/* eslint-disable */
import { Component, OnInit, ViewChild } from '@angular/core';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { Role, RoleService } from '../../../lib/role/role.service';
import { PermissionResource, PermissionResourceService } from '../../../lib/permission-resource.service';
import { TranslateService } from '@ngx-translate/core';
import { Transition, UIRouter } from '@uirouter/angular';
import { StateName } from '../../../app.state-names';
import { EmptyMessage, } from '../../../lib/util/messages';
import { RoleCreateModel } from '../role-create/role-create.component';
import { FieldValidationError } from '../../../lib/util/services';
import { NgForm } from '@angular/forms';
import { combineLatest, Subject } from 'rxjs';
import { saveAs } from 'file-saver';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FileItem, FileUploader, ParsedResponseHeaders } from 'ng2-file-upload';
import { FileUploaderUtil } from '../../../util/file-uploader-util';
import { ErrorMessageService } from '../../../lib/error-message-parser.service';
import { Node, TreeUtils } from '../../../util/tree/tree-utils';
import { TwoWayTreeMultiselectComponent } from '../../../shared/two-way-tree-multiselect/two-way-tree-multiselect.component';
import { StringKey } from '../../../app.string-keys';
import { UiConstants } from '../../../util/core-utils';
import Permission = PermissionResource.Permission;
import { FileUploadComponent } from '../../../shared/file-upload/flat/file-upload.component';

/* eslint-enable */

@Component({
  selector: 'app-role-edit',
  templateUrl: './role-edit.component.html',
  styleUrls: ['./role-edit.component.scss']
})
export class RoleEditComponent implements OnInit {
  UiConstants = UiConstants;
  Role = Role;
  StringKey = StringKey;

  @ViewChild('f', { static: true })
  fForm: NgForm;

  @ViewChild('permissionSelector', { static: true })
  permissionSelector: TwoWayTreeMultiselectComponent;

  model: RoleCreateModel = new RoleCreateModel();
  roleId: number;
  permissionData: Node[] = [];
  selectedPermissionData: Node[] = [];

  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  privilegeImportModel: PrivilegeImportModel = new PrivilegeImportModel();

  @ViewChild('importDialog', { static: true }) importDialog: ModalDirective;
  importDialogVisible: boolean = false;

  @ViewChild('fileUploadComponent', {static: false})
  fileUploadComponent: FileUploadComponent;

  uploadGlobalErrors: string;

  private fieldErrors: FieldValidationError<Role.ValidatedField> =
    FieldValidationError.empty<Role.ValidatedField>();

  constructor(
    private roleService: RoleService,
    private permissionService: PermissionResourceService,
    private translateService: TranslateService,
    private uiRouter: UIRouter,
    private transition: Transition,
    private uploadUtil: FileUploaderUtil,
    private errorMessageService: ErrorMessageService,
  ) {
    this.roleId = this.transition.params().id;
  }

  ngOnInit() {
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('ROLE_LIST_HEADING').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ROLE_LIST});
      }
    );
    this.loadModel();
  }

  private loadModel() {
    combineLatest(
      this.permissionService.query({
        explicit: false
      }),
      this.roleService.get({
        id: this.roleId
      }),
      (perm: Permission[],
       role: Role.Role) => {
        return {
          permissions: perm,
          role: role
        }
      }
    ).subscribe((result) => {
      this.breadcrumbSelf = result.role.name;
      this.model.name = result.role.name;
      const perms: string[] = [];
      result.permissions.forEach((p) => {
        perms.push(p.short_name);
      });
      result.role.privileges.forEach((priv) => {
        perms.splice(perms.findIndex((p) => p === priv), 1);
      });
      this.permissionData = this.processArray(perms);
      this.selectedPermissionData = this.processArray(result.role.privileges);
      this.permissionSelector.setUnselectedData(this.permissionData);
      this.permissionSelector.setSelectedData(this.selectedPermissionData);
    });
  }

  private processArray(list: string[]): Node[] {
    const tree: Node[] = [];
    list.forEach((i) => {
      const first: string[] = i.split('::');
      const descendants: string[] = first[0].split(':');
      if (first[1]) {
        descendants.push(first[1]);
      }
      const item: string = descendants.shift()!;
      TreeUtils.treePut(tree, [], item, descendants, i);
    });
    return TreeUtils.convertArray(tree);
  }

  hasFieldError(field: Role.ValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: Role.ValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: Role.ValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  update() {
    this.model.privileges = [];
    this.permissionSelector.getData().forEach(node => {
      this.model.privileges.push(node.id);
    });
    this.roleService.update({
      id: this.roleId,
      name: this.model.name,
      privileges: this.model.privileges
    }).subscribe((result: EmptyMessage) => {
        this.uiRouter.stateService.go(StateName.ROLE_LIST);
      },
      (error: any) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withForm(this.fForm);
        }
      }
    );
  }

  openImportDialog() {
    this.importDialogVisible = true;
    this.importDialog.show();
    this.privilegeImportModel = new PrivilegeImportModel();
  }

  closeImportDialog() {
    this.importDialogVisible = false;
    this.importDialog.hide();
    this.fileUploadComponent.uploader.clearQueue();
    this.fileUploadComponent.uploader.cancelAll();
  }

  onImportablePrivilegesSelected(files: File[]) {
    this.privilegeImportModel.importResult = undefined;
    const file = files[0];
    const fr = new FileReader();
    const subject: Subject<any> = new Subject<any>();
    fr.onloadend = (function (f) {
      return function (e) {
        try {
          const res = JSON.parse(this.result);
          subject.next(res);
        }
        catch (e) {
          subject.error('');
        }
      };
    })(file);
    fr.readAsText(file);
    subject.asObservable().subscribe((privis: any) => {
      if (privis && privis['privileges']) {
        this.privilegeImportModel.importablePrivileges = privis['privileges'];
        this.privilegeImportModel.importResult = undefined;
      }
      else {
        this.privilegeImportModel.importResult = 'FORMAT_ERROR';
      }
    }, error => {
      this.privilegeImportModel.importResult = 'FORMAT_ERROR';
    });
  }

  importPrivileges() {
    this.roleService.importPrivileges({id: this.roleId, privileges: this.privilegeImportModel.importablePrivileges})
      .subscribe(r => {
        this.privilegeImportModel.importResult = 'SUCCESS';
        this.closeImportDialog();
        this.loadModel();
      }, error => {
        this.privilegeImportModel.importResult = 'ERROR';
        const localizedError = this.errorMessageService.parseError(error);
        this.uploadGlobalErrors = localizedError!.globalErrors.join(',');

      });
  }

  exportPrivileges() {
    this.roleService.exportPrivileges({
      id: this.roleId
    }).subscribe((result: Role.PrivilegeExportResponse) => {
      const privi = JSON.stringify(result.privileges);
      const blob = new Blob(['{ "privileges" :\n' + privi + '\n}'],
        {type: 'text/json;charset=utf-8'});
      saveAs(blob, 'privileges_' + this.model.name + '.json');
    });
  }

}

export class PrivilegeImportModel {
  importablePrivileges: string[] = [];
  importResult?: string = undefined;
}
