/* eslint-disable */
import { Form } from './form.service';
import { LocalDate, OffsetDateTime, } from '../util/dates';
import { List, Set } from 'immutable';
import { Decimal } from 'decimal.js';
import { EmailAddress } from '../util/email-address';
import { PhoneNumber } from '../util/phone-number';
import { FormRecordResource } from './form-record-resource.service';
import { Services } from '../util/services';
import { FieldPaymentType } from '../../util/form/form-field-payment-type';
import { Invoice } from '../invoice/invoice/invoice.service';
import { FileAttachment, FileAttachmentResource, FileAttachments } from '../util/file-attachments';
/* eslint-enable */

export namespace FormRecord {

  export interface FormRecordCreateRequest {
    fields: Set<FieldEditRequest>;
  }

  export interface FormRecordUpdateRequest {
    fields: Set<FieldEditRequest>;
    version: number;
  }

  export interface FieldEditRequest {
    fieldId: number; // long
    data: FieldData;
  }

  export interface FormRecord {
    recordId: number; // long
    version: number; // long
    creationTime: OffsetDateTime;
    updateTime: OffsetDateTime;
    fields: List<Field>;
  }

  export class FormRecords {

    public static findFieldRecord(
      formRecord: FormRecord.FormRecord, field: Form.Field): FormRecord.Field | undefined {
      return formRecord.fields.toArray().filter((f) => {
        return f.fieldId === field.fieldId;
      })[0];
    }

    public static findComposedFieldRecord(
      formRecord: FormRecord.FormRecordComposed, field: Form.Field): FormRecord.FieldComposed | undefined {
      return formRecord.fields.toArray().filter((f) => {
        return f.fieldId === field.fieldId;
      })[0];
    }

    private constructor() {
    }

  }

  export interface FormRecordComposed {
    fields: Set<FieldComposed>;
  }

  export interface FieldComposed {
    fieldId: number; // long
    data: FieldData;
  }

  export interface Field extends FieldComposed {
    creationTime: OffsetDateTime;
    updateTime: OffsetDateTime;
    dataTypeSelector: Form.FieldDataTypeSelector;
  }

  export interface FieldData {
    stringAttributes?: FieldDataStringAttributes;
    numberAttributes?: FieldDataNumberAttributes;
    decimalAttributes?: FieldDataDecimalAttributes;
    dateAttributes?: FieldDataDateAttributes;
    dateTimeAttributes?: FieldDataDateTimeAttributes;
    booleanAttributes?: FieldDataBooleanAttributes;
    listItemAttributes?: FieldDataListItemAttributes;
    listMultiItemAttributes?: FieldDataListMultiItemAttributes;
    phoneNumberAttributes?: FieldDataPhoneNumberAttributes;
    emailAddressAttributes?: FieldDataEmailAddressAttributes;
    masterDataAttributes?: FieldDataMasterDataAttributes;
    customerAttributes?: FieldDataCustomerAttributes;
    userAttributes?: FieldDataUserAttributes;
    stockAttributes?: FieldDataStockAttributes;
    stockIntakeAttributes?: FieldDataStockIntakeAttributes;
    stockMoveAttributes?: FieldDataStockMoveAttributes;
    formTableAttributes?: FieldDataFormTableAttributes;
    paymentTypeAttributes?: FieldDataPaymentTypeAttributes;
    documentAttributes?: FieldDataDocumentAttributes;
    photoAttributes?: FieldDataPhotoAttributes;
    processOrderStockOuttakeAttributes?: FieldDataProcessOrderStockOuttakeAttributes;
    processOrderStockOuttakeCheckAttributes?: FieldDataProcessOrderStockOuttakeCheckAttributes;
    processOrderPackagingAttributes?: FieldDataProcessOrderPackagingAttributes;
    invoiceAttributes?: FieldDataInvoiceAttributes;
  }

  export interface FieldDataStringAttributes {
    value?: string;
  }

  export interface FieldDataNumberAttributes {
    value?: number; // Long
  }

  export interface FieldDataDecimalAttributes {
    value?: Decimal;
  }

  export interface FieldDataDateAttributes {
    value?: LocalDate;
  }

  export interface FieldDataDateTimeAttributes {
    value?: OffsetDateTime;
  }

  export interface FieldDataBooleanAttributes {
    value?: boolean;
  }

  export interface FieldDataListItemAttributes {
    value?: number; // long ID
  }

  export interface FieldDataListMultiItemAttributes {
    values: Set<number>; // long ID set
  }

  export interface FieldDataPhoneNumberAttributes {
    value?: PhoneNumber; // PhoneNumber
  }

  export interface FieldDataEmailAddressAttributes {
    value?: EmailAddress; // EmailAddress
  }

  export interface FieldDataMasterDataAttributes {
    values: Set<number>; // long ID set
  }

  export interface FieldDataCustomerAttributes {
    values: Set<number>; // long ID set
  }

  export interface FieldDataUserAttributes {
    values: Set<number>; // long ID set
  }

  export interface FieldDataStockAttributes {
    stocks: List<StockItem>;
    discountPercent?: Decimal;
  }

  export interface FieldDataStockIntakeAttributes {
    header?: StockIntakeHeader;
    values: List<StockIntakeItem>;
  }

  export interface FieldDataStockMoveAttributes {
    values: List<StockMoveItem>;
  }

  export interface FieldDataProcessOrderStockOuttakeAttributes {
    values: Set<ProcessOrderStockOuttakeItem>;
  }

  export interface FieldDataProcessOrderStockOuttakeCheckAttributes {
  }

  export interface FieldDataProcessOrderPackagingAttributes {
  }

  export interface FieldDataInvoiceAttributes {
    discountPercent?: Decimal;
    items: List<InvoiceItem>;
  }

  export interface ProcessOrderStockOuttakeItem {
    stockRecordId: number;
    amount?: Decimal;
  }

  export interface FieldDataDocumentAttributes {
    values?: Set<FileAttachment>;
  }

  export interface FieldDataPhotoAttributes {
    values?: Set<FileAttachment>;
  }

  export interface StockItem {
    id?: number;
    stockRecordId: number; // long
    amount: Decimal;

    unitOfMeasureId: number;
    submitAmount?: Decimal;
    submitBaseAmount?: Decimal;
    customNetPrice?: Decimal;
    code?: string;
    lastSavedStockItemNetPrice: Decimal;
    invoiced?: boolean;
    invoiceNumber?: string;
    dispersionPercent?: number;
    comment?: string;
    discountPercent?: number; // readonly
  }

  export interface StockIntakeHeader {
    intakeDate: LocalDate;
    shippingNumber: string;
    shippingCompany?: string;
    invoiceNumber?: string;
    state?: StockIntakeHeaderState;

  }

  export enum StockIntakeHeaderState {
    DEFAULT = 'DEFAULT',
    DRAFT = 'DRAFT',
    COMPLETED = 'COMPLETED',
    REVERTED = 'REVERTED'
  }

  export interface StockIntakeItem {
    stockId: number;
    stockItemId: number;
    amount: Decimal;
    unitOfMeasureId: number;
    intakeUnitNetPrice?: Decimal;
  }

  export interface StockMoveItem {
    sourceStockId: number;
    destinationStockId: number;
    stockItemId: number;
    amount: Decimal;
    unitOfMeasureId: number;
  }

  export interface InvoiceItem {
    id: number;
    amount: Decimal;
    comment?: string;
    currencyCode: string;
    externalId?: string;
    category?: string;
    hunVtszNumber?: string;
    netUnitPrice: Decimal;
    recordName: string;
    unitType: string;
    vatRate: Decimal;
    zeroVatRateType?: string;
    invoiced?: boolean;
  }

  export interface FieldDataFormTableAttributes {
    values: List<FormRecordComposed>;
  }

  export interface FieldDataPaymentTypeAttributes {
    paymentType?: FieldPaymentType;
  }

// Injectable is not allowed here. This class is in a namespace.
  export class ResourceMapper {

    constructor(private formMapper: Form.ResourceMapper) {
    }

    public toPublicComposedSet(l: FormRecordResource.FormRecordComposed[]): List<FormRecord.FormRecordComposed> {
      return List.of(...l.map((r) => {
        return this.toPublicComposed(r);
      }));
    }

    private toPublicComposed(r: FormRecordResource.FormRecordComposed): FormRecord.FormRecordComposed {
      return {
        fields: this.toPublicFieldComposedSet(r.fields)
      };
    }

    private toPublicFieldComposedSet(l: FormRecordResource.FieldComposed[]): Set<FormRecord.FieldComposed> {
      return Set.of(...l.map((r) => {
        return this.toPublicFieldComposed(r)!;
      }));
    }

    private toPublicFieldComposed(r: FormRecordResource.FieldComposed): FormRecord.FieldComposed {
      return {
        fieldId: r.id,
        data: this.toPublicFieldData(r.data),
      };
    }

    public toPublicSet(l: FormRecordResource.FormRecord[]): Set<FormRecord.FormRecord> {
      return Set.of(...l.map((r) => {
        return this.toPublic(r)!;
      }));
    }

    public toPublic(r?: FormRecordResource.FormRecord): FormRecord.FormRecord | undefined {
      if (!r) {
        return undefined;
      }
      return {
        recordId: r.id,
        version: r.version,
        creationTime: Services.toOffsetDateTime(r.creation_time),
        updateTime: Services.toOffsetDateTime(r.update_time),
        fields: this.toPublicFieldList(r.fields),
      };
    }

    public toPublicFieldList(resourceList: FormRecordResource.Field[]): List<FormRecord.Field> {
      return List.of(...resourceList.map((r) => this.toPublicField(r)));
    }

    public toPublicFieldSet(resourceList: FormRecordResource.Field[]): Set<FormRecord.Field> {
      return Set.of(...resourceList.map((r) => this.toPublicField(r)));
    }

    public toPublicField(r: FormRecordResource.Field): FormRecord.Field {
      return {
        fieldId: r.id,
        creationTime: Services.toOffsetDateTime(r.creation_time),
        updateTime: Services.toOffsetDateTime(r.update_time),
        dataTypeSelector: this.formMapper.toPublicFieldDataTypeSelector(r.data_type_selector),
        data: this.toPublicFieldData(r.data),
      };
    }

    toResourceComposedArray(s: List<FormRecord.FormRecordComposed>): FormRecordResource.FormRecordComposed[] {
      return s.toArray().map((o) => {
        return this.toResourceComposed(o);
      });
    }

    toResourceComposed(o: FormRecord.FormRecordComposed): FormRecordResource.FormRecordComposed {
      return {
        fields: this.toResourceFieldComposedArray(o.fields)
      };
    }

    toResourceFieldComposedArray(s: Set<FormRecord.FieldComposed>): FormRecordResource.FieldComposed[] {
      return s.toArray().map((o) => {
        return this.toResourceFieldComposed(o);
      });
    }

    toResourceFieldComposed(o: FormRecord.FieldComposed): FormRecordResource.FieldComposed {
      return {
        id: o.fieldId,
        data: this.toResourceFieldData(o.data)
      };
    }

    toResourceCreateRequest(r: FormRecord.FormRecordCreateRequest): FormRecordResource.FormRecordCreateRequest {
      return {
        fields: this.toResourceFieldCreateArray(r.fields)
      };
    }

    toResourceUpdateRequest(r: FormRecord.FormRecordUpdateRequest): FormRecordResource.FormRecordUpdateRequest {
      return {
        fields: this.toResourceFieldUpdateArray(r.fields),
        version: r.version
      };
    }

    // <editor-fold desc="Internal">

    private toPublicFieldData(r: FormRecordResource.FieldData): FormRecord.FieldData {
      return {
        stringAttributes: r.string_attributes
          ? this.toPublicFieldDataStringAttributes(r.string_attributes) : undefined,
        numberAttributes: r.number_attributes
          ? this.toPublicFieldDataNumberAttributes(r.number_attributes) : undefined,
        decimalAttributes: r.decimal_attributes
          ? this.toPublicFieldDataDecimalAttributes(r.decimal_attributes) : undefined,
        dateAttributes: r.date_attributes
          ? this.toPublicFieldDataDateAttributes(r.date_attributes) : undefined,
        dateTimeAttributes: r.date_time_attributes
          ? this.toPublicFieldDataDateTimeAttributes(r.date_time_attributes) : undefined,
        booleanAttributes: r.boolean_attributes
          ? this.toPublicFieldDataBooleanAttributes(r.boolean_attributes) : undefined,
        listItemAttributes: r.list_item_attributes
          ? this.toPublicFieldDataListItemAttributes(r.list_item_attributes) : undefined,
        listMultiItemAttributes: r.list_multi_item_attributes
          ? this.toPublicFieldDataListMultiItemAttributes(r.list_multi_item_attributes) : undefined,
        phoneNumberAttributes: r.phone_number_attributes
          ? this.toPublicFieldDataPhoneNumberAttributes(r.phone_number_attributes) : undefined,
        emailAddressAttributes: r.email_address_attributes
          ? this.toPublicFieldDataEmailAddressAttributes(r.email_address_attributes) : undefined,
        masterDataAttributes: r.master_data_attributes
          ? this.toPublicFieldDataMasterDataAttributes(r.master_data_attributes) : undefined,
        customerAttributes: r.customer_attributes
          ? this.toPublicFieldDataCustomerAttributes(r.customer_attributes) : undefined,
        userAttributes: r.user_attributes
          ? this.toPublicFieldDataUserAttributes(r.user_attributes) : undefined,
        stockAttributes: r.stock_attributes
          ? this.toPublicFieldDataStockAttributes(r.stock_attributes) : undefined,
        stockIntakeAttributes: r.stock_intake_attributes
          ? this.toPublicFieldDataStockIntakeAttributes(r.stock_intake_attributes) : undefined,
        stockMoveAttributes: r.stock_move_attributes
          ? this.toPublicFieldDataStockMoveAttributes(r.stock_move_attributes) : undefined,
        formTableAttributes: r.form_table_attributes
          ? this.toPublicFieldDataFormTableAttributes(r.form_table_attributes) : undefined,
        paymentTypeAttributes: r.invoice_payment_type_attributes
          ? this.toPublicFieldDataPaymentTypeAttributes(r.invoice_payment_type_attributes) : undefined,
        documentAttributes: r.document_attributes
          ? this.toPublicFieldDataDocumentAttributes(r.document_attributes) : undefined,
        photoAttributes: r.photo_attributes
          ? this.toPublicFieldDataPhotoAttributes(r.photo_attributes) : undefined,
        processOrderStockOuttakeAttributes: r.process_order_stock_outtake_attributes
          ? this.toPublicFieldDataProcessOrderStockOuttakeAttributes(r.process_order_stock_outtake_attributes) : undefined,
        processOrderStockOuttakeCheckAttributes: r.process_order_stock_outtake_check_attributes
          ? this.toPublicFieldDataProcessOrderStockOuttakeCheckAttributes(r.process_order_stock_outtake_check_attributes) : undefined,
        processOrderPackagingAttributes: r.process_order_packaging_attributes
          ? this.toPublicFieldDataProcessOrderPackagingAttributes(r.process_order_packaging_attributes) : undefined,
        invoiceAttributes: r.invoice_attributes
          ? this.toPublicFieldDataIncvoiceAttributes(r.invoice_attributes) : undefined,
      };
    }

    private toPublicFieldDataStringAttributes(
      r: FormRecordResource.FieldDataStringAttributes): FieldDataStringAttributes {
      return {
        value: r.value
      };
    }

    private toPublicFieldDataNumberAttributes(
      r: FormRecordResource.FieldDataNumberAttributes): FieldDataNumberAttributes {
      return {
        value: r.value
      };
    }

    private toPublicFieldDataDecimalAttributes(
      r: FormRecordResource.FieldDataDecimalAttributes): FieldDataDecimalAttributes {
      return {
        value: Services.toDecimal(r.value)
      };
    }

    private toPublicFieldDataDateAttributes(
      r: FormRecordResource.FieldDataDateAttributes): FieldDataDateAttributes {
      return {
        value: Services.toLocalDate(r.value)
      };
    }

    private toPublicFieldDataDateTimeAttributes(
      r: FormRecordResource.FieldDataStringAttributes): FieldDataDateTimeAttributes {
      return {
        value: Services.toOffsetDateTime(r.value)
      };
    }

    private toPublicFieldDataBooleanAttributes(
      r: FormRecordResource.FieldDataBooleanAttributes): FieldDataBooleanAttributes {
      return {
        value: r.value
      };
    }

    private toPublicFieldDataListItemAttributes(
      r: FormRecordResource.FieldDataListItemAttributes): FieldDataListItemAttributes {
      return {
        value: r.value
      };
    }

    private toPublicFieldDataListMultiItemAttributes(
      r: FormRecordResource.FieldDataListMultiItemAttributes): FieldDataListMultiItemAttributes {
      return {
        values: Set.of(...r.values)
      };
    }

    private toPublicFieldDataPhoneNumberAttributes(
      r: FormRecordResource.FieldDataPhoneNumberAttributes): FieldDataPhoneNumberAttributes {
      return {
        value: Services.toPhoneNumber(r.value)
      };
    }

    private toPublicFieldDataEmailAddressAttributes(
      r: FormRecordResource.FieldDataEmailAddressAttributes): FieldDataEmailAddressAttributes {
      return {
        value: Services.toEmailAddress(r.value)
      };
    }

    private toPublicFieldDataMasterDataAttributes(
      r: FormRecordResource.FieldDataMasterDataAttributes): FieldDataMasterDataAttributes {
      return {
        values: Set.of(...r.values)
      };
    }

    private toPublicFieldDataCustomerAttributes(
      r: FormRecordResource.FieldDataCustomerAttributes): FieldDataCustomerAttributes {
      return {
        values: Set.of(...r.values)
      };
    }

    private toPublicFieldDataUserAttributes(
      r: FormRecordResource.FieldDataUserAttributes): FieldDataUserAttributes {
      return {
        values: Set.of(...r.values)
      };
    }

    private toPublicFieldDataStockAttributes(
      r: FormRecordResource.FieldDataStockAttributes): FieldDataStockAttributes {
      return {
        stocks: this.toPublicStockItemList(r.stock_items),
        discountPercent: Services.toDecimal(r.discount_percent)
      };
    }

    private toPublicFieldDataStockIntakeAttributes(
      r: FormRecordResource.FieldDataStockIntakeAttributes): FieldDataStockIntakeAttributes {
      return {
        values: this.toPublicStockIntakeItemList(r.values),
        header: r.header ? {
          intakeDate: Services.toLocalDate(r.header.intake_date),
          shippingNumber: r.header.shipping_number,
          shippingCompany: r.header.shipping_company,
          invoiceNumber: r.header.invoice_number,
          state: <StockIntakeHeaderState>r.header.state
        } : undefined
      };
    }

    private toPublicFieldDataStockMoveAttributes(
      r: FormRecordResource.FieldDataStockMoveAttributes): FieldDataStockMoveAttributes {
      return {
        values: this.toPublicStockMoveItemList(r.values)
      };
    }

    private toPublicFieldDataProcessOrderStockOuttakeAttributes(
      r: FormRecordResource.FieldDataProcessOrderStockOuttakeAttributes): FieldDataProcessOrderStockOuttakeAttributes {
      return {
        values: this.toPublicProcessOrderStockOuttakeItemSet(r.values)
      };
    }

    private toPublicFieldDataProcessOrderStockOuttakeCheckAttributes(
      r: FormRecordResource.FieldDataProcessOrderStockOuttakeCheckAttributes): FieldDataProcessOrderStockOuttakeCheckAttributes {
      return {};
    }

    private toPublicFieldDataProcessOrderPackagingAttributes(
      r: FormRecordResource.FieldDataProcessOrderPackagingAttributes): FieldDataProcessOrderPackagingAttributes {
      return {};
    }

    private toPublicFieldDataIncvoiceAttributes(
      r: FormRecordResource.FieldDataInvoiceAttributes): FieldDataInvoiceAttributes {
      return {
        discountPercent: Services.toDecimal(r.discount_percent),
        items: this.toPublicInvoiceItemList(r.invoice_items)
      };
    }

    private toPublicFieldDataDocumentAttributes(
      r: FormRecordResource.FieldDataDocumentAttributes): FieldDataDocumentAttributes {
      return {
        values: this.toPublicFileAttachmentInfoSet(r.values)
      };
    }

    private toPublicFieldDataPhotoAttributes(
      r: FormRecordResource.FieldDataPhotoAttributes): FieldDataPhotoAttributes {
      return {
        values: this.toPublicFileAttachmentInfoSet(r.values)
      };
    }

    private toPublicFieldDataFormTableAttributes(
      r: FormRecordResource.FieldDataFormTableAttributes): FieldDataFormTableAttributes {
      return {
        values: this.toPublicComposedSet(r.values)
      };
    }

    private toPublicFieldDataPaymentTypeAttributes(
      r: FormRecordResource.FieldDataPaymentTypeAttributes): FieldDataPaymentTypeAttributes {
      return {
        paymentType: FieldPaymentType.fromStringValue(<Invoice.InvoicePaymentType>r.value)
      };
    }

    private toPublicStockItemList(l: FormRecordResource.StockItem[]): List<StockItem> {
      return List.of(...l.map((r) => {
        return FormRecord.ResourceMapper.toPublicStockItem(r);
      }));
    }

    private toPublicStockIntakeItemList(l: FormRecordResource.StockIntakeItem[]): List<StockIntakeItem> {
      return List.of(...l.map((r) => {
        return FormRecord.ResourceMapper.toPublicStockIntakeItem(r);
      }));
    }

    private toPublicStockMoveItemList(l: FormRecordResource.StockMoveItem[]): List<StockMoveItem> {
      return List.of(...l.map((r) => {
        return FormRecord.ResourceMapper.toPublicStockMoveItem(r);
      }));
    }

    /* eslint-disable-next-line */
    static toPublicStockItem(r: FormRecordResource.StockItem): FormRecord.StockItem {
      return {
        id: r.id,
        stockRecordId: r.stock_record_id,
        amount: Services.toDecimal(r.amount)!, // non-optional, check is not required
        unitOfMeasureId: r.unit_of_measure_id,
        submitAmount: Services.toDecimal(r.submit_amount),
        submitBaseAmount: Services.toDecimal(r.submit_base_amount),
        code: r.code,
        customNetPrice: Services.toDecimal(r.custom_net_price),
        lastSavedStockItemNetPrice: Services.toDecimal(r.last_saved_stock_item_net_price)!,
        invoiced: r.invoiced,
        invoiceNumber: r.invoice_number,
        dispersionPercent: r.dispersion_percent,
        comment: r.comment,
        discountPercent: r.discount_percent
      };
    }

    /* eslint-disable-next-line */
    static toPublicStockIntakeItem(r: FormRecordResource.StockIntakeItem): FormRecord.StockIntakeItem {
      return {
        stockId: r.stock_id,
        stockItemId: r.stock_item_id,
        amount: Services.toDecimal(r.amount)!, // non-optional, check is not required
        intakeUnitNetPrice: Services.toDecimal(r.intake_unit_net_price),
        unitOfMeasureId: r.unit_of_measure_id
      };
    }

    /* eslint-disable-next-line */
    static toPublicStockMoveItem(r: FormRecordResource.StockMoveItem): FormRecord.StockMoveItem {
      return {
        sourceStockId: r.source_stock_id,
        destinationStockId: r.destination_stock_id,
        stockItemId: r.stock_item_id,
        amount: Services.toDecimal(r.amount)!, // non-optional, check is not required
        unitOfMeasureId: r.unit_of_measure_id
      };
    }

    private toPublicInvoiceItemList(l: FormRecordResource.InvoiceItem[]): List<InvoiceItem> {
      return List.of(...l.map((r) => {
        return FormRecord.ResourceMapper.toPublicInvoiceItem(r);
      }));
    }

    /* eslint-disable-next-line */
    static toPublicInvoiceItem(r: FormRecordResource.InvoiceItem): FormRecord.InvoiceItem {
      return {
        id: r.id,
        amount: Services.toDecimal(r.amount)!,
        comment: r.comment,
        currencyCode: r.currency_code,
        externalId: r.external_id,
        category: r.category,
        hunVtszNumber: r.hun_vtsz_number,
        netUnitPrice: Services.toDecimal(r.net_unit_price)!,
        recordName: r.record_name,
        unitType: r.unit_type,
        vatRate: Services.toDecimal(r.vat_rate)!,
        zeroVatRateType: r.zero_vat_rate_type,
        invoiced: r.invoiced
      };
    }

    private toPublicProcessOrderStockOuttakeItemSet(
      l?: FormRecordResource.ProcessOrderStockOuttakeItem[]): Set<ProcessOrderStockOuttakeItem> {
      if (l) {
        return Set.of(...l.map((r) => {
          return this.toPublicProcessOrderStockOuttakeItem(r);
        }));
      }
      return Set<ProcessOrderStockOuttakeItem>();
    }

    private toPublicProcessOrderStockOuttakeItem(
      r: FormRecordResource.ProcessOrderStockOuttakeItem): FormRecord.ProcessOrderStockOuttakeItem {
      return {
        stockRecordId: r.stock_record_id,
        amount: Services.toDecimal(r.amount) ? Services.toDecimal(r.amount) : undefined
      };
    }

    private toPublicFileAttachmentInfoSet(l?: FileAttachmentResource[]): Set<FileAttachment> | undefined {
      if (l) {
        return Set.of(...FileAttachments.toPublicList(l));
      }
      return undefined;
    }

    public toPublicFileAttachmentInfo(r: FileAttachmentResource): FileAttachment {
      return FileAttachments.toPublic(r)
    }

    private toResourceFieldCreateArray(
      s: Set<FormRecord.FieldEditRequest>): FormRecordResource.FieldCreateRequest[] {
      return s.toArray().map((field) => {
        return this.toResourceField(field);
      });
    }

    private toResourceFieldUpdateArray(
      s: Set<FormRecord.FieldEditRequest>): FormRecordResource.FieldUpdateRequest[] {
      return this.toResourceFieldCreateArray(s); // For now the create and update request handled the same way.
    }

    private toResourceField(
      o: FormRecord.FieldEditRequest): FormRecordResource.FieldCreateRequest {
      return {
        id: o.fieldId,
        data: this.toResourceFieldData(o.data),
      };
    }

    private toResourceFieldData(
      o: FormRecord.FieldData): FormRecordResource.FieldData {
      return {
        string_attributes: o.stringAttributes
          ? this.toResourceFieldDataStringAttributes(o.stringAttributes) : undefined,
        number_attributes: o.numberAttributes
          ? this.toResourceFieldDataNumberAttributes(o.numberAttributes) : undefined,
        decimal_attributes: o.decimalAttributes
          ? this.toResourceFieldDataDecimalAttributes(o.decimalAttributes) : undefined,
        date_attributes: o.dateAttributes
          ? this.toResourceFieldDataDateAttributes(o.dateAttributes) : undefined,
        date_time_attributes: o.dateTimeAttributes
          ? this.toResourceFieldDataDateTimeAttributes(o.dateTimeAttributes) : undefined,
        boolean_attributes: o.booleanAttributes
          ? this.toResourceFieldDataBooleanAttributes(o.booleanAttributes) : undefined,
        list_item_attributes: o.listItemAttributes
          ? this.toResourceFieldDataListItemAttributes(o.listItemAttributes) : undefined,
        list_multi_item_attributes: o.listMultiItemAttributes
          ? this.toResourceFieldDataListMultiItemAttributes(o.listMultiItemAttributes) : undefined,
        phone_number_attributes: o.phoneNumberAttributes
          ? this.toResourceFieldDataPhoneNumberAttributes(o.phoneNumberAttributes) : undefined,
        email_address_attributes: o.emailAddressAttributes
          ? this.toResourceFieldDataEmailAddressAttributes(o.emailAddressAttributes) : undefined,
        master_data_attributes: o.masterDataAttributes
          ? this.toResourceFieldDataMasterDataAttributes(o.masterDataAttributes) : undefined,
        customer_attributes: o.customerAttributes
          ? this.toResourceFieldDataCustomerAttributes(o.customerAttributes) : undefined,
        user_attributes: o.userAttributes
          ? this.toResourceFieldDataUserAttributes(o.userAttributes) : undefined,
        stock_attributes: o.stockAttributes
          ? this.toResourceFieldDataStockAttributes(o.stockAttributes) : undefined,
        stock_intake_attributes: o.stockIntakeAttributes
          ? this.toResourceFieldDataStockIntakeAttributes(o.stockIntakeAttributes) : undefined,
        stock_move_attributes: o.stockMoveAttributes
          ? this.toResourceFieldDataStockMoveAttributes(o.stockMoveAttributes) : undefined,
        form_table_attributes: o.formTableAttributes
          ? this.toResourceFieldDataFormTableAttributes(o.formTableAttributes) : undefined,
        invoice_payment_type_attributes: o.paymentTypeAttributes
          ? this.toResourceFieldDataPaymentTypeAttributes(o.paymentTypeAttributes) : undefined,
        document_attributes: o.documentAttributes
          ? this.toResourceFieldDataDocumentAttributes(o.documentAttributes) : undefined,
        photo_attributes: o.photoAttributes
          ? this.toResourceFieldDataPhotoAttributes(o.photoAttributes) : undefined,
        process_order_stock_outtake_attributes: o.processOrderStockOuttakeAttributes
          ? this.toResourceFieldDataProcessOrderStockOuttakeAttributes(o.processOrderStockOuttakeAttributes) : undefined,
        process_order_stock_outtake_check_attributes: o.processOrderStockOuttakeCheckAttributes
          ? this.toResourceFieldDataProcessOrderStockOuttakeCheckAttributes(o.processOrderStockOuttakeCheckAttributes) : undefined,
        process_order_packaging_attributes: o.processOrderPackagingAttributes
          ? this.toResourceFieldDataProcessOrderPackagingAttributes(o.processOrderPackagingAttributes) : undefined,
        invoice_attributes: o.invoiceAttributes
          ? this.toResourceFieldDataInvoiceAttributes(o.invoiceAttributes) : undefined,
      };
    }

    private toResourceFieldDataStringAttributes(
      a: FormRecord.FieldDataStringAttributes): FormRecordResource.FieldDataStringAttributes {
      return {
        value: a.value
      };
    }

    private toResourceFieldDataNumberAttributes(
      a: FormRecord.FieldDataNumberAttributes): FormRecordResource.FieldDataNumberAttributes {
      return {
        value: a.value
      };
    }

    private toResourceFieldDataDecimalAttributes(
      a: FormRecord.FieldDataDecimalAttributes): FormRecordResource.FieldDataDecimalAttributes {
      return {
        value: Services.decimalToString(a.value)
      };
    }

    private toResourceFieldDataDateAttributes(
      a: FormRecord.FieldDataDateAttributes): FormRecordResource.FieldDataDateAttributes {
      return {
        value: Services.localDateToString(a.value)
      };
    }

    private toResourceFieldDataDateTimeAttributes(
      a: FormRecord.FieldDataDateTimeAttributes): FormRecordResource.FieldDataDateTimeAttributes {
      return {
        value: Services.offsetDateTimeToString(a.value)
      };
    }

    private toResourceFieldDataBooleanAttributes(
      a: FormRecord.FieldDataBooleanAttributes): FormRecordResource.FieldDataBooleanAttributes {
      return {
        value: a.value
      };
    }

    private toResourceFieldDataListItemAttributes(
      a: FormRecord.FieldDataListItemAttributes): FormRecordResource.FieldDataListItemAttributes {
      return {
        value: a.value
      };
    }

    private toResourceFieldDataListMultiItemAttributes(
      a: FormRecord.FieldDataListMultiItemAttributes): FormRecordResource.FieldDataListMultiItemAttributes {
      return {
        values: a.values.toArray()
      };
    }

    private toResourceFieldDataPhoneNumberAttributes(
      a: FormRecord.FieldDataPhoneNumberAttributes): FormRecordResource.FieldDataPhoneNumberAttributes {
      return {
        value: Services.phoneNumberToString(a.value)
      };
    }

    private toResourceFieldDataEmailAddressAttributes(
      a: FormRecord.FieldDataEmailAddressAttributes): FormRecordResource.FieldDataEmailAddressAttributes {
      return {
        value: Services.emailAddressToString(a.value)
      };
    }

    private toResourceFieldDataMasterDataAttributes(
      a: FormRecord.FieldDataMasterDataAttributes): FormRecordResource.FieldDataMasterDataAttributes {
      return {
        values: a.values.toArray()
      };
    }

    private toResourceFieldDataCustomerAttributes(
      a: FormRecord.FieldDataCustomerAttributes): FormRecordResource.FieldDataCustomerAttributes {
      return {
        values: a.values.toArray()
      };
    }

    private toResourceFieldDataUserAttributes(
      a: FormRecord.FieldDataUserAttributes): FormRecordResource.FieldDataUserAttributes {
      return {
        values: a.values.toArray()
      };
    }

    private toResourceFieldDataStockAttributes(
      a: FormRecord.FieldDataStockAttributes): FormRecordResource.FieldDataStockAttributes {
      return {
        stock_items: this.toResourceStockItemArray(a.stocks),
        discount_percent: Services.decimalToString(a.discountPercent)
      };
    }

    private toResourceFieldDataStockIntakeAttributes(
      a: FormRecord.FieldDataStockIntakeAttributes): FormRecordResource.FieldDataStockIntakeAttributes {
      return {
        values: this.toResourceStockIntakeItemArray(a.values),
        header: a.header ? {
          intake_date: Services.localDateToString(a.header.intakeDate)!,
          shipping_company: a.header.shippingCompany,
          shipping_number: a.header.shippingNumber,
          invoice_number: a.header.invoiceNumber
        } : undefined
      };
    }

    private toResourceFieldDataStockMoveAttributes(
      a: FormRecord.FieldDataStockMoveAttributes): FormRecordResource.FieldDataStockMoveAttributes {
      return {
        values: this.toResourceStockMoveItemArray(a.values)
      };
    }

    private toResourceFieldDataProcessOrderStockOuttakeAttributes(
      a: FormRecord.FieldDataProcessOrderStockOuttakeAttributes): FormRecordResource.FieldDataProcessOrderStockOuttakeAttributes {
      return {
        values: this.toResourceProcessOrderStockOuttakeValues(a.values)
      };
    }

    private toResourceFieldDataProcessOrderStockOuttakeCheckAttributes(
      a: FormRecord.FieldDataProcessOrderStockOuttakeCheckAttributes): FormRecordResource.FieldDataProcessOrderStockOuttakeCheckAttributes {
      return {};
    }

    private toResourceFieldDataProcessOrderPackagingAttributes(
      a: FormRecord.FieldDataProcessOrderPackagingAttributes): FormRecordResource.FieldDataProcessOrderPackagingAttributes {
      return {};
    }

    private toResourceFieldDataInvoiceAttributes(
      a: FormRecord.FieldDataInvoiceAttributes): FormRecordResource.FieldDataInvoiceAttributes {
      return {
        discount_percent: Services.decimalToString(a.discountPercent),
        invoice_items: this.toResourceInvoiceItemArray(a.items)
      }
    }

    private toResourceFieldDataDocumentAttributes(
      a: FormRecord.FieldDataDocumentAttributes): FormRecordResource.FieldDataDocumentAttributes {
      return {};
    }

    private toResourceFieldDataPhotoAttributes(
      a: FormRecord.FieldDataPhotoAttributes): FormRecordResource.FieldDataPhotoAttributes {
      return {};
    }

    private toResourceFieldDataFormTableAttributes(
      a: FormRecord.FieldDataFormTableAttributes): FormRecordResource.FieldDataFormTableAttributes {
      return {
        values: this.toResourceComposedArray(a.values)
      };
    }

    private toResourceFieldDataPaymentTypeAttributes(
      a: FormRecord.FieldDataPaymentTypeAttributes): FormRecordResource.FieldDataPaymentTypeAttributes {
      return {
        value: FieldPaymentType.toStringValue(a.paymentType)
      };
    }

    private toResourceStockItemArray(s: List<FormRecord.StockItem>): FormRecordResource.StockItem[] {
      return s.toArray().map((r) => {
        return this.toResourceStockItem(r);
      });
    }

    private toResourceStockItem(r: FormRecord.StockItem): FormRecordResource.StockItem {
      return {
        id: r.id,
        stock_record_id: r.stockRecordId,
        amount: Services.decimalToString(r.amount)!, // non-optional, check is not required
        unit_of_measure_id: r.unitOfMeasureId,
        code: r.code,
        custom_net_price: Services.decimalToString(r.customNetPrice),
        last_saved_stock_item_net_price: Services.decimalToString(r.lastSavedStockItemNetPrice)!,
        invoiced: r.invoiced,
        invoice_number: r.invoiceNumber,
        dispersion_percent: r.dispersionPercent,
        comment: r.comment
      };
    }

    private toResourceStockIntakeItemArray(s: List<FormRecord.StockIntakeItem>): FormRecordResource.StockIntakeItem[] {
      return s.toArray().map((r) => {
        return this.toResourceStockIntakeItem(r);
      });
    }

    private toResourceStockIntakeItem(r: FormRecord.StockIntakeItem): FormRecordResource.StockIntakeItem {
      return {
        stock_id: r.stockId,
        stock_item_id: r.stockItemId,
        amount: Services.decimalToString(r.amount)!, // non-optional, check is not required
        unit_of_measure_id: r.unitOfMeasureId,
        intake_unit_net_price: Services.decimalToString(r.intakeUnitNetPrice)
      };
    }

    private toResourceStockMoveItemArray(s: List<FormRecord.StockMoveItem>): FormRecordResource.StockMoveItem[] {
      return s.toArray().map((r) => {
        return this.toResourceStockMoveItem(r);
      });
    }

    private toResourceStockMoveItem(r: FormRecord.StockMoveItem): FormRecordResource.StockMoveItem {
      return {
        source_stock_id: r.sourceStockId,
        destination_stock_id: r.destinationStockId,
        stock_item_id: r.stockItemId,
        unit_of_measure_id: r.unitOfMeasureId,
        amount: Services.decimalToString(r.amount)! // non-optional, check is not required
      };
    }

    private toResourceInvoiceItemArray(s: List<FormRecord.InvoiceItem>): FormRecordResource.InvoiceItem[] {
      return s.toArray().map((r) => {
        return this.toResourceInvoiceItem(r);
      });
    }

    private toResourceInvoiceItem(r: FormRecord.InvoiceItem): FormRecordResource.InvoiceItem {
      return {
        id: r.id,
        amount: Services.decimalToString(r.amount)!,
        comment: r.comment,
        currency_code: r.currencyCode,
        external_id: r.externalId,
        category: r.category,
        hun_vtsz_number: r.hunVtszNumber,
        net_unit_price: Services.decimalToString(r.netUnitPrice)!,
        record_name: r.recordName,
        unit_type: r.unitType,
        vat_rate: Services.decimalToString(r.vatRate)!,
        zero_vat_rate_type: r.zeroVatRateType,
        invoiced: r.invoiced
      };
    }

    private toResourceProcessOrderStockOuttakeValues(
      r: Set<ProcessOrderStockOuttakeItem>): FormRecordResource.ProcessOrderStockOuttakeItem[] {
      return r.toArray().map((item) => {
        return this.toResourceProcessOrderStockOuttakeItem(item);
      });
    }

    private toResourceProcessOrderStockOuttakeItem(item: ProcessOrderStockOuttakeItem): FormRecordResource.ProcessOrderStockOuttakeItem {
      return {
        stock_record_id: item.stockRecordId,
        amount: Services.decimalToString(item.amount)
      }
    }

    // </editor-fold>

  }

}
