<div bsModal [config]="UiConstants.modalConfig" #taskRecordBulkCloneDialog="bs-modal" class="modal fade" tabindex="-1"
     id="taskRecordBulkCloneDialog"
     role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="isVisible">
    <div class="modal-content">
      <form [formGroup]='formGroup' (ngSubmit)="bulkClone()" #f="ngForm">
        <div class="modal-header">
          <p class="modal-title"><strong>{{'TASK_RECORD_BULK_CLONE_TITLE' | translate}}</strong></p>
          <button type="button" class="close" (click)="hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group row"
               [ngClass]="{ 'has-danger': hasLocalFieldError('task')}">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label required-field-label">
                {{'COMMON_TASK'|translate}}
              </label>
            </div>
            <div class="col-md-8">
              <angular2-multiselect [data]="tasks" [settings]="offlineDropdownSettings"
                                    class="form-control"
                                    [(ngModel)]="model.task"
                                    formControlName="task">
              </angular2-multiselect>
              <div class="form-control-feedback"
                   *ngIf="hasLocalFieldError('task')">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
          </div>

          <ng-container *ngIf="model.task.length > 0">

            <div class="form-group row"
                 [ngClass]="{ 'has-danger': hasLocalFieldError('cloneFields')}">
              <div class="col-md-4">
                <label class="col-form-label mw-100 form-control-label">
                  {{'TASK_RECORD_BULK_CLONE_FIELDS'|translate}}
                </label>
              </div>
              <div class="col-md-8">
                <angular2-multiselect [data]="cloneFields" [settings]="cloneFieldsDropdownSettings"
                                      class="form-control"
                                      [(ngModel)]="model.cloneFields"
                                      (ngModelChange)="onClonedFieldsChanged()"
                                      formControlName="cloneFields">
                </angular2-multiselect>
              </div>
            </div>

            <div class="form-group row" *ngIf="!model.nameCloned"
                 [ngClass]="{ 'has-danger': hasLocalFieldError('nameTemplate')}">
              <div class="col-md-4">
                <label class="col-form-label mw-100 form-control-label required-field-label">
                  {{'TASK_RECORD_CREATE_NAME_TEMPLATE'|translate}}
                </label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <app-material-tag-input
                    formControlName="nameTemplate"
                    [(ngModel)]="model.nameTemplate"
                    [placeholderStringKey]="'TASK_RECORD_CREATE_NAME_TEMPLATE'"
                    [autocompleteItems]="TaskRecordQuickCreateNameTemplate.availableTemplateBlocks"
                    [separatorCharacter]="TaskRecordQuickCreateNameTemplate.templateBlockSeparator"
                    [generatedStringExampleMap]="TaskRecordQuickCreateNameTemplate.templateBlockExampleMap"
                    [separatorKeyCodes]="TaskRecordQuickCreateNameTemplate.separatorKeysCodes"
                    [shouldGenerateExample]="true"
                    [form]="form">
                  </app-material-tag-input>
                  <div class="form-control-feedback"
                       *ngIf="hasLocalFieldError('nameTemplate', 'required')">
                    {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label
                class="col-form-label form-control-label col-md-4 detail-title">
                {{'TASK_RECORD_BULK_CLONE_OPEN_TASK_RECORDS' | translate}}
              </label>
              <div class="col-md-8">
                <label class="switch switch-text switch-info mb-0">
                  <input type="checkbox" class="switch-input"
                         [ngModelOptions]="{standalone: true}"
                         [(ngModel)]="model.openTaskRecords"
                         (ngModelChange)="onOpenTaskRecordsChanged()">
                  <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                  <span class="switch-handle"></span>
                </label>
              </div>
            </div>

            <div class="form-group row" *ngIf="!model.userGroupCloned"
                 [ngClass]="{ 'has-danger': hasLocalFieldError('userGroup')}">
              <div class="col-md-4">
                <label class="col-form-label mw-100 form-control-label"
                       [class.required-field-label]="model.openTaskRecords && model.assignee.user === undefined && model.assignee.device === undefined">
                  {{'TASK_DATA_MODIFICATION_USER_GROUP'|translate}}
                </label>
              </div>
              <div class="col-md-8">
                <angular2-multiselect [data]="userGroups" [settings]="dropdownSettings"
                                      class="form-control"
                                      [(ngModel)]="model.userGroup"
                                      (onRemoteSearch)="filterUserGroups($event.target.value)"
                                      (ngModelChange)="onUserGroupChanged()"
                                      formControlName="userGroup">
                </angular2-multiselect>
                <div class="form-control-feedback"
                     *ngIf="hasLocalFieldError('userGroup', 'required')">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
              </div>
            </div>

            <div class="form-group row" *ngIf="config.assigneeWithUser && !model.assigneeCloned"
                 [ngClass]="{ 'has-danger': hasLocalFieldError('user')}">
              <div class="col-md-4">
                <label class="col-form-label mw-100 form-control-label"
                       [class.required-field-label]="model.openTaskRecords && model.userGroup.length === 0">
                  {{'TASK_LABEL_OWNER_USER'|translate}}
                </label>
              </div>
              <div class="col-md-8">
                <angular2-multiselect [data]="users" [settings]="dropdownSettings"
                                      class="form-control"
                                      [(ngModel)]="model.assignee._user"
                                      (onRemoteSearch)="filterUsers($event.target.value)"
                                      (ngModelChange)="onAssigneeChanged()"
                                      formControlName="user">
                </angular2-multiselect>
                <div class="form-control-feedback"
                     *ngIf="hasLocalFieldError('user', 'required')">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
              </div>
            </div>

            <div class="form-group row" *ngIf="config.assigneeWithMobileApp && !model.assigneeCloned"
                 [ngClass]="{ 'has-danger': hasLocalFieldError('device')}">
              <div class="col-md-4">
                <label class="col-form-label mw-100 form-control-label"
                       [class.required-field-label]="model.openTaskRecords && model.userGroup.length === 0">
                  {{'TASK_DATA_MODIFICATION_DEVICE'|translate}}
                </label>
              </div>
              <div class="col-md-8">
                <angular2-multiselect [data]="devices" [settings]="dropdownSettings"
                                      class="form-control"
                                      [(ngModel)]="model.assignee._device"
                                      (ngModelChange)="onAssigneeChanged()"
                                      formControlName="device">
                </angular2-multiselect>
                <div class="form-control-feedback"
                     *ngIf="hasLocalFieldError('device', 'required')">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
              </div>
            </div>

          </ng-container>
          <div class="alert alert-warning mb-0"
               *ngIf="model.taskRecordIds.length > 1">
            <p class="mb-0">
            <span class="font-weight-bold">
              {{'CUSTOMER_RECORD_BATCH_TASK_RECORD_CREATE_WARNING' | translate: {number: model.taskRecordIds.length} }}
            </span>
            </p>
          </div>

        </div>
        <!-- /.modal-body -->
        <div class="modal-footer justify-content-end">
        <span>
          <button type="button" class="btn btn-outline-primary" (click)="hide()">
            {{'COMMON_BUTTON_CANCEL' | translate}}
          </button>
          <button type="submit" class="btn btn-primary ml-1"
                  [ladda]="creationInProgress"
                  [disabled]="creationInProgress">
            {{'EMPTY_DASHBOARD_CREATE_TASK' | translate}}
          </button>
        </span>
        </div>
      </form>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
