/* eslint-disable */
import { Component, OnInit, ViewChild } from '@angular/core';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { Role, RoleService } from '../../../lib/role/role.service';
import { TranslateService } from '@ngx-translate/core';
import { Transition, UIRouter } from '@uirouter/angular';
import { StateName } from '../../../app.state-names';
import { RoleCreateModel } from '../role-create/role-create.component';
import { RightModel } from '../../../app.rights';
import { RightResolver, RightService } from '../../../lib/right.service';
import { HistoryDetailDialogComponent } from '../../history-log/history-detail-dialog/history-detail-dialog.component';
import { HistoryBaseModel, HistoryLogComponent } from '../../history-log/history-log/history-log.component';
import { OptionItem, QueryFieldModel } from '../../../util/core-utils';
import { OrderType, QueryResult } from '../../../lib/util/services';
import { Set } from 'immutable';
import { Node, Tree, TreeUtils } from '../../../util/tree/tree-utils';
import RoleHistoryType = Role.RoleHistoryType;

/* eslint-enable */

@Component({
  selector: 'app-role-edit',
  templateUrl: './role-detail.component.html',
  styleUrls: ['./role-detail.component.scss']
})
export class RoleDetailComponent implements OnInit {
  Role = Role;

  @ViewChild('historyComponent')
  historyComponent: HistoryLogComponent;

  @ViewChild('historyDetailModal', { static: true })
  historyDetailModal: HistoryDetailDialogComponent;

  model: RoleCreateModel = new RoleCreateModel();
  roleId: number;
  selectedPermissionTree: Tree = new Tree([]);

  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  rightModel: RightModel = RightModel.empty();

  // History

  historyList: RoleHistoryModel[] = [];

  historyTypes: RoleHistoryTypeItem[];

  historyQueryModel: QueryFieldModel<Role.OrderField> = new QueryFieldModel(Role.OrderField.HISTORY_CREATION_TIME, OrderType.DESC);

  constructor(
    private roleService: RoleService,
    private translateService: TranslateService,
    private uiRouter: UIRouter,
    private transition: Transition,
    private rightService: RightService,
  ) {
    this.roleId = this.transition.params().id;

  }

  ngOnInit() {
    this.loadRightModels(() => {
      this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
        (result: string) => {
          this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
        }
      );
      this.translateService.get('ROLE_LIST_HEADING').subscribe(
        (result: string) => {
          this.breadcrumbParents.push({name: result, uiSref: StateName.ROLE_LIST});
        }
      );
      this.loadModel();
    })
  }

  private loadRightModels(completion: () => void) {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        completion();
      }
    );
  }

  private loadModel() {
    this.roleService.get({
      id: this.roleId
    }).subscribe(
      (result: Role.Role) => {
        this.breadcrumbSelf = result.name;
        this.model.name = result.name;
        this.selectedPermissionTree = new Tree(this.processArray(result.privileges));
        this.selectedPermissionTree.sort();
        if (this.rightModel.roleHistoryLog.hasRight()) {
          this.loadHistoryTypes(() => {
            this.loadHistoryList()
          });
        }
      });
  }

  private processArray(list: string[]): Node[] {
    const tree: Node[] = [];
    list.forEach((i) => {
      const first: string[] = i.split('::');
      const descendants: string[] = first[0].split(':');
      if (first[1]) {
        descendants.push(first[1]);
      }
      const item: string = descendants.shift()!;
      TreeUtils.treePut(tree, [], item, descendants, i);
    });
    return TreeUtils.convertArray(tree);
  }

  loadHistoryList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.historyQueryModel.currentPage;
    const order = this.historyQueryModel.getOrder();
    this.roleService.history({
      roleId: this.roleId,
      withRead: true!,
      orders: Set.of(order),
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.historyQueryModel.itemsPerPage
      } : undefined
    }).subscribe((result: QueryResult<Role.HistoryItem>) => {
      this.historyList = [];
      result.items.forEach((roleHistory: Role.HistoryItem) => {
        const roleHistoryModel = new RoleHistoryModel();
        roleHistoryModel.id = roleHistory.id;
        roleHistoryModel.creationTime = roleHistory.creationTime.toUtcIsoString();
        roleHistoryModel.type = roleHistory.type;
        roleHistoryModel.typeString = this.getHistoryType(roleHistory.type);
        roleHistoryModel.changeLog = roleHistory.changeLog;
        roleHistoryModel.user = roleHistory.issuerUser!;
        roleHistoryModel.applicationClassification =
          'HISTORY_APPLICATION_CLASS_TYPE_' + roleHistory.applicationClassification;
        roleHistoryModel.applicationId =
          roleHistory.mobileApplication ? roleHistory.mobileApplication.applicationId : '';
        this.historyList.push(roleHistoryModel);
      });
      this.historyQueryModel.currentPage = requestedPage;
      this.historyQueryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.historyQueryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
    });
  }

  getHistoryType(selectedType: RoleHistoryType): string {
    let text = '';
    this.historyTypes.forEach( (type: RoleHistoryTypeItem) => {
      if (type.id === selectedType) {
        text = type.text;
      }
    });
    return text;
  }

  private loadHistoryTypes(completion: () => void) {
    this.historyTypes = [];
    Role.roleHistoryTypes.forEach((type) => {
      const item = {
        id: type.type,
        text: '....'
      };
      this.historyTypes.push(item);
      this.translateService.get(type.stringKey).subscribe((text: string) => {
        item.text = text;
      })
    });
    completion();
  }

  orderBy(field: Role.OrderField) {
    this.historyQueryModel.onOrderFieldChanged(field);
    this.loadHistoryList(1);
  }

  pageChanged(selectedPage: number) {
    this.loadHistoryList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.historyQueryModel.itemsPerPage = itemsPerPage;
    this.loadHistoryList(1);
  }

}

class RoleHistoryModel extends HistoryBaseModel {
  type: RoleHistoryType;
}

export class RoleHistoryTypeItem extends OptionItem<RoleHistoryType> {
}
