/* eslint-disable */
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { StateName } from '../../../app.state-names';
import { StockLogListType } from '../stock-log-list/stock-log-list.component';
/* eslint-enable */

@Component({
  selector: 'app-stock-log-list-container',
  templateUrl: 'stock-log-list-container.component.html',
  styleUrls: ['stock-log-list-container.component.scss']
})
export class StockLogListContainerComponent implements OnInit {
  StockLogListType = StockLogListType;

  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  ngOnInit() {
    this.translateService.get('STOCK_RECORD_LOG_PANEL_HEADING_LIST').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_STOCK').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.STOCK_LIST});
      }
    );
  }

  constructor(private translateService: TranslateService) {}

}
