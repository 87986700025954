import { Component, Input, OnInit } from '@angular/core';
import { Form } from '../../lib/form/form.service';
import { FormRecord } from '../../lib/form/form-record.service';
import { DisplayedFormFieldModel, FormRecordSerializer } from '../../util/form/form-utils';

@Component({
  selector: 'app-table-displayed-form-field-cell',
  templateUrl: './table-displayed-form-field-cell.component.html',
  styleUrls: ['./table-displayed-form-field-cell.component.scss']
})
export class TableDisplayedFormFieldCellComponent implements OnInit {


  @Input()
  model: DisplayedFormFieldModel = new DisplayedFormFieldModel();
  constructor() { }

  ngOnInit(): void {

  }

  getTopSelected(): string[] {
    if (!this.model.displayedValues) {
      return [];
    }
    if (this.model.currentSelectedDataCount <= FormRecordSerializer.MAX_DISPLAY_COUNT) {
      return this.model.displayedValues;
    }
    return this.model.displayedValues.slice(0, FormRecordSerializer.MAX_DISPLAY_COUNT);
  }

  getMoreSelected(): string[] {
    if (!this.model.displayedValues) {
      return [];
    }
    if (this.model.currentSelectedDataCount <= FormRecordSerializer.MAX_DISPLAY_COUNT) {
      return [];
    }
    return this.model.displayedValues.slice(FormRecordSerializer.MAX_DISPLAY_COUNT, FormRecordSerializer.MAX_DISPLAY_COUNT * 2);
  }

  getNotSelected(): string[] {
    if (!this.model.notSelectedValues || this.model.notSelectedValues.length === 0) {
      return [];
    }
    if (this.model.currentSelectedDataCount < FormRecordSerializer.MAX_DISPLAY_COUNT) {
      return this.model.notSelectedValues.slice(0, FormRecordSerializer.MAX_DISPLAY_COUNT - this.model.currentSelectedDataCount);
    }
    return [];
  }

  displayMoreSelected(): boolean {
    return this.model.currentSelectedDataCount > FormRecordSerializer.MAX_DISPLAY_COUNT;
  }

  getMoreCount() {
    return this.model.currentSelectedDataCount - FormRecordSerializer.MAX_DISPLAY_COUNT;
  }
  getMoreMoreCount() {
    return this.model.currentSelectedDataCount - (2*FormRecordSerializer.MAX_DISPLAY_COUNT);
  }
}
