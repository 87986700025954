/* eslint-disable */
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { StateName } from '../../../app.state-names';
/* eslint-enable */

@Component({
  selector: 'app-worklog-list-container',
  templateUrl: 'worklog-list-container.component.html',
  styleUrls: ['worklog-list-container.component.scss']
})
export class WorklogListContainerComponent implements OnInit {

  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  ngOnInit() {
    this.translateService.get('WORKLOG_PANEL_HEADING_LIST').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
  }

  constructor(private translateService: TranslateService) {}

}
