<div class="card">

  <div class="card-header">
    {{'COMMON_DOCUMENTS' | translate}}
    <div class="card-actions">
      <a class="btn-setting cursor-pointer" (click)="toggleSearch()" title="{{'COMMON_BUTTON_SEARCH' | translate}}"><i
        class="icomoon icomoon-search"></i></a>
    </div><!-- /.card-actions -->
  </div>
  <div class="card-body border-bottom" *ngIf="showSearch">
    <div class="row form-group">
      <div class="col-md-3" *ngIf="false">
        <label class="search-label">{{'TRANSPORT_DOCUMENT_SHIPMENT' | translate}}</label>
        <angular2-multiselect [data]="shipmentsForSearch" [settings]="dropdownSettingsForShipment"
                              class="form-control"
                              [(ngModel)]="searchModel.shipmentIds"
                              #shipment="ngModel" name="shipment" id="shipment">
        </angular2-multiselect>
      </div>
      <div class="col-md-3">
        <label class="search-label">{{'TRANSPORT_DOCUMENT_TYPES' | translate}}</label>
        <angular2-multiselect [data]="documentTypesForSearch" [settings]="dropdownSettingsForDocumentType"
                              class="form-control"
                              [(ngModel)]="searchModel.documentTypeIds"
                              #documentType="ngModel" name="documentType" id="documentType">
        </angular2-multiselect>
      </div>
    </div>
    <div class="col-md-12 d-flex justify-content-md-end align-items-end px-0">
      <div class="btn-group" role="group">
        <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
          {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
        </button>
        <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
          {{'COMMON_BUTTON_SEARCH' | translate}}
        </button>
      </div>
    </div>
  </div>
  <div class="card-body">

    <table class="table table-striped table-bordered">
      <thead>
      <tr>
        <th class="table-sorter-header hidden-xs-down">
          <app-table-sorter-no-op [text]="'TRANSPORT_DOCUMENT_SERIAL' | translate">
          </app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header hidden-sm-down">
          <app-table-field-sorter
            [orderField]="TransportDocument.OrderField.TYPE"
            [orderType]="queryModel.getOrderType(TransportDocument.OrderField.TYPE)"
            (orderChange)="orderBy($event)"
            [text]="'COMMON_TYPE' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header hidden-md-down">
          <app-table-sorter-no-op [text]="'TRANSPORT_DOCUMENT_SHIPMENT_EXTERNAL_ID' | translate">
          </app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header">
          <app-table-field-sorter
            [orderField]="TransportDocument.OrderField.SHIPMENT_DELIVERY_NOTE"
            [orderType]="queryModel.getOrderType(TransportDocument.OrderField.SHIPMENT_DELIVERY_NOTE)"
            (orderChange)="orderBy($event)"
            [text]="'TRANSPORT_SHIPMENT_DELIVERY_NOTE_NUMBER' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header hidden-md-down">
          <app-table-field-sorter
            [orderField]="TransportDocument.OrderField.CREATION_TIME"
            [orderType]="queryModel.getOrderType(TransportDocument.OrderField.CREATION_TIME)"
            (orderChange)="orderBy($event)"
            [text]="'TRANSPORT_TIME' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header w-table-actions-single">
          <app-table-sorter-no-op [text]=""></app-table-sorter-no-op>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let transportDocument of transportDocumentList | paginate: { id: pagingId,
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }">
        <td class="responsive-table-column hidden-xs-down">{{transportDocument.serial}}</td>
        <td class="responsive-table-column hidden-sm-down">{{transportDocument.name | translate}}</td>
        <td class="responsive-table-column hidden-md-down">{{transportDocument.shipmentExternalId}}</td>
        <td class="responsive-table-column">{{transportDocument.shipmentDeliveryNoteNumber}}</td>
        <td class="responsive-table-column hidden-md-down">{{(transportDocument.creationTime.toUtcIsoString() | date:'short') || ''}}</td>
        <td>
          <a class="icons-table-group cursor-pointer"
             (click)="download(transportDocument.transportId, transportDocument.id, transportDocument.shipmentDeliveryNoteNumber)">
            <i class="icomoon icons-table-item icomoon-download icomoon-modify-table"
               title="{{'COMMON_BUTTON_DOWNLOAD' | translate}}"></i>
          </a>
        </td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <th class="table-sorter-header hidden-xs-down">
          <app-table-sorter-no-op [text]="'TRANSPORT_DOCUMENT_SERIAL' | translate">
          </app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header hidden-sm-down">
          <app-table-field-sorter
            [orderField]="TransportDocument.OrderField.TYPE"
            [orderType]="queryModel.getOrderType(TransportDocument.OrderField.TYPE)"
            (orderChange)="orderBy($event)"
            [text]="'COMMON_TYPE' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header hidden-md-down">
          <app-table-sorter-no-op [text]="'TRANSPORT_DOCUMENT_SHIPMENT_EXTERNAL_ID' | translate">
          </app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header">
          <app-table-field-sorter
            [orderField]="TransportDocument.OrderField.SHIPMENT_DELIVERY_NOTE"
            [orderType]="queryModel.getOrderType(TransportDocument.OrderField.SHIPMENT_DELIVERY_NOTE)"
            (orderChange)="orderBy($event)"
            [text]="'TRANSPORT_SHIPMENT_DELIVERY_NOTE_NUMBER' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header hidden-md-down">
          <app-table-field-sorter
            [orderField]="TransportDocument.OrderField.CREATION_TIME"
            [orderType]="queryModel.getOrderType(TransportDocument.OrderField.CREATION_TIME)"
            (orderChange)="orderBy($event)"
            [text]="'TRANSPORT_TIME' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header w-table-actions-single">
          <app-table-sorter-no-op [text]=""></app-table-sorter-no-op>
        </th>
      </tr>
      </tfoot>
    </table>
    <app-table-paging
      [id]="pagingId"
      [currentNumberOfItems]="queryModel.currentNumberOfItems"
      [totalNumberOfItems]="queryModel.totalNumberOfItems"
      [itemsPerPage]="queryModel.itemsPerPage"
      (pageChange)="pageChanged($event)"
      (itemsPerPageChange)="itemsPerPageChanged($event)">
    </app-table-paging>
  </div><!-- /.card-body -->

</div><!-- /.card -->
