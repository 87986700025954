/* eslint-disable */
import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {Transition, UIRouter} from '@uirouter/angular';
import {
  LdapGroup,
  UserGroup,
  UserGroupHistoryType,
  userGroupHistoryTypes,
  UserGroupService
} from '../../../lib/user-group.service';
import {
  ApplicationTypeItem,
  CompanyItem,
  OptionItem,
  QueryFieldModel,
  RoleItem,
  UserGroupEditModel,
  UserItem,
} from '../../../util/core-utils';
import {StateName} from '../../../app.state-names';
import {ApplicationType, RootCoreService} from '../../../lib/root-core.service';
import {Arrays} from '../../../lib/util/arrays';
import {TranslateService} from '@ngx-translate/core';
import {HistoryItemResource, User, UserItemResource, UserService,} from '../../../lib/user.service';
import {Set} from 'immutable';
import {BreadcrumbParent} from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import {Role, RoleService} from '../../../lib/role/role.service';
import {Company, CompanyService} from '../../../lib/company/company.service';
import {OrderType, QueryResult, ResourceQueryResult, Services} from '../../../lib/util/services';
import {RightModel} from '../../../app.rights';
import {UserProfile} from '../../../lib/auth.service';
import {RightResolver, RightService} from '../../../lib/right.service';
import {HistoryDetailDialogComponent} from '../../history-log/history-detail-dialog/history-detail-dialog.component';
import {HistoryBaseModel, HistoryLogComponent} from '../../history-log/history-log/history-log.component';
import {Angular2Multiselects} from '../../../util/multiselect';
import Keys = UserGroup.Keys;

/* eslint-enable */

@Component({
  selector: 'app-usergroup-edit',
  templateUrl: 'usergroup-detail.component.html',
  styleUrls: ['usergroup-detail.component.scss']
})
export class UsergroupDetailComponent implements OnInit, AfterViewInit {

  UserGroup = UserGroup;

  @ViewChild('historyComponent')
  historyComponent: HistoryLogComponent;

  @ViewChild('historyDetailModal', {static: true})
  historyDetailModal: HistoryDetailDialogComponent;


  userGroupId: number;
  protectedGroup: boolean = true;
  model: UserGroupEditModel = new UserGroupEditModel();
  mappedLdapGroupsText: string = '';

  rightModel: RightModel = RightModel.empty();
  userProfile: UserProfile;

  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  singleDropdownSettings: Angular2Multiselects.Settings;
  multiDropdownSettings: Angular2Multiselects.Settings;

  // History

  historyList: UserGroupHistoryModel[] = [];

  historyTypes: UserGroupHistoryTypeItem[];

  historyQueryModel: QueryFieldModel<UserGroup.OrderField> =
    new QueryFieldModel(UserGroup.OrderField.HISTORY_CREATION_TIME, OrderType.DESC);

  constructor(
    private translateService: TranslateService,
    private rootCoreService: RootCoreService,
    private userService: UserService,
    private userGroupService: UserGroupService,
    private uiRouter: UIRouter,
    private transition: Transition,
    private roleService: RoleService,
    private companyService: CompanyService,
    private rightService: RightService) {
    this.userGroupId = this.transition.params().id;
  }

  ngOnInit(): void {
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_USER_GROUPS').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.USER_GROUP_LIST});
      }
    );
    this.initDropdowns();
  }

  ngAfterViewInit(): void {
    this.loadRightModels(() => {
      this.loadModel();
    });
  }

  private initDropdowns() {
    this.singleDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .labelKey(OptionItem.KEY_TEXT)
      .build();
    this.multiDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .labelKey(OptionItem.KEY_TEXT)
      .build();
  }

  private loadModel() {
    this.userGroupService.get({
      id: this.userGroupId
    })
      .subscribe(
        (userGroup: UserGroup) => {
          this.breadcrumbSelf = userGroup.name;
          this.model.name = userGroup.name;
          this.model.externalId = userGroup.external_id;
          this.protectedGroup = userGroup.protected_group;
          if (this.rightModel.appTypeRead.hasRight()) {
            this.loadScopes(userGroup.application_types);
          }
          if (userGroup.ldap_mapped !== true && this.rightModel.userRead.hasRight()) {
            this.loadUsers(userGroup.user_ids);
          }
          if (this.rightModel.roleRead.hasRight()) {
            this.loadRoles(userGroup.roles, () => {
              if (this.rightModel.companyRead.hasRight() && userGroup.company_ids) {
                this.loadCompanies(userGroup.company_ids);
              }
            });
          }
          if (this.rightModel.userGroupHistoryLog.hasRight()) {
            this.loadHistoryTypes(() => {
              this.loadHistoryList();
            });
          }
          if (userGroup.ldap_mapped && this.rightModel.userGroupLdapMapRead.hasRight()) {
            this.loadMappedLdapGroups();
          }
        },
        (error: any) => {
          // Ignored. The interceptor handles the global errors.
        }
      );
  }

  private loadRightModels(completion: () => void) {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        this.userProfile = resolver.userProfile!;
        completion();
      }
    );
  }

  private loadRoles(roles: number[], completion: () => void) {
    this.roleService.query({
      id: Set.of(...roles),
      orders: Set.of({field: Role.OrderField.NAME, type: OrderType.ASC})
    }).subscribe(
      (result: QueryResult<Role.Role>) => {
        this.model.roles = [];
        result.items.forEach((role: Role.Role) => {
          const item = new RoleItem();
          item.id = role.id;
          item.text = role.name;
          item.disabled = role.disabled;
          this.model.roles.push(item);
        });
        completion();
      },
      (error: any) => {
        // Ignored. The interceptor handles the global errors.
      }
    );
  }

  private loadCompanies(companyIds?: number[]) {
    if (companyIds && companyIds.length > 0) {
      this.companyService.query({
        id: Set.of(...companyIds)
      }).subscribe(
        (result: QueryResult<Company.Company>) => {
          this.model.companies = [];
          result.items.forEach(company => {
            const item = new CompanyItem();
            item.id = company!.id;
            item.text = company!.name;
            item.disabled = company!.disabled;
            item.allowedRoleIds = company!.allowedRoleIds!;
            this.model.companies.push(item);
          });
        });
    }
  }

  private loadScopes(scopeKeys: string[]) {
    this.rootCoreService.getApplicationTypes({})
      .subscribe(
        (applicationTypes: ApplicationType[]) => {
          this.model.application_types = [];
          Arrays.iterateByIndex(applicationTypes, (applicationType) => {
            const item = new ApplicationTypeItem();
            item.id = applicationType.key;
            item.text = applicationType.name;
            if (scopeKeys.indexOf(applicationType.key) !== -1) {
              this.model.application_types.push(item);
            }
          });
        },
        (error: any) => {
          // Ignored. The interceptor handles the global errors.
        }
      );
  }

  private loadUsers(ids: number[]) {
    if (ids.length > 0) {
      this.userService.query({
        id: ids.join(','),
        order: Services.createOrderFieldParameter(User.Keys.toOrderFieldKey,
          Set.of({field: User.OrderField.PERSON_NAME, type: OrderType.ASC}))
      }).subscribe(
        (result: ResourceQueryResult<User>) => {
          this.model.users = [];
          Arrays.iterateByIndex(result.items, (user) => {
            const item = this.toUserItem(user);
            this.model.users.push(item);
          });
        }
      );
    }
  }

  private loadMappedLdapGroups() {
    this.userGroupService.getMappedLdapGroups({
      id: this.userGroupId
    }).subscribe((groups: LdapGroup[]) => {
      this.mappedLdapGroupsText = groups.map(group => group.natural_id).join(', ');
    });
  }

  get companyId(): number | undefined {
    if (this.model.companies[0] && this.model.companies[0].id !== null) {
      return this.model.companies[0].id!;
    }
    return undefined;
  }

  back() {
    window.history.back();
  }

  private toUserItem(user: User) {
    const item = new UserItem();
    item.id = user.id;
    item.text = user.user_name;
    item.disabled = user.disabled;
    return item;
  }

  loadHistoryList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.historyQueryModel.currentPage;
    const order = this.historyQueryModel.getOrder();
    this.userGroupService.history({
      user_group_id: this.userGroupId,
      with_read: true,
      order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, Set.of(order)),
      page_number: requestedPage ? requestedPage : undefined,
      number_of_items: this.historyQueryModel.itemsPerPage ? this.historyQueryModel.itemsPerPage : undefined
    }).subscribe((result: ResourceQueryResult<HistoryItemResource>) => {
      this.historyList = [];
      result.items.forEach((userHistory: HistoryItemResource) => {
        const userGroupHistoryModel = new UserGroupHistoryModel();
        userGroupHistoryModel.id = userHistory.id;
        userGroupHistoryModel.creationTime = Services.toOffsetDateTime(userHistory.creation_time).toUtcIsoString();
        userGroupHistoryModel.type = <UserGroupHistoryType>userHistory.type;
        userGroupHistoryModel.typeString = this.getHistoryType(<UserGroupHistoryType>userHistory.type);
        userGroupHistoryModel.changeLog = userHistory.change_log;
        userGroupHistoryModel.user = this.toPublicUser(userHistory.issuer_user!);
        userGroupHistoryModel.applicationClassification =
          'HISTORY_APPLICATION_CLASS_TYPE_' + userHistory.application_classification;
        userGroupHistoryModel.applicationId = userHistory.mobile_application ? userHistory.mobile_application.application_id : '';
        this.historyList.push(userGroupHistoryModel);
      });
      this.historyQueryModel.currentPage = requestedPage;
      this.historyQueryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.historyQueryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
    });
  }

  getHistoryType(selectedType: UserGroupHistoryType): string {
    let text = '';
    this.historyTypes.forEach((type: UserGroupHistoryTypeItem) => {
      if (type.id === selectedType) {
        text = type.text;
      }
    });
    return text;
  }

  private loadHistoryTypes(completion: () => void) {
    this.historyTypes = [];
    userGroupHistoryTypes.forEach((type) => {
      const item = {
        id: type.type,
        text: '....'
      };
      this.historyTypes.push(item);
      this.translateService.get(type.stringKey).subscribe((text: string) => {
        item.text = text;
      });
    });
    completion();
  }

  private toPublicUser(r: UserItemResource): UserItemFromService {
    return {
      id: r!.id,
      personName: r!.person_name
    };
  }

  orderBy(field: UserGroup.OrderField) {
    this.historyQueryModel.onOrderFieldChanged(field);
    this.loadHistoryList(1);
  }

  pageChanged(selectedPage: number) {
    this.loadHistoryList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.historyQueryModel.itemsPerPage = itemsPerPage;
    this.loadHistoryList(1);
  }

  navigateToRoleDetail(item: RoleItem) {
    this.uiRouter.stateService.go(StateName.ROLE_DETAIL,
      {id: item.id});
  }

  navigateToUserDetail(item: UserItem) {
    this.uiRouter.stateService.go(StateName.USER_DETAIL,
      {id: item.id});
  }

  navigateToCompanyDetail(item: CompanyItem) {
    this.uiRouter.stateService.go(StateName.COMPANY_DETAIL,
      {id: item.id});
  }

}

class UserGroupHistoryModel extends HistoryBaseModel {
  type: UserGroupHistoryType;
}

export class UserGroupHistoryTypeItem extends OptionItem<UserGroupHistoryType> {
}

export interface UserItemFromService {
  id: number;
  personName: string;
}
