import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { SearchUtils } from '../../../util/search-utils';
import { MultiselectOptionItem, OrderFieldFunction, OrderFieldModel, SelectUtils, UiConstants } from '../../../util/core-utils';
import { OrderField } from '../../../lib/query/orderfields';
import { OrderType, QueryResult } from '../../../lib/util/services';
import { Angular2Multiselects } from '../../../util/multiselect';
import { RightModel } from '../../../app.rights';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { TaskRecordMultiselectProvider } from '../../../lib/task/task-record-multiselect.provider';
import { VehicleMultiselectProvider } from '../../../lib/vehicles/vehicle-multiselect.provider';
import { UserMultiselectProvider } from '../../../lib/user/user-multiselect.provider';
import { CompanyService } from '../../../lib/company/company.service';
import { RightResolver, RightService } from '../../../lib/right.service';
import { StateName } from '../../../app.state-names';
import { FilterField } from '../../../lib/query/filterfields';
import { CriteriaBuilder } from '../../../util/model-utils';
import {
  OrderImportErrorSearch,
  OrderImportErrorSearchService
} from '../../../lib/order-import-error/order-import-error-search.service';
import { OrderImportError, OrderImportErrorService } from '../../../lib/order-import-error/order-import-error.service';
import { OrderImportErrorModel } from '../order-import-error.model';
import { CompanyMultiselectProvider } from '../../../lib/company/company-multiselect.provider';
import { ToasterService } from '../../../fork/angular2-toaster/src/toaster.service';
import { StringKey } from '../../../app.string-keys';
import { AlertType } from '../../../shared/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-order-import-error-list',
  templateUrl: './order-import-error-list.component.html',
  styleUrls: ['./order-import-error-list.component.scss']
})
export class OrderImportErrorListComponent extends SearchUtils.SearchableList<OrderImportErrorSearch.Model> implements OnInit, OnDestroy {

  UiConstants = UiConstants;
  SelectUtils = SelectUtils;
  OrderImportErrorSearch = OrderImportErrorSearch;
  AlertType = AlertType;

  queryModel: OrderFieldModel<OrderField.OrderImportError>
    = new OrderFieldModel(OrderImportErrorSearch.OrderFunctions.ID, OrderType.DESC);
  searchModel: OrderImportErrorSearch.Model = new OrderImportErrorSearch.Model();

  users: MultiselectOptionItem<number>[] = [];
  companies: MultiselectOptionItem<number>[] = [];
  realms: MultiselectOptionItem<OrderImportError.OrderImportErrorRealm>[];
  states: MultiselectOptionItem<OrderImportError.OrderImportErrorState>[];
  errorReasons: MultiselectOptionItem<OrderImportError.OrderImportErrorReason>[];

  localDropdownSettings: Angular2Multiselects.Settings;
  remoteDropdownSettings: Angular2Multiselects.Settings;

  orderImportErrorList: OrderImportErrorModel[] = [];
  rightModel: RightModel = RightModel.empty();

  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  constructor(
    private orderImportErrorService: OrderImportErrorService,
    private orderImportErrorSearchService: OrderImportErrorSearchService,
    private taskRecordMultiselectProvider: TaskRecordMultiselectProvider,
    private vehicleMultiselectProvider: VehicleMultiselectProvider,
    private userMultiselectProvider: UserMultiselectProvider,
    private companyMultiselectProvider: CompanyMultiselectProvider,
    private companyService: CompanyService,
    private rightService: RightService,
    private toasterService: ToasterService,
    injector: Injector) {
    super(OrderImportErrorSearch.Model, injector);
  }

  ngOnInit() {
    this.loadRightModels();
    this.initBreadcrumb();
    this.initSearch();
    this.initDropdownSettings();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  initBreadcrumb() {
    this.translateService.get('ORDER_IMPORT_ERROR_LIST').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
  }

  initDropdownSettings() {
    this.remoteDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .remoteSearch(true)
      .enableCheckAll(false)
      .build();
    this.localDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .remoteSearch(false)
      .enableCheckAll(false)
      .build();
  }

  loadSearch(completion: () => void) {
    this.orderImportErrorSearchService.getSearchData({})
      .subscribe(
        (result: OrderImportErrorSearch.SearchDataResult) => {
          this.queryModel = result.searchData.queryModel;
          this.searchModel = result.searchData.searchModel;
          completion();
        }
      );
  }

  onFirstSearchOpen(): void {
    this.onUserSearch();
    this.onCompanySearch();
    this.loadRealms();
    this.loadStates();
    this.loadErrorReasons();
  }

  onUserSearch(predicate?: string) {
    this.userMultiselectProvider.loadActive(predicate).subscribe((items) => {
      this.users = items;
    })
  }

  onCompanySearch(predicate?: string) {
    this.companyMultiselectProvider.loadAll(predicate).subscribe((items) => {
      this.companies = items;
    })
  }

  private loadRealms() {
    this.realms = [];
    OrderImportError.orderImportErrorRealms.forEach(item => {
      this.translateService.get(item.stringKey).subscribe(
        (result: string) => {
          const optionItem = new MultiselectOptionItem<OrderImportError.OrderImportErrorRealm>();
          optionItem.id = item.realm;
          optionItem.itemName = result;
          this.realms.push(optionItem);
        }
      );
    })
  }

  private loadStates() {
    this.states = [];
    OrderImportError.orderImportErrorStates.forEach(item => {
      this.translateService.get(item.stringKey).subscribe(
        (result: string) => {
          const optionItem = new MultiselectOptionItem<OrderImportError.OrderImportErrorState>();
          optionItem.id = item.state;
          optionItem.itemName = result;
          this.states.push(optionItem);
        }
      );
    })
  }

  private loadErrorReasons() {
    this.errorReasons = [];
    OrderImportError.orderImportErrorReasons.forEach(item => {
      this.translateService.get(item.stringKey).subscribe(
        (result: string) => {
          const optionItem = new MultiselectOptionItem<OrderImportError.OrderImportErrorReason>();
          optionItem.id = item.reason;
          optionItem.itemName = result;
          this.errorReasons.push(optionItem);
        }
      );
    })
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: OrderFieldFunction<OrderField.OrderImportError>) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.createOrderFunction();
    const filter = this.createFilter();
    this.orderImportErrorService.query({
      fields: f => f.each,
      order: order,
      filter: filter,
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined,
    }).subscribe((result: QueryResult<OrderImportError.OrderImportError>) => {
      this.orderImportErrorList = [];
      result.items.forEach((orderImportError: OrderImportError.OrderImportError) => {
        const model = new OrderImportErrorModel();
        model.id = orderImportError.id;
        model.companyName = orderImportError.company.name;
        model.userName = orderImportError.user.personName;
        model.externalId1 = orderImportError.externalId1;
        model.externalId2 = orderImportError.externalId2 ? orderImportError.externalId2 : '';
        model.extraId1 = orderImportError.extraId1 ? orderImportError.extraId1 : '';
        model.partnerNameStringKey =
          OrderImportError.orderImportErrorRealms.find(o => o.realm === orderImportError.realm)!.stringKey;
        model.importStateObject = OrderImportError.orderImportErrorStates.find(o => o.state === orderImportError.state)!;
        model.creationTime = orderImportError.creationTime;
        model.recipientName = orderImportError.recipientName ? orderImportError.recipientName : '';
        model.errorReason = OrderImportError.orderImportErrorReasons.find(o => o.reason === orderImportError.errorReason)!;
        model.errorMessage = orderImportError.errorMessage ? orderImportError.errorMessage : '';
        this.orderImportErrorList.push(model);
      });
      this.queryModel.currentPage = requestedPage;
      this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
    });
  }

  private createFilter() {
    return (f: FilterField.OrderImportError) => CriteriaBuilder.builder()
      .addString((orderCode) => f.externalId1.containsIgnoreCase(orderCode)
        .or(f.externalId2.containsIgnoreCase(orderCode)
        .or(f.extraId1.containsIgnoreCase(orderCode))),
        this.searchModel.orderCode)
      .addNumber((companyId) => f.company.id.eq(companyId), this.searchModel.companyId)
      .addNumber((userId) => f.user.id.eq(userId), this.searchModel.userId)
      .addEnum((realmId) => f.realm.eq(realmId), this.searchModel.realmId)
      .addEnum((stateId) => f.state.eq(stateId), this.searchModel.stateId)
      .addEnum((errorReasonId) => f.errorReason.eq(errorReasonId), this.searchModel.errorReasonId)
      .build();
  }

  onSearchClicked() {
    this.loadList(1);
  }

  onSearchReset() {
    this.orderImportErrorSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.toggleSearch();
          this.loadList(1);
        });
      }
    );
  }

  rejectOrderManually(id: number) {
    this.orderImportErrorService.rejectManually({id: id}).subscribe(() => {
      this.loadList();
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutShort,
        type: UiConstants.toastTypeSuccess,
        title: this.translateService.instant(StringKey.ORDER_IMPORT_ERROR_SUCCESSFUL_REJECT)
      });
    })
  }

  ngOnDestroy() {
    this.saveSearch();
  }

  private saveSearch() {
    const request = {
        searchData: {
          queryModel: this.queryModel,
          searchModel: this.searchModel
        }
      }
    ;
    this.orderImportErrorSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

}
