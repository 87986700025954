import { Injectable } from '@angular/core';
import { AndroidAppVersionResource, AndroidAppVersionResourceService } from './android-app-version-resource.service';
import { Observable, throwError } from 'rxjs';
import { FieldValidationError, Order, PagingRequest, QueryResult, ResourceQueryResult, Services } from '../util/services';
import { List, Map as ImmutableMap, Set } from 'immutable';
import { ObservableErrorResourceParser } from '../util/errors';
import { EmptyMessage, IdentityMessage } from '../util/messages';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class AndroidAppVersionService implements AndroidAppVersion.Service {

  constructor(private resourceService: AndroidAppVersionResourceService) {
  }

  query(request: AndroidAppVersion.QueryRequest): Observable<QueryResult<AndroidAppVersion.AndroidAppVersion>> {
    const resourceRequest = AndroidAppVersion.toResourceQueryRequest(request);
    return this.resourceService.query(resourceRequest).pipe(
      map((result: ResourceQueryResult<AndroidAppVersionResource.AndroidAppVersion>) => {
        return {
          items: List.of(...result.items.map((item) => {
            return AndroidAppVersion.toPublic(item);
          })),
          pagingResult: result.pagingResult
        };
      }),
      catchError(err => {
        return throwError(this.translateError(err));
      })
    );
  }

  create(request: AndroidAppVersion.CreateRequest): Observable<IdentityMessage> {
    const resourceRequest = AndroidAppVersion.toResourceCreateRequest(request);
    return this.resourceService.create(resourceRequest).pipe(catchError(err => {
        return throwError(this.translateError(err));
      })
    );
  }

  update(request: AndroidAppVersion.UpdateRequest): Observable<EmptyMessage> {
    const resourceRequest = AndroidAppVersion.toResourceUpdateRequest(request);
    return this.resourceService.update(resourceRequest).pipe(catchError(err => {
        return throwError(this.translateError(err));
      })
    );
  }

  delete(request: IdentityMessage): Observable<EmptyMessage> {
    return this.resourceService.delete(request)
      .pipe(catchError(err => {
          return throwError(this.translateError(err));
        })
      );
  }

  updateSettings(request: AndroidAppVersion.VersionSettingsRequest): Observable<EmptyMessage> {
    const resourceRequest = AndroidAppVersion.toResourceVersionSettingsRequest(request);
    return this.resourceService.updateSettings(resourceRequest).pipe(catchError(err => {
        return throwError(this.translateError(err));
      })
    );
  }

  getSettings(): Observable<AndroidAppVersion.VersionSettings> {
    return this.resourceService.getSettings().pipe(
      map(result => AndroidAppVersion.toPublicVersionSettings(result)),
      catchError(err => {
        return throwError(this.translateError(err));
      })
    );
  }

  private translateError(error: any): any {
    const res = ObservableErrorResourceParser.parseError(error);
    const fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
    const fieldErrorMap = ObservableErrorResourceParser.toFieldErrorMap(Keys.toValidatedField, fieldErrors);
    if (!fieldErrorMap.isEmpty()) {
      return FieldValidationError.of(fieldErrorMap);
    }
    return error;
  }
}

export namespace AndroidAppVersion {

  export interface Service {
    query(request: AndroidAppVersion.QueryRequest): Observable<QueryResult<AndroidAppVersion.AndroidAppVersion>>;
    create(request: AndroidAppVersion.CreateRequest): Observable<IdentityMessage>;
    update(request: AndroidAppVersion.UpdateRequest): Observable<EmptyMessage>;
    delete(request: IdentityMessage): Observable<EmptyMessage>;
    updateSettings(request: AndroidAppVersion.VersionSettings): Observable<EmptyMessage>;
    getSettings(): Observable<AndroidAppVersion.VersionSettings>;
  }

  export interface AndroidAppVersion {
    id: number;
    versionCode: number;
    versionName: string;
    buildSlug: string;
    changeLog: string;
    artifactUrl?: string;
  }

  export interface CreateRequest {
    versionCode: number;
    versionName: string;
    buildSlug: string;
    changeLog: string;
  }

  export interface UpdateRequest extends CreateRequest {
    id: number;
  }

  export interface QueryRequest {
    versionName?: string;
    withArtifactUrl?: boolean;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
    noProgressBar?: boolean;
  }

  export interface VersionSettingsRequest {
    autoUpdateEnabled: boolean;
    minimumVersionId?: number;
  }

  export interface VersionSettings {
    autoUpdateEnabled: boolean;
    minimumVersion?: AndroidAppVersion.AndroidAppVersion;
  }

  export enum OrderField {
    ID,
    VERSION_NAME,
    VERSION_CODE,
    BUILD_SLUG,
  }

  export enum ValidatedField {
    VERSION_NAME,
    VERSION_CODE,
    BUILD_SLUG,
    CHANGE_LOG,
    UNKNOWN
  }

  export function toResourceQueryRequest(request: AndroidAppVersion.QueryRequest): AndroidAppVersionResource.QueryRequest {
    return {
      version_name: request.versionName,
      with_artifact_url: false,
      order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
      page_number: request.paging ? request.paging.pageNumber : undefined,
      number_of_items: request.paging ? request.paging.numberOfItems : undefined,
      no_progress_bar: request.noProgressBar

    };
  }

  export function toPublic(r: AndroidAppVersionResource.AndroidAppVersion): AndroidAppVersion.AndroidAppVersion {
    return {
      id: r.id,
      versionCode: r.version_code,
      versionName: r.version_name,
      buildSlug: r.build_slug,
      changeLog: r.change_log,
      artifactUrl: r.artifact_url
    };
  }

  export function toResourceCreateRequest(request: AndroidAppVersion.CreateRequest): AndroidAppVersionResource.CreateRequest {
    return {
      version_code: request.versionCode,
      version_name: request.versionName,
      build_slug: request.buildSlug,
      change_log: request.changeLog
    };
  }

  export function toResourceUpdateRequest(request: AndroidAppVersion.UpdateRequest): AndroidAppVersionResource.UpdateRequest {
    const req: AndroidAppVersionResource.CreateRequest = toResourceCreateRequest(request);
    return {
      ...req,
      id: request.id
    };
  }

  export function toResourceVersionSettingsRequest(request: AndroidAppVersion.VersionSettingsRequest)
    : AndroidAppVersionResource.VersionSettingsRequest {
    return {
      auto_update_enabled: request.autoUpdateEnabled,
      minimum_version_id: request.minimumVersionId
    };
  }

  export function toPublicVersionSettings(r: AndroidAppVersionResource.VersionSettings)
    : AndroidAppVersion.VersionSettings {
    return {
      autoUpdateEnabled: r.auto_update_enabled,
      minimumVersion: r.minimum_version ? toPublic(r.minimum_version) : undefined
    };
  }

}

// <editor-fold desc="Internal">

class Keys {

  private static readonly ID = 'id';
  private static readonly VERSION_NAME = 'version_name';
  private static readonly VERSION_CODE = 'version_code';
  private static readonly BUILD_SLUG = 'build_slug';
  private static readonly CHANGE_LOG = 'change_log';

  private static readonly orderFieldKeyMap: ImmutableMap<AndroidAppVersion.OrderField, string> = ImmutableMap.of(
    AndroidAppVersion.OrderField.ID, Keys.ID,
    AndroidAppVersion.OrderField.VERSION_NAME, Keys.VERSION_NAME,
    AndroidAppVersion.OrderField.VERSION_CODE, Keys.VERSION_CODE,
    AndroidAppVersion.OrderField.BUILD_SLUG, Keys.BUILD_SLUG,
  );

  private static readonly keyValidatedFieldMap: ImmutableMap<string, AndroidAppVersion.ValidatedField> = ImmutableMap.of(
    Keys.VERSION_NAME, AndroidAppVersion.ValidatedField.VERSION_NAME,
    Keys.VERSION_CODE, AndroidAppVersion.ValidatedField.VERSION_CODE,
    Keys.BUILD_SLUG, AndroidAppVersion.ValidatedField.BUILD_SLUG,
    Keys.CHANGE_LOG, AndroidAppVersion.ValidatedField.CHANGE_LOG,
  );

  public static toOrderFieldKey(field: AndroidAppVersion.OrderField): string {
    return Keys.orderFieldKeyMap.get(field)!;
  }

  public static toValidatedField(fieldKey: string): AndroidAppVersion.ValidatedField {
    return Keys.keyValidatedFieldMap.get(fieldKey, AndroidAppVersion.ValidatedField.UNKNOWN);
  }

}

// </editor-fold>
