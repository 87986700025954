<app-loading-spinner *ngIf="loading && !fromRegister"></app-loading-spinner>
<div id="login-container" [ngClass]="{'background-christmas': isSantaOn}">

  <!-- region INTRODUCTION COMPONENT-->
  <div class="background hidden-md-down">
    <app-login-register-introduction
      [titleStringKey]="shopRenterCase ? 'LOGIN_SHOPRENTER_TITLE' : 'COMMON_LOGIN_TITLE'"
      [subtitleStringKey]="shopRenterCase ? 'LOGIN_SHOPRENTER_SUBTITLE' : 'COMMON_LOGIN_SUBTITLE'"
      [loginBackgroundSrc]="loginBackgroundSrc"
      [toRegister]="toRegister">
    </app-login-register-introduction>
  </div>
  <!-- endregion INTRODUCTION COMPONENT-->

  <!-- region LOGIN -->
  <div class="login-form login-out" #loginForm>

    <div class="d-flex justify-content-end">
      <a class="id-text cursor-pointer ml-half"
         *ngIf="isHelpdeskEnabled()"
         href="#" onclick="return false"
         uiSref="HelpdeskLogin"
         [uiOptions]="{custom: {fromBase: true}}"
         title="{{'COMMON_HELPDESK' | translate}}">
        {{'COMMON_HELPDESK' | translate}}
      </a>

      <!-- region LANGUAGE SELECTOR -->
      <ng-container *ngFor="let language of languages">
        <div class="ml-1 cursor-pointer"
             *ngIf="!isCurrentLanguage(language)"
             (click)="changeLanguage(language)"
             title="{{language.nativeName}}">
          <img
            src="../../assets/img/language-code-icon/{{language.localeCode.substr(0,2)}}.png"
            class="language-image">
        </div>
      </ng-container>
    </div>
    <!-- endregion LANGUAGE SELECTOR -->

    <!-- region IMAGES -->
    <div class="w-100 logo-panel d-flex justify-content-center">
      <div id="login-logo" class="mx-44 w-500" *ngIf="!brandLogoLoginSrc"></div>
      <img id="login-asset-logo" class="px-44 w-500" *ngIf="brandLogoLoginSrc" src="{{brandLogoLoginSrc}}"/>
    </div>
    <!-- endregion IMAGES -->

    <!-- region LOGIN FORM -->
    <div class="form-panel d-flex justify-content-center">
      <form class="w-500" name="form" (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate id="login-validation">
        <div class="card-group mb-0">
          <div class="card login-card p-2 border-0">
            <div class="card-body">
              <h2>{{'LOGIN_COMPONENT_TITLE' | translate}}</h2>
              <div *ngIf="getLoginRequiredReason()" class="alert {{getLoginRequiredReason().getAlertType()}} mt-1"
                   role="alert">
                <strong>{{getLoginRequiredReason().getTitleKey() | translate}}</strong>
                <br *ngIf="getLoginRequiredReason().getTitleKey() | translate | nonEmpty">
                {{getLoginRequiredReason().getTextKey() | translate }}
              </div>
              <div *ngIf="loginFail" class="alert alert-danger mt-1" role="alert">
                <strong>{{loginFail.message}}</strong>
                <br *ngIf="loginFail.message.length > 0">
                {{loginFail.description}}
              </div>
              <div *ngIf="!authTokenStorageAvailable" class="alert alert-danger mt-1" role="alert">
                <strong>{{'LOGIN_COMPONENT_LOGIN_NOT_AVAILABLE' | translate}}</strong><br>{{'LOGIN_COMPONENT_ENABLE' | translate}}
              </div>
              <label>{{'LOGIN_COMPONENT_USERNAME' | translate}}</label>
              <div class="form-group  mb-1" [ngClass]="{ 'has-danger': f.submitted && !username.valid }">
                <div class="input-group">
                  <input [(ngModel)]="model.username" name="user" id="user" #username="ngModel" required type="text"
                         autocomplete="username" class="form-control"
                         [ngClass]="{ 'form-control-danger': f.submitted && !username.valid }"
                         placeholder="{{'LOGIN_COMPONENT_USERNAME' | translate}}">
                </div>
                <div *ngIf="f.submitted && !username.valid"
                     class="form-control-feedback">{{'LOGIN_COMPONENT_USERNAME_REQUIRED' | translate}}</div>
              </div>
              <div class="d-flex justify-content-between">
                <label>{{'LOGIN_COMPONENT_PASSWORD' | translate}}</label>
                <a class="id-text cursor-pointer ml-half"
                   href="#" onclick="return false"
                   uiSref="PasswordRequest"
                   tabindex="-1"
                   title="{{'LOGIN_COMPONENT_REQUEST_NEW_PASSWORD' | translate}}">
                  {{'LOGIN_COMPONENT_REQUEST_NEW_PASSWORD' | translate}}
                </a>
              </div>
              <div class="form-group mb-1" [ngClass]="{ 'has-danger': f.submitted && !password.valid }">
                <div class="input-group">
                  <input [(ngModel)]="model.password"
                         name="password"
                         id="password"
                         #password="ngModel"
                         required
                         type="{{passwordVisible ? 'text' : 'password'}}"
                         autocomplete="current-password"
                         class="form-control"
                         [ngClass]="{ 'form-control-danger': f.submitted && !password.valid }"
                         placeholder="{{'LOGIN_COMPONENT_PASSWORD' | translate}}">
                  <span (click)="togglePasswordVisibility()"
                        class="input-group-addon-gray icomoon icomoon-enable cursor-pointer font-2xl"></span>
                </div>
                <div *ngIf="f.submitted && !password.valid"
                     class="form-control-feedback">{{'LOGIN_COMPONENT_PASSWORD_REQUIRED' | translate}}</div>
              </div>
              <div class="form-group">
                <div class="row m-0">
                  <button *ngIf="authTokenStorageAvailable"
                          type="submit" class="col-12 btn btn-primary btn-ladda px-2 mb-1" data-style="zoom-in"
                          [ladda]="loggingIn" [disabled]="loggingIn">
                    {{'LOGIN_COMPONENT_TITLE' | translate}}
                  </button>
                  <div *ngIf="registrationEnabled"
                       class="col-12 text-center">
                    {{'LOGIN_COMPONENT_REGISTRATION_DESCRIPTION' | translate}}
                    <a class="id-text cursor-pointer ml-half"
                       (click)="navigateToRegister()"
                       title="{{'LOGIN_COMPONENT_REGISTRATION' | translate}}">
                      {{'LOGIN_COMPONENT_REGISTRATION' | translate}}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <!-- endregion LOGIN FORM-->

    <div class="login-footer d-flex justify-content-end mt-auto w-100">
      <div class="d-flex flex-wrap justify-content-end">
        <div class="version-text">{{'ADMIN_VERSION' | translate}}: {{getFormattedAppVersion()}}</div>
        <div class="version-text ml-1">{{'SERVER_VERSION' | translate}}: {{getServerVersion()}}</div>
      </div>
    </div>

  </div>
</div>

<div bsModal [config]="UiConstants.modalConfig" #loginMethodSelectDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document" *ngIf="loginMethodSelectDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title"><strong>{{'LOGIN_METHOD' | translate}}</strong></p>
        <button type="button" class="close" (click)="closeLoginMethodSelectDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row m-0 justify-content-center">
          <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 p-0 mx-1"
               *ngFor="let loginMethod of loginMethods">
            <div class="create-card card card-hover-border cursor-pointer fixed-card-height"
                 (click)="loginWithMethod(loginMethod)">
              <div class="card-body">
                <div class="h1 mb-0 d-flex justify-content-center">
                  <i class="icomoon" [ngClass]="loginMethod.getIconClass()"></i>
                </div>
                <div><p class="mb-0 text-center">{{loginMethod.getName()}}</p></div>
              </div>
            </div>
          </div>
        </div>
      </div><!-- /.modal-body -->
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<app-confirm-dialog
  #loginInfoDialog
  [titleEnabled]="false"
  [negativeButtonEnabled]="false"
  [message]="infoMessage"
  [positiveButton]="'COMMON_BUTTON_CONTINUE' | translate"
  (onConfirm)="navigateToHome($event)">
</app-confirm-dialog>

<toaster-container [toasterconfig]="toasterConfig"></toaster-container>
