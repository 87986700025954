/* eslint-disable */
import { Component, OnInit, ViewChild } from '@angular/core';
import { InputMask } from '../../../../util/input-masks';
import { InvoiceSettings, InvoiceSettingsService } from '../../../../lib/invoice/invoice-settings/invoice-settings.service';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { AddressModel } from '../../../../lib/address';
import { Transition, UIRouter } from '@uirouter/angular';
import {
  FieldValidationError,
  FormGroupValidationUtils,
  ForwardingNgFormRef,
  LocalFormGroupValidationErrors,
  QueryResult,
  Services
} from '../../../../lib/util/services';
import { saveAs } from 'file-saver';
import { TranslateService } from '@ngx-translate/core';
import { Country, CountryService } from '../../../../lib/country.service';
import { List } from 'immutable';
import { StateName } from '../../../../app.state-names';
import { RightModel } from '../../../../app.rights';
import { RightResolver, RightService } from '../../../../lib/right.service';
import { BreadcrumbParent } from '../../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { DownloadedFile } from '../../../../lib/util/downloaded-files';
import { UploadErrorLocalizer } from '../../../../util/upload-error-localizer';
import { InvoicePaymentTypeMultiItem } from '../../invoices/invoice-create/invoice-create-clone.model';
import { ToasterService } from '../../../../fork/angular2-toaster/angular2-toaster';
import { MultiselectOptionItem, SelectUtils, UiConstants } from '../../../../util/core-utils';
import { ConfigurationService } from '../../../../lib/core-ext/configuration.service';
import { AppValidators } from '../../../../util/app-validators';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ComponentStateResolver } from '../../../../util/component-state/component-state-resolver';
import { Angular2Multiselects } from '../../../../util/multiselect';
import { StockMultiselectProvider } from '../../../../lib/stock/stock-multiselect.provider';
import { InvoiceSettingsModel } from './invoice-settings-base.model';
import { EmailAddressTypeService } from '../../../../lib/email-address-type/email-address-type.service';
import InvoiceIntegrationType = InvoiceSettings.InvoiceIntegrationType;
import { Models } from '../../../../util/model-utils';

/* eslint-enable */

@Component({
  selector: 'app-invoice-settings-base',
  templateUrl: './invoice-settings-base.component.html',
  styleUrls: ['./invoice-settings-base.component.scss']
})
export class InvoiceSettingsBaseComponent implements OnInit {

  UiConstants = UiConstants;
  InputMask = InputMask;
  InvoiceSettings = InvoiceSettings;
  SelectUtils = SelectUtils;
  InvoiceIntegrationType = InvoiceIntegrationType;

  formGroup: FormGroup;
  private formGroupValidationErrors: LocalFormGroupValidationErrors;

  @ViewChild('f', {static: true})
  fForm: NgForm;

  model: InvoiceSettingsModel;

  countryItems?: List<AddressModel.CountryItem>;

  customerEmailAddressTypes: MultiselectOptionItem<number>[] = [];

  uploadDialogVisible: boolean = false;
  @ViewChild('uploadDialog', {static: true})
  uploadDialog: ModalDirective;

  dropdownSettings: Angular2Multiselects.Settings;
  stockDropdownSettings: Angular2Multiselects.Settings;

  private fieldErrors: FieldValidationError<InvoiceSettings.ValidatedField> =
    FieldValidationError.empty<InvoiceSettings.ValidatedField>();

  invoiceNotificationHtmlEditorConfig = {
    'toolbar': [
      ['bold', 'italic', 'underline', 'strikeThrough'],
      ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'indent', 'outdent'],
    ]
  };

  rightModel: RightModel = RightModel.empty();
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  // Component state resolver, determines the state of the component
  componentState: ComponentStateResolver;

  private uploadUrl: string = '';
  private hadTechnicalUser: boolean = false;
  passwordVisible: boolean = false;
  stocks: MultiselectOptionItem<number>[] = [];

  constructor(
    private fb: FormBuilder,
    private invoiceSettingsService: InvoiceSettingsService,
    private uiRouter: UIRouter,
    private translateService: TranslateService,
    private countryService: CountryService,
    private rightService: RightService,
    private uploadErrorLocalizer: UploadErrorLocalizer,
    private toasterService: ToasterService,
    private transition: Transition,
    private configService: ConfigurationService,
    private stockMultiselectProvider: StockMultiselectProvider,
    private emailAddressTypeService: EmailAddressTypeService,
  ) {
    const invoiceIntegrationType = transition.params().type;
    this.componentState = new ComponentStateResolver(uiRouter, transition,
      'id',
      {stateName: StateName.INVOICE_SETTINGS_CREATE, stateHeaderKey: 'INVOICE_SETTINGS_CREATE'},
      {stateName: StateName.INVOICE_SETTINGS_EDIT, stateHeaderKey: 'INVOICE_SETTINGS_EDIT'},
      {stateName: StateName.INVOICE_SETTINGS_DETAIL, stateHeaderKey: 'INVOICE_SETTINGS_DETAIL'});
    this.uploadUrl = '/invoice/settings/' + transition.params().id + '/template/';
    this.model = new InvoiceSettingsModel(invoiceIntegrationType, configService, this.emailAddressTypeService);
    this.fieldErrors = FieldValidationError.empty<InvoiceSettings.ValidatedField>();
    this.formGroup = this.createFormGroup(this.fb);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(
      this.createForwardingHtmlForm(),
      this.formGroup
    );
  }

  private createForwardingHtmlForm() {
    return new ForwardingNgFormRef({
      formFn: () => {
        return this.fForm;
      }
    });
  }

  private createFormGroup(fb: FormBuilder): FormGroup {
    return fb.group(
      {
        profileName: fb.control(
          {value: this.model.profileName},
          [
            Validators.required
          ]
        ),
        szamlazzHuAgentKey: fb.control(
          {value: this.model.szamlazzHu.agentKey},
          [
            AppValidators.required({
              disabled: () => {
                return this.model.invoiceIntegrationType !== InvoiceIntegrationType.SZAMLAZZ_HU;
              }
            })
          ]
        ),
        inboundStock: fb.control(
          {value: this.model._inboundStock},
          [
            AppValidators.validateEnabledItems
          ]
        ),
        companyName: fb.control(
          {value: this.model.companyName},
          [
            Validators.required
          ]
        ),
        externalId: fb.control(
          {value: this.model.externalId},
          [
            AppValidators.tempValidator({
              disabled: () => !this.componentState.isEditView(),
              validator: Validators.required
            })
          ]
        ),
        taxNumber: fb.control(
          this.model.taxNumber.raw,
          [
            Validators.required
          ]
        ),
        bankAccount: fb.control(
          {value: this.model.bankAccount},
          [AppValidators.tempValidator({
            validator: Validators.required,
            disabled: () => {
              return this.model && this.model.bankName.length === 0;
            }
          })
          ]
        ),
        bankName: fb.control(
          {value: this.model.bankName},
          [AppValidators.tempValidator({
            validator: Validators.required,
            disabled: () => {
              return this.model && this.model.bankAccount.length === 0;
            }
          })
          ]
        ),
        phoneNumber: fb.control(
          {value: this.model.phoneNumber},
          [AppValidators.validatePhoneNumber]
        ),
        emailAddress: fb.control(
          {value: this.model.emailAddress},
          [AppValidators.validateOptionalEmail]
        ),
        notificationEmailAddress: fb.control(
          {value: this.model.szamlazzHu.notificationEmailAddress},
          [AppValidators.tempValidator({
            validator: AppValidators.validateOptionalEmail,
            disabled: () => {
              return this.model.invoiceIntegrationType !== InvoiceIntegrationType.SZAMLAZZ_HU || !this.model.szamlazzHu.sendEmail;
            }
          })]
        ),
        userName: fb.control(
          {value: this.model.technicalUser.userName},
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => {
                return this.model && !this.model.technicalUser.enabled;
              }
            })
          ]
        ),
        signatureKey: fb.control(
          {value: this.model.technicalUser.signatureKey},
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => {
                return this.model && !this.model.technicalUser.enabled;
              }
            })
          ]
        ),
        invoicePaymentTypeTRANSFER: fb.control(
          this.model.paymentTypeExtraItems[0],
          AppValidators.tempValidator({
            validator: Validators.required,
            disabled: () => {
              return this.model && this.model.invoiceIntegrationType !== InvoiceIntegrationType.APPWORKS;
            }
          })
        ),
        invoicePaymentTypeCOD: fb.control(
          this.model.paymentTypeExtraItems[1],
          AppValidators.tempValidator({
            validator: Validators.required,
            disabled: () => {
              return this.model && this.model.invoiceIntegrationType !== InvoiceIntegrationType.APPWORKS;
            }
          })
        ),
        exchangeKey: fb.control(
          {value: this.model.technicalUser.exchangeKey},
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => {
                return this.model && !this.model.technicalUser.enabled;
              }
            })
          ]
        ),
        password: fb.control(
          {value: this.model.technicalUser.password},
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => {
                return this.model && !(this.model.technicalUser.enabled && !this.hadTechnicalUser);
              }
            })
          ]
        ),
      }
    );
  }

  ngOnInit() {
    this.initBreadcrumb();
    this.loadRightModels();
    this.loadCountries(() => {
      this.initComponentState();
    });
    this.loadCustomerEmailAddressTypes();
    this.initDropdownSettings();
  }

  private initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .translate(true)
      .remoteSearch(false)
      .build();
    this.stockDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
  }

  private initComponentState() {
    if (!this.componentState.isCreateView()) {
      this.loadModel();
    }
    else {
      this.model.initPaymentTypes(InvoicePaymentTypeMultiItem.loadItems());
    }
    if (this.componentState.isEditable()) {
      this.loadStocks();
    }
  }

  initBreadcrumb() {
    // Set breadcrumbSelf if createView, otherwise set in loadModel()
    if (this.componentState.isCreateView()) {
      this.translateService.get('INVOICE_SETTINGS_CREATE').subscribe(
        (result: string) => {
          this.breadcrumbSelf = result;
        }
      );
    }
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_INVOICE_SETTINGS_LIST').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.INVOICE_SETTINGS_LIST});
      }
    );
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  submit(navigate: boolean) {
    FormGroupValidationUtils.markFormGroupTouched(this.formGroup);
    if (!this.model.technicalUser.enabled) {
      this.formGroup.controls['userName'].setErrors(null);
      this.formGroup.controls['password'].setErrors(null);
      this.formGroup.controls['signatureKey'].setErrors(null);
      this.formGroup.controls['exchangeKey'].setErrors(null);
    }
    if (this.hasLocalFieldError() || this.hasFieldError()) {
      return;
    }
    if (!this.model.address.valid) {
      return;
    }
    if (this.componentState.isEditView()) {
      this.update(navigate);
    }
    else {
      this.create(navigate);
    }
  }

  create(navigate: boolean) {
    this.invoiceSettingsService.create({
      profileName: this.model.profileName,
      companyName: this.model.companyName,
      externalId: this.model.externalId,
      invoiceIntegrationType: this.model.invoiceIntegrationType,
      invoiceDirectionType: this.model.invoiceDirectionType,
      inboundStockId: this.model.inboundStockId,
      taxNumber: Models.optToString(this.model.taxNumber.truncated),
      euVatNumber: this.model.euVatNumber.truncated,
      emailAddress: Services.toEmailAddress(this.model.emailAddress),
      phoneNumber: Services.toPhoneNumber(this.model.phoneNumber),
      bankName: this.model.bankName,
      bankAccount: this.model.bankAccount,
      webAddress: this.model.webAddress,
      registrationNumber: this.model.registrationNumber,
      commentTemplate: this.model.commentTemplate,
      footerText: this.model.footerText,
      address: this.model.address.toData()!,
      technicalUser: this.model.technicalUser.toRequest(),
      szamlazzHu: this.model.szamlazzHu.toRequest(),
      defaultInvoiceAppearanceType: this.model.defaultInvoiceAppearanceType,
      defaultPaymentType: this.model.getDefaultPaymentType(),
      languageCode: this.model.languageCode,
      summaryRoundingEnabled: this.model.summaryRoundingEnabled,
      recordRoundingEnabled: this.model.recordRoundingEnabled,
      zeroPricedRecordsAllowed: this.model.zeroPricedRecordsAllowed,
    }).subscribe(
      (response: InvoiceSettings.InvoiceSettings) => {
        this.showToast(true);
        if (navigate) {
          this.uiRouter.stateService.go(StateName.INVOICE_SETTINGS_LIST);
        }
        else {
          this.uiRouter.stateService.go(StateName.INVOICE_SETTINGS_EDIT, {id: response.id});
        }
      },
      (error: any) => {
        this.showToast(false);
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withForm(this.fForm);
        }
      });
  }

  update(navigate: boolean) {
    this.invoiceSettingsService.update({
      id: this.componentState.id!,
      profileName: this.model.profileName,
      companyName: this.model.companyName,
      externalId: this.model.externalId,
      invoiceIntegrationType: this.model.invoiceIntegrationType,
      invoiceDirectionType: this.model.invoiceDirectionType,
      inboundStockId: this.model.inboundStockId,
      taxNumber: this.model.taxNumber.truncated ? this.model.taxNumber.truncated : '',
      euVatNumber: this.model.euVatNumber.truncated,
      emailAddress: Services.toEmailAddress(this.model.emailAddress),
      phoneNumber: Services.toPhoneNumber(this.model.phoneNumber),
      bankName: this.model.bankName,
      bankAccount: this.model.bankAccount,
      webAddress: this.model.webAddress,
      registrationNumber: this.model.registrationNumber,
      commentTemplate: this.model.commentTemplate,
      footerText: this.model.footerText,
      address: this.model.address.toData()!,
      paymentTypeExtras: this.model.paymentTypeExtras,
      technicalUser: this.model.technicalUser.toRequest(),
      szamlazzHu: this.model.szamlazzHu.toRequest(),
      defaultInvoiceAppearanceType: this.model.defaultInvoiceAppearanceType,
      defaultPaymentType: this.model.getDefaultPaymentType(),
      languageCode: this.model.languageCode,
      summaryRoundingEnabled: this.model.summaryRoundingEnabled,
      recordRoundingEnabled: this.model.recordRoundingEnabled,
      zeroPricedRecordsAllowed: this.model.zeroPricedRecordsAllowed
    }).subscribe(
      (response: InvoiceSettings.InvoiceSettings) => {
        this.showToast(true);
        if (navigate) {
          this.uiRouter.stateService.go(StateName.INVOICE_SETTINGS_LIST);
        }
      },
      (error: any) => {
        if (error instanceof FieldValidationError) {
          this.showToast(false);
          this.fieldErrors = error.withForm(this.fForm);
        }
      });
  }

  private showToast(success: boolean, msg?: string) {
    this.toasterService.pop({
      timeout: UiConstants.ToastTimeoutLong,
      type: success ? UiConstants.toastTypeSuccess : UiConstants.toastTypeError,
      title: this.translateService.instant('COMMON_BUTTON_SAVE_CHANGES'),
      body: msg ? msg
        : success ? this.translateService.instant('COMMON_SUCCESS') : this.translateService.instant('COMMON_VALIDATION_ERROR')
    });
  }

  private loadModel() {
    this.invoiceSettingsService.get({
      id: this.componentState.id!
    }).subscribe(
      (invoiceSettings: InvoiceSettings.InvoiceSettings) => {
        this.breadcrumbSelf = invoiceSettings.profileName;
        this.model.load(invoiceSettings);
        this.model.initPaymentTypes(InvoicePaymentTypeMultiItem.loadItems());
        this.hadTechnicalUser = invoiceSettings.technicalUser !== undefined;
        if (invoiceSettings.inboundStockId) {
          this.model._inboundStock = [];
          this.stockMultiselectProvider.getById(invoiceSettings.inboundStockId).subscribe(stock => {
            this.model._inboundStock.push(stock);
          });
        }
      });
  }

  private loadCountries(completion: () => void) {
    this.countryService.query({}).subscribe((result: QueryResult<Country.Country>) => {
      this.countryItems = AddressModel.CountryItem.fromCountryList(result.items);
      this.model.address.loadCountryItems(this.countryItems, this.configService.getDefaultSelectedCountryCode());
      completion();
    });
  }

  loadStocks(predicate?: string) {
    this.stockMultiselectProvider.loadActive(predicate).subscribe(items => {
      this.stocks = items;
    });
  }

  loadCustomerEmailAddressTypes() {
    this.emailAddressTypeService.getLocalizedList(this.translateService.currentLang.substr(0, 2)).subscribe(items => {
      this.customerEmailAddressTypes = items;
    });
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  hasFieldError(field?: InvoiceSettings.ValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: InvoiceSettings.ValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: InvoiceSettings.ValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  openUploadDialog() {
    this.uploadDialogVisible = true;
    this.uploadDialog.show();
  }

  closeUploadDialog() {
    this.uploadDialogVisible = false;
    this.uploadDialog.hide();
  }

  downloadTemplate() {
    this.invoiceSettingsService.downloadTemplate({
      id: this.componentState.id!
    }).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName('invoice-pdf-template.fodt'));
      });
  }

  deleteTemplate() {
    this.invoiceSettingsService.deleteTemplate({
      id: this.componentState.id!
    }).subscribe(() => {
      },
      () => {
      },
      () => {
        this.loadModel();
      });
  }

  hasIntegrationTypeIcon(type: InvoiceSettings.InvoiceIntegrationType): boolean {
    return InvoiceSettings.invoiceIntegrationTypes.find(t => t.type === type)!.icon !== undefined;
  }

  getIntegrationTypeIcon(type: InvoiceSettings.InvoiceIntegrationType): string | undefined {
    return InvoiceSettings.invoiceIntegrationTypes.find(t => t.type === type)!.icon;
  }

  getIntegrationTypeString(type: InvoiceSettings.InvoiceIntegrationType): string | undefined {
    return InvoiceSettings.invoiceIntegrationTypes.find(t => t.type === type)!.stringKey;
  }

  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }

}

