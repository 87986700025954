/* eslint-disable */
import { Map } from 'immutable';

/* eslint-enable */

export interface FieldError {
  text: string;
}

export class FieldErrors {

  public static remove(fieldErrorMap: any, fieldError?: FieldError) {
    for (const name in fieldErrorMap) {
      if (!fieldErrorMap.hasOwnProperty(name)) {
        continue;
      }
      const value = fieldErrorMap[name];
      if (fieldError === value) {
        delete fieldErrorMap[name];
      }
    }
  }
}

export interface ServerError {
  error: ErrorResource;
  httpCode: number;
}

export interface ConnectionError {
}

export interface ErrorResource {
  audit_log_id?: number;
  error?: string;
  detail?: ErrorDetail;
  retryable: boolean;
  extra: any;
}

export interface ErrorDetail {
  message: string;
  description: string;
}

export interface GlobalError {
  text: string;
}

export interface ObservableErrorResponse {
  error: ErrorResource;
  ok: boolean;
  status: number;
  url: string;
}

export class ObservableErrorResourceParser {

  static parseError(response: ObservableErrorResponse): ErrorResource | null {
    return response.error;
  }

  // { '1_field_name': {'text': 'Error message'}, '2_field_name': {'text': 'Error message'} }
  static extractFieldErrors(res: ErrorResource | null): any {
    if (res && res.extra) {
      const fieldErrors = res.extra.field_errors;
      if (fieldErrors) {
        return fieldErrors;
      }
    }
    return {};
  }

  static extractCustomErrors(res: ErrorResource | null, errorKey: string): any {
    if (res && res.extra) {
      const customErrors = res.extra[errorKey];
      if (customErrors) {
        return customErrors;
      }
    }
    return {};
  }

  static hasCustomErrors(res: ErrorResource | null, errorKey: string): boolean {
    if (res && res.extra) {
      const customErrors = res.extra[errorKey];
      if (customErrors) {
        return true;
      }
    }
    return false;
  }

  static toFieldErrorMap<T>(keyMapper: (key: string) => T, fieldErrors: any): Map<T, FieldError> {
    const entries = ObservableErrorResourceParser.toFieldErrorEntryArray(keyMapper, fieldErrors);
    const eatIt: any = Map(entries.map((i) => [i.field, i]));
    return eatIt;
  }

  private static toFieldErrorEntryArray<T>(keyMapper: (key: string) => T, fieldErrors: any): Array<FieldErrorEntry<T>> {
    const entries: Array<FieldErrorEntry<T>> = [];
    for (const key in fieldErrors) {
      if (!fieldErrors.hasOwnProperty(key)) {
        continue;
      }
      const value: FieldError = fieldErrors[key];
      const field: T = keyMapper(key);
      const entry: FieldErrorEntry<T> = {
        field: field,
        text: value.text
      };
      entries.push(entry);
    }
    return entries;
  }

}

interface FieldErrorEntry<T> extends FieldError {
  field: T;
}

