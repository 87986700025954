/* eslint-disable */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { StateName } from '../../../app.state-names';
import { TranslateService } from '@ngx-translate/core';
import { CompanyStock, CompanyStockService } from '../../../lib/company-stock/company-stock.service';
import { MultiselectOptionItem, OptionItem, QueryFieldModel, SelectUtils, UiConstants } from '../../../util/core-utils';
import { OrderType, QueryResult, ResourceQueryResult, Services } from '../../../lib/util/services';
import { EmptyMessage } from '../../../lib/util/messages';
import { Strings } from '../../../lib/util/strings';
import { Set } from 'immutable';
import { InputMask } from '../../../util/input-masks';
import { CompanyStockSearch, CompanyStockSearchService } from '../../../lib/company-stock/company-stock-search.service';
import { combineLatest, Observable } from 'rxjs';
import { Arrays } from '../../../lib/util/arrays';
import { Company, CompanyService } from '../../../lib/company/company.service';
import { CompanyLocation, CompanyLocationService } from '../../../lib/company-location/company-location.service';
import { UserGroup, UserGroupService } from '../../../lib/user-group.service';
import { RightResolver, RightService } from '../../../lib/right.service';
import { RightModel } from '../../../app.rights';
import { VehicleSearch } from '../../../lib/vehicles/vehicle-search.service';
import { Angular2Multiselects } from '../../../util/multiselect';
import { StringKey } from '../../../app.string-keys';
import { TranslateUtils } from '../../../util/translate';
import { Logger, LoggerFactory } from '../../../util/logger-factory';
import { DisabledEnum, DisabledItem } from '../../../util/search-utils';
import { UserGroupMultiselectProvider } from '../../../lib/user-group/user-group-multiselect.provider';
/* eslint-enable */

@Component({
  selector: 'app-company-stock-list',
  templateUrl: './company-stock-list.component.html',
  styleUrls: ['./company-stock-list.component.scss']
})
export class CompanyStockListComponent implements OnInit, OnDestroy {

  CompanyStock = CompanyStock;
  InputMask = InputMask;
  SelectUtils = SelectUtils;
  UiConstants = UiConstants;

  private readonly logger: Logger;

  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  queryModel: QueryFieldModel<CompanyStock.OrderField> = new QueryFieldModel(CompanyStock.OrderField.ID, OrderType.DESC);

  showSearch: boolean = false;
  companyStockList: CompanyStockModel[] = [];
  searchModel: CompanyStockSearchModel = new CompanyStockSearchModel();
  userGroupList: UserGroup[] = [];
  disabledItems: DisabledItem[] = [];
  searchResult: CompanyStockSearch.SearchDataResult;
  rightModel: RightModel = RightModel.empty();

  dropdownSettingsForCompany: Angular2Multiselects.Settings;
  companyItems: CompanyModel[] = [];
  companyForSearch: CompanyStockSearch.CompanyItem[] = [];

  dropdownSettingsForLocation: Angular2Multiselects.Settings;
  locationItems: LocationModel[] = [];
  locationForSearch: CompanyStockSearch.LocationItem[] = [];

  dropdownSettingsForUserGroup: Angular2Multiselects.Settings;
  userGroups: MultiselectOptionItem<number>[] = [];

  constructor(
    private translateService: TranslateService,
    private companyStockService: CompanyStockService,
    private companyStockSearchService: CompanyStockSearchService,
    private companyService: CompanyService,
    private locationService: CompanyLocationService,
    private userGroupService: UserGroupService,
    private userGroupMultiselectProvider: UserGroupMultiselectProvider,
    private rightService: RightService,
  ) {
    this.logger = LoggerFactory.createLogger('CompanyStockListComponent');
  }

  ngOnInit() {
    this.loadRightModels();
    this.initBreadcrumb();
    this.initDropdownSettings();
    this.onUserGroupSearch();
    this.loadSearch(() => {
      this.loadUserGroup(() => {
        this.showSearch = !this.searchModel.isEmpty();
        this.initDisabledOptions(DisabledEnum.FALSE);
        this.loadList();
      })
    });
  }

  getUserGroupName(id: number | undefined): string {

    const selectedItem = this.userGroupList.filter((item) => item.id === id);
    return selectedItem.length !== 0 ? selectedItem[0].name : '';
  }

  loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();

    const idSet: number[] = [];
    if (Strings.undefinedOrNonEmpty(this.searchModel.id)) {
      idSet.push(+this.searchModel.id);
    }
    const disabled: boolean | undefined = !this.searchModel.disabled ||
    this.searchModel.disabled.id === DisabledEnum.NONE ?
      undefined : this.searchModel.disabled.id === DisabledEnum.TRUE;

    this.companyStockService.query({
      id: idSet.length > 0 ? Set.of(...idSet) : undefined,
      externalId: Strings.undefinedOrNonEmpty(this.searchModel.externalId),
      name: Strings.undefinedOrNonEmpty(this.searchModel.name),
      companyIds: Set.of(...this.searchModel.companyIds.map(t => t.id)),
      companyLocationIds: Set.of(...this.searchModel.locationIds.map(t => t.id)),
      userGroupIds: Set.of(...this.searchModel.userGroups.map(t => t.id)),
      disabled: disabled,
      orders: Set.of(order),
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined
    }).subscribe((result: QueryResult<CompanyStock.CompanyStock>) => {
      this.companyStockList = [];
      result.items.forEach((companyStock: CompanyStock.CompanyStock) => {
        const companyStockModel = new CompanyStockModel();
        companyStockModel.id = companyStock.id + '';
        companyStockModel.externalId = companyStock.externalId + '';
        companyStockModel.name = companyStock.name;
        companyStockModel.locationName = companyStock.companyLocation.name;
        companyStockModel.userGroupName = this.getUserGroupName(companyStock.assigneeUserGroupId);
        companyStockModel.disabled = companyStock.disabled;
        this.companyStockList.push(companyStockModel);
      });
      this.queryModel.currentPage = requestedPage;
      this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
      this.logger.debug(this.companyStockList);
    });
  }

  loadCompany(completion: () => void) {
    this.companyService.query({
      disabled: false,
      orders: Set.of({field: Company.OrderField.NAME, type: OrderType.ASC})
    }).subscribe((result: QueryResult<Company.Company>) => {
      this.companyItems = [];
      result.items.forEach((company: Company.Company) => {
        const companyModel = {id: 0, name: ''};
        companyModel.id = company.id;
        companyModel.name = company.name;
        this.companyItems.push(companyModel);
      });
      completion();
    });

  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  loadLocation(completion: () => void) {
    this.locationService.query({
      disabled: false,
      orders: Set.of({field: CompanyLocation.OrderField.NAME, type: OrderType.ASC})
    }).subscribe((result: QueryResult<CompanyLocation.CompanyLocation>) => {
      this.locationItems = [];
      result.items.forEach((location: CompanyLocation.CompanyLocation) => {
        const locationModel = {id: 0, name: ''};
        locationModel.id = location.id;
        locationModel.name = location.name;
        this.locationItems.push(locationModel);
      });
      completion();
    });

  }

  loadUserGroup(completion: () => void) {
    this.userGroupList = [];
    this.userGroupService.query({
      order: Services.createOrderFieldParameter(UserGroup.Keys.toOrderFieldKey, Set.of(UserGroup.DEFAULT_ORDER))
    })
      .subscribe(
      (result: ResourceQueryResult<UserGroup>) => {
        this.userGroupList = result.items;
        completion();
      });
  }


  initBreadcrumb() {
    this.translateService.get('MENU_NAVBAR_MENU_COMPANY_STOCK').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
  }


  ngOnDestroy() {
    this.saveSearch();
  }

  setDisabled(event: any, companyStock: CompanyStock.CompanyStock, disabled: boolean) {
    this.companyStockService.setDisabled({
      id: companyStock.id,
      disabled: disabled
    }).subscribe(
      (result: EmptyMessage) => {
        this.loadList();
      },
      (error: any) => {
        this.loadList();
      }
    );
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  onSearchClicked() {
    this.loadList(1);
  }

  onSearchReset() {
    this.companyStockSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.showSearch = true;
          this.loadList(1);
        });
      }
    );
  }

  private loadSearch(completion: () => void) {
    const obs: Observable<SearchLoadResult> = combineLatest(
      this.companyStockSearchService.getSearchData({}),
      (storedSearchData: CompanyStockSearch.SearchDataResult) => {
        const result: SearchLoadResult = {
          storedSearchData: storedSearchData
        };
        return result;
      }
    );
    obs.subscribe(
      (result: SearchLoadResult) => {
        this.loadCompaniesForSearch(result.storedSearchData, () => {
          this.loadLocationsForSearch(result.storedSearchData, () => {
            this.searchResult = result.storedSearchData;
            this.postInitSearch(result.storedSearchData, completion);
          })
        })
      }
    );

  }


  orderBy(field: CompanyStock.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  initDropdownSettings() {
    this.dropdownSettingsForCompany = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .build();
    this.dropdownSettingsForLocation = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .build();
    this.dropdownSettingsForUserGroup = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .remoteSearch(true)
      .build();
  }

  private postInitSearch(storedSearchData: CompanyStockSearch.SearchDataResult, completion: () => void) {
    this.queryModel.itemsPerPage = storedSearchData.searchData.itemsPerPage;
    this.queryModel.currentPage = storedSearchData.searchData.pageNumber;
    this.queryModel.setOrder(storedSearchData.searchData.order);
    this.searchModel.id = storedSearchData.searchData.id;
    this.searchModel.externalId = storedSearchData.searchData.externalId;
    this.searchModel.name = storedSearchData.searchData.name;
    this.searchModel.userGroups = storedSearchData.searchData.userGroups;
    this.searchModel.disabled = storedSearchData.searchData.disabled;
    completion()
  }

  private loadCompaniesForSearch(storedSearchData: CompanyStockSearch.SearchDataResult, completion: () => void) {
    this.searchModel.companyIds = [];
    this.loadCompany(() => {
      this.companyForSearch = [];
      this.companyItems.forEach((state) => {
        const item = new VehicleSearch.CompanyItem();
        item.id = state.id;
        item.name = state.name;
        this.companyForSearch.push(item);
        if (storedSearchData.searchData.companyIds.find(t => t.id === item.id)) {
          this.searchModel.companyIds.push(item);
        }
      });
      completion()
    });
  }

  private loadLocationsForSearch(storedSearchData: CompanyStockSearch.SearchDataResult, completion: () => void) {
    this.searchModel.locationIds = [];
    this.loadLocation(() => {
      this.locationForSearch = [];
      this.locationItems.forEach((state) => {
        const item = new CompanyStockSearch.LocationItem();
        item.id = state.id;
        item.name = state.name;
        this.locationForSearch.push(item);
        if (storedSearchData.searchData.locationIds.find(t => t.id === item.id)) {
          this.searchModel.locationIds.push(item);
        }
      });
      completion()
    });
  }

  onUserGroupSearch(predicate?: string) {
    this.userGroupMultiselectProvider.loadActive(predicate).subscribe((items) => {
      this.userGroups = items;
    })
  }


  private saveSearch() {
    const request = {
        searchData: {
          itemsPerPage: this.queryModel.itemsPerPage,
          pageNumber: this.queryModel.currentPage,
          order: this.queryModel.getOrder(),
          id: this.searchModel.id,
          externalId: this.searchModel.externalId,
          name: this.searchModel.name,
          companyIds: this.searchModel.companyIds,
          locationIds: this.searchModel.locationIds,
          userGroups: this.searchModel.userGroups,
          disabled: this.searchModel.disabled,
        }
      }
    ;
    this.companyStockSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  private initDisabledOptions(initValue: DisabledEnum) {
    this.disabledItems = [];
    const disabledEnums: DisabledEnum[] = [DisabledEnum.NONE, DisabledEnum.FALSE, DisabledEnum.TRUE];
    Arrays.iterateByIndex(disabledEnums, (key) => {
      const item = new DisabledItem();
      item.id = key;
      this.translateService.get('COMMON_VALUE_DISABLED_ENUM_' + key).subscribe(
        (text: string) => {
          item.text = text;
        }
      );
      this.disabledItems.push(item);
      if (!this.searchModel.disabled) {
        if (key === initValue) {
          this.searchModel.disabled = item;
        }
      }
    });
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }
}


class CompanyStockModel {
  id: string = '';
  externalId: string = '';
  name: string = '';
  locationName: string = '';
  userGroupName: string = '';
  disabled = false;

}

class CompanyModel {
  id: number = 0;
  name: string = '';
}

class LocationModel {
  id: number = 0;
  name: string = '';
}

export class CompanyStockSearchModel {
  id: string;
  externalId: string;
  name: string;
  locationIds: CompanyStockSearch.LocationItem[];
  companyIds: CompanyStockSearch.CompanyItem[];
  userGroups: MultiselectOptionItem<number>[];
  disabled?: DisabledItem;


  public isEmpty(): boolean {
    return this.id.length === 0
      && this.externalId.length === 0
      && this.name.length === 0
      && this.locationIds.length === 0
      && this.companyIds.length === 0
      && this.userGroups.length === 0
      && OptionItem.idOrUndefined(this.disabled) === undefined
      ;
  }
}

interface SearchLoadResult {
  storedSearchData: CompanyStockSearch.SearchDataResult,
}
