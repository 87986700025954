/* eslint-disable */
import { Component, OnInit, ViewChild } from '@angular/core';
import { Transition } from '@uirouter/angular';
import { TaskStatistics, TaskStatisticsService } from '../../../../lib/statistics/task-statistics/task-statistics.service';
import { BreadcrumbParent } from '../../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { StateName } from '../../../../app.state-names';
import { TranslateService } from '@ngx-translate/core';
import { Task, TaskService } from '../../../../lib/task/task.service';
import { User, UserService } from '../../../../lib/user.service';
import TaskStatistic = TaskStatistics.TaskStatistic;
/* eslint-enable */

@Component({
  selector: 'app-task-statistics-detail',
  templateUrl: './task-statistics-detail.component.html',
  styleUrls: ['./task-statistics-detail.component.scss']
})
export class TaskStatisticsDetailComponent implements OnInit {

  @ViewChild('chart') chart;

  taskId: number;
  userProfileId: number;
  taskName: string;
  userName: string;
  model: TaskStatistic;
  showChart: boolean = false;

  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  pieChartData;

  constructor(
    private transition: Transition,
    private taskService: TaskService,
    private userService: UserService,
    private taskStatisticsService: TaskStatisticsService,
    private translateService: TranslateService
  ) {
    this.taskId = this.transition.params().taskId;
    this.userProfileId = this.transition.params().userProfileId;
  }

  ngOnInit() {
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('MENU_NAVBAR_TASK_STATISTICS').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.TASK_STATISTICS_LIST});
      }
    );
    this.loadData(() => {
      this.loadTaskName();
      this.loadUserName();
      this.loadChart(() => {
        this.showChart = true;
      });
    });
  }

  private loadData(completion: () => void) {
    this.taskStatisticsService.get({
      by: 'TASK',
      taskId: this.taskId,
      userProfileId: this.userProfileId
    })
      .subscribe(
        (taskStatistic: TaskStatistic) => {
          this.model = taskStatistic;
          completion();
        }
      );
  }

  private loadTaskName() {
    this.taskService.get({
      taskId: this.taskId
    }).subscribe((task: Task.Task) => {
      this.breadcrumbSelf = task.name;
      this.taskName = task.name;
    });
  }

  private loadUserName() {
    this.userService.get({
      id: this.userProfileId
    }).subscribe((user: User) => {
      this.userName = user.person_name;
    });
  }

  private loadChart(completion: () => void) {
    this.pieChartData  =  {
      chartType: 'PieChart',
      dataTable: [
        ['Name', 'Count'],
        [this.translateService.instant('TASK_STATISTICS_TABLE_HEADER_START_COUNT'), this.model.startCount],
        [this.translateService.instant('TASK_STATISTICS_TABLE_HEADER_REJECT_COUNT'), this.model.rejectCount],
        [this.translateService.instant('TASK_STATISTICS_TABLE_HEADER_RECALL_COUNT'), this.model.recallCount],
        [this.translateService.instant('TASK_STATISTICS_TABLE_HEADER_FINISH_COUNT'), this.model.finishCount],
      ],
      options: {
        'width': 400,
        'height': 300,
      }
    };
    completion();
  }

}
