/* eslint-disable */
import { RestURLBuilder } from 'rest-url-builder';
/* eslint-enable */

export class UrlBuilder {

  public static create(urlTemplate: string): UrlTemplate {
    return new RestUrlTemplate(urlTemplate);
  }

}

export interface UrlTemplate {
  baseUrl(baseUrl: string): UrlTemplate;
  namedString(parameterName: string, value: string): UrlTemplate;
  queryString(parameterName: string, value?: string): UrlTemplate;
  namedNumber(parameterName: string, value: number): UrlTemplate;
  queryNumber(parameterName: string, value?: number): UrlTemplate;
  queryNumberArray(parameterName: string, values?: number[]): UrlTemplate;
  build(): string;
}

class RestUrlTemplate implements UrlTemplate {

  private builder: RestURLBuilder;
  private _baseUrl?: string = undefined;

  constructor(private readonly urlTemplate: string) {
    const builder = new RestURLBuilder();
    this.builder = builder.buildRestURL(urlTemplate);
  }

  baseUrl(baseUrl: string): UrlTemplate {
    this._baseUrl = baseUrl;
    return this;
  }

  build(): string {
    if (this._baseUrl) {
      const sep = this._baseUrl.endsWith('/') || this.urlTemplate.startsWith('/') ? '' : '/';
      return this._baseUrl + sep + this.builder.get();
    }
    return this.builder.get();
  }

  namedString(parameterName: string, value: string): UrlTemplate {
    if (value === undefined || value === null) {
      return this;
    }
    this.builder.setNamedParameter(parameterName, value);
    return this;
  }

  queryString(parameterName: string, value?: string): UrlTemplate {
    if (value === undefined || value === null) {
      return this;
    }
    this.builder.setQueryParameter(parameterName, value);
    return this;
  }

  namedNumber(parameterName: string, value: number): UrlTemplate {
    if (value === undefined || value === null) {
      return this;
    }
    this.builder.setNamedParameter(parameterName, value + '');
    return this;
  }

  queryNumber(parameterName: string, value?: number): UrlTemplate {
    if (value === undefined || value === null) {
      return this;
    }
    this.builder.setQueryParameter(parameterName, value + '');
    return this;
  }

  queryNumberArray(parameterName: string, values?: number[]): UrlTemplate {
    if (values === undefined || values === null) {
      return this;
    }
    const value = values.join(',');
    return this.queryString(parameterName, value);
  }

}
