import {Component, OnInit, ViewChild} from '@angular/core';
import {
  MultiselectOptionItem,
  MultiselectOptionItemWithData,
  QueryFieldModel,
  UiConstants
} from '../../../util/core-utils';
import {ComponentStateResolver} from '../../../util/component-state/component-state-resolver';
import {BreadcrumbParent} from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import {RightModel} from '../../../app.rights';
import {StateName} from '../../../app.state-names';
import {Transition, UIRouter} from '@uirouter/angular';
import {TranslateService} from '@ngx-translate/core';
import {RightResolver, RightService} from '../../../lib/right.service';
import {
  HelpdeskData,
  HistoryItemResource,
  PasswordChangeFieldErrorMap,
  PasswordFieldErrorMap,
  User,
  UserFieldErrorMap,
  UserHistoryType,
  userHistoryTypes,
  UserItem,
  UserItemResource,
  UserService,
  UserSignatureService
} from '../../../lib/user.service';
import {InputMask} from '../../../util/input-masks';
import {FormBuilder, FormGroup, NgForm, Validators} from '@angular/forms';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {NfcCardListComponent} from './nfc-card-list/nfc-card-list.component';
import {UserProfile} from '../../../lib/auth.service';
import {
  ForwardingNgFormRef,
  LocalFormGroupValidationErrors,
  OrderType,
  ResourceQueryResult,
  Services
} from '../../../lib/util/services';
import {Angular2Multiselects} from '../../../util/multiselect';
import {TaskRecordService} from '../../../lib/task/task-record.service';
import {UserMeService} from '../../../lib/user/user-me.service';
import {ToasterService} from '../../../fork/angular2-toaster/src/toaster.service';
import {UserGroup, UserGroupService} from '../../../lib/user-group.service';
import {Device, DeviceManagement, DeviceManagementService} from '../../../lib/device-management.service';
import {NfcCardService} from '../../../lib/nfc-card/nfc-card.service';
import {ErrorMessageService} from '../../../lib/error-message-parser.service';
import {NgbDatePickerParserFormatter} from '../../../util/ngb-datepicker';
import {VehicleMultiselectProvider} from '../../../lib/vehicles/vehicle-multiselect.provider';
import {ConfigurationService} from '../../../lib/core-ext/configuration.service';
import {AppValidators} from '../../../util/app-validators';
import {StringKey} from '../../../app.string-keys';
import {Models} from '../../../util/model-utils';
import {Strings} from '../../../lib/util/strings';
import {Set} from 'immutable';
import {PhoneNumbers} from '../../../lib/util/phone-number';
import {EmptyMessage, IdentityMessage} from '../../../lib/util/messages';
import {
  FieldError,
  FieldErrors,
  ObservableErrorResourceParser,
  ObservableErrorResponse
} from '../../../lib/util/errors';
import {LdapFeature, UserBaseModel, UserGroupItemForDropdown, UserHistoryModel} from '../../../util/user-utils';
import {Worklog} from '../../../lib/worklog/worklog.service';
import {HistoryLogComponent} from '../../history-log/history-log/history-log.component';
import {HistoryDetailDialogComponent} from '../../history-log/history-detail-dialog/history-detail-dialog.component';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {DownloadedFile} from '../../../lib/util/downloaded-files';
import {Subject} from 'rxjs';
import {WebcamImage} from 'ngx-webcam';
import {FileUtils} from '../../../util/file-utils';
import {UserRightModel} from '../../../app.rights.user';
import {UserDataLoader, UserDataLoaderPermissionDeniedStrategy} from '../../../lib/user-data-loader';
import {
  CustomerRecordContactLocationMultiselectProvider
} from '../../../lib/customer/contact/customer-record-contact-location-multiselect.provider';
import {CustomerRecordService} from '../../../lib/customer/customer-record.service';

@Component({
  selector: 'app-user-base',
  templateUrl: './user-base.component.html',
  styleUrls: ['./user-base.component.scss']
})
export class UserBaseComponent implements OnInit {

  // If you need to access certain classes from HTML, declare them here
  UiConstants = UiConstants;
  InputMask = InputMask;
  LdapFeature = LdapFeature;
  User = User;
  WorklogScope = Worklog.WorklogScope;
  ProfilePictureDialogMode = ProfilePictureDialogMode;

  @ViewChild('historyComponent')
  historyComponent: HistoryLogComponent;

  @ViewChild('historyDetailModal', {static: true})
  historyDetailModal: HistoryDetailDialogComponent;

  @ViewChild('f', {static: true})
  form: NgForm;

  @ViewChild('addNfcCardDialog', {static: true}) addNfcCardDialog: ModalDirective;
  addNfcCardDialogVisible = false;

  @ViewChild('deleteNfcCardDialog', {static: true}) deleteNfcCardDialog: ModalDirective;
  deleteNfcCardDialogVisible = false;

  @ViewChild(NfcCardListComponent)
  nfcCardListComponent: NfcCardListComponent;

  // Component state resolver, determines the state of the component
  componentState: ComponentStateResolver;

  // Model
  model: UserBaseModel = new UserBaseModel();
  password: string;
  fieldErrors: UserFieldErrorMap;
  passwordFieldError: PasswordFieldErrorMap;
  userGroups: UserGroupItemForDropdown[] = [];
  userGroupIds: number[] = [];
  deviceList: MultiselectOptionItem<number>[] = [];
  deviceIds: number[] = [];
  vehicles: MultiselectOptionItem<number>[] = [];
  protectedUser: boolean = false;

  userDriverEnabled: boolean = false;
  emailAddressRequired: boolean = false;
  userProfile: UserProfile;

  formGroup: FormGroup;
  passwordChangeForm: FormGroup;
  addNfcCardForm: FormGroup;
  passwordChangeFieldErrors: PasswordChangeFieldErrorMap;
  private formGroupValidationErrors: LocalFormGroupValidationErrors;

  userGroupDropdownSettings: Angular2Multiselects.Settings;
  mobileAppDropdownSettings: Angular2Multiselects.Settings;
  vehicleDropdownSettings: Angular2Multiselects.Settings;
  helpdeskDropdownSettings: Angular2Multiselects.Settings;

  forceDriver: boolean = false;
  nfcCardHexId: string = '';
  nfcCardIdToDelete: number;

  @ViewChild('passwordChangeDialog', {static: true}) passwordChangeDialog: ModalDirective;
  passwordChangeDialogVisible = false;
  private _currentUserProtected: boolean = false;
  passwordVisible: boolean = false;

  // Variables used for breadcrumb
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  // Right model to store the rights available to the User
  rightModel: RightModel = RightModel.empty();
  userRightModel: UserRightModel = UserRightModel.empty();
  userForRightModel: User;

  // History
  userHistoryList: UserHistoryModel[] = [];
  historyTypes: MultiselectOptionItem<UserHistoryType>[];
  historyQueryModel: QueryFieldModel<User.OrderField> = new QueryFieldModel(User.OrderField.HISTORY_CREATION_TIME, OrderType.DESC);

  // Profile picture upload
  @ViewChild('profilePictureUploadDialog', {static: true})
  profilePictureUploadDialog: ModalDirective;
  profilePictureUploadDialogVisible = false;
  profilePictureDialogMode: ProfilePictureDialogMode = ProfilePictureDialogMode.FILE;
  imageChangedEvent: any = '';
  croppedImage: string | null | undefined;
  imageSelected: boolean = false;
  existingProfilePictureDeleted: boolean = false;
  webcamCaptureTrigger: Subject<void> = new Subject<void>();
  webcamInitError: any;

  constructor(
    private uiRouter: UIRouter,
    private transition: Transition,
    private rightService: RightService,
    private translateService: TranslateService,
    private userService: UserService,
    private fb: FormBuilder,
    private taskRecordService: TaskRecordService,
    private userMeService: UserMeService,
    private toasterService: ToasterService,
    private userGroupService: UserGroupService,
    private deviceManagementService: DeviceManagementService,
    private nfcCardService: NfcCardService,
    private errorMessageService: ErrorMessageService,
    private ngbDatePickerParserFormatter: NgbDatePickerParserFormatter,
    private signatureService: UserSignatureService,
    private vehicleMultiselectProvider: VehicleMultiselectProvider,
    private configService: ConfigurationService,
    private userDataLoader: UserDataLoader,
    private customerRecordService: CustomerRecordService,
    private contactLocationMultiselectProvider: CustomerRecordContactLocationMultiselectProvider
  ) {
    this.componentState = new ComponentStateResolver(uiRouter, transition,
      'id',
      {stateName: StateName.USER_CREATE, stateHeaderKey: 'USER_PANEL_HEADING_ADD'},
      {stateName: StateName.USER_EDIT, stateHeaderKey: 'USER_PANEL_HEADING_EDIT'},
      {stateName: StateName.USER_DETAIL, stateHeaderKey: 'USER_PANEL_HEADING_DETAIL'});
    this.password = '';
    this.fieldErrors = {};
    this.passwordFieldError = {};
    this.userGroups = [];
    this.formGroup = this.createFormGroup(this.fb);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(
      this.createForwardingHtmlForm(),
      this.formGroup
    );
    this.passwordChangeForm = fb.group({
      password: [[], Validators.required],
      confirm_password: [[], Validators.required, AppValidators.validateMatchingPasswordPromise]
    });
    this.addNfcCardForm = fb.group({
      hexId: ['', Validators.compose([Validators.required, Validators.pattern(/^[a-fA-F0-9]{8}$|^[a-fA-F0-9]{14}$/)])]
    });
    this.passwordChangeFieldErrors = {};
    this.userDriverEnabled = this.configService.getConfiguration().feature_flags.user.driver_enabled;
    this.emailAddressRequired = this.configService.getConfiguration().feature_flags.user.email_address.required;
  }

  ngOnInit() {
    this.initBreadcrumb();
    this.loadRightModels(() => {
      if (this.componentState.id) {
        this.loadModel(() => {
          this.initDropdowns();
        });
      } else {
        this.initDropdowns();
        this.loadUserGroups();
        this.loadVehicles();
        this.loadDevices();
      }
      if (this.componentState.isDetailView() && this.rightModel.userHistoryLog.hasRight()) {
        this.loadHistoryTypes(() => {
          this.loadHistoryList();
        });
      }
    });
  }

  initBreadcrumb() {
    // Set breadcrumbSelf if createView, otherwise set in loadModel()
    if (this.componentState.isCreateView()) {
      this.translateService.get('USER_PANEL_HEADING_ADD').subscribe(
        (result: string) => {
          this.breadcrumbSelf = result;
        }
      );
    }
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('COMMON_USERS').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.USER_LIST});
      }
    );
  }

  initDropdowns() {
    this.userGroupDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .remoteSearch(true)
      .enableCheckAll(true)
      .disabled(this.isLdapUser())
      .text(StringKey.USER_PLACEHOLDER_USER_GROUPS)
      .build();
    this.mobileAppDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .remoteSearch(true)
      .enableCheckAll(true)
      .disabled(this.isLdapUser())
      .build();
    this.vehicleDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .remoteSearch(true)
      .enableCheckAll(false)
      .build();
    this.helpdeskDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .build();
  }

  // Get corresponding dictionary key
  getHeadingDictionaryKey(): string {
    return this.componentState.getCurrentHeaderKey();
  }

  // Loads user rights
  private loadRightModels(completion: () => void) {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        this.userDataLoader.load(resolver.userProfile!, UserDataLoaderPermissionDeniedStrategy.MISS_ALL).subscribe(userData => {
          this.userRightModel = UserRightModel.of({
            rightModel: this.rightModel,
            currentUser: userData
          });
          this.userMeService.amIProtected().subscribe(p => {
            this._currentUserProtected = p;
            completion();
          });
        });
      }
    );
  }

  private createForwardingHtmlForm() {
    return new ForwardingNgFormRef({
      formFn: () => {
        return this.form;
      }
    });
  }

  private createFormGroup(fb: FormBuilder): FormGroup {
    return fb.group(
      {
        externalId: fb.control(
          {value: this.model.externalId},
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => {
                return !this.componentState.isEditView();
              }
            })
          ]
        ),
        emailAddress: fb.control(
          {value: this.model.emailAddress},
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => {
                return !this.componentState.isEditable() || this.handledByLdap(LdapFeature.EMAIL) || !this.emailAddressRequired;
              }
            })
          ],
        ),
        licenceNumber: fb.control(
          {value: this.model.driver.drivingLicenceNumber},
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => {
                return !this.model.isDriver;
              }
            })
          ]
        ),
        licenceCategories: fb.control(
          {value: this.model.driver.drivingLicenceCategories},
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => {
                return !this.model.isDriver;
              }
            })
          ]
        ),
        licenceExpiryDate: fb.control(
          {value: this.model.driver.drivingLicenceExpiryDate},
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => {
                return !this.model.isDriver;
              }
            }),
            AppValidators.tempValidator({
              validator: AppValidators.validateLocalDate,
              disabled: () => {
                return !this.model.isDriver;
              }
            }),
          ]
        ),
        medicalExpiryDate: fb.control({value: this.model.driver.medicalExpiryDate},
          [
            AppValidators.tempValidator({
              validator: AppValidators.validateLocalDate,
              disabled: () => {
                return !this.model.isDriver;
              }
            }),
          ]
        ),
        userGroup: fb.control({value: this.model.userGroups},
          AppValidators.validateEnabledItems
        ),
        allowedDevices: fb.control({value: this.model.allowedDevices},
          AppValidators.validateEnabledItems
        ),
        vehicle: fb.control(
          {value: this.model.vehicle},
          []
        ),
        password: fb.control('')
      }
    );
  }

  private loadModel(completion: () => void) {
    this.userService.get({
      id: this.componentState.id!
    })
      .subscribe(
        (user: User) => {
          this.userForRightModel = user;
          this.breadcrumbSelf = user.user_name;
          this.userGroupIds = user.user_group_ids;
          this.deviceIds = user.enabled_mobile_app_ids ? user.enabled_mobile_app_ids : [];
          this.protectedUser = user.protected_user;
          this.model.type = user.type;
          this.model.userName = user.user_name;
          this.model.externalId = user.external_id;
          this.model.color = user.calendar_color;
          this.model.personName = user.person_name;
          this.model.phoneNumber = user.phone_number;
          this.model.emailAddress = user.email_address;
          this.model.hasSignature = user.has_signature;
          this.model.isProtected = user.protected_user;
          if (user.profile_picture_hash) {
            this.userService.downloadProfilePicture(user.id).subscribe(
              (res: DownloadedFile) => {
                this.model.profilePicture = URL.createObjectURL(res.getBlob());
              }
            );
          }

          const parsedInactivation = this.ngbDatePickerParserFormatter.fromOffsetDateTime(
            Services.toOffsetDateTime(user.inactivation_time)
          );
          if (parsedInactivation) {
            this.model.inactivationDate = parsedInactivation;
            this.model.inactivationTime = parsedInactivation ? parsedInactivation : Models.zeroNgbTime();
          }

          this.model.isDriver = !!user.driver;
          if (user.driver) {
            this.model.driver.drivingLicenceNumber = user.driver.driving_licence.licence_number;
            this.model.driver.drivingLicenceCategories = user.driver.driving_licence.licence_categories;
            this.model.driver.drivingLicenceExpiryDate =
              Models.localDateToNgbDate(Services.toLocalDate(user.driver.driving_licence.licence_expiry_date));
            this.model.driver.medicalExpiryDate = Models.localDateToNgbDate(Services.toLocalDate(user.driver.medical_expiry_date));
            this.model.driver.permissionExpiryDate = Models.localDateToNgbDate(Services.toLocalDate(user.driver.permission_expiry_date));
          }

          if (this.rightModel.userGroupRead.hasRight()) {
            this.loadUserGroups();
          }
          if (this.rightModel.mobileAppRead.hasRight()) {
            this.loadDevices();
          }
          if (this.rightModel.vehicleRead.hasRight()) {
            this.loadVehicles();
            this.loadSelectedVehicle(user.vehicle_id);
          }
          this.forceDriver = this.model.isDriver;
          if (user.helpdesk) {
            this.loadHelpdeskData(user.helpdesk);
          }
          if (user.ldap) {
            this.model.ldap = user.ldap;
          }
          completion();
        },
        (error: any) => {
          // Ignored. The interceptor handles the global errors.
        }
      );
  }

  private loadUserGroups(q?: string) {
    const notFoundUserGroupIds: number[] = [];
    if (q === undefined) {
      this.model.userGroups = [];
    }
    this.userGroupService.query({
      name: q ? Strings.undefinedOrNonEmpty(q) : undefined,
      disabled: false,
      order: Services.createOrderFieldParameter(UserGroup.Keys.toOrderFieldKey, Set.of(UserGroup.DEFAULT_ORDER)),
      page_number: 1,
      number_of_items: UiConstants.autocompletePageSize,
      no_progress_bar: true
    })
      .subscribe(
        (result: ResourceQueryResult<UserGroup>) => {
          this.userGroups = [];
          result.items.forEach((ug) => {
            const item = {
              id: ug.id,
              itemName: ug.name,
              companyIds: ug.company_ids,
              disabled: ug.protected_group && !this._currentUserProtected
            };
            if (!item.disabled) {
              this.userGroups.push(item);
            }
          });
          if (q === undefined) {
            this.userGroupIds.forEach((id) => {
              const ug = result.items.find((g) => g.id === id);
              if (ug) {
                this.model.userGroups.push({
                  id: ug.id,
                  itemName: ug.name,
                  companyIds: ug.company_ids
                });
              } else {
                notFoundUserGroupIds.push(id);
              }
            });
          }
          if (notFoundUserGroupIds.length > 0) {
            this.userGroupService.query({
              id: notFoundUserGroupIds.join(','),
              order: Services.createOrderFieldParameter(UserGroup.Keys.toOrderFieldKey, Set.of(UserGroup.DEFAULT_ORDER))
            }).subscribe((result: ResourceQueryResult<UserGroup>) => {
              result.items.forEach((ug) => {
                const item: UserGroupItemForDropdown = {
                  id: ug.id,
                  itemName: ug.name,
                  companyIds: ug.company_ids,
                  disabled: ug.disabled
                };
                if (item.disabled) {
                  this.model.userGroups.unshift(item);
                } else {
                  this.model.userGroups.push(item);
                }
              });
            });
          }
        });
  }

  loadDevices(q?: string) {
    const notFoundDeviceIds: number[] = [];
    if (q === undefined) {
      this.model.allowedDevices = [];
    }
    this.deviceManagementService.query({
      q: q ? Strings.undefinedOrNonEmpty(q) : undefined,
      disabled: false,
      order: Services.createOrderFieldParameter(DeviceManagement.Keys.toOrderFieldKey,
        Set.of(
          {field: DeviceManagement.OrderField.NAME, type: OrderType.ASC},
          {field: DeviceManagement.OrderField.APPLICATION_ID, type: OrderType.ASC})),
      page_number: 1,
      number_of_items: UiConstants.autocompletePageSize,
      no_progress_bar: true
    })
      .subscribe(
        (result: ResourceQueryResult<Device>) => {
          this.deviceList = [];
          result.items.forEach((d) => {
            this.deviceList.push({
              id: d.id,
              itemName: d.name ? d.name + ' (' + d.application_id + ')' : d.application_id
            });
          });
          if (q === undefined) {
            this.deviceIds.forEach((id) => {
              const device = result.items.find((d) => d.id === id);
              if (device) {
                this.model.allowedDevices.push({
                  id: device.id,
                  itemName: device.name ? device.name + ' (' + device.application_id + ')' : device.application_id
                });
              } else {
                notFoundDeviceIds.push(id);
              }
            });
          }
          if (notFoundDeviceIds.length > 0) {
            this.deviceManagementService.query({
              id: notFoundDeviceIds.join(','),
              order: Services.createOrderFieldParameter(DeviceManagement.Keys.toOrderFieldKey,
                Set.of(
                  {field: DeviceManagement.OrderField.NAME, type: OrderType.ASC},
                  {field: DeviceManagement.OrderField.APPLICATION_ID, type: OrderType.ASC})),
            }).subscribe((result: ResourceQueryResult<Device>) => {
              result.items.forEach((d) => {
                const item: MultiselectOptionItem<number> = {
                  id: d.id,
                  itemName: d.name ? d.name + ' (' + d.application_id + ')' : d.application_id,
                  disabled: d.disabled
                };
                if (item.disabled) {
                  this.model.allowedDevices.unshift(item);
                } else {
                  this.model.allowedDevices.push(item);
                }
              });
            });
          }
        });
  }

  loadVehicles(predicate?: string) {
    this.vehicleMultiselectProvider.loadAll(predicate).subscribe((vehicles) => {
      this.vehicles = vehicles;
    });
  };

  loadSelectedVehicle(id: number | undefined) {
    if (id) {
      this.model.vehicle = [];
      this.vehicleMultiselectProvider.getById(id).subscribe((vehicle) => {
        this.model.vehicle.push(vehicle);
      });
    }
  };

  private loadHelpdeskData(data: HelpdeskData) {
    this.model.isHelpdesk = true;
    const ids: number[] = [];
    if (data.contact_person_id) {
      ids.push(data.contact_person_id);
    }
    if (data.customer_record_id) {
      ids.push(data.customer_record_id);
    }
    if (ids.length > 0) {
      this.customerRecordService.globalQuery({
        customerRecordIdSet: Set.of(...ids)
      }).subscribe(result => {
        result.items.toArray().forEach(cr => {
          if (cr.customerRecordId === data.contact_person_id) {
            this.model.contactPerson.push({
              id: cr.customerRecordId,
              itemName: cr.name,
              disabled: cr.disabled,
              data: {
                customerId: cr.customerId
              }
            });
          } else if (data.customer_record_id && cr.customerRecordId === data.customer_record_id) {
            this.model.customerRecord.push({
              id: cr.customerRecordId,
              itemName: cr.name,
              disabled: cr.disabled,
              data: {
                customerId: cr.customerId
              }
            });
          }
        });
      });
    }
    if (data.contact_location_ids && data.contact_location_ids.length > 0) {
      this.contactLocationMultiselectProvider.getByIds(data.contact_location_ids)
        .subscribe(result => this.model.contactLocations = result);
    }
  }

  private showValidationErrorToast() {
    this.toasterService.pop({
      timeout: UiConstants.ToastTimeoutLong,
      type: UiConstants.toastTypeError,
      title: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_TITLE),
      body: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_MESSAGE)
    });
  }

  submit() {
    this.formGroup.get('userGroup')!.updateValueAndValidity();
    this.formGroup.get('allowedDevices')!.updateValueAndValidity();
    this.formGroup.controls['licenceNumber'].updateValueAndValidity();
    this.formGroup.controls['licenceCategories'].updateValueAndValidity();
    this.formGroup.controls['licenceExpiryDate'].updateValueAndValidity();
    this.formGroup.controls['medicalExpiryDate'].updateValueAndValidity();
    // sadface
    if (!this.model.isDriver) {
      this.formGroup.controls['licenceNumber'].setErrors(null);
      this.formGroup.controls['licenceCategories'].setErrors(null);
      this.formGroup.controls['licenceExpiryDate'].setErrors(null);
      this.formGroup.controls['medicalExpiryDate'].setErrors(null);
      // this.formGroup.controls['permissionExpiryDate'].setErrors(null);
    }

    if (this.hasLocalFieldError()) {
      this.showValidationErrorToast();
      return;
    }
    if (this.componentState.isCreateView()) {
      this.create();
    } else {
      this.update();
    }
  }

  private create() {
    const phoneNumber = PhoneNumbers.parse(this.model.phoneNumber);
    const phoneNumberIso = phoneNumber.toIso();

    this.userService.create({
      external_id: this.model.externalId,
      calendar_color: this.model.color,
      user_name: this.model.userName,
      password: this.model.password,
      person_name: this.model.personName,
      phone_number: Strings.undefinedOrNonEmpty(phoneNumber.isValid() ? phoneNumberIso : this.model.phoneNumber),
      email_address: this.model.emailAddress,
      user_group_ids: this.model.createUserGroupIds(),
      inactivation_time: this.model.inactivationDate ?
        Services.offsetDateTimeToString(
          this.ngbDatePickerParserFormatter.toOffsetDateTime(this.model.inactivationDate, this.model.inactivationTime)) :
        undefined,
      driver: this.model.isDriver ? {
        medical_expiry_date: Services.localDateToString(Models.ngbDateToLocalDate(this.model.driver.medicalExpiryDate)),
        permission_expiry_date: Services.localDateToString(Models.ngbDateToLocalDate(this.model.driver.permissionExpiryDate)),
        driving_licence: {
          licence_number: this.model.driver.drivingLicenceNumber,
          licence_categories: this.model.driver.drivingLicenceCategories,
          licence_expiry_date: Services.localDateToString(Models.ngbDateToLocalDate(this.model.driver.drivingLicenceExpiryDate))!
        },
      } : undefined,
      enabled_mobile_app_ids: this.model.allowedDevices.map(d => d.id),
      vehicle_id: this.model.vehicleId
    }).subscribe(
      (response: IdentityMessage) => {
        if (this.model.selectedProfilePicture) {
          this.userService.uploadProfilePicture({
            user_id: response.id,
            picture: this.model.selectedProfilePicture
          }).subscribe(result => {
            this.uiRouter.stateService.go(StateName.USER_LIST);
          });
        } else {
          this.uiRouter.stateService.go(StateName.USER_LIST);
        }
      },
      (error: any) => {
        const res = ObservableErrorResourceParser.parseError(error);
        this.fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
      }
    );
  }

  private update() {
    const phoneNumber = PhoneNumbers.parse(this.model.phoneNumber);
    const phoneNumberIso = phoneNumber.toIso();

    this.userService.update({
      id: this.componentState.id!,
      external_id: this.model.externalId,
      calendar_color: this.model.color,
      person_name: this.model.personName,
      phone_number: Strings.undefinedOrNonEmpty(phoneNumber.isValid() ? phoneNumberIso : this.model.phoneNumber),
      email_address: this.model.emailAddress,
      user_group_ids: this.model.createUserGroupIds(),
      inactivation_time: this.model.inactivationDate ?
        Services.offsetDateTimeToString(
          this.ngbDatePickerParserFormatter.toOffsetDateTime(this.model.inactivationDate, this.model.inactivationTime)
        ) :
        undefined,
      driver: this.model.isDriver ? {
        medical_expiry_date: Services.localDateToString(Models.ngbDateToLocalDate(this.model.driver.medicalExpiryDate)),
        permission_expiry_date: Services.localDateToString(Models.ngbDateToLocalDate(this.model.driver.permissionExpiryDate)),
        driving_licence: {
          licence_number: this.model.driver.drivingLicenceNumber,
          licence_categories: this.model.driver.drivingLicenceCategories,
          licence_expiry_date: Services.localDateToString(Models.ngbDateToLocalDate(this.model.driver.drivingLicenceExpiryDate))!
        }
      } : undefined,
      enabled_mobile_app_ids: this.model.allowedDevices.map(d => d.id),
      vehicle_id: this.model.vehicleId
    })
      .subscribe(
        (response: EmptyMessage) => {
          if (this.model.selectedProfilePicture) {
            this.userService.uploadProfilePicture({
              user_id: this.componentState.id!,
              picture: this.model.selectedProfilePicture
            }).subscribe(result => {
              this.uiRouter.stateService.go(StateName.USER_LIST);
            });
          } else {
            if (this.existingProfilePictureDeleted) {
              this.userService.deleteProfilePicture({
                id: this.componentState.id!
              }).subscribe(result => {
                this.uiRouter.stateService.go(StateName.USER_LIST);
              });
            } else {
              this.uiRouter.stateService.go(StateName.USER_LIST);
            }
          }
        },
        (error: any) => {
          const res = ObservableErrorResourceParser.parseError(error);
          this.fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
        }
      );
  }

  isLdapUser(): boolean {
    return this.model && this.model.type === 'LDAP';
  }

  back() {
    window.history.back();
  }

  changePassword() {
    if (!this.passwordChangeForm.valid) {
      this.passwordChangeForm.controls['password'].markAsTouched();
      this.passwordChangeForm.controls['confirm_password'].markAsTouched();
      return;
    }
    this.userService.changePassword({
      id: this.componentState.id!,
      password: this.password
    })
      .subscribe(
        (response: EmptyMessage) => {
          this.closePasswordChangeDialog();
        },
        (error: ObservableErrorResponse) => {
          const res = ObservableErrorResourceParser.parseError(error);
          this.passwordFieldError = ObservableErrorResourceParser.extractFieldErrors(res);
        }
      );
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  removeFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.fieldErrors, fieldError);
  }

  resetPasswordFieldError() {
    this.passwordFieldError = {};
  }

  removePasswordChangeFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.passwordChangeFieldErrors, fieldError);
  }

  onPasswordModelChange() {
    this.resetPasswordFieldError();
    this.passwordChangeForm.controls['confirm_password'].updateValueAndValidity();
  }

  getTextMaximumLength(): number {
    return UiConstants.maximumVarcharLength;
  }

  get selectedUserGroupsText(): string {
    return this.selectedOptionsText(this.model.userGroups);
  }

  private selectedOptionsText(items: UserGroupItemForDropdown[]): string {
    return items
      .map((o) => {
        return o.itemName;
      })
      .join(', ');
  }

  addNfcCard() {
    if (!this.addNfcCardForm.valid) {
      this.addNfcCardForm.get('hexId')!.markAsTouched();
      return;
    }
    this.nfcCardService.create({
      userId: this.componentState.id!,
      hexId: this.nfcCardHexId
    }).subscribe(() => {
      this.closeAddNfcCardDialog();
      this.nfcCardListComponent.loadList();
    });
  }

  deleteNfcCard() {
    this.nfcCardService.delete({
      userId: this.componentState.id!,
      nfcCardId: this.nfcCardIdToDelete
    }).subscribe(() => {
      this.closeDeleteNfcCardDialog();
      this.nfcCardListComponent.loadList();
    });
  }

  onModalHidden() {
    this.nfcCardHexId = '';
    this.addNfcCardForm.reset();
    this.passwordChangeForm.reset();
  }

  showPasswordChangeDialog() {
    this.passwordChangeDialogVisible = true;
    this.passwordChangeDialog.show();
  }

  closePasswordChangeDialog() {
    this.passwordChangeDialogVisible = false;
    this.passwordChangeDialog.hide();
  }

  showAddNfcCardDialog() {
    this.addNfcCardDialogVisible = true;
    this.addNfcCardDialog.show();
  }

  closeAddNfcCardDialog() {
    this.addNfcCardDialogVisible = false;
    this.addNfcCardDialog.hide();
  }

  showDeleteNfcCardDialog(nfcCardId: number) {
    this.nfcCardIdToDelete = nfcCardId;
    this.deleteNfcCardDialogVisible = true;
    this.deleteNfcCardDialog.show();
  }

  closeDeleteNfcCardDialog() {
    this.deleteNfcCardDialogVisible = false;
    this.deleteNfcCardDialog.hide();
  }

  handledByLdap(feature: LdapFeature): boolean {
    const ldapConfiguration = this.configService.getLdapConfiguration();
    if (this.isLdapUser() && ldapConfiguration) {
      switch (feature) {
        case LdapFeature.EMAIL:
          return ldapConfiguration.email_address_handled;
        case LdapFeature.PHONE_NUMBER:
          return ldapConfiguration.phone_number_handled;
        case LdapFeature.NFC_CARD:
          return ldapConfiguration.nfc_card_handled;
      }
    }
    return false;
  }

  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }

  loadHistoryList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.historyQueryModel.currentPage;
    const order = this.historyQueryModel.getOrder();
    this.userService.history({
      user_id: this.componentState.id!,
      with_read: true,
      order: Services.createOrderFieldParameter(User.Keys.toOrderFieldKey, Set.of(order)),
      page_number: requestedPage ? requestedPage : undefined,
      number_of_items: this.historyQueryModel.itemsPerPage ? this.historyQueryModel.itemsPerPage : undefined
    }).subscribe((result: ResourceQueryResult<HistoryItemResource>) => {
      this.userHistoryList = [];
      result.items.forEach((userHistory: HistoryItemResource) => {
        const historyModel = new UserHistoryModel();
        historyModel.id = userHistory.id;
        historyModel.creationTime = Services.toOffsetDateTime(userHistory.creation_time).toUtcIsoString();
        historyModel.type = <UserHistoryType>userHistory.type;
        historyModel.typeString = this.getHistoryType(<UserHistoryType>userHistory.type);
        historyModel.changeLog = userHistory.change_log;
        historyModel.user = this.toPublicUser(userHistory.issuer_user!);
        historyModel.applicationClassification =
          'HISTORY_APPLICATION_CLASS_TYPE_' + userHistory.application_classification;
        historyModel.applicationId = userHistory.mobile_application ? userHistory.mobile_application.application_id : '';
        this.userHistoryList.push(historyModel);
      });
      this.historyQueryModel.currentPage = requestedPage;
      this.historyQueryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.historyQueryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
    });
  }

  getHistoryType(selectedType: UserHistoryType): string {
    let text = '';
    this.historyTypes.forEach((type: MultiselectOptionItem<UserHistoryType>) => {
      if (type.id === selectedType) {
        text = type.itemName;
      }
    });
    return text;
  }

  private loadHistoryTypes(completion: () => void) {
    this.historyTypes = [];
    userHistoryTypes.forEach((type) => {
      const item = {
        id: type.type,
        itemName: '....'
      };
      this.historyTypes.push(item);
      this.translateService.get(type.stringKey).subscribe((text: string) => {
        item.itemName = text;
      });
    });
    completion();
  }

  private toPublicUser(r: UserItemResource): UserItem {
    return {
      id: r!.id,
      personName: r!.person_name
    };
  }

  orderBy(field: User.OrderField) {
    this.historyQueryModel.onOrderFieldChanged(field);
    this.loadHistoryList(1);
  }

  pageChanged(selectedPage: number) {
    this.loadHistoryList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.historyQueryModel.itemsPerPage = itemsPerPage;
    this.loadHistoryList(1);
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    this.imageSelected = true;
  }

  onProfilePictureUploadDialogHidden() {
    this.imageSelected = false;
    this.imageChangedEvent = null;
    this.croppedImage = undefined;
  }

  openProfilePictureUploadDialog(mode: ProfilePictureDialogMode) {
    this.profilePictureDialogMode = mode;
    this.webcamInitError = undefined;
    this.profilePictureUploadDialogVisible = true;
    this.profilePictureUploadDialog.show();
  }

  closeProfilePictureUploadDialog() {
    this.profilePictureUploadDialogVisible = false;
    this.profilePictureUploadDialog.hide();
  }

  saveProfilePicture() {
    this.model.selectedProfilePicture = this.croppedImage;
    this.closeProfilePictureUploadDialog();
  }

  deleteProfilePicture() {
    if (this.model.profilePicture) {
      this.existingProfilePictureDeleted = true;
    }
    this.model.selectedProfilePicture = undefined;
    this.model.profilePicture = undefined;
  }

  onWebcamImageCapture(event: WebcamImage) {
    this.imageChangedEvent = {
      target: {
        files: [
          FileUtils.dataURIToBlob(event.imageAsDataUrl)
        ]
      }
    };
  }

  onWebcamInitError(event: any) {
    this.webcamInitError = event;
  }

  retakeWebcamPicture() {
    this.imageSelected = false;
  }

  navigateToUserGroupDetail(item: UserGroupItemForDropdown) {
    this.uiRouter.stateService.go(StateName.USER_GROUP_DETAIL, {id: item.id});
  }

  navigateToCustomerRecordDetail(item: MultiselectOptionItemWithData<number>) {
    this.uiRouter.stateService.go(StateName.CUSTOMER_RECORD_DETAIL,
      {customerId: item.data.customerId, customerRecordId: item.id});
  }

}

enum ProfilePictureDialogMode {
  FILE,
  WEBCAM
}
