/* eslint-disable */
import { Injectable } from '@angular/core';
import { List, Map, Set } from 'immutable';
import { SurveyRecordResource, SurveyRecordResourceService } from './survey-record-resource.service';
import { Observable, Observer } from 'rxjs';
import {
  FieldValidationError,
  Order,
  PagingRequest,
  QueryResult,
  ResourceQueryResult,
  Services
} from '../util/services';
import { DownloadedFile } from '../util/downloaded-files';
import { OffsetDateTime, } from '../util/dates';
import { Form } from '../form/form.service';
import { UserProfile } from '../auth.service';
import { FormRecord } from '../form/form-record.service';
import { ObservableErrorResourceParser } from '../util/errors';

/* eslint-enable */

@Injectable()
export class SurveyRecordService implements SurveyRecord.Service {

  private formRecordMapper: FormRecord.ResourceMapper;

  constructor(private resourceService: SurveyRecordResourceService) {
    this.formRecordMapper = new FormRecord.ResourceMapper(new Form.ResourceMapper());

  }

  // <editor-fold desc="CRUD">

  query(request: SurveyRecord.QueryRequest): Observable<QueryResult<SurveyRecord.SurveyRecord>> {
    return Observable.create((observer: Observer<QueryResult<SurveyRecord.SurveyRecord>>) => {
      const resourceRequest: SurveyRecordResource.QueryRequest = {
        with_form_record: this.toResourceArgumentWithFormRecord(request.withFormRecord),
        survey_id: request.surveyId,
        id: Services.createIdParameter(request.surveyRecordIdSet),
        owner_user_id: request.ownerUserId,
        owner_user_name: request.ownerUserName,
        disabled: request.disabled,
        parent_disabled: request.parentDisabled,
        name: request.name,
        q: request.queryText,
        update_time_from: Services.offsetDateTimeToString(request.updateTimeFrom),
        update_time_to: Services.offsetDateTimeToString(request.updateTimeTo),
        order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined
      };
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<SurveyRecordResource.SurveyRecord>) => {
          observer.next({
            items: this.toPublicList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  get(request: SurveyRecord.GetRequest): Observable<SurveyRecord.SurveyRecord> {
    return Observable.create((observer: Observer<SurveyRecord.SurveyRecord>) => {
      const resourceRequest: SurveyRecordResource.GetRequest = {
        with_form_record: this.toResourceArgumentWithFormRecord(request.withFormRecord),
        survey_id: request.surveyId,
        survey_record_id: request.surveyRecordId
      };
      return this.resourceService.get(resourceRequest).subscribe(
        (result: SurveyRecordResource.SurveyRecord) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  create(request: SurveyRecord.CreateRequest): Observable<SurveyRecord.SurveyRecord> {
    return Observable.create((observer: Observer<SurveyRecord.SurveyRecord>) => {
      const resourceRequest: SurveyRecordResource.CreateRequest = {
        with_form_record: this.toResourceArgumentWithFormRecord(request.withFormRecord),
        survey_id: request.surveyId,
        name: request.name,
        customer_record_id: request.customerRecordId,
        form_record: this.formRecordMapper.toResourceCreateRequest(request.formRecord)
      };
      return this.resourceService.create(resourceRequest).subscribe(
        (result: SurveyRecordResource.SurveyRecord) => {
          observer.next(this.toPublic(result));
        },
        (error: any) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  update(request: SurveyRecord.UpdateRequest): Observable<SurveyRecord.SurveyRecord> {
    return Observable.create((observer: Observer<SurveyRecord.SurveyRecord>) => {
      const resourceRequest: SurveyRecordResource.UpdateRequest = {
        with_form_record: this.toResourceArgumentWithFormRecord(request.withFormRecord),
        survey_id: request.surveyId,
        survey_record_id: request.surveyRecordId,
        name: request.name,
        customer_record_id: request.customerRecordId,
        form_record: this.formRecordMapper.toResourceUpdateRequest(request.formRecord)
      };
      return this.resourceService.update(resourceRequest).subscribe(
        (result: SurveyRecordResource.SurveyRecord) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  setDisabled(request: SurveyRecord.DisableRequest): Observable<SurveyRecord.SurveyRecord> {
    return Observable.create((observer: Observer<SurveyRecord.SurveyRecord>) => {
      const resourceRequest: SurveyRecordResource.DisableRequest = {
        with_form_record: this.toResourceArgumentWithFormRecord(request.withFormRecord),
        survey_id: request.surveyId,
        survey_record_id: request.surveyRecordId,
        disabled: request.disabled
      };
      return this.resourceService.setDisabled(resourceRequest).subscribe(
        (result: SurveyRecordResource.SurveyRecord) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  // </editor-fold>

  // <editor-fold desc="FileAttachment">

  downloadPdf(request: SurveyRecord.DownloadAttachmentRequest): Observable<DownloadedFile> {
    return this.resourceService.downloadPdf({
      survey_id: request.surveyId,
      survey_record_id: request.surveyRecordId
    });
  }

  // </editor-fold>

  // <editor-fold desc="Internal">

  private toResourceArgumentWithFormRecord(withFormRecord?: boolean): boolean {
    return withFormRecord === undefined ? false : withFormRecord;
  }

  private translateError(error: any): any {
    const res = ObservableErrorResourceParser.parseError(error);
    const fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
    const fieldErrorMap = ObservableErrorResourceParser.toFieldErrorMap(Keys.toValidatedField, fieldErrors);
    if (!fieldErrorMap.isEmpty()) {
      return FieldValidationError.of(fieldErrorMap);
    }
    return error;
  }

  private toPublicList(resourceList: SurveyRecordResource.SurveyRecord[]): List<SurveyRecord.SurveyRecord> {
    return List.of(...resourceList.map((r) => this.toPublic(r)));
  }

  private toPublic(r: SurveyRecordResource.SurveyRecord): SurveyRecord.SurveyRecord {
    return {
      id: r.id,
      disabled: r.disabled,
      creationTime: Services.toOffsetDateTime(r.creation_time),
      updateTime: Services.toOffsetDateTime(r.update_time),
      name: r.name,
      ownerUserId: r.owner_user_id,
      surveyId: r.survey_id,
      customerRecordId: r.customer_record_id,
      formRecord: this.formRecordMapper.toPublic(r.form_record)
    };
  }

  // </editor-fold>

}

export namespace SurveyRecord {

  export interface Service {

    // <editor-fold desc="CRUD">

    query(request: QueryRequest): Observable<QueryResult<SurveyRecord>>;

    get(request: GetRequest): Observable<SurveyRecord>;

    create(request: CreateRequest): Observable<SurveyRecord>;

    update(request: UpdateRequest): Observable<SurveyRecord>;

    setDisabled(request: DisableRequest): Observable<SurveyRecord>;

    // </editor-fold>

  }

  // <editor-fold desc="CRUD">

  export interface SurveyRecord {
    surveyId: number;
    id: number;
    disabled: boolean;
    creationTime: OffsetDateTime;
    updateTime: OffsetDateTime;
    name?: string;
    ownerUserId: UserProfile;
    customerRecordId?: number;
    formRecord?: FormRecord.FormRecord; // non-null if requested
  }

  export interface QueryRequest {
    withFormRecord?: boolean;

    surveyId: number;
    surveyRecordIdSet?: Set<number>;
    ownerUserId?: number;
    updateTimeFrom?: OffsetDateTime;
    updateTimeTo?: OffsetDateTime;

    ownerUserName?: string;
    disabled?: boolean;
    parentDisabled?: boolean;
    name?: string;
    queryText?: string;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
  }

  export interface GetRequest {
    surveyId: number;
    surveyRecordId: number;

    withFormRecord?: boolean;
  }

  export interface CreateRequest {
    surveyId: number;
    name?: string;
    customerRecordId?: number;

    formRecord: FormRecord.FormRecordCreateRequest;
    withFormRecord?: boolean;
  }

  export interface UpdateRequest {
    surveyId: number;
    surveyRecordId: number;
    customerRecordId?: number;
    name?: string;

    formRecord: FormRecord.FormRecordUpdateRequest;
    withFormRecord?: boolean;
  }

  export interface DisableRequest {
    surveyId: number;
    surveyRecordId: number;
    disabled: boolean;

    withFormRecord?: boolean;
  }

  export interface DownloadAttachmentRequest {
    surveyId: number;
    surveyRecordId: number;
  }


  export enum OrderField {
    ID,
    DISABLED,
    CREATION_TIME,
    UPDATE_TIME,
    NAME,
    DESCRIPTION,
    OWNER_USER,
    CUSTOMER_RECORD_NAME
  }

  export enum ValidatedField {
    UNKNOWN,
    NAME,
  }

  // </editor-fold>

}

// <editor-fold desc="Internal">

class Keys {

  private static readonly ID = 'id';
  private static readonly DISABLED = 'disabled';
  private static readonly CREATION_TIME = 'creation_time';
  private static readonly UPDATE_TIME = 'update_time';
  private static readonly NAME = 'name';
  private static readonly DESCRIPTION = 'description';
  private static readonly CUSTOMER_RECORD_NAME = 'customer_record_name';

  private static readonly orderFieldKeyMap: Map<SurveyRecord.OrderField, string> = Map.of(
    SurveyRecord.OrderField.ID, Keys.ID,
    SurveyRecord.OrderField.DISABLED, Keys.DISABLED,
    SurveyRecord.OrderField.CREATION_TIME, Keys.CREATION_TIME,
    SurveyRecord.OrderField.UPDATE_TIME, Keys.UPDATE_TIME,
    SurveyRecord.OrderField.NAME, Keys.NAME,
    SurveyRecord.OrderField.DESCRIPTION, Keys.DESCRIPTION,
    SurveyRecord.OrderField.CUSTOMER_RECORD_NAME, Keys.CUSTOMER_RECORD_NAME,
  );

  private static readonly keyValidatedFieldMap: Map<string, SurveyRecord.ValidatedField> = Map.of(
    Keys.NAME, SurveyRecord.ValidatedField.NAME,
  );

  public static toOrderFieldKey(field: SurveyRecord.OrderField): string {
    return Keys.orderFieldKeyMap.get(field)!;
  }

  public static toValidatedField(fieldKey: string): SurveyRecord.ValidatedField {
    return Keys.keyValidatedFieldMap.get(fieldKey, SurveyRecord.ValidatedField.UNKNOWN);
  }

}

// </editor-fold>
