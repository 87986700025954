<div>
  <div bsModal [config]="UiConstants.modalConfig" #transportTaskCreate="bs-modal" class="modal fade" tabindex="-1" role="dialog"
       aria-labelledby="myModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document" *ngIf="transportTaskCreateVisible">
      <div class="modal-content">
        <div class="modal-header">
          <p class="modal-title"><strong>{{'TRANSPORT_TASK_CREATE' | translate}}</strong></p>
          <button type="button" class="close" (click)="closeTransportTaskCreateDialog()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form (ngSubmit)="createF.form.valid && createTransportTask()" #createF="ngForm" novalidate>
          <div class="modal-body">
            <div class="row form-group">
              <div class="col-md-4 mb-0">
                <label readonly
                       class="col-form-label form-control-label detail-title">{{'TRANSPORT_TASK_CREATE_POSITION'|translate}}</label>
              </div>
              <div class="col-md-8 mb-0">
                <select class="form-control"
                        [compareWith]="SelectUtils.compareNumbersWithNull"
                        [(ngModel)]="createModel.selectedIndex"
                        [ngModelOptions]="{standalone: true}">
                  <option *ngFor="let item of createModel.availableIndexes"
                          [ngValue]="item">{{'TRANSPORT_TASK_CREATE_POSITION_TEXT' | translate: {index: item + 1} }}
                  </option>
                </select>
              </div>
            </div>
            <app-shipping-demand-address
              [form]="createF"
              [model]="createModel.addressModel"
              [componentState]="componentState"
              [rightModel]="rightModel"
              [editableFieldModel]="editableFieldModel">
            </app-shipping-demand-address>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="closeTransportTaskCreateDialog()">
              {{'COMMON_BUTTON_CANCEL' | translate}}
            </button>
            <input type="submit" class="btn btn-primary"
                   value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div bsModal [config]="UiConstants.modalConfig" #transportTaskDetail="bs-modal" class="modal fade" tabindex="-1" role="dialog"
       aria-labelledby="myModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document" *ngIf="transportTaskDetailVisible">
      <div class="modal-content">
        <div class="modal-header">
          <p class="modal-title"><strong>{{'TRANSPORT_TASK_DETAIL' | translate}}</strong></p>
          <button type="button" class="close" (click)="closeTransportTaskDetailDialog()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">

          <app-place-update [place]="placeEdit" [state]="detailTask.state" (onModify)="closeTransportTaskDetailDialog(); reload.emit()"
          [enableCoordinateEdit]="rightModel.transportUpdate.hasRight()">
          </app-place-update>
          <!--ID-->

          <div class="m-1">

            <!-- region Arrival-->
            <div class="row form-group border-bottom">
              <p class="mb-0 font-weight-bold">{{'TRANSPORT_TASK_ARRIVAL'|translate}}</p>
            </div>
            <div class="row form-group">
              <div class="col-md-4">
                <p class="transport-label-small mb-0">{{'TRANSPORT_TASK_PLANNED'|translate}}</p>
                <p class="transport-label">{{(detailTask.timetable.plannedETA?.toUtcIsoString() | date:'short') || '-'}}</p>
              </div>
              <div class="col-md-4">
                <p class="transport-label-small mb-0">{{'TRANSPORT_TASK_CURRENT_ESTIMATED'|translate}}</p>
                <p class="transport-label">{{(detailTask.timetable.currentETA?.toUtcIsoString() | date:'short') || '-'}}</p>
              </div>
              <div class="col-md-4">
                <p class="transport-label-small mb-0">{{'TRANSPORT_TASK_ACTUAL'|translate}}</p>
                <p class="transport-label">{{(detailTask.timetable.realArrivalTime?.toUtcIsoString() | date:'short') || '-'}}</p>
              </div>
            </div>
            <!-- endregion Arrival-->

            <!-- region Departure-->
            <div class="row form-group border-bottom">
              <p class="mb-0 font-weight-bold">{{'TRANSPORT_TASK_DEPARTURE'|translate}}</p>
            </div>
            <div class="row form-group">
              <div class="col-md-4">
                <p class="transport-label-small mb-0">{{'TRANSPORT_TASK_PLANNED'|translate}}</p>
                <p class="transport-label">{{(detailTask.timetable.plannedETD?.toUtcIsoString() | date:'short') || '-'}}</p>
              </div>
              <div class="col-md-4">
                <p class="transport-label-small mb-0">{{'TRANSPORT_TASK_CURRENT_ESTIMATED'|translate}}</p>
                <p class="transport-label">{{(detailTask.timetable.currentETD?.toUtcIsoString() | date:'short') || '-'}}</p>
              </div>
              <div class="col-md-4">
                <p class="transport-label-small mb-0">{{'TRANSPORT_TASK_ACTUAL'|translate}}</p>
                <p class="transport-label">{{(detailTask.timetable.realDepartureTime?.toUtcIsoString() | date:'short') || '-'}}</p>
              </div>
            </div>
            <!-- endregion Departure-->

            <!-- region Time spent-->
            <div class="row form-group border-bottom">
              <p class="mb-0 font-weight-bold">{{'TRANSPORT_TASK_DURATION_OF_STAY'|translate}}</p>
            </div>
            <div class="row form-group">
              <div class="col-md-4">
                <p class="transport-label-small mb-0">{{'TRANSPORT_TASK_PLANNED'|translate}}</p>
                <p class="transport-label">{{getPlannedStayDuration(detailTask.timetable) | formattedDuration:'-'}}</p>
              </div>
              <div class="col-md-4">
                <p class="transport-label-small mb-0">{{'TRANSPORT_TASK_CURRENT_ESTIMATED'|translate}}</p>
                <p class="transport-label">{{getCurrentStayDuration(detailTask.timetable) | formattedDuration:'-'}}</p>
              </div>
            </div>
            <!-- endregion Time spent-->
          </div>

        </div>
      </div>
    </div>
  </div>


  <div bsModal [config]="UiConstants.modalConfig" #transportTaskTimePlan="bs-modal" class="modal fade" tabindex="-1" role="dialog"
       aria-labelledby="myModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document" *ngIf="transportTaskTimePlanVisible">
      <div class="modal-content">
        <div class="modal-header">
          <p class="modal-title"><strong>{{'TRANSPORT_TASK_TIME_PLAN' | translate}}</strong></p>
          <button type="button" class="close" (click)="closeTransportTaskTimePlanDialog()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" [formGroup]='TimePlanForm' #TimePlanF="ngForm">

          <div class="row form-group"
               [ngClass]="{ 'has-danger': hasLocalFieldError('newArrivalDate') || hasLocalFieldError('newArrivalTime')}">
            <div class="col-md-4">
              <label *ngIf="transportState === 'UNDER_PLANNING'" readonly
                     class="col-form-label form-control-label detail-title">{{'TRANSPORT_TASK_PLANNED_ETA'|translate}}</label>
              <label *ngIf="transportState !== 'UNDER_PLANNING'" readonly
                     class="col-form-label form-control-label detail-title">{{'TRANSPORT_TASK_CURRENT_ETA'|translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="row">
                <div class="input-group col-md-6 mb-1 mb-md-0">
                  <input placeholder="{{'DATE_TIME_PICKER_PLACEHOLDER_DATE' | translate}}" type="text"
                         class="form-control"
                         [ngClass]="{ 'has-danger': hasLocalFieldError('newArrivalDate')}"
                         [(ngModel)]="TimePlanModel.newArrivalDate"
                         formControlName="newArrivalDate"
                         validateOnBlur [validateFormControl]="TimePlanForm.controls['newArrivalDate']"
                         ngbDatepicker #arrival_date_picker="ngbDatepicker"
                         (dateSelect)="refreshForm()">
                  <span class="add-on input-group-addon-gray" (click)="arrival_date_picker.toggle();">
                        <i class="icomoon icomoon-calendar"></i>
                       </span>
                </div>

                <div class="input-group col-md-6">
                  <input placeholder="{{'DATE_TIME_PICKER_PLACEHOLDER_TIME' | translate}}" type="text"
                         class="form-control hidden-value-when-disabled"
                         [ngClass]="{ 'has-danger': hasLocalFieldError('newArrivalTime')}"
                         [(ngModel)]="TimePlanModel.newArrivalTime"
                         formControlName="newArrivalTime"
                         [seconds]="false"
                         [allowEmptyValue]="false"
                         ngbTimepicker #arrival_time_picker="ngbTimepicker" [toggleButtonId]="'arrival_time_toggle_button'"
                         (timeSelect)="refreshForm()"
                         validateOnBlur [validateFormControl]="TimePlanForm.controls['newArrivalTime']"
                         [spinners]="true" [hourStep]="1" [minuteStep]="10">
                  <span class="add-on input-group-addon-gray"
                        id="arrival_time_toggle_button"
                        (click)="arrival_time_picker.toggle();">
                        <i class="icomoon icomoon-time"></i>
                      </span>
                </div>
              </div>
              <div class="form-control-feedback"
                   *ngIf="hasLocalFieldError('newArrivalDate', 'required') || hasLocalFieldError('newArrivalTime')">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>

              <div class="form-control-feedback" *ngIf="hasLocalFieldError('newArrivalDate', 'validateMaxNgbDateTime')">
                {{'COMMON_VALIDATION_MESSAGE_INVALID_LOCAL_DATE_FROM_TO' | translate}}
              </div>

              <div class="form-control-feedback" *ngIf="hasLocalFieldError('newArrivalDate', 'validateMinNgbDateTime')">
                {{'COMMON_VALIDATION_MESSAGE_DATE_BEFORE_NOW' | translate}}
              </div>

              <div class="form-control-feedback" *ngIf="hasLocalFieldError('newArrivalDate', 'validateLocalDate')">
                {{'COMMON_VALIDATION_MESSAGE_INVALID_LOCAL_DATE' | translate}}
              </div>
            </div>

          </div>

          <div class="row form-group"
               [ngClass]="{ 'has-danger': hasLocalFieldError('newDepartureDate') || hasLocalFieldError('newDepartureTime')}">
            <div class="col-md-4">
              <label *ngIf="transportState === 'UNDER_PLANNING'" readonly
                     class="col-form-label form-control-label detail-title">{{'TRANSPORT_TASK_PLANNED_ETD'|translate}}</label>
              <label *ngIf="transportState !== 'UNDER_PLANNING'" readonly
                     class="col-form-label form-control-label detail-title">{{'TRANSPORT_TASK_CURRENT_ETD'|translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="row">
                <div class="input-group col-md-6 mb-1 mb-md-0">
                  <input placeholder="{{'DATE_TIME_PICKER_PLACEHOLDER_DATE' | translate}}" type="text"
                         class="form-control"
                         [ngClass]="{ 'has-danger': hasLocalFieldError('newDepartureDate')}"
                         [(ngModel)]="TimePlanModel.newDepartureDate"
                         formControlName="newDepartureDate"
                         validateOnBlur [validateFormControl]="TimePlanForm.controls['newDepartureDate']"
                         ngbDatepicker #departure_date_picker="ngbDatepicker"
                         (dateSelect)="refreshForm()">
                  <span class="add-on input-group-addon-gray" (click)="departure_date_picker.toggle();">
                        <i class="icomoon icomoon-calendar"></i>
                       </span>
                </div>

                <div class="input-group col-md-6">
                  <input placeholder="{{'DATE_TIME_PICKER_PLACEHOLDER_TIME' | translate}}" type="text"
                         class="form-control hidden-value-when-disabled"
                         [ngClass]="{ 'has-danger': hasLocalFieldError('newDepartureTime')}"
                         [(ngModel)]="TimePlanModel.newDepartureTime"
                         formControlName="newDepartureTime"
                         [seconds]="false"
                         [allowEmptyValue]="false"
                         ngbTimepicker #departure_time_picker="ngbTimepicker" [toggleButtonId]="'departure_time_toggle_button'"
                         validateOnBlur [validateFormControl]="TimePlanForm.controls['newDepartureTime']"
                         [spinners]="true" [hourStep]="1" [minuteStep]="10"
                         (timeSelect)="refreshForm()">
                  <span class="add-on input-group-addon-gray"
                        id="departure_time_toggle_button"
                        (click)="departure_time_picker.toggle();">
                        <i class="icomoon icomoon-time"></i>
                      </span>
                </div>
              </div>
              <div class="form-control-feedback" *ngIf="hasLocalFieldError('newDepartureDate', 'required')">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>

              <div class="form-control-feedback"
                   *ngIf="hasLocalFieldError('newDepartureDate', 'validateMinNgbDateTime')">
                {{'COMMON_VALIDATION_MESSAGE_INVALID_LOCAL_DATE_FROM_TO' | translate}}
              </div>

              <div class="form-control-feedback" *ngIf="hasLocalFieldError('newDepartureDate', 'validateLocalDate')">
                {{'COMMON_VALIDATION_MESSAGE_INVALID_LOCAL_DATE' | translate}}
              </div>
            </div>

          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="closeTransportTaskTimePlanDialog()">
            {{'COMMON_BUTTON_CANCEL' | translate}}
          </button>
          <button type="button" class="btn btn-primary" (click)="replanTime()">{{'COMMON_BUTTON_SAVE' |
            translate}}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div bsModal [config]="UiConstants.modalConfig" #optimizeWaypoints="bs-modal" class="modal fade" tabindex="-1" role="dialog"
       aria-labelledby="myModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document" *ngIf="transportOptimizeWaypointsVisible">
      <div class="modal-content">
        <div class="modal-header">
          <p class="modal-title"><strong>{{'TRANSPORT_TASK_OPTIMIZE_WAYPOINTS' | translate}}</strong></p>
          <button type="button" class="close" (click)="closeTransportOptimizeWaypointsDialog()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form (ngSubmit)="optimizeF.form.valid && optimize()" #optimizeF="ngForm" novalidate>
          <div class="modal-body">
            <div class="row form-group">
              <div class="col-md-4 mb-0">
                <label readonly
                       class="col-form-label form-control-label detail-title">{{'TRANSPORT_TASK_WAYPOINT_FROM'|translate}}</label>
              </div>
              <div class="col-md-8 mb-0">
                <select class="form-control"
                        [compareWith]="SelectUtils.compareNumbersWithNull"
                        [(ngModel)]="optimizeWaypointsModel.fromIndex"
                        required
                        [ngModelOptions]="{standalone: true}">
                  <option *ngFor="let item of optimizeWaypointsModel.availableFromIndexes"
                          [ngValue]="item">{{'TRANSPORT_TASK_CREATE_POSITION_TEXT' | translate: {index: item + 1} }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-md-4 mb-0">
                <label readonly
                       class="col-form-label form-control-label detail-title">{{'TRANSPORT_TASK_WAYPOINT_TO'|translate}}</label>
              </div>
              <div class="col-md-8 mb-0">
                <select class="form-control"
                        [compareWith]="SelectUtils.compareNumbersWithNull"
                        [(ngModel)]="optimizeWaypointsModel.toIndex"
                        required
                        [ngModelOptions]="{standalone: true}">
                  <option *ngFor="let item of optimizeWaypointsModel.availableToIndexes"
                          [ngValue]="item">{{'TRANSPORT_TASK_CREATE_POSITION_TEXT' | translate: {index: item + 1} }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="closeTransportOptimizeWaypointsDialog()">
              {{'COMMON_BUTTON_CANCEL' | translate}}
            </button>
            <input type="submit" class="btn btn-primary"
                   value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
          </div>
        </form>
      </div>
    </div>
  </div>

</div>
