<div class="card">

  <div class="card-header">
    {{'TRIGGER_HISTORY_TABLE_HEADER' | translate}}
  </div>

  <div class="card-body">

    <table class="table table-striped table-bordered">
      <thead>
      <tr>
        <th class="table-sorter-header w-10" *ngIf="triggerScope === 'TASK'">
          <app-table-field-sorter [orderField]="TriggerInstanceOrderField.TASK_RECORD_ID"
                                  [orderType]="queryModel.getOrderType(TriggerInstanceOrderField.TASK_RECORD_ID)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'TRIGGER_TABLE_HEADER_TASK_RECORD_ID' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header hidden-sm-down" *ngIf="triggerScope === 'TASK'">
          <app-table-field-sorter [orderField]="TriggerInstanceOrderField.TASK_RECORD_NAME"
                                  [orderType]="queryModel.getOrderType(TriggerInstanceOrderField.TASK_RECORD_NAME)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header w-10" *ngIf="triggerScope === 'TRANSPORT'">
          <app-table-sorter-no-op
            [text]="'COMMON_TABLE_HEADER_ID' | translate">
          </app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header hidden-sm-down" *ngIf="triggerScope === 'TRANSPORT'">
          <app-table-sorter-no-op
            [text]="'TRANSPORT_EXTERNAL_ID' | translate">
          </app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header hidden-sm-down">
          <app-table-field-sorter [orderField]="TriggerInstanceOrderField.CREATION_TIME"
                                  [orderType]="queryModel.getOrderType(TriggerInstanceOrderField.CREATION_TIME)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'TRIGGER_TABLE_HEADER_TIME_OF_RUN' | translate"></app-table-field-sorter>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let trigger of triggerInstanceList | paginate: {
                      itemsPerPage: queryModel.itemsPerPage,
                      currentPage: queryModel.currentPage,
                      totalItems: queryModel.currentNumberOfItems }">
        <td *ngIf="triggerScope === 'TASK'">{{trigger.taskRecordId}}</td>
        <td *ngIf="triggerScope === 'TASK'" class="hidden-sm-down">{{trigger.taskRecordName}}</td>
        <td *ngIf="triggerScope === 'TRANSPORT'">{{trigger.transportId}}</td>
        <td *ngIf="triggerScope === 'TRANSPORT'" class="hidden-sm-down">{{trigger.transportExternalId}}</td>
        <td class="hidden-sm-down">{{trigger.creationTime.toUtcIsoString() | date:'short'}}</td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <th class="table-sorter-header w-10" *ngIf="triggerScope === 'TASK'">
          <app-table-field-sorter [orderField]="TriggerInstanceOrderField.TASK_RECORD_ID"
                                  [orderType]="queryModel.getOrderType(TriggerInstanceOrderField.TASK_RECORD_ID)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'TRIGGER_TABLE_HEADER_TASK_RECORD_ID' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header hidden-sm-down" *ngIf="triggerScope === 'TASK'">
          <app-table-field-sorter [orderField]="TriggerInstanceOrderField.TASK_RECORD_NAME"
                                  [orderType]="queryModel.getOrderType(TriggerInstanceOrderField.TASK_RECORD_NAME)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header w-10" *ngIf="triggerScope === 'TRANSPORT'">
          <app-table-sorter-no-op
            [text]="'COMMON_TABLE_HEADER_ID' | translate">
          </app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header hidden-sm-down" *ngIf="triggerScope === 'TRANSPORT'">
          <app-table-sorter-no-op
            [text]="'TRANSPORT_EXTERNAL_ID' | translate">
          </app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header hidden-sm-down">
          <app-table-field-sorter [orderField]="TriggerInstanceOrderField.CREATION_TIME"
                                  [orderType]="queryModel.getOrderType(TriggerInstanceOrderField.CREATION_TIME)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'TRIGGER_TABLE_HEADER_TIME_OF_RUN' | translate"></app-table-field-sorter>
        </th>
      </tr>
      </tfoot>
    </table>

    <app-table-paging
      [currentNumberOfItems]="queryModel.currentNumberOfItems"
      [totalNumberOfItems]="queryModel.totalNumberOfItems"
      [itemsPerPage]="queryModel.itemsPerPage"
      (pageChange)="pageChanged($event)"
      (itemsPerPageChange)="itemsPerPageChanged($event)">
    </app-table-paging>

  </div><!-- /.card-body -->

</div><!-- /.card -->
