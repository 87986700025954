/* eslint-disable */
import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Transition, UIRouter} from '@uirouter/angular';
import {TranslateService} from '@ngx-translate/core';
import {DomSanitizer, SafeStyle} from '@angular/platform-browser';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {saveAs} from 'file-saver';
import {
  DocumentFileEditModel,
  DocumentFileFieldErrorMap,
  DocumentGroupItem,
  LanguageItem
} from '../../../../util/document/document-file-utils';
import {UploadErrorLocalizer} from '../../../../util/upload-error-localizer';
import {DocumentGroup, DocumentGroupService} from '../../../../lib/document/document-group.service';
import {ContentTypeParser, DownloadedFile,} from '../../../../lib/util/downloaded-files';
import {StateName} from '../../../../app.state-names';
import {StringKey} from '../../../../app.string-keys';
import {Arrays} from '../../../../lib/util/arrays';
import {LanguageService} from '../../../../lib/language.service';
import {DocumentFile, DocumentFileService} from '../../../../lib/document/document-file.service';
import {RightModel} from '../../../../app.rights';
import {RightResolver, RightService} from '../../../../lib/right.service';
import {BreadcrumbParent} from '../../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import {MultiselectOptionItem, UiConstants} from '../../../../util/core-utils';
import {ResourceQueryResult} from '../../../../lib/util/services';
import {EmptyMessage} from '../../../../lib/util/messages';
import {FieldError, FieldErrors, ObservableErrorResourceParser} from '../../../../lib/util/errors';
import {FileUploaderUtil} from '../../../../util/file-uploader-util';
import {Angular2Multiselects} from '../../../../util/multiselect';
import {Strings} from '../../../../lib/util/strings';
import {NgModel} from '@angular/forms';
import {UploadMimeType} from '../../../../shared/file-upload/upload-mime-type';
import {FileUploadComponent} from '../../../../shared/file-upload/flat/file-upload.component';

/* eslint-enable */


@Component({
  selector: 'app-document-file-edit',
  templateUrl: 'document-file-edit.component.html',
  styleUrls: ['document-file-edit.component.scss']
})
export class DocumentFileEditComponent implements OnInit, AfterViewInit {
  UiConstants = UiConstants;
  UploadMimeType = UploadMimeType;

  @ViewChild('fileEditDialog', {static: true}) fileEditDialog: ModalDirective;
  @ViewChild('fileEditUploadComponent', {static: true}) fileEditUploadComponent: FileUploadComponent;
  @ViewChild('thumbnailDialog', {static: true}) thumbnailDialog: ModalDirective;
  @ViewChild('imageDialog', {static: true}) imageDialog: ModalDirective;
  @ViewChild('fileIcon') fileIcon: ElementRef;
  @ViewChild('documentGroup') documentGroupInput: NgModel;

  fileUploadPath: string;
  thumbnailUploadPath: string;
  fieldErrors: DocumentFileFieldErrorMap;
  languageItems: LanguageItem[] = [];
  documentId: number;
  model: DocumentFileEditModel;
  documentGroupList: MultiselectOptionItem<number>[] = [];
  showFiles: boolean = true;
  imageToShow: any | null = null;
  thumbnailToShow: any | null = null;

  thumbnailLoaded = false;
  thumbnailSrc: SafeStyle;

  imageLoaded = false;
  imageSrc: SafeStyle;

  rightModel: RightModel = RightModel.empty();
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  dropdownSettings: Angular2Multiselects.Settings;

  private _loading = false;

  ctp: ContentTypeParser;

  get aspectRatio(): number {
    return DocumentFile.DOCUMENT_LIBRARY_ASPECT_RATIO;
  }
  get loading(): boolean {
    return this._loading;
  }

  constructor(
    private languageService: LanguageService,
    private uploadErrorLocalizer: UploadErrorLocalizer,
    private sanitizer: DomSanitizer,
    private translateService: TranslateService,
    private documentGroupService: DocumentGroupService,
    private fileDocumentService: DocumentFileService,
    private rightService: RightService,
    private uiRouter: UIRouter,
    private fileUploaderUtil: FileUploaderUtil,
    private transition: Transition) {
    this.fieldErrors = {};
    this.documentId = this.transition.params().id;
    this.model = new DocumentFileEditModel();

    this.fileUploadPath = '/documents/files/' + this.documentId + '/update';
    this.thumbnailUploadPath = '/documents/files/' + this.documentId + '/update-thumbnail';
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  ngOnInit() {
    this.translateService.get('COMMON_DOCUMENTS').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.DOCUMENT_DASHBOARD});
      }
    );
    this.translateService.get('MENU_NAVBAR_DOCUMENT_FILE').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.DOCUMENT_FILE_LIST});
      }
    );
    this.initDropdownSettings();
  }

  initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .remoteSearch(true)
      .enableCheckAll(false)
      .build();
  }

  ngAfterViewInit(): void {
    this.loadModel();
    this.loadRightModels();
  }

  update() {

    const languageCodeId = (this.model.language_code) ? this.model.language_code.id : null;
    const document_group_id: number | null = this.model.document_group.length === 1 ? this.model.document_group[0].id : null;

    this.fileDocumentService.update({
      id: this.model.id,
      name: this.model.name,
      external_id: this.model.external_id,
      language_code: languageCodeId ? languageCodeId : undefined,
      document_group_id: document_group_id ? document_group_id : undefined,
      description: this.model.description
    }).subscribe(
      (response: EmptyMessage) => {
        this.uiRouter.stateService.go(StateName.DOCUMENT_FILE_LIST);
      },
      (error: any) => {
        const res = ObservableErrorResourceParser.parseError(error);
        this.fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
      }
    );
  }

  back() {
    window.history.back();
  }

  private createDefaultDocumentGroup(): DocumentGroupItem {
    const def = new DocumentGroupItem();
    def.id = null;
    def.text = '';
    this.translateService.get(StringKey.COMMON_VALUE_UNSELECTED).subscribe(
      (text: string) => {
        def.text = text;
      }
    );
    return def;
  }

  private createDefaultLanguageCode(): LanguageItem {
    const def = new LanguageItem();
    def.id = null;
    def.text = '';
    this.translateService.get(StringKey.COMMON_VALUE_UNSELECTED).subscribe(
      (text: string) => {
        def.text = text;
      }
    );
    return def;
  }

  loadLanguageCodes(language_code?: string) {
    this.languageService.query({}).subscribe((result: ResourceQueryResult<string>) => {
      const def = this.createDefaultLanguageCode();
      this.model.language_code = def;
      this.languageItems.push(def);
      Arrays.iterateByIndex(result.items, language => {
        const languageItem = new LanguageItem();
        languageItem.id = language;
        languageItem.text = language;

        if (languageItem.id === language_code) {
          this.model.language_code = languageItem;
        }
        this.languageItems.push(languageItem);
      });
    });
  }

  loadDocumentGroupList(q?: string, id?: number) {
    this.documentGroupService.query({
      name: q ? Strings.undefinedOrNonEmpty(q) : undefined,
      order: '+name',
      disabled: false,
      page_number: 1,
      number_of_items: UiConstants.autocompletePageSize,
      no_progress_bar: true
    }).subscribe((result: ResourceQueryResult<DocumentGroup>) => {
      this.documentGroupList = result.items.map(g => ({id: g.id!, itemName: g.name}));
      if (id) {
        const item = this.documentGroupList.find(g => g.id === id);
        if (item) {
          this.model.document_group.push(item);
        } else {
          this.documentGroupService.get({
            id: id
          }).subscribe(result => {
            this.model.document_group.push({
              id: result.id!,
              itemName: result.name,
              disabled: result.disabled
            });
            this.documentGroupInput.control.updateValueAndValidity();
          });
        }
      }
    });
  }

  loadModel() {
    this.fileDocumentService.get({id: this.documentId})
      .subscribe(
        (fileDocument: DocumentFile) => {
          this.breadcrumbSelf = fileDocument.name;
          const model = new DocumentFileEditModel();
          model.name = fileDocument.name;
          model.id = fileDocument.id;
          model.external_id = fileDocument.external_id;
          model.has_thumbnail = fileDocument.has_thumbnail;
          model.description = fileDocument.description;
          model.contentType = fileDocument.content_type;
          this.ctp = new ContentTypeParser(fileDocument.content_type);
          this.model = model;

          if (this.ctp.isImageType) {
            this.downloadImageForPreview();
          }

          if (model.has_thumbnail) {
            this.downloadThumbnailForPreview();
          }

          this.loadDocumentGroupList(undefined, fileDocument.document_group_id);
          this.loadLanguageCodes(fileDocument.language_code);
        }
      );
  }

  downloadThumbnail() {
    this.thumbnailDialog.show();
    this.fileDocumentService.downloadThumbnail(this.documentId).subscribe(
      (res: DownloadedFile) => {
        this.thumbnailToShow = URL.createObjectURL(res.getBlob());
      }
    );
  }

  delete() {
    this.fileDocumentService.delete({
      id: this.documentId
    }).subscribe(
      (res: EmptyMessage) => {
        this.uiRouter.stateService.go(StateName.DOCUMENT_FILE_LIST);
      }
    );
  }

  deleteThumbnail() {
    this.fileDocumentService.deleteThumbnail({
      id: this.documentId
    }).subscribe(
      (res: EmptyMessage) => {
        this.loadModel();
      }
    );
  }

  downloadImage() {
    this.fileDocumentService.downloadContent(this.documentId).subscribe(
      (res: DownloadedFile) => {
        this.imageToShow = URL.createObjectURL(res.getBlob());
        this.imageDialog.show();
      }
    );
  }

  downloadThumbnailForPreview() {
    this.thumbnailLoaded = false;
    this.fileDocumentService.downloadThumbnail(this.documentId).subscribe(
      (res: DownloadedFile) => {
        this.thumbnailToShow = URL.createObjectURL(res.getBlob());
        this.thumbnailSrc = this.sanitizer.bypassSecurityTrustStyle(`url(${this.thumbnailToShow})`);
        this.thumbnailLoaded = true;
      }
    );
  }

  downloadImageForPreview() {
    this.imageLoaded = false;
    this.fileDocumentService.downloadContent(this.documentId).subscribe(
      (res: DownloadedFile) => {
        this.imageToShow = URL.createObjectURL(res.getBlob());
        this.imageSrc = this.sanitizer.bypassSecurityTrustStyle(`url(${this.imageToShow})`);
        this.imageLoaded = true;
      }
    );
  }

  downloadThumbnailAsFile() {
    this.fileDocumentService.downloadThumbnail(this.documentId).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName(this.model.name));
      }
    );
  }

  downloadFile() {
    this.fileDocumentService.downloadContent(this.documentId).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName(this.model.name));
      }
    );
  }

  editFile() {
    this.fileEditDialog.show();
  }

  hideFileEditDialog() {
    this.fileEditDialog.hide();
    this.fileEditUploadComponent.uploader.clearQueue();
  }

  onThumbnailUploaded() {
    this._loading = false;
    this.loadModel();
  }

  getTextMaximumLength(): number {
    return UiConstants.maximumVarcharLength;
  }

  removeFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.fieldErrors, fieldError);
  }
}
