/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { ObservableErrorResourceParser } from '../../util/errors';
import { FieldValidationError, PagingRequest, QueryResult, ResourceQueryResult, Services } from '../../util/services';
import { List, Map as ImmutableMap, Set } from 'immutable';
import { OffsetDateTime } from '../../util/dates';
import { IdentityMessage } from '../../util/messages';
import { DqlQuery, Query } from '../../query/field';
import { FilterField } from '../../query/filterfields';
import { OrderField } from '../../query/orderfields';
import { Invoice } from '../invoice/invoice.service';
import { InvoiceSpendingResource, InvoiceSpendingResourceService } from './invoice-spending-resource.service';
import { InvoiceResource } from '../invoice/invoice-resource.service';
import { BadgeStyle } from '../../../shared/table-badge/badge-style';

/* eslint-enable */

@Injectable()
export class InvoiceSpendingService implements InvoiceSpending.Service {

  private readonly filterField = new FilterField.InvoiceSpending();
  private readonly orderField = new OrderField.InvoiceSpending();
  private readonly fields = new InvoiceSpending.Fields.InvoiceSpending();
  private readonly invoiceMapper = new Invoice.InvoiceMapper();

  constructor(private resourceService: InvoiceSpendingResourceService) {
  }

  query(request: InvoiceSpending.QueryRequest): Observable<QueryResult<InvoiceSpending.InvoiceSpending>> {
    return Observable.create((observer: Observer<QueryResult<InvoiceSpending.InvoiceSpending>>) => {
      const resourceRequest = InvoiceSpending.toResourceQueryRequest(this.filterField, this.orderField, this.fields, request);
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<InvoiceSpendingResource.InvoiceSpending>) => {
          observer.next({
            items: List.of(...result.items.map((item) => InvoiceSpending.toPublic(this.invoiceMapper, item))),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  get(request: InvoiceSpending.GetRequest): Observable<InvoiceSpending.InvoiceSpending> {
    return Observable.create((observer: Observer<InvoiceSpending.InvoiceSpending>) => {
      const resourceRequest = InvoiceSpending.toResourceGetRequest(this.fields, request);
      return this.resourceService.get(resourceRequest).subscribe(
        (result: InvoiceSpendingResource.InvoiceSpending) => {
          observer.next(InvoiceSpending.toPublic(this.invoiceMapper, result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  create(request: InvoiceSpending.CreateRequest): Observable<IdentityMessage> {
    return Observable.create((observer: Observer<IdentityMessage>) => {
      const resourceRequest = InvoiceSpending.toResourceCreateRequest(request);
      return this.resourceService.create(resourceRequest).subscribe(
        (result: IdentityMessage) => {
          observer.next(result);
        },
        (error: any) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  private translateError(error: any): any {
    const res = ObservableErrorResourceParser.parseError(error);
    const fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
    const fieldErrorMap = ObservableErrorResourceParser.toFieldErrorMap(Keys.toValidatedField, fieldErrors);
    if (!fieldErrorMap.isEmpty()) {
      return FieldValidationError.of(fieldErrorMap);
    }
    return error;
  }
}

export namespace InvoiceSpending {


  export interface Service {

    // <editor-fold desc="CRUD">

    query(request: QueryRequest): Observable<QueryResult<InvoiceSpending>>;

    get(request: GetRequest): Observable<InvoiceSpending>;

    create(request: CreateRequest): Observable<IdentityMessage>;

    // </editor-fold>

  }

  // <editor-fold desc="CRUD">

  export interface QueryRequest {
    fields?: Query.FieldFunction<Fields.InvoiceSpending>;
    filter?: Query.FilterFunction<FilterField.InvoiceSpending>;
    order?: Query.OrderFunction<OrderField.InvoiceSpending>;
    paging?: PagingRequest;
    rights?: Set<string>;
    noProgressBar?: boolean;
  }

  export interface GetRequest {
    id: number;
    fields?: Query.FieldFunction<Fields.InvoiceSpending>;
    rights?: Set<string>;
  }

  export interface InvoiceSpending {
    id: number;
    grantedRights: Set<string>;
    creationTime: OffsetDateTime;
    updateTime: OffsetDateTime;
    spendingNumber: string;
    invoiceSpendingType: InvoiceSpendingType;
    eventName: string;
    comment?: string;
    amount: number;
    currencyCode: string;
    invoices?: Set<Invoice.Invoice>;
    user?: User;
  }

  export interface User {
    id: number;
    userName: string;
    personName: string;
  }

  export enum InvoiceSpendingType {
    REVENUE = 'REVENUE',
    EXPENSE = 'EXPENSE'
  }

  export interface CreateRequest {
    invoiceSpendingType: InvoiceSpendingType;
    eventName: string;
    comment?: string;
    amount?: number;
    currencyCode: string;
    invoiceIds?: Set<number>;
  }

  export function toResourceQueryRequest(
    filterField: FilterField.InvoiceSpending,
    orderField: OrderField.InvoiceSpending,
    fields: InvoiceSpending.Fields.InvoiceSpending,
    request: QueryRequest): InvoiceSpendingResource.QueryRequest {
    const filter: string | undefined = DqlQuery.toOptionalFilter(filterField, request.filter);
    const order: string | undefined = DqlQuery.toOptionalOrder(orderField, request.order);
    const field: string | undefined = DqlQuery.toOptionalFields(fields, request.fields);
    return {
      filter: filter,
      order: order,
      fields: field,
      page_number: request.paging ? request.paging.pageNumber : undefined,
      number_of_items: request.paging ? request.paging.numberOfItems : undefined,
      rights: DqlQuery.RightRequestSerializer.getInstance().serialize(request.rights),
      no_progress_bar: request.noProgressBar
    };
  }

  export function toResourceGetRequest(
    fields: InvoiceSpending.Fields.InvoiceSpending,
    request: GetRequest): InvoiceSpendingResource.GetRequest {
    const field: string | undefined = DqlQuery.toOptionalFields(fields, request.fields);
    return {
      id: request.id,
      fields: field,
      rights: DqlQuery.RightRequestSerializer.getInstance().serialize(request.rights)
    };
  }

  export function toResourceCreateRequest(request: CreateRequest): InvoiceSpendingResource.CreateRequest {
    return {
      invoice_spending_type: <string>request.invoiceSpendingType,
      event_name: request.eventName,
      comment: request.comment,
      amount: request.amount,
      currency_code: request.currencyCode,
      invoices: request.invoiceIds ? request.invoiceIds.toArray() : []
    };
  }


  export function toPublic(invoiceMapper: Invoice.InvoiceMapper, r: InvoiceSpendingResource.InvoiceSpending): InvoiceSpending {
    return {
      id: r.id,
      grantedRights: r.granted_rights ? Set.of(...r.granted_rights) : Set.of(),
      creationTime: Services.toOffsetDateTime(r.creation_time),
      updateTime: Services.toOffsetDateTime(r.update_time),
      spendingNumber: r.spending_number,
      invoiceSpendingType: <InvoiceSpendingType>r.invoice_spending_type,
      eventName: r.event_name,
      comment: r.comment,
      amount: r.amount,
      currencyCode: r.currency_code,
      invoices: toPublicInvoices(invoiceMapper, r.invoices),
      user: r.user ? {
        id: r.user.id,
        personName: r.user.person_name,
        userName: r.user.person_name
      } : undefined
    };
  }

  function toPublicInvoices(invoiceMapper: Invoice.InvoiceMapper,
                            invoices: InvoiceResource.Invoice[] | undefined): Set<Invoice.Invoice> | undefined {
    if (!invoices) {
      return undefined;
    }
    return Set.of(...invoices.map(i => invoiceMapper.toPublic(i)));
  }

  export interface InvoiceSpendingTypeObject {
    spendingType: InvoiceSpendingType;
    stringKey: string;
    iconClass: string;
    badgeStyle: string;
  }

  export const spendingTypes: InvoiceSpendingTypeObject[] = [
    {
      spendingType: InvoiceSpendingType.EXPENSE,
      stringKey: 'INVOICE_SPENDING_TYPE_EXPENSE',
      iconClass: 'icomoon-dropdown',
      badgeStyle: BadgeStyle.DANGER
    },
    {
      spendingType: InvoiceSpendingType.REVENUE,
      stringKey: 'INVOICE_SPENDING_TYPE_REVENUE',
      iconClass: 'icomoon-arrow-up',
      badgeStyle: BadgeStyle.SUCCESS
    },
  ];


  export enum ValidatedField {
    UNKNOWN,
    NAME,
    SPENDING_NUMBER,
    AMOUNT,
  }

  export namespace Fields {

    export class InvoiceSpending {

      readonly id: Query.Field = new DqlQuery.Field('id');
      readonly grantedRights: Query.Field = new DqlQuery.Field('granted_rights');
      readonly creationTime: Query.Field = new DqlQuery.Field('creation_time');
      readonly updateTime: Query.Field = new DqlQuery.Field('update_time');
      readonly spendingNumber: Query.Field = new DqlQuery.Field('spending_number');
      readonly invoiceSpendingType: Query.Field = new DqlQuery.Field('invoice_spending_type');
      readonly eventName: Query.Field = new DqlQuery.Field('event_name');
      readonly comment: Query.Field = new DqlQuery.Field('comment');
      readonly amount: Query.Field = new DqlQuery.Field('amount');
      readonly currencyCode: Query.Field = new DqlQuery.Field('currency_code');
      readonly invoices: Query.Field = new DqlQuery.Field('invoices');
      readonly user: Query.Field = new DqlQuery.Field('user');
      readonly invoiceBase: Query.Field = new DqlQuery.Field(`invoices(${InvoiceSpending.invoiceBase()})`);

      get each(): Set<Query.Field> {
        return Set.of(
          this.id,
          this.creationTime,
          this.updateTime,
          this.spendingNumber,
          this.invoiceSpendingType,
          this.eventName,
          this.comment,
          this.amount,
          this.currencyCode,
          this.invoices,
          this.user
        );
      }

      get forList(): Set<Query.Field> {
        return Set.of(
          this.id,
          this.eventName,
          this.spendingNumber,
          this.amount,
          this.currencyCode,
          this.creationTime,
          this.user,
          this.invoiceSpendingType,
          this.invoiceBase
        );
      }

      static invoiceBase(): string {
        return 'id,invoice_number,invoice_settings,language_code';
      }

    }

  }

}

// <editor-fold desc="Internal">


class Keys {

  private static readonly NAME = 'name';
  private static readonly SPENDING_NUMBER = 'spending_number';
  private static readonly AMOUNT = 'amount';

  private static readonly keyValidatedFieldMap: ImmutableMap<string, InvoiceSpending.ValidatedField> = ImmutableMap.of(
    Keys.NAME, InvoiceSpending.ValidatedField.NAME,
    Keys.SPENDING_NUMBER, InvoiceSpending.ValidatedField.SPENDING_NUMBER,
    Keys.AMOUNT, InvoiceSpending.ValidatedField.AMOUNT,
  );

  public static toValidatedField(fieldKey: string): InvoiceSpending.ValidatedField {
    return Keys.keyValidatedFieldMap.get(fieldKey, InvoiceSpending.ValidatedField.UNKNOWN);
  }

}

// </editor-fold>


