/* eslint-disable */
import { map } from 'rxjs/operators';
import { DownloadedFile, } from '../../util/downloaded-files';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AddressResource } from '../../address';
import { UrlBuilder } from '../../../util/url-builder';
import { ResourceQueryResult } from '../../util/services';
import { TaskRecordResource } from '../../task/task-record-resource.service';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from '../../util/http-services';
import { FormRecordResource } from '../../form/form-record-resource.service';
import { EmptyMessage } from '../../util/messages';
import { LedgerNumberResource } from '../../ledger/number/ledger-number-resource.service';
import { InvoiceTagResource } from '../tag/invoice-tag-resource.service';
import FormRecordInvoiceItemQueryRequest = InvoiceResource.FormRecordInvoiceItemQueryRequest;
import InvoiceItem = FormRecordResource.InvoiceItem;
import FormRecordInvoiceItemCategoryQueryRequest = InvoiceResource.FormRecordInvoiceItemCategoryQueryRequest;

/* eslint-enable */


@Injectable()
export class InvoiceResourceService extends BaseHttpService {

  query(request: InvoiceResource.QueryRequest): Observable<ResourceQueryResult<InvoiceResource.Invoice>> {
    return this.http.get<ResourceQueryResult<InvoiceResource.Invoice>>(this.url, this.parseParams(request));
  }

  create(request: InvoiceResource.InvoiceCreateRequest): Observable<InvoiceResource.Invoice> {
    return this.http.post<InvoiceResource.Invoice>(this.url, request);
  }

  storno(request: InvoiceResource.InvoiceStornoRequest): Observable<InvoiceResource.InvoiceStornoResponse> {
    return this.http.post<InvoiceResource.InvoiceStornoResponse>(
      this.url + `${request.invoice_id}/storno?task_revert=${request.task_revert}`,
      request
    );
  }

  retryPostProcess(request: InvoiceResource.PostProcessRetryRequest): Observable<EmptyMessage> {
    return this.http.post<EmptyMessage>(this.url + `${request.invoice_id}/retry-post-process`, {});
  }

  settle(request: InvoiceResource.SettleRequest): Observable<EmptyMessage> {
    return this.http.post<EmptyMessage>(this.url + `${request.invoice_id}/settle`, request);
  }

  setTag(request: InvoiceResource.InvoiceTagRequest): Observable<EmptyMessage> {
    return this.http.patch<EmptyMessage>(this.url + `set-tag`, request);
  }

  clearTag(request: InvoiceResource.InvoiceTagRequest): Observable<EmptyMessage> {
    return this.http.patch<EmptyMessage>(this.url + `clear-tag`, request);
  }

  get(request: InvoiceResource.GetRequest): Observable<InvoiceResource.Invoice> {
    return this.http.get<InvoiceResource.Invoice>(this.url + `${request.id}?with_records=true&with_footer=true`);
  }

  downloadPdf(request: InvoiceResource.DownloadPdfRequest): Observable<DownloadedFile> {
    return this.http.get(this.url + `${request.invoice_id}/pdf`, {
      observe: 'response',
      responseType: 'blob'
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  batchDownloadPdf(request: InvoiceResource.BatchDownloadPdfRequest): Observable<DownloadedFile> {
    return this.http.get(this.url + `batch-pdf`, {
      observe: 'response',
      responseType: 'blob',
      params: this.parseParams(request).params
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  generatePdf(request: InvoiceResource.DownloadPdfRequest): Observable<DownloadedFile> {
    return this.http.get(this.url + `${request.invoice_id}/generate-pdf`, {
      observe: 'response',
      responseType: 'blob'
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  exportSupInvoice(ids: string): Observable<DownloadedFile> {
    return this.http.get(this.url + `export-sup-invoice-xml`, {
      observe: 'response',
      responseType: 'blob',
      params: this.parseParams({id: ids}).params
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  getFormRecordInvoiceItems(request: FormRecordInvoiceItemQueryRequest): Observable<ResourceQueryResult<InvoiceItem>> {
    return this.http.get<ResourceQueryResult<InvoiceItem>>(
      this.resourceHelper.getBaseUrl() + '/form-record-invoice-items',
      this.parseParams(request));
  }

  getFormRecordInvoiceItemCategories(request: FormRecordInvoiceItemCategoryQueryRequest): Observable<ResourceQueryResult<string>> {
    return this.http.get<ResourceQueryResult<string>>(
      this.resourceHelper.getBaseUrl() + '/form-record-invoice-items/categories',
      this.parseParams(request));
  }

  exportXlsx(request: InvoiceResource.QueryRequest): Observable<DownloadedFile> {
    return this.http.get(this.url + `export-xlsx`, {
      observe: 'response',
      responseType: 'blob',
      params: this.parseParams(request).params
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  constructor(private http: HttpClient, private resourceHelper: ResourceHelper) {
    super(resourceHelper, '/invoice/invoice/');
  }
}

export namespace InvoiceResource {

  export interface QueryRequest {
    id?: string;
    invoice_book_id?: string;
    invoice_settings_id?: string;
    invoice_tag_id?: string;
    invoice_number?: string;
    category_type?: string;
    appearance_type?: string;
    payment_type?: string;
    direction_type?: string;
    task_record_assignee_user_id?: string;
    creator_user_id?: string;
    record_stock_item_id?: string;
    customer_name?: string;
    issue_date_from?: string;
    issue_date_to?: string;
    delivery_date_from?: string;
    delivery_date_to?: string;
    settlement_date_from?: string;
    settlement_date_to?: string;
    settlement_settled?: boolean;
    deadline_from?: string;
    deadline_to?: string;
    nav_export_state?: string;
    q?: string,
    order?: string;
    page_number?: number;
    number_of_items?: number;

    with_records?: boolean;
    with_footer?: boolean;
  }

  export interface GetRequest {
    id: number;
  }

  export interface Invoice {
    id: number;
    invoice_settings: {
      id: number;
      profile_name: string;
      invoice_integration_type: string;
    };
    invoice_book_id: number;
    invoice_number?: string;
    category_type: string;
    appearance_type: string;
    payment_type: string;
    direction_type: string;
    referenced_invoice_number?: string;
    comment?: string;
    technical_comment?: string;
    currency_code: string;
    seller_name: string;
    seller_tax_number: string;
    seller_eu_vat_number?: string;
    seller_bank_account?: string;
    seller_postal_address: AddressResource.PostalAddressResource;
    customer_name: string;
    customer_tax_number: string;
    customer_eu_vat_number?: string;
    customer_bank_account?: string;
    customer_postal_address: AddressResource.PostalAddressResource;
    customer_record_id?: number;
    billing_info_id?: number;
    issue_date: string; // LocalDate
    delivery_date: string; // LocalDate
    deadline: string; // LocalDate
    creation_time: string; // OffsetDateTime
    update_time: string; // OffsetDateTime
    creator_user?: {
      id: number;
      user_name: string;
      person_name: string;
    };
    footer?: InvoiceFooter;
    records?: InvoiceRecord[];
    nav_data: NavData;
    settlement_data: SettlementData;
    post_process_data?: PostProcessData;
    invoice_tag?: InvoiceTagResource.InvoiceTag;
    has_pdf: boolean;
    reverted?: boolean;
    task_record_data?: {
      task_id: number,
      id: number,
      name: string,
      external_id: string,
    };
  }

  export interface InvoiceFooter {
    net_sum_price: string; // BigDecimal
    gross_sum_price: string; // BigDecimal
    sum_vat_amount: string; // BigDecimal
    vat_sums: InvoiceVatSum[];
  }

  export interface InvoiceVatSum {
    vat_rate: string; // BigDecimal
    zero_vat_rate_type?: string;
    net_sum_price: string; // BigDecimal
    gross_sum_price: string; // BigDecimal
    sum_vat_amount: string; // BigDecimal
  }

  export interface InvoiceRecord {
    id: number;
    record_name: string;
    hun_vtsz_number?: string;
    amount: string; // BigDecimal
    unit_type: string;
    vat_rate: string; // BigDecimal
    zero_vat_rate_type?: string;
    comment?: string;
    currency_code: string;
    vat_amount: string; // BigDecimal
    net_price: string; // BigDecimal
    net_unit_price: string; // BigDecimal
    gross_price: string; // BigDecimal
    gross_unit_price: string; // BigDecimal
    stock_item_id?: number;
    ledger_number?: LedgerNumberResource.LedgerNumber;
  }

  export interface NavData {
    export_state: string;
    request_id?: string;
    technical_validation_message?: string;
    business_validation_message?: string;
  }

  export interface SettlementData {
    settled: boolean;
    date?: string;
    comment?: string;
    spending?: {
      id: number;
      spending_number: string;
    }
  }

  export interface NavValidationMessage {
    validation_result_code: string,
    validation_error_code: string,
    message: string,
    pointer?: any
  }

  export interface PostProcessData {
    state: string;
    info?: string;
    time?: string;
  }

  export interface InvoiceCreateRequest {
    invoice_number?: string;
    invoice_settings_id: number;
    invoice_book_id?: number;
    appearance_type: string;
    comment_template?: string;
    currency_code: string;
    payment_type: string;
    direction_type: string;
    issue_date: string;
    delivery_date: string;
    deadline: string;
    customer_record_id: number;
    billing_info_id?: number;
    invoice_tag_id?: number;
    records: InvoiceRecordCreateRequest[];
  }

  export interface InvoiceRecordCreateRequest {
    record_name: string;
    hun_vtsz_number?: string;
    amount: string; // BigDecimal
    unit_type: string;
    net_unit_price: string; // BigDecimal
    vat_rate: string; // BigDecimal
    zero_vat_rate_type?: string;
    comment?: string;
    currency_code: string;
    stock_item_id?: number;
    ledger_number?: number;
  }

  export interface InvoiceStornoRequest {
    invoice_id: number;
    invoice_settings_id: number;
    invoice_book_id?: number;
    invoice_number?: string;
    task_revert: boolean;
    comment_template?: string;
  }

  export interface InvoiceStornoResponse {
    invoice?: Invoice;
    revert_result?: TaskRecordResource.RevertResultResponse;
  }

  export interface DownloadPdfRequest {
    invoice_id: number;
  }

  export interface InvoiceTagRequest {
    invoice_ids: number[];
    invoice_tag_id?: number;
  }

  export interface BatchDownloadPdfRequest {
    invoice_book_id?: number;
    invoice_seq_from?: number;
    invoice_seq_to?: number;
    issue_date_from?: string;
    issue_date_to?: string;
  }

  export interface FormRecordInvoiceItemQueryRequest {
    record_name?: string;
    external_id?: string;
    excluded_ids?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
    no_progress_bar?: boolean;
  }

  export interface FormRecordInvoiceItemCategoryQueryRequest {
    category?: string;
    page_number?: number;
    number_of_items?: number;
    no_progress_bar?: boolean;
  }

  export interface PostProcessRetryRequest {
    invoice_id: number;
  }

  export interface SettleRequest {
    invoice_id: number;
    date: string;
    comment?: string;
  }
}
