<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-vertical-padding container-horizontal-padding animated fadeIn col-md-8 col-sm-12 col-xs-12">
  <div class="card">
    <div class="card-header">
      {{'INVOICE_EDIT_HEADING' | translate}}
    </div>
    <div class="card-body">
      <form class="form-horizontal bordered-row" (ngSubmit)="update()" #f="ngForm">
        <div class="form-group row" [ngClass]="{ 'has-danger': hasFieldError(InvoiceBook.ValidatedField.EXTERNAL_ID) || (f.submitted && !externalId.valid) }">
          <label class="col-4 col-form-label">
            {{'COMMON_EXTERNAL_ID' | translate}}
          </label>
          <div class="col-8">
            <input class="form-control" type="text" placeholder="{{'COMMON_EXTERNAL_ID' | translate}}"
                   maxlength="{{UiConstants.maximumVarcharLength}}"
                   [ngClass]="{ 'form-control-danger': hasFieldError(InvoiceBook.ValidatedField.EXTERNAL_ID) || (f.submitted && !externalId.valid) }"
                   [(ngModel)]="model.externalId" (ngModelChange)="removeFieldError(InvoiceBook.ValidatedField.EXTERNAL_ID)" name="externalId" id="externalId" #externalId="ngModel" required>
            <div class="form-control-feedback" *ngIf="hasFieldError(InvoiceBook.ValidatedField.EXTERNAL_ID)">{{getFieldErrorText(InvoiceBook.ValidatedField.EXTERNAL_ID)}}</div>
            <div class="form-control-feedback" *ngIf="f.submitted && !externalId.valid">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label">{{'COMMON_NOTE' | translate}}</label>
          <div class="col-8">
            <textarea class="form-control textarea-noresize" rows="3"
                      placeholder="{{'COMMON_NOTE' | translate}}"
                      [(ngModel)]="model.comment" name="comment" id="comment" #comment="ngModel">
            </textarea>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-form-label form-control-label col-md-4 detail-title">{{'INVOICE_CREATE_INVOICE_SETTINGS' |
            translate}}</label>
          <div class="col-md-8">
            <label
              class="form-control detail-description"
              readonly>{{model.invoiceSettings}}
            </label>
          </div>
        </div>
        <div class="d-inline pull-right">
          <input type="button" class="btn btn-outline-primary" (click)="back()"
                 value="{{'COMMON_BUTTON_CANCEL'|translate}}"/>
          <input type="submit" class="btn btn-primary" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
        </div>
      </form>
    </div>
  </div>
</div>
