import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from '../../fork/ngx-device-detector/device-detector.service';

@Component({
  selector: 'app-unsupported-device',
  templateUrl: './unsupported-device.component.html',
  styleUrls: ['./unsupported-device.component.scss']
})
export class UnsupportedDeviceComponent implements OnInit {

  get unsupported(): boolean {
    return this.deviceDetectorService.isMobile() || this.deviceDetectorService.isTablet();
  }

  constructor(
    private deviceDetectorService: DeviceDetectorService
  ) {}

  ngOnInit() {
  }

  back() {
    window.history.back();
  }

}
