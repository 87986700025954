import { Injectable } from '@angular/core';
import { Trigger } from '../../trigger/trigger.service';
import { Observable, Observer } from 'rxjs';
import { EmptyMessage, IdentityMessage } from '../../util/messages';
import { TriggerResource } from '../../trigger/trigger-resource.service';
import { TriggerUtils } from '../../trigger/trigger-utils';
import { QueryResult, ResourceQueryResult } from '../../util/services';
import { TriggerInstance } from '../../trigger/trigger-instance.service';
import { TriggerInstanceResource } from '../../trigger/trigger-instance-resource.service';
import { TransportTriggerResourceService } from './transport-trigger-resource.service';

@Injectable()
export class TransportTriggerService implements Trigger.TriggerService {

  constructor(private resourceService: TransportTriggerResourceService) {
  }

  createTrigger(request: Trigger.TriggerCreateRequest): Observable<IdentityMessage> {
    return Observable.create((observer: Observer<IdentityMessage>) => {
      const resourceRequest: TriggerResource.TriggerCreateRequest = TriggerUtils.toResourceTriggerCreateRequest(request);
      return this.resourceService.createTrigger(resourceRequest).subscribe(
        (result: IdentityMessage) => {
          observer.next(result);
        },
        (error: any) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  deleteTrigger(request: Trigger.TriggerDeleteRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: TriggerResource.TriggerDeleteRequest = TriggerUtils.toResourceTriggerDeleteRequest(request);
      return this.resourceService.deleteTrigger(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: any) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  disableTrigger(request: Trigger.TriggerDisableRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: TriggerResource.TriggerDisableRequest = TriggerUtils.toResourceTriggerDisableRequest(request);
      return this.resourceService.disableTrigger(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: any) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  getTrigger(request: Trigger.TriggerGetRequest): Observable<Trigger.Trigger> {
    return Observable.create((observer: Observer<Trigger.Trigger>) => {
      const resourceRequest: TriggerResource.TriggerGetRequest = TriggerUtils.toResourceTriggerGetRequest(request);
      return this.resourceService.getTrigger(resourceRequest).subscribe(
        (result: TriggerResource.Trigger) => {
          observer.next(TriggerUtils.toPublicTrigger(result));
        },
        (error: any) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  getTriggerInstances(request: Trigger.TriggerInstanceRequest): Observable<QueryResult<TriggerInstance.TriggerInstance>> {
    return Observable.create((observer: Observer<QueryResult<TriggerInstance.TriggerInstance>>) => {
      const resourceRequest: TriggerResource.TriggerInstanceRequest = TriggerUtils.toResourceTriggerInstanceRequest(request);
      return this.resourceService.getTriggerInstances(resourceRequest).subscribe(
        (result: ResourceQueryResult<TriggerInstanceResource.TriggerInstance>) => {
          observer.next({
            items: TriggerUtils.toPublicTriggerInstanceList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  queryTriggers(request: Trigger.TriggerQueryRequest): Observable<QueryResult<Trigger.Trigger>> {
    return Observable.create((observer: Observer<QueryResult<Trigger.Trigger>>) => {
      const resourceRequest: TriggerResource.TriggerQueryRequest = TriggerUtils.toResourceTriggerQueryRequest(request);
      return this.resourceService.queryTriggers(resourceRequest).subscribe(
        (result: ResourceQueryResult<TriggerResource.Trigger>) => {
          observer.next({
            items: TriggerUtils.toPublicTriggerList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  updateTrigger(request: Trigger.TriggerUpdateRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: TriggerResource.TriggerUpdateRequest = TriggerUtils.toResourceTriggerUpdateRequest(request);
      return this.resourceService.updateTrigger(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: any) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

}
