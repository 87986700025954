<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="animated fadeIn admin-container" *ngIf="cards.length > 0"
id="admin-dashboard-content">

  <ngx-masonry
    [options]="{percentPosition: true, horizontalOrder: true, transitionDuration: '0'}"
    [updateLayout]="updateMasonryLayout">
    <app-admin-dashboard-card
      ngxMasonryItem
      id="admin-dashboard-card-{{card.id}}"
      *ngFor="let card of cards"
      class="card-item col-xl-3 col-lg-4 col-md-6"
      [model]="card">
    </app-admin-dashboard-card>
  </ngx-masonry>

</div>
