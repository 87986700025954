/* eslint-disable */
import { Injectable } from '@angular/core';
import { OrderField } from '../query/orderfields';
import { OrderFieldModel, OrderFunctionResolver } from '../../util/core-utils';
import { NewBaseSearch, NewBaseSearchService } from '../new-base.search-service';
import { OrderType } from '../util/services';
import { SearchUtils } from '../../util/search-utils';
import { LoggedInUserStorage } from '../util/storages';
import { Workflow } from './workflow.service';

/* eslint-enable */

@Injectable()
export class WorkflowSearchService extends NewBaseSearchService<OrderField.Workflow> {

  protected createStorage(): WorkflowSearch.Storage {
    return new WorkflowSearch.Storage();
  }

}

export namespace WorkflowSearch {

  export class Model extends SearchUtils.SearchModel {

    public static defaultSelectedState = {id: null, stringKey: 'COMMON_VALUE_UNSELECTED'};

    showSearch: boolean = false;

    id?: number;
    externalId: string;
    name: string;
    versionState: StateFilterOption;

    constructor() {
      super();
      this.reset();
    }

    reset() {
      this.id = undefined;
      this.externalId = '';
      this.name = '';
      this.versionState = Model.defaultSelectedState;
    }

    isEmpty(): boolean {
      return this.id === undefined &&
        this.externalId === '' &&
        this.name === '' &&
        this.versionState.id === null;
    }

  }

  export namespace OrderFunctions {
    export const ID = (f: OrderField.Workflow) => f.id;
    export const NAME = (f: OrderField.Workflow) => f.name;
    export const EXTERNAL_ID = (f: OrderField.Workflow) => f.externalId;
    export const VERSION = (f: OrderField.Workflow) => f.version;
    export const UPDATE_TIME = (f: OrderField.Workflow) => f.updateTime;

    export const VALUES = OrderFunctionResolver.builder<OrderField.Workflow>()
      .add(ID, 'id')
      .add(NAME, 'name')
      .add(EXTERNAL_ID, 'external_id')
      .add(VERSION, 'version')
      .add(UPDATE_TIME, 'update_time')
      .build();
  }

  export interface StateFilterOption {
    id: Workflow.VersionState | null;
    stringKey: string;
  }

  export interface SearchDataResult {
    searchData: SearchData;
  }

  export interface SearchData extends NewBaseSearch.SearchData<OrderField.Workflow> {
    searchModel: Model;
  }

  interface StoredSearchRawData extends NewBaseSearch.StoredSearchRawData<OrderField.Workflow> {
    id?: number;
    externalId: string;
    name: string;
    versionState: StateFilterOption;
  }

  export class Storage extends NewBaseSearch.Storage<OrderField.Workflow> {

    constructor() {
      const defaultSearchData: SearchData = {
        queryModel: new OrderFieldModel(OrderFunctions.ID, OrderType.DESC),
        searchModel: new Model(),
      };
      /**
       * Increment this version if you change the SearchData interface.
       */
      const versionNumber: number = 1;
      super(defaultSearchData,
        versionNumber);
    }

    protected generateKey(): string {
      return 'UserId(' + LoggedInUserStorage.getInstance().getUserId() + ')-Search-Workflow';
    }

    protected fromRaw(data: StoredSearchRawData): NewBaseSearch.SearchDataResult<OrderField.Workflow> {
      const searchModel = new Model();
      searchModel.id = data.id;
      searchModel.externalId = data.externalId;
      searchModel.name = data.name;
      searchModel.versionState = data.versionState;
      const searchData: SearchData = {
        queryModel: OrderFieldModel.create<OrderField.Workflow>(OrderFunctions.VALUES, data.order, data.queryModel),
        searchModel: searchModel,
      };
      return {
        searchData: searchData
      };
    }

    protected toRaw(data: SearchData): StoredSearchRawData {
      return {
        version: this.VERSION,
        queryModel: data.queryModel,
        order: data.queryModel.createOrder(OrderFunctions.VALUES),
        id: data.searchModel.id,
        externalId: data.searchModel.externalId,
        name: data.searchModel.name,
        versionState: data.searchModel.versionState,
      };
    }

  }

}
