/* eslint-disable */
import { Component, OnInit, ViewChild, } from '@angular/core';
import { Transition, UIRouter } from '@uirouter/angular';
import { OwnerUserItem, UiConstants } from '../../../util/core-utils';
import { StateName } from '../../../app.state-names';
import { InputMask } from '../../../util/input-masks';
import { FormTableCreateModel } from '../../../util/form-table-utils';
import { FormTable, FormTableService } from '../../../lib/form/form-table.service';
import { FieldValidationError } from '../../../lib/util/services';
import { NgForm } from '@angular/forms';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { TranslateService } from '@ngx-translate/core';
import { Strings } from '../../../lib/util/strings';
import { ToasterService } from '../../../fork/angular2-toaster/src/toaster.service';
import { StringKey } from '../../../app.string-keys';
/* eslint-enable */

@Component({
  selector: 'app-form-table-create',
  templateUrl: 'form-table-create.component.html',
  styleUrls: ['form-table-create.component.scss']
})
export class FormTableCreateComponent implements OnInit {

  InputMask = InputMask;
  FormTable = FormTable;

  @ViewChild('f', { static: true })
  fForm: NgForm;

  model: FormTableCreateModel;
  ownerUsers: OwnerUserItem[];
  fieldErrors: FieldValidationError<FormTable.ValidatedField>;
  customerType: string;
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  constructor(private formTableService: FormTableService,
              private uiRouter: UIRouter,
              private toasterService: ToasterService,
              private translateService: TranslateService,
              private transition: Transition) {
    this.model = new FormTableCreateModel();
    this.ownerUsers = [];
    this.fieldErrors = FieldValidationError.empty<FormTable.ValidatedField>();
    this.customerType = this.transition.params().type;
  }

  ngOnInit() {
    this.translateService.get('FORM_TABLE_PANEL_HEADING_ADD').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('MENU_NAVBAR_FORM_TABLE').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.FORM_TABLE_LIST});
      }
    );
  }

  createFormTable() {
    this.formTableService.create({
      name: this.model.name,
      externalId: Strings.undefinedOrNonEmpty(this.model.externalId),
      description: this.model.description
    }).subscribe(
      (response: FormTable.FormTable) => {
        this.uiRouter.stateService.go(StateName.FORM_TABLE_EDIT, {id: response.id});
      },
      (error: any) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withForm(this.fForm);
          this.showValidationErrorToast();
        }
      }
    );
  }

  private showValidationErrorToast() {
    this.toasterService.pop({
      timeout: UiConstants.ToastTimeoutLong,
      type: UiConstants.toastTypeError,
      title: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_TITLE),
      body: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_MESSAGE)
    });
  }

  hasFieldError(field: FormTable.ValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: FormTable.ValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: FormTable.ValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  back() {
    window.history.back();
  }

}
