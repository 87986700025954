<app-form-field-rule-edit
  *ngIf="model.getModelType() === 'FORM_FIELD'"
  [model]="getFormFieldModel()"
  [operatorRequired]="operatorRequired"
  [create]="create"
  [taskIds]="taskIds"
  [form]="form"
  [readonly]="readonly"
  (createSubmitted)="createSubmitted.emit($event)"
  (deleteRule)="deleteRule.emit($event)">
</app-form-field-rule-edit>
<app-task-rule-edit
  *ngIf="model.getModelType() === 'TASK'"
  [model]="getTaskModel()"
  [operatorRequired]="operatorRequired"
  [create]="create"
  [readonly]="readonly"
  (createSubmitted)="createSubmitted.emit($event)"
  (deleteRule)="deleteRule.emit($event)">
</app-task-rule-edit>
