/* eslint-disable */
import { User, UserService } from './user.service';
import { UserProfile } from './auth.service';
import { Identities, Identity, IdentityArray } from './util/messages';
import { Injectable } from '@angular/core';
import { Arrays } from './util/arrays';
import { Observable, Observer } from 'rxjs';
import { Set } from 'immutable';
import { OrderType, ResourceQueryResult, Services } from './util/services';
/* eslint-enable */

export interface UserData extends User {
}

export enum UserDataLoaderPermissionDeniedStrategy {
  HANDLE_FUNCTION_DISABLED,
  MISS_FUNCTION_DISABLED,
  HANDLE_PERMISSION_DENIED,
  MISS_PERMISSION_DENIED,
  HANDLE_ALL,
  MISS_ALL,
}

@Injectable()
export class UserDataLoader {

  // NOTE: It should support UserMeService too.

  private static missPermissionDenied(permissionDeniedStrategy?: UserDataLoaderPermissionDeniedStrategy): boolean {
    if (!permissionDeniedStrategy) {
      permissionDeniedStrategy = UserDataLoaderPermissionDeniedStrategy.HANDLE_PERMISSION_DENIED;
    }
    return permissionDeniedStrategy === UserDataLoaderPermissionDeniedStrategy.MISS_PERMISSION_DENIED ||
      permissionDeniedStrategy === UserDataLoaderPermissionDeniedStrategy.MISS_ALL;
  }

  private static missFunctionDisabled(permissionDeniedStrategy?: UserDataLoaderPermissionDeniedStrategy): boolean {
    if (!permissionDeniedStrategy) {
      permissionDeniedStrategy = UserDataLoaderPermissionDeniedStrategy.HANDLE_FUNCTION_DISABLED;
    }
    return permissionDeniedStrategy === UserDataLoaderPermissionDeniedStrategy.MISS_FUNCTION_DISABLED ||
      permissionDeniedStrategy === UserDataLoaderPermissionDeniedStrategy.MISS_ALL;
  }

  constructor(private userService: UserService) {
  }

  public loadAllWithFields(permissionDeniedStrategy?: UserDataLoaderPermissionDeniedStrategy,
                           userGroupScope?: string, fields?: Set<string>): Observable<IdentityArray<UserData>> {
    const self = this;
    return Observable.create(function (observer: Observer<IdentityArray<UserData>>) {
      let counter = 0;
      const callCount = 1;
      const userList: IdentityArray<UserData> = new IdentityArray();
      const missPermissionDenied = UserDataLoader.missPermissionDenied(permissionDeniedStrategy);
      const missFunctionDisabled = UserDataLoader.missFunctionDisabled(permissionDeniedStrategy);
      const usersObservable = self.userService.query({
        missPermissionDenied: missPermissionDenied,
        missFunctionDisabled: missFunctionDisabled,
        user_group_scope: userGroupScope,
        fields: fields ? fields.join(',') : undefined,
        order: Services.createOrderFieldParameter(User.Keys.toOrderFieldKey,
          Set.of({field: User.OrderField.USER_NAME, type: OrderType.ASC}))
      })!;
      usersObservable.subscribe(
        (result: ResourceQueryResult<User>) => {
          Arrays.iterateByIndex(result.items, (user) => {
            userList.push(user);
          });
          counter++;
          if (counter === callCount) {
            userList.sort(this.compareById);
            observer.next(userList);
            observer.complete();
          }
        },
        (error: any) => {
          counter++;
          if (counter === callCount) {
            userList.sort(this.compareById);
            observer.next(userList);
            observer.complete();
          }
        }
      );
      return function () {
      };
    });
  }

  public loadAll(permissionDeniedStrategy?: UserDataLoaderPermissionDeniedStrategy,
                 userGroupScope?: string): Observable<IdentityArray<UserData>> {
    return this.loadAllWithFields(permissionDeniedStrategy, userGroupScope);
  }

  public load(profile: UserProfile,
              permissionDeniedStrategy?: UserDataLoaderPermissionDeniedStrategy): Observable<UserData> {
    return this._load(false, profile, permissionDeniedStrategy);
  }

  public loadMe(profile: UserProfile,
                permissionDeniedStrategy?: UserDataLoaderPermissionDeniedStrategy): Observable<UserData> {
    return this._load(true, profile, permissionDeniedStrategy);
  }

  private _load(me: boolean,
                profile: UserProfile,
                permissionDeniedStrategy?: UserDataLoaderPermissionDeniedStrategy): Observable<UserData> {
    const self = this;
    return Observable.create(function (observer: Observer<UserData>) {
      self.fromUser(
        me,
        profile.id,
        observer,
        UserDataLoader.missPermissionDenied(permissionDeniedStrategy),
        UserDataLoader.missFunctionDisabled(permissionDeniedStrategy));
      return function () {
      };
    });
  }

  private fromUser(me: boolean,
                   id: number,
                   observer: Observer<UserData>,
                   missPermissionDenied?: boolean,
                   missFunctionDisabled?: boolean): void {
    this.userService.get({
      id: id,
      missPermissionDenied: missPermissionDenied,
      missFunctionDisabled: missFunctionDisabled
    }).subscribe(
      (user: User) => {
        observer.next(user);
      },
      (error: any) => {
        observer.error(error);
      },
      () => {
        observer.complete();
      }
    );
  }

  private compareById(l: Identity, r: Identity) {
    return Identities.compareById(l, r);
  }

}
