/* eslint-disable */
import { List, Map, Set } from 'immutable';
import { Injectable, InjectionToken } from '@angular/core';
import { ProjectResource, ProjectResourceService } from './project-resource.service';
import { FieldValidationError, Order, PagingRequest, QueryResult, ResourceQueryResult, Services } from '../util/services';
import { Observable, Observer } from 'rxjs';
import { ObservableErrorResourceParser } from '../util/errors';
import { EmptyMessage, IdentityMessage } from '../util/messages';
import { Icon, IconService } from '../task/icon.service';
import { Form } from '../form/form.service';
import { OffsetDateTime } from '../util/dates';
import { FormResource } from '../form/form-resource.service';
import { DashboardCount } from '../util/dashboard-count.service';
import { DqlStoredQuery, DqlStoredQueryService } from '../dql/dql-stored-query.service';
import { DqlModel } from '../dql/dql.model';
import { DqlResourceModel } from '../dql/dql-resource.model';

/* eslint-enable */

@Injectable()
export class ProjectService implements Project.Service, Form.Service, DqlStoredQuery.HolderService, DashboardCount.Service {

  private formMapper: Form.ResourceMapper;

  private readonly _dqlStoredQueryService: DqlStoredQueryService;

  get dqlStoredQueryService(): DqlStoredQueryService {
    return this._dqlStoredQueryService;
  }

  query(request: Project.QueryRequest): Observable<QueryResult<Project.Project>> {
    return Observable.create((observer: Observer<QueryResult<Project.Project>>) => {
      const resourceRequest: ProjectResource.QueryRequest = {
        fields: request.fields ? request.fields.join(',') : undefined,
        order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
        with_form: false,
        rights: Services.createListParameter(request.rights),
        q: request.queryText,
        id: request.id ? request.id.join(',') : undefined,
        name: request.name,
        external_id: request.externalId,
        disabled: request.disabled,
        no_progress_bar: request.noProgressBar
      };
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<ProjectResource.Project>) => {
          observer.next({
            items: this.toPublicList(result.items),
            pagingResult: result.pagingResult
          });
        });
    });
  }

  get(request: Project.GetRequest): Observable<Project.Project> {
    return Observable.create((observer: Observer<Project.Project>) => {
      return this.resourceService.get({id: request.id, rights: Services.createListParameter(request.rights)}).subscribe(
        (result: ProjectResource.Project) => {
          observer.next(this.toPublic(result));
        }
      );
    });
  }

  create(request: Project.CreateRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: ProjectResource.CreateRequest = {
        name: request.name,
        external_id: request.externalId,
        description: request.description,
        icon: this.iconService.toResourceIcon(request.icon),
        explicit_order_number: request.explicitOrderNumber,
        related_user_groups: request.relatedUserGroups.toArray()
      };
      return this.resourceService.create(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        }
      )
    })
  }

  update(request: Project.UpdateRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: ProjectResource.UpdateRequest = {
        id: request.id,
        name: request.name,
        external_id: request.externalId,
        description: request.description,
        icon: this.iconService.toResourceIcon(request.icon),
        explicit_order_number: request.explicitOrderNumber,
        displayed_form_field: request.displayedFormField,
        related_user_groups: request.relatedUserGroups.toArray()
      };
      return this.resourceService.update(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        }
      );
    })
  }

  setDisabled(request: Project.DisableRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: ProjectResource.DisableRequest = {
        id: request.id,
        disabled: request.disabled,
      };
      return this.resourceService.setDisabled(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  getDashboardCount(request: EmptyMessage): Observable<Project.Count[]> {
    return Observable.create((observer: Observer<Project.Count[]>) => {
      return this.resourceService.counts({}).subscribe(
        (result: ProjectResource.Count[]) => {
          observer.next(this.toPublicCountList(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

// <editor-fold desc="Form">

  getForm(request: Form.GetFormRequest): Observable<Form.Form> {
    return Observable.create((observer: Observer<Form.Form>) => {
      const resourceRequest: FormResource.GetFormRequest =
        this.formMapper.toResourceGetFormRequest(request);
      return this.resourceService.getForm(resourceRequest).subscribe(
        (result: FormResource.Form) => {
          observer.next(this.formMapper.toPublicForm(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateGetFormError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  createGroup(request: Form.CreateGroupRequest): Observable<Form.CreateGroupResponse> {
    return Observable.create((observer: Observer<Form.CreateGroupResponse>) => {
      const resourceRequest: FormResource.CreateGroupRequest =
        this.formMapper.toResourceCreateGroupRequest(request);
      return this.resourceService.createFormGroup(resourceRequest).subscribe(
        (result: FormResource.CreateGroupResponse) => {
          observer.next(this.formMapper.toPublicCreateGroupResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateCreateGroupError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  updateGroup(request: Form.UpdateGroupRequest): Observable<Form.UpdateGroupResponse> {
    return Observable.create((observer: Observer<Form.UpdateGroupResponse>) => {
      const resourceRequest: FormResource.UpdateGroupRequest =
        this.formMapper.toResourceUpdateGroupRequest(request);
      return this.resourceService.updateFormGroup(resourceRequest).subscribe(
        (result: FormResource.UpdateGroupResponse) => {
          observer.next(this.formMapper.toPublicUpdateGroupResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateUpdateGroupError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  disableGroup(request: Form.DisableGroupRequest): Observable<Form.DisableGroupResponse> {
    return Observable.create((observer: Observer<Form.DisableGroupResponse>) => {
      const resourceRequest: FormResource.DisableGroupRequest =
        this.formMapper.toResourceDisableGroupRequest(request);
      return this.resourceService.disableFormGroup(resourceRequest).subscribe(
        (result: FormResource.DisableGroupResponse) => {
          observer.next(this.formMapper.toPublicDisableGroupResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateDisableGroupError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  moveGroup(request: Form.MoveGroupRequest): Observable<Form.MoveGroupResponse> {
    return Observable.create((observer: Observer<Form.MoveGroupResponse>) => {
      const resourceRequest: FormResource.MoveGroupRequest =
        this.formMapper.toResourceMoveGroupRequest(request);
      return this.resourceService.moveFormGroup(resourceRequest).subscribe(
        (result: FormResource.MoveGroupResponse) => {
          observer.next(this.formMapper.toPublicMoveGroupResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateMoveGroupError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  createField(request: Form.CreateFieldRequest): Observable<Form.CreateFieldResponse> {
    return Observable.create((observer: Observer<Form.CreateFieldResponse>) => {
      const resourceRequest: FormResource.CreateFieldRequest =
        this.formMapper.toResourceCreateFieldRequest(request);
      return this.resourceService.createFormField(resourceRequest).subscribe(
        (result: FormResource.CreateFieldResponse) => {
          observer.next(this.formMapper.toPublicCreateFieldResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateCreateFieldError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  updateField(request: Form.UpdateFieldRequest): Observable<Form.UpdateFieldResponse> {
    return Observable.create((observer: Observer<Form.UpdateFieldResponse>) => {
      const resourceRequest: FormResource.UpdateFieldRequest =
        this.formMapper.toResourceUpdateFieldRequest(request);
      return this.resourceService.updateFormField(resourceRequest).subscribe(
        (result: FormResource.UpdateFieldResponse) => {
          observer.next(this.formMapper.toPublicUpdateFieldResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateUpdateFieldError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  disableField(request: Form.DisableFieldRequest): Observable<Form.DisableFieldResponse> {
    return Observable.create((observer: Observer<Form.DisableFieldResponse>) => {
      const resourceRequest: FormResource.DisableFieldRequest =
        this.formMapper.toResourceDisableFieldRequest(request);
      return this.resourceService.disableFormField(resourceRequest).subscribe(
        (result: FormResource.DisableFieldResponse) => {
          observer.next(this.formMapper.toPublicDisableFieldResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateDisableFieldError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  moveFieldToGroup(request: Form.MoveFieldToGroupRequest): Observable<Form.MoveFieldToGroupResponse> {
    return Observable.create((observer: Observer<Form.MoveFieldToGroupResponse>) => {
      const resourceRequest: FormResource.MoveFieldToGroupRequest =
        this.formMapper.toResourceMoveFieldToGroupRequest(request);
      return this.resourceService.moveFormFieldToGroup(resourceRequest).subscribe(
        (result: FormResource.MoveFieldToGroupResponse) => {
          observer.next(this.formMapper.toPublicMoveFieldToGroupResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateMoveFieldToGroupError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  importForm(request: Form.ImportRequest): Observable<Form.Form> {
    return Observable.create((observer: Observer<Form.Form>) => {
      return this.resourceService.import({
        parent_id: request.parentId,
        ignore_disabled_items: request.ignoreDisabledFields,
        form: request.form,
        version: request.version
      }).subscribe((result: FormResource.Form) => {
          observer.next(this.formMapper.toPublicForm(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        }
      );
    });
  }

  exportForm(request: Form.ExportRequest): Observable<Form.FormImportDocument> {
    return Observable.create((observer: Observer<Form.FormImportDocument>) => {
      return this.resourceService.export({
        parent_id: request.parentId,
      }).subscribe((result: FormResource.FormImportDocument) => {
          observer.next({form: result.form, version: result.version});
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        }
      );
    });
  }

  getDqlModel(request: Project.DqlRequest): Observable<DqlModel.QueryableModel> {
    return Observable.create((observer: Observer<DqlModel.QueryableModel>) => {
      return this.resourceService.getDqlModel({
        project_id: request.projectId
      }).subscribe((result: DqlResourceModel.QueryableModel) => {
          observer.next(new DqlModel.DqlQueryableModelConverter(!request.projectId).toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        }
      );
    });
  }

  // </editor-fold>

  constructor(private resourceService: ProjectResourceService,
              private iconService: IconService) {
    this.formMapper = new Form.ResourceMapper();
    this._dqlStoredQueryService = new DqlStoredQueryService(resourceService.dqlStoredResourceService);
  }

  private translateError(error: any): any {
    const res = ObservableErrorResourceParser.parseError(error);
    const fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
    const fieldErrorMap = ObservableErrorResourceParser.toFieldErrorMap(Keys.toValidatedField, fieldErrors);
    if (!fieldErrorMap.isEmpty()) {
      return FieldValidationError.of(fieldErrorMap);
    }
    return error;
  }

  private toPublicList(resourceList: ProjectResource.Project[]): List<Project.Project> {
    return List.of(...resourceList.map((r) => this.toPublic(r)));
  }

  private toPublic(r: ProjectResource.Project): Project.Project {
    return {
      id: r.id,
      externalId: r.external_id,
      disabled: r.disabled,
      creationTime: Services.toOffsetDateTime(r.creation_time),
      updateTime: Services.toOffsetDateTime(r.update_time),
      name: r.name,
      description: r.description,
      form: r.form ? this.formMapper.toPublicForm(r.form) : undefined,
      icon: this.iconService.toPublicIcon(r.icon),
      explicitOrderNumber: r.explicit_order_number,
      displayedFormField: r.displayed_form_field ? this.formMapper.toPublicField(r.displayed_form_field) : undefined,
      relatedUserGroups: Set.of(...r.related_user_groups),
      grantedRights: r.granted_rights ? Set.of(...r.granted_rights) : Set.of(),
    };
  }

  private toPublicCountList(resList: ProjectResource.Count[]): Project.Count[] {
    return resList.map((s) => this.toPublicCount(s));
  }

  private toPublicCount(r: ProjectResource.Count): Project.Count {
    const item = new Project.Count();
    item.projectId = r.project_id;
    item.projectName = r.project_name;
    item.projectRecordCount = r.project_record_count;
    return item;
  }

}

export namespace Project {

  export interface Service {

    query(request: QueryRequest): Observable<QueryResult<Project>>;

    get(request: IdentityMessage): Observable<Project>;

    create(request: CreateRequest): Observable<EmptyMessage>;

    update(request: UpdateRequest): Observable<EmptyMessage>;

    setDisabled(request: DisableRequest): Observable<EmptyMessage>;

  }

  export interface QueryRequest {
    fields?: Set<string>;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
    with_form?: boolean;
    rights?: Set<string>;

    queryText?: string;
    id?: Set<number>;
    name?: string;
    externalId?: string;
    disabled?: boolean;
    noProgressBar?: boolean;
  }

  export interface GetRequest {
    id: number;
    rights?: Set<string>;
  }

  export interface CreateRequest {
    name: string;
    externalId?: string;
    description?: string;
    icon?: Icon.Icon;
    explicitOrderNumber?: number;
    relatedUserGroups: Set<number>;
  }

  export interface UpdateRequest {
    id: number;
    name: string;
    externalId: string;
    description?: string;
    icon?: Icon.Icon;
    explicitOrderNumber?: number;
    relatedUserGroups: Set<number>;
    displayedFormField?: number;
  }

  export interface DisableRequest extends IdentityMessage {
    disabled: boolean;
  }

  export interface Project {
    id: number;
    externalId: string;
    disabled: boolean;
    creationTime: OffsetDateTime;
    updateTime: OffsetDateTime;
    name: string;
    description?: string;
    form?: Form.Form;
    icon?: Icon.Icon;
    explicitOrderNumber?: number;
    relatedUserGroups: Set<number>;
    displayedFormField?: Form.Field;
    grantedRights: Set<string>;
  }

  export class Count implements DashboardCount.Item {
    projectId: number;
    projectName: string;
    projectRecordCount: number;

    getId(): number {
      return this.projectId;
    }

    getTitle(): string {
      return this.projectName;
    }

    getCount(): number {
      return this.projectRecordCount;
    }
  }

  export interface DqlRequest {
    projectId?: number;
  }


  export enum OrderField {
    ID,
    DISABLED,
    CREATION_TIME,
    UPDATE_TIME,
    NAME,
    EXTERNAL_ID,
    EXPLICIT_ORDER_NUMBER
  }

  export enum ValidatedField {
    UNKNOWN,
    NAME,
    EXTERNAL_ID,
    EXPLICIT_ORDER_NUMBER,
  }

}

class Keys {

  private static readonly ID = 'id';
  private static readonly DISABLED = 'disabled';
  private static readonly CREATION_TIME = 'creation_time';
  private static readonly UPDATE_TIME = 'update_time';
  private static readonly NAME = 'name';
  private static readonly EXTERNAL_ID = 'external_id';
  private static readonly EXPLICIT_ORDER_NUMBER = 'explicit_order_number';

  private static readonly orderFieldKeyMap: Map<Project.OrderField, string> = Map.of(
    Project.OrderField.ID, Keys.ID,
    Project.OrderField.DISABLED, Keys.DISABLED,
    Project.OrderField.CREATION_TIME, Keys.CREATION_TIME,
    Project.OrderField.UPDATE_TIME, Keys.UPDATE_TIME,
    Project.OrderField.NAME, Keys.NAME,
    Project.OrderField.EXTERNAL_ID, Keys.EXTERNAL_ID,
    Project.OrderField.EXPLICIT_ORDER_NUMBER, Keys.EXPLICIT_ORDER_NUMBER,
  );

  private static readonly keyValidatedFieldMap: Map<string, Project.ValidatedField> = Map.of(
    Keys.NAME, Project.ValidatedField.NAME,
    Keys.EXTERNAL_ID, Project.ValidatedField.EXTERNAL_ID,
    Keys.EXPLICIT_ORDER_NUMBER, Project.ValidatedField.EXPLICIT_ORDER_NUMBER,
  );

  public static toOrderFieldKey(field: Project.OrderField): string {
    return Keys.orderFieldKeyMap.get(field)!;
  }

  public static toValidatedField(fieldKey: string): Project.ValidatedField {
    return Keys.keyValidatedFieldMap.get(fieldKey, Project.ValidatedField.UNKNOWN);
  }

}

export const PROJECT_SERVICE = new InjectionToken<ProjectService>('ProjectService');

export const PROJECT_SERVICE_PROVIDER = {
  provide: PROJECT_SERVICE,
  useClass: ProjectService
};
