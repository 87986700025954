<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div class="breadcrumb-menu d-flex">
    <div *ngIf="rightModel.emailTemplateCreate.hasRight()"
         uiSref="Admin.EmailTemplateCreate"
         class="breadcrumb-button-icon-container negative-margin-right cursor-pointer"
    >
      <a class="btn-setting" title="{{'EMAIL_TEMPLATE_CREATE' | translate}}" >
        <i class="icomoon icomoon-add"></i>
        <span class="breadcrumb-button-text-visible">{{'EMAIL_TEMPLATE_CREATE' | translate}}</span>
      </a>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>

<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="card">

    <div class="card-header">
      {{'EMAIL_TEMPLATE_LIST_HEADER' | translate}}

      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()"
           title="{{'COMMON_SHOW_SEARCH_HINT' | translate}}"><i class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>
    <div class="card-body border-bottom" *ngIf="showSearch">
      <div class="row form-group">
        <div class="col-md-6">
          <label class="search-label">{{'EMAIL_TEMPLATE_NAME' | translate}}</label>
          <input type="text" class="form-control"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 placeholder="{{'EMAIL_TEMPLATE_NAME' | translate}}"
                 [(ngModel)]="searchModel.name">
        </div>
        <div class="col-md-6">
          <label class="search-label"> {{ 'COMMON_STATE' | translate }} </label>
          <select
            class="form-control"
            [(ngModel)]="searchModel.disabled" [compareWith]="SelectUtils.compareObjects">
            <option *ngFor="let item of disabledItems" [ngValue]="item">{{item.text}}</option>
          </select>
        </div>
      </div>
      <div class="col-md-12 d-flex justify-content-md-end align-items-end px-0">
        <div class="btn-group" role="group">
          <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
            {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
          </button>
          <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
            {{'COMMON_BUTTON_SEARCH' | translate}}
          </button>
        </div>
      </div>
    </div><!-- /.card-body-->

    <div class="card-body">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="EmailTemplate.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(EmailTemplate.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="EmailTemplate.OrderField.NAME"
                                    [orderType]="queryModel.getOrderType(EmailTemplate.OrderField.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'EMAIL_TEMPLATE_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-field-sorter [orderField]="EmailTemplate.OrderField.CREATION_TIME"
                                    [orderType]="queryModel.getOrderType(EmailTemplate.OrderField.CREATION_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_CREATION_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-field-sorter [orderField]="EmailTemplate.OrderField.UPDATE_TIME"
                                    [orderType]="queryModel.getOrderType(EmailTemplate.OrderField.UPDATE_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_UPDATE_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let emailTemplate of emailTemplateList | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }" [disabledTableRow]="emailTemplate.disabled">
          <td class="fit align-middle">{{emailTemplate.id}}</td>
          <td class="responsive-table-column long">
            <div class="d-flex justify-content-start align-items-center">
              <div>
                <a class="id-text cursor-pointer"
                   href="#" onclick="return false"
                   uiSref="Admin.EmailTemplateDetail"
                   [uiParams]="{ id: emailTemplate.id }"
                   title="{{'COMMON_BUTTON_DETAILS' | translate}}">
                  {{emailTemplate.name}}
                </a>
                <div class="table-text-subtitle">
                  {{emailTemplate.externalId}}
                </div>
              </div>
            </div>
          </td>
          <td class="responsive-table-column hidden-md-down align-middle">{{emailTemplate.creationTime.toUtcIsoString() | date:'short'}}</td>
          <td class="responsive-table-column hidden-md-down align-middle">{{emailTemplate.updateTime.toUtcIsoString() | date:'short'}}</td>
          <td class="align-middle">
            <app-table-options-menu>
              <app-dropdown-item
                [iconClass]="'icomoon-detail'"
                [titleStringKey]="'COMMON_BUTTON_DETAILS'"
                class="cursor-pointer"
                uiSref="Admin.EmailTemplateDetail"
                [uiParams]="{ id: emailTemplate.id }">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="rightModel.emailTemplateUpdate.hasRight()"
                [iconClass]="'icomoon-modify'"
                [titleStringKey]="'COMMON_BUTTON_EDIT'"
                class="cursor-pointer"
                uiSref="Admin.EmailTemplateEdit"
                [uiParams]="{ id: emailTemplate.id }">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="emailTemplate.disabled && rightModel.emailTemplateDisable.hasRight()"
                [iconClass]="'icomoon-enable'"
                [titleStringKey]="'COMMON_BUTTON_ENABLE'"
                [itemType]="DropdownItemType.ENABLE"
                class="cursor-pointer"
                (click)="setDisabled($event, emailTemplate, false)">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="!emailTemplate.disabled && rightModel.emailTemplateDisable.hasRight()"
                [iconClass]="'icomoon-disable'"
                [titleStringKey]="'COMMON_BUTTON_DISABLE'"
                [itemType]="DropdownItemType.DISABLE"
                class="cursor-pointer"
                (click)="setDisabled($event, emailTemplate, true)">
              </app-dropdown-item>
            </app-table-options-menu>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="EmailTemplate.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(EmailTemplate.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="EmailTemplate.OrderField.NAME"
                                    [orderType]="queryModel.getOrderType(EmailTemplate.OrderField.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'EMAIL_TEMPLATE_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-field-sorter [orderField]="EmailTemplate.OrderField.CREATION_TIME"
                                    [orderType]="queryModel.getOrderType(EmailTemplate.OrderField.CREATION_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_CREATION_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-field-sorter [orderField]="EmailTemplate.OrderField.UPDATE_TIME"
                                    [orderType]="queryModel.getOrderType(EmailTemplate.OrderField.UPDATE_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_UPDATE_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>
  </div>
</div>
