/* eslint-disable */
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, } from '@angular/core';
import { Transition, UIRouter } from '@uirouter/angular';
import { combineLatest, Observable, Subject } from 'rxjs';
import { CustomerRecord, CustomerRecordService } from '../../../lib/customer/customer-record.service';
import { Customer, CustomerService } from '../../../lib/customer/customer.service';
import { CustomerRecordBase } from '../customer-record-base/customer-record-base-view';
import { CustomerRecordBaseComponent } from '../customer-record-base/customer-record-base.component';
import { StateName } from '../../../app.state-names';
import { FieldValidationError, QueryResult, Services, } from '../../../lib/util/services';
import { FormRecordContainer } from '../../form/form-record/form-record-container';
import { FormRecordContainerComponent } from '../../form/form-record/form-record-container.component';
import { Form } from '../../../lib/form/form.service';
import { NgForm } from '@angular/forms';
import { ConfigurationService } from '../../../lib/core-ext/configuration.service';
import { LoadingHandler } from '../../../lib/loading-handler';
import { AddressModel, } from '../../../lib/address';
import { Country, CountryService, } from '../../../lib/country.service';
import { UserDataLoader, UserDataLoaderPermissionDeniedStrategy, } from '../../../lib/user-data-loader';
import { UiConstants, } from '../../../util/core-utils';
import { UserGroup, UserGroupService, } from '../../../lib/user-group.service';
import { StringKey } from '../../../app.string-keys';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { ConfirmLeaveModalComponent } from '../../../shared/confirm-leave-modal/confirm-leave-modal.component';
import { map } from 'rxjs/operators';
import { ToasterService } from '../../../fork/angular2-toaster/angular2-toaster';
import { List, Set } from 'immutable';
import { Models } from '../../../util/model-utils';
import { CustomerRecordFieldType } from '../../../util/customer-record-utils';
import PostalAddressModelType = AddressModel.PostalAddressModelType;

/* eslint-enable */

@Component({
  selector: 'app-customer-record-clone',
  templateUrl: 'customer-record-clone.component.html',
  styleUrls: ['customer-record-clone.component.scss'],
})
export class CustomerRecordCloneComponent implements OnInit, AfterViewInit, OnDestroy {
  UiConstants = UiConstants;

  @ViewChild(CustomerRecordBaseComponent, { static: true })
  baseView: CustomerRecordBase.View;

  @ViewChild(CustomerRecordBaseComponent, { static: true })
  baseComponent: CustomerRecordBaseComponent;

  @ViewChild(FormRecordContainerComponent, { static: true })
  formRecordContainerView: FormRecordContainer.View;

  @ViewChild('f', { static: true })
  fForm: NgForm;

  @ViewChild('warningDialog', { static: true }) warningDialog: ModalDirective;
  warningDialogVisible: boolean = false;

  private customerId: number;
  private customerRecordId: number;
  combinedResult?: CombinedResult;
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  deregisterWarningModal;
  saveButtonClicked: boolean = false;
  navigateOnSubmit: boolean = false;

  constructor(
    private toasterService: ToasterService,
    private translateService: TranslateService,
    private userDataLoader: UserDataLoader,
    private configurationService: ConfigurationService,
    private countryService: CountryService,
    private modalService: BsModalService,
    private customerService: CustomerService,
    private customerRecordService: CustomerRecordService,
    private uiRouter: UIRouter,
    private transition: Transition) {
    this.customerId = this.transition.params().customerId;
    this.customerRecordId = this.transition.params().customerRecordId;
  }

  ngOnInit() {
    this.initBreadcrumb();
    const context = this;
    this.deregisterWarningModal = this.uiRouter.transitionService.onStart({}, function(transition) {
      if (context.saveButtonClicked
        || transition.targetState().name() === StateName.CONNECTION_ERROR
        || transition.targetState().name() === StateName.SERVER_ERROR
        || transition.targetState().name() === StateName.LOGIN) {
        return true;
      }
      else {
        const subject = new Subject<boolean>();
        const modal = context.modalService.show(ConfirmLeaveModalComponent, {backdrop: 'static',  keyboard: false});
        modal.content!.subject = subject;
        return subject.asObservable().toPromise();
      }
    });
  }

  ngAfterViewInit(): void {
    this.createCombinedObservable().subscribe(
      (result: CombinedResult) => {
        this.breadcrumbSelf = result.customerRecord.name;
        this.baseView.loadCustomerRecord({
          customerRecord: result.customerRecord,
          countryItems: AddressModel.CountryItem.fromCountryList(result.countries.items),
          customer: result.customer,
          defaultSelectedCountryCode: this.configurationService.getDefaultSelectedCountryCode(),
          isClone: true,
          isCreate: false
        });
        this.formRecordContainerView.loadFormRecord({
          configuration: this.configurationService.getConfiguration(),
          form: result.form,
          record: result.customerRecord.formRecord,
        });
        this.combinedResult = result;
      }
    );
  }

  private initBreadcrumb() {
    this.translateService.get('COMMON_CUSTOMERS').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.CUSTOMER_DASHBOARD});
      }
    );
    if (this.customerId) {
      this.customerService.get({
        customerId: this.customerId,
      }).subscribe(
        (result: Customer.Customer) => {
          this.breadcrumbParents.push({name: result.name, uiSref: StateName.CUSTOMER_RECORD_LIST, uiParam: {customerId: this.customerId}});
        }
      )
    }
    else {
      this.translateService.get('COMMON_CUSTOMERS').subscribe(
        (result: string) => {
          this.breadcrumbParents.push({name: result, uiSref: StateName.CUSTOMER_RECORD_GLOBAL_LIST});
        }
      );
    }
  }

  createCustomerRecord() {
    if (!this.combinedResult) {
      return;
    }
    if (this.baseView.hasLocalFieldError() || this.formRecordContainerView.hasLocalFieldError()) {
      if (this.baseView.hasLocalFieldError('emailAddresses')) {
        this.showValidationErrorToast(StringKey.CUSTOMER_RECORD_EMAIL_ADDRESSES_REQUIRED);
      }
      else if (this.baseView.hasLocalFieldError('phoneNumbers')) {
        this.showValidationErrorToast(StringKey.CUSTOMER_RECORD_PHONE_NUMBERS_REQUIRED);
      }
      else {
        this.showValidationErrorToast();
      }
      return;
    }
    const model = this.baseView.getModel();
    if (this.baseView.getFieldModel().isRequired(CustomerRecordFieldType.ASSIGNEE)
      && model.users.length === 0 && model.userGroups.length === 0) {
      return;
    }
    if (this.formRecordContainerView.validateWithInterrupt()) {
      return;
    }
    const formModel = this.formRecordContainerView.createModel();

    let parentId: number | undefined = undefined;
    if (model.customerRecordParents.length !== 0) {
      parentId = model.customerRecordParents[0].id!;
    }

    const createRequest: CustomerRecord.CreateRequest = {
      withFormRecord: false,
      customerId: this.customerId,
      name: model.name,
      firstName: model.firstName,
      lastName: model.lastName,
      externalId: model.externalId,
      comment: model.comment,
      position: model.position,
      webAddress: model.webAddress,
      mainContractNumber: model.mainContractNumber,
      taxNumber: model.taxNumber.truncated,
      euTaxNumber: model.euTaxNumber.truncated,
      ownerUserIds: model.getUserIds(),
      ownerGroupIds: model.getUserGroupIds(),
      companyId: model.getCompanyId(),
      ledgerNumber: model.getLedgerNumberId(),
      postalAddress: model.postalAddress.toData(),
      notificationAddress: model.notificationAddress.toData(),
      notificationAddressEqPostal: model.notificationAddressEqPostal,
      emailAddresses: AddressModel.EmailAddressModel.toDataList(model.emailAddresses),
      phoneNumbers: AddressModel.PhoneNumberModel.toDataList(model.phoneNumbers),
      formRecord: {
        fields: formModel.fields
      },
      parentId: parentId,
      invoiceDeadlineAdditionalDays: model.invoiceDeadlineAdditionalDays,
      taskRecordDeadlineAdditionalDays: Models.optNumber(model.taskRecordDeadlineAdditionalDays),
      // PERSON type
      placeOfBirth: model.placeOfBirth,
      dateOfBirth: model.dateOfBirth,
      birthName: model.birthName,
      gender: model.gender,
      mothersName: model.mothersName,
      identityDocumentType: model.identityDocumentType,
      identityDocumentNumber: model.identityDocumentNumber,
    };
    this.customerRecordService.create(createRequest).subscribe(
      (cr: CustomerRecord.CustomerRecord) => {
        LoadingHandler.getInstance().refresh();
        this.saveButtonClicked = true;
        if (this.navigateOnSubmit) {
          this.uiRouter.stateService.go(StateName.CUSTOMER_RECORD_LIST, {customerId: this.customerId});
        }
        else {
          this.uiRouter.stateService.go(
            StateName.CUSTOMER_RECORD_EDIT, {customerId: cr.customerId, customerRecordId: cr.customerRecordId});
        }
      },
      (error) => {
        if (error instanceof FieldValidationError) {
          this.baseView.onFieldValidationError(error.withForm(this.fForm));
          this.showValidationErrorToast();
        }
      }
    );
  }

  private createCombinedObservable(): Observable<CombinedResult> {
    return combineLatest(
      this.customerService.get({
        customerId: this.customerId,
        withForm: true
      }),
      this.customerRecordService.get({
        fields: CustomerRecord.editFields,
        withFormRecord: true,
        customerId: this.customerId,
        customerRecordId: this.customerRecordId
      }),
      this.countryService.query({
      })
      ).pipe(map(([
        customer,
        customerRecord,
        countries]) => {
      return {
        form: customer.form!,
        customer: customer,
        customerRecord: customerRecord,
        countries: countries,
      }
    }));
  }

  private showValidationErrorToast(bodyStringKey?: string) {
    this.toasterService.pop({
      timeout: UiConstants.ToastTimeoutLong,
      type: UiConstants.toastTypeError,
      title: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_TITLE),
      body: this.translateService.instant(bodyStringKey ? bodyStringKey : StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_MESSAGE)
    });
  }

  submit(navigateOnSubmit: boolean) {
    this.navigateOnSubmit = navigateOnSubmit;
  }

  openWarningDialog() {
    this.warningDialogVisible = true;
    this.warningDialog.show();
  }

  closeWarningDialog() {
    this.warningDialogVisible = false;
    this.warningDialog.hide();
  }

  ngOnDestroy() {
    this.deregisterWarningModal();
  }

}

interface CombinedResult {
  form: Form.Form;
  customer: Customer.Customer;
  customerRecord: CustomerRecord.CustomerRecord;
  countries: QueryResult<Country.Country>;
}
