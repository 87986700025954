import { DqlQuery, Query } from '../field';

export class InvoiceTag {

  readonly id: Query.NumberField;
  readonly invoiceTag: Query.StringField;
  readonly description: Query.StringField;
  readonly creationTime: Query.DateTimeField;
  readonly disabled: Query.BooleanField;

  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this.id = new DqlQuery.NumberField(prefix + 'id');
    this.invoiceTag = new DqlQuery.StringField(prefix + 'invoice_tag');
    this.description = new DqlQuery.StringField(prefix + 'description');
    this.disabled = new DqlQuery.BooleanField(prefix + 'disabled');
    this.creationTime = new DqlQuery.DateTimeField(prefix + 'creation_time');
  }

}
