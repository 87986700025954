/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { Order, PagingRequest, QueryResult, ResourceQueryResult, Services } from '../util/services';
import { EmptyMessage } from '../util/messages';
import { TableDocumentSchemaResource, TableDocumentSchemaResourceService } from './table-document-schema-resource.service';
import { List, Map, Set } from 'immutable';
import { OffsetDateTime } from '../util/dates';
import { TableDocumentSchemaFieldResource, TableDocumentSchemaFieldResourceService, } from './table-document-schema-field-resource.service';
import {
  TableDocumentImportExportResource,
  TableDocumentSchemaImportExportResourceService
} from './table-document-schema-import-export-resource.service';
import TableDocumentSchemaFieldTypeState = TableDocumentSchemaField.TableDocumentSchemaFieldTypeState;
/* eslint-enable */


@Injectable()
export class TableDocumentSchemaService implements TableDocumentSchema.Service,
  TableDocumentSchemaField.Service,
  TableDocumentSchemaImportExport.Service {

  constructor(private resourceService: TableDocumentSchemaResourceService,
              private fieldResourceService: TableDocumentSchemaFieldResourceService,
              private importExportResourceService: TableDocumentSchemaImportExportResourceService) {
  }

  query(request: TableDocumentSchema.QueryRequest): Observable<QueryResult<TableDocumentSchema.TableDocumentSchema>> {
    return Observable.create((observer: Observer<QueryResult<TableDocumentSchema.TableDocumentSchema>>) => {
      const resourceRequest: TableDocumentSchemaResource.QueryRequest = {
        version_state: request.versionState,
        order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
        name: request.name,
        code: request.code,
        version: request.version,
      };
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<TableDocumentSchemaResource.TableDocumentSchema>) => {
          observer.next({
            items: this.toPublicList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  get(request: TableDocumentSchema.GetRequest): Observable<TableDocumentSchema.TableDocumentSchema> {
    return Observable.create((observer: Observer<TableDocumentSchema.TableDocumentSchema>) => {
      const resourceRequest: TableDocumentSchemaResource.GetRequest = {
        schema_id: request.schemaId
      };
      return this.resourceService.get(resourceRequest).subscribe(
        (result: TableDocumentSchemaResource.TableDocumentSchema) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  create(request: TableDocumentSchema.CreateRequest): Observable<TableDocumentSchema.CreateItem> {
    return Observable.create((observer: Observer<TableDocumentSchema.CreateItem>) => {
      const resourceRequest: TableDocumentSchemaResource.CreateRequest = {
        code: request.code,
        name: request.name,
        note: request.note,
      };
      return this.resourceService.create(resourceRequest).subscribe(
        (result: TableDocumentSchemaResource.CreateItem) => {
          observer.next({
            schemaId: result.schema_id
          });
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  clone(request: TableDocumentSchema.CloneRequest): Observable<TableDocumentSchema.CreateItem> {
    return Observable.create((observer: Observer<TableDocumentSchema.CreateItem>) => {
      const resourceRequest: TableDocumentSchemaResource.CloneRequest = {
        schema_id: request.schemaId,
        code: request.code,
        name: request.name,
        note: request.note,
      };
      return this.resourceService.clone(resourceRequest).subscribe(
        (result: TableDocumentSchemaResource.CreateItem) => {
          observer.next({
            schemaId: result.schema_id
          });
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  update(request: TableDocumentSchema.UpdateRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: TableDocumentSchemaResource.UpdateRequest = {
        schema_id: request.schemaId,
        code: request.code,
        name: request.name,
        note: request.note,
      };
      return this.resourceService.update(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  delete(request: TableDocumentSchema.DeleteRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: TableDocumentSchemaResource.DeleteRequest = {
        schema_id: request.schemaId,
      };
      return this.resourceService.delete(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  finalize(request: TableDocumentSchema.FinalizeRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: TableDocumentSchemaResource.FinalizeRequest = {
        schema_id: request.schemaId,
      };
      return this.resourceService.finalize(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  createNext(request: TableDocumentSchema.CreateNextRequest): Observable<TableDocumentSchema.CreateNextResponse> {
    return Observable.create((observer: Observer<TableDocumentSchema.CreateNextResponse>) => {
      const resourceRequest: TableDocumentSchemaResource.CreateNextRequest = {
        schema_id: request.schemaId,
        with_fields: request.withFields
      };
      return this.resourceService.createNext(resourceRequest).subscribe(
        (result: TableDocumentSchema.CreateNextResponse) => {
          observer.next({schema_id: result.schema_id});
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  deprecate(request: TableDocumentSchema.DeprecateRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: TableDocumentSchemaResource.DeprecateRequest = {
        schema_id: request.schemaId,
      };
      return this.resourceService.deprecate(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  private toPublicList(resourceList: TableDocumentSchemaResource.TableDocumentSchema[]): List<TableDocumentSchema.TableDocumentSchema> {
    return List.of(...resourceList.map((r) => this.toPublic(r)));
  }

  private toPublic(r: TableDocumentSchemaResource.TableDocumentSchema): TableDocumentSchema.TableDocumentSchema {
    return {
      schemaId: r.schema_id,
      creationTime: Services.toOffsetDateTime(r.creation_time),
      version: r.version,
      versionState: <TableDocumentSchema.TableDocumentSchemaVersionState> r.version_state,
      code: r.code,
      name: r.name,
      note: r.note,
    };
  }

  queryField(request: TableDocumentSchemaField.QueryRequest):
    Observable<List<TableDocumentSchemaField.TableDocumentSchemaField>> {
    return Observable.create((observer: Observer<List<TableDocumentSchemaField.TableDocumentSchemaField>>) => {
      const resourceRequest: TableDocumentSchemaFieldResource.QueryRequest = {
       schema_id: request.schemaId
      };
      return this.fieldResourceService.query(resourceRequest).subscribe(
        (result: TableDocumentSchemaFieldResource.TableDocumentSchemaField[]) => {
          observer.next(
            this.toPublicFieldList(result)
          );
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  createField(request: TableDocumentSchemaField.CreateRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: TableDocumentSchemaFieldResource.CreateRequest = {
        schema_id: request.schemaId,
        code: request.code,
        name: request.name,
        note: request.note,
        required: request.required,
        data_type: request.dataType
      };
      return this.fieldResourceService.create(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  updateField(request: TableDocumentSchemaField.UpdateRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: TableDocumentSchemaFieldResource.UpdateRequest = {
        schema_id: request.schemaId,
        schema_field_id: request.schemaFieldId,
        code: request.code,
        name: request.name,
        note: request.note,
        required: request.required,
        data_type: request.dataType
      };
      return this.fieldResourceService.update(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  deleteField(request: TableDocumentSchemaField.DeleteRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: TableDocumentSchemaFieldResource.DeleteRequest = {
        schema_id: request.schemaId,
        schema_field_id: request.schemaFieldId
      };
      return this.fieldResourceService.delete(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  private toPublicFieldList(resourceList: TableDocumentSchemaFieldResource.TableDocumentSchemaField[]):
    List<TableDocumentSchemaField.TableDocumentSchemaField> {
    return List.of(...resourceList.map((r) => this.toPublicField(r)));
  }

  private toPublicField(r: TableDocumentSchemaFieldResource.TableDocumentSchemaField): TableDocumentSchemaField.TableDocumentSchemaField {
    return {
      fieldId: r.field_id,
      schemaFieldId: r.schema_field_id,
      code: r.code,
      name: r.name,
      note: r.note,
      required: r.required,
      dataType: r.data_type
    };
  }

  exportSchema(request: TableDocumentSchemaImportExport.ExportRequest): Observable<TableDocumentSchemaImportExport.ImportDocument> {
    return Observable.create((observer: Observer<TableDocumentSchemaImportExport.ImportDocument>) => {
      return this.importExportResourceService.export({
        schema_id: request.schemaId,
      }).subscribe((result: TableDocumentImportExportResource.ImportDocument) => {
          observer.next({descriptor: result.descriptor, version: result.version});
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        }
      );
    });
  }
    importSchema(request: TableDocumentSchemaImportExport.ImportDocument): Observable<EmptyMessage> {
      return Observable.create((observer: Observer<TableDocumentSchemaImportExport.ImportDocument>) => {
        return this.importExportResourceService.import({
          descriptor: request.descriptor,
          version: request.version
      }).subscribe((result: TableDocumentImportExportResource.ImportDocument) => {
            observer.next({descriptor: result.descriptor, version: result.version});
          },
          (error: Error) => {
            observer.error(error);
          },
          () => {
            observer.complete();
          }
        );
      });
    }

}


export namespace TableDocumentSchema {

  export interface Service {

    // <editor-fold desc="CRUD">

    query(request: QueryRequest): Observable<QueryResult<TableDocumentSchema>>;

    get(request: GetRequest): Observable<EmptyMessage>;

    create(request: CreateRequest): Observable<CreateItem>;

    clone(request: CloneRequest): Observable<CreateItem>;

    update(request: UpdateRequest): Observable<EmptyMessage>;

    delete(request: DeleteRequest): Observable<EmptyMessage>;

    finalize(request: FinalizeRequest): Observable<EmptyMessage>;

    createNext(request: CreateNextRequest): Observable<CreateNextResponse>;

    deprecate(request: DeprecateRequest): Observable<EmptyMessage>;



    // </editor-fold>

  }

  export interface TableDocumentSchema {
    schemaId: number;
    creationTime?: OffsetDateTime;
    version?: number;
    versionState: TableDocumentSchemaVersionState;
    code: string;
    name: string;
    note: string;
  }

  export interface QueryRequest {
    versionState?: TableDocumentSchemaVersionState;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
    name?: string;
    code?: string;
    version?: string;
  }

  export interface GetRequest {
    schemaId: number;
  }

  export interface CreateRequest {
    code: string;
    name: string;
    note: string;
  }

  export interface CloneRequest {
    schemaId: number;
    code: string;
    name: string;
    note: string;
  }

  export interface CreateItem {
    schemaId: number;
  }

  export interface UpdateRequest {
    schemaId: number;
    code: string;
    name: string;
    note: string;
  }

  export interface DeleteRequest {
    schemaId: number;
  }

  export interface FinalizeRequest {
    schemaId: number;
  }

  export interface CreateNextRequest {
    schemaId: number;
    withFields: boolean;
  }
  export interface CreateNextResponse {
    schema_id: number;
  }

  export interface DeprecateRequest {
    schemaId: number;
  }

  export type TableDocumentSchemaVersionState =
    'DRAFT' |
    'FINALIZED' |
    'DEPRECATED';

  export class TableDocumentSchemaVersionStateObject {
    state: TableDocumentSchemaVersionState;
    stringKey: string;
  }

  export const tableDocumentSchemaVersionStates: TableDocumentSchemaVersionStateObject[] = [
    {
      state: 'DRAFT',
      stringKey: 'TABLE_DOCUMENT_SCHEMA_TYPE_DRAFT'
    },
    {
      state: 'FINALIZED',
      stringKey: 'TABLE_DOCUMENT_SCHEMA_TYPE_FINALIZED'
    },
    {
      state: 'DEPRECATED',
      stringKey: 'TABLE_DOCUMENT_SCHEMA_TYPE_DEPRECATED'
    }];

  export enum OrderField {
    ID,
    CODE,
    NAME
  }

  export enum ValidatedField {
    UNKNOWN,

    CODE,
    NAME,

    FIELD_NAME,
    FIELD_CODE,
    FIELD_TYPE
  }
}

export namespace TableDocumentSchemaField {

  export interface Service {

    // <editor-fold desc="CRUD">

    queryField(request: QueryRequest): Observable<List<TableDocumentSchemaField.TableDocumentSchemaField>>;

    createField(request: CreateRequest): Observable<EmptyMessage>;

    updateField(request: UpdateRequest): Observable<EmptyMessage>;

    deleteField(request: DeleteRequest): Observable<EmptyMessage>;

    // </editor-fold>

  }

  export type TableDocumentSchemaFieldTypeState = TableDocumentSchemaFieldResource.TableDocumentSchemaFieldTypeState;

  export interface TableDocumentSchemaField {
    fieldId: string;
    schemaFieldId: string;
    code: string;
    name: string;
    note?: string;
    required: boolean;
    dataType: TableDocumentSchemaFieldTypeState;
  }

  export interface QueryRequest {
    schemaId: number;
  }

  export interface CreateRequest {
    schemaId: string;
    code: string;
    name: string;
    note?: string;
    required: boolean;
    dataType: TableDocumentSchemaFieldTypeState;
  }

  export interface UpdateRequest {
    schemaId: string;
    schemaFieldId: string;
    code: string;
    name: string;
    note?: string;
    required: boolean;
    dataType: TableDocumentSchemaFieldTypeState;
  }

  export interface DeleteRequest {
    schemaId: string;
    schemaFieldId: string;
  }
}

export namespace TableDocumentSchemaImportExport {

  export interface Service {

    // <editor-fold desc="CRUD">

    exportSchema(request: ExportRequest): Observable<ImportDocument>;

    importSchema(request: ImportDocument): Observable<EmptyMessage>;


    // </editor-fold>

  }

  export interface ImportDocument {
    version: string;
    descriptor: string;
  }

  export interface ExportRequest {
    schemaId: number;
  }
}


class Keys {

  private static readonly SCHEMA_ID = 'schema_id';
  private static readonly CODE = 'code';
  private static readonly NAME = 'name';
  private static readonly FIELD_CODE = 'field_code';
  private static readonly FIELD_NAME = 'field_name';
  private static readonly FIELD_TYPE = 'field_type';

  private static readonly orderFieldKeyMap: Map<TableDocumentSchema.OrderField, string> = Map.of(
    TableDocumentSchema.OrderField.ID, Keys.SCHEMA_ID,
    TableDocumentSchema.OrderField.CODE, Keys.CODE,
    TableDocumentSchema.OrderField.NAME, Keys.NAME,
  );

  private static readonly keyValidatedFieldMap: Map<string, TableDocumentSchema.ValidatedField> = Map.of(
    TableDocumentSchema.ValidatedField.CODE, Keys.CODE,
    TableDocumentSchema.ValidatedField.NAME, Keys.NAME,
    TableDocumentSchema.ValidatedField.FIELD_CODE, Keys.FIELD_CODE,
    TableDocumentSchema.ValidatedField.FIELD_NAME, Keys.FIELD_NAME,
    TableDocumentSchema.ValidatedField.FIELD_TYPE, Keys.FIELD_TYPE,
  );

  public static toOrderFieldKey(field: TableDocumentSchema.OrderField): string {
    return Keys.orderFieldKeyMap.get(field)!;
  }

  public static toValidatedField(fieldKey: string): TableDocumentSchema.ValidatedField {
    return Keys.keyValidatedFieldMap.get(fieldKey, TableDocumentSchema.ValidatedField.UNKNOWN);
  }

}

export class TableDocumentSchemaFieldModel {
  fieldId: string;
  schemaFieldId: string;
  code: string;
  name: string;
  note?: string;
  required: boolean;
  requiredText?: string;
  dataType: TableDocumentSchemaFieldTypeState;
  dataTypeText?: string;
  editing: boolean = false;
}
