/* eslint-disable */
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UiConstants } from '../../../util/core-utils';
import { Transition, UIRouter } from '@uirouter/angular';
import { ComponentStateResolver } from '../../../util/component-state/component-state-resolver';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { NgForm, NgModel } from '@angular/forms';
import { LocalFieldValidationErrors, LocalFieldValidationErrorsFactory } from '../../../lib/util/services';
import { RightModel } from '../../../app.rights';
import { TranslateService } from '@ngx-translate/core';
import { RightResolver, RightService } from '../../../lib/right.service';
import { MessageTemplate, MessageTemplateService } from '../../../lib/message-template/message-template.service';
import { StateName } from '../../../app.state-names';
import { FieldError, FieldErrors, ObservableErrorResourceParser } from '../../../lib/util/errors';
import { Dates, OffsetDateTime } from '../../../lib/util/dates';
import { List } from 'immutable';
import { Strings } from '../../../lib/util/strings';
import { EmptyMessage } from '../../../lib/util/messages';
/* eslint-enable */

@Component({
  selector: 'app-notification-template-base',
  templateUrl: './notification-template-base.component.html',
  styleUrls: ['./notification-template-base.component.scss']
})
export class NotificationTemplateBaseComponent implements OnInit, AfterViewInit {

  // If you need to access certain classes from HTML, declare them here
  UiConstants = UiConstants;

  // Declare models before use
  editModel: MessageTemplateEditModel;
  detailModel: MessageTemplateDetailModel;

  // Component state resolver, determines the state of the component
  componentState: ComponentStateResolver;

  // Variables used for breadcrumb
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  // Form for validation
  @ViewChild('f', { static: true })
  fForm: NgForm;

  // Validated inputs
  @ViewChild('name')
  name: NgModel;

  @ViewChild('externalId')
  externalId: NgModel;

  @ViewChild('title')
  title: NgModel;

  @ViewChild('template')
  template: NgModel;

  // Field errors for server validation
  fieldErrors: MessageTemplateFieldErrorMap;

  private validatedInputs: LocalFieldValidationErrors<NgModel> =
    LocalFieldValidationErrorsFactory.empty();

  // Right model to store the rights available to the User
  rightModel: RightModel = RightModel.empty();

  constructor(private uiRouter: UIRouter,
              private transition: Transition,
              private messageTemplateService: MessageTemplateService,
              private translateService: TranslateService,
              private rightService: RightService) {
    this.fieldErrors = {};
    this.componentState = new ComponentStateResolver(uiRouter, transition,
      'id',
      {stateName: StateName.NOTIFICATION_TEMPLATE_CREATE, stateHeaderKey: 'NOTIFICATION_TEMPLATE_CREATE'},
      {stateName: StateName.NOTIFICATION_TEMPLATE_EDIT, stateHeaderKey: 'NOTIFICATION_TEMPLATE_EDIT'},
      {stateName: StateName.NOTIFICATION_TEMPLATE_DETAIL, stateHeaderKey: 'NOTIFICATION_TEMPLATE_DETAIL'});
  }

  ngOnInit() {
    this.initComponentState(); // Must be called first
    this.loadRightModels();
    this.initBreadcrumb();
  }

  ngAfterViewInit() {
    this.loadLocalFieldValidationErrors();
  }

  private initComponentState() {
    // Creates the editModel if not readonly
    if (!this.componentState.isDetailView()) {
      this.editModel = new MessageTemplateEditModel();
    }
    else {
      this.detailModel = new MessageTemplateDetailModel()
    }
    if (!this.componentState.isCreateView()) {
      this.loadModel();
    }
  }

  initBreadcrumb() {
    // Set breadcrumbSelf if createView, otherwise set in loadModel()
    if (this.componentState.isCreateView()) {
      this.translateService.get('NOTIFICATION_TEMPLATE_CREATE').subscribe(
        (result: string) => {
          this.breadcrumbSelf = result;
        }
      );
    }
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('NOTIFICATION_TEMPLATE_LIST').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.NOTIFICATION_TEMPLATE_LIST});
      }
    );
  }

  // Get corresponding dictionary key
  getHeadingDictionaryKey(): string {
    return this.componentState.getCurrentHeaderKey();
  }

  hasLocalFieldError(field?: NgModel): boolean {
    return this.validatedInputs.hasLocalError(field);
  }

  removeFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.fieldErrors, fieldError);
  }

  private loadLocalFieldValidationErrors() {
    const validatedInputs = List.of(this.name,
      this.externalId,
      this.title,
      this.template
    );
    this.validatedInputs = LocalFieldValidationErrorsFactory.ofFormFields(this.fForm, validatedInputs);
  }

  // Loads user rights
  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  // Called by the init method first
  private loadModel() {
    this.messageTemplateService.get({
      id: this.componentState.id!
    }).subscribe((messageTemplate: MessageTemplate.MessageTemplate) => {
      if (this.componentState.isEditView()) {
        this.loadEditModel(messageTemplate);
      }
      else if (this.componentState.isDetailView()) {
        this.loadDetailModel(messageTemplate);
      }
      this.breadcrumbSelf = messageTemplate.name;
    });
  }

  private loadEditModel(messageTemplate: MessageTemplate.MessageTemplate) {
    this.editModel = new MessageTemplateEditModel();
    this.editModel.id = messageTemplate.id;
    this.editModel.name = messageTemplate.name;
    this.editModel.externalId = messageTemplate.externalId;
    this.editModel.title = messageTemplate.title;
    this.editModel.template = messageTemplate.template;
  }

  private loadDetailModel(messageTemplate: MessageTemplate.MessageTemplate) {
    this.detailModel = new MessageTemplateDetailModel();
    this.detailModel.id = messageTemplate.id;
    this.detailModel.name = messageTemplate.name;
    this.detailModel.externalId = messageTemplate.externalId;
    this.detailModel.title = messageTemplate.title;
    this.detailModel.template = messageTemplate.template;
    this.detailModel.creationTime = messageTemplate.creationTime;
    this.detailModel.updateTime = messageTemplate.updateTime;
  }

  submit() {
    if (this.componentState.isCreateView()) {
      this.create();
    }
    else if (this.componentState.isEditView()) {
      this.update();
    }
  }

  create() {
    if (this.hasLocalFieldError()) {
      return;
    }
    this.messageTemplateService.create({
      name: this.editModel.name,
      externalId: Strings.undefinedOrNonEmpty(this.editModel.externalId),
      title: this.editModel.title,
      template: this.editModel.template
    }).subscribe(
      (response: EmptyMessage) => {
        this.uiRouter.stateService.go(StateName.NOTIFICATION_TEMPLATE_LIST);
      },
      (error: any) => {
        const res = ObservableErrorResourceParser.parseError(error);
        this.fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
      });
  }

  update() {
    if (this.hasLocalFieldError()) {
      return;
    }
    this.messageTemplateService.update({
      id: this.componentState.id!,
      name: this.editModel.name,
      title: this.editModel.title,
      externalId: this.editModel.externalId,
      template: this.editModel.template
    }).subscribe(
      (response: EmptyMessage) => {
        this.uiRouter.stateService.go(StateName.NOTIFICATION_TEMPLATE_LIST);
      },
      (error: any) => {
        const res = ObservableErrorResourceParser.parseError(error);
        this.fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
      });
  }

  back() {
    window.history.back();
  }

}

// Used for create/edit views
class MessageTemplateEditModel {
  id: number;
  name: string = '';
  title: string = '';
  externalId: string = '';
  template: string = '';

}

// Used for detail view
class MessageTemplateDetailModel {
  id: number;
  name: string = '';
  title: string = '';
  externalId: string = '';
  template: string = '';
  creationTime: OffsetDateTime = Dates.emptyOffsetDateTime();
  updateTime: OffsetDateTime = Dates.emptyOffsetDateTime();
}

interface MessageTemplateFieldErrorMap {
  name?: FieldError;
  external_id?: FieldError;
  title?: FieldError;
}
