<div class="w-100 d-flex justify-content-center">
  <ng-container *ngIf="!textAsPopover && (text || textKey); else popoverBlock">
  <div class="table-badge d-flex align-items-center position-relative"
       [ngClass]="badgeStyle" [class.badge-size-small]="fontSize === 'small'">
    <div *ngIf="showNotificationDot" class="notification-dot"></div>
    <i class="icomoon table-badge-icon d-flex align-items-center" [ngClass]="iconClass" *ngIf="iconClass"
       (click)="onIconClick()"></i>
    <span [class.break-word]="breakWord">{{text ? text : textKey | translate}}</span>
  </div>
  </ng-container>
  <ng-template #popoverBlock>
    <ng-container *ngIf="textAsPopover && (text || textKey); else elseBlock">
      <div class="table-badge d-flex align-items-center position-relative"
           style="padding: 0.2rem"
           [ngClass]="badgeStyle" [class.badge-size-small]="fontSize === 'small'">
        <div *ngIf="showNotificationDot" class="notification-dot"></div>
        <i class="icomoon table-badge-icon d-flex align-items-center m-0" [ngClass]="iconClass" *ngIf="iconClass"
           (click)="onIconClick()" popover="{{text ? text : textKey | translate}}"
           triggers="mouseenter:mouseleave" container="body" placement="top"></i>
      </div>
    </ng-container>
  </ng-template>
  <ng-template #elseBlock>
    <div class="table-badge d-flex align-items-center position-relative"
         style="padding: 0.2rem"
         [ngClass]="badgeStyle" [class.badge-size-small]="fontSize === 'small'">
      <div *ngIf="showNotificationDot" class="notification-dot"></div>
      <i class="icomoon table-badge-icon d-flex align-items-center m-0" [ngClass]="iconClass" *ngIf="iconClass"
         (click)="onIconClick()"></i>
    </div>
  </ng-template>
</div>
