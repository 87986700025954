/* eslint-disable */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { StateName } from '../../../app.state-names';
import { TranslateService } from '@ngx-translate/core';
import { Address } from '../../../lib/address';
import { Set } from 'immutable';
import { Strings } from '../../../lib/util/strings';
import { OrderType, QueryResult } from '../../../lib/util/services';
import { OptionItem, QueryFieldModel, SelectUtils, UiConstants } from '../../../util/core-utils';
import { CompanyLocation, CompanyLocationService } from '../../../lib/company-location/company-location.service';
import { EmptyMessage } from '../../../lib/util/messages';
import { ConfigModel } from '../../../util/task-record-utils';
import { ConfigurationService } from '../../../lib/core-ext/configuration.service';
import { InputMask } from '../../../util/input-masks';
import { combineLatest, Observable } from 'rxjs';
import { CompanyLocationSearch, CompanyLocationSearchService } from '../../../lib/company-location/company-location-search.service';
import { Arrays } from '../../../lib/util/arrays';
import { Company, CompanyService } from '../../../lib/company/company.service';
import { RightResolver, RightService } from '../../../lib/right.service';
import { RightModel } from '../../../app.rights';
import { Angular2Multiselects } from '../../../util/multiselect';
import { VehicleSearch } from '../../../lib/vehicles/vehicle-search.service';
import { StringKey } from '../../../app.string-keys';
import { TranslateUtils } from '../../../util/translate';
import { Logger, LoggerFactory } from '../../../util/logger-factory';
import PostalAddressData = Address.PostalAddressData;
import { DisabledEnum, DisabledItem } from '../../../util/search-utils';
/* eslint-enable */

@Component({
  selector: 'app-company-location-list',
  templateUrl: './company-location-list.component.html',
  styleUrls: ['./company-location-list.component.scss']
})
export class CompanyLocationListComponent implements OnInit, OnDestroy {

  private readonly logger: Logger;

  CompanyLocation = CompanyLocation;
  Address = Address;
  InputMask = InputMask;
  SelectUtils = SelectUtils;
  UiConstants = UiConstants;

  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  showSearch: boolean = false;
  searchModel: CompanyLocationSearchModel = new CompanyLocationSearchModel();
  queryModel: QueryFieldModel<CompanyLocation.OrderField> = new QueryFieldModel(CompanyLocation.OrderField.ID, OrderType.DESC);
  config: ConfigModel = new ConfigModel();
  searchResult: CompanyLocationSearch.SearchDataResult;
  disabledItems: DisabledItem[] = [];
  rightModel: RightModel = RightModel.empty();

  dropdownSettingsForCompany: Angular2Multiselects.Settings;
  companyItems: CompanyLocationSearch.CompanyItem[] = [];
  companyForSearch: CompanyLocationSearch.CompanyItem[] = [];

  companyLocationList: CompanyLocationModel[] = [];

  constructor(
    private translateService: TranslateService,
    private companyLocationService: CompanyLocationService,
    private configService: ConfigurationService,
    private companyLocationSearchService: CompanyLocationSearchService,
    private companyService: CompanyService,
    private rightService: RightService,
  ) {
    this.config = this.configService.getConfigurationModel();
    this.logger = LoggerFactory.createLogger('CompanyLocationListComponent');
  }

  ngOnInit() {
    this.initDropdownSettings();
    this.loadRightModels();
    this.initBreadcrumb();
    this.loadSearch(() => {
      this.showSearch = !this.searchModel.isEmpty();
      this.initDisabledOptions(DisabledEnum.FALSE);
      this.loadList();
    })
  }

  private initDisabledOptions(initValue: DisabledEnum) {
    this.disabledItems = [];
    const disabledEnums: DisabledEnum[] = [DisabledEnum.NONE, DisabledEnum.FALSE, DisabledEnum.TRUE];
    Arrays.iterateByIndex(disabledEnums, (key) => {
      const item = new DisabledItem();
      item.id = key;
      this.translateService.get('COMMON_VALUE_DISABLED_ENUM_' + key).subscribe(
        (text: string) => {
          item.text = text;
        }
      );
      this.disabledItems.push(item);
      if (!this.searchModel.disabled) {
        if (key === initValue) {
          this.searchModel.disabled = item;
        }
      }
    });
  }

  initDropdownSettings() {
    this.dropdownSettingsForCompany = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .labelKey('name')
      .enableCheckAll(false)
      .build();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  initBreadcrumb() {
    this.translateService.get('MENU_NAVBAR_MENU_COMPANY_LOCATION').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
  }

  loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();

    const idSet: number[] = [];
    if (Strings.undefinedOrNonEmpty(this.searchModel.id)) {
      idSet.push(+this.searchModel.id);
    }

    const disabled: boolean | undefined = !this.searchModel.disabled ||
    this.searchModel.disabled.id === DisabledEnum.NONE ?
      undefined : this.searchModel.disabled.id === DisabledEnum.TRUE;

    this.companyLocationService.query({
      id: idSet.length > 0 ? Set.of(...idSet) : undefined,
      externalId: Strings.undefinedOrNonEmpty(this.searchModel.externalId),
      name: Strings.undefinedOrNonEmpty(this.searchModel.name),
      companyIds: Set.of(...this.searchModel.companyIds.map(t => +t.id)),
      disabled: disabled,
      orders: Set.of(order),
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined
    }).subscribe((result: QueryResult<CompanyLocation.CompanyLocation>) => {
      this.companyLocationList = [];
      result.items.forEach((companyLocation: CompanyLocation.CompanyLocation) => {
        const companyLocationModel = new CompanyLocationModel();
        companyLocationModel.id = companyLocation.id + '';
        companyLocationModel.externalId = companyLocation.externalId + '';
        companyLocationModel.companyName = companyLocation.company.name;
        companyLocationModel.companyLocationAddress = companyLocation.place.postalAddress!;
        companyLocationModel.name = companyLocation.name;
        companyLocationModel.disabled = companyLocation.disabled;
        this.companyLocationList.push(companyLocationModel);
      });
      this.queryModel.currentPage = requestedPage;
      this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
      this.logger.debug(this.companyLocationList);
    });
  }

  loadCompany(completion: () => void) {
    this.companyService.query({
      disabled: false,
      orders: Set.of({field: Company.OrderField.NAME, type: OrderType.ASC})
    }).subscribe((result: QueryResult<Company.Company>) => {
      this.companyItems = [];
      result.items.forEach((company: Company.Company) => {
        const companyModel = {id: 0, name: ''};
        companyModel.id = company.id;
        companyModel.name = company.name;
        this.companyItems.push(companyModel);
      });
      completion();
    });

  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  orderBy(field: CompanyLocation.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  setDisabled(event: any, companyLocation: CompanyLocation.CompanyLocation, disabled: boolean) {
    this.companyLocationService.setDisabled({
      id: companyLocation.id,
      disabled: disabled
    }).subscribe(
      (result: EmptyMessage) => {
        this.loadList();
      },
      (error: any) => {
        this.loadList();
      }
    );
  }

  onSearchClicked() {
    this.loadList(1);
  }

  onSearchReset() {
    this.companyLocationSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.showSearch = true;
          this.loadList(1);
        });
      }
    );
  }

  private loadSearch(completion: () => void) {
    const obs: Observable<SearchLoadResult> = combineLatest(
      this.companyLocationSearchService.getSearchData({}),
      (storedSearchData: CompanyLocationSearch.SearchDataResult) => {
        const result: SearchLoadResult = {
          storedSearchData: storedSearchData
        };
        return result;
      }
    );
    obs.subscribe(
      (result: SearchLoadResult) => {
        this.loadCompaniesForSearch(result.storedSearchData, () => {
          this.searchResult = result.storedSearchData;
          this.postInitSearch(result.storedSearchData, completion);
        })
      }
    );

  }

  private loadCompaniesForSearch(storedSearchData: CompanyLocationSearch.SearchDataResult, completion: () => void) {
    this.searchModel.companyIds = [];
    this.loadCompany(() => {
      this.companyForSearch = [];
      this.companyItems.forEach((state) => {
        const item = new VehicleSearch.CompanyItem();
        item.id = state.id;
        item.name = state.name;
        this.companyForSearch.push(item);
        const storedCompanyIds = storedSearchData.searchData.companyIds;
        if (storedCompanyIds && storedCompanyIds.find(t => t.id === item.id)) {
          this.searchModel.companyIds.push(item);
        }
      });
      completion()
    });
  }

  private postInitSearch(storedSearchData: CompanyLocationSearch.SearchDataResult, completion: () => void) {
    this.queryModel.itemsPerPage = storedSearchData.searchData.itemsPerPage;
    this.queryModel.currentPage = storedSearchData.searchData.pageNumber;
    this.queryModel.setOrder(storedSearchData.searchData.order);
    this.searchModel.id = storedSearchData.searchData.id;
    this.searchModel.externalId = storedSearchData.searchData.externalId;
    this.searchModel.name = storedSearchData.searchData.name;
    this.searchModel.companyIds = storedSearchData.searchData.companyIds;
    this.searchModel.disabled = storedSearchData.searchData.disabled;
    completion()
  }

  ngOnDestroy() {
    this.saveSearch();
  }

  private saveSearch() {
    const request = {
        searchData: {
          itemsPerPage: this.queryModel.itemsPerPage,
          pageNumber: this.queryModel.currentPage,
          order: this.queryModel.getOrder(),
          id: this.searchModel.id,
          externalId: this.searchModel.externalId,
          name: this.searchModel.name,
          companyIds: this.searchModel.companyIds,
          disabled: this.searchModel.disabled,
        }
      }
    ;
    this.companyLocationSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }
}

class CompanyLocationModel {
  id: string = '';
  externalId: string = '';
  companyName: string = '';
  companyLocationAddress: PostalAddressData;
  name: string = '';
  disabled = false;

}

export class CompanyLocationSearchModel {
  id: string = '';
  name: string = '';
  externalId: string = '';
  companyName: string = '';
  companyIds: CompanyLocationSearch.CompanyItem[] = [];
  disabled?: DisabledItem;


  public isEmpty(): boolean {
    return this.id.length === 0
      && this.externalId.length === 0
      && this.name.length === 0
      && this.companyIds.length === 0
      && OptionItem.idOrUndefined(this.disabled) === undefined
      ;
  }

  constructor() {
  }
}

interface SearchLoadResult {
  storedSearchData: CompanyLocationSearch.SearchDataResult,
}
