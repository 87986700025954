import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CustomerRecordContactLocationMultiselectProvider } from '../../../../lib/customer/contact/customer-record-contact-location-multiselect.provider';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MasterDataRecord, MasterDataRecordService } from '../../../../lib/masterdata/master-data-record.service';
import { Angular2Multiselects } from '../../../../util/multiselect';
import { CustomerRecordMultiselectProvider } from '../../../../lib/customer/customer-record-multiselect-provider.service';
import { FieldValidationError, ForwardingNgFormRef, LocalFormGroupValidationErrors } from '../../../../lib/util/services';
import { MultiselectOptionItem, MultiselectOptionItemWithData, UiConstants } from '../../../../util/core-utils';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppValidators } from '../../../../util/app-validators';
import { MasterdataRecordConnectToCustomerDialogModel } from './masterdata-record-connect-to-customer-dialog.model';
import { MasterDataRecordMultiselectProvider } from '../../../../lib/masterdata/masterdata-record-multiselect-provider.service';
import { Set } from 'immutable';
import { EmptyMessage } from '../../../../lib/util/messages';

@Component({
  selector: 'app-masterdata-record-connect-to-customer-dialog',
  templateUrl: './masterdata-record-connect-to-customer-dialog.component.html',
  styleUrls: ['./masterdata-record-connect-to-customer-dialog.component.scss']
})
export class MasterdataRecordConnectToCustomerDialogComponent implements OnInit {

  UiConstants = UiConstants;
  ValidatedField = MasterDataRecord.ValidatedField;

  model: MasterdataRecordConnectToCustomerDialogModel = new MasterdataRecordConnectToCustomerDialogModel();
  remoteDropdownSettings: Angular2Multiselects.Settings;
  multiRemoteDropdownSettings: Angular2Multiselects.Settings;

  customerRecords: MultiselectOptionItem<number>[] = [];
  customerRecordContactLocations: MultiselectOptionItem<number>[] = [];
  masterDataRecordList: MultiselectOptionItemWithData<number>[] = [];

  private _navigateToEdit: boolean = false;

  @ViewChild('f', {static: true})
  form: NgForm;
  formGroup: FormGroup;
  private formGroupValidationErrors: LocalFormGroupValidationErrors;
  private fieldErrors: FieldValidationError<MasterDataRecord.ValidatedField> =
    FieldValidationError.empty<MasterDataRecord.ValidatedField>();

  constructor(
    public dialogRef: MatDialogRef<MasterdataRecordConnectToCustomerDialogComponent>,
    private translateService: TranslateService,
    private customerRecordMultiselectProvider: CustomerRecordMultiselectProvider,
    private customerRecordContactLocationMultiselectProvider: CustomerRecordContactLocationMultiselectProvider,
    private fb: FormBuilder,
    private masterDataRecordMultiselectProvider: MasterDataRecordMultiselectProvider,
    private masterDataRecordService: MasterDataRecordService,
    @Inject(MAT_DIALOG_DATA) public data: MasterdataRecordConnectToCustomerDialogData) {
    this.loadModel(data);
    this.formGroup = this.createFormGroup(this.fb);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(
      this.createForwardingHtmlForm(),
      this.formGroup
    );
  }

  ngOnInit(): void {
    this.initDropdown();
  }

  private initDropdown() {
    this.remoteDropdownSettings = Angular2Multiselects.REMOTE_SINGLE_SELECT;
    this.multiRemoteDropdownSettings = Angular2Multiselects.REMOTE_MULTI_SELECT;
  }

  closeDialog(modified: boolean) {
    this.dialogRef.close({modified: modified});
  }

  navigateToEdit() {
    this._navigateToEdit = true;
  }

  save() {
    if (this.hasLocalFieldError()) {
      return;
    }
    else {
      this.createMasterDataRecord();
    }
  }

  hasFieldError(field?: MasterDataRecord.ValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: MasterDataRecord.ValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: MasterDataRecord.ValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  private createForwardingHtmlForm() {
    return new ForwardingNgFormRef({
      formFn: () => {
        return this.form;
      }
    });
  }

  private createFormGroup(fb: FormBuilder): FormGroup {
    return fb.group(
      {
        masterDataRecords: fb.control(
          {value: this.model._masterDataRecords},
          [Validators.required]
        ),
        customerRecord: fb.control(
          {value: this.model.ownerCustomerRecord},
          AppValidators.validateEnabledItems
        ),
        contactLocation: fb.control(
          {value: this.model.ownerContactLocation, disabled: !this.model.hasCustomer()}
        )
      }
    );
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  createMasterDataRecord() {
    const navigateToEdit = this._navigateToEdit !== undefined ? this._navigateToEdit : false;
    this.formGroup.updateValueAndValidity();
    if (this.formGroup.invalid) {
      return;
    }
    this.masterDataRecordService.connectToCustomerRecord({
      ids: Set.of(...this.model._masterDataRecords.map(m => m.id)),
      ownerCustomerRecordId: this.model.ownerCustomerRecord ? this.model.ownerCustomerRecord.id : undefined,
      ownerContactLocationId: this.model.ownerContactLocation ? this.model.ownerContactLocation.id : undefined,
    }).subscribe(
      (result: EmptyMessage) => {
        this.closeDialog(true);
      },
      error => {

      });
  }

  private loadModel(data: MasterdataRecordConnectToCustomerDialogData) {
    this.model = new MasterdataRecordConnectToCustomerDialogModel();
    if (data.customerRecordId) {
      this.customerRecordMultiselectProvider.getById(data.customerRecordId).subscribe(customerRecord => {
        this.model._ownerCustomerRecord.push(customerRecord);
      })
    }
  }

  onCustomerRecordSearch(q?: string) {
    this.customerRecordMultiselectProvider.loadActive(q).subscribe(result => {
      this.customerRecords = result;
    });
  }

  onMasterDataRecordSearch(q?: string) {
    this.masterDataRecordMultiselectProvider.loadActive(q).subscribe(result => {
      this.masterDataRecordList = result;
    });
  }

  onCustomerContactLocationSearch(q?: string) {
    if (this.model.ownerCustomerRecord) {
      this.customerRecordContactLocationMultiselectProvider.loadForCustomer(this.model.ownerCustomerRecord!.id, q).subscribe(result => {
        this.customerRecordContactLocations = result;
      });
    }
    else {
      this.customerRecordContactLocations = [];
    }
  }

  onCustomerRecordChanged() {
    this.model._ownerContactLocation = [];
    this.onCustomerContactLocationSearch();
  }

}

export interface MasterdataRecordConnectToCustomerDialogData {
  customerRecordId?: number;
}
