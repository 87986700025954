/* eslint-disable */
import { Injectable } from '@angular/core';
import { MaterialIcons } from './material-icons';
import { MultiselectOptionItem, OptionItem } from '../../util/core-utils';
import { IcomoonIcons } from './icomoon-icons';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { TranslateUtils } from '../../util/translate';
import { Observable } from 'rxjs';
import IconModel = Icon.IconModel;

/* eslint-enable */

@Injectable()
export class IconService {

  private iconSets: Icon.IconSetModel[];

  constructor(private translateService: TranslateService) {
    this.iconSets = [new MaterialIcons(), new IcomoonIcons()];
  }

  getAvailableIconSets(): string[] {
    const sets: string[] = [];
    this.iconSets.forEach((is) => {
      sets.push(is.iconSetCode);
    });
    return sets;
  }

  getAvailableIconSetOptions(): Observable<MultiselectOptionItem<string>[]> {
    const sets: MultiselectOptionItem<string>[] = [];
    this.iconSets.forEach((is) => {
      sets.push({id: is.iconSetCode, itemName: is.iconSetNameKey})
    });
    return this.translateService.get(sets.map(s => s.itemName))
      .pipe(map(result => {
        sets.forEach(s => {
          s.itemName = TranslateUtils.extractValueFromObject(result, s.itemName);
        })
        return sets;
      }));
  }

  getIconSet(iconSetCode?: string): Icon.IconSetModel | undefined {
    return this.iconSets.find((is) => {
      return is.iconSetCode === iconSetCode;
    });
  }

  toResourceIcon(icon?: Icon.Icon): IconResource.Icon | undefined {
    if (!icon) {
      return undefined;
    }
    return {
      icon_type: 'ICON_SET',
      icon_set: {
        set_code: icon.iconSet.setCode,
        icon_code: icon.iconSet.iconCode
      }
    }
  }

  toPublicIcon(icon?: IconResource.Icon): Icon.Icon | undefined {
    if (!icon) {
      return undefined;
    }
    const iconSet = this.iconSets.find(s => s.iconSetCode === icon.icon_set.set_code);

    return {
      iconSet: {
        setCode: icon.icon_set.set_code,
        iconCode: icon.icon_set.icon_code,
        setClass: iconSet?.iconSetClass,
        usageType: iconSet ? iconSet.usageType : 'TEXT'
      }
    }
  }

  toIconModelList(iconSet: Icon.IconSetModel | undefined): IconModel[] {
    const models: IconModel[] = [];
    if (iconSet) {
      iconSet.iconSet.forEach((icon) => {
        models.push({
          id: icon,
          text: icon,
          setCode: iconSet.iconSetCode,
          setClass: iconSet.iconSetClass,
          usageType: iconSet.usageType
        });
      });
    }
    return models;
  }

}

export namespace Icon {

  export interface IconSetModel {
    iconSetNameKey: string;
    iconSetCode: string;
    iconSet: string[];
    iconSetClass: string;
    usageType: UsageType;
  }

  export type UsageType = 'CLASS' | 'TEXT';

  export class IconModel extends OptionItem<string> {
    setCode: string;
    setClass: string;
    usageType: UsageType;


    public static toServiceIcon(item?: IconModel): Icon | undefined {
      if (!item || !item.id) {
        return undefined;
      }
      return {
        iconSet: {
          setCode: item.setCode,
          iconCode: item.id,
          setClass: item.setClass,
          usageType: item.usageType
        }
      }
    }

    public static fromServiceIcon(icon?: Icon): IconModel {
      return {
        id: icon && icon.iconSet.iconCode ? icon.iconSet.iconCode : null,
        text: icon && icon.iconSet.iconCode ? icon.iconSet.iconCode : '',
        setCode: icon && icon.iconSet.setCode ? icon.iconSet.setCode : '',
        setClass: icon && icon.iconSet.setClass ? icon.iconSet.setClass : '',
        usageType: icon && icon.iconSet.usageType ? icon.iconSet.usageType : 'TEXT'
      }
    }
  }

  export interface IconSet {
    setCode?: string;
    iconCode?: string;
    setClass?: string;
    usageType: UsageType;
  }

  export interface Icon {
    iconType?: 'ICON_SET';
    iconSet: IconSet;
  }

}

export namespace IconResource {
  export interface IconSet {
    set_code?: string;
    icon_code?: string;
  }

  export interface Icon {
    icon_type?: string;
    icon_set: IconSet;
  }

}
