/* eslint-disable */
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { StateName } from '../../../app.state-names';
import { TranslateService } from '@ngx-translate/core';
import { OptionItem, QueryFieldModel, SelectUtils, UiConstants } from '../../../util/core-utils';
import { ForwardingNgFormRef, LocalFormGroupValidationErrors, OrderType, QueryResult } from '../../../lib/util/services';
import { RightModel } from '../../../app.rights';
import { OffsetDateTime } from '../../../lib/util/dates';
import { Set } from 'immutable';
import { combineLatest, Observable } from 'rxjs';
import { InputMask } from '../../../util/input-masks';
import { Arrays } from '../../../lib/util/arrays';
import { Strings } from '../../../lib/util/strings';
import { DownloadedFile } from '../../../lib/util/downloaded-files';
import { saveAs } from 'file-saver';
import { RightResolver, RightService } from '../../../lib/right.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { FileItem, FileUploader, FileUploaderOptions, ParsedResponseHeaders } from 'ng2-file-upload';
import { FileUploaderUtil } from '../../../util/file-uploader-util';
import { UploadErrorLocalizer } from '../../../util/upload-error-localizer';
import { EmptyMessage } from '../../../lib/util/messages';
import { FieldError, FieldErrors } from '../../../lib/util/errors';
import { ResourceHelper } from '../../../lib/util/http-services';
import {
  GeneralPrinterTemplateSearch,
  GeneralPrinterTemplateSearchService
} from '../../../lib/general-printer-template/general-printer-template-search-service';
import { GeneralPrinterTemplate, GeneralPrinterTemplateService } from '../../../lib/general-printer-template/general-printer-template.service';
import { DisabledEnum, DisabledItem } from '../../../util/search-utils';
import { DropdownItemType } from '../../../shared/dropdown/dropdown-item/dropdown-item-type';
import { FileUploadComponent } from '../../../shared/file-upload/flat/file-upload.component';
/* eslint-enable */

@Component({
  selector: 'app-general-printer-template',
  templateUrl: './general-printer-template-list.component.html',
  styleUrls: ['./general-printer-template-list.component.scss']
})
export class GeneralPrinterTemplateListComponent implements OnInit, OnDestroy {
  GeneralPrinterTemplate = GeneralPrinterTemplate;
  SelectUtils = SelectUtils;
  UiConstants = UiConstants;
  InputMask = InputMask;
  DropdownItemType = DropdownItemType;

  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  queryModel: QueryFieldModel<GeneralPrinterTemplate.OrderField>
    = new QueryFieldModel(GeneralPrinterTemplate.OrderField.ID, OrderType.DESC);
  rightModel: RightModel = RightModel.empty();
  searchModel: GeneralPrinterTemplateSearchModel = new GeneralPrinterTemplateSearchModel();

  generatePrinterTemplateList: GeneralPrinterTemplateModel[] = [];
  searchResult: GeneralPrinterTemplateSearch.SearchDataResult;
  showSearch: boolean = false;
  disabledItems: DisabledItem[] = [];

  @ViewChild('f')
  fForm: NgForm;

  @ViewChild('baseDialog', { static: true }) baseDialog: ModalDirective;
  baseDialogVisible: boolean = false;
  baseGeneralPrinterTemplateModel: GeneralPrinterTemplateModel = new GeneralPrinterTemplateModel();
  fieldErrors: GeneralPrinterTemplateErrorMap;

  @ViewChild('fileUploadComponent', {static: false})
  fileUploadComponent: FileUploadComponent;

  formGroup: FormGroup;
  private formGroupValidationErrors: LocalFormGroupValidationErrors;
  fileUploadEmptyError: boolean = false;
  fileFormatError: boolean = false;

  constructor(
    private translateService: TranslateService,
    private generalPrinterTemplateService: GeneralPrinterTemplateService,
    private generalPrinterTemplateSearchService: GeneralPrinterTemplateSearchService,
    private rightService: RightService,
    fb: FormBuilder,
    private uploadUtil: FileUploaderUtil,
    private changeDetector: ChangeDetectorRef,
    private uploadErrorLocalizer: UploadErrorLocalizer,
    private resourceHelper: ResourceHelper
  ) {
    this.fieldErrors = {};
    this.formGroup = this.createFormGroup(fb);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(this.createForwardingHtmlForm(), this.formGroup);
  }

  ngOnInit() {
    this.initBreadcrumb();
    this.loadRightModels();
    this.loadSearch(() => {
      this.initDisabledOptions(DisabledEnum.FALSE);
      this.loadList();
    });
  }

  ngOnDestroy() {
    this.saveSearch();
  }

  initBreadcrumb() {
    this.translateService.get('GENERAL_PRINTER_TEMPLATE_LIST_HEADER').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  private loadSearch(completion: () => void) {
    const obs: Observable<SearchLoadResult> = combineLatest(
      this.generalPrinterTemplateSearchService.getSearchData({}),
      (storedSearchData: GeneralPrinterTemplateSearch.SearchDataResult) => {
        const result: SearchLoadResult = {
          storedSearchData: storedSearchData
        };
        return result;
      }
    );
    obs.subscribe(
      (result: SearchLoadResult) => {
        this.searchResult = result.storedSearchData;
        this.postInitSearch(result.storedSearchData, completion);
      }
    );
  }

  private createFormGroup(fb: FormBuilder): FormGroup {
    return fb.group(
      {
        code: fb.control(
          {value: this.baseGeneralPrinterTemplateModel.code},
          [
            Validators.required,
          ]
        ),
      }
    );
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    this.formGroup.updateValueAndValidity();
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  removeFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.fieldErrors, fieldError);
  }

  private createForwardingHtmlForm() {
    return new ForwardingNgFormRef({
      formFn: () => {
        return this.fForm;
      }
    });
  }

  loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();
    const idSet: number[] = [];
    if (Strings.undefinedOrNonEmpty(this.searchModel.id)) {
      idSet.push(+this.searchModel.id);
    }
    const disabled: boolean | undefined = !this.searchModel.disabled ||
    this.searchModel.disabled.id === DisabledEnum.NONE ?
      undefined : this.searchModel.disabled.id === DisabledEnum.TRUE;
    this.generalPrinterTemplateService.query({
      ids: idSet.length > 0 ? Set.of(...idSet) : undefined,
      code: Strings.undefinedOrNonEmpty(this.searchModel.code),
      disabled: disabled,
      orders: Set.of(order),
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined
    }).subscribe((result: QueryResult<GeneralPrinterTemplate.GeneralPrinterTemplate>) => {
      this.generatePrinterTemplateList = [];
      result.items.forEach((generalPrinterTemplate: GeneralPrinterTemplate.GeneralPrinterTemplate) => {
        const generalPrinterTemplateModel = new GeneralPrinterTemplateModel();
        generalPrinterTemplateModel.id = generalPrinterTemplate.id;
        generalPrinterTemplateModel.code = generalPrinterTemplate.code;
        generalPrinterTemplateModel.version = generalPrinterTemplate.version;
        generalPrinterTemplateModel.creationTime = generalPrinterTemplate.creationTime;
        generalPrinterTemplateModel.updateTime = generalPrinterTemplate.updateTime;
        generalPrinterTemplateModel.disabled = generalPrinterTemplate.disabled;
        this.generatePrinterTemplateList.push(generalPrinterTemplateModel);
      });
      this.queryModel.currentPage = requestedPage;
      this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
    });

  }

  private postInitSearch(storedSearchData: GeneralPrinterTemplateSearch.SearchDataResult, completion: () => void) {
    this.queryModel.itemsPerPage = storedSearchData.searchData.itemsPerPage;
    this.queryModel.currentPage = storedSearchData.searchData.pageNumber;
    this.queryModel.setOrder(storedSearchData.searchData.order);
    this.searchModel.id = storedSearchData.searchData.id;
    this.searchModel.code = storedSearchData.searchData.code;
    this.searchModel.disabled = storedSearchData.searchData.disabled;
    completion()
  }

  private saveSearch() {
    const request = {
      searchData: {
        itemsPerPage: this.queryModel.itemsPerPage,
        pageNumber: this.queryModel.currentPage,
        order: this.queryModel.getOrder(),
        id: this.searchModel.id,
        code: this.searchModel.code,
        disabled: this.searchModel.disabled,
      }
    };
    this.generalPrinterTemplateSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  private initDisabledOptions(initValue: DisabledEnum) {
    this.disabledItems = [];
    const disabledEnums: DisabledEnum[] = [DisabledEnum.NONE, DisabledEnum.FALSE, DisabledEnum.TRUE];
    Arrays.iterateByIndex(disabledEnums, (key) => {
      const item = new DisabledItem();
      item.id = key;
      this.translateService.get('COMMON_VALUE_DISABLED_ENUM_' + key).subscribe(
        (text: string) => {
          item.text = text;
        }
      );
      this.disabledItems.push(item);
      if (!this.searchModel.disabled) {
        if (key === initValue) {
          this.searchModel.disabled = item;
        }
      }
    });
  }

  onSearchClicked() {
    this.loadList(1);
  }

  onSearchReset() {
    this.generalPrinterTemplateSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.showSearch = true;
          this.loadList(1);
        });
      }
    );
  }


  orderBy(field: GeneralPrinterTemplate.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  setDisabled(event: any, generalPrinterTemplate: GeneralPrinterTemplate.GeneralPrinterTemplate, disabled: boolean) {
    this.generalPrinterTemplateService.setDisabled({
      code: generalPrinterTemplate.code,
      disabled: disabled
    }).subscribe(
      (result: EmptyMessage) => {
        this.loadList();
      },
      (error: any) => {
        this.loadList();
      }
    );
  }

  exportDocument(model: GeneralPrinterTemplateModel) {

    this.generalPrinterTemplateService.export({
      code: model.code
    }).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName('General_Printer_Document_' + model.code + '.fodt'));
      });
  }

  showBaseDialog(code?: string) {
    if (code) {
      this.loadEditModel(code)
    }
    else {
      this.baseGeneralPrinterTemplateModel = new GeneralPrinterTemplateModel();
      this.openBaseDialog();
    }
  }

  loadEditModel(code: string) {
    this.generalPrinterTemplateService.get(
      {
        code: code
      }
    ).subscribe((template: GeneralPrinterTemplate.GeneralPrinterTemplate) => {
      this.baseGeneralPrinterTemplateModel = new GeneralPrinterTemplateModel();

      this.baseGeneralPrinterTemplateModel.code = template.code;
      this.baseGeneralPrinterTemplateModel.version = template.version;
      this.baseGeneralPrinterTemplateModel.isEdit = true;
      this.openBaseDialog();
    });
  }

  openBaseDialog() {
    this.baseDialogVisible = true;
    this.baseDialog.show();
    this.changeDetector.detectChanges();
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(this.createForwardingHtmlForm(), this.formGroup);
    this.fileUploadComponent.uploader.clearQueue();
    this.fileUploadComponent.uploader.cancelAll();
    this.fileUploadEmptyError = false;
    this.fileFormatError = false;
    this.formGroup.reset();
  }

  closeBaseDialog() {
    this.baseDialogVisible = false;
    this.baseDialog.hide();
    this.formGroup.reset();
  }

  createGeneralPrinterTemplate() {

    let error: boolean = false;

    this.fileUploadEmptyError = false;
    this.fileFormatError = false;
    this.formGroup.updateValueAndValidity();
    if (this.formGroup.invalid && !this.baseGeneralPrinterTemplateModel.isEdit) {
      this.formGroup.get('code')!.markAsTouched();
      error = true;
    }

    if (this.fileUploadComponent.uploader.queue.length === 0) {
      this.fileUploadEmptyError = true;
      error = true;
    }
    else {

      const fileName = this.fileUploadComponent.uploader.queue[0].file.name.toLocaleLowerCase();
      const regex = new RegExp('(.*?)\.(xml)$');
      const regexTest = regex.test(fileName);

      if (!regexTest) {
        this.fileFormatError = true;
        error = true;
      }
    }

    if (error) {
      return;
    }

    const version = this.baseGeneralPrinterTemplateModel.version ? this.baseGeneralPrinterTemplateModel.version : 0;
    const url = this.resourceHelper.getBaseUrl() + '/general-printer/template/' +
      this.baseGeneralPrinterTemplateModel.code + '?v=' + version;

    const options: FileUploaderOptions = this.fileUploadComponent.uploader.options;
    options.url = url;
    this.fileUploadComponent.uploader.setOptions(options);
    this.fileUploadComponent.uploader.uploadAll();
  }

  getBaseModalTitle(): string {
    if (this.baseGeneralPrinterTemplateModel.isEdit) {
      return 'GENERAL_PRINTER_TEMPLATE_EDIT';
    }
    else {
      return 'GENERAL_PRINTER_TEMPLATE_CREATE';
    }
  }

  onUploadResult(result: boolean) {
    if (result) {
      this.closeBaseDialog();
      this.loadList();
    }
  }
}

interface SearchLoadResult {
  storedSearchData: GeneralPrinterTemplateSearch.SearchDataResult,
}


class GeneralPrinterTemplateModel {
  id: number;
  code: string = '';
  version: number;
  creationTime?: OffsetDateTime;
  updateTime?: OffsetDateTime;
  disabled: boolean = false;
  isEdit: boolean = false;

  importableTemplate: string[] = [];
}

class GeneralPrinterTemplateSearchModel {
  id: string;
  code: string;
  disabled?: DisabledItem;

  public isEmpty(): boolean {
    return this.id.length === 0
      && this.code.length === 0
      && OptionItem.idOrUndefined(this.disabled) === undefined
      ;
  }

  public clear() {
    this.id = '';
    this.code = '';
    this.disabled = undefined;
  }

  constructor() {
  }

}

interface GeneralPrinterTemplateErrorMap {
  code?: FieldError;
}
