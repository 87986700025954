/* eslint-disable */
import { Component, OnInit } from '@angular/core';
import { Transport, TransportService } from '../../../../lib/transport/transport.service';
import { QueryFieldModel } from '../../../../util/core-utils';
import { OrderType, QueryResult } from '../../../../lib/util/services';
import { Set } from 'immutable';
import { TransportSearch } from '../../../../lib/transport/transport-search.service';
import { TranslateService } from '@ngx-translate/core';
import { RightModel } from '../../../../app.rights';
import { RightResolver, RightService } from '../../../../lib/right.service';
import transportStates = Transport.transportStates;
import TransportState = Transport.TransportState;
import * as moment from 'moment';
/* eslint-enable */

@Component({
  selector: 'app-anysped-dashboard-transport',
  templateUrl: './anysped-dashboard-transport.component.html',
  styleUrls: ['./anysped-dashboard-transport.component.scss']
})
export class AnyspedDashboardTransportComponent implements OnInit {

  pagingId = 'DashboardTransportList';

  Transport = Transport;

  queryModel: QueryFieldModel<Transport.OrderField> = new QueryFieldModel(Transport.OrderField.ID, OrderType.DESC);

  states: TransportSearch.StateItem[] = [];
  transportList: TransportModel[] = [];

  showTransport: boolean = false;

  rightModel: RightModel = RightModel.empty();

  constructor(
    private transportService: TransportService,
    private translateService: TranslateService,
    private rightService: RightService,
  ) { }

  ngOnInit() {
    this.loadRightModels(() => {
      this.loadStates(() => {
        this.loadList();
      });
    });
  }

  private loadRightModels(completion: () => void) {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        completion();
      }
    );
  }

  private loadStates(completion: () => void) {
    this.states = [];
    Transport.transportStates.forEach(
      (state) => {
        const item = {
          id: state.state,
          itemName: state.stringKey,
          name: state.stringKey,
        };
        this.states.push(item);
      });
    this.states.forEach(
      (state: TransportSearch.StateItem) => {
        this.translateService.get(state.name).subscribe((stateName: string) => {
            state.name = stateName;
          }
        )
      });
    completion();
  }

  loadList(pageNumber?: number) {
    if (this.rightModel.transportRead.hasRight()) {
      const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
      const order = this.queryModel.getOrder();

    const stateSet: TransportState[] = [];
      stateSet.push(transportStates.find((state) => state.state === 'RUNNING')!.state);
      stateSet.push(transportStates.find((state) => state.state === 'UNDER_REPLANNING')!.state);

      this.transportService.query({
        state: stateSet.length > 0 ? Set.of(...stateSet) : undefined,
        orders: Set.of(order),
        paging: requestedPage ? {
          pageNumber: requestedPage,
          numberOfItems: this.queryModel.itemsPerPage
        } : undefined
      }).subscribe((result: QueryResult<Transport.Transport>) => {

        this.transportList = [];
        result.items.forEach((transport: Transport.Transport) => {
          const transportModel = new TransportModel();
          transportModel.id = transport.id + '';
          transportModel.waybillNumber = transport.waybillNumber;
          transportModel.vehicleLicencePlate = transport.vehicle ? transport.vehicle.licencePlate : '';
          transportModel.driverName = transport.driver ? transport.driver.personName : '';
          transportModel.creationTime = transport.creationTime.toUtcIsoString();
          transportModel.startTime = transport.startTime ? transport.startTime.toUtcIsoString() : '';
          transportModel.closeTime = transport.closeTime ? transport.closeTime.toUtcIsoString() : '';
          transportModel.state = this.states.filter((s) => s.id === transport.state)[0].name;
          transportModel.securityGuard = transport.securityGuard;
          transportModel.safetyShipping = transport.safetyShipping;
          transportModel.delayMinutes = transport.delayMinutes;
          this.transportList.push(transportModel);
        });
        if (this.transportList.length > 0) {
          this.showTransport = true;
        }
        this.queryModel.currentPage = requestedPage;
        this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
        this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
      });
    }
  }


  orderBy(field: Transport.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }


  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  getDelay(delayMins?: number): moment.Duration | undefined {
    return delayMins && delayMins > 0 ? moment.duration(delayMins, 'minutes') : undefined;
  }

  getDelayIcon(delay: number): string {
    if (delay > 0) {
      return 'icomoon icomoon-transport-delay';
    }
    return '';
  }

  getSafetyShippingIcon(safetyShipping: boolean): string {
    if (safetyShipping) {
      return 'icomoon icomoon-safety-shipping';
    }
    return '';
  }

}

class TransportModel {
  id: string = '';
  waybillNumber: string = '';
  vehicleLicencePlate: string = '';
  driverName: string = '';
  creationTime: string = '';
  startTime: string = '';
  closeTime: string = '';
  safetyShipping: boolean = false;
  securityGuard?: Transport.SecurityGuard;
  state: string = '';
  delayMinutes: number;
}
