import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DocumentGroupGraphNode} from "../document-group-graph";
import {FileTypeModelFactory} from "../../../../util/file-attachment.model";
import {DocumentGroup} from "../../../../lib/document/document-group.service";

@Component({
  selector: 'app-document-library-group-list',
  templateUrl: './document-library-group-list.component.html',
  styleUrls: ['./document-library-group-list.component.scss']
})
export class DocumentLibraryGroupListComponent implements OnInit {

  FileTypeModelFactory = FileTypeModelFactory;

  @Input()
  documentGroups: DocumentGroupGraphNode[] = [];

  @Output()
  groupClicked: EventEmitter<DocumentGroupGraphNode> = new EventEmitter<DocumentGroupGraphNode>();

  get aspectRatio(): string {
    return DocumentGroup.DOCUMENT_LIBRARY_ASPECT_RATIO_STR;
  }

  constructor() {
  }

  ngOnInit(): void {
  }

  onGroupClicked(group: DocumentGroupGraphNode) {
    this.groupClicked.emit(group);
  }

  displayAsPlaceholder(m: DocumentGroupGraphNode): boolean {
    return !this.displayAsImage(m);
  }

  displayAsImage(m: DocumentGroupGraphNode): boolean {
    if (!m.hasImage() || !m.getImage().downloaded) {
      return false;
    }
    return true;
  }

  getPreviewDataUrl(m: DocumentGroupGraphNode): string | undefined {
    return m.getImage().base64dataUrl;
  }


}
