<div>
  <div class="col-md-12 d-flex flex-wrap">
    <ng-template dql-search-container-holder>
    </ng-template>
  </div>
  <div class="form-group text-center" *ngIf="cannotLoad">
    <p class="empty-form-hint-text">{{ 'DQL_SEARCH_CANNOT_LOAD_QUERY' | translate }}</p>
  </div>
  <div class="form-group text-center" *ngIf="pureQueryBuilder.isEmpty() && !cannotLoad">
    <p class="empty-form-hint-text">{{ 'DQL_SEARCH_ADD_FIELD_TEXT' | translate }}</p>
    <button type="button" class="btn btn-secondary" (click)="openNewSearchFieldDialog()">{{ 'COMMON_BUTTON_ADD' |
        translate }}
    </button>
  </div>
  <div class="row card-footer p-0">
    <div class="col-md-12 pr-0 pl-0">
      <div class="d-flex flex-wrap justify-content-between">
        <div class="row w-100 border-bottom ml-0"
             *ngIf="!pureQueryBuilder.isEmpty() && !cannotLoad">
          <label class="mt-1"><strong>{{getQueryString()}}</strong>
          </label>
        </div>
        <div class="row w-100 ml-0">
          <div class="d-flex col-md-6 pl-0 mt-1">
            <div class="flex-grow-1" *ngIf="dqlStoredQueryArgs">
              <angular2-multiselect
                class="form-control"
                [(ngModel)]="_selectedStoredQuery"
                [data]="storedQueries"
                [settings]="dropdownSettings"
                (onRemoteSearch)="loadStoredQueries($event.target.value)"
                (onSelect)="onStoredQueryChanged()">
              </angular2-multiselect>
            </div>
            <div>
              <button type="button" (click)="openUpdateDialog()" class="btn btn-secondary-green ml-1"
                      *ngIf="selectedStoredQuery && rightModel.storedDqlUpdate.hasRight() && !cannotLoad">
                {{'DQL_SEARCH_STORED_QUERY_UPDATE' | translate}}
              </button>
              <button type="button" (click)="openSaveDialog()" class="btn btn-primary-green ml-1 "
                      *ngIf="!pureQueryBuilder.isEmpty() && rightModel.storedDqlCreate.hasRight() && !cannotLoad">
                {{'DQL_SEARCH_STORED_QUERY_SAVE' | translate}}
              </button>
            </div>
          </div>
          <div class="btn-group col-md-6 justify-content-end pr-0" role="group">
            <button type="button" (click)="clearSearch(true)"
                    class="btn btn-secondary mr-1 search-button">
              {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
            </button>
            <button type="button" (click)="openNewSearchFieldDialog()"
                    [disabled]="cannotLoad"
                    class="btn btn-primary-green mr-1 search-button">
              {{'COMMON_BUTTON_ADD' | translate}}
            </button>
            <button type="button" (click)="onSearchClicked()"
                    class="btn btn-primary search-button"
                    [disabled]="pureQueryBuilder.isEmpty() || cannotLoad">
              {{'COMMON_BUTTON_SEARCH' | translate}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
