<div class="d-flex justify-content-between">
  <h5>{{editModel.name}}</h5>
  <div class="d-flex align-items-center justify-content-start">
    <i class="icomoon {{getTaskStateIcon(state)}} pr-quarter icon-standard cursor-pointer"
       title="{{getTaskStateNameKey(state) | translate}}"></i>
    <div *ngIf="getTaskStateIcon(state) !== ''" [ngClass]="{'shipment-state-blue': state === 'ARRIVED'}">
      {{getTaskStateNameKey(state) | translate}}
    </div>
  </div>
</div>
<div class="d-flex mb-1">
  <i class="icomoon icomoon-location icon-large pr-quarter"></i>
  <div>
    <p class="mb-0">{{getPostalAddressText()}}</p>
    <ng-container *ngIf="editModel.geocodeStatus.status === 'FAILED'">
      <i class="icomoon icon-standard pr-quarter {{editModel.geocodeStatus.iconClass}}" title="{{editModel.geocodeStatus.stringKey | translate}}"></i>
      <p class="mb-0 transport-label-small">{{editModel.geocodeStatus.stringKey | translate}}</p>
    </ng-container>
    <ng-container *ngIf="editModel.geocodeStatus.status === 'PARTIAL'">
      <ng-template #partialGeocodePopover>
        <i class="icomoon {{editModel.geocodeStatus.iconClass}}"></i>
        {{editModel.geocodeStatus.stringKey | translate}}
      </ng-template>
      <i class="icomoon icon-standard pr-quarter {{editModel.geocodeStatus.iconClass}}" title="{{editModel.geocodeStatus.stringKey | translate}}"
         [popover]="partialGeocodePopover" triggers="mouseenter:mouseleave">
      </i>
      <p class="mb-0 transport-label-small" *ngIf="editModel.coordinate">{{editModel.coordinate.latitude.toString()}}, {{editModel.coordinate.longitude.toString()}}</p>
    </ng-container>
    <ng-container *ngIf="editModel.geocodeStatus.status === 'QUEUED'">
      <i class="icomoon icon-standard icomoon-new-state-in-progress pr-quarter" title="{{editModel.geocodeStatus.stringKey | translate}}"></i>
    </ng-container>
    <ng-container *ngIf="editModel.geocodeStatus.status === 'DONE' || editModel.geocodeStatus.status === 'MANUAL'">
      <p class="mb-0 transport-label-small" *ngIf="editModel.coordinate">{{editModel.coordinate.latitude.toString()}}, {{editModel.coordinate.longitude.toString()}}</p>
    </ng-container>
  </div>
</div>

<div #gmap style="height:300px;width:100%"></div>

<div class="modal-footer" *ngIf="modified">
  <button type="button" class="btn btn-secondary" (click)="cancelModification()">
    {{'COMMON_BUTTON_CANCEL' | translate}}
  </button>
  <button type="button" class="btn btn-primary" (click)="saveModification()">{{'COMMON_BUTTON_SAVE' |
    translate}}
  </button>
</div>
