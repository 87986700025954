import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {Address, AddressModel} from '../../../../lib/address';
import {Angular2Multiselects} from '../../../../util/multiselect';
import {OrderType, QueryResult} from '../../../../lib/util/services';
import {Country, CountryService} from '../../../../lib/country.service';
import {List, Set} from 'immutable';
import {ConfigurationService} from '../../../../lib/core-ext/configuration.service';
import {CustomerRecord, CustomerRecordService} from '../../../../lib/customer/customer-record.service';
import {UiConstants} from '../../../../util/core-utils';
import {Strings} from '../../../../lib/util/strings';
import {PersonCustomerRecordItem} from '../../customer-record-edit/customer-record-edit.component';
import {Editor, Toolbar} from 'ngx-editor';

@Component({
  selector: 'app-customer-record-contact-locations-create-edit-dialog',
  templateUrl: './customer-record-contact-locations-create-edit-dialog.component.html',
  styleUrls: ['./customer-record-contact-locations-create-edit-dialog.component.scss']
})
export class CustomerRecordContactLocationsCreateEditDialogComponent implements OnInit, OnDestroy {
  UiConstants = UiConstants;
  CustomerRecordContactLocationDialogMode = CustomerRecordContactLocationDialogMode;

  openingTimeEditor: Editor;
  openingTimeEditorToolbar: Toolbar = [
    ['bold', 'italic', 'underline', 'strike'],
    ['ordered_list', 'bullet_list']
  ];

  @Input()
  customerId: number;

  @Input()
  customerRecordId: number;

  @Output()
  private locationSubmitted: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('dialog', {static: true})
  dialog: ModalDirective;
  dialogVisible = false;

  model: CustomerRecordContactLocationCreateEditModel = new CustomerRecordContactLocationCreateEditModel();

  dropdownSettings: Angular2Multiselects.Settings;

  countryItems: List<AddressModel.CountryItem>;

  personCustomerRecords: PersonCustomerRecordItem[] = [];

  constructor(private countryService: CountryService,
              private configService: ConfigurationService,
              private customerRecordService: CustomerRecordService) {
  }

  ngOnInit() {
    this.initDropdownSettings();
    this.dialog.onShow.subscribe(() => {
      if (this.model.mode === CustomerRecordContactLocationDialogMode.CREATE) {
        this.loadCountries();
        this.loadPersonCustomerRecords();
      }
    });
  }

  private loadLocation(data: Address.ContactLocation) {
    this.model.mode = CustomerRecordContactLocationDialogMode.EDIT
    this.model.id = data.id;
    this.model.name = data.name;
    this.model.externalId = data.externalId ? data.externalId : '';
    this.model.comment = data.comment ? data.comment : '';
    this.model.openingTimes = data.openingTimes ? data.openingTimes : '';
    this.model.contractNumber = data.contractNumber ? data.contractNumber : '';
    this.loadCountries(() => {
      this.model.postalAddress.load(data.postalAddress);
    });
    this.loadPersonCustomerRecords(undefined, data.contactPersons.toArray());
  }

  private loadCountries(completion?: () => void) {
    this.countryService.query({}).subscribe((result: QueryResult<Country.Country>) => {
      this.countryItems = AddressModel.CountryItem.fromCountryList(result.items);
      this.model.postalAddress.loadCountryItems(this.countryItems, this.configService.getDefaultSelectedCountryCode());
      if (completion) {
        completion();
      }
    });
  }

  loadPersonCustomerRecords(q?: string, ids?: number[]) {
    this.customerRecordService.globalQuery({
      name: q ? Strings.undefinedOrNonEmpty(q) : undefined,
      disabled: false,
      customerTypes: ['PERSON'],
      contactPersonType: true,
      orders: Set.of({type: OrderType.ASC, field: CustomerRecord.OrderField.NAME}),
      paging: {
        pageNumber: 1,
        numberOfItems: 30
      },
      fields: Set.of('id', 'external_id', 'name', 'position', 'postal_address', 'disabled'),
      noProgressBar: true
    }).subscribe((result: QueryResult<CustomerRecord.CustomerRecord>) => {
      this.personCustomerRecords = result.items.toArray()
        .map(r => this.toCustomerItem(r));
      if (ids) {
        const notFoundIds = ids.filter(id => !this.personCustomerRecords.map(r => r.id).includes(id));
        if (notFoundIds.length > 0) {
          this.customerRecordService.globalQuery({
            customerRecordIdSet: Set.of(...ids)
          }).subscribe(result => {
            this.model.contactPersons = result.items.toArray().map(r => this.toCustomerItem(r));
          });
        } else {
          this.model.contactPersons = this.personCustomerRecords.filter(r => ids.includes(r.id));
        }
      }
    });
  }

  submit() {
    if (this.model.mode === CustomerRecordContactLocationDialogMode.CREATE) {
      this.createLocation();
    } else {
      this.editLocation();
    }
  }

  createLocation() {
    this.customerRecordService.createContactLocation({
      customerId: this.customerId,
      customerRecordId: this.customerRecordId,
      name: this.model.name,
      externalId: Strings.undefinedOrNonEmpty(this.model.externalId),
      comment: Strings.undefinedOrNonEmpty(this.model.comment),
      openingTimes: Strings.undefinedOrNonEmpty(this.model.openingTimes),
      postalAddress: this.model.postalAddress.toData()!,
      contractNumber: Strings.undefinedOrNonEmpty(this.model.contractNumber),
      contactPersons: List.of(...this.model.contactPersons.map(r => r.id))
    }).subscribe(result => {
      this.locationSubmitted.emit();
      this.closeDialog();
    });
  }

  editLocation() {
    this.customerRecordService.updateContactLocation({
      customerId: this.customerId,
      customerRecordId: this.customerRecordId,
      id: this.model.id!,
      name: this.model.name,
      externalId: this.model.externalId,
      comment: Strings.undefinedOrNonEmpty(this.model.comment),
      openingTimes: Strings.undefinedOrNonEmpty(this.model.openingTimes),
      contractNumber: Strings.undefinedOrNonEmpty(this.model.contractNumber),
      postalAddress: this.model.postalAddress.toData()!,
      contactPersons: List.of(...this.model.contactPersons.map(r => r.id))
    }).subscribe(result => {
      this.locationSubmitted.emit();
      this.closeDialog();
    });
  }

  showDialog(data?: Address.ContactLocation) {
    this.openingTimeEditor = new Editor();
    this.model.reset();
    if (data) {
      this.loadLocation(data);
    }
    this.dialogVisible = true;
    this.dialog.show();
  }

  closeDialog() {
    this.dialogVisible = false;
    this.dialog.hide();
    this.openingTimeEditor.destroy();
  }

  initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .remoteSearch(true)
      .enableCheckAll(true)
      .build();
  }

  locationStepValid: () => boolean = () => {
    return this.model.postalAddress.valid;
  };

  ngOnDestroy(): void {
  }

  private toCustomerItem(r: CustomerRecord.CustomerRecord) {
    return {
      id: r.customerRecordId, itemName: r.name, position: r.position,
      itemSubtitle: r.postalAddress
        ? Address.PostalAddressMapper.toString(r.postalAddress, this.configService.getPostalAddressFormat())
        : undefined
    };
  }
}

export enum CustomerRecordContactLocationDialogMode {
  CREATE,
  EDIT
}

export class CustomerRecordContactLocationCreateEditModel {

  mode: CustomerRecordContactLocationDialogMode = CustomerRecordContactLocationDialogMode.CREATE;
  id?: number;
  name: string = '';
  externalId: string = '';
  comment: string = '';
  openingTimes: string = '';
  contractNumber: string = '';
  postalAddress: AddressModel.PostalAddressModel;
  contactPersons: PersonCustomerRecordItem[] = [];

  constructor() {
    this.postalAddress = new AddressModel.PostalAddressModel();
    this.postalAddress.type = AddressModel.PostalAddressModelType.COMPLEX;
  }

  reset() {
    this.mode = CustomerRecordContactLocationDialogMode.CREATE;
    this.id = undefined;
    this.name = '';
    this.externalId = '';
    this.comment = '';
    this.openingTimes = '';
    this.contractNumber = '';
    this.postalAddress.complexValue.reset();
    this.contactPersons.splice(0, this.contactPersons.length);
  }

}
