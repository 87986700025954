/* eslint-disable */
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Address, AddressModel } from '../../../lib/address';
import { FormGroup, NgForm } from '@angular/forms';
import { PostalAddressComplexComponent } from '../postal-address/complex/postal-address-complex.component';
import { ConfigModel } from '../../../util/task-record-utils';
import { ConfigurationService } from '../../../lib/core-ext/configuration.service';
import PostalAddressModelType = AddressModel.PostalAddressModelType;

/* eslint-enable */

@Component({
  selector: 'app-billing-address',
  templateUrl: './billing-address.component.html',
  styleUrls: ['./billing-address.component.scss']
})
export class BillingAddressComponent implements OnInit, AfterViewInit {

  AddressModel = AddressModel;
  Address = Address;

  @Input()
  readonly: boolean;

  @Input()
  forceComplex: boolean = true;

  @Input()
  form?: NgForm;

  @Input()
  model?: AddressModel.PostalAddressModel;

  @Input()
  postalAddress?: AddressModel.PostalAddressModel;

  @Input()
  addressEqPostal?: boolean = true;

  @Input()
  postalAddressType?: PostalAddressModelType;

  @Input()
  required: boolean = false;
  @Output() checkboxFalse: EventEmitter<any> = new EventEmitter();
  @Output() checkboxTrue: EventEmitter<any> = new EventEmitter();
  @Output() showDialog: EventEmitter<any> = new EventEmitter();

  @ViewChild(PostalAddressComplexComponent)
  child?: PostalAddressComplexComponent;

  formGroup: FormGroup;

  config: ConfigModel = new ConfigModel();

  @Input()
  addressNameStringKey: string;

  constructor(private configService: ConfigurationService) {
  }

  ngOnInit(): void {
    this.loadConfig();
  }

  ngAfterViewInit(): void {
    if (this.required) {
      this.addAddress();
    }
  }

  private loadConfig() {
    this.config = this.configService.getConfigurationModel();
  }

  checkboxChangedEvent(e) {
    if (e.target.checked) {
      if (this.forceComplex && this.postalAddressType !== PostalAddressModelType.COMPLEX) {
        this.showDialog.emit(null);
        this.unCheck();
      }
      else {
        this.checkboxTrue.emit(null);
        this.makePostalAddressEqualWithInvoiceAddress();
      }
    }
    else {
      this.checkboxFalse.emit(null);
    }
  }

  unCheck() {
    setTimeout(() => {
      this.addressEqPostal = false;
    })
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    if (!formControlName) {
      if (this.model && this.child) {
        if (this.child.hasLocalFieldError()) {
          return true;
        }
      }
    }
    return false;
  }

  get m(): AddressModel.PostalAddressModel {
    return this.model!;
  }

  deleteAddress() {
    if (this.model) {
      this.model.reset();
      this.model.type = PostalAddressModelType.NONE;
      this.addressEqPostal = false;
    }
  }

  addAddress() {
    this.unCheck();
  }

  makePostalAddressEqualWithInvoiceAddress() {
    if (this.model && this.postalAddress) {
      this.model.complexValue.country = this.postalAddress.complexValue.country;
      this.model.complexValue.houseNumber = this.postalAddress.complexValue.houseNumber;
      this.model.complexValue.addressType = this.postalAddress.complexValue.addressType;
      this.model.complexValue.address = this.postalAddress.complexValue.address;
      this.model.complexValue.zipCode = this.postalAddress.complexValue.zipCode;
      this.model.complexValue.building = this.postalAddress.complexValue.building;
      this.model.complexValue.city = this.postalAddress.complexValue.city;
      this.model.complexValue.floor = this.postalAddress.complexValue.floor;
      this.model.complexValue.door = this.postalAddress.complexValue.door;
      this.model.complexValue.name = this.postalAddress.complexValue.name;
    }
  }

}
