/* eslint-disable */
import { Component, OnInit } from '@angular/core';
import { UIRouter } from '@uirouter/angular';
import { StateName } from '../../../../app.state-names';
import {
  listItemParamTypes,
  ListItemResourceService,
  ListItemTypeFieldErrorMap,
  ListItemTypeParam
} from '../../../../lib/list-item/list-item-resource.service';
import { FieldError, FieldErrors, ObservableErrorResourceParser } from '../../../../lib/util/errors';
import { ListItemParamTypeItem, ListItemTypeCreateModel } from '../../../../util/list-item-utils';
import { RootCoreService } from '../../../../lib/root-core.service';
import { BreadcrumbParent } from '../../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { TranslateService } from '@ngx-translate/core';
import { MultiselectOptionItem, SelectUtils, UiConstants } from '../../../../util/core-utils';
import { StringKey } from '../../../../app.string-keys';
import { ToasterService } from '../../../../fork/angular2-toaster/src/toaster.service';
import { Angular2Multiselects } from '../../../../util/multiselect';
import { Strings } from '../../../../lib/util/strings';
import { ListItemMapService } from '../../../../lib/list-item/list-item-map.service';

/* eslint-enable */

@Component({
  selector: 'app-list-item-type-create',
  templateUrl: 'list-item-type-create.component.html',
  styleUrls: ['list-item-type-create.component.css']
})
export class ListItemTypeCreateComponent implements OnInit {
  SelectUtils = SelectUtils;
  UiConstants = UiConstants;

  model: ListItemTypeCreateModel;
  paramTypes: ListItemParamTypeItem[];
  fieldErrors: ListItemTypeFieldErrorMap;
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  filterTypes: MultiselectOptionItem<number>[] = [];
  dropdownSettings: Angular2Multiselects.Settings;
  localSingleDropdown: Angular2Multiselects.Settings = Angular2Multiselects.LOCAL_SINGLE_SELECT_TRANSLATE;

  constructor(private rootService: RootCoreService,
              private listItemService: ListItemResourceService,
              private translateService: TranslateService,
              private uiRouter: UIRouter,
              private toasterService: ToasterService) {
    this.model = new ListItemTypeCreateModel();
    this.fieldErrors = {};
  }

  ngOnInit() {
    this.initDropdownSettings();
    this.translateService.get('LIST_ITEM_TYPE_PANEL_HEADING_CREATE').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('MENU_NAVBAR_LIST_ITEM_TYPE').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.LIST_ITEM_TYPE_LIST});
      }
    );
    this.loadParamTypes();
    this.loadFilterTypes();
  }

  initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
  }

  private loadParamTypes() {
    this.paramTypes = [];
    listItemParamTypes.forEach((type) => {
      const item = {
        id: type.type,
        text: '....'
      };
      this.paramTypes.push(item);
      this.translateService.get(type.stringKey).subscribe((text: string) => {
        item.text = text;
      })
    });
  }

  loadFilterTypes(q?: string) {
    this.listItemService.getTypeList({
      key: q ? Strings.undefinedOrNonEmpty(q) : undefined,
      order: '+key',
      disabled: false,
      page_number: 1,
      number_of_items: UiConstants.autocompletePageSize,
      no_progress_bar: true
    }).subscribe(result => {
      this.filterTypes = result.items.map(t => ({id: t.id, itemName: t.key}));
    });
  }

  back() {
    window.history.back();
  }

  removeFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.fieldErrors, fieldError);
  }

  addNewParam() {
    this.model.params.push({
      key: '',
      paramType: 'STRING',
      defaultValue: ''
    });
  }

  deleteParam(i: number) {
    this.model.params.splice(i, 1);
  }

  private getParamsList(): ListItemTypeParam[] {
    const result: ListItemTypeParam[] = [];
    this.model.params.forEach((param) => {
      result.push({
        key: param.key,
        param_type: param.paramType,
        default_string_value: param.paramType === 'STRING' ? param.defaultValue : undefined,
        default_number_value: param.paramType === 'NUMBER' ? <number | undefined>param.defaultValue : undefined
      });
    });
    return result;
  }

  create() {
    for (let i = 0; i < this.model.params.length; i++) {
      if (!this.isParamKeyUnique(this.model.params[i].key, i)) {
        return;
      }
    }
    this.listItemService.createType({
      key: this.model.key,
      params: this.getParamsList(),
      filter_type_id: this.model.filterTypeId,
      list_item_order_field: this.model.orderField!,
      list_item_order_type: this.model.orderType!
    }).subscribe(response => {
        this.uiRouter.stateService.go(StateName.LIST_ITEM_TYPE_LIST);
      },
      (error: any) => {
        const res = ObservableErrorResourceParser.parseError(error);
        this.fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
        this.toasterService.pop({
          timeout: UiConstants.ToastTimeoutLong,
          type: UiConstants.toastTypeError,
          title: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_TITLE),
          body: this.fieldErrors.params
            ? this.fieldErrors.params.text
            : this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_MESSAGE)
        });
      }
    );
  }

  isParamKeyUnique(key: string, index: number): boolean {
    for (let i = 0; i < this.model.params.length; i++) {
      if (index !== i && this.model.params[i].key === key) {
        return false;
      }
    }
    return true;
  }
}
