import { Injectable } from '@angular/core';
import { MultiselectProvider } from '../multiselect-provider';
import { Observable, Observer } from 'rxjs';
import { MultiselectOptionItem, UiConstants } from '../../util/core-utils';
import { QueryResult } from '../util/services';
import { Process, ProcessService } from './process.service';
import { List, Set } from 'immutable';
import { FilterField } from '../query/filterfields';
import { CriteriaBuilder } from '../../util/model-utils';
import { Query } from '../query/field';

@Injectable()
export class ProcessMultiselectProvider implements MultiselectProvider {

  private multiselectQueryRequest: Process.QueryRequest = {
    fields: f => Set.of(f.id, f.name, f.state),
    order: f => List.of(f.name.asc().nullsLast()),
    paging: {
      pageNumber: 1,
      numberOfItems: UiConstants.autocompletePageSize,
    },
    noProgressBar: true,
  };

  constructor(private processService: ProcessService) {
  }

  load(queryRequest: Process.QueryRequest): Observable<MultiselectOptionItem<number>[]> {
    return Observable.create((observer: Observer<MultiselectOptionItem<number>[]>) => {
      this.processService.query(queryRequest).subscribe((result: QueryResult<Process.Process>) => {
          observer.next(result.items.map(item => this.toMultiselectOptionItem(item!)).toArray());
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  loadAll(predicate?: string): Observable<MultiselectOptionItem<number>[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.filter = (f: FilterField.Process) => CriteriaBuilder.builder()
      .addString((predicate) => f.name.containsIgnoreCase(predicate), predicate)
      .build();
    return this.load(queryRequest);
  }

  getById(id: number): Observable<MultiselectOptionItem<number>> {
    return Observable.create((observer: Observer<MultiselectOptionItem<number>>) => {
      this.processService.get({
        id: id
      }).subscribe((result: Process.Process) => {
          observer.next(this.toMultiselectOptionItem(result!));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  getByIds(ids: number[]): Observable<MultiselectOptionItem<number>[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    const filters: Query.Criteria[] = [];
    const f: FilterField.Process = new FilterField.Process();
    ids.forEach(id => {
      filters.push(f.id.eq(id));
    });
    queryRequest.filter = f => Query.Criterias.anyOf(List.of(...filters));
    return this.load(queryRequest);
  }

  toMultiselectOptionItem(item: Process.Process): MultiselectOptionItem<number> {
    const ret = new MultiselectOptionItem<number>();
    ret.id = item.id;
    ret.itemName = item.name;
    return ret;
  }

}
