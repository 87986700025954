/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IconResource } from '../task/icon.service';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { ResourceQueryResult } from '../util/services';
import { EmptyMessage, IdentityMessage } from '../util/messages';
import { FormResource } from '../form/form-resource.service';
import { TaskResource } from '../task/task-resource.service';

/* eslint-enable */

@Injectable()
export class WorkflowResourceService extends BaseHttpService {

  // <editor-fold desc="CRUD">

  query(request: WorkflowResource.QueryRequest): Observable<ResourceQueryResult<WorkflowResource.Workflow>> {
    return this.http.get<ResourceQueryResult<WorkflowResource.Workflow>>(this.url, this.parseParams(request));
  }

  get(request: WorkflowResource.GetRequest): Observable<WorkflowResource.Workflow> {
    return this.http.get<WorkflowResource.Workflow>(this.url + `${request.id}`, this.parseParams(request));
  }

  create(request: WorkflowResource.CreateRequest): Observable<IdentityMessage> {
    return this.http.post<IdentityMessage>(this.url, request);
  }

  createNewVersion(request: IdentityMessage): Observable<IdentityMessage> {
    return this.http.post<IdentityMessage>(this.url + `${request.id}/create-new-version`, request);
  }

  clone(request: WorkflowResource.CloneRequest): Observable<IdentityMessage> {
    return this.http.post<IdentityMessage>(this.url + `${request.id}/clone`, request);
  }

  update(request: WorkflowResource.UpdateRequest): Observable<EmptyMessage> {
    return this.http.put<EmptyMessage>(this.url + `${request.id}`, request);
  }

  changeIcon(request: WorkflowResource.ChangeIconRequest): Observable<EmptyMessage> {
    return this.http.patch<EmptyMessage>(this.url + `${request.id}/change-icon`, request);
  }

  delete(request: IdentityMessage): Observable<EmptyMessage> {
    return this.http.delete<EmptyMessage>(this.url + `${request.id}`);
  }

  activate(request: IdentityMessage): Observable<EmptyMessage> {
    return this.http.post<EmptyMessage>(this.url + `${request.id}/activate`, {});
  }

  inactivate(request: IdentityMessage): Observable<EmptyMessage> {
    return this.http.post<EmptyMessage>(this.url + `${request.id}/inactivate`, {});
  }

  finalize(request: IdentityMessage): Observable<EmptyMessage> {
    return this.http.post<EmptyMessage>(this.url + `${request.id}/finalize`, {});
  }

  import(request: any): Observable<IdentityMessage> {
    return this.http.post<IdentityMessage>(this.url + `/import`, request);
  }

  export(request: IdentityMessage): Observable<any> {
    return this.http.post<any>(this.url + `${request.id}/export`, {});
  }

  createTask(request: WorkflowResource.AddTaskRequest): Observable<IdentityMessage> {
    return this.http.post<IdentityMessage>(this.url + `${request.workflow_id}/tasks?no_progress_bar=true`, request);
  }

  updateTask(request: WorkflowResource.UpdateTaskRequest): Observable<EmptyMessage> {
    return this.http.post<EmptyMessage>(this.url + `${request.workflow_id}/tasks/${request.task_id}?no_progress_bar=true`, request);
  }

  addTaskToGroup(request: WorkflowResource.AddTaskToGroupRequest): Observable<EmptyMessage> {
    return this.http.post<EmptyMessage>(
      this.url + `${request.workflow_id}/tasks/${request.task_id}/add-to-group?no_progress_bar=true`, request);
  }

  removeTaskFromGroup(request: WorkflowResource.RemoveTaskFromGroupRequest): Observable<EmptyMessage> {
    return this.http.post<EmptyMessage>(
      this.url + `${request.workflow_id}/tasks/${request.task_id}/remove-from-group?no_progress_bar=true`, request);
  }

  createGroup(request: WorkflowResource.AddGroupRequest): Observable<IdentityMessage> {
    return this.http.post<IdentityMessage>(this.url + `${request.workflow_id}/groups?no_progress_bar=true`, request);
  }

  updateGroup(request: WorkflowResource.UpdateGroupRequest): Observable<EmptyMessage> {
    return this.http.put<EmptyMessage>(this.url + `${request.workflow_id}/groups/${request.group_id}?no_progress_bar=true`, request);
  }

  updateObjectLocation(request: WorkflowResource.UpdateObjectLocationRequest): Observable<EmptyMessage> {
    return this.http.patch<EmptyMessage>(
      this.url + `${request.workflow_id}/objects/${request.object_id}/location?no_progress_bar=true`, request);
  }

  deleteObject(request: WorkflowResource.DeleteObjectRequest): Observable<EmptyMessage> {
    return this.http.delete<EmptyMessage>(this.url + `${request.workflow_id}/objects/${request.object_id}?no_progress_bar=true`);
  }

  createTransition(request: WorkflowResource.CreateTransitionRequest): Observable<IdentityMessage> {
    return this.http.post<IdentityMessage>(this.url + `${request.workflow_id}/transitions?no_progress_bar=true`, request);
  }

  deleteTransition(request: WorkflowResource.DeleteTransitionRequest): Observable<EmptyMessage> {
    return this.http.delete<EmptyMessage>(this.url + `${request.workflow_id}/transitions/${request.transition_id}?no_progress_bar=true`);
  }

  updateTransitionPoints(request: WorkflowResource.UpdateTransitionPointsRequest): Observable<EmptyMessage> {
    return this.http.patch<EmptyMessage>(
      this.url + `${request.workflow_id}/transitions/${request.transition_id}/points?no_progress_bar=true`, request);
  }

  updateTransitionRule(request: WorkflowResource.UpdateTransitionRuleRequest): Observable<EmptyMessage> {
    return this.http.patch<EmptyMessage>(this.url + `${request.workflow_id}/transitions/${request.transition_id}/rule`, request);
  }

  stateCount(request: IdentityMessage): Observable<Map<string, number>> {
    return this.http.get<Map<string, number>>(this.url + `${request.id}/state-count`);
  }

  // </editor-fold>

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/workflows/');
  }

}

export namespace WorkflowResource {

  // <editor-fold desc="CRUD">

  import TaskRecordExternalIdSequenceData = TaskResource.TaskRecordExternalIdSequenceData;

  export interface QueryRequest {
    fields?: string;
    filter?: string;
    order?: string;
    latest_versions_only?: boolean;
    page_number?: number;
    number_of_items?: number;
    rights?: string;
    no_progress_bar?: boolean;
  }

  export interface Workflow {
    id: number;
    base_id?: number;
    granted_rights?: string[];
    version: number;
    version_state: string;
    name: string;
    external_id: string;
    description?: string;
    icon?: IconResource.Icon;
    inheritance_mode: string;
    creation_time: string;
    update_time: string;
    last_modified_user: {
      id: number;
      user_name: string;
      person_name: string;
    };
    state_machine: StateMachine;
    start_with_first_task: boolean;
    first_task_id?: number;
    process_config?: ProcessConfig;
  }

  export interface ProcessConfig {
    name_pattern?: string;
    external_id_sequence?: TaskRecordExternalIdSequenceData;
    required_fields: string[];
  }

  export interface StateMachine {
    entry_point: EntryPoint;
    tasks: Task[];
    groups: Group[];
    transitions: Transition[];
  }

  export interface EntryPoint {
    id: number;
    location: Location;
  }

  export interface Location {
    top_left_point: Point;
    size: Size;
  }

  export interface Point {
    x: number;
    y: number;
  }

  export interface Size {
    width: number;
    height: number;
  }

  export interface Task {
    id: number;
    task: {
      id: number;
      name: string;
    };
    default_assignee_user?: {
      id: number;
      person_name: string;
      user_name: string;
    };
    default_assignee_user_group?: {
      id: number;
      name: string;
    };
    inherit_from_workflow_task?: {
      id: number;
      name: string;
    }
    group_id?: number;
    group_index?: number;
    location?: Location;
    name_pattern?: string;
    open_task_record_on_create: boolean;
    assignee_inheritance_mode: string;
    customer_inheritance_mode: string;
    place_of_consumption_inheritance_mode: string;
    priority_inheritance_mode: string;
    deadline_inheritance_mode: string;
    project_inheritance_mode: string;
    attachment_inheritance_mode: string;
    pdf_inheritance_mode: string;
    contract_number_inheritance_mode: string;
    form_field_inheritance_mode: string;
  }

  export interface Group {
    id: number;
    location: Location;
    name: string;
  }

  export interface Transition {
    id: number;
    input_object_id: number;
    output_object_id: number;
    points: Point[];
    rule: Rule;
  }

  export interface Rule {
    task_record_states: string[];
    rules: BaseRule[];
  }

  export interface BaseRule {
    rule_type: 'form_field' | 'task';
    operator?: string;
    command: string;
  }

  export interface FormFieldRule extends BaseRule {
    type: string;
    form_field: {
      id: number;
      disabled?: boolean;
      title?: string; // undefined in request
      field_data_type?: FormResource.FieldDataTypeSelector; // undefined in request
    };
    string_value?: string;
    number_value?: number;
    list_items?: {
      id: number;
      text?: string; // undefined in request
    }[];
  }

  export interface TaskRule extends BaseRule {
    type: string;
    users?: {
      id: number;
      user_name?: string;
      person_name?: string;
    }[];
    user_groups?: {
      id: number;
      name?: string;
    }[];
    importances?: string[];
  }

  export interface GetRequest {
    id: number;
    fields?: string;
    rights?: string;
  }

  export interface CreateRequest {
    name: string;
    external_id?: string;
    description?: string;
    icon?: IconResource.Icon;
    inheritance_mode: string;
    state_machine: {
      entry_point: {
        location: Location;
      };
    };
    start_with_first_task: boolean;
    process_config: ProcessConfig;
  }

  export interface CloneRequest extends CreateRequest {
    id: number;
  }

  export interface UpdateRequest {
    id: number;
    name: string;
    external_id: string;
    description?: string;
    icon?: IconResource.Icon;
    inheritance_mode: string;
    start_with_first_task: boolean;
    process_config: ProcessConfig;
  }

  export interface ChangeIconRequest {
    id: number;
    icon?: IconResource.Icon;
  }

  export interface AddTaskRequest {
    workflow_id: number;
    group_id?: number;
    task: {
      id: number;
    };
    location: Location;
  }

  export interface UpdateTaskRequest {
    workflow_id: number;
    task_id: number;
    name_pattern?: string;
    open_task_record_on_create: boolean;
    assignee_inheritance_mode: string;
    customer_inheritance_mode: string;
    place_of_consumption_inheritance_mode: string;
    priority_inheritance_mode: string;
    deadline_inheritance_mode: string;
    project_inheritance_mode: string;
    attachment_inheritance_mode: string;
    pdf_inheritance_mode: string;
    contract_number_inheritance_mode: string;
    form_field_inheritance_mode: string;
    default_assignee_user?: number;
    default_assignee_user_group?: number;
    inherit_from_workflow_task?: number;
  }

  export interface AddTaskToGroupRequest {
    workflow_id: number;
    task_id: number;
    group_id: number;
  }

  export interface RemoveTaskFromGroupRequest {
    workflow_id: number;
    task_id: number;
    location: Location;
  }

  export interface AddGroupRequest {
    workflow_id: number;
    location: Location;
  }

  export interface UpdateGroupRequest {
    workflow_id: number;
    group_id: number;
    name: string;
  }

  export interface UpdateObjectLocationRequest {
    workflow_id: number;
    object_id: number;
    location: Location;
  }

  export interface DeleteObjectRequest {
    workflow_id: number;
    object_id: number;
  }

  export interface CreateTransitionRequest {
    workflow_id: number;
    input_object_id: number;
    output_object_id: number;
    points: Point[];
  }

  export interface DeleteTransitionRequest {
    workflow_id: number;
    transition_id: number;
  }

  export interface UpdateTransitionPointsRequest {
    workflow_id: number;
    transition_id: number;
    points: Point[];
  }

  export interface UpdateTransitionRuleRequest {
    workflow_id: number;
    transition_id: number;
    task_record_states: string[];
    rules: BaseRule[];
  }

  // </editor-fold>

}
