/* eslint-disable */
import {Component, OnInit} from '@angular/core';
import {RightService} from '../../../lib/right.service';
import {RightModel} from '../../../app.rights';
import {UIRouter} from '@uirouter/angular';
import {AppworksDashboardTaskModel} from '../../../util/appworks-utils';
import {Arrays} from '../../../lib/util/arrays';
import {OrderType, QueryResult} from '../../../lib/util/services';
import {TaskRecordStateMachine} from '../../../lib/task/task-record-statemachine';
import {Task, TaskService} from '../../../lib/task/task.service';
import {Icon} from '../../../lib/task/icon.service';
import {TranslateService} from '@ngx-translate/core';
import {Set} from 'immutable';
import {SiteTourBaseComponent, SiteTourId} from '../../../lib/site-tour/site-tour.factory';
import {SiteTourService} from '../../../lib/site-tour/site-tour.service';
import {StateName} from '../../../app.state-names';
import {PaletteColorPickerComponent} from '../../../shared/palette-color-picker/palette-color-picker.component';
import {TaskStatisticsService} from "../../../lib/statistics/task-statistics/task-statistics.service";
import {ConfigurationService} from "../../../lib/core-ext/configuration.service";

/* eslint-enable */

@Component({
  selector: 'app-task-dashboard',
  templateUrl: 'task-dashboard.component.html',
  styleUrls: ['task-dashboard.component.scss']
})

export class TaskDashboardComponent implements OnInit, SiteTourBaseComponent {

  rightModel: RightModel = RightModel.empty();

  taskGlobalCount: GlobalCounts = new GlobalCounts();

  dashboardTaskModels: AppworksDashboardTaskModel[] = [];

  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  private currentPage: number = 1;
  private maxPages: number = 1;

  private readonly pageSize: number = 16;
  constructor(private rightService: RightService,
              private uiRouter: UIRouter,
              private configService: ConfigurationService,
              private translateService: TranslateService,
              private taskService: TaskService,
              private taskStatisticsService: TaskStatisticsService,
              private siteTourService: SiteTourService) {
    this.pageSize = configService.getConfiguration().feature_flags.task_record.webadmin_dashboard_visible_count;
  }

  ngOnInit() {
    this.init();
    this.translateService.get('MENU_NAVBAR_FORMS').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
  }

  init() {
    this.loadGlobalStat();
    this.loadTasks();
    this.siteTourService.startTour(SiteTourId.TASK_DASHBOARD, {
      skipCallback: undefined, completeCallback: () => {
        if (this.dashboardTaskModels.length > 0) {
          this.uiRouter.stateService.go(StateName.TASK_RECORD_LIST, {taskId: this.dashboardTaskModels[0].taskId});
        }
      }
    });
  }

  loadTasks() {
    this.taskService.statistics({
      statisticsType: 'ALL',
      orders: Set.of({field: Task.OrderField.EXPLICIT_ORDER_NUMBER, type: OrderType.ASC}),
      disabled: false,
      paging: this.isPageSizeValid(this.pageSize) ? {
        pageNumber: this.currentPage,
        numberOfItems: this.getNumberOfItems(this.currentPage)
      } : undefined
    }).subscribe((result: QueryResult<Task.Task>) => {
        this.currentPage++;
        if (this.isPageSizeValid(this.pageSize)) {
          this.maxPages = 1 + Math.ceil((result.pagingResult.currentNumberOfItems - this.getFirstPageNumberOfItems()) / this.getNumberOfItems(this.currentPage));
        }

        const tasks: Task.Task[] = result.items.toArray();

        Arrays.iterateByIndex(tasks, task => {
          const taskCount = this.calcSumRecords(task);
          const taskModel = new AppworksDashboardTaskModel();

          taskModel.name = task.name;
          taskModel.taskId = task.taskId;
          if (task.icon) {
            taskModel.icon = task.icon;
          }
          taskModel.color = task.taskRecordColor;
          taskModel.overdueTaskCount = task.overdueCount;

          let taskRecordCount = 0;
          if (task.statistics && taskCount > 0) {
            task.statistics!.forEach((value: number, state: TaskRecordStateMachine.State) => {
              taskModel.stats[state] = {
                state: state,
                count: value,
                percent: Math.round((value / taskCount) * 100)
              };
              taskRecordCount += value;
            });
            taskModel.taskRecordCount = taskRecordCount;
          }
          this.dashboardTaskModels.push(taskModel);
        });
        if (this.dashboardTaskModels.length === 0) {
          this.uiRouter.stateService.go(StateName.TASK_RECORD_GLOBAL_LIST);
          return;
        } else if (this.dashboardTaskModels.length === 1) {
          this.uiRouter.stateService.go(StateName.TASK_RECORD_LIST, {taskId: this.dashboardTaskModels[0].taskId});
          return;
        }

      },
      () => {
      });
  }

  calcSumRecords(task: Task.Task): number {
    if (!task.statistics) {
      return 0;
    }
    let sum: number = 0;
    task.statistics.forEach((value: number) => {
      sum += value;
    });
    return sum;
  }

  loadGlobalStat() {
    this.taskGlobalCount = new GlobalCounts();
    this.taskGlobalCount.taskCount = 0;
    this.taskGlobalCount.taskOverDueCount = 0;
    this.taskGlobalCount.taskRejectedCount = 0;
    this.taskGlobalCount.taskSubmittedCount = 0;
    this.taskStatisticsService.getSimpleStat({helpdesk: false}).subscribe(stat => {
      this.taskGlobalCount.taskCount = Array.from(stat.stateStat.values())
        .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
      this.taskGlobalCount.taskRejectedCount = stat.stateStat.get('REJECTED') ? stat.stateStat.get('REJECTED')! : 0;
      this.taskGlobalCount.taskSubmittedCount = stat.stateStat.get('SUBMITTED') ? stat.stateStat.get('SUBMITTED')! : 0;
      this.taskGlobalCount.taskOverDueCount += this.getTaskRecordCount(stat.overdueCount);
    })
  }

  getTaskIconFromId(taskId: number): Icon.Icon | undefined {
    let icon: Icon.Icon | undefined = undefined;
    this.dashboardTaskModels.forEach(dashboardTaskModel => {
      if (dashboardTaskModel.icon && dashboardTaskModel.taskId === taskId) {
        icon = dashboardTaskModel.icon;
      }
    });
    return icon;
  }

  getTaskRecordCount(count?: number): number {
    if (count) {
      return count;
    } else {
      return 0;
    }
  }

  getColor(task: AppworksDashboardTaskModel) {
    const colorId = task.color;
    return PaletteColorPickerComponent.resolveColor(colorId);
  }

  private getNumberOfItems(pageNumber: number) {
    return pageNumber === 1 ? this.getFirstPageNumberOfItems() : this.pageSize;
  }

  private getFirstPageNumberOfItems() {
    return this.pageSize - 1;
  }

  hasMorePages(): boolean {
    return this.isPageSizeValid(this.pageSize) && this.maxPages >= this.currentPage;
  }

  private isPageSizeValid(s: number) {
    return s > 2;
  }
}

class GlobalCounts {
  taskCount: number = 0;
  taskOverDueCount: number = 0;
  taskRejectedCount: number = 0;
  taskSubmittedCount: number = 0;
}
