import { Component, Input, OnInit } from '@angular/core';
import { RightModel } from '../../../../app.rights';
import { TypeBasedRoleResource, UserGroupService } from '../../../../lib/user-group.service';
import { TaskService } from '../../../../lib/task/task.service';
import { CustomerService } from '../../../../lib/customer/customer.service';
import { Set } from 'immutable';
import { Observable } from 'rxjs';
import { TypeBasedRoleEditModel } from './user-group-type-based-role-edit/user-group-type-based-role-edit.component';
import { Arrays } from '../../../../lib/util/arrays';
import { EmptyMessage, IdentityMessage } from '../../../../lib/util/messages';
import { MultiselectOptionItem } from '../../../../util/core-utils';

@Component({
  selector: 'app-usergroup-type-based-role-card',
  templateUrl: './user-group-type-based-role-card.component.html',
  styleUrls: ['./user-group-type-based-role-card.component.scss']
})
export class UserGroupTypeBasedRoleCardComponent implements OnInit {

  Type = UserGroupRoleType;

  @Input()
  type: UserGroupRoleType;

  @Input()
  typeId: number;

  @Input()
  readonly: boolean = true;

  @Input()
  rightModel: RightModel = RightModel.empty();

  roles: TypeBasedRoleModel[] = [];

  creationInProgress: boolean = false;
  listVisible: boolean = false;

  get headerKey(): string {
    switch (this.type) {
      case UserGroupRoleType.USERGROUP_TASK:
        return 'USER_GROUP_EDIT_TASK_BASED_ROLES';
      case UserGroupRoleType.USERGROUP_CUSTOMER:
        return 'USER_GROUP_EDIT_CUSTOMER_BASED_ROLES';
      case UserGroupRoleType.TASK:
      case UserGroupRoleType.CUSTOMER:
        return 'TASK_EDIT_USER_GROUP_ROLES';
    }
  }

  get typeTableHeaderKey(): string {
    switch (this.type) {
      case UserGroupRoleType.USERGROUP_TASK:
        return 'COMMON_TASK_TYPE';
      case UserGroupRoleType.USERGROUP_CUSTOMER:
        return 'CUSTOMER_SEARCH_FIELD_TYPE';
      case UserGroupRoleType.TASK:
      case UserGroupRoleType.CUSTOMER:
        return 'COMMON_USER_GROUP';
    }
  }

  get typeDestinationStateName(): string {
    switch (this.type) {
      case UserGroupRoleType.USERGROUP_TASK:
        return 'Admin.TaskEdit';
      case UserGroupRoleType.USERGROUP_CUSTOMER:
        return 'Admin.CustomerEdit';
      case UserGroupRoleType.TASK:
      case UserGroupRoleType.CUSTOMER:
        return 'Admin.UserGroupDetail';
    }
  }

  constructor(
    private userGroupService: UserGroupService,
    private taskService: TaskService,
    private customerService: CustomerService,
  ) { }

  ngOnInit(): void {
  }

  private loadRoles() {
    this.getQueryObservable().subscribe(result => {
      this.roles = this.toModelArray(result);
    });
  }

  private getQueryObservable(): Observable<TypeBasedRoleResource[]> {
    switch (this.type) {
      case UserGroupRoleType.USERGROUP_TASK:
        return this.userGroupService.getTaskBasedRoles(this.typeId);
      case UserGroupRoleType.USERGROUP_CUSTOMER:
        return this.userGroupService.getCustomerBasedRoles(this.typeId);
      case UserGroupRoleType.TASK:
        return this.taskService.getRoles({id: this.typeId});
      case UserGroupRoleType.CUSTOMER:
        return this.customerService.getRoles({id: this.typeId});
    }
  }

  private toModelArray(roles: TypeBasedRoleResource[]): TypeBasedRoleModel[] {
    if (roles.length > 0) {
      const result = roles.map(r => ({
        id: r.id!,
        userGroups: this.type === UserGroupRoleType.TASK || this.type === UserGroupRoleType.CUSTOMER
          ? r.user_group_ids!.map(id => ({id: id, itemName: ''}))
          : [],
        typeId: r.type_id,
        typeName: '',
        typeExternalId: '',
        privileges: r.privileges,
        editing: false
      }));
      this.loadTypes(result);
      return result;
    }
    else {
      return this.roles = [];
    }
  }

  private loadTypes(roles: TypeBasedRoleModel[]) {
    switch (this.type) {
      case UserGroupRoleType.USERGROUP_TASK:
        const taskIds = roles.map(r => r.typeId);
        this.taskService.query({
          taskIdSet: Set.of(...taskIds)
        }).subscribe(tasks => {
          roles.forEach(r => {
            r.typeName = tasks.items.toArray().find(t => t.taskId === r.typeId)!.name;
            r.typeExternalId = tasks.items.toArray().find(t => t.taskId === r.typeId)!.externalId!;
          });
        });
        break;
      case UserGroupRoleType.USERGROUP_CUSTOMER:
        const customerIds = roles.map(r => r.typeId);
        this.customerService.query({
          customerIdSet: Set.of(...customerIds)
        }).subscribe(customers => {
          roles.forEach(r => {
            r.typeName = customers.items.toArray().find(t => t.customerId === r.typeId)!.name;
            r.typeExternalId = customers.items.toArray().find(t => t.customerId === r.typeId)!.externalId!;
          });
        });
        break;
      case UserGroupRoleType.TASK:
      case UserGroupRoleType.CUSTOMER:
        const userGroupIds = Arrays.flatten(roles.map(r => r.userGroups.map(ug => ug.id)));
        this.userGroupService.query({
          id: userGroupIds.join()
        }).subscribe(ugs => {
          roles.forEach(r => {
            r.userGroups.forEach(ug => {
              const item = ugs.items.find(i => i.id === ug.id)!;
              ug.itemName = item.name;
            });
          });
        });
    }
  }

  createRole(model: TypeBasedRoleEditModel) {
    const request = model.toRequest();
    let observable: Observable<IdentityMessage>;
    switch (this.type) {
      case UserGroupRoleType.USERGROUP_TASK:
        observable = this.userGroupService.createTaskBasedRole(this.typeId, request);
        break;
      case UserGroupRoleType.USERGROUP_CUSTOMER:
        observable = this.userGroupService.createCustomerBasedRole(this.typeId, request);
        break;
      case UserGroupRoleType.TASK:
        request.type_id = this.typeId;
        observable = this.taskService.createRole(request);
        break;
      case UserGroupRoleType.CUSTOMER:
        request.type_id = this.typeId;
        observable = this.customerService.createRole(request);
        break;
    }
    observable.subscribe(result => this.loadRoles());
    this.creationInProgress = false;
  }

  updateRole(model: TypeBasedRoleEditModel) {
    const request = model.toRequest();
    let observable: Observable<EmptyMessage>;
    switch (this.type) {
      case UserGroupRoleType.USERGROUP_TASK:
        observable = this.userGroupService.updateTaskBasedRole(this.typeId, model.id!, request);
        break;
      case UserGroupRoleType.USERGROUP_CUSTOMER:
        observable = this.userGroupService.updateCustomerBasedRole(this.typeId, model.id!, request);
        break;
      case UserGroupRoleType.TASK:
        observable = this.taskService.updateRole(request);
        break;
      case UserGroupRoleType.CUSTOMER:
        observable = this.customerService.updateRole(request);
        break;
    }
    observable.subscribe(result => this.loadRoles());
  }

  deleteRole(id: number) {
    let observable: Observable<EmptyMessage>;
    switch (this.type) {
      case UserGroupRoleType.USERGROUP_TASK:
        observable = this.userGroupService.deleteTaskBasedRole(this.typeId, id);
        break;
      case UserGroupRoleType.USERGROUP_CUSTOMER:
        observable = this.userGroupService.deleteCustomerBasedRole(this.typeId, id);
        break;
      case UserGroupRoleType.TASK:
        observable = this.taskService.deleteRole({type_id: this.typeId, id: id, privileges: []});
        break;
      case UserGroupRoleType.CUSTOMER:
        observable = this.customerService.deleteRole({type_id: this.typeId, id: id, privileges: []});
        break;
    }
    observable.subscribe(result => this.loadRoles());
  }

  toggleListVisible() {
    this.listVisible = !this.listVisible;
    if (this.listVisible) {
      this.loadRoles();
    }
  }

  onCreateClicked() {
    if (!this.listVisible) {
      this.toggleListVisible();
    }
    this.creationInProgress = true;
  }

}

export interface TypeBasedRoleModel {
  id: number;
  userGroups: MultiselectOptionItem<number>[];
  typeId: number;
  typeName: string;
  typeExternalId: string;
  privileges: string[];
  editing: boolean;
}

export enum UserGroupRoleType {
  USERGROUP_TASK,
  USERGROUP_CUSTOMER,
  TASK,
  CUSTOMER
}
