import { Column } from './column.model';

export class Board<T> {
  constructor(public name: string, public columns: Column<T>[]) {
  }

  getColumn(id: string): Column<T> | undefined {
    return this.columns.find(c => c.id === id);
  }

  get columnIds(): string[] {
    return this.columns.map(c => c.id);
  }
}
