<div class="modal-container-for-border">
  <div class="modal-header">
    <p class="modal-title">{{'WARNING_MODAL_HEADER' | translate}}</p>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="modal-icon">
        <i class="icomoon icomoon-warning"></i>
      </div>
      <div class="modal-text">
        {{'WARNING_MODAL_BODY_1' | translate}}
        <br>
        {{'WARNING_MODAL_BODY_2' | translate}}
      </div>
    </div>
    <!-- /.row -->
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary-warning" (click)="action(true)">{{'WARNING_MODAL_LEAVE_BUTTON' | translate}}</button>
    <button class="btn btn-primary-warning" (click)="action(false)">{{'COMMON_BUTTON_CANCEL' | translate}}</button>
  </div>
</div>
<!-- /.modal-container-for-border -->
