import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TaskRecord, TaskRecordService} from '../../../lib/task/task-record.service';
import {Map, Set} from 'immutable';
import {MultiselectOptionItem, QueryFieldModel, UiConstants} from '../../../util/core-utils';
import {OrderType, ResourceQueryResult} from '../../../lib/util/services';
import {TaskRecordStateMachine} from '../../../lib/task/task-record-statemachine';
import {AssigneeListModel, ConfigModel} from '../../../util/task-record-utils';
import {ConfigurationService} from '../../../lib/core-ext/configuration.service';
import {AssigneeType} from '../../../shared/assignee-table-cell/assignee-table-cell.component';
import {RightModel} from '../../../app.rights';
import {RightResolver, RightService} from '../../../lib/right.service';
import {Device, DeviceManagementService} from '../../../lib/device-management.service';
import {User, UserService} from '../../../lib/user.service';
import {UserGroupService} from '../../../lib/user-group.service';
import {Angular2Multiselects} from '../../../util/multiselect';
import {BugReportSearch, BugReportSearchService} from '../../../lib/helpdesk/bug-report/bug-report-search-service';
import {TranslateService} from '@ngx-translate/core';
import {Arrays} from '../../../lib/util/arrays';
import {Icon} from '../../../lib/task/icon.service';
import {UserMeService} from '../../../lib/user/user-me.service';
import {HelpdeskState} from "../../../lib/statistics/task-statistics/helpdesk.state";

@Component({
  selector: 'app-helpdesk-bug-report-list',
  templateUrl: './bug-report-list.component.html',
  styleUrls: ['./bug-report-list.component.scss']
})
export class HelpdeskBugReportListComponent implements OnInit, OnDestroy {

  AssigneeType = AssigneeType;
  UiConstants = UiConstants;

  @Input()
  dashboard: boolean = false;

  list: BugReportListModel[] = [];
  queryModel: QueryFieldModel<TaskRecord.OrderField> = new QueryFieldModel(TaskRecord.OrderField.CREATION_TIME, OrderType.DESC);
  searchModel: BugReportSearch.Model = new BugReportSearch.Model();
  states: MultiselectOptionItem<HelpdeskState.State>[] = [];
  statesDropdownSettings: Angular2Multiselects.Settings;

  config: ConfigModel = new ConfigModel();
  rightModel: RightModel = RightModel.empty();
  currentUser: User;

  constructor(
    private taskRecordService: TaskRecordService,
    private rightService: RightService,
    private configService: ConfigurationService,
    private userService: UserService,
    private userMeService: UserMeService,
    private userGroupService: UserGroupService,
    private searchService: BugReportSearchService,
    private translateService: TranslateService,
    private deviceManagementService: DeviceManagementService,
  ) {
  }

  ngOnInit(): void {
    this.loadConfig();
    this.loadRightModels();
    this.loadMe();
    this.loadTaskRecordStates();
    this.initDropdownSettings();
    if (this.dashboard) {
      this.loadList();
    } else {
      this.loadSearch(() => this.loadList());
    }
  }

  private loadConfig() {
    this.config = this.configService.getConfigurationModel();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  loadMe() {
    this.userMeService.loadMe().subscribe(me => {
      this.currentUser = me;
    });
  }

  private async loadTaskRecordStates() {
    this.states = HelpdeskState.orderedHelpdeskStates;
  }

  private initDropdownSettings() {
    this.statesDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(false)
      .enableCheckAll(false)
      .translate(true)
      .text(this.translateService.instant('TASK_RECORD_SEARCH_STATE'))
      .build();
  }

  private loadSearch(completion: () => void) {
    this.searchService.getSearchData({}).subscribe(result => {
        this.postInitSearch(result, completion);
      }
    );
  }

  private postInitSearch(storedSearchData: BugReportSearch.SearchDataResult, completion: () => void) {
    this.queryModel.itemsPerPage = storedSearchData.searchData.itemsPerPage;
    this.queryModel.currentPage = storedSearchData.searchData.pageNumber;
    this.queryModel.setOrder(storedSearchData.searchData.order);
    this.searchModel.query = storedSearchData.searchData.query;
    this.searchModel.states = storedSearchData.searchData.states;
    completion();
  }

  public loadList(pageNumber?: number): void {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();
    const states: Set<HelpdeskState.State> | undefined = this.searchModel.states.length > 0
      ? Set.of(...this.searchModel.states.map(s => s.id))
      : undefined;
    this.taskRecordService.globalQuery({
      helpdeskEnabled: true,
      queryText: this.searchModel.query.length > 0 ? this.searchModel.query : undefined,
      helpdeskState: states,
      orders: order ? Set.of(order) : Set.of(),
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.dashboard ? 5 : this.queryModel.itemsPerPage
      } : undefined
    }).subscribe(result => {
      this.list = result.items.toArray().map(tr => {
        return {
          taskRecord: tr,
          taskIcon: tr.task!.icon,
          stateObject: TaskRecordStateMachine.taskRecordStates.get(tr.state),
          helpdeskState: HelpdeskState.getStateObject(tr.helpdeskState!),
          assignee: new AssigneeListModel(),
          hasProcess: tr.process !== undefined
        };
      });
      const assigneeUserIds: number[] = [];
      const assigneeUserGroupIds: number[] = [];
      const assigneeAppIds: number[] = [];
      this.list.forEach(tr => {
        if (this.config.assigneeWithUser) {
          if (tr.taskRecord.assignee && tr.taskRecord.assignee.userId) {
            assigneeUserIds.push(tr.taskRecord.assignee.userId);
          } else if (tr.taskRecord.userGroupId) {
            assigneeUserGroupIds.push(tr.taskRecord.userGroupId);
          }
        }
        if (this.config.assigneeWithMobileApp && tr.taskRecord.assignee && tr.taskRecord.assignee.mobileApplicationId) {
          assigneeAppIds.push(tr.taskRecord.assignee.mobileApplicationId);
        }
      });
      if (this.config.assigneeWithUser) {
        if (assigneeUserIds.length > 0) {
          this.loadUsersForList(assigneeUserIds);
        }
        if (assigneeUserGroupIds.length > 0) {
          this.loadUserGroupsForList(assigneeUserGroupIds);
        }
      }
      if (this.config.assigneeWithMobileApp) {
        this.loadAppsForList(assigneeAppIds);
      }
      this.queryModel.currentPage = requestedPage;
      this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
    });
  }

  private loadUsersForList(assigneeUserIds: number[]) {
    this.userService.query({
      id: assigneeUserIds.join(',')
    }).subscribe(users => {
      this.list.forEach(tr => {
        if (tr.taskRecord.assignee && tr.taskRecord.assignee.userId) {
          const assigneeUser = users.items.find((u) => u.id === tr.taskRecord.assignee!.userId);
          if (assigneeUser) {
            tr.assignee.assigneeUser = assigneeUser;
          }
        }
      });
    });
  }

  private loadUserGroupsForList(assigneeUserGroupIds: number[]) {
    this.userGroupService.query({
      id: assigneeUserGroupIds.join(',')
    }).subscribe(userGroups => {
      this.list.forEach(tr => {
        if (tr.taskRecord.userGroupId) {
          const assigneeUserGroup = userGroups.items.find((ug) => ug.id === tr.taskRecord.userGroupId);
          if (assigneeUserGroup) {
            tr.assignee.assigneeUserGroup = assigneeUserGroup;
          }
        }
      });
    });
  }

  private loadAppsForList(appIds: number[]) {
    this.deviceManagementService.query({
      id: appIds.join(',')
    }).subscribe((devices: ResourceQueryResult<Device> | null) => {
      this.list.forEach((tr) => {
        if (devices !== null) {
          tr.assignee.assigneeMobileApp = devices.items
            .find(d => tr.taskRecord.assignee !== undefined && tr.taskRecord.assignee.mobileApplicationId === d.id);
        }
      });
    });
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  ngOnDestroy() {
    if (!this.dashboard) {
      this.saveSearch();
    }
  }

  private saveSearch() {
    const request: BugReportSearch.SearchDataSetRequest = {
      searchData: {
        itemsPerPage: this.queryModel.itemsPerPage,
        pageNumber: this.queryModel.currentPage,
        order: this.queryModel.getOrder(),
        query: this.searchModel.query,
        states: this.searchModel.states
      }
    };
    this.searchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  onSearchReset() {
    this.searchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.loadList(1);
        });
      }
    );
  }

  canValidate(taskRecord: TaskRecord.TaskRecord): boolean {
    const fromState = taskRecord.state;
    return fromState === 'SUBMITTED' && TaskRecordStateMachine.StateMachine.canChangeState(fromState, 'FINISHED')
      && TaskRecordStateMachine.StateMachine.hasRightForStateChange(this.rightModel, fromState, 'FINISHED');
  }

  canInvalidate(taskRecord: TaskRecord.TaskRecord): boolean {
    const fromState = taskRecord.state;
    return fromState === 'SUBMITTED' && this.rightModel.taskRecordChangeStateUnsubmit.hasRight();
  }

  setBugReportState(state: TaskRecordStateMachine.State, id: number) {
    this.taskRecordService.setState({
      taskRecordIdSet: Set.of(...[id]),
      newState: TaskRecordStateMachine.taskRecordStates.get(state)
    }).subscribe(result => {
      this.loadList();
    }, error => {
      this.loadList();
    });
  }

  canModify(taskRecord: TaskRecord.TaskRecord, assignee?: User): boolean {
    const inState = TaskRecordStateMachine.StateMachine.canEdit(taskRecord.task, taskRecord.state);
    if (inState && taskRecord.state === 'IN_PROGRESS') {
      if (!assignee || !this.currentUser) {
        return false;
      }
      return assignee.id === this.currentUser.id;
    }
    return inState;
  }
}

interface BugReportListModel {
  taskRecord: TaskRecord.TaskRecord,
  taskIcon: Icon.Icon | undefined,
  stateObject: TaskRecordStateMachine.StateObject,
  helpdeskState: HelpdeskState.StateObject,
  assignee: AssigneeListModel,
  hasProcess: boolean
}
