/* eslint-disable */
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ShopRenter, ShopRenterService } from '../../lib/shoprenter/shoprenter.service';
import { Transition } from '@uirouter/angular';
import { ShopRenterModel } from '../shoprenter';
/* eslint-enable */

@Component({
  selector: 'app-shoprenter-auth',
  templateUrl: './shoprenter-auth.component.html',
  styleUrls: ['./shoprenter-auth.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ShopRenterAuthComponent implements OnInit {

  private _params: ShopRenterModel.ShopRenterAuthRequestParam;

  private _installing = false;
  private _installFailed = false;
  private _installDone = false;
  private _invalidArguments: boolean = false;

  get params(): ShopRenterModel.ShopRenterAuthRequestParam {
    return this._params;
  }

  get invalidArguments(): boolean {
    return this._invalidArguments;
  }

  get installing(): boolean {
    return this._installing;
  }

  get installDone(): boolean {
    return this._installDone;
  }

  get installFailed(): boolean {
    return this._installFailed;
  }

  constructor(
    private shopRenterService: ShopRenterService,
    private transition: Transition) {
  }

  ngOnInit(): void {
    const params = this.parseParams();
    this._params = params; // debug
    this.installShop(params);
  }

  private parseParams(): ShopRenterModel.ShopRenterAuthRequestParam {
    return ShopRenterModel.parseShopRenterAuthRequestParam(this.transition);
  }

  private installShop(params: ShopRenterModel.ShopRenterAuthRequestParam) {
    if (params.shopName && params.code && params.hmac && params.timestamp) {
      const installRequest: ShopRenter.InstallShopRequest = {
        referrer: document.referrer,
        shopName: params.shopName,
        code: params.code,
        hmac: params.hmac,
        timestamp: params.timestamp
      };
      this._installing = true;
      this._installDone = false;
      this._installFailed = false;
      this.shopRenterService.installShop(installRequest).subscribe(
        (result: ShopRenter.InstallShopResponse) => {
          this._installing = false;
          this._installDone = true;
          this.handleSuccess(result);
        },
        (error) => {
          this._installing = false;
          this._installFailed = true;
        }
      );
    }
    else {
      this._invalidArguments = true;
    }
  }

  private handleSuccess(result: ShopRenter.InstallShopResponse) {
    window.location.href = result.redirectUrl;
  }

}
