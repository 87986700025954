<div class="row form-group">
  <div class="col-md-4">
    <label class="search-label">{{'FORM_ICON_CODE_NAME' | translate}}</label>
  </div>
  <div class="col-md-4" style="text-align: left">
    <input *ngIf="!selectedIcon" type="button" (click)="showIconDialog()"
           class="btn btn-primary-green btn-sm align-middle"
           readonly="{{readonly}}"
           value="{{'SHOW_MATERIAL_ICON_DIALOG'|translate}}"/>
    <app-type-icon
      *ngIf="selectedIcon"
      selected="{{!readonly}}"
      big="true"
      [icon]="selectedIcon"
      (click)="showIconDialog()"></app-type-icon>
  </div>
</div>
