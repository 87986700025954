<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [self]="breadcrumbSelf"
      [parents]="breadcrumbParents">
    </app-breadcrumb>
  </div>
  <div class="breadcrumb-menu d-flex">
    <!--CREATE-->
    <div
      *ngIf="rightModel.deliveryMethodCreate.hasRight()"
      class="breadcrumb-button-icon-container cursor-pointer negative-margin-right"
      uiSref="Admin.DeliveryMethodCreate">
      <a class="btn-setting d-flex align-items-center" title="{{'COMMON_ADD' | translate}}">
        <i class="icomoon icomoon-add"></i>
        <span class="breadcrumb-button-text-visible">{{'COMMON_ADD' | translate}}</span>
      </a>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="card">

    <div class="card-header">
      {{'MENU_NAVBAR_DELIVERY_METHODS' | translate}}

      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()" title="{{'COMMON_BUTTON_SEARCH' | translate}}"><i
          class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>

    <div class="card-body border-bottom" *ngIf="showSearch">
      <div class="row form-group">

        <div class="col-md-4">
          <label class="search-label">{{'COMMON_NAME' | translate}}</label>
          <input type="text" class="form-control"
                 placeholder="{{'COMMON_NAME' | translate}}"
                 maxlength="{{UiConstants.maxInputLongLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.name">
        </div>

        <div class="col-md-4">
          <label class="search-label">{{'COMMON_EXTERNAL_ID' | translate}}</label>
          <input type="text" class="form-control"
                 placeholder="{{'COMMON_EXTERNAL_ID' | translate}}"
                 maxlength="{{UiConstants.maxInputLongLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.externalId">
        </div>

        <div class="col-md-4">
          <label class="search-label">{{'DELIVERY_METHOD_TYPE' | translate}}</label>
          <angular2-multiselect class="form-control"
                                [data]="types"
                                [(ngModel)]="searchModel._type"
                                [settings]="dropdownSettings">
          </angular2-multiselect>
        </div>
      </div>
      <div class="col-md-12 d-flex justify-content-md-end align-items-end">
        <div class="btn-group" role="group">
          <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
            {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
          </button>
          <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
            {{'COMMON_BUTTON_SEARCH' | translate}}
          </button>
        </div>
      </div>
    </div><!-- /.card-body-->
    <div class="card-body">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="DeliveryMethodSearch.OrderFunctions.ID"
                                    [orderType]="queryModel.getOrderType(DeliveryMethodSearch.OrderFunctions.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="DeliveryMethodSearch.OrderFunctions.NAME"
                                    [orderType]="queryModel.getOrderType(DeliveryMethodSearch.OrderFunctions.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-field-sorter [orderField]="DeliveryMethodSearch.OrderFunctions.EXTERNAL_ID"
                                    [orderType]="queryModel.getOrderType(DeliveryMethodSearch.OrderFunctions.EXTERNAL_ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_EXTERNAL_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op
              [text]="'DELIVERY_METHOD_TYPE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-field-sorter [orderField]="DeliveryMethodSearch.OrderFunctions.CREATION_TIME"
                                    [orderType]="queryModel.getOrderType(DeliveryMethodSearch.OrderFunctions.CREATION_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_CREATION_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let deliveryMethod of deliveryMethodList | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }" [disabledTableRow]="deliveryMethod.disabled">
          <td class="responsive-table-column">{{deliveryMethod.id}}</td>
          <td class="responsive-table-column">{{deliveryMethod.name}}</td>
          <td class="responsive-table-column hidden-sm-down">{{deliveryMethod.externalId}}</td>
          <td class="responsive-table-column hidden-sm-down">{{deliveryMethod.type | translate}}</td>
          <td
            class="responsive-table-column hidden-md-down">{{deliveryMethod.creationTime.toUtcIsoString() | date: 'short'}}</td>
          <td>
            <app-table-options-menu>
              <app-dropdown-item
                [iconClass]="'icomoon-detail'"
                [titleStringKey]="'COMMON_BUTTON_DETAILS'"
                class="cursor-pointer"
                uiSref="Admin.DeliveryMethodDetail"
                [uiParams]="{ id: deliveryMethod.id }">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="rightModel.deliveryMethodUpdate.hasRight()"
                [iconClass]="'icomoon-modify'"
                [titleStringKey]="'COMMON_BUTTON_EDIT'"
                class="cursor-pointer"
                uiSref="Admin.DeliveryMethodEdit"
                [uiParams]="{ id: deliveryMethod.id }">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="!deliveryMethod.disabled && rightModel.deliveryMethodDisable.hasRight()"
                [iconClass]="'icomoon-disable icomoon-disable-table'"
                [titleStringKey]="'COMMON_BUTTON_DISABLE'"
                class="cursor-pointer"
                href="#" onclick="return false"
                (click)="setDisabled($event, deliveryMethod.id, true)">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="deliveryMethod.disabled && rightModel.deliveryMethodDisable.hasRight()"
                [iconClass]="'icomoon-enable icomoon-enable-table'"
                [titleStringKey]="'COMMON_BUTTON_ENABLE'"
                class="cursor-pointer"
                href="#" onclick="return false"
                (click)="setDisabled($event, deliveryMethod.id, false)">
              </app-dropdown-item>
            </app-table-options-menu>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="DeliveryMethodSearch.OrderFunctions.ID"
                                    [orderType]="queryModel.getOrderType(DeliveryMethodSearch.OrderFunctions.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="DeliveryMethodSearch.OrderFunctions.NAME"
                                    [orderType]="queryModel.getOrderType(DeliveryMethodSearch.OrderFunctions.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-field-sorter [orderField]="DeliveryMethodSearch.OrderFunctions.EXTERNAL_ID"
                                    [orderType]="queryModel.getOrderType(DeliveryMethodSearch.OrderFunctions.EXTERNAL_ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_EXTERNAL_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op
              [text]="'DELIVERY_METHOD_TYPE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-field-sorter [orderField]="DeliveryMethodSearch.OrderFunctions.CREATION_TIME"
                                    [orderType]="queryModel.getOrderType(DeliveryMethodSearch.OrderFunctions.CREATION_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_CREATION_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>
  </div>
</div>
