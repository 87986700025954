/* eslint-disable */
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { UiConstants } from '../util/core-utils';
import { Order, OrderType } from './util/services';
import { SearchUtils } from '../util/search-utils';
import { BaseSearch, BaseSearchService } from './base.search-service';
import { MultiselectOptionItem } from '../util/core-utils';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { StockRecordLogSource } from './stock/stock-record-log.service';
import { StockLogListType } from '../admin/stock-log/stock-log-list/stock-log-list.component';
import { RuntimeConfiguration } from './runtime-configuration';
import { StockRecordLog } from './stock/stock-record-log.service';
import { LoggedInUserStorage } from './util/storages';
import { Set } from 'immutable';
/* eslint-enable */

@Injectable()
export class StockLogSearchService extends BaseSearchService {

  protected createStorage(): StockLogSearch.Storage {
    return new StockLogSearch.Storage();
  }

}

export namespace StockLogSearch {

  export class Model extends SearchUtils.SearchModel {
    stock: MultiselectOptionItem<number>[] = [];
    editor: MultiselectOptionItem<number>[] = [];
    taskRecord: MultiselectOptionItem<number>[] = [];
    stockItem: MultiselectOptionItem<number>[] = [];
    creationTimeFrom: NgbDateStruct | null = null;
    creationTimeTo: NgbDateStruct | null = null;
    source: MultiselectOptionItem<StockRecordLogSource>[] = [];

    public isEmpty(): boolean {
      return this.stock.length === 0
        && this.editor.length === 0
        && this.taskRecord.length === 0
        && this.stockItem.length === 0
        && this.creationTimeFrom === null
        && this.creationTimeTo === null
        && this.source.length === 0
        ;
    }

    public reset() {
      this.stock = [];
      this.editor = [];
      this.taskRecord = [];
      this.stockItem = [];
      this.creationTimeFrom = null;
      this.creationTimeTo = null;
      this.source = [];
    }

    get taskRecordIds(): Set<number> {
      return Set.of(...this.taskRecord.map(t => t.id));
    }
  }

  export interface SearchDataGetRequest extends BaseSearch.SearchDataGetRequest {
    type: StockLogListType;
    resourceId?: number;
  }

  export interface SearchDataResult {
    searchData: SearchData;
  }

  export interface SearchDataSetRequest extends BaseSearch.SearchDataSetRequest {
    type: StockLogListType;
    resourceId?: number;
  }

  export interface SearchData extends BaseSearch.SearchData {
    order: Order<StockRecordLog.OrderField>;
    stock: MultiselectOptionItem<number>[];
    editor: MultiselectOptionItem<number>[];
    taskRecord: MultiselectOptionItem<number>[];
    stockItem: MultiselectOptionItem<number>[];
    creationTimeFrom: NgbDateStruct | null;
    creationTimeTo: NgbDateStruct | null;
    source: MultiselectOptionItem<StockRecordLogSource>[];
  }

  interface StoredSearchRawData extends BaseSearch.StoredSearchRawData {
    order: Order<StockRecordLog.OrderField>;
    stock: MultiselectOptionItem<number>[];
    editor: MultiselectOptionItem<number>[];
    taskRecord: MultiselectOptionItem<number>[];
    stockItem: MultiselectOptionItem<number>[];
    creationTimeFrom: NgbDateStruct | null;
    creationTimeTo: NgbDateStruct | null;
    source: MultiselectOptionItem<StockRecordLogSource>[];
  }

  export class Storage extends BaseSearch.Storage {

    constructor() {
      const defaultSearchData = {
        itemsPerPage: UiConstants.pageNumbers[0],
        pageNumber: 1,
        order: {
          field: StockRecordLog.OrderField.ID,
          type: OrderType.DESC
        },
        stock: [],
        editor: [],
        taskRecord: [],
        stockItem: [],
        creationTimeFrom: null,
        creationTimeTo: null,
        source: []
      };
      /**
       * Increment this version if you change the SearchData interface.
       */
      const versionNumber: number = 2;
      super(defaultSearchData,
        versionNumber);
    }

    protected generateKey(request: SearchDataGetRequest | SearchDataSetRequest): string {
      return request.resourceId
        ? 'UserId(' + LoggedInUserStorage.getInstance().getUserId()
            + ')-Search-StockRecordLog-ListType(' + request.type + ')-ResourceId(' + request.resourceId + ')'
        : 'UserId(' + LoggedInUserStorage.getInstance().getUserId() + ')-Search-StockRecordLog-ListType(' + request.type + ')';
    }

    protected fromRaw(data: StoredSearchRawData): BaseSearch.SearchDataResult {
      const searchData: SearchData = {
        itemsPerPage: data.itemsPerPage,
        pageNumber: data.pageNumber,
        order: data.order,
        stock: data.stock,
        editor: data.editor,
        taskRecord: data.taskRecord,
        stockItem: data.stockItem,
        creationTimeFrom: data.creationTimeFrom,
        creationTimeTo: data.creationTimeTo,
        source: data.source
      };
      return {
        searchData: searchData
      };
    }

    protected toRaw(data: SearchData): StoredSearchRawData {
      return {
        version: this.VERSION,
        itemsPerPage: data.itemsPerPage,
        pageNumber: data.pageNumber,
        order: data.order,
        stock: data.stock,
        editor: data.editor,
        taskRecord: data.taskRecord,
        stockItem: data.stockItem,
        creationTimeFrom: data.creationTimeFrom,
        creationTimeTo: data.creationTimeTo,
        source: data.source
      };
    }

  }

}
