import { map } from 'rxjs/operators';
import { Multiselect } from './multiselect-helper';
import { ImmutableUserItem, UiConstants } from './core-utils';
import { OrderType, Services } from '../lib/util/services';
import { List, Set } from 'immutable';
import { User, UserService } from '../lib/user.service';
import { TranslateService } from '@ngx-translate/core';
import { StringKey } from '../app.string-keys';

export namespace MultiselectUser {

  export const ImmutableUserItemMapper = (user: User) => ImmutableUserItem.fromUserData(user);

  export function createUnknownImmutableUserItemFactory(translateService: TranslateService):
    Multiselect.UnknownItemFactory<ImmutableUserItem> {
    return new UnknownImmutableUserItemFactory(translateService);
  }

  class UnknownImmutableUserItemFactory implements Multiselect.UnknownItemFactory<ImmutableUserItem> {

    constructor(private readonly translateService: TranslateService) {
    }

    createUnknownItem(id: number): ImmutableUserItem {
      const placeholder = this.translateService.get(StringKey.MULTISELECT_PLACEHOLDER_UNKNOWN_ITEM, {
        'id': id
      });
      return ImmutableUserItem.unknown(id, placeholder);
    }

  }

  export function topActiveItem$<T extends Multiselect.Item>(
    service: UserService, mapper: (user: User) => T): Multiselect.SearchFunction<T> {
    return (searchValue?: string) => service.query({
      q: searchValue,
      order: Services.createOrderFieldParameter(User.Keys.toOrderFieldKey,
        Set.of({field: User.OrderField.PERSON_NAME, type: OrderType.ASC})),
      page_number: 1,
      number_of_items: UiConstants.autocompletePageSize,
      disabled: false,
      no_progress_bar: true
    }).pipe(map(result => List.of(...result.items.map(mapper))));
  }

  export function searchItem$<T extends Multiselect.Item>(
    service: UserService, mapper: (user: User) => T): Multiselect.IdSetFunction<T> {
    return (ownerUserIds?: Set<number>) => service.query({
      id: ownerUserIds === undefined ? undefined : ownerUserIds.join(',')
    }).pipe(map(result => List.of(...result.items.map(mapper))));
  }

}
