/* eslint-disable */
import { Component, ComponentFactoryResolver, EventEmitter, OnInit, Output, Type, ViewChild } from '@angular/core';
import {
  AvailableDqlOperations,
  AvailableUnaryDqlOperations,
  DqlBinaryCriteriaModel,
  DqlCriteriaModel, DqlExpressionBuilder,
  DqlFieldModel,
  DqlOperationModel,
  DqlUnaryCriteriaModel, SimpleDqlExpressionBuilder
} from '../dql-search-container/dql-search-container.model';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DqlDialogValueHolderDirective } from './dql-dialog-value-holder.directive';
import { DqlSearchFieldTypes } from '../field/dql-search-field.model';
import { DqlSearchFieldComponent } from '../field/dql-search-field.component';
import { DqlSearchFieldUnaryComponent } from '../field/dql-search-field-unary/dql-search-field-unary.component';
import { UiConstants } from '../../../util/core-utils';
import * as $ from '../../../../../node_modules/jquery/dist/jquery.js';
/* eslint-enable */

@Component({
  selector: 'app-dql-create-dialog',
  templateUrl: './dql-create-dialog.component.html',
  styleUrls: ['./dql-create-dialog.component.scss']
})
export class DqlCreateDialogComponent implements OnInit {
  UiConstants = UiConstants;

  nextSearch: DqlCriteriaModel;

  newSearchFieldDialogVisible: boolean = false;

  @ViewChild('newSearchFieldDialog', { static: true }) newSearchFieldDialog: ModalDirective;

  @ViewChild(DqlDialogValueHolderDirective) valueHolder: DqlDialogValueHolderDirective;

  @Output()
  onCreateExpression: EventEmitter<DqlExpressionBuilder> = new EventEmitter<DqlExpressionBuilder>();
  fields: DqlFieldModel[];
  binaryOperations: DqlOperationModel[] = [];
  unaryOperations: DqlOperationModel[] = [];
  private searchFieldComponent?: DqlSearchFieldComponent;

  _isBinary: boolean = true;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {
  }

  ngOnInit() {
  }

  set isBinary(b: boolean) {
    const field = this.nextSearch.field;
    if (b && !this._isBinary) {
      this._isBinary = true;
      this.nextSearch = new DqlBinaryCriteriaModel();
      this.nextSearch.field = field;
      if (field) {
        this.nextSearch.operation = this.binaryOperations[0];
        this.searchFieldComponent = this.createSearchField();
      }
    }
    if (!b && this._isBinary) {
      this._isBinary = false;
      this.nextSearch = new DqlUnaryCriteriaModel();
      this.nextSearch.field = field;
      if (field) {
        this.nextSearch.operation = this.unaryOperations[0];
        this.searchFieldComponent = this.createSearchField();
      }
    }
  }

  get isBinary(): boolean {
    return this._isBinary;
  }

  onModalHide(event: ModalDirective) {
    if (event.dismissReason === 'backdrop-click' || event.dismissReason === 'esc') {
      this.closeNewSearchFieldDialog();
    }
  }

  closeNewSearchFieldDialog() {
    this._isBinary = true;
    this.nextSearch = new DqlBinaryCriteriaModel();
    this.binaryOperations = [];
    this.unaryOperations = [];
    this.valueHolder.viewContainerRef.clear();
    this.searchFieldComponent = undefined;
    this.newSearchFieldDialogVisible = false;
    this.newSearchFieldDialog.hide();
  }

  openNewSearchFieldDialog() {
    this.newSearchFieldDialogVisible = true;
    this.newSearchFieldDialog.show();
    $('#dqlNewSearchDialog').appendTo($('body'));
    this.nextSearch = new DqlBinaryCriteriaModel();
  }

  createExpression() {
    if (this.searchFieldComponent) {
      this.onCreateExpression.emit(this.searchFieldComponent.expression);
    }
    this.closeNewSearchFieldDialog();
  }

  setFields(fields: DqlFieldModel[]) {
    this.fields = fields;
  }

  nextFieldChange(field: DqlFieldModel) {
    this.isBinary = true;
    this.nextSearch.operation = undefined;
    this.binaryOperations = [];
    this.unaryOperations = [];
    this.binaryOperations = AvailableDqlOperations.get(field.dataType).toArray();
    this.unaryOperations = AvailableUnaryDqlOperations.toArray();
    this.nextSearch.operation = this.binaryOperations[0];
    this.searchFieldComponent = this.createSearchField();
  }

  private createSearchField(): DqlSearchFieldComponent {
    const expr = new SimpleDqlExpressionBuilder();
    expr.addCriteria(this.nextSearch);
    expr.closeExpression();
    if (this.isBinary) {
      const fieldType: Type<DqlSearchFieldComponent> = DqlSearchFieldTypes.get(this.nextSearch.field!.dataType);
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(fieldType);
      this.valueHolder.viewContainerRef.clear();
      const componentRef = this.valueHolder.viewContainerRef.createComponent(componentFactory);
      componentRef.instance.expression = expr;
      componentRef.instance.disableDelete = true;
      return componentRef.instance;
    }
    else {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(DqlSearchFieldUnaryComponent);
      this.valueHolder.viewContainerRef.clear();
      const componentRef = this.valueHolder.viewContainerRef.createComponent(componentFactory);
      componentRef.instance.expression = expr;
      componentRef.instance.disableDelete = true;
      return componentRef.instance;
    }
  }
}
