/* eslint-disable */
import { Component, OnDestroy, } from '@angular/core';
import {
  FormRecordFieldContext,
  FormRecordFieldValueUpdateArgs,
  FormRecordFieldView,
  FormRecordFieldViewContext,
  FormRecordFieldViewModel,
  SetTmpReadonlyArgs,
  SetTmpReadonlyResult
} from '../../../../../util/form/form-utils';
import { Form } from '../../../../../lib/form/form.service';
import { Command } from '../../../../../util/command';
import { FormRecord } from '../../../../../lib/form/form-record.service';
import Decimal from 'decimal.js';
import { InputMask } from '../../../../../util/input-masks';
import { FormRef, QueryResult } from '../../../../../lib/util/services';
import { FieldActivationState, FieldActivationStateResolver } from '../../../../../util/form/form-editors';
import { List, Map as ImmutableMap } from 'immutable';
import { OptionItem, SelectUtils, UiConstants } from '../../../../../util/core-utils';
import { Models } from '../../../../../util/model-utils';
import { TranslateService } from '@ngx-translate/core';
import { StringKey } from '../../../../../app.string-keys';
import { ToasterService } from '../../../../../fork/angular2-toaster/angular2-toaster';
import { FormRecordInactivityManager } from '../../manager/form-record-inactivity-manager';
import { INVOICE_VALID_CURRENCY_CODE } from '../../../../invoicing/invoices/invoice-create/invoice-create-clone.model';
import { VatRate, VatRateService } from '../../../../../lib/vat-rate.service';
import { MatConfirmDialogComponent, MatConfirmDialogData } from '../../../../../shared/mat-confirm-dialog/mat-confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import {
  FormRecordInvoiceFieldCopyDialogComponent,
  FormRecordInvoiceFieldCopyDialogData,
  InvoiceFormField
} from './form-record-invoice-field-copy-dialog/form-record-invoice-field-copy-dialog.component';
import { FieldNotification, FormRecordFieldNotifierService } from '../../../../../lib/form/form-record-field-notifier.service';
import { Observable, Subscription } from 'rxjs';
import ZeroVatRateType = VatRate.ZeroVatRateType;

/* eslint-enable */

@Component({
  selector: 'app-form-record-invoice-field',
  templateUrl: 'form-record-invoice-field.component.html',
  styleUrls: ['form-record-invoice-field.component.scss'],
})
export class FormRecordInvoiceFieldComponent implements FormRecordFieldView, OnDestroy {

  public readonly selector: Form.FieldDataTypeSelector.INVOICE;

  SelectUtils = SelectUtils;

  formRecordFieldContext?: FormRecordFieldContext;
  formRecordInactivityManager?: FormRecordInactivityManager;

  model: Model = new Model();
  addModel?: InvoiceItemModel;
  availableDiscounts: number[] = [];
  availableDiscountsForDropdown: DropdownItem[] = [];
  readonlyDiscount?: string;
  prices: { net: string, gross: string, currencyCode: string, vatSums: ImmutableMap<string, string> } = {
    net: '0',
    gross: '0',
    currencyCode: INVOICE_VALID_CURRENCY_CODE,
    vatSums: ImmutableMap.of(),
  };
  sameCurrency: boolean = true;
  InputMask = InputMask;

  selectableVatRates: VatRate.VatRate[] = [];
  selectableZeroVatRateTypes: OptionItem<VatRate.ZeroVatRateType>[] = [];

  private fieldId?: number;
  private htmlForm?: FormRef;
  private fieldNotifSubscription: Subscription;

  tmpReadonly: boolean = false;

  private readonlyFormFn: () => boolean = () => true;
  private readonlyFieldFn: () => boolean = () => false;
  private hiddenFieldFn: () => boolean = () => false;

  get nonEditable(): boolean {
    return FieldActivationStateResolver.isNonEditable(
      this.fieldActivationState
    );
  }

  get requiredDisabled(): boolean {
    return FieldActivationStateResolver.isRequiredDisabled(
      this.fieldActivationState
    );
  }

  private get readonlyForm(): boolean {
    return this.readonlyFormFn();
  }

  private get fieldActivationState(): FieldActivationState {
    return FieldActivationStateResolver.resolveFieldActivationState({
      readonlyFormFn: () => this.readonlyFormFn(),
      readonlyFieldFn: () => this.readonlyFieldFn(),
      tmpReadonlyFieldFn: () => this.tmpReadonly,
    });
  }

  private get reqContext() {
    return this.formRecordFieldContext!;
  }

  setTmpReadonly(args: SetTmpReadonlyArgs): Command<SetTmpReadonlyResult> {
    const previousTmpReadonly = this.tmpReadonly;
    const previousValue = this.model;
    return {
      execute: async () => {
        let changed = false;
        if (this.tmpReadonly !== args.tmpReadonly) {
          if (args.tmpReadonly) {
            changed = FieldActivationStateResolver.inactivationChangesTheValue({
              debugId: this.reqContext.field.title,
              valueIsEmpty: !this.model.items || this.model.items.length === 0,
              valueEqualsDefaultValue: !this.model.items || this.model.items.length === 0,
              defaultValueIsEmpty: true,
              canApplyDefaultValue: false
            });
            this.model = new Model();
            this.tmpReadonly = args.tmpReadonly; // last
          }
          else {
            this.tmpReadonly = args.tmpReadonly; // first
            this.model = new Model();
          }
        }
        return {
          changed: changed
        };
      },
      undo: async () => {
        this.tmpReadonly = previousTmpReadonly;
        this.model = previousValue;
      }
    };
  }

  registerField(context: FormRecordFieldContext, originalModel?: any): any {
    this.loadVatRates();
    this.loadZeroVatRateTypes();
    if (originalModel) {
      this.model = originalModel;
    }
    this.formRecordFieldContext = context;
    this.fieldId = context.field.fieldId;
    this.htmlForm = context.htmlForm;
    this.readonlyFormFn = context.readonly;
    this.hiddenFieldFn = () => Form.FormFieldValidationType.HIDDEN === context.validationType;
    this.readonlyFieldFn = () => Form.FormFieldValidationType.READONLY === context.validationType
      || Form.FormFieldValidationType.HIDDEN === context.validationType;
    if (context.field) {
      const invoiceAttributes = context.field.dataType.invoiceAttributes;
      this.loadDiscounts(invoiceAttributes);
    }
    if (context.fieldRecord) {
      this.registerFieldData(context.fieldRecord);
    }
    else {
      this.model.discountPercent = this.availableDiscounts.length > 0 ? this.availableDiscounts[0] : null;
    }
    return this.model;
  }

  registerFieldData(fieldRecord: FormRecord.FieldComposed): void {
    const attrs = fieldRecord.data.invoiceAttributes!;

    this.model.discountPercent = attrs.discountPercent ? Models.decimalToNumber(attrs.discountPercent)! :
      this.availableDiscounts.length > 0 ? this.availableDiscounts[0] : null;
    this.loadItems(attrs);
  }

  loadItems(attrs: FormRecord.FieldDataInvoiceAttributes) {
    const items: InvoiceItemModel[] = [];
    attrs.items.forEach((item: FormRecord.InvoiceItem) => {
      const newItem: InvoiceItemModel = new InvoiceItemModel();
      newItem.id = item.id;
      newItem.invoiced = item.invoiced;
      newItem.amount = Models.decimalToString(item.amount);
      newItem.comment = item.comment ? item.comment : '';
      newItem.currencyCode = item.currencyCode;
      newItem.externalId = item.externalId ? item.externalId : '';
      newItem.category = item.category ? item.category : '';
      newItem.hunVtszNumber = item.hunVtszNumber ? item.hunVtszNumber : '';
      newItem.netUnitPrice = Models.decimalToString(item.netUnitPrice);
      newItem.recordName = item.recordName;
      newItem.unitType = item.unitType;
      newItem.vatRate = item.vatRate.toNumber();
      newItem.zeroVatRateType = item.zeroVatRateType ? item.zeroVatRateType : VatRateService.DEFAULT_ZERO_VAT_RATE_TYPE;
      items.push(newItem);
    });
    if (items.length > 0) {
      this.model.items = items;
    }
    if (this.nonEditable) {
      if (this.model.discountPercent === null) {
        this.readonlyDiscount = this.translateService.instant(StringKey.FORM_RECORD_STOCK_DISCOUNT_NONE);
      }
      else {
        this.readonlyDiscount = this.model.discountPercent + '%';
      }
    }
    this.refreshPrices();
  }

  private loadDiscounts(attrs?: Form.FieldDataTypeInvoiceAttributes) {
    this.availableDiscountsForDropdown = [];
    if (attrs && attrs.availableDiscounts) {
      this.availableDiscounts = attrs.availableDiscounts;
    }
    this.availableDiscounts.forEach((d) => {
      this.availableDiscountsForDropdown.push({
        id: d,
        text: d + '%'
      });
    });
  }

  private loadVatRates() {
    this.vatRateService.query().subscribe((result: QueryResult<VatRate.VatRate>) => {
      this.selectableVatRates = result.items.toArray();
    });
  }

  private loadZeroVatRateTypes() {
    this.selectableZeroVatRateTypes = [];
    VatRate.zeroVatRateTypes.forEach(type => {
      const item = new OptionItem<ZeroVatRateType>();
      item.id = type.type;
      item.disabled = type.disabled;
      this.translateService.get(type.stringKey).subscribe((text) => {
        item.text = text;
      });
      this.selectableZeroVatRateTypes.push(item);
    });
  }

  refreshPrices() {
    let sumNet: Decimal = new Decimal('0');
    let sumGross: Decimal = new Decimal('0');
    this.model.items!.forEach(r => {
      const rawNet = r.rawNetPrice;
      const rawGross = r.rawGrossPrice;
      if (rawNet) {
        sumNet = sumNet.add(rawNet.toString());
      }
      if (rawGross) {
        sumGross = sumGross.add(rawGross.toString());
      }
    });
    if (this.model.discountPercent) {
      sumNet = sumNet.mul((100 - this.model.discountPercent) / 100);
      sumGross = sumGross.mul((100 - this.model.discountPercent) / 100);
    }
    this.prices.net = Models.decimalToFormattedString(sumNet);
    this.prices.gross = Models.decimalToFormattedString(sumGross);
  }

  toggleEdit(index: number) {
    this.model.items![index].editing = !this.model.items![index].editing;
  }

  newItem() {
    this.addModel = new InvoiceItemModel();
  }

  cancelNewItem() {
    this.addModel = undefined;
  }

  closeRow(index: number) {
    this.model.items![index].editing = false;
  }

  saveItem(index: number, model: InvoiceItemModel) {
    this.model.items![index] = model;
    this.closeRow(index);
    this.refreshPrices();
  }

  saveNewItem(model: InvoiceItemModel) {
    model.editing = false;
    this.model.items!.push(model);
    this.addModel = undefined;
    this.refreshPrices();
  }

  removeItem(index: number) {
    this.closeRow(index);
    this.model.items!.splice(index, 1);
    this.refreshPrices();
  }

  registerFieldViews(context: FormRecordFieldViewContext): void {
    this.formRecordInactivityManager = context.inactivityManager;
  }

  validateWithInterrupt(): boolean {
    return false;
  }

  shouldNotifyAfterCreation(): boolean {
    return false;
  }

  afterFormRecordCreation(formRecordId: number): Observable<any> | undefined {
    return undefined;
  }

  createModel(): FormRecordFieldViewModel {
    if (this.fieldId === undefined) {
      throw new Error('Field ID is undefined');
    }
    const attrs: FormRecord.FieldDataInvoiceAttributes = {
      discountPercent: this.model.discountPercent !== null ? new Decimal(this.model.discountPercent!) : undefined,
      items: List.of(...this.model.items!.map((i): FormRecord.InvoiceItem => {
        const vatRate = new Decimal(i.vatRate)!;
        return {
          id: i.id,
          amount: Models.parseDecimal(i.amount)!,
          comment: i.comment,
          currencyCode: i.currencyCode,
          externalId: i.externalId,
          category: i.category,
          hunVtszNumber: i.hunVtszNumber,
          netUnitPrice: Models.parseDecimal(i.netUnitPrice)!,
          recordName: i.recordName,
          unitType: i.unitType,
          vatRate: vatRate,
          zeroVatRateType: vatRate.equals(0) ? i.zeroVatRateType : undefined
        };
      }))
    };
    return {
      fieldEditRequest: {
        fieldId: this.fieldId,
        data: {
          invoiceAttributes: attrs
        }
      }
    };
  }

  getTitle(): string {
    if (this.formRecordFieldContext && this.formRecordFieldContext.field) {
      return this.formRecordFieldContext.field.title;
    }
    else {
      this.translateService.get('FORM_RECORD_INVOICE_TITLE').subscribe(
        (result: string) => {
          return result;
        }
      );
    }
    return '';
  }

  hasLocalFieldError(): boolean {
    return false;
  }

  updateValue(data: FormRecordFieldValueUpdateArgs) {
  }

  mergeData(itemList: InvoiceItemModel[]) {
    const map: Map<string, InvoiceItemModel[]> = new Map<string, InvoiceItemModel[]>();
    itemList.forEach(i => {
      const key = new MergeMapKey(i.category, i.vatRate, i.zeroVatRateType).toStringKey();
      if (!map.has(key)) {
        map.set(key, []);
      }
      const items = map.get(key)!;
      items.push(i);
      map.set(key, items);
    });

    const reduced: InvoiceItemModel[] = [];

    map.forEach(((value, key) => {
      const mapKey = MergeMapKey.fromStringKey(key);
      if (mapKey.category === undefined || mapKey.category.length === 0) {
        reduced.push(...value);
      }
      else {
        if (value.length === 1) {
          const item = value[0];
          item.recordName = item.category;
          item.netUnitPrice = item.netPrice;
          item.amount = '1';
          reduced.push(item);
        }
        else {
          reduced.push(value.reduce((a, c) => {
            a.recordName = a.category;
            a.netUnitPrice = Models.decimalToFormattedString(a.rawNetPrice!.add(c.rawNetPrice!));
            a.amount = '1';
            return a;
          }));
        }
      }
    }));
    this.model.items = reduced;
  }

  openMergeDialog() {
    const dialogConfig: MatConfirmDialogData = {
      titleKey: 'FORM_RECORD_INVOICE_MERGE_TITLE',
      messageKey: 'FORM_RECORD_INVOICE_MERGE_MESSAGE',
    };
    const dialogRef = this.dialog.open(MatConfirmDialogComponent, {
      data: dialogConfig
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.mergeData(this.model.items);
      }
    });
  }

  openCopyDialog() {
    const dialogConfig: FormRecordInvoiceFieldCopyDialogData = {
      selectableFields: this.getOtherInvoiceFields()
    };
    if (dialogConfig.selectableFields.length === 0) {
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutShort,
        type: UiConstants.toastTypeSuccess,
        title: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_TITLE),
        body: this.translateService.instant(StringKey.FORM_RECORD_INVOICE_FIELD_COPY_NO_OTHER)
      });
    }
    const dialogRef = this.dialog.open(FormRecordInvoiceFieldCopyDialogComponent, {
      data: dialogConfig,
      width: '60%'
    });

    dialogRef.afterClosed().subscribe((result: InvoiceFormField) => {
      if (result) {
        this.copyDataTo(result);
      }
    });
  }

  private copyDataTo(result: InvoiceFormField) {
    this.fieldNotifierService.notify({
      groupId: result.groupId,
      fieldId: result.id!,
      data: this.model.items
    });
  }

  private getOtherInvoiceFields(): InvoiceFormField[] {
    return this.formRecordFieldContext!.form.groups.filter(g => !g!.disabled).flatMap(g => {
      return g!.fields.filter(f => !f!.disabled && f!.fieldId !== this.formRecordFieldContext!.field.fieldId &&
        f!.dataTypeSelector === Form.FieldDataTypeSelector.INVOICE).map(f => {
        const item = new InvoiceFormField();
        item.groupId = g!.groupId;
        item.id = f!.fieldId;
        item.text = f!.title;
        return item;
      });
    }).toArray();
  }

  get mergeVisible(): boolean {
    return this.formRecordFieldContext ? this.formRecordFieldContext.field.dataType.invoiceAttributes!.mergeData : false;
  }

  get mergeDisabled(): boolean {
    return this.addModel !== undefined;
  }

  get copyVisible(): boolean {
    return this.formRecordFieldContext ? this.formRecordFieldContext.field.dataType.invoiceAttributes!.copyData : false;
  }

  get copyDisabled(): boolean {
    return this.addModel !== undefined;
  }

  onFieldNotification(fieldNotification: FieldNotification) {
    if (!this.nonEditable && fieldNotification.groupId === this.formRecordFieldContext!.group.groupId
      && fieldNotification.fieldId === this.formRecordFieldContext!.field.fieldId && (fieldNotification.data instanceof Array)) {
      this.model.items = [];
      fieldNotification.data.forEach(item => {
        const newItem: InvoiceItemModel = new InvoiceItemModel();
        newItem.amount = item.amount;
        newItem.comment = item.comment;
        newItem.currencyCode = item.currencyCode;
        newItem.externalId = item.externalId;
        newItem.category = item.category;
        newItem.hunVtszNumber = item.hunVtszNumber;
        newItem.netUnitPrice = item.netUnitPrice;
        newItem.recordName = item.recordName;
        newItem.unitType = item.unitType;
        newItem.vatRate = item.vatRate;
        newItem.zeroVatRateType = item.zeroVatRateType;
        this.model.items.push(newItem);
      });
      this.refreshPrices();
    }
  }

  constructor(private toasterService: ToasterService,
              private translateService: TranslateService,
              private vatRateService: VatRateService,
              private dialog: MatDialog,
              private fieldNotifierService: FormRecordFieldNotifierService) {
    this.fieldNotifSubscription = fieldNotifierService.subscribe(n => this.onFieldNotification(n));
  }

  ngOnDestroy(): void {
    this.fieldNotifierService.unsubscribe(this.fieldNotifSubscription);
  }
}

class MergeMapKey {

  static fromStringKey(key: string): MergeMapKey {
    const parts: string[] = key.split(';');
    return new MergeMapKey(parts[0], Number(parts[1]), parts.length < 3 ? undefined : parts[2]);
  }

  constructor(public readonly category: string,
              public readonly vatRate: number,
              public readonly zeroVatRateType?: string) {
  }

  public toStringKey(): string {
    return `${this.category};${this.vatRate};${this.zeroVatRateType ? this.zeroVatRateType : ''}`;
  }
}

export class Model {
  items: InvoiceItemModel[] = [];
  discountPercent: number | null = null;
}

export class InvoiceItemModel {
  id: number;
  amount: string = '';
  comment: string = '';
  currencyCode: string = INVOICE_VALID_CURRENCY_CODE;
  externalId: string = '';
  category: string = '';
  hunVtszNumber: string = '';
  netUnitPrice: string = '';
  recordName: string = '';
  unitType: string = '';
  vatRate: number = VatRateService.DEFAULT_VAT_RATE;
  zeroVatRateType: string = VatRateService.DEFAULT_ZERO_VAT_RATE_TYPE;
  editing: boolean = false;
  invoiced?: boolean;

  get rawNetPrice(): Decimal | undefined {
    if (this.amount.length > 0 && this.netUnitPrice.length > 0) {
      const netUnitPrice = this.trimDecimal(this.netUnitPrice);
      return netUnitPrice.mul(this.trimDecimal(this.amount));
    }
    return undefined;
  }

  get rawAmount(): Decimal | undefined {
    if (this.amount.length > 0) {
      return this.trimDecimal(this.amount);
    }
    return undefined;
  }

  get rawNetUnitPrice(): Decimal | undefined {
    if (this.netUnitPrice.length > 0) {
      return this.trimDecimal(this.netUnitPrice);
    }
    return undefined;
  }

  get netPrice(): string {
    return Models.decimalToFormattedString(this.rawNetPrice);
  }

  get rawGrossPrice(): Decimal | undefined {
    if (this.amount.length > 0 && this.netUnitPrice.length > 0) {
      const netUnitPrice = this.trimDecimal(this.netUnitPrice);
      return netUnitPrice.mul(this.vatRate).div(100).add(netUnitPrice).mul(this.trimDecimal(this.amount));
    }
    return undefined;
  }

  get grossPrice(): string {
    return Models.decimalToFormattedString(this.rawGrossPrice);
  }

  private trimDecimal(trimmable: string): Decimal {
    return Models.parseDecimal(trimmable)!;
  }
}

interface DropdownItem extends OptionItem<number> {
}
