/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { Order, OrderType, Services } from '../util/services';
import { Dates, LocalDate } from '../util/dates';
import { UiConstants } from '../../util/core-utils';
import { MemStorage } from '../util/mem-storage';
import { Transport } from './transport.service';
import { Icon } from '../task/icon.service';
import { LoggedInUserStorage } from '../util/storages';

/* eslint-enable */

@Injectable()
export class TransportSearchService {

  constructor() {

  }

  getSearchData(request: TransportSearch.SearchDataGetRequest): Observable<TransportSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<TransportSearch.SearchDataResult>) => {
      try {
        observer.next(storage.read(request));
      } catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  setSearchData(request: TransportSearch.SearchDataSetRequest): Observable<TransportSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<TransportSearch.SearchDataResult>) => {
      try {
        observer.next(storage.save(request));
      } catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  resetSearchData(request: TransportSearch.SearchDataGetRequest): Observable<TransportSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<TransportSearch.SearchDataResult>) => {
      try {
        observer.next(storage.reset(request));
      } catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  private createStorage(): TransportSearch.Storage {
    return new TransportSearch.Storage();
  }


}

export namespace TransportSearch {

  export interface SearchDataGetRequest {
  }

  export interface SearchDataSetRequest {
    searchData: SearchData;
  }

  export interface SearchDataResult {
    searchData: SearchData;
  }

  export interface SearchData {
    itemsPerPage: number;
    pageNumber: number;
    order: Order<Transport.OrderField>;
    id: string;
    waybillNumber: string;
    vehicleIds: VehicleItem[];
    driverIds: DriverItem[];
    safetyShipping?: boolean;
    stateIds: StateItem[];
    closeTimeFrom?: LocalDate;
    closeTimeTo?: LocalDate;
    creationTimeFrom?: LocalDate;
    creationTimeTo?: LocalDate;
    openTimeFrom?: LocalDate;
    openTimeTo?: LocalDate;
    shippingItem: string;
    dqlText?: string;
  }

  interface StoredSearchRawDataBase {
    version: number;
  }

  interface StoredSearchRawData extends StoredSearchRawDataBase {
    itemsPerPage: number;
    pageNumber: number;
    order: Order<Transport.OrderField>;
    id: string;
    waybillNumber: string;
    vehicleIds: VehicleItem[];
    driverIds: DriverItem[];
    safetyShipping?: boolean;
    stateIds: StateItem[];
    closeTimeFrom?: string;
    closeTimeTo?: string;
    creationTimeFrom?: string;
    creationTimeTo?: string;
    openTimeFrom?: string;
    openTimeTo?: string;
    shippingItem: string;
    dqlText?: string;
  }

  export class Storage {

    /**
     * Increment this version if you change the SearchData interface.
     */
    private static readonly VERSION: number = 6;

    private static readonly DEFAULT_SEARCH_DATA: SearchData = {
      itemsPerPage: UiConstants.pageNumbers[0],
      pageNumber: 1,
      order: {
        field: Transport.OrderField.ID,
        type: OrderType.DESC
      },
      id: '',
      waybillNumber: '',
      vehicleIds: [],
      driverIds: [],
      safetyShipping: false,
      stateIds: [],
      closeTimeFrom: Dates.emptyLocalDate(),
      closeTimeTo: Dates.emptyLocalDate(),
      creationTimeFrom: Dates.emptyLocalDate(),
      creationTimeTo: Dates.emptyLocalDate(),
      openTimeFrom: Dates.emptyLocalDate(),
      openTimeTo: Dates.emptyLocalDate(),
      shippingItem: '',
      dqlText: undefined
    };

    constructor() {
    }

    public read(request: SearchDataGetRequest): SearchDataResult {
      let rawBase: StoredSearchRawDataBase;
      try {
        const key = this.generateKey();
        const json = MemStorage.getItem(key);
        if (!json) {
          return this.createDefaultResult();
        }
        rawBase = JSON.parse(json);
      } catch (error) {
        return this.createDefaultResult();
      }
      if (Storage.VERSION === rawBase.version) {
        const raw: StoredSearchRawData = <StoredSearchRawData>rawBase;
        return this.fromRaw(raw);
      }
      return this.createDefaultResult();
    }


    public reset(request: SearchDataGetRequest): SearchDataResult {
      return this.save({
        searchData: Storage.DEFAULT_SEARCH_DATA,
      });
    }


    public save(request: SearchDataSetRequest): SearchDataResult {
      const key = this.generateKey();
      const raw = this.toRaw(request.searchData);
      const json = JSON.stringify(raw);
      MemStorage.setItem(key, json);
      return this.read(request);
    }

    private generateKey(): string {
      return 'UserId(' + LoggedInUserStorage.getInstance().getUserId() + ')-Search-Transport';
    }

    private createDefaultResult(): SearchDataResult {
      return {
        searchData: Storage.DEFAULT_SEARCH_DATA,
      };
    }


    private fromRaw(data: StoredSearchRawData): SearchDataResult {
      return {
        searchData: {
          itemsPerPage: data.itemsPerPage,
          pageNumber: data.pageNumber,
          order: data.order,
          id: data.id,
          waybillNumber: data.waybillNumber,
          vehicleIds: data.vehicleIds,
          driverIds: data.driverIds,
          safetyShipping: data.safetyShipping,
          stateIds: data.stateIds,
          closeTimeFrom: Services.toLocalDate(data.closeTimeFrom),
          closeTimeTo: Services.toLocalDate(data.closeTimeTo),
          creationTimeFrom: Services.toLocalDate(data.creationTimeFrom),
          creationTimeTo: Services.toLocalDate(data.creationTimeTo),
          openTimeFrom: Services.toLocalDate(data.openTimeFrom),
          openTimeTo: Services.toLocalDate(data.openTimeTo),
          shippingItem: data.shippingItem,
          dqlText: data.dqlText
        }
      };
    }

    private toRaw(data: SearchData): StoredSearchRawData {
      return {
        version: Storage.VERSION,
        itemsPerPage: data.itemsPerPage,
        pageNumber: data.pageNumber,
        order: data.order,
        id: data.id,
        waybillNumber: data.waybillNumber,
        vehicleIds: data.vehicleIds,
        driverIds: data.driverIds,
        safetyShipping: data.safetyShipping,
        stateIds: data.stateIds,
        closeTimeFrom: Services.localDateToString(data.closeTimeFrom),
        closeTimeTo: Services.localDateToString(data.closeTimeTo),
        creationTimeFrom: Services.localDateToString(data.creationTimeFrom),
        creationTimeTo: Services.localDateToString(data.creationTimeTo),
        openTimeFrom: Services.localDateToString(data.openTimeFrom),
        openTimeTo: Services.localDateToString(data.openTimeTo),
        shippingItem: data.shippingItem,
        dqlText: data.dqlText
      };
    }
  }

  export class VehicleItem {
    id: number;
    name: string;
    icon: Icon.Icon | undefined;

    get itemName() {
      return this.name;
    }
  }

  export class DriverItem {
    id: number;
    name: string;
    icon: Icon.Icon | undefined;

    get itemName() {
      return this.name;
    }
  }

  export class StateItem {
    id: string;
    name: string;

    get itemName() {
      return this.name;
    }
  }
}
