/* eslint-disable */
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { DocumentGroup, DocumentGroupService } from '../../../lib/document/document-group.service';
import { AuthService } from '../../../lib/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { DocumentGroupEditModel, DocumentGroupFieldErrorMap } from '../../../util/document-group.utils';
import { FieldError, FieldErrors, ObservableErrorResourceParser } from '../../../lib/util/errors';
import { UserGroup, UserGroupService } from '../../../lib/user-group.service';
import { MultiselectOptionItemWithData, UiConstants } from '../../../util/core-utils';
import { Arrays } from '../../../lib/util/arrays';
import { Transition, UIRouter } from '@uirouter/angular';
import { StateName } from '../../../app.state-names';
import { RootCoreService } from '../../../lib/root-core.service';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { Angular2Multiselects } from '../../../util/multiselect';
import {
  LocalFieldValidationErrors,
  LocalFieldValidationErrorsFactory,
  ResourceQueryResult,
  Services
} from '../../../lib/util/services';
import { List, Set } from 'immutable';
import { Strings } from '../../../lib/util/strings';
import { NgForm, NgModel } from '@angular/forms';
import { UserGroupItemForDropdown } from '../../../util/user-utils';
import { DownloadedFile } from '../../../lib/util/downloaded-files';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { saveAs } from 'file-saver';
import { EmptyMessage } from '../../../lib/util/messages';
import { StringKey } from '../../../app.string-keys';
import { ToasterService } from '../../../fork/angular2-toaster/src/toaster.service';
import { UploadMimeType } from '../../../shared/file-upload/upload-mime-type';
/* eslint-enable */

@Component({
  selector: 'app-document-group-edit',
  templateUrl: 'document-group-edit.component.html',
  styleUrls: ['document-group-edit.component.scss']
})
export class DocumentGroupEditComponent implements OnInit, AfterViewInit {

  UiConstants = UiConstants;
  UploadMimeType = UploadMimeType;

  languageCodes: string[] = [];
  model: Model = new Model();
  fieldErrors: DocumentGroupFieldErrorMap = {};
  userGroupList: UserGroupItemForDropdown[] = [];
  documentGroupId: number;
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  dropdownSettings: Angular2Multiselects.Settings;
  parentDropdownSettings: Angular2Multiselects.Settings;

  groups: MultiselectOptionItemWithData<number>[] = [];

  thumbnailUploadPath: string = '';

  imageToShow: any | null = null;
  thumbnailLoaded = false;
  thumbnailSrc: SafeStyle;

  @ViewChild('f', { static: true })
  fForm: NgForm;

  @ViewChild('code', { static: true })
  code: NgModel;

  @ViewChild('name', { static: true })
  name: NgModel;

  @ViewChild('userGroups', { static: true })
  userGroups: NgModel;

  @ViewChild('parent', { static: true })
  parent: NgModel;

  documentLibraryAspectRatio: number = DocumentGroup.DOCUMENT_LIBRARY_ASPECT_RATIO;

  private validatedInputs: LocalFieldValidationErrors<NgModel> =
    LocalFieldValidationErrorsFactory.empty();

  ngOnInit() {
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('COMMON_DOCUMENT_GROUPS').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.DOCUMENT_GROUP_LIST});
      }
    );
  }

  ngAfterViewInit() {
    this.loadModel();
    this.getLanguages();
    this.initDropDown();
    this.loadLocalFieldValidationErrors();
  }

  private loadLocalFieldValidationErrors() {
    const validatedInputs = List.of(this.name, this.code, this.userGroups);
    this.validatedInputs = LocalFieldValidationErrorsFactory.ofFormFields(this.fForm, validatedInputs);
  }

  update() {
    this.name.control.updateValueAndValidity();
    this.code.control.updateValueAndValidity();
    this.userGroups.control.updateValueAndValidity();
    this.parent.control.updateValueAndValidity();
    if (this.name.control.invalid ||
      this.code.control.invalid ||
      this.userGroups.invalid ||
      this.parent.invalid) {
      return;
    }
    this.documentGroupService.updateGroup({
      id: this.documentGroupId,
      name: this.model.item.name,
      language_code: this.model.item.language_code,
      code: this.model.item.code,
      user_group_id: this.model.item.createUserGroupIds(),
      parent_id: this.model.item.mainGroup ? undefined : this.model.item.parent[0].id
    }).subscribe( result => {
      this.uiRouter.stateService.go(StateName.DOCUMENT_GROUP_LIST);
    }, error => {
      const res = ObservableErrorResourceParser.parseError(error);
      this.fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
    })
  }

  getLanguages() {
    this.rootService.getLanguageCodes({}).subscribe(
      (result: string[]) => {
        this.languageCodes = result;
      });
  }

  private loadModel() {

    this.documentGroupService.get({id: this.documentGroupId})
      .subscribe(
      (documentGroup: DocumentGroup) => {
        this.breadcrumbSelf = documentGroup.name;
        this.model.item = new DocumentGroupEditModel();
        this.model.item.id = documentGroup.id!;
        this.model.item.code = documentGroup.code;
        this.model.item.name = documentGroup.name;
        this.model.item.language_code = documentGroup.language_code;
        this.model.item.mainGroup = !documentGroup.parent_id;

        this.model.hasThumbnail = !!documentGroup.picture;
        if (this.model.hasThumbnail) {
          this.downloadThumbnailForPreview();
        }

        this.loadUserGroups(documentGroup.user_group_id);
        this.loadGroups(undefined, documentGroup.parent_id);
        this.loadChildGroups();
      })
  }

  loadUserGroups(userGroupIds?: number[], q?: any) {
    this.userGroupService.query({
        name: q ? Strings.undefinedOrNonEmpty(q) : undefined,
        order: Services.createOrderFieldParameter(UserGroup.Keys.toOrderFieldKey, Set.of(UserGroup.DEFAULT_ORDER)),
        page_number: 1,
        number_of_items: 30,
        no_progress_bar: true,
        disabled: false
      }
    ).subscribe((result: ResourceQueryResult<UserGroup>) => {
      this.userGroupList = [];
      Arrays.iterateByIndex(result.items, (userGroup) => {
        const item = new UserGroupItemForDropdown();
        item.id = userGroup.id;
        item.itemName = userGroup.name;
        item.disabled = userGroup.disabled;
        this.userGroupList.push(item);
        if (userGroupIds && userGroupIds.includes(userGroup.id)) {
          this.model.item.user_groups.push(item);
        }
      });
      if (userGroupIds && userGroupIds.length !== this.model.item.user_groups.length) {
        this.userGroupService.query({
            id: userGroupIds.join(','),
            order: Services.createOrderFieldParameter(UserGroup.Keys.toOrderFieldKey, Set.of(UserGroup.DEFAULT_ORDER))
          }
        ).subscribe((result: ResourceQueryResult<UserGroup>) => {
          this.model.item.user_groups = [];
          Arrays.iterateByIndex(result.items, (userGroup) => {
            const item = new UserGroupItemForDropdown();
            item.id = userGroup.id;
            item.itemName = userGroup.name;
            item.disabled = userGroup.disabled;
            this.model.item.user_groups.push(item);
          });
        });
      }
    });
  }

  loadGroups(q?: string, parentId?: number) {
    this.documentGroupService.query({
      name: q ? Strings.undefinedOrNonEmpty(q) : undefined,
      disabled: false,
      page_number: 1,
      number_of_items: UiConstants.autocompletePageSize,
      no_progress_bar: true
    }).subscribe(result => {
      this.groups = result.items.map(g => ({id: g.id!, itemName: g.name}));
      if (parentId) {
        const parent = this.groups.find(g => g.id === parentId);
        if (parent) {
          this.model.item.parent.push(parent);
          this.parent.control.updateValueAndValidity();
        }
        else {
          this.documentGroupService.get({
            id: parentId
          }).subscribe(result => {
            this.model.item.parent.push({
              id: result.id!,
              itemName: result.name,
              disabled: result.disabled
            });
            this.parent.control.updateValueAndValidity();
          });
        }
      }
    });
  }

  loadChildGroups() {
    this.documentGroupService.query({
      parent_id: this.model.item.id,
      order: '+name'
    }).subscribe(result => {
      this.model.item.children = result.items.map(g => ({id: g.id!, itemName: g.name}));
    });
  }

  initDropDown() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .remoteSearch(true)
      .build();
    this.parentDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
  }

  removeFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.fieldErrors, fieldError);
  }

  private downloadThumbnailForPreview() {
    this.thumbnailLoaded = false;
    this.documentGroupService.downloadThumbnail(this.documentGroupId)
      .subscribe((res: DownloadedFile) => {
        this.imageToShow = URL.createObjectURL(res.getBlob());
        this.thumbnailSrc = this.sanitizer.bypassSecurityTrustStyle(`url(${this.imageToShow})`);
        this.thumbnailLoaded = true;
      }
    );
  }


  downloadThumbnail() {
    this.documentGroupService.downloadThumbnail(this.documentGroupId)
      .subscribe((res: DownloadedFile) => {
      saveAs(res.getBlob(), res.getFileName(this.model.item.name + '.png'));
    });
  }

  deleteThumbnail() {
    if (this.model.hasThumbnail) {
      this.documentGroupService.deleteThumbnail({
        id: this.documentGroupId
      }).subscribe(
        (result: EmptyMessage) => {
          this.toasterService.pop({
            timeout: UiConstants.ToastTimeoutShort,
            type: UiConstants.toastTypeSuccess,
            title: this.translateService.instant(StringKey.TASK_ATTACHMENT_TITLE),
            body: this.translateService.instant(StringKey.TASK_ATTACHMENT_DELETE_MESSAGE)
          });
          this.model.hasThumbnail = false;
        },
        (error: Error) => {
          this.toasterService.pop({
            timeout: UiConstants.ToastTimeoutLong,
            type: UiConstants.toastTypeError,
            title: this.translateService.instant(StringKey.TASK_ATTACHMENT_TITLE),
            body: error.message
          });
        });
    }
  }

  onMainGroupChanged() {
    this.model.item.user_groups = [];
  }

  onThumbnailUploaded() {
    this.model.hasThumbnail = true;
    this.downloadThumbnailForPreview();
  }

  constructor(private translateService: TranslateService,
              private authService: AuthService,
              private rootService: RootCoreService,
              private userGroupService: UserGroupService,
              private toasterService: ToasterService,
              private sanitizer: DomSanitizer,
              private uiRouter: UIRouter,
              private documentGroupService: DocumentGroupService,
              private transition: Transition) {
    this.documentGroupId = this.transition.params().id;
    this.thumbnailUploadPath = '/document-groups/' + this.documentGroupId + '/picture';
  }
}

class Model {
  item: DocumentGroupEditModel = new DocumentGroupEditModel();
  languageCode: string | null = null;
  hasThumbnail: boolean = false;
}
