import { AfterViewInit, Component, ContentChildren, EventEmitter, OnInit, Output, QueryList } from '@angular/core';
import { StepperItemComponent } from '../../stepper-item/stepper-item.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'stepper-container',
  templateUrl: './stepper-container.component.html',
  styleUrls: ['./stepper-container.component.scss']
})
export class StepperContainerComponent implements OnInit, AfterViewInit {

  @ContentChildren(StepperItemComponent)
  steps: QueryList<StepperItemComponent>;

  @Output()
  onSave: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  onStep: EventEmitter<any> = new EventEmitter<any>();

  index: number = 0;

  constructor() {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.initSteps();
  }

  private initSteps() {
    this.steps.first.setVisibility(true);
  }

  jump(index: number) {
    if (index < this.index) {
      this.currentStep.setVisibility(false);
      this.index = index;
      this.currentStep.setVisibility(true);
      this.onStep.emit();
    }
    else {
      throw Error('Cannot jump forward!');
    }
  }

  next() {
    if (this.index < this.steps.length - 1) {
      if (this.currentStep.submit()) {
        this.currentStep.setVisibility(false);
        this.index++;
        this.currentStep.setVisibility(true);
        this.onStep.emit();
      }
    }
    else {
      if (this.currentStep.submit()) {
        this.onSave.emit();
      }
    }
  }

  previous() {
    if (this.index !== 0) {
      this.currentStep.setVisibility(false);
      this.index--;
      this.currentStep.setVisibility(true);
      this.onStep.emit();
    }
    else {
      throw Error('Cannot step back!');
    }
  }

  get currentStep(): StepperItemComponent {
    return this.steps.toArray()[this.index];
  }

  get isFirst(): boolean {
    return this.index === 0;
  }

  get isLast(): boolean {
    return this.steps && this.index === this.steps.length - 1;
  }

}
