/* eslint-disable */
import { NamedBlobFile } from '../../lib/util/downloaded-files';
import { Observable } from 'rxjs';
import { AfterViewInit, Component, Input, ViewChild, } from '@angular/core';
import { FileAttachmentModel, FileTypeModelFactory } from '../../util/file-attachment.model';
import { FileAttachment, FileAttachmentUpdateRequest } from '../../lib/util/file-attachments';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatConfirmDialogComponent, MatConfirmDialogData } from '../mat-confirm-dialog/mat-confirm-dialog.component';
import { AlertType } from '../confirm-dialog/confirm-dialog.component';
import {
  MaterialImageViewerDialogComponent,
  MaterialImageViewerNavigation
} from './material-image-viewer-dialog/material-image-viewer-dialog.component';
import { FileUploadComponent, UploadResponse } from '../file-upload/flat/file-upload.component';
import { StringKey } from '../../app.string-keys';
import { UploadErrorLocalizer } from '../../util/upload-error-localizer';
import { ToasterService } from '../../fork/angular2-toaster/src/toaster.service';
import { UiConstants } from '../../util/core-utils';
import { TranslateService } from '@ngx-translate/core';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { EmptyMessage } from '../../lib/util/messages';

/* eslint-enable */

@Component({
  selector: 'app-material-attachment-gallery',
  templateUrl: './material-attachment-gallery.component.html',
  styleUrls: ['./material-attachment-gallery.component.scss']
})
export class MaterialAttachmentGalleryComponent implements AfterViewInit {

  FileTypeModelFactory = FileTypeModelFactory;

  @Input()
  loaderFactory: FileLoaderFactory | ThumbnailLoaderFactory;
  @Input()
  customFileUploader?: FileUploader;
  @Input()
  multiQueue?: boolean = false;
  @Input()
  deleteAllowed?: boolean = false;

  @Input()
  updateAllowed?: boolean = true;

  @Input()
  displayUpload: boolean = true;

  @Input()
  maxNumberOfItems: number = -1;

  get uploadAllowed() {
    if (!this.displayUpload) {
      return false;
    }
    if (this.maxNumberOfItems < 0) {
      return true;
    }
    return (this.attachments.length + this.fileQueue.length) < this.maxNumberOfItems;
  }

  private viewerDialogRef: MatDialogRef<MaterialImageViewerDialogComponent, void>;

  @ViewChild('fileUploader')
  fileUploader: FileUploadComponent;

  get fileAttachments(): FileAttachment[] {
    return this._fileAttachments;
  }

  set fileAttachments(fileAttachments: FileAttachment[]) {
    this._fileAttachments = this.fileAttachments;
    this.attachments = [];
    fileAttachments.forEach((r) => {
      const item = new FileAttachmentGalleryModel(r);
      this.attachments.push(item);
      this.downloadAttachmentOnceIfPreviewPossible(item);
    });
  }

  private _fileAttachments: FileAttachment[] = [];

  get fileQueue(): FileItem[] {
    if (!this.fileUploader || !this.fileUploader.uploader) {
      return [];
    }
    return this.fileUploader.uploader.queue.filter(i => !i.isUploaded);
  }

  attachments: FileAttachmentGalleryModel[] = [];
  modalOpened: boolean = false;
  clickedImage: FileAttachmentGalleryModel;
  clickedImageId: number;

  private activated: boolean = false;

  constructor(private dialog: MatDialog,
              private uploadErrorLocalizer: UploadErrorLocalizer,
              private toasterService: ToasterService,
              private translateService: TranslateService,
  ) {
  }

  ngAfterViewInit(): void {
    this.loadAttachments();
  }

  public activateView() {
    this.activated = true;
    this.attachments.forEach((a) => {
      this.downloadAttachmentOnceIfPreviewPossible(a);
    });
  }

  downloadFile(attachment: FileAttachmentGalleryModel) {
    attachment.saveAs();
  }

  downloadFileThenSave(attachment: FileAttachmentGalleryModel) {
    this.downloadAttachmentOnce(attachment, () => {
      this.downloadFile(attachment);
    });
  }

  retryDownloadFile(attachment: FileAttachmentGalleryModel) {
    this.downloadAttachmentOnce(attachment);
  }

  openConfirmDeleteDialog(attachment: FileAttachmentGalleryModel) {
    const dialogConfig: MatConfirmDialogData = {
      titleKey: 'CONFIRM_DIALOG_TITLE_DELETE',
      messageKey: 'CONFIRM_DIALOG_MESSAGE_DELETE',
      alertType: AlertType.DANGER
    };
    const dialogRef = this.dialog.open(MatConfirmDialogComponent, {
      data: dialogConfig,
      width: '50%'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteAttachment(attachment.attachment.id);
      }
    });
  }

  onImageClick(image: FileAttachmentGalleryModel) {
    if (this.loaderFactory.hasThumbnails() && !image.downloaded) {
      this.downloadAttachmentOnce(image, () => {
        this.modalOpened = true;
        this.clickedImage = image;
        this.openViewerDialog();
      });
    }
    else {
      this.modalOpened = true;
      this.clickedImage = image;
      this.openViewerDialog();
    }
  }

  onPreviousClicked(clickedImage: FileAttachmentGalleryModel) {
    this.clickedImageId = this.attachments.indexOf(clickedImage);
    if (this.clickedImageId === 0) {
      this.clickedImageId = this.attachments.length;
    }
    const nextImage = this.attachments[this.clickedImageId - 1];
    if (!this.displayAsImage(nextImage)) {
      this.onPreviousClicked(nextImage);
    }
    else {
      if (this.loaderFactory.hasThumbnails() && !nextImage.downloaded) {
        this.downloadAttachmentOnce(nextImage);
      }
      this.clickedImage = nextImage;
      this.viewerDialogRef.componentInstance.changeClickedImage(this.clickedImage);
    }
  }

  onNextClicked(clickedImage: FileAttachmentGalleryModel) {
    this.clickedImageId = this.attachments.indexOf(clickedImage);
    if (this.clickedImageId === (this.attachments.length - 1)) {
      this.clickedImageId = -1;
    }
    const nextImage = this.attachments[this.clickedImageId + 1];
    if (!this.displayAsImage(nextImage)) {
      this.onNextClicked(nextImage);
    }
    else {
      if (this.loaderFactory.hasThumbnails() && !nextImage.downloaded) {
        this.downloadAttachmentOnce(nextImage);
      }
      this.clickedImage = nextImage;
      this.viewerDialogRef.componentInstance.changeClickedImage(this.clickedImage);
    }
  }

  displayAsPlaceholder(m: FileAttachmentGalleryModel): boolean {
    return !this.displayAsImage(m);
  }

  displayAsImage(m: FileAttachmentGalleryModel): boolean {
    if (!m.downloaded && !m.thumbnailDataUrl) {
      return false;
    }
    return m.contentTypeParser.isImageType;
  }

  updateAttachmentName(m: FileAttachmentGalleryModel, popover: PopoverDirective, fileName?: string) {
    popover.hide();
    if (fileName) {
      this.loaderFactory.updateAttachment({fileId: m.attachment.id, name: fileName}).subscribe(r => this.loadAttachments());
    }
  }

  displayAsPlaceholderQueue(m: FileItem): boolean {
    return !this.displayAsImageQueue(m);
  }

  displayAsImageQueue(m: FileItem): boolean {
    return m.file.type.startsWith('image');
  }

  getPreviewDataUrl(m: FileAttachmentGalleryModel): string | undefined {
    return m.thumbnailDataUrl ? m.thumbnailDataUrl : m.base64dataUrl;
  }

  private hasPreviewSupport(item: FileAttachmentGalleryModel): boolean {
    return item.contentTypeParser.isImageType;
  }

  private downloadAttachmentOnceIfPreviewPossible(item: FileAttachmentGalleryModel) {
    if (this.activated && this.hasPreviewSupport(item)) {
      if (this.loaderFactory.hasThumbnails()) {
        this.downloadThumbnail(item);
      }
      else {
        this.downloadAttachmentOnce(item);
      }
    }
  }

  private downloadAttachmentOnce(item: FileAttachmentGalleryModel, callback?: () => any) {
    item.downloadOnce(this.loaderFactory.createFileLoader(item.attachment), callback);
  }

  private downloadThumbnail(item: FileAttachmentGalleryModel, callback?: () => any) {
    item.downloadThumbnail((<ThumbnailLoaderFactory>this.loaderFactory).createThumbnailLoader(item.attachment), callback);
  }

  private openViewerDialog() {
    this.viewerDialogRef = MaterialImageViewerDialogComponent.openImageViewerWindow(this.dialog, {
      clickedImage: this.clickedImage
    });
    const naviSubs = this.viewerDialogRef.componentInstance.navigationClicked.subscribe(value => {
      if (value === MaterialImageViewerNavigation.PREVIOUS) {
        this.onPreviousClicked(this.clickedImage);
      }
      else {
        this.onNextClicked(this.clickedImage);
      }
    });
    this.viewerDialogRef.afterClosed().subscribe(() => {
      naviSubs.unsubscribe();
    });
  }

  hasDeleteRight(image: FileAttachmentGalleryModel) {
    return image.attachment.hasDeleteRight === undefined ? true : image.attachment.hasDeleteRight;
  }

  private deleteAttachment(id: number) {
    this.loaderFactory.deleteAttachment(id).subscribe(() => {
      this.loadAttachments();
    });
  }

  onResponseContentReceived(uploadResponse: UploadResponse) {
    if (uploadResponse.status !== 200) {
      const localizedError = this.uploadErrorLocalizer.localizeError(uploadResponse.response);
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutLong,
        type: UiConstants.toastTypeError,
        title: this.translateService.instant(StringKey.FORM_DOCUMENT_UPLOAD_ERROR),
        body: localizedError.globalErrors
      });
    }
  }

  onAttachmentUploadSuccess(succeeded: boolean) {
    if (succeeded) {
      if (!this.customFileUploader) {
        this.fileUploader.clearQueue();
      }
      this.loadAttachments();
    }
  }

  private loadAttachments() {
    this.fileAttachments = [];
    this.loaderFactory.loadAttachments().subscribe(result => {
      this.fileAttachments = result;
      this.activateView();
    });
  }

  removeFromQueue(image: FileItem) {
    this.fileUploader.uploader.removeFromQueue(image);
  }
}

export class FileAttachmentGalleryModel extends FileAttachmentModel {
}

export interface FileLoaderFactory {
  loadAttachments(): Observable<FileAttachment[]>;

  createFileLoader(attachment: FileAttachment): Observable<NamedBlobFile>;

  hasThumbnails(): boolean;

  deleteAttachment(fileId: number): Observable<any>;

  getUploadPath(): string | undefined;

  updateAttachment(r: FileAttachmentUpdateRequest): Observable<EmptyMessage>;
}

export interface ThumbnailLoaderFactory extends FileLoaderFactory {
  createThumbnailLoader(attachment: FileAttachment): Observable<NamedBlobFile>;
}
