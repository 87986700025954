/* eslint-disable */
import { Observable, of as observableOf } from 'rxjs';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SelectUtils, UiConstants } from '../../../../util/core-utils';
import { WeightFactory } from '../../../../util/weight-utils';
import { InputMask } from '../../../../util/input-masks';
import { Models } from '../../../../util/model-utils';
import { ComponentStateResolver } from '../../../../util/component-state/component-state-resolver';
import { TranslateService } from '@ngx-translate/core';
import { LengthModel, PriceModel, ShippingItemEditModel, ShippingItemModel, ShippingItemSizeEdit } from '../shipping-demand-edit.model';
import Decimal from 'decimal.js';
import { Services } from '../../../../lib/util/services';
import { Strings } from '../../../../lib/util/strings';
import { ShippingDemand } from '../../../../lib/shipping-demand/shipping-demand.service';
import { RightModel } from '../../../../app.rights';
import ItemSize = ShippingDemand.ItemSize;
import { ToasterService } from '../../../../fork/angular2-toaster/src/toaster.service';
import { StringKey } from '../../../../app.string-keys';

/* eslint-enable */

@Component({
  selector: 'app-shipping-demand-item-edit',
  templateUrl: './shipping-demand-item-edit.component.html',
  styleUrls: ['./shipping-demand-item-edit.component.scss']
})
export class ShippingDemandItemEditComponent implements OnInit {

  SelectUtils = SelectUtils;
  InputMask = InputMask;
  UiConstants = UiConstants;
  EditableField = ShippingDemand.EditableField;

  @Input()
  isRemovable: boolean;

  @ViewChild('f', { static: true })
  fForm: NgForm;

  @ViewChild('name', { static: true })
  name;

  @Input()
  itemError: any;

  @Input()
  currencyList: string[];

  @Input()
  disabled?: boolean = false;

  selectableLengthUnits: string[] = ['mm', 'cm', 'm'];

  @Input()
  set model(model: ShippingItemModel) {
    const cloneModel = {...model};
    this.m = {
      id: cloneModel.id,
      externalId: cloneModel.externalId,
      name: cloneModel.name,
      category: cloneModel.category,
      productCode: cloneModel.productCode,
      amount: {...cloneModel.amount},
      comment: cloneModel.comment,
      boxCode: cloneModel.boxCode,
      labelCode: cloneModel.labelCode,
      weight: Models.numberToString(cloneModel.weightInGram),
      itemSize:
        cloneModel.itemSize
          ? {
            width: {
              amount: Models.decimalToString(cloneModel.itemSize.width),
              unit: 'm'
            },
            height: {
              amount: Models.decimalToString(cloneModel.itemSize.height),
              unit: 'm'
            },
            depth: {
              amount: Models.decimalToString(cloneModel.itemSize.depth),
              unit: 'm'
            }
          }
          : new ShippingItemSizeEdit(),
      price: {...cloneModel.price},
      editing: cloneModel.editing,
    };
    this.savedExternalId = cloneModel.externalId;
  }

  get model(): ShippingItemModel {
    const weightInGram = Models.parseNumber(this.m.weight);
    const price = Models.numberToString(Models.parseNumber(this.m.price.amount + ''));
    const model = new ShippingItemModel();
    model.id = this.m.id;
    model.externalId = this.m.externalId;
    model.name = this.m.name;
    model.category = this.m.category;
    model.productCode = this.m.productCode;
    model.amount = this.m.amount;
    model.comment = this.m.comment;
    model.boxCode = this.m.boxCode;
    model.labelCode = this.m.labelCode;
    model.weightInGram = weightInGram ? this.weightConverterToGram(weightInGram) : undefined;
    model.itemSize = this.itemSizeConverter(this.m.itemSize);
    model.price = new PriceModel();
    model.price.amount = price;
    model.price.currencyCode = this.m.price.currencyCode;
    model.editing = true;
    return model;
  }

  @Input()
  shippingItemExternalIds: string[];

  @Input()
  componentState: ComponentStateResolver;

  @Input()
  rightModel: RightModel = RightModel.empty();

  @Input()
  editableFieldModel: ShippingDemand.EditableFields;

  @Output()
  remove: EventEmitter<undefined> = new EventEmitter();

  @Output()
  save: EventEmitter<ShippingItemModel> = new EventEmitter<ShippingItemModel>();

  @Output()
  cancel: EventEmitter<undefined> = new EventEmitter();

  m: ShippingItemEditModel = new ShippingItemEditModel();


  unitOptions: Observable<string[]>;

  selectableWeightUnits: string[] = [];
  packageWeightUnit: string = 'g';
  uniqueExternalId: boolean = true;
  savedExternalId: string = '';

  unitTranslateArray: string[] = [];
  unitArray: string[] = [];

  maxWeightInGram: number = 100000000;
  isOverweight: boolean = false;


  constructor(
    private translateService: TranslateService,
    private toasterService: ToasterService
  ) {
  }

  ngOnInit() {
    this.loadDropdowns();
    this.initItemUnitAutocomplete();
  }

  private loadDropdowns() {
    this.selectableWeightUnits = WeightFactory.getOptions();
  }


  hasError(field: string): boolean {
    if (this.itemError && this.itemError[field]) {
      return true;
    }
    return false;
  }

  removeError(field: string) {
    if (this.hasError(field)) {
      this.itemError[field] = null;
    }
  }

  checkUniqueExternalId() {
    setTimeout(() => {
      const filteredShippingList =
        this.shippingItemExternalIds.filter(externalId => externalId === this.m.externalId && externalId !== this.savedExternalId);
      this.uniqueExternalId = this.m.externalId.length === 0 || filteredShippingList.length === 0;
    });
  }

  saveItem() {
    this.checkUniqueExternalId();

    // Validate if weight exceeds maximum weight
    this.isOverweight = this.m.weight.length > 0 &&
      Models.parseNumber(this.m.weight) !== undefined &&
      this.weightConverterToGram(Models.parseNumber(this.m.weight)!) > this.maxWeightInGram;

    // Emit the model if no validation errors present
    if (this.uniqueExternalId && !this.isOverweight) {
      if (!this.isEnabledField(ShippingDemand.EditableField.ITEM_AMOUNT)) {
        this.model.amount.value = '1';
      }
      this.save.emit(this.model)
    }
  }

  weightConverterToGram(weight: number): number {
      switch (this.packageWeightUnit) {
        case 'g':
          break;
        case 'dkg':
          weight = weight * 10;
          break;
        case 'kg':
          weight = weight * 1000;
          break;
        case 't':
          weight = weight * 1000 * 1000;
      }
      return weight;
  }

  lengthConverterToMeters(length: LengthModel): Decimal | undefined {
    let rawLength: Decimal | undefined = Services.toDecimal(Strings.undefinedOrNonEmpty(length.amount));
    if (rawLength) {
      switch (length.unit) {
        case 'mm':
          rawLength = rawLength.div(new Decimal(1000));
          break;
        case 'cm':
          rawLength = rawLength.div(new Decimal(100));
          break;
      }
    }
    return rawLength;
  }

  itemSizeConverter(itemSize: ShippingItemSizeEdit): ItemSize | undefined {
    const width: Decimal | undefined = this.lengthConverterToMeters(itemSize.width);
    const height: Decimal | undefined = this.lengthConverterToMeters(itemSize.height);
    const depth: Decimal | undefined = this.lengthConverterToMeters(itemSize.depth);
    if (width && height && depth) {
      return {
        width: width,
        height: height,
        depth: depth
      };
    }
    return undefined;
  }

  isItemSizeValid(): boolean {
    const width: Decimal | undefined = this.lengthConverterToMeters(this.m.itemSize.width);
    const height: Decimal | undefined = this.lengthConverterToMeters(this.m.itemSize.height);
    const depth: Decimal | undefined = this.lengthConverterToMeters(this.m.itemSize.depth);
    return (width !== undefined && height !== undefined && depth !== undefined)
      || (width === undefined && height === undefined && depth === undefined);
  }

  areCodesValid(): boolean {
    if (Strings.undefinedOrNonEmpty(this.m.boxCode) && this.m.labelCode === this.m.boxCode) {
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutLong,
        type: UiConstants.toastTypeError,
        title: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_TITLE),
        body: this.translateService.instant(StringKey.SHIPPING_DEMAND_ITEM_CODES_ERROR)
      });
      return false;
    }
    return true;
  }

  private initItemUnitAutocomplete() {

    this.unitTranslateArray = [
      'COMMON_UNIT_PIECES',
      'COMMON_UNIT_BOX',
      'COMMON_UNIT_LITER',
      'COMMON_UNIT_METER',
      'COMMON_UNIT_PALLET',
      'COMMON_UNIT_BARREL',
    ];
    this.unitTranslateArray.forEach(value => {
      this.translateService.get(value).subscribe((result: string) => {
        this.unitArray.push(result);
      })
    });
    this.itemUnitChange('')

  }

  itemUnitChange(inputText: string) {
    if (inputText.length !== 0) {
      const filteredUnits = this.unitArray.filter(value => value.includes(inputText));
      this.unitOptions = observableOf(filteredUnits);
    }
    else {
      this.unitOptions = observableOf(this.unitArray);
    }
  }

  isEnabledField(field: ShippingDemand.EditableField): boolean {
    if (this.componentState.isCreateView() || this.componentState.isCloneView()) {
      if (this.rightModel.shippingDemandCreate.hasRight()) {
        return this.editableFieldModel.isEnabledByServer(false, field);
      }
      if (this.rightModel.shippingDemandCreateSimple.hasRight()) {
        return this.editableFieldModel.isEnabledByServer(true, field);
      }
      return false;
    }
    if (this.componentState.isEditView()) {
      if (this.rightModel.shippingDemandUpdate.hasRight()) {
        return this.editableFieldModel.isEnabledByServer(false, field);
      }
      if (this.rightModel.shippingDemandUpdateSimple.hasRight()) {
        return this.editableFieldModel.isEnabledByServer(true, field);
      }
      return false;
    }
    return this.editableFieldModel.isEnabledByServer(false, field);
  }
}

