/* eslint-disable */
import {
  AfterViewInit,
  Component,
  DoCheck,
  ElementRef,
  ErrorHandler,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { AuthResult, AuthService, UserProfileType } from '../lib/auth.service';
import { UserData, UserDataLoader, UserDataLoaderPermissionDeniedStrategy } from '../lib/user-data-loader';
import { LoadingHandler } from '../lib/loading-handler';
import { UIRouter } from '@uirouter/angular';
import { StateName } from '../app.state-names';
import { environment } from '../../environments/environment';
import { AppConfigurationService } from '../lib/app-configuration.service';
import { RightModel } from '../app.rights';
import { RightResolver, RightService, } from '../lib/right.service';
import { SurveyService } from '../lib/survey/survey.service';
import { QueryResult, ResourceQueryResult, Services } from '../lib/util/services';
import { MasterDataService, } from '../lib/masterdata/master-data.service';
import { RuntimeConfiguration } from '../lib/runtime-configuration';
import { PasswordFieldErrorMap, UserService } from '../lib/user.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToasterContainer } from '../shared/toaster-container';
import { TaskService } from '../lib/task/task.service';
import { ConfigurationResource, ConfigurationService } from '../lib/core-ext/configuration.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppValidators } from '../util/app-validators';
import { UserGroup, UserGroupService } from '../lib/user-group.service';
import { Role, RoleService } from '../lib/role/role.service';
import {MultiselectOptionItem, OptionItem, UiConstants} from '../util/core-utils';
import {
  LocalStorages,
  LoginRequiredReason,
  LoginRequiredReasonStorage,
  ServiceErrorStorage
} from '../lib/util/storages';
import { EmptyMessage } from '../lib/util/messages';
import { ObservableErrorResourceParser, ObservableErrorResponse } from '../lib/util/errors';
import { SettingsService } from '../lib/settings.service';
import { Message, MessageService } from '../lib/message/message.service';
import { SidebarRightComponent } from './sidebar-right/sidebar-right.component';
import { FirebaseMessageService, PushMessage } from '../lib/firebase/firebase-message.service';
import { PushNavigationService } from '../util/firebase/push-navigation.service';
import { ToasterService } from '../fork/angular2-toaster/angular2-toaster';
import { GlobalErrorHandler } from '../util/global-error-handler';
import { toasterConfig } from '../fork/angular2-toaster/src/toaster-config';
import { Set } from 'immutable';
import { ServiceInfoDialogComponent } from './service-info/service-info-dialog/service-info-dialog.component';
import { ResourceHelper } from '../lib/util/http-services';
import { ListItemMapService } from '../lib/list-item/list-item-map.service';
import { DownloadedFile } from '../lib/util/downloaded-files';
import { SidebarNavItemUtils } from '../shared/sidebar-nav-item/sidebar-nav-item-utils';
import { DOCUMENT } from '@angular/common';
import { OffsetDateTime } from '../lib/util/dates';
import { Subscription } from 'rxjs';
import { SiteTourService } from '../lib/site-tour/site-tour.service';
import SidebarNavItemModel = SidebarNavItemUtils.SidebarNavItemModel;
import { SiteTourId } from '../lib/site-tour/site-tour.factory';
/* eslint-enable */

@Component({
  selector: 'app-admin-layout',
  templateUrl: 'admin-layout.component.html',
  styleUrls: ['admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit, AfterViewInit, ToasterContainer.View, DoCheck, OnDestroy {

  ConfigurationService = ConfigurationService;
  StateName = StateName;
  UiConstants = UiConstants;
  toasterConfig = toasterConfig;

  private errorSubscription?: Subscription;

  @ViewChild(SidebarRightComponent, { static: true })
  sidebarRight: SidebarRightComponent;

  @ViewChild('serviceInfoDialog', { static: true })
  serviceInfoDialog: ServiceInfoDialogComponent;

  @ViewChild('changePasswordDialog', { static: true })
  changePasswordDialog: ModalDirective;
  passwordChangeForm: FormGroup;

  navItems: SidebarNavItemModel[] = [];

  changePasswordDialogVisible: boolean = false;
  passwordVisible: boolean = false;

  networkLoading: boolean = false;

  rightModel: RightModel = RightModel.empty();
  configuration: ConfigurationResource.Configuration;

  userDataToDisplay: UserDataToDisplay = new UserDataToDisplay();

  passwordModel: PasswordChangeModel = new PasswordChangeModel();
  passwordFieldError: PasswordFieldErrorMap;

  activeState: string;

  currentLocale: string = '';

  @ViewChild('brandLogoElement') brandLogoElement: ElementRef;
  sidebarClosed = false;

  private firebaseSubscription: Subscription;

  get releaseYear() {
    return this.appConfigurationService.getCommitDateTime().getYear();
  }

  get brandLogoSrc(): string | undefined {
    return this.resourceHelper.getBrandLogoSrc();
  }

  get brandLogoLoginSrc(): string | undefined {
    return this.resourceHelper.getBrandLogoLoginSrc();
  }

  get brandLogoSmallSrc(): string | undefined {
    return this.resourceHelper.getBrandLogoSmallSrc();
  }

  get developerCompanyName() {
    return environment.developerCompanyName;
  }

  get poweredByCompanyUrl() {
    return environment.poweredByCompanyUrl;
  }

  get poweredByCompanyName() {
    return environment.poweredByCompanyName;
  }

  activeStateChanged(from: string, to: string) {
    this.getNavItem(from).activeSubject.next(false);
    this.getNavItem(to).activeSubject.next(true);
  }

  private getNavItem(state: string): SidebarNavItemModel {
    for (let i = 0; i < this.navItems.length; i++) {
      if (this.navItems[i].states.includes(state)) {
        return this.navItems[i];
      }
    }
    return this.navItems[0];
  }

  ngOnInit() {
    this.addUncaughtExceptionListener();
    this.loadRightModels();
    const self = this;
    ToasterContainer.ViewReference.set(this);
    LoadingHandler.getInstance().setOnStartStopListener({
      onStart() {
        self.networkLoading = true;
      },
      onStop() {
        self.networkLoading = false;
      }
    });
    LoadingHandler.getInstance().setOnRefreshListener({
      onRefresh() {
        self.refreshMenuData();
      }
    });

    this.currentLocale = this.settingsService.getLocaleCode();
  }

  ngAfterViewInit(): void {
    this.initSidebar();
    const context = this;
    this.router.transitionService.onSuccess({}, function (transition) {
      context.activeStateChanged(transition.from().name!, transition.to().name!);
      context.scrollToTop();
    });
    this.refreshMenuData();
    this.sidebarRight.refresh();
  }

  ngOnDestroy(): void {
    if (this.firebaseSubscription) {
      this.firebaseSubscription.unsubscribe();
    }
    this.removeUncaughtExceptionListener();
  }

  getToastTimeout(): number {
    const eatThis: any = toasterConfig.timeout;
    return eatThis;
  }

  setToastTimeout(timeout: number) {
    toasterConfig.timeout = timeout;
  }

  refreshMenuData() {
    if (this.rightModel.userRead.hasRight()) {
      this.loadUserData();
    }
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe((resolver: RightResolver) => {
      this.rightModel = RightModel.of(resolver);
      this.navItems = SidebarNavItemUtils.createAdminSidebarNavItems(this.rightModel);
      // hack to let the ngIf directive show the menu elements before apply styles
      setTimeout(() => {
        this.activeStateChanged(this.router.stateService.$current.name, this.router.stateService.$current.name);
      });
      this.initFirebase(resolver.userProfile!.id);
      this.refreshMenuData();
      this.loadCachedData();
    });
  }

  private loadCachedData() {
    if (this.rightModel.listItemRead.hasRight()) {
      this.listItemMapService.loadMap();
    }
  }

  private initFirebase(userId: number) {
    this.firebaseMessageService.requestPermission(userId).subscribe(token => {
      this.firebaseMessageService.receiveMessage();
      this.firebaseSubscription = this.firebaseMessageService.currentMessage.subscribe((pushMessage: PushMessage) => {
        this.sidebarRight.refresh();
        this.toasterService.pop({
          timeout: UiConstants.ToastTimeoutLong,
          type: UiConstants.toastTypeInfo,
          title: pushMessage.notification.title,
          body: pushMessage.notification.body,
          buttonClickHandler: toast => {
            this.pushNavigationService.messageAction(Message.toPublicMessageData(pushMessage.data)) ?
              this.sidebarRight.toggleRightSidebar('NOTIFICATION_LIST') : this.sidebarRight.closeRightSidebar();
            return true;
          }
        });
      });
    });
  }

  logout() {
    this.authService.logout({})
      .subscribe(
        (result: any) => {
          this.onLogout();
        },
        (error: any) => {
          this.onLogout();
        }
      );
  }

  private initUserData(userData: UserData) {
    this.userDataToDisplay.id = userData.id;
    this.userDataToDisplay.type = userData.type;
    if (userData.user_name && userData.user_name.length > 0) {
      this.userDataToDisplay.userName = userData.user_name;
    }
    else {
      this.userDataToDisplay.userName = userData.person_name!;
    }
    if (userData.person_name && userData.person_name.length > 0) {
      this.userDataToDisplay.personName = userData.person_name;
    }
    this.userDataToDisplay.userGroupIds = userData.user_group_ids;
    this.userDataToDisplay.emailAddress = userData.email_address ? userData.email_address : '';
    this.userDataToDisplay.inactivationTime = userData.inactivation_time
      ? Services.toOffsetDateTime(userData.inactivation_time)
      : undefined;
    this.loadProfilePicture(userData.id);
    this.loadGroupsAndRolesToDisplay(userData);
  }

  private onLogout() {
    LocalStorages.onLogout();
    this.router.stateService.go(StateName.LOGIN);
  }

  private loadUserData() {
    this.authService.check({})
      .subscribe(
        (authResult: AuthResult) => {
          const profile = authResult.user_profile;
          if (profile) {
            this.userDataLoader.loadMe(profile, UserDataLoaderPermissionDeniedStrategy.HANDLE_ALL).subscribe(
              (userData: UserData) => {
                this.initUserData(userData);
              },
              (error: any) => {
                LoginRequiredReasonStorage.getInstance().setReason(LoginRequiredReason.UNKNOWN_USER, {
                  stateName: this.router.stateService.$current.name,
                  params: this.router.stateService.params
                });
                this.onLogout();
              }
            );
          }
          else {
            LoginRequiredReasonStorage.getInstance().setReason(LoginRequiredReason.NO_PROFILE_AVAILABLE, {
              stateName: this.router.stateService.$current.name,
              params: this.router.stateService.params
            });
            this.onLogout();
          }
        }
      );
  }

  changePassword() {
    if (!this.passwordChangeForm.valid) {
      this.passwordChangeForm.controls['current_password'].markAsTouched();
      this.passwordChangeForm.controls['password'].markAsTouched();
      this.passwordChangeForm.controls['confirm_password'].markAsTouched();
      return;
    }
    this.userService.updatePassword({
      id: this.userDataToDisplay.id!,
      new_password: this.passwordModel.password,
      current_password: this.passwordModel.prevPassword
    })
      .subscribe(
        (response: EmptyMessage) => {
          this.passwordModel = new PasswordChangeModel();
          this.hideChangePasswordDialog();
        },
        (error: ObservableErrorResponse) => {
          const res = ObservableErrorResourceParser.parseError(error);
          this.passwordFieldError = ObservableErrorResourceParser.extractFieldErrors(res);
        }
      );
  }

  onPasswordChangeModalHide() {
    this.passwordModel.confirmPassword = '';
    this.passwordModel.password = '';
    this.passwordModel.prevPassword = '';
    this.passwordChangeForm.reset();
  }

  onPasswordModelChange() {
    this.resetPasswordFieldError();
    this.passwordChangeForm.controls['confirm_password'].updateValueAndValidity();
  }

  getCurrentPasswordMaximumLength(): number {
    // Do not limit max length. If the password rule change, the user must change the current password.
    return UiConstants.maximumVarcharLength;
  }

  getNewPasswordMaximumLength(): number {
    // TODO: Return max length based on password policy.
    // TODO: Missing reactive input validations:
    // - each field is required
    // - current and new password must be different
    // - create 'new password again' input and validate the two current input to be the same.
    // - min length for current password inputs
    return UiConstants.maximumVarcharLength;
  }

  resetPasswordFieldError() {
    this.passwordFieldError = {};
  }

  showChangePasswordDialog() {
    this.changePasswordDialog.show();
    this.changePasswordDialogVisible = true;
  }

  hideChangePasswordDialog() {
    this.changePasswordDialog.hide();
    this.changePasswordDialogVisible = false;
    this.resetPasswordFieldError();
  }

  get santaEnabled() {
    return this.configuration.santa !== undefined && this.configuration.santa;
  }

  get snowEnabled() {
    const enabledStr = localStorage.getItem('snow_enabled');
    return enabledStr === undefined || enabledStr === 'true' || enabledStr === null;
  }


  toggleSnow() {
    if (this.santaEnabled) {
      if (this.snowEnabled) {
        localStorage.setItem('snow_enabled', 'false');
      }
      else {
        localStorage.setItem('snow_enabled', 'true');
      }
    }
  }

  resetSiteTours() {
    this.siteTourSerivce.resetTours();
  }

  skipSiteTours() {
    this.siteTourSerivce.skipTours();
  }

  private initSidebar() {
    const bodyElement = document.querySelector('body');
    const headerElement = document.querySelector('header');
    const breadcrumbElement = document.getElementById('breadcrumb');
    const formEditorCardContainerElement = document.getElementById('form-editor-card-container');
    const logo = document.getElementById('navbar-brand');
    const brandLogo = document.getElementById('navbar-asset-brand');
    if (bodyElement && bodyElement.classList.contains('sidebar-compact')) {
      headerElement!.classList.add('app-header-compact');
      if (breadcrumbElement) {
        breadcrumbElement.classList.add('breadcrumb-container-compact');
      }
      if (formEditorCardContainerElement) {
        formEditorCardContainerElement.classList.add('form-editor-card-container-compact');
      }
      if (logo) {
        logo!.classList.add('navbar-brand-small');
      }
      if (brandLogo) {
        brandLogo!.classList.add('navbar-brand-small');
      }
    }
  }

  notificationIconClicked() {
    this.sidebarRight.toggleRightSidebar('NOTIFICATION_LIST');
  }

  ngDoCheck() {
    if (this.brandLogoElement && this.brandLogoElement.nativeElement.classList.contains('navbar-brand-small')) {
      this.sidebarClosed = true;
    }
    else {
      this.sidebarClosed = false;
    }
  }

  private loadProfilePicture(userId: number) {
    this.userService.downloadProfilePicture(userId).subscribe(
      (res: DownloadedFile) => {
        this.userDataToDisplay.profilePicture = URL.createObjectURL(res.getBlob());
      },
      () => {
      }
    );
  }

  private loadGroupsAndRolesToDisplay(userData: UserData) {
    this.userDataToDisplay.userGroups = [];
    this.userDataToDisplay.roles = [];
    let roleIds: number[] = [];
    if (this.rightModel.userGroupRead.hasRight()) {
      this.userGroupService.query({
        id: this.userDataToDisplay.userGroupIds.join(','),
        page_number: 1,
        number_of_items: 10,
        order: Services.createOrderFieldParameter(UserGroup.Keys.toOrderFieldKey, Set.of(UserGroup.DEFAULT_ORDER)),
      }).subscribe((userGroups: ResourceQueryResult<UserGroup>) => {
        userGroups.items.forEach((group: UserGroup) => {
          const item = new MultiselectOptionItem<number>();
          item.id = group.id;
          item.itemName = group.name;
          item.itemSubtitle = group.companies ? '(' + group.companies.map(c => c.name).join(',') + ')' : undefined;
          this.userDataToDisplay.userGroups.push(item);
          roleIds = roleIds.concat(group.roles.map((r) => +r));
        });
        roleIds.filter((item, index) => roleIds.indexOf(item) === index);
        if (this.rightModel.roleRead.hasRight()) {
          this.roleService.query({
            id: Set.of(...roleIds),
            paging: {
              pageNumber: 1,
              numberOfItems: 10
            }
          }).subscribe((roles: QueryResult<Role.Role>) => {
            roles.items.forEach((role: Role.Role) => {
              const item = new OptionItem<number>();
              item.id = role.id;
              item.text = role.name;
              this.userDataToDisplay.roles.push(item);
            });
          });
        }
      });
    }
  }

  private checkConfiguration() {
    if (!this.configService.loaded) {
      localStorage.setItem('configurationError', 'true');
      ServiceErrorStorage.getInstance().setStateObject({
        stateName: this.router.stateService.$current.name,
        params: this.router.stateService.params
      });
      this.router.stateService.go(StateName.CONFIGURATION_LOAD_ERROR);
    }
  }

  navigate(stateName: StateName, params?: any) {
    params ? this.router.stateService.go(stateName, params) : this.router.stateService.go(stateName);
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }

  constructor(private router: UIRouter,
              private renderer: Renderer2,
              private globalErrorHandler: ErrorHandler,
              private appConfigurationService: AppConfigurationService,
              private authService: AuthService,
              private taskService: TaskService,
              private surveyService: SurveyService,
              private masterDataService: MasterDataService,
              private userDataLoader: UserDataLoader,
              private userService: UserService,
              private userGroupService: UserGroupService,
              private roleService: RoleService,
              private rightService: RightService,
              private configService: ConfigurationService,
              private formBuilder: FormBuilder,
              private settingsService: SettingsService,
              private firebaseMessageService: FirebaseMessageService,
              private toasterService: ToasterService,
              private pushNavigationService: PushNavigationService,
              private messageService: MessageService,
              private listItemMapService: ListItemMapService,
              private siteTourSerivce: SiteTourService,
              private resourceHelper: ResourceHelper,
              @Inject(DOCUMENT) document) {
    this.checkConfiguration();
    this.configuration = this.configService.getConfiguration();
    this.passwordFieldError = {};
    this.passwordChangeForm = formBuilder.group({
      current_password: [[], Validators.required],
      password: [[], Validators.required],
      confirm_password: [[], Validators.required, AppValidators.validateMatchingPasswordPromise]
    });
  }

  private addUncaughtExceptionListener() {
    if (this.globalErrorHandler instanceof GlobalErrorHandler) {
      this.errorSubscription = this.globalErrorHandler.events.subscribe(e => {
        if (!this.isErrorToastActive()) {
          return;
        }
        this.toasterService.pop({
          type: UiConstants.toastTypeError,
          timeout: UiConstants.ToastTimeoutShort,
          title: 'Background error',
          body: 'Unexpected error happened in the background.'
        });
      });
    }
  }

  private removeUncaughtExceptionListener() {
    if (this.errorSubscription) {
      this.errorSubscription.unsubscribe();
    }
  }

  private isErrorToastActive() {
    return RuntimeConfiguration.getInstance().getShowBackgroundErrors(environment.showBackgroundErrors);
  }

  get adminVersion(): string {
    return this.appConfigurationService.getFormattedAppVersion();
  }

  get serverVersion(): string {
    return this.configService.getServerVersion();
  }

  get demoModeEnabled(): boolean {
    return this.configService.getConfiguration().feature_flags.demo_mode_enabled;
  }

  get inactivationPeriod(): string | undefined {
    return this.userDataToDisplay.inactivationTime ? this.userDataToDisplay.inactivationTime.fromNow() : undefined;
  }

}

export class PasswordChangeModel {
  prevPassword: string = '';
  password: string = '';
  confirmPassword: string = '';
}

class UserDataToDisplay {
  id?: number = undefined;
  userName: string = '';
  userGroupIds: number[] = [];
  userGroups: MultiselectOptionItem<number>[] = [];
  roles: OptionItem<number>[] = [];
  personName: string = '';
  emailAddress: string = '';
  type: UserProfileType = 'USER';
  profilePicture: any | undefined;
  inactivationTime?: OffsetDateTime;
}
