/* eslint-disable */
import {ConfigurationService} from '../core-ext/configuration.service';
import {Injectable} from '@angular/core';
import {AppType, AppTypeHelperService} from "./app-type-helper.service";
import {UserProfileType} from "../auth.service";

/* eslint-enable */

export enum LoginOptions {
  LDAP_USER,
  LOCAL_USER
}

@Injectable()
export class LoginOptionsHelper {

  public constructor(private configService: ConfigurationService,
                     private appTypeHelper: AppTypeHelperService) {
  }

  public getLoginOptions(): LoginOptions[] {
    const appType = this.appTypeHelper.appType();
    const cfg = this.configService.getConfiguration();
    const loginOptions: LoginOptions[] = [];
    let enabledMethods: UserProfileType[] = [];
    if (appType === AppType.WEBDMIN) {
      enabledMethods = cfg.enabled_auth_methods.webadmin;
    }
    if (appType === AppType.HELPDESK) {
      enabledMethods = cfg.enabled_auth_methods.helpdesk;
    }
    if (cfg.feature_flags.local_user_enabled && enabledMethods.includes("USER")) {
      loginOptions.push(LoginOptions.LOCAL_USER);
    }
    if (cfg.feature_flags.ldap_user_enabled && enabledMethods.includes("LDAP")) {
      loginOptions.push(LoginOptions.LDAP_USER);
    }
    return loginOptions;
  }

}
