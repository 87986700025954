/* eslint-disable */
import { Observable, Observer } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Order, OrderType, } from '../util/services';
import { MemStorage } from '../util/mem-storage';
import { Injectable } from '@angular/core';
import { UiConstants } from '../../util/core-utils';
import { LanguageCodeItem } from '../../util/document-group.utils';
import { DisabledItem } from '../../util/search-utils';
import { RuntimeConfiguration } from '../runtime-configuration';
import { LoggedInUserStorage } from '../util/storages';
import {DocumentGroup} from "./document-group.service";
/* eslint-enable */

@Injectable()
export class DocumentGroupSearchService {

  constructor() {
  }

  getSearchData(request: DocumentGroupSearch.SearchDataGetRequest): Observable<DocumentGroupSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<DocumentGroupSearch.SearchDataResult>) => {
      try {
        observer.next(storage.read(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  setSearchData(request: DocumentGroupSearch.SearchDataSetRequest): Observable<DocumentGroupSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<DocumentGroupSearch.SearchDataResult>) => {
      try {
        observer.next(storage.save(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  resetSearchData(request: DocumentGroupSearch.SearchDataGetRequest): Observable<DocumentGroupSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<DocumentGroupSearch.SearchDataResult>) => {
      try {
        observer.next(storage.reset(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  private createStorage(): DocumentGroupSearch.Storage {
    return new DocumentGroupSearch.Storage();
  }

}

export namespace DocumentGroupSearch {

  export interface SearchDataGetRequest {
  }

  export interface SearchDataSetRequest {
    searchData: SearchData;
  }

  export interface SearchDataResult {
    searchData: SearchData;
  }

  export interface SearchData {
    itemsPerPage: number;
    pageNumber: number;
    order: Order<DocumentGroup.OrderField>;
    id: number | null;
    name: string;
    code: string;
    languageCode?: LanguageCodeItem;
    disabled: DisabledItem | null;
  }

  interface StoredSearchRawDataBase {
    version: number;
  }

  interface StoredSearchRawData extends StoredSearchRawDataBase {
    itemsPerPage: number;
    pageNumber: number;
    order: Order<DocumentGroup.OrderField>;
    id: number | null;
    name: string;
    code: string;
    languageCode?: LanguageCodeItem;
    disabled: DisabledItem | null;
  }

  export class Storage {

    /**
     * Increment this version if you change the SearchData interface.
     */
    private static readonly VERSION: number = 4;

    private static readonly DEFAULT_SEARCH_DATA: SearchData = {
      itemsPerPage: UiConstants.pageNumbers[0],
      pageNumber: 1,
      order: {
        field: DocumentGroup.OrderField.ID,
        type: OrderType.DESC
      },
      id: null,
      name: '',
      code: '',
      languageCode: undefined,
      disabled: DisabledItem.active()
    };

    constructor() {
    }

    public read(request: SearchDataGetRequest): SearchDataResult {
      let rawBase: StoredSearchRawDataBase;
      try {
        const key = this.generateKey();
        const json = MemStorage.getItem(key);
        if (!json) {
          return this.createDefaultResult();
        }
        rawBase = JSON.parse(json);
      }
      catch (error) {
        return this.createDefaultResult();
      }
      if (Storage.VERSION === rawBase.version) {
        const raw: StoredSearchRawData = <StoredSearchRawData> rawBase;
        return this.fromRaw(raw);
      }
      return this.createDefaultResult();
    }

    public reset(request: SearchDataGetRequest): SearchDataResult {
      return this.save({
        searchData: Storage.DEFAULT_SEARCH_DATA,
      });
    }

    public save(request: SearchDataSetRequest): SearchDataResult {
      const key = this.generateKey();
      const raw = this.toRaw(request.searchData);
      const json = JSON.stringify(raw);
      MemStorage.setItem(key, json);
      return this.read(request);
    }

    private generateKey(): string {
      return 'UserId(' + LoggedInUserStorage.getInstance().getUserId() + ')-Search-Document-Group';
    }

    private createDefaultResult(): SearchDataResult {
      return {
        searchData: Storage.DEFAULT_SEARCH_DATA,
      };
    }

    private fromRaw(data: StoredSearchRawData): SearchDataResult {
      return {
        searchData: {
          itemsPerPage: data.itemsPerPage,
          pageNumber: data.pageNumber,
          order: data.order,
          id: data.id,
          name: data.name,
          code: data.code,
          languageCode: data.languageCode,
          disabled: data.disabled
        }
      };
    }

    private toRaw(data: SearchData): StoredSearchRawData {
      return {
        version: Storage.VERSION,
        itemsPerPage: data.itemsPerPage,
        pageNumber: data.pageNumber,
        order: data.order,
        id: data.id,
        name: data.name,
        code: data.code,
        languageCode: data.languageCode,
        disabled: data.disabled
      };
    }

  }

}
