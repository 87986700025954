<div bsModal [config]="UiConstants.modalConfig" #fieldDialog="bs-modal" (onHide)="onModalHide($event)"
     class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content" *ngIf="visible">
      <div class="modal-header">
        <h4 class="modal-title">{{dialogTitleDictionaryKey | translate}}</h4>
        <button type="button" class="close" (click)="closeDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="card-body p-0">

          <div class="row">

            <!-- region Title -->
            <div class="form-group col-md-12"
                 [ngClass]="{ 'has-danger': hasFieldError(Form.FieldValidatedField.TITLE) || hasLocalFieldError(title)}">
              <div class="row">
                <label class="col-md-4">{{'COMMON_NAME' | translate}}</label>
                <div class="col-md-8 m-0 p-0">
                  <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
                         maxlength="{{UiConstants.maximumVarcharLength}}"
                         [ngClass]="{ 'form-control-danger': hasFieldError(Form.FieldValidatedField.TITLE) || hasLocalFieldError(title) }"
                         [(ngModel)]="fieldModel.title"
                         (ngModelChange)="removeFieldError(Form.FieldValidatedField.TITLE)"
                         (blur)="onTitleBlur()"
                         name="title" id="title" #title="ngModel" required>
                  <div class="form-control-feedback" *ngIf="hasFieldError(Form.FieldValidatedField.TITLE)">
                    {{getFieldErrorText(Form.FieldValidatedField.TITLE)}}
                  </div>
                  <div class="form-control-feedback" *ngIf="hasLocalFieldError(title)">
                    {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                  </div>
                </div>
              </div>
            </div>
            <!-- endregion Title -->

            <!-- region field width - DISABLED -->
            <div class="form-group col-md-12">
              <div class="row">
                <label class="col-md-4">{{'FORM_ITEM_COMMON_FIELD_WIDTH' | translate}}</label>
                <select class="col-md-8 m-0 form-control" [(ngModel)]="fieldModel.fieldWidthType"
                        id="fieldWidth" name="fieldWidth" #fieldWidth="ngModel"
                        [disabled]="true">
                  <option *ngFor="let widthType of fieldWidthTypes"
                          [ngValue]="widthType">{{widthType.textDictionaryKey | translate}}</option>
                </select>
              </div>
            </div>
            <!-- endregion field width - DISABLED -->

          </div> <!-- ./row end -->

          <div class="row">

            <!-- region Description -->
            <div class="form-group col-md-12">
              <div class="row">
                <label class="col-md-4">{{'COMMON_DESCRIPTION' | translate}}</label>
                <input type="text" class="form-control col-md-8" [(ngModel)]="fieldModel.description"
                       maxlength="{{UiConstants.maximumVarcharLength}}"
                       #description="ngModel"
                       placeholder="{{'COMMON_DESCRIPTION' | translate}}"
                       name="description" id="description">
              </div>
            </div>
            <!-- endregion Description -->

          </div> <!-- ./row end -->

          <div class="row">

            <!-- region API export name -->
            <div class="form-group col-md-12"
                 [ngClass]="{ 'has-danger': hasFieldError(Form.FieldValidatedField.API_EXPORT_NAME) || hasLocalFieldError(api_export_name) }">
              <div class="row">
                <label class="col-md-4">{{'FORM_ITEM_COMMON_EXPORT_NAME' | translate}}</label>
                <div class="col-md-8 m-0 p-0">
                  <input type="text" class="form-control" [(ngModel)]="fieldModel.apiExportName"
                         maxlength="{{UiConstants.maximumVarcharLength}}"
                         #api_export_name="ngModel"
                         [ngClass]="{ 'form-control-danger': hasFieldError(Form.FieldValidatedField.API_EXPORT_NAME) || hasLocalFieldError(api_export_name) }"
                         (ngModelChange)="removeFieldError(Form.FieldValidatedField.API_EXPORT_NAME)"
                         placeholder="{{'FORM_ITEM_COMMON_EXPORT_NAME' | translate}}"
                         name="api_export_name" id="api_export_name"
                         required>
                  <div class="form-control-feedback" *ngIf="hasFieldError(Form.FieldValidatedField.API_EXPORT_NAME)">
                    {{getFieldErrorText(Form.FieldValidatedField.API_EXPORT_NAME)}}
                  </div>
                  <div class="form-control-feedback" *ngIf="hasLocalFieldError(api_export_name)">
                    {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                  </div>
                </div>
              </div>
            </div>
            <!-- endregion API export name -->

          </div> <!-- ./row end -->

          <div class="row">

            <!-- region PDF export name -->
            <div class="form-group col-md-12"
                 [ngClass]="{ 'has-danger': hasFieldError(Form.FieldValidatedField.PDF_EXPORT_NAME) }">
              <div class="row">
                <label class="col-md-4">{{'FORM_ITEM_COMMON_PDF_EXPORT_NAME' | translate}}</label>
                <div class="col-md-8 m-0 p-0">
                  <input type="text" class="form-control" [(ngModel)]="fieldModel.pdfExportName"
                         maxlength="{{UiConstants.maximumVarcharLength}}"
                         #pdfExportName="ngModel" name="pdfExportName" id="pdfExportName"
                         (ngModelChange)="removeFieldError(Form.FieldValidatedField.PDF_EXPORT_NAME)"
                         placeholder="{{'FORM_ITEM_COMMON_PDF_EXPORT_NAME' | translate}}"
                         [ngClass]="{ 'form-control-danger': hasFieldError(Form.FieldValidatedField.PDF_EXPORT_NAME) }">
                  <div class="form-control-feedback" *ngIf="hasFieldError(Form.FieldValidatedField.PDF_EXPORT_NAME)">
                    {{getFieldErrorText(Form.FieldValidatedField.PDF_EXPORT_NAME)}}
                  </div>
                </div>
              </div>
            </div>
            <!-- endregion PDF export name -->

          </div> <!-- ./row end -->

          <div class="row">

            <!-- region Field validation type (admin) -->
            <div class="form-group col-md-12">
              <div class="row">
                <label class="col-md-4">{{'FORM_ITEM_COMMON_ADMIN_FIELD_VALIDATION_TYPE' | translate}}</label>
                <select class="form-control col-md-8"
                        [(ngModel)]="fieldModel.adminFormFieldValidationType"
                        (change)="onAdminFormFieldValidationTypeChanged()"
                        #adminFormFieldValidationType="ngModel" id="adminFormFieldValidationType"
                        name="adminFormFieldValidationType">
                  <option *ngFor="let vt of fieldValidationTypes"
                          [ngValue]="vt">{{getFieldValidationTypeDictionaryKey(vt) | translate}}</option>
                </select>
              </div>
            </div>
            <!-- endregion Field validation type (admin) -->

            <!-- region Field validation type (mobile) -->
            <div class="form-group col-md-12">
              <div class="row">
                <label class="col-md-4">{{'FORM_ITEM_COMMON_MOBILE_FIELD_VALIDATION_TYPE' | translate}}</label>
                <select class="form-control col-md-8"
                        [(ngModel)]="fieldModel.mobileFormFieldValidationType"
                        (change)="onMobileFormFieldValidationTypeChanged()"
                        #adminFormFieldValidationType="ngModel" id="mobileFormFieldValidationType"
                        name="mobileFormFieldValidationType">
                  <option *ngFor="let vt of fieldValidationTypes" [ngValue]="vt">
                    {{getFieldValidationTypeDictionaryKey(vt) | translate}}
                  </option>
                </select>
              </div>
            </div>
            <!-- endregion Field validation type (mobile) -->

            <!-- region Field validation type (helpdesk) -->
            <div class="form-group col-md-12">
              <div class="row">
                <label class="col-md-4">{{'FORM_ITEM_COMMON_HELPDESK_FIELD_VALIDATION_TYPE' | translate}}</label>
                <select class="form-control col-md-8"
                        [(ngModel)]="fieldModel.helpdeskFormFieldValidationType"
                        #helpdeskFormFieldValidationType="ngModel" id="helpdeskFormFieldValidationType" name="helpdeskFormFieldValidationType">
                  <option *ngFor="let vt of fieldValidationTypes" [ngValue]="vt">{{getFieldValidationTypeDictionaryKey(vt) | translate}}</option>
                </select>
              </div>
            </div>
            <!-- endregion Field validation type (helpdesk) -->

          </div> <!-- ./row end -->

          <div class="row">

            <!-- region usable stock type -->
            <div class="form-group col-md-12">
              <div class="row">
                <label class="col-md-4">
                  {{'FORM_ITEM_STOCK_INTAKE_STOCK_TYPE' | translate}}
                </label>
                <div class="col-md-8 px-0">
                  <angular2-multiselect
                    class="col-md-8"
                    [(ngModel)]="fieldModel.stockType"
                    [data]="stockTypes"
                    [settings]="typeDropdownSettings"
                    (onSelect)="destinationStockTypeChanged($event)"
                    (onDeSelect)="destinationStockTypeChanged()"
                    #stockTypeInput="ngModel"
                    id="stockType"
                    name="stockType">
                  </angular2-multiselect>
                </div>
              </div>
            </div>
            <!-- endregion usable stock type -->
            <!-- region stock destination filter -->
            <div class="form-group col-md-12">
              <div class="row">
                <label class="col-md-4">
                  {{'FORM_ITEM_STOCK_STOCK_DESTINATION_FILTER' | translate}}
                  <i class="icomoon icomoon-info info-icon" popover="{{'FORM_ITEM_STOCK_STOCK_DESTINATION_FILTER_HINT' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-8 px-0">
                  <angular2-multiselect
                    class="col-md-8"
                    [(ngModel)]="fieldModel.stockDestinationFilters"
                    [data]="destinationStocks"
                    [settings]="stockDropdownSettings"
                    (onRemoteSearch)="loadDestinationStocks($event.target.value, fieldModel.stockTypeName)"
                    #stockDestinationFilters="ngModel"
                    id="stockDestinationFilters"
                    name="stockDestinationFilters"
                    validateEnabledItems>
                  </angular2-multiselect>
                </div>
              </div>
            </div>
            <!-- endregion stock destination filter -->
          </div>
          <div class="row">

            <!-- region usable stock categories -->
            <div class="form-group col-md-12">
              <div class="row">
                <label class="col-md-4">
                  {{'FORM_ITEM_STOCK_USABLE_STOCK_CATEGORIES' | translate}}
                  <i class="icomoon icomoon-info info-icon"
                     popover="{{'FORM_ITEM_STOCK_USABLE_STOCK_CATEGORIES_HINT' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-8 px-0">
                  <angular2-multiselect
                    class="col-md-8"
                    [(ngModel)]="fieldModel.usableStockItemCategories"
                    [data]="stockItemCategoryItems"
                    [settings]="categoryDropdownSettings"
                    (onRemoteSearch)="loadStockItemCategories($event.target.value)"
                    #usableStockItemCategories="ngModel"
                    id="usableStockItemCategories"
                    name="usableStockItemCategories"
                    validateEnabledItems>
                  </angular2-multiselect>
                </div>
              </div>
            </div>
            <!-- endregion usable stock categories -->
          </div> <!-- ./row end -->

          <div class="row">
            <!-- region clearOnReopen edit -->
            <div class="form-group col-md-12">
              <div class="row">
                <label class="col-md-4">{{'FORM_ITEM_CLEAR_ON_REOPEN' | translate}}</label>
                <label class="switch switch-text switch-info mb-0">
                  <input type="checkbox" class="switch-input" [(ngModel)]="fieldModel.clearOnReopen"
                         #clearOnReopen="ngModel" id="clearOnReopen" name="clearOnReopen">
                  <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                  <span class="switch-handle"></span>
                </label>
              </div>
              <!-- endregion clearOnReopen edit -->
            </div>
          </div>

        </div> <!-- ./card-body end -->
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeDialog()">{{'COMMON_BUTTON_CANCEL' | translate}}
        </button>
        <button type="button" class="btn btn-primary" #functionButton (click)="saveField()">{{'COMMON_BUTTON_SAVE' |
          translate}}
        </button>
      </div>

    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
