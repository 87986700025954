/* eslint-disable */
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormServiceCode, FormServiceFactory } from '../../../lib/form/form-service-factory';
import { Form } from '../../../lib/form/form.service';
import {
  FieldDataTypeDescriptor,
  FieldDataTypeSelectors,
  FormEditContext,
  FormEditView,
  FormGroupModel,
  FormRights,
} from '../../../util/form/form-utils';
import { FormEditDialogContainerComponent } from './dialog-container/form-edit-dialog-container.component';
import { GrantedPermissionSet, RightResolver, RightService, } from '../../../lib/right.service';
import { FieldWidthType } from '../../../util/form/form-field-width-type';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { UiConstants } from '../../../util/core-utils';
import { StringKey } from '../../../app.string-keys';
import { ToasterService } from '../../../fork/angular2-toaster/src/toaster.service';
import { TranslateService } from '@ngx-translate/core';
import { FormEditCreateUpdateDateFieldDialogComponent } from './dialogs/fields/date/create-update/form-edit-create-update-date-field-dialog.component';
import { fromEvent } from 'rxjs';
import FieldDataTypeSelector = Form.FieldDataTypeSelector;
import FormFieldValidationType = Form.FormFieldValidationType;
import { BadgeStyle } from '../../../shared/table-badge/badge-style';

/* eslint-enable */

@Component({
  selector: 'app-form-edit',
  templateUrl: 'form-edit.component.html',
  styleUrls: ['form-edit.component.scss'],
})
export class FormEditComponent implements FormEditView, OnInit, AfterViewInit {

  Form = Form;

  @Input()
  formServiceCode: FormServiceCode;

  @Input()
  formRights: FormRights;

  @Input()
  parentId: number;

  @Input()
  dialogContainer: FormEditDialogContainerComponent;

  @Output()
  public readonly formLoad: EventEmitter<Form.Form> = new EventEmitter<Form.Form>();

  model: Model = new Model();
  formRightModel: FormRightModel = FormRightModel.empty();

  displayDisabledFields: boolean = false;
  dragFocus: boolean = false;

  dragging = false;
  private dragStartGroup: FormGroupModel | undefined = undefined;
  private dragStartField: Form.Field | undefined = undefined;

  private formService: Form.Service;
  private fieldDragStartGroup?: FormGroupModel;

  public constructor(private rightService: RightService,
                     private toasterService: ToasterService,
                     private translateService: TranslateService,
                     private fieldDataTypeSelectors: FieldDataTypeSelectors,
                     private formServiceFactory: FormServiceFactory) {
  }

  ngOnInit(): void {
    this.formService = this.formServiceFactory.createService(this.formServiceCode);
    this.rightService.getRightResolver().subscribe(
      (rightResolver: RightResolver) => {
        this.formRightModel = FormRightModel.of(rightResolver, this.formRights);
        this.loadForm();
        this.loadFormFieldDescriptors();
      }
    );
  }

  ngAfterViewInit(): void {
    this.dialogContainer.registerContext(this.createContext());
    this.dialogContainer.registerFormFieldDialogs(
      this.createFormFieldCreateDialogs(), this.createFormFieldUpdateDialogs()
    );
  }

  get groupDragAndDropEnabled(): boolean {
    if (this.formRightModel.formGroupMove.hasRight()) {
      return this.model.groupDragAndDropEnabled;
    }
    return false;
  }

  get fieldDragAndDropEnabled(): boolean {
    if (this.formRightModel.formFieldMove.hasRight()) {
      return this.model.groupDragAndDropEnabled;
    }
    return false;
  }

  getFieldTypeName(field: Form.Field): string {
    const d = this.fieldDataTypeSelectors.findDescriptor(field.dataTypeSelector);
    if (d) {
      return d.formFieldNameDictionaryKey;
    }
    return '';
  }

  reloadForm(): void {
    const previousScroll = window.scrollY;
    this.loadForm();
    const scrollSubscription = fromEvent(window, 'scroll').subscribe(event => {
      scrollSubscription.unsubscribe();
      window.scrollTo(window.scrollX, previousScroll);
    });
  }

  showCreateGroupDialog(): void {
    this.dialogContainer.showCreateGroupDialog();
  }

  showUpdateGroupDialog(group: FormGroupModel): void {
    this.dialogContainer.showUpdateGroupDialog(group);
  }

  showCreateFieldDialog(group: FormGroupModel, menuOption: FieldDataTypeDescriptor): void {
    this.dialogContainer.showCreateFieldDialog(this.model, group, menuOption);
  }

  showUpdateFieldDialog(group: FormGroupModel, field: Form.Field, clone?: boolean): void {
    this.dialogContainer.showUpdateFieldDialog(this.model, group, field, clone);
  }

  groupDragStart(group: FormGroupModel, index: number): void {
    document.getElementById('group_' + index)!.classList.remove('card-accent-primary');
    document.getElementById('group_' + index)!.classList.add('card-accent-success');
  }

  groupDragEnd(group: FormGroupModel, index: number): void {
    document.getElementById('group_' + index)!.classList.add('card-accent-primary');
    document.getElementById('group_' + index)!.classList.remove('card-accent-success');
    const endIndex = this.model.formGroups.indexOf(group);
    this.moveGroup(group, endIndex);
  }

  dragStarted(group: FormGroupModel, field?: Form.Field): void {
    this.dragStartGroup = group;
    this.dragStartField = field ? field : undefined;
    if (!this.dragStartField) {
      document.getElementById('group_' + group.groupId)!.classList.remove('card-accent-primary');
      document.getElementById('group_' + group.groupId)!.classList.add('card-success');
    }
    this.dragging = true;
  }

  dragEnded(): void {
    if (!this.dragStartField) {
      document.getElementById('group_' + this.dragStartGroup!.groupId)!.classList.add('card-accent-primary');
      document.getElementById('group_' + this.dragStartGroup!.groupId)!.classList.remove('card-success');
    }
    this.dragStartGroup = undefined;
    this.dragStartField = undefined;
    this.dragging = false;
  }

  fieldEntered(): void {
    this.model.groupDragAndDropEnabled = false;
  }

  fieldLeaved(): void {
    this.model.groupDragAndDropEnabled = true;
  }

  fieldDragStart(group: FormGroupModel): void {
    this.fieldDragStartGroup = group;
  }

  fieldDragEnd(field: Form.Field, newGroup: FormGroupModel): void {
    const oldGroup = this.fieldDragStartGroup!;
    const toIndex = newGroup.fields.indexOf(field);
    this.moveField(oldGroup, field.fieldId, toIndex, newGroup);
  }

  onFormGroupDropped(event: CdkDragDrop<FormGroupModel[]>) {
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    this.moveGroup(event.container.data[event.currentIndex], event.currentIndex);
  }

  onFormGroupFieldDropped(event: CdkDragDrop<Form.Field[]>) {
    // In case the destination container is different from the previous container, we
    // need to transfer the given shipment to the target data array. This happens if
    // a shipment has been dropped on a different track.
    let previousIndex = event.previousIndex;
    let currentIndex = event.currentIndex;
    if (!this.displayDisabledFields) {
      const previousEnabledItems = event.previousContainer.data.filter(f => !f.disabled);
      const currentEnabledItems = event.container.data.filter(f => !f.disabled);
      if (event.previousIndex !== 0) {
        const precedingItemBeforeDrag = previousEnabledItems[previousIndex - 1];
        previousIndex = event.previousContainer.data.findIndex(f => f.fieldId === precedingItemBeforeDrag.fieldId)! + 1;
      }
      if (event.currentIndex !== 0) {
        const precedingItemAfterDrag = currentEnabledItems[currentIndex - 1];
        currentIndex = event.container.data.findIndex(f => f.fieldId === precedingItemAfterDrag.fieldId)! + 1;
      }
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, previousIndex, currentIndex);
    }
    else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        previousIndex,
        currentIndex);
    }
    this.moveField(
      this.model.formGroups.find((f) => f.groupId === +event.previousContainer.id)!,
      event.item.data.fieldId,
      currentIndex,
      this.model.formGroups.find((f) => f.groupId === +event.container.id)!);
  }

  get groupIds(): string[] {
    return this.model.formGroups.map(group => group.groupId + '');
  }

  setFieldDisabled(group: FormGroupModel, field: Form.Field, disabled: boolean): void {
    if (disabled) {
      if (this.activatesOtherField(field)) {
        this.toasterService.pop({
          timeout: UiConstants.ToastTimeoutLong,
          type: UiConstants.toastTypeError,
          title: this.translateService.instant(StringKey.FORM_EDITOR_FIELD_CANNOT_BE_DISABLED_ACTIVATOR_ERROR_TITLE),
          body: this.translateService.instant(StringKey.FORM_EDITOR_FIELD_CANNOT_BE_DISABLED_ACTIVATOR_ERROR_MESSAGE)
        });
        return;
      } else if (this.activatedByOtherFields(field)) {
        this.toasterService.pop({
          timeout: UiConstants.ToastTimeoutLong,
          type: UiConstants.toastTypeError,
          title: this.translateService.instant(StringKey.FORM_EDITOR_FIELD_CANNOT_BE_DISABLED_ACTIVATEE_ERROR_TITLE),
          body: this.translateService.instant(StringKey.FORM_EDITOR_FIELD_CANNOT_BE_DISABLED_ACTIVATEE_ERROR_MESSAGE)
        });
        return;
      }
    }
    this.formService.disableField({
      parentId: this.parentId,
      groupId: group.groupId,
      fieldId: field.fieldId,
      disabled: disabled
    }).subscribe(
      (response) => {
        this.loadForm();
      },
      (error) => {
        this.loadForm();
      }
    );
  }

  private activatesOtherField(field: Form.Field): boolean {
    switch (field.dataTypeSelector) {
      case Form.FieldDataTypeSelector.BOOLEAN:
        return field.dataType.booleanAttributes!.enableOtherFields;
      case Form.FieldDataTypeSelector.LIST_ITEM:
        return field.dataType.listItemAttributes!.fieldActivationEnabled;
      case Form.FieldDataTypeSelector.LIST_MULTI_ITEM:
        return field.dataType.listMultiItemAttributes!.fieldActivationEnabled;
      default:
        return false;
    }
  }

  private activatedByOtherFields(field: Form.Field): boolean {
    const fieldId = field.fieldId;
    for (const group of this.model.formGroups) {
      for (const field of group.fields) {
        if (FieldDataTypeSelectors.isActivatorField(field)) {
          const fieldIds = FieldDataTypeSelectors.getEnabledByThisFieldIds(field);
          if (fieldIds.includes(fieldId)) {
            return true;
          }
        }
      }
    }
    return false;
  }

  setGroupDisabled(group: FormGroupModel, disabled: boolean): void {
    if (disabled) {
      if (this.groupActivatesOtherField(group)) {
        this.toasterService.pop({
          timeout: UiConstants.ToastTimeoutLong,
          type: UiConstants.toastTypeError,
          title: this.translateService.instant(StringKey.FORM_EDITOR_GROUP_CANNOT_BE_DISABLED_ACTIVATOR_ERROR_TITLE),
          body: this.translateService.instant(StringKey.FORM_EDITOR_GROUP_CANNOT_BE_DISABLED_ACTIVATOR_ERROR_MESSAGE)
        });
        return;
      } else if (this.groupActivatedByOtherFields(group)) {
        this.toasterService.pop({
          timeout: UiConstants.ToastTimeoutLong,
          type: UiConstants.toastTypeError,
          title: this.translateService.instant(StringKey.FORM_EDITOR_GROUP_CANNOT_BE_DISABLED_ACTIVATEE_ERROR_TITLE),
          body: this.translateService.instant(StringKey.FORM_EDITOR_GROUP_CANNOT_BE_DISABLED_ACTIVATEE_ERROR_MESSAGE)
        });
        return;
      }
    }
    this.formService.disableGroup({
      parentId: this.parentId,
      groupId: group.groupId,
      disabled: disabled
    }).subscribe(
      (response) => {
        this.loadForm();
      },
      (error) => {
        this.loadForm();
      }
    );
  }

  private groupActivatesOtherField(group: FormGroupModel): boolean {

    let hasActivation: boolean = false;
    group.fields.forEach(field => {
      if (field) {
        switch (field.dataTypeSelector) {
          case Form.FieldDataTypeSelector.BOOLEAN:
            hasActivation = field.dataType.booleanAttributes!.enableOtherFields;
            break;
          case Form.FieldDataTypeSelector.LIST_ITEM:
            hasActivation = field.dataType.listItemAttributes!.fieldActivationEnabled;
            break;
          case Form.FieldDataTypeSelector.LIST_MULTI_ITEM:
            hasActivation = field.dataType.listMultiItemAttributes!.fieldActivationEnabled;
            break;
        }
      }
    });
    return hasActivation;
  }

  private groupActivatedByOtherFields(group: FormGroupModel): boolean {
    const fieldIds: number[] = group.fields.map(f => f.fieldId);
    for (const g of this.model.formGroups) {
      for (const field of g.fields) {
        if (FieldDataTypeSelectors.isActivatorField(field)) {
          const enabledFieldIds = FieldDataTypeSelectors.getEnabledByThisFieldIds(field);
          const enabledGroupIds = FieldDataTypeSelectors.getEnabledByThisFieldGroupIds(field);
          if (enabledGroupIds.includes(group.groupId)) {
            return true;
          }
          if (enabledFieldIds.filter(i => fieldIds.includes(i)).length > 0) {
            return true;
          }
        }
      }
    }
    return false;
  }

  private moveGroup(group: FormGroupModel, index: number, reload?: boolean): void {
    this.formService.moveGroup({
      parentId: this.parentId,
      groupId: group.groupId,
      index: index
    }).subscribe(
      (response) => {
        // reload the form if the group was moved by button click
        // do nothing if group was dragged, because the UI already reflects the correct order
        if (reload) {
          this.loadForm();
        }
      },
      (error) => {
        this.loadForm();
      }
    );
  }

  private moveField(oldGroup: FormGroupModel, fieldId: number, index: number, newGroup: FormGroupModel) {
    this.formService.moveFieldToGroup({
      parentId: this.parentId,
      groupId: oldGroup.groupId,
      fieldId: fieldId,
      index: index,
      newGroupId: newGroup.groupId
    }).subscribe(
      (response) => {
        // do nothing if move succeeded
      },
      (error) => {
        this.loadForm();
      }
    );
  }

  private loadFormFieldDescriptors() {
    if (!this.formRightModel.formRead.hasRight()) {
      return;
    }
    this.model.formFieldDescriptors = this.fieldDataTypeSelectors.descriptorArray;
    this.model.createGroupMenuDescriptors = this.model.formFieldDescriptors.filter((r) => {
      return this.fieldDataTypeSelectors.isValidInService(r, this.formServiceCode);
    });
  }

  private loadForm(): void {
    if (!this.formRightModel.formRead.hasRight()) {
      return;
    }
    this.formService.getForm({
      parentId: this.parentId,
    }).subscribe(
      (form: Form.Form) => {
        this.formLoad.emit(form);
        this.applyFormToModel(form);
      },
    );
  }

  private applyFormToModel(form: Form.Form) {
    this.model.formGroups = form.groups.toArray().map((g) => this.groupToModel(g));
  }

  private groupToModel(g: Form.Group): FormGroupModel {
    return {
      groupId: g.groupId,
      creationTime: g.creationTime,
      updateTime: g.updateTime,
      title: g.title,
      shortTitle: g.shortTitle,
      apiExportName: g.apiExportName,
      disabled: g.disabled,
      fields: g.fields.toArray(),
    };
  }

  private createContext(): FormEditContext {
    return {
      formView: this,
      formService: this.formService,
      parentId: this.parentId
    };
  }

  private parsePercentValueToText(width: number): FieldWidthType | undefined {
    return FieldWidthType.fromPercentValue(width);
  }

  private createFormFieldCreateDialogs() {
    return this.fieldDataTypeSelectors.descriptorArray.map((d) => {
      return d.formFieldDialogFactory.createCreateView();
    });
  }

  private createFormFieldUpdateDialogs() {
    return this.fieldDataTypeSelectors.descriptorArray.map((d) => {
      return d.formFieldDialogFactory.createUpdateView();
    });
  }

  getIconClassForField(dataTypeSelector: FieldDataTypeSelector): IconObject {
    switch (dataTypeSelector) {
      case FieldDataTypeSelector.STRING:
        return {icon : 'icomoon-text-body'};
      case FieldDataTypeSelector.NUMBER:
        return {icon : 'icomoon-integer'};
      case FieldDataTypeSelector.DECIMAL:
        return {icon : 'icomoon-fraction'};
      case FieldDataTypeSelector.DATE:
        return {icon : 'icomoon-calendar'};
      case FieldDataTypeSelector.DATE_TIME:
        return {icon : 'icomoon-date-time'};
      case FieldDataTypeSelector.BOOLEAN:
        return {icon : 'icomoon-checkbox'};
      case FieldDataTypeSelector.LIST_ITEM:
        return {icon : 'icomoon-multiselect-3'};
      case FieldDataTypeSelector.LIST_MULTI_ITEM:
        return {icon : 'icomoon-multiselect-2'};
      case FieldDataTypeSelector.EMAIL_ADDRESS:
        return {icon : 'icomoon-message-unread'};
      case FieldDataTypeSelector.PHONE_NUMBER:
        return {icon : 'icomoon-phone'};
      case FieldDataTypeSelector.MASTER_DATA:
        return {icon : 'icomoon-sidebar-masterdata'};
      case FieldDataTypeSelector.STOCK:
      case FieldDataTypeSelector.STOCK_INTAKE:
      case FieldDataTypeSelector.STOCK_MOVE:
        return {icon: 'icomoon-sidebar-stock'};
      case FieldDataTypeSelector.READONLY_TEXT:
        return {icon: 'icomoon-readonly-text'};
      case FieldDataTypeSelector.READONLY_HTML:
        return {icon : 'icomoon-readonly'};
      case FieldDataTypeSelector.READONLY_PICTURE:
        return {icon : 'icomoon-file-image'};
      case FieldDataTypeSelector.FORM_TABLE:
        return {icon : 'icomoon-administration-survey'};
      case FieldDataTypeSelector.PAYMENT_TYPE:
        return {icon : 'icomoon-payment-type'};
      case FieldDataTypeSelector.CUSTOMER:
        return {icon : 'icomoon-customer'};
      case FieldDataTypeSelector.DOCUMENT:
        return {icon : 'icomoon-documents-file'};
      case FieldDataTypeSelector.PHOTO:
        return {icon : 'icomoon-attachments'};
      case FieldDataTypeSelector.PROCESS_ORDER_STOCK_OUTTAKE:
        return {icon : 'icomoon-process-order-stock-outtake'};
      case FieldDataTypeSelector.PROCESS_ORDER_STOCK_OUTTAKE_CHECK:
        return {icon : 'icomoon-process-order-stock-outtake-check'};
      case FieldDataTypeSelector.PROCESS_ORDER_PACKAGING:
        return {icon : 'icomoon-process-order-packaging'};
      case FieldDataTypeSelector.INVOICE:
        return {icon : 'icomoon-invoice'};
    }
    return {icon : 'icomoon-exit'};
  }

  getValidationBadge(formFieldValidationType: FormFieldValidationType) {
    switch (formFieldValidationType) {
      case Form.FormFieldValidationType.OPTIONAL:
      return {style: BadgeStyle.SUCCESS, stringKey: 'FORM_OPTIONAL'};
      case Form.FormFieldValidationType.REQUIRED:
        return {style: BadgeStyle.WARNING, stringKey: 'FORM_REQUIRED'};
      case Form.FormFieldValidationType.READONLY:
        return {style: BadgeStyle.PRIMARY, stringKey: 'FORM_READONLY'};
      case Form.FormFieldValidationType.HIDDEN:
        return {style: BadgeStyle.SECONDARY, stringKey: 'FORM_HIDDEN'};

    }
  }

  isOptional(formFieldValidationType: FormFieldValidationType): boolean {
    return formFieldValidationType === FormFieldValidationType.OPTIONAL;
  }

  isRequired(formFieldValidationType: FormFieldValidationType): boolean {
    return formFieldValidationType === FormFieldValidationType.REQUIRED;
  }

  isReadonly(formFieldValidationType: FormFieldValidationType): boolean {
    return formFieldValidationType === FormFieldValidationType.READONLY;
  }

  isHidden(formFieldValidationType: FormFieldValidationType): boolean {
    return formFieldValidationType === FormFieldValidationType.HIDDEN;
  }

  isReadonlyType(dataTypeSelector: FieldDataTypeSelector): boolean {
    if (dataTypeSelector === FieldDataTypeSelector.READONLY_HTML || dataTypeSelector === FieldDataTypeSelector.READONLY_TEXT) {
      return true;
    }
    return false;
  }

  isCloneableField(field: Form.Field): boolean {
    switch (field.dataTypeSelector) {
      case FieldDataTypeSelector.PAYMENT_TYPE:
        return false;
      case FieldDataTypeSelector.DATE:
        return !(field.apiExportName === FormEditCreateUpdateDateFieldDialogComponent.DEADLINE_API_NAME
          || field.apiExportName === FormEditCreateUpdateDateFieldDialogComponent.DELIVERY_API_NAME);
      default:
        return true;
    }
  }

  countDisabledItemsInGroup(group: Form.Field[]): number {
    let disabledItemsCount = 0;
    group.forEach((field) => {
      if (field.disabled) {
        disabledItemsCount++;
      }
    });
    return disabledItemsCount;
  }

  onDragEnter() {
    this.dragFocus = true;
  }

  onDragLeave() {
    this.dragFocus = false;
  }

}

export class Model {
  groupDragAndDropEnabled: boolean = true;
  formFieldDescriptors: FieldDataTypeDescriptor[] = [];
  createGroupMenuDescriptors: FieldDataTypeDescriptor[] = [];
  formGroups: FormGroupModel[] = [];
}

class FormRightModel {

  public formRead: GrantedPermissionSet = GrantedPermissionSet.empty();
  public formGroupUpdate: GrantedPermissionSet = GrantedPermissionSet.empty();
  public formFieldUpdate: GrantedPermissionSet = GrantedPermissionSet.empty();
  public formGroupCreate: GrantedPermissionSet = GrantedPermissionSet.empty();
  public formFieldCreate: GrantedPermissionSet = GrantedPermissionSet.empty();
  public formGroupDisable: GrantedPermissionSet = GrantedPermissionSet.empty();
  public formFieldDisable: GrantedPermissionSet = GrantedPermissionSet.empty();
  public formGroupMove: GrantedPermissionSet = GrantedPermissionSet.empty();
  public formFieldMove: GrantedPermissionSet = GrantedPermissionSet.empty();

  public static empty(): FormRightModel {
    return new FormRightModel();
  }

  public static of(rightResolver: RightResolver, rights: FormRights): FormRightModel {
    const m = new FormRightModel();
    m.formRead = rightResolver.getGrantedPermissions(rights.formRead);
    m.formGroupUpdate = rightResolver.getGrantedPermissions(rights.formGroupUpdate);
    m.formFieldUpdate = rightResolver.getGrantedPermissions(rights.formFieldUpdate);
    m.formGroupCreate = rightResolver.getGrantedPermissions(rights.formGroupCreate);
    m.formFieldCreate = rightResolver.getGrantedPermissions(rights.formFieldCreate);
    m.formGroupDisable = rightResolver.getGrantedPermissions(rights.formGroupDisable);
    m.formFieldDisable = rightResolver.getGrantedPermissions(rights.formFieldDisable);
    m.formGroupMove = rightResolver.getGrantedPermissions(rights.formGroupMove);
    m.formFieldMove = rightResolver.getGrantedPermissions(rights.formFieldMove);
    return m;
  }

  private construct() {
  }

}

interface IconObject {
  icon: string;
}
