/* eslint-disable */
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { EmptyMessage } from '../../../../lib/util/messages';
import { QueryFieldModel, SelectUtils, UiConstants } from '../../../../util/core-utils';
import { AuthService } from '../../../../lib/auth.service';
import { Arrays } from '../../../../lib/util/arrays';
import { TranslateService } from '@ngx-translate/core';
import { DocumentText } from '../../../../lib/document/document-text.service';
import { DocumentLanguageItem, DocumentTextListModel, DocumentTextSearchModel } from '../../../../util/document/document-utils';
import { DocumentLanguageUtil } from '../../../../util/document/document-language-utils';
import { DocumentLanguageService } from '../../../../lib/document/document-language.service';
import { DocumentGroup } from '../../../../lib/document/document-group.service';
import { DocumentGroupService } from '../../../../lib/document/document-group.service';
import { DocumentGroupItem } from '../../../../util/document/document-file-utils';
import { RightModel } from '../../../../app.rights';
import { RightResolver, RightService } from '../../../../lib/right.service';
import { DocumentLink, DocumentLinkList, DocumentLinkService } from '../../../../lib/document/document-link.service';
import { DocumentLinkSearch, DocumentLinkSearchService } from '../../../../lib/document/document-link-search.service';
import { combineLatest, Observable } from 'rxjs';
import { OrderType, ResourceQueryResult, Services } from '../../../../lib/util/services';
import { Set } from 'immutable';
import { StateName } from '../../../../app.state-names';
import { BreadcrumbParent } from '../../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { DocumentFile } from '../../../../lib/document/document-file.service';
import { DisabledEnum, DisabledItem } from '../../../../util/search-utils';
import { DropdownItemType } from '../../../../shared/dropdown/dropdown-item/dropdown-item-type';
/* eslint-enable */

@Component({
  selector: 'app-document-link-list',
  templateUrl: 'document-link-list.component.html',
  styleUrls: ['document-link-list.component.scss']
})
export class DocumentLinkListComponent implements OnInit, AfterViewInit, OnDestroy {

  DropdownItemType = DropdownItemType;

  @ViewChild('searchIcon', { static: true }) searchIcon: ElementRef;


  queryModel: QueryFieldModel<DocumentLinkList.OrderField> = new QueryFieldModel(DocumentLinkList.OrderField.ID, OrderType.DESC);
  documentListModel: DocumentTextListModel[] = [];
  documentGroupList: DocumentGroupItem[] = [];
  documentLanguageList: DocumentLanguageItem[] = [];
  disabledItems: DisabledItem[] = [];
  searchResetClicked = false;
  searchModel: DocumentTextSearchModel = new DocumentTextSearchModel();
  showSearch: boolean = false;
  rightModel: RightModel = RightModel.empty();
  breadcrumbSelf: string;
  breadcrumbParents: BreadcrumbParent[] = [];
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  storedSearchData: DocumentLinkSearch.SearchDataResult;
  SelectUtils = SelectUtils;
  DocumentLinkList = DocumentLinkList;
  UiConstants = UiConstants;

  ngOnInit() {
    this.translateService.get('COMMON_DOCUMENTS').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.DOCUMENT_DASHBOARD});
      }
    );
    this.translateService.get('MENU_NAVBAR_DOCUMENT_LINK').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.loadRightModels();
  }

  ngAfterViewInit(): void {
    this.loadSearch(() => {
      this.showSearch = !this.searchModel.isEmpty();
      this.initDisabledOptions(DisabledEnum.FALSE);
      this.loadDefaultDocumentGroup(() => {
        this.loadDocumentGroups(() => {
          this.loadDocumentLanguageList(() => {
            this.loadList();
          }, this.storedSearchData);
        }, this.storedSearchData);
      });
    });
  }

  private loadSearch(completion: () => void) {
    const obs: Observable<SearchLoadResult> = combineLatest(
      this.documentLinkSearchService.getSearchData({}),
      (storedSearchData: DocumentLinkSearch.SearchDataResult) => {
        const result: SearchLoadResult = {
          storedSearchData: storedSearchData
        };
        return result;
      }
    );
    obs.subscribe(
      (result: SearchLoadResult) => {
        this.postInitSearch(result.storedSearchData);
        this.storedSearchData = result.storedSearchData;
        completion();
      }
    );
  }


  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  private postInitSearch(storedSearchData: DocumentLinkSearch.SearchDataResult) {
    this.queryModel.itemsPerPage = storedSearchData.searchData.itemsPerPage;
    this.queryModel.currentPage = storedSearchData.searchData.pageNumber;
    this.queryModel.setOrder(storedSearchData.searchData.order);
    this.searchModel.name = storedSearchData.searchData.name;
    this.searchModel.code = storedSearchData.searchData.code;
    this.searchModel.value = storedSearchData.searchData.value;
    this.searchModel.language_code = storedSearchData.searchData.language_code;
    this.searchModel.document_group = storedSearchData.searchData.document_group;
    this.searchModel.disabled = storedSearchData.searchData.disabled;
  }


  onSearchClicked() {
    this.loadList();
  }

  onSearchReset() {
    this.documentLinkSearchService.resetSearchData().subscribe(
      (result) => {
        this.searchResetClicked = true;
        this.loadSearch(() => {
          this.showSearch = true;
          this.loadList(1);
          this.translateService.get('DOCUMENT_GROUP_FILTER_ALL').subscribe(
            (text: string) => {
              const allGroup: DocumentGroupItem = {
                id: null,
                text: text
              };
              this.searchModel.document_group = allGroup;
            }
          );
          this.searchModel.language_code = this.documentLanguageUtil.createDefaultLanguageCode();
        });
      }
    );
  }

  private saveSearch() {
    const request = {
      searchData: {
        itemsPerPage: this.queryModel.itemsPerPage,
        pageNumber: this.queryModel.currentPage,
        order: this.queryModel.getOrder(),
        name: this.searchModel.name,
        code: this.searchModel.code,
        value: this.searchModel.value,
        language_code: this.searchModel.language_code,
        document_group: this.searchModel.document_group,
        disabled: this.searchModel.disabled,
      }
    };
    this.documentLinkSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  ngOnDestroy() {
    this.saveSearch();
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: DocumentLinkList.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  private initDisabledOptions(initValue: DisabledEnum) {
    this.disabledItems = [];
    const disabledEnums: DisabledEnum[] = [DisabledEnum.NONE, DisabledEnum.FALSE, DisabledEnum.TRUE];
    Arrays.iterateByIndex(disabledEnums, (key) => {
      const item = new DisabledItem();
      item.id = key;
      this.translateService.get('COMMON_VALUE_DISABLED_ENUM_' + key).subscribe(
        (text: string) => {
          item.text = text;
        }
      );
      this.disabledItems.push(item);
      if (key === initValue) {
        this.searchModel.disabled = item;
      }
    });
  }


  private loadList(pageNumber?: number) {
    const disabled: boolean | undefined = !this.searchModel.disabled ||
    this.searchModel.disabled.id === DisabledEnum.NONE ?
      undefined : this.searchModel.disabled.id === DisabledEnum.TRUE;

    let languageCode: string | null = null;
    if (this.searchModel.language_code) {
      languageCode = this.searchModel.language_code.id;
    }

    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();

    this.documentTextService.query({
      name: (this.searchModel.name) ? this.searchModel.name : undefined,
      code: (this.searchModel.code) ? this.searchModel.code : undefined,
      value: (this.searchModel.value) ? this.searchModel.value : undefined,
      language_code: (languageCode) ? languageCode : undefined,
      document_group_id: (this.searchModel.document_group && this.searchModel.document_group.id)
        ? this.searchModel.document_group.id : undefined,
      disabled: disabled,

      page_number: requestedPage,
      number_of_items: this.queryModel.itemsPerPage,
      order: Services.createOrderFieldParameter(DocumentLinkList.Keys.toOrderFieldKey, Set.of(order)),
    })
      .subscribe(
      (result: ResourceQueryResult<DocumentText>) => {
        const listModels: DocumentTextListModel[] = [];

        Arrays.iterateByIndex(result.items, (documentText: DocumentLink) => {
          const listModel = new DocumentTextListModel();
          listModel.id = documentText.id;
          listModel.code = documentText.code;
          listModel.name = documentText.name;
          listModel.value = documentText.value;
          listModel.comment = documentText.comment;
          listModel.language_code = documentText.language_code;
          listModel.creation_time = documentText.creation_time;
          listModel.update_time = documentText.update_time;
          listModel.disabled = documentText.disabled;
          listModel.document_group = {
            id: documentText.document_group_id,
            text: this.getDocumentGroupName(documentText.document_group_id)
          };
          listModels.push(listModel);
        });
        this.documentListModel = listModels;

        this.queryModel.currentPage = requestedPage;
        this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
        this.queryModel.currentNumberOfItems = result.pagingResult.totalNumberOfItems;
      }
    );
  }

  private loadDocumentLanguageList(completion: () => void, storedSearchData: DocumentLinkSearch.SearchDataResult) {
    const storedId = storedSearchData.searchData.language_code;
    this.documentLanguageService.query().subscribe(
      (result: string[]) => {
        const def = this.documentLanguageUtil.createDefaultLanguageCode();
        this.searchModel.language_code = def;
        this.documentLanguageList.push(def);

        Arrays.iterateByIndex(result, language => {
          const languageItem = new DocumentLanguageItem();
          languageItem.id = language;
          languageItem.text = this.documentLanguageUtil.getDocumentLanguageName(language);
          this.documentLanguageList.push(languageItem);
        });
        if (storedId) {
          this.documentLanguageList.forEach((item) => {
            if (item.id === storedId.id) {
              this.searchModel.language_code = item;
            }
          })
        }
        completion();

      }, (error: any) => {
        completion();
      });
  }

  loadDefaultDocumentGroup(completion: () => void) {
    this.translateService.get('DOCUMENT_GROUP_FILTER_ALL').subscribe(
      (text: string) => {
        const allGroup: DocumentGroupItem = {
          id: null,
          text: text
        };
        this.documentGroupList.push(allGroup);
        this.searchModel.document_group = allGroup;
        completion();
      }
    );
  }

  loadDocumentGroups(completion: () => void, storedSearchData: DocumentLinkSearch.SearchDataResult) {
    const storedId = storedSearchData.searchData.document_group;
    this.documentGroupService.query({
      order: Services.createOrderFieldParameter(DocumentFile.Keys.toOrderFieldKey,
        Set.of({field: DocumentFile.OrderField.NAME, type: OrderType.ASC}))
    }).subscribe(
      (result: ResourceQueryResult<DocumentGroup>) => {

        result.items.forEach(group => {
          const documentGroupItem = {
            id: group.id,
            text: group.name
          };
          this.documentGroupList.push(documentGroupItem);
        });
        if (storedId) {
          this.documentGroupList.forEach((item) => {
            if (item.id === storedId.id) {
              this.searchModel.document_group = item;
            }
          })
        }
        completion();

      }, (error: any) => {
        completion();
      });
  }

  getDocumentGroupName(documentGroupId: number | null): string {
    let groupName = '';
    if (!documentGroupId) {
      return groupName;
    }
    Arrays.iterateByIndex(this.documentGroupList, (userGroup) => {
      if (userGroup.id === documentGroupId) {
        groupName = userGroup.text;
      }
    });

    return groupName;
  }

  setDisabled(event: any, documentModel: DocumentTextListModel, disabled: boolean) {
    if (!documentModel.id) {
      return;
    }
    this.documentTextService.setDisabled({
      id: documentModel.id,
      disabled: disabled
    })
      .subscribe(
      (result: EmptyMessage) => {
        this.loadList();
      },
      (error: any) => {
        this.loadList();
      }
    );
  }

  constructor(private translateService: TranslateService,
              private documentLanguageService: DocumentLanguageService,
              private documentLanguageUtil: DocumentLanguageUtil,
              private documentGroupService: DocumentGroupService,
              private rightService: RightService,
              private documentTextService: DocumentLinkService,
              private documentLinkSearchService: DocumentLinkSearchService,
              private authService: AuthService) {
  }
}

interface SearchLoadResult {
  storedSearchData: DocumentLinkSearch.SearchDataResult,
}

