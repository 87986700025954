import { List, Map as ImmutableMap } from 'immutable';

export namespace Dql {

  export class Methods {
    public static readonly IS_NULL = 'isNull';
    public static readonly IS_NOT_NULL = 'isNotNull';
    public static readonly IS_TRUE = 'isTrue';
    public static readonly IS_FALSE = 'isFalse';
    public static readonly IS_EMPTY = 'isEmpty';
    public static readonly IS_NOT_EMPTY = 'isNotEmpty';
    public static readonly IN = 'in';
    public static readonly EQ = 'eq';
    public static readonly EQUALS_IGNORE_CASE = 'equalsIgnoreCase';
    public static readonly CONTAINS = 'contains';
    public static readonly CONTAINS_IGNORE_CASE = 'containsIgnoreCase';
    public static readonly STARTS_WITH = 'startsWith';
    public static readonly STARTS_WITH_IGNORE_CASE = 'startsWithIgnoreCase';
    public static readonly GT = 'gt';
    public static readonly GOE = 'goe';
    public static readonly LOE = 'loe';
    public static readonly LT = 'lt';
    public static readonly BEFORE = 'before';
    public static readonly AFTER = 'after';
  }

  export enum Op {
    AND,
    OR
  }

  export class StringBuilder {

    private static readonly OP_AND = '&';
    private static readonly OP_OR = '|';
    private static readonly OP_NOT = '!';
    private static readonly DELIMITER_METHOD = ':';
    private static readonly DELIMITER_LIST_BEGIN = '[';
    private static readonly DELIMITER_LIST_END = ']';
    private static readonly DELIMITER_EXPR_BEGIN = '(';
    private static readonly DELIMITER_EXPR_END = ')';
    private static readonly DELIMITER_LIST_VALUE = ',';

    private readonly partStack: StringBuilderPart[] = [];
    private part = new StringBuilderPart();

    public static builder(): StringBuilder {
      return new StringBuilder();
    }

    private constructor() {
    }

    public build(): string {
      if (this.part.size === 0) {
        return '';
      }
      this.validateEnd();
      return this.part.toString();
    }

    public appendOp(op: Op): StringBuilder {
      this.checkOpPosition();
      switch (op) {
        case Op.AND:
          this.part.append(StringBuilder.OP_AND);
          break;
        case Op.OR:
          this.part.append(StringBuilder.OP_OR);
          break;
        default:
          throw Error('Unhandled OP: ' + op);
      }
      this.part.size++;
      return this;
    }

    public beginExpression(negated?: boolean): StringBuilder {
      this.checkExpressionPosition();
      this.appendNot(negated);
      this.part.append(StringBuilder.DELIMITER_EXPR_BEGIN);
      this.pushPart(this.part);
      this.part = new StringBuilderPart();
      return this;
    }

    public endExpression(): StringBuilder {
      if (this.part.size === 0) {
        throw Error('Empty expression');
      }
      this.validateEnd();
      const text = this.part.toString();
      this.part = this.popPart();
      this.part.append(text);
      this.part.append(StringBuilder.DELIMITER_EXPR_END);
      this.part.size++;
      return this;
    }

    public appendUnaryCriteria(field: string, method: string, negated?: boolean): StringBuilder {
      this.validateField(field);
      this.validateMethod(method);
      this.checkCriteriaPosition();
      this.appendNot(negated);
      this.part.append(field);
      this.part.append(StringBuilder.DELIMITER_METHOD);
      this.part.append(method);
      this.part.size++;
      return this;
    }

    public appendStringCriteria(field: string, method: string, value: string, negated?: boolean): StringBuilder {
      this.validateField(field);
      this.validateMethod(method);
      this.checkCriteriaPosition();
      this.appendNot(negated);
      this.part.append(field);
      this.part.append(StringBuilder.DELIMITER_METHOD);
      this.part.append(method);
      this.part.append(StringBuilder.DELIMITER_METHOD);
      this.part.append(JSON.stringify(value)); // escapes the string AND add the delimiters (") too
      this.part.size++;
      return this;
    }

    public appendNumberCriteria(field: string, method: string, value: number, negated?: boolean): StringBuilder {
      this.validateField(field);
      this.validateMethod(method);
      this.checkCriteriaPosition();
      this.appendNot(negated);
      this.part.append(field);
      this.part.append(StringBuilder.DELIMITER_METHOD);
      this.part.append(method);
      this.part.append(StringBuilder.DELIMITER_METHOD);
      this.part.append(Number(value).toString());
      this.part.size++;
      return this;
    }

    public appendNumberListCriteria(field: string, method: string, values: List<number>, negated?: boolean): StringBuilder {
      this.validateField(field);
      this.validateMethod(method);
      this.checkCriteriaPosition();
      this.appendNot(negated);
      this.part.append(field);
      this.part.append(StringBuilder.DELIMITER_METHOD);
      this.part.append(method);
      this.part.append(StringBuilder.DELIMITER_METHOD);
      this.part.append(StringBuilder.DELIMITER_LIST_BEGIN);
      this.part.append(values.join(StringBuilder.DELIMITER_LIST_VALUE));
      this.part.append(StringBuilder.DELIMITER_LIST_END);
      this.part.size++;
      return this;
    }

    public appendStringListCriteria(field: string, method: string, values: List<string>, negated?: boolean): StringBuilder {
      this.validateField(field);
      this.validateMethod(method);
      this.checkCriteriaPosition();
      this.appendNot(negated);
      this.part.append(field);
      this.part.append(StringBuilder.DELIMITER_METHOD);
      this.part.append(method);
      this.part.append(StringBuilder.DELIMITER_METHOD);
      this.part.append(StringBuilder.DELIMITER_LIST_BEGIN);
      this.part.append(this.joinString(values));
      this.part.append(StringBuilder.DELIMITER_LIST_END);
      this.part.size++;
      return this;
    }

    private joinString(values: List<string>): string {
      const a: string[] = [];
      values.toArray().forEach((value: string) => {
        a.push(JSON.stringify(value));
      });
      return a.join(StringBuilder.DELIMITER_LIST_VALUE);
    }

    private appendNot(negated?: boolean) {
      if (negated) {
        this.part.append(StringBuilder.OP_NOT);
      }
    }

    private popPart(): StringBuilderPart {
      const p = this.partStack.pop();
      if (p === undefined) {
        throw Error('Empty stack');
      }
      return p;
    }

    private pushPart(p: StringBuilderPart) {
      this.partStack.push(p);
    }

    private checkExpressionPosition() {
      if (this.part.size % 2 !== 0) {
        throw Error('Expression is not expected');
      }
    }

    private checkCriteriaPosition() {
      if (this.part.size % 2 !== 0) {
        throw Error('Criteria is not expected');
      }
    }

    private checkOpPosition() {
      if (this.part.size % 2 === 0) {
        throw Error('OP is not expected');
      }
    }

    private validateEnd() {
      if (this.part.size % 2 === 0) {
        throw Error('OP at the end is not allowed');
      }
    }

    private validateField(field: string) {
      if (field.length === 0) {
        throw Error('Field can not be empty');
      }
    }

    private validateMethod(method: string) {
      if (method.length === 0) {
        throw Error('Method can not be empty');
      }
    }

  }

  class StringBuilderPart {

    private readonly _builderArray: string[] = [];
    public size = 0;

    public toString(): string {
      return this._builderArray.join('');
    }

    public append(text: string): StringBuilderPart {
      this._builderArray.push(text);
      return this;
    }

  }

  export class StringFactory {

    public static create(provider: Dql.StringBuilderVisitorProvider): string {
      const builder = Dql.StringBuilder.builder();
      provider.getStringBuilderVisitor().visit(builder);
      return builder.build();
    }

    private constructor() {
    }

  }

  // This is an interface but with static factory methods.
  export abstract class StringBuilderVisitor {

    public static ofUnary(field: string, method: string): StringBuilderVisitor {
      return new StringBuilderVisitorUnary(false, field, method);
    }

    public static ofNumber(field: string, method: string, value: number): StringBuilderVisitor {
      return new StringBuilderVisitorNumber(false, field, method, value);
    }

    public static ofNumberList(field: string, method: string, value: List<number>): StringBuilderVisitor {
      return new StringBuilderVisitorNumberList(false, field, method, value);
    }

    public static ofString(field: string, method: string, value: string): StringBuilderVisitor {
      return new StringBuilderVisitorString(false, field, method, value);
    }

    public static ofStringList(field: string, method: string, value: List<string>): StringBuilderVisitor {
      return new StringBuilderVisitorStringList(false, field, method, value);
    }

    public static ofOp(left: StringBuilderVisitor, op: Op, right: StringBuilderVisitor) {
      return new StringBuilderVisitorOp(false, left, op, right);
    }

    public abstract not(): StringBuilderVisitor;

    public abstract visit(builder: StringBuilder): void;

  }

  export interface StringBuilderVisitorProvider {
    getStringBuilderVisitor(): StringBuilderVisitor;
  }

  class StringBuilderVisitorUnary implements StringBuilderVisitor {

    constructor(
      private readonly negated: boolean,
      private readonly field: string,
      private readonly method: string) {
    }

    public not(): StringBuilderVisitor {
      return new StringBuilderVisitorUnary(!this.negated, this.field, this.method);
    }

    public visit(builder: StringBuilder): void {
      builder.appendUnaryCriteria(this.field, this.method, this.negated);
    }

  }

  class StringBuilderVisitorNumber implements StringBuilderVisitor {

    constructor(
      private readonly negated: boolean,
      private readonly field: string,
      private readonly method: string,
      private readonly value: number) {
    }

    public not(): StringBuilderVisitor {
      return new StringBuilderVisitorNumber(!this.negated, this.field, this.method, this.value);
    }

    public visit(builder: StringBuilder): void {
      builder.appendNumberCriteria(this.field, this.method, this.value, this.negated);
    }

  }

  class StringBuilderVisitorNumberList implements StringBuilderVisitor {

    constructor(
      private readonly negated: boolean,
      private readonly field: string,
      private readonly method: string,
      private readonly value: List<number>) {
    }

    public not(): StringBuilderVisitor {
      return new StringBuilderVisitorNumberList(!this.negated, this.field, this.method, this.value);
    }

    public visit(builder: StringBuilder): void {
      builder.appendNumberListCriteria(this.field, this.method, this.value, this.negated);
    }

  }

  class StringBuilderVisitorString implements StringBuilderVisitor {

    constructor(
      private readonly negated: boolean,
      private readonly field: string,
      private readonly method: string,
      private readonly value: string) {
    }

    public not(): StringBuilderVisitor {
      return new StringBuilderVisitorString(!this.negated, this.field, this.method, this.value);
    }

    public visit(builder: StringBuilder): void {
      builder.appendStringCriteria(this.field, this.method, this.value, this.negated);
    }

  }

  class StringBuilderVisitorStringList implements StringBuilderVisitor {

    constructor(
      private readonly negated: boolean,
      private readonly field: string,
      private readonly method: string,
      private readonly value: List<string>) {
    }

    public not(): StringBuilderVisitor {
      return new StringBuilderVisitorStringList(!this.negated, this.field, this.method, this.value);
    }

    public visit(builder: StringBuilder): void {
      builder.appendStringListCriteria(this.field, this.method, this.value, this.negated);
    }

  }

  class StringBuilderVisitorOp implements StringBuilderVisitor {

    private static readonly precedences: ImmutableMap<Op, number> = ImmutableMap.of(
      Op.OR, 1,
      Op.AND, 2
    );

    get precedence(): number {
      return StringBuilderVisitorOp.precedences.get(this.op)
    }

    constructor(
      private readonly negated: boolean,
      private readonly left: StringBuilderVisitor,
      private readonly op: Op,
      private readonly right: StringBuilderVisitor) {
    }

    public not(): StringBuilderVisitor {
      return new StringBuilderVisitorOp(!this.negated, this.left, this.op, this.right);
    }

    public visit(builder: StringBuilder): void {
      const groupThis = this.negated;
      const groupLeft = this.left instanceof StringBuilderVisitorOp
        && !(this.left.negated)
        && this.left.hasLowerPrecedence(this);
      const groupRight = this.right instanceof StringBuilderVisitorOp
        && !(this.right.negated)
        && this.right.hasLowerOrEqualPrecedence(this);
      if (groupThis) {
        builder.beginExpression(this.negated);
      }
      if (groupLeft) {
        builder.beginExpression((this.left as StringBuilderVisitorOp).negated);
      }
      this.left.visit(builder);
      if (groupLeft) {
        builder.endExpression();
      }
      builder.appendOp(this.op);
      if (groupRight) {
        builder.beginExpression((this.right as StringBuilderVisitorOp).negated);
      }
      this.right.visit(builder);
      if (groupRight) {
        builder.endExpression();
      }
      if (groupThis) {
        builder.endExpression();
      }
    }

    private hasLowerPrecedence(other: StringBuilderVisitorOp): boolean {
      return this.precedence < other.precedence
    }

    private hasLowerOrEqualPrecedence(other: StringBuilderVisitorOp): boolean {
      return this.precedence <= other.precedence
    }

  }

}
