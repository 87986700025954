<div class="modal-content">
  <div class="modal-header">
    <p class="modal-title"><strong>{{'INVOICE_BATCH_DOWNLOAD' | translate}}</strong></p>
    <button type="button" class="close" (click)="closeDialog(false)" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <mat-dialog-content style="overflow:visible">
      <form (ngSubmit)="save()" #f="ngForm" id="form"
            [formGroup]="formGroup" novalidate>
        <!-- region InvoiceBook -->
        <div class="row form-group"
             [ngClass]="{ 'has-danger': hasLocalFieldError('invoiceBook')}">
          <label
            class="col-form-label form-control-label col-md-4 detail-title">{{'INVOICE_CREATE_INVOICE_BOOK' | translate}}</label>

          <div class="col-8">
            <div class="mw-100">
              <angular2-multiselect [data]="invoiceBooks" [settings]="searchableDropdownSettings"
                                    class="form-control"
                                    [(ngModel)]="model._invoiceBook"
                                    [ngClass]="{ 'form-control-danger': hasLocalFieldError('invoiceBook') }"
                                    (onRemoteSearch)="onInvoiceBookSearch($event.target.value)"
                                    name="invoiceBook"
                                    id="invoiceBook"
                                    formControlName="invoiceBook">
              </angular2-multiselect>
              <div class="form-control-feedback" *ngIf="hasLocalFieldError('invoiceBook')">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
          </div>
        </div>
        <!-- endregion InvoiceBook -->

        <div class="row form-group">
          <label
            class="col-form-label form-control-label col-md-4 detail-title">{{'INVOICE_SEARCH_ISSUE_DATE_FROM' | translate}}</label>
          <div class="col-8">
            <div class="input-group">
              <input type="text" class="form-control date"
                     placeholder="{{'INVOICE_SEARCH_ISSUE_DATE_FROM' | translate}}"
                     [(ngModel)]="model.issueDateFrom" ngbDatepicker
                     name="issueDateFrom"
                     id="issueDateFrom"
                     formControlName="issueDateFrom"
                     #issueDateFrom="ngbDatepicker">
              <div class="input-group-addon-gray" (click)="issueDateFrom.toggle()">
                <i class="icomoon icomoon-calendar"></i>
              </div>
            </div>
          </div>
        </div>

        <div class="row form-group">
          <label
            class="col-form-label form-control-label col-md-4 detail-title">{{'INVOICE_SEARCH_ISSUE_DATE_TO' | translate}}</label>
          <div class="col-8">
            <div class="input-group">
              <input type="text" class="form-control date"
                     placeholder="{{'INVOICE_SEARCH_ISSUE_DATE_TO' | translate}}"
                     [(ngModel)]="model.issueDateTo" ngbDatepicker
                     name="issueDateTo"
                     id="issueDateTo"
                     formControlName="issueDateTo"
                     #issueDateTo="ngbDatepicker">
              <div class="input-group-addon-gray" (click)="issueDateTo.toggle()">
                <i class="icomoon icomoon-calendar"></i>
              </div>
            </div>
          </div>
        </div>

        <div class="row form-group" *ngIf="model.invoiceBook">
          <div class="col-md-6">
            <label class="search-label">{{'INVOICE_SEQ_FROM' | translate}}</label>
            <input type="number" class="form-control" [placeholder]="''+model.invoiceBook.minNum"
                   [textMask]="{mask: InputMask.NATURAL_INTEGER, guide: false}"
                   [(ngModel)]="model.invoiceSeqFrom"
                   name="invoiceSeqFrom"
                   id="invoiceSeqFrom"
                   formControlName="invoiceSeqFrom">
          </div>
          <div class="col-md-6">
            <label class="search-label">{{'INVOICE_SEQ_TO' | translate}}</label>
            <input type="number" class="form-control" [placeholder]="''+model.invoiceBook.maxNum"
                   [textMask]="{mask: InputMask.NATURAL_INTEGER, guide: false}"
                   [(ngModel)]="model.invoiceSeqTo"
                   name="invoiceSeqTo"
                   id="invoiceSeqTo"
                   formControlName="invoiceSeqTo">
          </div>
        </div>
      </form>
    </mat-dialog-content>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="closeDialog(false)">
      {{'COMMON_BUTTON_CANCEL' | translate}}
    </button>
    <button type="submit" class="btn btn-primary" form="form">
      {{'COMMON_BUTTON_SAVE' | translate}}
    </button>
  </div>
</div>
