import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { DisabledItem, SearchUtils } from '../../../../util/search-utils';
import { OrderFieldFunction, OrderFieldModel, SelectUtils, UiConstants } from '../../../../util/core-utils';
import { BadgeStyle } from '../../../../shared/table-badge/badge-style';
import { InputMask } from '../../../../util/input-masks';
import { OrderField } from '../../../../lib/query/orderfields';
import { OrderType, QueryResult } from '../../../../lib/util/services';
import { Angular2Multiselects } from '../../../../util/multiselect';
import { RightModel } from '../../../../app.rights';
import { BreadcrumbParent } from '../../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { GrantedPermissionSetResolver, RightResolver, RightService } from '../../../../lib/right.service';
import { MatDialog } from '@angular/material/dialog';
import { UIRouter } from '@uirouter/core';
import { FilterField } from '../../../../lib/query/filterfields';
import { CriteriaBuilder, Models } from '../../../../util/model-utils';
import { InvoiceTagListModel } from './invoice-tag-list.model';
import { DropdownItemType } from '../../../../shared/dropdown/dropdown-item/dropdown-item-type';
import { OperationRights } from '../../../../app.right-definitions';
import { Set } from 'immutable';
import { StateName } from '../../../../app.state-names';
import { InvoiceTagSearch, InvoiceTagSearchService } from '../../../../lib/invoice/tag/invoice-tag-search.service';
import { InvoiceTag, InvoiceTagService } from '../../../../lib/invoice/tag/invoice-tag.service';
import { InvoiceTagRightModel } from '../../../../lib/invoice/tag/invoice-tag-right.model';
import { InvoiceTagDialogComponent } from '../invoice-tag-dialog/invoice-tag-dialog.component';

@Component({
  selector: 'app-invoice-tag-list',
  templateUrl: './invoice-tag-list.component.html',
  styleUrls: ['./invoice-tag-list.component.scss']
})
export class InvoiceTagListComponent extends SearchUtils.SearchableList<InvoiceTagSearch.Model> implements OnInit, OnDestroy {

  UiConstants = UiConstants;
  BadgeStyle = BadgeStyle;
  InputMask = InputMask;
  SelectUtils = SelectUtils;
  InvoiceTagSearch = InvoiceTagSearch;
  DropdownItemType = DropdownItemType;

  queryModel: OrderFieldModel<OrderField.InvoiceTag>
    = new OrderFieldModel(InvoiceTagSearch.OrderFunctions.ID, OrderType.DESC);
  searchModel: InvoiceTagSearch.Model = new InvoiceTagSearch.Model();

  dropdownSettings: Angular2Multiselects.Settings;

  invoiceTagList: InvoiceTagListModel[] = [];
  rightModel: RightModel = RightModel.empty();

  breadcrumbSelf: string;
  breadcrumbParents: BreadcrumbParent[] = [];
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  disabledItems: DisabledItem[] = [];


  constructor(private invoiceTagService: InvoiceTagService,
              private invoiceTagSearchService: InvoiceTagSearchService,
              private rightService: RightService,
              private dialog: MatDialog,
              private uiRouter: UIRouter,
              injector: Injector) {
    super(InvoiceTagSearch.Model, injector);
  }

  ngOnInit() {
    this.disabledItems = this.initDisabledOptions();
    this.loadRightModels();
    this.initBreadcrumb();
    this.initSearch();
    this.initDropdownSettings();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  initBreadcrumb() {
    this.translateService.get('MENU_NAVBAR_MENU_INVOICE_TAG_LIST').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
  }

  initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .remoteSearch(true)
      .enableCheckAll(false)
      .build();
  }

  loadSearch(completion: () => void) {
    this.invoiceTagSearchService.getSearchData({})
      .subscribe(
        (result: InvoiceTagSearch.SearchDataResult) => {
          this.queryModel = result.searchData.queryModel;
          this.searchModel = result.searchData.searchModel;
          completion();
        }
      );
  }

  onFirstSearchOpen(): void {
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: OrderFieldFunction<OrderField.InvoiceTag>) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const rights = Set.of(OperationRights.INVOICE_TAG_DISABLE,
      OperationRights.INVOICE_TAG_UPDATE);
    const order = this.queryModel.createOrderFunction();
    const filter = this.createFilter();
    this.invoiceTagService.query({
      fields: f => f.forList,
      order: order,
      filter: filter,
      rights: rights,
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined,
    }).subscribe((result: QueryResult<InvoiceTag.InvoiceTag>) => {
      this.invoiceTagList = [];
      result.items.forEach((invoiceTag: InvoiceTag.InvoiceTag) => {
        const model = new InvoiceTagListModel();
        model.id = invoiceTag.id;
        model.rights = new InvoiceTagRightModel(GrantedPermissionSetResolver.byGrantedRights(invoiceTag.grantedRights));
        model.creationTime = invoiceTag.creationTime;
        model.updateTime = invoiceTag.updateTime;
        model.invoiceTag = invoiceTag.invoiceTag;
        model.description = invoiceTag.description;
        model.disabled = invoiceTag.disabled;
        this.invoiceTagList.push(model);
      });
      this.queryModel.currentPage = requestedPage;
      this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
    });
  }

  private createFilter() {
    return (f: FilterField.InvoiceTag) => CriteriaBuilder.builder()
      .addNumber((id) => f.id.eq(id), this.searchModel.id)
      .addString((invoiceTag) => f.invoiceTag.containsIgnoreCase(invoiceTag), this.searchModel.invoiceTag)
      .addString((name) => f.description.containsIgnoreCase(name), this.searchModel.description)
      .addDateTime((creationTime) => f.creationTime.before(creationTime), Models.parseDateTimeTo(this.searchModel.creationTimeTo))
      .addDateTime((creationTime) => f.creationTime.after(creationTime), Models.parseDateTimeFrom(this.searchModel.creationTimeFrom))
      .addBoolean((disabled) => disabled ? f.disabled.isTrue() : f.disabled.isFalse(), this.parseDisabled(this.searchModel.disabled))
      .build();
  }

  onSearchClicked() {
    this.loadList(1);
  }

  onSearchReset() {
    this.invoiceTagSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.toggleSearch();
          this.loadList(1);
        });
      }
    );
  }


  ngOnDestroy(): void {
    this.saveSearch();
  }

  private saveSearch() {
    const request = {
        searchData: {
          queryModel: this.queryModel,
          searchModel: this.searchModel
        }
      }
    ;
    this.invoiceTagSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  openInvoiceTagCreateDialog() {
    const dialogRef = this.dialog.open(InvoiceTagDialogComponent, {
      width: '60%',
      data: {readonly: false}
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.modified) {
        this.loadList();
      }
    });
  }

  openInvoiceTagUpdateDialog(id: number) {
    const dialogRef = this.dialog.open(InvoiceTagDialogComponent, {
      width: '60%',
      data: {readonly: false, invoiceTagId: id}
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.modified) {
        this.loadList();
      }
    });
  }

  openInvoiceTagDetailDialog(id: number) {
    const dialogRef = this.dialog.open(InvoiceTagDialogComponent, {
      width: '60%',
      data: {readonly: true, invoiceTagId: id}
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.modified) {
        this.loadList();
      }
    });
  }

  setDisabled(id: number, disabled: boolean) {
    this.invoiceTagService.disable({id: id, disabled: disabled})
      .subscribe(() => this.loadList());
  }

}
