/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { FieldValidationError, Order, PagingRequest, QueryResult, ResourceQueryResult, Services } from '../util/services';
import { FieldError, ObservableErrorResourceParser } from '../util/errors';
import { List, Map, Set } from 'immutable';
import { CompanyStockResource, CompanyStockResourceService } from './company-stock-resource.service';
import { Address } from '../address';
import { EmptyMessage } from '../util/messages';
import { HistoryLog } from '../history-log/history-log.service';
import { UserItem, UserItemResource } from '../user.service';
import CompanyStockHistoryType = CompanyStock.CompanyStockHistoryType;
/* eslint-enable */

@Injectable()
export class CompanyStockService implements CompanyStock.Service {

  constructor(private resourceService: CompanyStockResourceService) {
  }

  query(request: CompanyStock.QueryRequest): Observable<QueryResult<CompanyStock.CompanyStock>> {
    return Observable.create((observer: Observer<QueryResult<CompanyStock.CompanyStock>>) => {
      const resourceRequest: CompanyStockResource.QueryRequest = {
        id: Services.createIdParameter(request.id),
        external_id: request.externalId,
        name: request.name,
        company_ids: Services.createIdParameter(request.companyIds),
        company_location_ids: Services.createIdParameter(request.companyLocationIds),
        user_group_ids: Services.createIdParameter(request.userGroupIds),
        disabled: request.disabled,
        q: request.queryText,
        order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
        no_progress_bar: request.noProgressBar
      };
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<CompanyStockResource.CompanyStock>) => {
          observer.next({
            items: this.toPublicList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  get(request: CompanyStock.GetRequest): Observable<CompanyStock.CompanyStock> {
    return Observable.create((observer: Observer<CompanyStock.CompanyStock>) => {
      const resourceRequest: CompanyStockResource.GetRequest = {
        id: request.id
      };
      return this.resourceService.get(resourceRequest).subscribe(
        (result: CompanyStockResource.CompanyStock) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  create(request: CompanyStock.CreateRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: CompanyStockResource.CreateRequest = {
        external_id: request.externalId,
        name: request.name,
        company_location_id: request.companyLocationId,
        assignee_user_group_id: request.assigneeUserGroupId
      };
      return this.resourceService.create(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  update(request: CompanyStock.UpdateRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: CompanyStockResource.UpdateRequest = {
        id: request.id,
        external_id: request.externalId,
        name: request.name,
        company_location_id: request.companyLocationId,
        assignee_user_group_id: request.assigneeUserGroupId
      };
      return this.resourceService.update(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(/*this.translateError(*/error/*)*/);
        },
        () => {
          observer.complete();
        });
    });
  }

  setDisabled(request: CompanyStock.DisableRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: CompanyStockResource.DisableRequest = {
        id: request.id,
        disabled: request.disabled
      };
      return this.resourceService.setDisabled(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }


  private translateError(error: any): any {
    const res = ObservableErrorResourceParser.parseError(error);
    const fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
    const fieldErrorMap = ObservableErrorResourceParser.toFieldErrorMap(Keys.toValidatedField, fieldErrors);
    if (!fieldErrorMap.isEmpty()) {
      return FieldValidationError.of(fieldErrorMap);
    }
    return error;
  }

  private toPublicList(resourceList: CompanyStockResource.CompanyStock[]): List<CompanyStock.CompanyStock> {
    return List.of(...resourceList.map((r) => this.toPublic(r)));
  }

  private toPublic(r: CompanyStockResource.CompanyStock): CompanyStock.CompanyStock {
    return {
      id: r.id,
      externalId: r.external_id,
      name: r.name,
      disabled: r.disabled,
      companyLocation: r.company_location,
      assigneeUserGroupId: r.assignee_user_group_id
    };
  }

  history(request: CompanyStock.HistoryRequest): Observable<QueryResult<CompanyStock.HistoryItem>> {
    return Observable.create((observer: Observer<QueryResult<CompanyStock.HistoryItem>>) => {
      const resourceRequest: CompanyStockResource.HistoryRequest = {
        id: request.id,
        with_read: request.withRead,
        q: request.queryText,
        order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
      };
      return this.resourceService.history(resourceRequest).subscribe(
        (result: ResourceQueryResult<CompanyStockResource.HistoryItem>) => {
          observer.next({
            items: this.toPublicHistoryList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });

    });
  }

  private toPublicHistoryList(resourceList: CompanyStockResource.HistoryItem[]): List<CompanyStock.HistoryItem> {
    return List.of(...resourceList.map((r) => this.toPublicHistory(r)));
  }

  private toPublicHistory(r: CompanyStockResource.HistoryItem): CompanyStock.HistoryItem {
    return {
      id: r.id,
      creationTime: Services.toOffsetDateTime(r.creation_time),
      type: <CompanyStockHistoryType>r.type,
      changeLog: r.change_log,
      applicationClassification: r.application_classification,
      issuerUser: this.toPublicUser(r.issuer_user!),
      mobileApplication: this.toPublicMobileApplication(r.mobile_application)
    };
  }

  private toPublicUser(r: UserItemResource): UserItem {
    return {
      id: r.id,
      personName: r.person_name
    };
  }

  private toPublicMobileApplication(r: HistoryLog.MobileApplicationItemResource | undefined):
    HistoryLog.MobileApplicationItem | undefined {
    if (r) {
      return {
        id: r.id,
        applicationId: r.application_id
      };
    }
    return undefined;
  }
}

export namespace CompanyStock {

  export interface Service {

    // <editor-fold desc="CRUD">

    query(request: QueryRequest): Observable<QueryResult<CompanyStock>>;

    get(request: GetRequest): Observable<CompanyStock>;

    create(request: CreateRequest): Observable<EmptyMessage>;

    update(request: UpdateRequest): Observable<EmptyMessage>;

    setDisabled(request: DisableRequest): Observable<EmptyMessage>;

    // </editor-fold>

  }

  export interface CompanyStock {
    id: number;
    externalId: string;
    name: string;
    companyLocation: CompanyLocationData;
    assigneeUserGroupId?: number;
    disabled: boolean;
  }

  export interface QueryRequest {
    id?: Set<number>;
    externalId?: string;
    name?: string;
    companyIds?: Set<number>;
    companyLocationIds?: Set<number>;
    userGroupIds?: Set<number>;
    queryText?: string;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
    disabled?: boolean;
    noProgressBar?: boolean;
  }

  export interface GetRequest {
    id: number;
  }

  export interface CreateRequest {
    externalId?: string;
    name: string;
    companyLocationId: number;
    assigneeUserGroupId?: number;
  }

  export interface UpdateRequest {
    id: number;
    externalId: string;
    name: string;
    companyLocationId: number;
    assigneeUserGroupId?: number;
  }

  export interface DisableRequest {
    id: number;
    disabled: boolean;
  }

  export interface CompanyLocationData {
    id: number;
    name: string;
    address?: Address.PostalAddressData;
    companyName?: string;
  }

  export interface HistoryRequest {
    id: number;
    withRead: boolean;
    queryText?: string;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
  }

  export interface HistoryItem extends HistoryLog.HistoryItemBase {
    type: CompanyStockHistoryType;
  }

  export type CompanyStockHistoryType =
    'READ' |
    'CREATE' |
    'UPDATE' |
    'DISABLE' |
    'ENABLE';

  export class CompanyStockHistoryTypeObject {
    type: CompanyStockHistoryType;
    stringKey: string;
  }

  export const companyStockHistoryTypes: CompanyStockHistoryTypeObject[] = [
    {type: 'READ', stringKey: 'COMPANY_STOCK_HISTORY_TYPE_READ'},
    {type: 'CREATE', stringKey: 'COMPANY_STOCK_HISTORY_TYPE_CREATE'},
    {type: 'UPDATE', stringKey: 'COMPANY_STOCK_HISTORY_TYPE_UPDATE'},
    {type: 'DISABLE', stringKey: 'COMPANY_STOCK_HISTORY_TYPE_DISABLE'},
    {type: 'ENABLE', stringKey: 'COMPANY_STOCK_HISTORY_TYPE_ENABLE'},
  ];

  export enum OrderField {
    ID,
    EXTERNAL_ID,
    NAME,
    COMPANY_LOCATION,
    HISTORY_CREATION_TIME,
    HISTORY_ISSUER_USER_PERSON_NAME,
    HISTORY_MOBILE_APPLICATION_APPLICTAION_ID
  }

  export enum ValidatedField {
    UNKNOWN,

    EXTERNAL_ID,
    NAME,
    COMPANY_LOCATION,
    ASSIGNEE_USER_GROUP_ID
  }

}

export interface CompanyStockFieldErrorMap {
  assignee_user_group_id?: FieldError;
  external_id?: FieldError;
  name?: FieldError;
  company_location?: FieldError;

}

class Keys {

  private static readonly ID = 'id';
  private static readonly EXTERNAL_ID = 'external_id';
  private static readonly NAME = 'name';
  private static readonly COMPANY_LOCATION = 'company_location';
  private static readonly ASSIGNEE_USER_GROUP_ID = 'assignee_user_group_id';
  private static readonly HISTORY_CREATION_TIME = 'creation_time';
  private static readonly HISTORY_ISSUER_USER_PERSON_NAME = 'issuer_user_person_name';
  private static readonly HISTORY_MOBILE_APPLICATION_APPLICTAION_ID = 'mobile_application_application_id';

  private static readonly orderFieldKeyMap: Map<CompanyStock.OrderField, string> = Map.of(
    CompanyStock.OrderField.ID, Keys.ID,
    CompanyStock.OrderField.EXTERNAL_ID, Keys.EXTERNAL_ID,
    CompanyStock.OrderField.NAME, Keys.NAME,
    CompanyStock.OrderField.COMPANY_LOCATION, Keys.COMPANY_LOCATION,
    CompanyStock.OrderField.HISTORY_CREATION_TIME, Keys.HISTORY_CREATION_TIME,
    CompanyStock.OrderField.HISTORY_ISSUER_USER_PERSON_NAME, Keys.HISTORY_ISSUER_USER_PERSON_NAME,
    CompanyStock.OrderField.HISTORY_MOBILE_APPLICATION_APPLICTAION_ID, Keys.HISTORY_MOBILE_APPLICATION_APPLICTAION_ID,
  );

  private static readonly keyValidatedFieldMap: Map<string, CompanyStock.ValidatedField> = Map.of(
    CompanyStock.ValidatedField.EXTERNAL_ID, Keys.EXTERNAL_ID,
    CompanyStock.ValidatedField.NAME, Keys.NAME,
    CompanyStock.ValidatedField.COMPANY_LOCATION, Keys.COMPANY_LOCATION,
    CompanyStock.ValidatedField.ASSIGNEE_USER_GROUP_ID, Keys.ASSIGNEE_USER_GROUP_ID,
  );

  public static toOrderFieldKey(field: CompanyStock.OrderField): string {
    return Keys.orderFieldKeyMap.get(field)!;
  }

  public static toValidatedField(fieldKey: string): CompanyStock.ValidatedField {
    return Keys.keyValidatedFieldMap.get(fieldKey, CompanyStock.ValidatedField.UNKNOWN);
  }

}
