<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>

<div class="p-1 animated fadeIn" id="workflow-base-data">
  <div class="card" *ngIf="model">

    <div class="d-flex flex-row justify-content-between base-data-header">
      <div class="d-flex flex-row flex-wrap">
        <div class="d-flex flex-row px-1 py-half align-items-center">
          <app-type-icon [icon]="getIcon()" class="mr-05"></app-type-icon>
          {{model.externalId}}
        </div>
        <div class="px-1 py-third-quarter cursor-pointer"
             popover="{{'COMMON_CREATOR_USER' | translate}}" triggers="mouseenter:mouseleave" container="body"
             placement="top">
          <a class="mb-0 d-inline-block text-wrap id-text cursor-pointer"
             href="#" onclick="return false"
             uiSref="Admin.UserDetail"
             [uiParams]="{ id: model.creatorUser.id}">
            <div class="d-flex flex-row align-items-center">
              <i class="icomoon pr-half icomoon-change-assignee info-card-icon"></i>
              {{ model.creatorUser.personName }}</div>
          </a>
        </div>
        <div class="d-flex flex-row px-1 py-third-quarter align-items-center cursor-pointer"
             *ngIf="model.startTime"
             popover="{{'PROCESS_START_TIME' | translate}}" triggers="mouseenter:mouseleave" container="body"
             placement="top">
          <i class="icomoon pr-half icomoon-new-state-change-start info-card-icon"></i>
          <div>{{model.startTime.toUtcIsoString() | date:'short'}}</div>
        </div>
        <div class="px-1 py-third-quarter cursor-pointer"
             popover="{{'PROCESS_DETAIL_LAST_MODIFIER' | translate}}" triggers="mouseenter:mouseleave" container="body"
             placement="top">
          <a class="mb-0 d-inline-block text-wrap id-text cursor-pointer"
             href="#" onclick="return false"
             uiSref="Admin.UserDetail"
             [uiParams]="{ id: model.lastModifiedUser.id}">
            <div class="d-flex flex-row align-items-center">
              <i class="icomoon pr-half icomoon-last-modifier-user info-card-icon"></i>
              {{ model.lastModifiedUser.personName }}</div>
          </a>
        </div>
        <div class="d-flex flex-row px-1 py-third-quarter align-items-center cursor-pointer"
             *ngIf="model.finishTime"
             popover="{{'PROCESS_FINISH_TIME' | translate}}" triggers="mouseenter:mouseleave" container="body"
             placement="top">
          <i class="icomoon pr-half icomoon-new-state-finished info-card-icon"></i>
          <div>{{model.finishTime.toUtcIsoString() | date:'short'}}</div>
        </div>
        <div class="d-flex flex-row px-1 py-third-quarter align-items-center cursor-pointer"
             *ngIf="!model.finishTime"
             popover="{{'PROCESS_DETAIL_UPDATE_TIME' | translate}}" triggers="mouseenter:mouseleave" container="body"
             placement="top">
          <i class="icomoon pr-half icomoon-date-time info-card-icon"></i>
          <div>{{model.updateTime.toUtcIsoString() | date:'short'}}</div>
        </div>
        <div class="d-flex flex-row px-1 py-third-quarter align-items-center cursor-pointer"
             *ngIf="getProcessStateObject()"
             popover="{{'PROCESS_DETAIL_STATE' | translate}}" triggers="mouseenter:mouseleave" container="body"
             placement="top">
          <i class="icomoon pr-half {{getProcessStateObject().iconClass}} info-card-icon"></i>
          <div>{{getProcessStateObject().stringKey | translate}}</div>
        </div>
      </div>
      <div class="d-flex flex-row flex-wrap">
        <div class="border-left d-flex flex-row px-1 py-third-quarter align-items-center cursor-pointer"
             (click)="onOpenBaseDataDetailButtonClicked()">
          <i class="icomoon icomoon-detail"></i>
        </div>
        <div *ngIf="processRightModel && processRightModel.update.hasRight()" (click)="onModifyBaseDataButtonClicked()"
             class="border-left d-flex flex-row px-1 py-half align-items-center cursor-pointer">
          <i class="icomoon icomoon-modify"></i>
        </div>
      </div>
    </div>

  </div><!-- /.card -->

  <div class="container-vertical-padding-half"></div>

  <div class="card p-1" *ngIf="stateCount">
    <div>
      <span *ngFor="let count of stateCount" class="state-count-container">
        <i class="{{count.state.iconClass}} state-count-icon"></i>
        {{count.state.stringKey | translate}}:&nbsp;
        <span class="font-weight-bold">{{'PROCESS_STATISTICS_NUMBER' | translate: {count: count.count} }}</span>
      </span>
    </div>
  </div><!-- /.card -->

  <div class="container-vertical-padding-half"></div>

  <responsive-tabset class="fullscreen_tab">
    <responsive-tab heading="{{'PROCESS_DETAIL_WORKFLOW'|translate}}">
      <div class="animated fadeIn row m-0">
        <div class="card p-0" *ngIf="model && model.workflow"
             [class.col-12]="!selectedTaskRecords"
             [class.col-9]="selectedTaskRecords">
          <div class="card-header">
            {{'PROCESS_DETAIL_WORKFLOW'|translate}}
            <div class="card-actions">
            </div><!-- /.card-actions -->
          </div>
          <div class="card-body p-0">
            <app-workflow-create-editor
              [taskStateMap]="taskStateMap"
              [readonly]="true"
              [workflow]="model.workflow"
              [workflowId]="model.workflow.id"
              [displayOnProcessDetail]="true">
            </app-workflow-create-editor>
          </div>
        </div>
        <div class="col-3 p-0 pl-1" *ngIf="selectedTaskRecords && selectedTaskRecords.length === 0 ">
          <div class="card">
            <div class="card-header">
              {{'PROCESS_DETAIL_WORKFLOW_TASKS_HEADER' | translate}}
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-center">
                <label class="text-muted">{{'PROCESS_DETAIL_WORKFLOW_TASKS_NO_TASK' | translate}}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-3 p-0 pl-1" *ngIf="selectedTaskRecords && selectedTaskRecords.length > 0">
          <div class="card">
            <div class="card-header">
              {{'PROCESS_DETAIL_WORKFLOW_TASKS_HEADER' | translate}}
              <div class="card-actions">
                <a href="#" onclick="return false" uiSref="Admin.TaskRecordDetail"
                   *ngIf="selectedTaskRecords[selectedTaskRecordIndex].rightModel.read.hasRight()"
                   [uiParams]="{taskRecordId: selectedTaskRecords[selectedTaskRecordIndex].id, taskId: selectedTaskRecords[selectedTaskRecordIndex].taskId}">
                  <i class="icomoon icomoon-visible-on-detail"></i>
                </a>
              </div>
            </div>
            <div class="card-body">
              <div *ngIf="selectedTaskRecords.length === 0" class="d-flex justify-content-center">
                <label class="text-muted">{{'PROCESS_DETAIL_WORKFLOW_TASKS_NO_TASK' | translate}}</label>
              </div>
              <div *ngIf="selectedTaskRecordIndex !== undefined">
                <div class="d-flex align-items-center">
                  <i
                    class="{{selectedTaskRecords[selectedTaskRecordIndex].stateObject.iconClass}} task-record-state-icon"></i>
                  <div>
                    <span class="task-record-name d-flex">{{selectedTaskRecords[selectedTaskRecordIndex].name}}</span>
                    <span
                      class="task-record-state">{{selectedTaskRecords[selectedTaskRecordIndex].stateObject.stringKey | translate}}</span>
                  </div>
                </div>
                <div>
                  <span class="data-title">{{'PROCESS_DETAIL_WORKFLOW_TASK_ASSIGNEE' | translate}}</span>
                  <span *ngIf="selectedTaskRecords[selectedTaskRecordIndex].assigneeUser"
                        class="data-detail">
                    {{selectedTaskRecords[selectedTaskRecordIndex].assigneeUser.personName}}
                  </span>
                  <span *ngIf="selectedTaskRecords[selectedTaskRecordIndex].assigneeUserGroup"
                        class="data-detail">
                    {{selectedTaskRecords[selectedTaskRecordIndex].assigneeUserGroup.name}}
                  </span>
                </div>
                <div *ngIf="selectedTaskRecords[selectedTaskRecordIndex].customerRecord">
                  <span class="data-title">{{'PROCESS_DETAIL_WORKFLOW_TASK_CUSTOMER_RECORD' | translate}}</span>
                  <span
                    class="data-detail">
                    {{selectedTaskRecords[selectedTaskRecordIndex].customerRecord.name}}
                  </span>
                </div>
                <div *ngIf="selectedTaskRecords[selectedTaskRecordIndex].pocString">
                  <span class="data-title">{{'PROCESS_DETAIL_WORKFLOW_TASK_POC' | translate}}</span>
                  <span
                    class="data-detail">
                    {{selectedTaskRecords[selectedTaskRecordIndex].pocString}}
                  </span>
                </div>
                <div class="row m-0">
                  <div *ngIf="selectedTaskRecords[selectedTaskRecordIndex].deadline" class="col p-0 mr-1">
                    <span class="data-title">{{'PROCESS_DETAIL_WORKFLOW_TASK_DEADLINE' | translate}}</span>
                    <span
                      class="data-detail">
                    {{selectedTaskRecords[selectedTaskRecordIndex].deadline.toUtcIsoString() | date:'short'}}
                  </span>
                  </div>
                  <div class="col p-0">
                    <span class="data-title">{{'PROCESS_DETAIL_WORKFLOW_TASK_IMPORTANCE' | translate}}</span>
                    <span
                      class="data-detail">
                    {{selectedTaskRecords[selectedTaskRecordIndex].importanceStringKey | translate}}
                  </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-end" *ngIf="selectedTaskRecords.length > 1">
            <button type="button" class="task-record-change-button task-record-change-button-margin-right"
                    (click)="selectedTaskRecordIndex = selectedTaskRecordIndex - 1"
                    [disabled]="selectedTaskRecordIndex === 0">
              <i class="icomoon icomoon-chevron-left"></i>
            </button>
            <button type="button" class="task-record-change-button task-record-change-button-margin-right">
              {{selectedTaskRecordIndex + 1}}
            </button>
            <button type="button" class="task-record-change-button"
                    (click)="selectedTaskRecordIndex = selectedTaskRecordIndex + 1"
                    [disabled]="selectedTaskRecordIndex === selectedTaskRecords.length - 1">
              <i class="icomoon icomoon-chevron-right"></i>
            </button>
          </div>
        </div>
      </div>
    </responsive-tab>
    <responsive-tab heading="{{'INVOICE_CREATE_RELATED_TASKS'|translate}}"
                    *ngIf="rightModel.taskRecordRead.hasRight()">
      <app-new-task-record-list
        [processId]="processId"
        [_headerStringKey]="'INVOICE_CREATE_RELATED_TASKS'">
      </app-new-task-record-list>
    </responsive-tab>
    <responsive-tab heading="{{'COMMON_MESSAGES'|translate}}" id="messages"
                    (selectTab)="processMessageList.initComponent()">
      <app-chat
        #processMessageList
        [id]="processId"
        [messageSendEnable]="true"
        [chatService]="getChatService()">
      </app-chat>
    </responsive-tab>
    <responsive-tab heading="{{'PROCESS_DETAIL_HISTORY'|translate}}">
      <div class="container-custom-padding animated fadeIn" *ngIf="model">
        <app-process-history
          [processId]="model.id">
        </app-process-history>
      </div>
    </responsive-tab>
  </responsive-tabset>

</div>
