import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TriggerUtils } from '../../../lib/trigger/trigger-utils';
import { ModalDirective } from 'ngx-bootstrap/modal';
import TriggerTypeObject = TriggerUtils.TriggerTypeObject;
import { UiConstants } from '../../../util/core-utils';

@Component({
  selector: 'app-trigger-preselect-dialog',
  templateUrl: './trigger-preselect-dialog.component.html',
  styleUrls: ['./trigger-preselect-dialog.component.scss']
})
export class TriggerPreselectDialogComponent implements OnInit {
  UiConstants = UiConstants;
  TriggerUtils = TriggerUtils;

  @Input()
  scope: TriggerUtils.TriggerScope;

  @ViewChild('triggerPreselectDialog', { static: true })
  triggerPreselectDialog: ModalDirective;
  triggerPreselectDialogVisible: boolean = false;

  @Output()
  triggerTypeSelected: EventEmitter<TriggerUtils.TriggerType> = new EventEmitter<TriggerUtils.TriggerType>();

  constructor() { }

  ngOnInit() {
  }

  showTriggerPreselectDialog() {
    this.triggerPreselectDialogVisible = true;
    this.triggerPreselectDialog.show();
  }

  closeTriggerPreselectDialog(triggerType?: TriggerTypeObject) {
    this.triggerPreselectDialogVisible = false;
    this.triggerPreselectDialog.hide();
    if (triggerType) {
      this.triggerPreselectDialog.onHidden.subscribe(() => {
        this.triggerTypeSelected.emit(triggerType.type);
      });
    }
  }

  openTriggerEditDialog(triggerType: TriggerTypeObject) {
    this.closeTriggerPreselectDialog(triggerType);
  }

}
