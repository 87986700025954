import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {
  StockItem,
  StockItemQuery,
  StockItemService,
  StockItemStock,
  StockItemType
} from '../../../lib/stock/stock-item.service';
import {OrderType, ResourceQueryResult, Services} from '../../../lib/util/services';
import {QueryFieldModel, UiConstants} from '../../../util/core-utils';
import {Set} from 'immutable';
import {Strings} from '../../../lib/util/strings';
import {
  StockRecord,
  StockRecordFacade,
  StockRecordFacadeQuery,
  StockRecordService
} from '../../../lib/stock/stock-record.service';
import {StockItemUtils} from '../../../util/stock/stock-item-utils';
import {MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxDefaultOptions} from '@angular/material/checkbox';
import {StockItemUnitOfMeasure} from '../../../lib/stock/stock-item-unit-of-measure';
import {
  StockItemUnitOfMeasureMultiselectOptionItem,
  StockItemUnitOfMeasureMultiselectProvider
} from '../../../lib/stock/stock-item-unit-of-measure-multiselect.provider';
import {Decimal} from 'decimal.js';
import {Angular2Multiselects} from '../../../util/multiselect';
import {Weight, WeightFactory} from "../../../util/weight-utils";

@Component({
  selector: 'app-stock-item-selector-dialog',
  templateUrl: './stock-item-selector-dialog.component.html',
  styleUrls: ['./stock-item-selector-dialog.component.scss'],
  providers: [
    {provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: {clickAction: 'noop'} as MatCheckboxDefaultOptions}
  ]
})
export class StockItemSelectorDialogComponent implements OnInit {

  UiConstants = UiConstants;
  StockItem = StockItem;
  StockItemUtils = StockItemUtils;

  stockItems: StockItemSelectorModel[] = [];
  selectedStockItems: StockItemSelectorModel[] = [];
  queryModel: QueryFieldModel<StockItem.OrderField> = new QueryFieldModel(StockItem.OrderField.NAME, OrderType.ASC);

  loading: boolean = false;

  allSelected: boolean = false;

  numberOfItemsOptions: number[] = [12, 24, 48];

  filter: string = '';

  // If all items are selected, the visible list is the result of the last successful query
  private lastSuccessfulQueryRequest?: StockItemQuery;

  readonly pagingId: string = 'stockItemSelectorDialogPagingId';

  dropdownSettings: Angular2Multiselects.Settings = Angular2Multiselects.LOCAL_SINGLE_SELECT;

  constructor(
    public dialogRef: MatDialogRef<StockItemSelectorDialogComponent, StockItemSelectorDialogResult>,
    @Inject(MAT_DIALOG_DATA) public data: StockItemSelectorDialogData,
    private stockItemService: StockItemService,
    private stockRecordService: StockRecordService,
    private unitOfMeasureMultiselectProvider: StockItemUnitOfMeasureMultiselectProvider
  ) {
  }

  ngOnInit() {
    this.onItemsPerPageChange(this.numberOfItemsOptions[0])
  }

  load(pageNumber?: number) {
    if (this.data.loadRecordsOfStock !== undefined) {
      this.loadStockRecords(pageNumber);
    } else {
      this.loadStockItems(pageNumber);
    }
  }

  private loadStockRecords(pageNumber?: number) {
    this.loading = true;
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const request: StockRecordFacadeQuery = {
      stock_ids: this.data.loadRecordsOfStock! + '',
      stock_item_q: Strings.undefinedOrNonEmpty(this.filter),
      stock_item_usable_for_form_field: true,
      stock_item_disabled: false,
      stock_item_type: this.data.stockItemType,
      stock_item_category_ids: this.data.usableCategoryIds.length > 0 ? this.data.usableCategoryIds.join() : undefined,
      excluded_stock_item_ids: this.data.excludedIds.length > 0 ? this.data.excludedIds.join() : undefined,
      order: Services.createOrderFieldParameter(
        StockRecord.Keys.toOrderFieldKey,
        Set.of({field: StockRecord.OrderField.STOCK_ITEM_NAME, type: OrderType.ASC})),
      page_number: requestedPage,
      number_of_items: this.queryModel.itemsPerPage,
    };
    this.stockRecordService.facadeQuery(request).subscribe((result: ResourceQueryResult<StockRecordFacade>) => {
      this.stockItems = [];
      result.items.forEach(r => {
        const model = new StockItemSelectorModel();
        const selected = this.selectedStockItems.find(item => item.id === r.stock_item.stock_item_id);
        model.selected = !!selected;
        model.id = r.stock_item.stock_item_id;
        model.name = r.stock_item.name;
        model.productCode = r.stock_item.product_code!;
        model.weightInGrams = r.stock_item.weight_in_grams;
        model.externalId = r.stock_item.external_id;
        model.packageData = r.stock_item.package_data;
        model.measurements = this.unitOfMeasureMultiselectProvider.toMultiselectOptionItems(r.stock_item.measurements);
        model.currencyCode = r.stock_item.unit_price.currency_code;
        model.inStockAmount = r.amount;
        model.amount = selected ? selected.amount : undefined;
        model.baseUnit = r.stock_item.unit;
        model.unitOfMeasure = selected ? selected.unitOfMeasure : model.measurements.filter(m => m.baseUnit);
        this.stockItems.push(model);
      });

      this.queryModel.currentPage = requestedPage;
      this.queryModel.totalNumberOfItems = result.pagingResult.currentNumberOfItems;
      this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;

      this.loading = false;
      this.lastSuccessfulQueryRequest = request;
    });
  }

  private loadStockItems(pageNumber?: number) {
    this.loading = true;
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const request: StockItemQuery = {
      q: Strings.undefinedOrNonEmpty(this.filter),
      usable_for_form_field: true,
      with_amount: true,
      disabled: false,
      type: this.data.stockItemType,
      category_ids: this.data.usableCategoryIds.length > 0 ? this.data.usableCategoryIds.join() : undefined,
      excluded_ids: this.data.excludedIds.length > 0 ? this.data.excludedIds.join() : undefined,
      order: Services.createOrderFieldParameter(StockItem.Keys.toOrderFieldKey, Set.of(this.queryModel.getOrder())),
      page_number: requestedPage,
      number_of_items: this.queryModel.itemsPerPage,
    };
    this.stockItemService.query(request).subscribe((result: ResourceQueryResult<StockItem>) => {
      this.stockItems = [];
      result.items.forEach(r => {
        const model = new StockItemSelectorModel();
        const selected = this.selectedStockItems.find(item => item.id === r.id);
        model.selected = !!selected;
        model.id = r.id;
        model.name = r.name;
        model.productCode = r.product_code;
        model.weightInGrams = r.weight_in_grams;
        model.externalId = r.external_id;
        model.packageData = r.package_data;
        model.measurements = this.unitOfMeasureMultiselectProvider.toMultiselectOptionItems(r.measurements);
        model.currencyCode = r.unit_price.currency_code;
        model.inStockAmount = r.stock_amount ? r.stock_amount.reduce((sum, current) => sum + current.amount, 0) : 0;
        model.allStockAmount = r.stock_amount;
        model.amount = selected ? selected.amount : undefined;
        model.baseUnit = r.unit;
        model.unitOfMeasure = selected ? selected.unitOfMeasure : model.measurements.filter(m => m.baseUnit);
        this.stockItems.push(model);
      });

      this.queryModel.currentPage = requestedPage;
      this.queryModel.totalNumberOfItems = result.pagingResult.currentNumberOfItems;
      this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;

      this.loading = false;
      this.lastSuccessfulQueryRequest = request;
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  saveSelection() {
    if (this.allSelected) {
      if (this.lastSuccessfulQueryRequest) {
        this.lastSuccessfulQueryRequest.page_number = undefined; // Load all items
        this.lastSuccessfulQueryRequest.number_of_items = undefined; // Load all items
        this.lastSuccessfulQueryRequest.fields = 'id';
        this.stockItemService.query(this.lastSuccessfulQueryRequest).subscribe((result: ResourceQueryResult<StockItem>) => {
          const ids = result.items.map(r => r.id);
          this.dialogRef.close({
            selectedStockItemIds: ids
          });
        });
      } else {
        this.dialogRef.close({
          selectedStockItemIds: []
        });
      }
    } else {
      this.dialogRef.close(this.selectedStockItems.length > 0 ? {
        selectedStockItems: this.selectedStockItems
      } : undefined);
    }
  }

  orderBy(field: StockItem.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.load(1);
  }

  onPageChange(page: number) {
    this.load(page);
  }

  onItemsPerPageChange(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.load(1);
  }

  toggleSelected(record: StockItemSelectorModel) {
    const selected = record.selected;
    if (selected) {
      this.selectedStockItems.splice(this.selectedStockItems.findIndex(i => i === record), 1);
    } else {
      this.selectedStockItems.push(record);
    }
    record.selected = !selected;
    record.amount = undefined;
  }

  selectAll() {
    if (this.allSelected) {
      this.selectedStockItems = [];
      this.stockItems.forEach(r => r.selected = false);
    }
    this.allSelected = !this.allSelected;
  }

  public static openSelector(
    dialog: MatDialog,
    data: StockItemSelectorDialogData,
    resultCallback: (result?: StockItemSelectorDialogResult) => void) {
    const dialogRef = dialog.open(StockItemSelectorDialogComponent, {
      panelClass: 'custom-dialog',
      closeOnNavigation: true,
      data: data
    });

    dialogRef.afterClosed().subscribe((result: StockItemSelectorDialogResult) => {
      resultCallback(result);
    });
  }

}

export class StockItemSelectorModel {
  selected: boolean = false;
  id: number;
  name: string = '';
  productCode: string = '';
  externalId: string = '';
  inStockAmount: number = 0;
  amount?: number;
  baseUnit: string = '';
  packageData?: StockItemUnitOfMeasure.StockItemUnitOfMeasure;
  measurements: StockItemUnitOfMeasureMultiselectOptionItem[] = [];
  unitOfMeasure: StockItemUnitOfMeasureMultiselectOptionItem[] = [];
  currencyCode: string;
  allStockAmount?: StockItemStock[];

  private weight?: Weight;

  set weightInGrams(value: number | undefined) {
    if (!value) {
      return;
    }
    this.weight = WeightFactory.createWeightFromGram(value, 'kg');
  }

  get weightInGrams(): number | undefined {
    if (!this.weight) {
      return undefined;
    }
    return this.weight.toGrams();
  }

  get formattedWeight(): string {
    if (!this.weight) {
      return '';
    }
    return this.weight.toString();
  }

  get unitOfMeasureId(): number {
    return this.selectedUnitOfMeasure?.id!;
  }

  get selectedUnitOfMeasure(): StockItemUnitOfMeasureMultiselectOptionItem | undefined {
    return this.unitOfMeasure.length > 0 ? this.unitOfMeasure[0] : undefined;
  }

  isBaseUnitSelected(): boolean {
    if (!this.selectedUnitOfMeasure) {
      return true;
    }
    return this.selectedUnitOfMeasure.baseUnit;
  }

  get baseAmount(): number | undefined {
    if (!this.amount || !this.selectedUnitOfMeasure) {
      return undefined;
    }
    return this.amount * this.selectedUnitOfMeasure.conversion;
  }

  get baseAmountDecimal(): Decimal | undefined {
    if (!this.baseAmount) {
      return undefined;
    }
    return new Decimal(this.baseAmount);
  }
}

export interface StockItemSelectorDialogData {
  usableCategoryIds: number[];
  excludedIds: number[];
  loadRecordsOfStock?: number;
  amountLabel: string;
  stockItemType?: StockItemType;
}

export interface StockItemSelectorDialogResult {
  selectedStockItemIds?: number[];
  selectedStockItems?: StockItemSelectorModel[];
}
