/* eslint-disable */
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DqlSearchFieldComponent } from '../dql-search-field.component';
import {
  ComplexDqlExpressionBuilder,
  DqlBinaryCriteriaModel,
  DqlCriteriaModel,
  DqlExpressionBuilder,
  DqlLogicalOperation,
  DqlLogicalOperationObject,
  DqlOperations,
  SimpleDqlExpressionBuilder
} from '../../dql-search-container/dql-search-container.model';
import { TranslateService } from '@ngx-translate/core';
import { Angular2Multiselects } from '../../../../util/multiselect';
import { RightService } from '../../../../lib/right.service';
import { RightModel } from '../../../../app.rights';
import { DqlSearchFieldByIdLoader } from './dql-search-field-by-id.loader';
import { UserService } from '../../../../lib/user.service';
import { UserGroupService } from '../../../../lib/user-group.service';
import { DeviceManagementService } from '../../../../lib/device-management.service';
import { CustomerRecordService } from '../../../../lib/customer/customer-record.service';
import { CompanyService } from '../../../../lib/company/company.service';
import { CompanyLocationService } from '../../../../lib/company-location/company-location.service';
import { Set } from 'immutable';
import { ProcessMultiselectProvider } from '../../../../lib/process/process-multiselect.provider';
import { AngularMultiSelect } from '../../../../fork/angular2-multiselect-dropdown/src/lib';
import { CustomerRecordContactLocationMultiselectProvider } from '../../../../lib/customer/contact/customer-record-contact-location-multiselect.provider';
import { MultiselectOptionItem } from '../../../../util/core-utils';
import { UserMultiselectProvider } from '../../../../lib/user/user-multiselect.provider';
import { UserGroupMultiselectProvider } from '../../../../lib/user-group/user-group-multiselect.provider';
import { CustomerRecordMultiselectProvider } from '../../../../lib/customer/customer-record-multiselect-provider.service';
import { CompanyMultiselectProvider } from '../../../../lib/company/company-multiselect.provider';
import { CompanyLocationMultiselectProvider } from '../../../../lib/company-location/company-location-multiselect.provider';
import {
  ContractNumberMultiselectProvider
} from "../../../../lib/customer/contract-number/contract-number-multiselect-provider.service";

/* eslint-enable */

@Component({
  selector: 'app-dql-search-field-by-id',
  templateUrl: './dql-search-field-by-id.component.html',
  styleUrls: ['./dql-search-field-by-id.component.scss']
})
export class DqlSearchFieldByIdComponent implements OnInit, DqlSearchFieldComponent, AfterViewInit {
  @Input()
  disableDelete: boolean = false;
  @Output()
  onDelete: EventEmitter<DqlExpressionBuilder> = new EventEmitter<DqlExpressionBuilder>();

  private _expr: ComplexDqlExpressionBuilder;

  criteriaTemplate: DqlCriteriaModel;

  data: MultiselectOptionItem<number>[] = [];
  selectedValue: MultiselectOptionItem<number>[] = [];

  dropdownSettings?: Angular2Multiselects.Settings;

  loader: DqlSearchFieldByIdLoader;

  private logical = new DqlLogicalOperationObject(DqlLogicalOperation.OR);

  @ViewChild('multiSelect')
  multiSelect: AngularMultiSelect;

  @Input()
  set expression(exp: DqlExpressionBuilder) {
    if (exp instanceof SimpleDqlExpressionBuilder) {
      this.criteriaTemplate = exp.criteria;
      this._expr = new ComplexDqlExpressionBuilder();
      this.onItemSelect();
    }
    if (exp instanceof ComplexDqlExpressionBuilder) {
      this._expr = exp;
      this.criteriaTemplate = new DqlBinaryCriteriaModel();
      this.criteriaTemplate.field = this._expr.getFirstCriteria()!.field;
      this.criteriaTemplate.operation = this._expr.getFirstCriteria()!.operation;
    }
    this._expr.closeExpression();
    this.criteriaTemplate.operationChanged().subscribe((op) => {
      if (op === DqlOperations.EQUALS) {
        this.logical.operation = DqlLogicalOperation.OR;
      }
      if (op === DqlOperations.NOT_EQUALS) {
        this.logical.operation = DqlLogicalOperation.AND;
      }
      this._expr.changeCriteriaOperation(op);
    });
  }

  get expression(): DqlExpressionBuilder {
    return this._expr;
  }

  constructor(private translateService: TranslateService,
              private rightService: RightService,
              private userMultiselectProvider: UserMultiselectProvider,
              private userGroupMultiselectProvider: UserGroupMultiselectProvider,
              private mobileAppService: DeviceManagementService,
              private customerRecordMultiselectProvider: CustomerRecordMultiselectProvider,
              private contactLocationMultiselectProvider: CustomerRecordContactLocationMultiselectProvider,
              private companyMultiselectProvider: CompanyMultiselectProvider,
              private companyLocationMultiselectProvider: CompanyLocationMultiselectProvider,
              private processMultiselectProvider: ProcessMultiselectProvider,
              private contractNumberMultiselectProvider: ContractNumberMultiselectProvider
  ) {
    this.loader = new DqlSearchFieldByIdLoader(this.userMultiselectProvider,
      this.userGroupMultiselectProvider,
      this.mobileAppService,
      this.customerRecordMultiselectProvider,
      this.contactLocationMultiselectProvider,
      this.companyMultiselectProvider,
      this.companyLocationMultiselectProvider,
      this.processMultiselectProvider,
      this.contractNumberMultiselectProvider);
    this.loadRightModels();
    this.loader.data.subscribe(d => {
      this.data = d;
    });
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe((resolver) => {
      this.loader.rightModel = RightModel.of(resolver);
    });
  }

  ngOnInit() {
    this.initDropDown();
  }

  ngAfterViewInit() {
    if (this.criteriaTemplate.field) {
      this.loader.dataType = this.criteriaTemplate.field.dataType;
      this.loadSelectedValues(this._expr);
    }
  }

  initDropDown() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .badgeShowLimit(10)
      .remoteSearch(true)
      .build();
  }

  private loadSelectedValues(expr: ComplexDqlExpressionBuilder) {
    this.selectedValue = [];
    const ids: number[] = [];
    expr.expressionList.forEach(e => {
      if (e instanceof SimpleDqlExpressionBuilder) {
        const value = e.criteria.getValue();
        if (value.length > 0) {
          ids.push(+value);
        }
      }
    });
    this.loader.loadSelected(Set.of(...ids)).subscribe(result => {
      result.forEach(r => {
        const ref = ids.find(i => i === r.id);
        if (ref) {
          this.selectedValue.push(r);
        }
      });
    });
  }

  load(q: string) {
    this.loader.load(q);
  }

  onItemSelect(item?: MultiselectOptionItem<number>) {

    if (item) {
      let ex: DqlExpressionBuilder | undefined = undefined;
      this._expr.expressionList.forEach(e => {
        if (e instanceof SimpleDqlExpressionBuilder) {
          const value = e.criteria.getValue();
          if (value.length > 0 && +value === item.id) {
            ex = e;
          }
        }
      });
      if (ex) {
        return;
      }
    }

    const criteria = new DqlBinaryCriteriaModel();
    criteria.field = this.criteriaTemplate.field;
    criteria.operation = this.criteriaTemplate.operation;
    const expr = new SimpleDqlExpressionBuilder();
    expr.addCriteria(criteria);
    expr.closeExpression();
    this._expr.addExpression(expr);
    if (this._expr.logicalList.length < this._expr.expressionCount - 1) {
      this._expr.addLogical(this.logical);
    }
    if (item) {
      criteria.value = '' + item.id;
    }
  }

  onItemDeselect(item: MultiselectOptionItem<number>) {
    let ex: DqlExpressionBuilder | undefined = undefined;
    this._expr.expressionList.forEach(e => {
      if (e instanceof SimpleDqlExpressionBuilder) {
        const value = e.criteria.getValue();
        if (value.length > 0 && +value === item.id) {
          ex = e;
        }
      }
    });
    if (ex) {
      this._expr.removeExpression(ex);
    }
  }

  onSelectAll() {
    this.data.forEach(item => {
      this.onItemSelect(item);
    });
  }

  onDeselectAll() {
    this.data.forEach(item => {
      this.onItemDeselect(item);
    });
  }
}
