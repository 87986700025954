import { Component, OnInit, ViewChild } from '@angular/core';
import { Angular2Multiselects } from '../../../util/multiselect';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { FieldValidationError, ForwardingNgFormRef, LocalFormGroupValidationErrors, OrderType } from '../../../lib/util/services';
import { AndroidAppVersion, AndroidAppVersionService } from '../../../lib/andorid-app-version/android-app-version.service';
import { MultiselectOptionItemWithData, SelectUtils, UiConstants } from '../../../util/core-utils';
import { Strings } from '../../../lib/util/strings';
import { Set } from 'immutable';
import { InputMask } from '../../../util/input-masks';
import { AndroidAppVersionSettingsModel } from './android-app-version-settings.model';

@Component({
  selector: 'app-android-app-version-settings',
  templateUrl: './android-app-version-settings.component.html',
  styleUrls: ['./android-app-version-settings.component.scss']
})
export class AndroidAppVersionSettingsComponent implements OnInit {

  // If you need to access certain classes from HTML, declare them here
  SelectUtils = SelectUtils;
  InputMask = InputMask;
  UiConstants = UiConstants;

  dropdownSettings: Angular2Multiselects.Settings;
  @ViewChild('f', { static: true })
  form: NgForm;
  formGroup: FormGroup;
  private formGroupValidationErrors: LocalFormGroupValidationErrors;
  private fieldErrors: FieldValidationError<AndroidAppVersion.ValidatedField> =
    FieldValidationError.empty<AndroidAppVersion.ValidatedField>();

  model = new AndroidAppVersionSettingsModel();

  versions: MultiselectOptionItemWithData<number>[] = [];

  private versionPredicate?: string;

  constructor(private androidAppVersionService: AndroidAppVersionService,
              private fb: FormBuilder) {
    this.formGroup = this.createFormGroup(this.fb);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(
      this.createForwardingHtmlForm(),
      this.formGroup
    );
  }

  ngOnInit() {
    this.initDropdownSettings();
    this.loadModel();
    this.initSearch();
  }

  initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .translate(true)
      .build();
  }

  initSearch() {
    this.onVersionSearch();
  }

  onVersionSearch(predicate?: string, completion?: () => void) {
    this.versionPredicate = predicate;
    this.androidAppVersionService.query({
      versionName: Strings.undefinedOrNonEmpty(this.versionPredicate),
      paging: {
        pageNumber: 1,
        numberOfItems: UiConstants.autocompletePageSize,
      },
      noProgressBar: true,
      orders: Set.of({type: OrderType.ASC, field: AndroidAppVersion.OrderField.VERSION_NAME}),
    }).subscribe(result => {
      this.versions = result.items.toArray().map(version => {
        return this.toVersionItem(version);
      });
      if (completion) {
        completion();
      }
    });
  }

  loadModel() {
    this.androidAppVersionService.getSettings().subscribe(settings => {
      this.model.autoUpdateEnabled = settings.autoUpdateEnabled;
      if (settings.minimumVersion) {
        this.model.minimumVersion = [this.toVersionItem(settings.minimumVersion)];
      }
    });
  }

  toVersionItem(version: AndroidAppVersion.AndroidAppVersion) {
    return {
      id: version!.id,
      itemName: version!.versionName,
      data: version!
    };
  }

  submit() {
    if (this.hasLocalFieldError() || this.fieldErrors.hasError()) {
      return;
    }
    this.androidAppVersionService.updateSettings(this.model.toRequest()).subscribe(result => {

    });
  }

  private createForwardingHtmlForm() {
    return new ForwardingNgFormRef({
      formFn: () => {
        return this.form;
      }
    });
  }

  private createFormGroup(fb: FormBuilder): FormGroup {
    return fb.group(
      {
        autoUpdateEnabled: fb.control(
          {value: this.model.autoUpdateEnabled},
          []
        ),
        minimumVersion: fb.control(
          {value: this.model.minimumVersion},
          []
        ),
      }
    );
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  onVersionDeleted(id: number) {
    if (this.model.minimumVersionId === id) {
      this.model.minimumVersion = [];
    }
    this.onVersionSearch(this.versionPredicate);
  }

  onVersionChanged(version: { id: number, versionName: string }) {
    if (this.model.minimumVersionId === version.id) {
      this.onVersionSearch(version.versionName, () => {
        const newData = this.versions.find(v => v.id === version.id);
        this.model.minimumVersion = [];
        if (newData) {
          this.model.minimumVersion.push(newData);
        }
      });
    }
    else {
      this.onVersionSearch(this.versionPredicate);
    }
  }

}
