<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <!--BASIC DATA-->
  <div class="card">
    <div class="card-header">
      {{'DOCUMENT_HTML_PANEL_HEADING_ADD'|translate}}
    </div>
    <div class="card-body">
      <form class="form-horizontal bordered-row" (ngSubmit)="f.form.valid && update()" #f="ngForm" novalidate>

        <!--NAME-->
        <div class="form-group">
          <label class="edit-form-label form-control-label">{{'COMMON_NAME' | translate}}</label>
          <div class="edit-form-input-group">
            <input
              type="text"
              class="form-control"
              maxlength="{{getTextMaximumLength()}}"
              placeholder="{{'COMMON_NAME' | translate}}"
              [(ngModel)]="model.name"
              name="name"
              id="name"
              #code="ngModel">
          </div>
        </div>

        <!--CODE-->
        <div class="form-group"  [ngClass]="{ 'has-danger': (f.submitted && !code.valid) }">
          <label class="edit-form-label form-control-label required-field-label">
            {{'DOCUMENT_HTML_CODE' | translate}}
          </label>
          <div class="edit-form-input-group">
            <input
              type="text"
              class="form-control"
              maxlength="{{getTextMaximumLength()}}"
              placeholder="{{'DOCUMENT_HTML_CODE' | translate}}"
              [(ngModel)]="model.code"
              [ngClass]="{ 'form-control-danger': (f.submitted && !code.valid) }"
              name="code"
              id="code"
              #code="ngModel"
              required>
            <div class="form-control-feedback" *ngIf="f.submitted && !code.valid">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
          </div>
        </div>

        <!--COMMENT-->
        <div class="form-group">
          <label class="edit-form-label form-control-label">{{'DOCUMENT_HTML_COMMENT' | translate}}</label>
          <div class="edit-form-input-group">
            <input
              type="text"
              class="form-control"
              maxlength="{{getTextMaximumLength()}}"
              placeholder="{{'DOCUMENT_HTML_COMMENT' | translate}}"
              [(ngModel)]="model.comment"
              name="comment"
              id="comment"
              #description="ngModel">
          </div>
        </div>

        <!--GROUP-->
        <div class="form-group">
          <label class="edit-form-label form-control-label">{{'DOCUMENT_GROUP' | translate}}</label>
          <div class="edit-form-input-group">
            <select
              class="form-control"
              title=""
              [(ngModel)]="model.document_group"
              id="document_group"
              name="document_group"
              #document_group="ngModel">
              <option *ngFor="let documentGroup of documentGroupList" [ngValue]="documentGroup">{{documentGroup.text}}</option>
            </select>
          </div>
        </div>

        <!--LANGUAGE-->
        <div class="form-group">
          <label class="edit-form-label form-control-label">{{'DOCUMENT_LANGUAGE' | translate}}</label>
          <div class="edit-form-input-group">
            <select
              class="form-control"
              title=""
              [(ngModel)]="model.language_code"
              id="language"
              name="language"
              #language="ngModel">
              <option *ngFor="let language of documentLanguageList" [ngValue]="language">{{language.text}}</option>
            </select>
          </div>
        </div>

        <!--SEND BUTTON-->
        <div>
          <div class="edit-form-button-group">
            <input type="submit" class="btn btn-primary" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
          </div>
        </div>
      </form>
    </div>  <!-- ./card-body end -->

  </div> <!-- ./card end -->

  <div class="container-vertical-padding"></div>

  <!--HTML EDITOR-->
  <div class="card">
    <div class="card-header">
      {{'DOCUMENT_HTML_PANEL_HEADING_ADD'|translate}}
    </div>
    <div class="card-body">
      <form class="form-horizontal bordered-row" (ngSubmit)="f.form.valid && update()" #f="ngForm" novalidate>

        <!--EDITOR-->
        <div class="edit-form-input-group">
          <div id="editor">
          </div>
        </div>

        <div class="container-vertical-padding"></div>

        <!--SEND BUTTON-->
        <div>
          <div class="edit-form-button-group">
            <input type="submit" class="btn btn-primary" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
          </div>
        </div>
      </form>
    </div> <!-- ./card-body end -->
  </div> <!-- ./card end -->
</div>
