import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { ServiceInfoResource, ServiceInfoResourceService } from './service-info-resource.service';

@Injectable()
export class ServiceInfoService implements ServiceInfo.Service {

  constructor(private resourceService: ServiceInfoResourceService) {
  }

  get(): Observable<ServiceInfo.ServiceInfo> {
    return Observable.create((observer: Observer<ServiceInfo.ServiceInfo>) => {
      return this.resourceService.get().subscribe(
        (result: ServiceInfoResource.ServiceInfo) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  private toPublic(r: ServiceInfoResource.ServiceInfo): ServiceInfo.ServiceInfo {
    return {
      base: r.base,
      appVersion: r.app_version,
      revision: r.revision,
      commitYear: r.commit_year,
      commitTime: r.commit_time,
      internalApiVersion: r.internal_api_version,
      externalApiVersion: r.external_api_version,
      licenseApiVersion: r.license_api_version,
      licenseName: r.license_name,
      supportedDatabase: r.supported_database,
      serverInfo: r.server_info,
      osName: r.os_name,
      jreVersion: r.jre_version,
      hostname: r.hostname,
      timezone: r.timezone,
      androidAppInfo: r.android_app_info ? {
        versionName: r.android_app_info.version_name,
        versionCode: r.android_app_info.version_code,
        publicInstallPageUrl: r.android_app_info.public_install_page_url
      }: undefined
    };
  }

}

export namespace ServiceInfo {

  export interface Service {

    // <editor-fold desc="CRUD">

    get(): Observable<ServiceInfo>;

    // </editor-fold>

  }

  export interface ServiceInfo {
    base: string,
    appVersion: string,
    revision: string,
    commitYear: string,
    commitTime: string,
    internalApiVersion: string,
    externalApiVersion: string,
    licenseApiVersion: string,
    licenseName: string,
    supportedDatabase: string,
    serverInfo: string,
    osName: string,
    jreVersion: string,
    hostname: string,
    timezone: string,
    androidAppInfo?: {
      versionName: string;
      versionCode: string;
      publicInstallPageUrl: string;
    }
  }

}
