import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { QueryResult } from './util/services';
import { List } from 'immutable';

@Injectable()
export class VatRateService {

  public static DEFAULT_VAT_RATE: number = 27;
  public static DEFAULT_ZERO_VAT_RATE_TYPE: string = 'TAM';

  query(): Observable<QueryResult<VatRate.VatRate>> {
    return Observable.create((observer: Observer<QueryResult<VatRate.VatRate>>) => {
      const l = List.of<VatRate.VatRate>({
        value: 0
      }, {
        value: 5
      }, {
        value: 18
      }, {
        value: 27
      });
      observer.next({
        items: l,
        pagingResult: {
          numberOfPages: 1,
          currentNumberOfItems: l.size,
          totalNumberOfItems: l.size,
        }
      });
      observer.complete();
      return function () {
      };
    });
  }

}

export namespace VatRate {

  export class VatRate {
    value: number;
  }

  export function compareZeroVatRateTypeToString(o1?: VatRate.ZeroVatRateType, o2?: String): boolean {
    if (o1 && o2) {
      return o1 === o2;
    }
    else {
      return false;
    }
  }

  export type ZeroVatRateType =
    'TAM' |
    'AAM' |
    'EAM' |
    'KBAET' |
    'EUFAD37' |
    'NAM' |
    'ATK' |
    'UNKNOWN';

  export interface ZeroVatRateTypeObject {
    type: ZeroVatRateType;
    stringKey: string;
    disabled: boolean;
  }

  export const zeroVatRateTypes: ZeroVatRateTypeObject[] = [
    {type: 'TAM', stringKey: 'TAM', disabled: false},
    {type: 'AAM', stringKey: 'AAM', disabled: false},
    {type: 'EAM', stringKey: 'EAM', disabled: false},
    {type: 'KBAET', stringKey: 'KBAET', disabled: false},
    {type: 'EUFAD37', stringKey: 'EUFAD37', disabled: false},
    {type: 'NAM', stringKey: 'NAM', disabled: false},
    {type: 'ATK', stringKey: 'ATK', disabled: false},
    {type: 'UNKNOWN', stringKey: 'COMMON_VALUE_OTHER', disabled: true},
  ]

}
