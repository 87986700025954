/* eslint-disable */
import { Component, ViewChild } from '@angular/core';
import {
  FormRecordFieldContext,
  FormRecordFieldValueUpdateArgs,
  FormRecordFieldView,
  FormRecordFieldViewContext,
  FormRecordFieldViewModel,
  SetTmpReadonlyArgs,
  SetTmpReadonlyResult
} from '../../../../../util/form/form-utils';
import { Form } from '../../../../../lib/form/form.service';
import { FormRef } from '../../../../../lib/util/services';
import { FieldActivationState, FieldActivationStateResolver } from '../../../../../util/form/form-editors';
import { Command } from '../../../../../util/command';
import { FormRecord } from '../../../../../lib/form/form-record.service';
import { DocumentFileType } from '../../../../../util/form/form-field-document';
import { Set } from 'immutable';
import { FormGroup } from '@angular/forms';
import { FileItem, FileUploader, ParsedResponseHeaders } from 'ng2-file-upload';
import { NamedBlobFile } from '../../../../../lib/util/downloaded-files';
import { UiConstants } from '../../../../../util/core-utils';
import { StringKey } from '../../../../../app.string-keys';
import { ToasterService } from '../../../../../fork/angular2-toaster/angular2-toaster';
import { TranslateService } from '@ngx-translate/core';
import { FormAttachment, FormAttachmentService } from '../../../../../lib/form/form-attachment.service';
import { LoadingHandler } from '../../../../../lib/loading-handler';
import { RefreshFormOnChangeService } from '../../../../../lib/refresh-form-on-change.service';
import { FormRecordInactivityManager } from '../../manager/form-record-inactivity-manager';
import { AuthTokenStorage } from '../../../../../lib/util/storages';
import { ResourceHelper } from '../../../../../lib/util/http-services';
import { empty, Observable, of, Subject } from 'rxjs';
import {
  MaterialAttachmentGalleryComponent,
  ThumbnailLoaderFactory
} from '../../../../../shared/material-attachment-gallery/material-attachment-gallery.component';
import {
  FileAttachment,
  FileAttachmentResource,
  FileAttachments,
  FileAttachmentUpdateRequest
} from '../../../../../lib/util/file-attachments';
import { tap } from 'rxjs/operators';
import { EmptyMessage } from '../../../../../lib/util/messages';
import {AppTypeHelperService} from "../../../../../lib/util/app-type-helper.service";

/* eslint-enable */

@Component({
  selector: 'app-form-record-photo-field',
  templateUrl: './form-record-photo-field.component.html',
  styleUrls: ['./form-record-photo-field.component.scss']
})
export class FormRecordPhotoFieldComponent implements FormRecordFieldView, ThumbnailLoaderFactory {

  public readonly selector: Form.FieldDataTypeSelector.PHOTO;

  @ViewChild(MaterialAttachmentGalleryComponent)
  gallery: MaterialAttachmentGalleryComponent;

  uploadPath: string;
  fileUploader: FileUploader;
  _loading = false;

  formGroup: FormGroup;

  model: Model = new Model();

  // context fields are always optional
  formRecordFieldContext?: FormRecordFieldContext;
  formRecordInactivityManager?: FormRecordInactivityManager;
  private fieldId?: number;
  private formRecordId?: number;

  private htmlForm?: FormRef;

  tmpReadonly: boolean = false;

  private optionalValue: boolean = false;

  fileLoaderFactory: ThumbnailLoaderFactory = this;
  private readonlyFormFn: () => boolean = () => true;
  private readonlyFieldFn: () => boolean = () => false;

  private hiddenFieldFn: () => boolean = () => false;

  get nonEditable(): boolean {
    return FieldActivationStateResolver.isNonEditable(
      this.fieldActivationState
    );
  }

  get requiredDisabled(): boolean {
    return FieldActivationStateResolver.isRequiredDisabled(
      this.fieldActivationState
    );
  }

  get maxNumberOfPictures(): number {
    return this.model.multiSelect ? -1 : 1;
  }

  private get fieldActivationState(): FieldActivationState {
    return FieldActivationStateResolver.resolveFieldActivationState({
      readonlyFormFn: () => this.readonlyFormFn(),
      readonlyFieldFn: () => this.readonlyFieldFn(),
      tmpReadonlyFieldFn: () => this.tmpReadonly,
    });
  }

  get fileQueue(): FileItem[] {
    return this.fileUploader.queue.filter(i => !i.isUploaded);
  }

  constructor(private toasterService: ToasterService,
              private translateService: TranslateService,
              private formAttachmentService: FormAttachmentService,
              private refreshFormOnChangeService: RefreshFormOnChangeService,
              private appTypeHelperService: AppTypeHelperService,
              private resourceHelper: ResourceHelper) {
  }

  setTmpReadonly(args: SetTmpReadonlyArgs): Command<SetTmpReadonlyResult> {
    const previousTmpReadonly = this.tmpReadonly;
    const previousValue = this.model.values;
    return {
      execute: async () => {
        let changed = false;
        if (this.tmpReadonly !== args.tmpReadonly) {
          if (args.tmpReadonly) {
            changed = FieldActivationStateResolver.inactivationChangesTheValue({
              debugId: this.reqContext.field.title,
              valueIsEmpty: this.model.values.length === 0,
              valueEqualsDefaultValue: this.model.values.length === 0,
              defaultValueIsEmpty: true,
              canApplyDefaultValue: false
            });
            this.model.values = [];
            this.tmpReadonly = args.tmpReadonly; // last
          }
          else {
            this.tmpReadonly = args.tmpReadonly; // first
            this.model.values = [];
          }
        }
        return {
          changed: changed
        };
      },
      undo: async () => {
        this.tmpReadonly = previousTmpReadonly;
        this.model.values = previousValue;
      }
    };
  }

  private get reqContext() {
    return this.formRecordFieldContext!;
  }

  registerField(context: FormRecordFieldContext, originalModel?: any): any {
    if (originalModel) {
      this.model = originalModel;
    }
    this.formRecordFieldContext = context;
    this.fieldId = context.field.fieldId;
    this.htmlForm = context.htmlForm;
    this.readonlyFormFn = context.readonly;
    this.hiddenFieldFn = () => Form.FormFieldValidationType.HIDDEN === context.validationType;
    this.readonlyFieldFn = () => Form.FormFieldValidationType.READONLY === context.validationType
      || Form.FormFieldValidationType.HIDDEN === context.validationType;
    this.optionalValue = Form.FormFieldValidationType.REQUIRED !== context.validationType;
    this.model.title = context.field.title;
    const attrs = context.field.dataType.photoAttributes!;
    this.model.multiSelect = attrs.multiSelect;
      if (context.formRecordId) {
        this.formRecordId = context.formRecordId;
        this.uploadPath = this.createUploadPath(this.formRecordId, this.fieldId);
        if (context.fieldRecord) {
          this.registerFieldData(context.fieldRecord);
      }
    }
    this.initializeFileUploader();
    return this.model;
  }

  registerFieldData(fieldRecord: FormRecord.FieldComposed): void {
    const attrs = fieldRecord.data.photoAttributes!;
    if (fieldRecord) {
      if (attrs.values) {
        if (this.reqContext.cloning()) {
          // Do not use any data for cloning.
          this.model.values = [];
        }
        else {
          this.model.values = [];
          attrs.values.forEach((value => {
            if (value) {
              this.model.values.push(value);
            }
          }));
        }
      }
      else {
        this.model.values = [];
      }
    }
  }

  registerFieldViews(context: FormRecordFieldViewContext): void {
    this.formRecordInactivityManager = context.inactivityManager;
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return false;
  }

  validateWithInterrupt(): boolean {
    return false;
  }

  shouldNotifyAfterCreation(): boolean {
    return this.fileQueue.length > 0;
  }

  afterFormRecordCreation(formRecordId: number): Observable<any> {
    this.formRecordId = formRecordId;
    this.uploadPath = this.createUploadPath(this.formRecordId, this.fieldId!);
    this.fileUploader.setOptions({
      url: this.resourceHelper.getBaseUrl() + this.uploadPath,
      method: 'POST',
      authToken: AuthTokenStorage.getInstance().getToken(this.appTypeHelperService.appType())!,
    });
    const result: Subject<any> = new Subject<any>();
    this.fileUploader.onCompleteAll = () => {
      result.complete();
    };
    this.fileUploader.uploadAll();
    return result;
  }

  createModel(): FormRecordFieldViewModel {
    if (this.fieldId === undefined) {
      throw new Error('Field ID is undefined');
    }
    const attrs: FormRecord.FieldDataPhotoAttributes = {
      values: this.model.getValueSet()
    };
    return {
      fieldEditRequest: {
        fieldId: this.fieldId,
        data: {
          photoAttributes: attrs
        }
      }
    };
  }

  createUploadPath(formRecordId: number, fieldId: number): string {
    return '/form-records/' + formRecordId + '/field/' + fieldId + '/attachments';
  }

  initializeFileUploader() {
    this.fileUploader = new FileUploader({
      url: this.resourceHelper.getBaseUrl() + this.uploadPath,
      method: 'POST',
      authToken: AuthTokenStorage.getInstance().getToken(this.appTypeHelperService.appType())!,
    });

    this.fileUploader.onAfterAddingFile = (fileItem: any) => {
      if (this.formRecordId && this.fieldId) {
        this.fileUploader.uploadItem(fileItem);
      }
    };

    this.fileUploader.onBeforeUploadItem = (fileItem: any) => {
      if (this.formRecordId && this.fieldId) {
        this.fileUploader.options.url = this.resourceHelper.getBaseUrl() +
          this.createUploadPath(this.formRecordId, this.fieldId);
      }
      this._loading = true;
      LoadingHandler.getInstance().onRequest();
    };

    this.fileUploader.onErrorItem = ((item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any => {
      this._loading = false;
      this.fileUploader.cancelAll();
      LoadingHandler.getInstance().onResponse();
      return this.onUploadError(item, response, status, headers);
    });

    this.fileUploader.onSuccessItem = ((item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any => {
      this._loading = false;
      LoadingHandler.getInstance().onResponse();
      this.fileUploader.removeFromQueue(item);
      this.onUploadSuccess(response, () => {
        this.gallery.onAttachmentUploadSuccess(true);
      });
    });
  }

  onUploadSuccess(response: string, completion: () => void) {
    const receivedValues: FileAttachmentResource[] = JSON.parse(response);
    this.model.values = this.toPublicFileAttachmentArray(receivedValues);
    this.toasterService.pop({
      timeout: UiConstants.ToastTimeoutShort,
      type: UiConstants.toastTypeSuccess,
      title: this.translateService.instant(StringKey.COMMON_SUCCESS),
      body: this.translateService.instant(StringKey.FORM_DOCUMENT_SUCCESSFUL_UPLOAD)
    });
    this.refreshFormOnChangeService.onFormChanged();
    completion();
  }

  onUploadError(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    this.toasterService.pop({
      timeout: UiConstants.ToastTimeoutLong,
      type: UiConstants.toastTypeError,
      title: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_TITLE),
      body: this.translateService.instant(StringKey.FORM_DOCUMENT_UPLOAD_ERROR)
    });
    return null;
  }

  getValidMimeTypes(): string {
    if (!this.model.validMimeTypes) {
      return 'file';
    }
    else {
      return this.model.validMimeTypes.join(', ')
    }
  }

  toPublicFileAttachmentArray(r: FileAttachmentResource[]): FileAttachment[] {
    return FileAttachments.toPublicList(r);
  }

  toPublicFileAttachment(r: FileAttachmentResource): FileAttachment {
    return FileAttachments.toPublic(r);
  }

  updateValue(data: FormRecordFieldValueUpdateArgs) {
  }

  createFileLoader(attachment: FileAttachment): Observable<NamedBlobFile> {
    if (this.formRecordId && this.fieldId) {
      return this.formAttachmentService.downloadFile({
        formRecordId: this.formRecordId,
        fieldId: this.fieldId,
        fileId: attachment.id,
        attachment: attachment
      });
    }
    return empty();
  }

  createThumbnailLoader(attachment: FileAttachment): Observable<NamedBlobFile> {
    if (this.formRecordId && this.fieldId) {
      return this.formAttachmentService.downloadThumbnail({
        formRecordId: this.formRecordId,
        fieldId: this.fieldId,
        fileId: attachment.id,
        attachment: attachment
      });
    }
    return empty();
  }

  deleteAttachment(fileId: number): Observable<any> {
    if (this.formRecordId && this.fieldId) {
      return this.formAttachmentService.deleteFile({
        formRecordId: this.formRecordId,
        fieldId: this.fieldId,
        fileId: fileId
      }).pipe(tap((result) => {
          this.model.values = result.toArray();
          this.toasterService.pop({
            timeout: UiConstants.ToastTimeoutShort,
            type: UiConstants.toastTypeSuccess,
            title: this.translateService.instant(StringKey.COMMON_SUCCESS),
            body: this.translateService.instant(StringKey.FORM_DOCUMENT_SUCCESSFUL_DELETE)
          });
          this.refreshFormOnChangeService.onFormChanged();
        }
      ));
    }
    return empty();
  }

  getUploadPath(): string {
    return this.uploadPath;
  }

  hasThumbnails(): boolean {
    return true;
  }

  loadAttachments(): Observable<FileAttachment[]> {
    return of(this.model.values);
  }

  updateAttachment(r: FileAttachmentUpdateRequest): Observable<EmptyMessage> {
    if (this.formRecordId && this.fieldId) {
      const request: FormAttachment.AttachmentUpdateRequest = {
        fileId: r.fileId,
        name: r.name,
        fieldId: this.fieldId!,
        formRecordId: this.formRecordId!
      }
      return this.formAttachmentService.updateAttachment(request).pipe(tap(result => {
        const at = this.model.values.find(v => v.id === r.fileId);
        if (at) {
          at.fileName = r.name;
        }
        this.refreshFormOnChangeService.onFormChanged();
      }));
    }
    return empty();
  }

}

export class Model {
  title: string = '';
  multiSelect: boolean = false;
  readonly validMimeTypes: string[] = ['image/*'];
  documentFileType: DocumentFileType;
  values: FileAttachment[] = [];

  getValueSet(): Set<FileAttachment> {
    const ret: FileAttachment[] = [];
    if (this.values) {
      this.values.forEach((value) => {
        ret.push(value);
      });
    }
    return ret.length > 0 ? Set.of<FileAttachment>(...ret) : Set.of<FileAttachment>();
  }
}
