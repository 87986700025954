import {GuidedTour, TourStep} from 'ngx-guided-tour';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {TranslateUtils} from '../../util/translate';
import {map} from 'rxjs/operators';
import {SiteTourStringKeys} from './site-tour-string-keys';
import {MatDialogRef} from "@angular/material/dialog";


export abstract class SiteTourFactory {

  public constructor(private translateService: TranslateService) {
  }

  public getTour(siteTourRequest: SiteTourRequest): Observable<GuidedTour> {
    const rawTour: GuidedTour = this.getRawTour(siteTourRequest.skipCallback,
      siteTourRequest.completeCallback,
      siteTourRequest.baseComponent,
      siteTourRequest.dialogRef,
      siteTourRequest.startingStep);
    const keys: string[] = this.getKeys(rawTour);
    if (rawTour.resizeDialog) {
      if (rawTour.resizeDialog.title) {
        keys.push(rawTour.resizeDialog.title);
      }
      keys.push(rawTour.resizeDialog.content);
    }
    return this.translateService.get(keys)
      .pipe(map(o => {
        rawTour.steps.forEach(s => {
          s.content = TranslateUtils.extractValueFromObject(o, s.content);
          if (s.title) {
            s.title = TranslateUtils.extractValueFromObject(o, s.title);
          }
        })
        if (rawTour.resizeDialog) {
          if (rawTour.resizeDialog.title) {
            rawTour.resizeDialog.title = TranslateUtils.extractValueFromObject(o, rawTour.resizeDialog.title);
          }
          rawTour.resizeDialog.content = TranslateUtils.extractValueFromObject(o, rawTour.resizeDialog.content);
        }
        return rawTour;
      }));
  }

  protected getRawTour(
    /** Function will be called when tour is skipped */
    skipCallback?: (stepSkippedOn: number) => void,
    /** Function will be called when tour is completed */
    completeCallback?: () => void,
    /** base component */
    baseComponent?: SiteTourBaseComponent,
    dialogRef?: MatDialogRef<any>,
    startingStep?: string
  ): GuidedTour {
    return {
      /** Identifier for tour */
      tourId: this.tourKey,
      /** Use orb to start tour */
      useOrb: this.useOrb,
      /** Steps fo the tour */
      steps: this._getTourSteps(baseComponent, dialogRef, startingStep),
      /** Function will be called when tour is skipped */
      skipCallback: skipCallback,
      /** Function will be called when tour is completed */
      completeCallback: completeCallback,
      /**
       * Prevents the tour from advancing by clicking the backdrop.
       * This should only be set if you are completely sure your tour is displaying correctly on
       * all screen sizes otherwise a user can get stuck.
       */
      preventBackdropFromAdvancing: false,
      minimumScreenSize: 1048,
      resizeDialog: {
        title: SiteTourStringKeys.SITE_TOUR_RESIZE_DIALOG_TITLE,
        content: SiteTourStringKeys.SITE_TOUR_RESIZE_DIALOG_CONTENT
      }
    };
  }

  private _getTourSteps(baseComponent?: SiteTourBaseComponent, dialogRef?: MatDialogRef<any>, startingStep?: string): TourStep[] {
    const steps = this.getTourSteps(baseComponent, dialogRef);
    if (startingStep !== undefined) {
      let found = false;
      for (let i = 0; i < steps.length && !found; i++) {
        if (steps[i].selector !== startingStep) {
          steps[i].skipStep = true;
        } else {
          found = true;
        }
      }
    }
    return steps;
  }

  protected abstract getTourSteps(baseComponent?: SiteTourBaseComponent, dialogRef?: MatDialogRef<any>): TourStep[];

  protected abstract get tourKey(): SiteTourId;

  protected get useOrb(): boolean {
    return true;
  }

  public get timeout(): number {
    return 100;
  }

  private getKeys(rawTour: GuidedTour): string[] {
    const arr: string[] = [];
    rawTour.steps.forEach(s => {
      arr.push(s.content);
      if (s.title) {
        arr.push(s.title);
      }
    });
    return arr;
  }

}

export enum SiteTourId {
  HOME = 'HOME',
  TASK_RECORD_LIST_BASE = 'TASK_RECORD_LIST_BASE',
  TASK_RECORD_CREATE = 'TASK_RECORD_CREATE',
  TASK_RECORD_LIST_OPEN_1 = 'TASK_RECORD_LIST_OPEN_1',
  TASK_RECORD_LIST_OPEN_2 = 'TASK_RECORD_LIST_OPEN_2',
  TASK_DASHBOARD = 'TASK_DASHBOARD',
  ADMIN_DASHBOARD = 'ADMIN_DASHBOARD'
}

export interface SiteTourBaseComponent {

}

export interface SiteTourRequest {
  /** Function will be called when tour is skipped */
  skipCallback?: (stepSkippedOn: number) => void,
  /** Function will be called when tour is completed */
  completeCallback?: () => void,
  /** Sitetour interaction component */
  baseComponent?: SiteTourBaseComponent,
  /** Start tour from this step */
  startingStep?: string,
  /** Dialog */
  dialogRef?: MatDialogRef<any>
}

