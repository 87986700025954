/* eslint-disable */
import { Injectable } from '@angular/core';
import { Form } from '../../form/form.service';
import { Icon, IconService } from '../../task/icon.service';
import { Observable, Observer } from 'rxjs';
import { DownloadedFile } from '../../util/downloaded-files';
import { FieldValidationError, Order, PagingRequest, QueryResult, ResourceQueryResult, Services } from '../../util/services';
import { List, Map, Set } from 'immutable';
import { OffsetDateTime } from '../../util/dates';
import { LegacyWorkflowTaskResource, LegacyWorkflowTaskResourceService } from './legacy-workflow-task-resource.service';
import { FormResource } from '../../form/form-resource.service';
import { EmptyMessage } from '../../util/messages';
import { FieldError, ObservableErrorResourceParser } from '../../util/errors';
/* eslint-enable */

@Injectable()
export class LegacyWorkflowTaskService implements LegacyWorkflowTask.Service, Form.Service {

  private formMapper: Form.ResourceMapper;

  public static setWorkflowId(workflowId: number) {
    LegacyWorkflowTaskResourceService.workflow_id = workflowId;
  }

  constructor(private resourceService: LegacyWorkflowTaskResourceService,
              private iconService: IconService) {
    this.formMapper = new Form.ResourceMapper();
  }

  query(request: LegacyWorkflowTask.QueryRequest): Observable<QueryResult<LegacyWorkflowTask.WorkflowTask>> {
    return Observable.create((observer: Observer<QueryResult<LegacyWorkflowTask.WorkflowTask>>) => {
      const resourceRequest: LegacyWorkflowTaskResource.QueryRequest = {
        workflow_id: request.workflowId,
        workflow_task_id: Services.createIdParameter(request.workflowTaskIdSet),
        disabled: request.disabled,
        name: request.name,
        external_id: request.externalId,
        description: request.description,
        in_progress_state_name: request.inProgressStateName,
        finished_state_name: request.finishedStateName,
        q: request.queryText,
        order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined
      };
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<LegacyWorkflowTaskResource.WorkflowTask>) => {
          observer.next({
            items: this.toPublicList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  get(request: LegacyWorkflowTask.GetRequest): Observable<LegacyWorkflowTask.WorkflowTask> {
    return Observable.create((observer: Observer<LegacyWorkflowTask.WorkflowTask>) => {
      const resourceRequest: LegacyWorkflowTaskResource.GetRequest = {
        workflow_id: request.workflowId,
        workflow_task_id: request.workflowTaskId
      };
      return this.resourceService.get(resourceRequest).subscribe(
        (result: LegacyWorkflowTaskResource.WorkflowTask) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  create(request: LegacyWorkflowTask.CreateRequest): Observable<LegacyWorkflowTask.WorkflowTaskIdentity> {
    return Observable.create((observer: Observer<LegacyWorkflowTask.WorkflowTaskIdentity>) => {
      const resourceRequest: LegacyWorkflowTaskResource.CreateRequest = {
        workflow_id: request.workflowId,
        name: request.name,
        description: request.description,
        external_id: request.externalId,
        in_progress_state_name: request.inProgressStateName,
        finished_state_name: request.finishedStateName,
        customer_signature_enabled: request.customerSignatureEnabled,
        user_signature_enabled: request.userSignatureEnabled
      };
      return this.resourceService.create(resourceRequest).subscribe(
        (result: LegacyWorkflowTaskResource.WorkflowTaskIdentity) => {
          observer.next(this.toPublicIdentity(result));
        },
        (error: any) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  update(request: LegacyWorkflowTask.UpdateRequest): Observable<LegacyWorkflowTask.WorkflowTask> {
    return Observable.create((observer: Observer<LegacyWorkflowTask.WorkflowTask>) => {
      const resourceRequest: LegacyWorkflowTaskResource.UpdateRequest = {
        workflow_task_id: request.workflowTaskId,
        workflow_id: request.workflowId,
        name: request.name,
        description: request.description,
        external_id: request.externalId,
        in_progress_state_name: request.inProgressStateName,
        finished_state_name: request.finishedStateName,
        customer_signature_enabled: request.customerSignatureEnabled,
        user_signature_enabled: request.userSignatureEnabled,
        version: request.version,
      };
      return this.resourceService.update(resourceRequest).subscribe(
        (result: LegacyWorkflowTaskResource.WorkflowTask) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  setDisabled(request: LegacyWorkflowTask.DisableRequest): Observable<LegacyWorkflowTask.WorkflowTask> {
    return Observable.create((observer: Observer<LegacyWorkflowTask.WorkflowTask>) => {
      const resourceRequest: LegacyWorkflowTaskResource.DisableRequest = {
        workflow_id: request.workflowId,
        workflow_task_id: request.workflowTaskId,
        disabled: request.disabled
      };
      return this.resourceService.setDisabled(resourceRequest).subscribe(
        (result: LegacyWorkflowTaskResource.WorkflowTask) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  moveField(request: LegacyWorkflowTask.MoveFieldRequest): Observable<LegacyWorkflowTask.MoveFieldResponse> {
    return Observable.create((observer: Observer<LegacyWorkflowTask.MoveFieldResponse>) => {
      const resourceRequest: LegacyWorkflowTaskResource.MoveFieldRequest =
        this.toResourceMoveFieldRequest(request);
      return this.resourceService.moveField(resourceRequest).subscribe(
        (result: LegacyWorkflowTaskResource.MoveFieldResponse) => {
          observer.next(this.toPublicMoveFieldResponse(result));
        },
        (error: Error) => {
          observer.error(this.translateMoveFieldError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  // <editor-fold desc="Form">

  getForm(request: Form.GetFormRequest): Observable<Form.Form> {
    return Observable.create((observer: Observer<Form.Form>) => {
      const resourceRequest: FormResource.GetFormRequest =
        this.formMapper.toResourceGetFormRequest(request);
      return this.resourceService.getForm(resourceRequest).subscribe(
        (result: FormResource.Form) => {
          observer.next(this.formMapper.toPublicForm(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateGetFormError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  createGroup(request: Form.CreateGroupRequest): Observable<Form.CreateGroupResponse> {
    return Observable.create((observer: Observer<Form.CreateGroupResponse>) => {
      const resourceRequest: FormResource.CreateGroupRequest =
        this.formMapper.toResourceCreateGroupRequest(request);
      return this.resourceService.createFormGroup(resourceRequest).subscribe(
        (result: FormResource.CreateGroupResponse) => {
          observer.next(this.formMapper.toPublicCreateGroupResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateCreateGroupError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  updateGroup(request: Form.UpdateGroupRequest): Observable<Form.UpdateGroupResponse> {
    return Observable.create((observer: Observer<Form.UpdateGroupResponse>) => {
      const resourceRequest: FormResource.UpdateGroupRequest =
        this.formMapper.toResourceUpdateGroupRequest(request);
      return this.resourceService.updateFormGroup(resourceRequest).subscribe(
        (result: FormResource.UpdateGroupResponse) => {
          observer.next(this.formMapper.toPublicUpdateGroupResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateUpdateGroupError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  disableGroup(request: Form.DisableGroupRequest): Observable<Form.DisableGroupResponse> {
    return Observable.create((observer: Observer<Form.DisableGroupResponse>) => {
      const resourceRequest: FormResource.DisableGroupRequest =
        this.formMapper.toResourceDisableGroupRequest(request);
      return this.resourceService.disableFormGroup(resourceRequest).subscribe(
        (result: FormResource.DisableGroupResponse) => {
          observer.next(this.formMapper.toPublicDisableGroupResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateDisableGroupError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  moveGroup(request: Form.MoveGroupRequest): Observable<Form.MoveGroupResponse> {
    return Observable.create((observer: Observer<Form.MoveGroupResponse>) => {
      const resourceRequest: FormResource.MoveGroupRequest =
        this.formMapper.toResourceMoveGroupRequest(request);
      return this.resourceService.moveFormGroup(resourceRequest).subscribe(
        (result: FormResource.MoveGroupResponse) => {
          observer.next(this.formMapper.toPublicMoveGroupResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateMoveGroupError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  createField(request: Form.CreateFieldRequest): Observable<Form.CreateFieldResponse> {
    return Observable.create((observer: Observer<Form.CreateFieldResponse>) => {
      const resourceRequest: FormResource.CreateFieldRequest =
        this.formMapper.toResourceCreateFieldRequest(request);
      return this.resourceService.createFormField(resourceRequest).subscribe(
        (result: FormResource.CreateFieldResponse) => {
          observer.next(this.formMapper.toPublicCreateFieldResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateCreateFieldError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  updateField(request: Form.UpdateFieldRequest): Observable<Form.UpdateFieldResponse> {
    return Observable.create((observer: Observer<Form.UpdateFieldResponse>) => {
      const resourceRequest: FormResource.UpdateFieldRequest =
        this.formMapper.toResourceUpdateFieldRequest(request);
      return this.resourceService.updateFormField(resourceRequest).subscribe(
        (result: FormResource.UpdateFieldResponse) => {
          observer.next(this.formMapper.toPublicUpdateFieldResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateUpdateFieldError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  disableField(request: Form.DisableFieldRequest): Observable<Form.DisableFieldResponse> {
    return Observable.create((observer: Observer<Form.DisableFieldResponse>) => {
      const resourceRequest: FormResource.DisableFieldRequest =
        this.formMapper.toResourceDisableFieldRequest(request);
      return this.resourceService.disableFormField(resourceRequest).subscribe(
        (result: FormResource.DisableFieldResponse) => {
          observer.next(this.formMapper.toPublicDisableFieldResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateDisableFieldError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  moveFieldToGroup(request: Form.MoveFieldToGroupRequest): Observable<Form.MoveFieldToGroupResponse> {
    return Observable.create((observer: Observer<Form.MoveFieldToGroupResponse>) => {
      const resourceRequest: FormResource.MoveFieldToGroupRequest =
        this.formMapper.toResourceMoveFieldToGroupRequest(request);
      return this.resourceService.moveFormFieldToGroup(resourceRequest).subscribe(
        (result: FormResource.MoveFieldToGroupResponse) => {
          observer.next(this.formMapper.toPublicMoveFieldToGroupResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateMoveFieldToGroupError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  importForm(request: Form.ImportRequest): Observable<Form.Form> {
    return Observable.create((observer: Observer<Form.Form>) => {
      return this.resourceService.import({
        parent_id: request.parentId,
        ignore_disabled_items: request.ignoreDisabledFields,
        form: request.form,
        version: request.version
      }).subscribe((result: FormResource.Form) => {
          observer.next(this.formMapper.toPublicForm(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        }
      );
    });
  }

  exportForm(request: Form.ExportRequest): Observable<Form.FormImportDocument> {
    return Observable.create((observer: Observer<Form.FormImportDocument>) => {
      return this.resourceService.export({
        parent_id: request.parentId,
      }).subscribe((result: FormResource.FormImportDocument) => {
          observer.next({form: result.form, version: result.version});
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        }
      );
    });
  }

  getPdfs(request: LegacyWorkflowTask.GetPdfsRequest): Observable<LegacyWorkflowTask.Pdf[]> {
    return Observable.create((observer: Observer<LegacyWorkflowTask.Pdf[]>) => {
      const resourceRequest: LegacyWorkflowTaskResource.GetPdfsRequest = {
        workflow_id: request.workflowId,
        workflow_task_id: request.workflowTaskId
      };
      this.resourceService.getPdfs(resourceRequest).subscribe(
        (result: ResourceQueryResult<LegacyWorkflowTaskResource.Pdf>) => {
          const list: LegacyWorkflowTask.Pdf[] = [];
          result.items.forEach((r) => list.push({
            pdfTemplateId: r.template_id,
            creationTime: Services.toOffsetDateTime(r.creation_time),
            updateTime: Services.toOffsetDateTime(r.update_time),
            version: r.version,
            name: r.name,
            allowedTaskStatesForGeneration: r.allowed_task_states_for_generation
          }));
          observer.next(list);
        });
    });
  }

  downloadPdf(request: LegacyWorkflowTask.DownloadPdfRequest): Observable<DownloadedFile> {
    return this.resourceService.downloadPdf({
      workflow_id: request.workflowId,
      workflow_task_id: request.workflowTaskId,
      template_id: request.pdfTemplateId
    });
  }

  deletePdf(request: LegacyWorkflowTask.DeletePdfRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.resourceService.deletePdf({
        workflow_id: request.workflowId,
        workflow_task_id: request.workflowTaskId,
        template_id: request.pdfTemplateId
      }).subscribe((result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  updatePdf(r: LegacyWorkflowTask.UpdatePdfRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.resourceService.updatePdf({
        workflow_id: r.workflowId,
        workflow_task_id: r.workflowTaskId,
        template_id: r.pdfTemplateId,
        name: r.name,
        allowed_task_states_for_generation: r.allowedTaskStatesForGeneration
      }).subscribe((result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  // </editor-fold>

  private translateError(error: any): any {
    const res = ObservableErrorResourceParser.parseError(error);
    const fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
    const fieldErrorMap = ObservableErrorResourceParser.toFieldErrorMap(Keys.toValidatedField, fieldErrors);
    if (!fieldErrorMap.isEmpty()) {
      return FieldValidationError.of(fieldErrorMap);
    }
    return error;
  }

  private toPublicMoveFieldResponse(result: LegacyWorkflowTaskResource.MoveFieldResponse): LegacyWorkflowTask.MoveFieldResponse {
    return {};
  }

  private translateMoveFieldError(error: any): any {
    return error;
  }

  private toResourceMoveFieldRequest(request: LegacyWorkflowTask.MoveFieldRequest): LegacyWorkflowTaskResource.MoveFieldRequest {
    return {
      workflow_id: request.workflowId,
      workflow_task_id: request.workflowTaskId,
      index: request.index
    };
  }

  private toPublicList(resourceList: LegacyWorkflowTaskResource.WorkflowTask[]): List<LegacyWorkflowTask.WorkflowTask> {
    return List.of(...resourceList.map((r) => this.toPublic(r)));
  }

  private toPublic(r: LegacyWorkflowTaskResource.WorkflowTask): LegacyWorkflowTask.WorkflowTask {
    return {
      workflowId: r.workflow_id,
      workflowTaskId: r.workflow_task_id,
      disabled: r.disabled,
      creationTime: Services.toOffsetDateTime(r.creation_time),
      updateTime: Services.toOffsetDateTime(r.update_time),
      name: r.name,
      description: r.description,
      externalId: r.external_id,
      icon: this.iconService.toPublicIcon(r.icon),
      version: r.version,
      inProgressStateName: r.in_progress_state_name,
      finishedStateName: r.finished_state_name,
      customerSignatureEnabled: r.customer_signature_enabled,
      userSignatureEnabled: r.user_signature_enabled
    };
  }

  private toPublicIdentity(r: LegacyWorkflowTaskResource.WorkflowTaskIdentity): LegacyWorkflowTask.WorkflowTaskIdentity {
    return {
      workflowTaskId: r.workflow_task_id
    };
  }
}

export namespace LegacyWorkflowTask {

  export interface Service {

    // <editor-fold desc="CRUD">

    query(request: QueryRequest): Observable<QueryResult<WorkflowTask>>;

    get(request: GetRequest): Observable<WorkflowTask>;

    create(request: CreateRequest): Observable<WorkflowTaskIdentity>;

    update(request: UpdateRequest): Observable<WorkflowTask>;

    setDisabled(request: DisableRequest): Observable<WorkflowTask>;

    // </editor-fold>

  }

  // <editor-fold desc="CRUD">

  export interface WorkflowTask {
    workflowId: number;
    workflowTaskId: number;
    disabled: boolean;
    creationTime: OffsetDateTime;
    updateTime: OffsetDateTime;
    name: string;
    description: string;
    externalId: string;
    version: number;
    icon?: Icon.Icon;
    inProgressStateName: string;
    finishedStateName: string;
    customerSignatureEnabled: boolean;
    userSignatureEnabled: boolean;
  }

  export interface QueryRequest {
    workflowId: number;
    workflowTaskIdSet?: Set<number>;
    disabled?: boolean;
    name?: string;
    externalId?: string;
    description?: string;
    inProgressStateName?: string;
    finishedStateName?: string;
    queryText?: string;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
  }

  export interface GetRequest {
    workflowId: number;
    workflowTaskId: number;
  }

  export interface WorkflowTaskIdentity {
    workflowTaskId: number;
  }

  export interface CreateRequest {
    workflowId: number;
    name: string;
    description?: string;
    externalId?: string;
    inProgressStateName: string;
    finishedStateName: string;
    customerSignatureEnabled: boolean;
    userSignatureEnabled: boolean;
  }

  export interface UpdateRequest {
    workflowTaskId: number;
    workflowId: number;
    name: string;
    version: number;
    description?: string;
    externalId?: string;
    inProgressStateName: string;
    finishedStateName: string;
    customerSignatureEnabled: boolean;
    userSignatureEnabled: boolean;
  }

  export interface DisableRequest {
    workflowId: number;
    workflowTaskId: number;
    disabled: boolean;
  }

  export interface MoveFieldRequest {
    workflowId: number;
    workflowTaskId: number;
    index: number;
  }

  export interface MoveFieldResponse {
  }

  export interface GetPdfsRequest {
    workflowId: number;
    workflowTaskId: number;
  }

  export interface DeletePdfRequest {
    workflowId: number;
    workflowTaskId: number;
    pdfTemplateId: number;
  }

  export interface DownloadPdfRequest {
    workflowId: number;
    workflowTaskId: number;
    pdfTemplateId: number;
  }

  export interface UpdatePdfRequest {
    workflowId: number;
    workflowTaskId: number;
    pdfTemplateId: number;
    name: string;
    allowedTaskStatesForGeneration: string[];
  }

  export interface Pdf {
    pdfTemplateId: number;
    creationTime: OffsetDateTime;
    updateTime: OffsetDateTime;
    version: number;
    name: string;
    allowedTaskStatesForGeneration: string[];
  }

  export enum OrderField {
    ID,
    EXTERNAL_ID,
    NAME,
    DESCRIPTION,
  }

  export enum ValidatedField {
    UNKNOWN,
    NAME,
    EXTERNAL_ID
  }

}

// <editor-fold desc="Internal">

class Keys {

  private static readonly ID = 'id';
  private static readonly NAME = 'name';
  private static readonly DESCRIPTION = 'description';
  private static readonly EXTERNAL_ID = 'external_id';

  private static readonly orderFieldKeyMap: Map<LegacyWorkflowTask.OrderField, string> = Map.of(
    LegacyWorkflowTask.OrderField.ID, Keys.ID,
    LegacyWorkflowTask.OrderField.NAME, Keys.NAME,
    LegacyWorkflowTask.OrderField.DESCRIPTION, Keys.DESCRIPTION,
    LegacyWorkflowTask.OrderField.EXTERNAL_ID, Keys.EXTERNAL_ID,
  );

  private static readonly keyValidatedFieldMap: Map<string, LegacyWorkflowTask.ValidatedField> = Map.of(
    Keys.NAME, LegacyWorkflowTask.ValidatedField.NAME,
    Keys.EXTERNAL_ID, LegacyWorkflowTask.ValidatedField.EXTERNAL_ID,
  );

  public static toOrderFieldKey(field: LegacyWorkflowTask.OrderField): string {
    return Keys.orderFieldKeyMap.get(field)!;
  }

  public static toValidatedField(fieldKey: string): LegacyWorkflowTask.ValidatedField {
    return Keys.keyValidatedFieldMap.get(fieldKey, LegacyWorkflowTask.ValidatedField.UNKNOWN);
  }

}

// </editor-fold>

export interface WorkflowTaskCreateFieldErrorMap {
  name?: FieldError;
  external_id?: FieldError;
}


