/* eslint-disable */
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MultiselectOptionItem, QueryFieldModel, SelectUtils, UiConstants, UserItem } from '../../../../util/core-utils';
import { AuthService } from '../../../../lib/auth.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AuditLog, AuditLogList, AuditLogService } from '../../../../lib/statistics/audit-log/audit-log.service';
import { UserService } from '../../../../lib/user.service';
import { Arrays } from '../../../../lib/util/arrays';
import { Dates, OffsetDateTime } from '../../../../lib/util/dates';
import { Set } from 'immutable';
import {
  ApplicationIdTypeItem,
  AuditLogCallType,
  AuditLogCallTypeItem,
  AuditLogCallTypes,
  AuditLogDetailModel,
  AuditLogDeviceType,
  AuditLogDeviceTypeItem,
  AuditLogDeviceTypes,
  AuditLogResult,
  AuditLogResultItem,
  AuditLogResults,
  AuditLogSearchModel
} from '../../../../util/audit-log-utils';
import { TranslateService } from '@ngx-translate/core';
import { Strings } from '../../../../lib/util/strings';
import { Device, DeviceManagementService } from '../../../../lib/device-management.service';
import { BreadcrumbParent } from '../../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { StateName } from '../../../../app.state-names';
import { AuditLogSearch, AuditLogSearchService } from '../../../../lib/statistics/audit-log/audit-log-search.service';
import { combineLatest, Observable } from 'rxjs';
import { StringKey } from '../../../../app.string-keys';
import { Models } from '../../../../util/model-utils';
import { OrderType, ResourceQueryResult, Services } from '../../../../lib/util/services';
import { Logger, LoggerFactory } from '../../../../util/logger-factory';
import { RightResolver, RightService } from '../../../../lib/right.service';
import { RightModel } from '../../../../app.rights';
import { UserMultiselectProvider } from '../../../../lib/user/user-multiselect.provider';
import { Angular2Multiselects } from '../../../../util/multiselect';

/* eslint-enable */

@Component({
  selector: 'app-audit-log-list',
  templateUrl: 'audit-log-list.component.html',
  styleUrls: ['audit-log-list.component.scss']
})
export class AuditLogListComponent implements OnInit, AfterViewInit, OnDestroy {
  AuditLogList = AuditLogList;

  SelectUtils = SelectUtils;
  UiConstants = UiConstants;

  @ViewChild('importDialog') importDialog: ModalDirective;

  @ViewChild('searchIcon', {static: true}) searchIcon: ElementRef;

  @ViewChild('device_type') deviceType: ElementRef;

  queryModel: QueryFieldModel<AuditLogList.OrderField> = new QueryFieldModel(AuditLogList.OrderField.ID, OrderType.DESC);

  auditLogModelList: AuditLogDetailModel[] = [];
  private auditLogResultItems: AuditLogResultItem[] = [];
  private auditLogCallTypeItems: AuditLogCallTypeItem[] = [];
  applicationIdItems: ApplicationIdTypeItem[] = [];

  auditLogDeviceTypeItems: AuditLogDeviceTypeItem[] = [];
  users: MultiselectOptionItem<number>[] = [];
  dropdownSettings: Angular2Multiselects.Settings;

  searchModel: AuditLogSearchModel = new AuditLogSearchModel();
  showSearch: boolean = false;
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  private logger: Logger;
  rightModel: RightModel = RightModel.empty();

  static parseCreationTime(creation_time: string): string {
    const parsedDeadline = Dates.parseOffsetDateTimeIsoString(creation_time);
    return parsedDeadline.toUtcIsoString();
  }

  constructor(
    private translateService: TranslateService,
    private authService: AuthService,
    private userService: UserService,
    private rightService: RightService,
    private auditLogService: AuditLogService,
    private userMultiselectProvider: UserMultiselectProvider,
    private auditLogSearchService: AuditLogSearchService,
    private deviceManagementService: DeviceManagementService) {
    this.logger = LoggerFactory.createLogger('AuditLogListComponent');
  }

  ngOnInit() {
    this.initDropdownSettings();
    this.translateService.get('MENU_NAVBAR_AUDIT_LOG').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.onUserSearch();
    this.initAuditLogResultOptions('ALL');
    this.initAuditLogCallTypeOptions(null);
    this.initAuditLogDeviceTypeOptions('AUDIT_LOG_SEARCH_METHOD_DEVICE_TYPE_ALL');
    this.loadSearch(() => {
      this.showSearch = !this.searchModel.isEmpty();
      this.loadRightModels(() => {
        if (this.rightModel.mobileAppRead.hasRight()) {
          this.loadApplicationId();
        }
      });
      this.loadList();
    });
  }

  private initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();

  }

  ngAfterViewInit(): void {
  }

  private loadRightModels(completion: () => void) {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        completion();
      }
    );
  }

  private loadSearch(completion: () => void) {
    const obs: Observable<SearchLoadResult> = combineLatest(
      this.auditLogSearchService.getSearchData({}),
      (storedSearchData: AuditLogSearch.SearchDataResult) => {
        const result: SearchLoadResult = {
          storedSearchData: storedSearchData
        };
        return result;
      }
    );
    obs.subscribe(
      (result: SearchLoadResult) => {
        this.postInitSearch(result.storedSearchData);
        completion();
      }
    );
  }

  private postInitSearch(storedSearchData: AuditLogSearch.SearchDataResult) {
    this.queryModel.itemsPerPage = storedSearchData.searchData.itemsPerPage;
    this.queryModel.currentPage = storedSearchData.searchData.pageNumber;
    this.queryModel.setOrder(storedSearchData.searchData.order);
    this.searchModel.user = storedSearchData.searchData.user_id ? storedSearchData.searchData.user_id : [];
    this.searchModel.exclude_health_check = storedSearchData.searchData.exclude_health_check;
    this.searchModel.exclude_su = storedSearchData.searchData.exclude_su;
    this.searchModel.q = storedSearchData.searchData.q;
    this.searchModel.application_id = storedSearchData.searchData.application_id;
    this.searchModel.request_method = storedSearchData.searchData.request_method;
    this.searchModel.request_uri = storedSearchData.searchData.request_uri;
    this.searchModel.callType = storedSearchData.searchData.callType;
    this.searchModel.result = storedSearchData.searchData.result;
    this.searchModel.request_device_type = storedSearchData.searchData.request_device_type;
    this.searchModel.creation_time = Models.localDateToNgbDate(storedSearchData.searchData.creation_time);
  }

  onSearchClicked() {
    this.loadList();
  }

  onSearchReset() {
    this.auditLogSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.showSearch = true;
          this.loadList(1);
        });
      }
    );
  }

  private saveSearch() {
    const request = {
      searchData: {
        itemsPerPage: this.queryModel.itemsPerPage,
        pageNumber: this.queryModel.currentPage,
        order: this.queryModel.getOrder(),
        user_id: this.searchModel.selectedUsers,
        exclude_health_check: this.searchModel.exclude_health_check,
        exclude_su: this.searchModel.exclude_su,
        q: this.searchModel.q,
        application_id: this.searchModel.application_id,
        request_method: this.searchModel.request_method,
        request_uri: this.searchModel.request_uri,
        call_type: this.searchModel.callType,
        result: this.searchModel.result,
        request_device_type: this.searchModel.request_device_type,
        creation_time: Models.ngbDateToLocalDate(this.searchModel.creation_time),
      }
    };
    this.auditLogSearchService.setSearchData(request).subscribe(
      (result) => {
        this.logger.debug('Search model has saved');
      },
      (error) => {
        this.logger.warn('Failed to save the search model');
      }
    );
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: AuditLogList.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  hasOperationForModule(module: any, operation: any): boolean {
    return true;
  }


  private loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();

    let user_id: string | undefined = undefined;
    if (this.searchModel.selectedUsers) {
      user_id = this.searchModel.selectedUsers.map(u => u.id).join(',');
    }

    let request_method: string | undefined = undefined;
    if (this.searchModel.request_method) {
      request_method = this.searchModel.request_method
    }

    let request_uri: string | undefined = undefined;
    if (this.searchModel.request_uri) {
      request_uri = this.searchModel.request_uri
    }

    let result: AuditLogResult | undefined = undefined;
    if (this.searchModel.result) {
      result = this.searchModel.result.id === null ? undefined : this.searchModel.result.id;
    }

    let hasApplicationId: boolean | undefined = undefined;
    if (this.searchModel.request_device_type && this.searchModel.request_device_type.id === 'AUDIT_LOG_SEARCH_METHOD_DEVICE_TYPE_MOBILE') {
      hasApplicationId = true;
    }
    else if (this.searchModel.request_device_type
      && this.searchModel.request_device_type.id === 'AUDIT_LOG_SEARCH_METHOD_DEVICE_TYPE_WEB') {
      hasApplicationId = false;
    }

    const creationFrom: OffsetDateTime = Models.parseDateTimeFrom(this.searchModel.creation_time);
    const creationTo = Models.parseDateTimeTo(this.searchModel.creation_time);

    this.auditLogService.query({
      q: Strings.undefinedOrNonEmpty(this.searchModel.q),
      user_id: Strings.undefinedOrNonEmpty(user_id),
      exclude_health_check: this.searchModel.exclude_health_check,
      exclude_su: this.searchModel.exclude_su,
      request_method: Strings.undefinedOrNonEmpty(request_method),
      request_uri: Strings.undefinedOrNonEmpty(request_uri),
      result: result,
      has_application_id: hasApplicationId,
      device_id: this.searchModel.application_id ? Strings.undefinedOrNonEmpty(this.searchModel.application_id.id) : undefined,
      call_type: this.searchModel.callType && this.searchModel.callType.id ? this.searchModel.callType.id : undefined,
      create_time_from: creationFrom.isValid() ? creationFrom.toUtcIsoString() : undefined,
      create_time_to: creationTo.isValid() ? creationTo.toUtcIsoString() : undefined,
      page_number: requestedPage,
      number_of_items: this.queryModel.itemsPerPage,
      order: Services.createOrderFieldParameter(AuditLogList.Keys.toOrderFieldKey, Set.of(order)),
    })
      .subscribe(
        (result: AuditLog[]) => {
          this.auditLogModelList = result.map(auditLog => this.initAuditLogModel(auditLog));

          this.queryModel.currentPage = requestedPage;
          this.queryModel.indeterminateNumberOfItems(requestedPage);
        }
      );
  }

  private initAuditLogModel(auditLog: AuditLog): AuditLogDetailModel {
    const auditLogModel = new AuditLogDetailModel();
    auditLogModel.id = auditLog.id;
    auditLogModel.user_id = auditLog.user_id;
    auditLogModel.user_name = auditLog.user_name;
    auditLogModel.ip_address = auditLog.ip_address;
    auditLogModel.request_uri = auditLog.request_uri;
    auditLogModel.request_method = auditLog.request_method;
    auditLogModel.request_json = auditLog.request_json ? auditLog.request_json : '';
    auditLogModel.response_json = auditLog.response_json ? auditLog.response_json : '';
    auditLogModel.exception = auditLog.exception ? auditLog.exception : '';
    auditLogModel.result = auditLog.result ? auditLog.result : '';
    auditLogModel.creation_time = AuditLogListComponent.parseCreationTime(auditLog.creation_time);
    auditLogModel.content_type = auditLog.content_type ? auditLog.content_type : '';
    auditLogModel.device_id = auditLog.device_id ? auditLog.device_id : '';
    auditLogModel.application_id = auditLog.application_id ? auditLog.application_id : '';
    if (auditLog.application_name && auditLog.application_name !== '') {
      auditLogModel.application_id = auditLog.application_name;
    }
    return auditLogModel;
  }

  private loadApplicationId(): void {
    this.deviceManagementService.query(
      {
        order: ''
      }
    ).subscribe((devices: ResourceQueryResult<Device>) => {
      this.applicationIdItems = [];
      this.translateService.get('COMMON_VALUE_UNSELECTED').subscribe(text => {
        const def = {text: text, id: null};
        this.applicationIdItems.push(def);
        if (!this.searchModel.application_id || !this.searchModel.application_id.id) {
          this.searchModel.application_id = def;
        }
        devices.items.forEach((value) => {
          const item = new ApplicationIdTypeItem();
          item.id = value.application_id;
          if (value.name && value.name !== '') {
            item.text = value.name;
          }
          else {
            item.text = value.application_id;
          }
          this.applicationIdItems.push(item);
          if (this.searchModel.application_id && this.searchModel.application_id.id && this.searchModel.application_id.id === item.id) {
            this.searchModel.application_id = item;
          }
        });
      })
    });
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  private initAuditLogResultOptions(initValue: AuditLogResult) {
    this.auditLogResultItems = [];
    const auditLogResultEnums: AuditLogResult[] = AuditLogResults.values;

    Arrays.iterateByIndex(auditLogResultEnums, (key) => {
      const item = new AuditLogResultItem();
      item.id = key;
      this.translateService.get('AUDIT_LOG_RESULT_ENUM_' + key).subscribe(
        (text: string) => {
          item.text = text;
        }
      );
      this.auditLogResultItems.push(item);
      if (key === initValue) {
        this.searchModel.result = item;
      }
    });
  }

  private initAuditLogCallTypeOptions(initValue: AuditLogCallType | null) {
    this.translateService.get(StringKey.COMMON_VALUE_UNSELECTED).subscribe((unselected) => {
      const def = {id: null, text: unselected};
      this.auditLogCallTypeItems = [];
      this.auditLogCallTypeItems.push(def);
      const auditLogCallTypeEnums: AuditLogCallType[] = AuditLogCallTypes.values;

      Arrays.iterateByIndex(auditLogCallTypeEnums, (key) => {
        const item = new AuditLogCallTypeItem();
        item.id = key;
        this.translateService.get('AUDIT_LOG_CALL_TYPE_ENUM_' + key).subscribe(
          (text: string) => {
            item.text = text;
          }
        );
        this.auditLogCallTypeItems.push(item);
        if (key === initValue) {
          this.searchModel.callType = item;
        }
      });
    });
  }

  private initAuditLogDeviceTypeOptions(initValue: AuditLogDeviceType) {
    this.auditLogDeviceTypeItems = [];
    const auditLogDeviceTypeEnums: AuditLogDeviceType[] = AuditLogDeviceTypes.values;

    Arrays.iterateByIndex(auditLogDeviceTypeEnums, (key) => {
      const item = new AuditLogDeviceTypeItem();
      item.id = key;
      this.translateService.get(key).subscribe(
        (text: string) => {
          item.text = text;
        }
      );
      this.auditLogDeviceTypeItems.push(item);
      if (key === initValue) {
        this.searchModel.request_device_type = item;
      }
    });
  }

  onUserSearch(predicate?: string) {
    this.userMultiselectProvider.loadAll(predicate).subscribe((items) => {
      this.users = items;
    });
  }

  ngOnDestroy() {
    this.saveSearch();
  }

}

interface SearchLoadResult {
  storedSearchData: AuditLogSearch.SearchDataResult,
}
