/* eslint-disable */
import { Component, } from '@angular/core';
import {
  FormRecordGroupView,
  FormRecordGroupViewContext,
  FormRecordGroupViewFieldsContext,
  FormRecordGroupViewModel,
} from '../../../../../util/form/form-utils';
import { Set } from 'immutable';
import { FormRecord } from '../../../../../lib/form/form-record.service';
import { Observable, Subject } from 'rxjs';

/* eslint-enable */

@Component({
  selector: 'app-form-record-disabled-group',
  templateUrl: 'form-record-disabled-group.component.html',
  styleUrls: ['form-record-disabled-group.component.scss'],
})
export class FormRecordDisabledGroupComponent implements FormRecordGroupView {

  private context: FormRecordGroupViewContext;

  private fieldContext: FormRecordGroupViewFieldsContext;

  private get reqContext() {
    return this.fieldContext!;
  }

  constructor() {
  }

  registerContext(context: FormRecordGroupViewContext) {
    this.context = context;
  }

  registerFields(context: FormRecordGroupViewFieldsContext): void {
    this.fieldContext = context;
    // Calling method #registerFieldViews is not required cause
    // field inactivation by boolean field is allowed only in the same group.
    // This is a Webadmin UI limitation.
  }

  hasLocalFieldError(): boolean {
    return false;
  }

  validateWithInterrupt(): boolean {
    return false;
  }

  shouldNotifyAfterCreation(): boolean {
    return false;
  }

  afterFormRecordCreation(formRecordId: number): Observable<any> {
    const result: Subject<any> = new Subject<any>();
    setTimeout(() => {
      result.complete();
    });
    return result;
  }

  createModel(): FormRecordGroupViewModel {
    const requests: FormRecord.FieldEditRequest[] = [];
    return {
      fieldEditRequests: Set.of(...requests),
    };
  }

  getGroupId(): number {
    return this.fieldContext.group.groupId;
  }

  updateFieldData(fieldId: number, data: any) {

  }

}
