// to be used with HU_TAX_NUMBER InputMask
export class HuTaxNumber {
  private _raw: string = '';

  static getPattern(): RegExp {
    return new RegExp('^[0-9]{8}-{1}[1-5]{1}-{1}[0-9]{2}$');
  }

  set raw(raw: string) {
    this._raw = raw;
  }

  get raw(): string {
    return this._raw;
  }

  get truncated(): string | undefined {
    if (this._raw === '') {
      return undefined;
    }
    let temp = this._raw;
    while (!temp.charAt(temp.length - 1).match('[0-9]')) {
      temp = temp.substring(0, temp.length - 1);
    }
    return temp;
  }
}

export class EuVatNumber {
  private _raw: string = '';

  static getPattern(): RegExp {
    return new RegExp('^[A-Z]{2}[A-Z0-9]{2,13}$');
  }

  set raw(raw: string) {
    this._raw = raw;
  }

  get raw(): string {
    return this._raw;
  }

  get truncated(): string | undefined {
    if (this._raw === '') {
      return undefined;
    }
    let temp = this._raw;
    while (!temp.charAt(temp.length - 1).match('[0-9,A-Z]')) {
      temp = temp.substring(0, temp.length - 1);
    }
    return temp;
  }
}
