/* eslint-disable */
import { Component, Input, OnInit } from '@angular/core';
import { StateName } from '../../../../app.state-names';
import { MasterDataRecord, MasterDataRecordService } from '../../../../lib/masterdata/master-data-record.service';
import { UIRouter } from '@uirouter/angular';
import { NgForm } from '@angular/forms';
/* eslint-enable */

@Component({
  selector: 'app-masterdata-record-breadcrumb-buttons',
  templateUrl: './masterdata-record-breadcrumb-buttons.component.html',
  styleUrls: ['./masterdata-record-breadcrumb-buttons.component.scss']
})
export class MasterdataRecordBreadcrumbButtonsComponent implements OnInit {

  @Input()
  form?: NgForm;

  @Input()
  allowClone: boolean;

  @Input()
  allowEdit: boolean;

  @Input()
  masterdataId: number;

  @Input()
  masterdataRecordId: number;

  masterdataRecord?: MasterDataRecord.MasterDataRecord;

  constructor(
    private masterdataRecordService: MasterDataRecordService,
    private uiRouter: UIRouter
  ) { }

  ngOnInit() {
    this.loadMasterdataRecord();
  }

  loadMasterdataRecord() {
    this.masterdataRecordService.get({
      masterDataId: this.masterdataId,
      masterDataRecordId: this.masterdataRecordId
    }).subscribe(
      (result: MasterDataRecord.MasterDataRecord) => {
        this.masterdataRecord = result;
      });
  }

  cloneMasterData() {
    if (this.masterdataRecord && this.allowClone) {
      this.uiRouter.stateService.go(StateName.MASTERDATA_RECORD_CLONE, {
        masterDataId: this.masterdataRecord.masterDataId,
        masterDataRecordId: this.masterdataRecord.masterDataRecordId
      });
    }
  }

  editMasterData() {
    if (this.masterdataRecord && this.allowEdit) {
      this.uiRouter.stateService.go(StateName.MASTERDATA_RECORD_EDIT, {
        masterDataId: this.masterdataRecord.masterDataId,
        masterDataRecordId: this.masterdataRecord.masterDataRecordId
      });
    }
  }

}
