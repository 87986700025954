<app-loading-spinner *ngIf="activating"></app-loading-spinner>

<h1 *ngIf="activationFailed">
  {{'USER_ACTIVATION_FAILED' | translate}}
</h1>

<h1 *ngIf="invalidArguments">
  {{'SHOPRENTER_ERROR_INVALID_ARGUMENTS' | translate}}
</h1>

<toaster-container [toasterconfig]="toasterConfig"></toaster-container>
