/* eslint-disable */
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { QueryFieldModel, SelectUtils, UiConstants } from '../../../../util/core-utils';
import { AuthService } from '../../../../lib/auth.service';
import { DownloadedFile } from '../../../../lib/util/downloaded-files';
import { Arrays } from '../../../../lib/util/arrays';
import { ListItemList, ListItemResourceService, RawListTypeItem, } from '../../../../lib/list-item/list-item-resource.service';
import { SettingsService } from '../../../../lib/settings.service';
import { InputMask } from '../../../../util/input-masks';
import { TranslateService } from '@ngx-translate/core';
import { StringKey } from '../../../../app.string-keys';
import { Strings } from '../../../../lib/util/strings';
import { ListItemTypeItem, ListItemTypeSearchModel } from '../../../../util/list-item-utils';
import { RootCoreService } from '../../../../lib/root-core.service';
import { RightModel } from '../../../../app.rights';
import { RightResolver, RightService } from '../../../../lib/right.service';
import { BreadcrumbParent } from '../../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { StateName } from '../../../../app.state-names';
import { combineLatest, Observable } from 'rxjs';
import { ListItemTypeSearch, ListItemTypeSearchService } from '../../../../lib/list-item-type-search-service';
import { FileUploadDialogComponent } from '../../../../shared/file-upload/dialog/file-upload-dialog.component';
import { saveAs } from 'file-saver';
import { OrderType, ResourceQueryResult, Services } from '../../../../lib/util/services';
import { DisabledEnum, DisabledItem } from '../../../../util/search-utils';
import { Set } from 'immutable';

/* eslint-enable */

@Component({
  selector: 'app-list-item-type-list',
  templateUrl: 'list-item-type-list.component.html',
  styleUrls: ['list-item-type-list.component.scss']
})
export class ListItemTypeListComponent implements OnInit, AfterViewInit, OnDestroy {

  ListItemList = ListItemList;
  UiConstants = UiConstants;

  @ViewChild('searchIcon', {static: true}) searchIcon: ElementRef;

  queryModel: QueryFieldModel<ListItemList.TypeOrderField> = new QueryFieldModel(ListItemList.TypeOrderField.ID, OrderType.DESC);
  litItemTypeList: RawListTypeItem[] = [];
  languageCodes: string[] = [];
  showSearch: boolean = false;
  InputMask = InputMask;
  searchModel: ListItemTypeSearchModel;
  disabledItems: DisabledItem[] = [];
  rightModel: RightModel = RightModel.empty();
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  SelectUtils = SelectUtils;

  @ViewChild('importDialog', {static: true}) importDialog: FileUploadDialogComponent;
  uploadPath: string;


  ngOnInit() {
    this.translateService.get('MENU_NAVBAR_LIST_ITEM_TYPE').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.loadSearch(() => {
      this.showSearch = !this.searchModel.isEmpty();
      this.initDisabledOptions(DisabledEnum.FALSE);
      this.loadList();
    });
  }

  ngAfterViewInit(): void {
    this.loadRightModels();
    this.loadList();
  }

  private initDisabledOptions(initValue: DisabledEnum) {
    this.disabledItems = [];
    const disabledEnums: DisabledEnum[] = [DisabledEnum.NONE, DisabledEnum.FALSE, DisabledEnum.TRUE];
    Arrays.iterateByIndex(disabledEnums, (key) => {
      const item = new DisabledItem();
      item.id = key;
      this.translateService.get('COMMON_VALUE_DISABLED_ENUM_' + key).subscribe(
        (text: string) => {
          item.text = text;
        }
      );
      this.disabledItems.push(item);
      if (!this.searchModel.disabled) {
        if (key === initValue) {
          this.searchModel.disabled = item;
        }
      }
    });
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  private loadSearch(completion: () => void) {
    const obs: Observable<SearchLoadResult> = combineLatest(
      this.listItemTypeSearchService.getSearchData({}),
      (storedSearchData: ListItemTypeSearch.SearchDataResult) => {
        const result: SearchLoadResult = {
          storedSearchData: storedSearchData
        };
        return result;
      }
    );
    obs.subscribe(
      (result: SearchLoadResult) => {
        this.postInitSearch(result.storedSearchData);
        completion();
      }
    );
  }

  private postInitSearch(storedSearchData: ListItemTypeSearch.SearchDataResult) {
    this.queryModel.itemsPerPage = storedSearchData.searchData.itemsPerPage;
    this.queryModel.currentPage = storedSearchData.searchData.pageNumber;
    this.searchModel.key = storedSearchData.searchData.key;
    this.searchModel.disabled = storedSearchData.searchData.disabled;
  }

  onSearchClicked() {
    this.loadList();
  }

  onSearchReset() {
    this.listItemTypeSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.showSearch = true;
          this.loadList(1);
        });
      }
    );
  }

  private saveSearch() {
    const request = {
      searchData: {
        itemsPerPage: this.queryModel.itemsPerPage,
        pageNumber: this.queryModel.currentPage,
        key: this.searchModel.key,
        disabled: this.searchModel.disabled
      }
    };
    this.listItemTypeSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: ListItemList.TypeOrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }


  private loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();
    const disabled: boolean | undefined = !this.searchModel.disabled ||
    this.searchModel.disabled.id === DisabledEnum.NONE ?
      undefined : this.searchModel.disabled.id === DisabledEnum.TRUE;
    this.listItemService.getTypeList({
      key: Strings.undefinedOrNonEmpty(this.searchModel.key),
      page_number: requestedPage,
      disabled: disabled,
      number_of_items: this.queryModel.itemsPerPage,
      order: Services.createOrderFieldParameter(ListItemList.TypeKeys.toOrderFieldKey, Set.of(order))
    })
      .subscribe(
        (result: ResourceQueryResult<RawListTypeItem>) => {
          this.litItemTypeList = result.items;
          this.queryModel.currentPage = requestedPage;
          this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
          this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
        }
      );
  }

  setDisabled(event: any, listItemType: RawListTypeItem, disabled: boolean) {
    this.listItemService.setDisabledType({
      id: listItemType.id,
      disabled: disabled
    }).subscribe(
      (result: RawListTypeItem) => {
        this.loadList();
      },
      (error: any) => {
        this.loadList();
      }
    );
  }

  private createDefaultListItemType(): ListItemTypeItem {
    const def = new ListItemTypeItem();
    def.id = null;
    def.text = '';
    this.translateService.get(StringKey.COMMON_VALUE_UNSELECTED).subscribe(
      (text: string) => {
        def.text = text;
      }
    );
    return def;
  }

  public onImportSuccess(succeeded: boolean) {
    if (succeeded) {
      this.loadList();
    }
  }

  exportXls() {
    this.listItemService.typeExportXls().subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName('type_export.xlsx'));
      }
    );
  }


  ngOnDestroy() {
    this.saveSearch();
  }

  constructor(
    private rightService: RightService,
    private translateService: TranslateService,
    private settingsService: SettingsService,
    private rootService: RootCoreService,
    private listItemService: ListItemResourceService,
    private listItemTypeSearchService: ListItemTypeSearchService,
    private authService: AuthService) {
    this.searchModel = new ListItemTypeSearchModel();
    this.uploadPath = '/list-items/types/import-xls';
  }

}

interface SearchLoadResult {
  storedSearchData: ListItemTypeSearch.SearchDataResult,
}
