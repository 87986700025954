/* eslint-disable */
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, } from '@angular/core';
import { Transition, UIRouter } from '@uirouter/angular';
import { combineLatest, Observable, Subject } from 'rxjs';
import { CustomerRecord, CustomerRecordService } from '../../../lib/customer/customer-record.service';
import { FieldValidationError, OrderType, QueryResult, ResourceQueryResult, Services } from '../../../lib/util/services';
import { StateName } from '../../../app.state-names';
import { CustomerRecordBase } from '../customer-record-base/customer-record-base-view';
import { CustomerRecordBaseComponent } from '../customer-record-base/customer-record-base.component';
import { NgForm } from '@angular/forms';
import { FormRecordContainer } from '../../form/form-record/form-record-container';
import { FormRecordContainerComponent } from '../../form/form-record/form-record-container.component';
import { Customer, CustomerService, } from '../../../lib/customer/customer.service';
import { ConfigurationService, } from '../../../lib/core-ext/configuration.service';
import { Form } from '../../../lib/form/form.service';
import { LoadingHandler } from '../../../lib/loading-handler';
import { AddressModel, } from '../../../lib/address';
import { Country, CountryService, } from '../../../lib/country.service';
import { ImmutableUserGroupItem, ImmutableUserItem, UiConstants, } from '../../../util/core-utils';
import { UserData, UserDataLoader, UserDataLoaderPermissionDeniedStrategy, } from '../../../lib/user-data-loader';
import { IdentityArray } from '../../../lib/util/messages';
import { UserGroup, UserGroupService, } from '../../../lib/user-group.service';
import { StringKey } from '../../../app.string-keys';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '../../../fork/angular2-toaster/angular2-toaster';
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { ConfirmLeaveModalComponent } from '../../../shared/confirm-leave-modal/confirm-leave-modal.component';
import { NgbDatePickerParserFormatter } from '../../../util/ngb-datepicker';
import { List, Set } from 'immutable';
import PostalAddressModelType = AddressModel.PostalAddressModelType;
import { Models } from '../../../util/model-utils';
import { CustomerRecordFieldType } from '../../../util/customer-record-utils';
import { Strings } from '../../../lib/util/strings';
/* eslint-enable */

@Component({
  selector: 'app-customer-record-create',
  templateUrl: 'customer-record-create.component.html',
  styleUrls: ['customer-record-create.component.scss'],
})
export class CustomerRecordCreateComponent implements OnInit, AfterViewInit, OnDestroy {
  UiConstants = UiConstants;

  @ViewChild(CustomerRecordBaseComponent, { static: true })
  baseView: CustomerRecordBase.View;

  @ViewChild(CustomerRecordBaseComponent, { static: true })
  baseComponent: CustomerRecordBaseComponent;

  @ViewChild(FormRecordContainerComponent, { static: true })
  formRecordContainerView: FormRecordContainer.View;

  @ViewChild('f', { static: true })
  fForm: NgForm;

  @ViewChild('warningDialog', { static: true }) warningDialog: ModalDirective;
  warningDialogVisible: boolean = false;

  private customerId: number;
  combinedResult?: CombinedResult;
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  deregisterWarningModal;
  saveButtonClicked: boolean = false;
  navigateOnSubmit: boolean = false;

  constructor(
    private toasterService: ToasterService,
    private translateService: TranslateService,
    private userDataLoader: UserDataLoader,
    private modalService: BsModalService,
    private configurationService: ConfigurationService,
    private countryService: CountryService,
    private customerService: CustomerService,
    private customerRecordService: CustomerRecordService,
    private uiRouter: UIRouter,
    private transition: Transition) {
    this.customerId = this.transition.params().customerId;
  }

  ngOnInit(): void {
    this.initBreadcrumb();
    const context = this;
    this.deregisterWarningModal = this.uiRouter.transitionService.onStart({}, function(transition) {
      if (context.saveButtonClicked
        || transition.targetState().name() === StateName.CONNECTION_ERROR
        || transition.targetState().name() === StateName.SERVER_ERROR
        || transition.targetState().name() === StateName.LOGIN) {
        return true;
      }
      else {
        const subject = new Subject<boolean>();
        const modal = context.modalService.show(ConfirmLeaveModalComponent, {backdrop: 'static',  keyboard: false});
        modal.content!.subject = subject;
        return subject.asObservable().toPromise();
      }
    });
  }

  ngAfterViewInit(): void {
    this.createCombinedObservable().subscribe(
      (result: CombinedResult) => {
        this.baseView.loadCustomerRecord({
          countryItems: AddressModel.CountryItem.fromCountryList(result.countries.items),
          customer: result.customer,
          defaultSelectedCountryCode: this.configurationService.getDefaultSelectedCountryCode(),
          isClone: false,
          isCreate: true
        });
        this.formRecordContainerView.loadFormRecord({
          configuration: this.configurationService.getConfiguration(),
          form: result.form
        });
        this.combinedResult = result;
      }
    );
  }

  private initBreadcrumb() {
    this.translateService.get('COMMON_CUSTOMERS').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.CUSTOMER_DASHBOARD});
      }
    );
    this.translateService.get('CUSTOMER_RECORD_CREATE_BREADCRUMB').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    if (this.customerId) {
      this.customerService.get({
        customerId: this.customerId,
      }).subscribe(
        (result: Customer.Customer) => {
          this.breadcrumbParents.push({name: result.name, uiSref: StateName.CUSTOMER_RECORD_LIST, uiParam: {customerId: this.customerId}});
        }
      )
    }
    else {
      this.translateService.get('COMMON_CUSTOMERS').subscribe(
        (result: string) => {
          this.breadcrumbParents.push({name: result, uiSref: StateName.CUSTOMER_RECORD_GLOBAL_LIST});
        }
      );
    }
  }

  createCustomerRecord() {
    if (!this.combinedResult) {
      return;
    }
    if (this.baseView.hasLocalFieldError() || this.formRecordContainerView.hasLocalFieldError()) {
      if (this.baseView.hasLocalFieldError('emailAddresses')) {
        this.showValidationErrorToast(StringKey.CUSTOMER_RECORD_EMAIL_ADDRESSES_REQUIRED);
      }
      else if (this.baseView.hasLocalFieldError('phoneNumbers')) {
        this.showValidationErrorToast(StringKey.CUSTOMER_RECORD_PHONE_NUMBERS_REQUIRED);
      }
      else {
        this.showValidationErrorToast();
      }
      return;
    }
    const model = this.baseView.getModel();
    if (this.baseView.getFieldModel().isRequired(CustomerRecordFieldType.ASSIGNEE)
      && model.users.length === 0 && model.userGroups.length === 0) {
      return;
    }
    if (this.formRecordContainerView.validateWithInterrupt()) {
      return;
    }
    if (model.notificationAddressEqPostal) {
      model.notificationAddress.type = PostalAddressModelType.NONE;
    }
    const formModel = this.formRecordContainerView.createModel();

    let parentId: number | undefined = undefined;
    if (model.customerRecordParents.length !== 0) {
      parentId = model.customerRecordParents[0].id!;
    }
    const postalAddressManaged: boolean
      = this.baseView.getFieldModel().isManaged(CustomerRecordFieldType.POSTAL_ADDRESS_AND_INVOICE_ADDRESS_AND_NOTIFICATION_ADDRESS);
    const createRequest: CustomerRecord.CreateRequest = {
      withFormRecord: true,
      customerId: this.customerId,
      name: model.name,
      firstName: model.firstName,
      lastName: model.lastName,
      externalId: model.externalId,
      comment: model.comment,
      position: model.position,
      webAddress: model.webAddress,
      mainContractNumber: model.mainContractNumber,
      taxNumber: model.taxNumber.truncated,
      euTaxNumber: model.euTaxNumber.truncated,
      ownerUserIds: model.getUserIds(),
      ownerGroupIds: model.getUserGroupIds(),
      companyId: model.getCompanyId(),
      postalAddress:  postalAddressManaged ? model.postalAddress.toData() : undefined,
      notificationAddress: postalAddressManaged ? model.notificationAddress.toData() : undefined,
      notificationAddressEqPostal: model.notificationAddressEqPostal,
      billingInfo: !model.billingInfoMatchesCustomerData ? {
          customerId: this.customerId,
          customerRecordId: -1,
          name: model.billingInfoModel.name,
          taxNumber: Strings.undefinedOrNonEmpty(model.billingInfoModel.taxNumber),
          euTaxNumber: Strings.undefinedOrNonEmpty(model.billingInfoModel.euTaxNumber),
          bankAccount: Strings.undefinedOrNonEmpty(model.billingInfoModel.bankAccount),
          invoiceVatStatus: model.billingInfoModel.invoiceVatStatus!,
          invoiceAddress: model.billingInfoModel.invoiceAddress.toData()!,
        } : undefined,
      emailAddresses: AddressModel.EmailAddressModel.toDataList(model.emailAddresses),
      phoneNumbers: AddressModel.PhoneNumberModel.toDataList(model.phoneNumbers),
      formRecord: {
        fields: formModel.fields
      },
      parentId: parentId,
      ledgerNumber: model.getLedgerNumberId(),
      invoiceDeadlineAdditionalDays: model.invoiceDeadlineAdditionalDays,
      taskRecordDeadlineAdditionalDays: Models.optNumber(model.taskRecordDeadlineAdditionalDays),
      // PERSON type
      placeOfBirth: model.placeOfBirth,
      dateOfBirth: model.dateOfBirth,
      birthName: model.birthName,
      gender: model.gender,
      mothersName: model.mothersName,
      identityDocumentType: model.identityDocumentType,
      identityDocumentNumber: model.identityDocumentNumber,
    };
    this.customerRecordService.create(createRequest).subscribe(
      (cr: CustomerRecord.CustomerRecord) => {
        if (this.formRecordContainerView.shouldNotifyAfterCreation()) {
          this.formRecordContainerView.afterFormRecordCreation(cr.formRecord!.recordId).subscribe(
            next => {
              // do nothing
            },
            error => {
              // do nothing
            },
            () => {
              this.afterCreation(cr);
            });
        }
        else {
          this.afterCreation(cr);
        }
      },
      (error) => {
        if (error instanceof FieldValidationError) {
          this.baseView.onFieldValidationError(error.withForm(this.fForm));
          this.showValidationErrorToast();
        }
      }
    );
  }

  private afterCreation(cr: CustomerRecord.CustomerRecord) {
    LoadingHandler.getInstance().refresh();
    this.saveButtonClicked = true;
    if (this.navigateOnSubmit) {
      window.history.back();
    }
    else {
      this.uiRouter.stateService.go(
        StateName.CUSTOMER_RECORD_EDIT,
        {customerId: cr.customerId, customerRecordId: cr.customerRecordId});
    }
  }

  private createCombinedObservable(): Observable<CombinedResult> {
    return combineLatest(
      this.customerService.get({
        customerId: this.customerId,
        withForm: true
      }),
      this.countryService.query({
      }),
      (
        customer: Customer.Customer,
        countries: QueryResult<Country.Country>): CombinedResult => {
        return {
          form: customer.form!,
          customer: customer,
          countries: countries,
        }
      }
    );
  }

  private showValidationErrorToast(bodyStringKey?: string) {
    this.toasterService.pop({
      timeout: UiConstants.ToastTimeoutLong,
      type: UiConstants.toastTypeError,
      title: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_TITLE),
      body: this.translateService.instant(bodyStringKey ? bodyStringKey : StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_MESSAGE)
    });
  }

  submit(navigateOnSubmit: boolean) {
    this.navigateOnSubmit = navigateOnSubmit;
  }

  openWarningDialog() {
    this.warningDialogVisible = true;
    this.warningDialog.show();
  }

  closeWarningDialog() {
    this.warningDialogVisible = false;
    this.warningDialog.hide();
  }

  ngOnDestroy() {
    this.deregisterWarningModal();
  }

}

interface CombinedResult {
  form: Form.Form;
  customer: Customer.Customer;
  countries: QueryResult<Country.Country>;
}
