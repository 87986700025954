<div class="modal-content">
  <div class="modal-header">
    <p class="modal-title"><strong>{{'STOCK_RECORD_SELECTOR_DIALOG_TITLE' | translate}}</strong></p>
    <button type="button" class="close" (click)="closeDialog()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="filter-outer-container">
      <div class="filter-container p-1">
        <div class="filter-inner-container d-flex align-items-center">
          <i class="icomoon icomoon-helpdesk-search mr-05 font-xl"></i>
          <input type="text" class="form-control mr-05 flex-grow-1 filter-input"
                 placeholder="{{'STOCK_ITEM_SELECTOR_FILTER' | translate}}"
                 maxlength="{{UiConstants.maxInputLongLength}}"
                 (keyup.enter)="load(1)"
                 [(ngModel)]="filter">
          <input type="button" class="btn btn-primary filter-button" (click)="load(1)"
                 value="{{'COMMON_BUTTON_SEARCH'|translate}}"/>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between record-header">
      <span class="record-header-title">{{'STOCK_RECORD_SELECTOR_DIALOG_RECORDS_TITLE' | translate}}</span>
      <div class="row m-0">
        <mat-checkbox class="record-select-all"
                      (click)="selectAll()"
                      [checked]="allSelected"
                      [disabled]="stockItems.length === 0">
          {{'STOCK_RECORD_SELECTOR_DIALOG_SELECT_ALL' | translate}}
        </mat-checkbox>
        <div class="record-number-of-items hidden-xs-down">
          <span
            class="record-number-of-items-title">{{'SELECTOR_DIALOG_NUMBER_OF_ITEMS' | translate}}</span>
          <span class="record-number-of-items-option" *ngFor="let option of numberOfItemsOptions; let last = last"
                [class.record-number-of-items-option-active]="queryModel.itemsPerPage === option"
                [class.mr-0]="last"
                (click)="onItemsPerPageChange(option)">
              {{option}}
            </span>
        </div>
      </div>
    </div>
    <div class="list-container" *ngIf="stockItems.length > 0">
      <table class="table table-striped table-bordered mb-0">
        <thead>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="StockItem.OrderField.NAME"
                                    [orderType]="queryModel.getOrderType(StockItem.OrderField.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [checkAllSelected]="true"
                                    (onCheckAllClicked)="selectAll()"
                                    [text]="'STOCK_RECORD_SELECTOR_DIALOG_TABLE_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-field-sorter [orderField]="StockItem.OrderField.PRODUCT_CODE"
                                    [orderType]="queryModel.getOrderType(StockItem.OrderField.PRODUCT_CODE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'STOCK_RECORD_SELECTOR_DIALOG_TABLE_PRODUCT_CODE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-sorter-no-op
              [text]="'STOCK_RECORD_SELECTOR_DIALOG_RECORD_WEIGHT' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op
              [text]="'FORM_RECORD_STOCK_INTAKE_IN_STOCK_AMOUNT' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op [text]="data.amountLabel | translate"></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of stockItems | paginate: {
                          id: pagingId,
                          itemsPerPage: queryModel.itemsPerPage,
                          currentPage: queryModel.currentPage,
                          totalItems: queryModel.totalNumberOfItems }">
          <td class="responsive-table-column long align-middle">
            <div class="d-flex align-items-center">
              <app-animated-checkbox
                class="mr-05"
                (clickEvent)="toggleSelected(item)"
                [selected]="item.selected || allSelected">
              </app-animated-checkbox>
              <div class="d-flex flex-column">
                <div>{{item.name}}</div>
                <div class="table-text-subtitle">{{item.externalId}}</div>
              </div>
            </div>
          </td>
          <td class="responsive-table-column hidden-lg-down align-middle">{{item.productCode}}</td>
          <td class="responsive-table-column hidden-md-down align-middle">{{item.formattedWeight}}</td>
          <td class="responsive-table-column align-middle">{{item.inStockAmount | formattedNumber}} {{item.baseUnit}}
            <div *ngIf="item.packageData"
                 class="table-text-subtitle">{{StockItemUtils.convertToPackageStr(item.inStockAmount, item.packageData)}}</div>
          </td>
          <td class="responsive-table-column align-middle" style="overflow:visible">
            <div class="d-flex">
              <div class="form-group  col-md-6 col-sm-12">
                <input type="number" class="form-control"
                       placeholder="{{'FORM_RECORD_STOCK_INTAKE_AMOUNT_LABEL' | translate}}"
                       maxlength="{{UiConstants.maxInputLongLength}}"
                       [(ngModel)]="item.amount"
                       [disabled]="!item.selected">
              </div>
              <div class="form-group col-md-6 col-sm-12">
                <angular2-multiselect
                  class="form-control"
                  [(ngModel)]="item.unitOfMeasure"
                  [data]="item.measurements"
                  [settings]="dropdownSettings"
                  name="unitOfMeasureInput"
                  id="unitOfMeasureInput"
                  [readonly]="!item.selected"
                  [ngClass]="{ 'form-control-danger': !item.selectedUnitOfMeasure }"
                  validateEnabledItems
                  required>
                </angular2-multiselect>
              </div>
            </div>
            <div *ngIf="item.packageData && item.isBaseUnitSelected()"
                 class="table-text-subtitle">{{StockItemUtils.convertToPackageStr(item.amount, item.packageData)}}</div>
            <div *ngIf="!item.isBaseUnitSelected()"
                 class="table-text-subtitle">{{StockItemUtils.convertToBaseStr(item.amount, item.selectedUnitOfMeasure, item.baseUnit)}}</div>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="pagination-container">
        <app-table-paging
          [id]="pagingId"
          [currentNumberOfItems]="queryModel.currentNumberOfItems"
          [totalNumberOfItems]="queryModel.totalNumberOfItems"
          [itemsPerPage]="queryModel.itemsPerPage"
          (pageChange)="onPageChange($event)"
          (itemsPerPageChange)="onItemsPerPageChange($event)">
        </app-table-paging>
      </div>
    </div>
    <div class="empty-container" *ngIf="stockItems.length === 0">
      <img src="../../../../assets/img/stock/stock-item-placeholder.png" class="empty-icon">
      <p class="empty-title">{{'STOCK_RECORD_SELECTOR_DIALOG_RECORDS_EMPTY_TITLE' | translate}}</p>
    </div>
    <div class="spinner" *ngIf="loading">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
    <button type="button" class="btn btn-primary save-button" (click)="saveSelection()"
            *ngIf="selectedStockItems.length > 0 || allSelected">
      {{(selectedStockItems.length > 1 ? 'STOCK_RECORD_SELECTOR_DIALOG_SAVE_BUTTON_PLURAL' : 'STOCK_RECORD_SELECTOR_DIALOG_SAVE_BUTTON_SINGLE')
      | translate: {count: allSelected ? queryModel.totalNumberOfItems : selectedStockItems.length} }}
    </button>
  </div>
</div>
