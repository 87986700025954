/* eslint-disable */
import { Component, OnInit, ViewChild } from '@angular/core';
import { Transition, UIRouter } from '@uirouter/angular';
import { StateName } from '../../../app.state-names';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import {
  FieldValidationError
} from '../../../lib/util/services';
import { UiConstants } from '../../../util/core-utils';
import { ComponentStateResolver } from '../../../util/component-state/component-state-resolver';
import { EmptyMessage, IdentityMessage } from '../../../lib/util/messages';
import { EmailAddressType, EmailAddressTypeService } from '../../../lib/email-address-type/email-address-type.service';
import { RightResolver, RightService } from '../../../lib/right.service';
import { RightModel } from '../../../app.rights';
/* eslint-enable */

@Component({
  selector: 'app-email-address-type-base',
  templateUrl: './email-address-type-base.component.html',
  styleUrls: ['./email-address-type-base.component.scss']
})
export class EmailAddressTypeBaseComponent implements OnInit {

  // If you need to access certain classes from HTML, declare them here
  EmailAddressType = EmailAddressType;
  UiConstants = UiConstants;

  @ViewChild('f', { static: true })
  form: NgForm;

  formGroup: FormGroup;
  formSubmitted: boolean = false;

  // Declare model before use
  model: Model = new Model();

  // Component state resolver, determines the state of the component
  componentState: ComponentStateResolver;

  // Variables used for breadcrumb
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  private fieldErrors: FieldValidationError<EmailAddressType.ValidatedField> =
    FieldValidationError.empty<EmailAddressType.ValidatedField>();

  rightModel: RightModel = RightModel.empty();

  constructor(private uiRouter: UIRouter,
              private transition: Transition,
              private emailAddressTypeService: EmailAddressTypeService,
              private formBuilder: FormBuilder,
              private rightService: RightService,
              private translateService: TranslateService) {
    this.componentState = new ComponentStateResolver(uiRouter, transition,
      'id',
      {stateName: StateName.EMAIL_ADDRESS_TYPE_CREATE, stateHeaderKey: 'EMAIL_ADDRESS_TYPE_CREATE'},
      {stateName: StateName.EMAIL_ADDRESS_TYPE_EDIT, stateHeaderKey: 'EMAIL_ADDRESS_TYPE_EDIT'},
      {stateName: StateName.EMAIL_ADDRESS_TYPE_DETAIL, stateHeaderKey: 'EMAIL_ADDRESS_TYPE_DETAIL'});
    this.formGroup = this.formBuilder.group({
      externalId: ['', Validators.required],
      nameEn: ['', Validators.required],
      nameHu: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.initComponentState(); // Must be called first
    this.initBreadcrumb();
    this.loadRightModels();
  }

  // Called by the init method first
  private loadModel() {
    this.emailAddressTypeService.get({
      id: this.componentState.id!
    }).subscribe((emailAddressType: EmailAddressType.EmailAddressType) => {
      this.model.externalId = emailAddressType.externalId;
      this.model.nameEn = emailAddressType.names.en;
      this.model.nameHu = emailAddressType.names.hu;
      this.breadcrumbSelf = emailAddressType.externalId;
    });
  }

  private initComponentState() {
    // component state contains the id
    if (this.componentState.id) {
      this.loadModel();
    }
  }

  initBreadcrumb() {
    // Set breadcrumbSelf if createView, otherwise set in loadModel()
    if (this.componentState.isCreateView()) {
      this.translateService.get('EMAIL_ADDRESS_TYPE_CREATE').subscribe(
        (result: string) => {
          this.breadcrumbSelf = result;
        }
      );
    }
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('MENU_NAVBAR_EMAIL_ADDRESS_TYPES').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.EMAIL_ADDRESS_TYPE_LIST});
      }
    );
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  // Get corresponding dictionary key
  getHeadingDictionaryKey(): string {
    return this.componentState.getCurrentHeaderKey();
  }

  hasLocalFieldError(control?: string, error?: string): boolean {
    if (control) {
      if (error) {
        return this.formSubmitted && this.formGroup.controls[control].hasError(error);
      }
      return this.formSubmitted && this.formGroup.controls[control].invalid;
    }
    return this.formSubmitted && this.formGroup.invalid;
  }

  hasFieldError(field: EmailAddressType.ValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: EmailAddressType.ValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: EmailAddressType.ValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  submit() {
    this.formSubmitted = true;
    if (this.hasLocalFieldError()) {
      return;
    }
    if (this.componentState.isCreateView()) {
      this.create();
    }
    else if (this.componentState.isEditView()) {
      this.update();
    }
  }

  create() {
    this.emailAddressTypeService.create({
      externalId: this.model.externalId,
      names: {
        en: this.model.nameEn,
        hu: this.model.nameHu
      }
    }).subscribe(
      (response: IdentityMessage) => {
        this.uiRouter.stateService.go(StateName.EMAIL_ADDRESS_TYPE_LIST);
      },
      (error: any) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withForm(this.form);
        }
      });
  }

  update() {
    this.emailAddressTypeService.update({
      id: this.componentState.id!,
      externalId: this.model.externalId,
      names: {
        en: this.model.nameEn,
        hu: this.model.nameHu
      }
    }).subscribe(
      (response: EmptyMessage) => {
        this.uiRouter.stateService.go(StateName.EMAIL_ADDRESS_TYPE_LIST);
      },
      (error: any) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withForm(this.form);
        }
      });
  }

  back() {
    window.history.back();
  }
}

class Model {
  externalId: string = '';
  nameEn: string = '';
  nameHu: string = '';
}
