/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { ResourceQueryResult } from '../util/services';
import { AddressResource } from '../address';
import { DownloadedFile } from '../util/downloaded-files';
import { UrlBuilder } from '../../util/url-builder';
import { map } from 'rxjs/operators';
import Decimal from 'decimal.js';
import { EmptyMessage } from '../util/messages';
/* eslint-enable */

@Injectable()
export class MileageRecordResourceService extends BaseHttpService {

  query(request: MileageRecordResource.QueryRequest): Observable<ResourceQueryResult<MileageRecordResource.MileageRecord>> {
    return this.http.get<ResourceQueryResult<MileageRecordResource.MileageRecord>>(this.url, this.parseParams(request));
  }

  get(request: MileageRecordResource.GetRequest): Observable<MileageRecordResource.MileageRecord> {
    return this.http.get<MileageRecordResource.MileageRecord>(this.url + `${request.id}`, this.parseParams(request));
  }

  update(request: MileageRecordResource.UpdateRequest): Observable<EmptyMessage> {
    return this.http.put<EmptyMessage>(this.url + `${request.id}`, request);
  }

  exportXls(request: MileageRecordResource.QueryRequest): Observable<DownloadedFile> {
    const url = UrlBuilder.create('mileage-records/export-xls')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob',
      params: this.parseParams(request).params
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  exportMaconomy(request: MileageRecordResource.QueryRequest): Observable<DownloadedFile> {
    const url = UrlBuilder.create('mileage-records/export-maconomy')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob',
      params: this.parseParams(request).params
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  constructor(private http: HttpClient, private resourceHelper: ResourceHelper) {
    super(resourceHelper, 'mileage-records/');
  }

}

export namespace MileageRecordResource {

  export interface MileageRecord {
    id: number;
    creation_time: string;
    finish_time?: string;
    start_kilometer: string;
    finish_kilometer?: string;
    journey_type?: string;
    note?: string;
    start_coordinates?: AddressResource.CoordinateResource;
    finish_coordinates?: AddressResource.CoordinateResource;
    user: User;
    task_record?: TaskRecord;
    vehicle: Vehicle;
  }

  export interface User {
    id: number;
    person_name: string;
    user_name: string;
    user_group_ids: number[];
    profile_picture_hash?: string;
  }

  export interface TaskRecord {
    id: number;
    task_id: number;
    name: string;
    customer_name?: string;
  }

  export interface Vehicle {
    id: number;
    licence_plate: string;
  }

  export interface QueryRequest {
    fields?: string;
    filter?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
  }

  export interface GetRequest {
    id: number;
  }

  export interface UpdateRequest {
    id: number;
    start_kilometer: string;
    finish_kilometer: string;
    journey_type?: string;
    note?: string;
    vehicle_id: number;
  }

}
