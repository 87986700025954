<div class="card">
  <div class="card-header">
    {{'INVOICE_BALANCE' | translate}}

    <div class="card-actions">
      <!--      navigate in time not in order of elements-->
      <button class="btn-setting" (click)="nextBalance()"
         title="{{'COMMON_PAGINATION_PREVIOUS_LABEL' | translate}}" [disabled]="!hasNext()">
        <i class="icomoon icomoon-double-arrow-left"></i></button>
      <button class="btn-setting" (click)="previousBalance()"
         title="{{'COMMON_PAGINATION_NEXT_LABEL' | translate}}" [disabled]="!hasPrevious()">
        <i class="icomoon icomoon-double-arrow-right"></i></button>
      <a class="btn-setting cursor-pointer" (click)="deleteBalance()" title="{{'COMMON_DELETE' | translate}}"
         *ngIf="rightModel.invoiceBalanceDelete.hasRight() && balance">
        <i class="icomoon icomoon-trash"></i></a>
      <a class="btn-setting cursor-pointer" (click)="createNewBalance()" title="{{'COMMON_ADD' | translate}}"
         *ngIf="rightModel.invoiceBalanceCreate.hasRight()">
        <i class="icomoon icomoon-add"></i></a>
    </div><!-- /.card-actions -->
  </div>

  <div class="card-body" *ngIf="balance">
    <div class="d-flex flex-row justify-content-between base-data-header">
      <div class="d-flex flex-row px-1 py-third-quarter align-items-center"
      [ngClass]="{'font-weight-bold': isActive() }">
        <i class="icomoon pr-half icomoon-date-time info-card-icon"></i>
        <div class="mr-05">{{'INVOICE_BALANCE_STARTING_DATE' | translate}}</div>
        <div>{{balance.startingDate.toIsoString() |date:'shortDate'}}</div>
      </div>
      <div class="d-flex flex-row px-1 py-third-quarter align-items-center font-weight-bold">
        <i class="icomoon pr-half icomoon-price info-card-icon"></i>
        <div class="mr-05">{{'INVOICE_BALANCE_CURRENT_BALANCE' | translate}}</div>
        <div>{{balance.currentBalance | formattedNumber}} {{balance.currencyCode}}</div>
      </div>
      <div class="d-flex flex-row px-1 py-third-quarter align-items-center revenue">
        <i class="icomoon pr-half icomoon-arrow-up info-card-icon"></i>
        <div class="mr-05">{{'INVOICE_BALANCE_REVENUE' | translate}}</div>
        <div>{{balance.revenue | formattedNumber}} {{balance.currencyCode}}</div>
      </div>
      <div class="d-flex flex-row px-1 py-third-quarter align-items-center expense">
        <i class="icomoon pr-half icomoon-dropdown info-card-icon"></i>
        <div class="mr-05">{{'INVOICE_BALANCE_EXPENSE' | translate}}</div>
        <div>{{balance.expense | formattedNumber}} {{balance.currencyCode}}</div>
      </div>
      <div class="d-flex flex-row px-1 py-third-quarter align-items-center font-weight-light">
        <i class="icomoon pr-half icomoon-price info-card-icon"></i>
        <div class="mr-05">{{'INVOICE_BALANCE_STARTING_BALANCE' | translate}}</div>
        <div>{{balance.startingBalance | formattedNumber}} {{balance.currencyCode}}</div>
      </div>
    </div>
  </div>

</div>
