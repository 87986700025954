/* eslint-disable */
import { Injectable } from '@angular/core';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { Observable } from 'rxjs';
import { ResourceQueryResult } from '../util/services';
import { EmptyMessage } from '../util/messages';
import { HttpClient } from '@angular/common/http';
/* eslint-enable */

@Injectable()
export class EmailTemplateResourceService extends BaseHttpService {

  query(request: EmailTemplateResource.QueryRequest): Observable<ResourceQueryResult<EmailTemplateResource.EmailTemplate>> {
    return this.http.get<ResourceQueryResult<EmailTemplateResource.EmailTemplate>>(this.url, this.parseParams(request));
  }

  get(request: EmailTemplateResource.GetRequest): Observable<EmailTemplateResource.EmailTemplate> {
    return this.http.get<EmailTemplateResource.EmailTemplate>(this.url + `${request.id}`);
  }

  create(request: EmailTemplateResource.CreateRequest): Observable<EmptyMessage> {
    return this.http.post(this.url, request);
  }

  update(request: EmailTemplateResource.UpdateRequest): Observable<EmptyMessage> {
    return this.http.put(this.url + `${request.id}`, request);
  }

  disable(request: EmailTemplateResource.DisableRequest): Observable<EmptyMessage> {
    return this.http.patch(this.url + `${request.id}` + '/disabled', request);
  }

  getAvailableSystemCodes(request: EmailTemplateResource.AvailableSystemCodesRequest): Observable<string[]> {
    return this.http.get<string[]>(this.url + '/available-system-codes', this.parseParams(request));
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/email-templates/');
  }

}

export namespace EmailTemplateResource {

  export interface EmailTemplate {
    id: number;
    external_id: string;
    name: string;
    subject: string;
    template: string;
    disabled: boolean;
    creation_time: string;
    update_time: string;
  }

  export interface QueryRequest {
    id?: string;
    external_id?: string;
    name?: string;
    subject?: string;
    disabled?: boolean;
    q?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
    no_progress_bar?: boolean;
  }

  export interface GetRequest {
    id: number;
  }

  export interface CreateRequest {
    external_id?: string;
    name: string;
    subject: string;
    template: string;
  }

  export interface UpdateRequest {
    id: number;
    external_id: string;
    name: string;
    subject: string;
    template: string;
  }

  export interface DisableRequest {
    id: number;
    disabled: boolean;
  }

  export interface AvailableSystemCodesRequest {
    code: string;
    no_progress_bar?: boolean;
  }

}
