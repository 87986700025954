import { Observable, Observer } from 'rxjs';
import { PagingRequest, QueryResult, ResourceQueryResult, Services } from '../util/services';
import { EmptyMessage } from '../util/messages';
import { List, Set } from 'immutable';
import { OffsetDateTime } from '../util/dates';
import { DqlQuery, Query } from '../query/field';
import { FilterField } from '../query/filterfields';
import { OrderField } from '../query/orderfields';
import { OrderDocumentResource, OrderDocumentResourceService } from './order-document-resource.service';
import { Injectable } from '@angular/core';
import { DownloadedFile } from '../util/downloaded-files';

export namespace OrderDocument {

  export interface Service {
    query(request: QueryRequest): Observable<QueryResult<OrderDocument>>;
    get(request: GetRequest): Observable<OrderDocument>;
    update(request: UpdateRequest): Observable<EmptyMessage>;
    delete(request: DeleteRequest): Observable<EmptyMessage>;
    download(request: UUIDMessage): Observable<DownloadedFile>;
  }

  export interface OrderDocument {
    id: string;
    creationTime: OffsetDateTime;
    grantedRights: Set<string>;
    orderId: number;
    uploaderUserId?: number;
    originalFileName: string;
    contentHash: string;
    contentSize: number;
    contentType: string;
    meta: Meta;
  }

  export interface Meta {
    type?: string;
    comment?: string;
    updateTime: OffsetDateTime;
    version: number;
  }

  export interface QueryRequest {
    fields?: Query.FieldFunction<Fields.OrderDocument>;
    filter?: Query.FilterFunction<FilterField.OrderDocument>;
    order?: Query.OrderFunction<OrderField.OrderDocument>;
    paging?: PagingRequest;
    rights?: Set<string>;
  }

  export interface GetRequest extends UUIDMessage {
  }

  export interface UpdateRequest extends UUIDMessage {
    version: number;
    type?: string;
    comment?: string;
  }

  export interface DeleteRequest extends UUIDMessage {
  }

  export interface UUIDMessage {
    id: string;
  }

  export function toPublicOrderDocument(r: OrderDocumentResource.OrderDocument): OrderDocument.OrderDocument {
    return {
      id: r.id,
      creationTime: Services.toOffsetDateTime(r.creation_time),
      grantedRights: r.granted_rights ? Set.of(...r.granted_rights) : Set.of(),
      orderId: r.order_id,
      uploaderUserId: r.uploader_user_id,
      originalFileName: r.original_file_name,
      contentHash: r.content_hash,
      contentSize: r.content_size,
      contentType: r.content_type,
      meta: this.toPublicOrderDocumentMeta(r.meta)
    }
  }

  export function toPublicOrderDocumentMeta(r: OrderDocumentResource.Meta): OrderDocument.Meta {
    return {
      type: r.type,
      comment: r.comment,
      updateTime: Services.toOffsetDateTime(r.update_time),
      version: r.version
    }
  }

  export function toResourceUpdateRequest(request: OrderDocument.UpdateRequest): OrderDocumentResource.UpdateRequest {
    return {
      id: request.id,
      version: request.version,
      type: request.type,
      comment: request.comment
    }
  }

  export function toResourceDeleteRequest(request: OrderDocument.DeleteRequest): OrderDocumentResource.DeleteRequest {
    return {
      id: request.id
    }
  }

  export namespace Fields {

    // Each class should be an interface, but it is easier to define the constant fields in the constructors.
    // For now we have only one query language so it is not a problem.
    // Always use the public Query namespace for variable definitions so we will be able to introduce other language easily.

    export class OrderDocument {

      readonly id: Query.Field = new DqlQuery.Field('id');
      readonly creationTime: Query.Field = new DqlQuery.Field('creation_time');
      readonly originalFileName: Query.Field = new DqlQuery.Field('original_file_name');
      readonly contentHash: Query.Field = new DqlQuery.Field('content_hash');
      readonly contentSize: Query.Field = new DqlQuery.Field('content_size');
      readonly contentType: Query.Field = new DqlQuery.Field('content_type');
      readonly meta: Query.Field = new DqlQuery.Field('meta');
      readonly orderId: Query.Field = new DqlQuery.Field('order_id');
      readonly uploaderUserId: Query.Field = new DqlQuery.Field('uploader_user_id');

      get each(): Set<Query.Field> {
        return Set.of(
          this.id, this.creationTime,
          this.originalFileName, this.contentHash, this.contentSize,
          this.contentType, this.meta, this.orderId,
          this.uploaderUserId
        );
      }

    }

  }

}

@Injectable()
export class OrderDocumentService implements OrderDocument.Service {

  private readonly filterField = new FilterField.OrderDocument();
  private readonly orderField = new OrderField.OrderDocument();
  private readonly fields = new OrderDocument.Fields.OrderDocument();

  constructor(private resourceService: OrderDocumentResourceService) {
  }

  query(request: OrderDocument.QueryRequest): Observable<QueryResult<OrderDocument.OrderDocument>> {
    const filter: string | undefined = DqlQuery.toOptionalFilter(this.filterField, request.filter);
    const order: string | undefined = DqlQuery.toOptionalOrder(this.orderField, request.order);
    const fields: string | undefined = DqlQuery.toOptionalFields(this.fields, request.fields);
    return Observable.create((observer: Observer<QueryResult<OrderDocument.OrderDocument>>) => {
      const resourceRequest: OrderDocumentResource.QueryRequest = {
        filter: filter,
        order: order,
        fields: fields,
        rights: DqlQuery.RightRequestSerializer.getInstance().serialize(request.rights),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined
      };
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<OrderDocumentResource.OrderDocument>) => {
          observer.next({
            items: List.of(...result.items.map((item) => OrderDocument.toPublicOrderDocument(item))),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  get(request: OrderDocument.GetRequest): Observable<OrderDocument.OrderDocument> {
    return Observable.create((observer: Observer<OrderDocument.OrderDocument>) => {
      return this.resourceService.get(request).subscribe(
        (result: OrderDocumentResource.OrderDocument) => {
          observer.next(OrderDocument.toPublicOrderDocument(result));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  update(request: OrderDocument.UpdateRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: OrderDocumentResource.UpdateRequest = OrderDocument.toResourceUpdateRequest(request);
      return this.resourceService.update(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  delete(request: OrderDocument.DeleteRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: OrderDocumentResource.DeleteRequest = OrderDocument.toResourceDeleteRequest(request);
      return this.resourceService.delete(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  download(request: OrderDocument.UUIDMessage): Observable<DownloadedFile> {
    return this.resourceService.download(request);
  }

}
