/* eslint-disable */
import { Dates, OffsetDateTime } from '../lib/util/dates';
import { Icon } from '../lib/task/icon.service';
import { OptionItem } from './core-utils';
import { CustomerRecord } from '../lib/customer/customer-record.service';
import { Customer } from '../lib/customer/customer.service';
import { AddressModel } from '../lib/address';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { List, Set } from 'immutable';
import { GrantedPermissionSet, GrantedPermissionSetResolver } from '../lib/right.service';
import { OperationRights } from '../app.right-definitions';
import { TaskRecordStateMachine } from '../lib/task/task-record-statemachine';
import CustomerType = Customer.CustomerType;
import OwnerUserData = CustomerRecord.OwnerUserData;
import OwnerGroupData = CustomerRecord.OwnerGroupData;

/* eslint-enable */

export class CustomerRecordListModel {
  selected: boolean;
  customerRecordId: number;
  customerId: number;
  customerIcon?: Icon.Icon;
  customerRecord: CustomerRecord.CustomerRecord;
  customerName?: string;
  externalId?: string;
  postalAddress?: AddressModel.PostalAddressModel;
  phoneNumbers?: AddressModel.PhoneNumberModel[] = [];
  emailAddresses?: AddressModel.EmailAddressModel[] = [];
  ownerUsers: List<OwnerUserData>;
  ownerGroups: List<OwnerGroupData>;
  disabled: boolean;
  creationTime: OffsetDateTime;
  updateTime: OffsetDateTime;
  name: string;
  comment?: string;
  mainContractNumber?: string;
  contactPerson: boolean;
  rights: CustomerRecordRightModel = new CustomerRecordRightModel(GrantedPermissionSetResolver.empty());
  taskRecordData?: CustomerRecordTaskRecordData;
}

export class CustomerItem {
  id: number;
  name: string;
  icon?: Icon.Icon;
}

export class CustomerRecordTaskRecordData {
  taskRecordCount: number = 0;
  latestTaskRecordData?: {
    id: number,
    taskId: number,
    creationTime: OffsetDateTime,
    name: string,
    state: TaskRecordStateMachine.StateObject,
  };
}

export class CustomerListItem {
  id: number;
  name: string;
  icon?: Icon.Icon;
  type: CustomerType;
}

export class CustomerRecordItem extends OptionItem<number> {
  customerRecord?: CustomerRecord.CustomerRecord;
  address?: string;
  invoiceDeadlineAdditionalDays?: number;
  companyId?: number;
  customerId?: number;
}

export class CustomerItemForSearch extends OptionItem<number> {
}

export namespace CustomerRecordUtils {

  export const DateOfBirthMinDate: NgbDateStruct = {
    day: 1,
    month: 1,
    year: 1900
  };

  export const DateOfBirthMaxDate: NgbDateStruct = {
    day: Dates.now().getDay(),
    month: Dates.now().getMonth(),
    year: Dates.now().getYear(),
  };

  export const DateOfBirthDefaultDate = {
    month: 1,
    year: 1980
  };

}

export enum CustomerRecordFieldType {
  WEB_ADDRESS = 'WEB_ADDRESS',
  TAX_NUMBER = 'TAX_NUMBER',
  EU_TAX_NUMBER = 'EU_TAX_NUMBER',
  PLACE_OF_BIRTH = 'PLACE_OF_BIRTH',
  DATE_OF_BIRTH = 'DATE_OF_BIRTH',
  BIRTH_NAME = 'BIRTH_NAME',
  GENDER = 'GENDER',
  MOTHERS_NAME = 'MOTHERS_NAME',
  IDENTITY_DOCUMENT_TYPE = 'IDENTITY_DOCUMENT_TYPE',
  IDENTITY_DOCUMENT_NUMBER = 'IDENTITY_DOCUMENT_NUMBER',
  INVOICE_DEADLINE_ADDITIONAL_DAYS = 'INVOICE_DEADLINE_ADDITIONAL_DAYS',
  PHONE_NUMBERS = 'PHONE_NUMBERS',
  EMAIL_ADDRESSES = 'EMAIL_ADDRESSES',
  CONTACT_PERSONS = 'CONTACT_PERSONS',
  CONTACT_LOCATIONS = 'CONTACT_LOCATIONS',
  POSTAL_ADDRESS_AND_INVOICE_ADDRESS_AND_NOTIFICATION_ADDRESS = 'POSTAL_ADDRESS_AND_INVOICE_ADDRESS_AND_NOTIFICATION_ADDRESS',
  ASSIGNEE = 'ASSIGNEE',
  TASK_RECORD_DEADLINE_ADDITIONAL_DAYS = 'TASK_RECORD_DEADLINE_ADDITIONAL_DAYS',
  COMPANY = 'COMPANY',
  LEDGER_NUMBER = 'LEDGER_NUMBER',
  COMMENT = 'COMMENT',
  POSITION = 'POSITION',
  EXTERNAL_ID = 'EXTERNAL_ID',
  PARENT_CUSTOMER_RECORD = 'PARENT_CUSTOMER_RECORD',
  MAIN_CONTRACT_NUMBER = 'MAIN_CONTRACT_NUMBER'

}

export class CustomerRecordField {
  public static readonly WEB_ADDRESS: CustomerRecordField
    = new CustomerRecordField(CustomerRecordFieldType.WEB_ADDRESS, 'CUSTOMER_RECORD_WEB_ADDRESS');
  public static readonly TAX_NUMBER: CustomerRecordField
    = new CustomerRecordField(CustomerRecordFieldType.TAX_NUMBER, 'CUSTOMER_RECORD_TAX_NUMBER');
  public static readonly EU_TAX_NUMBER: CustomerRecordField
    = new CustomerRecordField(CustomerRecordFieldType.EU_TAX_NUMBER, 'CUSTOMER_RECORD_EU_TAX_NUMBER');
  public static readonly PLACE_OF_BIRTH: CustomerRecordField
    = new CustomerRecordField(CustomerRecordFieldType.PLACE_OF_BIRTH, 'CUSTOMER_RECORD_PLACE_OF_BIRTH');
  public static readonly DATE_OF_BIRTH: CustomerRecordField
    = new CustomerRecordField(CustomerRecordFieldType.DATE_OF_BIRTH, 'CUSTOMER_RECORD_DATE_OF_BIRTH');
  public static readonly BIRTH_NAME: CustomerRecordField
    = new CustomerRecordField(CustomerRecordFieldType.BIRTH_NAME, 'CUSTOMER_RECORD_BIRTH_NAME');
  public static readonly GENDER: CustomerRecordField
    = new CustomerRecordField(CustomerRecordFieldType.GENDER, 'CUSTOMER_RECORD_GENDER');
  public static readonly MOTHERS_NAME: CustomerRecordField
    = new CustomerRecordField(CustomerRecordFieldType.MOTHERS_NAME, 'CUSTOMER_RECORD_MOTHERS_NAME');
  public static readonly IDENTITY_DOCUMENT_TYPE: CustomerRecordField
    = new CustomerRecordField(CustomerRecordFieldType.IDENTITY_DOCUMENT_TYPE, 'CUSTOMER_RECORD_IDENTITY_DOCUMENT_TYPE');
  public static readonly IDENTITY_DOCUMENT_NUMBER: CustomerRecordField
    = new CustomerRecordField(CustomerRecordFieldType.IDENTITY_DOCUMENT_NUMBER, 'CUSTOMER_RECORD_IDENTITY_DOCUMENT_NUMBER');
  public static readonly PHONE_NUMBERS: CustomerRecordField
    = new CustomerRecordField(CustomerRecordFieldType.PHONE_NUMBERS, 'CUSTOMER_RECORD_PHONE_NUMBERS');
  public static readonly EMAIL_ADDRESSES: CustomerRecordField
    = new CustomerRecordField(CustomerRecordFieldType.EMAIL_ADDRESSES, 'CUSTOMER_RECORD_EMAIL_ADDRESSES');
  public static readonly CONTACT_PERSONS: CustomerRecordField
    = new CustomerRecordField(CustomerRecordFieldType.CONTACT_PERSONS, 'COMMON_LOCATION_CONTACT_PERSONS');
  public static readonly CONTACT_LOCATIONS: CustomerRecordField
    = new CustomerRecordField(CustomerRecordFieldType.CONTACT_LOCATIONS, 'CUSTOMER_RECORD_LOCATIONS');
  public static readonly INVOICE_DEADLINE_ADDITIONAL_DAYS: CustomerRecordField
    = new CustomerRecordField(CustomerRecordFieldType.INVOICE_DEADLINE_ADDITIONAL_DAYS, 'CUSTOMER_RECORD_INVOICE_DEADLINE_ADDITIONAL_DAYS');
  public static readonly POSTAL_ADDRESS_AND_INVOICE_ADDRESS_AND_NOTIFICATION_ADDRESS: CustomerRecordField
    = new CustomerRecordField(CustomerRecordFieldType.POSTAL_ADDRESS_AND_INVOICE_ADDRESS_AND_NOTIFICATION_ADDRESS,
    'CUSTOMER_RECORD_ADDRESSES');
  public static readonly ASSIGNEE: CustomerRecordField
    = new CustomerRecordField(CustomerRecordFieldType.ASSIGNEE, 'CUSTOMER_RECORD_QUICK_CREATE_ASSIGNEE');
  public static readonly TASK_RECORD_DEADLINE_ADDITIONAL_DAYS: CustomerRecordField
    = new CustomerRecordField(CustomerRecordFieldType.TASK_RECORD_DEADLINE_ADDITIONAL_DAYS,
    'CUSTOMER_RECORD_TASK_RECORD_DEADLINE_ADDITIONAL_DAYS');
  public static readonly COMPANY: CustomerRecordField
    = new CustomerRecordField(CustomerRecordFieldType.COMPANY, 'COMMON_COMPANY');
  public static readonly LEDGER_NUMBER: CustomerRecordField
    = new CustomerRecordField(CustomerRecordFieldType.LEDGER_NUMBER, 'LEDGER_NUMBER_LEDGER_NUMBER');

  public static readonly COMMENT: CustomerRecordField
    = new CustomerRecordField(CustomerRecordFieldType.COMMENT, 'COMMON_COMMENT');
  public static readonly MAIN_CONTRACT_NUMBER: CustomerRecordField
    = new CustomerRecordField(CustomerRecordFieldType.MAIN_CONTRACT_NUMBER, 'CUSTOMER_RECORD_CONTRACT_NUMBER');
  public static readonly POSITION: CustomerRecordField
    = new CustomerRecordField(CustomerRecordFieldType.POSITION, 'CUSTOMER_RECORD_POSITION');
  public static readonly EXTERNAL_ID: CustomerRecordField
    = new CustomerRecordField(CustomerRecordFieldType.EXTERNAL_ID, 'COMMON_EXTERNAL_ID');
  public static readonly PARENT_CUSTOMER_RECORD: CustomerRecordField
    = new CustomerRecordField(CustomerRecordFieldType.PARENT_CUSTOMER_RECORD, 'CUSTOMER_RECORD_PARENT');

  public static getFields(): CustomerRecordField[] {
    return [
      this.WEB_ADDRESS,
      this.TAX_NUMBER,
      this.EU_TAX_NUMBER,
      this.PLACE_OF_BIRTH,
      this.DATE_OF_BIRTH,
      this.BIRTH_NAME,
      this.GENDER,
      this.MOTHERS_NAME,
      this.IDENTITY_DOCUMENT_TYPE,
      this.IDENTITY_DOCUMENT_NUMBER,
      this.INVOICE_DEADLINE_ADDITIONAL_DAYS,
      this.PHONE_NUMBERS,
      this.EMAIL_ADDRESSES,
      this.CONTACT_PERSONS,
      this.CONTACT_LOCATIONS,
      this.POSTAL_ADDRESS_AND_INVOICE_ADDRESS_AND_NOTIFICATION_ADDRESS,
      this.ASSIGNEE,
      this.TASK_RECORD_DEADLINE_ADDITIONAL_DAYS,
      this.COMPANY,
      this.LEDGER_NUMBER,
      this.PARENT_CUSTOMER_RECORD,
      this.COMMENT,
      this.POSITION,
      this.EXTERNAL_ID,
      this.MAIN_CONTRACT_NUMBER,
    ];
  }

  public static from(type: CustomerRecordFieldType): CustomerRecordField {
    return this.getFields().find(f => f.type === type)!;
  }

  public static getManageableCompanyFields(): CustomerRecordField[] {
    return [
      this.WEB_ADDRESS,
      this.TAX_NUMBER,
      this.EU_TAX_NUMBER,
      this.INVOICE_DEADLINE_ADDITIONAL_DAYS,
      this.PHONE_NUMBERS,
      this.EMAIL_ADDRESSES,
      this.CONTACT_PERSONS,
      this.CONTACT_LOCATIONS,
      this.POSTAL_ADDRESS_AND_INVOICE_ADDRESS_AND_NOTIFICATION_ADDRESS,
      this.ASSIGNEE,
      this.TASK_RECORD_DEADLINE_ADDITIONAL_DAYS,
      this.COMPANY,
      this.LEDGER_NUMBER,
      this.PARENT_CUSTOMER_RECORD,
      this.COMMENT,
      this.EXTERNAL_ID,
      this.MAIN_CONTRACT_NUMBER,
    ];
  }

  public static getManageablePersonFields(): CustomerRecordField[] {
    return [
      this.PLACE_OF_BIRTH,
      this.DATE_OF_BIRTH,
      this.BIRTH_NAME,
      this.GENDER,
      this.MOTHERS_NAME,
      this.IDENTITY_DOCUMENT_TYPE,
      this.IDENTITY_DOCUMENT_NUMBER,
      this.INVOICE_DEADLINE_ADDITIONAL_DAYS,
      this.PHONE_NUMBERS,
      this.EMAIL_ADDRESSES,
      this.POSTAL_ADDRESS_AND_INVOICE_ADDRESS_AND_NOTIFICATION_ADDRESS,
      this.ASSIGNEE,
      this.TASK_RECORD_DEADLINE_ADDITIONAL_DAYS,
      this.COMPANY,
      this.LEDGER_NUMBER,
      this.PARENT_CUSTOMER_RECORD,
      this.COMMENT,
      this.EXTERNAL_ID,
      this.POSITION,
      this.MAIN_CONTRACT_NUMBER,
    ];
  }

  public static getRequirableCompanyFields(): CustomerRecordField[] {
    return [
      this.WEB_ADDRESS,
      this.TAX_NUMBER,
      this.EU_TAX_NUMBER,
      this.INVOICE_DEADLINE_ADDITIONAL_DAYS,
      this.PHONE_NUMBERS,
      this.EMAIL_ADDRESSES,
      this.ASSIGNEE,
      this.TASK_RECORD_DEADLINE_ADDITIONAL_DAYS,
      this.COMPANY,
      this.PARENT_CUSTOMER_RECORD,
      this.COMMENT,
      this.MAIN_CONTRACT_NUMBER,
      this.POSTAL_ADDRESS_AND_INVOICE_ADDRESS_AND_NOTIFICATION_ADDRESS
    ];
  }

  public static getRequirablePersonFields(): CustomerRecordField[] {
    return [
      this.PLACE_OF_BIRTH,
      this.DATE_OF_BIRTH,
      this.BIRTH_NAME,
      this.GENDER,
      this.MOTHERS_NAME,
      this.IDENTITY_DOCUMENT_TYPE,
      this.IDENTITY_DOCUMENT_NUMBER,
      this.INVOICE_DEADLINE_ADDITIONAL_DAYS,
      this.PHONE_NUMBERS,
      this.EMAIL_ADDRESSES,
      this.ASSIGNEE,
      this.TASK_RECORD_DEADLINE_ADDITIONAL_DAYS,
      this.COMPANY,
      this.PARENT_CUSTOMER_RECORD,
      this.COMMENT,
      this.POSITION,
      this.MAIN_CONTRACT_NUMBER,
      this.POSTAL_ADDRESS_AND_INVOICE_ADDRESS_AND_NOTIFICATION_ADDRESS
    ];
  }

  constructor(public readonly type: CustomerRecordFieldType, public readonly stringKey: string) {
  }
}

export class CustomerRecordFieldModel {
  private managedFields: Set<CustomerRecordFieldType> = Set.of();
  private requiredFields: Set<CustomerRecordFieldType> = Set.of();

  public static empty(): CustomerRecordFieldModel {
    return new CustomerRecordFieldModel();
  }

  public static ofCustomer(customer: Customer.Customer): CustomerRecordFieldModel {
    const model = new CustomerRecordFieldModel();
    model.managedFields = customer.managedFields;
    model.requiredFields = customer.requiredFields;
    return model;
  }

  public static ofFields(managedFields: Set<CustomerRecordFieldType>, requiredFields: Set<CustomerRecordFieldType>):
    CustomerRecordFieldModel {
    const model = new CustomerRecordFieldModel();
    model.managedFields = managedFields;
    model.requiredFields = requiredFields;
    return model;
  }

  public static ofManaged(managedFields: Set<CustomerRecordFieldType>): CustomerRecordFieldModel {
    const model = new CustomerRecordFieldModel();
    model.managedFields = managedFields;
    return model;
  }

  public static ofRequired(requiredFields: Set<CustomerRecordFieldType>): CustomerRecordFieldModel {
    const model = new CustomerRecordFieldModel();
    model.requiredFields = requiredFields;
    return model;
  }

  public isManaged(type: CustomerRecordFieldType): boolean {
    return this.managedFields.contains(type);
  }

  public isRequired(type: CustomerRecordFieldType): boolean {
    return this.requiredFields.contains(type);
  }
}

export class CustomerRecordRightModel {

  public readonly read: GrantedPermissionSet;
  public readonly create: GrantedPermissionSet;
  public readonly update: GrantedPermissionSet;
  public readonly disable: GrantedPermissionSet;
  public readonly attachmentRead: GrantedPermissionSet;
  public readonly attachmentUploadFile: GrantedPermissionSet;
  public readonly attachmentDeleteFile: GrantedPermissionSet;
  public readonly attachmentUpdateData: GrantedPermissionSet;

  constructor(resolver: GrantedPermissionSetResolver) {
    this.read = resolver.of(OperationRights.CUSTOMER_RECORD_READ);
    this.create = resolver.of(OperationRights.CUSTOMER_RECORD_CREATE);
    this.update = resolver.of(OperationRights.CUSTOMER_RECORD_UPDATE);
    this.disable = resolver.of(OperationRights.CUSTOMER_RECORD_DISABLE);
    this.attachmentRead = resolver.of(OperationRights.CUSTOMER_RECORD_ATTACHMENT_READ);
    this.attachmentUploadFile = resolver.of(OperationRights.CUSTOMER_RECORD_ATTACHMENT_UPLOAD_FILE);
    this.attachmentDeleteFile = resolver.of(OperationRights.CUSTOMER_RECORD_ATTACHMENT_DELETE_FILE);
    this.attachmentUpdateData = resolver.of(OperationRights.CUSTOMER_RECORD_ATTACHMENT_UPDATE_DATA);
  }

}
