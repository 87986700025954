/* eslint-disable */
import {Component, ComponentFactoryResolver, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {INVOICE_VALID_CURRENCY_CODE, InvoiceRecordCreateModel} from '../invoice-create/invoice-create-clone.model';
import {Decimal} from 'decimal.js/decimal';
import {Map} from 'immutable';
import {Models} from '../../../../util/model-utils';
import {OptionItem} from '../../../../util/core-utils';
import {VatRate} from '../../../../lib/vat-rate.service';
import {Invoice} from '../../../../lib/invoice/invoice/invoice.service';
import {RightModel} from '../../../../app.rights';
import {CurrencyService} from '../../../../lib/currency.service';

/* eslint-enable */


@Component({
  selector: 'app-invoice-record-container',
  templateUrl: './invoice-record-container.component.html',
  styleUrls: ['./invoice-record-container.component.scss']
})
export class InvoiceRecordContainerComponent implements OnInit {

  @Input()
  recordCreateModels: InvoiceRecordCreateModel[] = [];

  @Input()
  readonly: boolean = false;

  @Input()
  showSummary: boolean = true;

  @Input()
  selectableVatRates: VatRate.VatRate[] = [];

  @Input()
  selectableZeroVatRateTypes: OptionItem<VatRate.ZeroVatRateType>[] = [];

  @Input()
  summaryModel?: InvoiceSummaryModel;

  @Input()
  filterStockItemIds?: number[];

  addModel?: InvoiceRecordCreateModel;

  @Input()
  rightModel: RightModel = RightModel.empty();

  private _paymentType?: Invoice.InvoicePaymentType;
  @Input()
  set paymentType(value: Invoice.InvoicePaymentType) {
    this._paymentType = value;
    this.refreshPrices();
  }

  @Input()
  summaryRoundingEnabled: boolean = false;

  @Input()
  recordRoundingEnabled: boolean = false;

  @Input()
  zeroPricedRecordsAllowed: boolean = true;

  prices: { net: Decimal, gross: Decimal, currencyCode: string, vatSums: Map<string, string> } = {
    net: new Decimal(0),
    gross: new Decimal(0),
    currencyCode: INVOICE_VALID_CURRENCY_CODE,
    vatSums: Map.of(),
  };

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
              private translateService: TranslateService) {
  }

  ngOnInit() {
  }

  newRecord() {
    this.addModel = new InvoiceRecordCreateModel();
  }

  saveNewRecord(model: InvoiceRecordCreateModel) {
    model.editing = false;
    this.recordCreateModels.push(model);
    this.addModel = undefined;
    this.refreshPrices();
  }

  cancelNewRecord() {
    this.addModel = undefined;
  }

  toggleEdit(index: number) {
    this.recordCreateModels[index].editing = !this.recordCreateModels[index].editing;
  }

  closeRow(index: number) {
    this.recordCreateModels[index].editing = false;
  }

  saveRecord(index: number, model: InvoiceRecordCreateModel) {
    this.recordCreateModels[index] = model;
    this.closeRow(index);
    this.refreshPrices();
  }

  removeRecord(index: number) {
    this.closeRow(index);
    this.recordCreateModels.splice(index, 1);
    this.refreshPrices();
  }

  refreshPrices() {
    let sumNet: Decimal = new Decimal('0');
    let sumGross: Decimal = new Decimal('0');
    this.recordCreateModels.forEach(r => {
      const rawNet = r.rawNetPrice;
      const rawGross = r.rawGrossPrice;
      if (rawNet) {
        sumNet = sumNet.add(rawNet.toString());
      }
      if (rawGross) {
        sumGross = sumGross.add(rawGross.toString());
      }
    });
    this.prices.net = this.summaryRoundingEnabled
      ? CurrencyService.round(sumNet, this.prices.currencyCode, this._paymentType)
      : sumNet;
    this.prices.gross = this.summaryRoundingEnabled
      ? CurrencyService.round(sumGross, this.prices.currencyCode, this._paymentType)
      : sumGross;
  }

  shouldPresentStockItem(record: InvoiceRecordCreateModel) {
    if (this.readonly && this.filterStockItemIds) {
      return !!(record.readonlyStockItemId !== undefined && this.filterStockItemIds.find(s => s === record.readonlyStockItemId));
    }
    return true;
  }

  roundRecordPrice(price: Decimal | undefined): string {
    if (!price) {
      return '';
    }
    if (!this.recordRoundingEnabled) {
      return Models.decimalToString(price);
    }
    return Models.decimalToString(CurrencyService.round(price, this.prices.currencyCode, this._paymentType, true));
  }

  hasZeroPrice(): boolean {
    return this.recordCreateModels.filter(m => m.rawGrossPrice?.isZero()).length > 0;
  }
}

export interface InvoiceSummaryModel {
  netSumPrice: Decimal;
  grossSumPrice: Decimal;
  vatSums: Invoice.InvoiceVatSum[];
  currencyCode: string;
}
