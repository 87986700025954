<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div class="breadcrumb-menu d-flex">
    <div class="breadcrumb-button-icon-container cursor-pointer"
         *ngIf="!componentState.isReadonly() && baseDataModel.rights.start.hasRight() && baseDataModel.isNew()"
         (click)="startExteriorTransport()">
      <a class="btn-setting" title="{{'EXTERIOR_TRANSPORT_START' | translate}}">
        <i class="icomoon icomoon-process-start"></i>
        <span class="breadcrumb-button-text-visible">{{'EXTERIOR_TRANSPORT_START' | translate}}</span>
      </a>
    </div>
    <div class="breadcrumb-button-icon-container cursor-pointer"
         *ngIf="baseDataModel.rights.start.hasRight() && baseDataModel.isNew()"
         (click)="stateImportDialog.toggleDialog()">
      <a class="btn-setting" title="{{'ORDER_MPL_IMPORT' | translate}}">
        <i class="icomoon icomoon-csv-import"></i>
        <span class="breadcrumb-button-text-visible">{{'ORDER_MPL_IMPORT' | translate}}</span>
      </a>
    </div>
    <div class="breadcrumb-button-icon-container cursor-pointer"
         *ngIf="baseDataModel.rights.start.hasRight() && baseDataModel.isNew()"
         (click)="showMplExportDialog()">
      <a class="btn-setting" title="{{'ORDER_MPL_EXPORT' | translate}}">
        <i class="icomoon icomoon-csv-export"></i>
        <span class="breadcrumb-button-text-visible">{{'ORDER_MPL_EXPORT' | translate}}</span>
      </a>
    </div>
    <app-breadcrumb-button
      class="negative-margin-right"
      *ngIf="componentState.isDetailView()"
      [iconClass]="'icomoon-modify'"
      [titleStringKey]="'COMMON_BUTTON_EDIT'"
      uiSref="Admin.ExteriorTransportEdit"
      [uiParams]="{ id: componentState.id }">
    </app-breadcrumb-button>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">
  <responsive-tabset class="fullscreen_tab">
    <responsive-tab heading="{{'EXTERIOR_TRANSPORT_SHIPMENTS'|translate}}" (selectTab)="activateTab('SHIPMENTS')">
      <ng-container [ngTemplateOutlet]="baseDataTemplate"></ng-container>
      <div class="container-vertical-padding-half"></div>
      <!--SHIPMENTS-->
      <app-exterior-transport-shipments
        #shipmentsComponent
        [exteriorTransportId]="componentState.id!"
        [readonly]="componentState.isReadonly()"
        [deletable]="baseDataModel.isNew()"
        (shipmentRemove)="showShipmentRemoveDialog($event)"
        [exteriorTransportRights]="baseDataModel.rights"
      ></app-exterior-transport-shipments>
      <!--END SHIPMENTS-->
    </responsive-tab>
    <responsive-tab heading="{{'EXTERIOR_TRANSPORT_DOCUMENTS'|translate}}" (selectTab)="activateTab('DOCUMENTS')">
      <ng-container [ngTemplateOutlet]="baseDataTemplate"></ng-container>
      <div class="container-vertical-padding-half"></div>
      <!--DOCUMENTS-->
      <app-exterior-transport-documents
        #documentsComponent
        [exteriorTransportId]="componentState.id!"
        [readonly]="componentState.isReadonly()"
        [exteriorTransportRights]="baseDataModel.rights"
        (onDocumentCreate)="documentCreateDialog.openDocumentCreateDialog()">
      </app-exterior-transport-documents>
      <!--END DOCUMENTS-->
    </responsive-tab>
    <responsive-tab heading="{{'COMMON_HISTORY'|translate}}" (selectTab)="activateTab('HISTORY')">
      <ng-container [ngTemplateOutlet]="baseDataTemplate"></ng-container>
      <div class="container-vertical-padding-half"></div>
      <app-exterior-transport-event-log
        [id]="this.componentState.id!">
      </app-exterior-transport-event-log>
    </responsive-tab>
  </responsive-tabset>
</div>

<!--Transport base data component template-->
<ng-template #baseDataTemplate>
  <app-exterior-transport-base-data
    [baseData]="baseDataModel"
    (openBaseDataDetailDialog)="openBaseDataDialog()">
  </app-exterior-transport-base-data>
</ng-template>
<!--Transport base data component template end-->

<div bsModal [config]="UiConstants.modalConfig" #baseDataDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="baseDataDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title"><strong>{{'FORM_ITEM_CREATE_DOCUMENT_EXTRA_DATA' | translate}}</strong></p>
        <button type="button" class="close" (click)="closeBaseDataDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-4 d-flex align-items-center justify-content-center">
            <div class="d-flex flex-column justify-content-center align-items-center">
              <i class="icomoon icon-xl" [ngClass]="baseDataModel.stateIconClass"></i>
              <h5>{{baseDataModel.state | translate}}</h5>
            </div>
          </div>
          <div class="col-md-8 mb-2">
            <div class="d-flex flex-row align-items-baseline">
              <!-- todo: correct icon -->
              <i class="icomoon icon-large icomoon-calendar pr-half"></i>
              <div class="transport-label-small pr-quarter">{{'EXTERIOR_TRANSPORT_SERIAL_NUMBER' | translate}}:</div>
              <div>{{baseDataModel.serialNumber}}</div>
            </div>
            <div class="d-flex flex-row align-items-baseline">
              <i class="icomoon icon-large icomoon-receipt-alt pr-half"></i>
              <div class="transport-label-small pr-quarter">{{'EXTERIOR_TRANSPORT_DEMANDER_COMPANY' | translate}}:</div>
              <div>{{baseDataModel.demander | translate}}</div>
            </div>
            <div class="d-flex flex-row align-items-baseline">
              <!-- todo: correct icon -->
              <i class="icomoon icon-large icomoon-stock pr-half"></i>
              <div class="transport-label-small pr-quarter">{{'EXTERIOR_TRANSPORT_TRANSPORTER_COMPANY' | translate}}:</div>
              <div>{{baseDataModel.transporter}}</div>
            </div>
          </div>
        </div>
        <div class="row mb-1">
          <div class="col-md-3">
            <div class="transport-label-small">{{'COMMON_TABLE_HEADER_ID' | translate}}</div>
            <div>{{baseDataModel.id}}</div>
          </div>
          <div class="col-md-9">
            <div class="transport-label-small">{{'COMMON_EXTERNAL_ID' | translate}}</div>
            <div>{{baseDataModel.externalId}}</div>
          </div>
        </div>
        <div class="row mb-1">
          <div class="col-md-3">
            <div class="transport-label-small">{{'COMMON_CREATION_TIME' | translate}}</div>
            <div>{{baseDataModel.creationTime.toUtcIsoString() | date:'short'}}</div>
          </div>
          <div class="col-md-3">
            <div class="transport-label-small">{{'TRANSPORT_START_TIME' | translate}}</div>
            <div>{{(baseDataModel.startTime?.toUtcIsoString() | date:'short') || '-'}}</div>
          </div>
          <div class="col-md-3">
            <div class="transport-label-small">{{'COMMON_FINISH_TIME' | translate}}</div>
            <div>{{(baseDataModel.finishTime?.toUtcIsoString() | date:'short') || '-'}}</div>
          </div>
        </div>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<app-exterior-transport-document-create-dialog
  #documentCreateDialog
  [exteriorTransportId]="this.componentState.id!"
  (onDocumentCreated)="documentsComponent.loadList()">
</app-exterior-transport-document-create-dialog>

<div bsModal [config]="UiConstants.modalConfig" #mplExportDialog="bs-modal" class="modal" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document" *ngIf="mplExportDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title"><strong>{{'ORDER_MPL_EXPORT' | translate}}</strong></p>
        <button type="button" class="close" (click)="closeMplExportDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="alert alert-info mb-0">
          <p class="mb-0">
            <span class="font-weight-bold">
            {{'EXTERIOR_TRANSPORT_MPL_EXPORT_INFO' | translate}}
            </span>
          </p>
        </div>
      </div><!-- /.modal-body -->

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeMplExportDialog()">{{'COMMON_BUTTON_CANCEL' |
            translate}}
        </button>
        <button type="submit" data-style="zoom-in" [ladda]="mplDownloading" [disabled]="mplDownloading" (click)="mplExport()"
                class="btn btn-primary btn-ladda px-2">{{'COMMON_BUTTON_DOWNLOAD' |
            translate}}
        </button>
      </div>

    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<app-file-upload-dialog
  [uploadPath]="mplStateImportPath"
  (onResult)="onImportSuccess($event)"
  #stateImportDialog
></app-file-upload-dialog>

<app-confirm-dialog
  #shipmentRemoveDialog
  [title]="'EXTERIOR_TRANSPORT_SHIPMENT_REMOVE' | translate"
  [alertType]="AlertType.DANGER"
  (onConfirm)="removeShipment()"
  (onCancel)="closeShipmentRemoveDialog()">
  <div class="form-group row" *ngIf="shipmentRemoveDialogVisible">
    <div class="col-md-12">
      <div class="d-flex" *ngIf="shipmentRemoveModel.trackingNumberPresent">
        <app-animated-checkbox
          [(selected)]="shipmentRemoveModel.resetTrackingNumber">
        </app-animated-checkbox>
        <label class="m-0 pl-1">{{'EXTERIOR_TRANSPORT_SHIPMENT_REMOVE_RESET_TRACKING_NUMBER' | translate}}</label>
      </div>
    </div>
  </div>
</app-confirm-dialog>
