/* eslint-disable */
import { mergeMap } from 'rxjs/operators';
import { Component } from '@angular/core';
import {
  FormRecordFieldContext,
  FormRecordFieldValueUpdateArgs,
  FormRecordFieldView,
  FormRecordFieldViewContext,
  FormRecordFieldViewModel,
  SetTmpReadonlyArgs,
  SetTmpReadonlyResult
} from '../../../../../util/form/form-utils';
import { Form } from '../../../../../lib/form/form.service';
import { InputMask } from '../../../../../util/input-masks';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  FormRef,
  ForwardingFormRef,
  LocalFormGroupValidationErrors,
  ResourceQueryResult
} from '../../../../../lib/util/services';
import { FieldActivationState, FieldActivationStateResolver } from '../../../../../util/form/form-editors';
import { Command } from '../../../../../util/command';
import { FormRecord } from '../../../../../lib/form/form-record.service';
import { StockRecord, StockRecordService } from '../../../../../lib/stock/stock-record.service';
import { StockItem, StockItemService, UnitPrice } from '../../../../../lib/stock/stock-item.service';
import Decimal from 'decimal.js';
import { StockService } from '../../../../../lib/stock/stock.service';
import { Set } from 'immutable';
import { FormRecordInactivityManager, noOpCommand } from '../../manager/form-record-inactivity-manager';
import ProcessOrderStockOuttakeItem = FormRecord.ProcessOrderStockOuttakeItem;
import { Observable } from 'rxjs';

/* eslint-enable */

@Component({
  selector: 'app-form-record-process-order-stock-outtake-field',
  templateUrl: './form-record-process-order-stock-outtake-field.component.html',
  styleUrls: ['./form-record-process-order-stock-outtake-field.component.scss']
})
export class FormRecordProcessOrderStockOuttakeFieldComponent implements FormRecordFieldView {

  public readonly selector: Form.FieldDataTypeSelector.PROCESS_ORDER_STOCK_OUTTAKE;

  formRecordFieldContext?: FormRecordFieldContext;
  formRecordInactivityManager?: FormRecordInactivityManager;

  model: Model = new Model();

  InputMask = InputMask;

  formGroup: FormGroup;
  private fieldId?: number;
  private htmlForm?: FormRef;
  tmpReadonly: boolean = false;
  private formGroupValidationErrors: LocalFormGroupValidationErrors;

  hide: () => boolean = () => true;

  private readonlyFormFn: () => boolean = () => true;
  private readonlyFieldFn: () => boolean = () => false;
  private hiddenFieldFn: () => boolean = () => false;
get nonEditable(): boolean {
    return FieldActivationStateResolver.isNonEditable(
      this.fieldActivationState
    );
  }

  private get readonlyForm(): boolean {
    return this.readonlyFormFn();
  }

  private get fieldActivationState(): FieldActivationState {
    return FieldActivationStateResolver.resolveFieldActivationState({
      readonlyFormFn: () => this.readonlyFormFn(),
      readonlyFieldFn: () => this.readonlyFieldFn(),
      tmpReadonlyFieldFn: () => this.tmpReadonly,
    });
  }

  setTmpReadonly(args: SetTmpReadonlyArgs): Command<SetTmpReadonlyResult> {
    return noOpCommand;
  }

  registerField(context: FormRecordFieldContext, originalModel?: any): any {
    if (originalModel) {
      this.model = originalModel;
    }
    this.formRecordFieldContext = context;
    this.fieldId = context.field.fieldId;
    this.htmlForm = context.htmlForm;
    this.model.title = context.field.title;
    this.readonlyFormFn = context.readonly;
    this.hiddenFieldFn = () => Form.FormFieldValidationType.HIDDEN === context.validationType;
    this.readonlyFieldFn = () => Form.FormFieldValidationType.READONLY === context.validationType
    || Form.FormFieldValidationType.HIDDEN === context.validationType;
    this.hide = context.cloning;
    const attrs = context.field.dataType.processOrderStockOuttakeAttributes!;
    if (context.fieldRecord && !this.hide()) {
      this.registerFieldData(context.fieldRecord);
    }
    return this.model;
  }

  registerFieldData(fieldRecord: FormRecord.FieldComposed): void {
    const attrs = fieldRecord.data.processOrderStockOuttakeAttributes!;
    this.loadStocks(attrs);
  }

  loadStocks(attrs: FormRecord.FieldDataProcessOrderStockOuttakeAttributes) {
    const items: StockItemModel[] = [];
    const recordIds: number[] = [];
    let records: StockRecord[] = [];
    let stockItems: StockItem[] = [];
    attrs.values.forEach((item: FormRecord.ProcessOrderStockOuttakeItem) => {
      recordIds.push(item.stockRecordId);
    });
    this.stockRecordService.query({id: recordIds.join(',')}).pipe(mergeMap((result: ResourceQueryResult<StockRecord>) => {
      const itemIds: number[] = [];
      result.items.forEach((sr) => {
        itemIds.push(sr.stock_item_id);
      });
      records = result.items;
      return this.stockItemService.query({id: itemIds.join(',')});
    })).subscribe((result: ResourceQueryResult<StockItem>) => {
      stockItems = result.items;
      attrs.values.forEach((i) => {
        if (i && i.amount && i.amount.greaterThan(0.0)) {
          const rec = records.find((r) => r.id === i.stockRecordId);
          if (rec) {
            const it = stockItems.find((r) => r.id === rec.stock_item_id);
            if (it) {
              items.push({
                amount: i.amount,
                stockRecord: {
                  id: rec.id,
                  stock: rec.stock_id,
                  amount: rec.amount,
                  stockItem: {
                    id: it.id,
                    name: it.name,
                    external_id: it.external_id,
                    product_code: it.product_code,
                    unit: it.unit,
                    unit_price: it.unit_price,
                    serial_code: it.serial_code
                  }
                }
              });
            }
          }
        }
      });
      if (items.length > 0) {
        this.model.stocks = items;
      }
      this.formGroup = this.createFormGroup(this.fb);
      this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(
        this.createForwardingHtmlForm(),
        this.formGroup
      );

    })
  }

  registerFieldViews(context: FormRecordFieldViewContext): void {
    this.formRecordInactivityManager = context.inactivityManager;
  }

  hasLocalFieldError(): boolean {
    return false;
  }

  validateWithInterrupt(): boolean {
    return false;
  }

  shouldNotifyAfterCreation(): boolean {
    return false;
  }

  afterFormRecordCreation(formRecordId: number): Observable<any> | undefined {
    return undefined;
  }

  createModel(): FormRecordFieldViewModel {
    if (this.fieldId === undefined) {
      throw new Error('Field ID is undefined');
    }
    const attrs: FormRecord.FieldDataProcessOrderStockOuttakeAttributes = {
      values: Set<ProcessOrderStockOuttakeItem>()
    };
    return {
      fieldEditRequest: {
        fieldId: this.fieldId,
        data: {
          processOrderStockOuttakeAttributes: attrs
        }
      }
    };
  }

  constructor(private stockRecordService: StockRecordService,
              private stockService: StockService,
              private stockItemService: StockItemService,
              private fb: FormBuilder) {
  }

  private createFormGroup(fb: FormBuilder) {
    const controls = {};
    return fb.group(
      controls
    );
  }

  private createForwardingHtmlForm() {
    return new ForwardingFormRef({
      formFn: () => {
        return this.htmlForm;
      }
    });
  }

  updateValue(data: FormRecordFieldValueUpdateArgs) {
  }

}

export class Model {
  stocks?: StockItemModel[] = [];
  title: string = '';
}

export class StockItemModel {
  amount?: Decimal = undefined;
  customNetPrice?: string = undefined;
  stockRecord: StockRecordModel = new StockRecordModel();
}

export class StockRecordModel {
  id: number;
  amount?: number = undefined;
  stock?: number = undefined;
  stockItem: StockItemFormModel = new StockItemFormModel();
}

export class StockItemFormModel {
  id: number;
  name?: string;
  external_id?: string;
  product_code?: string;
  serial_code?: string;
  unit?: string;
  unit_price?: UnitPrice;
}

