import { Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SearchUtils } from '../../../util/search-utils';
import { MultiselectOptionItem, OrderFieldFunction, OrderFieldModel, SelectUtils, UiConstants } from '../../../util/core-utils';
import { OrderField } from '../../../lib/query/orderfields';
import { OrderType, QueryResult } from '../../../lib/util/services';
import { Angular2Multiselects } from '../../../util/multiselect';
import { RightModel } from '../../../app.rights';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { RightResolver, RightService } from '../../../lib/right.service';
import { StateName } from '../../../app.state-names';
import { FilterField } from '../../../lib/query/filterfields';
import { CriteriaBuilder } from '../../../util/model-utils';
import { DeliveryMethodListModel } from '../delivery-method.model';
import { EmptyMessage } from '../../../lib/util/messages';
import {
  DeliveryMethodSearch,
  DeliveryMethodSearchService
} from '../../../lib/delivery-method/delivery-method-search.service';
import { DeliveryMethod, DeliveryMethodService } from '../../../lib/delivery-method/delivery-method.service';

@Component({
  selector: 'app-delivery-method-list',
  templateUrl: './delivery-method-list.component.html',
  styleUrls: ['./delivery-method-list.component.scss']
})
export class DeliveryMethodListComponent extends SearchUtils.SearchableList<DeliveryMethodSearch.Model>
  implements OnInit, OnDestroy {

  UiConstants = UiConstants;
  SelectUtils = SelectUtils;
  DeliveryMethodSearch = DeliveryMethodSearch;

  queryModel: OrderFieldModel<OrderField.DeliveryMethod>
    = new OrderFieldModel(DeliveryMethodSearch.OrderFunctions.ID, OrderType.DESC);
  searchModel: DeliveryMethodSearch.Model = new DeliveryMethodSearch.Model();
  types: MultiselectOptionItem<DeliveryMethod.DeliveryMethodType>[]
    = DeliveryMethod.types.map(o => ({id: o.type, itemName: o.stringKey}));

  dropdownSettings: Angular2Multiselects.Settings;

  deliveryMethodList: DeliveryMethodListModel[] = [];
  rightModel: RightModel = RightModel.empty();

  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  constructor(
    private deliveryMethodService: DeliveryMethodService,
    private deliveryMethodSearchService: DeliveryMethodSearchService,
    private rightService: RightService,
    injector: Injector) {
    super(DeliveryMethodSearch.Model, injector);
  }

  ngOnInit() {
    this.loadRightModels();
    this.initBreadcrumb();
    this.initSearch();
    this.initDropdownSettings();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  initBreadcrumb() {
    this.translateService.get('MENU_NAVBAR_DELIVERY_METHODS').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
  }

  initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .translate(true)
      .build();
  }

  loadSearch(completion: () => void) {
    this.deliveryMethodSearchService.getSearchData({})
      .subscribe(
        (result: DeliveryMethodSearch.SearchDataResult) => {
          this.queryModel = result.searchData.queryModel;
          this.searchModel = result.searchData.searchModel;
          completion();
        }
      );
  }

  onFirstSearchOpen(): void {}

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: OrderFieldFunction<OrderField.DeliveryMethod>) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const request = this.getQueryRequest(requestedPage);
    this.deliveryMethodService.query(request).subscribe((result: QueryResult<DeliveryMethod.DeliveryMethod>) => {
      this.deliveryMethodList = [];
      result.items.forEach((deliveryMethod: DeliveryMethod.DeliveryMethod) => {
        const model = new DeliveryMethodListModel();
        model.id = deliveryMethod.id;
        model.name = deliveryMethod.name;
        model.externalId = deliveryMethod.externalId;
        model.type = DeliveryMethod.types.find(t => t.type === deliveryMethod.type)!.stringKey;
        model.creationTime = deliveryMethod.creationTime;
        model.disabled = deliveryMethod.disabled;
        this.deliveryMethodList.push(model);
      });
      this.queryModel.currentPage = requestedPage;
      this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
    });
  }

  private getQueryRequest(requestedPage?: number): DeliveryMethod.QueryRequest {
    const order = this.queryModel.createOrderFunction();
    const filter = this.createFilter();
    return {
      fields: f => f.forList,
      order: order,
      filter: filter,
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined,
    };
  }

  private createFilter() {
    return (f: FilterField.DeliveryMethod) => CriteriaBuilder.builder()
      .addString((name) => f.name.containsIgnoreCase(name), this.searchModel.name)
      .addString((code) => f.externalId.containsIgnoreCase(code), this.searchModel.externalId)
      .addEnum((type) => f.type.eq(type), this.searchModel.type ? this.searchModel.type : null)
      .build();
  }

  onSearchClicked() {
    this.loadList(1);
  }

  onSearchReset() {
    this.deliveryMethodSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.toggleSearch();
          this.loadList(1);
        });
      }
    );
  }

  ngOnDestroy() {
    this.saveSearch();
  }

  private saveSearch() {
    const request = {
        searchData: {
          queryModel: this.queryModel,
          searchModel: this.searchModel
        }
      }
    ;
    this.deliveryMethodSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  setDisabled(event: any, deliveryMethodId: number, disabled: boolean) {
    this.deliveryMethodService.setDisabled({
      id: deliveryMethodId,
      disabled: disabled
    })
      .subscribe(
        (result: EmptyMessage) => {
          this.loadList();
        },
        (error: any) => {
          this.loadList();
        }
      );
  }

}
