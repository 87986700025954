/* eslint-disable */
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BreadcrumbParent } from '../../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { StateName } from '../../../../app.state-names';
import { TranslateService } from '@ngx-translate/core';
import { TaskLog, TaskLogService } from '../../../../lib/statistics/task-log/task-log.service';
import { QueryFieldModel, UiConstants, UserItem } from '../../../../util/core-utils';
import { OrderType, QueryResult, ResourceQueryResult, Services } from '../../../../lib/util/services';
import { Set } from 'immutable';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { User, UserService } from '../../../../lib/user.service';
import { Arrays } from '../../../../lib/util/arrays';
import { Task, TaskService } from '../../../../lib/task/task.service';
import { Icon } from '../../../../lib/task/icon.service';
import { TaskRecord, TaskRecordService } from '../../../../lib/task/task-record.service';
import { Models } from '../../../../util/model-utils';
import { Strings } from '../../../../lib/util/strings';
import { Numbers } from '../../../../lib/util/numbers';
import { TaskLogSearch, TaskLogSearchService } from '../../../../lib/statistics/task-log/task-log-search.service';
import { combineLatest, Observable } from 'rxjs';
import { Logger, LoggerFactory } from '../../../../util/logger-factory';
/* eslint-enable */

@Component({
  selector: 'app-task-log-list',
  templateUrl: './task-log-list.component.html',
  styleUrls: ['./task-log-list.component.scss']
})
export class TaskLogListComponent implements OnInit, OnDestroy {

  @ViewChild('searchIcon', { static: true }) searchIcon: ElementRef;

  @ViewChild('application_type_id') applicationTypeId: ElementRef;

  TaskLog = TaskLog;
  UiConstants = UiConstants;

  taskLogList: TaskLog.TaskLog[] = [];
  tasks: TaskItemForLog[] = [];
  private userList: UserItem[] = [];
  queryModel: QueryFieldModel<TaskLog.OrderField> = new QueryFieldModel<TaskLog.OrderField>(TaskLog.OrderField.LOG_TIME, OrderType.DESC);
  searchModel: TaskLogSearchModel = new TaskLogSearchModel();

  showSearch: boolean = false;

  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  private logger: Logger;

  constructor(
    private translateService: TranslateService,
    private userService: UserService,
    private taskService: TaskService,
    private taskLogSearchService: TaskLogSearchService,
    private taskRecordService: TaskRecordService,
    private taskLogService: TaskLogService) {
    this.logger = LoggerFactory.createLogger('TaskLogListComponent');
  }

  ngOnInit() {
    this.translateService.get('MENU_NAVBAR_TASK_LOG').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.loadUsers();
    this.loadTasks();
    this.loadList();
    this.loadSearch(() => {
      this.showSearch = !this.searchModel.isEmpty();
      this.loadList();
    });
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: TaskLog.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  onSearchClicked() {
    this.loadList();
  }

  onSearchReset() {
    this.taskLogSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.showSearch = true;
          this.loadList(1);
        });
      }
    );
  }

  private loadSearch(completion: () => void) {
    const obs: Observable<SearchLoadResult> = combineLatest(
      this.taskLogSearchService.getSearchData({}),
      (storedSearchData: TaskLogSearch.SearchDataResult) => {
        const result: SearchLoadResult = {
          storedSearchData: storedSearchData
        };
        return result;
      }
    );
    obs.subscribe(
      (result: SearchLoadResult) => {
        this.postInitSearch(result.storedSearchData);
        completion();
      }
    );
  }

  private postInitSearch(storedSearchData: TaskLogSearch.SearchDataResult) {
    this.queryModel.itemsPerPage = storedSearchData.searchData.itemsPerPage;
    this.queryModel.currentPage = storedSearchData.searchData.pageNumber;
    this.queryModel.setOrder(storedSearchData.searchData.order);
    this.searchModel.userName = storedSearchData.searchData.userName;
    this.searchModel.applicationTypeId = storedSearchData.searchData.applicationTypeId;
    this.searchModel.taskTypeName = storedSearchData.searchData.taskTypeName;
    this.searchModel.taskName = storedSearchData.searchData.taskName;
    this.searchModel.logType = storedSearchData.searchData.logType;
    this.searchModel.message = storedSearchData.searchData.message;
    this.searchModel.logTimeFrom = Models.localDateToNgbDate(storedSearchData.searchData.logTimeFrom);
    this.searchModel.logTimeTo = Models.localDateToNgbDate(storedSearchData.searchData.logTimeTo);
  }

  private saveSearch() {
    const request = {
      searchData: {
        itemsPerPage: this.queryModel.itemsPerPage,
        pageNumber: this.queryModel.currentPage,
        order: this.queryModel.getOrder(),
        userName: this.searchModel.userName,
        applicationTypeId: this.searchModel.applicationTypeId,
        taskTypeName: this.searchModel.taskTypeName,
        taskName: this.searchModel.taskName,
        logType: this.searchModel.logType,
        message: this.searchModel.message,
        logTimeFrom: Models.ngbDateToLocalDate(this.searchModel.logTimeFrom),
        logTimeTo: Models.ngbDateToLocalDate(this.searchModel.logTimeTo)
      }
    };
    this.taskLogSearchService.setSearchData(request).subscribe(
      (result) => {
        this.logger.debug('Search model has saved');
      },
      (error) => {
        this.logger.warn('Failed to save the search model');
      }
    );
  }

  private loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();

    this.taskLogService.query({
      userName: Strings.undefinedOrNonEmpty(this.searchModel.userName),
      applicationTypeId: Numbers.undefinedOrNonEmpty(this.searchModel.applicationTypeId),
      taskTypeName: Strings.undefinedOrNonEmpty(this.searchModel.taskTypeName),
      taskName: Strings.undefinedOrNonEmpty(this.searchModel.taskName),
      logType: Strings.undefinedOrNonEmpty(this.searchModel.logType),
      message: Strings.undefinedOrNonEmpty(this.searchModel.message),
      logTimeFrom: Strings.undefinedOrNonEmpty(Services.offsetDateTimeToString(Models.parseDateTimeFrom(this.searchModel.logTimeFrom))),
      logTimeTo: Strings.undefinedOrNonEmpty(Services.offsetDateTimeToString(Models.parseDateTimeTo(this.searchModel.logTimeTo))),
      order: Set.of(order),
      paging: {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      }
    }).subscribe(
      (result: QueryResult<TaskLog.TaskLog>) => {
        this.taskLogList = result.items.toArray();
        this.queryModel.currentPage = requestedPage;
        this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
        this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
      }, () => {

      },
      () => {
        this.loadTaskRecords();
      }
    );
  }

  private loadUsers(): void {
    this.userService.query({})
      .subscribe(
      (result: ResourceQueryResult<User>) => {

        this.userList = [];

        Arrays.iterateByIndex(result.items, (user) => {
          const item = new UserItem();
          item.id = user.id;
          item.text = user.user_name;
          this.userList.push(item);
        });
      }
    );
  }

  private getUserName(user_id: number): string {
    const filtered_users = this.userList.filter(current_user => current_user.id === user_id);
    if (filtered_users === undefined || filtered_users.length === 0) {
      return ''
    }
    return filtered_users[0].text;
  }

  private getDeviceType(device_type_id: number): string {
    if (device_type_id === 1) {
      return 'TASK_LOG_TABLE_DEVICE_WEB';
    }
    else if (device_type_id === 2) {
      return 'TASK_LOG_TABLE_DEVICE_MOBILE';
    }
    else {
      return '';
    }
  }

  private loadTasks() {
    this.taskService.query({})
      .subscribe(
        (tasks: QueryResult<Task.Task>) => {
          this.tasks = [];
          tasks.items.forEach((task: Task.Task) => {
            const item = new TaskItemForLog();
            item.id = task.taskId;
            item.name = (task.name) ? task.name : '';
            item.icon = (task.icon) ? task.icon : undefined;
            this.tasks.push(item);
          });
        }
      );
  }

  private getTaskIcon(task_id: number): Icon.Icon | undefined {
    const filtered_tasks = this.tasks.filter(current_task => current_task.id === task_id);
    if (filtered_tasks === undefined || filtered_tasks.length === 0) {
      return undefined;
    }
    return filtered_tasks[0].icon;
  }

  loadTaskRecords() {
    const idsToLoad: number[] = [];
    this.taskLogList.forEach(item => {
      idsToLoad.push(item.taskRecordId);
    });
    this.taskRecordService.globalQuery(<TaskRecord.GlobalQueryRequest> {
      taskRecordIdSet: Set.of(...idsToLoad),
      fields: Set.of('id', 'name')
    }).subscribe((result: QueryResult<TaskRecord.TaskRecord>) => {
      result.items.forEach(item => {
        this.taskLogList.forEach(TaskLog => {
          if (item && item.taskRecordId === TaskLog.taskRecordId) {
            this.taskLogList[this.taskLogList.indexOf(TaskLog)].taskRecordName = item.name;
          }
        })
      })
    });
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  ngOnDestroy() {
    this.saveSearch();
  }

}

export class TaskLogSearchModel {
  logType: string;
  logTimeFrom: NgbDateStruct | null = null;
  logTimeTo: NgbDateStruct | null = null;
  userName: string;
  taskName: string;
  taskTypeName: string;
  message: string;
  applicationTypeId?: number = undefined;

  public isEmpty(): boolean {
    return this.logType.length === 0
      && this.applicationTypeId === undefined
      && this.userName.length === 0
      && this.taskTypeName.length === 0
      && this.taskName.length === 0
      && this.message.length === 0
      && this.logTimeFrom === null
      && this.logTimeTo === null
      ;
  }
}

export class TaskItemForLog {
  id: number;
  name: string;
  icon: Icon.Icon | undefined;
}

interface SearchLoadResult {
  storedSearchData: TaskLogSearch.SearchDataResult,
}
