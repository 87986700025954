import { StockItemMultiselectOptionItem } from '../../../../lib/stock/stock-item-multiselect.provider';
import { Strings } from '../../../../lib/util/strings';
import { MultiselectOptionItem } from '../../../../util/core-utils';
import { LedgerNumberMultiselectProvider } from '../../../../lib/ledger/number/ledger-number-multiselect.provider';
import { Models } from '../../../../util/model-utils';

export class InvoiceRecordEditModel {
  recordName: string = '';
  hunVtszNumber: string = '';
  amount: string = '1';
  unitType: string = '';
  private _netUnitPrice: string = '0';
  private _grossUnitPrice: string = '0';
  vatRate: number = 0;
  zeroVatRateType: string = '';
  comment: string = '';
  _stockItem: StockItemMultiselectOptionItem[] = [];
  _ledgerNumber: MultiselectOptionItem<number>[] = [];

  get netUnitPrice(): string {
    return this._netUnitPrice;
  }

  set netUnitPrice(value: string) {
    this._netUnitPrice = value;
    this._grossUnitPrice = '' + (this.getNumber(this._netUnitPrice) * (1 + this.vatRate / 100));
  }

  get grossUnitPrice(): string {
    return this._grossUnitPrice;
  }

  set grossUnitPrice(value: string) {
    this._grossUnitPrice = value;
    this._netUnitPrice = '' + (this.getNumber(this._grossUnitPrice) / (1 + this.vatRate / 100));
  }

  get netLinePrice(): string {
    return '' + (this.getNumber(this._netUnitPrice) * this.getNumber(this.amount));
  }

  get grossLinePrice(): string {
    return '' + (this.getNumber(this._grossUnitPrice) * this.getNumber(this.amount));
  }

  private getNumber(n: string) {
    const v = Models.parseDecimal(n);
    return v ? v.toNumber() : 0;
  }

  loadStockItem(stockItem: StockItemMultiselectOptionItem) {
    this.recordName = stockItem.itemName;
    this.hunVtszNumber = Strings.optToString(stockItem.hun_vtsz_number);
    this.amount = '1';
    this.unitType = stockItem.unit;
    this.vatRate = stockItem.unit_price.vat_rate;
    this.zeroVatRateType = Strings.optToString(stockItem.unit_price.zero_vat_rate_type);
    this.netUnitPrice = '' + stockItem.unit_price.value;
    this.comment = '';
    this._ledgerNumber = stockItem.ledgerNumber ? [LedgerNumberMultiselectProvider.toPublic(stockItem.ledgerNumber)] : []
  }
}
