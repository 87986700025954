/* eslint-disable */
import { DqlModel } from '../dql/dql.model';
import { Map } from 'immutable';
import { Shipment, ShipmentGroup } from './shipment-group.service';

/* eslint-enable */

export class ShipmentGroupDqlFieldTextProvider {
  public static getHelper(): DqlModel.DqlStaticFieldTextHelper {
    const fields: Map<string, DqlModel.DqlFieldTexts> = Map.of(
      'name', {stringKey: 'COMMON_NAME'},
      'id', {stringKey: 'COMMON_TABLE_HEADER_ID'},
      'creation_time', {stringKey: 'COMMON_TABLE_HEADER_CREATION_TIME'},
      'update_time', {stringKey: 'COMMON_TABLE_HEADER_UPDATE_TIME'},
      'group_state', {
        stringKey: 'SHIPMENT_GROUP_STATE',
        enumType: ShipmentGroup.shipmentGroupStates.map((value: ShipmentGroup.ShipmentGroupStateObject) => {
          return {id: <string>value.state, stringKey: value.stringKey};
        })
      },
      'shipment_state', {
        stringKey: 'SHIPMENT_STATE',
        enumType: Shipment.shipmentStates.map((value: Shipment.ShipmentStateObject) => {
          return {id: <string>value.state, stringKey: value.stringKey};
        })
      },
      'demander_company_id', {stringKey: 'SHIPPING_DEMAND_SHIPMENT_DEMANDER_COMPANY'},
      'main_transporter_company_id', {stringKey: 'SHIPMENT_GROUP_MAIN_TRANSPORTER_COMPANY'},
      'sub_transporter_company_id', {stringKey: 'SHIPMENT_GROUP_SUB_TRANSPORTER_COMPANY'},
      'demander_company_id', {stringKey: 'SHIPPING_DEMAND_SHIPMENT_DEMANDER_COMPANY'},
      'delivery_note_number', {stringKey: 'SHIPMENT_DELIVERY_NOTE_NUMBER'},
      'transport_waybill_number', {stringKey: 'TRANSPORT_WAYBILL_NUMBER'},
      'source_name', {stringKey: 'SHIPPING_DEMAND_SOURCE_NAME'},
      'destination_name', {stringKey: 'SHIPPING_DEMAND_DESTINATION_NAME'},
      'source_location_id', {stringKey: 'SHIPPING_DEMAND_SOURCE_LOCATION'},
      'destination_location_id', {stringKey: 'SHIPPING_DEMAND_DESTINATION_LOCATION'},
      'source_postal_address', {stringKey: 'SHIPPING_DEMAND_SEARCH_SOURCE_ADDRESS'},
      'destination_postal_address', {stringKey: 'SHIPPING_DEMAND_SEARCH_DESTINATION_ADDRESS'},
      'outtake_storekeeper_id', {stringKey: 'SHIPMENT_OUTTAKE_STOREKEEPER_SEARCH'},
      'outtake_state', {
        stringKey: 'SHIPMENT_OUTTAKE_STATE_SEARCH',
        enumType: Shipment.shipmentStates.map((value: Shipment.ShipmentStateObject) => {
          return {id: <string>value.state, stringKey: value.stringKey};
        })
      },
    );
    return new DqlModel.DqlStaticFieldTextHelper(fields);
  }
}
