/* eslint-disable */
import { Injectable } from '@angular/core';
import { OrderField } from '../query/orderfields';
import { MultiselectOptionItem, OrderFieldModel, OrderFunctionResolver } from '../../util/core-utils';
import { NewBaseSearch, NewBaseSearchService } from '../new-base.search-service';
import { OrderType } from '../util/services';
import { SearchUtils } from '../../util/search-utils';
import { LoggedInUserStorage } from '../util/storages';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

/* eslint-enable */

@Injectable()
export class GlobalCalendarEventSearchService extends NewBaseSearchService<OrderField.GlobalCalendarEvent> {

  protected createStorage(): GlobalCalendarEventSearch.Storage {
    return new GlobalCalendarEventSearch.Storage();
  }

}

export namespace GlobalCalendarEventSearch {

  export class Model extends SearchUtils.SearchModel {

    showSearch: boolean = false;

    id?: number;
    title: string;

    users: MultiselectOptionItem<number>[] = [];
    startTimeFrom: NgbDateStruct | null;
    startTimeTo: NgbDateStruct | null;
    color?: number;

    constructor() {
      super();
      this.reset();
    }

    reset() {
      this.id = undefined;
      this.title = '';
      this.startTimeFrom = null;
      this.startTimeTo = null;
      this.color = undefined;
      this.users = [];
    }

    isEmpty(): boolean {
      return this.id === undefined &&
        this.title === '' &&
        this.startTimeFrom === null &&
        this.startTimeTo === null &&
        this.users.length === 0 &&
        this.color === undefined;
    }

  }

  export namespace OrderFunctions {
    export const START_TIME = (f: OrderField.GlobalCalendarEvent) => f.startTime;

    export const VALUES = OrderFunctionResolver.builder<OrderField.GlobalCalendarEvent>()
      .add(START_TIME, 'start_time')
      .build();
  }

  export interface SearchDataResult {
    searchData: SearchData;
  }

  export interface SearchData extends NewBaseSearch.SearchData<OrderField.GlobalCalendarEvent> {
    searchModel: Model;
  }

  interface StoredSearchRawData extends NewBaseSearch.StoredSearchRawData<OrderField.GlobalCalendarEvent> {
    id?: number;
    title: string;
    color?: number;
    startTimeFrom: NgbDateStruct | null;
    startTimeTo: NgbDateStruct | null;
    users: MultiselectOptionItem<number>[];
  }

  export class Storage extends NewBaseSearch.Storage<OrderField.GlobalCalendarEvent> {

    constructor() {
      const defaultSearchData: SearchData = {
        queryModel: new OrderFieldModel(OrderFunctions.START_TIME, OrderType.ASC),
        searchModel: new Model(),
      };
      /**
       * Increment this version if you change the SearchData interface.
       */
      const versionNumber: number = 1;
      super(defaultSearchData,
        versionNumber);
    }

    protected generateKey(): string {
      return 'UserId(' + LoggedInUserStorage.getInstance().getUserId() + ')-Search-GlobalCalendarEvent';
    }

    protected fromRaw(data: StoredSearchRawData): NewBaseSearch.SearchDataResult<OrderField.GlobalCalendarEvent> {
      const searchModel = new Model();
      searchModel.id = data.id;
      searchModel.title = data.title;
      searchModel.color = data.color;
      searchModel.startTimeFrom = data.startTimeFrom;
      searchModel.startTimeTo = data.startTimeTo;
      searchModel.users = data.users;
      const searchData: SearchData = {
        queryModel: OrderFieldModel.create<OrderField.GlobalCalendarEvent>(OrderFunctions.VALUES, data.order, data.queryModel),
        searchModel: searchModel,
      };
      return {
        searchData: searchData
      };
    }

    protected toRaw(data: SearchData): StoredSearchRawData {
      return {
        version: this.VERSION,
        queryModel: data.queryModel,
        order: data.queryModel.createOrder(OrderFunctions.VALUES),
        id: data.searchModel.id,
        title: data.searchModel.title,
        startTimeFrom: data.searchModel.startTimeFrom,
        startTimeTo: data.searchModel.startTimeTo,
        color: data.searchModel.color,
        users: data.searchModel.users
      };
    }

  }

}
