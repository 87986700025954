/* eslint-disable */
import { EmptyMessage, IdentityMessage } from '../util/messages';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AddressResource } from '../address';
import { ChatResource } from '../chat/chat.utils';
import { TransportLogResource } from './transport-log/transport-log-resource.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  BaseHttpService,
  EchoHeader,
  EchoHeaderBuilder,
  HeaderKeys,
  HeaderValues,
  ResourceHelper
} from '../util/http-services';
import { ResourceQueryResult } from '../util/services';
import { TriggerInstanceResource } from '../trigger/trigger-instance-resource.service';
import { ExteriorTransport } from '../exterior-transport/exterior-transport.service';
import { DqlStoredQueryResourceService } from '../dql/dql-stored-query-resource.service';
import { GeneralDqlStoredQueryResourceService } from '../dql/general-dql-stored-query-resource.service';
import { DqlResourceModel } from '../dql/dql-resource.model';
import { Cacheable } from 'ts-cacheable';
import { UiConstants } from '../../util/core-utils';
import TriggerInstanceResourceService = TriggerInstanceResource.TriggerInstanceResourceService;

/* eslint-enable */

@Injectable()
export class TransportResourceService extends BaseHttpService implements TriggerInstanceResourceService {

  private readonly _dqlStoredResourceService: DqlStoredQueryResourceService;

  query(request: TransportResource.QueryRequest): Observable<ResourceQueryResult<TransportResource.Transport>> {
    return this.http.get<ResourceQueryResult<TransportResource.Transport>>(this.url, this.parseParams(request));
  }

  get(request: TransportResource.GetRequest): Observable<TransportResource.Transport> {
    return this.http.get<TransportResource.Transport>(this.url + `${request.id}`, this.parseParams(request));
  }

  delete(request: TransportResource.GetRequest): Observable<EmptyMessage> {
    return this.http.delete<EmptyMessage>(this.url + `${request.id}`);
  }

  messageQuery(request: TransportResource.MessageQueryRequest): Observable<ResourceQueryResult<ChatResource.Message>> {
    return this.http.get<ResourceQueryResult<ChatResource.Message>>(
      this.url + `${request.transport_id}/chat`, this.parseParams(request)
    );
  }

  sendMessage(request: TransportResource.SendMessageRequest): Observable<IdentityMessage> {
    return this.http.post<IdentityMessage>(
      this.url + `${request.transport_id}/chat`, request);
  }

  createByDemands(request: TransportResource.CreateByDemandsRequest): Observable<TransportResource.CreateResponse> {
    return this.http.post<TransportResource.CreateResponse>(this.url + 'create-by-demands', request);
  }

  createByShipments(request: TransportResource.CreateByShipmentsRequest): Observable<TransportResource.CreateResponse> {
    return this.http.post<TransportResource.CreateResponse>(this.url + 'create-by-shipments', request);
  }

  createEmpty(request: TransportResource.CreateEmptyRequest): Observable<TransportResource.CreateResponse> {
    return this.http.post<TransportResource.CreateResponse>(this.url + 'create-empty', request);
  }

  update(request: TransportResource.UpdateRequest): Observable<EmptyMessage> {
    return this.http.put(this.url + `${request.transport_id}`, request);
  }

  addDemands(request: TransportResource.AddDemandsRequest): Observable<EmptyMessage> {
    return this.http.post(this.url + `${request.id}/demands/add`, request);
  }

  addShipments(request: TransportResource.AddShipmentsRequest): Observable<EmptyMessage> {
    return this.http.post(this.url + `${request.id}/shipments/add`, request);
  }

  removeShipment(request: TransportResource.RemoveShipmentRequest): Observable<EmptyMessage> {
    return this.http.post(this.url + `${request.transport_id}/shipments/remove/${request.shipment_id}`, null);
  }

  open(request: TransportResource.GetRequest): Observable<EmptyMessage> {
    return this.http.post(this.url + `${request.id}/open`, null);
  }

  plan(request: TransportResource.GetRequest): Observable<EmptyMessage> {
    return this.http.post(this.url + `${request.id}/plan`, null);
  }

  replan(request: TransportResource.GetRequest): Observable<EmptyMessage> {
    const echoHeader: EchoHeader = new EchoHeaderBuilder().ignoreGlobalErrors(true).build();
    const headers = new HttpHeaders().set(HeaderKeys.ECHO, HeaderValues.serializeEchoHeader(echoHeader));
    return this.http.post(this.url + `${request.id}/replan`, null, {headers: headers});
  }

  continue(request: TransportResource.GetRequest): Observable<EmptyMessage> {
    return this.http.post(this.url + `${request.id}/continue`, null);
  }

  close(request: TransportResource.GetRequest): Observable<EmptyMessage> {
    return this.http.post(this.url + `${request.id}/close`, null);
  }

  getRoute(request: TransportResource.GetRequest): Observable<TransportResource.Directions> {
    return this.http.get<TransportResource.Directions>(this.url + `${request.id}/route`);
  }

  getPositionLogs(request: TransportResource.GetRequest): Observable<TransportResource.PositionLog[]> {
    return this.http.get<TransportResource.PositionLog[]>(
      this.url + `${request.id}/position-logs`, this.parseParams(request)
    );
  }

  getRunningPositionLogs(request: EmptyMessage): Observable<TransportResource.PositionLogExt[]> {
    return this.http.get<TransportResource.PositionLogExt[]>(
      this.url + `position-logs/running`, this.parseParams(request)
    );
  }

  setSecurityGuard(request: TransportResource.SecurityGuardRequest): Observable<EmptyMessage> {
    return this.http.put(this.url + `${request.id}/security-guard`, request);
  }

  setDriver(request: TransportResource.DriverRequest): Observable<EmptyMessage> {
    return this.http.put(this.url + `${request.id}/driver`, request);
  }

  setVehicle(request: TransportResource.VehicleRequest): Observable<EmptyMessage> {
    return this.http.put(this.url + `${request.id}/vehicle`, request);
  }

  getTriggerInstanceHistory(request: TriggerInstanceResource.TriggerInstanceHistoryRequest):
    Observable<ResourceQueryResult<TriggerInstanceResource.TriggerInstance>> {
    return this.http.get<ResourceQueryResult<TriggerInstanceResource.TriggerInstance>>(
      this.url + `${request.instance_parent_id}/triggers`
    );
  }

  @Cacheable({
    maxAge: UiConstants.defaultCacheMaxAge,
    slidingExpiration: true,
  })
  getDqlModel(): Observable<DqlResourceModel.QueryableModel> {
    return this.http.get<DqlResourceModel.QueryableModel>(this.url + `dql-model`);
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/transports/');
    this._dqlStoredResourceService = new GeneralDqlStoredQueryResourceService(http, resourceHelper, 'TRANSPORT');
  }

  get dqlStoredResourceService(): DqlStoredQueryResourceService {
    return this._dqlStoredResourceService;
  }
}


export namespace TransportResource {

  import Company = ExteriorTransport.Company;

  export interface Transport {
    id: number;
    creation_time: string;
    update_time: string;
    external_id: string;
    state: string;
    transporter_company: Company;
    waybill_number: string;
    comment?: string;
    dispatcher: Dispatcher;
    vehicle?: Vehicle;
    driver?: Driver;
    open_time?: string;
    start_time?: string;
    close_time?: string;
    safety_shipping: boolean;
    safety_shipping_user_settable: boolean;
    security_guard?: SecurityGuard;
    delay_minutes: number;
    shipping_demand_count: number;
    chat_message_count?: number;
    attachment_count?: number;
    shipping_demands?: ShippingDemand[];
    shipments?: Shipment[];
  }

  export interface QueryRequest {
    id?: string;
    external_id?: string;
    waybill_number?: string;
    vehicle_id?: string;
    driver_id?: string;
    company_id?: string;
    safety_shipping?: boolean;
    state?: string;
    close_time_from?: string;
    close_time_to?: string;
    creation_time_from?: string;
    creation_time_to?: string;
    start_time_from?: string;
    start_time_to?: string;
    open_time_from?: string;
    open_time_to?: string;
    shipping_item?: string;
    with_message_count?: boolean;
    with_shipping_demands?: boolean;
    with_shipments?: boolean;
    q?: string;
    dql?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
    no_progress_bar?: boolean;
  }

  export interface GetRequest {
    id: number;
    with_shipping_demand_count?: boolean;
  }

  export interface CreateRequest {
    vehicle_id?: number;
    driver_id?: number;
    safety_shipping: boolean;
    comment?: string;
  }

  export interface CreateByDemandsRequest extends CreateRequest {
    demand_ids: number[];
  }

  export interface CreateByShipmentsRequest extends CreateRequest {
    shipment_ids: number[];
  }

  export interface CreateEmptyRequest {
    transporter_company_id: number;
    vehicle_id?: number;
    driver_id?: number;
    safety_shipping: boolean;
  }

  export interface UpdateRequest {
    transport_id: number;
    transporter_company_id: number;
    external_id: string;
    vehicle_id?: number;
    driver_id?: number;
    safety_shipping: boolean;
    comment?: string;
  }

  export interface CreateResponse {
    transport_id: number;
  }

  export interface Dispatcher {
    id: number;
  }

  export interface Vehicle {
    id: number;
    licence_plate: string;
  }

  export interface Driver {
    id: number;
    person_name: string;
  }

  export interface SecurityGuard {
    person_name: string;
  }

  export interface ShippingDemand {
    id: number;
    demand_serial: string;
  }

  export interface Shipment {
    id: number;
    group_id: number;
    delivery_note_number: string;
  }

  export interface SecurityGuardRequest extends SecurityGuard, IdentityMessage {
  }

  export interface DriverRequest extends IdentityMessage {
    driver_id: number;
  }

  export interface VehicleRequest extends IdentityMessage {
    vehicle_id: number;
  }

  export interface MessageQueryRequest extends ChatResource.MessageQueryRequest {
    transport_id: number;
  }


  export interface SendMessageRequest extends ChatResource.SendMessageRequest {
    transport_id: number;
  }

  export interface Directions {
    polylines: string[];
    sum_distance_meter: number;
  }

  export interface AddDemandsRequest extends IdentityMessage {
    demand_ids: number[];
  }

  export interface AddShipmentsRequest extends IdentityMessage {
    shipment_ids: number[];
  }

  export interface RemoveShipmentRequest {
    transport_id: number;
    shipment_id: number;
  }

  export interface PositionLog {
    coordinate: AddressResource.CoordinateResource;
    creation_time: string;
  }

  export interface PositionLogExt {
    position_log: PositionLog;
    driver: Driver;
    transport: TransportData;
    to_task?: TransportLogResource.TransportTaskData;
    current_task?: TransportLogResource.TransportTaskData;
  }

  export interface TransportData {
    id: number;
    waybill_number: string;
    vehicle_licence_plate: string;
    delay_minutes: number;
  }

}
