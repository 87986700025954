<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-custom-padding animated fadeIn">
  <div class="row ml-0 mr-0">
    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12 card-custom-padding" *ngFor="let survey of surveyList">
      <div class="card card-top-color cursor-pointer"
           uiSref="Admin.SurveyRecordList"
           [uiParams]="{ id: survey.id }">
        <div>
          <div class="thumbnail-background" *ngIf="survey.thumbnailLoaded" [style.background-image]="survey.thumbnailSrc"></div>
          <div class="thumbnail-background-placeholder" *ngIf="!survey.thumbnailLoaded"></div>
        </div>
        <div class="card-body">
          <div class="h4 mb-0">{{getSurveyCount(survey.id)}}</div>
          <small class="text-uppercase font-weight-bold">{{survey.name}}</small>
          <br>
          <small class="text-muted">{{survey.id}}</small>
        </div>
      </div>
      <div class="container-vertical-padding-half"></div>
    </div>
  </div>
</div>
