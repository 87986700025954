<div class="card">
  <div class="card-header">
    {{'ANDROID_VERSION_SETTINGS' | translate}}
  </div>
  <form (ngSubmit)="submit()" #f="ngForm"
        [formGroup]="formGroup" novalidate>
    <div class="card-body">
      <div class="form-group row">
        <label
          class="col-form-label form-control-label col-md-4 detail-title">{{'ANDROID_VERSION_SETTINGS_AUTO_UPDATE' | translate}}</label>
        <div class="col-md-6">
          <div class="mw-100">
            <label class="switch switch-text switch-info mb-0">
              <input type="checkbox" class="switch-input" [(ngModel)]="model.autoUpdateEnabled"
                     formControlName="autoUpdateEnabled"
                     id="autoUpdateEnabled" name="autoUpdateEnabled">
              <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
              <span class="switch-handle"></span>
            </label>
          </div>
        </div>
      </div>

      <div class="form-group row" [ngClass]="{ 'has-danger': hasLocalFieldError('minimumVersion')}">
        <label
          class="col-form-label form-control-label col-md-4 detail-title">{{'ANDROID_VERSION_SETTING_MINIMUM_VERSION' | translate}}</label>
        <div class="col-md-6">
          <div class="mw-100">
            <angular2-multiselect
              [(ngModel)]="model.minimumVersion"
              id="minimumVersion"
              formControlName="minimumVersion"
              [ngClass]="{ 'form-control-danger': hasLocalFieldError('minimumVersion') }"
              [data]="versions"
              [readonlyBorderEnabled]=""
              (onRemoteSearch)="onVersionSearch($event.target.value)"
              [settings]="dropdownSettings">
            </angular2-multiselect>
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('minimumVersion')">
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="model.minimumVersionId">
          <app-android-app-version-base
            [appVersion]="model.minVersionData!"
            [readonly]="true"
            [headerKey]="'ANDROID_VERSION_CURRENT_VERSION'"
            (versionChanged)="onVersionChanged($event)"
            (versionDeleted)="onVersionDeleted($event)"
            [innerCard]="true">
          </app-android-app-version-base>
      </div>
    </div>
    <div class="card-footer d-flex justify-content-end">
      <input type="submit" class="btn btn-primary" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
    </div>
  </form>
</div>
