/* eslint-disable */
import { AfterContentInit, Component, ContentChildren, QueryList } from '@angular/core';
import { DropdownItemComponent } from '../dropdown/dropdown-item/dropdown-item.component';
/* eslint-enable */

@Component({
  selector: 'app-table-options-menu',
  templateUrl: './table-options-menu.component.html',
  styleUrls: ['./table-options-menu.component.scss']
})
export class TableOptionsMenuComponent implements AfterContentInit {

  @ContentChildren(DropdownItemComponent)
  menuItems: QueryList<DropdownItemComponent>;

  viewInitialized: boolean = false;

  constructor() { }

  ngAfterContentInit() {
    this.viewInitialized = true;
  }

  anyChildVisible(): boolean {
    if (this.viewInitialized) {
      return this.menuItems.length > 0;
    }
    return false;
  }

}
