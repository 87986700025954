<div class="container-vertical-padding container-horizontal-padding">
  <div class="col-md-12 d-flex justify-content-between" *ngIf="isRemovable">
    <h6>{{'ORDER_STOCK_ITEM_MODIFY' | translate}}</h6>
    <input type="button"
           (click)="remove.emit(model.itemId)" class="btn btn-secondary"
           value="{{'ORDER_STOCK_ITEM_DELETE' | translate}}">
  </div>
  <form class="form-horizontal d-flex flex-wrap" (ngSubmit)="f.form.valid && amount.value > 0 && saveItem()" #f="ngForm" novalidate>
    <div class="col-md-8 col-sm-12 col-xs-12 form-group"
         [ngClass]="{ 'has-danger': (f.submitted && !name.valid) }">
      <label class="form-control-label">{{'ORDER_STOCK_ITEM_NAME_OR_PRODUCT_CODE' | translate}}</label>
      <angular2-multiselect [data]="stockItemsForDropdown" [settings]="dropdownSettings"
                            class="form-control"
                            (onRemoteSearch)="onSearch($event)"
                            [ngClass]="{ 'form-control-danger': (f.submitted && !name.valid) }"
                            [(ngModel)]="selectedStockItem" (ngModelChange)="loadSelectedStockItem()" #name="ngModel" name="name" required>
      </angular2-multiselect>
      <div class="form-control-feedback" *ngIf="f.submitted && !name.valid">
        {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
      </div>
    </div>
    <div class="col-md-4 col-sm-12 col-xs-12 form-group"
         [ngClass]="{ 'has-danger': (f.submitted && !amount.valid) || (f.submitted && amount.value <= 0) || hasError('amount')}">
      <label class="form-control-label">{{'ORDER_STOCK_ITEM_AMOUNT' | translate}}</label>
      <div class="row">
        <div class="col-md-7">
          <input class="form-control" type="number" placeholder="{{'ORDER_STOCK_ITEM_AMOUNT' | translate}}"
                 [ngClass]="{ 'form-control-danger': (f.submitted && !amount.valid) || hasError('amount')}"
                 (ngModelChange) = "removeError('amount')"
                 [(ngModel)]="m.amount" #amount="ngModel" name="amount" required>
        </div>
        <!-- /.col-md-7 -->
        <div class="col-md-5 d-flex"><span class="align-self-center">{{m.unit}}</span></div>
      </div>
      <!-- /.row -->
      <div class="form-control-feedback" *ngIf="f.submitted && !amount.valid">
        {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
      </div>
      <div class="form-control-feedback" *ngIf="f.submitted && amount.value <= 0">
        {{'COMMON_VALIDATION_MESSAGE_NUMBER_NOT_POSITIVE' | translate}}
      </div>
      <div class="form-control-feedback" *ngIf="hasError('amount')">
        {{getError('amount')}}
      </div>

    </div>
    <div class="col-md-12">
      <div class="pull-right">
        <input type="button" class="btn btn-secondary-green cursor-pointer" (click)="cancel.emit()"
               value="{{'COMMON_BUTTON_CANCEL'|translate}}"/>
        <input type="submit" class="btn btn-primary-green cursor-pointer" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
      </div>
    </div>
  </form>
</div>
