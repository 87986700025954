/* eslint-disable */
/* eslint-enable */

import { AppNgbTimeStruct } from '../../util/ngb-datepicker';
import { Injectable } from '@angular/core';

/**
 * Abstract type serving as a DI token for the service parsing and formatting times for the NgbInputTimepicker
 * directive. A default implementation using the ISO 8601 format is provided, but you can provide another implementation
 * to use an alternative format.
 */
export abstract class NgbTimeParserFormatter {

  /**
   * Parses the given value to an AppNgbTimeStruct. Implementations should try their best to provide a result, even
   * partial. They must return null if the value can't be parsed.
   * @param value the value to parse
   * @param withSeconds if false, the second must be zero
   */
  abstract parse(value: string, withSeconds: boolean): AppNgbTimeStruct | null;
  /**
   * Formats the given time to a string. Implementations should return an empty string if the given date is null,
   * and try their best to provide a partial result if the given date is incomplete or invalid.
   * @param time the time to format as a string
   * @param withSeconds if false, second part is not in the string
   */
  abstract format(time: AppNgbTimeStruct | null, withSeconds: boolean): string;

}

/**
 * 24h formatter with border validation.
 */
@Injectable()
export class DefaultNgbTimeParserFormatter extends NgbTimeParserFormatter {

  format(time: AppNgbTimeStruct | null, withSeconds: boolean): string {
    if (!time) {
      return '';
    }
    return this.toTwoDigitString(time.hour) +
      ':' + this.toTwoDigitString(time.minute) +
      (withSeconds ? (':' + this.toTwoDigitString(time.second)) : '');
  }

  parse(value: string, withSeconds: boolean): AppNgbTimeStruct | null {
    if (!value) {
      return null;
    }
    const s: string[] = value.split(':');
    const numbers = s
      .map((txt) => this.toNumber(txt))
      .filter((n) => n !== null);
    if (numbers.length === 0) {
      return {
        hour: 0,
        minute: 0,
        second: 0,
        millis: 0
      };
    }
    let hour = numbers[0]!;
    if (hour > 24) {
      hour = 24;
    }
    if (hour < 0) {
      hour = 0;
    }
    if (numbers.length === 1) {
      return {
        hour: hour,
        minute: 0,
        second: 0,
        millis: 0
      };
    }
    let minute = numbers[1]!;
    if (minute > 59) {
      minute = 59;
    }
    if (minute < 0) {
      minute = 0;
    }
    if (numbers.length === 2 || !withSeconds) {
      return {
        hour: hour,
        minute: minute,
        second: 0,
        millis: 0
      };
    }
    let second = numbers[2]!;
    if (second > 59) {
      second = 59;
    }
    if (second < 0) {
      second = 0;
    }
    if (numbers.length === 3) {
      return {
        hour: hour,
        minute: minute,
        second: second,
        millis: 0
      };
    }
    return null;
  }

  private toNumber(part: string): number | null {
    part = part.replace(/\D+/g, '');
    while (part.startsWith('0')) {
      part = part.substring(1);
    }
    try {
      return Number(part);
    }
    catch (e) {
      return null;
    }
  }

  private toTwoDigitString(number: number): string {
    const txt = number + '';
    if (txt.length >= 2) {
      return txt;
    }
    return '0' + txt;
  }

}

export const TIMEPICKER_FORMATTER_PROVIDER = {
  provide: NgbTimeParserFormatter, useClass: DefaultNgbTimeParserFormatter
};
