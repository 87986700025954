export type RightDefinition = string;

export namespace OperationRights {

  // Task record
  export const TASK_RECORD_READ: RightDefinition = 'TaskRecord:read';
  export const TASK_RECORD_UPDATE: RightDefinition = 'TaskRecord:update';
  export const TASK_RECORD_CHANGE_DEADLINE: RightDefinition = 'TaskRecord:change:deadline';
  export const TASK_RECORD_CHANGE_AGREED_TIME: RightDefinition = 'TaskRecord:change:agreed_time';
  export const TASK_RECORD_CHANGE_ESTIMATED_TIME: RightDefinition = 'TaskRecord:change:estimated_time';
  export const TASK_RECORD_CHANGE_TIME_SPENT: RightDefinition = 'TaskRecord:change:time_spent';
  export const TASK_RECORD_CHANGE_ASSIGNEE: RightDefinition = 'TaskRecord:change:assignee';
  export const TASK_RECORD_CHANGE_ASSIGNEE_IN_PROGRESS: RightDefinition = 'TaskRecord:change:in_progress:assignee';
  export const TASK_RECORD_CHANGE_IMPORTANCE: RightDefinition = 'TaskRecord:change:importance';
  export const TASK_RECORD_DOWNLOAD_SIGNATURES: RightDefinition = 'TaskRecord:download_signatures';
  export const TASK_RECORD_UPLOAD_SIGNATURES: RightDefinition = 'TaskRecord:upload_signatures';
  export const TASK_RECORD_ATTACHMENT_READ: RightDefinition = 'TaskRecord:attachment:read';
  export const TASK_RECORD_ATTACHMENT_UPLOAD_FILE: RightDefinition = 'TaskRecord:attachment:upload';
  export const TASK_RECORD_ATTACHMENT_DELETE_FILE: RightDefinition = 'TaskRecord:attachment:delete';
  export const TASK_RECORD_ATTACHMENT_UPDATE_DATA: RightDefinition = 'TaskRecord:attachment:update';
  export const TASK_RECORD_LOG_READ: RightDefinition = 'TaskRecord:Log:read';
  export const TASK_RECORD_DOCUMENT_READ: RightDefinition = 'TaskRecord:Document:read';
  export const TASK_RECORD_CHAT_READ: RightDefinition = 'TaskRecord:Chat:read';
  export const TASK_RECORD_CHAT_MESSAGE_CREATE: RightDefinition = 'TaskRecord:Chat:Message:create';
  export const TASK_RECORD_CHANGE_STATE_ARCHIVE: RightDefinition = 'TaskRecord:change:state:archive';
  export const TASK_RECORD_CHANGE_STATE_RECALL: RightDefinition = 'TaskRecord:change:state:recall';
  export const TASK_RECORD_CHANGE_STATE_OPEN: RightDefinition = 'TaskRecord:change:state:open';
  export const TASK_RECORD_CHANGE_STATE_RESTART: RightDefinition = 'TaskRecord:change:state:restart';
  export const TASK_RECORD_CHANGE_STATE_REJECT: RightDefinition = 'TaskRecord:change:state:reject';
  export const TASK_RECORD_CHANGE_STATE_START: RightDefinition = 'TaskRecord:change:state:start';
  export const TASK_RECORD_CHANGE_STATE_REOPEN: RightDefinition = 'TaskRecord:change:state:reopen';
  export const TASK_RECORD_CHANGE_STATE_PAUSE: RightDefinition = 'TaskRecord:change:state:pause';
  export const TASK_RECORD_CHANGE_STATE_SUBMIT: RightDefinition = 'TaskRecord:change:state:submit';
  export const TASK_RECORD_CHANGE_STATE_UNSUBMIT: RightDefinition = 'TaskRecord:change:state:unsubmit';
  export const TASK_RECORD_CHANGE_STATE_VALIDATE: RightDefinition = 'TaskRecord:change:state:validate';
  export const TASK_RECORD_CHANGE_STATE_REVERT: RightDefinition = 'TaskRecord:change:state:revert';
  export const TASK_RECORD_ASSIGN_TO_ME: RightDefinition = 'TaskRecord:assign_to_me';
  export const TASK_RECORD_MANUAL_INVOICE_CREATE: RightDefinition = 'TaskRecord:ManualInvoice:create';

  // Subtask
  export const TASK_RECORD_SUB_TASK_CREATE: RightDefinition = 'TaskRecord:SubTask:create';
  export const TASK_RECORD_SUB_TASK_READ: RightDefinition = 'TaskRecord:SubTask:read';
  export const TASK_RECORD_SUB_TASK_UPDATE: RightDefinition = 'TaskRecord:SubTask:update';
  export const TASK_RECORD_SUB_TASK_DELETE: RightDefinition = 'TaskRecord:SubTask:delete';
  export const TASK_RECORD_SUB_TASK_START: RightDefinition = 'TaskRecord:SubTask:start';
  export const TASK_RECORD_SUB_TASK_FINISH: RightDefinition = 'TaskRecord:SubTask:finish';

  export const TASK_UPDATE: RightDefinition = 'Task:update';
  export const TASK_DISABLE: RightDefinition = 'Task:disable';
  export const TASK_RECORD_CREATE: RightDefinition = 'TaskRecord:create';

  export const PROJECT_RECORD_CREATE: RightDefinition = 'ProjectRecord:create';
  export const PROJECT_RECORD_IMPORT: RightDefinition = 'ProjectRecord:import';
  export const PROJECT_RECORD_EXPORT: RightDefinition = 'ProjectRecord:export';
  export const PROJECT_RECORD_UPDATE: RightDefinition = 'ProjectRecord:update';
  export const PROJECT_RECORD_DISABLE: RightDefinition = 'ProjectRecord:disable';

  // ExteriorTransport
  export const EXTERIOR_TRANSPORT_CREATE: RightDefinition = 'ExteriorTransport:create';
  export const EXTERIOR_TRANSPORT_DELETE: RightDefinition = 'ExteriorTransport:delete';
  export const EXTERIOR_TRANSPORT_SHIPMENT_ADD: RightDefinition = 'ExteriorTransport:Shipment:add';
  export const EXTERIOR_TRANSPORT_SHIPMENT_REMOVE: RightDefinition = 'ExteriorTransport:Shipment:remove';
  export const EXTERIOR_TRANSPORT_READ: RightDefinition = 'ExteriorTransport:read';
  export const EXTERIOR_TRANSPORT_START: RightDefinition = 'ExteriorTransport:start';
  export const EXTERIOR_TRANSPORT_FINISH: RightDefinition = 'ExteriorTransport:finish';

  // ExteriorTransport -> Document
  export const EXTERIOR_TRANSPORT_DOCUMENT_READ: RightDefinition = 'ExteriorTransport:Document:read';
  export const EXTERIOR_TRANSPORT_DOCUMENT_CREATE: RightDefinition = 'ExteriorTransport:Document:create';
  export const EXTERIOR_TRANSPORT_DOCUMENT_DELETE: RightDefinition = 'ExteriorTransport:Document:delete';
  export const EXTERIOR_TRANSPORT_DOCUMENT_DOWNLOAD: RightDefinition = 'ExteriorTransport:Document:download';
  export const EXTERIOR_TRANSPORT_DOCUMENT_META_UPDATE: RightDefinition = 'ExteriorTransport:Document:Meta:update';

  // ShopRenterShop
  export const SHOPRENTER_SHOP_READ: RightDefinition = 'ShopRenter:Shop:read';
  export const SHOPRENTER_SHOP_UPDATE: RightDefinition = 'ShopRenter:Shop:update';

  // Workflow
  export const WORKFLOW_CREATE: RightDefinition = 'Workflow:create';
  export const WORKFLOW_READ: RightDefinition = 'Workflow:read';
  export const WORKFLOW_UPDATE: RightDefinition = 'Workflow:update';
  export const WORKFLOW_DELETE: RightDefinition = 'Workflow:delete';
  export const WORKFLOW_ACTIVATE: RightDefinition = 'Workflow:activate';
  export const WORKFLOW_FINALIZE: RightDefinition = 'Workflow:finalize';
  export const WORKFLOW_CREATE_NEW_VERSION: RightDefinition = 'Workflow:create_new_version';

  // Workflow
  export const PROCESS_CREATE: RightDefinition = 'Process:create';
  export const PROCESS_READ: RightDefinition = 'Process:read';
  export const PROCESS_UPDATE: RightDefinition = 'Process:update';

  // Masterdata Record
  export const MASTER_DATA_RECORD_READ: RightDefinition = 'MasterDataRecord:read';
  export const MASTER_DATA_RECORD_CREATE: RightDefinition = 'MasterDataRecord:create';
  export const MASTER_DATA_RECORD_UPDATE: RightDefinition = 'MasterDataRecord:update';
  export const MASTER_DATA_RECORD_DISABLE: RightDefinition = 'MasterDataRecord:disable';

  // Customer record
  export const CUSTOMER_RECORD_READ: RightDefinition = 'CustomerRecord:read';
  export const CUSTOMER_RECORD_CREATE: RightDefinition = 'CustomerRecord:create';
  export const CUSTOMER_RECORD_UPDATE: RightDefinition = 'CustomerRecord:update';
  export const CUSTOMER_RECORD_DISABLE: RightDefinition = 'CustomerRecord:disable';
  export const CUSTOMER_RECORD_ATTACHMENT_READ: RightDefinition = 'CustomerRecord:attachment:read';
  export const CUSTOMER_RECORD_ATTACHMENT_UPLOAD_FILE: RightDefinition = 'CustomerRecord:attachment:upload';
  export const CUSTOMER_RECORD_ATTACHMENT_DELETE_FILE: RightDefinition = 'CustomerRecord:attachment:delete';
  export const CUSTOMER_RECORD_ATTACHMENT_UPDATE_DATA: RightDefinition = 'CustomerRecord:attachment:update';
  export const CUSTOMER_UPDATE: RightDefinition = 'Customer:update';
  export const CUSTOMER_DISABLE: RightDefinition = 'Customer:disable';

  export const INVOICE_SPENDING_CREATE: RightDefinition = 'InvoiceSpending:create';
  export const INVOICE_SPENDING_READ: RightDefinition = 'InvoiceSpending:read';
  export const INVOICE_SPENDING_UPDATE: RightDefinition = 'InvoiceSpending:update';

  export const LEDGER_NUMBER_CREATE: RightDefinition = 'LedgerNumber:create';
  export const LEDGER_NUMBER_READ: RightDefinition = 'LedgerNumber:read';
  export const LEDGER_NUMBER_UPDATE: RightDefinition = 'LedgerNumber:update';
  export const LEDGER_NUMBER_DISABLE: RightDefinition = 'LedgerNumber:disable';

  export const INVOICE_TAG_CREATE: RightDefinition = 'InvoiceTag:create';
  export const INVOICE_TAG_READ: RightDefinition = 'InvoiceTag:read';
  export const INVOICE_TAG_UPDATE: RightDefinition = 'InvoiceTag:update';
  export const INVOICE_TAG_DISABLE: RightDefinition = 'InvoiceTag:disable';

  export const CONTRACT_NUMBER_READ: RightDefinition = 'ContractNumber:read';
  export const GLOBAL_CALENDAR_CREATE: RightDefinition = 'GlobalCalendar:create';
  export const GLOBAL_CALENDAR_READ: RightDefinition = 'GlobalCalendar:read';
  export const GLOBAL_CALENDAR_UPDATE: RightDefinition = 'GlobalCalendar:update';
  export const GLOBAL_CALENDAR_DELETE: RightDefinition = 'GlobalCalendar:delete';


  export const taskRecordStateChanges: RightDefinition[] = [
    TASK_RECORD_CHANGE_STATE_ARCHIVE,
    TASK_RECORD_CHANGE_STATE_RECALL,
    TASK_RECORD_CHANGE_STATE_OPEN,
    TASK_RECORD_CHANGE_STATE_RESTART,
    TASK_RECORD_CHANGE_STATE_REJECT,
    TASK_RECORD_CHANGE_STATE_START,
    TASK_RECORD_CHANGE_STATE_REOPEN,
    TASK_RECORD_CHANGE_STATE_PAUSE,
    TASK_RECORD_CHANGE_STATE_SUBMIT,
    TASK_RECORD_CHANGE_STATE_UNSUBMIT,
    TASK_RECORD_CHANGE_STATE_VALIDATE,
    TASK_RECORD_CHANGE_STATE_REVERT,
  ]
}
