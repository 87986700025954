/* eslint-disable */
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { TaskStatistics, TaskStatisticsService } from '../../../../lib/statistics/task-statistics/task-statistics.service';
import { QueryFieldModel, UiConstants, UserItem } from '../../../../util/core-utils';
import { OrderType, QueryResult, ResourceQueryResult } from '../../../../lib/util/services';
import { Strings } from '../../../../lib/util/strings';
import { BreadcrumbParent } from '../../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import {
  TaskStatisticsSearch,
  TaskStatisticsSearchService
} from '../../../../lib/statistics/task-statistics/task-statistics-search.service';
import { combineLatest, Observable } from 'rxjs';
import { StateName } from '../../../../app.state-names';
import { TranslateService } from '@ngx-translate/core';
import { User, UserService } from '../../../../lib/user.service';
import { Arrays } from '../../../../lib/util/arrays';
import { Task, TaskService } from '../../../../lib/task/task.service';
import { Icon } from '../../../../lib/task/icon.service';
import { Set } from 'immutable';
import { Logger, LoggerFactory } from '../../../../util/logger-factory';
import TaskStatistic = TaskStatistics.TaskStatistic;
/* eslint-enable */

@Component({
  selector: 'app-task-statistics-list',
  templateUrl: './task-statistics-list.component.html',
  styleUrls: ['./task-statistics-list.component.scss']
})
export class TaskStatisticsListComponent implements OnInit, AfterViewInit, OnDestroy {
  TaskStatistics = TaskStatistics;
  UiConstants = UiConstants;

  queryModel: QueryFieldModel<TaskStatistics.OrderField> = new QueryFieldModel(TaskStatistics.OrderField.TASK_ID, OrderType.DESC);
  taskStatistics: TaskStatisticItem[] = [];
  private userList: UserItem[] = [];
  private taskList: TaskItem[] = [];
  searchModel: TaskStatisticsSearchModel = new TaskStatisticsSearchModel();
  showSearch: boolean = false;
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  private logger: Logger;

  constructor(
    private taskStatisticsService: TaskStatisticsService,
    private taskStatisticsSearchService: TaskStatisticsSearchService,
    private translateService: TranslateService,
    private userService: UserService,
    private taskService: TaskService) {
    this.logger = LoggerFactory.createLogger('TaskStatisticsListComponent');
  }

  ngOnInit() {
    this.translateService.get('MENU_NAVBAR_TASK_STATISTICS').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.loadSearch(() => {
      this.showSearch = !this.searchModel.isEmpty();
      this.loadList();
    });
  }

  ngAfterViewInit() {
    this.loadUsers(() => {
      this.loadTasks(() => {
        this.loadList();
      });
    });
  }

  private loadSearch(completion: () => void) {
    const obs: Observable<SearchLoadResult> = combineLatest(
      this.taskStatisticsSearchService.getSearchData({}),
      (storedSearchData: TaskStatisticsSearch.SearchDataResult) => {
        const result: SearchLoadResult = {
          storedSearchData: storedSearchData
        };
        return result;
      }
    );
    obs.subscribe(
      (result: SearchLoadResult) => {
        this.postInitSearch(result.storedSearchData);
        completion();
      }
    );
  }

  private loadUsers(completion: () => void): void {
    this.userService.query({})
      .subscribe(
      (result: ResourceQueryResult<User>) => {

        this.userList = [];

        Arrays.iterateByIndex(result.items, (user) => {
          const item = new UserItem();
          item.id = user.id;
          item.text = user.person_name;
          this.userList.push(item);
        });
        completion();
      }
    );
  }

  private loadTasks(completion: () => void): void {
    this.taskService.query({})
      .subscribe(
      (tasks: QueryResult<Task.Task>) => {

        this.taskList = [];

        tasks.items.forEach( task => {
          if (task) {
            const item = new TaskItem();
            item.id = task.taskId;
            item.text = task.name;
            item.icon = task.icon;
            this.taskList.push(item);
          }
        });
        completion();
      }
    );
  }

  private postInitSearch(storedSearchData: TaskStatisticsSearch.SearchDataResult) {
    this.queryModel.itemsPerPage = storedSearchData.searchData.itemsPerPage;
    this.queryModel.currentPage = storedSearchData.searchData.pageNumber;
    this.queryModel.setOrder(storedSearchData.searchData.order);
    this.searchModel.taskName = storedSearchData.searchData.taskName;
    this.searchModel.userProfileName = storedSearchData.searchData.userProfileName;
  }

  private loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();

    this.taskStatisticsService.query({
      by: 'TASK',
      taskName: Strings.undefinedOrNonEmpty(this.searchModel.taskName),
      userProfileName: Strings.undefinedOrNonEmpty(this.searchModel.userProfileName),
      paging: {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      },
      order: Set.of(order)
    })
      .subscribe(
      (taskStatistics: QueryResult<TaskStatistic>) => {
        this.taskStatistics = taskStatistics.items.toArray();
        this.queryModel.currentPage = requestedPage;
        this.queryModel.totalNumberOfItems = taskStatistics.pagingResult.totalNumberOfItems;
        this.queryModel.currentNumberOfItems = taskStatistics.pagingResult.currentNumberOfItems;
      }
    );
  }

  onSearchClicked() {
    this.loadList();
  }

  onSearchReset() {
    this.taskStatisticsSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.showSearch = true;
          this.loadList(1);
        });
      }
    );
  }

  private saveSearch() {
    const request = {
      searchData: {
        itemsPerPage: this.queryModel.itemsPerPage,
        pageNumber: this.queryModel.currentPage,
        order: this.queryModel.getOrder(),
        taskName: this.searchModel.taskName,
        userProfileName: this.searchModel.userProfileName
      }
    };
    this.taskStatisticsSearchService.setSearchData(request).subscribe(
      (result) => {
        this.logger.debug('Search model has saved');
      },
      (error) => {
        this.logger.warn('Failed to save the search model');
      }
    );
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: TaskStatistics.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  private getUserName(user_id: number): string {
    const filtered_users = this.userList.filter(current_user => current_user.id === user_id);
    if (filtered_users === undefined || filtered_users.length === 0) {
      return ''
    }
    return filtered_users[0].text
  }

  private getTaskName(task_id: number): string {
    const filtered_tasks = this.taskList.filter(current_task => current_task.id === task_id);
    if (filtered_tasks === undefined || filtered_tasks.length === 0) {
      return ''
    }
    return filtered_tasks[0].text
  }

  private getTaskIcon(task_id: number): Icon.Icon | undefined {
    const filtered_tasks = this.taskList.filter(current_task => current_task.id === task_id);
    if (filtered_tasks === undefined || filtered_tasks.length === 0) {
      return undefined;
    }
    return filtered_tasks[0].icon;
  }


  ngOnDestroy() {
    this.saveSearch();
  }

}

interface TaskStatisticItem {
  id: string;
  taskId: number;
  userProfileId: number;
  createCount: number;
  startCount: number;
  recallCount: number;
  rejectCount: number;
  finishCount: number;
}

class TaskStatisticsSearchModel {
  taskName: string;
  userProfileName: string;



  public isEmpty(): boolean {
    return this.taskName.length === 0
      && this.userProfileName.length === 0
      ;
  }
}

interface SearchLoadResult {
  storedSearchData: TaskStatisticsSearch.SearchDataResult,
}

class TaskItem {
  id: number;
  text: string;
  icon?: Icon.Icon;
}
