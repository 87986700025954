<div bsModal [config]="UiConstants.modalConfig" #uploadDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"
     id="uploadDialog">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content" *ngIf="visible">
      <div class="modal-header">
        <h4 class="modal-title">{{titleKey | translate}}</h4>
        <button type="button" class="close" (click)="closeDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
      <app-file-upload
        [uploadPath]="uploadPath"
        [acceptedMimeType]="acceptedMimeType"
        (onResult)="onResult.emit($event)"
        (responseContent)="responseContent.emit($event)" #uploadComponent>
      </app-file-upload>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="closeDialog()">{{'COMMON_BUTTON_CLOSE' | translate}}</button>
      </div><!-- /.modal-footer -->
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
