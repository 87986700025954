/* eslint-disable */
import { Right, } from './lib/auth.service';
import { PermissionDefinitions } from './app.permission-definitions';
import { GrantedPermissionSet, RightResolver } from './lib/right.service';
import { OperationRights, RightDefinition } from './app.right-definitions';

/* eslint-enable */

export class Rights {

  public static readonly WEB_ADMIN_CUSTOMERS: Right = Right.of(PermissionDefinitions.WEB_ADMIN_CUSTOMERS);
  public static readonly WEB_ADMIN_EMAIL_ADDRESS_TYPES: Right = Right.of(PermissionDefinitions.WEB_ADMIN_EMAIL_ADDRESS_TYPES);
  public static readonly WEB_ADMIN_TASKS: Right = Right.of(PermissionDefinitions.WEB_ADMIN_TASKS);
  public static readonly WEB_ADMIN_PROJECTS: Right = Right.of(PermissionDefinitions.WEB_ADMIN_PROJECTS);
  public static readonly WEB_ADMIN_MASTERDATA: Right = Right.of(PermissionDefinitions.WEB_ADMIN_MASTERDATA);
  public static readonly WEB_ADMIN_SURVEYS: Right = Right.of(PermissionDefinitions.WEB_ADMIN_SURVEYS);
  public static readonly WEB_ADMIN_FORM_TABLES: Right = Right.of(PermissionDefinitions.WEB_ADMIN_FORM_TABLES);
  public static readonly WEB_ADMIN_LIST_ITEM_TYPES: Right = Right.of(PermissionDefinitions.WEB_ADMIN_LIST_ITEM_TYPES);
  public static readonly WEB_ADMIN_WORKFLOWS: Right = Right.of(PermissionDefinitions.WEB_ADMIN_WORKFLOWS);
  public static readonly WEB_ADMIN_LEGACY_WORKFLOWS: Right = Right.of(PermissionDefinitions.WEB_ADMIN_LEGACY_WORKFLOWS);
  public static readonly WEB_ADMIN_DOCUMENTS: Right = Right.of(PermissionDefinitions.WEB_ADMIN_DOCUMENTS);
  public static readonly WEB_ADMIN_COMPANIES: Right = Right.of(PermissionDefinitions.WEB_ADMIN_COMPANIES);
  public static readonly WEB_ADMIN_COMPANY_LOCATIONS: Right = Right.of(PermissionDefinitions.WEB_ADMIN_COMPANY_LOCATIONS);
  public static readonly WEB_ADMIN_COMPANY_STOCKS: Right = Right.of(PermissionDefinitions.WEB_ADMIN_COMPANY_STOCKS);
  public static readonly WEB_ADMIN_SHOP_RENTER_SHOPS: Right = Right.of(PermissionDefinitions.WEB_ADMIN_SHOP_RENTER_SHOPS);
  public static readonly WEB_ADMIN_APPLICATION_FILES: Right = Right.of(PermissionDefinitions.WEB_ADMIN_APPLICATION_FILES);
  public static readonly WEB_ADMIN_TABLE_DOCUMENTS: Right = Right.of(PermissionDefinitions.WEB_ADMIN_TABLE_DOCUMENTS);
  public static readonly WEB_ADMIN_TABLE_DOCUMENT_SCHEMAS: Right = Right.of(PermissionDefinitions.WEB_ADMIN_TABLE_DOCUMENT_SCHEMAS);
  public static readonly WEB_ADMIN_STOCK_ITEMS: Right = Right.of(PermissionDefinitions.WEB_ADMIN_STOCK_ITEMS);
  public static readonly WEB_ADMIN_STOCK_ITEM_CATEGORIES: Right = Right.of(PermissionDefinitions.WEB_ADMIN_STOCK_ITEM_CATEGORIES);
  public static readonly WEB_ADMIN_STOCK_CHANGES: Right = Right.of(PermissionDefinitions.WEB_ADMIN_STOCK_CHANGES);
  public static readonly WEB_ADMIN_LIST_ITEMS: Right = Right.of(PermissionDefinitions.WEB_ADMIN_LIST_ITEMS);
  public static readonly WEB_ADMIN_VEHICLES: Right = Right.of(PermissionDefinitions.WEB_ADMIN_VEHICLES);
  public static readonly WEB_ADMIN_POST_OFFICES: Right = Right.of(PermissionDefinitions.WEB_ADMIN_POST_OFFICES);
  public static readonly WEB_ADMIN_PARCEL_COLLECTION_POINTS: Right = Right.of(PermissionDefinitions.WEB_ADMIN_PARCEL_COLLECTION_POINTS);
  public static readonly WEB_ADMIN_DELIVERY_METHODS: Right = Right.of(PermissionDefinitions.WEB_ADMIN_DELIVERY_METHODS);
  public static readonly WEB_ADMIN_USERS: Right = Right.of(PermissionDefinitions.WEB_ADMIN_USERS);
  public static readonly WEB_ADMIN_USER_GROUPS: Right = Right.of(PermissionDefinitions.WEB_ADMIN_USER_GROUPS);
  public static readonly WEB_ADMIN_ROLES: Right = Right.of(PermissionDefinitions.WEB_ADMIN_ROLES);
  public static readonly WEB_ADMIN_EMAIL_TEMPLATES: Right = Right.of(PermissionDefinitions.WEB_ADMIN_EMAIL_TEMPLATES);
  public static readonly WEB_ADMIN_PDF_TEMPLATES: Right = Right.of(PermissionDefinitions.WEB_ADMIN_PDF_TEMPLATES);
  public static readonly WEB_ADMIN_MESSAGE_TEMPLATES: Right = Right.of(PermissionDefinitions.WEB_ADMIN_MESSAGE_TEMPLATES);
  public static readonly WEB_ADMIN_PRINTER_TEMPLATES: Right = Right.of(PermissionDefinitions.WEB_ADMIN_PRINTER_TEMPLATES);
  public static readonly WEB_ADMIN_TASK_RECORD_LOGS: Right = Right.of(PermissionDefinitions.WEB_ADMIN_TASK_RECORD_LOGS);
  public static readonly WEB_ADMIN_TASK_RECORD_STATISTICS: Right = Right.of(PermissionDefinitions.WEB_ADMIN_TASK_RECORD_STATISTICS);
  public static readonly WEB_ADMIN_WORKLOGS: Right = Right.of(PermissionDefinitions.WEB_ADMIN_WORKLOGS);
  public static readonly WEB_ADMIN_INVOICE_STATISTICS: Right = Right.of(PermissionDefinitions.WEB_ADMIN_INVOICE_STATISTICS);
  public static readonly WEB_ADMIN_ORDER_IMPORT_ERRORS: Right = Right.of(PermissionDefinitions.WEB_ADMIN_ORDER_IMPORT_ERRORS);
  public static readonly WEB_ADMIN_MILEAGE_RECORDS: Right = Right.of(PermissionDefinitions.WEB_ADMIN_MILEAGE_RECORDS);
  public static readonly WEB_ADMIN_AUDIT_LOGS: Right = Right.of(PermissionDefinitions.WEB_ADMIN_AUDIT_LOGS);
  public static readonly WEB_ADMIN_ENVIRONMENT_CONFIGURATION: Right = Right.of(PermissionDefinitions.WEB_ADMIN_ENVIRONMENT_CONFIGURATION);
  public static readonly WEB_ADMIN_REGISTRATION_SETTINGS: Right = Right.of(PermissionDefinitions.WEB_ADMIN_REGISTRATION_SETTINGS);
  public static readonly WEB_ADMIN_MOBILE_APPLICATIONS: Right = Right.of(PermissionDefinitions.WEB_ADMIN_MOBILE_APPLICATIONS);
  public static readonly WEB_ADMIN_TOKENS: Right = Right.of(PermissionDefinitions.WEB_ADMIN_TOKENS);
  public static readonly WEB_ADMIN_INVOICE_BOOK: Right = Right.of(PermissionDefinitions.WEB_ADMIN_INVOICE_BOOK);
  public static readonly WEB_ADMIN_INVOICE_PROFILES: Right = Right.of(PermissionDefinitions.WEB_ADMIN_INVOICE_PROFILES);
  public static readonly WEB_ADMIN_LEDGER_NUMBERS: Right = Right.of(PermissionDefinitions.WEB_ADMIN_LEDGER_NUMBERS);
  public static readonly WEB_ADMIN_INVOICE_TAGS: Right = Right.of(PermissionDefinitions.WEB_ADMIN_INVOICE_TAGS);
  public static readonly WEB_ADMIN_TRANSPORT_TRIGGERS: Right = Right.of(PermissionDefinitions.WEB_ADMIN_TRANSPORT_TRIGGERS);
  public static readonly WEB_MENU_ADMIN: Right = Right.of(PermissionDefinitions.WEB_MENU_ADMIN);
  public static readonly WEB_MENU_DASHBOARD: Right = Right.of(PermissionDefinitions.WEB_MENU_DASHBOARD);
  public static readonly WEB_MENU_TASK_RECORDS: Right = Right.of(PermissionDefinitions.WEB_MENU_TASK_RECORDS);
  public static readonly WEB_MENU_TASK_RECORD_BOARD: Right = Right.of(PermissionDefinitions.WEB_MENU_TASK_RECORD_BOARD);
  public static readonly WEB_MENU_CUSTOMER_RECORDS: Right = Right.of(PermissionDefinitions.WEB_MENU_CUSTOMER_RECORDS);
  public static readonly WEB_MENU_MASTERDATA_RECORDS: Right = Right.of(PermissionDefinitions.WEB_MENU_MASTERDATA_RECORDS);
  public static readonly WEB_MENU_SURVEY_RECORDS: Right = Right.of(PermissionDefinitions.WEB_MENU_SURVEY_RECORDS);
  public static readonly WEB_ADMIN_NOTIFICATIONS: Right = Right.of(PermissionDefinitions.WEB_ADMIN_NOTIFICATIONS);
  public static readonly WEB_MENU_INVOICES: Right = Right.of(PermissionDefinitions.WEB_MENU_INVOICES);
  public static readonly WEB_MENU_INVOICE_SPENDINGS: Right = Right.of(PermissionDefinitions.WEB_MENU_INVOICE_SPENDINGS);
  public static readonly WEB_MENU_CALENDAR: Right = Right.of(PermissionDefinitions.WEB_MENU_CALENDAR);
  public static readonly WEB_MENU_GLOBAL_CALENDAR: Right = Right.of(PermissionDefinitions.WEB_MENU_GLOBAL_CALENDAR);
  public static readonly WEB_MENU_PROJECTS: Right = Right.of(PermissionDefinitions.WEB_MENU_PROJECTS);
  public static readonly WEB_MENU_ORDERS: Right = Right.of(PermissionDefinitions.WEB_MENU_ORDERS);
  public static readonly WEB_MENU_LEGACY_PROCESSES: Right = Right.of(PermissionDefinitions.WEB_MENU_LEGACY_PROCESSES);
  public static readonly WEB_MENU_PROCESSES: Right = Right.of(PermissionDefinitions.WEB_MENU_PROCESSES);
  public static readonly WEB_MENU_STOCKS: Right = Right.of(PermissionDefinitions.WEB_MENU_STOCKS);
  public static readonly WEB_MENU_STOCK_TAKINGS: Right = Right.of(PermissionDefinitions.WEB_MENU_STOCK_TAKINGS);
  public static readonly WEB_MENU_SHIPPING_DEMANDS: Right = Right.of(PermissionDefinitions.WEB_MENU_SHIPPING_DEMANDS);
  public static readonly WEB_MENU_SHIPMENTS: Right = Right.of(PermissionDefinitions.WEB_MENU_SHIPMENTS);
  public static readonly WEB_MENU_TRANSPORTS: Right = Right.of(PermissionDefinitions.WEB_MENU_TRANSPORTS);
  public static readonly WEB_MENU_EXTERIOR_TRANSPORTS: Right = Right.of(PermissionDefinitions.WEB_MENU_EXTERIOR_TRANSPORTS);
  public static readonly WEB_MENU_NOTES: Right = Right.of(PermissionDefinitions.WEB_MENU_NOTES);
  public static readonly WEB_MENU_DOCUMENTS: Right = Right.of(PermissionDefinitions.WEB_MENU_DOCUMENTS);
  public static readonly WEB_MENU_DOCUMENT_LIBRARY: Right = Right.of(PermissionDefinitions.WEB_MENU_DOCUMENT_LIBRARY);

  public static readonly APP_TYPE_READ: Right = Right.of(PermissionDefinitions.APP_TYPE_READ);

  public static readonly API_USER_KEY_READ: Right = Right.of(PermissionDefinitions.API_USER_KEY_READ);
  public static readonly API_USER_KEY_REGENERATE: Right = Right.of(PermissionDefinitions.API_USER_KEY_REGENERATE);
  public static readonly API_USER_KEY_INACTIVATE: Right = Right.of(PermissionDefinitions.API_USER_KEY_INACTIVATE);
  public static readonly API_USER_KEY_ACTIVATE: Right = Right.of(PermissionDefinitions.API_USER_KEY_ACTIVATE);

  public static readonly USER_CREATE: Right = Right.each(
    PermissionDefinitions.USER_CREATE,
    PermissionDefinitions.USER_CREATE_ALL,
    PermissionDefinitions.USER_CREATE_MY_COMPANY,
  );
  public static readonly USER_CREATE_ALL: Right = Right.each(
    PermissionDefinitions.USER_CREATE_ALL,
  );
  public static readonly USER_CREATE_MY_COMPANY: Right = Right.of(PermissionDefinitions.USER_CREATE_MY_COMPANY);
  public static readonly USER_DISABLE: Right = Right.each(
    PermissionDefinitions.USER_DISABLE,
    PermissionDefinitions.USER_DISABLE_ALL,
    PermissionDefinitions.USER_DISABLE_MY_COMPANY
  );
  public static readonly USER_DELETE: Right = Right.each(
    PermissionDefinitions.USER_DELETE_ALL,
    PermissionDefinitions.USER_DELETE_MY_COMPANY
  );
  public static readonly USER_HISTORY_LOG: Right = Right.each(
    PermissionDefinitions.USER_HISTORY_LOG_READ,
  );
  public static readonly USER_READ: Right = Right.each(
    PermissionDefinitions.USER_READ_ALL,
    PermissionDefinitions.USER_READ_MYSELF,
    PermissionDefinitions.USER_READ_MY_COMPANY
  );
  public static readonly USER_UPDATE: Right = Right.each(
    PermissionDefinitions.USER_UPDATE_ALL,
    PermissionDefinitions.USER_UPDATE_MYSELF,
    PermissionDefinitions.USER_UPDATE_MY_COMPANY
  );
  public static readonly USER_UPDATE_ALL: Right = Right.of(PermissionDefinitions.USER_UPDATE_ALL);
  public static readonly USER_UPDATE_MY_COMPANY: Right = Right.of(PermissionDefinitions.USER_UPDATE_MY_COMPANY);

  public static readonly USER_CHANGE_PASSWORD: Right = Right.each(
    PermissionDefinitions.USER_CHANGE_PASSWORD_ALL,
    PermissionDefinitions.USER_CHANGE_PASSWORD_MYSELF,
    PermissionDefinitions.USER_CHANGE_PASSWORD_MY_COMPANY,
  );

  public static readonly USER_CHANGE_PREVIOUS_PASSWORD: Right = Right.each(
    PermissionDefinitions.USER_CHANGE_PREVIOUS_PASSWORD_ALL,
    PermissionDefinitions.USER_CHANGE_PREVIOUS_PASSWORD_MYSELF,
  );

  public static readonly USER_LDAP_SYNC: Right = Right.of(PermissionDefinitions.USER_LDAP_SYNC_ALL);
  public static readonly USER_SIGNATURE_DELETE: Right = Right.each(PermissionDefinitions.USER_SIGNATURE_DELETE_ALL,
    PermissionDefinitions.USER_SIGNATURE_DELETE_MINE);
  public static readonly USER_SIGNATURE_READ: Right = Right.each(PermissionDefinitions.USER_SIGNATURE_READ_ALL,
    PermissionDefinitions.USER_SIGNATURE_READ_MINE);
  public static readonly USER_SIGNATURE_UPLOAD: Right = Right.each(PermissionDefinitions.USER_SIGNATURE_UPLOAD_ALL,
    PermissionDefinitions.USER_SIGNATURE_UPLOAD_MINE);

  public static readonly USER_ATTACHMENT_DOWNLOAD_FILE: Right = Right.each(
    PermissionDefinitions.USER_ATTACHMENT_DOWNLOAD_FILE_ALL,
    PermissionDefinitions.USER_ATTACHMENT_DOWNLOAD_FILE_MYSELF
  );

  public static readonly USER_IMPORT_FILE: Right = Right.of(PermissionDefinitions.USER_IMPORT_FILE);
  public static readonly USER_EXPORT_FILE: Right = Right.of(PermissionDefinitions.USER_EXPORT_FILE);

  public static readonly APP_FILE_READ: Right = Right.of(PermissionDefinitions.APP_FILE_READ_ALL);
  public static readonly APP_FILE_CREATE: Right = Right.of(PermissionDefinitions.APP_FILE_CREATE);
  public static readonly APP_FILE_UPDATE: Right = Right.of(PermissionDefinitions.APP_FILE_UPDATE_ALL);
  public static readonly APP_FILE_UPLOAD: Right = Right.of(PermissionDefinitions.APP_FILE_UPLOAD_ALL);
  public static readonly APP_FILE_DELETE: Right = Right.of(PermissionDefinitions.APP_FILE_DELETE_ALL);

  public static readonly MOBILE_APP_READ: Right = Right.of(PermissionDefinitions.MOBILE_APP_READ);
  public static readonly MOBILE_APP_DISABLE: Right = Right.of(PermissionDefinitions.MOBILE_APP_DISABLE);
  public static readonly MOBILE_APP_CHANGE_NAME: Right = Right.of(PermissionDefinitions.MOBILE_APP_CHANGE_NAME);

  public static readonly USER_GROUP_READ: Right = Right.each(
    PermissionDefinitions.USER_GROUP_READ,
    PermissionDefinitions.USER_GROUP_READ_ALL,
    PermissionDefinitions.USER_GROUP_READ_MY_COMPANY,
    PermissionDefinitions.USER_GROUP_READ_MINE
  );
  public static readonly USER_GROUP_CREATE: Right = Right.each(
    PermissionDefinitions.USER_GROUP_CREATE,
    PermissionDefinitions.USER_GROUP_CREATE_ALL,
    PermissionDefinitions.USER_GROUP_CREATE_MY_COMPANY
  );
  public static readonly USER_GROUP_CREATE_ALL: Right = Right.of(PermissionDefinitions.USER_GROUP_CREATE_ALL);
  public static readonly USER_GROUP_CREATE_MY_COMPANY: Right = Right.of(PermissionDefinitions.USER_GROUP_CREATE_MY_COMPANY);
  public static readonly USER_GROUP_UPDATE: Right = Right.each(
    PermissionDefinitions.USER_GROUP_UPDATE,
    PermissionDefinitions.USER_GROUP_UPDATE_ALL,
    PermissionDefinitions.USER_GROUP_UPDATE_MY_COMPANY
  );
  public static readonly USER_GROUP_UPDATE_ALL: Right = Right.of(PermissionDefinitions.USER_GROUP_UPDATE_ALL);
  public static readonly USER_GROUP_UPDATE_MY_COMPANY: Right = Right.of(PermissionDefinitions.USER_GROUP_UPDATE_MY_COMPANY);
  public static readonly USER_GROUP_DISABLE: Right = Right.each(
    PermissionDefinitions.USER_GROUP_DISABLE,
    PermissionDefinitions.USER_GROUP_DISABLE_ALL,
    PermissionDefinitions.USER_GROUP_DISABLE_MY_COMPANY
  );
  public static readonly USER_GROUP_DELETE: Right = Right.each(
    PermissionDefinitions.USER_GROUP_DELETE_ALL,
    PermissionDefinitions.USER_GROUP_DELETE_MY_COMPANY
  );

  public static readonly USER_GROUP_LDAP_CHANGE_USER_GROUP: Right = Right.of(PermissionDefinitions.USER_GROUP_LDAP_CHANGE_USER_GROUP);

  public static readonly USER_GROUP_LDAP_SYNC = Right.of(PermissionDefinitions.USER_GROUP_LDAP_SYNC_ALL);
  public static readonly USER_GROUP_LDAP_READ = Right.of(PermissionDefinitions.USER_GROUP_LDAP_READ_ALL);
  public static readonly USER_GROUP_LDAP_MAP_READ = Right.of(PermissionDefinitions.USER_GROUP_LDAP_MAP_READ_ALL);
  public static readonly USER_GROUP_LDAP_MAP_UPDATE = Right.of(PermissionDefinitions.USER_GROUP_LDAP_MAP_UPDATE_ALL);

  public static readonly APP_DICTIONARY_UPDATE: Right = Right.of(PermissionDefinitions.APP_DICTIONARY_UPDATE);

  public static readonly USER_GROUP_HISTORY_LOG: Right = Right.of(PermissionDefinitions.USER_GROUP_HISTORY_LOG_READ);

  // Document group
  public static readonly DOCUMENT_GROUP_READ: Right = Right.each(
    PermissionDefinitions.DOCUMENT_GROUP_READ_ALL,
    PermissionDefinitions.DOCUMENT_GROUP_READ_MINE,
  );
  public static readonly DOCUMENT_GROUP_CREATE: Right = Right.of(PermissionDefinitions.DOCUMENT_GROUP_CREATE);
  public static readonly DOCUMENT_GROUP_UPDATE: Right = Right.of(PermissionDefinitions.DOCUMENT_GROUP_UPDATE);
  public static readonly DOCUMENT_GROUP_DISABLE: Right = Right.of(PermissionDefinitions.DOCUMENT_GROUP_DISABLE);

  // MasterData
  public static readonly MASTER_DATA_READ: Right = Right.each(
    PermissionDefinitions.MASTER_DATA_READ_ALL,
    PermissionDefinitions.MASTER_DATA_READ_MINE
  );
  public static readonly MASTER_DATA_CREATE: Right = Right.of(PermissionDefinitions.MASTER_DATA_CREATE);
  public static readonly MASTER_DATA_UPDATE: Right = Right.each(
    PermissionDefinitions.MASTER_DATA_UPDATE_ALL,
    PermissionDefinitions.MASTER_DATA_UPDATE_MINE
  );
  public static readonly MASTER_DATA_DISABLE: Right = Right.each(
    PermissionDefinitions.MASTER_DATA_DISABLE_ALL,
    PermissionDefinitions.MASTER_DATA_DISABLE_MINE
  );

  // MasterData - Form
  public static readonly MASTER_DATA_FORM_READ: Right = Right.of(PermissionDefinitions.MASTER_DATA_FORM_READ);
  public static readonly MASTER_DATA_FORM_GROUP_READ: Right = Right.of(PermissionDefinitions.MASTER_DATA_FORM_GROUP_READ);
  public static readonly MASTER_DATA_FORM_FIELD_READ: Right = Right.of(PermissionDefinitions.MASTER_DATA_FORM_FIELD_READ);
  public static readonly MASTER_DATA_FORM_GROUP_UPDATE: Right = Right.of(PermissionDefinitions.MASTER_DATA_FORM_GROUP_UPDATE);
  public static readonly MASTER_DATA_FORM_FIELD_UPDATE: Right = Right.of(PermissionDefinitions.MASTER_DATA_FORM_FIELD_UPDATE);
  public static readonly MASTER_DATA_FORM_GROUP_CREATE: Right = Right.of(PermissionDefinitions.MASTER_DATA_FORM_GROUP_CREATE);
  public static readonly MASTER_DATA_FORM_FIELD_CREATE: Right = Right.of(PermissionDefinitions.MASTER_DATA_FORM_FIELD_CREATE);
  public static readonly MASTER_DATA_FORM_GROUP_DISABLE: Right = Right.of(PermissionDefinitions.MASTER_DATA_FORM_GROUP_DISABLE);
  public static readonly MASTER_DATA_FORM_FIELD_DISABLE: Right = Right.of(PermissionDefinitions.MASTER_DATA_FORM_FIELD_DISABLE);
  public static readonly MASTER_DATA_FORM_GROUP_MOVE: Right = Right.of(PermissionDefinitions.MASTER_DATA_FORM_GROUP_MOVE);
  public static readonly MASTER_DATA_FORM_FIELD_MOVE: Right = Right.of(PermissionDefinitions.MASTER_DATA_FORM_FIELD_MOVE);

  // MasterDataRecord
  public static readonly MASTER_DATA_RECORD_READ: Right = Right.each(
    PermissionDefinitions.MASTER_DATA_RECORD_READ_ALL,
    PermissionDefinitions.MASTER_DATA_RECORD_READ_MASTER_DATA_USER_GROUP_RELATED,
    PermissionDefinitions.MASTER_DATA_RECORD_READ_MY_CONTACT_LOCATION,
    PermissionDefinitions.MASTER_DATA_RECORD_READ_MINE
  );
  public static readonly MASTER_DATA_RECORD_CREATE: Right = Right.each(
    PermissionDefinitions.MASTER_DATA_RECORD_CREATE_ALL,
    PermissionDefinitions.MASTER_DATA_RECORD_CREATE_MASTER_DATA_USER_GROUP_RELATED
  );
  public static readonly MASTER_DATA_RECORD_UPDATE: Right = Right.each(
    PermissionDefinitions.MASTER_DATA_RECORD_UPDATE_ALL,
    PermissionDefinitions.MASTER_DATA_RECORD_UPDATE_MASTER_DATA_USER_GROUP_RELATED,
    PermissionDefinitions.MASTER_DATA_RECORD_UPDATE_MINE
  );
  public static readonly MASTER_DATA_RECORD_DISABLE: Right = Right.each(
    PermissionDefinitions.MASTER_DATA_RECORD_DISABLE_ALL,
    PermissionDefinitions.MASTER_DATA_RECORD_DISABLE_MASTER_DATA_USER_GROUP_RELATED,
    PermissionDefinitions.MASTER_DATA_RECORD_DISABLE_MINE
  );
  public static readonly MASTER_DATA_RECORD_IMPORT_FILE: Right = Right.of(PermissionDefinitions.MASTER_DATA_RECORD_IMPORT_FILE);
  public static readonly MASTER_DATA_RECORD_EXPORT_FILE: Right = Right.of(PermissionDefinitions.MASTER_DATA_RECORD_EXPORT_FILE);

  // MasterDataRecord Attachment
  public static readonly MASTER_DATA_RECORD_ATTACHMENT_UPLOAD: Right =
    Right.of(PermissionDefinitions.MASTER_DATA_RECORD_ATTACHMENT_UPLOAD);
  public static readonly MASTER_DATA_RECORD_ATTACHMENT_READ: Right = Right.each(
    PermissionDefinitions.MASTER_DATA_RECORD_ATTACHMENT_READ_ALL,
    PermissionDefinitions.MASTER_DATA_RECORD_ATTACHMENT_READ_MINE
  );
  public static readonly MASTER_DATA_RECORD_ATTACHMENT_DELETE: Right = Right.each(
    PermissionDefinitions.MASTER_DATA_RECORD_ATTACHMENT_DELETE_ALL,
    PermissionDefinitions.MASTER_DATA_RECORD_ATTACHMENT_DELETE_MINE
  );
  public static readonly MASTER_DATA_RECORD_ATTACHMENT_UPDATE: Right = Right.each(
    PermissionDefinitions.MASTER_DATA_RECORD_ATTACHMENT_UPDATE_ALL,
    PermissionDefinitions.MASTER_DATA_RECORD_ATTACHMENT_UPDATE_MINE
  );

  // Survey
  public static readonly SURVEY_READ: Right = Right.of(PermissionDefinitions.SURVEY_READ);
  public static readonly SURVEY_CREATE: Right = Right.of(PermissionDefinitions.SURVEY_CREATE);
  public static readonly SURVEY_UPDATE: Right = Right.of(PermissionDefinitions.SURVEY_UPDATE);
  public static readonly SURVEY_DISABLE: Right = Right.of(PermissionDefinitions.SURVEY_DISABLE);

  // Survey-Form
  public static readonly SURVEY_FORM_READ: Right = Right.of(PermissionDefinitions.SURVEY_FORM_READ);
  public static readonly SURVEY_FORM_GROUP_READ: Right = Right.of(PermissionDefinitions.SURVEY_FORM_GROUP_READ);
  public static readonly SURVEY_FORM_FIELD_READ: Right = Right.of(PermissionDefinitions.SURVEY_FORM_FIELD_READ);
  public static readonly SURVEY_FORM_GROUP_UPDATE: Right = Right.of(PermissionDefinitions.SURVEY_FORM_GROUP_UPDATE);
  public static readonly SURVEY_FORM_FIELD_UPDATE: Right = Right.of(PermissionDefinitions.SURVEY_FORM_FIELD_UPDATE);
  public static readonly SURVEY_FORM_GROUP_CREATE: Right = Right.of(PermissionDefinitions.SURVEY_FORM_GROUP_CREATE);
  public static readonly SURVEY_FORM_FIELD_CREATE: Right = Right.of(PermissionDefinitions.SURVEY_FORM_FIELD_CREATE);
  public static readonly SURVEY_FORM_GROUP_DISABLE: Right = Right.of(PermissionDefinitions.SURVEY_FORM_GROUP_DISABLE);
  public static readonly SURVEY_FORM_FIELD_DISABLE: Right = Right.of(PermissionDefinitions.SURVEY_FORM_FIELD_DISABLE);
  public static readonly SURVEY_FORM_GROUP_MOVE: Right = Right.of(PermissionDefinitions.SURVEY_FORM_GROUP_MOVE);
  public static readonly SURVEY_FORM_FIELD_MOVE: Right = Right.of(PermissionDefinitions.SURVEY_FORM_FIELD_MOVE);

  // FormTable
  public static readonly FORM_TABLE_READ: Right = Right.of(PermissionDefinitions.FORM_TABLE_READ);
  public static readonly FORM_TABLE_CREATE: Right = Right.of(PermissionDefinitions.FORM_TABLE_CREATE);
  public static readonly FORM_TABLE_UPDATE: Right = Right.of(PermissionDefinitions.FORM_TABLE_UPDATE);
  public static readonly FORM_TABLE_DISABLE: Right = Right.of(PermissionDefinitions.FORM_TABLE_DISABLE);

  // FormTable-Form
  public static readonly FORM_TABLE_FORM_READ: Right = Right.of(PermissionDefinitions.FORM_TABLE_FORM_READ);
  public static readonly FORM_TABLE_FORM_GROUP_READ: Right = Right.of(PermissionDefinitions.FORM_TABLE_FORM_GROUP_READ);
  public static readonly FORM_TABLE_FORM_FIELD_READ: Right = Right.of(PermissionDefinitions.FORM_TABLE_FORM_FIELD_READ);
  public static readonly FORM_TABLE_FORM_GROUP_UPDATE: Right = Right.of(PermissionDefinitions.FORM_TABLE_FORM_GROUP_UPDATE);
  public static readonly FORM_TABLE_FORM_FIELD_UPDATE: Right = Right.of(PermissionDefinitions.FORM_TABLE_FORM_FIELD_UPDATE);
  public static readonly FORM_TABLE_FORM_GROUP_CREATE: Right = Right.of(PermissionDefinitions.FORM_TABLE_FORM_GROUP_CREATE);
  public static readonly FORM_TABLE_FORM_FIELD_CREATE: Right = Right.of(PermissionDefinitions.FORM_TABLE_FORM_FIELD_CREATE);
  public static readonly FORM_TABLE_FORM_GROUP_DISABLE: Right = Right.of(PermissionDefinitions.FORM_TABLE_FORM_GROUP_DISABLE);
  public static readonly FORM_TABLE_FORM_FIELD_DISABLE: Right = Right.of(PermissionDefinitions.FORM_TABLE_FORM_FIELD_DISABLE);
  public static readonly FORM_TABLE_FORM_GROUP_MOVE: Right = Right.of(PermissionDefinitions.FORM_TABLE_FORM_GROUP_MOVE);
  public static readonly FORM_TABLE_FORM_FIELD_MOVE: Right = Right.of(PermissionDefinitions.FORM_TABLE_FORM_FIELD_MOVE);

  // SurveyRecord
  public static readonly SURVEY_RECORD_READ: Right = Right.each(
    PermissionDefinitions.SURVEY_RECORD_READ_ALL,
    PermissionDefinitions.SURVEY_RECORD_READ_MINE
  );
  public static readonly SURVEY_RECORD_CREATE: Right = Right.of(PermissionDefinitions.SURVEY_RECORD_CREATE);
  public static readonly SURVEY_RECORD_UPDATE: Right = Right.each(
    PermissionDefinitions.SURVEY_RECORD_UPDATE_ALL,
    PermissionDefinitions.SURVEY_RECORD_UPDATE_MINE
  );
  public static readonly SURVEY_RECORD_DISABLE: Right = Right.each(
    PermissionDefinitions.SURVEY_RECORD_DISABLE_ALL,
    PermissionDefinitions.SURVEY_RECORD_DISABLE_MINE
  );

  public static readonly SURVEY_RECORD_EXPORT: Right = Right.each(
    PermissionDefinitions.SURVEY_RECORD_EXPORT_ALL,
    PermissionDefinitions.SURVEY_RECORD_EXPORT_MINE
  );

  public static readonly SURVEY_ATTACHMENT_DOWNLOAD_FILE: Right = Right.of(
    PermissionDefinitions.SURVEY_ATTACHMENT_DOWNLOAD_FILE
  );

  public static readonly SURVEY_ATTACHMENT_DELETE_FILE: Right = Right.of(
    PermissionDefinitions.SURVEY_ATTACHMENT_DELETE_FILE
  );

  // StockListItem
  public static readonly STOCK_ITEM_READ: Right = Right.each(PermissionDefinitions.STOCK_ITEM_READ_ALL,
    PermissionDefinitions.STOCK_ITEM_READ_MY_COMPANY,
    PermissionDefinitions.STOCK_ITEM_READ_MY_STOCK_COMPANY);
  public static readonly STOCK_ITEM_CREATE: Right = Right.of(PermissionDefinitions.STOCK_ITEM_CREATE);
  public static readonly STOCK_ITEM_UPDATE: Right = Right.of(PermissionDefinitions.STOCK_ITEM_UPDATE);
  public static readonly STOCK_ITEM_DISABLE: Right = Right.of(PermissionDefinitions.STOCK_ITEM_DISABLE_ONE);
  public static readonly STOCK_ITEM_DISABLE_BULK: Right = Right.of(PermissionDefinitions.STOCK_ITEM_DISABLE_BULK);
  public static readonly STOCK_ITEM_MOVE: Right = Right.of(PermissionDefinitions.STOCK_ITEM_MOVE_ALL);
  public static readonly STOCK_ITEM_DISPOSE: Right = Right.of(PermissionDefinitions.STOCK_ITEM_DISPOSE_ALL);
  public static readonly STOCK_ITEM_IMPORT: Right = Right.of(PermissionDefinitions.STOCK_ITEM_IMPORT);
  public static readonly STOCK_ITEM_EXPORT: Right = Right.of(PermissionDefinitions.STOCK_ITEM_EXPORT);

  // Stock
  public static readonly STOCK_READ: Right = Right.each(
    PermissionDefinitions.STOCK_READ_ALL,
    PermissionDefinitions.STOCK_READ_MINE,
    PermissionDefinitions.STOCK_READ_MY_COMPANY,
    PermissionDefinitions.STOCK_READ_CENTRAL,
  );

  public static readonly STOCK_RECORD_READ: Right = Right.each(
    PermissionDefinitions.STOCK_RECORD_READ_ALL,
    PermissionDefinitions.STOCK_RECORD_READ_MINE,
    PermissionDefinitions.STOCK_RECORD_READ_MY_COMPANY,
    PermissionDefinitions.STOCK_RECORD_READ_CENTRAL,
  );

  public static readonly STOCK_RECORD_IMPORT: Right = Right.of(
    PermissionDefinitions.STOCK_RECORD_IMPORT
  );

  public static readonly STOCK_RECORD_CREATE: Right = Right.each(
    PermissionDefinitions.STOCK_RECORD_CREATE_ALL,
    PermissionDefinitions.STOCK_RECORD_CREATE_MINE,
  );

  public static readonly STOCK_RECORD_UPDATE: Right = Right.each(
    PermissionDefinitions.STOCK_RECORD_UPDATE_ALL,
    PermissionDefinitions.STOCK_RECORD_UPDATE_MINE,
  );

  public static readonly STOCK_RECORD_LOG_READ: Right = Right.each(
    PermissionDefinitions.STOCK_RECORD_LOG_READ_ALL,
    PermissionDefinitions.STOCK_RECORD_LOG_READ_MINE,
    PermissionDefinitions.STOCK_RECORD_LOG_READ_MY_COMPANY,
  );

  public static readonly STOCK_CREATE: Right = Right.of(PermissionDefinitions.STOCK_CREATE);
  public static readonly STOCK_DISABLE: Right = Right.of(PermissionDefinitions.STOCK_DISABLE);
  public static readonly STOCK_UPDATE: Right = Right.each(
    PermissionDefinitions.STOCK_UPDATE_ALL,
    PermissionDefinitions.STOCK_UPDATE_MINE
  );

  public static readonly MESSAGE_READ: Right = Right.of(PermissionDefinitions.MESSAGE_READ_MINE);
  public static readonly MESSAGE_CHANGE_READ: Right = Right.of(PermissionDefinitions.MESSAGE_CHANGE_READ_MINE);
  public static readonly MESSAGE_DISABLE: Right = Right.of(PermissionDefinitions.MESSAGE_DISABLE_MINE);
  public static readonly MESSAGE_DELETE: Right = Right.of(PermissionDefinitions.MESSAGE_DELETE_MINE);

  public static readonly MESSAGE_PARENT_READ: Right = Right.of(PermissionDefinitions.MESSAGE_PARENT_READ);
  public static readonly MESSAGE_PARENT_CREATE: Right = Right.of(PermissionDefinitions.MESSAGE_PARENT_CREATE);
  public static readonly MESSAGE_PARENT_DISABLE: Right = Right.of(PermissionDefinitions.MESSAGE_PARENT_DISABLE);
  public static readonly MESSAGE_PARENT_DELETE: Right = Right.of(PermissionDefinitions.MESSAGE_PARENT_DELETE);

  public static readonly TASK_READ: Right = Right.of(PermissionDefinitions.TASK_READ);
  public static readonly TASK_CREATE: Right = Right.of(PermissionDefinitions.TASK_CREATE);
  public static readonly TASK_UPDATE: Right = Right.of(PermissionDefinitions.TASK_UPDATE);
  public static readonly TASK_DISABLE: Right = Right.of(PermissionDefinitions.TASK_DISABLE);

  // Task - Form
  public static readonly TASK_FORM_READ: Right = Right.of(PermissionDefinitions.TASK_FORM_READ);
  public static readonly TASK_FORM_EXPORT: Right = Right.of(PermissionDefinitions.TASK_FORM_EXPORT);
  public static readonly TASK_FORM_IMPORT: Right = Right.of(PermissionDefinitions.TASK_FORM_IMPORT);
  public static readonly TASK_FORM_GROUP_READ: Right = Right.of(PermissionDefinitions.TASK_FORM_GROUP_READ);
  public static readonly TASK_FORM_FIELD_READ: Right = Right.of(PermissionDefinitions.TASK_FORM_FIELD_READ);
  public static readonly TASK_FORM_GROUP_UPDATE: Right = Right.of(PermissionDefinitions.TASK_FORM_GROUP_UPDATE);
  public static readonly TASK_FORM_FIELD_UPDATE: Right = Right.of(PermissionDefinitions.TASK_FORM_FIELD_UPDATE);
  public static readonly TASK_FORM_GROUP_CREATE: Right = Right.of(PermissionDefinitions.TASK_FORM_GROUP_CREATE);
  public static readonly TASK_FORM_FIELD_CREATE: Right = Right.of(PermissionDefinitions.TASK_FORM_FIELD_CREATE);
  public static readonly TASK_FORM_GROUP_DISABLE: Right = Right.of(PermissionDefinitions.TASK_FORM_GROUP_DISABLE);
  public static readonly TASK_FORM_FIELD_DISABLE: Right = Right.of(PermissionDefinitions.TASK_FORM_FIELD_DISABLE);
  public static readonly TASK_FORM_GROUP_MOVE: Right = Right.of(PermissionDefinitions.TASK_FORM_GROUP_MOVE);
  public static readonly TASK_FORM_FIELD_MOVE: Right = Right.of(PermissionDefinitions.TASK_FORM_FIELD_MOVE);


  public static readonly TASK_RECORD_READ: Right = Right.each(
    PermissionDefinitions.TASK_RECORD_READ_ALL,
    PermissionDefinitions.TASK_RECORD_READ_MY_CONTACT_LOCATION,
    PermissionDefinitions.TASK_RECORD_READ_MINE,
    PermissionDefinitions.TASK_RECORD_READ_CUSTOMER_RELATED,
    PermissionDefinitions.TASK_RECORD_READ_TASK_RECORD_USER_GROUP_RELATED,
    PermissionDefinitions.TASK_RECORD_READ_MY_USER_GROUP,
    PermissionDefinitions.TASK_RECORD_READ_CREATED_BY_ME,
    PermissionDefinitions.TASK_RECORD_READ_CREATED_BY_MY_USER_GROUP,
  );

  public static readonly TASK_RECORD_DOCUMENT_READ: Right = Right.each(
    PermissionDefinitions.TASK_RECORD_DOCUMENT_READ_ALL,
    PermissionDefinitions.TASK_RECORD_DOCUMENT_READ_MY_CONTACT_LOCATION,
    PermissionDefinitions.TASK_RECORD_DOCUMENT_READ_MINE,
    PermissionDefinitions.TASK_RECORD_DOCUMENT_READ_CUSTOMER_RELATED,
    PermissionDefinitions.TASK_RECORD_DOCUMENT_READ_TASK_RECORD_USER_GROUP_RELATED,
    PermissionDefinitions.TASK_RECORD_DOCUMENT_READ_MY_USER_GROUP,
    PermissionDefinitions.TASK_RECORD_DOCUMENT_READ_CREATED_BY_ME,
    PermissionDefinitions.TASK_RECORD_DOCUMENT_READ_CREATED_BY_MY_USER_GROUP,
  );

  public static readonly TASK_RECORD_CREATE: Right = Right.each(
    PermissionDefinitions.TASK_RECORD_CREATE_ALL,
    PermissionDefinitions.TASK_RECORD_CREATE_TASK_USER_GROUP_RELATED,
  );

  public static readonly TASK_RECORD_UPDATE: Right = Right.each(
    PermissionDefinitions.TASK_RECORD_UPDATE_ALL,
    PermissionDefinitions.TASK_RECORD_UPDATE_MY_CONTACT_LOCATION,
    PermissionDefinitions.TASK_RECORD_UPDATE_CREATED_BY_ME,
    PermissionDefinitions.TASK_RECORD_UPDATE_CREATED_BY_MY_USER_GROUP,
    PermissionDefinitions.TASK_RECORD_UPDATE_MINE
  );

  public static readonly TASK_RECORD_UPDATE_QUICK: Right = Right.each(
    PermissionDefinitions.TASK_RECORD_UPDATE_QUICK_ALL,
    PermissionDefinitions.TASK_RECORD_UPDATE_QUICK_MY_CONTACT_LOCATION,
    PermissionDefinitions.TASK_RECORD_UPDATE_QUICK_CREATED_BY_ME,
    PermissionDefinitions.TASK_RECORD_UPDATE_QUICK_CREATED_BY_MY_USER_GROUP,
    PermissionDefinitions.TASK_RECORD_UPDATE_QUICK_MINE
  );

  public static readonly TASK_ATTACHMENT_UPLOAD_FILE: Right = Right.of(
    PermissionDefinitions.TASK_ATTACHMENT_UPLOAD_FILE
  );

  public static readonly TASK_ATTACHMENT_DOWNLOAD_FILE: Right = Right.of(
    PermissionDefinitions.TASK_ATTACHMENT_DOWNLOAD_FILE
  );

  public static readonly TASK_ATTACHMENT_DELETE_FILE: Right = Right.of(
    PermissionDefinitions.TASK_ATTACHMENT_DELETE_FILE
  );


  public static readonly TASK_RECORD_CHANGE_DEADLINE: Right = Right.each(
    PermissionDefinitions.TASK_RECORD_CHANGE_DEADLINE_ALL,
    PermissionDefinitions.TASK_RECORD_CHANGE_DEADLINE_MINE,
  );

  public static readonly TASK_RECORD_CHANGE_AGREED_TIME: Right = Right.each(
    PermissionDefinitions.TASK_RECORD_CHANGE_AGREED_TIME_ALL,
    PermissionDefinitions.TASK_RECORD_CHANGE_AGREED_TIME_MINE,
  );

  public static readonly TASK_RECORD_CHANGE_ASSIGNEE: Right = Right.each(
    PermissionDefinitions.TASK_RECORD_CHANGE_ASSIGNEE_ALL,
    PermissionDefinitions.TASK_RECORD_CHANGE_ASSIGNEE_MINE,
  );

  public static readonly TASK_RECORD_CHANGE_ASSIGNEE_IN_PROGRESS: Right = Right.each(
    PermissionDefinitions.TASK_RECORD_CHANGE_ASSIGNEE_IN_PROGRESS_ALL,
    PermissionDefinitions.TASK_RECORD_CHANGE_ASSIGNEE_IN_PROGRESS_MINE,
  );

  public static readonly TASK_RECORD_CHANGE_IMPORTANCE: Right = Right.each(
    PermissionDefinitions.TASK_RECORD_CHANGE_IMPORTANCE_ALL,
    PermissionDefinitions.TASK_RECORD_CHANGE_IMPORTANCE_MINE,
  );

  public static readonly TASK_RECORD_GEOCODE: Right = Right.of(
    PermissionDefinitions.TASK_RECORD_GEOCODE
  );

  public static readonly TASK_RECORD_IMPORT_FILE: Right = Right.of(
    PermissionDefinitions.TASK_RECORD_IMPORT_FILE
  );

  public static readonly TASK_RECORD_EXPORT_FILE: Right = Right.of(
    PermissionDefinitions.TASK_RECORD_EXPORT_FILE
  );
  public static readonly TASK_RECORD_EXPORT_FILE_CUSTOM: Right = Right.of(
    PermissionDefinitions.TASK_RECORD_EXPORT_FILE_CUSTOM
  );
  public static readonly TASK_RECORD_EXPORT_API: Right = Right.of(
    PermissionDefinitions.TASK_RECORD_EXPORT_API
  );
  public static readonly TASK_RECORD_REVERT: Right = Right.of(
    PermissionDefinitions.TASK_RECORD_REVERT
  );

  public static readonly TASK_RECORD_LOG_READ: Right = Right.each(
    PermissionDefinitions.TASK_RECORD_LOG_READ_ALL,
    PermissionDefinitions.TASK_RECORD_LOG_READ_MY_CONTACT_LOCATION,
    PermissionDefinitions.TASK_RECORD_LOG_READ_CREATED_BY_ME,
    PermissionDefinitions.TASK_RECORD_LOG_READ_CREATED_BY_MY_USER_GROUP,
    PermissionDefinitions.TASK_RECORD_LOG_READ_MINE,
    PermissionDefinitions.TASK_RECORD_LOG_READ_CUSTOMER_RELATED
  );

  public static readonly TASK_RECORD_CHANGE_STATE_OPEN: Right = Right.each(
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_OPEN_ALL,
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_OPEN_MY_CONTACT_LOCATION,
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_OPEN_CREATED_BY_ME,
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_OPEN_CREATED_BY_MY_USER_GROUP,
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_OPEN_MINE,
  );

  public static readonly TASK_RECORD_CHANGE_STATE_REJECT: Right = Right.each(
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_REJECT_ALL,
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_REJECT_MY_CONTACT_LOCATION,
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_REJECT_CREATED_BY_ME,
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_REJECT_CREATED_BY_MY_USER_GROUP,
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_REJECT_MINE,
  );

  public static readonly TASK_RECORD_CHANGE_STATE_SUBMIT: Right = Right.each(
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_SUBMIT_ALL,
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_SUBMIT_MY_CONTACT_LOCATION,
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_SUBMIT_CREATED_BY_ME,
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_SUBMIT_CREATED_BY_MY_USER_GROUP,
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_SUBMIT_MINE,
  );

  public static readonly TASK_RECORD_CHANGE_STATE_RESTART: Right = Right.each(
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_RESTART_ALL,
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_RESTART_MY_CONTACT_LOCATION,
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_RESTART_CREATED_BY_ME,
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_RESTART_CREATED_BY_MY_USER_GROUP,
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_RESTART_MINE,
  );

  public static readonly TASK_RECORD_CHANGE_STATE_UNSUBMIT: Right = Right.each(
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_UNSUBMIT_ALL,
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_UNSUBMIT_MY_CONTACT_LOCATION,
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_UNSUBMIT_CREATED_BY_ME,
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_UNSUBMIT_CREATED_BY_MY_USER_GROUP,
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_UNSUBMIT_MINE,
  );

  public static readonly TASK_RECORD_CHANGE_STATE_RECALL: Right = Right.each(
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_RECALL_ALL,
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_RECALL_MY_CONTACT_LOCATION,
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_RECALL_CREATED_BY_ME,
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_RECALL_CREATED_BY_MY_USER_GROUP,
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_RECALL_MINE,
  );

  public static readonly TASK_RECORD_CHANGE_STATE_ARCHIVE: Right = Right.each(
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_ARCHIVE_ALL,
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_ARCHIVE_MINE,
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_ARCHIVE_MY_CONTACT_LOCATION,
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_ARCHIVE_CREATED_BY_ME,
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_ARCHIVE_CREATED_BY_MY_USER_GROUP,
  );

  public static readonly TASK_RECORD_CHANGE_STATE_VALIDATE: Right = Right.each(
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_VALIDATE_ALL,
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_VALIDATE_MY_CONTACT_LOCATION,
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_VALIDATE_CREATED_BY_ME,
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_VALIDATE_CREATED_BY_MY_USER_GROUP,
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_VALIDATE_MINE,
  );

  public static readonly TASK_RECORD_CHANGE_STATE_REVERT: Right = Right.each(
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_REVERT_ALL,
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_REVERT_MY_CONTACT_LOCATION,
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_REVERT_CREATED_BY_ME,
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_REVERT_CREATED_BY_MY_USER_GROUP,
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_REVERT_MINE,
  );

  public static readonly TASK_RECORD_CHANGE_STATE_START: Right = Right.each(
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_START_ALL,
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_START_MY_CONTACT_LOCATION,
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_START_CREATED_BY_ME,
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_START_CREATED_BY_MY_USER_GROUP,
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_START_MINE,
  );

  public static readonly TASK_RECORD_CHANGE_STATE_REOPEN: Right = Right.each(
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_REOPEN_ALL,
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_REOPEN_MY_CONTACT_LOCATION,
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_REOPEN_CREATED_BY_ME,
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_REOPEN_CREATED_BY_MY_USER_GROUP,
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_REOPEN_MINE,
  );

  public static readonly TASK_RECORD_CHANGE_STATE_PAUSE: Right = Right.each(
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_PAUSE_ALL,
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_PAUSE_MY_CONTACT_LOCATION,
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_PAUSE_CREATED_BY_ME,
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_PAUSE_CREATED_BY_MY_USER_GROUP,
    PermissionDefinitions.TASK_RECORD_CHANGE_STATE_PAUSE_MINE,
  );

  public static readonly TASK_RECORD_CHANGE_CONFIRM: Right = Right.each(
    PermissionDefinitions.TASK_RECORD_CHANGE_CONFIRM_ALL,
    PermissionDefinitions.TASK_RECORD_CHANGE_CONFIRM_MINE,
  );

  public static readonly TASK_RECORD_ATTACHMENT_READ_LIST: Right = Right.each(
    PermissionDefinitions.TASK_RECORD_ATTACHMENT_READ_LIST_ALL,
    PermissionDefinitions.TASK_RECORD_ATTACHMENT_READ_LIST_MY_CONTACT_LOCATION,
    PermissionDefinitions.TASK_RECORD_ATTACHMENT_READ_LIST_CREATED_BY_ME,
    PermissionDefinitions.TASK_RECORD_ATTACHMENT_READ_LIST_CREATED_BY_MY_USER_GROUP,
    PermissionDefinitions.TASK_RECORD_ATTACHMENT_READ_LIST_MINE,
    PermissionDefinitions.TASK_RECORD_ATTACHMENT_READ_LIST_CUSTOMER_RELATED
  );

  public static readonly TASK_RECORD_ATTACHMENT_UPLOAD_FILE: Right = Right.each(
    PermissionDefinitions.TASK_RECORD_ATTACHMENT_UPLOAD_FILE_ALL,
    PermissionDefinitions.TASK_RECORD_ATTACHMENT_UPLOAD_FILE_MINE,
  );

  public static readonly TASK_RECORD_ATTACHMENT_UPDATE_FILE: Right = Right.each(
    PermissionDefinitions.TASK_RECORD_ATTACHMENT_UPDATE_FILE_ALL,
    PermissionDefinitions.TASK_RECORD_ATTACHMENT_UPDATE_FILE_MINE,
  );

  public static readonly TASK_RECORD_ATTACHMENT_DELETE_FILE: Right = Right.each(
    PermissionDefinitions.TASK_RECORD_ATTACHMENT_DELETE_FILE_ALL,
    PermissionDefinitions.TASK_RECORD_ATTACHMENT_DELETE_FILE_MINE,
  );

  public static readonly CUSTOMER_RECORD_ATTACHMENT_READ_LIST: Right = Right.each(
    PermissionDefinitions.CUSTOMER_RECORD_ATTACHMENT_READ_ALL,
    PermissionDefinitions.CUSTOMER_RECORD_ATTACHMENT_READ_MINE,
    PermissionDefinitions.CUSTOMER_RECORD_ATTACHMENT_READ_MY_COMPANY,
    PermissionDefinitions.CUSTOMER_RECORD_ATTACHMENT_READ_CUSTOMER_USER_GROUP_RELATED,
    PermissionDefinitions.CUSTOMER_RECORD_ATTACHMENT_READ_TASK_RECORD_RELATED,
  );

  public static readonly CUSTOMER_RECORD_ATTACHMENT_UPLOAD_FILE: Right = Right.each(
    PermissionDefinitions.CUSTOMER_RECORD_ATTACHMENT_UPLOAD_FILE_ALL,
    PermissionDefinitions.CUSTOMER_RECORD_ATTACHMENT_UPLOAD_FILE_MINE,
    PermissionDefinitions.CUSTOMER_RECORD_ATTACHMENT_UPLOAD_FILE_MY_COMPANY,
    PermissionDefinitions.CUSTOMER_RECORD_ATTACHMENT_UPLOAD_FILE_CUSTOMER_USER_GROUP_RELATED,
    PermissionDefinitions.CUSTOMER_RECORD_ATTACHMENT_UPLOAD_FILE_TASK_RECORD_RELATED,
  );

  public static readonly CUSTOMER_RECORD_ATTACHMENT_DELETE_FILE: Right = Right.each(
    PermissionDefinitions.CUSTOMER_RECORD_ATTACHMENT_DELETE_FILE_ALL,
    PermissionDefinitions.CUSTOMER_RECORD_ATTACHMENT_DELETE_FILE_MINE,
    PermissionDefinitions.CUSTOMER_RECORD_ATTACHMENT_DELETE_FILE_MY_COMPANY,
    PermissionDefinitions.CUSTOMER_RECORD_ATTACHMENT_DELETE_FILE_CUSTOMER_USER_GROUP_RELATED,
    PermissionDefinitions.CUSTOMER_RECORD_ATTACHMENT_DELETE_FILE_TASK_RECORD_RELATED,
  );

  public static readonly CUSTOMER_RECORD_ATTACHMENT_UPDATE: Right = Right.each(
    PermissionDefinitions.CUSTOMER_RECORD_ATTACHMENT_UPDATE_ALL,
    PermissionDefinitions.CUSTOMER_RECORD_ATTACHMENT_UPDATE_MINE,
    PermissionDefinitions.CUSTOMER_RECORD_ATTACHMENT_UPDATE_MY_COMPANY,
    PermissionDefinitions.CUSTOMER_RECORD_ATTACHMENT_UPDATE_CUSTOMER_USER_GROUP_RELATED,
    PermissionDefinitions.CUSTOMER_RECORD_ATTACHMENT_UPDATE_TASK_RECORD_RELATED,
  );

  // Customer
  public static readonly CUSTOMER_READ: Right = Right.each(
    PermissionDefinitions.CUSTOMER_READ_ALL,
    PermissionDefinitions.CUSTOMER_READ_MINE);
  public static readonly CUSTOMER_CREATE: Right = Right.each(PermissionDefinitions.CUSTOMER_CREATE);
  public static readonly CUSTOMER_UPDATE: Right = Right.each(
    PermissionDefinitions.CUSTOMER_UPDATE_ALL,
    PermissionDefinitions.CUSTOMER_UPDATE_MINE);
  public static readonly CUSTOMER_DISABLE: Right = Right.each(
    PermissionDefinitions.CUSTOMER_DISABLE_ALL,
    PermissionDefinitions.CUSTOMER_DISABLE_MINE);

  // Customer - Form
  public static readonly CUSTOMER_FORM_READ: Right = Right.of(PermissionDefinitions.CUSTOMER_FORM_READ);
  public static readonly CUSTOMER_FORM_GROUP_READ: Right = Right.of(PermissionDefinitions.CUSTOMER_FORM_GROUP_READ);
  public static readonly CUSTOMER_FORM_FIELD_READ: Right = Right.of(PermissionDefinitions.CUSTOMER_FORM_FIELD_READ);
  public static readonly CUSTOMER_FORM_GROUP_UPDATE: Right = Right.of(PermissionDefinitions.CUSTOMER_FORM_GROUP_UPDATE);
  public static readonly CUSTOMER_FORM_FIELD_UPDATE: Right = Right.of(PermissionDefinitions.CUSTOMER_FORM_FIELD_UPDATE);
  public static readonly CUSTOMER_FORM_GROUP_CREATE: Right = Right.of(PermissionDefinitions.CUSTOMER_FORM_GROUP_CREATE);
  public static readonly CUSTOMER_FORM_FIELD_CREATE: Right = Right.of(PermissionDefinitions.CUSTOMER_FORM_FIELD_CREATE);
  public static readonly CUSTOMER_FORM_GROUP_DISABLE: Right = Right.of(PermissionDefinitions.CUSTOMER_FORM_GROUP_DISABLE);
  public static readonly CUSTOMER_FORM_FIELD_DISABLE: Right = Right.of(PermissionDefinitions.CUSTOMER_FORM_FIELD_DISABLE);
  public static readonly CUSTOMER_FORM_GROUP_MOVE: Right = Right.of(PermissionDefinitions.CUSTOMER_FORM_GROUP_MOVE);
  public static readonly CUSTOMER_FORM_FIELD_MOVE: Right = Right.of(PermissionDefinitions.CUSTOMER_FORM_FIELD_MOVE);

  // CustomerRecord
  public static readonly CUSTOMER_RECORD_CREATE: Right = Right.each(
    PermissionDefinitions.CUSTOMER_RECORD_CREATE_ALL,
    PermissionDefinitions.CUSTOMER_RECORD_CREATE_CUSTOMER_USER_GROUP_RELATED
  );
  public static readonly CUSTOMER_RECORD_IMPORT_FILE: Right = Right.of(PermissionDefinitions.CUSTOMER_RECORD_IMPORT_FILE);
  public static readonly CUSTOMER_RECORD_EXPORT_FILE: Right = Right.of(PermissionDefinitions.CUSTOMER_RECORD_EXPORT_FILE);

  public static readonly CUSTOMER_RECORD_READ: Right = Right.each(
    PermissionDefinitions.CUSTOMER_RECORD_READ_ALL,
    PermissionDefinitions.CUSTOMER_RECORD_READ_MY_CONTACT_LOCATION,
    PermissionDefinitions.CUSTOMER_RECORD_READ_MINE,
    PermissionDefinitions.CUSTOMER_RECORD_READ_CUSTOMER_USER_GROUP_RELATED,
    PermissionDefinitions.CUSTOMER_RECORD_READ_TASK_RECORD_RELATED,
  );

  public static readonly CUSTOMER_RECORD_UPDATE: Right = Right.each(
    PermissionDefinitions.CUSTOMER_RECORD_UPDATE_ALL,
    PermissionDefinitions.CUSTOMER_RECORD_UPDATE_MINE,
    PermissionDefinitions.CUSTOMER_RECORD_UPDATE_CUSTOMER_USER_GROUP_RELATED,
    PermissionDefinitions.CUSTOMER_RECORD_UPDATE_TASK_RECORD_RELATED,
  );

  public static readonly CUSTOMER_RECORD_CHANGE_OWNER: Right = Right.of(
    PermissionDefinitions.CUSTOMER_RECORD_CHANGE_OWNER
  );

  public static readonly CUSTOMER_RECORD_DISABLE: Right = Right.each(
    PermissionDefinitions.CUSTOMER_RECORD_DISABLE_ALL,
    PermissionDefinitions.CUSTOMER_RECORD_DISABLE_MINE,
    PermissionDefinitions.CUSTOMER_RECORD_DISABLE_CUSTOMER_USER_GROUP_RELATED,
    PermissionDefinitions.CUSTOMER_RECORD_DISABLE_TASK_RECORD_RELATED,
  );

  public static readonly CUSTOMER_RECORD_HISTORY_LOG: Right = Right.of(
    PermissionDefinitions.CUSTOMER_RECORD_HISTORY_LOG_READ
  );

  // TODO: this definition can be misleading
  // Here we think that it's a configuration error.
  // We have to see the usages to see it is a merged definition for the admin dropdown menu (and not used other places).
  public static readonly DOCUMENT_READ: Right = Right.each(
    PermissionDefinitions.DOCUMENT_FILE_READ_ALL,
    PermissionDefinitions.DOCUMENT_FILE_READ_MINE,
    PermissionDefinitions.DOCUMENT_HTML_READ_ALL,
    PermissionDefinitions.DOCUMENT_HTML_READ_MINE,
    PermissionDefinitions.DOCUMENT_TEXT_READ_ALL,
    PermissionDefinitions.DOCUMENT_TEXT_READ_MINE,
    PermissionDefinitions.DOCUMENT_LINK_READ_ALL,
    PermissionDefinitions.DOCUMENT_LINK_READ_MINE,
  );

  public static readonly DOCUMENT_FILE_READ: Right = Right.each(
    PermissionDefinitions.DOCUMENT_FILE_READ_ALL,
    PermissionDefinitions.DOCUMENT_FILE_READ_MINE,
  );

  public static readonly DOCUMENT_FILE_UPDATE: Right = Right.each(
    PermissionDefinitions.DOCUMENT_FILE_UPDATE_ALL,
    PermissionDefinitions.DOCUMENT_FILE_UPDATE_MINE,
  );

  public static readonly DOCUMENT_FILE_FILE_UPDATE: Right = Right.each(
    PermissionDefinitions.DOCUMENT_FILE_FILE_UPDATE_ALL,
    PermissionDefinitions.DOCUMENT_FILE_FILE_UPDATE_MINE,
  );

  public static readonly DOCUMENT_FILE_FILE_CREATE: Right =
    Right.of(PermissionDefinitions.DOCUMENT_FILE_FILE_CREATE);

  public static readonly DOCUMENT_FILE_FILE_DOWNLOAD: Right = Right.each(
    PermissionDefinitions.DOCUMENT_FILE_FILE_DOWNLOAD_ALL,
    PermissionDefinitions.DOCUMENT_FILE_FILE_DOWNLOAD_MINE,
  );

  public static readonly DOCUMENT_FILE_THUMBNAIL_DOWNLOAD: Right = Right.each(
    PermissionDefinitions.DOCUMENT_FILE_THUMBNAIL_DOWNLOAD_ALL,
    PermissionDefinitions.DOCUMENT_FILE_THUMBNAIL_DOWNLOAD_MINE,
  );

  public static readonly DOCUMENT_FILE_THUMBNAIL_UPDATE: Right = Right.each(
    PermissionDefinitions.DOCUMENT_FILE_THUMBNAIL_UPDATE_ALL,
    PermissionDefinitions.DOCUMENT_FILE_THUMBNAIL_UPDATE_MINE,
  );

  public static readonly DOCUMENT_HTML_READ: Right = Right.each(
    PermissionDefinitions.DOCUMENT_HTML_READ_ALL,
    PermissionDefinitions.DOCUMENT_HTML_READ_MINE,
  );

  public static readonly DOCUMENT_HTML_DISABLE: Right = Right.of(PermissionDefinitions.DOCUMENT_HTML_DISABLE);

  public static readonly DOCUMENT_HTML_UPDATE: Right = Right.each(
    PermissionDefinitions.DOCUMENT_HTML_UPDATE_ALL,
    PermissionDefinitions.DOCUMENT_HTML_UPDATE_MINE,
  );

  public static readonly DOCUMENT_LINK_READ: Right = Right.each(
    PermissionDefinitions.DOCUMENT_LINK_READ_ALL,
    PermissionDefinitions.DOCUMENT_LINK_READ_MINE,
  );

  public static readonly DOCUMENT_LINK_CREATE: Right = Right.of(PermissionDefinitions.DOCUMENT_LINK_CREATE);

  public static readonly DOCUMENT_LINK_DISABLE: Right = Right.of(PermissionDefinitions.DOCUMENT_LINK_DISABLE);

  public static readonly DOCUMENT_LINK_UPDATE: Right = Right.each(
    PermissionDefinitions.DOCUMENT_LINK_UPDATE_ALL,
    PermissionDefinitions.DOCUMENT_LINK_UPDATE_MINE,
  );

  public static readonly DOCUMENT_TEXT_READ: Right = Right.each(
    PermissionDefinitions.DOCUMENT_TEXT_READ_ALL,
    PermissionDefinitions.DOCUMENT_TEXT_READ_MINE,
  );

  public static readonly AUDIT_LOG_READ: Right = Right.of(PermissionDefinitions.AUDIT_LOG_READ);

  public static readonly NOTE_READ: Right = Right.each(
    PermissionDefinitions.NOTE_READ_ALL,
    PermissionDefinitions.NOTE_READ_MINE
  );

  public static readonly NOTE_CREATE: Right = Right.of(
    PermissionDefinitions.NOTE_CREATE
  );

  public static readonly NOTE_UPDATE: Right = Right.each(
    PermissionDefinitions.NOTE_UPDATE_ALL,
    PermissionDefinitions.NOTE_UPDATE_MINE,
  );

  public static readonly NOTE_DISABLE: Right = Right.each(
    PermissionDefinitions.NOTE_DISABLE_ALL,
    PermissionDefinitions.NOTE_DISABLE_MINE
  );

  // Project record
  public static readonly PROJECT_RECORD_READ: Right = Right.each(PermissionDefinitions.PROJECT_RECORD_READ_ALL,
    PermissionDefinitions.PROJECT_RECORD_READ_PROJECT_USER_GROUP_RELATED);
  public static readonly PROJECT_RECORD_CREATE: Right = Right.each(PermissionDefinitions.PROJECT_RECORD_CREATE_ALL,
    PermissionDefinitions.PROJECT_RECORD_CREATE_PROJECT_USER_GROUP_RELATED);
  public static readonly PROJECT_RECORD_UPDATE: Right = Right.each(PermissionDefinitions.PROJECT_RECORD_UPDATE_ALL,
    PermissionDefinitions.PROJECT_RECORD_UPDATE_PROJECT_USER_GROUP_RELATED);
  public static readonly PROJECT_RECORD_DISABLE: Right = Right.each(PermissionDefinitions.PROJECT_RECORD_DISABLE_ALL,
    PermissionDefinitions.PROJECT_RECORD_DISABLE_PROJECT_USER_GROUP_RELATED);
  public static readonly PROJECT_RECORD_EXPORT: Right = Right.each(PermissionDefinitions.PROJECT_RECORD_EXPORT_ALL,
    PermissionDefinitions.PROJECT_RECORD_EXPORT_PROJECT_USER_GROUP_RELATED);
  public static readonly PROJECT_RECORD_IMPORT: Right = Right.each(PermissionDefinitions.PROJECT_RECORD_IMPORT_ALL,
    PermissionDefinitions.PROJECT_RECORD_IMPORT_PROJECT_USER_GROUP_RELATED);
  public static readonly PROJECT_RECORD_ATTACHMENT_READ: Right = Right.each(PermissionDefinitions.PROJECT_RECORD_ATTACHMENT_READ_ALL,
    PermissionDefinitions.PROJECT_RECORD_ATTACHMENT_READ_PROJECT_USER_GROUP_RELATED);
  public static readonly PROJECT_RECORD_ATTACHMENT_DOWNLOAD_FILE: Right
    = Right.each(PermissionDefinitions.PROJECT_RECORD_ATTACHMENT_DOWNLOAD_FILE_ALL,
    PermissionDefinitions.PROJECT_RECORD_ATTACHMENT_DOWNLOAD_FILE_PROJECT_USER_GROUP_RELATED);
  public static readonly PROJECT_RECORD_ATTACHMENT_UPLOAD_FILE: Right
    = Right.each(PermissionDefinitions.PROJECT_RECORD_ATTACHMENT_UPLOAD_FILE_ALL,
    PermissionDefinitions.PROJECT_RECORD_ATTACHMENT_UPLOAD_FILE_PROJECT_USER_GROUP_RELATED);
  public static readonly PROJECT_RECORD_ATTACHMENT_DELETE: Right = Right.each(PermissionDefinitions.PROJECT_RECORD_ATTACHMENT_DELETE_ALL,
    PermissionDefinitions.PROJECT_RECORD_ATTACHMENT_DELETE_PROJECT_USER_GROUP_RELATED);

  // Project
  public static readonly PROJECT_READ: Right = Right.each(PermissionDefinitions.PROJECT_READ_ALL,
    PermissionDefinitions.PROJECT_READ_PROJECT_USER_GROUP_RELATED);
  public static readonly PROJECT_CREATE: Right = Right.of(PermissionDefinitions.PROJECT_CREATE);
  public static readonly PROJECT_UPDATE: Right = Right.of(PermissionDefinitions.PROJECT_UPDATE);
  public static readonly PROJECT_DISABLE: Right = Right.of(PermissionDefinitions.PROJECT_DISABLE);

  public static readonly PROJECT_FORM_READ: Right = Right.of(PermissionDefinitions.PROJECT_FORM_READ);
  public static readonly PROJECT_FORM_CREATE: Right = Right.of(PermissionDefinitions.PROJECT_FORM_CREATE);
  public static readonly PROJECT_FORM_UPDATE: Right = Right.of(PermissionDefinitions.PROJECT_FORM_UPDATE);
  public static readonly PROJECT_FORM_DISABLE: Right = Right.of(PermissionDefinitions.PROJECT_FORM_DISABLE);
  public static readonly PROJECT_FORM_IMPORT: Right = Right.of(PermissionDefinitions.PROJECT_FORM_IMPORT);
  public static readonly PROJECT_FORM_EXPORT: Right = Right.of(PermissionDefinitions.PROJECT_FORM_EXPORT);

  public static readonly LIST_ITEM_READ: Right = Right.of(PermissionDefinitions.LIST_ITEM_READ);
  public static readonly LIST_ITEM_CREATE: Right = Right.of(PermissionDefinitions.LIST_ITEM_CREATE);
  public static readonly LIST_ITEM_UPDATE: Right = Right.of(PermissionDefinitions.LIST_ITEM_UPDATE);
  public static readonly LIST_ITEM_DISABLE: Right = Right.of(PermissionDefinitions.LIST_ITEM_DISABLE);
  public static readonly LIST_ITEM_IMPORT_XLS: Right = Right.of(PermissionDefinitions.LIST_ITEM_IMPORT_XLS);
  public static readonly LIST_ITEM_TYPE_READ: Right = Right.of(PermissionDefinitions.LIST_ITEM_TYPE_READ);
  public static readonly LIST_ITEM_TYPE_CREATE: Right = Right.of(PermissionDefinitions.LIST_ITEM_TYPE_CREATE);
  public static readonly LIST_ITEM_TYPE_UPDATE: Right = Right.of(PermissionDefinitions.LIST_ITEM_TYPE_UPDATE);
  public static readonly LIST_ITEM_TYPE_DISABLE: Right = Right.of(PermissionDefinitions.LIST_ITEM_TYPE_DISABLE);

  public static readonly TASK_RECORD_MANUAL_INVOICE_CREATE: Right = Right.of(PermissionDefinitions.TASK_RECORD_MANUAL_INVOICE_CREATE);

  public static readonly INVOICE_SETTINGS_READ: Right = Right.of(PermissionDefinitions.INVOICE_SETTINGS_READ);
  public static readonly INVOICE_SETTINGS_CREATE: Right = Right.of(PermissionDefinitions.INVOICE_SETTINGS_CREATE);
  public static readonly INVOICE_SETTINGS_UPDATE: Right = Right.of(PermissionDefinitions.INVOICE_SETTINGS_UPDATE);
  public static readonly INVOICE_SETTINGS_DISABLE: Right = Right.of(PermissionDefinitions.INVOICE_SETTINGS_DISABLE);
  public static readonly INVOICE_BOOK_READ: Right = Right.of(PermissionDefinitions.INVOICE_BOOK_READ);
  public static readonly INVOICE_BOOK_CREATE: Right = Right.of(PermissionDefinitions.INVOICE_BOOK_CREATE);
  public static readonly INVOICE_BOOK_UPDATE: Right = Right.of(PermissionDefinitions.INVOICE_BOOK_UPDATE);
  public static readonly INVOICE_BOOK_DISABLE: Right = Right.of(PermissionDefinitions.INVOICE_BOOK_DISABLE);
  public static readonly LEDGER_NUMBER_READ: Right = Right.of(PermissionDefinitions.LEDGER_NUMBER_READ);
  public static readonly LEDGER_NUMBER_CREATE: Right = Right.of(PermissionDefinitions.LEDGER_NUMBER_CREATE);
  public static readonly LEDGER_NUMBER_UPDATE: Right = Right.of(PermissionDefinitions.LEDGER_NUMBER_UPDATE);
  public static readonly LEDGER_NUMBER_DISABLE: Right = Right.of(PermissionDefinitions.LEDGER_NUMBER_DISABLE);
  public static readonly INVOICE_TAG_READ: Right = Right.of(PermissionDefinitions.INVOICE_TAG_READ);
  public static readonly INVOICE_TAG_CREATE: Right = Right.of(PermissionDefinitions.INVOICE_TAG_CREATE);
  public static readonly INVOICE_TAG_UPDATE: Right = Right.of(PermissionDefinitions.INVOICE_TAG_UPDATE);
  public static readonly INVOICE_TAG_DISABLE: Right = Right.of(PermissionDefinitions.INVOICE_TAG_DISABLE);
  public static readonly INVOICE_CREATE: Right = Right.of(PermissionDefinitions.INVOICE_CREATE);
  public static readonly INVOICE_READ: Right = Right.of(PermissionDefinitions.INVOICE_READ);
  public static readonly INVOICE_STORNO: Right = Right.of(PermissionDefinitions.INVOICE_STORNO);
  public static readonly INVOICE_ACTION: Right = Right.of(PermissionDefinitions.INVOICE_ACTION);
  public static readonly INVOICE_BALANCE_READ: Right = Right.of(PermissionDefinitions.INVOICE_BALANCE_READ);
  public static readonly INVOICE_BALANCE_CREATE: Right = Right.of(PermissionDefinitions.INVOICE_BALANCE_CREATE);
  public static readonly INVOICE_BALANCE_DELETE: Right = Right.of(PermissionDefinitions.INVOICE_BALANCE_DELETE);
  public static readonly INVOICE_SPENDING_READ: Right = Right.of(PermissionDefinitions.INVOICE_SPENDING_READ);
  public static readonly INVOICE_SPENDING_CREATE: Right = Right.of(PermissionDefinitions.INVOICE_SPENDING_CREATE);

  public static readonly TASK_LOG_STAT_READ: Right = Right.of(PermissionDefinitions.TASK_LOG_STAT_READ);
  public static readonly TASK_INVOICE_STAT_READ: Right = Right.of(PermissionDefinitions.TASK_INVOICE_STAT_READ);
  public static readonly CONTRACT_NUMBER_READ: Right = Right.of(PermissionDefinitions.CONTRACT_NUMBER_READ_ALL);

  public static readonly LEGACY_WORKFLOW_READ_ALL: Right = Right.of(PermissionDefinitions.LEGACY_WORKFLOW_READ_ALL);
  public static readonly LEGACY_WORKFLOW_CREATE: Right = Right.of(PermissionDefinitions.LEGACY_WORKFLOW_CREATE);
  public static readonly LEGACY_WORKFLOW_UPATE_ALL: Right = Right.of(PermissionDefinitions.LEGACY_WORKFLOW_UPATE_ALL);
  public static readonly LEGACY_WORKFLOW_DISABLE_ALL: Right = Right.of(PermissionDefinitions.LEGACY_WORKFLOW_DISABLE_ALL);

  public static readonly WORKFLOW_CREATE: Right = Right.of(PermissionDefinitions.WORKFLOW_CREATE);
  public static readonly WORKFLOW_READ_ALL: Right = Right.of(PermissionDefinitions.WORKFLOW_READ_ALL);
  public static readonly WORKFLOW_UPDATE_ALL: Right = Right.of(PermissionDefinitions.WORKFLOW_UPDATE_ALL);
  public static readonly WORKFLOW_DELETE_ALL: Right = Right.of(PermissionDefinitions.WORKFLOW_DELETE_ALL);
  public static readonly WORKFLOW_ACTIVATE_ALL: Right = Right.of(PermissionDefinitions.WORKFLOW_ACTIVATE_ALL);
  public static readonly WORKFLOW_FINALIZE_ALL: Right = Right.of(PermissionDefinitions.WORKFLOW_FINALIZE_ALL);
  public static readonly WORKFLOW_CREATE_NEW_VERSION_ALL: Right = Right.of(PermissionDefinitions.WORKFLOW_CREATE_NEW_VERSION_ALL);

  public static readonly PROCESS_CREATE: Right = Right.of(PermissionDefinitions.PROCESS_CREATE);
  public static readonly PROCESS_READ: Right = Right.each(
    PermissionDefinitions.PROCESS_READ_ALL,
    PermissionDefinitions.PROCESS_READ_MINE,
    PermissionDefinitions.PROCESS_READ_USER_GROUP_RELATED,
    );
  public static readonly PROCESS_UPDATE: Right = Right.each(
    PermissionDefinitions.PROCESS_UPDATE_ALL,
    PermissionDefinitions.PROCESS_UPDATE_MINE,
    PermissionDefinitions.PROCESS_UPDATE_USER_GROUP_RELATED,

  );
  public static readonly PROCESS_CHAT_MESSAGE_DELETE: Right = Right.each(
    PermissionDefinitions.PROCESS_CHAT_MESSAGE_DELETE_ALL,
    PermissionDefinitions.PROCESS_CHAT_MESSAGE_DELETE_MINE,
  );

  public static readonly LEGACY_PROCESS_READ_ALL: Right = Right.of(PermissionDefinitions.LEGACY_PROCESS_READ_ALL);
  public static readonly LEGACY_PROCESS_CREATE_ALL: Right = Right.of(PermissionDefinitions.LEGACY_PROCESS_CREATE_ALL);
  public static readonly LEGACY_PROCESS_UPDATE_ALL: Right = Right.of(PermissionDefinitions.LEGACY_PROCESS_UPDATE_ALL);
  public static readonly LEGACY_PROCESS_CHANGE_STATE_ALL: Right = Right.of(PermissionDefinitions.LEGACY_PROCESS_CHANGE_STATE_ALL);

  public static readonly LEGACY_PROCESS_TASK_READ: Right = Right.each(
    PermissionDefinitions.LEGACY_PROCESS_TASK_READ_ALL,
    PermissionDefinitions.LEGACY_PROCESS_TASK_READ_MINE);
  public static readonly LEGACY_PROCESS_TASK_UPDATE: Right = Right.each(
    PermissionDefinitions.LEGACY_PROCESS_TASK_UPDATE_ALL,
    PermissionDefinitions.LEGACY_PROCESS_TASK_UPDATE_MINE);
  public static readonly LEGACY_PROCESS_TASK_CHANGE_ASSIGNEE_ALL: Right = Right.of(
    PermissionDefinitions.LEGACY_PROCESS_TASK_CHANGE_ASSIGNEE_ALL
  );
  public static readonly LEGACY_PROCESS_TASK_CHANGE_STATE: Right = Right.each(
    PermissionDefinitions.LEGACY_PROCESS_TASK_CHANGE_STATE_ALL,
    PermissionDefinitions.LEGACY_PROCESS_TASK_CHANGE_STATE_MINE);

  public static readonly ORDER_CREATE: Right = Right.of(PermissionDefinitions.ORDER_CREATE);
  public static readonly ORDER_READ: Right = Right.each(
    PermissionDefinitions.ORDER_READ_ALL,
    PermissionDefinitions.ORDER_READ_MINE,
    PermissionDefinitions.ORDER_READ_MY_COMPANY
  );
  public static readonly ORDER_UPDATE: Right = Right.each(
    PermissionDefinitions.ORDER_UPDATE_ALL,
    PermissionDefinitions.ORDER_UPDATE_MINE,
    PermissionDefinitions.ORDER_UPDATE_MY_COMPANY
  );
  public static readonly ORDER_SUBMIT: Right = Right.each(
    PermissionDefinitions.ORDER_SUBMIT_ALL,
    PermissionDefinitions.ORDER_SUBMIT_MINE,
    PermissionDefinitions.ORDER_SUBMIT_MY_COMPANY
  );
  public static readonly ORDER_CANCEL: Right = Right.each(
    PermissionDefinitions.ORDER_CANCEL_ALL,
    PermissionDefinitions.ORDER_CANCEL_MINE,
    PermissionDefinitions.ORDER_CANCEL_MY_COMPANY
  );

  public static readonly ORDER_NOTE_EXTRA_READ: Right = Right.each(
    PermissionDefinitions.ORDER_NOTE_EXTRA_READ_ALL,
    PermissionDefinitions.ORDER_NOTE_EXTRA_READ_MINE,
    PermissionDefinitions.ORDER_NOTE_EXTRA_READ_MY_COMPANY
  );
  public static readonly ORDER_NOTE_EXTRA_UPDATE: Right = Right.each(
    PermissionDefinitions.ORDER_NOTE_EXTRA_UPDATE_ALL,
    PermissionDefinitions.ORDER_NOTE_EXTRA_UPDATE_MINE,
    PermissionDefinitions.ORDER_NOTE_EXTRA_UPDATE_MY_COMPANY
  );
  public static readonly ORDER_NOTE_INTERNAL_READ: Right = Right.each(
    PermissionDefinitions.ORDER_NOTE_INTERNAL_READ_ALL,
    PermissionDefinitions.ORDER_NOTE_INTERNAL_READ_MINE,
    PermissionDefinitions.ORDER_NOTE_INTERNAL_READ_MY_COMPANY
  );
  public static readonly ORDER_NOTE_INTERNAL_UPDATE: Right = Right.each(
    PermissionDefinitions.ORDER_NOTE_INTERNAL_UPDATE_ALL,
    PermissionDefinitions.ORDER_NOTE_INTERNAL_UPDATE_MINE,
    PermissionDefinitions.ORDER_NOTE_INTERNAL_UPDATE_MY_COMPANY
  );
  public static readonly ORDER_NOTE_EXTRA_READ_ALL: Right = Right.of(PermissionDefinitions.ORDER_NOTE_EXTRA_READ_ALL);
  public static readonly ORDER_NOTE_EXTRA_READ_MINE: Right = Right.of(PermissionDefinitions.ORDER_NOTE_EXTRA_READ_MINE);
  public static readonly ORDER_NOTE_EXTRA_READ_MY_COMPANY: Right = Right.of(PermissionDefinitions.ORDER_NOTE_EXTRA_READ_MY_COMPANY);
  public static readonly ORDER_NOTE_INTERNAL_READ_ALL: Right = Right.of(PermissionDefinitions.ORDER_NOTE_INTERNAL_READ_ALL);
  public static readonly ORDER_NOTE_INTERNAL_READ_MINE: Right = Right.of(PermissionDefinitions.ORDER_NOTE_INTERNAL_READ_MINE);
  public static readonly ORDER_NOTE_INTERNAL_READ_MY_COMPANY: Right = Right.of(PermissionDefinitions.ORDER_NOTE_INTERNAL_READ_MY_COMPANY);
  public static readonly ORDER_NOTE_EXTRA_UPDATE_ALL: Right = Right.of(PermissionDefinitions.ORDER_NOTE_EXTRA_UPDATE_ALL);
  public static readonly ORDER_NOTE_EXTRA_UPDATE_MINE: Right = Right.of(PermissionDefinitions.ORDER_NOTE_EXTRA_UPDATE_MINE);
  public static readonly ORDER_NOTE_EXTRA_UPDATE_MY_COMPANY: Right = Right.of(PermissionDefinitions.ORDER_NOTE_EXTRA_UPDATE_MY_COMPANY);
  public static readonly ORDER_NOTE_INTERNAL_UPDATE_ALL: Right = Right.of(PermissionDefinitions.ORDER_NOTE_INTERNAL_UPDATE_ALL);
  public static readonly ORDER_NOTE_INTERNAL_UPDATE_MINE: Right = Right.of(PermissionDefinitions.ORDER_NOTE_INTERNAL_UPDATE_MINE);
  public static readonly ORDER_NOTE_INTERNAL_UPDATE_MY_COMPANY: Right
    = Right.of(PermissionDefinitions.ORDER_NOTE_INTERNAL_UPDATE_MY_COMPANY);

  public static readonly TASK_RECORD_CHAT_READ: Right = Right.each(
    PermissionDefinitions.TASK_RECORD_CHAT_READ_ALL,
    PermissionDefinitions.TASK_RECORD_CHAT_READ_MY_CONTACT_LOCATION,
    PermissionDefinitions.TASK_RECORD_CHAT_READ_MINE,
    PermissionDefinitions.TASK_RECORD_CHAT_READ_CUSTOMER_RELATED,
    PermissionDefinitions.TASK_RECORD_CHAT_READ_TASK_RECORD_USER_GROUP_RELATED,
    PermissionDefinitions.TASK_RECORD_CHAT_READ_MY_USER_GROUP,
    PermissionDefinitions.TASK_RECORD_CHAT_READ_CREATED_BY_ME,
    PermissionDefinitions.TASK_RECORD_CHAT_READ_CREATED_BY_MY_USER_GROUP,
  );
  public static readonly TASK_RECORD_CHAT_MESSAGE_CREATE: Right = Right.each(
    PermissionDefinitions.TASK_RECORD_CHAT_MESSAGE_CREATE_ALL,
    PermissionDefinitions.TASK_RECORD_CHAT_MESSAGE_CREATE_MY_CONTACT_LOCATION,
    PermissionDefinitions.TASK_RECORD_CHAT_MESSAGE_CREATE_MINE,
    PermissionDefinitions.TASK_RECORD_CHAT_MESSAGE_CREATE_CUSTOMER_RELATED,
    PermissionDefinitions.TASK_RECORD_CHAT_MESSAGE_CREATE_TASK_RECORD_USER_GROUP_RELATED,
    PermissionDefinitions.TASK_RECORD_CHAT_MESSAGE_CREATE_MY_USER_GROUP,
    PermissionDefinitions.TASK_RECORD_CHAT_MESSAGE_CREATE_CREATED_BY_ME,
    PermissionDefinitions.TASK_RECORD_CHAT_MESSAGE_CREATE_CREATED_BY_MY_USER_GROUP,
  );
  public static readonly TASK_RECORD_CHAT_MESSAGE_DELETE: Right = Right.each(
    PermissionDefinitions.TASK_RECORD_CHAT_MESSAGE_DELETE_ALL,
    PermissionDefinitions.TASK_RECORD_CHAT_MESSAGE_DELETE_MINE,
  );

  // Stock taking
  public static readonly STOCK_TAKING_READ: Right = Right.each(
    PermissionDefinitions.STOCK_TAKING_READ_ALL,
    PermissionDefinitions.STOCK_TAKING_READ_MINE,
  );
  public static readonly STOCK_TAKING_CREATE: Right = Right.each(
    PermissionDefinitions.STOCK_TAKING_CREATE,
  );
  public static readonly STOCK_TAKING_UPDATE: Right = Right.each(
    PermissionDefinitions.STOCK_TAKING_UPDATE_ALL,
    PermissionDefinitions.STOCK_TAKING_UPDATE_MINE,
  );
  public static readonly STOCK_TAKING_SHEET_READ: Right = Right.each(
    PermissionDefinitions.STOCK_TAKING_SHEET_READ_ALL,
    PermissionDefinitions.STOCK_TAKING_SHEET_READ_MINE,
  );
  public static readonly STOCK_TAKING_SHEET_CREATE: Right = Right.each(
    PermissionDefinitions.STOCK_TAKING_SHEET_CREATE,
  );
  public static readonly STOCK_TAKING_SHEET_UPDATE: Right = Right.each(
    PermissionDefinitions.STOCK_TAKING_SHEET_UPDATE_ALL,
    PermissionDefinitions.STOCK_TAKING_SHEET_UPDATE_MINE,
  );
  public static readonly STOCK_TAKING_SHEET_UPDATE_ASSIGNEE: Right = Right.each(
    PermissionDefinitions.STOCK_TAKING_SHEET_UPDATE_ASSIGNEE_ALL,
    PermissionDefinitions.STOCK_TAKING_SHEET_UPDATE_ASSIGNEE_MINE,
  );
  public static readonly STOCK_TAKING_SHEET_DELETE: Right = Right.each(
    PermissionDefinitions.STOCK_TAKING_SHEET_DELETE_ALL,
    PermissionDefinitions.STOCK_TAKING_SHEET_DELETE_MINE,
  );

  // Calendar
  public static readonly CALENDAR_EVENT_READ: Right = Right.each(
    PermissionDefinitions.CALENDAR_READ_ALL,
    PermissionDefinitions.CALENDAR_READ_MINE,
  );
  public static readonly CALENDAR_EVENT_UPDATE: Right = Right.each(
    PermissionDefinitions.CALENDAR_UPDATE_ALL,
    PermissionDefinitions.CALENDAR_UPDATE_MINE,
  );
  public static readonly CALENDAR_EVENT_DELETE: Right = Right.each(
    PermissionDefinitions.CALENDAR_DELETE_ALL,
    PermissionDefinitions.CALENDAR_DELETE_MINE,
  );
  public static readonly CALENDAR_EVENT_CREATE: Right = Right.of(PermissionDefinitions.CALENDAR_CREATE);

  // GlobalCalendar
  public static readonly GLOBAL_CALENDAR_EVENT_READ: Right = Right.of(
    PermissionDefinitions.GLOBAL_CALENDAR_READ_ALL,
  );
  public static readonly GLOBAL_CALENDAR_EVENT_UPDATE: Right = Right.of(
    PermissionDefinitions.GLOBAL_CALENDAR_UPDATE_ALL,
  );
  public static readonly GLOBAL_CALENDAR_EVENT_DELETE: Right = Right.of(
    PermissionDefinitions.GLOBAL_CALENDAR_DELETE_ALL,
  );
  public static readonly GLOBAL_CALENDAR_EVENT_CREATE: Right = Right.of(PermissionDefinitions.GLOBAL_CALENDAR_CREATE);

  // Role
  public static readonly ROLE_READ: Right = Right.each(PermissionDefinitions.ROLE_READ_ALL,
    PermissionDefinitions.ROLE_READ_MY_COMPANY, PermissionDefinitions.ROLE_READ_MINE);
  public static readonly ROLE_CREATE: Right = Right.of(PermissionDefinitions.ROLE_CREATE);
  public static readonly ROLE_UPDATE: Right = Right.of(PermissionDefinitions.ROLE_UPDATE);
  public static readonly ROLE_DISABLE: Right = Right.of(PermissionDefinitions.ROLE_DISABLE);
  public static readonly ROLE_HISTORY_LOG: Right = Right.of(PermissionDefinitions.ROLE_HISTORY_LOG_READ);

  // Order note
  public static readonly ORDER_IMPORT_MPL_RETURN: Right = Right.of(PermissionDefinitions.ORDER_IMPORT_MPL_RETURN);
  public static readonly ORDER_IMPORT_MPL_FINAL_STATUS: Right = Right.of(PermissionDefinitions.ORDER_IMPORT_MPL_FINAL_STATUS);
  public static readonly ORDER_IMPORT_XLSX: Right = Right.of(PermissionDefinitions.ORDER_IMPORT_XLSX);

  // Company
  public static readonly COMPANY_READ: Right = Right.each(
    PermissionDefinitions.COMPANY_READ_ALL,
    PermissionDefinitions.COMPANY_READ_MINE,
  );
  public static readonly COMPANY_READ_ALL: Right = Right.of(PermissionDefinitions.COMPANY_READ_ALL);
  public static readonly COMPANY_READ_MINE: Right = Right.of(PermissionDefinitions.COMPANY_READ_MINE);
  public static readonly COMPANY_READ_MY_COMPANY_RELATED: Right = Right.of(PermissionDefinitions.COMPANY_READ_MY_COMPANY_RELATED);
  public static readonly COMPANY_CREATE: Right = Right.of(PermissionDefinitions.COMPANY_CREATE);
  public static readonly COMPANY_UPDATE: Right = Right.each(
    PermissionDefinitions.COMPANY_UPDATE_ALL,
    PermissionDefinitions.COMPANY_UPDATE_MINE,
  );
  public static readonly COMPANY_UPDATE_ALL: Right = Right.of(PermissionDefinitions.COMPANY_UPDATE_ALL);
  public static readonly COMPANY_UPDATE_MINE: Right = Right.of(PermissionDefinitions.COMPANY_UPDATE_MINE);
  public static readonly COMPANY_ROLES_UPDATE: Right = Right.of(PermissionDefinitions.COMPANY_ROLES_UPDATE);
  public static readonly COMPANY_DISABLE: Right = Right.of(PermissionDefinitions.COMPANY_DISABLE);
  public static readonly COMPANY_HISTORY_LOG: Right = Right.of(PermissionDefinitions.COMPANY_HISTORY_LOG_READ);

  // Vehicle
  public static readonly VEHICLE_READ: Right = Right.each(
    PermissionDefinitions.VEHICLE_READ_ALL,
    PermissionDefinitions.VEHICLE_READ_MY_COMPANY,
  );

  public static readonly VEHICLE_CREATE: Right = Right.each(
    PermissionDefinitions.VEHICLE_CREATE_ALL,
    PermissionDefinitions.VEHICLE_CREATE_MY_COMPANY,
  );

  public static readonly VEHICLE_UPDATE: Right = Right.each(
    PermissionDefinitions.VEHICLE_UPDATE_ALL,
    PermissionDefinitions.VEHICLE_UPDATE_MY_COMPANY,
  );

  public static readonly VEHICLE_DISABLE: Right = Right.each(
    PermissionDefinitions.VEHICLE_DISABLE_ALL,
    PermissionDefinitions.VEHICLE_DISABLE_MY_COMPANY,
  );

  public static readonly VEHICLE_HISTORY_LOG: Right = Right.each(
    PermissionDefinitions.VEHICLE_HISTORY_LOG_READ,
  );

  // COMPANY_LOCATION
  public static readonly COMPANY_LOCATION_READ: Right = Right.each(
    PermissionDefinitions.COMPANY_LOCATION_READ_ALL,
    PermissionDefinitions.COMPANY_LOCATION_READ_MY_COMPANY,
  );

  public static readonly COMPANY_LOCATION_CREATE: Right = Right.each(
    PermissionDefinitions.COMPANY_LOCATION_CREATE_ALL,
    PermissionDefinitions.COMPANY_LOCATION_CREATE_MY_COMPANY,
  );

  public static readonly COMPANY_LOCATION_UPDATE: Right = Right.each(
    PermissionDefinitions.COMPANY_LOCATION_UPDATE_ALL,
    PermissionDefinitions.COMPANY_LOCATION_UPDATE_MY_COMPANY,
  );

  public static readonly COMPANY_LOCATION_DISABLE: Right = Right.each(
    PermissionDefinitions.COMPANY_LOCATION_DISABLE_ALL,
    PermissionDefinitions.COMPANY_LOCATION_DISABLE_MY_COMPANY,
  );

  public static readonly COMPANY_LOCATION_HISTORY_LOG: Right = Right.each(
    PermissionDefinitions.COMPANY_LOCATION_HISTORY_LOG_READ,
  );

  // COMPANY_STOCK
  public static readonly COMPANY_STOCK_READ: Right = Right.each(
    PermissionDefinitions.COMPANY_STOCK_READ_ALL,
    PermissionDefinitions.COMPANY_STOCK_READ_MY_COMPANY,
  );

  public static readonly COMPANY_STOCK_CREATE: Right = Right.each(
    PermissionDefinitions.COMPANY_STOCK_CREATE_ALL,
    PermissionDefinitions.COMPANY_STOCK_CREATE_MY_COMPANY,
  );

  public static readonly COMPANY_STOCK_UPDATE: Right = Right.each(
    PermissionDefinitions.COMPANY_STOCK_UPDATE_ALL,
    PermissionDefinitions.COMPANY_STOCK_UPDATE_MY_COMPANY,
  );

  public static readonly COMPANY_STOCK_DISABLE: Right = Right.each(
    PermissionDefinitions.COMPANY_STOCK_DISABLE_ALL,
    PermissionDefinitions.COMPANY_STOCK_DISABLE_MY_COMPANY,
  );

  public static readonly COMPANY_STOCK_HISTORY_LOG: Right = Right.each(
    PermissionDefinitions.COMPANY_STOCK_HISTORY_LOG_READ,
  );

  // Transport
  public static readonly TRANSPORT_READ: Right = Right.each(
    PermissionDefinitions.TRANSPORT_READ_ALL,
    PermissionDefinitions.TRANSPORT_READ_MY_COMPANY,
    PermissionDefinitions.TRANSPORT_READ_MINE,
  );
  public static readonly TRANSPORT_UPDATE: Right = Right.each(
    PermissionDefinitions.TRANSPORT_UPDATE_ALL,
    PermissionDefinitions.TRANSPORT_UPDATE_MY_COMPANY,
    PermissionDefinitions.TRANSPORT_UPDATE_MINE,
  );
  public static readonly TRANSPORT_DELETE: Right = Right.each(
    PermissionDefinitions.TRANSPORT_DELETE_ALL,
    PermissionDefinitions.TRANSPORT_DELETE_MY_COMPANY,
    PermissionDefinitions.TRANSPORT_DELETE_MINE,
  );
  public static readonly TRANSPORT_USE: Right = Right.each(
    PermissionDefinitions.TRANSPORT_USE_ALL,
    PermissionDefinitions.TRANSPORT_USE_MINE,
  );
  public static readonly TRANSPORT_CREATE: Right = Right.of(PermissionDefinitions.TRANSPORT_CREATE);
  public static readonly TRANSPORT_SET_SECURITY_GUARD: Right = Right.each(
    PermissionDefinitions.TRANSPORT_SET_SECURITY_GUARD_ALL,
    PermissionDefinitions.TRANSPORT_SET_SECURITY_GUARD_MY_COMPANY,
    PermissionDefinitions.TRANSPORT_SET_SECURITY_GUARD_MINE
  );
  public static readonly TRANSPORT_SET_DRIVER: Right = Right.each(
    PermissionDefinitions.TRANSPORT_SET_DRIVER_ALL,
    PermissionDefinitions.TRANSPORT_SET_DRIVER_MY_COMPANY,
    PermissionDefinitions.TRANSPORT_SET_DRIVER_MINE
  );
  public static readonly TRANSPORT_SET_VEHICLE: Right = Right.each(
    PermissionDefinitions.TRANSPORT_SET_VEHICLE_ALL,
    PermissionDefinitions.TRANSPORT_SET_VEHICLE_MY_COMPANY,
    PermissionDefinitions.TRANSPORT_SET_VEHICLE_MINE
  );

  // Shipping Demand
  public static readonly SHIPPING_DEMAND_CREATE: Right = Right.of(PermissionDefinitions.SHIPPING_DEMAND_CREATE);
  public static readonly SHIPPING_DEMAND_IMPORT_XLSX: Right = Right.of(PermissionDefinitions.SHIPPING_DEMAND_IMPORT_XLSX);
  public static readonly SHIPPING_DEMAND_CREATE_SIMPLE: Right = Right.of(PermissionDefinitions.SHIPPING_DEMAND_CREATE_SIMPLE);
  public static readonly SHIPPING_DEMAND_READ: Right = Right.each(
    PermissionDefinitions.SHIPPING_DEMAND_READ_ALL,
    PermissionDefinitions.SHIPPING_DEMAND_READ_MY_COMPANY,
  );
  public static readonly SHIPPING_DEMAND_READ_SIMPLE: Right = Right.of(PermissionDefinitions.SHIPPING_DEMAND_READ_MY_COMPANY_SIMPLE);
  public static readonly SHIPPING_DEMAND_UPDATE: Right = Right.each(
    PermissionDefinitions.SHIPPING_DEMAND_UPDATE_ALL,
    PermissionDefinitions.SHIPPING_DEMAND_UPDATE_MY_COMPANY,
  );
  public static readonly SHIPPING_DEMAND_UPDATE_SIMPLE: Right = Right.of(PermissionDefinitions.SHIPPING_DEMAND_UPDATE_MY_COMPANY_SIMPLE);
  public static readonly SHIPPING_DEMAND_CANCEL: Right = Right.each(
    PermissionDefinitions.SHIPPING_DEMAND_CANCEL_ALL,
    PermissionDefinitions.SHIPPING_DEMAND_CANCEL_MY_COMPANY,
  );
  public static readonly SHIPPING_DEMAND_SPLIT: Right = Right.each(
    PermissionDefinitions.SHIPPING_DEMAND_SPLIT_ALL,
    PermissionDefinitions.SHIPPING_DEMAND_SPLIT_MY_COMPANY
  );

  // Client Document
  public static readonly CLIENT_DOCUMENT_READ: Right = Right.each(
    PermissionDefinitions.CLIENT_DOCUMENT_SUBMITTED_READ_ALL,
    PermissionDefinitions.CLIENT_DOCUMENT_SUBMITTED_READ_MINE,
    PermissionDefinitions.CLIENT_DOCUMENT_IN_PROGRESS_READ_ALL,
    PermissionDefinitions.CLIENT_DOCUMENT_IN_PROGRESS_READ_MINE,
  );

  public static readonly CLIENT_DOCUMENT_CREATE: Right = Right.of(PermissionDefinitions.CLIENT_DOCUMENT_CREATE);
  public static readonly CLIENT_DOCUMENT_UPDATE: Right = Right.each(
    PermissionDefinitions.CLIENT_DOCUMENT_UPDATE_ALL,
    PermissionDefinitions.CLIENT_DOCUMENT_UPDATE_MINE,
  );
  public static readonly CLIENT_DOCUMENT_SUBMIT: Right = Right.each(
    PermissionDefinitions.CLIENT_DOCUMENT_SUBMIT_ALL,
    PermissionDefinitions.CLIENT_DOCUMENT_SUBMIT_MINE,
  );
  public static readonly CLIENT_DOCUMENT_DELETE: Right = Right.each(
    PermissionDefinitions.CLIENT_DOCUMENT_DELETE_ALL,
    PermissionDefinitions.CLIENT_DOCUMENT_DELETE_MINE,
  );

  // Email Template
  public static readonly EMAIL_TEMPLATE_READ: Right = Right.of(PermissionDefinitions.EMAIL_TEMPLATE_READ);
  public static readonly EMAIL_TEMPLATE_CREATE: Right = Right.of(PermissionDefinitions.EMAIL_TEMPLATE_CREATE);
  public static readonly EMAIL_TEMPLATE_UPDATE: Right = Right.of(PermissionDefinitions.EMAIL_TEMPLATE_UPDATE);
  public static readonly EMAIL_TEMPLATE_DISABLE: Right = Right.of(PermissionDefinitions.EMAIL_TEMPLATE_DISABLE);

  // Table Document Schema
  public static readonly TABLE_DOCUMENT_SCHEMA_READ: Right = Right.of(PermissionDefinitions.TABLE_DOCUMENT_SCHEMA_READ_ALL);
  public static readonly TABLE_DOCUMENT_SCHEMA_CREATE: Right = Right.of(PermissionDefinitions.TABLE_DOCUMENT_SCHEMA_CREATE);
  public static readonly TABLE_DOCUMENT_SCHEMA_CREATE_NEXT: Right = Right.of(PermissionDefinitions.TABLE_DOCUMENT_SCHEMA_CREATE_NEXT_ALL);
  public static readonly TABLE_DOCUMENT_SCHEMA_DEPRECATE: Right = Right.of(PermissionDefinitions.TABLE_DOCUMENT_SCHEMA_DEPRECATE_ALL);
  public static readonly TABLE_DOCUMENT_SCHEMA_UPDATE: Right = Right.of(PermissionDefinitions.TABLE_DOCUMENT_SCHEMA_UPDATE_ALL);
  public static readonly TABLE_DOCUMENT_SCHEMA_DELETE: Right = Right.of(PermissionDefinitions.TABLE_DOCUMENT_SCHEMA_DELETE_ALL);
  public static readonly TABLE_DOCUMENT_SCHEMA_FINALIZE: Right = Right.of(PermissionDefinitions.TABLE_DOCUMENT_SCHEMA_FINALIZE_ALL);

  // Table Document
  public static readonly TABLE_DOCUMENT_READ: Right = Right.of(PermissionDefinitions.TABLE_DOCUMENT_READ_ALL);
  public static readonly TABLE_DOCUMENT_CREATE: Right = Right.of(PermissionDefinitions.TABLE_DOCUMENT_CREATE_ALL);
  public static readonly TABLE_DOCUMENT_UPDATE: Right = Right.of(PermissionDefinitions.TABLE_DOCUMENT_UPDATE_ALL);
  public static readonly TABLE_DOCUMENT_DELETE: Right = Right.of(PermissionDefinitions.TABLE_DOCUMENT_DELETE_ALL);
  public static readonly TABLE_DOCUMENT_EXPORT: Right = Right.of(PermissionDefinitions.TABLE_DOCUMENT_EXPORT_ALL);
  public static readonly TABLE_DOCUMENT_REPLACE: Right = Right.of(PermissionDefinitions.TABLE_DOCUMENT_REPLACE_ALL);

  // General pdf template
  public static readonly GENERAL_PDF_TEMPLATE_READ: Right = Right.of(PermissionDefinitions.GENERAL_PDF_TEMPLATE_READ);
  public static readonly GENERAL_PDF_TEMPLATE_UPLOAD: Right = Right.of(PermissionDefinitions.GENERAL_PDF_TEMPLATE_UPLOAD);
  public static readonly GENERAL_PDF_TEMPLATE_DISABLE: Right = Right.of(PermissionDefinitions.GENERAL_PDF_TEMPLATE_DISABLE);

  public static readonly TOKEN_READ: Right = Right.of(PermissionDefinitions.TOKEN_READ_ALL);
  public static readonly TOKEN_DELETE: Right = Right.of(PermissionDefinitions.TOKEN_DELETE_ALL);

  // NFC Card
  public static readonly USER_NFC_CARD_READ: Right = Right.of(PermissionDefinitions.USER_NFC_CARD_READ_ALL);
  public static readonly USER_NFC_CARD_CREATE: Right = Right.of(PermissionDefinitions.USER_NFC_CARD_CREATE_ALL);
  public static readonly USER_NFC_CARD_DELETE: Right = Right.of(PermissionDefinitions.USER_NFC_CARD_DELETE_ALL);

  // Stock Item Category
  public static readonly STOCK_ITEM_CATEGORY_READ: Right = Right.of(PermissionDefinitions.STOCK_ITEM_CATEGORY_READ);
  public static readonly STOCK_ITEM_CATEGORY_CREATE: Right = Right.of(PermissionDefinitions.STOCK_ITEM_CATEGORY_CREATE);
  public static readonly STOCK_ITEM_CATEGORY_UPDATE: Right = Right.of(PermissionDefinitions.STOCK_ITEM_CATEGORY_UPDATE);
  public static readonly STOCK_ITEM_CATEGORY_DELETE: Right = Right.of(PermissionDefinitions.STOCK_ITEM_CATEGORY_DELETE);

  // Environment config
  public static readonly ENVIRONMENT_CONFIG_READ: Right = Right.of(PermissionDefinitions.ENVIRONMENT_CONFIG_READ);
  public static readonly ENVIRONMENT_CONFIG_UPDATE: Right = Right.of(PermissionDefinitions.ENVIRONMENT_CONFIG_UPDATE);

  // Registration settings
  public static readonly REGISTRATION_SETTINGS_READ: Right = Right.of(PermissionDefinitions.REGISTRATION_SETTINGS_READ);
  public static readonly REGISTRATION_SETTINGS_UPDATE: Right = Right.of(PermissionDefinitions.REGISTRATION_SETTINGS_UPDATE);

  // Message template
  public static readonly MESSAGE_TEMPLATE_READ: Right = Right.of(PermissionDefinitions.MESSAGE_TEMPLATE_READ);
  public static readonly MESSAGE_TEMPLATE_CREATE: Right = Right.of(PermissionDefinitions.MESSAGE_TEMPLATE_CREATE);
  public static readonly MESSAGE_TEMPLATE_UPDATE: Right = Right.of(PermissionDefinitions.MESSAGE_TEMPLATE_UPDATE);
  public static readonly MESSAGE_TEMPLATE_DISABLE: Right = Right.of(PermissionDefinitions.MESSAGE_TEMPLATE_DISABLE);

  // General pdf template
  public static readonly GENERAL_PRINTER_TEMPLATE_READ: Right = Right.of(PermissionDefinitions.GENERAL_PRINTER_TEMPLATE_READ);
  public static readonly GENERAL_PRINTER_TEMPLATE_UPLOAD: Right = Right.of(PermissionDefinitions.GENERAL_PRINTER_TEMPLATE_UPLOAD);
  public static readonly GENERAL_PRINTER_TEMPLATE_DISABLE: Right = Right.of(PermissionDefinitions.GENERAL_PRINTER_TEMPLATE_DISABLE);

  public static readonly STORED_DQL_READ: Right = Right.each(PermissionDefinitions.STORED_DQL_QUERY_READ_ALL,
    PermissionDefinitions.STORED_DQL_QUERY_READ_MY_USER_GROUP, PermissionDefinitions.STORED_DQL_QUERY_READ_MINE);
  public static readonly STORED_DQL_UPDATE: Right = Right.each(
    PermissionDefinitions.STORED_DQL_QUERY_UPDATE_ALL,
    PermissionDefinitions.STORED_DQL_QUERY_UPDATE_MINE);
  public static readonly STORED_DQL_CREATE: Right = Right.of(PermissionDefinitions.STORED_DQL_QUERY_CREATE);
  public static readonly STORED_DQL_DELETE: Right = Right.each(
    PermissionDefinitions.STORED_DQL_QUERY_DELETE_ALL,
    PermissionDefinitions.STORED_DQL_QUERY_DELETE_MINE);

  // Transport trigger
  public static readonly TRANSPORT_TRIGGER_READ = Right.of(PermissionDefinitions.TRANSPORT_TRIGGER_READ_ALL);
  public static readonly TRANSPORT_TRIGGER_DISABLE = Right.of(PermissionDefinitions.TRANSPORT_TRIGGER_DISABLE_ALL);
  public static readonly TRANSPORT_TRIGGER_DELETE = Right.of(PermissionDefinitions.TRANSPORT_TRIGGER_DELETE_ALL);
  public static readonly TRANSPORT_TRIGGER_CREATE = Right.of(PermissionDefinitions.TRANSPORT_TRIGGER_CREATE_ALL);
  public static readonly TRANSPORT_TRIGGER_UPDATE = Right.of(PermissionDefinitions.TRANSPORT_TRIGGER_UPDATE_ALL);

  // Transport trigger instance
  public static readonly TRANSPORT_TRIGGER_INSTANCE_READ = Right.of(PermissionDefinitions.TRANSPORT_TRIGGER_INSTANCE_READ_ALL);

  // TaskTrigger
  public static readonly TASK_TRIGGER_READ = Right.of(PermissionDefinitions.TASK_TRIGGER_READ_ALL);
  public static readonly TASK_TRIGGER_DISABLE = Right.of(PermissionDefinitions.TASK_TRIGGER_DISABLE_ALL);
  public static readonly TASK_TRIGGER_DELETE = Right.of(PermissionDefinitions.TASK_TRIGGER_DELETE_ALL);
  public static readonly TASK_TRIGGER_CREATE = Right.of(PermissionDefinitions.TASK_TRIGGER_CREATE_ALL);
  public static readonly TASK_TRIGGER_UPDATE = Right.of(PermissionDefinitions.TASK_TRIGGER_UPDATE_ALL);

  // TaskTriggerInstance
  public static readonly TASK_TRIGGER_INSTANCE_READ = Right.of(PermissionDefinitions.TASK_TRIGGER_INSTANCE_READ_ALL);

  // OrderDocument
  public static readonly ORDER_DOCUMENT_CREATE = Right.of(PermissionDefinitions.ORDER_DOCUMENT_CREATE_ALL);
  public static readonly ORDER_DOCUMENT_READ = Right.of(PermissionDefinitions.ORDER_DOCUMENT_READ_ALL);
  public static readonly ORDER_DOCUMENT_DOWNLOAD = Right.of(PermissionDefinitions.ORDER_DOCUMENT_DOWNLOAD_ALL);
  public static readonly ORDER_DOCUMENT_UPDATE_META = Right.of(PermissionDefinitions.ORDER_DOCUMENT_UPDATE_META_ALL);
  public static readonly ORDER_DOCUMENT_DELETE = Right.of(PermissionDefinitions.ORDER_DOCUMENT_DELETE_ALL);

  // Shipment
  public static readonly SHIPMENT_CREATE = Right.of(PermissionDefinitions.SHIPMENT_CREATE);
  public static readonly SHIPMENT_CHANGE_SUB_TRANSPORTER = Right.each(PermissionDefinitions.SHIPMENT_CHANGE_SUB_TRANSPORTER_ALL,
    PermissionDefinitions.SHIPMENT_CHANGE_SUB_TRANSPORTER_MY_COMPANY);
  public static readonly SHIPMENT_GROUP_READ = Right.each(
    PermissionDefinitions.SHIPMENT_GROUP_READ_ALL,
    PermissionDefinitions.SHIPMENT_GROUP_READ_MY_COMPANY);
  public static readonly SHIPMENT_GROUP_SHIPPING_DEMAND_REMOVE = Right.each(PermissionDefinitions.SHIPMENT_GROUP_DEMAND_REMOVE_ALL,
    PermissionDefinitions.SHIPMENT_GROUP_DEMAND_REMOVE_MY_COMPANY);
  public static readonly SHIPMENT_GROUP_DEPOT_UPDATE = Right.each(PermissionDefinitions.SHIPMENT_GROUP_DEPOT_UPDATE_ALL,
    PermissionDefinitions.SHIPMENT_GROUP_DEPOT_UPDATE_MY_COMPANY);

  // ExteriorTransport
  public static readonly EXTERIOR_TRANSPORT_CREATE = Right.of(PermissionDefinitions.EXTERIOR_TRANSPORT_CREATE);
  public static readonly EXTERIOR_TRANSPORT_READ = Right.of(PermissionDefinitions.EXTERIOR_TRANSPORT_READ_ALL);
  public static readonly EXTERIOR_TRANSPORT_START = Right.of(PermissionDefinitions.EXTERIOR_TRANSPORT_START_ALL);
  public static readonly EXTERIOR_TRANSPORT_FINISH = Right.of(PermissionDefinitions.EXTERIOR_TRANSPORT_FINISH_ALL);
  public static readonly EXTERIOR_TRANSPORT_SHIPMENT_ADD = Right.of(PermissionDefinitions.EXTERIOR_TRANSPORT_SHIPMENT_ADD_ALL);
  public static readonly EXTERIOR_TRANSPORT_SHIPMENT_REMOVE = Right.of(PermissionDefinitions.EXTERIOR_TRANSPORT_SHIPMENT_REMOVE_ALL);

  // ExteriorTransport -> Document
  public static readonly EXTERIOR_TRANSPORT_DOCUMENT_READ = Right.of(PermissionDefinitions.EXTERIOR_TRANSPORT_DOCUMENT_READ_ALL);
  public static readonly EXTERIOR_TRANSPORT_DOCUMENT_CREATE = Right.of(PermissionDefinitions.EXTERIOR_TRANSPORT_DOCUMENT_CREATE_ALL);
  public static readonly EXTERIOR_TRANSPORT_DOCUMENT_DELETE = Right.of(PermissionDefinitions.EXTERIOR_TRANSPORT_DOCUMENT_DELETE_ALL);
  public static readonly EXTERIOR_TRANSPORT_DOCUMENT_DOWNLOAD = Right.of(PermissionDefinitions.EXTERIOR_TRANSPORT_DOCUMENT_DOWNLOAD_ALL);
  public static readonly EXTERIOR_TRANSPORT_DOCUMENT_META_UPDATE
    = Right.of(PermissionDefinitions.EXTERIOR_TRANSPORT_DOCUMENT_META_UPDATE_ALL);

  // ShopRenterShop
  public static readonly SHOP_RENTER_SHOP_READ = Right.each(
    PermissionDefinitions.SHOP_RENTER_SHOP_READ_ALL,
    PermissionDefinitions.SHOP_RENTER_SHOP_READ_MINE,
    PermissionDefinitions.SHOP_RENTER_SHOP_READ_MY_COMPANY
  );
  public static readonly SHOP_RENTER_SHOP_UPDATE = Right.each(
    PermissionDefinitions.SHOP_RENTER_SHOP_UPDATE_ALL,
    PermissionDefinitions.SHOP_RENTER_SHOP_UPDATE_MINE,
    PermissionDefinitions.SHOP_RENTER_SHOP_UPDATE_MY_COMPANY
  );
  public static readonly SHOP_RENTER_SHOP_SYNC = Right.each(
    PermissionDefinitions.SHOP_RENTER_SHOP_SYNC_ALL,
    PermissionDefinitions.SHOP_RENTER_SHOP_SYNC_MINE,
    PermissionDefinitions.SHOP_RENTER_SHOP_SYNC_MY_COMPANY
  );
  public static readonly SHOP_RENTER_SHOP_IMPORT_PRODUCTS = Right.each(
    PermissionDefinitions.SHOP_RENTER_SHOP_IMPORT_PRODUCTS_ALL,
    PermissionDefinitions.SHOP_RENTER_SHOP_IMPORT_PRODUCTS_MINE,
    PermissionDefinitions.SHOP_RENTER_SHOP_IMPORT_PRODUCTS_MY_COMPANY
  );

  // Worklog
  public static readonly WORKLOG_READ = Right.each(
    PermissionDefinitions.WORKLOG_READ_ALL,
    PermissionDefinitions.WORKLOG_READ_MINE
  );
  public static readonly WORKLOG_UPDATE = Right.each(
    PermissionDefinitions.WORKLOG_UPDATE_ALL,
    PermissionDefinitions.WORKLOG_UPDATE_MINE
  );
  public static readonly WORKLOG_CREATE = Right.each(
    PermissionDefinitions.WORKLOG_CREATE_ALL,
    PermissionDefinitions.WORKLOG_CREATE_MINE
  );

  // MileageRecord
  public static readonly MILEAGE_RECORD_READ = Right.each(
    PermissionDefinitions.MILEAGE_RECORD_READ_ALL,
    PermissionDefinitions.MILEAGE_RECORD_READ_MY_COMPANY,
    PermissionDefinitions.MILEAGE_RECORD_READ_MINE
  );
  public static readonly MILEAGE_RECORD_CREATE = Right.of(PermissionDefinitions.MILEAGE_RECORD_CREATE);
  public static readonly MILEAGE_RECORD_UPDATE = Right.each(
    PermissionDefinitions.MILEAGE_RECORD_UPDATE_ALL,
    PermissionDefinitions.MILEAGE_RECORD_UPDATE_MY_COMPANY,
    PermissionDefinitions.MILEAGE_RECORD_UPDATE_MINE,
  );

  // OrderImportError
  public static readonly ORDER_IMPORT_ERROR_READ = Right.of(PermissionDefinitions.ORDER_IMPORT_ERROR_READ_ALL);
  public static readonly ORDER_IMPORT_ERROR_REJECT_MANUALLY = Right.of(PermissionDefinitions.ORDER_IMPORT_ERROR_REJECT_MANUALLY_ALL);

  // StockChange
  public static readonly STOCK_CHANGE_READ = Right.each(
    PermissionDefinitions.STOCK_CHANGE_READ_ALL,
    PermissionDefinitions.STOCK_CHANGE_READ_MINE
  );

  // StockItemCustomerRecordPrice
  public static readonly STOCK_ITEM_CUSTOMER_RECORD_PRICE_READ = Right.of(PermissionDefinitions.STOCK_ITEM_CUSTOMER_RECORD_PRICE_READ);
  public static readonly STOCK_ITEM_CUSTOMER_RECORD_PRICE_CREATE =
    Right.of(PermissionDefinitions.STOCK_ITEM_CUSTOMER_RECORD_PRICE_CREATE);
  public static readonly STOCK_ITEM_CUSTOMER_RECORD_PRICE_UPDATE =
    Right.of(PermissionDefinitions.STOCK_ITEM_CUSTOMER_RECORD_PRICE_UPDATE);
  public static readonly STOCK_ITEM_CUSTOMER_RECORD_PRICE_DELETE =
    Right.of(PermissionDefinitions.STOCK_ITEM_CUSTOMER_RECORD_PRICE_DELETE);

  // ParcelCollectionPoint
  public static readonly PARCEL_COLLECTION_POINT_READ = Right.of(PermissionDefinitions.PARCEL_COLLECTION_POINT_READ);
  public static readonly PARCEL_COLLECTION_POINT_CREATE = Right.of(PermissionDefinitions.PARCEL_COLLECTION_POINT_CREATE);
  public static readonly PARCEL_COLLECTION_POINT_UPDATE = Right.of(PermissionDefinitions.PARCEL_COLLECTION_POINT_UPDATE);
  public static readonly PARCEL_COLLECTION_POINT_DISABLE = Right.of(PermissionDefinitions.PARCEL_COLLECTION_POINT_DISABLE);
  public static readonly PARCEL_COLLECTION_POINT_IMPORT = Right.of(PermissionDefinitions.PARCEL_COLLECTION_POINT_IMPORT);

  // DeliveryMethod
  public static readonly DELIVERY_METHOD_READ = Right.of(PermissionDefinitions.DELIVERY_METHOD_READ_ALL);
  public static readonly DELIVERY_METHOD_CREATE = Right.of(PermissionDefinitions.DELIVERY_METHOD_CREATE_ALL);
  public static readonly DELIVERY_METHOD_UPDATE = Right.of(PermissionDefinitions.DELIVERY_METHOD_UPDATE_ALL);
  public static readonly DELIVERY_METHOD_DISABLE = Right.of(PermissionDefinitions.DELIVERY_METHOD_DISABLE_ALL);

  // Email address type
  public static readonly EMAIL_ADDRESS_TYPE_CREATE = Right.of(PermissionDefinitions.EMAIL_ADDRESS_TYPE_CREATE);
  public static readonly EMAIL_ADDRESS_TYPE_UPDATE = Right.of(PermissionDefinitions.EMAIL_ADDRESS_TYPE_UPDATE);
  public static readonly EMAIL_ADDRESS_TYPE_DISABLE = Right.of(PermissionDefinitions.EMAIL_ADDRESS_TYPE_DISABLE);

  // Colonnade
  public static readonly COLONNADE_B2B_READ = Right.each(
    PermissionDefinitions.COLONNADE_B2B_READ_ALL,
    PermissionDefinitions.COLONNADE_B2B_READ_MINE,
  );
  public static readonly COLONNADE_B2B_READ_ALL = Right.of(PermissionDefinitions.COLONNADE_B2B_READ_ALL);
  public static readonly COLONNADE_B2B_READ_MINE = Right.of(PermissionDefinitions.COLONNADE_B2B_READ_MINE);

  // Shipment Outtake
  public static readonly SHIPMENT_OUTTAKE_STOREKEEPER_CHANGE = Right.each(
    PermissionDefinitions.SHIPMENT_OUTTAKE_STOREKEEPER_CHANGE_ALL,
    PermissionDefinitions.SHIPMENT_OUTTAKE_STOREKEEPER_CHANGE_MY_COMPANY,
  );
  public static readonly SHIPMENT_OUTTAKE_REOPEN_FORCE = Right.each(
    PermissionDefinitions.SHIPMENT_OUTTAKE_REOPEN_FORCE_ALL,
    PermissionDefinitions.SHIPMENT_OUTTAKE_REOPEN_FORCE_MY_COMPANY,
  );

  public static readonly ANDROID_APP_VERSION_HANDLE = Right.of(PermissionDefinitions.ANDROID_APP_VERSION_HANDLE);

  // Helpdesk
  public static readonly CUSTOMER_RECORD_HELPDESK_ACTION = Right.each(
    PermissionDefinitions.CUSTOMER_RECORD_HELPDESK_ACTION_ALL,
    PermissionDefinitions.CUSTOMER_RECORD_HELPDESK_ACTION_MINE,
    PermissionDefinitions.CUSTOMER_RECORD_HELPDESK_ACTION_MY_COMPANY,
    PermissionDefinitions.CUSTOMER_RECORD_HELPDESK_ACTION_CUSTOMER_USER_GROUP_RELATED,
  );

  // Billing info
  public static readonly BILLING_INFO_READ = Right.of(PermissionDefinitions.BILLING_INFO_READ);
  public static readonly BILLING_INFO_CREATE = Right.of(PermissionDefinitions.BILLING_INFO_CREATE);
  public static readonly BILLING_INFO_UPDATE = Right.of(PermissionDefinitions.BILLING_INFO_UPDATE);
  public static readonly BILLING_INFO_DISABLE = Right.of(PermissionDefinitions.BILLING_INFO_DISABLE);
}

export class RightModel {

  public readonly webAdminCustomers: GrantedPermissionSet;
  public readonly webAdminEmailAddressTypes: GrantedPermissionSet;
  public readonly webAdminTasks: GrantedPermissionSet;
  public readonly webAdminProjects: GrantedPermissionSet;
  public readonly webAdminMasterdata: GrantedPermissionSet;
  public readonly webAdminSurveys: GrantedPermissionSet;
  public readonly webAdminFormTables: GrantedPermissionSet;
  public readonly webAdminListItemTypes: GrantedPermissionSet;
  public readonly webAdminWorkflows: GrantedPermissionSet;
  public readonly webAdminLegacyWorkflows: GrantedPermissionSet;
  public readonly webAdminDocuments: GrantedPermissionSet;
  public readonly webAdminCompanies: GrantedPermissionSet;
  public readonly webAdminCompanyLocations: GrantedPermissionSet;
  public readonly webAdminCompanyStocks: GrantedPermissionSet;
  public readonly webAdminShopRenterShops: GrantedPermissionSet;
  public readonly webAdminApplicationFiles: GrantedPermissionSet;
  public readonly webAdminTableDocuments: GrantedPermissionSet;
  public readonly webAdminTableDocumentSchemas: GrantedPermissionSet;
  public readonly webAdminStockItems: GrantedPermissionSet;
  public readonly webAdminStockItemCategories: GrantedPermissionSet;
  public readonly webAdminStockChanges: GrantedPermissionSet;
  public readonly webAdminListItems: GrantedPermissionSet;
  public readonly webAdminVehicles: GrantedPermissionSet;
  public readonly webAdminPostOffices: GrantedPermissionSet;
  public readonly webAdminParcelCollectionPoints: GrantedPermissionSet;
  public readonly webAdminDeliveryMethods: GrantedPermissionSet;
  public readonly webAdminUsers: GrantedPermissionSet;
  public readonly webAdminUserGroups: GrantedPermissionSet;
  public readonly webAdminRoles: GrantedPermissionSet;
  public readonly webAdminEmailTemplates: GrantedPermissionSet;
  public readonly webAdminPdfTemplates: GrantedPermissionSet;
  public readonly webAdminMessageTemplates: GrantedPermissionSet;
  public readonly webAdminPrinterTemplates: GrantedPermissionSet;
  public readonly webAdminTaskRecordLogs: GrantedPermissionSet;
  public readonly webAdminTaskRecordStatistics: GrantedPermissionSet;
  public readonly webAdminWorklogs: GrantedPermissionSet;
  public readonly webAdminInvoiceStatistics: GrantedPermissionSet;
  public readonly webAdminOrderImportErrors: GrantedPermissionSet;
  public readonly webAdminMileageRecords: GrantedPermissionSet;
  public readonly webAdminAuditLogs: GrantedPermissionSet;
  public readonly webAdminEnvironmentConfiguration: GrantedPermissionSet;
  public readonly webAdminNotifications: GrantedPermissionSet;
  public readonly webAdminRegistrationSettings: GrantedPermissionSet;
  public readonly webAdminMobileApplications: GrantedPermissionSet;
  public readonly webAdminTokens: GrantedPermissionSet;
  public readonly webAdminInvoiceBook: GrantedPermissionSet;
  public readonly webAdminInvoiceProfiles: GrantedPermissionSet;
  public readonly webAdminLedgerNumbers: GrantedPermissionSet;
  public readonly webAdminInvoiceTags: GrantedPermissionSet;
  public readonly webAdminTransportTriggers: GrantedPermissionSet;
  public readonly webMenuAdmin: GrantedPermissionSet;
  public readonly webMenuDashboard: GrantedPermissionSet;
  public readonly webMenuTaskRecords: GrantedPermissionSet;
  public readonly webMenuTaskRecordBoard: GrantedPermissionSet;
  public readonly webMenuCustomerRecords: GrantedPermissionSet;
  public readonly webMenuMasterdataRecords: GrantedPermissionSet;
  public readonly webMenuSurveyRecords: GrantedPermissionSet;
  public readonly webMenuInvoices: GrantedPermissionSet;
  public readonly webMenuInvoiceSpendings: GrantedPermissionSet;
  public readonly webMenuCalendar: GrantedPermissionSet;
  public readonly webMenuGlobalCalendar: GrantedPermissionSet;
  public readonly webMenuProjects: GrantedPermissionSet;
  public readonly webMenuOrders: GrantedPermissionSet;
  public readonly webMenuLegacyProcesses: GrantedPermissionSet;
  public readonly webMenuProcesses: GrantedPermissionSet;
  public readonly webMenuStocks: GrantedPermissionSet;
  public readonly webMenuStockTakings: GrantedPermissionSet;
  public readonly webMenuShippingDemands: GrantedPermissionSet;
  public readonly webMenuShipments: GrantedPermissionSet;
  public readonly webMenuTransports: GrantedPermissionSet;
  public readonly webMenuExteriorTransports: GrantedPermissionSet;
  public readonly webMenuNotes: GrantedPermissionSet;
  public readonly webMenuDocuments: GrantedPermissionSet;
  public readonly webMenuDocumentLibrary: GrantedPermissionSet;

  public readonly appTypeRead: GrantedPermissionSet;

  public readonly apiUserKeyRead: GrantedPermissionSet;
  public readonly apiUserKeyRegenerate: GrantedPermissionSet;
  public readonly apiUserKeyInactivate: GrantedPermissionSet;
  public readonly apiUserKeyActivate: GrantedPermissionSet;

  public readonly userCreate: GrantedPermissionSet;
  public readonly userCreateAll: GrantedPermissionSet;
  public readonly userCreateMyCompany: GrantedPermissionSet;
  public readonly userDisable: GrantedPermissionSet;
  public readonly userRead: GrantedPermissionSet;
  public readonly userUpdate: GrantedPermissionSet;
  public readonly userUpdateAll: GrantedPermissionSet;
  public readonly userUpdateMyCompany: GrantedPermissionSet;
  public readonly userDelete: GrantedPermissionSet;
  public readonly userHistoryLog: GrantedPermissionSet;

  public readonly userChangePassword: GrantedPermissionSet;
  public readonly userChangePreviousPassword: GrantedPermissionSet;

  public readonly userImportFile: GrantedPermissionSet;
  public readonly userExportFile: GrantedPermissionSet;

  public readonly userLdapSync: GrantedPermissionSet;
  public readonly userSignatureDelete: GrantedPermissionSet;
  public readonly userSignatureRead: GrantedPermissionSet;
  public readonly userSignatureUpload: GrantedPermissionSet;

  public readonly userAttachmentDownloadFile: GrantedPermissionSet;

  public readonly appFileRead: GrantedPermissionSet;
  public readonly appFileCreate: GrantedPermissionSet;
  public readonly appFileUpdate: GrantedPermissionSet;
  public readonly appFileUpload: GrantedPermissionSet;
  public readonly appFileDelete: GrantedPermissionSet;
  public readonly appDictionaryUpdate: GrantedPermissionSet;

  public readonly auditLogRead: GrantedPermissionSet;

  public readonly mobileAppRead: GrantedPermissionSet;
  public readonly mobileAppDisable: GrantedPermissionSet;
  public readonly mobileAppChangeName: GrantedPermissionSet;

  public readonly userGroupRead: GrantedPermissionSet;
  public readonly userGroupCreate: GrantedPermissionSet;
  public readonly userGroupCreateAll: GrantedPermissionSet;
  public readonly userGroupCreateMyCompany: GrantedPermissionSet;
  public readonly userGroupUpdate: GrantedPermissionSet;
  public readonly userGroupUpdateAll: GrantedPermissionSet;
  public readonly userGroupUpdateMyCompany: GrantedPermissionSet;
  public readonly userGroupDisable: GrantedPermissionSet;
  public readonly userGroupDelete: GrantedPermissionSet;
  public readonly userGroupLdapChangeUserGroup: GrantedPermissionSet;
  public readonly userGroupLdapSync: GrantedPermissionSet;
  public readonly userGroupLdapRead: GrantedPermissionSet;
  public readonly userGroupLdapMapRead: GrantedPermissionSet;
  public readonly userGroupLdapMapUpdate: GrantedPermissionSet;
  public readonly userGroupHistoryLog: GrantedPermissionSet;

  public readonly masterDataRead: GrantedPermissionSet;
  public readonly masterDataCreate: GrantedPermissionSet;
  public readonly masterDataDisable: GrantedPermissionSet;
  public readonly masterDataUpdate: GrantedPermissionSet;

  public readonly masterDataRecordRead: GrantedPermissionSet;
  public readonly masterDataRecordCreate: GrantedPermissionSet;
  public readonly masterDataRecordDisable: GrantedPermissionSet;
  public readonly masterDataRecordUpdate: GrantedPermissionSet;
  public readonly masterDataRecordImportFile: GrantedPermissionSet;
  public readonly masterDataRecordExportFile: GrantedPermissionSet;

  public readonly masterDataRecordAttachmentRead: GrantedPermissionSet;
  public readonly masterDataRecordAttachmentUpload: GrantedPermissionSet;
  public readonly masterDataRecordAttachmentDelete: GrantedPermissionSet;
  public readonly masterDataRecordAttachmentUpdate: GrantedPermissionSet;

  public readonly documentGroupRead: GrantedPermissionSet;
  public readonly documentGroupUpdate: GrantedPermissionSet;
  public readonly documentGroupCreate: GrantedPermissionSet;
  public readonly documentGroupDisable: GrantedPermissionSet;

  public readonly surveyRead: GrantedPermissionSet;
  public readonly surveyCreate: GrantedPermissionSet;
  public readonly surveyUpdate: GrantedPermissionSet;
  public readonly surveyDisable: GrantedPermissionSet;

  public readonly surveyFormRead: GrantedPermissionSet;
  public readonly surveyFormGroupRead: GrantedPermissionSet;
  public readonly surveyFormFieldRead: GrantedPermissionSet;
  public readonly surveyFormGroupUpdate: GrantedPermissionSet;
  public readonly surveyFormFieldUpdate: GrantedPermissionSet;
  public readonly surveyFormGroupCreate: GrantedPermissionSet;
  public readonly surveyFormFieldCreate: GrantedPermissionSet;
  public readonly surveyFormGroupDisable: GrantedPermissionSet;
  public readonly surveyFormFieldDisable: GrantedPermissionSet;
  public readonly surveyFormGroupMove: GrantedPermissionSet;
  public readonly surveyFormFieldMove: GrantedPermissionSet;

  public readonly surveyRecordRead: GrantedPermissionSet;
  public readonly surveyRecordCreate: GrantedPermissionSet;
  public readonly surveyRecordUpdate: GrantedPermissionSet;
  public readonly surveyRecordDisable: GrantedPermissionSet;
  public readonly formTableRead: GrantedPermissionSet;
  public readonly formTableCreate: GrantedPermissionSet;
  public readonly formTableUpdate: GrantedPermissionSet;
  public readonly formTableDisable: GrantedPermissionSet;

  public readonly stockItemRead: GrantedPermissionSet;
  public readonly stockItemCreate: GrantedPermissionSet;
  public readonly stockItemUpdate: GrantedPermissionSet;
  public readonly stockItemDisable: GrantedPermissionSet;
  public readonly stockItemDisableBulk: GrantedPermissionSet;
  public readonly stockItemMove: GrantedPermissionSet;
  public readonly stockItemDispose: GrantedPermissionSet;
  public readonly stockItemImportFile: GrantedPermissionSet;
  public readonly stockItemExportFile: GrantedPermissionSet;
  public readonly stockRead: GrantedPermissionSet;
  public readonly stockCreate: GrantedPermissionSet;
  public readonly stockUpdate: GrantedPermissionSet;
  public readonly stockDisable: GrantedPermissionSet;
  public readonly stockRecordRead: GrantedPermissionSet;
  public readonly stockRecordImportFile: GrantedPermissionSet;
  public readonly stockRecordCreate: GrantedPermissionSet;
  public readonly stockRecordUpdate: GrantedPermissionSet;
  public readonly stockRecordLogRead: GrantedPermissionSet;

  public readonly listItemRead: GrantedPermissionSet;
  public readonly listItemCreate: GrantedPermissionSet;
  public readonly listItemUpdate: GrantedPermissionSet;
  public readonly listItemDisable: GrantedPermissionSet;
  public readonly listItemImportXls: GrantedPermissionSet;
  public readonly listItemTypeRead: GrantedPermissionSet;
  public readonly listItemTypeCreate: GrantedPermissionSet;
  public readonly listItemTypeUpdate: GrantedPermissionSet;
  public readonly listItemTypeDisable: GrantedPermissionSet;

  // Task
  public readonly taskRead: GrantedPermissionSet;
  public readonly taskFormImport: GrantedPermissionSet;
  public readonly taskFormExport: GrantedPermissionSet;
  public readonly taskCreate: GrantedPermissionSet;
  public readonly taskUpdate: GrantedPermissionSet;
  public readonly taskDisable: GrantedPermissionSet;
  public readonly taskRecordRead: GrantedPermissionSet;
  public readonly taskRecordUpdate: GrantedPermissionSet;
  public readonly taskRecordUpdateQuick: GrantedPermissionSet;
  public readonly taskRecordChangeDeadline: GrantedPermissionSet;
  public readonly taskRecordChangeAgreedTime: GrantedPermissionSet;
  public readonly taskRecordChangeAssignee: GrantedPermissionSet;
  public readonly taskRecordChangeAssigneeInProgress: GrantedPermissionSet;
  public readonly taskRecordChangeImportance: GrantedPermissionSet;
  public readonly taskRecordCheckBulkUpdate: GrantedPermissionSet;
  public readonly taskRecordCheckBulkGeocode: GrantedPermissionSet;
  public readonly taskRecordGeocode: GrantedPermissionSet;
  public readonly taskRecordImportFile: GrantedPermissionSet;
  public readonly taskRecordExportFile: GrantedPermissionSet;
  public readonly taskRecordExportFileCustom: GrantedPermissionSet;
  public readonly taskRecordExportApi: GrantedPermissionSet;
  public readonly taskRecordRevert: GrantedPermissionSet;
  public readonly taskRecordChangeStateOpen: GrantedPermissionSet;
  public readonly taskRecordChangeStateReject: GrantedPermissionSet;
  public readonly taskRecordChangeStateInProgress: GrantedPermissionSet;
  public readonly taskRecordChangeStateSubmit: GrantedPermissionSet;
  public readonly taskRecordChangeStateRevert: GrantedPermissionSet;
  public readonly taskRecordChangeStateValidate: GrantedPermissionSet;
  public readonly taskRecordChangeStateArchive: GrantedPermissionSet;
  public readonly taskRecordChangeStateRecall: GrantedPermissionSet;
  public readonly taskRecordChangeStateRestart: GrantedPermissionSet;
  public readonly taskRecordChangeStateUnsubmit: GrantedPermissionSet;
  public readonly taskRecordChangeStateStart: GrantedPermissionSet;
  public readonly taskRecordChangeStateReopen: GrantedPermissionSet;
  public readonly taskRecordChangeStatePause: GrantedPermissionSet;
  public readonly taskRecordChangeConfirm: GrantedPermissionSet;
  public readonly taskRecordLogRead: GrantedPermissionSet;
  public readonly taskRecordDocumentRead: GrantedPermissionSet;
  public readonly taskRecordCreate: GrantedPermissionSet;

  public readonly taskRecordAttachmentReadList: GrantedPermissionSet;
  public readonly taskRecordAttachmentUploadFile: GrantedPermissionSet;
  public readonly taskRecordAttachmentUpdateFile: GrantedPermissionSet;
  public readonly taskRecordAttachmentDeleteFile: GrantedPermissionSet;

  public readonly customerRecordAttachmentReadList: GrantedPermissionSet;
  public readonly customerRecordAttachmentUploadFile: GrantedPermissionSet;
  public readonly customerRecordAttachmentDeleteFile: GrantedPermissionSet;
  public readonly customerRecordAttachmentUpdate: GrantedPermissionSet;

  public readonly taskRecordManualInvoiceCreate: GrantedPermissionSet;

  // Customer
  public readonly customerRead: GrantedPermissionSet;
  public readonly customerCreate: GrantedPermissionSet;
  public readonly customerDisable: GrantedPermissionSet;
  public readonly customerUpdate: GrantedPermissionSet;

  public readonly customerRecordCreate: GrantedPermissionSet;
  public readonly customerRecordImportFile: GrantedPermissionSet;
  public readonly customerRecordExportFile: GrantedPermissionSet;
  public readonly customerRecordRead: GrantedPermissionSet;
  public readonly customerRecordDisable: GrantedPermissionSet;
  public readonly customerRecordUpdate: GrantedPermissionSet;
  public readonly customerRecordChangeOwner: GrantedPermissionSet;
  public readonly customerRecordHistoryLog: GrantedPermissionSet;

  public readonly customerFormRead: GrantedPermissionSet;

  public readonly documentRead: GrantedPermissionSet;

  public readonly documentFileRead: GrantedPermissionSet;
  public readonly documentFileUpdate: GrantedPermissionSet;
  public readonly documentFileFileUpdate: GrantedPermissionSet;
  public readonly documentFileFileCreate: GrantedPermissionSet;
  public readonly documentFileFileDownload: GrantedPermissionSet;
  public readonly documentFileThumbnailDownload: GrantedPermissionSet;
  public readonly documentFileThumbnailUpdate: GrantedPermissionSet;

  public readonly documentHtmlRead: GrantedPermissionSet;
  public readonly documentHtmlUpdate: GrantedPermissionSet;
  public readonly documentHtmlDisable: GrantedPermissionSet;

  public readonly documentLinkRead: GrantedPermissionSet;
  public readonly documentLinkUpdate: GrantedPermissionSet;
  public readonly documentLinkDisable: GrantedPermissionSet;
  public readonly documentLinkCreate: GrantedPermissionSet;

  public readonly masterDataFormRead: GrantedPermissionSet;

  public readonly documentTextRead: GrantedPermissionSet;

  public readonly messageRead: GrantedPermissionSet;
  public readonly messageChangeRead: GrantedPermissionSet;
  public readonly messageDelete: GrantedPermissionSet;
  public readonly messageDisable: GrantedPermissionSet;

  public readonly messageParentRead: GrantedPermissionSet;
  public readonly messageParentCreate: GrantedPermissionSet;

  public readonly noteRead: GrantedPermissionSet;
  public readonly noteUpdate: GrantedPermissionSet;
  public readonly noteCreate: GrantedPermissionSet;
  public readonly noteDisable: GrantedPermissionSet;

  // Project record
  public readonly projectRecordRead: GrantedPermissionSet;
  public readonly projectRecordCreate: GrantedPermissionSet;
  public readonly projectRecordUpdate: GrantedPermissionSet;
  public readonly projectRecordDisable: GrantedPermissionSet;
  public readonly projectRecordExport: GrantedPermissionSet;
  public readonly projectRecordImport: GrantedPermissionSet;
  public readonly projectRecordAttachmentRead: GrantedPermissionSet;
  public readonly projectRecordAttachmentDownloadFile: GrantedPermissionSet;
  public readonly projectRecordAttachmentUploadFile: GrantedPermissionSet;
  public readonly projectRecordAttachmentDelete: GrantedPermissionSet;

  // Project
  public readonly projectRead: GrantedPermissionSet;
  public readonly projectCreate: GrantedPermissionSet;
  public readonly projectUpdate: GrantedPermissionSet;
  public readonly projectDisable: GrantedPermissionSet;

  public readonly projectFormRead: GrantedPermissionSet;
  public readonly projectFormCreate: GrantedPermissionSet;
  public readonly projectFormUpdate: GrantedPermissionSet;
  public readonly projectFormDisable: GrantedPermissionSet;
  public readonly projectFormExport: GrantedPermissionSet;
  public readonly projectFormImport: GrantedPermissionSet;

  public readonly surveyAttachmentDownloadFile: GrantedPermissionSet;
  public readonly surveyAttachmentDeleteFile: GrantedPermissionSet;

  // Invoice
  public readonly invoiceSettingsRead: GrantedPermissionSet;
  public readonly invoiceSettingsCreate: GrantedPermissionSet;
  public readonly invoiceSettingUpdate: GrantedPermissionSet;
  public readonly invoiceSettingDisable: GrantedPermissionSet;
  public readonly invoiceBookRead: GrantedPermissionSet;
  public readonly invoiceBookCreate: GrantedPermissionSet;
  public readonly invoiceBookUpdate: GrantedPermissionSet;
  public readonly invoiceBookDisable: GrantedPermissionSet;
  public readonly invoiceCreate: GrantedPermissionSet;
  public readonly invoiceRead: GrantedPermissionSet;
  public readonly invoiceStorno: GrantedPermissionSet;
  public readonly invoiceAction: GrantedPermissionSet;
  public readonly invoiceBalanceRead: GrantedPermissionSet;
  public readonly invoiceBalanceCreate: GrantedPermissionSet;
  public readonly invoiceBalanceDelete: GrantedPermissionSet;
  public readonly invoiceSpendingRead: GrantedPermissionSet;
  public readonly invoiceSpendingCreate: GrantedPermissionSet;
  public readonly ledgerNumberRead: GrantedPermissionSet;
  public readonly ledgerNumberCreate: GrantedPermissionSet;
  public readonly ledgerNumberUpdate: GrantedPermissionSet;
  public readonly ledgerNumberDisable: GrantedPermissionSet;
  public readonly invoiceTagRead: GrantedPermissionSet;
  public readonly invoiceTagCreate: GrantedPermissionSet;
  public readonly invoiceTagUpdate: GrantedPermissionSet;
  public readonly invoiceTagDisable: GrantedPermissionSet;

  public readonly taskLogStatRead: GrantedPermissionSet;
  public readonly taskInvoiceStatRead: GrantedPermissionSet;
  public readonly contractNumberRead: GrantedPermissionSet;

  // Workflow
  public readonly workflowCreate: GrantedPermissionSet;
  public readonly workflowRead: GrantedPermissionSet;
  public readonly workflowUpdate: GrantedPermissionSet;
  public readonly workflowDelete: GrantedPermissionSet;
  public readonly workflowActivate: GrantedPermissionSet;
  public readonly workflowFinalize: GrantedPermissionSet;
  public readonly workflowCreateNewVersion: GrantedPermissionSet;

  // Process
  public readonly processRead: GrantedPermissionSet;
  public readonly processCreate: GrantedPermissionSet;
  public readonly processUpdate: GrantedPermissionSet;
  public readonly processChatMessageDelete: GrantedPermissionSet;

  // LegacyWorkflow
  public readonly legacyWorkflowRead: GrantedPermissionSet;
  public readonly legacyWorkflowCreate: GrantedPermissionSet;
  public readonly legacyWorkflowUpdate: GrantedPermissionSet;
  public readonly legacyWorkflowDisable: GrantedPermissionSet;

  // LegacyProcess
  public readonly legacyProcessRead: GrantedPermissionSet;
  public readonly legacyProcessCreate: GrantedPermissionSet;
  public readonly legacyProcessUpdate: GrantedPermissionSet;
  public readonly legacyProcessChangeState: GrantedPermissionSet;

  // LegacyProcessTask
  public readonly legacyProcessTaskRead: GrantedPermissionSet;
  public readonly legacyProcessTaskUpdate: GrantedPermissionSet;
  public readonly legacyProcessTaskChangeAssignee: GrantedPermissionSet;
  public readonly legacyProcessTaskChangeState: GrantedPermissionSet;

  // Order
  public readonly orderCreate: GrantedPermissionSet;
  public readonly orderRead: GrantedPermissionSet;
  public readonly orderUpdate: GrantedPermissionSet;
  public readonly orderSubmit: GrantedPermissionSet;
  public readonly orderCancel: GrantedPermissionSet;

  // Order note
  public readonly orderNoteExtraRead: GrantedPermissionSet;
  public readonly orderNoteExtraReadAll: GrantedPermissionSet;
  public readonly orderNoteExtraReadMine: GrantedPermissionSet;
  public readonly orderNoteExtraReadMyCustomer: GrantedPermissionSet;
  public readonly orderNoteExtraUpdate: GrantedPermissionSet;
  public readonly orderNoteExtraUpdateAll: GrantedPermissionSet;
  public readonly orderNoteExtraUpdateMine: GrantedPermissionSet;
  public readonly orderNoteExtraUpdateMyCustomer: GrantedPermissionSet;
  public readonly orderNoteInternalRead: GrantedPermissionSet;
  public readonly orderNoteInternalReadAll: GrantedPermissionSet;
  public readonly orderNoteInternalReadMine: GrantedPermissionSet;
  public readonly orderNoteInternalReadMyCustomer: GrantedPermissionSet;
  public readonly orderNoteInternalUpdate: GrantedPermissionSet;
  public readonly orderNoteInternalUpdateAll: GrantedPermissionSet;
  public readonly orderNoteInternalUpdateMine: GrantedPermissionSet;
  public readonly orderNoteInternalUpdateMyCustomer: GrantedPermissionSet;

  // Task Record Chat
  public readonly taskRecordChatRead: GrantedPermissionSet;
  public readonly taskRecordChatMessageCreate: GrantedPermissionSet;
  public readonly taskRecordChatMessageDelete: GrantedPermissionSet;

  public readonly stockTakingRead: GrantedPermissionSet;
  public readonly stockTakingCreate: GrantedPermissionSet;
  public readonly stockTakingUpdate: GrantedPermissionSet;
  public readonly stockTakingSheetRead: GrantedPermissionSet;
  public readonly stockTakingSheetCreate: GrantedPermissionSet;
  public readonly stockTakingSheetUpdate: GrantedPermissionSet;
  public readonly stockTakingSheetUpdateAssignee: GrantedPermissionSet;
  public readonly stockTakingSheetDelete: GrantedPermissionSet;

  // Calendar
  public readonly calendarEventCreate: GrantedPermissionSet;
  public readonly calendarEventRead: GrantedPermissionSet;
  public readonly calendarEventUpdate: GrantedPermissionSet;
  public readonly calendarEventDelete: GrantedPermissionSet;

  // GlobalCalendar
  public readonly globalCalendarEventCreate: GrantedPermissionSet;
  public readonly globalCalendarEventRead: GrantedPermissionSet;
  public readonly globalCalendarEventUpdate: GrantedPermissionSet;
  public readonly globalCalendarEventDelete: GrantedPermissionSet;

  // Role
  public readonly roleRead: GrantedPermissionSet;
  public readonly roleCreate: GrantedPermissionSet;
  public readonly roleUpdate: GrantedPermissionSet;
  public readonly roleDisable: GrantedPermissionSet;
  public readonly roleHistoryLog: GrantedPermissionSet;

  // Order note
  public readonly orderImportMplReturn: GrantedPermissionSet;
  public readonly orderImportMplFinalStatus: GrantedPermissionSet;
  public readonly orderImportXlsx: GrantedPermissionSet;

  // Company
  public readonly companyRead: GrantedPermissionSet;
  public readonly companyReadAll: GrantedPermissionSet;
  public readonly companyReadMine: GrantedPermissionSet;
  public readonly companyReadMyCompanyRelated: GrantedPermissionSet;
  public readonly companyUpdate: GrantedPermissionSet;
  public readonly companyUpdateAll: GrantedPermissionSet;
  public readonly companyUpdateMine: GrantedPermissionSet;
  public readonly companyCreate: GrantedPermissionSet;
  public readonly companyRolesUpdate: GrantedPermissionSet;
  public readonly companyDisable: GrantedPermissionSet;
  public readonly companyHistoryLog: GrantedPermissionSet;

  // Vehicle
  public readonly vehicleRead: GrantedPermissionSet;
  public readonly vehicleCreate: GrantedPermissionSet;
  public readonly vehicleUpdate: GrantedPermissionSet;
  public readonly vehicleDisable: GrantedPermissionSet;
  public readonly vehicleHistoryLog: GrantedPermissionSet;

  // CompanyLocation
  public readonly companyLocationRead: GrantedPermissionSet;
  public readonly companyLocationCreate: GrantedPermissionSet;
  public readonly companyLocationUpdate: GrantedPermissionSet;
  public readonly companyLocationDisable: GrantedPermissionSet;
  public readonly companyLocationHistoryLog: GrantedPermissionSet;

  // CompanyStockStock
  public readonly companyStockRead: GrantedPermissionSet;
  public readonly companyStockCreate: GrantedPermissionSet;
  public readonly companyStockUpdate: GrantedPermissionSet;
  public readonly companyStockDisable: GrantedPermissionSet;
  public readonly companyStockHistoryLog: GrantedPermissionSet;

  // Transport
  public readonly transportRead: GrantedPermissionSet;
  public readonly transportCreate: GrantedPermissionSet;
  public readonly transportUpdate: GrantedPermissionSet;
  public readonly transportDelete: GrantedPermissionSet;
  public readonly transportUse: GrantedPermissionSet;
  public readonly transportSetSecurityGuard: GrantedPermissionSet;
  public readonly transportSetDriver: GrantedPermissionSet;
  public readonly transportSetVehicle: GrantedPermissionSet;

  // Shipping Demand
  public readonly shippingDemandRead: GrantedPermissionSet;
  public readonly shippingDemandReadSimple: GrantedPermissionSet;
  public readonly shippingDemandCreate: GrantedPermissionSet;
  public readonly shippingDemandXlsImport: GrantedPermissionSet;
  public readonly shippingDemandCreateSimple: GrantedPermissionSet;
  public readonly shippingDemandUpdate: GrantedPermissionSet;
  public readonly shippingDemandUpdateSimple: GrantedPermissionSet;
  public readonly shippingDemandCancel: GrantedPermissionSet;
  public readonly shippingDemandSplit: GrantedPermissionSet;

  // Client document
  public readonly clientDocumentRead: GrantedPermissionSet;
  public readonly clientDocumentCreate: GrantedPermissionSet;
  public readonly clientDocumentUpdate: GrantedPermissionSet;
  public readonly clientDocumentSubmit: GrantedPermissionSet;
  public readonly clientDocumentDelete: GrantedPermissionSet;

  // Email template
  public readonly emailTemplateRead: GrantedPermissionSet;
  public readonly emailTemplateCreate: GrantedPermissionSet;
  public readonly emailTemplateUpdate: GrantedPermissionSet;
  public readonly emailTemplateDisable: GrantedPermissionSet;

  // Table document schema
  public readonly tableDocumentSchemaRead: GrantedPermissionSet;
  public readonly tableDocumentSchemaCreate: GrantedPermissionSet;
  public readonly tableDocumentSchemaCreateNext: GrantedPermissionSet;
  public readonly tableDocumentSchemaDeprecate: GrantedPermissionSet;
  public readonly tableDocumentSchemaUpdate: GrantedPermissionSet;
  public readonly tableDocumentSchemaDelete: GrantedPermissionSet;
  public readonly tableDocumentSchemaFinalize: GrantedPermissionSet;

  // Table document
  public readonly tableDocumentRead: GrantedPermissionSet;
  public readonly tableDocumentCreate: GrantedPermissionSet;
  public readonly tableDocumentUpdate: GrantedPermissionSet;
  public readonly tableDocumentDelete: GrantedPermissionSet;
  public readonly tableDocumentExport: GrantedPermissionSet;
  public readonly tableDocumentReplace: GrantedPermissionSet;

  // General Pdf Template
  public readonly generalPdfTemplateRead: GrantedPermissionSet;
  public readonly generalPdfTemplateUpload: GrantedPermissionSet;
  public readonly generalPdfTemplateDisable: GrantedPermissionSet;

  // Token
  public readonly tokenRead: GrantedPermissionSet;
  public readonly tokenDelete: GrantedPermissionSet;

  // NFC card
  public readonly userNfcCardRead: GrantedPermissionSet;
  public readonly userNfcCardCreate: GrantedPermissionSet;
  public readonly userNfcCardDelete: GrantedPermissionSet;

  // Stock Item Category
  public readonly stockItemCategoryRead: GrantedPermissionSet;
  public readonly stockItemCategoryCreate: GrantedPermissionSet;
  public readonly stockItemCategoryUpdate: GrantedPermissionSet;
  public readonly stockItemCategoryDelete: GrantedPermissionSet;

  // Environment config
  public readonly environmentConfigRead: GrantedPermissionSet;
  public readonly environmentConfigUpdate: GrantedPermissionSet;

  // Registration settings
  public readonly registrationSettingsRead: GrantedPermissionSet;
  public readonly registrationSettingsUpdate: GrantedPermissionSet;

  // Message template
  public readonly messageTemplateRead: GrantedPermissionSet;
  public readonly messageTemplateCreate: GrantedPermissionSet;
  public readonly messageTemplateUpdate: GrantedPermissionSet;
  public readonly messageTemplateDisable: GrantedPermissionSet;

  // General Printer Template
  public readonly generalPrinterTemplateRead: GrantedPermissionSet;
  public readonly generalPrinterTemplateUpload: GrantedPermissionSet;
  public readonly generalPrinterTemplateDisable: GrantedPermissionSet;

  public readonly storedDqlRead: GrantedPermissionSet;
  public readonly storedDqlUpdate: GrantedPermissionSet;
  public readonly storedDqlDelete: GrantedPermissionSet;
  public readonly storedDqlCreate: GrantedPermissionSet;

  // Transport trigger
  public readonly transportTriggerRead: GrantedPermissionSet;
  public readonly transportTriggerDisable: GrantedPermissionSet;
  public readonly transportTriggerDelete: GrantedPermissionSet;
  public readonly transportTriggerCreate: GrantedPermissionSet;
  public readonly transportTriggerUpdate: GrantedPermissionSet;

  // Transport trigger instance
  public readonly transportTriggerInstanceRead: GrantedPermissionSet;

  // TaskTrigger
  public readonly taskTriggerRead: GrantedPermissionSet;
  public readonly taskTriggerDisable: GrantedPermissionSet;
  public readonly taskTriggerDelete: GrantedPermissionSet;
  public readonly taskTriggerCreate: GrantedPermissionSet;
  public readonly taskTriggerUpdate: GrantedPermissionSet;

  // TaskTriggerInstance
  public readonly taskTriggerInstanceRead: GrantedPermissionSet;

  // TaskRecordSubTask
  public readonly taskRecordSubTaskRead: GrantedPermissionSet;
  public readonly taskRecordSubTaskCreate: GrantedPermissionSet;

  // OrderDocument
  public readonly orderDocumentCreate: GrantedPermissionSet;
  public readonly orderDocumentRead: GrantedPermissionSet;
  public readonly orderDocumentDownload: GrantedPermissionSet;
  public readonly orderDocumentUpdateMeta: GrantedPermissionSet;
  public readonly orderDocumentDelete: GrantedPermissionSet;

  // Shipment
  public readonly shipmentCreate: GrantedPermissionSet;
  public readonly shipmentChangeSubTransporter: GrantedPermissionSet;
  public readonly shipmentGroupRead: GrantedPermissionSet;
  public readonly shipmentGroupShippingDemandRemove: GrantedPermissionSet;
  public readonly shipmentGroupDepotUpdate: GrantedPermissionSet;

  // ExteriorTransport
  public readonly exteriorTransportCreate: GrantedPermissionSet;
  public readonly exteriorTransportRead: GrantedPermissionSet;
  public readonly exteriorTransportStart: GrantedPermissionSet;
  public readonly exteriorTransportFinish: GrantedPermissionSet;
  public readonly exteriorTransportShipmentAdd: GrantedPermissionSet;
  public readonly exteriorTransportShipmentRemove: GrantedPermissionSet;

  // ExteriorTransportDocument
  public readonly exteriorTransportDocumentRead: GrantedPermissionSet;
  public readonly exteriorTransportDocumentCreate: GrantedPermissionSet;
  public readonly exteriorTransportDocumentDelete: GrantedPermissionSet;
  public readonly exteriorTransportDocumentDownload: GrantedPermissionSet;
  public readonly exteriorTransportDocumentMetaUpdate: GrantedPermissionSet;

  // ShopRenterShop
  public readonly shopRenterShopRead: GrantedPermissionSet;
  public readonly shopRenterShopUpdate: GrantedPermissionSet;
  public readonly shopRenterShopSync: GrantedPermissionSet;
  public readonly shopRenterShopImportProducts: GrantedPermissionSet;

  // Worklog
  public readonly worklogRead: GrantedPermissionSet;
  public readonly worklogUpdate: GrantedPermissionSet;
  public readonly worklogCreate: GrantedPermissionSet;

  // MileageRecord
  public readonly mileageRecordRead: GrantedPermissionSet;
  public readonly mileageRecordCreate: GrantedPermissionSet;
  public readonly mileageRecordUpdate: GrantedPermissionSet;

  // OrderImportError
  public readonly orderImportErrorRead: GrantedPermissionSet;
  public readonly orderImportErrorRejectManually: GrantedPermissionSet;

  // StockChange
  public readonly stockChangeRead: GrantedPermissionSet;

  // Post office
  public readonly postOfficeRead: GrantedPermissionSet;
  public readonly postOfficeImport: GrantedPermissionSet;

  // StockItemCustomerRecordPrice
  public readonly stockItemCustomerRecordPriceRead: GrantedPermissionSet;
  public readonly stockItemCustomerRecordPriceCreate: GrantedPermissionSet;
  public readonly stockItemCustomerRecordPriceUpdate: GrantedPermissionSet;
  public readonly stockItemCustomerRecordPriceDelete: GrantedPermissionSet;

  // ParcelCollectionPoint
  public readonly parcelCollectionPointRead: GrantedPermissionSet;
  public readonly parcelCollectionPointCreate: GrantedPermissionSet;
  public readonly parcelCollectionPointUpdate: GrantedPermissionSet;
  public readonly parcelCollectionPointDisable: GrantedPermissionSet;
  public readonly parcelCollectionPointImport: GrantedPermissionSet;

  // DeliveryMethod
  public readonly deliveryMethodRead: GrantedPermissionSet;
  public readonly deliveryMethodCreate: GrantedPermissionSet;
  public readonly deliveryMethodUpdate: GrantedPermissionSet;
  public readonly deliveryMethodDisable: GrantedPermissionSet;

  // EmailAddressType
  public readonly emailAddressTypeCreate: GrantedPermissionSet;
  public readonly emailAddressTypeUpdate: GrantedPermissionSet;
  public readonly emailAddressTypeDisable: GrantedPermissionSet;

  // Colonnade
  public readonly colonnadeB2bRead: GrantedPermissionSet;
  public readonly colonnadeB2bReadAll: GrantedPermissionSet;
  public readonly colonnadeB2bReadMine: GrantedPermissionSet;

  // Shipment Outtake
  public readonly shipmentOuttakeStorekeeperChange: GrantedPermissionSet;
  public readonly shipmentOuttakeReopenForce: GrantedPermissionSet;

  public readonly androidAppVersionHandle: GrantedPermissionSet;

  // Helpdesk
  public readonly customerRecordHelpdeskAction: GrantedPermissionSet;

  // Task record bulk clone
  public readonly taskRecordCreateClone: GrantedPermissionSet;

  // Billing info
  public readonly billingInfoRead: GrantedPermissionSet;
  public readonly billingInfoCreate: GrantedPermissionSet;
  public readonly billingInfoUpdate: GrantedPermissionSet;
  public readonly billingInfoDisable: GrantedPermissionSet;

  public static empty() {
    return new RightModel();
  }

  public static of(resolver: RightResolver) {
    return new RightModel(resolver);
  }

  private static map(right: Right | RightDefinition, resolver?: RightResolver): GrantedPermissionSet {
    if (resolver) {
      return resolver.getGrantedPermissions(right);
    }
    else {
      return GrantedPermissionSet.empty();
    }
  }

  private constructor(resolver?: RightResolver) {

    this.webAdminCustomers = RightModel.map(Rights.WEB_ADMIN_CUSTOMERS, resolver);
    this.webAdminEmailAddressTypes = RightModel.map(Rights.WEB_ADMIN_EMAIL_ADDRESS_TYPES, resolver);
    this.webAdminTasks = RightModel.map(Rights.WEB_ADMIN_TASKS, resolver);
    this.webAdminProjects = RightModel.map(Rights.WEB_ADMIN_PROJECTS, resolver);
    this.webAdminMasterdata = RightModel.map(Rights.WEB_ADMIN_MASTERDATA, resolver);
    this.webAdminSurveys = RightModel.map(Rights.WEB_ADMIN_SURVEYS, resolver);
    this.webAdminFormTables = RightModel.map(Rights.WEB_ADMIN_FORM_TABLES, resolver);
    this.webAdminListItemTypes = RightModel.map(Rights.WEB_ADMIN_LIST_ITEM_TYPES, resolver);
    this.webAdminWorkflows = RightModel.map(Rights.WEB_ADMIN_WORKFLOWS, resolver);
    this.webAdminLegacyWorkflows = RightModel.map(Rights.WEB_ADMIN_LEGACY_WORKFLOWS, resolver);
    this.webAdminDocuments = RightModel.map(Rights.WEB_ADMIN_DOCUMENTS, resolver);
    this.webAdminCompanies = RightModel.map(Rights.WEB_ADMIN_COMPANIES, resolver);
    this.webAdminCompanyLocations = RightModel.map(Rights.WEB_ADMIN_COMPANY_LOCATIONS, resolver);
    this.webAdminCompanyStocks = RightModel.map(Rights.WEB_ADMIN_COMPANY_STOCKS, resolver);
    this.webAdminShopRenterShops = RightModel.map(Rights.WEB_ADMIN_SHOP_RENTER_SHOPS, resolver);
    this.webAdminApplicationFiles = RightModel.map(Rights.WEB_ADMIN_APPLICATION_FILES, resolver);
    this.webAdminTableDocuments = RightModel.map(Rights.WEB_ADMIN_TABLE_DOCUMENTS, resolver);
    this.webAdminTableDocumentSchemas = RightModel.map(Rights.WEB_ADMIN_TABLE_DOCUMENT_SCHEMAS, resolver);
    this.webAdminStockItems = RightModel.map(Rights.WEB_ADMIN_STOCK_ITEMS, resolver);
    this.webAdminStockItemCategories = RightModel.map(Rights.WEB_ADMIN_STOCK_ITEM_CATEGORIES, resolver);
    this.webAdminStockChanges = RightModel.map(Rights.WEB_ADMIN_STOCK_CHANGES, resolver);
    this.webAdminListItems = RightModel.map(Rights.WEB_ADMIN_LIST_ITEMS, resolver);
    this.webAdminVehicles = RightModel.map(Rights.WEB_ADMIN_VEHICLES, resolver);
    this.webAdminPostOffices = RightModel.map(Rights.WEB_ADMIN_POST_OFFICES, resolver);
    this.webAdminParcelCollectionPoints = RightModel.map(Rights.WEB_ADMIN_PARCEL_COLLECTION_POINTS, resolver);
    this.webAdminDeliveryMethods = RightModel.map(Rights.WEB_ADMIN_DELIVERY_METHODS, resolver);
    this.webAdminUsers = RightModel.map(Rights.WEB_ADMIN_USERS, resolver);
    this.webAdminUserGroups = RightModel.map(Rights.WEB_ADMIN_USER_GROUPS, resolver);
    this.webAdminRoles = RightModel.map(Rights.WEB_ADMIN_ROLES, resolver);
    this.webAdminEmailTemplates = RightModel.map(Rights.WEB_ADMIN_EMAIL_TEMPLATES, resolver);
    this.webAdminPdfTemplates = RightModel.map(Rights.WEB_ADMIN_PDF_TEMPLATES, resolver);
    this.webAdminMessageTemplates = RightModel.map(Rights.WEB_ADMIN_MESSAGE_TEMPLATES, resolver);
    this.webAdminPrinterTemplates = RightModel.map(Rights.WEB_ADMIN_PRINTER_TEMPLATES, resolver);
    this.webAdminTaskRecordLogs = RightModel.map(Rights.WEB_ADMIN_TASK_RECORD_LOGS, resolver);
    this.webAdminTaskRecordStatistics = RightModel.map(Rights.WEB_ADMIN_TASK_RECORD_STATISTICS, resolver);
    this.webAdminWorklogs = RightModel.map(Rights.WEB_ADMIN_WORKLOGS, resolver);
    this.webAdminInvoiceStatistics = RightModel.map(Rights.WEB_ADMIN_INVOICE_STATISTICS, resolver);
    this.webAdminOrderImportErrors = RightModel.map(Rights.WEB_ADMIN_ORDER_IMPORT_ERRORS, resolver);
    this.webAdminMileageRecords = RightModel.map(Rights.WEB_ADMIN_MILEAGE_RECORDS, resolver);
    this.webAdminAuditLogs = RightModel.map(Rights.WEB_ADMIN_AUDIT_LOGS, resolver);
    this.webAdminEnvironmentConfiguration = RightModel.map(Rights.WEB_ADMIN_ENVIRONMENT_CONFIGURATION, resolver);
    this.webAdminNotifications = RightModel.map(Rights.WEB_ADMIN_NOTIFICATIONS, resolver);
    this.webAdminRegistrationSettings = RightModel.map(Rights.WEB_ADMIN_REGISTRATION_SETTINGS, resolver);
    this.webAdminMobileApplications = RightModel.map(Rights.WEB_ADMIN_MOBILE_APPLICATIONS, resolver);
    this.webAdminTokens = RightModel.map(Rights.WEB_ADMIN_TOKENS, resolver);
    this.webAdminInvoiceBook = RightModel.map(Rights.WEB_ADMIN_INVOICE_BOOK, resolver);
    this.webAdminInvoiceProfiles = RightModel.map(Rights.WEB_ADMIN_INVOICE_PROFILES, resolver);
    this.webAdminLedgerNumbers = RightModel.map(Rights.WEB_ADMIN_LEDGER_NUMBERS, resolver);
    this.webAdminInvoiceTags = RightModel.map(Rights.WEB_ADMIN_INVOICE_TAGS, resolver);
    this.webAdminTransportTriggers = RightModel.map(Rights.WEB_ADMIN_TRANSPORT_TRIGGERS, resolver);
    this.webMenuAdmin = RightModel.map(Rights.WEB_MENU_ADMIN, resolver);
    this.webMenuDashboard = RightModel.map(Rights.WEB_MENU_DASHBOARD, resolver);
    this.webMenuTaskRecords = RightModel.map(Rights.WEB_MENU_TASK_RECORDS, resolver);
    this.webMenuTaskRecordBoard = RightModel.map(Rights.WEB_MENU_TASK_RECORD_BOARD, resolver);
    this.webMenuCustomerRecords = RightModel.map(Rights.WEB_MENU_CUSTOMER_RECORDS, resolver);
    this.webMenuMasterdataRecords = RightModel.map(Rights.WEB_MENU_MASTERDATA_RECORDS, resolver);
    this.webMenuSurveyRecords = RightModel.map(Rights.WEB_MENU_SURVEY_RECORDS, resolver);
    this.webMenuInvoices = RightModel.map(Rights.WEB_MENU_INVOICES, resolver);
    this.webMenuInvoiceSpendings = RightModel.map(Rights.WEB_MENU_INVOICE_SPENDINGS, resolver);
    this.webMenuCalendar = RightModel.map(Rights.WEB_MENU_CALENDAR, resolver);
    this.webMenuGlobalCalendar = RightModel.map(Rights.WEB_MENU_GLOBAL_CALENDAR, resolver);
    this.webMenuProjects = RightModel.map(Rights.WEB_MENU_PROJECTS, resolver);
    this.webMenuOrders = RightModel.map(Rights.WEB_MENU_ORDERS, resolver);
    this.webMenuLegacyProcesses = RightModel.map(Rights.WEB_MENU_LEGACY_PROCESSES, resolver);
    this.webMenuProcesses = RightModel.map(Rights.WEB_MENU_PROCESSES, resolver);
    this.webMenuStocks = RightModel.map(Rights.WEB_MENU_STOCKS, resolver);
    this.webMenuStockTakings = RightModel.map(Rights.WEB_MENU_STOCK_TAKINGS, resolver);
    this.webMenuShippingDemands = RightModel.map(Rights.WEB_MENU_SHIPPING_DEMANDS, resolver);
    this.webMenuShipments = RightModel.map(Rights.WEB_MENU_SHIPMENTS, resolver);
    this.webMenuTransports = RightModel.map(Rights.WEB_MENU_TRANSPORTS, resolver);
    this.webMenuExteriorTransports = RightModel.map(Rights.WEB_MENU_EXTERIOR_TRANSPORTS, resolver);
    this.webMenuNotes = RightModel.map(Rights.WEB_MENU_NOTES, resolver);
    this.webMenuDocuments = RightModel.map(Rights.WEB_MENU_DOCUMENTS, resolver);
    this.webMenuDocumentLibrary = RightModel.map(Rights.WEB_MENU_DOCUMENT_LIBRARY, resolver);
    this.appTypeRead = RightModel.map(Rights.APP_TYPE_READ, resolver);
    this.apiUserKeyRead = RightModel.map(Rights.API_USER_KEY_READ, resolver);
    this.apiUserKeyActivate = RightModel.map(Rights.API_USER_KEY_ACTIVATE, resolver);
    this.apiUserKeyInactivate = RightModel.map(Rights.API_USER_KEY_INACTIVATE, resolver);
    this.apiUserKeyRegenerate = RightModel.map(Rights.API_USER_KEY_REGENERATE, resolver);
    this.userCreate = RightModel.map(Rights.USER_CREATE, resolver);
    this.userCreateAll = RightModel.map(Rights.USER_CREATE_ALL, resolver);
    this.userCreateMyCompany = RightModel.map(Rights.USER_CREATE_MY_COMPANY, resolver);
    this.userDisable = RightModel.map(Rights.USER_DISABLE, resolver);
    this.userRead = RightModel.map(Rights.USER_READ, resolver);
    this.userUpdate = RightModel.map(Rights.USER_UPDATE, resolver);
    this.userUpdateAll = RightModel.map(Rights.USER_UPDATE_ALL, resolver);
    this.userUpdateMyCompany = RightModel.map(Rights.USER_UPDATE_MY_COMPANY, resolver);
    this.userDelete = RightModel.map(Rights.USER_DELETE, resolver);
    this.userHistoryLog = RightModel.map(Rights.USER_HISTORY_LOG, resolver);
    this.userChangePassword = RightModel.map(Rights.USER_CHANGE_PASSWORD, resolver);
    this.userChangePreviousPassword = RightModel.map(Rights.USER_CHANGE_PREVIOUS_PASSWORD, resolver);
    this.userAttachmentDownloadFile = RightModel.map(Rights.USER_ATTACHMENT_DOWNLOAD_FILE, resolver);
    this.userImportFile = RightModel.map(Rights.USER_IMPORT_FILE, resolver);
    this.userExportFile = RightModel.map(Rights.USER_EXPORT_FILE, resolver);
    this.userLdapSync = RightModel.map(Rights.USER_LDAP_SYNC, resolver);
    this.userSignatureDelete = RightModel.map(Rights.USER_SIGNATURE_DELETE, resolver);
    this.userSignatureRead = RightModel.map(Rights.USER_SIGNATURE_READ, resolver);
    this.userSignatureUpload = RightModel.map(Rights.USER_SIGNATURE_UPLOAD, resolver);
    this.appFileRead = RightModel.map(Rights.APP_FILE_READ, resolver);
    this.appFileCreate = RightModel.map(Rights.APP_FILE_CREATE, resolver);
    this.appFileUpdate = RightModel.map(Rights.APP_FILE_UPDATE, resolver);
    this.appFileUpload = RightModel.map(Rights.APP_FILE_UPLOAD, resolver);
    this.appFileDelete = RightModel.map(Rights.APP_FILE_DELETE, resolver);
    this.appDictionaryUpdate = RightModel.map(Rights.APP_DICTIONARY_UPDATE, resolver);
    this.auditLogRead = RightModel.map(Rights.AUDIT_LOG_READ, resolver);
    this.mobileAppRead = RightModel.map(Rights.MOBILE_APP_READ, resolver);
    this.mobileAppDisable = RightModel.map(Rights.MOBILE_APP_DISABLE, resolver);
    this.mobileAppChangeName = RightModel.map(Rights.MOBILE_APP_CHANGE_NAME, resolver);

    this.userGroupRead = RightModel.map(Rights.USER_GROUP_READ, resolver);
    this.userGroupDisable = RightModel.map(Rights.USER_GROUP_DISABLE, resolver);
    this.userGroupCreate = RightModel.map(Rights.USER_GROUP_CREATE, resolver);
    this.userGroupCreateAll = RightModel.map(Rights.USER_GROUP_CREATE_ALL, resolver);
    this.userGroupCreateMyCompany = RightModel.map(Rights.USER_GROUP_CREATE_MY_COMPANY, resolver);
    this.userGroupUpdate = RightModel.map(Rights.USER_GROUP_UPDATE, resolver);
    this.userGroupUpdateAll = RightModel.map(Rights.USER_GROUP_UPDATE_ALL, resolver);
    this.userGroupUpdateMyCompany = RightModel.map(Rights.USER_GROUP_UPDATE_MY_COMPANY, resolver);
    this.userGroupDelete = RightModel.map(Rights.USER_GROUP_DELETE, resolver);
    this.userGroupLdapChangeUserGroup = RightModel.map(Rights.USER_GROUP_LDAP_CHANGE_USER_GROUP, resolver);
    this.userGroupLdapSync = RightModel.map(Rights.USER_GROUP_LDAP_SYNC, resolver);
    this.userGroupLdapRead = RightModel.map(Rights.USER_GROUP_LDAP_READ, resolver);
    this.userGroupLdapMapRead = RightModel.map(Rights.USER_GROUP_LDAP_MAP_READ, resolver);
    this.userGroupLdapMapUpdate = RightModel.map(Rights.USER_GROUP_LDAP_MAP_UPDATE, resolver);
    this.userGroupHistoryLog = RightModel.map(Rights.USER_GROUP_HISTORY_LOG, resolver);

    // MASTERDATA
    this.masterDataRead = RightModel.map(Rights.MASTER_DATA_READ, resolver);
    this.masterDataUpdate = RightModel.map(Rights.MASTER_DATA_UPDATE, resolver);
    this.masterDataCreate = RightModel.map(Rights.MASTER_DATA_CREATE, resolver);
    this.masterDataDisable = RightModel.map(Rights.MASTER_DATA_DISABLE, resolver);

    // MASTERDATA RECORD
    this.masterDataRecordRead = RightModel.map(Rights.MASTER_DATA_RECORD_READ, resolver);
    this.masterDataRecordUpdate = RightModel.map(Rights.MASTER_DATA_RECORD_UPDATE, resolver);
    this.masterDataRecordCreate = RightModel.map(Rights.MASTER_DATA_RECORD_CREATE, resolver);
    this.masterDataRecordDisable = RightModel.map(Rights.MASTER_DATA_RECORD_DISABLE, resolver);
    this.masterDataRecordImportFile = RightModel.map(Rights.MASTER_DATA_RECORD_IMPORT_FILE, resolver);
    this.masterDataRecordExportFile = RightModel.map(Rights.MASTER_DATA_RECORD_EXPORT_FILE, resolver);

    // MASTERDATA RECORD ATTACHMENT
    this.masterDataRecordAttachmentRead = RightModel.map(Rights.MASTER_DATA_RECORD_ATTACHMENT_READ, resolver);
    this.masterDataRecordAttachmentUpload = RightModel.map(Rights.MASTER_DATA_RECORD_ATTACHMENT_UPLOAD, resolver);
    this.masterDataRecordAttachmentDelete = RightModel.map(Rights.MASTER_DATA_RECORD_ATTACHMENT_DELETE, resolver);
    this.masterDataRecordAttachmentUpdate = RightModel.map(Rights.MASTER_DATA_RECORD_ATTACHMENT_UPDATE, resolver);

    this.documentGroupRead = RightModel.map(Rights.DOCUMENT_GROUP_READ, resolver);
    this.documentGroupUpdate = RightModel.map(Rights.DOCUMENT_GROUP_UPDATE, resolver);
    this.documentGroupCreate = RightModel.map(Rights.DOCUMENT_GROUP_CREATE, resolver);
    this.documentGroupDisable = RightModel.map(Rights.DOCUMENT_GROUP_DISABLE, resolver);

    // Survey
    this.surveyRead = RightModel.map(Rights.SURVEY_READ, resolver);
    this.surveyCreate = RightModel.map(Rights.SURVEY_CREATE, resolver);
    this.surveyUpdate = RightModel.map(Rights.SURVEY_UPDATE, resolver);
    this.surveyDisable = RightModel.map(Rights.SURVEY_DISABLE, resolver);

    this.surveyFormRead = RightModel.map(Rights.SURVEY_FORM_READ, resolver);
    this.surveyFormGroupRead = RightModel.map(Rights.SURVEY_FORM_GROUP_READ, resolver);
    this.surveyFormFieldRead = RightModel.map(Rights.SURVEY_FORM_FIELD_READ, resolver);
    this.surveyFormGroupUpdate = RightModel.map(Rights.SURVEY_FORM_GROUP_UPDATE, resolver);
    this.surveyFormFieldUpdate = RightModel.map(Rights.SURVEY_FORM_FIELD_UPDATE, resolver);
    this.surveyFormGroupCreate = RightModel.map(Rights.SURVEY_FORM_GROUP_CREATE, resolver);
    this.surveyFormFieldCreate = RightModel.map(Rights.SURVEY_FORM_FIELD_CREATE, resolver);
    this.surveyFormGroupDisable = RightModel.map(Rights.SURVEY_FORM_GROUP_DISABLE, resolver);
    this.surveyFormFieldDisable = RightModel.map(Rights.SURVEY_FORM_FIELD_DISABLE, resolver);
    this.surveyFormGroupMove = RightModel.map(Rights.SURVEY_FORM_GROUP_MOVE, resolver);
    this.surveyFormFieldMove = RightModel.map(Rights.SURVEY_FORM_FIELD_MOVE, resolver);

    this.surveyRecordRead = RightModel.map(Rights.SURVEY_RECORD_READ, resolver);
    this.surveyRecordCreate = RightModel.map(Rights.SURVEY_RECORD_CREATE, resolver);
    this.surveyRecordUpdate = RightModel.map(Rights.SURVEY_RECORD_UPDATE, resolver);
    this.surveyRecordDisable = RightModel.map(Rights.SURVEY_RECORD_DISABLE, resolver);
    this.surveyAttachmentDownloadFile = RightModel.map(Rights.SURVEY_ATTACHMENT_DOWNLOAD_FILE, resolver);
    this.surveyAttachmentDeleteFile = RightModel.map(Rights.SURVEY_ATTACHMENT_DELETE_FILE, resolver);

    this.formTableRead = RightModel.map(Rights.FORM_TABLE_READ, resolver);
    this.formTableCreate = RightModel.map(Rights.FORM_TABLE_CREATE, resolver);
    this.formTableUpdate = RightModel.map(Rights.FORM_TABLE_UPDATE, resolver);
    this.formTableDisable = RightModel.map(Rights.FORM_TABLE_DISABLE, resolver);

    this.stockRead = RightModel.map(Rights.STOCK_READ, resolver);
    this.stockCreate = RightModel.map(Rights.STOCK_CREATE, resolver);
    this.stockUpdate = RightModel.map(Rights.STOCK_UPDATE, resolver);
    this.stockDisable = RightModel.map(Rights.STOCK_DISABLE, resolver);
    this.stockItemRead = RightModel.map(Rights.STOCK_ITEM_READ, resolver);
    this.stockItemCreate = RightModel.map(Rights.STOCK_ITEM_CREATE, resolver);
    this.stockItemDisable = RightModel.map(Rights.STOCK_ITEM_DISABLE, resolver);
    this.stockItemDisableBulk = RightModel.map(Rights.STOCK_ITEM_DISABLE_BULK, resolver);
    this.stockItemMove = RightModel.map(Rights.STOCK_ITEM_MOVE, resolver);
    this.stockItemDispose = RightModel.map(Rights.STOCK_ITEM_DISPOSE, resolver);
    this.stockItemImportFile = RightModel.map(Rights.STOCK_ITEM_IMPORT, resolver);
    this.stockItemExportFile = RightModel.map(Rights.STOCK_ITEM_EXPORT, resolver);
    this.stockItemUpdate = RightModel.map(Rights.STOCK_ITEM_UPDATE, resolver);
    this.stockRecordRead = RightModel.map(Rights.STOCK_RECORD_READ, resolver);
    this.stockRecordImportFile = RightModel.map(Rights.STOCK_RECORD_IMPORT, resolver);
    this.stockRecordCreate = RightModel.map(Rights.STOCK_RECORD_CREATE, resolver);
    this.stockRecordUpdate = RightModel.map(Rights.STOCK_RECORD_UPDATE, resolver);
    this.stockRecordLogRead = RightModel.map(Rights.STOCK_RECORD_LOG_READ, resolver);

    // Task
    this.taskRead = RightModel.map(Rights.TASK_READ, resolver);
    this.taskFormImport = RightModel.map(Rights.TASK_FORM_EXPORT, resolver);
    this.taskFormExport = RightModel.map(Rights.TASK_FORM_IMPORT, resolver);
    this.taskCreate = RightModel.map(Rights.TASK_CREATE, resolver);
    this.taskUpdate = RightModel.map(Rights.TASK_UPDATE, resolver);
    this.taskDisable = RightModel.map(Rights.TASK_DISABLE, resolver);
    this.taskRecordRead = RightModel.map(Rights.TASK_RECORD_READ, resolver);
    this.taskRecordUpdate = RightModel.map(Rights.TASK_RECORD_UPDATE, resolver);
    this.taskRecordUpdateQuick = RightModel.map(Rights.TASK_RECORD_UPDATE_QUICK, resolver);
    this.taskRecordChangeDeadline = RightModel.map(Rights.TASK_RECORD_CHANGE_DEADLINE, resolver);
    this.taskRecordChangeAgreedTime = RightModel.map(Rights.TASK_RECORD_CHANGE_AGREED_TIME, resolver);
    this.taskRecordChangeAssignee = RightModel.map(Rights.TASK_RECORD_CHANGE_ASSIGNEE, resolver);
    this.taskRecordChangeAssigneeInProgress = RightModel.map(Rights.TASK_RECORD_CHANGE_ASSIGNEE_IN_PROGRESS, resolver);
    this.taskRecordChangeImportance = RightModel.map(Rights.TASK_RECORD_CHANGE_IMPORTANCE, resolver);
    this.taskRecordChangeConfirm = RightModel.map(Rights.TASK_RECORD_CHANGE_CONFIRM, resolver);
    this.taskRecordGeocode = RightModel.map(Rights.TASK_RECORD_GEOCODE, resolver);
    this.taskRecordImportFile = RightModel.map(Rights.TASK_RECORD_IMPORT_FILE, resolver);
    this.taskRecordExportFile = RightModel.map(Rights.TASK_RECORD_EXPORT_FILE, resolver);
    this.taskRecordExportFileCustom = RightModel.map(Rights.TASK_RECORD_EXPORT_FILE_CUSTOM, resolver);
    this.taskRecordExportApi = RightModel.map(Rights.TASK_RECORD_EXPORT_API, resolver);
    this.taskRecordRevert = RightModel.map(Rights.TASK_RECORD_REVERT, resolver);
    this.taskRecordLogRead = RightModel.map(Rights.TASK_RECORD_LOG_READ, resolver);
    this.taskRecordDocumentRead = RightModel.map(Rights.TASK_RECORD_DOCUMENT_READ, resolver);
    this.taskRecordCreate = RightModel.map(Rights.TASK_RECORD_CREATE, resolver);

    this.taskRecordAttachmentReadList = RightModel.map(Rights.TASK_RECORD_ATTACHMENT_READ_LIST, resolver);
    this.taskRecordAttachmentUploadFile = RightModel.map(Rights.TASK_RECORD_ATTACHMENT_UPLOAD_FILE, resolver);
    this.taskRecordAttachmentUpdateFile = RightModel.map(Rights.TASK_RECORD_ATTACHMENT_UPDATE_FILE, resolver);
    this.taskRecordAttachmentDeleteFile = RightModel.map(Rights.TASK_RECORD_ATTACHMENT_DELETE_FILE, resolver);

    this.customerRecordAttachmentReadList = RightModel.map(Rights.CUSTOMER_RECORD_ATTACHMENT_READ_LIST, resolver);
    this.customerRecordAttachmentUploadFile = RightModel.map(Rights.CUSTOMER_RECORD_ATTACHMENT_UPLOAD_FILE, resolver);
    this.customerRecordAttachmentDeleteFile = RightModel.map(Rights.CUSTOMER_RECORD_ATTACHMENT_DELETE_FILE, resolver);
    this.customerRecordAttachmentUpdate = RightModel.map(Rights.CUSTOMER_RECORD_ATTACHMENT_UPDATE, resolver);

    // TaskRecordInvoice
    this.taskRecordManualInvoiceCreate = RightModel.map(Rights.TASK_RECORD_MANUAL_INVOICE_CREATE, resolver);

    // Customer
    this.customerRead = RightModel.map(Rights.CUSTOMER_READ, resolver);
    this.customerUpdate = RightModel.map(Rights.CUSTOMER_UPDATE, resolver);
    this.customerCreate = RightModel.map(Rights.CUSTOMER_CREATE, resolver);
    this.customerDisable = RightModel.map(Rights.CUSTOMER_DISABLE, resolver);

    this.customerRecordCreate = RightModel.map(Rights.CUSTOMER_RECORD_CREATE, resolver);
    this.customerRecordImportFile = RightModel.map(Rights.CUSTOMER_RECORD_IMPORT_FILE, resolver);
    this.customerRecordExportFile = RightModel.map(Rights.CUSTOMER_RECORD_EXPORT_FILE, resolver);
    this.customerRecordRead = RightModel.map(Rights.CUSTOMER_RECORD_READ, resolver);
    this.customerRecordUpdate = RightModel.map(Rights.CUSTOMER_RECORD_UPDATE, resolver);
    this.customerRecordChangeOwner = RightModel.map(Rights.CUSTOMER_RECORD_CHANGE_OWNER, resolver);
    this.customerRecordDisable = RightModel.map(Rights.CUSTOMER_RECORD_DISABLE, resolver);
    this.customerRecordHistoryLog = RightModel.map(Rights.CUSTOMER_RECORD_HISTORY_LOG, resolver);

    this.customerFormRead = RightModel.map(Rights.CUSTOMER_FORM_READ, resolver);

    this.documentRead = RightModel.map(Rights.DOCUMENT_READ, resolver);

    this.documentFileRead = RightModel.map(Rights.DOCUMENT_FILE_READ, resolver);
    this.documentFileUpdate = RightModel.map(Rights.DOCUMENT_FILE_UPDATE, resolver);
    this.documentFileFileUpdate = RightModel.map(Rights.DOCUMENT_FILE_FILE_UPDATE, resolver);
    this.documentFileFileCreate = RightModel.map(Rights.DOCUMENT_FILE_FILE_CREATE, resolver);
    this.documentFileFileDownload = RightModel.map(Rights.DOCUMENT_FILE_FILE_DOWNLOAD, resolver);
    this.documentFileThumbnailDownload = RightModel.map(Rights.DOCUMENT_FILE_THUMBNAIL_DOWNLOAD, resolver);
    this.documentFileThumbnailUpdate = RightModel.map(Rights.DOCUMENT_FILE_THUMBNAIL_UPDATE, resolver);

    this.documentHtmlRead = RightModel.map(Rights.DOCUMENT_HTML_READ, resolver);
    this.documentHtmlUpdate = RightModel.map(Rights.DOCUMENT_HTML_UPDATE, resolver);
    this.documentHtmlDisable = RightModel.map(Rights.DOCUMENT_HTML_DISABLE, resolver);

    this.documentLinkRead = RightModel.map(Rights.DOCUMENT_LINK_READ, resolver);
    this.documentLinkUpdate = RightModel.map(Rights.DOCUMENT_LINK_UPDATE, resolver);
    this.documentLinkDisable = RightModel.map(Rights.DOCUMENT_LINK_DISABLE, resolver);
    this.documentLinkCreate = RightModel.map(Rights.DOCUMENT_LINK_CREATE, resolver);

    this.documentTextRead = RightModel.map(Rights.DOCUMENT_TEXT_READ, resolver);

    this.masterDataFormRead = RightModel.map(Rights.MASTER_DATA_FORM_READ, resolver);

    this.messageRead = RightModel.map(Rights.MESSAGE_READ, resolver);
    this.messageChangeRead = RightModel.map(Rights.MESSAGE_CHANGE_READ, resolver);
    this.messageDisable = RightModel.map(Rights.MESSAGE_DISABLE, resolver);
    this.messageDelete = RightModel.map(Rights.MESSAGE_DELETE, resolver);

    this.messageParentRead = RightModel.map(Rights.MESSAGE_PARENT_READ, resolver);
    this.messageParentCreate = RightModel.map(Rights.MESSAGE_PARENT_CREATE, resolver);

    // Note
    this.noteRead = RightModel.map(Rights.NOTE_READ, resolver);
    this.noteCreate = RightModel.map(Rights.NOTE_CREATE, resolver);
    this.noteUpdate = RightModel.map(Rights.NOTE_UPDATE, resolver);
    this.noteDisable = RightModel.map(Rights.NOTE_DISABLE, resolver);

    this.listItemRead = RightModel.map(Rights.LIST_ITEM_READ, resolver);
    this.listItemCreate = RightModel.map(Rights.LIST_ITEM_CREATE, resolver);
    this.listItemUpdate = RightModel.map(Rights.LIST_ITEM_UPDATE, resolver);
    this.listItemDisable = RightModel.map(Rights.LIST_ITEM_DISABLE, resolver);
    this.listItemImportXls = RightModel.map(Rights.LIST_ITEM_IMPORT_XLS, resolver);
    this.listItemTypeRead = RightModel.map(Rights.LIST_ITEM_TYPE_READ, resolver);
    this.listItemTypeCreate = RightModel.map(Rights.LIST_ITEM_TYPE_CREATE, resolver);
    this.listItemTypeUpdate = RightModel.map(Rights.LIST_ITEM_TYPE_UPDATE, resolver);
    this.listItemTypeDisable = RightModel.map(Rights.LIST_ITEM_TYPE_DISABLE, resolver);

    // Project record
    this.projectRecordRead = RightModel.map(Rights.PROJECT_RECORD_READ, resolver);
    this.projectRecordCreate = RightModel.map(Rights.PROJECT_RECORD_CREATE, resolver);
    this.projectRecordUpdate = RightModel.map(Rights.PROJECT_RECORD_UPDATE, resolver);
    this.projectRecordDisable = RightModel.map(Rights.PROJECT_RECORD_DISABLE, resolver);
    this.projectRecordExport = RightModel.map(Rights.PROJECT_RECORD_EXPORT, resolver);
    this.projectRecordImport = RightModel.map(Rights.PROJECT_RECORD_IMPORT, resolver);
    this.projectRecordAttachmentRead = RightModel.map(Rights.PROJECT_RECORD_ATTACHMENT_READ, resolver);
    this.projectRecordAttachmentDownloadFile = RightModel.map(Rights.PROJECT_RECORD_ATTACHMENT_DOWNLOAD_FILE, resolver);
    this.projectRecordAttachmentUploadFile = RightModel.map(Rights.PROJECT_RECORD_ATTACHMENT_UPLOAD_FILE, resolver);
    this.projectRecordAttachmentDelete = RightModel.map(Rights.PROJECT_RECORD_ATTACHMENT_DELETE, resolver);

    // Project
    this.projectRead = RightModel.map(Rights.PROJECT_READ, resolver);
    this.projectCreate = RightModel.map(Rights.PROJECT_CREATE, resolver);
    this.projectUpdate = RightModel.map(Rights.PROJECT_UPDATE, resolver);
    this.projectDisable = RightModel.map(Rights.PROJECT_DISABLE, resolver);

    this.projectFormRead = RightModel.map(Rights.PROJECT_FORM_READ, resolver);
    this.projectFormCreate = RightModel.map(Rights.PROJECT_FORM_CREATE, resolver);
    this.projectFormUpdate = RightModel.map(Rights.PROJECT_FORM_UPDATE, resolver);
    this.projectFormDisable = RightModel.map(Rights.PROJECT_FORM_DISABLE, resolver);
    this.projectFormExport = RightModel.map(Rights.PROJECT_FORM_EXPORT, resolver);
    this.projectFormImport = RightModel.map(Rights.PROJECT_FORM_IMPORT, resolver);

    this.taskRecordChangeStateOpen = RightModel.map(Rights.TASK_RECORD_CHANGE_STATE_OPEN, resolver);
    this.taskRecordChangeStateReject = RightModel.map(Rights.TASK_RECORD_CHANGE_STATE_REJECT, resolver);
    this.taskRecordChangeStateSubmit = RightModel.map(Rights.TASK_RECORD_CHANGE_STATE_SUBMIT, resolver);
    this.taskRecordChangeStateRevert = RightModel.map(Rights.TASK_RECORD_CHANGE_STATE_REVERT, resolver);
    this.taskRecordChangeStateValidate = RightModel.map(Rights.TASK_RECORD_CHANGE_STATE_VALIDATE, resolver);
    this.taskRecordChangeStateArchive = RightModel.map(Rights.TASK_RECORD_CHANGE_STATE_ARCHIVE, resolver);
    this.taskRecordChangeStateRecall = RightModel.map(Rights.TASK_RECORD_CHANGE_STATE_RECALL, resolver);
    this.taskRecordChangeStateRestart = RightModel.map(Rights.TASK_RECORD_CHANGE_STATE_RESTART, resolver);
    this.taskRecordChangeStateUnsubmit = RightModel.map(Rights.TASK_RECORD_CHANGE_STATE_UNSUBMIT, resolver);
    this.taskRecordChangeStateStart = RightModel.map(Rights.TASK_RECORD_CHANGE_STATE_START, resolver);
    this.taskRecordChangeStateReopen = RightModel.map(Rights.TASK_RECORD_CHANGE_STATE_REOPEN, resolver);
    this.taskRecordChangeStatePause = RightModel.map(Rights.TASK_RECORD_CHANGE_STATE_PAUSE, resolver);

    // Invoice
    this.invoiceSettingsRead = RightModel.map(Rights.INVOICE_SETTINGS_READ, resolver);
    this.invoiceSettingsCreate = RightModel.map(Rights.INVOICE_SETTINGS_CREATE, resolver);
    this.invoiceSettingUpdate = RightModel.map(Rights.INVOICE_SETTINGS_UPDATE, resolver);
    this.invoiceSettingDisable = RightModel.map(Rights.INVOICE_SETTINGS_DISABLE, resolver);
    this.invoiceBookRead = RightModel.map(Rights.INVOICE_BOOK_READ, resolver);
    this.invoiceBookCreate = RightModel.map(Rights.INVOICE_BOOK_CREATE, resolver);
    this.invoiceBookUpdate = RightModel.map(Rights.INVOICE_BOOK_UPDATE, resolver);
    this.invoiceBookDisable = RightModel.map(Rights.INVOICE_BOOK_DISABLE, resolver);
    this.ledgerNumberRead = RightModel.map(Rights.LEDGER_NUMBER_READ, resolver);
    this.ledgerNumberCreate = RightModel.map(Rights.LEDGER_NUMBER_CREATE, resolver);
    this.ledgerNumberUpdate = RightModel.map(Rights.LEDGER_NUMBER_UPDATE, resolver);
    this.ledgerNumberDisable = RightModel.map(Rights.LEDGER_NUMBER_DISABLE, resolver);
    this.invoiceTagRead = RightModel.map(Rights.INVOICE_TAG_READ, resolver);
    this.invoiceTagCreate = RightModel.map(Rights.INVOICE_TAG_CREATE, resolver);
    this.invoiceTagUpdate = RightModel.map(Rights.INVOICE_TAG_UPDATE, resolver);
    this.invoiceTagDisable = RightModel.map(Rights.INVOICE_TAG_DISABLE, resolver);
    this.invoiceCreate = RightModel.map(Rights.INVOICE_CREATE, resolver);
    this.invoiceRead = RightModel.map(Rights.INVOICE_READ, resolver);
    this.invoiceStorno = RightModel.map(Rights.INVOICE_STORNO, resolver);
    this.invoiceAction = RightModel.map(Rights.INVOICE_ACTION, resolver);

    this.invoiceBalanceRead = RightModel.map(Rights.INVOICE_BALANCE_READ, resolver);
    this.invoiceBalanceCreate = RightModel.map(Rights.INVOICE_BALANCE_CREATE, resolver);
    this.invoiceBalanceDelete = RightModel.map(Rights.INVOICE_BALANCE_DELETE, resolver);

    this.invoiceSpendingRead = RightModel.map(Rights.INVOICE_SPENDING_READ, resolver);
    this.invoiceSpendingCreate = RightModel.map(Rights.INVOICE_SPENDING_CREATE, resolver);

    this.taskLogStatRead = RightModel.map(Rights.TASK_LOG_STAT_READ, resolver);
    this.taskInvoiceStatRead = RightModel.map(Rights.TASK_INVOICE_STAT_READ, resolver);
    this.contractNumberRead = RightModel.map(Rights.CONTRACT_NUMBER_READ, resolver);

    // Workflow
    this.workflowCreate = RightModel.map(Rights.WORKFLOW_CREATE, resolver);
    this.workflowRead = RightModel.map(Rights.WORKFLOW_READ_ALL, resolver);
    this.workflowUpdate = RightModel.map(Rights.WORKFLOW_UPDATE_ALL, resolver);
    this.workflowDelete = RightModel.map(Rights.WORKFLOW_DELETE_ALL, resolver);
    this.workflowActivate = RightModel.map(Rights.WORKFLOW_ACTIVATE_ALL, resolver);
    this.workflowFinalize = RightModel.map(Rights.WORKFLOW_FINALIZE_ALL, resolver);
    this.workflowCreateNewVersion = RightModel.map(Rights.WORKFLOW_CREATE_NEW_VERSION_ALL, resolver);

    // Process
    this.processRead = RightModel.map(Rights.PROCESS_READ, resolver);
    this.processCreate = RightModel.map(Rights.PROCESS_CREATE, resolver);
    this.processUpdate = RightModel.map(Rights.PROCESS_UPDATE, resolver);
    this.processChatMessageDelete = RightModel.map(Rights.PROCESS_CHAT_MESSAGE_DELETE, resolver);


    // LegacyWorkflow
    this.legacyWorkflowRead = RightModel.map(Rights.LEGACY_WORKFLOW_READ_ALL, resolver);
    this.legacyWorkflowCreate = RightModel.map(Rights.LEGACY_WORKFLOW_CREATE, resolver);
    this.legacyWorkflowUpdate = RightModel.map(Rights.LEGACY_WORKFLOW_UPATE_ALL, resolver);
    this.legacyWorkflowDisable = RightModel.map(Rights.LEGACY_WORKFLOW_DISABLE_ALL, resolver);

    // LegacyProcess
    this.legacyProcessRead = RightModel.map(Rights.LEGACY_PROCESS_READ_ALL, resolver);
    this.legacyProcessCreate = RightModel.map(Rights.LEGACY_PROCESS_CREATE_ALL, resolver);
    this.legacyProcessUpdate = RightModel.map(Rights.LEGACY_PROCESS_UPDATE_ALL, resolver);
    this.legacyProcessChangeState = RightModel.map(Rights.LEGACY_PROCESS_CHANGE_STATE_ALL, resolver);

    // LegacyProcessTask
    this.legacyProcessTaskRead = RightModel.map(Rights.LEGACY_PROCESS_TASK_READ, resolver);
    this.legacyProcessTaskUpdate = RightModel.map(Rights.LEGACY_PROCESS_TASK_UPDATE, resolver);
    this.legacyProcessTaskChangeAssignee = RightModel.map(Rights.LEGACY_PROCESS_TASK_CHANGE_ASSIGNEE_ALL, resolver);
    this.legacyProcessTaskChangeState = RightModel.map(Rights.LEGACY_PROCESS_TASK_CHANGE_STATE, resolver);

    // Order
    this.orderCreate = RightModel.map(Rights.ORDER_CREATE, resolver);
    this.orderRead = RightModel.map(Rights.ORDER_READ, resolver);
    this.orderUpdate = RightModel.map(Rights.ORDER_UPDATE, resolver);
    this.orderSubmit = RightModel.map(Rights.ORDER_SUBMIT, resolver);
    this.orderCancel = RightModel.map(Rights.ORDER_CANCEL, resolver);

    // Order note
    this.orderNoteExtraRead = RightModel.map(Rights.ORDER_NOTE_EXTRA_READ, resolver);
    this.orderNoteExtraReadAll = RightModel.map(Rights.ORDER_NOTE_EXTRA_READ_ALL, resolver);
    this.orderNoteExtraReadMine = RightModel.map(Rights.ORDER_NOTE_EXTRA_READ_MINE, resolver);
    this.orderNoteExtraReadMyCustomer = RightModel.map(Rights.ORDER_NOTE_EXTRA_READ_MY_COMPANY, resolver);
    this.orderNoteExtraUpdate = RightModel.map(Rights.ORDER_NOTE_EXTRA_UPDATE, resolver);
    this.orderNoteExtraUpdateAll = RightModel.map(Rights.ORDER_NOTE_EXTRA_UPDATE_ALL, resolver);
    this.orderNoteExtraUpdateMine = RightModel.map(Rights.ORDER_NOTE_EXTRA_UPDATE_MINE, resolver);
    this.orderNoteExtraUpdateMyCustomer = RightModel.map(Rights.ORDER_NOTE_EXTRA_UPDATE_MY_COMPANY, resolver);
    this.orderNoteInternalRead = RightModel.map(Rights.ORDER_NOTE_INTERNAL_READ, resolver);
    this.orderNoteInternalReadAll = RightModel.map(Rights.ORDER_NOTE_INTERNAL_READ_ALL, resolver);
    this.orderNoteInternalReadMine = RightModel.map(Rights.ORDER_NOTE_INTERNAL_READ_MINE, resolver);
    this.orderNoteInternalReadMyCustomer = RightModel.map(Rights.ORDER_NOTE_INTERNAL_READ_MY_COMPANY, resolver);
    this.orderNoteInternalUpdate = RightModel.map(Rights.ORDER_NOTE_INTERNAL_UPDATE, resolver);
    this.orderNoteInternalUpdateAll = RightModel.map(Rights.ORDER_NOTE_INTERNAL_UPDATE_ALL, resolver);
    this.orderNoteInternalUpdateMine = RightModel.map(Rights.ORDER_NOTE_INTERNAL_UPDATE_MINE, resolver);
    this.orderNoteInternalUpdateMyCustomer = RightModel.map(Rights.ORDER_NOTE_INTERNAL_UPDATE_MY_COMPANY, resolver);

    // Task Record Chat
    this.taskRecordChatRead = RightModel.map(Rights.TASK_RECORD_CHAT_READ, resolver);
    this.taskRecordChatMessageCreate = RightModel.map(Rights.TASK_RECORD_CHAT_MESSAGE_CREATE, resolver);
    this.taskRecordChatMessageDelete = RightModel.map(Rights.TASK_RECORD_CHAT_MESSAGE_DELETE, resolver);

    // Stock taking
    this.stockTakingRead = RightModel.map(Rights.STOCK_TAKING_READ, resolver);
    this.stockTakingCreate = RightModel.map(Rights.STOCK_TAKING_CREATE, resolver);
    this.stockTakingUpdate = RightModel.map(Rights.STOCK_TAKING_UPDATE, resolver);
    this.stockTakingSheetRead = RightModel.map(Rights.STOCK_TAKING_SHEET_READ, resolver);
    this.stockTakingSheetCreate = RightModel.map(Rights.STOCK_TAKING_SHEET_CREATE, resolver);
    this.stockTakingSheetUpdate = RightModel.map(Rights.STOCK_TAKING_SHEET_UPDATE, resolver);
    this.stockTakingSheetUpdateAssignee = RightModel.map(Rights.STOCK_TAKING_SHEET_UPDATE_ASSIGNEE, resolver);
    this.stockTakingSheetDelete = RightModel.map(Rights.STOCK_TAKING_SHEET_DELETE, resolver);

    // Calendar
    this.calendarEventCreate = RightModel.map(Rights.CALENDAR_EVENT_CREATE, resolver);
    this.calendarEventRead = RightModel.map(Rights.CALENDAR_EVENT_READ, resolver);
    this.calendarEventUpdate = RightModel.map(Rights.CALENDAR_EVENT_UPDATE, resolver);
    this.calendarEventDelete = RightModel.map(Rights.CALENDAR_EVENT_DELETE, resolver);

    // Calendar
    this.globalCalendarEventCreate = RightModel.map(Rights.GLOBAL_CALENDAR_EVENT_CREATE, resolver);
    this.globalCalendarEventRead = RightModel.map(Rights.GLOBAL_CALENDAR_EVENT_READ, resolver);
    this.globalCalendarEventUpdate = RightModel.map(Rights.GLOBAL_CALENDAR_EVENT_UPDATE, resolver);
    this.globalCalendarEventDelete = RightModel.map(Rights.GLOBAL_CALENDAR_EVENT_DELETE, resolver);

    // Role
    this.roleRead = RightModel.map(Rights.ROLE_READ, resolver);
    this.roleCreate = RightModel.map(Rights.ROLE_CREATE, resolver);
    this.roleUpdate = RightModel.map(Rights.ROLE_UPDATE, resolver);
    this.roleDisable = RightModel.map(Rights.ROLE_DISABLE, resolver);
    this.roleHistoryLog = RightModel.map(Rights.ROLE_HISTORY_LOG, resolver);

    // Order note
    this.orderImportMplReturn = RightModel.map(Rights.ORDER_IMPORT_MPL_RETURN, resolver);
    this.orderImportMplFinalStatus = RightModel.map(Rights.ORDER_IMPORT_MPL_FINAL_STATUS, resolver);
    this.orderImportXlsx = RightModel.map(Rights.ORDER_IMPORT_XLSX, resolver);

    // Company
    this.companyRead = RightModel.map(Rights.COMPANY_READ, resolver);
    this.companyReadAll = RightModel.map(Rights.COMPANY_READ_ALL, resolver);
    this.companyReadMine = RightModel.map(Rights.COMPANY_READ_MINE, resolver);
    this.companyReadMyCompanyRelated = RightModel.map(Rights.COMPANY_READ_MY_COMPANY_RELATED, resolver);
    this.companyUpdate = RightModel.map(Rights.COMPANY_UPDATE, resolver);
    this.companyUpdateAll = RightModel.map(Rights.COMPANY_UPDATE_ALL, resolver);
    this.companyUpdateMine = RightModel.map(Rights.COMPANY_UPDATE_MINE, resolver);
    this.companyCreate = RightModel.map(Rights.COMPANY_CREATE, resolver);
    this.companyRolesUpdate = RightModel.map(Rights.COMPANY_ROLES_UPDATE, resolver);
    this.companyDisable = RightModel.map(Rights.COMPANY_DISABLE, resolver);
    this.companyHistoryLog = RightModel.map(Rights.COMPANY_HISTORY_LOG, resolver);

    // Vehicle
    this.vehicleRead = RightModel.map(Rights.VEHICLE_READ, resolver);
    this.vehicleCreate = RightModel.map(Rights.VEHICLE_CREATE, resolver);
    this.vehicleUpdate = RightModel.map(Rights.VEHICLE_UPDATE, resolver);
    this.vehicleDisable = RightModel.map(Rights.VEHICLE_DISABLE, resolver);
    this.vehicleHistoryLog = RightModel.map(Rights.VEHICLE_HISTORY_LOG, resolver);

    // Company Location
    this.companyLocationRead = RightModel.map(Rights.COMPANY_LOCATION_READ, resolver);
    this.companyLocationCreate = RightModel.map(Rights.COMPANY_LOCATION_CREATE, resolver);
    this.companyLocationUpdate = RightModel.map(Rights.COMPANY_LOCATION_UPDATE, resolver);
    this.companyLocationDisable = RightModel.map(Rights.COMPANY_LOCATION_DISABLE, resolver);
    this.companyLocationHistoryLog = RightModel.map(Rights.COMPANY_LOCATION_HISTORY_LOG, resolver);

    // Company Stock
    this.companyStockRead = RightModel.map(Rights.COMPANY_STOCK_READ, resolver);
    this.companyStockCreate = RightModel.map(Rights.COMPANY_STOCK_CREATE, resolver);
    this.companyStockUpdate = RightModel.map(Rights.COMPANY_STOCK_UPDATE, resolver);
    this.companyStockDisable = RightModel.map(Rights.COMPANY_STOCK_DISABLE, resolver);
    this.companyStockHistoryLog = RightModel.map(Rights.COMPANY_STOCK_HISTORY_LOG, resolver);

    // TRANSPORT
    this.transportRead = RightModel.map(Rights.TRANSPORT_READ, resolver);
    this.transportCreate = RightModel.map(Rights.TRANSPORT_CREATE, resolver);
    this.transportUpdate = RightModel.map(Rights.TRANSPORT_UPDATE, resolver);
    this.transportDelete = RightModel.map(Rights.TRANSPORT_DELETE, resolver);
    this.transportUse = RightModel.map(Rights.TRANSPORT_USE, resolver);
    this.transportSetSecurityGuard = RightModel.map(Rights.TRANSPORT_SET_SECURITY_GUARD, resolver);
    this.transportSetDriver = RightModel.map(Rights.TRANSPORT_SET_DRIVER, resolver);
    this.transportSetVehicle = RightModel.map(Rights.TRANSPORT_SET_VEHICLE, resolver);

    // SHIPPING DEMAND
    this.shippingDemandRead = RightModel.map(Rights.SHIPPING_DEMAND_READ, resolver);
    this.shippingDemandReadSimple = RightModel.map(Rights.SHIPPING_DEMAND_READ_SIMPLE, resolver);
    this.shippingDemandCreate = RightModel.map(Rights.SHIPPING_DEMAND_CREATE, resolver);
    this.shippingDemandXlsImport = RightModel.map(Rights.SHIPPING_DEMAND_IMPORT_XLSX, resolver);
    this.shippingDemandCreateSimple = RightModel.map(Rights.SHIPPING_DEMAND_CREATE_SIMPLE, resolver);
    this.shippingDemandUpdate = RightModel.map(Rights.SHIPPING_DEMAND_UPDATE, resolver);
    this.shippingDemandUpdateSimple = RightModel.map(Rights.SHIPPING_DEMAND_UPDATE_SIMPLE, resolver);
    this.shippingDemandCancel = RightModel.map(Rights.SHIPPING_DEMAND_CANCEL, resolver);
    this.shippingDemandSplit = RightModel.map(Rights.SHIPPING_DEMAND_SPLIT, resolver);

    // Client Document
    this.clientDocumentRead = RightModel.map(Rights.CLIENT_DOCUMENT_READ, resolver);
    this.clientDocumentCreate = RightModel.map(Rights.CLIENT_DOCUMENT_CREATE, resolver);
    this.clientDocumentUpdate = RightModel.map(Rights.CLIENT_DOCUMENT_UPDATE, resolver);
    this.clientDocumentSubmit = RightModel.map(Rights.CLIENT_DOCUMENT_SUBMIT, resolver);
    this.clientDocumentDelete = RightModel.map(Rights.CLIENT_DOCUMENT_DELETE, resolver);

    // Email Template
    this.emailTemplateRead = RightModel.map(Rights.EMAIL_TEMPLATE_READ, resolver);
    this.emailTemplateCreate = RightModel.map(Rights.EMAIL_TEMPLATE_CREATE, resolver);
    this.emailTemplateUpdate = RightModel.map(Rights.EMAIL_TEMPLATE_UPDATE, resolver);
    this.emailTemplateDisable = RightModel.map(Rights.EMAIL_TEMPLATE_DISABLE, resolver);

    // TASK DOCUMENT SCHEMA
    this.tableDocumentSchemaRead = RightModel.map(Rights.TABLE_DOCUMENT_SCHEMA_READ, resolver);
    this.tableDocumentSchemaCreate = RightModel.map(Rights.TABLE_DOCUMENT_SCHEMA_CREATE, resolver);
    this.tableDocumentSchemaCreateNext = RightModel.map(Rights.TABLE_DOCUMENT_SCHEMA_CREATE_NEXT, resolver);
    this.tableDocumentSchemaDeprecate = RightModel.map(Rights.TABLE_DOCUMENT_SCHEMA_DEPRECATE, resolver);
    this.tableDocumentSchemaUpdate = RightModel.map(Rights.TABLE_DOCUMENT_SCHEMA_UPDATE, resolver);
    this.tableDocumentSchemaDelete = RightModel.map(Rights.TABLE_DOCUMENT_SCHEMA_DELETE, resolver);
    this.tableDocumentSchemaFinalize = RightModel.map(Rights.TABLE_DOCUMENT_SCHEMA_FINALIZE, resolver);

    // TASK DOCUMENT
    this.tableDocumentRead = RightModel.map(Rights.TABLE_DOCUMENT_READ, resolver);
    this.tableDocumentCreate = RightModel.map(Rights.TABLE_DOCUMENT_CREATE, resolver);
    this.tableDocumentUpdate = RightModel.map(Rights.TABLE_DOCUMENT_UPDATE, resolver);
    this.tableDocumentDelete = RightModel.map(Rights.TABLE_DOCUMENT_DELETE, resolver);
    this.tableDocumentExport = RightModel.map(Rights.TABLE_DOCUMENT_EXPORT, resolver);
    this.tableDocumentReplace = RightModel.map(Rights.TABLE_DOCUMENT_REPLACE, resolver);

    // General PDF template
    this.generalPdfTemplateRead = RightModel.map(Rights.GENERAL_PDF_TEMPLATE_READ, resolver);
    this.generalPdfTemplateUpload = RightModel.map(Rights.GENERAL_PDF_TEMPLATE_UPLOAD, resolver);
    this.generalPdfTemplateDisable = RightModel.map(Rights.GENERAL_PDF_TEMPLATE_DISABLE, resolver);

    // Token
    this.tokenRead = RightModel.map(Rights.TOKEN_READ, resolver);
    this.tokenDelete = RightModel.map(Rights.TOKEN_DELETE, resolver);

    // NFC Card
    this.userNfcCardRead = RightModel.map(Rights.USER_NFC_CARD_READ, resolver);
    this.userNfcCardCreate = RightModel.map(Rights.USER_NFC_CARD_CREATE, resolver);
    this.userNfcCardDelete = RightModel.map(Rights.USER_NFC_CARD_DELETE, resolver);

    // Stock Item Category
    this.stockItemCategoryRead = RightModel.map(Rights.STOCK_ITEM_CATEGORY_READ, resolver);
    this.stockItemCategoryCreate = RightModel.map(Rights.STOCK_ITEM_CATEGORY_CREATE, resolver);
    this.stockItemCategoryUpdate = RightModel.map(Rights.STOCK_ITEM_CATEGORY_UPDATE, resolver);
    this.stockItemCategoryDelete = RightModel.map(Rights.STOCK_ITEM_CATEGORY_DELETE, resolver);

    // Environment config
    this.environmentConfigRead = RightModel.map(Rights.ENVIRONMENT_CONFIG_READ, resolver);
    this.environmentConfigUpdate = RightModel.map(Rights.ENVIRONMENT_CONFIG_UPDATE, resolver);

    // Registration settings
    this.registrationSettingsRead = RightModel.map(Rights.REGISTRATION_SETTINGS_READ, resolver);
    this.registrationSettingsUpdate = RightModel.map(Rights.REGISTRATION_SETTINGS_UPDATE, resolver);

    // Message template
    this.messageTemplateRead = RightModel.map(Rights.MESSAGE_TEMPLATE_READ, resolver);
    this.messageTemplateCreate = RightModel.map(Rights.MESSAGE_TEMPLATE_CREATE, resolver);
    this.messageTemplateUpdate = RightModel.map(Rights.MESSAGE_TEMPLATE_UPDATE, resolver);
    this.messageTemplateDisable = RightModel.map(Rights.MESSAGE_TEMPLATE_DISABLE, resolver);

    // General PDF template
    this.generalPrinterTemplateRead = RightModel.map(Rights.GENERAL_PRINTER_TEMPLATE_READ, resolver);
    this.generalPrinterTemplateUpload = RightModel.map(Rights.GENERAL_PRINTER_TEMPLATE_UPLOAD, resolver);
    this.generalPrinterTemplateDisable = RightModel.map(Rights.GENERAL_PRINTER_TEMPLATE_DISABLE, resolver);

    this.storedDqlRead = RightModel.map(Rights.STORED_DQL_READ, resolver);
    this.storedDqlCreate = RightModel.map(Rights.STORED_DQL_CREATE, resolver);
    this.storedDqlUpdate = RightModel.map(Rights.STORED_DQL_UPDATE, resolver);
    this.storedDqlDelete = RightModel.map(Rights.STORED_DQL_DELETE, resolver);

    // Transport trigger
    this.transportTriggerRead = RightModel.map(Rights.TRANSPORT_TRIGGER_READ, resolver);
    this.transportTriggerDisable = RightModel.map(Rights.TRANSPORT_TRIGGER_DISABLE, resolver);
    this.transportTriggerDelete = RightModel.map(Rights.TRANSPORT_TRIGGER_DELETE, resolver);
    this.transportTriggerCreate = RightModel.map(Rights.TRANSPORT_TRIGGER_CREATE, resolver);
    this.transportTriggerUpdate = RightModel.map(Rights.TRANSPORT_TRIGGER_UPDATE, resolver);

    // Transport trigger instance
    this.transportTriggerInstanceRead = RightModel.map(Rights.TRANSPORT_TRIGGER_INSTANCE_READ, resolver);

    // TaskTrigger
    this.taskTriggerRead = RightModel.map(Rights.TASK_TRIGGER_READ, resolver);
    this.taskTriggerDisable = RightModel.map(Rights.TASK_TRIGGER_DISABLE, resolver);
    this.taskTriggerDelete = RightModel.map(Rights.TASK_TRIGGER_DELETE, resolver);
    this.taskTriggerCreate = RightModel.map(Rights.TASK_TRIGGER_CREATE, resolver);
    this.taskTriggerUpdate = RightModel.map(Rights.TASK_TRIGGER_UPDATE, resolver);

    // TaskTriggerInstance
    this.taskTriggerInstanceRead = RightModel.map(Rights.TASK_TRIGGER_INSTANCE_READ, resolver);

    // TaskRecordSubTask
    this.taskRecordSubTaskRead = RightModel.map(OperationRights.TASK_RECORD_SUB_TASK_READ, resolver);
    this.taskRecordSubTaskCreate = RightModel.map(OperationRights.TASK_RECORD_SUB_TASK_CREATE, resolver);

    // Order Document
    this.orderDocumentCreate = RightModel.map(Rights.ORDER_DOCUMENT_CREATE, resolver);
    this.orderDocumentRead = RightModel.map(Rights.ORDER_DOCUMENT_READ, resolver);
    this.orderDocumentDownload = RightModel.map(Rights.ORDER_DOCUMENT_DOWNLOAD, resolver);
    this.orderDocumentUpdateMeta = RightModel.map(Rights.ORDER_DOCUMENT_UPDATE_META, resolver);
    this.orderDocumentDelete = RightModel.map(Rights.ORDER_DOCUMENT_DELETE, resolver);

    // Shipment
    this.shipmentCreate = RightModel.map(Rights.SHIPMENT_CREATE, resolver);
    this.shipmentChangeSubTransporter = RightModel.map(Rights.SHIPMENT_CREATE, resolver);
    this.shipmentGroupRead = RightModel.map(Rights.SHIPMENT_GROUP_READ, resolver);
    this.shipmentGroupShippingDemandRemove = RightModel.map(Rights.SHIPMENT_GROUP_SHIPPING_DEMAND_REMOVE, resolver);
    this.shipmentGroupDepotUpdate = RightModel.map(Rights.SHIPMENT_GROUP_DEPOT_UPDATE, resolver);

    this.exteriorTransportCreate = RightModel.map(Rights.EXTERIOR_TRANSPORT_CREATE, resolver);
    this.exteriorTransportRead = RightModel.map(Rights.EXTERIOR_TRANSPORT_READ, resolver);
    this.exteriorTransportStart = RightModel.map(Rights.EXTERIOR_TRANSPORT_START, resolver);
    this.exteriorTransportFinish = RightModel.map(Rights.EXTERIOR_TRANSPORT_FINISH, resolver);
    this.exteriorTransportShipmentAdd = RightModel.map(Rights.EXTERIOR_TRANSPORT_SHIPMENT_ADD, resolver);
    this.exteriorTransportShipmentRemove = RightModel.map(Rights.EXTERIOR_TRANSPORT_SHIPMENT_REMOVE, resolver);

    // ExteriorTransportDocument
    this.exteriorTransportDocumentRead = RightModel.map(Rights.EXTERIOR_TRANSPORT_DOCUMENT_READ, resolver);
    this.exteriorTransportDocumentCreate = RightModel.map(Rights.EXTERIOR_TRANSPORT_DOCUMENT_CREATE, resolver);
    this.exteriorTransportDocumentDelete = RightModel.map(Rights.EXTERIOR_TRANSPORT_DOCUMENT_DELETE, resolver);
    this.exteriorTransportDocumentDownload = RightModel.map(Rights.EXTERIOR_TRANSPORT_DOCUMENT_DOWNLOAD, resolver);
    this.exteriorTransportDocumentMetaUpdate = RightModel.map(Rights.EXTERIOR_TRANSPORT_DOCUMENT_META_UPDATE, resolver);

    // ShopRenterShop
    this.shopRenterShopRead = RightModel.map(Rights.SHOP_RENTER_SHOP_READ, resolver);
    this.shopRenterShopUpdate = RightModel.map(Rights.SHOP_RENTER_SHOP_UPDATE, resolver);
    this.shopRenterShopSync = RightModel.map(Rights.SHOP_RENTER_SHOP_SYNC, resolver);
    this.shopRenterShopImportProducts = RightModel.map(Rights.SHOP_RENTER_SHOP_IMPORT_PRODUCTS, resolver);

    // Worklog
    this.worklogRead = RightModel.map(Rights.WORKLOG_READ, resolver);
    this.worklogUpdate = RightModel.map(Rights.WORKLOG_UPDATE, resolver);
    this.worklogCreate = RightModel.map(Rights.WORKLOG_CREATE, resolver);

    // MileageRecord
    this.mileageRecordRead = RightModel.map(Rights.MILEAGE_RECORD_READ, resolver);
    this.mileageRecordCreate = RightModel.map(Rights.MILEAGE_RECORD_CREATE, resolver);
    this.mileageRecordUpdate = RightModel.map(Rights.MILEAGE_RECORD_UPDATE, resolver);

    // OrderImportError
    this.orderImportErrorRead = RightModel.map(Rights.ORDER_IMPORT_ERROR_READ, resolver);
    this.orderImportErrorRejectManually = RightModel.map(Rights.ORDER_IMPORT_ERROR_REJECT_MANUALLY, resolver);

    // StockChange
    this.stockChangeRead = RightModel.map(Rights.STOCK_CHANGE_READ, resolver);

    // StockItemCustomerRecordPrice
    this.stockItemCustomerRecordPriceRead = RightModel.map(Rights.STOCK_ITEM_CUSTOMER_RECORD_PRICE_READ, resolver);
    this.stockItemCustomerRecordPriceCreate = RightModel.map(Rights.STOCK_ITEM_CUSTOMER_RECORD_PRICE_CREATE, resolver);
    this.stockItemCustomerRecordPriceUpdate = RightModel.map(Rights.STOCK_ITEM_CUSTOMER_RECORD_PRICE_UPDATE, resolver);
    this.stockItemCustomerRecordPriceDelete = RightModel.map(Rights.STOCK_ITEM_CUSTOMER_RECORD_PRICE_DELETE, resolver);

    // ParcelCollectionPoint
    this.parcelCollectionPointRead = RightModel.map(Rights.PARCEL_COLLECTION_POINT_READ, resolver);
    this.parcelCollectionPointCreate = RightModel.map(Rights.PARCEL_COLLECTION_POINT_CREATE, resolver);
    this.parcelCollectionPointUpdate = RightModel.map(Rights.PARCEL_COLLECTION_POINT_UPDATE, resolver);
    this.parcelCollectionPointDisable = RightModel.map(Rights.PARCEL_COLLECTION_POINT_DISABLE, resolver);
    this.parcelCollectionPointImport = RightModel.map(Rights.PARCEL_COLLECTION_POINT_IMPORT, resolver);

    // DeliveryMethod
    this.deliveryMethodRead = RightModel.map(Rights.DELIVERY_METHOD_READ, resolver);
    this.deliveryMethodCreate = RightModel.map(Rights.DELIVERY_METHOD_CREATE, resolver);
    this.deliveryMethodUpdate = RightModel.map(Rights.DELIVERY_METHOD_UPDATE, resolver);
    this.deliveryMethodDisable = RightModel.map(Rights.DELIVERY_METHOD_DISABLE, resolver);

    // EmailAddressType
    this.emailAddressTypeCreate = RightModel.map(Rights.EMAIL_ADDRESS_TYPE_CREATE, resolver);
    this.emailAddressTypeUpdate = RightModel.map(Rights.EMAIL_ADDRESS_TYPE_UPDATE, resolver);
    this.emailAddressTypeDisable = RightModel.map(Rights.EMAIL_ADDRESS_TYPE_DISABLE, resolver);

    // Colonnade
    this.colonnadeB2bRead = RightModel.map(Rights.COLONNADE_B2B_READ, resolver);
    this.colonnadeB2bReadAll = RightModel.map(Rights.COLONNADE_B2B_READ_ALL, resolver);
    this.colonnadeB2bReadMine = RightModel.map(Rights.COLONNADE_B2B_READ_MINE, resolver);

    // Shipment Outtake
    this.shipmentOuttakeStorekeeperChange = RightModel.map(Rights.SHIPMENT_OUTTAKE_STOREKEEPER_CHANGE, resolver);
    this.shipmentOuttakeReopenForce = RightModel.map(Rights.SHIPMENT_OUTTAKE_REOPEN_FORCE, resolver);

    this.androidAppVersionHandle = RightModel.map(Rights.ANDROID_APP_VERSION_HANDLE, resolver);

    this.customerRecordHelpdeskAction = RightModel.map(Rights.CUSTOMER_RECORD_HELPDESK_ACTION, resolver);

    // Billing info
    this.billingInfoRead = RightModel.map(Rights.BILLING_INFO_READ, resolver);
    this.billingInfoCreate = RightModel.map(Rights.BILLING_INFO_CREATE, resolver);
    this.billingInfoUpdate = RightModel.map(Rights.BILLING_INFO_UPDATE, resolver);
    this.billingInfoDisable = RightModel.map(Rights.BILLING_INFO_DISABLE, resolver);
  }

}
