import { Address, AddressModel, AddressResource } from '../../../../lib/address';
import { MultiselectOptionItem } from '../../../../util/core-utils';
import { BehaviorSubject } from 'rxjs';
import { CompanyLocation, CompanyLocationService } from '../../../../lib/company-location/company-location.service';
import { Strings } from '../../../../lib/util/strings';
import { ShippingDemand } from '../../../../lib/shipping-demand/shipping-demand.service';
import ShippingPlace = ShippingDemand.ShippingPlace;
import SourceDestination = ShippingDemand.SourceDestination;

export type AddressType = 'NEW_ADDRESS' | 'LOCATION';

export const addressTypes: MultiselectOptionItem<AddressType>[] = [
  {id: 'NEW_ADDRESS', itemName: 'COMMON_LOCATION_POSTAL_ADDRESS'},
  {id: 'LOCATION', itemName: 'SHIPPING_DEMAND_ADDRESS_TYPE_LOCATION'}
];

export type AddressCategory = 'SOURCE' | 'DESTINATION';

export class ShippingDemandAddressModel {
  addressCategory: AddressCategory;
  addressType: MultiselectOptionItem<AddressType>[] = [];
  addressName: string = '';
  postalAddress: AddressModel.PostalAddressModel;
  companyItem: MultiselectOptionItem<number>[] = [];
  stockItem: MultiselectOptionItem<number>[] = [];
  locationItem: MultiselectOptionItem<number>[] = [];
  coordinate: Address.Coordinate | undefined;
  geocodeStatus: AddressResource.GeocodeStatus | undefined;
  companyLoadedObservable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  locationChanged: boolean = false;

  constructor(category: AddressCategory, private companyLocationService: CompanyLocationService) {
    this.postalAddress = new AddressModel.PostalAddressModel();
    this.postalAddress.type = AddressModel.PostalAddressModelType.COMPLEX;
    this.addressCategory = category;
  }

  public loadShippingPlace(place: ShippingPlace) {
    if (!place.location) {
      this.addressType = addressTypes.filter(t => t.id === 'NEW_ADDRESS');
      this.addressName = place.place.name;
      this.postalAddress.load(place.place.postalAddress);
      this.coordinate = place.place.coordinate;
      this.geocodeStatus = place.place.geocodeStatus;
    }
    else {
      this.addressType = addressTypes.filter(t => t.id === 'LOCATION');
      this.companyLocationService.get({id: place.location!.id}).subscribe((location: CompanyLocation.CompanyLocation) => {
        this.companyItem[0] = {
          id: location.company.id,
          itemName: location.company.name
        };
        this.locationItem[0] = {
          id: location.id,
          itemName: location.name
        };
        this.companyLoadedObservable.next(true);
      });
      this.coordinate = place.location!.place!.coordinate;
      this.geocodeStatus = place.location!.place!.geocodeStatus;
      if (place.stock) {
        this.stockItem = []
        this.stockItem.push({
          id: place.stock.id,
          itemName: place.stock.name
        });
      }
    }
    this.locationChanged = place.locationChanged;
  }

  public isValid(): boolean {
    if (this.addressType.length === 0) {
      return false;
    }
    if (this.addressType[0].id === 'NEW_ADDRESS') {
      if (!this.postalAddress.valid) {
        return false;
      }
      if (!Strings.undefinedOrNonEmpty(this.addressName)) {
        return false;
      }
      return true;
    }
    if (this.locationItem.length === 0) {
      return false;
    }
    return true;
  }

  public toSourceDestination(): SourceDestination {
    if (this.addressType[0].id === 'NEW_ADDRESS') {
      return {
        place: {
          name: this.addressName,
          postalAddress: this.postalAddress.toData()!
        }
      };
    }
    return {
      locationId: this.locationItem.length > 0 ? this.locationItem[0].id : undefined,
      stockId: this.stockItem.length === 1 ? this.stockItem[0].id : undefined
    };
  }

  public isLocation(): boolean {
    return this.addressType.length === 1 && this.addressType[0].id === 'LOCATION';
  }

  public isSource(): boolean {
    return this.addressCategory === 'SOURCE';
  }

  public isDestination(): boolean {
    return this.addressCategory === 'DESTINATION';
  }

  get geocodeStatusIcon(): string {
    const so = AddressResource.geocodeStatuses.find(s => s.status === this.geocodeStatus);
    return so ? so.iconClass : '';
  }

  get geocodeStatusNameKey(): string {
    const so = AddressResource.geocodeStatuses.find(s => s.status === this.geocodeStatus);
    return so ? so.stringKey : '';
  }

  get geocodeStatusCoordinateText(): string {
    return this.coordinate ? this.coordinate.latitude.toString() + ', ' + this.coordinate.longitude.toString() : '';
  }

}



