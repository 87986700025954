<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb"
     *ngIf="!isComponentEmbedded">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="taskName">
    </app-breadcrumb>
    <app-breadcrumb-menu>
      <!-- region BULK CHANGE-->
      <app-breadcrumb-button
        *ngIf="rightModel.taskRecordChangeAssignee.hasRight()"
        [iconClass]="'icomoon-deadline-alt'"
        [titleStringKey]="'COMMON_BUTTON_ASSIGNEE_MODIFICATION'"
        (click)="checkBulkUpdate('ASSIGNEE')">
      </app-breadcrumb-button>
      <app-breadcrumb-dropdown
        [titleStringKey]="'TASKS_DATA_MODIFY'">
        <app-dropdown-item
          *ngIf="rightModel.taskRecordChangeDeadline.hasRight()"
          [iconClass]="'icomoon-deadline'"
          [titleStringKey]="'COMMON_BUTTON_DEADLINE_MODIFICATION'"
          (click)="checkBulkUpdate('DEADLINE')">
        </app-dropdown-item>
        <app-dropdown-item
          *ngIf="rightModel.taskRecordGeocode.hasRight()"
          [iconClass]="'icomoon-location'"
          [titleStringKey]="'TASK_GEOCODING_TITLE'"
          (click)="checkBulkGeocode()">
        </app-dropdown-item>
        <app-dropdown-item
          *ngIf="rightModel.taskRecordRevert.hasRight()"
          [iconClass]="'icomoon-trash'"
          [titleStringKey]="'COMMON_VALUE_TASK_STATE_EDIT_REVERTED'"
          (click)="checkRevertIds()">
        </app-dropdown-item>
      </app-breadcrumb-dropdown>
      <!-- endregion BULK CHANGE-->

      <!-- region STATE CHANGE-->
      <app-breadcrumb-button
        *ngIf="settableStateOpen"
        id="task-record-list-state-change-open"
        [iconClass]="settableStateOpen.stateObject.iconEditClass"
        [titleStringKey]="settableStateOpen.text"
        (click)="checkState(settableStateOpen.stateObject)">
      </app-breadcrumb-button>
      <app-breadcrumb-dropdown
        [titleStringKey]="'PROCESS_EDIT_STATE_CHANGE'">
        <app-dropdown-item
          *ngFor="let state of settableStateList"
          id="task-record-list-state-change-{{state.stateObject.state.toLocaleLowerCase()}}"
          [iconClass]="state.stateObject.iconEditClass"
          [titleStringKey]="state.text"
          (click)="checkState(state.stateObject)">
        </app-dropdown-item>
      </app-breadcrumb-dropdown>
      <!-- endregion STATE CHANGE-->

      <!-- region ATTACHMENT ZIP DOWNLOAD-->
      <app-breadcrumb-button
        *ngIf="rightModel.taskRecordAttachmentReadList.hasRight()"
        [iconClass]="'icomoon-download'"
        [titleStringKey]="'ATTACHMENT_DOWNLOAD_TITLE'"
        (click)="downloadAttachmentZip()">
      </app-breadcrumb-button>
      <app-breadcrumb-dropdown
        [titleStringKey]="'ATTACHMENT_DOWNLOAD_TITLE'">
        <app-dropdown-item
          *ngIf="rightModel.taskRecordRead.hasRight()"
          [iconClass]="'icomoon-download'"
          [titleStringKey]="'TASK_PDF_DOWNLOAD'"
          (click)="downloadPdfZip()">
        </app-dropdown-item>
        <app-dropdown-item
          *ngIf="rightModel.taskRecordRead.hasRight()"
          [iconClass]="'icomoon-thermal-paper'"
          [titleStringKey]="'TASK_PDF_PRINT'"
          (click)="downloadMergedPdf(true)">
        </app-dropdown-item>
      </app-breadcrumb-dropdown>
      <!-- endregion ATTACHMENT ZIP DOWNLOAD-->

      <!-- region EXPORT TO EXTERNAL SYSTEM-->
      <app-breadcrumb-button
        *ngIf="rightModel.taskRecordExportApi.hasRight() && !(rightModel.taskRecordExportFile.hasRight() && taskId)"
        [iconClass]="'icomoon-export-to-external-system'"
        [titleStringKey]="'COMMON_BUTTON_EXTERNAL_EXPORT'"
        (click)="checkExternalExportIds()">
      </app-breadcrumb-button>
      <!-- endregion EXPORT TO EXTERNAL SYSTEM-->

      <!-- region XLSX EXPORT-->
      <app-breadcrumb-button
        *ngIf="rightModel.taskRecordExportFile.hasRight()"
        [iconClass]="'icomoon-file-xlsx'"
        [titleStringKey]="'COMMON_EXPORT_XLSX'"
        (click)="exportXls()">
      </app-breadcrumb-button>
      <app-breadcrumb-dropdown
        [titleStringKey]="'COMMON_EXPORT_XLSX'">
        <app-dropdown-item
          *ngIf="rightModel.taskRecordImportFile.hasRight() && taskId"
          [iconClass]="'icomoon-file-xlsx'"
          [titleStringKey]="'ORDER_XLS_IMPORT_SAMPLE_DOWNLOAD'"
          (click)="exportXlsTemplate()">
        </app-dropdown-item>
        <app-dropdown-item
          *ngIf="maconomyEnabled && rightModel.taskRecordExportFile.hasRight()"
          [iconClass]="'icomoon-file-xlsx'"
          [titleStringKey]="'COMMON_BUTTON_MACONOMY_EXPORT'"
          (click)="exportMaconomy()">
        </app-dropdown-item>
        <app-dropdown-item
          *ngIf="rightModel.taskRecordExportFile.hasRight()"
          [iconClass]="'icomoon-sidebar-stock'"
          [titleStringKey]="'TASK_RECORD_EXPORT_FORM_STOCK_RECORD_XLSX'"
          (click)="showStockExportDialog()">
        </app-dropdown-item>
        <app-dropdown-item
          *ngIf="customXlsxExportEnabled && rightModel.taskRecordExportFileCustom.hasRight()"
          [iconClass]="'icomoon-file-xlsx'"
          [titleStringKey]="'TASK_RECORD_EXPORT_CUSTOM_XLSX'"
          (click)="exportCustomXls()">
        </app-dropdown-item>
        <app-dropdown-item
          *ngIf="supExportEnabled && rightModel.taskRecordExportFile.hasRight()"
          [iconClass]="'icomoon-invoice'"
          [titleStringKey]="'TASK_RECORD_EXPORT_SUP_CREATE'"
          (click)="exportSupCreate()">
        </app-dropdown-item>
        <app-dropdown-item
          *ngIf="supExportEnabled && rightModel.taskRecordExportFile.hasRight()"
          [iconClass]="'icomoon-invoice'"
          [titleStringKey]="'TASK_RECORD_EXPORT_SUP_INVOICE'"
          (click)="exportSupInvoice()">
        </app-dropdown-item>
        <app-dropdown-item
          *ngIf="rightModel.taskRecordExportFile.hasRight() && taskId"
          [iconClass]="'icomoon-csv-export'"
          [titleStringKey]="'COMMON_BUTTON_CSV_EXPORT'"
          (click)="downloadCsv()">
        </app-dropdown-item>
        <app-dropdown-item
          *ngIf="rightModel.taskRecordExportApi.hasRight() && (rightModel.taskRecordExportFile.hasRight() && taskId)"
          [iconClass]="'icomoon-export-to-external-system'"
          [titleStringKey]="'COMMON_BUTTON_EXTERNAL_EXPORT'"
          (click)="checkExternalExportIds()">
        </app-dropdown-item>
        <app-dropdown-item
          *ngIf="rightModel.taskRecordRead.hasRight()"
          [iconClass]="'icomoon-file-xlsx'"
          [titleStringKey]="'TASK_FORM_TABLE_EXPORT'"
          (click)="downloadTableXls()">
        </app-dropdown-item>
      </app-breadcrumb-dropdown>
      <!-- endregion XLSX EXPORT-->

      <!-- region TASK RECORD CREATE-->
      <app-breadcrumb-button
        *ngIf="canCreateTaskRecord()"
        id="task-record-list-add"
        [iconClass]="'icomoon-add'"
        [titleStringKey]="'COMMON_BUTTON_ADD'"
        (click)="onCreateTaskRecord()">
      </app-breadcrumb-button>
      <app-breadcrumb-dropdown
        [titleStringKey]="'COMMON_BUTTON_ADD'">
        <app-dropdown-item
          *ngIf="taskId && (rightModel.taskRecordImportFile.hasRight() || demoModeEnabled)"
          [iconClass]="'icomoon-csv-import'"
          [titleStringKey]="'COMMON_BUTTON_TASK_RECORD_IMPORT'"
          [disabled]="!rightModel.taskRecordImportFile.hasRight()"
          (click)="rightModel.taskRecordImportFile.hasRight() ? importDialog.toggleDialog() : showDisabledDialog()">
        </app-dropdown-item>
        <app-dropdown-item
          *ngIf="rightModel.taskRecordCreate.hasRight()"
          [iconClass]="'icomoon-clone'"
          [titleStringKey]="'TASK_RECORD_BULK_CLONE_TITLE'"
          (click)="showBulkCloneDialog()">
        </app-dropdown-item>
      </app-breadcrumb-dropdown>
      <!-- endregion TASK RECORD CREATE-->

    </app-breadcrumb-menu>
  </div>
</div>

<div class="breadcrumb-placeholder" *ngIf="!isComponentEmbedded"></div>

<div class="animated fadeIn"
     [ngClass]="{
      'container-horizontal-padding container-vertical-padding': !isComponentEmbedded,
      'container-horizontal-padding-half container-vertical-padding-half': isComponentEmbedded
     }">

  <div class="card">
    <div class="card-header">
      {{headerStringKey | translate}}

      <div class="card-actions d-flex">
        <app-breadcrumb-menu *ngIf="isComponentEmbedded && !baseCustomerRecordByPhoneNumberId">
          <!-- region BULK CHANGE-->
          <app-breadcrumb-button
            *ngIf="rightModel.taskRecordChangeAssignee.hasRight()"
            [iconClass]="'icomoon-deadline-alt'"
            [titleStringKey]="'COMMON_BUTTON_ASSIGNEE_MODIFICATION'"
            (click)="checkBulkUpdate('ASSIGNEE')">
          </app-breadcrumb-button>
          <app-breadcrumb-dropdown
            [titleStringKey]="'TASKS_DATA_MODIFY'">
            <app-dropdown-item
              *ngIf="rightModel.taskRecordChangeDeadline.hasRight()"
              [iconClass]="'icomoon-deadline'"
              [titleStringKey]="'COMMON_BUTTON_DEADLINE_MODIFICATION'"
              (click)="checkBulkUpdate('DEADLINE')">
            </app-dropdown-item>
            <app-dropdown-item
              *ngIf="rightModel.taskRecordGeocode.hasRight()"
              [iconClass]="'icomoon-location'"
              [titleStringKey]="'TASK_GEOCODING_TITLE'"
              (click)="checkBulkGeocode()">
            </app-dropdown-item>
            <app-dropdown-item
              *ngIf="rightModel.taskRecordRevert.hasRight()"
              [iconClass]="'icomoon-trash'"
              [titleStringKey]="'COMMON_VALUE_TASK_STATE_EDIT_REVERTED'"
              (click)="checkRevertIds()">
            </app-dropdown-item>
          </app-breadcrumb-dropdown>
          <!-- endregion BULK CHANGE-->

          <!-- region STATE CHANGE-->
          <app-breadcrumb-button
            *ngIf="settableStateOpen"
            [iconClass]="settableStateOpen.stateObject.iconEditClass"
            [titleStringKey]="settableStateOpen.text"
            (click)="checkState(settableStateOpen.stateObject)">
          </app-breadcrumb-button>
          <app-breadcrumb-dropdown
            [titleStringKey]="'PROCESS_EDIT_STATE_CHANGE'">
            <app-dropdown-item
              *ngFor="let state of settableStateList"
              [iconClass]="state.stateObject.iconEditClass"
              [titleStringKey]="state.text"
              (click)="checkState(state.stateObject)">
            </app-dropdown-item>
          </app-breadcrumb-dropdown>
          <!-- endregion STATE CHANGE-->

          <!-- region ATTACHMENT ZIP DOWNLOAD-->
          <app-breadcrumb-button
            *ngIf="rightModel.taskRecordAttachmentReadList.hasRight()"
            [iconClass]="'icomoon-download'"
            [titleStringKey]="'ATTACHMENT_DOWNLOAD_TITLE'"
            (click)="downloadAttachmentZip()">
          </app-breadcrumb-button>
          <app-breadcrumb-dropdown
            [titleStringKey]="'ATTACHMENT_DOWNLOAD_TITLE'">
            <app-dropdown-item
              *ngIf="rightModel.taskRecordRead.hasRight()"
              [iconClass]="'icomoon-download'"
              [titleStringKey]="'TASK_PDF_DOWNLOAD'"
              (click)="downloadPdfZip()">
            </app-dropdown-item>
            <app-dropdown-item
              *ngIf="rightModel.taskRecordRead.hasRight()"
              [iconClass]="'icomoon-thermal-paper'"
              [titleStringKey]="'TASK_PDF_PRINT'"
              (click)="downloadMergedPdf(true)">
            </app-dropdown-item>
          </app-breadcrumb-dropdown>
          <!-- endregion ATTACHMENT ZIP DOWNLOAD-->

          <!-- region EXPORT TO EXTERNAL SYSTEM-->
          <app-breadcrumb-button
            *ngIf="rightModel.taskRecordExportApi.hasRight() && !(rightModel.taskRecordExportFile.hasRight() && taskId)"
            [iconClass]="'icomoon-export-to-external-system'"
            [titleStringKey]="'COMMON_BUTTON_EXTERNAL_EXPORT'"
            (click)="checkExternalExportIds()">
          </app-breadcrumb-button>
          <!-- endregion EXPORT TO EXTERNAL SYSTEM-->

          <!-- region XLSX EXPORT-->
          <app-breadcrumb-button
            *ngIf="rightModel.taskRecordExportFile.hasRight()"
            [iconClass]="'icomoon-file-xlsx'"
            [titleStringKey]="'COMMON_EXPORT_XLSX'"
            (click)="exportXls()">
          </app-breadcrumb-button>
          <app-breadcrumb-dropdown
            [titleStringKey]="'COMMON_EXPORT_XLSX'">
            <app-dropdown-item
              *ngIf="rightModel.taskRecordImportFile.hasRight() && taskId"
              [iconClass]="'icomoon-file-xlsx'"
              [titleStringKey]="'ORDER_XLS_IMPORT_SAMPLE_DOWNLOAD'"
              (click)="exportXlsTemplate()">
            </app-dropdown-item>
            <app-dropdown-item
              *ngIf="maconomyEnabled && rightModel.taskRecordExportFile.hasRight()"
              [iconClass]="'icomoon-file-xlsx'"
              [titleStringKey]="'COMMON_BUTTON_MACONOMY_EXPORT'"
              (click)="exportMaconomy()">
            </app-dropdown-item>
            <app-dropdown-item
              *ngIf="rightModel.taskRecordExportFile.hasRight()"
              [iconClass]="'icomoon-sidebar-stock'"
              [titleStringKey]="'TASK_RECORD_EXPORT_FORM_STOCK_RECORD_XLSX'"
              (click)="showStockExportDialog()">
            </app-dropdown-item>
            <app-dropdown-item
              *ngIf="customXlsxExportEnabled && rightModel.taskRecordExportFileCustom.hasRight()"
              [iconClass]="'icomoon-file-xlsx'"
              [titleStringKey]="'TASK_RECORD_EXPORT_CUSTOM_XLSX'"
              (click)="exportCustomXls()">
            </app-dropdown-item>
            <app-dropdown-item
              *ngIf="supExportEnabled && rightModel.taskRecordExportFile.hasRight()"
              [iconClass]="'icomoon-invoice'"
              [titleStringKey]="'TASK_RECORD_EXPORT_SUP_CREATE'"
              (click)="exportSupCreate()">
            </app-dropdown-item>
            <app-dropdown-item
              *ngIf="supExportEnabled && rightModel.taskRecordExportFileCustom.hasRight()"
              [iconClass]="'icomoon-invoice'"
              [titleStringKey]="'TASK_RECORD_EXPORT_SUP_INVOICE'"
              (click)="exportSupInvoice()">
            </app-dropdown-item>
            <app-dropdown-item
              *ngIf="rightModel.taskRecordExportFile.hasRight() && taskId"
              [iconClass]="'icomoon-csv-export'"
              [titleStringKey]="'COMMON_BUTTON_CSV_EXPORT'"
              (click)="downloadCsv()">
            </app-dropdown-item>
            <app-dropdown-item
              *ngIf="rightModel.taskRecordExportApi.hasRight() && (rightModel.taskRecordExportFile.hasRight() && taskId)"
              [iconClass]="'icomoon-export-to-external-system'"
              [titleStringKey]="'COMMON_BUTTON_EXTERNAL_EXPORT'"
              (click)="checkExternalExportIds()">
            </app-dropdown-item>
            <app-dropdown-item
              *ngIf="rightModel.taskRecordRead.hasRight()"
              [iconClass]="'icomoon-file-xlsx'"
              [titleStringKey]="'TASK_FORM_TABLE_EXPORT'"
              (click)="downloadTableXls()">
            </app-dropdown-item>
          </app-breadcrumb-dropdown>
          <!-- endregion XLSX EXPORT-->

          <!-- region TASK RECORD CREATE-->
          <app-breadcrumb-button
            *ngIf="rightModel.taskRecordCreate.hasRight()"
            [iconClass]="'icomoon-add'"
            [titleStringKey]="'COMMON_BUTTON_ADD'"
            (click)="onCreateTaskRecord()">
          </app-breadcrumb-button>
          <app-breadcrumb-dropdown
            [titleStringKey]="'COMMON_BUTTON_ADD'">
            <app-dropdown-item
              *ngIf="taskId && (rightModel.taskRecordImportFile.hasRight() || demoModeEnabled)"
              [iconClass]="'icomoon-csv-import'"
              [titleStringKey]="'COMMON_BUTTON_TASK_RECORD_IMPORT'"
              [disabled]="!rightModel.taskRecordImportFile.hasRight()"
              (click)="rightModel.taskRecordImportFile.hasRight() ? importDialog.toggleDialog() : showDisabledDialog()">
            </app-dropdown-item>
            <app-dropdown-item
              *ngIf="rightModel.taskRecordCreate.hasRight()"
              [iconClass]="'icomoon-clone'"
              [titleStringKey]="'TASK_RECORD_BULK_CLONE_TITLE'"
              (click)="showBulkCloneDialog()">
            </app-dropdown-item>
          </app-breadcrumb-dropdown>
          <!-- endregion TASK RECORD CREATE-->
        </app-breadcrumb-menu>
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()"
           id="task-record-list-search-button"
           title="{{'COMMON_SHOW_SEARCH_HINT' | translate}}"><i #searchIcon
                                                                class="icomoon icomoon-search"></i></a>
        <a *ngIf="!showMap && !isComponentEmbedded" class="btn-setting cursor-pointer" (click)="toggleMap()"
           title="{{ 'TRANSPORT_LIST_SHOW_MAP' | translate}}"><i class="icomoon icomoon-transport-map"></i></a>
        <a *ngIf="showMap" class="btn-setting cursor-pointer" (click)="toggleMap()"
           title="{{ 'TRANSPORT_LIST_SHOW_LIST' | translate}}"><i class="icomoon icomoon-transport-list"></i></a>
      </div><!-- /.card-actions -->
    </div>

    <div class="card-body border-bottom" *ngIf="showSearch"
         id="task-record-list-search-panel">
      <responsive-tabset #searchTabs>
        <responsive-tab heading="{{'SEARCH_MODE_SIMPLE' | translate}}" (selectTab)="activeSearchTab = 'simple'"
                        id="simple">
          <div class="row form-group">
            <div class="col-sm-2" *ngIf="!showMap">
              <label class="search-label">{{'COMMON_TABLE_HEADER_ID' | translate}}</label>
              <input type="text" class="form-control" placeholder="{{'COMMON_TABLE_HEADER_ID' | translate}}"
                     maxlength="{{UiConstants.maxInputLongLength}}"
                     (keyup.enter)="onSearchClicked()"
                     [(ngModel)]="searchModel.id"
                     [textMask]="{mask: InputMask.NATURAL_INTEGER_WITH_THOUSAND_SEPARATOR, guide: false}"
                     name="id" id="id" #id="ngModel">
            </div>
            <div class="col-sm-2" *ngIf="!showMap && isManagedField('EXTERNAL_ID')">
              <label class="search-label">{{'TASK_RECORD_SEARCH_EXTERNAL_ID' | translate}}</label>
              <input type="text" class="form-control" placeholder="{{'TASK_RECORD_SEARCH_EXTERNAL_ID' | translate}}"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     (keyup.enter)="onSearchClicked()"
                     [(ngModel)]="searchModel.externalId" name="externalId" id="externalId" #externalId="ngModel">
            </div>
            <div class="col-sm-3" *ngIf="!showMap">
              <label class="search-label">{{'TASK_RECORD_NAME' | translate}}</label>
              <input type="text" class="form-control" placeholder="{{'TASK_RECORD_NAME' | translate}}"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     (keyup.enter)="onSearchClicked()"
                     [(ngModel)]="searchModel.name" name="name" id="name" #name="ngModel">
            </div>
            <div class="col-sm-3" *ngIf="!showMap && isManagedField('CUSTOMER')">
              <label class="search-label">{{'TASK_RECORD_SEARCH_CUSTOMER_NAME' | translate}}</label>
              <input type="text" class="form-control" placeholder="{{'TASK_RECORD_SEARCH_CUSTOMER_NAME' | translate}}"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     (keyup.enter)="onSearchClicked()"
                     [(ngModel)]="searchModel.customerName" name="customer_name" id="customer_name"
                     #customer_name="ngModel">
            </div>
            <div class="col-sm-2" *ngIf="!showMap && isManagedField('CUSTOMER')">
              <label class="search-label">{{'TASK_RECORD_SEARCH_CUSTOMER_PHONE_NUMBER' | translate}}</label>
              <input type="text" class="form-control"
                     placeholder="{{'TASK_RECORD_SEARCH_CUSTOMER_PHONE_NUMBER' | translate}}"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     (keyup.enter)="onSearchClicked()"
                     [(ngModel)]="searchModel.customerPhoneNumber" name="customer_phone_number"
                     id="customer_phone_number"
                     #customer_phone_number="ngModel">
            </div>
            <div class="col-sm-2">
              <label class="search-label">{{'TASK_RECORD_SEARCH_STATE' | translate}}</label>
              <angular2-multiselect [data]="taskStates" [settings]="dropdownSettingsForMulti"
                                    class="form-control"
                                    [(ngModel)]="searchModel.states"
                                    #states="ngModel" name="states" id="states">
              </angular2-multiselect>
            </div>
            <div class="col-sm-2" *ngIf="rightModel.contractNumberRead.hasRight()">
              <label class="search-label">{{'CUSTOMER_RECORD_CONTRACT_NUMBER' | translate}}</label>
              <angular2-multiselect [data]="contractNumbers" [settings]="dropdownSettingsForMultiWithRemoteSearch"
                                    class="form-control"
                                    [(ngModel)]="searchModel.contractNumbers"
                                    (onRemoteSearch)="onContractNumberSearch($event.target.value)"
                                    #contractNumber="ngModel" name="contractNumber" id="contractNumber">
              </angular2-multiselect>
            </div>
            <div class="col-sm-3"
                 *ngIf="!showMap && config.assigneeWithMobileApp && isManagedField('ASSIGNEE_USER_APPLICATION')">
              <label class="search-label">{{'TASK_RECORD_SEARCH_DEVICE' | translate}}</label>
              <angular2-multiselect [data]="devices" [settings]="dropdownSettingsWithRemoteSearch"
                                    class="form-control"
                                    (onRemoteSearch)="onDeviceSearch($event.target.value)"
                                    [(ngModel)]="searchModel.assigneeMobileApp"
                                    #deviceMulti="ngModel" name="deviceMulti" id="deviceMulti">
              </angular2-multiselect>
            </div>
            <div class="col-sm-3">
              <label class="search-label">{{'TASK_RECORD_SEARCH_CREATOR_USER' | translate}}</label>
              <angular2-multiselect [data]="users" [settings]="dropdownSettingsForMultiWithRemoteSearch"
                                    class="form-control"
                                    [(ngModel)]="searchModel.creatorUser"
                                    (onRemoteSearch)="onUserSearch($event.target.value)"
                                    #creatorMulti="ngModel" name="creatorMulti" id="creatorMulti">
              </angular2-multiselect>
            </div>
            <div class="col-sm-3" *ngIf="config.assigneeWithUser && isManagedField('ASSIGNEE_USER_APPLICATION')">
              <label class="search-label">{{'TASK_RECORD_SEARCH_USER_NAME' | translate}}</label>
              <angular2-multiselect [data]="users" [settings]="dropdownSettingsWithRemoteSearch"
                                    class="form-control"
                                    [(ngModel)]="searchModel.assigneeUser"
                                    (onRemoteSearch)="onUserSearch($event.target.value)"
                                    #userMulti="ngModel" name="userMulti" id="userMulti">
              </angular2-multiselect>
            </div>
            <div class="col-sm-3" *ngIf="isManagedField('ASSIGNEE_USER_APPLICATION')">
              <label class="search-label">{{'COMMON_USER_GROUP' | translate}}</label>
              <input type="text" class="form-control" placeholder="{{'COMMON_USER_GROUP' | translate}}"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     (keyup.enter)="onSearchClicked()"
                     [(ngModel)]="searchModel.userGroup" name="group" id="group" #group="ngModel">
            </div>
            <div class="col-sm-3"
                 *ngIf="!showMap && isManagedField('PROJECT') && !projectRecordId && rightModel.projectRecordRead.hasRight()">
              <label class="search-label">{{'TASK_RECORD_SEARCH_PROJECT' | translate}}</label>
              <angular2-multiselect [data]="projects" [settings]="dropdownSettingsForMultiWithRemoteSearch"
                                    class="form-control"
                                    [(ngModel)]="searchModel.projects"
                                    (onRemoteSearch)="loadProjectsForSearch($event.target.value)"
                                    #project="ngModel" name="project" id="project">
              </angular2-multiselect>
            </div>
            <div class="col-sm-3"
                 *ngIf="!showMap && isEnabledByServer('WORKFLOW') && rightModel.processRead.hasRight()">
              <label class="search-label">{{'TASK_RECORD_SEARCH_PROCESS' | translate}}</label>
              <angular2-multiselect [data]="processes" [settings]="dropdownSettingsForMultiWithRemoteSearch"
                                    class="form-control"
                                    [(ngModel)]="searchModel.processes"
                                    (onRemoteSearch)="loadProcessesForSearch($event.target.value)"
                                    #process="ngModel" name="process" id="process">
              </angular2-multiselect>
            </div>

            <div class="col-sm-3" *ngIf="!showMap">
              <label class="search-label">{{'TASK_RECORD_SEARCH_TASK_NAME' | translate}}</label>
              <angular2-multiselect [data]="tasksForSearch" [settings]="dropdownSettingsForTask"
                                    class="form-control"
                                    [(ngModel)]="searchModel.tasks"
                                    (onRemoteSearch)="loadTasksForSearch($event.target.value)"
                                    #taskName="ngModel" name="taskName" id="taskName">
              </angular2-multiselect>
            </div>

            <div class="col-sm-2" *ngIf="!showMap && isManagedField('IMPORTANCE')">
              <label class="search-label">{{'TASK_RECORD_SEARCH_IMPORTANCE' | translate}}</label>
              <select class="form-control" [(ngModel)]="searchModel.importance"
                      id="importance" name="importance" #importance="ngModel">
                <option *ngFor="let item of taskImportances" [ngValue]="item.id">{{item.text}}</option>
              </select>
            </div>

            <div class="col-sm-2" *ngIf="!showMap">
              <label class="search-label">{{'TASK_RECORD_SEARCH_EXPORT' | translate}}</label>
              <select class="form-control" [(ngModel)]="searchModel.exportState"
                      id="export" name="export" #export="ngModel">
                <option *ngFor="let item of exportStates" [ngValue]="item.id">{{item.text}}</option>
              </select>
            </div>

            <div class="col-md-4 col-sm-6" *ngIf="!showMap && isManagedField('DEADLINE')">
              <label class="search-label">{{'TASK_RECORD_SEARCH_DEADLINE' | translate}}</label>
              <div class="d-flex align-items-center">
                <div class="input-group">
                  <input type="text" class="form-control date"
                         placeholder="{{'COMMON_DATE_FROM' | translate}}"
                         [(ngModel)]="searchModel.deadlineFrom" ngbDatepicker name="deadline_from" id="deadline_from"
                         #deadline_from="ngbDatepicker">
                  <div class="input-group-addon-gray" (click)="deadline_from.toggle()">
                    <i class="icomoon icomoon-calendar"></i>
                  </div>
                </div>
                <span class="search-date-separator"></span>
                <div class="input-group">
                  <input type="text" class="form-control date"
                         placeholder="{{'COMMON_DATE_TO' | translate}}"
                         [(ngModel)]="searchModel.deadlineTo" ngbDatepicker name="deadline_to"
                         #deadline_to="ngbDatepicker"
                         id="deadline_to">
                  <div class="input-group-addon-gray" (click)="deadline_to.toggle()">
                    <i class="icomoon icomoon-calendar"></i>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-sm-6" *ngIf="!showMap">
              <label class="search-label">{{'TASK_RECORD_SEARCH_CREATION_TIME' | translate}}</label>
              <div class="d-flex align-items-center">
                <div class="input-group">
                  <input type="text" class="form-control date"
                         placeholder="{{'COMMON_DATE_FROM' | translate}}"
                         [(ngModel)]="searchModel.creationTimeFrom" ngbDatepicker name="creationTime_from"
                         id="creationTime_from"
                         #creationTime_from="ngbDatepicker">
                  <div class="input-group-addon-gray" (click)="creationTime_from.toggle()">
                    <i class="icomoon icomoon-calendar"></i>
                  </div>
                </div>
                <span class="search-date-separator"></span>
                <div class="input-group">
                  <input type="text" class="form-control date"
                         placeholder="{{'COMMON_DATE_TO' | translate}}"
                         [(ngModel)]="searchModel.creationTimeTo" ngbDatepicker name="creationTime_to"
                         id="creationTime_to"
                         #creationTime_to="ngbDatepicker">
                  <div class="input-group-addon-gray" (click)="creationTime_to.toggle()">
                    <i class="icomoon icomoon-calendar"></i>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-sm-6" *ngIf="!showMap">
              <label class="search-label">{{'TASK_SEARCH_FIELD_UPDATE_TIME' | translate}}</label>
              <div class="d-flex align-items-center">
                <div class="input-group">
                  <input type="text" class="form-control date"
                         placeholder="{{'COMMON_DATE_FROM' | translate}}"
                         [(ngModel)]="searchModel.updateTimeFrom" ngbDatepicker name="updateTime_from"
                         id="updateTime_from"
                         #updateTime_from="ngbDatepicker">
                  <div class="input-group-addon-gray" (click)="updateTime_from.toggle()">
                    <i class="icomoon icomoon-calendar"></i>
                  </div>
                </div>
                <span class="search-date-separator"></span>
                <div class="input-group">
                  <input type="text" class="form-control date"
                         placeholder="{{'COMMON_DATE_TO' | translate}}"
                         [(ngModel)]="searchModel.updateTimeTo" ngbDatepicker name="updateTime_to"
                         id="updateTime_to"
                         #updateTime_to="ngbDatepicker">
                  <div class="input-group-addon-gray" (click)="updateTime_to.toggle()">
                    <i class="icomoon icomoon-calendar"></i>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-sm-6" *ngIf="!showMap">
              <label class="search-label">{{'TASK_RECORD_SEARCH_RELEASE_TIME' | translate}}</label>
              <div class="d-flex align-items-center">
                <div class="input-group">
                  <input type="text" class="form-control date"
                         placeholder="{{'COMMON_DATE_FROM' | translate}}"
                         [(ngModel)]="searchModel.releaseTimeFrom" ngbDatepicker name="releaseTime_from"
                         id="releaseTime_from"
                         #releaseTime_from="ngbDatepicker">
                  <div class="input-group-addon-gray" (click)="releaseTime_from.toggle()">
                    <i class="icomoon icomoon-calendar"></i>
                  </div>
                </div>
                <span class="search-date-separator"></span>
                <div class="input-group">
                  <input type="text" class="form-control date"
                         placeholder="{{'COMMON_DATE_TO' | translate}}"
                         [(ngModel)]="searchModel.releaseTimeTo" ngbDatepicker name="releaseTime_to" id="releaseTime_to"
                         #releaseTime_to="ngbDatepicker">
                  <div class="input-group-addon-gray" (click)="releaseTime_to.toggle()">
                    <i class="icomoon icomoon-calendar"></i>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-sm-6" *ngIf="!showMap">
              <label class="search-label">{{'TASK_RECORD_SEARCH_AGREED_TIME' | translate}}</label>
              <div class="d-flex align-items-center">
                <div class="input-group">
                  <input type="text" class="form-control date"
                         placeholder="{{'COMMON_DATE_FROM' | translate}}"
                         [(ngModel)]="searchModel.agreedTimeFrom" ngbDatepicker name="agreedTimeFrom"
                         id="agreedTimeFrom"
                         #agreedTimeFrom="ngbDatepicker">
                  <div class="input-group-addon-gray" (click)="agreedTimeFrom.toggle()">
                    <i class="icomoon icomoon-calendar"></i>
                  </div>
                </div>
                <span class="search-date-separator"></span>
                <div class="input-group">
                  <input type="text" class="form-control date"
                         placeholder="{{'COMMON_DATE_TO' | translate}}"
                         [(ngModel)]="searchModel.agreedTimeTo" ngbDatepicker name="agreedTimeTo" id="agreedTimeTo"
                         #agreedTimeTo="ngbDatepicker">
                  <div class="input-group-addon-gray" (click)="agreedTimeTo.toggle()">
                    <i class="icomoon icomoon-calendar"></i>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-sm-6" *ngIf="!showMap">
              <label class="search-label">{{'TASK_RECORD_SEARCH_FINISHED_TIME' | translate}}</label>
              <div class="d-flex align-items-center">
                <div class="input-group">
                  <input type="text" class="form-control date"
                         placeholder="{{'COMMON_DATE_FROM' | translate}}"
                         [(ngModel)]="searchModel.finishedTimeFrom" ngbDatepicker name="finishedTimeFrom"
                         id="finishedTimeFrom"
                         #finishedTimeFrom="ngbDatepicker">
                  <div class="input-group-addon-gray" (click)="finishedTimeFrom.toggle()">
                    <i class="icomoon icomoon-calendar"></i>
                  </div>
                </div>
                <span class="search-date-separator"></span>
                <div class="input-group">
                  <input type="text" class="form-control date"
                         placeholder="{{'COMMON_DATE_TO' | translate}}"
                         [(ngModel)]="searchModel.finishedTimeTo" ngbDatepicker name="finishedTimeTo"
                         id="finishedTimeTo"
                         #finishedTimeTo="ngbDatepicker">
                  <div class="input-group-addon-gray" (click)="finishedTimeTo.toggle()">
                    <i class="icomoon icomoon-calendar"></i>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-3 col-sm-6" *ngIf="!showMap && isManagedField('PLACE_OF_CONSUMPTION')">
              <label class="search-label">{{'TASK_RECORD_SEARCH_POC_ADDRESS_CITY' | translate}}</label>
              <input type="text" class="form-control"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     (keyup.enter)="onSearchClicked()"
                     placeholder="{{'TASK_RECORD_SEARCH_POC_ADDRESS_CITY' | translate}}"
                     [(ngModel)]="searchModel.placeOfConsumptionCity">
            </div>
            <div class="col-md-3 col-sm-6" *ngIf="!showMap && isManagedField('PLACE_OF_CONSUMPTION')">
              <label class="search-label">{{'TASK_RECORD_SEARCH_POC_ADDRESS_ZIP_CODE' | translate}}</label>
              <input type="text" class="form-control"
                     maxlength="{{UiConstants.maxInputIntLength}}"
                     (keyup.enter)="onSearchClicked()"
                     placeholder="{{'TASK_RECORD_SEARCH_POC_ADDRESS_ZIP_CODE' | translate}}"
                     [(ngModel)]="searchModel.placeOfConsumptionZipCode">
            </div>
            <div class="col-md-3 col-sm-6" *ngIf="!showMap && isManagedField('PLACE_OF_CONSUMPTION')">
              <label class="search-label">{{'TASK_RECORD_SEARCH_POC_ADDRESS_STREET' | translate}}</label>
              <input type="text" class="form-control"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     (keyup.enter)="onSearchClicked()"
                     placeholder="{{'TASK_RECORD_SEARCH_POC_ADDRESS_STREET' | translate}}"
                     [(ngModel)]="searchModel.placeOfConsumptionStreet">
            </div>
            <div class="col-md-3 col-sm-6" *ngIf="!showMap && isManagedField('PLACE_OF_CONSUMPTION')">
              <label class="search-label">{{'TASK_RECORD_SEARCH_POC_ADDRESS_HOUSE_NUMBER' | translate}}</label>
              <input type="text" class="form-control"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     (keyup.enter)="onSearchClicked()"
                     placeholder="{{'TASK_RECORD_SEARCH_POC_ADDRESS_HOUSE_NUMBER' | translate}}"
                     [(ngModel)]="searchModel.placeOfConsumptionHouseNumber">
            </div>
            <div class="col-sm-3"
                 *ngIf="!showMap">
              <label class="search-label">{{'TASK_RECORD_SEARCH_EMPTY_INTAKE_PRICES' | translate}}</label>
              <div class="col-md-8">
                <label class="switch switch-text switch-info mb-0">
                  <input type="checkbox" class="switch-input"
                         [ngModelOptions]="{standalone: true}"
                         [(ngModel)]="searchModel.emptyIntakePrices">
                  <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                  <span class="switch-handle"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="row p-0">
            <div class="col-md-12 d-flex justify-content-md-end align-items-end pr-0 pl-0">
              <div class="btn-group" role="group">
                <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
                  {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
                </button>
                <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
                  {{'COMMON_BUTTON_SEARCH' | translate}}
                </button>
              </div>
            </div>
          </div>
        </responsive-tab>
        <responsive-tab heading="{{'SEARCH_MODE_DQL' | translate}}" (selectTab)="activeSearchTab = 'dql'" id="dql"
                        *ngIf="!showMap">
          <app-dql-search-container #dqlSearchContainer
                                    (onSearch)="onDqlSearchClicked()"
                                    [dqlStoredQueryArgs]="dqlStoredArgs"
                                    [rightModel]="rightModel"
                                    [dialogContainer]="dqlDialogHolder"></app-dql-search-container>
        </responsive-tab>
      </responsive-tabset>
    </div> <!-- ./card-body end -->

    <div class="card-body"
         id="task-record-list-panel"
         *ngIf="!showMap">
      <table class="table table-striped table-bordered">
        <thead
          id="task-record-list-header-row">
        <tr>
          <th class="table-sorter-header fit">
            <app-table-field-sorter [orderField]="TaskRecord.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(TaskRecord.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [checkAllEnabled]="true"
                                    [checkAllSelected]="eachTaskSelected"
                                    (onCheckAllClicked)="toggleEachTask()"
                                    id="task-record-list-header-id"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th *ngIf="!taskId" class="table-sorter-header hidden-1400-down">
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="TaskRecord.OrderField.NAME"
                                    [orderType]="queryModel.getOrderType(TaskRecord.OrderField.NAME)"
                                    (orderChange)="orderBy($event)"
                                    id="task-record-list-header-name"
                                    [text]="'TASK_RECORD_NAME' | translate"></app-table-field-sorter>
            <app-table-field-sorter [orderField]="TaskRecord.OrderField.EXTERNAL_ID"
                                    [orderType]="queryModel.getOrderType(TaskRecord.OrderField.EXTERNAL_ID)"
                                    (orderChange)="orderBy($event)"
                                    *ngIf="isManagedField('EXTERNAL_ID')"
                                    class="table-text-subtitle"
                                    [text]="'COMMON_EXTERNAL_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down"
              *ngIf="config.assigneeWithUser && isManagedField('ASSIGNEE_USER_APPLICATION')">
            <app-table-field-sorter [orderField]="TaskRecord.OrderField.ASSIGNEE_USER_NAME"
                                    id="task-record-list-header-user"
                                    [orderType]="queryModel.getOrderType(TaskRecord.OrderField.ASSIGNEE_USER_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'TASK_RECORD_TABLE_HEADER_USER_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down"
              *ngIf="config.assigneeWithMobileApp && isManagedField('ASSIGNEE_USER_APPLICATION')">
            <app-table-sorter-no-op
              id="task-record-list-header-mobile"
              [text]="'TASK_RECORD_TABLE_HEADER_DEVICE_NAME' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header"
              *ngIf="isManagedField('CUSTOMER') || isManagedField('PLACE_OF_CONSUMPTION')">
            <app-table-field-sorter [orderField]="TaskRecord.OrderField.CUSTOMER_NAME"
                                    [orderType]="queryModel.getOrderType(TaskRecord.OrderField.CUSTOMER_NAME)"
                                    (orderChange)="orderBy($event)"
                                    *ngIf="isManagedField('CUSTOMER')"
                                    [text]="'TASK_RECORD_TABLE_HEADER_CUSTOMER_NAME' | translate"></app-table-field-sorter>
            <app-table-field-sorter [orderField]="TaskRecord.OrderField.POC"
                                    [orderType]="queryModel.getOrderType(TaskRecord.OrderField.POC)"
                                    *ngIf="isManagedField('PLACE_OF_CONSUMPTION')"
                                    (orderChange)="orderBy($event)"
                                    class="table-text-subtitle"
                                    [text]="'TASK_RECORD_TABLE_HEADER_POC' | translate"></app-table-field-sorter>
          </th>
          <th *ngIf="isManagedField('DEADLINE') || isManagedField('AGREED_TIME')"
              class="table-sorter-header hidden-lg-down">
            <app-table-field-sorter [orderField]="TaskRecord.OrderField.AGREED_TIME"
                                    [orderType]="queryModel.getOrderType(TaskRecord.OrderField.AGREED_TIME)"
                                    (orderChange)="orderBy($event)"
                                    id="task-record-list-header-agreed-time"
                                    *ngIf="isManagedField('AGREED_TIME')"
                                    [text]="'TASK_LABEL_AGREED_TIME_SHORT' | translate"></app-table-field-sorter>
            <app-table-field-sorter [orderField]="TaskRecord.OrderField.DEADLINE"
                                    [orderType]="queryModel.getOrderType(TaskRecord.OrderField.DEADLINE)"
                                    (orderChange)="orderBy($event)"
                                    id="task-record-list-header-deadline"
                                    *ngIf="isManagedField('DEADLINE')"
                                    class="table-text-subtitle"
                                    [text]="'TASK_RECORD_TABLE_HEADER_DEADLINE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-1400-down">
            <app-table-field-sorter [orderField]="TaskRecord.OrderField.CREATION_TIME"
                                    [orderType]="queryModel.getOrderType(TaskRecord.OrderField.CREATION_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_CREATION_TIME' | translate"></app-table-field-sorter>
            <app-table-field-sorter [orderField]="TaskRecord.OrderField.CREATOR_USER"
                                    [orderType]="queryModel.getOrderType(TaskRecord.OrderField.CREATOR_USER)"
                                    (orderChange)="orderBy($event)"
                                    class="table-text-subtitle"
                                    [text]="'TASK_RECORD_TABLE_HEADER_CREATOR_USER' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-sorter-no-op
              id="task-record-list-header-state"
              [text]="'TASK_RECORD_TABLE_HEADER_STATE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr class="table"
            appTableColoredRow
            [colorIndex]="getColorIndex(model)"
            [rowIndex]="i"
            id="task-record-list-row-{{i}}"
            *ngFor="let model of taskRecordList | paginate: {
              id: getPagingId(),
              itemsPerPage: queryModel.itemsPerPage,
              currentPage: queryModel.currentPage,
              totalItems: queryModel.currentNumberOfItems }; index as i">
          <td class="fit align-middle" id="task-record-list-row-id-{{i}}">
            <div class="d-flex">
              <app-animated-checkbox
                class="toggle-checkbox"
                id="task-record-list-row-checkbox-{{i}}"
                [(selected)]="model.selected">
              </app-animated-checkbox>
              {{model.taskRecord.taskRecordId}}
            </div>
          </td>
          <td *ngIf="!taskId"
              id="task-record-list-row-task-{{i}}"
              class="hidden-1400-down fit align-middle">
            <div class="d-flex align-items-center" *ngIf="getTaskName(model.taskRecord.taskId)"
                 [popover]="taskTypePopover" triggers="mouseenter:mouseleave">
              <app-type-icon [ngStyle]="{'color': getColor(model)}" [icon]="getTaskIcon(model.taskRecord.taskId)"
                             class="mr-05"></app-type-icon>
            </div>
            <ng-template #taskTypePopover>
              <div class="d-flex align-middle">
                <app-type-icon [ngStyle]="{'color': getColor(model)}" [icon]="getTaskIcon(model.taskRecord.taskId)"
                               class="mr-05"></app-type-icon>
                <span>{{getTaskName(model.taskRecord.taskId)}}</span>
              </div>
            </ng-template>
          </td>
          <td class="align-middle w-35">
            <div class="d-flex align-items-center justify-content-between">
              <a class="id-text cursor-pointer mr-05"
                 *ngIf="isReadable(model); else taskRecordName"
                 id="task-record-list-row-name-{{i}}"
                 href="#" onclick="return false"
                 uiSref="Admin.TaskRecordDetail"
                 [uiParams]="{ taskRecordId: model.taskRecord.taskRecordId, taskId: model.taskRecord.taskId }"
                 title="{{'COMMON_BUTTON_DETAILS' | translate}}">
                {{model.taskRecord.name}}
              </a>
              <ng-template #taskRecordName>{{model.taskRecord.name}}</ng-template>
              <div class="d-flex flex-wrap justify-content-end">
              <span *ngIf="model.taskRecord.invoiceIds && model.taskRecord.invoiceIds.length > 0"
                    class="icomoon icomoon-invoice popover-trigger-icon icomoon-table-icon"
                    [popover]="invoicePopover" triggers="mouseenter:mouseleave"></span>
              <span *ngIf="model.taskRecord.agreedTime.isValid() && isManagedField('AGREED_TIME')"
                    class="icomoon icomoon-task-record-agreed-time popover-trigger-icon icomoon-table-icon"
                    [popover]="agreedTimePopover" triggers="mouseenter:mouseleave"></span>
              <span *ngIf="model.taskRecord.exportState === 'REVERTED'"
                    class="icomoon icomoon-succeded icomoon-trash popover-trigger-icon icomoon-table-icon"></span>
              <span *ngIf="model.taskRecord.exportState === 'REVERT_FAILED'"
                    class="icomoon-failed icomoon icomoon-trash popover-trigger-icon icomoon-table-icon"
                    popover="{{model.taskRecord.previousExportError.message ? model.taskRecord.previousExportError.message : ''}}"
                    [triggers]="model.taskRecord.previousExportError.message ? 'mouseenter:mouseleave' : ''"></span>
              <span *ngIf="model.taskRecord.exportState === 'EXPORTED'"
                    class="icomoon icomoon-administration-task-checked popover-trigger-icon icomoon-table-icon icomoon-succeded"
                    [popover]="exportPopover" triggers="mouseenter:mouseleave"></span>
              <span *ngIf="model.taskRecord.exportState === 'EXPORT_FAILED'"
                    class="icomoon icomoon-task-export-failed popover-trigger-icon icomoon-table-icon icomoon-failed"
                    [popover]="exportPopover" triggers="mouseenter:mouseleave"></span>
              <span *ngIf="model.taskRecord.attachmentCount > 0"
                    class="icomoon icomoon-attachments popover-trigger-icon icomoon-table-icon"
                    [popover]="attachmentCount" triggers="mouseenter:mouseleave"></span>
              <span *ngIf="model.taskRecord.chatMessageCount > 0"
                    class="icomoon icomoon-chat-message popover-trigger-icon icomoon-table-icon"
                    [popover]="messageCount" triggers="mouseenter:mouseleave" placement="right"></span>
              </div>
              <ng-template #invoicePopover>
                <div>
                  <i class="icomoon icomoon-invoice popover-icon mr-1"></i>
                  <span>{{'INVOICES_LIST_HEADING' | translate}}: {{model.taskRecord.invoiceIds.length}}</span>
                </div>
              </ng-template>
              <ng-template #agreedTimePopover>
                <div class="d-flex align-items-center">
                  <i class="icomoon icomoon-task-record-agreed-time popover-icon pr-1"></i>
                  <div>
                    <div>{{'TASK_RECORD_AGREED_TIME' | translate }}</div>
                    <div class="table-text-subtitle">
                      {{model.taskRecord.agreedTime.toUtcIsoString() | date:'short'}}
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-template #exportPopover>
                <i *ngIf="model.taskRecord.exportState === 'EXPORTED'"
                   class="icomoon-export-state-icons icomoon-succeded icomoon icomoon-administration-task-checked popover-icon"></i>
                <i *ngIf="model.taskRecord.exportState === 'EXPORT_FAILED'"
                   class="icomoon-export-state-icons icomoon-failed icomoon icomoon-task-export-failed popover-icon"></i>
                <span
                  *ngIf="model.taskRecord.exportState === 'EXPORTED'">{{'TASK_RECORD_LIST_POPUP_EXPORTED' | translate}}</span>
                <span
                  *ngIf="model.taskRecord.exportState === 'EXPORT_FAILED'">{{'TASK_RECORD_LIST_POPUP_EXPORT_FAILED' | translate}}</span>
                <div *ngIf="model.taskRecord.previousExportError && model.taskRecord.previousExportError.message">
                  {{model.taskRecord.previousExportError.message}}
                </div>
              </ng-template>
              <ng-template #attachmentCount>
                <div class="d-flex align-items-center">
                  <i class="icomoon icomoon-attachments popover-icon pr-1"></i>
                  <div>
                    <div>{{'COMMON_LABEL_ATTACHMENT_COUNT' | translate: {count: model.taskRecord.attachmentCount} }} </div>
                  </div>
                </div>
              </ng-template>
              <ng-template #messageCount>
                <div class="d-flex align-items-center">
                  <i class="icomoon icomoon-chat-message popover-icon pr-1"></i>
                  <div>
                    <div>
                      <div class="font-weight-bold">{{'TASK_RECORD_MESSAGES' | translate }} </div>
                    </div>
                  </div>
                </div>
                <div class="text-truncate table-text-subtitle mt-1">{{'TASK_RECORD_MESSAGE_COUNT' | translate}}</div>
                <div class="text-truncate">{{model.taskRecord.chatMessageCount}}</div>
                <ng-container *ngIf="model.taskRecord.lastChatMessage">
                  <div class="text-truncate table-text-subtitle mt-1">{{'TASK_RECORD_LATEST_MESSAGE' | translate}}</div>
                  <div class="font-weight-bold break-word">{{model.taskRecord.lastChatMessage.userPersonName}}</div>
                  <div class="break-word">{{model.taskRecord.lastChatMessage.content}}</div>
                </ng-container>
              </ng-template>
            </div>
            <span class=" table-text-subtitle"
                  *ngIf="isManagedField('EXTERNAL_ID')">{{model.taskRecord.externalId}}</span>
            <div *ngIf="model.taskRecord.process">
              <a class="id-text  table-text-subtitle cursor-pointer mr-05"
                 *ngIf="rightModel.processRead.hasRight(); else processExternalIdLabel"
                 id="task-record-list-row-process-{{i}}"
                 href="#" onclick="return false"
                 uiSref="Admin.ProcessDetail"
                 [uiParams]="{ id: model.taskRecord.process.id}"
                 title="{{'PROCESS' | translate}}">
                {{model.taskRecord.process.externalId}}
              </a>
              <ng-template #processExternalIdLabel>
                <span class=" table-text-subtitle">{{model.taskRecord.process.externalId}}</span>
              </ng-template>
            </div>
          </td>
          <td
            id="task-record-list-row-assignee-user-{{i}}"
            class="hidden-md-down responsive-table-column assignee-table-column align-middle"
            *ngIf="config.assigneeWithUser && isManagedField('ASSIGNEE_USER_APPLICATION')">
            <app-assignee-table-cell
              [assigneeType]="AssigneeType.USER"
              [user]="model.assignee.assigneeUser"
              [userGroup]="model.assignee.assigneeUserGroup"
              [userGroups]="relatedUserGroups"
              [rightModel]="rightModel">
            </app-assignee-table-cell>
          </td>
          <td
            id="task-record-list-row-assignee-mobile-{{i}}"
            class="hidden-md-down responsive-table-column assignee-table-column"
            *ngIf="config.assigneeWithMobileApp && isManagedField('ASSIGNEE_USER_APPLICATION')">
            <app-assignee-table-cell
              [assigneeType]="AssigneeType.MOBILE"
              [device]="model.assignee.assigneeMobileApp"
              [userGroup]="model.assignee.assigneeUserGroup"
              [rightModel]="rightModel">
            </app-assignee-table-cell>
          </td>
          <td
            *ngIf="isManagedField('CUSTOMER') || isManagedField('PLACE_OF_CONSUMPTION')"
            id="task-record-list-row-customer-{{i}}"
            class="responsive-table-column align-middle">
            <div  *ngIf="isManagedField('CUSTOMER')">
            <span *ngIf="rightModel.customerRecordRead.hasRight() && model.taskRecord.customerRecord && model.taskRecord.customerRecord.customerRecordId;
                         else customerName">
              <span
                class="icomoon icomoon-warning popover-trigger-icon icomoon-table-icon"
                *ngIf="isCustomerMatching(model)">
              </span>
              <a uiSref="Admin.CustomerRecordDetail"
                 *ngIf="!baseCustomerRecordByPhoneNumberId; else customerRecordName"
                 [uiParams]="{customerId: model.taskRecord.customerRecord.customerId, customerRecordId: model.taskRecord.customerRecord.customerRecordId}"
                 [popover]="customerPopover" triggers="mouseenter:mouseleave" container="body">
                {{model.taskRecord.customerRecord.name}}
              </a>
              <ng-template #customerRecordName>
                <span [popover]="customerPopover" triggers="mouseenter:mouseleave"
                      container="body">{{model.taskRecord.customerRecord.name}}</span>
              </ng-template>
            </span>
            <ng-template #customerName>
              {{model.customerName}}
            </ng-template>
            <ng-template #customerPopover>
              <div class="font-weight-bold">{{model.taskRecord.customerRecord.name}}</div>
              <div
                class="table-text-small"
                *ngIf="model.taskRecord.customerRecord.postalAddress">{{getCustomerPostalAddress(model.taskRecord.customerRecord.postalAddress)}}</div>
              <div *ngIf="model.taskRecord.customerRecord.phoneNumbers.size > 0"
                   class="table-text-subtitle table-text-small mt-1">{{'CUSTOMER_RECORD_PHONE_NUMBERS' | translate}}</div>
              <div
                *ngFor="let phoneNumber of model.taskRecord.customerRecord.phoneNumbers">{{phoneNumber.value.toIso()}}</div>
              <div *ngIf="model.taskRecord.customerRecord.emailAddresses.size > 0"
                   class="table-text-subtitle table-text-small mt-1">{{'CUSTOMER_RECORD_EMAIL_ADDRESSES' | translate}}</div>
              <div
                *ngFor="let emailAddress of model.taskRecord.customerRecord.emailAddresses">{{emailAddress.value.toIso()}}</div>
              <div *ngIf="model.taskRecord.customerRecord.ownerUsers.size > 0"
                   class="table-text-subtitle table-text-small mt-1">{{'CUSTOMER_RECORD_USERS' | translate}}</div>
              <div
                *ngFor="let user of model.taskRecord.customerRecord.ownerUsers">{{user.personName + ' (' + user.userName + ')'}}</div>
              <div *ngIf="model.taskRecord.customerRecord.ownerGroups.size > 0"
                   class="table-text-subtitle table-text-small mt-1">{{'CUSTOMER_RECORD_USER_GROUPS' | translate}}</div>
              <div *ngFor="let group of model.taskRecord.customerRecord.ownerGroups">{{group.name}}</div>
            </ng-template>
            </div>
            <div *ngIf="isManagedField('PLACE_OF_CONSUMPTION')" class=" table-text-subtitle">
              {{getPoc(model.taskRecord)}}
            </div>
          </td>
          <td *ngIf="isManagedField('DEADLINE') || isManagedField('AGREED_TIME')"
              id="task-record-list-row-deadline-{{i}}"
              class="fit icon-sizing hidden-lg-down align-middle">
            <div class="d-flex flex-column">
              <span *ngIf="isManagedField('AGREED_TIME')">
              {{model.taskRecord.agreedTime?.toUtcIsoString() |date:'short'}}
              </span>
              <ng-template #notOverdue>
              <span *ngIf="isManagedField('DEADLINE')" class="break-word table-text-subtitle">
                {{model.taskRecord.deadline.toUtcIsoString() | date: 'short'}}
              </span>
              </ng-template>
              <ng-container *ngIf="isOverdue(model); else notOverdue">
                <app-table-badge
                  [popover]="overduePopover"
                  triggers="mouseenter:mouseleave"
                  container="body"
                  [badgeStyle]="BadgeStyle.WARNING"
                  [iconClass]="'icomoon-expired'"
                  [breakWord]="true"
                  class="table-text-subtitle"
                  [text]="model.taskRecord.deadline.toUtcIsoString() | date: 'short'">
                </app-table-badge>
                <ng-template #overduePopover>
                  <div class="d-flex align-items-center">
                    <i class="icomoon icomoon-expired popover-icon pr-1"></i>
                    <div>
                      <div>{{'COMMON_OVERDUE' | translate }}</div>
                    </div>
                  </div>
                </ng-template>
              </ng-container>
            </div>
          </td>
          <td
            id="task-record-list-row-creation-time-{{i}}"
            class="responsive-table-column icon-sizing hidden-1400-down align-middle">
            <div class="d-flex flex-column">
              {{model.taskRecord.creationTime?.toUtcIsoString() |date:'short'}}
              <span class="table-text-subtitle text-break">{{model.taskRecord.creatorUser.personName}}</span>
            </div>
          </td>
          <td
            id="task-record-list-row-state-{{i}}"
            class="fit text-center icon-sizing hidden-xs-down align-middle">
            <app-table-badge
              [popover]="statePopover"
              triggers="mouseenter:mouseleave"
              container="body"
              placement="left"
              [showNotificationDot]="model.taskRecord.stateChangeLog && model.taskRecord.stateChangeLog.message"
              [badgeStyle]="model.stateObject.badgeStyle"
              [iconClass]="model.stateObject.iconClass"
              [text]="model.stateObject.stringKey | translate">
            </app-table-badge>
            <ng-template #statePopover>
              <div class="d-flex align-items-center">
                <i class="icomoon {{model.stateObject.iconClass}} popover-icon pr-1"></i>
                <div>
                  <div class="font-weight-bold">{{model.stateObject.stringKey | translate }}</div>
                </div>
              </div>
              <ng-container *ngIf="!model.taskRecord.stateChangeLog">
                <div class="text-truncate table-text-subtitle mt-1">{{'COMMON_CREATION_TIME' | translate}}</div>
                <div class="text-truncate">{{model.taskRecord.creationTime.toUtcIsoString() | date: 'short'}}</div>
              </ng-container>
              <ng-container *ngIf="model.taskRecord.stateChangeLog">
                <div
                  class="text-truncate table-text-subtitle mt-1">{{getLogTypeStringKey(model.taskRecord.stateChangeLog.logType) | translate}}</div>
                <div
                  class="text-truncate">{{model.taskRecord.stateChangeLog.logTime.toUtcIsoString() | date: 'short'}}</div>
                <ng-container *ngIf="model.taskRecord.stateChangeLog.message">
                  <div class="text-truncate table-text-subtitle mt-1">{{'COMMON_COMMENT' | translate}}</div>
                  <div class="break-word">{{model.taskRecord.stateChangeLog.message}}</div>
                </ng-container>
              </ng-container>
            </ng-template>
          </td>
          <td
            id="task-record-list-row-options-{{i}}"
            class="align-middle">
            <app-table-options-menu>
              <app-dropdown-item
                id="task-record-list-row-options-detail-{{i}}"
                *ngIf="isReadable(model)"
                [iconClass]="'icomoon-detail'"
                [titleStringKey]="'COMMON_BUTTON_DETAILS'"
                class="cursor-pointer"
                uiSref="Admin.TaskRecordDetail"
                [uiParams]="{ taskId: model.taskRecord.taskId, taskRecordId: model.taskRecord.taskRecordId }">
              </app-dropdown-item>
              <app-dropdown-item
                id="task-record-list-row-options-edit-{{i}}"
                *ngIf="isReadable(model) && model.rights.update.hasRight() && canModify(model.taskRecord, model.assignee.assigneeUser)"
                [iconClass]="'icomoon-modify'"
                [titleStringKey]="'COMMON_BUTTON_EDIT'"
                class="cursor-pointer"
                uiSref="Admin.TaskRecordEdit"
                [uiParams]="{ taskId: model.taskRecord.taskId, taskRecordId: model.taskRecord.taskRecordId }">
              </app-dropdown-item>
            </app-table-options-menu>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header fit">
            <app-table-field-sorter [orderField]="TaskRecord.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(TaskRecord.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [checkAllEnabled]="true"
                                    [checkAllSelected]="eachTaskSelected"
                                    (onCheckAllClicked)="toggleEachTask()"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th *ngIf="!taskId" class="table-sorter-header hidden-1400-down">
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="TaskRecord.OrderField.NAME"
                                    [orderType]="queryModel.getOrderType(TaskRecord.OrderField.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'TASK_RECORD_NAME' | translate"></app-table-field-sorter>
            <app-table-field-sorter [orderField]="TaskRecord.OrderField.EXTERNAL_ID"
                                    [orderType]="queryModel.getOrderType(TaskRecord.OrderField.EXTERNAL_ID)"
                                    (orderChange)="orderBy($event)"
                                    *ngIf="isManagedField('EXTERNAL_ID')"
                                    class="table-text-subtitle"
                                    [text]="'COMMON_EXTERNAL_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down"
              *ngIf="config.assigneeWithUser && isManagedField('ASSIGNEE_USER_APPLICATION')">
            <app-table-field-sorter [orderField]="TaskRecord.OrderField.ASSIGNEE_USER_NAME"
                                    [orderType]="queryModel.getOrderType(TaskRecord.OrderField.ASSIGNEE_USER_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'TASK_RECORD_TABLE_HEADER_USER_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down"
              *ngIf="config.assigneeWithMobileApp && isManagedField('ASSIGNEE_USER_APPLICATION')">
            <app-table-sorter-no-op
              [text]="'TASK_RECORD_TABLE_HEADER_DEVICE_NAME' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header"
              *ngIf="isManagedField('CUSTOMER') || isManagedField('PLACE_OF_CONSUMPTION')">
            <app-table-field-sorter [orderField]="TaskRecord.OrderField.CUSTOMER_NAME"
                                    [orderType]="queryModel.getOrderType(TaskRecord.OrderField.CUSTOMER_NAME)"
                                    (orderChange)="orderBy($event)"
                                    *ngIf="isManagedField('CUSTOMER')"
                                    [text]="'TASK_RECORD_TABLE_HEADER_CUSTOMER_NAME' | translate"></app-table-field-sorter>
            <app-table-field-sorter [orderField]="TaskRecord.OrderField.POC"
                                    [orderType]="queryModel.getOrderType(TaskRecord.OrderField.POC)"
                                    *ngIf="isManagedField('PLACE_OF_CONSUMPTION')"
                                    (orderChange)="orderBy($event)"
                                    class="table-text-subtitle"
                                    [text]="'TASK_RECORD_TABLE_HEADER_POC' | translate"></app-table-field-sorter>
          </th>
          <th *ngIf="isManagedField('DEADLINE') || isManagedField('AGREED_TIME')"
              class="table-sorter-header hidden-lg-down">
            <app-table-field-sorter [orderField]="TaskRecord.OrderField.AGREED_TIME"
                                    [orderType]="queryModel.getOrderType(TaskRecord.OrderField.AGREED_TIME)"
                                    (orderChange)="orderBy($event)"
                                    *ngIf="isManagedField('AGREED_TIME')"
                                    [text]="'TASK_LABEL_AGREED_TIME_SHORT' | translate"></app-table-field-sorter>
            <app-table-field-sorter [orderField]="TaskRecord.OrderField.DEADLINE"
                                    [orderType]="queryModel.getOrderType(TaskRecord.OrderField.DEADLINE)"
                                    (orderChange)="orderBy($event)"
                                    *ngIf="isManagedField('DEADLINE')"
                                    class="table-text-subtitle"
                                    [text]="'TASK_RECORD_TABLE_HEADER_DEADLINE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-1400-down">
            <app-table-field-sorter [orderField]="TaskRecord.OrderField.CREATION_TIME"
                                    [orderType]="queryModel.getOrderType(TaskRecord.OrderField.CREATION_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_CREATION_TIME' | translate"></app-table-field-sorter>
            <app-table-field-sorter [orderField]="TaskRecord.OrderField.CREATOR_USER"
                                    [orderType]="queryModel.getOrderType(TaskRecord.OrderField.CREATOR_USER)"
                                    (orderChange)="orderBy($event)"
                                    class="table-text-subtitle"
                                    [text]="'TASK_RECORD_TABLE_HEADER_CREATOR_USER' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-sorter-no-op [text]="'TASK_RECORD_TABLE_HEADER_STATE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [id]="getPagingId()"
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>
    <app-task-record-list-map
      *ngIf="showMap"
      #mapComponent
      [taskId]="taskId"
      [searchModel]="searchModel">
    </app-task-record-list-map>
  </div>
</div>

<app-file-upload-dialog
  [uploadPath]="uploadPath"
  (onResult)="onImportSuccess($event)"
  #importDialog
></app-file-upload-dialog>

<app-dql-dialog-container #dqlDialogHolder></app-dql-dialog-container>

<div bsModal [config]="UiConstants.modalConfig" #assigneeModificationDialog="bs-modal" class="modal fade" tabindex="-1"
     role="dialog"
     id="assigneeModificationDialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="assigneeModificationDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'TASK_SET_RESPONSIBLE' | translate}}</h4>
        <button type="button" class="close" (click)="closeAssigneeModificationDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div>
          <div class="col-sm-12 p-0">
            <div class="card">
              <div class="card-body">

                <div class="form-group" *ngIf="config.assigneeWithUser">
                  <label class="edit-form-label form-control-label">{{'TASK_DATA_MODIFICATION_USER' |
                    translate}}</label>
                  <div class="edit-form-input-group">
                    <angular2-multiselect [data]="users" [settings]="dropdownSettingsWithRemoteSearch"
                                          class="form-control"
                                          [(ngModel)]="assigneeModificationModel._assigneeUser"
                                          (onRemoteSearch)="onUserSearch($event.target.value)"
                                          #dataModificationAssigneeUser="ngModel"
                                          name="dataModificationAssigneeUser"
                                          id="dataModificationAssigneeUser">
                    </angular2-multiselect>
                  </div>
                </div>

                <div class="form-group" *ngIf="config.assigneeWithMobileApp">
                  <label class="edit-form-label form-control-label">{{'TASK_DATA_MODIFICATION_DEVICE' |
                    translate}}</label>
                  <div class="edit-form-input-group">
                    <angular2-multiselect [data]="devices" [settings]="dropdownSettingsWithRemoteSearch"
                                          class="form-control"
                                          (onRemoteSearch)="onDeviceSearch($event.target.value)"
                                          [(ngModel)]="assigneeModificationModel._assigneeMobileApp"
                                          #dataModificationAssigneeMobileApp="ngModel"
                                          name="dataModificationAssigneeMobileApp"
                                          id="dataModificationAssigneeMobileApp">
                    </angular2-multiselect>
                  </div>
                </div>
              </div>
            </div>
          </div><!--/.col-->
          <div class="alert alert-warning mt-1 mb-0">
            <h6 class="alert-title mb-0">{{'TASK_LIST_APPROVE_DATA_MODIFICATION_MESSAGE' | translate: {
              count:
              bulkModifiableTaskRecordIds.size
            } }}</h6>
          </div>
        </div>

      </div><!-- /.modal-body -->

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeAssigneeModificationDialog()">
          {{'COMMON_BUTTON_CANCEL' | translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="tryChangeAssignee()">
          {{'COMMON_BUTTON_SAVE_CHANGES' |
          translate}}
        </button>
      </div><!-- /.modal-footer -->
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<div bsModal [config]="UiConstants.modalConfig" #deadlineModificationDialog="bs-modal" class="modal fade" tabindex="-1"
     role="dialog"
     id="deadlineModificationDialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="deadlineModificationDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'TASK_LIST_DEADLINE_MODIFICATION' | translate}}</h4>
        <button type="button" class="close" (click)="closeDeadlineModificationDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div>
          <div class="col-sm-12">
            <div class="card">
              <div class="card-body">

                <div class="form-group">
                  <label class="form-control-label">{{'TASK_LABEL_DEADLINE' | translate}}</label>
                  <div>
                    <div class="clearfix row">
                      <div class="col-sm">
                        <div class="row">

                          <div class="input-group col-md-6">
                            <input placeholder="{{'DATE_TIME_PICKER_PLACEHOLDER_DATE' | translate}}" type="text"
                                   class="form-control"
                                   [(ngModel)]="deadlineModificationModel.deadlineDate"
                                   [ngModelOptions]="{standalone: true}"
                                   ngbDatepicker #date_picker="ngbDatepicker"
                                   validateLocalDate>
                            <span class="add-on input-group-addon-gray" (click)="date_picker.toggle()">
                              <i class="icomoon icomoon-calendar"></i>
                            </span>
                          </div>
                          <div class="input-group col-md-6">
                            <input placeholder="{{'DATE_TIME_PICKER_PLACEHOLDER_TIME' | translate}}" type="text"
                                   class="form-control hidden-value-when-disabled"
                                   [(ngModel)]="deadlineModificationModel.deadlineTime"
                                   [ngModelOptions]="{standalone: true}"
                                   [seconds]="false"
                                   [disabled]="deadlineModificationModel.deadlineDate === null"
                                   [allowEmptyValue]="false"
                                   ngbTimepicker #time_picker="ngbTimepicker"
                                   [toggleButtonId]="'time_picker_toggle_button'"
                                   [spinners]="true" [hourStep]="1" [minuteStep]="10">
                            <span class="add-on input-group-addon-gray"
                                  id="time_picker_toggle_button"
                                  (click)="time_picker.toggle()">
                              <i class="icomoon icomoon-time"></i>
                            </span>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="container-vertical-padding-half"></div>
            <div class="alert alert-danger mb-0">
              <h6 class="alert-title">{{'TASK_LIST_APPROVE_DATA_MODIFICATION_MESSAGE' | translate: {
                count:
                bulkModifiableTaskRecordIds.size
              } }}</h6>
            </div>
          </div><!--/.col-->
        </div>

      </div><!-- /.modal-body -->

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeDeadlineModificationDialog()">
          {{'COMMON_BUTTON_CANCEL' | translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="tryChangeDeadline()">{{'COMMON_BUTTON_SAVE_CHANGES' |
          translate}}
        </button>
      </div><!-- /.modal-footer -->
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<div bsModal [config]="UiConstants.modalConfig" #geocodingDialog="bs-modal" class="modal fade" tabindex="-1"
     role="dialog" aria-labelledby="myModalLabel"
     id="geocodingDialog"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="geocodingDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'TASK_GEOCODING_TITLE' | translate}}</h4>
        <button type="button" class="close" (click)="closeGeocodingDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div>
          <div class="alert alert-danger">
            <h6 class="alert-title">{{'TASK_LIST_APPROVE_DATA_MODIFICATION_MESSAGE' | translate: {
              count:
              bulkModifiableTaskRecordIds.size
            } }}</h6>
          </div>
        </div>

      </div><!-- /.modal-body -->

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeGeocodingDialog()">{{'COMMON_BUTTON_CANCEL' |
          translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="tryGeocode()">{{'COMMON_BUTTON_SAVE_CHANGES' |
          translate}}
        </button>
      </div><!-- /.modal-footer -->
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->


<div bsModal [config]="UiConstants.modalConfig" #externalExportDialog="bs-modal" class="modal fade" tabindex="-1"
     role="dialog"
     id="externalExportDialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="externalExportDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'COMMON_BUTTON_EXTERNAL_EXPORT' | translate}}</h4>
        <button type="button" class="close" (click)="closeExternalExportDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div>
          <div class="alert alert-danger">
            <h6 class="alert-title">{{'TASK_LIST_APPROVE_EXPORT_MESSAGE' | translate: {
              count:
              checkedIds.size
            } }}</h6>
          </div>
        </div>

      </div><!-- /.modal-body -->

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeExternalExportDialog()">{{'COMMON_BUTTON_CANCEL' |
          translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="exportToExternalSystem(checkedIds)">
          {{'COMMON_BUTTON_NEXT' |
          translate}}
        </button>
      </div><!-- /.modal-footer -->
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->


<div bsModal [config]="UiConstants.modalConfig" #createPreselectDialog="bs-modal" class="modal fade" tabindex="-1"
     role="dialog"
     id="createPreselectDialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="createPreselectDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'TASK_RECORD_CREATE_TYPE' | translate}}</h4>
        <button type="button" class="close" (click)="closeCreatePreselectDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body create-body">
        <div class="row m-0">
          <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 p-0" *ngFor="let task of tasksForCreate">
            <div class="create-card card card-hover-border cursor-pointer fixed-card-height"
                 (click)="createTaskRecord(task.taskId)">
              <div class="card-body d-flex justify-content-start align-items-center">
                <div class="h1 float-left mb-0 d-flex align-items-center">
                  <app-type-icon [icon]="task.icon" big="true" class="mr-1"></app-type-icon>
                </div>
                <div>
                  <div class="h4 task-name">{{task.name}}</div>
                  <small class="text-muted task-id">{{task.taskId}}</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div><!-- /.modal-body -->
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary cursor-pointer"
                (click)="closeCreatePreselectDialog()">{{'COMMON_BUTTON_CANCEL' |
          translate}}
        </button>
      </div><!-- /.modal-footer -->
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->


<div bsModal [config]="UiConstants.modalConfig" #stockExportDialog="bs-modal" class="modal fade" tabindex="-1"
     id="stockExportDialog"
     role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="stockExportDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title"><strong>{{'TASK_RECORD_EXPORT_FORM_STOCK_RECORD_XLSX' | translate}}</strong></p>
        <button type="button" class="close" (click)="closeStockExportDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form class="form-horizontal bordered-row" (ngSubmit)="exportStockXls()" [formGroup]="stockExportForm">
        <div class="modal-body">

          <div class="form-group row">
            <label class="col-form-label form-control-label col-md-4 detail-title">
              {{'TASK_RECORD_EXPORT_FORM_STOCK_RECORD_INCLUDE_OTHER_PRODUCTS' | translate}}
            </label>
            <div class="col-md-8">
              <label class="switch switch-text switch-info mb-0">
                <input type="checkbox" class="switch-input"
                       [ngModelOptions]="{standalone: true}"
                       [(ngModel)]="stockExportIncludeOtherProducts">
                <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                <span class="switch-handle"></span>
              </label>
            </div>
          </div>

          <div class="form-group row"
               *ngIf="stockExportIncludeOtherProducts"
               [ngClass]="{ 'has-danger': (stockExportForm.controls['stock'].touched && !stockExportForm.controls['stock'].valid)}">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label">{{'COMMON_STOCK'|translate}}</label>
            </div>
            <div class="col-md-8">
              <angular2-multiselect class="form-control"
                                    [data]="stocks"
                                    [settings]="dropdownSettingsWithRemoteSearch"
                                    [(ngModel)]="stockExportSelectedStock"
                                    (onRemoteSearch)="loadStocks($event.target.value)"
                                    formControlName="stock">
              </angular2-multiselect>
              <div class="form-control-feedback"
                   *ngIf="stockExportForm.controls['stock'].touched && stockExportForm.controls['stock'].hasError('required')">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
          </div>

        </div>
        <!-- /.modal-body -->
        <div class="modal-footer">
          <div class="d-flex justify-content-end">
            <input type="button" class="btn btn-outline-primary" value="{{'COMMON_BUTTON_CANCEL'|translate}}"
                   (click)="closeStockExportDialog()"/>
          </div>
          <div class="d-flex justify-content-end">
            <input type="submit" class="btn btn-primary pl-1" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
          </div>
        </div>
      </form>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<app-task-record-bulk-clone-dialog
  #bulkCloneDialog
  (onSuccess)="loadList()">
</app-task-record-bulk-clone-dialog>
