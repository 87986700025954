/* eslint-disable */
import { Injectable } from '@angular/core';
import { List, Map } from 'immutable';
import { StringKey } from '../app.string-keys';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FieldValidationError, Services } from './util/services';
import { FieldError, ObservableErrorResourceParser } from './util/errors';
/* eslint-enable */

@Injectable()
export class ErrorMessageService {

  constructor(private translateService: TranslateService) {
  }

  parseError(error: any): ErrorMessage | undefined {

      let hasFieldErrors = false;
      if (Object.prototype.hasOwnProperty.call(error, 'error')) {
        const globalErrorArray: string[] = [];
        const errors = error['error'];
        if (Object.prototype.hasOwnProperty.call(errors, 'extra')) {
          const extra = errors['extra'];
          if (Object.prototype.hasOwnProperty.call(extra, 'global_errors')) {
            const globalErrors: any[] = extra['global_errors'];
            globalErrors.forEach((ge) => {
              if (Object.prototype.hasOwnProperty.call(ge, 'text')) {
                const text = ge['text'];
                globalErrorArray.push(text);
              }
            });
          }
          if (Object.prototype.hasOwnProperty.call(extra, 'field_errors')) {
            const fieldErrors: any[] = extra['field_errors'];
            const map = Services.resourceToStringMap(fieldErrors, (v) => {
              return v;
            });
            hasFieldErrors = !map.isEmpty();
          }
        }
        return new ErrorMessage(error, hasFieldErrors, List.of(...globalErrorArray));
      }
      return undefined;
  }

  localize(msg?: ErrorMessage): Observable<ErrorDetail> {
    return this.translateService.get([
      StringKey.COMMON_ERROR_DIALOG_TITLE,
      StringKey.COMMON_ERROR_DIALOG_MESSAGE]).pipe(
      map((res): ErrorDetail => {
        const title: string = res[StringKey.COMMON_ERROR_DIALOG_TITLE];
        const message: string = res[StringKey.COMMON_ERROR_DIALOG_MESSAGE];
        if (msg && !msg.globalErrors.isEmpty()) {
          return {
            title: title,
            message: msg.globalErrors.join('\n')
          }
        }
        return {
          title: title,
          message: message
        }
      })
    );
  }

}

export class ErrorMessage {

  // For now we just need the global errors.
  // It is a simple immutable bean so easy to extend.
  // ErrorMessageService uses it for localized message calculation.
  constructor(private readonly res, public readonly hasFieldErrors: boolean, public readonly globalErrors: List<string>) {
  }

  public extractFieldErrors(): any {
    if (!this.hasFieldErrors) {
      throw Error('No field errors');
    }
    const res = ObservableErrorResourceParser.parseError(this.res);
    return ObservableErrorResourceParser.extractFieldErrors(res);
  }

  public parseFieldErrors<T>(keyMapper: (key: string) => T): FieldValidationError<T> {
    const fieldErrors = this.extractFieldErrors();
    const fieldErrorMap: Map<T, FieldError> = ObservableErrorResourceParser.toFieldErrorMap(keyMapper, fieldErrors);
    return FieldValidationError.of(fieldErrorMap);
  }

}

export class ErrorDetail {
  readonly title: string;
  readonly message: string;
}
