import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { FieldValidationError, ForwardingNgFormRef, LocalFormGroupValidationErrors } from '../../../../lib/util/services';
import { SelectUtils, UiConstants } from '../../../../util/core-utils';
import { Invoice } from '../../../../lib/invoice/invoice/invoice.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { InvoiceSettings } from '../../../../lib/invoice/invoice-settings/invoice-settings.service';
import { NgbDatePickerParserFormatter } from '../../../../util/ngb-datepicker';
import { EmptyMessage } from '../../../../lib/util/messages';
import { InputMask } from '../../../../util/input-masks';
import { LedgerNumber, LedgerNumberService } from '../../../../lib/ledger/number/ledger-number.service';
import { Strings } from '../../../../lib/util/strings';

@Component({
  selector: 'app-ledger-number-dialog',
  templateUrl: './ledger-number-dialog.component.html',
  styleUrls: ['./ledger-number-dialog.component.scss']
})
export class LedgerNumberDialogComponent implements OnInit {

  Invoice = Invoice;
  UiConstants = UiConstants;
  InvoiceSettings = InvoiceSettings;
  SelectUtils = SelectUtils;
  InputMask = InputMask;
  ValidatedField = LedgerNumber.ValidatedField;

  model: LedgerNumberModel = new LedgerNumberModel();

  @ViewChild('f', {static: true})
  form: NgForm;
  formGroup: FormGroup;
  private formGroupValidationErrors: LocalFormGroupValidationErrors;
  private fieldErrors: FieldValidationError<LedgerNumber.ValidatedField> =
    FieldValidationError.empty<LedgerNumber.ValidatedField>();

  ledgerNumber?: LedgerNumber.LedgerNumber;

  get readonly(): boolean {
    return this.data.readonly;
  }

  constructor(public dialogRef: MatDialogRef<LedgerNumberDialogComponent>,
              private translateService: TranslateService,
              private datePickerParserFormatter: NgbDatePickerParserFormatter,
              private fb: FormBuilder,
              private ledgerNumberService: LedgerNumberService,
              @Inject(MAT_DIALOG_DATA) public data: LedgerNumberDialogData) {
    this.loadModel();
    this.formGroup = this.createFormGroup(this.fb);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(
      this.createForwardingHtmlForm(),
      this.formGroup
    );
  }

  ngOnInit() {
  }

  closeDialog(modified: boolean) {
    this.dialogRef.close({modified: modified});
  }

  save() {
    if (this.hasLocalFieldError()) {
      return;
    }
    else {
      if (this.data.ledgerNumberId) {
        this.updateLedgerNumber();
      }
      else {
        this.createLedgerNumber();
      }
    }
  }

  hasFieldError(field?: LedgerNumber.ValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: LedgerNumber.ValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: LedgerNumber.ValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  private createForwardingHtmlForm() {
    return new ForwardingNgFormRef({
      formFn: () => {
        return this.form;
      }
    });
  }

  private createFormGroup(fb: FormBuilder): FormGroup {
    return fb.group(
      {
        name: fb.control(
          {value: this.model.name, disabled: this.readonly},
          []
        ),
        ledgerNumber: fb.control(
          {
            value: this.model.ledgerNumber,
            disabled: this.readonly
          },
          [Validators.required]
        ),
      }
    );
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  createLedgerNumber() {
    this.formGroup.updateValueAndValidity();
    if (this.formGroup.invalid) {
      return;
    }
    this.ledgerNumberService.create({
      ledgerNumber: this.model.ledgerNumber,
      name: Strings.undefinedOrNonEmpty(this.model.name),
    }).subscribe(
      (result: EmptyMessage) => {
        this.closeDialog(true);
      },
      error => {

      });
  }

  updateLedgerNumber() {
    this.formGroup.updateValueAndValidity();
    if (this.formGroup.invalid) {
      return;
    }
    this.ledgerNumberService.update({
      id: this.data.ledgerNumberId!,
      ledgerNumber: this.model.ledgerNumber,
      name: Strings.undefinedOrNonEmpty(this.model.name),
    }).subscribe(
      (result: EmptyMessage) => {
        this.closeDialog(true);
      },
      error => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error;
        }
      });
  }

  private loadModel() {
    this.model = new LedgerNumberModel();

    if (this.data.ledgerNumberId) {
      this.ledgerNumberService.get({
        id: this.data.ledgerNumberId!,
        fields: f => f.each
      }).subscribe(l => {
        this.model.ledgerNumber = l.ledgerNumber;
        this.model.name = Strings.optToString(l.name);
        this.ledgerNumber = l;
      });
    }
  }

  getDialogTitle(): string {
    if (this.data.readonly) {
      return 'LEDGER_NUMBER_DETAIL';
    }
    if (this.data.ledgerNumberId) {
      return 'LEDGER_NUMBER_UPDATE';
    }
    return 'LEDGER_NUMBER_CREATE';
  }
}

class LedgerNumberModel {
  name: string = '';
  ledgerNumber: string = '';
}

export interface LedgerNumberDialogData {
  ledgerNumberId?: number;
  readonly: boolean;
}

export interface LedgerNumberDialogResult {
  modified: boolean;
}
