/* eslint-disable */
import { AfterViewInit, Component, ViewChild, } from '@angular/core';
import {
  FieldValidationError,
  FormRef,
  LocalFieldValidationErrors,
  LocalFieldValidationErrorsFactory,
} from '../../../../../../../lib/util/services';
import {
  FormEditFieldCreateDialogView,
  FormEditFieldDialogContext,
  FormEditFieldUpdateDialogView,
  FormGroupModel,
  FormModel,
} from '../../../../../../../util/form/form-utils';
import { Form } from '../../../../../../../lib/form/form.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgModel } from '@angular/forms';
import { FieldWidthType } from '../../../../../../../util/form/form-field-width-type';
import { InputMask } from '../../../../../../../util/input-masks';
import { List } from 'immutable';
import {
  FieldValidationType,
  FormFieldValidationTypeModel,
} from '../../../../../../../util/form/form-field-validation-type';
import { Models } from '../../../../../../../util/model-utils';
import { UiConstants } from '../../../../../../../util/core-utils';

/* eslint-enable */

@Component({
  selector: 'app-form-edit-create-update-number-field-dialog',
  templateUrl: 'form-edit-create-update-number-field-dialog.component.html',
  styleUrls: ['form-edit-create-update-number-field-dialog.component.scss'],
})
export class FormEditCreateUpdateNumberFieldDialogComponent
  implements FormEditFieldCreateDialogView, FormEditFieldUpdateDialogView, FormRef, AfterViewInit {

  public readonly selector = Form.FieldDataTypeSelector.NUMBER;
  public readonly createTitle = 'FORM_ITEM_CREATE_INTEGER_TITLE';
  public readonly updateTitle = 'FORM_ITEM_UPDATE_INTEGER_TITLE';
  public readonly cloneTitle = 'FORM_ITEM_CLONE_INTEGER_TITLE';

  InputMask = InputMask;
  Form = Form;
  UiConstants = UiConstants;
  Models = Models;

  @ViewChild('fieldDialog', { static: true })
  dialogComponent: ModalDirective;

  @ViewChild('title')
  titleInput: NgModel;

  @ViewChild('api_export_name')
  apiExportNameInput: NgModel;

  visible: boolean = false;
  fieldModel: Model = new Model();
  dialogTitleDictionaryKey: string = '';
  latestApiExportName = '';
  minMaxValueValid: boolean = true;
  maxMaxValueValid: boolean = true;

  fieldWidthTypes: FieldWidthType[] = FieldWidthType.VALUES.toArray();
  fieldValidationTypes: Form.FormFieldValidationType[] = FieldValidationType.VALUES.toArray();

  private fieldErrors: FieldValidationError<Form.FieldValidatedField> =
    FieldValidationError.empty<Form.FieldValidatedField>();

  private validatedInputs: LocalFieldValidationErrors<NgModel> =
    LocalFieldValidationErrorsFactory.empty();

  private context: FormEditFieldDialogContext;
  private group: FormGroupModel;
  private field?: Form.Field;
  private clone?: boolean;
  private submitted: boolean = false;

  defValueValid: boolean = true;
  minValueValid: boolean = true;

  getForm() {
    return {
      submitted: this.submitted
    };
  }

  ngAfterViewInit(): void {
    this.loadLocalFieldValidationErrors();
  }

  registerContext(context: FormEditFieldDialogContext): void {
    this.context = context;
  }

  showDialog(form: FormModel, group: FormGroupModel, field?: Form.Field, clone?: boolean): void {
    this.latestApiExportName = '';
    this.visible = true;
    this.group = group;
    this.clone = clone;
    this.dialogComponent.show();
    if (field && clone) {
      this.dialogTitleDictionaryKey = this.cloneTitle;
      this.loadField(field);
    }
    else if (field) {
      this.dialogTitleDictionaryKey = this.updateTitle;
      this.loadField(field);
    }
    else {
      this.dialogTitleDictionaryKey = this.createTitle;
    }
  }

  onModalHide(event: ModalDirective) {
    if (event.dismissReason === 'backdrop-click' || event.dismissReason === 'esc') {
      this.visible = false;
      this.submitted = false;
      this.fieldModel = new Model();
    }
  }

  closeDialog(): void {
    this.visible = false;
    this.submitted = false;
    this.dialogComponent.hide();
    this.fieldModel = new Model();
  }

  private updateDefValueValidity() {
    this.removeFieldError(Form.FieldValidatedField.DEFAULT_VALUE);
    const min = this.parseNumber(this.fieldModel.minValueText);
    const max = this.parseNumber(this.fieldModel.maxValueText);
    const def = this.parseNumber(this.fieldModel.defaultValue);
    if (this.submitted && def) {
      if (min) {
        if (def < min) {
          this.defValueValid = false;
          return;
        }
      }
      if (max) {
        if (def > max) {
          this.defValueValid = false;
          return;
        }
      }
    }
    this.defValueValid = true;
  }

  private updateMinValueValidity() {
    this.removeFieldError(Form.FieldValidatedField.MIN_VALUE);
    const min = this.parseNumber(this.fieldModel.minValueText);
    const max = this.parseNumber(this.fieldModel.maxValueText);
    if (this.submitted && min && max) {
      this.minValueValid = min <= max;
      return;
    }
    this.minValueValid = true;
  }

  private updateMinMaxValueValidity() {
    const min = this.fieldModel.minValueText;
    const max = this.fieldModel.maxValueText;

    if (min && min.length > this.parseNumber(this.UiConstants.maxInputLongLength)! ) {
      this.minMaxValueValid = false;
    }
    else {
      this.minMaxValueValid = true;
    }
    if (max && max.length > this.parseNumber(this.UiConstants.maxInputLongLength)!) {
      this.maxMaxValueValid = false;
    }
    else {
      this.maxMaxValueValid = true;
    }

    if (!this.minMaxValueValid || !this.maxMaxValueValid) {
      return
    }
  }

  saveField() {

    this.submitted = true;
    this.updateMinValueValidity();
    this.updateDefValueValidity();
    this.updateMinMaxValueValidity();
    if (this.hasLocalFieldError(this.titleInput) || this.hasLocalFieldError(this.apiExportNameInput)
      || !this.defValueValid || !this.minValueValid || !this.minMaxValueValid || !this.maxMaxValueValid) {
      return;
    }
    if (this.field && this.clone) {
      this.createField();
    }
    else if (this.field) {
      this.updateField(this.field);
    }
    else {
      this.createField();
    }
  }

  hasLocalFieldError(field?: NgModel): boolean {
    return this.validatedInputs.hasLocalError(field);
  }

  hasFieldError(field?: Form.FieldValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: Form.FieldValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: Form.FieldValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  getFieldValidationTypeDictionaryKey(vt: Form.FormFieldValidationType): string {
    return FieldValidationType.getFieldValidationTypeDictionaryKey(vt);
  }

  onAdminFormFieldValidationTypeChanged() {
    FieldValidationType.onAdminFormFieldValidationTypeChanged(this.fieldModel);
  }

  onMobileFormFieldValidationTypeChanged() {
    FieldValidationType.onMobileFormFieldValidationTypeChanged(this.fieldModel);
  }

  private parseNumber(text: string): number | undefined {
    return text.length > 0 ? Number(text) : undefined;
  }

  private loadLocalFieldValidationErrors() {
    const validatedInputs = List.of(this.titleInput, this.apiExportNameInput);
    this.validatedInputs = LocalFieldValidationErrorsFactory.ofFields(this, validatedInputs);
  }

  private loadField(field: Form.Field) {
    this.field = field;
    const attrs = field.dataType.numberAttributes!;
    const minValue = attrs.minValue;
    const maxValue = attrs.maxValue;
    const placeholder = attrs.hint;
    const defaultValue = attrs.defaultValue;
    const fieldWidth = FieldWidthType.fromPercentValue(field.formFieldWidthPercent);
    this.fieldModel.title = field.title;
    this.fieldModel.description = field.description ? field.description : '';
    this.fieldModel.hint = field.hint ? field.hint : '';
    this.fieldModel.apiExportName = field.apiExportName ? field.apiExportName : '';
    this.fieldModel.pdfExportName = field.pdfExportName ? field.pdfExportName : '';
    this.fieldModel.minValueText = '' + minValue;
    this.fieldModel.maxValueText = '' + maxValue;
    this.fieldModel.defaultValue = defaultValue === undefined ? '' : '' + defaultValue;
    this.fieldModel.fieldWidthType = fieldWidth ? fieldWidth : FieldWidthType.DEFAULT;
    this.fieldModel.displayOnNewRow = field.displayOnNewRow;
    this.fieldModel.placeholder = placeholder ? placeholder : '';
    this.fieldModel.adminVisibleOnFormDetail = field.admin.visibleOnFormDetail;
    this.fieldModel.mobileVisibleOnFormDetail = field.mobile.visibleOnFormDetail;
    this.fieldModel.mobileVisibleOnMasterDetail = field.mobile.visibleOnMasterDetail;
    this.fieldModel.adminFormFieldValidationType = field.admin.validationType;
    this.fieldModel.helpdeskFormFieldValidationType = field.helpdesk.validationType;
    this.fieldModel.mobileFormFieldValidationType = field.mobile.validationType;
    this.fieldModel.thousandSeparatorEnabled = attrs.thousandSeparatorEnabled ? attrs.thousandSeparatorEnabled : false;
    this.fieldModel.showOnReceipt = field.showOnReceipt;
  }

  private createField() {
    this.context.formService.createField({
      parentId: this.context.parentId,
      groupId: this.group.groupId,
      title: this.fieldModel.title,
      description: this.fieldModel.description,
      hint: this.fieldModel.hint,
      pdfExportName: this.fieldModel.pdfExportName,
      apiExportName: this.fieldModel.apiExportName,
      dataTypeSelector: this.selector,
      dataType: this.getDataTypeRequest(),
      formFieldWidthPercent: this.fieldModel.fieldWidthType.percentValue,
      displayOnNewRow: this.fieldModel.displayOnNewRow,
      admin: {
        visibleOnFormDetail: this.fieldModel.adminVisibleOnFormDetail,
        validationType: this.fieldModel.adminFormFieldValidationType,
      },
      helpdesk: {
        validationType: this.fieldModel.helpdeskFormFieldValidationType
      },
      mobile: {
        visibleOnFormDetail: this.fieldModel.mobileVisibleOnFormDetail,
        visibleOnMasterDetail: this.fieldModel.mobileVisibleOnMasterDetail,
        validationType: this.fieldModel.mobileFormFieldValidationType
      },
      showOnReceipt: this.fieldModel.showOnReceipt
    }).subscribe(
      (result) => {
        this.context.formView.reloadForm();
        this.closeDialog();
      },
      (error) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withFormRef(this);
        }
      }
    );
  }

  private updateField(field: Form.Field) {
    this.context.formService.updateField({
      parentId: this.context.parentId,
      groupId: this.group.groupId,
      fieldId: field.fieldId,
      title: this.fieldModel.title,
      description: this.fieldModel.description,
      hint: this.fieldModel.hint,
      pdfExportName: this.fieldModel.pdfExportName,
      apiExportName: this.fieldModel.apiExportName,
      dataTypeSelector: this.selector,
      dataType: this.getDataTypeRequest(),
      formFieldWidthPercent: this.fieldModel.fieldWidthType.percentValue,
      displayOnNewRow: this.fieldModel.displayOnNewRow,
      admin: {
        visibleOnFormDetail: this.fieldModel.adminVisibleOnFormDetail,
        validationType: this.fieldModel.adminFormFieldValidationType,
      },
      helpdesk: {
        validationType: this.fieldModel.helpdeskFormFieldValidationType
      },
      mobile: {
        visibleOnFormDetail: this.fieldModel.mobileVisibleOnFormDetail,
        visibleOnMasterDetail: this.fieldModel.mobileVisibleOnMasterDetail,
        validationType: this.fieldModel.mobileFormFieldValidationType
      },
      showOnReceipt: this.fieldModel.showOnReceipt
    }).subscribe(
      (result) => {
        this.context.formView.reloadForm();
        this.closeDialog();
      },
      (error) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withFormRef(this);
        }
      }
    );
  }

  private getDataTypeRequest(): Form.FieldDataType {
    return {
      numberAttributes: {
        hint: this.fieldModel.placeholder,
        minValue: this.parseNumber(this.fieldModel.minValueText),
        maxValue: this.parseNumber(this.fieldModel.maxValueText),
        defaultValue: this.parseNumber(this.fieldModel.defaultValue),
        thousandSeparatorEnabled: this.fieldModel.thousandSeparatorEnabled
      }
    };
  }

  onTitleBlur() {
    const normalizedText = Models.normalizeText(this.fieldModel.title);
    if (this.fieldModel.apiExportName === '') {
      this.fieldModel.apiExportName = normalizedText;
    }
    if (this.fieldModel.pdfExportName === '') {
      this.fieldModel.pdfExportName = normalizedText;
    }
  }
}

export class Model implements FormFieldValidationTypeModel {
  title: string = '';
  description: string = '';
  apiExportName: string = '';
  pdfExportName: string = '';
  minValueText: string = '';
  maxValueText: string = '';
  defaultValue: string = '';
  fieldWidthType: FieldWidthType = FieldWidthType.DEFAULT;
  displayOnNewRow: boolean = false;
  placeholder: string = '';
  hint: string = '';
  adminVisibleOnFormDetail: boolean = true;
  mobileVisibleOnFormDetail: boolean = true;
  mobileVisibleOnMasterDetail: boolean = false;
  thousandSeparatorEnabled: boolean = true;
  adminFormFieldValidationType: Form.FormFieldValidationType = Form.FormFieldValidationType.OPTIONAL;
  helpdeskFormFieldValidationType: Form.FormFieldValidationType = Form.FormFieldValidationType.OPTIONAL;
  mobileFormFieldValidationType: Form.FormFieldValidationType = Form.FormFieldValidationType.OPTIONAL;
  showOnReceipt?: boolean = false;
}
