import { DqlQuery, Query } from '../field';
import { User } from './user';
import { LazyReference } from '../../../util/lazy';

export class GlobalCalendarEvent {

  private readonly creatorUserRef: LazyReference<User>;

  readonly id: Query.NumberField;
  readonly startTime: Query.DateTimeField;
  readonly endTime: Query.DateTimeField;
  readonly title: Query.StringField;
  readonly color: Query.NumberField;

  get creatorUser(): User {
    return this.creatorUserRef.get();
  }

  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this.id = new DqlQuery.NumberField(prefix + 'id');
    this.startTime = new DqlQuery.DateTimeField(prefix + 'start_time');
    this.endTime = new DqlQuery.DateTimeField(prefix + 'end_time');
    this.title = new DqlQuery.StringField(prefix + 'title');
    this.color = new DqlQuery.NumberField(prefix + 'color');
    this.creatorUserRef = LazyReference.of(
      () => new User(prefix + 'creator_user.')
    );
  }

}
