<h2 class="mb-2">{{'LOGIN_COMPONENT_TITLE' | translate}}</h2>
<p>{{'HELPDESK_LOGIN_CARD_SUBTITLE' | translate:{environment: environmentName} }}</p>
<div *ngIf="model.error" class="alert alert-danger mt-1" role="alert">
  <strong>{{model.error.message}}</strong>
  <br *ngIf="model.error.message.length > 0">
  {{model.error.description}}
</div>
<div *ngIf="getLoginRequiredReason()" class="alert {{getLoginRequiredReason().getAlertType()}} mt-1"
     role="alert">
  <strong>{{getLoginRequiredReason().getTitleKey() | translate}}</strong>
  <br *ngIf="getLoginRequiredReason().getTitleKey() | translate | nonEmpty">
  {{getLoginRequiredReason().getTextKey() | translate }}
</div>
<form class="form-horizontal bordered-row" (ngSubmit)="f.form.valid && onSubmit.emit()" #f="ngForm">
  <div class="form-group row mb-1" [ngClass]="{ 'has-danger': f.submitted && !username.valid }">
    <div class="col-12">
      <label class="mw-100 col-form-label form-control-label required-field-label">
        {{'LOGIN_COMPONENT_USERNAME' | translate}}
      </label>
    </div>
    <div class="col-12">
      <input
        class="form-control"
        type="text"
        placeholder="{{'LOGIN_COMPONENT_USERNAME' | translate}}"
        autocomplete="username"
        [(ngModel)]="model.username"
        [ngClass]="{ 'form-control-danger': f.submitted && !username.valid }"
        id="username" name="username" #username="ngModel" required>
      <div *ngIf="f.submitted && !username.valid"
           class="form-control-feedback">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
    </div>
  </div>
  <div class="form-group row mb-2" [ngClass]="{ 'has-danger': f.submitted && !loginPassword.valid }">
    <div class="col-12 d-flex justify-content-between">
      <label class="col-form-label form-control-label required-field-label">
        {{'LOGIN_COMPONENT_PASSWORD' | translate}}
      </label>
      <a class="id-text cursor-pointer ml-half"
         href="#" onclick="return false"
         uiSref="PasswordRequest"
         tabindex="-1"
         title="{{'LOGIN_COMPONENT_REQUEST_NEW_PASSWORD' | translate}}">
        {{'LOGIN_COMPONENT_REQUEST_NEW_PASSWORD' | translate}}
      </a>
    </div>
    <div class="col-12">
      <div class="input-group">
        <input class="form-control"
               type="{{passwordVisible ? 'text' : 'password'}}"
               placeholder="{{'LOGIN_COMPONENT_PASSWORD' | translate}}"
               autocomplete="current-password"
               [(ngModel)]="model.password"
               [ngClass]="{ 'form-control-danger': f.submitted && !loginPassword.valid }"
               id="loginPassword" name="loginPassword" #loginPassword="ngModel" required>
        <span (click)="togglePasswordVisibility()"
              class="input-group-addon-gray icomoon icomoon-enable cursor-pointer font-2xl"></span>
      </div>
      <div *ngIf="f.submitted && !loginPassword.valid"
           class="form-control-feedback">{{'LOGIN_COMPONENT_PASSWORD_REQUIRED' | translate}}</div>
    </div>
  </div>
  <button type="submit" class="col-12 btn btn-primary btn-ladda px-2" data-style="zoom-in"
          [ladda]="model.inProgress" [disabled]="model.inProgress">
    {{'LOGIN_COMPONENT_TITLE' | translate}}
  </button>
</form>
