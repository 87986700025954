/* eslint-disable */
import { EventEmitter, Injectable } from '@angular/core';
/* eslint-enable */

@Injectable()
export class RefreshFormOnChangeService {

  formChanged: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  onFormChanged() {
    this.formChanged.next();
  }

}
