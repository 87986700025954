// Environment for release. Contains all the available properties. Include this file into ts files.
// The file contents for the current environment will overwrite these during build.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {

  // Build
  production: true,

  // Environment
  // DO NOT CHANGE!!!
  // This default value works well if the admin is hosted by the backend on the same hostname and port. Cloud support.
  // Set the baseUrl property in the 'environment/config.json' file.
  baseUrl: '/service/internal/v{{apiVersion}}',
  developerCompanyName: 'Applion Kft.',
  poweredByCompanyName: '',
  poweredByCompanyUrl: '',

  showBackgroundErrors: false,

  googleMapsApiKey: '', // only used as a default value if not present in config.json
  recaptchaV2SiteKey: '', // only used as a default value if not present in config.json
  recaptchaV3SiteKey: '', // only used as a default value if not present in config.json
  firebase: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: ''
  },
  registrationConversionCode: ''
};
