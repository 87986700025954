import { DqlQuery, Query } from '../field';
import { LazyReference } from '../../../util/lazy';
import { UserGroups } from './user-group';
import { Order } from './order';

// Each class should be an interface, but it is easier to define the constant fields in the constructors.
// For now we have only one query language so it is not a problem.
// Always use the public Query namespace for variable definitions so we will be able to introduce other language easily.

export class OrderDocument {

  readonly id: Query.StringField;

  readonly creationTime: Query.DateTimeField;

  readonly contentSize: Query.NumberField;

  readonly contentType: Query.StringField;

  readonly originalFileName: Query.StringField;

  private readonly orderReference: LazyReference<Order>; // Lazy to support circular references.

  private readonly uploaderUserReference: LazyReference<UploaderUser>; // Lazy to support circular references.

  private readonly meta: OrderDocumentMeta;

  get order(): Order {
    return this.orderReference.get();
  }

  get uploaderUser(): UploaderUser {
    return this.uploaderUserReference.get();
  }

  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this.id = new DqlQuery.StringField(prefix + 'id');
    this.creationTime = new DqlQuery.DateTimeField(prefix + 'creation_time');
    this.contentSize = new DqlQuery.NumberField(prefix + 'content_size');
    this.contentType = new DqlQuery.StringField(prefix + 'content_type');
    this.originalFileName = new DqlQuery.StringField(prefix + 'original_file_name');
    this.orderReference = LazyReference.of(
      () => new Order(prefix + 'order.')
    );
    this.uploaderUserReference = LazyReference.of(
      () => new UploaderUser(prefix + 'uploader_user.')
    );
    this.meta = new OrderDocumentMeta(prefix + 'meta.')
  }

}

export class OrderDocumentMeta {

  readonly comment: Query.StringField;
  readonly type: Query.StringField;

  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this.comment = new DqlQuery.StringField(prefix + 'comment');
    this.type = new DqlQuery.StringField(prefix + 'type');
  }

}

export class UploaderUser {

  readonly id: Query.NumberField;

  private readonly userGroupsReference: LazyReference<UserGroups>; // Lazy to support circular references.

  get userGroup(): UserGroups {
    return this.userGroupsReference.get();
  }

  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this.id = new DqlQuery.NumberField(prefix + 'id');
    this.userGroupsReference = LazyReference.of(
      () => new UserGroups(prefix + 'user_groups.')
    );
  }

}
