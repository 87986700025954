/* eslint-disable */
import { GrantedPermissionSet } from './lib/right.service';
import { UserProfile, } from './lib/auth.service';
import { PermissionDefinition, PermissionDefinitions } from './app.permission-definitions';
import { RightModel } from './app.rights';
import { MasterData } from './lib/masterdata/master-data.service';
/* eslint-enable */

export interface MasterDataRecordRightModelContext {
  rightModel: RightModel;
  currentUser?: UserProfile;
  currentUserGroups?: number[];
}

/**
 * Extension for masterdata record rights.
 * @see UserGrantedPermissionSet
 */
export class MasterDataRecordCreateRightModel {

  public readonly masterDataRecordCreate: MasterDataRecordCreateGrantedPermissionSet;

  static empty() {
    return new MasterDataRecordCreateRightModel();
  }

  static of(context: MasterDataRecordRightModelContext) {
    return new MasterDataRecordCreateRightModel(context);
  }

  private static map<T>(
    presentFactory: (
      context: MasterDataRecordRightModelContext) => T,
      absentFactory: () => T,
      context?: MasterDataRecordRightModelContext): T {
    if (context) {
      return presentFactory(context);
    }
    else {
      return absentFactory();
    }
  }

  private constructor(context?: MasterDataRecordRightModelContext) {
    this.masterDataRecordCreate = MasterDataRecordCreateRightModel.map(
      (c: MasterDataRecordRightModelContext) =>
        MasterDataRecordCreateGrantedPermissionSet.of(c.rightModel, c.currentUser, c.currentUserGroups),
      () => MasterDataRecordCreateGrantedPermissionSet.empty(),
      context);
  }

}

class MasterDataRecordGrantedPermissionSet implements GrantedPermissionSet {

  constructor(private delegate: GrantedPermissionSet,
              private currentUser?: UserProfile,
              private currentUserGroups?: number[],
              private allPermission?: string,
              private minePermission?: string,
              private myMasterDataPermission?: string) {
  }

  public hasRight(): boolean {
    return this.delegate.hasRight();
  }

  public contains(permission: PermissionDefinition): boolean {
    return this.delegate.contains(permission);
  }

  public hasRightForMasterData(masterData?: MasterData.MasterData): boolean {
    if (this.hasAllPermission()) {
      return true;
    }
    if (this.hasMyMasterDataPermission(masterData)) {
      return true;
    }
    return false;
  }

  private hasAllPermission(): boolean {
    if (!this.allPermission) {
      return false;
    }
    return this.delegate.contains(this.allPermission);
  }

  private hasMyMasterDataPermission(masterData?: MasterData.MasterData): boolean {
    if (!masterData) {
      return false;
    }
    if (!this.currentUser) {
      return false;
    }
    if (!this.currentUserGroups) {
      return false;
    }
    if (!this.myMasterDataPermission) {
      return false;
    }
    if (masterData.ownerUserGroupIds.filter((c) => this.currentUserGroups!.find(x => x === c) !== undefined).length > 0) {
      return this.delegate.contains(this.myMasterDataPermission);
    }
    return false;
  }

}

export class MasterDataRecordCreateGrantedPermissionSet extends MasterDataRecordGrantedPermissionSet {

  static empty(): MasterDataRecordCreateGrantedPermissionSet {
    return new MasterDataRecordCreateGrantedPermissionSet(GrantedPermissionSet.empty());
  }

  static of(rightModel: RightModel, currentUser?: UserProfile, currentUserGroups?: number[]): MasterDataRecordCreateGrantedPermissionSet {
    return new MasterDataRecordCreateGrantedPermissionSet(
      rightModel.masterDataRecordCreate,
      currentUser,
      currentUserGroups,
      PermissionDefinitions.MASTER_DATA_RECORD_CREATE_ALL,
      undefined,
      PermissionDefinitions.MASTER_DATA_RECORD_CREATE_MASTER_DATA_USER_GROUP_RELATED);
  }

}

