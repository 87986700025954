/* eslint-disable */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Set } from 'immutable';
import { QueryFieldModel, SelectUtils, UiConstants } from '../../../util/core-utils';
import { Customer, CustomerService } from '../../../lib/customer/customer.service';
import { OrderType, QueryResult, } from '../../../lib/util/services';
import { RightModel } from '../../../app.rights';
import { RightResolver, RightService } from '../../../lib/right.service';
import { LoadingHandler } from '../../../lib/loading-handler';
import { TranslateService } from '@ngx-translate/core';
import {
  CustomerSearchModel,
  CustomerTypeForSearchItem,
  CustomerTypeItem,
  CustomerTypesForSearchItems
} from '../../../util/customer-utils';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { StateName } from '../../../app.state-names';
import { InputMask } from '../../../util/input-masks';
import { combineLatest, Observable } from 'rxjs';
import { Arrays } from '../../../lib/util/arrays';
import { Strings } from '../../../lib/util/strings';
import { Models } from '../../../util/model-utils';
import { CustomerSearch, CustomerSearchService } from '../../../lib/customer-search-service';
import { DisabledEnum, DisabledItem } from '../../../util/search-utils';
import { DropdownItemType } from '../../../shared/dropdown/dropdown-item/dropdown-item-type';
import CustomerType = Customer.CustomerType;
import CustomerTypeForSearch = Customer.CustomerTypeForSearch;
import { OperationRights } from '../../../app.right-definitions';

/* eslint-enable */

@Component({
  selector: 'app-customer-list',
  templateUrl: 'customer-list.component.html',
  styleUrls: ['customer-list.component.scss']
})
export class CustomerListComponent implements OnInit, OnDestroy {

  Customer = Customer;
  UiConstants = UiConstants;
  DropdownItemType = DropdownItemType;

  queryModel: QueryFieldModel<Customer.OrderField> = new QueryFieldModel(Customer.OrderField.ID, OrderType.DESC);
  customerList: Array<Customer.Customer> = [];
  showSearch: boolean = false;
  rightModel: RightModel = RightModel.empty();
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  searchModel: CustomerSearchModel = new CustomerSearchModel();
  customerDisabledItems: DisabledItem[] = [];
  customerTypeForSearchItems: CustomerTypeForSearchItem[] = [];
  InputMask = InputMask;
  SelectUtils = SelectUtils;

  customerTypes: CustomerTypeItem[] = [];

  ngOnInit() {
    this.translateService.get('MENU_NAVBAR_MENU_CUSTOMER_TYPES').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.queryModel = new QueryFieldModel(Customer.OrderField.ID, OrderType.DESC);
    this.loadRightModels();
    this.loadCustomerTypes();
    this.loadSearch(() => {
      this.showSearch = !this.searchModel.isEmpty();
      this.initDisabledOptions(DisabledEnum.FALSE);
      this.initCustomerTypesForSearchOptions('ALL');
      this.loadList();
    });
  }

  private loadSearch(completion: () => void) {
    const obs: Observable<SearchLoadResult> = combineLatest(
      this.customerSearchService.getSearchData({}),
      (storedSearchData: CustomerSearch.SearchDataResult) => {
        const result: SearchLoadResult = {
          storedSearchData: storedSearchData
        };
        return result;
      }
    );
    obs.subscribe(
      (result: SearchLoadResult) => {
        this.postInitSearch(result.storedSearchData);
        completion();
      }
    );
  }

  private initDisabledOptions(initValue: DisabledEnum) {
    this.customerDisabledItems = [];
    const disabledEnums: DisabledEnum[] = [DisabledEnum.NONE, DisabledEnum.FALSE, DisabledEnum.TRUE];
    Arrays.iterateByIndex(disabledEnums, (key) => {
      const item = new DisabledItem();
      item.id = key;
      this.translateService.get('COMMON_VALUE_DISABLED_ENUM_' + key).subscribe(
        (text: string) => {
          item.text = text;
        }
      );
      this.customerDisabledItems.push(item);
      if (!this.searchModel.disabled) {
        if (key === initValue) {
          this.searchModel.disabled = item;
        }
      }
    });
  }

  private initCustomerTypesForSearchOptions(initValue: CustomerTypeForSearch) {
    this.customerTypeForSearchItems = [];
    const customerTypeForSearchEnums: CustomerTypeForSearch[] = CustomerTypesForSearchItems.values;

    Arrays.iterateByIndex(customerTypeForSearchEnums, (key) => {
      const item = new CustomerTypeForSearchItem();
      item.id = key;
      this.translateService.get('CUSTOMER_SEARCH_FIELD_TYPE_' + key).subscribe(
        (text: string) => {
          item.text = text;
        }
      );
      this.customerTypeForSearchItems.push(item);
      if (!this.searchModel.type) {
        if (key === initValue) {
          this.searchModel.type = item;
        }
      }
    });
  }

  private postInitSearch(storedSearchData: CustomerSearch.SearchDataResult) {
    this.queryModel.itemsPerPage = storedSearchData.searchData.itemsPerPage;
    this.queryModel.currentPage = storedSearchData.searchData.pageNumber;
    this.queryModel.setOrder(storedSearchData.searchData.order);
    this.searchModel.id = storedSearchData.searchData.id;
    this.searchModel.name = storedSearchData.searchData.name;
    this.searchModel.type = storedSearchData.searchData.type;
    this.searchModel.externalId = storedSearchData.searchData.external_id;
    this.searchModel.disabled = storedSearchData.searchData.disabled;
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: Customer.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  setDisabled(id: number, disabled: boolean) {
    this.customerService.setDisabled({
      customerId: id,
      disabled: disabled
    }).subscribe(
      (result: Customer.Customer) => {
        LoadingHandler.getInstance().refresh();
        this.loadList();
      },
      (error: any) => {
        this.loadList();
      }
    );
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  loadCustomerTypes() {
    const keys: string[] = [];
    Customer.customerTypes.forEach((type) => {
      this.customerTypes.push({id: type.type, text: '', key: type.stringKey});
      keys.push(type.stringKey);
    });
    this.translateService.get(keys).subscribe((texts) => {
      this.customerTypes.forEach((type) => {
        type.text = texts[type.key];
      });
    });
  }

  private loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();
    const customerIdSet: number[] = [];
    const disabled: boolean | undefined = !this.searchModel.disabled ||
    this.searchModel.disabled.id === DisabledEnum.NONE ?
      undefined : this.searchModel.disabled.id === DisabledEnum.TRUE;
    if (Models.parseNumber(this.searchModel.id)) {
      customerIdSet.push(Models.parseNumber(this.searchModel.id)!);
    }
    this.customerService.query({
      customerIdSet: Set.of(...customerIdSet),
      name: Strings.undefinedOrNonEmpty(this.searchModel.name),
      externalId: Strings.undefinedOrNonEmpty(this.searchModel.externalId),
      types: this.searchModel.type ? this.getCustomerType() : undefined,
      disabled: disabled,
      orders: Set.of(order),
      paging: {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      }
    }).subscribe(
      (result: QueryResult<Customer.Customer>) => {
        this.customerList = result.items.toArray();
        this.queryModel.currentPage = requestedPage;
        this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
        this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
      }
    );
  }

  getDisabled(): boolean | undefined {
    if (this.searchModel.disabled!.id === DisabledEnum.FALSE) {
      return false;
    }
    else if (this.searchModel.disabled!.id === DisabledEnum.TRUE) {
      return true;
    }
    return undefined;
  }

  getCustomerType(): CustomerType | undefined {
    if (this.searchModel.type!.id === 'PERSON') {
      return 'PERSON';
    }
    else if (this.searchModel.type!.id === 'COMPANY') {
      return 'COMPANY';
    }
    else {
      return undefined;
    }
  }

  onSearchClicked() {
    this.loadList();
  }

  onSearchReset() {
    this.customerSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.showSearch = true;
          this.loadList(1);
        });
      }
    );
  }

  private saveSearch() {
    const request = {
      searchData: {
        itemsPerPage: this.queryModel.itemsPerPage,
        pageNumber: this.queryModel.currentPage,
        order: this.queryModel.getOrder(),
        id: this.searchModel.id,
        name: this.searchModel.name,
        external_id: this.searchModel.externalId,
        type: this.searchModel.type,
        disabled: this.searchModel.disabled
      }
    };
    this.customerSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  getTypeName(type: Customer.CustomerType) {
    const item: CustomerTypeItem | undefined = this.customerTypes.find((t) => t.id === type);
    return item ? item.text : '';
  }

  ngOnDestroy() {
    this.saveSearch();
  }

  constructor(
    private rightService: RightService,
    private customerService: CustomerService,
    private translateService: TranslateService,
    private customerSearchService: CustomerSearchService) {
  }

}

interface SearchLoadResult {
  storedSearchData: CustomerSearch.SearchDataResult,
}

