/* eslint-disable */
import { Type } from '@angular/core';
import { DqlSearchFieldSimpleComponent } from './dql-search-field-simple/dql-search-field-simple.component';
import { DqlSearchFieldComponent } from './dql-search-field.component';
import { DqlSearchFieldListComponent } from './dql-search-field-list/dql-search-field-list.component';
import { DqlSearchFieldEnumComponent } from './dql-search-field-enum/dql-search-field-enum.component';
import { Map } from 'immutable';
import { DqlModel } from '../../../lib/dql/dql.model';
import { DqlSearchFieldBooleanComponent } from './dql-search-field-boolean/dql-search-field-boolean.component';
import { DqlSearchFieldDateRangeComponent } from './dql-search-field-date-range/dql-search-field-date-range.component';
import { DqlSearchFieldNumberRangeComponent } from './dql-search-field-number-range/dql-search-field-number-range.component';
import { DqlSearchFieldByIdComponent } from './dql-search-field-by-id/dql-search-field-by-id.component';
/* eslint-enable */

export const DqlSearchFieldTypes: Map<DqlModel.QueryableFieldDataType, Type<DqlSearchFieldComponent>> = Map.of(
  DqlModel.QueryableFieldDataType.STRING, DqlSearchFieldSimpleComponent,
  DqlModel.QueryableFieldDataType.NUMBER, DqlSearchFieldNumberRangeComponent,
  DqlModel.QueryableFieldDataType.DECIMAL, DqlSearchFieldNumberRangeComponent,
  DqlModel.QueryableFieldDataType.PHONE_NUMBER, DqlSearchFieldSimpleComponent,
  DqlModel.QueryableFieldDataType.EMAIL_ADDRESS, DqlSearchFieldSimpleComponent,
  DqlModel.QueryableFieldDataType.LIST_ITEM, DqlSearchFieldListComponent,
  DqlModel.QueryableFieldDataType.LIST_MULTI_ITEM, DqlSearchFieldListComponent,
  DqlModel.QueryableFieldDataType.ENUM, DqlSearchFieldEnumComponent,
  DqlModel.QueryableFieldDataType.PAYMENT_TYPE, DqlSearchFieldEnumComponent,
  DqlModel.QueryableFieldDataType.BOOLEAN, DqlSearchFieldBooleanComponent,

  DqlModel.QueryableFieldDataType.DATE, DqlSearchFieldDateRangeComponent,
  DqlModel.QueryableFieldDataType.DATE_TIME, DqlSearchFieldDateRangeComponent,
  DqlModel.QueryableFieldDataType.USER, DqlSearchFieldByIdComponent,
  DqlModel.QueryableFieldDataType.USER_GROUP, DqlSearchFieldByIdComponent,
  DqlModel.QueryableFieldDataType.MOBILE_APP, DqlSearchFieldByIdComponent,
  DqlModel.QueryableFieldDataType.CUSTOMER, DqlSearchFieldByIdComponent,
  DqlModel.QueryableFieldDataType.CONTACT_LOCATION, DqlSearchFieldByIdComponent,
  DqlModel.QueryableFieldDataType.COMPANY, DqlSearchFieldByIdComponent,
  DqlModel.QueryableFieldDataType.COMPANY_LOCATION, DqlSearchFieldByIdComponent,
  DqlModel.QueryableFieldDataType.PROCESS, DqlSearchFieldByIdComponent,
  DqlModel.QueryableFieldDataType.CONTRACT_NUMBER, DqlSearchFieldByIdComponent,
);
