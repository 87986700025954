/* eslint-disable */
import { MultiselectOptionItem, OptionItem } from '../core-utils';
import { StockItemType } from '../../lib/stock/stock-item.service';
import { Currency } from '../../lib/currency.service';
import { DisabledItem } from '../search-utils';
import { StockItemUnitOfMeasure } from '../../lib/stock/stock-item-unit-of-measure';
import { StockItemUnitOfMeasureMultiselectOptionItem } from '../../lib/stock/stock-item-unit-of-measure-multiselect.provider';
import {WeightFactory} from "../weight-utils";

/* eslint-enable */

export class UnitPriceModel {
  value: string = '';
  currency: Currency.Currency = {currencyCode: '', localizedName: ''};
  vatRate: number;
  zeroVatRateType?: string;
}

export class StockItemEditModel {
  externalId?: string;
  productCode: string = '';
  company: MultiselectOptionItem<number>[] = [];
  name: string = '';
  type: StockItemType;
  unit: string = '';
  description?: string;
  vtszNumber?: string;
  unitPrice: UnitPriceModel = new UnitPriceModel();
  usableForFormField: boolean = true;
  productCategory: string = '';
  stockItemCategories: MultiselectOptionItem<number>[] = [];
  weightValue: string = '';
  weightUnit: string = 'kg';
  place: string = '';
  serialCode: string = '';
  hasPicture: boolean = false;
  _ledgerNumber: MultiselectOptionItem<number>[] = [];
  defaultPackageMeasurement: MultiselectOptionItem<number>[] = [];
  packageEnabled: boolean = false;

  creatorUserString?: string;
  createdOnMobile: boolean = false;

  get companyId(): number | undefined {
    return this.company.length === 1 ? this.company[0].id : undefined;
  }

  get ledgerNumberId(): number | undefined {
    return this._ledgerNumber.length === 1 ? this._ledgerNumber[0].id : undefined;
  }

  get defaultPackageMeasurementId(): number | undefined {
    return this.packageEnabled && this.defaultPackageMeasurement.length > 0 ? this.defaultPackageMeasurement[0].id : undefined
  }

  get weightInGrams(): number | undefined {
    if (this.weightUnit.length > 0 && this.weightValue.length > 0) {
      return WeightFactory.createWeightFromUnit(this.weightValue, this.weightUnit).toGrams();
    }
  }
}

export class StockItemCreateModel extends StockItemEditModel {
  packageUnitName?: string;
  packageConversion?: string = '';
}

export class StockItemItem extends OptionItem<number> {
  itemName: string;
  itemType: StockItemType;
  itemSerialCode: string;
  packageData?: StockItemUnitOfMeasure.StockItemUnitOfMeasure;
}

export class StockItemSearchModel {
  name?: string = undefined;
  type?: StockItemTypeItem;
  externalId?: string = undefined;
  productCode?: string = undefined;
  companies: MultiselectOptionItem<number>[] = [];
  ledgerNumbers: MultiselectOptionItem<number>[] = [];
  createdOn: MultiselectOptionItem<string>[] = [];
  unit?: string = undefined;
  unitPriceFrom?: number = undefined;
  unitPriceTo?: number = undefined;
  description?: string = undefined;
  vtszNumber?: string = undefined;
  productCategory?: string = undefined;
  place?: string = undefined;
  serialCode?: string = undefined;
  weightFrom?: number = undefined;
  weightTo?: number = undefined;
  categories: MultiselectOptionItem<number>[] = [];
  unitPrice: UnitPriceSearchModel = new UnitPriceSearchModel();
  disabled: DisabledItem | null = null;

  public isEmpty(): boolean {
    return this.name === undefined
      && (OptionItem.idOrUndefined(this.type) === undefined
        || (this.type !== undefined && this.type.id === null))
      && this.externalId === undefined
      && this.productCode === undefined
      && this.companies.length === 0
      && this.ledgerNumbers.length === 0
      && this.createdOn.length === 0
      && this.unit === undefined
      && this.unitPriceFrom === undefined
      && this.unitPriceTo === undefined
      && this.description === undefined
      && this.vtszNumber === undefined
      && this.unitPrice.currency.currencyCode.length === 0
      && this.unitPrice.vatRate === undefined
      && this.productCategory === undefined
      && this.place === undefined
      && this.serialCode === undefined
      && this.weightFrom === undefined
      && this.weightTo === undefined
      && (this.disabled ? this.disabled.id === DisabledItem.active().id : true)
      && this.categories.length === 0
      ;
  }
}

export class UnitPriceSearchModel {
  currency: Currency.Currency = new Currency.Currency();
  vatRate?: number = undefined;
}

export class StockItemTypeItem extends OptionItem<StockItemType> {
  iconRes: string;
}

export class StockItemTypes {
  public static readonly types: StockItemType[] = ['STOCK', 'SERVICE'];
}

export class StockItemUtils {
  public static convertToPackageStr(amount?: number,
                                    packageData?: StockItemUnitOfMeasure.StockItemUnitOfMeasure
                                      | StockItemUnitOfMeasureMultiselectOptionItem): string {
    if (amount === undefined || !packageData) {
      return '';
    }

    return `${this.convertToPackage(amount, packageData)} ${packageData.unit_name}`;
  }

  public static convertToBaseStr(amount?: number,
                                    selectedUnitOfMeasure?: StockItemUnitOfMeasure.StockItemUnitOfMeasure
                                      | StockItemUnitOfMeasureMultiselectOptionItem,
                                    baseUnit?: string): string {
    if (amount === undefined || !selectedUnitOfMeasure || !baseUnit) {
      return '';
    }

    return `${this.convertToBase(amount, selectedUnitOfMeasure)} ${baseUnit}`;
  }

  public static convertToPackage(amount: number,
                                 packageData: StockItemUnitOfMeasure.StockItemUnitOfMeasure
                                   | StockItemUnitOfMeasureMultiselectOptionItem): number | undefined {

    const conv = packageData.unit_conversion;

    const value = Math.round(amount * 100) / Math.round(conv * 100);

    if (value >= 0) {
      return Math.ceil(value);
    }
    return Math.floor(value);
  }

  public static convertToBase(amount: number,
                                 selectedUnitOfMeasure: StockItemUnitOfMeasure.StockItemUnitOfMeasure
                                   | StockItemUnitOfMeasureMultiselectOptionItem): number {
    const conv = selectedUnitOfMeasure.unit_conversion;
    return Math.round(amount * conv * 100) / 100;
  }


}
