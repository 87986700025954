<div bsModal [config]="UiConstants.modalConfig" #newSearchFieldDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     (onHide)="onModalHide($event)"
     id="dqlNewSearchDialog"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="newSearchFieldDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'DQL_SEARCH_ADD_FIELD' | translate}}</h4>
        <button type="button" class="close" (click)="closeNewSearchFieldDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-md-12 d-flex justify-content-md-end align-items-end px-0" *ngIf="nextSearch.field">
          <button type="button" class="btn btn-secondary" (click)="isBinary = !isBinary">
            {{(isBinary ? 'COMMON_VALUE' : 'DQL_SEARCH_IS_NULL') | translate}}
          </button>
        </div>
        <div class="form-group">
          <label class="form-control-label">{{'DQL_SEARCH_FIELD_TITLE' | translate}}</label>
          <select class="form-control" [(ngModel)]="nextSearch.field" (ngModelChange)="nextFieldChange($event)">
            <option *ngFor="let field of fields" [ngValue]="field">{{field.title}}</option>
          </select>
        </div>
        <div>
          <ng-template appDqlDialogValueHolder></ng-template>
        </div>
        <div *ngIf="isBinary">
          <div class="d-flex flex-wrap" *ngIf="nextSearch.field && binaryOperations.length > 1">
            <button type="button" class="btn btn-secondary" *ngFor="let op of binaryOperations"
                    [ngClass]="nextSearch.operation === op ? 'btn-outline-secondary' : 'btn-secondary'"
                    (click)="nextSearch.operation = op;">{{op.stringKey | translate}}
            </button>
          </div>
        </div>
        <div *ngIf="!isBinary">
          <div class="d-flex flex-wrap" *ngIf="nextSearch.field">
            <button type="button" class="btn btn-secondary" *ngFor="let op of unaryOperations"
                    [ngClass]="nextSearch.operation === op ? 'btn-outline-secondary' : 'btn-secondary'"
                    (click)="nextSearch.operation = op;">{{op.stringKey | translate}}
            </button>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeNewSearchFieldDialog()">
          {{'COMMON_BUTTON_CANCEL' | translate}}
        </button>
        <button type="button" [disabled]="!nextSearch.isAddable()" class="btn btn-primary-green"
                (click)="createExpression()">{{'COMMON_BUTTON_ADD' |
          translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
