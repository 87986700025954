<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
    <app-breadcrumb-menu>
      <app-breadcrumb-button
        *ngIf="rightModel.stockItemCategoryUpdate.hasRight() && componentState.isDetailView()"
        [iconClass]="'icomoon-modify'"
        [titleStringKey]="'COMMON_BUTTON_EDIT'"
        uiSref="Admin.StockItemCategoryEdit"
        [uiParams]="{ id: componentState.id }">
      </app-breadcrumb-button>
    </app-breadcrumb-menu>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>

<div class="container-custom-padding animated fadeIn">

  <div class="form-group row mx-0">

    <!--region Basic Details-->
    <div class="col-xs-12 col-sm-12 col-md-8 card-custom-padding">
      <div class="card">
        <div class="card-header">
          {{ getHeadingDictionaryKey() | translate}}
        </div>
        <div class="card-body">
          <form class="form-horizontal bordered-row" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>
            <!--region CompanyName-->
            <div class="form-group row" [ngClass]="{ 'has-danger': hasFieldError(StockItemCategory.ValidatedField.NAME) || hasLocalFieldError(name) }">
              <label class="col-form-label form-control-label col-md-4 detail-title text-md-right required-field-label">
                {{'COMMON_NAME' | translate}}
              </label>
              <div class="col-md-8">
                <input *ngIf="componentState.isEditable()"
                       type="text" class="form-control"
                       maxlength="{{UiConstants.maximumVarcharLength}}"
                       placeholder="{{'COMMON_NAME' | translate}}"
                       [(ngModel)]="model.name"
                       (ngModelChange)="removeFieldError(StockItemCategory.ValidatedField.NAME)"
                       [ngClass]="{ 'form-control-danger': hasFieldError(StockItemCategory.ValidatedField.NAME) || hasLocalFieldError(name) }"
                       name="name"
                       id="name"
                       #name="ngModel"
                       required>
                <div class="form-control-feedback" *ngIf="hasFieldError(StockItemCategory.ValidatedField.NAME)">
                  {{getFieldErrorText(StockItemCategory.ValidatedField.NAME)}}
                </div>
                <div class="form-control-feedback" *ngIf="hasLocalFieldError(name)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                <!--Appears in detailView-->
                <label
                  *ngIf="componentState.isReadonly()" class="form-control detail-description" readonly>{{model.name}}
                </label>
              </div>
            </div>
            <!--endregion CompanyName-->

            <!--region ExternalId-->
            <div class="form-group row" [ngClass]="{ 'has-danger': hasFieldError(StockItemCategory.ValidatedField.EXTERNAL_ID) || hasLocalFieldError(externalId)}">
              <label class="col-form-label form-control-label col-md-4 detail-title text-md-right"
                     [class.required-field-label]="componentState.isEditView()">
                {{'COMMON_TABLE_HEADER_EXTERNAL_ID' | translate}}
              </label>
              <div class="col-md-8">
                <input *ngIf="componentState.isEditable()"
                       type="text" class="form-control"
                       maxlength="{{UiConstants.maximumVarcharLength}}"
                       placeholder="{{'COMMON_TABLE_HEADER_EXTERNAL_ID' | translate}}"
                       [(ngModel)]="model.externalId"
                       (ngModelChange)="removeFieldError(StockItemCategory.ValidatedField.EXTERNAL_ID)"
                       [ngClass]="{ 'form-control-danger': hasFieldError(StockItemCategory.ValidatedField.EXTERNAL_ID) || hasLocalFieldError(externalId)}"
                       name="externalId"
                       id="externalId"
                       #externalId="ngModel"
                       [required]="componentState.isEditView()">
                <div class="form-control-feedback" *ngIf="hasFieldError(StockItemCategory.ValidatedField.EXTERNAL_ID)">
                  {{getFieldErrorText(StockItemCategory.ValidatedField.EXTERNAL_ID)}}
                </div>
                <div class="form-control-feedback" *ngIf="hasLocalFieldError(externalId)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                <!--Appears in detailView-->
                <label
                  *ngIf="componentState.isReadonly()" class="form-control detail-description" readonly>{{model.externalId}}
                </label>
              </div>
            </div>
            <!--endregion ExternalId-->

            <!--region Main category-->
            <div class="form-group row">
              <label class="col-form-label form-control-label col-md-4 detail-title text-md-right">{{'STOCK_ITEM_CATEGORY_MAIN_CATEGORY' |
                translate}}</label>
              <div class="col-md-8">
                <label class="switch switch-text switch-info mb-0" *ngIf="!componentState.isReadonly()">
                  <input type="checkbox" class="switch-input"
                         [(ngModel)]="model.mainCategory"
                         [ngModelOptions]="{standalone: true}">
                  <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                  <span class="switch-handle"></span>
                </label>
                <!--Appears in detailView-->
                <label
                  *ngIf="componentState.isReadonly()" class="form-control detail-description" readonly>{{(model.mainCategory ? 'COMMON_YES' : 'COMMON_NO') | translate}}
                </label>
              </div>
            </div>
            <!--endregion Main category-->

            <!--region Parent category-->
            <div class="form-group row" *ngIf="componentState.isEditable() || model.parentCategoryId"
                 [ngClass]="{ 'has-danger': hasFieldError(StockItemCategory.ValidatedField.PARENT_ID) || hasLocalFieldError(parentCategory), 'hidden': model.mainCategory}">
              <label class="col-form-label form-control-label col-md-4 detail-title text-md-right"
                     [class.required-field-label]="!model.mainCategory">
                {{'STOCK_ITEM_CATEGORY_PARENT_CATEGORY' | translate}}
              </label>
              <div class="col-md-8">
                <angular2-multiselect
                  [(ngModel)]="model.parentCategory"
                  (ngModelChange)="removeFieldError(StockItemCategory.ValidatedField.PARENT_ID)"
                  [ngClass]="{ 'form-control-danger': hasFieldError(StockItemCategory.ValidatedField.PARENT_ID) || hasLocalFieldError(parentCategory)}"
                  [data]="categories"
                  [settings]="parentDropdownSettings"
                  [readonly]="componentState.isReadonly()"
                  (onRemoteSearch)="loadCategories($event.target.value)"
                  name="parentCategory"
                  id="parentCategory"
                  #parentCategory="ngModel"
                  [required]="!model.mainCategory">
                </angular2-multiselect>
                <div class="form-control-feedback" *ngIf="hasFieldError(StockItemCategory.ValidatedField.PARENT_ID)">
                  {{getFieldErrorText(StockItemCategory.ValidatedField.PARENT_ID)}}
                </div>
                <div class="form-control-feedback" *ngIf="hasLocalFieldError(parentCategory)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
              </div>
            </div>
            <!--endregion Parent category-->

            <!--region Child categories-->
            <div class="form-group row" *ngIf="componentState.isCreateView() || model.childCategories.length > 0">
              <label class="col-form-label form-control-label col-md-4 detail-title text-md-right">{{'STOCK_ITEM_CATEGORY_CHILD_CATEGORIES' |
                translate}}</label>
              <div class="col-md-8">
                <angular2-multiselect
                  [(ngModel)]="model.childCategories"
                  [ngModelOptions]="{standalone: true}"
                  [data]="categories"
                  [settings]="childDropdownSettings"
                  [readonly]="!componentState.isCreateView()"
                  (onRemoteSearch)="loadCategories($event.target.value)">
                </angular2-multiselect>
              </div>
            </div>
            <!--endregion Child categories-->

            <!--Only visible on create/edit views-->
            <div class="col-md-12 d-flex justify-content-end p-0 pl-1" *ngIf="componentState.isEditable()">
              <div>
                <input type="submit" class="btn btn-primary"
                       value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
