/* eslint-disable */
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppworksDashboardProjectModel, AppworksDashboardTaskModel } from '../../util/appworks-utils';
/* eslint-enable */

@Component({
  selector: 'app-dashboard-pie-chart',
  templateUrl: './dashboard-pie-chart.component.html',
  styleUrls: ['./dashboard-pie-chart.component.scss']
})
export class DashboardPieChartComponent implements OnInit {

  @Input()
  dashboardTaskModel: AppworksDashboardTaskModel | AppworksDashboardProjectModel;

  @ViewChild('googleChart') googleChart;
  showGoogleChart = false;
  pieChartData;

  constructor(private translateService: TranslateService) { }

  ngOnInit() {
    this.loadChart(() => {
      this.showGoogleChart = true;
    });
  }

  loadChart(completion: () => void) {
    this.pieChartData  =  {
      chartType: 'PieChart',
      dataTable: [
        ['Name', 'Count'],
        [this.translateService.instant('COMMON_VALUE_TASK_STATE_NEW'), this.dashboardTaskModel.getStat('NEW').count],
        [this.translateService.instant('COMMON_VALUE_TASK_STATE_OPEN'), this.dashboardTaskModel.getStat('OPEN').count],
        [this.translateService.instant('COMMON_VALUE_TASK_STATE_IN_PROGRESS'), this.dashboardTaskModel.getStat('IN_PROGRESS').count],
        [this.translateService.instant('COMMON_VALUE_TASK_STATE_FINISHED'), this.dashboardTaskModel.getStat('FINISHED').count],
        [this.translateService.instant('COMMON_VALUE_OTHER'), this.otherTaskCount()]
      ],
      options: {
        'width': 150,
        'height': 150,
        'legend': 'none',
        'pieSliceText': 'none',
        'tooltip': { trigger: 'none' },
        'pieHole': 0.85,
        chartArea: {
          // leave room for y-axis labels
          'width': '90%',
          'height': '90%'
        },
        'pieSliceBorderColor' : 'transparent',
        'enableInteractivity': false,
        slices: {
          0: { color: '#00727b'},
          1: { color: '#e74b14'},
          2: { color: '#ffd300'},
          3: { color: '#01aee8'},
          4: { color: '#eeeeee'},
        }
      }
    };
    completion();
  }

  otherTaskCount(): number {
    let otherTaskCount = 0;
    if (this.dashboardTaskModel.taskRecordCount) {
      otherTaskCount = this.dashboardTaskModel.taskRecordCount!;
      otherTaskCount -= this.dashboardTaskModel.getStat('NEW').count;
      otherTaskCount -= this.dashboardTaskModel.getStat('IN_PROGRESS').count;
      otherTaskCount -= this.dashboardTaskModel.getStat('OPEN').count;
      otherTaskCount -= this.dashboardTaskModel.getStat('FINISHED').count;
    }
    return otherTaskCount;
  }

}
