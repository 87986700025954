/* eslint-disable */
import { Invoice } from '../../../../lib/invoice/invoice/invoice.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { OrderType, QueryResult, Services } from '../../../../lib/util/services';
import { List, Set } from 'immutable';
import { CustomerRecord, CustomerRecordService } from '../../../../lib/customer/customer-record.service';
import { Address } from '../../../../lib/address';
import { Decimal } from 'decimal.js/decimal';
import { Models } from '../../../../util/model-utils';
import { Observable, of } from 'rxjs';
import { ConfigurationService } from '../../../../lib/core-ext/configuration.service';
import { VatRateService } from '../../../../lib/vat-rate.service';
import { MultiselectOptionItem, MultiselectOptionItemWithData } from '../../../../util/core-utils';
import { InvoiceSettings } from '../../../../lib/invoice/invoice-settings/invoice-settings.service';
import { StockItemMultiselectOptionItem } from '../../../../lib/stock/stock-item-multiselect.provider';
import { Strings } from '../../../../lib/util/strings';
import { LedgerNumberMultiselectProvider } from '../../../../lib/ledger/number/ledger-number-multiselect.provider';
import { InvoiceTagMultiselectProvider } from '../../../../lib/invoice/tag/invoice-tag-multiselect-provider.service';
import { Dates } from '../../../../lib/util/dates';
import { NgbDatePickerParserFormatter } from '../../../../util/ngb-datepicker';
import {
  InvoiceSettingsMultiselectOptionItem,
  InvoiceSettingsMultiselectProvider
} from '../../../../lib/invoice/invoice-settings/invoice-settings-multiselect-provider.service';
import { InvoiceBookMultiselectProvider } from '../../../../lib/invoice/invoice-book/invoice-book-multiselect-provider.service';
import { TranslateService } from '@ngx-translate/core';
import InvoiceDirectionType = Invoice.InvoiceDirectionType;
import InvoiceAppearanceType = InvoiceSettings.InvoiceAppearanceType;
/* eslint-enable */

// This value is also used on the dashboard!
export const INVOICE_VALID_CURRENCY_CODE = 'HUF';

export class InvoiceCreateCloneModel {

  readonly _directionType: InvoiceDirectionType;
  readonly _currencyCode: string = INVOICE_VALID_CURRENCY_CODE;

  _invoiceNumber: string = ''; // only used for INBOUND invoces
  _invoiceSettings: InvoiceSettingsMultiselectOptionItem[] = [];
  _invoiceBook: MultiselectOptionItem<number>[] = [];
  _appearanceType: InvoiceSettings.InvoiceAppearanceType;
  _commentTemplate: string = '';
  _paymentType: InvoicePaymentTypeMultiItem[] = [];
  _issueDate: NgbDateStruct | null;
  _deliveryDate: NgbDateStruct | null;
  _deadline: NgbDateStruct | null;
  _customerRecord: CustomerMultiItem[] = [];
  _billingInfo: MultiselectOptionItemWithData<number>[] = [];
  _records: InvoiceRecordCreateModel[] = [];
  _invoiceTag: MultiselectOptionItem<number>[] = [];

  get invoiceSettings(): InvoiceSettingsMultiselectOptionItem | undefined {
    return this._invoiceSettings.length === 1 ? this._invoiceSettings[0] : undefined;
  }

  get invoiceBook(): MultiselectOptionItem<number> | undefined {
    return this._invoiceBook.length === 1 ? this._invoiceBook[0] : undefined;
  }

  get invoiceTag(): MultiselectOptionItem<number> | undefined {
    return this._invoiceTag.length === 1 ? this._invoiceTag[0] : undefined;
  }

  get paymentType(): InvoicePaymentTypeMultiItem | undefined {
    return this._paymentType.length === 1 ? this._paymentType[0] : undefined;
  }

  get customerRecord(): CustomerMultiItem | undefined {
    return this._customerRecord.length === 1 ? this._customerRecord[0] : undefined;
  }

  get billingInfo(): MultiselectOptionItemWithData<number> | undefined {
    return this._billingInfo.length === 1 ? this._billingInfo[0] : undefined;
  }

  get billingInfoId(): number | undefined {
    return this.billingInfo ? this.billingInfo.id : undefined;
  }

  readonly deadlineAdds: number[] = [];
  readonly paymentTypeList: InvoicePaymentTypeMultiItem[] = [];
  invoiceSettingsList: InvoiceSettingsMultiselectOptionItem[] = [];
  invoiceBookList: MultiselectOptionItem<number>[] = [];
  customerRecordList: CustomerMultiItem[] = [];
  invoiceTagList: MultiselectOptionItem<number>[] = [];
  billingInfos: MultiselectOptionItemWithData<number>[] = [];

  constructor(private customerRecordService: CustomerRecordService,
              private configService: ConfigurationService,
              private invoiceSettingsService: InvoiceSettingsMultiselectProvider,
              private invoiceBookService: InvoiceBookMultiselectProvider,
              private invoiceTagMultiselectProvider: InvoiceTagMultiselectProvider,
              private datePickerParserFormatter: NgbDatePickerParserFormatter,
              private translateService: TranslateService,
              directionType: Invoice.InvoiceDirectionType) {
    this.initDeadlineAdds();
    this.initPaymentTypes();
    this._directionType = directionType;
    this.initDates();
  }

  private initDates() {
    const today = Dates.today();
    this._issueDate = this.datePickerParserFormatter.fromLocalDate(today);
    this._deliveryDate = this.datePickerParserFormatter.fromLocalDate(today);
  }

  private initDeadlineAdds() {
    this.deadlineAdds.push(5);
    this.deadlineAdds.push(15);
    this.deadlineAdds.push(30);
  }

  private initPaymentTypes() {
    InvoicePaymentTypeMultiItem.loadItems().subscribe(paymentTypes => this.paymentTypeList.push(...paymentTypes));
  }

  load(originalInvoice: Invoice.Invoice) {
    this._invoiceNumber = this._directionType === 'INBOUND' ? originalInvoice.invoiceNumber! : '';
    this.invoiceSettingsService.getById(originalInvoice.invoiceSettings.id!).subscribe(s => {
      this._invoiceSettings = [s];
    })
    if (originalInvoice.invoiceBookId) {
      this.invoiceBookService.getById(originalInvoice.invoiceBookId).subscribe(b => {
        this._invoiceBook = [b];
      });
    }

    this._appearanceType = originalInvoice.appearanceType === 'ELECTRONIC' ? InvoiceAppearanceType.ELECTRONIC : InvoiceAppearanceType.PAPER;
    this._commentTemplate = originalInvoice.comment ? originalInvoice.comment : '';
    this._paymentType = [this.paymentTypeList.find(t => t.id === originalInvoice.paymentType)!];

    const baseDate = this._directionType === 'INBOUND' ? originalInvoice.issueDate : Dates.today();
    this._issueDate = this.datePickerParserFormatter.fromLocalDate(baseDate);
    this._deadline = this.datePickerParserFormatter.fromLocalDate(
      baseDate.plusDays(originalInvoice.deadline.diffDays(originalInvoice.issueDate)!));
    this._deliveryDate = this.datePickerParserFormatter.fromLocalDate(
      baseDate.plusDays(originalInvoice.deliveryDate.diffDays(originalInvoice.issueDate)!));

    if (originalInvoice.customerRecordId) {
      this.customerRecordService.globalQuery({customerRecordIdSet: Set.of(originalInvoice.customerRecordId)})
        .subscribe(cr => {
          if (cr.items) {
            this._customerRecord = [];
            const item = InvoiceCreateCloneModel.mapCustomerRecord(cr.items.get(0), this.configService.getPostalAddressFormat())
            this._customerRecord.push(item);
            this.loadBillingInfos(item, originalInvoice.billingInfoId);
          }
        })
    }

    if (originalInvoice.invoiceTag) {
      this.invoiceTagMultiselectProvider.getById(originalInvoice.invoiceTag.id).subscribe(t => {
        this._invoiceTag = [t];
      });
    }
    this._records = [];
    originalInvoice.records?.forEach(r => {
      this._records.push(InvoiceRecordCreateModel.map(r))
    });
  }

  loadBillingInfos(customer: CustomerMultiItem, billingInfoId?: number) {
    this.customerRecordService.listBillingInfo({
      customerId: customer.customerId,
      customerRecordId: customer.id,
      disabled: false
    }).subscribe(result => {
      this.billingInfos = result.map(i => {
        return {
          id: i.id,
          itemName: i.name,
          itemSubtitle: this.createBillingInfoSubtitle(i),
          data: i
        }
      });
      if (billingInfoId) {
        this._billingInfo = this.billingInfos.filter(b => b.id === billingInfoId);
      }
      else if (this.billingInfos.length === 1) {
        this._billingInfo = this.billingInfos;
      }
    });
  }

  onInvoiceTagSearch(q?: string) {
    this.invoiceTagMultiselectProvider.loadActive(q).subscribe(tags => {
      this.invoiceTagList = tags;
    });
  }

  onInvoiceSettingsSearch(q?: string) {
    this.invoiceSettingsService.loadWithPartialRequest({
      profileName: q,
      disabled: false,
      invoiceDirectionType: this._directionType,
    })
      .subscribe(
        (result: InvoiceSettingsMultiselectOptionItem[]) => {
          this.invoiceSettingsList = result;
        }
      );
  }

  onInvoiceBookSearch(evt?: string) {
    this.invoiceBookService.loadActiveWithSettings(this.invoiceSettings!.id, evt).subscribe((result) => {
      this.invoiceBookList = result;
    });
  }

  onCustomerRecordSearch(evt?: string) {
    this.customerRecordService.globalQuery({
      fields: Set.of('id', 'customer_id', 'name', 'email_addresses', 'postal_address'),
      queryText: evt,
      paging: {
        pageNumber: 1,
        numberOfItems: 50
      },
      orders: Set.of({field: CustomerRecord.OrderField.NAME, type: OrderType.ASC}),
      noProgressBar: true,
      disabled: false,
      parentDisabled: false,
      contactPersonType: false,
    }).subscribe((crs: QueryResult<CustomerRecord.CustomerRecord>) => {
      this.customerRecordList = [];
      crs.items.forEach((cr) => {
        if (cr) {
          this.customerRecordList.push(InvoiceCreateCloneModel.mapCustomerRecord(cr, this.configService.getPostalAddressFormat()));
        }
      });
    });
  }

  getRecordCreateRequests(): Invoice.InvoiceRecordCreateRequest[] {
    const requests: Invoice.InvoiceRecordCreateRequest[] = [];
    this._records.forEach((r) => {
      requests.push({
        recordName: r.recordName,
        hunVtszNumber: r.hunVtszNumber,
        amount: Models.parseDecimal(r.amount)!,
        unitType: r.unitType,
        netUnitPrice: Models.parseDecimal(r.netUnitPrice)!,
        vatRate: new Decimal(r.vatRate),
        zeroVatRateType: r.vatRate === 0 ? r.zeroVatRateType : undefined,
        comment: r.comment,
        currencyCode: r.currencyCode,
        stockItemId: r.stockItemId,
        ledgerNumberId: r.ledgerNumberId
      });
    });
    return requests;
  }

  createBillingInfoSubtitle(info: CustomerRecord.BillingInfo) {
    switch (info.invoiceVatStatus) {
      case CustomerRecord.InvoiceVatStatus.PRIVATE_PERSON:
        return this.translateService.instant('INVOICE_VAT_STATUS_PRIVATE_PERSON');
      case CustomerRecord.InvoiceVatStatus.DOMESTIC:
        return this.translateService.instant('INVOICE_VAT_STATUS_DOMESTIC') + ' / ' + info.taxNumber;
      case CustomerRecord.InvoiceVatStatus.OTHER:
        return this.translateService.instant('INVOICE_VAT_STATUS_OTHER') + ' / ' + info.euTaxNumber;
    }
  }

  static mapCustomerRecord(customerRecord: CustomerRecord.CustomerRecord, postalAddressFormat: string): CustomerMultiItem {
    const address = customerRecord.postalAddress
      ? Address.PostalAddressMapper.toString(customerRecord.postalAddress, postalAddressFormat)
      : undefined;
    const email = InvoiceCreateCloneModel.mapEmailAddresses(customerRecord.emailAddresses);
    return {
      itemName: customerRecord.name,
      itemSubtitle: (address ? address + ', ' : '') + (email ? email : ''),
      id: customerRecord.customerRecordId,
      customerId: customerRecord.customerId,
      postalAddress: customerRecord.postalAddress,
    };
  }

  static mapEmailAddresses(emailAddresses: List<Address.EmailAddressData>): string | undefined {
    return emailAddresses.size > 0
      ? emailAddresses.get(0).value.toIso()!
      : undefined;
  }
}

export class InvoiceRecordCreateModel {
  _stockItem: StockItemMultiselectOptionItem[] = [];
  _ledgerNumber: MultiselectOptionItem<number>[] = [];
  recordName: string = '';
  hunVtszNumber: string = '';
  amount: string = '';
  unitType: string = '';
  netUnitPrice: string = '';
  vatRate: number = VatRateService.DEFAULT_VAT_RATE;
  zeroVatRateType: string = VatRateService.DEFAULT_ZERO_VAT_RATE_TYPE;
  comment: string = '';
  readonly currencyCode: string = INVOICE_VALID_CURRENCY_CODE;
  editing: boolean = false;
  readonlyStockItemId?: number;

  readonlyNetPrice?: string;
  readonlyGrossPrice?: string;

  get rawNetPrice(): Decimal | undefined {
    if (this.amount.length > 0 && this.netUnitPrice.length > 0) {
      const netUnitPrice = this.trimDecimal(this.netUnitPrice);
      return netUnitPrice.mul(this.trimDecimal(this.amount));
    }
    return undefined;
  }

  get netPrice(): string {
    return Models.decimalToString(this.rawNetPrice);
  }

  get rawGrossPrice(): Decimal | undefined {
    if (this.amount.length > 0 && this.netUnitPrice.length > 0) {
      const netUnitPrice = this.trimDecimal(this.netUnitPrice);
      return netUnitPrice.mul(this.vatRate).div(100).add(netUnitPrice).mul(this.trimDecimal(this.amount));
    }
    return undefined;
  }

  get grossPrice(): string {
    return Models.decimalToString(this.rawGrossPrice);
  }

  get stockItemId(): number | undefined {
    return this._stockItem.length === 0 ? undefined : this._stockItem[0].id;
  }

  get ledgerNumberId(): number | undefined {
    return this._ledgerNumber.length === 0 ? undefined : this._ledgerNumber[0].id;
  }

  get ledgerNumberString(): string {
    return this._ledgerNumber.length === 0 ? '' : (this._ledgerNumber[0].itemName +
      (this._ledgerNumber[0].itemSubtitle ? ` (${this._ledgerNumber[0].itemSubtitle})` : ''));
  }

  private trimDecimal(trimmable: string): Decimal {
    return Models.parseDecimal(trimmable)!;
  }

  static map(record: Invoice.InvoiceRecord): InvoiceRecordCreateModel {
    const model = new InvoiceRecordCreateModel();
    model.recordName = record.recordName;
    model.hunVtszNumber = Strings.optToString(record.hunVtszNumber);
    model.amount = Strings.optToString(Services.decimalToString(record.amount));
    model.unitType = record.unitType;
    model.netUnitPrice = Strings.optToString(Services.decimalToString(record.netUnitPrice));
    model.vatRate = Number.parseFloat(Services.decimalToString(record.vatRate)!);
    model.zeroVatRateType = Strings.optToString(record.zeroVatRateType);
    model.comment = Strings.optToString(record.comment);
    model._ledgerNumber = record.ledgerNumber ? [LedgerNumberMultiselectProvider.toPublic(record.ledgerNumber)] : [];
    model.readonlyStockItemId = record.stockItemId;
    model.editing = false;
    model.readonlyNetPrice = Strings.optToString(Services.decimalToString(record.netPrice));
    model.readonlyGrossPrice = Strings.optToString(Services.decimalToString(record.grossPrice));
    return model;
  }
}

export class CustomerMultiItem extends MultiselectOptionItem<number> {
  customerId: number;
  postalAddress?: Address.PostalAddressData;
}

export class InvoicePaymentTypeMultiItem extends MultiselectOptionItem<Invoice.InvoicePaymentType> {

  public static loadItems(): Observable<InvoicePaymentTypeMultiItem[]> {
    const paymentTypes: InvoicePaymentTypeMultiItem[] = [];
    paymentTypes.push({
      id: 'CASH',
      itemName: 'INVOICE_PAYMENT_TYPE_CASH'
    });
    paymentTypes.push({
      id: 'CREDIT_CARD',
      itemName: 'INVOICE_PAYMENT_TYPE_CREDIT_CARD'
    });
    paymentTypes.push({
      id: 'TRANSFER',
      itemName: 'INVOICE_PAYMENT_TYPE_TRANSFER'
    });
    paymentTypes.push({
      id: 'VOUCHER',
      itemName: 'INVOICE_PAYMENT_TYPE_VOUCHER'
    });
    paymentTypes.push({
      id: 'COD',
      itemName: 'INVOICE_PAYMENT_TYPE_COD'
    });
    paymentTypes.push({
      id: 'OTHER',
      itemName: 'INVOICE_PAYMENT_TYPE_OTHER'
    });
    return of(paymentTypes);
  }
}
