/* eslint-disable */
import { OptionItem } from './core-utils';
import { UserDisabled } from '../lib/user.service';
import { DisabledItem } from './search-utils';
/* eslint-enable */

export class FormTableEditModel {
  name: string = '';
  externalId: string = '';
  description: string = '';
}

export class FormTableSearchModel {
  id: string;
  name: string;
  externalId: string;
  disabled?: DisabledItem;

  public isEmpty(): boolean {
    return this.id.length === 0
      && this.name.length === 0
      && this.externalId.length === 0
      && OptionItem.idOrUndefined(this.disabled) === undefined
      ;
  }
}

export class FormTableDisabledItem extends OptionItem<FormTableDisabled> {
}

export class FormTableDisabledItems {
  public static readonly values: UserDisabled[] = ['ALL', 'ACTIVE', 'INACTIVE'];
}

export class FormTableCreateModel extends FormTableEditModel {
}

export type FormTableDisabled =
  'ALL' |
  'ACTIVE' |
  'INACTIVE';
