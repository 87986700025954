<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]=breadcrumbParents
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div class="breadcrumb-menu d-flex">
    <div class="breadcrumb-button-icon-container cursor-pointer"
         *ngIf="rightModel.stockItemMove.hasRight()"
         (click)="showStockItemMoveDialog()">
      <a class="btn-setting" title="{{'STOCK_ITEM_MOVE' | translate}}">
        <i class="icomoon icomoon-stock-move"></i> {{'STOCK_ITEM_MOVE' | translate}}
      </a>
    </div>
    <div class="breadcrumb-button-icon-container cursor-pointer"
         *ngIf="rightModel.stockItemDispose.hasRight()"
         (click)="showStockItemDisposeDialog()">
      <a class="btn-setting" title="{{'STOCK_ITEM_DISPOSE' | translate}}">
        <i class="icomoon icomoon-stock-dispose"></i> {{'STOCK_ITEM_DISPOSE' | translate}}
      </a>
    </div>
    <!-- region EDIT-->
    <app-breadcrumb-button
      class="negative-margin-right"
      *ngIf="rightModel.stockItemUpdate.hasRight()"
      [iconClass]="'icomoon-modify'"
      [titleStringKey]="'COMMON_BUTTON_EDIT'"
      uiSref="Admin.StockItemEdit"
      [uiParams]="{ id: stockItemId }">
    </app-breadcrumb-button>
    <!-- endregion EDIT-->
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding-half animated fadeIn">
  <responsive-tabset class="fullscreen_tab">
    <responsive-tab heading="{{'COMMON_CARD_HEADER_BASE_DATA'|translate}}">
      <div class="p-0 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-header">
            {{'STOCK_ITEM_DETAIL_TITLE'|translate}}
          </div>

          <div class="card-body">
            <form class="form-horizontal" #f="ngForm" novalidate>

              <div class="row">

                <div class="col-md-8 order-2">
                  <!--NAME-->
                  <div class="row" *ngIf="m && m.name">
                    <div class="form-group col-md-4 mb-0">
                      <label readonly
                             class="col-form-label form-control-label detail-title">{{'COMMON_NAME'|translate}}</label>
                    </div>
                    <div class="form-group col-md-8 mb-0">
                      <label readonly
                             class="col-form-label form-control-label detail-description"> {{ m.name }} </label>
                    </div>
                  </div>

                  <!--EXTERNAL ID-->
                  <div class="row" *ngIf="m && m.external_id">
                    <div class="form-group col-md-4 mb-0">
                      <label readonly
                             class="col-form-label form-control-label detail-title">{{'STOCK_ITEM_TABLE_HEADER_EXTERNAL_ID'|translate}}</label>
                    </div>
                    <div class="form-group col-md-8 mb-0">
                      <label readonly
                             class="col-form-label form-control-label detail-description"> {{ m.external_id }} </label>
                    </div>
                  </div>

                  <!--PRODUCT CODE-->
                  <div class="row" *ngIf="m && m.product_code">
                    <div class="form-group col-md-4 mb-0">
                      <label readonly
                             class="col-form-label form-control-label detail-title">{{'STOCK_ITEM_TABLE_HEADER_PRODUCT_CODE'|translate}}</label>
                    </div>
                    <div class="form-group col-md-8 mb-0">
                      <label readonly
                             class="col-form-label form-control-label detail-description"> {{ m.product_code }} </label>
                    </div>
                  </div>

                  <!--COMPANY-->
                  <div class="row" *ngIf="m && selectedCompany.length > 0">
                    <div class="form-group col-md-4 mb-0">
                      <label readonly
                             class="col-form-label form-control-label detail-title">{{'STOCK_ITEM_COMPANY'|translate}}</label>
                    </div>
                    <div class="form-group col-md-8 mb-0">
                      <angular2-multiselect [data]="selectedCompany" [settings]="dropdownSettings"
                                            class="form-control"
                                            [(ngModel)]="selectedCompany"
                                            [ngModelOptions]="{standalone: true}"
                                            [readonly]="true">
                      </angular2-multiselect>
                    </div>
                  </div>

                  <!--LEDGER_NUMBER-->
                  <div class="row" *ngIf="m && m.ledger_number">
                    <div class="form-group col-md-4 mb-0">
                      <label readonly
                             class="col-form-label form-control-label detail-title">{{'LEDGER_NUMBER_LEDGER_NUMBER'|translate}}</label>
                    </div>
                    <div class="form-group col-md-8 mb-0">
                      <label readonly
                             class="col-form-label form-control-label detail-description"> {{ m.ledger_number.ledger_number }}
                        ({{m.ledger_number.name}}) </label>
                    </div>
                  </div>

                  <!--TYPE-->
                  <div class="row" *ngIf="m && m.type">
                    <div class="form-group col-md-4 mb-0">
                      <label readonly
                             class="col-form-label form-control-label detail-title">{{'STOCK_ITEM_TABLE_HEADER_TYPE'|translate}}</label>
                    </div>
                    <div class="form-group col-md-8 mb-0">
                      <label readonly
                             class="col-form-label form-control-label detail-description"> {{ getStockItemTypeText(m.type) }} </label>
                    </div>
                  </div>

                  <!--SERIAL CODE-->
                  <div class="row" *ngIf="m && m.serial_code">
                    <div class="form-group col-md-4 mb-0">
                      <label readonly
                             class="col-form-label form-control-label detail-title">{{'STOCK_ITEM_SERIAL_CODE'|translate}}</label>
                    </div>
                    <div class="form-group col-md-8 mb-0">
                      <label readonly
                             class="col-form-label form-control-label detail-description"> {{ m.serial_code }} </label>
                    </div>
                  </div>

                  <!--PRODUCT CATEGORY-->
                  <div class="row" *ngIf="m && m.product_category">
                    <div class="form-group col-md-4 mb-0">
                      <label readonly
                             class="col-form-label form-control-label detail-title">{{'STOCK_ITEM_SHORT_PRODUCT_CODE'|translate}}</label>
                    </div>
                    <div class="form-group col-md-8 mb-0">
                      <label readonly
                             class="col-form-label form-control-label detail-description"> {{ m.product_category }} </label>
                    </div>
                  </div>

                  <!--STOCK CATEGORY-->
                  <div class="row" *ngIf="m && selectedCategories.length > 0">
                    <div class="form-group col-md-4 mb-0">
                      <label readonly
                             class="col-form-label form-control-label detail-title">{{'STOCK_ITEM_PRODUCT_CATEGORY'|translate}}</label>
                    </div>
                    <div class="form-group col-md-8 mb-0">
                      <angular2-multiselect [data]="selectedCategories" [settings]="dropdownSettings"
                                            class="form-control"
                                            [(ngModel)]="selectedCategories"
                                            [ngModelOptions]="{standalone: true}"
                                            (onReadonlyItemClick)="navigateToCategory($event)"
                                            [readonly]="true">
                      </angular2-multiselect>
                    </div>
                  </div>

                  <!--WEIGHT IN GRAMS-->
                  <div class="row" *ngIf="m && m.weight_in_grams">
                    <div class="form-group col-md-4 mb-0">
                      <label readonly
                             class="col-form-label form-control-label detail-title">{{'STOCK_ITEM_WEIGHT'|translate}}</label>
                    </div>
                    <div class="form-group col-md-8 mb-0">
                      <label readonly
                             class="col-form-label form-control-label detail-description"> {{m.weight_in_grams ?
                        (WeightFactory.createWeightFromGram(m.weight_in_grams).toString()) : ''}}</label>
                    </div>
                  </div>

                  <!--UNIT PRICE-->
                  <div class="row" *ngIf="m && m.unit_price && m.unit_price.value && m.unit_price.currency_code">
                    <div class="form-group col-md-4 mb-0">
                      <label readonly
                             class="col-form-label form-control-label detail-title">{{'STOCK_ITEM_UNIT_PRICE'|translate}}</label>
                    </div>
                    <div class="form-group col-md-8 mb-0">
                      <label readonly
                             class="col-form-label form-control-label detail-description"> {{ m.unit_price.value | formattedNumber }} {{getCurrencyName(m.unit_price.currency_code)}} </label>
                    </div>
                  </div>

                  <!--VTSZ NUMBER-->
                  <div class="row" *ngIf="m && m.hun_vtsz_number">
                    <div class="form-group col-md-4 mb-0">
                      <label readonly
                             class="col-form-label form-control-label detail-title">{{'STOCK_ITEM_VTSZ_NUMBER'|translate}}</label>
                    </div>
                    <div class="form-group col-md-8 mb-0">
                      <label readonly
                             class="col-form-label form-control-label detail-description"> {{ m.hun_vtsz_number }} </label>
                    </div>
                  </div>

                  <!--VAT RATE-->
                  <div class="row" *ngIf="m && m.unit_price">
                    <div class="form-group col-md-4 mb-0">
                      <label readonly
                             class="col-form-label form-control-label detail-title">{{'STOCK_ITEM_UNIT_VAT_RATE'|translate}}</label>
                    </div>
                    <div class="form-group col-md-8 mb-0">
                      <label readonly
                             class="col-form-label form-control-label detail-description"> {{ m.unit_price.vat_rate }}
                        %</label>
                    </div>
                  </div>

                  <!--ZERO VAT RATE TYPE-->
                  <div class="row" *ngIf="m && m.unit_price && m.unit_price.zero_vat_rate_type">
                    <div class="form-group col-md-4 mb-0">
                      <label readonly
                             class="col-form-label form-control-label detail-title">{{'ZERO_VAT_RATE_REASON'|translate}}</label>
                    </div>
                    <div class="form-group col-md-8 mb-0">
                      <label readonly
                             class="col-form-label form-control-label detail-description"> {{ m.unit_price.zero_vat_rate_type }}</label>
                    </div>
                  </div>

                  <!--PLACE-->
                  <div class="row" *ngIf="m && m.place">
                    <div class="form-group col-md-4 mb-0">
                      <label readonly
                             class="col-form-label form-control-label detail-title">{{'STOCK_ITEM_PLACE'|translate}}</label>
                    </div>
                    <div class="form-group col-md-8 mb-0">
                      <label readonly
                             class="col-form-label form-control-label detail-description"> {{ m.place }} </label>
                    </div>
                  </div>

                  <div class="row" *ngIf="m && m.package_data">
                    <div class="form-group col-md-4 mb-0">
                      <label readonly
                             class="col-form-label form-control-label detail-title">{{'STOCK_ITEM_PACKAGE'|translate}}</label>
                    </div>
                    <div class="form-group col-md-8 mb-0">
                      <label readonly
                             [ngClass]="{'text-muted': m.package_data.disabled}"
                             class="col-form-label form-control-label detail-description">{{ m.package_data.unit_conversion | formattedNumber }}
                        / {{ m.package_data.unit_name }}</label>
                    </div>
                  </div>

                  <!--region CREATOR USER-->
                  <div class="row" *ngIf="m && m.creator_user">
                    <div class="form-group col-md-4 mb-0">
                      <label
                        class="col-form-label form-control-label detail-title">{{'TASK_RECORD_TABLE_HEADER_CREATOR_USER' |
                        translate}}</label>
                    </div>
                    <div class="form-group col-md-8 mb-0">
                      <label readonly
                             class="col-form-label form-control-label detail-description">{{getCreatorUserData()}}</label>
                    </div>
                  </div>
                  <!--endregion CREATOR USER-->
                  <!--region CREATED ON-->
                  <div class="row" *ngIf="m && m.created_on">
                    <div class="form-group col-md-4 mb-0">
                        <label
                          class="col-form-label form-control-label detail-title">{{ 'STOCK_ITEM_CREATED_ON_MOBILE' | translate }}</label>
                    </div>
                    <div class="form-group col-md-8 mb-0">
                        <div class="mw-100">
                          <app-animated-checkbox
                            readonly="true"
                            [selected]="isCreatedOnMobile()">
                          </app-animated-checkbox>
                        </div>
                    </div>
                  </div>
                  <!--endregion CREATED ON-->

                  <!--DESCRIPTION-->
                  <div class="row" *ngIf="m && m.description">
                    <div class="form-group col-md-4 mb-0">
                      <label readonly
                             class="col-form-label form-control-label detail-title">{{'COMMON_DESCRIPTION'|translate}}</label>
                    </div>
                    <div class="form-group col-md-8 mb-0">
                      <label class="form-control detail-description col-form-label html-template-container" readonly>
                        <span [innerHTML]="sanitizeHtml(decodeHTMLEntities(m.description))"></span>
                      </label>
                    </div>
                  </div>

                  <div class="form-group row" *ngIf="m && m.picture">
                    <div class="col-md-4">
                      <label
                        class="mw-100 col-form-label form-control-label">{{'STOCK_ITEM_PICTURE' | translate}}</label>
                    </div>
                    <div class="col-md-8">
                      <div class="mw-100">
                        <div>
                          <div class="thumbnail-background mb-1" *ngIf="pictureLoaded"
                               [style.background-image]="pictureSrc"></div>
                          <div class="thumbnail-background-placeholder mb-1" *ngIf="!pictureLoaded"></div>
                          <input type="button" class="btn btn-primary" value="{{'COMMON_BUTTON_DOWNLOAD'|translate}}"
                                 (click)="downloadPicture()"/>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <!-- /.col -->

                <div class="col-md-4 order-1">
                  <img *ngIf="m && m.external_id" class="pull-right" src="{{getQRCodeUrl(m.product_code)}}">
                </div>
                <!-- /.col -->

              </div>

            </form>
          </div>
        </div>
      </div>

      <div class="container-vertical-padding-half"></div>
      <div class="p-0 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-header">
            {{'STOCK_ITEM_SUMMARY'|translate}}
          </div>

          <div class="card-body">
            <!--TOTAL AMOUNT OF ITEMS IN STOCK-->
            <div class="row" *ngIf="totalAmountOfProductsInStock">
              <div class="form-group col-md-4 mb-0">
                <label readonly
                       class="col-form-label form-control-label detail-title font-weight-bold">{{'STOCK_ITEM_TOTAL_AMOUNT'|translate}}</label>
              </div>
              <div class="form-group col-md-8 mb-0">
                <label readonly
                       class="col-form-label form-control-label detail-description font-weight-bold">{{ totalAmountOfProductsInStock | formattedNumber }} {{ m.unit }}
                  <span
                    *ngIf="packageEnabled">({{StockItemUtils.convertToPackageStr(totalAmountOfProductsInStock, m.package_data)}}
                    )</span></label>
              </div>
            </div>

            <!--INTAKE SUM PRICE-->
            <div class="row" *ngIf="intakeNetPriceSum">
              <div class="form-group col-md-4 mb-0">
                <label readonly
                       class="col-form-label form-control-label detail-title font-weight-bold">{{'STOCK_RECORD_LOT_INTAKE_PRICE_SUM'|translate}}</label>
              </div>
              <div class="form-group col-md-8 mb-0">
                <label readonly
                       class="col-form-label form-control-label detail-description font-weight-bold">{{ intakeNetPriceSum | formattedNumber }} {{ getCurrencyName(m.unit_price.currency_code) }}
                </label>
              </div>
            </div>

            <div class="row" *ngIf="intakeNetPriceAvg">
              <div class="form-group col-md-4 mb-0">
                <label readonly
                       class="col-form-label form-control-label detail-title font-weight-bold">{{'STOCK_RECORD_LOT_INTAKE_PRICE_AVG'|translate}}</label>
              </div>
              <div class="form-group col-md-8 mb-0">
                <label readonly
                       class="col-form-label form-control-label detail-description font-weight-bold">{{ intakeNetPriceAvg | formattedNumber }} {{ getCurrencyName(m.unit_price.currency_code) }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-vertical-padding-half"></div>
      <div class="p-0 col-12" *ngIf="m && currencies.length > 0">
        <app-stock-item-customer-record-price-list
          [stockItemId]="stockItemId"
          [rightModel]="rightModel"
          [readonly]="true"
          [vatRate]="m.unit_price.vat_rate"
          [currency]="getCurrency(m.unit_price.currency_code)">
        </app-stock-item-customer-record-price-list>
      </div>
      <div class="container-vertical-padding-half"></div>
      <div class="p-0 col-12" *ngIf="m">
        <app-stock-item-measurement-list
          [stockItemId]="stockItemId"
          [rightModel]="rightModel"
          [readonly]="true">
        </app-stock-item-measurement-list>
      </div>
    </responsive-tab>
    <responsive-tab heading="{{'STOCK_RECORD_LOG_PANEL_HEADING_STOCK_ITEM'|translate}}">
      <app-stock-log-list
        [type]="StockLogListType.STOCK_ITEM"
        [resourceId]="stockItemId">
      </app-stock-log-list>
    </responsive-tab>
    <responsive-tab heading="{{'STOCK_ITEM_DETAIL_STOCK' | translate}}">
      <app-stock-item-stock-list
        [stockItemId]="stockItemId">
      </app-stock-item-stock-list>
    </responsive-tab>
  </responsive-tabset>
</div>

<div bsModal [config]="UiConstants.modalConfig" #stockItemMoveDialog="bs-modal" class="modal fade" tabindex="-1"
     role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true"
     (onHide)="onModalHidden()">
  <div class="modal-dialog modal-lg" role="document" *ngIf="stockItemMoveDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title"><strong>{{'STOCK_ITEM_MOVE_DIALOG_HEADING' | translate}}</strong></p>
        <button type="button" class="close" (click)="closeStockItemMoveDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <!--NAME-->
        <div class="row" *ngIf="m && m.name">
          <div class="form-group col-md-4 mb-0">
            <label readonly class="col-form-label form-control-label detail-title">{{'COMMON_NAME'|translate}}</label>
          </div>
          <div class="form-group col-md-8 mb-0">
            <label readonly class="col-form-label form-control-label detail-description"> {{ m.name }} </label>
          </div>
        </div>

        <!--EXTERNAL ID-->
        <div class="row" *ngIf="m && m.external_id">
          <div class="form-group col-md-4 mb-0">
            <label readonly
                   class="col-form-label form-control-label detail-title">{{'STOCK_ITEM_TABLE_HEADER_EXTERNAL_ID'|translate}}</label>
          </div>
          <div class="form-group col-md-8 mb-0">
            <label readonly class="col-form-label form-control-label detail-description"> {{ m.external_id }} </label>
          </div>
        </div>

        <!--SERIAL CODE-->
        <div class="row" *ngIf="m && m.serial_code">
          <div class="form-group col-md-4 mb-0">
            <label readonly
                   class="col-form-label form-control-label detail-title">{{'STOCK_ITEM_SERIAL_CODE'|translate}}</label>
          </div>
          <div class="form-group col-md-8 mb-0">
            <label readonly class="col-form-label form-control-label detail-description"> {{ m.serial_code }} </label>
          </div>
        </div>

        <!--UNIT PRICE-->
        <div class="row" *ngIf="m && m.unit_price && m.unit_price.value && m.unit_price.currency_code">
          <div class="form-group col-md-4 mb-0">
            <label readonly
                   class="col-form-label form-control-label detail-title">{{'STOCK_ITEM_UNIT_PRICE'|translate}}</label>
          </div>
          <div class="form-group col-md-8 mb-0">
            <label readonly
                   class="col-form-label form-control-label detail-description"> {{ m.unit_price.value | formattedNumber }} {{getCurrencyName(m.unit_price.currency_code)}} </label>
          </div>
        </div>

      </div>
      <form [formGroup]='stockMoveForm' (ngSubmit)="moveStockItem()" class="modal-body border-top-1">
        <div class="form-group row"
             [ngClass]="{ 'has-danger': (stockMoveForm.controls['moveSource'].touched && !stockMoveForm.controls['moveSource'].valid) || (moveFieldErrors.source_stock_id)}">
          <div class="col-md-4">
            <label class="col-form-label mw-100 form-control-label">{{'STOCK_ITEM_MOVE_SOURCE'|translate}}</label>
          </div>
          <div class="col-md-8">
            <div class="mw-100">
              <angular2-multiselect
                [(ngModel)]="selectedSource"
                [data]="containingStocks"
                [settings]="sourceDropdownSettings"
                formControlName="moveSource"
                (ngModelChange)="removeMoveFieldError(moveFieldErrors.source_stock_id)">
              </angular2-multiselect>
              <div class="form-control-feedback"
                   *ngIf="stockMoveForm.controls['moveSource'].touched && stockMoveForm.controls['moveSource'].hasError('required')">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
              <div class="form-control-feedback"
                   *ngIf="stockMoveForm.controls['moveSource'].touched && stockMoveForm.controls['moveSource'].hasError('hasDisabledItem')">
                {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
              </div>
              <div class="form-control-feedback" *ngIf="moveFieldErrors.source_stock_id">
                {{moveFieldErrors.source_stock_id.text}}
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row"
             [ngClass]="{ 'has-danger': (stockMoveForm.controls['moveDestination'].touched && !stockMoveForm.controls['moveDestination'].valid)
             || (moveFieldErrors.destination_stock_id)
             || stockMoveForm.hasError('equals')}">
          <div class="col-md-4">
            <label class="col-form-label mw-100 form-control-label">{{'STOCK_ITEM_MOVE_DESTINATION'|translate}}</label>
          </div>
          <div class="col-md-8">
            <div class="mw-100">
              <angular2-multiselect
                [(ngModel)]="selectedDestination"
                [data]="nonWasteStocks"
                [settings]="destinationDropdownSettings"
                formControlName="moveDestination"
                (ngModelChange)="removeMoveFieldError(moveFieldErrors.destination_stock_id)">
              </angular2-multiselect>
              <div class="form-control-feedback"
                   *ngIf="stockMoveForm.controls['moveDestination'].touched && stockMoveForm.controls['moveDestination'].hasError('required')">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
              <div class="form-control-feedback"
                   *ngIf="stockMoveForm.controls['moveDestination'].touched && stockMoveForm.hasError('equals')">
                {{'STOCK_VALIDATION_MESSAGE_EQUALS' | translate}}
              </div>
              <div class="form-control-feedback" *ngIf="moveFieldErrors.destination_stock_id">
                {{moveFieldErrors.destination_stock_id.text}}
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row"
             [ngClass]="{ 'has-danger': (stockMoveForm.controls['moveAmount'].touched && !stockMoveForm.controls['moveAmount'].valid) || (moveFieldErrors.amount)}">
          <div class="col-md-4">
            <label class="col-form-label mw-100 form-control-label">{{'STOCK_RECORD_AMOUNT'|translate}}</label>
          </div>
          <div class="col-md-6">
            <input type="text" class="form-control" placeholder="{{'STOCK_RECORD_AMOUNT' | translate}}"
                   [textMask]="{mask: InputMask.NATURAL_INTEGER, guide: false}"
                   [(ngModel)]="amountToMove"
                   formControlName="moveAmount"
                   (ngModelChange)="removeMoveFieldError(moveFieldErrors.amount)">
            <div class="form-control-feedback"
                 *ngIf="stockMoveForm.controls['moveAmount'].touched && stockMoveForm.controls['moveAmount'].hasError('required')">
              {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
            </div>
            <div class="form-control-feedback"
                 *ngIf="stockMoveForm.controls['moveAmount'].touched && stockMoveForm.controls['moveAmount'].hasError('min')">
              {{'COMMON_VALIDATION_MESSAGE_GREATER_THAN_ZERO' | translate}}
            </div>
            <div class="form-control-feedback" *ngIf="moveFieldErrors.amount">
              {{moveFieldErrors.amount.text}}
            </div>
          </div>
          <div class="col-md-2">
            <span *ngIf="packageEnabled"
                  class="col-form-label mw-100 form-control-label">({{StockItemUtils.convertToPackageStr(+amountToMove, m.package_data)}}
              )</span>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-4">
            <label class="col-form-label mw-100 form-control-label">{{'COMMON_NOTE'|translate}}</label>
          </div>
          <div class="col-md-8">
            <input type="text" class="form-control" placeholder="{{'COMMON_NOTE' | translate}}"
                   [(ngModel)]="note" [ngModelOptions]="{standalone: true}">
          </div>
        </div>
      </form>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary" (click)="closeStockItemMoveDialog()">
          {{'COMMON_BUTTON_CANCEL' | translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="moveStockItem()">{{'STOCK_ITEM_MOVE' |
          translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<div bsModal [config]="UiConstants.modalConfig" #stockItemDisposeDialog="bs-modal" class="modal fade" tabindex="-1"
     role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true"
     (onHide)="onModalHidden()">
  <div class="modal-dialog modal-lg" role="document" *ngIf="stockItemDisposeDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title"><strong>{{'STOCK_ITEM_DISPOSE_DIALOG_HEADING' | translate}}</strong></p>
        <button type="button" class="close" (click)="closeStockItemDisposeDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <!--NAME-->
        <div class="row" *ngIf="m && m.name">
          <div class="form-group col-md-4 mb-0">
            <label readonly class="col-form-label form-control-label detail-title">{{'COMMON_NAME'|translate}}</label>
          </div>
          <div class="form-group col-md-8 mb-0">
            <label readonly class="col-form-label form-control-label detail-description"> {{ m.name }} </label>
          </div>
        </div>

        <!--EXTERNAL ID-->
        <div class="row" *ngIf="m && m.external_id">
          <div class="form-group col-md-4 mb-0">
            <label readonly
                   class="col-form-label form-control-label detail-title">{{'STOCK_ITEM_TABLE_HEADER_EXTERNAL_ID'|translate}}</label>
          </div>
          <div class="form-group col-md-8 mb-0">
            <label readonly class="col-form-label form-control-label detail-description"> {{ m.external_id }} </label>
          </div>
        </div>

        <!--SERIAL CODE-->
        <div class="row" *ngIf="m && m.serial_code">
          <div class="form-group col-md-4 mb-0">
            <label readonly
                   class="col-form-label form-control-label detail-title">{{'STOCK_ITEM_SERIAL_CODE'|translate}}</label>
          </div>
          <div class="form-group col-md-8 mb-0">
            <label readonly class="col-form-label form-control-label detail-description"> {{ m.serial_code }} </label>
          </div>
        </div>

        <!--UNIT PRICE-->
        <div class="row" *ngIf="m && m.unit_price && m.unit_price.value && m.unit_price.currency_code">
          <div class="form-group col-md-4 mb-0">
            <label readonly
                   class="col-form-label form-control-label detail-title">{{'STOCK_ITEM_UNIT_PRICE'|translate}}</label>
          </div>
          <div class="form-group col-md-8 mb-0">
            <label readonly
                   class="col-form-label form-control-label detail-description"> {{ m.unit_price.value | formattedNumber }} {{getCurrencyName(m.unit_price.currency_code)}} </label>
          </div>
        </div>

      </div>
      <form [formGroup]='stockDisposeForm' (ngSubmit)="disposeStockItem()" class="modal-body border-top-1">
        <div class="form-group row"
             [ngClass]="{ 'has-danger': (stockDisposeForm.controls['disposeSource'].touched && !stockDisposeForm.controls['disposeSource'].valid) || (disposeFieldErrors.source_stock_id)}">
          <div class="col-md-4">
            <label class="col-form-label mw-100 form-control-label">{{'STOCK_ITEM_MOVE_SOURCE'|translate}}</label>
          </div>
          <div class="col-md-8">
            <div class="mw-100">
              <angular2-multiselect
                [(ngModel)]="selectedSource"
                [data]="containingStocks"
                [settings]="sourceDropdownSettings"
                formControlName="disposeSource"
                (ngModelChange)="removeDisposeFieldError(disposeFieldErrors.source_stock_id)">
              </angular2-multiselect>
              <div class="form-control-feedback"
                   *ngIf="stockDisposeForm.controls['disposeSource'].touched && stockDisposeForm.controls['disposeSource'].hasError('required')">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
              <div class="form-control-feedback" *ngIf="disposeFieldErrors.source_stock_id">
                {{disposeFieldErrors.source_stock_id.text}}
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row"
             [ngClass]="{ 'has-danger': (stockDisposeForm.controls['disposeDestination'].touched && !stockDisposeForm.controls['disposeDestination'].valid) || (disposeFieldErrors.destination_stock_id)}">
          <div class="col-md-4">
            <label class="col-form-label mw-100 form-control-label">{{'STOCK_ITEM_MOVE_DESTINATION'|translate}}</label>
          </div>
          <div class="col-md-8">
            <div class="mw-100">
              <angular2-multiselect
                [(ngModel)]="selectedDestination"
                [data]="wasteStocks"
                [settings]="destinationDropdownSettings"
                formControlName="disposeDestination"
                (ngModelChange)="removeDisposeFieldError(disposeFieldErrors.destination_stock_id)">
              </angular2-multiselect>
              <div class="form-control-feedback"
                   *ngIf="stockDisposeForm.controls['disposeDestination'].touched && stockDisposeForm.controls['disposeDestination'].hasError('required')">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
              <div class="form-control-feedback" *ngIf="disposeFieldErrors.destination_stock_id">
                {{disposeFieldErrors.destination_stock_id.text}}
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row"
             [ngClass]="{ 'has-danger': (stockDisposeForm.controls['disposeAmount'].touched && !stockDisposeForm.controls['disposeAmount'].valid) || (disposeFieldErrors.amount)}">
          <div class="col-md-4">
            <label class="col-form-label mw-100 form-control-label">{{'STOCK_RECORD_AMOUNT'|translate}}</label>
          </div>
          <div class="col-md-6">
            <input type="text" class="form-control" placeholder="{{'STOCK_RECORD_AMOUNT' | translate}}"
                   [textMask]="{mask: InputMask.NATURAL_INTEGER, guide: false}"
                   [(ngModel)]="amountToMove"
                   formControlName="disposeAmount"
                   (ngModelChange)="removeDisposeFieldError(disposeFieldErrors.amount)">
            <div class="form-control-feedback"
                 *ngIf="stockDisposeForm.controls['disposeAmount'].touched && stockDisposeForm.controls['disposeAmount'].hasError('required')">
              {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
            </div>
            <div class="form-control-feedback"
                 *ngIf="stockDisposeForm.controls['disposeAmount'].touched && stockDisposeForm.controls['disposeAmount'].hasError('min')">
              {{'COMMON_VALIDATION_MESSAGE_GREATER_THAN_ZERO' | translate}}
            </div>
            <div class="form-control-feedback" *ngIf="disposeFieldErrors.amount">
              {{disposeFieldErrors.amount.text}}
            </div>
          </div>
          <div class="col-md-2">
            <span *ngIf="packageEnabled"
                  class="col-form-label mw-100 form-control-label">({{StockItemUtils.convertToPackageStr(+amountToMove, m.package_data)}}
              )</span>
          </div>

        </div>
        <div class="form-group row">
          <div class="col-md-4">
            <label class="col-form-label mw-100 form-control-label">{{'COMMON_NOTE'|translate}}</label>
          </div>
          <div class="col-md-8">
            <input type="text" class="form-control" placeholder="{{'COMMON_NOTE' | translate}}"
                   [(ngModel)]="note" [ngModelOptions]="{standalone: true}">
          </div>
        </div>
      </form>
      <!-- /.modal-body -->
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary" (click)="closeStockItemDisposeDialog()">
          {{'COMMON_BUTTON_CANCEL' | translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="disposeStockItem()">{{'STOCK_ITEM_DISPOSE' |
          translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
