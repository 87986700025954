/* eslint-disable */
import { DisableRequest, EmptyMessage, IdentityMessage } from '../util/messages';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { ResourceQueryResult } from '../util/services';

/* eslint-enable */

@Injectable()
export class RoleResourceService extends BaseHttpService {

  query(request: RoleResource.QueryRequest): Observable<ResourceQueryResult<RoleResource.Role>> {
    return this.http.get<ResourceQueryResult<RoleResource.Role>>(this.url, this.parseParams(request));
  }

  get(request: IdentityMessage): Observable<RoleResource.Role> {
    return this.http.get<RoleResource.Role>(this.url + `${request.id}`);
  }

  create(request: RoleResource.CreateRequest): Observable<EmptyMessage> {
    return this.http.post(this.url, request);
  }

  update(request: RoleResource.UpdateRequest): Observable<EmptyMessage> {
    return this.http.put(this.url + `${request.id}`, request);
  }

  setDisabled(request: DisableRequest): Observable<EmptyMessage> {
    return this.http.patch(this.url + `${request.id}/disabled`, request);
  }

  importPrivileges(request: RoleResource.PrivilegeImportRequest): Observable<EmptyMessage> {
    return this.http.post(this.url + `${request.id}/privilege/import`, request);
  }

  exportPrivileges(request: IdentityMessage): Observable<RoleResource.PrivilegeExportResponse> {
    return this.http.get<RoleResource.PrivilegeExportResponse>(this.url + `${request.id}/privilege/export`);
  }

  importRole(request: RoleResource.RoleImportRequest) {
    return this.http.post(this.url + `/import`, request);
  }

  exportRole(request: IdentityMessage): Observable<RoleResource.RoleImportRequest> {
    return this.http.get<RoleResource.RoleImportRequest>(this.url + `${request.id}/export`);
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/roles/');
  }
}

export namespace RoleResource {

  export interface QueryRequest {
    fields?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
    with_privileges?: boolean;

    id?: string;
    name?: string;
    disabled?: boolean;
    no_progress_bar?: boolean;
  }

  export interface CreateRequest {
    name: string;
    privileges: string[];
  }

  export interface UpdateRequest {
    id: number;
    name: string;
    privileges: string[];
  }

  export interface DisableRequest extends IdentityMessage {
    disabled: boolean;
  }

  export interface Role {
    id: number;
    name: string;
    privileges?: string[];
    protected_role: boolean;
    disabled: boolean;
  }

  export interface PrivilegeImportRequest {
    id: number
    privileges: string[];
  }

  export interface PrivilegeExportResponse {
    privileges: string[];
  }

  export interface RoleImportRequest {
    name: string;
    privileges: string[];
  }
}
