/* eslint-disable */
import { Injectable } from '@angular/core';
import { IdentityMessage, ListQuery } from '../../util/messages';
import { Observable } from 'rxjs';
import { AuditLogCallType, AuditLogResult } from '../../../util/audit-log-utils';
import { Map as ImmutableMap } from 'immutable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from '../../util/http-services';
/* eslint-enable */

export namespace AuditLogList {

  export enum OrderField {
    ID,
    USER_NAME,
    APPLICATION_ID,
    REQUEST_URI,
    RESULT,
    CREATION_TIME
  }

  export class Keys {

    private static readonly ID = 'id';
    private static readonly USER_NAME = 'user_name';
    private static readonly APPLICATION_ID = 'application_id';
    private static readonly REQUEST_URI = 'request_uri';
    private static readonly RESULT = 'result';
    private static readonly CREATION_TIME = 'creation_time';

    private static readonly orderFieldKeyMap: ImmutableMap<AuditLogList.OrderField, string> = ImmutableMap.of(
      AuditLogList.OrderField.ID, Keys.ID,
      AuditLogList.OrderField.USER_NAME, Keys.USER_NAME,
      AuditLogList.OrderField.APPLICATION_ID, Keys.APPLICATION_ID,
      AuditLogList.OrderField.REQUEST_URI, Keys.REQUEST_URI,
      AuditLogList.OrderField.RESULT, Keys.RESULT,
      AuditLogList.OrderField.CREATION_TIME, Keys.CREATION_TIME,
    );

    public static toOrderFieldKey(field: AuditLogList.OrderField): string {
      return Keys.orderFieldKeyMap.get(field)!;
    }

  }

}

export interface AuditQuery extends ListQuery.Paging, ListQuery.Order, ListQuery.QueryText, ListQuery.Fields {
  user_name?: string;
  user_id?: string;
  exclude_health_check?: boolean;
  exclude_su?: boolean;
  request_method?: string;
  request_uri?: string;
  result?: AuditLogResult;
  device_id?: string;
  application_name?: string;
  application_id?: string;
  has_application_id?: boolean;
  create_time_from?: string;
  create_time_to?: string;
  api_operation_definition?: string;
  call_type?: AuditLogCallType;
}

export class AuditLog {
  id: number;
  user_id?: number;
  user_name?: string;
  ip_address: string;
  request_uri: string;
  request_method: string;
  request_query?: string;
  request_json?: string;
  response_json?: string;
  exception?: string;
  result?: string;
  creation_time: string;
  finish_time?: string;
  duration_in_nanoseconds?: number;
  content_type?: string;
  device_id?: string;
  has_application_id: boolean;
  application_name?: string;
  application_id?: string;
  api_operation_id?: number;
  api_operation_definition?: string;
  call_type: AuditLogCallType;
}

@Injectable()
export class AuditLogService extends BaseHttpService {

  query(request: AuditQuery): Observable<AuditLog[]> {
    return this.http.get<AuditLog[]>(this.url, this.parseParams(request));
  }

  get(request: IdentityMessage): Observable<AuditLog> {
    return this.http.get<AuditLog>(this.url + `${request.id}`);
  }

  getHtml(request: IdentityMessage): Observable<Blob> {
    const path: string = this.url + request.id + '/html';
    return this.http.get(path, {responseType: 'blob'});
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/audit-log/');
  }

}
