/* eslint-disable */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { StateName } from '../../../app.state-names';
import { RightResolver, RightService } from '../../../lib/right.service';
import { TranslateService } from '@ngx-translate/core';
import { LegacyWorkflow, LegacyWorkflowService } from '../../../lib/legacy-workflow/legacy-workflow-service';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { QueryFieldModel, SelectUtils, UiConstants } from '../../../util/core-utils';
import { OrderType, QueryResult } from '../../../lib/util/services';
import { RightModel } from '../../../app.rights';
import { InputMask } from '../../../util/input-masks';
import { LegacyWorkflowSearchModel } from '../../../lib/legacy-workflow/legacy-workflow-utils';
import { combineLatest, Observable } from 'rxjs';
import { LoadingHandler } from '../../../lib/loading-handler';
import { Models } from '../../../util/model-utils';
import { Set } from 'immutable';
import { Strings } from '../../../lib/util/strings';
import { Icon } from '../../../lib/task/icon.service';
import { LegacyWorkflowSearch, LegacyWorkflowSearchService } from '../../../lib/legacy-workflow/legacy-workflow-search-service';
import { Arrays } from '../../../lib/util/arrays';
import { DisabledEnum, DisabledItem } from '../../../util/search-utils';
/* eslint-enable */

@Component({
  selector: 'app-workflow-legacy-list',
  templateUrl: './legacy-workflow-list.component.html',
  styleUrls: ['./legacy-workflow-list.component.scss']
})
export class LegacyWorkflowListComponent implements OnInit, OnDestroy {

  Workflow = LegacyWorkflow;
  UiConstants = UiConstants;

  queryModel: QueryFieldModel<LegacyWorkflow.OrderField> = new QueryFieldModel(LegacyWorkflow.OrderField.ID, OrderType.DESC);
  workflowList: Array<LegacyWorkflow.Workflow> = [];
  showSearch: boolean = false;
  rightModel: RightModel = RightModel.empty();
  disabledItems: DisabledItem[] = [];
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  searchModel: LegacyWorkflowSearchModel = new LegacyWorkflowSearchModel();
  InputMask = InputMask;
  SelectUtils = SelectUtils;

  constructor(
    private rightService: RightService,
    private translateService: TranslateService,
    private legacyWorkflowService: LegacyWorkflowService,
    private legacyWorkflowSearchService: LegacyWorkflowSearchService) {
  }

  ngOnInit() {
    this.initDisabledOptions(DisabledEnum.FALSE);
    this.translateService.get('WORKFLOW_LIST').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.queryModel = new QueryFieldModel(LegacyWorkflow.OrderField.ID, OrderType.DESC);
    this.loadRightModels();
    this.loadSearch(() => {
      this.showSearch = !this.searchModel.isEmpty();
      this.loadList();
    });
  }

  private loadSearch(completion: () => void) {
    const obs: Observable<SearchLoadResult> = combineLatest(
      this.legacyWorkflowSearchService.getSearchData({}),
      (storedSearchData: LegacyWorkflowSearch.SearchDataResult) => {
        const result: SearchLoadResult = {
          storedSearchData: storedSearchData
        };
        return result;
      }
    );
    obs.subscribe(
      (result: SearchLoadResult) => {
        this.postInitSearch(result.storedSearchData);
        completion();
      }
    );
  }

  private postInitSearch(storedSearchData: LegacyWorkflowSearch.SearchDataResult) {
    this.queryModel.itemsPerPage = storedSearchData.searchData.itemsPerPage;
    this.queryModel.currentPage = storedSearchData.searchData.pageNumber;
    this.queryModel.setOrder(storedSearchData.searchData.order);
    this.searchModel.id = storedSearchData.searchData.id;
    this.searchModel.name = storedSearchData.searchData.name;
    this.searchModel.externalId = storedSearchData.searchData.external_id;
    this.searchModel.description = storedSearchData.searchData.description;
    this.searchModel.creationTimeFrom = Models.localDateToNgbDate(storedSearchData.searchData.creationTimeFrom);
    this.searchModel.creationTimeTo = Models.localDateToNgbDate(storedSearchData.searchData.creationTimeTo);
    this.searchModel.updateTimeFrom = Models.localDateToNgbDate(storedSearchData.searchData.updateTimeFrom);
    this.searchModel.updateTimeTo = Models.localDateToNgbDate(storedSearchData.searchData.updateTimeTo);
    this.searchModel.disabled = storedSearchData.searchData.disabled;
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: LegacyWorkflow.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  private initDisabledOptions(initValue: DisabledEnum) {
    this.disabledItems = [];
    const disabledEnums: DisabledEnum[] = [DisabledEnum.NONE, DisabledEnum.FALSE, DisabledEnum.TRUE];
    Arrays.iterateByIndex(disabledEnums, (key) => {
      const item = new DisabledItem();
      item.id = key;
      this.translateService.get('COMMON_VALUE_DISABLED_ENUM_' + key).subscribe(
        (text: string) => {
          item.text = text;
        }
      );
      this.disabledItems.push(item);
      if (key === initValue) {
        this.searchModel.disabled = item;
      }
    });
  }

  setDisabled(event: any, workflow: LegacyWorkflow.Workflow, disabled: boolean) {
    this.legacyWorkflowService.setDisabled({
      workflowId: workflow.workflowId,
      disabled: disabled
    }).subscribe(
      (result: LegacyWorkflow.Workflow) => {
        LoadingHandler.getInstance().refresh();
        this.loadList();
      },
      (error: any) => {
        this.loadList();
      }
    );
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  private loadList(pageNumber?: number) {
    const disabled: boolean | undefined = !this.searchModel.disabled ||
    this.searchModel.disabled.id === DisabledEnum.NONE ?
      undefined : this.searchModel.disabled.id === DisabledEnum.TRUE;
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();
    const workflowIdSet: number[] = [];
    if (Models.parseNumber(this.searchModel.id)) {
      workflowIdSet.push(Models.parseNumber(this.searchModel.id)!);
    }
    this.legacyWorkflowService.query({
      workflowIdSet: Set.of(...workflowIdSet),
      name: Strings.undefinedOrNonEmpty(this.searchModel.name),
      externalId: Strings.undefinedOrNonEmpty(this.searchModel.externalId),
      description: Strings.undefinedOrNonEmpty(this.searchModel.description),
      creationTimeFrom: Models.parseDateTimeFrom(this.searchModel.creationTimeFrom),
      creationTimeTo: Models.parseDateTimeTo(this.searchModel.creationTimeTo),
      updateTimeFrom: Models.parseDateTimeFrom(this.searchModel.updateTimeFrom),
      updateTimeTo: Models.parseDateTimeTo(this.searchModel.updateTimeTo),
      disabled: disabled,
      orders: Set.of(order),
      paging: {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      }
    }).subscribe(
      (result: QueryResult<LegacyWorkflow.Workflow>) => {
        this.workflowList = result.items.toArray();
        this.queryModel.currentPage = requestedPage;
        this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
        this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
      }
    );
  }

  onSearchClicked() {
    this.loadList();
  }

  onSearchReset() {
    this.legacyWorkflowSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.showSearch = true;
          this.loadList(1);
        });
      }
    );
  }

  private saveSearch() {
    const request = {
      searchData: {
        itemsPerPage: this.queryModel.itemsPerPage,
        pageNumber: this.queryModel.currentPage,
        order: this.queryModel.getOrder(),
        id: this.searchModel.id,
        name: this.searchModel.name,
        external_id: this.searchModel.externalId,
        description: this.searchModel.description,
        disabled: this.searchModel.disabled,
        creationTimeFrom: Models.ngbDateToLocalDate(this.searchModel.creationTimeFrom),
        creationTimeTo: Models.ngbDateToLocalDate(this.searchModel.creationTimeTo),
        updateTimeFrom: Models.ngbDateToLocalDate(this.searchModel.updateTimeFrom),
        updateTimeTo: Models.ngbDateToLocalDate(this.searchModel.updateTimeTo),
      }
    };
    this.legacyWorkflowSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  ngOnDestroy() {
    this.saveSearch();
  }

}

interface SearchLoadResult {
  storedSearchData: LegacyWorkflowSearch.SearchDataResult,
}
