/* eslint-disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {BaseHttpService, ResourceHelper} from '../../util/http-services';
import {ResourceQueryResult} from '../../util/services';
import {Map as ImmutableMap} from 'immutable';

/* eslint-enable */

@Injectable()
export class TaskStatisticsResourceService extends BaseHttpService {

  query(request: TaskStatisticsResource.QueryRequest): Observable<ResourceQueryResult<TaskStatisticsResource.TaskStatistic>> {
    return this.http.get<ResourceQueryResult<TaskStatisticsResource.TaskStatistic>>(this.url + 'log-stat', this.parseParams(request));
  }

  get(request: TaskStatisticsResource.GetRequest): Observable<ResourceQueryResult<TaskStatisticsResource.TaskStatistic>> {
    return this.http.get<ResourceQueryResult<TaskStatisticsResource.TaskStatistic>>(this.url + 'log-stat', this.parseParams(request));
  }

  getSimpleStat(request: { helpdesk_enabled?: boolean }): Observable<TaskStatisticsResource.SimpleStat> {
    return this.http.get<TaskStatisticsResource.SimpleStat>(this.url + 'simple-stat', this.parseParams(request));
  }

  getHelpdeskStat(): Observable<ImmutableMap<string, number>> {
    return this.http.get<ImmutableMap<string, number>>(this.url + 'helpdesk-stat');
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/tasks/statistics/');
  }

}

export namespace TaskStatisticsResource {

  export interface QueryRequest {
    by: string;
    task_id?: number;
    user_profile_id?: number;
    task_name?: string;
    user_profile_name?: string;
    q?: string,
    order?: string;
    page_number?: number;
    number_of_items?: number;
  }

  export interface GetRequest {
    by: string;
    task_id: number;
    user_profile_id: number;
  }

  export interface TaskStatistic {
    task_id: number;
    id: string;
    user_profile_id: number;
    create_count: number;
    start_count: number;
    recall_count: number;
    reject_count: number;
    finish_count: number;
  }

  export interface SimpleStat {
    state_stat: Map<string, number>;
    overdue_count: number;
  }

}
