import { WorkflowEditorCanvas } from './workflow-editor-canvas';
import { CanvasDragLogService } from './service/canvas-drag-log-service';
import { DragConnectionCreatePolicy } from './policy/drag-connection-create-policy';
import { WorkflowEditorPainter } from './workflow-editor-painter';
import { Workflow, WorkflowService } from '../../../../lib/workflow/workflow.service';
import { CanvasSelectionService } from './service/canvas-selection-service';
import { MatDialog } from '@angular/material/dialog';

export class WorkflowEditorApplication {

  public canvas: WorkflowEditorCanvas;
  public painter: WorkflowEditorPainter;

  constructor(
    private canvasDragLogService: CanvasDragLogService,
    private canvasSelectionService: CanvasSelectionService,
    private workflowService: WorkflowService,
    private dialog: MatDialog
  ) {
    // Create and initialize canvas
    this.canvas = new WorkflowEditorCanvas(
      'canvas',
      canvasDragLogService,
      canvasSelectionService,
      workflowService,
      dialog);
    // Install policy to customize the arrows between the shapes
    this.canvas.installEditPolicy(new DragConnectionCreatePolicy());

    this.painter = new WorkflowEditorPainter(
      this.canvasDragLogService,
      this.canvasSelectionService,
      this.canvas);
  }

  setStateMachine(readonly: boolean, stateMachine: Workflow.StateMachine, taskStateMap) {
    this.painter.setStateMachine(readonly, stateMachine, taskStateMap);
  }

}
