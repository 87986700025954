import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, NgForm, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {RoleResource} from '../../../lib/role/role-resource.service';
import {UiConstants} from '../../../util/core-utils';
import {RoleService} from '../../../lib/role/role.service';
import {Subject} from 'rxjs';
import {FieldValidationError, ForwardingNgFormRef, LocalFormGroupValidationErrors} from '../../../lib/util/services';
import {AppValidators} from '../../../util/app-validators';
import {ErrorMessageService} from '../../../lib/error-message-parser.service';
import {PrivilegeImportModel} from "../role-edit/role-edit.component";

@Component({
  selector: 'app-role-import-dialog',
  templateUrl: './role-import-dialog.component.html',
  styleUrls: ['./role-import-dialog.component.scss']
})
export class RoleImportDialogComponent implements OnInit {

  UiConstants = UiConstants;
  @ViewChild('f') form: NgForm;
  formGroup: FormGroup;
  private formGroupValidationErrors: LocalFormGroupValidationErrors;

  importInProgress: boolean = false;
  importDnDVisible: boolean = true;
  fieldErrors?: string;
  uploadGlobalErrors?: string;
  fileName?: string;
  importResult?: string = undefined;

  model: RoleImportModel = new RoleImportModel();

  constructor(
    private translateService: TranslateService,
    private roleService: RoleService,
    private formBuilder: FormBuilder,
    private errorMessageService: ErrorMessageService,
    public dialogRef: MatDialogRef<RoleImportDialogComponent, RoleImportDialogResult>,
    @Inject(MAT_DIALOG_DATA) public data: RoleImportDialogData
  ) {
    this.formInit();
  }

  formInit() {
    this.formGroup = this.createFormGroup(this.formBuilder);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(
      this.createForwardingHtmlForm(),
      this.formGroup
    );
  }
  public static openDialog(
    dialog: MatDialog,
    data: RoleImportDialogData,
    resultCallback: (result?: RoleImportDialogResult) => void) {
    const dialogRef = dialog.open(RoleImportDialogComponent, {
      closeOnNavigation: true,
      data: data,
      width: '60%',
      height: 'auto',
      panelClass: 'custom-dialog-container'
    });
    dialogRef.afterClosed().subscribe((result?: RoleImportDialogResult) => {
      resultCallback(result);
    });
  }

  closeDialog(roleId?: number) {
    if (roleId) {
      this.dialogRef.close({roleId: roleId});
    } else {
      this.dialogRef.close();
    }
  }

  onImportableFileDropped(files: File[]) {
    this.importResult = undefined;
    const file = files[0];
    this.fileName = file.name;
    const fr = new FileReader();
    const subject: Subject<any> = new Subject<any>();
    fr.onloadend = (function (f) {
      return function (e) {
        try {
          const res = JSON.parse(this.result);
          subject.next(res);
        } catch (e) {
          subject.error('');
        }
      };
    })(file);
    fr.readAsText(file);
    subject.asObservable().subscribe((doc: RoleResource.RoleImportRequest) => {
      if (doc) {
        this.model.name = doc.name;
        this.model.overriddenName = doc.name;
        this.model.overrideName = !this.model.name;
        this.model.privileges = new PrivilegeImportModel();
        this.model.privileges.importablePrivileges = doc.privileges;
        this.importResult = undefined;
        this.importDnDVisible = false;
      } else {
        this.importResult = 'FORMAT_ERROR';
        this.importDnDVisible = true;
      }
      this.formInit();
    }, error => {
      this.importResult = 'FORMAT_ERROR';
      this.importDnDVisible = true;
    });
  }

  onFileRemoved() {
    this.model.name = undefined;
    this.model.privileges.importablePrivileges = [];
    this.fileName = undefined;
    this.importResult = undefined;
    this.fieldErrors = undefined;
    this.uploadGlobalErrors = undefined;
    this.importDnDVisible = true;
  }

  importRole() {
    if (this.importResult !== undefined && this.importResult !== 'ERROR') {
      return;
    }
    if (this.hasLocalFieldError()) {
      return;
    }
    this.roleService.importRole({
      name: this.model.overrideName ? this.model.overriddenName! : this.model.name!,
      privileges: this.model.privileges.importablePrivileges,
    }).subscribe(result => {
      this.closeDialog(result.id)
    }, (error) => {

      this.importResult = 'ERROR';
      const localizedError = this.errorMessageService.parseError(error);
      this.uploadGlobalErrors = localizedError?.globalErrors?.join('<br/>');
      this.fieldErrors = '';
      if (localizedError?.hasFieldErrors) {
        const f = localizedError!.extractFieldErrors();
        for (let key of Object.keys(f)) {
          this.fieldErrors += `${key}: ${f[key].text}<br/>`;
        }

      }
    });
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  private createForwardingHtmlForm() {
    return new ForwardingNgFormRef({
      formFn: () => {
        return this.form;
      }
    });
  }

  private createFormGroup(fb: FormBuilder): FormGroup {
    return fb.group({
      overrideName: fb.control(
        {value: this.model.overrideName, disabled: !this.model.name},
        []
      ),
      overriddenName: fb.control(
        {value: this.model.overriddenName},
        [AppValidators.tempValidator({
          disabled: () => !this.model.overrideName,
          validator: Validators.required
        })]
      )
    });
  }

  ngOnInit(): void {
  }

}

export interface RoleImportDialogResult {
  roleId: number;
}

export interface RoleImportDialogData {
}

class RoleImportModel {
  name?: string;
  privileges: PrivilegeImportModel = new PrivilegeImportModel();
  overrideName: boolean = false;
  overriddenName: string = '';
}
