/* eslint-disable */
import { Injectable } from '@angular/core';
import { List, Map, Set } from 'immutable';
import { SurveyResource, SurveyResourceService } from './survey-resource.service';
import { Observable, Observer } from 'rxjs';
import {
  FieldValidationError,
  Order,
  PagingRequest,
  QueryResult,
  ResourceQueryResult,
  Services
} from '../util/services';
import { DownloadedFile } from '../util/downloaded-files';
import { OffsetDateTime, } from '../util/dates';
import { Form } from '../form/form.service';
import { FormResource } from '../form/form-resource.service';
import { EmptyMessage } from '../util/messages';
import { ObservableErrorResourceParser } from '../util/errors';

/* eslint-enable */


@Injectable()
export class SurveyService implements Survey.Service, Form.Service {

  private formMapper: Form.ResourceMapper;

  constructor(private resourceService: SurveyResourceService) {
    this.formMapper = new Form.ResourceMapper();
  }

  // <editor-fold desc="CRUD">

  query(request: Survey.QueryRequest): Observable<QueryResult<Survey.Survey>> {
    return Observable.create((observer: Observer<QueryResult<Survey.Survey>>) => {
      const resourceRequest: SurveyResource.QueryRequest = {
        id: Services.createIdParameter(request.surveyIdSet),
        disabled: request.disabled,
        name: request.name,
        q: request.queryText,
        order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
        no_progress_bar: request.noProgressBar
      };
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<SurveyResource.Survey>) => {
          observer.next({
            items: this.toPublicList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  get(request: Survey.GetRequest): Observable<Survey.Survey> {
    return Observable.create((observer: Observer<Survey.Survey>) => {
      const resourceRequest: SurveyResource.GetRequest = {
        survey_id: request.surveyId
      };
      return this.resourceService.get(resourceRequest).subscribe(
        (result: SurveyResource.Survey) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  create(request: Survey.CreateRequest): Observable<Survey.Survey> {
    return Observable.create((observer: Observer<Survey.Survey>) => {
      const resourceRequest: SurveyResource.CreateRequest = {
        name: request.name,
        external_id: request.externalId,
        description: request.description
      };
      return this.resourceService.create(resourceRequest).subscribe(
        (result: SurveyResource.Survey) => {
          observer.next(this.toPublic(result));
        },
        (error: any) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  update(request: Survey.UpdateRequest): Observable<Survey.Survey> {
    return Observable.create((observer: Observer<Survey.Survey>) => {
      const resourceRequest: SurveyResource.UpdateRequest = {
        survey_id: request.surveyId,
        name: request.name,
        external_id: request.externalId,
        description: request.description
      };
      return this.resourceService.update(resourceRequest).subscribe(
        (result: SurveyResource.Survey) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  setDisabled(request: Survey.DisableRequest): Observable<Survey.Survey> {
    return Observable.create((observer: Observer<Survey.Survey>) => {
      const resourceRequest: SurveyResource.DisableRequest = {
        survey_id: request.surveyId,
        disabled: request.disabled
      };
      return this.resourceService.setDisabled(resourceRequest).subscribe(
        (result: SurveyResource.Survey) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  // </editor-fold>

  // <editor-fold desc="FileAttachment">

  deletePdfTemplate(request: Survey.DeleteAttachmentRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.resourceService.deletePdfTemplate({
        survey_id: request.surveyId
      }).subscribe((result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  downloadPdfTemplate(request: Survey.DownloadAttachmentRequest): Observable<DownloadedFile> {
    return this.resourceService.downloadPdfTemplate({
      survey_id: request.surveyId
    });
  }

  deleteThumbnail(request: Survey.DeleteAttachmentRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.resourceService.deleteThumbnail({
        survey_id: request.surveyId
      }).subscribe((result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  downloadThumbnail(request: Survey.DownloadAttachmentRequest): Observable<DownloadedFile> {
    return this.resourceService.downloadThumbnail({
      survey_id: request.surveyId
    });
  }

  // </editor-fold>

  // <editor-fold desc="Form">

  getForm(request: Form.GetFormRequest): Observable<Form.Form> {
    return Observable.create((observer: Observer<Form.Form>) => {
      const resourceRequest: FormResource.GetFormRequest =
        this.formMapper.toResourceGetFormRequest(request);
      return this.resourceService.getForm(resourceRequest).subscribe(
        (result: FormResource.Form) => {
          observer.next(this.formMapper.toPublicForm(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateGetFormError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  createGroup(request: Form.CreateGroupRequest): Observable<Form.CreateGroupResponse> {
    return Observable.create((observer: Observer<Form.CreateGroupResponse>) => {
      const resourceRequest: FormResource.CreateGroupRequest =
        this.formMapper.toResourceCreateGroupRequest(request);
      return this.resourceService.createFormGroup(resourceRequest).subscribe(
        (result: FormResource.CreateGroupResponse) => {
          observer.next(this.formMapper.toPublicCreateGroupResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateCreateGroupError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  updateGroup(request: Form.UpdateGroupRequest): Observable<Form.UpdateGroupResponse> {
    return Observable.create((observer: Observer<Form.UpdateGroupResponse>) => {
      const resourceRequest: FormResource.UpdateGroupRequest =
        this.formMapper.toResourceUpdateGroupRequest(request);
      return this.resourceService.updateFormGroup(resourceRequest).subscribe(
        (result: FormResource.UpdateGroupResponse) => {
          observer.next(this.formMapper.toPublicUpdateGroupResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateUpdateGroupError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  disableGroup(request: Form.DisableGroupRequest): Observable<Form.DisableGroupResponse> {
    return Observable.create((observer: Observer<Form.DisableGroupResponse>) => {
      const resourceRequest: FormResource.DisableGroupRequest =
        this.formMapper.toResourceDisableGroupRequest(request);
      return this.resourceService.disableFormGroup(resourceRequest).subscribe(
        (result: FormResource.DisableGroupResponse) => {
          observer.next(this.formMapper.toPublicDisableGroupResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateDisableGroupError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  moveGroup(request: Form.MoveGroupRequest): Observable<Form.MoveGroupResponse> {
    return Observable.create((observer: Observer<Form.MoveGroupResponse>) => {
      const resourceRequest: FormResource.MoveGroupRequest =
        this.formMapper.toResourceMoveGroupRequest(request);
      return this.resourceService.moveFormGroup(resourceRequest).subscribe(
        (result: FormResource.MoveGroupResponse) => {
          observer.next(this.formMapper.toPublicMoveGroupResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateMoveGroupError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  createField(request: Form.CreateFieldRequest): Observable<Form.CreateFieldResponse> {
    return Observable.create((observer: Observer<Form.CreateFieldResponse>) => {
      const resourceRequest: FormResource.CreateFieldRequest =
        this.formMapper.toResourceCreateFieldRequest(request);
      return this.resourceService.createFormField(resourceRequest).subscribe(
        (result: FormResource.CreateFieldResponse) => {
          observer.next(this.formMapper.toPublicCreateFieldResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateCreateFieldError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  updateField(request: Form.UpdateFieldRequest): Observable<Form.UpdateFieldResponse> {
    return Observable.create((observer: Observer<Form.UpdateFieldResponse>) => {
      const resourceRequest: FormResource.UpdateFieldRequest =
        this.formMapper.toResourceUpdateFieldRequest(request);
      return this.resourceService.updateFormField(resourceRequest).subscribe(
        (result: FormResource.UpdateFieldResponse) => {
          observer.next(this.formMapper.toPublicUpdateFieldResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateUpdateFieldError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  disableField(request: Form.DisableFieldRequest): Observable<Form.DisableFieldResponse> {
    return Observable.create((observer: Observer<Form.DisableFieldResponse>) => {
      const resourceRequest: FormResource.DisableFieldRequest =
        this.formMapper.toResourceDisableFieldRequest(request);
      return this.resourceService.disableFormField(resourceRequest).subscribe(
        (result: FormResource.DisableFieldResponse) => {
          observer.next(this.formMapper.toPublicDisableFieldResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateDisableFieldError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  moveFieldToGroup(request: Form.MoveFieldToGroupRequest): Observable<Form.MoveFieldToGroupResponse> {
    return Observable.create((observer: Observer<Form.MoveFieldToGroupResponse>) => {
      const resourceRequest: FormResource.MoveFieldToGroupRequest =
        this.formMapper.toResourceMoveFieldToGroupRequest(request);
      return this.resourceService.moveFormFieldToGroup(resourceRequest).subscribe(
        (result: FormResource.MoveFieldToGroupResponse) => {
          observer.next(this.formMapper.toPublicMoveFieldToGroupResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateMoveFieldToGroupError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  importForm(request: Form.ImportRequest): Observable<Form.Form> {
    return Observable.create((observer: Observer<Form.Form>) => {
      return this.resourceService.import({
        survey_id: request.parentId,
        form: request.form,
        version: request.version
      }).subscribe((result: FormResource.Form) => {
          observer.next(this.formMapper.toPublicForm(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        }
      );
    });
  }

  exportForm(request: Form.ExportRequest): Observable<Form.FormImportDocument> {
    return Observable.create((observer: Observer<Form.FormImportDocument>) => {
      return this.resourceService.export({
        survey_id: request.parentId,
      }).subscribe((result: FormResource.FormImportDocument) => {
          observer.next({form: result.form, version: result.version});
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        }
      );
    });
  }

  counts(request: EmptyMessage): Observable<List<Survey.SurveyCount>> {
    return Observable.create((observer: Observer<List<Survey.SurveyCount>>) => {
      return this.resourceService.counts({}).subscribe(
        (result: Array<SurveyResource.SurveyCount>) => {
          observer.next(this.toPublicCountList(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  // </editor-fold>

  // <editor-fold desc="Internal">

  private translateError(error: any): any {
    const res = ObservableErrorResourceParser.parseError(error);
    const fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
    const fieldErrorMap = ObservableErrorResourceParser.toFieldErrorMap(Keys.toValidatedField, fieldErrors);
    if (!fieldErrorMap.isEmpty()) {
      return FieldValidationError.of(fieldErrorMap);
    }
    return error;
  }

  private toPublicList(resourceList: SurveyResource.Survey[]): List<Survey.Survey> {
    return List.of(...resourceList.map((r) => this.toPublic(r)));
  }

  private toPublic(r: SurveyResource.Survey): Survey.Survey {
    return {
      id: r.id,
      disabled: r.disabled,
      creationTime: Services.toOffsetDateTime(r.creation_time),
      updateTime: Services.toOffsetDateTime(r.update_time),
      name: r.name,
      externalId: r.external_id,
      description: r.description,
      hasPdfTemplate: r.has_pdf_template,
      hasXlsTemplate: r.has_xls_template,
      hasThumbnail: r.has_thumbnail
    };
  }

  private toPublicCountList(resList: SurveyResource.SurveyCount[]): List<Survey.SurveyCount> {
    return List.of(...resList.map((s) => this.toPublicCount(s)));
  }

  private toPublicCount(r: SurveyResource.SurveyCount): Survey.SurveyCount {
    return {
      surveyId: r.survey_id,
      surveyName: r.survey_name,
      surveyRecordCount: r.survey_record_count
    };
  }

  // </editor-fold>

}

export namespace Survey {

  export interface Service {

    // <editor-fold desc="CRUD">

    query(request: QueryRequest): Observable<QueryResult<Survey>>;

    get(request: GetRequest): Observable<Survey>;

    create(request: CreateRequest): Observable<Survey>;

    update(request: UpdateRequest): Observable<Survey>;

    setDisabled(request: DisableRequest): Observable<Survey>;

    // </editor-fold>

  }

  // <editor-fold desc="CRUD">

  export interface Survey {
    id: number;
    disabled: boolean;
    creationTime: OffsetDateTime;
    updateTime: OffsetDateTime;
    name: string;
    externalId: string;
    description: string;
    hasPdfTemplate: boolean;
    hasXlsTemplate: boolean;
    hasThumbnail: boolean;
  }

  export interface QueryRequest {
    surveyIdSet?: Set<number>;
    disabled?: boolean;
    name?: string;
    queryText?: string;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
    noProgressBar?: boolean;
  }

  export interface GetRequest {
    surveyId: number;
  }

  export interface CreateRequest {
    name: string;
    description?: string;
    externalId?: string;
  }

  export interface UpdateRequest {
    surveyId: number;
    name: string;
    description?: string;
    externalId: string;
  }

  export interface DisableRequest {
    surveyId: number;
    disabled: boolean;
  }

  export interface DeleteAttachmentRequest {
    surveyId: number;
  }

  export interface DownloadAttachmentRequest {
    surveyId: number;
  }

  export interface SurveyCount {
    surveyId: number;
    surveyName: string;
    surveyRecordCount: number;
  }

  export enum OrderField {
    ID,
    DISABLED,
    CREATION_TIME,
    UPDATE_TIME,
    NAME,
    DESCRIPTION,
  }

  export enum ValidatedField {
    NAME,
    EXTERNAL_ID,
  }

  // </editor-fold>

}

// <editor-fold desc="Internal">

class Keys {
  private static readonly ID = 'id';
  private static readonly DISABLED = 'disabled';
  private static readonly CREATION_TIME = 'creation_time';
  private static readonly UPDATE_TIME = 'update_time';
  private static readonly NAME = 'name';
  private static readonly EXTERNAL_ID = 'external_id';
  private static readonly DESCRIPTION = 'description';

  private static readonly orderFieldKeyMap: Map<Survey.OrderField, string> = Map.of(
    Survey.OrderField.ID, Keys.ID,
    Survey.OrderField.DISABLED, Keys.DISABLED,
    Survey.OrderField.CREATION_TIME, Keys.CREATION_TIME,
    Survey.OrderField.UPDATE_TIME, Keys.UPDATE_TIME,
    Survey.OrderField.NAME, Keys.NAME,
    Survey.OrderField.DESCRIPTION, Keys.DESCRIPTION,
  );

  private static readonly keyValidatedFieldMap: Map<string, Survey.ValidatedField> = Map.of(
    Keys.NAME, Survey.ValidatedField.NAME,
    Keys.EXTERNAL_ID, Survey.ValidatedField.EXTERNAL_ID,
  );

  public static toOrderFieldKey(field: Survey.OrderField): string {
    return Keys.orderFieldKeyMap.get(field)!;
  }

  public static toValidatedField(fieldKey: string): Survey.ValidatedField {
    return Keys.keyValidatedFieldMap.get(fieldKey)!;
  }

}

export type SurveyDisabled =
  'ALL' |
  'ACTIVE' |
  'INACTIVE';

// </editor-fold>
