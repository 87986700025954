/* eslint-disable */
import { Injectable } from '@angular/core';
import { EmptyMessage, IdentityMessage } from '../util/messages';
import { StockItemType } from './stock-item.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseHttpService, ResourceHelper } from '../util/http-services';

/* eslint-enable */

export interface StockRecordResourceCreateRequest {
  stockId: number;
  stock_item_id?: number;
  amount?: number;
  intake_unit_net_price?: number;
}

export interface StockRecordResourceCreateMassRequest {
  stockId: number;
  stock_item_ids: number[];
  amount?: number;
  all?: boolean;
  stock_item_type: StockItemType;
}

export interface StockRecordResourceUpdateRequest extends IdentityMessage {
  stockId: number;
  lotId: number;
  amount?: number;
  intake_unit_net_price?: number;
}

@Injectable()
export class StockSubStockRecordService extends BaseHttpService {

  create(request: StockRecordResourceCreateRequest): Observable<IdentityMessage> {
    return this.http.post<IdentityMessage>(this.url + `${request.stockId}/records`, request);
  }

  createMass(request: StockRecordResourceCreateMassRequest): Observable<EmptyMessage> {
    return this.http.post<EmptyMessage>(this.url + `${request.stockId}/records/mass`, request);
  }

  update(request: StockRecordResourceUpdateRequest): Observable<EmptyMessage> {
    return this.http.put(this.url + `${request.stockId}/records/${request.id}/lots/${request.lotId}`, request);
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/stocks/');
  }

}
