/* eslint-disable */
import { Observable, Observer } from 'rxjs';
import { Order, OrderType, Services, } from '../util/services';
import { MemStorage } from '../util/mem-storage';
import { Injectable } from '@angular/core';
import { UiConstants } from '../../util/core-utils';
import { Vehicle } from './vehicle.service';
import { Dates, LocalDate } from '../util/dates';
import { DisabledItem } from '../../util/search-utils';
import { LoggedInUserStorage } from '../util/storages';

/* eslint-enable */

@Injectable()
export class VehicleSearchService {

  constructor() {
  }

  getSearchData(request: VehicleSearch.SearchDataGetRequest): Observable<VehicleSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<VehicleSearch.SearchDataResult>) => {
      try {
        observer.next(storage.read(request));
      } catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  setSearchData(request: VehicleSearch.SearchDataSetRequest): Observable<VehicleSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<VehicleSearch.SearchDataResult>) => {
      try {
        observer.next(storage.save(request));
      } catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  resetSearchData(request: VehicleSearch.SearchDataGetRequest): Observable<VehicleSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<VehicleSearch.SearchDataResult>) => {
      try {
        observer.next(storage.reset(request));
      } catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }


  private createStorage(): VehicleSearch.Storage {
    return new VehicleSearch.Storage();
  }


}

export namespace VehicleSearch {

  export interface SearchDataGetRequest {
  }

  export interface SearchDataSetRequest {
    searchData: SearchData;
  }

  export interface SearchDataResult {
    searchData: SearchData;
  }

  export interface SearchData {
    itemsPerPage: number;
    pageNumber: number;
    order: Order<Vehicle.OrderField>;
    id: string;
    externalId: string;
    model: string;
    registrationType: string;
    licencePlate: string;
    companyIds: CompanyItem[];
    registrationDateFrom: LocalDate;
    registrationDateTo: LocalDate;
    payloadKgFrom: string;
    payloadKgTo: string;
    totalWeightKgFrom: string;
    totalWeightKgTo: string;
    etollRequired?: boolean;
    disabled?: DisabledItem;
  }

  interface StoredSearchRawDataBase {
    version: number;
  }

  interface StoredSearchRawData extends StoredSearchRawDataBase {
    itemsPerPage: number;
    pageNumber: number;
    order: Order<Vehicle.OrderField>;
    id: string;
    externalId: string;
    model: string;
    registrationType: string;
    licencePlate: string;
    companyIds: CompanyItem[];
    registrationDateFrom?: string;
    registrationDateTo?: string;
    payloadKgFrom: string;
    payloadKgTo: string;
    totalWeightKgFrom: string;
    totalWeightKgTo: string;
    etollRequired?: boolean;
    disabled?: DisabledItem;
  }

  export class Storage {

    /**
     * Increment this version if you change the SearchData interface.
     */
    private static readonly VERSION: number = 4;

    private static readonly DEFAULT_SEARCH_DATA: SearchData = {
      itemsPerPage: UiConstants.pageNumbers[0],
      pageNumber: 1,
      order: {
        field: Vehicle.OrderField.ID,
        type: OrderType.DESC
      },
      id: '',
      externalId: '',
      model: '',
      registrationType: '',
      licencePlate: '',
      companyIds: [],
      registrationDateFrom: Dates.emptyLocalDate(),
      registrationDateTo: Dates.emptyLocalDate(),
      payloadKgFrom: '',
      payloadKgTo: '',
      totalWeightKgFrom: '',
      totalWeightKgTo: '',
      etollRequired: false,
      disabled: DisabledItem.active(),
    };

    constructor() {
    }

    public read(request: SearchDataGetRequest): SearchDataResult {
      let rawBase: StoredSearchRawDataBase;
      try {
        const key = this.generateKey();
        const json = MemStorage.getItem(key);
        if (!json) {
          return this.createDefaultResult();
        }
        rawBase = JSON.parse(json);
      } catch (error) {
        return this.createDefaultResult();
      }
      if (Storage.VERSION === rawBase.version) {
        const raw: StoredSearchRawData = <StoredSearchRawData>rawBase;
        return this.fromRaw(raw);
      }
      return this.createDefaultResult();
    }


    public reset(request: SearchDataGetRequest): SearchDataResult {
      return this.save({
        searchData: Storage.DEFAULT_SEARCH_DATA,
      });
    }


    public save(request: SearchDataSetRequest): SearchDataResult {
      const key = this.generateKey();
      const raw = this.toRaw(request.searchData);
      const json = JSON.stringify(raw);
      MemStorage.setItem(key, json);
      return this.read(request);
    }

    private generateKey(): string {
      return 'UserId(' + LoggedInUserStorage.getInstance().getUserId() + ')-Search-Vehicle';
    }

    private createDefaultResult(): SearchDataResult {
      return {
        searchData: Storage.DEFAULT_SEARCH_DATA,
      };
    }


    private fromRaw(data: StoredSearchRawData): SearchDataResult {
      return {
        searchData: {
          itemsPerPage: data.itemsPerPage,
          pageNumber: data.pageNumber,
          order: data.order,
          id: data.id,
          externalId: data.externalId,
          model: data.model,
          registrationType: data.registrationType,
          licencePlate: data.licencePlate,
          companyIds: data.companyIds,
          registrationDateFrom: Services.toLocalDate(data.registrationDateFrom),
          registrationDateTo: Services.toLocalDate(data.registrationDateTo),
          payloadKgFrom: data.payloadKgFrom,
          payloadKgTo: data.payloadKgTo,
          totalWeightKgFrom: data.totalWeightKgFrom,
          totalWeightKgTo: data.totalWeightKgTo,
          etollRequired: data.etollRequired,
          disabled: data.disabled,

        }
      };
    }

    private toRaw(data: SearchData): StoredSearchRawData {
      return {
        version: Storage.VERSION,
        itemsPerPage: data.itemsPerPage,
        pageNumber: data.pageNumber,
        order: data.order,
        id: data.id,
        externalId: data.externalId,
        model: data.model,
        registrationType: data.registrationType,
        licencePlate: data.licencePlate,
        companyIds: data.companyIds,
        registrationDateFrom: Services.localDateToString(data.registrationDateFrom),
        registrationDateTo: Services.localDateToString(data.registrationDateTo),
        payloadKgFrom: data.payloadKgFrom,
        payloadKgTo: data.payloadKgTo,
        totalWeightKgFrom: data.totalWeightKgFrom,
        totalWeightKgTo: data.totalWeightKgTo,
        etollRequired: data.etollRequired,
        disabled: data.disabled,

      };
    }
  }

  export class CompanyItem {
    id: number;
    name: string;

    get itemName() {
      return this.name;
    }
  }
}

