import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { RightModel } from '../../../../app.rights';
import {
  ColonnadeClientDocumentStatSearchComponent,
  ColonnadeClientDocumentStatSearchSearchScope
} from '../colonnade-client-document-stat-search/colonnade-client-document-stat-search.component';
import { RightResolver, RightService } from '../../../../lib/right.service';
import {
  ColonnadeClientDocumentStat,
  ColonnadeClientDocumentStatService
} from '../../../../lib/colonnade-client-document-stat/colonnade-client-document-stat.service';
import { DownloadedFile } from '../../../../lib/util/downloaded-files';
import { saveAs } from 'file-saver';
import { UiConstants } from '../../../../util/core-utils';
import { StringKey } from '../../../../app.string-keys';
import { ToasterService } from '../../../../fork/angular2-toaster/src/toaster.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-colonnade-client-document-stat-enroller-summary',
  templateUrl: './colonnade-client-document-stat-enroller-summary.component.html',
  styleUrls: ['./colonnade-client-document-stat-enroller-summary.component.scss']
})
export class ColonnadeClientDocumentStatEnrollerSummaryComponent implements OnInit {
  ColonnadeClientDocumentStatSearchSearchScope = ColonnadeClientDocumentStatSearchSearchScope;

  @ViewChild('searchComponent', { static: true })
  searchComponent: ColonnadeClientDocumentStatSearchComponent;

  @ViewChild('enrollerCustomerChart') enrollerCustomerChart;
  enrollerCustomerChartData;

  @ViewChild('enrollerInsuranceChart') enrollerInsuranceChart;
  enrollerInsuranceChartData;

  @ViewChild('enrollerSumPriceChart') enrollerSumPriceChart;
  enrollerSumPriceChartData;

  rightModel: RightModel = RightModel.empty();
  currentUserId?: number;

  enrollerSummaries: ColonnadeClientDocumentStat.ColonnadeB2BByEnrollerValue[] = [];

  constructor(private rightService: RightService,
              private toasterService: ToasterService,
              private translateService: TranslateService,
              private colonnadeClientDocumentStatService: ColonnadeClientDocumentStatService) { }

  ngOnInit() {
    this.loadRightModel();
  }

  private loadRightModel() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        this.currentUserId = resolver.userProfile!.id;
      }
    );
  }

  downloadXlsx() {
    if (!this.searchComponent.fForm.submitted || !this.searchComponent.validate()) {
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutLong,
        type: UiConstants.toastTypeError,
        title: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_TITLE),
        body: this.translateService.instant(StringKey.COLONNADE_CLIENT_DOCUMENT_STAT_XLSX_EXPORT_NO_RESULT)
      });
      return;
    }
    const request = this.searchComponent.getSearchRequest()
    this.colonnadeClientDocumentStatService.getEnrollerSummaryXlsx(request).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName('enroller_summary.xlsx'));
      }
    );
  }

  loadList() {
    this.colonnadeClientDocumentStatService.getEnrollerSummary(this.searchComponent.getSearchRequest())
      .subscribe((result) => {
        this.enrollerSummaries = result;
        this.updateChartData();
      });
  }

  private updateChartData() {
    // enrollerCustomerChart
    const enrollerCustomerChartDataTable: any[] = [];
    const enrollerCustomerFirstRow: any[] = [];
    enrollerCustomerFirstRow.push('EnrollerName');
    enrollerCustomerFirstRow.push(this.translateService.instant('COLONNADE_CLIENT_DOCUMENT_STAT_SEEN_COUNT'));
    enrollerCustomerFirstRow.push(this.translateService.instant('COLONNADE_CLIENT_DOCUMENT_STAT_PRESENTED_COUNT'));
    enrollerCustomerFirstRow.push(this.translateService.instant('COLONNADE_CLIENT_DOCUMENT_STAT_CUSTOMER_COUNT'));
    enrollerCustomerChartDataTable.push(enrollerCustomerFirstRow);
    for (const enrollerSummary of this.enrollerSummaries) {
      const row: any[] = [];
      row.push(enrollerSummary.user.personName)
      row.push(enrollerSummary.seenCount)
      row.push(enrollerSummary.presentedCount)
      row.push(enrollerSummary.insuranceCount)
      enrollerCustomerChartDataTable.push(row);
    }
    this.enrollerCustomerChartData  = {
      chartType: 'ColumnChart',
      dataTable: enrollerCustomerChartDataTable,
      options: {
        'isStacked': true,
        'height': 500,
        'legend': {
          position: 'top',
          alignment: 'center',
        },
      }
    }

    // enrollerInsuranceChart
    const enrollerInsuranceChartDataTable: any[] = [];
    const enrollerInsuranceFirstRow: any[] = [];
    enrollerInsuranceFirstRow.push('Enroller');
    enrollerInsuranceFirstRow.push('InsuranceCount');
    enrollerInsuranceChartDataTable.push(enrollerInsuranceFirstRow);
    for (const enrollerSummary of this.enrollerSummaries) {
      const row: any[] = [];
      row.push(enrollerSummary.user.personName)
      row.push(enrollerSummary.insuranceCount)
      enrollerInsuranceChartDataTable.push(row);
    }
    this.enrollerInsuranceChartData  = {
      chartType: 'PieChart',
      dataTable: enrollerInsuranceChartDataTable,
      options: {
        'height': 500,
        'pieSliceText': 'value',
      }
    }


    // enrollerSumPriceChart
    const enrollerSumPriceChartDataTable: any[] = [];
    const enrollerSumPriceFirstRow: any[] = [];
    enrollerSumPriceFirstRow.push('EnrollerName');
    enrollerSumPriceFirstRow.push(this.translateService.instant('COLONNADE_CLIENT_DOCUMENT_STAT_SUM_PRICE'));
    enrollerSumPriceChartDataTable.push(enrollerSumPriceFirstRow);
    for (const enrollerSummary of this.enrollerSummaries) {
      const row: any[] = [];
      row.push(enrollerSummary.user.personName)
      row.push(enrollerSummary.sumPrice.toNumber())
      enrollerSumPriceChartDataTable.push(row);
    }
    this.enrollerSumPriceChartData  = {
      chartType: 'ColumnChart',
      dataTable: enrollerSumPriceChartDataTable,
      options: {
        'height': 500,
      }
    }
  }
}
