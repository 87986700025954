/* eslint-disable */
import { Decimal } from 'decimal.js';
import { EmailAddress, EmailAddresses, } from '../lib/util/email-address';
import { PhoneNumber, PhoneNumbers } from '../lib/util/phone-number';
import { NgbDateStruct, } from '@ng-bootstrap/ng-bootstrap';
import { Dates, LocalDate, OffsetDateTime, } from '../lib/util/dates';
import { InputMask } from './input-masks';
import { Query } from '../lib/query/field';
import { List } from 'immutable';
import { AppNgbTimeStruct } from './ngb-datepicker';

/* eslint-enable */

/**
 * Mapper methods from UI model to DOM model and vice-versa.
 */
export class Models {

  // <editor-fold desc="Date/time picker">

  // NOTE: Inject and use NgbDatePickerParserFormatter to parse/format date and date-time.

  public static zeroNgbTime(): AppNgbTimeStruct {
    return {
      hour: 0,
      minute: 0,
      second: 0,
      millis: 0
    };
  }

  public static mergeNgbDateAndTime(
    date: NgbDateStruct | null,
    time: AppNgbTimeStruct | null):
    (NgbDateStruct & AppNgbTimeStruct) | null {
    if (!date) {
      return null;
    }
    return {
      year: date.year,
      month: date.month,
      day: date.day,
      hour: time ? time.hour : 0,
      minute: time ? time.minute : 0,
      second: time ? time.second : 0,
      millis: time ? time.millis : 0
    };
  }

  public static optToNgbTime(value: AppNgbTimeStruct | null | undefined): AppNgbTimeStruct {
    return value ? value : Models.zeroNgbTime();
  }

  public static minutesToNgbTime(minutes: number): AppNgbTimeStruct {
    if (minutes >= 1440) {
      return Models.zeroNgbTime();
    }
    return {
      hour: Math.floor(minutes / 60),
      minute: minutes % 60,
      second: 0,
      millis: 0
    }
  }

  public static ngbTimeToMinutes(time: AppNgbTimeStruct): number {
    const mins = time.hour * 60 + time.minute;
    if (mins >= 1440) {
      return 1439;
    }
    return mins; // + Math.round(time.second / 60 + time.millis / 60000)
  }

  // </editor-fold>

  public static optToString(text?: string): string {
    return text ? text : '';
  }

  public static optToBoolean(bool?: boolean): boolean {
    return bool ? bool : false;
  }

  public static optNumber(value: number | string | undefined | null): number | undefined {
    if (value === null || value === undefined) {
      return undefined;
    }
    if (typeof value === 'string') {
      // Wrong type definition in the UI model.
      // Try to fix it.
      if (Models.isEmptyText(value)) {
        return undefined;
      }
      return Number(value);
    }
    return value;
  }

  public static parseNumber(text: string | undefined | null): number | undefined {
    // Default arguments:
    const thousandSeparatorSymbol = InputMask.THOUSAND_SEPARATOR_SYMBOL;
    const decimalSymbol = InputMask.DECIMAL_SYMBOL;
    //
    if (Models.isEmptyText(text)) {
      return undefined;
    }
    const t = Models.toProgrammaticDecimalString(text!, thousandSeparatorSymbol, decimalSymbol);
    return Number(t);
  }

  public static numberToString(number?: number): string {
    // TODO: use InputMask.DECIMAL_SYMBOL and support thousand separator somehow.
    return number !== undefined ? number + '' : '';
  }

  public static numberToDecimal(number?: number): Decimal | undefined {
    if (number !== undefined) {
      return new Decimal(number);
    }
    return undefined;
  }

  public static parseDecimal(text: string | undefined | null): Decimal | undefined {
    // Default arguments:
    const thousandSeparatorSymbol = InputMask.THOUSAND_SEPARATOR_SYMBOL;
    const decimalSymbol = InputMask.DECIMAL_SYMBOL;
    //
    if (Models.isEmptyText(text)) {
      return undefined;
    }
    const t = Models.toProgrammaticDecimalString(text!, thousandSeparatorSymbol, decimalSymbol);
    return new Decimal(t);
  }

  public static decimalToString(number?: Decimal): string {
    // TODO: use InputMask.DECIMAL_SYMBOL and support thousand separator somehow.
    return number !== undefined ? number.toString() : '';
  }

  public static decimalToFormattedString(number?: Decimal | number | string,
                                         thousandSeparator?: boolean,
                                         numberOfDigits?: number): string {
    if (number === undefined) {
      return '';
    }
    let num: Decimal | number | string = number;
    if (numberOfDigits !== undefined) {
      num = typeof number !== 'number' ? this.parseNumber(number.toString())! : number!;
      num = (Math.round(num! * Math.pow(10, numberOfDigits)) / Math.pow(10, numberOfDigits));
    }
    thousandSeparator = thousandSeparator ? thousandSeparator : true;
    return thousandSeparator
      ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      : num.toString();
  }

  public static decimalToNumber(number?: Decimal): number | undefined {
    return number !== undefined ? number.toNumber() : undefined;
  }

  public static parseEmailAddress(text?: string): EmailAddress {
    return EmailAddresses.parse(text);
  }

  public static emailAddressToString(email: EmailAddress): string {
    return email.format();
  }

  public static parsePhoneNumber(text?: string): PhoneNumber {
    return PhoneNumbers.parse(text);
  }

  public static phoneNumberToString(phoneNumber: PhoneNumber): string {
    return phoneNumber.format();
  }

  public static ngbDateToLocalDate(model: NgbDateStruct | null | undefined): LocalDate {
    if (!model) {
      return Dates.emptyLocalDate();
    }
    return Dates.createLocalDate({
      year: model.year,
      month: model.month,
      day: model.day,
    });
  }

  public static localDateToNgbDate(date?: LocalDate): NgbDateStruct | null {
    if (!date || !date.isValid()) {
      return null;
    }
    return {
      year: date.getYear(),
      month: date.getMonth(),
      day: date.getDay()
    };
  }

  public static offsetDateTimeToNgbDate(date: OffsetDateTime): NgbDateStruct | null {
    if (!date.isValid()) {
      return null;
    }
    return {
      year: date.getYear(),
      month: date.getMonth(),
      day: date.getDay()
    };
  }

  public static parseDateTimeFrom(model: NgbDateStruct | null): OffsetDateTime {
    const day = Models.ngbDateToLocalDate(model);
    const time = day.startOfDay();
    return time;
  }

  public static parseDateTimeTo(model: NgbDateStruct | null): OffsetDateTime {
    const day = Models.ngbDateToLocalDate(model);
    const time = day.startOfDay().endOfDay();
    return time;
  }

  private static toProgrammaticDecimalString(
    localizedNumberText: string,
    thousandSeparatorSymbol: string,
    decimalSymbol: string): string {
    const thousandSeparatorSymbolReplaceRegExp = new RegExp(Models.escapeRegExp(thousandSeparatorSymbol), 'g');
    const decimalSymbolReplaceRegExp = new RegExp(Models.escapeRegExp(decimalSymbol), 'g');
    let t = localizedNumberText.replace(thousandSeparatorSymbolReplaceRegExp, '');
    t = t.replace(decimalSymbolReplaceRegExp, '.');
    return t;
  }

  private static escapeRegExp(str) {
    return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
  }

  private static isEmptyText(text: string | undefined | null): boolean {
    return text === undefined || text === null || text.length === 0 || text === '';
  }

  public static applyThousandSeparatorToDecimal(rawNumber: Decimal): string {
    return this.decimalToFormattedString(rawNumber, true);
  }

  public static normalizeText(rawText: string): string {
    return rawText
      .toLowerCase()
      .normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      .replace(/[^a-zA-Z0-9 \d_]/g, '')
      .replace(/ +/g, ' ')
      .replace(/ /g, '_');
  }

  private constructor() {
  }

}

export class CriteriaBuilder {

  private readonly filters: Query.Criteria[] = [];

  public static builder(): CriteriaBuilder {
    return new CriteriaBuilder();
  }

  private constructor() {
  }

  build(): Query.Criteria | undefined {
    return Query.Criterias.allOf(List.of(...this.filters));
  }

  addString(fn: (value: string) => Query.Criteria, value?: string) {
    if (value) { // NOT(null or undefined or empty)
      this.filters.push(fn(value));
    }
    return this;
  }

  addNumber(fn: (value: number) => Query.Criteria, value?: number) {
    if (value !== undefined && value !== null) {
      this.filters.push(fn(value));
    }
    return this;
  }

  addNumberList(fn: (value: List<number>) => Query.Criteria, value?: List<number>) {
    if (value !== undefined && value !== null && !value.isEmpty()) {
      this.filters.push(fn(value));
    }
    return this;
  }

  addBoolean(fn: (value: boolean) => Query.Criteria, value?: boolean) {
    if (value !== undefined && value !== null) {
      this.filters.push(fn(value));
    }
    return this;
  }

  addEnum<T>(fn: (value: T) => Query.Criteria, value: T | null) {
    if (value !== undefined && value !== null) {
      this.filters.push(fn(value));
    }
    return this;
  }

  addDateTime(fn: (value: OffsetDateTime) => Query.Criteria, value?: OffsetDateTime) {
    if (value !== undefined && value !== null && value.isValid()) {
      this.filters.push(fn(value));
    }
    return this;
  }

}
