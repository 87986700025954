import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nonEmpty'
})
export class NonEmptyPipe implements PipeTransform {

  transform(val: any): boolean {
    if (typeof val === 'string' || val instanceof String) {
      return val.trim().length > 0;
    }
    else {
      return false;
    }
  }

}
