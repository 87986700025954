/* eslint-disable */
import { Component, } from '@angular/core';
import {
  FormRecordBooleanFieldView,
  FormRecordFieldContext,
  FormRecordFieldValueReference,
  FormRecordFieldValueUpdateArgs,
  FormRecordFieldViewContext,
  FormRecordFieldViewModel,
  SetTmpReadonlyArgs,
  SetTmpReadonlyResult,
} from '../../../../../util/form/form-utils';
import { Form } from '../../../../../lib/form/form.service';
import { FormRef, } from '../../../../../lib/util/services';
import { FormRecord } from '../../../../../lib/form/form-record.service';
import { FieldActivationState, FieldActivationStateResolver, } from '../../../../../util/form/form-editors';
import { Command, } from '../../../../../util/command';
import { Models } from '../../../../../util/model-utils';
import { FormRecordInactivityManager } from '../../manager/form-record-inactivity-manager';
import { LoggerFactory } from '../../../../../util/logger-factory';
import { Observable } from 'rxjs';

/* eslint-enable */

@Component({
  selector: 'app-form-record-boolean-field',
  templateUrl: 'form-record-boolean-field.component.html',
  styleUrls: ['form-record-boolean-field.component.scss'],
})
export class FormRecordBooleanFieldComponent implements FormRecordBooleanFieldView {

  private readonly logger = LoggerFactory.createLogger('FormRecordBooleanFieldComponent');

  public readonly selector: Form.FieldDataTypeSelector.BOOLEAN;

  model: Model = new Model(
    () => this.tmpReadonly,
    () => this.willBeAbleToApplyDefaultValue,
    () => this.reqDefaultValue
  );

  valueReference: ValueReference;

  private changedByUser = false;

  // context fields are always optional
  formRecordFieldContext?: FormRecordFieldContext;
  formRecordInactivityManager?: FormRecordInactivityManager;
  private fieldId?: number;
  private htmlForm?: FormRef;

  tmpReadonly: boolean = false;

  private readonlyFormFn: () => boolean = () => true;
  private readonlyFieldFn: () => boolean = () => false;
  private hiddenFieldFn: () => boolean = () => false;

  get nonEditable(): boolean {
    return FieldActivationStateResolver.isNonEditable(
      this.fieldActivationState
    );
  }

  get requiredDisabled(): boolean {
    return FieldActivationStateResolver.isRequiredDisabled(
      this.fieldActivationState
    );
  }

  private get readonlyForm(): boolean {
    return this.readonlyFormFn();
  }

  private get fieldActivationState(): FieldActivationState {
    return FieldActivationStateResolver.resolveFieldActivationState({
      readonlyFormFn: () => this.readonlyFormFn(),
      readonlyFieldFn: () => this.readonlyFieldFn(),
      tmpReadonlyFieldFn: () => this.tmpReadonly,
    });
  }

  private get canApplyDefaultValue(): boolean {
    return FieldActivationStateResolver.canApplyDefaultValue({
      formRecordFieldContext: () => {
        return this.formRecordFieldContext;
      },
      fieldActivationState: () => {
        return this.fieldActivationState;
      },
    });
  }

  private get willBeAbleToApplyDefaultValue(): boolean {
    return FieldActivationStateResolver.willBeAbleToApplyDefaultValue({
      formRecordFieldContext: () => {
        return this.formRecordFieldContext;
      },
      fieldActivationState: () => {
        return this.fieldActivationState;
      },
    });
  }

  private get reqContext() {
    return this.formRecordFieldContext!;
  }

  private get reqAttrs(): Form.FieldDataTypeBooleanAttributes {
    return this.reqContext.field.dataType.booleanAttributes!;
  }

  private get reqDefaultValue(): boolean {
    return Models.optToBoolean(this.reqAttrs.defaultValue);
  }

  constructor() {
    this.valueReference = new ValueReference(this.model);
  }

  setTmpReadonly(args: SetTmpReadonlyArgs): Command<SetTmpReadonlyResult> {
    const previousTmpReadonly = this.tmpReadonly;
    const previousValue = this.model.value;
    return {
      execute: async () => {
        const inactiveValue = false;
        const defaultValue = this.reqDefaultValue;
        let changed = false;
        if (this.tmpReadonly !== args.tmpReadonly) {
          if (args.tmpReadonly) {
            changed = FieldActivationStateResolver.inactivationChangesTheValue({
              debugId: this.reqContext.field.title,
              valueIsEmpty: this.model.value === inactiveValue,
              valueEqualsDefaultValue: this.model.value === defaultValue,
              defaultValueIsEmpty: defaultValue === inactiveValue,
              canApplyDefaultValue: this.canApplyDefaultValue
            });
            this.model.value = inactiveValue;
            this.tmpReadonly = args.tmpReadonly; // last
          }
          else {
            this.tmpReadonly = args.tmpReadonly; // first
            this.applyDefaultValue();
          }
        }
        return {
          changed: changed
        };
      },
      undo: async () => {
        this.tmpReadonly = previousTmpReadonly;
        this.model.value = previousValue;
      }
    };
  }

  registerField(context: FormRecordFieldContext, originalModel?: any): any {
    const readonlyMode = context.readonly();
    this.logger.debug('registerField', 'readonlyMode: ' + readonlyMode);
    if (originalModel) {
      this.model = originalModel;
      this.valueReference = new ValueReference(this.model);
    }
    this.formRecordFieldContext = context;
    this.fieldId = context.field.fieldId;
    this.htmlForm = context.htmlForm;
    this.readonlyFormFn = context.readonly;
    this.hiddenFieldFn = () => Form.FormFieldValidationType.HIDDEN === context.validationType;
    this.readonlyFieldFn = () => Form.FormFieldValidationType.READONLY === context.validationType
      || Form.FormFieldValidationType.HIDDEN === context.validationType;
    const attrs = context.field.dataType.booleanAttributes!;
    this.model.title = context.field.title;
    this.model.hint = Models.optToString(context.field.hint);
    this.applyDefaultValue();
    if (context.fieldRecord) {
      this.registerFieldData(context.fieldRecord);
    }
    return this.model;
  }

  private applyDefaultValue() {
    if (this.canApplyDefaultValue) {
      this.model.value = this.reqDefaultValue;
    }
  }

  private registerFieldData(fieldRecord: FormRecord.FieldComposed): void {
    const attrs = fieldRecord.data.booleanAttributes!;
    if (fieldRecord) {
      if (attrs.value) {
        if (this.readonlyFieldFn() && this.reqContext.cloning()) {
          // Do not use read-only data for cloning.
          // Boolean by default false to respect activation logic.
          // Note that readonly field is filtered from the service request.
          this.model.value = false;
        }
        else {
          this.model.value = attrs.value;
        }
      }
      else {
        this.model.value = false;
      }
    }
  }

  registerFieldViews(context: FormRecordFieldViewContext): void {
    this.formRecordInactivityManager = context.inactivityManager;
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return false;
  }

  validateWithInterrupt(): boolean {
    return false;
  }

  shouldNotifyAfterCreation(): boolean {
    return false;
  }

  afterFormRecordCreation(formRecordId: number): Observable<any> | undefined {
    return undefined;
  }

  createModel(): FormRecordFieldViewModel {
    if (this.fieldId === undefined) {
      throw new Error('Field ID is undefined');
    }
    const attrs: FormRecord.FieldDataBooleanAttributes = {
      value: this.model.value
    };
    return {
      fieldEditRequest: {
        fieldId: this.fieldId,
        data: {
          booleanAttributes: attrs
        }
      }
    };
  }

  onFieldClick(): void {
    this.changedByUser = true;
  }

  onFieldChanged(): void {
    if (this.changedByUser) {
      this.changedByUser = false;
      this.formRecordInactivityManager!.onBooleanFieldChangedByUser(this);
    }
  }

  updateValue(data: FormRecordFieldValueUpdateArgs) {
  }

}

export class Model {

  title: string = '';
  hint: string = '';

  _value: boolean = false;
  _previousValue: boolean = false;

  get previousValue() {
    return this._previousValue;
  }

  get value() {
    return this._value;
  }

  set value(value: boolean) {
    this._previousValue = this._value;
    this._value = value;
  }

  constructor(
    public readonly tmpReadonly: () => boolean,
    public readonly willBeAbleToApplyDefaultValue: () => boolean,
    public readonly defaultValue: () => boolean) {
  }

}

class ValueReference implements FormRecordFieldValueReference<boolean> {

  constructor(private readonly model: Model) {
  }

  async get(): Promise<boolean> {
    if (this.model.tmpReadonly() && this.model.willBeAbleToApplyDefaultValue()) {
      return this.model.defaultValue();
    }
    return this.model.value;
  }

  set(value: boolean) {
    this.model.value = value;
  }

  previous(): boolean {
    return this.model.previousValue;
  }

}
