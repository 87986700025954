import { DqlQuery, Query } from '../../query/field';
import { Observable, Observer } from 'rxjs';
import { PagingRequest, QueryResult, ResourceQueryResult, Services } from '../../util/services';
import { Injectable } from '@angular/core';
import { FilterField } from '../../query/filterfields';
import { OrderField } from '../../query/orderfields';
import { List, Set } from 'immutable';
import { EmptyMessage, IdentityMessage } from '../../util/messages';
import { OffsetDateTime } from '../../util/dates';
import { TaskRecordSubTaskResource, TaskRecordSubTaskResourceService } from './task-record-sub-task-resource.service';
import { TaskRecordStateMachine } from '../task-record-statemachine';

export namespace TaskRecordSubTask {

  export interface Service {
    query(request: QueryRequest): Observable<QueryResult<SubTask>>;

    get(request: GetRequest): Observable<TaskRecordSubTask.SubTask>;

    create(request: CreateRequest): Observable<IdentityMessage>;

    update(request: UpdateRequest): Observable<EmptyMessage>;

    delete(request: DeleteRequest): Observable<EmptyMessage>;
  }

  export interface SubTask {
    id: number;
    version: number;
    grantedRights: Set<string>;
    creationTime: OffsetDateTime;
    state: SubTaskState;
    workerUser?: WorkerUser;
    name: string;
    type?: string;
    note?: string;
    startTime: OffsetDateTime;
    endTime: OffsetDateTime;
  }

  export interface WorkerUser {
    id: number;
    personName: string;
    userName: string;
  }

  export interface QueryRequest extends SubTaskBaseRequest {
    fields?: Query.FieldFunction<Fields.SubTask>;
    filter?: Query.FilterFunction<FilterField.TaskRecordSubTask>;
    order?: Query.OrderFunction<OrderField.TaskRecordSubTask>;
    paging?: PagingRequest;
    rights?: Set<string>;
  }

  export interface GetRequest extends IdentityMessage, SubTaskBaseRequest {
  }

  export interface CreateRequest extends SubTaskBaseRequest {
    name: string;
    type?: string;
    note?: string;
    workerUserId?: number;
  }

  export interface UpdateRequest extends IdentityMessage, SubTaskBaseRequest {
    version: number;
    name: string;
    type?: string;
    note?: string;
    workerUserId?: number;
  }

  export interface DeleteRequest extends IdentityMessage, SubTaskBaseRequest {
  }

  export interface SubTaskBaseRequest {
    taskId: number;
    taskRecordId: number;
  }

  export enum SubTaskState {
    OPEN = 'OPEN',
    IN_PROGRESS = 'IN_PROGRESS',
    FINISHED = 'FINISHED',
    UNFINISHED = 'UNFINISHED',
  }

  export interface SubTaskStateObject {
    state: SubTaskState;
    stringKey: string;
    iconClass: string;
  }

  export const subTaskStates: SubTaskStateObject[] = [
    {state: SubTaskState.OPEN, stringKey: 'TRANSPORT_STATE_OPEN', iconClass: 'icomoon-new-state-new'},
    {
      state: SubTaskState.IN_PROGRESS,
      stringKey: 'PROCESS_STATE_IN_PROGRESS',
      iconClass: 'icomoon-new-state-in-progress'
    },
    {
      state: SubTaskState.FINISHED,
      stringKey: 'PROCESS_TASK_STATE_FINISHED',
      iconClass: 'icomoon-state-action-validate'
    },
    {
      state: SubTaskState.UNFINISHED,
      stringKey: 'COMMON_VALUE_TASK_STATE_UNFINISHED',
      iconClass: 'icomoon-state-action-invalidate'
    },
  ];

  export const ValidTaskRecordStatesForCreate: Set<TaskRecordStateMachine.State> = Set.of(
    'NEW' as TaskRecordStateMachine.State,
    'OPEN' as TaskRecordStateMachine.State,
    'IN_PROGRESS' as TaskRecordStateMachine.State
  );

  export const ValidSubTaskStatesForDelete: Set<TaskRecordSubTask.SubTaskState> = Set.of(
    SubTaskState.OPEN
  );

  export const ValidTaskRecordStatesForDelete: Set<TaskRecordStateMachine.State> = Set.of(
    'NEW' as TaskRecordStateMachine.State,
    'OPEN' as TaskRecordStateMachine.State,
    'IN_PROGRESS' as TaskRecordStateMachine.State
  );

  export const ValidSubTaskStatesForWorkerChange: Set<TaskRecordSubTask.SubTaskState> = Set.of(
    SubTaskState.OPEN,
    SubTaskState.IN_PROGRESS
  );

  export function toPublicSubTask(r: TaskRecordSubTaskResource.SubTask): TaskRecordSubTask.SubTask {
    return {
      id: r.id,
      version: r.version,
      grantedRights: r.granted_rights ? Set.of(...r.granted_rights) : Set.of(),
      creationTime: Services.toOffsetDateTime(r.creation_time),
      state: SubTaskState[r.state],
      workerUser: r.worker_user ? {
        id: r.worker_user.id,
        personName: r.worker_user.person_name,
        userName: r.worker_user.user_name
      } : undefined,
      name: r.name,
      type: r.type,
      note: r.note,
      startTime: Services.toOffsetDateTime(r.start_time),
      endTime: Services.toOffsetDateTime(r.end_time),
    }
  }

  export function toResourceCreateRequest(request: TaskRecordSubTask.CreateRequest): TaskRecordSubTaskResource.CreateRequest {
    return {
      task_id: request.taskId,
      task_record_id: request.taskRecordId,
      name: request.name,
      note: request.note,
      type: request.type,
      worker_user_id: request.workerUserId
    }
  }

  export function toResourceUpdateRequest(request: TaskRecordSubTask.UpdateRequest): TaskRecordSubTaskResource.UpdateRequest {
    return {
      task_id: request.taskId,
      task_record_id: request.taskRecordId,
      id: request.id,
      name: request.name,
      note: request.note,
      type: request.type,
      version: request.version,
      worker_user_id: request.workerUserId
    }
  }

  export function toResourceDeleteRequest(request: TaskRecordSubTask.DeleteRequest): TaskRecordSubTaskResource.DeleteRequest {
    return {
      task_id: request.taskId,
      task_record_id: request.taskRecordId,
      id: request.id
    }
  }

  export namespace Fields {

    // Each class should be an interface, but it is easier to define the constant fields in the constructors.
    // For now we have only one query language so it is not a problem.
    // Always use the public Query namespace for variable definitions so we will be able to introduce other language easily.

    export class SubTask {

      readonly id: Query.Field = new DqlQuery.Field('id');
      readonly name: Query.Field = new DqlQuery.Field('name');
      readonly creationTime: Query.Field = new DqlQuery.Field('creation_time');
      readonly state: Query.Field = new DqlQuery.Field('state');
      readonly workerUser: Query.Field = new DqlQuery.Field('worker_user');
      readonly type: Query.Field = new DqlQuery.Field('type');
      readonly note: Query.Field = new DqlQuery.Field('note');
      readonly startTime: Query.Field = new DqlQuery.Field('start_time');
      readonly endTime: Query.Field = new DqlQuery.Field('end_time');

      get each(): Set<Query.Field> {
        return Set.of(
          this.id, this.name, this.creationTime,
          this.state, this.workerUser, this.type,
          this.note, this.startTime, this.endTime
        );
      }

    }

  }

}

function sample(service: TaskRecordSubTask.Service) {
  service.query({
    taskId: 1,
    taskRecordId: 1,
    filter: f => f.id.eq(1)
      .or(f.taskRecord.name.startsWith('Test'))
      .and(f.taskRecord.formRecord.fieldRecordByApiName('something')
        .castToField(DqlQuery.StringField).containsIgnoreCase('some string'))
      .and(f.taskRecord.formRecord.fieldRecordById(1)
        .castToObject(FilterField.ListItem).code.isNotNull()),
    order: f => List.of(f.id.desc().nullsFirst()),
    fields: f => Set.of(f.id, f.name),
  });
}

@Injectable()
export class TaskRecordSubTaskService implements TaskRecordSubTask.Service {

  private readonly filterField = new FilterField.TaskRecordSubTask();
  private readonly orderField = new OrderField.TaskRecordSubTask();
  private readonly fields = new TaskRecordSubTask.Fields.SubTask();

  constructor(private resourceService: TaskRecordSubTaskResourceService) {
  }

  query(request: TaskRecordSubTask.QueryRequest): Observable<QueryResult<TaskRecordSubTask.SubTask>> {
    const filter: string | undefined = DqlQuery.toOptionalFilter(this.filterField, request.filter);
    const order: string | undefined = DqlQuery.toOptionalOrder(this.orderField, request.order);
    const fields: string | undefined = DqlQuery.toOptionalFields(this.fields, request.fields);
    return Observable.create((observer: Observer<QueryResult<TaskRecordSubTask.SubTask>>) => {
      const resourceRequest: TaskRecordSubTaskResource.QueryRequest = {
        task_id: request.taskId,
        task_record_id: request.taskRecordId,
        filter: filter,
        order: order,
        fields: fields,
        rights: DqlQuery.RightRequestSerializer.getInstance().serialize(request.rights),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined
      };
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<TaskRecordSubTaskResource.SubTask>) => {
          observer.next({
            items: List.of(...result.items.map((item) => TaskRecordSubTask.toPublicSubTask(item))),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  get(request: TaskRecordSubTask.GetRequest): Observable<TaskRecordSubTask.SubTask> {
    return Observable.create((observer: Observer<TaskRecordSubTask.SubTask>) => {
      const resourceRequest: TaskRecordSubTaskResource.GetRequest = {
        id: request.id,
        task_id: request.taskId,
        task_record_id: request.taskRecordId
      };
      return this.resourceService.get(resourceRequest).subscribe(
        (result: TaskRecordSubTaskResource.SubTask) => {
          observer.next(TaskRecordSubTask.toPublicSubTask(result));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  create(request: TaskRecordSubTask.CreateRequest): Observable<IdentityMessage> {
    return Observable.create((observer: Observer<IdentityMessage>) => {
      const resourceRequest: TaskRecordSubTaskResource.CreateRequest = TaskRecordSubTask.toResourceCreateRequest(request);
      return this.resourceService.create(resourceRequest).subscribe(
        (result: IdentityMessage) => {
          observer.next(result);
        },
        (error: any) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  update(request: TaskRecordSubTask.UpdateRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: TaskRecordSubTaskResource.UpdateRequest = TaskRecordSubTask.toResourceUpdateRequest(request);
      return this.resourceService.update(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  delete(request: TaskRecordSubTask.DeleteRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: TaskRecordSubTaskResource.DeleteRequest = TaskRecordSubTask.toResourceDeleteRequest(request);
      return this.resourceService.delete(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

}
