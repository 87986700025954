/* eslint-disable */
import { Injectable, InjectionToken } from '@angular/core';
import { List, Map, Set } from 'immutable';
import { MasterDataResource, MasterDataResourceService } from './master-data-resource.service';
import { Observable, Observer } from 'rxjs';
import {
  FieldValidationError,
  Order,
  PagingRequest,
  QueryResult,
  ResourceQueryResult,
  Services
} from '../util/services';
import { ObservableErrorResourceParser } from '../util/errors';
import { OffsetDateTime } from '../util/dates';
import { Form } from '../form/form.service';
import { FormResource } from '../form/form-resource.service';
import { EmptyMessage } from '../util/messages';
import { Customer } from '../customer/customer.service';
import { DashboardCount } from '../util/dashboard-count.service';
import { DqlStoredQuery, DqlStoredQueryService } from '../dql/dql-stored-query.service';
import { DqlModel } from '../dql/dql.model';
import { DqlResourceModel } from '../dql/dql-resource.model';
import { Task } from '../task/task.service';

/* eslint-enable */

@Injectable()
export class MasterDataService implements MasterData.Service, Form.Service, DqlStoredQuery.HolderService, DashboardCount.Service {

  private formMapper: Form.ResourceMapper;

  private readonly _dqlStoredQueryService: DqlStoredQueryService;

  constructor(private resourceService: MasterDataResourceService) {
    this.formMapper = new Form.ResourceMapper();
    this._dqlStoredQueryService = new DqlStoredQueryService(resourceService.dqlStoredResourceService);
  }

  get dqlStoredQueryService(): DqlStoredQueryService {
    return this._dqlStoredQueryService;
  }

  // <editor-fold desc="CRUD">

  query(request: MasterData.QueryRequest): Observable<QueryResult<MasterData.MasterData>> {
    return Observable.create((observer: Observer<QueryResult<MasterData.MasterData>>) => {
      const resourceRequest: MasterDataResource.QueryRequest = {
        id: Services.createIdParameter(request.masterDataIdSet),
        disabled: request.disabled,
        name: request.name,
        external_id: request.externalId,
        q: request.queryText,
        order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
        fields: request.fields && request.fields.size > 0 ? request.fields.join(',') : undefined,
        no_progress_bar: request.noProgressBar
      };
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<MasterDataResource.MasterData>) => {
          observer.next({
            items: this.toPublicList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  get(request: MasterData.GetRequest): Observable<MasterData.MasterData> {
    return Observable.create((observer: Observer<MasterData.MasterData>) => {
      const resourceRequest: MasterDataResource.GetRequest = {
        master_data_id: request.masterDataId,
        with_form: request.withForm,
        fields: Services.createListParameter(request.fields)
      };
      return this.resourceService.get(resourceRequest).subscribe(
        (result: MasterDataResource.MasterData) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  create(request: MasterData.CreateRequest): Observable<MasterData.MasterData> {
    return Observable.create((observer: Observer<MasterData.MasterData>) => {
      const resourceRequest: MasterDataResource.CreateRequest = {
        name: request.name,
        external_id: request.externalId,
        description: request.description,
        client_required_id_field: request.requiredMasterDataId,
        mobile_owner_type: request.mobileOwnerType,
        owner_user_group_ids: request.ownerUserGroupIds,
      };
      return this.resourceService.create(resourceRequest).subscribe(
        (result: MasterDataResource.MasterData) => {
          observer.next(this.toPublic(result));
        },
        (error: any) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  update(request: MasterData.UpdateRequest): Observable<MasterData.MasterData> {
    return Observable.create((observer: Observer<MasterData.MasterData>) => {
      const resourceRequest: MasterDataResource.UpdateRequest = {
        master_data_id: request.masterDataId,
        name: request.name,
        external_id: request.externalId,
        description: request.description,
        client_required_id_field: request.requiredMasterDataId,
        mobile_owner_type: request.mobileOwnerType,
        owner_user_group_ids: request.ownerUserGroupIds,
      };
      return this.resourceService.update(resourceRequest).subscribe(
        (result: MasterDataResource.MasterData) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  setDisabled(request: MasterData.DisableRequest): Observable<MasterData.MasterData> {
    return Observable.create((observer: Observer<MasterData.MasterData>) => {
      const resourceRequest: MasterDataResource.DisableRequest = {
        master_data_id: request.masterDataId,
        disabled: request.disabled
      };
      return this.resourceService.setDisabled(resourceRequest).subscribe(
        (result: MasterDataResource.MasterData) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  // </editor-fold>

  // <editor-fold desc="Form">

  getForm(request: Form.GetFormRequest): Observable<Form.Form> {
    return Observable.create((observer: Observer<Form.Form>) => {
      const resourceRequest: FormResource.GetFormRequest =
        this.formMapper.toResourceGetFormRequest(request);
      return this.resourceService.getForm(resourceRequest).subscribe(
        (result: FormResource.Form) => {
          observer.next(this.formMapper.toPublicForm(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateGetFormError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  createGroup(request: Form.CreateGroupRequest): Observable<Form.CreateGroupResponse> {
    return Observable.create((observer: Observer<Form.CreateGroupResponse>) => {
      const resourceRequest: FormResource.CreateGroupRequest =
        this.formMapper.toResourceCreateGroupRequest(request);
      return this.resourceService.createFormGroup(resourceRequest).subscribe(
        (result: FormResource.CreateGroupResponse) => {
          observer.next(this.formMapper.toPublicCreateGroupResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateCreateGroupError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  updateGroup(request: Form.UpdateGroupRequest): Observable<Form.UpdateGroupResponse> {
    return Observable.create((observer: Observer<Form.UpdateGroupResponse>) => {
      const resourceRequest: FormResource.UpdateGroupRequest =
        this.formMapper.toResourceUpdateGroupRequest(request);
      return this.resourceService.updateFormGroup(resourceRequest).subscribe(
        (result: FormResource.UpdateGroupResponse) => {
          observer.next(this.formMapper.toPublicUpdateGroupResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateUpdateGroupError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  disableGroup(request: Form.DisableGroupRequest): Observable<Form.DisableGroupResponse> {
    return Observable.create((observer: Observer<Form.DisableGroupResponse>) => {
      const resourceRequest: FormResource.DisableGroupRequest =
        this.formMapper.toResourceDisableGroupRequest(request);
      return this.resourceService.disableFormGroup(resourceRequest).subscribe(
        (result: FormResource.DisableGroupResponse) => {
          observer.next(this.formMapper.toPublicDisableGroupResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateDisableGroupError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  moveGroup(request: Form.MoveGroupRequest): Observable<Form.MoveGroupResponse> {
    return Observable.create((observer: Observer<Form.MoveGroupResponse>) => {
      const resourceRequest: FormResource.MoveGroupRequest =
        this.formMapper.toResourceMoveGroupRequest(request);
      return this.resourceService.moveFormGroup(resourceRequest).subscribe(
        (result: FormResource.MoveGroupResponse) => {
          observer.next(this.formMapper.toPublicMoveGroupResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateMoveGroupError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  createField(request: Form.CreateFieldRequest): Observable<Form.CreateFieldResponse> {
    return Observable.create((observer: Observer<Form.CreateFieldResponse>) => {
      const resourceRequest: FormResource.CreateFieldRequest =
        this.formMapper.toResourceCreateFieldRequest(request);
      return this.resourceService.createFormField(resourceRequest).subscribe(
        (result: FormResource.CreateFieldResponse) => {
          observer.next(this.formMapper.toPublicCreateFieldResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateCreateFieldError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  updateField(request: Form.UpdateFieldRequest): Observable<Form.UpdateFieldResponse> {
    return Observable.create((observer: Observer<Form.UpdateFieldResponse>) => {
      const resourceRequest: FormResource.UpdateFieldRequest =
        this.formMapper.toResourceUpdateFieldRequest(request);
      return this.resourceService.updateFormField(resourceRequest).subscribe(
        (result: FormResource.UpdateFieldResponse) => {
          observer.next(this.formMapper.toPublicUpdateFieldResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateUpdateFieldError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  disableField(request: Form.DisableFieldRequest): Observable<Form.DisableFieldResponse> {
    return Observable.create((observer: Observer<Form.DisableFieldResponse>) => {
      const resourceRequest: FormResource.DisableFieldRequest =
        this.formMapper.toResourceDisableFieldRequest(request);
      return this.resourceService.disableFormField(resourceRequest).subscribe(
        (result: FormResource.DisableFieldResponse) => {
          observer.next(this.formMapper.toPublicDisableFieldResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateDisableFieldError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  moveFieldToGroup(request: Form.MoveFieldToGroupRequest): Observable<Form.MoveFieldToGroupResponse> {
    return Observable.create((observer: Observer<Form.MoveFieldToGroupResponse>) => {
      const resourceRequest: FormResource.MoveFieldToGroupRequest =
        this.formMapper.toResourceMoveFieldToGroupRequest(request);
      return this.resourceService.moveFormFieldToGroup(resourceRequest).subscribe(
        (result: FormResource.MoveFieldToGroupResponse) => {
          observer.next(this.formMapper.toPublicMoveFieldToGroupResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateMoveFieldToGroupError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  importForm(request: Form.ImportRequest): Observable<Form.Form> {
    return Observable.create((observer: Observer<Form.Form>) => {
      return this.resourceService.import({
        parent_id: request.parentId,
        ignore_disabled_items: request.ignoreDisabledFields,
        form: request.form,
        version: request.version
      }).subscribe((result: FormResource.Form) => {
          observer.next(this.formMapper.toPublicForm(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        }
      );
    });
  }

  exportForm(request: Form.ExportRequest): Observable<Form.FormImportDocument> {
    return Observable.create((observer: Observer<Form.FormImportDocument>) => {
      return this.resourceService.export({
        parent_id: request.parentId,
      }).subscribe((result: FormResource.FormImportDocument) => {
          observer.next({form: result.form, version: result.version});
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        }
      );
    });
  }

  getDashboardCount(request: EmptyMessage): Observable<MasterData.MasterDataCount[]> {
    return Observable.create((observer: Observer<MasterData.MasterDataCount[]>) => {
      return this.resourceService.counts({}).subscribe(
        (result: MasterDataResource.MasterDataCount[]) => {
          observer.next(this.toPublicCountList(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  getDqlModel(request: MasterData.DqlRequest): Observable<DqlModel.QueryableModel> {
    return Observable.create((observer: Observer<DqlModel.QueryableModel>) => {
      return this.resourceService.getDqlModel({
        master_data_id: request.masterDataId
      }).subscribe((result: DqlResourceModel.QueryableModel) => {
          observer.next(new DqlModel.DqlQueryableModelConverter(!request.masterDataId).toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        }
      );
    });
  }

  // </editor-fold>

  // <editor-fold desc="Internal">

  private translateError(error: any): any {
    const res = ObservableErrorResourceParser.parseError(error);
    const fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
    const fieldErrorMap = ObservableErrorResourceParser.toFieldErrorMap(Keys.toValidatedField, fieldErrors);
    if (!fieldErrorMap.isEmpty()) {
      return FieldValidationError.of(fieldErrorMap);
    }
    return error;
  }

  private toPublicList(resourceList: MasterDataResource.MasterData[]): List<MasterData.MasterData> {
    return List.of(...resourceList.map((r) => this.toPublic(r)));
  }

  private toPublic(r: MasterDataResource.MasterData): MasterData.MasterData {
    return {
      masterDataId: r.id,
      disabled: r.disabled,
      creationTime: Services.toOffsetDateTime(r.creation_time),
      updateTime: Services.toOffsetDateTime(r.update_time),
      name: r.name,
      description: r.description,
      externalId: r.external_id,
      clientRequiredIdField: r.client_required_id_field,
      form: r.form ? this.formMapper.toPublicForm(r.form) : undefined,
      mobileOwnerType: <Customer.CustomerOwnerType>r.mobile_owner_type,
      ownerUserGroupIds: r.owner_user_group_ids ? r.owner_user_group_ids : [],
    };
  }

  private toPublicCountList(resList: MasterDataResource.MasterDataCount[]): MasterData.MasterDataCount[] {
    return resList.map((s) => this.toPublicCount(s));
  }

  private toPublicCount(r: MasterDataResource.MasterDataCount): MasterData.MasterDataCount {
    const item = new MasterData.MasterDataCount();
    item.masterDataId = r.master_data_id;
    item.masterDataName = r.master_data_name;
    item.masterDataRecordCount = r.master_data_record_count;
    return item;
  }

  // </editor-fold>

}

export namespace MasterData {

  export interface Service {

    // <editor-fold desc="CRUD">

    query(request: QueryRequest): Observable<QueryResult<MasterData>>;

    get(request: GetRequest): Observable<MasterData>;

    create(request: CreateRequest): Observable<MasterData>;

    update(request: UpdateRequest): Observable<MasterData>;

    setDisabled(request: DisableRequest): Observable<MasterData>;

    // </editor-fold>

  }

  // <editor-fold desc="CRUD">

  export interface MasterData {
    masterDataId: number;
    disabled: boolean;
    creationTime: OffsetDateTime;
    updateTime: OffsetDateTime;
    name: string;
    description?: string;
    externalId?: string;
    clientRequiredIdField: string;
    form?: Form.Form;
    mobileOwnerType: Customer.CustomerOwnerType;
    ownerUserGroupIds: number[];
  }

  export interface QueryRequest {
    masterDataIdSet?: Set<number>;
    disabled?: boolean;
    name?: string;
    externalId?: string;
    queryText?: string;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
    fields?: Set<string>;
    noProgressBar?: boolean;
  }

  export interface GetRequest {
    masterDataId: number;
    fields?: Set<string>;
    withForm?: boolean;
  }

  export interface CreateRequest {
    name: string;
    description?: string;
    externalId?: string;
    requiredMasterDataId: string;
    mobileOwnerType: Customer.CustomerOwnerType;
    ownerUserGroupIds: number[];
  }

  export interface UpdateRequest {
    masterDataId: number;
    name: string;
    description?: string;
    externalId?: string;
    requiredMasterDataId: string;
    mobileOwnerType: Customer.CustomerOwnerType;
    ownerUserGroupIds: number[];
  }

  export interface DisableRequest {
    masterDataId: number;
    disabled: boolean;
  }

  export interface DqlRequest {
    masterDataId?: number;
  }

  export class MasterDataCount implements DashboardCount.Item {
    masterDataId: number;
    masterDataName: string;
    masterDataRecordCount: number;

    getId(): number {
      return this.masterDataId;
    }

    getTitle(): string {
      return this.masterDataName;
    }

    getCount(): number {
      return this.masterDataRecordCount;
    }
  }

  export enum OrderField {
    ID,
    DISABLED,
    CREATION_TIME,
    UPDATE_TIME,
    NAME,
    DESCRIPTION,
    EXTERNAL_ID,
  }

  export enum ValidatedField {
    UNKNOWN,
    NAME,
    EXTERNAL_ID,
    CLIENT_REQUIRED_ID_FIELD,
    MOBILE_OWNER_TYPE,
    OWNER_USER_GROUPS,
  }

  // </editor-fold>

}

// <editor-fold desc="Internal">

class Keys {

  private static readonly ID = 'id';
  private static readonly DISABLED = 'disabled';
  private static readonly CREATION_TIME = 'creation_time';
  private static readonly UPDATE_TIME = 'update_time';
  private static readonly NAME = 'name';
  private static readonly DESCRIPTION = 'description';
  private static readonly EXTERNAL_ID = 'external_id';
  private static readonly MOBILE_OWNER_TYPE = 'mobile_owner_type';
  private static readonly OWNER_USER_GROUPS = 'owner_user_group_ids';

  private static readonly orderFieldKeyMap: Map<MasterData.OrderField, string> = Map.of(
    MasterData.OrderField.ID, Keys.ID,
    MasterData.OrderField.DISABLED, Keys.DISABLED,
    MasterData.OrderField.CREATION_TIME, Keys.CREATION_TIME,
    MasterData.OrderField.UPDATE_TIME, Keys.UPDATE_TIME,
    MasterData.OrderField.NAME, Keys.NAME,
    MasterData.OrderField.DESCRIPTION, Keys.DESCRIPTION,
    MasterData.OrderField.EXTERNAL_ID, Keys.EXTERNAL_ID,
  );

  private static readonly keyValidatedFieldMap: Map<string, MasterData.ValidatedField> = Map.of(
    Keys.NAME, MasterData.ValidatedField.NAME,
    Keys.EXTERNAL_ID, MasterData.ValidatedField.EXTERNAL_ID,
    Keys.MOBILE_OWNER_TYPE, MasterData.ValidatedField.MOBILE_OWNER_TYPE,
    Keys.OWNER_USER_GROUPS, MasterData.ValidatedField.OWNER_USER_GROUPS,
  );

  public static toOrderFieldKey(field: MasterData.OrderField): string {
    return Keys.orderFieldKeyMap.get(field)!;
  }

  public static toValidatedField(fieldKey: string): MasterData.ValidatedField {
    return Keys.keyValidatedFieldMap.get(fieldKey, MasterData.ValidatedField.UNKNOWN);
  }

}

// </editor-fold>

export const MASTER_DATA_SERVICE = new InjectionToken<MasterDataService>('MasterDataService');

export const MASTER_DATA_SERVICE_PROVIDER = {
  provide: MASTER_DATA_SERVICE,
  useClass: MasterDataService
};
