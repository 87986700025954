/* eslint-disable */
import { Component, } from '@angular/core';
import {
  FormRecordFieldContext,
  FormRecordFieldValueUpdateArgs,
  FormRecordFieldView,
  FormRecordFieldViewContext,
  FormRecordFieldViewModel,
  SetTmpReadonlyArgs,
  SetTmpReadonlyResult,
} from '../../../../../../util/form/form-utils';
import { Models } from '../../../../../../util/model-utils';
import { Form } from '../../../../../../lib/form/form.service';
import { Command } from '../../../../../../util/command';
import { noOpCommand } from '../../../manager/form-record-inactivity-manager';
import { Observable } from 'rxjs';

/* eslint-enable */

@Component({
  selector: 'app-form-record-readonly-html-field',
  templateUrl: 'form-record-readonly-html-field.component.html',
  styleUrls: ['form-record-readonly-html-field.component.scss'],
})
export class FormRecordReadonlyHtmlFieldComponent implements FormRecordFieldView {

  public readonly selector: Form.FieldDataTypeSelector.READONLY_HTML;

  formRecordFieldContext?: FormRecordFieldContext;

  model: Model = new Model();

  tmpReadonly: boolean = false;

  setTmpReadonly(args: SetTmpReadonlyArgs): Command<SetTmpReadonlyResult> {
    return noOpCommand;
  }

  registerField(context: FormRecordFieldContext, originalModel?: any): any {
    if (originalModel) {
      this.model = originalModel;
    }
    this.formRecordFieldContext = context;
    const attrs = context.field.dataType.readonlyTextAttributes!;
    this.model.value = Models.optToString(attrs.defaultValue);
    return this.model;
  }

  registerFieldViews(context: FormRecordFieldViewContext): void {
  }

  hasLocalFieldError(): boolean {
    return false;
  }

  validateWithInterrupt(): boolean {
    return false;
  }

  shouldNotifyAfterCreation(): boolean {
    return false;
  }

  afterFormRecordCreation(formRecordId: number): Observable<any> | undefined {
    return undefined;
  }

  createModel(): FormRecordFieldViewModel | undefined {
    return undefined;
  }

  updateValue(data: FormRecordFieldValueUpdateArgs) {
  }

}

export class Model {
  value: string = '';
}
