import { GrantedPermissionSet, GrantedPermissionSetResolver } from '../right.service';
import { OperationRights } from '../../app.right-definitions';

export class MasterDataRecordRightModel {

  public readonly create: GrantedPermissionSet;
  public readonly read: GrantedPermissionSet;
  public readonly update: GrantedPermissionSet;
  public readonly disable: GrantedPermissionSet;

  constructor(resolver: GrantedPermissionSetResolver) {
    this.create = resolver.of(OperationRights.MASTER_DATA_RECORD_CREATE);
    this.read = resolver.of(OperationRights.MASTER_DATA_RECORD_READ);
    this.update = resolver.of(OperationRights.MASTER_DATA_RECORD_UPDATE);
    this.disable = resolver.of(OperationRights.MASTER_DATA_RECORD_DISABLE);
  }
}
