/* eslint-disable */
import { FormEditFieldCreateDialogView, FormEditFieldDialogViewFactory, FormEditFieldUpdateDialogView, } from './form-utils';
import { ComponentFactoryResolver, ComponentRef, Injector, } from '@angular/core';
import { FormEditCreateUpdateStringFieldDialogComponent } from '../../admin/form/form-edit/dialogs/fields/string/create-update/form-edit-create-update-string-field-dialog.component';
/* eslint-enable */

/**
 * String field implementation.
 */
export class FormEditStringFieldDialogViewFactory implements FormEditFieldDialogViewFactory {

  constructor(
    private injector: Injector,
    private cfr: ComponentFactoryResolver) {
  }

  createCreateView(): ComponentRef<FormEditFieldCreateDialogView> {
    return this.createView();
  }

  createUpdateView(): ComponentRef<FormEditFieldUpdateDialogView> {
    return this.createView();
  }

  private createView(): ComponentRef<FormEditCreateUpdateStringFieldDialogComponent> {
    return this.cfr.resolveComponentFactory(FormEditCreateUpdateStringFieldDialogComponent)
      .create(this.injector);
  }

}
