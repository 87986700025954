import * as draw2d from '../../../../../../node_modules/draw2d/dist/draw2d.js';
import * as $ from '../../../../../../node_modules/jquery/dist/jquery.js';
import { Label } from './shape/label';
import { Group } from './shape/group';
import { DragDropEditPolicy } from './policy/drag-drop-edit-policy';
import { CanvasDragLogService } from './service/canvas-drag-log-service';
import { CanvasDragEvent, CanvasDragEventType } from './canvas-drag-event';
import { DropInterceptorPolicy } from './policy/drop-interceptor-policy';
import { Workflow, WorkflowService } from '../../../../lib/workflow/workflow.service';
import { fromEvent, Subscription } from 'rxjs';
import { Connection } from './connection/connection';
import { CanvasSelectionService, CanvasSelectionType } from './service/canvas-selection-service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { WorkflowTaskSelectorDialogComponent } from './task-selector-dialog/workflow-task-selector-dialog.component';
import { Set } from 'immutable';
import { CanvasSelectionPolicy } from './policy/canvas-selection-policy';

export class WorkflowEditorCanvas extends draw2d.Canvas {

  private startingCircle: draw2d.shape.basic.Circle;
  private startingPort: draw2d.Port;

  private workflowId: number;
  private dragSubscription: Subscription;
  private deletionSubscription: Subscription;
  private ignoreSelection: boolean = false;
  private taskSelectorDialogRef?: MatDialogRef<WorkflowTaskSelectorDialogComponent, any>;

  constructor(
    id: any,
    private canvasDragLogService: CanvasDragLogService,
    private canvasSelectionService: CanvasSelectionService,
    private workflowService: WorkflowService,
    private dialog: MatDialog
  ) {
    super(id);
    super.setScrollArea('#' + id);

    // Service responsible for listening to drag and dragEnd events
    this.dragSubscription = this.canvasDragLogService.subscribe(dragEvent => this.onFieldDrag(dragEvent));

    // Install edit policy for dropping figures that support recursive connections
    super.installEditPolicy(new DropInterceptorPolicy());

    // Creates the start circle on the top left
    this.createStartCircle();

    // Send selection changed event
    super.on('select', (emitter, event) => {
      if (event.selection.all.getSize() === 1) {
        if (event.figure instanceof Connection) {
          if (event.figure.getSource().parent.getId() === this.startingCircle.getId()) {
            this.canvasSelectionService.onSelectionChanged({
              type: CanvasSelectionType.NONE
            });
          } else {
            this.canvasSelectionService.onSelectionChanged({
              figure: event.figure,
              type: this.getSelectionType(event.figure)
            });
          }
        } else {
          this.canvasSelectionService.onSelectionChanged({
            figure: event.figure,
            type: this.getSelectionType(event.figure)
          });
        }
      } else {
        this.canvasSelectionService.onSelectionChanged({
          type: CanvasSelectionType.MULTI,
          figures: event.selection.all.data
        });
      }
    });

    const selectionPolicy = new CanvasSelectionPolicy();

    super.on('unselect', (emitter, event) => {
      selectionPolicy.addPreviousSelectedFigure(event.figure);
      this.canvasSelectionService.onSelectionChanged({
        type: CanvasSelectionType.NONE
      });
    });

    super.installEditPolicy(selectionPolicy);
  }

  setWorkflowId(id: number) {
    this.workflowId = id;
  }

  setReadonly(readonly: boolean) {
    this.startingPort.setDraggable(!readonly);
    this.startingPort.setSelectable(!readonly);
    if (!readonly) {
      // Initialize deletion policy
      this.initDeletionPolicy();
    }
  }

  // Called if a DOM item is dropped on the canvas
  public onDrop(droppedDomNode: any, x: number, y: number, shiftKey: any, ctrlKey: any) {
    // Type of the shape to be created can be resolved from the 'shape' attribute of the dropped DOM element
    const type = $(droppedDomNode).data('shape');

    // Resolve type from the variable
    let figure: Group | Label;
    if (type === 'group') {
      figure = new Group();
      figure.setName($(droppedDomNode).data('id'));
      this.createGroup(figure, x, y);

      // Installs the drag/drop edit policy, which handles drag/drop events
      figure!.installEditPolicy(new DragDropEditPolicy(this.canvasDragLogService));

      this.ignoreSelection = true;

      // Finally, add the new element to the canvas
      super.add(figure!, x, y);
    }
    else {
      this.taskSelectorDialogRef = this.dialog.open(WorkflowTaskSelectorDialogComponent, {
        width: '90vw'
      });
      const modalBody = document.getElementById('modalBody');
      modalBody!.click();

      this.taskSelectorDialogRef.afterClosed().subscribe(task => {
        this.taskSelectorDialogRef = undefined;
        if (task) {
          figure = new Label(true);
          figure.setName(task.name);
          this.createTask(figure, task, x, y);

          // Installs the drag/drop edit policy, which handles drag/drop events
          figure!.installEditPolicy(new DragDropEditPolicy(this.canvasDragLogService));

          // Finally, add the new element to the canvas
          super.add(figure!, x, y);
        }
      });
    }
  }

  private createStartCircle() {
    const circle = new draw2d.shape.basic.Circle( { diameter: 24 });
    circle.setSelectable(false);
    circle.setResizeable(false);
    circle.setDraggable(false);
    circle.setDeleteable(false);
    circle.setBackgroundColor(new draw2d.util.Color('#8D949C'));
    circle.setStroke(0);
    this.startingCircle = circle;

    // Create the output port fot he circle
    const outputPort = circle.createPort('output');
    outputPort.setMaxFanOut(1);
    outputPort.setStroke(0);
    outputPort.setDiameter(4);
    outputPort.setBackgroundColor(new draw2d.util.Color('#8D949C'));
    outputPort.useGradient = false;
    outputPort.setColor(new draw2d.util.Color('#00727B'));
    this.startingPort = outputPort;

    // Add circle to the canvas
    super.add(circle, 25, 30);
  }

  private initDeletionPolicy() {
    this.deletionSubscription = fromEvent(document, 'keyup').subscribe((event: KeyboardEvent) => {
      if (event.code === 'Delete') {
        const figure = super.getSelection().primary;
        if (figure) {
          // @ts-ignore
          if (figure instanceof Label && figure.parent) {
            // @ts-ignore
            figure.parent.parent.removeChild(figure);
          }
          if (figure instanceof Label || figure instanceof Group) {
            this.deleteObject(figure);
            figure.getConnections().data.forEach(c => {
              super.remove(c);
            });
            super.remove(figure);
          }
          else if (figure instanceof Connection) {
            this.deleteTransition(figure);
            super.remove(figure);
          }
        }
      }
    });
  }

  private onFieldDrag(dragEvent: CanvasDragEvent) {
    // Iterate each figure on the canvas
    if (dragEvent.figure instanceof Label) {
      let movedToGroup: boolean = false;
      super.getFigures().asArray().forEach(figure => {
        if (figure instanceof Group) {
          // If label overlaps a group.
          if (this.overlaps(figure, dragEvent.figure)) {
            // If item is dragged over a group, show drop zone
            if (dragEvent.eventType === CanvasDragEventType.DRAG) {
              figure.showDropZone();
            }
            // On drag end, add figure to the group, and remove it from the canvas
            else if (dragEvent.eventType === CanvasDragEventType.DRAG_END) {
              dragEvent.figure.getConnections().data.forEach(c => {
                super.remove(c);
              });
              figure.addItem(dragEvent.figure);
              figure.hideDropZone();
              setTimeout(() => {
                super.remove(dragEvent.figure);
              });
              this.addTaskToGroup(figure, dragEvent.figure);
              movedToGroup = true;
            }
          }
          // If no conditions match, hide drop zone on the group
          else {
            figure.hideDropZone();
          }
        }
      });
      if (!movedToGroup && dragEvent.eventType === CanvasDragEventType.DRAG_END) {
        // @ts-ignore
        this.moveObject(dragEvent.figure, dragEvent.figure.x, dragEvent.figure.y);
      }
    }
    else if (dragEvent.eventType === CanvasDragEventType.DRAG_END) {
      this.moveObject(dragEvent.figure, dragEvent.figure.x, dragEvent.figure.y);
    }
  }

  // Maths magic to find out if two figures overlap
  overlaps(figure: any, figure2: any): boolean {
    const rectA1 = figure.getBoundingBox().getTopLeft();
    const rectB1 = figure2.getBoundingBox().getTopLeft();
    const rectA2 = figure.getBoundingBox().getBottomRight();
    const rectB2 = figure2.getBoundingBox().getBottomRight();

    return rectA1.getX() < rectB2.getX() && rectA2.getX() > rectB1.getX() && rectA1.getY() < rectB2.getY() && rectA2.getY() > rectB1.getY();
  }

  setZoom(level: number) {
    super.setZoom(level, true);
  }

  getZoom(): number {
    return super.getZoom();
  }

  installEditPolicy(policy) {
    super.installEditPolicy(policy);
  }

  add(figure, x?, y?) {
    super.add(figure, x, y);
  }

  getFigures() {
    return super.getFigures();
  }

  getStartingPort(): draw2d.Port {
    return this.startingPort;
  }

  getStartingCircle(): draw2d.shape.basic.Circle {
    return this.startingCircle;
  }

  getFigure(id) {
    return super.getFigure(id);
  }

  createTask(figure, task, x, y) {
    this.workflowService.createTask({
      workflowId: this.workflowId,
      taskId: task.taskId,
      location: {
        topLeftPoint: {
          x: x,
          y: y
        },
        size: {
          width: figure.getName().length,
          height: figure.height
        }
      }
    }).subscribe(result => {
      figure.setId(result.id);
      figure.setUserData({
        id: result.id,
        task: {
          name: task.name,
          id: task.taskId
        }
      });
    });
  }

  createGroup(figure, x, y) {
    const location: Workflow.Location = {
      topLeftPoint: {
        x: x,
        y: y
      },
      size: {
        width: figure.getName().length,
        height: figure.height
      }
    };
    this.workflowService.createGroup({
      workflowId: this.workflowId,
      location: location
    }).subscribe(result => {
      figure.setId(result.id);
      figure.setUserData({
        id: result.id,
        name: '',
        location: location
      });
    });
  }

  moveObject(figure, x, y) {
    this.workflowService.updateObjectLocation({
      workflowId: this.workflowId,
      objectId: figure.getId(),
      location: {
        topLeftPoint: {
          x: x,
          y: y
        },
        size: {
          width: figure.getName().length,
          height: figure.height
        }
      }
    }).subscribe(result => {
      // do nothing?
    });
  }

  addTaskToGroup(group, task) {
    this.workflowService.addTaskToGroup({
      workflowId: this.workflowId,
      groupId: group.getId(),
      taskId: task.getId()
    }).subscribe(result => {
      // do nothing?
    });
  }

  removeTaskFromGroup(figure, event) {
    const coordinates = super.fromDocumentToCanvasCoordinate(event.clientX - 35, event.clientY - 19);
    this.workflowService.removeTaskFromGroup({
      workflowId: this.workflowId,
      taskId: figure.getId(),
      location: {
        topLeftPoint: {
          x: coordinates.x,
          y: coordinates.y
        },
        size: {
          width: figure.getName().length,
          height: figure.height
        }
      }
    }).subscribe(result => {
      // do nothing?
    });
  }

  deleteObject(figure) {
    this.workflowService.deleteObject({
      workflowId: this.workflowId,
      objectId: figure.getId()
    }).subscribe(result => {
      // do nothing?
    });
  }

  createTransition(connection) {
    this.workflowService.createTransition({
      workflowId: this.workflowId,
      inputObjectId: connection.targetPort.parent.id,
      outputObjectId: connection.sourcePort.parent.id,
      points: connection.getVertices().data.map(v => ({x: v.x, y: v.y}))
    }).subscribe(result => {
      connection.setId(result.id);
      connection.setUserData({
        addedByPainter: false,
        transition: {
          id: result.id,
          inputObjectId: connection.targetPort.parent.id,
          outputObjectId: connection.sourcePort.parent.id,
          points: [],
          rule: {
            taskRecordStates: Set.of(),
            formFieldRules: []
          }
        }
      });
    });
  }

  updateTransitionPoints(connection) {
    this.workflowService.updateTransitionPoints({
      workflowId: this.workflowId,
      transitionId: connection.getId(),
      points: connection.getVertices().data.map(v => ({x: v.x, y: v.y}))
    }).subscribe(result => {
      // do nothing?
    });
  }

  deleteTransition(connection) {
    this.workflowService.deleteTransition({
      workflowId: this.workflowId,
      transitionId: connection.getId()
    }).subscribe(result => {
      // do nothing?
    });
  }

  private getSelectionType(figure): CanvasSelectionType {
    if (figure instanceof Label) {
      return CanvasSelectionType.TASK;
    }
    else if (figure instanceof Group) {
      return CanvasSelectionType.GROUP;
    }
    else if (figure instanceof Connection) {
      return CanvasSelectionType.TRANSITION;
    }
    return CanvasSelectionType.NONE;
  }

  remove(figure) {
    super.remove(figure);
  }

  setCurrentSelection(figure) {
    super.setCurrentSelection(figure);
  }

  onDestroy() {
    this.canvasDragLogService.unsubscribe(this.dragSubscription);
    if (this.deletionSubscription && !this.deletionSubscription.closed) {
      this.deletionSubscription.unsubscribe();
    }
    if (this.taskSelectorDialogRef) {
      this.taskSelectorDialogRef.close();
    }
  }

}
