/* eslint-disable */
import { DisableRequest, EmptyMessage, IdentityMessage } from '../util/messages';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { ResourceQueryResult } from '../util/services';
import { IconResource } from '../task/icon.service';
import { FormResource, FormResourcePathGenerator } from '../form/form-resource.service';
import { DqlStoredQueryResourceService } from '../dql/dql-stored-query-resource.service';
import { DqlResourceModel } from '../dql/dql-resource.model';
import { TaskResource } from '../task/task-resource.service';

/* eslint-enable */

@Injectable()
export class ProjectResourceService extends BaseHttpService {

  private static readonly baseUrl: string = '/projects/';

  private readonly _dqlStoredResourceService: DqlStoredQueryResourceService;

  query(request: ProjectResource.QueryRequest): Observable<ResourceQueryResult<ProjectResource.Project>> {
    return this.http.get<ResourceQueryResult<ProjectResource.Project>>(this.url, this.parseParams(request));
  }

  get(request: ProjectResource.GetRequest): Observable<ProjectResource.Project> {
    return this.http.get<ProjectResource.Project>(this.url + `${request.id}`, this.parseParams(request));
  }

  create(request: ProjectResource.CreateRequest): Observable<EmptyMessage> {
    return this.http.post(this.url, request);
  }

  update(request: ProjectResource.UpdateRequest): Observable<EmptyMessage> {
    return this.http.put(this.url + `${request.id}`, request);
  }

  setDisabled(request: DisableRequest): Observable<EmptyMessage> {
    return this.http.patch(this.url + `${request.id}/disabled`, request);
  }

  counts(request: EmptyMessage): Observable<ProjectResource.Count[]> {
    return this.http.get<ProjectResource.Count[]>(this.url + `counts`, this.parseParams(request));
  }

  // <editor-fold desc="Form">

  getForm(request: FormResource.GetFormRequest): Observable<FormResource.Form> {
    return this.http.get<FormResource.Form>(this.url + FormResourcePathGenerator.GetForm(request));
  }

  createFormGroup(request: FormResource.CreateGroupRequest): Observable<FormResource.CreateGroupResponse> {
    return this.http.post<FormResource.CreateGroupResponse>(this.url + FormResourcePathGenerator.CreateGroup(request), request);
  }

  updateFormGroup(request: FormResource.UpdateGroupRequest): Observable<FormResource.UpdateGroupResponse> {
    return this.http.put<FormResource.UpdateGroupResponse>(this.url + FormResourcePathGenerator.UpdateGroup(request), request);
  }

  disableFormGroup(request: FormResource.DisableGroupRequest): Observable<FormResource.DisableGroupResponse> {
    return this.http.patch<FormResource.DisableGroupResponse>(this.url + FormResourcePathGenerator.DisableGroup(request), request);
  }

  moveFormGroup(request: FormResource.MoveGroupRequest): Observable<FormResource.MoveGroupResponse> {
    return this.http.post<FormResource.MoveGroupResponse>(this.url + FormResourcePathGenerator.MoveGroup(request), request);
  }

  createFormField(request: FormResource.CreateFieldRequest): Observable<FormResource.CreateFieldResponse> {
    return this.http.post<FormResource.CreateFieldResponse>(this.url + FormResourcePathGenerator.CreateField(request), request);
  }

  updateFormField(request: FormResource.UpdateFieldRequest): Observable<FormResource.UpdateFieldResponse> {
    return this.http.post<FormResource.UpdateFieldResponse>(this.url + FormResourcePathGenerator.UpdateField(request), request);
  }

  disableFormField(request: FormResource.DisableFieldRequest): Observable<FormResource.DisableFieldResponse> {
    return this.http.patch<FormResource.DisableFieldResponse>(this.url + FormResourcePathGenerator.DisableField(request), request);
  }

  moveFormFieldToGroup(request: FormResource.MoveFieldToGroupRequest): Observable<FormResource.MoveFieldToGroupResponse> {
    return this.http.post<FormResource.MoveFieldToGroupResponse>(this.url + FormResourcePathGenerator.MoveFieldToGroup(request), request);
  }

  import(request: FormResource.ImportRequest): Observable<EmptyMessage> {
    return this.http.post<EmptyMessage>(this.url + FormResourcePathGenerator.Import(request), request);
  }

  export(request: FormResource.ExportRequest): Observable<FormResource.FormImportDocument> {
    return this.http.post<FormResource.FormImportDocument>(this.url + FormResourcePathGenerator.Export(request), request);
  }

  getDqlModel(request: ProjectResource.DqlRequest): Observable<DqlResourceModel.QueryableModel> {
    if (request.project_id) {
      return this.http.get<DqlResourceModel.QueryableModel>(this.url + `dql/model/${request.project_id}` + + '?no_progress_bar=true');
    } else {
      return this.http.get<DqlResourceModel.QueryableModel>(this.url + `dql/model`+ + '?no_progress_bar=true');
    }
  }

  get dqlStoredResourceService(): DqlStoredQueryResourceService {
    return this._dqlStoredResourceService;
  }

  // </editor-fold>

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, ProjectResourceService.baseUrl);
    this._dqlStoredResourceService = new DqlStoredQueryResourceService(http, resourceHelper, ProjectResourceService.baseUrl);
  }

}

export namespace ProjectResource {

  export interface QueryRequest {
    fields?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
    with_form?: boolean;
    q?: string;

    id?: string;
    name?: string;
    external_id?: string;
    disabled?: boolean;
    no_progress_bar?: boolean;
    rights?: string;
  }

  export interface CreateRequest {
    name: string;
    external_id?: string;
    description?: string;
    icon?: IconResource.Icon;
    explicit_order_number?: number;
    related_user_groups: number[];
  }

  export interface UpdateRequest {
    id: number;
    name: string;
    external_id: string;
    description?: string;
    icon?: IconResource.Icon;
    explicit_order_number?: number;
    displayed_form_field?: number;
    related_user_groups: number[];
  }

  export interface DisableRequest extends IdentityMessage {
    disabled: boolean;
  }
  export interface GetRequest extends IdentityMessage {
    rights?: string;
  }

  export interface Project {
    id: number;
    external_id: string;
    disabled: boolean;
    creation_time: string;
    update_time: string;
    name: string;
    description?: string;
    form?: FormResource.Form;
    icon?: IconResource.Icon;
    explicit_order_number?: number;
    displayed_form_field?: FormResource.Field;
    related_user_groups: number[];
    granted_rights?: string[];
  }

  export interface Count {
    project_id: number;
    project_record_count: number;
    project_name: string;
    icon?: IconResource.Icon;
  }

  export interface DqlRequest {
    project_id?: number;
  }

}
