/* eslint-disable */
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { PermissionResource, PermissionResourceService } from '../../../lib/permission-resource.service';
import { Role, RoleService } from '../../../lib/role/role.service';
import { UIRouter } from '@uirouter/angular';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { TranslateService } from '@ngx-translate/core';
import { StateName } from '../../../app.state-names';
import { EmptyMessage } from '../../../lib/util/messages';
import { NgForm } from '@angular/forms';
import { FieldValidationError } from '../../../lib/util/services';
import { UiConstants } from '../../../util/core-utils';
import { TwoWayTreeMultiselectComponent } from '../../../shared/two-way-tree-multiselect/two-way-tree-multiselect.component';
import { Node, TreeUtils } from '../../../util/tree/tree-utils';
import { StringKey } from '../../../app.string-keys';
import Permission = PermissionResource.Permission;

/* eslint-enable */

@Component({
  selector: 'app-role-create',
  templateUrl: './role-create.component.html',
  styleUrls: ['./role-create.component.scss']
})
export class RoleCreateComponent implements OnInit, AfterViewInit {
  Role = Role;
  UiConstants = UiConstants;
  StringKey = StringKey;

  @ViewChild('f', { static: true })
  fForm: NgForm;

  @ViewChild('permissionSelector', { static: true })
  permissionSelector: TwoWayTreeMultiselectComponent;

  model: RoleCreateModel = new RoleCreateModel();
  permissionList: Node[] = [];

  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  private fieldErrors: FieldValidationError<Role.ValidatedField> =
    FieldValidationError.empty<Role.ValidatedField>();

  constructor(
    private roleService: RoleService,
    private permissionService: PermissionResourceService,
    private translateService: TranslateService,
    private uiRouter: UIRouter,
  ) {
  }

  ngOnInit() {
    this.translateService.get('ROLE_CREATE_HEADING').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('ROLE_LIST_HEADING').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ROLE_LIST});
      }
    );
  }

  ngAfterViewInit(): void {
    this.loadPermissions();
  }


  private loadPermissions() {
    this.permissionService.query({
      explicit: false
    }).subscribe(
      (perms) => {
        this.permissionList = this.processArray(perms);
        this.permissionSelector.setUnselectedData(Object.assign([], this.permissionList));
      }
    );
  }

  private processArray(list: Permission[]): Node[] {
    const tree: Node[] = [];
    list.forEach((i) => {
      const first: string[] = i.short_name.split('::');
      const descendants: string[] = first[0].split(':');
      if (first[1]) {
        descendants.push(first[1]);
      }
      const item: string = descendants.shift()!;
      TreeUtils.treePut(tree, [], item, descendants, i.short_name);
    });
    return TreeUtils.convertArray(tree);
  }

  hasFieldError(field: Role.ValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: Role.ValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: Role.ValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  create() {
    this.model.privileges = [];
    this.permissionSelector.getData().forEach(node => {
      this.model.privileges.push(node.id);
    });
    this.roleService.create({
      name: this.model.name,
      privileges: this.model.privileges
    }).subscribe((result: EmptyMessage) => {
        this.uiRouter.stateService.go(StateName.ROLE_LIST);
      },
      (error: any) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withForm(this.fForm);
        }
      }
    );
  }

}

export class RoleCreateModel {
  name: string = '';
  privileges: string[] = [];
}
