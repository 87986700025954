/* eslint-disable */
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UiConstants } from '../../../util/core-utils';
import { FileAttachmentModel } from '../../../util/file-attachment.model';
import { Subject } from 'rxjs';

/* eslint-enable */

@Component({
  selector: 'app-material-image-viewer-window',
  templateUrl: './material-image-viewer-dialog.component.html',
  styleUrls: ['./material-image-viewer-dialog.component.scss']
})
export class MaterialImageViewerDialogComponent implements OnInit {
  UiConstants = UiConstants;

  clickedImage: FileAttachmentModel;

  navigationClicked: Subject<MaterialImageViewerNavigation> = new Subject<MaterialImageViewerNavigation>();

  constructor(public dialogRef: MatDialogRef<MaterialImageViewerDialogComponent, void>,
              @Inject(MAT_DIALOG_DATA) public data: MaterialImageViewerDialogComponentData) {
    this.clickedImage = data.clickedImage;
  }

  ngOnInit() {
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === 'Escape') {
        this.closeImageViewerWindow();
      }
      if (event.key === 'ArrowLeft') {
        this.onPreviousClicked();
      }
      if (event.key === 'ArrowRight') {
        this.onNextClicked();
      }
    });
  }

  static openImageViewerWindow(dialog: MatDialog, data: MaterialImageViewerDialogComponentData):
    MatDialogRef<MaterialImageViewerDialogComponent, void> {
    const dialogRef: MatDialogRef<MaterialImageViewerDialogComponent, void> = dialog.open(MaterialImageViewerDialogComponent, {
      data: data
    });
    return dialogRef;
  }

  closeImageViewerWindow() {
    this.dialogRef.close();
  }

  getAttachedImageUrl(): string {
    if (this.clickedImage && this.clickedImage.base64dataUrl) {
      return this.clickedImage.base64dataUrl;
    }
    return '';
  }

  get onNavigationClicked() {
    return this.navigationClicked;
  }

  onPreviousClicked() {
    this.navigationClicked.next(MaterialImageViewerNavigation.PREVIOUS);
  }

  onNextClicked() {
    this.navigationClicked.next(MaterialImageViewerNavigation.NEXT);
  }

  changeClickedImage(clickedImage: FileAttachmentModel) {
    this.clickedImage = clickedImage;
  }

}

export interface MaterialImageViewerDialogComponentData {
  clickedImage: FileAttachmentModel;
}

export enum MaterialImageViewerNavigation {
  PREVIOUS, NEXT
}
