<!-- region Form card -->
<div class="card" *ngIf="formRightModel.formRead.hasRight()">

  <div class="card-header">
      {{'FORM_CREATE_GROUP_TITLE' | translate}}
      <div class="card-actions margin-right-50">
        <a class="btn-setting table-header-action-button show-disabled-form-checkbox d-flex align-items-center">
          {{'FORM_SHOW_DISABLED_FIELDS' | translate}}
        <label class="switch switch-text switch-info mb-0 ml-1">
          <input type="checkbox" class="switch-input" [(ngModel)]="displayDisabledFields" (click)="loadForm()">
          <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
          <span class="switch-handle"></span>
        </label>
        </a>
      </div><!-- /.card-actions -->
      <div class="card-actions" *ngIf="formRightModel.formGroupCreate.hasRight()">
        <a class="btn-setting table-header-action-button" (click)="showCreateGroupDialog()" title="{{'FORM_CREATE_GROUP_ADD' | translate}}"><i class="icomoon icomoon-add"></i></a>
      </div><!-- /.card-actions -->
  </div> <!-- /.card-header -->

  <div class="card-body p-1">

    <div class="panel panel-success">
      <div class="panel-body">
        <div cdkDropList
             [cdkDropListData]="model.formGroups"
             (cdkDropListDropped)="onFormGroupDropped($event)">
          <!-- region Groups -->
          <div class="col-sm3"
               *ngFor="let group of model.formGroups; let i = index; let lastItem = last;"
               cdkDrag [cdkDragDisabled]="!groupDragAndDropEnabled" cdkDragLockAxis="y" cdkDropListGroup [cdkDragData]="group"
               (cdkDragStarted)="dragStarted(group)" (cdkDragEnded)="dragEnded()">

            <div id="group_{{group.groupId}}" class="card card-accent-primary cursor-default-important">

              <div class="p-0 m-0"
                   cdkDropList [id]="group.groupId+''" [cdkDropListData]="group.fields"
                   [cdkDropListConnectedTo]="groupIds" (cdkDropListDropped)="onFormGroupFieldDropped($event)">

                <!-- region Group (card header) -->
                <div class="card-header p-0" [ngClass]="{'border-bottom-0': group.fields.length === 0}">
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="p-0 pl-1 m-0 d-flex align-items-center flex-1 min-width-0">
                      <i class="icomoon icomoon-menu-thick pr-1 type-icon cursor-pointer" title="{{'FORM_MOVE_GROUP' | translate}}"
                         (mouseenter)="onDragEnter()" (mouseleave)="onDragLeave()" cdkDragHandle></i>
                      <p class="mb-0 text-truncate">
                        {{group.title}} <span *ngIf="group.shortTitle" class="text-muted">({{group.shortTitle}})</span>
                      </p>
                    </div>
                    <!-- /.p-0 m-0 -->
                  <!-- /.d-flex justify-content-between -->
                    <div class="card-actions position-relative d-flex text-nowrap">
                      <a href="#" onclick="return false" *ngIf="i !== model.formGroups.length - 1 && formRightModel.formGroupMove.hasRight()" (click)="moveGroup(group, i+1, true)" class="btn-setting table-header-action-button"
                         title="{{'FORM_MOVE_GROUP_UP' | translate}}">
                        <i class="icomoon icons-table-item icomoon-dropdown"></i>
                      </a>

                      <a href="#" onclick="return false" *ngIf="i > 0 && formRightModel.formGroupMove.hasRight()" (click)="moveGroup(group, i-1, true)" class="btn-setting table-header-action-button"
                         title="{{'FORM_MOVE_GROUP_DOWN' | translate}}">
                        <i class="icomoon icons-table-item icomoon-arrow-up"></i>
                      </a>

                      <a href="#" onclick="return false" *ngIf="!group.disabled && formRightModel.formGroupDisable.hasRight()" (click)="setGroupDisabled(group, true)" class="btn-setting table-header-action-button-disable"
                         title="{{'COMMON_BUTTON_DISABLE' | translate}}">
                        <i class="icomoon icons-table-item icomoon-disable"></i>
                      </a>

                      <a href="#" onclick="return false" *ngIf="group.disabled && formRightModel.formGroupDisable.hasRight()" (click)="setGroupDisabled(group, false)" class="btn-setting table-header-action-button-enable"
                         title="{{'COMMON_BUTTON_ENABLE' | translate}}">
                        <i class="icomoon icons-table-item icomoon-enable"></i>
                      </a>

                      <a href="#" onclick="return false" (click)="showUpdateGroupDialog(group)" *ngIf="formRightModel.formGroupUpdate.hasRight()" class="btn-setting table-header-action-button"
                         title="{{'COMMON_BUTTON_EDIT' | translate}}">
                        <i class="icomoon icons-table-item icomoon-modify"></i>
                      </a>

                      <a class="dropdown btn-setting table-header-action-button" dropdown href="#" onclick="return false" title="{{'COMMON_BUTTON_ADD' | translate}}" *ngIf="formRightModel.formFieldCreate.hasRight()"
                         data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" dropdownToggle>
                        <i class="icomoon icomoon-add"></i>
                        <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
                          <span *ngFor="let descriptor of model.createGroupMenuDescriptors"
                                (click)="showCreateFieldDialog(group, descriptor)"
                                class="dropdown-item">{{descriptor.formFieldNameDictionaryKey | translate}}</span>
                        </div>
                      </a>
                    </div> <!-- /.card-actions -->
                </div> <!-- /.card-header -->
                </div>
                <!-- endregion Group (card header) -->

                <!--region header texts-->
                <div class="card-body p-0" *ngIf="group.fields.length > 0">
                  <ul class="list-group" *ngIf="displayDisabledFields || (!displayDisabledFields && countDisabledItemsInGroup(group.fields) < group.fields.length)">
                    <li class="list-group-item border-0">
                      <div class="row" style="width: 100%;">
                        <div class="col-md-3 col-lg-3 text-truncate">
                          <h6 class="mb-0">
                            <i class="icomoon icomoon-menu-thick pr-1 type-icon" style="color:transparent" title="{{'COMMON_BUTTON_MOVE' | translate}}"></i>
                            {{ 'COMMON_NAME' | translate }}
                          </h6>
                        </div>
                        <div class="col-lg-2 col-md-2 text-truncate hidden-sm-down">
                          <h6 class="mb-0">{{ 'FORM_ITEM_COMMON_FIELD_WIDTH' | translate }}</h6>
                        </div>
                        <div class="col-lg-2 col-md-2 text-truncate hidden-sm-down">
                          <h6 class="mb-0">{{ 'FORM_ITEM_COMMON_PDF_EXPORT_NAME' | translate }}</h6>
                        </div>
                        <div class="col-md-1 col-lg-1 text-truncate">
                          <h6 class="mb-0">{{ 'COMMON_BUTTON_DETAILS' | translate }}</h6>
                        </div>
                        <div class="col-md-3 col-lg-3 text-truncate">
                          <h6 class="mb-0">{{ 'COMMON_BUTTON_VALIDATION' | translate }}</h6>
                        </div>
                        <div class="col-md-1 col-lg-1">
                          <!--left empty intentionally-->
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <!--endregion header texts-->

              <!-- region Fields - Group (card block) -->
              <div class="card-body p-0">
                <ul class="list-group">
                  <ng-container *ngFor="let field of group.fields; let x = index; let last = last" >
                    <li *ngIf="!field.disabled || (field.disabled && displayDisabledFields)"
                        [id]="group.groupId+'_'+field.fieldId"
                        class="cursor-default-important list-group-item border-left-0 border-right-0"
                        [ngClass]="{'border-bottom-0' : last}"
                        cdkDrag
                        [cdkDragDisabled]="!fieldDragAndDropEnabled"
                        cdkDragLockAxis="y"
                        [cdkDragData]="field"
                        (cdkDragStarted)="dragStarted(group, field)"
                        (cdkDragEnded)="dragEnded()">
                      <div class="row" style="width: 100%;">
                        <div class="col-md-3 col-lg-3 text-truncate d-flex">
                            <i class="icomoon icomoon-menu-thick type-icon cursor-pointer pr-1" cdkDragHandle
                               title="{{'COMMON_BUTTON_MOVE' | translate}}"
                               (mouseenter)="onDragEnter()" (mouseleave)="onDragLeave()"></i>
                            <div>
                              <i class="icomoon type-icon" [ngClass]="getIconClassForField(field.dataTypeSelector).icon"
                              title="{{ 'FORM_ITEM_LIST_' + Form.FieldDataTypeSelector[field.dataTypeSelector] + '_TYPE' | translate }}"></i>
                            </div>
                          <span class="pl-1 text-truncate">{{field.title}}</span>
                        </div>
                        <div class="col-lg-2 col-md-2 text-truncate hidden-sm-down">
                          {{ parsePercentValueToText(field.formFieldWidthPercent).textDictionaryKey | translate }}
                        </div>
                        <div class="col-lg-2 col-md-2 text-truncate hidden-sm-down">
                          {{ field.pdfExportName }}
                        </div>
                        <div class="col-md-1 col-lg-1 text-nowrap">
                          <i *ngIf="field.mobile.visibleOnMasterDetail" class="icomoon icomoon-visible-on-detail detail-icon" title="{{ 'FORM_ITEM_COMMON_MOBILE_VISIBLE_ON_MASTER_DETAIL' | translate}}"></i>
                          <i *ngIf="field.showOnReceipt" class="icomoon icomoon-visible-on-invoice detail-icon" title="{{ 'FORM_ITEM_COMMON_SHOW_ON_RECEIPT' | translate}}"></i>
                        </div>
                        <div class="col-md-3 col-lg-3 d-flex">
                          <app-table-badge
                            class="mr-05"
                            [badgeStyle]="getValidationBadge(field.admin.validationType).style"
                            [iconClass]="'icomoon-admin'"
                            [textAsPopover]="true"
                            [text]="getValidationBadge(field.admin.validationType).stringKey | translate">
                          </app-table-badge>

                          <app-table-badge
                            class="mr-05"
                            [badgeStyle]="getValidationBadge(field.mobile.validationType).style"
                            [iconClass]="'icomoon-mobile'"
                            [textAsPopover]="true"
                            [text]="getValidationBadge(field.mobile.validationType).stringKey | translate">
                          </app-table-badge>
                          <app-table-badge
                            [badgeStyle]="getValidationBadge(field.helpdesk.validationType).style"
                            [iconClass]="'icomoon-helpdesk-icon'"
                            [textAsPopover]="true"
                            [text]="getValidationBadge(field.helpdesk.validationType).stringKey | translate">
                          </app-table-badge>
                        </div>
                        <div class="col-md-1 col-lg-1 text-nowrap">

                          <a href="#"  *ngIf="field.disabled && formRightModel.formFieldDisable.hasRight()" onclick="return false" (click)="setFieldDisabled(group, field, false)" class="btn-setting"
                             title="{{'COMMON_BUTTON_ENABLE' | translate}}">
                            <i class="icomoon icomoon-enable icomoon-enable-table detail-icon"></i>
                          </a>

                          <a href="#" *ngIf="!field.disabled && formRightModel.formFieldDisable.hasRight()" onclick="return false"  (click)="setFieldDisabled(group, field, true)" class="btn-setting"
                             title="{{'COMMON_BUTTON_DISABLE' | translate}}">
                            <i class="icomoon icomoon-disable icomoon-disable-table detail-icon"></i>
                          </a>

                          <a href="#" onclick="return false" (click)="showUpdateFieldDialog(group, field)"
                             *ngIf="formRightModel.formFieldUpdate.hasRight()" class="btn-setting"
                             title="{{'COMMON_BUTTON_EDIT' | translate}}">
                            <i class="icomoon icomoon-modify icomoon-modify-table detail-icon"></i>
                          </a>

                          <a href="#" onclick="return false" (click)="showUpdateFieldDialog(group, field, true)"
                             *ngIf="formRightModel.formFieldCreate.hasRight() && isCloneableField(field)" class="btn-setting"
                             title="{{'COMMON_BUTTON_CLONE' | translate}}">
                            <i class="icomoon icomoon-clone icomoon-modify-table detail-icon"></i>
                          </a>

                        </div>
                      </div>
                    </li>
                  </ng-container>
                </ul>
              </div> <!-- /.card-body -->
              <!-- endregion Fields - Group (card block) -->

              </div>

            </div>
            <div *ngIf="!lastItem" class="container-vertical-padding-half" ></div>
          </div>
          <!-- endregion Groups -->
        </div><!-- /.dnd-sortable-container -->
      </div>
    </div>

  </div><!-- /.card-body -->

</div><!-- /.card -->
<!-- endregion Form card -->
