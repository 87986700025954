import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-password-reset-success-dialog',
  templateUrl: './password-reset-success-dialog.component.html',
  styleUrls: ['./password-reset-success-dialog.component.scss']
})
export class PasswordResetSuccessDialogComponent implements OnInit {

  get titleKey(): string {
    switch (this.data.mode) {
      case PasswordResetMode.REQUEST:
        return 'PASSWORD_RESET_REQUEST_SUCCESS_TITLE';
      case PasswordResetMode.CHANGE:
        return 'PASSWORD_RESET_CHANGE_SUCCESS_TITLE';
    }
  }

  get descriptionKey(): string {
    switch (this.data.mode) {
      case PasswordResetMode.REQUEST:
        return 'PASSWORD_RESET_REQUEST_SUCCESS_DESCRIPTION';
      case PasswordResetMode.CHANGE:
        return 'PASSWORD_RESET_CHANGE_SUCCESS_DESCRIPTION';
    }
  }

  get buttonKey(): string {
    switch (this.data.mode) {
      case PasswordResetMode.REQUEST:
        return 'PASSWORD_RESET_REQUEST_SUCCESS_BACK_BUTTON';
      case PasswordResetMode.CHANGE:
        return 'PASSWORD_RESET_CHANGE_SUCCESS_BACK_BUTTON';
    }
  }

  constructor(public dialogRef: MatDialogRef<PasswordResetSuccessDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: PasswordResetRequestSuccessDialogData) {
  }

  ngOnInit() {
  }

}

export interface PasswordResetRequestSuccessDialogData {
  mode: PasswordResetMode;
}

export enum PasswordResetMode {
  REQUEST,
  CHANGE
}
