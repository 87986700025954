/* eslint-disable */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Set } from 'immutable';
import { QueryFieldModel, SelectUtils, UiConstants } from '../../../util/core-utils';
import { OrderType, QueryResult, } from '../../../lib/util/services';
import { RightModel } from '../../../app.rights';
import { RightResolver, RightService } from '../../../lib/right.service';
import { LoadingHandler } from '../../../lib/loading-handler';
import { Task, TaskService } from '../../../lib/task/task.service';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { TranslateService } from '@ngx-translate/core';
import { StateName } from '../../../app.state-names';
import { TaskDisabledItem, TaskSearchModel } from '../../../util/task-utils';
import { InputMask } from '../../../util/input-masks';
import { TaskSearch, TaskSearchService } from '../../../lib/task/task-search.service';
import { combineLatest, Observable } from 'rxjs';
import { Arrays } from '../../../lib/util/arrays';
import { Models } from '../../../util/model-utils';
import { DisabledEnum, DisabledItem, SearchBooleanItem, searchBooleanItemObjects } from '../../../util/search-utils';
import { EmptyMessage } from '../../../lib/util/messages';
import { DropdownItemType } from '../../../shared/dropdown/dropdown-item/dropdown-item-type';
import { MatDialog } from '@angular/material/dialog';
import { TaskImportDialogComponent } from '../task-import-dialog/task-import-dialog.component';
import { UIRouter } from '@uirouter/angular';

/* eslint-enable */

@Component({
  selector: 'app-new-task-list',
  templateUrl: 'task-list.component.html',
  styleUrls: ['task-list.component.scss']
})
export class TaskListComponent implements OnInit, OnDestroy {

  Task = Task;
  UiConstants = UiConstants;
  DropdownItemType = DropdownItemType;
  searchBooleanItemObjects = searchBooleanItemObjects;

  queryModel: QueryFieldModel<Task.OrderField> = new QueryFieldModel(Task.OrderField.ID, OrderType.DESC);
  taskList: Array<Task.Task> = [];
  showSearch: boolean = false;
  rightModel: RightModel = RightModel.empty();
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  searchModel: TaskSearchModel = new TaskSearchModel();
  taskDisabledItems: DisabledItem[] = [];
  InputMask = InputMask;
  SelectUtils = SelectUtils;

  ngOnInit() {
    this.translateService.get('MENU_FORMS').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.loadRightModels();
    this.loadSearch(() => {
      this.showSearch = !this.searchModel.isEmpty();
      this.initDisabledOptions(DisabledEnum.FALSE);
      this.loadList();
    });
  }

  openImportDialog() {
    TaskImportDialogComponent.openDialog(this.dialog, {
    }, result => {
      if (result && result.taskId) {
        this.uiRouter.stateService.go(StateName.TASK_EDIT, {
          id: result.taskId
        });
      }
    });
  }

  private loadSearch(completion: () => void) {
    const obs: Observable<SearchLoadResult> = combineLatest(
      this.taskSearchService.getSearchData({}),
      (storedSearchData: TaskSearch.SearchDataResult) => {
        const result: SearchLoadResult = {
          storedSearchData: storedSearchData
        };
        return result;
      }
    );
    obs.subscribe(
      (result: SearchLoadResult) => {
        this.postInitSearch(result.storedSearchData);
        completion();
      }
    );
  }

  private initDisabledOptions(initValue: DisabledEnum) {
    this.taskDisabledItems = [];
    const disabledEnums: DisabledEnum[] = [DisabledEnum.NONE, DisabledEnum.FALSE, DisabledEnum.TRUE];
    Arrays.iterateByIndex(disabledEnums, (key) => {
      const item = new DisabledItem();
      item.id = key;
      this.translateService.get('COMMON_VALUE_DISABLED_ENUM_' + key).subscribe(
        (text: string) => {
          item.text = text;
        }
      );
      this.taskDisabledItems.push(item);
      if (!this.searchModel.disabled) {
        if (key === initValue) {
          this.searchModel.disabled = item;
        }
      }
    });
  }

  private postInitSearch(storedSearchData: TaskSearch.SearchDataResult) {
    this.queryModel.itemsPerPage = storedSearchData.searchData.itemsPerPage;
    this.queryModel.currentPage = storedSearchData.searchData.pageNumber;
    this.queryModel.setOrder(storedSearchData.searchData.order);
    this.searchModel.id = storedSearchData.searchData.id;
    this.searchModel.name = storedSearchData.searchData.name;
    this.searchModel.disabled = storedSearchData.searchData.disabled;
    this.searchModel.showOnDashboard = storedSearchData.searchData.showOnDashboard;
    this.searchModel.helpdeskEnabled = storedSearchData.searchData.helpdeskEnabled;
  }

  private getActiveItem(): TaskDisabledItem {
    const item = new TaskDisabledItem();
    item.id = 'ACTIVE';
    item.text = 'TASK_SEARCH_FIELD_DISABLED_ACTIVE';
    return item;
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: Task.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  setDisabled(event: any, task: Task.Task, disabled: boolean) {
    this.taskService.setDisabled({
      taskId: task.taskId,
      disabled: disabled
    }).subscribe(
      (result: EmptyMessage) => {
        LoadingHandler.getInstance().refresh();
        this.loadList();
      },
      (error: any) => {
        this.loadList();
      }
    );
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  private loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();
    const taskIdSet: number[] = [];
    const disabled: boolean | undefined = !this.searchModel.disabled ||
    this.searchModel.disabled.id === DisabledEnum.NONE ?
      undefined : this.searchModel.disabled.id === DisabledEnum.TRUE;
    if (Models.parseNumber(this.searchModel.id)) {
      taskIdSet.push(Models.parseNumber(this.searchModel.id)!);
    }
    this.taskService.query({
      taskIdSet: Set.of(...taskIdSet),
      name: this.searchModel.name,
      disabled: disabled,
      showOnDashboard: this.searchModel.showOnDashboard !== SearchBooleanItem.ALL
        ? this.searchModel.showOnDashboard === SearchBooleanItem.YES
        : undefined,
      helpdeskEnabled: this.searchModel.helpdeskEnabled !== SearchBooleanItem.ALL
        ? this.searchModel.helpdeskEnabled === SearchBooleanItem.YES
        : undefined,
      orders: Set.of(order),
      paging: {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      }
    }).subscribe(
      (result: QueryResult<Task.Task>) => {
        this.taskList = result.items.toArray();
        this.queryModel.currentPage = requestedPage;
        this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
        this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
      }
    );
  }

  getDisabled(): boolean | undefined {
    if (this.searchModel.disabled!.id === DisabledEnum.FALSE) {
      return false;
    }
    else if (this.searchModel.disabled!.id === DisabledEnum.TRUE) {
      return true;
    }
    return undefined;
  }

  onSearchClicked() {
    this.loadList();
  }

  onSearchReset() {
    this.taskSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.showSearch = true;
          this.loadList(1);
        });
      }
    );
  }

  private saveSearch() {
    const request = {
      searchData: {
        itemsPerPage: this.queryModel.itemsPerPage,
        pageNumber: this.queryModel.currentPage,
        order: this.queryModel.getOrder(),
        id: this.searchModel.id,
        name: this.searchModel.name,
        disabled: this.searchModel.disabled,
        showOnDashboard: this.searchModel.showOnDashboard,
        helpdeskEnabled: this.searchModel.helpdeskEnabled
      }
    };
    this.taskSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  ngOnDestroy() {
    this.saveSearch();
  }

  constructor(
    private rightService: RightService,
    private translateService: TranslateService,
    private taskService: TaskService,
    private taskSearchService: TaskSearchService,
    private uiRouter: UIRouter,
    private dialog: MatDialog) {
  }

}

interface SearchLoadResult {
  storedSearchData: TaskSearch.SearchDataResult,
}
