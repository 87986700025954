<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-vertical-padding container-horizontal-padding animated fadeIn">

  <!-- region trigger card -->
  <app-trigger-list #triggerList
                    [scope]="'TRANSPORT'"
                    [triggerService]="getTransportTriggerService()"
                    [expandable]="false"
                    (triggerCreate)="triggerPreselectDialog.showTriggerPreselectDialog()"
                    (triggerEdit)="triggerEditDialog.showTriggerEditDialog($event.type, $event.id, false)"
                    (triggerClone)="triggerEditDialog.showTriggerEditDialog($event.type, $event.id, true)">
  </app-trigger-list>
  <!-- endregion trigger card -->

</div>

<app-trigger-preselect-dialog
    #triggerPreselectDialog
    [scope]="'TRANSPORT'"
    (triggerTypeSelected)="triggerEditDialog.showTriggerEditDialog($event)">
</app-trigger-preselect-dialog>

<app-trigger-edit-dialog
    #triggerEditDialog
    [triggerService]="getTransportTriggerService()"
    [scope]="'TRANSPORT'"
    (onTriggerCreated)="onTriggerCreated()">
</app-trigger-edit-dialog>
