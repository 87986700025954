/* eslint-disable */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Transition, UIRouter } from '@uirouter/angular';
import { StateName } from '../../../../app.state-names';
import { BreadcrumbParent } from '../../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import {
  FieldValidationError
} from '../../../../lib/util/services';
import { MultiselectOptionItem, UiConstants } from '../../../../util/core-utils';
import { ComponentStateResolver } from '../../../../util/component-state/component-state-resolver';
import { EmptyMessage, IdentityMessage } from '../../../../lib/util/messages';
import { GrantedPermissionSetResolver, RightResolver, RightService } from '../../../../lib/right.service';
import { RightModel } from '../../../../app.rights';
import { ProjectService } from '../../../../lib/project/project.service';
import { IconService } from '../../../../lib/task/icon.service';
import { Models } from '../../../../util/model-utils';
import { Strings } from '../../../../lib/util/strings';
import { InputMask } from '../../../../util/input-masks';
import { AppValidators } from '../../../../util/app-validators';
import { Angular2Multiselects } from '../../../../util/multiselect';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';
import { ProjectRecord, ProjectRecordService } from '../../../../lib/project/record/project-record.service';
import { AddressModel } from '../../../../lib/address';
import { NgbDatePickerParserFormatter } from '../../../../util/ngb-datepicker';
import { List, Set } from 'immutable';
import { QueryResult } from '../../../../lib/util/services';
import { Country, CountryService } from '../../../../lib/country.service';
import { ConfigurationService } from '../../../../lib/core-ext/configuration.service';
import BillingType = ProjectRecord.BillingType;
import { UserMultiselectProvider } from '../../../../lib/user/user-multiselect.provider';
import PostalAddressModelType = AddressModel.PostalAddressModelType;
import { FormRecordContainerComponent } from '../../../form/form-record/form-record-container.component';
import { FormRecordContainer } from '../../../form/form-record/form-record-container';
import { FormRecord } from '../../../../lib/form/form-record.service';
import { Subject } from 'rxjs';
import { ConfirmLeaveModalComponent } from '../../../../shared/confirm-leave-modal/confirm-leave-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { OperationRights } from '../../../../app.right-definitions';
import { ProjectRecordRightModel } from '../../../../util/project-utils';
/* eslint-enable */

@Component({
  selector: 'app-project-base',
  templateUrl: './project-record-base.component.html',
  styleUrls: ['./project-record-base.component.scss']
})
export class ProjectRecordBaseComponent implements OnInit, OnDestroy {

  // If you need to access certain classes from HTML, declare them here
  ProjectRecord = ProjectRecord;
  UiConstants = UiConstants;
  InputMask = InputMask;
  PostalAddressType = PostalAddressModelType;

  projectId: number;

  @ViewChild('f', { static: true })
  form: NgForm;

  @ViewChild(FormRecordContainerComponent, { static: true })
  formRecordContainerView: FormRecordContainer.View;
  formVersion: number = 0;

  rights?: ProjectRecordRightModel;

  formGroup: FormGroup;
  formSubmitted: boolean = false;

  // Declare model before use
  model: Model = new Model();
  billingTypeDropdownSettings: Angular2Multiselects.Settings;
  userDropdownSettings: Angular2Multiselects.Settings;
  billingTypes: MultiselectOptionItem<ProjectRecord.BillingType>[]
    = ProjectRecord.billingTypes.map(t => ({id: t.type, itemName: t.stringKey}));
  users: MultiselectOptionItem<number>[] = [];

  // Country items for postal address
  countryItems: List<AddressModel.CountryItem>;

  // Component state resolver, determines the state of the component
  componentState: ComponentStateResolver;

  // Variables used for breadcrumb
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  private fieldErrors: FieldValidationError<ProjectRecord.ValidatedField> =
    FieldValidationError.empty<ProjectRecord.ValidatedField>();

  rightModel: RightModel = RightModel.empty();

  deregisterWarningModal;
  saveButtonClicked: boolean = false;
  navigateOnSubmit: boolean = false;

  constructor(private uiRouter: UIRouter,
              private transition: Transition,
              private datePickerParserFormatter: NgbDatePickerParserFormatter,
              private projectRecordService: ProjectRecordService,
              private projectService: ProjectService,
              private formBuilder: FormBuilder,
              private modalService: BsModalService,
              private userMultiselectProvider: UserMultiselectProvider,
              private rightService: RightService,
              private configService: ConfigurationService,
              private iconService: IconService,
              private countryService: CountryService,
              private translateService: TranslateService) {
    this.projectId = transition.params().projectId;
    this.componentState = new ComponentStateResolver(uiRouter, transition,
      'id',
      {stateName: StateName.PROJECT_RECORD_CREATE, stateHeaderKey: 'PROJECT_RECORD_CREATE'},
      {stateName: StateName.PROJECT_RECORD_EDIT, stateHeaderKey: 'PROJECT_RECORD_EDIT'},
      {stateName: StateName.PROJECT_RECORD_DETAIL, stateHeaderKey: 'PROJECT_RECORD_DETAIL'});
    this.formGroup = this.formBuilder.group({
      name: ['', Validators.required],
      externalId: ['', this.componentState.isEditView() ? Validators.required : null],
      startDate: ['', AppValidators.validateLocalDate],
      endDate: ['', AppValidators.validateLocalDate],
      billingStartDate: ['', AppValidators.validateLocalDate],
      billingEndDate: ['', AppValidators.validateLocalDate],
      assigneeUser: [[], AppValidators.validateEnabledItems]
    });
  }

  ngOnInit() {
    this.loadCountries(() => {
      this.initComponentState();
    });
    this.initDropdown();
    this.initBreadcrumb();
    this.loadRightModels();
    if (this.componentState.isEditable()) {
      this.createWarningModalFunction();
    }
  }

  private createWarningModalFunction() {
    const context = this;
    this.deregisterWarningModal = this.uiRouter.transitionService.onStart({}, function (transition) {
      if (context.saveButtonClicked
        || transition.targetState().name() === StateName.CONNECTION_ERROR
        || transition.targetState().name() === StateName.SERVER_ERROR
        || transition.targetState().name() === StateName.LOGIN) {
        return true;
      }
      else {
        const subject = new Subject<boolean>();
        const modal = context.modalService.show(ConfirmLeaveModalComponent, {backdrop: 'static',  keyboard: false});
        modal.content!.subject = subject;
        return subject.asObservable().toPromise();
      }
    });
  }

  private loadCountries(completion: () => void) {
    this.countryService.query({}).subscribe((result: QueryResult<Country.Country>) => {
      this.countryItems = AddressModel.CountryItem.fromCountryList(result.items);
      completion();
    });
  }

  private initComponentState() {
    // component state contains the id
    this.loadProjectName();
    if (this.componentState.id) {
      this.loadModel();
    }
    else {
      this.loadUsers();
      this.loadForm();
      this.model.postalAddress.loadCountryItems(this.countryItems, this.configService.getDefaultSelectedCountryCode());
    }
  }

  initDropdown() {
    this.billingTypeDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .translate(true)
      .build();
    this.userDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .remoteSearch(true)
      .enableCheckAll(false)
      .build();
  }

  // Called by the init method first
  private loadModel() {
    this.projectRecordService.get({
      projectId: this.projectId,
      id: this.componentState.id!,
      rights: Set.of(OperationRights.PROJECT_RECORD_UPDATE)
    }).subscribe(result => {
      this.model.name = result.name;
      this.breadcrumbSelf = result.name;
      this.model.externalId = result.externalId;
      this.model.amount = Models.numberToString(result.amount);
      this.model.startDate = this.datePickerParserFormatter.fromLocalDate(result.startDate);
      this.model.endDate = this.datePickerParserFormatter.fromLocalDate(result.endDate);
      this.model.billingStartDate = this.datePickerParserFormatter.fromLocalDate(result.billingStartDate);
      this.model.billingEndDate = this.datePickerParserFormatter.fromLocalDate(result.billingEndDate);
      if (result.billingType) {
        const type = this.billingTypes.find(t => t.id === result.billingType)!;
        this.model.billingType.push(type);
      }
      this.model.billingPeriodInMonths = Models.numberToString(result.billingPeriodInMonths);
      this.loadUsers(undefined, result.assigneeUser ? result.assigneeUser.id : undefined);
      this.model.postalAddress.loadCountryItems(this.countryItems, this.configService.getDefaultSelectedCountryCode());
      this.model.postalAddress.load(result.postalAddress);
      this.formVersion = result.formRecord ? result.formRecord.version : 0;
      this.loadForm(result.formRecord);
      this.rights = new ProjectRecordRightModel(GrantedPermissionSetResolver.byGrantedRights(result.grantedRights))
    });
  }

  initBreadcrumb() {
    // Set breadcrumbSelf if createView, otherwise set in loadModel()
    if (this.componentState.isCreateView()) {
      this.translateService.get('PROJECT_RECORD_CREATE').subscribe(
        (result: string) => {
          this.breadcrumbSelf = result;
        }
      );
    }
    this.translateService.get('MENU_NAVBAR_PROJECT_RECORD').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.PROJECT_DASHBOARD});
      }
    );
  }

  private loadProjectName() {
    this.projectService.get({
      id: this.projectId
    }).subscribe(result => {
      this.breadcrumbParents.push({name: result.name, uiSref: StateName.PROJECT_RECORD_LIST, uiParam: {id: this.projectId}});
    });
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  // Get corresponding dictionary key
  getHeadingDictionaryKey(): string {
    return this.componentState.getCurrentHeaderKey();
  }

  hasLocalFieldError(control?: string, error?: string): boolean {
    if (control) {
      if (error) {
        return this.formSubmitted && this.formGroup.controls[control].hasError(error);
      }
      return this.formSubmitted && this.formGroup.controls[control].invalid;
    }
    return this.formSubmitted && (this.formGroup.invalid || !this.form.valid);
  }

  hasFieldError(field: ProjectRecord.ValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: ProjectRecord.ValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: ProjectRecord.ValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  submit() {
    this.formSubmitted = true;
    if (this.hasLocalFieldError() || this.formRecordContainerView.hasLocalFieldError()) {
      return;
    }
    if (this.formRecordContainerView.validateWithInterrupt()) {
      return;
    }
    this.saveButtonClicked = true;
    if (this.componentState.isCreateView()) {
      this.create();
    }
    else if (this.componentState.isEditView()) {
      this.update();
    }
  }

  create() {
    this.projectRecordService.create({
      projectId: this.projectId,
      name: this.model.name,
      externalId: Strings.undefinedOrNonEmpty(this.model.externalId),
      amount: Models.parseNumber(this.model.amount),
      startDate: this.datePickerParserFormatter.toLocalDate(this.model.startDate),
      endDate: this.datePickerParserFormatter.toLocalDate(this.model.endDate),
      billingStartDate: this.datePickerParserFormatter.toLocalDate(this.model.billingStartDate),
      billingEndDate: this.datePickerParserFormatter.toLocalDate(this.model.billingEndDate),
      billingType: this.model.getBillingType(),
      billingPeriodInMonths: Models.parseNumber(this.model.billingPeriodInMonths),
      assigneeUserId: this.model.getAssigneeUserId(),
      postalAddress: this.model.postalAddress.toData(),
      formRecord: {
        fields: this.formRecordContainerView.createModel().fields
      }
    }).subscribe(
      (response: IdentityMessage) => {
        if (this.formRecordContainerView.shouldNotifyAfterCreation()) {
          this.projectRecordService.get({
            projectId: this.projectId,
            id: response.id
          }).subscribe(result => {
            this.formRecordContainerView.afterFormRecordCreation(result.formRecord!.recordId).subscribe(
              next => {
                // do nothing
              },
              error => {
                // do nothing
              },
              () => {
                this.afterSubmit(response.id);
              });
          });
        }
        else {
          this.afterSubmit(response.id);
        }
      },
      (error: any) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withForm(this.form);
        }
      });
  }

  update() {
    this.projectRecordService.update({
      projectId: this.projectId,
      id: this.componentState.id!,
      name: this.model.name,
      externalId: this.model.externalId,
      amount: Models.parseNumber(this.model.amount),
      startDate: this.datePickerParserFormatter.toLocalDate(this.model.startDate),
      endDate: this.datePickerParserFormatter.toLocalDate(this.model.endDate),
      billingStartDate: this.datePickerParserFormatter.toLocalDate(this.model.billingStartDate),
      billingEndDate: this.datePickerParserFormatter.toLocalDate(this.model.billingEndDate),
      billingType: this.model.getBillingType(),
      billingPeriodInMonths: Models.parseNumber(this.model.billingPeriodInMonths),
      assigneeUserId: this.model.getAssigneeUserId(),
      postalAddress: this.model.postalAddress.toData(),
      formRecord: {
        version: this.formVersion,
        fields: this.formRecordContainerView.createModel().fields
      }
    }).subscribe(
      (response: EmptyMessage) => {
        this.afterSubmit();
      },
      (error: any) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withForm(this.form);
        }
      });
  }

  private afterSubmit(id?: number) {
    if (this.navigateOnSubmit) {
      this.uiRouter.stateService.go(StateName.PROJECT_RECORD_LIST, {id: this.projectId});
    }
    else {
      if (id) {
        this.uiRouter.stateService.go(StateName.PROJECT_RECORD_EDIT, {projectId: this.projectId, id: id});
      }
      else {
        this.loadModel();
      }
    }
  }

  back() {
    window.history.back();
  }

  loadUsers(q?: string, id?: number) {
    this.userMultiselectProvider.loadActive(q).subscribe(result => {
      this.users = result;
      if (id) {
        const user = this.users.find(u => u.id === id);
        if (user) {
          this.model.assigneeUser.push(user);
        }
        else {
          this.userMultiselectProvider.getById(id).subscribe(result => {
            this.model.assigneeUser.push(result);
            this.formGroup.controls['assigneeUser'].updateValueAndValidity();
          });
        }
      }
    });
  }

  private loadForm(record?: FormRecord.FormRecord) {
    this.projectService.get({
      id: this.projectId
    }).subscribe(result => {
      this.formRecordContainerView.loadFormRecord({
        configuration: this.configService.getConfiguration(),
        form: result.form!,
        record: record
      });
    });
  }

  getLocalDateString(date: NgbDateStruct | null): string {
    return this.datePickerParserFormatter.toLocalDate(date).toIsoString();
  }

  onSaveButtonClicked(navigate: boolean) {
    this.navigateOnSubmit = navigate;
  }

  ngOnDestroy(): void {
    if (this.componentState.isEditable()) {
      this.deregisterWarningModal();
    }
  }
}

class Model {
  name: string = '';
  externalId: string = '';
  amount: string = '';
  startDate: NgbDateStruct | null = null;
  endDate: NgbDateStruct | null = null;
  billingStartDate: NgbDateStruct | null = null;
  billingEndDate: NgbDateStruct | null = null;
  billingType: MultiselectOptionItem<ProjectRecord.BillingType>[] = [];
  billingPeriodInMonths: string = '';
  assigneeUser: MultiselectOptionItem<number>[] = [];
  postalAddress: AddressModel.PostalAddressModel = new AddressModel.PostalAddressModel();

  getBillingType(): BillingType | undefined {
    return this.billingType.length === 1 ? this.billingType[0].id : undefined;
  }

  getAssigneeUserId(): number | undefined {
    return this.assigneeUser.length === 1 ? this.assigneeUser[0].id : undefined;
  }
}
