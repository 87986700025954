<div class="col-xs-12 col-sm-12 col-md-8 p-0">
  <div class="card">
    <div class="card-header">
      {{'MENU_NAVBAR_REGISTRATION_SETTINGS' | translate}}
    </div>
    <div class="card-body">
      <form class="form-horizontal bordered-row" (ngSubmit)="update()" #f="ngForm" [formGroup]="formGroup">

        <!--region notification template-->
        <div class="form-group row"
             [ngClass]="{ 'has-danger': submitted && hasLocalFieldError('notificationTemplate') }">
          <label class="col-form-label form-control-label col-md-6 detail-title required-field-label">
            {{'REGISTRATION_SETTINGS_NOTIFICATION_TEMPLATE' | translate}}
            <i class="icomoon icomoon-info info-icon"
               popover="{{'REGISTRATION_SETTINGS_NOTIFICATION_TEMPLATE_INFO' | translate}}"
               *ngIf="registrationType !== RegistrationType.HELPDESK"
               triggers="mouseenter:mouseleave" container="body" placement="right"></i>
            <i class="icomoon icomoon-info info-icon"
               popover="{{'REGISTRATION_SETTINGS_HELPDESK_NOTIFICATION_TEMPLATE_INFO' | translate}}"
               *ngIf="registrationType === RegistrationType.HELPDESK"
               triggers="mouseenter:mouseleave" container="body" placement="right"></i>
          </label>
          <div class="col-md-6">
            <label *ngIf="readonly"
                   class="col-form-label form-control-label detail-description">{{getMultiselectDataReadonlyLabel(model.notificationTemplate)}}</label>
            <angular2-multiselect *ngIf="!readonly"
                                  [data]="notificationTemplates" [settings]="multiselectDropdownSettings"
                                  class="form-control"
                                  [(ngModel)]="model.notificationTemplate"
                                  (onRemoteSearch)="loadNotificationTemplates($event.target.value)"
                                  formControlName="notificationTemplate">
            </angular2-multiselect>
            <div class="form-control-feedback"
                 *ngIf="submitted && hasLocalFieldError('notificationTemplate', 'hasDisabledItem')">
              <label>{{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}</label>
            </div>
            <div class="form-control-feedback"
                 *ngIf="submitted && hasLocalFieldError('notificationTemplate', 'required')">
              <label>{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</label>
            </div>
          </div>
        </div>
        <!--endregion notification template-->

        <!--region response template-->
        <div class="form-group row" [ngClass]="{ 'has-danger': submitted && hasLocalFieldError('responseTemplate') }">
          <label class="col-form-label form-control-label col-md-6 detail-title required-field-label">
            {{'REGISTRATION_SETTINGS_RESPONSE_TEMPLATE' | translate}}
            <i class="icomoon icomoon-info info-icon"
               popover="{{'REGISTRATION_SETTINGS_RESPONSE_TEMPLATE_INFO' | translate}}"
               *ngIf="registrationType !== RegistrationType.HELPDESK"
               triggers="mouseenter:mouseleave" container="body" placement="right"></i>
            <i class="icomoon icomoon-info info-icon"
               popover="{{'REGISTRATION_SETTINGS_HELPDESK_RESPONSE_TEMPLATE_INFO' | translate}}"
               *ngIf="registrationType === RegistrationType.HELPDESK"
               triggers="mouseenter:mouseleave" container="body" placement="right"></i>
          </label>
          <div class="col-md-6">
            <label *ngIf="readonly"
                   class="col-form-label form-control-label detail-description">{{getMultiselectDataReadonlyLabel(model.responseTemplate)}}</label>
            <angular2-multiselect *ngIf="!readonly"
                                  [data]="responseTemplates" [settings]="multiselectDropdownSettings"
                                  class="form-control"
                                  [(ngModel)]="model.responseTemplate"
                                  (onRemoteSearch)="loadResponseTemplates($event.target.value)"
                                  formControlName="responseTemplate">
            </angular2-multiselect>
            <div class="form-control-feedback"
                 *ngIf="submitted && hasLocalFieldError('responseTemplate', 'hasDisabledItem')">
              <label>{{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}</label>
            </div>
            <div class="form-control-feedback" *ngIf="submitted && hasLocalFieldError('responseTemplate', 'required')">
              <label>{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</label>
            </div>
          </div>
        </div>
        <!--endregion response template-->

        <!--region notification addresses-->
        <div class="form-group row" [ngClass]="{ 'has-danger': submitted && model.notificationAddresses.length === 0}">
          <div class="col-md-6">
            <label class="col-form-label mw-100 form-control-label required-field-label">
              {{'REGISTRATION_SETTINGS_NOTIFICATION_ADDRESSES'|translate}}
              <i class="icomoon icomoon-info info-icon"
                 popover="{{'REGISTRATION_SETTINGS_NOTIFICATION_ADDRESSES_INFO' | translate}}"
                 triggers="mouseenter:mouseleave" container="body" placement="right"></i>
            </label>
          </div>
          <div class="col-md-6">
            <mat-chip-list #chipList aria-label="Email selection" class="tag-input-chip-list">
              <mat-chip *ngFor="let emailAddress of model.notificationAddresses" [selectable]="false"
                        [removable]="!readonly" (removed)="removeEmailAddress(emailAddress)" disableRipple>
                {{emailAddress}}
                <c-icon *ngIf="!readonly" [name]="'remove'" style="width: 10px" matChipRemove></c-icon>
              </mat-chip>
              <input placeholder="{{'REGISTRATION_SETTINGS_NOTIFICATION_ADDRESSES' | translate}}"
                     [ngClass]="model.notificationAddresses.length > 0 ? 'placeholder-margin-top' : 'placeholder-margin-center'"
                     *ngIf="!readonly"
                     class="tag-input border-0"
                     [matChipInputFor]="chipList"
                     [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                     [matChipInputAddOnBlur]="true"
                     (matChipInputTokenEnd)="addEmailAddress($event)">
            </mat-chip-list>
            <div class="form-control-feedback" *ngIf="submitted && model.notificationAddresses.length === 0">
              <label>{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</label>
            </div>
          </div>
        </div>
        <!--endregion notification addresses-->

        <!--region default role-->
        <div *ngIf="registrationType
                    && (registrationType === RegistrationType.CREATE_USER_SHOPRENTER
                    || registrationType === RegistrationType.CREATE_USER_DEMO)"
             class="form-group row" [ngClass]="{ 'has-danger': submitted && hasLocalFieldError('defaultRole')}">
          <label class="col-form-label form-control-label col-md-6 detail-title required-field-label">
            {{'REGISTRATION_SETTINGS_ROLE' | translate}}
            <i class="icomoon icomoon-info info-icon"
               popover="{{'REGISTRATION_SETTINGS_ROLE_INFO' | translate}}"
               triggers="mouseenter:mouseleave" container="body" placement="right"></i>
          </label>
          <div class="col-md-6">
            <label *ngIf="readonly"
                   class="col-form-label form-control-label detail-description">{{getMultiselectDataReadonlyLabel(model.defaultRole)}}</label>
            <angular2-multiselect *ngIf="!readonly"
                                  [data]="roles" [settings]="singleselectDropdownSettings"
                                  class="form-control"
                                  [(ngModel)]="model.defaultRole"
                                  (onRemoteSearch)="loadRoles($event.target.value)"
                                  formControlName="defaultRole">
            </angular2-multiselect>
            <div class="form-control-feedback"
                 *ngIf="submitted && hasLocalFieldError('defaultRole', 'hasDisabledItem')">
              <label>{{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}</label>
            </div>
            <div class="form-control-feedback" *ngIf="submitted && hasLocalFieldError('defaultRole', 'required')">
              <label>{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</label>
            </div>
          </div>
        </div>
        <!--endregion default role-->

        <!--region default user group-->
        <div *ngIf="registrationType && registrationType === RegistrationType.HELPDESK"
             class="form-group row" [ngClass]="{ 'has-danger': submitted && hasLocalFieldError('defaultUserGroup')}">
          <label class="col-form-label form-control-label col-md-6 detail-title required-field-label">
            {{'REGISTRATION_SETTINGS_USER_GROUP' | translate}}
            <i class="icomoon icomoon-info info-icon"
               popover="{{'REGISTRATION_SETTINGS_USER_GROUP_INFO' | translate}}"
               triggers="mouseenter:mouseleave" container="body" placement="right"></i>
          </label>
          <div class="col-md-6">
            <label *ngIf="readonly"
                   class="col-form-label form-control-label detail-description">{{getMultiselectDataReadonlyLabel(model.defaultUserGroup)}}</label>
            <angular2-multiselect *ngIf="!readonly"
                                  [data]="userGroups" [settings]="singleselectDropdownSettings"
                                  class="form-control"
                                  [(ngModel)]="model.defaultUserGroup"
                                  (onRemoteSearch)="loadUserGroups($event.target.value)"
                                  formControlName="defaultUserGroup">
            </angular2-multiselect>
            <div class="form-control-feedback"
                 *ngIf="submitted && hasLocalFieldError('defaultUserGroup', 'hasDisabledItem')">
              <label>{{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}</label>
            </div>
            <div class="form-control-feedback" *ngIf="submitted && hasLocalFieldError('defaultUserGroup', 'required')">
              <label>{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</label>
            </div>
          </div>
        </div>
        <!--endregion default user group-->

        <!--region usergroup settings-->
        <div *ngIf="registrationType === RegistrationType.CREATE_USER_DEMO && model.userGroupSettings.length > 0">
          <label class="col-form-label form-control-label font-weight-bold">
            {{'REGISTRATION_SETTINGS_USER_GROUP_SETTINGS_TITLE' | translate}}
          </label>
          <div *ngFor="let userGroupSettings of model.userGroupSettings; let i = index">
            <div class="d-flex justify-content-between">
              <label class="col-form-label form-control-label">
                {{'REGISTRATION_SETTINGS_USER_GROUP_SETTINGS' | translate:{index: i + 1} }}
              </label>
              <i class="icomoon icomoon-trash cursor-pointer" (click)="removeUserGroupSettings(i)" *ngIf="!readonly"
                 title="{{ 'COMMON_BUTTON_DELETE' | translate }}"></i>
            </div>

            <!--region roles-->
            <div class="form-group row" [ngClass]="{ 'has-danger': submitted && hasLocalFieldError('roles_' + i) }">
              <label class="col-form-label form-control-label col-md-6 detail-title required-field-label">
                {{'REGISTRATION_SETTINGS_USER_GROUP_ROLES' | translate}}
              </label>
              <div class="col-md-6">
                <label *ngIf="readonly"
                       class="col-form-label form-control-label detail-description">{{getMultiselectDataReadonlyLabel(userGroupSettings.roles)}}</label>
                <angular2-multiselect *ngIf="!readonly"
                                      [data]="roles" [settings]="multiselectDropdownSettings"
                                      class="form-control"
                                      [(ngModel)]="userGroupSettings.roles"
                                      (onRemoteSearch)="loadRoles($event.target.value)"
                                      [formControlName]="'roles_' + i">
                </angular2-multiselect>
                <div class="form-control-feedback"
                     *ngIf="submitted && hasLocalFieldError('roles_' + i, 'hasDisabledItem')">
                  <label>{{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}</label>
                </div>
                <div class="form-control-feedback" *ngIf="submitted && hasLocalFieldError('roles_' + i, 'required')">
                  <label>{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</label>
                </div>
              </div>
            </div>
            <!--endregion roles-->

            <!--region application types-->
            <div class="form-group row"
                 [ngClass]="{ 'has-danger': submitted && hasLocalFieldError('applicationTypes_' + i) }">
              <label class="col-form-label form-control-label col-md-6 detail-title required-field-label">
                {{'REGISTRATION_SETTINGS_USER_GROUP_APPLICATION_TYPES' | translate}}
              </label>
              <div class="col-md-6">
                <label *ngIf="readonly"
                       class="col-form-label form-control-label detail-description">{{getMultiselectDataReadonlyLabel(userGroupSettings.applicationTypes)}}</label>
                <angular2-multiselect *ngIf="!readonly"
                                      [data]="applicationTypes" [settings]="multiselectDropdownSettings"
                                      class="form-control"
                                      [(ngModel)]="userGroupSettings.applicationTypes"
                                      [formControlName]="'applicationTypes_' + i">
                </angular2-multiselect>
                <div class="form-control-feedback"
                     *ngIf="submitted && hasLocalFieldError('applicationTypes_' + i, 'required')">
                  <label>{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</label>
                </div>
              </div>
            </div>
            <!--endregion application types-->

            <!--region name template-->
            <div class="form-group row"
                 [ngClass]="{ 'has-danger': submitted && hasLocalFieldError('nameTemplate_' + i) }">
              <label class="col-form-label form-control-label col-md-6 detail-title required-field-label">
                {{'REGISTRATION_SETTINGS_USER_GROUP_NAME_TEMPLATE' | translate}}
              </label>
              <div class="col-md-6">
                <app-material-tag-input
                  [formControlName]="'nameTemplate_' + i"
                  [(ngModel)]="userGroupSettings.nameTemplate"
                  [placeholderStringKey]="'REGISTRATION_SETTINGS_USER_GROUP_NAME_TEMPLATE'"
                  [autocompleteItems]="UserGroupNameSequence.availableSequenceBlocks"
                  [separatorCharacter]="UserGroupNameSequence.sequenceBlockSeparator"
                  [generatedStringExampleMap]="UserGroupNameSequence.sequenceBlockExampleMap"
                  [separatorKeyCodes]="UserGroupNameSequence.separatorKeysCodes"
                  [shouldGenerateExample]="true"
                  [form]="f"
                  [readonly]="readonly"
                  [required]="true">
                </app-material-tag-input>
                <div class="form-control-feedback"
                     *ngIf="submitted && hasLocalFieldError('nameTemplate_' + i, 'required')">
                  <label>{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</label>
                </div>
              </div>
            </div>
            <!--endregion name template-->

          </div>
        </div>
        <!--endregion usergroup settings-->

        <!--region buttons-->
        <div *ngIf="!readonly" class="pt-1 d-flex justify-content-end"
             [class.justify-content-between]="registrationType === RegistrationType.CREATE_USER_DEMO">
          <input *ngIf="registrationType === RegistrationType.CREATE_USER_DEMO"
                 type="button" class="btn btn-outline-primary" (click)="addUserGroupSettings()"
                 value="{{'REGISTRATION_SETTINGS_ADD_USER_GROUP_SETTINGS'|translate}}"/>
          <div>
            <input type="button" class="btn btn-outline-primary mr-1" (click)="back()"
                   value="{{'COMMON_BUTTON_CANCEL'|translate}}"/>
            <input type="submit" class="btn btn-primary" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
          </div>
        </div>
        <!--endregion buttons-->
      </form>
    </div>
  </div>
</div>
