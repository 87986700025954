<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [self]="breadcrumbSelf"
      [parents]="breadcrumbParents">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="card">

    <div class="card-header">
      {{'ORDER_IMPORT_ERROR_LIST' | translate}}

      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()" title="{{'COMMON_BUTTON_SEARCH' | translate}}"><i
          class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>

    <div class="card-body border-bottom" *ngIf="showSearch">

      <div class="row form-group">

        <div class="col-md-3">
          <label class="search-label">{{'COMMON_COMPANY' | translate}}</label>
          <angular2-multiselect class="form-control"
                                [data]="companies"
                                [(ngModel)]="searchModel.company"
                                (onRemoteSearch)="onCompanySearch($event.target.value)"
                                [settings]="remoteDropdownSettings">
          </angular2-multiselect>
        </div>

        <div class="col-md-3">
          <label class="search-label">{{'COMMON_USER' | translate}}</label>
          <angular2-multiselect class="form-control"
                                [data]="users"
                                [(ngModel)]="searchModel.user"
                                (onRemoteSearch)="onUserSearch($event.target.value)"
                                [settings]="remoteDropdownSettings">
          </angular2-multiselect>
        </div>

        <div class="col-md-3">
          <label class="search-label">{{'ORDER_IMPORT_ERROR_REALM' | translate}}</label>
          <angular2-multiselect class="form-control"
                                [data]="realms"
                                [(ngModel)]="searchModel.realm"
                                [settings]="localDropdownSettings">
          </angular2-multiselect>
        </div>

        <div class="col-md-3">
          <label class="search-label">{{'ORDER_IMPORT_ERROR_STATE' | translate}}</label>
          <angular2-multiselect class="form-control"
                                [data]="states"
                                [(ngModel)]="searchModel.state"
                                [settings]="localDropdownSettings">
          </angular2-multiselect>
        </div>
      </div>

      <div class="row form-group">

        <div class="col-md-3">
          <label class="search-label">{{'ORDER_IMPORT_ERROR_REASON' | translate}}</label>
          <angular2-multiselect class="form-control"
                                [data]="errorReasons"
                                [(ngModel)]="searchModel.errorReason"
                                [settings]="localDropdownSettings">
          </angular2-multiselect>
        </div>

        <div class="col-md-3">
          <label class="search-label">{{'ORDER_IMPORT_ERROR_ORDER_CODE' | translate}}</label>
          <input type="text" class="form-control"
                 placeholder="{{'ORDER_IMPORT_ERROR_ORDER_CODE' | translate}}"
                 maxlength="{{UiConstants.maxInputLongLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.orderCode">
        </div>

      </div>
      <div class="col-md-12 d-flex justify-content-md-end align-items-end px-0">
        <div class="btn-group" role="group">
          <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
            {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
          </button>
          <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
            {{'COMMON_BUTTON_SEARCH' | translate}}
          </button>
        </div>
      </div>
    </div><!-- /.card-body-->
    <div class="card-body">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="OrderImportErrorSearch.OrderFunctions.ID"
                                    [orderType]="queryModel.getOrderType(OrderImportErrorSearch.OrderFunctions.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op
              [text]="('COMMON_COMPANY' | translate) + '/' + ('COMMON_USER' | translate)"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op
              [text]="'ORDER_IMPORT_ERROR_ORDER_CODE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-sorter-no-op
              [text]="'ORDER_IMPORT_ERROR_REALM' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-sorter-no-op
              [text]="'ORDER_IMPORT_ERROR_STATE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-lg-down">
          <app-table-field-sorter [orderField]="OrderImportErrorSearch.OrderFunctions.CREATION_TIME"
                                  [orderType]="queryModel.getOrderType(OrderImportErrorSearch.OrderFunctions.CREATION_TIME)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'ORDER_IMPORT_ERROR_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-sorter-no-op
              [text]="'COMMON_RECIPIENT_NAME' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op
              [text]="'COMMON_ERROR' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-actions">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let orderImportError of orderImportErrorList | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }">
          <td class="responsive-table-column">{{orderImportError.id}}</td>
          <td class="responsive-table-column hidden-sm-down">
            {{orderImportError.companyName}}
            <p class="text-muted mb-0">{{orderImportError.userName}}</p>
          </td>
          <td class="responsive-table-column long text-nowrap">
            {{orderImportError.externalId1}} ({{orderImportError.extraId1}})
            <p class="text-muted mb-0">{{orderImportError.externalId2}}</p>
          </td>
          <td class="responsive-table-column hidden-md-down long">{{orderImportError.partnerNameStringKey | translate}}</td>
          <td class="responsive-table-column hidden-md-down long text-nowrap text-center">
            <i class="icomoon {{orderImportError.importStateObject.iconClass}} icon-large"></i>
            {{orderImportError.importStateObject.stringKey | translate}}
          </td>
          <td class="responsive-table-column hidden-lg-down">{{orderImportError.creationTime.toUtcIsoString() | date: 'short'}}</td>
          <td class="responsive-table-column hidden-lg-down">{{orderImportError.recipientName}}</td>
          <td class="responsive-table-column hidden-sm-down w-20 text-nowrap">
            {{orderImportError.errorReasonStringKey | translate}}
            <p class="text-muted mb-0 text-truncate">{{orderImportError.errorMessage}}</p>
          </td>
          <td class="w-table-actions-double">
            <a class="icons-table-group cursor-pointer" uiSref="Admin.OrderImportErrorDetail"
               [uiParams]="{ id: orderImportError.id }">
              <i class="icomoon icons-table-item icomoon-detail icomoon-detail-table"
                 title="{{'COMMON_BUTTON_DETAILS' | translate}}"></i>
            </a>
            <a *ngIf="orderImportError.importStateObject.state === 'IGNORED'"
               class="icons-table-group cursor-pointer" (click)="deleteDialog.showDialog(orderImportError.id)">
              <i class="icomoon icons-table-item icomoon-reject-manually-orange icomoon-detail-table"
                 title="{{'ORDER_IMPORT_ERROR_REJECT' | translate}}"></i>
            </a>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="OrderImportErrorSearch.OrderFunctions.ID"
                                    [orderType]="queryModel.getOrderType(OrderImportErrorSearch.OrderFunctions.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op
              [text]="('COMMON_COMPANY' | translate) + '/' + ('COMMON_USER' | translate)"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op
              [text]="'ORDER_IMPORT_ERROR_ORDER_CODE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-sorter-no-op
              [text]="'ORDER_IMPORT_ERROR_REALM' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-sorter-no-op
              [text]="'ORDER_IMPORT_ERROR_STATE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-field-sorter [orderField]="OrderImportErrorSearch.OrderFunctions.CREATION_TIME"
                                    [orderType]="queryModel.getOrderType(OrderImportErrorSearch.OrderFunctions.CREATION_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'ORDER_IMPORT_ERROR_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-sorter-no-op
              [text]="'COMMON_RECIPIENT_NAME' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op
              [text]="'COMMON_ERROR' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-actions">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>
  </div>
</div>

<app-confirm-dialog
  #deleteDialog
  (onConfirm)="rejectOrderManually($event)"
  [title]="'ORDER_IMPORT_ERROR_REJECT' | translate"
  [message]="'ORDER_IMPORT_ERROR_REJECT_CONFIRMATION' | translate"
  [alertType]="AlertType.DANGER">
</app-confirm-dialog>
