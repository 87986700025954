<div bsModal [config]="UiConstants.modalConfig" #fieldDialog="bs-modal" (onHide)="onModalHide($event)"
     class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content" *ngIf="visible">
      <div class="modal-header">
        <h4 class="modal-title">{{dialogTitleDictionaryKey | translate}}</h4>
        <button type="button" class="close" (click)="closeDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="card-body p-0">

          <h6 class="mb-1">{{'COMMON_BASIC_DATA' | translate}}</h6>

          <div class="row">

            <!-- region Title -->
            <div class="form-group col-md-6"
                 [ngClass]="{ 'has-danger': hasFieldError(Form.FieldValidatedField.TITLE) || hasLocalFieldError(title)}">
              <label>{{'COMMON_NAME' | translate}}</label>
              <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     [ngClass]="{ 'form-control-danger': hasFieldError(Form.FieldValidatedField.TITLE) || hasLocalFieldError(title) }"
                     [(ngModel)]="fieldModel.title"
                     (ngModelChange)="removeFieldError(Form.FieldValidatedField.TITLE)"
                     (blur)="onTitleBlur()"
                     name="title" id="title" #title="ngModel" required>
              <div class="form-control-feedback" *ngIf="hasFieldError(Form.FieldValidatedField.TITLE)">
                {{getFieldErrorText(Form.FieldValidatedField.TITLE)}}
              </div>
              <div class="form-control-feedback" *ngIf="hasLocalFieldError(title)">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
            <!-- endregion Title -->

            <!-- region field width - DISABLED -->
            <div class="form-group col-md-3">
              <label>{{'FORM_ITEM_COMMON_FIELD_WIDTH' | translate}}</label>
              <select class="form-control" [(ngModel)]="fieldModel.fieldWidthType"
                      id="fieldWidth" name="fieldWidth" #fieldWidth="ngModel"
                      [disabled]="true">
                <option *ngFor="let widthType of fieldWidthTypes" [ngValue]="widthType">{{widthType.textDictionaryKey | translate}}</option>
              </select>
            </div>
            <!-- endregion field width - DISABLED -->

          </div> <!-- ./row end -->

          <div class="row">

            <!-- region Field validation type (admin) -->
            <div class="form-group col-md-3">
              <label>{{'FORM_ITEM_COMMON_ADMIN_FIELD_VALIDATION_TYPE' | translate}}</label>
              <select class="form-control"
                      [(ngModel)]="fieldModel.adminFormFieldValidationType" (change)="onAdminFormFieldValidationTypeChanged()"
                      #adminFormFieldValidationType="ngModel" id="adminFormFieldValidationType" name="adminFormFieldValidationType">
                <option *ngFor="let vt of fieldValidationTypes" [ngValue]="vt">{{getFieldValidationTypeDictionaryKey(vt) | translate}}</option>
              </select>
            </div>
            <!-- endregion Field validation type (admin) -->

            <!-- region Field validation type (mobile) -->
            <div class="form-group col-md-3">
              <label>{{'FORM_ITEM_COMMON_MOBILE_FIELD_VALIDATION_TYPE' | translate}}</label>
              <select class="form-control"
                      [(ngModel)]="fieldModel.mobileFormFieldValidationType" (change)="onMobileFormFieldValidationTypeChanged()"
                      #adminFormFieldValidationType="ngModel" id="mobileFormFieldValidationType" name="mobileFormFieldValidationType">
                <option *ngFor="let vt of fieldValidationTypes" [ngValue]="vt">{{getFieldValidationTypeDictionaryKey(vt) | translate}}</option>
              </select>
            </div>
            <!-- endregion Field validation type (mobile) -->

            <!-- region Field validation type (helpdesk) -->
            <div class="form-group col-md-3">
              <label>{{'FORM_ITEM_COMMON_HELPDESK_FIELD_VALIDATION_TYPE' | translate}}</label>
              <select class="form-control"
                      [(ngModel)]="fieldModel.helpdeskFormFieldValidationType"
                      #helpdeskFormFieldValidationType="ngModel" id="helpdeskFormFieldValidationType" name="helpdeskFormFieldValidationType">
                <option *ngFor="let vt of fieldValidationTypes" [ngValue]="vt">{{getFieldValidationTypeDictionaryKey(vt) | translate}}</option>
              </select>
            </div>
            <!-- endregion Field validation type (helpdesk) -->

          </div> <!-- ./row end -->

          <div class="row">

            <!-- region API export name -->
            <div class="form-group col-md-6" [ngClass]="{ 'has-danger': hasFieldError(Form.FieldValidatedField.API_EXPORT_NAME) || hasLocalFieldError(api_export_name) }">
              <label>{{'FORM_ITEM_COMMON_EXPORT_NAME' | translate}}</label>
              <input type="text" class="form-control" [(ngModel)]="fieldModel.apiExportName" #api_export_name="ngModel"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     [ngClass]="{ 'form-control-danger': hasFieldError(Form.FieldValidatedField.API_EXPORT_NAME) || hasLocalFieldError(api_export_name) }"
                     (ngModelChange)="removeFieldError(Form.FieldValidatedField.API_EXPORT_NAME)"
                     placeholder="{{'FORM_ITEM_COMMON_EXPORT_NAME' | translate}}"
                     name="api_export_name" id="api_export_name"
                     required>
              <div class="form-control-feedback" *ngIf="hasFieldError(Form.FieldValidatedField.API_EXPORT_NAME)">
                {{getFieldErrorText(Form.FieldValidatedField.API_EXPORT_NAME)}}
              </div>
              <div class="form-control-feedback" *ngIf="hasLocalFieldError(api_export_name)">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
            <!-- endregion API export name -->

            <!-- region PDF export name -->
            <div class="form-group col-md-6" [ngClass]="{ 'has-danger': hasFieldError(Form.FieldValidatedField.PDF_EXPORT_NAME) }">
              <label>{{'FORM_ITEM_COMMON_PDF_EXPORT_NAME' | translate}}</label>
              <input type="text" class="form-control" [(ngModel)]="fieldModel.pdfExportName"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     #pdfExportName="ngModel" name="pdfExportName" id="pdfExportName"
                     (ngModelChange)="removeFieldError(Form.FieldValidatedField.PDF_EXPORT_NAME)"
                     placeholder="{{'FORM_ITEM_COMMON_PDF_EXPORT_NAME' | translate}}"
                     [ngClass]="{ 'form-control-danger': hasFieldError(Form.FieldValidatedField.PDF_EXPORT_NAME) }">
              <div class="form-control-feedback" *ngIf="hasFieldError(Form.FieldValidatedField.PDF_EXPORT_NAME)">
                {{getFieldErrorText(Form.FieldValidatedField.PDF_EXPORT_NAME)}}
              </div>
            </div>
            <!-- endregion PDF export name -->

          </div> <!-- ./row end -->

          <div class="row">

            <!-- region Description -->
            <div class="form-group col-md-6">
              <label>{{'COMMON_DESCRIPTION' | translate}}</label>
              <input type="text" class="form-control" [(ngModel)]="fieldModel.description" #description="ngModel"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     placeholder="{{'COMMON_DESCRIPTION' | translate}}"
                     name="description" id="description">
            </div>
            <!-- endregion Description -->

            <!-- region hint -->
            <div class="form-group col-md-6">
              <label>{{'FORM_ITEM_COMMON_HINT' | translate}}</label>
              <textarea rows="1" class="form-control" [(ngModel)]="fieldModel.hint"
                        [ngModelOptions]="{standalone: true}"
                        placeholder="{{'FORM_ITEM_COMMON_HINT' | translate}}"></textarea>
            </div>
            <!-- endregion hint -->

          </div>

          <h6 class="mb-1 mt-1">{{'FORM_ITEM_CREATE_DOCUMENT_EXTRA_DATA' | translate}}</h6>

          <div class="row">

            <!-- region customer type -->
            <div class="form-group col-md-6"
                 [ngClass]="{ 'has-danger': hasFieldError(Form.FieldValidatedField.CUSTOMER_ID) || hasLocalFieldError(customer) }">
              <label>{{'FORM_ITEM_CUSTOMER_CUSTOMER_TYPE' | translate}}</label>
              <angular2-multiselect
                class="form-control"
                [(ngModel)]="fieldModel.customerItem"
                [data]="availableCustomer"
                [settings]="dropdownSettings"
                (ngModelChange)="removeFieldError(Form.FieldValidatedField.CUSTOMER_ID)"
                (onRemoteSearch)="loadCustomerTypes(undefined, $event.target.value)"
                name="customer"
                id="customer"
                #customer="ngModel"
                validateEnabledItems
                required>
              </angular2-multiselect>
              <div class="form-control-feedback" *ngIf="hasFieldError(Form.FieldValidatedField.CUSTOMER_ID)">
                {{getFieldErrorText(Form.FieldValidatedField.CUSTOMER_ID)}}
              </div>
              <div class="form-control-feedback" *ngIf="hasLocalFieldError(customer) && customer.errors['required']">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
              <div class="form-control-feedback" *ngIf="hasLocalFieldError(customer) && customer.errors['hasDisabledItem']">
                {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
              </div>
            </div>
            <!-- endregion customer value -->

            <div class="form-group col-md-3">
              <label>{{'FORM_ITEM_COMMON_MULTISELECT' | translate}}</label>
              <br>
              <label class="switch switch-text switch-info mb-0">
                <input type="checkbox" class="switch-input" [(ngModel)]="fieldModel.multiSelect"
                       #multiSelect="ngModel" id="multiSelect" name="multiSelect">
                <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                <span class="switch-handle"></span>
              </label>
            </div>

            <!-- region show on receipt -->
            <div class="form-group col-md-3">
              <label>{{'FORM_ITEM_COMMON_SHOW_ON_RECEIPT' | translate}}
                <i class="icomoon icomoon-info info-icon" popover="{{'FORM_EDIT_SHOW_ON_RECEIPT_INFO' | translate}}"
                   triggers="mouseenter:mouseleave" container="body" placement="right"></i></label>
              <br>
              <label class="switch switch-text switch-info mb-0">
                <input type="checkbox" class="switch-input" [(ngModel)]="fieldModel.showOnReceipt"
                       #showOnReceipt="ngModel" id="showOnReceipt" name="showOnReceipt">
                <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                <span class="switch-handle"></span>
              </label>
            </div>
            <!-- endregion show on receipt -->

          </div>

          <div class="row">

            <!-- region master data filter type -->
            <div class="form-group col-md-6"
                 [ngClass]="{ 'has-danger': hasLocalFieldError(filterType) }">
              <label>{{'MASTER_DATA_FILTER_TYPE' | translate}}</label>
              <angular2-multiselect
                class="form-control"
                [(ngModel)]="fieldModel.filterTypes"
                [data]="filterTypes"
                [settings]="localDropdownSettings"
                name="filterType"
                id="filterType"
                #filterType="ngModel"
                required>
              </angular2-multiselect>
              <div class="form-control-feedback" *ngIf="hasLocalFieldError(filterType) && filterType.errors['required']">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
            <!-- endregion master data filter type -->
            <div class="form-group col-md-6">
              <label>{{'MASTER_DATA_AUTO_JOIN_OWNER_ON_FINISH' | translate}}</label>
              <br>
              <label class="switch switch-text switch-info mb-0">
                <input type="checkbox" class="switch-input" [(ngModel)]="fieldModel.autoJoinOwnerOnFinish"
                       #autoJoinOwnerOnFinish="ngModel" id="autoJoinOwnerOnFinish" name="autoJoinOwnerOnFinish">
                <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                <span class="switch-handle"></span>
              </label>
            </div>
          </div>


        </div> <!-- ./card-body end -->
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeDialog()">{{'COMMON_BUTTON_CANCEL' | translate}}</button>
        <button type="button" class="btn btn-primary" #functionButton (click)="saveField()">{{'COMMON_BUTTON_SAVE' | translate}}</button>
      </div>

    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

