/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { Order, PagingRequest, QueryResult, ResourceQueryResult, Services } from '../util/services';
import { EmptyMessage, IdentityMessage } from '../util/messages';
import { List, Map, Set } from 'immutable';
import { OffsetDateTime } from '../util/dates';
import { TaskRecordStateMachine } from '../task/task-record-statemachine';
import { CalendarResource, CalendarResourceService } from './calendar-resource.service';
import { TaskRecord, TaskRecordMapper } from '../task/task-record.service';
import { Address, AddressResource } from '../address';
import CalendarEventType = Calendar.CalendarEventType;

/* eslint-enable */

@Injectable()
export class CalendarService implements Calendar.Service {

  query(request: Calendar.QueryRequest): Observable<QueryResult<Calendar.CalendarEvent>> {
    return Observable.create((observer: Observer<QueryResult<Calendar.CalendarEvent>>) => {
      return this.resourceService.query(this.toResourceQueryRequest(request)).subscribe(
        (result: ResourceQueryResult<CalendarResource.CalendarEvent>) => {
          observer.next({
            items: this.toPublicList(result.items),
            pagingResult: result.pagingResult
          });
        });
    });
  }

  timeline(request: Calendar.QueryRequest): Observable<QueryResult<Calendar.CalendarEvent>> {
    return Observable.create((observer: Observer<QueryResult<Calendar.CalendarEvent>>) => {
      return this.resourceService.timeline(this.toResourceQueryRequest(request)).subscribe(
        (result: ResourceQueryResult<CalendarResource.CalendarEvent>) => {
          observer.next({
            items: this.toPublicList(result.items),
            pagingResult: result.pagingResult
          });
        });
    });
  }

  get(request: IdentityMessage): Observable<Calendar.CalendarEvent> {
    return Observable.create((observer: Observer<Calendar.CalendarEvent>) => {
      const resourceRequest: IdentityMessage = {
        id: request.id
      };
      return this.resourceService.get(resourceRequest).subscribe(
        (result: CalendarResource.CalendarEvent) => {
          observer.next(this.toPublic(result));
        });
    });
  }

  createAvailable(request: Calendar.CreateAvailableRequest): Observable<Calendar.CreateAvailableResponse> {
    return Observable.create((observer: Observer<Calendar.CreateAvailableResponse>) => {
      const resourceRequest: CalendarResource.CreateAvailableRequest = {
        start_time: request.startTime.toUtcIsoString(),
        end_time: request.endTime.toUtcIsoString(),
        comment: request.comment
      };
      return this.resourceService.createAvailable(resourceRequest).subscribe(
        (result: CalendarResource.CreateAvailableResponse) => {
          observer.next({
            id: result.id
          });
        });
    });
  }

  updateAvailable(request: Calendar.UpdateAvailableRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: CalendarResource.UpdateAvailableRequest = {
        id: request.id,
        start_time: request.startTime.toUtcIsoString(),
        end_time: request.endTime.toUtcIsoString(),
        comment: request.comment
      };
      return this.resourceService.updateAvailable(resourceRequest).subscribe(
        () => {
          observer.next({});
        });
    });
  }

  deleteAvailable(request: Calendar.DeleteAvailableRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: CalendarResource.DeleteAvailableRequest = {
        id: request.id
      };
      return this.resourceService.deleteAvailable(resourceRequest).subscribe(
        () => {
          observer.next({});
        });
    });
  }

  private toPublicList(resourceList: CalendarResource.CalendarEvent[]): List<Calendar.CalendarEvent> {
    return List.of(...resourceList.map((r) => this.toPublic(r)));
  }

  private toPublic(r: CalendarResource.CalendarEvent): Calendar.CalendarEvent {
    return {
      id: r.id,
      startTime: Services.toOffsetDateTime(r.start_time),
      endTime: Services.toOffsetDateTime(r.end_time),
      type: <CalendarEventType>r.type,
      comment: r.comment,
      taskRecord: this.toPublicTaskRecord(r.task_record),
      projectRecord: this.toPublicProjectRecord(r.project_record),
      owner: this.toPublicOwner(r.owner)
    };
  }

  private toPublicTaskRecord(r?: CalendarResource.CalendarEventTaskRecord): Calendar.CalendarEventTaskRecord | undefined {
    if (r) {
      return {
        id: r.id,
        name: r.name,
        confirmed: r.confirmed,
        state: <TaskRecordStateMachine.State>r.state,
        placeOfConsumption: TaskRecordMapper.toPublicPOC(r.place_of_consumption),
        customerRecord: r.customer_record ? {
          id: r.customer_record.id,
          name: r.customer_record.name,
          postalAddress: AddressResource.Mapper.toPublicPostalAddressOpt(r.customer_record.postal_address),
          phoneNumbers: AddressResource.Mapper.toPublicPhoneNumberList(r.customer_record.phone_numbers)
        } : undefined,
        user: r.user ? {
          id: r.user.id,
          name: r.user.name
        } : undefined,
        userGroup: r.user_group ? {
          id: r.user_group.id,
          name: r.user_group.name
        } : undefined,
        mobileApp: r.mobile_app ? {
          id: r.mobile_app.id,
          name: r.mobile_app.name
        } : undefined
      };
    }
  }

  private toPublicProjectRecord(r?: CalendarResource.CalendarEventProjectRecord): Calendar.CalendarEventProjectRecord | undefined {
    if (r) {
      return {
        projectId: r.project_id,
        id: r.project_record_id,
        name: r.name,
        externalId: r.external_id,
        address: AddressResource.Mapper.toPublicPostalAddressOpt(r.address),
        taskCount: r.task_count
      };
    }
    return undefined;
  }

  private toPublicOwner(r: CalendarResource.CalendarEventOwner): Calendar.CalendarEventOwner {
    return {
      userId: r.user_id,
      mobileAppId: r.mobile_app_id,
      name: r.name,
      color: r.color
    };
  }

  private toResourceQueryRequest(request: Calendar.QueryRequest): CalendarResource.QueryRequest {
    return {
      id: Services.createIdParameter(request.id),
      owner_user_group_id: Services.createIdParameter(request.ownerUserGroupId),
      owner_user_id: Services.createIdParameter(request.ownerUserId),
      owner_mobile_app_id: Services.createIdParameter(request.ownerMobileAppId),
      type: Services.createListParameter(request.type),
      task_record_state: Services.createListParameter(request.taskRecordStates),
      assignee_type: Services.createListParameter(request.assigneeFilter),
      from_time: request.fromTime && request.fromTime.isValid() ? request.fromTime.toUtcIsoString() : undefined,
      to_time: request.toTime && request.toTime.isValid() ? request.toTime.toUtcIsoString() : undefined,
      task_record_task_id: Services.createIdParameter(request.taskRecordTaskId),
      order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
      page_number: request.paging ? request.paging.pageNumber : undefined,
      number_of_items: request.paging ? request.paging.numberOfItems : undefined,
    };
  }

  constructor(
    private resourceService: CalendarResourceService) {
  }
}

export namespace Calendar {

  export interface Service {

    query(request: QueryRequest): Observable<QueryResult<CalendarEvent>>;

    timeline(request: QueryRequest): Observable<QueryResult<CalendarEvent>>;

    get(request: IdentityMessage): Observable<CalendarEvent>;

    createAvailable(request: CreateAvailableRequest): Observable<CreateAvailableResponse>;

    updateAvailable(request: UpdateAvailableRequest): Observable<EmptyMessage>;

    deleteAvailable(request: DeleteAvailableRequest): Observable<EmptyMessage>;

  }

  export interface QueryRequest {
    id?: Set<number>;
    ownerUserGroupId?: Set<number>;
    ownerUserId?: Set<number>;
    ownerMobileAppId?: Set<number>;
    type?: Set<CalendarEventType>
    taskRecordStates?: Set<TaskRecordStateMachine.State>;
    assigneeFilter?: Set<Calendar.CalendarAssigneeType>;
    fromTime?: OffsetDateTime;
    toTime?: OffsetDateTime;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
    taskRecordTaskId?: Set<number>;
  }

  export interface CreateAvailableRequest {
    startTime: OffsetDateTime;
    endTime: OffsetDateTime;
    comment?: string;
  }

  export interface CreateAvailableResponse {
    id: number;
  }

  export interface UpdateAvailableRequest {
    id: number;
    startTime: OffsetDateTime;
    endTime: OffsetDateTime;
    comment?: string;
  }

  export interface DeleteAvailableRequest {
    id: number;
  }

  export interface CalendarEvent {
    id: number;
    startTime: OffsetDateTime;
    endTime: OffsetDateTime;
    type: CalendarEventType;
    comment?: string;
    taskRecord?: CalendarEventTaskRecord;
    projectRecord?: CalendarEventProjectRecord;
    owner: CalendarEventOwner;
  }

  export interface CalendarEventTaskRecord {
    id: number;
    name: string;
    confirmed: boolean;
    state: TaskRecordStateMachine.State;
    placeOfConsumption?: TaskRecord.PlaceOfConsumption;
    customerRecord?: {
      id: number;
      name: string;
      postalAddress?: Address.PostalAddressData;
      phoneNumbers: List<Address.PhoneNumberData>;
    };
    user?: {
      id: number;
      name: string;
    };
    userGroup?: {
      id: number;
      name: string;
    };
    mobileApp?: {
      id: number;
      name: string;
    };
  }

  export interface CalendarEventProjectRecord {
    projectId: number;
    id: number;
    name: string;
    externalId: string;
    address?: Address.PostalAddressData;
    taskCount: number;
  }

  export interface CalendarEventOwner {
    userId?: number;
    mobileAppId?: number;
    name?: string;
    color?: number;
  }

  export type CalendarEventType =
    'TASK_RECORD' | 'AVAILABLE' | 'PROJECT_RECORD';

  export class CalendarEventTypeObject {
    type: CalendarEventType;
    stringKey: string;
  }

  export const calendarEventTypes: CalendarEventTypeObject[] = [
    {type: 'TASK_RECORD', stringKey: 'COMMON_TASK'},
    {type: 'AVAILABLE', stringKey: 'CALENDAR_EVENT_TYPE_AVAILABLE'},
    {type: 'PROJECT_RECORD', stringKey: 'TASK_LABEL_PROJECT'},
  ];

  export type CalendarAssigneeType =
    'USER' | 'GROUP' | 'EMPTY';

  export class CalendarAssigneeTypeObject {
    type: CalendarAssigneeType;
    stringKey: string;
  }

  export const calendarAssigneeTypes: CalendarAssigneeTypeObject[] = [
    {type: 'USER', stringKey: 'CALENDAR_ASSIGNEE_FILTER_USER'},
    {type: 'GROUP', stringKey: 'CALENDAR_ASSIGNEE_FILTER_USER_GROUP'},
    {type: 'EMPTY', stringKey: 'CALENDAR_ASSIGNEE_FILTER_EMPTY'},
  ];

  export enum OrderField {
    START_TIME
  }

}

class Keys {

  private static readonly START_TIME = 'start_time';

  private static readonly orderFieldKeyMap: Map<Calendar.OrderField, string> = Map.of(
    Calendar.OrderField.START_TIME, Keys.START_TIME,
  );

  public static toOrderFieldKey(field: Calendar.OrderField): string {
    return Keys.orderFieldKeyMap.get(field)!;
  }
}
