/* eslint-disable */
import { List, Map } from 'immutable';
import { RightModel } from '../../app.rights';
import { BadgeStyle } from '../../shared/table-badge/badge-style';
import { Task } from './task.service';
import { TaskRecord } from './task-record.service';
import {TaskRecordRightModel} from "../../util/task-record-utils";
import {GrantedPermissionSetResolver} from "../right.service";
/* eslint-enable */

export namespace TaskRecordStateMachine {

  export type State =
    'NEW'
    | 'OPEN'
    | 'IN_PROGRESS'
    | 'PAUSED'
    | 'RESUMED' // technical state
    | 'REJECTED'
    | 'RECALLED'
    | 'SUBMITTED'
    | 'FINISHED'
    | 'NEW_FINISHED' // technical state
    | 'REOPENED' // technical state
    | 'UNSUBMITTED' // technical state
    | 'ARCHIVED'
    | 'PENDING_APPROVAL'
    | 'EXPORTED'
    | 'REVERTED';

  export class StateObject {
    state: State;
    stringKey: string;
    iconClass: string = '';
    stringEditKey?: string = undefined;
    apiName?: string = undefined;
    iconEditClass?: string = undefined;
    stringEditMessage?: string = undefined;
    isPresentable: boolean = true;
    badgeStyle: BadgeStyle;
  }

  export class StateMachine {

    private static readonly finishedStates: State[] = [
      'SUBMITTED',
      'FINISHED',
      'NEW_FINISHED',
      'ARCHIVED',
      'EXPORTED',
      'REVERTED'
    ];

    // TO: [FROM]
    private static readonly availableStateChanges: Map<State, State[]> = Map.of(
      'NEW', ['REJECTED', 'RECALLED'],
      'OPEN', ['NEW', 'REJECTED', 'RECALLED'],
      'RECALLED', ['OPEN', 'IN_PROGRESS'],
      'ARCHIVED', ['FINISHED', 'EXPORTED', 'REJECTED'],
      'FINISHED', ['SUBMITTED'],
      'REVERTED', ['NEW', 'REJECTED', 'RECALLED', 'SUBMITTED', 'FINISHED', 'EXPORTED'],
      'NEW_FINISHED', ['NEW', 'OPEN', 'IN_PROGRESS', 'PENDING_APPROVAL'],
      'IN_PROGRESS', ['OPEN'],
      'RESUMED', ['PAUSED'],
      'PAUSED', ['IN_PROGRESS'],
      'SUBMITTED', ['IN_PROGRESS', 'PENDING_APPROVAL'],
      'REOPENED', ['REVERTED'],
      'UNSUBMITTED', ['SUBMITTED']
    );

    public static getAvailableStatesFromState(fromState: State): State[] {
      const ret: State[] = [];
      this.availableStateChanges.forEach((value, key) => {
        if (value && key && value.includes(fromState)) {
          ret.push(key);
        }
      });
      return ret;
    }

    public static canChangeState(fromState?: State | null, toState?: State | null): boolean {
      if (fromState == null || toState == null) {
        return false;
      }
      const validFromStates: State[] | undefined = this.availableStateChanges.get(toState);
      return validFromStates !== undefined && validFromStates.indexOf(fromState) !== -1;
    }

    public static hasGrantedRightForStateChange(  grantedRights: TaskRecordRightModel, fromState?: State | null, toState?: State | null): boolean {
      if (fromState === null || toState === null) {
        return false;
      }
      switch (toState) {
        case 'NEW':
          return grantedRights.changeStateRestart.hasRight();
        case 'OPEN':
          return grantedRights.changeStateOpen.hasRight();
        case 'RECALLED':
          return grantedRights.changeStateRecall.hasRight();
        case 'ARCHIVED':
          return grantedRights.changeStateArchive.hasRight();
        case 'FINISHED':
          return grantedRights.changeStateValidate.hasRight();
        case 'REVERTED':
          return grantedRights.changeStateRevert.hasRight();
        case 'NEW_FINISHED':
          return grantedRights.changeStateSubmit.hasRight();
        case 'SUBMITTED':
          return grantedRights.changeStateSubmit.hasRight();
        case 'PAUSED':
          return grantedRights.changeStatePause.hasRight();
        case 'RESUMED':
          return grantedRights.changeStatePause.hasRight();
        case 'IN_PROGRESS':
          return grantedRights.changeStateStart.hasRight();
        case 'REOPENED':
          return grantedRights.changeStateReopen.hasRight();
        case 'UNSUBMITTED':
          return grantedRights.changeStateUnsubmit.hasRight();
        default:
          return false;
      }
    }

    public static hasRightForStateChange(rightModel: RightModel, fromState?: State | null, toState?: State | null): boolean {
      if (fromState === null || toState === null) {
        return false;
      }
      switch (toState) {
        case 'NEW':
          return rightModel.taskRecordChangeStateRestart.hasRight();
        case 'OPEN':
          return rightModel.taskRecordChangeStateOpen.hasRight();
        case 'RECALLED':
          return rightModel.taskRecordChangeStateRecall.hasRight();
        case 'ARCHIVED':
          return rightModel.taskRecordChangeStateArchive.hasRight();
        case 'FINISHED':
          return rightModel.taskRecordChangeStateValidate.hasRight();
        case 'REVERTED':
          return rightModel.taskRecordChangeStateRevert.hasRight();
        case 'NEW_FINISHED':
          return rightModel.taskRecordChangeStateSubmit.hasRight();
        case 'SUBMITTED':
          return rightModel.taskRecordChangeStateSubmit.hasRight();
        case 'PAUSED':
          return rightModel.taskRecordChangeStatePause.hasRight();
        case 'RESUMED':
          return rightModel.taskRecordChangeStatePause.hasRight();
        case 'IN_PROGRESS':
          return rightModel.taskRecordChangeStateStart.hasRight();
        case 'REOPENED':
          return rightModel.taskRecordChangeStateReopen.hasRight();
        case 'UNSUBMITTED':
          return rightModel.taskRecordChangeStateUnsubmit.hasRight();
        default:
          return false;
      }
    }

    private static inState(state?: State, ...states: State[]): boolean {
      if (state === undefined) {
        return true;
      }
      return states.indexOf(state) !== -1;
    }

    public static canEdit(task: Task.Task | TaskRecord.TaskData | undefined, state?: TaskRecordStateMachine.State): boolean {
      if (!task) {
        return false;
      }
      return this.inState(state, ...task.adminEditableStates);
    }

    public static canChangeImportance(state?: State): boolean {
      return this.inState(state, 'NEW', 'OPEN', 'PAUSED');
    }

    public static canChangeAssignee(state?: State): boolean {
      return this.inState(state, 'NEW', 'OPEN', 'IN_PROGRESS', 'PAUSED');
    }

    public static canChangeDeadline(state?: State): boolean {
      return this.inState(state, 'NEW', 'OPEN');
    }

    public static canChangeAgreedTime(state?: State): boolean {
      return this.inState(state, 'NEW', 'OPEN', 'IN_PROGRESS');
    }

    public static canChangeEstimatedTime(state?: State): boolean {
      return this.inState(state, 'NEW', 'OPEN');
    }

    public static canChangeTimeSpent(state?: State): boolean {
      return this.inState(state, 'IN_PROGRESS', 'PAUSED');
    }

    public static canChangeConfirmed(state?: State): boolean {
      return this.inState(state, 'NEW', 'OPEN', 'IN_PROGRESS', 'PAUSED');
    }

    public static canChangeCustomerRecord(state?: State): boolean {
      return this.inState(state, 'NEW');
    }

    public static canChangeProject(state?: State): boolean {
      return this.inState(state, 'NEW');
    }

    public static canChangePoc(state?: State): boolean {
      return this.inState(state, 'NEW', 'SUBMITTED');
    }

    public static canChangeContractNumber(state?: State): boolean {
      return this.inState(state, 'NEW', 'SUBMITTED');
    }

    public static canChangeLinkedSurveys(state?: State): boolean {
      return this.inState(state, 'NEW');
    }

    public static canSendInfoEmail(state?: State): boolean {
      return this.inState(state, 'SUBMITTED', 'FINISHED', 'EXPORTED');
    }

    public static canSendClosingEmail(state?: State): boolean {
      return this.inState(state, 'FINISHED', 'EXPORTED');
    }

    static canStartApproval(state: TaskRecordStateMachine.State, task: Task.Task | undefined) {
      if (!task) {
        return false;
      }
      let userAllowed: boolean = true;
      if (task.userSignature === 'OFF'
        || task.userSignature === 'MOBILE_REQUIRED_ADMIN_CLOSABLE'
        || task.userSignature === 'MOBILE_REQUIRED_ADMIN_NOT_CLOSABLE') {
        userAllowed = false;
      }

      let customerAllowed: boolean = true;
      if (task.customerSignature === 'OFF'
        || task.customerSignature === 'MOBILE_REQUIRED_ADMIN_CLOSABLE'
        || task.customerSignature === 'MOBILE_REQUIRED_ADMIN_NOT_CLOSABLE') {
        customerAllowed = false;
      }

      return (userAllowed || customerAllowed) && this.inState(state, 'IN_PROGRESS');
    }

    static canCancelApproval(state: TaskRecordStateMachine.State, task: Task.Task | undefined) {
      if (!task) {
        return false;
      }
      let userAllowed: boolean = true;
      if (task.userSignature === 'OFF'
        || task.userSignature === 'MOBILE_REQUIRED_ADMIN_CLOSABLE'
        || task.userSignature === 'MOBILE_REQUIRED_ADMIN_NOT_CLOSABLE') {
        userAllowed = false;
      }

      let customerAllowed: boolean = true;
      if (task.customerSignature === 'OFF'
        || task.customerSignature === 'MOBILE_REQUIRED_ADMIN_CLOSABLE'
        || task.customerSignature === 'MOBILE_REQUIRED_ADMIN_NOT_CLOSABLE') {
        customerAllowed = false;
      }

      return (userAllowed || customerAllowed) && this.inState(state, 'PENDING_APPROVAL');
    }

    public static showSignature(state: TaskRecordStateMachine.State, task: Task.Task | undefined, type: 'user' | 'customer') {
      if (!task) {
        return false;
      }

      if (type === 'user') {
        switch (task.userSignature) {
          case 'OFF':
            return false;
          case 'MOBILE_REQUIRED_ADMIN_CLOSABLE':
            return this.inState(state, ...this.finishedStates);
          case 'MOBILE_REQUIRED_ADMIN_NOT_CLOSABLE':
            return this.inState(state, ...this.finishedStates);
          case 'MOBILE_REQUIRED_ADMIN_REQUIRED':
            return this.inState(state, ...this.finishedStates, 'PENDING_APPROVAL');
          case 'MOBILE_CLOSABLE_ADMIN_REQUIRED':
            return this.inState(state, ...this.finishedStates, 'PENDING_APPROVAL');
        }
      }
      else {
        switch (task.customerSignature) {
          case 'OFF':
            return false;
          case 'MOBILE_REQUIRED_ADMIN_CLOSABLE':
            return this.inState(state, ...this.finishedStates);
          case 'MOBILE_REQUIRED_ADMIN_NOT_CLOSABLE':
            return this.inState(state, ...this.finishedStates);
          case 'MOBILE_REQUIRED_ADMIN_REQUIRED':
            return this.inState(state, ...this.finishedStates, 'PENDING_APPROVAL');
          case 'MOBILE_CLOSABLE_ADMIN_REQUIRED':
            return this.inState(state, ...this.finishedStates, 'PENDING_APPROVAL');
        }
      }
    }

    static canEditSignature(state: TaskRecordStateMachine.State, task: Task.Task | undefined, type: 'user' | 'customer') {
      if (!task) {
        return false;
      }

      if (type === 'user') {
        switch (task.userSignature) {
          case 'OFF':
          case 'MOBILE_REQUIRED_ADMIN_CLOSABLE':
          case 'MOBILE_REQUIRED_ADMIN_NOT_CLOSABLE':
            return false;
          case 'MOBILE_REQUIRED_ADMIN_REQUIRED':
          case 'MOBILE_CLOSABLE_ADMIN_REQUIRED':
            return this.inState(state, 'PENDING_APPROVAL');
        }
      }
      else {
        switch (task.customerSignature) {
          case 'OFF':
          case 'MOBILE_REQUIRED_ADMIN_CLOSABLE':
          case 'MOBILE_REQUIRED_ADMIN_NOT_CLOSABLE':
            return false;
          case 'MOBILE_REQUIRED_ADMIN_REQUIRED':
          case 'MOBILE_CLOSABLE_ADMIN_REQUIRED':
            return this.inState(state, 'PENDING_APPROVAL');
        }
      }
    }

    static isProcessTaskEndState(state: TaskRecordStateMachine.State): boolean {
      return state === 'FINISHED' || state === 'SUBMITTED' || state === 'REVERTED';
    }
  }

  export const taskRecordStates: Map<State, StateObject> = Map.of(
    'NEW', {
      state: 'NEW',
      stringKey: 'COMMON_VALUE_TASK_STATE_NEW',
      stringEditKey: 'COMMON_VALUE_TASK_STATE_EDIT_RESTART',
      apiName: 'restart',
      iconClass: 'icomoon icomoon-new-state-new',
      iconEditClass: 'icomoon icomoon-state-action-restart',
      stringEditMessage: 'TASK_STATE_CHANGE_NEW_MESSAGE',
      isPresentable: true,
      badgeStyle: BadgeStyle.PRIMARY
    },
    'OPEN', {
      state: 'OPEN',
      stringKey: 'COMMON_VALUE_TASK_STATE_OPEN',
      stringEditKey: 'COMMON_VALUE_TASK_STATE_EDIT_OPEN',
      iconClass: 'icomoon icomoon-new-state-opened',
      apiName: 'open',
      iconEditClass: 'icomoon icomoon-state-action-open-alt',
      stringEditMessage: 'TASK_STATE_CHANGE_OPEN_MESSAGE',
      isPresentable: true,
      badgeStyle: BadgeStyle.SECONDARY
    },
    'IN_PROGRESS', {
      state: 'IN_PROGRESS',
      stringKey: 'COMMON_VALUE_TASK_STATE_IN_PROGRESS',
      stringEditKey: 'COMMON_VALUE_TASK_STATE_EDIT_IN_PROGRESS',
      iconClass: 'icomoon icomoon-new-state-in-progress',
      apiName: 'start',
      iconEditClass: 'icomoon icomoon-start',
      stringEditMessage: 'TASK_STATE_CHANGE_IN_PROGRESS_MESSAGE',
      isPresentable: true,
      badgeStyle: BadgeStyle.SECONDARY
    },
    'PAUSED', {
      state: 'PAUSED',
      stringKey: 'COMMON_VALUE_TASK_STATE_PAUSED',
      stringEditKey: 'COMMON_VALUE_TASK_STATE_EDIT_PAUSED',
      iconClass: 'icomoon icomoon-new-state-in-progress',
      apiName: 'pause',
      iconEditClass: 'icomoon icomoon-pause',
      stringEditMessage: 'TASK_STATE_CHANGE_PAUSED_MESSAGE',
      isPresentable: true,
      badgeStyle: BadgeStyle.PRIMARY
    },
    'RESUMED', {
      state: 'RESUMED',
      stringKey: 'COMMON_VALUE_TASK_STATE_IN_PROGRESSX',
      stringEditKey: 'COMMON_VALUE_TASK_STATE_EDIT_RESUME',
      iconClass: 'icomoon icomoon-new-state-in-progress',
      apiName: 'resume',
      iconEditClass: 'icomoon icomoon-start',
      stringEditMessage: 'TASK_STATE_CHANGE_RESUME_MESSAGE',
      isPresentable: false,
      badgeStyle: BadgeStyle.SECONDARY
    },
    'REJECTED', {
      state: 'REJECTED',
      stringKey: 'COMMON_VALUE_TASK_STATE_REJECTED',
      iconClass: 'icomoon icomoon-new-state-rejected',
      isPresentable: true,
      badgeStyle: BadgeStyle.WARNING
    },
    'RECALLED', {
      state: 'RECALLED',
      stringKey: 'COMMON_VALUE_TASK_STATE_RECALLED',
      stringEditKey: 'COMMON_VALUE_TASK_STATE_EDIT_RECALLED',
      apiName: 'recall',
      iconClass: 'icomoon icomoon-new-state-recalled',
      iconEditClass: 'icomoon icomoon-state-action-recall',
      stringEditMessage: 'TASK_STATE_CHANGE_RECALLED_MESSAGE',
      isPresentable: true,
      badgeStyle: BadgeStyle.WARNING
    },
    'ARCHIVED', {
      state: 'ARCHIVED',
      stringKey: 'COMMON_VALUE_TASK_STATE_ARCHIVED',
      stringEditKey: 'COMMON_VALUE_TASK_STATE_EDIT_ARCHIVED',
      apiName: 'archive',
      iconClass: 'icomoon icomoon-new-state-archived',
      iconEditClass: 'icomoon icomoon-state-action-archive-alt',
      stringEditMessage: 'TASK_STATE_CHANGE_ARCHIVED_MESSAGE',
      isPresentable: true,
      badgeStyle: BadgeStyle.LIGHT_GRAY
    },
    'SUBMITTED', {
      state: 'SUBMITTED',
      stringKey: 'COMMON_VALUE_TASK_STATE_SUBMITTED',
      stringEditKey: 'COMMON_VALUE_TASK_STATE_EDIT_SUBMITTED',
      iconClass: 'icomoon icomoon-new-state-submitted',
      apiName: 'submit',
      iconEditClass: 'icomoon icomoon-new-state-submitted',
      stringEditMessage: 'TASK_STATE_CHANGE_SUBMITTED_MESSAGE',
      isPresentable: true,
      badgeStyle: BadgeStyle.SECONDARY
    },
    'FINISHED', {
      state: 'FINISHED',
      stringKey: 'COMMON_VALUE_TASK_STATE_FINISHED',
      stringEditKey: 'COMMON_VALUE_TASK_STATE_EDIT_FINISHED',
      apiName: 'validate',
      iconClass: 'icomoon icomoon-new-state-finished',
      iconEditClass: 'icomoon icomoon-state-action-validate',
      stringEditMessage: 'TASK_STATE_CHANGE_FINISHED_MESSAGE',
      isPresentable: true,
      badgeStyle: BadgeStyle.SUCCESS
    },
    'NEW_FINISHED', {
      state: 'NEW_FINISHED',
      stringKey: 'COMMON_VALUE_TASK_STATE_FINISHEDX',
      stringEditKey: 'COMMON_VALUE_TASK_STATE_EDIT_NEW_FINISHED',
      apiName: 'finish',
      iconClass: 'icomoon icomoon-new-state-finished',
      iconEditClass: 'icomoon icomoon-state-action-finish',
      stringEditMessage: 'TASK_STATE_CHANGE_NEW_FINISHED_MESSAGE',
      isPresentable: false,
      badgeStyle: BadgeStyle.SUCCESS
    },
    'PENDING_APPROVAL', {
      state: 'PENDING_APPROVAL',
      stringKey: 'COMMON_VALUE_TASK_STATE_PENDING_APPROVAL',
      iconClass: 'icomoon icomoon-new-state-pending-approval',
      isPresentable: true,
      badgeStyle: BadgeStyle.SECONDARY
    },
    'REVERTED', {
      state: 'REVERTED',
      stringKey: 'COMMON_VALUE_TASK_STATE_REVERTED',
      iconClass: 'icomoon icomoon-new-state-invalidated',
      iconEditClass: 'icomoon icomoon-trash',
      stringEditKey: 'COMMON_VALUE_TASK_STATE_EDIT_REVERTED',
      apiName: 'revert',
      stringEditMessage: 'TASK_STATE_CHANGE_REVERTED_MESSAGE',
      isPresentable: true,
      badgeStyle: BadgeStyle.DANGER
    },
    'EXPORTED', {
      state: 'EXPORTED',
      stringKey: 'COMMON_VALUE_TASK_STATE_EXPORTED',
      iconClass: 'icomoon icomoon-new-state-exported',
      isPresentable: true,
      badgeStyle: BadgeStyle.SUCCESS
    },
    'REOPENED', {
      state: 'REOPENED',
      stringKey: 'COMMON_VALUE_TASK_STATE_REOPEN',
      stringEditKey: 'COMMON_VALUE_TASK_STATE_EDIT_REOPEN',
      apiName: 'reopen',
      iconClass: 'icomoon icomoon-new-state-new',
      iconEditClass: 'icomoon icomoon-state-action-restart',
      stringEditMessage: 'TASK_STATE_CHANGE_REOPEN_MESSAGE',
      isPresentable: false,
      badgeStyle: BadgeStyle.PRIMARY
    },
    'UNSUBMITTED', {
      state: 'UNSUBMITTED',
      stringKey: 'COMMON_VALUE_TASK_STATE_UNSUBMITTED',
      stringEditKey: 'COMMON_VALUE_TASK_STATE_EDIT_UNSUBMIT',
      apiName: 'unsubmit',
      iconClass: 'icomoon icomoon-new-state-recalled',
      iconEditClass: 'icomoon icomoon-state-action-recall',
      stringEditMessage: 'TASK_STATE_CHANGE_UNSUBMIT_MESSAGE',
      isPresentable: false,
      badgeStyle: BadgeStyle.WARNING
    },
  );

  export function getOrderedStates(presentableOnly: boolean): List<StateObject> {
    const taskStateOrder: State[] = ['NEW', 'OPEN', 'IN_PROGRESS', 'RESUMED', 'PAUSED', 'PENDING_APPROVAL', 'SUBMITTED',
      'FINISHED', 'NEW_FINISHED', 'UNSUBMITTED', 'RECALLED', 'REJECTED', 'EXPORTED', 'ARCHIVED', 'REVERTED', 'REOPENED'];
    return List.of(...taskRecordStates.slice()
      .filter((stateObject: StateObject) => {
        return presentableOnly ? stateObject.isPresentable : true;
      })
      .sort((a, b) => {
        return taskStateOrder.indexOf(a.state) - taskStateOrder.indexOf(b.state);
      }).toArray());
  }
}
