import { Query } from '../field';

// Each class should be an interface, but it is easier to define the constant fields in the constructors.
// For now we have only one query language so it is not a problem.
// Always use the public Query namespace for variable definitions so we will be able to introduce other language easily.

export class FormRecord {

  private readonly _prefix: string;

  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this._prefix = prefix;
  }

  fieldRecordById(id: number): FormFieldRecord {
    return new FormFieldRecord(this._prefix + 'fields.by_id.' + id + '.');
  }

  fieldRecordByApiName(name: string): FormFieldRecord {
    return new FormFieldRecord(this._prefix + 'fields.by_api_name.' + name + '.');
  }

}

export class FormFieldRecord {

  private primitivePrefix: string;

  constructor(private readonly prefix: string) {
    this.primitivePrefix = prefix.substring(0, prefix.length - 1);
  }

  castToField<T extends Query.Field>(constructorFn: new (field: string) => T): T {
    return new constructorFn(this.primitivePrefix);
  }

  castToObject<T>(constructorFn: new (field: string) => T): T {
    return new constructorFn(this.prefix);
  }

}
