<div bsModal [config]="UiConstants.modalConfig" #imageViewerWindow="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
     aria-hidden="true">
  <!--lightbox container-->
  <div>
    <div class="image-lightbox">
      <!--close button-->
      <span class="close cursor" (click)="closeImageViewerWindow()">&times;</span>
      <!--image to display-->
      <img src="{{ getAttachedImageUrl() }}" alt="">
      <!--navigation arrows-->
      <a class="prev" (click)="attachmentView.onPreviousClicked()">&#10094;</a>
      <a class="next" (click)="attachmentView.onNextClicked()">&#10095;</a>
    </div>
  </div>
  <!--lightbox container end-->
</div>
