<div class="modal-content">
  <div class="modal-header">
    <p class="modal-title"><strong>{{'WORKFLOW_EDIT_TASKS_DIALOG_TITLE' | translate}}</strong></p>
    <button type="button" class="close" (click)="closeDialog()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <mat-dialog-content id="modalBody">
    <div class="row m-0">
      <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 p-0" *ngFor="let task of tasks">
        <div class="create-card card card-hover-border cursor-pointer fixed-card-height"
             (click)="closeDialog(task)">
          <div class="card-body d-flex justify-content-start align-items-center">
            <div class="h1 float-left mb-0 d-flex align-items-center">
              <app-type-icon [icon]="task.icon" class="mr-1" big="true"></app-type-icon>
            </div>
            <div>
              <div class="h4 task-name">{{task.name}}</div>
              <small class="text-muted task-id">{{task.taskId}}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>
