export abstract class Column<T> {

  items: T[] = [];

  protected currentPage = 1;
  protected maxPages = 10;
  private _dragEnabledTo: string[] = [];

  public get dragEnabledTo(): string[] {
    return this._dragEnabledTo;
  }

  public set dragEnabledTo(value: string[]) {
    this._dragEnabledTo = value;
  }

  abstract loadMore(): void;
  abstract loadItems(): void;
  abstract reloadItems(): void;
  abstract canLoadMore(): boolean;

  constructor(public stringKey: string, public id: string) {
  }
}
