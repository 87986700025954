/* eslint-disable */
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { ResourceQueryResult } from '../util/services';
import { ShippingDemandResource } from '../shipping-demand/shipping-demand-resource.service';
import { ShippingItem } from './shipping-item.service';
/* eslint-enable */

@Injectable()
export class ShippingItemResourceService extends BaseHttpService {

  query(request: ShippingItemResource.QueryRequest): Observable<ResourceQueryResult<ShippingItemResource.ShippingItem>> {
    return this.http.get<ResourceQueryResult<ShippingItemResource.ShippingItem>>(this.url, this.parseParams(request));
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/shipping-items/');
  }

}

export namespace ShippingItemResource {

  import ItemSize = ShippingDemandResource.ItemSize;
  import Label = ShippingItem.Label;

  export interface QueryRequest {
    demand_id?: number;
  }

  export interface ShippingItem {
    id: number;
    external_id: string;
    demand_id: number;
    shipment_id?: number;
    name: string;
    category?: string;
    product_code?: string;
    amount: Amount;
    box?: Label;
    label?: Label;
    weight_in_gram?: number;
    price?: Price;
    outtaken: boolean;
    comment?: string;
    item_size?: ItemSize;
  }

  export interface Amount {
    value: number;
    unit: string;
  }

  export interface Price {
    amount: string;
    currency_code: string;
  }

}
