<h2 class="mb-2">{{'REGISTER_COMPONENT_TITLE' | translate}}</h2>
<p>{{'HELPDESK_REGISTRATION_CARD_SUBTITLE' | translate:{environment: environmentName} }}</p>
<div *ngIf="model.globalError && model.globalError['status'] === 404" class="alert alert-danger mt-1" role="alert">
  {{'HELPDESK_REGISTRATION_TOKEN_EXPIRED' | translate}}
</div>
<form class="form-horizontal bordered-row" (ngSubmit)="f.form.valid && onSubmit.emit()" #f="ngForm">
  <div class="form-group row mb-1">
    <div class="col-12">
      <label class="mw-100 col-form-label form-control-label">
        {{'HELPDESK_REGISTRATION_EMAIL' | translate}}
      </label>
    </div>
    <div class="col-12 input-group">
      <input
        class="form-control"
        type="text"
        [value]="model.emailAddress"
        disabled>
    </div>
  </div>
  <div class="form-group row mb-1">
    <div class="col-12">
      <label class="mw-100 col-form-label form-control-label">
        {{'HELPDESK_REGISTRATION_NAME' | translate}}
      </label>
    </div>
    <div class="col-12 input-group">
      <input
        class="form-control"
        type="text"
        [value]="model.personName"
        disabled>
    </div>
  </div>
  <div class="form-group row mb-1">
    <div class="col-12">
      <label class="mw-100 col-form-label form-control-label">
        {{'HELPDESK_REGISTRATION_CUSTOMER_RECORD' | translate}}
      </label>
    </div>
    <div class="col-12 input-group">
      <input
        class="form-control"
        type="text"
        [value]="model.customerRecord"
        disabled>
    </div>
  </div>
  <div class="form-group row mb-1" [ngClass]="{ 'has-danger': f.submitted && !password.valid }">
    <div class="col-12">
      <label class="mw-100 col-form-label form-control-label required-field-label">
        {{'LOGIN_COMPONENT_PASSWORD' | translate}}
      </label>
    </div>
    <div class="col-12">
      <div class="input-group">
        <input class="form-control"
               type="{{passwordVisible ? 'text' : 'password'}}"
               placeholder="{{'LOGIN_COMPONENT_PASSWORD' | translate}}"
               [(ngModel)]="model.password"
               [ngClass]="{ 'form-control-danger': f.submitted && !password.valid }"
               (ngModelChange)="removeFieldError(model.fieldErrors.password)"
               maxlength="{{getTextMaximumLength()}}"
               id="password" name="password" #password="ngModel" required>
        <span (click)="togglePasswordVisibility()"
              class="input-group-addon-gray icomoon icomoon-enable cursor-pointer font-2xl"></span>
      </div>
      <div *ngIf="f.submitted && !password.valid"
           class="form-control-feedback">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
      <div class="form-control-feedback col-12"
           *ngIf="model.fieldErrors.password">{{model.fieldErrors.password.text}}</div>
    </div>
  </div>
  <div class="form-group row mb-1" [ngClass]="{ 'has-danger': f.submitted && !confirmPassword.valid }">
    <div class="col-12">
      <label class="mw-100 col-form-label form-control-label required-field-label">
        {{'LOGIN_COMPONENT_CONFIRM_PASSWORD' | translate}}
      </label>
    </div>
    <div class="col-12">
      <div class="input-group">
        <input class="form-control"
               type="{{passwordVisible ? 'text' : 'password'}}"
               placeholder="{{'LOGIN_COMPONENT_CONFIRM_PASSWORD' | translate}}"
               [(ngModel)]="model.confirmPassword"
               [ngClass]="{ 'form-control-danger': f.submitted && !confirmPassword.valid }"
               id="confirmPassword" name="confirmPassword" #confirmPassword="ngModel"
               maxlength="{{getTextMaximumLength()}}"
               required validateMatchingPassword>
        <span (click)="togglePasswordVisibility()"
              class="input-group-addon-gray icomoon icomoon-enable cursor-pointer font-2xl"></span>
      </div>
      <div *ngIf="f.submitted && confirmPassword.errors && confirmPassword.errors['required']"
           class="form-control-feedback">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
      <div *ngIf="f.submitted && confirmPassword.errors && confirmPassword.errors['no_match']"
           class="form-control-feedback">
        {{'COMMON_VALIDATION_MESSAGE_PASSWORD_MATCH' | translate}}
      </div>
    </div>
  </div>
  <div class="form-group row m-0">
    <button type="submit" class="col-12 btn btn-primary btn-ladda px-2 mb-1" data-style="zoom-in"
            [ladda]="model.inProgress" [disabled]="model.inProgress">
      {{'REGISTER_COMPONENT_TITLE' | translate}}
    </button>
    <small class="col-12 text-center"
           [innerHTML]="'REGISTER_RECAPTCHA_DISCLAIMER' | translate"></small>
    <div class="col-12 text-center">
      {{'REGISTER_ALREADY_HAVE_AN_ACCOUNT' | translate}}
      <button type="button" class="btn btn-link px-0 cursor-pointer"
              (click)="toLogin.emit()">{{'LOGIN_COMPONENT_TITLE' | translate}}</button>
    </div>
  </div>
</form>
