
import { map } from 'rxjs/operators';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { Form } from '../lib/form/form.service';
import { Models } from './model-utils';
import { ImmutableOptionItem, ImmutableUserItem } from './core-utils';
import { TranslateService } from '@ngx-translate/core';
import { Multiselect } from './multiselect-helper';
import { StringKey } from '../app.string-keys';
import { Set } from 'immutable';
import { FieldDataTypeSelectors } from './form/form-utils';

export namespace MultiselectForm {

  export class ImmutableFormFieldItem extends ImmutableOptionItem<number> implements Multiselect.Item {

    public static unknown(id: number, placeholderText: Observable<string>): ImmutableFormFieldItem {
      const disabled = false;
      const unknown = true;
      return new ImmutableFormFieldItem(id, placeholderText, undefined, disabled, unknown);
    }

    public static fromField(field: Form.Field, group: Form.Group, translateService: TranslateService): ImmutableFormFieldItem {
      const localizedNameObs = of(Models.optToString(field.title));
      const subtitleObs = new ReplaySubject<string>();
      translateService.get(FieldDataTypeSelectors.getFormFieldNameKey(field.dataTypeSelector)).subscribe(result => {
        subtitleObs.next(result + ' - ' + group.title);
      });
      const unknown = false;
      return new ImmutableFormFieldItem(field.fieldId, localizedNameObs, subtitleObs, field.disabled, unknown);
    }

    public static lazy(id: number): ImmutableFormFieldItem {
      const localizedNameObs = of('');
      const subtitleObs = of('');
      const unknown = false;
      const disabled = false;
      return new ImmutableFormFieldItem(id, localizedNameObs, subtitleObs, disabled, unknown);
    }

  }

  export function createUnknownImmutableUserItemFactory(translateService: TranslateService):
    Multiselect.UnknownItemFactory<ImmutableUserItem> {
    return new UnknownImmutableFormFieldItemFactory(translateService);
  }

  class UnknownImmutableFormFieldItemFactory implements Multiselect.UnknownItemFactory<ImmutableFormFieldItem> {

    constructor(private readonly translateService: TranslateService) {
    }

    createUnknownItem(id: number): ImmutableFormFieldItem {
      const placeholder = this.translateService.get(StringKey.MULTISELECT_PLACEHOLDER_UNKNOWN_ITEM, {
        'id': id
      });
      return ImmutableFormFieldItem.unknown(id, placeholder);
    }

  }

  export function topActiveItem$(form: Form.Form, predicate: (field: Form.Field) => boolean, translateService: TranslateService):
      Multiselect.SearchFunction<ImmutableFormFieldItem> {
    return (searchValue?: string) => of(form).pipe(map(result =>
      result.groups.map(item => ({fields: item!.fields.map(f => ({group: item!, field: f}))}))
        .flatMap(item => item!.fields)
        .filter(item => predicate(item!.field!))
        .filter(item => !item!.field!.disabled)
        .filter(item => searchValue === undefined
          ? true
          : item!.field!.title.toUpperCase().indexOf(searchValue.toUpperCase()) !== -1)
        .sort((left, right) => left.field!.title.localeCompare(right.field!.title))
        .map(item => MultiselectForm.ImmutableFormFieldItem.fromField(item!.field!, item!.group!, translateService))
        .toList()
    ));
  }

  export function searchItem$(form: Form.Form, predicate: (field: Form.Field) => boolean, translateService: TranslateService):
      Multiselect.IdSetFunction<ImmutableFormFieldItem> {
    return (ids?: Set<number>) => of(form).pipe(map(result =>
      result.groups.map(item => ({fields: item!.fields.map(f => ({group: item!, field: f}))}))
        .flatMap(item => item!.fields)
        .filter(item => predicate(item!.field!))
        .filter(item => ids === undefined
          ? true
          : ids.contains(item!.field!.fieldId))
        .sort((left, right) => left.field!.title.localeCompare(right.field!.title))
        .map(item => MultiselectForm.ImmutableFormFieldItem.fromField(item!.field!, item!.group!, translateService))
        .toList()
    ));
  }

}
