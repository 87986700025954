<div [ngClass]="{'container-left-margin': profilePicture}">
  <div class="d-flex justify-content-between main-data-container">
    <div class="d-flex align-items-center">
      <i *ngIf="getTaskRecordStateObject(model.cluster.taskRecord!.state)"
         class="icomoon {{getTaskRecordStateObject(model.cluster.taskRecord!.state).iconClass}} state-icon">
      </i>
      <div class="d-flex flex-column">
        <label class="task-record-name">{{model.cluster.taskRecord!.name}}</label>
        <label *ngIf="getTaskRecordStateObject(model.cluster.taskRecord!.state)"
               class="state-name">
          {{getTaskRecordStateObject(model.cluster.taskRecord!.state).stringKey | translate}}
        </label>
      </div>
    </div>
    <div class="task-record-detail-icon" (click)="onTaskRecordOpen()">
      <i class="icomoon icomoon-service-info"></i>
    </div>
  </div>
  <div *ngIf="model.cluster.taskRecord!.customerRecord" class="data-container">
    <label>{{'TASK_RECORD_MAP_INFO_WINDOW_CUSTOMER_RECORD' | translate}}</label>
    <a (click)="onCustomerRecordOpen()">
      {{model.cluster.taskRecord!.customerRecord.name}}
    </a>
  </div>
  <div *ngIf="model.cluster.taskRecord!.placeOfConsumption" class="data-container">
    <label>{{'TASK_RECORD_MAP_INFO_WINDOW_PLACE_OF_CONSUMPTION' | translate}}</label>
    <p>
      {{displayPostalAddress(model.cluster.taskRecord!)}}
    </p>
  </div>
  <div *ngIf="model.cluster.taskRecord!.assignee && model.cluster.taskRecord!.assignee!.userData" class="data-container">
    <label>{{'TASK_RECORD_MAP_INFO_WINDOW_ASSIGNEE' | translate}}</label>
    <p>
      {{model.cluster.taskRecord!.assignee!.userData.personName}}
    </p>
  </div>
  <div class="row m-0">
    <div *ngIf="model.cluster.taskRecord!.finalizationTime.isValid()" class="data-container col-md-6 p-0">
      <label>{{'TASK_RECORD_MAP_INFO_WINDOW_FINALIZATION_TIME' | translate}}</label>
      <p>
        {{model.cluster.taskRecord!.finalizationTime.toUtcIsoString() | date:'short'}}
      </p>
    </div>
    <div *ngIf="model.cluster.taskRecord!.deadline && model.cluster.taskRecord!.deadline!.isValid()" class="data-container col-md-6 p-0">
      <label>{{'TASK_RECORD_MAP_INFO_WINDOW_DEADLINE' | translate}}</label>
      <p>
        {{model.cluster.taskRecord!.deadline!.toUtcIsoString() | date:'short'}}
      </p>
    </div>
  </div>
  <img class="profile-picture"
       [src]="sanitizer.bypassSecurityTrustUrl(profilePicture)"
       *ngIf="profilePicture">
</div>
