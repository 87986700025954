<ul #tabListContainer
    (domChange)="onTabListContainerDomChange($event)"
    class="nav d-flex flex-wrap" [ngClass]="classMap"
    (click)="$event.preventDefault()"
    [attr.aria-label]="ariaLabel"
    role="tablist">
  <li *ngFor="let tabz of tabs; let i = index" [ngClass]="['nav-item', tabz.customClass || '']" class="text-nowrap"
      [class.active]="tabz.active" [class.disabled]="tabz.disabled" (keydown)="keyNavActions($event, i)" [id]="'tab-' + i">
    <a href="javascript:void(0);" class="nav-link" role="tab"
       [attr.aria-controls]="tabz.id ? tabz.id : ''"
       [attr.aria-selected]="!!tabz.active"
       [attr.id]="tabz.id ? tabz.id + '-link' : ''"
       [class.active]="tabz.active" [class.disabled]="tabz.disabled"
       (click)="tabz.active = true">
      <span [ngTransclude]="tabz.headingRef">{{ tabz.heading }}</span>
      <span *ngIf="tabz.removable" (click)="$event.preventDefault(); removeTab(tabz);" class="bs-remove-tab"> &#10060;</span>
    </a>
  </li>
  <li [ngClass]="['nav-item']" class="text-nowrap nav-item dropdown" [ngStyle]="{'opacity': hiddenTabCount > 0 ? '1' : '0', 'height': hiddenTabCount > 0 ? 'auto' : '0'}"
      #tabDropdown dropdown placement="bottom right">
    <a onclick="return false"
       href="javascript:void(0);"
       class="nav-link dropdown-toggle"
       role="tab"
       [class.active]="isHiddenTabActive()"
       dropdownToggle data-toggle="dropdown">
      <span>{{ 'COMMON_MORE' | translate }}</span>
    </a>
    <div class="dropdown-menu dropdown-menu-right cursor-pointer" *dropdownMenu aria-labelledby="simple-dropdown">
      <ng-container *ngFor="let tabz of tabs; let i = index;">
        <div class="d-flex dropdown-item"
             [class.pointer-events-none]="tabz.disabled"
             [class.tab-dropdown-disabled]="tabz.disabled"
             [class.tab-dropdown-active]="tabz.active"
             *ngIf="tabs.length - hiddenTabCount <= i" (click)="tabz.active = true">
            <div class="text-nowrap">{{tabz.heading}}</div>
        </div>
      </ng-container>
    </div>
  </li>
</ul>
<div class="tab-content" (resized)="updateTabVisibility()">
  <ng-content></ng-content>
</div>
