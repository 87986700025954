import { StateRequestParam } from '../app.state-names';
import { Transition } from '@uirouter/angular';

export namespace ShopRenterModel {

  export interface ShopRenterAuthRequestParam {
    readonly shopName?: string;
    readonly code?: string;
    readonly timestamp?: number;
    readonly hmac?: string;
  }

  export function parseShopRenterAuthRequestParam(transition: Transition): ShopRenterAuthRequestParam {
    const params = transition.params();
    const timestampText = params[StateRequestParam.SHOPRENTER_AUTH_TIMESTAMP];
    const shopName = params[StateRequestParam.SHOPRENTER_AUTH_SHOP_NAME];
    const code = params[StateRequestParam.SHOPRENTER_AUTH_CODE];
    const hmac = params[StateRequestParam.SHOPRENTER_AUTH_HMAC];
    let timestamp: number | undefined = undefined;
    if (timestampText) {
      timestamp = Number(timestampText);
    }
    return {
      shopName: shopName,
      code: code,
      timestamp: timestamp,
      hmac: hmac,
    }
  }

}
