/* eslint-disable */
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Transition } from '@uirouter/angular';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, UserProfile } from '../../../lib/auth.service';
import { OwnerUserItem, OwnerUserItemFactory, TypeItem } from '../../../util/core-utils';
import { UserData, UserDataLoader, UserDataLoaderPermissionDeniedStrategy } from '../../../lib/user-data-loader';
import { StringKey } from '../../../app.string-keys';
import { CustomerItem } from '../../../util/customer-utils';
import { CustomerNoteResource, NoteService } from '../../../lib/note.service';
import { NoteDetailModel } from '../../../util/note-utils';
import { Arrays } from '../../../lib/util/arrays';
import { CustomerRecordService } from '../../../lib/customer/customer-record.service';
import { TaskRecord, TaskRecordService } from '../../../lib/task/task-record.service';
import { QueryResult } from '../../../lib/util/services';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { StateName } from '../../../app.state-names';

/* eslint-enable */

@Component({
  selector: 'app-note-detail',
  templateUrl: 'note-detail.component.html',
  styleUrls: ['note-detail.component.css']
})
export class NoteDetailComponent implements OnInit, AfterViewInit {

  noteId: number;
  model: NoteDetailModel;
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  constructor(
    private ownerUserItemFactory: OwnerUserItemFactory,
    private userDataLoader: UserDataLoader,
    private authService: AuthService,
    private translateService: TranslateService,
    private customerRecordService: CustomerRecordService,
    private taskRecordService: TaskRecordService,
    private noteService: NoteService,
    private transition: Transition) {
    this.noteId = this.transition.params().id;
    this.model = new NoteDetailModel();
  }

  ngOnInit() {
    this.translateService.get('MENU_NAVBAR_NOTES').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.NOTE_LIST});
      }
    );
  }

  ngAfterViewInit(): void {
    this.loadModel();
  }

  private createDefaultCustomerItem(): CustomerItem {
    const def = new CustomerItem();
    def.id = null;
    def.text = '';
    this.translateService.get(StringKey.COMMON_VALUE_UNSELECTED).subscribe(
      (text: string) => {
        def.text = text;
      }
    );
    return def;
  }

  private loadCustomers(customerId?: number) {
    this.model.customer = this.createDefaultCustomerItem();
    this.customerRecordService.globalQuery({withFormRecord: false}).subscribe( customers => {
      Arrays.iterateByIndex(customers.items.toArray(), customer => {

        if (customerId === customer.customerRecordId) {
          const item = new CustomerItem();
          item.id = customer.customerRecordId;
          item.text = customer.name;
          this.model.customer = item;
        }
      });
    });
  }

  createDefaultTask(): TypeItem {
    const def = new TypeItem();
    def.id = null;
    def.text = '';
    this.translateService.get(StringKey.COMMON_VALUE_UNSELECTED).subscribe(
      (text: string) => {
        def.text = text;
      }
    );
    return def;
  }

  private loadTasks(taskId?: number) {
    this.model.task_record = this.createDefaultTask();
    this.taskRecordService.globalQuery({}).subscribe( (taskRecords: QueryResult<TaskRecord.TaskRecord>) => {
      const taskRecord: TaskRecord.TaskRecord | undefined =
        taskRecords.items.find((tr: TaskRecord.TaskRecord) => tr.taskRecordId === taskId);
      if (taskRecord) {
        const item = new TypeItem();
        item.id = taskRecord.taskRecordId;
        item.text = taskRecord.name;
        this.model.task_record = item;
      }
    });
  }

  getOwnerUserText() {
    if (this.model && this.model.owner_user) {
      return this.model.owner_user.text;
    }
    return this.translateService.instant(StringKey.COMMON_VALUE_UNSELECTED);
  }

  getCustomerName() {
    if (this.model.customer && this.model.customer.text) {
      return this.model.customer.text
    }
    return '';
  }

  back() {
    window.history.back();
  }


  getTaskName() {
    if (this.model.task_record) {
      return this.model.task_record.text;
    }

    return '';
  }

  private loadModel() {
    this.noteService.get({id: this.noteId})
    .subscribe(
      (note: CustomerNoteResource) => {
        this.breadcrumbSelf = note.id.toString();
        this.model.content = note.content;
        this.model.attachment_ids = note.attachment_ids;
        const up: UserProfile = {
          id: note.owner_user_id
        };
        this.loadOwnerUsers(up);
        this.loadCustomers(note.customer_id);
        this.loadTasks(note.task_record_id);
      }
    );
  }

  private loadOwnerUsers(up?: UserProfile) {
    const def = this.createDefaultOwnerUser();
    this.model.owner_user = def;
    if (up) {
      this.userDataLoader.load(up, UserDataLoaderPermissionDeniedStrategy.MISS_ALL).subscribe(
        (user: UserData) => {
          this.model.owner_user = this.ownerUserItemFactory.create(user);
        }
      );
    }
  }

  getAttachments(): string {
    if (this.model.attachment_ids.length === 0) {
      return '';
    }
    let result = '';
    Arrays.iterateByIndex(this.model.attachment_ids, attachment => {
      if (result === '') {
        result += String(attachment);
      }
      else {
        result += ', ' + String(attachment);
      }
    });
    return result;
  }

  private createDefaultOwnerUser(): OwnerUserItem {
    const def = new OwnerUserItem();
    def.id = null;
    def.text = '';
    this.translateService.get(StringKey.COMMON_VALUE_UNSELECTED).subscribe(
      (text: string) => {
        def.text = text;
      }
    );
    return def;
  }

}
