import { Component, Input, OnInit } from '@angular/core';
import { ExteriorTransport, ExteriorTransportService } from '../../../../lib/exterior-transport/exterior-transport.service';
import { QueryFieldModel } from '../../../../util/core-utils';
import { OrderType, QueryResult } from '../../../../lib/util/services';
import { Set } from 'immutable';

@Component({
  selector: 'app-exterior-transport-event-log',
  templateUrl: './exterior-transport-event-log.component.html',
  styleUrls: ['./exterior-transport-event-log.component.scss']
})
export class ExteriorTransportEventLogComponent implements OnInit {
  ExteriorTransport = ExteriorTransport;

  @Input()
  id: number;

  queryModel: QueryFieldModel<ExteriorTransport.EventLogOrderField>
    = new QueryFieldModel<ExteriorTransport.EventLogOrderField>(ExteriorTransport.EventLogOrderField.CREATION_TIME, OrderType.DESC);

  eventLogList: ExteriorTransport.EventLog[] = [];

  constructor(
    private exteriorTransportService: ExteriorTransportService
  ) { }

  ngOnInit() {
    this.loadList();
  }

  private loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();
    this.exteriorTransportService.getEventLog({
      id: this.id,
      orders: Set.of(order),
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined
    }).subscribe((result: QueryResult<ExteriorTransport.EventLog>) => {
      this.eventLogList = result.items.toArray();
      this.queryModel.currentPage = requestedPage;
      this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
    });
  }

  orderBy(field: ExteriorTransport.EventLogOrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  getEventLogType(type: ExteriorTransport.EventLogType): string {
    return ExteriorTransport.eventLogTypes.find(t => t.type === type)!.stringKey;
  }

}
