import { Component, Input, OnInit } from '@angular/core';
import {
  DemoModeFeatureDisabledDialogComponent
} from '../../../shared/demo-mode-feature-disabled-dialog/demo-mode-feature-disabled-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfigurationService } from '../../../lib/core-ext/configuration.service';

@Component({
  selector: 'app-admin-dashboard-card',
  templateUrl: './admin-dashboard-card.component.html',
  styleUrls: ['./admin-dashboard-card.component.scss']
})
export class AdminDashboardCardComponent implements OnInit {

  @Input()
  model: AdminDashboardCardModel;

  demoModeEnabled: boolean = false;

  constructor(
    private dialog: MatDialog,
    private configService: ConfigurationService,
  ) {
    this.demoModeEnabled = this.configService.getConfiguration().feature_flags.demo_mode_enabled;
  }

  ngOnInit() {
  }

  showDisabledDialog() {
    this.dialog.open(DemoModeFeatureDisabledDialogComponent);
  }

}

export interface AdminDashboardCardModel {
  iconClass: string;
  headerKey: string;
  menuItems: AdminDashboardCardMenuItem[];
  id: string;
}

export interface AdminDashboardCardMenuItem {
  titleKey: string;
  destination: string;
  visiblePredicate: () => boolean;
  enabledPredicate: () => boolean;
}
