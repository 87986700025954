/* eslint-disable */
import { FormEditFieldCreateDialogView, FormEditFieldDialogViewFactory, FormEditFieldUpdateDialogView, } from './form-utils';
import { ComponentFactoryResolver, ComponentRef, Injector, } from '@angular/core';
import { FormEditCreateUpdateDecimalFieldDialogComponent } from '../../admin/form/form-edit/dialogs/fields/decimal/create-update/form-edit-create-update-decimal-field-dialog.component';
/* eslint-enable */

/**
 * Decimal field implementation.
 */
export class FormEditDecimalFieldDialogViewFactory implements FormEditFieldDialogViewFactory {

  constructor(
    private injector: Injector,
    private cfr: ComponentFactoryResolver) {
  }

  createCreateView(): ComponentRef<FormEditFieldCreateDialogView> {
    return this.createView();
  }

  createUpdateView(): ComponentRef<FormEditFieldUpdateDialogView> {
    return this.createView();
  }

  private createView(): ComponentRef<FormEditCreateUpdateDecimalFieldDialogComponent> {
    return this.cfr.resolveComponentFactory(FormEditCreateUpdateDecimalFieldDialogComponent)
      .create(this.injector);
  }

}
