import { Injectable } from '@angular/core';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { Observable } from 'rxjs';
import { ResourceQueryResult } from '../util/services';
import { HttpClient } from '@angular/common/http';
import { EmptyMessage } from '../util/messages';

@Injectable()
export class OrderImportErrorResourceService extends BaseHttpService {

  query(request: OrderImportErrorResource.QueryRequest): Observable<ResourceQueryResult<OrderImportErrorResource.OrderImportError>> {
    return this.http.get<ResourceQueryResult<OrderImportErrorResource.OrderImportError>>(this.url, this.parseParams(request));
  }

  get(request: OrderImportErrorResource.GetRequest): Observable<OrderImportErrorResource.OrderImportError> {
    return this.http.get<OrderImportErrorResource.OrderImportError>(this.url + `${request.id}`, this.parseParams(request));
  }

  rejectManually(request: OrderImportErrorResource.RejectManuallyRequest): Observable<EmptyMessage> {
    return this.http.post<EmptyMessage>(this.url + `${request.id}`, null);
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, 'order-import-errors/');
  }

}

export namespace OrderImportErrorResource {

  export interface OrderImportError {
    id: number;
    state: string
    error_reason?: string
    error_message?: string
    error_stack_trace?: string
    realm: string
    external_id1: string;
    external_id2?: string;
    extra_id1?: string;
    creation_time: string;
    update_time: string;
    company: Company;
    user: User;
    recipient_name?: string;
    data1?: Data;
    data2?: Data;
    data3?: Data;
    manual_reject_user?: User;
    manual_reject_time?: string;
  }

  export interface User {
    id: number;
    person_name: string;
  }

  export interface Company {
    id: number;
    name: string;
  }

  export interface Data {
    content: string;
    content_type: string;
  }

  export interface QueryRequest {
    fields?: string;
    filter?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
  }

  export interface GetRequest {
    id: number;
  }

  export interface RejectManuallyRequest {
    id: number;
  }

}
