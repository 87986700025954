import { GrantedPermissionSet, GrantedPermissionSetResolver } from '../../right.service';
import { OperationRights } from '../../../app.right-definitions';

export class InvoiceSpendingRightModel {

  public readonly create: GrantedPermissionSet;
  public readonly read: GrantedPermissionSet;
  public readonly update: GrantedPermissionSet;

  constructor(resolver: GrantedPermissionSetResolver) {
    this.create = resolver.of(OperationRights.INVOICE_SPENDING_CREATE);
    this.read = resolver.of(OperationRights.INVOICE_SPENDING_READ);
    this.update = resolver.of(OperationRights.INVOICE_SPENDING_UPDATE);
  }
}
