/* eslint-disable */
import { AfterViewInit, Component, Input, OnInit, ViewChild, } from '@angular/core';
import { Address, AddressModel } from '../../../lib/address';
import { FormBuilder, FormGroup, NgForm, } from '@angular/forms';
import { PostalAddressComplexComponent } from './complex/postal-address-complex.component';
import { ConfigModel } from '../../../util/task-record-utils';
import { ConfigurationService } from '../../../lib/core-ext/configuration.service';
import PostalAddressModelType = AddressModel.PostalAddressModelType;

/* eslint-enable */

/**
 * Single postal address component with type selector.
 * It handles the coordinate and the address with inner components.
 */
@Component({
  selector: 'app-postal-address-field',
  templateUrl: './postal-address-field.component.html',
  styleUrls: ['./postal-address-field.component.scss']
})
export class PostalAddressFieldComponent implements OnInit, AfterViewInit {

  AddressModel = AddressModel;
  Address = Address;

  @Input()
  form?: NgForm;

  @Input()
  model?: AddressModel.PostalAddressModel;

  @Input()
  label: string = 'COMMON_LOCATION_POSTAL_ADDRESS';

  @Input()
  readonly: boolean;

  @Input()
  innerField: boolean;

  @Input()
  requiredField: boolean;

  @Input()
  fieldStyle?;

  @Input()
  noCard: boolean = false;

  @Input()
  autoAdd: boolean = false;

  @Input()
  fieldRatio: number = 9;

  @ViewChild(PostalAddressComplexComponent)
  complexChild?: PostalAddressComplexComponent;

  formGroup: FormGroup;

  config: ConfigModel = new ConfigModel();

  /**
   * Only for HTML binding!
   */
  get m(): AddressModel.PostalAddressModel {
    return this.model!;
  }

  constructor(fb: FormBuilder,
              private configService: ConfigurationService) {
    this.formGroup = this.createFormGroup(fb);
  }

  ngOnInit(): void {
    this.loadConfig();
  }

  ngAfterViewInit() {
    if (this.autoAdd && (!this.model || this.model.type === PostalAddressModelType.NONE)) {
      this.setSelectedItemToComplex();
    }
  }

  private loadConfig() {
    this.config = this.configService.getConfigurationModel();
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    if (!formControlName) {
      if (this.complexChild) {
        if (this.complexChild.hasLocalFieldError()) {
          return true;
        }
      }
    }
    return false;
  }

  private createFormGroup(fb: FormBuilder): FormGroup {
    return fb.group({});
  }

  private setSelectedItemToComplex() {
    this.model!.type = AddressModel.PostalAddressModelType.COMPLEX;
  }

  private setSelectedItemToNone() {
    this.model!.type = AddressModel.PostalAddressModelType.NONE;
  }

}
