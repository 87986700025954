<div class="modal-content">
  <div class="modal-header">
    <p class="modal-title"><strong>{{'COMMON_BUTTON_EXPORT' | translate}}</strong></p>
    <button type="button" class="close" (click)="closeDialog()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form [formGroup]='formGroup' (ngSubmit)="exportTask()" #f="ngForm">
    <div class="modal-body">
      <mat-dialog-content style="overflow:visible">

        <div>
          <div class="form-group row mt-1 mr-0 ml-0"
               [ngClass]="{ 'has-danger': hasLocalFieldError('exportableDataTypes')}">
            <label class="col-form-label col-md-4 required-field-label">
              {{'TASK_IMPORT_EXPORTABLE_DATA'|translate}}
            </label>
            <div class="col-md-8">
              <angular2-multiselect [data]="model.availableDataTypes" [settings]="offlineDropdownSettings"
                                    class="form-control"
                                    [(ngModel)]="model.exportableDataTypes"
                                    formControlName="exportableDataTypes">
              </angular2-multiselect>
              <div class="form-control-feedback"
                   *ngIf="hasLocalFieldError('exportableDataTypes')">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
          </div>
        </div>
      </mat-dialog-content>
    </div>
    <div class="modal-footer justify-content-end">
        <span>
          <button type="button" class="btn btn-outline-primary" (click)="closeDialog()">
            {{'COMMON_BUTTON_CANCEL' | translate}}
          </button>
          <button type="submit" class="btn btn-primary ml-1"
                  [ladda]="exportInProgress"
                  [disabled]="exportInProgress">
            {{'COMMON_BUTTON_EXPORT' | translate}}
          </button>
        </span>
    </div>
  </form>
</div>
