import { Injectable } from '@angular/core';
import { UIRouter } from '@uirouter/angular';
import { PushMessageEntity } from '../../lib/firebase/firebase-message.service';
import { StateName } from '../../app.state-names';
import { Message } from '../../lib/message/message.service';

@Injectable()
export class PushNavigationService {

  readonly states: Map<PushMessageEntity, NavigationItem> = new Map([
    [<PushMessageEntity>'FILE_DOCUMENT', new NavigationItem(StateName.DOCUMENT_FILE_READ, true)],
    [<PushMessageEntity>'TASK_RECORD', new NavigationItem(StateName.TASK_RECORD_DETAIL, false)],
    [<PushMessageEntity>'PROCESS_TASK', new NavigationItem(StateName.LEGACY_PROCESS_TASK_DETAIL, false)],
    [<PushMessageEntity>'PROCESS', new NavigationItem(StateName.PROCESS_DETAIL, true)],
    [<PushMessageEntity>'TRANSPORT', new NavigationItem(StateName.TRANSPORT_DETAIL, true)],
    [<PushMessageEntity>'PROSPEDITION', new NavigationItem(StateName.ORDER_IMPORT_ERROR_LIST, false)],
  ]);

  constructor(private uiRouter: UIRouter) {
  }

  messageAction(messageData?: Message.MessageData): boolean {

    // if the router is already in the required state, this ensures reloading with correct parameters
    const transitionOptions = { reload: true };

    if (!messageData) {
      return true;
    }
    const navItem = this.states.get(messageData.entityType);
    if (!navItem) {
      return true;
    }
    if (navItem.stateName === StateName.TASK_RECORD_DETAIL) {
      if (messageData.taskRecordTaskId) {
        this.uiRouter.stateService.go(navItem.stateName,
          {
          taskId: messageData.taskRecordTaskId,
          taskRecordId: messageData.entityId
          },
          transitionOptions);
      }
    }
    else if (navItem.stateName === StateName.LEGACY_PROCESS_TASK_DETAIL) {
      if (messageData.processTaskWorkflowTaskId) {
        this.uiRouter.stateService.go(navItem.stateName,
          {
          processId: messageData.entityId,
          id: messageData.processTaskWorkflowTaskId
          },
          transitionOptions);
      }
    }
    else {
      this.uiRouter.stateService.go(navItem.stateName, navItem.reqsId ? {id: messageData.entityId} : undefined, transitionOptions);
    }
    return false;
  }
}

class NavigationItem {

  constructor(readonly stateName: string,
              readonly reqsId: boolean) {

  }

}
