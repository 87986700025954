/* eslint-disable */
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import {
  FormEditFieldCreateDialogView,
  FormEditFieldDialogContext,
  FormEditFieldUpdateDialogView,
  FormGroupModel,
  FormModel
} from '../../../../../../../util/form/form-utils';
import {
  FieldValidationError,
  FormRef,
  LocalFieldValidationErrors,
  LocalFieldValidationErrorsFactory
} from '../../../../../../../lib/util/services';
import { Form } from '../../../../../../../lib/form/form.service';
import { InputMask } from '../../../../../../../util/input-masks';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgModel } from '@angular/forms';
import { FieldWidthType } from '../../../../../../../util/form/form-field-width-type';
import { FieldValidationType, FormFieldValidationTypeModel } from '../../../../../../../util/form/form-field-validation-type';
import { OptionItem, TypeItem, UiConstants } from '../../../../../../../util/core-utils';
import { List } from 'immutable';
import { StringKey } from '../../../../../../../app.string-keys';
import { ToasterService } from '../../../../../../../fork/angular2-toaster/angular2-toaster';
import { TranslateService } from '@ngx-translate/core';
import { Models } from '../../../../../../../util/model-utils';
import { Angular2Multiselects } from '../../../../../../../util/multiselect';
import { UserGroupService } from '../../../../../../../lib/user-group.service';
import { UserSignatureType } from '../../../../../../../lib/user.service';

/* eslint-enable */

@Component({
  selector: 'app-form-edit-create-update-user-field-dialog',
  templateUrl: './form-edit-create-update-user-field-dialog.component.html',
  styleUrls: ['./form-edit-create-update-user-field-dialog.component.scss']
})
export class FormEditCreateUpdateUserFieldDialogComponent
  implements FormEditFieldCreateDialogView, FormEditFieldUpdateDialogView, FormRef, AfterViewInit {

  public readonly selector = Form.FieldDataTypeSelector.USER;
  public readonly createTitle = 'FORM_ITEM_CREATE_USER_TITLE';
  public readonly updateTitle = 'FORM_ITEM_EDIT_USER_TITLE';
  public readonly cloneTitle = 'FORM_ITEM_CLONE_USER_TITLE';

  InputMask = InputMask;
  Form = Form;
  UiConstants = UiConstants;

  @ViewChild('fieldDialog', { static: true })
  dialogComponent: ModalDirective;

  @ViewChild('title')
  titleInput: NgModel;

  @ViewChild('userGroup')
  userGroupInput: NgModel;

  @ViewChild('api_export_name')
  apiExportNameInput: NgModel;

  visible: boolean = false;
  fieldModel: Model = new Model();
  dialogTitleDictionaryKey: string = '';
  latestApiExportName = '';

  fieldWidthTypes: FieldWidthType[] = FieldWidthType.VALUES.toArray();
  fieldValidationTypes: Form.FormFieldValidationType[] = FieldValidationType.VALUES.toArray();

  private fieldErrors: FieldValidationError<Form.FieldValidatedField> =
    FieldValidationError.empty<Form.FieldValidatedField>();

  private validatedInputs: LocalFieldValidationErrors<NgModel> =
    LocalFieldValidationErrorsFactory.empty();

  private context: FormEditFieldDialogContext;
  private group: FormGroupModel;
  private field?: Form.Field;
  private clone?: boolean;
  private submitted: boolean = false;

  availableUserGroup: TypeItem[] = [];

  dropdownSettings?: Angular2Multiselects.Settings;

  getForm() {
    return {
      submitted: this.submitted
    };
  }

  public constructor(private userGroupService: UserGroupService,
                     private toasterService: ToasterService,
                     private translateService: TranslateService) {
  }

  ngAfterViewInit(): void {
    this.loadLocalFieldValidationErrors();
    this.initDropdownSettings();
  }

  initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .remoteSearch(true)
      .labelKey(OptionItem.KEY_TEXT)
      .build();
  }

  registerContext(context: FormEditFieldDialogContext): void {
    this.context = context;
  }

  showDialog(form: FormModel, group: FormGroupModel, field?: Form.Field, clone?: boolean): void {
    this.latestApiExportName = '';
    this.visible = true;
    this.clone = clone;
    this.loadUserGroupsAndShowDialog(form, group, field, clone);
  }

  onModalHide(event: ModalDirective) {
    if (event.dismissReason === 'backdrop-click' || event.dismissReason === 'esc') {
      this.visible = false;
      this.submitted = false;
      this.fieldModel = new Model();
    }
  }

  closeDialog(): void {
    this.visible = false;
    this.submitted = false;
    this.dialogComponent.hide();
    this.fieldModel = new Model();
    this.availableUserGroup = [];
  }

  saveField() {
    this.submitted = true;
    if (this.hasLocalFieldError(this.titleInput)
      || this.hasLocalFieldError(this.userGroupInput)
      || this.hasLocalFieldError(this.apiExportNameInput)) {
      return;
    }
    if (this.field && this.clone) {
      this.createField();
    }
    else if (this.field) {
      this.updateField(this.field);
    }
    else {
      this.createField();
    }
  }

  hasLocalFieldError(field?: NgModel): boolean {
    return this.validatedInputs.hasLocalError(field);
  }

  hasFieldError(field?: Form.FieldValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: Form.FieldValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: Form.FieldValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  getFieldValidationTypeDictionaryKey(vt: Form.FormFieldValidationType): string {
    return FieldValidationType.getFieldValidationTypeDictionaryKey(vt);
  }

  onAdminFormFieldValidationTypeChanged() {
    FieldValidationType.onAdminFormFieldValidationTypeChanged(this.fieldModel);
  }

  onMobileFormFieldValidationTypeChanged() {
    FieldValidationType.onMobileFormFieldValidationTypeChanged(this.fieldModel);
  }

  multiSelectChanged(multiselect: boolean) {
    if (multiselect) {
      this.fieldModel.generateSignature = false;
    }
  }

  private loadLocalFieldValidationErrors() {
    const validatedInputs = List.of(this.titleInput, this.userGroupInput, this.apiExportNameInput);
    this.validatedInputs = LocalFieldValidationErrorsFactory.ofFields(this, validatedInputs);
  }

  private loadUserGroupsAndShowDialog(form: FormModel, group: FormGroupModel, field?: Form.Field, clone?: boolean) {
    this.loadUserGroups(() => {
      if (this.availableUserGroup.length > 0) {
        this.loadDialog(form, group, field, clone);
      }
      else {
        this.toasterService.pop({
          timeout: UiConstants.ToastTimeoutLong,
          type: UiConstants.toastTypeError,
          title: this.translateService.instant(StringKey.FORM_ITEM_USER_NO_USER_GROUP_IMAGE_TITLE),
          body: this.translateService.instant(StringKey.FORM_ITEM_USER_NO_USER_GROUP_MESSAGE)
        });
      }
    })
  }

  private loadUserGroups(completion: () => void, searchValue?: string) {
    this.userGroupService.query({
      name: searchValue,
      disabled: false,
      page_number: 1,
      number_of_items: UiConstants.autocompletePageSize,
      no_progress_bar: true
    }).subscribe(userGroupList => {
      this.availableUserGroup = [];
      userGroupList.items.forEach((userGroup) => {
        if (userGroup) {
          const item = new TypeItem();
          item.id = userGroup.id;
          item.text = userGroup.name;
          item.disabled = userGroup.disabled;
          this.availableUserGroup.push(item);
        }
      });
      if (completion) {
        completion();
      }
    });
  }


  private loadDialog(form: FormModel, group: FormGroupModel, field?: Form.Field, clone?: boolean) {
    this.group = group;
    this.dialogComponent.show();
    if (field && clone) {
      this.dialogTitleDictionaryKey = this.cloneTitle;
      this.loadField(field);
    }
    else if (field) {
      this.dialogTitleDictionaryKey = this.updateTitle;
      this.loadField(field);
    }
    else {
      this.dialogTitleDictionaryKey = this.createTitle;
    }
  }

  private loadField(field: Form.Field) {
    this.field = field;
    const attrs = field.dataType.userAttributes!;
    const userGroups = this.getUserGroupsById(attrs.userGroupIds);
    const placeholder = attrs.hint;
    const fieldWidth = FieldWidthType.fromPercentValue(field.formFieldWidthPercent);
    this.fieldModel.title = field.title;
    this.fieldModel.description = field.description ? field.description : '';
    this.fieldModel.hint = field.hint ? field.hint : '';
    this.fieldModel.apiExportName = field.apiExportName ? field.apiExportName : '';
    this.fieldModel.pdfExportName = field.pdfExportName ? field.pdfExportName : '';
    this.fieldModel.fieldWidthType = fieldWidth ? fieldWidth : FieldWidthType.DEFAULT;
    this.fieldModel.displayOnNewRow = field.displayOnNewRow;
    this.fieldModel.placeholder = placeholder ? placeholder : '';
    this.fieldModel.multiSelect = attrs.multiSelect;
    this.fieldModel.generateSignature = attrs.generateSignature !== undefined;
    this.fieldModel.adminVisibleOnFormDetail = field.admin.visibleOnFormDetail;
    this.fieldModel.mobileVisibleOnFormDetail = field.mobile.visibleOnFormDetail;
    this.fieldModel.mobileVisibleOnMasterDetail = field.mobile.visibleOnMasterDetail;
    this.fieldModel.adminFormFieldValidationType = field.admin.validationType;
    this.fieldModel.helpdeskFormFieldValidationType = field.helpdesk.validationType;
    this.fieldModel.mobileFormFieldValidationType = field.mobile.validationType;
    this.fieldModel.showOnReceipt = field.showOnReceipt;
    this.fieldModel.userGroupItems = [];
    if (attrs.userGroupIds) {
      this.fieldModel.userGroupItems.push(...userGroups);
      if (userGroups.length < attrs.userGroupIds.length) {
        this.userGroupService.query({
          id: attrs.userGroupIds.filter(id => !userGroups.map(ug => ug.id!).includes(id)).join()
        }).subscribe(result => {
          result.items.forEach(ug => {
            const item = new TypeItem();
            item.id = ug.id;
            item.text = ug.name;
            item.disabled = ug.disabled;
            this.fieldModel.userGroupItems.push(item);
          });
          this.userGroupInput.control.updateValueAndValidity();
        })
      }
    }
  }

  private getUserGroupsById(userGroupIds?: number[]): TypeItem[] {
    const items: TypeItem[] = [];
    if (userGroupIds) {
      this.availableUserGroup.forEach(i => {
        if (userGroupIds.includes(i.id!)) {
          items.push(i);
        }
      });
    }
    return items;
  }

  private createField() {
    this.context.formService.createField({
      parentId: this.context.parentId,
      groupId: this.group.groupId,
      title: this.fieldModel.title,
      description: this.fieldModel.description,
      hint: this.fieldModel.hint,
      pdfExportName: this.fieldModel.pdfExportName,
      apiExportName: this.fieldModel.apiExportName,
      dataTypeSelector: this.selector,
      dataType: this.getDataTypeRequest(),
      formFieldWidthPercent: FieldWidthType.FULL_SCREEN.percentValue,
      displayOnNewRow: this.fieldModel.displayOnNewRow,
      admin: {
        visibleOnFormDetail: this.fieldModel.adminVisibleOnFormDetail,
        validationType: this.fieldModel.adminFormFieldValidationType
      },
      helpdesk: {
        validationType: this.fieldModel.helpdeskFormFieldValidationType
      },
      mobile: {
        visibleOnFormDetail: this.fieldModel.mobileVisibleOnFormDetail,
        visibleOnMasterDetail: this.fieldModel.mobileVisibleOnMasterDetail,
        validationType: this.fieldModel.mobileFormFieldValidationType
      },
      showOnReceipt: this.fieldModel.showOnReceipt

    }).subscribe(
      (result) => {
        this.context.formView.reloadForm();
        this.closeDialog();
      },
      (error) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withFormRef(this);
        }
      }
    );
  }

  private updateField(field: Form.Field) {
    this.context.formService.updateField({
      parentId: this.context.parentId,
      groupId: this.group.groupId,
      fieldId: field.fieldId,
      title: this.fieldModel.title,
      description: this.fieldModel.description,
      hint: this.fieldModel.hint,
      pdfExportName: this.fieldModel.pdfExportName,
      apiExportName: this.fieldModel.apiExportName,
      dataTypeSelector: this.selector,
      dataType: this.getDataTypeRequest(),
      formFieldWidthPercent: FieldWidthType.FULL_SCREEN.percentValue,
      displayOnNewRow: this.fieldModel.displayOnNewRow,
      admin: {
        visibleOnFormDetail: this.fieldModel.adminVisibleOnFormDetail,
        validationType: this.fieldModel.adminFormFieldValidationType
      },
      helpdesk: {
        validationType: this.fieldModel.helpdeskFormFieldValidationType
      },
      mobile: {
        visibleOnFormDetail: this.fieldModel.mobileVisibleOnFormDetail,
        visibleOnMasterDetail: this.fieldModel.mobileVisibleOnMasterDetail,
        validationType: this.fieldModel.mobileFormFieldValidationType
      },
      showOnReceipt: this.fieldModel.showOnReceipt
    }).subscribe(
      (result) => {
        this.context.formView.reloadForm();
        this.closeDialog();
      },
      (error) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withFormRef(this);
        }
      }
    );
  }

  private getDataTypeRequest(): Form.FieldDataType {
    return {
      userAttributes: {
        userGroupIds: this.fieldModel.userGroupIds,
        hint: this.fieldModel.placeholder,
        multiSelect: this.fieldModel.multiSelect,
        generateSignature: this.fieldModel.generateSignature ? UserSignatureType.OFFICIAL : undefined
      }
    };
  }

  onTitleBlur() {
    const normalizedText = Models.normalizeText(this.fieldModel.title);
    if (this.fieldModel.apiExportName === '') {
      this.fieldModel.apiExportName = normalizedText;
    }
    if (this.fieldModel.pdfExportName === '') {
      this.fieldModel.pdfExportName = normalizedText;
    }
  }
}


export class Model implements FormFieldValidationTypeModel {
  title: string = '';
  description: string = '';
  apiExportName: string = '';
  pdfExportName: string = '';
  fieldWidthType: FieldWidthType = FieldWidthType.FULL_SCREEN;
  displayOnNewRow: boolean = false;
  hint?: string;
  placeholder?: string;
  userGroupItems: TypeItem[] = [];
  multiSelect: boolean = false;
  generateSignature: boolean = false;
  adminVisibleOnFormDetail: boolean = true;
  mobileVisibleOnFormDetail: boolean = true;
  mobileVisibleOnMasterDetail: boolean = false;
  adminFormFieldValidationType: Form.FormFieldValidationType = Form.FormFieldValidationType.OPTIONAL;
  helpdeskFormFieldValidationType: Form.FormFieldValidationType = Form.FormFieldValidationType.OPTIONAL;
  mobileFormFieldValidationType: Form.FormFieldValidationType = Form.FormFieldValidationType.OPTIONAL;
  showOnReceipt?: boolean = false;

  get userGroupIds(): number[] {
    return this.userGroupItems.map(ug => ug.id!);
  }
}
