<div bsModal [config]="UiConstants.modalConfig" #dialog="bs-modal" class="modal fade" tabindex="-1"
     role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="dialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title"><strong>{{titleKey | translate}}</strong></p>
        <button type="button" class="close" (click)="hideDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body d-flex flex-column align-items-center">
        <div class="form-group row w-100" *ngIf="fixedRatioSwitchEnabled">
          <label class="col-form-label form-control-label col-md-4 detail-title">{{fixedRatioKey | translate}}</label>
          <div class="col-md-8">
            <label class="switch switch-text switch-info mb-0">
              <input type="checkbox" class="switch-input"
                     [(ngModel)]="fixedRatioEnabled"
                     (ngModelChange)="onFixedRatioEnabledChanged()"
                     [ngModelOptions]="{standalone: true}">
              <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
              <span class="switch-handle"></span>
            </label>
          </div>
        </div>
        <div>
          <image-cropper [imageFile]="selectedImageSource"
                         [aspectRatio]="aspectRatio"
                         [maintainAspectRatio]="fixedRatioEnabled || fixedRatioForced"
                         [backgroundColor]="'#E3E8EC'"
                         (imageCropped)="imageCropped($event)">
          </image-cropper>
        </div>
      </div>
      <div class="modal-footer">
        <div class="w-100 d-flex justify-content-end">
          <div>
            <input type="button" class="btn btn-secondary mr-1" (click)="hideDialog()" value="{{'COMMON_BUTTON_CANCEL'|translate}}"/>
            <input type="button" class="btn btn-primary" (click)="uploadImage()" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
          </div>
        </div>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
