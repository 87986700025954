import { SiteTourBaseComponent, SiteTourFactory, SiteTourId } from '../site-tour.factory';
import { TourStep } from 'ngx-guided-tour';
import { SiteTourStringKeys } from '../site-tour-string-keys';

export class TaskDashboardTourFactory extends SiteTourFactory {

  protected getTourSteps(baseComponent?: SiteTourBaseComponent): TourStep[] {
    return [
      {
        selector: '#task-dashboard-card-global',
        title: SiteTourStringKeys.SITE_TOUR_TASK_DASHBOARD_GLOBAL_STEP_TITLE,
        content: SiteTourStringKeys.SITE_TOUR_TASK_DASHBOARD_GLOBAL_STEP_CONTENT,
        orientation: 'bottom',
        action: () => {
        },
        closeAction: () => {
        },
        skipStep: false,
      },
      {
        selector: '#task-dashboard-card-0',
        title: SiteTourStringKeys.SITE_TOUR_TASK_DASHBOARD_1_STEP_TITLE,
        content: SiteTourStringKeys.SITE_TOUR_TASK_DASHBOARD_1_STEP_CONTENT,
        orientation: 'bottom',
        action: () => {
        },
        closeAction: () => {
        },
        skipStep: false,
      }
    ];
  }

  protected get tourKey(): SiteTourId {
    return SiteTourId.TASK_DASHBOARD;
  }

}
