/* eslint-disable */
import { debounceTime, flatMap, map, switchMap } from 'rxjs/operators';
import { AfterViewInit, Component, Input, OnDestroy, OnInit, } from '@angular/core';
import { AddressModel } from '../../../../lib/address';
import { AbstractControl, FormBuilder, FormGroup, NgForm, ValidationErrors, Validators, } from '@angular/forms';
import { ForwardingNgFormRef, LocalFormGroupValidationErrors, } from '../../../../lib/util/services';
import { City, CityService, CountryService, } from '../../../../lib/country.service';
import { ConfigurationService } from '../../../../lib/core-ext/configuration.service';
import { UiConstants } from '../../../../util/core-utils';
import { Strings } from '../../../../lib/util/strings';
import { Angular2Multiselects } from '../../../../util/multiselect';
import PostalAddressModelType = AddressModel.PostalAddressModelType;
import { AppValidators } from '../../../../util/app-validators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { RecaptchaV3Utils } from '../../../../util/recaptcha-utils';
import { of as observableOf,  merge, Observable, Subject, Subscription, timer } from 'rxjs';
import { InputMask } from '../../../../util/input-masks';
/* eslint-enable */

/**
 * Postal address component for complex type.
 * Does not handle the coordinate.
 * WARNING: Each locale (like Hungary, U.S.A. etc.) has custom spelling for addresses.
 * Each spelling can be complex, so we do not format the addresses, and we accept the user input as-is.
 * Only required validation is active. Address type is optional because address might contain it.
 * Each field is a string (so house number can be text too, and it's OK).
 */
@Component({
  selector: 'app-postal-address-complex',
  templateUrl: './postal-address-complex.component.html',
  styleUrls: ['./postal-address-complex.component.scss']
})
export class PostalAddressComplexComponent implements OnInit, OnDestroy, AfterViewInit {
  private static readonly MAX_AUTOCOMPLETE_ITEMS = 20;
  private static readonly MAX_CHECK_ITEMS = 20;

  UiConstants = UiConstants;
  PostalAddressModelType = AddressModel.PostalAddressModelType;
  InputMask = InputMask;

  @Input()
  model?: AddressModel.PostalAddressModel;

  @Input()
  readonly: boolean;

  @Input()
  form?: NgForm;

  @Input()
  allowSimpleAddress?: boolean = false;

  @Input()
  fieldRatio: number = 9;

  @Input()
  otherFieldColumns?: number;

  get labelRatio(): number {
    if (this.fieldRatio < 12) {
      return 12 - this.fieldRatio;
    }
    return 12;
  }

  @Input()
  useMdTextAlign: boolean = true;

  @Input()
  recaptchaEnabled?: boolean = false;

  formGroup: FormGroup;

  fillCityInProgress: boolean = false;
  fillZipCodeInProgress: boolean = false;

  cityOptions: Observable<City.City[]>;
  availableStreetOptions: City.Street[] = [];
  streetOptions: Observable<City.Street[]>;
  refreshStreetOptions: Subject<any> = new Subject<any>();
  typeOptions: Observable<string[]>;

  config: ConfigModel = new ConfigModel();

  cityQueryToken?: string;

  dropdownSettings: Angular2Multiselects.Settings;

  /**
   * Only for HTML binding!
   */
  get m(): AddressModel.PostalAddressModel {
    return this.model!;
  }

  get complexValue(): AddressModel.PostalAddressComplexModel {
    return this.model!.complexValue;
  }

  get cityControl(): AbstractControl {
    return this.formGroup.controls['city'];
  }

  get addressControl(): AbstractControl {
    return this.formGroup.controls['address'];
  }

  get addressTypeControl(): AbstractControl {
    return this.formGroup.controls['addressType'];
  }

  get zipCodeControl(): AbstractControl {
    return this.formGroup.controls['zipCode'];
  }

  get houseNumberControl(): AbstractControl {
    return this.formGroup.controls['houseNumber'];
  }

  get buildingControl(): AbstractControl {
    return this.formGroup.controls['building'];
  }

  get stairwayControl(): AbstractControl {
    return this.formGroup.controls['stairway'];
  }

  get floorControl(): AbstractControl {
    return this.formGroup.controls['floor'];
  }

  get doorControl(): AbstractControl {
    return this.formGroup.controls['door'];
  }

  get nameControl(): AbstractControl {
    return this.formGroup.controls['name'];
  }

  get countryItemControl(): AbstractControl {
    return this.formGroup.controls['countryItem'];
  }

  private formGroupValidationErrors: LocalFormGroupValidationErrors;

  private zipCodeChangedDelayedSubscription: Subscription;
  private addressChangedSubscription: Subscription;

  constructor(fb: FormBuilder,
              private cityService: CityService,
              private configService: ConfigurationService,
              private recaptchaV3Service: ReCaptchaV3Service,
              private countryService: CountryService) {
    this.formGroup = this.createFormGroup(fb);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(
      this.createForwardingHtmlForm(),
      this.formGroup
    );
  }

  ngOnInit() {
    this.initDropdownSettings();
    this.zipCodeChangedDelayedSubscription =
      this.registerOnZipCodeChangedDelayed();
    this.addressChangedSubscription =
      this.registerOnAddressChanged();
    this.loadConfig(() => {
      this.initCityAutocomplete();
      this.initAddressAutocomplete();
      this.initAddressTypeAutocomplete();
    });
  }

  ngOnDestroy() {
    this.zipCodeChangedDelayedSubscription.unsubscribe();
    this.addressChangedSubscription.unsubscribe();
  }

  ngAfterViewInit() {
    this.setDefaultCountryItem();
  }

  private initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .build();
  }

  private initCityAutocomplete() {
    this.cityOptions = merge(this.zipCodeControl.valueChanges, this.cityControl.valueChanges).pipe(
      debounceTime(UiConstants.autocompleteDebounceTime),
      flatMap(
        (value) => {
          if (this.model && this.model.complexValue && this.model.complexValue.country) {
            if (value.length > 0) {
              return this.preAuthenticate('cityQuery', this.cityService.queryCity({
                countryCode: this.model.complexValue.country.id!,
                zipCode: Strings.undefinedOrNonEmpty(this.model.complexValue.zipCode),
                nativeName: Strings.undefinedOrNonEmpty(this.model.complexValue.city),
                withZipCodes: false,
                withStreets: true,
                token: this.cityQueryToken,
                paging: {
                  numberOfItems: PostalAddressComplexComponent.MAX_AUTOCOMPLETE_ITEMS,
                  pageNumber: 1
                }
              })).pipe(map((qr: City.CityQueryResult) => {
                this.cityQueryToken = qr.token;
                let array = qr.result.items.toArray();
                array = array.filter((city, index, self) =>
                  index === self.findIndex((c) => (
                    c.localizedName === city.localizedName && c.countryCode === city.countryCode && c.county === city.county
                  ))
                );
                const selectedCity = array.find(c => c.localizedName === this.m.complexValue.city);
                if (selectedCity) {
                  this.availableStreetOptions = selectedCity.streets ? selectedCity.streets : [];
                  this.refreshStreetOptions.next();
                }
                return array;
              }));
            }
            else {
              return [];
            }
          }
          else {
            return [];
          }
        }
      )
    );
  }

  private initAddressAutocomplete() {
    this.streetOptions = merge(this.addressControl.valueChanges, this.refreshStreetOptions).pipe(
      debounceTime(UiConstants.autocompleteDebounceTime),
      flatMap(
        (value) => {
          if (this.availableStreetOptions.length > 0) {
            if (this.m.complexValue.address.length > 0) {
              return observableOf(this.availableStreetOptions
                .filter(s => s.nativeName.toLowerCase().startsWith(this.m.complexValue.address.toLowerCase()))
                .slice(0, UiConstants.autocompletePageSize))
                .pipe(map(result => {
                  return result;
                }));
            }
            else {
              return observableOf(this.availableStreetOptions
                .slice(0, UiConstants.autocompletePageSize))
                .pipe(map(result => {
                  return result;
                }));
            }
          }
          else {
            return [];
          }
        }
      )
    );
  }

  private initAddressTypeAutocomplete() {
    this.typeOptions = this.addressTypeControl.valueChanges.pipe(
      debounceTime(UiConstants.autocompleteDebounceTime),
      flatMap(
        (value) => {
          if (this.model && this.model.complexValue && this.model.complexValue.country) {
            if (value.length > 0) {
              return this.preAuthenticate('addressTypeQuery', this.countryService.addressTypeQuery({
                countryCode: this.model.complexValue.country.id!,
                q: value,
                paging: {
                  numberOfItems: PostalAddressComplexComponent.MAX_AUTOCOMPLETE_ITEMS,
                  pageNumber: 1
                }
              })).pipe(map((result) => {
                return result.items.toArray();
              }));
            }
            else {
              return [];
            }
          }
          else {
            return [];
          }
        }
      )
    );
  }

  private validZipCodeFormatValidator({value}: AbstractControl): ValidationErrors | null {
    if (this.zipCodeValidationEnabled()
      && value
      && value.length > 0) {
      return /^[0-9]{4}$/.test(value) ? null : {zipCodeFormatInvalid: true};
    }
    return null;
  }

  private existingCityValidator({value}: AbstractControl): Observable <ValidationErrors | null> {
    if (this.cityValidationEnabled()
      && this.complexValue && this.complexValue.country
      && this.complexValue.city && this.complexValue.city.length > 0) {
      return timer(UiConstants.autocompleteDebounceTime).pipe(
        switchMap(() => this.preAuthenticate('cityQuery', this.cityService.queryCity({
          token: this.cityQueryToken,
          countryCode: this.complexValue.country.id!,
          nativeName: Strings.undefinedOrNonEmpty(this.complexValue.city),
          withZipCodes: false,
          paging: {
            numberOfItems: PostalAddressComplexComponent.MAX_CHECK_ITEMS,
            pageNumber: 1
          }
        }))),
        map(qr => {
          const items = qr.result.items;
          this.cityQueryToken = qr.token;
          let valid: boolean = false;
          if (!items.isEmpty()) {
            items.forEach((t) => {
              if (!t) {
                return;
              }
              if (t.localizedName === this.complexValue.city) {
                valid = true;
              }
            });
          }
          return valid ? null : {
            invalidCity: true
          };
        })
      );
    }
    return observableOf(null);
  }

  private existingStreetTypeValidator({value}: AbstractControl): Observable <ValidationErrors | null> {
    if (this.addressTypeValidationEnabled()
      && this.complexValue && this.complexValue.country && this.complexValue.addressType
      && this.complexValue.addressType.length > 0) {
      return timer(UiConstants.autocompleteDebounceTime).pipe(
        switchMap(() => this.preAuthenticate('addressTypeQuery', this.countryService.addressTypeQuery({
            countryCode: this.complexValue.country.id!,
            q: this.complexValue.addressType,
            paging: {
              numberOfItems: PostalAddressComplexComponent.MAX_CHECK_ITEMS,
              pageNumber: 1
            }
          }))),
        map(result => {
          const array = result.items.toArray();
          let valid: boolean = false;
          if (array.length > 0) {
            array.forEach((t) => {
              if (t === this.complexValue.addressType) {
                valid = true;
              }
            });
          }
          return valid ? null : {
            invalidStreetType: true
          };
        })
      );
    }
    return observableOf(null);
  }

  private loadConfig(completion: () => void) {
    const configuration = this.configService.getConfiguration();
    this.config.acceptOnlyExistingCityInCountries
      = configuration.feature_flags.postal_address.accept_only_existing_city_in_countries;
    this.config.acceptOnlyExistingStreetTypeInCountries
      = configuration.feature_flags.postal_address.accept_only_existing_street_type_in_countries;
    this.config.acceptOnlyValidZipCodeFormat
      = configuration.feature_flags.postal_address.accept_only_valid_zip_code_format;
    this.config.acceptOnlyComplexAddress
      = configuration.feature_flags.postal_address.accept_only_complex_address;
    if (this.config.acceptOnlyComplexAddress && this.model && this.model.type === AddressModel.PostalAddressModelType.SIMPLE) {
      this.model.type = AddressModel.PostalAddressModelType.COMPLEX;
    }
    completion();
  }

  private onZipCodeChangedDelayed() {
    this.fillCity();
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return this.form !== undefined && this.form.submitted && this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  private fillCity() {
    if (!this.zipCodeControl.dirty) {
      return;
    }
    if (!this.model) {
      return; // Component binding is loading.
    }
    if (!this.model.complexValue.country) {
      return; // Country is not selected yet.
    }
    const zipCode = this.model.complexValue.zipCode;
    if (zipCode.length === 0) {
      return; // ZIP code is not entered yet.
    }
    this.fillCityInProgress = true;
    this.preAuthenticate('cityQuery', this.cityService.queryCity({
      countryCode: this.model.complexValue.country.id!,
      zipCode: Strings.undefinedOrNonEmpty(zipCode),
      withStreets: false,
      token: this.cityQueryToken,
    })).subscribe(
      (qr: City.CityQueryResult) => {
        const result = qr.result;
        this.cityQueryToken = qr.token;
        if (result.items.size === 1) {
          const uniqueResult = result.items.get(0);
          this.complexValue.city = uniqueResult.localizedName; // write
        }
        this.fillCityInProgress = false;
      },
      () => {
        this.fillCityInProgress = false;
      }
    );
  }

  private fillZipCode() {
    if (!this.model) {
      return; // Component binding is loading.
    }
    if (!this.model.complexValue.country) {
      return; // Country is not selected yet.
    }
    const city = this.model.complexValue.city;
    if (city.length === 0) {
      return; // City is not entered yet.
    }
    const zipCode = this.model.complexValue.zipCode;
    if (zipCode.length !== 0) {
      return; // Zip code is already entered.
    }
    this.fillZipCodeInProgress = true;
    this.preAuthenticate('cityQuery', this.cityService.queryCity({
      countryCode: this.model.complexValue.country.id!,
      nativeName: Strings.undefinedOrNonEmpty(city),
      withStreets: false,
      withZipCodes: true,
      token: this.cityQueryToken,
    })).subscribe(
      (qr: City.CityQueryResult) => {
        const result = qr.result;
        this.cityQueryToken = qr.token;
        for (let i = 0; i < result.items.size; i++) {
          const c = result.items.get(i);
          if (c.localizedName === city) {
            if (c.zipCodes!.length === 1) {
              this.complexValue.zipCode = c.zipCodes![0];
            }
            break;
          }
        }
        this.fillZipCodeInProgress = false;
      },
      () => {
        this.fillZipCodeInProgress = false;
      }
    );
  }

  private createFormGroup(fb: FormBuilder): FormGroup {
    return fb.group(
      {
        countryItem: fb.control(
          {},
          [
            Validators.required
          ]
        ),
        zipCode: fb.control(
          '',
          [
            Validators.required,
            AppValidators.noWhiteSpace,
            this.validZipCodeFormatValidator.bind(this)
          ]
        ),
        city: fb.control(
          {},
          [
            Validators.required,
            AppValidators.noWhiteSpace
          ],
          this.existingCityValidator.bind(this)
        ),
        address: fb.control(
          {},
          [
            Validators.required,
            AppValidators.noWhiteSpace
          ]
        ),
        addressType: fb.control(
          {},
          [
            AppValidators.required({
              value: () => {
                return this.model!.complexValue.addressType
              },
              disabled: () => {
                const result = !this.model || this.model.type !== PostalAddressModelType.COMPLEX;
                return result;
              }
            }),
            AppValidators.tempValidator({
              validator: AppValidators.noWhiteSpace,
              disabled: () => {
                return !this.model || this.model.type !== PostalAddressModelType.COMPLEX;
              }
            })
          ],
          this.existingStreetTypeValidator.bind(this)
        ),
        houseNumber: fb.control(
          {},
          [
            AppValidators.required({
              value: () => {
                return this.model!.complexValue.houseNumber
              },
              disabled: () => {
                return !this.model || this.model.type !== PostalAddressModelType.COMPLEX;
              }
            }),
            AppValidators.tempValidator({
              validator: AppValidators.noWhiteSpace,
              disabled: () => {
                return !this.model || this.model.type !== PostalAddressModelType.COMPLEX;
              }
            })
          ]
        ),
        building: fb.control(
          {},
          [
            AppValidators.noWhiteSpace
          ]
        ),
        stairway: fb.control(
          {},
          [
            AppValidators.noWhiteSpace
          ]
        ),
        floor: fb.control(
          {},
          [
            AppValidators.noWhiteSpace
          ]
        ),
        door: fb.control(
          {},
          [
            AppValidators.noWhiteSpace
          ]
        ),
        name: fb.control(
          {},
          [
            AppValidators.noWhiteSpace
          ]
        ),
      }
    );
  }

  private addressTypeValidationEnabled(): boolean {
    if (!this.model) {
      return false;
    }
    if (this.model.type !== PostalAddressModelType.COMPLEX) {
      return false;
    }
    if (!this.model.complexValue) {
      return false;
    }
    if (!this.model.complexValue.country) {
      return false;
    }
    return this.config.validateAddressType(this.model.complexValue.country.id!);
  }

  private zipCodeValidationEnabled(): boolean {
    if (!this.model) {
      return false;
    }
    if (!this.model.complexValue) {
      return false;
    }
    if (!this.model.complexValue.country) {
      return false;
    }
    return this.config.validateZipCode(this.model.complexValue.country.id!);
  }

  getZipCodeTextMaskConfiguration(): any {
    if (this.zipCodeValidationEnabled()) {
      return {mask: InputMask.ZIP_CODE, guide: true};
    }
    return {mask: false, guide: false};
  }

  private cityValidationEnabled(): boolean {
    if (!this.model) {
      return false;
    }
    if (!this.model.complexValue) {
      return false;
    }
    if (!this.model.complexValue.country) {
      return false;
    }
    return this.config.validateCity(this.model.complexValue.country.id!);
  }

  private createForwardingHtmlForm() {
    return new ForwardingNgFormRef({
      formFn: () => {
        return this.form;
      }
    });
  }

  private setDefaultCountryItem() {
    if (!this.complexValue.country) {
      this.model!.countryItems.forEach((countryItem) => {
        if (countryItem.id === this.configService.getDefaultSelectedCountryCode()) {
          this.complexValue.country = countryItem; // write
          return;
        }
      });
    }
  }

  private registerOnZipCodeChangedDelayed(): Subscription {
    return this.zipCodeControl.valueChanges.pipe(
      debounceTime(UiConstants.autocompleteDebounceTime),
    ).subscribe((value) => {
      this.onZipCodeChangedDelayed();
    });
  }

  private registerOnAddressChanged(): Subscription {
    return this.addressControl.valueChanges.subscribe((value) => {
      this.updateValueAndValidity();
    });
  }

  switchType(type: AddressModel.PostalAddressModelType) {
    this.model!.type = type;
    this.updateValueAndValidity();
  }

  private updateValueAndValidity() {
    this.formGroup.controls.addressType.updateValueAndValidity();
    this.formGroup.controls.houseNumber.updateValueAndValidity();
  }

  onAddressOptionSelected(event: MatAutocompleteSelectedEvent) {
    this.m.complexValue.addressType = event.option._getHostElement().children[0].innerHTML.split(event.option.value)[1].trim();
  }

  private preAuthenticate(action: string, call: Observable<any>): Observable<any> {
    if (this.recaptchaEnabled) {
      return RecaptchaV3Utils.preAuthenticate(this.recaptchaV3Service, action, call);
    }
    return call;
  }

}

class ConfigModel {
  acceptOnlyExistingCityInCountries: string[] = [];
  acceptOnlyExistingStreetTypeInCountries: string[] = [];
  acceptOnlyValidZipCodeFormat: string[] = [];
  acceptOnlyComplexAddress: boolean = true;

  validateCity(countryCode: string): boolean {
    return this.acceptOnlyExistingCityInCountries.includes(countryCode);
  }

  validateAddressType(countryCode: string): boolean {
    return this.acceptOnlyExistingStreetTypeInCountries.includes(countryCode);
  }

  validateZipCode(countryCode: string): boolean {
    return this.acceptOnlyValidZipCodeFormat.includes(countryCode);
  }

  simpleAddressEnabled(): boolean {
    return !this.acceptOnlyComplexAddress;
  }

}
