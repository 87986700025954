import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { GoogleMapsLoaderUtil } from '../../../../../util/google/google-maps-loader.util';
import { DatePipe } from '@angular/common';
import { SettingsService } from '../../../../../lib/settings.service';
import { TranslateService } from '@ngx-translate/core';
import { TaskRecordHistoryModel } from '../task-record-history.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-task-record-history-map',
  templateUrl: './task-record-history-map.component.html',
  styleUrls: ['./task-record-history-map.component.scss']
})
export class TaskRecordHistoryMapComponent implements OnInit, AfterViewInit {

  @ViewChild('gmap', { static: true }) gmapElement: any;
  map: google.maps.Map;

  mapLoaded: Subject<any> = new Subject<any>();

  @Input()
  set logs(logs: TaskRecordHistoryModel[]) {
    if (!this.map) {
      this.mapLoaded.subscribe(r => this.showMarkers(logs));
    }
    else {
      this.showMarkers(logs);
    }
  }

  @Output()
  highlightTableRow: EventEmitter<number> = new EventEmitter<number>();

  private markers: google.maps.Marker[] = [];

  constructor(private settings: SettingsService,
              private translateService: TranslateService) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.reset();
    this.initMap();
  }

  initMap() {
    if (!this.map) {
      if (GoogleMapsLoaderUtil.didMapLoad()) {
        this.createMap();
      }
      else {
        GoogleMapsLoaderUtil.subscribe(() => {
          this.createMap();
        });
      }
    }
  }

  createMap() {
    const mapProp: google.maps.MapOptions = {
      center: new google.maps.LatLng(47.49801, 19.03991),
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      streetViewControl: false,
      mapTypeControl: false,
      styles: [
        {
          featureType: 'poi',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'transit.station',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        }
      ]
    };
    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
    this.mapLoaded.next();
  }

  private showMarkers(logs: TaskRecordHistoryModel[]) {
    const latlngs: google.maps.LatLng[] = [];
    const bounds = new google.maps.LatLngBounds();
    logs.forEach((log, index) => {
      const coord = log.coordinate;
      if (coord) {
        const latlng = new google.maps.LatLng(coord.latitude.toNumber(), coord.longitude.toNumber());
        bounds.extend(latlng);
        this.translateService.get(log.changeType).subscribe(logTypeText => {
          const dateTimeText = new DatePipe(this.settings.getLocaleCode()).transform(log.changeTime, 'short');
          const coordinatesText = coord.latitude! + ', ' + coord.longitude!;
          const infoWindow = new google.maps.InfoWindow({
            content:
              '<center><strong>' + logTypeText + '</strong></center>' +
              log.taskRecord ? '<center>' + log.taskRecord!.name + '</center>' : '' +
              '<center>' + dateTimeText + '</center>' +
              '<center>' + coordinatesText + '</center>'
          });
          const marker = new google.maps.Marker({
            position: latlng,
            map: this.map,
            title: logTypeText + ', ' + dateTimeText,
            label: {
              text: (1 + index) + '',
              fontSize: '16px'
            },
            icon: {
              url: this.resolvePoiUrl(),
              labelOrigin: this.getLabelOrigin()
            },
            zIndex: index
          });
          const that = this;
          marker.addListener('click', function () {
            infoWindow.open(map, marker);
            that.highlightTableRow.emit(index);
          });
          latlngs.push(latlng);
          this.markers.push(marker);
        });
      }
    });
    const map = this.map;
    if (!bounds.isEmpty()) {
      google.maps.event.addListenerOnce(this.map, 'idle', function () {
        map.fitBounds(bounds);
        map.panToBounds(bounds);
      });
      map.setZoom(map.getZoom());
    }
  }

  private resolvePoiUrl() {
    return '../../../assets/img/poi/ic_map_poi_number_green.svg';
  }

  private getLabelOrigin() {
    return new google.maps.Point(18, 15);
  }

  private reset() {
    this.markers.forEach(m => m.setMap(null));
    this.markers = [];
  }

  highlightMarker(log: TaskRecordHistoryModel) {
    const latlng = new google.maps.LatLng(log.coordinate!.latitude.toNumber(), log.coordinate!.longitude.toNumber());
    this.map.setCenter(latlng);
    this.map.setZoom(15);
  }

}
