/* eslint-disable */
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormRecordContainerComponent } from '../../form/form-record/form-record-container.component';
import { FormRecordContainer } from '../../form/form-record/form-record-container';
import { NgForm } from '@angular/forms';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { ToasterService } from '../../../fork/angular2-toaster/angular2-toaster';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ConfigurationService } from '../../../lib/core-ext/configuration.service';
import { StateName } from '../../../app.state-names';
import { combineLatest, Observable, Subject } from 'rxjs';
import { ConfirmLeaveModalComponent } from '../../../shared/confirm-leave-modal/confirm-leave-modal.component';
import { Form } from '../../../lib/form/form.service';
import { FieldValidationError } from '../../../lib/util/services';
import { UiConstants } from '../../../util/core-utils';
import { StringKey } from '../../../app.string-keys';
import { LegacyProcess, LegacyProcessService } from '../../../lib/legacy-process/legacy-process.service';
import { LegacyProcessTask, LegacyProcessTaskService } from '../../../lib/legacy-process/legacy-process-task.service';
import { LegacyWorkflowTaskService } from '../../../lib/legacy-workflow/legacy-workflow-tasks/legacy-workflow-task.service';
import { LegacyProcessTaskBaseComponent } from '../legacy-process-task-base/legacy-process-task-base.component';
import { LegacyProcessTaskBase } from '../legacy-process-task-base/legacy-process-task-base-view';
import { Transition, UIRouter } from '@uirouter/angular';

/* eslint-enable */

@Component({
  selector: 'app-legacy-process-task-edit',
  templateUrl: './legacy-process-task-edit.component.html',
  styleUrls: ['./legacy-process-task-edit.component.scss']
})
export class LegacyProcessTaskEditComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(LegacyProcessTaskBaseComponent, { static: true })
  baseView: LegacyProcessTaskBase.View;

  @ViewChild(FormRecordContainerComponent, { static: true })
  formRecordContainerView: FormRecordContainer.View;

  @ViewChild('f', { static: true })
  fForm: NgForm;

  forceSaveButton: boolean = false;

  processId: number;
  processTaskId: number;
  workflowId: number;
  workflowTaskId: number;
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  breadcrumbParents: BreadcrumbParent[] = [];
  processName: string;
  deregisterWarningModal;
  saveButtonClicked: boolean = false;

  private combinedResult?: CombinedResult;

  constructor(
    private toasterService: ToasterService,
    private translateService: TranslateService,
    private modalService: BsModalService,
    private configurationService: ConfigurationService,
    private processService: LegacyProcessService,
    private processTaskService: LegacyProcessTaskService,
    private workflowTaskService: LegacyWorkflowTaskService,
    private uiRouter: UIRouter,
    private transition: Transition) {
    this.processId = this.transition.params().processId;
    this.processTaskId = this.transition.params().id;

  }

  ngOnInit() {
    const context = this;
    this.deregisterWarningModal = this.uiRouter.transitionService.onStart({}, function (transition) {
      if (context.saveButtonClicked
        || transition.targetState().name() === StateName.CONNECTION_ERROR
        || transition.targetState().name() === StateName.SERVER_ERROR
        || transition.targetState().name() === StateName.LOGIN) {
        return true;
      }
      else {
        const subject = new Subject<boolean>();
        const modal = context.modalService.show(ConfirmLeaveModalComponent, {backdrop: 'static', keyboard: false});
        modal.content!.subject = subject;
        return subject.asObservable().toPromise();
      }
    });
  }

  ngAfterViewInit(): void {
    this.processTaskService.get({
      processId: this.processId,
      processTaskId: this.processTaskId
    }).subscribe((processTask: LegacyProcessTask.LegacyProcessTask) => {
      this.workflowId = processTask.workflowId;
      this.workflowTaskId = processTask.workflowTaskId;
      LegacyWorkflowTaskService.setWorkflowId(this.workflowId);
      this.createCombinedObservable().subscribe(
        (result: CombinedResult) => {
          this.breadcrumbSelf = result.processTask.displayName;
          this.processName = result.process.displayName ? result.process.displayName : String(result.process.processId);
          this.baseView.loadProcessTask({
            processTask: result.processTask
          });
          this.formRecordContainerView.loadFormRecord({
            configuration: this.configurationService.getConfiguration(),
            form: result.form,
            record: result.processTask.formRecord,
            other: {processId: this.processId}
          });
          this.combinedResult = result;
          this.forceSaveButton = !Form.Forms.hasVisibleGroup(result.form);
          this.translateService.get('MENU_NAVBAR_LEGACY_PROCESSES').subscribe(
            (text: string) => {
              this.breadcrumbParents.push({name: text, uiSref: StateName.LEGACY_PROCESS_LIST});
            }
          );
          this.breadcrumbParents.push({
            name: String(this.processId),
            uiSref: StateName.LEGACY_PROCESS_EDIT,
            uiParam: {id: this.processId}
          });
        },
        () => {
        },
        () => {
        }
      );
    });
  }

  updateProcessTask() {
    if (!this.combinedResult) {
      return;
    }
    if (this.baseView.hasLocalFieldError() || this.formRecordContainerView.hasLocalFieldError()) {
      this.showValidationErrorToast();
      return;
    }
    if (this.formRecordContainerView.validateWithInterrupt()) {
      return;
    }
    const model = this.baseView.getModel();
    const formModel = this.formRecordContainerView.createModel();
    this.processTaskService.update({
      withFormRecord: false,
      processId: this.processId,
      processTaskId: this.processTaskId,
      name: model.name,
      externalId: model.externalId,
      description: model.description,
      assigneeUserId: model.assigneeUserId ? model.assigneeUserId : null,
      formRecord: {
        version: this.combinedResult.processTask.formRecord!.version,
        fields: formModel.fields
      }
    }).subscribe(
      (processTask: LegacyProcessTask.LegacyProcessTask) => {
        this.saveButtonClicked = true;
        this.uiRouter.stateService.go(StateName.LEGACY_PROCESS_EDIT, {
          id: this.processId
        });
      },
      (error) => {
        if (error instanceof FieldValidationError) {
          this.baseView.onFieldValidationError(error.withForm(this.fForm));
          this.showValidationErrorToast();
        }
      }
    );
  }

  private createCombinedObservable(): Observable<CombinedResult> {
    return combineLatest(
      this.workflowTaskService.getForm({
        parentId: this.workflowTaskId
      }),
      this.processService.get({
        id: this.processId
      }),
      this.processTaskService.get({
        withFormRecord: true,
        processId: this.processId,
        processTaskId: this.processTaskId
      }),
      (
        form: Form.Form,
        process: LegacyProcess.LegacyProcess,
        processTask: LegacyProcessTask.LegacyProcessTask) => {
        return {
          form: form,
          process: process,
          processTask: processTask
        };
      }
    );
  }

  private showValidationErrorToast() {
    this.toasterService.pop({
      timeout: UiConstants.ToastTimeoutLong,
      type: UiConstants.toastTypeError,
      title: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_TITLE),
      body: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_MESSAGE)
    });
  }

  ngOnDestroy() {
    this.deregisterWarningModal();
  }

}

interface CombinedResult {
  form: Form.Form;
  process: LegacyProcess.LegacyProcess;
  processTask: LegacyProcessTask.LegacyProcessTask;
}
