import { Injectable } from '@angular/core';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EmptyMessage } from '../util/messages';


@Injectable()
export class PasswordResetResourceService extends BaseHttpService {

  private static readonly baseUrl: string = '/password-reset/';

  request(request: PasswordResetResource.RequestRequest): Observable<EmptyMessage> {
    return this.http.post<EmptyMessage>(this.url + 'request', request);
  }

  change(request: PasswordResetResource.ChangeRequest): Observable<EmptyMessage> {
    return this.http.post<EmptyMessage>(this.url + 'change', request);
  }

  constructor(private http: HttpClient, private resourceHelper: ResourceHelper) {
    super(resourceHelper, PasswordResetResourceService.baseUrl);
  }

}

export namespace PasswordResetResource {

  export interface RequestRequest {
    user_name: string;
  }

  export interface ChangeRequest {
    token: string;
    password: string;
  }

}
