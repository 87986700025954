import { GrantedPermissionSetResolver } from '../../../../lib/right.service';
import { Dates, LocalDate, OffsetDateTime } from '../../../../lib/util/dates';
import { InvoiceSpendingRightModel } from '../../../../lib/invoice/spending/invoice-spending-right.model';
import { InvoiceSpending } from '../../../../lib/invoice/spending/invoice-spending.service';
import { Invoice } from '../../../../lib/invoice/invoice/invoice.service';

export class InvoiceSpendingListModel {
  id: number;
  rights: InvoiceSpendingRightModel = new InvoiceSpendingRightModel(GrantedPermissionSetResolver.empty());
  creationTime: OffsetDateTime = Dates.emptyOffsetDateTime();
  updateTime: OffsetDateTime = Dates.emptyOffsetDateTime();
  issuedDate: LocalDate = Dates.emptyLocalDate();
  spendingNumber: string;
  invoiceSpendingType: InvoiceSpending.InvoiceSpendingType;
  invoiceSpendingTypeObject?: InvoiceSpending.InvoiceSpendingTypeObject;
  eventName: string;
  comment?: string;
  amount: number;
  currencyCode: string = 'HUF';
  creatorUser: {
    id: number;
    userName: string;
    personName: string;
  };
  invoices?: Invoice.Invoice[];

  getCreatorUserName(): string {
    return this.creatorUser.personName;
  }
}
