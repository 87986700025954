/* eslint-disable */
import { Injectable } from '@angular/core';
import { MultiselectOptionItem, UiConstants } from '../../util/core-utils';
import { Order, OrderType } from '../util/services';
import { SearchUtils } from '../../util/search-utils';
import { BaseSearch, BaseSearchService } from '../base.search-service';
import { StockItem } from './stock-item.service';
import { LoggedInUserStorage } from '../util/storages';
import {CustomerRecordMultiselectItem} from "../customer/customer-record-multiselect-provider.service";

/* eslint-enable */

@Injectable()
export class StockItemCustomerRecordPriceSearchService extends BaseSearchService {

  protected createStorage(): StockItemCustomerRecordPriceSearch.Storage {
    return new StockItemCustomerRecordPriceSearch.Storage();
  }

}

export namespace StockItemCustomerRecordPriceSearch {

  export class Model extends SearchUtils.SearchModel {

    customUnitPriceFrom: string = '';
    customUnitPriceTo: string = '';
    customerRecords: CustomerRecordMultiselectItem[] = [];

    isEmpty(): boolean {
      return this.customUnitPriceFrom === ''
        && this.customUnitPriceTo === ''
        && this.customerRecords.length === 0;
    }

    reset() {
      this.customUnitPriceFrom = '';
      this.customUnitPriceTo = '';
      this.customerRecords = [];
    }

  }

  export interface SearchDataGetRequest extends BaseSearch.SearchDataGetRequest {
    stockItemId: number;
  }

  export interface SearchDataResult {
    searchData: SearchData;
  }

  export interface SearchDataSetRequest extends BaseSearch.SearchDataSetRequest {
    stockItemId: number;
  }

  export interface SearchData extends BaseSearch.SearchData {
    order: Order<StockItem.CustomerRecordPriceOrderField>;
    customUnitPriceFrom: string;
    customUnitPriceTo: string;
    customerRecords: CustomerRecordMultiselectItem[];
  }

  interface StoredSearchRawData extends BaseSearch.StoredSearchRawData {
    order: Order<StockItem.CustomerRecordPriceOrderField>;
    customUnitPriceFrom: string;
    customUnitPriceTo: string;
    customerRecords: CustomerRecordMultiselectItem[];
  }

  export class Storage extends BaseSearch.Storage {

    constructor() {
      const defaultSearchData = {
        itemsPerPage: UiConstants.pageNumbers[0],
        pageNumber: 1,
        order: {
          field: StockItem.CustomerRecordPriceOrderField.ID,
          type: OrderType.DESC
        },
        customUnitPriceFrom: '',
        customUnitPriceTo: '',
        customerRecords: []
      };
      /**
       * Increment this version if you change the SearchData interface.
       */
      const versionNumber: number = 2;
      super(defaultSearchData,
        versionNumber);
    }

    protected generateKey(request: SearchDataGetRequest | SearchDataSetRequest): string {
      return 'UserId(' + LoggedInUserStorage.getInstance().getUserId()
        + ')-Search-StockItemCustomerRecordPrice-StockItemId(' + request.stockItemId + ')';
    }

    protected fromRaw(data: StoredSearchRawData): BaseSearch.SearchDataResult {
      const searchData: SearchData = {
        itemsPerPage: data.itemsPerPage,
        pageNumber: data.pageNumber,
        order: data.order,
        customUnitPriceFrom: data.customUnitPriceFrom,
        customUnitPriceTo: data.customUnitPriceTo,
        customerRecords: data.customerRecords
      };
      return {
        searchData: searchData
      };
    }

    protected toRaw(data: SearchData): StoredSearchRawData {
      return {
        version: this.VERSION,
        itemsPerPage: data.itemsPerPage,
        pageNumber: data.pageNumber,
        order: data.order,
        customUnitPriceFrom: data.customUnitPriceFrom,
        customUnitPriceTo: data.customUnitPriceTo,
        customerRecords: data.customerRecords
      };
    }

  }

}
