<div [ngSwitch]="type" class="container p-0">
  <div class="data-container" [class.d-none]="type !== CanvasSelectionType.NONE || readonly">
    <div class="card-header d-flex justify-content-between">
      {{'WORKFLOW_EDIT_ITEMS' | translate}}
    </div>
    <div class="card-body">
      <div class="card draw2d_droppable mb-1"
           data-shape="task">
        <div class="card-header border-bottom-0">
          {{'WORKFLOW_EDIT_TASK_ITEM' | translate}}
        </div>
      </div>
      <div class="card draw2d_droppable"
           data-shape="group" [attr.data-id]="'WORKFLOW_EDIT_GROUP_ITEM' | translate">
        <div class="card-header border-bottom-0">
          {{'WORKFLOW_EDIT_GROUP_ITEM' | translate}}
        </div>
      </div>
    </div>
  </div>
  <div class="data-container" *ngSwitchCase="CanvasSelectionType.TASK">
    <form class="form-horizontal bordered-row" (ngSubmit)="taskForm.form.valid && saveTask()" #taskForm="ngForm"
          novalidate>
      <div class="card-header d-flex justify-content-between">
        {{'WORKFLOW_EDIT_TASK_DETAILS' | translate}}

        <a class="btn-setting cursor-pointer" (click)="deleteTask(figure)" *ngIf="!readonly"
           title="{{'COMMON_BUTTON_DELETE' | translate}}"><i class="icomoon icomoon-trash"></i></a>
      </div>
      <div class="card-body fixed-card-body"
           [class.fixed-card-body-readonly]="readonly">
        <div class="task-name-container">
          <span class="task-name" *ngIf="!rightModel.taskUpdate.hasRight()">{{taskEditModel.name}}</span>
          <a *ngIf="rightModel.taskUpdate.hasRight()"
             class="id-text cursor-pointer"
             href="#" onclick="return false"
             uiSref="Admin.TaskEdit"
             [uiParams]="{ id: taskEditModel.id }"
             title="{{'COMMON_BUTTON_EDIT' | translate}}">
            {{taskEditModel.name}}
          </a>

          <span class="task-id">ID:{{taskEditModel.id}}</span>
          <span class="task-id">WT ID:{{taskEditModel.objectId}}</span>
        </div>
        <label class="task-defaults-title">{{'WORKFLOW_EDIT_TASK_DEFAULTS_TITLE' | translate}}</label>
        <br>
        <div class="form-group mb-0"
             *ngIf="taskEditModel.allowWorkflowTaskSelector()"
             [ngClass]="{ 'has-danger': taskForm.submitted && !inheritWorkflowTask.valid }">
          <label class="form-control-label mt-1"
                 [class.required-field-label]="!readonly">
            {{ 'WORKFLOW_EDIT_INHERIT_WORKFLOW_TASK' | translate }}
          </label>
          <angular2-multiselect [data]="_workflowTasks"
                                [settings]="dropdownSettingsForWorkflowTask"
                                class="form-control"
                                id="inheritWorkflowTask"
                                name="inheritWorkflowTask"
                                #inheritWorkflowTask="ngModel"
                                [(ngModel)]="taskEditModel._inheritFromWorkflowTask"
                                [readonly]="readonly"
                                [readonlyBorderEnabled]="true"
                                validateEnabledItems
                                required>
          </angular2-multiselect>
          <div class="form-control-feedback" *ngIf="taskForm.submitted && !taskAssigneeInheritanceMode.valid">
            {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
          </div>
        </div>
        <div class="form-group mb-0"
             [ngClass]="{ 'has-danger': taskForm.submitted && !taskAssigneeInheritanceMode.valid }">
          <label class="form-control-label mt-1"
                 [class.required-field-label]="!readonly">
            {{ 'WORKFLOW_EDIT_TASK_DEFAULTS_ASSIGNEE' | translate }}
          </label>
          <angular2-multiselect [data]="assigneeInheritanceModes"
                                [settings]="dropdownSettingsForInheritanceMode"
                                class="form-control"
                                id="taskAssigneeInheritanceMode"
                                name="taskAssigneeInheritanceMode"
                                #taskAssigneeInheritanceMode="ngModel"
                                [(ngModel)]="taskEditModel.assigneeInheritanceMode"
                                (ngModelChange)="onAssigneeInheritanceModeChanged()"
                                [readonly]="readonly"
                                [readonlyBorderEnabled]="true"
                                required>
          </angular2-multiselect>
          <div class="form-control-feedback" *ngIf="taskForm.submitted && !taskAssigneeInheritanceMode.valid">
            {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
          </div>
        </div>
        <div
          *ngIf="WorkflowTaskEditModel.getInheritanceMode(taskEditModel.assigneeInheritanceMode) === InheritanceMode.WORKFLOW_TASK">
          <label class="mt-1">{{'WORKFLOW_EDIT_TASK_DEFAULT_ASSIGNEE_USER' | translate}}</label>
          <angular2-multiselect [data]="users"
                                [settings]="dropdownSettingsForAssignee"
                                class="form-control"
                                [(ngModel)]="taskEditModel._defaultAssigneeUser"
                                [ngModelOptions]="{standalone: true}"
                                (onRemoteSearch)="loadUsers($event.target.value)"
                                (onSelect)="onUserChanged()"
                                [readonly]="readonly"
                                [readonlyBorderEnabled]="true">
          </angular2-multiselect>
          <label class="mt-1">{{'WORKFLOW_EDIT_TASK_DEFAULT_ASSIGNEE_USER_GROUP' | translate}}</label>
          <angular2-multiselect [data]="userGroups"
                                [settings]="dropdownSettingsForAssignee"
                                class="form-control"
                                [(ngModel)]="taskEditModel._defaultAssigneeUserGroup"
                                [ngModelOptions]="{standalone: true}"
                                (onRemoteSearch)="loadUserGroups($event.target.value)"
                                (onSelect)="onUserGroupChanged()"
                                [readonly]="readonly"
                                [readonlyBorderEnabled]="true">
          </angular2-multiselect>
        </div>
        <label class="mt-1">{{'WORKFLOW_EDIT_TASK_DEFAULTS_NAME' | translate}}</label>
        <app-material-tag-input
          [(ngModel)]="taskEditModel.namePattern"
          [ngModelOptions]="{standalone: true}"
          [placeholderStringKey]="'WORKFLOW_EDIT_TASK_DEFAULTS_NAME'"
          [autocompleteItems]="ProcessTaskRecordConfigNameTemplate.availableSequenceBlocks"
          [separatorCharacter]="ProcessTaskRecordConfigNameTemplate.sequenceBlockSeparator"
          [generatedStringExampleMap]="ProcessTaskRecordConfigNameTemplate.sequenceBlockExampleMap"
          [separatorKeyCodes]="ProcessTaskRecordConfigNameTemplate.separatorKeysCodes"
          [shouldGenerateExample]="true"
          [readonly]="readonly">
        </app-material-tag-input>
        <div class="form-group mb-0"
             [ngClass]="{ 'has-danger': taskForm.submitted && !taskCustomerInheritanceMode.valid }">
          <label class="form-control-label mt-1"
                 [class.required-field-label]="!readonly">
            {{ 'WORKFLOW_EDIT_TASK_DEFAULTS_CUSTOMER' | translate }}
          </label>
          <angular2-multiselect [data]="inheritanceModes"
                                [settings]="dropdownSettingsForInheritanceMode"
                                class="form-control"
                                id="taskCustomerInheritanceMode"
                                name="taskCustomerInheritanceMode"
                                #taskCustomerInheritanceMode="ngModel"
                                [(ngModel)]="taskEditModel.customerInheritanceMode"
                                [readonly]="readonly"
                                [readonlyBorderEnabled]="true"
                                required>
          </angular2-multiselect>
          <div class="form-control-feedback" *ngIf="taskForm.submitted && !taskCustomerInheritanceMode.valid">
            {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
          </div>
        </div>
        <div class="form-group mb-0"
             [ngClass]="{ 'has-danger': taskForm.submitted && !taskPriorityInheritanceMode.valid }">
          <label class="form-control-label mt-1"
                 [class.required-field-label]="!readonly">
            {{ 'WORKFLOW_EDIT_TASK_DEFAULTS_PRIORITY' | translate }}
          </label>
          <angular2-multiselect [data]="inheritanceModes"
                                [settings]="dropdownSettingsForInheritanceMode"
                                class="form-control"
                                id="taskPriorityInheritanceMode"
                                name="taskPriorityInheritanceMode"
                                #taskPriorityInheritanceMode="ngModel"
                                [(ngModel)]="taskEditModel.priorityInheritanceMode"
                                [readonly]="readonly"
                                [readonlyBorderEnabled]="true"
                                required>
          </angular2-multiselect>
          <div class="form-control-feedback" *ngIf="taskForm.submitted && !taskPriorityInheritanceMode.valid">
            {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
          </div>
        </div>
        <div class="form-group mb-0"
             [ngClass]="{ 'has-danger': taskForm.submitted && !taskDeadlineInheritanceMode.valid }">
          <label class="form-control-label mt-1"
                 [class.required-field-label]="!readonly">
            {{ 'WORKFLOW_EDIT_TASK_DEFAULTS_DEADLINE' | translate }}
          </label>
          <angular2-multiselect [data]="inheritanceModes"
                                [settings]="dropdownSettingsForInheritanceMode"
                                class="form-control"
                                id="taskDeadlineInheritanceMode"
                                name="taskDeadlineInheritanceMode"
                                #taskDeadlineInheritanceMode="ngModel"
                                [(ngModel)]="taskEditModel.deadlineInheritanceMode"
                                [readonly]="readonly"
                                [readonlyBorderEnabled]="true"
                                required>
          </angular2-multiselect>
          <div class="form-control-feedback" *ngIf="taskForm.submitted && !taskDeadlineInheritanceMode.valid">
            {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
          </div>
        </div>
        <div class="form-group mb-0"
             [ngClass]="{ 'has-danger': taskForm.submitted && !taskProjectInheritanceMode.valid }">
          <label class="form-control-label mt-1"
                 [class.required-field-label]="!readonly">
            {{ 'WORKFLOW_EDIT_TASK_DEFAULTS_PROJECT' | translate }}
          </label>
          <angular2-multiselect [data]="inheritanceModes"
                                [settings]="dropdownSettingsForInheritanceMode"
                                class="form-control"
                                id="taskProjectInheritanceMode"
                                name="taskProjectInheritanceMode"
                                #taskProjectInheritanceMode="ngModel"
                                [(ngModel)]="taskEditModel.projectInheritanceMode"
                                [readonly]="readonly"
                                [readonlyBorderEnabled]="true"
                                required>
          </angular2-multiselect>
          <div class="form-control-feedback" *ngIf="taskForm.submitted && !taskProjectInheritanceMode.valid">
            {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
          </div>
        </div>
        <div class="form-group mb-0"
             [ngClass]="{ 'has-danger': taskForm.submitted && !taskAttachmentInheritanceMode.valid }">
          <label class="form-control-label mt-1"
                 [class.required-field-label]="!readonly">
            {{ 'WORKFLOW_EDIT_TASK_DEFAULTS_ATTACHMENT' | translate }}
          </label>
          <angular2-multiselect [data]="inheritanceModes"
                                [settings]="dropdownSettingsForInheritanceMode"
                                class="form-control"
                                id="taskAttachmentInheritanceMode"
                                name="taskAttachmentInheritanceMode"
                                #taskAttachmentInheritanceMode="ngModel"
                                [(ngModel)]="taskEditModel.attachmentInheritanceMode"
                                [readonly]="readonly"
                                [readonlyBorderEnabled]="true"
                                required>
          </angular2-multiselect>
          <div class="form-control-feedback" *ngIf="taskForm.submitted && !taskPdfInheritanceMode.valid">
            {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
          </div>
        </div>
        <div class="form-group mb-0"
             [ngClass]="{ 'has-danger': taskForm.submitted && !taskPdfInheritanceMode.valid }">
          <label class="form-control-label mt-1"
                 [class.required-field-label]="!readonly">
            {{ 'WORKFLOW_EDIT_TASK_DEFAULTS_PDF' | translate }}
          </label>
          <angular2-multiselect [data]="inheritanceModes"
                                [settings]="dropdownSettingsForInheritanceMode"
                                class="form-control"
                                id="taskPdfInheritanceMode"
                                name="taskPdfInheritanceMode"
                                #taskPdfInheritanceMode="ngModel"
                                [(ngModel)]="taskEditModel.pdfInheritanceMode"
                                [readonly]="readonly"
                                [readonlyBorderEnabled]="true"
                                required>
          </angular2-multiselect>
          <div class="form-control-feedback" *ngIf="taskForm.submitted && !taskPdfInheritanceMode.valid">
            {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
          </div>
        </div>
        <div class="form-group mb-0"
             [ngClass]="{ 'has-danger': taskForm.submitted && !taskContractNumberInheritanceMode.valid }">
          <label class="form-control-label mt-1"
                 [class.required-field-label]="!readonly">
            {{ 'WORKFLOW_EDIT_TASK_DEFAULTS_CONTRACT_NUMBER' | translate }}
          </label>
          <angular2-multiselect [data]="inheritanceModes"
                                [settings]="dropdownSettingsForInheritanceMode"
                                class="form-control"
                                id="taskContractNumberInheritanceMode"
                                name="taskContractNumberInheritanceMode"
                                #taskContractNumberInheritanceMode="ngModel"
                                [(ngModel)]="taskEditModel.contractNumberInheritanceMode"
                                [readonly]="readonly"
                                [readonlyBorderEnabled]="true"
                                required>
          </angular2-multiselect>
          <div class="form-control-feedback" *ngIf="taskForm.submitted && !taskContractNumberInheritanceMode.valid">
            {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
          </div>
        </div>
        <div class="form-group mb-0"
             [ngClass]="{ 'has-danger': taskForm.submitted && !taskPlaceOfConsumptionInheritanceMode.valid }">
          <label class="form-control-label mt-1"
                 [class.required-field-label]="!readonly">
            {{ 'WORKFLOW_EDIT_TASK_DEFAULTS_POC' | translate }}
          </label>
          <angular2-multiselect [data]="inheritanceModes"
                                [settings]="dropdownSettingsForInheritanceMode"
                                class="form-control"
                                id="taskPlaceOfConsumptionInheritanceMode"
                                name="taskPlaceOfConsumptionInheritanceMode"
                                #taskPlaceOfConsumptionInheritanceMode="ngModel"
                                [(ngModel)]="taskEditModel.placeOfConsumptionInheritanceMode"
                                [readonly]="readonly"
                                [readonlyBorderEnabled]="true"
                                required>
          </angular2-multiselect>
          <div class="form-control-feedback" *ngIf="taskForm.submitted && !taskPlaceOfConsumptionInheritanceMode.valid">
            {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
          </div>
        </div>
        <div class="form-group mb-0"
             [ngClass]="{ 'has-danger': taskForm.submitted && !taskFormFieldInheritanceMode.valid }">
          <label class="form-control-label mt-1"
                 [class.required-field-label]="!readonly">
            {{ 'WORKFLOW_EDIT_TASK_DEFAULTS_FORM_FIELD' | translate }}
          </label>
          <angular2-multiselect [data]="inheritanceModes"
                                [settings]="dropdownSettingsForInheritanceMode"
                                class="form-control"
                                id="taskFormFieldInheritanceMode"
                                name="taskFormFieldInheritanceMode"
                                #taskFormFieldInheritanceMode="ngModel"
                                [(ngModel)]="taskEditModel.formFieldInheritanceMode"
                                [readonly]="readonly"
                                [readonlyBorderEnabled]="true"
                                required>
          </angular2-multiselect>
          <div class="form-control-feedback" *ngIf="taskForm.submitted && !taskFormFieldInheritanceMode.valid">
            {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
          </div>
        </div>
        <label class="mt-1"> {{ 'WORKFLOW_EDIT_TASK_DEFAULTS_OPEN_TASK_RECORD_TITLE' | translate }} </label>
        <div class="d-flex">
          <app-animated-checkbox
            [(selected)]="taskEditModel.openTaskRecordOnCreate"
            [readonly]="readonly">
          </app-animated-checkbox>
          <span class="ml-1">{{ 'WORKFLOW_EDIT_TASK_DEFAULTS_OPEN_TASK_RECORD' | translate }}</span>
        </div>
      </div>
      <div class="card-footer" *ngIf="!readonly">
        <button type="submit" class="btn btn-primary w-100" [ladda]="taskEditModel.saveInProgress"
                [disabled]="taskEditModel.saveInProgress">
          {{'COMMON_BUTTON_SAVE' | translate}}
        </button>
      </div>
    </form>
  </div>
  <div class="data-container" *ngSwitchCase="CanvasSelectionType.GROUP">
    <div class="card-header d-flex justify-content-between">
      {{'WORKFLOW_EDIT_GROUP_DETAILS' | translate}}

      <a class="btn-setting cursor-pointer" (click)="deleteGroup(figure)" *ngIf="!readonly"
         title="{{'COMMON_BUTTON_DELETE' | translate}}"><i class="icomoon icomoon-trash"></i></a>
    </div>
    <div class="card-body fixed-card-body"
         [class.fixed-card-body-readonly]="readonly">
      <label>{{'WORKFLOW_EDIT_GROUP_NAME' | translate}}</label>
      <input type="text" class="form-control" placeholder="{{'WORKFLOW_EDIT_GROUP_NAME' | translate}}"
             maxlength="{{UiConstants.maximumVarcharLength}}"
             [(ngModel)]="groupEditModel.name"
             [readOnly]="readonly">
      <label class="task-defaults-title">{{'WORKFLOW_EDIT_GROUP_TASKS' | translate}}</label>
      <div class="group-task-container mt-1"
           *ngFor="let task of groupEditModel.tasks; let i = index"
           (click)="canvas.setCurrentSelection(groupEditModel.children[i])">
        <span class="task-name">{{task.name}}</span>
        <span class="task-id">ID:{{task.id}}</span>
      </div>
      <div *ngIf="groupEditModel.tasks.length === 0" class="d-flex justify-content-center mt-1">
        <label class="text-muted">{{'WORKFLOW_EDIT_GROUP_NO_TASKS' | translate}}</label>
      </div>
    </div>
    <div class="card-footer" *ngIf="!readonly">
      <button type="button" class="btn btn-primary w-100" [ladda]="groupEditModel.saveInProgress"
              [disabled]="groupEditModel.saveInProgress"
              (click)="saveGroup()">{{'COMMON_BUTTON_SAVE' | translate}}
      </button>
    </div>
  </div>
  <div class="data-container" *ngSwitchCase="CanvasSelectionType.TRANSITION">
    <div class="card-header d-flex justify-content-between">
      {{'WORKFLOW_EDIT_TRANSITION_DETAILS' | translate}}

      <a class="btn-setting cursor-pointer" (click)="deleteTransition()" *ngIf="!readonly"
         title="{{'COMMON_BUTTON_DELETE' | translate}}"><i class="icomoon icomoon-trash"></i></a>
    </div>
    <div class="card-body fixed-card-body"
         [class.fixed-card-body-readonly]="readonly">
      <label>{{'WORKFLOW_EDIT_TRANSITION_TASK_RECORD_STATES' | translate}}</label>
      <angular2-multiselect [data]="taskRecordStates"
                            [settings]="dropdownSettingsForTaskRecordState"
                            class="form-control"
                            [(ngModel)]="transitionEditModel.taskRecordStates"
                            [readonly]="readonly"
                            [readonlyBorderEnabled]="true">
      </angular2-multiselect>
      <label class="task-defaults-title my-1">{{'WORKFLOW_EDIT_TRANSITION_FORM_FIELD_RULES' | translate}}</label>
      <div class="d-flex" *ngIf="!transitionEditModel.addRuleModel && !readonly">
      <button type="button" class="btn btn-secondary-applion-green-gray col-6 mr-05"
              (click)="newFormFieldRule()">{{'WORKFLOW_EDIT_TRANSITION_NEW_FORM_FIELD_RULE' | translate}}
      </button>
      <button type="button" class="btn btn-secondary-applion-green-gray col-6"
              (click)="newTaskRule()">{{'WORKFLOW_EDIT_TRANSITION_NEW_TASK_RULE' | translate}}
      </button>
      </div>
      <app-base-rule-edit *ngIf="transitionEditModel.addRuleModel"
                                [model]="transitionEditModel.addRuleModel"
                                [operatorRequired]="transitionEditModel.rules.length !== 0"
                                [create]="true"
                                [taskIds]="transitionEditModel.taskIds"
                                (createSubmitted)="ruleCreateSubmitted($event)">
      </app-base-rule-edit>
      <hr *ngIf="!readonly">
      <div *ngIf="transitionEditModel.rules.length === 0" class="d-flex justify-content-center">
        <label class="text-muted">{{'WORKFLOW_EDIT_RULE_NO_RULES' | translate}}</label>
      </div>
      <ng-container *ngFor="let rule of transitionEditModel.rules; let i = index">
        <div *ngIf="!rule.editing" class="rule-container mt-half cursor-pointer"
             [ngClass]="{'disabled-rule': rule.disabled}" (click)="editRule(i)">
          {{rule.name}}
        </div>
        <app-base-rule-edit *ngIf="rule.editing"
                                  [model]="rule"
                                  [operatorRequired]="i !== 0"
                                  [taskIds]="transitionEditModel.taskIds"
                                  (deleteRule)="deleteRule(i)"
                                  [readonly]="readonly">
        </app-base-rule-edit>
      </ng-container>
    </div>
    <div class="card-footer" *ngIf="!readonly">
      <button type="button" class="btn btn-primary w-100" [ladda]="transitionEditModel.saveInProgress"
              [disabled]="transitionEditModel.saveInProgress"
              (click)="saveTransition()">{{'COMMON_BUTTON_SAVE' | translate}}
      </button>
    </div>
  </div>
  <div class="data-container" *ngSwitchCase="CanvasSelectionType.MULTI">
    <div class="card-header d-flex justify-content-between">
      {{'WORKFLOW_EDIT_MULTI_TITLE' | translate}}

      <a class="btn-setting cursor-pointer" (click)="deleteMulti()" *ngIf="!readonly"
         title="{{'COMMON_BUTTON_DELETE' | translate}}"><i class="icomoon icomoon-trash"></i></a>
    </div>
    <div class="card-body fixed-card-body"
         [class.fixed-card-body-readonly]="readonly">
      {{'WORKFLOW_EDIT_MULTI_DETAILS' | translate: {count: multiEditModel.figures.length} }}
    </div>
  </div>
</div>
