import {Component, Inject, OnInit} from '@angular/core';
import {EmptyMessage} from "../../../../lib/util/messages";
import {StringKey} from "../../../../app.string-keys";
import {ErrorMessage} from "../../../../lib/error-message-parser.service";
import {BodyOutputType} from "../../../../fork/angular2-toaster/src/bodyOutputType";
import {TaskRecord, TaskRecordService} from "../../../../lib/task/task-record.service";
import {TranslateService} from "@ngx-translate/core";
import {UiConstants} from "../../../../util/core-utils";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ToasterService} from "../../../../fork/angular2-toaster/src/toaster.service";
import {NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {TaskRecordStateMachine} from "../../../../lib/task/task-record-statemachine";
import {Set} from "immutable";
import {NgbDatePickerParserFormatter} from "../../../../util/ngb-datepicker";
import {LocalDate} from "../../../../lib/util/dates";
import {TranslateUtils} from "../../../../util/translate";

@Component({
  selector: 'app-task-record-state-change-dialog',
  templateUrl: './task-record-state-change-dialog.component.html',
  styleUrls: ['./task-record-state-change-dialog.component.scss']
})
export class TaskRecordStateChangeDialogComponent implements OnInit {

  UiConstants = UiConstants;
  TaskRecord = TaskRecord;

  stateChangeModel?: StateChangeModel;
  loading: boolean = false;

  constructor(private taskRecordService: TaskRecordService,
              private translateService: TranslateService,
              private toasterService: ToasterService,
              private ngbDatePickerParserFormatter: NgbDatePickerParserFormatter,
              private dialog: MatDialog,
              public dialogRef: MatDialogRef<TaskRecordStateChangeDialogComponent, TaskRecordStateChangeDialogResult>,
              @Inject(MAT_DIALOG_DATA) public data: TaskRecordStateChangeDialogData) {
  }

  ngOnInit(): void {
    this.stateChangeModel = new StateChangeModel(this.data.newState, this.data.ids, this.ngbDatePickerParserFormatter);
  }

  public static openDialog(
    dialog: MatDialog,
    data: TaskRecordStateChangeDialogData,
    resultCallback: (result?: TaskRecordStateChangeDialogResult) => void): MatDialogRef<TaskRecordStateChangeDialogComponent> {
    const dialogRef = dialog.open(TaskRecordStateChangeDialogComponent, {
      closeOnNavigation: true,
      data: data,
      width: '60%',
      height: 'auto',
      panelClass: 'custom-dialog-container'
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      resultCallback({success: result});
    });
    return dialogRef;
  }

  tryStateChange() {
    if (!this.loading && this.stateChangeModel && this.stateChangeModel.settableStateTaskRecordIds.size > 0) {
      this.loading = true;
      this.taskRecordService.setState({
        newState: this.stateChangeModel.settableState,
        taskRecordIdSet: this.stateChangeModel.settableStateTaskRecordIds,
        stateChangeMessage: this.stateChangeModel.massStateChangeMessage,
        checkUpDate: this.stateChangeModel.checkUpdateLocalDate,
      }).subscribe((result: EmptyMessage | TaskRecord.RevertResultResponse) => {
          this.loading = false;
          if (result.hasOwnProperty('result')) {
            this.parseRevertResult((<TaskRecord.RevertResultResponse>result).result);
          } else {
            this.toasterService.pop({
              timeout: UiConstants.ToastTimeoutShort,
              type: UiConstants.toastTypeSuccess,
              title: this.translateService.instant(StringKey.TASKS_STATE_MODIFY),
              body: this.translateService.instant(StringKey.TASK_STATE_CHANGE_SUCCESS)
            });
          }
          this.close(true);
        },
        (error: ErrorMessage | TaskRecord.TaskRecordInvoiceGeneratorException | TaskRecord.TaskRecordStockItemChangeException) => {
          this.loading = false;
          let errorMessage: string = '';
          let errorTitleKey: string = StringKey.TASKS_STATE_MODIFY;
          if (error instanceof TaskRecord.TaskRecordInvoiceGeneratorException) {
            error.errors.forEach(value => {
              errorMessage += value.taskRecordId + ':<br/>';
              errorMessage += value.messages.join('<br/>');
              errorMessage += '<br/>';
            });
            errorMessage.trim();
          }
          if (error instanceof TaskRecord.TaskRecordStockItemChangeException) {
            errorTitleKey = StringKey.STOCK_ITEM_CHANGE_ERROR_TITLE;
            error.errors.forEach(value => {
              errorMessage += value.errors.map(e => this.translateService.instant(StringKey.STOCK_ITEM_CHANGE_ERROR, {
                taskRecordId: value.taskRecordId,
                stockRecordName: e.stockRecordName,
                newAmount: e.newAmount,
                currentAmount: e.currentAmount,
              }))
                .join('<br/>');
              errorMessage += '<br/>';
            });
            errorMessage.trim();
          }
          if (error instanceof ErrorMessage) {
            error.globalErrors.forEach(e => {
              errorMessage += e + '<br/>';
            });
            errorMessage.trim();
          }

          this.toasterService.pop({
            timeout: UiConstants.ToastTimeoutLong,
            type: UiConstants.toastTypeError,
            bodyOutputType: BodyOutputType.TrustedHtml,
            title: this.translateService.instant(errorTitleKey),
            body: errorMessage.length > 0 ? errorMessage : this.translateService.instant(StringKey.TASK_STATE_CHANGE_ERROR)
          });
          this.close(false);
        });
    }
  }

  parseRevertResult(result: TaskRecord.RevertResult) {
    this.toasterService.clear();
    let toastType: string = UiConstants.toastTypeInfo;
    let toastMessageKey: string = 'COMMON_VALUE_TASK_STATE_EDIT_REVERTED';
    switch (result) {
      case TaskRecord.RevertResult.EACH_REVERT_DONE:
        toastType = UiConstants.toastTypeSuccess;
        toastMessageKey = 'TASK_RECORD_REVERT_RESULT_EACH_REVERT_DONE';
        break;
      case TaskRecord.RevertResult.EACH_REVERT_FAILED:
        toastType = UiConstants.toastTypeError;
        toastMessageKey = 'TASK_RECORD_REVERT_RESULT_EACH_REVERT_FAILED';
        break;
      case TaskRecord.RevertResult.EMPTY_ID_SET:
        toastType = UiConstants.toastTypeInfo;
        toastMessageKey = 'TASK_RECORD_REVERT_RESULT_EMPTY_ID_SET';
        break;
      case TaskRecord.RevertResult.FAILED_TO_SAVE_REVERT_STATE:
        toastType = UiConstants.toastTypeError;
        toastMessageKey = 'TASK_RECORD_REVERT_RESULT_FAILED_TO_SAVE_REVERT_STATE';
        break;
      case TaskRecord.RevertResult.INIT_ERROR:
        toastType = UiConstants.toastTypeError;
        toastMessageKey = 'TASK_RECORD_REVERT_RESULT_INIT_ERROR';
        break;
      case TaskRecord.RevertResult.INVALID_TASK_RECORD_STATE:
        toastType = UiConstants.toastTypeError;
        toastMessageKey = 'TASK_RECORD_REVERT_RESULT_INVALID_TASK_RECORD_STATE';
        break;
      case TaskRecord.RevertResult.SOME_REVERT_DONE:
        toastType = UiConstants.toastTypeWarning;
        toastMessageKey = 'TASK_RECORD_REVERT_RESULT_SOME_REVERT_DONE';
        break;
    }
    const toastTitleKey: string = 'COMMON_VALUE_TASK_STATE_EDIT_REVERTED';
    this.translateService.get([toastTitleKey, toastMessageKey]).subscribe((o) => {
      this.toasterService.pop({
        type: toastType,
        timeout: UiConstants.ToastTimeoutLong,
        title: TranslateUtils.extractValueFromObject(o, toastTitleKey),
        body: TranslateUtils.extractValueFromObject(o, toastMessageKey)
      });
    });
  }

  close(success: boolean) {
    this.dialogRef.close({success: success});
  }

  getStateChangeString(): string {
    if (this.stateChangeModel && this.stateChangeModel.settableState.stringEditMessage) {
      return this.translateService.instant(this.stateChangeModel.settableState.stringEditMessage);
    }
    return '';
  }

}

export class TaskRecordStateChangeDialogData {
  newState: TaskRecordStateMachine.StateObject;
  ids: Set<number>;
}

export class TaskRecordStateChangeDialogResult {
  success: boolean;
}

class StateChangeModel {
  public massStateChangeMessage: string;
  public checkUpDate: NgbDateStruct | null = null;

  get count(): number {
    if (this.settableStateTaskRecordIds) {
      return this.settableStateTaskRecordIds.size;
    }
    return 0;
  }
  constructor(public settableState: TaskRecordStateMachine.StateObject, public settableStateTaskRecordIds: Set<number>, private ngbDatePickerParserFormatter: NgbDatePickerParserFormatter) {
    this.massStateChangeMessage = '';
  }

  get canCheckUp(): boolean {
    return this.settableState.state === 'ARCHIVED';
  }

  get isRevert(): boolean {
    return this.settableState.state === 'REVERTED';
  }

  get checkUpdateLocalDate(): LocalDate | undefined {
    const ld = this.ngbDatePickerParserFormatter.toLocalDate(this.checkUpDate);
    return ld !== null ? ld : undefined;
  }

}
