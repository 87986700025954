/* eslint-disable */
import { Command, CommandManager } from '../../../../util/command';
import {
  FormRecordBooleanFieldView,
  FormRecordFieldValueReference,
  FormRecordFieldView,
  FormRecordListItemFieldView,
  FormRecordListMultiItemFieldView,
  SetTmpReadonlyResult,
} from '../../../../util/form/form-utils';
import { List as ImmutableList } from 'immutable';
import { Toast, ToasterService } from '../../../../fork/angular2-toaster/angular2-toaster';
import { TranslateService } from '@ngx-translate/core';
import { CommandResultStore } from '../command-result-store';
import { UiConstants } from '../../../../util/core-utils';
import { Logger, LoggerFactory } from '../../../../util/logger-factory';
import { FieldActivationStrategyFactory } from './activation-strategy/field-activation-strategy-factory';
import { FieldViewItemCollection } from './field-view-item-collection';
import { FieldViewItemChain, FieldViewItemChainBuilder } from './field-view-item-chain';
import { FieldViewItem } from './form-record-inactivity-manager-api';
import { ElementRef, Injectable, ViewRef } from '@angular/core';
import { Form } from '../../../../lib/form/form.service';
import { FormRecordFieldHolderDirective } from '../shared/form-record-field-holder.directive';
import { FieldViewItemOrder } from './field-view-item-order';
import FormFieldValidationType = Form.FormFieldValidationType;
/* eslint-enable */

export const noOpCommand: Command<SetTmpReadonlyResult> = {
  execute: async () => {
    return {
      changed: false
    };
  },
  undo: async () => {
  }
};

export interface FormRecordInactivityManagerArgs {
  toasterService: ToasterService;
  translateService: TranslateService;
  fieldActivationStrategyFactory: FieldActivationStrategyFactory;
  commandManager: CommandManager<SetTmpReadonlyResult>;
  commandResultStore: CommandResultStore;
  readonlyForm: () => boolean;
}

export interface FormRecordInactivityManagerCreateArgs {
  commandManager: CommandManager<SetTmpReadonlyResult>;
  commandResultStore: CommandResultStore;
  readonlyForm: () => boolean;
}

export interface FormRecordInactivityManagerAddViewArgs {
  form: Form.Form;
  groupId: number;
  groupHost?: ElementRef;
  fieldViews: ImmutableList<FormRecordFieldView>;
  fieldOrder: ImmutableList<number | null>;
  fieldHost: FormRecordFieldHolderDirective;
}

@Injectable()
export class FormRecordInactivityManagerFactory {

  constructor(
    private toasterService: ToasterService,
    private translateService: TranslateService,
    private fieldActivationStrategyFactory: FieldActivationStrategyFactory) {
  }

  create(args: FormRecordInactivityManagerCreateArgs): FormRecordInactivityManager {
    return new FormRecordInactivityManager({
      toasterService: this.toasterService,
      translateService: this.translateService,
      fieldActivationStrategyFactory: this.fieldActivationStrategyFactory,
      commandManager: args.commandManager,
      commandResultStore: args.commandResultStore,
      readonlyForm: args.readonlyForm,
    });
  }

}

export class FormRecordInactivityManager {

  private readonly logger: Logger = LoggerFactory.createLogger('FormRecordInactivityManager');

  private readonly views: FieldViewItemCollection;
  private activationChain: FieldViewItemChain;
  private orders: Map<number, FieldViewItemOrder> = new Map<number, FieldViewItemOrder>();
  private groupHosts: Map<number, ElementRef> = new Map<number, ElementRef>();
  private fieldHosts: Map<number, FormRecordFieldHolderDirective> = new Map<number, FormRecordFieldHolderDirective>();
  private detachedViews: Map<number, ViewRef> = new Map<number, ViewRef>();

  constructor(private args: FormRecordInactivityManagerArgs) {
    this.views = new FieldViewItemCollection();
  }

  public addViews(args: FormRecordInactivityManagerAddViewArgs) {
    this.views.addFieldViews(args.fieldViews);
    this.views.filter((viewItem) => {
      return !viewItem.field.disabled;
    });
    this.orders.set(args.groupId, new FieldViewItemOrder(args.fieldOrder));
    if (args.groupHost) {
      this.groupHosts.set(args.groupId, args.groupHost);
    }
    this.fieldHosts.set(args.groupId, args.fieldHost);
  }

  public createLogic(form: Form.Form) {
    this.activationChain = this.createActivationChain(form, this.views);
    this.executeActivationLogic(this.activationChain);
  }

  /**
   * A field changed (by the user) that is not able to (in)activate other fields.
   */
  public onGeneralFieldChangedByUser(activatorView: FormRecordFieldView) {
    this.clear();
  }

  /**
   * A Boolean field changed (by the user).
   */
  public onBooleanFieldChangedByUser(activatorView: FormRecordBooleanFieldView) {
    this.handleActivationWithUndo(activatorView.valueReference);
  }

  /**
   * A ListItem field changed (by the user).
   */
  public onListItemFieldChangedByUser(activatorView: FormRecordListItemFieldView) {
    this.handleActivationWithUndo(activatorView.valueReference);
  }

  /**
   * A ListMultiItem field changed (by the user).
   */
  public onListMultiItemFieldChangedByUser(activatorView: FormRecordListMultiItemFieldView) {
    this.handleActivationWithUndo(activatorView.valueReference);
  }

  private createActivationChain(form: Form.Form, viewItems: FieldViewItemCollection): FieldViewItemChain {
    const builder = new FieldViewItemChainBuilder(form, this.args.fieldActivationStrategyFactory);
    viewItems.forEach((viewItem: FieldViewItem) => {
      this.args.fieldActivationStrategyFactory.getStrategy(viewItem.field.dataTypeSelector).putRules({
        form: form,
        builder: builder,
        viewItems: viewItems,
        viewItem: viewItem
      });
    });
    return builder.build();
  }

  private async executeActivationLogic(activationChain: FieldViewItemChain) {
    if (activationChain.acyclic) {
      /**
       * Each form has its own inactivity manager.
       * We execute the activation logic after the manager is initialized;
       * it is safe because each dependency is provided in the constructor and the form container is already initialized.
       */
      const commands: Command<SetTmpReadonlyResult>[] = [];
      await this.executeActivationCommands(commands);
    }
    else {
      /**
       * Cyclic activation settings. Warn the user that the activation logic is disabled.
       * This is a general warning so we show it only at the first time
       * to prevent a lot of warning toast (in case of inner forms).
       */
      this.showInvalidDefinitionToastAtFirstTime();
    }
  }

  private async handleActivationWithUndo<T>(valueReference: FormRecordFieldValueReference<T>) {
    if (!this.activationChain.acyclic) {
      return;
    }
    const commands: Command<SetTmpReadonlyResult>[] = this.createNewCommandsWithUndo(valueReference);
    await this.executeActivationCommands(commands);
  }

  private async executeActivationCommands(commands: Command<SetTmpReadonlyResult>[]) {
    // APPWORKS-10827
    // intentional logic change: do not skip readonly form anymore
    // if (this.args.readonlyForm()) {
    //   this.logger.debug('readonly form; skip');
    //   return;
    // }
    const undoPossible = commands.length > 0;
    await this.activationChain.refreshActivationData();
    this.activationChain.forEach((viewItem: FieldViewItem) => {
      // APPWORKS-10250
      // intentional logic change: do not skip readonly fields anymore
      // if (Form.FormFieldValidationType.READONLY === viewItem.validationType) {
      //   this.logger.debug('readonly field; skip', viewItem);
      //   return;
      // }
      this.refreshViewItemVisibility(viewItem);
      const active = viewItem.activationData.active;
      this.logger.debug(active ? 'activate' : 'inactivate', viewItem);
      commands.push(viewItem.view.setTmpReadonly({
        tmpReadonly: !active
      }));
    });
    await this.executeCommandsWithRevertToast(commands, undoPossible);
  }

  private refreshViewItemVisibility(viewItem: FieldViewItem) {
    const activationChanged = viewItem.activationData.activationChanged;
    const active = viewItem.activationData.active;
    const hidden = viewItem.formRecordFieldContext.validationType === FormFieldValidationType.HIDDEN;
    // hidden fields shouldn't be shown, but they can be needed for activation

    if (activationChanged || hidden) {
      if (active && !hidden) {
        this.insertViewItem(viewItem);
      }
      else {
        this.removeViewItem(viewItem)
      }
    }
  }

  private insertViewItem(viewItem: FieldViewItem) {
    const groupHost = this.groupHosts.get(viewItem.groupId);
    const fieldHost = this.fieldHosts.get(viewItem.groupId)!;
    const viewContainerRef = fieldHost.viewContainerRef;
    const detachedView = this.detachedViews.get(viewItem.fieldId);
    this.detachedViews.delete(viewItem.fieldId);
    const order = this.orders.get(viewItem.groupId)!;
    const insertIndex = order.insert(viewItem.fieldId, viewItem.field.displayOnNewRow);
    if (detachedView) {
      if (viewItem.field.displayOnNewRow) {
        const lineBreakView = fieldHost.createLineBreakView();
        viewContainerRef.insert(detachedView, insertIndex);
        viewContainerRef.insert(lineBreakView.hostView, insertIndex);
      }
      else {
        viewContainerRef.insert(detachedView, insertIndex);
      }
      if (groupHost) {
        groupHost.nativeElement.style.display = 'block';
      }
    }
  }

  private removeViewItem(viewItem: FieldViewItem) {
    const groupHost = this.groupHosts.get(viewItem.groupId);
    const removeIndex = this.orders.get(viewItem.groupId)!.remove(viewItem.fieldId, viewItem.field.displayOnNewRow);
    if (removeIndex !== undefined) {
      const viewContainerRef = this.fieldHosts.get(viewItem.groupId)!.viewContainerRef;
      const detachedView = viewContainerRef.detach(removeIndex)!;
      if (viewItem.field.displayOnNewRow && removeIndex > 0) {
        viewContainerRef.remove(removeIndex - 1);
      }
      this.detachedViews.set(viewItem.fieldId, detachedView);
      if (groupHost && viewContainerRef.length === 0) {
        groupHost.nativeElement.style.display = 'none';
      }
    }
  }

  /**
   * This toast is good for two reasons:
   * - When the user does not know that the checkbox will purge one or more data, one-time undo is possible.
   * - When the form appears and the record contains outdated data the system purge the data automatically.
   *   In this case the user is notified about the change, but undo is not possible.
   */
  private async executeCommandsWithRevertToast(
    commands: Command<SetTmpReadonlyResult>[], undoPossible: boolean): Promise<void> {
    this.clear(); // clear before command execution to remove previous changes
    await this.processCommands(commands);
    if (this.args.commandResultStore.hasChangedField()) {
      // Display only one toast that undo each command if inputChanged so there is data loss.
      this.showUndoToast(undoPossible);
    }
  }

  private async processCommands(commands: Command<SetTmpReadonlyResult>[]): Promise<void> {
    for (const command of commands) {
      const result = await this.args.commandManager.executeCommand(command);
      this.args.commandResultStore.add(result);
    }
  }

  private async undoCommands() {
    await this.args.commandManager.undoAll();
    await this.activationChain.refreshActivationData();
    this.activationChain.forEach((viewItem: FieldViewItem) => {
      this.refreshViewItemVisibility(viewItem);
    });
  }

  /**
   * Clears the command stack and removes the toast.
   */
  private clear() {
    this.args.commandResultStore.toastIds.forEach((toastId) => {
      this.args.toasterService.clear(toastId);
    });
    this.args.commandResultStore.clear();
    this.args.commandManager.clear();
  }

  private createNewCommandsWithUndo<T>(valueReference: FormRecordFieldValueReference<T>): Command<SetTmpReadonlyResult>[] {
    const previousValue = valueReference.previous();
    const commands: Command<SetTmpReadonlyResult>[] = [];
    commands.push({
      execute: async () => {
        return {
          changed: false
        };
      },
      undo: async () => {
        valueReference.set(previousValue);
      }
    });
    return commands;
  }

  private showUndoToast(undoPossible: boolean) {
    const undoButtonText = this.args.translateService.instant('COMMON_BUTTON_UNDO');
    const titleText = this.args.translateService.instant('COMMON_TOAST_TITLE_READONLY_STATE_REVERT');
    const bodyText = this.args.translateService.instant(
      undoPossible ? 'COMMON_TOAST_BODY_READONLY_STATE_REVERT' : 'COMMON_TOAST_BODY_READONLY_STATE_NOTICE');
    const poppedToast = this.args.toasterService.pop({
      type: undoPossible ? UiConstants.toastTypeInfo : UiConstants.toastTypeWarning,
      title: titleText,
      body: bodyText,
      timeout: UiConstants.ToastTimeoutInfinite,
      showCloseButton: undoPossible,
      closeHtml: `
        <button type="button" class="btn btn-info" style="margin-top: 1.5em; margin-right: 0.5em;">
          <i class="glyphicons glyphicons-undo" style="vertical-align: middle;"></i>
          <span>${undoButtonText}</span>
        </button>
      `,
      clickHandler: (toast: Toast, isCloseButton?: boolean) => {
        if (isCloseButton === true) {
          this.undoCommands();
        }
        else {
          this.args.commandManager.clear();
        }
        this.args.commandResultStore.clear();
        return true;
      }
    });
    this.args.commandResultStore.addToastId(poppedToast.toastId!);
  }

  private showInvalidDefinitionToastAtFirstTime() {
    if (this.args.commandResultStore.toastIds.length !== 0) {
      return;
    }
    const titleText = this.args.translateService.instant('COMMON_TOAST_TITLE_READONLY_STATE_INVALID');
    const bodyText = this.args.translateService.instant('COMMON_TOAST_BODY_READONLY_STATE_INVALID');
    const poppedToast = this.args.toasterService.pop({
      type: UiConstants.toastTypeWarning,
      title: titleText,
      body: bodyText,
      timeout: UiConstants.ToastTimeoutInfinite,
      showCloseButton: false,
      clickHandler: (toast: Toast, isCloseButton?: boolean) => {
        this.args.commandManager.clear();
        this.args.commandResultStore.clear();
        return true;
      }
    });
    this.args.commandResultStore.addToastId(poppedToast.toastId!);
  }

}
