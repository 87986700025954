import { Directive, HostBinding, Input } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { PaletteColorPickerComponent } from './palette-color-picker/palette-color-picker.component';

@Directive({
  selector: '[appTableColoredRow]'
})
export class TableColoredRowDirective {

  @Input()
  colorIndex: number = 0;

  @Input()
  rowIndex: number = 0;

  @HostBinding('style')
  get coloredStyle(): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(`background-color: ${this.getRowColor()};`);
  }

  constructor(private sanitizer: DomSanitizer) {
  }

  getRowColor() {
    if (this.colorIndex === 0) {
      return '';
    }

    if (this.rowIndex % 2 === 0) {
      return PaletteColorPickerComponent.getListColorEven(this.colorIndex);
    }
    return PaletteColorPickerComponent.getListColorOdd(this.colorIndex);
  }
}
