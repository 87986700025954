/* eslint-disable */
import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../../../lib/auth.service';
import {MultiselectOptionItem, OptionItem, QueryFieldModel, SelectUtils, UiConstants} from '../../../util/core-utils';
import {UserData, UserDataLoader, UserDataLoaderPermissionDeniedStrategy} from '../../../lib/user-data-loader';
import {Arrays} from '../../../lib/util/arrays';
import {UploadErrorLocalizer} from '../../../util/upload-error-localizer';
import {Stock} from '../../../lib/stock/stock.service';
import {Set} from 'immutable';
import {
  StockItem,
  StockItemQuery,
  StockItemService,
  StockItemType,
  stockItemTypes,
  StockItemTypeUrlMapper
} from '../../../lib/stock/stock-item.service';
import {Numbers} from '../../../lib/util/numbers';
import {FileUploadDialogComponent} from '../../../shared/file-upload/dialog/file-upload-dialog.component';
import {
  StockItemSearchModel,
  StockItemTypeItem,
  StockItemUtils
} from '../../../util/stock/stock-item-utils';
import {Strings} from '../../../lib/util/strings';
import {InputMask} from '../../../util/input-masks';
import {UIRouter} from '@uirouter/angular';
import {StateName} from '../../../app.state-names';
import {Models} from '../../../util/model-utils';
import {BreadcrumbParent} from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import {RightModel} from '../../../app.rights';
import {RightResolver, RightService} from '../../../lib/right.service';
import {combineLatest, Observable} from 'rxjs';
import {StockItemSearch, StockItemSearchService} from '../../../lib/stock-item-search-service';
import {Currency, CurrencyService} from '../../../lib/currency.service';
import {StringKey} from '../../../app.string-keys';
import {OrderType, QueryResult, ResourceQueryResult, Services} from '../../../lib/util/services';
import {
  StockItemCategory,
  StockItemCategoryService
} from '../../../lib/stock-item-category/stock-item-category.service';
import {Angular2Multiselects} from '../../../util/multiselect';
import {EmptyMessage, IdentityArray} from '../../../lib/util/messages';
import {DisabledEnum, DisabledItem} from '../../../util/search-utils';
import {CompanyMultiselectProvider} from '../../../lib/company/company-multiselect.provider';
import {DownloadedFile} from '../../../lib/util/downloaded-files';
import {saveAs} from 'file-saver';
import {AlertType, ConfirmDialogComponent} from '../../../shared/confirm-dialog/confirm-dialog.component';
import {DropdownItemType} from '../../../shared/dropdown/dropdown-item/dropdown-item-type';
import {MatDialog} from '@angular/material/dialog';
import {
  StockItemCreateTypeSelectorDialogComponent
} from '../stock-item-create-type-selector/stock-item-create-type-selector-dialog.component';
import {LedgerNumberMultiselectProvider} from '../../../lib/ledger/number/ledger-number-multiselect.provider';
import {
  StockItemCategoryMultiselectProvider
} from "../../../lib/stock-item-category/stock-item-category-multiselect.provider";

/* eslint-enable */

@Component({
  selector: 'app-stock-item-list',
  templateUrl: 'stock-item-list.component.html',
  styleUrls: ['stock-item-list.component.scss']
})
export class StockItemListComponent implements OnInit, AfterViewInit, OnDestroy {
  StockItem = StockItem;
  StockItemUtils = StockItemUtils;
  InputMask = InputMask;
  Currency = Currency;
  UiConstants = UiConstants;
  AlertType = AlertType;
  BulkDisableOperationMode = BulkDisableOperationMode;
  DropdownItemType = DropdownItemType;

  queryModel: QueryFieldModel<StockItem.OrderField> = new QueryFieldModel(StockItem.OrderField.ID, OrderType.DESC);
  users: IdentityArray<UserData> | null = null;
  stockItemList: StockItemListModel[] = [];
  disabledItems: DisabledItem[] = [];
  showSearch: boolean = false;
  searchModel: StockItemSearchModel = new StockItemSearchModel();
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  rightModel: RightModel = RightModel.empty();

  @ViewChild('importDialog', {static: true}) importDialog: FileUploadDialogComponent;
  uploadPath: string;

  @ViewChild('stockItemBulkDisableDialog', {static: true}) stockItemBulkDisableDialog: ConfirmDialogComponent;

  SelectUtils = SelectUtils;

  selectableCurrencies: Currency.Currency[] = [];
  stockItemTypes: StockItemTypeItem[] = [];
  companies: MultiselectOptionItem<number>[] = [];
  applicationClassifications: MultiselectOptionItem<string>[] = [];
  ledgerNumbers: MultiselectOptionItem<number>[] = [];
  productCategories: MultiselectOptionItem<number>[] = [];
  itemsToBeDisabledCount: number;
  bulkDisableOperationMode: BulkDisableOperationMode = BulkDisableOperationMode.DISABLE;

  dropdownSettingsForCompanies: Angular2Multiselects.Settings;
  dropdownSettingsLocalMulti: Angular2Multiselects.Settings = Angular2Multiselects.LOCAL_MULTI_SELECT_TRANSLATE;
  dropdownSettingsForProductCategories: Angular2Multiselects.Settings;

  constructor(
    private uiRouter: UIRouter,
    private translateService: TranslateService,
    private uploadErrorLocalizer: UploadErrorLocalizer,
    private companyMultiselectProvider: CompanyMultiselectProvider,
    private ledgerNumberMultiselectProvider: LedgerNumberMultiselectProvider,
    private authService: AuthService,
    private userDataLoader: UserDataLoader,
    private stockItemService: StockItemService,
    private stockItemSearchService: StockItemSearchService,
    private currencyService: CurrencyService,
    private rightService: RightService,
    private dialog: MatDialog,
    private stockItemCategoryMultiselectProvider: StockItemCategoryMultiselectProvider) {
    this.uploadPath = '/stock-items/import-xls';
  }

  ngOnInit() {
    this.loadRightModels();
    this.initDropdownSettings();
    this.initDisabledOptions(DisabledEnum.FALSE);
    this.translateService.get('MENU_NAVBAR_PRODUCTS').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.loadSearch(() => {
      this.showSearch = !this.searchModel.isEmpty();
      this.loadList();
      this.loadCompanies();
      this.loadApplicationClassifications();
    });
  }

  private loadSearch(completion: () => void) {
    const obs: Observable<SearchLoadResult> = combineLatest(
      this.stockItemSearchService.getSearchData({}),
      (storedSearchData: StockItemSearch.SearchDataResult) => {
        const result: SearchLoadResult = {
          storedSearchData: storedSearchData
        };
        return result;
      }
    );
    obs.subscribe(
      (result: SearchLoadResult) => {
        this.postInitSearch(result.storedSearchData);
        this.loadTypes(result.storedSearchData);
        completion();
      }
    );
  }

  private createDefaultTypeItem(): StockItemTypeItem {
    const def = new StockItemTypeItem();
    def.id = null;
    def.text = '';
    this.translateService.get(StringKey.COMMON_VALUE_UNSELECTED).subscribe(
      (text: string) => {
        def.text = text;
      }
    );
    return def;
  }

  private loadTypes(storedSearchData: StockItemSearch.SearchDataResult) {
    this.stockItemTypes = [];
    const storedId = storedSearchData.searchData.type;
    const def = this.createDefaultTypeItem();
    this.stockItemTypes.push(def);
    this.searchModel.type = def;
    stockItemTypes.forEach(t => {
      const item: StockItemTypeItem = {
        id: t.type,
        text: '....',
        iconRes: t.iconRes
      };
      this.stockItemTypes.push(item);
      this.translateService.get(t.stringKey).subscribe((text: string) => {
        item.text = text;
      });
    });
    if (storedId) {
      this.stockItemTypes.forEach((item) => {
        if (item.id === storedId) {
          this.searchModel.type = item;
        }
      });
    }
  }

  private loadApplicationClassifications() {
    this.applicationClassifications = [
      {id: 'MOBILE', itemName: 'HISTORY_APPLICATION_CLASS_TYPE_MOBILE'},
      {id: 'ADMIN', itemName: 'HISTORY_APPLICATION_CLASS_TYPE_ADMIN'},
    ];
  }

  getStockItemTypeText(type: StockItemType): string {
    const item = this.stockItemTypes.find(t => t.id === type);
    return item ? item.text : '';
  }

  getStockItemTypeIconRes(type: StockItemType): string {
    const item = this.stockItemTypes.find(t => t.id === type);
    return item ? item.iconRes : '';
  }

  private postInitSearch(storedSearchData: StockItemSearch.SearchDataResult) {
    this.queryModel.itemsPerPage = storedSearchData.searchData.itemsPerPage;
    this.queryModel.currentPage = storedSearchData.searchData.pageNumber;
    this.queryModel.setOrder(storedSearchData.searchData.order);
    this.searchModel.name = storedSearchData.searchData.name;
    this.searchModel.externalId = storedSearchData.searchData.externalId;
    this.searchModel.productCode = storedSearchData.searchData.productCode;
    this.searchModel.companies = storedSearchData.searchData.companies;
    this.searchModel.ledgerNumbers = storedSearchData.searchData.ledgerNumbers;
    this.searchModel.unit = storedSearchData.searchData.unit;
    this.searchModel.description = storedSearchData.searchData.description;
    this.searchModel.vtszNumber = storedSearchData.searchData.vtszNumber;
    this.searchModel.createdOn = storedSearchData.searchData.createdOn;
    this.searchModel.unitPrice.vatRate = storedSearchData.searchData.unitPriceVatRate;
    this.searchModel.unitPriceFrom = storedSearchData.searchData.unitPriceFrom;
    this.searchModel.unitPriceTo = storedSearchData.searchData.unitPriceTo;
    this.searchModel.productCategory = storedSearchData.searchData.productCategory;
    this.searchModel.weightFrom = storedSearchData.searchData.weightFrom;
    this.searchModel.weightTo = storedSearchData.searchData.weightTo;
    this.searchModel.place = storedSearchData.searchData.place;
    this.searchModel.serialCode = storedSearchData.searchData.serialCode;
    this.searchModel.categories = storedSearchData.searchData.categories;
    this.searchModel.disabled = storedSearchData.searchData.disabled;
    this.loadCurrencies(storedSearchData.searchData.unitPriceCurrencyCode);
  }

  private saveSearch() {
    const request = {
      searchData: {
        itemsPerPage: this.queryModel.itemsPerPage,
        pageNumber: this.queryModel.currentPage,
        order: this.queryModel.getOrder(),
        name: this.searchModel.name,
        type: OptionItem.idOrUndefined(this.searchModel.type),
        externalId: this.searchModel.externalId,
        productCode: this.searchModel.productCode,
        companies: this.searchModel.companies,
        ledgerNumbers: this.searchModel.ledgerNumbers,
        unit: this.searchModel.unit,
        description: this.searchModel.description,
        vtszNumber: this.searchModel.vtszNumber,
        createdOn: this.searchModel.createdOn,
        created_in: Strings.undefinedOrNonEmpty(this.searchModel.createdOn.map(c => c.id).join(',')),
        unitPriceFrom: this.searchModel.unitPriceFrom,
        unitPriceTo: this.searchModel.unitPriceTo,
        unitPriceCurrencyCode: Strings.undefinedOrNonEmpty(this.searchModel.unitPrice.currency.currencyCode),
        unitPriceVatRate: this.searchModel.unitPrice.vatRate,
        productCategory: this.searchModel.productCategory,
        weightFrom: this.searchModel.weightFrom,
        weightTo: this.searchModel.weightTo,
        place: this.searchModel.place,
        serialCode: this.searchModel.serialCode,
        categories: this.searchModel.categories,
        disabled: this.searchModel.disabled,
      }
    };
    this.stockItemSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  ngAfterViewInit(): void {
    this.loadUserData();
  }

  onSearchClicked() {
    this.deselectAll();
    this.loadList(1);
  }

  onSearchReset() {
    this.deselectAll();
    this.stockItemSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.searchModel.companies = [];
          this.searchModel.ledgerNumbers = [];
          this.searchModel.categories = [];
          this.showSearch = true;
          this.loadList(1);
        });
      }
    );
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: StockItem.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  private initDisabledOptions(initValue: DisabledEnum) {
    this.disabledItems = [];
    const disabledEnums: DisabledEnum[] = [DisabledEnum.NONE, DisabledEnum.FALSE, DisabledEnum.TRUE];
    Arrays.iterateByIndex(disabledEnums, (key) => {
      const item = new DisabledItem();
      item.id = key;
      this.translateService.get('COMMON_VALUE_DISABLED_ENUM_' + key).subscribe(
        (text: string) => {
          item.text = text;
        }
      );
      this.disabledItems.push(item);
      if (key === initValue) {
        this.searchModel.disabled = item;
      }
    });
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  private loadUserData() {
    this.userDataLoader.loadAll(UserDataLoaderPermissionDeniedStrategy.MISS_ALL).subscribe(
      (users: IdentityArray<UserData>) => {
        this.users = users;
      }
    );
  }

  private loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    this.stockItemService.query(this.getQueryRequest(requestedPage))
      .subscribe(
        (result: ResourceQueryResult<StockItem>) => {
          this.stockItemList = result.items;
          this.stockItemList.forEach(item => {
            item.selected = false;
            if (item.stock_amount) {
              let amount = 0;
              item.stock_amount.forEach(stock => {
                amount += stock.amount;
              });
              if (amount > 0) {
                item.inStockAmount = amount;
              }
            }
          });
          this.queryModel.currentPage = requestedPage;
          this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
          this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
        }
      );
  }

  private getQueryRequest(pageNumber?: number): StockItemQuery {
    const order = this.queryModel.getOrder();
    const type = this.searchModel.type ?
      this.searchModel.type.id === null ? undefined : this.searchModel.type.id! : undefined;
    const disabled: boolean | undefined = !this.searchModel.disabled ||
    this.searchModel.disabled.id === DisabledEnum.NONE ?
      undefined : this.searchModel.disabled.id === DisabledEnum.TRUE;
    const stockCategoryIdSet: number[] = [];
    this.searchModel.categories.forEach((item) => {
      stockCategoryIdSet.push(item.id);
    });
    return {
      name: Strings.undefinedOrNonEmpty(this.searchModel.name),
      type: type,
      disabled: disabled,
      external_id_part: Strings.undefinedOrNonEmpty(this.searchModel.externalId),
      product_code_part: Strings.undefinedOrNonEmpty(this.searchModel.productCode),
      company_ids: Strings.undefinedOrNonEmpty(this.searchModel.companies.map(c => c.id).join(',')),
      ledger_number_ids: Strings.undefinedOrNonEmpty(this.searchModel.ledgerNumbers.map(c => c.id).join(',')),
      hun_vtsz_number: Strings.undefinedOrNonEmpty(this.searchModel.vtszNumber),
      created_on: Strings.undefinedOrNonEmpty(this.searchModel.createdOn.map(c => c.id).join(',')),
      unit_vat_rate: Numbers.undefinedOrNonEmpty(Models.parseNumber(this.searchModel.unitPrice.vatRate + '')),
      unit: Strings.undefinedOrNonEmpty(this.searchModel.unit),
      unit_price_from: Numbers.undefinedOrNonEmpty(Models.parseNumber(this.searchModel.unitPriceFrom + '')),
      unit_price_to: Numbers.undefinedOrNonEmpty(Models.parseNumber(this.searchModel.unitPriceTo + '')),
      unit_currency_code: this.searchModel.unitPrice.currency
        ? Strings.undefinedOrNonEmpty(this.searchModel.unitPrice.currency.currencyCode)
        : undefined,
      product_category: Strings.undefinedOrNonEmpty(this.searchModel.productCategory),
      weight_in_grams_from: Numbers.undefinedOrNonEmpty(Models.parseNumber(this.searchModel.weightFrom + '')),
      weight_in_grams_to: Numbers.undefinedOrNonEmpty(Models.parseNumber(this.searchModel.weightTo + '')),
      place: Strings.undefinedOrNonEmpty(this.searchModel.place),
      serial_code: Strings.undefinedOrNonEmpty(this.searchModel.serialCode),
      category_ids: stockCategoryIdSet.length > 0 ? stockCategoryIdSet.join(',') : undefined,
      page_number: pageNumber ? pageNumber : undefined,
      number_of_items: pageNumber ? this.queryModel.itemsPerPage : undefined,
      order: Services.createOrderFieldParameter(StockItem.Keys.toOrderFieldKey, Set.of(order)),
      with_amount: true
    };
  }

  getOwnerUserPersonName(stock: Stock): string | undefined {
    const users = this.users;
    const owners: string[] = [];

    if (users === null) {
      return '...';
    }
    if (!stock || !stock.owner_user_ids) {
      return undefined;
    }
    stock.owner_user_ids.forEach(id => {
      const result = Arrays.findById(users, id);
      if (result) {
        owners.push(result.person_name!);
      }
    });
    return owners.join(', ');
  }

  setDisabled(event: any, stock: StockItemListModel, disabled: boolean) {
    this.stockItemService.setDisabled({
      id: stock.id,
      disabled: disabled
    })
      .subscribe(
        (result: EmptyMessage) => {
          this.loadList(1);
        },
        (error: any) => {
          this.loadList(1);
        }
      );
  }

  getUnitPrice(stockItem: StockItem): string {
    if (stockItem.unit_price === null || stockItem.unit_price.value === null) {
      return '';
    }
    return Models.numberToString(stockItem.unit_price.value);
  }

  openStockItemCreateTypeSelectorDialog() {
    const dialogRef = this.dialog.open(StockItemCreateTypeSelectorDialogComponent);
    dialogRef.afterClosed().subscribe(type => {
      if (type) {
        this.showStockItemCreate(type);
      }
    });
  }

  showStockItemCreate(stockItemType: StockItemType) {
    const stockItemTypeUrlParam = StockItemTypeUrlMapper.toUrl(stockItemType);
    this.uiRouter.stateService.go(StateName.STOCK_ITEM_CREATE, {
      stockItemType: stockItemTypeUrlParam
    });
  }

  public onImportSuccess(succeeded: boolean) {
    if (succeeded) {
      this.loadList(1);
    }
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  private loadCurrencies(unitPriceCurrencyCode?: string) {
    if (unitPriceCurrencyCode) {
      this.currencyService.query({currencyCode: unitPriceCurrencyCode}).subscribe((result) => {
        if (result.items.size === 1) {
          this.searchModel.unitPrice.currency = result.items.get(0);
        }
      });
    }
    this.selectableCurrencies = [];
    this.defaultSelectableCurrency(() => {
        this.currencyService.query({}).subscribe((result: QueryResult<Currency.Currency>) => {
          result.items.forEach(c => {
            if (c) {
              this.selectableCurrencies.push(c);
            }
          })
          ;
        });
      },
      unitPriceCurrencyCode);
  }

  private defaultSelectableCurrency(completion: () => void, unitPriceCurrencyCode?: string) {
    this.translateService.get(StringKey.COMMON_VALUE_UNSELECTED).subscribe((text) => {
      const def: Currency.Currency = {currencyCode: '', localizedName: text};
      this.selectableCurrencies.push(def);
      if (!unitPriceCurrencyCode) {
        this.searchModel.unitPrice.currency = def;
      }
      completion();
    });
  }

  loadCompanies(q?: string) {
    this.companyMultiselectProvider.loadAll(q)
      .subscribe((result: MultiselectOptionItem<number>[]) => {
        this.companies = result;
      });
  }

  loadLedgerNumbers(q?: string) {
    if (this.rightModel.ledgerNumberRead.hasRight()) {
      this.ledgerNumberMultiselectProvider.loadAll(q)
        .subscribe((result: MultiselectOptionItem<number>[]) => {
          this.ledgerNumbers = result;
        });
    }
  }

  loadProductCategories(q?: string) {
    this.stockItemCategoryMultiselectProvider.loadActive(
      q
    ).subscribe((result: MultiselectOptionItem<number>[]) => {
      this.productCategories = result;
    });
  }

  getCurrencyName(currencyCode: string) {
    const cc = this.selectableCurrencies.find(c => c.currencyCode === currencyCode);
    return cc ? cc.localizedName : currencyCode;
  }

  exportXlsTemplate() {
    this.stockItemService.exportXlsTemplate().subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName('stock-item-xls-template.xlsx'));
      }
    );
  }

  exportXls() {
    const ids: number[] = this.stockItemList.filter(item => !!item.selected).map(item => item.id);
    let request: StockItemQuery;
    if (ids.length === 0) {
      request = this.getQueryRequest();
    } else {
      request = {
        id: ids.join()
      };
    }
    this.stockItemService.exportXls(request).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName('stock-item.xlsx'));
      }
    );
  }

  initDropdownSettings() {
    this.dropdownSettingsForCompanies = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .remoteSearch(true)
      .build();
    this.dropdownSettingsForProductCategories = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .remoteSearch(true)
      .enableCheckAll(true)
      .build();
  }

  deselectAll() {
    this.stockItemList.forEach((stockItem) => {
      stockItem.selected = false;
    });
  }

  toggleEachStockItem() {
    const eachStockItemSelected = !this.eachStockItemSelected;
    this.stockItemList.forEach((stockItem) => {
      stockItem.selected = eachStockItemSelected;
    });
  }

  get eachStockItemSelected(): boolean {
    if (this.stockItemList.length === 0) {
      return false;
    }
    let selected: boolean | undefined = true;
    this.stockItemList.forEach((stockItem) => {
      selected = selected && stockItem.selected;
    });
    return selected;
  }

  openBulkDisableDialog() {
    let ids: number[] = this.stockItemList.filter(item => !!item.selected).map(item => item.id);
    this.itemsToBeDisabledCount = ids.length;
    if (ids.length === 0) {
      const request = this.getQueryRequest();
      this.stockItemService.query(request).subscribe(result => {
        this.itemsToBeDisabledCount = result.pagingResult.currentNumberOfItems;
        ids = result.items.map(item => item.id);
        this.stockItemBulkDisableDialog.showDialog(ids);
      });
    } else {
      this.stockItemBulkDisableDialog.showDialog(ids);
    }
  }

  bulkDisable(ids: number[]) {
    this.stockItemService.bulkDisable({
      ids: ids,
      disabled: this.bulkDisableOperationMode === BulkDisableOperationMode.DISABLE
    }).subscribe(() => {
      this.loadList(1);
      this.deselectAll();
    });
  }

  ngOnDestroy() {
    this.saveSearch();
  }
}

interface SearchLoadResult {
  storedSearchData: StockItemSearch.SearchDataResult,
}

interface StockItemListModel extends StockItem {
  selected?: boolean;
  inStockAmount?: number;
}

enum BulkDisableOperationMode {
  ENABLE, DISABLE
}
