<div class="card">

  <div class="card-header">
    {{'NFC_CARD_LIST_TABLE_HEADER' | translate}}

    <div class="card-actions">
      <a *ngIf="!readonly && rightModel.userNfcCardCreate.hasRight()" class="btn-setting cursor-pointer" (click)="addNfcCard()"
         title="{{'COMMON_ADD' | translate}}"><i class="icomoon icomoon-add"></i></a>
    </div><!-- /.card-actions -->
  </div>

  <div class="card-body">
    <div class="p-2 text-center text-muted"
         *ngIf="nfcCardList.length === 0">
      <h6>{{ "NFC_CARD_LIST_NO_CARDS" | translate }}</h6>
    </div>
    <table class="table table-striped table-bordered" *ngIf="nfcCardList.length > 0">
      <thead>
      <tr>
        <th class="table-sorter-header long">
          <app-table-sorter-no-op [text]="'NFC_CARD_HEX_ID' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header w-20">
          <app-table-sorter-no-op [text]="'COMMON_TABLE_HEADER_CREATION_TIME' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header w-20">
          <app-table-sorter-no-op [text]="'NFC_CARD_MOBILE_REGISTRATION_STATUS' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header w-table-actions-single" *ngIf="!readonly">
          <app-table-sorter-no-op [text]="'COMMON_TABLE_HEADER_ACTIONS' | translate"></app-table-sorter-no-op>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let nfcCard of nfcCardList">
         <td>{{nfcCard.hexId}}</td>
        <td class="hidden-sm-down">{{nfcCard.creationTime.toUtcIsoString() | date:'short'}}</td>
        <td class="hidden-sm-down">
          {{getNfcCardRegistrationStatus(nfcCard.mobileApplicationRegistrationCount)
          | translate:{registrationCount: nfcCard.mobileApplicationRegistrationCount} }}
        </td>
        <td  *ngIf="!readonly">
          <a class="icons-table-group" href="#" onclick="return false" (click)="deleteNfcCard(nfcCard.id)"
             *ngIf="rightModel.userNfcCardDelete.hasRight()">
            <i class="icomoon icons-table-item icomoon-trash icomoon-table-icon"
               title="{{'COMMON_BUTTON_DELETE' | translate}}"></i>
          </a>
        </td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <th class="table-sorter-header long">
          <app-table-sorter-no-op [text]="'NFC_CARD_HEX_ID' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header w-20">
          <app-table-sorter-no-op [text]="'COMMON_TABLE_HEADER_CREATION_TIME' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header w-20">
          <app-table-sorter-no-op [text]="'NFC_CARD_MOBILE_REGISTRATION_STATUS' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header w-table-actions-single" *ngIf="!readonly">
          <app-table-sorter-no-op [text]="'COMMON_TABLE_HEADER_ACTIONS' | translate"></app-table-sorter-no-op>
        </th>
      </tr>
      </tfoot>
    </table>

  </div><!-- /.card-body -->

</div><!-- /.card -->
