export class DispersionPercentValidator {
  dispersionPercentValidationGroups: DispersionPercentValidationGroup[] = [];

  constructor() {
  }

  updateItem(categoryId: number, stockItemId: number, percentage: number) {
    const groupIndex = this.dispersionPercentValidationGroups.findIndex(g => g.categoryId === categoryId);
    if (groupIndex > -1) {
      this.dispersionPercentValidationGroups[groupIndex].updateItem(stockItemId, percentage)
    }
    else {
      const newGroup = new DispersionPercentValidationGroup(categoryId);
      newGroup.updateItem(stockItemId, percentage);
      this.dispersionPercentValidationGroups.push(newGroup);
    }
  }

  removeItem(categoryId: number, stockItemId: number) {
    const groupIndex = this.dispersionPercentValidationGroups.findIndex(g => g.categoryId === categoryId);
    if (groupIndex > -1) {
      this.dispersionPercentValidationGroups[groupIndex].removeItem(stockItemId)
    }
  }

  isValid() {
    return this.dispersionPercentValidationGroups.filter(g => !g.valid).length === 0;
  }

  isCategoryValid(categoryId: number): boolean {
    return this.dispersionPercentValidationGroups.filter(g => g.categoryId === categoryId && !g.valid).length === 0;
  }
}

// Group represents items in the same category
export class DispersionPercentValidationGroup {
  categoryId: number; // Note: -1 represents the 'other' category
  items: DispersionPercentValidationItem[] = [];
  valid: boolean = true;

  constructor(categoryId: number) {
    this.categoryId = categoryId;
  }

  updateItem(stockRecordId: number, percentageValue: number): void {
    const itemIndex = this.items.findIndex(i => i.stockRecordId === stockRecordId);
    if (itemIndex > -1) {
      this.items[itemIndex].stockRecordId = stockRecordId;
      this.items[itemIndex].percentageValue = percentageValue;
    }
    else {
      const newItem = {
        stockRecordId: stockRecordId,
        percentageValue: percentageValue
      };
      this.items.push(newItem);
    }
    this.valid = this.validate();
  }

  removeItem(stockRecordId: number): void {
    const itemIndex = this.items.findIndex(i => i.stockRecordId === stockRecordId);
    if (itemIndex > -1) {
      this.items.splice(itemIndex, 1);
    }
    this.valid = this.validate();
  }

  validate(): boolean {
    const sum = this.items.reduce((sum, current) => sum + current.percentageValue, 0);
    return sum === 100 || sum === 0;
  }
}

export interface DispersionPercentValidationItem {
  stockRecordId: number;
  percentageValue: number;
}
