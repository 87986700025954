import { UiConstants } from './core-utils';
import { StringKey } from '../app.string-keys';

export namespace Angular2Multiselects {

  export class Settings {

    public classes?: string;
    public labelKey?: string;
    public disabled?: boolean;
    public singleSelection?: boolean;
    public enableSearchFilter?: boolean;
    public enableCheckAll?: boolean;
    public enableFilterSelectAll?: boolean;
    public badgeShowLimit?: number;
    public noDataLabel?: string;
    public text?: string;
    public selectAllText?: string;
    public unSelectAllText?: string;
    public filterSelectAllText?: string;
    public filterUnSelectAllText?: string;
    public searchPlaceholderText?: string;
    public limitSelection?: number;
    public lazyLoading?: boolean;
    public searchBy?: string[];
    public showCheckbox?: boolean;
    public position?: string;
    public searchAutofocus?: boolean;
    public remoteSearch?: boolean;
    public autoPosition?: boolean;
    public translate?: boolean;
    public primaryKey?: string;

    constructor(settingsBuilder: SettingsBuilder) {
      this.classes = settingsBuilder._classes;
      this.labelKey = settingsBuilder._labelKey;
      this.disabled = settingsBuilder._disabled;
      this.singleSelection = settingsBuilder._singleSelection;
      this.enableSearchFilter = settingsBuilder._enableSearchFilter;
      this.enableCheckAll = settingsBuilder._enableCheckAll;
      this.enableFilterSelectAll = !settingsBuilder._singleSelection;
      this.badgeShowLimit = settingsBuilder._badgeShowLimit;
      this.noDataLabel = settingsBuilder._noDataLabel;
      this.text = settingsBuilder._text;
      this.selectAllText = settingsBuilder._selectAllText;
      this.unSelectAllText = settingsBuilder._unSelectAllText;
      this.filterSelectAllText = settingsBuilder._filterSelectAllText;
      this.filterUnSelectAllText = settingsBuilder._filterUnSelectAllText;
      this.searchPlaceholderText = settingsBuilder._searchPlaceholderText;
      this.limitSelection = settingsBuilder._limitSelection;
      this.lazyLoading = settingsBuilder._lazyLoading;
      this.searchBy
        = settingsBuilder._searchBy && settingsBuilder._searchBy.length > 0
        ? settingsBuilder._searchBy
        : settingsBuilder._labelKey
          ? [settingsBuilder._labelKey]
          : ['itemName'];
      this.showCheckbox = settingsBuilder._showCheckbox;
      this.position = settingsBuilder._position;
      this.searchAutofocus = settingsBuilder._searchAutofocus;
      this.remoteSearch = settingsBuilder._remoteSearch;
      this.autoPosition = settingsBuilder._autoPosition;
      this.translate = settingsBuilder._translate;
      this.primaryKey = settingsBuilder._primaryKey;
    }
  }

  export class SettingsBuilder {

    _classes?: string = UiConstants.angular2MultiselectClass;
    _labelKey?: string = 'itemName';
    _disabled?: boolean;
    _singleSelection?: boolean;
    _enableSearchFilter?: boolean;
    _enableCheckAll?: boolean = true;
    _badgeShowLimit?: number = UiConstants.angular2MultiselectUserGroupEditorBadgeLimit;
    _noDataLabel?: string = StringKey.COMMON_PLACEHOLDER_EMPTY_LIST;
    _text?: string = StringKey.COMMON_VALUE_UNSELECTED;
    _selectAllText?: string = StringKey.COMMON_PLACEHOLDER_SELECT_ALL;
    _unSelectAllText?: string = StringKey.COMMON_PLACEHOLDER_SELECT_ALL;
    _filterSelectAllText?: string = StringKey.COMMON_PLACEHOLDER_SELECT_ALL_FILTERED;
    _filterUnSelectAllText?: string = StringKey.COMMON_PLACEHOLDER_SELECT_ALL_FILTERED;
    _searchPlaceholderText?: string = StringKey.COMMON_PLACEHOLDER_SEARCH;
    _limitSelection?: number;
    _lazyLoading?: boolean;
    _searchBy?: string[] = [];
    _showCheckbox?: boolean = true;
    _position?: string = 'bottom';
    _searchAutofocus?: boolean = true;
    _remoteSearch?: boolean = false;
    _autoPosition?: boolean = false;
    _translate?: boolean = false;
    _primaryKey?: string = 'id';

    constructor() {
    }

    classes(value: string) {
      this._classes = value;
      return this;
    }

    labelKey(value: string) {
      this._labelKey = value;
      return this;
    }

    disabled(value: boolean) {
      this._disabled = value;
      return this;
    }

    singleSelection(value: boolean) {
      this._singleSelection = value;
      return this;
    }

    enableSearchFilter(value: boolean) {
      this._enableSearchFilter = value;
      return this;
    }

    enableCheckAll(value: boolean) {
      this._enableCheckAll = value;
      return this;
    }

    badgeShowLimit(value: number) {
      this._badgeShowLimit = value;
      return this;
    }

    noDataLabel(value: string) {
      this._noDataLabel = value;
      return this;
    }

    text(value: string) {
      this._text = value;
      return this;
    }

    selectAllText(value: string) {
      this._selectAllText = value;
      return this;
    }

    unSelectAllText(value: string) {
      this._unSelectAllText = value;
      return this;
    }

    filterSelectAllText(value: string) {
      this._filterSelectAllText = value;
      return this;
    }

    filterUnSelectAllText(value: string) {
      this._filterUnSelectAllText = value;
      return this;
    }

    searchPlaceholderText(value: string) {
      this._searchPlaceholderText = value;
      return this;
    }

    limitSelection(value: number) {
      this._limitSelection = value;
      return this;
    }

    lazyLoading(value: boolean) {
      this._lazyLoading = value;
      return this;
    }

    searchBy(value: string[]) {
      this._searchBy = value;
      return this;
    }

    showCheckbox(value: boolean) {
      this._showCheckbox = value;
      return this;
    }

    searchAutofocus(value: boolean) {
      this._searchAutofocus = value;
      return this;
    }

    autoPosition(value: boolean) {
      this._autoPosition = value;
      return this;
    }

    remoteSearch(value: boolean) {
      this._remoteSearch = value;
      return this;
    }

    translate(value: boolean) {
      this._translate = value;
      return this;
    }

    position(value: string) {
      this._position = value;
      return this;
    }

    primaryKey(value: string) {
      this._primaryKey = value;
      return this;
    }

    build(): Angular2Multiselects.Settings {
      return new Angular2Multiselects.Settings(this);
    }
  }

  export const EmptySearchEvent = {
    target: {
      value: ''
    }
  };

  export const REMOTE_SINGLE_SELECT = new Angular2Multiselects.SettingsBuilder()
    .singleSelection(true)
    .enableSearchFilter(true)
    .enableCheckAll(false)
    .remoteSearch(true)
    .build();

  export const LOCAL_SINGLE_SELECT = new Angular2Multiselects.SettingsBuilder()
    .singleSelection(true)
    .enableSearchFilter(true)
    .enableCheckAll(false)
    .remoteSearch(false)
    .build();

  export const LOCAL_SINGLE_SELECT_TRANSLATE = new Angular2Multiselects.SettingsBuilder()
    .singleSelection(true)
    .enableSearchFilter(true)
    .enableCheckAll(false)
    .remoteSearch(false)
    .translate(true)
    .build();

  export const LOCAL_MULTI_SELECT = new Angular2Multiselects.SettingsBuilder()
    .singleSelection(false)
    .enableSearchFilter(true)
    .enableCheckAll(true)
    .remoteSearch(false)
    .build();
  export const LOCAL_MULTI_SELECT_TRANSLATE = new Angular2Multiselects.SettingsBuilder()
    .singleSelection(false)
    .translate(true)
    .enableSearchFilter(true)
    .enableCheckAll(true)
    .remoteSearch(false)
    .build();

  export const REMOTE_MULTI_SELECT = new Angular2Multiselects.SettingsBuilder()
    .singleSelection(false)
    .enableSearchFilter(true)
    .enableCheckAll(false)
    .remoteSearch(true)
    .build();

}
