/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { Order, PagingRequest, QueryResult, ResourceQueryResult, Services } from '../../util/services';
import { Map, Set } from 'immutable';
import { Address, AddressResource } from '../../address';
import { CustomerRecordContactResource, CustomerRecordContactResourceService } from './customer-record-contact-resource.service';
/* eslint-enable */

@Injectable()
export class CustomerRecordContactService implements CustomerRecordContact.Service {

  constructor(private resourceService: CustomerRecordContactResourceService) {
  }

  getLocations(request: CustomerRecordContact.LocationQueryRequest):
    Observable<QueryResult<Address.ContactLocation>> {
    return Observable.create((observer: Observer<QueryResult<Address.ContactLocation>>) => {
      return this.resourceService.getLocations(this.toResourceQueryRequest(request)).subscribe(
        (result: ResourceQueryResult<AddressResource.ContactLocationResource>) => {
          observer.next({
            items: AddressResource.Mapper.toPublicContactLocationList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  private toResourceQueryRequest(request: CustomerRecordContact.LocationQueryRequest):
    CustomerRecordContactResource.LocationQueryRequest {
    return {
      q: request.q,
      id: Services.createIdParameter(request.id),
      external_id: request.externalId,
      name: request.name,
      customer_record_id: request.customerRecordId,
      contact_customer_record_id: request.contactCustomerRecordId,
      order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
      page_number: request.paging ? request.paging.pageNumber : undefined,
      number_of_items: request.paging ? request.paging.numberOfItems : undefined,
      no_progress_bar: request.noProgressBar
    };
  }

}

export namespace CustomerRecordContact {

  export interface Service {

    // <editor-fold desc="CRUD">

    getLocations(request: LocationQueryRequest): Observable<QueryResult<Address.ContactLocation>>;

    // </editor-fold>

  }

  export interface LocationQueryRequest {
    q?: string;
    id?: Set<number>;
    name?: string;
    externalId?: string;
    customerRecordId?: number;
    contactCustomerRecordId?: number;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
    noProgressBar?: boolean;
  }

  export enum OrderField {
    ID,
    NAME,
    CUSTOMER_RECORD_NAME,
    EXTERNAL_ID
  }

}

class Keys {

  private static readonly ID = 'id';
  private static readonly EXTERNAL_ID = 'external_id';
  private static readonly CUSTOMER_RECORD_NAME = 'customer_record_name';
  private static readonly NAME = 'name';


  private static readonly orderFieldKeyMap: Map<CustomerRecordContact.OrderField, string> = Map.of(
    CustomerRecordContact.OrderField.ID, Keys.ID,
    CustomerRecordContact.OrderField.NAME, Keys.NAME,
    CustomerRecordContact.OrderField.EXTERNAL_ID, Keys.EXTERNAL_ID,
    CustomerRecordContact.OrderField.CUSTOMER_RECORD_NAME, Keys.CUSTOMER_RECORD_NAME,
  );

  public static toOrderFieldKey(field: CustomerRecordContact.OrderField): string {
    return Keys.orderFieldKeyMap.get(field)!;
  }

}
