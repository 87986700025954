/* eslint-disable */
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Transition, UIRouter } from '@uirouter/angular';
import { InputMask } from '../../../../util/input-masks';
import { CustomerService } from '../../../../lib/customer/customer.service';
import { FormServiceCode } from '../../../../lib/form/form-service-factory';
import { SelectUtils, UiConstants } from '../../../../util/core-utils';
import { FormRights } from '../../../../util/form/form-utils';
import { Rights } from '../../../../app.rights';
import { NgForm, NgModel } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { BreadcrumbParent } from '../../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { FieldValidationError, LocalFieldValidationErrors, LocalFieldValidationErrorsFactory } from '../../../../lib/util/services';
import { TranslateService } from '@ngx-translate/core';
import {
  LegacyWorkflowTask,
  LegacyWorkflowTaskService
} from '../../../../lib/legacy-workflow/legacy-workflow-tasks/legacy-workflow-task.service';
import { StateName } from '../../../../app.state-names';
import { LegacyWorkflowTaskEditModel } from '../../../../lib/legacy-workflow/legacy-workflow-utils';
import { LoadingHandler } from '../../../../lib/loading-handler';
import { List } from 'immutable';
import { FileItem, FileUploader, ParsedResponseHeaders } from 'ng2-file-upload';
import { DownloadedFile } from '../../../../lib/util/downloaded-files';
import { UploadErrorLocalizer } from '../../../../util/upload-error-localizer';
import { Angular2Multiselects } from '../../../../util/multiselect';
import { saveAs } from 'file-saver';
import { LegacyProcessTask } from '../../../../lib/legacy-process/legacy-process-task.service';
import { FileUploaderUtil } from '../../../../util/file-uploader-util';
import { FileUploadComponent, UploadResponse } from '../../../../shared/file-upload/flat/file-upload.component';
import { ToasterService } from '../../../../fork/angular2-toaster/src/toaster.service';
import { StringKey } from '../../../../app.string-keys';

/* eslint-enable */

@Component({
  selector: 'app-legacy-workflow-task-edit',
  templateUrl: './legacy-workflow-task-edit.component.html',
  styleUrls: ['./legacy-workflow-task-edit.component.scss']
})
export class LegacyWorkflowTaskEditComponent implements OnInit, AfterViewInit {

  UiConstants = UiConstants;
  InputMask = InputMask;
  WorkflowTask = LegacyWorkflowTask;
  FormServiceCode = FormServiceCode;
  SelectUtils = SelectUtils;

  // TODO: add new rights and update
  formRights: FormRights = {
    formRead: Rights.CUSTOMER_FORM_READ,
    formGroupUpdate: Rights.CUSTOMER_FORM_GROUP_UPDATE,
    formFieldUpdate: Rights.CUSTOMER_FORM_FIELD_UPDATE,
    formGroupCreate: Rights.CUSTOMER_FORM_GROUP_CREATE,
    formFieldCreate: Rights.CUSTOMER_FORM_FIELD_CREATE,
    formGroupDisable: Rights.CUSTOMER_FORM_GROUP_DISABLE,
    formFieldDisable: Rights.CUSTOMER_FORM_FIELD_DISABLE,
    formGroupMove: Rights.CUSTOMER_FORM_GROUP_MOVE,
    formFieldMove: Rights.CUSTOMER_FORM_FIELD_MOVE,
  };

  @ViewChild('f', { static: true })
  fForm: NgForm;

  @ViewChild('name', { static: true })
  nameInput: NgModel;

  @ViewChild('external_id', { static: true })
  externalIdInput: NgModel;

  @ViewChild('deletePdfDialog', { static: true }) deletePdfDialog: ModalDirective;
  @ViewChild('uploadPdfDialog', { static: true }) uploadPdfDialog: ModalDirective;
  @ViewChild('editPdfDialog', { static: true }) editPdfDialog: ModalDirective;
  deletePdfDialogVisible: boolean = false;
  uploadPdfDialogVisible: boolean = false;
  editPdfDialogVisible: boolean = false;

  @ViewChild('fileUploadComponent', {static: false})
  fileUploadComponent: FileUploadComponent;
  uploadPath: string;
  uploadSuccessful?: boolean = false;

  model: LegacyWorkflowTaskEditModel = new LegacyWorkflowTaskEditModel();
  workflowId: number;
  workflowTaskId: number;
  pdfs: LegacyWorkflowTask.Pdf[] = [];
  currentPdfId: number;
  currentPdfName: string;
  dropdownSettings: Angular2Multiselects.Settings;
  taskStates: StateSelect[] = [];
  selectedStates: StateSelect[] = [];
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  private fieldErrors: FieldValidationError<LegacyWorkflowTask.ValidatedField> =
    FieldValidationError.empty<LegacyWorkflowTask.ValidatedField>();

  private validatedInputs: LocalFieldValidationErrors<NgModel> =
    LocalFieldValidationErrorsFactory.empty();

  constructor(private customerService: CustomerService,
              private uiRouter: UIRouter,
              private toasterService: ToasterService,
              private transition: Transition,
              private translateService: TranslateService,
              private fileUploaderUtil: FileUploaderUtil,
              private uploadErrorLocalizer: UploadErrorLocalizer,
              private workflowTaskService: LegacyWorkflowTaskService) {
    this.workflowId = this.transition.params().workflowId;
    this.workflowTaskId = this.transition.params().id;
    LegacyWorkflowTaskService.setWorkflowId(this.workflowId);
    this.uploadPath = '/legacy-workflows/' + this.workflowId + '/tasks/' + this.workflowTaskId + '/pdf-templates/ad-hoc/upload';
  }

  onUploadSuccess(response: UploadResponse) {
    this.uploadSuccessful = true;
    const responseObject = JSON.parse(response.response);
    this.currentPdfId = responseObject.template_id;
  }

  ngOnInit() {
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('WORKFLOW_LIST').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.LEGACY_WORKFLOW_LIST});
      }
    );
    this.translateService.get('WORKFLOW_EDIT').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.LEGACY_WORKFLOW_EDIT, uiParam: {id: this.workflowId}});
      }
    );
  }

  ngAfterViewInit(): void {
    this.loadProcessTaskStates();
    this.loadLocalFieldValidationErrors();
    this.loadModel();
    this.loadPdfs();
    this.initDropdown();
  }

  private loadProcessTaskStates() {
    this.taskStates = [];
    LegacyProcessTask.processTaskStates.forEach(
      (state) => {
        const item = {
          id: state.state,
          itemName: state.stringKey,
        };
        this.taskStates.push(item);
      });
    this.taskStates.forEach(
      (state: StateSelect) => {
        this.translateService.get(state.itemName).subscribe((stateName: string) => {
            state.itemName = stateName;
          }
        );
      });
  }

  initDropdown() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(false)
      .enableCheckAll(true)
      .build();
  }

  update() {
    if (this.hasLocalFieldError()) {
      this.showValidationErrorToast();
      return;
    }
    this.workflowTaskService.update({
      workflowTaskId: this.workflowTaskId,
      workflowId: this.workflowId,
      name: this.model.name,
      description: this.model.description,
      externalId: this.model.externalId,
      inProgressStateName: this.model.inProgressStateName,
      finishedStateName: this.model.finishedStateName,
      customerSignatureEnabled: this.model.customerSignature,
      userSignatureEnabled: this.model.userSignature,
      version: this.model.version,
    }).subscribe(
      (response: LegacyWorkflowTask.WorkflowTask) => {
        LoadingHandler.getInstance().refresh();
        this.uiRouter.stateService.go(StateName.LEGACY_WORKFLOW_EDIT, {id: this.workflowId});
      },
      (error: any) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withForm(this.fForm);
          this.showValidationErrorToast();
        }
      });
  }

  private showValidationErrorToast() {
    this.toasterService.pop({
      timeout: UiConstants.ToastTimeoutLong,
      type: UiConstants.toastTypeError,
      title: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_TITLE),
      body: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_MESSAGE)
    });
  }

  hasLocalFieldError(field?: NgModel): boolean {
    return this.validatedInputs.hasLocalError(field);
  }

  hasFieldError(field?: LegacyWorkflowTask.ValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: LegacyWorkflowTask.ValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: LegacyWorkflowTask.ValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  back() {
    window.history.back();
  }

  private loadLocalFieldValidationErrors() {
    const validatedInputs = List.of(this.externalIdInput, this.nameInput);
    this.validatedInputs = LocalFieldValidationErrorsFactory.ofFormFields(this.fForm, validatedInputs);
  }

  private loadModel() {
    this.workflowTaskService.get({
      workflowId: this.workflowId,
      workflowTaskId: this.workflowTaskId,
    }).subscribe(
      (workflowTask: LegacyWorkflowTask.WorkflowTask) => {
        this.breadcrumbSelf = workflowTask.name;
        this.model.name = workflowTask.name;
        this.model.description = workflowTask.description;
        this.model.externalId = workflowTask.externalId;
        this.model.inProgressStateName = workflowTask.inProgressStateName;
        this.model.finishedStateName = workflowTask.finishedStateName;
        this.model.customerSignature = workflowTask.customerSignatureEnabled;
        this.model.userSignature = workflowTask.userSignatureEnabled;
        this.model.version = workflowTask.version;
      });
  }

  private loadPdfs() {
    this.workflowTaskService.getPdfs({
      workflowId: this.workflowId,
      workflowTaskId: this.workflowTaskId
    }).subscribe((result: LegacyWorkflowTask.Pdf[]) => {
      this.pdfs = result;
    });
  }

  openDeletePdfDialog(id) {
    this.currentPdfId = id;
    this.deletePdfDialogVisible = true;
    this.deletePdfDialog.show();
  }

  closeDeletePdfDialog() {
    this.deletePdfDialogVisible = false;
    this.deletePdfDialog.hide();
  }

  openUploadPdfDialog() {
    this.currentPdfName = '';
    this.selectedStates = [];
    this.uploadSuccessful = false;
    this.fileUploadComponent.uploader.clearQueue();
    this.uploadPdfDialogVisible = true;
    this.uploadPdfDialog.show();
  }

  closeUploadPdfDialog(cancel) {
    this.uploadPdfDialogVisible = false;
    if (cancel === true && this.fileUploadComponent.uploader.queue.length > 0) {
      this.deletePdf();
      this.fileUploadComponent.uploader.clearQueue();
    }
    this.uploadPdfDialog.hide();
  }

  openEditPdfDialog(id, name, states) {
    this.currentPdfId = id;
    this.currentPdfName = name;
    this.arrayToSelectedStates(states);
    this.editPdfDialogVisible = true;
    this.editPdfDialog.show();
  }

  closeEditPdfDialog() {
    this.editPdfDialogVisible = false;
    this.editPdfDialog.hide();
  }

  uploadPdfFinish() {
    this.updatePdf();
    this.closeUploadPdfDialog(false);
  }

  private selectedStatesToArray(): string[] {
    const string_array: string[] = [];
    this.selectedStates.forEach((state: StateSelect) => {
      string_array.push(state.id);
    });
    return string_array;
  }

  private arrayToSelectedStates(input: string[]) {
    this.selectedStates = [];
    input.forEach((state) => {
      const item = this.taskStates.filter((s) => s.id === state);
      if (item && item.length === 1) {
        this.selectedStates.push(item[0]);
      }
    });
  }

  updatePdf() {
    this.workflowTaskService.updatePdf({
      workflowId: this.workflowId,
      workflowTaskId: this.workflowTaskId,
      pdfTemplateId: this.currentPdfId,
      name: this.currentPdfName,
      allowedTaskStatesForGeneration: this.selectedStatesToArray()
    }).subscribe(() => {
      },
      () => {
      },
      () => {
        this.closeEditPdfDialog();
        this.loadPdfs();
      }
    );
  }

  downloadPdf(id, name) {
    this.workflowTaskService.downloadPdf({
      pdfTemplateId: id,
      workflowId: this.workflowId,
      workflowTaskId: this.workflowTaskId
    }).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName(name + '.fodt'));
      }
    );
  }

  deletePdf() {
    this.workflowTaskService.deletePdf({
      workflowId: this.workflowId,
      workflowTaskId: this.workflowTaskId,
      pdfTemplateId: this.currentPdfId
    }).subscribe(() => {
      },
      () => {
      },
      () => {
        this.closeDeletePdfDialog();
        this.loadPdfs();
      }
    );
  }

  taskStateMapper(input: string[]): string {
    const out: string[] = [];
    input.forEach((state) => {
      const item = this.taskStates.filter((s) => s.id === state);
      if (item && item.length === 1) {
        out.push(item[0].itemName);
      }
    });
    return out.join(', ');
  }

}

export class StateSelect {
  id: string;
  itemName: string;
}
