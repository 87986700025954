/* eslint-disable */
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  CustomerRecord,
  CustomerRecordContactLocation,
  CustomerRecordService,
} from '../../../../lib/customer/customer-record.service';
import { OrderType, QueryResult } from '../../../../lib/util/services';
import { MultiselectOptionItem, QueryFieldModel } from '../../../../util/core-utils';
import { List, Set } from 'immutable';
import { Address } from '../../../../lib/address';
import { ConfigurationService } from '../../../../lib/core-ext/configuration.service';
import PostalAddressData = Address.PostalAddressData;
import { CustomerRecordMultiselectProvider } from '../../../../lib/customer/customer-record-multiselect-provider.service';
import { Arrays } from '../../../../lib/util/arrays';
/* eslint-enable */

@Component({
  selector: 'app-customer-record-contact-locations-list',
  templateUrl: './customer-record-contact-locations-list.component.html',
  styleUrls: ['./customer-record-contact-locations-list.component.scss']
})
export class CustomerRecordContactLocationsListComponent implements OnInit {
  CustomerRecordContactLocation = CustomerRecordContactLocation;
  pagingId = 'customerRecordContactLocationsList';

  @Input()
  customerId: number;

  @Input()
  customerRecordId: number;

  @Input()
  readonly: boolean = true;

  @Output()
  createContactLocation: EventEmitter<any> = new EventEmitter();

  @Output()
  importContactLocation: EventEmitter<any> = new EventEmitter();

  @Output()
  editContactLocation: EventEmitter<Address.ContactLocation> = new EventEmitter();

  @Output()
  detailContactLocation: EventEmitter<Address.ContactLocation> = new EventEmitter();

  queryModel: QueryFieldModel<CustomerRecordContactLocation.OrderField> =
    new QueryFieldModel(CustomerRecordContactLocation.OrderField.NAME, OrderType.ASC);

  locationList: Address.ContactLocation[] = [];
  contactPersonList: MultiselectOptionItem<number>[] = [];

  private postalAddressFormat: string;

  constructor(
    private customerRecordService: CustomerRecordService,
    private customerRecordMultiselectProvider: CustomerRecordMultiselectProvider,
    private configService: ConfigurationService,
  ) { }

  ngOnInit() {
    this.postalAddressFormat = this.configService.getPostalAddressFormat();
  }

  initComponent() {
    this.loadList();
  }
  loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();
    this.customerRecordService.queryContactLocations({
      customerId: this.customerId,
      customerRecordId: this.customerRecordId,
      orders: Set.of(order),
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined
    }).subscribe((result: QueryResult<Address.ContactLocation>) => {
      this.locationList = result.items.toArray();
      this.loadContactPersons();
      this.queryModel.currentPage = requestedPage;
      this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
    });
  }

  private loadContactPersons() {
    this.customerRecordMultiselectProvider
      .getByIds(Arrays.flatten(this.locationList.map(l => l.contactPersons.toArray())))
      .subscribe(result => {
        this.contactPersonList = result;
      });
  }

  getContactPersons(ids: List<number>) {
    return this.contactPersonList.filter(p => ids.includes(p.id)).map(p => p.itemName).join(', ');
  }

  orderBy(field: CustomerRecordContactLocation.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  removeContactLocation(id: number) {
    this.customerRecordService.deleteContactLocation({
      customerId: this.customerId,
      customerRecordId: this.customerRecordId,
      id: id
    }).subscribe(() => {
      this.loadList(1);
    });
  }

  formatPostalAddress(postalAddress?: PostalAddressData): string {
    return postalAddress ? Address.PostalAddressMapper.toString(postalAddress, this.postalAddressFormat) : '';
  }

  getOpeningTimeLines(value: string): string[] {
    return value.replace('</div><div>', '\n')
                .replace('<div>', '\n')
                .replace(/<[^>]*>/g, '')
                .split('\n')
                .filter((l, i) => i < 2);
  }

}
