<div class="form-group" [formGroup]="formGroup" [ngClass]="{ 'has-danger': hasLocalFieldError('value')}">
  <label class="form-control-label text-break" [ngClass]="{ 'required-field-label': required }">{{ model.title }}
    <i *ngIf="model.hint !== ''" class="icon icon-info popover-info-icon" title="{{ model.hint }}"></i>
  </label>
  <select *ngIf="!nonEditable"
          class="form-control"
          formControlName="value"
          (change)="onManualChange()"
          (ngModelChange)="onModelChange()"
          [(ngModel)]="model.value"
          [ngClass]="{
            'form-control-danger': hasLocalFieldError('value'),
            'cursor-not-allowed': nonEditable,
            'item-selected': model.value && model.value.id !== null,
            'item-unselected': model.value && model.value.id === null
          }">
    <option *ngFor="let type of listItems"
            [ngClass]="{
                'item-selected': type.id !== null,
                'item-unselected': type.id === null
            }"
            [ngValue]="type">
      {{type.text}}
    </option>
  </select>
  <div class="form-control-feedback" *ngIf="hasLocalFieldError('value')">
    <span *ngIf="hasLocalFieldError('value', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
  </div>
  <div *ngIf="nonEditable">
    <label class="form-control edit-form-input edit-form-input-inactive" readonly>{{model.value && model.value.id ? model.value.text : ''}}</label>
  </div>
</div>
