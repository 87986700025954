/* eslint-disable */
import * as Moment from 'moment-timezone';
import * as moment from 'moment';
import { Duration } from 'moment';
import { Numbers } from './numbers';

/* eslint-enable */

export class Dates {

  public static guessLocalTimeZoneName(): string {
    return Moment.tz.guess();
  }

  public static emptyLocalDate(): LocalDate {
    return new InvalidLocalDate();
  }

  public static emptyOffsetDateTime(): OffsetDateTime {
    return new InvalidOffsetDateTime();
  }

  public static now(): OffsetDateTime {
    const moment = Moment();
    return new MomentOffsetDateTime(moment);
  }

  public static today(): LocalDate {
    const moment = Moment();
    return new MomentLocalDate(moment);
  }

  public static isAlmostChristmas(): boolean {
    const now = Dates.today();
    const almostChristmas = Dates.createLocalDate({year: now.getYear(), month: 12, day: 17});
    return now.isAfter(almostChristmas);
  }

  public static createOffsetDateTime(
    args: DateTimeArgs): OffsetDateTime {
    const yearText = '' + args.year;
    const monthText = Numbers.padNumber(args.month, 2);
    const dayText = Numbers.padNumber(args.day, 2);
    const hourText = Numbers.padNumber(args.hour, 2);
    const minuteText = Numbers.padNumber(args.minute, 2);
    const secondText = Numbers.padNumber(args.second, 2);
    const milliText = Numbers.padNumber(args.milliseconds, 3);
    const timeZoneName = args.timeZoneName ? args.timeZoneName : Dates.guessLocalTimeZoneName();
    try {
      const moment = Moment.tz(
        yearText + monthText + dayText +
        hourText + minuteText + secondText + milliText, 'YYYYMMDDhhmmssSSS',
        timeZoneName);
      if (moment.isValid()) {
        return new MomentOffsetDateTime(moment);
      }
      return new InvalidOffsetDateTime();
    }
    catch (error) {
      return new InvalidOffsetDateTime();
    }
  }

  public static parseOffsetDateTimeIsoString(isoText: string | null | undefined): OffsetDateTime {
    if (!isoText) {
      return new InvalidOffsetDateTime();
    }
    try {
      const moment = Moment(isoText);
      if (moment.isValid()) {
        return new MomentOffsetDateTime(moment);
      }
      return new InvalidOffsetDateTime();
    }
    catch (error) {
      return new InvalidOffsetDateTime();
    }
  }

  public static parseOffsetDateTimeInput(input: OffsetDateTimeInput): OffsetDateTime {
    return Dates.parseOffsetDateTimeTemplate(input, input.getText());
  }

  public static parseOffsetDateTimeTemplate(template: OffsetDateTimeTemplate, text: string | null | undefined): OffsetDateTime {
    if (!text) {
      return new InvalidOffsetDateTime();
    }
    try {
      const moment = Moment.tz(text, template.getPattern(), template.getTimeZoneName());
      if (moment.isValid()) {
        return new MomentOffsetDateTime(moment);
      }
      return new InvalidOffsetDateTime();
    }
    catch (error) {
      return new InvalidOffsetDateTime();
    }
  }

  public static createLocalDate(args: LocalDateArgs): LocalDate {
    const yearText = '' + args.year;
    const monthText = (args.month >= 10 ? '' : '0') + args.month;
    const dayText = (args.day >= 10 ? '' : '0') + args.day;
    try {
      const moment = Moment(yearText + monthText + dayText, 'YYYYMMDD');
      if (moment.isValid()) {
        return new MomentLocalDate(moment);
      }
      return new InvalidLocalDate();
    }
    catch (error) {
      return new InvalidLocalDate();
    }
  }

  public static parseLocalDateIsoString(isoText: string | null | undefined): LocalDate {
    if (!isoText) {
      return new InvalidLocalDate();
    }
    try {
      const moment = Moment(isoText);
      if (moment.isValid()) {
        return new MomentLocalDate(moment);
      }
      return new InvalidLocalDate();
    }
    catch (error) {
      return new InvalidLocalDate();
    }
  }

  public static parseLocalDateTemplate(template: LocalDateTemplate, text: string | null | undefined): LocalDate {
    if (!text) {
      return new InvalidLocalDate();
    }
    try {
      const moment = Moment(text, template.getPattern());
      if (moment.isValid()) {
        return new MomentLocalDate(moment);
      }
      return new InvalidLocalDate();
    }
    catch (error) {
      return new InvalidLocalDate();
    }
  }

  public static parseLocalDateInput(input: LocalDateInput): LocalDate {
    return Dates.parseLocalDateTemplate(input, input.getText());
  }

  private constructor() {
    // Only static methods
  }
}

export interface LocalDateArgs {
  year: number;
  month: number;
  day: number;
}

export interface DateTimeArgs extends LocalDateArgs {
  hour: number;
  minute: number;
  second: number;
  milliseconds: number;
  timeZoneName?: string;
}

export interface LocalDateTemplate {
  getPattern(): string;
}

export interface OffsetDateTimeTemplate {
  getPattern(): string;

  getTimeZoneName(): string;
}

export interface OffsetDateTimeInput extends OffsetDateTimeTemplate {
  getText(): string | null | undefined;
}

export interface LocalDateInput extends LocalDateTemplate {
  getText(): string | null | undefined;
}

export interface LocalDate {
  isValid(): boolean;

  format(template: LocalDateTemplate): string;

  toIsoString(): string;

  startOfDay(): OffsetDateTime;

  plusDays(days: number): LocalDate;

  minusDays(days: number): LocalDate;

  getYear(): number;

  getMonth(): number;

  getDay(): number;

  isBefore(ld: LocalDate): boolean;

  isAfter(ld: LocalDate): boolean;

  equals(ld: LocalDate): boolean;

  fromNow(): string;

  diffDays(b: LocalDate): number | undefined;

}

export interface OffsetDateTime {
  isValid(): boolean;

  format(template: OffsetDateTimeTemplate): string;

  toUtcIsoString(): string;

  startOfDay(): OffsetDateTime;

  endOfDay(): OffsetDateTime;

  minusDays(days: number): OffsetDateTime;

  plusDays(days: number): OffsetDateTime;

  plusSeconds(seconds: number): OffsetDateTime;

  getYear(): number;

  getMonth(): number;

  getDay(): number;

  getHour(): number;

  getMinute(): number;

  getSecond(): number;

  getMillisecond(): number;

  isAfter(time: OffsetDateTime): boolean;

  isBefore(time: OffsetDateTime): boolean;

  equals(time: OffsetDateTime): boolean;

  fromNow(): string;

  diffMinutes(b: OffsetDateTime): number | undefined;

  diffDuration(b: OffsetDateTime): Duration | undefined;

  isToday(): boolean;
}

export class InvalidLocalDate implements LocalDate {

  isValid(): boolean {
    return false;
  }

  format(template: LocalDateTemplate): string {
    return '';
  }

  toIsoString(): string {
    return '';
  }

  startOfDay(): OffsetDateTime {
    return new InvalidOffsetDateTime();
  }

  minusDays(days: number): LocalDate {
    return this;
  }

  plusDays(days: number): LocalDate {
    return this;
  }

  getYear(): number {
    return 0;
  }

  getMonth(): number {
    return 0;
  }

  getDay(): number {
    return 0;
  }

  isBefore(ld: LocalDate): boolean {
    return false;
  }

  isAfter(ld: LocalDate): boolean {
    return false;
  }

  equals(ld: LocalDate): boolean {
    return false;
  }

  fromNow(): string {
    return '';
  }

  diffDays(b: LocalDate): number | undefined {
    return undefined;
  }

}

export class InvalidOffsetDateTime implements OffsetDateTime {

  isValid(): boolean {
    return false;
  }

  format(template: OffsetDateTimeTemplate): string {
    return '';
  }

  toUtcIsoString(): string {
    return '';
  }

  startOfDay(): OffsetDateTime {
    return this;
  }

  endOfDay(): OffsetDateTime {
    return this;
  }

  minusDays(days: number): OffsetDateTime {
    return this;
  }

  plusDays(days: number): OffsetDateTime {
    return this;
  }

  plusSeconds(days: number): OffsetDateTime {
    return this;
  }

  getYear(): number {
    return 0;
  }

  getMonth(): number {
    return 0;
  }

  getDay(): number {
    return 0;
  }

  getHour(): number {
    return 0;
  }

  getMinute(): number {
    return 0;
  }

  getSecond(): number {
    return 0;
  }

  getMillisecond(): number {
    return 0;
  }

  isAfter(time: OffsetDateTime): boolean {
    return false;
  }

  isBefore(time: OffsetDateTime): boolean {
    return false;
  }

  equals(time: OffsetDateTime): boolean {
    return false;
  }

  fromNow(): string {
    return '';
  }

  diffMinutes(b: OffsetDateTime): number | undefined {
    return undefined;
  }

  diffDuration(b: OffsetDateTime): moment.Duration | undefined {
    return undefined;
  }

  isToday(): boolean {
    return false;
  }

}

class MomentLocalDate implements LocalDate {

  constructor(private moment: Moment.Moment) {
    moment.startOf('day');
  }

  isValid(): boolean {
    return this.moment.isValid();
  }

  format(template: LocalDateTemplate): string {
    return this.moment.format(template.getPattern());
  }

  toIsoString(): string {
    return this.moment.format('YYYY-MM-DD');
  }

  startOfDay(): OffsetDateTime {
    return new MomentOffsetDateTime(this.moment.clone());
  }

  plusDays(days: number): LocalDate {
    const m = this.moment.clone().add(days, 'day');
    return new MomentLocalDate(m);
  }

  minusDays(days: number): LocalDate {
    const m = this.moment.clone().subtract(days, 'day');
    return new MomentLocalDate(m);
  }

  getYear(): number {
    return this.moment.year();
  }

  getMonth(): number {
    return this.moment.month() + 1;
  }

  getDay(): number {
    return this.moment.date();
  }

  isBefore(time: LocalDate): boolean {
    if (this.isValid() && time.isValid() && time instanceof MomentLocalDate) {
      return this.moment.isBefore(time.moment);
    }
    return false;
  }

  isAfter(time: LocalDate): boolean {
    if (this.isValid() && time.isValid() && time instanceof MomentLocalDate) {
      return this.moment.isAfter(time.moment);
    }
    return false;
  }

  equals(time: LocalDate): boolean {
    if (this.isValid() && time.isValid() && time instanceof MomentLocalDate) {
      return this.moment.unix() === time.moment.unix();
    }
    return false;
  }

  fromNow(): string {
    return this.moment.fromNow();
  }

  diffDays(b: LocalDate): number | undefined {
    if (this.isValid() && b.isValid() && b instanceof MomentLocalDate) {
      return this.moment.diff(b.moment, 'day');
    }
    return undefined;
  }

}

class MomentOffsetDateTime implements OffsetDateTime {

  constructor(private moment: Moment.Moment) {
  }

  isValid(): boolean {
    return this.moment.isValid();
  }

  format(template: OffsetDateTimeTemplate): string {
    const m = this.moment.clone().tz(template.getTimeZoneName());
    return m.format(template.getPattern());
  }

  toUtcIsoString(): string {
    const m = this.moment.clone().tz('utc');
    return m.toISOString();
  }

  startOfDay(): OffsetDateTime {
    const m = this.moment.clone().startOf('day');
    return new MomentOffsetDateTime(m);
  }

  endOfDay(): OffsetDateTime {
    const m = this.moment.clone().endOf('day');
    return new MomentOffsetDateTime(m);
  }

  minusDays(days: number): OffsetDateTime {
    const m = this.moment.clone().subtract(days, 'day');
    return new MomentOffsetDateTime(m);
  }

  plusDays(days: number): OffsetDateTime {
    const m = this.moment.clone().add(days, 'day');
    return new MomentOffsetDateTime(m);
  }

  plusSeconds(seconds: number): OffsetDateTime {
    const m = this.moment.clone().add(seconds, 'second');
    return new MomentOffsetDateTime(m);
  }

  getYear(): number {
    return this.moment.year();
  }

  getMonth(): number {
    return this.moment.month() + 1;
  }

  getDay(): number {
    return this.moment.date();
  }

  getHour(): number {
    return this.moment.hour();
  }

  getMinute(): number {
    return this.moment.minute();
  }

  getSecond(): number {
    return this.moment.second();
  }

  getMillisecond(): number {
    return this.moment.millisecond();
  }

  isAfter(time: OffsetDateTime): boolean {
    if (this.isValid() && time.isValid() && time instanceof MomentOffsetDateTime) {
      return this.moment.isAfter(time.moment);
    }
    return false;
  }

  isBefore(time: OffsetDateTime): boolean {
    if (this.isValid() && time.isValid() && time instanceof MomentOffsetDateTime) {
      return this.moment.isBefore(time.moment);
    }
    return false;
  }

  equals(time: OffsetDateTime): boolean {
    if (this.isValid() && time.isValid() && time instanceof MomentOffsetDateTime) {
      return this.moment.unix() === time.moment.unix();
    }
    return false;
  }

  fromNow(): string {
    return this.moment.fromNow();
  }

  diffMinutes(b: OffsetDateTime): number | undefined {
    if (this.isValid() && b.isValid() && b instanceof MomentOffsetDateTime) {
      return this.moment.diff(b.moment, 'minute');
    }
    return undefined;
  }

  diffDuration(b: OffsetDateTime): Duration | undefined {
    if (this.isValid() && b.isValid() && b instanceof MomentOffsetDateTime) {
      return moment.duration(this.moment.diff(b.moment));
    }
    return undefined;
  }

  isToday(): boolean {
    return Moment().isSame(this.moment, 'day');
  }
}

export class DefaultLocalDateTemplate implements LocalDateTemplate {

  constructor(private pattern: string) {
  }

  getPattern(): string {
    return this.pattern;
  }

}

export class DefaultOffsetDateTimeTemplate implements OffsetDateTimeTemplate {

  private timeZoneName: string;

  constructor(private pattern: string) {
    this.timeZoneName = Dates.guessLocalTimeZoneName();
  }

  getPattern(): string {
    return this.pattern;
  }

  getTimeZoneName(): string {
    return this.timeZoneName;
  }

}
