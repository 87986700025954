/* eslint-disable */
import { Component, Input, OnInit, ViewChild, } from '@angular/core';
import { SurveyRecord, SurveyRecordService } from '../../../lib/survey/survey-record.service';
import { FormBuilder, FormGroup, NgForm, NgModel, Validators, } from '@angular/forms';
import {
  FieldValidationError,
  FormRef,
  FormStrategy,
  LocalFormGroupValidationErrors,
  OrderType,
  QueryResult,
} from '../../../lib/util/services';
import { SurveyRecordBase, } from './survey-record-base-view';
import { UserData, UserDataLoader, UserDataLoaderPermissionDeniedStrategy } from '../../../lib/user-data-loader';
import {
  MultiselectOptionItem,
  OptionItem,
  OwnerUserItem,
  OwnerUserItemFactory,
  UiConstants,
} from '../../../util/core-utils';
import { StringKey } from '../../../app.string-keys';
import { saveAs } from 'file-saver';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '../../../fork/angular2-toaster/angular2-toaster';
import { UIRouter } from '@uirouter/angular';
import { CustomerRecord, CustomerRecordService } from '../../../lib/customer/customer-record.service';
import { Address } from '../../../lib/address';
import { Observable, Subject } from 'rxjs';
import { Set } from 'immutable';
import { Strings } from '../../../lib/util/strings';
import { Angular2Multiselects } from '../../../util/multiselect';
import { TranslateUtils } from '../../../util/translate';
import { ConfigurationService } from '../../../lib/core-ext/configuration.service';
import { Customer, CustomerService } from '../../../lib/customer/customer.service';
import { CustomerRecordFieldType } from '../../../util/customer-record-utils';

/* eslint-enable */

@Component({
  selector: 'app-survey-record-base',
  templateUrl: 'survey-record-base.component.html',
  styleUrls: ['survey-record-base.component.scss'],
})
export class SurveyRecordBaseComponent implements FormRef, OnInit, SurveyRecordBase.View {

  SurveyRecord = SurveyRecord;
  UiConstants = UiConstants;

  @ViewChild('name')
  nameInput: NgModel;

  @Input()
  form?: NgForm;

  @Input()
  headingDictionaryKey: string;

  @Input()
  readonly: boolean;

  @Input()
  allowClone: boolean;

  @Input()
  allowExport: boolean;

  fieldStyle = {
    'padding': UiConstants.formRecordFieldPadding
  };

  formGroup: FormGroup;

  model: SurveyRecordBase.Model = new SurveyRecordBase.Model();
  surveyRecord?: SurveyRecord.SurveyRecord;
  ownerUser?: OwnerUserItem;

  customers: MultiselectOptionItem<number>[] = [];
  customerDropdownSettings: Angular2Multiselects.Settings;

  postalAddressFormat: string = '';

  private fieldErrors: FieldValidationError<SurveyRecord.ValidatedField> =
    FieldValidationError.empty<SurveyRecord.ValidatedField>();

  private formGroupValidationErrors: LocalFormGroupValidationErrors;

  constructor(fb: FormBuilder,
              private userDataLoader: UserDataLoader,
              private ownerUserItemFactory: OwnerUserItemFactory,
              private surveyRecordService: SurveyRecordService,
              private toasterService: ToasterService,
              private uiRouter: UIRouter,
              private translateService: TranslateService,
              private configService: ConfigurationService,
              private customerRecordService: CustomerRecordService,
              private customerService: CustomerService) {
    this.formGroup = this.createFormGroup(fb);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(this, this.formGroup);
    this.loadConfig();
    this.initCustomerDropDown();
  }

  private loadConfig() {
    this.postalAddressFormat = this.configService.getPostalAddressFormat();
  }

  private initCustomerDropDown() {
    this.customerDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
  }

  getForm(): FormStrategy {
    const form = this.form;
    return {
      submitted: form ? form.submitted : false
    };
  }

  getModel(): SurveyRecordBase.Model {
    return this.model;
  }

  loadSurveyRecord(args: SurveyRecordBase.Args) {
    if (args.surveyRecord) {
      this.model.load(args.surveyRecord);
      this.surveyRecord = args.surveyRecord;
      this.loadOwnerUser(this.surveyRecord);
    }
    this.loadCustomers();

  }

  onFieldValidationError(fieldErrors: FieldValidationError<SurveyRecord.ValidatedField>) {
    this.fieldErrors = fieldErrors;
  }

  ngOnInit(): void {
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  hasLocalFormError(errorCode: string): boolean {
    return this.formGroupValidationErrors.hasFormError(errorCode);
  }

  hasFieldError(field: SurveyRecord.ValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: SurveyRecord.ValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: SurveyRecord.ValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  private createFormGroup(fb: FormBuilder): FormGroup {
    return fb.group(
      {
        name: fb.control(
          {value: this.model.name},
          [
            Validators.nullValidator
          ]
        )
      }
    );
  }

  private loadOwnerUser(record: SurveyRecord.SurveyRecord) {

    this.userDataLoader.load(record.ownerUserId, UserDataLoaderPermissionDeniedStrategy.MISS_ALL).subscribe(
      (user: UserData) => {
        this.ownerUser = this.ownerUserItemFactory.create(user);
      }
    );
  }

  getOwnerUserName(): string {
    return this.ownerUser ? this.ownerUser.text : '';
  }

  private loadCustomers() {
    this.initCustomerDropDown();
    if ((this.readonly && this.surveyRecord && this.surveyRecord.customerRecordId)) {
      this.getCustomers([this.surveyRecord.customerRecordId]);
    }
    else if (!this.readonly) {
      this.getCustomers([]);
    }
  }

  private getCustomers(custIds: number[], q?: any) {
    this.queryCustomers(custIds, q).subscribe((result: CombinedResult) => {
      this.customers = [];
      result.customerRecords.forEach((customer) => {
        if (customer) {
          const c = result.customers.find(c => c.customerId === customer.customerId)!;
          const addr = !customer.postalAddress
          || !c.managedFields.contains(CustomerRecordFieldType.POSTAL_ADDRESS_AND_INVOICE_ADDRESS_AND_NOTIFICATION_ADDRESS)
            ? ''
            : Address.PostalAddressMapper.toString(customer.postalAddress, this.postalAddressFormat);
          const item = {
            id: customer.customerRecordId,
            itemName: customer.name + ' (' + addr + ')'
          };
          this.customers.push(item);
          if (this.surveyRecord && this.surveyRecord.customerRecordId === item.id) {
            this.model.customerRecord = item;
          }
        }
      });
      // load the already selected customer, if it is out of the selectable list's range
      if (this.surveyRecord && this.surveyRecord.customerRecordId && (!this.model.customerRecord || !this.model.customerRecord.id)) {
        this.queryCustomers([this.surveyRecord.customerRecordId]).subscribe((result: CombinedResult) => {
          if (result.customerRecords.length > 0 && result.customerRecords[0]) {
            const customer: CustomerRecord.CustomerRecord = result.customerRecords[0];
            const c = result.customers.find(c => c.customerId === customer.customerId)!;
            const addr = !customer.postalAddress
            || !c.managedFields.contains(CustomerRecordFieldType.POSTAL_ADDRESS_AND_INVOICE_ADDRESS_AND_NOTIFICATION_ADDRESS)
              ? ''
              : Address.PostalAddressMapper.toString(customer.postalAddress, this.postalAddressFormat);
            const item = {
              id: customer.customerRecordId,
              text: customer.name,
              itemName: customer.name + ' (' + addr + ')',
              customerRecord: customer,
              address: addr
            };
            if (this.surveyRecord && this.surveyRecord.customerRecordId === item.id) {
              this.model.customerRecord = item;
            }
          }
        });
      }
    });
  }

  private queryCustomers(custIds: number[], q?: any): Observable<CombinedResult> {
    const subject = new Subject<CombinedResult>();
    this.customerRecordService.globalQuery({
      withFormRecord: false,
      customerRecordIdSet: Set.of(...custIds),
      fields: Set.of('id', 'name', 'postal_address'),
      orders: Set.of({type: OrderType.ASC, field: CustomerRecord.OrderField.NAME}),
      paging: {
        numberOfItems: 100,
        pageNumber: 1
      },
      queryText: q ? Strings.undefinedOrNonEmpty(q.target.value) : undefined,
      noProgressBar: true
    }).subscribe(crs => {
      this.customerService.query({
        customerIdSet: Set.of(...crs.items.toArray().map(cr => cr.customerId))
      }).subscribe(cs => {
        subject.next({
          customerRecords: crs.items.toArray(),
          customers: cs.items.toArray()
        });
      });
    });
    return subject;
  }
}

interface CombinedResult {
  customerRecords: CustomerRecord.CustomerRecord[];
  customers: Customer.Customer[];
}
