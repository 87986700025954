/* eslint-disable */
import { FormRecordFieldView, FormRecordFieldViewFactory, } from './form-utils';
import { ComponentFactoryResolver, ComponentRef, Injector, } from '@angular/core';
import { FormRecordStockMoveFieldComponent } from '../../admin/form/form-record/fields/stock-move/form-record-stock-move-field.component';

/* eslint-enable */

export class FormRecordStockMoveFieldViewFactory implements FormRecordFieldViewFactory {

  constructor(
    private injector: Injector,
    private cfr: ComponentFactoryResolver) {
  }

  createView(): ComponentRef<FormRecordFieldView> {
    const cmp = this.cfr.resolveComponentFactory(FormRecordStockMoveFieldComponent)
      .create(this.injector);
    return cmp;
  }

}
