<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]=breadcrumbParents
      [self]="breadcrumbSelf">
    </app-breadcrumb>
    <app-breadcrumb-menu>
      <app-breadcrumb-button
        *ngIf="rightModel.emailAddressTypeUpdate.hasRight() && componentState.isDetailView()"
        [iconClass]="'icomoon-modify'"
        [titleStringKey]="'COMMON_BUTTON_EDIT'"
        uiSref="Admin.EmailAddressTypeEdit"
        [uiParams]="{ id: componentState.id }">
      </app-breadcrumb-button>
    </app-breadcrumb-menu>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>

<div class="container-horizontal-padding animated fadeIn">

      <div class="container-custom-padding">

        <div class="form-group">

          <form class="form-horizontal bordered-row row" [formGroup]="formGroup" (ngSubmit)="submit()" #f=ngForm novalidate>

            <!--region Basic Details-->
            <div class="col-xs-12 col-sm-12 col-md-6 card-custom-padding">
              <div class="card">
                <div class="card-header">
                  {{ getHeadingDictionaryKey() | translate}}
                </div>
                <div class="card-body">

                  <!--region ExternalId-->
                  <div class="form-group row"
                       [ngClass]="{ 'has-danger': (hasLocalFieldError('externalId') || hasFieldError(EmailAddressType.ValidatedField.EXTERNAL_ID)) }">
                    <label class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_EXTERNAL_ID' |
                      translate}}</label>
                    <div class="col-md-8">
                      <input *ngIf="componentState.isEditable()"
                             type="text" class="form-control"
                             maxlength="{{UiConstants.maximumVarcharLength}}"
                             placeholder="{{'COMMON_EXTERNAL_ID' | translate}}"
                             [(ngModel)]="model.externalId"
                             (ngModelChange)="removeFieldError(EmailAddressType.ValidatedField.EXTERNAL_ID)"
                             [ngClass]="{ 'form-control-danger': hasLocalFieldError('externalId') || hasFieldError(EmailAddressType.ValidatedField.EXTERNAL_ID) }"
                             formControlName="externalId"
                             [required]="componentState.isEditable()">
                      <div class="form-control-feedback"
                           *ngIf="hasLocalFieldError('externalId')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                      <div class="form-control-feedback"
                           *ngIf="hasFieldError(EmailAddressType.ValidatedField.EXTERNAL_ID)">{{getFieldErrorText(EmailAddressType.ValidatedField.EXTERNAL_ID)}}</div>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{model.externalId}}
                      </label>
                    </div>
                  </div>
                  <!--endregion ExternalId-->

                  <!--region NameHu-->
                  <div class="form-group row"
                       [ngClass]="{ 'has-danger': (hasLocalFieldError('nameHu')) }">
                    <label class="col-form-label form-control-label col-md-4 detail-title">{{'EMAIL_ADDRESS_TYPE_NAME_HU' |
                      translate}}</label>
                    <div class="col-md-8">
                      <input *ngIf="componentState.isEditable()"
                             type="text" class="form-control"
                             maxlength="{{UiConstants.maximumVarcharLength}}"
                             placeholder="{{'EMAIL_ADDRESS_TYPE_NAME_HU' | translate}}"
                             [(ngModel)]="model.nameHu"
                             [ngClass]="{ 'form-control-danger': hasLocalFieldError('nameHu') }"
                             formControlName="nameHu"
                             [required]="componentState.isEditable()">
                      <div class="form-control-feedback"
                           *ngIf="hasLocalFieldError('nameHu')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{model.nameHu}}
                      </label>
                    </div>
                  </div>
                  <!--endregion NameHu-->

                  <!--region NameEn-->
                  <div class="form-group row"
                       [ngClass]="{ 'has-danger': (hasLocalFieldError('nameEn')) }">
                    <label class="col-form-label form-control-label col-md-4 detail-title">{{'EMAIL_ADDRESS_TYPE_NAME_EN' |
                      translate}}</label>
                    <div class="col-md-8">
                      <input *ngIf="componentState.isEditable()"
                             type="text" class="form-control"
                             maxlength="{{UiConstants.maximumVarcharLength}}"
                             placeholder="{{'EMAIL_ADDRESS_TYPE_NAME_EN' | translate}}"
                             [(ngModel)]="model.nameEn"
                             [ngClass]="{ 'form-control-danger': hasLocalFieldError('nameEn') }"
                             formControlName="nameEn"
                             [required]="componentState.isEditable()">
                      <div class="form-control-feedback"
                           *ngIf="hasLocalFieldError('nameEn')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{model.nameEn}}
                      </label>
                    </div>
                  </div>
                  <!--endregion NameHu-->

                  <!--Only visible on create/edit views-->
                  <div class="col-md-12 d-flex justify-content-end p-0" *ngIf="componentState.isEditable()">
                    <div>
                      <input type="button" class="btn btn-secondary" (click)="back()"
                             value="{{'COMMON_BUTTON_CANCEL'|translate}}"/>
                      <input type="submit" class="btn btn-primary ml-1" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- endregion Basic Details-->
          </form>
        </div>
        <!-- /.form-group row -->
      </div>
</div>


