/* eslint-disable */
import { map } from 'rxjs/operators';
import { DownloadedFile } from '../util/downloaded-files';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlBuilder } from '../../util/url-builder';
import { ResourceQueryResult } from '../util/services';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { HttpClient } from '@angular/common/http';
import { EmptyMessage } from '../util/messages';
/* eslint-enable */

@Injectable()
export class StockTakingSheetResourceService extends BaseHttpService {

  query(request: StockTakingSheetResource.QueryRequest): Observable<ResourceQueryResult<StockTakingSheetResource.StockTakingSheet>> {
    return this.http.get<ResourceQueryResult<StockTakingSheetResource.StockTakingSheet>>(this.url, this.parseParams(request));
  }

  get(request: StockTakingSheetResource.GetRequest): Observable<StockTakingSheetResource.StockTakingSheet> {
    return this.http.get<StockTakingSheetResource.StockTakingSheet>(this.url + `${request.sheet_id}`);
  }

  create(request: StockTakingSheetResource.CreateRequest): Observable<StockTakingSheetResource.CreateResponse> {
    return this.http.post<StockTakingSheetResource.CreateResponse>(this.url, request);
  }

  update(request: StockTakingSheetResource.UpdateRequest): Observable<EmptyMessage> {
    return this.http.put(this.url + `${request.sheet_id}`, request);
  }

  updateStock(request: StockTakingSheetResource.GetRequest): Observable<EmptyMessage> {
    return this.http.post(this.url + `${request.sheet_id}/update-stock`, request);
  }

  downloadPdf(request: StockTakingSheetResource.StockTakingSheetPdfDownloadRequest): Observable<DownloadedFile> {
    const url = UrlBuilder.create(
      'stock-taking-sheets/:sheetId/pdf')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .namedNumber('sheetId', request.sheetId)
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob'
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  exportXls(request: StockTakingSheetResource.GetRequest): Observable<DownloadedFile> {
    const url = UrlBuilder.create(
      'stock-taking-sheets/:sheetId/export-xls')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .namedNumber('sheetId', request.sheet_id)
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob'
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  constructor(private http: HttpClient, private resourceHelper: ResourceHelper) {
    super(resourceHelper, '/stock-taking-sheets/');
  }

}

export namespace StockTakingSheetResource {

  export interface StockTakingSheet {
    sheet_id: number;
    stock_taking_id: number;
    stock_id: number;
    trx_id: string;
    creation_time: string;
    update_time: string;
    assignee_user_id: number;
    state: string;
    note?: string;
    items?: StockTakingSheetRecord[];
  }

  export interface StockTakingSheetRecord {
    item_id: number;
    stock_item_id: number;
    stock_item_external_id: string;
    stock_item_product_code: string;
    stock_item_name: string;
    stock_item_unit?: string;
    stock_item_serial_code?: string;
    amount_found: string;
    stock_amount: string;
  }

  export interface QueryRequest {
    with_items?: boolean;
    sheet_ids?: string;
    assignee_user_id?: number;
    trx_id?: string;
    stock_taking_id?: number;
    stock_id?: number;
    state?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
  }

  export interface GetRequest {
    sheet_id: number;
  }

  export interface CreateRequest {
    stock_taking_id: number;
    stock_id: number;
    assignee_user_id: number;
  }

  export interface CreateResponse {
    sheet_id: number;
  }

  export interface UpdateRequest {
    sheet_id: number;
    assignee_user_id: number;
  }

  export interface StockTakingSheetPdfDownloadRequest {
    sheetId: number;
  }

}
