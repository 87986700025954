<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]=breadcrumbParents
      [self]="breadcrumbSelf">
    </app-breadcrumb>
    <app-breadcrumb-menu>
      <app-breadcrumb-button
        *ngIf="rightModel.deliveryMethodUpdate.hasRight() && componentState.isDetailView()"
        [iconClass]="'icomoon-modify'"
        [titleStringKey]="'COMMON_BUTTON_EDIT'"
        uiSref="Admin.DeliveryMethodEdit"
        [uiParams]="{ id: detailModel.id }">
      </app-breadcrumb-button>
    </app-breadcrumb-menu>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>

<div class="container-horizontal-padding animated fadeIn">

      <div class="container-custom-padding">

        <div class="form-group">

          <form class="form-horizontal bordered-row row" [formGroup]="formGroup" (ngSubmit)="submit()" #f=ngForm novalidate>

            <!--region Basic Details-->
            <div class="col-xs-12 col-sm-12 col-md-6 card-custom-padding">
              <div class="card">
                <div class="card-header">
                  {{ getHeadingDictionaryKey() | translate}}
                </div>
                <div class="card-body">

                  <!--region Name-->
                  <div class="form-group row"
                       [ngClass]="{ 'has-danger': (hasLocalFieldError('name') || hasFieldError(DeliveryMethod.ValidatedField.NAME)) }">
                    <label class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_NAME' |
                      translate}}</label>
                    <div class="col-md-8">
                      <input *ngIf="componentState.isEditable()"
                             type="text" class="form-control"
                             maxlength="{{UiConstants.maximumVarcharLength}}"
                             placeholder="{{'COMMON_NAME' | translate}}"
                             [(ngModel)]="editModel.name"
                             (ngModelChange)="removeFieldError(DeliveryMethod.ValidatedField.NAME)"
                             [ngClass]="{ 'form-control-danger': hasLocalFieldError('name') || hasFieldError(DeliveryMethod.ValidatedField.NAME) }"
                             formControlName="name">
                      <div class="form-control-feedback"
                           *ngIf="hasLocalFieldError('name')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                      <div class="form-control-feedback"
                           *ngIf="hasFieldError(DeliveryMethod.ValidatedField.NAME)">{{getFieldErrorText(DeliveryMethod.ValidatedField.NAME)}}</div>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.name}}
                      </label>
                    </div>
                  </div>
                  <!--endregion Name-->

                  <!--region ExternalId-->
                  <div class="form-group row"
                       [ngClass]="{ 'has-danger': (hasLocalFieldError('externalId') || hasFieldError(DeliveryMethod.ValidatedField.EXTERNAL_ID)) }">
                    <label class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_EXTERNAL_ID' |
                      translate}}</label>
                    <div class="col-md-8">
                      <input *ngIf="componentState.isEditable()"
                             type="text" class="form-control"
                             maxlength="{{UiConstants.maximumVarcharLength}}"
                             placeholder="{{'COMMON_EXTERNAL_ID' | translate}}"
                             [(ngModel)]="editModel.externalId"
                             (ngModelChange)="removeFieldError(DeliveryMethod.ValidatedField.EXTERNAL_ID)"
                             [ngClass]="{ 'form-control-danger': hasLocalFieldError('externalId') || hasFieldError(DeliveryMethod.ValidatedField.EXTERNAL_ID) }"
                             formControlName="externalId"
                             [required]="componentState.isEditable()">
                      <div class="form-control-feedback"
                           *ngIf="hasLocalFieldError('externalId')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                      <div class="form-control-feedback"
                           *ngIf="hasFieldError(DeliveryMethod.ValidatedField.EXTERNAL_ID)">{{getFieldErrorText(DeliveryMethod.ValidatedField.EXTERNAL_ID)}}</div>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.externalId}}
                      </label>
                    </div>
                  </div>
                  <!--endregion ExternalId-->

                  <!--region Type-->
                  <div class="form-group row"
                       [ngClass]="{ 'has-danger': hasLocalFieldError('type') }">
                    <label class="col-form-label form-control-label col-md-4 detail-title">{{'DELIVERY_METHOD_TYPE' |
                      translate}}</label>
                    <div class="col-md-8">
                      <div class="mw-100">
                        <angular2-multiselect *ngIf="componentState.isEditable()"
                                              [data]="types"
                                              [settings]="typeDropdownSettings"
                                              [(ngModel)]="editModel._type"
                                              [ngClass]="{ 'form-control-danger': hasLocalFieldError('type') }"
                                              formControlName="type">
                        </angular2-multiselect>
                      </div>
                      <div class="form-control-feedback"
                           *ngIf="hasLocalFieldError('type')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                      <!--Appears in detailView-->
                      <label *ngIf="componentState.isReadonly()"
                        class="form-control detail-description"
                        readonly>{{detailModel.type | translate}}
                      </label>
                    </div>
                  </div>
                  <!--endregion Type-->

                  <!--region Transporter company-->
                  <div class="form-group row"
                       [ngClass]="{ 'has-danger': hasLocalFieldError('transporterCompany') }">
                    <label class="col-form-label form-control-label col-md-4 detail-title">{{'DELIVERY_METHOD_TRANSPORTER_COMPANY' |
                      translate}}</label>
                    <div class="col-md-8">
                      <div class="mw-100">
                        <angular2-multiselect *ngIf="componentState.isEditable()"
                                              [data]="transporterCompanies"
                                              [settings]="transporterCompanyDropdownSettings"
                                              [(ngModel)]="editModel.transporterCompany"
                                              [ngClass]="{ 'form-control-danger': hasLocalFieldError('transporterCompany') }"
                                              formControlName="transporterCompany">
                        </angular2-multiselect>
                      </div>
                      <div class="form-control-feedback"
                           *ngIf="hasLocalFieldError('transporterCompany')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                      <!--Appears in detailView-->
                      <label *ngIf="componentState.isReadonly()"
                             class="form-control detail-description"
                             readonly>{{detailModel.transporterCompany}}
                      </label>
                    </div>
                  </div>
                  <!--endregion Tranporter company-->

                  <!--Only visible on create/edit views-->
                  <div class="col-md-12 d-flex justify-content-end p-0" *ngIf="componentState.isEditable()">
                    <div>
                      <input type="button" class="btn btn-secondary" (click)="back()"
                             value="{{'COMMON_BUTTON_CANCEL'|translate}}"/>
                      <input type="submit" class="btn btn-primary ml-1" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- endregion Basic Details-->

            <!--region Validation-->
            <div class="col-xs-12 col-sm-12 col-md-6 card-custom-padding">

              <div class="card">
                <div class="card-header">
                  {{ 'DELIVERY_METHOD_VALIDATION' | translate}}
                </div>

                <div class="card-body">

                  <!--region Package weight required-->
                  <div class="form-group row">
                    <label
                      class="col-form-label form-control-label col-md-4 detail-title">{{'DELIVERY_METHOD_PACKAGE_WEIGHT_REQUIRED' |
                      translate}}</label>
                    <div class="col-md-8">
                      <label class="switch switch-text switch-info mb-0" *ngIf="componentState.isEditable()">
                        <input type="checkbox" class="switch-input"
                               [ngModelOptions]="{standalone: true}"
                               [(ngModel)]="editModel.packageWeightRequired">
                        <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                        <span class="switch-handle"></span>
                      </label>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.packageWeightRequired ? ('COMMON_YES' | translate) : ('COMMON_NO' | translate)}}
                      </label>
                    </div>
                  </div>
                  <!--endregion Package weight required-->

                  <!--region Max package weight-->
                  <div class="form-group row" *ngIf="componentState.isEditable() || detailModel.maxPackageWeight"
                       [ngClass]="{ 'has-danger': hasLocalFieldError('maxPackageWeight')}">
                    <label class="col-form-label form-control-label col-md-4 detail-title">{{'DELIVERY_METHOD_MAX_PACKAGE_WEIGHT'
                      | translate}}</label>
                    <div class="col-md-8">
                      <div class="row" *ngIf="componentState.isEditable()">
                        <div class="col-md-8 padding-15-15-for-col-md pr-md-0 mb-md-0">
                          <input type="text" class="form-control"
                                 placeholder="{{'DELIVERY_METHOD_MAX_PACKAGE_WEIGHT' | translate}}"
                                 [textMask]="{mask: InputMask.NATURAL_FLOAT_WITH_THOUSAND_SEPARATOR, guide: false}"
                                 [(ngModel)]="editModel.maxPackageWeightValue"
                                 formControlName="maxPackageWeight">
                        </div>
                        <!-- /.col-md-8 -->
                        <div class="col-md-4 padding-15-15-for-col-md mb-0">
                          <select class="form-control" [compareWith]="SelectUtils.compareObjects"
                                  [(ngModel)]="editModel.maxPackageWeightUnit"
                                  [ngModelOptions]="{standalone: true}">
                            <option *ngFor="let item of selectableWeightUnits" [ngValue]="item">{{item}}</option>
                          </select>
                        </div>
                        <!-- /.col-md-4 -->
                      </div>
                      <div class="form-control-feedback" *ngIf="hasLocalFieldError('maxPackageWeight')">
                        {{'COMMON_VALIDATION_MESSAGE_NUMBER_NOT_POSITIVE' | translate}}
                      </div>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.maxPackageWeight}}
                      </label>
                    </div>
                  </div>
                  <!--endregion Max package weight-->

                  <!--region Package size required-->
                  <div class="form-group row">
                    <label
                      class="col-form-label form-control-label col-md-4 detail-title">{{'DELIVERY_METHOD_PACKAGE_SIZE_REQUIRED' |
                      translate}}</label>
                    <div class="col-md-8">
                      <label class="switch switch-text switch-info mb-0" *ngIf="componentState.isEditable()">
                        <input type="checkbox" class="switch-input"
                               [ngModelOptions]="{standalone: true}"
                               [(ngModel)]="editModel.packageSizeRequired">
                        <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                        <span class="switch-handle"></span>
                      </label>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.packageSizeRequired ? ('COMMON_YES' | translate) : ('COMMON_NO' | translate)}}
                      </label>
                    </div>
                  </div>
                  <!--endregion Package size required-->

                  <!--region Max package size-->
                  <div class="form-group row" *ngIf="componentState.isEditable()"
                       [ngClass]="{ 'has-danger': hasLocalFieldError('maxPackageSizeWidth')}">
                    <label class="col-form-label form-control-label col-md-4 detail-title">{{'DELIVERY_METHOD_MAX_PACKAGE_SIZE_WIDTH'
                      | translate}}</label>
                    <div class="col-md-8">
                      <div class="row">
                        <div class="col-md-8 pr-md-0">
                          <div class="row">
                            <div class="col-md padding-15-15-for-col-md mb-md-0">
                              <input type="text" class="form-control"
                                     placeholder="{{'DELIVERY_METHOD_MAX_PACKAGE_SIZE_WIDTH' | translate}}"
                                     maxlength="{{UiConstants.maxInputBigDecimalLengthWithSpaces}}"
                                     [textMask]="{mask: InputMask.NATURAL_FLOAT, guide: false}"
                                     [(ngModel)]="editModel.maxPackageSizeWidth"
                                     (ngModelChange)="validateMaxPackageSize()"
                                     formControlName="maxPackageSizeWidth">
                            </div>
                          </div>
                          <!-- /.row -->
                        </div>
                        <!-- /.col-md-8 -->
                        <div class="col-md-4">
                          <select class="form-control" [compareWith]="SelectUtils.compareStrings"
                                  [(ngModel)]="editModel.maxPackageSizeUnit"
                                  [ngModelOptions]="{standalone: true}">
                            <option *ngFor="let item of selectableLengthUnits" [ngValue]="item">{{item}}</option>
                          </select>
                        </div>
                        <!-- /.col-md-4 -->
                      </div>
                      <div class="form-control-feedback" *ngIf="hasLocalFieldError('maxPackageSizeWidth', 'required')">
                        {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                      </div>
                      <div class="form-control-feedback" *ngIf="hasLocalFieldError('maxPackageSizeWidth', 'numberNotPositive')">
                        {{'COMMON_VALIDATION_MESSAGE_NUMBER_NOT_POSITIVE' | translate}}
                      </div>
                    </div>
                  </div>

                  <div class="form-group row" *ngIf="componentState.isEditable()"
                       [ngClass]="{ 'has-danger': hasLocalFieldError('maxPackageSizeHeight')}">
                    <label class="col-form-label form-control-label col-md-4 detail-title">{{'DELIVERY_METHOD_MAX_PACKAGE_SIZE_HEIGHT'
                      | translate}}</label>
                    <div class="col-md-8">
                      <div class="row">
                        <div class="col-md-8 pr-md-0">
                          <div class="row">
                            <div class="col-md padding-15-15-for-col-md mb-md-0">
                              <input type="text" class="form-control"
                                     placeholder="{{'DELIVERY_METHOD_MAX_PACKAGE_SIZE_HEIGHT' | translate}}"
                                     maxlength="{{UiConstants.maxInputBigDecimalLengthWithSpaces}}"
                                     [textMask]="{mask: InputMask.NATURAL_FLOAT, guide: false}"
                                     [(ngModel)]="editModel.maxPackageSizeHeight"
                                     (ngModelChange)="validateMaxPackageSize()"
                                     formControlName="maxPackageSizeHeight">
                            </div>
                          </div>
                          <!-- /.row -->
                        </div>
                        <!-- /.col-md-8 -->
                        <div class="col-md-4">
                          <select class="form-control" [compareWith]="SelectUtils.compareStrings"
                                  [(ngModel)]="editModel.maxPackageSizeUnit"
                                  [ngModelOptions]="{standalone: true}">
                            <option *ngFor="let item of selectableLengthUnits" [ngValue]="item">{{item}}</option>
                          </select>
                        </div>
                        <!-- /.col-md-4 -->
                      </div>
                      <div class="form-control-feedback" *ngIf="hasLocalFieldError('maxPackageSizeHeight', 'required')">
                        {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                      </div>
                      <div class="form-control-feedback" *ngIf="hasLocalFieldError('maxPackageSizeHeight', 'numberNotPositive')">
                        {{'COMMON_VALIDATION_MESSAGE_NUMBER_NOT_POSITIVE' | translate}}
                      </div>
                    </div>
                  </div>

                  <div class="form-group row" *ngIf="componentState.isEditable()"
                       [ngClass]="{ 'has-danger': hasLocalFieldError('maxPackageSizeDepth')}">
                    <label class="col-form-label form-control-label col-md-4 detail-title">{{'DELIVERY_METHOD_MAX_PACKAGE_SIZE_DEPTH'
                      | translate}}</label>
                    <div class="col-md-8">
                      <div class="row">
                        <div class="col-md-8 pr-md-0">
                          <div class="row">
                            <div class="col-md padding-15-15-for-col-md mb-md-0">
                              <input type="text" class="form-control"
                                     placeholder="{{'DELIVERY_METHOD_MAX_PACKAGE_SIZE_DEPTH' | translate}}"
                                     maxlength="{{UiConstants.maxInputBigDecimalLengthWithSpaces}}"
                                     [textMask]="{mask: InputMask.NATURAL_FLOAT, guide: false}"
                                     [(ngModel)]="editModel.maxPackageSizeDepth"
                                     (ngModelChange)="validateMaxPackageSize()"
                                     formControlName="maxPackageSizeDepth">
                            </div>
                          </div>
                          <!-- /.row -->
                        </div>
                        <!-- /.col-md-8 -->
                        <div class="col-md-4">
                          <select class="form-control" [compareWith]="SelectUtils.compareStrings"
                                  [(ngModel)]="editModel.maxPackageSizeUnit"
                                  [ngModelOptions]="{standalone: true}">
                            <option *ngFor="let item of selectableLengthUnits" [ngValue]="item">{{item}}</option>
                          </select>
                        </div>
                        <!-- /.col-md-4 -->
                      </div>
                      <div class="form-control-feedback" *ngIf="hasLocalFieldError('maxPackageSizeDepth', 'required')">
                        {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                      </div>
                      <div class="form-control-feedback" *ngIf="hasLocalFieldError('maxPackageSizeDepth', 'numberNotPositive')">
                        {{'COMMON_VALIDATION_MESSAGE_NUMBER_NOT_POSITIVE' | translate}}
                      </div>
                    </div>
                  </div>

                  <div class="form-group row" *ngIf="componentState.isDetailView() && detailModel.maxPackageSize">
                    <label class="col-form-label form-control-label col-md-4 detail-title">{{'DELIVERY_METHOD_MAX_PACKAGE_SIZE'
                      | translate}}</label>
                    <div class="col-md-8">
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.maxPackageSize}}
                      </label>
                    </div>
                  </div>
                  <!--endregion Max package size-->

                  <!--region Max insurance price-->
                  <div class="form-group form-group-no-margin-md-down row" *ngIf="componentState.isEditable() || detailModel.maxInsurancePrice"
                       [ngClass]="{ 'has-danger': hasLocalFieldError('maxInsurancePrice')}">
                    <label class="col-form-label form-control-label col-md-4 detail-title">{{'DELIVERY_METHOD_MAX_INSURANCE_PRICE'
                      | translate}}</label>
                    <div class="col-md-8">
                      <div class="row" *ngIf="componentState.isEditable()">
                        <div class="col-md-8 padding-15-15-for-col-md pr-0">
                          <input type="text" class="form-control"
                                 placeholder="{{'DELIVERY_METHOD_MAX_INSURANCE_PRICE' | translate}}"
                                 maxlength="{{UiConstants.maxInputBigDecimalLengthWithSpaces}}"
                                 [textMask]="{mask: InputMask.NATURAL_FLOAT_WITH_THOUSAND_SEPARATOR, guide: false}"
                                 [(ngModel)]="editModel.maxInsurancePriceValue"
                                 formControlName="maxInsurancePrice">
                        </div>
                        <!-- /.col-md-8 -->
                        <div class="col-md-4 padding-15-15-for-col-md">
                          <select class="form-control" [compareWith]="SelectUtils.compareObjects"
                                  [(ngModel)]="editModel.maxInsurancePriceCurrency"
                                  [ngModelOptions]="{standalone: true}" disabled>
                            <option *ngFor="let item of selectableInsuranceCurrencies" [ngValue]="item">{{item}}</option>
                          </select>
                        </div>
                        <!-- /.col-md-4 -->
                      </div>
                      <div class="form-control-feedback" *ngIf="hasLocalFieldError('maxInsurancePrice')">
                        {{'COMMON_VALIDATION_MESSAGE_NUMBER_NOT_POSITIVE' | translate}}
                      </div>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.maxInsurancePrice}}
                      </label>
                    </div>
                  </div>
                  <!--endregion Max insurance price-->

                  <!--region Max cash on delivery price-->
                  <div class="form-group form-group-no-margin-md-down row" *ngIf="componentState.isEditable() || detailModel.maxCashOnDeliveryPrice"
                       [ngClass]="{ 'has-danger': hasLocalFieldError('maxCashOnDeliveryPrice')}">
                    <label class="col-form-label form-control-label col-md-4 detail-title">{{'DELIVERY_METHOD_MAX_CASH_ON_DELIVERY_PRICE'
                      | translate}}</label>
                    <div class="col-md-8">
                      <div class="row" *ngIf="componentState.isEditable()">
                        <div class="col-md-8 padding-15-15-for-col-md pr-0">
                          <input type="text" class="form-control"
                                 placeholder="{{'DELIVERY_METHOD_MAX_CASH_ON_DELIVERY_PRICE' | translate}}"
                                 maxlength="{{UiConstants.maxInputBigDecimalLengthWithSpaces}}"
                                 [textMask]="{mask: InputMask.NATURAL_FLOAT_WITH_THOUSAND_SEPARATOR, guide: false}"
                                 [(ngModel)]="editModel.maxCashOnDeliveryPriceValue"
                                 formControlName="maxCashOnDeliveryPrice">
                        </div>
                        <!-- /.col-md-8 -->
                        <div class="col-md-4 padding-15-15-for-col-md">
                          <select class="form-control" [compareWith]="SelectUtils.compareObjects"
                                  [(ngModel)]="editModel.maxCashOnDeliveryPriceCurrency"
                                  [ngModelOptions]="{standalone: true}">
                            <option *ngFor="let item of selectableInsuranceCurrencies" [ngValue]="item">{{item}}</option>
                          </select>
                        </div>
                        <!-- /.col-md-4 -->
                      </div>
                      <div class="form-control-feedback" *ngIf="hasLocalFieldError('maxCashOnDeliveryPrice')">
                        {{'COMMON_VALIDATION_MESSAGE_NUMBER_NOT_POSITIVE' | translate}}
                      </div>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.maxCashOnDeliveryPrice}}
                      </label>
                    </div>
                  </div>
                  <!--endregion Max cash on delivery price-->

                  <!--region Recipient email address required-->
                  <div class="form-group row">
                    <label
                      class="col-form-label form-control-label col-md-4 detail-title">{{'DELIVERY_METHOD_RECIPIENT_EMAIL_ADDRESS_REQUIRED' |
                      translate}}</label>
                    <div class="col-md-8">
                      <label class="switch switch-text switch-info mb-0" *ngIf="componentState.isEditable()">
                        <input type="checkbox" class="switch-input"
                               [ngModelOptions]="{standalone: true}"
                               [(ngModel)]="editModel.recipientEmailAddressRequired">
                        <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                        <span class="switch-handle"></span>
                      </label>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.recipientEmailAddressRequired ? ('COMMON_YES' | translate) : ('COMMON_NO' | translate)}}
                      </label>
                    </div>
                  </div>
                  <!--endregion Recipient email address required-->

                  <!--region Recipient phone number required-->
                  <div class="form-group row">
                    <label
                      class="col-form-label form-control-label col-md-4 detail-title">{{'DELIVERY_METHOD_RECIPIENT_PHONE_NUMBER_REQUIRED' |
                      translate}}</label>
                    <div class="col-md-8">
                      <label class="switch switch-text switch-info mb-0" *ngIf="componentState.isEditable()">
                        <input type="checkbox" class="switch-input"
                               [ngModelOptions]="{standalone: true}"
                               [(ngModel)]="editModel.recipientPhoneNumberRequired">
                        <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                        <span class="switch-handle"></span>
                      </label>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.recipientPhoneNumberRequired ? ('COMMON_YES' | translate) : ('COMMON_NO' | translate)}}
                      </label>
                    </div>
                  </div>
                  <!--endregion Recipient phone number required-->

                </div>
              </div>
            </div>
            <!--endregion Validation-->
          </form>
        </div>
        <!-- /.form-group row -->
      </div>
</div>


