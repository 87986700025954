/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { Order, PagingRequest, QueryResult, ResourceQueryResult, Services } from '../../util/services';
import { List, Map as ImmutableMap, Set } from 'immutable';
import { InvoiceStatisticsResource, InvoiceStatisticsResourceService } from './invoice-statistics-resource.service';
/* eslint-enable */

@Injectable()
export class InvoiceStatisticsService implements InvoiceStatistics.Service {

  query(request: InvoiceStatistics.QueryRequest): Observable<QueryResult<InvoiceStatistics.InvoiceStatistic>> {
    return Observable.create((observer: Observer<QueryResult<InvoiceStatistics.InvoiceStatistic>>) => {
      const resourceRequest: InvoiceStatisticsResource.QueryRequest = {
        order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.order),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
      };
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<InvoiceStatisticsResource.InvoiceStatistic>) => {
          observer.next({
            items: this.toPublicList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  constructor(private resourceService: InvoiceStatisticsResourceService) {}

  private toPublicList(resourceList: InvoiceStatisticsResource.InvoiceStatistic[]): List<InvoiceStatistics.InvoiceStatistic> {
    return List.of(...resourceList.map((r) => this.toPublic(r)));
  }

  private toPublic(r: InvoiceStatisticsResource.InvoiceStatistic): InvoiceStatistics.InvoiceStatistic {
    return {
      taskId: r.task_id,
      taskName: r.task_name,
      taskRecordCount: r.task_record_count,
      netSum: r.net_sum,
      grossSum: r.gross_sum
    };
  }

}

export namespace InvoiceStatistics {

  export interface Service {

    query(request: QueryRequest): Observable<QueryResult<InvoiceStatistic>>;

  }

  export interface QueryRequest {
    order?: Set<Order<InvoiceStatistics.OrderField>>;
    paging?: PagingRequest;
  }

  export interface InvoiceStatistic {
    taskId: number;
    taskName: string;
    taskRecordCount: number;
    netSum: number;
    grossSum: number;
  }

  export enum OrderField {
    TASK_ID,
    TASK_NAME,
    TASK_RECORD_COUNT,
    NET_SUM,
    GROSS_SUM
  }

}

class Keys {

  private static readonly TASK_ID = 'task_id';
  private static readonly TASK_NAME = 'task_name';
  private static readonly TASK_RECORD_COUNT = 'task_record_count';
  private static readonly NET_SUM = 'net_sum';
  private static readonly GROSS_SUM = 'gross_sum';

  private static readonly orderFieldKeyMap: ImmutableMap<InvoiceStatistics.OrderField, string> = ImmutableMap.of(
    InvoiceStatistics.OrderField.TASK_ID, Keys.TASK_ID,
    InvoiceStatistics.OrderField.TASK_NAME, Keys.TASK_NAME,
    InvoiceStatistics.OrderField.TASK_RECORD_COUNT, Keys.TASK_RECORD_COUNT,
    InvoiceStatistics.OrderField.NET_SUM, Keys.NET_SUM,
    InvoiceStatistics.OrderField.GROSS_SUM, Keys.GROSS_SUM,
  );

  public static toOrderFieldKey(field: InvoiceStatistics.OrderField): string {
    return Keys.orderFieldKeyMap.get(field)!;
  }

}
