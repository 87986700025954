<div class="card">
  <div class="card-header">
    {{'COLONNADE_CLIENT_DOCUMENT_STAT_PRODUCT_REPORT' | translate}}
    <div class="card-actions">
      <a class="btn-setting table-header-action-button d-flex align-items-center px-1 w-auto font-weight-normal" title="{{'COMMON_BUTTON_EXPORT' | translate}}"
         (click)="downloadXlsx()">
        <i class="icomoon pr-1 icomoon-file-xlsx"></i>
        <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_EXPORT' | translate}}</span>
      </a>
    </div><!-- /.card-actions -->
  </div>
  <app-colonnade-client-document-stat-search
    #searchComponent
    (onSearch)="loadList()"
    [searchScope]="ColonnadeClientDocumentStatSearchSearchScope.PRODUCT_REPORT"
    [currentUserId]="currentUserId"
    [userRequired]="false"
    [sponsorCodeRequired]="false"
    [branchCodeRequired]="false"
    [dateFromRequired]="true"
    [dateToRequired]="true"
    [rightModel]="rightModel">
  </app-colonnade-client-document-stat-search>
  <div *ngIf="!searchComponent.didSearch" class="text-center">
    <p class="font-weight-bold text-muted font-xl">{{ 'COLONNADE_CLIENT_DOCUMENT_STAT_NO_DATA_SEARCH_REQUIRED' | translate }}</p>
  </div>
  <div *ngIf="searchComponent.didSearch && productReports.length === 0" class="text-center">
    <p class="font-weight-bold text-muted font-xl">{{ 'COLONNADE_CLIENT_DOCUMENT_STAT_NO_DATA' | translate }}</p>
  </div>
  <ng-container *ngIf="searchComponent.didSearch && productReports.length !== 0">
    <div class="card-body">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header">
            <app-table-sorter-no-op [text]="'COLONNADE_CLIENT_DOCUMENT_STAT_SEARCH_USER' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op [text]="'COLONNADE_CLIENT_DOCUMENT_STAT_PRODUCT' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op [text]="'COLONNADE_CLIENT_DOCUMENT_STAT_PRESENTED_COUNT' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op [text]="'COLONNADE_CLIENT_DOCUMENT_STAT_CONTRACT_COUNT' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op [text]="'COLONNADE_CLIENT_DOCUMENT_STAT_SUM_PRICE' | translate"></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr class="table"
            *ngFor="let productReport of productReports">
          <td class="responsive-table-column">{{productReport.user.personName}} ({{productReport.user.userName}})</td>
          <td class="responsive-table-column">{{productReport.productText}}</td>
          <td class="responsive-table-column hidden-sm-down">{{productReport.presentedCount}}</td>
          <td class="responsive-table-column">{{productReport.insuranceCount}}</td>
          <td class="responsive-table-column">{{productReport.sumPrice | formattedNumber}} Ft</td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header">
            <app-table-sorter-no-op [text]="'COLONNADE_CLIENT_DOCUMENT_STAT_SEARCH_USER' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op [text]="'COLONNADE_CLIENT_DOCUMENT_STAT_PRODUCT' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op [text]="'COLONNADE_CLIENT_DOCUMENT_STAT_PRESENTED_COUNT' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op [text]="'COLONNADE_CLIENT_DOCUMENT_STAT_CONTRACT_COUNT' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op [text]="'COLONNADE_CLIENT_DOCUMENT_STAT_SUM_PRICE' | translate"></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
    </div>
    <div class="card-body">
      <ng-container *ngIf="presentationChartData">
        <h5>{{'COLONNADE_CLIENT_DOCUMENT_STAT_PRODUCT_PRESENTATION_CHART' | translate}}</h5>
        <google-chart #presentationChart [data]="presentationChartData"></google-chart>
      </ng-container>

      <ng-container *ngIf="contractChartData">
        <h5>{{'COLONNADE_CLIENT_DOCUMENT_STAT_PRODUCT_CONTRACT_CHART' | translate}}</h5>
        <google-chart *ngIf="contractChartData" #contractChart [data]="contractChartData"></google-chart>
      </ng-container>

      <ng-container *ngIf="productChartData">
        <h5>{{'COLONNADE_CLIENT_DOCUMENT_STAT_PRODUCT_PRODUCT_CHART' | translate}}</h5>
        <google-chart *ngIf="productChartData" #productChart [data]="productChartData"></google-chart>
      </ng-container>
    </div>
  </ng-container>
</div>
