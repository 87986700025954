/* eslint-disable */
import { Observable } from 'rxjs';
import { DefaultOffsetDateTimeTemplate, OffsetDateTime } from './dates';
import * as b64toBlob from 'b64-to-blob';
import { saveAs } from 'file-saver';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { HeaderKeys } from './http-services';

/* eslint-enable */

export interface BlobFile {
  getBlob(): Blob;

  getContentType(): string;

  getFileName(fallback: string): string;
}

export interface NamedBlobFile extends BlobFile {
  getPreferredFileName(): string;
}

export class DownloadedFile implements BlobFile {

  private readonly blob: Blob;
  private readonly fileName: string | null;
  private readonly contentType: string | null;

  public static extractContentTypeFromHeaders(headers: HttpHeaders | null): string | null {
    return headers ? headers.get(HeaderKeys.CONTENT_TYPE) : null;
  }

  public static extractFilenameFromHeaders(headers: HttpHeaders | null): string | null {
    const cd: string | null = headers ? headers.get(HeaderKeys.CONTENT_DISPOSITION) : null;
    if (cd) {
      const cdm = /.*filename="([^"]+)"/g.exec(cd);
      if (cdm && cdm.length >= 2) {
        const filename = cdm[1];
        return filename;
      }
    }
    return null;
  }

  public static extractBase64FromDataUrl(dataUrl: string): string {
    const m = /data:[^;]+;base64,(.*)/g.exec(dataUrl);
    if (m && m.length >= 2) {
      return m[1];
    }
    return dataUrl;
  }

  public constructor(response: HttpResponse<Blob>) {
    const h = response.headers;
    this.fileName = DownloadedFile.extractFilenameFromHeaders(h);
    this.contentType = DownloadedFile.extractContentTypeFromHeaders(h);
    if (response.body) {
      this.blob = response.body;
    }
  }

  public getFileName(fallback: string): string {
    if (fallback.length < 1) {
      throw new Error('File name can not be empty.');
    }
    return this.fileName ? this.fileName : fallback;
  }

  public getBlob(): Blob {
    return this.blob;
  }

  public getContentType(): string {
    return this.contentType ? this.contentType : 'application/octet-stream';
  }

}


export class NamedBlobFileDecorator implements NamedBlobFile {

  public constructor(private file: BlobFile, private localName: string) {
  }

  public getBlob(): Blob {
    return this.file.getBlob();
  }

  public getFileName(fallback: string): string {
    if (fallback.length === 0) {
      return this.getPreferredFileName();
    }
    return this.file.getFileName(fallback);
  }

  public getPreferredFileName(): string {
    return this.file.getFileName(this.localName);
  }

  public getContentType(): string {
    return this.file.getContentType();
  }

}

export class FileNameBuilder {

  private static datePattern = new DefaultOffsetDateTimeTemplate('YYYY-MM-DD');

  private parts: string[] = [];

  public addString(string?: string): FileNameBuilder {
    if (string && string.length > 0) {
      // TODO: simplify string (remove accents, replace spaces with - etc)
      this.parts.push(string);
    }
    return this;
  }

  public addOffsetDateTime(dateTime: OffsetDateTime): FileNameBuilder {
    if (dateTime.isValid()) {
      this.parts.push(dateTime.format(FileNameBuilder.datePattern));
    }
    return this;
  }

  public build(): string {
    return this.parts.join('_');
  }

}

export class LazyFileModel {

  // NOTE: This is a complete model for file download.
  // The model provides info about the downloaded file.
  // Pre-download data is not handled here!

  private _base64dataUrl?: string;
  private _file?: BlobFile;
  private _loading: boolean = false;
  private _error: boolean = false;
  private _thumbnailDataUrl?: string;

  constructor() {
  }

  get downloadNotStarted(): boolean {
    return !this.downloading && !this.downloaded && !this.downloadError
  }

  get downloading(): boolean {
    return this._loading;
  }

  get downloaded(): boolean {
    return this.base64dataUrl !== undefined;
  }

  get downloadError(): boolean {
    return this._error;
  }

  get base64dataUrl(): string | undefined {
    return this._base64dataUrl;
  }

  get thumbnailDataUrl(): string | undefined {
    return this._thumbnailDataUrl;
  }

  get contentType(): string | undefined {
    if (!this._file) {
      return undefined;
    }
    const file: BlobFile = this._file;
    return file.getContentType();
  }

  public createBase64data(): string | undefined {
    if (!this.base64dataUrl) {
      return undefined;
    }
    return DownloadedFile.extractBase64FromDataUrl(this.base64dataUrl);
  }

  public createBlob(): Blob | undefined {
    const data = this.createBase64data();
    const contentType = this.contentType;
    if (!contentType || !data) {
      return undefined;
    }
    return (b64toBlob as any)(data, contentType);
  }

  public createFileName(fallback?: string): string | undefined {
    if (!this._file) {
      return undefined;
    }
    const file: BlobFile = this._file;
    return file.getFileName(fallback ? fallback : '');
  }

  public saveAs(fallback?: string): boolean {
    const fileName = this.createFileName(fallback);
    const blob = this.createBlob();
    if (fileName && blob) {
      saveAs(blob, fileName);
      return true;
    }
    return false;
  }

  public downloadOnce(loader: Observable<BlobFile>, callback?: () => any) {
    if (this.downloading || this.downloaded) {
      return;
    }
    this.download(loader, callback);
  }

  public downloadThumbnail(loader: Observable<BlobFile>, callback?: () => any) {
    const self = this;
    loader.subscribe(
      (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.getBlob());
        reader.onloadend = function () {
          const base64data = reader.result;
          self._thumbnailDataUrl = base64data ? base64data + '' : undefined;
          if (callback) {
            callback();
          }
        }
      });
  }

  public download(loader: Observable<BlobFile>, callback?: () => any) {
    if (loader === null || loader === undefined) {
      throw new Error('Undefined loader');
    }
    const self = this;
    self._loading = true;
    self._error = false;
    loader.subscribe(
      (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.getBlob());
        reader.onloadend = function () {
          const base64data = reader.result;
          self._file = file;
          self._base64dataUrl = base64data ? base64data + '' : undefined;
          self._loading = false;
          if (callback) {
            callback();
          }
        }
      },
      (error) => {
        self._loading = false;
        self._error = true;
      });
  }

}

export class ContentTypeParser {

  public constructor(private contentType?: string) {
  }

  get isJSON(): boolean {
    return this.typeEqualsWith('application/json');
  }

  get isPdf(): boolean {
    return this.typeEqualsWith('application/pdf');
  }

  get isFodt(): boolean {
    return this.typeEqualsWith('application/fodt');
  }

  get isXls(): boolean {
    return this.typeEqualsWith('application/vnd.ms-excel')
      || this.typeEqualsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
  }

  get isZip(): boolean {
    return this.typeEqualsWith('application/zip');
  }

  get isAudioType(): boolean {
    return this.typeStartsWith('audio/');
  }

  get isImageType(): boolean {
    return this.typeStartsWith('image/');
  }

  get isJpeg(): boolean {
    return this.typeEqualsWith('image/jpeg');
  }

  get isPng(): boolean {
    return this.typeEqualsWith('image/png');
  }

  get isTextType(): boolean {
    return this.typeStartsWith('text/');
  }

  get isDoc(): boolean {
    return this.typeEqualsWith('application/msword')
      || this.typeEqualsWith('application/vnd.openxmlformats-officedocument.wordprocessingml.document');
  }

  get isVideoType(): boolean {
    return this.typeStartsWith('video/');
  }

  private typeEqualsWith(type: string): boolean {
    const ct = this.contentType;
    if (!ct) {
      return false;
    }
    return ct === type;
  }

  private typeStartsWith(prefix: string): boolean {
    const ct = this.contentType;
    if (!ct) {
      return false;
    }
    return ct.startsWith(prefix);
  }

}
