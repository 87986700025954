import { DqlQuery, Query } from '../field';
import { Process as ProcessNS } from '../../process/process.service';
import { LazyReference } from '../../../util/lazy';
import { User } from './user';
import { Workflow } from './workflow';

export class Process {

  private readonly creatorUserRef: LazyReference<User>;
  private readonly lastModifiedUserRef: LazyReference<User>;
  private readonly workflowRef: LazyReference<Workflow>;
  readonly id: Query.NumberField;
  readonly externalId: Query.StringField;
  readonly name: Query.StringField;
  readonly state: Query.EnumField<ProcessNS.ProcessState>;
  readonly creationTime: Query.DateTimeField;
  readonly updateTime: Query.DateTimeField;
  readonly startTime: Query.DateTimeField;
  readonly finishTime: Query.DateTimeField;

  get creatorUser(): User {
    return this.creatorUserRef.get();
  }

  get lastModifiedUser(): User {
    return this.lastModifiedUserRef.get();
  }

  get workflow(): Workflow {
    return this.workflowRef.get();
  }


  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this.id = new DqlQuery.NumberField(prefix + 'id');
    this.externalId = new DqlQuery.StringField(prefix + 'external_id');
    this.name = new DqlQuery.StringField(prefix + 'name');
    this.state = new DqlQuery.StringField(prefix + 'state');
    this.creationTime = new DqlQuery.DateTimeField(prefix + 'creation_time');
    this.updateTime = new DqlQuery.DateTimeField(prefix + 'update_time');
    this.startTime = new DqlQuery.DateTimeField(prefix + 'start_time');
    this.finishTime = new DqlQuery.DateTimeField(prefix + 'finish_time');
    this.creatorUserRef = LazyReference.of(
      () => new User(prefix + 'creator_user.')
    );
    this.lastModifiedUserRef = LazyReference.of(
      () => new User(prefix + 'last_modified_user.')
    );
    this.workflowRef = LazyReference.of(
      () => new Workflow(prefix + 'workflow.')
    );
  }

}
