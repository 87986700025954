/* eslint-disable */
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { OffsetDateTime } from '../../../lib/util/dates';
import { TransportLog, TransportLogService } from '../../../lib/transport/transport-log/transport-log.service';
import { Address } from '../../../lib/address';
import { ConfigModel } from '../../../util/task-record-utils';
import { ConfigurationService } from '../../../lib/core-ext/configuration.service';
import { Models } from '../../../util/model-utils';
import { UiConstants } from '../../../util/core-utils';
/* eslint-enable */

@Component({
  selector: 'app-transport-log-detail-modal',
  templateUrl: './transport-log-detail-modal.component.html',
  styleUrls: ['./transport-log-detail-modal.component.scss']
})
export class TransportLogDetailModalComponent implements OnInit {
  UiConstants = UiConstants;

  @Input()
  transportId: number;

  @ViewChild('transportLogDetail', { static: true }) transportLogDetail: ModalDirective;
  transportLogDetailVisible = false;

  transportLogDetailModel: TransportLogDetailModel = new TransportLogDetailModel();

  config: ConfigModel = new ConfigModel();

  constructor(private transportLogService: TransportLogService,
              private configService: ConfigurationService) { }

  ngOnInit() {

  }

  private loadConfig(completion: () => void) {
    this.config.postalAddressFormat = this.configService.getPostalAddressFormat();
    completion();
  }

  show(id: number) {
    this.loadConfig(() => {

    this.transportLogService.get({
      transportId: this.transportId,
      id: id
    }).subscribe((result: TransportLog.TransportLog) => {
      const transportLogModel = new TransportLogDetailModel();
      transportLogModel.id = result.id + '';
      transportLogModel.creationTime = result.creationTime;
      transportLogModel.user = result.user.personName;
      transportLogModel.procurer = '';
      transportLogModel.shipmentExternalId = result.shipment ? result.shipment.deliveryNoteNumber : '';
      transportLogModel.gpsCoordinate = result.coordinate ? result.coordinate.latitude + ', ' + result.coordinate.longitude : '';
      transportLogModel.user = result.user.personName;
      transportLogModel.eventType = TransportLog.transportLogTypes.find((type) => type.type === result.eventType)!.stringKey;
      transportLogModel.gateKeeperName = '';
      transportLogModel.userGroup = '';
      transportLogModel.transportTaskAddress = result.transportTask ?
        Address.PostalAddressMapper.toString(result.transportTask.shippingPlace.postalAddress, this.config.postalAddressFormat) : '';
      transportLogModel.gateKeeperExternalId = result.gateKeeperExternalId ? result.gateKeeperExternalId : '';
      transportLogModel.transportTaskName = result.transportTask ? result.transportTask.shippingPlace.name : '';
      transportLogModel.transportTaskIndex = result.transportTask ? Models.numberToString(result.transportTask.index) : '';

      this.transportLogDetailModel = transportLogModel;

    });

    this.transportLogDetailVisible = true;
    this.transportLogDetail.show();
    });
  }

  hide() {
    this.transportLogDetailVisible = false;
    this.transportLogDetail.hide();
  }

}

export class TransportLogDetailModel {

  id: string;
  creationTime?: OffsetDateTime = undefined;
  user: string = '';
  userName: string = '';
  procurer: string = '';
  shipmentExternalId: string = '';
  eventType: string = '';
  gpsCoordinate: string = '';
  gateKeeperName: string = '';
  userGroup: string = '';
  transportTaskAddress: string = '';
  gateKeeperExternalId: string = '';
  transportTaskName: string = '';
  transportTaskIndex: string = '' ;
}
