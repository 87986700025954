import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { CanvasDragEvent } from '../canvas-drag-event';

@Injectable()
export class CanvasDragLogService {

  private _subject: Subject<CanvasDragEvent> = new Subject();

  constructor() {
  }

  subscribe(next?: (value: CanvasDragEvent) => void, error?: (error: any) => void, complete?: () => void): Subscription {
    return this._subject.subscribe(next, error, complete);
  }

  unsubscribe(subscription: Subscription) {
    subscription.unsubscribe();
  }

  clear() {
    this._subject.unsubscribe();
    this._subject = new Subject();
  }

  onDrag(dragEvent: CanvasDragEvent) {
    this._subject.next(dragEvent);
  }

}
