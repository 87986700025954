import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-demo-mode-feature-disabled-dialog',
  templateUrl: './demo-mode-feature-disabled-dialog.component.html',
  styleUrls: ['./demo-mode-feature-disabled-dialog.component.scss']
})
export class DemoModeFeatureDisabledDialogComponent implements OnInit {

  public readonly CONTACT_PHONE_NUMBER: string = '+36306789170';
  public readonly CONTACT_EMAIL_ADDRESS: string = 'sales@applion.hu';

  constructor(public dialogRef: MatDialogRef<DemoModeFeatureDisabledDialogComponent>) {
  }

  ngOnInit() {
  }

}
