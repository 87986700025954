/* eslint-disable */
import {Component,} from '@angular/core';
import {
  FormRecordFieldContext,
  FormRecordFieldValueUpdateArgs,
  FormRecordFieldView,
  FormRecordFieldViewContext,
  FormRecordFieldViewModel,
  SetTmpReadonlyArgs,
  SetTmpReadonlyResult
} from '../../../../../util/form/form-utils';
import {Form} from '../../../../../lib/form/form.service';
import {Command} from '../../../../../util/command';
import {FormRecord} from '../../../../../lib/form/form-record.service';
import {Stock, StockService} from '../../../../../lib/stock/stock.service';
import {StockItem, StockItemService, StockItemStock} from '../../../../../lib/stock/stock-item.service';
import {Decimal} from 'decimal.js';
import {InputMask} from '../../../../../util/input-masks';
import {FormRef, LocalFormGroupValidationErrors, QueryResult, Services,} from '../../../../../lib/util/services';
import {FieldActivationState, FieldActivationStateResolver} from '../../../../../util/form/form-editors';
import {List} from 'immutable';
import {SelectUtils} from '../../../../../util/core-utils';
import {TranslateService} from '@ngx-translate/core';
import {ToasterService} from '../../../../../fork/angular2-toaster/angular2-toaster';
import {FormRecordInactivityManager} from '../../manager/form-record-inactivity-manager';
import {BadgeStyle} from '../../../../../shared/table-badge/badge-style';
import {MatDialog} from '@angular/material/dialog';
import {
  MatConfirmDialogComponent,
  MatConfirmDialogData
} from '../../../../../shared/mat-confirm-dialog/mat-confirm-dialog.component';
import {AlertType} from '../../../../../shared/confirm-dialog/confirm-dialog.component';
import {combineLatest, Observable} from 'rxjs';
import {StockTypeName} from '../../../../../util/stock/stock-utils';
import {Models} from '../../../../../util/model-utils';
import {Arrays} from '../../../../../lib/util/arrays';
import {StockIntakeSelectorUtils} from '../../../../../util/stock/stock-intake-selector-utils';
import {StockItemUtils} from '../../../../../util/stock/stock-item-utils';
import {LocalDate} from '../../../../../lib/util/dates';
import {
  FormRecordStockIntakeHeaderDialogComponent,
  FormRecordStockIntakeHeaderDialogData
} from './form-record-stock-intake-header-dialog/form-record-stock-intake-header-dialog.component';
import {Strings} from '../../../../../lib/util/strings';
import {Currency, CurrencyService} from '../../../../../lib/currency.service';
import {FormRecordFieldUpdateCustomerLocationArgs} from '../master-data/form-record-master-data-field.component';
import {StockItemUnitOfMeasure} from '../../../../../lib/stock/stock-item-unit-of-measure';
import {
  StockItemUnitOfMeasureMultiselectOptionItem,
  StockItemUnitOfMeasureMultiselectProvider
} from '../../../../../lib/stock/stock-item-unit-of-measure-multiselect.provider';
import {Angular2Multiselects} from '../../../../../util/multiselect';
import {Weight, WeightFactory} from "../../../../../util/weight-utils";
import StockIntakeHeaderState = FormRecord.StockIntakeHeaderState;

/* eslint-enable */

@Component({
  selector: 'app-form-record-stock-intake-field',
  templateUrl: 'form-record-stock-intake-field.component.html',
  styleUrls: ['form-record-stock-intake-field.component.scss'],
})
export class FormRecordStockIntakeFieldComponent implements FormRecordFieldView {

  public readonly selector: Form.FieldDataTypeSelector.STOCK_INTAKE;

  SelectUtils = SelectUtils;
  InputMask = InputMask;
  BadgeStyle = BadgeStyle;
  StockItemUtils = StockItemUtils;

  formRecordFieldContext?: FormRecordFieldContext;
  formRecordInactivityManager?: FormRecordInactivityManager;

  model: Model = new Model();
  excludedStockIds: number[] = [];
  excludedItemIds: ExcludedItemIdCollection = new ExcludedItemIdCollection();
  usableCategoryIds: number[] = [];
  stockTypeName?: StockTypeName;
  forceDestinationStockIds?: number[];
  customerRecordId: number | undefined;
  contactLocationId: number | undefined;

  hasDisabledItem: boolean = false;

  private headerState: FormRecord.StockIntakeHeaderState = StockIntakeHeaderState.DRAFT;
  private fieldId?: number;
  htmlForm?: FormRef;
  tmpReadonly: boolean = false;
  optionalValue: boolean = false;
  private formGroupValidationErrors: LocalFormGroupValidationErrors;
  currencies: Currency.Currency[];
  dropdownSettings: Angular2Multiselects.Settings = Angular2Multiselects.LOCAL_SINGLE_SELECT;


  private readonlyFormFn: () => boolean = () => true;
  private readonlyFieldFn: () => boolean = () => false;
  private hiddenFieldFn: () => boolean = () => false;

  get nonEditable(): boolean {
    return FieldActivationStateResolver.isNonEditable(
      this.fieldActivationState
    );
  }

  get requiredDisabled(): boolean {
    return FieldActivationStateResolver.isRequiredDisabled(
      this.fieldActivationState
    );
  }

  get required(): boolean {
    const optional = this.optionalValue;
    const requiredDisabled = this.requiredDisabled;
    return !optional && !requiredDisabled;
  }

  get allowItemEdit(): boolean {
    return this.headerState === StockIntakeHeaderState.DRAFT;
  }

  private get readonlyForm(): boolean {
    return this.readonlyFormFn();
  }

  private get fieldActivationState(): FieldActivationState {
    return FieldActivationStateResolver.resolveFieldActivationState({
      readonlyFormFn: () => this.readonlyFormFn(),
      readonlyFieldFn: () => this.readonlyFieldFn(),
      tmpReadonlyFieldFn: () => this.tmpReadonly,
    });
  }


  private get reqContext() {
    return this.formRecordFieldContext!;
  }

  setTmpReadonly(args: SetTmpReadonlyArgs): Command<SetTmpReadonlyResult> {
    const previousTmpReadonly = this.tmpReadonly;
    const previousValue = this.model;
    return {
      execute: async () => {
        let changed = false;
        if (this.tmpReadonly !== args.tmpReadonly) {
          if (args.tmpReadonly) {
            changed = FieldActivationStateResolver.inactivationChangesTheValue({
              debugId: this.reqContext.field.title,
              valueIsEmpty: !this.model.stocks || this.model.stocks.length === 0,
              valueEqualsDefaultValue: !this.model.stocks || this.model.stocks.length === 0,
              defaultValueIsEmpty: true,
              canApplyDefaultValue: false
            });
            this.model = new Model();
            this.tmpReadonly = args.tmpReadonly; // last
          } else {
            this.tmpReadonly = args.tmpReadonly; // first
            this.model = new Model();
          }
        }
        return {
          changed: changed
        };
      },
      undo: async () => {
        this.tmpReadonly = previousTmpReadonly;
        this.model = previousValue;
      }
    };
  }

  registerField(context: FormRecordFieldContext, originalModel?: any): any {
    if (originalModel) {
      this.model = originalModel;
    }
    this.formRecordFieldContext = context;
    this.fieldId = context.field.fieldId;
    this.htmlForm = context.htmlForm;
    this.readonlyFormFn = context.readonly;
    this.hiddenFieldFn = () => Form.FormFieldValidationType.HIDDEN === context.validationType;
    this.readonlyFieldFn = () => Form.FormFieldValidationType.READONLY === context.validationType
      || Form.FormFieldValidationType.HIDDEN === context.validationType;
    this.optionalValue = Form.FormFieldValidationType.REQUIRED !== context.validationType;
    if (context.field) {
      const stockIntakeAttributes = context.field.dataType.stockIntakeAttributes;
      if (stockIntakeAttributes) {
        this.usableCategoryIds = stockIntakeAttributes.usableStockItemCategories;
        this.stockTypeName = stockIntakeAttributes.stockType;
        this.forceDestinationStockIds = stockIntakeAttributes.stockDestinationFilters.length > 0
          ? stockIntakeAttributes.stockDestinationFilters
          : undefined;
      }
    }
    if (context.fieldRecord) {
      this.registerFieldData(context.fieldRecord);
    } else {
      this.model.loaded = true;
    }
    return this.model;
  }

  registerFieldData(fieldRecord: FormRecord.FieldComposed): void {
    const attrs = fieldRecord.data.stockIntakeAttributes!;
    this.loadItems(attrs);
  }

  private loadCurrencies() {
    this.currencyService.query({}).subscribe((result: QueryResult<Currency.Currency>) => {
      this.currencies = result.items.toArray();
    });
  }

  loadItems(attrs: FormRecord.FieldDataStockIntakeAttributes) {
    if (attrs.header) {
      this.model.header.intakeDate = attrs.header.intakeDate;
      this.model.header.shippingNumber = attrs.header.shippingNumber;
      this.model.header.shippingCompany = Models.optToString(attrs.header.shippingCompany);
      this.model.header.invoiceNumber = Models.optToString(attrs.header.invoiceNumber);
      this.headerState = attrs.header.state ? attrs.header.state : StockIntakeHeaderState.DRAFT;
    }
    if (attrs.values.size > 0) {
      const itemIds: number[] = [];
      const stockIds: number[] = [];
      attrs.values.forEach((item: FormRecord.StockIntakeItem) => {
        itemIds.push(item.stockItemId);
        this.excludedItemIds.add(item.stockId, item.stockItemId);
        stockIds.push(item.stockId);
        this.excludedStockIds.push(item.stockId);
      });
      combineLatest([
        this.stockItemService.query({
          id: itemIds.join(),
          with_amount: true
        }),
        this.stockService.query({
          id: stockIds.join()
        })
      ]).subscribe(result => {
        const items = result[0].items;
        const stocks = result[1].items;
        attrs.values.forEach((item: FormRecord.StockIntakeItem) => {
          const stock = this.getStockById(item.stockId, stocks)!;
          stock.stockItems.push(this.createStockItemModel(item, items, stock)!);
        });
        this.model.loaded = true;
        this.refreshHasDisabled();
      });
    } else {
      this.model.loaded = true;
    }
  }

  private getStockById(id: number, stocks?: Stock[]): StockModel | undefined {
    for (let i = 0; i < this.model.stocks.length; i++) {
      if (this.model.stocks[i].id === id) {
        return this.model.stocks[i];
      }
    }
    if (stocks) {
      for (let i = 0; i < stocks.length; i++) {
        const stock = stocks[i];
        if (stock.id === id) {
          this.model.stocks.push(new StockModel(stock.id, stock.name, stock.disabled));
          return this.model.stocks[this.model.stocks.length - 1];
        }
      }
    }
  }

  private createStockItemModel(item: FormRecord.StockIntakeItem, items: StockItem[], stock: StockModel): StockItemModel | undefined {
    for (let i = 0; i < items.length; i++) {
      const it = items[i];
      if (item.stockItemId === it.id) {
        const model = new StockItemModel();
        model.id = it.id;
        model.name = it.name;
        model.productCode = it.product_code;
        model.weightInGrams = it.weight_in_grams;
        model.packageData = it.package_data;
        model.measurements = this.unitOfMeasureMultiselectProvider.toMultiselectOptionItems(it.measurements);
        model.currencyCode = it.unit_price.currency_code;
        if (it.stock_amount) {
          model.inStockAmount = this.getStockAmount(it.stock_amount, stock.id);
          model.intakePriceAvg = this.getAvgPrice(it.stock_amount);
        }
        model.numberAmount = Models.decimalToNumber(item.amount);
        model.unitOfMeasure = model.measurements.filter(m => m.id === item.unitOfMeasureId);
        model.intakePrice = Models.decimalToNumber(item.intakeUnitNetPrice);
        model.disabled = it.disabled;
        model.baseUnit = it.unit;
        return model;
      }
    }
  }

  private refreshHasDisabled() {
    this.hasDisabledItem = false;
    this.model.stocks.forEach((item) => {
      if (item.disabled) {
        this.hasDisabledItem = true;
      }
      item.stockItems.forEach(i => {
        if (i.disabled) {
          this.hasDisabledItem = true;
        }
      });
    });
  }

  removeStock(id: number, auto?: boolean) {
    if (!auto) {
      const dialogConfig: MatConfirmDialogData = {
        titleKey: 'CONFIRM_DIALOG_TITLE_DELETE',
        messageKey: 'CONFIRM_DIALOG_MESSAGE_DELETE',
        alertType: AlertType.DANGER
      };
      const dialogRef = this.dialog.open(MatConfirmDialogComponent, {
        data: dialogConfig
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.model.stocks.splice(this.model.stocks.findIndex(s => s.id === id), 1);
          this.excludedStockIds.splice(this.excludedStockIds.findIndex(i => i === id), 1);
          this.excludedItemIds.remove(id);
          this.refreshHasDisabled();
        }
      });
    } else {
      this.model.stocks.splice(this.model.stocks.findIndex(s => s.id === id), 1);
      this.excludedStockIds.splice(this.excludedStockIds.findIndex(i => i === id), 1);
      this.excludedItemIds.remove(id);
      this.refreshHasDisabled();
    }
  }

  removeItem(stockId: number, id: number) {
    const dialogConfig: MatConfirmDialogData = {
      titleKey: 'CONFIRM_DIALOG_TITLE_DELETE',
      messageKey: 'CONFIRM_DIALOG_MESSAGE_DELETE',
      alertType: AlertType.DANGER
    };
    const dialogRef = this.dialog.open(MatConfirmDialogComponent, {
      data: dialogConfig
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const stockItems = this.model.stocks.find(s => s.id === stockId)!.stockItems;
        if (stockItems.length === 1) {
          this.removeStock(stockId, true);
        } else {
          stockItems.splice(stockItems.findIndex(i => i.id === id), 1);
          this.excludedItemIds.remove(stockId, id);
        }
        this.refreshHasDisabled();
      }
    });
  }

  openHeaderDialog() {
    const data: FormRecordStockIntakeHeaderDialogData = {
      readonly: this.nonEditable,
      intakeDate: this.model.header.intakeDate,
      shippingNumber: this.model.header.shippingNumber,
      shippingCompany: this.model.header.shippingCompany,
      invoiceNumber: this.model.header.invoiceNumber,
    };
    const dialogRef = this.dialog.open(FormRecordStockIntakeHeaderDialogComponent, {
      data: data,
      panelClass: 'custom-dialog-container',
    });

    dialogRef.afterClosed().subscribe((result?: FormRecordStockIntakeHeaderDialogData) => {
      if (result && !this.nonEditable) {
        this.model.header.intakeDate = result.intakeDate;
        this.model.header.shippingNumber = result.shippingNumber;
        this.model.header.shippingCompany = result.shippingCompany;
        this.model.header.invoiceNumber = result.invoiceNumber;
      }
    });

  }

  registerFieldViews(context: FormRecordFieldViewContext): void {
    this.formRecordInactivityManager = context.inactivityManager;
  }

  hasLocalFieldError(): boolean {

    if (this.nonEditable) {
      return false;
    }
    // Check stockIntakeAtributes disabled Items

    let hasDisabledItem = false;
    if (this.model.stocks) {
      this.model.stocks.forEach((value) => {
        if (value.disabled) {
          hasDisabledItem = true;
        }
        value.stockItems.forEach(i => {
          if (i.disabled) {
            hasDisabledItem = true;
          }
        });
      });
    }

    if (this.model.stocks.length !== 0) {
      if (this.model.header.isEmpty()) {
        return true;
      }
    }

    if (hasDisabledItem) {
      return true;
    }
    return false;
  }

  validateWithInterrupt(): boolean {
    if (this.nonEditable) {
      return false;
    }
    for (const stock of this.model.stocks) {
      for (const item of stock.stockItems) {
        if (!item.numberAmount || item.numberAmount <= 0) {
          return true;
        }
      }
    }
    if (this.model.header.isEmpty()) {
      return true;
    }
    return false;
  }

  isHeaderEmpty(): boolean {
    if (this.model.stocks.length !== 0) {
      if (this.model.header.isEmpty()) {
        return true;
      }
    }
    return false;
  }

  getCurrencyString(currencyCode: string): string {
    const currency = this.currencies.find((c) => c.currencyCode === currencyCode);
    if (currency) {
      return currency.localizedName;
    } else {
      return '';
    }
  }

  shouldNotifyAfterCreation(): boolean {
    return false;
  }

  afterFormRecordCreation(formRecordId: number): Observable<any> | undefined {
    return undefined;
  }

  createModel(): FormRecordFieldViewModel {
    if (this.fieldId === undefined) {
      throw new Error('Field ID is undefined');
    }
    let attrs: FormRecord.FieldDataStockIntakeAttributes;
    if (!this.model.loaded && this.formRecordFieldContext?.fieldRecord?.data) {
      attrs = this.formRecordFieldContext.fieldRecord.data.stockIntakeAttributes!;
    } else {
      attrs = {
        header: this.model.header.isEmpty() ? undefined : {
          intakeDate: this.model.header.intakeDate!,
          shippingNumber: this.model.header.shippingNumber,
          shippingCompany: Strings.undefinedOrNonEmpty(this.model.header.shippingCompany),
          invoiceNumber: Strings.undefinedOrNonEmpty(this.model.header.invoiceNumber),
        },
        values: List.of(...Arrays.flatten(this.model.stocks!.map(s => {
          return s.stockItems.map(i => {
            return {
              stockId: s.id,
              stockItemId: i.id,
              amount: i.amount!,
              intakeUnitNetPrice: Services.toDecimal(i.intakePrice),
              unitOfMeasureId: i.unitOfMeasureId
            };
          });
        })))
      };
    }
    return {
      fieldEditRequest: {
        fieldId: this.fieldId,
        data: {
          stockIntakeAttributes: attrs
        }
      }
    };
  }

  openStockIntakeDialog(stockId?: number) {
    StockIntakeSelectorUtils.startIntakeSelector({
      dialog: this.dialog,
      usableCategoryIds: this.usableCategoryIds,
      stockTypeName: this.stockTypeName,
      ownerCustomerRecordId: this.stockTypeName === 'CUSTOMER' ? this.customerRecordId : undefined,
      ownerContactLocationId: this.stockTypeName === 'CUSTOMER' ? this.contactLocationId : undefined,
      excludedStockItemIds: stockId ? this.excludedItemIds.get(stockId) : [],
      excludedStockIds: this.excludedStockIds,
      forceDestinationStockIds: this.forceDestinationStockIds,
      stockId: stockId,
      resultCallback: (result) => this.onStockIntakeSelectorResult(result)
    });
  }

  private onStockIntakeSelectorResult(result: StockIntakeSelectorUtils.StockIntakeSelectorResult) {
    if ((result.selectedStockItems && result.selectedStockItems.length > 0)
      || (result.selectedStockItemIds && result.selectedStockItemIds.length > 0)) {
      if (result.selectedStockId) {
        const stockModel = this.model.stocks.find(s => s.id === result.selectedStockId)!;
        this.loadStockItemSelectorResult(stockModel, result);
      } else {
        this.excludedStockIds.push(result.selectedStock!.id);
        const stockModel = new StockModel(result.selectedStock!.id, result.selectedStock!.itemName, false);
        this.model.stocks.push(stockModel);
        this.loadStockItemSelectorResult(stockModel, result);
      }
    }
  }

  private loadStockItemSelectorResult(stockModel: StockModel, result: StockIntakeSelectorUtils.StockIntakeSelectorResult) {
    if (result.selectedStockItems) {
      result.selectedStockItems.forEach(i => {
        this.excludedItemIds.add(stockModel.id, i.id);
        const model = new StockItemModel();
        model.id = i.id;
        model.name = i.name;
        model.productCode = i.productCode;
        model.weightInGrams = i.weightInGrams;
        model.packageData = i.packageData;
        model.currencyCode = i.currencyCode;
        model.inStockAmount = i.allStockAmount ? this.getStockAmount(i.allStockAmount, stockModel.id) : model.inStockAmount;
        model.intakePriceAvg = i.allStockAmount ? this.getAvgPrice(i.allStockAmount) : undefined;
        model.numberAmount = i.amount;
        model.disabled = false;
        model.baseUnit = i.baseUnit;
        model.measurements = i.measurements;
        model.unitOfMeasure = i.unitOfMeasure;

        stockModel.stockItems.push(model);
      });
    } else {
      result.selectedStockItemIds!.forEach(id => {
        this.excludedItemIds.add(stockModel.id, id);
      });
      this.stockItemService.query({
        id: result.selectedStockItemIds!.join(),
        with_amount: true
      }).subscribe(items => {
        items.items.forEach(i => {
          const model = new StockItemModel();
          model.id = i.id;
          model.name = i.name;
          model.productCode = i.product_code;
          model.weightInGrams = i.weight_in_grams;
          model.packageData = i.package_data;
          model.currencyCode = i.unit_price.currency_code;
          if (i.stock_amount) {
            model.inStockAmount = this.getStockAmount(i.stock_amount, stockModel.id);
            model.intakePriceAvg = this.getAvgPrice(i.stock_amount);
          }
          model.numberAmount = undefined;
          model.measurements = this.unitOfMeasureMultiselectProvider.toMultiselectOptionItems(i.measurements);
          model.unitOfMeasure = model.measurements.filter(m => m.baseUnit);
          model.disabled = i.disabled;
          model.baseUnit = i.unit;
          stockModel.stockItems.push(model);
        });
      });
    }
  }

  getStockAmount(allStockAmount: StockItemStock[], stockId: number) {
    let ret: StockItemStock | undefined;
    ret = allStockAmount.find(s => s.stock_id === stockId);
    return ret ? ret.amount : 0;
  }

  getAvgPrice(allStockAmount: StockItemStock[]) {
    const avgs = allStockAmount.filter(s => s.intake_avg_net_price)
      .map(s => s.intake_avg_net_price!);
    return avgs.length === 0
      ? 0
      : avgs.reduce((previousValue, currentValue) => previousValue + currentValue, 0) / avgs.length;
    ;
  }

  getTitle(): string {
    if (this.formRecordFieldContext && this.formRecordFieldContext.field) {
      return this.formRecordFieldContext.field.title;
    } else {
      this.translateService.get('FORM_RECORD_STOCK_INTAKE_TITLE').subscribe(
        (result: string) => {
          return result;
        }
      );
    }
    return '';
  }

  constructor(private stockService: StockService,
              private dialog: MatDialog,
              private toasterService: ToasterService,
              private translateService: TranslateService,
              private stockItemService: StockItemService,
              private unitOfMeasureMultiselectProvider: StockItemUnitOfMeasureMultiselectProvider,
              private currencyService: CurrencyService) {
    this.loadCurrencies();
  }

  updateValue(data: FormRecordFieldValueUpdateArgs) {
    if (data instanceof FormRecordFieldUpdateCustomerLocationArgs) {
      if ((<FormRecordFieldUpdateCustomerLocationArgs>data).customerRecordId !== null) {
        this.customerRecordId = (<FormRecordFieldUpdateCustomerLocationArgs>data).customerRecordId!;
      }
      this.contactLocationId = (<FormRecordFieldUpdateCustomerLocationArgs>data).contactLocationId;
    }
  }

}

export class Model {
  loaded: boolean = false;
  header: HeaderModel = new HeaderModel();
  stocks: StockModel[] = [];
}

export class HeaderModel {
  intakeDate?: LocalDate;
  shippingNumber: string = '';
  shippingCompany: string = '';
  invoiceNumber: string = '';

  get intakeDateString(): string | undefined {
    return this.intakeDate?.toIsoString();
  }

  isEmpty() {
    return this.intakeDate === undefined
      && this.shippingNumber.length === 0
      && this.shippingCompany.length === 0
      && this.invoiceNumber.length === 0;
  }
}

export class StockModel {
  stockItems: StockItemModel[] = [];

  constructor(public id: number, public name: string, public disabled: boolean) {
  }

  get sumWeight(): string {
    if (this.stockItems.length === 0) {
      return '';
    }
    let weight = 0;
    this.stockItems.forEach(si => {
      const w = si.sumWeightInGrams;
      if (w) {
        weight += w;
      }
    });
    if (weight === 0) {
      return '';
    }
    return WeightFactory.createWeightFromGram(weight, 'kg').toString();
  }

}

export class StockItemModel {
  id: number;
  name: string;
  productCode: string;
  inStockAmount: number = 0;
  amount?: Decimal = undefined;
  unitOfMeasure: StockItemUnitOfMeasureMultiselectOptionItem[] = [];
  disabled: boolean = false;
  baseUnit: string;
  packageData?: StockItemUnitOfMeasure.StockItemUnitOfMeasure;
  measurements: StockItemUnitOfMeasureMultiselectOptionItem[] = [];
  currencyCode: string;
  intakePrice?: number;
  intakePriceAvg?: number;

  // this is a helper field for UI, because number picker does not handle decimal.js very well
  private _numberAmount: number | undefined = undefined;
  private unitWeight?: Weight;

  get numberAmount(): number | undefined {
    return this.amount ? new Decimal(this.amount).toNumber() : undefined;
  }

  set numberAmount(value: number | undefined) {
    this.amount = value ? new Decimal(value) : undefined;
    this._numberAmount = value;
  }

  set weightInGrams(value: number | undefined) {
    if (!value) {
      return;
    }
    this.unitWeight = WeightFactory.createWeightFromGram(value, 'kg');
  }

  get weightInGrams(): number | undefined {
    if (!this.unitWeight) {
      return undefined;
    }
    return this.unitWeight.toGrams();
  }

  get formattedWeight(): string {
    if (!this.unitWeight) {
      return '';
    }
    return this.unitWeight.toString();
  }

  get sumWeightInGrams(): number {
    if (!this.unitWeight || !this.baseAmount) {
      return 0;
    }
    return this.unitWeight.toGrams() * this.baseAmount;
  }

  get sumWeightFormatted(): string {
    if (!this.unitWeight || !this.baseAmount) {
      return '';
    }
    return WeightFactory.createWeightFromGram(this.unitWeight.toGrams() * this.baseAmount, 'kg').toString();
  }

  get lineIntakePrice() {
    if (!this.baseAmount || !this.intakePrice) {
      return undefined;
    }
    return this.baseAmount * this.intakePrice;
  }

  get unitOfMeasureId(): number {
    return this.selectedUnitOfMeasure?.id!;
  }

  get selectedUnitOfMeasure(): StockItemUnitOfMeasureMultiselectOptionItem | undefined {
    return this.unitOfMeasure.length > 0 ? this.unitOfMeasure[0] : undefined;
  }

  isBaseUnitSelected(): boolean {
    if (!this.selectedUnitOfMeasure) {
      return true;
    }
    return this.selectedUnitOfMeasure.baseUnit;
  }

  get baseAmount(): number | undefined {
    if (!this.numberAmount || !this.selectedUnitOfMeasure) {
      return undefined;
    }
    return this.numberAmount * this.selectedUnitOfMeasure.conversion;
  }

  get baseAmountDecimal(): Decimal | undefined {
    if (!this.baseAmount) {
      return undefined;
    }
    return new Decimal(this.baseAmount);
  }

}

class ExcludedItemIdCollection {
  private ids: Map<number, number[]> = new Map();

  get(stockId): number[] {
    if (this.ids.has(stockId)) {
      return this.ids.get(stockId)!;
    } else {
      throw new Error('Invalid stock id');
    }
  }

  add(stockId: number, itemId: number) {
    if (this.ids.has(stockId)) {
      this.ids.get(stockId)!.push(itemId);
    } else {
      this.ids.set(stockId, [itemId]);
    }
  }

  remove(stockId: number, itemId?: number) {
    if (this.ids.has(stockId)) {
      if (itemId) {
        const array = this.ids.get(stockId)!;
        const index = array.findIndex(id => id === itemId);
        array.splice(index, 1);
      } else {
        this.ids.delete(stockId);
      }
    } else {
      throw new Error('Invalid stock id');
    }
  }
}
