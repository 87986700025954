<div>
  <div class="col-sm-12 p-0">
    <div ng2FileDrop *ngIf="hideQueue || !file"
         [uploader]="_uploader"
         class="drag-n-drop cursor-pointer"
         (fileOver)="!disabled && onFileOver($event)"
         (onFileDrop)="onFileDrop($event)"
         (click)="!disabled && fileInput.click()"
         [class.drag-n-drop-hover]="fileHovering">
      <div class="panel-body">
        <i class="icomoon icomoon-drag_drop"
           title="{{'COMMON_BUTTON_DOWNLOAD' | translate}}"></i>
        <br>
        <label class="title">{{'DRAG_AND_DROP_UPLOADER_TITLE' | translate}}</label>
        <br>
        <label class="subtitle">{{'DRAG_AND_DROP_UPLOADER_SUBTITLE' | translate}}</label>
        <br>
        <input type="file"
               class="d-none"
               ng2FileSelect
               #fileInput
               [uploader]="_uploader"
               (onFileSelected)="onFileDrop($event)"
               [accept]="acceptedMimeType ? acceptedMimeType : UploadMimeType.ALL"/>
      </div>
    </div>

    <div class="drag-n-drop d-flex align-items-center justify-content-center" *ngIf="file && !hideQueue">
      <div class="position-relative w-100 d-flex align-items-center justify-content-center">
        <div>
          <img src="{{getFileTypeModel().iconImageSrc}}" class="file-type-icon">
          <div class="position-absolute file-type-icon file-type-icon-overlay"></div>
        </div>
        <div class="position-absolute h-100 d-flex flex-column justify-content-between align-items-center mb-05"
             [popover]="errorPopover" triggers="" container="body" placement="bottom" #errorPopoverContainer="bs-popover">
          <div class="file-action-container">
            <i class="material-icons file-action-icon cursor-pointer float-right p-0"
               *ngIf="file.isError || !file.isUploaded"
               (click)="clearQueue()">highlight_off</i>
          </div>
          <div>
            <div class="progress" *ngIf="file.isUploading">
              <div class="progress-bar" [ngStyle]="{ 'width': file.progress + '%' }"></div>
            </div>
            <i class="material-icons file-action-icon"
               *ngIf="file.isSuccess">
              check_circle
            </i>
            <br *ngIf="file.isSuccess">
            <label class="file-detail">{{file.file.name}}</label>
            <br>
            <label class="file-detail">{{ getFileSizeModel().value | formattedNumber }} {{getFileSizeModel().unitDictKey | translate }}</label>
          </div>
        </div>
        <ng-template #errorPopover>
          <div class="alert alert-danger mb-0" *ngIf="file.isError">
            <h4 class="alert-title">{{'ALERT_TEXT_UPLOAD_FAILED' | translate}}</h4>
            <p>{{uploadGlobalErrors}}</p>
            <p [innerHTML]="uploadCollectionFieldErrors"></p>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
