import { DqlQuery, Query } from '../field';
import { LazyReference } from '../../../util/lazy';
import { TaskRecord } from './task-record';
import { User } from './user';
import { Vehicle } from './vehicle';

export class MileageRecord {

  readonly id: Query.NumberField;

  readonly creationTime: Query.DateTimeField;

  readonly finishTime: Query.DateTimeField;

  readonly startKilometer: Query.NumberField;

  readonly finishKilometer: Query.NumberField;

  readonly journeyType: Query.StringField;

  readonly note: Query.StringField;

  private readonly taskRecordReference: LazyReference<TaskRecord>; // Lazy to support circular references.

  private readonly userReference: LazyReference<User>; // Lazy to support circular references.

  private readonly vehicleReference: LazyReference<Vehicle>; // Lazy to support circular references.

  get taskRecord(): TaskRecord {
    return this.taskRecordReference.get();
  }

  get user(): User {
    return this.userReference.get();
  }

  get vehicle(): Vehicle {
    return this.vehicleReference.get();
  }

  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this.id = new DqlQuery.NumberField(prefix + 'id');
    this.creationTime = new DqlQuery.DateTimeField(prefix + 'creation_time');
    this.finishTime = new DqlQuery.DateTimeField(prefix + 'finish_time');
    this.startKilometer = new DqlQuery.NumberField(prefix + 'start_kilometer');
    this.finishKilometer = new DqlQuery.NumberField(prefix + 'finish_kilometer');
    this.note = new DqlQuery.StringField(prefix + 'note');
    this.journeyType = new DqlQuery.StringField(prefix + 'journey_type');
    this.taskRecordReference = LazyReference.of(
      () => new TaskRecord(prefix + 'task_record.')
    );
    this.userReference = LazyReference.of(
      () => new User(prefix + 'user.')
    );
    this.vehicleReference = LazyReference.of(
      () => new Vehicle(prefix + 'vehicle.')
    );
  }

}
