/* eslint-disable */
import { Component, ComponentRef, ViewChild, } from '@angular/core';
import {
  FieldDataTypeSelectors,
  FormRecordFieldContext,
  FormRecordFieldValueUpdateArgs,
  FormRecordFieldView,
  FormRecordFieldViewContext,
  FormRecordFieldViewModel,
  SetTmpReadonlyArgs,
  SetTmpReadonlyResult,
} from '../../../../../util/form/form-utils';
import { Form } from '../../../../../lib/form/form.service';
import { FormRef, ForwardingFormRef, } from '../../../../../lib/util/services';
import { List, } from 'immutable';
import { combineLatest, Observable } from 'rxjs';
import { FormRecord } from '../../../../../lib/form/form-record.service';
import { FormRecordFormTableFieldHolderDirective } from './form-record-form-table-field-holder.directive';
import { FormTable, FormTableService, } from '../../../../../lib/form/form-table.service';
import { FormRecordComposedContainer } from '../../groups/composed/form-record-composed-container';
import { FormRecordFormTableFieldWrapper } from './wrapper/form-record-form-table-field-record-wrapper';
import { FieldActivationState, FieldActivationStateResolver, } from '../../../../../util/form/form-editors';
import { Command } from '../../../../../util/command';
import { FormRecordInactivityManager } from '../../manager/form-record-inactivity-manager';

/* eslint-enable */

@Component({
  selector: 'app-form-record-form-table-field',
  templateUrl: 'form-record-form-table-field.component.html',
  styleUrls: ['form-record-form-table-field.component.scss'],
})
export class FormRecordFormTableFieldComponent
  implements FormRecordFieldView, FormRecordFormTableFieldWrapper.ShowHideListener {

  public readonly selector: Form.FieldDataTypeSelector.FORM_TABLE;

  @ViewChild(FormRecordFormTableFieldHolderDirective, {static: true})
  fieldHost: FormRecordFormTableFieldHolderDirective;

  model: Model = new Model();

  private selectors: FieldDataTypeSelectors;
  private recordViews: ComponentRef<FormRecordComposedContainer.View>[] = [];
  private wrapperViews: ComponentRef<FormRecordFormTableFieldWrapper.View>[] = [];

  // context fields are always optional
  formRecordFieldContext?: FormRecordFieldContext;
  formRecordInactivityManager?: FormRecordInactivityManager;
  private formTable?: FormTable.FormTable;
  private formTableForm?: Form.Form;

  tmpReadonly: boolean = false;

  private readonlyFormFn: () => boolean = () => true;
  private readonlyFieldFn: () => boolean = () => false;
  private hiddenFieldFn: () => boolean = () => false;

  get nonEditable(): boolean {
    return FieldActivationStateResolver.isNonEditable(
      this.fieldActivationState
    );
  }

  private get readonlyForm(): boolean {
    return this.readonlyFormFn();
  }

  private get fieldActivationState(): FieldActivationState {
    return FieldActivationStateResolver.resolveFieldActivationState({
      readonlyFormFn: () => this.readonlyFormFn(),
      readonlyFieldFn: () => this.readonlyFieldFn(),
      tmpReadonlyFieldFn: () => this.tmpReadonly,
    });
  }

  get forwardingForm(): FormRef {
    return new ForwardingFormRef({
      formFn: () => {
        return this.formRecordFieldContext === undefined
          ? undefined : this.formRecordFieldContext.htmlForm;
      }
    });
  }

  constructor(private formTableService: FormTableService) {
  }

  onShowContent(view: FormRecordFormTableFieldWrapper.View) {
    this.wrapperViews.forEach((wrapperView) => {
      if (wrapperView.instance === view) {
        return;
      }
      wrapperView.instance.hideContent();
    });
  }

  onHideContent(view: FormRecordFormTableFieldWrapper.View) {
  }

  registerSelectors(selectors: FieldDataTypeSelectors) {
    this.selectors = selectors;
  }

  setTmpReadonly(args: SetTmpReadonlyArgs): Command<SetTmpReadonlyResult> {
    const previousTmpReadonly = this.tmpReadonly;
    return {
      execute: async () => {
        let changed = false;
        this.tmpReadonly = args.tmpReadonly;
        if (args.tmpReadonly) {
          changed = changed || this.recordViews.length !== 0;
          this.clear();
        }
        return {
          changed: changed
        };
      },
      undo: async () => {
        this.tmpReadonly = previousTmpReadonly;
        // TODO: undo clear
      }
    };
  }

  registerField(context: FormRecordFieldContext, originalModel?: any): any {
    if (originalModel) {
      this.model = originalModel;
    }
    this.hiddenFieldFn = () => Form.FormFieldValidationType.HIDDEN === context.validationType;
    this.readonlyFieldFn = () => Form.FormFieldValidationType.READONLY === context.validationType
      || Form.FormFieldValidationType.HIDDEN === context.validationType;
    this.readonlyFormFn = () => {
      return context.readonly() || this.readonlyFieldFn();
    };
    const attrs = context.field.dataType.formTableAttributes!;
    this.model.title = context.field.title;
    this.formRecordFieldContext = context;
    combineLatest(
      this.formTableService.getForm({
        parentId: attrs.formTableId
      }),
      this.formTableService.get({
        formTableId: attrs.formTableId
      }),
      (formTableForm: Form.Form, formTable: FormTable.FormTable) => {
        return {
          formTable: formTable,
          formTableForm: formTableForm
        }
      })
      .subscribe((result: CombinedResult) => {
        this.formTable = result.formTable;
        this.formTableForm = result.formTableForm;
        this.model.formTableName = result.formTable.name;
        this.registerFieldItemData();
      });
    return this.model;
  }

  registerFieldViews(context: FormRecordFieldViewContext): void {
    this.formRecordInactivityManager = context.inactivityManager;
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    let hasError = false;
    List.of(...this.recordViews).forEach((recordView) => {
      const e = recordView!.instance.hasLocalFieldError();
      if (e) {
        hasError = true;
        return false;
      }
    });
    return hasError;
  }

  validateWithInterrupt(): boolean {
    return false;
  }

  shouldNotifyAfterCreation(): boolean {
    return false;
  }

  afterFormRecordCreation(formRecordId: number): Observable<any> | undefined {
    return undefined;
  }

  createModel(): FormRecordFieldViewModel {
    if (this.formRecordFieldContext === undefined || this.formRecordFieldContext.field.fieldId === undefined) {
      throw new Error('Field ID is undefined');
    }
    const models: FormRecord.FormRecordComposed[] = [];
    List.of(...this.recordViews).forEach((recordView: ComponentRef<FormRecordComposedContainer.View>) => {
      const model = recordView!.instance.createModel();
      models.push(model);
    });
    const attrs: FormRecord.FieldDataFormTableAttributes = {
      values: List.of<FormRecord.FormRecordComposed>(...models)
    };
    return {
      fieldEditRequest: {
        fieldId: this.formRecordFieldContext.field.fieldId,
        data: {
          formTableAttributes: attrs
        }
      }
    };
  }

  addItem() {
    this.insertItem().showContent();
  }

  private registerFieldItemData(): void {
    let formTableFormRecords: List<FormRecord.FormRecordComposed>;
    if (this.formRecordFieldContext!.fieldRecord) {
      formTableFormRecords = this.formRecordFieldContext!.fieldRecord!.data.formTableAttributes!.values;
    }
    else {
      formTableFormRecords = List.of<FormRecord.FormRecordComposed>();
    }
    formTableFormRecords.forEach((formTableFormRecord) => {
      this.insertItem(formTableFormRecord);
    });
  }

  private insertItem(formTableFormRecord?: FormRecord.FormRecordComposed): FormRecordFormTableFieldWrapper.View {
    const wrapperView = this.fieldHost.createFormRecordFormTableFieldWrapper();
    const formRecordView = this.fieldHost.createFormRecordComponent(this.forwardingForm, this.readonlyFormFn);
    const formRecordPreView = this.fieldHost.createFormRecordPreviewComponent(this.forwardingForm);
    const loadArgs = {
      fieldDataTypeSelectors: this.selectors,
      commandResultStore: this.formRecordFieldContext!.commandResultStore,
      commandManager: this.formRecordFieldContext!.commandManager,
      configuration: this.formRecordFieldContext!.configuration,
      form: this.formTableForm!,
      record: formTableFormRecord
    };
    formRecordView.instance.loadFormRecord(loadArgs);
    formRecordPreView.instance.loadFormRecord(loadArgs, formRecordView.instance);
    this.recordViews.push(formRecordView);
    this.wrapperViews.push(wrapperView);
    wrapperView.instance.setTitle(this.formTable!.name);
    wrapperView.instance.setShowHideListener(this);
    wrapperView.instance.setReadonly(this.readonlyFormFn);
    wrapperView.instance.registerContent(formRecordView, formRecordPreView);
    wrapperView.instance.setOnRemoveListener(() => {
      this.removeItem(formRecordView, wrapperView);
    });
    this.fieldHost.viewContainerRef.insert(wrapperView.hostView);
    return wrapperView.instance;
  }

  private removeItem(
    formRecordView: ComponentRef<FormRecordComposedContainer.View>,
    wrapperView: ComponentRef<FormRecordFormTableFieldWrapper.View>) {
    const viewIndex = this.recordViews.indexOf(formRecordView);
    this.recordViews.splice(viewIndex, 1);
    this.wrapperViews.splice(viewIndex, 1);
    this.fieldHost.viewContainerRef.remove(viewIndex);
    formRecordView.destroy();
    wrapperView.destroy();
  }

  private clear() {
    this.recordViews.forEach((formRecordView) => {
      const viewIndex = this.recordViews.indexOf(formRecordView);
      const wrapperView = this.wrapperViews[viewIndex];
      this.fieldHost.viewContainerRef.remove(viewIndex);
      formRecordView.destroy();
      wrapperView.destroy();
    });
    this.recordViews.splice(0, this.recordViews.length);
    this.wrapperViews.splice(0, this.wrapperViews.length);
  }

  updateValue(data: FormRecordFieldValueUpdateArgs) {
  }

}

export class Model {
  title: string = '';
  formTableName: string = '';
}

interface CombinedResult {
  formTable: FormTable.FormTable;
  formTableForm: Form.Form;
}
