/* eslint-disable */
import { Observable, Observer } from 'rxjs';
import { Order, OrderType } from '../util/services';
import { MultiselectOptionItem, UiConstants } from '../../util/core-utils';
import { MemStorage } from '../util/mem-storage';
import { environment } from '../../../environments/environment';
import { CompanyStock } from './company-stock.service';
import { DisabledItem } from '../../util/search-utils';
import { RuntimeConfiguration } from '../runtime-configuration';
import { LoggedInUserStorage } from '../util/storages';
import { Injectable } from "@angular/core";
/* eslint-enable */

@Injectable()
export class CompanyStockSearchService {

  constructor() {
  }

  getSearchData(request: CompanyStockSearch.SearchDataGetRequest): Observable<CompanyStockSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<CompanyStockSearch.SearchDataResult>) => {
      try {
        observer.next(storage.read(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  setSearchData(request: CompanyStockSearch.SearchDataSetRequest): Observable<CompanyStockSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<CompanyStockSearch.SearchDataResult>) => {
      try {
        observer.next(storage.save(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  resetSearchData(request: CompanyStockSearch.SearchDataGetRequest): Observable<CompanyStockSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<CompanyStockSearch.SearchDataResult>) => {
      try {
        observer.next(storage.reset(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  private createStorage(): CompanyStockSearch.Storage {
    return new CompanyStockSearch.Storage();
  }

}

export namespace CompanyStockSearch {

  export interface SearchDataGetRequest {
  }

  export interface SearchDataSetRequest {
    searchData: SearchData;
  }

  export interface SearchDataResult {
    searchData: SearchData;
  }

  export interface SearchData {
    itemsPerPage: number;
    pageNumber: number;
    order: Order<CompanyStock.OrderField>;
    id: string;
    externalId: string;
    name: string;
    locationIds: LocationItem[];
    companyIds: CompanyItem[];
    userGroups: MultiselectOptionItem<number>[];
    disabled?: DisabledItem;
  }

  interface StoredSearchRawDataBase {
    version: number;
  }

  interface StoredSearchRawData extends StoredSearchRawDataBase {
    itemsPerPage: number;
    pageNumber: number;
    order: Order<CompanyStock.OrderField>;
    id: string;
    externalId: string;
    name: string;
    locationIds: LocationItem[];
    companyIds: CompanyItem[];
    userGroups: MultiselectOptionItem<number>[];
    disabled?: DisabledItem;
  }

  export class Storage {

    /**
     * Increment this version if you change the SearchData interface.
     */
    private static readonly VERSION: number = 3;

    private static readonly DEFAULT_SEARCH_DATA: SearchData = {
      itemsPerPage: UiConstants.pageNumbers[0],
      pageNumber: 1,
      order: {
        field: CompanyStock.OrderField.ID,
        type: OrderType.DESC
      },
      id: '',
      externalId: '',
      name: '',
      locationIds: [],
      companyIds: [],
      userGroups: [],
      disabled: DisabledItem.active(),
    };

    constructor() {
    }

    public read(request: SearchDataGetRequest): SearchDataResult {
      let rawBase: StoredSearchRawDataBase;
      try {
        const key = this.generateKey();
        const json = MemStorage.getItem(key);
        if (!json) {
          return this.createDefaultResult();
        }
        rawBase = JSON.parse(json);
      }
      catch (error) {
        return this.createDefaultResult();
      }
      if (Storage.VERSION === rawBase.version) {
        const raw: StoredSearchRawData = <StoredSearchRawData>rawBase;
        return this.fromRaw(raw);
      }
      return this.createDefaultResult();
    }


    public reset(request: SearchDataGetRequest): SearchDataResult {
      return this.save({
        searchData: Storage.DEFAULT_SEARCH_DATA,
      });
    }


    public save(request: SearchDataSetRequest): SearchDataResult {
      const key = this.generateKey();
      const raw = this.toRaw(request.searchData);
      const json = JSON.stringify(raw);
      MemStorage.setItem(key, json);
      return this.read(request);
    }

    private generateKey(): string {
      return 'UserId(' + LoggedInUserStorage.getInstance().getUserId() + ')-Search-CompanyStock';
    }

    private createDefaultResult(): SearchDataResult {
      return {
        searchData: Storage.DEFAULT_SEARCH_DATA,
      };
    }


    private fromRaw(data: StoredSearchRawData): SearchDataResult {
      return {
        searchData: {
          itemsPerPage: data.itemsPerPage,
          pageNumber: data.pageNumber,
          order: data.order,
          id: data.id,
          externalId: data.externalId,
          name: data.name,
          locationIds: data.locationIds,
          companyIds: data.companyIds,
          userGroups: data.userGroups,
          disabled: data.disabled,

        }
      };
    }

    private toRaw(data: SearchData): StoredSearchRawData {
      return {
        version: Storage.VERSION,
        itemsPerPage: data.itemsPerPage,
        pageNumber: data.pageNumber,
        order: data.order,
        id: data.id,
        externalId: data.externalId,
        name: data.name,
        locationIds: data.locationIds,
        companyIds: data.companyIds,
        userGroups: data.userGroups,
        disabled: data.disabled,

      };
    }
  }
  export class CompanyItem {
    id: number;
    name: string;

    get itemName() {
      return this.name;
    }
  }

  export class LocationItem {
    id: number;
    name: string;

    get itemName() {
      return this.name;
    }
  }
}
