<div bsModal [config]="UiConstants.modalConfig" #dialog="bs-modal" class="modal" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document" *ngIf="dialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title"><strong>{{'ORDER_MPL_EXPORT' | translate}}</strong></p>
        <button type="button" class="close" (click)="closeDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">

        <div class="form-group row">
          <div class="col-md-12">
            <div class="d-flex">
              <app-animated-checkbox
                [(selected)]="groupByIdenticalItems">
              </app-animated-checkbox>
              <label class="m-0 pl-1">{{'DOCUMENT_DOWNLOAD_GROUP_BY_IDENTICAL_ITEMS' | translate}}</label>
            </div>
          </div>
        </div>

      </div><!-- /.modal-body -->

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeDialog()">{{'COMMON_BUTTON_CANCEL' |
          translate}}
        </button>
        <button type="submit" data-style="zoom-in" [ladda]="downloading" [disabled]="downloading" (click)="mplExport()"
                class="btn btn-primary btn-ladda px-2">{{'COMMON_BUTTON_DOWNLOAD' |
          translate}}
        </button>
      </div>

    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
