/* eslint-disable */
import { Injectable } from '@angular/core';
import { EmptyMessage } from './util/messages';
import { Observable } from 'rxjs';
import { BaseHttpService, ResourceHelper } from './util/http-services';
import { HttpClient } from '@angular/common/http';
/* eslint-enable */

@Injectable()
export class RootCoreService extends BaseHttpService {

  getPrivileges(request: EmptyMessage): Observable<Privilege[]> {
    return this.http.get<Privilege[]>(this.url + `privileges`);
  }

  getApplicationTypes(request: EmptyMessage): Observable<ApplicationType[]> {
    return this.http.get<ApplicationType[]>(this.url + `application-types`);
  }

  getLanguageCodes(request: EmptyMessage): Observable<string[]> {
    return this.http.get<string[]>(this.url + `languages`);
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/');
  }

}

export interface Privilege {
  definition: string;
  short_name: string;
}

export interface ApplicationType {
  id: number;
  key: string;
  name: string;
}
