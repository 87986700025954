<div bsModal [config]="UiConstants.modalConfig" #companyCreateDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true"
     (onHide)="onModalHidden()"
     (onShown)="onModalShown()">
  <div class="modal-dialog modal-lg" role="document" *ngIf="companyCreateDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title"><strong>{{'COMPANY_ADD' | translate}}</strong></p>
        <button type="button" class="close" (click)="closeCompanyCreateDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form-horizontal bordered-row" (ngSubmit)="f.form.valid && createCompany()" #f="ngForm" novalidate>

        <!--region CompanyName-->
        <div class="form-group row" [ngClass]="{ 'has-danger': hasLocalFieldError(name) }">
          <label class="col-form-label form-control-label col-md-4 detail-title required-field-label">
            {{'COMMON_COMPANY_NAME' | translate}}
          </label>
          <div class="col-md-8">
            <input
                   type="text" class="form-control"
                   maxlength="{{UiConstants.maximumVarcharLength}}"
                   placeholder="{{'COMMON_COMPANY_NAME' | translate}}"
                   [(ngModel)]="editModel.name"
                   (ngModelChange)="removeFieldError(Company.ValidatedField.NAME)"
                   [ngClass]="{ 'form-control-danger': hasLocalFieldError(name) }"
                   name="name"
                   id="name"
                   #name="ngModel"
                   required>
            <div class="form-control-feedback"
                 *ngIf="hasLocalFieldError(name)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
            </div>
          </div>
        </div>
        <!--endregion CompanyName-->

        <!--region ExternalId-->
        <div class="form-group row"
             [ngClass]="{ 'has-danger': hasLocalFieldError(externalId) || fieldErrors.external_id}">
          <label class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_EXTERNAL_ID' |
            translate}}</label>
          <div class="col-md-8">
            <input
                   type="text" class="form-control"
                   maxlength="{{UiConstants.maximumVarcharLength}}"
                   placeholder="{{'COMMON_EXTERNAL_ID' | translate}}"
                   [(ngModel)]="editModel.externalId"
                   (ngModelChange)="removeFieldError(fieldErrors.external_id)"
                   [ngClass]="{ 'form-control-danger': hasLocalFieldError(externalId) || fieldErrors.external_id}"
                   name="externalId"
                   id="externalId"
                   #externalId="ngModel">
            <div class="form-control-feedback"
                 *ngIf="fieldErrors.external_id">{{fieldErrors.external_id.text}}</div>
            <div class="form-control-feedback"
                 *ngIf="hasLocalFieldError(externalId)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
          </div>
        </div>
        <!--endregion ExternalId-->

        <!--region Type-->
        <div class="form-group row" [ngClass]="{ 'has-danger': f.submitted && editModel.type.id === 'UNKNOWN'}">
          <label class="col-form-label form-control-label col-md-4 detail-title required-field-label">
            {{'COMPANY_TYPE' | translate}}
          </label>
          <div class="col-md-8">
            <select
              class="form-control"
              [(ngModel)]="editModel.type" [compareWith]="SelectUtils.compareObjects"
              (ngModelChange)="removeFieldError(Company.ValidatedField.TYPE)"
              [ngClass]="{ 'form-control-danger': f.submitted && editModel.type.id === 'UNKNOWN'}"
              name="type"
              id="type"
              #type="ngModel">
              <option *ngFor="let item of typeModels"
                      [ngValue]="item">{{ 'COMPANY_TYPE_' + item.text | translate }} </option>
            </select>
            <div class="form-control-feedback"
                 *ngIf="f.submitted && editModel.type.id === 'UNKNOWN'">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
          </div>
        </div>
        <!--endregion Type-->

        <!--region TaxNumber-->
        <div class="form-group row"
             [ngClass]="{ 'has-danger': fieldErrors.tax_number || hasLocalFieldError(taxNumber) }">
          <label class="col-form-label form-control-label col-md-4 detail-title">{{'COMPANY_TAX_NUMBER' |
            translate}}</label>
          <div class="col-md-8">
            <input type="text" class="form-control"
                   placeholder="{{'COMPANY_TAX_NUMBER' | translate}}"
                   [(ngModel)]="editModel.taxNumber.raw"
                   (ngModelChange)="removeFieldError(fieldErrors.tax_number)"
                   [ngClass]="{ 'form-control-danger': fieldErrors.tax_number || hasLocalFieldError(taxNumber) }"
                   name="taxNumber"
                   id="taxNumber"
                   [textMask]="{mask: InputMask.HU_TAX_NUMBER, guide: true}"
                   #taxNumber="ngModel">
            <div class="form-control-feedback" *ngIf="fieldErrors.tax_number">{{fieldErrors.tax_number.text}}</div>
          </div>
        </div>
        <!--endregion TaxNumber-->

        <!--region EuTaxNumber-->
        <div class="form-group row">
          <label class="col-form-label form-control-label col-md-4 detail-title">{{'COMPANY_EU_TAX_NUMBER' |
            translate}}</label>
          <div class="col-md-8">
            <input type="text" class="form-control"
                   placeholder="{{'INVOICE_SETTINGS_EU_TAX_NUMBER' | translate}}"
                   [(ngModel)]="editModel.euTaxNumber.raw"
                   name="euVatNumber"
                   id="euVatNumber"
                   #euVatNumber="ngModel">
          </div>
        </div>
        <!--endregion EuTaxNumber-->

        <!-- region Roles -->
        <div class="form-group row">
          <label
            class="col-form-label form-control-label col-md-4 detail-title">{{'COMPANY_ALLOWED_ROLES'|translate}}</label>
          <div class="col-md-8">
            <angular2-multiselect
              [(ngModel)]="selectedRoles"
              [ngModelOptions]="{standalone: true}"
              [data]="roles"
              [settings]="dropdownSettings">
            </angular2-multiselect>
          </div>
        </div>
        <!-- endregion Roles -->

        <!-- region Postal address -->
        <div *ngIf="editModel.postalAddress"
             class="form-group row">
          <label
            class="col-form-label form-control-label col-md-4 detail-title required-field-label">
            {{'COMMON_LOCATION_POSTAL_ADDRESS'|translate}}
          </label>
          <div class="col-md-8">
            <app-postal-address-complex
              [form]="f"
              [model]="editModel.postalAddress">
            </app-postal-address-complex>
          </div>
        </div>
        <!-- endregion Postal address -->
        <div class="col-md-12 d-flex justify-content-end p-0 pl-1">
          <div>
            <button type="button" class="btn btn-outline-primary" (click)="closeCompanyCreateDialog()">
              {{'COMMON_BUTTON_CANCEL' | translate}}
            </button>
            <button type="submit" class="btn btn-primary ml-1">{{'COMMON_BUTTON_SAVE' |
              translate}}
            </button>
          </div>
        </div>
      </form>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
