/* eslint-disable */
import {Component,} from '@angular/core';
import {
  FormRecordFieldContext,
  FormRecordFieldValueUpdateArgs,
  FormRecordFieldView,
  FormRecordFieldViewContext,
  FormRecordFieldViewModel,
  SetTmpReadonlyArgs,
  SetTmpReadonlyResult
} from '../../../../../util/form/form-utils';
import {Form} from '../../../../../lib/form/form.service';
import {Command} from '../../../../../util/command';
import {FormRecord} from '../../../../../lib/form/form-record.service';
import {StockService} from '../../../../../lib/stock/stock.service';
import {Decimal} from 'decimal.js';
import {InputMask} from '../../../../../util/input-masks';
import {FormRef, LocalFormGroupValidationErrors,} from '../../../../../lib/util/services';
import {FieldActivationState, FieldActivationStateResolver} from '../../../../../util/form/form-editors';
import {List} from 'immutable';
import {SelectUtils} from '../../../../../util/core-utils';
import {TranslateService} from '@ngx-translate/core';
import {ToasterService} from '../../../../../fork/angular2-toaster/angular2-toaster';
import {FormRecordInactivityManager} from '../../manager/form-record-inactivity-manager';
import {BadgeStyle} from '../../../../../shared/table-badge/badge-style';
import {MatDialog} from '@angular/material/dialog';
import {
  MatConfirmDialogComponent,
  MatConfirmDialogData
} from '../../../../../shared/mat-confirm-dialog/mat-confirm-dialog.component';
import {AlertType} from '../../../../../shared/confirm-dialog/confirm-dialog.component';
import {forkJoin, Observable} from 'rxjs';
import {StockTypeName} from '../../../../../util/stock/stock-utils';
import {Models} from '../../../../../util/model-utils';
import {Arrays} from '../../../../../lib/util/arrays';
import {StockRecordFacade, StockRecordService} from '../../../../../lib/stock/stock-record.service';
import {StockMoveSelectorUtils} from '../../../../../util/stock/stock-move-selector-utils';
import {StockItemUtils} from '../../../../../util/stock/stock-item-utils';
import {FormRecordFieldUpdateCustomerLocationArgs} from '../master-data/form-record-master-data-field.component';
import {StockItemUnitOfMeasure} from '../../../../../lib/stock/stock-item-unit-of-measure';
import {
  StockItemUnitOfMeasureMultiselectOptionItem,
  StockItemUnitOfMeasureMultiselectProvider
} from '../../../../../lib/stock/stock-item-unit-of-measure-multiselect.provider';
import {Angular2Multiselects} from '../../../../../util/multiselect';
import {Weight, WeightFactory} from "../../../../../util/weight-utils";

/* eslint-enable */

@Component({
  selector: 'app-form-record-stock-move-field',
  templateUrl: 'form-record-stock-move-field.component.html',
  styleUrls: ['form-record-stock-move-field.component.scss'],
})
export class FormRecordStockMoveFieldComponent implements FormRecordFieldView {

  public readonly selector: Form.FieldDataTypeSelector.STOCK_MOVE;

  SelectUtils = SelectUtils;
  InputMask = InputMask;
  BadgeStyle = BadgeStyle;
  StockItemUtils = StockItemUtils;

  formRecordFieldContext?: FormRecordFieldContext;
  formRecordInactivityManager?: FormRecordInactivityManager;

  model: Model = new Model();
  excludedStockIds: ExcludedStockIdCollection = new ExcludedStockIdCollection();
  excludedItemIds: ExcludedItemIdCollection = new ExcludedItemIdCollection();
  usableCategoryIds: number[] = [];
  sourceStockTypeName?: StockTypeName;
  destinationStockTypeName?: StockTypeName;
  forceSourceStockIds?: number[];
  forceDestinationStockIds?: number[];
  hasDisabledItem: boolean = false;
  customerRecordId: number | undefined;
  contactLocationId: number | undefined;

  dropdownSettings: Angular2Multiselects.Settings = Angular2Multiselects.LOCAL_SINGLE_SELECT;

  private fieldId?: number;
  htmlForm?: FormRef;
  tmpReadonly: boolean = false;
  optionalValue: boolean = false;
  private formGroupValidationErrors: LocalFormGroupValidationErrors;

  private readonlyFormFn: () => boolean = () => true;
  private readonlyFieldFn: () => boolean = () => false;
  private hiddenFieldFn: () => boolean = () => false;

  get nonEditable(): boolean {
    return FieldActivationStateResolver.isNonEditable(
      this.fieldActivationState
    );
  }

  get requiredDisabled(): boolean {
    return FieldActivationStateResolver.isRequiredDisabled(
      this.fieldActivationState
    );
  }

  get required(): boolean {
    const optional = this.optionalValue;
    const requiredDisabled = this.requiredDisabled;
    return !optional && !requiredDisabled;
  }


  private get readonlyForm(): boolean {
    return this.readonlyFormFn();
  }

  private get fieldActivationState(): FieldActivationState {
    return FieldActivationStateResolver.resolveFieldActivationState({
      readonlyFormFn: () => this.readonlyFormFn(),
      readonlyFieldFn: () => this.readonlyFieldFn(),
      tmpReadonlyFieldFn: () => this.tmpReadonly,
    });
  }


  private get reqContext() {
    return this.formRecordFieldContext!;
  }

  setTmpReadonly(args: SetTmpReadonlyArgs): Command<SetTmpReadonlyResult> {
    const previousTmpReadonly = this.tmpReadonly;
    const previousValue = this.model;
    return {
      execute: async () => {
        let changed = false;
        if (this.tmpReadonly !== args.tmpReadonly) {
          if (args.tmpReadonly) {
            changed = FieldActivationStateResolver.inactivationChangesTheValue({
              debugId: this.reqContext.field.title,
              valueIsEmpty: !this.model.movements || this.model.movements.length === 0,
              valueEqualsDefaultValue: !this.model.movements || this.model.movements.length === 0,
              defaultValueIsEmpty: true,
              canApplyDefaultValue: false
            });
            this.model = new Model();
            this.tmpReadonly = args.tmpReadonly; // last
          } else {
            this.tmpReadonly = args.tmpReadonly; // first
            this.model = new Model();
          }
        }
        return {
          changed: changed
        };
      },
      undo: async () => {
        this.tmpReadonly = previousTmpReadonly;
        this.model = previousValue;
      }
    };
  }

  registerField(context: FormRecordFieldContext, originalModel?: any): any {
    if (originalModel) {
      this.model = originalModel;
    }
    this.formRecordFieldContext = context;
    this.fieldId = context.field.fieldId;
    this.htmlForm = context.htmlForm;
    this.readonlyFormFn = context.readonly;
    this.hiddenFieldFn = () => Form.FormFieldValidationType.HIDDEN === context.validationType;
    this.readonlyFieldFn = () => Form.FormFieldValidationType.READONLY === context.validationType
      || Form.FormFieldValidationType.HIDDEN === context.validationType;
    this.optionalValue = Form.FormFieldValidationType.REQUIRED !== context.validationType;
    if (context.field) {
      const stockMoveAttributes = context.field.dataType.stockMoveAttributes;
      if (stockMoveAttributes) {
        this.usableCategoryIds = stockMoveAttributes.usableStockItemCategories;
        this.sourceStockTypeName = stockMoveAttributes.sourceStockType;
        this.destinationStockTypeName = stockMoveAttributes.destinationStockType;
        this.forceSourceStockIds = stockMoveAttributes.stockSourceFilters.length > 0
          ? stockMoveAttributes.stockSourceFilters
          : undefined;
        this.forceDestinationStockIds = stockMoveAttributes.stockDestinationFilters.length > 0
          ? stockMoveAttributes.stockDestinationFilters : undefined;
      }
    }
    if (context.fieldRecord) {
      this.registerFieldData(context.fieldRecord);
    } else {
      this.model.loaded = true;
    }
    return this.model;
  }

  registerFieldData(fieldRecord: FormRecord.FieldComposed): void {
    const attrs = fieldRecord.data.stockMoveAttributes!;
    this.loadItems(attrs);
  }

  loadItems(attrs: FormRecord.FieldDataStockMoveAttributes) {
    if (attrs.values.size > 0) {
      const destinationStockIds: number[] = [];
      attrs.values.forEach((item: FormRecord.StockMoveItem) => {
        this.excludedStockIds.add(item.sourceStockId, item.destinationStockId);
        this.excludedItemIds.add(new MovementId(item.sourceStockId, item.destinationStockId), item.stockItemId);
        destinationStockIds.push(item.destinationStockId);
      });
      const queries: Observable<any>[] = [];
      queries.push(this.stockService.query({
        id: destinationStockIds.join()
      }));
      this.excludedItemIds.keys.forEach(movementId => {
        queries.push(this.stockRecordService.facadeQuery({
          stock_ids: movementId.sourceStockId + '',
          stock_item_ids: this.excludedItemIds.get(movementId)!.join()
        }));
      });
      forkJoin(queries).subscribe(result => {
        const stocks: StockModel[] = [];
        const stockRecords: Map<string, StockRecordFacade[]> = new Map<string, StockRecordFacade[]>();
        stocks.push(...result.splice(0, 1)[0].items.map(stock => ({
          id: stock.id,
          name: stock.name,
          disabled: stock.disabled
        })));
        result.forEach((recordResult, index) => {
          const records = recordResult.items;
          stocks.push({
            id: records[0].stock.stock_id,
            name: records[0].stock.name,
            disabled: records[0].stock.disabled
          });
          stockRecords.set(this.excludedItemIds.keys[index].stringify(), records);
        });
        attrs.values.forEach((item: FormRecord.StockMoveItem) => {
          const movement = this.getMovementByIds(item.sourceStockId, item.destinationStockId, stocks)!;
          movement.stockItems.push(this.createStockItemModel(item, stockRecords.get(movement.id.stringify())!)!);
        });
        this.refreshHasDisabled();
        this.model.loaded = true;
      });
    } else {
      this.model.loaded = true;
    }
  }

  private getMovementByIds(
    sourceId: number,
    destinationId: number,
    stocks?: StockModel[]): MovementModel | undefined {
    const movement = this.model.movements.find(movement =>
      movement.id.sourceStockId === sourceId && movement.id.destinationStockId === destinationId);
    if (movement) {
      return movement;
    }
    if (stocks) {
      const sourceStock = stocks.find(s => s.id === sourceId);
      const destinationStock = stocks.find(s => s.id === destinationId);
      if (sourceStock && destinationStock) {
        const mv = new MovementModel();
        mv.id = new MovementId(sourceId, destinationId);
        mv.sourceStock = sourceStock;
        mv.destinationStock = destinationStock;
        mv.stockItems = [];
        this.model.movements.push(mv);
        return this.model.movements[this.model.movements.length - 1];
      }
    }
  }

  private createStockItemModel(item: FormRecord.StockMoveItem, records: StockRecordFacade[]): StockItemModel | undefined {
    const record = records.find(r => r.stock_item.stock_item_id === item.stockItemId);
    if (record) {
      const model = new StockItemModel();
      model.id = record.stock_item.stock_item_id;
      model.name = record.stock_item.name;
      model.productCode = record.stock_item.product_code!;
      model.weightInGrams = record.stock_item.weight_in_grams;
      model.packageData = record.stock_item.package_data;
      model.measurements = this.unitOfMeasureMultiselectProvider.toMultiselectOptionItems(record.stock_item.measurements);
      model.inStockAmount = record.amount;
      model.numberAmount = Models.decimalToNumber(item.amount);
      model.unitOfMeasure = model.measurements.filter(m => m.id === item.unitOfMeasureId);
      model.disabled = record.stock_item.disabled;
      model.baseUnit = record.stock_item.unit;
      return model;
    }
  }

  private refreshHasDisabled() {
    this.hasDisabledItem = false;
    this.model.movements.forEach((movement) => {
      if (movement.sourceStock.disabled || movement.destinationStock.disabled) {
        this.hasDisabledItem = true;
      }
      movement.stockItems.forEach(i => {
        if (i.disabled) {
          this.hasDisabledItem = true;
        }
      });
    });
  }

  removeMovement(id: MovementId, auto?: boolean) {
    if (!auto) {
      const dialogConfig: MatConfirmDialogData = {
        titleKey: 'CONFIRM_DIALOG_TITLE_DELETE',
        messageKey: 'CONFIRM_DIALOG_MESSAGE_DELETE',
        alertType: AlertType.DANGER
      };
      const dialogRef = this.dialog.open(MatConfirmDialogComponent, {
        data: dialogConfig
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.model.movements.splice(this.model.movements.findIndex(s => s.id === id), 1);
          this.excludedStockIds.remove(id.sourceStockId, id.destinationStockId);
          this.excludedItemIds.remove(id);
          this.refreshHasDisabled();
        }
      });
    } else {
      this.model.movements.splice(this.model.movements.findIndex(s => s.id === id), 1);
      this.excludedStockIds.remove(id.sourceStockId, id.destinationStockId);
      this.excludedItemIds.remove(id);
      this.refreshHasDisabled();
    }
  }

  removeItem(movementId: MovementId, id: number) {
    const dialogConfig: MatConfirmDialogData = {
      titleKey: 'CONFIRM_DIALOG_TITLE_DELETE',
      messageKey: 'CONFIRM_DIALOG_MESSAGE_DELETE',
      alertType: AlertType.DANGER
    };
    const dialogRef = this.dialog.open(MatConfirmDialogComponent, {
      data: dialogConfig
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const stockItems = this.model.movements.find(m => m.id === movementId)!.stockItems;
        if (stockItems.length === 1) {
          this.removeMovement(movementId, true);
        } else {
          stockItems.splice(stockItems.findIndex(i => i.id === id), 1);
          this.excludedItemIds.remove(movementId, id);
        }
        this.refreshHasDisabled();
      }
    });
  }

  registerFieldViews(context: FormRecordFieldViewContext): void {
    this.formRecordInactivityManager = context.inactivityManager;
  }

  hasLocalFieldError(): boolean {
    if (this.nonEditable) {
      return false;
    }
      let validAmounts = true;
    if (this.model.movements) {
      this.model.movements.forEach((value) => {
        value.stockItems.forEach(i => {
          if (i.baseAmount && i.baseAmount > i.inStockAmount) {
            validAmounts = false;
          }
        });
      });
    }

    this.refreshHasDisabled();
    return !validAmounts || this.hasDisabledItem;
  }

  validateWithInterrupt(): boolean {
    if (this.nonEditable) {
      return false;
    }
    for (const movement of this.model.movements) {
      for (const item of movement.stockItems) {
        if (!item.baseAmount || item.baseAmount <= 0 || item.baseAmount > item.inStockAmount) {
          return true;
        }
      }
    }
    return false;
  }

  shouldNotifyAfterCreation(): boolean {
    return false;
  }

  afterFormRecordCreation(formRecordId: number): Observable<any> | undefined {
    return undefined;
  }

  createModel(): FormRecordFieldViewModel {
    if (this.fieldId === undefined) {
      throw new Error('Field ID is undefined');
    }
    let attrs: FormRecord.FieldDataStockMoveAttributes;
    if (!this.model.loaded && this.formRecordFieldContext?.fieldRecord?.data) {
      attrs = this.formRecordFieldContext.fieldRecord.data.stockMoveAttributes!;
    } else {
      attrs = {
        values: List.of(...Arrays.flatten(this.model.movements!.map(m => {
          return m.stockItems.map(i => {
            return {
              sourceStockId: m.id.sourceStockId,
              destinationStockId: m.id.destinationStockId,
              stockItemId: i.id,
              amount: i.amount!,
              unitOfMeasureId: i.unitOfMeasureId
            };
          });
        })))
      };
    }
    return {
      fieldEditRequest: {
        fieldId: this.fieldId,
        data: {
          stockMoveAttributes: attrs
        }
      }
    };
  }

  startStockMove(movementId?: MovementId) {
    StockMoveSelectorUtils.startMoveSelector({
      dialog: this.dialog,
      usableCategoryIds: this.usableCategoryIds,
      sourceStockTypeName: this.sourceStockTypeName,
      destinationStockTypeName: this.destinationStockTypeName,
      forceSourceStockIds: this.forceSourceStockIds,
      forceDestinationStockIds: this.forceDestinationStockIds,
      ownerCustomerRecordId: this.customerRecordId,
      ownerContactLocationId: this.contactLocationId,
      excludedStockItemIds: movementId ? this.excludedItemIds.get(movementId) : [],
      excludedDestinationStockIds: this.excludedStockIds,
      movementId: movementId,
      resultCallback: (result) => this.onStockMoveSelectorResult(result)
    });
  }

  private onStockMoveSelectorResult(result: StockMoveSelectorUtils.StockMoveSelectorResult) {
    if ((result.selectedStockItems && result.selectedStockItems.length > 0)
      || (result.selectedStockItemIds && result.selectedStockItemIds.length > 0)) {
      if (result.movementId) {
        const movement = this.model.movements.find(m => m.id === result.movementId)!;
        this.loadStockItemSelectorResult(movement, result);
      } else {
        this.excludedStockIds.add(result.selectedSourceStock!.id, result.selectedDestinationStock!.id);
        const mv = new MovementModel();
        mv.id = new MovementId(result.selectedSourceStock!.id, result.selectedDestinationStock!.id);
        mv.sourceStock = {
          id: result.selectedSourceStock!.id,
          name: result.selectedSourceStock!.itemName,
          disabled: false
        };
        mv.destinationStock = {
          id: result.selectedDestinationStock!.id,
          name: result.selectedDestinationStock!.itemName,
          disabled: false
        };
        mv.stockItems = [];
        this.model.movements.push(mv);
        this.loadStockItemSelectorResult(mv, result);
      }
    }
  }

  private loadStockItemSelectorResult(movement: MovementModel, result: StockMoveSelectorUtils.StockMoveSelectorResult) {
    if (result.selectedStockItems) {
      result.selectedStockItems.forEach(i => {
        this.excludedItemIds.add(movement.id, i.id);
        const model = new StockItemModel();
        model.id = i.id;
        model.name = i.name;
        model.productCode = i.productCode;
        model.weightInGrams = i.weightInGrams;
        model.packageData = i.packageData;
        model.inStockAmount = i.inStockAmount;
        model.numberAmount = i.amount;
        model.disabled = false;
        model.baseUnit = i.baseUnit;
        model.measurements = i.measurements;
        model.unitOfMeasure = i.unitOfMeasure;
        movement.stockItems.push(model);
      });
    } else {
      result.selectedStockItemIds!.forEach(id => {
        this.excludedItemIds.add(movement.id, id);
      });
      this.stockRecordService.facadeQuery({
        stock_ids: movement.id.sourceStockId + '',
        stock_item_ids: result.selectedStockItemIds!.join()
      }).subscribe(items => {
        items.items.forEach(i => {
          const model = new StockItemModel();
          model.id = i.stock_item.stock_item_id;
          model.name = i.stock_item.name;
          model.productCode = i.stock_item.product_code!;
          model.weightInGrams = i.stock_item.weight_in_grams;
          model.packageData = i.stock_item.package_data;
          model.measurements = this.unitOfMeasureMultiselectProvider.toMultiselectOptionItems(i.stock_item.measurements);
          model.inStockAmount = i.amount;
          model.numberAmount = undefined;
          model.unitOfMeasure = model.measurements.filter(m => m.baseUnit);
          model.disabled = i.stock_item.disabled;
          model.baseUnit = i.stock_item.unit;
          movement.stockItems.push(model);
        });
      });
    }
  }

  getTitle(): string {
    if (this.formRecordFieldContext && this.formRecordFieldContext.field) {
      return this.formRecordFieldContext.field.title;
    } else {
      this.translateService.get('FORM_RECORD_STOCK_MOVE_TITLE').subscribe(
        (result: string) => {
          return result;
        }
      );
    }
    return '';
  }

  constructor(private stockService: StockService,
              private stockRecordService: StockRecordService,
              private dialog: MatDialog,
              private toasterService: ToasterService,
              private unitOfMeasureMultiselectProvider: StockItemUnitOfMeasureMultiselectProvider,
              private translateService: TranslateService) {
  }

  updateValue(data: FormRecordFieldValueUpdateArgs) {
    if (data instanceof FormRecordFieldUpdateCustomerLocationArgs) {
      if ((<FormRecordFieldUpdateCustomerLocationArgs>data).customerRecordId !== null) {
        this.customerRecordId = (<FormRecordFieldUpdateCustomerLocationArgs>data).customerRecordId!;
      }
      this.contactLocationId = (<FormRecordFieldUpdateCustomerLocationArgs>data).contactLocationId;
    }
  }

}

export class Model {
  movements: MovementModel[] = [];
  loaded: boolean = false;
}

export class MovementId {
  constructor(public sourceStockId: number, public destinationStockId: number) {
  }

  stringify(): string {
    return JSON.stringify(this);
  }

  static parse(source: string): MovementId {
    const rawObject = JSON.parse(source);
    return new MovementId(rawObject.sourceStockId, rawObject.destinationStockId);
  }
}

export class MovementModel {
  id: MovementId;
  sourceStock: StockModel;
  destinationStock: StockModel;
  stockItems: StockItemModel[];

  get sumWeight(): string {
    if (this.stockItems.length === 0) {
      return '';
    }
    let weight = 0;
    this.stockItems.forEach(si => {
      const w = si.sumWeightInGrams;
      if (w) {
        weight += w;
      }
    });
    if (weight === 0) {
      return '';
    }
    return WeightFactory.createWeightFromGram(weight, 'kg').toString();
  }
}

export interface StockModel {
  id: number;
  name: string;
  disabled: boolean;
}

export class StockItemModel {
  id: number;
  name: string;
  productCode: string;
  packageData?: StockItemUnitOfMeasure.StockItemUnitOfMeasure;
  measurements: StockItemUnitOfMeasureMultiselectOptionItem[] = [];
  unitOfMeasure: StockItemUnitOfMeasureMultiselectOptionItem[] = [];
  inStockAmount: number = 0;
  amount?: Decimal = undefined;
  disabled: boolean = false;
  baseUnit: string;

  private unitWeight?: Weight;
  // this is a helper field for UI, because number picker does not handle decimal.js very well
  private _numberAmount: number | undefined = undefined;

  get numberAmount(): number | undefined {
    return this.amount ? new Decimal(this.amount).toNumber() : undefined;
  }

  set numberAmount(value: number | undefined) {
    this.amount = value ? new Decimal(value) : undefined;
    this._numberAmount = value;
  }


  get unitOfMeasureId(): number {
    return this.selectedUnitOfMeasure?.id!;
  }

  get selectedUnitOfMeasure(): StockItemUnitOfMeasureMultiselectOptionItem | undefined {
    return this.unitOfMeasure.length > 0 ? this.unitOfMeasure[0] : undefined;
  }

  isBaseUnitSelected(): boolean {
    if (!this.selectedUnitOfMeasure) {
      return true;
    }
    return this.selectedUnitOfMeasure.baseUnit;
  }

  get baseAmount(): number | undefined {
    if (!this.numberAmount || !this.selectedUnitOfMeasure) {
      return undefined;
    }
    return this.numberAmount * this.selectedUnitOfMeasure.conversion;
  }

  get baseAmountDecimal(): Decimal | undefined {
    if (!this.baseAmount) {
      return undefined;
    }
    return new Decimal(this.baseAmount);
  }

  set weightInGrams(value: number | undefined) {
    if (!value) {
      return;
    }
    this.unitWeight = WeightFactory.createWeightFromGram(value, 'kg');
  }

  get weightInGrams(): number | undefined {
    if (!this.unitWeight) {
      return undefined;
    }
    return this.unitWeight.toGrams();
  }

  get formattedWeight(): string {
    if (!this.unitWeight) {
      return '';
    }
    return this.unitWeight.toString();
  }

  get sumWeightInGrams(): number {
    if (!this.unitWeight || !this.baseAmount) {
      return 0;
    }
    return this.unitWeight.toGrams() * this.baseAmount;
  }

  get sumWeightFormatted(): string {
    if (!this.unitWeight || !this.baseAmount) {
      return '';
    }
    return WeightFactory.createWeightFromGram(this.unitWeight.toGrams() * this.baseAmount, 'kg').toString();
  }

}

class ExcludedItemIdCollection {
  // <{Movement id}, {Stock item id}>
  private readonly ids: Map<string, number[]> = new Map();

  get keys(): MovementId[] {
    return Array.from(this.ids.keys()).map(s => MovementId.parse(s));
  }

  get(id: MovementId): number[] {
    if (this.ids.has(id.stringify())) {
      return this.ids.get(id.stringify())!;
    } else {
      throw new Error('Invalid movement id');
    }
  }

  add(id: MovementId, itemId: number) {
    if (this.ids.has(id.stringify())) {
      this.ids.get(id.stringify())!.push(itemId);
    } else {
      this.ids.set(id.stringify(), [itemId]);
    }
  }

  remove(id: MovementId, itemId?: number) {
    if (this.ids.has(id.stringify())) {
      if (itemId) {
        const array = this.ids.get(id.stringify())!;
        const index = array.findIndex(id => id === itemId);
        array.splice(index, 1);
      } else {
        this.ids.delete(id.stringify());
      }
    } else {
      throw new Error('Invalid movement id');
    }
  }
}

export class ExcludedStockIdCollection {
  // <{Source stock id}, {Destination stock id}>
  private ids: Map<number, Set<number>> = new Map();

  get(sourceStockId): Set<number> {
    if (this.ids.has(sourceStockId)) {
      return this.ids.get(sourceStockId)!;
    } else {
      throw new Error('Invalid source stock id');
    }
  }

  add(sourceStockId: number, destinationStockId: number) {
    if (this.ids.has(sourceStockId)) {
      this.ids.get(sourceStockId)!.add(destinationStockId);
    } else {
      this.ids.set(sourceStockId, new Set([sourceStockId, destinationStockId]));
    }
  }

  remove(sourceStockId: number, destinationStockId: number) {
    if (this.ids.has(sourceStockId)) {
      this.ids.get(sourceStockId)!.delete(destinationStockId);
    } else {
      throw new Error('Invalid source stock id');
    }
  }

  has(sourceStockId: number): boolean {
    return this.ids.has(sourceStockId);
  }
}
