<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]=breadcrumbParents
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>

<div class="container-horizontal-padding-half container-vertical-padding-half animated fadeIn">

  <div class="card">

    <div class="card-header">
      {{'COMMON_CALENDAR' | translate}}

      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()"
           title="{{'COMMON_SHOW_SEARCH_HINT' | translate}}"><i class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>

    <div class="card-body border-bottom" *ngIf="showSearch">

      <div class="row form-group">
        <div class="col">
          <label class="search-label">{{'CALENDAR_SEARCH_USER_GROUPS'|translate}}</label>
          <angular2-multiselect
            [(ngModel)]="searchModel.userGroups"
            [ngModelOptions]="{standalone: true}"
            [data]="userGroups"
            (onRemoteSearch)="loadUserGroups($event.target.value)"
            [settings]="assigneeSearchDropdownSettings">
          </angular2-multiselect>
        </div>
        <div class="col" *ngIf="config.assigneeWithUser">
          <label class="search-label">{{'COMMON_USERS'|translate}}</label>
          <angular2-multiselect
            [(ngModel)]="searchModel.users"
            [ngModelOptions]="{standalone: true}"
            [data]="users"
            (onRemoteSearch)="loadUsers($event.target.value)"
            [settings]="assigneeSearchDropdownSettings">
          </angular2-multiselect>
        </div>
        <div class="col" *ngIf="config.assigneeWithMobileApp">
          <label class="search-label">{{'COMMON_DEVICES'|translate}}</label>
          <angular2-multiselect
            [(ngModel)]="searchModel.devices"
            [ngModelOptions]="{standalone: true}"
            [data]="devices"
            (onRemoteSearch)="loadDevices($event.target.value)"
            [settings]="assigneeSearchDropdownSettings">
          </angular2-multiselect>
        </div>
        <div class="col">
          <label class="search-label">{{'CALENDAR_SEARCH_TASKS' | translate}}</label>
          <angular2-multiselect
            [(ngModel)]="searchModel.tasks"
            [ngModelOptions]="{standalone: true}"
            [data]="tasks"
            [settings]="localDropdownSettings">
          </angular2-multiselect>
        </div>
        <div class="col">
          <label class="search-label">{{'TASK_RECORD_SEARCH_STATE'|translate}}</label>
          <angular2-multiselect
            [(ngModel)]="searchModel.taskRecordStates"
            [ngModelOptions]="{standalone: true}"
            [data]="taskStates"
            [settings]="stateDropdownSettings">
          </angular2-multiselect>
        </div>
        <div class="col">
          <label class="search-label">{{'CALENDAR_SEARCH_AVAILABLE_TIME' | translate}}</label>
          <app-animated-checkbox class="available-time-checkbox"
                                 [(selected)]="searchModel.availableTime">
          </app-animated-checkbox>
        </div>
      </div>
      <div class="col-md-12 d-flex justify-content-md-end align-items-end px-0">
        <div class="btn-group" role="group">
          <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
            {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
          </button>
          <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
            {{'COMMON_BUTTON_SEARCH' | translate}}
          </button>
        </div>
      </div>

    </div> <!-- ./card-body end -->

    <div class="container-vertical-padding-half"></div>


    <div class="container-vertical-padding-half container-horizontal-padding-half">
      <!--CALENDAR HEADER-->
      <div class="row">
        <div class="col-md-4">
          <div class="btn-group">
            <div
              class="btn btn-secondary-green-gray p-0 d-flex align-items-center"
              (click)="showPreviousView()">
              <i class="icomoon icomoon-greater-than arrow-icon"></i>
            </div>
            <div
              class="btn btn-secondary-green-gray"
              (click)="showTodayView()">
              {{'CALENDAR_TODAY' | translate}}
            </div>
            <div
              class="btn btn-secondary-green-gray p-0 d-flex align-items-center"
              (click)="showNextView()">
              <i class="icomoon icomoon-smaller-than arrow-icon"></i>
            </div>
          </div>
        </div>
        <div class="col-md-4 text-center">
          <h3 *ngIf="view === CalendarView.Week || view === CustomCalendarView.Timeline">
            {{rangeStartDate | date:'MMMM d.'}} - {{rangeEndDate | date:'MMMM d.'}}
          </h3>
          <h3 *ngIf="view === CalendarView.Day">
            {{rangeStartDate | date:'MMMM d., EEEE'}}
          </h3>
        </div>
        <div class="col-md-4 text-right d-flex justify-content-end">
          <div class="btn-group">
            <div
              class="btn"
              (click)="switchToTimeline()"
              [class.btn-primary-green-gray]="view === CustomCalendarView.Timeline"
              [class.btn-secondary-green-gray]="view !== CustomCalendarView.Timeline">
              {{'CALENDAR_TIMELINE' | translate}}
            </div>
            <div
              class="btn"
              (click)="switchToDayView()"
              [class.btn-primary-green-gray]="view === CalendarView.Day"
              [class.btn-secondary-green-gray]="view !== CalendarView.Day">
              {{'CALENDAR_DAY_VIEW' | translate}}
            </div>
            <div
              class="btn"
              (click)="switchToWeekView()"
              [class.btn-primary-green-gray]="view === CalendarView.Week"
              [class.btn-secondary-green-gray]="view !== CalendarView.Week">
              {{'CALENDAR_WEEK_VIEW' | translate}}
            </div>
          </div>
          <div class="vertical-line"></div>
          <app-dropdown-button
            buttonIconClass="icomoon-settings">
            <app-dropdown-item [titleStringKey]="'CALENDAR_TURN_WEEKEND_OFF'"
                               [switchData]="this.searchModel.disableWeekend"
                               (switchDataChange)="daysInWeekChanged($event)"
                               [enableSwitch]="true"
            ></app-dropdown-item>
            <app-dropdown-item [titleStringKey]="'CALENDAR_ASSIGNEE_FILTER_USER'"
                               [(switchData)]="searchModel.isAssigneeUserFilled"
                               (switchDataChange)="onSearchClicked()"
                               [enableSwitch]="true"
            ></app-dropdown-item>
            <app-dropdown-item [titleStringKey]="'CALENDAR_ASSIGNEE_FILTER_USER_GROUP'"
                               [(switchData)]="searchModel.isAssigneeUserGroupFilled"
                               (switchDataChange)="onSearchClicked()"
                               [enableSwitch]="true"
            ></app-dropdown-item>
            <app-dropdown-item [titleStringKey]="'CALENDAR_ASSIGNEE_FILTER_EMPTY'"
                               [(switchData)]="searchModel.isAssigneeEmpty"
                               (switchDataChange)="onSearchClicked()"
                               [enableSwitch]="true"
            ></app-dropdown-item>
          </app-dropdown-button>
        </div>
      </div>
      <br>
      <!--CALENDAR HEADER END-->

      <!--CALENDAR-->
      <div [ngSwitch]="view">
        <div
          *ngSwitchCase="CustomCalendarView.Timeline">
          <app-calendar-timeline-view
            [viewDate]="viewDate"
            [events]="events"
            [locale]="locale"
            [refresh]="refresh"
            [weekStartsOn]="weekStartsOn"
            [daysInWeek]="searchModel.daysInWeek"
            [excludeDays]="excludeDays"
            (eventClicked)="onCalendarEventClicked($event)"
            (pageChange)="pageChanged($event)"
            (itemsPerPageChange)="itemsPerPageChanged($event)">
          </app-calendar-timeline-view>
        </div>
        <mwl-calendar-week-view
          *ngSwitchCase="CalendarView.Week"
          [viewDate]="viewDate"
          [events]="events"
          [locale]="locale"
          [refresh]="refresh"
          [weekStartsOn]="weekStartsOn"
          [dayStartHour]="dayStartHour"
          [dayEndHour]="dayEndHour"
          [excludeDays]="excludeDays"
          (dayHeaderClicked)="switchToDayView($event)"
          (eventClicked)="onCalendarEventClicked($event)"
          (hourSegmentClicked)="hourSegmentClicked($event)">
        </mwl-calendar-week-view>
        <div *ngSwitchCase="CalendarView.Day" class="day-container">
          <mwl-calendar-day-view
            [viewDate]="viewDate"
            [dayStartHour]="dayStartHour"
            [dayEndHour]="dayEndHour"
            [events]="events"
            [locale]="locale"
            [refresh]="refresh"
            [eventWidth]="225"
            (eventClicked)="onCalendarEventClicked($event)"
            (hourSegmentClicked)="hourSegmentClicked($event)">
          </mwl-calendar-day-view>
        </div>
      </div>
      <!--CALENDAR END-->

      <span *ngIf="tasksForCreate.length > 0 && this.rightModel.calendarEventCreate.hasRight()"
            (click)="createEventClicked()"
            class="btn floating-save-green-btn">
        + {{ 'CALENDAR_NEW_EVENT' | translate }}
      </span>
    </div>

  </div>
</div>
