/* eslint-disable */
import { environment } from '../../environments/environment';
import { Order, OrderType, } from './util/services';
import { Injectable } from '@angular/core';
import { UiConstants } from '../util/core-utils';
import { User } from './user.service';
import { CompanySearchItem, UserGroupSearchItem } from '../admin/user/user-list/user-list.component';
import { BaseSearch, BaseSearchService } from './base.search-service';
import { DisabledEnum } from '../util/search-utils';
import { RuntimeConfiguration } from './runtime-configuration';
import { UserTypeFilterOption } from '../util/user-utils';
import { LoggedInUserStorage } from './util/storages';

/* eslint-enable */

@Injectable()
export class UserSearchService extends BaseSearchService {

  protected createStorage(): UserSearch.Storage {
    return new UserSearch.Storage();
  }

}

export namespace UserSearch {

  export interface SearchData extends BaseSearch.SearchData {
    order: Order<User.OrderField>;
    id: string;
    user_name: string;
    person_name: string;
    external_id: string;
    email_address: string;
    companies: CompanySearchItem[];
    user_groups: UserGroupSearchItem[];
    isDriver: boolean;
    disabled: DisabledEnum;
    type: UserTypeFilterOption;
  }

  export interface SearchDataResult extends BaseSearch.SearchDataResult {
    searchData: SearchData;
  }

  interface StoredSearchRawData extends BaseSearch.StoredSearchRawData {
    order: Order<User.OrderField>;
    id: string;
    user_name: string;
    person_name: string;
    external_id: string;
    email_address: string;
    companies: CompanySearchItem[];
    user_groups: UserGroupSearchItem[];
    isDriver: boolean;
    disabled: DisabledEnum;
    type: UserTypeFilterOption;
  }

  export class Storage extends BaseSearch.Storage {

    constructor() {
      const defaultSearchData = {
        itemsPerPage: UiConstants.pageNumbers[0],
        pageNumber: 1,
        order: {
          field: User.OrderField.ID,
          type: OrderType.DESC
        },
        id: '',
        user_name: '',
        person_name: '',
        external_id: '',
        email_address: '',
        user_groups: [],
        companies: [],
        isDriver: false,
        disabled: DisabledEnum.FALSE,
        type: 'ALL'
      };
      /**
       * Increment this version if you change the SearchData interface.
       */
      const versionNumber: number = 10;
      super(defaultSearchData,
        versionNumber);
    }

    protected generateKey(): string {
      return 'UserId(' + LoggedInUserStorage.getInstance().getUserId() + ')-Search-User';
    }

    protected fromRaw(data: StoredSearchRawData): SearchDataResult {
      const searchData: SearchData = {
        itemsPerPage: data.itemsPerPage,
        pageNumber: data.pageNumber,
        order: data.order,
        id: data.id,
        user_name: data.user_name,
        person_name: data.person_name,
        external_id: data.external_id,
        email_address: data.email_address,
        companies: data.companies,
        isDriver: data.isDriver,
        disabled: data.disabled,
        type: data.type,
        user_groups: data.user_groups
      };
      return {
        searchData: searchData
      };
    }

    protected toRaw(data: SearchData): StoredSearchRawData {
      return {
        version: this.VERSION,
        itemsPerPage: data.itemsPerPage,
        pageNumber: data.pageNumber,
        order: data.order,
        id: data.id,
        user_name: data.user_name,
        person_name: data.person_name,
        external_id: data.external_id,
        email_address: data.email_address,
        companies: data.companies,
        isDriver: data.isDriver,
        disabled: data.disabled,
        type: data.type,
        user_groups: data.user_groups
      };
    }

  }

}
