/* eslint-disable */
import { EmptyMessage } from '../util/messages';
import { Injectable } from '@angular/core';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
/* eslint-enable */

@Injectable()
export class TableDocumentSchemaImportExportResourceService extends BaseHttpService {

  import(request: TableDocumentImportExportResource.ImportDocument): Observable<EmptyMessage> {
    return this.http.post(this.url, request);
  }

  export(request: TableDocumentImportExportResource.ExportRequest): Observable<TableDocumentImportExportResource.ImportDocument> {
    return this.http.get<TableDocumentImportExportResource.ImportDocument>(this.url + `${request.schema_id}`);
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/table-document/schema-descriptor/');
  }
}

export namespace TableDocumentImportExportResource {

  export interface ImportDocument {
    version: string;
    descriptor: string;
  }

  export interface ExportRequest {
    schema_id: number;
  }

}
