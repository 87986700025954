/* eslint-disable */
import { Observable, Observer } from 'rxjs';
import { RegistrationResourceService, RegistrationResource } from './registration-resource.service';
import { Injectable } from '@angular/core';
import { EmptyMessage } from '../util/messages';
import { EmailAddress } from '../util/email-address';
import { PhoneNumber } from '../util/phone-number';
import { Services } from '../util/services';
import { Address, AddressResource } from '../address';
import Mapper = AddressResource.Mapper;
/* eslint-enable */

@Injectable()
export class RegistrationService implements Registration.Service {

  constructor(private resourceService: RegistrationResourceService) {
  }

  requestRegistration(request: Registration.RegistrationRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: RegistrationResource.RegistrationRequest = {
        person_name: request.personName,
        email_address: Services.emailAddressToString(request.emailAddress)!,
        phone_number: Services.phoneNumberToString(request.phoneNumber),
        company: {
          name: request.company.name,
          address: Mapper.fromPublicPostalAddress(request.company.address),
        }
      };
      return this.resourceService.requestRegistration(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  registerUserShoprenter(request: Registration.RegistrationUserShoprenterRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: RegistrationResource.RegistrationUserShoprenterRequest = {
        person_name: request.personName,
        email_address: Services.emailAddressToString(request.emailAddress)!,
        phone_number: Services.phoneNumberToString(request.phoneNumber),
        company: {
          name: request.company.name,
          address: Mapper.fromPublicPostalAddress(request.company.address),
        },
        shoprenter_webshop: request.shoprenterWebshop,
        user:  {
          user_name: request.user.userName,
          password: request.user.password
        }
      };
      return this.resourceService.registerUserShoprenter(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  registerUserDemo(request: Registration.RegistrationUserDemoRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: RegistrationResource.RegistrationUserDemoRequest = {
        person_name: request.personName,
        email_address: Services.emailAddressToString(request.emailAddress)!,
        phone_number: Services.phoneNumberToString(request.phoneNumber),
        company: {
          name: request.company.name,
          address: Mapper.fromPublicPostalAddress(request.company.address),
        },
        user: {
          user_name: request.user.userName,
          password: request.user.password
        }
      };
      return this.resourceService.registerUserDemo(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  registerUserHelpdesk(request: Registration.RegistrationHelpdeskRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: RegistrationResource.RegistrationHelpdeskRequest = {
        person_name: request.personName,
        email_address: Services.emailAddressToString(request.emailAddress)!,
        phone_number: Services.phoneNumberToString(request.phoneNumber),
        user: {
          user_name: request.user.userName,
          password: request.user.password
        },
        helpdesk_token: request.helpdeskToken
      };
      return this.resourceService.registerUserHelpdesk(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }
}


export namespace Registration {

  export interface Service {

    requestRegistration(request: RegistrationRequest): Observable<EmptyMessage>;

    registerUserShoprenter(request: RegistrationUserShoprenterRequest): Observable<EmptyMessage>;

    registerUserDemo(request: RegistrationUserDemoRequest): Observable<EmptyMessage>;

    registerUserHelpdesk(request: RegistrationHelpdeskRequest): Observable<EmptyMessage>;

  }

  export interface RegistrationRequest {
    personName: string;
    emailAddress: EmailAddress;
    phoneNumber?: PhoneNumber;
    company: CompanyData;
  }

  export interface RegistrationUserShoprenterRequest extends RegistrationRequest {
    user: UserData;
    shoprenterWebshop: string;
  }

  export interface RegistrationUserDemoRequest extends RegistrationRequest {
    user: UserData;
  }

  export interface RegistrationHelpdeskRequest {
    personName: string;
    emailAddress: EmailAddress;
    phoneNumber?: PhoneNumber;
    user: UserData;
    helpdeskToken: string;
  }

  export interface CompanyData {
    name: string;
    address: Address.PostalAddressData;
  }

  export interface UserData {
    userName: string;
    password: string;
  }

}
