/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { QueryResult } from './util/services';
import { List } from 'immutable';
import { Decimal } from 'decimal.js';
import { Invoice } from './invoice/invoice/invoice.service';

/* eslint-enable */

@Injectable()
export class CurrencyService {

  query(request: Currency.QueryRequest): Observable<QueryResult<Currency.Currency>> {
    return Observable.create((observer: Observer<QueryResult<Currency.Currency>>) => {
      let l = List.of<Currency.Currency>({
          currencyCode: 'HUF',
          localizedName: 'Forint'
        }, {
          currencyCode: 'EUR',
          localizedName: 'Euro'
        }, {
          currencyCode: 'USD',
          localizedName: 'USD'
        }, {
          currencyCode: 'CHF',
          localizedName: 'CHF'
        }
      );

      if (request.currencyCode) {
        l = l.filter((cc) => (cc !== undefined && cc.currencyCode === request.currencyCode)).toList();
      }

      if (request.localizedName) {
        l = l.filter((cc) => (cc !== undefined && cc.localizedName === request.localizedName)).toList();
      }

      observer.next({
        items: l,
        pagingResult: {
          numberOfPages: 1,
          currentNumberOfItems: l.size,
          totalNumberOfItems: l.size,
        }
      });
      observer.complete();
      return function () {
      };
    });
  }

  public static round(value: number | Decimal, currency: Currency.Currency | string, paymentType?: Invoice.InvoicePaymentType, isRecord?: boolean): Decimal {
    const v: number = value instanceof Decimal ? value.toNumber() : value;
    const c: string = currency instanceof Currency.Currency ? currency.currencyCode : currency;

    if (c === 'HUF') {
      if (paymentType && paymentType === 'CASH' && (isRecord === undefined || !isRecord)) {
        return new Decimal(5 * Math.round(v / 5.0));
      }
      return new Decimal(Math.round(v));
    }
    return new Decimal(v);
  }
}

export namespace Currency {

  export class Currency {
    currencyCode: string = '';
    localizedName: string = ''; // localized based on app language
  }

  export interface QueryRequest {
    currencyCode?: string;
    localizedName?: string; // localized based on app language
  }

  export function compareCurrencies(o1?: Currency.Currency, o2?: Currency.Currency): boolean {
    if (o1 && o2) {
      return o1.currencyCode === o2.currencyCode;
    }
    else {
      return false;
    }
  }

}
