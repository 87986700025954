<mat-form-field appearance="none" class="w-100 tag-input-form-field">
  <mat-chip-list #chipList class="tag-input-chip-list">
    <mat-chip *ngFor="let item of selectedItems, let i = index;"
              (removed)="removeFromItemsByIndex(i)"
              [ngClass]="{ 'mat-chip-accent': isAutocompleteItem(item)}"
              [removable]="!readonly"
              disableRipple>
      {{formatTagInputItem(item)}}
      <c-icon [name]="'remove'" style="width: 10px" matChipRemove *ngIf="!readonly"></c-icon>
    </mat-chip>
    <input *ngIf="!textMaskInput && (!readonly || selectedItems.length === 0)"
           placeholder="{{placeholderStringKey | translate}}"
           [ngClass]="selectedItems.length > 0 ? 'placeholder-margin-top' : 'placeholder-margin-center'"
           class="tag-input border-0 p-0"
           [formControl]="tagInputControl"
           #tagInput
           [matChipInputFor]="chipList"
           [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
           [matAutocomplete]="autocomplete"
           (matChipInputTokenEnd)="onItemAdded($event)"
           #trigger="matAutocompleteTrigger"
           (blur)="onTouched()"
           [readOnly]="readonly">
    <input *ngIf="textMaskInput && (!readonly || selectedItems.length === 0)"
           placeholder="{{placeholderStringKey | translate}}"
           [ngClass]="selectedItems.length > 0 ? 'placeholder-margin-top' : 'placeholder-margin-center'"
           [textMask]="textMaskInput"
           class="tag-input border-0 p-0"
           [formControl]="tagInputControl"
           #tagInput
           [matChipInputFor]="chipList"
           [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
           (matChipInputTokenEnd)="onItemAdded($event)"
           (blur)="onTouched()"
           [readOnly]="readonly">
  </mat-chip-list>
  <mat-autocomplete #autocomplete="matAutocomplete" class="w-100" disableRipple (optionSelected)="onAutocompleteItemSelected($event)" *ngIf="!readonly">
    <mat-option *ngFor="let item of filteredItems | async" [value]="item" (click)="$event.stopPropagation(); trigger.openPanel()">
      {{formatTagInputItem(item)}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

<label class="col-form-label form-control-label detail-title mb-0 w-100 text-truncate"
       *ngIf="generateExampleOutput().length > 0 && shouldGenerateExample">
  {{'COMMON_EXAMPLE' | translate}}: {{generateExampleOutput()}}
</label>
