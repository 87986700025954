/* eslint-disable */
import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {TaskRecord, TaskRecordService} from '../../../../lib/task/task-record.service';
import {AbstractControl, FormBuilder, FormGroup, NgForm, NgModel, ValidatorFn, Validators,} from '@angular/forms';
import {
  EMPTY_QUERY_RESULT,
  FieldValidationError,
  ForwardingNgFormRef,
  LocalFormGroupValidationErrors,
  OrderType,
  ResourceQueryResult,
  Services
} from '../../../../lib/util/services';
import {TaskRecordBase} from './task-record-base-view';
import {
  DeviceItem,
  MultiselectOptionItem,
  OptionItem,
  OptionItems,
  OwnerUserItem,
  OwnerUserItemFactory,
  ProjectItem,
  UiConstants,
} from '../../../../util/core-utils';
import {ProjectRecordResourceService} from '../../../../lib/project/record/project-record-resource.service';
import {Device, DeviceManagementService} from '../../../../lib/device-management.service';
import {
  ConfigModel,
  CustomerAddressItem,
  TaskRecordImportanceItem,
  TaskRecordRightModel,
  TaskRecordStateItem,
} from '../../../../util/task-record-utils';
import {Arrays} from '../../../../lib/util/arrays';
import {StringKey} from '../../../../app.string-keys';
import {TranslateService} from '@ngx-translate/core';
import {InputMask} from '../../../../util/input-masks';
import {NgbDatePickerParserFormatter} from '../../../../util/ngb-datepicker';
import {ConfigurationService} from '../../../../lib/core-ext/configuration.service';
import {Map, Set} from 'immutable';
import {ToasterService} from '../../../../fork/angular2-toaster/angular2-toaster';
import {UIRouter} from '@uirouter/angular';
import {
  CustomerRecord,
  CustomerRecordContactLocation,
  CustomerRecordService
} from '../../../../lib/customer/customer-record.service';
import {TaskRecordStateMachine} from '../../../../lib/task/task-record-statemachine';
import {CustomerRecordFieldType, CustomerRecordItem} from '../../../../util/customer-record-utils';
import {Address, AddressModel} from '../../../../lib/address';
import {Angular2Multiselects} from '../../../../util/multiselect';
import {DocumentLinkList, DocumentLinkService} from '../../../../lib/document/document-link.service';
import {Strings} from '../../../../lib/util/strings';
import {combineLatest, empty, merge, Observable, of, Subject} from 'rxjs';
import {DocumentFile, DocumentFileService} from '../../../../lib/document/document-file.service';
import {debounceTime, flatMap, map} from 'rxjs/operators';
import {User, UserService} from '../../../../lib/user.service';
import {Task, TaskService} from '../../../../lib/task/task.service';
import {AppValidators} from '../../../../util/app-validators';
import {CustomerService} from '../../../../lib/customer/customer.service';
import {RightModel} from '../../../../app.rights';
import {SurveyMultiselectProvider} from '../../../../lib/survey/survey-multiselect.provider';
import {StateName} from '../../../../app.state-names';
import {ProjectRecordMultiselectProvider} from '../../../../lib/project/record/project-record-multiselect.provider';
import {AppTypeHelperService} from '../../../../lib/util/app-type-helper.service';
import {UserMeService} from '../../../../lib/user/user-me.service';
import {
  CustomerRecordQuickCreateMaterialDialogComponent
} from '../../../customer/customer-record-quick-create-material/customer-record-quick-create-material-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {
  CustomerRecordBillingInfoBaseDialogComponent
} from '../../../customer/customer-record-billing-info/customer-record-billing-info-base-dialog/customer-record-billing-info-base-dialog.component';
import {
  CustomerRecordBillingInfoComponentMode
} from '../../../customer/customer-record-billing-info/customer-record-billing-info-base/customer-record-billing-info-base.component';
import {HelpdeskState} from "../../../../lib/statistics/task-statistics/helpdesk.state";
import {
  ContractNumberMultiselectProvider
} from "../../../../lib/customer/contract-number/contract-number-multiselect-provider.service";
import {UserGroupMultiselectProvider} from "../../../../lib/user-group/user-group-multiselect.provider";
import {GrantedPermissionSetResolver} from "../../../../lib/right.service";
import ExportState = TaskRecord.ExportState;
import ComplexTimeModel = TaskRecordBase.ComplexTimeModel;
import TaskRecordField = TaskRecord.TaskRecordField;
import PlaceOfConsumptionRequirement = Task.PlaceOfConsumptionRequirement;
import PostalAddressModelType = AddressModel.PostalAddressModelType;
import DocumentItem = TaskRecordBase.DocumentItem;

/* eslint-enable */

@Component({
  selector: 'app-new-task-record-base',
  templateUrl: 'task-record-base.component.html',
  styleUrls: ['task-record-base.component.scss'],
})
export class TaskRecordBaseComponent implements OnInit, TaskRecordBase.View {

  TaskRecord = TaskRecord;
  TaskRecordStateMachine = TaskRecordStateMachine;
  UiConstants = UiConstants;
  PlaceOfConsumptionRequirement = PlaceOfConsumptionRequirement;
  console = console;

  @ViewChild('name')
  nameInput: NgModel;

  @Input()
  form?: NgForm;

  @Input()
  headingDictionaryKey: string;

  @Input()
  readonly: boolean;

  @Input()
  allowClone: boolean;

  @Input()
  allowStateChange: boolean;

  @Input()
  allowExport: boolean;

  @Input()
  externalIdRequired?: boolean;

  @Input()
  quickUpdateEnabled: boolean = false;

  grantedRights: TaskRecordRightModel = new TaskRecordRightModel(GrantedPermissionSetResolver.empty());

  @Output()
  private stateChange = new EventEmitter<TaskRecordStateMachine.State>();

  @Output()
  private sendEmail = new EventEmitter();

  @Output()
  settableStateListEmitter = new EventEmitter();

  @Output()
  submitted = new EventEmitter<boolean>();

  @Output()
  quickUpdate = new EventEmitter<void>();

  @Output()
  customerRecordChanged: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  selectedAddressChanged: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  assigneeUserChanged: EventEmitter<void> = new EventEmitter<void>();

  fieldStyle = {
    'padding': UiConstants.formRecordFieldPadding
  };

  formGroup: FormGroup;

  InputMask = InputMask;

  nameOptions$: Observable<string[]>;

  model: TaskRecordBase.Model;
  task: Task.Task;
  taskRecord?: TaskRecord.TaskRecord;
  createOptions?: TaskRecordBase.TaskRecordCreateOptions;
  taskId: number;
  customers: CustomerRecordItem[] = [];
  billingInfos: MultiselectOptionItem<number>[] = [];
  enabledCustomerIds: number[] = [];
  surveys: MultiselectOptionItem<number>[] = [];
  users: OwnerUserItem[] = [];
  userGroups: MultiselectOptionItem<number>[] = [];
  devices: DeviceItem[] = [];
  projects: ProjectItem[] = [];
  contractNumbers: MultiselectOptionItem<number>[] = [];
  taskImportances: TaskRecordImportanceItem[] = [];
  settableStateList: TaskRecordStateItem[] = [];
  _selectedAddress: CustomerAddressItem[];
  get selectedAddress(): CustomerAddressItem {
    return this._selectedAddress[0];
  }

  set selectedAddress(value: CustomerAddressItem) {
    this._selectedAddress = [];
    this._selectedAddress.push(value);
  }

  selectableAddresses: CustomerAddressItem[] = [];

  config: ConfigModel = new ConfigModel();

  singleDropdownSettings: Angular2Multiselects.Settings;
  multiDropdownSettings: Angular2Multiselects.Settings;
  customerDropdownSettings: Angular2Multiselects.Settings;
  billingInfoDropdownSettings: Angular2Multiselects.Settings;
  assigneeDropdownSettings: Angular2Multiselects.Settings;
  pocDropdownSettings: Angular2Multiselects.Settings;
  linkListItems: TaskRecordBase.DocumentItem[] = [];
  fileListItems: TaskRecordBase.DocumentItem[] = [];

  private readonly formControlClickEvents: Map<string, Subject<void>> = Map.of('name', new Subject());

  private fieldErrors: FieldValidationError<TaskRecord.ValidatedField> =
    FieldValidationError.empty<TaskRecord.ValidatedField>();

  private formGroupValidationErrors: LocalFormGroupValidationErrors;

  rightModel: RightModel = RightModel.empty();
  private forceCustomerSelectionId: number | undefined = undefined;


  /**
   * Only for HTML binding!
   */
  get m(): TaskRecordBase.Model {
    return this.model!;
  }


  constructor(private fb: FormBuilder,
              private ownerUserItemFactory: OwnerUserItemFactory,
              private deviceManagementService: DeviceManagementService,
              private projectRecordService: ProjectRecordResourceService,
              private projectRecordMultiselectProvider: ProjectRecordMultiselectProvider,
              private contractNumberMultiselectProvider: ContractNumberMultiselectProvider,
              private customerRecordService: CustomerRecordService,
              private customerService: CustomerService,
              private translateService: TranslateService,
              private ngbDatePickerParserFormatter: NgbDatePickerParserFormatter,
              private configService: ConfigurationService,
              private taskRecordService: TaskRecordService,
              private taskService: TaskService,
              private toasterService: ToasterService,
              private documentLinkService: DocumentLinkService,
              private documentFileService: DocumentFileService,
              private userService: UserService,
              private userMeService: UserMeService,
              private appTypeHelperService: AppTypeHelperService,
              private uiRouter: UIRouter,
              private dialog: MatDialog,
              private userGroupMultiselectProvider: UserGroupMultiselectProvider,
              private surveyMultiselectProvider: SurveyMultiselectProvider) {
    this.model = new TaskRecordBase.Model();
    this.selectedAddress = this.loadNoneSelectableAddress();
    this.initDropDown();
  }

  private initDropDown() {
    this.singleDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
    this.multiDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
  }

  private initCustomerDropDown() {
    this.customerDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
    this.assigneeDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .labelKey(OptionItem.KEY_TEXT)
      .remoteSearch(true)
      .build();
    this.billingInfoDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(false)
      .enableCheckAll(false)
      .build();
    this.pocDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(false)
      .searchBy(['itemName', 'itemSubtitle'])
      .build();
  }

  private loadDocuments() {
    if (this.rightModel.documentLinkRead.hasRight()) {
      this.loadLinkDocuments();
    }
    if (this.rightModel.documentFileRead.hasRight()) {
      this.loadFileDocuments();
    }
  }

  private loadLinkDocuments(searchValue?: string) {
    this.linkListItems = [];
    this.documentLinkService.query({
      name: Strings.undefinedOrNonEmpty(searchValue),
      order: Services.createOrderFieldParameter(DocumentLinkList.Keys.toOrderFieldKey,
        Set.of({field: DocumentLinkList.OrderField.NAME, type: OrderType.ASC})),
      page_number: 1,
      number_of_items: UiConstants.autocompletePageSize,
      disabled: false,
      no_progress_bar: true
    }).subscribe(result => {
      result.items.forEach(item => {
        const i = {
          id: item.id,
          itemName: item.name,
          disabled: item.disabled
        };
        this.linkListItems.push(i);
        if (searchValue === undefined && this.model.linkDocumentIds.indexOf(i.id) > -1) {
          this.model.linkDocuments.push(i);
          this.formGroup.controls['linkDocument'].updateValueAndValidity();
        }
      });
      if (searchValue === undefined && this.model.linkDocumentIds.length !== this.model.linkDocuments.length) {
        this.model.linkDocuments = [];
        this.documentLinkService.query({
          id: this.model.linkDocumentIds.join(',')
        }).subscribe(result => {
          result.items.forEach(item => {
            const i = {
              id: item.id,
              itemName: item.name,
              disabled: item.disabled
            };
            this.model.linkDocuments.push(i);
            this.formGroup.controls['linkDocument'].updateValueAndValidity();
          });
        });
      }
    });
  }

  private loadFileDocuments(searchValue?: string) {
    this.fileListItems = [];
    this.documentFileService.query({
      name: Strings.undefinedOrNonEmpty(searchValue),
      order: Services.createOrderFieldParameter(DocumentFile.Keys.toOrderFieldKey,
        Set.of({field: DocumentFile.OrderField.NAME, type: OrderType.ASC})),
      page_number: 1,
      number_of_items: UiConstants.autocompletePageSize,
      disabled: false,
      no_progress_bar: true
    }).subscribe(result => {
      result.items.forEach(item => {
        const i = {
          id: item.id,
          itemName: item.name,
          disabled: item.disabled
        };
        this.fileListItems.push(i);
        if (searchValue === undefined && this.model.fileDocumentIds.indexOf(i.id) > -1) {
          this.model.fileDocuments.push(i);
          this.formGroup.controls['fileDocument'].updateValueAndValidity();
        }
      });
      if (searchValue === undefined && this.model.fileDocumentIds.length !== this.model.fileDocuments.length) {
        this.model.fileDocuments = [];
        this.documentFileService.query({
          id: this.model.fileDocumentIds.join(',')
        }).subscribe(result => {
          result.items.forEach(item => {
            const i = {
              id: item.id,
              itemName: item.name,
              disabled: item.disabled
            };
            this.model.fileDocuments.push(i);
            this.formGroup.controls['fileDocument'].updateValueAndValidity();
          });
        });
      }
    });
  }

  getModel(): TaskRecordBase.Model {
    return this.model;
  }

  loadTaskRecord(args: TaskRecordBase.Args) {
    this.model.load(args, this.ngbDatePickerParserFormatter, this.translateService);
    this.taskRecord = args.taskRecord;
    this.createOptions = args.createOptions;
    this.task = args.task;
    this.taskId = args.task.taskId;
    this.rightModel = args.rightModel ? args.rightModel : RightModel.empty();
    this.grantedRights = args.grantedRights ? args.grantedRights : new TaskRecordRightModel(GrantedPermissionSetResolver.empty());
    this.loadOtherModelFields();
    if (this.rightModel.documentLinkRead.hasRight() || this.rightModel.documentFileRead.hasRight()) {
      this.loadDocuments();
    }
    this.setDefaultDeadline(args.defaultDeadline);
    if (this.config.assigneeWithUser && args.defaultUser) {
      this.model.assignee.load(this.ownerUserItemFactory.create(args.defaultUser));
    }
  }

  onFieldValidationError(fieldErrors: FieldValidationError<TaskRecord.ValidatedField>) {
    this.fieldErrors = fieldErrors;
  }

  onAutoCompleteClick(formControlName: string) {
    const eventEmitter: Subject<void> = this.formControlClickEvents.get(formControlName);
    const c: AbstractControl = this.formGroup.controls[formControlName];
    const value: string = c.value;
    if (value.length === 0) {
      eventEmitter.next();
    }
  }

  ngOnInit(): void {
    this.formGroup = this.createFormGroup(this.fb);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(
      this.createForwardingHtmlForm(),
      this.formGroup
    );
    this.loadConfig();
    const nameClickEvent: Observable<void> = this.formControlClickEvents.get('name').asObservable();
    const nameChangeEvent: Observable<any> = this.formGroup.controls['name'].valueChanges;
    this.nameOptions$ = merge(nameClickEvent, nameChangeEvent).pipe(
      debounceTime(UiConstants.autocompleteDebounceTime),
      flatMap(
        (value) => {
          return this.taskRecordService.queryTaskRecordNames({
            name: value,
            numberOfItems: UiConstants.autocompletePageSize,
          }).pipe(map((result) => {
            return result;
          }));
        }
      )
    );
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  hasLocalFormError(errorCode: string): boolean {
    return this.formGroupValidationErrors.hasFormError(errorCode);
  }

  hasFieldError(field: TaskRecord.ValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: TaskRecord.ValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: TaskRecord.ValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  private createForwardingHtmlForm() {
    return new ForwardingNgFormRef({
      formFn: () => {
        return this.form;
      }
    });
  }

  private getExternalIdRequirement(): ValidatorFn | undefined {
    if (this.externalIdRequired === true) {
      return Validators.required;
    } else {
      return undefined;
    }
  }

  private createFormGroup(fb: FormBuilder): FormGroup {
    return fb.group(
      {
        name: fb.control(
          {value: this.model.name},
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => this.isNameOptional()
            })
          ]
        ),
        externalId: fb.control(
          {value: this.model.externalId},
          this.getExternalIdRequirement()
        ),
        project: fb.control(
          {value: this.model.project},
          [
            AppValidators.validateEnabledItems,
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => !this.isRequiredField('PROJECT')
            })
          ]
        ),
        contractNumber: fb.control(
          {value: this.model.contractNumber},
          [
            AppValidators.validateEnabledItems,
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => !this.isRequiredField('CONTRACT_NUMBER')
            })
          ]
        ),
        assigneeUser: fb.control(
          {value: this.model.assignee._user},
          [
            AppValidators.validateEnabledItems,
            AppValidators.required({
              disabled: () => {
                return !this.isRequiredField('ASSIGNEE_USER_APPLICATION');
              }
            })
          ]
        ),
        usergroup: fb.control(
          {value: this.model.userGroup},
          [
            AppValidators.validateEnabledItems,
            AppValidators.required({
              disabled: () => {
                return !this.isRequiredField('ASSIGNEE_USER_GROUP');
              }
            })
          ]
        ),
        device: fb.control(
          {value: this.model.assignee._device},
          [
            AppValidators.validateEnabledItems,
            AppValidators.required({
              disabled: () => {
                return !this.isRequiredField('ASSIGNEE_USER_APPLICATION');
              }
            })
          ]
        ),
        customer: fb.control(
          {value: this.model.customerM},
          [
            AppValidators.validateEnabledItems,
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => !this.isRequiredField('CUSTOMER')
            })
          ]
        ),
        billingInfo: fb.control(
          {value: this.model.billingInfo},
          [
            AppValidators.validateEnabledItems,
            AppValidators.required({
              disabled: () => {
                return this.task && !this.task.autoInvoiceGenerate;
              }
            })
          ]
        ),
        fileDocument: fb.control(
          {value: this.model.fileDocuments},
          [
            AppValidators.validateEnabledItems,
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => !this.isRequiredField('FILE_DOCUMENTS')
            })
          ]
        ),
        linkDocument: fb.control(
          {value: this.model.linkDocuments},
          [
            AppValidators.validateEnabledItems,
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => !this.isRequiredField('TEXT_DOCUMENTS')
            })
          ]
        ),
        survey: fb.control(
          {value: this.model.linkedSurveys},
          [
            AppValidators.validateEnabledItems,
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => !this.isRequiredField('SURVEYS')
            })
          ]
        ),
        latitude: fb.control(
          {value: this.model.placeOfConsumption.latitude},
          [
            AppValidators.validateOptionalLatitude
          ]
        ),
        longitude: fb.control(
          {value: this.model.placeOfConsumption.longitude},
          [
            AppValidators.validateOptionalLongitude
          ]
        ),
        agreedTimeDate: fb.control(
          {value: this.model.agreedTimeDate},
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => !this.isRequiredField('AGREED_TIME')
            })
          ]
        ),
        agreedTimeTime: fb.control(
          {value: this.model.agreedTimeTime},
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => !this.isRequiredField('AGREED_TIME')
            })
          ]
        ),
        deadlineDate: fb.control(
          {value: this.model.deadlineDate},
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => !this.isRequiredField('DEADLINE')
            })
          ]
        ),
        deadlineTime: fb.control(
          {value: this.model.deadlineTime},
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => !this.isRequiredField('DEADLINE')
            })
          ]
        ),
        description: fb.control(
          {value: this.model.description},
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => !this.isRequiredField('DESCRIPTION')
            })
          ]
        ),
        estimatedTimeHour: fb.control(
          {value: this.model.estimatedTime},
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => !this.isRequiredField('ESTIMATED_TIME') || !this.task || !this.task.timeTrackingEnabled
            })
          ]
        ),
        estimatedTimeMinute: fb.control(
          {value: this.model.estimatedTime},
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => !this.isRequiredField('ESTIMATED_TIME') || !this.task || !this.task.timeTrackingEnabled
            })
          ]
        ),
        importance: fb.control(
          {value: this.model.importance},
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => !this.isRequiredField('IMPORTANCE')
            })
          ]
        )
      }
    );
  }

  loadOtherModelFields() {
    this.loadAssigneeUserGroup();
    this.loadSelectableAddresses();
    if (this.config.assigneeWithUser) {
      this.loadUsers();
    }
    if (this.config.assigneeWithMobileApp) {
      this.loadDevices();
    }
    if (this.rightModel.surveyRead.hasRight()) {
      this.loadLinkedSurveys();
      this.onSurveySearch();
    }
    if (this.rightModel.projectRecordRead.hasRight()) {
      this.onProjectSearch();
      this.loadProject();
    }
    this.loadCustomers();
    this.loadTaskStates();
    this.loadTaskImportances();
    if (!this.taskRecord) {
      this.setDefaultEstimatedTime();
    }
  }

  // <editor-fold desc="loaders">
  private loadUsers(searchValue?: string) {
    if (this.rightModel.userRead.hasRight()) {
      let observable: Observable<ResourceQueryResult<User>> = empty();
      if (this.readonly && this.model && this.model.assignee.user && this.model.assignee.user.id) {
        observable = this.userService.query({id: '' + this.model.assignee.user.id});
      } else if (!this.readonly) {
        observable = this.userService.query({
          q: Strings.undefinedOrNonEmpty(searchValue),
          user_group_ids: this.model.usergroup && this.model.usergroup.id
            ? this.model.usergroup.id.toString()
            : Services.createIdParameter(this.task.enabledAssignees.userGroups),
          fields: 'id,user_name,person_name,disabled',
          disabled: false,
          number_of_items: UiConstants.autocompletePageSize,
          page_number: 1,
          order: Services.createOrderFieldParameter(User.Keys.toOrderFieldKey, Set.of(User.DEFAULT_ORDER)),
          no_progress_bar: true
        });
      }
      observable.subscribe((users: ResourceQueryResult<User>) => {
        this.users = [];
        const sortedOwnerUsers: OwnerUserItem[] = this.ownerUserItemFactory.createAll(users.items);
        sortedOwnerUsers.sort(OptionItems.createNameComparator());
        let assigneeFound = false;
        Arrays.iterateByIndex(sortedOwnerUsers, (item) => {
          this.users.push(item);
          if (searchValue === undefined && this.model && this.model.assignee.user && this.model.assignee.user.id === item.id) {
            this.model.assignee.user = item;
            this.assigneeUserChanged.emit();
            this.formGroup.controls['assigneeUser'].updateValueAndValidity();
            assigneeFound = true;
          }
        });
        if (searchValue === undefined && !assigneeFound && this.model && this.model.assignee.user && this.model.assignee.user.id) {
          this.userService.get({
            id: this.model.assignee.user.id,
            ignore_permission_denied: true
          }).subscribe(user => {
            this.model.assignee.user = this.ownerUserItemFactory.create(user);
            this.assigneeUserChanged.emit();
            this.formGroup.controls['assigneeUser'].updateValueAndValidity();
          });
        }
      });
    }
  }

  private loadAssigneeUserGroup() {
    if (this.model.usergroup != null) {
      this.userGroupMultiselectProvider.getById(this.model.usergroup.id!).subscribe(item => {
        this.model.usergroup = item;
        this.formGroup.controls['usergroup'].updateValueAndValidity();
      })
    }
  }

  private loadUserGroups(searchValue?: string) {
    this.userGroupMultiselectProvider.loadActiveWithIdSet(this.task.enabledAssignees.userGroups, searchValue).subscribe(result => {
      this.userGroups = result;
      if ((!this.model.usergroup || this.model.usergroup.itemName.length === 0) && (searchValue === undefined || searchValue === '') &&
        result.length === 1 && this.isRequiredField('ASSIGNEE_USER_GROUP')) {
        this.model.usergroup = result[0];
        this.formGroup.controls['usergroup'].updateValueAndValidity();
      }
    });
  }

  private loadDevices(searchValue?: string) {
    let observable: Observable<ResourceQueryResult<Device>> = empty();
    if (this.readonly && this.model && this.model.assignee && this.model.assignee.device && this.model.assignee.device.id) {
      observable = this.deviceManagementService.query({id: '' + this.model.assignee.device.id});
    } else if (!this.readonly) {
      observable = this.deviceManagementService.query({
        disabled: false,
        name: Strings.undefinedOrNonEmpty(searchValue),
        number_of_items: UiConstants.autocompletePageSize,
        page_number: 1,
        no_progress_bar: true
      });
    }
    observable.subscribe((devices: ResourceQueryResult<Device>) => {
      this.devices = [];
      let deviceFound = false;
      devices.items.forEach((device) => {
        const item = {
          id: device.id,
          text: device.name ? device.name : device.application_id
        };
        this.devices.push(item);
        if (searchValue === undefined && this.model && this.model.assignee.device && this.model.assignee.device.id === item.id) {
          this.model.assignee.device = item;
          this.formGroup.controls['device'].updateValueAndValidity();
          deviceFound = true;
        }
      });
      if (searchValue === undefined &&
        !deviceFound && this.taskRecord && this.taskRecord.assignee && this.taskRecord.assignee.mobileApplicationId) {
        this.deviceManagementService.get({
          id: this.taskRecord.assignee.mobileApplicationId
        }).subscribe(device => {
          const item = {
            id: device.id,
            text: device.name,
            disabled: device.disabled
          };
          this.model.assignee.device = item;
          this.formGroup.controls['device'].updateValueAndValidity();
        });
      }
      if (this.taskRecord && this.taskRecord.assignee && this.taskRecord.assignee.userId) {
        this.userService.get({
          id: this.taskRecord.assignee.userId,
        }).subscribe(
          (user: User) => {
            this.model.assignee.user = this.ownerUserItemFactory.create(user);
          });
      }
    });
  }

  isHelpdesk(): boolean {
    return this.appTypeHelperService.isHelpdesk();
  }

  private loadCustomers() {
    this.initCustomerDropDown();
    if ((this.readonly && this.taskRecord && this.taskRecord.customerId)) {
      this.getCustomers([this.taskRecord.customerId]);
    } else if (this.appTypeHelperService.isHelpdesk()) {
      this.userMeService.loadMe().subscribe(me => {
        if (me.helpdesk && me.helpdesk.customer_record_id) {
          this.forceCustomerSelectionId = me.helpdesk.customer_record_id;
          this.getCustomers([me.helpdesk.customer_record_id]);
        } else {
          this.getCustomers([]);
        }
      })
    } else if (!this.readonly) {
      this.getCustomers([]);
    }
  }

  private getCustomers(custIds: number[], q?: any) {
    let selectedId: number | undefined = this.taskRecord ? this.taskRecord.customerId : undefined;
    if (this.forceCustomerSelectionId) {
      selectedId = this.forceCustomerSelectionId;
    }
    if (this.rightModel.customerRead.hasRight() && this.rightModel.customerRecordRead.hasRight()) {
      this.queryCustomers(custIds, q).subscribe((result: CustomerRecord.CustomerRecord[]) => {
        this.customers = [];
        const len = result.length;
        result.forEach((customerRecord) => {
          if (customerRecord) {
            const c = customerRecord.customer!;
            const addr = !customerRecord.postalAddress
            || !c.managedFields.contains(CustomerRecordFieldType.POSTAL_ADDRESS_AND_INVOICE_ADDRESS_AND_NOTIFICATION_ADDRESS)
              ? ''
              : Address.PostalAddressMapper.toString(customerRecord.postalAddress, this.config.postalAddressFormat);
            const item = {
              id: customerRecord.customerRecordId,
              customerId: customerRecord.customerId,
              text: customerRecord.name,
              itemName: customerRecord.name,
              itemSubtitle: addr === '' ? undefined : addr,
              customerRecord: customerRecord,
              address: addr,
              disabled: customerRecord.disabled,
              invoiceDeadlineAdditionalDays: customerRecord.invoiceDeadlineAdditionalDays,
              companyId: customerRecord.companyId
            };
            this.customers.push(item);
            if ((q === undefined && selectedId === item.id) || (len === 1 && this.model.contractNumberId)) {
              this.model.customer = item;
              this.formGroup.controls['customer'].updateValueAndValidity();
              this.loadSelectableAddresses(customerRecord,
                c.managedFields.contains(CustomerRecordFieldType.POSTAL_ADDRESS_AND_INVOICE_ADDRESS_AND_NOTIFICATION_ADDRESS));
              this.loadBillingInfos();
              this.customerRecordChanged.emit();
            }
          }
        });
      });
    }
    // load the already selected customer, if it is out of the selectable list's range
    // Also, at this point, the customer was not added, but the task record has a customer so it is possible that
    // the user does not have the necessary rights. The server sends the customerRecord with the task record, so
    // it can be added to the model from the task record
    if (this.taskRecord && this.taskRecord.customerId && this.taskRecord.customerRecord &&
      (!this.model.customer || !this.model.customer.id)) {
      const customer = this.taskRecord!.customerRecord!;
      const c = customer.customer!;
      const addr = !customer.postalAddress
      || !c.managedFields.contains(CustomerRecordFieldType.POSTAL_ADDRESS_AND_INVOICE_ADDRESS_AND_NOTIFICATION_ADDRESS)
        ? ''
        : Address.PostalAddressMapper.toString(customer.postalAddress, this.config.postalAddressFormat);
      const item = {
        id: customer.customerRecordId,
        customerId: customer.customerId,
        text: customer.name,
        itemName: customer.name + ' (' + addr + ')',
        customerRecord: customer,
        address: addr,
        disabled: customer.disabled,
        invoiceDeadlineAdditionalDays: customer.invoiceDeadlineAdditionalDays,
        companyId: customer.companyId
      };
      if (q === undefined && this.taskRecord && this.taskRecord.customerId === item.id) {
        this.model.customer = item;
        this.formGroup.controls['customer'].updateValueAndValidity();
        this.loadSelectableAddresses(customer,
          c.managedFields.contains(CustomerRecordFieldType.POSTAL_ADDRESS_AND_INVOICE_ADDRESS_AND_NOTIFICATION_ADDRESS));
        this.loadBillingInfos();
        this.customerRecordChanged.emit();
      }
    }
  }

  private loadLinkedSurveys() {
    let linkedSurveyIds: number[] = [];
    this.model.linkedSurveys = [];
    if (!this.taskRecord) { // create case
      if (this.defaultLinkedSurveyIds.length === 0) { // empty id set causes all items to load
        return;
      }
      linkedSurveyIds = this.task.defaultLinkedSurveyIds.toArray();
    } else { // edit/detail case
      if (this.taskRecord.linkedSurveyIds.size === 0) { // empty id set causes all items to load
        return;
      }
      linkedSurveyIds = this.taskRecord.linkedSurveyIds.toArray();
    }
    this.surveyMultiselectProvider.getByIds(linkedSurveyIds).subscribe(linkedSurveys => {
      this.model.linkedSurveys = linkedSurveys;
      this.formGroup.controls['survey'].updateValueAndValidity();
    });
  }

  get defaultLinkedSurveyIds(): number[] {
    return this.task.defaultLinkedSurveyIds.size > 0 ? this.task.defaultLinkedSurveyIds.toArray() : [];
  }

  private onSurveySearch(predicate?: string) {
    if (!this.task || this.defaultLinkedSurveyIds.length === 0) {
      this.surveys = [];
      return;
    }
    this.surveyMultiselectProvider.loadForTask(this.defaultLinkedSurveyIds, predicate).subscribe(surveys => {
      this.surveys = surveys;
    });
  }

  private setDefaultEstimatedTime() {
    if (this.task.defaultEstimatedTimeInMinutes
      && !this.taskRecord
      && TaskRecordStateMachine.StateMachine.canChangeEstimatedTime(this.currentState)
      && this.model.estimatedTime.isZero()) {
      this.model.estimatedTime = ComplexTimeModel.minutesToComplexTime(this.task.defaultEstimatedTimeInMinutes);
    }
  }

  private queryCustomers(custIds: number[], q?: any):
    Observable<CustomerRecord.CustomerRecord[]> {
    return this.customerRecordService.globalQuery({
      withFormRecord: false,
      customerRecordIdSet: Set.of(...custIds),
      customerIds: !this.readonly ? Set.of(...this.enabledCustomerIds) : undefined,
      disabled: false,
      parentDisabled: false,
      contractNumberId: this.model.contractNumberId && custIds.length === 0 ? Set.of(this.model.contractNumberId) : undefined,
      contactPersonType: false,
      fields: Set.of('id', 'name', 'postal_address', 'customer_id', 'customer_record_id',
        'invoice_deadline_additional_days', 'company_id', 'customer', 'main_contract_number'),
      orders: Set.of({type: OrderType.ASC, field: CustomerRecord.OrderField.NAME}),
      paging: {
        numberOfItems: UiConstants.autocompletePageSize,
        pageNumber: 1
      },
      queryText: q ? Strings.undefinedOrNonEmpty(q.target.value) : undefined,
      noProgressBar: true
    }).pipe(map(result => result.items.toArray()));
  }

  onProjectSearch(predicate?: string) {
    this.projectRecordMultiselectProvider.loadActive(predicate).subscribe((items) => {
      this.projects = items;
    });
  }

  onContractNumberSearch(predicate?: string) {
    this.contractNumberMultiselectProvider.loadActive(predicate).subscribe((items) => {
      this.contractNumbers = items;
    });
  }

  contractNumberSelected(selected: MultiselectOptionItem<number>) {
    if (selected) {
      this.getCustomers([]);
      this.model.name = selected.itemName;
    }
  }

  private loadProject() {
    if (this.taskRecord && this.taskRecord.projectId) {
      this.projectRecordMultiselectProvider.getById(this.taskRecord.projectId).subscribe(project => {
        this.model.project = [];
        this.model.project.push(project);
        this.formGroup.controls['project'].updateValueAndValidity();
      });
    }
    if (this.createOptions && this.createOptions.projectRecordId) {
      this.projectRecordMultiselectProvider.getById(this.createOptions.projectRecordId).subscribe(project => {
        this.model.project = [];
        this.model.project.push(project);
        this.formGroup.controls['project'].updateValueAndValidity();
      });
    }
  }

  private loadTaskStates() {
    this.settableStateList = [];
    this.model.helpdeskState = this.appTypeHelperService.isHelpdesk() && this.taskRecord?.helpdeskState ? HelpdeskState.getStateObject(this.taskRecord.helpdeskState) : undefined;
    this.TaskRecordStateMachine.getOrderedStates(false).forEach(
      (state: TaskRecordStateMachine.StateObject) => {
        this.translateService.get(state.stringKey).subscribe((text: string) => {
          if (this.taskRecord && this.taskRecord.state === state.state) {
            if (!this.model.isClone) {
              let item = {id: state.state, text: text, stateObject: state};
              this.model.state = item;
            }
            this.setDefaultEstimatedTime();
          }
          if (state.stringEditKey && this.canChangeState(state) && this.allowStateChange) {
            this.translateService.get(state.stringEditKey).subscribe((editText: string) => {
              const editItem = {id: state.state, text: editText, stateObject: state};
              this.settableStateList.push(editItem);
            });
          }
        });
      });
    this.settableStateListEmitter.emit(this.settableStateList);
  }

  private loadTaskImportances() {
    this.taskImportances = [];
    this.TaskRecord.taskRecordImportances.forEach((importance) => {
      this.translateService.get(importance.stringKey).subscribe((text: string) => {
        const item = {id: importance.importance, text: text, badgeClass: importance.badgeClass};
        this.taskImportances.push(item);
        if (this.taskRecord && this.taskRecord.importance === item.id) {
          this.model.importance = item;
        }
        if (!this.taskRecord && item.id === 'NOT_IMPORTANT') {
          this.model.importance = item;
        }
      });
    });
  }

  loadDefaultItem() {
    return {id: null, text: this.translateService.instant(StringKey.COMMON_VALUE_UNSELECTED), address: ''};
  }

  private loadConfig() {
    this.config = this.configService.getConfigurationModel();
  }

  canChangeState(toState: TaskRecordStateMachine.StateObject): boolean {
    const hasRightForStateChange = this.TaskRecordStateMachine.StateMachine.hasGrantedRightForStateChange(
      this.grantedRights, this.taskRecord ? this.taskRecord.state : null, toState.state);
    if (this.taskRecord && this.taskRecord.state) {
      switch (this.taskRecord.state) {
        case 'NEW':
        case 'OPEN':
          if (toState.apiName === 'finish') {
            return hasRightForStateChange && this.task
              && this.task.validationType !== 'REQUIRED';
          }
          break;
        case 'IN_PROGRESS':
          if (toState.apiName === 'submit') {
            return hasRightForStateChange && this.task
              && this.task.validationType !== 'DISABLED';
          }
          if (toState.apiName === 'finish') {
            return hasRightForStateChange && this.task
              && this.task.validationType !== 'REQUIRED';
          }
          break;
        case 'REJECTED':
          if (toState.state === 'ARCHIVED') {
            return hasRightForStateChange && this.config.rejectedToArchived;
          }
          break;
      }
    }
    const canChangeState = this.TaskRecordStateMachine.StateMachine.canChangeState(this.taskRecord ?
      this.taskRecord.state : null, toState.state);
    return canChangeState && hasRightForStateChange;
  }

  loadSelectableAddresses(customerRecord?: CustomerRecord.CustomerRecord, addressManaged?: boolean) {
    const canChangePOC = TaskRecordStateMachine.StateMachine.canChangePoc(this.currentState);
    const locationObs = customerRecord
    && this.configService.isCustomerRecordManagedField(CustomerRecordFieldType.CONTACT_LOCATIONS)
    && this.rightModel.customerRecordRead.hasRight() ?
      this.customerRecordService.queryContactLocations({
        customerId: customerRecord.customerId,
        customerRecordId: customerRecord.customerRecordId,
        noProgressBar: true,
        contractNumberIds: this.model.contractNumberId ? Set.of(this.model.contractNumberId) : undefined,
        orders: Set.of({type: OrderType.ASC, field: CustomerRecordContactLocation.OrderField.NAME},
          {type: OrderType.ASC, field: CustomerRecordContactLocation.OrderField.ID})
      }) : of(new EMPTY_QUERY_RESULT<Address.ContactLocation>());
    combineLatest(this.userMeService.loadMe(), locationObs, (me, locations) => {
      return {me: me, locations: locations}
    }).subscribe(value => {
      const me = value.me;
      const locations = value.locations && value.locations.items ? value.locations.items.toArray() : [];
      const isHelpdesk = me.helpdesk !== undefined && me.helpdesk.contact_person_id !== undefined;
      const hasLocation = me.helpdesk !== undefined && me.helpdesk.contact_location_ids != undefined && me.helpdesk.contact_location_ids.length > 0;
      const forceLocation = customerRecord ? customerRecord.customerRecordId === this.forceCustomerSelectionId && hasLocation : false;
      this.selectableAddresses = [];
      const def = this.loadNoneSelectableAddress();
      const newAddress = this.loadNewSelectableAddress();
      this.selectableAddresses.push(def);
      this.selectedAddress = def;
      const contrarctNumberCheck = this.model.contractNumberStr != null;
      const matchesContractNumber: boolean = contrarctNumberCheck && value.locations.items.filter(x => x !== undefined && x.contractNumber === this.model.contractNumberStr).size > 0;
      if (customerRecord) {
        this.translateService.get('CUSTOMER_RECORD_MAIN_ADDRESS_TEXT').subscribe((text: string) => {
          const mainAddress: Address.PostalAddressData | undefined = customerRecord.postalAddress;
          if (!hasLocation && mainAddress && addressManaged && (!contrarctNumberCheck || (this.model.contractNumberStr === customerRecord.mainContractNumber))) {
            const mainAddressItem: CustomerAddressItem = {
              id: mainAddress.id ? mainAddress.id : null,
              itemName: text,
              itemSubtitle: Address.PostalAddressMapper.toString(mainAddress, this.config.postalAddressFormat),
              postalAddress: mainAddress
            };
            this.selectableAddresses.push(mainAddressItem);
            if (canChangePOC && !hasLocation &&
              (contrarctNumberCheck && !matchesContractNumber && this.model.contractNumberStr === customerRecord.mainContractNumber)) {
              this.model.contactLocationId = undefined;
              this.selectedAddress = mainAddressItem;
              this.model.placeOfConsumption.loadPostalAddressData(
                this.selectedAddress.postalAddress, this.configService.getDefaultSelectedCountryCode()
              );
            } else if (this.model.placeOfConsumption.address.id === mainAddressItem.id) {
              this.model.contactLocationId = undefined;
              this.selectedAddress = mainAddressItem;
              this.model.placeOfConsumption.loadPostalAddressData(
                this.selectedAddress.postalAddress, this.configService.getDefaultSelectedCountryCode()
              );
            }
          }
        });
        locations.forEach((l) => {
          if (l && l.postalAddress) {
            const addressItem: CustomerAddressItem = {
              id: l.postalAddress.id ? l.postalAddress.id : null,
              itemName: l.name + (l.contractNumber ? (' (' + l.contractNumber + ')') : ''),
              itemSubtitle: Address.PostalAddressMapper.toString(l.postalAddress, this.config.postalAddressFormat),
              postalAddress: l.postalAddress,
              contactLocationId: l.id
            };
            if (!hasLocation
              || (isHelpdesk && me.helpdesk!.contact_location_ids && me.helpdesk!.contact_location_ids.findIndex(hl => hl === addressItem.contactLocationId) >= 0)) {
              this.selectableAddresses.push(addressItem);
              if (canChangePOC && ((this.model.placeOfConsumption.address.id === undefined && forceLocation)
                || (contrarctNumberCheck && value.locations.items.size === 1 && l.contractNumber === this.model.contractNumber[0].itemName))) {
                this.selectedAddress = addressItem;
                this.model.contactLocationId = addressItem.contactLocationId;
                this.model.placeOfConsumption.loadPostalAddressData(
                  this.selectedAddress.postalAddress, this.configService.getDefaultSelectedCountryCode()
                );
              } else if (this.model.placeOfConsumption.address.id === addressItem.id) {
                this.selectedAddress = addressItem;
                this.model.contactLocationId = addressItem.contactLocationId;
                this.model.placeOfConsumption.loadPostalAddressData(
                  this.selectedAddress.postalAddress, this.configService.getDefaultSelectedCountryCode()
                );
              }
            }
          }
        });
        if ((!hasLocation || (this.selectedAddress === def && this.model.placeOfConsumption.address.id))) {
          this.selectableAddresses.push(newAddress);
        }
        if (this.selectedAddress === def && this.model.placeOfConsumption.address.id) {
          this.selectedAddress = newAddress;
          if (this.taskRecord && this.taskRecord.placeOfConsumption && this.taskRecord.placeOfConsumption.address
            && this.model.placeOfConsumption.address.id === this.taskRecord.placeOfConsumption.address.id) {
          } else {
            this.model.placeOfConsumption.reset();
          }
        }
      }
      if (!customerRecord || !addressManaged) {
        if (!this.selectableAddresses.find(a => a === newAddress)) {
          this.selectableAddresses.push(newAddress);
        }
        if (this.selectedAddress === def && this.taskRecord && this.taskRecord.placeOfConsumption && this.taskRecord.placeOfConsumption.address) {
          this.selectedAddress = newAddress;
        }
        if (this.selectedAddress.id === newAddress.id
          && this.selectedAddress.itemName === newAddress.itemName
          && this.selectedAddress.itemSubtitle === newAddress.itemSubtitle) {
          setTimeout(() => {
            this.model.placeOfConsumption.address.type = PostalAddressModelType.COMPLEX
          });
        }
      }

    });
  }

  loadNoneSelectableAddress(): CustomerAddressItem {
    return {id: null, itemName: this.translateService.instant(StringKey.TASK_RECORD_POC_ADDRESS_NONE)};
  }

  loadNewSelectableAddress(): CustomerAddressItem {
    return {id: -1, itemName: this.translateService.instant(StringKey.TASK_RECORD_POC_ADDRESS_NEW)};
  }


  onSelectedAddressChanged() {
    const newAddress = this.loadNewSelectableAddress();
    if (this.selectedAddress && this.selectedAddress.id && this.selectedAddress.id !== newAddress.id) {
      this.model.contactLocationId = this.selectedAddress.contactLocationId;
      this.model.placeOfConsumption.loadPostalAddressData(
        this.selectedAddress.postalAddress, this.configService.getDefaultSelectedCountryCode()
      );
    } else if (this.selectedAddress && this.selectedAddress.id === newAddress.id
      && this.selectedAddress.itemName === newAddress.itemName
      && this.selectedAddress.itemSubtitle === newAddress.itemSubtitle) {
      this.model.contactLocationId = undefined;
      this.model.placeOfConsumption.reset();
      this.model.placeOfConsumption.address.type = AddressModel.PostalAddressModelType.NONE;
      const thisComponent = this;
      setTimeout(function () {
        thisComponent.model.placeOfConsumption.address.type = AddressModel.PostalAddressModelType.COMPLEX;
      }, 0);
    } else {
      this.model.contactLocationId = undefined;
      this.model.placeOfConsumption.reset();
      this.model.placeOfConsumption.latitude = undefined;
      this.model.placeOfConsumption.longitude = undefined;
      this.model.placeOfConsumption.address.type = AddressModel.PostalAddressModelType.NONE;
    }
    this.selectedAddressChanged.emit();
  }

  private loadBillingInfos() {
    this.customerRecordService.listBillingInfo({
      customerId: this.model.customer!.customerRecord!.customerId,
      customerRecordId: this.model.customer!.customerRecord!.customerRecordId,
      disabled: false
    }).subscribe(result => {
      this.billingInfos = result.map(i => (
        {
          id: i.id,
          itemName: i.name,
          itemSubtitle: this.createBillingInfoSubtitle(i)
        }));
    });
  }

  private createBillingInfoSubtitle(info: CustomerRecord.BillingInfo) {
    switch (info.invoiceVatStatus) {
      case CustomerRecord.InvoiceVatStatus.PRIVATE_PERSON:
        return this.translateService.instant('INVOICE_VAT_STATUS_PRIVATE_PERSON');
      case CustomerRecord.InvoiceVatStatus.DOMESTIC:
        return this.translateService.instant('INVOICE_VAT_STATUS_DOMESTIC') + ' / ' + info.taxNumber;
      case CustomerRecord.InvoiceVatStatus.OTHER:
        return this.translateService.instant('INVOICE_VAT_STATUS_OTHER') + ' / ' + info.euTaxNumber;
    }
  }

  onCustomerChanged() {
    if (!this.model.customer) {
      const newAddress = this.loadNewSelectableAddress();
      if (this.selectedAddress.id !== newAddress.id
        || this.selectedAddress.itemName !== newAddress.itemName
        || this.selectedAddress.itemSubtitle !== newAddress.itemSubtitle) {
        this.model.placeOfConsumption.reset();
      }
      this.loadSelectableAddresses();
      this.model.billingInfo = [];
    } else {
      this.loadSelectableAddresses(this.model.customer.customerRecord, !!this.model.customer.address);
      this.loadBillingInfos();
    }
    // this.onSelectedAddressChanged();
    this.customerRecordChanged.emit();
  }

  onUserGroupModelChange() {
    this.loadUsers();
    this.formGroup.controls['assigneeUser'].updateValueAndValidity();
    this.formGroup.controls['device'].updateValueAndValidity();
  }

  onAssigneeChange() {
    this.formGroup.controls['usergroup'].updateValueAndValidity();
  }

  onAssigneeCustomerChanged() {
    this.assigneeUserChanged.emit();
  }

  onSaveButtonClicked(navigateOnSubmit: boolean) {
    this.submitted.emit(navigateOnSubmit);
  }

  onQuickUpdateClicked() {
    this.quickUpdate.emit();
  }

  get latLonVisible() {
    return this.model.placeOfConsumption.address && this.model.placeOfConsumption.address.type !== AddressModel.PostalAddressModelType.NONE;
  }

  showPOCAddress(): boolean {
    if (this.model.placeOfConsumption) {
      return this.model.placeOfConsumption.address.type !== PostalAddressModelType.NONE;
    }
    return false;
  }

  getDeadlineDateTime() {
    return this.ngbDatePickerParserFormatter.toOffsetDateTime(this.model.deadlineDate, this.model.deadlineTime).toUtcIsoString();
  }

  getAgreedDateTime() {
    return this.ngbDatePickerParserFormatter.toOffsetDateTime(this.model.agreedTimeDate, this.model.agreedTimeTime).toUtcIsoString();
  }

  getExportStateString(state: ExportState): string {
    if (state === 'EXPORTED') {
      return 'TASK_RECORD_DETAIL_EXPORT_SUCCEEDED';
    } else if (state === 'EXPORT_FAILED') {
      return 'TASK_RECORD_DETAIL_EXPORT_FAILED';
    }
    return '';
  }

  private setDefaultDeadline(defaultDeadline?: Task.TaskDefaultDeadline) {
    if (defaultDeadline) {
      if (defaultDeadline.dayType === 'CALENDAR_DAY') {
        const deadline = new Date(new Date().getTime() + defaultDeadline.offset * 24 * 60 * 60 * 1000);
        this.model.deadlineDate = {
          day: deadline.getUTCDate(),
          month: deadline.getUTCMonth() + 1,
          year: deadline.getUTCFullYear()
        };
      }
      if (defaultDeadline.dayType === 'BUSINESS_DAY') {
        const deadline = this.addBusinessDaysToDate(defaultDeadline.offset);
        this.model.deadlineDate = {
          day: deadline.getUTCDate(),
          month: deadline.getUTCMonth() + 1,
          year: deadline.getUTCFullYear()
        };
      }
    }
  }

  private addBusinessDaysToDate(businessDays: number): Date {
    let counter = 0;
    const deadline = new Date();
    while (businessDays >= 0) {
      deadline.setTime(new Date().getTime() + counter * 86400000);
      if (this.isBusinessDay(deadline)) {
        --businessDays;
      }
      ++counter;
    }
    return deadline;
  }

  private isBusinessDay(date): boolean {
    const dayOfWeek = date.getDay();
    if (dayOfWeek === 0 || dayOfWeek === 6) {
      return false;
    }
    return true;
  }

  get currentState(): TaskRecordStateMachine.State | undefined {
    return this.model.state === undefined ? undefined : this.model.state.id!;
  }

  openCustomerRecordQuickCreateDialog() {
    CustomerRecordQuickCreateMaterialDialogComponent.openDialog(this.dialog,
      {}, (result) => {
        if (result?.customerRecord) {
          this.setCustomerRecord(result.customerRecord);
        }
      });
  }

  setCustomerRecord(customerRecord: CustomerRecord.CustomerRecord) {
    this.customerService.get({
      customerId: customerRecord.customerId
    }).subscribe(c => {
      const addr = !customerRecord.postalAddress
      || !c.managedFields.contains(CustomerRecordFieldType.POSTAL_ADDRESS_AND_INVOICE_ADDRESS_AND_NOTIFICATION_ADDRESS)
        ? ''
        : Address.PostalAddressMapper.toString(customerRecord.postalAddress, this.config.postalAddressFormat);
      const item = {
        id: customerRecord.customerRecordId,
        text: customerRecord.name,
        itemName: customerRecord.name,
        itemSubtitle: addr === '' ? undefined : addr,
        customerRecord: customerRecord,
        address: addr,
        invoiceDeadlineAdditionalDays: customerRecord.invoiceDeadlineAdditionalDays,
        companyId: customerRecord.companyId
      };
      this.customers.push(item);
      this.model.customer = item;
      this.formGroup.controls['customer'].updateValueAndValidity();
      this.loadSelectableAddresses(customerRecord,
        c.managedFields.contains(CustomerRecordFieldType.POSTAL_ADDRESS_AND_INVOICE_ADDRESS_AND_NOTIFICATION_ADDRESS));
      this.loadBillingInfos();
      this.customerRecordChanged.emit();
    });
  };

  getInvoiceAllowedState(): boolean {
    return this.grantedRights.manualInvoiceCreate.hasRight()
      && this.task && this.task.manualInvoiceEnabled
      && this.model.customer !== undefined
      && (this.currentState === 'SUBMITTED' || this.currentState === 'FINISHED');
  }

  isManagedField(field: TaskRecordField): boolean {
    if (this.appTypeHelperService.isHelpdesk()) {
      return this.isHelpdeskManagedField(field);
    }
    return this.task && this.task.managedFields.contains(field);
  }

  private isHelpdeskManagedField(field: TaskRecordField): boolean {
    if (!this.task || !this.task.helpdeskManagedFields) {
      return false;
    }
    return this.task && this.task.helpdeskManagedFields.contains(field);
  }

  isRequiredField(field: TaskRecordField): boolean {
    if (this.appTypeHelperService.isHelpdesk()) {
      return this.isHelpdeskRequiredField(field);
    }
    return this.task && this.task.requiredFields.contains(field);
  }

  private isHelpdeskRequiredField(field: TaskRecordField): boolean {
    if (!this.task || !this.task.helpdeskRequiredFields) {
      return false;
    }
    return this.task && this.task.helpdeskRequiredFields.contains(field);
  }

  validatePlaceOfConsumption(): boolean {
    const requirement: Task.PlaceOfConsumptionRequirement = this.task.placeOfConsumptionRequirement;
    if (requirement === PlaceOfConsumptionRequirement.CONTACT_LOCATION_REQUIRED) {
      if (!this.model.contactLocationId) {
        this.showValidationErrorToast(StringKey.TASK_RECORD_PLACE_OF_CONSUMPTION_CONTACT_LOCATION_REQUIRED);
        return false;
      }
      return true;
    }
    if (requirement === PlaceOfConsumptionRequirement.REQUIRED) {
      if ((!this.selectedAddress || !this.selectedAddress.id)
        && this.model.placeOfConsumption.address.type === PostalAddressModelType.NONE) {
        this.showValidationErrorToast(StringKey.TASK_RECORD_PLACE_OF_CONSUMPTION_REQUIRED);
        return false;
      }
      return true;
    }
    return true;
  }

  private showValidationErrorToast(body?: string) {
    this.toasterService.pop({
      timeout: UiConstants.ToastTimeoutLong,
      type: UiConstants.toastTypeError,
      title: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_TITLE),
      body: this.translateService.instant(body ? body : StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_MESSAGE)
    });
  }

  navigateToProcessDetail(process: MultiselectOptionItem<number>) {
    this.uiRouter.stateService.go(StateName.PROCESS_DETAIL, {id: process.id});
  }

  navigateToCustomerRecordDetail(item: CustomerRecordItem) {
    this.uiRouter.stateService.go(StateName.CUSTOMER_RECORD_DETAIL,
      {customerId: item.customerRecord!.customerId, customerRecordId: item.customerRecord!.customerRecordId});
  }

  navigateToUserGroupDetail(item: MultiselectOptionItem<number>) {
    if (this.isHelpdesk()) {
      return;
    }
    this.uiRouter.stateService.go(StateName.USER_GROUP_DETAIL, {id: item.id});
  }

  navigateToUserDetail(item: OwnerUserItem) {
    if (this.isHelpdesk()) {
      return;
    }
    this.uiRouter.stateService.go(StateName.USER_DETAIL, {id: item.id});
  }

  navigateToProjectDetail(item: ProjectItem) {
    this.uiRouter.stateService.go(StateName.PROJECT_RECORD_DETAIL, {id: item.id, projectId: item.projectId});
  }

  navigateToFileDocument(item: DocumentItem) {
    this.uiRouter.stateService.go(StateName.DOCUMENT_FILE_READ, {id: item.id});
  }

  navigateToLinkDocument(item: DocumentItem) {
    this.uiRouter.stateService.go(StateName.DOCUMENT_LINK_READ, {id: item.id});
  }

  navigateToSurvey(item: MultiselectOptionItem<number>) {
    this.uiRouter.stateService.go(StateName.SURVEY_EDIT, {id: item.id});
  }

  onDeadlineDateChanged() {
    if (!this.model.deadlineTime) {
      this.model.deadlineTime = {
        hour: 0,
        minute: 0,
        second: 0,
        millis: 0
      };
    }
  }

  onAgreedTimeDateChanged() {
    if (!this.model.agreedTimeTime) {
      this.model.agreedTimeTime = {
        hour: 0,
        minute: 0,
        second: 0,
        millis: 0
      };
    }
  }

  openBillingInfoCreateDialog() {
    CustomerRecordBillingInfoBaseDialogComponent.openDialog(this.dialog,
      {
        customerId: this.model.customer ? this.model.customer.customerId! : 0,
        customerRecordId: this.model.customer ? this.model.customer.id! : 0,
        mode: CustomerRecordBillingInfoComponentMode.CREATE
      }, result => {
        if (result) {
          this.model.billingInfo = [];
          this.model.billingInfo.push({
            id: result.id,
            itemName: result.name
          });
        }
      });
  }

  protected readonly AppTypeHelperService = AppTypeHelperService;

  isNameOptional() {
    if (!this.task) {
      return false;
    }
    if (!this.isHelpdesk()) {
      if (this.externalIdRequired) {
        return false;
      }
    }
    return this.task.taskRecordNameTemplate !== undefined;
  }

  isNameEditable() {
    if (this.readonly) {
      return false;
    }
    if (this.isNameOptional() && this.isHelpdesk()) {
      return false;
    }
    return true;
  }

  hideName() {
    return this.isNameOptional() && this.isHelpdesk() && !this.externalIdRequired;
  }
}
