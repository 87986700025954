import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { StockItemType } from '../../../lib/stock/stock-item.service';

@Component({
  selector: 'app-stock-item-create-type-selector-dialog',
  templateUrl: './stock-item-create-type-selector-dialog.component.html',
  styleUrls: ['./stock-item-create-type-selector-dialog.component.scss']
})
export class StockItemCreateTypeSelectorDialogComponent implements OnInit {

  types: {
    type: StockItemType,
    iconClass: string,
    stringKey: string
  }[] = [
    {
      type: 'STOCK',
      iconClass: 'icomoon icomoon-stock',
      stringKey: 'STOCK_ITEM_TYPE_STOCK'
    },
    {
      type: 'SERVICE',
      iconClass: 'icomoon icomoon-sidebar-customers',
      stringKey: 'STOCK_ITEM_TYPE_SERVICE'
    }
  ];

  constructor(
    public dialogRef: MatDialogRef<StockItemCreateTypeSelectorDialogComponent, StockItemType>
  ) {}

  ngOnInit() {}

  closeDialog(type?: StockItemType) {
    this.dialogRef.close(type);
  }

}
