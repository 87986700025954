<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="card">

    <div class="card-header cursor-pointer" (click)="toggleSearch()">
      {{'MENU_NAVBAR_TASK_STATISTICS' | translate}}

      <div class="card-actions">
        <a class="btn-setting" title="{{'COMMON_SHOW_SEARCH_HINT' | translate}}"><i #searchIcon
                                                                                    class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>

    <div class="card-body border-bottom" *ngIf="showSearch">

      <div class="row form-group">
        <div class="col-md-4">
          <label class="search-label">{{'TASK_LOG_SEARCH_TASK_NAME' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'TASK_LOG_SEARCH_TASK_NAME' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.taskName" name="name" id="name" #name="ngModel">
        </div>
        <div class="col-md-4">
          <label class="search-label">{{'AUDIT_LOG_SEARCH_USER_NAME' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'AUDIT_LOG_SEARCH_USER_NAME' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.userProfileName" name="api_operation_definition" id="api_operation_definition"
                 #api_operation_definition="ngModel">
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-12 d-flex justify-content-md-end align-items-end pr-1 pl-0">
          <div class="btn-group" role="group">
            <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
              {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
            </button>
            <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
              {{'COMMON_BUTTON_SEARCH' | translate}}
            </button>
          </div>
        </div>
      </div>

    </div><!-- /.card-body-->

    <div class="card-body">

      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header hidden-1300-down">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="TaskStatistics.OrderField.TASK_NAME"
                                    [orderType]="queryModel.getOrderType(TaskStatistics.OrderField.TASK_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'TASK_LOG_SEARCH_TASK_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="TaskStatistics.OrderField.USER_PROFILE_NAME"
                                    [orderType]="queryModel.getOrderType(TaskStatistics.OrderField.USER_PROFILE_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'AUDIT_LOG_TABLE_HEADER_USER_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-field-sorter [orderField]="TaskStatistics.OrderField.CREATE_COUNT"
                                    [orderType]="queryModel.getOrderType(TaskStatistics.OrderField.CREATE_COUNT)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'TASK_STATISTICS_TABLE_HEADER_CREATE_COUNT' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-field-sorter [orderField]="TaskStatistics.OrderField.START_COUNT"
                                    [orderType]="queryModel.getOrderType(TaskStatistics.OrderField.START_COUNT)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'TASK_STATISTICS_TABLE_HEADER_START_COUNT' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-field-sorter [orderField]="TaskStatistics.OrderField.REJECT_COUNT"
                                    [orderType]="queryModel.getOrderType(TaskStatistics.OrderField.REJECT_COUNT)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'TASK_STATISTICS_TABLE_HEADER_REJECT_COUNT' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-field-sorter [orderField]="TaskStatistics.OrderField.RECALL_COUNT"
                                    [orderType]="queryModel.getOrderType(TaskStatistics.OrderField.RECALL_COUNT)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'TASK_STATISTICS_TABLE_HEADER_RECALL_COUNT' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-field-sorter [orderField]="TaskStatistics.OrderField.FINISH_COUNT"
                                    [orderType]="queryModel.getOrderType(TaskStatistics.OrderField.FINISH_COUNT)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'TASK_STATISTICS_TABLE_HEADER_FINISH_COUNT' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-10">
            <app-table-sorter-no-op [text]="'COMMON_TABLE_HEADER_ACTIONS' | translate"></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let model of taskStatistics | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }">
          <td class="w-5 hidden-1300-down"><app-type-icon [icon]="getTaskIcon(model.taskId)"></app-type-icon></td>
          <td class="responsive-table-column">{{ getTaskName(model.taskId) }}</td>
          <td class="responsive-table-column">{{ getUserName(model.userProfileId) }}</td>
          <td class="responsive-table-column hidden-lg-down">{{ model.createCount }}</td>
          <td class="responsive-table-column hidden-lg-down">{{ model.startCount }}</td>
          <td class="responsive-table-column hidden-lg-down">{{ model.rejectCount }}</td>
          <td class="responsive-table-column hidden-lg-down">{{ model.recallCount }}</td>
          <td class="responsive-table-column hidden-lg-down">{{ model.finishCount }}</td>
          <td class="responsive-table-column">
            <span class="text-nowrap">
                <a class="icons-table-group" uiSref="Admin.TaskStatisticsDetail" [uiParams]="{ taskId: model.taskId, userProfileId: model.userProfileId }">
                  <i class="icomoon icons-table-item icomoon-detail icomoon-detail-table"
                     title="{{'COMMON_BUTTON_DETAILS' | translate}}"></i>
                </a>
            </span>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header hidden-1300-down">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="TaskStatistics.OrderField.TASK_NAME"
                              [orderType]="queryModel.getOrderType(TaskStatistics.OrderField.TASK_NAME)"
                              (orderChange)="orderBy($event)"
                              [text]="'TASK_LOG_SEARCH_TASK_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="TaskStatistics.OrderField.USER_PROFILE_NAME"
                              [orderType]="queryModel.getOrderType(TaskStatistics.OrderField.USER_PROFILE_NAME)"
                              (orderChange)="orderBy($event)"
                              [text]="'AUDIT_LOG_TABLE_HEADER_USER_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-field-sorter [orderField]="TaskStatistics.OrderField.CREATE_COUNT"
                              [orderType]="queryModel.getOrderType(TaskStatistics.OrderField.CREATE_COUNT)"
                              (orderChange)="orderBy($event)"
                              [text]="'TASK_STATISTICS_TABLE_HEADER_CREATE_COUNT' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-field-sorter [orderField]="TaskStatistics.OrderField.START_COUNT"
                              [orderType]="queryModel.getOrderType(TaskStatistics.OrderField.START_COUNT)"
                              (orderChange)="orderBy($event)"
                              [text]="'TASK_STATISTICS_TABLE_HEADER_START_COUNT' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-field-sorter [orderField]="TaskStatistics.OrderField.REJECT_COUNT"
                              [orderType]="queryModel.getOrderType(TaskStatistics.OrderField.REJECT_COUNT)"
                              (orderChange)="orderBy($event)"
                              [text]="'TASK_STATISTICS_TABLE_HEADER_REJECT_COUNT' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-field-sorter [orderField]="TaskStatistics.OrderField.RECALL_COUNT"
                              [orderType]="queryModel.getOrderType(TaskStatistics.OrderField.RECALL_COUNT)"
                              (orderChange)="orderBy($event)"
                              [text]="'TASK_STATISTICS_TABLE_HEADER_RECALL_COUNT' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-field-sorter [orderField]="TaskStatistics.OrderField.FINISH_COUNT"
                              [orderType]="queryModel.getOrderType(TaskStatistics.OrderField.FINISH_COUNT)"
                              (orderChange)="orderBy($event)"
                              [text]="'TASK_STATISTICS_TABLE_HEADER_FINISH_COUNT' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-10">
            <app-table-sorter-no-op [text]="'COMMON_TABLE_HEADER_ACTIONS' | translate"></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>

    </div><!-- /.card-body -->
  </div><!-- /.card-->
</div>
