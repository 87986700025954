import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { OptionItem, SelectUtils, UiConstants } from '../../../util/core-utils';
import { AddressModel } from '../../../lib/address';
import { InputMask } from '../../../util/input-masks';
import { CompanyLocation, CompanyLocationService } from '../../../lib/company-location/company-location.service';
import { NgForm, NgModel } from '@angular/forms';
import { LocalFieldValidationErrors, LocalFieldValidationErrorsFactory, QueryResult } from '../../../lib/util/services';
import { RightModel } from '../../../app.rights';
import { List } from 'immutable';
import { Angular2Multiselects } from '../../../util/multiselect';
import { CompanyLocationEditModel, CompanyLocationFieldErrorMap } from '../company-location-base/company-location-base.component';
import { Company, CompanyService } from '../../../lib/company/company.service';
import { TranslateService } from '@ngx-translate/core';
import { Country, CountryService } from '../../../lib/country.service';
import { ConfigurationService } from '../../../lib/core-ext/configuration.service';
import { RightResolver, RightService } from '../../../lib/right.service';
import { ToasterService } from '../../../fork/angular2-toaster/angular2-toaster';
import { Transition, UIRouter } from '@uirouter/angular';
import { StringKey } from '../../../app.string-keys';
import { TranslateUtils } from '../../../util/translate';
import { FieldError, FieldErrors, ObservableErrorResourceParser } from '../../../lib/util/errors';
import { IdentityMessage } from '../../../lib/util/messages';

@Component({
  selector: 'app-company-location-create-modal',
  templateUrl: './company-location-create-modal.component.html',
  styleUrls: ['./company-location-create-modal.component.scss']
})
export class CompanyLocationCreateModalComponent implements OnInit, AfterViewInit {

  // Company selected in previous step
  @Input()
  companyId?: number;

  // Event emitter to notify parent component of successful company location creation
  @Output()
  private companyLocationCreated = new EventEmitter<number>();

  // If you need to access certain classes from HTML, declare them here
  SelectUtils = SelectUtils;
  AddressModel = AddressModel;
  InputMask = InputMask;
  CompanyLocation = CompanyLocation;
  UiConstants = UiConstants;

  // Declare models before use
  editModel: CompanyLocationEditModel;
  modelLoaded = false;

  // Form for validation
  @ViewChild('f')
  fForm: NgForm;

  // Validated inputs
  @ViewChild('name')
  name: NgModel;

  @ViewChild('externalId')
  externalId: NgModel;

  @ViewChild('company')
  company: NgModel;

  @ViewChild('longitude')
  longitude: NgModel;

  @ViewChild('latitude')
  latitude: NgModel;

  // Field errors for server validation
  fieldErrors: CompanyLocationFieldErrorMap;

  private validatedInputs: LocalFieldValidationErrors<NgModel> =
    LocalFieldValidationErrorsFactory.empty();

  // Right model to store the rights available to the User
  rightModel: RightModel = RightModel.empty();

  // Format of postal address, received from server
  postalAddressFormat: string;

  // Country items for postal address
  countryItems: List<AddressModel.CountryItem>;

  // Settings for the multiselect dropdown
  dropdownSettings: Angular2Multiselects.Settings;

  @ViewChild('companyLocationCreateDialog', { static: true }) companyLocationCreateDialog: ModalDirective;
  companyLocationCreateDialogVisible = true;

  constructor(private uiRouter: UIRouter,
              private transition: Transition,
              private companyService: CompanyService,
              private translateService: TranslateService,
              private countryService: CountryService,
              private configService: ConfigurationService,
              private rightService: RightService,
              private toasterService: ToasterService,
              private companyLocationService: CompanyLocationService,
              private configurationService: ConfigurationService) {
    this.postalAddressFormat = this.configService.getPostalAddressFormat();
    this.fieldErrors = {};
  }

  ngOnInit() {
    this.editModel = new CompanyLocationEditModel();
    this.loadRightModels();
    this.initDropdownSettings();
  }

  ngAfterViewInit() {
  }

  // Loads user rights
  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  private loadCountries(completion: () => void) {
    this.countryService.query({}).subscribe((result: QueryResult<Country.Country>) => {
      this.countryItems = AddressModel.CountryItem.fromCountryList(result.items);
      completion();
    });
  }

  private loadLocalFieldValidationErrors() {
    const validatedInputs = List.of(this.name,
      this.externalId
    );
    this.validatedInputs = LocalFieldValidationErrorsFactory.ofFormFields(this.fForm, validatedInputs);
  }

  private loadSelectedCompany() {
    if (this.companyId) {
      this.companyService.get({
        id: this.companyId
      }).subscribe((company: Company.Company) => {
        const item = new OptionItem<number>();
        item.id = company.id;
        item.text = company.name;
        this.editModel.company.push(item);
      });
    }
  }

  createCompanyLocation() {
    if (this.hasLocalFieldError()) {
      return;
    }
    if (!this.editModel.postalAddress.valid) {
      return;
    }

    this.companyLocationService.create({
      name: this.editModel.name,
      externalId: this.editModel.externalId,
      companyId: this.editModel.company[0].id!,
      gateCrossingRequired: this.editModel.gateCrossingRequired,
      place: {
        postalAddress: this.editModel.postalAddress.toData()!,
        name: this.editModel.name
      }
    }).subscribe(
      (response: IdentityMessage) => {
        this.closeCompanyLocationCreateDialog();
        this.companyLocationCreated.emit(response.id);
        this.fForm.resetForm();
      },
      (error: any) => {
        const res = ObservableErrorResourceParser.parseError(error);
        this.fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
      });
  }

  getSelectedCompanyName(): string {
    if (this.editModel.company.length > 0 && this.editModel.company.length > 0) {
      return this.editModel.company[0]!.text;
    }
    return '';
  }

  showCompanyLocationCreateDialog() {
    this.editModel = new CompanyLocationEditModel();
    if (!this.modelLoaded) {
      this.loadCountries(() => {
        this.editModel.postalAddress.loadCountryItems(this.countryItems, this.configService.getDefaultSelectedCountryCode());
        this.modelLoaded = true;
      });
    }
    else {
      this.editModel.postalAddress.loadCountryItems(this.countryItems, this.configService.getDefaultSelectedCountryCode());
    }
    this.loadSelectedCompany();
    this.companyLocationCreateDialogVisible = true;
    this.companyLocationCreateDialog.show();
  }

  closeCompanyLocationCreateDialog() {
    this.companyLocationCreateDialogVisible = false;
    this.companyLocationCreateDialog.hide();
  }

  onModalHidden() {
    this.fForm.resetForm();
    this.editModel.postalAddress.reset();
  }

  onModalShown() {
    this.loadLocalFieldValidationErrors();
  }

  hasLocalFieldError(field?: NgModel): boolean {
    return this.validatedInputs.hasLocalError(field);
  }

  removeFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.fieldErrors, fieldError);
  }

  // Initializes settings for the multiselect dropdown
  initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .labelKey(OptionItem.KEY_TEXT)
      .enableCheckAll(false)
      .build();
  }

}
