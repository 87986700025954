<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-vertical-padding-half animated fadeIn">
  <div class="container-vertical-padding-half">
    <div class=" col-lg-8 col-md-8 col-xs-12 col-sm-12">
      <div class="card">
        <div class="card-header">
          {{'TASK_STATISTICS_DETAIL_TITLE'|translate}}
        </div>

        <div class="card-body">
          <div class="form-group row mb-0">
            <label class="col-form-label form-control-label col-md-4 detail-title">{{'TASK_LOG_TABLE_HEADER_TASK_RECORD_NAME' | translate}}</label>
            <div class="col-md-8">
              <input readonly type="text" class="form-control detail-description" [(ngModel)]="taskName"
                     name="content" id="content" #content="ngModel" aria-readonly>
            </div>
          </div>
          <div class="form-group row mb-0">
            <label class="col-form-label form-control-label col-md-4 detail-title">{{'TASK_LOG_TABLE_HEADER_USER_NAME' | translate}}</label>
            <div class="col-md-8">
              <input readonly type="text" class="form-control detail-description" [(ngModel)]="userName"
                     name="content" id="content2" #content="ngModel" aria-readonly>
            </div>
          </div>


          <div class="form-group row mb-0">
            <label class="col-form-label form-control-label col-md-4 detail-title">{{'TASK_STATISTICS_TABLE_HEADER_CREATE_COUNT' | translate}}</label>
            <div class="col-md-8">
              <input readonly type="text" class="form-control detail-description" [(ngModel)]="model.createCount"
                     name="createCount" id="createCount" #createCount="ngModel" aria-readonly>
            </div>
          </div>
          <div class="form-group row mb-0">
            <label class="col-form-label form-control-label col-md-4 detail-title">{{'TASK_STATISTICS_TABLE_HEADER_START_COUNT' | translate}}</label>
            <div class="col-md-8">
              <input readonly type="text" class="form-control detail-description" [(ngModel)]="model.startCount"
                     name="startCount" id="startCount" #startCount="ngModel" aria-readonly>
            </div>
          </div>
          <div class="form-group row mb-0">
            <label class="col-form-label form-control-label col-md-4 detail-title">{{'TASK_STATISTICS_TABLE_HEADER_REJECT_COUNT' | translate}}</label>
            <div class="col-md-8">
              <input readonly type="text" class="form-control detail-description" [(ngModel)]="model.recallCount"
                     name="recallCount" id="recallCount" #recallCount="ngModel" aria-readonly>
            </div>
          </div>
          <div class="form-group row mb-0">
            <label class="col-form-label form-control-label col-md-4 detail-title">{{'TASK_STATISTICS_TABLE_HEADER_RECALL_COUNT' | translate}}</label>
            <div class="col-md-8">
              <input readonly type="text" class="form-control detail-description" [(ngModel)]="model.rejectCount"
                     name="rejectCount" id="rejectCount" #rejectCount="ngModel" aria-readonly>
            </div>
          </div>
          <div class="form-group row mb-0">
            <label class="col-form-label form-control-label col-md-4 detail-title">{{'TASK_STATISTICS_TABLE_HEADER_FINISH_COUNT' | translate}}</label>
            <div class="col-md-8">
              <input readonly type="text" class="form-control detail-description" [(ngModel)]="model.finishCount"
                     name="finishCount" id="finishCount" #finishCount="ngModel" aria-readonly>
            </div>
          </div>
          <ng-container *ngIf="showChart">
            <google-chart #chart [data]="pieChartData"></google-chart>
          </ng-container>
        </div>
      </div>

    </div>
  </div>
</div>
