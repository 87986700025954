import { DqlQuery, Query } from '../field';

export class Coordinate {

  readonly latitude: Query.NumberField;

  readonly longitude: Query.NumberField;

  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this.latitude = new DqlQuery.NumberField(prefix + 'latitude');
    this.longitude = new DqlQuery.NumberField(prefix + 'longitude');
  }

}
