<div class="form-group" [formGroup]="formGroup" [ngClass]="{ 'has-danger': hasLocalFieldError('dateValue') || hasLocalFieldError('timeValue')}">
  <label class="form-control-label text-break" [ngClass]="{ 'required-field-label': required }">{{ model.title }}
    <i *ngIf="model.hint !== ''" class="icon icon-info popover-info-icon" title="{{ model.hint }}"></i>
  </label>

  <div class="row">

    <div class="input-group col-md-6 mb-1 mb-md-0">
      <input placeholder="{{ model.placeholder }}" type="text" class="form-control"
             formControlName="dateValue"
             (input)="onInput()"
             (dateSelect)="onSelect()"
             [(ngModel)]="model.dateValue"
             (ngModelChange)="onValueChanged()"
             [readonly]="nonEditable"
             [ngClass]="{ 'form-control-danger': hasLocalFieldError('dateValue') || hasLocalFieldError('timeValue'), 'no-border': nonEditable }"
             ngbDatepicker #date_picker="ngbDatepicker"
             container="body">
      <span *ngIf="!nonEditable" class="add-on input-group-addon-gray" (click)="date_picker.toggle()">
        <i class="icomoon icomoon-calendar"></i>
      </span>
    </div>

    <div class="input-group col-md-6">
      <input placeholder="{{ model.placeholder }}" type="text" class="form-control hidden-value-when-disabled"
             formControlName="timeValue"
             (input)="onInput()"
             (timeSelect)="onSelect()"
             [(ngModel)]="model.timeValue"
             (ngModelChange)="onValueChanged()"
             [readonly]="nonEditable"
             [ngClass]="{ 'form-control-danger': hasLocalFieldError('dateValue') || hasLocalFieldError('timeValue'), 'no-border': nonEditable }"
             [seconds]="false"
             [allowEmptyValue]="false"
             [disabled]="model.dateValue === null"
             ngbTimepicker #time_picker="ngbTimepicker" [toggleButtonId]="'time_picker_toggle_button'"
             [spinners]="true" [hourStep]="1" [minuteStep]="10">
      <span *ngIf="!nonEditable" class="add-on input-group-addon-gray"
            id="time_picker_toggle_button"
            (click)="time_picker.toggle()">
        <i class="icomoon icomoon-time"></i>
      </span>
    </div>

  </div>

  <div class="form-control-feedback" *ngIf="hasLocalFieldError('dateValue') || hasLocalFieldError('timeValue')">
    <span *ngIf="hasLocalFieldError('dateValue', 'required')">{{ 'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate }}</span>
    <span *ngIf="hasLocalFieldError('dateValue', 'validateNgbDateTime') || hasLocalFieldError('timeValue', 'validateNgbDateTime')">{{ 'COMMON_VALIDATION_MESSAGE_INVALID_LOCAL_DATE' | translate }}</span>
    <span *ngIf="hasLocalFieldError('dateValue', 'validateOffsetDateTimeRange')
                  || hasLocalFieldError('timeValue', 'validateOffsetDateTimeRange')">
      <span *ngIf="maxValue.isValid() && minValue.isValid()">
        {{ 'COMMON_VALIDATION_MESSAGE_INVALID_SPECIFIC_DATE_TIME_RANGE' | translate: {min: minValueText, max: maxValueText} }}
      </span>
      <span *ngIf="maxValue.isValid() && !minValue.isValid()">
        {{ 'COMMON_VALIDATION_MESSAGE_INVALID_SPECIFIC_DATE_TIME_RANGE_MAX' | translate: {min: minValueText, max: maxValueText} }}
      </span>
      <span *ngIf="!maxValue.isValid() && minValue.isValid()">
        {{ 'COMMON_VALIDATION_MESSAGE_INVALID_SPECIFIC_DATE_TIME_RANGE_MIN' | translate: {min: minValueText, max: maxValueText} }}
      </span>
    </span>
  </div>
</div>
