<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div class="breadcrumb-menu">
    <div class="breadcrumb-button-icon-container cursor-pointer negative-margin-right"
         *ngIf="rightModel.invoiceSettingsCreate.hasRight()"
         (click)="openCreatePreselectDialog()">
      <a class="btn-setting" title="{{'INVOICE_SETTINGS_CREATE' | translate}}">
        <i class="icomoon icomoon-add"></i>
        <span class="breadcrumb-button-text-visible">{{'INVOICE_SETTINGS_CREATE' | translate}}</span>
      </a>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="card">

    <div class="card-header">
      {{'MENU_NAVBAR_MENU_INVOICE_SETTINGS_LIST' | translate}}
    </div>

    <div class="card-body">

      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="InvoiceSettings.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(InvoiceSettings.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="InvoiceSettings.OrderField.PROFILE_NAME"
                                    [orderType]="queryModel.getOrderType(InvoiceSettings.OrderField.PROFILE_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'INVOICE_SETTINGS_PROFILE_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="InvoiceSettings.OrderField.COMPANY_NAME"
                                    [orderType]="queryModel.getOrderType(InvoiceSettings.OrderField.COMPANY_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'INVOICE_SETTINGS_COMPANY_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-sorter-no-op [text]="'INVOICE_SETTINGS_INTEGRATION_TYPE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let model of list | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }" [disabledTableRow]="model.disabled">
          <td class="responsive-table-column w-5">{{ model.id }}</td>
          <td class="responsive-table-column"><a class="id-text cursor-pointer"
                                                 href="#" onclick="return false"
                                                 uiSref="Admin.InvoiceSettingsDetail"
                                                 [uiParams]="{ id: model.id }"
                                                 title="{{'COMMON_BUTTON_DETAILS' | translate}}">
            {{model.profileName}}
          </a>
            <br/><span class="table-text-subtitle">{{model.externalId}}</span></td>
          <td class="responsive-table-column">{{ model.companyName }}</td>
          <td class="responsive-table-column hidden-md-down w-25">
            <img src="{{getIntegrationTypeIcon(model.invoiceIntegrationType)}}" class="w-100 invoice-integration-icon"
                 alt="{{getIntegrationTypeString(model.invoiceIntegrationType) | translate}}" *ngIf="hasIntegrationTypeIcon(model.invoiceIntegrationType)">
            <span *ngIf="!hasIntegrationTypeIcon(model.invoiceIntegrationType)"> {{getIntegrationTypeString(model.invoiceIntegrationType) | translate}}</span>
          </td>
          <td>
            <app-table-options-menu>
              <app-dropdown-item
                [iconClass]="'icomoon-detail'"
                [titleStringKey]="'COMMON_BUTTON_DETAILS'"
                class="cursor-pointer"
                uiSref="Admin.InvoiceSettingsDetail"
                [uiParams]="{ id: model.id }">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="rightModel.invoiceSettingUpdate.hasRight()"
                [iconClass]="'icomoon-modify'"
                [titleStringKey]="'COMMON_BUTTON_EDIT'"
                class="cursor-pointer"
                uiSref="Admin.InvoiceSettingsEdit"
                [uiParams]="{ id: model.id }">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="model.disabled && rightModel.invoiceSettingDisable.hasRight()"
                [iconClass]="'icomoon-enable'"
                [titleStringKey]="'COMMON_BUTTON_ENABLE'"
                [itemType]="DropdownItemType.ENABLE"
                class="cursor-pointer"
                (click)="setDisabled(model.id, false)">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="!model.disabled && rightModel.invoiceSettingDisable.hasRight()"
                [iconClass]="'icomoon-disable'"
                [titleStringKey]="'COMMON_BUTTON_DISABLE'"
                [itemType]="DropdownItemType.DISABLE"
                class="cursor-pointer"
                (click)="setDisabled(model.id, true)">
              </app-dropdown-item>
            </app-table-options-menu>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="InvoiceSettings.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(InvoiceSettings.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="InvoiceSettings.OrderField.PROFILE_NAME"
                                    [orderType]="queryModel.getOrderType(InvoiceSettings.OrderField.PROFILE_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'INVOICE_SETTINGS_PROFILE_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="InvoiceSettings.OrderField.COMPANY_NAME"
                                    [orderType]="queryModel.getOrderType(InvoiceSettings.OrderField.COMPANY_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'INVOICE_SETTINGS_COMPANY_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-sorter-no-op [text]="'INVOICE_SETTINGS_INTEGRATION_TYPE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>

    </div><!-- /.card-body -->
  </div><!-- /.card-->
</div>

<div bsModal [config]="UiConstants.modalConfig" #createPreSelectDialog="bs-modal" class="modal fade" tabindex="-1"
     role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="createPreselectDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'INVOICE_SETTINGS_CREATE' | translate}}</h4>
        <button type="button" class="close" (click)="closeCreatePreselectDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="container-custom-padding-outer">
        <div class="row m-0">
          <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 container-custom-padding-outer"
               *ngFor="let type of InvoiceSettings.invoiceIntegrationTypes">
            <div class="card card-hover-border cursor-pointer"
                 (click)="create(type.type)">
              <div class="card-body">
                <div *ngIf="type.icon" class="icon-background" [style.background-image]="getSanitizedImageUrl(type.icon)"></div>
                <div *ngIf="!type.icon">{{getIntegrationTypeString(type.type) | translate}}</div>
              </div>
            </div>
          </div>
        </div>
      </div><!-- /.modal-body -->
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary cursor-pointer" (click)="closeCreatePreselectDialog()">
          {{'COMMON_BUTTON_CANCEL' |
          translate}}
        </button>
      </div><!-- /.modal-footer -->
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
