<div id="login-container">

  <!-- region INTRODUCTION COMPONENT-->
  <div class="background hidden-md-down">
    <app-login-register-introduction
      [loginBackgroundSrc]="loginBackgroundSrc">
    </app-login-register-introduction>
  </div>
  <!-- endregion INTRODUCTION COMPONENT-->

  <!-- region CONTENT -->
  <div class="password-reset-form">

    <!-- region LANGUAGE SELECTOR -->
    <div class="d-flex justify-content-end">
      <ng-container *ngFor="let language of languages">
        <div class="ml-1 cursor-pointer"
             *ngIf="!isCurrentLanguage(language)"
             (click)="changeLanguage(language)"
             title="{{language.nativeName}}">
          <img
            src="../../assets/img/language-code-icon/{{language.localeCode.substr(0,2)}}.png"
            class="language-image">
        </div>
      </ng-container>
    </div>
    <!-- endregion LANGUAGE SELECTOR -->

    <!-- region IMAGES -->
    <div class="w-100 logo-panel d-flex justify-content-center">
      <div id="login-logo" class="mx-44 w-500" *ngIf="!brandLogoLoginSrc"></div>
      <img id="login-asset-logo" class="px-44 w-500" *ngIf="brandLogoLoginSrc" src="{{brandLogoLoginSrc}}"/>
    </div>
    <!-- endregion IMAGES -->

    <!-- region FORM -->
    <div class="form-panel d-flex justify-content-center">
      <form class="w-500" name="form" (ngSubmit)="submit()" #f="ngForm" novalidate>
        <div class="card-group mb-0">
          <div class="card p-2 border-0">
            <div class="card-body">
              <h2 class="mb-1">{{titleKey | translate}}</h2>
              <div *ngIf="mode === PasswordResetMode.REQUEST">
                <div class="alert alert-info">{{'PASSWORD_RESET_REQUEST_INFO' | translate}}</div>
                <label>{{'LOGIN_COMPONENT_USERNAME' | translate}}</label>
                <div class="form-group  mb-1" [ngClass]="{ 'has-danger': f.submitted && !username.valid }">
                  <div class="input-group">
                    <input type="text" class="form-control" autocomplete="username"
                           placeholder="{{'LOGIN_COMPONENT_USERNAME' | translate}}"
                           [ngClass]="{ 'form-control-danger': f.submitted && !username.valid }"
                           [(ngModel)]="model.username"
                           id="user"
                           name="user"
                           #username="ngModel"
                           required>
                  </div>
                  <div *ngIf="f.submitted && !username.valid"
                       class="form-control-feedback">{{'LOGIN_COMPONENT_USERNAME_REQUIRED' | translate}}</div>
                </div>
              </div>
              <div *ngIf="mode === PasswordResetMode.CHANGE">
                <label>{{'LOGIN_COMPONENT_PASSWORD' | translate}}</label>
                <div class="form-group mb-1" [ngClass]="{ 'has-danger': f.submitted && !password.valid }">
                  <div class="input-group">
                    <input type="{{passwordVisible ? 'text' : 'password'}}" class="form-control"
                           autocomplete="current-password"
                           placeholder="{{'LOGIN_COMPONENT_PASSWORD' | translate}}"
                           [ngClass]="{ 'form-control-danger': f.submitted && !password.valid }"
                           [(ngModel)]="model.password"
                           id="password"
                           name="password"
                           #password="ngModel"
                           required>
                    <span (click)="togglePasswordVisibility()"
                          class="input-group-addon-gray icomoon icomoon-enable cursor-pointer font-2xl"></span>
                  </div>
                  <div *ngIf="f.submitted && !password.valid"
                       class="form-control-feedback">{{'LOGIN_COMPONENT_PASSWORD_REQUIRED' | translate}}</div>
                </div>
                <label>{{'LOGIN_COMPONENT_CONFIRM_PASSWORD' | translate}}</label>
                <div class="form-group mb-1" [ngClass]="{ 'has-danger': f.submitted && !confirmPassword.valid }">
                  <div class="input-group">
                    <input type="{{passwordVisible ? 'text' : 'password'}}" class="form-control"
                           placeholder="{{'LOGIN_COMPONENT_CONFIRM_PASSWORD' | translate}}"
                           [ngClass]="{ 'form-control-danger': f.submitted && !confirmPassword.valid }"
                           [(ngModel)]="model.confirmPassword"
                           id="confirmPassword"
                           name="confirmPassword"
                           #confirmPassword="ngModel"
                           required
                           validateMatchingPassword>
                    <span (click)="togglePasswordVisibility()"
                          class="input-group-addon-gray icomoon icomoon-enable cursor-pointer font-2xl"></span>
                  </div>
                  <div *ngIf="f.submitted && confirmPassword.errors && confirmPassword.errors['required']"
                       class="form-control-feedback">
                    {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                  </div>
                  <div *ngIf="f.submitted && confirmPassword.errors && confirmPassword.errors['no_match']"
                       class="form-control-feedback">
                    {{'COMMON_VALIDATION_MESSAGE_PASSWORD_MATCH' | translate}}
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row m-0">
                  <button type="submit" class="col-12 btn btn-primary btn-ladda px-2" data-style="zoom-in"
                          [ladda]="working" [disabled]="working">
                    {{submitKey | translate}}
                  </button>
                </div>
              </div>
              <div class="form-group" *ngIf="mode === PasswordResetMode.REQUEST">
                <div class="row m-0">
                  <button type="button" class="col-12 btn btn-outline-primary" (click)="backToLogin()">
                    {{'COMMON_BUTTON_CANCEL' | translate}}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <!-- endregion FORM-->

    <div class="login-footer d-flex justify-content-end mt-auto w-100">
      <div class="d-flex flex-wrap justify-content-end">
        <div class="version-text">{{'ADMIN_VERSION' | translate}}: {{formattedAppVersion}}</div>
        <div class="version-text ml-1">{{'SERVER_VERSION' | translate}}: {{serverVersion}}</div>
      </div>
    </div>

  </div>
</div>

<toaster-container [toasterconfig]="toasterConfig"></toaster-container>
