/* eslint-disable */
import { Component, OnDestroy, } from '@angular/core';
import {
  FormRecordFieldContext,
  FormRecordFieldValueReference,
  FormRecordFieldValueUpdateArgs,
  FormRecordFieldViewContext,
  FormRecordFieldViewModel,
  FormRecordListItemFieldView,
  FormRecordListItemValue,
  SetTmpReadonlyArgs,
  SetTmpReadonlyResult,
} from '../../../../../util/form/form-utils';
import { Form } from '../../../../../lib/form/form.service';
import { AbstractControl, FormBuilder, FormGroup, } from '@angular/forms';
import {
  FormRef,
  ForwardingFormRef,
  LocalFormGroupValidationErrors,
  QueryResult,
} from '../../../../../lib/util/services';
import { FormRecord } from '../../../../../lib/form/form-record.service';
import { AppValidators } from '../../../../../util/app-validators';
import { Models } from '../../../../../util/model-utils';
import { OptionItem, UiConstants } from '../../../../../util/core-utils';
import { TranslateService } from '@ngx-translate/core';
import { RawListItem } from '../../../../../lib/list-item/list-item-resource.service';
import { FieldActivationState, FieldActivationStateResolver, } from '../../../../../util/form/form-editors';
import { Command } from '../../../../../util/command';
import { ofCondition } from '../../../../../util/wait';
import { FormRecordInactivityManager } from '../../manager/form-record-inactivity-manager';
import { Angular2Multiselects } from '../../../../../util/multiselect';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import {
  FieldNotification,
  FormRecordFieldNotifierService
} from '../../../../../lib/form/form-record-field-notifier.service';
import { ListItemMapService } from '../../../../../lib/list-item/list-item-map.service';

/* eslint-enable */

@Component({
  selector: 'app-form-record-list-item-field',
  templateUrl: 'form-record-list-item-field.component.html',
  styleUrls: ['form-record-list-item-field.component.scss'],
})
export class FormRecordListItemFieldComponent implements FormRecordListItemFieldView, OnDestroy {

  get inactive(): boolean {
    return this.tmpReadonly;
  }

  get nonEditable(): boolean {
    return FieldActivationStateResolver.isNonEditable(
      this.fieldActivationState
    );
  }

  get requiredDisabled(): boolean {
    return FieldActivationStateResolver.isRequiredDisabled(
      this.fieldActivationState
    );
  }

  get required(): boolean {
    const optional = this.optionalValue;
    const requiredDisabled = this.requiredDisabled;
    return !optional && !requiredDisabled;
  }


  private get fieldActivationState(): FieldActivationState {
    return FieldActivationStateResolver.resolveFieldActivationState({
      readonlyFormFn: () => this.readonlyFormFn(),
      readonlyFieldFn: () => this.readonlyFieldFn(),
      tmpReadonlyFieldFn: () => this.tmpReadonly,
    });
  }

  private get canApplyDefaultValue(): boolean {
    return FieldActivationStateResolver.canApplyDefaultValue({
      formRecordFieldContext: () => {
        return this.formRecordFieldContext;
      },
      fieldActivationState: () => {
        return this.fieldActivationState;
      },
    });
  }

  private get willBeAbleToApplyDefaultValue(): boolean {
    return FieldActivationStateResolver.willBeAbleToApplyDefaultValue({
      formRecordFieldContext: () => {
        return this.formRecordFieldContext;
      },
      fieldActivationState: () => {
        return this.fieldActivationState;
      },
    });
  }

  private get valueFormControl(): AbstractControl {
    return this.formGroup.get('value')!;
  }

  private get reqContext() {
    return this.formRecordFieldContext!;
  }

  private get reqAttrs(): Form.FieldDataTypeListItemAttributes {
    return this.reqContext.field.dataType.listItemAttributes!;
  }

  private get reqDefaultValue(): ListItemItem | null {
    const id: number | undefined = this.reqAttrs.defaultListItemId;
    if (!id || !this.model.defValue) {
      return null;
    }
    return this.model.defValue;
  }

  public readonly selector: Form.FieldDataTypeSelector.LIST_ITEM;

  private loaded = false;

  formGroup: FormGroup;

  model: Model = new Model(
    () => this.tmpReadonly,
    () => this.willBeAbleToApplyDefaultValue
  );
  valueReference: ValueReference;

  // context fields are always optional
  formRecordFieldContext?: FormRecordFieldContext;
  formRecordInactivityManager?: FormRecordInactivityManager;
  private fieldId?: number;
  private groupId?: number;
  private htmlForm?: FormRef;

  tmpReadonly: boolean = false;

  private optionalValue: boolean = false;

  listItems: ListItemItem[] = [];
  private filterItemIds: number[] = [];

  private formGroupValidationErrors: LocalFormGroupValidationErrors;

  dropdownSettings: Angular2Multiselects.Settings;

  private querySubject: Subject<string> = new Subject<string>();
  private filterNotificationSubscription: Subscription;

  private readonlyFormFn: () => boolean = () => true;
  private readonlyFieldFn: () => boolean = () => false;
  private hiddenFieldFn: () => boolean = () => false;
constructor(fb: FormBuilder,
              private translateService: TranslateService,
              private formRecordFieldNotifierService: FormRecordFieldNotifierService,
              private listItemService: ListItemMapService) {
    this.valueReference = this.createValueReference();
    this.formGroup = this.createFormGroup(fb);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(
      this.createForwardingHtmlForm(),
      this.formGroup
    );
    this.initDropdown(false);
    this.initListItemFilter();
    this.filterNotificationSubscription
      = this.formRecordFieldNotifierService.subscribe(n => this.onFieldNotification(n));
  }

  private initDropdown(disabled: boolean) {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .labelKey(OptionItem.KEY_TEXT)
      .enableCheckAll(true)
      .remoteSearch(true)
      .disabled(disabled)
      .build();
  }

  private equal(a: ListItemItem | null, b: ListItemItem | null): boolean {
    if (a !== null && b === null || a === null && b !== null) {
      // Only one present.
      return false;
    }
    else if (a !== null && b !== null) {
      // Both present.
      return a.id === b.id;
    }
    else {
      // Both absent.
      return true;
    }
  }

  setTmpReadonly(args: SetTmpReadonlyArgs): Command<SetTmpReadonlyResult> {
    let previousTmpReadonly: boolean;
    let previousValue: ListItemItem | null;
    return {
      execute: async () => {
        await ofCondition({
          condition: () => {
            return this.loaded;
          }
        });
        previousTmpReadonly = this.tmpReadonly;
        previousValue = this.model.value;
        const inactiveValue: ListItemItem | null = null;
        const defaultValue: ListItemItem | null = this.reqDefaultValue;
        let changed = false;
        if (this.tmpReadonly !== args.tmpReadonly) {
          if (args.tmpReadonly) {
            changed = FieldActivationStateResolver.inactivationChangesTheValue({
              debugId: this.reqContext.field.title,
              valueIsEmpty: this.equal(this.model.value, inactiveValue),
              valueEqualsDefaultValue: this.equal(this.model.value, defaultValue),
              defaultValueIsEmpty: this.equal(defaultValue, inactiveValue),
              canApplyDefaultValue: this.canApplyDefaultValue
            });
            this.model.value = inactiveValue;
            this.tmpReadonly = args.tmpReadonly; // last
          }
          else {
            this.tmpReadonly = args.tmpReadonly; // first
            this.applyDefaultValue();
          }
          this.valueFormControl.updateValueAndValidity();
        }
        return {
          changed: changed
        };
      },
      undo: async () => {
        await ofCondition({
          condition: () => {
            return this.loaded;
          }
        });
        this.tmpReadonly = previousTmpReadonly;
        this.model.value = previousValue;
        this.valueFormControl.updateValueAndValidity();
      }
    };
  }

  registerField(context: FormRecordFieldContext, originalModel?: any): any {
    if (originalModel) {
      this.model = originalModel;
      this.valueReference = this.createValueReference();
    }
    this.formRecordFieldContext = context;
    this.fieldId = context.field.fieldId;
    this.groupId = context.group.groupId;
    this.htmlForm = context.htmlForm;
    this.readonlyFormFn = context.readonly;
    this.hiddenFieldFn = () => Form.FormFieldValidationType.HIDDEN === context.validationType;
    this.readonlyFieldFn = () => Form.FormFieldValidationType.READONLY === context.validationType
    || Form.FormFieldValidationType.HIDDEN === context.validationType;
    const attrs = context.field.dataType.listItemAttributes!;
    this.optionalValue = Form.FormFieldValidationType.REQUIRED !== context.validationType;
    this.model.title = context.field.title;
    this.model.hint = Models.optToString(context.field.hint);
    this.model.placeholder = Models.optToString(attrs.hint);
    this.model.filterFieldId = attrs.filterFieldId;
    if (this.model.filterFieldId) {
      this.initDropdown(true);
    }
    this.loadFieldData();
    return this.model;
  }

  private loadFieldData() {
    const context = this.reqContext;
    if (this.canApplyDefaultValue) {
      const defaultValue = this.reqAttrs.defaultListItemId;
      this.setValueBeforeLoad(defaultValue);
    }
    if (context.fieldRecord) {
      const dataAttrs = context.fieldRecord.data.listItemAttributes!;
      this.setValueBeforeLoad(dataAttrs.value);
    }
    this.loadListItems();
  }

  private setValueBeforeLoad(id?: number) {
    this.model.prevValueId = id;
  }

  private applyDefaultValue() {
    if (this.canApplyDefaultValue) {
      this.model.value = this.reqDefaultValue;
    }
  }

  registerFieldViews(context: FormRecordFieldViewContext): void {
    this.formRecordInactivityManager = context.inactivityManager;
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  validateWithInterrupt(): boolean {
    return false;
  }

  shouldNotifyAfterCreation(): boolean {
    return false;
  }

  afterFormRecordCreation(formRecordId: number): Observable<any> | undefined {
    return undefined;
  }

  createModel(): FormRecordFieldViewModel {
    if (this.fieldId === undefined) {
      throw new Error('Field ID is undefined');
    }
    const attrs: FormRecord.FieldDataListItemAttributes = {
      value: this.model.value ? this.model.value.id ? this.model.value.id : undefined : undefined
    };
    return {
      fieldEditRequest: {
        fieldId: this.fieldId,
        data: {
          listItemAttributes: attrs
        }
      }
    };
  }

  onManualChange() {
    this.formGroup.controls.value.updateValueAndValidity();
    this.formRecordInactivityManager!.onListItemFieldChangedByUser(this);
    this.onModelChange();
  }

  private createValueReference() {
    return new ValueReference(this.model, () => {
      return this.loaded;
    });
  }

  private createForwardingHtmlForm() {
    return new ForwardingFormRef({
      formFn: () => {
        return this.htmlForm;
      }
    });
  }

  private createFormGroup(fb: FormBuilder) {
    return fb.group(
      {
        value: fb.control(
          {value: this.model.value},
          [
            AppValidators.tempValidator({
              validator: AppValidators.requiredListItem(() => this.model.value),
              disabled: () => {
                return !this.required;
              }
            }),
            AppValidators.validateEnabledItems,
          ]
        )
      }
    );
  }

  private onListItemSearch(q?: string) {
    this.listItemService.getList({
      type_key: this.reqAttrs.listItemTypeKey,
      disabled: false,
      page_number: 1,
      number_of_items: UiConstants.autocompletePageSize,
      text: q,
      filter_item_id: this.filterItemIds && this.filterItemIds.length > 0 ? this.filterItemIds.join(',') : undefined,
      no_progress_bar: true
    }).subscribe((result: QueryResult<RawListItem>) => {
      this.listItems = [];
      result.items.forEach(item => {
        if (item === undefined) {
          return;
        }
        const i = {
          id: item.id,
          text: item.text,
          disabled: item.disabled,
          filterItemIds: item.filter_item_ids
        };
        this.listItems.push(i);
      });
    });
  }

  private loadListItems() {
    this.listItemService.getList({
      type_key: this.reqAttrs.listItemTypeKey,
      disabled: false,
      page_number: 1,
      number_of_items: UiConstants.autocompletePageSize,
      order: 'text',
    })
      .subscribe((result: QueryResult<RawListItem>) => {
        this.listItems = [];
        result.items.forEach(item => {
          if (item === undefined) {
            return;
          }
          const i = {
            id: item.id,
            text: item.text,
            disabled: item.disabled,
            filterItemIds: item.filter_item_ids
          };
          if (!i.disabled) {
            this.listItems.push(i);
          }
          if (this.model.prevValueId && this.model.prevValueId === i.id) {
            this.applyValue(i);
          }
        });
        this.onModelChange();
        const defaultValue = this.reqDefaultValue;
        const defaultValueId = defaultValue === null ? null : defaultValue.id;
        if (!this.loaded && ((this.model.prevValueId && !this.model.value)
          || (this.reqAttrs.defaultListItemId && !defaultValueId))) {
          let idString = '';
          if (this.model.prevValueId && !this.model.value) {
            idString += this.model.prevValueId + ',';
          }
          if (this.reqAttrs.defaultListItemId && !defaultValueId) {
            idString += this.reqAttrs.defaultListItemId + ',';
          }
          this.listItemService.getList({id: idString}).subscribe((result: QueryResult<RawListItem>) => {
            result.items.forEach(item => {
              if (item === undefined) {
                return;
              }
              const i = {
                id: item.id,
                text: item.text,
                disabled: item.disabled,
                filterItemIds: item.filter_item_ids
              };
              if (!i.disabled && this.listItems.findIndex(item => item.id === i.id) === -1) {
                this.listItems.push(i);
              }
              if (this.model.prevValueId === i.id) {
                this.applyValue(i);
              }
              if (this.reqAttrs.defaultListItemId === i.id) {
                this.model.defValue = i;
              }
            });
            this.onModelChange();
            this.loaded = true;
          });
        }
        else {
          this.loaded = true;
        }
      });
  }

  // Used by only the loader function.
  private applyValue(value: ListItemItem | null) {
    if (this.readonlyFieldFn() && this.reqContext.cloning()) {
      // Do not use read-only data for cloning.
      // Note that readonly field is filtered from the service request.
      return;
    }
    this.model.value = value;
  }

  private initListItemFilter() {
    this.querySubject.pipe(debounceTime(UiConstants.autocompleteDebounceTime)).subscribe(q => {
      this.onListItemSearch(q);
    });
  }

  filterListItems(value?: string) {
    this.querySubject.next(value);
  }

  updateValue(data: FormRecordFieldValueUpdateArgs) {
  }

  onModelChange() {
    this.formRecordFieldNotifierService.notify({
      groupId: this.groupId!,
      fieldId: this.fieldId!,
      data: {
        filterItemIds: this.model.value !== null && this.model.value.id !== null ? [this.model.value.id] : undefined
      }
    });
  }

  ngOnDestroy(): void {
    this.formRecordFieldNotifierService.unsubscribe(this.filterNotificationSubscription);
  }

  private onFieldNotification(n: FieldNotification) {
    if (n.data && this.fieldId && n.fieldId === this.model.filterFieldId) {
      this.initDropdown(true);
      if (n.data.filterItemIds === undefined || n.data.filterItemIds.length === 0) {
        this.filterItemIds.splice(0, this.filterItemIds.length);
        this.model.value = null;
        this.initDropdown(true);
      }
      else {
        this.filterItemIds = [...n.data.filterItemIds];
        let newModel: ListItemItem | null = null;
        if (this.model.value !== null) {
          if (this.model.value.filterItemIds) {
            this.model.value.filterItemIds!.forEach(filterItemId => {
              if (this.filterItemIds.find(v => v === filterItemId)) {
                newModel = this.model.value;
              }
            });
          }
        }
        this.model.value = newModel;
        this.initDropdown(false);
        this.onListItemSearch();
      }
    }
  }

}

export class Model {

  private _value: ListItemItem[] = [];
  private _previousValue: ListItemItem | null = null;

  defValue: ListItemItem | null = null;
  filterFieldId?: number;

  get previousValue() {
    return this._previousValue;
  }

  get value(): ListItemItem | null {
    return this._value.length === 0 ? null : this._value[0];
  }

  set value(value: ListItemItem | null) {
    this._previousValue = this.value;
    this._value = [];
    if (value) {
      this._value.push(value);
    }
  }

  get valueArray(): ListItemItem[] {
    return this._value;
  }

  set valueArray(v: ListItemItem[]) {
    this._previousValue = this.value;
    this._value = Array.from(v);
  }

  title: string = '';
  hint: string = '';
  placeholder: string = '';
  prevValueId?: number = undefined;
  minLength: number = 0;
  maxLength: number = 0;

  constructor(
    public readonly tmpReadonly: () => boolean,
    public readonly willBeAbleToApplyDefaultValue: () => boolean) {
  }

}

export class ListItemItem extends OptionItem<number> implements FormRecordListItemValue {
  filterItemIds?: number[] = [];
}

type FormRecordListItemValueType = FormRecordListItemValue | null;

class ValueReference implements FormRecordFieldValueReference<FormRecordListItemValueType> {

  constructor(private readonly model: Model, private readonly loadedRef: () => boolean) {
  }

  async get(): Promise<FormRecordListItemValueType> {
    await ofCondition({
      condition: () => {
        return this.loadedRef();
      }
    });
    if (this.model.tmpReadonly() && this.model.willBeAbleToApplyDefaultValue()) {
      return this.model.defValue;
    }
    return this.model.value;
  }

  set(value: FormRecordListItemValueType) {
    const val: ListItemItem = <ListItemItem>value;
    this.model.value = val;
  }

  previous(): FormRecordListItemValueType {
    return this.model.previousValue;
  }

}
