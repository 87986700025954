import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { TableDocumentContentResource, TableDocumentContentResourceService } from './table-document-content-resource.service';

@Injectable()
export class TableDocumentContentService {

  constructor(private resourceService: TableDocumentContentResourceService) {
  }

  getTableDocumentSchema(request: TableDocumentContent.TableDocumentSchemaGetRequest): any {
    return Observable.create((observer: Observer<any>) => {
      const resourceRequest: TableDocumentContentResource.TableDocumentSchemaGetRequest = {
        schema_code: request.schemaCode,
        version: request.version
      };
      return this.resourceService.getTableDocumentSchema(resourceRequest).subscribe(
        (result: any) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  getTableDocumentContent(request: TableDocumentContent.TableDocumentContentGetRequest): any {
    return Observable.create((observer: Observer<any>) => {
      const resourceRequest: TableDocumentContentResource.TableDocumentContentGetRequest = {
        schema_code: request.schemaCode,
        document_code: request.documentCode,
        version: request.version
      };
      return this.resourceService.getTableDocumentContent(resourceRequest).subscribe(
        (result: any) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

}

export namespace TableDocumentContent {

  export interface TableDocumentSchemaGetRequest {
    schemaCode: string;
    version: number;
  }

  export interface TableDocumentContentGetRequest {
    schemaCode: string;
    documentCode: string;
    version: number;
  }

}
