/* eslint-disable */
import { Observable, Observer } from 'rxjs';
import { MemStorage } from './util/mem-storage';
import { Injectable } from '@angular/core';
import { UiConstants } from '../util/core-utils';
import { MasterDataRecord } from './masterdata/master-data-record.service';
import { Order, OrderType } from './util/services';
import { DisabledItem } from '../util/search-utils';
import { LoggedInUserStorage } from './util/storages';

/* eslint-enable */

@Injectable()
export class MasterdataRecordSearchService {

  constructor() {
  }

  getSearchData(request: MasterdataRecordSearch.SearchDataGetRequest): Observable<MasterdataRecordSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<MasterdataRecordSearch.SearchDataResult>) => {
      try {
        observer.next(storage.read(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  setSearchData(request: MasterdataRecordSearch.SearchDataSetRequest): Observable<MasterdataRecordSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<MasterdataRecordSearch.SearchDataResult>) => {
      try {
        observer.next(storage.save(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  resetSearchData(request: MasterdataRecordSearch.SearchDataGetRequest): Observable<MasterdataRecordSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<MasterdataRecordSearch.SearchDataResult>) => {
      try {
        observer.next(storage.reset(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  private createStorage(): MasterdataRecordSearch.Storage {
    return new MasterdataRecordSearch.Storage();
  }

}

export namespace MasterdataRecordSearch {

  export interface SearchDataGetRequest {
  }

  export interface SearchDataSetRequest {
    searchData: SearchData;
  }

  export interface SearchDataResult {
    searchData: SearchData;
  }

  export interface SearchData {
    itemsPerPage: number;
    pageNumber: number;
    order: Order<MasterDataRecord.OrderField>;
    id: string;
    name: string;
    external_id: string;
    dqlText?: string;
    disabled?: DisabledItem;
  }

  interface StoredSearchRawDataBase {
    version: number;
  }

  interface StoredSearchRawData extends StoredSearchRawDataBase {
    itemsPerPage: number;
    pageNumber: number;
    order: Order<MasterDataRecord.OrderField>;
    id: string;
    name: string;
    external_id: string;
    dqlText?: string;
    disabled?: DisabledItem;
  }

  export class Storage {

    /**
     * Increment this version if you change the SearchData interface.
     */
    private static readonly VERSION: number = 4;

    private static readonly DEFAULT_SEARCH_DATA: SearchData = {
      itemsPerPage: UiConstants.pageNumbers[0],
      pageNumber: 1,
      order: {
        field: MasterDataRecord.OrderField.ID,
        type: OrderType.DESC
      },
      id: '',
      name: '',
      external_id: '',
      dqlText: undefined,
      disabled: DisabledItem.active()
    };

    constructor() {
    }

    public read(request: SearchDataGetRequest): SearchDataResult {
      let rawBase: StoredSearchRawDataBase;
      try {
        const key = this.generateKey();
        const json = MemStorage.getItem(key);
        if (!json) {
          return this.createDefaultResult();
        }
        rawBase = JSON.parse(json);
      }
      catch (error) {
        return this.createDefaultResult();
      }
      if (Storage.VERSION === rawBase.version) {
        const raw: StoredSearchRawData = <StoredSearchRawData>rawBase;
        return this.fromRaw(raw);
      }
      return this.createDefaultResult();
    }

    public reset(request: SearchDataGetRequest): SearchDataResult {
      return this.save({
        searchData: Storage.DEFAULT_SEARCH_DATA,
      });
    }

    public save(request: SearchDataSetRequest): SearchDataResult {
      const key = this.generateKey();
      const raw = this.toRaw(request.searchData);
      const json = JSON.stringify(raw);
      MemStorage.setItem(key, json);
      return this.read(request);
    }

    private generateKey(): string {
      return 'UserId(' + LoggedInUserStorage.getInstance().getUserId() + ')-Search-Masterdata-Record';
    }

    private createDefaultResult(): SearchDataResult {
      return {
        searchData: Storage.DEFAULT_SEARCH_DATA,
      };
    }

    private fromRaw(data: StoredSearchRawData): SearchDataResult {
      return {
        searchData: {
          itemsPerPage: data.itemsPerPage,
          pageNumber: data.pageNumber,
          order: data.order,
          id: data.id,
          name: data.name,
          external_id: data.external_id,
          dqlText: data.dqlText,
          disabled: data.disabled
        }
      };
    }

    private toRaw(data: SearchData): StoredSearchRawData {
      return {
        version: Storage.VERSION,
        itemsPerPage: data.itemsPerPage,
        pageNumber: data.pageNumber,
        order: data.order,
        id: data.id,
        name: data.name,
        external_id: data.external_id,
        dqlText: data.dqlText,
        disabled: data.disabled
      };
    }

  }

}
