/* eslint-disable */
import { FormEditFieldCreateDialogView, FormEditFieldDialogViewFactory, FormEditFieldUpdateDialogView, } from './form-utils';
import { ComponentFactoryResolver, ComponentRef, Injector, } from '@angular/core';
import { FormEditCreateUpdateReadonlyTextFieldDialogComponent } from '../../admin/form/form-edit/dialogs/fields/readonly/text-create-update/form-edit-create-update-readonly-text-field-dialog.component';
/* eslint-enable */

/**
 * ReadonlyText field implementation.
 */
export class FormEditReadonlyTextFieldDialogViewFactory implements FormEditFieldDialogViewFactory {

  constructor(private injector: Injector,
              private cfr: ComponentFactoryResolver) {
  }

  createCreateView(): ComponentRef<FormEditFieldCreateDialogView> {
    return this.createView();
  }

  createUpdateView(): ComponentRef<FormEditFieldUpdateDialogView> {
    return this.createView();
  }

  private createView(): ComponentRef<FormEditCreateUpdateReadonlyTextFieldDialogComponent> {
    return this.cfr.resolveComponentFactory(FormEditCreateUpdateReadonlyTextFieldDialogComponent)
      .create(this.injector);
  }

}
