import {BehaviorSubject, Observable, of} from "rxjs";
import {DocumentGroup, DocumentGroupService} from "../../../lib/document/document-group.service";
import {map} from "rxjs/operators";
import {LazyFileModel} from "../../../lib/util/downloaded-files";

export class DocumentGroupGraph {

  private mainNodes: DocumentGroupGraphNode[];

  private _selectedNodes: DocumentGroupGraphNode[] = [];

  private _currentDocumentGroupLevel: BehaviorSubject<DocumentGroupGraphNode[]> = new BehaviorSubject<DocumentGroupGraphNode[]>([]);

  constructor(private documentGroupService: DocumentGroupService) {
  }

  nodeSelected(node?: DocumentGroupGraphNode) {
    let obs: Observable<DocumentGroupGraphNode[]>;
    if (node) {
      if (this.selectedNode) {
        this.selectedNode.setSelected(false);
      }
      this.selectedNode = node;
      this.selectedNode.setSelected(true);
      obs = this.selectedNode.loadChildren();
    } else {
      this.selectedNode = undefined;
      obs = this.loadMain();
    }
    obs.subscribe(value => {
      this._currentDocumentGroupLevel.next(value);
    });
  }

  loadMain(): Observable<DocumentGroupGraphNode[]> {
    if (this.mainNodes) {
      return of(this.mainNodes);
    }
    return this.documentGroupService.query({
      only_root: true,
      no_progress_bar: true,
      disabled: false,
      order: '+name'
    }).pipe(map(value => {
      const items: DocumentGroupGraphNode[] = [];
      for (let item of value.items) {
        items.push(new DocumentGroupGraphNodeImpl(item, this.documentGroupService));
      }
      this.mainNodes = items;
      return items;
    }));
  }

  canGoBack(): boolean {
    return this.selectedNode !== undefined;
  }

  back() {
    const p = this.selectedNode?.getParentNode();
    this._selectedNodes.pop();
    this.nodeSelected(p);
  }

  home() {
    this.nodeSelected();
  }

  getSelectedId() {
    if (this.selectedNode === undefined) {
      return null
    }
    return this.selectedNode.getData().id;
  }

  get currentDocumentGroupLevel(): Observable<DocumentGroupGraphNode[]> {
    return this._currentDocumentGroupLevel;
  }

  get selectedNodes(): DocumentGroupGraphNode[] {
    return this._selectedNodes;
  }

  private get selectedNode(): DocumentGroupGraphNode | undefined {
    return this._selectedNodes.length > 0 ? this._selectedNodes[this._selectedNodes.length - 1] : undefined;
  }

  private set selectedNode(v: DocumentGroupGraphNode | undefined) {
    if (v) {
      if (!this._selectedNodes.includes(v)) {
        this._selectedNodes.push(v);
      } else {
        while (this.selectedNode !== v) {
          this._selectedNodes.pop();
        }
      }
    } else {
      this._selectedNodes.splice(0);
    }
  }
}

export interface DocumentGroupGraphNode {

  getParentNode(): DocumentGroupGraphNode | undefined;

  isMainGroup(): boolean;

  loadChildren(): Observable<DocumentGroupGraphNode[]>;

  isSelected(): boolean;

  getData(): DocumentGroup;

  setSelected(s: boolean): void;

  hasImage(): boolean;

  loadImage(): void;

  getImage(): LazyFileModel;
}

class DocumentGroupGraphNodeImpl implements DocumentGroupGraphNode {

  private children: DocumentGroupGraphNode[] | undefined;
  private selected: boolean = false;
  private image: LazyFileModel = new LazyFileModel();

  constructor(private data: DocumentGroup,
              private documentGroupService: DocumentGroupService,
              private parentNode?: DocumentGroupGraphNode) {
    this.loadImage();
  }

  getParentNode(): DocumentGroupGraphNode | undefined {
    return this.parentNode;
  }

  isMainGroup(): boolean {
    return this.parentNode === undefined;
  }

  isSelected(): boolean {
    return this.selected;
  }

  loadChildren(): Observable<DocumentGroupGraphNode[]> {
    if (this.children === undefined) {
      return this.documentGroupService.query({
        parent_id: this.data.id!,
        disabled: false,
        order: '+name',
        no_progress_bar: true
      }).pipe(map(value => {
        const items: DocumentGroupGraphNode[] = [];
        for (let item of value.items) {
          items.push(new DocumentGroupGraphNodeImpl(item, this.documentGroupService, this));
        }
        this.children = items;
        return items;
      }));
    } else {
      return of(this.children);
    }
  }

  getData(): DocumentGroup {
    return this.data;
  }

  setSelected(s: boolean): void {
    this.selected = s;
  }

  hasImage(): boolean {
    return this.data.picture !== undefined;
  }

  loadImage(): void {
    if (this.hasImage() && !this.image.downloaded && !this.image.downloading) {
      this.image.download(this.documentGroupService.downloadThumbnail(this.data.id!, true));
    }
  }

  getImage(): LazyFileModel {
    return this.image;
  }

}
