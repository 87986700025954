<div class="modal-content">
  <div class="modal-header">
    <p class="modal-title"><strong>{{'INVOICE_STORNO' | translate}}</strong></p>
    <button type="button" class="close" (click)="closeDialog(false)" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <div class="alert alert-danger">
        <h6 class="alert-title mb-0">{{'INVOICE_LIST_APPROVE_REVERT_MESSAGE' | translate }}</h6>
      </div>
    </div>
    <form (ngSubmit)="save()" #f="ngForm" id="form"
          [formGroup]="formGroup" novalidate>
      <div class="row form-group" [ngClass]="{ 'has-danger': hasLocalFieldError('stornoInvoiceSettings')  || hasFieldError(Invoice.ValidatedField.INVOICE_SETTINGS)}">
        <label class="col-form-label form-control-label col-md-4 detail-title required-field-label">
          {{'INVOICE_STORNO_SETTINGS_MESSAGE' | translate}}
        </label>
        <div class="col-md-8">
          <angular2-multiselect
            (onRemoteSearch)="loadInvoiceSettingsForStorno($event.target.value)"
            [(ngModel)]="model.invoiceSettings"
            (ngModelChange)="loadInvoiceBooksForStorno(); removeFieldError(Invoice.ValidatedField.INVOICE_SETTINGS)"
            [data]="invoiceSettingsForStorno"
            [settings]="dropdownSettings"
            [ngClass]="{ 'form-control-danger': hasLocalFieldError('stornoInvoiceSettings') || hasFieldError(Invoice.ValidatedField.INVOICE_SETTINGS) }"
            id="stornoInvoiceSettings"
            name="stornoInvoiceSettings"
            formControlName="stornoInvoiceSettings"
            required>
          </angular2-multiselect>
          <div class="form-control-feedback"
               *ngIf="hasLocalFieldError('stornoInvoiceSettings')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
          </div>
          <div class="form-control-feedback" *ngIf="hasFieldError(Invoice.ValidatedField.INVOICE_SETTINGS)">
            {{getFieldErrorText(Invoice.ValidatedField.INVOICE_SETTINGS)}}
          </div>
        </div>
      </div>
      <div class="row form-group" [ngClass]="{ 'has-danger': hasLocalFieldError('stornoInvoiceBook') || hasFieldError(Invoice.ValidatedField.INVOICE_BOOK) }"
           *ngIf="model.invoiceSettings.length > 0 && model.invoiceSettings[0].integrationType === InvoiceSettings.InvoiceIntegrationType.APPWORKS">
        <label
          class="col-form-label form-control-label col-md-4 detail-title">{{'INVOICE_STORNO_BOOK_MESSAGE' | translate}}</label>
        <div class="col-md-8">
          <angular2-multiselect
            (onRemoteSearch)="loadInvoiceBooksForStorno($event.target.value)"
            [(ngModel)]="model.invoiceBook"
            (ngModelChange)="removeFieldError(Invoice.ValidatedField.INVOICE_BOOK)"
            [data]="stornoInvoiceBooks"
            [settings]="dropdownSettings"
            [ngClass]="{ 'form-control-danger': hasLocalFieldError('stornoInvoiceBook') || hasFieldError(Invoice.ValidatedField.INVOICE_BOOK) }"
            id="stornoInvoiceBook"
            name="stornoInvoiceBook"
            formControlName="stornoInvoiceBook"
            [required]="model.invoiceSettings.length > 0 && model.invoiceSettings[0].integrationType === InvoiceSettings.InvoiceIntegrationType.APPWORKS">
          </angular2-multiselect>
          <div class="form-control-feedback"
               *ngIf="hasLocalFieldError('stornoInvoiceBook')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
          </div>
          <div class="form-control-feedback" *ngIf="hasFieldError(Invoice.ValidatedField.INVOICE_BOOK)">
            {{getFieldErrorText(Invoice.ValidatedField.INVOICE_BOOK)}}
          </div>
        </div>
      </div>
      <div class="form-group row"
           [ngClass]="{ 'has-danger': hasLocalFieldError('invoiceNumber') || hasFieldError(Invoice.ValidatedField.INVOICE_NUMBER) }"
           *ngIf="integrationType === InvoiceSettings.InvoiceIntegrationType.NONE">
        <label for="invoiceNumber" class="col-form-label form-control-label col-md-4 detail-title required-field-label">
          {{'INVOICE_DETAIL_INVOICE_NUMBER'|translate}}
        </label>
        <div class="col-md-6">
          <div class="mw-100">
            <input type="text"
                   [(ngModel)]="model.invoiceNumber"
                   maxlength="{{getTextMaximumLength()}}"
                   trim="blur"
                   validateOnBlur
                   [validateFormControl]="formGroup.controls['invoiceNumber']"
                   [ngClass]="{ 'form-control-danger': hasLocalFieldError('invoiceNumber') || hasFieldError(Invoice.ValidatedField.INVOICE_NUMBER) }"
                   (ngModelChange)="removeFieldError(Invoice.ValidatedField.INVOICE_NUMBER)"
                   class="form-control"
                   name="invoiceNumber"
                   id="invoiceNumber"
                   formControlName="invoiceNumber"
                   [required]="integrationType === InvoiceSettings.InvoiceIntegrationType.NONE"
                   placeholder="{{'INVOICE_DETAIL_INVOICE_NUMBER'|translate}}">
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('invoiceNumber')">
                      <span
                        *ngIf="hasLocalFieldError('invoiceNumber')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
            </div>
            <div class="form-control-feedback" *ngIf="hasFieldError(Invoice.ValidatedField.INVOICE_NUMBER)">
              {{getFieldErrorText(Invoice.ValidatedField.INVOICE_NUMBER)}}
            </div>
          </div>
        </div>
      </div>
      <div class="row form-group">
        <label class="col-form-label form-control-label col-md-4 detail-title">{{'INVOICE_LIST_APPROVE_REVERT_TASK' |
          translate}}</label>
        <div class="col-md-8">
          <label class="switch switch-text switch-info mb-0">
            <input type="checkbox" class="switch-input"
                   name="taskRevert" id="taskRevert" formControlName="taskRevert"
                   [(ngModel)]="model.taskRevert">
            <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
            <span class="switch-handle"></span>
          </label>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-form-label form-control-label col-md-4 detail-title">{{'INVOICE_STORNO_REASON' |
          translate}}
          <i class="icomoon icomoon-info info-icon" popover="{{'INVOICE_STORNO_REASON_HINT' | translate}}"
             triggers="mouseenter:mouseleave" container="body" placement="right"></i></label>
        <div class="col-md-8">
                <textarea
                  type="text" class="form-control"
                  placeholder="{{'INVOICE_STORNO_REASON' | translate}}"
                  [(ngModel)]="model.commentTemplate"
                  rows="{{UiConstants.textareaDescriptionRowsNumber}}"
                  name="comment"
                  id="comment"
                  [ngModelOptions]="{standalone: true}">
                </textarea>
          <!--Appears in detailView-->
        </div>
      </div>

    </form>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="closeDialog(false)">
      {{'COMMON_BUTTON_CANCEL' | translate}}
    </button>
    <button type="submit" form="form" class="btn btn-primary btn-ladda"
            data-style="zoom-in" [ladda]="inProgress" [disabled]="inProgress">
      {{'COMMON_BUTTON_SAVE' | translate}}
    </button>
  </div>
</div>
