<div class="form-group">
  <label>{{ StringKey.FORM_ITEM_CREATE_CHECKBOX_ENABLE_FIELD_IF_CHECKED | translate }}</label>
  <br>
  <label class="switch switch-text switch-info mb-0">
    <input type="checkbox" class="switch-input" [(ngModel)]="fieldModel.fieldActivationEnabled">
    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
    <span class="switch-handle"></span>
  </label>
</div>

<div class="d-flex justify-content-between" *ngIf="fieldModel.fieldActivationEnabled">
  <label class="font-weight-bold">{{ 'FORM_ITEM_CHECKBOX_FIELDS_TO_ENABLE' | translate }}</label>
  <div class="form-group col-md-1 icon mb-0">
    <a title="{{ StringKey.COMMON_BUTTON_ADD | translate }}" (click)="addFieldRuleItem()">
      <i aria-haspopup="true" class="icomoon icomoon-add" data-toggle="dropdown" role="button"></i>
    </a>
  </div>
</div>
<div *ngIf="fieldModel.fieldActivationEnabled">
  <div *ngFor="let ruleFormGroup of fieldRuleFormGroups; let ruleFormGroupIndex = index">
    <div [formGroup]="ruleFormGroup" class="row">

      <div class="form-group col-md-5" [ngClass]="{ 'has-danger': hasLocalFieldFieldError(ruleFormGroup, RuleFormGroupKey.ACTIVATOR) }">
        <label class="form-control-label">{{ StringKey.FORM_EDIT_LIST_ITEM_ACTIVATOR_LIST_ITEM | translate }}</label>
        <angular2-multiselect
          class="edit-form-input"
          [formControlName]="RuleFormGroupKey.ACTIVATOR"
          [data]="availableListItems"
          [settings]="activatorDropdownSettings">
        </angular2-multiselect>
        <div class="form-control-feedback" *ngIf="hasLocalFieldFieldError(ruleFormGroup, RuleFormGroupKey.ACTIVATOR)">
          <span *ngIf="hasLocalFieldFieldError(ruleFormGroup, RuleFormGroupKey.ACTIVATOR, RuleValidatorKey.REQUIRED_ACTIVATOR)">{{ StringKey.COMMON_VALIDATION_MESSAGE_REQUIRED | translate }}</span>
          <span *ngIf="hasLocalFieldFieldError(ruleFormGroup, RuleFormGroupKey.ACTIVATOR, RuleValidatorKey.UNIQUE_ACTIVATOR)">{{ StringKey.COMMON_VALIDATION_MESSAGE_UNIQUE_KEY | translate }}</span>
        </div>
      </div>

      <div class="form-group col-md-6"[ngClass]="{ 'has-danger': hasLocalFieldFieldError(ruleFormGroup, RuleFormGroupKey.ACTIVATED_FIELDS) }">
        <label class="form-control-label">{{ StringKey.FORM_EDIT_LIST_ITEM_ACTIVATED_FORM_FIELD | translate }}</label>
        <angular2-multiselect
          class="edit-form-input"
          [formControlName]="RuleFormGroupKey.ACTIVATED_FIELDS"
          [data]="availableFields"
          [settings]="activatedFieldDropdownSettings">
        </angular2-multiselect>
        <div class="form-control-feedback" *ngIf="hasLocalFieldFieldError(ruleFormGroup, RuleFormGroupKey.ACTIVATED_FIELDS)">
          <span *ngIf="hasLocalFieldFieldError(ruleFormGroup, RuleFormGroupKey.ACTIVATED_FIELDS, RuleValidatorKey.REQUIRED_ACTIVATED_FIELDS)">{{ StringKey.COMMON_VALIDATION_MESSAGE_REQUIRED | translate }}</span>
        </div>
      </div>

      <div class="form-group col-md-1 icon">
        <a class="button-icon cursor-pointer"
           (click)="removeFieldRuleItem(ruleFormGroupIndex)" title="{{ StringKey.COMMON_BUTTON_DELETE | translate }}">
          <i class="icomoon icomoon-trash"></i>
        </a>
      </div>

    </div>
  </div>
</div>

<div class="d-flex justify-content-between" *ngIf="fieldModel.fieldActivationEnabled">
  <label class="font-weight-bold">{{ 'FORM_ITEM_CHECKBOX_GROUPS_TO_ENABLE' | translate }}</label>
  <div class="form-group col-md-1 icon mb-0">
    <a title="{{ StringKey.COMMON_BUTTON_ADD | translate }}" (click)="addGroupRuleItem()">
      <i aria-haspopup="true" class="icomoon icomoon-add" data-toggle="dropdown" role="button"></i>
    </a>
  </div>
</div>
<div *ngIf="fieldModel.fieldActivationEnabled">
  <div *ngFor="let ruleFormGroup of groupRuleFormGroups; let ruleFormGroupIndex = index">
    <div [formGroup]="ruleFormGroup" class="row">

      <div class="form-group col-md-5" [ngClass]="{ 'has-danger': hasLocalGroupFieldError(ruleFormGroup, RuleFormGroupKey.ACTIVATOR) }">
        <label class="form-control-label">{{ StringKey.FORM_EDIT_LIST_ITEM_ACTIVATOR_LIST_ITEM | translate }}</label>
        <angular2-multiselect
          class="edit-form-input"
          [formControlName]="RuleFormGroupKey.ACTIVATOR"
          [data]="availableListItems"
          [settings]="activatorDropdownSettings">
        </angular2-multiselect>
        <div class="form-control-feedback" *ngIf="hasLocalGroupFieldError(ruleFormGroup, RuleFormGroupKey.ACTIVATOR)">
          <span *ngIf="hasLocalGroupFieldError(ruleFormGroup, RuleFormGroupKey.ACTIVATOR, RuleValidatorKey.REQUIRED_ACTIVATOR)">{{ StringKey.COMMON_VALIDATION_MESSAGE_REQUIRED | translate }}</span>
          <span *ngIf="hasLocalGroupFieldError(ruleFormGroup, RuleFormGroupKey.ACTIVATOR, RuleValidatorKey.UNIQUE_ACTIVATOR)">{{ StringKey.COMMON_VALIDATION_MESSAGE_UNIQUE_KEY | translate }}</span>
        </div>
      </div>

      <div class="form-group col-md-6"[ngClass]="{ 'has-danger': hasLocalGroupFieldError(ruleFormGroup, RuleFormGroupKey.ACTIVATED_GROUPS) }">
        <label class="form-control-label">{{ StringKey.FORM_EDIT_LIST_ITEM_ACTIVATED_FORM_GROUP | translate }}</label>
        <angular2-multiselect
          class="edit-form-input"
          [formControlName]="RuleFormGroupKey.ACTIVATED_GROUPS"
          [data]="availableGroups"
          [settings]="activatedFieldDropdownSettings">
        </angular2-multiselect>
        <div class="form-control-feedback" *ngIf="hasLocalGroupFieldError(ruleFormGroup, RuleFormGroupKey.ACTIVATED_GROUPS)">
          <span *ngIf="hasLocalGroupFieldError(ruleFormGroup, RuleFormGroupKey.ACTIVATED_GROUPS, RuleValidatorKey.REQUIRED_ACTIVATED_FIELDS)">{{ StringKey.COMMON_VALIDATION_MESSAGE_REQUIRED | translate }}</span>
        </div>
      </div>

      <div class="form-group col-md-1 icon">
        <a class="button-icon cursor-pointer"
           (click)="removeGroupRuleItem(ruleFormGroupIndex)" title="{{ StringKey.COMMON_BUTTON_DELETE | translate }}">
          <i class="icomoon icomoon-trash"></i>
        </a>
      </div>

    </div>
  </div>
</div>
