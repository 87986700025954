<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]=breadcrumbParents
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding-half animated fadeIn">
  <form class="form-horizontal bordered-row" (ngSubmit)="updateProcessTask()" #f="ngForm" novalidate>
    <app-legacy-process-task-base
      headingDictionaryKey="PROCESS_TASK_PANEL_HEADING_UPDATE"
      [readonly]="false"
      [allowClone]="true"
      [externalIdRequired]="true"
      [form]="f"></app-legacy-process-task-base>
    <app-form-record-container
      [readonly]="false"
      [cloning]="false"
      [form]="f"></app-form-record-container>
  </form>
</div>
