<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding-half animated fadeIn">
  <div class="container-vertical-padding-half">
    <form class="form-horizontal bordered-row" (ngSubmit)="f.form.valid && create()" #f="ngForm" novalidate>
      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 p-0">
        <div class="card">

          <div class="card-header">
              {{'LIST_ITEM_TYPE_PANEL_HEADING_CREATE'|translate}}
          </div>

          <div class="card-body">

            <div class="form-group row"  [ngClass]="{ 'has-danger': (fieldErrors.key) || (f.submitted && !code.valid) }">
              <div class="col-md-4">
                <label class="mw-100 col-form-label form-control-label required-field-label">
                  {{'LIST_ITEM_CODE' | translate}}
                </label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <input type="text" class="form-control"
                         maxlength="{{UiConstants.maximumVarcharLength}}"
                         [ngClass]="{ 'form-control-danger': (fieldErrors.key) || (f.submitted && !code.valid) }"
                         (ngModelChange)="removeFieldError(fieldErrors.key)"
                         placeholder="{{'LIST_ITEM_CODE' | translate}}" [(ngModel)]="model.key"
                         name="code" id="code" #code="ngModel" required>
                  <div class="form-control-feedback" *ngIf="fieldErrors.key">{{fieldErrors.key.text}}</div>
                  <div class="form-control-feedback" *ngIf="f.submitted && !code.valid">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                </div>
              </div>
            </div>

            <div class="form-group row"  [ngClass]="{ 'has-danger': f.submitted && !orderField.valid }">
              <div class="col-md-4">
                <label class="mw-100 col-form-label form-control-label required-field-label">
                  {{'LIST_ITEM_ORDER_FIELD' | translate}}
                </label>
              </div>
              <div class="col-md-8">
                <angular2-multiselect [data]="model.selectableOrderFields" [settings]="localSingleDropdown"
                                      class="form-control"
                                      [ngClass]="{ 'form-control-danger': f.submitted && !orderField.valid }"
                                      [(ngModel)]="model._orderField"
                                      required
                                      #orderField="ngModel" name="orderField" id="orderField">
                </angular2-multiselect>
              </div>
            </div>
            <div class="form-group row"  [ngClass]="{ 'has-danger': f.submitted && !orderType.valid }">
              <div class="col-md-4">
                <label class="mw-100 col-form-label form-control-label required-field-label">
                  {{'LIST_ITEM_ORDER_TYPE' | translate}}
                </label>
              </div>
              <div class="col-md-8">
                <angular2-multiselect [data]="model.selectableOrderTypes" [settings]="localSingleDropdown"
                                      class="form-control"
                                      [ngClass]="{ 'form-control-danger': f.submitted && !orderType.valid }"
                                      [(ngModel)]="model._orderType"
                                      required
                                      #orderType="ngModel" name="orderType" id="orderType">
                </angular2-multiselect>
              </div>
            </div>
          </div>
        </div>

        <div class="container-vertical-padding-half"></div>

        <div class="card">

          <div class="card-header">
            {{'LIST_ITEM_TYPE_PANEL_HEADING_PARAMS' | translate}}
          </div>
          <div class="card-body">

            <div class="form-group row" *ngIf="model.params.length > 0">
              <div class="col">
                <label class="col-form-label">{{'COMMON_TYPE' | translate}}</label>
              </div>
              <div class="col">
                <label class="col-form-label required-field-label">
                  {{'LIST_ITEM_TYPE_PARAM_KEY' | translate}}
                </label>
              </div>
              <div class="col">
                <label class="col-form-label required-field-label">
                  {{'LIST_ITEM_TYPE_PARAM_DEFAULT_VALUE' | translate}}
                </label>
              </div>
              <div class="col-md-1"></div>
            </div>

            <div class="form-group row"
                 *ngFor="let param of model.params; let i = index">
              <div class="col">
                <select class="form-control"
                        [(ngModel)]="param.paramType" [compareWith]="SelectUtils.compareStrings"
                        name="{{'paramType' + i}}" id="{{'paramType' + i}}">
                  <option *ngFor="let item of paramTypes" [ngValue]="item.id">{{item.text}}</option>
                </select>
              </div>
              <div class="col"
                   [ngClass]="{ 'has-danger': f.submitted && (!paramKey.valid || !isParamKeyUnique(param.key, i)) }">
                <input type="text" class="form-control" placeholder="{{'LIST_ITEM_TYPE_PARAM_KEY' | translate}}"
                       maxlength="{{UiConstants.maximumVarcharLength}}"
                       [ngClass]="{ 'form-control-danger': f.submitted && (!paramKey.valid || !isParamKeyUnique(param.key, i)) }"
                       [(ngModel)]="param.key" name="{{'paramKey' + i}}" id="{{'paramKey' + i}}" #paramKey="ngModel" required>
                <div class="form-control-feedback"
                     *ngIf="f.submitted && !paramKey.valid">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                <div class="form-control-feedback"
                     *ngIf="f.submitted && !isParamKeyUnique(param.key, i)">{{'LIST_ITEM_TYPE_ERROR_PARAMETER_KEY_UNIQUE' | translate}}</div>
              </div>
              <div class="col"
                   [ngClass]="{ 'has-danger': f.submitted && !paramDefValue.valid}">
                <input type="text" class="form-control" placeholder="{{'LIST_ITEM_TYPE_PARAM_DEFAULT_VALUE' | translate}}"
                       maxlength="{{param.paramType === 'STRING' ? UiConstants.maximumVarcharLength : UiConstants.maxInputDoubleLength}}"
                       [ngClass]="{ 'form-control-danger': f.submitted && !paramDefValue.valid }"
                       [(ngModel)]="param.defaultValue"
                       name="{{'defValue' + i}}" id="{{'defValue' + i}}" #paramDefValue="ngModel" required>
                <div class="form-control-feedback"
                     *ngIf="f.submitted && !paramDefValue.valid">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
              </div>
              <div class="col-md-1 cursor-pointer" (click)="deleteParam(i)">
                <i class="icomoon icomoon-trash delete-icon"></i>
              </div>
            </div>

            <input type="button" class="btn btn-primary" (click)="addNewParam()" value="{{'LIST_ITEM_TYPE_ADD_PARAM' | translate}}"/>

          </div> <!-- card block end -->

        </div> <!-- card end -->

        <div class="container-vertical-padding-half"></div>

        <div class="card">

          <div class="card-header">
            {{'LIST_ITEM_TYPE_PANEL_HEADING_FILTER' | translate}}
            <i class="icomoon icomoon-info info-icon" popover="{{'LIST_ITEM_TYPE_FILTER_INFO' | translate}}"
               triggers="mouseenter:mouseleave" container="body" placement="right"></i>
          </div>
          <div class="card-body">

            <angular2-multiselect [data]="filterTypes" [settings]="dropdownSettings"
                                  class="form-control"
                                  [(ngModel)]="model.filterType"
                                  (onRemoteSearch)="loadFilterTypes($event.target.value)"
                                  #filterType="ngModel" name="filterType" id="filterType">
            </angular2-multiselect>

          </div> <!-- card block end -->

        </div> <!-- card end -->

      </div>

      <input type="submit" class="btn btn-primary floating_save_button" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>

    </form>

  </div>
</div>
