/* eslint-disable */
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { DownloadedFile, } from '../util/downloaded-files';
import { Observable } from 'rxjs';
import { FormRecordResource } from '../form/form-record-resource.service';
import { FileAttachmentResource } from '../util/file-attachments';
import { UrlBuilder } from '../../util/url-builder';
import { ResourceQueryResult } from '../util/services';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from '../util/http-services';

/* eslint-enable */

@Injectable()
export class LegacyProcessTaskResourceService extends BaseHttpService {

  // <editor-fold desc="CRUD">

  query(request: LegacyProcessTaskResource.QueryRequest): Observable<ResourceQueryResult<LegacyProcessTaskResource.LegacyProcessTask>> {
    return this.http.get<ResourceQueryResult<LegacyProcessTaskResource.LegacyProcessTask>>
    (this.url + `tasks?process_id=${request.process_id}&with_form_record=${request.with_form_record}`, this.parseParams(request));
  }

  get(request: LegacyProcessTaskResource.GetRequest): Observable<LegacyProcessTaskResource.LegacyProcessTask> {
    return this.http.get<LegacyProcessTaskResource.LegacyProcessTask>
    (this.url + `${request.process_id}/tasks/${request.process_task_id}`, this.parseParams(request));
  }

  update(request: LegacyProcessTaskResource.UpdateRequest): Observable<LegacyProcessTaskResource.LegacyProcessTask> {
    return this.http.put<LegacyProcessTaskResource.LegacyProcessTask>
    (this.url + `${request.process_id}/tasks/${request.process_task_id}?with_form_record=${request.with_form_record}`, request);
  }

  setDisabled(request: LegacyProcessTaskResource.AssigneeChangeRequest): Observable<LegacyProcessTaskResource.LegacyProcessTask> {
    return this.http.patch<LegacyProcessTaskResource.LegacyProcessTask>
    (this.url + `${request.process_id}/tasks/${request.process_task_id}/assignee`, request);
  }

  getAttachments(request: LegacyProcessTaskResource.GetAttachmentRequest): Observable<Array<FileAttachmentResource>> {
    return this.http.get<Array<FileAttachmentResource>>
    (this.url + `${request.process_id}/tasks/${request.process_task_id}/attachments`, this.parseParams(request));
  }

  downloadAttachment(request: LegacyProcessTaskResource.DownloadAttachmentRequest): Observable<DownloadedFile> {
    const url = UrlBuilder.create('processes/:processId/tasks/:processTaskId/attachments/:attachmentId')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .namedNumber('processId', request.processId)
      .namedNumber('processTaskId', request.processTaskId)
      .namedNumber('attachmentId', request.attachmentId)
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob'
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  // </editor-fold>

  constructor(private http: HttpClient, private resourceHelper: ResourceHelper) {
    super(resourceHelper, '/legacy-processes/');
  }

}

export namespace LegacyProcessTaskResource {

  // <editor-fold desc="CRUD">

  export interface LegacyProcessTask {
    process_task_id: number;
    process_id: number;
    workflow_task_id: number;
    workflow_id: number;
    external_id: string;
    state: string;
    name: string;
    display_name: string;
    assignee_user_id?: number;
    description?: string;
    form_record?: FormRecordResource.FormRecord; // non-null if requested
  }

  export interface QueryRequest {
    with_form_record: boolean;
    process_id: number;
  }

  export interface GetRequest {
    with_form_record: boolean;
    process_task_id: number;
    process_id: number;
  }

  export interface UpdateRequest {
    with_form_record: boolean;
    process_id: number;
    process_task_id: number;
    external_id?: string;
    name: string;
    description?: string;
    assignee_user_id: number | null;
    form_record: FormRecordResource.FormRecordUpdateRequest;
  }

  export interface AssigneeChangeRequest {
    process_task_id: number;
    process_id: number;
    assignee_user_id: number | null;
  }

  export interface GetAttachmentRequest {
    process_id: number;
    process_task_id: number;
  }

  export interface DownloadAttachmentRequest {
    processId: number;
    processTaskId: number;
    attachmentId: number;
  }

  // </editor-fold>

}
