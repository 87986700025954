import { Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SearchUtils } from '../../../../util/search-utils';
import { InvoiceSpendingSearch, InvoiceSpendingSearchService } from '../../../../lib/invoice/spending/invoice-spending-search.service';
import { OrderFieldFunction, OrderFieldModel, SelectUtils, UiConstants } from '../../../../util/core-utils';
import { BadgeStyle } from '../../../../shared/table-badge/badge-style';
import { InputMask } from '../../../../util/input-masks';
import { OrderField } from '../../../../lib/query/orderfields';
import { OrderType, QueryResult } from '../../../../lib/util/services';
import { Angular2Multiselects } from '../../../../util/multiselect';
import { RightModel } from '../../../../app.rights';
import { BreadcrumbParent } from '../../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { InvoiceSpending, InvoiceSpendingService } from '../../../../lib/invoice/spending/invoice-spending.service';
import { GrantedPermissionSetResolver, RightResolver, RightService } from '../../../../lib/right.service';
import { MatDialog } from '@angular/material/dialog';
import { UIRouter } from '@uirouter/core';
import { InvoiceSpendingListModel } from './invoice-spending-list.model';
import { InvoiceSpendingRightModel } from '../../../../lib/invoice/spending/invoice-spending-right.model';
import { FilterField } from '../../../../lib/query/filterfields';
import { CriteriaBuilder, Models } from '../../../../util/model-utils';
import { InvoiceSpendingDialogComponent } from '../invoice-spending-dialog/invoice-spending-dialog.component';
import { InvoiceBalanceDetailComponent } from '../../invoice-balance/invoice-balance-detail/invoice-balance-detail.component';
import { Currency, CurrencyService } from '../../../../lib/currency.service';
import { Invoice } from '../../../../lib/invoice/invoice/invoice.service';

@Component({
  selector: 'app-invoice-spending-list',
  templateUrl: './invoice-spending-list.component.html',
  styleUrls: ['./invoice-spending-list.component.scss']
})
export class InvoiceSpendingListComponent extends SearchUtils.SearchableList<InvoiceSpendingSearch.Model>
  implements OnInit, OnDestroy {

  UiConstants = UiConstants;
  BadgeStyle = BadgeStyle;
  InputMask = InputMask;
  SelectUtils = SelectUtils;
  InvoiceSpendingSearch = InvoiceSpendingSearch;

  queryModel: OrderFieldModel<OrderField.InvoiceSpending>
    = new OrderFieldModel(InvoiceSpendingSearch.OrderFunctions.ID, OrderType.DESC);
  searchModel: InvoiceSpendingSearch.Model = new InvoiceSpendingSearch.Model();

  dropdownSettings: Angular2Multiselects.Settings;

  invoiceSpendingList: InvoiceSpendingListModel[] = [];
  spendingTypes: InvoiceSpendingSearch.SpendingTypeFilterOption[] = [];
  rightModel: RightModel = RightModel.empty();
  currencies: Currency.Currency[] = [];

  breadcrumbSelf: string;
  breadcrumbParents: BreadcrumbParent[] = [];
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  @ViewChild(InvoiceBalanceDetailComponent)
  invoiceBalanceComponent: InvoiceBalanceDetailComponent;


  constructor(private invoiceSpendingService: InvoiceSpendingService,
              private invoiceSpendingSearchService: InvoiceSpendingSearchService,
              private rightService: RightService,
              private dialog: MatDialog,
              private currencyService: CurrencyService,
              private uiRouter: UIRouter,
              injector: Injector) {
    super(InvoiceSpendingSearch.Model, injector);
  }

  ngOnInit() {
    this.loadRightModels();
    this.initBreadcrumb();
    this.initSearch();
    this.initDropdownSettings();
    this.loadCurrencies();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  initBreadcrumb() {
    this.translateService.get('MENU_NAVBAR_NAV_TITLE_INVOICE_SPENDING').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
  }

  initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .remoteSearch(true)
      .enableCheckAll(false)
      .build();
  }

  loadSearch(completion: () => void) {
    this.invoiceSpendingSearchService.getSearchData({})
      .subscribe(
        (result: InvoiceSpendingSearch.SearchDataResult) => {
          this.queryModel = result.searchData.queryModel;
          this.searchModel = result.searchData.searchModel;
          completion();
        }
      );
  }

  onFirstSearchOpen(): void {
    this.loadSpendingTypeOptions();
  }

  private loadSpendingTypeOptions() {
    this.spendingTypes = [];
    this.spendingTypes.push(InvoiceSpendingSearch.Model.defaultSelectedState);
    InvoiceSpending.spendingTypes.forEach((stateObject) => {
      this.spendingTypes.push({
        id: stateObject.spendingType,
        stringKey: stateObject.stringKey
      });
    });
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: OrderFieldFunction<OrderField.InvoiceSpending>) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const rights = undefined;
    const order = this.queryModel.createOrderFunction();
    const filter = this.createFilter();
    this.invoiceSpendingService.query({
      fields: f => f.forList,
      order: order,
      filter: filter,
      rights: rights,
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined,
    }).subscribe((result: QueryResult<InvoiceSpending.InvoiceSpending>) => {
      this.invoiceSpendingList = [];
      result.items.forEach((invoiceSpending: InvoiceSpending.InvoiceSpending) => {
        const model = new InvoiceSpendingListModel();
        model.id = invoiceSpending.id;
        model.rights = new InvoiceSpendingRightModel(GrantedPermissionSetResolver.byGrantedRights(invoiceSpending.grantedRights));
        model.creationTime = invoiceSpending.creationTime;
        model.updateTime = invoiceSpending.updateTime;
        model.spendingNumber = invoiceSpending.spendingNumber;
        model.invoiceSpendingType = invoiceSpending.invoiceSpendingType;
        const invoiceSpendingTypeObject = InvoiceSpending.spendingTypes
          .find(invoiceSpendingType => invoiceSpendingType.spendingType === invoiceSpending.invoiceSpendingType);
        if (invoiceSpendingTypeObject) {
          model.invoiceSpendingTypeObject = invoiceSpendingTypeObject;
        }
        model.eventName = invoiceSpending.eventName;
        model.comment = invoiceSpending.comment;
        model.amount = Math.floor(invoiceSpending.amount);
        model.currencyCode = invoiceSpending.currencyCode;
        model.creatorUser = invoiceSpending.user!;
        model.invoices = invoiceSpending.invoices?.toArray();
        this.invoiceSpendingList.push(model);
      });
      this.queryModel.currentPage = requestedPage;
      this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
    });
  }

  private createFilter() {
    return (f: FilterField.InvoiceSpending) => CriteriaBuilder.builder()
      .addNumber((id) => f.id.eq(id), this.searchModel.id)
      .addString((spendingNumber) => f.spendingNumber.containsIgnoreCase(spendingNumber), this.searchModel.spendingNumber)
      .addString((eventName) => f.eventName.containsIgnoreCase(eventName), this.searchModel.eventName)
      .addDateTime((creationTime) => f.creationTime.before(creationTime), Models.parseDateTimeTo(this.searchModel.creationTimeTo))
      .addDateTime((creationTime) => f.creationTime.after(creationTime), Models.parseDateTimeFrom(this.searchModel.creationTimeFrom))
      .addEnum((spendingType) => f.invoiceSpendingType.eq(spendingType), this.searchModel.invoiceSpendingType.id)
      .build();
  }

  onSearchClicked() {
    this.loadList(1);
  }

  onSearchReset() {
    this.invoiceSpendingSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.toggleSearch();
          this.loadList(1);
        });
      }
    );
  }


  ngOnDestroy(): void {
    this.saveSearch();
  }

  private saveSearch() {
    const request = {
        searchData: {
          queryModel: this.queryModel,
          searchModel: this.searchModel
        }
      }
    ;
    this.invoiceSpendingSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  openInvoiceSpendingDetailDialog(id: number) {
    const dialogRef = this.dialog.open(InvoiceSpendingDialogComponent, {
      width: '60%',
      data: {readonly: true, invoiceSpendingId: id}
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.modified) {
        this.loadList();
        this.invoiceBalanceComponent.loadBalance();
      }
    });
  }

  openInvoiceSpendingCreateDialog() {
    const dialogRef = this.dialog.open(InvoiceSpendingDialogComponent, {
      width: '60%',
      data: {readonly: false}
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.modified) {
        this.loadList();
        this.invoiceBalanceComponent.loadBalance();
      }
    });
  }

  private loadCurrencies() {
    this.currencies = [];
    this.currencyService.query({}).subscribe((result: QueryResult<Currency.Currency>) => {
      result.items.forEach(c => {
        if (c) {
          this.currencies.push(c);
        }
      });
    });
  }

  getCurrencyName(currencyCode: string) {
    const cc = this.currencies.find(c => c.currencyCode === currencyCode);
    return cc ? cc.localizedName : currencyCode;
  }

  getInvoiceNumbers(invoices: Invoice.Invoice[]) {
    return invoices.map(i => i.invoiceNumber).join(', ');
  }
}
