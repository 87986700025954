import {
  AfterViewInit,
  Component,
  DoCheck,
  ElementRef,
  ErrorHandler,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { RightResolver, RightService } from '../../lib/right.service';
import { ConfigurationService } from '../../lib/core-ext/configuration.service';
import { StateName } from '../../app.state-names';
import { UIRouter } from '@uirouter/angular';
import { RightModel } from '../../app.rights';
import { ResourceHelper } from '../../lib/util/http-services';
import { SidebarNavItemUtils } from '../../shared/sidebar-nav-item/sidebar-nav-item-utils';
import SidebarNavItemModel = SidebarNavItemUtils.SidebarNavItemModel;
import { SidebarTogglerService } from '../../lib/util/sidebar-toggler.service';
import {
  LocalStorages,
  LoginRequiredReason,
  LoginRequiredReasonStorage,
  ServiceErrorStorage
} from '../../lib/util/storages';
import { toasterConfig } from '../../fork/angular2-toaster/src/toaster-config';
import { GlobalErrorHandler } from '../../util/global-error-handler';
import { RuntimeConfiguration } from '../../lib/runtime-configuration';
import { environment } from '../../../environments/environment';
import { UiConstants } from '../../util/core-utils';
import { Subscription } from 'rxjs';
import { ToasterService } from '../../fork/angular2-toaster/src/toaster.service';
import { ToasterContainer } from '../../shared/toaster-container';
import { LoadingHandler } from '../../lib/loading-handler';
import {AuthResult, AuthService, UserProfileType} from '../../lib/auth.service';
import { UserData, UserDataLoader, UserDataLoaderPermissionDeniedStrategy } from '../../lib/user-data-loader';
import { DownloadedFile } from '../../lib/util/downloaded-files';
import { PasswordFieldErrorMap, UserService } from '../../lib/user.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PasswordChangeModel } from '../../admin/admin-layout.component';
import { EmptyMessage } from '../../lib/util/messages';
import { ObservableErrorResourceParser, ObservableErrorResponse } from '../../lib/util/errors';
import { AppValidators } from '../../util/app-validators';
import { MatDialog } from '@angular/material/dialog';
import { HelpdeskBugReportTypeSelectorDialogComponent } from '../bug-report/type-selector/type-selector-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { LoggerFactory } from '../../util/logger-factory';

@Component({
  selector: 'app-helpdesk-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class HelpdeskLayoutComponent implements OnInit, AfterViewInit, DoCheck, OnDestroy, ToasterContainer.View {

  toasterConfig = toasterConfig;
  UiConstants = UiConstants;

  @ViewChild('brandLogoElement') brandLogoElement: ElementRef;
  sidebarClosed = false;

  @ViewChild('changePasswordDialog', { static: true })
  changePasswordDialog: ModalDirective;
  passwordChangeForm: FormGroup;
  changePasswordDialogVisible: boolean = false;
  passwordVisible: boolean = false;
  passwordModel: PasswordChangeModel = new PasswordChangeModel();
  passwordFieldError: PasswordFieldErrorMap;

  rightModel: RightModel = RightModel.empty();

  navItems: SidebarNavItemModel[] = [];

  userDataToDisplay: UserDataToDisplay = new UserDataToDisplay();

  networkLoading: boolean = false;
  private errorSubscription?: Subscription;

  get brandLogoSrc(): string | undefined {
    return this.resourceHelper.getBrandLogoSrc();
  }

  get brandLogoSmallSrc(): string | undefined {
    return this.resourceHelper.getBrandLogoSmallSrc();
  }

  private readonly logger = LoggerFactory.createLogger('HelpdeskLayoutComponent');

  constructor(
    private router: UIRouter,
    private configService: ConfigurationService,
    private globalErrorHandler: ErrorHandler,
    private resourceHelper: ResourceHelper,
    private toasterService: ToasterService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private userDataLoader: UserDataLoader,
    private dialog: MatDialog,
    private sidebarTogglerService: SidebarTogglerService,
    private rightService: RightService,
    private translateService: TranslateService,
  ) {
    this.checkConfiguration();
    this.updateHelpdeskTranslations();
    this.passwordFieldError = {};
    this.passwordChangeForm = formBuilder.group({
      current_password: [[], Validators.required],
      password: [[], Validators.required],
      confirm_password: [[], Validators.required, AppValidators.validateMatchingPasswordPromise]
    });
  }

  private checkConfiguration() {
    if (!this.configService.loaded) {
      localStorage.setItem('configurationError', 'true');
      ServiceErrorStorage.getInstance().setStateObject({
        stateName: this.router.stateService.$current.name,
        params: this.router.stateService.params
      });
      this.router.stateService.go(StateName.CONFIGURATION_LOAD_ERROR);
    }
  }

  private updateHelpdeskTranslations() {
    const lang = this.translateService.currentLang;
    this.translateService.getTranslation(lang).pipe(take(1)).subscribe(result => {
      const context = this;
      const xhr = new XMLHttpRequest();
      xhr.open('GET', '../../../assets/strings/helpdesk/' + lang + '.json');
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.onload = function() {
        if (xhr.status === 200) {
          try {
            const data = JSON.parse(xhr.responseText);
            Object.keys(data).forEach(p => {
              context.translateService.set(p, data[p], lang);
            });
          }
          catch (e) {
            context.logger.error(e);
          }
        }
        else {
          context.logger.error('Failed loading translations...');
        }
      };
      xhr.onerror = function() {
        context.logger.error('Failed loading translations...');
      };
      xhr.send();
    });
  }

  ngOnInit(): void {
    this.addUncaughtExceptionListener();
    this.loadRightModels();
    const self = this;
    ToasterContainer.ViewReference.set(this);
    LoadingHandler.getInstance().setOnStartStopListener({
      onStart() {
        self.networkLoading = true;
      },
      onStop() {
        self.networkLoading = false;
      }
    });
    LoadingHandler.getInstance().setOnRefreshListener({
      onRefresh() {
        self.refreshMenuData();
      }
    });
  }

  ngAfterViewInit(): void {
    this.initSidebar();
    const context = this;
    this.router.transitionService.onSuccess({}, function (transition) {
      context.activeStateChanged(transition.from().name!, transition.to().name!);
      context.scrollToTop();
    });
    this.refreshMenuData();
  }

  private initSidebar() {
    const bodyElement = document.querySelector('body');
    const headerElement = document.querySelector('header');
    const breadcrumbElement = document.getElementById('breadcrumb');
    const formEditorCardContainerElement = document.getElementById('form-editor-card-container');
    const logo = document.getElementById('navbar-brand');
    const brandLogo = document.getElementById('navbar-asset-brand');
    if (bodyElement) {
      bodyElement.classList.add('sidebar-helpdesk');
      bodyElement.classList.add('header-helpdesk');
      if (bodyElement.classList.contains('sidebar-compact')) {
        headerElement!.classList.add('app-header-compact');
        if (breadcrumbElement) {
          breadcrumbElement.classList.add('breadcrumb-container-compact');
        }
        if (formEditorCardContainerElement) {
          formEditorCardContainerElement.classList.add('form-editor-card-container-compact');
        }
        if (logo) {
          logo!.classList.add('navbar-brand-small');
        }
        if (brandLogo) {
          brandLogo!.classList.add('navbar-brand-small');
        }
      }
    }
  }

  ngDoCheck() {
    if (this.brandLogoElement && this.brandLogoElement.nativeElement.classList.contains('navbar-brand-small')) {
      this.sidebarClosed = true;
    }
    else {
      this.sidebarClosed = false;
    }
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe((resolver: RightResolver) => {
      this.rightModel = RightModel.of(resolver);
      this.navItems = SidebarNavItemUtils.createHelpdeskSidebarNavItems(this.rightModel);
      // hack to let the ngIf directive show the menu elements before apply styles
      setTimeout(() => {
        this.activeStateChanged(this.router.stateService.$current.name, this.router.stateService.$current.name);
      });
      this.refreshMenuData();
    });
  }

  private activeStateChanged(from: string, to: string) {
    this.getNavItem(from).activeSubject.next(false);
    this.getNavItem(to).activeSubject.next(true);
  }

  private getNavItem(state: string): SidebarNavItemModel {
    for (let i = 0; i < this.navItems.length; i++) {
      if (this.navItems[i].states.includes(state)) {
        return this.navItems[i];
      }
    }
    return this.navItems[0];
  }

  private scrollToTop() {
    window.scrollTo(0, 0);
  }

  private addUncaughtExceptionListener() {
    if (this.globalErrorHandler instanceof GlobalErrorHandler) {
      this.errorSubscription = this.globalErrorHandler.events.subscribe(e => {
        if (!this.isErrorToastActive()) {
          return;
        }
        this.toasterService.pop({
          type: UiConstants.toastTypeError,
          timeout: UiConstants.ToastTimeoutShort,
          title: 'Background error',
          body: 'Unexpected error happened in the background.'
        });
      });
    }
  }

  private removeUncaughtExceptionListener() {
    if (this.errorSubscription) {
      this.errorSubscription.unsubscribe();
    }
  }

  private isErrorToastActive() {
    return RuntimeConfiguration.getInstance().getShowBackgroundErrors(environment.showBackgroundErrors);
  }

  getToastTimeout(): number {
    const eatThis: any = toasterConfig.timeout;
    return eatThis;
  }

  setToastTimeout(timeout: number) {
    toasterConfig.timeout = timeout;
  }

  refreshMenuData() {
    if (this.rightModel.userRead.hasRight()) {
      this.loadUserData();
    }
  }

  private loadUserData() {
    this.authService.check({})
      .subscribe(
        (authResult: AuthResult) => {
          const profile = authResult.user_profile;
          if (profile) {
            this.userDataLoader.loadMe(profile, UserDataLoaderPermissionDeniedStrategy.HANDLE_ALL).subscribe(
              (userData: UserData) => {
                this.initUserData(userData);
              },
              (error: any) => {
                LoginRequiredReasonStorage.getInstance().setReason(LoginRequiredReason.UNKNOWN_USER, {
                  stateName: this.router.stateService.$current.name,
                  params: this.router.stateService.params
                });
                this.onLogout();
              }
            );
          }
          else {
            LoginRequiredReasonStorage.getInstance().setReason(LoginRequiredReason.NO_PROFILE_AVAILABLE, {
              stateName: this.router.stateService.$current.name,
              params: this.router.stateService.params
            });
            this.onLogout();
          }
        }
      );
  }

  private initUserData(userData: UserData) {
    this.userDataToDisplay.id = userData.id;
    this.userDataToDisplay.userName = userData.user_name;
    this.userDataToDisplay.type = userData.type;
    this.userDataToDisplay.personName = userData.person_name;
    this.loadProfilePicture(userData.id);
  }

  private loadProfilePicture(userId: number) {
    this.userService.downloadProfilePicture(userId).subscribe(
      (res: DownloadedFile) => {
        this.userDataToDisplay.profilePicture = URL.createObjectURL(res.getBlob());
      },
      () => {
      }
    );
  }

  logout() {
    this.authService.logout({})
      .subscribe(
        (result: any) => {
          this.onLogout();
        },
        (error: any) => {
          this.onLogout();
        }
      );
  }

  private onLogout() {
    LocalStorages.onLogout();
    this.router.stateService.go(StateName.HELPDESK_LOGIN);
  }

  changePassword() {
    if (!this.passwordChangeForm.valid) {
      this.passwordChangeForm.controls['current_password'].markAsTouched();
      this.passwordChangeForm.controls['password'].markAsTouched();
      this.passwordChangeForm.controls['confirm_password'].markAsTouched();
      return;
    }
    this.userService.updatePassword({
      id: this.userDataToDisplay.id!,
      new_password: this.passwordModel.password,
      current_password: this.passwordModel.prevPassword
    })
      .subscribe(
        (response: EmptyMessage) => {
          this.passwordModel = new PasswordChangeModel();
          this.hideChangePasswordDialog();
        },
        (error: ObservableErrorResponse) => {
          const res = ObservableErrorResourceParser.parseError(error);
          this.passwordFieldError = ObservableErrorResourceParser.extractFieldErrors(res);
        }
      );
  }

  onPasswordChangeModalHide() {
    this.passwordModel.confirmPassword = '';
    this.passwordModel.password = '';
    this.passwordModel.prevPassword = '';
    this.passwordChangeForm.reset();
  }

  onPasswordModelChange() {
    this.resetPasswordFieldError();
    this.passwordChangeForm.controls['confirm_password'].updateValueAndValidity();
  }

  getCurrentPasswordMaximumLength(): number {
    // Do not limit max length. If the password rule change, the user must change the current password.
    return UiConstants.maximumVarcharLength;
  }

  getNewPasswordMaximumLength(): number {
    // TODO: Return max length based on password policy.
    // TODO: Missing reactive input validations:
    // - each field is required
    // - current and new password must be different
    // - create 'new password again' input and validate the two current input to be the same.
    // - min length for current password inputs
    return UiConstants.maximumVarcharLength;
  }

  resetPasswordFieldError() {
    this.passwordFieldError = {};
  }

  showChangePasswordDialog() {
    this.changePasswordDialog.show();
    this.changePasswordDialogVisible = true;
  }

  hideChangePasswordDialog() {
    this.changePasswordDialog.hide();
    this.changePasswordDialogVisible = false;
    this.resetPasswordFieldError();
  }

  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }

  addNewBugReport() {
    const dialogRef = this.dialog.open(HelpdeskBugReportTypeSelectorDialogComponent);
    dialogRef.afterClosed().subscribe(taskId => {
      if (taskId) {
        this.router.stateService.go(StateName.HELPDESK_BUG_REPORT_CREATE, {taskId: taskId});
      }
    });
  }

  ngOnDestroy(): void {
    this.removeUncaughtExceptionListener();
  }

}

class UserDataToDisplay {
  id?: number = undefined;
  userName: string = '';
  personName: string = '';
  profilePicture: any | undefined;
  type: UserProfileType = 'USER';
}
