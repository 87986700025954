/* eslint-disable */
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {List, Map as ImmutableMap, Set} from 'immutable';
import {Observable, Observer, Subject} from 'rxjs';
import {
  FieldValidationError,
  Order,
  PagingRequest,
  QueryResult,
  ResourceQueryResult,
  Services,
} from '../util/services';
import {FormRecord} from '../form/form-record.service';
import {Form} from '../form/form.service';
import {DownloadedFile, FileNameBuilder, NamedBlobFile, NamedBlobFileDecorator,} from '../util/downloaded-files';
import {
  TaskRecordGlobalResourceService,
  TaskRecordResource,
  TaskRecordResourceService,
} from './task-record-resource.service';
import {LocalDate, OffsetDateTime} from '../util/dates';
import {TaskRecordStateMachine} from './task-record-statemachine';
import {Address, AddressResource} from '../address';
import {Invoice} from '../invoice/invoice/invoice.service';
import {
  FileAttachment,
  FileAttachmentResource,
  FileAttachments,
  FileAttachmentUpdateRequest
} from '../util/file-attachments';
import {CustomerRecord, CustomerRecordService} from '../customer/customer-record.service';
import {ChatService} from '../../shared/chat/chat-service.interface';
import {Chat, ChatResource} from '../chat/chat.utils';
import {EmptyMessage, IdentityMessage} from '../util/messages';
import {ObservableErrorResourceParser} from '../util/errors';
import {TriggerInstance} from '../trigger/trigger-instance.service';
import {TriggerUtils} from '../trigger/trigger-utils';
import {TriggerInstanceResource} from '../trigger/trigger-instance-resource.service';
import {InvoiceSettings} from '../invoice/invoice-settings/invoice-settings.service';
import Decimal from 'decimal.js';
import {Icon, IconService} from './icon.service';
import {LocalizedTypeObject, UiConstants} from '../../util/core-utils';
import {DqlQuery} from '../query/field';
import {ErrorMessageService} from '../error-message-parser.service';
import {Cacheable, CacheBuster} from 'ts-cacheable';
import {FileUtils} from '../../util/file-utils';
import {Process} from "../process/process.service";
import {HelpdeskState} from "../statistics/task-statistics/helpdesk.state";
import TriggerInstanceService = TriggerInstance.TriggerInstanceService;
import TaskRecordLinkType = TaskRecord.TaskRecordLinkType;
import UploadSignatureRequest = TaskRecord.UploadSignatureRequest;
import {ContractNumber} from "../customer/contract-number/contract-number.service";

/* eslint-enable */

const signatureCacheBuster = new Subject<void>();

@Injectable()
export class TaskRecordService implements TaskRecord.Service, ChatService, TriggerInstanceService {

  private formRecordMapper: FormRecord.ResourceMapper;

  constructor(private resourceService: TaskRecordResourceService,
              private globalResourceService: TaskRecordGlobalResourceService,
              private errorMessageService: ErrorMessageService,
              private iconService: IconService) {
    this.formRecordMapper = new FormRecord.ResourceMapper(new Form.ResourceMapper());
  }

  // <editor-fold desc="CRUD">

  query(request: TaskRecord.QueryRequest): Observable<QueryResult<TaskRecord.TaskRecord>> {
    return Observable.create((observer: Observer<QueryResult<TaskRecord.TaskRecord>>) => {
      const resourceRequest: TaskRecordResource.GlobalQueryRequest = TaskRecordMapper.toResourceQueryRequest(request);
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<TaskRecordResource.TaskRecord>) => {
          observer.next({
            items: TaskRecordMapper.toPublicList(result.items, this.formRecordMapper),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  globalQuery(request: TaskRecord.GlobalQueryRequest): Observable<QueryResult<TaskRecord.TaskRecord>> {
    return Observable.create((observer: Observer<QueryResult<TaskRecord.TaskRecord>>) => {
      const resourceRequest: TaskRecordResource.GlobalQueryRequest = TaskRecordMapper.toResourceGlobalQueryRequest(request);
      return this.globalResourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<TaskRecordResource.TaskRecord>) => {
          observer.next({
            items: TaskRecordMapper.toPublicList(result.items, this.formRecordMapper, this.iconService),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  listQuery(request: TaskRecord.GlobalQueryRequest): Observable<TaskRecord.TaskRecord[]> {
    return Observable.create((observer: Observer<TaskRecord.TaskRecord[]>) => {
      if (!request.taskRecordIdSet || request.taskRecordIdSet.size === 0) {
        observer.next([]);
      } else {
        const resourceRequest: TaskRecordResource.GlobalQueryRequest = TaskRecordMapper.toResourceGlobalQueryRequest(request);
        return this.globalResourceService.query(resourceRequest).subscribe(
          (result: ResourceQueryResult<TaskRecordResource.TaskRecord>) => {
            observer.next(
              TaskRecordMapper.toPublicList(result.items, this.formRecordMapper).toArray()
            );
          },
          (error: Error) => {
            observer.error(this.translateError(error));
          },
          () => {
            observer.complete();
          });
      }
    });
  }

  queryTaskRecordNames(request: TaskRecord.QueryTaskRecordNamesRequest): Observable<string[]> {
    return Observable.create((observer: Observer<string[]>) => {
      const resourceRequest = {
        name: request.name,
        number_of_items: request.numberOfItems,
        no_progress_bar: true
      };
      return this.globalResourceService.queryTaskRecordNames(resourceRequest).subscribe(
        (result: string[]) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  exportToExternalSystem(request: TaskRecord.ExternalExportRequest): Observable<TaskRecord.ExternalExportResult> {
    return Observable.create((observer: Observer<TaskRecord.ExternalExportResult>) => {
      return this.globalResourceService.exportToExternalSystem({
        task_record_ids: request.taskRecordIds.toArray(),
        with_location: true
      }).subscribe((result: TaskRecordResource.ExternalExportResult) => {
          observer.next(TaskRecord.ExportResultMapper.map(result.result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });

    });
  }

  get(request: TaskRecord.GetRequest): Observable<TaskRecord.TaskRecord> {
    return Observable.create((observer: Observer<TaskRecord.TaskRecord>) => {
      const resourceRequest: TaskRecordResource.GetRequest = {
        with_form_record: TaskRecordMapper.toResourceArgumentWithFormRecord(request.withFormRecord),
        fields: request.fields ? request.fields.join(',') : undefined,
        rights: DqlQuery.RightRequestSerializer.getInstance().serialize(request.rights),
        customer_fields: request.customerFields ? request.customerFields.join(',') : undefined,
        task_id: request.taskId,
        task_record_id: request.taskRecordId,
        ignore_permission_denied: request.ignorePermissionDenied
      };
      return this.resourceService.get(resourceRequest).subscribe(
        (result: TaskRecordResource.TaskRecord) => {
          observer.next(TaskRecordMapper.toPublic(result, this.formRecordMapper));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  create(request: TaskRecord.CreateRequest): Observable<IdentityMessage> {
    return Observable.create((observer: Observer<IdentityMessage>) => {
      const resourceRequest: TaskRecordResource.CreateRequest = {
        with_form_record: TaskRecordMapper.toResourceArgumentWithFormRecord(request.withFormRecord),
        task_id: request.taskId,
        external_id: request.externalId,
        name: request.name,
        description: request.description,
        importance: request.importance,
        owner_user_id: request.ownerUserId,
        customer_id: request.customerId,
        billing_info_id: request.billingInfoId,
        contact_location_id: request.contactLocationId,
        project_id: request.projectId,
        agreed_time: request.agreedTime && request.agreedTime.isValid() ? request.agreedTime.toUtcIsoString() : undefined,
        deadline: request.deadline && request.deadline.isValid() ? request.deadline.toUtcIsoString() : undefined,
        user_group_id: request.usergroupId,
        assignee: {
          user_id: request.assignee.userId,
          mobile_application_id: request.assignee.mobileApplicationId
        },
        place_of_consumption: request.placeOfConsumption ? {
          address: request.placeOfConsumption.address
            ? AddressResource.Mapper.fromPublicPostalAddressOpt(request.placeOfConsumption.address)
            : undefined,
          coordinate: AddressResource.Mapper.fromPublicCoordinate(request.placeOfConsumption.coordinate)
        } : undefined,
        form_record: this.formRecordMapper.toResourceCreateRequest(request.formRecord),
        text_document_ids: request.textDocumentIds,
        file_document_ids: request.fileDocumentIds,
        linked_survey_ids: request.linkedSurveyIds,
        estimated_time_in_minutes: request.estimatedTimeInMinutes,
        contract_number_id: request.contractNumberId,
        with_location: true
      };
      return this.resourceService.create(resourceRequest).subscribe(
        (result: IdentityMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  quickCreate(request: TaskRecord.QuickCreateRequest): Observable<IdentityMessage> {
    return Observable.create((observer: Observer<IdentityMessage>) => {
      const resourceRequest: TaskRecordResource.QuickCreateRequest = TaskRecordMapper.toQuickCreateRequest(request);
      return this.resourceService.quickCreate(resourceRequest).subscribe(
        (result: IdentityMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  update(request: TaskRecord.UpdateRequest): Observable<TaskRecord.TaskRecord> {
    return Observable.create((observer: Observer<TaskRecord.TaskRecord>) => {
      const resourceRequest: TaskRecordResource.UpdateRequest = {
        task_record_id: request.taskRecordId,
        with_form_record: TaskRecordMapper.toResourceArgumentWithFormRecord(request.withFormRecord),
        task_id: request.taskId,
        external_id: request.externalId,
        name: request.name,
        description: request.description,
        importance: request.importance,
        owner_user_id: request.ownerUserId,
        customer_id: request.customerId,
        billing_info_id: request.billingInfoId,
        contact_location_id: request.contactLocationId,
        project_id: request.projectId,
        agreed_time: request.agreedTime && request.agreedTime.isValid() ? request.agreedTime.toUtcIsoString() : undefined,
        deadline: request.deadline && request.deadline.isValid() ? request.deadline.toUtcIsoString() : undefined,
        user_group_id: request.usergroupId,
        contract_number_id: request.contractNumberId,
        assignee: {
          user_id: request.assignee.userId,
          mobile_application_id: request.assignee.mobileApplicationId
        },
        place_of_consumption: request.placeOfConsumption ? {
          address: request.placeOfConsumption.address
            ? AddressResource.Mapper.fromPublicPostalAddressOpt(request.placeOfConsumption.address)
            : undefined,
          coordinate: AddressResource.Mapper.fromPublicCoordinate(request.placeOfConsumption.coordinate)
        } : undefined,
        form_record: this.formRecordMapper.toResourceUpdateRequest(request.formRecord),
        text_document_ids: request.textDocumentIds,
        file_document_ids: request.fileDocumentIds,
        linked_survey_ids: request.linkedSurveyIds,
        estimated_time_in_minutes: request.estimatedTimeInMinutes
      };
      return this.resourceService.update(resourceRequest).subscribe(
        (result: TaskRecord.TaskRecord) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  quickUpdate(request: TaskRecord.QuickUpdateRequest): Observable<TaskRecord.TaskRecord> {
    return Observable.create((observer: Observer<TaskRecord.TaskRecord>) => {
      const resourceRequest: TaskRecordResource.QuickUpdateRequest = {
        task_record_id: request.taskRecordId,
        task_id: request.taskId,
        external_id: request.externalId,
        name: request.name,
        description: request.description,
        importance: request.importance,
        owner_user_id: request.ownerUserId,
        customer_id: request.customerId,
        billing_info_id: request.billingInfoId,
        contact_location_id: request.contactLocationId,
        project_id: request.projectId,
        agreed_time: request.agreedTime && request.agreedTime.isValid() ? request.agreedTime.toUtcIsoString() : undefined,
        deadline: request.deadline && request.deadline.isValid() ? request.deadline.toUtcIsoString() : undefined,
        user_group_id: request.usergroupId,
        contract_number_id: request.contractNumberId,
        assignee: {
          user_id: request.assignee.userId,
          mobile_application_id: request.assignee.mobileApplicationId
        },
        place_of_consumption: request.placeOfConsumption ? {
          address: request.placeOfConsumption.address
            ? AddressResource.Mapper.fromPublicPostalAddressOpt(request.placeOfConsumption.address)
            : undefined,
          coordinate: AddressResource.Mapper.fromPublicCoordinate(request.placeOfConsumption.coordinate)
        } : undefined,
        text_document_ids: request.textDocumentIds,
        file_document_ids: request.fileDocumentIds,
        linked_survey_ids: request.linkedSurveyIds,
        estimated_time_in_minutes: request.estimatedTimeInMinutes
      };
      return this.resourceService.quickUpdate(resourceRequest).subscribe(
        (result: TaskRecord.TaskRecord) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  // </editor-fold>

  // <editor-fold desc="state">

  checkState(request: TaskRecord.CheckStateQuery): Observable<Set<number>> {
    return Observable.create((observer: Observer<Set<number>>) => {
      const resourceRequest: TaskRecordResource.CheckStateQuery = TaskRecordMapper.toResourceCheckStateRequest(request);
      this.globalResourceService.checkState(resourceRequest).subscribe(
        (result: number[]) => {
          observer.next(Set.of(...result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  setState(request: TaskRecord.ChangeStateRequest): Observable<EmptyMessage | TaskRecord.RevertResultResponse> {
    if (!request.newState.apiName) {
      throw Error('no available state change request')
    }
    return Observable.create((observer: Observer<EmptyMessage | TaskRecord.RevertResultResponse>) => {
      const recordIds = request.taskRecordIdSet.toArray();
      const resourceRequest: TaskRecordResource.ChangeStateRequest = {
        id: recordIds,
        new_state: request.newState.apiName!,
        state_change_message: request.stateChangeMessage,
        with_location: true,
        check_up_date: request.checkUpDate && request.checkUpDate.isValid() ? request.checkUpDate.toIsoString() : undefined
      };
      this.globalResourceService.setState(resourceRequest).subscribe(
        (result: EmptyMessage | TaskRecordResource.RevertResultResponse) => {
          if ("result" in result) {
            observer.next({result: TaskRecord.RevertResultMapper.map(result.result)});
          } else {
            observer.next(result);
          }
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });

    });
  }

  startApproval(request: TaskRecord.GetRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: TaskRecordResource.GetRequest = {
        task_id: request.taskId,
        task_record_id: request.taskRecordId,
        with_form_record: false
      };
      this.globalResourceService.startApproval(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });

    });
  }

  @CacheBuster({
    cacheBusterNotifier: signatureCacheBuster
  })
  cancelApproval(request: TaskRecord.GetRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: TaskRecordResource.GetRequest = {
        task_id: request.taskId,
        task_record_id: request.taskRecordId,
        with_form_record: false
      };
      this.globalResourceService.cancelApproval(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });

    });
  }

  // </editor-fold>

  // <editor-fold desc="bulk updates">

  checkBulkChangeAssignee(request: TaskRecord.GlobalQueryRequest): Observable<Set<number>> {
    return Observable.create((observer: Observer<Set<number>>) => {
      const resourceRequest: TaskRecordResource.GlobalQueryRequest = TaskRecordMapper.toResourceGlobalQueryRequest(request);
      this.globalResourceService.checkBulkChangeAssignee(resourceRequest).subscribe(
        (result: number[]) => {
          observer.next(Set.of(...result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  checkBulkChangeDeadline(request: TaskRecord.GlobalQueryRequest): Observable<Set<number>> {
    return Observable.create((observer: Observer<Set<number>>) => {
      const resourceRequest: TaskRecordResource.GlobalQueryRequest = TaskRecordMapper.toResourceGlobalQueryRequest(request);
      this.globalResourceService.checkBulkChangeDeadline(resourceRequest).subscribe(
        (result: number[]) => {
          observer.next(Set.of(...result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  checkGeocode(request: TaskRecord.GlobalQueryRequest): Observable<Set<number>> {
    return Observable.create((observer: Observer<Set<number>>) => {
      const resourceRequest: TaskRecordResource.GlobalQueryRequest = TaskRecordMapper.toResourceGlobalQueryRequest(request);
      resourceRequest.fields = 'id';
      this.globalResourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<TaskRecordResource.TaskRecord>) => {
          observer.next(Set.of(...result.items.map(tr => tr.id)));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  geocode(request: TaskRecord.TaskRecordIdSetRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: TaskRecordResource.TaskRecordIdSetRequest = {
        task_record_ids: request.taskRecordIdSet && request.taskRecordIdSet.size > 0 ? request.taskRecordIdSet.toArray() : [],
      };
      this.globalResourceService.geocode(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });

    });

  }

  confirm(request: TaskRecord.ConfirmRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: TaskRecordResource.ConfirmRequest = {
        task_record_ids: request.taskRecordIdSet && request.taskRecordIdSet.size > 0 ? request.taskRecordIdSet.toArray() : [],
        confirmed: request.confirmed
      };
      this.globalResourceService.confirm(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });

    });

  }

  importanceUpdate(request: TaskRecord.ImportanceRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: TaskRecordResource.ChangeImportanceRequest = {
        task_record_ids: request.taskRecordIdSet && request.taskRecordIdSet.size > 0 ? request.taskRecordIdSet.toArray() : [],
        importance: request.importance
      };
      this.globalResourceService.importanceBulkUpdate(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });

    });
  }

  changeAssignee(request: TaskRecord.AssigneeChangeRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: TaskRecordResource.AssigneeChangeRequest = {
        task_record_ids: request.taskRecordIdSet && request.taskRecordIdSet.size > 0 ? request.taskRecordIdSet.toArray() : [],
        user_id: request.userId,
        mobile_application_string_id: request.mobileApplicationStringId,
        mobile_application_id: request.mobileApplicationId
      };
      this.globalResourceService.assigneeBulkUpdate(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });

    });
  }

  changeAssigneeInProgress(request: TaskRecord.AssigneeChangeInProgressRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: TaskRecordResource.AssigneeChangeInProgressRequest = {
        task_id: request.taskId,
        task_record_id: request.taskRecordId,
        task_record_ids: [request.taskRecordId],
        user_id: request.userId,
        mobile_application_string_id: request.mobileApplicationStringId,
        mobile_application_id: request.mobileApplicationId
      };
      this.resourceService.changeAssigneeInProgress(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });

    });
  }

  changeDeadline(request: TaskRecord.DeadlineChangeRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: TaskRecordResource.DeadlineChangeRequest = {
        task_record_ids: request.taskRecordIdSet && request.taskRecordIdSet.size > 0 ? request.taskRecordIdSet.toArray() : [],
        deadline: request.deadline.toUtcIsoString()
      };
      this.globalResourceService.deadlineBulkUpdate(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });

    });
  }

  sendEmail(request: TaskRecord.SendEmailRequest): Observable<TaskRecord.TaskRecordEmailResult> {
    return Observable.create((observer: Observer<TaskRecord.TaskRecordEmailResult>) => {
      const resourceRequest: TaskRecordResource.SendEmailRequest = {
        task_id: request.taskId,
        task_record_id: request.taskRecordId,
        send_to_me: request.sendToMe,
        send_to_assignee: request.sendToAssignee,
        send_to_customer: request.sendToCustomer,
        other_recipients: request.otherRecipients
      };
      this.resourceService.sendEmail(resourceRequest).subscribe(
        (result: TaskRecordResource.TaskRecordEmailResult) => {
          observer.next({emailResult: result.email_result});
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });

    });
  }

  getAttachments(request: TaskRecord.GetAttachmentRequest): Observable<FileAttachment[]> {
    return Observable.create((observer: Observer<FileAttachment[]>) => {
      const resourceRequest: TaskRecordResource.GetAttachmentRequest = {
        task_id: request.taskId,
        task_record_id: request.taskRecordId
      };
      this.resourceService.getAttachments(resourceRequest).subscribe(
        (result: FileAttachmentResource[]) => {
          observer.next(FileAttachments.toPublicList(result));
        });
    });
  }

  downloadAttachment(request: TaskRecord.DownloadAttachmentRequest): Observable<NamedBlobFile> {
    const fileName = new FileNameBuilder()
      .addString(request.attachment.fullFileName)
      .addOffsetDateTime(Services.toOffsetDateTime(request.attachment.contentUpdateTime))
      .build();
    return this.resourceService.downloadAttachment(
      request.taskId,
      request.taskRecordId,
      request.attachment.id,
      false
    ).pipe(map((file: DownloadedFile) => {
      return new NamedBlobFileDecorator(file, fileName);
    }));
  }

  downloadAttachmentThumbnail(request: TaskRecord.DownloadAttachmentRequest): Observable<NamedBlobFile> {
    const fileName = new FileNameBuilder()
      .addString(request.attachment.fullFileName)
      .addOffsetDateTime(Services.toOffsetDateTime(request.attachment.contentUpdateTime))
      .build();
    return this.resourceService.downloadAttachment(
      request.taskId,
      request.taskRecordId,
      request.attachment.id,
      true
    ).pipe(map((file: DownloadedFile) => {
      return new NamedBlobFileDecorator(file, fileName);
    }));
  }

  downloadAttachmentZip(request: TaskRecord.TaskRecordIdSetRequest): Observable<NamedBlobFile> {
    return this.globalResourceService.downloadAttachmentZip(
      {task_record_ids: request.taskRecordIdSet.toArray()}
    ).pipe(map((file: DownloadedFile) => {
      return new NamedBlobFileDecorator(file, 'attachments.zip');
    }));
  }

  getPdfs(request: TaskRecord.GetPdfsRequest): Observable<QueryResult<TaskRecord.Pdf>> {
    return Observable.create((observer: Observer<QueryResult<TaskRecord.Pdf>>) => {
      const resourceRequest: TaskRecordResource.GetPdfsRequest = {
        task_id: request.taskId,
        task_record_id: request.taskRecordId,
        order: Services.createOrderFieldParameter(PdfKeys.toOrderFieldKey, request.orders),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined
      };
      this.resourceService.getPdfs(resourceRequest).subscribe(
        (result: ResourceQueryResult<TaskRecordResource.Pdf>) => {
          observer.next({
            items: List.of(...result.items.map(r => ({
              pdfDocumentId: r.document_id,
              creationTime: Services.toOffsetDateTime(r.creation_time),
              templateName: r.template_name,
              contentHash: r.content_hash,
              trigger: r.trigger ? {
                triggerId: r.trigger.trigger_id,
                instanceId: r.trigger.instance_id,
                name: r.trigger.name,
                event: <TriggerUtils.NotificationEvent>r.trigger.event
              } : undefined,
              originalTaskRecord: r.original_task_record ? {
                taskId: r.original_task_record.task_id,
                id: r.original_task_record.id,
                name: r.original_task_record.name,
                externalId: r.original_task_record.external_id
              } : undefined
            }))),
            pagingResult: result.pagingResult
          });
        });
    });
  }

  downloadPdf(request: TaskRecord.DownloadPdfRequest): Observable<DownloadedFile> {
    return this.resourceService.downloadPdf({
      task_id: request.taskId,
      task_record_id: request.taskRecordId,
      document_id: request.documentId
    });
  }

  downloadPdfZip(request: TaskRecord.TaskRecordIdSetRequest): Observable<NamedBlobFile> {
    return this.globalResourceService.downloadPdfZip(
      {task_record_ids: request.taskRecordIdSet.toArray()}
    ).pipe(map((file: DownloadedFile) => {
      return new NamedBlobFileDecorator(file, 'pdf-document.zip');
    }));
  }

  downloadMergedPdf(request: TaskRecord.TaskRecordIdSetRequest): Observable<NamedBlobFile> {
    return this.globalResourceService.downloadMergedPdf(
      {task_record_ids: request.taskRecordIdSet.toArray()}
    ).pipe(map((file: DownloadedFile) => {
      return new NamedBlobFileDecorator(file, 'merged-pdf.pdf');
    }));
  }

  downloadTableXls(request: TaskRecord.TaskRecordIdSetRequest): Observable<NamedBlobFile> {
    return this.globalResourceService.downloadTableXls(
      {task_record_ids: request.taskRecordIdSet.toArray()}
    ).pipe(map((file: DownloadedFile) => {
      return new NamedBlobFileDecorator(file, 'table-xls.xlsx');
    }));
  }

  deleteAttachment(request: TaskRecord.DeleteAttachmentRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.resourceService.deleteAttachment({
        task_record_id: request.taskRecordId,
        task_id: request.taskId,
        file_id: request.fileId,
      }).subscribe((result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });

    });
  }

  updateAttachment(request: TaskRecord.UpdateAttachmentRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.resourceService.updateAttachment({
        task_record_id: request.taskRecordId,
        task_id: request.taskId,
        file_id: request.fileId,
        name: request.name,
      }).subscribe((result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });

    });
  }

  downloadCsv(request: TaskRecord.QueryRequest): Observable<DownloadedFile> {
    const resourceRequest: TaskRecordResource.GlobalQueryRequest = TaskRecordMapper.toResourceQueryRequest(request);
    return this.resourceService.downloadCsv(resourceRequest);
  }

  exportXls(request: TaskRecord.GlobalQueryRequest): Observable<DownloadedFile> {
    const resourceRequest: TaskRecordResource.GlobalQueryRequest = TaskRecordMapper.toResourceGlobalQueryRequest(request);
    return this.globalResourceService.exportXls(resourceRequest);
  }

  exportCustomXls(request: TaskRecord.GlobalQueryRequest): Observable<DownloadedFile> {
    const resourceRequest: TaskRecordResource.GlobalQueryRequest = TaskRecordMapper.toResourceGlobalQueryRequest(request);
    return this.globalResourceService.exportCustomXls(resourceRequest);
  }

  exportSupInvoice(ids: Set<number>): Observable<DownloadedFile> {
    return this.globalResourceService.exportSupInvoice(Services.createListParameter(ids)!);
  }

  exportSupCreate(request: TaskRecord.TaskRecordSupCreateRequest): Observable<DownloadedFile> {
    const resourceRequest: TaskRecordResource.TaskRecordSupCreateRequest = {
      task_record_ids: request.taskRecordIds.toArray(),
      journal_code: request.journalCode,
      payment_type: request.paymentType,
      issue_date: Services.localDateToString(request.issueDate)!,
      delivery_date: Services.localDateToString(request.deliveryDate)!,
      deadline_offset: request.deadlineOffset
    };
    return this.globalResourceService.exportSupCreate(resourceRequest);
  }

  exportMaconomy(request: TaskRecord.GlobalQueryRequest): Observable<DownloadedFile> {
    const resourceRequest: TaskRecordResource.GlobalQueryRequest = TaskRecordMapper.toResourceGlobalQueryRequest(request);
    return this.globalResourceService.exportMaconomy(resourceRequest);
  }

  exportStockXls(request: TaskRecord.GlobalQueryRequest): Observable<DownloadedFile> {
    const resourceRequest: TaskRecordResource.GlobalQueryRequest = TaskRecordMapper.toResourceGlobalQueryRequest(request);
    return this.globalResourceService.exportStockXls(resourceRequest);
  }

  exportXlsTemplate(request: IdentityMessage): Observable<DownloadedFile> {
    return this.resourceService.exportXlsTemplate(request);
  }

  getLogHistory(request: TaskRecord.GetLogHistoryRequest): Observable<QueryResult<TaskRecord.Log>> {
    return Observable.create((observer: Observer<QueryResult<TaskRecord.Log>>) => {
      const resourceRequest: TaskRecordResource.GetLogHistoryRequest = {
        task_id: request.taskId,
        task_record_id: request.taskRecordId,
        with_coordinates_only: request.withCoordinatesOnly,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
        page_number: request.paging ? request.paging.pageNumber : undefined,
        order: Services.createOrderFieldParameter(LogKeys.toOrderFieldKey, request.orders),
      };
      return this.resourceService.getLogHistory(resourceRequest).subscribe(
        (result: ResourceQueryResult<TaskRecordResource.LogResource>) => {
          observer.next({
            items: List.of(...result.items.map((log: TaskRecordResource.LogResource): TaskRecord.Log => {
              return TaskRecordMapper.toPublicTaskRecordLog(log)!
            })),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  getGlobalLogHistory(request: TaskRecord.GetGlobalLogHistoryRequest): Observable<QueryResult<TaskRecord.Log>> {
    return Observable.create((observer: Observer<QueryResult<TaskRecord.Log>>) => {
      const resourceRequest: TaskRecordResource.GlobalLogHistoryRequest = {
        order: Services.createOrderFieldParameter(LogKeys.toOrderFieldKey, request.orders),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
        process_id: request.processId,
        helpdesk_enabled: request.helpdesk
      };
      return this.globalResourceService.getLogHistory(resourceRequest).subscribe(
        (result: ResourceQueryResult<TaskRecordResource.LogResource>) => {
          observer.next({
            items: List.of(...result.items.map((log: TaskRecordResource.LogResource): TaskRecord.Log => {
              return TaskRecordMapper.toPublicTaskRecordLog(log)!
            })),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  getRelatedInvoices(request: TaskRecord.RelatedInvoiceGetRequest): Observable<TaskRecord.RelatedInvoice[]> {
    return Observable.create((observer: Observer<TaskRecord.RelatedInvoice[]>) => {
      const resourceRequest: TaskRecordResource.RelatedInvoiceGetRequest = {
        task_id: request.taskId,
        task_record_id: request.taskRecordId
      };
      return this.resourceService.getRelatedInvoices(resourceRequest).subscribe(
        (result: TaskRecordResource.RelatedInvoiceResource[]) => {
          observer.next(result.map(inv => {
            return {
              id: inv.id,
              categoryType: <Invoice.InvoiceCategoryType>inv.category_type,
              invoiceNumber: inv.invoice_number,
              appearanceType: <Invoice.InvoiceAppearanceType>inv.appearance_type,
              creationTime: Services.toOffsetDateTime(inv.creation_time),
              invoiceSettings: {
                id: inv.invoice_settings.id,
                profileName: inv.invoice_settings.profile_name,
                companyName: inv.invoice_settings.company_name,
                invoiceIntegrationType: <InvoiceSettings.InvoiceIntegrationType>inv.invoice_settings.invoice_integration_type
              }
            }
          }));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  messageQuery(request: Chat.MessageQueryRequest): Observable<QueryResult<Chat.Message>> {
    return Observable.create((observer: Observer<QueryResult<Chat.Message>>) => {
      const resourceRequest: TaskRecordResource.MessageQueryRequest = {
        task_id: request.parentId!,
        task_record_id: request.id,
        content: request.content,
        user_profile_id: request.userProfileId,
        creation_time_from: request.creationTimeFrom && request.creationTimeFrom.isValid()
          ? request.creationTimeFrom.toUtcIsoString() : undefined,
        creation_time_to: request.creationTimeTo && request.creationTimeTo.isValid()
          ? request.creationTimeTo.toUtcIsoString() : undefined,
        order: Services.createOrderFieldParameter(Chat.Keys.toOrderFieldKey, request.order),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
      };
      return this.resourceService.messageQuery(resourceRequest).subscribe(
        (result: ResourceQueryResult<ChatResource.Message>) => {
          observer.next({
            items: Chat.Mapper.toPublicMessageList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  sendMessage(request: Chat.SendMessageRequest): Observable<IdentityMessage> {
    return Observable.create((observer: Observer<IdentityMessage>) => {
      const resourceRequest: TaskRecordResource.SendMessageRequest = {
        task_id: request.parentId!,
        task_record_id: request.id,
        content: request.content,
      };
      return this.resourceService.sendMessage(resourceRequest).subscribe(
        (result: IdentityMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  getTriggerInstanceHistory(request: TriggerInstance.TriggerInstanceHistoryRequest):
    Observable<QueryResult<TriggerInstance.TriggerInstance>> {
    return Observable.create((observer: Observer<QueryResult<TriggerInstance.TriggerInstance>>) => {
      const resourceRequest: TriggerInstanceResource.TriggerInstanceHistoryRequest
        = TriggerUtils.toResourceTriggerInstanceHistoryRequest(request);
      return this.resourceService.getTriggerInstanceHistory(resourceRequest).subscribe(
        (result: ResourceQueryResult<TriggerInstanceResource.TriggerInstance>) => {
          observer.next({
            items: TriggerUtils.toPublicTriggerInstanceList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  getRecentMessages(request: TaskRecord.RecentMessageQueryRequest): Observable<QueryResult<TaskRecord.RecentMessage>> {
    return Observable.create((observer: Observer<QueryResult<TaskRecord.RecentMessage>>) => {
      return this.globalResourceService.getRecentMessages({
        helpdesk_enabled: request.helpdesk,
        fields: request.fields ? request.fields.join(',') : undefined,
        customer_fields: request.customerFields ? request.customerFields.join(',') : undefined,
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
        no_progress_bar: request.noProgressBar

      }).subscribe(
        (result: ResourceQueryResult<TaskRecordResource.RecentMessage>) => {
          observer.next({
            items: List.of(...result.items.map(m => {
              return {
                taskRecord: TaskRecordMapper.toPublic(m.task_record, this.formRecordMapper, this.iconService),
                chatMessage: Chat.Mapper.toPublicMessage(m.chat_message)
              };
            })),
            pagingResult: result.pagingResult
          });

        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  createManualInvoice(request: TaskRecord.ManualInvoiceCreateRequest): Observable<IdentityMessage> {
    return Observable.create((observer: Observer<IdentityMessage>) => {
      const resourceRequest: TaskRecordResource.ManualInvoiceCreateRequest = {
        task_id: request.taskId,
        task_record_id: request.taskRecordId,
        invoice_book_id: request.invoiceBookId,
        invoice_settings_id: request.invoiceSettingsId,
        invoice_tag_id: request.invoiceTagId,
        billing_info_id: request.billingInfoId,
        payment_type: <string>request.paymentType,
        invoice_appearance_type: <string>request.invoiceAppearanceType,
        comment_template: request.commentTemplate,
        issue_date: Services.localDateToString(request.issueDate)!,
        delivery_date: Services.localDateToString(request.deliveryDate)!,
        deadline: Services.localDateToString(request.deadline)!,
        stock_item_ids: request.stockItemIds.toArray(),
        invoice_item_ids: request.invoiceItemIds.toArray()
      };
      return this.resourceService.createManualInvoice(resourceRequest).subscribe(
        (result: IdentityMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateManualInvoiceError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  getInvoiceableItems(request: TaskRecord.GetAttachmentRequest): Observable<TaskRecord.InvoiceableItem[]> {
    return Observable.create((observer: Observer<TaskRecord.InvoiceableItem[]>) => {
      const resourceRequest: TaskRecordResource.GetAttachmentRequest = {
        task_id: request.taskId,
        task_record_id: request.taskRecordId
      };
      this.resourceService.getInvoiceableItems(resourceRequest).subscribe(
        (result: TaskRecordResource.InvoiceableItem[]) => {
          observer.next(result.map(r => TaskRecordMapper.toPublicInvoiceableItem(r)));
        });
    });
  }

  quickCreateByCustomerRecords(request: TaskRecord.QuickCreateByCustomerRecordsRequest): Observable<IdentityMessage[]> {
    return Observable.create((observer: Observer<IdentityMessage[]>) => {
      const resourceRequest: TaskRecordResource.QuickCreateByCustomerRecordsRequest = {
        task_id: request.taskId,
        name_template: request.nameTemplate,
        state: request.state.toString(),
        description: request.description,
        importance: request.importance,
        customer_ids: request.customerIds,
        project: request.projectId,
        agreed_time: request.agreedTime && request.agreedTime.isValid() ? request.agreedTime.toUtcIsoString() : undefined,
        deadline: request.deadline && request.deadline.isValid() ? request.deadline.toUtcIsoString() : undefined,
        assignee: request.assignee ? {
          user_id: request.assignee.userId,
          mobile_application_string_id: request.assignee.mobileApplicationStringId,
          mobile_application_id: request.assignee.mobileApplicationId
        } : undefined,
        user_group_id: request.userGroupId,
        estimated_time_in_minutes: request.estimatedTimeInMinutes
      };
      this.resourceService.quickCreateByCustomerRecords(resourceRequest).subscribe(
        (result: IdentityMessage[]) => {
          observer.next(result);
        },
        (error) => {
          observer.error(this.translateError(error));
        });
    });
  }

  getClusters(request: TaskRecord.ClustersRequest): Observable<TaskRecord.ClustersResponse[]> {
    return Observable.create((observer: Observer<TaskRecord.ClustersResponse[]>) => {
      const resourceRequest: TaskRecordResource.ClustersRequest = {
        fields: request.fields ? request.fields.join(',') : undefined,
        customer_fields: request.customerFields ? request.customerFields.join(',') : undefined,
        sw_lat: Services.decimalToString(request.swLat)!,
        sw_lon: Services.decimalToString(request.swLon)!,
        ne_lat: Services.decimalToString(request.neLat)!,
        ne_lon: Services.decimalToString(request.neLon)!,
        task_id: request.taskId,
        assignee_user_id: request.userIds ? request.userIds.join(',') : undefined,
        assignee_user_group_id: request.userGroupIds ? request.userGroupIds.join(',') : undefined,
        state: Services.createListParameter(request.state),
        no_progress_bar: request.noProgressBar
      };
      return this.globalResourceService.getClusters(resourceRequest).subscribe(
        (result: TaskRecordResource.ClustersResponse[]) => {
          observer.next(result.map(r => {
            return {
              clusterId: r.cluster_id,
              count: r.count,
              center: AddressResource.Mapper.toPublicCoordinate(r.center)!,
              taskRecord: r.task_record ? TaskRecordMapper.toPublic(r.task_record, this.formRecordMapper) : undefined
            };
          }));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  clone(request: TaskRecord.CloneRequest): Observable<number[]> {
    return Observable.create((observer: Observer<number[]>) => {
      const resourceRequest: TaskRecordResource.CloneRequest = {
        task_record_ids: request.taskRecordIds,
        target_task_id: request.targetTaskId,
        name_template: request.nameTemplate,
        assignee: request.assignee ? {
          user_id: request.assignee.userId,
          mobile_application_string_id: request.assignee.mobileApplicationStringId,
          mobile_application_id: request.assignee.mobileApplicationId
        } : undefined,
        user_group_id: request.userGroupId,
        clone_fields: request.cloneFields,
        open_task_records: request.openTaskRecords
      };
      return this.globalResourceService.clone(resourceRequest).subscribe(
        (result: number[]) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  readLinks(request: TaskRecord.ReadLinksRequest): Observable<TaskRecord.Link[]> {
    return Observable.create((observer: Observer<TaskRecord.Link[]>) => {
      const resourceRequest: TaskRecordResource.ReadLinksRequest = {
        task_id: request.taskId,
        task_record_id: request.taskRecordId,
        fields: request.fields ? request.fields.join() : undefined,
        customer_fields: request.customerFields ? request.customerFields.join() : undefined
      };
      return this.resourceService.readLinks(resourceRequest).subscribe(
        (result: TaskRecordResource.Link[]) => {
          observer.next(result.map(l => {
            return {
              destination: TaskRecordMapper.toPublic(l.destination, this.formRecordMapper, this.iconService),
              type: <TaskRecordLinkType>l.type,
              creationTime: Services.toOffsetDateTime(l.creation_time)
            };
          }));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  @Cacheable({
    maxAge: UiConstants.defaultCacheMaxAge,
    maxCacheCount: 100,
    slidingExpiration: true,
    cacheBusterObserver: signatureCacheBuster
  })
  downloadUserSignature(request: TaskRecord.GetRequest): Observable<DownloadedFile> {
    return this.resourceService.downloadSignature(
      request.taskId,
      request.taskRecordId,
      'user'
    );
  }

  @Cacheable({
    maxAge: UiConstants.defaultCacheMaxAge,
    maxCacheCount: 100,
    slidingExpiration: true,
    cacheBusterObserver: signatureCacheBuster
  })
  downloadCustomerSignature(request: TaskRecord.GetRequest): Observable<DownloadedFile> {
    return this.resourceService.downloadSignature(
      request.taskId,
      request.taskRecordId,
      'customer'
    );
  }

  @CacheBuster({
    cacheBusterNotifier: signatureCacheBuster
  })
  uploadSignature(request: UploadSignatureRequest): Observable<EmptyMessage> {
    const file: Blob = FileUtils.dataURIToBlob(request.picture);
    return this.resourceService.uploadSignature({
      task_record_id: request.taskRecordId,
      task_id: request.taskId,
      file: file,
      type: request.type
    });
  }


  // </editor-fold>

  // <editor-fold desc="Internal">

  private translateError(error: any): any {
    const res = ObservableErrorResourceParser.parseError(error);
    const fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
    const fieldErrorMap = ObservableErrorResourceParser.toFieldErrorMap(Keys.toValidatedField, fieldErrors);
    if (!fieldErrorMap.isEmpty()) {
      return FieldValidationError.of(fieldErrorMap);
    }
    if (ObservableErrorResourceParser.hasCustomErrors(res, 'invoice_errors')) {
      return this.translateInvoiceError(error);
    }
    if (ObservableErrorResourceParser.hasCustomErrors(res, 'stock_item_change_errors')) {
      return this.translateStockItemChangeError(error);
    }
    return this.errorMessageService.parseError(error);
  }

  private translateManualInvoiceError(error: any): any {
    const res = ObservableErrorResourceParser.parseError(error);
    const fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
    const fieldErrorMap = ObservableErrorResourceParser.toFieldErrorMap(Keys.toManualInvoiceValidatedField, fieldErrors);
    if (!fieldErrorMap.isEmpty()) {
      return FieldValidationError.of(fieldErrorMap);
    }
    return error;
  }

  private translateInvoiceError(error: any): TaskRecord.TaskRecordInvoiceGeneratorException {
    const res = ObservableErrorResourceParser.parseError(error);
    const customErrors = ObservableErrorResourceParser.extractCustomErrors(res, 'invoice_errors');
    const extractedErrors: TaskRecord.TaskRecordInvoiceGeneratorError[] = [];
    for (const id in customErrors) {
      if (!customErrors.hasOwnProperty(id)) {
        continue;
      }
      const e = customErrors[id];
      const index = extractedErrors.findIndex(value => value.taskRecordId === e['task_record']);
      if (index >= 0) {
        extractedErrors[index].messages.push(e['message']);
      } else {
        extractedErrors.push({taskRecordId: e['task_record'], messages: [e['message']]});
      }
    }
    return new TaskRecord.TaskRecordInvoiceGeneratorException(extractedErrors);
  }

  private translateStockItemChangeError(error: any): TaskRecord.TaskRecordStockItemChangeException {
    const res = ObservableErrorResourceParser.parseError(error);
    const customErrors = ObservableErrorResourceParser.extractCustomErrors(res, 'stock_item_change_errors');
    const extractedErrors: TaskRecord.StockItemChangeError[] = [];
    for (const id in customErrors) {
      if (!customErrors.hasOwnProperty(id)) {
        continue;
      }
      const e = customErrors[id];
      const index = extractedErrors.findIndex(value => value.taskRecordId === e['task_record_id']);
      if (index >= 0) {
        extractedErrors[index].errors.push(this.parseStockError(e));
      } else {
        extractedErrors.push({taskRecordId: e['task_record_id'], errors: [this.parseStockError(e)]});
      }
    }
    return new TaskRecord.TaskRecordStockItemChangeException(extractedErrors);
  }

  private parseStockError(e) {
    return {
      stockRecordName: e['stock_record_name'],
      currentAmount: e['current_amount'],
      newAmount: e['new_amount'],
      stockRecordId: e['stock_record_id']

    };
  }

  // </editor-fold>

}

export class TaskRecordMapper {
  public static toResourceGlobalQueryRequest(request: TaskRecord.GlobalQueryRequest): TaskRecordResource.GlobalQueryRequest {
    return {
      rights: DqlQuery.RightRequestSerializer.getInstance().serialize(request.rights),
      fields: request.fields ? request.fields.join(',') : undefined,
      customer_fields: request.customerFields ? request.customerFields.join(',') : undefined,
      with_form_record: this.toResourceArgumentWithFormRecord(request.withFormRecord),
      parent_disabled: request.parentDisabled,
      task_id: Services.createIdParameter(request.taskIdSet),
      id: Services.createIdParameter(request.taskRecordIdSet),
      name: request.name,
      external_id: request.externalId,
      description: request.description,
      state: Services.createListParameter(request.state),
      helpdesk_state: Services.createListParameter(request.helpdeskState),
      assignee_user_id: request.assigneeUserId,
      assignee_user_group_id: request.assigneeUserGroupId,
      assignee_mobile_app_id: request.assigneeMobileAppId,
      customer_id: request.customerId,
      customer_name: request.customerName,
      customer_phone_number: request.customerPhoneNumber,
      project_name: request.projectName,
      project_id: Services.createIdParameter(request.projectIdSet),
      process_id: Services.createIdParameter(request.processIdSet),
      importance: request.importance,
      export_state: request.exportState,
      place_of_consumption_zip_code: request.placeOfConsumptionZipCode,
      place_of_consumption_city: request.placeOfConsumptionCity,
      place_of_consumption_address: request.placeOfConsumptionStreet,
      place_of_consumption_house_number: request.placeOfConsumptionHouseNumber,
      deadline_from: request.deadlineFrom && request.deadlineFrom.isValid()
        ? request.deadlineFrom.toUtcIsoString() : undefined,
      deadline_to: request.deadlineTo && request.deadlineTo.isValid()
        ? request.deadlineTo.toUtcIsoString() : undefined,
      creation_time_from: request.creationTimeFrom && request.creationTimeFrom.isValid()
        ? request.creationTimeFrom.toUtcIsoString() : undefined,
      creation_time_to: request.creationTimeTo && request.creationTimeTo.isValid()
        ? request.creationTimeTo.toUtcIsoString() : undefined,
      update_time_from: request.updateTimeFrom && request.updateTimeFrom.isValid()
        ? request.updateTimeFrom.toUtcIsoString() : undefined,
      update_time_to: request.updateTimeTo && request.updateTimeTo.isValid()
        ? request.updateTimeTo.toUtcIsoString() : undefined,
      release_time_from: request.releaseTimeFrom && request.releaseTimeFrom.isValid()
        ? request.releaseTimeFrom.toUtcIsoString() : undefined,
      release_time_to: request.releaseTimeTo && request.releaseTimeTo.isValid()
        ? request.releaseTimeTo.toUtcIsoString() : undefined,
      agreed_time_from: request.agreedTimeFrom && request.agreedTimeFrom.isValid()
        ? request.agreedTimeFrom.toUtcIsoString() : undefined,
      agreed_time_to: request.agreedTimeTo && request.agreedTimeTo.isValid()
        ? request.agreedTimeTo.toUtcIsoString() : undefined,
      finished_time_from: request.finishedTimeFrom && request.finishedTimeFrom.isValid()
        ? request.finishedTimeFrom.toUtcIsoString() : undefined,
      finished_time_to: request.finishedTimeTo && request.finishedTimeTo.isValid()
        ? request.finishedTimeTo.toUtcIsoString() : undefined,
      stock_id: request.stockId,
      creator_user_id: Services.createIdParameter(request.creatorUserId),
      helpdesk_enabled: request.helpdeskEnabled,
      empty_intake_prices: request.emptyIntakePrices,
      contract_number_id: Services.createIdParameter(request.contractNumberId),
      base_project: request.baseProject,
      base_process: request.baseProcess,
      base_master_data_record: request.baseMasterDataRecord,
      base_customer_record: request.baseCustomerRecord,
      base_customer_record_by_phone: request.baseCustomerRecordByPhoneNumber,
      base_task_record: request.baseTaskRecord,
      q: request.queryText,
      dql: request.dqlText,
      order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
      page_number: request.paging ? request.paging.pageNumber : undefined,
      number_of_items: request.paging ? request.paging.numberOfItems : undefined,
      no_progress_bar: request.noProgressBar,
    };
  }

  public static toResourceCheckStateRequest(request: TaskRecord.CheckStateQuery): TaskRecordResource.CheckStateQuery {
    if (!request.stateChange.apiName) {
      throw Error('no available check state request');
    }
    const req: TaskRecordResource.CheckStateQuery = this.toResourceGlobalQueryRequest(request.query);
    req.state_change = request.stateChange.apiName;
    return req;
  }

  public static toResourceQueryRequest(request: TaskRecord.QueryRequest): TaskRecordResource.GlobalQueryRequest {
    const req: TaskRecordResource.GlobalQueryRequest = this.toResourceGlobalQueryRequest(request.query);
    req.task_id = request.taskId;
    return req;
  }

  public static toResourceArgumentWithFormRecord(withFormRecord?: boolean): boolean {
    return withFormRecord === undefined ? false : withFormRecord;
  }

  public static toPublicList(
    resourceList: TaskRecordResource.TaskRecord[],
    formRecordMapper: FormRecord.ResourceMapper,
    iconService?: IconService):
    List<TaskRecord.TaskRecord> {
    return List.of(...resourceList.map((r) => this.toPublic(r, formRecordMapper, iconService)));
  }

  public static toPublic(
    r: TaskRecordResource.TaskRecord,
    formRecordMapper: FormRecord.ResourceMapper,
    iconService?: IconService): TaskRecord.TaskRecord {
    return {
      taskRecordId: r.id,
      taskId: r.task_id,
      state: <TaskRecordStateMachine.State>r.state,
      helpdeskState: <HelpdeskState.State>r.helpdesk_state,
      ownerUser: this.toPublicOwnerUser(r.owner_user),
      creationTime: Services.toOffsetDateTime(r.creation_time),
      updateTime: Services.toOffsetDateTime(r.update_time),
      disabled: r.disabled,
      externalId: r.external_id,
      name: r.name,
      description: r.description,
      agreedTime: Services.toOffsetDateTime(r.agreed_time),
      customerId: r.customer_id,
      contactLocationId: r.contact_location_id,
      customerRecord: CustomerRecord.Mapper.toPublic(r.customer_record),
      importance: <TaskRecord.TaskRecordImportance>r.importance,
      userGroupId: r.user_group_id,
      userGroupName: r.user_group_name,
      assignee: this.toPublicAssignee(r.assignee),
      deadline: Services.toOffsetDateTime(r.deadline),
      placeOfConsumption: this.toPublicPOC(r.place_of_consumption),
      projectId: r.project_id,
      formRecord: formRecordMapper.toPublic(r.form_record),
      stateChangeLog: this.toPublicTaskRecordLog(r.state_change_log),
      textDocumentIds: r.text_document_ids,
      fileDocumentIds: r.file_document_ids,
      invoiceIds: r.invoice_ids,
      exportState: <TaskRecord.ExportState>r.export_state,
      exportTime: Services.toOffsetDateTime(r.export_time),
      finalizationTime: Services.toOffsetDateTime(r.finalization_time),
      previousExportError: r.previous_export_error,
      linkedSurveyIds: r.linked_survey_ids ? Set.of(...r.linked_survey_ids) : Set.of(),
      linkedSurveyRecordIds: r.linked_survey_record_ids ? this.toSurveyMap(r.linked_survey_record_ids) : ImmutableMap.of(),
      estimatedTimeInMinutes: r.estimated_time_in_minutes,
      timeSpentInMinutes: r.time_spent_in_minutes,
      chatMessageCount: r.chat_message_count,
      lastChatMessage: this.toPublicMessageOpt(r.last_chat_message),
      attachmentCount: r.attachment_count,
      confirmed: r.confirmed,
      contractNumber: r.contract_number ? ContractNumber.toPublic(r.contract_number) : undefined,
      process: r.process ?
        {
          id: r.process.id,
          name: r.process.name,
          externalId: r.process.external_id,
          state: <Process.ProcessState>r.process.state,
          creatorUser: {
            id: r.process.creator_user.id,
            userName: r.process.creator_user.user_name,
            personName: r.process.creator_user.person_name
          }
        } : undefined,
      task: r.task ? {
        id: r.task.id,
        name: r.task.name,
        icon: iconService
          ? iconService.toPublicIcon(r.task.icon)
          : undefined,
        adminEditableStates: r.task.admin_editable_states.map(s => <TaskRecordStateMachine.State>s),
        taskRecordColor: r.task.task_record_color
      } : undefined,
      creatorUser: r.creator_user ? {
        id: r.creator_user.id,
        userName: r.creator_user.user_name,
        personName: r.creator_user.person_name,
      } : undefined,
      billingInfo: r.billing_info ? CustomerRecordService.toPublicBillingInfo(r.billing_info) : undefined,
      smsRating: r.sms_rating,
      smsContent: r.sms_content,
      grantedRights: r.granted_rights ? Set.of(...r.granted_rights) : Set.of(),
    };
  }

  public static toPublicMessageOpt(r?: ChatResource.Message): Chat.Message | undefined {
    if (r) {
      return Chat.Mapper.toPublicMessage(r);
    }
    return undefined;
  }

  public static toSurveyMap(surveyMap: Map<number, number[]>): ImmutableMap<number, number[]> {
    const keyValues: any[] = [];
    Object.keys(surveyMap).forEach((key: string, index: number) => {
      keyValues.push(key, surveyMap[key]);
    });
    return ImmutableMap.of(...keyValues);
  }

  public static toPublicAssignee(r?: TaskRecordResource.Assignee): TaskRecord.Assignee | undefined {
    return r ? {
      userId: r.user_id,
      mobileApplicationId: r.mobile_application_id,
      userData: r.user_data ? {
        id: r.user_data.id,
        userName: r.user_data.user_name,
        personName: r.user_data.person_name
      } : undefined
    } : undefined
  }

  public static toPublicOwnerUser(r?: TaskRecordResource.OwnerUser): TaskRecord.OwnerUser | undefined {
    if (!r) {
      return undefined;
    }
    return {
      id: r.id,
      type: r.type
    }
  }

  public static toPublicInvoiceableItem(r: TaskRecordResource.InvoiceableItem): TaskRecord.InvoiceableItem {
    return {
      stockItem: r.stock_item ? FormRecord.ResourceMapper.toPublicStockItem(r.stock_item) : undefined,
      invoiceItem: r.invoice_item ? FormRecord.ResourceMapper.toPublicInvoiceItem(r.invoice_item) : undefined
    };
  }

  public static toPublicPOC(r?: TaskRecordResource.PlaceOfConsumption): TaskRecord.PlaceOfConsumption | undefined {
    return r ? {
      address: AddressResource.Mapper.toPublicPostalAddressOpt(r.address),
      coordinate: AddressResource.Mapper.toPublicCoordinate(r.coordinate),
      geocodeStatus: <TaskRecord.GeocodeStatus>r.geocode_status
    } : undefined;
  }

  public static toPublicTaskRecordLog(log?: TaskRecordResource.LogResource): TaskRecord.Log | undefined {
    if (log) {
      return {
        id: log.id,
        logType: <TaskRecord.LogType>log.log_type,
        logTime: Services.toOffsetDateTime(log.log_time),
        userProfileId: log.user_profile_id,
        user: {
          id: log.user.id,
          personName: log.user.person_name,
          userName: log.user.user_name
        },
        taskRecord: {
          id: log.taskrecord_id,
          taskId: log.task_id,
          name: log.taskrecord_name,
          externalId: log.taskrecord_external_id
        },
        message: log.message,
        coordinate: AddressResource.Mapper.toPublicCoordinate(log.coordinate)
      }
    }
    return undefined;
  }

  static toQuickCreateRequest(request: TaskRecord.QuickCreateRequest) {
    return {
      task_id: request.taskId,
      external_id: request.externalId,
      state: request.state.toString(),
      name: request.name,
      importance: request.importance,
      description: request.description,
      customer_id: request.customerId,
      billing_info_id: request.billingInfoId,
      project: request.projectId,
      contact_location_id: request.contactLocationId,
      agreed_time: request.agreedTime && request.agreedTime.isValid() ? request.agreedTime.toUtcIsoString() : undefined,
      deadline: request.deadline && request.deadline.isValid() ? request.deadline.toUtcIsoString() : undefined,
      user_group_id: request.userGroupId,
      assignee: request.assignee ? {
        user_id: request.assignee.userId,
        mobile_application_string_id: request.assignee.mobileApplicationStringId,
        mobile_application_id: request.assignee.mobileApplicationId
      } : undefined,
      estimated_time_in_minutes: request.estimatedTimeInMinutes,
      contract_number_id: request.contractNumberId,
      place_of_consumption: request.placeOfConsumption ? {
        address: request.placeOfConsumption.address
          ? AddressResource.Mapper.fromPublicPostalAddressOpt(request.placeOfConsumption.address)
          : undefined,
        coordinate: AddressResource.Mapper.fromPublicCoordinate(request.placeOfConsumption.coordinate)
      } : undefined,
      with_location: true
    };
  }
}

export namespace TaskRecord {

  import PreviousExportError = TaskRecordResource.PreviousExportError;

  export interface Service {

    // <editor-fold desc="CRUD">

    query(request: QueryRequest): Observable<QueryResult<TaskRecord>>;

    get(request: GetRequest): Observable<TaskRecord>;

    create(request: CreateRequest): Observable<IdentityMessage>;

    update(request: UpdateRequest): Observable<TaskRecord>;

    // </editor-fold>

  }

  export enum OrderField {
    ID,
    DISABLED,
    CREATION_TIME,
    UPDATE_TIME,
    DEADLINE,
    NAME,
    TASK_NAME,
    DESCRIPTION,
    AGREED_TIME,
    CUSTOMER_NAME,
    ASSIGNEE_USER_NAME,
    CUSTOMER_POSTAL_ADDRESS,
    PROJECT_NAME,
    EXTERNAL_ID,
    POC,
    CREATOR_USER
  }

  export enum LogOrderField {
    LOG_TIME,
    USER_PROFILE_NAME,
    TASKRECORD_NAME,
  }

  export enum PdfOrderField {
    DOCUMENT_ID,
    CREATION_TIME,
    TEMPLATE_NAME
  }

  export enum ValidatedField {
    UNKNOWN,

    EXTERNAL_ID,
    NAME,
    LATITUDE,
    LONGITUDE
  }

  export enum ManualInvoiceValidatedField {
    UNKNOWN,

    INVOICE_SETTINGS,
    INVOICE_BOOK,
    INVOICE_TAG,
    CUSTOMER_RECORD,
    CURRENCY_CODE,
    ISSUE_DATE,
    DELIVERY_DATE,
    DEADLINE,
    BILLING_INFO
  }

  export type TaskRecordImportance =
    'NOT_IMPORTANT' |
    'IMPORTANT' |
    'CRITICAL';

  export class TaskRecordImportanceObject {
    importance: TaskRecordImportance;
    stringKey: string;
    badgeClass: string;
  }

  export const taskRecordImportances: TaskRecordImportanceObject[] = [
    {importance: 'NOT_IMPORTANT', stringKey: 'TASK_IMPORTANCE_NOT_IMPORTANT_TYPE', badgeClass: 'badge-not-important'},
    {importance: 'IMPORTANT', stringKey: 'TASK_IMPORTANCE_IMPORTANT_TYPE', badgeClass: 'badge-important'},
    {importance: 'CRITICAL', stringKey: 'TASK_IMPORTANCE_CRITICAL_TYPE', badgeClass: 'badge-critical'}
  ];

  export const DefImportance: TaskRecordImportance = 'NOT_IMPORTANT';

  export interface Assignee {
    mobileApplicationId?: number;
    userId?: number;
    userData?: AssigneeUserData;
  }

  export interface AssigneeUserData {
    id: number;
    userName: string;
    personName: string;
  }

  export interface PlaceOfConsumption {
    address?: Address.PostalAddressData;
    coordinate?: Address.Coordinate;
    geocodeStatus?: GeocodeStatus;
  }

  export type GeocodeStatus = 'QUEUED' |
    'DONE' |
    'PARTIAL' |
    'FAILED' |
    'MANUAL';

  export interface OwnerUser {
    id: number;
    type: string;
  }

  export interface QueryTaskRecordNamesRequest {
    name: string;
    numberOfItems: number;
  }


  export interface TaskRecord {
    taskRecordId: number;
    taskId: number;
    projectId?: number;
    creationTime: OffsetDateTime;
    updateTime: OffsetDateTime;
    disabled: boolean;
    state: TaskRecordStateMachine.State;
    helpdeskState?: HelpdeskState.State;
    importance: TaskRecord.TaskRecordImportance;
    externalId?: string;
    name: string;
    description?: string;
    ownerUser?: OwnerUser;
    customerId?: number;
    contactLocationId?: number;
    customerRecord?: CustomerRecord.CustomerRecord;
    agreedTime?: OffsetDateTime;
    deadline?: OffsetDateTime;
    userGroupId?: number;
    userGroupName?: string;
    assignee?: Assignee;
    placeOfConsumption?: PlaceOfConsumption;
    formRecord?: FormRecord.FormRecord; // non-null if requested
    stateChangeLog?: Log;
    textDocumentIds?: number[];
    fileDocumentIds?: number[];
    invoiceIds?: number[];
    exportState: ExportState;
    exportTime: OffsetDateTime;
    finalizationTime: OffsetDateTime;
    previousExportError?: PreviousExportError;
    linkedSurveyIds: Set<number>;
    linkedSurveyRecordIds: ImmutableMap<number, number[]>;
    estimatedTimeInMinutes?: number;
    timeSpentInMinutes?: number;
    chatMessageCount?: number;
    lastChatMessage?: Chat.Message;
    attachmentCount?: number;
    confirmed: boolean;
    contractNumber?: ContractNumber.ContractNumber;
    process?: ProcessData;
    task?: TaskData;
    creatorUser?: AssigneeUserData;
    billingInfo?: CustomerRecord.BillingInfo;
    smsRating?: number;
    smsContent?: string[];
    grantedRights: Set<string>;
  }

  export interface ProcessData {
    id: number;
    name: string;
    externalId: string;
    state: Process.ProcessState;
    creatorUser: AssigneeUserData;
  }

  export interface TaskData {
    id: number;
    name: string;
    icon?: Icon.Icon;
    adminEditableStates: TaskRecordStateMachine.State[];
    taskRecordColor: number;
  }

  export interface DownloadAttachmentRequest {
    taskId: number,
    taskRecordId: number,
    attachment: FileAttachment
  }

  export interface DownloadCsvRequest {
    taskId: number,
    taskRecordIds: number[]
  }

  export interface DqlQueryRequest {
    taskId: number;
    dql: string;
    withFormRecord?: boolean;
    fields?: Set<string>;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
  }

  export interface QueryRequest {
    query: GlobalQueryRequest;
    taskId: number;
  }

  export interface GlobalQueryRequest {
    withFormRecord?: boolean;
    parentDisabled?: boolean;
    rights?: Set<string>;
    fields?: Set<string>;
    customerFields?: Set<string>;
    taskIdSet?: Set<number>;
    taskName?: string;
    taskRecordIdSet?: Set<number>;
    disabled?: boolean;
    id?: number;
    name?: string;
    externalId?: string;
    description?: string;
    state?: Set<TaskRecordStateMachine.State>;
    helpdeskState?: Set<HelpdeskState.State>;
    assigneeUserName?: string;
    assigneeUserGroupName?: string;
    assigneeMobileAppName?: string;
    assigneeUserId?: number;
    assigneeUserGroupId?: number;
    assigneeMobileAppId?: string;
    customerId?: number;
    customerName?: string;
    customerPhoneNumber?: string;
    projectName?: string;
    projectIdSet?: Set<number>;
    processIdSet?: Set<number>;
    importance?: TaskRecord.TaskRecordImportance;
    exportState?: TaskRecord.ExportState;
    placeOfConsumptionCity?: string;
    placeOfConsumptionZipCode?: string;
    placeOfConsumptionStreet?: string;
    placeOfConsumptionHouseNumber?: string;
    deadlineFrom?: OffsetDateTime;
    deadlineTo?: OffsetDateTime;
    creationTimeFrom?: OffsetDateTime;
    creationTimeTo?: OffsetDateTime;
    updateTimeFrom?: OffsetDateTime;
    updateTimeTo?: OffsetDateTime;
    releaseTimeFrom?: OffsetDateTime;
    releaseTimeTo?: OffsetDateTime;
    agreedTimeFrom?: OffsetDateTime;
    agreedTimeTo?: OffsetDateTime;
    finishedTimeFrom?: OffsetDateTime;
    finishedTimeTo?: OffsetDateTime;
    helpdeskEnabled?: boolean;
    emptyIntakePrices?: boolean;
    queryText?: string;
    dqlText?: string;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
    noProgressBar?: boolean;
    stockId?: number;
    creatorUserId?: Set<number>;
    contractNumberId?: Set<number>;
    baseProject?: number;
    baseProcess?: number;
    baseMasterDataRecord?: number;
    baseCustomerRecord?: number;
    baseCustomerRecordByPhoneNumber?: number;
    baseTaskRecord?: number;
  }

  export interface RecentMessageQueryRequest {
    helpdesk?: boolean,
    paging?: PagingRequest;
    fields?: Set<string>;
    customerFields?: Set<string>;
    noProgressBar?: boolean;
  }

  export interface GetRequest {
    withFormRecord?: boolean;
    fields?: Set<string>;
    rights?: Set<string>;
    customerFields?: Set<string>;
    ignorePermissionDenied?: boolean;

    taskId: number;
    taskRecordId: number;
  }

  export interface CreateRequest {
    withFormRecord?: boolean;

    taskId: number;
    externalId?: string;
    name?: string;
    description?: string;
    importance: TaskRecord.TaskRecordImportance;
    ownerUserId?: number;
    customerId?: number;
    billingInfoId?: number;
    contactLocationId?: number;
    projectId?: number;
    agreedTime?: OffsetDateTime;
    deadline?: OffsetDateTime;
    usergroupId?: number;
    assignee: Assignee;
    placeOfConsumption?: PlaceOfConsumption;
    formRecord: FormRecord.FormRecordCreateRequest;
    textDocumentIds?: number[];
    fileDocumentIds?: number[];
    linkedSurveyIds?: number[];
    contractNumberId?: number;
    estimatedTimeInMinutes?: number;
  }

  export interface QuickCreateRequest {
    taskId: number;
    externalId?: string;
    state: TaskRecordStateMachine.State;
    name?: string;
    importance: TaskRecord.TaskRecordImportance;
    description?: string;
    customerId?: number;
    billingInfoId?: number;
    projectId?: number;
    contactLocationId?: number;
    agreedTime?: OffsetDateTime;
    deadline?: OffsetDateTime;
    userGroupId?: number;
    assignee?: AssigneeChangeRequest;
    estimatedTimeInMinutes?: number;
    contractNumberId?: number;
    placeOfConsumption?: PlaceOfConsumption;
  }

  export interface QuickCreateByCustomerRecordsRequest {
    taskId: number;
    nameTemplate: string;
    state: TaskRecordStateMachine.State;
    description?: string;
    importance: TaskRecord.TaskRecordImportance;
    customerIds: number[];
    projectId?: number;
    agreedTime?: OffsetDateTime;
    deadline?: OffsetDateTime;
    assignee?: AssigneeChangeRequest;
    userGroupId?: number;
    estimatedTimeInMinutes?: number;
  }

  export interface UpdateRequest {
    withFormRecord?: boolean;

    taskRecordId: number;
    taskId: number;
    externalId?: string;
    name: string;
    description?: string;
    importance: TaskRecord.TaskRecordImportance;
    ownerUserId?: number;
    customerId?: number;
    billingInfoId?: number;
    contactLocationId?: number;
    agreedTime?: OffsetDateTime;
    deadline?: OffsetDateTime;
    usergroupId?: number;
    assignee: Assignee;
    placeOfConsumption?: PlaceOfConsumption;
    projectId?: number;
    formRecord: FormRecord.FormRecordUpdateRequest;
    textDocumentIds?: number[];
    fileDocumentIds?: number[];
    linkedSurveyIds?: number[];
    contractNumberId?: number;
    estimatedTimeInMinutes?: number;
  }

  export interface QuickUpdateRequest {

    taskRecordId: number;
    taskId: number;
    externalId?: string;
    name: string;
    description?: string;
    importance: TaskRecord.TaskRecordImportance;
    ownerUserId?: number;
    customerId?: number;
    billingInfoId?: number;
    contactLocationId?: number;
    agreedTime?: OffsetDateTime;
    deadline?: OffsetDateTime;
    usergroupId?: number;
    assignee: Assignee;
    placeOfConsumption?: PlaceOfConsumption;
    projectId?: number;
    textDocumentIds?: number[];
    fileDocumentIds?: number[];
    linkedSurveyIds?: number[];
    contractNumberId?: number;
    estimatedTimeInMinutes?: number;
  }

  export interface CheckStateQuery {
    query: GlobalQueryRequest;
    stateChange: TaskRecordStateMachine.StateObject;
  }

  export interface ChangeStateRequest {
    newState: TaskRecordStateMachine.StateObject;
    taskRecordIdSet: Set<number>;
    stateChangeMessage?: string;
    checkUpDate?: LocalDate;
  }

  export interface ImportanceRequest {
    importance: TaskRecordImportance;
    taskRecordIdSet: Set<number>;
  }

  export interface AssigneeChangeRequest {
    taskRecordIdSet?: Set<number>;
    userId?: number;
    mobileApplicationStringId?: number;
    mobileApplicationId?: number;
  }

  export interface AssigneeChangeInProgressRequest {
    taskId: number;
    taskRecordId: number;
    userId?: number;
    mobileApplicationStringId?: number;
    mobileApplicationId?: number;
  }

  export interface DeadlineChangeRequest {
    taskRecordIdSet: Set<number>;
    deadline: OffsetDateTime;
  }

  export interface TaskRecordIdSetRequest {
    taskRecordIdSet: Set<number>;
  }

  export interface ConfirmRequest {
    taskRecordIdSet: Set<number>;
    confirmed: boolean;
  }

  export interface SendEmailRequest {
    taskId: number;
    taskRecordId: number;
    sendToMe: boolean;
    sendToAssignee: boolean;
    sendToCustomer: boolean;
    otherRecipients?: string[];

  }

  export interface TaskRecordEmailResult {
    emailResult: string;
  }

  export interface GetAttachmentRequest {
    taskId: number;
    taskRecordId: number;
  }

  export interface DeleteAttachmentRequest {
    taskId: number;
    taskRecordId: number;
    fileId: number;
  }

  export interface UpdateAttachmentRequest extends FileAttachmentUpdateRequest {
    taskId: number;
    taskRecordId: number;
  }

  export interface GetLogHistoryRequest {
    taskId: number;
    taskRecordId: number;
    withCoordinatesOnly?: boolean;
    orders?: Set<Order<LogOrderField>>;
    paging?: PagingRequest;
  }

  export interface GetGlobalLogHistoryRequest {
    orders?: Set<Order<LogOrderField>>;
    paging?: PagingRequest;
    processId?: number;
    helpdesk?: boolean;
  }

  export interface Log {
    id: number;
    logType: LogType;
    logTime: OffsetDateTime;
    userProfileId: number;
    user: {
      id: number;
      userName: string;
      personName: string;
    };
    taskRecord: {
      id: number;
      taskId: number;
      name: string;
      externalId: string;
    }
    message?: string;
    coordinate?: Address.Coordinate;
  }

  export class TaskRecordInvoiceGeneratorException {
    readonly errors: TaskRecordInvoiceGeneratorError[];

    constructor(errors: TaskRecord.TaskRecordInvoiceGeneratorError[]) {
      this.errors = errors;
    }
  }

  export class TaskRecordStockItemChangeException {
    readonly errors: StockItemChangeError[];

    constructor(errors: TaskRecord.StockItemChangeError[]) {
      this.errors = errors;
    }

  }


  export interface TaskRecordInvoiceGeneratorError {
    taskRecordId: number;
    messages: string[];
  }

  export interface StockItemChangeError {
    taskRecordId: number;
    errors: {
      stockRecordId: number;
      newAmount: number;
      currentAmount: number;
      stockRecordName: string;
    }[];
  }

  export interface RelatedInvoiceGetRequest {
    taskId: number;
    taskRecordId: number;
  }

  export interface RelatedInvoice {
    id: number;
    categoryType: Invoice.InvoiceCategoryType;
    invoiceNumber: string;
    creationTime: OffsetDateTime;
    appearanceType: Invoice.InvoiceAppearanceType;
    invoiceSettings: {
      id: number;
      profileName: string;
      companyName?: string;
      invoiceIntegrationType: InvoiceSettings.InvoiceIntegrationType;
    };
  }

  export interface ExternalExportRequest {
    taskRecordIds: Set<number>;
  }

  export interface RecentMessage {
    taskRecord: TaskRecord;
    chatMessage: Chat.Message;
  }

  export interface ManualInvoiceCreateRequest {
    taskId: number;
    taskRecordId: number;
    invoiceBookId?: number;
    invoiceSettingsId: number;
    invoiceTagId?: number;
    billingInfoId?: number;
    paymentType: Invoice.InvoicePaymentType;
    invoiceAppearanceType: Invoice.InvoiceAppearanceType;
    commentTemplate?: string;
    issueDate: LocalDate;
    deliveryDate: LocalDate;
    deadline: LocalDate;
    stockItemIds: Set<number>;
    invoiceItemIds: Set<number>;
  }

  export interface InvoiceableItem {
    invoiceItem?: FormRecord.InvoiceItem;
    stockItem?: FormRecord.StockItem;
  }

  export interface ClustersRequest {
    fields?: Set<string>;
    customerFields?: Set<string>;

    swLat: Decimal;
    swLon: Decimal;
    neLat: Decimal;
    neLon: Decimal;
    taskId?: number;

    userIds?: Set<number>;
    userGroupIds?: Set<number>;
    state?: Set<TaskRecordStateMachine.State>;

    noProgressBar?: boolean;
  }

  export interface ClustersResponse {
    clusterId: number;
    count: number;
    center: Address.Coordinate;
    taskRecord?: TaskRecord;
  }

  export interface GetPdfsRequest {
    taskId: number;
    taskRecordId: number;
    orders?: Set<Order<PdfOrderField>>;
    paging?: PagingRequest;
  }

  export interface Pdf {
    pdfDocumentId: number;
    creationTime: OffsetDateTime;
    templateName: string;
    contentHash: string;
    trigger?: {
      triggerId: number;
      instanceId: number;
      name: string;
      event: TriggerUtils.NotificationEvent;
    };
    originalTaskRecord?: {
      taskId: number;
      id: number;
      name: string;
      externalId: string;
    }
  }

  export interface DownloadPdfRequest {
    taskId: number;
    taskRecordId: number;
    documentId: number;
  }

  export interface CloneRequest {
    taskRecordIds: number[];
    targetTaskId: number;
    nameTemplate?: string;
    assignee?: AssigneeChangeRequest;
    userGroupId?: number;
    cloneFields: TaskRecordBulkCloneField[];
    openTaskRecords: boolean;
  }

  export interface ReadLinksRequest {
    taskId: number;
    taskRecordId: number;
    fields?: Set<string>;
    customerFields?: Set<string>;
  }

  export interface Link {
    destination: TaskRecord;
    type: TaskRecordLinkType;
    creationTime: OffsetDateTime;
  }

  export interface TaskRecordSupCreateRequest {
    taskRecordIds: Set<number>;
    journalCode: string;
    paymentType: Invoice.InvoicePaymentType;
    issueDate: LocalDate;
    deliveryDate: LocalDate;
    deadlineOffset: number;
  }

  export interface RevertResultResponse {
    result: RevertResult;
  }

  export interface UploadSignatureRequest extends GetRequest {
    picture: string;
    type: 'user' | 'customer';
  }

  export enum TaskRecordLinkType {
    CLONES = 'CLONES',
    CLONED_BY = 'CLONED_BY'
  }

  export enum TaskRecordBulkCloneField {
    NAME = 'NAME',
    CUSTOMER_RECORD = 'CUSTOMER_RECORD',
    CONTACT_LOCATION = 'CONTACT_LOCATION',
    POC = 'POC',
    DEADLINE = 'DEADLINE',
    IMPORTANCE = 'IMPORTANCE',
    FORM_FIELDS = 'FORM_FIELDS',
    DESCRIPTION = 'DESCRIPTION',
    AGREED_TIME = 'AGREED_TIME',
    ATTACHMENTS = 'ATTACHMENTS',
    PROJECT = 'PROJECT',
    ESTIMATED_TIME = 'ESTIMATED_TIME',
    USER_GROUP = 'USER_GROUP',
    CONTRACT_NUMBER = 'CONTRACT_NUMBER',
    ASSIGNEE = 'ASSIGNEE'
  }

  export const bulkCloneFields: LocalizedTypeObject<TaskRecordBulkCloneField>[] = [
    {type: TaskRecordBulkCloneField.NAME, stringKey: 'TASK_RECORD_BULK_CLONE_FIELD_NAME'},
    {type: TaskRecordBulkCloneField.CUSTOMER_RECORD, stringKey: 'TASK_RECORD_BULK_CLONE_FIELD_CUSTOMER_RECORD'},
    {type: TaskRecordBulkCloneField.CONTACT_LOCATION, stringKey: 'TASK_RECORD_BULK_CLONE_FIELD_CONTACT_LOCATION'},
    {type: TaskRecordBulkCloneField.POC, stringKey: 'TASK_RECORD_BULK_CLONE_FIELD_POC'},
    {type: TaskRecordBulkCloneField.DEADLINE, stringKey: 'TASK_RECORD_BULK_CLONE_FIELD_DEADLINE'},
    {type: TaskRecordBulkCloneField.IMPORTANCE, stringKey: 'TASK_RECORD_BULK_CLONE_FIELD_IMPORTANCE'},
    {type: TaskRecordBulkCloneField.FORM_FIELDS, stringKey: 'TASK_RECORD_BULK_CLONE_FIELD_FORM_FIELDS'},
    {type: TaskRecordBulkCloneField.DESCRIPTION, stringKey: 'TASK_RECORD_BULK_CLONE_FIELD_DESCRIPTION'},
    {type: TaskRecordBulkCloneField.AGREED_TIME, stringKey: 'TASK_RECORD_BULK_CLONE_FIELD_AGREED_TIME'},
    {type: TaskRecordBulkCloneField.ATTACHMENTS, stringKey: 'TASK_RECORD_BULK_CLONE_FIELD_ATTACHMENTS'},
    {type: TaskRecordBulkCloneField.PROJECT, stringKey: 'TASK_RECORD_BULK_CLONE_FIELD_PROJECT'},
    {type: TaskRecordBulkCloneField.ESTIMATED_TIME, stringKey: 'TASK_RECORD_BULK_CLONE_FIELD_ESTIMATED_TIME'},
    {type: TaskRecordBulkCloneField.USER_GROUP, stringKey: 'TASK_RECORD_BULK_CLONE_FIELD_USER_GROUP'},
    {type: TaskRecordBulkCloneField.ASSIGNEE, stringKey: 'TASK_RECORD_BULK_CLONE_FIELD_ASSIGNEE'},
    {type: TaskRecordBulkCloneField.CONTRACT_NUMBER, stringKey: 'CUSTOMER_RECORD_CONTRACT_NUMBER'},
  ];

  export enum ExternalExportResult {
    SKIPPED, EMPTY_ID_SET, INVALID_TASK_RECORD_STATE, INVALID_TASK_RECORD_TYPE, INIT_ERROR, FAILED_TO_SAVE_EXPORT_STATE,
    EACH_EXPORT_FAILED, SOME_EXPORT_DONE, EACH_EXPORT_DONE
  }

  export enum RevertResult {
    EMPTY_ID_SET,
    INVALID_TASK_RECORD_STATE,
    INIT_ERROR,
    FAILED_TO_SAVE_REVERT_STATE,
    EACH_REVERT_FAILED,
    SOME_REVERT_DONE,
    EACH_REVERT_DONE
  }

  export type ExportState =
    'NOT_EXPORTED' |
    'EXPORTED' |
    'EXPORT_FAILED' |
    'REVERTED' |
    'REVERT_FAILED';

  export class TaskRecordExportStateObject {
    state: ExportState;
    stringKey: string;
  }

  export const taskRecordExportStates: TaskRecordExportStateObject[] = [
    {state: 'NOT_EXPORTED', stringKey: 'EXPORT_STATE_NOT_EXPORTED_TYPE'},
    {state: 'EXPORTED', stringKey: 'EXPORT_STATE_EXPORTED_TYPE'},
    {state: 'EXPORT_FAILED', stringKey: 'EXPORT_STATE_EXPORT_FAILED_TYPE'},
    {state: 'REVERTED', stringKey: 'EXPORT_STATE_REVERTED_TYPE'},
    {state: 'REVERT_FAILED', stringKey: 'EXPORT_STATE_REVERT_FAILED_TYPE'}
  ];

  export type LogType =
    'CREATE_CLONE' |
    'CREATE_QUICK' |
    'CREATE_REGULAR' |
    'CREATE_API_IMPORT' |
    'UPDATE_API_IMPORT' |
    'CREATE_DOCUMENT_IMPORT' |
    'UPDATE_DOCUMENT_IMPORT' |
    'CREATE_TRIGGER' |
    'CREATE_PROCESS' |
    'ASSIGNEE_CHANGE' |
    'ASSIGN_TO_ME' |
    'STATE_OPEN' |
    'STATE_ARCHIVE' |
    'STATE_RECALL' |
    'STATE_REJECT' |
    'STATE_START' |
    'STATE_PAUSE' |
    'STATE_RESUME' |
    'STATE_SUBMIT' |
    'STATE_UNSUBMIT' |
    'STATE_FINISH' |
    'STATE_VALIDATE' |
    'STATE_INVALIDATE' |
    'STATE_RESTART' |
    'STATE_REOPEN' |
    'ATTACHMENT_CREATE' |
    'ATTACHMENT_DELETE' |
    'REVERT_SUCCEED' |
    'REVERT_FAILED' |
    'EXPORT_FAILED' |
    'EXPORT_SUCCEED';

  export const LogTypeMap: ImmutableMap<TaskRecord.LogType, string> = ImmutableMap.of(
    'CREATE_CLONE', 'TASK_RECORD_LOG_TYPE_CREATE_CLONE',
    'CREATE_QUICK', 'TASK_RECORD_LOG_TYPE_CREATE_QUICK',
    'CREATE_REGULAR', 'TASK_RECORD_LOG_TYPE_CREATE_REGULAR',
    'CREATE_API_IMPORT', 'TASK_RECORD_LOG_TYPE_CREATE_API_IMPORT',
    'UPDATE_API_IMPORT', 'TASK_RECORD_LOG_TYPE_UPDATE_API_IMPORT',
    'CREATE_DOCUMENT_IMPORT', 'TASK_RECORD_LOG_TYPE_CREATE_DOCUMENT_IMPORT',
    'UPDATE_DOCUMENT_IMPORT', 'TASK_RECORD_LOG_TYPE_UPDATE_DOCUMENT_IMPORT',
    'CREATE_TRIGGER', 'TASK_RECORD_LOG_TYPE_CREATE_TRIGGER',
    'CREATE_PROCESS', 'TASK_RECORD_LOG_TYPE_CREATE_PROCESS',
    'ASSIGNEE_CHANGE', 'TASK_RECORD_LOG_TYPE_ASSIGNEE_CHANGE',
    'ASSIGN_TO_ME', 'TASK_RECORD_LOG_TYPE_ASSIGN_TO_ME',
    'STATE_OPEN', 'TASK_RECORD_LOG_TYPE_STATE_OPEN',
    'STATE_REOPEN', 'TASK_RECORD_LOG_TYPE_STATE_REOPEN',
    'STATE_ARCHIVE', 'TASK_RECORD_LOG_TYPE_STATE_ARCHIVE',
    'STATE_RECALL', 'TASK_RECORD_LOG_TYPE_STATE_RECALL',
    'STATE_REJECT', 'TASK_RECORD_LOG_TYPE_STATE_REJECT',
    'STATE_START', 'TASK_RECORD_LOG_TYPE_STATE_START',
    'STATE_PAUSE', 'TASK_RECORD_LOG_TYPE_STATE_PAUSE',
    'STATE_RESUME', 'TASK_RECORD_LOG_TYPE_STATE_RESUME',
    'STATE_SUBMIT', 'TASK_RECORD_LOG_TYPE_STATE_SUBMIT',
    'STATE_UNSUBMIT', 'TASK_RECORD_LOG_TYPE_STATE_UNSUBMIT',
    'STATE_FINISH', 'TASK_RECORD_LOG_TYPE_STATE_FINISH',
    'STATE_VALIDATE', 'TASK_RECORD_LOG_TYPE_STATE_VALIDATE',
    'STATE_INVALIDATE', 'TASK_RECORD_LOG_TYPE_STATE_INVALIDATE',
    'STATE_RESTART', 'TASK_RECORD_LOG_TYPE_STATE_RESTART',
    'ATTACHMENT_CREATE', 'TASK_RECORD_LOG_TYPE_ATTACHMENT_CREATE',
    'ATTACHMENT_DELETE', 'TASK_RECORD_LOG_TYPE_ATTACHMENT_DELETE',
    'EXPORT_FAILED', 'TASK_RECORD_LOG_TYPE_EXPORT_FAILED',
    'EXPORT_SUCCEED', 'TASK_RECORD_LOG_TYPE_EXPORTED',
    'REVERT_FAILED', 'TASK_RECORD_LOG_TYPE_REVERT_FAILED',
    'REVERT_SUCCEED', 'TASK_RECORD_LOG_TYPE_REVERTED'
  );

  export const StateChangeLabelMap: ImmutableMap<TaskRecord.LogType, string> = ImmutableMap.of(
    'STATE_OPEN', 'TASK_RECORD_LOG_TYPE_STATE_OPEN_LABEL',
    'STATE_REOPEN', 'TASK_RECORD_LOG_TYPE_STATE_REOPEN_LABEL',
    'STATE_ARCHIVE', 'TASK_RECORD_LOG_TYPE_STATE_ARCHIVE_LABEL',
    'STATE_RECALL', 'TASK_RECORD_LOG_TYPE_STATE_RECALL_LABEL',
    'STATE_REJECT', 'TASK_RECORD_LOG_TYPE_STATE_REJECT_LABEL',
    'STATE_START', 'TASK_RECORD_LOG_TYPE_STATE_START_LABEL',
    'STATE_PAUSE', 'TASK_RECORD_LOG_TYPE_STATE_PAUSE_LABEL',
    'STATE_RESUME', 'TASK_RECORD_LOG_TYPE_STATE_RESUME_LABEL',
    'STATE_SUBMIT', 'TASK_RECORD_LOG_TYPE_STATE_SUBMIT_LABEL',
    'STATE_UNSUBMIT', 'TASK_RECORD_LOG_TYPE_STATE_UNSUBMIT_LABEL',
    'STATE_FINISH', 'TASK_RECORD_LOG_TYPE_STATE_FINISH_LABEL',
    'STATE_VALIDATE', 'TASK_RECORD_LOG_TYPE_STATE_VALIDATE_LABEL',
    'STATE_INVALIDATE', 'TASK_RECORD_LOG_TYPE_STATE_INVALIDATE_LABEL',
    'STATE_RESTART', 'TASK_RECORD_LOG_TYPE_STATE_RESTART_LABEL'
  );

  export type TaskRecordField =
    'AGREED_TIME' |
    'ASSIGNEE_USER_APPLICATION' |
    'ASSIGNEE_USER_GROUP' |
    'COORDINATES' |
    'CUSTOMER' |
    'DEADLINE' |
    'DESCRIPTION' |
    'ESTIMATED_TIME' |
    'EXTERNAL_ID' |
    'FILE_DOCUMENTS' |
    'IMPORTANCE' |
    'PLACE_OF_CONSUMPTION' |
    'PROJECT' |
    'TEXT_DOCUMENTS' |
    'CONTRACT_NUMBER' |
    'SURVEYS';

  export class TaskRecordFieldObject {
    field: TaskRecordField;
    stringKey: string;
  }

  export const taskRecordFields: TaskRecordFieldObject[] = [
    {field: 'AGREED_TIME', stringKey: 'TASK_RECORD_FIELD_AGREED_TIME'},
    {field: 'ASSIGNEE_USER_APPLICATION', stringKey: 'TASK_RECORD_FIELD_ASSIGNEE_USER_APPLICATION'},
    {field: 'ASSIGNEE_USER_GROUP', stringKey: 'TASK_RECORD_FIELD_ASSIGNEE_USER_GROUP'},
    {field: 'COORDINATES', stringKey: 'TASK_RECORD_FIELD_COORDINATES'},
    {field: 'CUSTOMER', stringKey: 'TASK_RECORD_FIELD_CUSTOMER'},
    {field: 'DEADLINE', stringKey: 'TASK_RECORD_FIELD_DEADLINE'},
    {field: 'DESCRIPTION', stringKey: 'TASK_RECORD_FIELD_DESCRIPTION'},
    {field: 'ESTIMATED_TIME', stringKey: 'TASK_RECORD_FIELD_ESTIMATED_TIME'},
    {field: 'EXTERNAL_ID', stringKey: 'TASK_RECORD_FIELD_EXTERNAL_ID'},
    {field: 'FILE_DOCUMENTS', stringKey: 'TASK_RECORD_FIELD_FILE_DOCUMENTS'},
    {field: 'IMPORTANCE', stringKey: 'TASK_RECORD_FIELD_IMPORTANCE'},
    {field: 'PLACE_OF_CONSUMPTION', stringKey: 'TASK_RECORD_FIELD_PLACE_OF_CONSUMPTION'},
    {field: 'PROJECT', stringKey: 'TASK_RECORD_FIELD_PROJECT'},
    {field: 'TEXT_DOCUMENTS', stringKey: 'TASK_RECORD_FIELD_TEXT_DOCUMENTS'},
    {field: 'SURVEYS', stringKey: 'TASK_RECORD_FIELD_SURVEYS'},
    {field: 'CONTRACT_NUMBER', stringKey: 'CUSTOMER_RECORD_CONTRACT_NUMBER'},
  ];

  export const taskRecordRequirableFields: TaskRecordFieldObject[] = [
    {field: 'AGREED_TIME', stringKey: 'TASK_RECORD_FIELD_AGREED_TIME'},
    {field: 'ASSIGNEE_USER_APPLICATION', stringKey: 'TASK_RECORD_FIELD_ASSIGNEE_USER_APPLICATION'},
    {field: 'ASSIGNEE_USER_GROUP', stringKey: 'TASK_RECORD_FIELD_ASSIGNEE_USER_GROUP'},
    {field: 'CUSTOMER', stringKey: 'TASK_RECORD_FIELD_CUSTOMER'},
    {field: 'DEADLINE', stringKey: 'TASK_RECORD_FIELD_DEADLINE'},
    {field: 'DESCRIPTION', stringKey: 'TASK_RECORD_FIELD_DESCRIPTION'},
    {field: 'ESTIMATED_TIME', stringKey: 'TASK_RECORD_FIELD_ESTIMATED_TIME'},
    {field: 'FILE_DOCUMENTS', stringKey: 'TASK_RECORD_FIELD_FILE_DOCUMENTS'},
    {field: 'IMPORTANCE', stringKey: 'TASK_RECORD_FIELD_IMPORTANCE'},
    {field: 'PROJECT', stringKey: 'TASK_RECORD_FIELD_PROJECT'},
    {field: 'TEXT_DOCUMENTS', stringKey: 'TASK_RECORD_FIELD_TEXT_DOCUMENTS'},
    {field: 'SURVEYS', stringKey: 'TASK_RECORD_FIELD_SURVEYS'},
  ];

  export type TaskRecordAssigneeFilter =
    'USER' |
    'GROUP' |
    'USER_OR_GROUP' |
    'NONE';

  export class TaskRecordAssigneeFilterObject {
    field: TaskRecordAssigneeFilter;
    stringKey: string;
  }

  export const taskRecordAssigneeFilters: TaskRecordAssigneeFilterObject[] = [
    {field: 'USER', stringKey: 'TASK_RECORD_ASSIGNEE_FILTER_USER'},
    {field: 'GROUP', stringKey: 'TASK_RECORD_ASSIGNEE_FILTER_GROUP'},
    {field: 'USER_OR_GROUP', stringKey: 'TASK_RECORD_ASSIGNEE_FILTER_USER_OR_GROUP'},
    {field: 'NONE', stringKey: 'TASK_RECORD_ASSIGNEE_FILTER_NONE'},
  ];

  export class ExportResultMapper {

    public static map(result: TaskRecordResource.ExternalExportResultCode): TaskRecord.ExternalExportResult {
      switch (result) {
        case 'EACH_EXPORT_DONE':
          return TaskRecord.ExternalExportResult.EACH_EXPORT_DONE;
        case 'EACH_EXPORT_FAILED':
          return TaskRecord.ExternalExportResult.EACH_EXPORT_FAILED;
        case 'EMPTY_ID_SET':
          return TaskRecord.ExternalExportResult.EMPTY_ID_SET;
        case 'FAILED_TO_SAVE_EXPORT_STATE':
          return TaskRecord.ExternalExportResult.FAILED_TO_SAVE_EXPORT_STATE;
        case 'INIT_ERROR':
          return TaskRecord.ExternalExportResult.INIT_ERROR;
        case 'INVALID_TASK_RECORD_STATE':
          return TaskRecord.ExternalExportResult.INVALID_TASK_RECORD_STATE;
        case 'INVALID_TASK_RECORD_TYPE':
          return TaskRecord.ExternalExportResult.INVALID_TASK_RECORD_TYPE;
        case 'SKIPPED':
          return TaskRecord.ExternalExportResult.SKIPPED;
        case 'SOME_EXPORT_DONE':
          return TaskRecord.ExternalExportResult.SOME_EXPORT_DONE;


      }
    }
  }

  export class RevertResultMapper {

    public static map(result: TaskRecordResource.RevertResult): TaskRecord.RevertResult {
      switch (result) {
        case 'EACH_REVERT_DONE':
          return TaskRecord.RevertResult.EACH_REVERT_DONE;
        case 'EACH_REVERT_FAILED':
          return TaskRecord.RevertResult.EACH_REVERT_FAILED;
        case 'EMPTY_ID_SET':
          return TaskRecord.RevertResult.EMPTY_ID_SET;
        case 'FAILED_TO_SAVE_REVERT_STATE':
          return TaskRecord.RevertResult.FAILED_TO_SAVE_REVERT_STATE;
        case 'INIT_ERROR':
          return TaskRecord.RevertResult.INIT_ERROR;
        case 'INVALID_TASK_RECORD_STATE':
          return TaskRecord.RevertResult.INVALID_TASK_RECORD_STATE;
        case 'SOME_REVERT_DONE':
          return TaskRecord.RevertResult.SOME_REVERT_DONE;


      }
    }
  }

}

// <editor-fold desc="Internal">

class LogKeys {
  private static readonly LOG_TIME = 'log_time';
  private static readonly USER_PROFILE_NAME = 'user_profile_name';
  private static readonly TASKRECORD_NAME = 'taskrecord_name';

  private static readonly orderFieldKeyMap: ImmutableMap<TaskRecord.LogOrderField, string> = ImmutableMap.of(
    TaskRecord.LogOrderField.LOG_TIME, LogKeys.LOG_TIME,
    TaskRecord.LogOrderField.USER_PROFILE_NAME, LogKeys.USER_PROFILE_NAME,
    TaskRecord.LogOrderField.TASKRECORD_NAME, LogKeys.TASKRECORD_NAME
  );

  public static toOrderFieldKey(field: TaskRecord.LogOrderField): string {
    return LogKeys.orderFieldKeyMap.get(field)!;
  }

}

class PdfKeys {
  private static readonly DOCUMENT_ID = 'document_id';
  private static readonly CREATION_TIME = 'creation_time';
  private static readonly TEMPLATE_NAME = 'template_name';

  private static readonly orderFieldKeyMap: ImmutableMap<TaskRecord.PdfOrderField, string> = ImmutableMap.of(
    TaskRecord.PdfOrderField.DOCUMENT_ID, PdfKeys.DOCUMENT_ID,
    TaskRecord.PdfOrderField.CREATION_TIME, PdfKeys.CREATION_TIME,
    TaskRecord.PdfOrderField.TEMPLATE_NAME, PdfKeys.TEMPLATE_NAME
  );

  public static toOrderFieldKey(field: TaskRecord.PdfOrderField): string {
    return PdfKeys.orderFieldKeyMap.get(field)!;
  }

}

class Keys {

  private static readonly ID = 'id';
  private static readonly DISABLED = 'disabled';
  private static readonly CREATION_TIME = 'creation_time';
  private static readonly UPDATE_TIME = 'update_time';
  private static readonly DEADLINE = 'deadline';
  private static readonly BILLING_INFO = 'billing_info';
  private static readonly EXTERNAL_ID = 'external_id';
  private static readonly NAME = 'name';
  private static readonly TASK_NAME = 'task_name';
  private static readonly DESCRIPTION = 'description';
  private static readonly AGREED_TIME = 'agreed_time';
  private static readonly CUSTOMER_NAME = 'customer_name';
  private static readonly ASSIGNEE_USER_NAME = 'assignee_user_name';
  private static readonly CUSTOMER_POSTAL_ADDRESS = 'customer_postal_address';
  private static readonly POC = 'place_of_consumption';
  private static readonly CREATOR_USER = 'creator_user';
  private static readonly PROJECT_NAME = 'project_name';

  private static readonly INVOICE_SETTINGS = 'invoice_settings';
  private static readonly INVOICE_BOOK = 'invoice_book';
  private static readonly INVOICE_TAG = 'invoice_tag_id';
  private static readonly CUSTOMER_RECORD = 'customer_record';
  private static readonly CURRENCY_CODE = 'currency_code';
  private static readonly ISSUE_DATE = 'issue_date';
  private static readonly DELIVERY_DATE = 'delivery_date';

  private static readonly orderFieldKeyMap: ImmutableMap<TaskRecord.OrderField, string> = ImmutableMap.of(
    TaskRecord.OrderField.ID, Keys.ID,
    TaskRecord.OrderField.DISABLED, Keys.DISABLED,
    TaskRecord.OrderField.CREATION_TIME, Keys.CREATION_TIME,
    TaskRecord.OrderField.UPDATE_TIME, Keys.UPDATE_TIME,
    TaskRecord.OrderField.DEADLINE, Keys.DEADLINE,
    TaskRecord.OrderField.NAME, Keys.NAME,
    TaskRecord.OrderField.TASK_NAME, Keys.TASK_NAME,
    TaskRecord.OrderField.DESCRIPTION, Keys.DESCRIPTION,
    TaskRecord.OrderField.AGREED_TIME, Keys.AGREED_TIME,
    TaskRecord.OrderField.CUSTOMER_NAME, Keys.CUSTOMER_NAME,
    TaskRecord.OrderField.ASSIGNEE_USER_NAME, Keys.ASSIGNEE_USER_NAME,
    TaskRecord.OrderField.CUSTOMER_POSTAL_ADDRESS, Keys.CUSTOMER_POSTAL_ADDRESS,
    TaskRecord.OrderField.PROJECT_NAME, Keys.PROJECT_NAME,
    TaskRecord.OrderField.EXTERNAL_ID, Keys.EXTERNAL_ID,
    TaskRecord.OrderField.POC, Keys.POC,
    TaskRecord.OrderField.CREATOR_USER, Keys.CREATOR_USER,
  );

  private static readonly keyValidatedFieldMap: ImmutableMap<string, TaskRecord.ValidatedField> = ImmutableMap.of(
    Keys.EXTERNAL_ID, TaskRecord.ValidatedField.EXTERNAL_ID,
    Keys.NAME, TaskRecord.ValidatedField.NAME,
  );

  private static readonly keyManualInvoiceValidatedFieldMap: ImmutableMap<string, TaskRecord.ManualInvoiceValidatedField> = ImmutableMap.of(
    Keys.INVOICE_SETTINGS, TaskRecord.ManualInvoiceValidatedField.INVOICE_SETTINGS,
    Keys.INVOICE_BOOK, TaskRecord.ManualInvoiceValidatedField.INVOICE_BOOK,
    Keys.INVOICE_TAG, TaskRecord.ManualInvoiceValidatedField.INVOICE_TAG,
    Keys.CUSTOMER_RECORD, TaskRecord.ManualInvoiceValidatedField.CUSTOMER_RECORD,
    Keys.CURRENCY_CODE, TaskRecord.ManualInvoiceValidatedField.CURRENCY_CODE,
    Keys.ISSUE_DATE, TaskRecord.ManualInvoiceValidatedField.ISSUE_DATE,
    Keys.DELIVERY_DATE, TaskRecord.ManualInvoiceValidatedField.DELIVERY_DATE,
    Keys.DEADLINE, TaskRecord.ManualInvoiceValidatedField.DEADLINE,
    Keys.BILLING_INFO, TaskRecord.ManualInvoiceValidatedField.BILLING_INFO,
  );

  public static toOrderFieldKey(field: TaskRecord.OrderField): string {
    return Keys.orderFieldKeyMap.get(field)!;
  }

  public static toValidatedField(fieldKey: string): TaskRecord.ValidatedField {
    return Keys.keyValidatedFieldMap.get(fieldKey, TaskRecord.ValidatedField.UNKNOWN);
  }

  public static toManualInvoiceValidatedField(fieldKey: string): TaskRecord.ManualInvoiceValidatedField {
    return Keys.keyManualInvoiceValidatedFieldMap.get(fieldKey, TaskRecord.ManualInvoiceValidatedField.UNKNOWN);
  }

}


// </editor-fold>
