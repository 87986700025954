import { Workflow } from '../../../lib/workflow/workflow.service';
import { WorkflowRightModel } from '../../../lib/workflow/workflow-right.model';
import { GrantedPermissionSetResolver } from '../../../lib/right.service';

export class WorkflowBaseModel {

  private readonly _inheritanceModeKey: string;
  private readonly _versionStateKey: string;
  private readonly _rights: WorkflowRightModel;

  constructor(private readonly _workflow?: Workflow.Workflow) {
    if (_workflow) {
      const mode = Workflow.inheritanceModes.find(im => im.mode === _workflow.inheritanceMode);
      const versionState = Workflow.versionStates.find(im => im.state === _workflow.versionState);
      this._inheritanceModeKey = mode ? mode.stringKey : '';
      this._versionStateKey = versionState ? versionState.stringKey : '';
      this._rights = new WorkflowRightModel(GrantedPermissionSetResolver.byGrantedRights(_workflow.grantedRights));
    }
    else {
      this._inheritanceModeKey = '';
      this._versionStateKey = '';
      this._rights = new WorkflowRightModel(GrantedPermissionSetResolver.empty());
    }

  }

  get workflow(): Workflow.Workflow | undefined {
    return this._workflow;
  }

  get rights(): WorkflowRightModel {
    return this._rights;
  }

  getLastModifierName(): string {
    if (!this.workflow) {
      return '';
    }
    return this.workflow.lastModifiedUser!.personName;
  }


  get inheritanceModeKey(): string {
    return this._inheritanceModeKey;
  }

  get versionStateKey(): string {
    return this._versionStateKey;
  }

  toCloneRequest(): Workflow.CloneRequest | undefined {
    if (!this.workflow) {
      return undefined;
    }
    return {
      id: this.workflow.id,
      name: this.workflow.name + '_clone',
      description: this.workflow.description,
      inheritanceMode: this.workflow.inheritanceMode!,
      icon: this.workflow.icon,
      stateMachine: { // some random location
        entryPoint: this.workflow.stateMachine!.entryPoint
      },
      startWithFirstTask: this.workflow.startWithFirstTask,
      processConfig: this.workflow.processConfig!
    };
  }

  getIcon() {
    return this.workflow?.icon;
  }
}
