/* eslint-disable */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageTemplate, MessageTemplateService } from '../../../lib/message-template/message-template.service';
import { OptionItem, QueryFieldModel, SelectUtils, UiConstants } from '../../../util/core-utils';
import { InputMask } from '../../../util/input-masks';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { RightModel } from '../../../app.rights';
import { OrderType, QueryResult } from '../../../lib/util/services';
import { TranslateService } from '@ngx-translate/core';
import { RightResolver, RightService } from '../../../lib/right.service';
import { combineLatest, Observable } from 'rxjs';
import { Arrays } from '../../../lib/util/arrays';
import { Strings } from '../../../lib/util/strings';
import { Set } from 'immutable';
import { StateName } from '../../../app.state-names';
import { EmptyMessage } from '../../../lib/util/messages';
import { Dates, OffsetDateTime } from '../../../lib/util/dates';
import { MessageTemplateSearch, MessageTemplateSearchService } from '../../../lib/message-template/message-template-search.service';
import { DisabledEnum, DisabledItem } from '../../../util/search-utils';
import { DropdownItemType } from '../../../shared/dropdown/dropdown-item/dropdown-item-type';
/* eslint-enable */

@Component({
  selector: 'app-notification-template-list',
  templateUrl: './notification-template-list.component.html',
  styleUrls: ['./notification-template-list.component.scss']
})
export class NotificationTemplateListComponent implements OnInit, OnDestroy {
  MessageTemplate = MessageTemplate;
  SelectUtils = SelectUtils;
  InputMask = InputMask;
  UiConstants = UiConstants;
  DropdownItemType = DropdownItemType;

  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  showSearch: boolean = false;
  rightModel: RightModel = RightModel.empty();
  queryModel: QueryFieldModel<MessageTemplate.OrderField> = new QueryFieldModel(MessageTemplate.OrderField.ID, OrderType.DESC);

  searchResult: MessageTemplateSearch.SearchDataResult;
  searchModel: MessageTemplateSearchModel = new MessageTemplateSearchModel();
  disabledItems: DisabledItem[] = [];

  messageTemplateList: MessageTemplateModel[] = [];

  constructor(private messageTemplateService: MessageTemplateService,
              private translateService: TranslateService,
              private rightService: RightService,
              private messageTemplateSearchService: MessageTemplateSearchService) { }

  ngOnInit() {
    this.loadRightModels();
    this.loadSearch(() => {
      this.showSearch = !this.searchModel.isEmpty();
      this.initDisabledOptions(DisabledEnum.FALSE);
      this.loadList();
    });
    this.initBreadcrumb();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  private loadSearch(completion: () => void) {
    const obs: Observable<SearchLoadResult> = combineLatest(
      this.messageTemplateSearchService.getSearchData({}),
      (storedSearchData: MessageTemplateSearch.SearchDataResult) => {
        const result: SearchLoadResult = {
          storedSearchData: storedSearchData
        };
        return result;
      }
    );
    obs.subscribe(
      (result: SearchLoadResult) => {
        this.searchResult = result.storedSearchData;
        this.postInitSearch(result.storedSearchData, completion);
      }
    );
  }

  private postInitSearch(storedSearchData: MessageTemplateSearch.SearchDataResult, completion: () => void) {
    this.queryModel.itemsPerPage = storedSearchData.searchData.itemsPerPage;
    this.queryModel.currentPage = storedSearchData.searchData.pageNumber;
    this.queryModel.setOrder(storedSearchData.searchData.order);
    this.searchModel.name = storedSearchData.searchData.name;
    this.searchModel.disabled = storedSearchData.searchData.disabled;
    completion()
  }

  private initDisabledOptions(initValue: DisabledEnum) {
    this.disabledItems = [];
    const disabledEnums: DisabledEnum[] = [DisabledEnum.NONE, DisabledEnum.FALSE, DisabledEnum.TRUE];
    Arrays.iterateByIndex(disabledEnums, (key) => {
      const item = new DisabledItem();
      item.id = key;
      this.translateService.get('COMMON_VALUE_DISABLED_ENUM_' + key).subscribe(
        (text: string) => {
          item.text = text;
        }
      );
      this.disabledItems.push(item);
      if (!this.searchModel.disabled) {
        if (key === initValue) {
          this.searchModel.disabled = item;
        }
      }
    });
  }

  loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();
    const disabled: boolean | undefined = !this.searchModel.disabled ||
    this.searchModel.disabled.id === DisabledEnum.NONE ?
      undefined : this.searchModel.disabled.id === DisabledEnum.TRUE;
    this.messageTemplateService.query({
      name: Strings.undefinedOrNonEmpty(this.searchModel.name),
      disabled: disabled,
      orders: Set.of(order),
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined
    }).subscribe((result: QueryResult<MessageTemplate.MessageTemplate>) => {
      this.messageTemplateList = [];
      result.items.forEach((messageTemplate: MessageTemplate.MessageTemplate) => {
        const messageTemplateModel = new MessageTemplateModel();
        messageTemplateModel.id = messageTemplate.id + '';
        messageTemplateModel.externalId = messageTemplate.externalId;
        messageTemplateModel.name = messageTemplate.name;
        messageTemplateModel.creationTime = messageTemplate.creationTime;
        messageTemplateModel.updateTime = messageTemplate.updateTime;
        messageTemplateModel.disabled = messageTemplate.disabled;
        this.messageTemplateList.push(messageTemplateModel);
      });
      this.queryModel.currentPage = requestedPage;
      this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
    });
  }

  onSearchClicked() {
    this.loadList(1);
  }

  onSearchReset() {
    this.messageTemplateSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.showSearch = true;
          this.loadList(1);
        });
      }
    );
  }

  private saveSearch() {
    const request = {
      searchData: {
        itemsPerPage: this.queryModel.itemsPerPage,
        pageNumber: this.queryModel.currentPage,
        order: this.queryModel.getOrder(),
        name: this.searchModel.name,
        disabled: this.searchModel.disabled,
      }
    };
    this.messageTemplateSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  initBreadcrumb() {
    this.translateService.get('NOTIFICATION_TEMPLATE_LIST_HEADER').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  orderBy(field: MessageTemplate.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  setDisabled(event: any, messageTemplate: MessageTemplate.MessageTemplate, disabled: boolean) {
    this.messageTemplateService.setDisabled({
      id: messageTemplate.id,
      disabled: disabled
    }).subscribe(
      (result: EmptyMessage) => {
        this.loadList();
      },
      (error: any) => {
        this.loadList();
      }
    );
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  ngOnDestroy() {
    this.saveSearch();
  }

}

class MessageTemplateModel {
  id: string = '';
  externalId: string = '';
  name: string = '';
  creationTime: OffsetDateTime = Dates.emptyOffsetDateTime();
  updateTime: OffsetDateTime = Dates.emptyOffsetDateTime();
  disabled = false;
}

class MessageTemplateSearchModel {
  name: string;
  disabled?: DisabledItem;

  public isEmpty(): boolean {
    return this.name.length === 0
      && OptionItem.idOrUndefined(this.disabled) === undefined
      ;
  }

  public clear() {
    this.name = '';
    this.disabled = undefined;
  }

  constructor() {
  }

}

interface SearchLoadResult {
  storedSearchData: MessageTemplateSearch.SearchDataResult,
}
