import { ANTLRInputStream, CommonTokenStream } from 'antlr4ts';
import { TypedDqlListenerImpl } from './typed-dql-listener.impl';
import { DqlFieldModel, PureDqlQueryBuilder } from '../../dql-search-container/dql-search-container.model';
import { ParseTreeWalker } from 'antlr4ts/tree';
import { TypedDqlLexer } from '../../../../util/generated/dql/typed/TypedDqlLexer';
import { TypedDqlParser as TypedDqlParserx } from '../../../../util/generated/dql/typed/TypedDqlParser';
import { DqlQueryParser } from '../dql-query-parser';

export class TypedDqlQueryParser implements DqlQueryParser {

  private readonly listener: TypedDqlListenerImpl;

  constructor(private fields: DqlFieldModel[]) {
    this.listener = new TypedDqlListenerImpl(this.fields);

  }

  public parse(dqlQuery: string): PureDqlQueryBuilder {
    // Create the lexer and parser
    const inputStream = new ANTLRInputStream(dqlQuery);
    const lexer = new TypedDqlLexer(inputStream);
    const tokenStream = new CommonTokenStream(lexer);
    const parser = new TypedDqlParserx(tokenStream);

    const tree = parser.sentence();

    ParseTreeWalker.DEFAULT.walk(this.listener, tree);
    return this.listener.queryBuilder;
  }

}
