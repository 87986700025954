/* eslint-disable */
import { AfterViewInit, Component, OnInit, ViewChild, } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Transition, UIRouter } from '@uirouter/angular';
import { combineLatest, Observable } from 'rxjs';
import { MasterDataRecord, MasterDataRecordService } from '../../../../lib/masterdata/master-data-record.service';
import { MasterData, MasterDataService } from '../../../../lib/masterdata/master-data.service';
import { MasterDataRecordBase } from '../masterdata-record-base/masterdata-record-base-view';
import { MasterDataRecordBaseComponent } from '../masterdata-record-base/masterdata-record-base.component';
import { FormRecordContainer } from '../../../form/form-record/form-record-container';
import { FormRecordContainerComponent } from '../../../form/form-record/form-record-container.component';
import { Form } from '../../../../lib/form/form.service';
import { ConfigurationService, } from '../../../../lib/core-ext/configuration.service';
import { BreadcrumbParent } from '../../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { StateName } from '../../../../app.state-names';
import { TranslateService } from '@ngx-translate/core';
import { GrantedPermissionSetResolver, RightResolver, RightService } from '../../../../lib/right.service';
import { MasterDataRecordRightModel } from '../../../../lib/masterdata/master-data-record-right.model';
import { OperationRights } from '../../../../app.right-definitions';
import { Set } from 'immutable';
import { RightModel } from '../../../../app.rights';
/* eslint-enable */

@Component({
  selector: 'app-masterdata-record-detail',
  templateUrl: 'masterdata-record-detail.component.html',
  styleUrls: ['masterdata-record-detail.component.scss'],
})
export class MasterDataRecordDetailComponent implements OnInit, AfterViewInit {

  @ViewChild(MasterDataRecordBaseComponent, { static: true })
  baseView: MasterDataRecordBase.View;

  @ViewChild(FormRecordContainerComponent, { static: true })
  formRecordContainerView: FormRecordContainer.View;

  @ViewChild('f', { static: true })
  fForm: NgForm;

  forceSaveButton: boolean = false;

  masterDataId: number;
  masterDataRecordId: number;
  breadcrumbSelf: string;
  breadcrumbParents: BreadcrumbParent[] = [];
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  masterdataName: string;
  masterDataRecordRightModel: MasterDataRecordRightModel = new MasterDataRecordRightModel(GrantedPermissionSetResolver.empty());
  rightModel: RightModel = RightModel.empty();

  private combinedResult?: CombinedResult;

  constructor(
    private configurationService: ConfigurationService,
    private masterDataService: MasterDataService,
    private masterDataRecordService: MasterDataRecordService,
    private translateService: TranslateService,
    private rightService: RightService,
    private uiRouter: UIRouter,
    private transition: Transition) {
    this.masterDataId = this.transition.params().masterDataId;
    this.masterDataRecordId = this.transition.params().masterDataRecordId;
  }

  ngOnInit(): void {
    this.loadRightModels();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  ngAfterViewInit(): void {
    this.createCombinedObservable().subscribe(
      (result: CombinedResult) => {
        this.breadcrumbSelf = result.masterDataRecord.name ? result.masterDataRecord.name : result.masterDataRecord.externalId!;
        this.masterdataName = result.masterData.name;
        this.masterDataRecordRightModel
          = new MasterDataRecordRightModel(GrantedPermissionSetResolver.byGrantedRights(result.masterDataRecord.grantedRights));
        this.baseView.loadMasterDataRecord({
          masterDataRecord: result.masterDataRecord
        });
        this.formRecordContainerView.loadFormRecord({
          configuration: this.configurationService.getConfiguration(),
          form: result.form,
          record: result.masterDataRecord.formRecord,
        });
        this.combinedResult = result;
        this.forceSaveButton = !Form.Forms.hasVisibleGroup(result.form);
      },
      () => {},
      () => {
        this.translateService.get('MENU_NAVBAR_MASTERDATA_RECORD').subscribe(
          (result: string) => {
            this.breadcrumbParents.push({name: result, uiSref: StateName.MASTERDATA_DASHBOARD});
          }
        );
        this.breadcrumbParents.push({
          name: this.masterdataName,
          uiSref: StateName.MASTERDATA_RECORD_LIST,
          uiParam: {id: this.masterDataId}
        });
      }
    );
  }

  nothing() {
  }

  private createCombinedObservable(): Observable<CombinedResult> {
    const rights = Set.of(
      OperationRights.MASTER_DATA_RECORD_CREATE,
      OperationRights.MASTER_DATA_RECORD_UPDATE
    );
    return combineLatest(
      this.masterDataService.get({
        masterDataId: this.masterDataId,
        withForm: true
      }),
      this.masterDataRecordService.get({
        withFormRecord: true,
        masterDataId: this.masterDataId,
        masterDataRecordId: this.masterDataRecordId,
        rights: rights
      }),
      (
        masterData: MasterData.MasterData,
        masterDataRecord: MasterDataRecord.MasterDataRecord) => {
        return {
          form: masterData.form!,
          masterData: masterData,
          masterDataRecord: masterDataRecord
        }
      }
    );
  }

}

interface CombinedResult {
  form: Form.Form;
  masterData: MasterData.MasterData;
  masterDataRecord: MasterDataRecord.MasterDataRecord;
}
