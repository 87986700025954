/* eslint-disable */
import { Component, } from '@angular/core';
import {
  DisplayedFormFieldModel,
  FormRecordFieldContext,
  FormRecordFieldValueUpdateArgs,
  FormRecordFieldView,
  FormRecordFieldViewContext,
  FormRecordFieldViewModel,
  SetTmpReadonlyArgs,
  SetTmpReadonlyResult,
} from '../../../../../util/form/form-utils';
import { Form } from '../../../../../lib/form/form.service';
import {AbstractControl, FormBuilder, FormGroup, Validators,} from '@angular/forms';
import { FormRef, ForwardingFormRef, LocalFormGroupValidationErrors, } from '../../../../../lib/util/services';
import { FormRecord } from '../../../../../lib/form/form-record.service';
import { AppValidators } from '../../../../../util/app-validators';
import { Models } from '../../../../../util/model-utils';
import { List, Set } from 'immutable';
import { MasterDataRecord, } from '../../../../../lib/masterdata/master-data-record.service';
import { FieldActivationState, FieldActivationStateResolver, } from '../../../../../util/form/form-editors';
import { Command } from '../../../../../util/command';
import { OptionItem, SelectUtils } from '../../../../../util/core-utils';
import { Angular2Multiselects } from '../../../../../util/multiselect';
import { Observable } from 'rxjs';
import { ofCondition } from '../../../../../util/wait';
import { FormRecordInactivityManager } from '../../manager/form-record-inactivity-manager';
import { Arrays } from '../../../../../lib/util/arrays';
import { MasterDataFilterType } from '../../../../../util/form/form-field-masterdata-filter-type';
import { MasterDataRecordRightModel } from '../../../../../lib/masterdata/master-data-record-right.model';
import { FormRecordMasterDataFieldLoader } from './form-record-master-data-field.loader';
import { MatDialog } from '@angular/material/dialog';
import { MasterDataRecordSelectorDialogComponent } from './form-record-master-data-add-dialog/master-data-record-selector-dialog.component';

/* eslint-enable */

@Component({
  selector: 'app-form-record-master-data-field',
  templateUrl: 'form-record-master-data-field.component.html',
  styleUrls: ['form-record-master-data-field.component.scss'],
})
export class FormRecordMasterDataFieldComponent implements FormRecordFieldView {

  SelectUtils = SelectUtils;

  public readonly selector: Form.FieldDataTypeSelector.MASTER_DATA;

  formGroup: FormGroup;

  model: Model = new Model();

  // context fields are always optional
  formRecordFieldContext?: FormRecordFieldContext;
  formRecordInactivityManager?: FormRecordInactivityManager;
  private fieldId?: number;
  htmlForm?: FormRef;

  tmpReadonly: boolean = false;

  optionalValue: boolean = false;

  masterDataId: number;

  private customerRecordId?: number;
  private contactLocationId?: number;

  availableMasterDataFilters: OptionItem<MasterDataFilterType.MasterDataFilterType>[] = [];
  masterDataFilter: OptionItem<MasterDataFilterType.MasterDataFilterType>;

  dropdownSettings: Angular2Multiselects.Settings;

  private defText: string;

  private formGroupValidationErrors: LocalFormGroupValidationErrors;
  private readonlyFormFn: () => boolean = () => true;

  private readonlyFieldFn: () => boolean = () => false;
  private hiddenFieldFn: () => boolean = () => false;

  get nonEditable(): boolean {
    return FieldActivationStateResolver.isNonEditable(
      this.fieldActivationState,
    );
  }

  get requiredDisabled(): boolean {
    return FieldActivationStateResolver.isRequiredDisabled(
      this.fieldActivationState,
    );
  }

  get required(): boolean {
    const optional = this.optionalValue;
    const requiredDisabled = this.requiredDisabled;
    return !optional && !requiredDisabled;
  }


  private get fieldActivationState(): FieldActivationState {
    return FieldActivationStateResolver.resolveFieldActivationState({
      readonlyFormFn: () => this.readonlyFormFn(),
      readonlyFieldFn: () => this.readonlyFieldFn(),
      tmpReadonlyFieldFn: () => this.tmpReadonly,
    });
  }

  private get canApplyDefaultValue(): boolean {
    return FieldActivationStateResolver.canApplyDefaultValue({
      formRecordFieldContext: () => {
        return this.formRecordFieldContext;
      },
      fieldActivationState: () => {
        return this.fieldActivationState;
      },
    });
  }

  private get valueFormControl(): AbstractControl {
    return this.formGroup.get('value')!;
  }

  private get reqContext() {
    return this.formRecordFieldContext!;
  }

  private get reqAttrs(): Form.FieldDataTypeMasterDataAttributes {
    return this.formRecordFieldContext!.field.dataType.masterDataAttributes!;
  }

  private get reqDefaultValue(): MasterDataRecordFormFieldModel[] {
    return []; // for now it has no default value
  }

  constructor(fb: FormBuilder,
              private formRecordMasterDataFieldLoader: FormRecordMasterDataFieldLoader,
              private dialog: MatDialog) {
    this.formGroup = this.createFormGroup(fb);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(
      this.createForwardingHtmlForm(),
      this.formGroup,
    );
  }

  setTmpReadonly(args: SetTmpReadonlyArgs): Command<SetTmpReadonlyResult> {
    const masterDataItemToIdText = (item: MasterDataRecordFormFieldModel) => {
      return item.masterDataRecordId === null ? null : item.masterDataRecordId.toString();
    };
    let previousTmpReadonly;
    let previousValues;
    return {
      execute: async () => {
        await ofCondition({
          condition: () => {
            return this.model.loaded;
          }
        });
        previousTmpReadonly = this.tmpReadonly;
        previousValues = List.of(...this.model.values).toArray();
        const inactiveValue: MasterDataRecordFormFieldModel[] = [];
        const defaultValue: MasterDataRecordFormFieldModel[] = this.reqDefaultValue;
        let changed = false;
        if (this.tmpReadonly !== args.tmpReadonly) {
          if (args.tmpReadonly) {
            changed = FieldActivationStateResolver.inactivationChangesTheValue({
              debugId: this.reqContext.field.title,
              valueIsEmpty: Arrays.equals(this.model.values, inactiveValue, masterDataItemToIdText),
              valueEqualsDefaultValue: Arrays.equals(this.model.values, defaultValue, masterDataItemToIdText),
              defaultValueIsEmpty: Arrays.equals(defaultValue, inactiveValue, masterDataItemToIdText),
              canApplyDefaultValue: this.canApplyDefaultValue
            });
            this.model.values = inactiveValue;
            this.tmpReadonly = args.tmpReadonly; // last
          }
          else {
            this.tmpReadonly = args.tmpReadonly; // first
            this.applyDefaultValue();
          }
          this.valueFormControl.updateValueAndValidity();
        }
        return {
          changed: changed
        };
      },
      undo: async () => {
        await ofCondition({
          condition: () => {
            return this.model.loaded;
          }
        });
        this.tmpReadonly = previousTmpReadonly;
        this.model.values = List.of(...previousValues).toArray();
        this.valueFormControl.updateValueAndValidity();
      }
    };
  }

  registerField(context: FormRecordFieldContext, originalModel?: any): any {
    if (originalModel) {
      this.model = originalModel;
    }
    this.formRecordFieldContext = context;
    this.fieldId = context.field.fieldId;
    this.htmlForm = context.htmlForm;
    this.readonlyFormFn = context.readonly;
    this.hiddenFieldFn = () => Form.FormFieldValidationType.HIDDEN === context.validationType;
    this.readonlyFieldFn = () => Form.FormFieldValidationType.READONLY === context.validationType
      || Form.FormFieldValidationType.HIDDEN === context.validationType;
    const attrs = context.field.dataType.masterDataAttributes!;
    this.optionalValue = Form.FormFieldValidationType.REQUIRED !== context.validationType;
    this.model.title = context.field.title;
    this.model.hint = Models.optToString(context.field.hint);
    this.model.placeholder = Models.optToString(attrs.hint);
    this.model.multiSelect = attrs.multiSelect;
    this.masterDataId = attrs.masterDataId;
    this.model.displayedFormField = attrs.displayedFormField;
    this.availableMasterDataFilters = MasterDataFilterType.masterDataFilterTypes.filter(f => attrs.filterTypes.contains(f.type)).map(f => {
      return {id: f.type, text: f.stringKey};
    });
    this.masterDataFilter = this.availableMasterDataFilters[0];
    this.loadFieldData();
    return this.model;
  }

  private loadFieldData() {
    const context = this.formRecordFieldContext!;
    if (this.canApplyDefaultValue) {
      const defaultValue: number[] = []; // for now it has no default value
      this.setValuesBeforeLoad(Set.of<number>(...defaultValue));
    }
    if (context.fieldRecord) {
      const dataAttrs = context.fieldRecord.data.masterDataAttributes!;
      this.setValuesBeforeLoad(dataAttrs.values);
      this.loadMasterDataRecords(this.masterDataId, this.model.prevValueIds?.toArray());
    } else {
      this.model.loaded = true;
    }
  }

  private setValuesBeforeLoad(ids: Set<number>) {
    this.model.prevValueIds = ids;
  }

  private applyDefaultValue() {
    if (this.canApplyDefaultValue) {
      this.model.values = this.reqDefaultValue;
    }
  }

  registerFieldViews(context: FormRecordFieldViewContext): void {
    this.formRecordInactivityManager = context.inactivityManager;
  }

  onManualChange() {
    this.formGroup.controls.value.updateValueAndValidity();
    this.formRecordInactivityManager!.onGeneralFieldChangedByUser(this);
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  validateWithInterrupt(): boolean {
    return false;
  }

  shouldNotifyAfterCreation(): boolean {
    return false;
  }

  afterFormRecordCreation(formRecordId: number): Observable<any> | undefined {
    return undefined;
  }

  createModel(): FormRecordFieldViewModel {
    if (this.fieldId === undefined) {
      throw new Error('Field ID is undefined');
    }
    const attrs: FormRecord.FieldDataMasterDataAttributes = {
      values: this.model.getValueIdSet(),
    };
    return {
      fieldEditRequest: {
        fieldId: this.fieldId,
        data: {
          masterDataAttributes: attrs,
        },
      },
    };
  }

  private createForwardingHtmlForm() {
    return new ForwardingFormRef({
      formFn: () => {
        return this.htmlForm;
      },
    });
  }

  private createFormGroup(fb: FormBuilder) {
    return fb.group(
      {
        value: fb.control(
          {value: this.model.values},
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => {
                return !this.required;
              },
            }),
            AppValidators.tempValidator({
              validator: AppValidators.maxArraySize({
                array: () => {
                  return this.model.values;
                },
                maxSize: 1
              }),
              disabled: () => {
                const requiredDisabled = this.requiredDisabled;
                return this.model.multiSelect || requiredDisabled;
              },
            }),
            AppValidators.validateEnabledItems
          ],
        ),
      },
    );
  }

  loadMasterDataRecords(masterDataId: number, ids?: number[]) {
    if (ids && ids.length > 0) {
      this.formRecordMasterDataFieldLoader.getMasterDataRecordObservable(this.masterDataId,
        true,
        true,
        this.model.displayedFormField,
        ids)
        .subscribe(result => {
          this.model.values = result.items.toArray();
          this.model.loaded = true;
          this.valueFormControl.updateValueAndValidity({
            onlySelf: false,
            emitEvent: true
          });
        });
    } else {
      this.model.loaded = true;
    }
  }

  updateValue(data: FormRecordFieldValueUpdateArgs) {
    if ((<FormRecordFieldUpdateCustomerLocationArgs>data).customerRecordId !== null) {
      this.customerRecordId = (<FormRecordFieldUpdateCustomerLocationArgs>data).customerRecordId!;
    }
    this.contactLocationId = (<FormRecordFieldUpdateCustomerLocationArgs>data).contactLocationId;
  }

  getTitle(): string {
    if (this.formRecordFieldContext && this.formRecordFieldContext.field) {
      return this.formRecordFieldContext.field.title;
    }
    return '';
  }

  displayFormField(): boolean {
    return this.model.displayedFormField !== undefined;
  }

  displayedFormFieldName(): string | undefined {
    return this.model.displayedFormField?.title;
  }

  canAdd(): boolean {
    if (this.nonEditable) {
      return false;
    }
    if (this.model.multiSelect) {
      return true;
    }
    return this.model.values.length < 1;
  }

  canModify(): boolean {
    return !this.nonEditable;
  }

  openAddDialog() {
    MasterDataRecordSelectorDialogComponent.openSelector(this.dialog, {
      multiSelect: this.model.multiSelect,
        masterDataId: this.masterDataId,
        displayedFormField: this.model.displayedFormField,
        ownerCustomerRecordIds: this.customerRecordId ? Set.of(this.customerRecordId) : undefined,
        ownerContactLocationIds: this.contactLocationId ? Set.of(this.contactLocationId) : undefined,
        alreadySelected: this.model.values
      },
      result => {
      if (result) {
        this.model.values = [];
        if (result.selectedMasterDataRecords && result.selectedMasterDataRecords.length > 0) {
          this.loadMasterDataRecords(this.masterDataId, result.selectedMasterDataRecords.map(m => m.masterDataRecordId));
        }
      }
      });
  }

  removeMasterData(idx: number) {
    this.model.values.splice(idx, 1);
  }

  getNarrowestFilter(): MasterDataFilterType.MasterDataFilterType {
    if (this.availableMasterDataFilters.length === 0) {
      return 'GLOBAL';
    }
    return this.availableMasterDataFilters[0].id!;
  }

  customerMatches(item: MasterDataRecordFormFieldModel): boolean {
    if (this.getNarrowestFilter() === 'GLOBAL') {
      return true;
    }
    return this.customerRecordId === item.ownerCustomerRecordId;
  }

  locationMatches(item: MasterDataRecordFormFieldModel): boolean {
    if (this.getNarrowestFilter() !== 'LOCATION') {
      return true;
    }
    return this.contactLocationId === item.ownerContactLocationId;
  }
}

export class Model {

  title: string = '';
  hint: string = '';
  placeholder: string = '';
  prevValueIds?: Set<number>;
  values: MasterDataRecordFormFieldModel[] = [];
  multiSelect: boolean = false;
  displayedFormField: Form.Field | undefined;

  loaded: boolean = false;

  getValueIdSet(): Set<number> {
    const ret: number[] = [];
    if (this.loaded && this.values) {
      this.values.forEach((value) => {
        if (value.masterDataRecordId !== null) {
          ret.push(value.masterDataRecordId!);
        }
      });
    }
    if (!this.loaded && this.prevValueIds) {
      return this.prevValueIds;
    }
    return ret.length > 0 ? Set.of<number>(...ret) : Set.of<number>();
  }
}

export interface MasterDataRecordFormFieldModel extends MasterDataRecord.MasterDataRecord {
  displayedFormField?: DisplayedFormFieldModel;
  rights?: MasterDataRecordRightModel;
}

export class FormRecordFieldUpdateCustomerLocationArgs implements FormRecordFieldValueUpdateArgs {

  constructor(
    public customerId: number | undefined | null,
    public customerRecordId: number | undefined | null,
              public contactLocationId?: number) {

  }

}

