import { Injectable } from '@angular/core';
import { MasterDataRecord, MasterDataRecordService } from '../../../../../lib/masterdata/master-data-record.service';
import { FormRecordSerializer } from '../../../../../util/form/form-utils';
import { MasterDataRecordRightModel } from '../../../../../lib/masterdata/master-data-record-right.model';
import { GrantedPermissionSetResolver } from '../../../../../lib/right.service';
import { Observable } from 'rxjs';
import { List, Set } from 'immutable';
import { OperationRights } from '../../../../../app.right-definitions';
import { MasterDataRecordFormFieldModel } from './form-record-master-data-field.component';
import { map } from 'rxjs/operators';
import { FormRecord } from '../../../../../lib/form/form-record.service';
import { Strings } from '../../../../../lib/util/strings';
import { Order, OrderType, PagingRequest, QueryResult } from '../../../../../lib/util/services';
import { Form } from '../../../../../lib/form/form.service';

@Injectable()
export class FormRecordMasterDataFieldLoader {

  constructor(private masterDataRecordService: MasterDataRecordService,
              public formRecordSerializer: FormRecordSerializer,
  ) {
  }


  getMasterDataRecordObservable(masterDataId: number, includeDisabled: boolean, withRights: boolean, displayedFormField?: Form.Field,
                                ids?: number[], q?: string,
                                ownerCustomerRecords?: Set<number>, ownerLocations?: Set<number>, paging?: PagingRequest,
                                orders?: Set<Order<MasterDataRecord.OrderField>>):
    Observable<QueryResult<MasterDataRecordFormFieldModel>> {
    const idSet = ids ? Set.of(...ids) : undefined;
    return this.masterDataRecordService.query({
      disabled: includeDisabled ? undefined : false,
      queryText: Strings.undefinedOrNonEmpty(q),
      masterDataId: masterDataId,
      withFormRecord: true,
      displayedFormFieldId: displayedFormField?.fieldId,
      fields: Set.of('id', 'name', 'external_id', 'description', 'disabled', 'owner_customer_record_id', 'owner_contact_location_id'),
      masterDataRecordIdSet: idSet,
      ownerCustomerRecordId: ownerCustomerRecords,
      ownerContactLocationId: ownerLocations,
      orders: orders ? orders : Set.of({field: MasterDataRecord.OrderField.NAME, type: OrderType.ASC}),
      paging: paging ? paging : {
        pageNumber: 1,
        numberOfItems: 100
      },
      noProgressBar: true,
      rights: Set.of(
        OperationRights.MASTER_DATA_RECORD_READ,
        OperationRights.MASTER_DATA_RECORD_UPDATE,
        OperationRights.MASTER_DATA_RECORD_DISABLE
      )
    }).pipe(map(value => {
      return {
        items: List.of(...value.items.map(md => {
          if (md) {
            const mx: MasterDataRecordFormFieldModel = md;
            mx.rights = new MasterDataRecordRightModel(GrantedPermissionSetResolver.byGrantedRights(md.grantedRights));
            if (displayedFormField) {
              this.formRecordSerializer.serializeFieldModel(displayedFormField, this.getDisplayedFormField(mx, displayedFormField.fieldId)).subscribe(ff => {
                mx.displayedFormField = ff;
              });
            }
            return mx;
          }
          return md!;
        }).toArray()),
        pagingResult: value.pagingResult
      };
    }));
  }

  private getDisplayedFormField(md: MasterDataRecord.MasterDataRecord, displayedFormFieldId?: number): FormRecord.Field | undefined {
    if (!displayedFormFieldId) {
      return undefined;
    }
    if (!md.formRecord) {
      return undefined;
    }
    return md.formRecord.fields.find(f => f?.fieldId === displayedFormFieldId);

  }

}
