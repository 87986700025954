import { Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ConfigurationResource, ConfigurationService } from '../../lib/core-ext/configuration.service';
import { GrantedPermissionSet } from '../../lib/right.service';
import { SidebarNavItemUtils } from './sidebar-nav-item-utils';
import SidebarNavItemModel = SidebarNavItemUtils.SidebarNavItemModel;
import { MatDialog } from '@angular/material/dialog';
import { DemoModeFeatureDisabledDialogComponent } from '../demo-mode-feature-disabled-dialog/demo-mode-feature-disabled-dialog.component';

@Component({
  selector: 'app-sidebar-nav-item',
  templateUrl: './sidebar-nav-item.component.html',
  styleUrls: ['./sidebar-nav-item.component.scss']
})
export class SidebarNavItemComponent implements OnInit, OnDestroy {

  @Input()
  model: SidebarNavItemModel;

  @ViewChild('navItem')
  navItem: ElementRef;

  demoModeEnabled: boolean = false;

  private configuration: ConfigurationResource.Configuration;

  get adminFeaturesEnabled(): boolean {
    return !this.model.adminFeatures || ConfigurationService.areEnabledByServer(this.model.adminFeatures, this.configuration);
  }

  get rightsGranted(): boolean {
    return !this.model.rights || GrantedPermissionSet.each(this.model.rights);
  }

  constructor(
    private renderer: Renderer2,
    private configService: ConfigurationService,
    private dialog: MatDialog,
  ) {
    this.configuration = configService.getConfiguration();
    this.demoModeEnabled = this.configuration.feature_flags.demo_mode_enabled;
  }

  ngOnInit() {
    this.model.activeSubject.subscribe(r => this.setActive(r));
  }

  setActive(active: boolean) {
    if (active) {
      this.renderer.addClass(this.navItem.nativeElement, 'active');
    }
    else {
      this.renderer.removeClass(this.navItem.nativeElement, 'active');
    }
  }

  showDisabledDialog() {
    this.dialog.open(DemoModeFeatureDisabledDialogComponent);
  }

  ngOnDestroy() {
    this.model.activeSubject.unsubscribe();
  }

}
