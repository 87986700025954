<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div class="breadcrumb-menu d-flex">
    <div class="breadcrumb-button-icon-container cursor-pointer"
         (click)="updateStock()" *ngIf="rightModel.stockTakingSheetUpdate.hasRight() && model.canUpdateStock">
      <a class="btn-setting" title="{{'STOCK_TAKING_SHEET_UPDATE_STOCK' | translate}}">
        <i class="icomoon icomoon-stock"></i> {{'STOCK_TAKING_SHEET_UPDATE_STOCK' | translate}}
      </a>
    </div>
    <div class="breadcrumb-button-icon-container cursor-pointer"
         (click)="downloadPdf()"
         *ngIf="hasPdf">
      <a class="btn-setting" title="{{'PDF_DOWNLOAD' | translate}}">
        <i class="icomoon icomoon-download"></i> {{'PDF_DOWNLOAD' | translate}}
      </a>
    </div>
    <div class="breadcrumb-button-icon-container cursor-pointer negative-margin-right"
         (click)="downloadXls()">
      <a class="btn-setting" title="{{'COMMON_BUTTON_EXPORT' | translate}}">
        <i class="icomoon icomoon-csv-export"></i> {{'COMMON_BUTTON_EXPORT' | translate}}
      </a>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<!-- region Content -->
<div class="container-horizontal-padding-half container-vertical-padding-half animated fadeIn">

  <div class="row ml-0 mr-0">

    <!-- region StockTaking card -->
    <div class="col-xs-12 col-sm-12 col-md-6 card-custom-padding">
      <div class="container-vertical-padding-half">
        <div class="card">

          <div class="card-header">
            {{'STOCK_TAKING_SHEET_DETAIL'|translate}}
          </div><!-- /.card-header -->

          <div class="card-body">

            <div class="form-group row">
              <div class="col-md-4">
                <label
                  class="col-form-label mw-100 form-control-label">{{'STOCK_CHANGE_TABLE_HEADER_TRX_ID'|translate}}</label>
              </div>
              <div class="col-md-8">
                <label
                  class="col-form-label mw-100 form-control-label">{{model.trxId}}</label>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-4">
                <label
                  class="col-form-label mw-100 form-control-label">{{'FORM_RECORD_STOCK_STOCK'|translate}}</label>
              </div>
              <div class="col-md-8">
                <angular2-multiselect [data]="model.stock" [settings]="dropdownSettings"
                                      class="form-control"
                                      [(ngModel)]="model.stock"
                                      [ngModelOptions]="{standalone: true}"
                                      [readonly]="true">
                </angular2-multiselect>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-4">
                <label
                  class="col-form-label mw-100 form-control-label">{{'TASK_RECORD_TABLE_HEADER_STATE'|translate}}</label>
              </div>
              <div class="col-md-8">
                <label
                  class="col-form-label mw-100 form-control-label">{{model.state}}</label>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-4">
                <label
                  class="col-form-label mw-100 form-control-label">{{'TASK_RECORD_TABLE_HEADER_USER_NAME'|translate}}</label>
              </div>
              <div class="col-md-8">
                <angular2-multiselect [data]="model.ownerUser" [settings]="dropdownSettings"
                                      class="form-control"
                                      [(ngModel)]="model.ownerUser"
                                      [ngModelOptions]="{standalone: true}"
                                      [readonly]="true">
                </angular2-multiselect>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-4">
                <label
                  class="col-form-label mw-100 form-control-label">{{'STOCK_TAKING_SHEET_STOCK_AMOUNT'|translate}}</label>
              </div>
              <div class="col-md-8">
                <label
                  class="col-form-label mw-100 form-control-label">{{model.stockAmount}} {{model.unit}}</label>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-4">
                <label
                  class="col-form-label mw-100 form-control-label">{{'STOCK_TAKING_SHEET_AMOUNT_FOUND'|translate}}</label>
              </div>
              <div class="col-md-8">
                <label
                  class="col-form-label mw-100 form-control-label">{{model.amountFound}} {{model.unit}}</label>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-4">
                <label
                  class="col-form-label mw-100 form-control-label">{{'STOCK_TAKING_SHEET_AMOUNT_DIFFERENCE'|translate}}</label>
              </div>
              <div class="col-md-8">
                <label
                  class="col-form-label mw-100 form-control-label">
                  <span [ngClass]="{'color-red': model.amountDifferenceNum < 0}">
                      {{model.amountDifference}} {{model.unit}}
                  </span>
                </label>
              </div>
            </div>

          </div><!-- /.card-body -->

        </div><!-- /.card -->
      </div>
    </div>

    <div class="col-md-12 card-custom-padding">
      <div class="container-vertical-padding-half">
        <div class="card">

          <div class="card-header">
            {{'MENU_NAVBAR_PRODUCTS'|translate}}
          </div><!-- /.card-header -->

          <div class="card-body">
            <!--NO DATA TEXT ON DETAIL SCREEN IF NO DATA-->
            <div *ngIf="stockTakingSheetRecords.length === 0" class="text-center">
              <p class="empty-form-hint-text">{{ 'STOCK_TAKING_SHEET_DETAIL_NO_DATA' | translate }}</p>
            </div>
            <!--NO DATA TEXT ON DETAIL SCREEN IF NO DATA END-->
            <table *ngIf="stockTakingSheetRecords.length > 0" class="table table-striped table-bordered">
              <thead>
              <tr>
                <th class="table-sorter-header w-5 hidden-sm-down">
                  <app-table-sorter-no-op
                    [text]="'COMMON_TABLE_HEADER_ID' | translate">
                  </app-table-sorter-no-op>
                </th>
                <th class="table-sorter-header long">
                  <app-table-sorter-no-op
                    [text]="'STOCK_TAKING_SHEET_RECORD_NAME' | translate">
                  </app-table-sorter-no-op>
                </th>
                <th class="table-sorter-header hidden-sm-down">
                  <app-table-sorter-no-op
                    [text]="'ORDER_STOCK_ITEM_SERIAL_CODE' | translate">
                  </app-table-sorter-no-op>
                </th>
                <th class="table-sorter-header hidden-sm-down">
                  <app-table-sorter-no-op
                    [text]="'STOCK_ITEM_TABLE_HEADER_PRODUCT_CODE' | translate">
                  </app-table-sorter-no-op>
                </th>
                <th class="table-sorter-header hidden-md-down">
                  <app-table-sorter-no-op
                    [text]="'STOCK_TAKING_SHEET_STOCK_AMOUNT' | translate">
                  </app-table-sorter-no-op>
                </th>
                <th class="table-sorter-header hidden-md-down">
                  <app-table-sorter-no-op
                    [text]="'STOCK_TAKING_SHEET_AMOUNT_FOUND' | translate">
                  </app-table-sorter-no-op>
                </th>
                <th class="table-sorter-header">
                  <app-table-sorter-no-op
                    [text]="'STOCK_TAKING_SHEET_AMOUNT_DIFFERENCE' | translate">
                  </app-table-sorter-no-op>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let stockTakingSheetRecord of stockTakingSheetRecords">
                <td class="responsive-table-column hidden-sm-down">{{stockTakingSheetRecord.itemId}}</td>
                <td class="responsive-table-column">{{stockTakingSheetRecord.stockItemName}}</td>
                <td class="responsive-table-column hidden-sm-down">{{stockTakingSheetRecord.stockItemSerialCode}}</td>
                <td class="responsive-table-column hidden-sm-down">{{stockTakingSheetRecord.stockItemProductCode}}</td>
                <td class="responsive-table-column hidden-md-down">{{stockTakingSheetRecord.stockAmount}}</td>
                <td class="responsive-table-column hidden-md-down">{{stockTakingSheetRecord.amountFound}}</td>
                <td class="responsive-table-column">
                  <span [ngClass]="{'color-red': +stockTakingSheetRecord.amountDifference < 0}">
                    <span
                      *ngIf="+stockTakingSheetRecord.amountDifference > 0">+</span>{{stockTakingSheetRecord.amountDifference}}
                  </span>
                </td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <th class="table-sorter-header w-5 hidden-sm-down">
                  <app-table-sorter-no-op
                    [text]="'COMMON_TABLE_HEADER_ID' | translate">
                  </app-table-sorter-no-op>
                </th>
                <th class="table-sorter-header long">
                  <app-table-sorter-no-op
                    [text]="'STOCK_TAKING_SHEET_RECORD_NAME' | translate">
                  </app-table-sorter-no-op>
                </th>
                <th class="table-sorter-header hidden-sm-down">
                  <app-table-sorter-no-op
                    [text]="'ORDER_STOCK_ITEM_SERIAL_CODE' | translate">
                  </app-table-sorter-no-op>
                </th>
                <th class="table-sorter-header hidden-sm-down">
                  <app-table-sorter-no-op
                    [text]="'STOCK_ITEM_TABLE_HEADER_PRODUCT_CODE' | translate">
                  </app-table-sorter-no-op>
                </th>
                <th class="table-sorter-header hidden-md-down">
                  <app-table-sorter-no-op
                    [text]="'STOCK_TAKING_SHEET_STOCK_AMOUNT' | translate">
                  </app-table-sorter-no-op>
                </th>
                <th class="table-sorter-header hidden-md-down">
                  <app-table-sorter-no-op
                    [text]="'STOCK_TAKING_SHEET_AMOUNT_FOUND' | translate">
                  </app-table-sorter-no-op>
                </th>
                <th class="table-sorter-header">
                  <app-table-sorter-no-op
                    [text]="'STOCK_TAKING_SHEET_AMOUNT_DIFFERENCE' | translate">
                  </app-table-sorter-no-op>
                </th>
              </tr>
              </tfoot>
            </table>

          </div><!-- /.card-body -->

        </div><!-- /.card -->
      </div>
    </div>

  </div>

</div>
<!-- endregion Content -->
