/* eslint-disable */
import { Observable, Observer } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Order, OrderType } from '../util/services';
import { MemStorage } from '../util/mem-storage';
import { Injectable } from '@angular/core';
import { UiConstants } from '../../util/core-utils';
import { StockTakings } from './stock-takings.service';
import { RuntimeConfiguration } from '../runtime-configuration';
import { LoggedInUserStorage } from '../util/storages';
/* eslint-enable */

@Injectable()
export class StockTakingsSearchService {

  constructor() {
  }

  getSearchData(request: StockTakingsSearch.SearchDataGetRequest): Observable<StockTakingsSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<StockTakingsSearch.SearchDataResult>) => {
      try {
        observer.next(storage.read(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  setSearchData(request: StockTakingsSearch.SearchDataSetRequest): Observable<StockTakingsSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<StockTakingsSearch.SearchDataResult>) => {
      try {
        observer.next(storage.save(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  resetSearchData(request: StockTakingsSearch.SearchDataGetRequest): Observable<StockTakingsSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<StockTakingsSearch.SearchDataResult>) => {
      try {
        observer.next(storage.reset(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  private createStorage(): StockTakingsSearch.Storage {
    return new StockTakingsSearch.Storage();
  }

}

export namespace StockTakingsSearch {

  export interface SearchDataGetRequest {
  }

  export interface SearchDataSetRequest {
    searchData: SearchData;
  }

  export interface SearchDataResult {
    searchData: SearchData;
  }

  export interface SearchData {
    itemsPerPage: number;
    pageNumber: number;
    order: Order<StockTakings.OrderField>;
    stockTakingId: string;
    ownerUserId: number[];
    name: string;
    trxId: string;
  }

  interface StoredSearchRawDataBase {
    version: number;
  }

  interface StoredSearchRawData extends StoredSearchRawDataBase {
    itemsPerPage: number;
    pageNumber: number;
    order: Order<StockTakings.OrderField>;
    stockTakingId: string;
    ownerUserId: number[];
    name: string;
    trxId: string;
  }

  export class Storage {

    /**
     * Increment this version if you change the SearchData interface.
     */
    private static readonly VERSION: number = 1;

    private static readonly DEFAULT_SEARCH_DATA: SearchData = {
      itemsPerPage: UiConstants.pageNumbers[0],
      pageNumber: 1,
      order: {
        field: StockTakings.OrderField.CREATION_TIME,
        type: OrderType.DESC
      },
      stockTakingId: '',
      ownerUserId: [],
      name: '',
      trxId: '',
    };

    constructor() {
    }

    public read(request: SearchDataGetRequest): SearchDataResult {
      const key = this.generateKey();
      let rawBase: StoredSearchRawDataBase;
      try {
        const json = MemStorage.getItem(key);
        if (!json) {
          return this.createDefaultResult();
        }
        rawBase = JSON.parse(json);
      }
      catch (error) {
        return this.createDefaultResult();
      }
      if (Storage.VERSION === rawBase.version) {
        const raw: StoredSearchRawData = <StoredSearchRawData> rawBase;
        return this.fromRaw(raw);
      }
      return this.createDefaultResult();
    }

    public reset(request: SearchDataGetRequest): SearchDataResult {
      return this.save({
        searchData: Storage.DEFAULT_SEARCH_DATA,
      });
    }

    public save(request: SearchDataSetRequest): SearchDataResult {
      const key = this.generateKey();
      const raw = this.toRaw(request.searchData);
      const json = JSON.stringify(raw);
      MemStorage.setItem(key, json);
      return this.read(request);
    }

    private generateKey(): string {
      return 'UserId(' + LoggedInUserStorage.getInstance().getUserId() + ')-Search-Stock-Takings';
    }

    private createDefaultResult(): SearchDataResult {
      return {
        searchData: Storage.DEFAULT_SEARCH_DATA,
      };
    }

    private fromRaw(data: StoredSearchRawData): SearchDataResult {
      return {
        searchData: {
          itemsPerPage: data.itemsPerPage,
          pageNumber: data.pageNumber,
          order: data.order,
          stockTakingId: data.stockTakingId,
          ownerUserId: data.ownerUserId,
          name: data.name,
          trxId: data.trxId,
        }
      };
    }

    private toRaw(data: SearchData): StoredSearchRawData {
      return {
        version: Storage.VERSION,
        itemsPerPage: data.itemsPerPage,
        pageNumber: data.pageNumber,
        order: data.order,
        stockTakingId: data.stockTakingId,
        ownerUserId: data.ownerUserId,
        name: data.name,
        trxId: data.trxId,
      }
        ;
    }

  }

}

export class StockTakingsSearchModel {
  stockTakingId: string = '';
  ownerUserId: OptionItem[] = [];
  name: string = '';
  trxId: string = '';

  public isEmpty(): boolean {
    return this.stockTakingId.length === 0
      && this.ownerUserId.length === 0
      && this.name.length === 0
      && this.trxId.length === 0
      ;
  }
}

interface OptionItem {
  id: number;
  itemName: string;
}
