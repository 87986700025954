import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Observable } from 'rxjs';
import { RecaptchaV3TokenStorage } from '../lib/util/storages';

export class RecaptchaV3Utils {

  static preAuthenticate<T>(
    service: ReCaptchaV3Service,
    action: string,
    call: Observable<T>
  ): Observable<T> {
    return new Observable((subscriber) => {
      // Hack to make the recaptcha script load on aot and optimized builds
      // Shouldn't cause any problems: https://stackoverflow.com/a/16085078/2923589
      // @ts-ignore
      service.init();
      service.execute(action).subscribe(token => {
          RecaptchaV3TokenStorage.getInstance().setToken(token);
          return call.subscribe(
            next => subscriber.next(next),
            error => subscriber.error(error),
            () => subscriber.complete());
        });
    });
  }

}
