
<div class="col-md-12 d-flex justify-content-between mb-1 pl-1 pr-1">
  <h5 class="">{{getTitle()}}</h5>
  <input *ngIf="mergeVisible" [disabled]="mergeDisabled" type="button" class="btn btn-secondary-green cursor-pointer" (click)="openMergeDialog()"
         value="{{'FORM_RECORD_INVOICE_FIELD_MERGE'|translate}}"/>
  <input *ngIf="copyVisible" [disabled]="copyDisabled" type="button" class="btn btn-secondary-green cursor-pointer" (click)="openCopyDialog()"
         value="{{'FORM_RECORD_INVOICE_FIELD_COPY'|translate}}"/>
</div>

<div>
  <table class="table table-sm invoice-create-table">
    <thead>
    <tr>
      <th class="responsive-table-column w-5 hidden-sm-down"></th>
      <th class="responsive-table-column w-5 hidden-sm-down"></th>
      <th class="responsive-table-column w-15">{{'COMMON_NAME' | translate}}</th>
      <th class="responsive-table-column w-10">{{'COMMON_EXTERNAL_ID' | translate}}</th>
      <th class="responsive-table-column w-10 hidden-sm-down">{{'INVOICE_RECORD_AMOUNT' | translate}}</th>
      <th class="responsive-table-column w-10 hidden-md-down">{{'INVOICE_RECORD_HUN_VTSZ_NUMBER' | translate}}</th>
      <th class="responsive-table-column w-10 hidden-sm-down">{{'INVOICE_RECORD_NET_UNIT_PRICE' | translate}}</th>
      <th class="responsive-table-column w-10 hidden-sm-down">{{'INVOICE_RECORD_VAT_RATE' | translate}}</th>
      <th class="responsive-table-column w-10">{{'INVOICE_RECORD_PRICE' | translate}}</th>
      <th class="responsive-table-column w-10 hidden-md-down">{{'COMMON_CATEGORY' | translate}}</th>
      <th class="responsive-table-column w-5"></th>
    </tr>
    </thead>
    <tbody *ngFor="let item of model.items; index as i;">
    <tr>
      <td class="responsive-table-column w-5 text-center align-middle p-0">
        <i class="icomoon icomoon-invoice icons-table-item" *ngIf="item.invoiced"></i>
      </td>
      <td class="responsive-table-column w-5 text-center align-middle hidden-sm-down">{{i+1}}</td>
      <td class="responsive-table-column w-15 align-middle">{{item.recordName}}</td>
      <td class="responsive-table-column w-10 align-middle">{{item.externalId}}</td>
      <td class="responsive-table-column w-10 align-middle hidden-sm-down">{{item.amount}} {{item.unitType}}</td>
      <td class="responsive-table-column w-10 align-middle hidden-sm-down">{{item.hunVtszNumber}}</td>
      <td class="responsive-table-column w-10 align-middle hidden-md-down">{{item.netUnitPrice}} {{item.currencyCode}}</td>
      <td class="responsive-table-column w-10 align-middle hidden-sm-down">
        {{item.vatRate}}% <span *ngIf="item.vatRate === 0">({{item.zeroVatRateType}})</span>
      </td>
      <td class="responsive-table-column w-10 align-middle">
        <p class="text-muted m-0">br. {{item.grossPrice}} {{item.currencyCode}}</p>
        {{item.netPrice}} {{item.currencyCode}}
      </td>
      <td class="responsive-table-column w-10 align-middle hidden-md-down">{{item.category}}</td>
      <td class="responsive-table-column w-5 align-middle" (click)="toggleEdit(i)"><i
        class="icomoon icomoon-detail cursor-pointer"></i></td>
    </tr>
    <tr *ngIf="item.editing">
      <td colspan="11" style="padding: 0">
        <app-form-record-invoice-field-item-edit
          [model]="item"
          [selectableVatRates]="selectableVatRates"
          [selectableZeroVatRateTypes]="selectableZeroVatRateTypes"
          [readonly]="nonEditable || item.invoiced"
          (remove)="removeItem(i)"
          (cancel)="closeRow(i)"
          (save)="saveItem(i, $event)">
        </app-form-record-invoice-field-item-edit>
      </td>
    </tr>
    </tbody>
    <tbody *ngIf="!addModel && (!model.items || model.items.length === 0)">
    <tr>
      <td colspan="11">
        <div class="text-center text-muted p-2">
          <label class="m-0">{{'INVOICE_RECORD_NO_RECORD' | translate}}</label>
        </div>
      </td>
    </tr>
    </tbody>
    <tbody *ngIf="addModel">
    <tr>
      <td colspan="11" class="p-0">
        <app-form-record-invoice-field-item-edit
          [model]="addModel"
          [selectableVatRates]="selectableVatRates"
          [selectableZeroVatRateTypes]="selectableZeroVatRateTypes"
          [isRemovable]="false"
          [readonly]="nonEditable"
          (cancel)="cancelNewItem()"
          (save)="saveNewItem($event)">
        </app-form-record-invoice-field-item-edit>
      </td>
    </tr>
    </tbody>
    <tr *ngIf="!nonEditable">
      <td colspan="11">
        <input class="btn btn-add-record cursor-pointer" type="button" [disabled]="addModel"
               value="{{'INVOICE_RECORD_ADD_BUTTON' | translate}}" (click)="newItem()">
      </td>
    </tr>
  </table>

  <div>
    <div class="d-flex justify-content-end" *ngIf="model.discountPercent !== undefined">
      <div class="d-flex align-items-center">
        <label class="mr-1">{{'FORM_RECORD_STOCK_DISCOUNT' | translate}}</label>
        <select class="form-control min-width-7-5" [compareWith]="SelectUtils.compareNumbersWithNull"
                [(ngModel)]="model.discountPercent" *ngIf="!nonEditable"
                (ngModelChange)="refreshPrices()" id="discountSelect" name="discountSelect" #discountSelect="ngModel">
          <option *ngFor="let item of availableDiscountsForDropdown" [ngValue]="item.id">{{item.text}}</option>
        </select>
        <label>{{readonlyDiscount}}</label>
      </div>
      <!-- /.row -->
    </div>
    <!-- /.row -->
    <h5>{{'INVOICE_SUM' | translate}}</h5>
    <hr>
    <table class="pull-right mb-1">
      <tr>
        <td class="summary_td text-right text-muted">{{'INVOICE_SUM_NET_PRICE' | translate}}</td>
        <td class="summary_td">{{prices.net}} {{prices.currencyCode}}</td>
      </tr>
      <tr>
        <td class="summary_td text-right text-muted"><h6>{{'INVOICE_SUM_GROSS_PRICE' | translate}}</h6></td>
        <td class="summary_td"><h6>{{prices.gross}} {{prices.currencyCode}}</h6></td>
      </tr>
    </table>
  </div>
</div>

