/* eslint-disable */
import { Injectable } from '@angular/core';
import { StockChangeResource, StockChangeResourceService } from './stock-change-resource.service';
import { Observable, Observer } from 'rxjs';
import { Order, PagingRequest, QueryResult, ResourceQueryResult, Services } from '../util/services';
import { List, Map, Set } from 'immutable';
import { OffsetDateTime } from '../util/dates';
import { Decimal } from 'decimal.js';

/* eslint-enable */

@Injectable()
export class StockChangeService implements StockChange.Service {

  constructor(private resourceService: StockChangeResourceService) {
  }

  // <editor-fold desc="CRUD">

  query(request: StockChange.QueryRequest): Observable<QueryResult<StockChange.StockChange>> {
    return Observable.create((observer: Observer<QueryResult<StockChange.StockChange>>) => {
      const resourceRequest: StockChangeResource.QueryRequest = {
        with_items: request.withItems ? request.withItems : false,
        trx_id: request.trxId,
        change_type: request.changeType,
        assignee_user_id: request.assigneeUserId,
        stock_item_id: request.stockItemId,
        stock_id: request.stockId,
        change_time_from: request.changeTimeFrom,
        change_time_to: request.changeTimeTo,
        query_text: request.queryText,
        order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined
      };
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<StockChangeResource.StockChange>) => {
          observer.next({
            items: this.toPublicList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  get(request: StockChange.GetRequest): Observable<StockChange.StockChange> {
    return Observable.create((observer: Observer<StockChange.StockChange>) => {
      const resourceRequest: StockChangeResource.GetRequest = {
        with_items: request.withItems,
        id: request.id
      };
      return this.resourceService.get(resourceRequest).subscribe(
        (result: StockChangeResource.StockChange) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  // </editor-fold>

  // <editor-fold desc="Internal">

  private translateError(error: any): any {
    return error;
  }

  private toPublicList(resourceList: StockChangeResource.StockChange[]): List<StockChange.StockChange> {
    return List.of(...resourceList.map((r) => this.toPublic(r)));
  }

  private toPublic(r: StockChangeResource.StockChange): StockChange.StockChange {
    return {
      id: r.id,
      trxId: r.trx_id,
      assigneeUserId: r.assignee_user_id,
      note: r.note,
      changeType: <StockChange.StockChangeType>r.change_type,
      changeTime: Services.toOffsetDateTime(r.change_time),
      supplierId: r.supplier_id,
      acquisitionId: r.acquisition_id,
      items: this.toPublicStockChangeItemList(r.items)
    };
  }

  private toPublicStockChangeItemList(resourceList?: StockChangeResource.StockChangeItem[]): List<StockChange.StockChangeItem> | undefined {
    if (resourceList) {
      return List.of(...resourceList.map((r) => this.toPublicStockChangeItem(r)));
    }
    return undefined;
  }

  private toPublicStockChangeItem(r: StockChangeResource.StockChangeItem): StockChange.StockChangeItem {
    return {
      sourceStockId: r.source_stock_id,
      stockItemId: r.stock_item_id,
      destinationStockId: r.destination_stock_id,
      amount: Services.toDecimal(r.amount),
      expiryDate: Services.toOffsetDateTime(r.expiry_date)
    }
  }

  // </editor-fold>

}

export namespace StockChange {

  export interface Service {

    // <editor-fold desc="CRUD">

    query(request: QueryRequest): Observable<QueryResult<StockChange>>;

    get(request: GetRequest): Observable<StockChange>;

    // </editor-fold>

  }

  export interface StockChange {
    id: number;
    trxId: string;
    assigneeUserId: number;
    note?: string;
    changeType: StockChangeType;
    changeTime: OffsetDateTime;
    supplierId?: number;
    acquisitionId?: number;
    items?: List<StockChangeItem>;
  }

  export interface QueryRequest {
    withItems?: boolean;
    trxId?: string;
    changeType?: StockChangeType;
    assigneeUserId?: number;
    stockItemId?: number;
    stockId?: number;
    changeTimeFrom?: string;
    changeTimeTo?: string;
    queryText?: string;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
  }

  export interface GetRequest {
    withItems?: boolean;
    id: number;
  }

  export type StockChangeType =
    'INTAKE'
    | 'TASK_INTAKE'
    | 'INVOICE_INTAKE'
    | 'INVOICE_STORNO'
    | 'TASK_MOVEMENT'
    | 'TASK_OPEN'
    | 'ORDER_INTAKE'
    | 'OUTTAKE'
    | 'OUTTAKE_REVERSAL'
    | 'MOVEMENT'
    | 'DISPOSAL';

  export class StockChangeTypeObject {
    type: StockChangeType;
    stringKey: string;
    iconClass: string;
  }

  export const stockChangeTypes: StockChangeTypeObject[] = [{
    type: 'INTAKE',
    stringKey: 'STOCK_CHANGE_TYPE_INTAKE',
    iconClass: 'icomoon-import'
  },
    {
      type: 'TASK_INTAKE',
      stringKey: 'STOCK_CHANGE_TYPE_TASK_INTAKE',
      iconClass: 'icomoon-import'
    },
    {
      type: 'INVOICE_INTAKE',
      stringKey: 'STOCK_CHANGE_TYPE_INVOICE_INTAKE',
      iconClass: 'icomoon-import'
    },
    {
      type: 'INVOICE_STORNO',
      stringKey: 'STOCK_CHANGE_TYPE_INVOICE_STORNO',
      iconClass: 'icomoon-export'
    },
    {
      type: 'TASK_MOVEMENT',
      stringKey: 'STOCK_CHANGE_TYPE_TASK_MOVEMENT',
      iconClass: 'icomoon-stock-move'
    },
    {
      type: 'TASK_OPEN',
      stringKey: 'STOCK_CHANGE_TYPE_TASK_OPEN',
      iconClass: 'icomoon-stock-move'
    },
    {
      type: 'ORDER_INTAKE',
      stringKey: 'STOCK_CHANGE_TYPE_ORDER_INTAKE',
      iconClass: 'icomoon-stock-change-order-intake'
    },
    {
      type: 'OUTTAKE',
      stringKey: 'STOCK_CHANGE_TYPE_OUTTAKE',
      iconClass: 'icomoon-export'
    },
    {
      type: 'OUTTAKE_REVERSAL',
      stringKey: 'STOCK_CHANGE_TYPE_OUTTAKE_REVERSAL',
      iconClass: 'icomoon-stock-change-outtake-reversal'
    },
    {
      type: 'MOVEMENT',
      stringKey: 'STOCK_CHANGE_TYPE_MOVEMENT',
      iconClass: 'icomoon-stock-move'
    },
    {
      type: 'DISPOSAL',
      stringKey: 'STOCK_CHANGE_TYPE_DISPOSAL',
      iconClass: 'icomoon-stock-dispose'
    }];

  export interface StockChangeItem {
    stockItemId: number;
    sourceStockId?: number;
    destinationStockId?: number;
    amount?: Decimal;
    expiryDate?: OffsetDateTime;
  }

  export enum OrderField {
    ID,
    TRX_ID,
    CHANGE_TIME,
    CREATION_TIME
  }

}

// <editor-fold desc="Internal">

class Keys {

  private static readonly ID = 'id';
  private static readonly TRX_ID = 'trx_id';
  private static readonly CHANGE_TIME = 'change_time';

  private static readonly orderFieldKeyMap: Map<StockChange.OrderField, string> = Map.of(
    StockChange.OrderField.ID, Keys.ID,
    StockChange.OrderField.TRX_ID, Keys.TRX_ID,
    StockChange.OrderField.CHANGE_TIME, Keys.CHANGE_TIME
  );

  public static toOrderFieldKey(field: StockChange.OrderField): string {
    return Keys.orderFieldKeyMap.get(field)!;
  }

}

// </editor-fold>
