<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">
  <div class="p-0 col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
    <div class="card">

      <div class="card-header">
        {{'USER_GROUP_PANEL_HEADING_CREATE'|translate}}
      </div>

      <div class="card-body">

        <form class="form-horizontal bordered-row" id="user_group_create_form" (ngSubmit)="create()" #f="ngForm" novalidate>

          <!-- region Name -->
          <div class="form-group row" [ngClass]="{ 'has-danger': fieldErrors.name || (f.submitted && !name.valid) }">
            <div class="col-md-4">
              <label for="name" class="mw-100 col-form-label form-control-label required-field-label">
                {{'COMMON_NAME'|translate}}
              </label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <input type="text" [(ngModel)]="model.name" (ngModelChange)="removeFieldError(fieldErrors.name)"
                       maxlength="{{getTextMaximumLength()}}" class="form-control"
                       [ngClass]="{ 'form-control-danger': fieldErrors.name }" name="name" id="name" #name=ngModel
                       placeholder="{{'USER_GROUP_PLACEHOLDER_NAME'|translate}}" required />
                <div class="form-control-feedback" *ngIf="fieldErrors.name">{{fieldErrors.name.text}}</div>
                <div class="form-control-feedback" *ngIf="f.submitted && !name.valid && name.errors['required']">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
              </div>
            </div>
          </div>
          <!-- endregion Name -->

          <!-- region ExternalId -->
          <div class="form-group row" [ngClass]="{ 'has-danger': fieldErrors.external_id || (f.submitted && !externalId.valid) }">
            <div class="col-md-4">
              <label for="externalId" class="mw-100 col-form-label form-control-label required-field-label">
                {{'COMMON_EXTERNAL_ID'|translate}}
              </label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <input type="text" [(ngModel)]="model.externalId" (ngModelChange)="removeFieldError(fieldErrors.external_id)"
                       maxlength="{{getTextMaximumLength()}}" class="form-control"
                       [ngClass]="{ 'form-control-danger': fieldErrors.external_id }" name="externalId" id="externalId" #externalId=ngModel
                       placeholder="{{'COMMON_EXTERNAL_ID'|translate}}" />
                <div class="form-control-feedback" *ngIf="fieldErrors.external_id">{{fieldErrors.external_id.text}}</div>
              </div>
            </div>
          </div>
          <!-- endregion ExternalId -->

          <!-- region Company -->
          <div class="form-group row" [ngClass]="{ 'has-danger': fieldErrors.company_ids || f.submitted && !company.valid}">
            <div class="col-md-4">
              <label class="mw-100 col-form-label form-control-label" [class.required-field-label]="currentUserCompanyIds.length > 0">
                {{'COMMON_COMPANY'|translate}}
              </label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  id="company"
                  name="company"
                  #company="ngModel"
                  [(ngModel)]="model.companies"
                  [ngModelOptions]="{standalone: true}"
                  [ngClass]="{ 'form-control-danger': fieldErrors.company_ids }"
                  (ngModelChange)="removeFieldError(fieldErrors.company_ids)"
                  (onSelect)="onCompanyChanged()"
                  (onDeSelect)="onCompanyChanged()"
                  (onRemoteSearch)="loadCompanies($event.target.value)"
                  [data]="companies"
                  [settings]="dropdownSettingsForCompany"
                  [required]="currentUserCompanyIds.length > 0"
                  validateEnabledItems>
                </angular2-multiselect>
                <div class="form-control-feedback" *ngIf="fieldErrors.company_ids">{{fieldErrors.company_ids.text}}
                </div>
                <div class="form-control-feedback" *ngIf="f.submitted && !company.valid && company.errors['hasDisabledItem']">
                  {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
                </div>
                <div class="form-control-feedback" *ngIf="f.submitted && !company.valid && company.errors['required']">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
              </div>
            </div>
          </div>
          <!-- endregion Company -->

          <!-- region Roles -->
          <div class="form-group row">
            <div class="col-md-4">
              <label class="mw-100 col-form-label form-control-label">{{'ROLE_LIST_HEADING'|translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  id="test_user_group_role_angular_select"
                  [(ngModel)]="model.roles"
                  [ngModelOptions]="{standalone: true}"
                  [data]="selectableRoles"
                  (onRemoteSearch)="loadRoles($event.target.value)"
                  [settings]="dropdownSettingsForRole">
                </angular2-multiselect>
              </div>
            </div>
          </div>
          <!-- endregion Roles -->

          <!-- region Application types -->
          <div class="form-group row">
            <div class="col-md-4">
              <label class="mw-100 col-form-label form-control-label">{{'USER_GROUP_LABEL_SCOPE'|translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  [(ngModel)]="model.application_types"
                  [ngModelOptions]="{standalone: true}"
                  [data]="applicationTypes"
                  [settings]="dropdownSettings">
                </angular2-multiselect>
              </div>
            </div>
          </div>
          <!-- endregion Application types -->

          <!-- region Users -->
          <div class="form-group row">
            <div class="col-md-4">
              <label class="mw-100 col-form-label form-control-label">{{'COMMON_USERS'|translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  id="user_group_create_users_select"
                  [(ngModel)]="model.users"
                  [ngModelOptions]="{standalone: true}"
                  [data]="selectableUsers"
                  [settings]="dropdownSettingsForUser"
                  (onSelect)="onUserSelect($event)"
                  (onDeSelect)="onUserDeSelect($event)"
                  (onSelectAll)="onSelectAllUser($event)"
                  (onDeSelectAll)="onDeSelectAllUser($event)"
                  (onRemoteSearch)="loadUsers($event.target.value)">
                </angular2-multiselect>
              </div>
            </div>
          </div>
          <!-- endregion Users -->

          <div>
            <div class="d-flex justify-content-end">
              <input type="submit" class="btn btn-primary" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
            </div>
          </div>

        </form>

      </div> <!--card-->
    </div>
  </div>

</div>
