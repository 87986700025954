<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
    <app-breadcrumb-menu>
      <!-- region EDIT-->
      <app-breadcrumb-button
        *ngIf="rightModel.roleUpdate.hasRight()"
        [iconClass]="'icomoon-modify'"
        [titleStringKey]="'COMMON_BUTTON_EDIT'"
        uiSref="Admin.RoleEdit"
        [uiParams]="{ id: roleId }">
      </app-breadcrumb-button>
      <!-- endregion EDIT-->
    </app-breadcrumb-menu>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding-half animated fadeIn">
  <responsive-tabset class="fullscreen_tab">
    <responsive-tab heading="{{'COMMON_CARD_HEADER_BASE_DATA'|translate}}">
      <div
        class="container-vertical-padding container-horizontal-padding animated fadeIn col-md-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-header">
            {{'ROLE_DETAIL_HEADING' | translate}}
          </div>
          <div class="card-body">
              <div class="form-group row">
                <label class="col-md-2 col-form-label">{{'ROLE_CREATE_NAME' | translate}}</label>
                <div class="col-md-10 row pr-1">
                  <label class="form-control detail-description">{{model.name}}</label>
                </div>
              </div>
              <div class="form-group row mr-0">
                <label class="col-md-2 col-form-label">{{'ROLE_CREATE_PERMISSIONS' | translate}}</label>
                <div class="col-md-10 pl-0 pr-1">
                  <div>
                    <app-tree-node-component
                      *ngFor="let node of selectedPermissionTree.data"
                      [readonly]="true"
                      [node]="node">
                    </app-tree-node-component>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </responsive-tab>
    <responsive-tab *ngIf="rightModel.userHistoryLog.hasRight()"
           heading="{{'COMMON_HISTORY'|translate}}">
      <app-history-log
        [queryModel]="historyQueryModel"
        [Type]="Role"
        [historyList]="historyList"
        (openHistoryDetail)="historyDetailModal.openDetailDialog($event)"
        (itemsPerPageChangedOut)="itemsPerPageChanged($event)"
        (orderByOut)="orderBy($event)"
        (pageChangedOut)="pageChanged($event)"
        #historyComponent>
      </app-history-log>
    </responsive-tab>
  </responsive-tabset>
</div>

<app-history-detail-dialog
  #historyDetailModal>
</app-history-detail-dialog>
