import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StockRecordSelectorModel } from '../stock-record-selector-dialog.component';

@Component({
  selector: 'app-stock-record-selector-card-item',
  templateUrl: './stock-record-selector-card-item.component.html',
  styleUrls: ['./stock-record-selector-card-item.component.scss']
})
export class StockRecordSelectorCardItemComponent implements OnInit {

  @Input()
  record: StockRecordSelectorModel;

  @Input()
  allSelected: boolean = false;

  @Output()
  onToggleSelected: EventEmitter<StockRecordSelectorModel> = new EventEmitter<StockRecordSelectorModel>();

  constructor() { }

  ngOnInit() {
  }

  toggleSelected() {
    this.onToggleSelected.emit(this.record);
  }

}
