/* eslint-disable */
import { EmptyMessage, IdentityMessage } from '../util/messages';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { HttpClient } from '@angular/common/http';
import { ResourceQueryResult } from '../util/services';
import { DownloadedFile } from '../util/downloaded-files';
import { UrlBuilder } from '../../util/url-builder';
import { map } from 'rxjs/operators';
import { StockItemQuery } from '../stock/stock-item.service';
/* eslint-enable */

@Injectable()
export class StockItemCategoryResourceService extends BaseHttpService {

  query(request: StockItemCategoryResource.QueryRequest): Observable<ResourceQueryResult<StockItemCategoryResource.StockItemCategory>> {
    return this.http.get<ResourceQueryResult<StockItemCategoryResource.StockItemCategory>>(this.url, this.parseParams(request));
  }

  get(request: StockItemCategoryResource.GetRequest): Observable<StockItemCategoryResource.StockItemCategory> {
    return this.http.get<StockItemCategoryResource.StockItemCategory>(this.url + `${request.id}`);
  }

  create(request: StockItemCategoryResource.CreateRequest): Observable<IdentityMessage> {
    return this.http.post<IdentityMessage>(this.url, request);
  }

  update(request: StockItemCategoryResource.UpdateRequest): Observable<EmptyMessage> {
    return this.http.put(this.url + `${request.id}`, request);
  }

  delete(request: IdentityMessage): Observable<EmptyMessage> {
    return this.http.delete(this.url + `${request.id}`);
  }

  exportXlsTemplate(): Observable<DownloadedFile> {
    const url = UrlBuilder.create(`${this.resourcePath}/export-xls-template`)
      .baseUrl(this.resourceHelper.getBaseUrl())
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob'
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  exportXls(request: StockItemCategoryResource.QueryRequest): Observable<DownloadedFile> {
    const url = UrlBuilder.create(`${this.resourcePath}/export-xls`)
      .baseUrl(this.resourceHelper.getBaseUrl())
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob',
      params: this.parseParams(request).params
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  constructor(private http: HttpClient, private resourceHelper: ResourceHelper) {
    super(resourceHelper, '/stock-items/categories/');
  }

}

export namespace StockItemCategoryResource {

  export interface StockItemCategory {
    id: number;
    parent_id?: number;
    external_id: string;
    name: string;
    creation_time: string;
    disabled: boolean;
  }

  export interface QueryRequest {
    id?: string;
    external_id?: string;
    name?: string;
    parent_id?: string;
    enabled_category_ids?: string;
    q?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
    no_progress_bar?: boolean;
  }

  export interface GetRequest {
    id: number;
  }

  export interface CreateRequest {
    external_id?: string;
    parent_id?: number;
    name: string;
  }

  export interface UpdateRequest {
    id: number;
    external_id: string;
    parent_id?: number;
    name: string;
  }

}
