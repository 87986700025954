<app-new-task-record-list
  [taskRecordId]="taskRecordId"
  [_headerStringKey]="'INVOICE_CREATE_RELATED_TASKS'">
</app-new-task-record-list>

<div class="container-vertical-padding-half" *ngIf="listByLink.length > 0"></div>

<div class="card" *ngIf="listByLink.length > 0">
  <div class="card-header">
    {{'TASK_RECORD_RELATED_TASKS_BY_LINK' | translate}}
  </div>

  <div class="card-body">
    <table class="table table-striped table-bordered">
      <thead>
      <tr>
        <th class="table-sorter-header">
          <app-table-sorter-no-op [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header hidden-1300-down">
          <app-table-sorter-no-op
            [text]="'TASK_RECORD_GLOBAL_LIST_TASK_TYPE_NAME' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header">
          <app-table-sorter-no-op [text]="'TASK_RECORD_NAME' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header hidden-sm-down">
          <app-table-sorter-no-op [text]="'COMMON_TYPE' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header hidden-xs-down text-center">
          <app-table-sorter-no-op [text]="'TASK_RECORD_TABLE_HEADER_STATE' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header hidden-lg-down">
          <app-table-sorter-no-op [text]="'COMMON_TABLE_HEADER_CREATION_TIME' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header w-table-options-menu">
          <app-table-sorter-no-op></app-table-sorter-no-op>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr class="table"
          *ngFor="let model of listByLink">
        <td class="fit align-middle">{{model.destination.taskRecordId}}</td>
        <td class="responsive-table-column hidden-1300-down align-middle">
          <div class="d-flex align-items-center">
            <app-type-icon [icon]="model.destination.task.icon" class="mr-05"></app-type-icon>
            <div>{{model.destination.task.name}}</div>
          </div>
        </td>
        <td class="responsive-table-column align-middle">
          <a class="id-text cursor-pointer" *ngIf="rightModel.taskRecordRead.hasRight()"
             href="#" onclick="return false"
             uiSref="Admin.TaskRecordDetail"
             [uiParams]="{ taskRecordId: model.destination.taskRecordId, taskId: model.destination.taskId }"
             title="{{'COMMON_BUTTON_DETAILS' | translate}}">
            {{model.destination.name}}
          </a>
          <span *ngIf="!rightModel.taskRecordRead.hasRight()">{{model.destination.name}}</span>
        </td>
        <td class="hidden-sm-down fit align-middle">
          {{('TASK_RECORD_LINK_TYPE_' + model.type) | translate}}
        </td>
        <td class="responsive-table-column text-center icon-sizing hidden-xs-down icomoon-state-icons align-middle">
          <app-table-badge
            [badgeStyle]="model.stateObject.badgeStyle"
            [iconClass]="model.stateObject.iconClass"
            [text]="model.stateObject.stringKey | translate">
          </app-table-badge>
        </td>
        <td class="hidden-lg-down fit align-middle">
          {{model.creationTime.toUtcIsoString() | date:'short'}}
        </td>
        <td class="align-middle">
          <app-table-options-menu>
            <app-dropdown-item
              *ngIf="rightModel.taskRecordRead.hasRight()"
              [iconClass]="'icomoon-detail'"
              [titleStringKey]="'COMMON_BUTTON_DETAILS'"
              class="cursor-pointer"
              uiSref="Admin.TaskRecordDetail"
              [uiParams]="{ taskId: model.destination.taskId, taskRecordId: model.destination.taskRecordId }">
            </app-dropdown-item>
            <app-dropdown-item
              *ngIf="rightModel.taskRecordUpdate.hasRight()"
              [iconClass]="'icomoon-modify'"
              [titleStringKey]="'COMMON_BUTTON_EDIT'"
              class="cursor-pointer"
              uiSref="Admin.TaskRecordEdit"
              [uiParams]="{ taskId: model.destination.taskId, taskRecordId: model.destination.taskRecordId }">
            </app-dropdown-item>
          </app-table-options-menu>
        </td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <th class="table-sorter-header">
          <app-table-sorter-no-op [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header hidden-1300-down">
          <app-table-sorter-no-op
            [text]="'TASK_RECORD_GLOBAL_LIST_TASK_TYPE_NAME' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header">
          <app-table-sorter-no-op [text]="'TASK_RECORD_NAME' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header hidden-sm-down">
          <app-table-sorter-no-op [text]="'COMMON_TYPE' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header hidden-xs-down text-center">
          <app-table-sorter-no-op [text]="'TASK_RECORD_TABLE_HEADER_STATE' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header hidden-lg-down">
          <app-table-sorter-no-op [text]="'COMMON_TABLE_HEADER_CREATION_TIME' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header w-table-options-menu">
          <app-table-sorter-no-op></app-table-sorter-no-op>
        </th>
      </tr>
      </tfoot>
    </table>
  </div>
</div>
