// Generated from src/app/util/generated/dql/typed/TypedDql.g4 by ANTLR 4.7.3-SNAPSHOT
/* eslint-disable */


import { ATN } from "antlr4ts/atn/ATN";
import { ATNDeserializer } from "antlr4ts/atn/ATNDeserializer";
import { CharStream } from "antlr4ts/CharStream";
import { Lexer } from "antlr4ts/Lexer";
import { LexerATNSimulator } from "antlr4ts/atn/LexerATNSimulator";
import { NotNull } from "antlr4ts/Decorators";
import { Override } from "antlr4ts/Decorators";
import { RuleContext } from "antlr4ts/RuleContext";
import { Vocabulary } from "antlr4ts/Vocabulary";
import { VocabularyImpl } from "antlr4ts/VocabularyImpl";

import * as Utils from "antlr4ts/misc/Utils";


export class TypedDqlLexer extends Lexer {
	public static readonly T__0 = 1;
	public static readonly DATATYPE = 2;
	public static readonly NUMBER = 3;
	public static readonly WORD = 4;
	public static readonly OPERATION = 5;
	public static readonly UNARY_OPERATION = 6;
	public static readonly LOGICAL = 7;
	public static readonly WS = 8;
	public static readonly VALUE = 9;
	public static readonly SEMICOLON = 10;
	public static readonly LPAR = 11;
	public static readonly RPAR = 12;

	/* eslint-disable no-trailing-spaces */
	public static readonly channelNames: string[] = [
		"DEFAULT_TOKEN_CHANNEL", "HIDDEN",
	];

	/* eslint-disable no-trailing-spaces */
	public static readonly modeNames: string[] = [
		"DEFAULT_MODE",
	];

	public static readonly ruleNames: string[] = [
		"T__0", "DIGIT", "LOWERCASE", "UPPERCASE", "VALUE_CHARS", "DATATYPE",
		"NUMBER", "WORD", "OPERATION", "UNARY_OPERATION", "LOGICAL", "WS", "VALUE",
		"SEMICOLON", "LPAR", "RPAR",
	];

	private static readonly _LITERAL_NAMES: Array<string | undefined> = [
		undefined, "'.'", undefined, undefined, undefined, undefined, undefined,
		undefined, undefined, undefined, "';'", "'('", "')'",
	];
	private static readonly _SYMBOLIC_NAMES: Array<string | undefined> = [
		undefined, undefined, "DATATYPE", "NUMBER", "WORD", "OPERATION", "UNARY_OPERATION",
		"LOGICAL", "WS", "VALUE", "SEMICOLON", "LPAR", "RPAR",
	];
	public static readonly VOCABULARY: Vocabulary = new VocabularyImpl(TypedDqlLexer._LITERAL_NAMES, TypedDqlLexer._SYMBOLIC_NAMES, []);

	// @Override
	// @NotNull
	public get vocabulary(): Vocabulary {
		return TypedDqlLexer.VOCABULARY;
	}
	/* eslint-enable no-trailing-spaces */


	constructor(input: CharStream) {
		super(input);
		this._interp = new LexerATNSimulator(TypedDqlLexer._ATN, this);
	}

	// @Override
	public get grammarFileName(): string { return "TypedDql.g4"; }

	// @Override
	public get ruleNames(): string[] { return TypedDqlLexer.ruleNames; }

	// @Override
	public get serializedATN(): string { return TypedDqlLexer._serializedATN; }

	// @Override
	public get channelNames(): string[] { return TypedDqlLexer.channelNames; }

	// @Override
	public get modeNames(): string[] { return TypedDqlLexer.modeNames; }

	public static readonly _serializedATN: string =
		"\x03\uC91D\uCABA\u058D\uAFBA\u4F53\u0607\uEA8B\uC241\x02\x0E\xB3\b\x01" +
		"\x04\x02\t\x02\x04\x03\t\x03\x04\x04\t\x04\x04\x05\t\x05\x04\x06\t\x06" +
		"\x04\x07\t\x07\x04\b\t\b\x04\t\t\t\x04\n\t\n\x04\v\t\v\x04\f\t\f\x04\r" +
		"\t\r\x04\x0E\t\x0E\x04\x0F\t\x0F\x04\x10\t\x10\x04\x11\t\x11\x03\x02\x03" +
		"\x02\x03\x03\x03\x03\x03\x04\x03\x04\x03\x05\x03\x05\x03\x06\x03\x06\x03" +
		"\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03" +
		"\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03" +
		"\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03" +
		"\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03" +
		"\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03" +
		"\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03" +
		"\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03" +
		"\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03" +
		"\x07\x03\x07\x03\x07\x03\x07\x05\x07z\n\x07\x03\b\x03\b\x07\b~\n\b\f\b" +
		"\x0E\b\x81\v\b\x03\t\x03\t\x03\t\x06\t\x86\n\t\r\t\x0E\t\x87\x03\n\x03" +
		"\n\x03\n\x03\n\x03\n\x03\n\x03\n\x03\n\x03\n\x03\n\x03\n\x05\n\x95\n\n" +
		"\x03\v\x03\v\x03\v\x05\v\x9A\n\v\x03\f\x03\f\x03\r\x06\r\x9F\n\r\r\r\x0E" +
		"\r\xA0\x03\r\x03\r\x03\x0E\x03\x0E\x07\x0E\xA7\n\x0E\f\x0E\x0E\x0E\xAA" +
		"\v\x0E\x03\x0E\x03\x0E\x03\x0F\x03\x0F\x03\x10\x03\x10\x03\x11\x03\x11" +
		"\x02\x02\x02\x12\x03\x02\x03\x05\x02\x02\x07\x02\x02\t\x02\x02\v\x02\x02" +
		"\r\x02\x04\x0F\x02\x05\x11\x02\x06\x13\x02\x07\x15\x02\b\x17\x02\t\x19" +
		"\x02\n\x1B\x02\v\x1D\x02\f\x1F\x02\r!\x02\x0E\x03\x02\n\x03\x022;\x03" +
		"\x02c|\x03\x02C\\\x03\x02$$\x03\x023;\x04\x02>>@@\x04\x02((~~\x05\x02" +
		"\v\f\x0F\x0F\"\"\x02\xC5\x02\x03\x03\x02\x02\x02\x02\r\x03\x02\x02\x02" +
		"\x02\x0F\x03\x02\x02\x02\x02\x11\x03\x02\x02\x02\x02\x13\x03\x02\x02\x02" +
		"\x02\x15\x03\x02\x02\x02\x02\x17\x03\x02\x02\x02\x02\x19\x03\x02\x02\x02" +
		"\x02\x1B\x03\x02\x02\x02\x02\x1D\x03\x02\x02\x02\x02\x1F\x03\x02\x02\x02" +
		"\x02!\x03\x02\x02\x02\x03#\x03\x02\x02\x02\x05%\x03\x02\x02\x02\x07\'" +
		"\x03\x02\x02\x02\t)\x03\x02\x02\x02\v+\x03\x02\x02\x02\r-\x03\x02\x02" +
		"\x02\x0F{\x03\x02\x02\x02\x11\x85\x03\x02\x02\x02\x13\x94\x03\x02\x02" +
		"\x02\x15\x99\x03\x02\x02\x02\x17\x9B\x03\x02\x02\x02\x19\x9E\x03\x02\x02" +
		"\x02\x1B\xA4\x03\x02\x02\x02\x1D\xAD\x03\x02\x02\x02\x1F\xAF\x03\x02\x02" +
		"\x02!\xB1\x03\x02\x02\x02#$\x070\x02\x02$\x04\x03\x02\x02\x02%&\t\x02" +
		"\x02\x02&\x06\x03\x02\x02\x02\'(\t\x03\x02\x02(\b\x03\x02\x02\x02)*\t" +
		"\x04\x02\x02*\n\x03\x02\x02\x02+,\n\x05\x02\x02,\f\x03\x02\x02\x02-y\x07" +
		"B\x02\x02./\x07U\x02\x02/0\x07V\x02\x0201\x07T\x02\x0212\x07K\x02\x02" +
		"23\x07P\x02\x023z\x07I\x02\x0245\x07P\x02\x0256\x07W\x02\x0267\x07O\x02" +
		"\x0278\x07D\x02\x0289\x07G\x02\x029z\x07T\x02\x02:;\x07F\x02\x02;<\x07" +
		"G\x02\x02<=\x07E\x02\x02=>\x07K\x02\x02>?\x07O\x02\x02?@\x07C\x02\x02" +
		"@z\x07N\x02\x02AB\x07F\x02\x02BC\x07C\x02\x02CD\x07V\x02\x02Dz\x07G\x02" +
		"\x02EF\x07F\x02\x02FG\x07C\x02\x02GH\x07V\x02\x02HI\x07G\x02\x02IJ\x07" +
		"a\x02\x02JK\x07V\x02\x02KL\x07K\x02\x02LM\x07O\x02\x02Mz\x07G\x02\x02" +
		"NO\x07D\x02\x02OP\x07Q\x02\x02PQ\x07Q\x02\x02QR\x07N\x02\x02RS\x07G\x02" +
		"\x02ST\x07C\x02\x02Tz\x07P\x02\x02UV\x07G\x02\x02VW\x07P\x02\x02WX\x07" +
		"W\x02\x02Xz\x07O\x02\x02YZ\x07W\x02\x02Z[\x07U\x02\x02[\\\x07G\x02\x02" +
		"\\z\x07T\x02\x02]^\x07W\x02\x02^_\x07U\x02\x02_`\x07G\x02\x02`a\x07T\x02" +
		"\x02ab\x07a\x02\x02bc\x07I\x02\x02cd\x07T\x02\x02de\x07Q\x02\x02ef\x07" +
		"W\x02\x02fz\x07R\x02\x02gh\x07O\x02\x02hi\x07Q\x02\x02ij\x07D\x02\x02" +
		"jk\x07K\x02\x02kl\x07N\x02\x02lm\x07G\x02\x02mn\x07a\x02\x02no\x07C\x02" +
		"\x02op\x07R\x02\x02pz\x07R\x02\x02qr\x07E\x02\x02rs\x07W\x02\x02st\x07" +
		"U\x02\x02tu\x07V\x02\x02uv\x07Q\x02\x02vw\x07O\x02\x02wx\x07G\x02\x02" +
		"xz\x07T\x02\x02y.\x03\x02\x02\x02y4\x03\x02\x02\x02y:\x03\x02\x02\x02" +
		"yA\x03\x02\x02\x02yE\x03\x02\x02\x02yN\x03\x02\x02\x02yU\x03\x02\x02\x02" +
		"yY\x03\x02\x02\x02y]\x03\x02\x02\x02yg\x03\x02\x02\x02yq\x03\x02\x02\x02" +
		"z\x0E\x03\x02\x02\x02{\x7F\t\x06\x02\x02|~\x05\x05\x03\x02}|\x03\x02\x02" +
		"\x02~\x81\x03\x02\x02\x02\x7F}\x03\x02\x02\x02\x7F\x80\x03\x02\x02\x02" +
		"\x80\x10\x03\x02\x02\x02\x81\x7F\x03\x02\x02\x02\x82\x86\x05\x07\x04\x02" +
		"\x83\x86\x05\t\x05\x02\x84\x86\x07a\x02\x02\x85\x82\x03\x02\x02\x02\x85" +
		"\x83\x03\x02\x02\x02\x85\x84\x03\x02\x02\x02\x86\x87\x03\x02\x02\x02\x87" +
		"\x85\x03\x02\x02\x02\x87\x88\x03\x02\x02\x02\x88\x12\x03\x02\x02\x02\x89" +
		"\x95\x07?\x02\x02\x8A\x8B\x07#\x02\x02\x8B\x95\x07?\x02\x02\x8C\x95\t" +
		"\x07\x02\x02\x8D\x8E\x07>\x02\x02\x8E\x95\x07?\x02\x02\x8F\x90\x07@\x02" +
		"\x02\x90\x95\x07?\x02\x02\x91\x95\x07\x80\x02\x02\x92\x93\x07#\x02\x02" +
		"\x93\x95\x07\x80\x02\x02\x94\x89\x03\x02\x02\x02\x94\x8A\x03\x02\x02\x02" +
		"\x94\x8C\x03\x02\x02\x02\x94\x8D\x03\x02\x02\x02\x94\x8F\x03\x02\x02\x02" +
		"\x94\x91\x03\x02\x02\x02\x94\x92\x03\x02\x02\x02\x95\x14\x03\x02\x02\x02" +
		"\x96\x9A\x07%\x02\x02\x97\x98\x07#\x02\x02\x98\x9A\x07%\x02\x02\x99\x96" +
		"\x03\x02\x02\x02\x99\x97\x03\x02\x02\x02\x9A\x16\x03\x02\x02\x02\x9B\x9C" +
		"\t\b\x02\x02\x9C\x18\x03\x02\x02\x02\x9D\x9F\t\t\x02\x02\x9E\x9D\x03\x02" +
		"\x02\x02\x9F\xA0\x03\x02\x02\x02\xA0\x9E\x03\x02\x02\x02\xA0\xA1\x03\x02" +
		"\x02\x02\xA1\xA2\x03\x02\x02\x02\xA2\xA3\b\r\x02\x02\xA3\x1A\x03\x02\x02" +
		"\x02\xA4\xA8\x07$\x02\x02\xA5\xA7\x05\v\x06\x02\xA6\xA5\x03\x02\x02\x02" +
		"\xA7\xAA\x03\x02\x02\x02\xA8\xA6\x03\x02\x02\x02\xA8\xA9\x03\x02\x02\x02" +
		"\xA9\xAB\x03\x02\x02\x02\xAA\xA8\x03\x02\x02\x02\xAB\xAC\x07$\x02\x02" +
		"\xAC\x1C\x03\x02\x02\x02\xAD\xAE\x07=\x02\x02\xAE\x1E\x03\x02\x02\x02" +
		"\xAF\xB0\x07*\x02\x02\xB0 \x03\x02\x02\x02\xB1\xB2\x07+\x02\x02\xB2\"" +
		"\x03\x02\x02\x02\v\x02y\x7F\x85\x87\x94\x99\xA0\xA8\x03\b\x02\x02";
	public static __ATN: ATN;
	public static get _ATN(): ATN {
		if (!TypedDqlLexer.__ATN) {
			TypedDqlLexer.__ATN = new ATNDeserializer().deserialize(Utils.toCharArray(TypedDqlLexer._serializedATN));
		}

		return TypedDqlLexer.__ATN;
	}

}

/* eslint-enable */
