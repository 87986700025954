/* eslint-disable */
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Form } from '../form/form.service';
import { Observable, Observer } from 'rxjs';
import { FieldValidationError, QueryResult, ResourceQueryResult, Services } from '../util/services';
import { DownloadedFile, FileNameBuilder, NamedBlobFile, NamedBlobFileDecorator } from '../util/downloaded-files';
import { List, Map } from 'immutable';
import { LegacyProcessTaskResource, LegacyProcessTaskResourceService } from './legacy-process-task-resource.service';
import { FormRecord } from '../form/form-record.service';
import { FileAttachment, FileAttachmentResource, FileAttachments } from '../util/file-attachments';
import { ObservableErrorResourceParser } from '../util/errors';

/* eslint-enable */

@Injectable()
export class LegacyProcessTaskService implements LegacyProcessTask.Service {

  private formRecordMapper: FormRecord.ResourceMapper;

  constructor(private resourceService: LegacyProcessTaskResourceService) {
    this.formRecordMapper = new FormRecord.ResourceMapper(new Form.ResourceMapper());
  }

  query(request: LegacyProcessTask.QueryRequest): Observable<QueryResult<LegacyProcessTask.LegacyProcessTask>> {
    return Observable.create((observer: Observer<QueryResult<LegacyProcessTask.LegacyProcessTask>>) => {
      const resourceRequest: LegacyProcessTaskResource.QueryRequest = {
        with_form_record: this.toResourceArgumentWithFormRecord(request.withFormRecord),
        process_id: request.processId
      };
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<LegacyProcessTaskResource.LegacyProcessTask>) => {
          observer.next({
            items: this.toPublicList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  get(request: LegacyProcessTask.GetRequest): Observable<LegacyProcessTask.LegacyProcessTask> {
    return Observable.create((observer: Observer<LegacyProcessTask.LegacyProcessTask>) => {
      const resourceRequest: LegacyProcessTaskResource.GetRequest = {
        with_form_record: this.toResourceArgumentWithFormRecord(request.withFormRecord),
        process_id: request.processId,
        process_task_id: request.processTaskId
      };
      return this.resourceService.get(resourceRequest).subscribe(
        (result: LegacyProcessTaskResource.LegacyProcessTask) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  update(request: LegacyProcessTask.UpdateRequest): Observable<LegacyProcessTask.LegacyProcessTask> {
    return Observable.create((observer: Observer<LegacyProcessTask.LegacyProcessTask>) => {
      const resourceRequest: LegacyProcessTaskResource.UpdateRequest = {
        with_form_record: this.toResourceArgumentWithFormRecord(request.withFormRecord),
        process_id: request.processId,
        process_task_id: request.processTaskId,
        external_id: request.externalId,
        name: request.name,
        description: request.description,
        form_record: this.formRecordMapper.toResourceUpdateRequest(request.formRecord),
        assignee_user_id: request.assigneeUserId
      };
      return this.resourceService.update(resourceRequest).subscribe(
        (result: LegacyProcessTaskResource.LegacyProcessTask) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  changeAssignee(request: LegacyProcessTask.AssigneeChangeRequest): Observable<LegacyProcessTask.LegacyProcessTask> {
    return Observable.create((observer: Observer<LegacyProcessTask.LegacyProcessTask>) => {
      const resourceRequest: LegacyProcessTaskResource.AssigneeChangeRequest = {
        process_id: request.processId,
        process_task_id: request.processTaskId,
        assignee_user_id: request.assigneeUserId
      };
      return this.resourceService.setDisabled(resourceRequest).subscribe(
        (result: LegacyProcessTaskResource.LegacyProcessTask) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  getAttachments(request: LegacyProcessTask.GetAttachmentRequest): Observable<FileAttachment[]> {
    return Observable.create((observer: Observer<FileAttachment[]>) => {
      const resourceRequest: LegacyProcessTaskResource.GetAttachmentRequest = {
        process_id: request.processId,
        process_task_id: request.processTaskId
      };
      this.resourceService.getAttachments(resourceRequest).subscribe(
        (result: Array<FileAttachmentResource>) => {
          observer.next(FileAttachments.toPublicList(result));
        });
    });
  }

  downloadAttachment(request: LegacyProcessTask.DownloadAttachmentRequest): Observable<NamedBlobFile> {
    const fileName = new FileNameBuilder()
      .addString(request.attachment.fullFileName)
      .addOffsetDateTime(Services.toOffsetDateTime(request.attachment.contentUpdateTime))
      .build();
    return this.resourceService.downloadAttachment({
        processId: request.processId,
        processTaskId: request.processTaskId,
        attachmentId: request.attachment.id
      }
    ).pipe(map((file: DownloadedFile) => {
      return new NamedBlobFileDecorator(file, fileName);
    }));
  }

  private translateError(error: any): any {
    const res = ObservableErrorResourceParser.parseError(error);
    const fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
    const fieldErrorMap = ObservableErrorResourceParser.toFieldErrorMap(Keys.toValidatedField, fieldErrors);
    if (!fieldErrorMap.isEmpty()) {
      return FieldValidationError.of(fieldErrorMap);
    }
    return error;
  }

  private toPublicList(resourceList: LegacyProcessTaskResource.LegacyProcessTask[]): List<LegacyProcessTask.LegacyProcessTask> {
    return List.of(...resourceList.map((r) => this.toPublic(r)));
  }

  private toPublic(r: LegacyProcessTaskResource.LegacyProcessTask): LegacyProcessTask.LegacyProcessTask {
    return {
      processTaskId: r.process_task_id,
      processId: r.process_id,
      workflowTaskId: r.workflow_task_id,
      workflowId: r.workflow_id,
      externalId: r.external_id,
      state: <LegacyProcessTask.LegacyProcessTaskState>r.state,
      name: r.name,
      displayName: r.display_name,
      assigneeUserId: r.assignee_user_id,
      description: r.description,
      formRecord: this.formRecordMapper.toPublic(r.form_record),
    };
  }

  private toResourceArgumentWithFormRecord(withFormRecord?: boolean): boolean {
    return withFormRecord === undefined ? false : withFormRecord;
  }

}

export namespace LegacyProcessTask {

  export interface Service {

    // <editor-fold desc="CRUD">

    query(request: QueryRequest): Observable<QueryResult<LegacyProcessTask>>;

    get(request: GetRequest): Observable<LegacyProcessTask>;

    update(request: UpdateRequest): Observable<LegacyProcessTask>;

    changeAssignee(request: AssigneeChangeRequest): Observable<LegacyProcessTask>;

    // </editor-fold>

  }

  export enum ValidatedField {
    UNKNOWN,

    EXTERNAL_ID,
    NAME,
  }

  // <editor-fold desc="CRUD">

  export interface LegacyProcessTask {
    processTaskId: number;
    processId: number;
    workflowTaskId: number;
    workflowId: number;
    externalId: string;
    state: LegacyProcessTaskState;
    name: string;
    displayName: string;
    assigneeUserId?: number;
    description?: string;
    formRecord?: FormRecord.FormRecord; // non-null if requested
  }

  export interface QueryRequest {
    withFormRecord?: boolean;
    processId: number;
  }

  export interface GetRequest {
    withFormRecord?: boolean;
    processTaskId: number;
    processId: number;
  }

  export interface UpdateRequest {
    withFormRecord?: boolean;

    processId: number;
    processTaskId: number;
    externalId?: string;
    name: string;
    description?: string;
    assigneeUserId: number | null;
    formRecord: FormRecord.FormRecordUpdateRequest;
  }

  export interface AssigneeChangeRequest {
    processTaskId: number;
    processId: number;
    assigneeUserId: number | null;
  }

  export interface GetAttachmentRequest {
    processId: number;
    processTaskId: number;
  }

  export interface DownloadAttachmentRequest {
    processId: number,
    processTaskId: number,
    attachment: FileAttachment
  }

  export type LegacyProcessTaskState = 'TO_DO' | 'OPEN' | 'IN_PROGRESS' | 'PENDING_APPROVAL' | 'FINISHED' | 'FAILED';

  export class LegacyProcessTaskStateObject {
    state: LegacyProcessTaskState;
    stringKey: string;
  }

  export const processTaskStates: LegacyProcessTaskStateObject[] = [
    {state: 'TO_DO', stringKey: 'PROCESS_TASK_STATE_TO_DO'},
    {state: 'OPEN', stringKey: 'PROCESS_TASK_STATE_OPEN'},
    {state: 'IN_PROGRESS', stringKey: 'PROCESS_TASK_STATE_IN_PROGRESS'},
    {state: 'PENDING_APPROVAL', stringKey: 'PROCESS_TASK_STATE_PENDING_APPROVAL'},
    {state: 'FINISHED', stringKey: 'PROCESS_TASK_STATE_FINISHED'},
    {state: 'FAILED', stringKey: 'PROCESS_TASK_STATE_FAILED'},
  ];

}

class Keys {

  private static readonly NAME = 'name';
  private static readonly EXTERNAL_ID = 'external_id';

  private static readonly keyValidatedFieldMap: Map<string, LegacyProcessTask.ValidatedField> = Map.of(
    Keys.EXTERNAL_ID, LegacyProcessTask.ValidatedField.EXTERNAL_ID,
    Keys.NAME, LegacyProcessTask.ValidatedField.NAME,
  );

  public static toValidatedField(fieldKey: string): LegacyProcessTask.ValidatedField {
    return Keys.keyValidatedFieldMap.get(fieldKey, LegacyProcessTask.ValidatedField.UNKNOWN);
  }

}
