/* eslint-disable */
import { Calendar } from '../../lib/calendar/calendar.service';
import { CalendarEvent, EventColor } from 'calendar-utils';
import { Dates, OffsetDateTime } from '../../lib/util/dates';
import * as moment from 'moment';
import { MultiselectOptionItem } from '../core-utils';
import { TaskItem } from '../../admin/calendar/calendar.component';
import { CalendarView, CustomCalendarView } from '../../fork/angular-calendar/src';
import { Address } from '../../lib/address';
import { ConfigModel, TaskRecordStateItem } from '../task-record-utils';
import { PaletteColorPickerComponent } from '../../shared/palette-color-picker/palette-color-picker.component';
import { ConfigurationResource } from '../../lib/core-ext/configuration.service';
import { Models } from '../model-utils';
import { TaskRecordStateMachine } from '../../lib/task/task-record-statemachine';
import { GlobalCalendarEvent } from '../../lib/global-calendar/global-calendar-event.service';
import CalendarDisplayLabel = ConfigurationResource.CalendarDisplayLabel;
import PostalAddressMapper = Address.PostalAddressMapper;
/* eslint-enable */

export namespace CalendarUtils {

  import CalendarEventOwner = Calendar.CalendarEventOwner;
  import CalendarEventType = Calendar.CalendarEventType;

  export function toCalendarEvent(event: Calendar.CalendarEvent, config: ConfigModel): LocalCalendarEvent {
    return {
      start: new Date(
        event.startTime.getYear(),
        event.startTime.getMonth() - 1,
        event.startTime.getDay(),
        event.startTime.getHour(),
        event.startTime.getMinute()
      ),
      end: new Date(
        event.endTime.getYear(),
        event.endTime.getMonth() - 1,
        event.endTime.getDay(),
        event.endTime.getHour(),
        event.endTime.getMinute()
      ),
      title: event.type.toString(),
      color: EventColorResolver.getEventColor(event, config.calendarUserColorEnabled),
      taskRecordId: event.taskRecord ? event.taskRecord.id : undefined,
      iconClass: event.taskRecord ? TaskRecordStateMachine.taskRecordStates.get(event.taskRecord.state).iconClass : undefined,
      projectRecordId: event.projectRecord ? event.projectRecord.id : undefined,
      owner: event.owner,
      type: event.type,
      line1: event.type === 'AVAILABLE'
        ? event.owner.name
        : event.type === 'PROJECT_RECORD'
          ? event.projectRecord!.name
          : config.calendarLabels[0]
            ? EventLineResolver.getEventLineByLabel(config.calendarLabels[0], event, config.postalAddressFormat)
            : undefined,
      line1Initials: config.calendarDisplayMonogram ? getOwnerInitials(event.owner) : undefined,
      line2: event.type === 'PROJECT_RECORD'
        ? event.projectRecord!.address ? PostalAddressMapper.toString(event.projectRecord!.address, config.postalAddressFormat) : undefined
        : config.calendarLabels[1]
          ? EventLineResolver.getEventLineByLabel(config.calendarLabels[1], event, config.postalAddressFormat)
          : undefined,
      line3: event.type === 'PROJECT_RECORD'
        ? Models.numberToString(event.projectRecord!.taskCount)
        : config.calendarLabels[2]
          ? EventLineResolver.getEventLineByLabel(config.calendarLabels[2], event, config.postalAddressFormat)
          : undefined
    }
  }

  export function toGlobalCalendarEvent(event: GlobalCalendarEvent.GlobalCalendarEvent, config: ConfigModel): LocalCalendarEvent {
    const owner: CalendarEventOwner = {
      name: event.creatorUser.personName,
      userId: event.creatorUser.id
    }
    return {
      start: new Date(
        event.startTime.getYear(),
        event.startTime.getMonth() - 1,
        event.startTime.getDay(),
        event.startTime.getHour(),
        event.startTime.getMinute()
      ),
      end: new Date(
        event.endTime.getYear(),
        event.endTime.getMonth() - 1,
        event.endTime.getDay(),
        event.endTime.getHour(),
        event.endTime.getMinute()
      ),
      title: event.title,
      id: event.id,
      color: {primary: '#fff', secondary: PaletteColorPickerComponent.resolveColor(event.color)},
      owner: owner,
      type: 'AVAILABLE',
      line1: event.title,
      line1Initials: getOwnerInitials(owner),
    }
  }

  export function getStartOfDay(date: Date): OffsetDateTime {
    return Dates.parseOffsetDateTimeIsoString(date.toUTCString()).startOfDay();
  }

  export function getEndOfDay(date: Date): OffsetDateTime {
    return Dates.parseOffsetDateTimeIsoString(date.toUTCString()).endOfDay();
  }

  export function calculateViewDate(dayOfPeriod: number, viewDate: Date, view: CalendarView | CustomCalendarView): Date {
    if (view === CalendarView.Week || view === CustomCalendarView.Timeline) {
      const momentDate = moment(viewDate.toISOString());
      const dayInWeek = momentDate.isoWeekday();
      const dateOffset = dayOfPeriod - dayInWeek;
      return momentDate.add('days', dateOffset).toDate();
    }
    if (view === CalendarView.Month) {
      const momentDate = moment(viewDate.toISOString());
      const dayInMonth = momentDate.daysInMonth();
      const dateOffset = dayOfPeriod - dayInMonth;
      return momentDate.add('days', dateOffset).toDate();
    }
    return viewDate;
  }

  export function calculateStartOfMonth(viewDate: Date): Date {
    return moment(viewDate.toISOString()).startOf('month').toDate();
  }

  export function calculateEndOfMonth(viewDate: Date): Date {
    return moment(viewDate.toISOString()).endOf('month').toDate();
  }

  export function getNextViewDate(viewDate: Date, view: CalendarView | CustomCalendarView): Date {
    if (view === CalendarView.Month) {
      return moment(viewDate.toISOString()).add('month', 1).toDate();
    }
    if (view === CalendarView.Week || view === CustomCalendarView.Timeline) {
      return moment(viewDate.toISOString()).add('week', 1).toDate();
    }
    if (view === CalendarView.Day) {
      return moment(viewDate.toISOString()).add('days', 1).toDate();
    }
    else {
      return viewDate;
    }
  }

  export function getPreviousViewDate(viewDate: Date, view: CalendarView | CustomCalendarView): Date {
    if (view === CalendarView.Month) {
      return moment(viewDate.toISOString()).add('month', -1).toDate();
    }
    if (view === CalendarView.Week || view === CustomCalendarView.Timeline) {
      return moment(viewDate.toISOString()).add('week', -1).toDate();
    }
    if (view === CalendarView.Day) {
      return moment(viewDate.toISOString()).add('days', -1).toDate();
    }
    else {
      return viewDate;
    }
  }

  export function daysBetween(first: Date, second: Date) {

    // Copy date parts of the timestamps, discarding the time parts.
    const one = new Date(first.getFullYear(), first.getMonth(), first.getDate());
    const two = new Date(second.getFullYear(), second.getMonth(), second.getDate());

    // Do the math.
    const millisecondsPerDay = 1000 * 60 * 60 * 24;
    const millisBetween = two.getTime() - one.getTime();
    const days = millisBetween / millisecondsPerDay;

    // Round down.
    return Math.floor(days);
  }

  export enum DaysOfWeek {
    SUNDAY,
    MONDAY,
    TUESDAY,
    WEDNESDAY,
    THURSDAY,
    FRIDAY,
    SATURDAY,
  }

  export interface LocalCalendarEvent extends CalendarEvent {
    iconClass?: string;
    taskRecordId?: number;
    projectRecordId?: number;
    owner?: CalendarEventOwner;
    type: CalendarEventType;
    line1?: string;
    line1Initials?: string;
    line2?: string;
    line3?: string;
  }

  export interface CustomWeekViewAllDayEvent {
    event: LocalCalendarEvent;
    offset: number;
    span: number;
    startsBeforeWeek: boolean;
    endsAfterWeek: boolean;
  }

  export interface CustomDayViewEvent {
    event: LocalCalendarEvent;
    height: number;
    width: number;
    top: number;
    left: number;
    startsBeforeDay: boolean;
    endsAfterDay: boolean;
  }

  export function getOwnerInitials(owner: CalendarEventOwner): string {
    if (owner.name) {
      const words = owner.name.split(' ');
      const firstInitial = words[0][0];
      const secondInitial = words[1] !== undefined ? words[1][0] : undefined;
      const initials = firstInitial + '.' + (secondInitial !== undefined ? secondInitial + '.' : '');
      return ' (' + initials + ')';
    }
    return '';
  }

}

class EventColorResolver {

  private static readonly projectColors: string[] = [
    '#0B8043',
    '#F6BF26',
    '#C41C00',
    '#002984',
    '#9C27B0',
    '#039BE5'
  ];

  private static projectColorCounter: number = 0;

  public static getEventColor(event: Calendar.CalendarEvent, userColorEnabled: boolean): EventColor {
    const eventType = event.type;
    const taskRecord = event.taskRecord;
    const color = event.owner.color;

    const applionGreenActiveBackground = '#e1efef';
    const applionGreen = '#e6f1f2';
    const applionBlue = '#007ab6';
    const applionBlueLight = '#4eb9ec';
    const finishedBackground = '#0b8043';
    const rejectedBackground = '#f6bf26';
    const white = '#fff';
    const gray = '#A9A9A9';
    const darkGray = '#808080';
    const darkerGray = '#585858';

    let primary = applionGreen;
    let secondary = applionGreenActiveBackground;

    if (userColorEnabled) {
      const resolvedColor = color !== undefined ? PaletteColorPickerComponent.resolveColor(color) : applionBlue;
      return {
        primary: white,
        secondary: taskRecord
          ? taskRecord.user !== undefined
            ? resolvedColor
            : taskRecord.mobileApp !== undefined
              ? gray
              : taskRecord.userGroup !== undefined
                ? darkGray
                : darkerGray
          : resolvedColor
      };
    }
    if (eventType === 'AVAILABLE') {
      return {
        primary: primary,
        secondary: secondary,
      };
    }
    else if (eventType === 'TASK_RECORD') {
      primary = white;
      secondary = applionGreen;
      if (taskRecord) {
        if (taskRecord.confirmed) {
          primary = white;
          secondary = applionBlue;
        }
        else {
          primary = white;
          secondary = applionBlueLight;
        }
        if (taskRecord.state === 'FINISHED') {
          primary = white;
          secondary = finishedBackground;
        }
        if (taskRecord.state === 'REJECTED') {
          primary = white;
          secondary = rejectedBackground;
        }
      }
    }
    else if (eventType === 'PROJECT_RECORD') {
      primary = white;
      secondary = this.projectColors[this.projectColorCounter];
      if (this.projectColorCounter < this.projectColors.length - 1) {
        this.projectColorCounter++;
      }
      else {
        this.projectColorCounter = 0;
      }
    }
    return {
      primary: primary,
      secondary: secondary,
    };
  }
}

class EventLineResolver {

  public static getEventLineByLabel(
    label: CalendarDisplayLabel,
    event: Calendar.CalendarEvent,
    postalAddressFormat: string): string | undefined {
    switch (label) {
      case ConfigurationResource.CalendarDisplayLabel.TASK_NAME:
        return event.taskRecord ? event.taskRecord.name : undefined;
      case ConfigurationResource.CalendarDisplayLabel.ASSIGNEE_NAME:
        return event.taskRecord
          ? event.taskRecord.userGroup
            ? event.taskRecord.userGroup.name
            : event.taskRecord.mobileApp
              ? event.taskRecord.mobileApp.name
              : event.owner.name
          : event.owner.name;
      case ConfigurationResource.CalendarDisplayLabel.POC:
        return event.taskRecord
        && event.taskRecord.placeOfConsumption
        && event.taskRecord.placeOfConsumption.address
          ? PostalAddressMapper.toString(event.taskRecord.placeOfConsumption.address, postalAddressFormat)
          : undefined;
      case ConfigurationResource.CalendarDisplayLabel.CUSTOMER_NAME:
        return event.taskRecord && event.taskRecord.customerRecord ? event.taskRecord.customerRecord.name : undefined;
      case ConfigurationResource.CalendarDisplayLabel.CUSTOMER_ADDRESS:
        return event.taskRecord
        && event.taskRecord.customerRecord
        && event.taskRecord.customerRecord.postalAddress
          ? PostalAddressMapper.toString(event.taskRecord.customerRecord.postalAddress, postalAddressFormat)
          : undefined;
      case ConfigurationResource.CalendarDisplayLabel.CUSTOMER_PHONE_NUMBERS:
        return event.taskRecord && event.taskRecord.customerRecord
          ? event.taskRecord.customerRecord.phoneNumbers.size > 0
            ? event.taskRecord.customerRecord.phoneNumbers.size === 1
              ? event.taskRecord.customerRecord.phoneNumbers.get(0).value.format()
              : event.taskRecord.customerRecord.phoneNumbers.get(0).value.format()
              + ' (+' + (event.taskRecord.customerRecord.phoneNumbers.size - 1) + ')'
            : undefined
          : undefined;
    }
  }

}

export class CalendarSearchModel {

  users: MultiselectOptionItem<number>[] = [];
  userGroups: MultiselectOptionItem<number>[] = [];
  devices: MultiselectOptionItem<number>[] = [];
  tasks: TaskItem[] = [];
  taskRecordStates: MultiselectOptionItem<TaskRecordStateMachine.State>[] = [];
  taskId?: number = undefined;
  availableTime?: boolean = undefined;
  daysInWeek: number = 7;

  isAssigneeUserFilled: boolean = true;
  isAssigneeUserGroupFilled: boolean = true;
  isAssigneeEmpty: boolean = true;

  public isEmpty(): boolean {
    return this.users.length === 0
      && this.userGroups.length === 0
      && this.devices.length === 0
      && this.taskId === undefined
      && this.taskRecordStates.length === 0
      && (this.availableTime === undefined || !this.availableTime);
  }

  public clear() {
    this.users = [];
    this.userGroups = [];
    this.devices = [];
    this.tasks = [];
    this.taskRecordStates = [];
    this.taskId = undefined;
    this.availableTime = undefined;
  }

  constructor() {
  }

  get assigneeFilters(): Calendar.CalendarAssigneeType[] {
    const ret: Calendar.CalendarAssigneeType[] = [];
    if (this.isAssigneeUserFilled) {
      ret.push('USER');
    }
    if (this.isAssigneeUserGroupFilled) {
      ret.push('GROUP');
    }
    if (this.isAssigneeEmpty) {
      ret.push('EMPTY');
    }
    return ret;
  }

  set assigneeFilters(value: Calendar.CalendarAssigneeType[]) {
    this.isAssigneeUserFilled = !!value.find(v => v === 'USER');
    this.isAssigneeUserGroupFilled = !!value.find(v => v === 'GROUP');
    this.isAssigneeEmpty = !!value.find(v => v === 'EMPTY');
  }

  get disableWeekend(): boolean {
    return this.daysInWeek === 5;
  }

}
