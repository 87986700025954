import { Injectable } from '@angular/core';
import { MultiselectProvider } from '../multiselect-provider';
import { Observable, Observer } from 'rxjs';
import { MultiselectOptionItem, UiConstants } from '../../util/core-utils';
import { QueryResult } from '../util/services';
import { DeliveryMethod, DeliveryMethodService } from './delivery-method.service';
import { Set, List } from 'immutable'
import { FilterField } from '../query/filterfields';
import { CriteriaBuilder } from '../../util/model-utils';
import { Query } from '../query/field';

@Injectable()
export class DeliveryMethodMultiselectProvider implements MultiselectProvider {

  private multiselectQueryRequest: DeliveryMethod.QueryRequest = {
    fields: f => Set.of(f.id, f.name, f.disabled),
    order: f => List.of(f.name.asc().nullsLast()),
    paging: {
      pageNumber: 1,
      numberOfItems: UiConstants.autocompletePageSize,
    },
    noProgressBar: true,
  };

  constructor(private deliveryMethodService: DeliveryMethodService) {
  }

  load(queryRequest: DeliveryMethod.QueryRequest): Observable<MultiselectOptionItem<number>[]> {
    return Observable.create((observer: Observer<MultiselectOptionItem<number>[]>) => {
      this.deliveryMethodService.query(queryRequest).subscribe((result: QueryResult<DeliveryMethod.DeliveryMethod>) => {
          observer.next(result.items.map(item => this.toMultiselectOptionItem(item!)).toArray());
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  loadAll(predicate?: string): Observable<MultiselectOptionItem<number>[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.filter = (f: FilterField.DeliveryMethod) => CriteriaBuilder.builder()
      .addString((predicate) => f.name.containsIgnoreCase(predicate), predicate)
      .build();
    return this.load(queryRequest);
  }

  loadActive(predicate?: string): Observable<MultiselectOptionItem<number>[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.filter = (f: FilterField.DeliveryMethod) => CriteriaBuilder.builder()
      .addString((predicate) => f.name.containsIgnoreCase(predicate), predicate)
      .addBoolean(() => f.disabled.isFalse())
      .build();
    return this.load(queryRequest);
  }

  loadForParcelCollectionPoint(predicate?: string): Observable<MultiselectOptionItem<number>[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.filter = (f: FilterField.DeliveryMethod) => CriteriaBuilder.builder()
      .addString((predicate) => f.name.containsIgnoreCase(predicate), predicate)
      .addEnum((type) => f.type.eq(type), 'PARCEL_COLLECTION_POINT' as DeliveryMethod.DeliveryMethodType)
      .addBoolean(() => f.disabled.isFalse())
      .build();
    return this.load(queryRequest);
  }

  getById(id: number): Observable<MultiselectOptionItem<number>> {
    return Observable.create((observer: Observer<MultiselectOptionItem<number>>) => {
      this.deliveryMethodService.get({
        id: id
      }).subscribe((result: DeliveryMethod.DeliveryMethod) => {
          observer.next(this.toMultiselectOptionItem(result!));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  getByIds(ids: number[]): Observable<MultiselectOptionItem<number>[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    const filters: Query.Criteria[] = [];
    const f: FilterField.DeliveryMethod = new FilterField.DeliveryMethod();
    ids.forEach(id => {
      filters.push(f.id.eq(id));
    });
    queryRequest.filter = f => Query.Criterias.anyOf(List.of(...filters));
    return this.load(queryRequest);
  }

  toMultiselectOptionItem(item: DeliveryMethod.DeliveryMethod): MultiselectOptionItem<number> {
    const ret = new MultiselectOptionItem<number>();
    ret.id = item.id;
    ret.itemName = item.name;
    ret.disabled = item.disabled;
    return ret;
  }

}
