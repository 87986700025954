<div bsModal [config]="UiConstants.modalConfig" #subTaskDetailDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="subTaskDetailDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title"><strong>{{ 'TASK_RECORD_SUB_TASK_DETAIL' | translate}}</strong></p>
        <button type="button" class="close" (click)="closeSubTaskDetailDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <!--NAME-->
        <div class="row">
          <div class="form-group col-md-4 mb-0">
            <label readonly class="col-form-label form-control-label detail-title">{{'COMMON_NAME' | translate}}</label>
          </div>
          <div class="form-group col-md-8 mb-0">
            <label readonly class="col-form-label form-control-label detail-description"> {{ model.name }} </label>
          </div>
        </div>

        <!--TYPE-->
        <div class="row" *ngIf="model.type.length > 0">
          <div class="form-group col-md-4 mb-0">
            <label readonly class="col-form-label form-control-label detail-title">{{'TASK_RECORD_GLOBAL_LIST_TASK_TYPE_NAME' | translate}}</label>
          </div>
          <div class="form-group col-md-8 mb-0">
            <label readonly class="col-form-label form-control-label detail-description"> {{ model.type }} </label>
          </div>
        </div>

        <!--NOTE-->
        <div class="row" *ngIf="model.note.length > 0">
          <div class="form-group col-md-4 mb-0">
            <label readonly class="col-form-label form-control-label detail-title">{{'COMMON_NOTE' | translate}}</label>
          </div>
          <div class="form-group col-md-8 mb-0">
            <label readonly class="col-form-label form-control-label detail-description"> {{ model.note }} </label>
          </div>
        </div>

        <!--WORKER USER-->
        <div class="row" *ngIf="model.workerUser.length > 0">
          <div class="form-group col-md-4 mb-0">
            <label readonly class="col-form-label form-control-label detail-title">{{'TASK_RECORD_TABLE_HEADER_USER_NAME' | translate}}</label>
          </div>
          <div class="form-group col-md-8 mb-0">
            <label readonly class="col-form-label form-control-label detail-description"> {{model.workerUser}} </label>
          </div>
        </div>

        <!--STATE-->
        <div class="row">
          <div class="form-group col-md-4 mb-0">
            <label readonly class="col-form-label form-control-label detail-title">{{'TASK_RECORD_SEARCH_STATE' | translate}}</label>
          </div>
          <div class="form-group col-md-8 mb-0">
            <label readonly class="col-form-label form-control-label detail-description"> {{model.stateStringKey | translate }} </label>
          </div>
        </div>

        <!--CREATION TIME-->
        <div class="row">
          <div class="form-group col-md-4 mb-0">
            <label readonly class="col-form-label form-control-label detail-title">{{'COMMON_CREATION_TIME' | translate}}</label>
          </div>
          <div class="form-group col-md-8 mb-0">
            <label readonly class="col-form-label form-control-label detail-description"> {{ model.creationTime.toUtcIsoString() | date:'medium' }} </label>
          </div>
        </div>

        <!--START TIME-->
        <div class="row" *ngIf="model.startTime && model.startTime.isValid()">
          <div class="form-group col-md-4 mb-0">
            <label readonly class="col-form-label form-control-label detail-title">{{'TASK_RECORD_SUB_TASK_START_TIME' | translate}}</label>
          </div>
          <div class="form-group col-md-8 mb-0">
            <label readonly class="col-form-label form-control-label detail-description"> {{ model.startTime.toUtcIsoString() | date:'medium' }} </label>
          </div>
        </div>

        <!--END TIME-->
        <div class="row" *ngIf="model.endTime && model.endTime.isValid()">
          <div class="form-group col-md-4 mb-0">
            <label readonly class="col-form-label form-control-label detail-title">{{'TASK_RECORD_SUB_TASK_END_TIME' | translate}}</label>
          </div>
          <div class="form-group col-md-8 mb-0">
            <label readonly class="col-form-label form-control-label detail-description"> {{ model.endTime.toUtcIsoString() | date:'medium' }} </label>
          </div>
        </div>

      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary" (click)="closeSubTaskDetailDialog()">
          {{'COMMON_BUTTON_CLOSE' | translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

