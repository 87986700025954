/* eslint-disable */
import { Form } from '../../lib/form/form.service';
import { List } from 'immutable';
/* eslint-enable */

export class FieldInputCapitalizationMode {

  public static readonly VALUES: List<Form.FormFieldInputCapitalizeMode> = List.of(
    Form.FormFieldInputCapitalizeMode.NONE,
    Form.FormFieldInputCapitalizeMode.WORDS,
    Form.FormFieldInputCapitalizeMode.SENTENCES,
    Form.FormFieldInputCapitalizeMode.CHARACTERS,
  );

  public static getFieldInputCapitalizeModeDictionaryKey(icm: Form.FormFieldInputCapitalizeMode): string {
    switch (icm) {
      case Form.FormFieldInputCapitalizeMode.NONE:
        return 'FORM_FIELD_INPUT_CAPITALIZE_MODE_NONE';
      case Form.FormFieldInputCapitalizeMode.WORDS:
        return 'FORM_FIELD_INPUT_CAPITALIZE_MODE_WORDS';
      case Form.FormFieldInputCapitalizeMode.SENTENCES:
        return 'FORM_FIELD_INPUT_CAPITALIZE_MODE_SENTENCES';
      case Form.FormFieldInputCapitalizeMode.CHARACTERS:
        return 'FORM_FIELD_INPUT_CAPITALIZE_MODE_CHARACTERS';
      default:
        throw new Error('Unhandled field input capitalization mode');
    }
  }

}

export interface FormFieldInputCapitalizeModeModel {
  formFieldInputCapitalizeMode: Form.FormFieldInputCapitalizeMode
}
