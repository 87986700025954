import * as draw2d from '../../../../../../../node_modules/draw2d/dist/draw2d.js';
import { FigureSelectionFeedbackPolicy } from '../policy/figure-selection-feedback-policy';
import { Label } from './label';

export class Group extends draw2d.shape.layout.VerticalLayout {

  topLabel = new draw2d.shape.basic.Label(); // Name of the group

  dropZoneRectangle = new draw2d.shape.basic.Rectangle(); // Rectangle for the drop zone
  hasDropZoneRectangle = false;

  innerLayout = new draw2d.shape.layout.VerticalLayout(); // The inner layout containing the dropped elements and the drop zone

  private inputPort: draw2d.Port;
  private outputPort: draw2d.Port;

  constructor(readonly?: boolean) {
    super();

    // Custom policy to show green outline on selection
    super.installEditPolicy(new FigureSelectionFeedbackPolicy());

    super.setRadius(4);
    super.setStroke(1);
    super.setColor(new draw2d.util.Color('#8D949C'));
    super.setBackgroundColor(new draw2d.util.Color('#3E515B'));
    super.setDeleteable(false);
    super.setSelectable(false);
    super.setDraggable(!readonly);

    this.outputPort = this.createOutputPort(readonly);
    this.inputPort = this.createInputPort(readonly);
    this.addTopLabel();
    this.addInnerVerticalLayout();
    this.initDropZoneRectangle();
  }

  private createInputPort(readonly?: boolean) {
    const inputPort = super.createPort('input');
    inputPort.setStroke(0);
    inputPort.setDiameter(4);
    inputPort.setBackgroundColor(new draw2d.util.Color('#8D949C'));
    inputPort.useGradient = false;
    inputPort.setColor(new draw2d.util.Color('#00727B'));
    inputPort.setDraggable(!readonly);
    inputPort.setSelectable(!readonly);
    return inputPort;
  }

  private createOutputPort(readonly?: boolean) {
    const outputPort = super.createPort('output');
    outputPort.setStroke(0);
    outputPort.setDiameter(4);
    outputPort.setBackgroundColor(new draw2d.util.Color('#8D949C'));
    outputPort.useGradient = false;
    outputPort.setColor(new draw2d.util.Color('#00727B'));
    outputPort.setDraggable(!readonly);
    outputPort.setSelectable(!readonly);
    return outputPort;
  }

  private initDropZoneRectangle() {
    this.dropZoneRectangle.setStroke(1);
    this.dropZoneRectangle.setRadius(4);
    this.dropZoneRectangle.setDashArray('- ');
    this.dropZoneRectangle.setColor(new draw2d.util.Color('#8D949C'));
    this.dropZoneRectangle.setMinHeight(40);
    this.dropZoneRectangle.setBackgroundColor(new draw2d.util.Color('#FBF5DB'));
  }

  setName(name: string) {
    this.topLabel.setText(Label.getFormattedName(name, 15));
  }

  getName(): string {
    return this.topLabel.getText().replace(/\u00A0/g, '');
  }

  addTopLabel() {
    this.topLabel.setStroke(0);
    this.topLabel.setPadding(12);
    this.topLabel.setFontFamily('Lucida Console');
    this.topLabel.setFontColor(new draw2d.util.Color('#FFFFFF'));
    this.topLabel.setBackgroundColor(new draw2d.util.Color('#3E515B'));
    super.add(this.topLabel);
  }

  addInnerVerticalLayout() {
    this.innerLayout.setBackgroundColor(new draw2d.util.Color('#E9E9E9'));
    super.add(this.innerLayout);
  }

  // Required for drag and drop to work properly
  delegateTarget(draggedFigure) {
    return this;
  }

  showDropZone() {
    if (!this.hasDropZoneRectangle) {
      this.hasDropZoneRectangle = true;
      this.innerLayout.setPadding(5);
      this.innerLayout.setGap(5);
      this.innerLayout.add(this.dropZoneRectangle);
    }
  }

  hideDropZone() {
    this.innerLayout.remove(this.dropZoneRectangle);
    const children = this.innerLayout.getChildren().data;
    if (children.length === 0) {
      this.innerLayout.setPadding(0);
      this.innerLayout.setGap(0);
    }
    this.hasDropZoneRectangle = false;
  }

  installEditPolicy(policy) {
    super.installEditPolicy(policy);
  }

  add(attr) {
    super.add(attr);
  }

  addItem(figure: Label) {
    const item =  new Label();
    item.setIcon(figure.getIcon());
    item.setName(figure.getName(), 15);
    item.setId(figure.getId());
    item.setUserData(figure.getUserData());
    this.innerLayout.setPadding(5);
    this.innerLayout.setGap(5);
    this.innerLayout.add(item);
  }

  getChildren() {
    return super.getChildren();
  }

  getChildAtPosition(x: number, y: number): any {
    const childrenData = super.getChildren().data;
    const topLabel = childrenData[0];
    if (y <= topLabel.height) {
      return undefined;
    }
    const children = childrenData[1].children.data;
    let startingY = childrenData[1].y;
    let label;
    for (const child of children) {
      startingY += child.figure.height;
      if (y <= startingY) {
        label = child.figure;
        break;
      }
    }
    return label;
  }

  removeChild(figure) {
    this.innerLayout.remove(figure);
    const children = this.innerLayout.getChildren().data;
    if (children.length === 0) {
      this.innerLayout.setPadding(0);
      this.innerLayout.setGap(0);
    }
  }

  getInputPort(): draw2d.Port {
    return this.inputPort;
  }

  getOutputPort(): draw2d.Port {
    return this.outputPort;
  }

  setId(id: number) {
    super.setId(id);
  }

  on(event, callback) {
    super.on(event, callback);
  }

  off(eventOrFunction) {
    super.off(eventOrFunction);
  }

  getConnections() {
    return super.getConnections();
  }

  setUserData(data) {
    super.setUserData(data);
  }

  getUserData() {
    return super.getUserData();
  }
}

