<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div class="breadcrumb-menu d-flex">
    <!--todo: rights-->
    <div class="breadcrumb-button-icon-container cursor-pointer"
         *ngIf="isExteriorTransportEnabledByServer() && rightModel.exteriorTransportCreate.hasRight()"
         (click)="openExteriorTransportCreateDialog()">
      <a class="btn-setting" title="{{'EXTERIOR_TRANSPORT_CREATE' | translate}}">
        <i class="icomoon icomoon-exterior-transport"></i>
        <span class="breadcrumb-button-text-visible">{{'EXTERIOR_TRANSPORT_CREATE' | translate}}</span>
      </a>
    </div>
    <div class="breadcrumb-button-icon-container cursor-pointer"
         *ngIf="rightModel.shipmentChangeSubTransporter.hasRight()"
         (click)="openChangeSubtransporterDialog()">
      <a class="btn-setting" title="{{'SHIPMENT_CHANGE_SUBTRANSPORTER_TITLE' | translate}}">
        <i class="icomoon icomoon-vehicles"></i>
        <span class="breadcrumb-button-text-visible">{{'SHIPMENT_CHANGE_SUBTRANSPORTER_TITLE' | translate}}</span>
      </a>
    </div>
    <div *ngIf="rightModel.transportCreate.hasRight()"
         class="breadcrumb-button-icon-container cursor-pointer"
         (click)="openTransportCreateDialog()">
      <a class="btn-setting" title="{{'SHIPMENT_CREATE_TRANSPORT' | translate}}">
        <i class="icomoon icomoon-vehicles"></i>
        <span class="breadcrumb-button-text-visible">{{'SHIPMENT_CREATE_TRANSPORT' | translate}}</span>
      </a>
    </div>
    <!-- DROPDOWN TRANSPORT START -->
    <div class="breadcrumb-button-icon-container-no-text cursor-pointer p-0 negative-margin-right"
         *ngIf="rightModel.transportUpdate.hasRight() && shipmentGroupList.length > 0">
      <a class="dropdown dropdown-arrow-padding"
         dropdown title="{{'COMMON_BUTTON_ADD' | translate}}"
         href="#" onclick="return false" dropdownToggle
         data-toggle="dropdown" role="button"
         aria-haspopup="true" aria-expanded="false">
        <i class="icomoon icomoon-dropdown"></i>
        <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
                <span (click)="openAddToTransportDialog()"
                      class="btn-setting dropdown-item" title="{{'SHIPMENT_ADD_TO_TRANSPORT' | translate}}">
                  <i class="icomoon icomoon-vehicle-add"></i> {{'SHIPMENT_ADD_TO_TRANSPORT' | translate}}
                </span>
        </div>
      </a>
    </div>
    <!-- DROPDOWN TRANSPORT END -->
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">
  <div class="card">
    <div class="card-header">
      {{'SHIPMENTS' | translate}}
      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()"
           title="{{'COMMON_SHOW_SEARCH_HINT' | translate}}">
          <i #searchIcon class="icomoon icomoon-search"></i>
        </a>
      </div><!-- /.card-actions -->
    </div>
    <div class="card-body border-bottom" *ngIf="showSearch">
      <app-dql-search-container #dqlSearchContainer
                                (onSearch)="onDqlSearchClicked()"
                                [rightModel]="rightModel"
                                [dqlStoredQueryArgs]="dqlStoredArgs"

                                [dialogContainer]="dqlDialogHolder"
                                [isTyped]="false">
      </app-dql-search-container>
    </div>
    <div class="card-body">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header w-checkbox">
            <app-animated-checkbox [selected]="eachShipmentSelected"
                                   (clickEvent)="toggleEachShipment()"></app-animated-checkbox>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op [text]="'SHIPMENT_LIST_GROUP_DELIVERY_NOTE_NUMBER' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-sorter-no-op [text]="'SHIPMENT_LIST_WAYBILL_NUMBER' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-field-sorter [orderField]="ShipmentGroup.OrderField.DEMANDER_NAME"
                                    [orderType]="queryModel.getOrderType(ShipmentGroup.OrderField.DEMANDER_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'SHIPMENT_LIST_CUSTOMER' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-sorter-no-op
              [text]="('SHIPMENT_LIST_SOURCE_ADDRESS' | translate) + ' / ' + ('SHIPMENT_LIST_DESTINATION_ADDRESS' | translate)">
            </app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-sorter-no-op [text]="'SHIPMENT_LIST_TRANSPORTER_COMPANY' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op [text]="'SHIPMENT_LIST_STATE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let shipmentGroup of shipmentGroupList | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }">
          <tr [ngClass]="shipmentGroup.collapsed ? '' : 'shipment-row'">
            <td>
              <app-animated-checkbox
                *ngIf="shipmentGroup.isSingle"
                [(selected)]="shipmentGroup.shipment.selected">
              </app-animated-checkbox>
              <i *ngIf="!shipmentGroup.isSingle"
                 (click)="toggleGroupCollapsed(shipmentGroup)"
                 title="{{'MENU_NAVBAR_MENU_SHIPMENTS' | translate}}"
                 class="shipment-icon icon-medium icomoon
                        {{shipmentGroup.collapsed ? 'icomoon-arrow-filled-right' : 'icomoon-arrow-filled-down'}}
                        cursor-pointer">
              </i>
            </td>
            <td class="responsive-table-column long">
              <a class="id-text cursor-pointer"
                 href="#" onclick="return false"
                 uiSref="Admin.ShipmentDetail"
                 [uiParams]="{ id: shipmentGroup.id }"
                 title="{{'COMMON_BUTTON_DETAILS' | translate}}">
                {{shipmentGroup.deliveryNoteNumber}}
              </a>
              <ng-container *ngIf="shipmentGroup.isSingle">
                <br>
                <div class="text-truncate table-text-subtitle d-flex align-items-center">
                   <i class="shipment-icon icon-medium icomoon icomoon-shipment"></i>
                    <div>{{shipmentGroup.shipment.deliveryNoteNumber}}</div>
                </div>
              </ng-container>
            </td>
            <td class="responsive-table-column hidden-md-down">{{shipmentGroup.isSingle ? shipmentGroup.shipment.waybillNumber : ''}}</td>
            <td class="responsive-table-column hidden-lg-down">{{shipmentGroup.customer}}</td>
            <td class="responsive-table-column hidden-xs-down long">
              <div class="text-truncate">
                <span class="text-truncate">{{shipmentGroup.sourceAddress}}</span>
                <br>
                <span class="text-truncate table-text-subtitle">{{shipmentGroup.destinationAddress}}</span>
              </div>
            </td>
            <td class="responsive-table-column hidden-lg-down">
              {{shipmentGroup.transporterCompany.name}}
            </td>
            <td class="fit text-center align-middle hidden-sm-down">
              <app-table-badge
                *ngIf="!shipmentGroup.isSingle"
                [badgeStyle]="shipmentGroup.stateObject.badgeStyle"
                [iconClass]="shipmentGroup.stateObject.iconClass"
                [text]="shipmentGroup.state">
              </app-table-badge>
              <app-table-badge
                *ngIf="shipmentGroup.isSingle"
                [badgeStyle]="shipmentGroup.shipment.stateObject.badgeStyle"
                [iconClass]="shipmentGroup.shipment.stateObject.iconClass"
                [text]="shipmentGroup.shipment.state">
              </app-table-badge>
            </td>
            <td class="align-middle">
              <app-table-options-menu>
                <!--todo: allowed states-->
                <app-dropdown-item
                  *ngIf="canEditGroup(shipmentGroup)"
                  [iconClass]="'icomoon-modify'"
                  [titleStringKey]="'COMMON_BUTTON_EDIT'"
                  class="cursor-pointer"
                  uiSref="Admin.ShipmentEdit"
                  [uiParams]="{ id: shipmentGroup.id }">
                </app-dropdown-item>
                <app-dropdown-item
                  [iconClass]="'icomoon-detail'"
                  [titleStringKey]="'COMMON_BUTTON_DETAILS'"
                  class="cursor-pointer"
                  uiSref="Admin.ShipmentDetail"
                  [uiParams]="{ id: shipmentGroup.id }">
                </app-dropdown-item>
                <!-- SHIPPING DEMANDS -->
                <div class="d-flex dropdown-item no-hover border-bottom-0"
                     *ngIf="rightModel.shippingDemandRead.hasRight() && shipmentGroup.demands && shipmentGroup.demands.length > 0">
                  <i class="icon-standard dropdown-icon icomoon icomoon-shipment-demand"></i>
                  <div>
                    <div class="text-nowrap small">{{'SHIPMENT_RELATED_SHIPPING_DEMANDS' | translate: { count: shipmentGroup.demands.length } }}</div>
                    <div *ngFor="let shippingDemand of shipmentGroup.demands" class="text-nowrap">
                      <a class="cursor-pointer"
                         uiSref="Admin.ShippingDemandDetail"
                         [uiParams]="{ id: shippingDemand.id }">
                        {{shippingDemand.demandSerial}}
                      </a>
                    </div>
                  </div>
                </div>
                <!-- TRANSPORTS -->
                <div class="d-flex dropdown-item no-hover border-bottom-0"
                     *ngIf="rightModel.transportRead.hasRight() && shipmentGroup.relatedTransports.length > 0">
                  <i class="icon-standard dropdown-icon icomoon icomoon-vehicles"></i>
                  <div>
                    <div class="text-nowrap small">{{'SHIPPING_DEMAND_RELATED_TRANSPORTS' | translate: { count: shipmentGroup.relatedTransports.length } }}</div>
                    <div *ngFor="let transport of shipmentGroup.relatedTransports" class="text-nowrap">
                      <a class="cursor-pointer"
                         uiSref="Admin.TransportDetail"
                         [uiParams]="{ id: transport.id }">
                        {{transport.waybillNumber}}
                      </a>
                    </div>
                  </div>
                </div>
              </app-table-options-menu>
            </td>
          </tr>
          <tr *ngIf="!shipmentGroup.isSingle && !shipmentGroup.collapsed" class="no-hover">
            <td colspan="8" class="shipment-row-shadow">
              <app-shipment-group-shipment-list
              [shipments]="shipmentGroup.shipments">
              </app-shipment-group-shipment-list>
            </td>
          </tr>
        </ng-container>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header w-checkbox">
            <app-animated-checkbox [selected]="eachShipmentSelected"
                                   (clickEvent)="toggleEachShipment()"></app-animated-checkbox>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op [text]="'SHIPMENT_LIST_GROUP_DELIVERY_NOTE_NUMBER' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-sorter-no-op [text]="'SHIPMENT_LIST_WAYBILL_NUMBER' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-field-sorter [orderField]="ShipmentGroup.OrderField.DEMANDER_NAME"
                                    [orderType]="queryModel.getOrderType(ShipmentGroup.OrderField.DEMANDER_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'SHIPMENT_LIST_CUSTOMER' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-sorter-no-op
              [text]="('SHIPMENT_LIST_SOURCE_ADDRESS' | translate) + ' / ' + ('SHIPMENT_LIST_DESTINATION_ADDRESS' | translate)">
            </app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-sorter-no-op [text]="'SHIPMENT_LIST_TRANSPORTER_COMPANY' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op [text]="'SHIPMENT_LIST_STATE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>
  </div>
</div>

<div bsModal #changeSubtransporterDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="changeSubtransporterDialogVisible">
    <div class="modal-content">
      <form class="form-horizontal bordered-row" (ngSubmit)="f.form.valid && changeSubtransporter()" #f="ngForm" novalidate>
        <div class="modal-header">
          <h4 class="modal-title">{{'SHIPMENT_CHANGE_SUBTRANSPORTER_TITLE' | translate}}</h4>
          <button type="button" class="close" (click)="closeChangeSubtransporterDialog()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">

          <div class="form-group row"
               [ngClass]="{ 'has-danger': f.submitted && !transporter_company.valid }">
            <label class="col-form-label col-md-4 required-field-label">
              {{'SHIPMENT_CHANGE_SUBTRANSPORTER_TRANSPORTER_COMPANY' | translate}}
            </label>
            <div class="col-md-8">
              <angular2-multiselect [data]="transporterCompanies" [settings]="singleDropdownSettings"
                                    class="form-control"
                                    (onRemoteSearch)="loadTransporterCompanies($event.target.value)"
                                    [(ngModel)]="changeSubtransporterModel.transporterCompany"
                                    id="transporter_company" name="transporter_company" #transporter_company="ngModel" required>
              </angular2-multiselect>
              <div class="form-control-feedback" *ngIf="f.submitted && !transporter_company.valid">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
            </div>
            <!-- /.col-md-8 -->
          </div>

          <div class="alert alert-warning mb-0">
            <p class="mb-0">
              <span class="font-weight-bold">
                {{'SHIPMENT_CHANGE_SUBTRANSPORTER_SHIPMENT_NUMBER' | translate: {number: changeSubtransporterModel.shipmentIds.length} }}
              </span>
              <span>
                {{'SHIPMENT_CHANGE_SUBTRANSPORTER_CONFIRMATION' | translate }}
              </span>
            </p>
          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="closeChangeSubtransporterDialog()">
            {{'COMMON_BUTTON_CANCEL' | translate}}
          </button>
          <button type="submit" class="btn btn-primary">{{'COMMON_BUTTON_SAVE' |
              translate}}
          </button>
        </div>
      </form>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<div bsModal [config]="UiConstants.modalConfig" #transportCreateDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="transportCreateDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'SHIPPING_DEMAND_CREATE_TRANSPORT' | translate}}</h4>
        <button type="button" class="close" (click)="closeTransportCreateDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <div class="form-group row">
          <label class="col-form-label col-md-4">{{'TRANSPORT_CREATE_VEHICLE' | translate}}</label>
          <div class="col-md-8">
            <angular2-multiselect [data]="vehicleList" [settings]="singleDropdownSettings"
                                  class="form-control"
                                  (onRemoteSearch)="loadVehicles($event.target.value)"
                                  [(ngModel)]="transportCreateModel.vehicle">
            </angular2-multiselect>
          </div>
          <!-- /.col-md-8 -->
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-4">{{'TRANSPORT_CREATE_DRIVER' | translate}}</label>
          <div class="col-md-8">
            <angular2-multiselect [data]="driverList" [settings]="singleDropdownSettings"
                                  class="form-control"
                                  (onRemoteSearch)="loadDrivers($event.target.value)"
                                  [(ngModel)]="transportCreateModel.driver">
            </angular2-multiselect>
          </div>
          <!-- /.col-md-8 -->
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-4">{{'TRANSPORT_CREATE_SAFETY_SHIPPING' | translate}}</label>
          <div class="col-md-8">
            <app-animated-checkbox
              [readonly]="!transportCreateModel.safetyShippingUserSettable"
              [(selected)]="transportCreateModel.safetyShipping">
            </app-animated-checkbox>
          </div>
          <!-- /.col-md-8 -->
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-4">{{'COMMON_COMMENT' | translate}}</label>
          <div class="col-md-8">
            <input type="text" class="form-control" placeholder="{{'COMMON_COMMENT' | translate}}"
                   [(ngModel)]="transportCreateModel.comment">
          </div>
          <!-- /.col-md-8 -->
        </div>

        <div class="alert alert-warning mb-0">
          <p class="mb-0">
            <span class="font-weight-bold">
              {{'TRANSPORT_CREATE_BY_SHIPMENTS_SELECTED_NUMBER' | translate: {number: transportCreateModel.shipmentIds.length} }}
            </span>
            <span>
              {{'TRANSPORT_CREATE_SELECTED_CONFIRMATION' | translate }}
            </span>
          </p>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeTransportCreateDialog()">
          {{'COMMON_BUTTON_CANCEL' | translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="createTransport()">{{'COMMON_BUTTON_SAVE' |
          translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<div bsModal [config]="UiConstants.modalConfig" #addToTransportDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="addToTransportDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'SHIPMENT_ADD_TO_TRANSPORT' | translate}}</h4>
        <button type="button" class="close" (click)="closeAddToTransportDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <div class="form-group row"
             [ngClass]="{ 'has-danger': addToTransportSubmitted && !transport.valid }">
          <label class="col-form-label col-md-4 required-field-label">
            {{'MENU_NAVBAR_MENU_TRANSPORT' | translate}}
          </label>
          <div class="col-md-8">
            <angular2-multiselect
              id="transport"
              name="transport"
              #transport="ngModel"
              [(ngModel)]="addToTransportModel.transport"
              (ngModelChange)="onTransportChosen()"
              (onRemoteSearch)="loadTransports($event.target.value)"
              [data]="transportList"
              [settings]="singleDropdownSettings"
              required>
            </angular2-multiselect>
            <div class="form-control-feedback" *ngIf="addToTransportSubmitted && !transport.valid">
              {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
            </div>
          </div>
          <!-- /.col-md-8 -->
        </div>

        <ng-container *ngIf="addToTransportModel.transportId">

          <div class="form-group row">
            <label class="col-form-label col-md-4">{{'TRANSPORT_CREATE_SAFETY_SHIPPING' | translate}}</label>
            <div class="col-md-8">
              <app-animated-checkbox
                [(selected)]="addToTransportModel.safetyShipping"
                [readonly]="true">
              </app-animated-checkbox>
            </div>
            <!-- /.col-md-8 -->
          </div>

          <div class="form-group row">
            <label class="col-form-label col-md-4">{{'TRANSPORT_CREATE_SECURITY_GUARD_NAME' | translate}}</label>
            <div class="col-md-8">
              <label readonly
                     class="col-form-label form-control-label detail-description">{{addToTransportModel.securityGuard}}
              </label>
            </div>
            <!-- /.col-md-8 -->
          </div>

          <div class="form-group row">
            <label class="col-form-label col-md-4">{{'COMMON_COMMENT' | translate}}</label>
            <div class="col-md-8">
              <label readonly
                     class="col-form-label form-control-label detail-description">{{addToTransportModel.comment}}
              </label>
            </div>
            <!-- /.col-md-8 -->
          </div>

        </ng-container>

        <div class="alert alert-warning mb-0">
          <p class="mb-0">
            <span class="font-weight-bold">
              {{'SHIPMENT_ADD_DIALOG_NUMBER' | translate: {number: addToTransportModel.shipmentIds.length} }}
            </span>
            <span>
              {{'TRANSPORT_DIALOG_CONFIRMATION_ADD' | translate }}
            </span>
          </p>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeAddToTransportDialog()">
          {{'COMMON_BUTTON_CANCEL' | translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="addToTransport()">{{'COMMON_BUTTON_SAVE' |
          translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<div bsModal [config]="UiConstants.modalConfig" #exteriorTransportCreateDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="exteriorTransportCreateDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'EXTERIOR_TRANSPORT_CREATE' | translate}}</h4>
        <button type="button" class="close" (click)="closeExteriorTransportCreateDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <div class="alert alert-warning mb-0">
          <p class="mb-0">
            <span class="font-weight-bold">
              {{'EXTERIOR_TRANSPORT_CREATE_BY_SHIPMENTS_SELECTED_NUMBER_SHIPMENT' | translate: {number: exteriorTransportCreateModel.shipmentIds.length} }}
            </span>
            <span>
              {{'EXTERIOR_TRANSPORT_CREATE_SELECTED_CONFIRMATION' | translate }}
            </span>
          </p>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeExteriorTransportCreateDialog()">
          {{'COMMON_BUTTON_CANCEL' | translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="createExteriorTransport()">{{'COMMON_BUTTON_SAVE' |
          translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<app-dql-dialog-container #dqlDialogHolder></app-dql-dialog-container>
