import { GrantedPermissionSet, GrantedPermissionSetResolver } from '../right.service';
import { OperationRights } from '../../app.right-definitions';

export class ShopRenterRightModel {

  public readonly read: GrantedPermissionSet;
  public readonly update: GrantedPermissionSet;

  constructor(resolver: GrantedPermissionSetResolver) {
    this.read = resolver.of(OperationRights.SHOPRENTER_SHOP_READ);
    this.update = resolver.of(OperationRights.SHOPRENTER_SHOP_UPDATE);
  }
}
