<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
    <div class="breadcrumb-menu d-flex">
      <div *ngIf="rightModel.processRead.hasRight()"
           class="breadcrumb-button-icon-container cursor-pointer"
           (click)="exportProcessXls()">
        <a class="btn-setting" title="{{'COMMON_EXPORT_XLSX' | translate}}">
          <i class="icomoon icomoon-file-xlsx"></i>
          <span class="breadcrumb-button-text-visible">{{'COMMON_EXPORT_XLSX' | translate}}</span>
        </a>
      </div>
      <div *ngIf="rightModel.processCreate.hasRight()"
           class="breadcrumb-button-icon-container cursor-pointer negative-margin-right"
           (click)="openProcessCreateDialog()">
        <a class="btn-setting" title="{{'PROCESS_CREATE' | translate}}">
          <i class="icomoon icomoon-add"></i>
          <span class="breadcrumb-button-text-visible">{{'PROCESS_CREATE' | translate}}</span>
        </a>
      </div>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="card">
    <div class="card-header">
      {{'MENU_NAVBAR_NAV_TITLE_PROCESSS' | translate}}

      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()" title="{{'COMMON_BUTTON_SEARCH' | translate}}"><i
          class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>

    <div class="card-body border-bottom" *ngIf="showSearch">
      <div class="row form-group">
        <div class="col-md-3">
          <label class="search-label">{{'COMMON_TABLE_HEADER_ID' | translate}}</label>
          <input type="number" class="form-control"
                 placeholder="{{'COMMON_TABLE_HEADER_ID' | translate}}"
                 maxlength="{{UiConstants.maxInputNumberLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.id">
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'COMMON_TABLE_HEADER_EXTERNAL_ID' | translate}}</label>
          <input type="text" class="form-control"
                 placeholder="{{'COMMON_TABLE_HEADER_EXTERNAL_ID' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.externalId">
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'COMMON_NAME' | translate}}</label>
          <input type="text" class="form-control"
                 placeholder="{{'COMMON_NAME' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.name">
        </div>
        <div class="col-md-3">
          <label class="search-label"> {{ 'COMMON_STATE' | translate }} </label>
          <select
            class="form-control"
            [(ngModel)]="searchModel.state" [compareWith]="SelectUtils.compareObjects">
            <option *ngFor="let item of states" [ngValue]="item">{{item.stringKey | translate}}</option>
          </select>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-3">
          <label class="search-label">{{'COMMON_CREATOR_USER' | translate}}</label>
          <angular2-multiselect class="form-control"
                                [data]="users"
                                [(ngModel)]="searchModel.creatorUser"
                                (onRemoteSearch)="onUserSearch($event.target.value)"
                                [settings]="dropdownSettings">
          </angular2-multiselect>
        </div>

        <div class="col-md-3">
          <label class="search-label">{{'COMMON_LAST_EDITOR_USER' | translate}}</label>
          <angular2-multiselect class="form-control"
                                [data]="users"
                                [(ngModel)]="searchModel.lastModifiedUser"
                                (onRemoteSearch)="onUserSearch($event.target.value)"
                                [settings]="dropdownSettings">
          </angular2-multiselect>
        </div>

        <div class="col-md-3">
          <label class="search-label">{{'PROCESS_CREATE_WORKFLOW' | translate}}</label>
          <angular2-multiselect class="form-control"
                                [data]="workflows"
                                [(ngModel)]="searchModel.workflow"
                                (onRemoteSearch)="onWorkflowSearch($event.target.value)"
                                [settings]="dropdownSettings">
          </angular2-multiselect>
        </div>

      </div>
      <div class="row form-group">
        <div class="col-md-3">
          <label class="search-label">{{'WORKLOG_SEARCH_START_DATE_FROM' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control date"
                   placeholder="{{'WORKLOG_SEARCH_START_DATE_FROM' | translate}}"
                   [(ngModel)]="searchModel.startTimeFrom" ngbDatepicker #start_date_from="ngbDatepicker">
            <div class="input-group-addon-gray" (click)="start_date_from.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'WORKLOG_SEARCH_START_DATE_TO' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control date"
                   placeholder="{{'WORKLOG_SEARCH_START_DATE_TO' | translate}}"
                   [(ngModel)]="searchModel.startTimeTo" ngbDatepicker #start_date_to="ngbDatepicker">
            <div class="input-group-addon-gray" (click)="start_date_to.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'WORKLOG_SEARCH_END_DATE_FROM' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control date"
                   placeholder="{{'WORKLOG_SEARCH_END_DATE_FROM' | translate}}"
                   [(ngModel)]="searchModel.finishTimeFrom" ngbDatepicker #finish_from="ngbDatepicker">
            <div class="input-group-addon-gray" (click)="finish_from.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'WORKLOG_SEARCH_END_DATE_TO' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control date"
                   placeholder="{{'WORKLOG_SEARCH_END_DATE_TO' | translate}}"
                   [(ngModel)]="searchModel.finishTimeTo" ngbDatepicker #finish_to="ngbDatepicker">
            <div class="input-group-addon-gray" (click)="finish_to.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-3">
          <label class="search-label">{{'COMMON_SEARCH_CREATION_TIME_FROM' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control date"
                   placeholder="{{'COMMON_SEARCH_CREATION_TIME_FROM' | translate}}"
                   [(ngModel)]="searchModel.creationTimeFrom" ngbDatepicker #creation_date_from="ngbDatepicker">
            <div class="input-group-addon-gray" (click)="creation_date_from.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'COMMON_SEARCH_CREATION_TIME_TO' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control date"
                   placeholder="{{'COMMON_SEARCH_CREATION_TIME_TO' | translate}}"
                   [(ngModel)]="searchModel.creationTimeTo" ngbDatepicker #creation_date_to="ngbDatepicker">
            <div class="input-group-addon-gray" (click)="creation_date_to.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'COMMON_SEARCH_UPDATE_TIME_FROM' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control date"
                   placeholder="{{'COMMON_SEARCH_UPDATE_TIME_FROM' | translate}}"
                   [(ngModel)]="searchModel.updateTimeFrom" ngbDatepicker #update_from="ngbDatepicker">
            <div class="input-group-addon-gray" (click)="update_from.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'COMMON_SEARCH_UPDATE_TIME_TO' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control date"
                   placeholder="{{'COMMON_SEARCH_UPDATE_TIME_TO' | translate}}"
                   [(ngModel)]="searchModel.updateTimeTo" ngbDatepicker #update_to="ngbDatepicker">
            <div class="input-group-addon-gray" (click)="update_to.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 d-flex justify-content-md-end align-items-end px-0">
        <div class="btn-group" role="group">
          <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
            {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
          </button>
          <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
            {{'COMMON_BUTTON_SEARCH' | translate}}
          </button>
        </div>
      </div>
    </div><!-- /.search card-->

    <div class="card-body">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="ProcessSearch.OrderFunctions.ID"
                                    [orderType]="queryModel.getOrderType(ProcessSearch.OrderFunctions.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="ProcessSearch.OrderFunctions.NAME"
                                    [orderType]="queryModel.getOrderType(ProcessSearch.OrderFunctions.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
            <app-table-field-sorter [orderField]="ProcessSearch.OrderFunctions.EXTERNAL_ID"
                                    [orderType]="queryModel.getOrderType(ProcessSearch.OrderFunctions.EXTERNAL_ID)"
                                    (orderChange)="orderBy($event)"
                                    class="table-text-subtitle"
                                    [text]="'COMMON_EXTERNAL_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-sorter-no-op [text]="'PROCESS_CREATE_WORKFLOW' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-field-sorter [orderField]="ProcessSearch.OrderFunctions.DEADLINE"
                                    [orderType]="queryModel.getOrderType(ProcessSearch.OrderFunctions.DEADLINE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_DEADLINE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-field-sorter [orderField]="ProcessSearch.OrderFunctions.CREATION_TIME"
                                    [orderType]="queryModel.getOrderType(ProcessSearch.OrderFunctions.CREATION_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_CREATION_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-field-sorter [orderField]="ProcessSearch.OrderFunctions.UPDATE_TIME"
                                    [orderType]="queryModel.getOrderType(ProcessSearch.OrderFunctions.UPDATE_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_UPDATE_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-sorter-no-op
              [text]="'COMMON_STATE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let process of processList | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }">
          <td class="fit align-middle">{{process.id}}</td>
          <td class="responsive-table-column long">
            <div class="d-flex justify-content-start align-items-center">
              <app-type-icon [icon]="process.icon" class="mr-05"></app-type-icon>
              <div>
                <a class="id-text cursor-pointer"
                   href="#" onclick="return false"
                   uiSref="Admin.ProcessDetail"
                   [uiParams]="{ id: process.id }"
                   title="{{'COMMON_BUTTON_DETAILS' | translate}}">
                  {{process.name}}
                </a>
                <div class="table-text-subtitle">
                  {{process.externalId}}
                </div>
              </div>
            </div>
          </td>
          <td class="responsive-table-column hidden-md-down align-middle">
            <a class="id-text cursor-pointer"
               href="#" onclick="return false"
               uiSref="Admin.WorkflowDetail"
               [uiParams]="{ id: process.workflow.id }"
               title="{{'COMMON_BUTTON_DETAILS' | translate}}">
              {{process.workflow.name}}
            </a>
          </td>
          <td class="fit hidden-lg-down align-middle">
              <span class="text-truncate" *ngIf="process.deadline">{{process.deadline.toUtcIsoString() |
                date:'shortDate'}}</span>
          </td>
          <td class="fit hidden-lg-down align-middle">
              <span class="text-truncate">{{process.creationTime.toUtcIsoString() |
                date:'short'}}</span>
          </td>
          <td class="fit hidden-lg-down">
            <div class="text-truncate">
              <span class="text-truncate">{{process.updateTime.toUtcIsoString() |
                date:'short'}}</span>
              <br>
              <span class="text-truncate table-text-subtitle mt-0">{{process.getLastModifierName()}}</span>
            </div>
          </td>
          <td class="fit hidden-xs-down align-middle">
            <app-table-badge
              *ngIf="process.stateObject"
              [badgeStyle]="process.stateObject.badgeStyle"
              [iconClass]="process.stateObject.iconClass"
              [text]="process.stateObject.stringKey | translate">
            </app-table-badge>
          </td>
          <td>
            <app-table-options-menu>
              <app-dropdown-item
                [iconClass]="'icomoon-detail'"
                [titleStringKey]="'COMMON_BUTTON_DETAILS'"
                class="cursor-pointer"
                uiSref="Admin.ProcessDetail"
                [uiParams]="{ id: process.id }">
              </app-dropdown-item>
            </app-table-options-menu>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="ProcessSearch.OrderFunctions.ID"
                                    [orderType]="queryModel.getOrderType(ProcessSearch.OrderFunctions.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="ProcessSearch.OrderFunctions.NAME"
                                    [orderType]="queryModel.getOrderType(ProcessSearch.OrderFunctions.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
            <app-table-field-sorter [orderField]="ProcessSearch.OrderFunctions.EXTERNAL_ID"
                                    [orderType]="queryModel.getOrderType(ProcessSearch.OrderFunctions.EXTERNAL_ID)"
                                    (orderChange)="orderBy($event)"
                                    class="table-text-subtitle"
                                    [text]="'COMMON_EXTERNAL_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-sorter-no-op [text]="'PROCESS_CREATE_WORKFLOW' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-field-sorter [orderField]="ProcessSearch.OrderFunctions.DEADLINE"
                                    [orderType]="queryModel.getOrderType(ProcessSearch.OrderFunctions.DEADLINE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_DEADLINE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-field-sorter [orderField]="ProcessSearch.OrderFunctions.CREATION_TIME"
                                    [orderType]="queryModel.getOrderType(ProcessSearch.OrderFunctions.CREATION_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_CREATION_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-field-sorter [orderField]="ProcessSearch.OrderFunctions.UPDATE_TIME"
                                    [orderType]="queryModel.getOrderType(ProcessSearch.OrderFunctions.UPDATE_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_UPDATE_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-sorter-no-op
              [text]="'COMMON_STATE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>

  </div>

</div>

