/* eslint-disable */
import { Component } from '@angular/core';
import {
  FormRecordFieldContext,
  FormRecordFieldValueUpdateArgs,
  FormRecordFieldView,
  FormRecordFieldViewContext,
  FormRecordFieldViewModel,
  SetTmpReadonlyArgs,
  SetTmpReadonlyResult,
} from '../../../../../util/form/form-utils';
import { Form } from '../../../../../lib/form/form.service';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormRef, ForwardingFormRef, LocalFormGroupValidationErrors } from '../../../../../lib/util/services';
import { FormRecord } from '../../../../../lib/form/form-record.service';
import { AppValidators } from '../../../../../util/app-validators';
import { Models } from '../../../../../util/model-utils';
import { InputMask } from '../../../../../util/input-masks';
import { FieldActivationState, FieldActivationStateResolver } from '../../../../../util/form/form-editors';
import { Command } from '../../../../../util/command';
import { FormRecordInactivityManager } from '../../manager/form-record-inactivity-manager';
import { LoggerFactory } from '../../../../../util/logger-factory';
import { UiConstants } from '../../../../../util/core-utils';
import { Observable } from 'rxjs';

/* eslint-enable */

@Component({
  selector: 'app-form-record-decimal-field',
  templateUrl: 'form-record-decimal-field.component.html',
  styleUrls: ['form-record-decimal-field.component.scss'],
})
export class FormRecordDecimalFieldComponent implements FormRecordFieldView {

  UiConstants = UiConstants

  private readonly logger = LoggerFactory.createLogger('FormRecordDecimalFieldComponent');

  public readonly selector: Form.FieldDataTypeSelector.DECIMAL;

  InputMask = InputMask;

  formGroup: FormGroup;

  minValue?: number;
  maxValue?: number;

  thousandSeparatorEnabled?: boolean = true;

  model: Model = new Model();

  // context fields are always optional
  formRecordFieldContext?: FormRecordFieldContext;
  formRecordInactivityManager?: FormRecordInactivityManager;
  private fieldId?: number;
  private htmlForm?: FormRef;

  tmpReadonly: boolean = false;

  private optionalValue: boolean = false;

  private formGroupValidationErrors: LocalFormGroupValidationErrors;

  private readonlyFormFn: () => boolean = () => true;
  private readonlyFieldFn: () => boolean = () => false;
  private hiddenFieldFn: () => boolean = () => false;
get nonEditable(): boolean {
    return FieldActivationStateResolver.isNonEditable(
      this.fieldActivationState
    );
  }

  get requiredDisabled(): boolean {
    return FieldActivationStateResolver.isRequiredDisabled(
      this.fieldActivationState
    );
  }

  get required(): boolean {
    const optional = this.optionalValue;
    const requiredDisabled = this.requiredDisabled;
    return !optional && !requiredDisabled;
  }

  private get fieldActivationState(): FieldActivationState {
    return FieldActivationStateResolver.resolveFieldActivationState({
      readonlyFormFn: () => this.readonlyFormFn(),
      readonlyFieldFn: () => this.readonlyFieldFn(),
      tmpReadonlyFieldFn: () => this.tmpReadonly,
    });
  }

  private get canApplyDefaultValue(): boolean {
    return FieldActivationStateResolver.canApplyDefaultValue({
      formRecordFieldContext: () => {
        return this.formRecordFieldContext;
      },
      fieldActivationState: () => {
        return this.fieldActivationState;
      },
    });
  }

  private get valueFormControl(): AbstractControl {
    return this.formGroup.get('value')!;
  }

  private get reqContext() {
    return this.formRecordFieldContext!;
  }

  private get reqAttrs(): Form.FieldDataTypeDecimalAttributes {
    return this.formRecordFieldContext!.field.dataType.decimalAttributes!;
  }

  private get reqDefaultValue(): string {
    return Models.decimalToString(this.reqAttrs.defaultValue);
  }

  constructor(fb: FormBuilder) {
    this.formGroup = this.createFormGroup(fb);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(
      this.createForwardingHtmlForm(),
      this.formGroup
    );
  }

  setTmpReadonly(args: SetTmpReadonlyArgs): Command<SetTmpReadonlyResult> {
    const previousTmpReadonly = this.tmpReadonly;
    const previousValue = this.model.value;
    return {
      execute: async () => {
        const inactiveValue = '';
        const defaultValue = this.reqDefaultValue;
        let changed = false;
        if (this.tmpReadonly !== args.tmpReadonly) {
          if (args.tmpReadonly) {
            changed = FieldActivationStateResolver.inactivationChangesTheValue({
              debugId: this.reqContext.field.title,
              valueIsEmpty: this.model.value === inactiveValue,
              valueEqualsDefaultValue: this.model.value === defaultValue,
              defaultValueIsEmpty: defaultValue === inactiveValue,
              canApplyDefaultValue: this.canApplyDefaultValue
            });
            this.model.value = inactiveValue;
            this.tmpReadonly = args.tmpReadonly; // last
          }
          else {
            this.tmpReadonly = args.tmpReadonly; // first
            this.applyDefaultValue();
          }
          this.valueFormControl.updateValueAndValidity();
        }
        return {
          changed: changed
        };
      },
      undo: async () => {
        this.tmpReadonly = previousTmpReadonly;
        this.model.value = previousValue;
        this.valueFormControl.updateValueAndValidity();
      }
    };
  }

  registerField(context: FormRecordFieldContext, originalModel?: any): any {
    const readonlyMode = context.readonly();
    this.logger.debug('registerField', 'readonlyMode: ' + readonlyMode);
    if (originalModel) {
      this.model = originalModel;
    }
    this.formRecordFieldContext = context;
    this.fieldId = context.field.fieldId;
    this.htmlForm = context.htmlForm;
    this.readonlyFormFn = context.readonly;
    this.hiddenFieldFn = () => Form.FormFieldValidationType.HIDDEN === context.validationType;
    this.readonlyFieldFn = () => Form.FormFieldValidationType.READONLY === context.validationType
    || Form.FormFieldValidationType.HIDDEN === context.validationType;
    const attrs = context.field.dataType.decimalAttributes!;
    this.optionalValue = Form.FormFieldValidationType.REQUIRED !== context.validationType;
    this.model.title = context.field.title;
    this.model.hint = Models.optToString(context.field.hint);
    this.model.placeholder = Models.optToString(attrs.hint);
    this.thousandSeparatorEnabled = attrs.thousandSeparatorEnabled;
    this.minValue = Models.decimalToNumber(attrs.minValue);
    this.maxValue = Models.decimalToNumber(attrs.maxValue);
    this.applyDefaultValue();
    if (context.fieldRecord) {
      this.registerFieldData(context.fieldRecord);
    }
    return this.model;
  }

  private applyDefaultValue() {
    if (this.canApplyDefaultValue) {
      this.model.value = this.reqDefaultValue;
    }
  }

  private registerFieldData(fieldRecord: FormRecord.FieldComposed): void {
    const attrs = fieldRecord.data.decimalAttributes!;
    if (attrs.value !== undefined) {
      if (this.readonlyFieldFn() && this.reqContext.cloning()) {
        // Do not use read-only data for cloning.
        // Note that readonly field is filtered from the service request.
        this.model.value = '';
      }
      else {
        this.model.value = Models.decimalToString(attrs.value);
      }
    }
    else {
      this.model.value = '';
    }
  }

  registerFieldViews(context: FormRecordFieldViewContext): void {
    this.formRecordInactivityManager = context.inactivityManager;
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  validateWithInterrupt(): boolean {
    return false;
  }

  shouldNotifyAfterCreation(): boolean {
    return false;
  }

  afterFormRecordCreation(formRecordId: number): Observable<any> | undefined {
    return undefined;
  }

  createModel(): FormRecordFieldViewModel {
    if (this.fieldId === undefined) {
      throw new Error('Field ID is undefined');
    }
    const attrs: FormRecord.FieldDataDecimalAttributes = {
      value: Models.parseDecimal(this.model.value)
    };
    return {
      fieldEditRequest: {
        fieldId: this.fieldId,
        data: {
          decimalAttributes: attrs
        }
      }
    };
  }

  onInput() {
    this.formRecordInactivityManager!.onGeneralFieldChangedByUser(this);
  }

  private createForwardingHtmlForm() {
    return new ForwardingFormRef({
      formFn: () => {
        return this.htmlForm;
      }
    });
  }

  private createFormGroup(fb: FormBuilder) {
    return fb.group(
      {
        value: fb.control(
          this.model.value,
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => {
                return !this.required;
              }
            }),
            AppValidators.optMin(() => {
              return this.minValue;
            }),
            AppValidators.optMax(() => {
              return this.maxValue;
            })
          ]
        )
      }
    );
  }

  getTextMaskConfiguration(): any {
    if (this.thousandSeparatorEnabled) {
      return {mask: InputMask.RELATIVE_FLOAT_WITH_THOUSAND_SEPARATOR, guide: true};
    }
    return {mask: InputMask.RELATIVE_FLOAT, guide: false};
  }

  updateValue(data: FormRecordFieldValueUpdateArgs) {
  }

}

export class Model {
  title: string = '';
  hint: string = '';
  placeholder: string = '';
  value: string = '';

  /*
   * Used to return correct decimal value for validation
   */
  get _value(): string {
    return this.value;
  }

  set _value(value: string) {
    const v = Models.parseDecimal(value);
    this.value = v !== undefined ? v + '' : '';
  }
}
