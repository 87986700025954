/* eslint-disable */
import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {Transition, UIRouter} from '@uirouter/angular';
import {FieldError, FieldErrors, ObservableErrorResourceParser} from '../../../lib/util/errors';
import {StateName} from '../../../app.state-names';
import {InputMask} from '../../../util/input-masks';
import {
  Company,
  StockItem,
  StockItemCustomerRecordPrice,
  StockItemFieldErrorMap,
  StockItemService,
  StockItemType,
  stockItemTypes,
  StockItemTypeUrlMapper
} from '../../../lib/stock/stock-item.service';
import {StockItemEditModel} from '../../../util/stock/stock-item-utils';
import {Models} from '../../../util/model-utils';
import {BreadcrumbParent} from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import {TranslateService} from '@ngx-translate/core';
import {Currency, CurrencyService} from '../../../lib/currency.service';
import {QueryResult} from '../../../lib/util/services';
import {MultiselectOptionItem, OptionItem, SelectUtils, UiConstants} from '../../../util/core-utils';
import {Angular2Multiselects} from '../../../util/multiselect';
import {VatRate, VatRateService} from '../../../lib/vat-rate.service';
import {combineLatest} from 'rxjs';
import {CompanyMultiselectProvider} from '../../../lib/company/company-multiselect.provider';
import {RightModel} from '../../../app.rights';
import {RightResolver, RightService} from '../../../lib/right.service';
import {CustomerRecordService} from '../../../lib/customer/customer-record.service';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {NgForm} from '@angular/forms';
import {
  StockItemCustomerRecordPriceListComponent
} from '../stock-item-customer-record-price-list/stock-item-customer-record-price-list.component';
import {DomSanitizer, SafeStyle} from '@angular/platform-browser';
import {DownloadedFile} from '../../../lib/util/downloaded-files';
import {EmptyMessage} from '../../../lib/util/messages';
import {StringKey} from '../../../app.string-keys';
import {ToasterService} from '../../../fork/angular2-toaster/src/toaster.service';
import {saveAs} from 'file-saver';
import {
  StockItemCategoryMultiselectProvider
} from '../../../lib/stock-item-category/stock-item-category-multiselect.provider';
import {LedgerNumberMultiselectProvider} from '../../../lib/ledger/number/ledger-number-multiselect.provider';
import {StockItemUnitOfMeasure} from '../../../lib/stock/stock-item-unit-of-measure';
import {
  StockItemUnitOfMeasureMultiselectOptionItem,
  StockItemUnitOfMeasureMultiselectProvider
} from '../../../lib/stock/stock-item-unit-of-measure-multiselect.provider';
import {
  CustomerRecordMultiselectItem,
  CustomerRecordMultiselectProvider
} from "../../../lib/customer/customer-record-multiselect-provider.service";
import {WeightFactory} from "../../../util/weight-utils";
import {ConfigurationResource, ConfigurationService} from "../../../lib/core-ext/configuration.service";
import ZeroVatRateType = VatRate.ZeroVatRateType;

/* eslint-enable */

@Component({
  selector: 'app-stock-item-edit',
  templateUrl: 'stock-item-edit.component.html',
  styleUrls: ['stock-item-edit.component.scss']
})
export class StockItemEditComponent implements AfterViewInit {

  Currency = Currency;
  UiConstants = UiConstants;
  VatRate = VatRate;

  @ViewChild('customerRecordPriceListComponent', {static: true})
  customerRecordPriceListComponent: StockItemCustomerRecordPriceListComponent;

  @ViewChild('customerRecordPriceDialog', {static: true})
  customerRecordPriceDialog: ModalDirective;

  @ViewChild('customerRecordPriceForm', {static: true})
  customerRecordPriceForm?: NgForm;

  InputMask = InputMask;
  model: StockItemEditModel;
  fieldErrors: StockItemFieldErrorMap;
  customerType: string;
  stockItemType?: StockItemType;
  stockItemId: number;
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  selectableZeroVatRateTypes: OptionItem<VatRate.ZeroVatRateType>[] = [];

  stockItemTypes: OptionItem<StockItemType>[] = [];
  dropdownSettingsForCompanies: Angular2Multiselects.Settings;
  companies: MultiselectOptionItem<number>[] = [];

  measurements: StockItemUnitOfMeasureMultiselectOptionItem[] = [];
  dropdownSettingsForProductCategory: Angular2Multiselects.Settings;
  dropdownSettingsForPackage: Angular2Multiselects.Settings;
  stockItemCategories: MultiselectOptionItem<number>[] = [];
  dropdownSettingsForCreateEdit: Angular2Multiselects.Settings;
  customerRecords: MultiselectOptionItem<number>[] = [];
  customerRecordPriceModel: CustomerRecordPriceModel = new CustomerRecordPriceModel();
  ledgerNumbers: MultiselectOptionItem<number>[] = [];

  selectableCurrencies: Currency.Currency[] = [];
  selectableVatRates: VatRate.VatRate[] = [];
  selectableWeightUnits: string[] = [];

  rightModel: RightModel = RightModel.empty();

  pictureUploadPath: string = '';
  pictureLoaded = false;
  pictureSrc: SafeStyle;

  HTMLEditorModules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{'header': [1, 2, 3, 4, 5, 6, false]}],
      ['link', 'image']
    ]
  };

  private readonly config: ConfigurationResource.Configuration;

  get categoryRequired(): boolean {
    if (!this.config) {
      return false;
    }
    return this.config.feature_flags.stock_item_category_required;
  }

  constructor(
    private stockItemService: StockItemService,
    private uiRouter: UIRouter,
    private companyMultiselectProvider: CompanyMultiselectProvider,
    private translateService: TranslateService,
    private currencyService: CurrencyService,
    private transition: Transition,
    private sanitizer: DomSanitizer,
    private toasterService: ToasterService,
    private customerRecordService: CustomerRecordService,
    private customerRecordMultiselectProvider: CustomerRecordMultiselectProvider,
    private rightService: RightService,
    private vatRateService: VatRateService,
    private stockItemCategoryMultiselectProvider: StockItemCategoryMultiselectProvider,
    private ledgerNumberMultiselectProvider: LedgerNumberMultiselectProvider,
    private configurationService: ConfigurationService,
    private unitOfMeasureMultiselectProvider: StockItemUnitOfMeasureMultiselectProvider) {
    this.model = new StockItemEditModel();
    this.fieldErrors = {};
    this.stockItemId = this.transition.params().id;
    this.stockItemType = StockItemTypeUrlMapper.fromUrl(this.transition.params().stockItemType);
    this.pictureUploadPath = '/stock-items/' + this.stockItemId + '/picture';
    this.config = this.configurationService.getConfiguration();
  }

  ngAfterViewInit() {
    this.initDropdownSettings();
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('MENU_NAVBAR_PRODUCTS').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.STOCK_ITEM_LIST});
      }
    );
    this.loadZeroVatRateTypes();
    this.loadData();
    this.loadStockItemTypes();
    this.selectableWeightUnits = WeightFactory.getOptions();
    this.loadRightModels();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  private loadStockItemTypes() {
    this.stockItemTypes = [];
    stockItemTypes.forEach(t => {
      const item: OptionItem<StockItemType> = {
        id: t.type,
        text: '....'
      };
      this.stockItemTypes.push(item);
      this.translateService.get(t.stringKey).subscribe((text: string) => {
        item.text = text;
      });
    });
  }

  loadData() {
    combineLatest(
      this.currencyService.query({}),
      this.vatRateService.query(),
      this.companyMultiselectProvider.searchCompanies(),
      this.stockItemCategoryMultiselectProvider.loadActive(),
      this.stockItemService.get({
        id: this.stockItemId
      }),
      (
        currency: QueryResult<Currency.Currency>,
        vatRate: QueryResult<VatRate.VatRate>,
        companies: MultiselectOptionItem<number>[],
        categories: MultiselectOptionItem<number>[],
        stockItem: StockItem
      ) => {
        return {
          currency: currency,
          vatRate: vatRate,
          companies: companies,
          categories: categories,
          stockItem: stockItem
        }
      }
    ).subscribe((result: CombinedResult) => {
      this.selectableVatRates = result.vatRate.items.toArray();
      this.selectableCurrencies = result.currency.items.toArray();
      this.companies = result.companies;
      this.stockItemCategories = result.categories;
      this.loadModel(result.stockItem);
    });
  }

  loadZeroVatRateTypes() {
    this.selectableZeroVatRateTypes = [];
    VatRate.zeroVatRateTypes.forEach(type => {
      const item = new OptionItem<ZeroVatRateType>();
      item.id = type.type;
      item.disabled = type.disabled;
      this.translateService.get(type.stringKey).subscribe((text) => {
        item.text = text;
      });
      this.selectableZeroVatRateTypes.push(item);
    });
  }

  getStockItemTypeText(type: StockItemType): string {
    const item = this.stockItemTypes.find(t => t.id === type);
    return item ? item.text : '';
  }

  initDropdownSettings() {
    this.dropdownSettingsForCompanies = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
    this.dropdownSettingsForProductCategory = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
    this.dropdownSettingsForPackage = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
    this.dropdownSettingsForCreateEdit = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
  }

  private loadModel(stockItem: StockItem) {
    this.breadcrumbSelf = stockItem.name;
    this.model.name = stockItem.name;
    this.model.type = stockItem.type;
    this.model.unit = stockItem.unit;
    this.model.externalId = stockItem.external_id;
    this.model.productCode = stockItem.product_code;
    this.loadSelectedCompany(stockItem.company);
    this.model.description = stockItem.description;
    this.model.vtszNumber = stockItem.hun_vtsz_number;
    this.model.hasPicture = !!stockItem.picture;
    if (this.model.hasPicture) {
      this.downloadPictureForPreview();
    }
    this.model.usableForFormField = stockItem.usable_for_form_field;
    this.model.productCategory = stockItem.product_category;
    this.loadSelectedStockItemCategories(stockItem.category_ids)
    this.loadSelectedLedgerNumber(stockItem.ledger_number?.id)
    if (stockItem.weight_in_grams) {
      const weight = WeightFactory.createWeightFromGram(stockItem.weight_in_grams);
      this.model.weightValue = '' + weight.value;
      this.model.weightUnit = weight.unit;
    }
    this.model.place = stockItem.place;
    this.model.serialCode = stockItem.serial_code;
    if (stockItem.unit_price !== null) {
      this.currencyService.query({currencyCode: stockItem.unit_price.currency_code})
        .subscribe((result: QueryResult<Currency.Currency>) => {
          if (result.items.size === 1) {
            this.model.unitPrice = {
              value: Models.numberToString(stockItem.unit_price.value),
              vatRate: stockItem.unit_price.vat_rate,
              currency: result.items.get(0),
              zeroVatRateType: stockItem.unit_price.zero_vat_rate_type ?
                stockItem.unit_price.zero_vat_rate_type : this.selectableZeroVatRateTypes[0].text
            }
          } else {
            this.model.unitPrice = {
              value: Models.numberToString(stockItem.unit_price.value),
              vatRate: stockItem.unit_price.vat_rate,
              currency: this.selectableCurrencies[0],
              zeroVatRateType: stockItem.unit_price.zero_vat_rate_type ?
                stockItem.unit_price.zero_vat_rate_type : this.selectableZeroVatRateTypes[0].text
            }
          }
        })
    }
    this.model.packageEnabled = stockItem.package_data !== undefined;
    if (stockItem.package_data) {
      this.loadPackageData(stockItem.package_data);
    }

    if (stockItem.creator_user) {
      this.model.creatorUserString = `${stockItem.creator_user.person_name} (${stockItem.creator_user.user_name})`
    }

    this.model.createdOnMobile = stockItem.created_on === 'MOBILE';
  }

  private loadSelectedCompany(company?: Company) {
    if (company) {
      this.companyMultiselectProvider.getById(company.id).subscribe((result) => {
        this.model.company.push(result);
      })
    }
  }

  searchCompanies(q?: string) {
    this.companyMultiselectProvider.searchCompanies(q)
      .subscribe((result: MultiselectOptionItem<number>[]) => {
        this.companies = result;
      });
  }

  loadLedgerNumbers(predicate?: string) {
    if (this.rightModel.ledgerNumberRead.hasRight()) {
      this.ledgerNumberMultiselectProvider.loadActive(predicate).subscribe((ledgerNumbers) => {
        this.ledgerNumbers = ledgerNumbers;
      });
    }
  };

  loadSelectedLedgerNumber(id?: number) {
    this.model._ledgerNumber = [];
    if (id) {
      this.ledgerNumberMultiselectProvider.getById(id).subscribe(ledgerNumber => {
        this.model._ledgerNumber.push(ledgerNumber);
      })
    }
  }

  loadStockItemCategories(predicate?: string) {
    this.stockItemCategoryMultiselectProvider.loadActive(predicate).subscribe((stockItemCategories) => {
      this.stockItemCategories = stockItemCategories;
    });
  }

  loadSelectedStockItemCategories(ids: number[]) {
    if (ids.length > 0) {
      this.stockItemCategoryMultiselectProvider.getByIds(ids).subscribe((stockItemCategories) => {
        this.model.stockItemCategories = stockItemCategories;
      });
    } else {
      this.model.stockItemCategories = [];
    }
  }

  update() {
    const unitPriceValue: number = Models.decimalToNumber(Models.parseDecimal(this.model.unitPrice.value))!;
    this.stockItemService.update({
      id: this.stockItemId,
      usable_for_form_field: this.model.usableForFormField,
      external_id: this.model.externalId,
      product_code: this.model.productCode,
      company_id: this.model.companyId,
      ledger_number: this.model.ledgerNumberId,
      name: this.model.name,
      description: this.model.description,
      hun_vtsz_number: this.model.vtszNumber,
      unit: this.model.unit,
      unit_price: {
        value: unitPriceValue,
        vat_rate: this.model.unitPrice.vatRate,
        currency_code: this.model.unitPrice.currency.currencyCode,
        zero_vat_rate_type: this.model.unitPrice.vatRate === 0 ? this.model.unitPrice.zeroVatRateType : undefined
      },
      product_category: this.model.productCategory,
      category_ids: this.model.stockItemCategories.map(c => c.id),
      weight_in_grams: this.model.weightInGrams,
      place: this.model.place,
      serial_code: this.model.serialCode,
      default_package_measurement_id: this.model.defaultPackageMeasurementId
    }).subscribe(
      (response) => {
        this.uiRouter.stateService.go(StateName.STOCK_ITEM_LIST, response);
      },
      (error: any) => {
        const res = ObservableErrorResourceParser.parseError(error);
        this.fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
      }
    );
  }

  removeFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.fieldErrors, fieldError);
  }

  back() {
    window.history.back();
  }

  getTextMaximumLength(): number {
    return UiConstants.maximumVarcharLength;
  }

  loadCustomerRecord(id?: number) {
    this.customerRecordPriceModel.customerRecord = [];
    if (id) {
      this.customerRecordMultiselectProvider.getById(id).subscribe(result => {
        this.customerRecordPriceModel.customerRecord.push(result);
      })
    }
  }

  onCustomerRecordSearch(q?: string) {
    this.customerRecordMultiselectProvider.loadActiveNonContact(q)
      .subscribe((result: CustomerRecordMultiselectItem[]) => {
        this.customerRecords = result;
      });
  }

  showCustomerRecordPriceDialog(price?: StockItemCustomerRecordPrice) {
    if (price) {
      this.customerRecordPriceModel.mode = CustomerRecordPriceDialogMode.EDIT;
      this.customerRecordPriceModel.id = price.id;
      this.customerRecordPriceModel.price = price.custom_unit_price;
      this.loadCustomerRecord(price.customer_record.id);
    }
    this.customerRecordPriceDialog.show();
  }

  closeCustomerRecordPriceDialog() {
    this.customerRecordPriceDialog.hide();
    if (this.customerRecordPriceForm) {
      this.customerRecordPriceForm.resetForm();
    }
    this.customerRecordPriceModel.reset();
  }

  saveCustomerRecordPrice() {
    if (this.customerRecordPriceModel.mode === CustomerRecordPriceDialogMode.CREATE) {
      this.stockItemService.createCustomerRecordPrice({
        stock_item_id: this.stockItemId,
        custom_unit_price: this.customerRecordPriceModel.price,
        customer_record_id: this.customerRecordPriceModel.customerRecordId!
      }).subscribe(() => {
          this.closeCustomerRecordPriceDialog();
          this.customerRecordPriceListComponent.loadList(1);
        },
        error => {
          const res = ObservableErrorResourceParser.parseError(error);
          const fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
          if (fieldErrors['customer_record_id']) {
            this.toasterService.pop({
              timeout: UiConstants.ToastTimeoutLong,
              type: UiConstants.toastTypeError,
              title: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_TITLE),
              body: fieldErrors['customer_record_id'].text
            });
          }
        });
    } else {
      this.stockItemService.updateCustomerRecordPrice({
        stock_item_id: this.stockItemId,
        id: this.customerRecordPriceModel.id!,
        custom_unit_price: this.customerRecordPriceModel.price,
        customer_record_id: this.customerRecordPriceModel.customerRecordId!
      }).subscribe(() => {
          this.closeCustomerRecordPriceDialog();
          this.customerRecordPriceListComponent.loadList(1);
        },
        error => {
          const res = ObservableErrorResourceParser.parseError(error);
          const fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
          if (fieldErrors['customer_record_id']) {
            this.toasterService.pop({
              timeout: UiConstants.ToastTimeoutLong,
              type: UiConstants.toastTypeError,
              title: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_TITLE),
              body: fieldErrors['customer_record_id'].text
            });
          }
        });
    }
  }

  getCustomerRecordPriceDialogHeading(): string {
    return this.customerRecordPriceModel.mode === CustomerRecordPriceDialogMode.CREATE
      ? 'STOCK_ITEM_CUSTOMER_RECORD_PRICE_HEADING_CREATE'
      : 'STOCK_ITEM_CUSTOMER_RECORD_PRICE_HEADING_EDIT';
  }

  loadMeasurements(q?: string) {
    this.unitOfMeasureMultiselectProvider.loadActive(this.stockItemId, q).subscribe(result => {
      this.measurements = result;
    });
  }

  onPictureUpload(success: boolean) {
    if (success) {
      this.model.hasPicture = true;
      this.downloadPictureForPreview();
    }
  }

  private downloadPictureForPreview() {
    this.pictureLoaded = false;
    this.stockItemService.downloadPicture({
      id: this.stockItemId,
    }).subscribe((res: DownloadedFile) => {
        this.pictureSrc = this.sanitizer.bypassSecurityTrustStyle(`url(${URL.createObjectURL(res.getBlob())})`);
        this.pictureLoaded = true;
      }
    );
  }

  deletePicture() {
    if (this.model.hasPicture) {
      this.stockItemService.deletePicture({
        id: this.stockItemId
      }).subscribe(
        (result: EmptyMessage) => {
          this.toasterService.pop({
            timeout: UiConstants.ToastTimeoutShort,
            type: UiConstants.toastTypeSuccess,
            title: this.translateService.instant(StringKey.STOCK_ITEM_PICTURE),
            body: this.translateService.instant(StringKey.STOCK_ITEM_PICTURE_DELETE_MESSAGE)
          });
          this.model.hasPicture = false;
        },
        (error: Error) => {
          this.toasterService.pop({
            timeout: UiConstants.ToastTimeoutLong,
            type: UiConstants.toastTypeError,
            title: this.translateService.instant(StringKey.STOCK_ITEM_PICTURE),
            body: error.message
          });
        });
    }
  }

  downloadPicture() {
    this.stockItemService.downloadPicture({
      id: this.stockItemId
    }).subscribe((res: DownloadedFile) => {
      saveAs(res.getBlob(), res.getFileName(this.model.name + '.png'));
    });
  }

  private loadPackageData(packageData: StockItemUnitOfMeasure.StockItemUnitOfMeasure) {
    this.model.defaultPackageMeasurement = [this.unitOfMeasureMultiselectProvider.toMultiselectOptionItem(packageData)];
  }

  protected readonly SelectUtils = SelectUtils;
}

interface CombinedResult {
  currency: QueryResult<Currency.Currency>;
  vatRate: QueryResult<VatRate.VatRate>;
  companies: MultiselectOptionItem<number>[];
  categories: MultiselectOptionItem<number>[];
  stockItem: StockItem;
}

class CustomerRecordPriceModel {
  mode: CustomerRecordPriceDialogMode = CustomerRecordPriceDialogMode.CREATE;
  id?: number;
  price: string = '';
  customerRecord: MultiselectOptionItem<number>[] = [];

  get customerRecordId(): number | undefined {
    return this.customerRecord.length === 1 ? this.customerRecord[0].id : undefined;
  }

  reset() {
    this.mode = CustomerRecordPriceDialogMode.CREATE;
    this.id = undefined;
    this.price = '';
    this.customerRecord = [];
  }
}

enum CustomerRecordPriceDialogMode {
  CREATE,
  EDIT
}
