import { Observable, Observer } from 'rxjs';
import { FieldValidationError } from '../util/services';
import { EmptyMessage, IdentityMessage } from '../util/messages';
import { Map } from 'immutable';
import { Injectable } from '@angular/core';
import { ObservableErrorResourceParser } from '../util/errors';
import {
  EmailAddressTypeResource,
  EmailAddressTypeResourceService
} from './email-address-type-resource.service';
import { MultiselectOptionItem, UiConstants } from '../../util/core-utils';
import { Cacheable } from 'ts-cacheable';

export namespace EmailAddressType {

  export interface Service {
    getList(): Observable<EmailAddressType[]>;
    get(request: IdentityMessage): Observable<EmailAddressType>;
    create(request: CreateRequest): Observable<IdentityMessage>;
    update(request: UpdateRequest): Observable<EmptyMessage>;
    setDisabled(request: DisableRequest): Observable<EmptyMessage>;
  }

  export interface EmailAddressType {
    id: number;
    externalId: string;
    disabled: boolean;
    names: {
      en: string;
      hu: string;
    };
  }

  export interface CreateRequest {
    externalId: string;
    names: {
      en: string;
      hu: string;
    };
  }

  export interface UpdateRequest extends CreateRequest {
    id: number;
  }

  export interface DisableRequest {
    id: number;
    disabled: boolean;
  }

  export function toResourceCreateRequest(request: EmailAddressType.CreateRequest): EmailAddressTypeResource.CreateRequest {
    return {
      external_id: request.externalId,
      names: {
        en: request.names.en,
        hu: request.names.hu
      }
    };
  }

  export function toResourceUpdateRequest(request: EmailAddressType.UpdateRequest): EmailAddressTypeResource.UpdateRequest {
    return {
      id: request.id,
      external_id: request.externalId,
      names: {
        en: request.names.en,
        hu: request.names.hu
      }
    };
  }

  export function toResourceDisableRequest(request: EmailAddressType.DisableRequest): EmailAddressTypeResource.DisableRequest {
    return {
      id: request.id,
      disabled: request.disabled
    }
  }

  export function toPublic(r: EmailAddressTypeResource.EmailAddressType): EmailAddressType {
    return {
      id: r.id,
      externalId: r.external_id,
      disabled: r.disabled,
      names: {
        en: r.names['en'],
        hu: r.names['hu']
      }
    }
  }

  export enum ValidatedField {
    UNKNOWN,
    NAMES,
    EXTERNAL_ID
  }

}

class Keys {

  private static readonly NAMES = 'names';
  private static readonly EXTERNAL_ID = 'external_id';

  private static readonly keyValidatedFieldMap: Map<string, EmailAddressType.ValidatedField> = Map.of(
    Keys.NAMES, EmailAddressType.ValidatedField.NAMES,
    Keys.EXTERNAL_ID, EmailAddressType.ValidatedField.EXTERNAL_ID,
  );

  public static toValidatedField(fieldKey: string): EmailAddressType.ValidatedField {
    return Keys.keyValidatedFieldMap.get(fieldKey, EmailAddressType.ValidatedField.UNKNOWN);
  }

}

@Injectable()
export class EmailAddressTypeService implements EmailAddressType.Service {

  constructor(private resourceService: EmailAddressTypeResourceService) {
  }

  getList(): Observable<EmailAddressType.EmailAddressType[]> {
    return Observable.create((observer: Observer<EmailAddressType.EmailAddressType[]>) => {
      return this.resourceService.getList().subscribe(
        (result: EmailAddressTypeResource.EmailAddressType[]) => {
          observer.next(result.map((item) => EmailAddressType.toPublic(item)));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  @Cacheable({
    maxAge: UiConstants.defaultCacheMaxAge,
    slidingExpiration: true
  })
  getLocalizedList(locale: string): Observable<MultiselectOptionItem<number>[]> {
    return Observable.create((observer: Observer<MultiselectOptionItem<number>[]>) => {
      return this.getList().subscribe((result: EmailAddressType.EmailAddressType[]) => {
        observer.next(result.filter(t => !t.disabled).map(t => ({
          id: t.id,
          itemName: t.names[locale]
        })));
      });
    });
  }

  get(request: IdentityMessage): Observable<EmailAddressType.EmailAddressType> {
    return Observable.create((observer: Observer<EmailAddressType.EmailAddressType>) => {
      return this.resourceService.get(request).subscribe(
        (result: EmailAddressTypeResource.EmailAddressType) => {
          observer.next(EmailAddressType.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  create(request: EmailAddressType.CreateRequest): Observable<IdentityMessage> {
    return Observable.create((observer: Observer<IdentityMessage>) => {
      const resourceRequest: EmailAddressTypeResource.CreateRequest = EmailAddressType.toResourceCreateRequest(request);
      return this.resourceService.create(resourceRequest).subscribe(
        (result: IdentityMessage) => {
          observer.next(result);
        },
        (error: any) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  update(request: EmailAddressType.UpdateRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: EmailAddressTypeResource.UpdateRequest = EmailAddressType.toResourceUpdateRequest(request);
      return this.resourceService.update(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  setDisabled(request: EmailAddressType.DisableRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: EmailAddressTypeResource.DisableRequest = EmailAddressType.toResourceDisableRequest(request);
      return this.resourceService.setDisabled(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  private translateError(error: any): any {
    const res = ObservableErrorResourceParser.parseError(error);
    const fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
    const fieldErrorMap = ObservableErrorResourceParser.toFieldErrorMap(Keys.toValidatedField, fieldErrors);
    if (!fieldErrorMap.isEmpty()) {
      return FieldValidationError.of(fieldErrorMap);
    }
    return error;
  }

}
