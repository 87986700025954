import {
  StockItemSelectorDialogComponent,
  StockItemSelectorDialogData,
  StockItemSelectorModel
} from '../../admin/stock-item/stock-item-selector-dialog/stock-item-selector-dialog.component';
import { MultiselectOptionItem } from '../core-utils';
import { MatDialog } from '@angular/material/dialog';
import { StockTypeName } from './stock-utils';
import {
  ExcludedStockIdCollection,
  MovementId
} from '../../admin/form/form-record/fields/stock-move/form-record-stock-move-field.component';
import { StockSelectorDialogComponent } from '../../admin/stock/stock-selector-dialog/stock-selector-dialog.component';

export namespace StockMoveSelectorUtils {
  export function startMoveSelector(args: StockMoveSelectorArgs) {
    if (args.movementId) {
      const stockItemData: StockItemSelectorDialogData = {
        usableCategoryIds: args.usableCategoryIds,
        amountLabel: 'FORM_RECORD_STOCK_MOVE_AMOUNT',
        excludedIds: args.excludedStockItemIds,
        stockItemType: 'STOCK',
        loadRecordsOfStock: args.movementId.sourceStockId
      };
      StockItemSelectorDialogComponent.openSelector(args.dialog, stockItemData, stockItemResult => {
        if (stockItemResult) {
          args.resultCallback({
            selectedStockItemIds: stockItemResult.selectedStockItemIds,
            selectedStockItems: stockItemResult.selectedStockItems,
            movementId: args.movementId
          });
        }
      });
    } else {
      StockSelectorDialogComponent.openSelector(args.dialog, {
          excludedIds: [],
          forceStockIds: args.forceSourceStockIds,
          typeName: args.sourceStockTypeName,
          ownerCustomerRecordId: args.sourceStockTypeName === 'CUSTOMER' ? args.ownerCustomerRecordId : undefined,
          ownerContactLocationId: args.sourceStockTypeName === 'CUSTOMER' ? args.ownerContactLocationId : undefined,
          titleKey: 'STOCK_MOVE_SOURCE_STOCK_SELECTOR_DIALOG_TITLE'
        },
        (sourceStockResult) => {
          if (sourceStockResult) {
            const stockItemData: StockItemSelectorDialogData = {
              usableCategoryIds: args.usableCategoryIds,
              amountLabel: 'FORM_RECORD_STOCK_MOVE_AMOUNT',
              excludedIds: args.excludedStockItemIds,
              stockItemType: 'STOCK',
              loadRecordsOfStock: sourceStockResult.id
            };
            StockItemSelectorDialogComponent.openSelector(args.dialog, stockItemData, stockItemResult => {
              if (stockItemResult) {
                StockSelectorDialogComponent.openSelector(args.dialog, {
                    excludedIds: args.excludedDestinationStockIds.has(sourceStockResult.id)
                      ? Array.from(args.excludedDestinationStockIds.get(sourceStockResult.id))
                      : [sourceStockResult.id],
                    forceStockIds: args.forceDestinationStockIds,
                    typeName: args.destinationStockTypeName,
                    ownerCustomerRecordId: args.destinationStockTypeName === 'CUSTOMER' ? args.ownerCustomerRecordId : undefined,
                    ownerContactLocationId: args.destinationStockTypeName === 'CUSTOMER' ? args.ownerContactLocationId : undefined,
                    titleKey: 'STOCK_MOVE_DESTINATION_STOCK_SELECTOR_DIALOG_TITLE'
                  },
                  (destinationStockResult) => {
                    if (destinationStockResult) {
                      args.resultCallback({
                        selectedStockItemIds: stockItemResult.selectedStockItemIds,
                        selectedStockItems: stockItemResult.selectedStockItems,
                        selectedSourceStock: sourceStockResult,
                        selectedDestinationStock: destinationStockResult
                      });
                    }
                  });
              }
            });
          }
        });
    }
  }

  export interface StockMoveSelectorArgs {
    dialog: MatDialog;
    usableCategoryIds: number[];
    sourceStockTypeName?: StockTypeName;
    destinationStockTypeName?: StockTypeName;
    forceSourceStockIds?: number[],
    forceDestinationStockIds?: number[],
    ownerCustomerRecordId?: number,
    ownerContactLocationId?: number,
    excludedStockItemIds: number[];
    excludedDestinationStockIds: ExcludedStockIdCollection;
    movementId?: MovementId;
    resultCallback: (result: StockMoveSelectorResult) => void;
  }

  export interface StockMoveSelectorResult {
    selectedStockItemIds?: number[];
    selectedStockItems?: StockItemSelectorModel[];
    selectedSourceStock?: MultiselectOptionItem<number>;
    selectedDestinationStock?: MultiselectOptionItem<number>;
    movementId?: MovementId;
  }
}
