/* eslint-disable */
import { Component, ComponentRef, Input, OnDestroy, ViewChild, } from '@angular/core';
import { FormRecordContainer } from './form-record-container';
import { FormRecordGroupHolderDirective } from './form-record-group-holder.directive';
import { FormRecord } from '../../../lib/form/form-record.service';
import { Form } from '../../../lib/form/form.service';
import { FormRecordGroupView, SetTmpReadonlyResult, } from '../../../util/form/form-utils';
import { List, Set } from 'immutable';
import { ForwardingNgFormRef, } from '../../../lib/util/services';
import { NgForm } from '@angular/forms';
import { CommandManager } from '../../../util/command';
import { CommandResultStore } from './command-result-store';
import { ToasterService } from '../../../fork/angular2-toaster/angular2-toaster';
import { FormRecordFieldNotifierService } from '../../../lib/form/form-record-field-notifier.service';
import { FormRecordStockPercentValidatorService } from '../../../lib/form/form-record-stock-percent-validator.service';
import { FormRecordStockAmountValidatorService } from '../../../lib/form/form-record-stock-amount-validator.service';
import { forkJoin, Observable } from 'rxjs';
import { FormRecordInactivityManagerFactory } from './manager/form-record-inactivity-manager';

/* eslint-enable */

@Component({
  selector: 'app-form-record-container',
  templateUrl: 'form-record-container.component.html',
  styleUrls: ['form-record-container.component.scss'],
})
export class FormRecordContainerComponent implements FormRecordContainer.View, OnDestroy {

  @ViewChild(FormRecordGroupHolderDirective, { static: true })
  groupHost: FormRecordGroupHolderDirective;

  @Input()
  readonly: boolean;

  @Input()
  cloning: boolean;

  @Input()
  form?: NgForm;

  private groupViews: ComponentRef<FormRecordGroupView>[] = [];
  private readonly commandManager: CommandManager<SetTmpReadonlyResult> = new CommandManager<SetTmpReadonlyResult>();
  private readonly commandResultStore: CommandResultStore = new CommandResultStore();

  constructor(
    private toasterService: ToasterService,
    private fieldNotifierService: FormRecordFieldNotifierService,
    private formRecordInactivityManagerFactory: FormRecordInactivityManagerFactory,
    private formRecordStockPercentValidatorService: FormRecordStockPercentValidatorService,
    private formRecordStockAmountValidatorService: FormRecordStockAmountValidatorService) {
  }

  ngOnDestroy() {
    this.commandResultStore.toastIds.forEach((toastId) => {
      this.toasterService.clear(toastId);
    });
    this.commandResultStore.clear();
    this.commandManager.clear();
    this.groupViews = [];
    this.fieldNotifierService.clear();
    this.formRecordStockPercentValidatorService.clear();
    this.formRecordStockAmountValidatorService.clear();
  }

  createModel(): FormRecordContainer.Model {
    const requests: FormRecord.FieldEditRequest[] = [];
    const model = new FormRecordContainer.Model();
    List.of(...this.groupViews).forEach((groupView) => {
      if (!groupView) {
        return true;
      }
      const m = groupView.instance.createModel();
      requests.push(...m.fieldEditRequests.toArray());
    });
    model.fields = Set.of(...requests);
    return model;
  }

  hasLocalFieldError(): boolean {
    let hasError = false;
    List.of(...this.groupViews).forEach((groupView) => {
      if (!groupView) {
        return true;
      }
      if (groupView.instance.hasLocalFieldError()) {
        hasError = true;
        return false;
      }
    });
    return hasError;
  }

  validateWithInterrupt(): boolean {
    let interrupted = false;
    List.of(...this.groupViews).forEach((groupView) => {
      if (!groupView) {
        return true;
      }
      if (groupView.instance.validateWithInterrupt()) {
        interrupted = true;
        return false;
      }
    });
    return interrupted;
  }

  shouldNotifyAfterCreation(): boolean {
    for (const groupView of this.groupViews) {
      if (groupView.instance.shouldNotifyAfterCreation()) {
        return true;
      }
    }
    return false;
  }

  afterFormRecordCreation(formRecordId: number): Observable<any> {
    const observables: Observable<any>[] = [];
    this.groupViews.forEach(groupView => {
      if (groupView.instance.shouldNotifyAfterCreation()) {
        observables.push(groupView.instance.afterFormRecordCreation(formRecordId)!);
      }
    });
    return forkJoin(observables);
  }

  loadFormRecord(args: FormRecordContainer.Args) {
    const inactivityManager = this.formRecordInactivityManagerFactory.create({
      commandManager: this.commandManager,
      commandResultStore: this.commandResultStore,
      readonlyForm: () => this.readonly,
    });
    this.groupViews = [];
    this.groupHost.viewContainerRef.clear();
    args.form.groups.forEach((group) => {
      if (!group) {
        return true;
      }
      const groupView = this.createGroupView(args.form, group);
      groupView.instance.registerFields({
        commandManager: this.commandManager,
        commandResultStore: this.commandResultStore,
        inactivityManager: inactivityManager,
        htmlForm: this.createForwardingHtmlForm(),
        readonly: () => this.readonly,
        cloning: () => this.cloning,
        configuration: args.configuration,
        form: args.form,
        group: group,
        formRecord: args.record,
        other: args.other
      });
      this.groupViews.push(groupView);
      this.groupHost.viewContainerRef.insert(groupView.hostView);
    });
    inactivityManager.createLogic(args.form);
  }

  private createForwardingHtmlForm() {
    return new ForwardingNgFormRef({
      formFn: () => {
        return this.form;
      }
    });
  }

  private createGroupView(form: Form.Form, group: Form.Group) {
    return this.groupHost.groupViewFactory.createView({
      form: form,
      group: group,
    });
  }

  updateFormRecordValues(args: FormRecordContainer.UpdateArgs) {
    args.fields.forEach(field => {
      const group = this.groupViews.find(groupView => groupView.instance.getGroupId() === field.fieldIdentifier.groupId);
      if (group) {
        group.instance.updateFieldData(field.fieldIdentifier.fieldId, field.data);
      }
    });
  }

}
