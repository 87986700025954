/* eslint-disable */
import { DownloadedFile, } from '../util/downloaded-files';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResourceQueryResult } from '../util/services';
import { map } from 'rxjs/operators';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { HttpClient } from '@angular/common/http';
import { EmptyMessage } from '../util/messages';
/* eslint-enable */

@Injectable()
export class GeneralPdfTemplateResourceService extends BaseHttpService {

  query(request: GeneralPdfTemplateResource.QueryRequest): Observable<ResourceQueryResult<GeneralPdfTemplateResource.GeneralPdfTemplate>> {
    return this.http.get<ResourceQueryResult<GeneralPdfTemplateResource.GeneralPdfTemplate>>(this.url, this.parseParams(request));
  }

  get(request: GeneralPdfTemplateResource.GetRequest): Observable<GeneralPdfTemplateResource.GeneralPdfTemplate> {
    return this.http.get<GeneralPdfTemplateResource.GeneralPdfTemplate>(this.url + `${request.code}`);
  }

  getAvailableSystemCodes(request: GeneralPdfTemplateResource.AvailableSystemCodesRequest): Observable<string[]> {
    return this.http.get<string[]>(this.url + '/available-system-codes', this.parseParams(request));
  }

  setDisabled(request: GeneralPdfTemplateResource.DisableRequest): Observable<EmptyMessage> {
    return this.http.patch(this.url + `${request.code}/disabled`, request);
  }

  download(request: GeneralPdfTemplateResource.DownloadRequest): Observable<DownloadedFile> {
    return this.http.get(this.url + `${request.code}/download`, {
      observe: 'response',
      responseType: 'blob'
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/general-pdf/template/');
  }
}

export namespace GeneralPdfTemplateResource {

  export interface GeneralPdfTemplate {
    id: number;
    code: string;
    content_hash: string;
    version: number;
    creation_time?: string;
    update_time?: string;
    disabled: boolean;
    system_code: boolean;
  }

  export interface QueryRequest {
    ids?: string;
    code?: string;
    q?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
    disabled?: boolean;
    no_progress_bar?: boolean;
  }

  export interface GetRequest {
    code: string;
  }

  export interface AvailableSystemCodesRequest {
    code: string;
    no_progress_bar?: boolean;
  }

  export interface DownloadRequest {
    code: string;
  }

  export interface DisableRequest {
    code: string;
    disabled: boolean;
  }

}
