/* eslint-disable */
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Transition, UIRouter } from '@uirouter/angular';
import { combineLatest, Observable, Subject } from 'rxjs';
import { SurveyRecord, SurveyRecordService } from '../../../lib/survey/survey-record.service';
import { Survey, SurveyService } from '../../../lib/survey/survey.service';
import { SurveyRecordBase } from '../survey-record-base/survey-record-base-view';
import { SurveyRecordBaseComponent } from '../survey-record-base/survey-record-base.component';
import { StateName } from '../../../app.state-names';
import { FieldValidationError, } from '../../../lib/util/services';
import { FormRecordContainer } from '../../form/form-record/form-record-container';
import { FormRecordContainerComponent } from '../../form/form-record/form-record-container.component';
import { Form } from '../../../lib/form/form.service';
import { ConfigurationService, } from '../../../lib/core-ext/configuration.service';
import { ToasterService } from '../../../fork/angular2-toaster/angular2-toaster';
import { TranslateService } from '@ngx-translate/core';
import { StringKey } from '../../../app.string-keys';
import { UiConstants } from '../../../util/core-utils';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmLeaveModalComponent } from '../../../shared/confirm-leave-modal/confirm-leave-modal.component';
/* eslint-enable */

@Component({
  selector: 'app-survey-record-edit',
  templateUrl: 'survey-record-edit.component.html',
  styleUrls: ['survey-record-edit.component.scss'],
})
export class SurveyRecordEditComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(SurveyRecordBaseComponent, { static: true })
  baseView: SurveyRecordBase.View;

  @ViewChild(FormRecordContainerComponent, { static: true })
  formRecordContainerView: FormRecordContainer.View;

  @ViewChild('f', { static: true })
  fForm: NgForm;

  surveyId: number;
  surveyRecordId: number;
  breadcrumbSelf: string;
  breadcrumbParents: BreadcrumbParent[] = [];
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  surveyName: string;

  deregisterWarningModal;
  saveButtonClicked: boolean = false;

  private combinedResult?: CombinedResult;

  constructor(
    private toasterService: ToasterService,
    private translateService: TranslateService,
    private configurationService: ConfigurationService,
    private surveyService: SurveyService,
    private surveyRecordService: SurveyRecordService,
    private uiRouter: UIRouter,
    private modalService: BsModalService,
    private transition: Transition) {
    this.surveyId = this.transition.params().surveyId;
    this.surveyRecordId = this.transition.params().id;
  }

  ngOnInit() {
    const context = this;
    this.deregisterWarningModal = this.uiRouter.transitionService.onStart({}, function (transition) {
      if (context.saveButtonClicked
        || transition.targetState().name() === StateName.CONNECTION_ERROR
        || transition.targetState().name() === StateName.SERVER_ERROR
        || transition.targetState().name() === StateName.LOGIN) {
        return true;
      }
      else {
        const subject = new Subject<boolean>();
        const modal = context.modalService.show(ConfirmLeaveModalComponent, {backdrop: 'static',  keyboard: false});
        modal.content!.subject = subject;
        return subject.asObservable().toPromise();
      }
    });
  }

  ngAfterViewInit(): void {
    this.createCombinedObservable().subscribe(
      (result: CombinedResult) => {
        this.breadcrumbSelf = result.surveyRecord.name!;
        this.surveyName = result.survey.name;
        this.baseView.loadSurveyRecord({
          surveyRecord: result.surveyRecord
        });
        this.formRecordContainerView.loadFormRecord({
          configuration: this.configurationService.getConfiguration(),
          form: result.form,
          record: result.surveyRecord.formRecord,
        });
        this.combinedResult = result;
      },
      () => {
      },
      () => {
        this.translateService.get('MENU_NAVBAR_SURVEY_RECORD').subscribe(
          (result: string) => {
            this.breadcrumbParents.push({name: result, uiSref: StateName.SURVEY_DASHBOARD});
          }
        );
        this.breadcrumbParents.push({name: this.surveyName, uiSref: StateName.SURVEY_RECORD_LIST, uiParam: {id: this.surveyId}});
      }
    );
  }

  updateSurveyRecord() {
    if (!this.combinedResult) {
      return;
    }
    if (this.baseView.hasLocalFieldError() || this.formRecordContainerView.hasLocalFieldError()) {
      this.showValidationErrorToast();
      return;
    }
    if (this.formRecordContainerView.validateWithInterrupt()) {
      return;
    }
    const model = this.baseView.getModel();
    const formModel = this.formRecordContainerView.createModel();
    this.surveyRecordService.update({
      withFormRecord: false,
      surveyId: this.surveyId,
      surveyRecordId: this.surveyRecordId,
      name: model.name,
      customerRecordId: model.customerRecordId,
      formRecord: {
        version: this.combinedResult.surveyRecord.formRecord!.version,
        fields: formModel.fields
      }
    }).subscribe(
      (surveyRecord: SurveyRecord.SurveyRecord) => {
        this.saveButtonClicked = true;
        this.uiRouter.stateService.go(StateName.SURVEY_RECORD_LIST, {
          surveyId: surveyRecord.surveyId
        });
      },
      (error) => {
        if (error instanceof FieldValidationError) {
          this.baseView.onFieldValidationError(error.withForm(this.fForm));
          this.showValidationErrorToast();
        }
      }
    );
  }

  private createCombinedObservable(): Observable<CombinedResult> {
    return combineLatest(
      this.surveyService.getForm({
        parentId: this.surveyId
      }),
      this.surveyService.get({
        surveyId: this.surveyId
      }),
      this.surveyRecordService.get({
        withFormRecord: true,
        surveyId: this.surveyId,
        surveyRecordId: this.surveyRecordId
      }),
      (
        form: Form.Form,
        survey: Survey.Survey,
        surveyRecord: SurveyRecord.SurveyRecord) => {
        return {
          form: form,
          survey: survey,
          surveyRecord: surveyRecord
        }
      }
    );
  }

  private showValidationErrorToast() {
    this.toasterService.pop({
      timeout: UiConstants.ToastTimeoutLong,
      type: UiConstants.toastTypeError,
      title: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_TITLE),
      body: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_MESSAGE)
    });
  }

  ngOnDestroy() {
    this.deregisterWarningModal();
  }

}

interface CombinedResult {
  form: Form.Form;
  survey: Survey.Survey;
  surveyRecord: SurveyRecord.SurveyRecord;
}
