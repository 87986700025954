/* eslint-disable */
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { StockItemModel } from '../form-record-stock-field.component';
import { StockItemService } from '../../../../../../lib/stock/stock-item.service';
import { StockRecordService } from '../../../../../../lib/stock/stock-record.service';
import { StockService } from '../../../../../../lib/stock/stock.service';
import { UserService } from '../../../../../../lib/user.service';
import { TranslateService } from '@ngx-translate/core';
import { Decimal } from 'decimal.js';
import { Angular2Multiselects } from '../../../../../../util/multiselect';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppValidators } from '../../../../../../util/app-validators';
import { Models } from '../../../../../../util/model-utils';
import {
  FormRecordStockAmountValidatorService,
  StockRecordDataCollection
} from '../../../../../../lib/form/form-record-stock-amount-validator.service';
import { Subscription } from 'rxjs';
import { DispersionPercentValidator } from '../dispersion-percent-validator';
import { StockItemUnitOfMeasureMultiselectOptionItem } from '../../../../../../lib/stock/stock-item-unit-of-measure-multiselect.provider';

/* eslint-enable */

@Component({
  selector: 'app-form-record-stock-field-item-edit',
  templateUrl: './form-record-stock-field-item-edit.component.html',
  styleUrls: ['./form-record-stock-field-item-edit.component.scss']
})
export class FormRecordStockFieldItemEditComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input()
  isRemovable: boolean = true;
  @Input()
  model: StockItemModel;
  @Input()
  discount?: Decimal | null;
  @Input()
  excludedIds: number[];
  @Input()
  uniqueStockRecords: boolean;
  @Input()
  usableCategoryIds: number[];
  @Input()
  customerRecordId: number | undefined;
  @Input()
  dispersionPercentEnabled: boolean = false;
  @Input()
  dispersionPercentValidator: DispersionPercentValidator;

  @Output()
  remove: EventEmitter<undefined> = new EventEmitter();
  @Output()
  save: EventEmitter<StockItemModel> = new EventEmitter<StockItemModel>();
  @Output()
  cancel: EventEmitter<undefined> = new EventEmitter();
  @Output()
  dispersionPercentChangedEvent: EventEmitter<DispersionPercentChangedEvent> = new EventEmitter();

  amount?: number;
  unitOfMeasure: StockItemUnitOfMeasureMultiselectOptionItem[] = [];
  amountInStock?: number;
  vat?: number;
  unit?: string;
  netReducedPrice?: number;
  grossReducedPrice?: number;
  validAmount: boolean = true;
  validPercentage: boolean = true;
  submitted: boolean = false;
  code?: string;

  disabledItem = true;

  dropdownSettings: Angular2Multiselects.Settings = Angular2Multiselects.LOCAL_SINGLE_SELECT;

  formGroup: FormGroup;
  private readonly amountValidatorSubscription: Subscription;

  @Input()
  fieldId?: number;


  get baseAmount(): number | undefined {
    if (this.amount === undefined || this.unitOfMeasure.length === 0 ) {
      return undefined;
    }
    return this.amount * this.unitOfMeasure[0].conversion;
  }
  constructor(
    private stockRecordService: StockRecordService,
    private stockItemService: StockItemService,
    private stockService: StockService,
    private userService: UserService,
    private translateService: TranslateService,
    private formBuilder: FormBuilder,
    private formRecordStockAmountValidatorService: FormRecordStockAmountValidatorService
  ) {
    this.amountValidatorSubscription = formRecordStockAmountValidatorService.subscribe(n => this.onRecordDataChanged(n));
    this.formGroup = formBuilder.group({
      amount: ['', [Validators.required, AppValidators.validateOptionalPositiveNumber]],
      dispersionPercent: ['', [Validators.required]],
      unitOfMeasure: ['', [Validators.required, AppValidators.validateEnabledItems]]
    });
  }

  ngOnInit() {
    this.loadModel();
  }

  ngAfterViewInit() {
  }

  loadModel() {
    if (this.model.stockRecord.id) {
      this.disabledItem = this.model.disabledItem;
      this.code = this.model.code;
      this.amount = Models.decimalToNumber(this.model.amount);
      this.unitOfMeasure = this.model.unitOfMeasure;
      this.amountInStock = this.model.stockRecord.amount;
      this.netReducedPrice = this.model.customNetPrice !== undefined ? parseFloat(this.model.customNetPrice) : undefined;
      this.vat = this.model.stockRecord.stockItem.unit_price!.vat_rate;
      this.unit = this.model.stockRecord.stockItem.unit;
      if (this.netReducedPrice !== undefined) {
        this.setGrossPrice();
      }
      this.notifyStockRecordAmountChange();
    }
    else {
      this.disabledItem = false;
    }
  }

  setGrossPrice() {
    if (!this.netReducedPrice) {
      this.grossReducedPrice = undefined;
      return;
    }
    if (this.vat !== undefined) {
      this.grossReducedPrice = parseFloat((this.netReducedPrice! * (1 + this.vat! / 100)).toFixed(2));
    }
  }

  setNetPrice() {
    if (!this.grossReducedPrice) {
      this.netReducedPrice = undefined;
      return;
    }
    if (this.vat !== undefined) {
      this.netReducedPrice = parseFloat((this.grossReducedPrice! / (1 + this.vat! / 100)).toFixed(6));
    }
  }

  notifyStockRecordAmountChange() {
    this.formRecordStockAmountValidatorService.updateStockRecordAmount(
      this.fieldId!,
      this.model.stockRecord.id,
      this.baseAmount ? this.baseAmount : 0,
      this.model.stockRecord.amount ? this.model.stockRecord.amount : 0,
      this.model.submitAmount?.toNumber()
    );
  }

  onRecordDataChanged(stockRecordDataCollection: StockRecordDataCollection) {
    if (this.model && this.model.stockRecord.id === stockRecordDataCollection.stockRecordId) {
      this.validAmount = this.formRecordStockAmountValidatorService.validateDataCollection(stockRecordDataCollection);
    }
  }

  validatePercentage() {
    let isCategoryValid;
    if (this.model.dispersionPercent !== undefined) {
      this.dispersionPercentValidator.updateItem(this.model.categoryId, this.model.stockRecord.id, this.model.dispersionPercent);
      this.validPercentage = this.model.dispersionPercent <= 100 && this.model.dispersionPercent >= 0;
      isCategoryValid = this.dispersionPercentValidator.isCategoryValid(this.model.categoryId);
      this.model.validDispersionPercent = isCategoryValid;
    }
    else {
      this.dispersionPercentValidator.updateItem(this.model.categoryId, this.model.stockRecord.id, 0);
      isCategoryValid = this.dispersionPercentValidator.isCategoryValid(this.model.categoryId);
      this.validPercentage = true;
      this.model.validDispersionPercent = isCategoryValid;
    }
    this.dispersionPercentChangedEvent.emit({
      categoryId: this.model.categoryId,
      valid: isCategoryValid
    });
  }

  saveRecord() {
    this.submitted = true;
    if (this.dispersionPercentEnabled) {
      this.formGroup.get('amount')!.setErrors(null)
      this.formGroup.get('unitOfMeasure')!.setErrors(null)
    }
    else {
      this.formGroup.get('dispersionPercent')!.setErrors(null);
    }
    if (!this.formGroup.valid) {
      this.formGroup.get('amount')!.markAsTouched();
      this.formGroup.get('unitOfMeasure')!.markAsTouched();
      this.formGroup.get('dispersionPercent')!.markAsTouched();
      return;
    }
    else {
      if (this.dispersionPercentEnabled) {
        this.validatePercentage();
      }
      if (!this.validAmount || !this.validPercentage || !this.model.validDispersionPercent
        || this.unitOfMeasure.length === 0) {
        return;
      }
      this.model.amount = this.amount !== undefined ? new Decimal(this.amount) : undefined;
      this.model.unitOfMeasure = this.unitOfMeasure;
      this.model.code = this.code;
      this.model.customNetPrice = this.netReducedPrice !== undefined && this.netReducedPrice !== null
        ? this.netReducedPrice.toString() : undefined;
      this.model.lastSavedStockItemNetPrice = this.model.stockRecord.stockItem.unit_price.value.toString();
      this.model.editing = true;
      this.model.disabledItem = false;
      this.save.emit(this.model);
    }
  }

  ngOnDestroy(): void {
    this.formRecordStockAmountValidatorService.unsubscribe(this.amountValidatorSubscription);
  }

}

export interface DispersionPercentChangedEvent {
  categoryId: number;
  valid: boolean;
}
