import { Injectable } from '@angular/core';
import { MultiselectProvider } from '../../multiselect-provider';
import { MultiselectOptionItem, MultiselectOptionItemWithData, UiConstants } from '../../../util/core-utils';
import { Set } from 'immutable';
import { OrderType, QueryResult } from '../../util/services';
import { Observable, Observer } from 'rxjs';
import { CustomerRecordContact, CustomerRecordContactService } from './customer-record-contact.service';
import { Address } from '../../address';

@Injectable()
export class CustomerRecordContactLocationMultiselectProvider implements MultiselectProvider {

  private multiselectQueryRequest: CustomerRecordContact.LocationQueryRequest = {
    id: undefined,
    paging: {
      pageNumber: 1,
      numberOfItems: UiConstants.autocompletePageSize
    },
    noProgressBar: true,
    orders: Set.of({field: CustomerRecordContact.OrderField.NAME, type: OrderType.ASC},
      {field: CustomerRecordContact.OrderField.CUSTOMER_RECORD_NAME, type: OrderType.ASC})
  };

  constructor(private customerRecordContactService: CustomerRecordContactService) {
  }

  load(queryRequest: CustomerRecordContact.LocationQueryRequest): Observable<MultiselectOptionItem<number>[]> {
    return Observable.create((observer: Observer<MultiselectOptionItem<number>[]>) => {
      this.customerRecordContactService.getLocations(queryRequest).subscribe((result: QueryResult<Address.ContactLocation>) => {
          observer.next(result.items.map(item => this.toMultiselectOptionItem(item!)).toArray());
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  loadAsPostalAddress(queryRequest: CustomerRecordContact.LocationQueryRequest): Observable<MultiselectOptionItemWithData<number>[]> {
    return Observable.create((observer: Observer<MultiselectOptionItemWithData<number>[]>) => {
      this.customerRecordContactService.getLocations(queryRequest).subscribe((result: QueryResult<Address.ContactLocation>) => {
          observer.next(result.items.map(item => this.toMultiselectOptionItemPostalAddress(item!)).toArray());
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  loadAll(predicate?: string): Observable<MultiselectOptionItem<number>[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.q = predicate;
    return this.load(queryRequest);
  }

  loadForCustomer(customerRecordId: number, predicate?: string): Observable<MultiselectOptionItem<number>[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.q = predicate;
    queryRequest.customerRecordId = customerRecordId;
    return this.load(queryRequest);
  }

  loadForCustomerAsPostalAddress(customerRecordId: number, predicate?: string): Observable<MultiselectOptionItemWithData<number>[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.q = predicate;
    queryRequest.customerRecordId = customerRecordId;
    return this.loadAsPostalAddress(queryRequest);
  }

  getById(id: number): Observable<MultiselectOptionItem<number>> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.id = Set.of(id);
    return Observable.create((observer: Observer<MultiselectOptionItem<number>>) => {
      this.customerRecordContactService.getLocations(queryRequest).subscribe((result: QueryResult<Address.ContactLocation>) => {
          observer.next(result.items.map(item => this.toMultiselectOptionItem(item!)).toArray()[0]);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  getByIds(ids: number[]): Observable<MultiselectOptionItem<number>[]> {
    const queryRequest = Object.assign([], this.multiselectQueryRequest);
    queryRequest.id = Set.of(...ids);
    return this.load(queryRequest);
  }

  toMultiselectOptionItem(item: Address.ContactLocation): MultiselectOptionItem<number> {
    const ret = new MultiselectOptionItem<number>();
    ret.id = item.id!;
    ret.itemName = item.name;
    ret.itemSubtitle = `${item.customerRecordName}${item.contractNumber ? ' - ' + item.contractNumber : ''}`;
    ret.disabled = false;
    return ret;
  }

  toMultiselectOptionItemPostalAddress(item: Address.ContactLocation): MultiselectOptionItemWithData<number> {
    const ret = new MultiselectOptionItemWithData<number>();
    ret.id = item.postalAddress!.id!;
    ret.itemName = item.name;
    ret.disabled = false;
    ret.data = {postalAddress: item.postalAddress, locationId: item.id};
    return ret;
  }

}
