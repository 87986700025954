/* eslint-disable */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Company, CompanyService } from '../../../lib/company/company.service';
import { OrderType, QueryResult, Services } from '../../../lib/util/services';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { StateName } from '../../../app.state-names';
import { TranslateService } from '@ngx-translate/core';
import { Address } from '../../../lib/address';
import { ConfigModel } from '../../../util/task-record-utils';
import { ConfigurationService } from '../../../lib/core-ext/configuration.service';
import { OptionItem, QueryFieldModel, SelectUtils, UiConstants } from '../../../util/core-utils';
import { Set } from 'immutable';
import { EmptyMessage } from '../../../lib/util/messages';
import { InputMask } from '../../../util/input-masks';
import { Strings } from '../../../lib/util/strings';
import { CompanySearch, CompanySearchService, CompanyTypeItem } from '../../../lib/company/company-search.service';
import { combineLatest, Observable } from 'rxjs';
import { Arrays } from '../../../lib/util/arrays';
import { RightResolver, RightService } from '../../../lib/right.service';
import { RightModel } from '../../../app.rights';
import { DisabledEnum, DisabledItem, SearchUtils } from '../../../util/search-utils';
import CompanyType = Company.CompanyType;
import companyTypes = Company.companyTypes;
import SearchModel = SearchUtils.SearchModel;
/* eslint-enable */

@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.scss']
})
export class CompanyListComponent implements OnInit, OnDestroy {
  Company = Company;
  SelectUtils = SelectUtils;
  InputMask = InputMask;
  UiConstants = UiConstants;

  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  showSearch: boolean = false;
  config: ConfigModel = new ConfigModel();
  rightModel: RightModel = RightModel.empty();
  queryModel: QueryFieldModel<Company.OrderField> = new QueryFieldModel(Company.OrderField.ID, OrderType.DESC);

  searchResult: CompanySearch.SearchDataResult;
  searchModel: CompanySearchModel = new CompanySearchModel();
  disabledItems: DisabledItem[] = [];
  companyTypeItems: CompanyTypeItem[] = [];

  companyList: CompanyModel[] = [];

  constructor(private companyService: CompanyService,
              private translateService: TranslateService,
              private configService: ConfigurationService,
              private companySearchService: CompanySearchService,
              private rightService: RightService) {
    this.config = this.configService.getConfigurationModel();
  }

  ngOnInit() {
    this.loadRightModels();
    this.loadSearch(() => {
      this.showSearch = !this.searchModel.isEmpty();
      this.initCompanyTypeOptions();
      this.initDisabledOptions(DisabledEnum.FALSE);
      this.loadList();
    });
    this.initBreadcrumb();
  }

  private loadSearch(completion: () => void) {
    const obs: Observable<SearchLoadResult> = combineLatest(
      this.companySearchService.getSearchData({}),
      (storedSearchData: CompanySearch.SearchDataResult) => {
        const result: SearchLoadResult = {
          storedSearchData: storedSearchData
        };
        return result;
      }
    );
    obs.subscribe(
      (result: SearchLoadResult) => {
        this.searchResult = result.storedSearchData;
        this.postInitSearch(result.storedSearchData, completion);
      }
    );
  }

  private postInitSearch(storedSearchData: CompanySearch.SearchDataResult, completion: () => void) {
    this.queryModel.itemsPerPage = storedSearchData.searchData.itemsPerPage;
    this.queryModel.currentPage = storedSearchData.searchData.pageNumber;
    this.queryModel.setOrder(storedSearchData.searchData.order);
    this.searchModel.id = storedSearchData.searchData.id;
    this.searchModel.externalId = storedSearchData.searchData.externalId;
    this.searchModel.companyName = storedSearchData.searchData.companyName;
    this.searchModel.companyType = storedSearchData.searchData.companyType;
    this.searchModel.disabled = storedSearchData.searchData.disabled;
    completion()
  }

  private initDisabledOptions(initValue: DisabledEnum) {
    this.disabledItems = [];
    const disabledEnums: DisabledEnum[] = [DisabledEnum.NONE, DisabledEnum.FALSE, DisabledEnum.TRUE];
    Arrays.iterateByIndex(disabledEnums, (key) => {
      const item = new DisabledItem();
      item.id = key;
      this.translateService.get('COMMON_VALUE_DISABLED_ENUM_' + key).subscribe(
        (text: string) => {
          item.text = text;
        }
      );
      this.disabledItems.push(item);
      if (!this.searchModel.disabled) {
        if (key === initValue) {
          this.searchModel.disabled = item;
        }
      }
    });
  }

  private initCompanyTypeOptions() {
    this.companyTypeItems = [];
    const companyTypes: (CompanyType | null)[] = [null, 'DEMANDER', 'TRANSPORTER'];
    Arrays.iterateByIndex(companyTypes, (key) => {
      const item = new CompanyTypeItem();
      item.id = key;
      this.translateService.get(key !== null ? 'COMPANY_TYPE_' + key : 'COMMON_VALUE_ALL').subscribe(
        (text: string) => {
          item.text = text;
        }
      );
      if (!this.searchModel.companyType && key === null) {
        this.searchModel.companyType = item;
      }
      this.companyTypeItems.push(item);
    });
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();
    const idSet: number[] = [];
    if (Strings.undefinedOrNonEmpty(this.searchModel.id)) {
      idSet.push(+this.searchModel.id);
    }
    const disabled: boolean | undefined = !this.searchModel.disabled ||
    this.searchModel.disabled.id === DisabledEnum.NONE ?
      undefined : this.searchModel.disabled.id === DisabledEnum.TRUE;
    this.companyService.query({
      id: idSet.length > 0 ? Set.of(... idSet) : undefined,
      externalId: Strings.undefinedOrNonEmpty(this.searchModel.externalId),
      name: Strings.undefinedOrNonEmpty(this.searchModel.companyName),
      type: OptionItem.idOrUndefined(this.searchModel.companyType)
        ? Set.of(OptionItem.idOrUndefined(this.searchModel.companyType)!)
        : undefined,
      disabled: disabled,
      orders: Set.of(order),
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined
    }).subscribe((result: QueryResult<Company.Company>) => {
      this.companyList = [];
      result.items.forEach((company: Company.Company) => {
        const companyModel = new CompanyModel();
        companyModel.id = company.id + '';
        companyModel.externalId = company.externalId;
        companyModel.companyName = company.name;
        companyModel.companyAddress = Address.PostalAddressMapper.toString(company.postalAddress, this.config.postalAddressFormat);
        companyModel.type = company.type;
        companyModel.disabled = company.disabled;
        this.companyList.push(companyModel);
      });
      this.queryModel.currentPage = requestedPage;
      this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
    });
  }

  onSearchClicked() {
    this.loadList(1);
  }

  onSearchReset() {
    this.companySearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.showSearch = true;
          this.loadList(1);
        });
      }
    );
  }

  private saveSearch() {
    const request = {
      searchData: {
        itemsPerPage: this.queryModel.itemsPerPage,
        pageNumber: this.queryModel.currentPage,
        order: this.queryModel.getOrder(),
        id: this.searchModel.id,
        externalId: this.searchModel.externalId,
        companyName: this.searchModel.companyName,
        companyType: this.searchModel.companyType,
        disabled: this.searchModel.disabled,
      }
    };
    this.companySearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  initBreadcrumb() {
    this.translateService.get('MENU_NAVBAR_COMPANIES').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  orderBy(field: Company.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  setDisabled(event: any, company: Company.Company, disabled: boolean) {
    this.companyService.setDisabled({
      id: company.id,
      disabled: disabled
    }).subscribe(
      (result: EmptyMessage) => {
        this.loadList();
      },
      (error: any) => {
        this.loadList();
      }
    );
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  ngOnDestroy() {
    this.saveSearch();
  }

  getCompanyTypeStringKey(type: CompanyType): string {
    return companyTypes.filter((t) => t.type === type)![0].stringKey;
  }

}

class CompanyModel {
  id: string = '';
  externalId: string = '';
  companyName: string = '';
  companyAddress: string = '';
  type: CompanyType;
  disabled = false;
}

class CompanySearchModel {
  id: string;
  externalId: string;
  companyName: string;
  companyType?: CompanyTypeItem;
  disabled?: DisabledItem;

  public isEmpty(): boolean {
    return this.id.length === 0
      && this.externalId.length === 0
      && this.companyName.length === 0
      && OptionItem.idOrUndefined(this.companyType) === undefined
      && OptionItem.idOrUndefined(this.disabled) === undefined
      ;
  }

  public clear() {
    this.id = '';
    this.externalId = '';
    this.companyName = '';
    this.companyType = undefined;
    this.disabled = undefined;
  }

  constructor() {
  }

}

interface SearchLoadResult {
  storedSearchData: CompanySearch.SearchDataResult,
}
