import { GrantedPermissionSet, GrantedPermissionSetResolver } from '../right.service';
import { OperationRights } from '../../app.right-definitions';

export class ExteriorTransportRightModel {

  public readonly create: GrantedPermissionSet;
  public readonly delete: GrantedPermissionSet;
  public readonly read: GrantedPermissionSet;
  public readonly start: GrantedPermissionSet;
  public readonly finish: GrantedPermissionSet;
  public readonly shipmentAdd: GrantedPermissionSet;
  public readonly shipmentRemove: GrantedPermissionSet;
  public readonly documentRead: GrantedPermissionSet;
  public readonly documentCreate: GrantedPermissionSet;
  public readonly documentDelete: GrantedPermissionSet;
  public readonly documentDownload: GrantedPermissionSet;
  public readonly documentMetaUpdate: GrantedPermissionSet;

  constructor(resolver: GrantedPermissionSetResolver) {
    this.create = resolver.of(OperationRights.EXTERIOR_TRANSPORT_CREATE);
    this.delete = resolver.of(OperationRights.EXTERIOR_TRANSPORT_DELETE);
    this.read = resolver.of(OperationRights.EXTERIOR_TRANSPORT_READ);
    this.start = resolver.of(OperationRights.EXTERIOR_TRANSPORT_START);
    this.finish = resolver.of(OperationRights.EXTERIOR_TRANSPORT_FINISH);
    this.shipmentAdd = resolver.of(OperationRights.EXTERIOR_TRANSPORT_SHIPMENT_ADD);
    this.shipmentRemove = resolver.of(OperationRights.EXTERIOR_TRANSPORT_SHIPMENT_REMOVE);
    this.documentRead = resolver.of(OperationRights.EXTERIOR_TRANSPORT_DOCUMENT_READ);
    this.documentCreate = resolver.of(OperationRights.EXTERIOR_TRANSPORT_DOCUMENT_CREATE);
    this.documentDelete = resolver.of(OperationRights.EXTERIOR_TRANSPORT_DOCUMENT_DELETE);
    this.documentDownload = resolver.of(OperationRights.EXTERIOR_TRANSPORT_DOCUMENT_DOWNLOAD);
    this.documentMetaUpdate = resolver.of(OperationRights.EXTERIOR_TRANSPORT_DOCUMENT_META_UPDATE);
  }
}
