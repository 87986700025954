import { AndroidAppVersion } from '../../../lib/andorid-app-version/android-app-version.service';

export class AndroidAppVersionBaseModel {

  private static buildSlugPattern: RegExp
    = /(https:\/\/.*\/)?(?<slug>[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12})(\?.*)?/;
  id?: number;
  versionCode?: number;
  versionName: string = '';
  _buildSlug: string = '';
  changeLog: string = '';

  constructor() {
  }

  set version(v: AndroidAppVersion.AndroidAppVersion) {
    this.id = v.id;
    this.versionCode = v.versionCode;
    this.versionName = v.versionName;
    this.buildSlug = v.buildSlug;
    this.changeLog = v.changeLog;
  }

  set buildSlug(value: string) {
    if (value) {
      const matchGroups = value.match(AndroidAppVersionBaseModel.buildSlugPattern)?.groups;
      if (matchGroups && matchGroups['slug']) {
        this._buildSlug = matchGroups['slug'];
      } else {
        this._buildSlug = '';
      }
    } else {
      this._buildSlug = '';
    }
  }

  get buildSlug(): string {
    return this._buildSlug;
  }

  toCreateRequest() {
    return {
      versionCode: this.versionCode!,
      versionName: this.versionName,
      buildSlug: this.buildSlug,
      changeLog: this.changeLog
    };
  }

  toUpdateRequest() {
    return {
      ...this.toCreateRequest(),
      id: this.id!
    };
  }
}
