/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
/* eslint-enable */

@Injectable()
export class TransportDashboardResourceService extends BaseHttpService {

  get(request: TransportDashboardResource.GetRequest): Observable<TransportDashboardResource.Item> {
    return this.http.get<TransportDashboardResource.Item>(this.url);
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/transport-dashboard/');
  }

}

export namespace TransportDashboardResource {

  export interface Item {
    demand_statistics: DemandStatistics;
    transport_statistics: TransportStatistics;
    recent_messages: Message[];
    lost_drivers: DriverItem[];
  }
  export interface GetRequest {
  }

  export interface DemandStatistics {
    NEW?: number;
    UNDER_CONSTRUCTION?: number;
    IN_PROGRESS?: number;
    DELIVERED?: number;
    HANDOVER_FAILED?: number;
    CANCELED?: number;
  }
  export interface TransportStatistics {
    UNDER_PLANNING?: number;
    OPEN?: number;
    RUNNING?: number;
    UNDER_REPLANNING?: number;
    FINISHED?: number;
    CLOSED?: number;
  }

  export interface Message {
    transport_id: number;
    chat_message: TransportMessage;
  }

  export interface TransportMessage {
    id: number;
    creation_time: string;
    content: string;
    user_profile_id: number;
    user_name: string;
    user_person_name: string;
  }

  export interface DriverItem {
    creation_time: string;
    driver: Driver;
    transport_id: number;
    transport_waybill_number: string;
    vehicle_licence_plate: string;
  }

  export interface Driver {
    id: number;
    person_name: string;
  }

}
