import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  WorkflowTransitionFormFieldRuleModel,
  WorkflowTransitionRuleModel, WorkflowTransitionTaskRuleModel
} from "../workflow-create-editor-data-edit.component";
import {Form} from "../../../../../lib/form/form.service";

@Component({
  selector: 'app-base-rule-edit',
  templateUrl: './base-rule-edit.component.html',
  styleUrls: ['./base-rule-edit.component.scss']
})
export class BaseRuleEditComponent implements OnInit {

  @Input()
  model: WorkflowTransitionRuleModel;

  @Input()
  operatorRequired: boolean = true;

  @Input()
  create: boolean = false;

  @Input()
  taskIds: number[] = [];

  @Input()
  form: Form.Form;

  @Input()
  readonly: boolean = false;

  @Output()
  createSubmitted: EventEmitter<boolean> = new EventEmitter();

  @Output()
  deleteRule: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  getFormFieldModel() {
    return <WorkflowTransitionFormFieldRuleModel> this.model;
  }
  getTaskModel() {
    return <WorkflowTransitionTaskRuleModel> this.model;
  }
}
