/* eslint-disable */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Role, RoleService } from '../../../lib/role/role.service';
import { OrderType, QueryResult } from '../../../lib/util/services';
import { QueryFieldModel, SelectUtils, UiConstants } from '../../../util/core-utils';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { RightModel } from '../../../app.rights';
import { RoleSearch, RoleSearchModel, RoleSearchService } from '../../../lib/role/role-search.service';
import { TranslateService } from '@ngx-translate/core';
import { RightResolver, RightService } from '../../../lib/right.service';
import { StateName } from '../../../app.state-names';
import { combineLatest, Observable } from 'rxjs';
import { Strings } from '../../../lib/util/strings';
import { Set } from 'immutable';
import { EmptyMessage } from '../../../lib/util/messages';
import { Arrays } from '../../../lib/util/arrays';
import { DisabledEnum, DisabledItem } from '../../../util/search-utils';
import {TaskImportDialogComponent} from "../../task/task-import-dialog/task-import-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {RoleImportDialogComponent} from "../role-import-dialog/role-import-dialog.component";
import {DropdownItemType} from "../../../shared/dropdown/dropdown-item/dropdown-item-type";
import { saveAs } from 'file-saver';

/* eslint-enable */

@Component({
  selector: 'app-role-list',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.scss']
})
export class RoleListComponent implements OnInit, OnDestroy {
  Role = Role;
  SelectUtils = SelectUtils;
  UiConstants = UiConstants;

  roleList: Role.Role[] = [];
  queryModel: QueryFieldModel<Role.OrderField> = new QueryFieldModel(Role.OrderField.ID, OrderType.DESC);

  rightModel: RightModel = RightModel.empty();
  roleDisabledItems: DisabledItem[] = [];
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  showSearch: boolean = false;
  searchModel: RoleSearchModel = new RoleSearchModel();
  storedSearchData: RoleSearch.SearchDataResult;

  constructor(
    private roleService: RoleService,
    private roleSearchService: RoleSearchService,
    private translateService: TranslateService,
    private rightService: RightService,
    private dialog: MatDialog
  ) {
  }

  ngOnInit() {
    this.translateService.get('ROLE_LIST_HEADING').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.loadRights();
    this.loadSearch(() => {
      this.showSearch = !this.searchModel.isEmpty();
      this.initDisabledOptions(DisabledEnum.FALSE);
      this.loadList();
    });
  }

  private loadRights() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  openImportDialog() {
    RoleImportDialogComponent.openDialog(this.dialog, {
    }, result => {
      if (result && result.roleId) {
        this.loadList();
      }
    });
  }

  private initDisabledOptions(initValue: DisabledEnum) {
    this.roleDisabledItems = [];
    const disabledEnums: DisabledEnum[] = [DisabledEnum.NONE, DisabledEnum.FALSE, DisabledEnum.TRUE];
    Arrays.iterateByIndex(disabledEnums, (key) => {
      const item = new DisabledItem();
      item.id = key;
      this.translateService.get('COMMON_VALUE_DISABLED_ENUM_' + key).subscribe(
        (text: string) => {
          item.text = text;
        }
      );
      this.roleDisabledItems.push(item);
      if (!this.searchModel.disabled) {
        if (key === initValue) {
          this.searchModel.disabled = item;
        }
      }
    });
  }

  private loadSearch(completion: () => void) {
    const obs: Observable<SearchLoadResult> = combineLatest(
      this.roleSearchService.getSearchData({}),
      (storedSearchData: RoleSearch.SearchDataResult) => {
        const result: SearchLoadResult = {
          storedSearchData: storedSearchData
        };
        return result;
      }
    );
    obs.subscribe(
      (result: SearchLoadResult) => {
        this.storedSearchData = result.storedSearchData;
        this.postInitSearch(result.storedSearchData);
        completion();
      }
    );
  }

  private postInitSearch(storedSearchData: RoleSearch.SearchDataResult) {
    this.queryModel.itemsPerPage = storedSearchData.searchData.itemsPerPage;
    this.queryModel.currentPage = storedSearchData.searchData.pageNumber;
    this.queryModel.setOrder(storedSearchData.searchData.order);
    this.searchModel.id = storedSearchData.searchData.id;
    this.searchModel.name = storedSearchData.searchData.name;
    this.searchModel.disabled = storedSearchData.searchData.disabled;
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: Role.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  private loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const disabled: boolean | undefined = !this.searchModel.disabled ||
    this.searchModel.disabled.id === DisabledEnum.NONE ?
      undefined : this.searchModel.disabled.id === DisabledEnum.TRUE;
    this.roleService.query({
      id: this.searchModel.id.length > 0 ? Set.of(Number(this.searchModel.id)) : undefined,
      name: Strings.undefinedOrNonEmpty(this.searchModel.name),
      disabled: disabled,
      orders: Set.of(this.queryModel.getOrder()),
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined
    }).subscribe(
      (result: QueryResult<Role.Role>) => {
        this.roleList = result.items.toArray();
        this.queryModel.currentPage = requestedPage;
        this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
        this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
      });
  }

  onSearchClicked() {
    this.loadList();
  }

  onSearchReset() {
    this.roleSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.showSearch = true;
          this.loadList(1);
        });
      }
    );
  }

  private saveSearch() {
    const request = {
      searchData: {
        itemsPerPage: this.queryModel.itemsPerPage,
        pageNumber: this.queryModel.currentPage,
        order: this.queryModel.getOrder(),
        id: this.searchModel.id,
        name: this.searchModel.name,
        disabled: this.searchModel.disabled,
      }
    };
    this.roleSearchService.setSearchData(request).subscribe()
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  setDisabled(id: number, disabled: boolean) {
    this.roleService.setDisabled({
      id: id,
      disabled: disabled
    }).subscribe((result: EmptyMessage) => {
      this.loadList();
    });
  }

  exportRole(role: Role.Role) {
    this.roleService.exportRole({id: role.id}).subscribe(value => {
      const privi = JSON.stringify(value);
      const blob = new Blob([privi],
        {type: 'text/json;charset=utf-8'});
      saveAs(blob, 'role_' + role.name + '.json');
    });
  }

  ngOnDestroy() {
    this.saveSearch();
  }

  protected readonly DropdownItemType = DropdownItemType;
}

interface SearchLoadResult {
  storedSearchData: RoleSearch.SearchDataResult,
}
