/* eslint-disable */
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, NgModel, Validators } from '@angular/forms';
import { AppValidators } from '../../../util/app-validators';
import { Angular2Multiselects } from '../../../util/multiselect';
import { StringKey } from '../../../app.string-keys';
import { TranslateUtils } from '../../../util/translate';
import { OptionItem, UiConstants } from '../../../util/core-utils';
import { TranslateService } from '@ngx-translate/core';
import {
  TableDocumentSchema,
  TableDocumentSchemaField,
  TableDocumentSchemaFieldModel
} from '../../../lib/table-document-schema/table-document-schema.service';
import { TableDocumentSchemaFieldResource } from '../../../lib/table-document-schema/table-document-schema-field-resource.service';
import { LocalFieldValidationErrors, LocalFieldValidationErrorsFactory } from '../../../lib/util/services';
import { FieldError, FieldErrors } from '../../../lib/util/errors';
import { List } from 'immutable';
import TableDocumentSchemaFieldTypeState = TableDocumentSchemaField.TableDocumentSchemaFieldTypeState;
/* eslint-enable */

@Component({
  selector: 'app-table-document-schema-field-base',
  templateUrl: './table-document-schema-field-base.component.html',
  styleUrls: ['./table-document-schema-field-base.component.scss']
})
export class TableDocumentSchemaFieldBaseComponent implements OnInit {

  UiConstants = UiConstants;
  TableDocumentSchema = TableDocumentSchema;

  @Input()
  isRemovable: boolean = true;

  @Input()
  set model(model: TableDocumentSchemaFieldModel) {
    const cloneModel = {...model};
    this.m = {
      fieldId: cloneModel.fieldId,
      schemaFieldId: cloneModel.schemaFieldId,
      code: cloneModel.code,
      name: cloneModel.name,
      note: cloneModel.note,
      required: cloneModel.required,
      dataType: cloneModel.dataType,
      editing: cloneModel.editing
    };
    this.savedCode = cloneModel.code;
    this.savedName = cloneModel.name;
  };

  @Input()
  fieldList: TableDocumentSchemaFieldModel[] = [];

  @Output()
  remove: EventEmitter<undefined> = new EventEmitter();
  @Output()
  save: EventEmitter<TableDocumentSchemaFieldModel> = new EventEmitter<TableDocumentSchemaFieldModel>();
  @Output()
  cancel: EventEmitter<undefined> = new EventEmitter();

  @ViewChild('f', { static: true })
  fForm: NgForm;

  @ViewChild('code', { static: true })
  code: NgModel;

  @ViewChild('name', { static: true })
  name: NgModel;

  @ViewChild('type', { static: true })
  type: NgModel;

  m: TableDocumentSchemaFieldModel;

  formGroup: FormGroup;

  dropdownSettings: Angular2Multiselects.Settings;
  dataType: { id: TableDocumentSchemaFieldTypeState, text: string }[] = [];
  dataTypes: { id: TableDocumentSchemaFieldTypeState, text: string }[] = [];

  savedCode: string;
  savedName: string;
  uniqueCode: boolean = false;
  uniqueName: boolean = false;

  private validatedInputs: LocalFieldValidationErrors<NgModel> =
    LocalFieldValidationErrorsFactory.empty();
  fieldErrors: TableDocumentSchemaFieldErrorMap;

  constructor(
    private translateService: TranslateService,
    private formBuilder: FormBuilder
  ) {
    this.fieldErrors = {};
    this.formGroup = formBuilder.group({
      amount: [[], [Validators.required, AppValidators.validateOptionalPositiveNumber]],
    })
  }

  ngOnInit() {
    this.initDropdown();
    this.initDataTypes();
    this.loadLocalFieldValidationErrors();
  }

  initDropdown() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .labelKey(OptionItem.KEY_TEXT)
      .build();
  }

  initDataTypes() {
    const types: { id: TableDocumentSchemaFieldTypeState, text: string }[] = [

      {
        id: TableDocumentSchemaFieldResource.TableDocumentSchemaFieldTypeState.STRING,
        text: ''
      },
      {
        id: TableDocumentSchemaFieldResource.TableDocumentSchemaFieldTypeState.LONG,
        text: ''
      },
      {
        id: TableDocumentSchemaFieldResource.TableDocumentSchemaFieldTypeState.DOUBLE,
        text: ''
      },
      {
        id: TableDocumentSchemaFieldResource.TableDocumentSchemaFieldTypeState.BOOLEAN,
        text: ''
      },
      {
        id: TableDocumentSchemaFieldResource.TableDocumentSchemaFieldTypeState.LOCAL_DATE,
        text: ''
      },
      {
        id: TableDocumentSchemaFieldResource.TableDocumentSchemaFieldTypeState.LOCAL_DATE_TIME,
        text: ''
      }
    ];
    const keys: string[] = [];

    types.forEach(value => {
      keys.push(StringKey.TABLE_DOCUMENT_SCHEMA_FIELD_DATA_TYPE + value.id);
    });


    this.translateService.get(keys).subscribe((o) => {
      keys.forEach((value: string, index: number) => {
        types[index].text = TranslateUtils.extractValueFromObject(o, value)
      })
    });

    this.dataTypes = types;
    const selectedType = types.filter(value => {
      return value.id === this.m.dataType
    });

    this.dataType = selectedType;

  }


  saveRecord() {
    if (this.dataType.length !== 0) {
      this.uniqueCode = false;
      this.uniqueName = false;

      const filteredCodeList = this.fieldList.filter(
        value => value.code === this.m.code && value.code !== this.savedCode);

      const filteredNameList = this.fieldList.filter(
        value => value.name === this.m.name && value.name !== this.savedName);

      if (filteredCodeList.length !== 0) {
        this.uniqueCode = true;
      }
      if (filteredNameList.length !== 0) {
        this.uniqueName = true;
      }
      if (!this.uniqueCode && !this.uniqueName) {
        this.m.dataType = this.dataType[0].id;
        this.save.emit(this.m);
      }
    }
  }

  hasLocalFieldError(field?: NgModel): boolean {
    return this.validatedInputs.hasLocalError(field);
  }

  removeFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.fieldErrors, fieldError);
  }

  private loadLocalFieldValidationErrors() {
    const validatedInputs = List.of(
      this.code,
      this.name,
      this.type
    );
    this.validatedInputs = LocalFieldValidationErrorsFactory.ofFormFields(this.fForm, validatedInputs);
  }

}


interface TableDocumentSchemaFieldErrorMap {
  code?: FieldError;
  name?: FieldError;
  type?: FieldError;
}
