<div class="modal-content">
  <div class="modal-header">
    <p class="modal-title"><strong>{{'MASTERDATA_RECORD_PANEL_HEADING_CREATE' | translate}}</strong></p>
    <button type="button" class="close" (click)="closeDialog(false)" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <mat-dialog-content style="overflow:visible">
      <form (ngSubmit)="save()" #f="ngForm" id="form"
            [formGroup]="formGroup" novalidate>

        <div class="row form-group" [ngClass]="{ 'has-danger': hasLocalFieldError('masterData')}">
          <label class="col-form-label form-control-label col-md-4 detail-title required-field-label">
            {{'MASTERDATA_RECORD_CREATE_MASTER_DATA' | translate}}
          </label>
          <div class="col-md-8">
            <angular2-multiselect
              (onRemoteSearch)="onMasterDataSearch($event.target.value)"
              [(ngModel)]="model._masterData"
              [data]="masterDataList"
              [settings]="remoteDropdownSettings"
              [ngClass]="{ 'form-control-danger': hasLocalFieldError('masterData') }"
              id="masterData"
              name="masterData"
              formControlName="masterData"
              required>
            </angular2-multiselect>
            <div class="form-control-feedback"
                 *ngIf="hasLocalFieldError('masterData')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
            </div>
          </div>
        </div>

        <div class="row form-group"
             [ngClass]="{ 'has-danger': hasLocalFieldError('name') || hasFieldError(ValidatedField.NAME)}">
          <label
            class="col-form-label form-control-label col-md-4 detail-title"
            [class.required-field-label]="nameRequired">{{'COMMON_NAME' | translate}}</label>
          <div class="col-md-8">
            <div class="mw-100">
              <input type="text"
                     [(ngModel)]="model.name"
                     [ngClass]="{ 'form-control-danger': hasLocalFieldError('name') || hasFieldError(ValidatedField.NAME) }"
                     trim="blur"
                     validateOnBlur
                     class="form-control"
                     name="name"
                     id="name"
                     formControlName="name"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     (ngModelChange)="removeFieldError(ValidatedField.NAME)"
                     placeholder="{{'COMMON_NAME'|translate}}">
              <div class="form-control-feedback" *ngIf="hasLocalFieldError('name')">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
              <div class="form-control-feedback" *ngIf="hasFieldError(ValidatedField.NAME)">
                {{getFieldErrorText(ValidatedField.NAME)}}
              </div>
            </div>
          </div>
        </div>
        <div class="row form-group"
             [ngClass]="{ 'has-danger': hasLocalFieldError('externalId') || hasFieldError(ValidatedField.EXTERNAL_ID)}">
          <label
            class="col-form-label form-control-label col-md-4 detail-title"
            [class.required-field-label]="externalIdRequired">{{'COMMON_EXTERNAL_ID' | translate}}</label>
          <div class="col-md-8">
            <div class="mw-100">
              <input type="text"
                     [(ngModel)]="model.externalId"
                     [ngClass]="{ 'form-control-danger': hasLocalFieldError('externalId') || hasFieldError(ValidatedField.EXTERNAL_ID) }"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     trim="blur"
                     validateOnBlur
                     class="form-control"
                     name="externalId"
                     id="externalId"
                     formControlName="externalId"
                     (ngModelChange)="removeFieldError(ValidatedField.EXTERNAL_ID)"
                     placeholder="{{'COMMON_EXTERNAL_ID'|translate}}">
              <div class="form-control-feedback" *ngIf="hasLocalFieldError('externalId')">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
              <div class="form-control-feedback" *ngIf="hasFieldError(ValidatedField.EXTERNAL_ID)">
                {{getFieldErrorText(ValidatedField.EXTERNAL_ID)}}
              </div>
            </div>
          </div>
        </div>

        <div class="row form-group"
             [ngClass]="{ 'has-danger': hasLocalFieldError('description')}">
          <label
            class="col-form-label form-control-label col-md-4 detail-title"
            [class.required-field-label]="false">{{'COMMON_DESCRIPTION' | translate}}</label>
          <div class="col-md-8">
            <div class="mw-100">
              <input type="text"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     [(ngModel)]="model.description"
                     [ngClass]="{ 'form-control-danger': hasLocalFieldError('description') }"
                     trim="blur"
                     validateOnBlur
                     class="form-control"
                     name="description"
                     id="description"
                     formControlName="description"
                     placeholder="{{'COMMON_DESCRIPTION'|translate}}">
              <div class="form-control-feedback" *ngIf="hasLocalFieldError('description')">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
          </div>
        </div>

        <div class="row form-group">
          <label class="col-form-label form-control-label col-md-4 detail-title">
            {{'CUSTOMER_RECORD_USERS' | translate}}
          </label>
          <div class="col-md-8">
            <angular2-multiselect
              (onRemoteSearch)="onUserSearch($event.target.value)"
              [(ngModel)]="model._users"
              [data]="users"
              [settings]="multiRemoteDropdownSettings"
              id="user"
              name="user"
              formControlName="user">
            </angular2-multiselect>
          </div>
        </div>

        <div class="row form-group">
          <label class="col-form-label form-control-label col-md-4 detail-title">
            {{'CUSTOMER_RECORD_USER_GROUPS' | translate}}
          </label>
          <div class="col-md-8">
            <angular2-multiselect
              (onRemoteSearch)="onUserGroupSearch($event.target.value)"
              [(ngModel)]="model._userGroups"
              [data]="userGroups"
              [settings]="multiRemoteDropdownSettings"
              id="userGroup"
              name="userGroup"
              formControlName="userGroup">
            </angular2-multiselect>
          </div>
        </div>

        <div class="row form-group">
          <label class="col-form-label form-control-label col-md-4 detail-title">
            {{'MASTERDATA_RECORD_OWNER_CUSTOMER_RECORD' | translate}}
          </label>
          <div class="col-md-8">
            <angular2-multiselect
              (onRemoteSearch)="onCustomerRecordSearch($event.target.value)"
              [(ngModel)]="model._ownerCustomerRecord"
              (ngModelChange)="onCustomerRecordChanged()"
              [data]="customerRecords"
              [settings]="remoteDropdownSettings"
              [readonly]="data.customerRecordId"
              id="customerRecord"
              name="customerRecord"
              formControlName="customerRecord">
            </angular2-multiselect>
          </div>
        </div>

        <div class="row form-group">
          <label class="col-form-label form-control-label col-md-4 detail-title">
            {{'COMPANY_LOCATION' | translate}}
          </label>
          <div class="col-md-8">
            <angular2-multiselect
              (onRemoteSearch)="onCustomerContactLocationSearch($event.target.value)"
              [(ngModel)]="model._ownerContactLocation"
              [data]="customerRecordContactLocations"
              [settings]="remoteDropdownSettings"
              id="contactLocation"
              name="contactLocation"
              formControlName="contactLocation">
            </angular2-multiselect>
          </div>
        </div>
      </form>
    </mat-dialog-content>
  </div>

  <div class="modal-footer">
    <div>
      <button type="submit" class="btn btn-outline-primary" (click)="navigateToEdit()" form="form">
        {{'FORM_ITEM_CREATE_DOCUMENT_EXTRA_DATA' | translate}}
      </button>
    </div>
    <button type="button" class="btn btn-outline-primary" (click)="closeDialog(false)">
      {{'COMMON_BUTTON_CANCEL' | translate}}
    </button>
    <button type="submit" class="btn btn-primary" form="form">
      {{'COMMON_BUTTON_SAVE' | translate}}
    </button>
  </div>
</div>
