/* eslint-disable */
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, } from '@angular/core';
import { Transition, UIRouter } from '@uirouter/angular';
import { combineLatest, Observable, Subject } from 'rxjs';
import { TaskRecord, TaskRecordService } from '../../../../lib/task/task-record.service';
import { Task, TaskService } from '../../../../lib/task/task.service';
import { TaskRecordBase } from '../task-record-base/task-record-base-view';
import { TaskRecordBaseComponent } from '../task-record-base/task-record-base.component';
import { StateName } from '../../../../app.state-names';
import { FieldValidationError, QueryResult } from '../../../../lib/util/services';
import { FormRecordContainer } from '../../../form/form-record/form-record-container';
import { FormRecordContainerComponent } from '../../../form/form-record/form-record-container.component';
import { Form } from '../../../../lib/form/form.service';
import { NgForm } from '@angular/forms';
import { ConfigurationService } from '../../../../lib/core-ext/configuration.service';
import { LoadingHandler } from '../../../../lib/loading-handler';
import { NgbDatePickerParserFormatter } from '../../../../util/ngb-datepicker';
import { Country, CountryService } from '../../../../lib/country.service';
import { AddressModel } from '../../../../lib/address';
import { StringKey } from '../../../../app.string-keys';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '../../../../fork/angular2-toaster/angular2-toaster';
import { UiConstants } from '../../../../util/core-utils';
import { BreadcrumbParent } from '../../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmLeaveModalComponent } from '../../../../shared/confirm-leave-modal/confirm-leave-modal.component';
import { RefreshFormOnChangeService } from '../../../../lib/refresh-form-on-change.service';
import { RightResolver, RightService } from '../../../../lib/right.service';
import { RightModel } from '../../../../app.rights';
import { IdentityMessage } from '../../../../lib/util/messages';
import { TaskRecordFormFieldUpdater } from '../task-record-base/task-record-form-field-updater';
import { CustomerRecord } from '../../../../lib/customer/customer-record.service';
import { Strings } from '../../../../lib/util/strings';

/* eslint-enable */

@Component({
  selector: 'app-new-task-record-clone',
  templateUrl: 'task-record-clone.component.html',
  styleUrls: ['task-record-clone.component.scss'],
})
export class TaskRecordCloneComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(TaskRecordBaseComponent, {static: true})
  baseView: TaskRecordBase.View;

  @ViewChild(FormRecordContainerComponent, {static: true})
  formRecordContainerView: FormRecordContainer.View;

  @ViewChild('f', {static: true})
  fForm: NgForm;

  forceSaveButton: boolean = false;

  private taskId: number;
  private taskRecordId: number;
  taskName: string;
  taskRecordName: string;
  breadcrumbParents: BreadcrumbParent[] = [];
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  private combinedResult?: CombinedResult;

  deregisterWarningModal;
  saveButtonClicked: boolean = false;
  navigateOnSubmit: boolean = false;

  private formFieldUpdater: TaskRecordFormFieldUpdater;

  rightModel: RightModel = RightModel.empty();

  constructor(
    private toasterService: ToasterService,
    private translateService: TranslateService,
    private modalService: BsModalService,
    private configurationService: ConfigurationService,
    private taskService: TaskService,
    private taskRecordService: TaskRecordService,
    private uiRouter: UIRouter,
    private transition: Transition,
    private countryService: CountryService,
    private rightService: RightService,
    private ngbDatePickerParserFormatter: NgbDatePickerParserFormatter,
    private refreshFormOnChangeService: RefreshFormOnChangeService) {
    this.taskId = this.transition.params().taskId;
    this.taskRecordId = this.transition.params().taskRecordId;
  }

  ngOnInit() {
    const context = this;
    this.deregisterWarningModal = this.uiRouter.transitionService.onStart({}, function (transition) {
      if (context.saveButtonClicked
        || transition.targetState().name() === StateName.CONNECTION_ERROR
        || transition.targetState().name() === StateName.SERVER_ERROR
        || transition.targetState().name() === StateName.LOGIN) {
        return true;
      }
      else {
        const subject = new Subject<boolean>();
        const modal = context.modalService.show(ConfirmLeaveModalComponent, {backdrop: 'static', keyboard: false});
        modal.content!.subject = subject;
        return subject.asObservable().toPromise();
      }
    });
    this.refreshFormOnChangeService.formChanged.subscribe((result) => {
        this.onFormFieldsUpdated();
      }
    );
  }

  ngAfterViewInit(): void {
    this.loadRightModels(() => {
      this.createCombinedObservable().subscribe(
        (result: CombinedResult) => {
          this.taskName = result.task.name;
          this.taskRecordName = result.taskRecord.name;
          this.formRecordContainerView.loadFormRecord({
            configuration: this.configurationService.getConfiguration(),
            form: result.form,
            record: result.taskRecord.formRecord,
          });
          this.formFieldUpdater = new TaskRecordFormFieldUpdater(result.form, this.formRecordContainerView);
          result.taskRecord.externalId = undefined;
          this.baseView.loadTaskRecord({
            taskRecord: result.taskRecord,
            task: result.task,
            countryItems: AddressModel.CountryItem.fromCountryList(result.countries.items),
            rightModel: this.rightModel,
            isClone: true
          });
          this.combinedResult = result;
          this.forceSaveButton = !Form.Forms.hasVisibleGroup(result.form);
        },
        () => {
        },
        () => {
          this.translateService.get('MENU_NAVBAR_FORMS').subscribe(
            (result: string) => {
              this.breadcrumbParents.push({name: result, uiSref: StateName.TASK_DASHBOARD});
            }
          );
          this.breadcrumbParents.push({name: this.taskName, uiSref: StateName.TASK_RECORD_LIST, uiParam: {taskId: this.taskId}});
        }
      );
    });
  }

  private loadRightModels(completion: () => void) {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        completion();
      }
    );
  }

  createTaskRecord() {
    if (!this.combinedResult) {
      return;
    }
    if (this.baseView.hasLocalFieldError() || this.formRecordContainerView.hasLocalFieldError()) {
      this.showValidationErrorToast();
      return;
    }
    if (!this.baseView.validatePlaceOfConsumption()) {
      return;
    }
    if (this.formRecordContainerView.validateWithInterrupt()) {
      return;
    }
    const model = this.baseView.getModel();
    const formModel = this.formRecordContainerView.createModel();
    this.taskRecordService.create({
      withFormRecord: false,
      taskId: this.taskId,
      externalId: model.externalId,
      name: Strings.undefinedOrNonEmpty(model.name),
      description: model.description,
      importance: model.importance && model.importance.id ? model.importance.id : TaskRecord.DefImportance,
      ownerUserId: model.ownerUser && model.ownerUser.id ? model.ownerUser.id : undefined,
      customerId: model.customer && model.customer.id ? model.customer.id : undefined,
      billingInfoId: model.billingInfoId,
      contactLocationId: model.contactLocationId,
      projectId: model.project[0] && model.project[0].id ? model.project[0].id! : undefined,
      agreedTime: this.ngbDatePickerParserFormatter.toOffsetDateTime(model.agreedTimeDate, model.agreedTimeTime),
      deadline: this.ngbDatePickerParserFormatter.toOffsetDateTime(model.deadlineDate, model.deadlineTime),
      assignee: model.getServiceAssignee(),
      usergroupId: model.usergroup && model.usergroup.id ? model.usergroup.id : undefined,
      estimatedTimeInMinutes: model.estimatedTimeInMinutes,
      placeOfConsumption: model.getServicePoc(),
      linkedSurveyIds: model.linkedSurveyIds,
      contractNumberId: model.contractNumberId,
      formRecord: {
        fields: formModel.fields
      }
    }).subscribe(
      (result: IdentityMessage) => {
        LoadingHandler.getInstance().refresh();
        this.saveButtonClicked = true;
        if (this.navigateOnSubmit) {
          this.uiRouter.stateService.go(StateName.TASK_RECORD_LIST, {
            taskId: this.taskId,
          });
        }
        else {
          this.uiRouter.stateService.go(
            StateName.TASK_RECORD_EDIT, {taskId: this.taskId, taskRecordId: result.id}
          );
        }
      },
      (error) => {
        if (error instanceof FieldValidationError) {
          this.baseView.onFieldValidationError(error.withForm(this.fForm));
          this.showValidationErrorToast();
        }
      }
    );
  }

  private createCombinedObservable(): Observable<CombinedResult> {
    return combineLatest(
      this.taskService.get({
        taskId: this.taskId
      }),
      this.taskRecordService.get({
        withFormRecord: true,
        taskId: this.taskId,
        taskRecordId: this.taskRecordId,
        customerFields: TaskRecordBase.customerQueryFields
      }),
      this.countryService.query({}),
      (task: Task.Task,
       taskRecord: TaskRecord.TaskRecord,
       countries: QueryResult<Country.Country>) => {
        return {
          form: task.form!,
          task: task,
          taskRecord: taskRecord,
          countries: countries
        };
      }
    );
  }


  private showValidationErrorToast() {
    this.toasterService.pop({
      timeout: UiConstants.ToastTimeoutLong,
      type: UiConstants.toastTypeError,
      title: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_TITLE),
      body: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_MESSAGE)
    });
  }

  submit(navigateOnSubmit: boolean) {
    this.navigateOnSubmit = navigateOnSubmit;
  }

  ngOnDestroy() {
    this.deregisterWarningModal();
  }

  onFormFieldsUpdated() {
  }

  onCustomerRecordChanged() {
    this.formFieldUpdater.onCustomerRecordChanged(this.baseView.getModel().customer, this.baseView.getModel().contactLocationId);
  }

  onAssigneeUserChanged() {
    this.formFieldUpdater.onAssigneeUserChanged(this.baseView.getModel().assignee.user);
  }

  onSelectedAddressChanged() {
    this.formFieldUpdater.onContactLocationChanged(this.baseView.getModel().contactLocationId);
  }
}

interface CombinedResult {
  form: Form.Form;
  task: Task.Task;
  taskRecord: TaskRecord.TaskRecord;
  countries: QueryResult<Country.Country>;
}
