<div class="card">
  <div class="card-header">
    {{'HELPDESK_DASHBOARD_NOTIFICATIONS' | translate}}
  </div>
  <div class="card-body">
    <div *ngIf="list.length === 0" class="p-2 d-flex flex-column align-items-center justify-content-center text-muted">
      <i class="icomoon icomoon-helpdesk-notification font-4xl"></i>
      <div class="font-weight-bold font-xl">{{'HELPDESK_DASHBOARD_NO_NOTIFICATIONS' | translate}}</div>
    </div>
    <div *ngFor="let item of list" class="container"
         uiSref="Helpdesk.BugReportDetail" [uiParams]="{taskId: item.log.taskRecord.taskId, taskRecordId: item.log.taskRecord.id}">
      <div>
        <app-user-profile-picture
          class="align-self-center mr-1 float-left"
          [userPersonName]="item.log.user.personName"
          [profilePicture]="item.profilePicture"
          [size]="48">
        </app-user-profile-picture>
      </div>
      <div>
        <div class="notification-content" [ngSwitch]="item.log.logType">
          <span *ngSwitchCase="'CREATE_QUICK'"
                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_CREATE_QUICK' | translate: {param_name: item.log.user.personName, param_task_name: item.log.taskRecord.name}">
          </span>
          <span *ngSwitchCase="'CREATE_REGULAR'"
                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_CREATE_REGULAR' | translate: {param_name: item.log.user.personName, param_task_name: item.log.taskRecord.name}">
          </span>
          <span *ngSwitchCase="'CREATE_CLONE'"
                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_CREATE_CLONE' | translate: {param_name: item.log.user.personName, param_task_name: item.log.taskRecord.name}">
          </span>
          <span *ngSwitchCase="'CREATE_TRIGGER'"
                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_CREATE_TRIGGER' | translate: {param_name: item.log.user.personName, param_task_name: item.log.taskRecord.name}">
          </span>
          <span *ngSwitchCase="'CREATE_PROCESS'"
                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_CREATE_PROCESS' | translate: {param_name: item.log.user.personName, param_task_name: item.log.taskRecord.name}">
          </span>
          <span *ngSwitchCase="'CREATE_IMPORT'"
                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_CREATE_IMPORT' | translate: {param_name: item.log.user.personName, param_task_name: item.log.taskRecord.name}">
          </span>
          <span *ngSwitchCase="'CREATE_API_IMPORT'"
                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_CREATE_API_IMPORT' | translate: {param_name: item.log.user.personName, param_task_name: item.log.taskRecord.name}">
          </span>
          <span *ngSwitchCase="'UPDATE_API_IMPORT'"
                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_UPDATE_API_IMPORT' | translate: {param_name: item.log.user.personName, param_task_name: item.log.taskRecord.name}">
          </span>
          <span *ngSwitchCase="'CREATE_DOCUMENT_IMPORT'"
                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_CREATE_DOCUMENT_IMPORT' | translate: {param_name: item.log.user.personName, param_task_name: item.log.taskRecord.name}">
          </span>
          <span *ngSwitchCase="'UPDATE_DOCUMENT_IMPORT'"
                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_UPDATE_DOCUMENT_IMPORT' | translate: {param_name: item.log.user.personName, param_task_name: item.log.taskRecord.name}">
          </span>
          <span *ngSwitchCase="'STATE_OPEN'"
                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_STATE_OPEN' | translate: {param_name: item.log.user.personName, param_task_name: item.log.taskRecord.name}">
          </span>
          <span *ngSwitchCase="'STATE_REOPEN'"
                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_STATE_REOPEN' | translate: {param_name: item.log.user.personName, param_task_name: item.log.taskRecord.name}">
          </span>
          <span *ngSwitchCase="'STATE_ARCHIVE'"
                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_STATE_ARCHIVE' | translate: {param_name: item.log.user.personName, param_task_name: item.log.taskRecord.name}">
          </span>
          <span *ngSwitchCase="'STATE_RECALL'"
                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_STATE_RECALL' | translate: {param_name: item.log.user.personName, param_task_name: item.log.taskRecord.name}">
          </span>
          <span *ngSwitchCase="'STATE_REJECT'"
                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_STATE_REJECT' | translate: {param_name: item.log.user.personName, param_task_name: item.log.taskRecord.name}">
          </span>
          <span *ngSwitchCase="'STATE_START'"
                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_STATE_START' | translate: {param_name: item.log.user.personName, param_task_name: item.log.taskRecord.name}">
          </span>
          <span *ngSwitchCase="'STATE_PAUSE'"
                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_STATE_PAUSE' | translate: {param_name: item.log.user.personName, param_task_name: item.log.taskRecord.name}">
          </span>
          <span *ngSwitchCase="'STATE_RESUME'"
                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_STATE_RESUME' | translate: {param_name: item.log.user.personName, param_task_name: item.log.taskRecord.name}">
          </span>
          <span *ngSwitchCase="'STATE_SUBMIT'"
                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_STATE_SUBMIT' | translate: {param_name: item.log.user.personName, param_task_name: item.log.taskRecord.name}">
          </span>
          <span *ngSwitchCase="'STATE_UNSUBMIT'"
                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_STATE_UNSUBMIT' | translate: {param_name: item.log.user.personName, param_task_name: item.log.taskRecord.name}">
          </span>
          <span *ngSwitchCase="'STATE_FINISH'"
                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_STATE_FINISH' | translate: {param_name: item.log.user.personName, param_task_name: item.log.taskRecord.name}">
          </span>
          <span *ngSwitchCase="'STATE_VALIDATE'"
                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_STATE_VALIDATE' | translate: {param_name: item.log.user.personName, param_task_name: item.log.taskRecord.name}">
          </span>
          <span *ngSwitchCase="'STATE_INVALIDATE'"
                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_STATE_INVALIDATE' | translate: {param_name: item.log.user.personName, param_task_name: item.log.taskRecord.name}">
          </span>
          <span *ngSwitchCase="'STATE_RESTART'"
                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_STATE_RESTART' | translate: {param_name: item.log.user.personName, param_task_name: item.log.taskRecord.name}">
          </span>
          <span *ngSwitchCase="'ATTACHMENT_CREATE'"
                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_ATTACHMENT_CREATE' | translate: {param_name: item.log.user.personName, param_task_name: item.log.taskRecord.name}">
          </span>
          <span *ngSwitchCase="'ATTACHMENT_DELETE'"
                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_ATTACHMENT_DELETE' | translate: {param_name: item.log.user.personName, param_task_name: item.log.taskRecord.name}">
          </span>
          <span *ngSwitchCase="'EXPORT_SUCCEED'"
                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_EXPORT_SUCCEED' | translate: {param_name: item.log.user.personName, param_task_name: item.log.taskRecord.name}">
          </span>
          <span *ngSwitchCase="'EXPORT_FAILED'"
                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_EXPORT_FAILED' | translate: {param_name: item.log.user.personName, param_task_name: item.log.taskRecord.name}">
          </span>
          <span *ngSwitchCase="'REVERT_SUCCEED'"
                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_REVERT_SUCCEED' | translate: {param_name: item.log.user.personName, param_task_name: item.log.taskRecord.name}">
          </span>
          <span *ngSwitchCase="'REVERT_FAILED'"
                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_REVERT_FAILED' | translate: {param_name: item.log.user.personName, param_task_name: item.log.taskRecord.name}">
          </span>
          <span *ngSwitchCase="'ASSIGNEE_CHANGE'"
                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_ASSIGNEE_CHANGE' | translate: {param_name: item.log.user.personName, param_task_name: item.log.taskRecord.name}">
          </span>
          <span *ngSwitchCase="'ASSIGN_TO_ME'"
                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_ASSIGN_TO_ME' | translate: {param_name: item.log.user.personName, param_task_name: item.log.taskRecord.name}">
          </span>
        </div>
        <div class="notification-time">{{item.log.logTime.fromNow()}}</div>
      </div>
    </div>
  </div>
</div>
