import { ConfigurationResource } from '../../lib/core-ext/configuration.service';
import { GrantedPermissionSet } from '../../lib/right.service';
import { RightModel } from '../../app.rights';
import { StateName } from '../../app.state-names';
import { Subject } from 'rxjs';

export namespace SidebarNavItemUtils {

  export interface SidebarNavItemModel {
    adminFeatures?: ConfigurationResource.AdminFeature[];
    rights?: GrantedPermissionSet[];
    destination: string;
    icon: string;
    titleKey: string;
    states: string[];
    activeSubject: Subject<boolean>;
    id: string;
  }

  export function createAdminSidebarNavItems(rightModel: RightModel): SidebarNavItemModel[] {
    // WARNING: When you add/modify the menu options, update it in StartupComponent#goToStartPage too!
    return [
      {
        rights: [
          rightModel.webMenuDashboard
        ],
        destination: StateName.HOME,
        icon: 'icomoon-sidebar-dashboard',
        titleKey: 'MENU_DASHBOARD',
        states: [
          StateName.STARTUP
        ],
        activeSubject: new Subject<boolean>(),
        id: 'sidebar-nav-item-home'
      },
      {
        rights: [
          rightModel.webMenuAdmin
        ],
        destination: StateName.ADMIN_DASHBOARD,
        icon: 'icomoon-sidebar-administration',
        titleKey: 'MENU_NAVBAR_MENU_ADMINISTRATION',
        states: [
          StateName.ADMIN_DASHBOARD,
          StateName.USER_LIST,
          StateName.USER_CREATE,
          StateName.USER_EDIT,
          StateName.USER_DETAIL,
          StateName.USER_GROUP_LIST,
          StateName.USER_GROUP_CREATE,
          StateName.USER_GROUP_EDIT,
          StateName.USER_GROUP_CLONE,
          StateName.SURVEY_LIST,
          StateName.SURVEY_CREATE,
          StateName.SURVEY_EDIT,
          StateName.FORM_TABLE_LIST,
          StateName.FORM_TABLE_CREATE,
          StateName.FORM_TABLE_EDIT,
          StateName.DOCUMENT_GROUP_LIST,
          StateName.DOCUMENT_GROUP_CREATE,
          StateName.DOCUMENT_GROUP_EDIT,
          StateName.APPLICATION_DEVICE_MANAGEMENT_LIST,
          StateName.APPLICATION_DICTIONARY_LIST,
          StateName.STOCK_ITEM_LIST,
          StateName.STOCK_ITEM_CREATE,
          StateName.STOCK_ITEM_EDIT,
          StateName.STOCK_ITEM_DETAIL,
          StateName.INVOICE_BOOK_LIST,
          StateName.INVOICE_BOOK_CREATE,
          StateName.INVOICE_BOOK_EDIT,
          StateName.INVOICE_SETTINGS_LIST,
          StateName.INVOICE_SETTINGS_CREATE,
          StateName.INVOICE_SETTINGS_EDIT,
          StateName.INVOICE_SETTINGS_DETAIL,
          StateName.LEDGER_NUMBER_LIST,
          StateName.INVOICE_TAG_LIST,
          StateName.CUSTOMER_LIST,
          StateName.CUSTOMER_CREATE,
          StateName.CUSTOMER_EDIT,
          StateName.TASK_LIST,
          StateName.TASK_CREATE,
          StateName.TASK_EDIT,
          StateName.TASK_TRIGGER_DETAIL,
          StateName.MASTERDATA_LIST,
          StateName.MASTERDATA_CREATE,
          StateName.MASTERDATA_EDIT,
          StateName.AUDIT_LOG_LIST,
          StateName.AUDIT_LOG_DETAIL,
          StateName.TASK_LOG_LIST,
          StateName.LIST_ITEM_LIST,
          StateName.LIST_ITEM_CREATE,
          StateName.LIST_ITEM_EDIT,
          StateName.LIST_ITEM_TYPE_LIST,
          StateName.LIST_ITEM_TYPE_CREATE,
          StateName.LIST_ITEM_TYPE_EDIT,
          StateName.TASK_STATISTICS_LIST,
          StateName.TASK_STATISTICS_DETAIL,
          StateName.INVOICE_STATISTICS_LIST,
          StateName.LEGACY_WORKFLOW_LIST,
          StateName.LEGACY_WORKFLOW_CREATE,
          StateName.LEGACY_WORKFLOW_EDIT,
          StateName.LEGACY_WORKFLOW_TASK_EDIT,
          StateName.WORKFLOW_LIST,
          StateName.WORKFLOW_EDIT,
          StateName.WORKFLOW_EDIT,
          StateName.WORKFLOW_CREATE_EDITOR,
          StateName.STOCK_CHANGE_LIST,
          StateName.STOCK_CHANGE_DETAIL,
          StateName.COMPANY_LIST,
          StateName.COMPANY_CREATE,
          StateName.COMPANY_EDIT,
          StateName.COMPANY_DETAIL,
          StateName.VEHICLE_LIST,
          StateName.VEHICLE_CREATE,
          StateName.VEHICLE_EDIT,
          StateName.VEHICLE_DETAIL,
          StateName.ROLE_LIST,
          StateName.ROLE_CREATE,
          StateName.ROLE_EDIT,
          StateName.COMPANY_LOCATION_LIST,
          StateName.COMPANY_LOCATION_CREATE,
          StateName.COMPANY_LOCATION_EDIT,
          StateName.COMPANY_LOCATION_DETAIL,
          StateName.COMPANY_STOCK_LIST,
          StateName.COMPANY_STOCK_CREATE,
          StateName.COMPANY_STOCK_EDIT,
          StateName.COMPANY_STOCK_DETAIL,
          StateName.STOCK_ITEM_CATEGORY_LIST,
          StateName.STOCK_ITEM_CATEGORY_EDIT,
          StateName.STOCK_ITEM_CATEGORY_CREATE,
          StateName.TABLE_DOCUMENT_SCHEMA_LIST,
          StateName.TABLE_DOCUMENT_LIST,
          StateName.GENERAL_PDF_TEMPLATE,
          StateName.EMAIL_TEMPLATE_LIST,
          StateName.EMAIL_TEMPLATE_CREATE,
          StateName.EMAIL_TEMPLATE_EDIT,
          StateName.EMAIL_TEMPLATE_DETAIL,
          StateName.TABLE_DOCUMENT_SCHEMA_LIST,
          StateName.APPLICATION_FILE_LIST,
          StateName.ENVIRONMENT_CONFIG,
          StateName.REGISTRATION_SETTINGS,
          StateName.NOTIFICATION_TEMPLATE_LIST,
          StateName.NOTIFICATION_TEMPLATE_CREATE,
          StateName.NOTIFICATION_TEMPLATE_DETAIL,
          StateName.NOTIFICATION_TEMPLATE_EDIT,
          StateName.NOTIFICATION_LIST,
          StateName.NOTIFICATION_READ,
          StateName.NOTIFICATION_CREATE,
          StateName.GENERAL_PRINTER_TEMPLATE_LIST,
          StateName.TRANSPORT_TRIGGER_LIST,
          StateName.SHOP_RENTER_SHOP_LIST,
          StateName.SHOP_RENTER_SHOP_EDIT,
          StateName.SHOP_RENTER_SHOP_DETAIL,
          StateName.WORKLOG_LIST,
          StateName.MILEAGE_RECORD_LIST,
          StateName.MILEAGE_RECORD_DETAIL,
          StateName.ORDER_IMPORT_ERROR_LIST,
          StateName.ORDER_IMPORT_ERROR_DETAIL,
          StateName.PARCEL_COLLECTION_POINT_LIST,
          StateName.EMAIL_ADDRESS_TYPE_LIST,
          StateName.EMAIL_ADDRESS_TYPE_CREATE,
          StateName.EMAIL_ADDRESS_TYPE_EDIT,
          StateName.EMAIL_ADDRESS_TYPE_DETAIL,
          StateName.ANDROID_APP_VERSION,
          StateName.PROJECT_LIST,
          StateName.PROJECT_CREATE,
          StateName.PROJECT_EDIT,
          StateName.PROJECT_DETAIL
        ],
        activeSubject: new Subject<boolean>(),
        id: 'sidebar-nav-item-admin-dashboard'
      },
      {
        adminFeatures: ['TASKS'],
        rights: [
          rightModel.webMenuTaskRecords,
          rightModel.taskRead,
          rightModel.taskRecordRead
        ],
        destination: StateName.TASK_DASHBOARD,
        icon: 'icomoon-sidebar-tasks',
        titleKey: 'MENU_NAVBAR_FORMS',
        states: [
          StateName.TASK_DASHBOARD,
          StateName.TASK_RECORD_LIST,
          StateName.TASK_RECORD_GLOBAL_LIST,
          StateName.TASK_RECORD_CREATE,
          StateName.TASK_RECORD_DETAIL,
          StateName.TASK_RECORD_EDIT,
          StateName.TASK_RECORD_CLONE
        ],
        activeSubject: new Subject<boolean>(),
        id: 'sidebar-nav-item-task-record'
      },
      {
        adminFeatures: ['TASKS'],
        rights: [
          rightModel.webMenuTaskRecordBoard,
          rightModel.taskRead,
          rightModel.taskRecordRead
        ],
        destination: StateName.TASK_RECORD_BOARD,
        icon: 'icomoon-task-board',
        titleKey: 'MENU_NAVBAR_TASK_RECORD_BOARD',
        states: [
          StateName.TASK_RECORD_BOARD,
        ],
        activeSubject: new Subject<boolean>(),
        id: 'sidebar-nav-item-task-record-board'
      },
      {
        adminFeatures: ['CUSTOMERS'],
        rights: [
          rightModel.webMenuCustomerRecords,
          rightModel.customerRead,
          rightModel.customerRecordRead
        ],
        destination: StateName.CUSTOMER_DASHBOARD,
        icon: 'icomoon-sidebar-customers',
        titleKey: 'COMMON_CUSTOMERS',
        states: [
          StateName.CUSTOMER_DASHBOARD,
          StateName.CUSTOMER_RECORD_LIST,
          StateName.CUSTOMER_RECORD_GLOBAL_LIST,
          StateName.CUSTOMER_RECORD_CREATE,
          StateName.CUSTOMER_RECORD_CLONE,
          StateName.CUSTOMER_RECORD_EDIT,
          StateName.CUSTOMER_RECORD_DETAIL
        ],
        activeSubject: new Subject<boolean>(),
        id: 'sidebar-nav-item-customer-record'
      },
      {
        adminFeatures: ['MASTER_DATA'],
        rights: [
          rightModel.webMenuMasterdataRecords,
          rightModel.masterDataRecordRead,
          rightModel.masterDataRead
        ],
        destination: StateName.MASTERDATA_DASHBOARD,
        icon: 'icomoon-sidebar-masterdata',
        titleKey: 'MENU_NAVBAR_MASTERDATA_RECORD',
        states: [
          StateName.MASTERDATA_RECORD_LIST,
          StateName.MASTERDATA_RECORD_CREATE,
          StateName.MASTERDATA_RECORD_EDIT,
          StateName.MASTERDATA_RECORD_DETAIL,
          StateName.MASTERDATA_RECORD_CLONE,
          StateName.MASTERDATA_DASHBOARD
        ],
        activeSubject: new Subject<boolean>(),
        id: 'sidebar-nav-item-master-data-record'
      },
      {
        adminFeatures: ['SURVEY'],
        rights: [
          rightModel.webMenuSurveyRecords,
          rightModel.surveyRead,
          rightModel.surveyRecordRead
        ],
        destination: StateName.SURVEY_DASHBOARD,
        icon: 'icomoon-sidebar-projects',
        titleKey: 'MENU_NAVBAR_SURVEY_RECORD',
        states: [
          StateName.SURVEY_DASHBOARD,
          StateName.SURVEY_RECORD_LIST,
          StateName.SURVEY_RECORD_CREATE,
          StateName.FORM_TABLE_EDIT,
          StateName.SURVEY_RECORD_DETAIL,
          StateName.SURVEY_RECORD_CLONE
        ],
        activeSubject: new Subject<boolean>(),
        id: 'sidebar-nav-item-survey-record'
      },
      {
        adminFeatures: ['TASKS'],
        rights: [
          rightModel.webMenuTaskRecords,
          rightModel.taskRecordChatRead
        ],
        destination: StateName.MESSAGE_LISTS,
        icon: 'icomoon-sidebar-messages',
        titleKey: 'MENU_NAVBAR_MESSAGES',
        states: [
          StateName.MESSAGE_LISTS,
        ],
        activeSubject: new Subject<boolean>(),
        id: 'sidebar-nav-item-task-record-messages'
      },
      {
        adminFeatures: ['INVOICE'],
        rights: [
          rightModel.webMenuInvoices,
          rightModel.invoiceRead
        ],
        destination: StateName.INVOICE_LIST,
        icon: 'icomoon-invoice',
        titleKey: 'MENU_NAVBAR_INVOICES',
        states: [
          StateName.INVOICE_LIST,
          StateName.INVOICE_CREATE
        ],
        activeSubject: new Subject<boolean>(),
        id: 'sidebar-nav-item-invoice'
      },
      {
        adminFeatures: ['INVOICE'],
        rights: [
          rightModel.webMenuInvoiceSpendings,
          rightModel.invoiceSpendingRead
        ],
        destination: StateName.INVOICE_SPENDING_LIST,
        icon: 'icomoon-insurance-payment-payroll',
        titleKey: 'MENU_NAVBAR_INVOICE_SPENDINGS',
        states: [
          StateName.INVOICE_SPENDING_LIST
        ],
        activeSubject: new Subject<boolean>(),
        id: 'sidebar-nav-item-invoice'
      },
      {
        adminFeatures: ['CALENDAR'],
        rights: [
          rightModel.calendarEventRead,
          rightModel.webMenuCalendar
        ],
        destination: StateName.CALENDAR,
        icon: 'icomoon-calendar',
        titleKey: 'COMMON_CALENDAR',
        states: [
          StateName.CALENDAR
        ],
        activeSubject: new Subject<boolean>(),
        id: 'sidebar-nav-item-calendar'
      },
      {
        adminFeatures: ['CALENDAR'],
        rights: [
          rightModel.globalCalendarEventRead,
          rightModel.webMenuGlobalCalendar
        ],
        destination: StateName.GLOBAL_CALENDAR,
        icon: 'icomoon-calendar',
        titleKey: 'GLOBAL_CALENDAR',
        states: [
          StateName.GLOBAL_CALENDAR
        ],
        activeSubject: new Subject<boolean>(),
        id: 'sidebar-nav-item-global-calendar'
      },
      {
        adminFeatures: ['PROJECTS'],
        rights: [
          rightModel.webMenuProjects,
          rightModel.projectRead,
          rightModel.projectRecordRead
        ],
        destination: StateName.PROJECT_DASHBOARD,
        icon: 'icomoon-sidebar-projects',
        titleKey: 'MENU_NAVBAR_PROJECT_RECORD',
        states: [
          StateName.PROJECT_DASHBOARD,
          StateName.PROJECT_RECORD_LIST,
          StateName.PROJECT_RECORD_CREATE,
          StateName.PROJECT_RECORD_EDIT,
          StateName.PROJECT_RECORD_DETAIL
        ],
        activeSubject: new Subject<boolean>(),
        id: 'sidebar-nav-item-project'
      },
      {
        adminFeatures: ['ORDER'],
        rights: [
          rightModel.webMenuOrders,
          rightModel.orderRead
        ],
        destination: StateName.ORDER_LIST,
        icon: 'icomoon-order',
        titleKey: 'MENU_NAVBAR_ORDERS',
        states: [
          StateName.ORDER_LIST,
          StateName.ORDER_EDIT,
          StateName.ORDER_DETAIL_WITH_PROCESS_EDIT,
          StateName.ORDER_DETAIL_WITH_PROCESS_DETAIL,
          StateName.ORDER_DETAIL
        ],
        activeSubject: new Subject<boolean>(),
        id: 'sidebar-nav-item-order'
      },
      {
        adminFeatures: ['LEGACY_WORKFLOW'],
        rights: [
          rightModel.webMenuLegacyProcesses,
          rightModel.legacyProcessRead
        ],
        destination: StateName.LEGACY_PROCESS_LIST,
        icon: 'icomoon-process',
        titleKey: 'MENU_NAVBAR_LEGACY_PROCESSES',
        states: [
          StateName.LEGACY_PROCESS_LIST,
          StateName.LEGACY_PROCESS_EDIT,
          StateName.LEGACY_PROCESS_DETAIL,
          StateName.LEGACY_PROCESS_TASK_EDIT,
          StateName.LEGACY_PROCESS_TASK_DETAIL
        ],
        activeSubject: new Subject<boolean>(),
        id: 'sidebar-nav-item-legacy-process'
      },
      {
        adminFeatures: ['WORKFLOW'],
        rights: [
          rightModel.webMenuProcesses,
          rightModel.processRead
        ],
        destination: StateName.PROCESS_LIST,
        icon: 'icomoon-process',
        titleKey: 'MENU_NAVBAR_PROCESSES',
        states: [
          StateName.PROCESS_LIST,
          StateName.PROCESS_DETAIL
        ],
        activeSubject: new Subject<boolean>(),
        id: 'sidebar-nav-item-process'
      },
      {
        adminFeatures: ['STOCK'],
        rights: [
          rightModel.webMenuStocks,
          rightModel.stockRead
        ],
        destination: StateName.STOCK_LIST,
        icon: 'icomoon-sidebar-stock',
        titleKey: 'MENU_NAVBAR_STOCK',
        states: [
          StateName.STOCK_LIST,
          StateName.STOCK_CREATE,
          StateName.STOCK_EDIT,
          StateName.STOCK_LOG_LIST,
          StateName.STOCK_EDIT_WITH_STOCK_ITEM_DETAIL
        ],
        activeSubject: new Subject<boolean>(),
        id: 'sidebar-nav-item-stock'
      },
      {
        adminFeatures: ['STOCK', 'STOCK_TAKING'],
        rights: [
          rightModel.webMenuStockTakings,
          rightModel.stockTakingRead
        ],
        destination: StateName.STOCK_TAKINGS_LIST,
        icon: 'icomoon-stock-takings',
        titleKey: 'MENU_NAVBAR_STOCK_TAKINGS',
        states: [
          StateName.STOCK_TAKINGS_LIST,
          StateName.STOCK_TAKINGS_EDIT,
          StateName.STOCK_TAKINGS_DETAIL,
          StateName.STOCK_TAKING_SHEET_DETAIL
        ],
        activeSubject: new Subject<boolean>(),
        id: 'sidebar-nav-item-stock-tacking'
      },
      {
        adminFeatures: ['COMPANY', 'SHIPPING_DEMAND'],
        rights: [
          rightModel.webMenuShippingDemands,
          rightModel.shippingDemandRead.hasRight() ? rightModel.shippingDemandRead : rightModel.shippingDemandReadSimple
        ],
        destination: StateName.SHIPPING_DEMAND_LIST,
        icon: 'icomoon-shipment-demand',
        titleKey: 'MENU_NAVBAR_MENU_SHIPPING_DEMAND',
        states: [
          StateName.SHIPPING_DEMAND_LIST,
          StateName.SHIPPING_DEMAND_CREATE,
          StateName.SHIPPING_DEMAND_CLONE,
          StateName.SHIPPING_DEMAND_EDIT,
          StateName.SHIPPING_DEMAND_DETAIL
        ],
        activeSubject: new Subject<boolean>(),
        id: 'sidebar-nav-item-shippnig-demand'
      },
      {
        adminFeatures: ['COMPANY', 'TRANSPORT', 'EXTERIOR_TRANSPORT'],
        rights: [
          rightModel.webMenuShipments,
          rightModel.shipmentGroupRead
        ],
        destination: StateName.SHIPMENT_LIST,
        icon: 'icomoon-process-order-packaging',
        titleKey: 'MENU_NAVBAR_MENU_SHIPMENTS',
        states: [
          StateName.SHIPMENT_LIST,
          StateName.SHIPMENT_EDIT,
          StateName.SHIPMENT_DETAIL
        ],
        activeSubject: new Subject<boolean>(),
        id: 'sidebar-nav-item-shipment'
      },
      {
        adminFeatures: ['COMPANY', 'TRANSPORT'],
        rights: [
          rightModel.webMenuTransports,
          rightModel.transportRead
        ],
        destination: StateName.TRANSPORT_LIST,
        icon: 'icomoon-vehicles',
        titleKey: 'MENU_NAVBAR_MENU_TRANSPORT',
        states: [
          StateName.TRANSPORT_LIST,
          StateName.TRANSPORT_DETAIL,
          StateName.TRANSPORT_DETAIL_TAB
        ],
        activeSubject: new Subject<boolean>(),
        id: 'sidebar-nav-item-transport'
      },
      {
        adminFeatures: ['COMPANY', 'EXTERIOR_TRANSPORT'],
        rights: [
          rightModel.webMenuExteriorTransports,
          rightModel.exteriorTransportRead
        ],
        destination: StateName.EXTERIOR_TRANSPORT_LIST,
        icon: 'icomoon-exterior-transport',
        titleKey: 'MENU_NAVBAR_MENU_EXTERIOR_TRANSPORT',
        states: [
          StateName.EXTERIOR_TRANSPORT_LIST
        ],
        activeSubject: new Subject<boolean>(),
        id: 'sidebar-nav-item-exterior-transport'
      },
      {
        adminFeatures: ['CRM'],
        rights: [
          rightModel.webMenuNotes,
          rightModel.noteRead
        ],
        destination: StateName.NOTE_LIST,
        icon: 'icomoon-sidebar-notes',
        titleKey: 'MENU_NAVBAR_NOTES',
        states: [
          StateName.NOTE_LIST,
          StateName.NOTE_DETAIL
        ],
        activeSubject: new Subject<boolean>(),
        id: 'sidebar-nav-item-crm'
      },
      {
        adminFeatures: ['DOCUMENTS'],
        rights: [
          rightModel.webMenuDocuments,
          rightModel.documentRead
        ],
        destination: StateName.DOCUMENT_DASHBOARD,
        icon: 'icomoon-documents',
        titleKey: 'COMMON_DOCUMENTS',
        states: [
          StateName.DOCUMENT_FILE_LIST,
          StateName.DOCUMENT_LINK_LIST,
          StateName.DOCUMENT_FILE_CREATE,
          StateName.DOCUMENT_FILE_READ,
          StateName.DOCUMENT_FILE_EDIT,
          StateName.DOCUMENT_LINK_CREATE,
          StateName.DOCUMENT_LINK_EDIT,
          StateName.DOCUMENT_LINK_READ,
          StateName.DOCUMENT_DASHBOARD
        ],
        activeSubject: new Subject<boolean>(),
        id: 'sidebar-nav-item-document'
      },
      {
        adminFeatures: ['DOCUMENTS'],
        rights: [
          rightModel.webMenuDocumentLibrary,
          rightModel.documentRead,
          rightModel.documentGroupRead,
        ],
        destination: StateName.DOCUMENT_LIBRARY,
        icon: 'icomoon-documents',
        titleKey: 'MENU_NAVBAR_DOCUMENT_LIBRARY',
        states: [
          StateName.DOCUMENT_LIBRARY
        ],
        activeSubject: new Subject<boolean>(),
        id: 'sidebar-nav-item-document'
      },
      {
        adminFeatures: ['COLONNADE_INSURANCE_1'],
        rights: [
          rightModel.clientDocumentRead.hasRight() ? rightModel.clientDocumentRead : rightModel.colonnadeB2bRead
        ],
        destination: StateName.COLONNADE_INSURANCE_LIST,
        icon: 'icomoon-insurance',
        titleKey: 'INSURANCE_LIST',
        states: [
          StateName.COLONNADE_INSURANCE_LIST
        ],
        activeSubject: new Subject<boolean>(),
        id: 'sidebar-nav-item-insurance'
      },
    ];
  }

  export function createHelpdeskSidebarNavItems(rightModel: RightModel): SidebarNavItemModel[] {
    return [
      {
        rights: [
          // nothing?
        ],
        destination: StateName.HELPDESK_DASHBOARD,
        icon: 'icomoon-helpdesk-dashboard',
        titleKey: 'MENU_DASHBOARD',
        states: [
          StateName.HELPDESK_DASHBOARD
        ],
        activeSubject: new Subject<boolean>(),
        id: 'helpdesk-sidebar-nav-item-home'
      },
      {
        rights: [
          rightModel.taskRecordRead
        ],
        destination: StateName.HELPDESK_BUG_REPORT_LIST,
        icon: 'icomoon-helpdesk-bug-list',
        titleKey: 'HELPDESK_BUG_REPORT_LIST',
        states: [
          StateName.HELPDESK_BUG_REPORT_LIST,
          StateName.HELPDESK_BUG_REPORT_CREATE,
          StateName.HELPDESK_BUG_REPORT_EDIT,
          StateName.HELPDESK_BUG_REPORT_DETAIL,
        ],
        activeSubject: new Subject<boolean>(),
        id: 'helpdesk-sidebar-nav-item-bug-report-list'
      },
    ];
  }

}
