<ng-container *ngIf="anyChildVisible()">
  <div class="breadcrumb-button-icon-container-no-text cursor-pointer p-0">
    <a class="dropdown dropdown-arrow-padding"
       dropdown title="{{titleStringKey | translate}}"
       href="#" onclick="return false" dropdownToggle
       data-toggle="dropdown" role="button"
       aria-haspopup="true" aria-expanded="false">
      <i class="icomoon icomoon-dropdown">
      </i>
      <div class="dropdown-menu dropdown-menu-right border-bottom-0"
           *dropdownMenu aria-labelledby="simple-dropdown">
        <ng-content></ng-content>
      </div>
    </a>
  </div>
</ng-container>
