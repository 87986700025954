/* eslint-disable */
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { StateName } from '../../../app.state-names';
import { ComponentStateResolver } from '../../../util/component-state/component-state-resolver';
import { Transition, UIRouter } from '@uirouter/angular';
import { TranslateService } from '@ngx-translate/core';
import { NgForm, NgModel } from '@angular/forms';
import { FieldError } from '../../../lib/util/errors';
import {
  FieldValidationError,
  LocalFieldValidationErrors,
  LocalFieldValidationErrorsFactory,
  OrderType
} from '../../../lib/util/services';
import {
  StockItemCategory,
  StockItemCategoryService
} from '../../../lib/stock-item-category/stock-item-category.service';
import { Strings } from '../../../lib/util/strings';
import { List, Set } from 'immutable';
import { MultiselectOptionItem, UiConstants } from '../../../util/core-utils';
import { EmptyMessage, IdentityMessage } from '../../../lib/util/messages';
import { Angular2Multiselects } from '../../../util/multiselect';
import OrderField = StockItemCategory.OrderField;
import { forkJoin, Observable } from 'rxjs';
import { RightModel } from '../../../app.rights';
import { RightResolver, RightService } from '../../../lib/right.service';
/* eslint-enable */

@Component({
  selector: 'app-stock-item-category-base',
  templateUrl: './stock-item-category-base.component.html',
  styleUrls: ['./stock-item-category-base.component.scss']
})
export class StockItemCategoryBaseComponent implements OnInit, AfterViewInit {

  StockItemCategory = StockItemCategory;
  UiConstants = UiConstants;

  @ViewChild('f', { static: true })
  fForm: NgForm;

  // Validated inputs
  @ViewChild('name')
  name: NgModel;

  @ViewChild('externalId')
  externalId: NgModel;

  @ViewChild('parentCategory')
  parentCategory: NgModel;


  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;

  componentState: ComponentStateResolver;

  model: StockItemCategoryModel = new StockItemCategoryModel();
  rightModel: RightModel = RightModel.empty();

  categories: CategoryItem[] = [];

  parentDropdownSettings: Angular2Multiselects.Settings;
  childDropdownSettings: Angular2Multiselects.Settings;

  // Field errors for server validation
  fieldErrors: FieldValidationError<StockItemCategory.ValidatedField> =
    FieldValidationError.empty<StockItemCategory.ValidatedField>();

  private validatedInputs: LocalFieldValidationErrors<NgModel> =
    LocalFieldValidationErrorsFactory.empty();

  constructor(
    private uiRouter: UIRouter,
    private transition: Transition,
    private translateService: TranslateService,
    private stockItemCategoryService: StockItemCategoryService,
    private rightService: RightService
  ) {
    this.componentState = new ComponentStateResolver(uiRouter, transition,
      'id',
      {stateName: StateName.STOCK_ITEM_CATEGORY_CREATE, stateHeaderKey: 'STOCK_ITEM_CATEGORY_CREATE'},
      {stateName: StateName.STOCK_ITEM_CATEGORY_EDIT, stateHeaderKey: 'STOCK_ITEM_CATEGORY_EDIT'},
      {stateName: StateName.STOCK_ITEM_CATEGORY_DETAIL, stateHeaderKey: 'STOCK_ITEM_CATEGORY_DETAIL'})
  }

  ngOnInit() {
    this.initComponentState(); // Must be called first
    this.initBreadcrumb();
    this.initDropdownSettings();
    this.loadRightModels();
  }

  ngAfterViewInit(): void {
    this.loadLocalFieldValidationErrors();
  }

  private initComponentState() {
    // Creates the editModel if not readonly
    if (this.componentState.id) {
      this.loadModel();
    }
    else {
      this.loadCategories();
    }
  }

  initBreadcrumb() {
    // Set breadcrumbSelf if createView, otherwise set in loadModel()
    if (this.componentState.isCreateView()) {
      this.translateService.get('STOCK_ITEM_CATEGORY_CREATE').subscribe(
        (result: string) => {
          this.breadcrumbSelf = result;
        }
      );
    }
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('MENU_NAVBAR_STOCK_ITEM_CATEGORY_MANAGEMENT').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.STOCK_ITEM_CATEGORY_LIST});
      }
    );
  }

  initDropdownSettings() {
    this.parentDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
    this.childDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  // Called by the init method first
  private loadModel() {
    this.stockItemCategoryService.get({
      id: this.componentState.id!
    }).subscribe((category: StockItemCategory.StockItemCategory) => {
      this.breadcrumbSelf = category.name;
      this.model.name = category.name;
      this.model.externalId = category.externalId;
      this.model.mainCategory = !category.parentId;
      this.loadCategories(undefined, category.parentId);
      this.loadChildCategories();
    });
  }

  loadCategories(q?: string, parentId?: number) {
    this.stockItemCategoryService.query({
      name: q ? Strings.undefinedOrNonEmpty(q) : undefined,
      orders: Set.of({field: OrderField.NAME, type: OrderType.ASC}),
      paging: {
        pageNumber: 1,
        numberOfItems: UiConstants.autocompletePageSize
      },
      noProgressBar: true
    }).subscribe(result => {
      this.categories = result.items.toArray().map(c => ({id: c.id, itemName: c.name, externalId: c.externalId}));
      if (this.componentState.id) {
        const self = this.categories.findIndex(c => c.id === +this.componentState.id!);
        if (self > -1) {
          this.categories.splice(self, 1);
        }
      }
      if (parentId) {
        const cat = this.categories.find(c => c.id === parentId);
        if (cat) {
          this.model.parentCategory.push(cat);
          if (this.parentCategory) {
            this.parentCategory.control.updateValueAndValidity();
          }
        }
        else {
          this.stockItemCategoryService.get({id: parentId}).subscribe(result => {
            this.model.parentCategory.push({id: result.id, itemName: result.name, externalId: result.externalId});
            if (this.parentCategory) {
              this.parentCategory.control.updateValueAndValidity();
            }
          });
        }
      }
    });
  }

  private loadChildCategories() {
    this.stockItemCategoryService.query({
      parentId: this.componentState.id
    }).subscribe(result => {
      this.model.childCategories = result.items.toArray().map(c => ({id: c.id, itemName: c.name, externalId: c.externalId}));
    });
  }

  getHeadingDictionaryKey(): string {
    return this.componentState.getCurrentHeaderKey();
  }

  hasLocalFieldError(field?: NgModel): boolean {
    return this.validatedInputs.hasLocalError(field);
  }

  hasFieldError(field: StockItemCategory.ValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: StockItemCategory.ValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: StockItemCategory.ValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  private loadLocalFieldValidationErrors() {
    const validatedInputs = List.of(this.name,
      this.name,
      this.externalId,
      this.parentCategory
    );
    this.validatedInputs = LocalFieldValidationErrorsFactory.ofFormFields(this.fForm, validatedInputs);
  }

  submit() {
    if (this.componentState.isCreateView()) {
      this.create();
    }
    else if (this.componentState.isEditView()) {
      this.update();
    }
  }

  create() {
    if (this.hasLocalFieldError()) {
      return;
    }

    this.stockItemCategoryService.create({
      name: this.model.name,
      externalId: Strings.undefinedOrNonEmpty(this.model.externalId),
      parentId: this.model.mainCategory ? undefined : this.model.parentCategoryId
    }).subscribe(
      (response: IdentityMessage) => {
        if (this.model.childCategories.length > 0) {
          const updateObservables: Observable<EmptyMessage>[] = [];
          this.model.childCategories.forEach(c => {
            updateObservables.push(
              this.stockItemCategoryService.update({
                id: c.id,
                name: c.itemName,
                externalId: c.externalId,
                parentId: response.id
              })
            );
          });
          forkJoin(updateObservables).subscribe(result => {
            this.uiRouter.stateService.go(StateName.STOCK_ITEM_CATEGORY_LIST);
          },
            error => {
              // todo
            }
          );
        }
        else {
          this.uiRouter.stateService.go(StateName.STOCK_ITEM_CATEGORY_LIST);
        }
      },
      (error: any) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withForm(this.fForm);
        }
      });
  }

  update() {
    if (this.hasLocalFieldError()) {
      return;
    }

    this.stockItemCategoryService.update({
      id: this.componentState.id!,
      name: this.model.name,
      externalId: this.model.externalId,
      parentId: this.model.mainCategory ? undefined : this.model.parentCategoryId
    }).subscribe(
      (response: EmptyMessage) => {
        this.uiRouter.stateService.go(StateName.STOCK_ITEM_CATEGORY_LIST);
      },
      (error: any) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withForm(this.fForm);
        }
      });
  }

}

class StockItemCategoryModel {
  id: number | undefined;
  name: string = '';
  externalId: string = '';
  mainCategory: boolean = false;
  parentCategory: CategoryItem[] = [];
  childCategories: CategoryItem[] = [];

  get parentCategoryId(): number | undefined {
    return this.parentCategory.length === 1 ? this.parentCategory[0].id : undefined;
  }
}

interface StockItemCategoryFieldErrorMap {
  external_id?: FieldError;
  name?: FieldError;
  parent_id?: FieldError;
}

class CategoryItem extends MultiselectOptionItem<number> {
  externalId: string;
}
