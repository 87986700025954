<div class="pb-0">

  <div class="row ml-0 mr-0">

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 card-custom-padding">

      <div class="container-vertical-padding-half">
        <div class="card">

          <div class="card-header">
            {{'CUSTOMER_RECORD_CONNECTED_MASTER_DATA' | translate}}
            <div class="card-actions">
              <a class="btn-setting table-header-action-button d-flex align-items-center px-1 w-auto font-weight-normal"
                 (click)="connectMasterDataRecords()"
                 *ngIf="!readonly && rightModel.masterDataRecordUpdate.hasRight()"
                 title="{{'CUSTOMER_RECORD_CONNECT_MASTER_DATA' | translate}}">
                <i class="icomoon pr-1 icomoon-add"></i>{{'CUSTOMER_RECORD_CONNECT_MASTER_DATA' | translate}}
              </a>
              <a class="btn-setting table-header-action-button d-flex align-items-center px-1 w-auto font-weight-normal"
                 (click)="createMasterDataRecord()"
                 *ngIf="!readonly && rightModel.masterDataRecordCreate.hasRight()"
                 title="{{'MASTERDATA_RECORD_PANEL_HEADING_CREATE' | translate}}">
                <i class="icomoon pr-1 icomoon-add"></i>{{'MASTERDATA_RECORD_PANEL_HEADING_CREATE' | translate}}
              </a>
            </div><!-- /.card-actions -->

          </div>

          <div class="card-body">
            <table class="table table-striped table-bordered">
              <thead>
              <tr>
                <th class="table-sorter-header hidden-sm-down">
                  <app-table-sorter-no-op [text]="'COMMON_TYPE' | translate"></app-table-sorter-no-op>
                </th>
                <th class="table-sorter-header hidden-sm-down">
                  <app-table-field-sorter [orderField]="MasterDataRecord.OrderField.EXTERNAL_ID"
                                          [orderType]="queryModel.getOrderType(MasterDataRecord.OrderField.EXTERNAL_ID)"
                                          (orderChange)="orderBy($event)"
                                          [text]="'COMMON_EXTERNAL_ID' | translate"></app-table-field-sorter>
                </th>
                <th class="table-sorter-header">
                  <app-table-field-sorter [orderField]="MasterDataRecord.OrderField.NAME"
                                          [orderType]="queryModel.getOrderType(MasterDataRecord.OrderField.NAME)"
                                          (orderChange)="orderBy($event)"
                                          [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
                </th>
                <th class="table-sorter-header">
                  <app-table-sorter-no-op [text]="'COMPANY_LOCATION' | translate"></app-table-sorter-no-op>
                </th>
                <th class="table-sorter-header hidden-md-down">
                  <app-table-field-sorter [orderField]="MasterDataRecord.OrderField.DESCRIPTION"
                                          [orderType]="queryModel.getOrderType(MasterDataRecord.OrderField.DESCRIPTION)"
                                          (orderChange)="orderBy($event)"
                                          [text]="'COMMON_DESCRIPTION' | translate"></app-table-field-sorter>
                </th>
                <th class="table-sorter-header hidden-md-down">
                  <app-table-sorter-no-op [text]="'CUSTOMER_RECORD_BASE_DATA_CREATE_TIME' | translate"></app-table-sorter-no-op>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let masterdata of masterDataList | paginate: {
                                                      id: pagingId,
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }">
                <td class="responsive-table-column hidden-sm-down">
                    {{masterdata.masterDataName}}
                </td>
                <td class="responsive-table-column hidden-sm-down">
                  <a class="id-text cursor-pointer"
                     href="#" onclick="return false"
                     uiSref="Admin.MasterDataRecordDetail"
                     [uiParams]="{masterDataId: masterdata.masterDataId, masterDataRecordId: masterdata.id}"
                     title="{{'COMMON_BUTTON_DETAILS' | translate}}">
                    {{masterdata.externalId}}
                  </a>
                </td>
                <td class="responsive-table-column">
                  <a class="id-text cursor-pointer"
                     href="#" onclick="return false"
                     uiSref="Admin.MasterDataRecordDetail"
                     [uiParams]="{masterDataId: masterdata.masterDataId, masterDataRecordId: masterdata.id}"
                     title="{{'COMMON_BUTTON_DETAILS' | translate}}">
                    {{masterdata.name}}
                  </a>
                </td>
                <td class="responsive-table-column">{{masterdata.ownerContactLocationName}}</td>
                <td class="responsive-table-column hidden-md-down">{{masterdata.description}}</td>
                <td class="responsive-table-column hidden-md-down">{{masterdata.creationTime.toUtcIsoString() | date:'short'}}</td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <th class="table-sorter-header hidden-sm-down">
                  <app-table-sorter-no-op [text]="'COMMON_TYPE' | translate"></app-table-sorter-no-op>
                </th>
                <th class="table-sorter-header hidden-sm-down">
                  <app-table-field-sorter [orderField]="MasterDataRecord.OrderField.EXTERNAL_ID"
                                          [orderType]="queryModel.getOrderType(MasterDataRecord.OrderField.EXTERNAL_ID)"
                                          (orderChange)="orderBy($event)"
                                          [text]="'COMMON_EXTERNAL_ID' | translate"></app-table-field-sorter>
                </th>
                <th class="table-sorter-header">
                  <app-table-field-sorter [orderField]="MasterDataRecord.OrderField.NAME"
                                          [orderType]="queryModel.getOrderType(MasterDataRecord.OrderField.NAME)"
                                          (orderChange)="orderBy($event)"
                                          [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
                </th>
                <th class="table-sorter-header">
                  <app-table-sorter-no-op [text]="'COMPANY_LOCATION' | translate"></app-table-sorter-no-op>
                </th>
                <th class="table-sorter-header hidden-md-down">
                  <app-table-field-sorter [orderField]="MasterDataRecord.OrderField.DESCRIPTION"
                                          [orderType]="queryModel.getOrderType(MasterDataRecord.OrderField.DESCRIPTION)"
                                          (orderChange)="orderBy($event)"
                                          [text]="'COMMON_DESCRIPTION' | translate"></app-table-field-sorter>
                </th>
                <th class="table-sorter-header hidden-md-down">
                  <app-table-sorter-no-op [text]="'CUSTOMER_RECORD_BASE_DATA_CREATE_TIME' | translate"></app-table-sorter-no-op>
                </th>
              </tr>
              </tfoot>
            </table>
            <app-table-paging
              [id]="pagingId"
              [currentNumberOfItems]="queryModel.currentNumberOfItems"
              [totalNumberOfItems]="queryModel.totalNumberOfItems"
              [itemsPerPage]="queryModel.itemsPerPage"
              (pageChange)="pageChanged($event)"
              (itemsPerPageChange)="itemsPerPageChanged($event)">
            </app-table-paging>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
