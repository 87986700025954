/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from '../../util/http-services';
import { ResourceQueryResult } from '../../util/services';
import { IdentityMessage } from '../../util/messages';
import { InvoiceResource } from '../invoice/invoice-resource.service';

/* eslint-enable */

@Injectable()
export class InvoiceSpendingResourceService extends BaseHttpService {

  // <editor-fold desc="CRUD">

  query(request: InvoiceSpendingResource.QueryRequest): Observable<ResourceQueryResult<InvoiceSpendingResource.InvoiceSpending>> {
    return this.http.get<ResourceQueryResult<InvoiceSpendingResource.InvoiceSpending>>(this.url, this.parseParams(request));
  }

  get(request: InvoiceSpendingResource.GetRequest): Observable<InvoiceSpendingResource.InvoiceSpending> {
    return this.http.get<InvoiceSpendingResource.InvoiceSpending>(this.url + `${request.id}`, this.parseParams(request));
  }

  create(request: InvoiceSpendingResource.CreateRequest): Observable<IdentityMessage> {
    return this.http.post<IdentityMessage>(this.url, request);
  }

  // </editor-fold>

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/invoices/invoice-spending/');
  }

}

export namespace InvoiceSpendingResource {

  // <editor-fold desc="CRUD">

  export interface QueryRequest {
    fields?: string;
    filter?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
    rights?: string;
    no_progress_bar?: boolean;
  }

  export interface InvoiceSpending {
    id: number;
    granted_rights: string[];
    creation_time: string;
    update_time: string;
    spending_number: string;
    invoice_spending_type: string;
    event_name: string;
    comment?: string;
    amount: number;
    currency_code: string;
    invoices?: InvoiceResource.Invoice[];
    user: User;
  }

  export interface User {
    id: number;
    user_name: string;
    person_name: string;
  }

  export interface GetRequest {
    id: number;
    fields?: string;
    rights?: string;
  }

  export interface CreateRequest {
    invoice_spending_type: string;
    event_name: string;
    comment?: string;
    amount?: number;
    currency_code: string;
    invoices?: number[];
  }

  // </editor-fold>

}
