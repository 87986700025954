/* eslint-disable */
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { QueryFieldModel, } from '../../../../util/core-utils';
import { AuthService } from '../../../../lib/auth.service';
import { DocumentHTMLListModel, DocumentHTMLSearchModel, DocumentLanguageItem } from '../../../../util/document/document-utils';
import { DocumentHTML, DocumentHTMLService } from '../../../../lib/document/document-html.service';
import { Arrays } from '../../../../lib/util/arrays';
import { TranslateService } from '@ngx-translate/core';
import { DocumentLanguageService } from '../../../../lib/document/document-language.service';
import { DocumentLanguageUtil } from '../../../../util/document/document-language-utils';
import { DocumentGroup } from '../../../../lib/document/document-group.service';
import { DocumentGroupService } from '../../../../lib/document/document-group.service';
import { DocumentGroupItem } from '../../../../util/document/document-file-utils';
import { RightModel } from '../../../../app.rights';
import { RightResolver, RightService } from '../../../../lib/right.service';
import { OrderType, ResourceQueryResult, Services } from '../../../../lib/util/services';
import { Set } from 'immutable';
import { ConfigurationResource, ConfigurationService } from '../../../../lib/core-ext/configuration.service';
import { EmptyMessage } from '../../../../lib/util/messages';
import { DisabledEnum, DisabledItem } from '../../../../util/search-utils';
/* eslint-enable */

@Component({
  selector: 'app-help-app-document-html-list',
  templateUrl: 'document-html-list.component.html',
  styleUrls: ['document-html-list.component.scss']
})
export class DocumentHTMLListComponent implements OnInit, AfterViewInit {
  ConfigurationService = ConfigurationService;
  DocumentHTML = DocumentHTML;

  @ViewChild('searchIcon', { static: true }) searchIcon: ElementRef;

  queryModel: QueryFieldModel<DocumentHTML.OrderField> = new QueryFieldModel(DocumentHTML.OrderField.ID, OrderType.DESC);
  documentListModel: DocumentHTMLListModel[] = [];
  documentGroupList: DocumentGroupItem[] = [];
  documentLanguageList: DocumentLanguageItem[] = [];
  disabledItems: DisabledItem[] = [];

  searchModel: DocumentHTMLSearchModel = new DocumentHTMLSearchModel();
  showSearch: boolean = false;
  rightModel: RightModel = RightModel.empty();
  configuration: ConfigurationResource.Configuration;

  ngOnInit() {
    this.initDisabledOptions(DisabledEnum.FALSE);
    this.loadRightModels()
  }

  ngAfterViewInit(): void {
    this.loadDefaultDocumentGroup(() => {
      this.loadDocumentGroups(() => {
        this.loadDocumentLanguageList(() => {
          this.loadList();
        });
      });
    });
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  onSearchClicked() {
    this.loadList();
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: DocumentHTML.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  private initDisabledOptions(initValue: DisabledEnum) {
    this.disabledItems = [];
    const disabledEnums: DisabledEnum[] = [DisabledEnum.NONE, DisabledEnum.FALSE, DisabledEnum.TRUE];
    Arrays.iterateByIndex(disabledEnums, (key) => {
      const item = new DisabledItem();
      item.id = key;
      this.translateService.get('COMMON_VALUE_DISABLED_ENUM_' + key).subscribe(
        (text: string) => {
          item.text = text;
        }
      );
      this.disabledItems.push(item);
      if (key === initValue) {
        this.searchModel.disabled = item;
      }
    });
  }


  private loadList(pageNumber?: number) {
    this.documentListModel = [];

    const disabled: boolean | undefined = !this.searchModel.disabled ||
    this.searchModel.disabled.id === DisabledEnum.NONE ?
      undefined : this.searchModel.disabled.id === DisabledEnum.TRUE;

    let languageCode: string | null = null;
    if (this.searchModel.language_code) {
      languageCode = this.searchModel.language_code!.id;
    }

    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();

    this.documentHtmlService.query({
      name: (this.searchModel.name) ? this.searchModel.name : undefined,
      code: (this.searchModel.code) ? this.searchModel.code : undefined,
      value: (this.searchModel.value) ? this.searchModel.value : undefined,
      language_code: (languageCode) ? languageCode : undefined,
      document_group_id: (this.searchModel.document_group && this.searchModel.document_group.id)
        ? this.searchModel.document_group.id : undefined,
      disabled: disabled,

      page_number: requestedPage,
      number_of_items: this.queryModel.itemsPerPage,
      order: Services.createOrderFieldParameter(DocumentHTML.Keys.toOrderFieldKey, Set.of(order)),
    })
    .subscribe(
      (result: ResourceQueryResult<DocumentHTML>) => {
        const listModels: DocumentHTMLListModel[] = [];

        Arrays.iterateByIndex(result.items, documentHtml => {
          const listModel = new DocumentHTMLListModel();
          listModel.id = documentHtml.id;
          listModel.name = documentHtml.name;
          listModel.code = documentHtml.code;
          listModel.value = documentHtml.value;
          listModel.comment = documentHtml.comment;

          if (documentHtml.language_code) {
            listModel.language_code = {
              id: documentHtml.language_code,
              text: this.documentLanguageUtil.getDocumentLanguageName(documentHtml.language_code)
            };
          }
          listModel.creation_time = documentHtml.creation_time;
          listModel.update_time = documentHtml.update_time;
          listModel.disabled = documentHtml.disabled;
          listModel.document_group = {
            id: documentHtml.document_group_id,
            text: this.getDocumentGroupName(documentHtml.document_group_id)
          };
          listModels.push(listModel);
        });
        this.documentListModel = listModels;

        this.queryModel.currentPage = requestedPage;
        this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
        this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
      }
    );
  }

  loadDefaultDocumentGroup(completion: () => void) {
    this.translateService.get('DOCUMENT_GROUP_FILTER_ALL').subscribe(
      (text: string) => {
        const allGroup: DocumentGroupItem = {
          id: null,
          text: text
        };
        this.documentGroupList.push(allGroup);
        this.searchModel.document_group = allGroup;
        completion();
      }
    );
  }

  loadDocumentGroups(completion: () => void) {
    this.documentGroupService.query({}).subscribe((result: ResourceQueryResult<DocumentGroup>) => {

        result.items.forEach( group => {
          const documentGroupItem = {
            id: group.id,
            text: group.name
          };
          this.documentGroupList.push(documentGroupItem);
        });
        completion();

      }, (error: any) => {
        completion();
      });
  }

  loadDocumentLanguageList(completion: () => void) {
    this.documentLanguageService.query().subscribe(
      (result: string[]) => {
        const def = this.documentLanguageUtil.createDefaultLanguageCode();
        this.searchModel.language_code = def;
        this.documentLanguageList.push(def);

        Arrays.iterateByIndex( result, language => {
          const languageItem = new DocumentLanguageItem();
          languageItem.id = language;
          languageItem.text = this.documentLanguageUtil.getDocumentLanguageName(language);
          this.documentLanguageList.push(languageItem);
        });
        completion();

      }, (error: any) => {
        completion();
      });
  }

  getDocumentGroupName(documentGroupId: number | null): string {
    let groupName = '';
    if (!documentGroupId) {
      return groupName;
    }
    Arrays.iterateByIndex(this.documentGroupList, (userGroup) => {
      if (userGroup.id === documentGroupId ) {
        groupName = userGroup.text;
      }
    });

    return groupName;
  }

  setDisabled(event: any, documentModel: DocumentHTMLListModel, disabled: boolean) {
    if (!documentModel.id) {
      return;
    }
    this.documentHtmlService.setDisabled({
      id: documentModel.id,
      disabled: disabled
    })
      .subscribe(
      (result: EmptyMessage) => {
        this.loadList();
      },
      (error: any) => {
        this.loadList();
      }
    );
  }

  constructor(
    private translateService: TranslateService,
    private documentLanguageUtil: DocumentLanguageUtil,
    private rightService: RightService,
    private documentLanguageService: DocumentLanguageService,
    private documentGroupService: DocumentGroupService,
    private documentHtmlService: DocumentHTMLService,
    private configurationService: ConfigurationService,
    private authService: AuthService) {
    this.configuration = this.configurationService.getConfiguration();
  }
}
