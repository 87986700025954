/* eslint-disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {BaseHttpService, ResourceHelper} from '../util/http-services';
import {ResourceQueryResult} from '../util/services';
import {EmptyMessage, IdentityMessage} from '../util/messages';
import {WorkflowResource} from '../workflow/workflow-resource.service';
import {TaskRecordResource} from '../task/task-record-resource.service';
import {DownloadedFile} from '../util/downloaded-files';
import {UrlBuilder} from '../../util/url-builder';
import {map} from 'rxjs/operators';
import {ChatResource} from "../chat/chat.utils";

/* eslint-enable */

@Injectable()
export class ProcessResourceService extends BaseHttpService {

  // <editor-fold desc="CRUD">

  query(request: ProcessResource.QueryRequest): Observable<ResourceQueryResult<ProcessResource.Process>> {
    return this.http.get<ResourceQueryResult<ProcessResource.Process>>(this.url, this.parseParams(request));
  }

  get(request: ProcessResource.GetRequest): Observable<ProcessResource.Process> {
    return this.http.get<ProcessResource.Process>(this.url + `${request.id}`, this.parseParams(request));
  }

  create(request: ProcessResource.CreateRequest): Observable<IdentityMessage> {
    return this.http.post<IdentityMessage>(this.url, request);
  }

  update(request: ProcessResource.UpdateRequest): Observable<EmptyMessage> {
    return this.http.put<EmptyMessage>(this.url + `${request.id}`, request);
  }

  stateCount(request: IdentityMessage): Observable<Map<string, number>> {
    return this.http.get<Map<string, number>>(this.url + `${request.id}/state-count`);
  }

  messageQuery(request: ProcessResource.MessageQueryRequest): Observable<ResourceQueryResult<ChatResource.Message>> {
    return this.http.get<ResourceQueryResult<ChatResource.Message>>(
      this.url + `${request.process_id}/chat`, this.parseParams(request)
    );
  }

  sendMessage(request: ProcessResource.SendMessageRequest): Observable<IdentityMessage> {
    return this.http.post<IdentityMessage>(
      this.url + `${request.process_id}/chat`, request);
  }

  getRecentMessages(request: ProcessResource.RecentMessageQueryRequest):
    Observable<ResourceQueryResult<ProcessResource.RecentMessage>> {
    return this.http.get<ResourceQueryResult<ProcessResource.RecentMessage>>(this.url + '/recent-messages', this.parseParams(request));
  }

  exportXls(request: ProcessResource.XlsExportRequest): Observable<DownloadedFile> {
    const url = UrlBuilder.create('processes/export-xls')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob',
      params: this.parseParams(request).params
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }


  // </editor-fold>

  constructor(private http: HttpClient, private resourceHelper: ResourceHelper) {
    super(resourceHelper, '/processes/');
  }

}

export namespace ProcessResource {

  // <editor-fold desc="CRUD">

  export interface QueryRequest {
    fields?: string;
    filter?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
    rights?: string;
    no_progress_bar?: boolean;
  }

  export interface XlsExportRequest {
    filter?: string;
  }

  export interface Process {
    id: number;
    granted_rights?: string[];
    name: string;
    external_id: string;
    description?: string;
    creation_time: string;
    update_time: string;
    start_time?: string;
    finish_time?: string;
    last_modified_user?: User;
    creator_user?: User;
    state: string;
    deadline?: string;
    task_records?: TaskRecord[];
    task_record_name_pattern?: string;
    workflow?: WorkflowResource.Workflow;
  }

  export interface TaskRecord {
    id: number;
    task_id: number;
    name: string;
    granted_rights?: string[];
    state: string;
    creation_time: string;
    workflow_task: {
      id: number;
    };
    assignee_user?: {
      id: number;
      person_name: string;
      user_name: string;
    };
    assignee_user_group?: {
      id: number;
      name: string;
    };
    customer_record?: {
      id: number;
      name: string;
    };
    contact_location?: {
      id: number;
      name: string;
    };
    importance: string;
    place_of_consumption?: TaskRecordResource.PlaceOfConsumption;
    deadline?: string;
  }

  export interface User {
    id: number;
    user_name: string;
    person_name: string;
  }

  export interface Project {
    id: number;
    name: string;
    external_id: string;
  }

  export interface GetRequest {
    id: number;
    fields?: string;
    rights?: string;
    task_record_rights?: string;
  }

  export interface CreateRequest {
    workflow: { id: number };
    name?: string;
    external_id?: string;
    description?: string;
    deadline?: string;
    task_record_name_pattern?: string;
    task_record_quick_create_request: TaskRecordResource.QuickCreateRequest;
  }

  export interface UpdateRequest {
    id: number;
    name?: string;
    external_id: string;
    description?: string;
    deadline?: string;
    task_record_name_pattern?: string;
  }


  export interface RecentMessageQueryRequest {
    fields?: string;
    page_number?: number;
    number_of_items?: number;
    no_progress_bar?: boolean;
  }

  export interface RecentMessage {
    process: Process;
    chat_message: ChatResource.Message;
  }

  export interface MessageQueryRequest extends ChatResource.MessageQueryRequest {
    process_id: number;
  }

  export interface SendMessageRequest extends ChatResource.SendMessageRequest {
    process_id: number;
  }

  // </editor-fold>
}
