<div class="animated fadeIn">

  <div class="card">

    <div class="card-header">{{'CUSTOMER_RECORD_HELPDESK_DATA' | translate}}</div>

    <div class="card-body">
      <div class="data-container col-lg-6 col-md-8">
        <div class="d-flex align-items-center">
          <app-user-profile-picture
            class="mr-1"
            [userPersonName]="customerRecord.name"
            [profilePicture]="profilePicture"
            [size]="72">
          </app-user-profile-picture>
          <div class="state-icon-container" *ngIf="helpdesk && helpdesk.invitationTime"
               [ngClass]="{'state-not-registered': !helpdesk.user, 'state-active': helpdesk.user && !helpdesk.user.disabled, 'state-disabled': helpdesk.user && helpdesk.user.disabled}">
            <i class="icomoon icomoon-helpdesk-user"></i>
          </div>
          <div class="flex-grow-1 mr-1">
            <div class="cr-name">
              <span *ngIf="!helpdesk || !helpdesk.user">{{customerRecord.name}}</span>
              <a uiSref="Admin.UserDetail" [uiParams]="{id: helpdesk.user.id}" *ngIf="helpdesk && helpdesk.user">{{customerRecord.name}}</a>
            </div>
            <div class="cr-email">
              {{customerRecord.emailAddresses.size > 0 ? customerRecord.emailAddresses.get(0).value.toIso() : ''}}
            </div>
          </div>
          <button type="button" (click)="inviteToHelpdesk()" class="btn btn-primary"
                  *ngIf="(!helpdesk || !helpdesk.invitationTime) && rightModel.customerRecordHelpdeskAction.hasRight() && customerRecord.emailAddresses.size > 0">
            {{'CUSTOMER_RECORD_HELPDESK_INVITE_CUSTOMER' | translate}}
          </button>
          <button type="button" (click)="inviteToHelpdesk()" class="btn btn-primary"
                  *ngIf="helpdesk && helpdesk.invitationTime && !helpdesk.user && rightModel.customerRecordHelpdeskAction.hasRight()">
            {{'CUSTOMER_RECORD_HELPDESK_RE_INVITE_CUSTOMER' | translate}}
          </button>
          <button type="button" (click)="helpdesk.user.disabled ? enableHelpdeskUser() : disableHelpdeskUser()" class="btn btn-primary"
                  *ngIf="helpdesk && helpdesk.invitationTime && helpdesk.user && rightModel.customerRecordHelpdeskAction.hasRight()">
            {{(helpdesk.user.disabled ? 'CUSTOMER_RECORD_HELPDESK_ENABLE_USER' : 'CUSTOMER_RECORD_HELPDESK_DISABLE_USER') | translate}}
          </button>
        </div>
        <div *ngIf="helpdesk && helpdesk.invitationTime" class="row m-0 mt-1 pt-1 state-container">
          <div *ngIf="helpdesk.invitationTime" class="col-sm-6 p-0 pr-1">
            {{'CUSTOMER_RECORD_HELPDESK_INVITATION_TIME' | translate}}
            <div class="state-data">
              {{helpdesk.invitationTime.toUtcIsoString() | date:'short'}}
            </div>
          </div>
          <div class="col-sm-6 p-0">
            {{'CUSTOMER_RECORD_HELPDESK_INVITATION_STATE' | translate}}
            <div class="state-data">
              {{(helpdesk.user ? helpdesk.user.disabled ? 'CUSTOMER_RECORD_HELPDESK_USER_DISABLED' : 'CUSTOMER_RECORD_HELPDESK_USER_REGISTERED' : 'CUSTOMER_RECORD_HELPDESK_USER_NOT_REGISTERED') | translate}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
