import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { MultiselectOptionItem, UiConstants } from '../../../util/core-utils';
import { Angular2Multiselects } from '../../../util/multiselect';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { FieldError, FieldErrors, ObservableErrorResourceParser } from '../../../lib/util/errors';
import { VehicleMultiselectProvider } from '../../../lib/vehicles/vehicle-multiselect.provider';
import { MileageRecordService } from '../../../lib/mileage-record/mileage-record.service';
import { MileageRecordEditModel, MileageRecordFieldErrorMap, MileageRecordModel } from '../mileage-record.model';
import { Models } from '../../../util/model-utils';
import { InputMask } from '../../../util/input-masks';
import { Strings } from '../../../lib/util/strings';

@Component({
  selector: 'app-mileage-record-edit-dialog',
  templateUrl: './mileage-record-edit-dialog.component.html',
  styleUrls: ['./mileage-record-edit-dialog.component.scss']
})
export class MileageRecordEditDialogComponent implements OnInit {
  UiConstants = UiConstants;
  InputMask = InputMask;

  @Output()
  onMileageRecordUpdated: EventEmitter<any> = new EventEmitter<any>();

  model: MileageRecordEditModel = new MileageRecordEditModel();

  @ViewChild('dialog', { static: true })
  dialog: ModalDirective;
  dialogVisible: boolean = false;

  vehicles: MultiselectOptionItem<number>[] = [];

  singleRemoteDropdownSettings: Angular2Multiselects.Settings;

  formGroup: FormGroup;

  mileageRecordFieldErrors: MileageRecordFieldErrorMap;

  constructor(private vehicleMultiselectProvider: VehicleMultiselectProvider,
              private mileageRecordService: MileageRecordService,
              private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.initDropdownSettings();
  }

  loadVehicles(predicate?: string) {
    this.vehicleMultiselectProvider.loadActive(predicate).subscribe((vehicles) => {
      this.vehicles = vehicles;
    });
  };

  private loadVehicle(vehicleId: number) {
    this.vehicleMultiselectProvider.getById(vehicleId).subscribe((vehicle) => {
      this.model.vehicle.splice(0, this.model.vehicle.length)
      this.model.vehicle.push(vehicle);
      this.formGroup.get('vehicle')!.markAsTouched();
      this.formGroup.get('vehicle')!.updateValueAndValidity();
    });
  }

  initDropdownSettings() {
    this.singleRemoteDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
  }

  initDialog(model: MileageRecordModel) {
    this.loadVehicles();
    this.mileageRecordFieldErrors = {};
    this.model = new MileageRecordEditModel();
    this.model.id = model.id;
    this.model.startKilometer = model.startKilometer.toString();
    this.model.finishKilometer = model.finishKilometer ? model.finishKilometer.toString() : '';
    this.model.note = model.note;
    this.model.journeyType = model.journeyType;
    this.loadVehicle(model.vehicleId);
    this.createFormGroup();
  }

  private createFormGroup() {
    this.formGroup = this.formBuilder.group({
      startKilometer: ['', Validators.required],
      finishKilometer: ['', Validators.required],
      vehicle: ['', Validators.required],
    }, {validator: [this.kilometerIntervalValidator]});
  }

  showDialog(model: MileageRecordModel) {
    this.dialogVisible = true;
    this.dialog.show();
    this.initDialog(model);
  }

  closeDialog() {
    this.dialogVisible = false;
    this.dialog.hide();
  }

  update() {
    if (!this.formGroup.valid) {
      this.formGroup.get('startKilometer')!.markAsTouched();
      this.formGroup.get('finishKilometer')!.markAsTouched();
      this.formGroup.get('vehicle')!.markAsTouched();
      return;
    }
    this.mileageRecordService.update({
      id: this.model.id,
      vehicleId: this.model.vehicleId!,
      startKilometer: Models.parseDecimal(this.model.startKilometer)!,
      finishKilometer: Models.parseDecimal(this.model.finishKilometer)!,
      journeyType: Strings.undefinedOrNonEmpty(this.model.journeyType),
      note: Strings.undefinedOrNonEmpty(this.model.note)
    }).subscribe(() => {
        this.closeDialog();
        this.onMileageRecordUpdated.emit();
      },
      (error: any) => {
        const res = ObservableErrorResourceParser.parseError(error);
        this.mileageRecordFieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
      });
  }

  hasLocalFieldError(control: string, errorCode?: string): boolean {
    if (!this.formGroup.controls[control]) {
      return false;
    }
    let hasError = this.formGroup.controls[control].touched && !this.formGroup.controls[control].valid;
    if (errorCode) {
      hasError = hasError
      && this.formGroup.controls[control].errors !== null
      && this.formGroup.controls[control].errors![errorCode];
    }
    return hasError;
  }

  hasLocalFormError(errorCode?: string): boolean {
    if (this.formGroup.errors === null) {
      return false;
    }
    let hasError = this.formGroup.touched && !this.formGroup.controls.valid;
    if (errorCode) {
      hasError = hasError && this.formGroup.errors![errorCode];
    }
    return hasError;
  }

  removeFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.mileageRecordFieldErrors, fieldError);
  }

  kilometerIntervalValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
    const startKilometer = control.get('startKilometer');
    const finishKilometer = control.get('finishKilometer');
    if (!finishKilometer || !startKilometer) {
      return null;
    }
    return +finishKilometer.value < +startKilometer.value ? { 'kilometerInterval': true } : null;
  };
}
