/* eslint-disable */
import { Component, Input, OnInit } from '@angular/core';
import { OrderType, QueryResult } from '../../../lib/util/services';
import { Dates, OffsetDateTime } from '../../../lib/util/dates';
import { QueryFieldModel } from '../../../util/core-utils';
import { Set } from 'immutable';
import { Transition } from '@uirouter/angular';
import {
  CustomerRecordContactLocationMultiselectProvider
} from '../../../lib/customer/contact/customer-record-contact-location-multiselect.provider';
import { MasterDataRecord, MasterDataRecordService } from '../../../lib/masterdata/master-data-record.service';
import { Models } from '../../../util/model-utils';
import { MatDialog } from '@angular/material/dialog';
import { RightModel } from '../../../app.rights';
import { RightResolver, RightService } from '../../../lib/right.service';
import {
  MasterdataRecordQuickCreateDialogComponent
} from '../../masterdata/record/masterdata-record-quick-create-dialog/masterdata-record-quick-create-dialog.component';
import {
  MasterdataRecordConnectToCustomerDialogComponent
} from '../../masterdata/record/masterdata-record-connect-to-customer-dialog/masterdata-record-connect-to-customer-dialog.component';
import { MasterDataService } from '../../../lib/masterdata/master-data.service';

/* eslint-enable */

@Component({
  selector: 'app-customer-owner-master-data-list',
  templateUrl: './customer-owner-master-data-list.component.html',
  styleUrls: ['./customer-owner-master-data-list.component.scss']
})
export class CustomerOwnerMasterDataListComponent implements OnInit {
  pagingId = 'customerOwnerMasterDataListComponent';

  MasterDataRecord = MasterDataRecord;

  queryModel: QueryFieldModel<MasterDataRecord.OrderField> = new QueryFieldModel(MasterDataRecord.OrderField.ID, OrderType.DESC);

  masterDataList: MasterDataModel[] = [];
  customerRecordId: number;
  rightModel: RightModel = RightModel.empty();

  @Input()
  readonly: boolean = false;

  constructor(
    private masterDataRecordService: MasterDataRecordService,
    private masterDataService: MasterDataService,
    private customerRecordContactLocationMultiselectProvider: CustomerRecordContactLocationMultiselectProvider,
    private transition: Transition,
    private rightService: RightService,
    private dialog: MatDialog
  ) {
    this.customerRecordId = this.transition.params().customerRecordId;
  }

  ngOnInit() {
  }

  initComponent() {
    this.loadRightModels();
  }
  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        if (this.rightModel.masterDataRecordRead.hasRight()) {
          this.loadList();
        }
      }
    );
  }


  loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();

    this.masterDataList = [];
    this.masterDataRecordService.globalQuery({
      ownerCustomerRecordId: Set.of(this.customerRecordId),
      orders: Set.of(order),
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined
    }).subscribe((result: QueryResult<MasterDataRecord.MasterDataRecord>) => {
      if (result.items.size > 0) {
        this.masterDataService.query({masterDataIdSet: result.items.map(i => i?.masterDataId!).toSet()}).subscribe(md => {
          result.items.forEach((value: MasterDataRecord.MasterDataRecord) => {
            const masterData = new MasterDataModel();
            masterData.externalId = Models.optToString(value.externalId);
            masterData.masterDataName = Models.optToString(md.items
              .filter(m => m!.masterDataId === value.masterDataId)
              .map(m => m?.name)
              .first())
            masterData.name = Models.optToString(value.name);
            masterData.description = Models.optToString(value.description);
            masterData.creationTime = value.creationTime;
            masterData.id = value.masterDataRecordId;
            masterData.masterDataId = value.masterDataId;
            if (value.ownerContactLocationId) {
              this.customerRecordContactLocationMultiselectProvider.getById(value.ownerContactLocationId).subscribe((result) => {
                masterData.ownerContactLocationName = result.itemName;
              })
            }
            this.masterDataList.push(masterData)
          })
        });
      }
      this.queryModel.currentPage = requestedPage;
      this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
    });
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: MasterDataRecord.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  createMasterDataRecord() {
    if (this.rightModel.masterDataRecordCreate.hasRight()) {
      const dialogRef = this.dialog.open(MasterdataRecordQuickCreateDialogComponent, {
        width: '60%',
        panelClass: 'custom-dialog-container',
        data: {customerRecordId: this.customerRecordId}
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result.modified) {
          this.loadList();
        }
      });
    }
  }

  connectMasterDataRecords() {
    if (this.rightModel.masterDataRecordUpdate.hasRight()) {
      const dialogRef = this.dialog.open(MasterdataRecordConnectToCustomerDialogComponent, {
        width: '60%',
        panelClass: 'custom-dialog-container',
        data: {customerRecordId: this.customerRecordId}
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result.modified) {
          this.loadList();
        }
      });
    }
  }
}

class MasterDataModel {
  id: number;
  masterDataId: number;
  externalId: string = '';
  masterDataName: string = '';
  name: string = '';
  ownerContactLocationName: string = '';
  description: string = '';
  creationTime: OffsetDateTime = Dates.now();
}
