import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CalendarUtils } from '../../../../util/calendar/calendar-utils';
import pSBC from 'shade-blend-color';
import { CustomCalendarView } from '../../../../fork/angular-calendar/src';
import LocalCalendarEvent = CalendarUtils.LocalCalendarEvent;

@Component({
  selector: 'app-calendar-timeline-row',
  templateUrl: './calendar-timeline-row.component.html',
  styleUrls: ['./calendar-timeline-row.component.scss']
})
export class CalendarTimelineRowComponent implements OnInit {
  @Input()
  set startDate(date: Date) {
    this.monday = CalendarUtils.calculateViewDate(1, date, CustomCalendarView.Timeline);
  }

  @Input()
  set daysInWeek(value: number) {
    this._daysInWeek = value;
  }

  get daysInWeek(): number {
    return this._daysInWeek;
  }

  private _daysInWeek: number = 7;

  private monday: Date;


  @Input()
  set events(rawEvents: LocalCalendarEvent[]) {
    this._events = [];
    this.rows = [];
    this.rows.push(this.createEmptyRow());
    rawEvents.forEach(e => {
      const start: number = Math.max(CalendarUtils.daysBetween(this.monday, e.start), 0);
      let length: number = CalendarUtils.daysBetween(e.start, e.end!) + 1;
      if (start + length > this._daysInWeek - 1) {
        length = this._daysInWeek - start;
      }
      const event = {
        start: start,
        length: length,
        row: 0,
        event: e
      };
      event.row = this.calculateRow(start, length);
      this._events.push(event);
    });
  }

  /**
   * Called when the event title is clicked
   */
  @Output()
  eventClicked = new EventEmitter<{
    event: LocalCalendarEvent;
  }>();

  _events: TimelineEvent[] = [];

  rows: boolean[][] = [];

  @ViewChild('container')
  container: ElementRef;

  readonly cellHeight: number = 84;

  constructor() {
  }

  ngOnInit(): void {
  }

  get days(): number[] {
    return [...Array(this._daysInWeek).keys()];
  }

  private createEmptyRow(): boolean[] {
    const result: boolean[] = [];
    for (let i = 0; i < this._daysInWeek; i++) {
      result.push(true);
    }
    return result;
  }

  private calculateRow(start: number, length: number): number {
    let i: number = 0;
    while (true) {
      const row = this.rows[i];
      let spaceFound: boolean = true;
      for (let j = start; j < start + length; j++) {
        spaceFound = spaceFound && row[j];
      }
      if (spaceFound) {
        for (let j = start; j < start + length; j++) {
          row[j] = false;
        }
        break;
      }
      else {
        if (i === this.rows.length - 1) {
          this.rows.push(this.createEmptyRow());
          i++;
          const newRow = this.rows[i];
          for (let j = start; j < start + length; j++) {
            newRow[j] = false;
          }
          break;
        }
      }
      i++;
    }
    return i;
  }

  lightenColor(color: string): string {
    return pSBC(0.8, color);
  }
}

interface TimelineEvent {
  start: number;
  length: number;
  row: number;
  event: LocalCalendarEvent;
}
