/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from '../util/http-services';

/* eslint-enable */

@Injectable()
export class DocumentLanguageService extends BaseHttpService {

  query(): Observable<string[]> {
    return this.http.get<string[]>(this.url);
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/documents/languages/');
  }
}
