<div bsModal [config]="UiConstants.modalConfig" #dialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="isVisible">
    <div class="modal-content">
      <form [formGroup]="formGroup" (ngSubmit)="create()" #f="ngForm">
      <div class="modal-header">
        <p class="modal-title"><strong>{{'TASK_RECORD_MANUAL_INVOICE_CREATE_TITLE' | translate}}</strong></p>
        <button type="button" class="close" (click)="hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-5">
            <div class="form-group"
                 [ngClass]="{ 'has-danger': hasLocalFieldError('invoiceSettings') || hasFieldError(TaskRecord.ManualInvoiceValidatedField.INVOICE_SETTINGS)}">
              <label class="col-form-label form-control-label required-field-label">
                {{'TASK_RECORD_MANUAL_INVOICE_CREATE_INVOICE_SETTINGS'|translate}}
              </label>
              <angular2-multiselect [data]="invoiceSettingsList" [settings]="dropdownSettings"
                                    class="form-control"
                                    [(ngModel)]="model.invoiceSettings"
                                    formControlName="invoiceSettings"
                                    (onRemoteSearch)="loadInvoiceSettings($event.target.value)"
                                    (ngModelChange)="onInvoiceSettingsChanged()">
              </angular2-multiselect>
              <div class="form-control-feedback" *ngIf="hasLocalFieldError('invoiceSettings', 'required')">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
              <div class="form-control-feedback" *ngIf="hasFieldError(TaskRecord.ManualInvoiceValidatedField.INVOICE_SETTINGS)">
                {{getFieldErrorText(TaskRecord.ManualInvoiceValidatedField.INVOICE_SETTINGS)}}
              </div>
            </div>

            <div class="form-group"
                 *ngIf="model.invoiceSettingsId && model.invoiceSettings[0].integrationType === InvoiceSettings.InvoiceIntegrationType.APPWORKS"
                 [ngClass]="{ 'has-danger': hasLocalFieldError('invoiceBook') || hasFieldError(TaskRecord.ManualInvoiceValidatedField.INVOICE_BOOK)}">
              <label class="col-form-label form-control-label required-field-label">
                {{'TASK_RECORD_MANUAL_INVOICE_CREATE_INVOICE_BOOK'|translate}}
              </label>
              <angular2-multiselect [data]="invoiceBookList" [settings]="dropdownSettings"
                                    class="form-control"
                                    [(ngModel)]="model.invoiceBook"
                                    (onRemoteSearch)="loadInvoiceBooks($event.target.value)"
                                    (ngModelChange)="onInvoiceBookChanged()"
                                    formControlName="invoiceBook">
              </angular2-multiselect>
              <div class="form-control-feedback" *ngIf="hasLocalFieldError('invoiceBook', 'required')">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
              <div class="form-control-feedback" *ngIf="hasFieldError(TaskRecord.ManualInvoiceValidatedField.INVOICE_BOOK)">
                {{getFieldErrorText(TaskRecord.ManualInvoiceValidatedField.INVOICE_BOOK)}}
              </div>
            </div>

            <div class="form-group"
                 [ngClass]="{ 'has-danger': hasLocalFieldError('invoiceTag') || hasFieldError(TaskRecord.ManualInvoiceValidatedField.INVOICE_TAG)}">
              <label class="col-form-label form-control-label">
                {{'TASK_RECORD_MANUAL_INVOICE_CREATE_INVOICE_TAG'|translate}}
              </label>
              <angular2-multiselect [data]="invoiceTagList" [settings]="dropdownSettings"
                                    class="form-control"
                                    [(ngModel)]="model.invoiceTag"
                                    (onRemoteSearch)="onInvoiceTagSearch($event.target.value)"
                                    formControlName="invoiceTag">
              </angular2-multiselect>
              <div class="form-control-feedback" *ngIf="hasFieldError(TaskRecord.ManualInvoiceValidatedField.INVOICE_TAG)">
                {{getFieldErrorText(TaskRecord.ManualInvoiceValidatedField.INVOICE_TAG)}}
              </div>
            </div>

            <div class="form-group" [ngClass]="{ 'has-danger': hasLocalFieldError('appearanceType')}">
              <label class="col-form-label form-control-label required-field-label">
                {{'TASK_RECORD_MANUAL_INVOICE_CREATE_APPEARANCE_TYPE' | translate}}
              </label>
              <select class="form-control"
                      [ngClass]="{'hidden-control': !model.invoiceSettingsId || model.invoiceSettings[0].integrationType === InvoiceSettings.InvoiceIntegrationType.APPWORKS}"
                      [(ngModel)]="model.appearanceType"
                      formControlName="appearanceType">
                <option [ngValue]="null" disabled>{{'COMMON_VALUE_UNSELECTED' | translate}}</option>
                <option *ngFor="let appearanceType of InvoiceSettings.invoiceAppearanceTypes"
                        [ngValue]="appearanceType.type">{{appearanceType.stringKey |
                  translate}}
                </option>
              </select>
              <select class="form-control"
                      [ngClass]="{'hidden-control': model.invoiceSettingsId && model.invoiceSettings[0].integrationType !== InvoiceSettings.InvoiceIntegrationType.APPWORKS}"
                      [(ngModel)]="model.appearanceType"
                      [ngModelOptions]="{standalone: true}"
                      disabled>
                <option [ngValue]="null" disabled>{{'COMMON_VALUE_UNSELECTED' | translate}}</option>
                <option *ngFor="let appearanceType of InvoiceSettings.invoiceAppearanceTypes"
                        [ngValue]="appearanceType.type">{{appearanceType.stringKey |
                  translate}}
                </option>
              </select>
              <div class="form-control-feedback" *ngIf="hasLocalFieldError('appearanceType', 'required')">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>

            <div class="form-group" [ngClass]="{ 'has-danger': hasLocalFieldError('languageCode', 'required')}">
              <label class="col-form-label form-control-label required-field-label">
                {{'TASK_RECORD_MANUAL_INVOICE_CREATE_LANGUAGE' | translate}}
              </label>
              <select class="form-control hidden-control" [(ngModel)]="model.languageCode"
                      formControlName="languageCode">
                <option [ngValue]="null">{{'COMMON_VALUE_UNSELECTED' | translate}}</option>
                <option *ngFor="let languageObject of InvoiceSettings.invoiceLanguages"
                        [ngValue]="languageObject.code">{{languageObject.stringKey |
                  translate}}
                </option>
              </select>
              <select class="form-control" [(ngModel)]="model.languageCode"
                      [ngModelOptions]="{standalone: true}" disabled>
                <option [ngValue]="null">{{'COMMON_VALUE_UNSELECTED' | translate}}</option>
                <option *ngFor="let languageObject of InvoiceSettings.invoiceLanguages"
                        [ngValue]="languageObject.code">{{languageObject.stringKey |
                  translate}}
                </option>
              </select>
              <div class="form-control-feedback" *ngIf="hasLocalFieldError('languageCode', 'required')">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>

            <div class="form-group" [ngClass]="{ 'has-danger': hasLocalFieldError('paymentType')}">
              <label class="col-form-label form-control-label required-field-label">
                {{'TASK_RECORD_MANUAL_INVOICE_CREATE_PAYMENT_TYPE' | translate}}
              </label>
              <select class="form-control" [(ngModel)]="model.paymentType"
                      formControlName="paymentType">
                <option [ngValue]="null" disabled>{{'COMMON_VALUE_UNSELECTED' | translate}}</option>
                <option *ngFor="let paymentType of paymentTypes" [ngValue]="paymentType.id">{{paymentType.itemName | translate}}
                </option>
              </select>
              <div class="form-control-feedback" *ngIf="hasLocalFieldError('paymentType', 'required')">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>

            <div class="form-group"
                 [ngClass]="{ 'has-danger': hasLocalFieldError('issueDate') || hasFieldError(TaskRecord.ManualInvoiceValidatedField.ISSUE_DATE) }">
              <label class="col-form-label form-control-label required-field-label">
                {{'TASK_RECORD_MANUAL_INVOICE_CREATE_ISSUE_DATE' | translate}}
              </label>
              <div class="input-group">
                <input class="form-control" type="text"
                       placeholder="{{'TASK_RECORD_MANUAL_INVOICE_CREATE_ISSUE_DATE' | translate}}"
                       [ngClass]="{ 'form-control-danger': hasLocalFieldError('issueDate') || hasFieldError(TaskRecord.ManualInvoiceValidatedField.ISSUE_DATE) }"
                       [(ngModel)]="model.issueDate"
                       (ngModelChange)="onIssueDateChanged()"
                       #issueDate="ngbDatepicker"
                       formControlName="issueDate"
                       ngbDatepicker>
                <span class="add-on input-group-addon-gray" (click)="issueDate.toggle()">
                  <i class="icomoon icomoon-calendar"></i>
                </span>
              </div>
              <div class="form-control-feedback" *ngIf="hasLocalFieldError('issueDate')">
              <span
                *ngIf="hasLocalFieldError('issueDate', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
                <span
                  *ngIf="hasLocalFieldError('issueDate', 'validateLocalDate')">{{'COMMON_VALIDATION_MESSAGE_INVALID_LOCAL_DATE' | translate}}</span>
              </div>
              <div class="form-control-feedback" *ngIf="hasFieldError(TaskRecord.ManualInvoiceValidatedField.ISSUE_DATE)">
                {{getFieldErrorText(TaskRecord.ManualInvoiceValidatedField.ISSUE_DATE)}}
              </div>
            </div>

            <div class="form-group"
                 [ngClass]="{ 'has-danger': hasLocalFieldError('deliveryDate') || hasFieldError(TaskRecord.ManualInvoiceValidatedField.DELIVERY_DATE) }">
              <label class="col-form-label form-control-label required-field-label">
                {{'TASK_RECORD_MANUAL_INVOICE_CREATE_DELIVERY_DATE' | translate}}
              </label>
              <div class="input-group">
                <input class="form-control" type="text"
                       placeholder="{{'TASK_RECORD_MANUAL_INVOICE_CREATE_DELIVERY_DATE' | translate}}"
                       [ngClass]="{ 'form-control-danger': hasLocalFieldError('deliveryDate') || hasFieldError(TaskRecord.ManualInvoiceValidatedField.DELIVERY_DATE) }"
                       [(ngModel)]="model.deliveryDate"
                       #deliveryDate="ngbDatepicker"
                       formControlName="deliveryDate"
                       (ngModelChange)="removeFieldError(TaskRecord.ManualInvoiceValidatedField.DELIVERY_DATE)"
                       ngbDatepicker>
                <span class="add-on input-group-addon-gray" (click)="deliveryDate.toggle()">
                  <i class="icomoon icomoon-calendar"></i>
                </span>
              </div>
              <div class="form-control-feedback" *ngIf="hasLocalFieldError('deliveryDate')">
              <span
                *ngIf="hasLocalFieldError('deliveryDate', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
                <span
                  *ngIf="hasLocalFieldError('deliveryDate', 'validateLocalDate')">{{'COMMON_VALIDATION_MESSAGE_INVALID_LOCAL_DATE' | translate}}</span>
              </div>
              <div class="form-control-feedback" *ngIf="hasFieldError(TaskRecord.ManualInvoiceValidatedField.DELIVERY_DATE)">
                {{getFieldErrorText(TaskRecord.ManualInvoiceValidatedField.DELIVERY_DATE)}}
              </div>
            </div>

            <div class="form-group"
                 [ngClass]="{ 'has-danger': hasLocalFieldError('deadline') || hasFieldError(TaskRecord.ManualInvoiceValidatedField.DEADLINE) }">
              <label class="col-form-label form-control-label required-field-label">
                {{'TASK_RECORD_MANUAL_INVOICE_CREATE_DEADLINE' | translate}}
              </label>
              <div class="input-group">
                <input class="form-control" type="text"
                       placeholder="{{'TASK_RECORD_MANUAL_INVOICE_CREATE_DEADLINE' | translate}}"
                       [ngClass]="{ 'form-control-danger': hasLocalFieldError('deadline') || hasFieldError(TaskRecord.ManualInvoiceValidatedField.DEADLINE) }"
                       [(ngModel)]="model.deadline"
                       (ngModelChange)="removeFieldError(TaskRecord.ManualInvoiceValidatedField.DEADLINE)"
                       #deadline="ngbDatepicker"
                       formControlName="deadline"
                       ngbDatepicker>
                <span class="add-on input-group-addon-gray" (click)="deadline.toggle()">
                    <i class="icomoon icomoon-calendar"></i>
                  </span>
              </div>
              <div class="form-control-feedback" *ngIf="hasLocalFieldError('deadline')">
                <span
                  *ngIf="hasLocalFieldError('deadline', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
                <span
                  *ngIf="hasLocalFieldError('deadline', 'validateLocalDate')">{{'COMMON_VALIDATION_MESSAGE_INVALID_LOCAL_DATE' | translate}}</span>
              </div>
              <div class="form-control-feedback" *ngIf="hasFieldError(TaskRecord.ManualInvoiceValidatedField.DEADLINE)">
                {{getFieldErrorText(TaskRecord.ManualInvoiceValidatedField.DEADLINE)}}
              </div>
              <div class="form-control-feedback" *ngIf="selectedCustomerRecord && selectedCustomerRecord.invoiceDeadlineAdditionalDays">
                {{'TASK_RECORD_MANUAL_INVOICE_CREATE_DEADLINE_DEFAULT_ADDITIONAL_DAYS' | translate:{ days: selectedCustomerRecord.invoiceDeadlineAdditionalDays } }}
              </div>
            </div>
          </div>

          <div class="col-md-7">
            <div class="card p-1 mb-1">
              <div class="form-group" *ngIf="model.customerCompany.length > 0">
                <label class="col-form-label form-control-label detail-title">
                  {{'TASK_RECORD_MANUAL_INVOICE_CREATE_CUSTOMER_COMPANY_NAME'|translate}}
                </label>
                <br>
                <label class="col-form-label form-control-label detail-description">
                  {{model.customerCompany}}
                </label>
              </div>
              <div class="form-group">
                <label class="col-form-label form-control-label detail-title">
                  {{'TASK_RECORD_MANUAL_INVOICE_CREATE_COMPANY_NAME'|translate}}
                </label>
                <br>
                <label class="col-form-label form-control-label detail-description">
                  {{model.invoiceSettingsId ? model.invoiceSettings[0].companyName : '-'}}
                </label>
              </div>
              <div class="form-group">
                <label class="col-form-label form-control-label">
                  {{'TASK_RECORD_MANUAL_INVOICE_CREATE_ADDRESS'|translate}}
                </label>
                <br>
                <label class="col-form-label form-control-label">
                  {{model.invoiceSettingsId ? formatPostalAddress(model.invoiceSettings[0].address) : '-'}}
                </label>
              </div>
              <div class="form-group">
                <label class="col-form-label form-control-label">
                  {{'TASK_RECORD_MANUAL_INVOICE_CREATE_TAX_NUMBER'|translate}}
                </label>
                <br>
                <label class="col-form-label form-control-label">
                  {{model.invoiceSettingsId ? model.invoiceSettings[0].taxNumber : '-'}}
                </label>
              </div>
              <div class="form-group"
                   [ngClass]="{ 'has-danger': hasLocalFieldError('billingInfo') || hasFieldError(TaskRecord.ManualInvoiceValidatedField.BILLING_INFO)}">
                <label class="col-form-label form-control-label required-field-label">
                  {{'TASK_RECORD_MANUAL_INVOICE_CREATE_BILLING_INFO'|translate}}
                </label>
                <angular2-multiselect [data]="billingInfoList" [settings]="simpleDropdownSettings"
                                      class="form-control"
                                      [(ngModel)]="model.billingInfo"
                                      formControlName="billingInfo">
                </angular2-multiselect>
                <div class="form-control-feedback" *ngIf="hasLocalFieldError('billingInfo', 'required')">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
                <div class="form-control-feedback" *ngIf="hasFieldError(TaskRecord.ManualInvoiceValidatedField.BILLING_INFO)">
                  {{getFieldErrorText(TaskRecord.ManualInvoiceValidatedField.BILLING_INFO)}}
                </div>
              </div>
            </div>

            <div class="form-group">
              <label class="col-form-label form-control-label">
                {{'TASK_RECORD_MANUAL_INVOICE_CREATE_COMMENT' | translate}}
              </label>
              <textarea class="form-control"
                        rows="10"
                        placeholder="{{'TASK_RECORD_MANUAL_INVOICE_CREATE_COMMENT' | translate}}"
                        [(ngModel)]="model.commentTemplate"
                        [ngModelOptions]="{standalone: true}">
              </textarea>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header">
            {{'TASK_RECORD_MANUAL_INVOICE_CREATE_ITEMS' | translate}}
          </div>
          <div class="card-body">
            <table class="table table-striped table-bordered mb-0">
              <thead>
              <tr>
                <th>
                  <app-animated-checkbox
                    [selected]="eachItemSelected"
                    (clickEvent)="toggleEachItem()">
                  </app-animated-checkbox>
                </th>
                <th class="table-sorter-header hidden-xs-down">
                  <app-table-sorter-no-op [text]="'TASK_RECORD_MANUAL_INVOICE_CREATE_ITEMS_NUMBER' | translate"></app-table-sorter-no-op>
                </th>
                <th class="table-sorter-header">
                  <app-table-sorter-no-op [text]="'TASK_RECORD_MANUAL_INVOICE_CREATE_ITEMS_NAME' | translate"></app-table-sorter-no-op>
                </th>
                <th class="table-sorter-header">
                  <app-table-sorter-no-op [text]="'TASK_RECORD_MANUAL_INVOICE_CREATE_ITEMS_AMOUNT' | translate"></app-table-sorter-no-op>
                </th>
                <th class="table-sorter-header">
                  <app-table-sorter-no-op [text]="'TASK_RECORD_MANUAL_INVOICE_CREATE_ITEMS_NET_PRICE' | translate"></app-table-sorter-no-op>
                </th>
                <th class="table-sorter-header hidden-md-down">
                  <app-table-sorter-no-op [text]="'TASK_RECORD_MANUAL_INVOICE_CREATE_ITEMS_VAT_RATE' | translate"></app-table-sorter-no-op>
                </th>
                <th class="table-sorter-header hidden-xs-down">
                  <app-table-sorter-no-op [text]="'TASK_RECORD_MANUAL_INVOICE_CREATE_ITEMS_GROSS_PRICE' | translate"></app-table-sorter-no-op>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr class="table" *ngFor="let item of items; let i = index">
                <td class="w-5">
                  <app-animated-checkbox
                    [(selected)]="item.selected">
                  </app-animated-checkbox>
                </td>
                <td class="responsive-table-column hidden-xs-down w-5">{{i + 1}}</td>
                <td class="long">{{item.name}}</td>
                <td class="responsive-table-column">{{item.amount}}</td>
                <td class="responsive-table-column">{{item.netPrice}} {{item.currencyCode}}</td>
                <td class="responsive-table-column hidden-md-down">{{item.vatRate}}%</td>
                <td class="responsive-table-column hidden-xs-down">{{item.grossPrice}} {{item.currencyCode}}</td>
              </tr>
              </tbody>
              <tbody *ngIf="items.length > 0">
              <tr class="bg-white">
                <td colspan="7">
                  <div class="row m-0 d-flex justify-content-between">
                    <h5 class="m-0">{{'INVOICE_SUM' | translate}}</h5>
                    <table>
                      <tr>
                        <td class="summary-td text-right text-muted">{{'INVOICE_SUM_NET_PRICE' | translate}}</td>
                        <td class="summary-td">{{itemSummary.net}} {{itemSummary.currencyCode}}</td>
                      </tr>
                      <tr>
                        <td class="summary-td text-right text-muted"><h6>{{'INVOICE_SUM_GROSS_PRICE' | translate}}</h6></td>
                        <td class="summary-td"><h6>{{itemSummary.gross}} {{itemSummary.currencyCode}}</h6></td>
                      </tr>
                    </table>
                  </div>
                </td>
              </tr>
              </tbody>
              <tbody *ngIf="items.length === 0">
              <tr class="bg-white">
                <td colspan="7">
                  <div class="text-center text-muted font-weight-bold p-2">
                    <h3>{{'TASK_RECORD_MANUAL_INVOICE_CREATE_ITEMS_NO_ITEMS' | translate}}</h3>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
      <!-- /.modal-body -->
      <div class="modal-footer justify-content-end">
        <span>
          <button type="button" class="btn btn-outline-primary" (click)="hide()">
            {{'COMMON_BUTTON_CANCEL' | translate}}
          </button>
          <button type="submit" class="btn btn-primary ml-1" [ladda]="creationInProgress" [disabled]="creationInProgress">
            {{'TASK_RECORD_MANUAL_INVOICE_CREATE_BUTTON' | translate}}
          </button>
        </span>
      </div>
      </form>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
