/* eslint-disable */
import { Injectable } from '@angular/core';
import { EmptyMessage } from './util/messages';
import { Observable } from 'rxjs';
import { BaseHttpService, ResourceHelper } from './util/http-services';
import { HttpClient } from '@angular/common/http';
/* eslint-enable */

export interface ApiUserKey {
  token: string;
  signature?: string;
  disabled?: boolean;
}

@Injectable()
export class ApiUserKeyService extends BaseHttpService {

  query(request: EmptyMessage): Observable<ApiUserKey> {
    return this.http.get<ApiUserKey>(this.url);
  }

  regenerate(request: EmptyMessage): Observable<ApiUserKey> {
    return this.http.post<ApiUserKey>(this.url + `regenerate`, request);
  }

  activate(request: EmptyMessage): Observable<ApiUserKey> {
    return this.http.post<ApiUserKey>(this.url + `activate`, request);
  }

  inactivate(request: EmptyMessage): Observable<ApiUserKey> {
    return this.http.post<ApiUserKey>(this.url + `inactivate`, request);
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/api-user-key/');
  }

}
