/* eslint-disable */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  DqlBinaryCriteriaModel,
  DqlCriteriaModel,
  DqlExpressionBuilder,
  SimpleDqlExpressionBuilder
} from '../../dql-search-container/dql-search-container.model';
import { DqlSearchFieldComponent } from '../dql-search-field.component';
import { InputMask } from '../../../../util/input-masks';
import { DqlModel } from '../../../../lib/dql/dql.model';
/* eslint-enable */

@Component({
  selector: 'app-dql-search-field-simple',
  templateUrl: './dql-search-field-simple.component.html',
  styleUrls: ['./dql-search-field-simple.component.scss']
})
export class DqlSearchFieldSimpleComponent implements OnInit, DqlSearchFieldComponent {
  @Input()
  disableDelete: boolean = false;

  @Output()
  onDelete: EventEmitter<DqlExpressionBuilder> = new EventEmitter<DqlExpressionBuilder>();

  InputMask = InputMask;

  @Input()
  expression: SimpleDqlExpressionBuilder;

  constructor() {
  }

  ngOnInit() {
  }

  get textMask() {
    let mask: any = false;
    if (this.expression && this.expression.criteria.field) {
      switch (this.expression.criteria.field.dataType) {
        case DqlModel.QueryableFieldDataType.STRING:
        case DqlModel.QueryableFieldDataType.PHONE_NUMBER:
        case DqlModel.QueryableFieldDataType.EMAIL_ADDRESS:
          mask = false;
          break;
        case DqlModel.QueryableFieldDataType.DECIMAL:
          mask = this.InputMask.NATURAL_FLOAT;
          break;
        case DqlModel.QueryableFieldDataType.NUMBER:
          mask = this.InputMask.NATURAL_INTEGER;
          break;
      }
    }
    return {mask: mask, guide: false};
  }

}
