<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div *ngIf="rightModel.appFileUpdate.hasRight()"
       class="breadcrumb-menu d-flex">
    <div *ngIf="rightModel.appFileCreate.hasRight()"
         (click)="showBaseDialog()"
         class="breadcrumb-button-icon-container negative-margin-right cursor-pointer">
      <a class="btn-setting" title="{{'APPLICATION_FILE_CREATE' | translate}}">
        <i class="icomoon icomoon-add"></i>
        <span class="breadcrumb-button-text-visible">{{'APPLICATION_FILE_CREATE' | translate}}</span>
      </a>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>

<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="card">

    <div class="card-header">
      {{'MENU_NAVBAR_APPLICATION_FILES' | translate}}
      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()"
           title="{{'COMMON_SHOW_SEARCH_HINT' | translate}}"><i class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>

    <div class="card-body border-bottom" *ngIf="showSearch">
      <div class="row form-group">
        <div class="col-md-3">
          <label class="search-label">{{'APPLICATION_FILE_CODE' | translate}}</label>
          <input type="text" class="form-control"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 placeholder="{{'APPLICATION_FILE_CODE' | translate}}"
                 [(ngModel)]="searchModel.code">
        </div>
        <!--<div class="col-md-3">-->
          <!--<label class="search-label">{{'COMMON_TYPE' | translate}}</label>-->
          <!--<input type="text" class="form-control"-->
                 <!--maxlength="{{UiConstants.maximumVarcharLength}}"-->
                 <!--(keyup.enter)="onSearchClicked()"-->
                 <!--placeholder="{{'COMMON_TYPE' | translate}}"-->
                 <!--[(ngModel)]="searchModel.contentType">-->
        <!--</div>-->
      </div>
      <div class="col-md-12 d-flex justify-content-md-end align-items-end px-0">
        <div class="btn-group" role="group">
          <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
            {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
          </button>
          <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
            {{'COMMON_BUTTON_SEARCH' | translate}}
          </button>
        </div>
      </div>
    </div><!-- /.card-body-->

    <div class="card-body">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header w-5 hidden-md-down">
            <app-table-field-sorter [orderField]="ApplicationFile.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(ApplicationFile.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header ">
            <app-table-field-sorter [orderField]="ApplicationFile.OrderField.CODE"
                                    [orderType]="queryModel.getOrderType(ApplicationFile.OrderField.CODE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'APPLICATION_FILE_CODE' | translate"></app-table-field-sorter>
          </th>
          <!--<th class="table-sorter-header ">-->
            <!--<app-table-field-sorter [orderField]="ApplicationFile.OrderField.CONTENT_TYPE"-->
                                    <!--[orderType]="queryModel.getOrderType(ApplicationFile.OrderField.CONTENT_TYPE)"-->
                                    <!--(orderChange)="orderBy($event)"-->
                                    <!--[text]="'COMMON_TYPE' | translate"></app-table-field-sorter>-->
          <!--</th>-->
          <th class="table-sorter-header hidden-md-down">
            <app-table-field-sorter [orderField]="ApplicationFile.OrderField.CONTENT_UPDATE_TIME"
                                    [orderType]="queryModel.getOrderType(ApplicationFile.OrderField.CONTENT_UPDATE_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_UPDATE_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-table-actions-four">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let applicationFile of applicationFileList | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }">
          <td class="responsive-table-column w-5 hidden-md-down">{{applicationFile.id}}</td>
          <td class="responsive-table-column ">{{applicationFile.code}}</td>
          <!--<td class="responsive-table-column ">{{applicationFile.contentType}}</td>-->
          <td class="responsive-table-column hidden-md-down">{{applicationFile.contentUpdateTime | date:'short'}}</td>
          <td class="responsive-table-column">
            <a *ngIf="rightModel.tableDocumentUpdate.hasRight()"
               class="icons-table-group cursor-pointer"
               (click)="showBaseDialog(applicationFile)">
              <i class="icomoon icons-table-item icomoon-modify icomoon-modify-table"
                 title="{{'COMMON_BUTTON_EDIT' | translate}}"></i>
            </a>
            <a *ngIf="rightModel.appFileDelete.hasRight()"
            class="icons-table-group cursor-pointer" (click)="openDeleteModal(applicationFile)">
            <i class="icomoon icons-table-item icomoon-trash icomoon-disable-table"
            title="{{'COMMON_BUTTON_DELETE' | translate}}"></i>
            </a>
            <a *ngIf="rightModel.tableDocumentRead.hasRight() && applicationFile.content"
            class="icons-table-group cursor-pointer" (click)="exportFile(applicationFile)">
            <i class="icomoon icons-table-item icomoon-csv-export"
            title="{{'COMMON_EXPORT' | translate}}"></i>
            </a>
            <a *ngIf="rightModel.appFileUpload.hasRight()"
            class="icons-table-group cursor-pointer" (click)="importFile(applicationFile)">
            <i class="icomoon icons-table-item icomoon-csv-import"
            title="{{'COMMON_IMPORT' | translate}}"></i>
            </a>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header w-5 hidden-md-down">
            <app-table-field-sorter [orderField]="ApplicationFile.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(ApplicationFile.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header ">
            <app-table-field-sorter [orderField]="ApplicationFile.OrderField.CODE"
                                    [orderType]="queryModel.getOrderType(ApplicationFile.OrderField.CODE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'APPLICATION_FILE_CODE' | translate"></app-table-field-sorter>
          </th>
          <!--<th class="table-sorter-header ">-->
            <!--<app-table-field-sorter [orderField]="ApplicationFile.OrderField.CONTENT_TYPE"-->
                                    <!--[orderType]="queryModel.getOrderType(ApplicationFile.OrderField.CONTENT_TYPE)"-->
                                    <!--(orderChange)="orderBy($event)"-->
                                    <!--[text]="'COMMON_TYPE' | translate"></app-table-field-sorter>-->
          <!--</th>-->
          <th class="table-sorter-header hidden-md-down">
            <app-table-field-sorter [orderField]="ApplicationFile.OrderField.CONTENT_UPDATE_TIME"
                                    [orderType]="queryModel.getOrderType(ApplicationFile.OrderField.CONTENT_UPDATE_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_UPDATE_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-table-actions-four">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>
  </div>
</div>

<!--BaseDialog Modal-->
<div bsModal [config]="UiConstants.modalConfig" #baseDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="baseDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{getBaseModalTitle() | translate}}</h4>
        <button type="button" class="close" (click)="closeBaseDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" [formGroup]="formGroup" #f="ngForm">
        <div class="form-group row"
             [ngClass]="{ 'has-danger': hasLocalFieldError('code') || fieldErrors.code}">
          <label class="col-form-label col-md-4">{{'LIST_ITEM_CODE' | translate}}</label>
          <div class="col-md-8">
            <input *ngIf="applicationFileEditModel.status === ApplicationFileEditStatus.CREATE"
                   type="text" class="form-control"
                   placeholder="{{'LIST_ITEM_CODE' | translate}}"
                   maxlength="{{UiConstants.maximumVarcharLength}}"
                   [ngClass]="{ 'form-control-danger': hasLocalFieldError('code') }"
                   [(ngModel)]="applicationFileEditModel.code"
                   formControlName="code"
                   (paste)="onCodePaste()"
                   (ngModelChange)="onCodeChanged()">
            <label
              *ngIf="applicationFileEditModel.status === ApplicationFileEditStatus.EDIT"
              class="form-control detail-description"
              readonly>{{applicationFileEditModel.code}}
            </label>
            <div class="form-control-feedback"
                 *ngIf="fieldErrors.code">{{fieldErrors.code.text}}</div>
            <div class="form-control-feedback"
                 *ngIf="hasLocalFieldError('code')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
          </div>
          <!-- /.col-md-8 -->
        </div>

        <div class=" form-group row">
          <label class="col-form-label col-md-4">{{'APPLICATION_DOCUMENT_NOTE' | translate}}</label>
          <div class="col-md-8">
            <input type="text"
                   class="form-control"
                   formControlName="note"
                   placeholder="{{'APPLICATION_DOCUMENT_NOTE' | translate}}"
                   maxlength="{{UiConstants.maximumVarcharLength}}"
                   [(ngModel)]="applicationFileEditModel.note"
            >
          </div>
          <!-- /.col-md-8 -->
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeBaseDialog()">
          {{'COMMON_BUTTON_CANCEL' | translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="createApplicationDocument()">{{'COMMON_BUTTON_SAVE' |
          translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<app-file-upload-dialog
[uploadPath]="fileImportPath"
(onResult)="onImportSuccess($event)"
#fileImportDialog
></app-file-upload-dialog>

<div bsModal [config]="UiConstants.modalConfig" #deleteModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="deleteModalVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'TABLE_DOCUMENT_SCHEMA_DELETE' | translate}}</h4>
        <button type="button" class="close" (click)="closeDeleteModal()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group col-md-12 mb-0">
          <label>{{'TABLE_DOCUMENT_SCHEMA_DELETE_LABEL' | translate}}</label>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeDeleteModal()">
          {{'COMMON_BUTTON_CANCEL' | translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="deleteFile()">{{'COMMON_BUTTON_DELETE' |
          translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
