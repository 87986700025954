/* eslint-disable */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Transport, TransportService } from '../../../lib/transport/transport.service';
import {
  ForwardingNgFormRef,
  LocalFormGroupValidationErrors,
  OrderType,
  QueryResult,
  ResourceQueryResult,
  Services
} from '../../../lib/util/services';
import { Transition, UIRouter } from '@uirouter/angular';
import { TranslateService } from '@ngx-translate/core';
import { RightResolver, RightService } from '../../../lib/right.service';
import { RightModel } from '../../../app.rights';
import { Strings } from '../../../lib/util/strings';
import { MultiselectOptionItem, QueryFieldModel, SelectUtils, UiConstants } from '../../../util/core-utils';
import { Set } from 'immutable';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { InputMask } from '../../../util/input-masks';
import { combineLatest, Observable } from 'rxjs';
import { TransportSearch, TransportSearchService } from '../../../lib/transport/transport-search.service';
import { Models } from '../../../util/model-utils';
import { Angular2Multiselects } from '../../../util/multiselect';
import { StringKey } from '../../../app.string-keys';
import { Vehicle, VehicleService } from '../../../lib/vehicles/vehicle.service';
import { User, UserService } from '../../../lib/user.service';
import { OffsetDateTime } from '../../../lib/util/dates';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { Company, CompanyService } from '../../../lib/company/company.service';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ToasterService } from '../../../fork/angular2-toaster/src/toaster.service';
import { StateName } from '../../../app.state-names';
import { DqlStoredQueryArgs } from '../../dql-search/dql-search-container/dql-stored-query.args';
import { DqlSearchContainerComponent } from '../../dql-search/dql-search-container/dql-search-container.component';
import { TransportDqlFieldTextProvider } from '../../../lib/transport/transport.dql.service';
import TransportState = Transport.TransportState;
import transportStates = Transport.transportStates;
import CompanyType = Company.CompanyType;
import { AlertType } from '../../../shared/confirm-dialog/confirm-dialog.component';

/* eslint-enable */

@Component({
  selector: 'app-transport-list',
  templateUrl: './transport-list.component.html',
  styleUrls: ['./transport-list.component.scss']
})
export class TransportListComponent implements OnInit, OnDestroy {

  Transport = Transport;
  InputMask = InputMask;
  UiConstants = UiConstants;
  SelectUtils = SelectUtils;
  AlertType = AlertType;

  @ViewChild('transportEmptyCreateDialog', { static: true })
  transportEmptyCreateDialog: ModalDirective;

  @ViewChild('f')
  form?: NgForm;

  transportEmptyCreateDialogVisible: boolean = false;
  transportEmptyCreateModel: TransportEmptyCreateModel = new TransportEmptyCreateModel();
  transporterCompanyList: MultiselectOptionItem<number>[] = [];
  vehicleList: MultiselectOptionItem<number>[] = [];
  driverList: MultiselectOptionItem<number>[] = [];
  singleDropdownSettings: Angular2Multiselects.Settings;
  formGroup: FormGroup;
  private formGroupValidationErrors: LocalFormGroupValidationErrors;

  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  showSearch: boolean = false;

  dqlStoredArgs: DqlStoredQueryArgs;
  activeSearchTab: string = 'simple';

  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  rightModel: RightModel = RightModel.empty();

  searchModel: TransportSearchModel = new TransportSearchModel();
  queryModel: QueryFieldModel<Transport.OrderField> = new QueryFieldModel(Transport.OrderField.ID, OrderType.DESC);

  transportList: TransportModel[] = [];
  states: TransportSearch.StateItem[] = [];

  searchResult: TransportSearch.SearchDataResult;

  dropdownSettingsForVehicle: Angular2Multiselects.Settings;
  vehiclesForSearch: TransportSearch.VehicleItem[] = [];

  dropdownSettingsForDriver: Angular2Multiselects.Settings;
  driversForSearch: TransportSearch.DriverItem[] = [];

  dropdownSettingsForState: Angular2Multiselects.Settings;
  statesForSearch: TransportSearch.StateItem[] = [];

  showMap: boolean = false;

  latestMapRefreshTime?: OffsetDateTime = undefined;

  private _searchTabs?: TabsetComponent;

  @ViewChild('searchTabs') set searchTabs(c: TabsetComponent) {
    if (c && !this._searchTabs) {
      this._searchTabs = c;
      if (this.searchModel.dqlText) {
        this.activeSearchTab = 'dql';
        this._searchTabs.tabs[1].active = true;
      }
      else {
        this.activeSearchTab = 'simple';
        this._searchTabs.tabs[0].active = true;
      }
    }
    if (!c) {
      this._searchTabs = c;
    }
  }

  private dqlSearchContainer?: DqlSearchContainerComponent;

  @ViewChild('dqlSearchContainer') set dqlContainer(c: DqlSearchContainerComponent) {
    if (c) {
      if (!this.dqlSearchContainer) {
        const qt = this.searchModel.dqlText;
        this.dqlSearchContainer = c;
        this.loadDqlModel(() => {
          this.loadDqlSearch(qt);
          this.dqlSearchContainer!.loadContent();
        });
      }
    }
    else {
      this.dqlSearchContainer = undefined;
    }
  }

  constructor(
    private transportService: TransportService,
    private translateService: TranslateService,
    private rightService: RightService,
    private companyService: CompanyService,
    private transportSearchService: TransportSearchService,
    private vehicleService: VehicleService,
    private uiRouter: UIRouter,
    private toasterService: ToasterService,
    private transition: Transition,
    private userService: UserService,
    fb: FormBuilder
  ) {
    this.formGroup = this.createFormGroup(fb);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(this.createForwardingHtmlForm(), this.formGroup);
    this.dqlStoredArgs = {service: transportService.dqlStoredQueryService, parentId: undefined, documentType: undefined};
  }

  ngOnInit() {
    this.initDropdownSettings();
    this.loadRightModels();
    this.initBreadcrumb();
    this.loadSearch(() => {
      this.showSearch = !this.searchModel.isEmpty();
      this.loadList();
    });
  }

  ngOnDestroy() {
    this.saveSearch();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  initBreadcrumb() {
    this.translateService.get('MENU_NAVBAR_MENU_TRANSPORT').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
  }

  loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();
    const idSet: number[] = [];
    if (Strings.undefinedOrNonEmpty(this.searchModel.id)) {
      idSet.push(+this.searchModel.id);
    }
    const stateSet: TransportState[] = [];
    this.searchModel.stateIds.forEach((item) => {
      stateSet.push(transportStates.find((state) => state.state === item.id)!.state);
    });

    this.transportService.query({
      id: idSet.length > 0 ? Set.of(...idSet) : undefined,
      waybillNumber: Strings.undefinedOrNonEmpty(this.searchModel.waybillNumber),
      vehicleIds: Set.of(...this.searchModel.vehicleIds.map(t => t.id)),
      driverIds: Set.of(...this.searchModel.driverIds.map(t => t.id)),
      safetyShipping: this.searchModel.safetyShipping ? this.searchModel.safetyShipping : undefined,
      state: stateSet.length > 0 ? Set.of(...stateSet) : undefined,
      creationTimeFrom: this.searchModel.creationTimeFrom ? Models.parseDateTimeFrom(this.searchModel.creationTimeFrom) : undefined,
      creationTimeTo: this.searchModel.creationTimeTo ? Models.parseDateTimeTo(this.searchModel.creationTimeTo) : undefined,
      openTimeFrom: this.searchModel.openTimeFrom ? Models.parseDateTimeFrom(this.searchModel.openTimeFrom) : undefined,
      openTimeTo: this.searchModel.openTimeTo ? Models.parseDateTimeTo(this.searchModel.openTimeTo) : undefined,
      closeTimeFrom: this.searchModel.closeTimeFrom ? Models.parseDateTimeFrom(this.searchModel.closeTimeFrom) : undefined,
      closeTimeTo: this.searchModel.closeTimeTo ? Models.parseDateTimeTo(this.searchModel.closeTimeTo) : undefined,
      shippingItem: Strings.undefinedOrNonEmpty(this.searchModel.shippingItem),
      withMessageCount: true,
      withShippingDemands: true,
      withShipments: true,
      orders: Set.of(order),
      dqlText: this.searchModel.dqlText,
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined
    }).subscribe((result: QueryResult<Transport.Transport>) => {

      this.transportList = [];
      result.items.forEach((transport: Transport.Transport) => {

        const transportModel = new TransportModel();
        transportModel.id = transport.id + '';
        transportModel.waybillNumber = transport.waybillNumber;
        transportModel.transporterCompany = transport.transporterCompany.name;
        transportModel.vehicleLicencePlate = transport.vehicle ? transport.vehicle.licencePlate : '';
        transportModel.driverName = transport.driver ? transport.driver.personName : '';
        transportModel.creationTime = transport.creationTime.toUtcIsoString();
        transportModel.startTime = transport.startTime ? transport.startTime.toUtcIsoString() : '';
        transportModel.closeTime = transport.closeTime ? transport.closeTime.toUtcIsoString() : '';
        transportModel.state = this.states.filter((s) => s.id === transport.state)[0].name;
        transportModel.stateObject = Transport.transportStates.find(s => s.state === transport.state)!;
        transportModel.securityGuard = transport.securityGuard;
        transportModel.safetyShipping = transport.safetyShipping;
        transportModel.messageCount = transport.chatMessageCount ? transport.chatMessageCount : 0;
        transportModel.attachmentCount = transport.attachmentCount ? transport.attachmentCount : 0;
        transportModel.shipments = transport.shipments;
        transportModel.shippingDemands = transport.shippingDemands;
        this.transportList.push(transportModel);
      });
      this.queryModel.currentPage = requestedPage;
      this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
    });
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  loadDqlSearch(queryString?: string) {
    if (queryString) {
      this.dqlSearchContainer!.loadQuery(queryString);
    }
  }

  loadDqlModel(completion?: () => void) {
    this.dqlSearchContainer!.setFields(this.transportService.getDqlModel(),
      TransportDqlFieldTextProvider.getHelper(),
      completion ? completion : () => {
      });
  }

  toggleMap() {
    this.latestMapRefreshTime = undefined;
    this.showMap = !this.showMap;
  }

  orderBy(field: Transport.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }


  onSearchReset() {
    this.transportSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.showSearch = true;
          this.loadList(1);
        });
      }
    );
  }

  private loadSearch(completion: () => void) {
    const obs: Observable<SearchLoadResult> = combineLatest(
      this.transportSearchService.getSearchData({}),
      (storedSearchData: TransportSearch.SearchDataResult) => {
        const result: SearchLoadResult = {
          storedSearchData: storedSearchData
        };
        return result;
      }
    );
    obs.subscribe(
      (result: SearchLoadResult) => {
        this.searchResult = result.storedSearchData;
        this.loadVehiclesForSearch(result.storedSearchData, () => {
          this.loadDriversForSearch(result.storedSearchData, () => {
            this.loadStatesForSearch(result.storedSearchData, () => {
              this.postInitSearch(result.storedSearchData);
              completion();
            });
          });
        });
      }
    );

  }

  private loadVehiclesForSearch(storedSearchData: TransportSearch.SearchDataResult, completion: () => void) {
    this.searchModel.vehicleIds = [];
    this.vehicleService.query({
      disabled: false,
      orders: Set.of({field: Vehicle.OrderField.LICENCE_PLATE, type: OrderType.ASC})
    }).subscribe(
      (vehicles: QueryResult<Vehicle.Vehicle>) => {
        this.vehiclesForSearch = [];
        vehicles.items.forEach((vehicle: Vehicle.Vehicle) => {
          const item = new TransportSearch.VehicleItem();
          item.id = vehicle.id;
          item.name = vehicle.licencePlate;
          this.vehiclesForSearch.push(item);
          if (storedSearchData.searchData.vehicleIds.find(t => t.id === item.id)) {
            this.searchModel.vehicleIds.push(item);
          }
        });
        completion();
      }
    );
  }

  private loadDriversForSearch(storedSearchData: TransportSearch.SearchDataResult, completion: () => void) {
    this.searchModel.driverIds = [];
    this.userService.query({
      is_driver: true,
      order: Services.createOrderFieldParameter(User.Keys.toOrderFieldKey,
        Set.of({field: User.OrderField.PERSON_NAME, type: OrderType.ASC}))
    }).subscribe((result: ResourceQueryResult<User>) => {
      this.driversForSearch = [];
      result.items.forEach((driver) => {
        const item = new TransportSearch.DriverItem();
        item.id = driver.id;
        item.name = (driver.person_name) ? driver.person_name : '';
        this.driversForSearch.push(item);
        if (storedSearchData.searchData.driverIds.find(t => t.id === item.id)) {
          this.searchModel.driverIds.push(item);
        }
      });
      completion();
    });
  }

  private loadStatesForSearch(storedSearchData: TransportSearch.SearchDataResult, completion: () => void) {
    this.searchModel.stateIds = [];
    this.loadStates(() => {
      this.statesForSearch = [];
      this.states.forEach((state) => {
        const item = new TransportSearch.StateItem();
        item.id = state.id;
        item.name = state.name;
        this.statesForSearch.push(item);
        if (storedSearchData.searchData.stateIds.find(t => t.id === item.id)) {
          this.searchModel.stateIds.push(item);
        }
      });
      completion();
    });
  }

  private loadStates(completion: () => void) {
    this.states = [];
    Transport.transportStates.forEach(
      (state) => {
        const item = {
          id: state.state,
          itemName: state.stringKey,
          name: state.stringKey,
        };
        this.states.push(item);
      });
    this.states.forEach(
      (state: TransportSearch.StateItem) => {
        this.translateService.get(state.name).subscribe((stateName: string) => {
            state.name = stateName;
          }
        );
      });
    completion();
  }


  private postInitSearch(storedSearchData: TransportSearch.SearchDataResult) {
    this.queryModel.itemsPerPage = storedSearchData.searchData.itemsPerPage;
    this.queryModel.currentPage = storedSearchData.searchData.pageNumber;
    this.queryModel.setOrder(storedSearchData.searchData.order);
    this.searchModel.id = storedSearchData.searchData.id;
    this.searchModel.waybillNumber = storedSearchData.searchData.waybillNumber;
    this.searchModel.safetyShipping = storedSearchData.searchData.safetyShipping ?
      storedSearchData.searchData.safetyShipping : undefined;
    this.searchModel.creationTimeFrom = storedSearchData.searchData.creationTimeFrom ?
      Models.localDateToNgbDate(storedSearchData.searchData.creationTimeFrom) : undefined;
    this.searchModel.creationTimeTo = storedSearchData.searchData.creationTimeTo ?
      Models.localDateToNgbDate(storedSearchData.searchData.creationTimeTo) : undefined;
    this.searchModel.openTimeFrom = storedSearchData.searchData.openTimeFrom ?
      Models.localDateToNgbDate(storedSearchData.searchData.openTimeFrom) : undefined;
    this.searchModel.openTimeTo = storedSearchData.searchData.openTimeTo ?
      Models.localDateToNgbDate(storedSearchData.searchData.openTimeTo) : undefined;
    this.searchModel.closeTimeFrom = storedSearchData.searchData.closeTimeFrom ?
      Models.localDateToNgbDate(storedSearchData.searchData.closeTimeFrom) : undefined;
    this.searchModel.closeTimeTo = storedSearchData.searchData.closeTimeTo ?
      Models.localDateToNgbDate(storedSearchData.searchData.closeTimeTo) : undefined;
    this.searchModel.shippingItem = storedSearchData.searchData.shippingItem;
    this.searchModel.dqlText = storedSearchData.searchData.dqlText;
  }

  private saveSearch() {
    const request = {
        searchData: {
          itemsPerPage: this.queryModel.itemsPerPage,
          pageNumber: this.queryModel.currentPage,
          order: this.queryModel.getOrder(),
          id: this.searchModel.id,
          waybillNumber: this.searchModel.waybillNumber,
          vehicleIds: this.searchModel.vehicleIds,
          driverIds: this.searchModel.driverIds,
          companyId: this.searchModel.companyId,
          safetyShipping: this.searchModel.safetyShipping,
          stateIds: this.searchModel.stateIds,
          closeTimeFrom: Models.ngbDateToLocalDate(this.searchModel.closeTimeFrom),
          closeTimeTo: Models.ngbDateToLocalDate(this.searchModel.closeTimeTo),
          creationTimeFrom: Models.ngbDateToLocalDate(this.searchModel.creationTimeFrom),
          creationTimeTo: Models.ngbDateToLocalDate(this.searchModel.creationTimeTo),
          openTimeFrom: Models.ngbDateToLocalDate(this.searchModel.openTimeFrom),
          openTimeTo: Models.ngbDateToLocalDate(this.searchModel.openTimeTo),
          shippingItem: this.searchModel.shippingItem,
          dqlText: this.searchModel.dqlText
        }
      }
    ;
    this.transportSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  onSearchClicked() {
    this.loadList(1);
  }

  initDropdownSettings() {
    this.dropdownSettingsForVehicle = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .build();
    this.dropdownSettingsForDriver = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .build();
    this.dropdownSettingsForState = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .build();
    this.singleDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  getSafetyShippingIcon(safetyShipping: boolean): string {
    if (safetyShipping) {
      return 'icomoon icomoon-safety-shipping';
    }
    return '';
  }

  onMapRefresh(date: OffsetDateTime) {
    this.latestMapRefreshTime = date;
  }

  private createFormGroup(fb: FormBuilder): FormGroup {
    return fb.group(
      {
        transporter: fb.control(
          {value: this.transportEmptyCreateModel.transporterCompanyId},
          [
            Validators.required,
          ]
        )
      }
    );
  }

  private createForwardingHtmlForm() {
    return new ForwardingNgFormRef({
      formFn: () => {
        return this.form;
      }
    });
  }

  private createTransporterCompaniesObs(): Observable<QueryResult<Company.Company>> {
    return this.companyService.query({
      type: Set.of(<CompanyType>'TRANSPORTER'),
      disabled: false,
      orders: Set.of({field: Company.OrderField.NAME, type: OrderType.ASC})
    });
  }

  private createVehiclesObs(): Observable<QueryResult<Vehicle.Vehicle>> {
    return this.vehicleService.query({
      disabled: false
    });
  }

  private createDriversObs(): Observable<ResourceQueryResult<User>> {
    return this.userService.query({
      disabled: false,
      is_driver: true
    });
  }

  private loadStuffForTransportEmptyCreateDialog(completion: () => void) {
    combineLatest(
      this.createTransporterCompaniesObs(),
      this.createVehiclesObs(),
      this.createDriversObs(),
      (trs: QueryResult<Company.Company>, vs: QueryResult<Vehicle.Vehicle>, ds: ResourceQueryResult<User>) => {
        return {
          transporterCompanies: trs.items.toArray(),
          vehicles: vs.items.toArray(),
          drivers: ds.items
        };
      }
    ).subscribe(result => {
      this.transporterCompanyList = result.transporterCompanies.map(c => ({id: c.id, itemName: c.name}));
      this.vehicleList = result.vehicles.map(v => ({id: v.id, itemName: v.licencePlate + ' (' + v.company.name + ')'}));
      this.driverList = result.drivers.map(u => ({id: u.driver!.driver_id!, itemName: u.person_name}));
      completion();
    });
  }

  openTransportEmptyCreateDialog() {
    this.loadStuffForTransportEmptyCreateDialog(() => {
      if (this.transporterCompanyList.length === 0) {
        this.toasterService.pop({
          timeout: UiConstants.ToastTimeoutLong,
          type: UiConstants.toastTypeError,
          title: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_TITLE),
          body: this.translateService.instant(StringKey.TRANSPORT_EMPTY_CREATE_NO_TRANSPORTER_COMPANIES)
        });
      }
      else {
        this.formGroup.get('transporter')!.markAsUntouched();
        this.transportEmptyCreateDialogVisible = true;
        this.transportEmptyCreateDialog.show();
      }
    });
  }

  closeTransportEmptyCreateDialog() {
    this.transportEmptyCreateModel.reset();
    this.transportEmptyCreateDialogVisible = false;
    this.transportEmptyCreateDialog.hide();
  }

  private loadVehicles(q?: string) {
    this.vehicleService.query({
      licencePlate: q ? Strings.undefinedOrNonEmpty(q) : undefined,
      disabled: false,
      paging: {
        numberOfItems: UiConstants.autocompletePageSize,
        pageNumber: 1
      },
      noProgressBar: true
    }).subscribe((result: QueryResult<Vehicle.Vehicle>) => {
      this.vehicleList = result.items.toArray().map(v => ({id: v.id, itemName: v.licencePlate + '(' + v.company.name + ')'}));
    });
  }

  private loadDrivers(q?: string) {
    this.userService.query({
      person_name: q ? Strings.undefinedOrNonEmpty(q) : undefined,
      disabled: false,
      is_driver: true,
      number_of_items: UiConstants.autocompletePageSize,
      page_number: 1,
      order: Services.createOrderFieldParameter(User.Keys.toOrderFieldKey, Set.of(User.DEFAULT_ORDER)),
      no_progress_bar: true
    }).subscribe((result: ResourceQueryResult<User>) => {
      this.driverList = result.items.map(u => ({id: u.driver!.driver_id!, itemName: u.person_name}));
    });
  }

  private loadTransporterCompanies(q?: string) {
    this.companyService.query({
      name: q,
      type: Set.of(<Company.CompanyType>'TRANSPORTER'),
      disabled: false,
      orders: Set.of({field: Company.OrderField.NAME, type: OrderType.ASC}),
      paging: {
        numberOfItems: UiConstants.autocompletePageSize,
        pageNumber: 1
      },
      noProgressBar: true
    }).subscribe((result: QueryResult<Company.Company>) => {
      this.transporterCompanyList = result.items.toArray().map(c => ({id: c.id, itemName: c.name}));
    });
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  createEmptyTransport() {
    this.formGroup.updateValueAndValidity();
    if (this.formGroup.invalid) {
      this.formGroup.get('transporter')!.markAsTouched();
      return;
    }
    this.transportService.createEmpty({
      transporterCompanyId: this.transportEmptyCreateModel.transporterCompanyId!,
      vehicleId: this.transportEmptyCreateModel.vehicleId,
      driverId: this.transportEmptyCreateModel.driverId,
      safetyShipping: this.transportEmptyCreateModel.safetyShipping
    }).subscribe(
      (response: Transport.CreateResponse) => {
        this.uiRouter.stateService.go(StateName.TRANSPORT_DETAIL, {id: response.transportId});
      }
    );
  }

  deleteTransport(id: number) {
    this.transportService.delete({id: id}).subscribe((result) => {
      this.loadList();
    });
  }

  onDqlSearchClicked() {
    const dqlQueryString = this.dqlSearchContainer!.getQueryString();
    if (dqlQueryString) {
      this.searchModel = new TransportSearchModel();
    }
    this.searchModel.dqlText = dqlQueryString;
    this.loadList(1);
  }

}

class TransportModel {
  id: string = '';
  waybillNumber: string = '';
  transporterCompany: string = '';
  vehicleLicencePlate: string = '';
  driverName: string = '';
  creationTime: string = '';
  startTime: string = '';
  closeTime: string = '';
  safetyShipping: boolean = false;
  securityGuard?: Transport.SecurityGuard;
  state: string = '';
  stateObject: Transport.TransportStateObject;
  attachmentCount: number = 0;
  messageCount: number = 0;
  shippingDemands?: Set<Transport.ShippingDemand>;
  shipments?: Set<Transport.Shipment>;
}

class TransportSearchModel {
  id: string = '';
  waybillNumber: string = '';
  vehicleIds: TransportSearch.VehicleItem[] = [];
  driverIds: TransportSearch.DriverItem[] = [];
  companyId: string = '';
  safetyShipping?: boolean = undefined;
  stateIds: TransportSearch.StateItem[] = [];
  closeTimeFrom?: NgbDateStruct | null = null;
  closeTimeTo?: NgbDateStruct | null = null;
  creationTimeFrom?: NgbDateStruct | null = null;
  creationTimeTo?: NgbDateStruct | null = null;
  openTimeFrom?: NgbDateStruct | null = null;
  openTimeTo?: NgbDateStruct | null = null;
  shippingItem: string = '';
  dqlText?: string = undefined;

  public isEmpty(): boolean {
    return this.id.length === 0
      && this.waybillNumber.length === 0
      && this.vehicleIds.length === 0
      && this.driverIds.length === 0
      && this.companyId.length === 0
      && this.safetyShipping === undefined
      && this.stateIds.length === 0
      && this.closeTimeFrom === null
      && this.closeTimeTo === null
      && this.creationTimeFrom === null
      && this.creationTimeTo === null
      && this.openTimeFrom === null
      && this.openTimeTo === null
      && this.shippingItem.length === 0
      && this.dqlText === undefined;
  }
}


interface SearchLoadResult {
  storedSearchData: TransportSearch.SearchDataResult,
}

class TransportEmptyCreateModel {
  transporterCompany: MultiselectOptionItem<number>[] = [];
  vehicle: MultiselectOptionItem<number>[] = [];
  driver: MultiselectOptionItem<number>[] = [];
  safetyShipping: boolean = false;

  get transporterCompanyId(): number | undefined {
    return this.transporterCompany.length === 1 ? this.transporterCompany[0].id : undefined;
  }

  get vehicleId(): number | undefined {
    return this.vehicle.length === 1 ? this.vehicle[0].id : undefined;
  }

  get driverId(): number | undefined {
    return this.driver.length === 1 ? this.driver[0].id : undefined;
  }

  reset() {
    this.transporterCompany = [];
    this.vehicle = [];
    this.driver = [];
    this.safetyShipping = false;
  }
}
