/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { QueryResult, ResourceQueryResult, Services } from '../util/services';
import { ShippingItemResource, ShippingItemResourceService } from './shipping-item-resource.service';
import { List } from 'immutable';
import { ShippingDemand } from '../shipping-demand/shipping-demand.service';
import { ShippingDemandResource } from '../shipping-demand/shipping-demand-resource.service';
/* eslint-enable */

@Injectable()
export class ShippingItemService implements ShippingItem.Service {

  constructor(private resourceService: ShippingItemResourceService) {
  }

  query(request: ShippingItem.QueryRequest): Observable<QueryResult<ShippingItem.ShippingItem>> {
    return Observable.create((observer: Observer<QueryResult<ShippingItem.ShippingItem>>) => {
      const resourceRequest: ShippingItemResource.QueryRequest = {
        demand_id: request.demandId,
      };
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<ShippingItemResource.ShippingItem>) => {
          observer.next({
            items: this.toPublicList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  private toPublicList(resourceList: ShippingItemResource.ShippingItem[]): List<ShippingItem.ShippingItem> {
    return List.of(...resourceList.map((r) => this.toPublic(r)));
  }

  private toPublic(r: ShippingItemResource.ShippingItem): ShippingItem.ShippingItem {
    return {
      id: r.id,
      externalId: r.external_id,
      demandId: r.demand_id,
      shipmentId: r.shipment_id,
      name: r.name,
      category: r.category,
      productCode: r.product_code,
      amount: r.amount,
      box: r.box,
      label: r.label,
      weightInGram: r.weight_in_gram,
      price: this.toPublicPrice(r.price),
      outtaken: r.outtaken,
      comment: r.comment,
      itemSize: this.toPublicItemSize(r.item_size)
    };
  }

  private toPublicPrice(request?: ShippingItemResource.Price): ShippingItem.Price | undefined {
    if (request) {
      return {
        amount: request.amount,
        currencyCode: request.currency_code
      };
    }
    return undefined;
  }

  private toPublicItemSize(request?: ShippingDemandResource.ItemSize): ShippingDemand.ItemSize | undefined {
    if (request) {
      return {
        width: Services.toDecimal(request.width_in_meter)!,
        height: Services.toDecimal(request.height_in_meter)!,
        depth: Services.toDecimal(request.depth_in_meter)!
      };
    }
    return undefined;
  }

}

export namespace ShippingItem {

  import ItemSize = ShippingDemand.ItemSize;

  export interface Service {

    // <editor-fold desc="CRUD">
    query(request: QueryRequest): Observable<QueryResult<ShippingItem>>;

    // </editor-fold>

  }

  export interface QueryRequest {
    demandId?: number;
  }


  export interface ShippingItem {
    id: number;
    externalId: string;
    demandId: number;
    shipmentId?: number;
    name: string;
    category?: string;
    productCode?: string;
    amount: Amount;
    box?: Label;
    label?: Label;
    weightInGram?: number;
    price?: Price;
    outtaken: boolean;
    comment?: string;
    itemSize?: ItemSize;
  }

  export interface Label {
    value: string;
    mode: string;
  }

  export interface Amount {
    value: number;
    unit: string;
  }

  export interface Price {
    amount: string;
    currencyCode: string;
  }

}
