import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AndroidAppVersion, AndroidAppVersionService } from '../../../lib/andorid-app-version/android-app-version.service';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { FieldValidationError, ForwardingNgFormRef, LocalFormGroupValidationErrors } from '../../../lib/util/services';
import { SelectUtils, UiConstants } from '../../../util/core-utils';
import { InputMask } from '../../../util/input-masks';
import { AndroidAppVersionBaseModel } from './android-app-version-base.model';
import { ToasterService } from '../../../fork/angular2-toaster/src/toaster.service';
import { StringKey } from '../../../app.string-keys';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-android-app-version-base',
  templateUrl: './android-app-version-base.component.html',
  styleUrls: ['./android-app-version-base.component.scss']
})
export class AndroidAppVersionBaseComponent implements OnInit {

  // If you need to access certain classes from HTML, declare them here
  SelectUtils = SelectUtils;
  InputMask = InputMask;
  UiConstants = UiConstants;
  AndroidAppVersion = AndroidAppVersion;

  @ViewChild('versionForm', { static: true })
  form: NgForm;
  formGroup: FormGroup;
  private formGroupValidationErrors: LocalFormGroupValidationErrors;
  private fieldErrors: FieldValidationError<AndroidAppVersion.ValidatedField> =
    FieldValidationError.empty<AndroidAppVersion.ValidatedField>();

  model: AndroidAppVersionBaseModel = new AndroidAppVersionBaseModel();

  // Configuration for the HTML Editor
  HTMLEditorModules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{'header': [1, 2, 3, 4, 5, 6, false]}],
      ['link', 'image']
    ]
  };
  htmlEditorEmpty = false;

  @Input()
  set appVersion(v: AndroidAppVersion.AndroidAppVersion) {
    this.model.version = v;
  }

  @Input()
  set readonly(v: boolean) {
    this._readonly = v;
  }

  get readonly(): boolean {
    return this._readonly;
  }

  @Input()
  innerCard: boolean = false;

  @Input()
  headerKey: string = 'ANDROID_VERSION_CURRENT_VERSION';

  @Output()
  versionChanged: EventEmitter<{id: number, versionName: string}> = new EventEmitter<{id: number, versionName: string}>();

  @Output()
  versionDeleted: EventEmitter<number> = new EventEmitter<number>();

  _readonly: boolean = false;

  constructor(private androidAppVersionService: AndroidAppVersionService,
              private toasterService: ToasterService,
              private translateService: TranslateService,
              private fb: FormBuilder) {
    this.formGroup = this.createFormGroup(this.fb);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(
      this.createForwardingHtmlForm(),
      this.formGroup
    );
  }

  ngOnInit() {
  }

  submit() {
    if (this.hasLocalFieldError() || this.fieldErrors.hasError()) {
      return;
    }
    if (!this.validateChangeLogNonEmpty()) {
      return;
    }
    if (this.isUpdate) {
      this.androidAppVersionService.update(this.model.toUpdateRequest()).subscribe(result => {
          this.toasterService.pop({
            timeout: UiConstants.ToastTimeoutShort,
            type: UiConstants.toastTypeSuccess,
            title: this.translateService.instant(StringKey.COMMON_SUCCESS),
            body: this.translateService.instant(StringKey.ANDROID_VERSION_SUCCESS_BODY)
          });
          this.readonly = true;
          this.versionChanged.emit({versionName: this.model.versionName, id: this.model.id!});
        },
        (error: any) => {
          if (error instanceof FieldValidationError) {
            this.fieldErrors = error.withForm(this.form);
          }
        });
    }
    else {
      this.androidAppVersionService.create(this.model.toCreateRequest()).subscribe(result => {
          this.toasterService.pop({
            timeout: UiConstants.ToastTimeoutShort,
            type: UiConstants.toastTypeSuccess,
            title: this.translateService.instant(StringKey.COMMON_SUCCESS),
            body: this.translateService.instant(StringKey.ANDROID_VERSION_SUCCESS_BODY)
          });
          this.model.id = result.id;
          this.versionChanged.emit({versionName: this.model.versionName, id: result.id});
        },
        (error: any) => {
          if (error instanceof FieldValidationError) {
            this.fieldErrors = error.withForm(this.form);
          }
        });
    }
  }

  delete() {
    if (!this.isUpdate) {
      return;
    }
    this.androidAppVersionService.delete({id: this.model.id!}).subscribe(result => {
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutShort,
        type: UiConstants.toastTypeSuccess,
        title: this.translateService.instant(StringKey.COMMON_SUCCESS),
        body: this.translateService.instant(StringKey.ANDROID_VERSION_DELETE_SUCCESS_BODY)
      });
      this.versionDeleted.emit(this.model.id);
    });
  }

  private createForwardingHtmlForm() {
    return new ForwardingNgFormRef({
      formFn: () => {
        return this.form;
      }
    });
  }

  private createFormGroup(fb: FormBuilder): FormGroup {
    return fb.group(
      {
        versionName: fb.control(
          {value: this.model.versionName},
          [Validators.required]
        ),
        versionCode: fb.control(
          {value: this.model.versionCode},
          [Validators.required]
        ),
        buildSlug: fb.control(
          {value: this.model.buildSlug},
          [Validators.required]
        ),
        changeLog: fb.control(
          {value: this.model.changeLog},
          [Validators.required]
        ),
      }
    );
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  hasFieldError(field: AndroidAppVersion.ValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: AndroidAppVersion.ValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: AndroidAppVersion.ValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  getTextMaximumLength(): number {
    return UiConstants.maximumVarcharLength;
  }

  getNumberMaximumLength(): string {
    return UiConstants.maxInputNumberLength;
  }

  get isUpdate() {
    return this.model.id !== undefined;
  }

  private validateChangeLogNonEmpty(): boolean {
    if (!this.model.changeLog || this.model.changeLog.length < 1) {
      this.htmlEditorEmpty = true;
      return false;
    }
    this.htmlEditorEmpty = false;
    return true;
  }

}
