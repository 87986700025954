<div class="modal-content">
  <div class="modal-header">
    <p class="modal-title"><strong>{{getDialogTitle() | translate}}</strong></p>
    <button type="button" class="close" (click)="closeDialog(false)" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <mat-dialog-content style="overflow:visible">
      <form (ngSubmit)="save()" #f="ngForm" id="form"
            [formGroup]="formGroup" novalidate>

        <div class="row form-group"
             [ngClass]="{ 'has-danger': hasLocalFieldError('title') || hasFieldError(ValidatedField.TITLE)}">
          <label
            class="col-form-label form-control-label col-md-4 detail-title required-field-label"
            [class.required-field-label]="true">{{'COMMON_NAME' | translate}}</label>
          <div class="col-md-8">
            <div class="mw-100">
              <input type="text"
                     [(ngModel)]="model.title"
                     [ngClass]="{ 'form-control-danger': hasLocalFieldError('title') || hasFieldError(ValidatedField.TITLE) }"
                     class="form-control"
                     name="title"
                     id="title"
                     formControlName="title"
                     (ngModelChange)="removeFieldError(ValidatedField.TITLE)"
                     placeholder="{{'COMMON_NAME'|translate}}">
              <div class="form-control-feedback" *ngIf="hasLocalFieldError('title')">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
              <div class="form-control-feedback" *ngIf="hasFieldError(ValidatedField.TITLE)">
                {{getFieldErrorText(ValidatedField.TITLE)}}
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-form-label form-control-label col-md-4 detail-title required-field-label">
            {{'USER_LABEL_COLOR'|translate}}
          </label>
          <div class="col-md-8">
            <div class="mw-100">
              <palette-color-picker
                [(color)]="model.color">
              </palette-color-picker>
            </div>
          </div>
        </div>
        <div class="row form-group"
             [ngClass]="{ 'has-danger': hasLocalFieldError('startDate') || hasFieldError(ValidatedField.START_TIME) || hasLocalFormError('validateOffsetDateTimeFromTo')}">
          <label
            class="col-form-label form-control-label col-md-4 detail-title required-field-label"
            [class.required-field-label]="true">{{'CALENDAR_EVENT_START_DATE' | translate}}</label>
          <div class="col-md-8">
            <div class="row">
              <div class="input-group col-md-6 mb-1 mb-md-0">
                <input type="text"
                       placeholder="{{'DATE_TIME_PICKER_PLACEHOLDER_DATE' | translate}}"
                       [(ngModel)]="model.startDate"
                       [ngClass]="{ 'form-control-danger': hasLocalFieldError('startDate') || hasFieldError(ValidatedField.START_TIME) || hasLocalFormError('validateOffsetDateTimeFromTo') }"
                       class="form-control"
                       name="startDate"
                       id="startDate"
                       formControlName="startDate"
                       (ngModelChange)="removeFieldError(ValidatedField.START_TIME)"
                       ngbDatepicker #start_date_picker="ngbDatepicker">
                <span *ngIf="!readonly"
                      class="add-on input-group-addon-gray" (click)="start_date_picker.toggle()">
                          <i class="icomoon icomoon-calendar"></i>
                       </span>
              </div>
              <div class="input-group col-md-6">
                <input placeholder="{{'DATE_TIME_PICKER_PLACEHOLDER_TIME' | translate}}" type="text"
                       class="form-control hidden-value-when-disabled"
                       [(ngModel)]="model.startTime"
                       name="startTime"
                       id="startTime"
                       formControlName="startTime"
                       [readonly]="readonly || (model.startDate === null || model.startDate === undefined)"
                       [ngClass]="{ 'form-control-danger': hasLocalFieldError('startDate')  || hasFieldError(ValidatedField.START_TIME) || hasLocalFormError('validateOffsetDateTimeFromTo') }"
                       [seconds]="false"
                       [allowEmptyValue]="false"
                       ngbTimepicker #time_picker="ngbTimepicker" [toggleButtonId]="'start_toggle_button'"
                       [spinners]="true" [hourStep]="1" [minuteStep]="10">
                <span *ngIf="!readonly && !(model.startDate === null || model.startDate === undefined)"
                      id="start_toggle_button"
                      class="add-on input-group-addon-gray"
                      (click)="time_picker.toggle()">
                          <i class="icomoon icomoon-time"></i>
                        </span>
              </div>
            </div>
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('startDate', 'required')">
              {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
            </div>
            <div class="form-control-feedback" *ngIf="hasLocalFormError('validateOffsetDateTimeFromTo')">
              {{'COMMON_VALIDATION_MESSAGE_INVALID_LOCAL_DATE_FROM_TO' | translate}}
            </div>
            <div class="form-control-feedback" *ngIf="hasFieldError(ValidatedField.START_TIME)">
              {{getFieldErrorText(ValidatedField.START_TIME)}}
            </div>
          </div>
        </div>
        <div class="row form-group"
             [ngClass]="{ 'has-danger': hasLocalFieldError('endDate') || hasFieldError(ValidatedField.END_TIME) || hasLocalFormError('validateOffsetDateTimeFromTo')}">
          <label
            class="col-form-label form-control-label col-md-4 detail-title required-field-label"
            [class.required-field-label]="true">{{'CALENDAR_EVENT_END_DATE' | translate}}</label>
          <div class="col-md-8">
            <div class="row">
              <div class="input-group col-md-6 mb-1 mb-md-0">
                <input type="text"
                       placeholder="{{'DATE_TIME_PICKER_PLACEHOLDER_DATE' | translate}}"
                       [(ngModel)]="model.endDate"
                       [ngClass]="{ 'form-control-danger': hasLocalFieldError('endDate') || hasFieldError(ValidatedField.END_TIME) || hasLocalFormError('validateOffsetDateTimeFromTo') }"
                       class="form-control"
                       name="endDate"
                       id="endDate"
                       formControlName="endDate"
                       (ngModelChange)="removeFieldError(ValidatedField.END_TIME)"
                       ngbDatepicker #end_date_picker="ngbDatepicker">
                <span *ngIf="!readonly"
                      class="add-on input-group-addon-gray" (click)="end_date_picker.toggle()">
                          <i class="icomoon icomoon-calendar"></i>
                       </span>
              </div>
              <div class="input-group col-md-6">
                <input placeholder="{{'DATE_TIME_PICKER_PLACEHOLDER_TIME' | translate}}" type="text"
                       class="form-control hidden-value-when-disabled"
                       [(ngModel)]="model.endTime"
                       name="endTime"
                       id="endTime"
                       formControlName="endTime"
                       [readonly]="readonly || (model.endDate === null || model.endDate === undefined)"
                       [ngClass]="{ 'form-control-danger': hasLocalFieldError('endDate')  || hasFieldError(ValidatedField.END_TIME) || hasLocalFormError('validateOffsetDateTimeFromTo') }"
                       [seconds]="false"
                       [allowEmptyValue]="false"
                       ngbTimepicker #time_picker="ngbTimepicker" [toggleButtonId]="'end_toggle_button'"
                       [spinners]="true" [hourStep]="1" [minuteStep]="10">
                <span *ngIf="!readonly && !(model.endDate === null || model.endDate === undefined)"
                      id="end_toggle_button"
                      class="add-on input-group-addon-gray"
                      (click)="time_picker.toggle()">
                          <i class="icomoon icomoon-time"></i>
                        </span>
              </div>
            </div>
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('endDate', 'required')">
              {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
            </div>
            <div class="form-control-feedback" *ngIf="hasLocalFormError('validateOffsetDateTimeFromTo')">
              {{'COMMON_VALIDATION_MESSAGE_INVALID_LOCAL_DATE_FROM_TO' | translate}}
            </div>
            <div class="form-control-feedback" *ngIf="hasFieldError(ValidatedField.END_TIME)">
              {{getFieldErrorText(ValidatedField.END_TIME)}}
            </div>
          </div>
        </div>
        <div class="row form-group"
             [ngClass]="{ 'has-danger': hasFieldError(ValidatedField.COMMENT)}">
          <label
            class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_COMMENT' | translate}}</label>
          <div class="col-md-8">
            <div class="mw-100">
              <input type="text"
                     [(ngModel)]="model.comment"
                     [ngClass]="{ 'form-control-danger': hasFieldError(ValidatedField.COMMENT) }"
                     class="form-control"
                     name="comment"
                     id="comment"
                     formControlName="comment"
                     (ngModelChange)="removeFieldError(ValidatedField.COMMENT)"
                     placeholder="{{'COMMON_COMMENT'|translate}}">
              <div class="form-control-feedback" *ngIf="hasFieldError(ValidatedField.COMMENT)">
                {{getFieldErrorText(ValidatedField.COMMENT)}}
              </div>
            </div>
          </div>
        </div>
      </form>

      <div *ngIf="globalCalendarEvent">
        <div class="row form-group">
          <label
            class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_TABLE_HEADER_CREATION_TIME' | translate}}</label>
          <div class="col-md-8">
            <div class="mw-100">
              <label
                class="form-control detail-input">{{globalCalendarEvent.creationTime.toUtcIsoString() | date:'short'}}</label>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <label
            class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_CREATOR_USER' | translate}}</label>
          <div class="col-md-8">
            <div class="mw-100">
              <label class="form-control detail-input">{{globalCalendarEvent.creatorUser.personName}}
                ({{globalCalendarEvent.creatorUser.userName}})</label>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <label
            class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_TABLE_HEADER_UPDATE_TIME' | translate}}</label>
          <div class="col-md-8">
            <div class="mw-100">
              <label
                class="form-control detail-input">{{globalCalendarEvent.updateTime.toUtcIsoString() | date:'short'}}</label>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>
  </div>

  <div class="modal-footer" [ngClass]="{'justify-content-between': !readonly && globalCalendarEvent && data.allowDelete}">
    <button type="button" class="btn btn btn-secondary-green-gray" (click)="deleteEvent()"
            *ngIf="!readonly && globalCalendarEvent && data.allowDelete">
      {{'COMMON_BUTTON_DELETE' | translate}}
    </button>
    <span>
      <button type="button" class="btn btn-outline-primary mr-05" (click)="closeDialog(false)">
        {{'COMMON_BUTTON_CANCEL' | translate}}
      </button>
      <button type="submit" class="btn btn-primary" form="form">
        {{'COMMON_BUTTON_SAVE' | translate}}
      </button>
    </span>
  </div>
</div>
