/* eslint-disable */
import * as deepEqual from 'deep-equal';
/* eslint-enable */

export class Equals {

  public static deepEqual<T>(a: T, b: T): boolean {
    return deepEqual(a, b);
  }

  private constructor() {
  }

}
