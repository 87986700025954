<div class="container-custom-padding-outer">
  <div class="card-custom-padding-complete row">
    <h3 class="mb-0 col-lg-9 col-md-6">{{'HELPDESK_DASHBOARD_OVERVIEW' | translate}}</h3>
    <div class="col-lg-3 col-md-6">
      <input type="text" class="form-control w-100"
             maxlength="{{UiConstants.maxInputLongLength}}"
             (keyup.enter)="onBugReportSearch()"
             placeholder="{{'HELPDESK_BUG_REPOST_LIST_SEARCH_QUERY' | translate}}"
             [(ngModel)]="bugReportQuery">
    </div>
  </div>
  <div class="row m-0">
    <app-helpdesk-dashboard-stats class="col-12 p-0">
    </app-helpdesk-dashboard-stats>
    <div class="card-custom-padding-complete col-md-6" *ngIf="rightModel.taskRecordChatRead.hasRight()">
      <app-helpdesk-messages-card>
      </app-helpdesk-messages-card>
    </div>
    <div class="card-custom-padding-complete col-md-6" *ngIf="rightModel.taskRecordLogRead.hasRight()">
      <app-helpdesk-notifications-card>
      </app-helpdesk-notifications-card>
    </div>
    <app-helpdesk-bug-report-list
      class="w-100"
      [dashboard]="true">
    </app-helpdesk-bug-report-list>
  </div>
</div>
