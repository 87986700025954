import { TaskRecordStateMachine } from '../../../../../lib/task/task-record-statemachine';
import { TaskRecord } from '../../../../../lib/task/task-record.service';
import { OffsetDateTime } from '../../../../../lib/util/dates';
import { ConfigModel } from '../../../../../util/task-record-utils';
import { Address } from '../../../../../lib/address';

export class TaskRecordBoardModel {
  taskRecordId: number;
  task: TaskRecord.TaskData;
  name: string = '';
  assigneeName?: string;
  userGroupName?: string;
  assigneeUserName?: string;
  state: TaskRecordStateMachine.StateObject = TaskRecordStateMachine.taskRecordStates.get('NEW');
  relevantDate: OffsetDateTime;
  customerRecordName?: string;
  poc?: string;

  public static fromTaskRecord(tr: TaskRecord.TaskRecord, configModel: ConfigModel): TaskRecordBoardModel {
    const model = new TaskRecordBoardModel();
    model.taskRecordId = tr.taskRecordId;
    model.task = tr.task!;
    model.name = tr.name;
    model.assigneeName = tr.assignee?.userData?.personName;
    model.assigneeUserName = tr.assignee?.userData?.userName;
    model.userGroupName = tr.userGroupName;
    model.state = TaskRecordStateMachine.taskRecordStates.get(tr.state);
    model.relevantDate = tr.updateTime;
    model.customerRecordName = tr.customerRecord?.name;
    model.poc = tr.placeOfConsumption && tr.placeOfConsumption.address ? Address.PostalAddressMapper.toString(tr.placeOfConsumption.address, configModel.postalAddressFormat) : undefined;
    return model;
  }

}
