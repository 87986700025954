/* eslint-disable */
import { Injectable } from '@angular/core';
import { OrderField } from '../query/orderfields';
import { MultiselectOptionItem, OrderFieldModel } from '../../util/core-utils';
import { Worklog } from './worklog.service';
import { NewBaseSearch, NewBaseSearchService } from '../new-base.search-service';
import { OrderType, Services } from '../util/services';
import { SearchUtils } from '../../util/search-utils';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { BaseSearch } from '../base.search-service';
import { Models } from '../../util/model-utils';
import { LoggedInUserStorage } from '../util/storages';
import { TaskRecordStateMachine } from '../task/task-record-statemachine';
import { List, Set } from 'immutable';

/* eslint-enable */

@Injectable()
export class WorklogSearchService extends NewBaseSearchService<OrderField.Worklog> {

  protected createStorage(): WorklogSearch.Storage {
    return new WorklogSearch.Storage();
  }

}

export namespace WorklogSearch {



  export class Model extends SearchUtils.SearchModel {

    showSearch: boolean = false;

    taskRecordState: MultiselectOptionItem<TaskRecordStateMachine.State>[];
    taskRecord: MultiselectOptionItem<number>[];
    task: MultiselectOptionItem<number>[];
    loggerUser: MultiselectOptionItem<number>[];
    workerUser: MultiselectOptionItem<number>[];
    loggedTimeInMinutes: string;
    estimatedTimeInMinutes: string;
    worklogUserType: Worklog.WorklogUserType | null;
    startTimeFrom: NgbDateStruct | null;
    startTimeTo: NgbDateStruct | null;
    endTimeFrom: NgbDateStruct | null;
    endTimeTo: NgbDateStruct | null;

    get taskRecordId(): number | undefined {
      if (this.taskRecord.length > 0 && this.taskRecord[0].id !== null) {
        return this.taskRecord[0].id!;
      }
      return undefined;
    }

    get taskIds(): List<number> | undefined {
      if (this.task.length > 0) {
        return List.of(...this.task.map(t => t.id));
      }
      return undefined;
    }

    get taskRecordStates(): List<TaskRecordStateMachine.State> | null {
      if (this.taskRecordState.length > 0) {
        return List.of(...this.taskRecordState.map(s => s.id));
      }
      return null;
    }

    get loggerUserId(): number | undefined {
      if (this.loggerUser.length > 0 && this.loggerUser[0].id !== null) {
        return this.loggerUser[0].id!;
      }
      return undefined;
    }

    get workerUserId(): number | undefined {
      if (this.workerUser.length > 0 && this.workerUser[0].id !== null) {
        return this.workerUser[0].id!;
      }
      return undefined;
    }

    constructor() {
      super();
      this.reset();
    }

    reset() {
      this.taskRecordState = [];
      this.taskRecord = [];
      this.task = [];
      this.loggerUser = [];
      this.workerUser = [];
      this.loggedTimeInMinutes = '';
      this.estimatedTimeInMinutes = '';
      this.worklogUserType = null;
      this.startTimeFrom = null;
      this.startTimeTo = null;
      this.endTimeFrom = null;
      this.endTimeTo = null;
    }

    isEmpty(): boolean {
      return this.taskRecordState.length === 0 &&
      this.taskRecord.length === 0 &&
          this.task.length === 0 &&
        this.loggerUser.length === 0 &&
        this.workerUser.length === 0 &&
        this.loggedTimeInMinutes === '' &&
        this.estimatedTimeInMinutes === '' &&
        this.worklogUserType === null &&
        this.startTimeFrom === null &&
        this.startTimeTo === null &&
        this.endTimeFrom === null &&
        this.endTimeTo === null;
    }

  }

  export interface SearchDataGetRequest extends BaseSearch.SearchDataGetRequest {
    scope?: Worklog.WorklogScope;
    resourceId?: number;
  }

  export interface SearchDataResult {
    searchData: SearchData;
  }

  export interface SearchDataSetRequest extends BaseSearch.SearchDataSetRequest {
    scope?: Worklog.WorklogScope;
    resourceId?: number;
  }

  export interface SearchData extends NewBaseSearch.SearchData<OrderField.Worklog> {
    searchModel: Model;
  }

  interface StoredSearchRawData extends NewBaseSearch.StoredSearchRawData<OrderField.Worklog> {
    taskRecordState: MultiselectOptionItem<TaskRecordStateMachine.State>[];
    taskRecord: MultiselectOptionItem<number>[];
    task: MultiselectOptionItem<number>[];
    loggerUser: MultiselectOptionItem<number>[];
    workerUser: MultiselectOptionItem<number>[];
    loggedTimeInMinutes: string;
    estimatedTimeInMinutes: string;
    worklogUserType: Worklog.WorklogUserType | null;
    startTimeFrom: string;
    startTimeTo: string;
    endTimeFrom: string;
    endTimeTo: string;
  }

  export class Storage extends NewBaseSearch.Storage<OrderField.Worklog> {

    constructor() {
      const defaultSearchData: SearchData = {
        queryModel: new OrderFieldModel(Worklog.OrderFunctions.ID, OrderType.DESC),
        searchModel: new Model(),
      };
      /**
       * Increment this version if you change the SearchData interface.
       */
      const versionNumber: number = 4;
      super(defaultSearchData,
        versionNumber);
    }

    protected generateKey(request: SearchDataGetRequest | SearchDataSetRequest): string {
      return request.scope && request.resourceId
        ? 'UserId(' + LoggedInUserStorage.getInstance().getUserId()
        + ')-Search-Worklog-Scope(' + request.scope.toString() + ')-ResourceId(' + request.resourceId + ')'
        : 'UserId(' + LoggedInUserStorage.getInstance().getUserId() + ')-Search-Worklog';
    }

    protected fromRaw(data: StoredSearchRawData): NewBaseSearch.SearchDataResult<OrderField.Worklog> {
      const searchModel = new Model();
      searchModel.taskRecordState = data.taskRecordState;
      searchModel.taskRecord = data.taskRecord;
      searchModel.task = data.task;
      searchModel.loggerUser = data.loggerUser;
      searchModel.workerUser = data.workerUser;
      searchModel.loggedTimeInMinutes = data.loggedTimeInMinutes;
      searchModel.estimatedTimeInMinutes = data.estimatedTimeInMinutes;
      searchModel.worklogUserType = data.worklogUserType;
      searchModel.startTimeFrom = Models.localDateToNgbDate(Services.toLocalDate(data.startTimeFrom));
      searchModel.startTimeTo = Models.localDateToNgbDate(Services.toLocalDate(data.startTimeTo));
      searchModel.endTimeFrom = Models.localDateToNgbDate(Services.toLocalDate(data.endTimeFrom));
      searchModel.endTimeTo = Models.localDateToNgbDate(Services.toLocalDate(data.endTimeTo));
      const searchData: SearchData = {
        queryModel: OrderFieldModel.create<OrderField.Worklog>(Worklog.OrderFunctions.VALUES, data.order, data.queryModel),
        searchModel: searchModel,
      };
      return {
        searchData: searchData
      };
    }

    protected toRaw(data: SearchData): StoredSearchRawData {
      return {
        version: this.VERSION,
        queryModel: data.queryModel,
        order: data.queryModel.createOrder(Worklog.OrderFunctions.VALUES),
        taskRecordState: data.searchModel.taskRecordState,
        taskRecord: data.searchModel.taskRecord,
        task: data.searchModel.task,
        loggerUser: data.searchModel.loggerUser,
        workerUser: data.searchModel.workerUser,
        loggedTimeInMinutes: data.searchModel.loggedTimeInMinutes,
        estimatedTimeInMinutes: data.searchModel.estimatedTimeInMinutes,
        worklogUserType: data.searchModel.worklogUserType,
        startTimeFrom: Services.localDateToString(Models.ngbDateToLocalDate(data.searchModel.startTimeFrom))!,
        startTimeTo: Services.localDateToString(Models.ngbDateToLocalDate(data.searchModel.startTimeTo))!,
        endTimeFrom: Services.localDateToString(Models.ngbDateToLocalDate(data.searchModel.endTimeFrom))!,
        endTimeTo: Services.localDateToString(Models.ngbDateToLocalDate(data.searchModel.endTimeTo))!,
      };
    }

  }

}
