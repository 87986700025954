import { DqlQuery, Query } from '../field';
import { LazyReference } from '../../../util/lazy';

// Each class should be an interface, but it is easier to define the constant fields in the constructors.
// For now we have only one query language so it is not a problem.
// Always use the public Query namespace for variable definitions so we will be able to introduce other language easily.

export class UserGroups {

  private readonly userGroupReference: LazyReference<UserGroup>; // Lazy to support circular references.

  get any(): UserGroup {
    return this.userGroupReference.get();
  }

  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this.userGroupReference = LazyReference.of(
      () => new UserGroup(prefix + 'any.')
    );
  }

}

export class UserGroup {

  readonly id: Query.NumberField;

  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this.id = new DqlQuery.NumberField(prefix + 'id');
  }

}
