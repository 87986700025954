<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-vertical-padding container-horizontal-padding animated fadeIn col-md-8 col-sm-12 col-xs-12">
  <div class="card">
    <div class="card-header">
      {{'INVOICE_BOOK_CREATE_HEADING' | translate}}
    </div>
    <div class="card-body">
      <form class="form-horizontal bordered-row" (ngSubmit)="f.form.valid && create()" #f="ngForm" novalidate>

        <div class="form-group row" [ngClass]="{ 'has-danger': hasFieldError(InvoiceBook.ValidatedField.EXTERNAL_ID) || (f.submitted && !externalId.valid) }">
          <label class="col-4 col-form-label">
            {{'COMMON_EXTERNAL_ID' | translate}}
          </label>
          <div class="col-8">
            <input class="form-control" type="text" placeholder="{{'COMMON_EXTERNAL_ID' | translate}}"
                   maxlength="{{UiConstants.maximumVarcharLength}}"
                   [ngClass]="{ 'form-control-danger': hasFieldError(InvoiceBook.ValidatedField.EXTERNAL_ID) || (f.submitted && !externalId.valid) }"
                   [(ngModel)]="model.externalId" (ngModelChange)="removeFieldError(InvoiceBook.ValidatedField.EXTERNAL_ID)" name="externalId" id="externalId" #externalId="ngModel">
            <div class="form-control-feedback" *ngIf="hasFieldError(InvoiceBook.ValidatedField.EXTERNAL_ID)">{{getFieldErrorText(InvoiceBook.ValidatedField.EXTERNAL_ID)}}</div>
          </div>
        </div>

        <div class="form-group row" [ngClass]="{ 'has-danger': hasFieldError(InvoiceBook.ValidatedField.PREFIX) || (f.submitted && !prefix.valid) }">
          <label class="col-4 col-form-label">
            {{'INVOICE_CREATE_NUMBERING_TEMPLATE' | translate}}
            <i class="icomoon icomoon-info info-icon" popover="{{'INVOICE_CREATE_NUMBERING_TEMPLATE_INFO' | translate}}"
               triggers="mouseenter:mouseleave" container="body" placement="right"></i>
          </label>
          <div class="col-8">
            <input class="form-control" type="text" placeholder="{{'INVOICE_CREATE_NUMBERING_TEMPLATE' | translate}}"
                   maxlength="{{UiConstants.maximumVarcharLength}}"
                   [ngClass]="{ 'form-control-danger': hasFieldError(InvoiceBook.ValidatedField.PREFIX) || (f.submitted && !prefix.valid) }"
                   [(ngModel)]="model.prefix" (ngModelChange)="removeFieldError(InvoiceBook.ValidatedField.PREFIX)" name="prefix" id="prefix" #prefix="ngModel" required>
            <div class="form-control-feedback" *ngIf="hasFieldError(InvoiceBook.ValidatedField.PREFIX)">{{getFieldErrorText(InvoiceBook.ValidatedField.PREFIX)}}</div>
            <div class="form-control-feedback" *ngIf="f.submitted && !prefix.valid">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
          </div>
        </div>
        <div class="form-group row" [ngClass]="{ 'has-danger': hasFieldError(InvoiceBook.ValidatedField.START_NUMBER) || (f.submitted && !startNumber.valid) }">
          <label class="col-4 col-form-label">{{'INVOICE_CREATE_NUMBERING_START' | translate}}</label>
          <div class="col-8">
            <input class="form-control" type="text" placeholder="{{'INVOICE_CREATE_NUMBERING_START' | translate}}"
                   maxlength="{{UiConstants.maxInputLongLength}}"
                   [ngClass]="{ 'form-control-danger': hasFieldError(InvoiceBook.ValidatedField.START_NUMBER) || (f.submitted && !startNumber.valid) }"
                   [(ngModel)]="model.startNumber" (ngModelChange)="removeFieldError(InvoiceBook.ValidatedField.START_NUMBER)" name="startNumber" id="startNumber" #startNumber="ngModel" required
                   [textMask]="{mask: InputMask.NATURAL_FLOAT, guide: false}">
            <div class="form-control-feedback" *ngIf="hasFieldError(InvoiceBook.ValidatedField.START_NUMBER)">{{getFieldErrorText(InvoiceBook.ValidatedField.START_NUMBER)}}</div>
            <div class="form-control-feedback" *ngIf="f.submitted && !startNumber.valid">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label">{{'COMMON_NOTE' | translate}}</label>
          <div class="col-8">
            <textarea class="form-control textarea-noresize" rows="3"
                      placeholder="{{'COMMON_NOTE' | translate}}"
                      [(ngModel)]="model.comment" name="comment" id="comment" #comment="ngModel">
            </textarea>
          </div>
        </div>
        <div class="form-group row" [ngClass]="{ 'has-danger': f.submitted && !invoiceSettingsInput.valid }">
          <label class="col-4 col-form-label">{{'INVOICE_CREATE_INVOICE_SETTINGS' | translate}}</label>
          <div class="col-8">
            <angular2-multiselect [data]="invoiceSettings" [settings]="dropdownSettings"
                                  class="form-control"
                                  [ngClass]="{ 'form-control-danger': f.submitted && !invoiceSettingsInput.valid }"
                                  [(ngModel)]="model.invoiceSettings"
                                  (onRemoteSearch)="loadInvoiceSettings($event.target.value)"
                                  id="invoiceSettingsInput"
                                  name="invoiceSettingsInput"
                                  #invoiceSettingsInput="ngModel"
                                  required>
            </angular2-multiselect>
            <div class="form-control-feedback" *ngIf="f.submitted && !invoiceSettingsInput.valid">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
          </div>
        </div>
        <div class="d-inline pull-right">
          <input type="button" class="btn btn-outline-primary" (click)="back()"
                 value="{{'COMMON_BUTTON_CANCEL'|translate}}"/>
          <input type="submit" class="btn btn-primary" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
        </div>
      </form>
    </div>
  </div>
</div>
