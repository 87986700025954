import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {
  ApplicationTypeItem,
  CompanyItem,
  OptionItem,
  RoleItem,
  UiConstants,
  UserGroupEditModel,
  UserItem
} from '../../../util/core-utils';
import {UserGroupFieldErrorMap, UserGroupService} from '../../../lib/user-group.service';
import {RightModel} from '../../../app.rights';
import {UserProfile} from '../../../lib/auth.service';
import {List, Set} from 'immutable';
import {User, UserService} from '../../../lib/user.service';
import {Angular2Multiselects} from '../../../util/multiselect';
import {TranslateService} from '@ngx-translate/core';
import {ApplicationType, RootCoreService} from '../../../lib/root-core.service';
import {Role, RoleService} from '../../../lib/role/role.service';
import {Company, CompanyService} from '../../../lib/company/company.service';
import {RightResolver, RightService} from '../../../lib/right.service';
import {UIRouter} from '@uirouter/angular';
import {
  LocalFieldValidationErrors,
  LocalFieldValidationErrorsFactory,
  OrderType,
  QueryResult,
  ResourceQueryResult,
  Services
} from '../../../lib/util/services';
import {Arrays} from '../../../lib/util/arrays';
import {EmptyMessage, IdentityMessage} from '../../../lib/util/messages';
import {FieldError, FieldErrors, ObservableErrorResourceParser} from '../../../lib/util/errors';
import {NgForm, NgModel} from '@angular/forms';

@Component({
  selector: 'app-user-group-create-modal',
  templateUrl: './user-group-create-modal.component.html',
  styleUrls: ['./user-group-create-modal.component.scss']
})
export class UserGroupCreateModalComponent implements OnInit, AfterViewInit {
  UiConstants = UiConstants;

  // Company selected in previous step
  @Input()
  companyId?: number;

  // Event emitter to notify parent component of successful user group creation
  @Output()
  private userGroupCreated = new EventEmitter<number>();

  @ViewChild('userGroupCreateDialog', {static: true}) userGroupCreateDialog: ModalDirective;
  userGroupCreateDialogVisible = true;

  // Form for validation
  @ViewChild('f')
  fForm: NgForm;

  // Validated inputs
  @ViewChild('name')
  name: NgModel;

  private validatedInputs: LocalFieldValidationErrors<NgModel> =
    LocalFieldValidationErrorsFactory.empty();

  model: UserGroupEditModel = new UserGroupEditModel();
  fieldErrors: UserGroupFieldErrorMap = {};
  roles: RoleItem[] = [];
  selectableRoles: RoleItem[] = [];
  companies: CompanyItem[] = [];
  applicationTypes: ApplicationTypeItem[] = [];
  userItems: UserItem[] = [];
  selectableUsers: UserItem[] = [];
  modelLoaded = false;

  rightModel: RightModel = RightModel.empty();
  userProfile: UserProfile;
  users: List<User> = List.of<User>();

  dropdownSettings?: Angular2Multiselects.Settings;
  dropdownSettingsForCompany?: Angular2Multiselects.Settings;
  dropdownSettingsForUser?: Angular2Multiselects.Settings;

  constructor(
    private translateService: TranslateService,
    private rootCoreService: RootCoreService,
    private userService: UserService,
    private userGroupService: UserGroupService,
    private uiRouter: UIRouter,
    private roleService: RoleService,
    private companyService: CompanyService,
    private rightService: RightService) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.loadRightModels();
  }

  private initDropdown(enableCheckAllUser: boolean) {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .labelKey(OptionItem.KEY_TEXT)
      .build();
    this.dropdownSettingsForCompany = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .labelKey(OptionItem.KEY_TEXT)
      .build();
    this.dropdownSettingsForUser = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(enableCheckAllUser)
      .labelKey(OptionItem.KEY_TEXT)
      .build();
  }

  private loadModel() {
    this.loadRoles();
    this.loadScopes();
    this.loadUsers();
    this.modelLoaded = true;
  }

  private loadLocalFieldValidationErrors() {
    const validatedInputs = List.of(this.name);
    this.validatedInputs = LocalFieldValidationErrorsFactory.ofFormFields(this.fForm, validatedInputs);
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        this.userProfile = resolver.userProfile!;
      }
    );
  }

  private loadRoles() {
    this.roleService.query({
      orders: Set.of({field: Role.OrderField.NAME, type: OrderType.ASC})
    }).subscribe(
      (result: QueryResult<Role.Role>) => {
        this.roles = [];
        this.model.roles = [];
        result.items.forEach((role: Role.Role) => {
          const item = new RoleItem();
          item.id = role.id;
          item.text = role.name;
          this.roles.push(item);
        });
        // Equal on load, filtered later in onCompanySelected()
        this.selectableRoles = this.roles;
      },
      (error: any) => {
        // Ignored. The interceptor handles the global errors.
      }
    );
  }

  private loadSelectedCompany() {
    if (this.companyId) {
      this.companyService.get({
        id: this.companyId
      }).subscribe((company: Company.Company) => {
        const item = new CompanyItem();
        item.id = company.id;
        item.text = company.name;
        item.allowedRoleIds = company.allowedRoleIds!;
        this.model.companies.push(item);
        this.filterRoles();
        this.filterUsers();
      });
    }
  }

  private loadScopes() {
    this.rootCoreService.getApplicationTypes({})
      .subscribe(
        (applicationTypes: ApplicationType[]) => {
          this.applicationTypes = [];
          this.model.application_types = [];
          Arrays.iterateByIndex(applicationTypes, (scope) => {
            const item = new ApplicationTypeItem();
            item.id = scope.key;
            item.text = scope.name;
            this.applicationTypes.push(item);
          });
        },
        (error: any) => {
          // Ignored. The interceptor handles the global errors.
        }
      );
  }

  private loadUsers() {
    this.userService.query({
      order: Services.createOrderFieldParameter(User.Keys.toOrderFieldKey,
        Set.of({field: User.OrderField.USER_NAME, type: OrderType.ASC})),
      disabled: false
    }).subscribe(
      (result: ResourceQueryResult<User>) => {
        this.userItems = [];
        this.model.users = [];
        this.selectableUsers = [];
        Arrays.iterateByIndex(result.items, (user) => {
          const item = this.toUserItem(user);
          if (!user.protected_user) {
            // Filter the protected user from the dropdown.
            this.userItems.push(item);
          }
        });
        this.users = List.of(...result.items);
        this.selectableUsers = this.userItems;
        this.initDropdown(true);
      }
    );
  }

  onUserSelect(event: UserItem) {
    const selectedUser = this.findUserById(event.id!);
    if (selectedUser !== null && selectedUser.protected_user) {
      // Protected user is added to the group, undo!
      const index = this.model.users.indexOf(event);
      this.model.users.splice(index, 1);
    }
  }

  onUserDeSelect(event: UserItem) {
    const selectedUser = this.findUserById(event.id!);
    if (selectedUser !== null && selectedUser.protected_user) {
      // Protected user is removed from the group, undo!
      this.model.users.push(event);
    }
  }

  onSelectAllUser(event: UserItem[]) {
    const ua: User[] = [];
    this.users.forEach((u) => {
      if (!u!.protected_user) {
        ua.push(u!);
      }
    });
    const items = ua.map((u) => {
      return this.toUserItem(u!);
    });
    this.model.users = items;
  }

  onDeSelectAllUser(event: UserItem[]) {
    // No filter needed.
  }

  filterRoles() {
    if (this.model.companies[0] && this.model.companies[0].id !== null) {
      this.selectableRoles = [];
      const filteredRoles: RoleItem[] = [];
      this.model.companies[0].allowedRoleIds.forEach((roleId: number) => {
        this.roles.forEach((role: RoleItem) => {
          if (role.id === roleId) {
            this.selectableRoles.push(role);
          }
        });
        this.model.roles.forEach((selectedRole: RoleItem) => {
          if (roleId === selectedRole.id) {
            filteredRoles.push(selectedRole);
          }
        });
      });
      this.model.roles = filteredRoles;
    } else {
      this.selectableRoles = this.roles;
    }
  }

  filterUsers() {
    this.selectableUsers = this.userItems;
  }

  create() {
    if (this.hasLocalFieldError()) {
      return;
    }
    this.userGroupService.create({
      name: this.model.name,
      external_id: this.model.externalId,
      roles: this.model.createRoleKeys(),
      application_types: this.model.createApplicationTypeKeys(),
      company_ids: this.companyId ? [this.companyId] : undefined
    }).subscribe(
      (createResult: IdentityMessage) => {
        this.userGroupService.updateUsers({
          id: createResult.id,
          user_ids: this.model.createUserIds()
        }).subscribe((response: EmptyMessage) => {
          this.userGroupCreateDialog.hide();
          this.userGroupCreated.emit(createResult.id);
          this.fForm.resetForm();
        });
      },
      (error: any) => {
        const res = ObservableErrorResourceParser.parseError(error);
        this.fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
      }
    );
  }

  hasLocalFieldError(field?: NgModel): boolean {
    return this.validatedInputs.hasLocalError(field);
  }

  removeFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.fieldErrors, fieldError);
  }

  getTextMaximumLength(): number {
    return UiConstants.maximumVarcharLength;
  }

  private findUserById(id: number): User | null {
    let user: User | null = null;
    this.users.forEach((u: User) => {
      if (u.id === id) {
        user = u;
        return false;
      }
    });
    return user;
  }

  private toUserItem(user: User) {
    const item = new UserItem();
    item.id = user.id;
    item.text = user.person_name + ' (' + user.user_name + ')';
    item.disabled = user.disabled;
    return item;
  }

  showUserGroupCreateDialog() {
    this.model = new UserGroupEditModel();
    if (!this.modelLoaded) {
      this.loadModel();
    }
    this.loadSelectedCompany();
    this.userGroupCreateDialogVisible = true;
    this.userGroupCreateDialog.show();
  }

  closeUserGroupCreateDialog() {
    this.userGroupCreateDialogVisible = false;
    this.userGroupCreateDialog.hide();
  }

  onModalShown() {
    this.loadLocalFieldValidationErrors();
  }

  getSelectedCompanyName(): string {
    if (this.model.companies.length > 0 && this.model.companies.length > 0) {
      return this.model.companies[0]!.text;
    }
    return '';
  }

}
