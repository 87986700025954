import { Observable, Observer } from 'rxjs';
import { FieldValidationError } from '../util/services';
import { EmptyMessage } from '../util/messages';
import { Map } from 'immutable';
import {
  PasswordResetResource,
  PasswordResetResourceService
} from './password-reset-resource.service';
import { Injectable } from '@angular/core';
import { ObservableErrorResourceParser } from '../util/errors';

export namespace PasswordReset {

  export interface Service {
    request(request: RequestRequest): Observable<EmptyMessage>;
    change(request: ChangeRequest): Observable<EmptyMessage>;
  }

  export interface RequestRequest {
    userName: string;
  }

  export interface ChangeRequest {
    token: string;
    password: string;
  }

  export enum ValidatedField {
    UNKNOWN,
    PASSWORD,
  }

}

class Keys {

  private static readonly PASSWORD = 'password';

  private static readonly keyValidatedFieldMap: Map<string, PasswordReset.ValidatedField> = Map.of(
    Keys.PASSWORD, PasswordReset.ValidatedField.PASSWORD,
  );

  public static toValidatedField(fieldKey: string): PasswordReset.ValidatedField {
    return Keys.keyValidatedFieldMap.get(fieldKey, PasswordReset.ValidatedField.UNKNOWN);
  }

}

@Injectable()
export class PasswordResetService implements PasswordReset.Service {

  constructor(private resourceService: PasswordResetResourceService) {
  }

  request(request: PasswordReset.RequestRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: PasswordResetResource.RequestRequest = {
        user_name: request.userName
      };
      return this.resourceService.request(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  change(request: PasswordReset.ChangeRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: PasswordResetResource.ChangeRequest = {
        token: request.token,
        password: request.password
      };
      return this.resourceService.change(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  private translateError(error: any): any {
    const res = ObservableErrorResourceParser.parseError(error);
    const fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
    const fieldErrorMap = ObservableErrorResourceParser.toFieldErrorMap(Keys.toValidatedField, fieldErrors);
    if (!fieldErrorMap.isEmpty()) {
      return FieldValidationError.of(fieldErrorMap);
    }
    return error;
  }

}
