/* eslint-disable */
import { Directive, ViewContainerRef } from '@angular/core';
/* eslint-enable */

/* eslint-disable */
@Directive({
  selector: '[dql-search-container-holder]',
})
/* eslint-enable */
export class DqlSearchContainerHolderDirective {

  constructor(public viewContainerRef: ViewContainerRef) {
  }
}
