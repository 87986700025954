/* eslint-disable */
import { defer as observableDefer, of as observableOf } from 'rxjs';
import { Decimal } from 'decimal.js';
import { List } from 'immutable';
import { PhoneNumber, PhoneNumbers, } from './util/phone-number';
import { EmailAddress, EmailAddresses, } from './util/email-address';
import { Models } from '../util/model-utils';
import { ImmutableOptionItem, } from '../util/core-utils';
import { Country } from './country.service';
import { Services } from './util/services';
import { RuntimeConfiguration } from './runtime-configuration';
/* eslint-enable */

// Layer 1
export namespace AddressResource {

  export type PostalAddressType = 'TEXT' | 'COMPLEX';

  export interface PostalAddressResource {
    id?: number;
    type: PostalAddressType;
    text_address?: string;
    country_code?: string;
    city?: string;
    zip_code?: string;
    address?: string;
    house_number?: string;
    address_type?: string;
    building?: string;
    stairway?: string;
    floor?: string;
    door?: string;
    name?: string;
  }

  export interface EmailAddressResource {
    type_id: number;
    type_names?: Map<string, string>;
    value?: string;
    comment?: string;
    notify?: boolean;
  }

  export type PhoneNumberType = 'OTHER' | 'PRIVATE_MOBILE' | 'WORK_MOBILE' | 'PRIVATE_WIRE' | 'WORK_WIRE';

  export interface PhoneNumberResource {
    type: PhoneNumberType;
    value?: string;
    comment?: string;
  }

  export interface CompanyContactPersonResource {
    name?: string;
    position?: string;
    comment?: string;
    phone_number?: PhoneNumberResource;
    email_address?: EmailAddressResource;
  }

  export interface ContactLocationResource {
    id?: number;
    name?: string;
    customer_record_name?: string;
    external_id?: string;
    opening_times?: string;
    comment?: string;
    postal_address?: PostalAddressResource;
    contact_persons?: number[];
    contract_number?: string;
  }

  export interface PlaceCreateResourceRequest {
    postal_address: AddressResource.PostalAddressResource;
    name: string;
    coordinate?: CoordinateResource;
  }

  export interface PlaceResource {
    id: number;
    postal_address?: AddressResource.PostalAddressResource;
    name: string;
    coordinate?: CoordinateResource;
    geocode_status?: string;
  }

  export interface CoordinateResource {
    latitude: string;
    longitude: string;
  }

  export type GeocodeStatus =
    'QUEUED' |
    'DONE' |
    'PARTIAL' |
    'FAILED' |
    'MANUAL';

  export class GeocodeStatusObject {
    status: GeocodeStatus;
    stringKey: string;
    iconClass: string;
  }

  export const geocodeStatuses: GeocodeStatusObject[] = [
    {status: 'QUEUED', stringKey: 'GEOCODE_STATUS_QUEUED', iconClass: 'icomoon-new-state-in-progress'},
    {status: 'DONE', stringKey: 'GEOCODE_STATUS_DONE', iconClass: 'icomoon-geocode-success'},
    {status: 'PARTIAL', stringKey: 'GEOCODE_STATUS_PARTIAL', iconClass: 'icomoon-geocode-partial-alt'},
    {status: 'FAILED', stringKey: 'GEOCODE_STATUS_FAILED', iconClass: 'icomoon-geocode-fail-alt'},
    {status: 'MANUAL', stringKey: 'GEOCODE_STATUS_MANUAL', iconClass: ''}
  ];

  export class Mapper {

    // region Postal address

    public static toPublicPostalAddressOpt(resource?: PostalAddressResource): Address.PostalAddressData | undefined {
      if (resource) {
        return Mapper.toPublicPostalAddress(resource);
      }
      return undefined;
    }

    public static toPublicPostalAddress(resource: PostalAddressResource): Address.PostalAddressData {
      const type = resource.type === 'TEXT'
        ? Address.PostalAddressDataType.TEXT
        : Address.PostalAddressDataType.COMPLEX;
      const textValue = resource.type !== 'TEXT' ? undefined : resource.text_address;
      const complexValue: Address.PostalAddressStruct | undefined = resource.type === 'TEXT' ? undefined : {
        countryCode: Services.optToString(resource.country_code),
        zipCode: Services.optToString(resource.zip_code),
        city: Services.optToString(resource.city),
        address: Services.optToString(resource.address),
        addressType: Services.optToString(resource.address_type),
        houseNumber: Services.optToString(resource.house_number),
        building: Services.optToString(resource.building),
        stairway: Services.optToString(resource.stairway),
        floor: Services.optToString(resource.floor),
        door: Services.optToString(resource.door),
        name: Services.optToString(resource.name),
      };
      return {
        id: resource.id,
        type: type,
        coordinate: undefined,
        complexValue: complexValue,
        textValue: textValue
      };
    }

    public static fromPublicPostalAddressOpt(data?: Address.PostalAddressData): PostalAddressResource | undefined {
      if (data) {
        return Mapper.fromPublicPostalAddress(data);
      }
      return undefined;
    }

    public static fromPublicPostalAddress(data: Address.PostalAddressData): PostalAddressResource {
      const type = data.type === Address.PostalAddressDataType.TEXT ? 'TEXT' : 'COMPLEX';
      return {
        id: data.id,
        type: type,
        text_address: type === 'TEXT' ? data.textValue : undefined,
        country_code: type !== 'TEXT' ? data.complexValue!.countryCode : undefined,
        city: type !== 'TEXT' ? data.complexValue!.city.trim() : undefined,
        zip_code: type !== 'TEXT' ? data.complexValue!.zipCode.trim() : undefined,
        address: type !== 'TEXT' ? data.complexValue!.address.trim() : undefined,
        house_number: type !== 'TEXT' ? data.complexValue!.houseNumber ? data.complexValue!.houseNumber!.trim() : undefined : undefined,
        address_type: type !== 'TEXT' ? data.complexValue!.addressType ? data.complexValue!.addressType!.trim() : undefined : undefined,
        building: type !== 'TEXT' ? data.complexValue!.building ? data.complexValue!.building!.trim() : undefined : undefined,
        stairway: type !== 'TEXT' ? data.complexValue!.stairway ? data.complexValue!.stairway!.trim() : undefined : undefined,
        floor: type !== 'TEXT' ? data.complexValue!.floor ? data.complexValue!.floor!.trim() : undefined : undefined,
        door: type !== 'TEXT' ? data.complexValue!.door ? data.complexValue!.door!.trim() : undefined : undefined,
        name: type !== 'TEXT' ? data.complexValue!.name ? data.complexValue!.name!.trim() : undefined : undefined,
      };
    }

    // endregion

    // region Phone number

    public static toPublicPhoneNumberList(resources?: PhoneNumberResource[]): List<Address.PhoneNumberData> {
      if (!resources) {
        return List.of<Address.PhoneNumberData>();
      }
      return List.of(...resources.map((resource) => {
        return Mapper.toPublicPhoneNumber(resource);
      }));
    }

    public static toPublicPhoneNumberOpt(resource?: PhoneNumberResource): Address.PhoneNumberData | undefined {
      if (resource) {
        return Mapper.toPublicPhoneNumber(resource);
      }
      return undefined;
    }

    public static toPublicPhoneNumber(resource: PhoneNumberResource): Address.PhoneNumberData {
      return {
        value: Services.toPhoneNumber(resource.value),
        description: Services.optToString(resource.comment),
        type: Mapper.toPublicPhoneNumberType(resource.type),
      };
    }

    public static fromPublicPhoneNumberList(dataList: List<Address.PhoneNumberData>): PhoneNumberResource[] {
      return dataList.map((data) => {
        return Mapper.fromPublicPhoneNumber(data!);
      }).toArray();
    }

    public static fromPublicPhoneNumberOpt(data?: Address.PhoneNumberData): PhoneNumberResource | undefined {
      if (data) {
        return Mapper.fromPublicPhoneNumber(data);
      }
      return undefined;
    }

    public static fromPublicPhoneNumber(data: Address.PhoneNumberData): PhoneNumberResource {
      return {
        value: Services.phoneNumberToString(data.value),
        comment: data.description,
        type: Mapper.fromPublicPhoneNumberType(data.type),
      };
    }

    private static toPublicPhoneNumberType(resourceType: PhoneNumberType): Address.PhoneNumberDataType {
      switch (resourceType) {
        case 'PRIVATE_MOBILE':
          return Address.PhoneNumberDataType.PERSONAL_MOBILE;
        case 'PRIVATE_WIRE':
          return Address.PhoneNumberDataType.PERSONAL_WIRE;
        case 'WORK_MOBILE':
          return Address.PhoneNumberDataType.WORK_MOBILE;
        case 'WORK_WIRE':
          return Address.PhoneNumberDataType.WORK_WIRE;
      }
      return Address.PhoneNumberDataType.OTHER;
    }

    private static fromPublicPhoneNumberType(type: Address.PhoneNumberDataType): PhoneNumberType {
      switch (type) {
        case Address.PhoneNumberDataType.PERSONAL_MOBILE:
          return 'PRIVATE_MOBILE';
        case Address.PhoneNumberDataType.PERSONAL_WIRE:
          return 'PRIVATE_WIRE';
        case Address.PhoneNumberDataType.WORK_MOBILE:
          return 'WORK_MOBILE';
        case Address.PhoneNumberDataType.WORK_WIRE:
          return 'WORK_WIRE';
      }
      return 'OTHER';
    }

    // endregion

    // region E-mail address

    public static toPublicEmailAddressList(resources: EmailAddressResource[]): List<Address.EmailAddressData> {
      if (!resources) {
        return List.of<Address.EmailAddressData>();
      }
      return List.of(...resources.map((resource) => {
        return Mapper.toPublicEmailAddress(resource);
      }));
    }

    public static toPublicEmailAddressOpt(resource?: EmailAddressResource): Address.EmailAddressData | undefined {
      if (resource) {
        return Mapper.toPublicEmailAddress(resource);
      }
      return undefined;
    }

    public static toPublicEmailAddress(resource: EmailAddressResource): Address.EmailAddressData {
      return {
        value: Services.toEmailAddress(resource.value),
        description: Services.optToString(resource.comment),
        typeId: resource.type_id,
        typeNames: resource.type_names,
        notify: resource.notify
      };
    }

    public static fromPublicEmailAddressList(dataList: List<Address.EmailAddressData>): EmailAddressResource[] {
      return dataList.map((data) => {
        return Mapper.fromPublicEmailAddress(data!);
      }).toArray();
    }

    public static fromPublicEmailAddressOpt(data?: Address.EmailAddressData): EmailAddressResource | undefined {
      if (data) {
        return Mapper.fromPublicEmailAddress(data);
      }
      return undefined;
    }

    public static fromPublicEmailAddress(data: Address.EmailAddressData): EmailAddressResource {
      return {
        value: Services.emailAddressToString(data.value),
        comment: data.description,
        type_id: data.typeId,
        notify: data.notify
      };
    }

    // endregion

    // region Company contact person

    public static toPublicCompanyContactPersonList(resources: CompanyContactPersonResource[]): List<Address.CompanyContactPerson> {
      if (!resources) {
        return List.of<Address.CompanyContactPerson>();
      }
      return List.of(...resources.map((resource) => {
        return Mapper.toPublicCompanyContactPerson(resource);
      }));
    }

    public static toPublicCompanyContactPersonOpt(resource?: CompanyContactPersonResource): Address.CompanyContactPerson | undefined {
      if (resource) {
        return Mapper.toPublicCompanyContactPerson(resource);
      }
      return undefined;
    }

    public static toPublicCompanyContactPerson(resource: CompanyContactPersonResource): Address.CompanyContactPerson {
      return {
        name: Services.optToString(resource.name),
        phoneNumber: Mapper.toPublicPhoneNumberOpt(resource.phone_number),
        emailAddress: Mapper.toPublicEmailAddressOpt(resource.email_address),
        position: Services.optToString(resource.position),
        comment: Services.optToString(resource.comment),
      };
    }

    public static fromPublicCompanyContactPersonList(dataList: List<Address.CompanyContactPerson>): CompanyContactPersonResource[] {
      return dataList.map((data) => {
        return Mapper.fromPublicCompanyContactPerson(data!);
      }).toArray();
    }

    public static fromPublicCompanyContactPersonOpt(data?: Address.CompanyContactPerson): CompanyContactPersonResource | undefined {
      if (data) {
        return Mapper.fromPublicCompanyContactPerson(data);
      }
      return undefined;
    }

    public static fromPublicCompanyContactPerson(data: Address.CompanyContactPerson): CompanyContactPersonResource {
      return {
        name: data.name,
        position: data.position,
        comment: data.comment,
        phone_number: Mapper.fromPublicPhoneNumberOpt(data.phoneNumber),
        email_address: Mapper.fromPublicEmailAddressOpt(data.emailAddress),
      };
    }

    // endregion

    // region Company location

    public static toPublicContactLocationList(resources: ContactLocationResource[]): List<Address.ContactLocation> {
      if (!resources) {
        return List.of<Address.ContactLocation>();
      }
      return List.of(...resources.map((resource) => {
        return Mapper.toPublicContactLocation(resource);
      }));
    }

    public static toPublicContactLocationOpt(resource?: ContactLocationResource): Address.ContactLocation | undefined {
      if (resource) {
        return Mapper.toPublicContactLocation(resource);
      }
      return undefined;
    }

    public static toPublicContactLocation(resource: ContactLocationResource): Address.ContactLocation {
      return {
        id: resource.id,
        name: Services.optToString(resource.name),
        customerRecordName: resource.customer_record_name,
        externalId: Services.optToString(resource.external_id),
        comment: Services.optToString(resource.comment),
        contractNumber: resource.contract_number,
        openingTimes: Services.optToString(resource.opening_times),
        contactPersons: resource.contact_persons ? List.of(...resource.contact_persons) : List.of(),
        postalAddress: Mapper.toPublicPostalAddressOpt(resource.postal_address),
      };
    }

    public static fromPublicContactLocationList(dataList: List<Address.ContactLocation>): ContactLocationResource[] {
      return dataList.map((data) => {
        return Mapper.fromPublicContactLocation(data!);
      }).toArray();
    }

    public static fromPublicContactLocationOpt(data?: Address.ContactLocation): ContactLocationResource | undefined {
      if (data) {
        return Mapper.fromPublicContactLocation(data);
      }
      return undefined;
    }

    public static fromPublicContactLocation(data: Address.ContactLocation): ContactLocationResource {
      return {
        id: data.id,
        name: data.name,
        external_id: data.externalId,
        opening_times: data.openingTimes,
        comment: data.comment,
        postal_address: Mapper.fromPublicPostalAddressOpt(data.postalAddress),
        contact_persons: data.contactPersons.toArray(),
        contract_number: data.contractNumber,
      };
    }

    public static toPublicPlace(r: PlaceResource): Address.Place {
      return {
        id: r.id,
        name: r.name,
        postalAddress: r.postal_address ? AddressResource.Mapper.toPublicPostalAddress(r.postal_address) : undefined,
        coordinate: this.toPublicCoordinate(r.coordinate),
        geocodeStatus: <GeocodeStatus>r.geocode_status
      };
    }

    public static optToPublicPlace(r?: PlaceResource): Address.Place | undefined {
      if (r) {
        return {
          id: r.id,
          name: r.name,
          postalAddress: r.postal_address ? AddressResource.Mapper.toPublicPostalAddress(r.postal_address) : undefined,
          coordinate: this.toPublicCoordinate(r.coordinate)
        };
      }
      return undefined;
    }

    public static toPublicCoordinate(r?: CoordinateResource): Address.Coordinate | undefined {
      if (r) {
        return {
          longitude: Services.toDecimal(r.longitude)!,
          latitude: Services.toDecimal(r.latitude)!,
        };
      }
      return undefined;
    }

    public static fromPublicPlace(r: Address.PlaceCreateRequest): PlaceCreateResourceRequest {
      return {
        postal_address: AddressResource.Mapper.fromPublicPostalAddress(r.postalAddress),
        name: r.name,
        coordinate: this.fromPublicCoordinate(r.coordinate)
      };
    }

    public static fromPublicCoordinate(r?: Address.Coordinate): CoordinateResource | undefined {
      if (r) {
        return {
          latitude: r.latitude.toString(),
          longitude: r.longitude.toString(),
        };
      }
      return undefined;
    }

    // endregion

  }

}

// Layer 2
export namespace Address {

  import GeocodeStatus = AddressResource.GeocodeStatus;

  export interface EmailAddressData {
    value: EmailAddress;
    typeId: number;
    typeNames?: Map<string, string>;
    description?: string;
    notify?: boolean;
  }

  export enum PhoneNumberDataType {
    WORK_MOBILE = 'WORK_MOBILE',
    WORK_WIRE = 'WORK_WIRE',
    PERSONAL_MOBILE = 'PERSONAL_MOBILE',
    PERSONAL_WIRE = 'PERSONAL_WIRE',
    OTHER = 'OTHER'
  }

  export interface PhoneNumberData {
    value: PhoneNumber;
    type: PhoneNumberDataType;
    description?: string;
  }

  export enum PostalAddressDataType {
    COMPLEX,
    TEXT,
  }

  export interface PostalAddressData {
    id?: number;
    type: PostalAddressDataType;
    coordinate?: Coordinate;
    complexValue?: PostalAddressStruct;
    textValue?: string;
  }

  export interface PostalAddressStruct {
    countryCode: string;
    zipCode: string;
    city: string;
    address: string;
    addressType?: string; // null if address contains it
    houseNumber?: string; // null if address contains it
    building?: string;
    stairway?: string;
    floor?: string;
    door?: string;
    name?: string;
  }

  export interface Place {
    id: number;
    postalAddress?: Address.PostalAddressData;
    name: string;
    coordinate?: Coordinate;
    geocodeStatus?: GeocodeStatus;
  }

  export interface PlaceCreateRequest {
    postalAddress: Address.PostalAddressData;
    name: string;
    coordinate?: Coordinate;
  }

  export class PostalAddressMapper {
    // "{{ZipCode}} {{City}} {{Address}} {{AddressType}} {{HouseNumber}} {{Building}} {{Stairway}} {{Floor}} {{Door}}"
    public static toString(data: Address.PostalAddressData | AddressModel.PostalAddressModel, format: string): string {
      if (data.type === Address.PostalAddressDataType.TEXT && data.textValue) {
        return data.textValue;
      } else if ((data.type === Address.PostalAddressDataType.COMPLEX
        || data.type === AddressModel.PostalAddressModelType.COMPLEX
        || data.type === AddressModel.PostalAddressModelType.SIMPLE)
        && data.complexValue) {
        let formatString: string = format;
        const complex = data.complexValue;
        const addressMap: { key: string, value: string | undefined }[] = [];
        addressMap.push({key: 'ZipCode', value: complex.zipCode});
        addressMap.push({key: 'City', value: complex.city});
        addressMap.push({key: 'Address', value: complex.address});
        addressMap.push({key: 'AddressType', value: complex.addressType});
        addressMap.push({key: 'HouseNumber', value: complex.houseNumber});
        addressMap.push({key: 'Building', value: complex.building});
        addressMap.push({key: 'Stairway', value: complex.stairway});
        addressMap.push({key: 'Floor', value: complex.floor});
        addressMap.push({key: 'Door', value: complex.door});
        addressMap.push({key: 'Name', value: complex.name});
        addressMap.forEach((item) => {
          formatString = formatString.replace(RegExp('{{' + item.key + '}}', 'g'), item.value ? item.value : '');
        });
        formatString = formatString.replace(RegExp('  +', 'g'), ' ');
        formatString = formatString.replace(RegExp(' *"', 'g'), '');
        if (formatString.startsWith(' , ')) {
          formatString = formatString.slice(3);
        }
        // formatString.trim() would be a cleaner solution, but it does not work here for some reason.
        if (formatString.charAt(formatString.length - 1) === ' ') {
          formatString = formatString.slice(0, -1);
        }
        return formatString;
      }
      return '';
    }


  }

  export interface Coordinate {
    latitude: Decimal;
    longitude: Decimal;
  }

  export interface CompanyContactPerson {
    name: string;
    phoneNumber?: PhoneNumberData;
    emailAddress?: EmailAddressData;
    position?: string;
    comment?: string;
  }

  export interface ContactLocation {
    id?: number;
    name: string;
    customerRecordName?: string;
    externalId?: string;
    comment?: string;
    openingTimes?: string;
    contactPersons: List<number>;
    postalAddress?: PostalAddressData;
    contractNumber?: string;
  }

}

// Layer 3
export namespace AddressModel {

  // region Postal address

  import PostalAddressDataType = Address.PostalAddressDataType;

  export enum PostalAddressModelType {
    NONE, SIMPLE, COMPLEX
  }

  export class PostalAddressModel {

    readonly countryItems: CountryItem[] = [];

    id?: number;
    type: PostalAddressModelType;
    readonly complexValue: PostalAddressComplexModel;
    readonly coordinate: CoordinateModel;

    get valid(): boolean {
      // NOTE: if you change the logic, update method #toData too
      if (PostalAddressModelType.NONE === this.type) {
        return true;
      }
      const complex = this.type === PostalAddressModelType.COMPLEX;
      const longitude = Models.parseDecimal(this.coordinate.longitude);
      const latitude = Models.parseDecimal(this.coordinate.latitude);
      if ((longitude === undefined) !== (latitude === undefined)) {
        return false;
      }
      if (this.complexValue.address.length > 0 && this.complexValue.zipCode.length === 0) {
        // NOTE: This case is only for supporting old, text type addresses which can't be updated
        // (e.g.: because the task record that contained them were already submitted/archived/etc.), but need to be loaded into edit view.
        // The form group level validation takes care of every other case.
        return true;
      }
      if (!this.complexValue.country) {
        return false;
      }
      if (this.complexValue.zipCode.length < 1) {
        return false;
      }
      if (this.complexValue.city.length < 1) {
        return false;
      }
      if (this.complexValue.address.length < 1) {
        return false;
      }
      if (complex) {
        if (this.complexValue.addressType.length < 1) {
          return false;
        }
        if (this.complexValue.houseNumber.length < 1) {
          return false;
        }
      }
      return true;
    }

    constructor() {
      this.complexValue = new PostalAddressComplexModel();
      this.coordinate = new CoordinateModel();
      this.reset();
    }

    reset(postalAddressType?: PostalAddressModelType) {
      this.id = undefined;
      this.type = postalAddressType ? postalAddressType : PostalAddressModelType.NONE;
      this.complexValue.reset();
      this.coordinate.reset();
    }

    /**
     * Call #loadCountryItems before using this method or you get an {@link Error}
     */
    load(data?: Address.PostalAddressData) {
      if (!data) {
        this.reset();
        return;
      }
      this.id = data.id;
      this.type = data.complexValue === undefined
        ? PostalAddressModelType.SIMPLE
        : data.complexValue.houseNumber && data.complexValue.houseNumber.length > 0
          ? PostalAddressModelType.COMPLEX
          : PostalAddressModelType.SIMPLE;
      this.complexValue.load(this.countryItems, data.complexValue, data.textValue);
      this.coordinate.load(data.coordinate);
    }

    loadCountryItems(countryItems: List<CountryItem>, defaultCountryCode?: string) {
      this.countryItems.splice(0, this.countryItems.length);
      this.countryItems.push(...countryItems.toArray());
      if (defaultCountryCode) {
        this.countryItems.forEach((countryItem) => {
          if (countryItem && countryItem.id === defaultCountryCode) {
            this.complexValue.country = countryItem;
          }
        });
      }
    }

    toData(): Address.PostalAddressData | undefined {
      // NOTE: if you change the logic, update getter #valid too
      if (PostalAddressModelType.NONE === this.type) {
        return undefined;
      }
      const complex = this.type === PostalAddressModelType.COMPLEX;
      const longitude = Models.parseDecimal(this.coordinate.longitude);
      const latitude = Models.parseDecimal(this.coordinate.latitude);
      if ((longitude === undefined) !== (latitude === undefined)) {
        throw new Error('Both latitude and longitude must be null or present');
      }
      const coordinate = !longitude || !latitude ? undefined : {
        latitude: latitude,
        longitude: longitude
      };
      if (this.complexValue.address.length > 0 && this.complexValue.zipCode.length === 0) {
        // NOTE: This case is only for supporting old, text type addresses which can't be updated
        // (e.g.: because the task record that contained them were already submitted/archived/etc.), but need to be loaded into edit view.
        // The form group level validation takes care of every other case.
        return {
          id: this.id,
          type: PostalAddressDataType.TEXT,
          coordinate: coordinate,
          complexValue: undefined,
          textValue: this.complexValue.address,
        };
      }
      if (!this.complexValue.country) {
        throw new Error('Country is not selected');
      }
      if (this.complexValue.zipCode.length < 1) {
        throw new Error('ZIP code is required');
      }
      if (this.complexValue.city.length < 1) {
        throw new Error('City is required');
      }
      if (this.complexValue.address.length < 1) {
        throw new Error('Address is required');
      }
      if (complex) {
        if (this.complexValue.addressType.length < 1) {
          // Legacy support
          // throw new Error('Address type is required');
        }
        if (this.complexValue.houseNumber.length < 1) {
          // Legacy support
          // throw new Error('House number is required');
        }
      }
      const addressType = complex ? this.complexValue.addressType : undefined;
      const houseNumber = complex ? this.complexValue.houseNumber : undefined;
      const building = complex ? this.complexValue.building : undefined;
      const stairway = complex ? this.complexValue.stairway : undefined;
      const floor = complex ? this.complexValue.floor : undefined;
      const door = complex ? this.complexValue.door : undefined;
      const complexValue: Address.PostalAddressStruct | undefined = {
        countryCode: this.complexValue.country.id!,
        zipCode: this.complexValue.zipCode,
        city: this.complexValue.city,
        address: this.complexValue.address,
        addressType: addressType,
        houseNumber: houseNumber,
        building: building,
        stairway: stairway,
        floor: floor,
        door: door,
        name: this.complexValue.name,
      };
      return {
        id: this.id,
        type: PostalAddressDataType.COMPLEX,
        coordinate: coordinate,
        complexValue: complexValue,
        textValue: undefined,
      };
    }

  }

  export class PostalAddressComplexModel {

    _country: CountryItem[];
    zipCode: string;
    city: string;
    address: string;
    addressType: string;
    houseNumber: string;
    building: string;
    stairway: string;
    floor: string;
    door: string;
    name: string;

    constructor() {
      this.reset();
    }

    get country() {
      return this._country[0];
    }

    set country(country: CountryItem) {
      this._country = [];
      this._country.push(country);
    }

    reset() {
      if (RuntimeConfiguration.getInstance().getDummyPostalAddress(false)) {
        this._country = [CountryItem.fromCountry({
          countryCode: 'HU',
          localizedName: 'HU'
        })];
        this.zipCode = '2330';
        this.city = 'Dunaharaszti';
        this.address = 'Attila';
        this.addressType = 'utca';
        this.houseNumber = '6';
      } else {
        this._country = [];
        this.zipCode = '';
        this.city = '';
        this.address = '';
        this.addressType = '';
        this.houseNumber = '';
      }
      this.building = '';
      this.stairway = '';
      this.floor = '';
      this.door = '';
      this.name = '';
    }

    load(countryItems: CountryItem[], data?: Address.PostalAddressStruct, text?: string) {
      if (!data && !text) {
        this.reset();
        return;
      }
      if (data) {
        countryItems.forEach((countryItem) => {
          if (countryItem.id === data.countryCode) {
            this.country = countryItem;
          }
        });
        this.zipCode = Models.optToString(data.zipCode);
        this.city = Models.optToString(data.city);
        this.address = Models.optToString(data.address);
        this.addressType = Models.optToString(data.addressType);
        this.houseNumber = Models.optToString(data.houseNumber);
        this.building = Models.optToString(data.building);
        this.stairway = Models.optToString(data.stairway);
        this.floor = Models.optToString(data.floor);
        this.door = Models.optToString(data.door);
        this.name = Models.optToString(data.name);
      } else {
        this.address = Models.optToString(text);
      }
    }

  }

  export class CoordinateModel {

    latitude: string;
    longitude: string;

    constructor() {
      this.reset();
    }

    reset() {
      this.latitude = '';
      this.longitude = '';
    }

    load(data?: Address.Coordinate) {
      if (!data) {
        this.reset();
        return;
      }
      this.latitude = Models.decimalToString(data.latitude);
      this.longitude = Models.decimalToString(data.longitude);
    }

  }

  export class CountryItem extends ImmutableOptionItem<string> {

    public static fromCountry(country: Country.Country): CountryItem {
      const localizedNameObs = observableDefer(() => observableOf(country.localizedName));
      return new CountryItem(country.countryCode, localizedNameObs);
    }

    public static fromCountryList(countryList: List<Country.Country>): List<CountryItem> {
      return List.of(...countryList.toArray().map((country) => {
        return CountryItem.fromCountry(country);
      }));
    }

  }

  // endregion

  // region E-mail address

  export class EmailAddressModel {

    typeId: number = 1;
    value: string;
    description: string;

    public static isValid(items?: EmailAddressModel[]): boolean {
      let valid = true;
      if (items) {
        items.forEach((item) => {
          valid = valid && item.valid;
        });
      }
      return valid;
    }

    public static toDataList(items?: EmailAddressModel[]): List<Address.EmailAddressData> {
      if (!items) {
        return List.of<Address.EmailAddressData>();
      }
      return List.of(...items.map((item) => {
        return item.toData();
      }));
    }

    get valid(): boolean {
      return EmailAddresses.parse(this.value).isValid();
    }

    constructor() {
      this.reset();
    }

    reset() {
      this.typeId = 1;
      this.value = '';
      this.description = '';
    }

    load(data?: Address.EmailAddressData) {
      if (!data) {
        this.reset();
        return;
      }
      this.typeId = data.typeId;
      this.value = Models.emailAddressToString(data.value);
      this.description = Models.optToString(data.description);
    }

    toData(): Address.EmailAddressData {
      if (this.value.length < 1) {
        throw new Error('Value is required');
      }
      const value = Models.parseEmailAddress(this.value);
      if (!value.isValid()) {
        throw new Error('Invalid value');
      }
      return {
        value: value,
        typeId: this.typeId,
        description: this.description
      };
    }

  }

  export class OptionalEmailAddressModel {

    hasValue: boolean;
    readonly value: EmailAddressModel = new EmailAddressModel();

    get valid(): boolean {
      if (!this.hasValue) {
        return true;
      }
      return this.value.valid;
    }

    constructor() {
      this.reset();
    }

    reset() {
      this.hasValue = true;
      this.value.reset();
    }

    load(data?: Address.EmailAddressData) {
      if (!data) {
        this.reset();
        this.hasValue = false;
        return;
      }
      this.hasValue = true;
      this.value.load(data);
    }

    toData(): Address.EmailAddressData | undefined {
      if (!this.hasValue) {
        return undefined;
      }
      return this.value.toData();
    }

  }

  // endregion

  // region Phone number

  export class PhoneNumberModel {

    private static readonly DEFAULT_TYPE = Address.PhoneNumberDataType.PERSONAL_MOBILE;

    readonly types: Address.PhoneNumberDataType[] = List.of(
      Address.PhoneNumberDataType.PERSONAL_MOBILE,
      Address.PhoneNumberDataType.WORK_MOBILE,
      Address.PhoneNumberDataType.PERSONAL_WIRE,
      Address.PhoneNumberDataType.WORK_WIRE,
      Address.PhoneNumberDataType.OTHER
    ).toArray();

    type: Address.PhoneNumberDataType;
    value: string;
    description: string;

    public static getTypeDictionaryCode(type: Address.PhoneNumberDataType): string {
      switch (type) {
        case Address.PhoneNumberDataType.WORK_MOBILE:
          return 'COMMON_PHONE_NUMBER_TYPE_WORK_MOBILE';
        case Address.PhoneNumberDataType.WORK_WIRE:
          return 'COMMON_PHONE_NUMBER_TYPE_WORK_WIRE';
        case Address.PhoneNumberDataType.PERSONAL_MOBILE:
          return 'COMMON_PHONE_NUMBER_TYPE_PERSONAL_MOBILE';
        case Address.PhoneNumberDataType.PERSONAL_WIRE:
          return 'COMMON_PHONE_NUMBER_TYPE_PERSONAL_WIRE';
        case Address.PhoneNumberDataType.OTHER:
          return 'COMMON_PHONE_NUMBER_TYPE_OTHER';
      }
    }

    public static isValid(items?: PhoneNumberModel[]): boolean {
      let valid = true;
      if (items) {
        items.forEach((item) => {
          valid = valid && item.valid;
        });
      }
      return valid;
    }

    public static toDataList(items?: PhoneNumberModel[]): List<Address.PhoneNumberData> {
      if (!items) {
        return List.of<Address.PhoneNumberData>();
      }
      return List.of(...items.map((item) => {
        return item.toData();
      }));
    }

    get valid(): boolean {
      return PhoneNumbers.parse(this.value).isValid();
    }

    constructor() {
      this.reset();
    }

    reset() {
      this.type = PhoneNumberModel.DEFAULT_TYPE;
      this.value = '';
      this.description = '';
    }

    load(data?: Address.PhoneNumberData) {
      if (!data) {
        this.reset();
        return;
      }
      this.type = data.type;
      this.value = Models.phoneNumberToString(data.value);
      this.description = Models.optToString(data.description);
    }

    toData(): Address.PhoneNumberData {
      if (this.value.length < 1) {
        throw new Error('Value is required');
      }
      const value = Models.parsePhoneNumber(this.value);
      if (!value.isValid()) {
        throw new Error('Invalid value');
      }
      return {
        value: value,
        type: this.type,
        description: this.description
      };
    }

  }

  export class OptionalPhoneNumberModel {

    hasValue: boolean;
    readonly value: PhoneNumberModel = new PhoneNumberModel();

    get valid(): boolean {
      if (!this.hasValue) {
        return true;
      }
      return this.value.valid;
    }

    constructor() {
      this.reset();
    }

    reset() {
      this.hasValue = true;
      this.value.reset();
    }

    load(data?: Address.PhoneNumberData) {
      if (!data) {
        this.reset();
        this.hasValue = false;
        return;
      }
      this.hasValue = true;
      this.value.load(data);
    }

    toData(): Address.PhoneNumberData | undefined {
      if (!this.hasValue) {
        return undefined;
      }
      return this.value.toData();
    }

  }

  // endregion

  // region Company contact person

  export class CompanyContactPersonModel {

    name: string;
    position: string;
    description: string;
    readonly phoneNumber: OptionalPhoneNumberModel = new OptionalPhoneNumberModel();
    readonly emailAddress: OptionalEmailAddressModel = new OptionalEmailAddressModel();

    public static isValid(items?: CompanyContactPersonModel[]): boolean {
      let valid = true;
      if (items) {
        items.forEach((item) => {
          valid = valid && item.valid;
        });
      }
      return valid;
    }

    public static toDataList(items?: CompanyContactPersonModel[]): List<Address.CompanyContactPerson> {
      if (!items) {
        return List.of<Address.CompanyContactPerson>();
      }
      return List.of(...items.map((item) => {
        return item.toData();
      }));
    }

    get valid(): boolean {
      return this.hasName && this.phoneNumber.valid && this.emailAddress.valid;
    }

    get hasName(): boolean {
      return this.name.length > 0;
    }

    constructor() {
      this.reset();
    }

    reset() {
      this.name = '';
      this.position = '';
      this.description = '';
      this.phoneNumber.reset();
      this.emailAddress.reset();
    }

    load(data?: Address.CompanyContactPerson) {
      if (!data) {
        this.reset();
        return;
      }
      this.name = Models.optToString(data.name);
      this.position = Models.optToString(data.position);
      this.description = Models.optToString(data.comment);
      this.phoneNumber.load(data.phoneNumber);
      this.emailAddress.load(data.emailAddress);
    }

    toData(): Address.CompanyContactPerson {
      if (!this.hasName) {
        throw new Error('Name is required');
      }
      const phoneNumber = this.phoneNumber.toData();
      const emailAddress = this.emailAddress.toData();
      return {
        name: this.name,
        phoneNumber: phoneNumber,
        emailAddress: emailAddress,
        position: this.position,
        comment: this.description
      };
    }

  }

  // endregion

  // region Company location

  export class CompanyLocationModel {

    id?: number;
    name: string;
    externalId: string;
    description: string;
    openingTimeHtml: string;
    validateExternalIdRequired: boolean = true;
    externalIdUnique: boolean = true;
    readonly postalAddress: PostalAddressModel;
    readonly contactPersons: number[] = [];

    public static isValid(items?: CompanyLocationModel[]): boolean {
      let valid = true;
      if (items) {
        items.forEach((item) => {
          valid = valid && item.valid;
        });
      }
      return valid;
    }

    public static toDataList(items?: CompanyLocationModel[]): List<Address.ContactLocation> {
      if (!items) {
        return List.of<Address.ContactLocation>();
      }
      return List.of(...items.map((item) => {
        return item.toData();
      }));
    }

    get valid(): boolean {
      return this.hasName && this.postalAddress.valid && this.externalIdValid;
    }

    get hasName(): boolean {
      return this.name.length > 0;
    }

    get externalIdValid(): boolean {
      return (!this.validateExternalIdRequired || this.externalId.length > 0) && this.externalIdUnique;
    }

    constructor() {
      this.postalAddress = new PostalAddressModel();
      this.reset();
    }

    reset() {
      this.id = undefined;
      this.name = '';
      this.externalId = '';
      this.description = '';
      this.openingTimeHtml = '';
      this.postalAddress.reset();
      this.contactPersons.splice(0, this.contactPersons.length);
    }

    load(countryItems: List<CountryItem>, isClone?: boolean, defaultSelectedCountryCode?: string, data?: Address.ContactLocation) {
      this.postalAddress.loadCountryItems(countryItems, defaultSelectedCountryCode);
      if (!data) {
        this.reset();
        return;
      }
      this.id = !isClone ? data.id : undefined;
      this.name = Models.optToString(data.name);
      this.externalId = Models.optToString(data.externalId);
      this.description = Models.optToString(data.comment);
      this.openingTimeHtml = Models.optToString(data.openingTimes);
      this.postalAddress.load(data.postalAddress);
      this.contactPersons.splice(0, this.contactPersons.length);
      this.contactPersons.push(...data.contactPersons.toArray());
    }

    toData(): Address.ContactLocation {
      if (!this.hasName) {
        throw new Error('Name is required');
      }
      return {
        id: this.id,
        name: this.name,
        externalId: this.externalId,
        comment: this.description,
        openingTimes: this.openingTimeHtml,
        postalAddress: this.postalAddress.toData(),
        contactPersons: List.of(...this.contactPersons),
      };
    }
  }

  // endregion

}
