import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

@Directive({selector: '[appImgPreview]'})
export class ImagePreviewDirective implements OnChanges {

  @Input() image: File;

  constructor(private el: ElementRef, private renderer2: Renderer2) {
  }

  ngOnChanges(changes: SimpleChanges) {

    if (!this.image || !this.image.type.startsWith('image')) {
      return;
    }

    const reader = new FileReader();
    const el = this.el;
    const renderer = this.renderer2;

    reader.onloadend = function (e) {
      renderer.setStyle(el.nativeElement, 'background-image', `url(${reader.result})`);
    };

    return reader.readAsDataURL(this.image);

  }

}
