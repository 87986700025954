<div class="card">

  <div class="card-header">
    {{'TRANSPORT_HISTORY' | translate}}
  </div>
  <div class="card-body">

    <table class="table table-striped table-bordered">
      <thead>
      <tr>
        <th class="table-sorter-header hidden-sm-down">
          <app-table-field-sorter
            [orderField]="TransportLog.OrderField.ID"
            [orderType]="queryModel.getOrderType(TransportLog.OrderField.ID)"
            (orderChange)="orderBy($event)"
            [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header hidden-sm-down">
          <app-table-field-sorter
            [orderField]="TransportLog.OrderField.CREATION_TIME"
            [orderType]="queryModel.getOrderType(TransportLog.OrderField.CREATION_TIME)"
            (orderChange)="orderBy($event)"
            [text]="'TRANSPORT_TIME' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header hidden-xs-down">
          <app-table-field-sorter
            [orderField]="TransportLog.OrderField.USER_PERSON_NAME"
            [orderType]="queryModel.getOrderType(TransportLog.OrderField.USER_PERSON_NAME)"
            (orderChange)="orderBy($event)"
            [text]="'TRANSPORT_USER' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header hidden-xs-down">
          <app-table-field-sorter
            [orderField]="TransportLog.OrderField.SHIPMENT_DELIVERY_NOTE_NUMBER"
            [orderType]="queryModel.getOrderType(TransportLog.OrderField.SHIPMENT_DELIVERY_NOTE_NUMBER)"
            (orderChange)="orderBy($event)"
            [text]="'TRANSPORT_SHIPMENT_DELIVERY_NOTE_NUMBER' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header">
          <app-table-sorter-no-op [text]="'TRANSPORT_LOG_EVENT_TYPE' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header w-table-actions-single">
          <app-table-sorter-no-op [text]=""></app-table-sorter-no-op>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let transportLog of transportLogList | paginate: { id: pagingId,
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }">
        <td class="hidden-sm-down">{{transportLog.id}}</td>
        <td class="hidden-sm-down">{{(transportLog.creationTime.toUtcIsoString() | date:'short') || ''}}</td>
        <td class="hidden-xs-down">{{transportLog.user.personName}}</td>
        <td class="hidden-xs-down">{{transportLog.shipment ? transportLog.shipment.deliveryNoteNumber : ''}}</td>
        <td>
          {{getTypeKey(transportLog.eventType) | translate}}<br>
          <span class="table-text-small" *ngIf="transportLog.transportTask">
            {{formatAddress(transportLog.transportTask)}}</span>
        </td>
        <td>
          <span (click)="showTransportLogDetailDialog(transportLog.id)">
          <a class="icons-table-group cursor-pointer">
            <i class="icomoon icons-table-item icomoon-detail icomoon-detail-table"
               title="{{'COMMON_BUTTON_DETAILS' | translate}}"></i>
          </a>
            </span>
        </td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <th class="table-sorter-header hidden-sm-down">
          <app-table-field-sorter
            [orderField]="TransportLog.OrderField.ID"
            [orderType]="queryModel.getOrderType(TransportLog.OrderField.ID)"
            (orderChange)="orderBy($event)"
            [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header hidden-sm-down">
          <app-table-field-sorter
            [orderField]="TransportLog.OrderField.CREATION_TIME"
            [orderType]="queryModel.getOrderType(TransportLog.OrderField.CREATION_TIME)"
            (orderChange)="orderBy($event)"
            [text]="'TRANSPORT_TIME' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header hidden-xs-down">
          <app-table-field-sorter
            [orderField]="TransportLog.OrderField.USER_PERSON_NAME"
            [orderType]="queryModel.getOrderType(TransportLog.OrderField.USER_PERSON_NAME)"
            (orderChange)="orderBy($event)"
            [text]="'TRANSPORT_USER' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header hidden-xs-down">
          <app-table-field-sorter
            [orderField]="TransportLog.OrderField.SHIPMENT_DELIVERY_NOTE_NUMBER"
            [orderType]="queryModel.getOrderType(TransportLog.OrderField.SHIPMENT_DELIVERY_NOTE_NUMBER)"
            (orderChange)="orderBy($event)"
            [text]="'TRANSPORT_SHIPMENT_DELIVERY_NOTE_NUMBER' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header">
          <app-table-sorter-no-op [text]="'TRANSPORT_LOG_EVENT_TYPE' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header w-table-actions-single">
          <app-table-sorter-no-op [text]=""></app-table-sorter-no-op>
        </th>
      </tr>
      </tfoot>
    </table>
    <app-table-paging
      [id]="pagingId"
      [currentNumberOfItems]="queryModel.currentNumberOfItems"
      [totalNumberOfItems]="queryModel.totalNumberOfItems"
      [itemsPerPage]="queryModel.itemsPerPage"
      (pageChange)="pageChanged($event)"
      (itemsPerPageChange)="itemsPerPageChanged($event)">
    </app-table-paging>
  </div><!-- /.card-body -->

</div><!-- /.card -->
