export interface EmailAddress {
  format(): string;

  toIso(): string | null;

  isValid(): boolean;
}

export class EmailAddresses {

  // https://stackoverflow.com/a/201378
  private static readonly VALID_PATTERN: RegExp =
    new RegExp('^(?:[a-z0-9!#$%&\'*+=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+=?^_`{|}~-]+)*|"' +
      '(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@' +
      '(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|' +
      '\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}' +
      '(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:' +
      '(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])');

  public static parse(text?: string | null): EmailAddress {
    if (text && EmailAddresses.VALID_PATTERN.test(text)) {
      return new ValidEmailAddress(text);
    }
    return new InvalidEmailAddress(text);
  }

}

class ValidEmailAddress implements EmailAddress {

  constructor(private text: string) {
  }

  format(): string {
    return this.text;
  }

  toIso(): string | null {
    return this.text;
  }

  isValid(): boolean {
    return true;
  }

}

class InvalidEmailAddress implements EmailAddress {

  constructor(private rawText?: string | null) {
  }

  format(): string {
    return this.rawText ? this.rawText : '';
  }

  toIso(): string | null {
    return null;
  }

  isValid(): boolean {
    return false;
  }

}
