<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]=breadcrumbParents
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding-half animated fadeIn">
  <form class="form-horizontal bordered-row" (ngSubmit)="createTaskRecord()" #f="ngForm" novalidate>
    <app-new-task-record-base
      id="task-record-create-base"
      headingDictionaryKey="{{(appTypeHelperService.isHelpdesk() ? 'BUG_REPORT_PANEL_HEADING_CREATE' : 'TASK_RECORD_PANEL_HEADING_CREATE') | translate}}"
      [readonly]="false"
      [allowClone]="false"
      [allowStateChange]="false"
      [allowExport]="false"
      [form]="f"
      (customerRecordChanged)="onCustomerRecordChanged()"
      (assigneeUserChanged)="onAssigneeUserChanged()"
      (selectedAddressChanged)="onSelectedAddressChanged()"
      (submitted)="submit($event)">
    </app-new-task-record-base>
    <app-form-record-container
      id="task-record-create-form"
      [readonly]="false"
      [cloning]="false"
      [form]="f"></app-form-record-container>
  </form>
</div>
