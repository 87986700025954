/* eslint-disable */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Set } from 'immutable';
import { QueryFieldModel, SelectUtils, UiConstants } from '../../../util/core-utils';
import { MasterData, MasterDataService } from '../../../lib/masterdata/master-data.service';
import { OrderType, QueryResult, } from '../../../lib/util/services';
import { RightModel } from '../../../app.rights';
import { RightResolver, RightService } from '../../../lib/right.service';
import { LoadingHandler } from '../../../lib/loading-handler';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { TranslateService } from '@ngx-translate/core';
import { StateName } from '../../../app.state-names';
import { MasterdataDisabledItem, MasterdataSearchModel } from '../../../util/masterdata-utils';
import { InputMask } from '../../../util/input-masks';
import { MasterdataSearch, MasterdataSearchService } from '../../../lib/masterdata-search-service';
import { combineLatest, Observable } from 'rxjs';
import { Arrays } from '../../../lib/util/arrays';
import { Strings } from '../../../lib/util/strings';
import { Models } from '../../../util/model-utils';
import { DisabledEnum, DisabledItem } from '../../../util/search-utils';
import { DropdownItemType } from '../../../shared/dropdown/dropdown-item/dropdown-item-type';
/* eslint-enable */

@Component({
  selector: 'app-masterdata-list',
  templateUrl: 'masterdata-list.component.html',
  styleUrls: ['masterdata-list.component.scss']
})
export class MasterDataListComponent implements OnInit, OnDestroy {

  MasterData = MasterData;
  UiConstants = UiConstants;
  DropdownItemType = DropdownItemType;

  queryModel: QueryFieldModel<MasterData.OrderField> = new QueryFieldModel(MasterData.OrderField.ID, OrderType.DESC);
  masterDataList: Array<MasterData.MasterData> = [];
  showSearch: boolean = false;
  rightModel: RightModel = RightModel.empty();
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  searchModel: MasterdataSearchModel = new MasterdataSearchModel();
  masterdataDisabledItems: DisabledItem[] = [];
  InputMask = InputMask;
  SelectUtils = SelectUtils;

  ngOnInit() {
    this.translateService.get('MENU_NAVBAR_MASTERDATA').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.loadSearch(() => {
      this.showSearch = !this.searchModel.isEmpty();
      this.initDisabledOptions(DisabledEnum.FALSE);
      this.loadList();
    });
    this.loadRightModels();
  }

  private loadSearch(completion: () => void) {
    const obs: Observable<SearchLoadResult> = combineLatest(
      this.masterdataSearchService.getSearchData({}),
      (storedSearchData: MasterdataSearch.SearchDataResult) => {
        const result: SearchLoadResult = {
          storedSearchData: storedSearchData
        };
        return result;
      }
    );
    obs.subscribe(
      (result: SearchLoadResult) => {
        this.postInitSearch(result.storedSearchData);
        completion();
      }
    );
  }

  private initDisabledOptions(initValue: DisabledEnum) {
    this.masterdataDisabledItems = [];
    const disabledEnums: DisabledEnum[] = [DisabledEnum.NONE, DisabledEnum.FALSE, DisabledEnum.TRUE];
    Arrays.iterateByIndex(disabledEnums, (key) => {
      const item = new DisabledItem();
      item.id = key;
      this.translateService.get('COMMON_VALUE_DISABLED_ENUM_' + key).subscribe(
        (text: string) => {
          item.text = text;
        }
      );
      this.masterdataDisabledItems.push(item);
      if (!this.searchModel.disabled) {
        if (key === initValue) {
          this.searchModel.disabled = item;
        }
      }
    });
  }

  private postInitSearch(storedSearchData: MasterdataSearch.SearchDataResult) {
    this.queryModel.itemsPerPage = storedSearchData.searchData.itemsPerPage;
    this.queryModel.currentPage = storedSearchData.searchData.pageNumber;
    this.queryModel.setOrder(storedSearchData.searchData.order);
    this.searchModel.id = storedSearchData.searchData.id;
    this.searchModel.name = storedSearchData.searchData.name;
    this.searchModel.externalId = storedSearchData.searchData.external_id;
    this.searchModel.disabled = storedSearchData.searchData.disabled;
  }

  private getActiveItem(): MasterdataDisabledItem {
    const item = new MasterdataDisabledItem();
    item.id = 'ACTIVE';
    item.text = 'MASTERDATA_SEARCH_FIELD_DISABLED_ACTIVE';
    return item;
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: MasterData.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  setDisabled(masterDataId: number, disabled: boolean) {
    this.masterDataService.setDisabled({
      masterDataId: masterDataId,
      disabled: disabled
    }).subscribe(
      (result: MasterData.MasterData) => {
        LoadingHandler.getInstance().refresh();
        this.loadList();
      },
      (error: any) => {
        this.loadList();
      }
    );
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  private loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();
    const masterdataIdSet: number[] = [];
    const disabled: boolean | undefined = !this.searchModel.disabled ||
    this.searchModel.disabled.id === DisabledEnum.NONE ?
      undefined : this.searchModel.disabled.id === DisabledEnum.TRUE;
    if (Models.parseNumber(this.searchModel.id)) {
      masterdataIdSet.push(Models.parseNumber(this.searchModel.id)!);
    }
    this.masterDataService.query({
      masterDataIdSet: Set.of(...masterdataIdSet),
      name: Strings.undefinedOrNonEmpty(this.searchModel.name),
      externalId: Strings.undefinedOrNonEmpty(this.searchModel.externalId),
      disabled:  disabled,
      orders: Set.of(order),
      paging: {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      }
    }).subscribe(
      (result: QueryResult<MasterData.MasterData>) => {
        this.masterDataList = result.items.toArray();
        this.queryModel.currentPage = requestedPage;
        this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
        this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
      }
    );
  }

  getDisabled(): boolean | undefined {
    if (this.searchModel.disabled!.id === DisabledEnum.FALSE) {
      return false;
    }
    else if (this.searchModel.disabled!.id === DisabledEnum.TRUE) {
      return true;
    }
    return undefined;
  }

  onSearchClicked() {
    this.loadList();
  }

  onSearchReset() {
    this.masterdataSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.showSearch = true;
          this.loadList(1);
        });
      }
    );
  }

  private saveSearch() {
    const request = {
      searchData: {
        itemsPerPage: this.queryModel.itemsPerPage,
        pageNumber: this.queryModel.currentPage,
        order: this.queryModel.getOrder(),
        id: this.searchModel.id,
        name: this.searchModel.name,
        external_id: this.searchModel.externalId,
        disabled: this.searchModel.disabled
      }
    };
    this.masterdataSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  ngOnDestroy() {
    this.saveSearch();
  }

  constructor(
    private rightService: RightService,
    private translateService: TranslateService,
    private masterDataService: MasterDataService,
    private masterdataSearchService: MasterdataSearchService) {
  }

}

interface SearchLoadResult {
  storedSearchData: MasterdataSearch.SearchDataResult,
}

