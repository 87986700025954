/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Address, AddressResource } from '../../address';
import { TransportTaskResource } from '../transport-task/transport-task-resource.service';
import { ResourceQueryResult } from '../../util/services';
import { BaseHttpService, ResourceHelper } from '../../util/http-services';
import { HttpClient } from '@angular/common/http';

/* eslint-enable */

@Injectable()
export class TransportLogResourceService extends BaseHttpService {

  query(request: TransportLogResource.QueryRequest): Observable<ResourceQueryResult<TransportLogResource.TransportLog>> {
    return this.http.get<ResourceQueryResult<TransportLogResource.TransportLog>>
    (this.url + `${request.transport_id}/logs`, this.parseParams(request));
  }

  get(request: TransportLogResource.GetRequest): Observable<TransportLogResource.TransportLog> {
    return this.http.get<TransportLogResource.TransportLog>
    (this.url + `${request.transport_id}/logs/${request.id}`);
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/transports/');
  }
}


export namespace TransportLogResource {

  export interface TransportLog {
    id: number;
    creation_time: string;
    user: UserData;
    shipment?: ShipmentData;
    coordinate?: AddressResource.CoordinateResource;
    event_type: string;
    gate_keeper_external_id?: string;
    transport_task?: TransportTaskData;
  }

  export interface TransportTaskData {
    index: number;
    shipping_place: TransportTaskResource.ShippingPlaceData;
  }

  export interface ShipmentData {
    delivery_note_number: string;
  }

  export interface UserData {
    id: number;
    person_name: string;
  }

  export interface QueryRequest {
    transport_id: number;
    q?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
  }

  export interface GetRequest {
    transport_id: number;
    id: number;
  }

  export interface ShippingPlace {
    id: number;
    name: string;
    postal_address: AddressResource.PostalAddressResource;
    coordinate?: Address.Coordinate;
    geocode_status: AddressResource.GeocodeStatus;
    stockId?: number;
    locationId?: number;
  }

}
