import { Component, Input, OnInit } from '@angular/core';
import { CustomerRecord, CustomerRecordService } from '../../../lib/customer/customer-record.service';
import { RightModel } from '../../../app.rights';
import { Set } from 'immutable';
import { UserService } from '../../../lib/user.service';
import { DownloadedFile } from '../../../lib/util/downloaded-files';

@Component({
  selector: 'app-customer-record-helpdesk',
  templateUrl: './customer-record-helpdesk.component.html',
  styleUrls: ['./customer-record-helpdesk.component.scss']
})
export class CustomerRecordHelpdeskComponent implements OnInit {

  @Input()
  customerRecord: CustomerRecord.CustomerRecord;

  @Input()
  rightModel: RightModel = RightModel.empty();

  profilePicture: any | undefined;

  get helpdesk(): CustomerRecord.HelpdeskData | undefined {
    return this.customerRecord.helpdesk;
  }

  constructor(
    private customerRecordService: CustomerRecordService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
  }

  initComponent() {
    if (this.helpdesk && this.helpdesk.user) {
      this.loadProfilePicture();
    }
  }
  private loadProfilePicture() {
    this.userService.downloadProfilePicture(this.customerRecord.helpdesk!.user!.id).subscribe(
      (res: DownloadedFile) => {
        this.profilePicture = URL.createObjectURL(res.getBlob());
      },
      () => {
      }
    );
  }

  inviteToHelpdesk() {
    this.customerRecordService.inviteToHelpdesk(this.getHelpdeskRequest())
      .subscribe(result => {
        location.reload();
      });
  }

  disableHelpdeskUser() {
    this.customerRecordService.disableHelpdeskUser(this.getHelpdeskRequest())
      .subscribe(result => {
        this.helpdesk!.user!.disabled = true;
      });
  }

  enableHelpdeskUser() {
    this.customerRecordService.enableHelpdeskUser(this.getHelpdeskRequest())
      .subscribe(result => {
        this.helpdesk!.user!.disabled = false;
      });
  }

  private getHelpdeskRequest(): CustomerRecord.HelpdeskInviteRequest {
    return {
      customerRecordIds: Set.of(this.customerRecord.customerRecordId)
    };
  }

}
