<div class="animated fadeIn">

  <div class="card">

    <div class="card-header">
      {{'COMMON_LOCATION_CONTACT_PERSONS' | translate}}
      <div class="card-actions">
        <a class="btn-setting table-header-action-button d-flex align-items-center px-1 w-auto font-weight-normal"
           (click)="tryInviteToHelpdesk()"
           *ngIf="rightModel.customerRecordHelpdeskAction.hasRight()"
           title="{{'CUSTOMER_RECORD_INVITE_TO_HELPDESK' | translate}}">
          <i class="icomoon pr-1 icomoon-email-send"></i>{{'CUSTOMER_RECORD_INVITE_TO_HELPDESK' | translate}}
        </a>
        <a class="btn-setting table-header-action-button d-flex align-items-center px-1 w-auto font-weight-normal"
           (click)="createContactPerson()"
           *ngIf="!readonly"
           title="{{'CUSTOMER_RECORD_CONTACT_PERSON_CREATE' | translate}}">
          <i class="icomoon pr-1 icomoon-add"></i>{{'CUSTOMER_RECORD_CONTACT_PERSON_CREATE' | translate}}
        </a>
        <a class="btn-setting table-header-action-button d-flex align-items-center px-1 w-auto font-weight-normal"
           (click)="addContactPerson.emit()"
           *ngIf="!readonly"
           title="{{'CUSTOMER_RECORD_CONTACT_PERSON_ADD' | translate}}">
          <i class="icomoon pr-1 icomoon-add"></i>{{'CUSTOMER_RECORD_CONTACT_PERSON_ADD' | translate}}
        </a>
      </div><!-- /.card-actions -->
    </div>

    <div class="card-body">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="CustomerRecordContactPerson.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(CustomerRecordContactPerson.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [checkAllEnabled]="true"
                                    [checkAllSelected]="eachCustomerRecordSelected"
                                    (onCheckAllClicked)="toggleEachCustomerRecord()"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="CustomerRecordContactPerson.OrderField.NAME"
                                    [orderType]="queryModel.getOrderType(CustomerRecordContactPerson.OrderField.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'CUSTOMER_RECORD_CONTACT_PERSON_CREATE_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-sorter-no-op
              [text]="'COMMON_PHONE_NUMBER' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-sorter-no-op
              [text]="'COMMON_EMAIL_ADDRESS' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op
              [text]="'CUSTOMER_RECORD_LOCATIONS' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let row of customerRecordList | paginate: {
                                                      id: pagingId,
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }">
          <td class="fit align-middle">
            <div class="d-flex">
              <app-animated-checkbox
                class="toggle-checkbox"
                [(selected)]="row.selected">
              </app-animated-checkbox>
              {{row.cr.customerRecordId}}
            </div>
          </td>
          <td class="responsive-table-column position-relative">
            <app-customer-record-table-cell
              [imageSize]="60"
              [customerRecord]="row.cr"
              [rightModel]="rightModel">
            </app-customer-record-table-cell>
            <div class="state-icon-container" *ngIf="row.cr.helpdesk && row.cr.helpdesk.invitationTime"
                 [ngClass]="{'state-not-registered': !row.cr.helpdesk.user, 'state-active': row.cr.helpdesk.user && !row.cr.helpdesk.user.disabled, 'state-disabled': row.cr.helpdesk.user && row.cr.helpdesk.user.disabled}"
                 [popover]="helpdeskPopover"
                 triggers="mouseenter:mouseleave" container="body">
              <i class="icomoon icomoon-stock-type-central"></i>
            </div>
            <ng-template #helpdeskPopover>
              <div>
                {{'CUSTOMER_RECORD_HELPDESK_INVITATION_TIME' | translate}}
                <div class="font-weight-bold">
                  {{row.cr.helpdesk.invitationTime.toUtcIsoString() | date:'short'}}
                </div>
              </div>
              <div>
                {{'CUSTOMER_RECORD_HELPDESK_INVITATION_STATE' | translate}}
                <div class="font-weight-bold">
                  {{(row.cr.helpdesk.user ? row.cr.helpdesk.user.disabled ? 'CUSTOMER_RECORD_HELPDESK_USER_DISABLED' : 'CUSTOMER_RECORD_HELPDESK_USER_REGISTERED' : 'CUSTOMER_RECORD_HELPDESK_USER_NOT_REGISTERED') | translate}}
                </div>
              </div>
            </ng-template>
          </td>
          <td class="fit hidden-md-down">
            <div *ngIf="row.cr.phoneNumbers.size > 0">
              {{row.cr.phoneNumbers.get(0).value.format()}}
              <div class="text-truncate table-text-subtitle">
                {{('COMMON_PHONE_NUMBER_TYPE_' + row.cr.phoneNumbers.get(0).type) | translate}}
              </div>
            </div>
          </td>
          <td class="fit hidden-md-down">
            <div *ngIf="row.cr.emailAddresses.size > 0">
              {{row.cr.emailAddresses.get(0).value.format()}}
              <div class="text-truncate table-text-subtitle">
                {{getEmailAddressTypeName(row.cr.emailAddresses.get(0))}}
              </div>
            </div>
          </td>
          <td class="responsive-table-column">
            <div *ngIf="row.cr.relatedLocations && row.cr.relatedLocations.size > 0"
                 [popover]="row.cr.relatedLocations.size > 1 ? relatedLocationsPopover : undefined"
                 triggers="mouseenter:mouseleave" container="body">
              {{row.cr.relatedLocations.get(0).name}}
              <div class="text-truncate table-text-subtitle">
                {{formatPostalAddress(row.cr.relatedLocations.get(0).postalAddress)}}
              </div>
            </div>
            <ng-template #relatedLocationsPopover>
              <div *ngFor="let location of row.cr.relatedLocations.toArray() | slice:1">
                {{location.name}}
                <div class="text-muted">{{formatPostalAddress(location.postalAddress)}}</div>
              </div>
            </ng-template>
          </td>
          <td class="align-middle">
            <app-table-options-menu>
              <app-dropdown-item
                [iconClass]="'icomoon-detail'"
                [titleStringKey]="'COMMON_BUTTON_DETAILS'"
                class="cursor-pointer"
                uiSref="Admin.CustomerRecordDetail"
                [uiParams]="{ customerId: row.cr.customerId, customerRecordId: row.cr.customerRecordId }">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="rightModel.customerRecordUpdate.hasRight() && !readonly"
                [iconClass]="'icomoon-modify'"
                [titleStringKey]="'COMMON_BUTTON_EDIT'"
                class="cursor-pointer"
                uiSref="Admin.CustomerRecordEdit"
                [uiParams]="{ customerId: row.cr.customerId, customerRecordId: row.cr.customerRecordId }">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="rightModel.customerRecordUpdate.hasRight() && !readonly"
                [iconClass]="'icomoon-trash'"
                [titleStringKey]="'COMMON_BUTTON_REMOVE'"
                class="cursor-pointer"
                (click)="removeContactPerson(row.cr.customerRecordId)">
              </app-dropdown-item>
            </app-table-options-menu>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="CustomerRecordContactPerson.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(CustomerRecordContactPerson.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [checkAllEnabled]="true"
                                    [checkAllSelected]="eachCustomerRecordSelected"
                                    (onCheckAllClicked)="toggleEachCustomerRecord()"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="CustomerRecordContactPerson.OrderField.NAME"
                                    [orderType]="queryModel.getOrderType(CustomerRecordContactPerson.OrderField.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'CUSTOMER_RECORD_CONTACT_PERSON_CREATE_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-sorter-no-op
              [text]="'COMMON_PHONE_NUMBER' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-sorter-no-op
              [text]="'COMMON_EMAIL_ADDRESS' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op
              [text]="'CUSTOMER_RECORD_LOCATIONS' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [id]="pagingId"
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>
  </div>
</div>
