/* eslint-disable */
import { Component, Input, } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AddressModel } from '../../../../lib/address';
import { ListView } from '../../list-view';
/* eslint-enable */

@Component({
  selector: 'app-phone-number-list',
  templateUrl: './phone-number-list.component.html',
  styleUrls: ['./phone-number-list.component.scss']
})
export class PhoneNumberListComponent implements ListView {

  @Input()
  form?: NgForm;

  @Input()
  items?: AddressModel.PhoneNumberModel[];

  @Input()
  label?: string;

  @Input()
  readonly: boolean;

  @Input()
  fieldStyle?;

  @Input()
  required?: boolean;

  get m() {
    return this.items!;
  }

  addNewEmptyItem() {
    const items = this.items!;
    this.items!.push(new AddressModel.PhoneNumberModel());
  }

  removeItem(item: AddressModel.PhoneNumberModel) {
    const items = this.items!;
    items.splice(items.indexOf(item), 1);
  }

  hasLocalError(): boolean {
    if (!this.required || this.readonly || !this.form || !this.items) {
      return false;
    }
    return this.form!.submitted && this.items.length < 1;
  }

}
