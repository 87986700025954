import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FileAttachment, FileAttachmentUpdateRequest } from '../../../../lib/util/file-attachments';
import { RightModel } from '../../../../app.rights';
import { MasterDataRecord, MasterDataRecordService } from '../../../../lib/masterdata/master-data-record.service';
import { RightResolver, RightService } from '../../../../lib/right.service';
import { Observable } from 'rxjs';
import { NamedBlobFile } from '../../../../lib/util/downloaded-files';
import { UserDataLoader } from '../../../../lib/user-data-loader';
import { MasterDataRecordAttachmentRightModel } from './masterdata-record-attachment.rights';
import { EmptyMessage } from '../../../../lib/util/messages';
import { ThumbnailLoaderFactory } from '../../../../shared/material-attachment-gallery/material-attachment-gallery.component';

@Component({
  selector: 'app-masterdata-record-attachment',
  templateUrl: './masterdata-record-attachment.component.html',
  styleUrls: ['./masterdata-record-attachment.component.scss']
})
export class MasterdataRecordAttachmentComponent implements OnInit, AfterViewInit, ThumbnailLoaderFactory {

  @Input()
  masterDataRecordId: number;

  @Input()
  readonly: boolean;

  @Input()
  masterDataId: number;

  fileLoaderFactory = this;

  rightModel: RightModel = RightModel.empty();

  masterDataRecordAttachmentRightModel: MasterDataRecordAttachmentRightModel = MasterDataRecordAttachmentRightModel.empty();

  constructor(private masterDataRecordService: MasterDataRecordService,
              private userDataLoader: UserDataLoader,
              private rightService: RightService) {
  }

  getUploadPath(): string {
    return '/master-data/' + this.masterDataId + '/records/' + this.masterDataRecordId + '/attachments';
  }

  ngOnInit() {
    this.loadRightModels(() => {
      if (this.rightModel.masterDataRecordAttachmentRead.hasRight()) {
        this.loadAttachments();
      }
    });
  }

  loadAttachments() {
    return this.masterDataRecordService.getAttachments({
      masterDataRecordId: this.masterDataRecordId,
      masterDataId: this.masterDataId,
    });
  }

  deleteAttachment(fileId: number) {
    return this.masterDataRecordService.deleteAttachment({
      masterDataId: this.masterDataId,
      masterDataRecordId: this.masterDataRecordId,
      fileId: fileId
    });
  }

  private loadRightModels(completion: () => void) {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        completion();
      }
    );
  }

  public createFileLoader(attachment: FileAttachment): Observable<NamedBlobFile> {
    return this.masterDataRecordService.downloadAttachment({
      masterDataId: this.masterDataId,
      masterDataRecordId: this.masterDataRecordId,
      attachment: attachment,
    });
  }

  hasThumbnails(): boolean {
    return true;
  }

  createThumbnailLoader(attachment: FileAttachment): Observable<NamedBlobFile> {
    return this.masterDataRecordService.downloadAttachmentThumbnail({
      masterDataId: this.masterDataId,
      masterDataRecordId: this.masterDataRecordId,
      attachment: attachment,
    });
  }

  updateAttachment(r: FileAttachmentUpdateRequest): Observable<EmptyMessage> {
    const request: MasterDataRecord.UpdateAttachmentRequest = {
      name: r.name,
      fileId: r.fileId,
      masterDataId: this.masterDataId,
      masterDataRecordId: this.masterDataRecordId
    };
    return this.masterDataRecordService.updateAttachment(request);
  }

  ngAfterViewInit(): void {
  }

}
