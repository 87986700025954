<div class="card" *ngIf="!readonly || (readonly && m.id)">
  <div class="card-header">
    {{addressNameStringKey | translate}}
    <div class="card-actions">
    </div>
  </div>
  <div class="card-body" *ngIf="m.type !== AddressModel.PostalAddressModelType.NONE">
    <div class="d-flex justify-content-between" *ngIf="!readonly || (readonly && addressEqPostal)">
      <label class="col-form-label form-control-label detail-title">{{addressNameStringKey | translate}} {{'SAME_AS_POSTAL_ADDRESS' |
        translate}}</label>
      <label *ngIf="!readonly" class="switch switch-text switch-info" style="margin-left: 0.3rem">
        <input type="checkbox" class="switch-input"
               [(ngModel)]="addressEqPostal"
               [disabled]="readonly"
               (change)="checkboxChangedEvent($event)">
        <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
        <span class="switch-handle"></span>
      </label>
    </div>

    <div class="container-vertical-padding-half" *ngIf="!readonly || (readonly && addressEqPostal)"></div>

    <app-postal-address-complex
      *ngIf="!addressEqPostal && !readonly"
      [form]="form"
      [allowSimpleAddress]="true"
      [model]="model"
      [readonly]="readonly">
    </app-postal-address-complex>

    <div class="d-flex flex-column" *ngIf="readonly && !addressEqPostal && model.complexValue.address">
    <label class="col-form-label detail-description">{{Address.PostalAddressMapper.toString(m, config.postalAddressFormat)}}</label>
    <label class="col-form-label detail-description">{{model.complexValue.name}}</label>
    </div>

  </div>

  <div class="card-body text-center" *ngIf="!readonly && m.type === AddressModel.PostalAddressModelType.NONE && !addressEqPostal">
    <p class="empty-form-hint-text">{{ 'POSTAL_ADDRESS_ADD_TEXT' | translate }}</p>
    <button type="button" class="btn btn-secondary" (click)="addAddress()">{{ 'COMMON_BUTTON_ADD' | translate }}
    </button>
  </div>

</div>
