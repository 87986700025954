import { DqlQuery, Query } from '../field';

export class ExteriorTransport {

  readonly id: Query.OrderField;
  readonly state: Query.OrderField;
  readonly creationTime: Query.OrderField;
  readonly startTime: Query.OrderField;
  readonly finishTime: Query.OrderField;

  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this.id = new DqlQuery.OrderField(prefix + 'id');
    this.state = new DqlQuery.OrderField(prefix + 'state');
    this.creationTime = new DqlQuery.OrderField(prefix + 'creation_time');
    this.startTime = new DqlQuery.OrderField(prefix + 'start_time');
    this.finishTime = new DqlQuery.OrderField(prefix + 'finish_time');
  }

}
