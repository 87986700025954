import { empty as observableEmpty, Observable, of as observableOf, Subject } from 'rxjs';
import { catchError, concatMap, mergeMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LoggerFactory } from '../../util/logger-factory';
import { FirebaseMessageResourceService } from './firebase-message-resource.service';
import { TaskRecordStateMachine } from '../task/task-record-statemachine';
import { LegacyProcessTask } from '../legacy-process/legacy-process-task.service';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';

@Injectable()
export class FirebaseMessageService {

  private readonly logger = LoggerFactory.createLogger('FirebaseMessaging');
  currentMessage = new Subject<PushMessage>();

  private readonly currentTokenKey = 'firebase_current_token';

  constructor(
    private resourceService: FirebaseMessageResourceService,
    private angularFireMessaging: AngularFireMessaging) {
    this.angularFireMessaging.tokenChanges.subscribe(value => {
      this.angularFireMessaging.getToken.subscribe(token => {
        const currToken = this.getCurrentToken();
        if (currToken && currToken.token !== token) {
          this.updateToken(currToken, token);
        }
      });
    });
  }

  private updateToken(tokenPair: TokenPair, newToken: string | null) {
    if (newToken) {
      this.resourceService.updateToken(tokenPair.id, newToken).subscribe(result => {
        this.setCurrentToken({id: tokenPair.id, userId: tokenPair.userId, token: newToken});
      });
    } else {
      this.resourceService.deleteToken(tokenPair.id).subscribe(result => {
        this.setCurrentToken(null);
      }, error => {
        this.setCurrentToken(null);
      });
    }
  }

  /**
   * update token in firebase database
   *
   * @param userId userId as a key
   * @param token token as a value
   */
  private registerToken(userId: number, token: string | null) {
    const currToken = this.getCurrentToken();
    if (currToken) {
      this.updateToken(currToken, token);
    } else if (token) {
      this.resourceService.registerToken(token).subscribe(result => {
        this.setCurrentToken({id: result.id, token: token, userId: userId});
      });
    }
  }

  /**
   * request permission for notification from firebase cloud messaging
   *
   * @param userId userId
   */
  requestPermission(userId: number): Observable<string | null> {
    const currToken = this.getCurrentToken();
    if (currToken && currToken.userId !== userId) {
      return this.revokePermission().pipe(concatMap(result => {
        return this.angularFireMessaging.requestToken;
      })).pipe(tap(
        (token: string | null) => {
          this.logger.debug(token);
          this.registerToken(userId, token);
        },
        (err) => {
          this.logger.error('Unable to get permission to notify.', err);
        }));
    }
    return this.angularFireMessaging.requestToken.pipe(tap(
      (token: string | null) => {
        this.logger.debug(token);
        this.registerToken(userId, token);
      },
      (err) => {
        this.logger.error('Unable to get permission to notify.', err);
      })
    );
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload: any) => {
        this.logger.debug('new message received. ', payload);
        this.currentMessage.next(payload);
      });
  }

  revokePermission(): Observable<boolean> {
    const currToken = this.getCurrentToken();
    if (currToken) {
      const id = currToken.id;
      const token = currToken.token;
      return this.resourceService.deleteToken(id)
        .pipe(mergeMap(result => {
          this.logger.debug('delete token: ' + token);
          return this.angularFireMessaging.deleteToken(token);
        })).pipe(catchError(err => observableOf(false)))
        .pipe(tap(result => {
          this.setCurrentToken(null);
        }, error => {
          this.setCurrentToken(null);
        }));
    }
    return observableEmpty();
  }

  private getCurrentToken(): TokenPair | null {
    const tokenStr = localStorage.getItem(this.currentTokenKey);
    if (!tokenStr) {
      return null;
    }
    return JSON.parse(tokenStr);
  }

  private setCurrentToken(token: TokenPair | null) {
    if (!token) {
      this.logger.debug('delete token');
      localStorage.removeItem(this.currentTokenKey);
      return;
    }
    this.logger.debug('set token');
    localStorage.setItem(this.currentTokenKey, JSON.stringify(token));
  }
}

interface TokenPair {
  userId: number;
  token: string;
  id: number;
}

export type PushMessageEntity = 'FILE_DOCUMENT' | 'TASK_RECORD' | 'PROCESS' | 'PROCESS_TASK' | 'TRANSPORT' | 'PROSPEDITION';


export interface PushMessage {
  notification: {
    title: string;
    body: string;
  }
  data?: {
    entity_type: PushMessageEntity,
    message_id: number,
    entity_id: number,
    task_record_task_id?: number,
    task_record_state?: TaskRecordStateMachine.State,
    process_task_workflow_task_id?: number,
    process_task_state?: LegacyProcessTask.LegacyProcessTaskState,
  }
}
