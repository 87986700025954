/* eslint-disable */
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Transition, UIRouter } from '@uirouter/angular';
import { TranslateService } from '@ngx-translate/core';
import { MultiselectOptionItem, OptionItem, OwnerUserItem, OwnerUserItemFactory, UiConstants, } from '../../../util/core-utils';
import { UserData, UserDataLoader, UserDataLoaderPermissionDeniedStrategy } from '../../../lib/user-data-loader';
import { FieldError, FieldErrors, ObservableErrorResourceParser } from '../../../lib/util/errors';
import { StringKey } from '../../../app.string-keys';
import { StateName } from '../../../app.state-names';
import { Arrays } from '../../../lib/util/arrays';
import { InputMask } from '../../../util/input-masks';
import { StockFieldErrorMap, StockService } from '../../../lib/stock/stock.service';
import { SelectableCompanyModel, StockCreateModel, StockType } from '../../../util/stock/stock-utils';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { StockOwnerUserItem } from '../stock-edit/stock-edit.component';
import { Angular2Multiselects } from '../../../util/multiselect';
import { Strings } from '../../../lib/util/strings';
import { Set } from 'immutable';
import { OrderType } from '../../../lib/util/services';
import { Company, CompanyService } from '../../../lib/company/company.service';
import { RightResolver, RightService } from '../../../lib/right.service';
import { RightModel } from '../../../app.rights';
import { CustomerRecordMultiselectProvider } from '../../../lib/customer/customer-record-multiselect-provider.service';
import {
  CustomerRecordContactLocationMultiselectProvider
} from '../../../lib/customer/contact/customer-record-contact-location-multiselect.provider';

/* eslint-enable */

@Component({
  selector: 'app-stock-create',
  templateUrl: 'stock-create.component.html',
  styleUrls: ['stock-create.component.scss']
})
export class StockCreateComponent implements OnInit, AfterViewInit {

  InputMask = InputMask;
  UiConstants = UiConstants;

  model: StockCreateModel;
  ownerUsers: StockOwnerUserItem[];
  userDropdownSettings: Angular2Multiselects.Settings;
  customerDropdownSettings: Angular2Multiselects.Settings;
  fieldErrors: StockFieldErrorMap;
  customerType: string;
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  dropdownSettings?: Angular2Multiselects.Settings;
  selectableCompanies: SelectableCompanyModel[] = [];
  stockTypes: StockType[] = StockType.VALUES.toArray();
  rightModel: RightModel = RightModel.empty();
  customerRecords: MultiselectOptionItem<number>[] = [];
  contactLocations: MultiselectOptionItem<number>[] = [];

  constructor(
    private ownerUserItemFactory: OwnerUserItemFactory,
    private userDataLoader: UserDataLoader,
    private translateService: TranslateService,
    private stockService: StockService,
    private companyService: CompanyService,
    private customerRecordMultiselectProvider: CustomerRecordMultiselectProvider,
    private contactLocationMultiselectProvider: CustomerRecordContactLocationMultiselectProvider,
    private rightService: RightService,
    private uiRouter: UIRouter,
    private transition: Transition) {
    this.model = new StockCreateModel();
    this.model.type = StockType.DEFAULT.name;
    this.ownerUsers = [];
    this.fieldErrors = {};
    this.customerType = this.transition.params().type;
  }

  ngOnInit() {
    this.translateService.get('STOCK_PANEL_HEADING_ADD').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_STOCK').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.STOCK_LIST});
      }
    );
  }

  ngAfterViewInit() {
    this.loadOwnerUsers();
    this.loadRightModels(() => {
      if (this.rightModel.companyReadAll.hasRight()) {
        this.loadSelectableCompanies();
      }
      else {
        this.model.type = 'PERSONAL';
        this.stockTypes = this.stockTypes.filter(t => t.name === 'PERSONAL');
      }
    });
    this.initDropDown();
  }

  private loadRightModels(completion: () => void) {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        completion();
      }
    );
  }

  update() {
    const ownerUsers = this.model.owner_users;
    const ownerUserIds: number[] = [];
    ownerUsers.forEach(user => {
      if (user.id !== null) {
        ownerUserIds.push(user.id);
      }
    });
    this.stockService.create({
      name: this.model.name,
      external_id: Strings.undefinedOrNonEmpty(this.model.externalId),
      owner_user_ids: this.model.type === 'PERSONAL' ? ownerUserIds : [],
      company_id: this.model.type === 'COMPANY' ?
        (this.model.company.length === 1 && this.model.company[0].id)
          ? this.model.company[0].id! : undefined : undefined,
      customer_record_id: this.model.type === 'CUSTOMER' ?
        (this.model.customerRecord.length === 1 && this.model.customerRecord[0].id)
          ? this.model.customerRecord[0].id! : undefined : undefined,
      contact_location_id: this.model.type === 'CUSTOMER' ?
        (this.model.contactLocation.length === 1 && this.model.contactLocation[0].id)
          ? this.model.contactLocation[0].id! : undefined : undefined,
      central: this.model.type === 'COMPANY' && this.model.central,
      waste: this.model.type === 'COMPANY' && this.model.waste,
      type: this.model.type,
    }).subscribe(
      (response) => {
        this.uiRouter.stateService.go(StateName.STOCK_EDIT, response);
      },
      (error: any) => {
        const res = ObservableErrorResourceParser.parseError(error);
        this.fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
      }
    );
  }

  initDropDown() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .labelKey(OptionItem.KEY_TEXT)
      .build();
    this.userDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .text(StringKey.COMMON_PLACEHOLDER_USER_NOT_SELECTED_YET)
      .selectAllText(StringKey.COMMON_PLACEHOLDER_SELECT_ALL_USER)
      .unSelectAllText(StringKey.COMMON_PLACEHOLDER_SELECT_ALL_USER)
      .noDataLabel(StringKey.COMMON_PLACEHOLDER_EMPTY_USER_LIST)
      .build();
    this.customerDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();

  }

  onOwnerUserChange(fieldError?: FieldError) {
    this.removeFieldError(fieldError);
  }


  removeFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.fieldErrors, fieldError);
  }

  back() {
    window.history.back();
  }

  getTextMaximumLength(): number {
    return UiConstants.maximumVarcharLength;
  }

  private loadOwnerUsers() {

    this.ownerUsers = [];
    this.userDataLoader.loadAll(UserDataLoaderPermissionDeniedStrategy.MISS_ALL).subscribe(
      (users: UserData[]) => {
        const items = this.ownerUserItemFactory.createAll(users);

        const sortedOwnerUsers: OwnerUserItem[] = items;
        // sortedOwnerUsers.sort(OptionItems.createNameComparator());

        Arrays.iterateByIndex(sortedOwnerUsers, (sortedItem) => {
          const item = new StockOwnerUserItem();
          item.id = sortedItem.id;
          item.itemName = sortedItem.text;
          this.ownerUsers.push(item);
        });
      }
    );
  }

  private loadSelectableCompanies() {
    this.selectableCompanies = [];
    this.companyService.query({
      disabled: false,
      orders: Set.of({field: Company.OrderField.NAME, type: OrderType.ASC})
    }).subscribe(result => {
      Arrays.iterateByIndex(result.items.toArray(), customer => {
        const item = {
          id: customer.id,
          text: customer.name
        };
        this.selectableCompanies.push(item);
      });
    });
  }

  private createDefaultOwnerUser(): OwnerUserItem {
    const def = new OwnerUserItem();
    def.id = null;
    def.text = '';
    this.translateService.get(StringKey.COMMON_VALUE_UNSELECTED).subscribe(
      (text: string) => {
        def.text = text;
      }
    );
    return def;
  }

  searchCustomerRecord(q?: string) {
    this.customerRecordMultiselectProvider.loadActiveNonContact(q).subscribe(c => {
      this.customerRecords = c;
    });
  }

  onCustomerRecordChanged() {
    this.model.contactLocation = [];
    if (this.model.customerRecord.length === 1) {
      this.searchContactLocation();
    }
  }

  searchContactLocation(q?: string) {
    if (this.model.customerRecord.length === 1) {
      this.contactLocationMultiselectProvider.loadForCustomer(this.model.customerRecord[0].id, q).subscribe(c => {
        this.contactLocations = c;
      });
    }
  }


}
