/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { UiConstants } from '../util/core-utils';
import { MemStorage } from './util/mem-storage';
import { environment } from '../../environments/environment';
import { Dates, LocalDate } from './util/dates';
import { Order, OrderType, Services } from './util/services';
import { MessageList } from './message-parent/messages.service';
import { RuntimeConfiguration } from './runtime-configuration';
import { LoggedInUserStorage } from './util/storages';
/* eslint-enable */

@Injectable()
export class MessageSearchService {

  constructor() {
  }

  getSearchData(request: MessageSearch.SearchDataGetRequest): Observable<MessageSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<MessageSearch.SearchDataResult>) => {
      try {
        observer.next(storage.read(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  setSearchData(request: MessageSearch.SearchDataSetRequest): Observable<MessageSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<MessageSearch.SearchDataResult>) => {
      try {
        observer.next(storage.save(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  resetSearchData(request: MessageSearch.SearchDataGetRequest): Observable<MessageSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<MessageSearch.SearchDataResult>) => {
      try {
        observer.next(storage.reset(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  private createStorage(): MessageSearch.Storage {
    return new MessageSearch.Storage();
  }

}

export namespace MessageSearch {

  export interface SearchDataGetRequest {
  }

  export interface SearchDataSetRequest {
    searchData: SearchData;
  }

  export interface SearchDataResult {
    searchData: SearchData;
  }

  export interface SearchData {
    itemsPerPage: number;
    pageNumber: number;
    order: Order<MessageList.OrderField>;
    title: string;
    content: string;
    sender: string;
    sendDateFrom: LocalDate;
    sendDateTo: LocalDate;
    type: string | undefined;
  }

  interface StoredSearchRawDataBase {
    version: number;
  }

  interface StoredSearchRawData extends StoredSearchRawDataBase {
    itemsPerPage: number;
    pageNumber: number;
    order: Order<MessageList.OrderField>;
    title: string;
    content: string;
    sender: string;
    sendDateFrom?: string;
    sendDateTo?: string;
    type: string | undefined;
  }

  export class Storage {

    /**
     * Increment this version if you change the SearchData interface.
     */
    private static readonly VERSION: number = 4;

    private static readonly DEFAULT_SEARCH_DATA: SearchData = {
      itemsPerPage: UiConstants.pageNumbers[0],
      pageNumber: 1,
      order: {
        field: MessageList.OrderField.ID,
        type: OrderType.DESC
      },
      title: '',
      content: '',
      sender: '',
      sendDateFrom: Dates.emptyLocalDate(),
      sendDateTo: Dates.emptyLocalDate(),
      type: undefined
    };

    constructor() {
    }

    public read(request: SearchDataGetRequest): SearchDataResult {
      let rawBase: StoredSearchRawDataBase;
      try {
        const key = this.generateKey();
        const json = MemStorage.getItem(key);
        if (!json) {
          return this.createDefaultResult();
        }
        rawBase = JSON.parse(json);
      }
      catch (error) {
        return this.createDefaultResult();
      }
      if (Storage.VERSION === rawBase.version) {
        const raw: StoredSearchRawData = <StoredSearchRawData> rawBase;
        return this.fromRaw(raw);
      }
      return this.createDefaultResult();
    }

    public reset(request: SearchDataGetRequest): SearchDataResult {
      return this.save({
        searchData: Storage.DEFAULT_SEARCH_DATA,
      });
    }

    public save(request: SearchDataSetRequest): SearchDataResult {
      const key = this.generateKey();
      const raw = this.toRaw(request.searchData);
      const json = JSON.stringify(raw);
      MemStorage.setItem(key, json);
      return this.read(request);
    }

    private generateKey(): string {
      return 'UserId(' + LoggedInUserStorage.getInstance().getUserId() + ')-Search-Message';
    }

    private createDefaultResult(): SearchDataResult {
      return {
        searchData: Storage.DEFAULT_SEARCH_DATA,
      };
    }

    private fromRaw(data: StoredSearchRawData): SearchDataResult {
      return {
        searchData: {
          itemsPerPage: data.itemsPerPage,
          pageNumber: data.pageNumber,
          order: data.order,
          title: data.title,
          content: data.content,
          sender: data.sender,
          sendDateFrom: Services.toLocalDate(data.sendDateFrom),
          sendDateTo: Services.toLocalDate(data.sendDateTo),
          type: data.type
        }
      };
    }

    private toRaw(data: SearchData): StoredSearchRawData {
      return {
        version: Storage.VERSION,
        itemsPerPage: data.itemsPerPage,
        pageNumber: data.pageNumber,
        order: data.order,
        title: data.title,
        content: data.content,
        sender: data.sender,
        sendDateFrom: Services.localDateToString(data.sendDateFrom),
        sendDateTo: Services.localDateToString(data.sendDateTo),
        type: data.type
      };
    }

  }

}
