/* eslint-disable */
import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {Transition, UIRouter} from '@uirouter/angular';
import {LdapGroup, UserGroup, UserGroupFieldErrorMap, UserGroupService} from '../../../lib/user-group.service';
import {
  ApplicationTypeItem,
  CompanyItem,
  OptionItem,
  RoleItem,
  UiConstants,
  UserGroupEditModel,
  UserItem,
} from '../../../util/core-utils';
import {FieldError, FieldErrors, ObservableErrorResourceParser} from '../../../lib/util/errors';
import {StateName} from '../../../app.state-names';
import {ApplicationType, RootCoreService} from '../../../lib/root-core.service';
import {Arrays} from '../../../lib/util/arrays';
import {TranslateService} from '@ngx-translate/core';
import {StringKey} from '../../../app.string-keys';
import {Angular2Multiselects} from '../../../util/multiselect';
import {User, UserService,} from '../../../lib/user.service';
import {combineLatest, Observable} from 'rxjs';
import {List, Set} from 'immutable';
import {BreadcrumbParent} from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import {Role, RoleService} from '../../../lib/role/role.service';
import {Company, CompanyService} from '../../../lib/company/company.service';
import {
  LocalFieldValidationErrors,
  LocalFieldValidationErrorsFactory,
  OrderType,
  QueryResult,
  ResourceQueryResult,
  Services
} from '../../../lib/util/services';
import {RightModel} from '../../../app.rights';
import {UserProfile} from '../../../lib/auth.service';
import {RightResolver, RightService} from '../../../lib/right.service';
import {NgForm, NgModel} from '@angular/forms';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {ToasterService} from '../../../fork/angular2-toaster/angular2-toaster';
import {ConfigurationService} from '../../../lib/core-ext/configuration.service';
import {map} from 'rxjs/operators';
import {Strings} from '../../../lib/util/strings';
import {UserMeService} from '../../../lib/user/user-me.service';

/* eslint-enable */

@Component({
  selector: 'app-usergroup-edit',
  templateUrl: 'usergroup-edit.component.html',
  styleUrls: ['usergroup-edit.component.scss']
})
export class UserGroupEditComponent implements OnInit, AfterViewInit {
  UiConstants = UiConstants;

  userGroupId: number;
  protectedGroup: boolean = true;
  model: UserGroupEditModel = new UserGroupEditModel();
  fieldErrors: UserGroupFieldErrorMap = {};
  roles: RoleItem[] = [];
  selectableRoles: RoleItem[] = [];
  companies: CompanyItem[] = [];
  applicationTypes: ApplicationTypeItem[] = [];
  userItems: UserItem[] = [];
  selectableUsers: UserItem[] = [];
  userIds: number[] = [];

  rightModel: RightModel = RightModel.empty();
  userProfile: UserProfile;

  users: List<User> = List.of<User>();
  currentUsers: User[] = [];
  mappedLdapGroupsText: string = '';

  dropdownSettings?: Angular2Multiselects.Settings;
  dropdownSettingsForRole?: Angular2Multiselects.Settings;
  dropdownSettingsForCompany?: Angular2Multiselects.Settings;
  dropdownSettingsForUser?: Angular2Multiselects.Settings;
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  currentUserCompanyIds: number[] = [];

  // Form for ldap import validation
  @ViewChild('ldapForm')
  ldapForm: NgForm;

  @ViewChild('f', {static: true})
  fForm: NgForm;

  @ViewChild('ldapUserGroupField') ldapUserGroupField: NgModel;
  ldapUserGroups: OptionItem<number>[] = [];
  selectedLdapUserGroups: OptionItem<number>[] = [];

  @ViewChild('users')
  usersInput: NgModel;

  @ViewChild('rolesInput')
  rolesInput: NgModel;

  @ViewChild('external_id')
  externalIdInput: NgModel;

  @ViewChild('companyInput')
  companyInput: NgModel;

  // Field errors for server validation
  ldapUserGroupMapFieldErrors: LdapUserGroupMapFieldErrorMap;

  // validated ldap inputs
  private validatedInputs: LocalFieldValidationErrors<NgModel> =
    LocalFieldValidationErrorsFactory.empty();

  @ViewChild('ldapUserGroupMapDialog', {static: true}) ldapUserGroupMapDialog: ModalDirective;
  ldapUserGroupMapDialogVisible = true;
  private _currentUserProtected: boolean = false;

  get ldapEnabled(): boolean {
    return this.configurationService.getConfiguration().feature_flags.ldap_user_enabled;
  }

  canEditUserList() {
    return !this.model.ldapMapped && !this.protectedGroup || (this.protectedGroup && this._currentUserProtected);
  }

  constructor(
    private translateService: TranslateService,
    private rootCoreService: RootCoreService,
    private userService: UserService,
    private userMeService: UserMeService,
    private userGroupService: UserGroupService,
    private uiRouter: UIRouter,
    private transition: Transition,
    private toasterService: ToasterService,
    private roleService: RoleService,
    private companyService: CompanyService,
    private rightService: RightService,
    private configurationService: ConfigurationService) {
    this.userGroupId = this.transition.params().id;
    this.ldapUserGroupMapFieldErrors = {};
  }

  ngOnInit(): void {
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_USER_GROUPS').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.USER_GROUP_LIST});
      }
    );
    this.initDropdown();
  }

  ngAfterViewInit(): void {
    this.loadRightModels(() => {
      this.loadModel();
    });
    this.loadLocalFieldValidationErrors();
  }

  private loadLocalFieldValidationErrors() {
    const validatedInputs = List.of(this.ldapUserGroupField);
    this.validatedInputs = LocalFieldValidationErrorsFactory.ofFormFields(this.ldapForm, validatedInputs);
  }

  private initDropdown() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .labelKey(OptionItem.KEY_TEXT)
      .build();
    this.dropdownSettingsForRole = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .remoteSearch(true)
      .enableCheckAll(true)
      .labelKey(OptionItem.KEY_TEXT)
      .build();
    this.dropdownSettingsForCompany = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .remoteSearch(true)
      .enableCheckAll(false)
      .labelKey(OptionItem.KEY_TEXT)
      .build();
    this.dropdownSettingsForUser = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .remoteSearch(true)
      .enableCheckAll(true)
      .labelKey(OptionItem.KEY_TEXT)
      .build();
  }

  private loadModel() {
    this.userGroupService.get({
      id: this.userGroupId
    })
      .subscribe(
        (userGroup: UserGroup) => {
          this.breadcrumbSelf = userGroup.name;
          this.userIds = userGroup.user_ids;
          this.model.name = userGroup.name;
          this.model.externalId = userGroup.external_id;
          this.model.ldapMapped = userGroup.ldap_mapped!;
          this.protectedGroup = userGroup.protected_group;
          this.loadScopes(userGroup.application_types);
          this.model.users = [];
          if (userGroup.ldap_mapped !== true) {
            this.loadCurrentUsers(userGroup.user_ids);
          }
          this.loadRoles(undefined, userGroup.roles, () => {
            this.loadCurrentUserCompanyIds(() => {
              this.loadSelectedCompany(userGroup.company_ids);
              this.loadCompanies();
            });
          });
          if (userGroup.ldap_mapped && this.rightModel.userGroupLdapMapRead.hasRight()) {
            this.loadMappedLdapGroups();
          }
        },
        (error: any) => {
          // Ignored. The interceptor handles the global errors.
        }
      );
  }

  private loadRightModels(completion: () => void) {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        this.userProfile = resolver.userProfile!;
        this.userMeService.amIProtected().subscribe(p => {
          this._currentUserProtected = p;
          completion();
        });

      }
    );
  }

  loadRoles(q?: string, roles?: number[], completion?: () => void) {
    const notFoundRoleIds: number[] = [];
    this.roleService.query({
      name: Strings.undefinedOrNonEmpty(q),
      disabled: false,
      orders: Set.of({field: Role.OrderField.NAME, type: OrderType.ASC}),
      paging: {
        pageNumber: 1,
        numberOfItems: UiConstants.autocompletePageSize
      },
      noProgressBar: true
    }).subscribe(
      (result: QueryResult<Role.Role>) => {
        this.roles = [];
        if (q === undefined) {
          this.model.roles = [];
        }
        result.items.forEach((role: Role.Role) => {
          const item = new RoleItem();
          item.id = role.id;
          item.text = role.name;
          item.disabled = role.protectedRole && !this._currentUserProtected;
          if (!item.disabled) {
            this.roles.push(item);
          }
        });
        if (roles) {
          roles.forEach((id) => {
            const selectedRole = this.roles.find((role) => role.id === id);
            if (selectedRole) {
              this.model.roles.push(selectedRole);
            } else {
              notFoundRoleIds.push(+id);
            }
          });
        }
        if (notFoundRoleIds.length > 0) {
          this.roleService.query({
            id: Set.of(...notFoundRoleIds),
            orders: Set.of({field: Role.OrderField.NAME, type: OrderType.ASC})
          }).subscribe(
            (result: QueryResult<Role.Role>) => {
              result.items.forEach((role: Role.Role) => {
                const item = new RoleItem();
                item.id = role.id;
                item.text = role.name;
                item.disabled = role.disabled || (role.protectedRole && !this._currentUserProtected);
                if (item.disabled) {
                  this.model.roles.unshift(item);
                } else {
                  this.model.roles.push(item);
                  this.roles.unshift(item);
                }
              });
            });
        }
        // Equal on load, filtered later in onCompanySelected()
        this.selectableRoles = this.roles;
        if (completion) {
          completion()
        }
      }
    );
  }

  private loadCurrentUserCompanyIds(completion: () => void) {
    this.userService.get({
      id: this.userProfile.id
    }).subscribe((user: User) => {
      this.currentUserCompanyIds = user.companies.map(c => c.id);
      completion();
    });
  }

  loadCompanies(searchValue?: string) {
    if (this.rightModel.userGroupCreateAll.hasRight()) {
      this.queryCompanies(searchValue);
    } else {
      if (this.currentUserCompanyIds.length === 0) {
        this.companies = [];
      } else {
        this.queryCompanies(searchValue, this.currentUserCompanyIds);
      }
    }
  }

  private queryCompanies(searchValue?: string, companyIds?: number[], completion?: () => void) {
    this.companyService.query({
      id: companyIds ? Set.of(...companyIds) : undefined,
      name: Strings.undefinedOrNonEmpty(searchValue),
      orders: Set.of({field: Company.OrderField.NAME, type: OrderType.ASC}),
      disabled: false,
      paging: {
        pageNumber: 1,
        numberOfItems: UiConstants.autocompletePageSize
      },
      noProgressBar: true
    }).subscribe((result: QueryResult<Company.Company>) => {
      this.companies = [];
      result.items.forEach((company: Company.Company) => {
        const item = new CompanyItem();
        item.id = company.id;
        item.text = company.name;
        item.allowedRoleIds = company.allowedRoleIds!;
        item.disabled = company.disabled;
        this.companies.push(item);
      });
      if (completion) {
        completion();
      }
    });
  }

  private loadSelectedCompany(companyIds?: number[]) {
    if (!companyIds) {
      return;
    }
    this.companyService.query({
      id: Set.of(...companyIds)
    }).subscribe((result: QueryResult<Company.Company>) => {
      this.model.companies = [];
      result.items.forEach(company => {
        const item = new CompanyItem();
        item.id = company!.id;
        item.text = company!.name;
        item.allowedRoleIds = company!.allowedRoleIds!;
        item.disabled = company!.disabled;
        this.model.companies.push(item);
      });
      this.onCompanyChanged();
    });
  }

  private loadScopes(scopeKeys: string[]) {
    this.rootCoreService.getApplicationTypes({})
      .subscribe(
        (applicationTypes: ApplicationType[]) => {
          this.applicationTypes = [];
          this.model.application_types = [];
          Arrays.iterateByIndex(applicationTypes, (applicationType) => {
            const item = new ApplicationTypeItem();
            item.id = applicationType.key;
            item.text = applicationType.name;
            if (scopeKeys.indexOf(applicationType.key) !== -1) {
              this.model.application_types.push(item);
            }
            this.applicationTypes.push(item);
          });
        },
        (error: any) => {
          // Ignored. The interceptor handles the global errors.
        }
      );
  }

  private loadCurrentUsers(ids: number[]) {
    if (ids.length > 0) {
      this.userService.query({
        id: ids.join(','),
        order: Services.createOrderFieldParameter(User.Keys.toOrderFieldKey,
          Set.of({field: User.OrderField.PERSON_NAME, type: OrderType.ASC}))
      }).subscribe(
        (result: ResourceQueryResult<User>) => {
          this.model.users = [];
          let enableCheckAllUser = true; // By default, allow check all.
          Arrays.iterateByIndex(result.items, (user) => {
            const item = this.toUserItem(user);
            this.model.users.push(item);
            this.currentUsers.push(user);
            if (user.protected_user) {
              // There is at least one selected protected user, disable select all, cause it's buggy.
              enableCheckAllUser = false;
            }
          });
          this.dropdownSettingsForUser!.enableCheckAll = enableCheckAllUser;
        }
      );
    }
  }

  loadUsers(q?: string) {
    this.userService.query({
      q: q ? Strings.undefinedOrNonEmpty(q) : undefined,
      disabled: false,
      order: Services.createOrderFieldParameter(User.Keys.toOrderFieldKey,
        Set.of({field: User.OrderField.PERSON_NAME, type: OrderType.ASC})),
      page_number: 1,
      type: 'USER',
      number_of_items: UiConstants.autocompletePageSize,
      no_progress_bar: true
    }).subscribe(
      (result: ResourceQueryResult<User>) => {
        this.userItems = [];
        this.selectableUsers = [];
        Arrays.iterateByIndex(result.items, (user) => {
          const item = this.toUserItem(user);
          if (!user.protected_user && user.type !== 'LDAP') {
            // Filter the protected user from the dropdown.
            this.userItems.push(item);
          }
        });
        this.users = List.of(...result.items);
        this.selectableUsers = this.userItems;
      }
    );
  }

  onUserSelect(event: UserItem) {
    const selectedUser = this.findUserById(event.id!);
    if (selectedUser !== null && selectedUser.protected_user) {
      // Protected user is added to the group, undo!
      const index = this.model.users.indexOf(event);
      this.model.users.splice(index, 1);
    }
  }

  onUserDeSelect(event: UserItem) {
    const selectedUser = this.findUserById(event.id!);
    if (selectedUser !== null && selectedUser.protected_user) {
      // Protected user is removed from the group, undo!
      this.model.users.push(event);
    }
  }

  onSelectAllUser(event: UserItem[]) {
    const ua: User[] = [];
    this.users.forEach((u) => {
      if (u!.protected_user) {
        if (this.currentUsers.includes(u!)) {
          ua.push(u!);
        }
      } else {
        ua.push(u!);
      }
    });
    const items = ua.map((u) => {
      return this.toUserItem(u!);
    });
    this.model.users = items;
  }

  onDeSelectAllUser(event: UserItem[]) {
    const ua: User[] = [];
    this.users.forEach((u) => {
      if (u!.protected_user) {
        if (this.currentUsers.includes(u!)) {
          ua.push(u!);
        }
      }
    });
    const items = ua.map((u) => {
      return this.toUserItem(u!);
    });
    this.model.users = items;
  }

  onCompanyChanged() {
    this.filterRoles();
    this.filterUsers();
  }

  filterRoles() {
    if (this.model.companies.length > 0) {
      this.selectableRoles = [];
      const filteredRoles: RoleItem[] = [];
      Set.of(...this.model.companies.map(c => c.allowedRoleIds.toArray())
        .reduce((previousValue, currentValue) => previousValue.concat(currentValue), []))
        .forEach((roleId: number) => {
          this.roles.forEach((role: RoleItem) => {
            if (role.id === roleId) {
              this.selectableRoles.push(role);
            }
          });
          this.model.roles.forEach((selectedRole: RoleItem) => {
            if (roleId === selectedRole.id) {
              filteredRoles.push(selectedRole);
            }
          });
        });
      this.model.roles = filteredRoles;
    } else {
      this.selectableRoles = this.roles;
    }
  }

  filterUsers() {
    this.selectableUsers = this.userItems;
  }

  get companyIds(): number[] | undefined {
    if (this.model.companies.length > 0) {
      return this.model.companies.map(c => c.id!);
    }
    return undefined;
  }

  update() {
    let usersValid = true;
    if (!this.model.ldapMapped && this.usersInput && this.model.users.length > 0) {
      this.usersInput.control.updateValueAndValidity();
      usersValid = this.usersInput.control.valid;
    }
    this.rolesInput.control.updateValueAndValidity();
    this.companyInput.control.updateValueAndValidity();
    this.externalIdInput.control.updateValueAndValidity();
    if (!usersValid || this.rolesInput.control.invalid || this.companyInput.invalid
      || this.externalIdInput.control.invalid) {
      this.showValidationErrorToast();
      return;
    }
    this.userGroupService.update({
      id: this.userGroupId,
      name: this.model.name,
      external_id: this.model.externalId,
      roles: this.model.createRoleKeys(),
      application_types: this.model.createApplicationTypeKeys(),
      company_ids: this.companyIds
    }).subscribe(result => {
      if (!this.model.ldapMapped) {
        this.userGroupService.updateUsers({
          id: this.userGroupId,
          user_ids: this.model.createUserIds()
        }).subscribe(r => {
          this.uiRouter.stateService.go(StateName.USER_GROUP_LIST);
        }, error => this.handleError(error));
      } else {
        this.uiRouter.stateService.go(StateName.USER_GROUP_LIST);
      }
    }, error => this.handleError(error));
  }

  private handleError(error: any) {
    const res = ObservableErrorResourceParser.parseError(error);
    this.fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
    this.showValidationErrorToast();

  }

  private showValidationErrorToast() {
    this.toasterService.pop({
      timeout: UiConstants.ToastTimeoutLong,
      type: UiConstants.toastTypeError,
      title: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_TITLE),
      body: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_MESSAGE)
    });
  }

  back() {
    window.history.back();
  }

  removeFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.fieldErrors, fieldError);
  }

  getTextMaximumLength(): number {
    return UiConstants.maximumVarcharLength;
  }

  get selectedUsersText(): string {
    return this.selectedOptionsText(this.model.users);
  }

  get selectedApplicationTypesText(): string {
    return this.selectedOptionsText(this.model.application_types);
  }

  get selectedRolesText(): string {
    return this.selectedOptionsText(this.model.roles);
  }

  private selectedOptionsText<T>(items: OptionItem<T>[]): string {
    return items
      .map((o) => {
        return o.text;
      })
      .join(', ');
  }

  private findUserById(id: number): User | null {
    let user: User | null = null;
    this.users.forEach((u: User) => {
      if (u.id === id) {
        user = u;
        return false;
      }
    });
    return user;
  }

  private toUserItem(user: User) {
    const item = new UserItem();
    item.id = user.id;
    item.text = user.person_name + ' (' + user.user_name + ')';
    item.disabled = user.disabled;
    return item;
  }

  syncLdapUserGroups(completion: () => void) {
    this.userGroupService.syncLdapGroups({}).subscribe(() => {
      completion();
    })
  }

  private loadMappedLdapGroups() {
    this.userGroupService.getMappedLdapGroups({
      id: this.userGroupId
    }).subscribe((groups: LdapGroup[]) => {
      this.mappedLdapGroupsText = groups.map(group => group.natural_id).join(', ');
    });
  }

  loadLdapUserGroups() {
    this.createCombinedObservable().subscribe(
      (result: CombinedResult) => {
        this.ldapUserGroups = [];
        this.selectedLdapUserGroups = [];
        result.groups.forEach((group) => {
          const item = new OptionItem<number>();
          item.id = group.id;
          item.text = group.natural_id;
          this.ldapUserGroups.push(item);
        });
        result.mappedGroups.forEach((group) => {
          const item = new OptionItem<number>();
          item.id = group.id;
          item.text = group.natural_id;
          this.selectedLdapUserGroups.push(item);
        });
      });
  }

  mapLdapUserGroups() {
    const ldapGroupIds: number[] = [];
    this.selectedLdapUserGroups.forEach((group) => {
      if (group.id) {
        ldapGroupIds.push(group.id);
      }
    });
    this.userGroupService.mapLdapGroups(this.userGroupId, Set.of(...ldapGroupIds)).subscribe(() => {
        this.ldapUserGroupMapDialog.hide();
      },
      (error: any) => {
        const res = ObservableErrorResourceParser.parseError(error);
        this.ldapUserGroupMapFieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
      })
  }

  private createCombinedObservable(): Observable<CombinedResult> {
    return combineLatest(
      this.userGroupService.getLdapGroups({}),
      this.userGroupService.getMappedLdapGroups({
        id: this.userGroupId
      })).pipe(map(
      (
        [groups, mappedGroups]) => {
        return {
          groups: groups,
          mappedGroups: mappedGroups
        }
      })
    );
  }

  showLdapUserGroupMapDialog() {
    this.syncLdapUserGroups(() => {
      this.loadLdapUserGroups();
      this.ldapUserGroupMapDialogVisible = true;
      this.ldapUserGroupMapDialog.show();
    });
  }

  closeLdapUserGroupMapDialog() {
    this.ldapUserGroupMapDialogVisible = false;
    this.ldapUserGroupMapDialog.hide();
  }

  onModalHidden() {
    this.ldapForm.resetForm();
  }

  hasLocalFieldError(field?: NgModel): boolean {
    return this.validatedInputs.hasLocalError(field);
  }

  removeLdapUserGroupMapFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.ldapUserGroupMapFieldErrors, fieldError);
  }

}

export interface LdapUserGroupMapFieldErrorMap {
  ldap_group_ids?: FieldError;
}

interface CombinedResult {
  groups: LdapGroup[];
  mappedGroups: LdapGroup[];
}
