<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
    <app-breadcrumb-menu>
      <!-- region EDIT-->
      <app-breadcrumb-button
        *ngIf="rightModel.mileageRecordUpdate.hasRight()"
        [iconClass]="'icomoon-modify'"
        [titleStringKey]="'COMMON_BUTTON_EDIT'"
        (click)="mileageRecordUpdateDialog.showDialog(model)">
      </app-breadcrumb-button>
      <!-- endregion EDIT-->
    </app-breadcrumb-menu>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-vertical-padding-half animated fadeIn">
  <div class="container-vertical-padding-half">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          {{'MILEAGE_RECORD' | translate}}
        </div>

        <div class="card-body row">

          <div class="col-md-5">
            <div class="form-horizontal">

              <div class="inner-card">

                <div class="row" *ngIf="model.taskId">
                  <div class="form-group col-md-4">
                    <label readonly class="detail-title">{{'COMMON_TASK'|translate}}</label>
                  </div>
                  <div class="form-group col-md-8">
                    <label readonly class="detail-description">
                      <a uiSref="Admin.TaskRecordDetail"
                         [uiParams]="{taskRecordId: model.taskRecordId, taskId: model.taskId}">
                        {{model.taskName}}
                      </a>
                    </label>
                  </div>
                </div>

                <div class="row" *ngIf="model.taskId">
                  <div class="form-group col-md-4">
                    <label readonly class="detail-title">{{'CUSTOMER_RECORD_NAME'|translate}}</label>
                  </div>
                  <div class="form-group col-md-8">
                    <label readonly class="detail-description">{{model.customerName }}</label>
                  </div>
                </div>

                <div class="row" *ngIf="model.user">
                  <div class="form-group col-md-4">
                    <label readonly class="detail-title">{{'TASK_LABEL_OWNER_USER'|translate}}</label>
                  </div>
                  <div class="form-group col-md-8">
                    <label readonly class="detail-description">{{model.user.personName + ' (' + model.user.userName + ')'}}</label>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-md-4">
                    <label readonly class="detail-title">{{'TRANSPORT_VEHICLE'|translate}}</label>
                  </div>
                  <div class="form-group col-md-8">
                    <label readonly class="detail-description">{{model.vehicleLicencePlate }}</label>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-md-4">
                    <label readonly class="detail-title">{{'MILEAGE_RECORD_START_KILOMETER'|translate}}</label>
                  </div>
                  <div class="form-group col-md-8">
                    <label readonly class="detail-description">{{model.startKilometerText }}</label>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-md-4">
                    <label readonly class="detail-title">{{'MILEAGE_RECORD_FINISH_KILOMETER'|translate}}</label>
                  </div>
                  <div class="form-group col-md-8">
                    <label readonly class="detail-description">{{model.finishKilometerText }}</label>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-md-4">
                    <label readonly class="detail-title">{{'MILEAGE_RECORD_KILOMETER_COUNT'|translate}}</label>
                  </div>
                  <div class="form-group col-md-8">
                    <label readonly class="detail-description">{{model.kilometerCountText }}</label>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-md-4">
                    <label readonly class="detail-title">{{'COMMON_NOTE'|translate}}</label>
                  </div>
                  <div class="form-group col-md-8">
                    <label readonly class="detail-description">{{model.note }}</label>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-md-4">
                    <label readonly class="detail-title">{{'MILEAGE_RECORD_JOURNEY_TYPE'|translate}}</label>
                  </div>
                  <div class="form-group col-md-8">
                    <label readonly class="detail-description">{{ model.journeyType }}</label>
                  </div>
                </div>

                <label class="inner-card-title">{{'MILEAGE_RECORD_TIME_LABEL' | translate}}</label>

                <div class="row">
                  <div class="form-group col-md-4">
                    <label readonly class="detail-title">{{'MILEAGE_RECORD_CREATION_TIME'|translate}}</label>
                  </div>
                  <div class="form-group col-md-8">
                    <label readonly class="detail-description">{{model.creationTime.toUtcIsoString() | date:'short' }}</label>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-md-4">
                    <label readonly class="detail-title">{{'MILEAGE_RECORD_FINISH_TIME'|translate}}</label>
                  </div>
                  <div class="form-group col-md-8">
                    <label readonly class="detail-description">{{model.finishTime.toUtcIsoString() | date:'short' }}</label>
                  </div>
                </div>

              </div>

            </div>
          </div>

          <div class="col-md-7 col-sm-12 col-xs-12">
            <div class="maps-container">
              <div #gmap style="flex: 1 1 auto;min-height: 300px"></div>
            </div>
          </div>

        </div>
      </div>

      <div class="container-vertical-padding-half"></div>

    </div>
  </div>
</div>

<app-mileage-record-edit-dialog
  #mileageRecordUpdateDialog
  (onMileageRecordUpdated)="loadModel()">
</app-mileage-record-edit-dialog>
