/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IconResource } from '../task/icon.service';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { ResourceQueryResult } from '../util/services';
/* eslint-enable */

@Injectable()
export class LegacyWorkflowResourceService extends BaseHttpService {

  // <editor-fold desc="CRUD">

  query(request: LegacyWorkflowResource.QueryRequest): Observable<ResourceQueryResult<LegacyWorkflowResource.Workflow>> {
    return this.http.get<ResourceQueryResult<LegacyWorkflowResource.Workflow>>(this.url, this.parseParams(request));
  }

  get(request: LegacyWorkflowResource.GetRequest): Observable<LegacyWorkflowResource.Workflow> {
    return this.http.get<LegacyWorkflowResource.Workflow>(this.url + `${request.workflow_id}`);
  }

  create(request: LegacyWorkflowResource.CreateRequest): Observable<LegacyWorkflowResource.WorkflowIdentity> {
    return this.http.post<LegacyWorkflowResource.WorkflowIdentity>(this.url, request);
  }

  update(request: LegacyWorkflowResource.UpdateRequest): Observable<LegacyWorkflowResource.Workflow> {
    return this.http.put<LegacyWorkflowResource.Workflow>(this.url + `${request.workflow_id}`, request);
  }

  setDisabled(request: LegacyWorkflowResource.DisableRequest): Observable<LegacyWorkflowResource.Workflow> {
    return this.http.patch<LegacyWorkflowResource.Workflow>(this.url + `${request.workflow_id}/disabled`, request);
  }

  // </editor-fold>

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/legacy-workflows/');
  }

}

export namespace LegacyWorkflowResource {

  // <editor-fold desc="CRUD">

  export interface Workflow {
    workflow_id: number;
    disabled: boolean;
    creation_time: string;
    update_time: string;
    name: string;
    description: string;
    external_id: string,
    version: number;
    icon?: IconResource.Icon;
  }

  export interface QueryRequest {
    workflow_id?: string;
    disabled?: boolean;
    name?: string;
    external_id?: string,
    description?: string,
    creation_time_from?: string,
    creation_time_to?: string,
    update_time_from?: string,
    update_time_to?: string,
    q?: string;
    fields?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
  }

  export interface GetRequest {
    workflow_id: number;
  }

  export interface WorkflowIdentity {
    workflow_id: number;
  }

  export interface CreateRequest {
    name: string;
    description?: string;
    external_id?: string,
    icon?: IconResource.Icon;
    usage_type: 'ORDER';
  }

  export interface UpdateRequest {
    workflow_id: number;
    name: string;
    version: number;
    description?: string;
    external_id?: string,
    icon?: IconResource.Icon;
  }

  export interface DisableRequest {
    workflow_id: number;
    disabled: boolean;
  }

  // </editor-fold>

}
