<div class="app flex-row align-items-center background-white" id="register-container"
     [ngClass]="{'background-christmas': isSantaOn}">
  <div class="w-100">

    <!-- region INTRODUCTION COMPONENT-->
    <div class="w-100 background hidden-md-down">
      <app-login-register-introduction
        [titleStringKey]="registrationType === RegistrationType.CREATE_USER_SHOPRENTER ? 'LOGIN_SHOPRENTER_TITLE' : 'COMMON_REGISTER_TITLE'"
        [subtitleStringKey]="registrationType === RegistrationType.CREATE_USER_SHOPRENTER ? 'LOGIN_SHOPRENTER_SUBTITLE' : 'COMMON_REGISTER_SUBTITLE'"
        [loginBackgroundSrc]="loginBackgroundSrc"
        [registerUseCase]="true">
      </app-login-register-introduction>
    </div>
    <!-- endregion INTRODUCTION COMPONENT-->

    <!-- region REGISTER -->
    <div class="register-container register-out" #registerContainer>

      <div class="card register-card">

        <!-- region LANGUAGE SELECTOR -->
        <div class="d-flex justify-content-between mb-2">
          <h2>{{'REGISTER_COMPONENT_TITLE' | translate}}</h2>
          <ng-container *ngFor="let language of languages">
            <div class="ml-1 cursor-pointer"
                 *ngIf="!isCurrentLanguage(language)"
                 (click)="changeLanguage(language)"
                 title="{{language.nativeName}}">
              <img
                src="../../assets/img/language-code-icon/{{language.localeCode.substr(0,2)}}.png"
                class="language-image">
            </div>
          </ng-container>
        </div>
        <!-- endregion LANGUAGE SELECTOR -->

        <!-- region REGISTER FORM -->
        <div class="form-panel d-flex justify-content-center">
          <form name="form" (ngSubmit)="register()" #f="ngForm" novalidate id="register-validation">
            <h5>{{'REGISTRATION_STEP_USER_DATA' | translate}}</h5>
            <div
              class="form-group row mb-1"
              [ngClass]="{ 'has-danger': f.submitted && !personName.valid }">
              <div class="col-12">
                <label
                  class="mw-100 col-form-label form-control-label required-field-label">
                  {{'REGISTER_COMPONENT_PERSON_NAME'|translate}}
                </label>
              </div>
              <div class="col-12 input-group">
                <input
                  class="form-control"
                  type="text"
                  placeholder="{{'REGISTER_COMPONENT_PERSON_NAME' | translate}}"
                  [ngClass]="{ 'form-control-danger': f.submitted && !personName.valid }"
                  [(ngModel)]="model.personName"
                  id="personName"
                  name="personName"
                  #personName="ngModel"
                  required>
              </div>
              <div *ngIf="f.submitted && !personName.valid"
                   class="col-12 form-control-feedback">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
            <div *ngIf="registrationType !== RegistrationType.REQUEST_USER">
              <div class="form-group row mb-1"
                   [ngClass]="{ 'has-danger': f.submitted && !userName.valid }">
                <div class="col-12">
                  <label
                    class="mw-100 col-form-label form-control-label required-field-label">
                    {{'USER_LABEL_USER_NAME'|translate}}
                  </label>
                </div>
                <div class="col-12 input-group">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="{{'USER_LABEL_USER_NAME' | translate}}"
                    [ngClass]="{ 'form-control-danger': f.submitted && !userName.valid }"
                    [(ngModel)]="model.userName"
                    id="userName"
                    name="userName"
                    #userName="ngModel"
                    (ngModelChange)="removeFieldError(fieldErrors.user_name)"
                    maxlength="{{getTextMaximumLength()}}"
                    required>
                </div>
                <div *ngIf="f.submitted && !userName.valid"
                     class="col-12 form-control-feedback">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
                <div class="col-12 form-control-feedback"
                     *ngIf="fieldErrors.user_name">{{fieldErrors.user_name.text}}</div>
              </div>
              <div class="form-group row mb-1"
                   [ngClass]="{ 'has-danger': f.submitted && !password.valid }">
                <div class="col-12">
                  <label
                    class="mw-100 col-form-label form-control-label required-field-label">
                    {{'USER_LABEL_PASSWORD'|translate}}
                  </label>
                </div>
                <div class="col-12 input-group">
                  <input
                    class="form-control"
                    type="{{passwordVisible ? 'text' : 'password'}}"
                    placeholder="{{'USER_LABEL_PASSWORD' | translate}}"
                    [ngClass]="{ 'form-control-danger': f.submitted && !password.valid }"
                    [(ngModel)]="model.password"
                    id="password"
                    name="password"
                    #password="ngModel"
                    (ngModelChange)="removeFieldError(fieldErrors.password)"
                    maxlength="{{getTextMaximumLength()}}"
                    required>
                  <span (click)="togglePasswordVisibility()"
                        class="input-group-addon-gray icomoon icomoon-enable cursor-pointer font-2xl"></span>
                </div>
                <div *ngIf="f.submitted && !password.valid"
                     class="col-12 form-control-feedback">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
                <div class="form-control-feedback col-12"
                     *ngIf="fieldErrors.password">{{fieldErrors.password.text}}</div>
              </div>
              <div class="form-group row mb-1"
                   [ngClass]="{ 'has-danger': f.submitted && !confirmPassword.valid }">
                <div class="col-12">
                  <label
                    class="mw-100 col-form-label form-control-label required-field-label">
                    {{'LOGIN_COMPONENT_CONFIRM_PASSWORD'|translate}}
                  </label>
                </div>
                <div class="col-12 input-group">
                  <input
                    class="form-control"
                    type="{{passwordVisible ? 'text' : 'password'}}"
                    placeholder="{{'LOGIN_COMPONENT_CONFIRM_PASSWORD' | translate}}"
                    [ngClass]="{ 'form-control-danger': f.submitted && !confirmPassword.valid }"
                    [(ngModel)]="model.confirmPassword"
                    id="confirmPassword"
                    name="confirmPassword"
                    #confirmPassword="ngModel"
                    maxlength="{{getTextMaximumLength()}}"
                    required
                    validateMatchingPassword>
                  <span (click)="togglePasswordVisibility()"
                        class="input-group-addon-gray icomoon icomoon-enable cursor-pointer font-2xl"></span>
                </div>
                <div *ngIf="f.submitted && confirmPassword.errors && confirmPassword.errors['required']"
                     class="col-12 form-control-feedback">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
                <div *ngIf="f.submitted && confirmPassword.errors && confirmPassword.errors['no_match']"
                     class="col-12 form-control-feedback">
                  {{'COMMON_VALIDATION_MESSAGE_PASSWORD_MATCH' | translate}}
                </div>
              </div>
            </div>
            <div
              class="form-group row mb-1"
              [ngClass]="{ 'has-danger': f.submitted && !emailAddress.valid }">
              <div class="col-12">
                <label
                  class="mw-100 col-form-label form-control-label required-field-label">
                  {{'REGISTER_COMPONENT_EMAIL_ADDRESS'|translate}}
                </label>
              </div>
              <div class="col-12 input-group">
                <input
                  class="form-control"
                  type="text"
                  placeholder="{{'REGISTER_COMPONENT_EMAIL_ADDRESS' | translate}}"
                  [ngClass]="{ 'form-control-danger': f.submitted && !emailAddress.valid }"
                  [(ngModel)]="model.emailAddress"
                  id="emailAddress"
                  name="emailAddress"
                  #emailAddress="ngModel"
                  required
                  validateOptionalEmail>
              </div>
              <div *ngIf="f.submitted && !emailAddress.valid"
                   class="col-12 form-control-feedback">
                <span *ngIf="emailAddress.errors['required']">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </span>
                <span *ngIf="emailAddress.errors['email']">
                  {{'COMMON_VALIDATION_MESSAGE_EMAIL' | translate}}
                </span>
              </div>
            </div>
            <div
              class="form-group row mb-1"
              [ngClass]="{ 'has-danger': f.submitted && !phoneNumber.valid }">
              <div class="col-12">
                <label
                  class="mw-100 col-form-label form-control-label required-field-label">
                  {{'REGISTER_COMPONENT_PHONE_NUMBER'|translate}}
                </label>
              </div>
              <div class="col-12 input-group">
                <input
                  class="form-control"
                  type="text"
                  placeholder="{{'REGISTER_COMPONENT_PHONE_NUMBER' | translate}}"
                  [ngClass]="{ 'form-control-danger': f.submitted && !phoneNumber.valid }"
                  [(ngModel)]="model.phoneNumber"
                  id="phoneNumber"
                  name="phoneNumber"
                  #phoneNumber="ngModel"
                  required
                  validatePhoneNumber>
              </div>
              <div *ngIf="f.submitted && !phoneNumber.valid"
                   class="col-12 form-control-feedback">
                <span *ngIf="phoneNumber.errors['required']">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </span>
                <span *ngIf="phoneNumber.errors['validatePhoneNumber']">
                  {{'COMMON_VALIDATION_MESSAGE_PHONE' | translate}}
                </span>
              </div>
            </div>
            <h5>{{'REGISTRATION_STEP_COMPANY_DATA' | translate}}</h5>
            <div
              class="form-group row mb-1"
              [ngClass]="{ 'has-danger': f.submitted && !companyName.valid }">
              <div class="col-12">
                <label
                  class="mw-100 col-form-label form-control-label required-field-label">
                  {{'REGISTER_COMPONENT_COMPANY_NAME'|translate}}
                </label>
              </div>
              <div class="col-12 input-group">
                <input
                  class="form-control"
                  type="text"
                  placeholder="{{'REGISTER_COMPONENT_COMPANY_NAME' | translate}}"
                  [ngClass]="{ 'form-control-danger': f.submitted && !companyName.valid }"
                  [(ngModel)]="model.companyName"
                  id="companyName"
                  name="companyName"
                  #companyName="ngModel"
                  required>
              </div>
              <div *ngIf="f.submitted && !companyName.valid"
                   class="col-12 form-control-feedback">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
            <div
              class="form-group row mb-1">
              <div class="col-12" [ngClass]="{ 'has-danger': f.submitted && !model.companyAddress.valid }">
                <label
                  class="mw-100 col-form-label form-control-label font-weight-bold">{{'REGISTER_COMPONENT_COMPANY_ADDRESS'|translate}}</label>
              </div>
              <div class="col-12 ">
                <app-postal-address-complex
                  [form]="f"
                  [model]="model.companyAddress"
                  [recaptchaEnabled]="true"
                  [fieldRatio]="12"
                  [useMdTextAlign]="false"
                  [otherFieldColumns]="3">
                </app-postal-address-complex>
              </div>
            </div>
            <div class="form-group mb-0">
              <div class="row m-0">
                <button
                  type="submit"
                  class="col-12 btn btn-primary btn-ladda mb-1"
                  data-style="zoom-in"
                  [ladda]="registrationInProgress"
                  [disabled]="registrationInProgress">
                  {{'REGISTER_COMPONENT_TITLE' | translate}}
                </button>
                <small class="col-12 text-center"
                       [innerHTML]="'REGISTER_RECAPTCHA_DISCLAIMER' | translate"></small>
                <div class="col-12 text-center">
                  {{'REGISTER_ALREADY_HAVE_AN_ACCOUNT' | translate}}
                  <button type="button" class="btn btn-link px-0 cursor-pointer"
                          (click)="navigateToLogin()">{{'LOGIN_COMPONENT_TITLE' | translate}}</button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <!-- endregion REGISTER FORM-->

      </div>

    </div>
  </div>
</div>

<toaster-container [toasterconfig]="toasterConfig"></toaster-container>
