/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { Order, PagingRequest, QueryResult, ResourceQueryResult, Services } from '../util/services';
import { List, Map, Set } from 'immutable';
import { OffsetDateTime } from '../util/dates';
import { EmptyMessage, IdentityMessage } from '../util/messages';
import { MessageResource, MessageResourceService } from './message-resource.service';
import { UserProfileType } from '../auth.service';
import { PushMessageEntity } from '../firebase/firebase-message.service';
import { TaskRecordStateMachine } from '../task/task-record-statemachine';
import { LegacyProcessTask } from '../legacy-process/legacy-process-task.service';
import MessageType = Message.MessageType;
import {Process} from "../process/process.service";

/* eslint-enable */

@Injectable()
export class MessageService implements Message.Service {

  constructor(private resourceService: MessageResourceService) {
  }

  query(request: Message.QueryRequest): Observable<QueryResult<Message.Message>> {
    return Observable.create((observer: Observer<QueryResult<Message.Message>>) => {
      const resourceRequest: MessageResource.QueryRequest = {
        title: request.title,
        content: request.content,
        sent_date_from: Services.offsetDateTimeToString(request.sentDateFrom),
        sent_date_to: Services.offsetDateTimeToString(request.sentDateTo),
        include_read_messages: request.includeReadMessages,
        include_system_messages: request.includeSystemMessages,
        disabled: request.disabled,
        no_progress_bar: request.noProgressBar,
        q: request.queryText,
        order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
      };
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<MessageResource.Message>) => {
          observer.next({
            items: this.toPublicList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  setRead(request: Message.SetReadRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: MessageResource.SetReadRequest = {
        id: request.id,
        read: request.read,
        no_progress_bar: request.noProgressBar
      };
      return this.resourceService.setRead(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  private toPublicList(resourceList: MessageResource.Message[]): List<Message.Message> {
    return List.of(...resourceList.map((r) => this.toPublic(r)));
  }

  private toPublic(r: MessageResource.Message): Message.Message {
    return {
      id: r.id,
      title: r.title,
      content: r.content,
      type: <MessageType>r.type,
      creationTime: Services.toOffsetDateTime(r.creation_time),
      read: r.read,
      disabled: r.disabled,
      senderUser: this.toPublicSenderUser(r.sender_user),
      data: Message.toPublicMessageData(r.data)
    };
  }

  private toPublicSenderUser(r?: MessageResource.SenderUser): Message.SenderUser | undefined {
    if (r) {
      return {
        id: r.id,
        type: <UserProfileType>r.type
      };
    }
    return undefined;
  }

}

export namespace Message {

  export interface Service {

    // <editor-fold desc="CRUD">

    query(request: QueryRequest): Observable<QueryResult<Message.Message>>;

    setRead(request: SetReadRequest): Observable<EmptyMessage>;

    // </editor-fold>

  }

  export interface Message {
    id: number;
    title: string;
    content: string;
    type: MessageType;
    creationTime: OffsetDateTime;
    read: boolean;
    disabled: boolean;
    senderUser?: SenderUser;
    data?: MessageData;
  }

  export interface MessageData {
    messageId?: number,
    entityId: number;
    entityType: PushMessageEntity;
    taskRecordTaskId?: number;
    taskRecordState?: TaskRecordStateMachine.State;
    processTaskWorkflowTaskId?: number;
    processTaskState?: LegacyProcessTask.LegacyProcessTaskState;
    processWorkflowId?: number;
    processState?: Process.ProcessState;
  }

  export interface QueryRequest {
    title?: string;
    content?: string;
    sentDateFrom?: OffsetDateTime;
    sentDateTo?: OffsetDateTime;
    includeReadMessages?: boolean;
    includeSystemMessages?: boolean;
    queryText?: string;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
    disabled?: boolean;
    noProgressBar?: boolean;
  }

  export interface SetReadRequest extends IdentityMessage {
    read: boolean;
    noProgressBar?: boolean;
  }

  export interface SenderUser {
    id: number;
    type: UserProfileType;
  }

  export type MessageType = 'SYSTEM' | 'USER';

  export enum OrderField {
    ID,
    PARENT_TITLE,
    PARENT_CREATION_TIME
  }

  export function toPublicMessageData(r?: MessageResource.MessageData): Message.MessageData | undefined {
    if (r) {
      return {
        messageId: r.message_id,
        entityId: r.entity_id,
        entityType: <PushMessageEntity>r.entity_type,
        taskRecordTaskId: r.task_record_task_id,
        taskRecordState: <TaskRecordStateMachine.State>r.task_record_state,
        processTaskWorkflowTaskId: r.process_task_workflow_task_id,
        processTaskState: <LegacyProcessTask.LegacyProcessTaskState>r.process_task_state,
        processWorkflowId: r.process_workflow_id,
        processState: <Process.ProcessState>r.process_state
      };
    }
    return undefined;
  }

}

class Keys {

  private static readonly ID = 'id';
  private static readonly PARENT_TITLE = 'parent_title';
  private static readonly PARENT_CREATION_TIME = 'parent_creation_time';

  private static readonly orderFieldKeyMap: Map<Message.OrderField, string> = Map.of(
    Message.OrderField.ID, Keys.ID,
    Message.OrderField.PARENT_TITLE, Keys.PARENT_TITLE,
    Message.OrderField.PARENT_CREATION_TIME, Keys.PARENT_CREATION_TIME,
  );

  public static toOrderFieldKey(field: Message.OrderField): string {
    return Keys.orderFieldKeyMap.get(field)!;
  }

}

