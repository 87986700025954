<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [self]="breadcrumbSelf">
    </app-breadcrumb>
    <div class="breadcrumb-menu d-flex">
      <div class="breadcrumb-button-icon-container cursor-pointer"
           (click)="exportXls()">
        <a class="btn-setting d-flex align-items-center" title="{{'COMMON_EXPORT_XLSX' | translate}}">
          <i class="icomoon icomoon-csv-import"></i>
          <span class="breadcrumb-button-text-visible">{{'COMMON_EXPORT_XLSX' | translate}}</span>
        </a>
      </div>
      <div *ngIf="rightModel.orderImportMplReturn.hasRight()"
           class="breadcrumb-button-icon-container cursor-pointer"
           (click)="orderReturnImportDialog.toggleDialog()">
        <a class="btn-setting d-flex align-items-center" title="{{'ORDER_LIST_ORDER_RETURN_IMPORT' | translate}}">
          <i class="icomoon icomoon-csv-import"></i>
          <span class="breadcrumb-button-text-visible">{{'ORDER_LIST_ORDER_RETURN_IMPORT' | translate}}</span>
        </a>
      </div>
      <!-- DROPDOWN IMPORT START -->
      <div class="breadcrumb-button-icon-container-no-text cursor-pointer p-0"
           *ngIf="rightModel.orderImportMplFinalStatus.hasRight()">
        <a class="dropdown dropdown-arrow-padding"
           dropdown title="{{'COMMON_BUTTON_ADD' | translate}}"
           href="#" onclick="return false" dropdownToggle
           data-toggle="dropdown" role="button"
           aria-haspopup="true" aria-expanded="false">
          <i class="icomoon icomoon-dropdown"></i>
          <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
                <span *ngIf="rightModel.orderImportMplFinalStatus.hasRight()" (click)="statImportDialog.toggleDialog()"
                      class="btn-setting dropdown-item" title="{{'ORDER_MPL_STATISTICS_IMPORT' | translate}}">
                  <i class="icomoon icomoon-csv-import"></i> {{'ORDER_MPL_STATISTICS_IMPORT' | translate}}
                </span>
          </div>
        </a>
      </div>
      <!-- DROPDOWN IMPORT END --> 
      <div *ngIf="rightModel.legacyProcessCreate.hasRight() && ConfigurationService.isEnabledByServer('LEGACY_WORKFLOW', config) && workflowList.length > 0"
           class="breadcrumb-button-icon-container cursor-pointer"
           (click)="openProcessCreateDialog()">
        <a class="btn-setting d-flex align-items-center" title="{{'ORDER_LIST_CREATE_PROCESS_BUTTON' | translate}}">
          <i class="icomoon icomoon-process"></i>
          <span class="breadcrumb-button-text-visible">{{'ORDER_LIST_CREATE_PROCESS_BUTTON' | translate}}</span>
        </a>
      </div>
      <!--DROPDOWN FOR PROCESS-->
      <div *ngIf="rightModel.legacyProcessUpdate.hasRight() && ConfigurationService.isEnabledByServer('LEGACY_WORKFLOW', config) && workflowList.length > 0"
           class="breadcrumb-button-icon-container-no-text cursor-pointer p-0">
        <a class="dropdown dropdown-arrow-padding"
           dropdown title="{{'COMMON_BUTTON_ADD' | translate}}"
           href="#" onclick="return false" dropdownToggle
           data-toggle="dropdown" role="button"
           aria-haspopup="true" aria-expanded="false">
          <i class="icomoon icomoon-dropdown">
          </i>
          <div class="dropdown-menu dropdown-menu-right"
               *dropdownMenu
               aria-labelledby="simple-dropdown">
                <span *ngIf="rightModel.legacyProcessUpdate.hasRight() && ConfigurationService.isEnabledByServer('LEGACY_WORKFLOW', config)"
                      (click)="openAddToProcessDialog()"
                      class="btn-setting dropdown-item"
                      title="{{'ORDER_LIST_ADD_TO_PROCESS_DIALOG_TITLE' | translate}}">
                <i class="icomoon icomoon-process"></i> {{'ORDER_LIST_ADD_TO_PROCESS_DIALOG_TITLE' | translate}}
                </span>
          </div>
        </a>
      </div>
      <div *ngIf="rightModel.orderCreate.hasRight()" class="breadcrumb-button-icon-container cursor-pointer"
           (click)="createOrder()">
        <a *ngIf="draftAvailable" class="btn-setting d-flex align-items-center" title="{{'ORDER_LIST_CONTINUE_BUTTON' | translate}}">
          <i class="icomoon icomoon-add"></i>
          <span class="breadcrumb-button-text-visible">{{'ORDER_LIST_CONTINUE_BUTTON' | translate}}</span>
        </a>
        <a *ngIf="!draftAvailable" class="btn-setting d-flex align-items-center" title="{{'ORDER_LIST_CREATE_BUTTON' | translate}}">
          <i class="icomoon icomoon-add"></i>
          <span class="breadcrumb-button-text-visible">{{'ORDER_LIST_CREATE_BUTTON' | translate}}</span>
        </a>
      </div>
      <!--DROPDOWN FOR ORDER-->
      <div class="breadcrumb-button-icon-container-no-text cursor-pointer p-0 negative-margin-right" *ngIf="rightModel.orderImportXlsx.hasRight()">
        <a class="dropdown dropdown-arrow-padding"
           dropdown title="{{'COMMON_BUTTON_ADD' | translate}}"
           href="#" onclick="return false" dropdownToggle
           data-toggle="dropdown" role="button"
           aria-haspopup="true" aria-expanded="false">
          <i class="icomoon icomoon-dropdown">
          </i>
          <div class="dropdown-menu dropdown-menu-right"
               *dropdownMenu
               aria-labelledby="simple-dropdown">
                <span (click)="orderImportDialog.toggleDialog()"
                      class="btn-setting dropdown-item" title="{{'ORDER_IMPORT' | translate}}">
                  <i class="icomoon icomoon-csv-import"></i> {{'ORDER_IMPORT' | translate}}
                </span>
            <span (click)="downloadFile()" class="btn-setting dropdown-item"
                  title="{{'ORDER_XLS_IMPORT_SAMPLE_DOWNLOAD' | translate}}">
                <i class="icomoon icomoon-download"></i> {{'ORDER_XLS_IMPORT_SAMPLE_DOWNLOAD' | translate}}
                </span>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">
  <div class="card">
    <div class="card-header">
      {{'ORDER_HEADING_LIST' | translate}}
      <span *ngIf="getSelectedOrderCount() > 0" class="card-header-secondary-text">
        - {{ getSelectedOrderCount() }} {{'ORDER_HEADING_SELECTED_ITEMS_COUNT' | translate}}
      </span>
      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()"
           title="{{'COMMON_SHOW_SEARCH_HINT' | translate}}"><i class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>
    <div class="card-body border-bottom" *ngIf="showSearch">
      <div class="row form-group mb-0">
        <div class="col-lg-2 col-md-3 col-sm-12">
          <label class="search-label">{{'ORDER_SEARCH_ID' | translate}}</label>
          <input type="number" class="form-control" placeholder="{{'ORDER_SEARCH_ID' | translate}}"
                 maxlength="{{UiConstants.maxInputLongLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.id" name="id" id="id" #id="ngModel">
        </div>
        <div class="col-lg-2 col-md-3 col-sm-12">
          <label class="search-label">{{'ORDER_SEARCH_EXTERNAL_ID' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'ORDER_SEARCH_EXTERNAL_ID' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.externalId" name="externalId" id="externalId" #externalId="ngModel">
        </div>
        <div class="col-lg-2 col-md-3 col-sm-12">
          <label class="search-label">{{'ORDER_SEARCH_COMPANY' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'ORDER_SEARCH_COMPANY' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.ownerCompanyName" name="ownerCompanyName"
                 id="ownerCompanyName" #ownerCompanyName="ngModel">
        </div>
        <div class="col-lg-2 col-md-3 col-sm-12">
          <label class="search-label">{{'ORDER_SEARCH_SHIPMENT_TRACKING_NUMBER' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'ORDER_SEARCH_SHIPMENT_TRACKING_NUMBER' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.shipmentTrackingNumber" name="shipmentTrackingNumber"
                 id="shipmentTrackingNumber" #shipmentTrackingNumber="ngModel">
        </div>
        <div class="col-lg-2 col-md-3 col-sm-12">
          <label class="search-label">{{'ORDER_SEARCH_CASH_ON_DELIVERY_PRICE_FROM' | translate}}</label>
          <input type="text" class="form-control"
                 maxlength="{{UiConstants.maxInputLongLength}}"
                 [textMask]="{mask: InputMask.NATURAL_INTEGER, guide: false}"
                 placeholder="{{'ORDER_SEARCH_CASH_ON_DELIVERY_PRICE_FROM' | translate}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.cashOnDeliveryPriceFrom" name="cashOnDeliveryPriceFrom"
                 id="cashOnDeliveryPriceFrom"
                 #cashOnDeliveryPriceFrom="ngModel">
        </div>
        <div class="col-lg-2 col-md-3 col-sm-12">
          <label class="search-label">{{'ORDER_SEARCH_CASH_ON_DELIVERY_PRICE_TO' | translate}}</label>
          <input type="text" class="form-control"
                 maxlength="{{UiConstants.maxInputLongLength}}"
                 [textMask]="{mask: InputMask.NATURAL_INTEGER, guide: false}"
                 placeholder="{{'ORDER_SEARCH_CASH_ON_DELIVERY_PRICE_TO' | translate}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.cashOnDeliveryPriceTo" name="cashOnDeliveryPriceTo" id="cashOnDeliveryPriceTo"
                 #cashOnDeliveryPriceTo="ngModel">
        </div>
        <div class="col-lg-2 col-md-3 col-sm-12">
          <label class="search-label">{{'ORDER_SEARCH_STATE' | translate}}</label>
          <angular2-multiselect
            [(ngModel)]="searchModel.logicState"
            [data]="orderDisplayStates"
            [settings]="dropdownSettings">
          </angular2-multiselect>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-12">
          <label class="search-label">{{'ORDER_SEARCH_SHIPPING_STATE' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'ORDER_SEARCH_SHIPPING_STATE' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.shippingState" name="shippingState" id="shippingState"
                 #shippingState="ngModel">
        </div>
        <div class="col-lg-2 col-md-3 col-sm-12">
          <label class="search-label">{{'ORDER_SEARCH_RECIPIENT_NAME' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'ORDER_SEARCH_RECIPIENT_NAME' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.recipientName" name="recipientName" id="recipientName"
                 #recipientName="ngModel">
        </div>
        <div class="col-lg-2 col-md-3 col-sm-12">
          <label class="search-label">{{'ORDER_SEARCH_RECIPIENT_ADDRESS' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'ORDER_SEARCH_RECIPIENT_ADDRESS' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.recipientAddress" name="recipientAddress" id="recipientAddress"
                 #recipientAddress="ngModel">
        </div>
        <div class="col-lg-2 col-md-3 col-sm-12">
          <label class="search-label">{{'ORDER_SEARCH_WEIGHT_FROM' | translate}}</label>
          <input type="text" class="form-control"
                 maxlength="{{UiConstants.maxInputLongLength}}"
                 [textMask]="{mask: InputMask.NATURAL_INTEGER, guide: false}" placeholder="{{'ORDER_SEARCH_WEIGHT_FROM' | translate}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.weightInGramFrom" name="weightInGramFrom" id="weightInGramFrom"
                 #weightInGramFrom="ngModel">
        </div>
        <div class="col-lg-2 col-md-3 col-sm-12">
          <label class="search-label">{{'ORDER_SEARCH_WEIGHT_TO' | translate}}</label>
          <input type="text" class="form-control"
                 maxlength="{{UiConstants.maxInputLongLength}}"
                 [textMask]="{mask: InputMask.NATURAL_INTEGER, guide: false}" placeholder="{{'ORDER_SEARCH_WEIGHT_TO' | translate}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.weightInGramTo" name="weightInGramTo" id="weightInGramTo"
                 #weightInGramTo="ngModel">
        </div>
        <div class="col-lg-2 col-md-3 col-sm-12">
          <label class="search-label">{{'ORDER_SEARCH_SUBMIT_TIME_FROM' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control date" placeholder="{{'ORDER_SEARCH_SUBMIT_TIME_FROM' | translate}}"
                   [(ngModel)]="searchModel.submitTimeFrom" ngbDatepicker name="submitTimeFrom" id="submitTimeFrom"
                   #submitTimeFrom="ngbDatepicker">
            <div class="input-group-addon-gray" (click)="submitTimeFrom.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-12">
          <label class="search-label">{{'ORDER_SEARCH_SUBMIT_TIME_TO' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control date"
                   placeholder="{{'ORDER_SEARCH_SUBMIT_TIME_TO' | translate}}"
                   [(ngModel)]="searchModel.submitTimeTo" ngbDatepicker name="submitTimeTo"
                   #submitTimeTo="ngbDatepicker"
                   id="submitTimeTo">
            <div class="input-group-addon-gray" (click)="submitTimeTo.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-12">
          <label class="search-label">{{'ORDER_SEARCH_RETURN_TIME_FROM' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control date" placeholder="{{'ORDER_SEARCH_RETURN_TIME_FROM' | translate}}"
                   [(ngModel)]="searchModel.returnTimeFrom" ngbDatepicker name="returnTimeFrom" id="returnTimeFrom"
                   #returnTimeFrom="ngbDatepicker">
            <div class="input-group-addon-gray" (click)="returnTimeFrom.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-12">
          <label class="search-label">{{'ORDER_SEARCH_RETURN_TIME_TO' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control date"
                   placeholder="{{'ORDER_SEARCH_RETURN_TIME_TO' | translate}}"
                   [(ngModel)]="searchModel.returnTimeTo" ngbDatepicker name="returnTimeTo"
                   #returnTimeTo="ngbDatepicker"
                   id="returnTimeTo">
            <div class="input-group-addon-gray" (click)="returnTimeTo.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-12">
          <label class="search-label">{{'ORDER_SEARCH_PROCESS_DEADLINE_FROM' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control date" placeholder="{{'ORDER_SEARCH_PROCESS_DEADLINE_FROM' | translate}}"
                   [(ngModel)]="searchModel.processDeadlineFrom" ngbDatepicker name="processDeadlineFrom" id="processDeadlineFrom"
                   #processDeadlineFrom="ngbDatepicker">
            <div class="input-group-addon-gray" (click)="processDeadlineFrom.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-12">
          <label class="search-label">{{'ORDER_SEARCH_PROCESS_DEADLINE_TO' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control date"
                   placeholder="{{'ORDER_SEARCH_PROCESS_DEADLINE_TO' | translate}}"
                   [(ngModel)]="searchModel.processDeadlineTo" ngbDatepicker name="processDeadlineTo"
                   #processDeadlineTo="ngbDatepicker"
                   id="processDeadlineTo">
            <div class="input-group-addon-gray" (click)="processDeadlineTo.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-12">
          <label class="search-label">{{'MINIMUM_IDENTICAL_PRODUCT_COUNT' | translate}}</label>
          <input type="text" class="form-control"
                 maxlength="{{UiConstants.maxInputLongLength}}"
                 [textMask]="{mask: InputMask.NATURAL_INTEGER, guide: false}"
                 placeholder="{{'MINIMUM_IDENTICAL_PRODUCT_COUNT' | translate}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.minimumIdenticalItemCount"
                 name="minimumIdenticalProductCount"
                 id="minimumIdenticalProductCount"
                 #minimumIdenticalProductCount="ngModel">
        </div>
        <div class="col d-flex align-items-end">
          <div class="row">
            <app-animated-checkbox class="search-checkbox"
                                   [(selected)]="searchModel.returnReasonEmpty">
            </app-animated-checkbox>
            <label class="search-label">{{'ORDER_SEARCH_NO_RETURN_REASON' | translate}}</label>
          </div>
        </div>
      </div>
      <div class="col-md-12 d-flex justify-content-md-end align-items-end px-0">
        <div class="btn-group" role="group">
          <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
            {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
          </button>
          <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
            {{'COMMON_BUTTON_SEARCH' | translate}}
          </button>
        </div>
      </div>
    </div><!-- /.card-body-->

    <div class="card-body">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header w-checkbox hidden-xs-down">
            <app-animated-checkbox [selected]="eachOrderSelected" (clickEvent)="toggleEachOrder()"></app-animated-checkbox>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-field-sorter [orderField]="Order.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(Order.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="Order.OrderField.OWNER_COMPANY_ID"
                                    [orderType]="queryModel.getOrderType(Order.OrderField.OWNER_COMPANY_ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'ORDER_TABLE_HEADER_COMPANY' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op [text]="'ORDER_TABLE_HEADER_TRACKING_NUMBER' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op [text]="'ORDER_TABLE_HEADER_PROCESS' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-1370-down">
            <app-table-field-sorter [orderField]="Order.OrderField.CASH_ON_DELIVERY_PRICE"
                                    [orderType]="queryModel.getOrderType(Order.OrderField.CASH_ON_DELIVERY_PRICE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'ORDER_TABLE_HEADER_CASH_ON_DELIVERY' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-10 hidden-sm-down">
            <app-table-sorter-no-op [text]="'COMMON_STATE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-15">
            <app-table-field-sorter [orderField]="Order.OrderField.RECIPIENT"
                                    [orderType]="queryModel.getOrderType(Order.OrderField.RECIPIENT)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'ORDER_TABLE_HEADER_RECIPIENT' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-15 hidden-lg-down">
            <app-table-sorter-no-op [text]="'ORDER_TABLE_HEADER_SUBMIT_TIME' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-actions">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let order of orderList | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }">
          <td class="hidden-xs-down">
            <app-animated-checkbox [(selected)]="order.selected"></app-animated-checkbox>
          </td>
          <td class="responsive-table-column hidden-md-down">{{order.orderId}}</td>
          <td class="responsive-table-column text-truncate">
            {{getCompanyName(order.ownerCompanyId)}}
            <br>
            <span class="order-subline">{{getUserName(order.ownerUserId)}}</span>
          </td>
          <td class="hidden-sm-down break-word">{{order.shipmentTrackingNumber}}</td>
          <ng-template #processPopover>
            <div>{{order.process.displayName}}</div>
            <div class="table-text-small" *ngIf="order.process.deadline">{{order.process.deadline.toUtcIsoString() | date:'short'}}</div>
            <div class="d-flex align-items-center">
              <i class="icomoon {{order.process.stateIcon}} process-state-icon"></i>
              {{order.process.stateText | translate}}
            </div>
            <div class="d-flex align-items-center">
              <app-type-icon class="mr-05" [icon]="order.process.workflow.icon"></app-type-icon>
              {{order.process.workflow.name}}
            </div>
          </ng-template>
          <td class="responsive-table-column long hidden-sm-down">
            <span *ngIf="rightModel.legacyProcessRead.hasRight() && order.process;
                         else processName">
              <a uiSref="Admin.LegacyProcessDetail"
                 [uiParams]="{id: order.process.id}"
                 [popover]="processPopover" triggers="mouseenter:mouseleave" container="body">
                {{order.process.displayName}}
              </a>
            </span>
            <ng-template #processName>
              <span *ngIf="order.process">{{order.process.displayName}}</span>
            </ng-template>
          </td>
          <td class="responsive-table-column hidden-1370-down">
            {{order.cashOnDeliveryPrice}}
          </td>
          <td class="responsive-table-column long hidden-sm-down">
            <div class="order-state-badge {{order.state.style}} d-flex align-items-center">
              <i class="icomoon {{order.state.icon}} order-state-icon d-flex align-items-center"></i>
              {{order.state.stringKey | translate}}
            </div>
          </td>
          <td class="responsive-table-column text-truncate">
            {{order.recipientName}}
            <br>
            <span class="order-subline">{{order.recipientDeliveryAddress}}</span>
          </td>
          <td class="responsive-table-column hidden-lg-down">{{order.submitTime.toUtcIsoString() | date:'short'}}</td>
          <td class="responsive-table-column w-table-actions" nowrap>
            <a class="icons-table-group cursor-pointer" uiSref="Admin.OrderDetail"
               [uiParams]="{ id: order.orderId }">
              <i class="icomoon icons-table-item icomoon-detail icomoon-detail-table"
                 title="{{'COMMON_BUTTON_DETAILS' | translate}}"></i>
            </a>
            <a class="icons-table-group cursor-pointer" uiSref="Admin.OrderEdit"
               [uiParams]="{ id: order.orderId }"
               *ngIf="rightModel.orderUpdate.hasRight() && isEditableOrderState(order.orderState)">
              <i class="icomoon icons-table-item icomoon-modify icomoon-modify-table"
                 title="{{'COMMON_BUTTON_EDIT' | translate}}"></i>
            </a>
            <a class="icons-table-group cursor-pointer"
               (click)="cancelOrder(order.orderId)"
               *ngIf="rightModel.orderCancel.hasRight() && (order.orderState === 'DRAFT' || order.orderState === 'SUBMITTED')">
              <i class="icomoon icons-table-item icomoon-trash icomoon-disable-table"
                 title="{{'COMMON_BUTTON_DELETE' | translate}}"></i>
            </a>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header w-checkbox hidden-xs-down">
            <app-animated-checkbox [selected]="eachOrderSelected" (clickEvent)="toggleEachOrder()"></app-animated-checkbox>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-field-sorter [orderField]="Order.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(Order.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="Order.OrderField.OWNER_COMPANY_ID"
                                    [orderType]="queryModel.getOrderType(Order.OrderField.OWNER_COMPANY_ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'ORDER_TABLE_HEADER_COMPANY' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op [text]="'ORDER_TABLE_HEADER_TRACKING_NUMBER' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op [text]="'ORDER_TABLE_HEADER_PROCESS' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-1370-down">
            <app-table-field-sorter [orderField]="Order.OrderField.CASH_ON_DELIVERY_PRICE"
                                    [orderType]="queryModel.getOrderType(Order.OrderField.CASH_ON_DELIVERY_PRICE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'ORDER_TABLE_HEADER_CASH_ON_DELIVERY' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-10 hidden-sm-down">
            <app-table-sorter-no-op [text]="'COMMON_STATE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-15">
            <app-table-field-sorter [orderField]="Order.OrderField.RECIPIENT"
                                    [orderType]="queryModel.getOrderType(Order.OrderField.RECIPIENT)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'ORDER_TABLE_HEADER_RECIPIENT' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-15 hidden-lg-down">
            <app-table-sorter-no-op [text]="'ORDER_TABLE_HEADER_SUBMIT_TIME' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-actions">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>
  </div>
</div>

<app-file-upload-dialog
  [uploadPath]="mplStatImportPath"
  (onResult)="onImportSuccess($event)"
  #statImportDialog
></app-file-upload-dialog>

<app-file-upload-dialog
  [uploadPath]="orderImportPath"
  (onResult)="onImportSuccess($event)"
  #orderImportDialog
></app-file-upload-dialog>

<app-file-upload-dialog
  [uploadPath]="orderReturnImportPath"
  (responseContent)="onOrderReturnImportFinished($event)"
  #orderReturnImportDialog
></app-file-upload-dialog>

<div bsModal [config]="UiConstants.modalConfig" #processCreateDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="processCreateDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'PROCESS_CREATE_TITLE' | translate}}</h4>
        <button type="button" class="close" (click)="closeProcessCreateDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" [formGroup]="formGroup" #f="ngForm">

        <div class="form-group row"
             [ngClass]="{ 'has-danger': hasLocalFieldError('workflowId')}">
          <label class="col-form-label col-md-4">{{'LEGACY_PROCESS_CREATE_WORKFLOW' | translate}}</label>
          <div class="col-md-8">
            <select class="form-control" [compareWith]="SelectUtils.compareObjects"
                    [(ngModel)]="processCreateModel.workflowId"
                    [ngClass]="{ 'form-control-danger': hasLocalFieldError('workflowId') }"
                    formControlName="workflowId"
                    (ngModelChange)="removeFieldError(Order.ProcessValidatedField.WORKFLOW_ID)"
                    trim="blur" validateOnBlur [validateFormControl]="formGroup.controls['workflowId']">
              <option *ngFor="let item of workflowList" [ngValue]="item.id">{{item.name}}</option>
            </select>
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('workflowId')">
              {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
            </div>
          </div>
          <!-- /.col-md-8 -->
        </div>

        <div class="form-group row"
             [ngClass]="{ 'has-danger': hasFieldError(Order.ProcessValidatedField.NAME) }">
          <label class="col-form-label col-md-4">{{'COMMON_NAME' | translate}}</label>
          <div class="col-md-8">
            <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
                   [ngClass]="{ 'form-control-danger': hasFieldError(Order.ProcessValidatedField.NAME) }"
                   [(ngModel)]="processCreateModel.name" formControlName="name"
                   (ngModelChange)="removeFieldError(Order.ProcessValidatedField.NAME)"
                   trim="blur" validateOnBlur [validateFormControl]="formGroup.controls['name']">
            <div class="form-control-feedback" *ngIf="hasFieldError(Order.ProcessValidatedField.NAME)">
              {{getFieldErrorText(Order.ProcessValidatedField.NAME)}}
            </div>
          </div>
          <!-- /.col-md-8 -->
        </div>

        <div class="form-group row"
             [ngClass]="{ 'has-danger': hasFieldError(Order.ProcessValidatedField.EXTERNAL_ID) }">
          <label class="col-form-label col-md-4">{{'PROCESS_CREATE_EXTERNAL_ID' | translate}}</label>
          <div class="col-md-8">
            <input type="text" class="form-control" placeholder="{{'PROCESS_CREATE_EXTERNAL_ID' | translate}}"
                   [ngClass]="{ 'form-control-danger': hasFieldError(Order.ProcessValidatedField.EXTERNAL_ID) }"
                   [(ngModel)]="processCreateModel.externalId" formControlName="externalId"
                   (ngModelChange)="removeFieldError(Order.ProcessValidatedField.EXTERNAL_ID)"
                   trim="blur" validateOnBlur [validateFormControl]="formGroup.controls['externalId']">
            <div class="form-control-feedback" *ngIf="hasFieldError(Order.ProcessValidatedField.EXTERNAL_ID)">
              {{getFieldErrorText(Order.ProcessValidatedField.EXTERNAL_ID)}}
            </div>
          </div>
          <!-- /.col-md-8 -->
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-4">{{'COMMON_DESCRIPTION' | translate}}</label>
          <div class="col-md-8">
            <input class="form-control" type="text" placeholder="{{'COMMON_DESCRIPTION' | translate}}"
                   name="description"
                   [ngModelOptions]="{standalone: true}"
                   id="description" #description="ngModel" [(ngModel)]="processCreateModel.description">
          </div>
          <!-- /.col-md-8 -->
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-4">{{'PROCESS_CREATE_DEADLINE' | translate}}</label>
          <div class="input-group col-md-8">
            <input type="text" class="form-control date"
                   placeholder="{{'PROCESS_CREATE_DEADLINE' | translate}}"
                   [(ngModel)]="processCreateModel.deadline" ngbDatepicker name="deadline" id="deadline"
                   [ngModelOptions]="{standalone: true}" #deadline="ngbDatepicker">
            <div class="input-group-addon-gray" (click)="deadline.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>

        <div class="alert alert-warning mb-0">
          <p class="mb-0">
            <span class="font-weight-bold">
              {{'ORDER_LIST_PROCESS_DIALOG_NUMBER' | translate: {number: processCreateModel.orderIds.length} }}
            </span>
            <span>
              {{'ORDER_LIST_PROCESS_DIALOG_CONFIRMATION_NEW' | translate }}
            </span>
          </p>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeProcessCreateDialog()">
          {{'COMMON_BUTTON_CANCEL' | translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="createProcess()">{{'COMMON_BUTTON_SAVE' |
          translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<div bsModal [config]="UiConstants.modalConfig" #addToProcessDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="addToProcessDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'ORDER_LIST_ADD_TO_PROCESS_DIALOG_TITLE' | translate}}</h4>
        <button type="button" class="close" (click)="closeAddToProcessDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <div class="form-group row"
             [ngClass]="{ 'has-danger': addToProcessSubmitted && !processChosen }">
          <label class="col-form-label col-md-4">{{'ORDER_LIST_ADD_TO_PROCESS_DIALOG_LABEL' | translate}}</label>
          <div class="col-md-8">
            <select class="form-control" [compareWith]="SelectUtils.compareObjects"
                    [(ngModel)]="addToProcessModel.processId"
                    [ngClass]="{ 'form-control-danger': addToProcessSubmitted && !processChosen }"
                    (ngModelChange)="processChosen = true">
              <option *ngFor="let item of processList" [ngValue]="item.id">{{item.name}}</option>
            </select>
            <div class="form-control-feedback" *ngIf="addToProcessSubmitted && !processChosen">
              {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
            </div>
          </div>
          <!-- /.col-md-8 -->
        </div>

        <div class="alert alert-warning mb-0">
          <p class="mb-0">
            <span class="font-weight-bold">
              {{'ORDER_LIST_PROCESS_DIALOG_NUMBER' | translate: {number: addToProcessModel.orderIds.length} }}
            </span>
            <span>
              {{'ORDER_LIST_PROCESS_DIALOG_CONFIRMATION_ADD' | translate }}
            </span>
          </p>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeAddToProcessDialog()">
          {{'COMMON_BUTTON_CANCEL' | translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="addToProcess()">{{'COMMON_BUTTON_SAVE' |
          translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<div bsModal [config]="UiConstants.modalConfig" #companySelectDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="companySelectDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'USER_PLACEHOLDER_COMPANY' | translate}}</h4>
        <button type="button" class="close" (click)="closeCompanySelectDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <div class="form-group row">
          <label class="col-form-label col-md-4">{{'USER_PLACEHOLDER_COMPANY' | translate}}</label>
          <div class="col-md-8">
            <select class="form-control" [compareWith]="SelectUtils.compareObjects"
                    [(ngModel)]="orderCreateModel.selectedCompany">
              <option *ngFor="let item of orderCreateModel.selectableCompanies" [ngValue]="item">{{item.name}}</option>
            </select>
          </div>
          <!-- /.col-md-8 -->
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeCompanySelectDialog()">
          {{'COMMON_BUTTON_CANCEL' | translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="createOrderForCompany(orderCreateModel.selectedCompany!.id)">{{'COMMON_BUTTON_SAVE' |
          translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
