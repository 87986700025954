/* eslint-disable */
import { Component, OnInit, ViewChild } from '@angular/core';
import { Transition, UIRouter } from '@uirouter/angular';
import { StateName } from '../../../app.state-names';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { TranslateService } from '@ngx-translate/core';
import { InputMask } from '../../../util/input-masks';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import {
  FieldValidationError,
  QueryResult
} from '../../../lib/util/services';
import { MultiselectOptionItem, SelectUtils, UiConstants } from '../../../util/core-utils';
import { ComponentStateResolver } from '../../../util/component-state/component-state-resolver';
import { Angular2Multiselects } from '../../../util/multiselect';
import { DeliveryMethodDetailModel, DeliveryMethodEditModel } from '../delivery-method.model';
import { DeliveryMethod, DeliveryMethodService } from '../../../lib/delivery-method/delivery-method.service';
import { Currency, CurrencyService } from '../../../lib/currency.service';
import { WeightFactory } from '../../../util/weight-utils';
import { AppValidators } from '../../../util/app-validators';
import { Strings } from '../../../lib/util/strings';
import { Models } from '../../../util/model-utils';
import Decimal from 'decimal.js';
import { EmptyMessage, IdentityMessage } from '../../../lib/util/messages';
import { LengthFactory } from '../../../util/length-utils';
import { CompanyMultiselectProvider } from '../../../lib/company/company-multiselect.provider';
import { RightResolver, RightService } from '../../../lib/right.service';
import { RightModel } from '../../../app.rights';
/* eslint-enable */

@Component({
  selector: 'app-parcel-collection-point-base',
  templateUrl: './delivery-method-base.component.html',
  styleUrls: ['./delivery-method-base.component.scss']
})
export class DeliveryMethodBaseComponent implements OnInit {

  // If you need to access certain classes from HTML, declare them here
  InputMask = InputMask;
  DeliveryMethod = DeliveryMethod;
  UiConstants = UiConstants;
  SelectUtils = SelectUtils;

  @ViewChild('f', { static: true })
  form: NgForm;

  formGroup: FormGroup;
  formSubmitted: boolean = false;

  // Declare models before use
  editModel: DeliveryMethodEditModel = new DeliveryMethodEditModel();
  detailModel: DeliveryMethodDetailModel = new DeliveryMethodDetailModel();

  // Component state resolver, determines the state of the component
  componentState: ComponentStateResolver;

  // Variables used for breadcrumb
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  selectableCashOnDeliveryCurrencies: string[] = [];
  selectableInsuranceCurrencies: string[] = [];
  selectableWeightUnits: string[] = [];
  selectableLengthUnits: string[] = [];
  transporterCompanies: MultiselectOptionItem<number>[] = [];

  types: MultiselectOptionItem<DeliveryMethod.DeliveryMethodType>[]
    = DeliveryMethod.types.map(o => ({id: o.type, itemName: o.stringKey}));

  private fieldErrors: FieldValidationError<DeliveryMethod.ValidatedField> =
    FieldValidationError.empty<DeliveryMethod.ValidatedField>();

  typeDropdownSettings: Angular2Multiselects.Settings;
  transporterCompanyDropdownSettings: Angular2Multiselects.Settings;

  rightModel: RightModel = RightModel.empty();

  constructor(private uiRouter: UIRouter,
              private transition: Transition,
              private deliveryMethodService: DeliveryMethodService,
              private companyMultiselectProvider: CompanyMultiselectProvider,
              private currencyService: CurrencyService,
              private formBuilder: FormBuilder,
              private rightService: RightService,
              private translateService: TranslateService) {
    this.componentState = new ComponentStateResolver(uiRouter, transition,
      'id',
      {stateName: StateName.DELIVERY_METHOD_CREATE, stateHeaderKey: 'DELIVERY_METHOD_CREATE'},
      {stateName: StateName.DELIVERY_METHOD_EDIT, stateHeaderKey: 'DELIVERY_METHOD_EDIT'},
      {stateName: StateName.DELIVERY_METHOD_DETAIL, stateHeaderKey: 'DELIVERY_METHOD_DETAIL'});
    this.formGroup = this.formBuilder.group({
      name: ['', Validators.required],
      externalId: ['', Validators.required],
      type: ['', Validators.required],
      transporterCompany: ['', Validators.required],
      maxPackageWeight: ['', AppValidators.validateOptionalPositiveNumber],
      maxPackageSizeWidth: ['', [
          AppValidators.validateOptionalPositiveNumber,
          AppValidators.required({
            disabled: () => {
              return Strings.undefinedOrNonEmpty(this.editModel.maxPackageSizeWidth) === undefined
                && Strings.undefinedOrNonEmpty(this.editModel.maxPackageSizeHeight) === undefined
                && Strings.undefinedOrNonEmpty(this.editModel.maxPackageSizeDepth) === undefined;
            }
          })
        ]
      ],
      maxPackageSizeHeight: ['', [
        AppValidators.validateOptionalPositiveNumber,
        AppValidators.required({
          disabled: () => {
            return Strings.undefinedOrNonEmpty(this.editModel.maxPackageSizeWidth) === undefined
              && Strings.undefinedOrNonEmpty(this.editModel.maxPackageSizeHeight) === undefined
              && Strings.undefinedOrNonEmpty(this.editModel.maxPackageSizeDepth) === undefined;
          }
        })
      ]
      ],
      maxPackageSizeDepth: ['', [
          AppValidators.validateOptionalPositiveNumber,
          AppValidators.required({
            disabled: () => {
              return Strings.undefinedOrNonEmpty(this.editModel.maxPackageSizeWidth) === undefined
                && Strings.undefinedOrNonEmpty(this.editModel.maxPackageSizeHeight) === undefined
                && Strings.undefinedOrNonEmpty(this.editModel.maxPackageSizeDepth) === undefined;
            }
          })
        ]
      ],
      maxInsurancePrice: ['', AppValidators.validateOptionalPositiveNumber],
      maxCashOnDeliveryPrice: ['', AppValidators.validateOptionalPositiveNumber],
    });
  }

  ngOnInit() {
    this.initComponentState(); // Must be called first
    this.initBreadcrumb();
    this.initDropdowns();
    this.loadRightModels();
    this.loadTransporterCompanies();
  }

  initDropdowns() {
    this.typeDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .translate(true)
      .build();
    this.transporterCompanyDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
    this.selectableCashOnDeliveryCurrencies = [];
    this.selectableInsuranceCurrencies = [];
    this.currencyService.query({}).subscribe((result: QueryResult<Currency.Currency>) => {
      const array = result.items.toArray();
      array.forEach((currency) => {
        this.selectableCashOnDeliveryCurrencies.push(currency.currencyCode);
        if (currency.currencyCode === 'HUF') {
          this.editModel.maxCashOnDeliveryPriceCurrency = currency.currencyCode;
          this.editModel.maxInsurancePriceCurrency = currency.currencyCode;
          this.selectableInsuranceCurrencies.push(currency.currencyCode);
        }
      });
    });
    this.selectableWeightUnits = WeightFactory.getOptions();
    this.selectableLengthUnits = LengthFactory.getOptions();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  // Called by the init method first
  private loadModel() {
    this.deliveryMethodService.get({
      id: this.componentState.id!
    }).subscribe((deliveryMethod: DeliveryMethod.DeliveryMethod) => {
      if (this.componentState.isEditView()) {
        this.loadEditModel(deliveryMethod);
      }
      else if (this.componentState.isDetailView()) {
        this.loadDetailModel(deliveryMethod);
      }
      this.breadcrumbSelf = deliveryMethod.name;
    });
  }

  private loadEditModel(deliveryMethod: DeliveryMethod.DeliveryMethod) {
    this.editModel.id = deliveryMethod.id;
    this.editModel.name = deliveryMethod.name;
    this.editModel.externalId = deliveryMethod.externalId;
    this.editModel._type.push(this.types.find(o => o.id === deliveryMethod.type)!);
    if (deliveryMethod.transporterCompany) {
      this.editModel.transporterCompany.push({id: deliveryMethod.transporterCompany.id, itemName: deliveryMethod.transporterCompany.name});
    }
    this.formGroup.controls.type.updateValueAndValidity();
    this.editModel.integrationType = deliveryMethod.integrationType;
    this.editModel.packageWeightRequired = deliveryMethod.packageWeightRequired;
    this.editModel.maxPackageWeightValue = deliveryMethod.maxPackageWeightInGram ? deliveryMethod.maxPackageWeightInGram.toFixed() : '';
    this.editModel.packageSizeRequired = deliveryMethod.packageSizeRequired;
    this.editModel.maxPackageSizeWidth
      = deliveryMethod.maxPackageSize ? Models.decimalToString(deliveryMethod.maxPackageSize.widthInMeter.mul(100)) : '';
    this.editModel.maxPackageSizeHeight
      = deliveryMethod.maxPackageSize ? Models.decimalToString(deliveryMethod.maxPackageSize.heightInMeter.mul(100)) : '';
    this.editModel.maxPackageSizeDepth
      = deliveryMethod.maxPackageSize ? Models.decimalToString(deliveryMethod.maxPackageSize.depthInMeter.mul(100)) : '';
    this.editModel.maxInsurancePriceValue
      = deliveryMethod.maxInsurancePrice ? Models.decimalToString(deliveryMethod.maxInsurancePrice.amount) : '';
    this.editModel.maxInsurancePriceCurrency
      = deliveryMethod.maxInsurancePrice ? deliveryMethod.maxInsurancePrice.currencyCode : 'HUF';
    this.editModel.maxCashOnDeliveryPriceValue
      = deliveryMethod.maxCashOnDeliveryPrice ? Models.decimalToString(deliveryMethod.maxCashOnDeliveryPrice.amount) : '';
    this.editModel.maxCashOnDeliveryPriceCurrency
      = deliveryMethod.maxCashOnDeliveryPrice ? deliveryMethod.maxCashOnDeliveryPrice.currencyCode : 'HUF';
    this.editModel.recipientEmailAddressRequired = deliveryMethod.recipientEmailAddressRequired;
    this.editModel.recipientPhoneNumberRequired = deliveryMethod.recipientPhoneNumberRequired;
  }

  private loadDetailModel(deliveryMethod: DeliveryMethod.DeliveryMethod) {
    this.detailModel.id = deliveryMethod.id;
    this.detailModel.name = deliveryMethod.name;
    this.detailModel.externalId = deliveryMethod.externalId;
    this.detailModel.type = this.types.find(o => o.id === deliveryMethod.type)!.itemName;
    if (deliveryMethod.transporterCompany) {
      this.detailModel.transporterCompany = deliveryMethod.transporterCompany.name;
    }
    this.detailModel.integrationType = deliveryMethod.integrationType;
    this.detailModel.packageWeightRequired = deliveryMethod.packageWeightRequired;
    this.detailModel.maxPackageWeight = deliveryMethod.maxPackageWeightInGram
      ? WeightFactory.createWeightFromGram(deliveryMethod.maxPackageWeightInGram).toString()
      : undefined;
    this.detailModel.packageSizeRequired = deliveryMethod.packageSizeRequired;
    this.detailModel.maxPackageSize = deliveryMethod.maxPackageSize
      ? LengthFactory.createLengthFromMeters(deliveryMethod.maxPackageSize.widthInMeter.toNumber()).toString()
        + ' x ' + LengthFactory.createLengthFromMeters(deliveryMethod.maxPackageSize.heightInMeter.toNumber()).toString()
        + ' x ' + LengthFactory.createLengthFromMeters(deliveryMethod.maxPackageSize.depthInMeter.toNumber()).toString()
      : undefined;
    this.detailModel.maxInsurancePrice = deliveryMethod.maxInsurancePrice
      ? deliveryMethod.maxInsurancePrice.amount + ' ' + deliveryMethod.maxInsurancePrice.currencyCode
      : '';
    this.detailModel.maxCashOnDeliveryPrice = deliveryMethod.maxCashOnDeliveryPrice
      ? deliveryMethod.maxCashOnDeliveryPrice.amount + ' ' + deliveryMethod.maxCashOnDeliveryPrice.currencyCode
      : '';
    this.detailModel.recipientEmailAddressRequired = deliveryMethod.recipientEmailAddressRequired;
    this.detailModel.recipientPhoneNumberRequired = deliveryMethod.recipientPhoneNumberRequired;
  }

  private initComponentState() {
    // component state contains the id
    if (this.componentState.id) {
      this.loadModel();
    }
  }

  initBreadcrumb() {
    // Set breadcrumbSelf if createView, otherwise set in loadModel()
    if (this.componentState.isCreateView()) {
      this.translateService.get('DELIVERY_METHOD_CREATE').subscribe(
        (result: string) => {
          this.breadcrumbSelf = result;
        }
      );
    }
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('MENU_NAVBAR_DELIVERY_METHODS').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.DELIVERY_METHOD_LIST});
      }
    );
  }

  loadTransporterCompanies(q?: string) {
    this.companyMultiselectProvider.loadTransporterCompanies(q).subscribe(result => this.transporterCompanies = result);
  }

  // Get corresponding dictionary key
  getHeadingDictionaryKey(): string {
    return this.componentState.getCurrentHeaderKey();
  }

  hasLocalFieldError(control?: string, error?: string): boolean {
    if (control) {
      if (error) {
        return this.formSubmitted && this.formGroup.controls[control].hasError(error);
      }
      return this.formSubmitted && this.formGroup.controls[control].invalid;
    }
    return this.formSubmitted && this.formGroup.invalid;
  }

  hasFieldError(field: DeliveryMethod.ValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: DeliveryMethod.ValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: DeliveryMethod.ValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  submit() {
    this.formSubmitted = true;
    if (this.hasLocalFieldError()) {
      return;
    }
    if (this.componentState.isCreateView()) {
      this.create();
    }
    else if (this.componentState.isEditView()) {
      this.update();
    }
  }

  create() {
    let widthInMeter: Decimal | undefined = undefined;
    let heightInMeter: Decimal | undefined = undefined;
    let depthInMeter: Decimal | undefined = undefined;
    if (this.editModel.maxPackageSizeWidth.length > 0) {
      widthInMeter = Models.parseDecimal(this.editModel.maxPackageSizeWidth);
      heightInMeter = Models.parseDecimal(this.editModel.maxPackageSizeHeight);
      depthInMeter = Models.parseDecimal(this.editModel.maxPackageSizeDepth);
      if (widthInMeter && heightInMeter && depthInMeter) {
        switch (this.editModel.maxPackageSizeUnit) {
          case 'mm':
            widthInMeter = widthInMeter.div(1000);
            heightInMeter = heightInMeter.div(1000);
            depthInMeter = depthInMeter.div(1000);
            break;
          case 'cm':
            widthInMeter = widthInMeter.div(100);
            heightInMeter = heightInMeter.div(100);
            depthInMeter = depthInMeter.div(100);
            break;
          case 'm':
            break;
        }
      }
    }
    let weightInGram: number | undefined = undefined;
    if (this.editModel.maxPackageWeightValue.length > 0) {
      weightInGram = Models.parseNumber(this.editModel.maxPackageWeightValue);
      if (weightInGram) {
        switch (this.editModel.maxPackageWeightUnit) {
          case 'g':
            break;
          case 'dkg':
            weightInGram = weightInGram * 10;
            break;
          case 'kg':
            weightInGram = weightInGram * 1000;
            break;
          case 't':
            weightInGram = weightInGram * 1000 * 1000;
        }
      }
    }
    const maxInsurancePrice: Decimal | undefined = Models.parseDecimal(this.editModel.maxInsurancePriceValue);
    const maxCashOnDeliveryPrice: Decimal | undefined = Models.parseDecimal(this.editModel.maxCashOnDeliveryPriceValue);
    this.deliveryMethodService.create({
      name: this.editModel.name,
      externalId: this.editModel.externalId,
      type: this.editModel.type!,
      transporterCompany: {
        id: this.editModel.transporterCompanyId!
      },
      integrationType: this.editModel.integrationType,
      packageWeightRequired: this.editModel.packageWeightRequired,
      maxPackageWeightInGram: weightInGram,
      packageSizeRequired: this.editModel.packageSizeRequired,
      maxPackageSize: widthInMeter ? {
        widthInMeter: widthInMeter,
        heightInMeter: heightInMeter!,
        depthInMeter: depthInMeter!
      } : undefined,
      maxInsurancePrice: maxInsurancePrice ? {
        amount: maxInsurancePrice,
        currencyCode: this.editModel.maxInsurancePriceCurrency
      } : undefined,
      maxCashOnDeliveryPrice: maxCashOnDeliveryPrice ? {
        amount: maxCashOnDeliveryPrice,
        currencyCode: this.editModel.maxCashOnDeliveryPriceCurrency
      } : undefined,
      recipientEmailAddressRequired: this.editModel.recipientEmailAddressRequired,
      recipientPhoneNumberRequired: this.editModel.recipientPhoneNumberRequired
    }).subscribe(
      (response: IdentityMessage) => {
        this.uiRouter.stateService.go(StateName.DELIVERY_METHOD_LIST);
      },
      (error: any) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withForm(this.form);
        }
      });
  }

  update() {
    let widthInMeter: Decimal | undefined = undefined;
    let heightInMeter: Decimal | undefined = undefined;
    let depthInMeter: Decimal | undefined = undefined;
    if (this.editModel.maxPackageSizeWidth.length > 0) {
      widthInMeter = Models.parseDecimal(this.editModel.maxPackageSizeWidth);
      heightInMeter = Models.parseDecimal(this.editModel.maxPackageSizeHeight);
      depthInMeter = Models.parseDecimal(this.editModel.maxPackageSizeDepth);
      if (widthInMeter && heightInMeter && depthInMeter) {
        switch (this.editModel.maxPackageSizeUnit) {
          case 'mm':
            widthInMeter = widthInMeter.div(1000);
            heightInMeter = heightInMeter.div(1000);
            depthInMeter = depthInMeter.div(1000);
            break;
          case 'cm':
            widthInMeter = widthInMeter.div(100);
            heightInMeter = heightInMeter.div(100);
            depthInMeter = depthInMeter.div(100);
            break;
          case 'm':
            break;
        }
      }
    }
    let weightInGram: number | undefined = undefined;
    if (this.editModel.maxPackageWeightValue.length > 0) {
      weightInGram = Models.parseNumber(this.editModel.maxPackageWeightValue);
      if (weightInGram) {
        switch (this.editModel.maxPackageWeightUnit) {
          case 'g':
            break;
          case 'dkg':
            weightInGram = weightInGram * 10;
            break;
          case 'kg':
            weightInGram = weightInGram * 1000;
            break;
          case 't':
            weightInGram = weightInGram * 1000 * 1000;
        }
      }
    }
    const maxInsurancePrice: Decimal | undefined = Models.parseDecimal(this.editModel.maxInsurancePriceValue);
    const maxCashOnDeliveryPrice: Decimal | undefined = Models.parseDecimal(this.editModel.maxCashOnDeliveryPriceValue);
    this.deliveryMethodService.update({
      id: this.editModel.id,
      name: this.editModel.name,
      externalId: this.editModel.externalId,
      type: this.editModel.type!,
      transporterCompany: {
        id: this.editModel.transporterCompanyId!
      },
      integrationType: this.editModel.integrationType,
      packageWeightRequired: this.editModel.packageWeightRequired,
      maxPackageWeightInGram: weightInGram,
      packageSizeRequired: this.editModel.packageSizeRequired,
      maxPackageSize: widthInMeter ? {
        widthInMeter: widthInMeter,
        heightInMeter: heightInMeter!,
        depthInMeter: depthInMeter!
      } : undefined,
      maxInsurancePrice: maxInsurancePrice ? {
        amount: maxInsurancePrice,
        currencyCode: this.editModel.maxInsurancePriceCurrency
      } : undefined,
      maxCashOnDeliveryPrice: maxCashOnDeliveryPrice ? {
        amount: maxCashOnDeliveryPrice,
        currencyCode: this.editModel.maxCashOnDeliveryPriceCurrency
      } : undefined,
      recipientEmailAddressRequired: this.editModel.recipientEmailAddressRequired,
      recipientPhoneNumberRequired: this.editModel.recipientPhoneNumberRequired
    }).subscribe(
      (response: EmptyMessage) => {
        this.uiRouter.stateService.go(StateName.DELIVERY_METHOD_LIST);
      },
      (error: any) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withForm(this.form);
        }
      });
  }

  back() {
    window.history.back();
  }

  validateMaxPackageSize() {
    this.formGroup.controls.maxPackageSizeWidth.updateValueAndValidity();
    this.formGroup.controls.maxPackageSizeHeight.updateValueAndValidity();
    this.formGroup.controls.maxPackageSizeDepth.updateValueAndValidity();
  }
}
