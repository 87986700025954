/* eslint-disable */
import { EmptyError, Observable, Observer } from 'rxjs';
import { Injectable } from '@angular/core';
import { PagingRequest, QueryResult, ResourceQueryResult, Services } from '../util/services';
import { List, Set } from 'immutable';
import { OffsetDateTime } from '../util/dates';
import { DqlQuery, Query } from '../query/field';
import { FilterField } from '../query/filterfields';
import { OrderField } from '../query/orderfields';
import { EmptyMessage } from '../util/messages';
import {
  OrderImportErrorResource,
  OrderImportErrorResourceService,
} from './order-import-error-resource.service';
/* eslint-enable */

export namespace OrderImportError {

  export interface Service {
    query(request: QueryRequest): Observable<QueryResult<OrderImportError>>;
    get(request: GetRequest): Observable<OrderImportError>;
    rejectManually(request: GetRequest): Observable<EmptyMessage>;
  }

  export interface QueryRequest {
    fields?: Query.FieldFunction<Fields.OrderImportError>;
    filter?: Query.FilterFunction<FilterField.OrderImportError>;
    order?: Query.OrderFunction<OrderField.OrderImportError>;
    paging?: PagingRequest;
  }

  export interface OrderImportError {
    id: number;
    state: OrderImportErrorState;
    errorReason?: OrderImportErrorReason;
    errorMessage?: string;
    errorStackTrace?: string;
    realm: OrderImportErrorRealm;
    externalId1: string;
    externalId2?: string;
    extraId1?: string;
    creationTime: OffsetDateTime;
    updateTime: OffsetDateTime;
    company: Company;
    user: User;
    recipientName?: string;
    data1?: Data;
    data2?: Data;
    data3?: Data;
    manualRejectUser?: User;
    manualRejectTime?: OffsetDateTime;
  }

  export interface User {
    id: number;
    personName: string;
  }

  export interface Company {
    id: number;
    name: string;
  }

  export interface Data {
    content: string;
    contentType: string;
  }

  export type OrderImportErrorState =
    'FIXED' |
    'IGNORED' |
    'REJECTED' |
    'REJECTED_MANUALLY';

  export interface OrderImportErrorStateObject {
    state: OrderImportErrorState;
    stringKey: string;
    iconClass: string;
  }

  export const orderImportErrorStates: OrderImportErrorStateObject[] = [
    {state: 'FIXED', stringKey: 'ORDER_IMPORT_ERROR_STATE_FIXED', iconClass: 'icomoon-order-import-error-fixed'},
    {state: 'IGNORED', stringKey: 'ORDER_IMPORT_ERROR_STATE_IGNORED', iconClass: 'icomoon-order-import-error-ignored'},
    {state: 'REJECTED', stringKey: 'ORDER_IMPORT_ERROR_STATE_REJECTED', iconClass: 'icomoon-order-import-error-rejected'},
    {
      state: 'REJECTED_MANUALLY',
      stringKey: 'ORDER_IMPORT_ERROR_STATE_REJECTED_MANUALLY',
      iconClass: 'icomoon-order-import-error-rejected-manually'
    }
  ];

  export type OrderImportErrorReason =
    'GENERAL_ERROR' |
    'UNKNOWN_PRODUCT_CODE' |
    'INSUFFICIENT_AMOUNT' |
    'INVALID_ADDRESS';


  export interface OrderImportErrorReasonObject {
    reason: OrderImportErrorReason;
    stringKey: string;
  }

  export const orderImportErrorReasons: OrderImportErrorReasonObject[] = [
    {reason: 'GENERAL_ERROR', stringKey: 'ORDER_IMPORT_ERROR_REASON_GENERAL_ERROR'},
    {reason: 'UNKNOWN_PRODUCT_CODE', stringKey: 'ORDER_IMPORT_ERROR_REASON_UNKNOWN_PRODUCT_CODE'},
    {reason: 'INSUFFICIENT_AMOUNT', stringKey: 'ORDER_IMPORT_ERROR_REASON_INSUFFICIENT_AMOUNT'},
    {reason: 'INVALID_ADDRESS', stringKey: 'ORDER_IMPORT_ERROR_REASON_INVALID_ADDRESS'}
  ];

  export type OrderImportErrorRealm =
    'PROSPEDITION' |
    'SHOP_RENTER';

  export interface OrderImportErrorRealmObject {
    realm: OrderImportErrorRealm;
    stringKey: string;
  }

  export const orderImportErrorRealms: OrderImportErrorRealmObject[] = [
    {realm: 'PROSPEDITION', stringKey: 'ORDER_IMPORT_ERROR_REALM_PROSPEDITION'},
    {realm: 'SHOP_RENTER', stringKey: 'ORDER_IMPORT_ERROR_REALM_SHOP_RENTER'}
  ];

  export interface GetRequest {
    id: number;
  }

  export interface RejectManuallyRequest {
    id: number;
  }

  export function toPublic(r: OrderImportErrorResource.OrderImportError): OrderImportError {
    return {
      id: r.id,
      state: <OrderImportError.OrderImportErrorState> r.state,
      errorReason: r.error_reason ? <OrderImportError.OrderImportErrorReason> r.error_reason : undefined,
      errorMessage: r.error_message,
      errorStackTrace: r.error_stack_trace ? <OrderImportError.OrderImportErrorReason> r.error_stack_trace : undefined,
      realm: <OrderImportError.OrderImportErrorRealm> r.realm,
      externalId1: r.external_id1,
      externalId2: r.external_id2,
      extraId1: r.extra_id1,
      creationTime: Services.toOffsetDateTime(r.creation_time),
      updateTime: Services.toOffsetDateTime(r.update_time),
      company: {
        id: r.company.id,
        name: r.company.name,
      },
      user: {
        id: r.user.id,
        personName: r.user.person_name
      },
      recipientName: r.recipient_name,
      data1: this.toPublicData(r.data1),
      data2: this.toPublicData(r.data2),
      data3: this.toPublicData(r.data3),
      manualRejectUser: r.manual_reject_user ? {
        id: r.manual_reject_user.id,
        personName: r.manual_reject_user.person_name
      } : undefined,
      manualRejectTime: r.manual_reject_time ? Services.toOffsetDateTime(r.manual_reject_time) : undefined
    }
  }

  export function toPublicData(data?: OrderImportErrorResource.Data): OrderImportError.Data | undefined {
    if (data) {
      return {
        content: data.content,
        contentType: data.content_type
      }
    }
    return undefined;
  }

  export namespace Fields {

    export class OrderImportError {

      readonly id: Query.Field = new DqlQuery.Field('id');
      readonly state: Query.Field = new DqlQuery.Field('state');
      readonly errorReason: Query.Field = new DqlQuery.Field('error_reason');
      readonly errorMessage: Query.Field = new DqlQuery.Field('error_message');
      readonly errorStackTrace: Query.Field = new DqlQuery.Field('error_stack_trace');
      readonly realm: Query.Field = new DqlQuery.Field('realm');
      readonly externalId1: Query.Field = new DqlQuery.Field('external_id1');
      readonly externalId2: Query.Field = new DqlQuery.Field('external_id2');
      readonly extraId1: Query.Field = new DqlQuery.Field('extra_id1');
      readonly creationTime: Query.Field = new DqlQuery.Field('creation_time');
      readonly updateTime: Query.Field = new DqlQuery.Field('update_time');
      readonly company: Query.Field = new DqlQuery.Field('company');
      readonly user: Query.Field = new DqlQuery.Field('user');
      readonly recipientName: Query.Field = new DqlQuery.Field('recipient_name');
      readonly data1: Query.Field = new DqlQuery.Field('data1');
      readonly data2: Query.Field = new DqlQuery.Field('data2');
      readonly data3: Query.Field = new DqlQuery.Field('data3');

      get each(): Set<Query.Field> {
        return Set.of(
          this.id,
          this.state,
          this.errorReason,
          this.errorMessage,
          this.errorStackTrace,
          this.realm,
          this.externalId1,
          this.externalId2,
          this.extraId1,
          this.creationTime,
          this.updateTime,
          this.company,
          this.user,
          this.recipientName,
          this.data1,
          this.data2,
          this.data3,
        );
      }

    }

  }

}

@Injectable()
export class OrderImportErrorService implements OrderImportError.Service {

  private readonly filterField = new FilterField.OrderImportError();
  private readonly orderField = new OrderField.OrderImportError();
  private readonly fields = new OrderImportError.Fields.OrderImportError();

  constructor(private resourceService: OrderImportErrorResourceService) {
  }

  query(request: OrderImportError.QueryRequest): Observable<QueryResult<OrderImportError.OrderImportError>> {
    const filter: string | undefined = DqlQuery.toOptionalFilter(this.filterField, request.filter);
    const order: string | undefined = DqlQuery.toOptionalOrder(this.orderField, request.order);
    const fields: string | undefined = DqlQuery.toOptionalFields(this.fields, request.fields);
    return Observable.create((observer: Observer<QueryResult<OrderImportError.OrderImportError>>) => {
      const resourceRequest: OrderImportErrorResource.QueryRequest = {
        filter: filter,
        order: order,
        fields: fields,
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined
      };
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<OrderImportErrorResource.OrderImportError>) => {
          observer.next({
            items: List.of(...result.items.map((item) => OrderImportError.toPublic(item))),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  get(request: OrderImportError.GetRequest): Observable<OrderImportError.OrderImportError> {
    return Observable.create((observer: Observer<OrderImportError.OrderImportError>) => {
      const resourceRequest: OrderImportErrorResource.GetRequest = {
        id: request.id
      };
      return this.resourceService.get(resourceRequest).subscribe(
        (result: OrderImportErrorResource.OrderImportError) => {
          observer.next(OrderImportError.toPublic(result));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  rejectManually(request: OrderImportError.RejectManuallyRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: OrderImportErrorResource.RejectManuallyRequest = {
        id: request.id
      };
      return this.resourceService.rejectManually(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

}
