import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { GlobalCalendarEvent, GlobalCalendarEventService } from 'src/app/lib/global-calendar/global-calendar-event.service';
import { UiConstants } from '../../../util/core-utils';
import { InputMask } from '../../../util/input-masks';
import { FieldValidationError, ForwardingNgFormRef, LocalFormGroupValidationErrors } from '../../../lib/util/services';
import { AppNgbTimeStruct, NgbDatePickerParserFormatter } from '../../../util/ngb-datepicker';
import { EmptyMessage } from '../../../lib/util/messages';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AppValidators } from '../../../util/app-validators';
import { Models } from '../../../util/model-utils';
import { Dates } from '../../../lib/util/dates';

@Component({
  selector: 'app-global-calendar-event-dialog',
  templateUrl: './global-calendar-event-dialog.component.html',
  styleUrls: ['./global-calendar-event-dialog.component.scss']
})
export class GlobalCalendarEventDialogComponent implements OnInit {

  GlobalCalendarEvent = GlobalCalendarEvent;
  UiConstants = UiConstants;
  InputMask = InputMask;
  ValidatedField = GlobalCalendarEvent.ValidatedField;

  model: GlobalCalendarEventModel = new GlobalCalendarEventModel();

  @ViewChild('f', {static: true})
  form: NgForm;
  formGroup: FormGroup;
  private formGroupValidationErrors: LocalFormGroupValidationErrors;
  private fieldErrors: FieldValidationError<GlobalCalendarEvent.ValidatedField> =
    FieldValidationError.empty<GlobalCalendarEvent.ValidatedField>();

  globalCalendarEvent?: GlobalCalendarEvent.GlobalCalendarEvent;

  get readonly(): boolean {
    return this.data.readonly;
  }

  constructor(public dialogRef: MatDialogRef<GlobalCalendarEventDialogComponent>,
              private translateService: TranslateService,
              private datePickerParserFormatter: NgbDatePickerParserFormatter,
              private fb: FormBuilder,
              private globalCalendarEventService: GlobalCalendarEventService,
              @Inject(MAT_DIALOG_DATA) public data: GlobalCalendarEventDialogData) {
    this.loadModel();
    this.formGroup = this.createFormGroup(this.fb);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(
      this.createForwardingHtmlForm(),
      this.formGroup
    );
  }

  ngOnInit() {
  }

  public static openDialog(
    dialog: MatDialog,
    data: GlobalCalendarEventDialogData,
    resultCallback: (GlobalCalendarEventDialogResult) => void) {
    const dialogRef = dialog.open(GlobalCalendarEventDialogComponent, {
      closeOnNavigation: true,
      data: data,
      width: '60%',
      height: 'auto',
      panelClass: 'custom-dialog-container'
    });
    dialogRef.afterClosed().subscribe((result) => {
      resultCallback(result);
    });
  }

  closeDialog(modified: boolean) {
    this.dialogRef.close({modified: modified});
  }

  deleteEvent() {
    if (!this.globalCalendarEvent || this.readonly || !this.data.allowDelete) {
      return;
    }
    this.globalCalendarEventService.delete({id: this.globalCalendarEvent.id}).subscribe(result => {
      this.closeDialog(true);
    })
  }

  save() {
    if (this.hasLocalFieldError()) {
      return;
    }
    else {
      if (this.data.globalCalendarEventId) {
        this.updateGlobalCalendarEvent();
      }
      else {
        this.createGlobalCalendarEvent();
      }
    }
  }

  hasFieldError(field?: GlobalCalendarEvent.ValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: GlobalCalendarEvent.ValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: GlobalCalendarEvent.ValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  private createForwardingHtmlForm() {
    return new ForwardingNgFormRef({
      formFn: () => {
        return this.form;
      }
    });
  }

  private createFormGroup(fb: FormBuilder): FormGroup {
    return fb.group(
      {
        title: fb.control(
          {
            value: this.model.title,
            disabled: this.readonly
          },
          [Validators.required]
        ),
        startDate: fb.control(
          {
            value: this.model.startDate,
            disabled: this.readonly
          },
          [Validators.required, AppValidators.validateLocalDate]
        ),
        startTime: fb.control(
          {
            value: this.model.startTime,
            disabled: this.readonly
          },
          [Validators.required]
        ),
        endDate: fb.control(
          {
            value: this.model.endDate,
            disabled: this.readonly
          },
          [Validators.required, AppValidators.validateLocalDate]
        ),
        endTime: fb.control(
          {
            value: this.model.endTime,
            disabled: this.readonly
          },
          [Validators.required]
        ),
        comment: fb.control(
          {
            value: this.model.comment,
            disabled: this.readonly
          },
          []
        ),
      },
      {
        validator: AppValidators.validatorChain(
          AppValidators.validateOffsetDateTimeFromTo({
            allowEquality: false,
            fromDate: () => this.model.startDate,
            toDate: () => this.model.endDate,
            fromTime: () => this.model.startTime,
            toTime: () => this.model.endTime,
            datePickerParserFormatter: this.datePickerParserFormatter
          }),
        )
      }
    );
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  hasLocalFormError(errorCode: string): boolean {
    return this.formGroupValidationErrors.hasFormError(errorCode);
  }

  createGlobalCalendarEvent() {
    this.formGroup.updateValueAndValidity();
    if (this.formGroup.invalid) {
      return;
    }
    this.globalCalendarEventService.create({
      title: this.model.title,
      startTime: this.datePickerParserFormatter.toOffsetDateTime(this.model.startDate, this.model.startTime),
      endTime: this.datePickerParserFormatter.toOffsetDateTime(this.model.endDate, this.model.endTime),
      color: this.model.color,
      comment: this.model.comment
    }).subscribe(
      (result) => {
        this.closeDialog(true);
      },
      error => {

      });
  }

  updateGlobalCalendarEvent() {
    this.formGroup.updateValueAndValidity();
    if (this.formGroup.invalid) {
      return;
    }
    this.globalCalendarEventService.update({
      id: this.data.globalCalendarEventId!,
      title: this.model.title,
      startTime: this.datePickerParserFormatter.toOffsetDateTime(this.model.startDate, this.model.startTime),
      endTime: this.datePickerParserFormatter.toOffsetDateTime(this.model.endDate, this.model.endTime),
      color: this.model.color,
      comment: this.model.comment
    }).subscribe(
      (result: EmptyMessage) => {
        this.closeDialog(true);
      },
      error => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error;
        }
      });
  }

  private loadModel() {
    this.model = new GlobalCalendarEventModel();

    if (this.data.globalCalendarEventId) {
      this.globalCalendarEventService.get({
        id: this.data.globalCalendarEventId!,
        fields: f => f.each
      }).subscribe(e => {
        this.model.title = e.title;
        this.model.color = e.color;
        const parsedStart = this.datePickerParserFormatter.fromOffsetDateTime(e.startTime);
        this.model.startDate = parsedStart;
        this.model.startTime = parsedStart;
        const parsedEnd = this.datePickerParserFormatter.fromOffsetDateTime(e.endTime);
        this.model.endDate = parsedEnd;
        this.model.endTime = parsedEnd;
        this.model.comment = Models.optToString(e.comment);
        this.globalCalendarEvent = e;
      });
    }
    else {
      this.model.endTime = {hour: 23, millis: 0, minute: 59, second: 0};
      const now = this.datePickerParserFormatter.fromOffsetDateTime(Dates.now());
      this.model.startDate = now;
      this.model.startTime = now;

      if (this.data.startDate) {
        this.model.startDate = {
          year: this.data.startDate.getUTCFullYear(),
          month: this.data.startDate.getMonth() + 1,
          day: this.data.startDate.getDate()
        };
        this.model.startTime = {
          hour: this.data.startDate.getHours(),
          minute: this.data.startDate.getMinutes(),
          second: this.data.startDate.getSeconds(),
          millis: 0
        };
      }
    }
  }

  getDialogTitle(): string {
    if (this.data.readonly) {
      return 'CALENDAR_EVENT_DETAIL';
    }
    if (this.data.globalCalendarEventId) {
      return 'CALENDAR_EVENT_UPDATE';
    }
    return 'CALENDAR_EVENT_CREATE';
  }
}

class GlobalCalendarEventModel {
  title: string = '';
  startDate: NgbDateStruct | null = null;
  startTime: AppNgbTimeStruct | null = null;
  endDate: NgbDateStruct | null = null;
  endTime: AppNgbTimeStruct | null = null;
  color: number = 0;
  comment: string = '';
}

export interface GlobalCalendarEventDialogData {
  globalCalendarEventId?: number;
  readonly: boolean;
  allowDelete?: boolean;
  startDate?: Date;
}

export interface GlobalCalendarEventDialogResult {
  modified: boolean;
}
