import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ForwardingNgFormRef, LocalFormGroupValidationErrors } from '../../../../lib/util/services';
import { SelectUtils, UiConstants } from '../../../../util/core-utils';
import { Invoice } from '../../../../lib/invoice/invoice/invoice.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { InvoiceSettings } from '../../../../lib/invoice/invoice-settings/invoice-settings.service';
import { AppValidators } from '../../../../util/app-validators';
import { NgbDatePickerParserFormatter } from '../../../../util/ngb-datepicker';
import { EmptyMessage } from '../../../../lib/util/messages';
import { InvoiceSpending, InvoiceSpendingService } from '../../../../lib/invoice/spending/invoice-spending.service';
import { InvoiceSpendingSearch } from '../../../../lib/invoice/spending/invoice-spending-search.service';
import { Set } from 'immutable';
import { InputMask } from '../../../../util/input-masks';
import { Models } from '../../../../util/model-utils';
import { Strings } from '../../../../lib/util/strings';
import SpendingTypeFilterOption = InvoiceSpendingSearch.SpendingTypeFilterOption;
import InvoiceSpendingType = InvoiceSpending.InvoiceSpendingType;
import InvoiceSpendingTypeObject = InvoiceSpending.InvoiceSpendingTypeObject;
import { OffsetDateTime } from '../../../../lib/util/dates';

@Component({
  selector: 'app-invoice-spending-dialog',
  templateUrl: './invoice-spending-dialog.component.html',
  styleUrls: ['./invoice-spending-dialog.component.scss']
})
export class InvoiceSpendingDialogComponent implements OnInit {

  Invoice = Invoice;
  UiConstants = UiConstants;
  InvoiceSettings = InvoiceSettings;
  SelectUtils = SelectUtils;
  InputMask = InputMask;

  model: InvoiceSpendingModel = new InvoiceSpendingModel();

  @ViewChild('f', {static: true})
  form: NgForm;
  formGroup: FormGroup;
  private formGroupValidationErrors: LocalFormGroupValidationErrors;
  spendingTypes: InvoiceSpendingSearch.SpendingTypeFilterOption[] = [];

  inProgress: boolean = false;

  constructor(public dialogRef: MatDialogRef<InvoiceSpendingDialogComponent>,
              private translateService: TranslateService,
              private datePickerParserFormatter: NgbDatePickerParserFormatter,
              private fb: FormBuilder,
              private invoiceSpendingService: InvoiceSpendingService,
              @Inject(MAT_DIALOG_DATA) public data: InvoiceSpendingDialogData) {
    this.loadModel();
    this.loadSpendingTypeOptions();
    this.formGroup = this.createFormGroup(this.fb);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(
      this.createForwardingHtmlForm(),
      this.formGroup
    );
  }

  get readonly(): boolean {
    return this.data.invoiceSpendingId !== undefined;
  }

  ngOnInit() {
  }

  closeDialog(modified: boolean) {
    this.dialogRef.close({modified: modified});
  }

  save() {
    if (this.hasLocalFieldError()) {
      return;
    }
    else {
      this.createSpending();
    }
  }

  hasFieldError(field: InvoiceSpending.ValidatedField): boolean {
    return false;
  }

  removeFieldError(field: InvoiceSpending.ValidatedField) {
  }

  getFieldErrorText(field: InvoiceSpending.ValidatedField): string {
    return '';
  }

  showIconDialog() {

  }

  private createForwardingHtmlForm() {
    return new ForwardingNgFormRef({
      formFn: () => {
        return this.form;
      }
    });
  }

  private createFormGroup(fb: FormBuilder): FormGroup {
    return fb.group(
      {
        eventName: fb.control(
          {value: this.model.eventName, disabled: this.readonly},
          [Validators.required]
        ),
        amount: fb.control(
          {
            value: this.model.amount,
            disabled: this.readonly || this.hasInvoice()
          },
          [AppValidators.tempValidator({
            validator: Validators.required,
            disabled: () => this.hasInvoice()
          })]
        ),
        invoiceSpendingType: fb.control(
          {
            value: this.model.invoiceSpendingType,
            disabled: this.readonly || this.hasInvoice()
          },
          [Validators.required],
        ),
        comment: fb.control(
          {value: this.model.comment, disabled: this.readonly },
          []
        ),
        amountCheck: fb.control(
          {
            value: this.model.amountCheck,
            disabled: this.readonly || !this.hasInvoice()
          },
          [AppValidators.tempValidator({
            validator: AppValidators.validatorChain(
              Validators.required,
              AppValidators.validateNumberEquals({
                equalsTo: () => {
                  return this.invoiceAmount();
                }
              })
            ),
            disabled: () => !this.hasInvoice()
          })]
        )
      }
    );
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  createSpending() {
    this.formGroup.updateValueAndValidity();
    this.formGroup.controls['amountCheck'].updateValueAndValidity();
    if (this.formGroup.invalid) {
      return;
    }
    this.inProgress = true;
    this.invoiceSpendingService.create({
      invoiceIds: this.model.selectedInvoiceIds,
      invoiceSpendingType: this.model.invoiceSpendingType!.id!,
      amount: this.hasInvoice() ? undefined : Models.parseNumber(this.model.amount),
      eventName: this.model.eventName,
      comment: Strings.undefinedOrNonEmpty(this.model.comment),
      currencyCode: this.model.currencyCode
    }).subscribe(
      (result: EmptyMessage) => {
        this.inProgress = false;
        this.closeDialog(true);
      },
      error => {
        this.inProgress = false;
      });
  }

  private loadModel() {
    this.model = new InvoiceSpendingModel();
    if (!this.readonly && this.data.originals && this.data.originals.size > 0) {
      this.model.selectedInvoiceIds = Set.of(...this.data.originals.toArray().map(o => o!.id));
      const invoice = this.data.originals.first();
      let spendingType: SpendingTypeFilterOption | undefined;
      if (invoice.directionType === 'INBOUND') {
        if (invoice.categoryType === 'NORMAL') {
          spendingType = this.mapSpendingType(InvoiceSpending.spendingTypes.find(s => s.spendingType === InvoiceSpendingType.EXPENSE)!);
        }
        else {
          spendingType = this.mapSpendingType(InvoiceSpending.spendingTypes.find(s => s.spendingType === InvoiceSpendingType.REVENUE)!);
        }
      }
      if (invoice.directionType === 'OUTBOUND') {
        if (invoice.categoryType === 'STORNO') {
          spendingType = this.mapSpendingType(InvoiceSpending.spendingTypes.find(s => s.spendingType === InvoiceSpendingType.EXPENSE)!);
        }
        else {
          spendingType = this.mapSpendingType(InvoiceSpending.spendingTypes.find(s => s.spendingType === InvoiceSpendingType.REVENUE)!);
        }
      }
      this.model.invoiceSpendingType = spendingType;
      this.model.amount = '' + this.invoiceAmount()!;
    }

    if (this.readonly) {
      this.invoiceSpendingService.get({id: this.data.invoiceSpendingId!, fields: f => f.each}).subscribe(spending => {
        this.model.eventName = spending.eventName;
        this.model.amount = '' + Math.floor(spending.amount);
        this.model.comment = spending.comment ? spending.comment : '';
        this.model.invoiceSpendingType =
          this.mapSpendingType(InvoiceSpending.spendingTypes.find(s => s.spendingType === spending.invoiceSpendingType)!);
        this.model.currencyCode = spending.currencyCode;
        this.model._invoices = spending.invoices ? spending.invoices.toArray() : [];
        this.model.creationTime = spending.creationTime;
      });
    }
  }

  private loadSpendingTypeOptions() {
    this.spendingTypes = [];
    InvoiceSpending.spendingTypes.forEach((spendingType) => {
      this.spendingTypes.push(this.mapSpendingType(spendingType));
    });
  }

  private mapSpendingType(spendingType: InvoiceSpendingTypeObject) {
    return {
      id: spendingType.spendingType,
      stringKey: spendingType.stringKey
    };
  }

  hasInvoice(): boolean {
    return this.data && this.data.originals !== undefined && this.data.originals.size > 0;
  }

  invoiceAmount(): number | undefined {
    if (!this.data || !this.data.originals || this.data.originals.size === 0) {
      return undefined;
    }
    return this.data.originals.toArray().map(i => i.footer ? Math.abs(Models.decimalToNumber(i.footer.grossSumPrice)!) : 0)
      .reduce((reduction, value) => {
        return reduction + (5 * Math.round(value / 5.0));
      }, 0);
  }
}

class InvoiceSpendingModel {
  comment: string = '';
  selectedInvoiceIds: Set<number>;
  eventName: string = '';
  amount: string  = '';
  amountCheck: string = '';
  invoiceSpendingType?: InvoiceSpendingSearch.SpendingTypeFilterOption;
  currencyCode: string = 'HUF';

  _invoices: Invoice.Invoice[] = [];
  creationTime?: OffsetDateTime;
}

export interface InvoiceSpendingDialogData {
  originals?: Set<Invoice.Invoice>;
  invoiceSpendingId?: number;
}

export interface InvoiceSpendingDialogResult {
  modified: boolean;
}
