/* eslint-disable */
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, } from '@angular/core';
import { Transition, UIRouter } from '@uirouter/angular';
import { combineLatest, Observable, Subject } from 'rxjs';
import { MasterDataRecord, MasterDataRecordService } from '../../../../lib/masterdata/master-data-record.service';
import { MasterData, MasterDataService } from '../../../../lib/masterdata/master-data.service';
import { MasterDataRecordBase } from '../masterdata-record-base/masterdata-record-base-view';
import { MasterDataRecordBaseComponent } from '../masterdata-record-base/masterdata-record-base.component';
import { StateName } from '../../../../app.state-names';
import { FieldValidationError } from '../../../../lib/util/services';
import { FormRecordContainer } from '../../../form/form-record/form-record-container';
import { FormRecordContainerComponent } from '../../../form/form-record/form-record-container.component';
import { Form } from '../../../../lib/form/form.service';
import { NgForm } from '@angular/forms';
import { ConfigurationService } from '../../../../lib/core-ext/configuration.service';
import { LoadingHandler } from '../../../../lib/loading-handler';
import { StringKey } from '../../../../app.string-keys';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '../../../../fork/angular2-toaster/angular2-toaster';
import { UiConstants } from '../../../../util/core-utils';
import { BreadcrumbParent } from '../../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmLeaveModalComponent } from '../../../../shared/confirm-leave-modal/confirm-leave-modal.component';
import { Strings } from '../../../../lib/util/strings';
/* eslint-enable */

@Component({
  selector: 'app-masterdata-record-clone',
  templateUrl: 'masterdata-record-clone.component.html',
  styleUrls: ['masterdata-record-clone.component.scss'],
})
export class MasterDataRecordCloneComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MasterDataRecordBaseComponent, { static: true })
  baseView: MasterDataRecordBase.View;

  @ViewChild(FormRecordContainerComponent, { static: true })
  formRecordContainerView: FormRecordContainer.View;

  @ViewChild('f', { static: true })
  fForm: NgForm;

  forceSaveButton: boolean = false;

  private masterDataId: number;
  private masterDataRecordId: number;
  private combinedResult?: CombinedResult;
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  masterdataName: string;

  deregisterWarningModal;
  saveButtonClicked: boolean = false;
  navigateOnSubmit: boolean = false;

  constructor(
    private toasterService: ToasterService,
    private translateService: TranslateService,
    private modalService: BsModalService,
    private configurationService: ConfigurationService,
    private masterDataService: MasterDataService,
    private masterDataRecordService: MasterDataRecordService,
    private uiRouter: UIRouter,
    private transition: Transition) {
    this.masterDataId = this.transition.params().masterDataId;
    this.masterDataRecordId = this.transition.params().masterDataRecordId;
    this.translateService.get('MASTERDATA_RECORD_PANEL_HEADING_CLONE').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
  }

  ngOnInit() {
    const context = this;
    this.deregisterWarningModal = this.uiRouter.transitionService.onStart({}, function(transition) {
      if (context.saveButtonClicked
        || transition.targetState().name() === StateName.CONNECTION_ERROR
        || transition.targetState().name() === StateName.SERVER_ERROR
        || transition.targetState().name() === StateName.LOGIN) {
        return true;
      }
      else {
        const subject = new Subject<boolean>();
        const modal = context.modalService.show(ConfirmLeaveModalComponent, {backdrop: 'static',  keyboard: false});
        modal.content!.subject = subject;
        return subject.asObservable().toPromise();
      }
    });
  }

  ngAfterViewInit(): void {
    this.createCombinedObservable().subscribe(
      (result: CombinedResult) => {
        this.masterdataName = result.masterData.name;
        this.baseView.loadMasterDataRecord({
          masterDataRecord: result.masterDataRecord
        });
        this.formRecordContainerView.loadFormRecord({
          configuration: this.configurationService.getConfiguration(),
          form: result.form,
          record: result.masterDataRecord.formRecord,
        });
        this.combinedResult = result;
        this.forceSaveButton = !Form.Forms.hasVisibleGroup(result.form);
      },
      () => {},
      () => {
        this.translateService.get('MENU_NAVBAR_MASTERDATA_RECORD').subscribe(
          (result: string) => {
            this.breadcrumbParents.push({name: result, uiSref: StateName.MASTERDATA_DASHBOARD});
          }
        );
        this.breadcrumbParents.push({
          name: this.masterdataName,
          uiSref: StateName.MASTERDATA_RECORD_LIST,
          uiParam: {id: this.masterDataId}
        });
      }
    );
  }

  createMasterDataRecord() {
    this.baseView.getFormGroup().get('ownerCustomer')!.updateValueAndValidity();
    if (!this.combinedResult) {
      return;
    }
    if (this.baseView.hasLocalFieldError() || this.formRecordContainerView.hasLocalFieldError()) {
      this.showValidationErrorToast();
      return;
    }
    if (this.formRecordContainerView.validateWithInterrupt()) {
      return;
    }
    const model = this.baseView.getModel();
    const formModel = this.formRecordContainerView.createModel();
    this.masterDataRecordService.create({
      withFormRecord: false,
      masterDataId: this.masterDataId,
      name: Strings.undefinedOrNonEmpty(model.name),
      externalId: Strings.undefinedOrNonEmpty(model.externalId),
      description: model.description,
      ownerUserIds: model.userIds,
      ownerGroupIds: model.userGroupIds,
      ownerCustomerRecordId: model.ownerCustomerRecord ? model.ownerCustomerRecord.id : undefined,
      ownerContactLocationId: model.ownerContactLocation ? model.ownerContactLocation.id : undefined,
      formRecord: {
        fields: formModel.fields
      }
    }).subscribe(
      (masterDataRecord: MasterDataRecord.MasterDataRecord) => {
        LoadingHandler.getInstance().refresh();
        this.saveButtonClicked = true;
        if (this.navigateOnSubmit) {
          this.uiRouter.stateService.go(StateName.MASTERDATA_RECORD_LIST, {
            id: masterDataRecord.masterDataId
          });
        }
        else {
          this.uiRouter.stateService.go(
            StateName.MASTERDATA_RECORD_EDIT, {
              masterDataId: masterDataRecord.masterDataId,
              masterDataRecordId: masterDataRecord.masterDataRecordId
            });
        }
      },
      (error) => {
        if (error instanceof FieldValidationError) {
          this.baseView.onFieldValidationError(error.withForm(this.fForm));
          this.showValidationErrorToast();
        }
      }
    );
  }

  private createCombinedObservable(): Observable<CombinedResult> {
    return combineLatest(
      this.masterDataService.get({
        masterDataId: this.masterDataId,
        withForm: true
      }),
      this.masterDataRecordService.get({
        withFormRecord: true,
        masterDataId: this.masterDataId,
        masterDataRecordId: this.masterDataRecordId
      }),
      (
        masterData: MasterData.MasterData,
        masterDataRecord: MasterDataRecord.MasterDataRecord) => {
        return {
          form: masterData.form!,
          masterData: masterData,
          masterDataRecord: masterDataRecord
        }
      }
    );
  }

  private showValidationErrorToast() {
    this.toasterService.pop({
      timeout: UiConstants.ToastTimeoutLong,
      type: UiConstants.toastTypeError,
      title: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_TITLE),
      body: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_MESSAGE)
    });
  }

  submit(navigateOnSubmit: boolean) {
    this.navigateOnSubmit = navigateOnSubmit;
  }

  ngOnDestroy() {
    this.deregisterWarningModal();
  }

}

interface CombinedResult {
  form: Form.Form;
  masterData: MasterData.MasterData;
  masterDataRecord: MasterDataRecord.MasterDataRecord;
}
