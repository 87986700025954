/* eslint-disable */
import { Component, OnInit } from '@angular/core';
import { Shipment, ShipmentGroup, ShipmentGroupService } from '../../../../lib/shipment-group/shipment-group.service';
import { MultiselectOptionItem, QueryFieldModel } from '../../../../util/core-utils';
import { OrderType, QueryResult } from '../../../../lib/util/services';
import { Set } from 'immutable';
import { ShippingDemand } from '../../../../lib/shipping-demand/shipping-demand.service';
import { Address } from '../../../../lib/address';
import { ConfigModel } from '../../../../util/task-record-utils';
import { ConfigurationService } from '../../../../lib/core-ext/configuration.service';
import { TranslateService } from '@ngx-translate/core';
import { RightModel } from '../../../../app.rights';
import { RightResolver, RightService } from '../../../../lib/right.service';
import ShipmentGroupState = ShipmentGroup.ShipmentGroupState;
import { TransportShipmentGroupModel, TransportShipmentModel } from '../../../shipment/shipment-group-list/shipment-group-list.component';
import ShipmentState = Shipment.ShipmentState;
import * as moment from 'moment';
import { ShipmentRightModel } from '../../../../app.rights.shpiment';
import { UserService } from '../../../../lib/user.service';
/* eslint-enable */

@Component({
  selector: 'app-anysped-dashboard-demand',
  templateUrl: './anysped-dashboard-shipment.component.html',
  styleUrls: ['./anysped-dashboard-shipment.component.scss']
})
export class AnyspedDashboardShipmentComponent implements OnInit {

  pagingId = 'DashboardShipmentList';

  ShipmentGroup = ShipmentGroup;

  queryModel: QueryFieldModel<ShipmentGroup.OrderField> = new QueryFieldModel(ShipmentGroup.OrderField.ID, OrderType.DESC);

  config: ConfigModel = new ConfigModel();
  shipmentGroupStates: MultiselectOptionItem<ShipmentGroupState>[] = [];
  shipmentStates: MultiselectOptionItem<ShipmentState>[] = [];

  shipmentGroupList: TransportShipmentGroupModel[] = [];

  showShipment: boolean = false;

  rightModel: RightModel = RightModel.empty();
  shipmentRightModel: ShipmentRightModel = ShipmentRightModel.empty();

  constructor(private shipmentGroupService: ShipmentGroupService,
              private configurationService: ConfigurationService,
              private rightService: RightService,
              private userService: UserService,
              private translateService: TranslateService) {
  }

  ngOnInit() {
    this.loadRightModels(() => {
      this.loadConfig(() => {
        this.loadStates(() => {
          this.loadList();
        });
      });
    });
  }

  private loadRightModels(completion: () => void) {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.userService.get({
          id: resolver.userProfile!.id
        }).subscribe((user) => {
          this.rightModel = RightModel.of(resolver);
          this.shipmentRightModel = ShipmentRightModel.of({
            rightModel: this.rightModel,
            currentCompanies: user.companies.map(c => c.id)
          });
          completion();
        });
      }
    );

  }

  private loadConfig(completion: () => void) {
    this.config = this.configurationService.getConfigurationModel();
    completion();
  }

  private loadStates(completion: () => void) {
    this.shipmentGroupStates = [];
    this.shipmentStates = [];
    ShipmentGroup.shipmentGroupStates.forEach(
      (state) => {
        const item = {
          id: state.state,
          itemName: state.stringKey
        };
        this.shipmentGroupStates.push(item);
      });
    this.shipmentGroupStates.forEach(
      (state: MultiselectOptionItem<ShipmentGroupState>) => {
        this.translateService.get(state.itemName).subscribe((stateName: string) => {
            state.itemName = stateName;
          }
        )
      });
    Shipment.shipmentStates.forEach(
      (state) => {
        const item = {
          id: state.state,
          itemName: state.stringKey
        };
        this.shipmentStates.push(item);
      });
    this.shipmentStates.forEach(
      (state: MultiselectOptionItem<ShipmentState>) => {
        this.translateService.get(state.itemName).subscribe((stateName: string) => {
            state.itemName = stateName;
          }
        )
      });
    completion();
  }

  private loadList(pageNumber?: number) {
    if (this.rightModel.shipmentGroupRead.hasRight()) {
      const stateIdSet: ShipmentGroupState[] = [];
      stateIdSet.push('OPEN');
      stateIdSet.push('IN_PROGRESS');

      const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
      const order = this.queryModel.getOrder();
      this.shipmentGroupService.globalQuery({
        state: stateIdSet.length > 0 ? Set.of(...stateIdSet) : undefined,
        delayed: true,
        orders: Set.of(order),
        paging: requestedPage ? {
          pageNumber: requestedPage,
          numberOfItems: this.queryModel.itemsPerPage
        } : undefined
      }).subscribe((result: QueryResult<ShipmentGroup.ShipmentGroup>) => {
        this.shipmentGroupList = [];
        result.items.forEach((sg: ShipmentGroup.ShipmentGroup) => {
          const group = new TransportShipmentGroupModel();
          group.id = sg.id;
          group.deliveryNoteNumber = sg.deliveryNote.number;
          group.customer = sg.demander ? sg.demander.company ? sg.demander.company.name : '' : '';
          group.sourceAddress = this.unwrapAddress(sg.source);
          group.destinationAddress = this.unwrapAddress(sg.destination);
          group.transporterCompany = sg.transporter;
          group.state = this.shipmentGroupStates.find(state => state.id === sg.state)!.itemName;
          group.stateObject = ShipmentGroup.shipmentGroupStates.find(state => state.state === sg.state)!
          group.delay = sg.shipments.first().delay ? sg.shipments.first().delay!.delayMinutes : 0;
          sg.shipments.forEach((s: Shipment.Shipment) => {
            const shipment = new TransportShipmentModel();
            shipment.id = s.id;
            shipment.deliveryNoteNumber = s.deliveryNote.number;
            shipment.waybillNumber = s.transport ? s.transport.waybillNumber : '';
            shipment.customer = group.customer;
            shipment.sourceAddress = this.unwrapAddress(s.source);
            shipment.destinationAddress = this.unwrapAddress(s.destination);
            shipment.state = this.shipmentStates.find(state => state.id === s.state)!.itemName;
            shipment.stateObject = Shipment.shipmentStates.find(state => state.state === s.state)!;
            group.shipments.push(shipment);
          });
          this.shipmentGroupList.push(group);
        });

        if (this.shipmentGroupList.length > 0) {
          this.showShipment = true;
        }
        this.queryModel.currentPage = requestedPage;
        this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
        this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
      });
    }
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: ShipmentGroup.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  unwrapAddress(shippingPlace: ShippingDemand.ShippingPlace): string {
    if (shippingPlace.place && shippingPlace.place.postalAddress) {
      return Address.PostalAddressMapper.toString(shippingPlace.place.postalAddress, this.config.postalAddressFormat);
    }
    else if (shippingPlace.location && shippingPlace.location.place && shippingPlace.location.place.postalAddress) {
      return Address.PostalAddressMapper.toString(shippingPlace.location.place.postalAddress, this.config.postalAddressFormat);
    }
    else {
      return '';
    }
  }

  canEditGroup(shipmentGroup: TransportShipmentGroupModel) {
    return shipmentGroup.stateObject.state !== 'DELIVERED' && this.shipmentRightModel.shipmentGroupDemandRemove
        .hasRightForShipment({transporterId: shipmentGroup.transporterCompany.id}, undefined)
      || this.shipmentRightModel.shipmentGroupDepotUpdate
        .hasRightForShipment({transporterId: shipmentGroup.transporterCompany.id}, undefined);
  }

  getDelay(delayMins?: number): moment.Duration | undefined {
    return delayMins && delayMins > 0 ? moment.duration(delayMins, 'minutes') : undefined;
  }

  getDelayIcon(delay: number): string {
    if (delay > 0) {
      return 'icomoon icomoon-transport-delay';
    }
    return '';
  }

}
