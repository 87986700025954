/* eslint-disable */
import { Directive, ViewContainerRef } from '@angular/core';
/* eslint-enable */

@Directive({
  selector: '[appDqlDialogValueHolder]'
})
export class DqlDialogValueHolderDirective {

  constructor(public viewContainerRef: ViewContainerRef) {
  }

}
