<app-breadcrumb-menu>
  <!-- region CLONE-->
  <app-breadcrumb-button
    *ngIf="masterdataRecord && allowClone"
    [iconClass]="'icomoon-clone'"
    [titleStringKey]="'MASTER_DATA_RECORD_CLONE_TITLE'"
    (click)="cloneMasterData()">
  </app-breadcrumb-button>
  <!-- endregion CLONE-->
  <!-- region EDIT-->
  <app-breadcrumb-button
    *ngIf="masterdataRecord && allowEdit"
    [iconClass]="'icomoon-modify'"
    [titleStringKey]="'MASTERDATA_RECORD_PANEL_HEADING_UPDATE'"
    (click)="editMasterData()">
  </app-breadcrumb-button>
  <!-- endregion EDIT-->
</app-breadcrumb-menu>
