<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-custom-padding animated fadeIn">
  <div class="row ml-0 mr-0">
    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12 card-custom-padding"
         *ngIf="rightModel.documentFileRead.hasRight()">
      <div class="card card-top-color cursor-pointer"
           uiSref="Admin.DocumentFileList">
        <div>
          <div class="thumbnail-background-file"></div>
        </div>
        <div class="card-body">
          <div class="h4 mb-0">{{documentFileCount}}</div>
          <small class="text-uppercase font-weight-bold">{{'MENU_NAVBAR_DOCUMENT_FILE' |
            translate}}</small>
          <br>
        </div>
      </div>
      <div class="container-vertical-padding-half"></div>
    </div>
    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12 card-custom-padding"
         *ngIf="rightModel.documentLinkRead.hasRight()">
      <div class="card card-top-color cursor-pointer"
           uiSref="Admin.DocumentLinkList">
        <div>
          <div class="thumbnail-background-link"></div>
        </div>
        <div class="card-body">
          <div class="h4 mb-0">{{documentLinkCount}}</div>
          <small class="text-uppercase font-weight-bold">{{'MENU_NAVBAR_DOCUMENT_LINK' |
            translate}}</small>
          <br>
        </div>
      </div>
      <div class="container-vertical-padding-half"></div>
    </div>
  </div>
</div>
