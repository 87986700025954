<div bsModal [config]="UiConstants.modalConfig" #transportLogDetail="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="transportLogDetailVisible">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title"><strong>{{'TRANSPORT_HISTORY' | translate}}</strong></p>
        <button type="button" class="close" (click)="hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!--ID-->
        <div class="row" *ngIf="transportLogDetailModel.id !== ''">
          <div class="form-group col-md-4 mb-0">
            <label readonly
                   class="col-form-label form-control-label detail-title">{{'TRANSPORT_LOG_DETAIL_ID'|translate}}</label>
          </div>
          <div class="form-group col-md-8 mb-0">
            <label readonly class="col-form-label form-control-label detail-description"> {{ transportLogDetailModel.id
              }} </label>
          </div>
        </div>
        <!--CreationTime-->
        <div class="row" *ngIf="transportLogDetailModel.creationTime">
          <div class="form-group col-md-4 mb-0">
            <label readonly
                   class="col-form-label form-control-label detail-title">{{'TRANSPORT_TIME'|translate}}</label>
          </div>
          <div class="form-group col-md-8 mb-0">
            <label readonly class="col-form-label form-control-label detail-description"> {{
              transportLogDetailModel.creationTime.toUtcIsoString() | date:'short'
              }} </label>
          </div>
        </div>
        <!--User-->
        <div class="row" *ngIf="transportLogDetailModel.user !== ''">
          <div class="form-group col-md-4 mb-0">
            <label readonly
                   class="col-form-label form-control-label detail-title">{{'TRANSPORT_USER'|translate}}</label>
          </div>
          <div class="form-group col-md-8 mb-0">
            <label readonly class="col-form-label form-control-label detail-description"> {{
              transportLogDetailModel.user
              }} </label>
          </div>
        </div>
        <!--Procurer-->
        <div class="row" *ngIf="transportLogDetailModel.procurer !== ''">
          <div class="form-group col-md-4 mb-0">
            <label readonly
                   class="col-form-label form-control-label detail-title">{{'TRANSPORT_CUSTOMER'|translate}}</label>
          </div>
          <div class="form-group col-md-8 mb-0">
            <label readonly class="col-form-label form-control-label detail-description"> {{
              transportLogDetailModel.procurer
              }} </label>
          </div>
        </div>
        <!--ShipmentExternalId-->
        <div class="row" *ngIf="transportLogDetailModel.shipmentExternalId !== ''">
          <div class="form-group col-md-4 mb-0">
            <label readonly
                   class="col-form-label form-control-label detail-title">{{'TRANSPORT_SHIPMENT_DELIVERY_NOTE_NUMBER'|translate}}</label>
          </div>
          <div class="form-group col-md-8 mb-0">
            <label readonly class="col-form-label form-control-label detail-description"> {{
              transportLogDetailModel.shipmentExternalId
              }} </label>
          </div>
        </div>
        <!--EventType-->
        <div class="row" *ngIf="transportLogDetailModel.eventType !== ''">
          <div class="form-group col-md-4 mb-0">
            <label readonly
                   class="col-form-label form-control-label detail-title">{{'TRANSPORT_LOG_EVENT_TYPE'|translate}}</label>
          </div>
          <div class="form-group col-md-8 mb-0">
            <label readonly class="col-form-label form-control-label detail-description"> {{
              transportLogDetailModel.eventType | translate
              }} </label>
          </div>
        </div>
        <!--Coordinate-->
        <div class="row" *ngIf="transportLogDetailModel.gpsCoordinate !== ''">
          <div class="form-group col-md-4 mb-0">
            <label readonly
                   class="col-form-label form-control-label detail-title">{{'TRANSPORT_GPS_COORDINATE'|translate}}</label>
          </div>
          <div class="form-group col-md-8 mb-0">
            <label readonly class="col-form-label form-control-label detail-description"> {{
              transportLogDetailModel.gpsCoordinate
              }} </label>
          </div>
        </div>
        <!--GateKeeperName-->
        <div class="row" *ngIf="transportLogDetailModel.gateKeeperName !== ''">
          <div class="form-group col-md-4 mb-0">
            <label readonly
                   class="col-form-label form-control-label detail-title">{{'TRANSPORT_LOG_GATE_KEEPER_NAME'|translate}}</label>
          </div>
          <div class="form-group col-md-8 mb-0">
            <label readonly class="col-form-label form-control-label detail-description"> {{
              transportLogDetailModel.gateKeeperName
              }} </label>
          </div>
        </div>
        <!--UserGroup-->
        <div class="row" *ngIf="transportLogDetailModel.userGroup !== ''">
          <div class="form-group col-md-4 mb-0">
            <label readonly
                   class="col-form-label form-control-label detail-title">{{'TRANSPORT_LOG_USER_GROUP'|translate}}</label>
          </div>
          <div class="form-group col-md-8 mb-0">
            <label readonly class="col-form-label form-control-label detail-description"> {{
              transportLogDetailModel.userGroup
              }} </label>
          </div>
        </div>
        <!--GateKeeperExternalId-->
        <div class="row" *ngIf="transportLogDetailModel.gateKeeperExternalId !== ''">
          <div class="form-group col-md-4 mb-0">
            <label readonly
                   class="col-form-label form-control-label detail-title">{{'TRANSPORT_LOG_GATE_KEEPER_EXTERNAL_ID'|translate}}</label>
          </div>
          <div class="form-group col-md-8 mb-0">
            <label readonly class="col-form-label form-control-label detail-description"> {{
              transportLogDetailModel.gateKeeperExternalId
              }} </label>
          </div>
        </div>
        <!--TransportTaskIndex-->
        <div class="row" *ngIf="transportLogDetailModel.transportTaskIndex !== ''">
          <div class="form-group col-md-4 mb-0">
            <label readonly
                   class="col-form-label form-control-label detail-title">{{'TRANSPORT_LOG_TRANSPORT_TASK_INDEX'|translate}}</label>
          </div>
          <div class="form-group col-md-8 mb-0">
            <label readonly class="col-form-label form-control-label detail-description"> {{
              transportLogDetailModel.transportTaskIndex
              }} </label>
          </div>
        </div>
        <!--TransportTaskName-->
        <div class="row" *ngIf="transportLogDetailModel.transportTaskName !== ''">
          <div class="form-group col-md-4 mb-0">
            <label readonly
                   class="col-form-label form-control-label detail-title">{{'TRANSPORT_LOG_TRANSPORT_TASK_NAME'|translate}}</label>
          </div>
          <div class="form-group col-md-8 mb-0">
            <label readonly class="col-form-label form-control-label detail-description"> {{
              transportLogDetailModel.transportTaskName
              }} </label>
          </div>
        </div>
        <!--TransportTaskAddress-->
        <div class="row" *ngIf="transportLogDetailModel.transportTaskAddress !== ''">
          <div class="form-group col-md-4 mb-0">
            <label readonly
                   class="col-form-label form-control-label detail-title">{{'TRANSPORT_LOG_TRANSPORT_TASK_ADDRESS'|translate}}</label>
          </div>
          <div class="form-group col-md-8 mb-0">
            <label readonly class="col-form-label form-control-label detail-description"> {{
              transportLogDetailModel.transportTaskAddress
              }} </label>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <button type="button" class="btn btn-outline-primary" (click)="hide()">
          {{'COMMON_BUTTON_CLOSE' | translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
