/* eslint-disable */
import { Dates, LocalDate, } from '../util/dates';
import { Services, } from '../util/services';
import { Injectable } from '@angular/core';
import { MultiselectOptionItem, UiConstants } from '../../util/core-utils';
import { BaseSearch, BaseSearchService } from '../base.search-service';
import { ColonnadeClientDocumentStatSearchSearchScope } from '../../admin/client-document/colonnade-client-document-stat/colonnade-client-document-stat-search/colonnade-client-document-stat-search.component';
import { LoggedInUserStorage } from '../util/storages';
/* eslint-enable */

@Injectable()
export class ColonnadeClientDocumentStatSearchService extends BaseSearchService {

  protected createStorage(): ColonnadeClientDocumentStatSearch.Storage {
    return new ColonnadeClientDocumentStatSearch.Storage();
  }

}

export namespace ColonnadeClientDocumentStatSearch {

  export interface SearchDataGetRequest extends BaseSearch.SearchDataGetRequest {
    scope: ColonnadeClientDocumentStatSearchSearchScope;
  }

  export interface SearchDataSetRequest extends BaseSearch.SearchDataSetRequest {
    scope: ColonnadeClientDocumentStatSearchSearchScope;
    searchData: SearchData;
  }

  export interface SearchDataResult extends BaseSearch.SearchDataResult {
    searchData: SearchData;
  }

  export interface SearchData extends BaseSearch.SearchData {
    user: MultiselectOptionItem<number>[];
    branchCode: MultiselectOptionItem<string>[];
    sponsorCode: MultiselectOptionItem<string>[];
    dateFrom: LocalDate;
    dateTo: LocalDate;
  }

  interface StoredSearchRawData extends BaseSearch.StoredSearchRawData {
    itemsPerPage: number;
    pageNumber: number;
    user: MultiselectOptionItem<number>[];
    branchCode: MultiselectOptionItem<string>[];
    sponsorCode: MultiselectOptionItem<string>[];
    dateFrom?: string;
    dateTo?: string;
  }

  export class Storage extends BaseSearch.Storage {

    constructor() {
      const defaultSearchData = {
        itemsPerPage: UiConstants.pageNumbers[0],
        pageNumber: 1,
        user: [],
        branchCode: [],
        sponsorCode: [],
        dateFrom: Services.toLocalDate(Dates.now().minusDays(1).toUtcIsoString()),
        dateTo: Services.toLocalDate(Dates.now().minusDays(1).toUtcIsoString())
      };
      /**
       * Increment this version if you change the SearchData interface.
       */
      const versionNumber: number = 1;
      super(defaultSearchData,
        versionNumber);
    }

    protected generateKey(request: SearchDataGetRequest | SearchDataSetRequest): string {
      return 'UserId(' + LoggedInUserStorage.getInstance().getUserId()
        + ')-Search-ColonnadeClientDocumentStat-Scope(' + request.scope + ')';
    }

    protected fromRaw(data: StoredSearchRawData): BaseSearch.SearchDataResult {
      const searchData: SearchData = {
        itemsPerPage: data.itemsPerPage,
        pageNumber: data.pageNumber,
        user: data.user,
        branchCode: data.branchCode,
        sponsorCode: data.sponsorCode,
        dateFrom: Services.toLocalDate(data.dateFrom),
        dateTo: Services.toLocalDate(data.dateTo),
      };
      return {
        searchData: searchData
      };
    }

    protected toRaw(data: SearchData): StoredSearchRawData {
      return {
        version: this.VERSION,
        itemsPerPage: data.itemsPerPage,
        pageNumber: data.pageNumber,
        user: data.user,
        branchCode: data.branchCode,
        sponsorCode: data.sponsorCode,
        dateFrom: Services.localDateToString(data.dateFrom),
        dateTo: Services.localDateToString(data.dateTo),
      };
    }

  }
}

