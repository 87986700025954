<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div class="breadcrumb-menu d-flex">
    <div *ngIf="ldapEnabled && (rightModel.userLdapSync.hasRight() || demoModeEnabled)"
         (click)="rightModel.userLdapSync.hasRight() ? showLdapImportDialog() : showDisabledDialog()"
         class="breadcrumb-button-icon-container cursor-pointer">
      <div class="breadcrumb-button-disabled-overlay" *ngIf="demoModeEnabled && !rightModel.userLdapSync.hasRight()"></div>
      <a class="btn-setting" title="{{'USER_PANEL_HEADING_LDAP_IMPORT' | translate}}">
        <i class="icomoon icomoon-csv-import"></i>
        <span class="breadcrumb-button-text-visible">{{'USER_PANEL_HEADING_LDAP_IMPORT' | translate}}</span>
      </a>
    </div>
    <!--XLSX EXPORT-->
    <div
      *ngIf="rightModel.userExportFile.hasRight()"
      class="breadcrumb-button-icon-container cursor-pointer"
      (click)="exportXls()">
      <a class="btn-setting d-flex align-items-center" title="{{'COMMON_EXPORT_XLSX' | translate}}">
        <i class="icomoon icomoon-file-xlsx"></i>
        <span class="breadcrumb-button-text-visible">{{'COMMON_EXPORT_XLSX' | translate}}</span>
      </a>
    </div>
    <!--DROPDOWN FOR XLS EXPORT-->
    <div class="breadcrumb-button-icon-container-no-text cursor-pointer p-0"
         *ngIf="rightModel.userImportFile.hasRight()">
      <a class="dropdown dropdown-arrow-padding"
         dropdown title="{{'COMMON_EXPORT_XLSX' | translate}}"
         href="#" onclick="return false" dropdownToggle
         data-toggle="dropdown" role="button"
         aria-haspopup="true" aria-expanded="false">
        <i class="icomoon icomoon-dropdown">
        </i>
        <div class="dropdown-menu dropdown-menu-right"
             *dropdownMenu
             aria-labelledby="simple-dropdown">
          <span (click)="exportXlsTemplate()"
                class="btn-setting dropdown-item d-flex align-items-center"
                title="{{'ORDER_XLS_IMPORT_SAMPLE_DOWNLOAD' | translate}}">
            <i class="icomoon icomoon-file-xlsx"></i>
            <span>{{'ORDER_XLS_IMPORT_SAMPLE_DOWNLOAD' | translate}}</span>
          </span>
        </div>
      </a>
    </div>
    <div *ngIf="userRightModel.userCreate.hasRight() || demoModeEnabled"
         [uiSref]="userRightModel.userCreate.hasRight() ? 'Admin.UserCreate' : undefined"
         (click)="!userRightModel.userCreate.hasRight() && showDisabledDialog() "
         class="breadcrumb-button-icon-container cursor-pointer">
      <div class="breadcrumb-button-disabled-overlay" *ngIf="demoModeEnabled && !rightModel.userCreate.hasRight()"></div>
      <a class="btn-setting" title="{{'USER_PANEL_HEADING_ADD' | translate}}">
        <i class="icomoon icomoon-add"></i>
        <span class="breadcrumb-button-text-visible">{{'USER_PANEL_HEADING_ADD' | translate}}</span>
      </a>
    </div>
    <!--DROPDOWN FOR CUSTOMER RECORD CREATE-->
    <div *ngIf="rightModel.userImportFile.hasRight()"
         class="breadcrumb-button-icon-container-no-text cursor-pointer p-0 negative-margin-right">
      <a class="dropdown dropdown-arrow-padding"
         dropdown title="{{'COMMON_BUTTON_ADD' | translate}}"
         href="#" onclick="return false" dropdownToggle
         data-toggle="dropdown" role="button"
         aria-haspopup="true" aria-expanded="false">
        <i class="icomoon icomoon-dropdown">
        </i>
        <div class="dropdown-menu dropdown-menu-right"
             *dropdownMenu
             aria-labelledby="simple-dropdown">
                <span *ngIf="rightModel.userImportFile.hasRight()" (click)="importDialog.showDialog()"
                class="btn-setting dropdown-item d-flex align-items-center"
                title="{{'COMMON_BUTTON_IMPORT' | translate}}">
                  <i class="icomoon icomoon-csv-import"></i>
                  <span>{{'COMMON_BUTTON_IMPORT' | translate}}</span>
                </span>
        </div>
      </a>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="card">

    <div class="card-header">
      {{'COMMON_USERS' | translate}}

      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()"
           title="{{'COMMON_SHOW_SEARCH_HINT' | translate}}"><i #searchIcon
                                                                class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>

    <div class="card-body border-bottom" *ngIf="showSearch">
      <div class="row form-group">
        <div class="col-md-2">
          <label class="search-label">{{'USER_SEARCH_FIELD_ID' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'USER_SEARCH_FIELD_ID' | translate}}"
                 maxlength="{{UiConstants.maxInputLongLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [textMask]="{mask: InputMask.NATURAL_INTEGER, guide: false}"
                 [(ngModel)]="searchModel.id" name="id" id="id" #id="ngModel">
        </div>
        <div class="col-md-2">
          <label class="search-label">{{'USER_SEARCH_FIELD_USERNAME' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'USER_SEARCH_FIELD_USERNAME' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.username" name="username" id="username" #username="ngModel">
        </div>
        <div class="col-md-2">
          <label class="search-label">{{'USER_SEARCH_FIELD_PERSON_NAME' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'USER_SEARCH_FIELD_PERSON_NAME' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.personName" name="personName" id="personName" #personName="ngModel">
        </div>
        <div class="col-md-2">
          <label class="search-label">{{'USER_SEARCH_FIELD_EXTERNAL_ID' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'USER_SEARCH_FIELD_EXTERNAL_ID' | translate}}"
                 maxlength="{{UiConstants.maxInputLongLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.externalId" name="externalId" id="externalId" #externalId="ngModel">
        </div>
        <div class="col-md-2 hidden-sm-down">
          <label class="search-label">{{'COMMON_EMAIL_ADDRESS' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'COMMON_EMAIL_ADDRESS' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.emailAddress" name="emailAddress" id="emailAddress" #emailAddress="ngModel">
        </div>
        <div class="col-md-2">
          <label class="search-label">{{'USER_SEARCH_FIELD_DISABLED' | translate}}</label>
          <select class="form-control" [(ngModel)]="searchModel.disabled"
                  id="disabled" name="disabled" #disabled="ngModel">
            <option *ngFor="let item of userDisabledItems" [ngValue]="item.id">{{item.text}}</option>
          </select>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-2">
          <label class="search-label">{{'COMMON_TYPE' | translate}}</label>
          <select class="form-control" [(ngModel)]="searchModel.type"
                  id="type" name="type" #type="ngModel">
            <option *ngFor="let item of userTypes" [ngValue]="item.id">{{item.text}}</option>
          </select>
        </div>
        <div class="col-md-4">
          <label class="search-label">{{'COMMON_COMPANY' | translate}}</label>
          <angular2-multiselect [data]="companies" [settings]="dropdownSettings"
                                class="form-control"
                                [(ngModel)]="searchModel.companies"
                                (onRemoteSearch)="loadCompaniesForSearch($event.target.value)"
                                #companyMulti="ngModel" name="companyMulti" id="companyMulti">
          </angular2-multiselect>
        </div>
        <div class="col-md-4">
          <label class="search-label">{{'COMMON_USER_GROUP'|translate}}</label>
          <angular2-multiselect
            [(ngModel)]="searchModel.userGroups"
            [ngModelOptions]="{standalone: true}"
            (onRemoteSearch)="loadUserGroupsForSearch($event.target.value)"
            [data]="userGroupsForSearch"
            [settings]="dropdownSettings">
          </angular2-multiselect>
        </div>
        <div class="col-md-2 d-flex align-items-end ">
          <div class="row">
            <app-animated-checkbox class="search-checkbox"
                                   [(selected)]="searchModel.isDriver">
            </app-animated-checkbox>
            <label class="search-label">{{'USER_SEARCH_FIELD_IS_DRIVER' | translate}}</label>
          </div>
        </div>

      </div>
      <div class="col-md-12 d-flex justify-content-md-end align-items-end px-0">
        <div class="btn-group" role="group">
          <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
            {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
          </button>
          <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
            {{'COMMON_BUTTON_SEARCH' | translate}}
          </button>
        </div>
      </div>
    </div><!-- /.card-body-->
    <div class="card-body">

      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header fit">
            <app-table-field-sorter [orderField]="User.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(User.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [checkAllEnabled]="true"
                                    [checkAllSelected]="eachUserSelected"
                                    (onCheckAllClicked)="toggleEachUserSelected()"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header fit hidden-md-down"></th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-field-sorter [orderField]="User.OrderField.PERSON_NAME"
                                    [orderType]="queryModel.getOrderType(User.OrderField.PERSON_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'USERS_TABLE_HEADER_PERSON_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="User.OrderField.USER_NAME"
                                    [orderType]="queryModel.getOrderType(User.OrderField.USER_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'USERS_TABLE_HEADER_USER_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-field-sorter [orderField]="User.OrderField.EMAIL_ADDRESS"
                                    [orderType]="queryModel.getOrderType(User.OrderField.EMAIL_ADDRESS)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_EMAIL_ADDRESS' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-sorter-no-op [text]="'USER_LABEL_USER_GROUPS' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let user of userList | paginate: {
                      itemsPerPage: queryModel.itemsPerPage,
                      currentPage: queryModel.currentPage,
                      totalItems: queryModel.currentNumberOfItems }"
            [disabledTableRow]="user.disabled">
          <td class="test_user_id align-middle">
            <div class="d-flex">
              <app-animated-checkbox
                class="toggle-checkbox"
                [(selected)]="user.selected">
              </app-animated-checkbox>
              {{user.id}}
            </div>
          </td>
          <td class="fit align-middle hidden-md-down">
            <i class="icomoon {{user.profileTypeObject.iconClass}}"
               [popover]="profileTypePopover"
               container="body" triggers="mouseenter:mouseleave"></i>
            <ng-template #profileTypePopover>
              {{user.profileTypeObject.stringKey | translate}}
              <div *ngIf="user.helpdesk">
                <label>
                  {{'USER_LABEL_HELPDESK_CONTACT_PERSON' | translate}}:
                </label>
                <a class="id-text cursor-pointer"
                   href="#" onclick="return false"
                   uiSref="Admin.CustomerRecordDetail"
                   [uiParams]="{ customerId: user.contactPerson.data.customerId, customerRecordId: user.contactPerson.id }"
                   title="{{'COMMON_BUTTON_DETAILS' | translate}}">
                  {{user.contactPerson.itemName}}
                </a>
              </div>
            </ng-template>
          </td>
          <td class="test_user_person_name hidden-xs-down align-middle">
            <div class="d-flex text-truncate align-items-center">
              <app-user-profile-picture
                class="mr-05"
                [userPersonName]="user.person_name"
                [profilePicture]="user.profile_picture ? user.profile_picture.profilePicture : undefined"
                [size]="36">
              </app-user-profile-picture>
              <div class="text-truncate">
                <a class="id-text cursor-pointer"
                   href="#" onclick="return false"
                   uiSref="Admin.UserDetail"
                   [uiParams]="{ id: user.id }"
                   title="{{'COMMON_BUTTON_DETAILS' | translate}}">
                  {{user.person_name}}
                </a>
              </div>
            </div>
          </td>
          <td class="test_user_name align-middle text-truncate">{{user.user_name}}</td>
          <td class="test_user_email_address hidden-sm-down align-middle">{{user.email_address}}</td>
          <td class="test_user_user_groups hidden-lg-down align-middle"
              [popover]="getUserGroups(user.user_group_ids).length > 0 ? userGroupPopover : null"
              container="body" triggers="mouseenter:mouseleave">
            {{getFirstUserGroup(user.user_group_ids)}}
            <ng-template #userGroupPopover>
              <ng-container *ngFor="let group of getUserGroups(user.user_group_ids)">
                <span>{{group}}</span><br>
              </ng-container>
            </ng-template>
          </td>
          <td class="align-middle">
            <app-table-options-menu>
              <app-dropdown-item
                [iconClass]="'icomoon-detail'"
                [titleStringKey]="'COMMON_BUTTON_DETAILS'"
                class="cursor-pointer"
                uiSref="Admin.UserDetail"
                [uiParams]="{ id: user.id }">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="!user.protected_user && userRightModel.userUpdate.hasRightForUser(user)"
                [iconClass]="'icomoon-modify'"
                [titleStringKey]="'COMMON_BUTTON_EDIT'"
                class="cursor-pointer"
                uiSref="Admin.UserEdit"
                [uiParams]="{ id: user.id }">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="!user.protected_user && user.disabled && userRightModel.userDisable.hasRight()"
                [iconClass]="'icomoon-enable'"
                [titleStringKey]="'COMMON_BUTTON_ENABLE'"
                [itemType]="DropdownItemType.ENABLE"
                class="cursor-pointer"
                (click)="setDisabled($event, user, false)">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="!user.protected_user && !user.disabled && userRightModel.userDisable.hasRight()"
                [iconClass]="'icomoon-disable'"
                [titleStringKey]="'COMMON_BUTTON_DISABLE'"
                [itemType]="DropdownItemType.DISABLE"
                class="cursor-pointer"
                (click)="setDisabled($event, user, true)">
              </app-dropdown-item>
            </app-table-options-menu>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header fit">
            <app-table-field-sorter [orderField]="User.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(User.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [checkAllEnabled]="true"
                                    [checkAllSelected]="eachUserSelected"
                                    (onCheckAllClicked)="toggleEachUserSelected()"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header fit hidden-md-down"></th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-field-sorter [orderField]="User.OrderField.PERSON_NAME"
                                    [orderType]="queryModel.getOrderType(User.OrderField.PERSON_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'USERS_TABLE_HEADER_PERSON_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="User.OrderField.USER_NAME"
                                    [orderType]="queryModel.getOrderType(User.OrderField.USER_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'USERS_TABLE_HEADER_USER_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-field-sorter [orderField]="User.OrderField.EMAIL_ADDRESS"
                                    [orderType]="queryModel.getOrderType(User.OrderField.EMAIL_ADDRESS)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_EMAIL_ADDRESS' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-sorter-no-op [text]="'USER_LABEL_USER_GROUPS' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>

      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>

    </div><!-- /.card-body -->

  </div><!-- /.card -->

</div><!-- /container -->

<div bsModal [config]="UiConstants.modalConfig" #ldapImportDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true"
     (onHide)="onModalHidden()">
  <div class="modal-dialog modal-lg" role="document" *ngIf="ldapImportDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title"><strong>{{'USER_PANEL_HEADING_LDAP_IMPORT' | translate}}</strong></p>
        <button type="button" class="close" (click)="closeLdapImportDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form-horizontal bordered-row" (ngSubmit)="ldapForm.form.valid && importLdapUser()" #ldapForm="ngForm" novalidate>

          <!--region username-->
          <div class="form-group row" [ngClass]="{ 'has-danger': hasLocalFieldError(ldapUsernameField) || ldapImportFieldErrors.user_name }">
            <label class="col-form-label form-control-label col-md-4 detail-title">{{'LOGIN_COMPONENT_USERNAME' |
              translate}}</label>
            <div class="col-md-8">
              <input
                type="text" class="form-control"
                maxlength="{{UiConstants.maximumVarcharLength}}"
                placeholder="{{'LOGIN_COMPONENT_USERNAME' | translate}}"
                [(ngModel)]="ldapUsername"
                (ngModelChange)="removeFieldError(ldapImportFieldErrors.user_name)"
                [ngClass]="{ 'form-control-danger': hasLocalFieldError(ldapUsernameField) || ldapImportFieldErrors.user_name }"
                name="ldapUsernameField"
                id="ldapUsernameField"
                #ldapUsernameField="ngModel"
                required>
              <div class="form-control-feedback"
                   *ngIf="hasLocalFieldError(ldapUsernameField)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
              <div class="form-control-feedback"
                   *ngIf="ldapImportFieldErrors.user_name">{{ ldapImportFieldErrors.user_name.text }}
              </div>
            </div>
          </div>
          <!--endregion username-->

          <div class="col-md-12 d-flex justify-content-end p-0 pl-1">
            <div>
              <button type="button" class="btn btn-outline-primary" (click)="closeLdapImportDialog()">
                {{'COMMON_BUTTON_CANCEL' | translate}}
              </button>
              <button type="submit" class="btn btn-primary ml-1">{{'COMMON_BUTTON_SAVE' |
                translate}}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<app-file-upload-dialog
  [uploadPath]="uploadPath"
  (onResult)="onImportSuccess($event)"
  #importDialog
></app-file-upload-dialog>
