<div class="card">
  <div class="card-header">
    {{'SHIPPING_DEMAND_SHIPPING_DEMANDS'|translate}}
  </div>

  <div class="card-body">
    <table class="table table-striped table-bordered">
      <thead>
      <tr>
        <th class="table-sorter-header hidden-xs-down">
          <app-table-sorter-no-op [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header long">
          <app-table-sorter-no-op [text]="'SHIPPING_DEMAND_INTERNAL_ID' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header hidden-md-down">
          <app-table-sorter-no-op [text]="'COMMON_STATE' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header w-table-actions-double">
          <app-table-sorter-no-op></app-table-sorter-no-op>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let shippingDemand of shipmentGroupModel.shippingDemands">
        <td class="responsive-table-column hidden-xs-down">{{ shippingDemand.id }}</td>
        <td class="responsive-table-column long">{{ shippingDemand.deliveryNoteNumber }}</td>
        <td class="responsive-table-column hidden-md-down">{{ shippingDemand.stateStringKey | translate }}</td>
        <td class="w-table-actions-single">
            <span class="text-nowrap">
                <a class="icons-table-group"
                   uiSref="Admin.ShippingDemandDetail"
                   [uiParams]="{ id: shippingDemand.id }">
                  <i class="icomoon icons-table-item icomoon-detail icomoon-detail-table"
                     title="{{'COMMON_BUTTON_DETAILS' | translate}}"></i>
                </a>
                <a class="icons-table-group cursor-pointer" (click)="deleteShippingDemand(shippingDemand.id)" *ngIf="canDelete()">
                  <i class="icomoon icons-table-item icomoon-trash icomoon-detail-table"
                     title="{{'COMMON_BUTTON_DELETE' | translate}}"></i>
                </a>
            </span>
        </td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <th class="table-sorter-header hidden-xs-down">
          <app-table-sorter-no-op [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header long">
          <app-table-sorter-no-op [text]="'SHIPPING_DEMAND_INTERNAL_ID' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header hidden-md-down">
          <app-table-sorter-no-op [text]="'COMMON_STATE' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header w-table-actions-double">
          <app-table-sorter-no-op></app-table-sorter-no-op>
        </th>
      </tr>
      </tfoot>
    </table>
  </div>
</div>
