<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
    <app-breadcrumb-menu>
      <app-breadcrumb-button
        *ngIf="rightModel.stockItemCategoryRead.hasRight()"
        [iconClass]="'icomoon-csv-export'"
        [titleStringKey]="'COMMON_BUTTON_EXPORT'"
        (click)="exportXls()">
      </app-breadcrumb-button>
      <app-breadcrumb-dropdown
        titleStringKey="'ORDER_XLS_IMPORT_SAMPLE_DOWNLOAD'">
        <app-dropdown-item
          *ngIf="rightModel.stockItemCategoryCreate.hasRight()"
          [iconClass]="'icomoon-file-xlsx'"
          [titleStringKey]="'ORDER_XLS_IMPORT_SAMPLE_DOWNLOAD'"
          (click)="exportXlsTemplate()"
        >
        </app-dropdown-item>
      </app-breadcrumb-dropdown>

      <app-breadcrumb-button
        *ngIf="rightModel.stockItemCategoryCreate.hasRight()"
        [iconClass]="'icomoon-add'"
        [titleStringKey]="'COMMON_BUTTON_ADD'"
        uiSref="Admin.StockItemCategoryCreate">
      </app-breadcrumb-button>
      <app-breadcrumb-dropdown
        titleStringKey="'COMMON_BUTTON_IMPORT'">
        <app-dropdown-item
          *ngIf="rightModel.stockItemCategoryCreate.hasRight()"
          [iconClass]="'icomoon-file-xlsx'"
          [titleStringKey]="'COMMON_BUTTON_IMPORT'"
          (click)="importDialog.toggleDialog()"
        >
        </app-dropdown-item>
      </app-breadcrumb-dropdown>

    </app-breadcrumb-menu>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>

<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="card">

    <div class="card-header">
      {{'STOCK_ITEM_CATEGORY_HEADER' | translate}}

      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()"
           title="{{'COMMON_SHOW_SEARCH_HINT' | translate}}"><i #searchIcon class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>

    <div class="card-body border-bottom" *ngIf="showSearch">
      <div class="row form-group">

        <div class="col">
          <label class="search-label">{{'COMMON_TABLE_HEADER_ID' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control" placeholder="{{'COMMON_TABLE_HEADER_ID' | translate}}"
                   maxlength="{{UiConstants.maxInputLongLength}}"
                   (keyup.enter)="onSearchClicked()"
                   [(ngModel)]="searchModel.id" name="id" id="id" #id="ngModel">
          </div>
        </div>
        <div class="col">
          <label class="search-label">{{'COMMON_TABLE_HEADER_EXTERNAL_ID' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control" placeholder="{{'COMMON_TABLE_HEADER_EXTERNAL_ID' | translate}}"
                   maxlength="{{UiConstants.maximumVarcharLength}}"
                   (keyup.enter)="onSearchClicked()"
                   [(ngModel)]="searchModel.externalId" name="externalId" id="externalId" #externalId="ngModel">
          </div>
        </div>
        <div class="col">
          <label class="search-label">{{'STOCK_ITEM_CATEGORY_PARENT_CATEGORY' | translate}}</label>
          <angular2-multiselect [data]="parentCategories" [settings]="dropdownSettings"
                                class="form-control"
                                [(ngModel)]="searchModel.parentCategory"
                                (onRemoteSearch)="loadParentCategories($event.target.value)"
                                #parentCategory="ngModel" name="parentCategory" id="parentCategory">
          </angular2-multiselect>
        </div>
        <div class="col">
          <label class="search-label">{{'COMMON_NAME' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
                   maxlength="{{UiConstants.maximumVarcharLength}}"
                   (keyup.enter)="onSearchClicked()"
                   [(ngModel)]="searchModel.name" name="name" id="name" #name="ngModel">
          </div>
        </div>
      </div>
      <div class="col-md-12 d-flex justify-content-md-end align-items-end">
        <div class="btn-group" role="group">
          <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
            {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
          </button>
          <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
            {{'COMMON_BUTTON_SEARCH' | translate}}
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">

      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header fit">
            <app-table-field-sorter [orderField]="StockItemCategory.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(StockItemCategory.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [checkAllEnabled]="true"
                                    [checkAllSelected]="eachStockItemCategorySelected"
                                    (onCheckAllClicked)="toggleEachStockItemCategory()"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="StockItemCategory.OrderField.NAME"
                                    [orderType]="queryModel.getOrderType(StockItemCategory.OrderField.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op [text]="'STOCK_ITEM_CATEGORY_PARENT_CATEGORY' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="StockItemCategory.OrderField.EXTERNAL_ID"
                                    [orderType]="queryModel.getOrderType(StockItemCategory.OrderField.EXTERNAL_ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_EXTERNAL_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-20 hidden-xs-down">
            <app-table-sorter-no-op [text]="'COMMON_TABLE_HEADER_CREATION_TIME' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-actions-single">
            <app-table-sorter-no-op [text]="''"></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let categoryItem of categoryList | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }">

          <td class="fit align-middle">
            <div class="d-flex">
              <app-animated-checkbox
                class="toggle-checkbox"
                [(selected)]="categoryItem.selected">
              </app-animated-checkbox>
              {{categoryItem.id}}
            </div>
          </td>
          <td class="responsive-table-column">
            <a class="id-text cursor-pointer"
               href="#" onclick="return false"
               uiSref="Admin.StockItemCategoryDetail"
               [uiParams]="{ id: categoryItem.id }"
               title="{{'COMMON_BUTTON_DETAILS' | translate}}">
              {{categoryItem.name}}
            </a>
          </td>
          <td class="responsive-table-column">{{categoryItem.parentCategory}}</td>
          <td class="responsive-table-column">{{categoryItem.externalId}}</td>
          <td class="responsive-table-column hidden-xs-down">{{categoryItem.creationTime.toUtcIsoString() | date:'short'}}</td>
          <td>
            <app-table-options-menu>
              <app-dropdown-item
                *ngIf="rightModel.stockItemCategoryRead.hasRight()"
                [iconClass]="'icomoon-detail'"
                [titleStringKey]="'COMMON_BUTTON_DETAILS'"
                class="cursor-pointer"
                uiSref="Admin.StockItemCategoryDetail"
                [uiParams]="{ id: categoryItem.id }">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="rightModel.stockItemCategoryUpdate.hasRight()"
                [iconClass]="'icomoon-modify'"
                [titleStringKey]="'COMMON_BUTTON_EDIT'"
                class="cursor-pointer"
                uiSref="Admin.StockItemCategoryEdit"
                [uiParams]="{ id: categoryItem.id }">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="rightModel.stockItemCategoryDelete.hasRight()"
                [iconClass]="'icomoon-trash'"
                [titleStringKey]="'COMMON_BUTTON_DELETE'"
                class="cursor-pointer"
                (click)="deleteCategory(categoryItem.id)">
              </app-dropdown-item>
            </app-table-options-menu>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header fit">
            <app-table-field-sorter [orderField]="StockItemCategory.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(StockItemCategory.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [checkAllEnabled]="true"
                                    [checkAllSelected]="eachStockItemCategorySelected"
                                    (onCheckAllClicked)="toggleEachStockItemCategory()"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="StockItemCategory.OrderField.NAME"
                                    [orderType]="queryModel.getOrderType(StockItemCategory.OrderField.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op [text]="'STOCK_ITEM_CATEGORY_PARENT_CATEGORY' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="StockItemCategory.OrderField.EXTERNAL_ID"
                                    [orderType]="queryModel.getOrderType(StockItemCategory.OrderField.EXTERNAL_ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_EXTERNAL_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-20 hidden-xs-down">
            <app-table-sorter-no-op [text]="'COMMON_TABLE_HEADER_CREATION_TIME' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-actions-single">
            <app-table-sorter-no-op [text]="''"></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>
  </div>
</div>

<app-file-upload-dialog
  [uploadPath]="uploadPath"
  (onResult)="onImportSuccess($event)"
  #importDialog>

</app-file-upload-dialog>
