import * as draw2d from '../../../../../../../node_modules/draw2d/dist/draw2d.js';

export class FigureSelectionFeedbackPolicy extends draw2d.policy.figure.GlowSelectionFeedbackPolicy {

  constructor() {
    super();
  }

  // Sets the figure color to green when selected
  onSelect(canvas, figure, isPrimarySelection) {
    figure.setStroke(2);
    figure.setColor(new draw2d.util.Color('#00727B'));
    super.moved(canvas, figure);
  }

  // Reverts the figure color when unselected
  onUnselect(canvas, figure) {
    figure.setStroke(1);
    figure.setColor(new draw2d.util.Color('#8D949C'));
    super.onUnselect(canvas, figure);
  }

}
