<h5 class="mb-1 pr-1 form-control-label text-break"
    [ngClass]="{ 'required-field-label': required }">{{ getTitle() }} </h5>
<label class="warning-text"
       *ngIf="hasDisabledItem && !nonEditable">{{'FORM_RECORD_STOCK_DISABLED_WARNING_TEXT' | translate}}</label>
<label class="warning-text"
       *ngIf="!optionalValue && htmlForm && htmlForm.getForm().submitted && (!model.stocks || model.stocks.length === 0)">{{'FORM_RECORD_STOCK_REQUIRED_WARNING_TEXT' | translate}}</label>
<div class="mb-1 d-flex align-items-center justify-content-between" *ngIf="isAnyPriceChanged() && !nonEditable">
  <label class="warning-text">{{'FORM_RECORD_STOCK_PRICES_CHANGED_WARNING_TEXT' | translate}}</label>
  <button type="button" class="btn btn-primary"
          (click)="acceptAllNewPrices()">{{'FORM_RECORD_STOCK_REFRESH_PRICE_ALL_TEXT' | translate}}</button>
</div>
<div class="mb-1 d-flex align-items-center justify-content-between"
     *ngIf="shouldDisplayDispersionPercentValidationMessage() && !nonEditable">
  <label class="warning-text">{{'FORM_RECORD_STOCK_DISPERSION_PERCENT_ERROR' | translate}}</label>
</div>
<div *ngIf="model.stocks && currencies">
  <table class="table table-sm invoice-create-table">
    <thead>
    <tr>
      <th class="responsive-table-column">{{'COMMON_NAME' | translate}}<br/><span
        class="text-muted">{{'FORM_RECORD_STOCK_STOCK_NAME' | translate}}</span></th>
      <th class="responsive-table-column" *ngIf="hasAnyInvoicedItem()">
        {{'INVOICES_TABLE_HEADER_INVOICE_NUMBER' | translate}}
      </th>
      <th class="responsive-table-column hidden-md-down">{{'STOCK_ITEM_TABLE_HEADER_PRODUCT_CODE' | translate}}
        <br/><span class="text-muted">{{'COMMON_EXTERNAL_ID_SHORT' | translate}}</span></th>
      <th *ngIf="!dispersionPercentEnabled"
          class="responsive-table-column hidden-sm-down">{{'FORM_RECORD_STOCK_AMOUNT' | translate}}</th>
      <th *ngIf="!dispersionPercentEnabled"
          class="fit hidden-md-down">{{'FORM_RECORD_STOCK_IN_STOCK_AMOUNT' | translate}}</th>
      <th *ngIf="dispersionPercentEnabled"
          class="responsive-table-column hidden-sm-down">{{'FORM_RECORD_STOCK_DISPERSION_PERCENT' | translate}}</th>
      <th class="responsive-table-column hidden-md-down">{{'STOCK_ITEM_WEIGHT' | translate}}</th>
      <th class="responsive-table-column hidden-md-down text-break">{{'FORM_RECORD_STOCK_UNIT_PRICE' | translate}}</th>
      <th class="responsive-table-column">{{'FORM_RECORD_STOCK_SUM_LINE_GROSS_PRICE' | translate}}<br/><span
        class="text-muted">{{'FORM_RECORD_STOCK_SUM_LINE_NET_PRICE' | translate}}</span></th>
      <th class="w-table-options-menu text-center" *ngIf="!nonEditable && model.stocks.length > 0"
          (click)="removeAllRecords()">
        <i class="icomoon icomoon-trash cursor-pointer icon-medium"></i>
      </th>
    </tr>
    </thead>
    <tbody *ngIf="model.stocks && model.stocks.length > 0">
    <ng-container *ngFor="let item of model.stocks; index as i;">
      <tr [ngClass]="{'disabled-item': item.disabledItem && !nonEditable}">
        <td class="responsive-table-column table-column-name w-25">
          <div class="d-flex align-items-center">
            <div class="stock-item-image-container">
              <div *ngIf="item.stockRecord.stockItem.picture && !!item.stockRecord.stockItem.picture.image_loaded"
                   class="stock-item-image"
                   [style.background-image]="item.stockRecord.stockItem.picture!.picture_src"></div>
              <div *ngIf="!item.stockRecord.stockItem.picture || !item.stockRecord.stockItem.picture.image_loaded"
                   class="stock-item-image-placeholder">
                <div class="icon-background"></div>
                <i class="icon-centered icomoon icomoon-products"></i>
              </div>
            </div>
            <div class="text-truncate">
              <a class="stock-item-name cursor-pointer"
                 uiSref="Admin.StockItemDetail"
                 [uiParams]="{ id: item.stockRecord.stockItem.id }">
                {{item.stockRecord.stockItem.name}}</a>
              <p class="text-muted text-truncate mb-0">{{item.stockRecord.stockName}}</p>
            </div>
          </div>
        </td>
        <td class="responsive-table-column align-middle" *ngIf="!!item.invoiced">
          <app-table-badge
            [badgeStyle]="BadgeStyle.SECONDARY"
            [iconClass]="'icomoon-invoice'"
            [text]="item.invoiceNumber">
          </app-table-badge>
        </td>
        <td class="responsive-table-column align-middle hidden-md-down">
          {{item.stockRecord.stockItem.product_code}}<br/>
          <span class="text-muted text-truncate mb-0">{{item.stockRecord.stockItem.external_id}}</span>
        </td>
        <td *ngIf="!nonEditable && !item.invoiced && !dispersionPercentEnabled"
            class="responsive-table-column align-middle hidden-sm-down"
            style="overflow:visible"
            [ngClass]="{ 'has-danger': (!item.validAmount && !item.service) || !item.amount || !item.selectedUnitOfMeasure}">
          <div class="d-flex align-items-center">
            <input type="number" min="0" class="form-control w-50"
                   placeholder="{{item.stockRecord.stockItem.unit}}"
                   [(ngModel)]="item.numberAmount"
                   (change)="notifyStockRecordAmountChange(item)"
                   [ngClass]="{ 'form-control-danger': (!item.validAmount && !item.service) || !item.amount }"
                   [disabled]="item.disabledItem">
            <angular2-multiselect
              class="form-control ml-05 flex-grow-2"
              [(ngModel)]="item.unitOfMeasure"
              [data]="item.stockRecord.stockItem.measurements"
              [settings]="dropdownSettings"
              name="unitOfMeasureInput"
              id="unitOfMeasureInput"
              (onDeSelect)="notifyStockRecordAmountChange(item)"
              (onSelect)="notifyStockRecordAmountChange(item)"
              [ngClass]="{ 'form-control-danger': (!item.validAmount && !item.service) || !item.selectedUnitOfMeasure }"
              #unitOfMeasureInput="ngModel"
              [readonly]="item.disabledItem"
              validateEnabledItems
              required>
            </angular2-multiselect>
          </div>
          <div *ngIf="item.stockRecord.stockItem.package_data && item.isBaseUnitSelected()"
               class="table-text-subtitle">{{StockItemUtils.convertToPackageStr(item.numberAmount, item.stockRecord.stockItem.package_data)}}</div>
          <div *ngIf="!item.isBaseUnitSelected()"
               class="table-text-subtitle">{{StockItemUtils.convertToBaseStr(item.numberAmount, item.selectedUnitOfMeasure, item.stockRecord.stockItem.baseUnit.unit_name)}}</div>
        </td>
        <td *ngIf="!nonEditable && !item.invoiced && dispersionPercentEnabled"
            class="fit align-middle hidden-sm-down"
            [ngClass]="{ 'has-danger': !item.validDispersionPercent || item.dispersionPercent === undefined }">
          <div class="d-flex align-items-center">
            <input type="number" min="0" class="form-control percentage-field"
                   placeholder="{{'FORM_RECORD_STOCK_DISPERSION_PERCENT' | translate}}"
                   [(ngModel)]="item.dispersionPercent"
                   (blur)="onDispersionPercentChanged(item)"
                   [ngClass]="{ 'form-control-danger': !item.validDispersionPercent || item.dispersionPercent === undefined }"
                   [disabled]="item.disabledItem">
            <span class="ml-05">%</span>
          </div>
        </td>
        <td *ngIf="(nonEditable || item.invoiced) && !dispersionPercentEnabled"
            class="responsive-table-column align-middle hidden-sm-down">{{item.amount | formattedNumber}} {{item.selectedUnitOfMeasure.unit_name}}
          <div *ngIf="item.stockRecord.stockItem.package_data && item.isBaseUnitSelected()"
               class="table-text-subtitle">{{StockItemUtils.convertToPackageStr(item.numberAmount, item.stockRecord.stockItem.package_data)}}</div>
          <div *ngIf="!item.isBaseUnitSelected()"
               class="table-text-subtitle">{{StockItemUtils.convertToBaseStr(item.numberAmount, item.selectedUnitOfMeasure, item.stockRecord.stockItem.baseUnit.unit_name)}}</div>
        </td>
        <td *ngIf="(nonEditable || item.invoiced) && dispersionPercentEnabled"
            class="responsive-table-column align-middle hidden-sm-down">{{item.dispersionPercent}}%
        </td>
        <td *ngIf="!dispersionPercentEnabled"
            class="align-middle hidden-md-down fit font-xs">{{item.stockRecord.amount | formattedNumber}} {{item.stockRecord.stockItem.unit}}
          <div *ngIf="item.stockRecord.stockItem.package_data"
               class="table-text-subtitle">{{StockItemUtils.convertToPackageStr(item.stockRecord.amount, item.stockRecord.stockItem.package_data)}}</div>
        </td>
        <td class="responsive-table-column align-middle hidden-md-down w-5">{{item.sumWeight}}</td>
        <td class="responsive-table-column align-middle hidden-md-down">
          {{getReducedNetPrice(item) | formattedNumber}} {{getCurrencyString(item.stockRecord.stockItem.unit_price.currency_code)}}
          <ng-container *ngIf="model.discountPercent === null && !dispersionPercentEnabled">
            <i class="icomoon icomoon-price icon-standard price-edit-icon cursor-pointer"
               [popover]="customPricePopover" triggers="click" container="body"
               #customPricePopoverTrigger="bs-popover"></i>
            <ng-template #customPricePopover>
              <app-form-record-stock-item-custom-price-edit
                class="pointer-events-auto"
                [discount]="model.discountPercent"
                (onPriceChanged)="onCustomPriceChanged(customPricePopoverTrigger)"
                (onCloseButtonClicked)="customPricePopoverTrigger.hide()"
                [disabled]="nonEditable || item.invoiced || item.disabledItem"
                [currencyCodeString]="getCurrencyString(item.stockRecord.stockItem.unit_price.currency_code)"
                [model]="item">
              </app-form-record-stock-item-custom-price-edit>
            </ng-template>
          </ng-container>
          <i *ngIf="isPriceChanged(item) && !nonEditable && !item.invoiced"
             (click)="acceptNewPrice(item)"
             title="{{'FORM_RECORD_STOCK_REFRESH_PRICE_TEXT' | translate}}"
             class="icomoon icomoon-restart icon-standard px-quarter cursor-pointer warning-text">
          </i>
          <br>
          <span class="text-muted font-xs" *ngIf="+getReducedNetPrice(item) !== +getUnitPrice(item)">
          <span>{{'FORM_RECORD_STOCK_ORIGINAL_PRICE' | translate}}</span>:
          <span
            class="text-truncate font-weight-bold">
            {{getUnitPrice(item).toFixed(2) | formattedNumber}} {{getCurrencyString(item.stockRecord.stockItem.unit_price.currency_code)}}
          </span>
        </span>
        </td>
        <td class="responsive-table-column align-middle">{{getRecordGrossPrice(item) | formattedNumber}}
          {{getCurrencyString(item.stockRecord.stockItem.unit_price.currency_code)}}
          <div class="text-muted font-xs"
               *ngIf="getRecordCustomGrossPrice(item) && +getRecordDefaultGrossPrice(item) !== +getRecordCustomGrossPrice(item)">
            <span>{{'FORM_RECORD_STOCK_DISCOUNT' | translate}}</span>
            <span
              class="text-truncate font-weight-bold">
            {{(+getRecordDefaultGrossPrice(item) - +getRecordCustomGrossPrice(item)).toFixed(2) | formattedNumber}} {{getCurrencyString(item.stockRecord.stockItem.unit_price.currency_code)}}
          </span>
          </div>
          <div class="text-muted">{{getRecordNetPrice(item) | formattedNumber}} {{getCurrencyString(item.stockRecord.stockItem.unit_price.currency_code)}}</div>

        </td>
        <td class="responsive-table-column align-middle text-center hidden-lg-up" (click)="toggleEdit(i)"
            *ngIf="!nonEditable && !item.invoiced"><i
          class="icomoon icomoon-modify cursor-pointer icon-medium"></i></td>
        <td class="responsive-table-column align-middle text-center hidden-md-down" (click)="removeRecord(i)"
            *ngIf="!nonEditable && !item.invoiced"><i
          class="icomoon icomoon-trash cursor-pointer icon-medium"></i></td>
      </tr>
      <tr *ngIf="commentEnabled" [ngClass]="{'disabled-item': item.disabledItem && !nonEditable}">
        <td *ngIf="!nonEditable"
            [ngClass]="{ 'has-danger': commentRequired && (item.comment === undefined || item.comment.length === 0)}"
            colspan="100%">
          <app-form-record-stock-field-comment-edit
            [(commentText)]="item.comment"
            [required]="commentRequired"
            [customLabel]="commentCustomLabel"
            [disabled]="item.disabledItem"
          ></app-form-record-stock-field-comment-edit>
        </td>

        <td *ngIf="nonEditable" colspan="100%">
          <p class="text-muted text-truncate mb-0">{{item.comment}}</p>
        </td>
      </tr>
      <tr *ngIf="item.editing">
        <td colspan="100%" style="padding: 0">
          <app-form-record-stock-field-item-edit
            [fieldId]="fieldId"
            [model]="item"
            [discount]="model.discountPercent"
            [excludedIds]="excludedIds"
            [uniqueStockRecords]="uniqueStockRecords"
            [usableCategoryIds]="usableCategoryIds"
            [customerRecordId]="customerRecordId"
            [dispersionPercentEnabled]="dispersionPercentEnabled"
            [dispersionPercentValidator]="dispersionPercentValidator"
            (dispersionPercentChangedEvent)="onDispersionPercentChangedInEditor($event)"
            (remove)="removeRecord(i)"
            (cancel)="closeRow(i)"
            (save)="saveRecord(i, $event)">
          </app-form-record-stock-field-item-edit>
        </td>
      </tr>
    </ng-container>
    </tbody>
    <tbody *ngIf="!model.stocks || model.stocks.length === 0">
    <tr>
      <td colspan="9">
        <div class="text-center text-muted p-2">
          <label class="m-0" *ngIf="!nonEditable">{{'FORM_RECORD_STOCK_NO_ITEM' | translate}}</label>
          <label class="m-0" *ngIf="nonEditable">{{'FORM_RECORD_STOCK_NO_ITEM_READONLY' | translate}}</label>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
  <button type="button" class="btn btn-secondary"
          *ngIf="!nonEditable"
          (click)="openStockRecordSelectorDialog()">
    {{'FORM_RECORD_STOCK_ADD' | translate}}
  </button>

  <div>
    <div class="d-flex justify-content-end" *ngIf="model.discountPercent !== undefined && !dispersionPercentEnabled">
      <div class="d-flex align-items-center">
        <label class="mr-1 mb-0">{{'FORM_RECORD_STOCK_DISCOUNT' | translate}}</label>
        <select class="form-control min-width-7-5" [compareWith]="SelectUtils.compareNumbersWithNull"
                [(ngModel)]="model.discountPercent" *ngIf="!nonEditable"
                (ngModelChange)="resetCustomPrices()" id="discountSelect" name="discountSelect"
                #discountSelect="ngModel">
          <option *ngFor="let item of availableDiscountsForDropdown" [ngValue]="item.id">{{item.text}}</option>
        </select>
        <label class="mb-0">{{readonlyDiscount}}</label>
      </div>
      <!-- /.row -->
    </div>
    <!-- /.row -->
    <div class="row mt-1" *ngIf="sameCurrency">
      <div class="col-md-4 offset-md-8">
        <div class="d-flex justify-content-between pt-1 mb-1 border-top">
          <div class="font-weight-bold text-small">{{'FORM_RECORD_STOCK_SUM_NET_PRICE' | translate}}</div>
          <div>{{netSumPrice | formattedNumber}} {{getCurrencyCode()}}</div>
        </div>
        <div class="d-flex justify-content-between">
          <div class="font-weight-bold text-small">{{'FORM_RECORD_STOCK_SUM_GROSS_PRICE' | translate}}</div>
          <div>{{grossSumPrice | formattedNumber}} {{getCurrencyCode()}}</div>
        </div>
      </div>
    </div>
    <div class="pull-right mb-1" *ngIf="!sameCurrency"><h6
      class="text-muted">{{'FORM_RECORD_STOCK_CURRENCY_ERROR' | translate}}</h6></div>
    <div class="row mt-1">
      <div class="col-md-4 offset-md-8">
        <div class="d-flex justify-content-between" *ngIf="sumWeight.length > 0">
          <div class="font-weight-bold text-small">{{'FORM_RECORD_STOCK_SUM_WEIGHT' | translate}}</div>
          <div>{{ sumWeight }}</div>
        </div>
      </div>
    </div>
    <!-- /.pull-right -->
  </div>
</div>

