import {Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {QueryFieldModel, UiConstants,} from '../../../util/core-utils';
import {OrderType, QueryResult, ResourceQueryResult, Services} from '../../../lib/util/services';
import {RightModel} from '../../../app.rights';
import {StockItem, StockItemCustomerRecordPrice, StockItemService,} from '../../../lib/stock/stock-item.service';
import {Numbers} from '../../../lib/util/numbers';
import {Set} from 'immutable';
import {Models} from '../../../util/model-utils';
import {SearchUtils} from '../../../util/search-utils';
import {InputMask} from '../../../util/input-masks';
import {
  StockItemCustomerRecordPriceSearch,
  StockItemCustomerRecordPriceSearchService
} from '../../../lib/stock/stock-item-customer-record-price-search-service';
import {Angular2Multiselects} from '../../../util/multiselect';
import {CustomerRecord} from '../../../lib/customer/customer-record.service';
import {Strings} from '../../../lib/util/strings';
import {Currency} from '../../../lib/currency.service';
import {
  CustomerRecordMultiselectItem,
  CustomerRecordMultiselectProvider
} from "../../../lib/customer/customer-record-multiselect-provider.service";

@Component({
  selector: 'app-stock-item-customer-record-price-list',
  templateUrl: './stock-item-customer-record-price-list.component.html',
  styleUrls: ['./stock-item-customer-record-price-list.component.scss']
})
export class StockItemCustomerRecordPriceListComponent extends SearchUtils.SearchableList<StockItemCustomerRecordPriceSearch.Model>
  implements OnInit, OnDestroy {
  StockItem = StockItem;
  UiConstants = UiConstants;
  InputMask = InputMask;

  @Input()
  stockItemId: number;

  @Input()
  rightModel: RightModel = RightModel.empty();

  @Input()
  readonly: boolean;

  @Input()
  vatRate: number;

  @Input()
  currency: Currency.Currency;

  @Output()
  onEdit: EventEmitter<StockItemCustomerRecordPrice> = new EventEmitter<StockItemCustomerRecordPrice>();

  @Output()
  onCreate: EventEmitter<any> = new EventEmitter<any>();

  searchModel: StockItemCustomerRecordPriceSearch.Model = new StockItemCustomerRecordPriceSearch.Model();

  dropdownSettings: Angular2Multiselects.Settings;

  priceList: StockItemCustomerRecordPrice[] = [];
  queryModel: QueryFieldModel<StockItem.CustomerRecordPriceOrderField>
    = new QueryFieldModel(StockItem.CustomerRecordPriceOrderField.ID, OrderType.DESC);

  customerRecords: CustomerRecordMultiselectItem[] = [];

  constructor(
    private stockItemService: StockItemService,
    private customerRecordMultiselectProvider: CustomerRecordMultiselectProvider,
    private stockItemCustomerRecordPriceSearchService: StockItemCustomerRecordPriceSearchService,
    injector: Injector
  ) {
    super(StockItemCustomerRecordPriceSearch.Model, injector);
  }

  ngOnInit() {
    this.initSearch();
    this.initDropdownSettings();
  }

  onFirstSearchOpen(): void {
  }

  loadSearch(completion: () => void) {
    this.stockItemCustomerRecordPriceSearchService.getSearchData({stockItemId: this.stockItemId})
      .subscribe(
        (result: StockItemCustomerRecordPriceSearch.SearchDataResult) => {
          this.queryModel.itemsPerPage = result.searchData.itemsPerPage;
          this.queryModel.currentPage = result.searchData.pageNumber;
          this.queryModel.setOrder(result.searchData.order);
          this.searchModel.customUnitPriceFrom = result.searchData.customUnitPriceFrom;
          this.searchModel.customUnitPriceTo = result.searchData.customUnitPriceTo;
          this.searchModel.customerRecords = result.searchData.customerRecords;
          completion();
        }
      );
  }

  loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();
    this.stockItemService.getCustomerRecordPrices({
      stock_item_id: this.stockItemId,
      custom_unit_price_from: Numbers.undefinedOrNonEmpty(Models.parseNumber(this.searchModel.customUnitPriceFrom)),
      custom_unit_price_to: Numbers.undefinedOrNonEmpty(Models.parseNumber(this.searchModel.customUnitPriceTo)),
      customer_record_id: this.searchModel.customerRecords.length > 0
        ? this.searchModel.customerRecords.map(r => r.id).join(',')
        : undefined,
      page_number: requestedPage,
      number_of_items: this.queryModel.itemsPerPage,
      order: Services.createOrderFieldParameter(StockItem.CustomerRecordPriceKeys.toOrderFieldKey, Set.of(order)),
    }).subscribe((result: ResourceQueryResult<StockItemCustomerRecordPrice>) => {
      this.priceList = result.items;
      this.queryModel.currentPage = requestedPage;
      this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
    });
  }

  orderBy(field: StockItem.CustomerRecordPriceOrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  onSearchClicked() {
    this.loadList(1);
  }

  onSearchReset() {
    this.stockItemCustomerRecordPriceSearchService.resetSearchData({stockItemId: this.stockItemId}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.loadList(1);
        });
      }
    );
  }

  private saveSearch() {
    const request = {
      stockItemId: this.stockItemId,
      searchData: {
        itemsPerPage: this.queryModel.itemsPerPage,
        pageNumber: this.queryModel.currentPage,
        order: this.queryModel.getOrder(),
        customUnitPriceFrom: this.searchModel.customUnitPriceFrom,
        customUnitPriceTo: this.searchModel.customUnitPriceTo,
        customerRecords: this.searchModel.customerRecords,
      }
    };
    this.stockItemCustomerRecordPriceSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  onEditClicked(price: StockItemCustomerRecordPrice) {
    this.onEdit.emit(price);
  }

  onDeleteClicked(price: StockItemCustomerRecordPrice) {
    this.stockItemService.deleteCustomerRecordPrice({
      stockItemId: this.stockItemId,
      id: price.id
    }).subscribe(() => {
      this.loadList(1);
    });
  }

  onCreateClicked() {
    this.onCreate.emit();
  }

  initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .remoteSearch(true)
      .build();
  }

  loadCustomerRecords(q?: string) {
    this.customerRecordMultiselectProvider.loadActiveNonContact(q).subscribe((result: CustomerRecordMultiselectItem[]) => {
      this.customerRecords = result;
    });
  }

  getGrossPrice(netPrice: number) {
    return (((100 + this.vatRate) / 100) * netPrice).toFixed(2);
  }

  ngOnDestroy() {
    this.saveSearch();
  }

}
