/* eslint-disable */
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UserGroup, UserGroupService } from '../../../lib/user-group.service';
import { Set } from 'immutable';
import { AuthService } from '../../../lib/auth.service';
import {
  ApplicationTypeItem, MultiselectOptionItem,
  OptionItem,
  QueryFieldModel,
  SelectUtils,
  UiConstants,
  UserGroupDisabledItem,
  UserGroupSearchModel,
} from '../../../util/core-utils';
import { RightModel } from '../../../app.rights';
import { RightResolver, RightService } from '../../../lib/right.service';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { TranslateService } from '@ngx-translate/core';
import { StateName } from '../../../app.state-names';
import { InputMask } from '../../../util/input-masks';
import { UserGroupSearch, UserGroupSearchService } from '../../../lib/user-group-search-service';
import { combineLatest, Observable } from 'rxjs';
import { Arrays } from '../../../lib/util/arrays';
import { Strings } from '../../../lib/util/strings';
import { Models } from '../../../util/model-utils';
import { StringKey } from '../../../app.string-keys';
import { TranslateUtils } from '../../../util/translate';
import { Angular2Multiselects } from '../../../util/multiselect';
import { ApplicationType, RootCoreService } from '../../../lib/root-core.service';
import { OrderType, QueryResult, ResourceQueryResult, Services } from '../../../lib/util/services';
import { UserData, UserDataLoader, UserDataLoaderPermissionDeniedStrategy } from '../../../lib/user-data-loader';
import { EmptyMessage } from '../../../lib/util/messages';
import { DisabledEnum, DisabledItem } from '../../../util/search-utils';
import { Company, CompanyService } from '../../../lib/company/company.service';
import { Role, RoleService } from '../../../lib/role/role.service';
import { RoleMultiselectProvider } from '../../../lib/role/role-multiselect.provider';
/* eslint-enable */

@Component({
  selector: 'app-usergroup-list',
  templateUrl: 'usergroup-list.component.html',
  styleUrls: ['usergroup-list.component.scss']
})
export class UserGroupListComponent implements OnInit, AfterViewInit, OnDestroy {
  UserGroup = UserGroup;
  UiConstants = UiConstants;

  queryModel: QueryFieldModel<UserGroup.OrderField> = new QueryFieldModel(UserGroup.OrderField.ID, OrderType.DESC);
  userGroupList: UserGroup[] = [];
  roleList: Role.Role[] = [];
  rightModel: RightModel = RightModel.empty();
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  userData: UserData;
  companiesForSearch: MultiselectOptionItem<number>[] = [];

  @ViewChild('searchIcon', { static: true }) searchIcon: ElementRef;
  showSearch: boolean = false;
  searchModel: UserGroupSearchModel = new UserGroupSearchModel();
  userGroupDisabledItems: DisabledItem[] = [];
  InputMask = InputMask;
  SelectUtils = SelectUtils;
  applicationTypeDropdownSettings?: Angular2Multiselects.Settings;
  companyDropdownSettings?: Angular2Multiselects.Settings;
  applicationTypes: ApplicationTypeItem[] = [];
  roleSearchList: MultiselectOptionItem<number>[] = [];

  constructor(
    private authService: AuthService,
    private rightService: RightService,
    private translateService: TranslateService,
    private userDataLoader: UserDataLoader,
    private userGroupService: UserGroupService,
    private userGroupSearchService: UserGroupSearchService,
    private companyService: CompanyService,
    private roleService: RoleService,
    private roleMultiselectProvider: RoleMultiselectProvider,
    private rootCoreService: RootCoreService) {}

  ngOnInit() {
    this.translateService.get('MENU_NAVBAR_MENU_USER_GROUPS').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.loadSearch(() => {
      this.showSearch = !this.searchModel.isEmpty();
      this.initDisabledOptions(DisabledEnum.FALSE);
    });
    this.loadCompaniesForSearch();
  }

  private loadSearch(completion: () => void) {
    const obs: Observable<SearchLoadResult> = combineLatest(
      this.userGroupSearchService.getSearchData({}),
      (storedSearchData: UserGroupSearch.SearchDataResult) => {
        const result: SearchLoadResult = {
          storedSearchData: storedSearchData
        };
        return result;
      }
    );
    obs.subscribe(
      (result: SearchLoadResult) => {
        this.postInitSearch(result.storedSearchData);
        completion();
      }
    );
  }

  private postInitSearch(storedSearchData: UserGroupSearch.SearchDataResult) {
    this.queryModel.itemsPerPage = storedSearchData.searchData.itemsPerPage;
    this.queryModel.currentPage = storedSearchData.searchData.pageNumber;
    this.queryModel.setOrder(storedSearchData.searchData.order);
    this.searchModel.id = storedSearchData.searchData.id;
    this.searchModel.name = storedSearchData.searchData.name;
    this.searchModel.disabled = storedSearchData.searchData.disabled;
    this.searchModel.adminGroup = storedSearchData.searchData.adminGroup;
    this.searchModel.applicationTypeIds = storedSearchData.searchData.applicationTypeIds;
    this.searchModel.companies = storedSearchData.searchData.companies;
    this.searchModel.roles = storedSearchData.searchData.roles;
  }

  private initDisabledOptions(initValue: DisabledEnum) {
    this.userGroupDisabledItems = [];
    const disabledEnums: DisabledEnum[] = [DisabledEnum.NONE, DisabledEnum.FALSE, DisabledEnum.TRUE];
    Arrays.iterateByIndex(disabledEnums, (key) => {
      const item = new DisabledItem();
      item.id = key;
      this.translateService.get('COMMON_VALUE_DISABLED_ENUM_' + key).subscribe(
        (text: string) => {
          item.text = text;
        }
      );
      this.userGroupDisabledItems.push(item);
      if (!this.searchModel.disabled) {
        if (key === initValue) {
          this.searchModel.disabled = item;
        }
      }
    });
  }

  private loadCompaniesForSearch(q?: string) {
    this.companyService.query({
      name: q ? Strings.undefinedOrNonEmpty(q) : undefined,
      orders: Set.of({field: Company.OrderField.NAME, type: OrderType.ASC}),
      paging: {
        pageNumber: 1,
        numberOfItems: 30
      },
      noProgressBar: true
    }).subscribe((result: QueryResult<Company.Company>) => {
      this.companiesForSearch = [];
      result.items.toArray().forEach((c) => {
        this.companiesForSearch.push({
          id: c.id,
          itemName: c.name,
          disabled: c.disabled
        });
      });
    });
  }

  loadRolesForSearch(q?: string) {
    this.roleMultiselectProvider.loadRoles(false, q).subscribe(roles => {
      this.roleSearchList = roles;
    });
  }

  ngAfterViewInit(): void {
    this.loadScopes();
    this.initDropdowns();
    this.loadRightModels(() => {
      this.loadList();
    });
  }

  private loadRightModels(completion: () => void) {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        this.userDataLoader.loadMe(resolver.userProfile!, UserDataLoaderPermissionDeniedStrategy.MISS_ALL).subscribe(userData => {
          this.userData = userData;
          completion();
        });
      }
    );
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: UserGroup.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }


  setDisabled(event: any, userGroup: UserGroup, disabled: boolean) {
    this.userGroupService.setDisabled({
      id: userGroup.id,
      disabled: disabled
    })
    .subscribe(
      (result: EmptyMessage) => {
        this.loadList();
      },
      (error: any) => {
        this.loadList();
      }
    );
  }

  private loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();
    const disabled: boolean | undefined = !this.searchModel.disabled ||
    this.searchModel.disabled.id === DisabledEnum.NONE ?
      undefined : this.searchModel.disabled.id === DisabledEnum.TRUE;

    let applicationTypeIds: string | undefined = this.searchModel.applicationTypes.length === 0
    || (this.searchModel.applicationTypes.length === 1 && this.searchModel.applicationTypes[0].id === null)
      ? undefined
      : this.searchModel.applicationTypes.filter((s) => s.id !== null).map((s) => s.id).join(',');

    if (this.searchModel.applicationTypes.length === 0 && this.searchModel.applicationTypeIds) {
      applicationTypeIds = this.searchModel.applicationTypeIds;
    }

    this.userGroupService.query({
      id: Strings.undefinedOrNonEmpty(this.searchModel.id),
      name: Strings.undefinedOrNonEmpty(this.searchModel.name),
      disabled:  disabled,
      company_id: this.searchModel.companies.length > 0 ? this.searchModel.companies.map(c => c.id).join(',') : undefined,
      roles: this.searchModel.roles.length > 0 ? this.searchModel.roles.map(c => c.id).join(',') : undefined,
      application_type_ids: applicationTypeIds,
      page_number: requestedPage,
      number_of_items: this.queryModel.itemsPerPage,
      order: Services.createOrderFieldParameter(UserGroup.Keys.toOrderFieldKey, Set.of(order)),
    })
    .subscribe(
      (result: ResourceQueryResult<UserGroup>) => {
        this.userGroupList = result.items;
        this.queryModel.currentPage = requestedPage;
        this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
        this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
        this.loadRoles();
      }
    );
  }

  private loadRoles() {
    const roleIds: number[] = [];
    this.userGroupList.forEach(ug => {
      roleIds.push(...ug.roles)
    });
    this.roleService.query({
      id: Set.of(...roleIds)
    }).subscribe(roles => {
      this.roleList = roles.items.toArray();
    });
  }

  getFirstRole(ids: number[]): string {
    if (ids.length > 0) {
      const filtered_roles = this.roleList.filter(current_role => current_role.id === ids[0]);
      if (filtered_roles === undefined || filtered_roles.length === 0) {
        return '';
      }
      return filtered_roles[0].name;
    }
    return '';
  }

  getRoles(ids: number[]): string[] {
    const groups: string[] = [];
    ids.forEach((id) => {
      const filtered_roles = this.roleList.filter(current_role => current_role.id === id);
      if (!(filtered_roles === undefined || filtered_roles.length === 0)) {
        groups.push(filtered_roles[0].name);
      }
    });
    groups.shift();
    return groups;
  }

  private loadScopes() {
    this.rootCoreService.getApplicationTypes({})
      .subscribe(
      (applicationTypes: ApplicationType[]) => {
        const selectedItemIdsInSearch: string[] = this.searchModel.applicationTypeIds.split(',');
        this.searchModel.applicationTypes = [];
        Arrays.iterateByIndex(applicationTypes, (scope) => {
          const item = new ApplicationTypeItem();
          item.id = String(scope.id);
          item.text = scope.name;
          this.applicationTypes.push(item);
          selectedItemIdsInSearch.forEach((selectedItemId) => {
            if (item.text === selectedItemId) {
              this.searchModel.applicationTypes.push(item);
            }
          })
        });
      },
      (error: any) => {
        // Ignored. The interceptor handles the global errors.
      }
    );
  }

  initDropdowns() {
    this.applicationTypeDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .labelKey(OptionItem.KEY_TEXT)
      .build();
    this.companyDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .remoteSearch(true)
      .enableCheckAll(true)
      .build();
  }

  getDisabled(): boolean | undefined {
    if (this.searchModel.disabled!.id === DisabledEnum.FALSE) {
      return false;
    }
    else if (this.searchModel.disabled!.id === DisabledEnum.TRUE) {
      return true;
    }
    return undefined;
  }

  onSearchClicked() {
    this.loadList();
  }

  onSearchReset() {
    this.userGroupSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.showSearch = true;
          this.loadList(1);
        });
      }
    );
    this.searchModel.applicationTypes = [];
  }

  private saveSearch() {
    const request = {
      searchData: {
        itemsPerPage: this.queryModel.itemsPerPage,
        pageNumber: this.queryModel.currentPage,
        order: this.queryModel.getOrder(),
        id: this.searchModel.id,
        name: this.searchModel.name,
        disabled: this.searchModel.disabled,
        adminGroup: this.searchModel.adminGroup,
        applicationTypeIds: this.encodeSelectedApplicationTypes(),
        companies: this.searchModel.companies,
        roles: this.searchModel.roles,
      }
    };
    this.userGroupSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  encodeSelectedApplicationTypes(): string {
    let selectedApplicationTypeIds = '';
    this.searchModel.applicationTypes.forEach((item) => {
      selectedApplicationTypeIds += (',');
      selectedApplicationTypeIds += item.id;
    });
    return selectedApplicationTypeIds.substr(1);
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  private getActiveItem(): UserGroupDisabledItem {
    const item = new UserGroupDisabledItem();
    item.id = 'ACTIVE';
    item.text = 'USER_GROUP_SEARCH_FIELD_DISABLED_ACTIVE';
    return item;
  }

  canCreateGroup(userGroup: UserGroup) {
    if (this.rightModel.userGroupCreateAll.hasRight()) {
      return true;
    }
    return this.rightModel.userGroupCreateMyCompany.hasRight() &&
           this.userData.companies.filter(c => userGroup.company_ids?.includes(c.id)).length > 0;
  }

  canUpdateGroup(userGroup: UserGroup) {
    if (this.rightModel.userGroupUpdateAll.hasRight()) {
      return true;
    }
    return this.rightModel.userGroupUpdateMyCompany.hasRight() &&
           this.userData.companies.filter(c => userGroup.company_ids?.includes(c.id)).length > 0;
  }

  ngOnDestroy() {
    this.saveSearch();
  }

}

interface SearchLoadResult {
  storedSearchData: UserGroupSearch.SearchDataResult,
}
