<div class="form-group" [formGroup]="formGroup" [ngClass]="{ 'has-danger': hasLocalFieldError('value')}">
  <label class="form-control-label text-break" [ngClass]="{ 'required-field-label': required }">{{ model.title }}
    <i *ngIf="model.hint !== ''" class="icon icon-info popover-info-icon" title="{{ model.hint }}"></i>
  </label>
  <input *ngIf="!model.multi_line"
         placeholder="{{ model.placeholder }}" type="text" class="form-control"
         formControlName="value"
         [(ngModel)]="model.value"
         [readonly]="nonEditable"
         [ngClass]="{ 'form-control-danger': hasLocalFieldError('value'), 'no-border': nonEditable }"
         (input)="onInput()">
  <textarea *ngIf="model.multi_line"
            rows="{{UiConstants.textareaDescriptionRowsNumber}}"
            placeholder="{{ model.placeholder }}" type="text" class="form-control"
            formControlName="value"
            [(ngModel)]="model.value"
            [readonly]="nonEditable"
            [ngClass]="{ 'form-control-danger': hasLocalFieldError('value') }"
            (input)="onInput()">
  </textarea>
  <div class="form-control-feedback" *ngIf="hasLocalFieldError('value')">
    <span *ngIf="hasLocalFieldError('value', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
    <span
      *ngIf="hasLocalFieldError('value', 'minlength')">{{'COMMON_VALIDATION_MESSAGE_SPECIFIC_MIN_LENGTH' | translate: {minLength: model.minLength} }}</span>
    <span
      *ngIf="hasLocalFieldError('value', 'maxlength')">{{'COMMON_VALIDATION_MESSAGE_SPECIFIC_MAX_LENGTH' | translate: {maxLength: model.maxLength} }}</span>
  </div>
</div>
