export class SiteTourStringKeys {
  public static readonly SITE_TOUR_RESIZE_DIALOG_TITLE = 'SITE_TOUR_RESIZE_DIALOG_TITLE';
  public static readonly SITE_TOUR_RESIZE_DIALOG_CONTENT = 'SITE_TOUR_RESIZE_DIALOG_CONTENT';

  public static readonly SITE_TOUR_HOME_TOUR_USER_STEP_TITLE = 'SITE_TOUR_HOME_TOUR_USER_STEP_TITLE';
  public static readonly SITE_TOUR_HOME_TOUR_USER_STEP_CONTENT = 'SITE_TOUR_HOME_TOUR_USER_STEP_CONTENT';
  public static readonly SITE_TOUR_HOME_TOUR_TASK_MENU_STEP_TITLE = 'SITE_TOUR_HOME_TOUR_TASK_MENU_STEP_TITLE';
  public static readonly SITE_TOUR_HOME_TOUR_TASK_MENU_STEP_CONTENT = 'SITE_TOUR_HOME_TOUR_TASK_MENU_STEP_CONTENT';
  public static readonly SITE_TOUR_HOME_TOUR_CARD_STEP_TITLE = 'SITE_TOUR_HOME_TOUR_CARD_STEP_TITLE';
  public static readonly SITE_TOUR_HOME_TOUR_CARD_STEP_CONTENT = 'SITE_TOUR_HOME_TOUR_CARD_STEP_CONTENT';
  public static readonly SITE_TOUR_HOME_TOUR_TASK_CHART_STEP_TITLE = 'SITE_TOUR_HOME_TOUR_TASK_CHART_STEP_TITLE';
  public static readonly SITE_TOUR_HOME_TOUR_TASK_CHART_STEP_CONTENT = 'SITE_TOUR_HOME_TOUR_TASK_CHART_STEP_CONTENT';

  public static readonly SITE_TOUR_TASK_DASHBOARD_GLOBAL_STEP_TITLE = 'SITE_TOUR_TASK_DASHBOARD_GLOBAL_STEP_TITLE';
  public static readonly SITE_TOUR_TASK_DASHBOARD_GLOBAL_STEP_CONTENT = 'SITE_TOUR_TASK_DASHBOARD_GLOBAL_STEP_CONTENT';
  public static readonly SITE_TOUR_TASK_DASHBOARD_1_STEP_TITLE = 'SITE_TOUR_TASK_DASHBOARD_1_STEP_TITLE';
  public static readonly SITE_TOUR_TASK_DASHBOARD_1_STEP_CONTENT = 'SITE_TOUR_TASK_DASHBOARD_1_STEP_CONTENT';

  public static readonly SITE_TOUR_TASK_LIST_PANEL_STEP_TITLE = 'SITE_TOUR_TASK_LIST_PANEL_STEP_TITLE';
  public static readonly SITE_TOUR_TASK_LIST_PANEL_STEP_CONTENT = 'SITE_TOUR_TASK_LIST_PANEL_STEP_CONTENT';
  public static readonly SITE_TOUR_TASK_LIST_ADD_STEP_TITLE = 'SITE_TOUR_TASK_LIST_ADD_STEP_TITLE';
  public static readonly SITE_TOUR_TASK_LIST_ADD_STEP_CONTENT = 'SITE_TOUR_TASK_LIST_ADD_STEP_CONTENT';

  public static readonly SITE_TOUR_TASK_RECORD_CREATE_BASE_STEP_TITLE = 'SITE_TOUR_TASK_RECORD_CREATE_BASE_STEP_TITLE';
  public static readonly SITE_TOUR_TASK_RECORD_CREATE_BASE_STEP_CONTENT = 'SITE_TOUR_TASK_RECORD_CREATE_BASE_STEP_CONTENT';
  public static readonly SITE_TOUR_TASK_RECORD_CREATE_NAME_STEP_TITLE = 'SITE_TOUR_TASK_RECORD_CREATE_NAME_STEP_TITLE';
  public static readonly SITE_TOUR_TASK_RECORD_CREATE_NAME_STEP_CONTENT = 'SITE_TOUR_TASK_RECORD_CREATE_NAME_STEP_CONTENT';
  public static readonly SITE_TOUR_TASK_RECORD_CREATE_ASSIGNEE_USER_STEP_TITLE = 'SITE_TOUR_TASK_RECORD_CREATE_ASSIGNEE_USER_STEP_TITLE';
  public static readonly SITE_TOUR_TASK_RECORD_CREATE_ASSIGNEE_USER_STEP_CONTENT =
    'SITE_TOUR_TASK_RECORD_CREATE_ASSIGNEE_USER_STEP_CONTENT';
  public static readonly SITE_TOUR_TASK_RECORD_CREATE_ASSIGNEE_GROUP_STEP_TITLE = 'SITE_TOUR_TASK_RECORD_CREATE_ASSIGNEE_GROUP_STEP_TITLE';
  public static readonly SITE_TOUR_TASK_RECORD_CREATE_ASSIGNEE_GROUP_STEP_CONTENT =
    'SITE_TOUR_TASK_RECORD_CREATE_ASSIGNEE_GROUP_STEP_CONTENT';
  public static readonly SITE_TOUR_TASK_RECORD_CREATE_CUSTOMER_STEP_TITLE = 'SITE_TOUR_TASK_RECORD_CREATE_CUSTOMER_STEP_TITLE';
  public static readonly SITE_TOUR_TASK_RECORD_CREATE_CUSTOMER_STEP_CONTENT = 'SITE_TOUR_TASK_RECORD_CREATE_CUSTOMER_STEP_CONTENT';
  public static readonly SITE_TOUR_TASK_RECORD_CREATE_POC_STEP_TITLE = 'SITE_TOUR_TASK_RECORD_CREATE_POC_STEP_TITLE';
  public static readonly SITE_TOUR_TASK_RECORD_CREATE_POC_STEP_CONTENT = 'SITE_TOUR_TASK_RECORD_CREATE_POC_STEP_CONTENT';
  public static readonly SITE_TOUR_TASK_RECORD_CREATE_FORM_STEP_TITLE = 'SITE_TOUR_TASK_RECORD_CREATE_FORM_STEP_TITLE';
  public static readonly SITE_TOUR_TASK_RECORD_CREATE_FORM_STEP_CONTENT = 'SITE_TOUR_TASK_RECORD_CREATE_FORM_STEP_CONTENT';
  public static readonly SITE_TOUR_TASK_RECORD_CREATE_SAVE_STEP_TITLE = 'SITE_TOUR_TASK_RECORD_CREATE_SAVE_STEP_TITLE';
  public static readonly SITE_TOUR_TASK_RECORD_CREATE_SAVE_STEP_CONTENT = 'SITE_TOUR_TASK_RECORD_CREATE_SAVE_STEP_CONTENT';

  public static readonly SITE_TOUR_TASK_OPEN_PANEL_STEP_TITLE = 'SITE_TOUR_TASK_OPEN_PANEL_STEP_TITLE';
  public static readonly SITE_TOUR_TASK_OPEN_PANEL_STEP_CONTENT = 'SITE_TOUR_TASK_OPEN_PANEL_STEP_CONTENT';
  public static readonly SITE_TOUR_TASK_OPEN_SEARCH_BUTTON_STEP_TITLE = 'SITE_TOUR_TASK_OPEN_SEARCH_BUTTON_STEP_TITLE';
  public static readonly SITE_TOUR_TASK_OPEN_SEARCH_BUTTON_STEP_CONTENT = 'SITE_TOUR_TASK_OPEN_SEARCH_BUTTON_STEP_CONTENT';
  public static readonly SITE_TOUR_TASK_OPEN_SEARCH_PANEL_STEP_TITLE = 'SITE_TOUR_TASK_OPEN_SEARCH_PANEL_STEP_TITLE';
  public static readonly SITE_TOUR_TASK_OPEN_SEARCH_PANEL_STEP_CONTENT = 'SITE_TOUR_TASK_OPEN_SEARCH_PANEL_STEP_CONTENT';
  public static readonly SITE_TOUR_TASK_OPEN_DQL_BUTTON_STEP_TITLE = 'SITE_TOUR_TASK_OPEN_DQL_BUTTON_STEP_TITLE';
  public static readonly SITE_TOUR_TASK_OPEN_DQL_BUTTON_STEP_CONTENT = 'SITE_TOUR_TASK_OPEN_DQL_BUTTON_STEP_CONTENT';
  public static readonly SITE_TOUR_TASK_OPEN_SORT_STEP_TITLE = 'SITE_TOUR_TASK_OPEN_SORT_STEP_TITLE';
  public static readonly SITE_TOUR_TASK_OPEN_SORT_STEP_CONTENT = 'SITE_TOUR_TASK_OPEN_SORT_STEP_CONTENT';
  public static readonly SITE_TOUR_TASK_OPEN_CHECK_BOX_STEP_TITLE = 'SITE_TOUR_TASK_OPEN_CHECK_BOX_STEP_TITLE';
  public static readonly SITE_TOUR_TASK_OPEN_CHECK_BOX_STEP_CONTENT = 'SITE_TOUR_TASK_OPEN_CHECK_BOX_STEP_CONTENT';
  public static readonly SITE_TOUR_TASK_OPEN_OPEN_BUTTON_STEP_TITLE = 'SITE_TOUR_TASK_OPEN_OPEN_BUTTON_STEP_TITLE';
  public static readonly SITE_TOUR_TASK_OPEN_OPEN_BUTTON_STEP_CONTENT = 'SITE_TOUR_TASK_OPEN_OPEN_BUTTON_STEP_CONTENT';
  public static readonly SITE_TOUR_TASK_OPEN_APPROVE_DIALOG_STEP_TITLE = 'SITE_TOUR_TASK_OPEN_APPROVE_DIALOG_STEP_TITLE';
  public static readonly SITE_TOUR_TASK_OPEN_APPROVE_DIALOG_STEP_CONTENT = 'SITE_TOUR_TASK_OPEN_APPROVE_DIALOG_STEP_CONTENT';
  public static readonly SITE_TOUR_TASK_OPEN_APPROVE_SAVE_STEP_TITLE = 'SITE_TOUR_TASK_OPEN_APPROVE_SAVE_STEP_TITLE';
  public static readonly SITE_TOUR_TASK_OPEN_APPROVE_SAVE_STEP_CONTENT = 'SITE_TOUR_TASK_OPEN_APPROVE_SAVE_STEP_CONTENT';

  public static readonly SITE_TOUR_ADMIN_TOUR_CONTENT_STEP_TITLE = 'SITE_TOUR_ADMIN_TOUR_CONTENT_STEP_TITLE';
  public static readonly SITE_TOUR_ADMIN_TOUR_CONTENT_STEP_CONTENT = 'SITE_TOUR_ADMIN_TOUR_CONTENT_STEP_CONTENT';
  public static readonly SITE_TOUR_ADMIN_TOUR_USER_CARD_STEP_TITLE = 'SITE_TOUR_ADMIN_TOUR_USER_CARD_STEP_TITLE';
  public static readonly SITE_TOUR_ADMIN_TOUR_USER_CARD_STEP_CONTENT = 'SITE_TOUR_ADMIN_TOUR_USER_CARD_STEP_CONTENT';
}
