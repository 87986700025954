<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div class="breadcrumb-menu d-flex">
    <!--Export-->
    <div class="breadcrumb-button-icon-container cursor-pointer"
         href="#" onclick="return false" (click)="exportHtml()">
      <a class="btn-setting d-flex align-items-center" title="{{'COMMON_BUTTON_EXPORT' | translate}}">
        <i class="icomoon icomoon-csv-import"></i>
        <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_EXPORT' | translate}}</span>
      </a>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-vertical-padding-half animated fadeIn">
  <div class="container-vertical-padding-half">
    <div class=" col-lg-8 col-md-8 col-xs-12 col-sm-12">
      <div class="card">
        <div class="card-header">
          {{'AUDIT_LOG_DETAIL_TITLE'|translate}}
        </div>

        <div class="card-body">
          <form class="form-horizontal" #f="ngForm" novalidate>

            <div class="inner-card">
              <label class="inner-card-title">{{'COMMON_BASIC_DATA' | translate}}</label>

              <div class="row">
                <div class="form-group col-md-4">
                  <label readonly class="detail-title">{{'AUDIT_LOG_DETAIL_USER_NAME'|translate}}</label>
                </div>
                <div class="form-group col-md-8">
                  <label readonly class="detail-description" id="user_name">{{
                    model.user_name }}</label>
                </div>
              </div>

              <div class="row" *ngIf="Strings.undefinedOrNonEmpty(model.device_id)">
                <div class="form-group col-md-4">
                  <label readonly class="detail-title">{{'AUDIT_LOG_TABLE_HEADER_DEVICE_ID'|translate}}</label>
                </div>
                <div class="form-group col-md-8">
                  <label readonly class="detail-description" id="device_id">{{
                      model.device_id }}</label>
                </div>
              </div>

              <div class="row" *ngIf="Strings.undefinedOrNonEmpty(model.application_id)">
                <div class="form-group col-md-4">
                  <label readonly class="detail-title">{{'COMMON_APP_ID'|translate}}</label>
                </div>
                <div class="form-group col-md-8">
                  <label readonly class="detail-description" id="application_id">{{
                      model.application_id }}</label>
                </div>
              </div>

              <div class="row" *ngIf="Strings.undefinedOrNonEmpty(model.application_name)">
                <div class="form-group col-md-4">
                  <label readonly class="detail-title">{{'AUDIT_LOG_DETAIL_APPLICATION_NAME'|translate}}</label>
                </div>
                <div class="form-group col-md-8">
                  <label readonly class="detail-description" id="application_name">{{
                      model.application_name }}</label>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-md-4">
                  <label readonly class="detail-title">{{'AUDIT_LOG_DETAIL_IP_ADDRESS'|translate}}</label>
                </div>
                <div class="form-group col-md-8">
                  <label readonly class="detail-description" id="ip_address">{{
                    model.ip_address }}</label>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-md-4">
                  <label readonly class="detail-title">{{'COMMON_CREATION_TIME'|translate}}</label>
                </div>
                <div class="form-group col-md-8">
                  <label readonly class="detail-description" id="creation_time">{{
                    model.creation_time | date:'medium'}}</label>
                </div>
              </div>

              <div class="row" *ngIf="Strings.undefinedOrNonEmpty(model.finish_time)">
                <div class="form-group col-md-4">
                  <label readonly class="detail-title">{{'COMMON_FINISH_TIME'|translate}}</label>
                </div>
                <div class="form-group col-md-8">
                  <label readonly class="detail-description" id="finish_time">{{
                      model.finish_time | date:'medium'}}</label>
                </div>
              </div>

              <div class="row" *ngIf="model.duration_in_milliseconds">
                <div class="form-group col-md-4">
                  <label readonly class="detail-title">{{'COMMON_DURATION'|translate}}</label>
                </div>
                <div class="form-group col-md-8">
                  <label readonly class="detail-description" id="duration">{{
                      model.duration_in_milliseconds }} ms</label>
                </div>
              </div>

              <label class="inner-card-title">{{'AUDIT_LOG_DETAIL_LOG_DATA' | translate}}</label>

              <div class="row" *ngIf="Strings.undefinedOrNonEmpty(model.result)">
                <div class="form-group col-md-4">
                  <label readonly class="detail-title">{{'COMMON_METHOD_RESULT'|translate}}</label>
                </div>
                <div class="form-group col-md-8">
                  <label readonly class="detail-description" id="result">{{
                    model.result }}</label>
                </div>
              </div>

              <div class="row" *ngIf="Strings.undefinedOrNonEmpty(model.api_operation_definition)">
                <div class="form-group col-md-4">
                  <label readonly class="detail-title">{{'AUDIT_LOG_DETAIL_API_OPERATION_DEFINITION'|translate}}</label>
                </div>
                <div class="form-group col-md-8">
                  <label readonly class="detail-description" id="api_operation_definition">{{
                      model.api_operation_definition }}</label>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-md-4">
                  <label readonly class="detail-title">{{'AUDIT_LOG_DETAIL_REQUEST_URI'|translate}}</label>
                </div>
                <div class="form-group col-md-8">
                  <label readonly class="detail-description text-break" id="request_uri">{{
                    model.request_uri }}</label>
                </div>
              </div>

              <div class="row" *ngIf="Strings.undefinedOrNonEmpty(model.request_query)">
                <div class="form-group col-md-4">
                  <label readonly class="detail-title">{{'AUDIT_LOG_DETAIL_REQUEST_QUERY'|translate}}</label>
                </div>
                <div class="form-group col-md-8">
                  <label readonly class="detail-description text-break" id="request_query">{{
                    model.request_query }}</label>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-md-4">
                <label readonly class="detail-title">{{'COMMON_METHOD'|translate}}</label>
              </div>
              <div class="form-group col-md-8">
                <label readonly class="detail-description" id="request_method">{{
                  model.request_method }}</label>
              </div>
            </div>

            <div class="row" *ngIf="Strings.undefinedOrNonEmpty(model.content_type)">
              <div class="form-group col-md-4">
                <label readonly class="detail-title">{{'AUDIT_LOG_CONTENT_TYPE'|translate}}</label>
              </div>
              <div class="form-group col-md-8">
                <label readonly class="detail-description" id="content_type">{{
                    model.content_type }}</label>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-md-4">
                <label readonly class="detail-title">{{'AUDIT_LOG_CALL_TYPE'|translate}}</label>
              </div>
              <div class="form-group col-md-8">
                <label readonly class="detail-description" id="call_type">{{
                    model.call_type | translate}}</label>
              </div>
            </div>

          </form>
        </div>
      </div>

      <div class="container-vertical-padding-half"></div>

    </div>

    <div class=" col-lg-12 col-md-12 col-xs-12 col-sm-12">
      <div class="card">
        <div class="card-header">
          {{'AUDIT_LOG_DETAIL_TITLE'|translate}}
        </div>

        <div class="card-body">
          <form class="form-horizontal" #f="ngForm" novalidate>

            <div class="inner-card">

              <div class="d-flex" *ngIf="Strings.undefinedOrNonEmpty(model.request_json)">
                <div class="form-group mr-1">
                  <label readonly class="detail-title">{{'AUDIT_LOG_DETAIL_REQUEST'|translate}}</label>
                </div>
                <div class="form-group">
                  <app-json *ngIf="isJson(model.content_type)" id="request" [content]="model.request_json"></app-json>
                  <!-- 'pre' and 'code' selectors must be in the same line-->
                  <pre *ngIf="!isJson(model.content_type)" class="long-code p-0"><code class="p-0 hljs xml" [highlight]="model.request_json"></code></pre>
                </div>
              </div>

              <div class="d-flex" *ngIf="Strings.undefinedOrNonEmpty(model.response_json)">
                <div class="form-group mr-1">
                  <label readonly class="detail-title">{{'AUDIT_LOG_DETAIL_RESPONSE'|translate}}</label>
                </div>
                <div class="form-group">
                  <app-json *ngIf="isJson(model.content_type)" id="response" [content]="model.response_json"></app-json>
                  <!-- 'pre' and 'code' selectors must be in the same line-->
                  <pre *ngIf="!isJson(model.content_type)" class="long-code p-0"><code class="p-0 hljs xml" [highlight]="model.response_json"></code></pre>
                </div>
              </div>

              <div class="d-flex" *ngIf="Strings.undefinedOrNonEmpty(model.exception)">
                <div class="form-group mr-1">
                  <label readonly class="detail-title">{{'AUDIT_LOG_DETAIL_EXCEPTION'|translate}}</label>
                </div>
                <div class="form-group">
                  <pre class="long-code" id="exception">{{ model.exception }}</pre>
                </div>
              </div>

            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
