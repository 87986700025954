import { Injectable } from '@angular/core';
import { NewBaseSearch, NewBaseSearchService } from '../new-base.search-service';
import { OrderField } from '../query/orderfields';
import { SearchUtils } from '../../util/search-utils';
import { MultiselectOptionItem, OrderFieldModel, OrderFunctionResolver } from '../../util/core-utils';
import { OrderType } from '../util/services';
import { LoggedInUserStorage } from '../util/storages';

@Injectable()
export class ShopRenterSearchService extends NewBaseSearchService<OrderField.ShopRenter> {

  protected createStorage(): ShopRenterSearch.Storage {
    return new ShopRenterSearch.Storage();
  }

}

export namespace ShopRenterSearch {

  export class Model extends SearchUtils.SearchModel {

    showSearch: boolean = false;

    id?: number;
    name: string;
    humanName: string;
    user: MultiselectOptionItem<number>[];
    company: MultiselectOptionItem<number>[];

    get userId(): number | undefined {
      if (this.user.length > 0 && this.user[0].id !== null) {
        return this.user[0].id!;
      }
      return undefined;
    }

    get companyId(): number | undefined {
      if (this.company.length > 0 && this.company[0].id !== null) {
        return this.company[0].id!;
      }
      return undefined;
    }

    constructor() {
      super();
      this.reset();
    }

    reset() {
      this.id = undefined;
      this.name = '';
      this.humanName = '';
      this.user = [];
      this.company = [];
    }

    isEmpty(): boolean {
      return this.name === '' &&
        this.humanName === '' &&
        this.user.length === 0 &&
        this.company.length === 0 &&
        this.id === undefined;
    }

  }

  export namespace OrderFunctions {
    export const ID = (f: OrderField.ShopRenter) => f.id;
    export const NAME = (f: OrderField.ShopRenter) => f.name;
    export const HUMAN_NAME = (f: OrderField.ShopRenter) => f.humanName;

    export const VALUES = OrderFunctionResolver.builder<OrderField.ShopRenter>()
      .add(ID, 'id')
      .add(NAME, 'name')
      .add(HUMAN_NAME, 'human_name')
      .build();
  }

  export interface SearchDataResult {
    searchData: SearchData;
  }

  export interface SearchData extends NewBaseSearch.SearchData<OrderField.ShopRenter> {
    searchModel: Model;
  }

  interface StoredSearchRawData extends NewBaseSearch.StoredSearchRawData<OrderField.ShopRenter> {
    id?: number;
    name: string;
    humanName: string;
    user: MultiselectOptionItem<number>[];
    company: MultiselectOptionItem<number>[];
  }

  export class Storage extends NewBaseSearch.Storage<OrderField.ShopRenter> {

    constructor() {
      const defaultSearchData: SearchData = {
        queryModel: new OrderFieldModel(OrderFunctions.ID, OrderType.DESC),
        searchModel: new Model(),
      };
      /**
       * Increment this version if you change the SearchData interface.
       */
      const versionNumber: number = 1;
      super(defaultSearchData,
        versionNumber);
    }

    protected generateKey(): string {
      return 'UserId(' + LoggedInUserStorage.getInstance().getUserId() + ')-Search-ShopRenter';
    }

    protected fromRaw(data: StoredSearchRawData): NewBaseSearch.SearchDataResult<OrderField.ShopRenter> {
      const searchModel = new Model();
      searchModel.id = data.id;
      searchModel.name = data.name;
      searchModel.humanName = data.humanName;
      searchModel.user = data.user;
      searchModel.company = data.company;
      const searchData: SearchData = {
        queryModel: OrderFieldModel.create<OrderField.ShopRenter>(OrderFunctions.VALUES, data.order, data.queryModel),
        searchModel: searchModel,
      };
      return {
        searchData: searchData
      };
    }

    protected toRaw(data: SearchData): StoredSearchRawData {
      return {
        version: this.VERSION,
        queryModel: data.queryModel,
        order: data.queryModel.createOrder(OrderFunctions.VALUES),
        id: data.searchModel.id,
        name: data.searchModel.name,
        humanName: data.searchModel.humanName,
        user: data.searchModel.user,
        company: data.searchModel.company,
      };
    }

  }

}
