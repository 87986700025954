<div [ngStyle]="{'width': size + 'px', 'height': size + 'px'}">
  <ngx-avatars *ngIf="profilePicture === undefined"
              size="{{size}}"
              name="{{userPersonName}}"
              textSizeRatio="2"
              initialsSize="2"
              [round]="true"
              [bgColor]="'#e3e8ec'"
              [fgColor]="'#000000'">
  </ngx-avatars>
  <img *ngIf="profilePicture !== undefined"
       class="rounded-circle"
       [ngStyle]="{'width': size + 'px', 'height': size + 'px'}"
       [src]="sanitizer.bypassSecurityTrustUrl(profilePicture)">
</div>
