import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { StateName } from '../../../app.state-names';
import { TranslateService } from '@ngx-translate/core';
import { TriggerPreselectDialogComponent } from '../../trigger/trigger-preselect-dialog/trigger-preselect-dialog.component';
import { TriggerEditDialogComponent } from '../../trigger/trigger-edit-dialog/trigger-edit-dialog.component';
import { TriggerListComponent } from '../../trigger/trigger-list/trigger-list.component';
import { TransportTriggerService } from '../../../lib/transport/transport-trigger/transport-trigger.service';
import { RightModel } from '../../../app.rights';
import { RightResolver, RightService } from '../../../lib/right.service';

@Component({
  selector: 'app-transport-trigger-list',
  templateUrl: './transport-trigger-list.component.html',
  styleUrls: ['./transport-trigger-list.component.scss']
})
export class TransportTriggerListComponent implements OnInit, AfterViewInit {

  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  rightModel: RightModel = RightModel.empty();

  @ViewChild('triggerPreselectDialog', { static: true })
  triggerPreselectDialog: TriggerPreselectDialogComponent;

  @ViewChild('triggerEditDialog', { static: true })
  triggerEditDialog: TriggerEditDialogComponent;

  @ViewChild('triggerList', { static: true })
  triggerList: TriggerListComponent;

  constructor(private translateService: TranslateService,
              private transportTriggerService: TransportTriggerService,
              private rightService: RightService) { }

  ngOnInit() {
    this.initBreadcrumb();
  }

  ngAfterViewInit() {
    this.triggerList.loadList();
  }

  initBreadcrumb() {
    this.translateService.get('TRANSPORT_TRIGGER_LIST').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
  }

  onTriggerCreated() {
    this.triggerList.onTriggerCreated();
  }

  getTransportTriggerService(): TransportTriggerService {
    return this.transportTriggerService;
  }

}
