/* eslint-disable */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  CustomerRecord,
  CustomerRecordContactPerson,
  CustomerRecordService,
} from '../../../lib/customer/customer-record.service';
import { OrderType, QueryResult } from '../../../lib/util/services';
import { QueryFieldModel, UiConstants } from '../../../util/core-utils';
import { List, Set } from 'immutable';
import { RightModel } from '../../../app.rights';
import { Address } from '../../../lib/address';
import { TranslateService } from '@ngx-translate/core';
import PostalAddressData = Address.PostalAddressData;
import { ConfigurationService } from '../../../lib/core-ext/configuration.service';
import { StringKey } from '../../../app.string-keys';
import { ToasterService } from '../../../fork/angular2-toaster/src/toaster.service';
import {
  CustomerRecordQuickCreateMaterialDialogComponent
} from '../customer-record-quick-create-material/customer-record-quick-create-material-dialog.component';
import { MatDialog } from '@angular/material/dialog';

/* eslint-enable */

@Component({
  selector: 'app-customer-record-contact-persons',
  templateUrl: './customer-record-contact-persons.component.html',
  styleUrls: ['./customer-record-contact-persons.component.scss']
})
export class CustomerRecordContactPersonsComponent implements OnInit {
  CustomerRecordContactPerson = CustomerRecordContactPerson;
  pagingId = 'customerRecordContactPersonsList';

  @Input()
  readonly: boolean = true;

  @Input()
  rightModel: RightModel = RightModel.empty();

  @Input()
  customerId: number;

  @Input()
  customerRecordId: number;

  @Output()
  contactPersonCreated: EventEmitter<CustomerRecord.CustomerRecord> = new EventEmitter();

  @Output()
  addContactPerson: EventEmitter<any> = new EventEmitter();

  @Output()
  inviteToHelpdesk: EventEmitter<number[]> = new EventEmitter();

  queryModel: QueryFieldModel<CustomerRecordContactPerson.OrderField> =
    new QueryFieldModel(CustomerRecordContactPerson.OrderField.NAME, OrderType.ASC);

  customerRecordList: {
    selected: boolean,
    cr: CustomerRecord.CustomerRecord
  }[] = [];

  private postalAddressFormat: string;

  constructor(
    private customerRecordService: CustomerRecordService,
    private translateService: TranslateService,
    private toasterService: ToasterService,
    private configService: ConfigurationService,
    private dialog: MatDialog
  ) {
  }

  ngOnInit() {
    this.postalAddressFormat = this.configService.getPostalAddressFormat();
  }

  initComponent() {
    this.loadList(1);
  }
  loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();
    this.customerRecordService.queryContactPersons({
      customerId: this.customerId,
      customerRecordId: this.customerRecordId,
      orders: Set.of(order),
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined
    }).subscribe((result: QueryResult<CustomerRecord.CustomerRecord>) => {
      this.customerRecordList = result.items.toArray().map(cr => ({selected: false, cr: cr}));
      this.queryModel.currentPage = requestedPage;
      this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
    });
  }

  orderBy(field: CustomerRecordContactPerson.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  removeContactPerson(id: number) {
    this.customerRecordService.removeContactPerson({
      customerId: this.customerId,
      customerRecordId: this.customerRecordId,
      contactPersonId: id
    }).subscribe(() => {
      this.loadList(1);
    });
  }

  getEmailAddressTypeName(email: Address.EmailAddressData) {
    const languageCode = this.translateService.currentLang.substr(0, 2);
    return email.typeNames![languageCode];
  }

  formatPostalAddress(postalAddress?: PostalAddressData): string {
    return postalAddress ? Address.PostalAddressMapper.toString(postalAddress, this.postalAddressFormat) : '';
  }

  get eachCustomerRecordSelected(): boolean {
    let selected = this.customerRecordList.length > 0;
    this.customerRecordList.forEach((cr) => {
      selected = selected && cr.selected;
    });
    return selected;
  }

  get selectedCustomerRecordIds(): number[] {
    if (this.customerRecordList.length > 0) {
      return this.customerRecordList.filter(cr => cr.selected).map(cr => cr.cr.customerRecordId);
    }
    return [];
  }

  toggleEachCustomerRecord() {
    const eachCustomerRecordSelected = !this.eachCustomerRecordSelected;
    this.customerRecordList.forEach(cr => {
      cr.selected = eachCustomerRecordSelected;
    });
  }

  tryInviteToHelpdesk() {
    const ids = this.selectedCustomerRecordIds;
    if (ids.length > 0) {
      this.inviteToHelpdesk.emit(ids);
    }
    else {
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutLong,
        type: UiConstants.toastTypeError,
        title: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_TITLE),
        body: this.translateService.instant(StringKey.CUSTOMER_RECORD_INVITE_TO_HELPDESK_UNSELECTED_ERROR)
      });
    }
  }

  createContactPerson() {
    const forContactPerson = {customerId: this.customerId, customerRecordId: this.customerRecordId};
    CustomerRecordQuickCreateMaterialDialogComponent.openDialog(this.dialog,
      {forContactPersonData: forContactPerson},
      result => {
        if (result?.success) {
          if (result.customerRecord) {
            this.contactPersonCreated.emit(result.customerRecord);
            this.loadList();
          }
        }
      }
    )
  }

}
