/* eslint-disable */
import { Set as ImmutableSet } from 'immutable';
import { FieldViewItem } from '../form-record-inactivity-manager-api';

/* eslint-enable */

export class FieldActivationStrategyUtils {

  static collectViewItemsFromFieldIdSet(set: ImmutableSet<number>, viewItems: FieldViewItem[]): FieldViewItem[] {
    const a: FieldViewItem[] = [];
    set.forEach((id) => {
      const found = FieldActivationStrategyUtils.findViewItemByFieldId(viewItems, id!);
      if (found) {
        a.push(found);
      }
    });
    return a;
  }

  static findViewItemByFieldId(viewItems: FieldViewItem[], fieldId: number): FieldViewItem | undefined {
    const a = viewItems.filter((value) => {
      return value.fieldId === fieldId;
    });
    // The array might have more than one item because the list multi item
    // field could enable the same field view on multiple list items selected.
    if (a.length > 0) {
      return a[0];
    }
    return undefined;
  }

  static without(complete: FieldViewItem[], removables: FieldViewItem[]) {
    return complete.filter((item) => {
      return removables.indexOf(item) === -1;
    });
  }

  private constructor() {
  }

}
