import { Injectable } from '@angular/core';
import { MultiselectProvider } from '../../multiselect-provider';
import { Observable, Observer } from 'rxjs';
import { MultiselectOptionItem, ProjectItem, UiConstants } from '../../../util/core-utils';
import { OrderType, QueryResult } from '../../util/services';
import { Set } from 'immutable';
import { ProjectRecord, ProjectRecordService } from './project-record.service';

@Injectable()
export class ProjectRecordMultiselectProvider implements MultiselectProvider {

  private multiselectQueryRequest: ProjectRecord.GlobalQueryRequest = {
    paging: {
      pageNumber: 1,
      numberOfItems: UiConstants.autocompletePageSize
    },
    noProgressBar: true,
    orders: Set.of({field: ProjectRecord.OrderField.NAME, type: OrderType.ASC}),
    disabled: undefined,
    queryText: undefined
  };

  constructor(private projectService: ProjectRecordService) {
  }

  load(queryRequest: ProjectRecord.GlobalQueryRequest): Observable<ProjectItem[]> {
    return Observable.create((observer: Observer<ProjectItem[]>) => {
      this.projectService.globalQuery(queryRequest).subscribe((result: QueryResult<ProjectRecord.ProjectRecord>) => {
          observer.next(result.items.toArray().map(item => this.toMultiselectOptionItem(item)));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  loadAll(predicate?: string): Observable<ProjectItem[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.name = predicate;
    return this.load(queryRequest);
  }

  loadActive(predicate?: string): Observable<ProjectItem[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.name = predicate;
    queryRequest.disabled = false;
    return this.load(queryRequest);
  }

  getById(id: number): Observable<ProjectItem> {
    return Observable.create((observer: Observer<ProjectItem>) => {
      this.projectService.globalGet({
        id: id
      }).subscribe((result: ProjectRecord.ProjectRecord) => {
          observer.next(this.toMultiselectOptionItem(result));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  getByIds(ids: number[]): Observable<ProjectItem[]> {
    const queryRequest = Object.assign([], this.multiselectQueryRequest);
    queryRequest.projectRecordIdSet = Set.of(...ids);
    return this.load(queryRequest);
  }

  toMultiselectOptionItem(item: ProjectRecord.ProjectRecord): ProjectItem {
    const ret = new ProjectItem();
    ret.id = item.id;
    ret.itemName = item.name;
    ret.itemSubtitle = item.externalId;
    ret.disabled = item.disabled;
    ret.projectId = item.project.id;
    return ret;
  }

}
