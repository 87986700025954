/* eslint-disable */
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Transition } from '@uirouter/angular';
import { TranslateService } from '@ngx-translate/core';
import { DocumentLanguageItem, DocumentTextReadModel } from '../../../../util/document/document-utils';
import { Arrays } from '../../../../lib/util/arrays';
import { DocumentLanguageService } from '../../../../lib/document/document-language.service';
import { DocumentLanguageUtil } from '../../../../util/document/document-language-utils';
import { DocumentGroup } from '../../../../lib/document/document-group.service';
import { DocumentGroupService } from '../../../../lib/document/document-group.service';
import { DocumentLink, DocumentLinkService } from '../../../../lib/document/document-link.service';
import { BreadcrumbParent } from '../../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { StateName } from '../../../../app.state-names';
import { ResourceQueryResult } from '../../../../lib/util/services';
import { StringKey } from '../../../../app.string-keys';
/* eslint-enable */

@Component({
  selector: 'app-document-link-read',
  templateUrl: 'document-link-read.component.html',
  styleUrls: ['document-link-read.component.scss']
})
export class DocumentLinkReadComponent implements OnInit, AfterViewInit {

  model: DocumentTextReadModel;
  documentId: number;
  documentGroupList: DocumentGroup[] = [];
  documentLanguageList: DocumentLanguageItem[] = [];
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  constructor(private translateService: TranslateService,
              private documentLanguageService: DocumentLanguageService,
              private documentLanguageUtil: DocumentLanguageUtil,
              private documentGroupService: DocumentGroupService,
              private documentTextService: DocumentLinkService,
              private transition: Transition) {
    this.documentId = this.transition.params().id;
    this.model = new DocumentTextReadModel();
  }

  ngOnInit() {
    this.translateService.get('COMMON_DOCUMENTS').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.DOCUMENT_DASHBOARD});
      }
    );
    this.translateService.get('MENU_NAVBAR_DOCUMENT_LINK').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.DOCUMENT_LINK_LIST});
      }
    );
  }

  ngAfterViewInit() {
    this.loadDocumentGroups(() => {
      this.loadLanguageCodes();
      this.loadModel();
    });
  }

  loadModel() {
    this.documentTextService.get({
      id: this.documentId
    }).subscribe((documentLink: DocumentLink) => {
      const readModel = new DocumentTextReadModel();
      readModel.id = documentLink.id;
      readModel.name = documentLink.name;
      readModel.code = documentLink.code;
      readModel.value = documentLink.value;
      readModel.comment = documentLink.comment;
      readModel.creation_time = documentLink.creation_time;
      readModel.update_time = documentLink.update_time;
      readModel.disabled = documentLink.disabled;
      readModel.document_group = {
        id: documentLink.document_group_id,
        text: this.getDocumentGroupName(documentLink.document_group_id)
      };
      this.loadLanguageCodes(documentLink.language_code);
      this.model = readModel;
      this.breadcrumbSelf = readModel.name;
    });
  }

  private loadLanguageCodes(language_code?: string) {
    this.documentLanguageService.query().subscribe((result: string[]) => {
      const def = this.createDefaultLanguageCode();
      this.model.language_code = def;
      this.documentLanguageList.push(def);

      Arrays.iterateByIndex(result, language => {
        const languageItem = new DocumentLanguageItem();
        languageItem.id = language;
        languageItem.text = this.documentLanguageUtil.getDocumentLanguageName(language);

        if (languageItem.id === language_code) {
          this.model.language_code = languageItem;
        }
        this.documentLanguageList.push(languageItem);
      });
    });
  }

  private createDefaultLanguageCode(): DocumentLanguageItem {
    const def = new DocumentLanguageItem();
    def.id = null;
    def.text = '';
    this.translateService.get(StringKey.COMMON_VALUE_UNSELECTED).subscribe(
      (text: string) => {
        def.text = text;
      }
    );
    return def;
  }

  loadDocumentGroups(completion: () => void) {
    this.documentGroupService.query({}).subscribe((result: ResourceQueryResult<DocumentGroup>) => {

        result.items.forEach(group => {
          this.documentGroupList.push(group);
        });
        completion();

      }, (error: any) => {
        completion();
      });
  }

  getDocumentGroupName(documentGroupId?: number): string {
    if (!documentGroupId) {
      return '';
    }

    let groupName = '';
    Arrays.iterateByIndex(this.documentGroupList, group => {
      if (group.id === documentGroupId) {
        groupName = group.name;
      }
    });
    return groupName;
  }

  back() {
    window.history.back();
  }

}
