/* eslint-disable */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DqlExpressionBuilder, SimpleDqlExpressionBuilder } from '../../dql-search-container/dql-search-container.model';
import { DqlUnarySearchFieldComponent } from '../dql-search-field.component';
import { InputMask } from '../../../../util/input-masks';

/* eslint-enable */

@Component({
  selector: 'app-dql-search-field-unary',
  templateUrl: './dql-search-field-unary.component.html',
  styleUrls: ['./dql-search-field-unary.component.scss']
})
export class DqlSearchFieldUnaryComponent implements OnInit, DqlUnarySearchFieldComponent {
  @Input()
  disableDelete: boolean = false;

  @Output()
  onDelete: EventEmitter<DqlExpressionBuilder> = new EventEmitter<DqlExpressionBuilder>();

  InputMask = InputMask;

  @Input()
  expression: SimpleDqlExpressionBuilder;

  constructor() {
  }

  ngOnInit() {
  }

}
