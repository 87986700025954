<div class="form-group mb-0" [ngClass]="{'border-bottom': nonEditable}">
  <label class="form-control-label text-break">{{ model.title }}</label>
</div>
<div class="card-body">
  <app-material-attachment-gallery
    [loaderFactory]="fileLoaderFactory"
    [customFileUploader]="fileUploader"
    [multiQueue]="true"
    [maxNumberOfItems]="maxNumberOfPictures"
    [deleteAllowed]="!nonEditable"
    [displayUpload]="!nonEditable && (model.multiSelect || model.values.length < 1)">
  </app-material-attachment-gallery>
</div>
