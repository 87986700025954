<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="p-0 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12">
    <div class="card">

      <div class="card-header">
        {{'STOCK_PANEL_HEADING_EDIT'|translate}}
      </div>

      <div class="card-body">
        <form class="form-horizontal bordered-row" (ngSubmit)="f.form.valid && update()" #f="ngForm" novalidate>

          <div class="form-group row" [ngClass]="{ 'has-danger': (fieldErrors.name) || (f.submitted && !name.valid) }">
            <label class="col-form-label form-control-label col-md-4 detail-title required-field-label">
              {{'COMMON_NAME' | translate}}
            </label>
            <div class="col-md-8">
              <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     [ngClass]="{ 'form-control-danger': (fieldErrors.name) || (f.submitted && !name.valid) }"
                     [(ngModel)]="model.name" (ngModelChange)="removeFieldError(fieldErrors.name)" name="name"
                     id="person_name" #name="ngModel" required>
              <div class="form-control-feedback" *ngIf="fieldErrors.name">{{fieldErrors.name.text}}</div>
              <div class="form-control-feedback" *ngIf="f.submitted && !name.valid">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED'
                | translate}}
              </div>
            </div>
          </div>

          <div class="form-group row" [ngClass]="{ 'has-danger': (fieldErrors.externalId) || (f.submitted && !externalId.valid) }">
            <label class="col-form-label form-control-label col-md-4 detail-title required-field-label">
              {{'STOCK_EXTERNAL_ID' | translate}}
            </label>
            <div class="col-md-8">
              <input type="text" class="form-control" placeholder="{{'STOCK_EXTERNAL_ID' | translate}}"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     [ngClass]="{ 'form-control-danger': (fieldErrors.externalId) || (f.submitted && !externalId.valid) }"
                     [(ngModel)]="model.externalId" (ngModelChange)="removeFieldError(fieldErrors.externalId)" name="externalId"
                     id="externalId" #externalId="ngModel" required>
              <div class="form-control-feedback" *ngIf="fieldErrors.externalId">{{fieldErrors.externalId.text}}</div>
              <div class="form-control-feedback" *ngIf="f.submitted && !externalId.valid">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED'
                | translate}}
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-form-label form-control-label col-md-4 detail-title">{{'STOCK_TYPE' | translate}}</label>
            <div class="col-md-8">
              <select class="form-control" [(ngModel)]="model.type"
                      id="stockType" name="stockType" #stockType="ngModel">
                <option *ngFor="let stockType of stockTypes" [ngValue]="stockType.name">
                  {{stockType.textDictionaryKey | translate}}
                </option>
              </select>
            </div>
          </div>

          <div *ngIf="model.type === 'PERSONAL'" class="form-group row" [ngClass]="{ 'has-error': fieldErrors.owner_user_id }">
            <label class="col-form-label form-control-label col-md-4 detail-title">{{'STOCK_LABEL_OWNER_USER' |
              translate}}</label>
            <div class="col-md-8">
              <angular2-multiselect [data]="ownerUsers" [settings]="userDropdownSettings"
                                    class="form-control"
                                    (ngModelChange)="onOwnerUserChange(fieldErrors.owner_user_id)"
                                    [ngClass]="{ 'form-control-danger': (fieldErrors.owner_user_id) }"
                                    [(ngModel)]="model.owner_users"
                                    name="owner_user"
                                    id="owner_user"
                                    #owner_user="ngModel">
              </angular2-multiselect>
              <span class="help-block" *ngIf="fieldErrors.owner_user_id">{{fieldErrors.owner_user_id.text}}</span>
              <div class="form-control-feedback" *ngIf="f.submitted && !owner_user.valid">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
          </div>

          <div *ngIf="model.type === 'CUSTOMER'" class="form-group row" [ngClass]="{ 'has-danger': (fieldErrors.customer_record_id) || (f.submitted && !customerRecord.valid) }">
            <div class="col-md-4">
              <label
                class="mw-100 col-form-label form-control-label required-field-label">{{'STOCK_CREATE_EDIT_CUSTOMER_RECORD'|translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  [(ngModel)]="model.customerRecord"
                  [data]="customerRecords"
                  (onRemoteSearch)="searchCustomerRecord($event.target.value)"
                  (onSelect)="onCustomerRecordChanged()"
                  (onDeSelect)="onCustomerRecordChanged()"
                  name="customerRecord"
                  id="customerRecord"
                  [ngClass]="{ 'form-control-danger': (fieldErrors.customer_record_id) || (f.submitted && !customerRecord.valid) }"
                  #customerRecord="ngModel"
                  [settings]="customerDropdownSettings"
                  [required]="model.type === 'CUSTOMER'">
                </angular2-multiselect>
              </div>
              <div class="form-control-feedback" *ngIf="f.submitted && !customerRecord.valid">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
          </div>

          <div *ngIf="model.type === 'CUSTOMER' && model.customerRecord.length === 1" class="form-group row">
            <div class="col-md-4">
              <label
                class="mw-100 col-form-label form-control-label">{{'STOCK_CREATE_EDIT_CONTACT_LOCATION'|translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  [(ngModel)]="model.contactLocation"
                  [ngModelOptions]="{standalone: true}"
                  [data]="contactLocations"
                  (onRemoteSearch)="searchContactLocation($event.target.value)"
                  [settings]="customerDropdownSettings">
                </angular2-multiselect>
              </div>
            </div>
          </div>

          <div *ngIf="model.type === 'COMPANY' && rightModel.companyReadAll.hasRight()" class="form-group row">
            <div class="col-md-4">
              <label class="mw-100 col-form-label form-control-label">{{'STOCK_CREATE_EDIT_COMPANY'|translate}}</label>
            </div>
            <div class="col-md-8">
              <label class="mw-100 col-form-label form-control-label detail-description">{{model.company.length === 1 ? model.company[0].itemName : ''}}</label>
            </div>
          </div>

          <div *ngIf="model.type === 'COMPANY'" class="form-group row">
            <div class="col-md-4">
              <label class="mw-100 col-form-label form-control-label">{{'STOCK_CREATE_EDIT_CENTRAL'|translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="row pl-1">
                <app-animated-checkbox [(selected)]="model.central"
                                       class="mr-1 col-form-label"></app-animated-checkbox>
              </div>
            </div>
          </div>

          <div *ngIf="model.type === 'COMPANY'" class="form-group row">
            <div class="col-md-4">
              <label class="mw-100 col-form-label form-control-label">{{'STOCK_CREATE_EDIT_WASTE'|translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="row pl-1">
                <app-animated-checkbox [(selected)]="model.waste"
                                       class="mr-1 col-form-label"></app-animated-checkbox>
              </div>
            </div>
          </div>


          <div>
            <div class="d-flex justify-content-end">
              <input type="submit" class="btn btn-primary" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
            </div>
          </div>

        </form>
      </div>
    </div>
  </div>

  <div class="container-vertical-padding-half"></div>

  <div class="p-0 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12" *ngIf="model.sums">
    <div class="card">

      <div class="card-header">
        {{'STOCK_RECORD_LOT_INTAKE_PRICE_SUM'|translate}}
      </div>

      <div class="card-body">
        <div class="form-group row" *ngFor="let sum of model.sums">
          <div class="col-md-4">
            <label readonly
                   class="mw-100 col-form-label form-control-label">{{ getCurrencyName(sum.currency_code) }}</label>
          </div>
          <div class="col-md-8">
            <div class="row" >
              <label readonly
                     class="col-form-label form-control-label col-md-8 detail-description font-weight-bold">{{ sum.intake_sum_net_price | formattedNumber }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-vertical-padding-half" *ngIf="model.sums"></div>


  <app-stock-stock-record-list
    #stockStockRecordList
    [stockId]="stockId"
    [rightModel]="rightModel"
    [readonly]="false"
    (onEdit)="onStockRecordEdited($event)"
    (onNewStockRecord)="showNewStockItemDialog($event)"
    (onExport)="exportXls()">
  </app-stock-stock-record-list>

</div>


<!-- stock record create -->
<div bsModal [config]="UiConstants.modalConfig" #newStockItemDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true" (onHide)="onNewStockItemDialogClosed()">
  <div class="modal-dialog modal-lg" role="document">
    <form class="form-horizontal bordered-row" (ngSubmit)="cf.form.valid && createStockItem()" #cf="ngForm" novalidate>
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{('STOCK_RECORD_CREATE_' + createType) | translate}}</h4>
          <button type="button" class="close" (click)="closeNewStockItemDialog()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card-body">
            <div class="row">
              <div class="form-group col-md-10"
                   [ngClass]="{ 'has-danger': (cf.submitted && !newStockItem.valid && !newStockRecordModel.all)}">
                <label [class.required-field-label]="!newStockRecordModel.all" class="col-form-label">
                  {{'COMMON_NAME' | translate}}
                </label>
                <angular2-multiselect [data]="stockItemsForCreate" [settings]="createDropdownSettings"
                                      (onRemoteSearch)="onSearch($event)"
                                      class="form-control"
                                      [(ngModel)]="newStockRecordModel.stock_item"
                                      [ngClass]="{ 'has-danger': (cf.submitted && !newStockItem.valid && !newStockRecordModel.all)}"
                                      name="newStockItem"
                                      #newStockItem="ngModel" [required]="!newStockRecordModel.all"
                                      *ngIf="newStockDialogShown">
                </angular2-multiselect>
                <div class="form-control-feedback" *ngIf="cf.submitted && !newStockItem.valid">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>

              </div>
              <div class="form-group col-md-2">
                <label class="form-control-label">{{ 'COMMON_PLACEHOLDER_SELECT_ALL' | translate }}</label>
                <app-animated-checkbox [(selected)]="newStockRecordModel.all"
                                       (selectedChanged)="stockRecordCreateRealAll($event)"></app-animated-checkbox>
              </div>
            </div>

            <div class="row" *ngIf="createWithAmount">
              <div class="form-group col-md-12"
                   [ngClass]="{ 'has-danger': (cf.submitted && !create_stock_record_amount.valid)}">
                <label class="required-field-label col-form-label">
                  {{'STOCK_RECORD_AMOUNT' | translate}}
                </label>
                <input type="text" class="form-control"
                       maxlength="21"
                       [ngClass]="{ 'has-danger': (cf.submitted && !create_stock_record_amount.valid)}"
                       [(ngModel)]="newStockRecordModel.amount" #create_stock_record_amount="ngModel"
                       placeholder="{{'STOCK_RECORD_AMOUNT' | translate}}"
                       [textMask]="{mask: InputMask.NATURAL_FLOAT_WITH_THOUSAND_SEPARATOR, guide: false}"
                       name="create_stock_record_amount" id="create_stock_record_amount" required>
                <div class="form-control-feedback" *ngIf="cf.submitted && !create_stock_record_amount.valid">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
              </div>
            </div>

          </div> <!-- ./card-body end -->
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="closeNewStockItemDialog()">{{'COMMON_BUTTON_CANCEL' |
            translate}}
          </button>
          <button type="submit" class="btn btn-primary" #functionButton [ladda]="stockRecordCreateInProgress" [disabled]="stockRecordCreateInProgress">{{'COMMON_BUTTON_SAVE'
            | translate}}
          </button>
        </div>
      </div><!-- /.modal-content -->
    </form>
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

