import { Injectable } from '@angular/core';
import { Observable } from 'rxjs'
import {
  BaseHttpService,
  EchoHeader,
  EchoHeaderBuilder,
  HeaderKeys,
  HeaderValues,
  ResourceHelper,
} from '../util/http-services';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EmptyMessage, IdentityMessage } from '../util/messages';
import { LoadingHandler } from '../loading-handler';

@Injectable()
export class FirebaseMessageResourceService extends BaseHttpService {

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/fcm/web');
  }

  registerToken(token: string): Observable<IdentityMessage> {
    return this.http.post<IdentityMessage>(this.url + `?${LoadingHandler.HANDLER_OFF}`, {token: token});
  }

  updateToken(id: number, token: string): Observable<EmptyMessage> {
    const echoHeader: EchoHeader = new EchoHeaderBuilder().ignoreEntityNotFound(true).build();
    const headers = new HttpHeaders().set(HeaderKeys.ECHO, HeaderValues.serializeEchoHeader(echoHeader));
    return this.http.put(this.url + `${id}` + `?${LoadingHandler.HANDLER_OFF}`, {token: token}, {headers: headers})
  }

  deleteToken(id: number): Observable<EmptyMessage> {
    const echoHeader: EchoHeader = new EchoHeaderBuilder().ignoreEntityNotFound(true).build();
    const headers = new HttpHeaders().set(HeaderKeys.ECHO, HeaderValues.serializeEchoHeader(echoHeader));
    return this.http.delete(this.url + `${id}` + `?${LoadingHandler.HANDLER_OFF}`, {headers: headers});
  }

}
