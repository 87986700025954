import { DqlQuery, Query } from '../field';

export class GlobalCalendarEvent {

  readonly startTime: Query.OrderField;

  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this.startTime = new DqlQuery.OrderField(prefix + 'start_time');
  }

}
