<div class="card">
  <div class="card-body">
    <div class="row m-0 d-flex align-items-center">
      <i class="icomoon card-header-image" [ngClass]="model.iconClass"></i>
      <h4 class="card-title mb-0">{{model.headerKey | translate}}</h4>
    </div>
    <!-- /.row -->
  </div>
  <div class="list-group list-group-flush">
    <div class="list-group-item-container" *ngFor="let item of model.menuItems">
      <div class="disabled-overlay" *ngIf="demoModeEnabled && !item.enabledPredicate()"></div>
      <a class="list-group-item list-group-item-action cursor-pointer"
         [uiSref]="item.enabledPredicate() ? item.destination : undefined"
         (click)="!item.enabledPredicate() && showDisabledDialog()">
        {{item.titleKey | translate}}
      </a>
    </div>
  </div>
</div>
