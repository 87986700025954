/* eslint-disable */
import { List, Map, Set } from 'immutable';
import { Injectable } from '@angular/core';
import { RoleResource, RoleResourceService } from './role-resource.service';
import {
  FieldValidationError,
  Order,
  PagingRequest,
  QueryResult,
  ResourceQueryResult,
  Services
} from '../util/services';
import { Observable, Observer } from 'rxjs';
import { ObservableErrorResourceParser } from '../util/errors';
import { EmptyMessage, IdentityMessage } from '../util/messages';
import { RoleLogResource, RoleLogResourceService } from './role-log-resource.service';
import { UserItem, UserItemResource } from '../user.service';
import { HistoryLog } from '../history-log/history-log.service';
import RoleHistoryType = Role.RoleHistoryType;

/* eslint-enable */

@Injectable()
export class RoleService implements Role.Service {

  query(request: Role.QueryRequest): Observable<QueryResult<Role.Role>> {
    return Observable.create((observer: Observer<QueryResult<Role.Role>>) => {
      const resourceRequest: RoleResource.QueryRequest = {
        fields: request.fields ? request.fields.join(',') : undefined,
        order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
        with_privileges: false,
        id: request.id ? request.id.join(',') : undefined,
        name: request.name,
        disabled: request.disabled,
        no_progress_bar: request.noProgressBar
      };
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<RoleResource.Role>) => {
          observer.next({
            items: this.toPublicList(result.items),
            pagingResult: result.pagingResult
          });
        });
    });
  }

  get(request: IdentityMessage): Observable<Role.Role> {
    return Observable.create((observer: Observer<Role.Role>) => {
      return this.resourceService.get(request).subscribe(
        (result: RoleResource.Role) => {
          observer.next(this.toPublic(result));
        }
      );
    });
  }

  create(request: Role.CreateRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: RoleResource.CreateRequest = {
        name: request.name,
        privileges: request.privileges
      };
      return this.resourceService.create(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        }
      )
    })
  }

  update(request: Role.UpdateRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: RoleResource.UpdateRequest = {
        id: request.id,
        name: request.name,
        privileges: request.privileges
      };
      return this.resourceService.update(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        }
      );
    })
  }

  setDisabled(request: Role.DisableRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: RoleResource.DisableRequest = {
        id: request.id,
        disabled: request.disabled,
      };
      return this.resourceService.setDisabled(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  importPrivileges(request: Role.PrivilegeImportRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: RoleResource.PrivilegeImportRequest = {
        id: request.id,
        privileges: request.privileges,
      };
      return this.resourceService.importPrivileges(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  exportPrivileges(request: IdentityMessage): Observable<Role.PrivilegeExportResponse> {
    return Observable.create((observer: Observer<Role.PrivilegeExportResponse>) => {
      const resourceRequest: IdentityMessage = {
        id: request.id,
      };
      return this.resourceService.exportPrivileges(resourceRequest).subscribe(
        (result: Role.PrivilegeExportResponse) => {
          observer.next(result);
        }, error => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  importRole(request: RoleResource.RoleImportRequest) {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.resourceService.importRole(request).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  exportRole(request: IdentityMessage): Observable<RoleResource.RoleImportRequest> {
    return Observable.create((observer: Observer<RoleResource.RoleImportRequest>) => {
      const resourceRequest: IdentityMessage = {
        id: request.id,
      };
      return this.resourceService.exportRole(resourceRequest).subscribe(
        (result: RoleResource.RoleImportRequest) => {
          observer.next(result);
        }, error => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  constructor(
    private resourceService: RoleResourceService,
    private logResourceService: RoleLogResourceService,
  ) {
  }

  private translateError(error: any): any {
    const res = ObservableErrorResourceParser.parseError(error);
    const fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
    const fieldErrorMap = ObservableErrorResourceParser.toFieldErrorMap(Keys.toValidatedField, fieldErrors);
    if (!fieldErrorMap.isEmpty()) {
      return FieldValidationError.of(fieldErrorMap);
    }
    return error;
  }

  private toPublicList(resourceList: RoleResource.Role[]): List<Role.Role> {
    return List.of(...resourceList.map((r) => this.toPublic(r)));
  }

  // list query doesn't send the privileges array, but getOne sends it (only the role edit/detail screens need this info)
  private toPublic(r: RoleResource.Role): Role.Role {
    return {
      id: r.id,
      name: r.name,
      privileges: r.privileges ? r.privileges : [],
      protectedRole: r.protected_role,
      disabled: r.disabled,
    };
  }

  history(request: Role.HistoryRequest): Observable<QueryResult<Role.HistoryItem>> {
    return Observable.create((observer: Observer<QueryResult<Role.HistoryItem>>) => {
      const resourceRequest: RoleLogResource.HistoryRequest = {
        role_id: request.roleId,
        with_read: request.withRead,
        q: request.queryText,
        order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
      };
      return this.logResourceService.history(resourceRequest).subscribe(
        (result: ResourceQueryResult<RoleLogResource.HistoryItem>) => {
          observer.next({
            items: this.toPublicHistoryList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });

    });
  }

  private toPublicHistoryList(resourceList: RoleLogResource.HistoryItem[]): List<Role.HistoryItem> {
    return List.of(...resourceList.map((r) => this.toPublicHistory(r)));
  }

  private toPublicHistory(r: RoleLogResource.HistoryItem): Role.HistoryItem {
    return {
      id: r.id,
      creationTime: Services.toOffsetDateTime(r.creation_time),
      type: <RoleHistoryType>r.type,
      changeLog: r.change_log,
      applicationClassification: r.application_classification,
      issuerUser: this.toPublicUser(r.issuer_user!),
      mobileApplication: this.toPublicMobileApplication(r.mobile_application)
    };
  }

  private toPublicUser(r: UserItemResource): UserItem {
    return {
      id: r.id,
      personName: r.person_name
    };
  }

  private toPublicMobileApplication(r: HistoryLog.MobileApplicationItemResource | undefined):
    HistoryLog.MobileApplicationItem | undefined {
    if (r) {
      return {
        id: r.id,
        applicationId: r.application_id
      };
    }
    return undefined;
  }

}

export namespace Role {

  export interface Service {

    query(request: QueryRequest): Observable<QueryResult<Role>>;

    get(request: IdentityMessage): Observable<Role>;

    create(request: CreateRequest): Observable<EmptyMessage>;

    update(request: UpdateRequest): Observable<EmptyMessage>;

    setDisabled(request: DisableRequest): Observable<EmptyMessage>;

  }

  export interface QueryRequest {
    fields?: Set<string>;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;

    id?: Set<number>;
    name?: string;
    disabled?: boolean;
    noProgressBar?: boolean;
  }

  export interface CreateRequest {
    name: string;
    privileges: string[];
  }

  export interface UpdateRequest {
    id: number;
    name: string;
    privileges: string[];
  }

  export interface DisableRequest extends IdentityMessage {
    disabled: boolean;
  }

  export interface Role {
    id: number;
    name: string;
    privileges: string[];
    protectedRole: boolean;
    disabled: boolean;
  }

  export interface PrivilegeImportRequest {
    id: number
    privileges: string[];
  }

  export interface PrivilegeExportResponse {
    privileges: string[];
  }


  export enum OrderField {
    ID,
    NAME,
    HISTORY_CREATION_TIME,
    HISTORY_ISSUER_USER_PERSON_NAME,
    HISTORY_MOBILE_APPLICATION_APPLICTAION_ID
  }

  export enum ValidatedField {
    UNKNOWN,
    NAME,
  }

  export interface HistoryRequest {
    roleId: number;
    withRead: boolean;
    queryText?: string;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
  }

  export interface HistoryItem extends HistoryLog.HistoryItemBase {
    type: RoleHistoryType;
  }

  export type RoleHistoryType =
    'READ' |
    'CREATE' |
    'UPDATE' |
    'DISABLE' |
    'ENABLE' |
    'IMPORT_PRIVILEGES' |
    'EXPORT_PRIVILEGES';

  export class RoleHistoryTypeObject {
    type: RoleHistoryType;
    stringKey: string;
  }

  export const roleHistoryTypes: RoleHistoryTypeObject[] = [
    {type: 'READ', stringKey: 'ROLE_HISTORY_TYPE_READ'},
    {type: 'CREATE', stringKey: 'ROLE_HISTORY_TYPE_CREATE'},
    {type: 'UPDATE', stringKey: 'ROLE_HISTORY_TYPE_UPDATE'},
    {type: 'DISABLE', stringKey: 'ROLE_HISTORY_TYPE_DISABLE'},
    {type: 'ENABLE', stringKey: 'ROLE_HISTORY_TYPE_ENABLE'},
    {type: 'IMPORT_PRIVILEGES', stringKey: 'ROLE_HISTORY_TYPE_IMPORT_PRIVILEGES'},
    {type: 'EXPORT_PRIVILEGES', stringKey: 'ROLE_HISTORY_TYPE_EXPORT_PRIVILEGES'}
  ];

}

class Keys {

  private static readonly ID = 'id';
  private static readonly NAME = 'name';
  private static readonly HISTORY_CREATION_TIME = 'creation_time';
  private static readonly HISTORY_ISSUER_USER_PERSON_NAME = 'issuer_user_person_name';
  private static readonly HISTORY_MOBILE_APPLICATION_APPLICTAION_ID = 'mobile_application_application_id';

  private static readonly orderFieldKeyMap: Map<Role.OrderField, string> = Map.of(
    Role.OrderField.ID, Keys.ID,
    Role.OrderField.NAME, Keys.NAME,
    Role.OrderField.HISTORY_CREATION_TIME, Keys.HISTORY_CREATION_TIME,
    Role.OrderField.HISTORY_ISSUER_USER_PERSON_NAME, Keys.HISTORY_ISSUER_USER_PERSON_NAME,
    Role.OrderField.HISTORY_MOBILE_APPLICATION_APPLICTAION_ID, Keys.HISTORY_MOBILE_APPLICATION_APPLICTAION_ID,
  );

  private static readonly keyValidatedFieldMap: Map<string, Role.ValidatedField> = Map.of(
    Keys.NAME, Role.ValidatedField.NAME,
  );

  public static toOrderFieldKey(field: Role.OrderField): string {
    return Keys.orderFieldKeyMap.get(field)!;
  }

  public static toValidatedField(fieldKey: string): Role.ValidatedField {
    return Keys.keyValidatedFieldMap.get(fieldKey, Role.ValidatedField.UNKNOWN);
  }

}
