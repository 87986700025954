<div *ngIf="node && node.visible" [ngClass]="{'has-no-children': !node.hasChildren}">
  <div>
    <button type="button" mat-icon-button (click)="node.toggleChildrenShown()" *ngIf="node.hasChildren">
      <mat-icon class="mat-icon-rtl-mirror">
        {{node.childrenShown ? 'expand_more' : 'chevron_right'}}
      </mat-icon>
    </button>
    <mat-checkbox
      *ngIf="!readonly"
      [checked]="node.selected"
      [indeterminate]="node.partSelected"
      (click)="node.toggleSelected()">
      {{node.name}}
    </mat-checkbox>
    <label *ngIf="readonly">{{node.name}}</label>
  </div>
  <div class="child" *ngIf="node.childrenShown">
    <app-tree-node-component
      *ngFor="let child of node.children"
      [readonly]="readonly"
      [node]="child">
    </app-tree-node-component>
  </div>
</div>
