/* eslint-disable */
import { Component, EventEmitter, Input, Output, } from '@angular/core';
import { Address, AddressModel, } from '../../../../lib/address';
import { FormBuilder, FormGroup, NgForm, Validators, } from '@angular/forms';
import { ForwardingNgFormRef, LocalFormGroupValidationErrors, } from '../../../../lib/util/services';
import { AppValidators } from '../../../../util/app-validators';
import { UiConstants } from '../../../../util/core-utils';
/* eslint-enable */

@Component({
  selector: 'app-phone-number-item',
  templateUrl: './phone-number-item.component.html',
  styleUrls: ['./phone-number-item.component.scss']
})
export class PhoneNumberItemComponent {

  UiConstants = UiConstants;

  @Input()
  model?: AddressModel.PhoneNumberModel;

  @Input()
  readonly: boolean;

  @Input()
  deletable?: boolean = false;

  @Input()
  form?: NgForm;

  @Output()
  deleteEventEmitter: EventEmitter<any> = new EventEmitter();

  formGroup: FormGroup;

  /**
   * Only for HTML binding!
   */
  get m(): AddressModel.PhoneNumberModel {
    return this.model!;
  }

  private formGroupValidationErrors: LocalFormGroupValidationErrors;

  constructor(fb: FormBuilder) {
    this.formGroup = this.createFormGroup(fb);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(
      this.createForwardingHtmlForm(),
      this.formGroup
    );
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  getTypeDictionaryCode(type: Address.PhoneNumberDataType): string {
    return AddressModel.PhoneNumberModel.getTypeDictionaryCode(type);
  }

  private createFormGroup(fb: FormBuilder): FormGroup {
    return fb.group(
      {
        value: fb.control(
          '',
          [
            Validators.required,
            AppValidators.validatePhoneNumber
          ]
        ),
      }
    );
  }

  private createForwardingHtmlForm() {
    return new ForwardingNgFormRef({
      formFn: () => {
        return this.form;
      }
    });
  }

  private emitDeleteEvent() {
    this.deleteEventEmitter.emit(null);
  }
}
