/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { Order, OrderType, Services } from './util/services';
import { Dates, LocalDate } from './util/dates';
import { MultiselectOptionItem, UiConstants } from '../util/core-utils';
import { MemStorage } from './util/mem-storage';
import { Invoice } from './invoice/invoice/invoice.service';
import { LoggedInUserStorage } from './util/storages';
import { SearchBooleanItem } from '../util/search-utils';

/* eslint-enable */

@Injectable()
export class InvoiceSearchService {

  constructor() {
  }

  getSearchData(request: InvoiceSearch.SearchDataGetRequest): Observable<InvoiceSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<InvoiceSearch.SearchDataResult>) => {
      try {
        observer.next(storage.read());
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  setSearchData(request: InvoiceSearch.SearchDataSetRequest): Observable<InvoiceSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<InvoiceSearch.SearchDataResult>) => {
      try {
        observer.next(storage.save(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  resetSearchData(): Observable<InvoiceSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<InvoiceSearch.SearchDataResult>) => {
      try {
        observer.next(storage.reset());
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  private createStorage(): InvoiceSearch.Storage {
    return new InvoiceSearch.Storage();
  }

}

export namespace InvoiceSearch {

  export interface SearchDataGetRequest {
  }

  export interface SearchDataSetRequest {
    searchData: SearchData;
  }

  export interface SearchDataResult {
    searchData: SearchData;
  }

  export interface SearchData {
    itemsPerPage: number;
    pageNumber: number;
    order: Order<Invoice.OrderField>;
    invoiceNumber?: string;
    assigneeUsers: MultiselectOptionItem<number>[];
    creatorUsers: MultiselectOptionItem<number>[];
    recordStockItems: MultiselectOptionItem<number>[];
    invoiceBooks: MultiselectOptionItem<number>[];
    invoiceSettings: MultiselectOptionItem<number>[];
    invoiceTags: MultiselectOptionItem<number>[];
    customerName?: string;
    issueDateFrom: LocalDate;
    issueDateTo: LocalDate;
    deliveryDateFrom: LocalDate;
    deliveryDateTo: LocalDate;
    deadlineDateFrom: LocalDate;
    deadlineDateTo: LocalDate;
    settlementDateFrom: LocalDate;
    settlementDateTo: LocalDate;
    settled: SearchBooleanItem;
    exportStates: MultiselectOptionItem<Invoice.NavExportState>[];
    directionTypes: MultiselectOptionItem<Invoice.InvoiceDirectionType>[];
    paymentTypes: MultiselectOptionItem<Invoice.InvoicePaymentType>[];
    categoryTypes: MultiselectOptionItem<Invoice.InvoiceCategoryType>[];
    advancedMode: boolean;
  }

  interface StoredSearchRawDataBase {
    version: number;
  }

  interface StoredSearchRawData extends StoredSearchRawDataBase {
    itemsPerPage: number;
    pageNumber: number;
    order: Order<Invoice.OrderField>;
    invoiceNumber?: string;
    assigneeUsers: MultiselectOptionItem<number>[];
    creatorUsers: MultiselectOptionItem<number>[];
    recordStockItems: MultiselectOptionItem<number>[];
    invoiceBooks: MultiselectOptionItem<number>[];
    invoiceSettings: MultiselectOptionItem<number>[];
    invoiceTags: MultiselectOptionItem<number>[];
    customerName?: string;
    issueDateFrom?: string;
    issueDateTo?: string;
    deliveryDateFrom?: string;
    deliveryDateTo?: string;
    deadlineDateFrom?: string;
    deadlineDateTo?: string;
    settlementDateFrom?: string;
    settlementDateTo?: string;
    settled: SearchBooleanItem;
    exportStates: MultiselectOptionItem<Invoice.NavExportState>[];
    directionTypes: MultiselectOptionItem<Invoice.InvoiceDirectionType>[];
    paymentTypes: MultiselectOptionItem<Invoice.InvoicePaymentType>[];
    categoryTypes: MultiselectOptionItem<Invoice.InvoiceCategoryType>[];
    advancedMode: boolean;
  }

  export class Storage {

    /**
     * Increment this version if you change the SearchData interface.
     */
    private static readonly VERSION: number = 11;

    private static readonly DEFAULT_SEARCH_DATA: SearchData = {
      itemsPerPage: UiConstants.pageNumbers[0],
      pageNumber: 1,
      order: {
        field: Invoice.OrderField.ID,
        type: OrderType.DESC
      },
      invoiceNumber: undefined,
      assigneeUsers: [],
      creatorUsers: [],
      recordStockItems: [],
      paymentTypes: [],
      categoryTypes: [],
      invoiceBooks: [],
      invoiceSettings: [],
      invoiceTags: [],
      customerName: undefined,
      issueDateFrom: Dates.emptyLocalDate(),
      issueDateTo: Dates.emptyLocalDate(),
      deliveryDateFrom: Dates.emptyLocalDate(),
      deliveryDateTo: Dates.emptyLocalDate(),
      deadlineDateFrom: Dates.emptyLocalDate(),
      deadlineDateTo: Dates.emptyLocalDate(),
      settlementDateFrom: Dates.emptyLocalDate(),
      settlementDateTo: Dates.emptyLocalDate(),
      settled: SearchBooleanItem.ALL,
      exportStates: [],
      directionTypes: [],
      advancedMode: false
    };

    constructor() {
    }

    public read(): SearchDataResult {
      const key = this.generateKey();
      let rawBase: StoredSearchRawDataBase;
      try {
        const json = MemStorage.getItem(key);
        if (!json) {
          return this.createDefaultResult();
        }
        rawBase = JSON.parse(json);
      }
      catch (error) {
        return this.createDefaultResult();
      }
      if (Storage.VERSION === rawBase.version) {
        const raw: StoredSearchRawData = <StoredSearchRawData>rawBase;
        return this.fromRaw(raw);
      }
      return this.createDefaultResult();
    }

    public reset(): SearchDataResult {
      return this.save({
        searchData: Storage.DEFAULT_SEARCH_DATA,
      });
    }

    public save(request: SearchDataSetRequest): SearchDataResult {
      const key = this.generateKey();
      const raw = this.toRaw(request.searchData);
      const json = JSON.stringify(raw);
      MemStorage.setItem(key, json);
      return this.read();
    }

    private generateKey(): string {
      return 'UserId(' + LoggedInUserStorage.getInstance().getUserId() + ')-Search-Invoice';
    }

    private createDefaultResult(): SearchDataResult {
      return {
        searchData: Storage.DEFAULT_SEARCH_DATA,
      };
    }

    private fromRaw(data: StoredSearchRawData): SearchDataResult {
      return {
        searchData: {
          itemsPerPage: data.itemsPerPage,
          pageNumber: data.pageNumber,
          order: data.order,
          invoiceNumber: data.invoiceNumber,
          assigneeUsers: data.assigneeUsers,
          creatorUsers: data.creatorUsers,
          recordStockItems: data.recordStockItems,
          paymentTypes: data.paymentTypes,
          categoryTypes: data.categoryTypes,
          invoiceBooks: data.invoiceBooks,
          invoiceSettings: data.invoiceSettings,
          invoiceTags: data.invoiceTags,
          customerName: data.customerName,
          issueDateFrom: Services.toLocalDate(data.issueDateFrom),
          issueDateTo: Services.toLocalDate(data.issueDateTo),
          deliveryDateFrom: Services.toLocalDate(data.deliveryDateFrom),
          deliveryDateTo: Services.toLocalDate(data.deliveryDateTo),
          deadlineDateFrom: Services.toLocalDate(data.deadlineDateFrom),
          deadlineDateTo: Services.toLocalDate(data.deadlineDateTo),
          settlementDateFrom: Services.toLocalDate(data.settlementDateFrom),
          settlementDateTo: Services.toLocalDate(data.settlementDateTo),
          settled: data.settled,
          exportStates: data.exportStates,
          directionTypes: data.directionTypes,
          advancedMode: data.advancedMode
        }
      };
    }

    private toRaw(data: SearchData): StoredSearchRawData {
      return {
        version: Storage.VERSION,
        itemsPerPage: data.itemsPerPage,
        pageNumber: data.pageNumber,
        order: data.order,
        invoiceNumber: data.invoiceNumber,
        assigneeUsers: data.assigneeUsers,
        creatorUsers: data.creatorUsers,
        recordStockItems: data.recordStockItems,
        paymentTypes: data.paymentTypes,
        categoryTypes: data.categoryTypes,
        invoiceBooks: data.invoiceBooks,
        invoiceSettings: data.invoiceSettings,
        invoiceTags: data.invoiceTags,
        customerName: data.customerName,
        issueDateFrom: Services.localDateToString(data.issueDateFrom),
        issueDateTo: Services.localDateToString(data.issueDateTo),
        deliveryDateFrom: Services.localDateToString(data.deliveryDateFrom),
        deliveryDateTo: Services.localDateToString(data.deliveryDateTo),
        deadlineDateFrom: Services.localDateToString(data.deadlineDateFrom),
        deadlineDateTo: Services.localDateToString(data.deadlineDateTo),
        settlementDateFrom: Services.localDateToString(data.settlementDateFrom),
        settlementDateTo: Services.localDateToString(data.settlementDateTo),
        settled: data.settled,
        exportStates: data.exportStates,
        directionTypes: data.directionTypes,
        advancedMode: data.advancedMode
      };
    }

  }

}
