import { Component, Input, OnInit } from '@angular/core';
import { Order } from '../../../../lib/order/order.service';
import {
  orderDisplayStateListObjects,
  OrderDisplayStateListObject,
  OrderDisplayStateObject
} from '../../../../util/order-utils';

@Component({
  selector: 'app-order-detail-state-list',
  templateUrl: './order-detail-state-list.component.html',
  styleUrls: ['./order-detail-state-list.component.scss']
})
export class OrderDetailStateListComponent implements OnInit {

  OrderDetailStateType = OrderDetailStateType;

  @Input()
  set order(order: Order.Order) {
    const displayStateObject = OrderDisplayStateObject.resolve(order.logicState);
    const resolver = orderDisplayStateListObjects.find(o => o.state === displayStateObject);
    this.createStateList(resolver);
  }

  stateList: OrderDetailStateModel[] = [];

  constructor() { }

  ngOnInit() {
  }

  private createStateList(object?: OrderDisplayStateListObject) {
    if (object) {
      object.beforeStates.forEach(s => {
        this.stateList.push({
          displayState: s,
          type: OrderDetailStateType.COMPLETED
        });
      });
      this.stateList.push({
        displayState: object.state,
        type: OrderDetailStateType.CURRENT
      });
      object.afterStates.forEach(s => {
        this.stateList.push({
          displayState: s,
          type: OrderDetailStateType.WAITING
        });
      });
    }
  }

}

interface OrderDetailStateModel {
  displayState: OrderDisplayStateObject;
  type: OrderDetailStateType;
}

enum OrderDetailStateType {
  WAITING,
  COMPLETED,
  CURRENT
}
