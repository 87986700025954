import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UIRouter } from '@uirouter/core';
import { RightModel } from '../../app.rights';
import { StateName } from '../../app.state-names';
import { CustomerRecord } from '../../lib/customer/customer-record.service';

@Component({
  selector: 'app-customer-record-table-cell',
  templateUrl: './customer-record-table-cell.component.html',
  styleUrls: ['./customer-record-table-cell.component.scss']
})
export class CustomerRecordTableCellComponent implements OnInit {

  @Input()
  set customerRecord(value: CustomerRecord.CustomerRecord) {
    this._customerRecord = value;
    this.updateData();
  }

  @Input()
  set customerRecordName(value: string) {
    this._name = value;
    this.updateData();
  }

  @Input()
  set customerName(value: string) {
    this._customerName = value;
    this.updateData();
  }

  @Input()
  set customerRecordPosition(value: string | undefined) {
    this._position = value;
    this.updateData();
  }

  @Input()
  rightModel: RightModel = RightModel.empty();

  @Input()
  imageSize: number = 36;

  @Input()
  borderEnabled: boolean = false;

  _customerRecord?: CustomerRecord.CustomerRecord;
  _name?: string;
  _customerName?: string;
  _position?: string;

  cellTitle: string = '';
  cellSubtitle: string = '';

  constructor(private translateService: TranslateService,
              private sanitizer: DomSanitizer,
              private uiRouter: UIRouter) {
  }

  ngOnInit() {
  }

  private updateData() {
    if (this._customerRecord) {
      this.cellTitle = this._customerRecord.name;
      const subtitleParts: string[] = [];
      if (this._customerRecord.customer?.name) {
        subtitleParts.push(this._customerRecord.customer?.name);
      }
      if (this._customerRecord.position) {
        subtitleParts.push(this._customerRecord.position);
      }
      this.cellSubtitle =  subtitleParts.join(" - ");
      return;
    }
    else if (this._name) {
      this.cellTitle = this._name;
      const subtitleParts: string[] = [];
      if (this._customerName) {
        subtitleParts.push(this._customerName);
      }
      if (this._position) {
        subtitleParts.push(this._position);
      }
      this.cellSubtitle =  subtitleParts.join(" - ");
    }
  }

  navigateToAssigneeDetail() {
    if (this._customerRecord) {
      if (this.rightModel.customerRecordRead.hasRight()) {
        this.uiRouter.stateService.go(StateName.CUSTOMER_RECORD_DETAIL,
          {customerId: this._customerRecord.customerId, customerRecordId: this._customerRecord.customerRecordId});
      }
    }
  }
}
