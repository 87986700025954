/* eslint-disable */
import {Component, OnInit} from '@angular/core';
import {BreadcrumbParent} from '../../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import {ConfigurationService} from '../../../../lib/core-ext/configuration.service';
import {TranslateService} from '@ngx-translate/core';
import {Transition, UIRouter} from '@uirouter/angular';
import {StateName} from '../../../../app.state-names';
import {Address} from '../../../../lib/address';
import {Invoice, InvoiceService} from '../../../../lib/invoice/invoice/invoice.service';
import {InvoiceListModel} from '../invoice-list/invoice-list.model';
import {InvoiceSettings} from '../../../../lib/invoice/invoice-settings/invoice-settings.service';
import {RightModel} from '../../../../app.rights';
import {ToasterService} from '../../../../fork/angular2-toaster/src/toaster.service';
import {MultiselectOptionItem, UiConstants} from '../../../../util/core-utils';
import {DownloadedFile} from '../../../../lib/util/downloaded-files';
import {saveAs} from 'file-saver';
import {MatDialog} from '@angular/material/dialog';
import {
  InvoiceStornoDialogComponent,
  InvoiceStornoDialogResult
} from '../invoice-storno-dialog/invoice-storno-dialog.component';
import {RightResolver, RightService} from '../../../../lib/right.service';
import {
  InvoiceSpendingDialogComponent,
  InvoiceSpendingDialogResult
} from '../../invoice-spending/invoice-spending-dialog/invoice-spending-dialog.component';
import {InvoiceCreateCloneModel, InvoiceRecordCreateModel} from '../invoice-create/invoice-create-clone.model';
import {InvoiceSummaryModel} from '../invoice-record-container/invoice-record-container.component';
import {List, Set} from 'immutable';
import {InvoiceTaggerDialogComponent} from '../invoice-tagger-dialog/invoice-tagger-dialog.component';
import {BadgeStyle} from '../../../../shared/table-badge/badge-style';
import {CustomerRecord, CustomerRecordService} from '../../../../lib/customer/customer-record.service';
import {Angular2Multiselects} from "../../../../util/multiselect";
import {TaskRecordService} from "../../../../lib/task/task-record.service";
import {OperationRights} from "../../../../app.right-definitions";
import invoiceAppearanceTypes = InvoiceSettings.invoiceAppearanceTypes;

/* eslint-enable */

@Component({
  selector: 'app-invoice-detail',
  templateUrl: './invoice-detail.component.html',
  styleUrls: ['./invoice-detail.component.scss']
})
export class InvoiceDetailComponent implements OnInit {

  InvoiceSettings = InvoiceSettings;
  BadgeStyle = BadgeStyle;

  invoice?: Invoice.Invoice;
  invoiceId: number;

  breadcrumbSelf: string;
  breadcrumbParents: BreadcrumbParent[] = [];
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  postProcessInfoOpened: boolean = false;

  private postalAddressFormat: string;

  rightModel: RightModel = RightModel.empty();

  records: InvoiceRecordCreateModel[] = [];
  customerRecord?: CustomerRecord.CustomerRecord;
  singleDropdownSettings: Angular2Multiselects.Settings = Angular2Multiselects.LOCAL_SINGLE_SELECT;

  taskRecordAsArray: MultiselectOptionItem<number>[] = [];

  constructor(private translateService: TranslateService,
              private uiRouter: UIRouter,
              private transition: Transition,
              private invoiceService: InvoiceService,
              private rightService: RightService,
              private toasterService: ToasterService,
              private dialog: MatDialog,
              private configService: ConfigurationService,
              private customerRecordService: CustomerRecordService,
              private taskRecordService: TaskRecordService
  ) {
    this.invoiceId = this.transition.params().id;
    this.postalAddressFormat = this.configService.getPostalAddressFormat();
  }

  ngOnInit() {
    this.translateService.get('MENU_NAVBAR_INVOICES').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.INVOICE_LIST});
      }
    );
    this.loadRightModels();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        this.loadInvoice();
      }
    );
  }

  loadInvoice() {
    this.invoiceService.get({
      id: this.invoiceId
    }).subscribe(
      (invoice: Invoice.Invoice) => {
        this.invoice = invoice;
        this.records = invoice.records!.map(InvoiceRecordCreateModel.map);
        if (this.invoice.invoiceNumber) {
          this.breadcrumbSelf = this.invoice.invoiceNumber;
        } else {
          this.breadcrumbSelf = '' + this.invoiceId;
        }
        if (invoice.customerRecordId) {
          this.loadCustomerRecord(invoice.customerRecordId);
        }
        if (invoice.taskRecordData) {
          this.taskRecordAsArray = this.getTaskRecord(invoice);
        }
      });
  }

  formatCustomerAddress(data: Address.PostalAddressData): string {
    return Address.PostalAddressMapper.toString(data, this.postalAddressFormat);
  }

  formatEmailAddresses(emailAddresses: List<Address.EmailAddressData>) {
    return InvoiceCreateCloneModel.mapEmailAddresses(emailAddresses);
  }

  getPaymentTypeString(): string {
    if (!this.invoice) {
      return '';
    }
    let paymentTypeString = '';
    this.translateService.get('INVOICE_PAYMENT_TYPE_' + this.invoice.paymentType).subscribe(
      (result: string) => {
        paymentTypeString = result;
      }
    );
    return paymentTypeString;
  }

  getInvoiceIconClass(type: Invoice.InvoiceCategoryType): string {
    return InvoiceListModel.InvoiceCategoryTypes.get(type).iconClass;
  }

  getInvoiceTypeName(type: Invoice.InvoiceCategoryType): string {
    return InvoiceListModel.InvoiceCategoryTypes.get(type).stringKey;
  }

  getNavExportStateName(type: Invoice.NavExportState) {
    return InvoiceListModel.InvoiceNavExportStates.get(type).stringKey;
  }

  getPostProcessStateName(type: Invoice.PostProcessState) {
    return InvoiceListModel.InvoicePostProcessStates.get(type).stringKey;
  }

  getInvoiceAppearanceTypeName(type: Invoice.InvoiceAppearanceType) {
    return invoiceAppearanceTypes.find(t => t.type === type)!.stringKey;
  }

  validationMessages(technicalValidationMessages: Invoice.NavValidationMessage[]) {
    return technicalValidationMessages.map(m => m.message).join(', ');
  }

  getTaskRecord(invoice?: Invoice.Invoice): MultiselectOptionItem<number>[] {
    if (!invoice || !invoice.taskRecordData) {
      return [];
    }
    return [{
      id: invoice.taskRecordData.id,
      itemName: invoice.taskRecordData.name,
      itemSubtitle: invoice.taskRecordData.externalId,
    }];
  }

  navigateToTaskRecordDetail() {
    if (!this.invoice || !this.invoice.taskRecordData) {
      return;
    }
    this.taskRecordService.get({
      taskId: this.invoice.taskRecordData.taskId,
      taskRecordId: this.invoice.taskRecordData.id,
      rights: Set.of(OperationRights.TASK_RECORD_READ),
      ignorePermissionDenied: true,
      fields: Set.of('id'), withFormRecord: false
    }).subscribe(value => {
      if (!value.grantedRights.isEmpty()) {
        this.uiRouter.stateService.go(StateName.TASK_RECORD_DETAIL, {
          taskId: this.invoice!.taskRecordData!.taskId,
          taskRecordId: this.invoice!.taskRecordData!.id
        });
      }
    });
  }

  openStornoDialog() {
    const dialogRef = this.dialog.open(InvoiceStornoDialogComponent, {
      width: '60%',
      data: {original: this.invoice}
    });

    dialogRef.afterClosed().subscribe((result: InvoiceStornoDialogResult) => {
      if (result.modified) {
        this.loadInvoice();
      }
    });

  }

  openSettlementDialog() {
    const dialogRef = this.dialog.open(InvoiceSpendingDialogComponent, {
      width: '60%',
      data: {originals: Set.of(this.invoice)},
      panelClass: 'custom-dialog-container'
    });

    dialogRef.afterClosed().subscribe((result: InvoiceSpendingDialogResult) => {
      if (result.modified) {
        this.loadInvoice();
      }
    });

  }

  openInvoiceTagDialog(mode: 'SET' | 'CLEAR') {
    const dialogRef = this.dialog.open(InvoiceTaggerDialogComponent, {
      width: '60%',
      data: {originals: Set.of(this.invoice), mode: mode},
      panelClass: 'custom-dialog-container'
    });

    dialogRef.afterClosed().subscribe((result: InvoiceSpendingDialogResult) => {
      if (result.modified) {
        this.loadInvoice();
      }
    });

  }

  retryPostProcess() {
    this.invoiceService.retryPostProcess({invoiceId: this.invoiceId}).subscribe(result => {
      this.loadInvoice();
    });
  }

  downloadPdf() {
    this.invoiceService.downloadPdf({
      invoiceId: this.invoiceId
    }).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName('invoice.pdf'));
      },
      (error) => {
        this.toasterService.pop({
          timeout: UiConstants.ToastTimeoutLong,
          type: UiConstants.toastTypeError,
          title: this.translateService.instant('PDF_DOWNLOAD'),
          body: this.translateService.instant('PDF_DOWNLOAD_ERROR_MESSAGE')
        });

      });
  }

  get summaryModel(): InvoiceSummaryModel | undefined {
    if (!this.invoice || !this.invoice.footer) {
      return undefined;
    }
    return {
      currencyCode: this.invoice.currencyCode,
      grossSumPrice: this.invoice.footer.grossSumPrice,
      netSumPrice: this.invoice.footer.netSumPrice,
      vatSums: this.invoice.footer.vatSums
    }
  }

  private loadCustomerRecord(customerRecordId: number) {
    this.customerRecordService.globalQuery({
      customerRecordIdSet: Set.of(customerRecordId),
      noProgressBar: true,
      fields: Set.of('id', 'customer_id', 'name', 'postal_address', 'email_addresses')
    }).subscribe(result => {
      if (result.items.size > 0) {
        this.customerRecord = result.items.get(0);
      }
    });
  }

  cloneInvoice() {
    if (!this.invoice) {
      return;
    }
    this.uiRouter.stateService.go(StateName.INVOICE_CLONE, {
      id: this.invoiceId,
      type: this.invoice.directionType
    });
  }
}
