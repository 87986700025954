<div class="container-vertical-padding container-horizontal-padding-half">
  <div class="col-md-12 d-flex justify-content-between mb-1 half-padding" *ngIf="isRemovable">
    <h6>{{'TABLE_DOCUMENT_SCHEMA_FIELD_MODIFY_ITEM' | translate}}</h6>
    <input type="button"
           (click)="remove.emit()" class="btn btn-secondary"
           value="{{'TABLE_DOCUMENT_SCHEMA_FIELD_DELETE_ITEM' | translate}}">
  </div>
  <div class="col-md-12 mb-1 half-padding" *ngIf="!isRemovable">
    <h6>{{'TABLE_DOCUMENT_SCHEMA_FIELD_ADD_ITEM' | translate}}</h6>
  </div>
  <form class="form-horizontal d-flex flex-wrap form-group" (ngSubmit)=" f.form.valid && saveRecord()" #f="ngForm"
        novalidate>
    <div class="col-md-12 p-0">
      <div class="row m-0">
        <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 form-group"
             [ngClass]="{ 'has-danger': hasLocalFieldError(code) || uniqueCode}">
          <label class="form-control-label">{{'LIST_ITEM_CODE' | translate}}</label>
          <div>
            <input
              class="form-control"
              type="text"
              maxlength="{{UiConstants.maximumVarcharLength}}"
              [ngClass]="{ 'form-control-danger': hasLocalFieldError(code) }"
              (ngModelChange)="removeFieldError(fieldErrors.code)"
              placeholder="{{'LIST_ITEM_CODE' | translate}}"
              [(ngModel)]="m.code"
              #code="ngModel"
              name="code"
            required>
            <div class="form-control-feedback"
                 *ngIf="fieldErrors.code">{{fieldErrors.code.text}}</div>
            <div class="form-control-feedback"
                 *ngIf="hasLocalFieldError(code)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
            <div class="form-control-feedback"
                 *ngIf="uniqueCode">{{'COMMON_VALIDATION_MESSAGE_UNIQUE' | translate}}</div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 form-group"
             [ngClass]="{ 'has-danger': hasLocalFieldError(name) || uniqueName}">
          <label class="form-control-label">{{'COMMON_NAME' | translate}}</label>
          <div>
            <input
              class="form-control"
              type="text"
              maxlength="{{UiConstants.maximumVarcharLength}}"
              [ngClass]="{ 'form-control-danger': hasLocalFieldError(name) }"
              (ngModelChange)="removeFieldError(fieldErrors.name)"
              placeholder="{{'COMMON_NAME' | translate}}"
              [(ngModel)]="m.name"
              #name="ngModel"
              name="name"
            required>
            <div class="form-control-feedback"
                 *ngIf="fieldErrors.name">{{fieldErrors.name.text}}</div>
            <div class="form-control-feedback"
                 *ngIf="hasLocalFieldError(name)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
            <div class="form-control-feedback"
                 *ngIf="uniqueName">{{'COMMON_VALIDATION_MESSAGE_UNIQUE' | translate}}</div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 form-group half-padding"
             [ngClass]="{ 'has-danger': hasLocalFieldError(type) }">
          <label class="form-control-label">{{'TABLE_DOCUMENT_SCHEMA_FIELD_TYPE' | translate}}</label>
          <div>
            <angular2-multiselect
              (ngModelChange)="removeFieldError(fieldErrors.type)"
              [(ngModel)]="dataType"
              [ngModelOptions]="{standalone: true}"
              [data]="dataTypes"
              [settings]="dropdownSettings"
              #type="ngModel"
              name="type"
            required>
            </angular2-multiselect>
            <div class="form-control-feedback"
                 *ngIf="hasLocalFieldError(type)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 form-group half-padding">
          <label class="form-control-label">{{'TABLE_DOCUMENT_SCHEMA_FIELD_NOTE' | translate}}</label>
          <div>
        <textarea
          type="text"
          class="form-control"
          maxlength="{{UiConstants.maximumVarcharLength}}"
          placeholder="{{'TABLE_DOCUMENT_SCHEMA_FIELD_NOTE' | translate}}"
          [(ngModel)]="m.note"
          name="note"
          id="note"
          #note="ngModel">
        </textarea>
          </div>
        </div>

        <div class="col-md-1 col-sm-12 col-xs-12 form-group half-padding">
          <label class="form-control-label">{{'COMMON_FORM_FIELD_VALIDATION_TYPE_REQUIRED' | translate}}</label>
          <div class="col-md-8">
            <label
              class="switch switch-text switch-info mb-0">
              <input type="checkbox"
                     class="switch-input"
                     [ngModelOptions]="{standalone: true}"
                     [(ngModel)]="m.required"
                     required>
              <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
              <span class="switch-handle"></span>
            </label>
          </div>
        </div></div>

    </div>
    <!-- /.col-md-12 -->
    <div class="col-md-12 half-padding">
      <div class="pull-right">
        <input type="button" class="btn btn-secondary-green cursor-pointer" (click)="cancel.emit()"
               value="{{'COMMON_BUTTON_CANCEL'|translate}}"/>
        <input type="submit" class="btn btn-primary-green cursor-pointer" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
      </div>
    </div>
  </form>
</div>
