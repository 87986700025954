import { Component, OnInit, ViewChild } from '@angular/core';
import { saveAs } from 'file-saver';
import { ComponentStateResolver } from '../../../util/component-state/component-state-resolver';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { StateName } from '../../../app.state-names';
import { Transition, UIRouter } from '@uirouter/core';
import { TranslateService } from '@ngx-translate/core';
import { Workflow, WorkflowService } from '../../../lib/workflow/workflow.service';
import { Set } from 'immutable';
import { OperationRights } from '../../../app.right-definitions';
import { WorkflowBaseModel } from './workflow-base.model';
import { WorkflowBaseDialogComponent } from '../workflow-base-dialog/workflow-base-dialog.component';
import { EmptyMessage, IdentityMessage } from '../../../lib/util/messages';
import { MatDialog } from '@angular/material/dialog';
import { RightModel } from '../../../app.rights';
import { RightResolver, RightService } from '../../../lib/right.service';
import { SidebarTogglerService } from '../../../lib/util/sidebar-toggler.service';
import { Observable } from 'rxjs';
import { TaskRecordStateMachine } from '../../../lib/task/task-record-statemachine';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UnsupportedDeviceComponent } from '../../../shared/unsupported-device/unsupported-device.component';
import { WorkflowCloneDialogComponent } from '../workflow-clone-dialog/workflow-clone-dialog.component';
import { UiConstants } from '../../../util/core-utils';
import VersionState = Workflow.VersionState;

@Component({
  selector: 'app-workflow-base',
  templateUrl: './workflow-base.component.html',
  styleUrls: ['./workflow-base.component.scss']
})
export class WorkflowBaseComponent implements OnInit {

  UiConstants = UiConstants;

  @ViewChild(UnsupportedDeviceComponent, {static: true})
  unsupportedDeviceComponent: UnsupportedDeviceComponent;

  // Component state resolver, determines the state of the component
  componentState: ComponentStateResolver;

  // Variables used for breadcrumb
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  rightModel: RightModel = RightModel.empty();

  model: WorkflowBaseModel = new WorkflowBaseModel();
  private _hasProcess: boolean = false;
  constructor(private uiRouter: UIRouter,
              private transition: Transition,
              private translateService: TranslateService,
              private dialog: MatDialog,
              private modalService: BsModalService,
              private rightService: RightService,
              private workflowService: WorkflowService,
              private sidebarToggleService: SidebarTogglerService
  ) {
    if (this.transition.params().fromDetail) {
      location.reload();
    }
    this.componentState = new ComponentStateResolver(uiRouter, transition,
      'id',
      undefined,
      {stateName: StateName.WORKFLOW_EDIT, stateHeaderKey: 'WORKFLOW_EDIT'},
      {stateName: StateName.WORKFLOW_DETAIL, stateHeaderKey: 'WORKFLOW_DETAIL'});
  }

  ngOnInit() {
    this.initBreadcrumb();
    this.loadRightModels();
    this.loadModel();
    this.loadHasProcess();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  initBreadcrumb() {
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('MENU_NAVBAR_NAV_TITLE_WORKFLOWS').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.WORKFLOW_LIST});
      }
    );
  }

  loadModel() {
    const rights = Set.of(
      OperationRights.WORKFLOW_UPDATE,
      OperationRights.WORKFLOW_DELETE,
      OperationRights.WORKFLOW_ACTIVATE,
      OperationRights.WORKFLOW_FINALIZE,
      OperationRights.WORKFLOW_CREATE_NEW_VERSION,
    );
    this.workflowService.get({
      id: this.componentState.id!,
      fields: f => f.each,
      rights: rights
    }).subscribe(workflow => {
      this.breadcrumbSelf = workflow.name;
      this.model = new WorkflowBaseModel(workflow);
    });
  }

  private loadHasProcess() {
    this._hasProcess = false;
    if (this.componentState.hasEntity()) {
      this.workflowService.stateCount({
        id: this.componentState.id!
      }).subscribe(result => {
        const keys = Object.keys(result);
        if (keys.length > 0) {
          this._hasProcess = true;
        }
      });
    }
  }

  onOpenBaseDataDetailButtonClicked() {
    const dialogRef = this.dialog.open(WorkflowBaseDialogComponent, {
      width: '70%',
      panelClass: 'custom-dialog-container',
      maxHeight: '100vh',
      data: {readonly: true, workflow: this.model.workflow}
    });

  }

  onModifyBaseDataButtonClicked() {
    const dialogRef = this.dialog.open(WorkflowBaseDialogComponent, {
      width: '70%',
      panelClass: 'custom-dialog-container',
      maxHeight: '100vh',
      data: {readonly: false, workflow: this.model.workflow}
    });

    dialogRef.afterClosed().subscribe((result: IdentityMessage) => {
      if (result) {
        location.reload();
      }
    });
  }

  openWorkflowCloneDialog() {
    const request = this.model.toCloneRequest();
    if (request) {
      this.dialog.open(WorkflowCloneDialogComponent, {
        width: '60%',
        panelClass: 'custom-dialog-container',
        data: request
      });
    }
  }

  exportWorkflow() {
    this.workflowService.export({id: this.componentState.id!})
      .subscribe((result: any) => {
        const blob = new Blob([JSON.stringify(result)], {type: 'text/json;charset=utf-8'});
        saveAs(blob, 'workflow_' + this.model.workflow!.externalId + '.json');
      });
  }

  editWorkflow() {
    this.uiRouter.stateService.go(StateName.WORKFLOW_EDIT, {
      id: this.componentState.id!,
      fromDetail: true
    });
  }

  editNewVersion() {
    this.workflowService.createNewVersion({id: this.componentState.id!})
      .subscribe((result: IdentityMessage) => {
        this.uiRouter.stateService.go(StateName.WORKFLOW_EDIT, {
          id: result.id,
          fromDetail: true
        });
      });
  }

  inactivateWorkflow() {
    this.workflowService.inactivate({id: this.componentState.id!})
      .subscribe((result: EmptyMessage) => {
        this.loadModel();
      });
  }

  activateWorkflow() {
    this.workflowService.activate({id: this.componentState.id!})
      .subscribe((result: EmptyMessage) => {
        this.loadModel();
      });
  }

  deleteWorkflow() {
    this.workflowService.delete({id: this.componentState.id!})
      .subscribe((result: EmptyMessage) => {
        this.uiRouter.stateService.go(StateName.WORKFLOW_LIST);
      });
  }

  finalizeWorkflow() {
    this.workflowService.finalize({id: this.componentState.id!})
      .subscribe((result: EmptyMessage) => {
        this.loadModel();
      });
  }

  isReadonly(): boolean {
    return this.componentState.isReadonly();
  }

  isFooterEnabled(): boolean {
    if (this.isReadonly()) {
      return false;
    }
    return this.isDeletable();
  }

  isDraft(): boolean {
    if (this.model.workflow) {
      return this.model.workflow.versionState === Workflow.VersionState.DRAFT;
    }
    return false;
  }

  isInactive(): boolean {
    if (this.model.workflow) {
      return this.model.workflow.versionState === Workflow.VersionState.INACTIVE;
    }
    return false;
  }

  isDeletable(): boolean {
    if (this.model.workflow) {
      return this.isDraft() || ((this.model.workflow.versionState === Workflow.VersionState.FINALIZED ||
          this.model.workflow.versionState === Workflow.VersionState.INACTIVE)
        && this.model.workflow.version === 1 && !this.hasProcess());
    }
    return false;
  }

  hasProcess(): boolean {
    return this._hasProcess;
  }

  get sidebarCompact(): Observable<boolean> {
    return this.sidebarToggleService.observable;
  }

}
