<div class="w-100 vh-100">
  <div class="login-background"
       [ngStyle]="backgroundStyle">
    <img class="vh-100 wave" src="assets/img/appworks/login-wave.png" *ngIf="!registerUseCase" #wave/>
    <div class="wave-background" *ngIf="!registerUseCase" #waveBackground></div>
    <div class="fixed-bottom bottom-out" #bottom
         [ngStyle]="{'width': registerUseCase ? '50%' : '65%'}">
      <div class="introduction-text my-1">
        <h3 class="text-white mb-2">{{titleStringKey | translate}}</h3>
        <h5 class="text-white mb-2">{{subtitleStringKey | translate}}</h5>
        <div class="d-flex justify-content-start align-items-center">
          <h5 class="powered-by-text font-weight-lighter mr-1">{{'LOGIN_POWERED_BY' | translate}}</h5>
          <div class="powered-by-image cursor-pointer" (click)="onNavigate()"></div>
        </div>
      </div>
    </div>
  </div>
</div>
