<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]=breadcrumbParents
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>

<div class="container-custom-padding animated fadeIn">

  <div class="form-group row mx-0">

    <div class="col-xs-12 col-sm-12 col-md-8 card-custom-padding">
      <div class="card">
        <div class="card-body p-0">
          <mat-vertical-stepper [linear]="isLinear" (selectionChange)="hideOverflow()" (animationDone)="showOverflow()"
                               [ngClass]="{ 'overflow-visible': overflowVisible }" #stepper>
            <ng-template matStepperIcon="edit" let-index="index"><span class="mat-step-icon-content ng-star-inserted">{{index+1}}</span></ng-template>
            <ng-template matStepperIcon="done" let-index="index"><span class="mat-step-icon-content ng-star-inserted">{{index+1}}</span></ng-template>
            <ng-template matStepperIcon="number" let-index="index"><span class="mat-step-icon-content ng-star-inserted">{{index+1}}</span></ng-template>
            <mat-step [stepControl]="companyFormGroup" [ngClass]="{ 'mat-step-icon-active': stepper.selectedIndex === 0}">
              <form [formGroup]="companyFormGroup">
                <ng-template matStepLabel>
                  <span class="required-field-label">
                    {{'COMMON_COMPANY' | translate}}
                  </span>
                  <br>
                  {{createModel.selectedCompanyText}}
                </ng-template>
                <div class="d-flex">
                  <div class="flex-grow-1">
                    <angular2-multiselect
                      formControlName="companyControl"
                      [(ngModel)]="createModel.company"
                      (ngModelChange)="onSelectedCompanyChanged()"
                      [data]="companies"
                      [settings]="dropdownSettings">
                    </angular2-multiselect>
                  </div>
                  <button *ngIf="rightModel.companyCreate.hasRight()" type="button" class="btn btn-secondary ml-1" (click)="companyCreateModal.showCompanyCreateDialog()">
                    {{'COMPANY_ADD' | translate}}
                  </button>
                </div>
                <div class="form-control-feedback color-red"
                     *ngIf="companyFormGroup.controls['companyControl'].touched && companyFormGroup.controls['companyControl'].hasError('required')">
                      {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
                <div class="d-flex justify-content-end">
                  <button matStepperNext type="button" class="btn btn-primary-green mt-1 ml-1" (click)="markAsTouched(companyFormGroup.controls['companyControl'])">
                    {{'COMMON_BUTTON_NEXT' | translate}}
                  </button>
                </div>
              </form>
            </mat-step>
            <mat-step [stepControl]="companyLocationFormGroup">
              <form [formGroup]="companyLocationFormGroup">
                <ng-template matStepLabel>
                  <span class="required-field-label">
                    {{'COMPANY_LOCATION' | translate}}
                  </span>
                  <br>
                  {{createModel.selectedCompanyLocationText}}
                </ng-template>
                <div class="d-flex">
                  <div class="flex-grow-1">
                    <angular2-multiselect
                      formControlName="companyLocationControl"
                      [(ngModel)]="createModel.companyLocation"
                      (ngModelChange)="onSelectedCompanyLocationChanged()"
                      [data]="companyLocations"
                      [settings]="dropdownSettings">
                    </angular2-multiselect>
                  </div>
                  <button *ngIf="rightModel.companyLocationCreate.hasRight()" type="button" class="btn btn-secondary ml-1" (click)="companyLocationCreateModal.showCompanyLocationCreateDialog()">
                    {{'COMPANY_LOCATION_CREATE' | translate}}
                  </button>
                </div>
                <div class="form-control-feedback color-red"
                     *ngIf="companyLocationFormGroup.controls['companyLocationControl'].touched && companyLocationFormGroup.controls['companyLocationControl'].hasError('required')">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
                <div class="d-flex justify-content-end">
                  <button matStepperPrevious type="button" class="btn btn-secondary-green mt-1">
                    {{'COMMON_BUTTON_BACK' | translate}}
                  </button>
                  <button matStepperNext type="button" class="btn btn-primary-green mt-1 ml-1" (click)="markAsTouched(companyLocationFormGroup.controls['companyLocationControl'])">
                    {{'COMMON_BUTTON_NEXT' | translate}}
                  </button>
                </div>
              </form>
            </mat-step>
            <mat-step [stepControl]="userGroupFormGroup">
              <form [formGroup]="userGroupFormGroup">
                <ng-template matStepLabel>
                  <span class="required-field-label">
                    {{'COMMON_USER_GROUP' | translate}}
                  </span>
                  <br>
                  {{createModel.selectedUserGroupText}}
                </ng-template>
                <div class="d-flex">
                  <div class="flex-grow-1">
                    <angular2-multiselect
                      formControlName="userGroupControl"
                      [(ngModel)]="createModel.userGroup"
                      (ngModelChange)="onSelectedUserGroupChanged()"
                      [data]="userGroups"
                      (onRemoteSearch)="loadUserGroups($event.target.value)"
                      [settings]="usergroupDropdownSettings">
                    </angular2-multiselect>
                  </div>
                  <button *ngIf="rightModel.userGroupCreate.hasRight()" type="button" class="btn btn-secondary ml-1" (click)="userGroupCreateModal.showUserGroupCreateDialog()">
                    {{'USER_GROUP_PANEL_HEADING_CREATE' | translate}}
                  </button>
                </div>
                <div class="form-control-feedback color-red"
                     *ngIf="userGroupFormGroup.controls['userGroupControl'].touched && userGroupFormGroup.controls['userGroupControl'].hasError('required')">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
                <div class="d-flex justify-content-end">
                  <button matStepperPrevious type="button" class="btn btn-secondary-green mt-1">
                    {{'COMMON_BUTTON_BACK' | translate}}
                  </button>
                  <button matStepperNext type="button" class="btn btn-primary-green mt-1 ml-1" (click)="markAsTouched(userGroupFormGroup.controls['userGroupControl'])">
                    {{'COMMON_BUTTON_NEXT' | translate}}
                  </button>
                </div>
              </form>
            </mat-step>
            <mat-step [stepControl]="companyStockFormGroup">
              <form [formGroup]="companyStockFormGroup">
                <ng-template matStepLabel>{{'FORM_RECORD_STOCK_STOCK' | translate}}</ng-template>
                <div class="form-group row"
                     [ngClass]="{ 'has-danger': (companyStockFormGroup.controls['externalId'].touched && companyStockFormGroup.controls['externalId'].hasError('required')) || fieldErrors.external_id }">
                  <div class="col-md-4">
                    <label class="mw-100 col-form-label form-control-label">{{'COMMON_EXTERNAL_ID' | translate}}</label>
                  </div>
                  <div class="col-md-8">
                    <div class="mw-100">
                      <input type="text" class="form-control"
                             formControlName="externalId"
                             maxlength="{{UiConstants.maximumVarcharLength}}"
                             [(ngModel)]="createModel.externalId"
                             [ngClass]="{ 'form-control-danger': (companyStockFormGroup.controls['externalId'].touched && companyStockFormGroup.controls['externalId'].hasError('required')) || fieldErrors.external_id}"
                             (ngModelChange)="removeFieldError(fieldErrors.external_id)"
                             placeholder="{{'COMMON_EXTERNAL_ID' | translate}}">
                      <div class="form-control-feedback"
                           *ngIf="companyStockFormGroup.controls['externalId'].touched && companyStockFormGroup.controls['externalId'].hasError('required')">
                        {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                      </div>
                      <div class="form-control-feedback" *ngIf="fieldErrors.external_id">{{fieldErrors.external_id.text}}</div>
                    </div>
                  </div>
                </div>
                <div class="form-group row"
                     [ngClass]="{ 'has-danger': (companyStockFormGroup.controls['name'].touched && companyStockFormGroup.controls['name'].hasError('required')) || fieldErrors.name}">
                  <div class="col-md-4">
                    <label class="mw-100 col-form-label form-control-label required-field-label">
                      {{'COMMON_NAME' | translate}}
                    </label>
                  </div>
                  <div class="col-md-8">
                    <div class="mw-100">
                      <input type="text" class="form-control"
                             formControlName="name"
                             maxlength="{{UiConstants.maximumVarcharLength}}"
                             [(ngModel)]="createModel.name"
                             [ngClass]="{ 'form-control-danger': (companyStockFormGroup.controls['name'].touched && companyStockFormGroup.controls['name'].hasError('required')) || fieldErrors.name }"
                             (ngModelChange)="removeFieldError(fieldErrors.name)"
                             placeholder="{{'COMMON_NAME' | translate}}">
                      <div class="form-control-feedback"
                           *ngIf="companyStockFormGroup.controls['name'].touched && companyStockFormGroup.controls['name'].hasError('required')">
                        {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                      </div>
                      <div class="form-control-feedback" *ngIf="fieldErrors.name">{{fieldErrors.name.text}}</div>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-end">
                  <button matStepperPrevious type="button" class="btn btn-secondary-green mt-1">
                    {{'COMMON_BUTTON_BACK' | translate}}
                  </button>
                  <button type="button" class="btn btn-primary-green mt-1 ml-1" (click)="createStock()">
                    {{'COMMON_BUTTON_SAVE' | translate}}
                  </button>
                </div>
              </form>
            </mat-step>
          </mat-vertical-stepper>
        </div>
      </div>

    </div>

  </div>

</div>

<app-company-create-modal
  #companyCreateModal
  (companyCreated)="onCompanyCreated($event)">
</app-company-create-modal>

<app-company-location-create-modal
  #companyLocationCreateModal
  [companyId]="getSelectedCompanyId()"
  (companyLocationCreated)="onCompanyLocationCreated($event)">
</app-company-location-create-modal>

<app-user-group-create-modal
  #userGroupCreateModal
  [companyId]="getSelectedCompanyId()"
  (userGroupCreated)="onUserGroupCreated($event)">
</app-user-group-create-modal>
