<!--container for everything-->
<div class="mx-1" [class.mt-1]="!displayOnProcessDetail">

  <div class="row">

    <div class="p-0 canvas-container" id="canvas-container"
      [class.col-9]="(!readonly || canvasSelection.type !== CanvasSelectionType.NONE) && !displayOnProcessDetail"
      [class.col-12]="(readonly && canvasSelection.type === CanvasSelectionType.NONE) || displayOnProcessDetail"
      [class.canvas-container-readonly]="readonly"
      [class.canvas-container-on-process]="displayOnProcessDetail">
      <div id="canvas">
      </div>
      <div class="zoom-container" #zoomContainer
           [class.zoom-container-readonly-full]="readonly"
           [class.zoom-container-readonly]="readonly && canvasSelection.type !== CanvasSelectionType.NONE"
           [class.zoom-container-process-full]="displayOnProcessDetail"
           [class.zoom-container-process]="displayOnProcessDetail && canvasSelection.type === CanvasSelectionType.TASK">
        <button type="button" class="zoom-button d-flex align-items-end justify-content-center" (click)="zoomIn()">
          <i class="icomoon icomoon-add-nocircle"></i>
        </button>
        <button type="button" class="zoom-button d-flex align-items-end justify-content-center" (click)="zoomOut()">
          <i class="icomoon icomoon-line-horizontal"></i>
        </button>
      </div>
    </div>

    <div class="col-3 data-column-right" [class.d-none]="(readonly && canvasSelection.type === CanvasSelectionType.NONE) || displayOnProcessDetail">
      <app-workflow-create-editor-data-edit
        [workflowId]="workflowId"
        [canvasSelection]="canvasSelection"
        [canvas]="app.canvas"
        [readonly]="readonly"
        [defaultInheritanceMode]="workflow ? workflow.inheritanceMode : undefined">
      </app-workflow-create-editor-data-edit>
    </div>


  </div>


</div>
