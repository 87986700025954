import { Component, HostBinding, Input, OnDestroy, Renderer2, ElementRef, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { ResponsiveTabDirective } from './responsive-tab.directive';
import { ResponsiveTabsetConfig } from './responsive-tabset.config';
import { TabSelectService } from '../../lib/util/tab-select.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'responsive-tabset',
  templateUrl: './responsive-tabset.component.html',
  styleUrls: ['./responsive-tabset.component.scss']
})
export class ResponsiveTabsetComponent implements OnDestroy, AfterViewInit {
  hiddenTabCount: number = 0;
  /** if true tabs will be placed vertically */
  @Input()
  get vertical(): boolean {
    return this._vertical;
  }
  set vertical(value: boolean) {
    this._vertical = value;
    this.setClassMap();
  }

  /** if true tabs fill the container and have a consistent width */
  @Input()
  get justified(): boolean {
    return this._justified;
  }
  set justified(value: boolean) {
    this._justified = value;
    this.setClassMap();
  }

  /** navigation context class: 'tabs' or 'pills' */
  @Input()
  get type(): string {
    return this._type;
  }
  set type(value: string) {
    this._type = value;
    this.setClassMap();
  }

  @HostBinding('class.tab-container') clazz = true;

  @ViewChild('tabListContainer', { static: true }) tabListContainer: ElementRef;
  @ViewChild('tabDropdown', { static: true }) tabDropdown: ElementRef;

  tabs: ResponsiveTabDirective[] = [];
  containerWidth: number = 0;

  classMap: { [key: string]: boolean } = {};

  /** aria label for tab list */
  ariaLabel: string;

  protected isDestroyed: boolean;
  protected _vertical: boolean;
  protected _justified: boolean;
  protected _type: string;

  constructor(
    config: ResponsiveTabsetConfig,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private selectService: TabSelectService
  ) {
    Object.assign(this, config);
  }

  ngAfterViewInit(): void {
    this.selectService.subscribe(id => {
      setTimeout(() => {
        const selectedTab = this.tabs.find(t => t.id === id);
        if (selectedTab) {
          selectedTab.active = true;
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.isDestroyed = true;
  }

  addTab(tab: ResponsiveTabDirective): void {
    this.tabs.push(tab);
    tab.active = this.tabs.length === 1 && typeof tab.active === 'undefined';
  }

  removeTab(
    tab: ResponsiveTabDirective,
    options = { reselect: true, emit: true }
  ): void {
    const index = this.tabs.indexOf(tab);
    if (index === -1 || this.isDestroyed) {
      return;
    }
    // Select a new tab if the tab to be removed is selected and not destroyed
    if (options.reselect && tab.active && this.hasAvailableTabs(index)) {
      const newActiveIndex = this.getClosestTabIndex(index);
      this.tabs[newActiveIndex].active = true;
    }
    if (options.emit) {
      tab.removed.emit(tab);
    }
    this.tabs.splice(index, 1);
    if (tab.elementRef.nativeElement.parentNode) {
      this.renderer.removeChild(
        tab.elementRef.nativeElement.parentNode,
        tab.elementRef.nativeElement
      );
    }
  }

  /* eslint-disable-next-line complexity */
  keyNavActions(event: KeyboardEvent, index: number) {
    const list: HTMLElement[] = Array.from(this.elementRef.nativeElement.querySelectorAll('.nav-link'));
    // const activeElList = list.filter((el: HTMLElement) => !el.classList.contains('disabled'));

    if (event.keyCode === 13 || event.key === 'Enter' || event.keyCode === 32 || event.key === 'Space') {
      event.preventDefault();
      const currentTab = list[(index) % list.length];
      currentTab.click();

      return;
    }

    if (event.keyCode === 39 || event.key === 'RightArrow') {
      let nextTab: any;
      let shift = 1;

      do {
        nextTab = list[(index + shift) % list.length];

        shift++;
      } while (nextTab.classList.contains('disabled'));

      nextTab.focus();

      return;
    }

    if (event.keyCode === 37 || event.key === 'LeftArrow') {
      let previousTab: any;
      let shift = 1;
      let i = index;

      do {
        if ((i - shift) < 0) {
          i = list.length - 1;
          previousTab = list[i];
          shift = 0;
        }
        else {
          previousTab = list[i - shift];
        }

        shift++;
      } while (previousTab.classList.contains('disabled'));

      previousTab.focus();

      return;
    }

    if (event.keyCode === 36 || event.key === 'Home') {
      event.preventDefault();

      let firstTab: any;
      let shift = 0;

      do {
        firstTab = list[shift % list.length];

        shift++;
      } while (firstTab.classList.contains('disabled'));

      firstTab.focus();

      return;
    }

    if (event.keyCode === 35 || event.key === 'End') {
      event.preventDefault();

      let lastTab: any;
      let shift = 1;
      let i = index;

      do {
        if ((i - shift) < 0) {
          i = list.length - 1;
          lastTab = list[i];
          shift = 0;
        }
        else {
          lastTab = list[i - shift];
        }

        shift++;
      } while (lastTab.classList.contains('disabled'));

      lastTab.focus();

      return;
    }

    if (event.keyCode === 46 || event.key === 'Delete') {
      if (this.tabs[index].removable) {
        this.removeTab(this.tabs[index]);

        if (list[index + 1]) {
          list[(index + 1) % list.length].focus();

          return;
        }

        if (list[list.length - 1]) {
          list[0].focus();
        }
      }
    }
  }

  protected getClosestTabIndex(index: number): number {
    const tabsLength = this.tabs.length;
    if (!tabsLength) {
      return -1;
    }

    for (let step = 1; step <= tabsLength; step += 1) {
      const prevIndex = index - step;
      const nextIndex = index + step;
      if (this.tabs[prevIndex] && !this.tabs[prevIndex].disabled) {
        return prevIndex;
      }
      if (this.tabs[nextIndex] && !this.tabs[nextIndex].disabled) {
        return nextIndex;
      }
    }

    return -1;
  }

  protected hasAvailableTabs(index: number): boolean {
    const tabsLength = this.tabs.length;
    if (!tabsLength) {
      return false;
    }

    for (let i = 0; i < tabsLength; i += 1) {
      if (!this.tabs[i].disabled && i !== index) {
        return true;
      }
    }

    return false;
  }

  protected setClassMap(): void {
    this.classMap = {
      'nav-stacked': this.vertical,
      'flex-column': this.vertical,
      'nav-justified': this.justified,
      [`nav-${this.type}`]: true
    };
  }

  onTabListContainerDomChange(event: any) {
      this.updateTabVisibility();
  }

  updateTabVisibility() {
    for (let i = 0; i < this.tabListContainer.nativeElement.children.length - 1; i++) {
      this.tabListContainer.nativeElement.children[i].hidden = false;
    }
    this.containerWidth = this.tabListContainer.nativeElement.offsetWidth;
    let currentWidth = 0;
    this.hiddenTabCount = 0;
    let hideNext = false;
    for (let i = 0; i < this.tabListContainer.nativeElement.children.length - 1; i++) {
      const isLastTab = i === (this.tabListContainer.nativeElement.children.length - 2 - this.hiddenTabCount);
      if (hideNext || (
        currentWidth + this.tabListContainer.nativeElement.children[i].offsetWidth
        > this.containerWidth - (isLastTab ? 1 : (this.tabDropdown.nativeElement.offsetWidth + 1)))) {
        this.tabListContainer.nativeElement.children[i].hidden = true;
        this.hiddenTabCount++
        hideNext = true;
      }
      currentWidth += this.tabListContainer.nativeElement.children[i].offsetWidth;
    }
  }

  isHiddenTabActive() {
    for (let i = 0; i < this.tabs.length; i++) {
      if (this.tabs.length - this.hiddenTabCount <= i) {
        if (this.tabs[i].active) {
          return true;
        }
      }
    }
    return false;
  }

}
