import { Dates, OffsetDateTime } from '../../lib/util/dates';
import { Address } from '../../lib/address';
import { FieldError } from '../../lib/util/errors';
import { MultiselectOptionItem } from '../../util/core-utils';
import { MileageRecord } from '../../lib/mileage-record/mileage-record.service';

export class MileageRecordModel {

  id: number;
  vehicleId: number;
  taskId?: number;
  taskRecordId?: number;
  taskName: string = '';
  user: MileageRecord.User;
  customerName: string = '';
  vehicleLicencePlate: string = '';
  note: string = '';
  journeyType: string = '';
  startKilometer: number;
  finishKilometer?: number;
  finishTime: OffsetDateTime = Dates.emptyOffsetDateTime();
  creationTime: OffsetDateTime = Dates.emptyOffsetDateTime();
  startCoordinates?: Address.Coordinate;
  finishCoordinates?: Address.Coordinate;

  get kilometerCountText(): string {
    if (this.finishKilometer) {
      return (this.finishKilometer - this.startKilometer) + ' km'
    }
    return '';
  }

  get startKilometerText(): string {
    return this.startKilometer + ' km';
  }

  get finishKilometerText(): string {
    return this.finishKilometer ? this.finishKilometer + ' km' : '';
  }

  get kilometerIntervalText(): string {
    return this.startKilometer + ' - ' + (this.finishKilometer ? this.finishKilometer : '');
  }
}

export class MileageRecordEditModel {
  id: number;
  vehicle: MultiselectOptionItem<number>[] = [];
  note: string = '';
  journeyType: string = '';
  startKilometer: string = '';
  finishKilometer: string = '';

  get vehicleId(): number | undefined {
    return this.vehicle.length > 0 ? this.vehicle[0].id : undefined;
  }
}

export interface MileageRecordFieldErrorMap {
  start_kilometer?: FieldError;
  finish_kilometer?: FieldError;
}
