/* eslint-disable */
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { StateName } from '../../../app.state-names';
import { TranslateService } from '@ngx-translate/core';
import { RightResolver, RightService } from '../../../lib/right.service';
import { RightModel } from '../../../app.rights';
import { QueryFieldModel, UiConstants } from '../../../util/core-utils';
import { ForwardingNgFormRef, LocalFormGroupValidationErrors, OrderType, QueryResult } from '../../../lib/util/services';
import { ApplicationFile, ApplicationFileService } from '../../../lib/application-file/application-file.service';
import { ApplicationFileSearch, ApplicationFileSearchService } from '../../../lib/application-file/application-file-search.service';
import { combineLatest, Observable } from 'rxjs';
import { Set } from 'immutable';
import { Strings } from '../../../lib/util/strings';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { DownloadedFile } from '../../../lib/util/downloaded-files';
import { AppValidators } from '../../../util/app-validators';
import { FileUploadDialogComponent } from '../../../shared/file-upload/dialog/file-upload-dialog.component';
import { saveAs } from 'file-saver';
import { EmptyMessage } from '../../../lib/util/messages';
import { FieldError, FieldErrors, ObservableErrorResourceParser } from '../../../lib/util/errors';
import Content = ApplicationFile.Content;
/* eslint-enable */

@Component({
  selector: 'app-application-file-list',
  templateUrl: './application-file-list.component.html',
  styleUrls: ['./application-file-list.component.scss']
})
export class ApplicationFileListComponent implements OnInit, OnDestroy {

  UiConstants = UiConstants;
  ApplicationFile = ApplicationFile;
  ApplicationFileEditStatus = ApplicationFileEditStatus;

  @ViewChild('f')
  fForm: NgForm;

  @ViewChild('baseDialog', { static: true }) baseDialog: ModalDirective;
  baseDialogVisible: boolean = false;
  applicationFileEditModel: ApplicationFileModel = new ApplicationFileModel();

  @ViewChild('fileImportDialog', { static: true })
  fileImportDialog: FileUploadDialogComponent;
  fileImportPath: string = '';

  @ViewChild('deleteModal', { static: true }) deleteModal: ModalDirective;
  deleteModalVisible: boolean = false;
  selectedDeleteItem: ApplicationFileModel;

  formGroup: FormGroup;
  private formGroupValidationErrors: LocalFormGroupValidationErrors;

  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  queryModel: QueryFieldModel<ApplicationFile.OrderField> = new QueryFieldModel(ApplicationFile.OrderField.ID, OrderType.DESC);
  rightModel: RightModel = RightModel.empty();

  searchResult: ApplicationFileSearch.SearchDataResult;

  showSearch: boolean = false;
  searchModel: ApplicationFileSearchModel = new ApplicationFileSearchModel();

  applicationFileList: ApplicationFileModel[] = [];

  fieldErrors: ApplicationFileErrorMap;


  constructor(
    private translateService: TranslateService,
    private rightService: RightService,
    private applicationFileSearchService: ApplicationFileSearchService,
    private applicationFileService: ApplicationFileService,
    private changeDetector: ChangeDetectorRef,
    fb: FormBuilder,
  ) {
    this.fieldErrors = {};
    this.formGroup = this.createFormGroup(fb);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(this.createForwardingHtmlForm(), this.formGroup);
  }

  ngOnInit() {
    this.loadRightModels();
    this.initBreadcrumb();
    this.loadSearch(() => {
      this.showSearch = !this.searchModel.isEmpty();
        this.loadList()
      });
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  initBreadcrumb() {
    this.translateService.get('MENU_NAVBAR_APPLICATION_FILES').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
  }

  private createFormGroup(fb: FormBuilder): FormGroup {
    return fb.group(
      {
        code: fb.control(
          {value: this.applicationFileEditModel.code},
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => {
                return this.applicationFileEditModel.status === ApplicationFileEditStatus.EDIT;
              }
            })
          ]
        ),
        note: fb.control(
          {value: this.applicationFileEditModel.note},
        ),
      }
    );
  }

  loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();
    this.applicationFileService.query({
      code: Strings.undefinedOrNonEmpty(this.searchModel.code),
      contentType: Strings.undefinedOrNonEmpty(this.searchModel.contentType),
      orders: Set.of(order),
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined
    }).subscribe((result: QueryResult<ApplicationFile.ApplicationFile>) => {
      this.applicationFileList = [];
      result.items.forEach((applicationFile: ApplicationFile.ApplicationFile) => {
        const applicationFileModel = new ApplicationFileModel();
        applicationFileModel.id = applicationFile.id;
        applicationFileModel.code = applicationFile.code;
        applicationFileModel.note = applicationFile.note;
        if (applicationFile.content) {
          applicationFileModel.content = applicationFile.content;
          applicationFileModel.contentType = applicationFile.content.type;
          applicationFileModel.contentUpdateTime = applicationFile.content.updateTime.toUtcIsoString();
        }
        this.applicationFileList.push(applicationFileModel);
      });
      this.queryModel.currentPage = requestedPage;
      this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
    });
  }

  private loadSearch(completion: () => void) {
    const obs: Observable<SearchLoadResult> = combineLatest(
      this.applicationFileSearchService.getSearchData({}),
      (storedSearchData: ApplicationFileSearch.SearchDataResult) => {
        const result: SearchLoadResult = {
          storedSearchData: storedSearchData
        };
        return result;
      }
    );
    obs.subscribe(
      (result: SearchLoadResult) => {
        this.searchResult = result.storedSearchData;
        this.postInitSearch(result.storedSearchData, completion);
      }
    );
  }

  private postInitSearch(storedSearchData: ApplicationFileSearch.SearchDataResult, completion: () => void) {
    this.queryModel.itemsPerPage = storedSearchData.searchData.itemsPerPage;
    this.queryModel.currentPage = storedSearchData.searchData.pageNumber;
    this.queryModel.setOrder(storedSearchData.searchData.order);
    this.searchModel.id = storedSearchData.searchData.id;
    this.searchModel.code = storedSearchData.searchData.code;
    this.searchModel.contentType = storedSearchData.searchData.contentType;
    completion()
  }


  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  onSearchReset() {
    this.applicationFileSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.showSearch = true;
          this.loadList(1);
        });
      }
    );
  }

  onSearchClicked() {
    this.loadList(1);
  }

  private saveSearch() {
    const request = {
      searchData: {
        itemsPerPage: this.queryModel.itemsPerPage,
        pageNumber: this.queryModel.currentPage,
        order: this.queryModel.getOrder(),
        id: this.searchModel.id,
        code: this.searchModel.code,
        contentType: this.searchModel.contentType
      }
    };
    this.applicationFileSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  orderBy(field: ApplicationFile.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  showBaseDialog(applicationFile?: ApplicationFileModel) {

    if (applicationFile) {
      this.loadEditModel(applicationFile)
    }
    else {
      this.applicationFileEditModel = new ApplicationFileModel();
      this.applicationFileEditModel.status = ApplicationFileEditStatus.CREATE;
      this.openBaseDialog();
    }
  }

  loadEditModel(applicationFile: ApplicationFileModel) {
    this.applicationFileService.get(
      {
        id: applicationFile.id!
      }
    ).subscribe((applicationFile: ApplicationFile.ApplicationFile) => {
      this.applicationFileEditModel = new ApplicationFileModel();
      this.applicationFileEditModel.id = applicationFile.id;
      this.applicationFileEditModel.code = applicationFile.code;
      this.applicationFileEditModel.note = applicationFile.note;
      this.applicationFileEditModel.status = ApplicationFileEditStatus.EDIT;
      this.openBaseDialog();
    });
  }

  openBaseDialog() {
    this.baseDialogVisible = true;
    this.baseDialog.show();
    this.changeDetector.detectChanges();
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(this.createForwardingHtmlForm(), this.formGroup);
  }

  private createForwardingHtmlForm() {
    return new ForwardingNgFormRef({
      formFn: () => {
        return this.fForm;
      }
    });
  }

  closeBaseDialog() {
    this.baseDialogVisible = false;
    this.baseDialog.hide();
    this.formGroup.reset();
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    this.formGroup.updateValueAndValidity();
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  removeFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.fieldErrors, fieldError);
  }

  createApplicationDocument() {
    if (this.formGroup.invalid) {
      this.formGroup.get('code')!.markAsTouched();
      return;
    }
    if (this.applicationFileEditModel.status === ApplicationFileEditStatus.CREATE) {
      this.create();
    }
    else {
      this.update();
    }
  }

  create() {
    this.applicationFileService.create(
      {
        code: this.applicationFileEditModel.code,
        note: this.applicationFileEditModel.note
      }
    ).subscribe(
      (responde: EmptyMessage) => {
        this.baseDialogVisible = false;
        this.baseDialog.hide();
        this.formGroup.reset();
        this.loadList();
      },
      (error: any) => {
        const res = ObservableErrorResourceParser.parseError(error);
        this.fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
      });
  }

  update() {
    this.applicationFileService.update(
      {
        id: this.applicationFileEditModel.id!,
        note: this.applicationFileEditModel.note
      }
    ).subscribe(
      (responde: EmptyMessage) => {
        this.baseDialogVisible = false;
        this.baseDialog.hide();
        this.formGroup.reset();
        this.loadList();
      },
      (error: any) => {
        const res = ObservableErrorResourceParser.parseError(error);
        this.fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
      });
  }

  importFile(model: ApplicationFileModel) {
    this.fileImportPath = '/application-files/' + model.id + '/content';
    this.fileImportDialog.toggleDialog()
  }

  public onImportSuccess(succeeded: boolean) {
    if (succeeded) {
      this.loadList();
    }
  }

  deleteFile() {
    const model = this.selectedDeleteItem;
    this.applicationFileService.delete(
      {
        id: model.id!,
      }).subscribe(
      (result: EmptyMessage) => {
      },
      (error: Error) => {
      },
      () => {
        this.loadList();
        this.closeDeleteModal();
      });
  }

  exportFile(model: ApplicationFileModel) {

    this.applicationFileService.export({
      id: model.id!,
    }).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), 'Application_File_' + model.code);
      });
  }

  openDeleteModal(model: ApplicationFileModel) {
    this.selectedDeleteItem = model;
    this.deleteModalVisible = true;
    this.deleteModal.show();
  }

  closeDeleteModal() {
    this.deleteModalVisible = false;
    this.deleteModal.hide();
  }

  getBaseModalTitle(): string {
    if (this.applicationFileEditModel.status === ApplicationFileEditStatus.EDIT) {
      return 'APPLICATION_FILE_EDIT_TITLE'
    }
    return 'APPLICATION_FILE_CREATE_TITLE'
  }

  onCodeChanged() {
    this.removeFieldError(this.fieldErrors.code);
    this.formGroup.get('code')!.updateValueAndValidity();
  }

  onCodePaste() {
    this.formGroup.get('code')!.markAsTouched();
  }

  ngOnDestroy() {
    this.saveSearch();
  }

}

class ApplicationFileSearchModel {

  id: string = '';
  code: string = '';
  contentType: string = '';

  public isEmpty(): boolean {

    return this.id.length === 0
      && this.code.length === 0
      && this.contentType.length === 0
  }

  constructor() {
  }

}

interface SearchLoadResult {
  storedSearchData: ApplicationFileSearch.SearchDataResult,
}

class ApplicationFileModel {
  id?: number;
  code: string = '';
  note?: string = '';
  contentType?: string = '';
  contentUpdateTime: string = '';
  content?: Content;
  status: ApplicationFileEditStatus;
}

enum ApplicationFileEditStatus {
  CREATE = 'CREATE',
  EDIT = 'EDIT'
}

interface ApplicationFileErrorMap {
  code?: FieldError;
}
