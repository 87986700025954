import { Injectable } from '@angular/core';
import { GuidedTourService } from 'ngx-guided-tour';
import { TranslateService } from '@ngx-translate/core';
import { SiteTourFactory, SiteTourId, SiteTourRequest } from './site-tour.factory';
import { HomeTourFactory } from './site-tour-steps/home-tour.factory';
import { SiteTourState, SiteTourStateObject, SiteTourStorage } from './site-tour.storage';
import { ConfigurationService } from '../core-ext/configuration.service';
import { TaskDashboardTourFactory } from './site-tour-steps/task-dashboard-tour.factory';
import { Logger, LoggerFactory } from '../../util/logger-factory';
import {
  TaskRecordListBaseTourFactory,
  TaskRecordListOpen2TourFactory,
  TaskRecordListOpenTourFactory
} from './site-tour-steps/task-list-tour.factory';
import { TaskRecordCreateTourFactory } from './site-tour-steps/task-record-create-tour.factory';
import { Subscription } from 'rxjs';
import { AdminTourFactory } from './site-tour-steps/admin-tour.factory';
import { UserMeService } from '../user/user-me.service';
import { UIRouter } from '@uirouter/angular';
import { StateName } from '../../app.state-names';

@Injectable()
export class SiteTourService {

  private tourMap: Map<SiteTourId, SiteTourFactory> = new Map<SiteTourId, SiteTourFactory>();
  private logger: Logger = LoggerFactory.createLogger('SiteTourService');
  private stepSubscription: Subscription | null;

  constructor(private guidedTourService: GuidedTourService,
              private translateService: TranslateService,
              private userMeService: UserMeService,
              private router: UIRouter,
              private configurationService: ConfigurationService) {
    if (this.configurationService.getConfiguration().feature_flags.demo_mode_enabled) {
      this.tourMap.set(SiteTourId.HOME, new HomeTourFactory(translateService));
      this.tourMap.set(SiteTourId.TASK_DASHBOARD, new TaskDashboardTourFactory(translateService));
      this.tourMap.set(SiteTourId.TASK_RECORD_LIST_BASE, new TaskRecordListBaseTourFactory(translateService));
      this.tourMap.set(SiteTourId.TASK_RECORD_LIST_OPEN_1, new TaskRecordListOpenTourFactory(translateService));
      this.tourMap.set(SiteTourId.TASK_RECORD_LIST_OPEN_2, new TaskRecordListOpen2TourFactory(translateService));
      this.tourMap.set(SiteTourId.TASK_RECORD_CREATE, new TaskRecordCreateTourFactory(translateService));
      this.tourMap.set(SiteTourId.ADMIN_DASHBOARD, new AdminTourFactory(translateService));
      this.router.transitionService.onStart({}, (transition) => {
        this.unsubscribeFromTour();
        this.guidedTourService.resetTour();
      });
    }
  }

  public startTour(
    siteTourId: SiteTourId,
    siteTourRequest: SiteTourRequest
  ) {
    this.logger.info('tour requested: ' + siteTourId);
    this.unsubscribeFromTour();
    this.userMeService.amIProtected().subscribe(p => {
      if (!p && this.configurationService.getConfiguration().feature_flags.demo_mode_enabled) {
        const factory = this.tourMap.get(siteTourId);
        const stateObject = this.getTourState(siteTourId);
        if (stateObject.state === SiteTourState.WAITING || stateObject.state === SiteTourState.IN_PROGRESS) {
          if (factory) {
            const timeout = factory.timeout;
            factory.getTour({
              completeCallback: this.wrapCompleteCallback(siteTourId, siteTourRequest.completeCallback),
              skipCallback: this.wrapSkipCallback(siteTourId, siteTourRequest.skipCallback),
              baseComponent: siteTourRequest.baseComponent,
              startingStep: stateObject.step
            }).subscribe(tour => {
              setTimeout(() => {
                this.guidedTourService.startTour(tour);
                this.stepSubscription = this.guidedTourService.guidedTourCurrentStepStream.subscribe(step => {
                  this.saveStep(siteTourId, step.selector);
                });
                this.logger.info('tour started: ' + siteTourId);
              }, timeout);
            })
          }
        }
      }
    })
  }

  getTourState(siteTourId: SiteTourId): SiteTourStateObject {
    return SiteTourStorage.getInstance().getTourState(siteTourId);
  }

  isTourAvailable(siteTourId: SiteTourId) {
    const stateObject = SiteTourStorage.getInstance().getTourState(siteTourId);
    return stateObject.state === SiteTourState.WAITING || stateObject.state === SiteTourState.IN_PROGRESS;
  }

  private wrapSkipCallback(siteTourId: SiteTourId, skipCallback?: (stepSkippedOn: number) => void): (stepSkippedOn: number) => void {
    return stepSkippedOn => {
      SiteTourStorage.getInstance().setTourState(siteTourId, SiteTourState.SKIPPED);
      this.unsubscribeFromTour();
      if (skipCallback !== undefined) {
        skipCallback(stepSkippedOn);
      }
    }
  }

  private wrapCompleteCallback(siteTourId: SiteTourId, completeCallback?: () => void): () => void {
    return () => {
      SiteTourStorage.getInstance().setTourState(siteTourId, SiteTourState.DONE);
      this.unsubscribeFromTour();
      if (completeCallback !== undefined) {
        completeCallback();
      }
    }
  }

  private unsubscribeFromTour() {
    if (this.stepSubscription) {
      this.stepSubscription.unsubscribe();
      this.stepSubscription = null;
    }
  }

  private saveStep(siteTourId: SiteTourId, selector?: string) {
    SiteTourStorage.getInstance().setTourState(siteTourId, SiteTourState.IN_PROGRESS, selector);
  }

  resetTours() {
    SiteTourStorage.getInstance().clearAll();
    this.router.stateService.go(StateName.STARTUP);
  }

  skipTours() {
    SiteTourStorage.getInstance().skipAll();
    this.guidedTourService.skipTour();
  }
}
