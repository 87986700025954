<div class="p-0 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12">
  <div class="card">
    <div class="card-header">
      {{'STOCK_ITEM_SUMMARY'|translate}}
    </div>

    <div class="card-body">
      <!--TOTAL AMOUNT OF ITEMS IN STOCK-->
      <div class="row" *ngIf="totalAmountOfProductsInStock">
        <div class="form-group col-md-4 mb-0">
          <label readonly
                 class="col-form-label form-control-label detail-title font-weight-bold">{{'STOCK_ITEM_TOTAL_AMOUNT'|translate}}</label>
        </div>
        <div class="form-group col-md-8 mb-0">
          <label readonly
                 class="col-form-label form-control-label detail-description font-weight-bold">{{ totalAmountOfProductsInStock | formattedNumber }} {{ m.unit }}
            <span
              *ngIf="packageEnabled">({{StockItemUtils.convertToPackageStr(totalAmountOfProductsInStock, m.package_data)}}
              )</span></label>
        </div>
      </div>

      <!--INTAKE SUM PRICE-->
      <div class="row" *ngIf="intakeNetPriceSum">
        <div class="form-group col-md-4 mb-0">
          <label readonly
                 class="col-form-label form-control-label detail-title font-weight-bold">{{'STOCK_RECORD_LOT_INTAKE_PRICE_SUM'|translate}}</label>
        </div>
        <div class="form-group col-md-8 mb-0">
          <label readonly
                 class="col-form-label form-control-label detail-description font-weight-bold">{{ intakeNetPriceSum | formattedNumber }} {{ getCurrencyName(m.unit_price.currency_code) }}
          </label>
        </div>
      </div>

      <div class="row" *ngIf="intakeNetPriceAvg">
        <div class="form-group col-md-4 mb-0">
          <label readonly
                 class="col-form-label form-control-label detail-title font-weight-bold">{{'STOCK_RECORD_LOT_INTAKE_PRICE_AVG'|translate}}</label>
        </div>
        <div class="form-group col-md-8 mb-0">
          <label readonly
                 class="col-form-label form-control-label detail-description font-weight-bold">{{ intakeNetPriceAvg | formattedNumber }} {{ getCurrencyName(m.unit_price.currency_code) }}
          </label>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-vertical-padding-half"></div>

<div class="p-0">
  <div class="card" *ngIf="stockItemStock && stockItemStock.length > 0">
    <div class="card-header">
      {{'STOCK_ITEM_DETAIL_STOCK'|translate}}
    </div>

    <!--STOCK INFORMATION-->
    <div class="card-body pt-0">
      <div>
        <table class="table table-striped table-bordered">
          <thead>
          <tr>
            <th class="table-sorter-header long">
              <app-table-sorter-no-op
                [text]="'COMMON_STOCK' | translate"></app-table-sorter-no-op>
            </th>
            <th class="table-sorter-header long">
              <app-table-sorter-no-op
                [text]="'STOCK_RECORD_AMOUNT' | translate"></app-table-sorter-no-op>
            </th>
            <th class="table-sorter-header long">
              <app-table-sorter-no-op
                [text]="'STOCK_RECORD_LOT_INTAKE_PRICE_AVG' | translate"></app-table-sorter-no-op>
            </th>
            <th class="table-sorter-header long">
              <app-table-sorter-no-op
                [text]="'STOCK_RECORD_LOT_INTAKE_PRICE_SUM' | translate"></app-table-sorter-no-op>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let stock of currentStockItemStock | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems };"
              [disabledTableRow]="isDisabled(stock)">
            <td class="fit">
              <a class="id-text cursor-pointer"
                 href="#" onclick="return false"
                 uiSref="Admin.StockDetail"
                 [uiParams]="{ id: stock.stock_id }"
                 title="{{'COMMON_BUTTON_DETAILS' | translate}}">
                {{stock.stock_name}}
              </a>
            </td>
            <td>
              {{ stock.amount | formattedNumber }} {{stockItem.unit}}
              <br/>
              <span class="table-text-subtitle"
                    *ngIf="stockItem.package_data">{{StockItemUtils.convertToPackageStr(stock.amount, stockItem.package_data)}}</span>
            </td>
            <td>{{ stock.intake_avg_net_price | formattedNumber }} {{getCurrencyName(stockItem.unit_price.currency_code)}}</td>
            <td class="font-weight-bold">{{ stock.intake_sum_net_price | formattedNumber }} {{getCurrencyName(stockItem.unit_price.currency_code)}}</td>
          </tr>
          </tbody>
          <tfoot>
          <tr>
            <th class="table-sorter-header long">
              <app-table-sorter-no-op
                [text]="'COMMON_STOCK' | translate"></app-table-sorter-no-op>
            </th>
            <th class="table-sorter-header long">
              <app-table-sorter-no-op
                [text]="'STOCK_RECORD_AMOUNT' | translate"></app-table-sorter-no-op>
            </th>
            <th class="table-sorter-header long">
              <app-table-sorter-no-op
                [text]="'STOCK_RECORD_LOT_INTAKE_PRICE_AVG' | translate"></app-table-sorter-no-op>
            </th>
            <th class="table-sorter-header long">
              <app-table-sorter-no-op
                [text]="'STOCK_RECORD_LOT_INTAKE_PRICE_SUM' | translate"></app-table-sorter-no-op>
            </th>
          </tr>
          </tfoot>
        </table>
        <app-table-paging
          [currentNumberOfItems]="queryModel.currentNumberOfItems"
          [totalNumberOfItems]="queryModel.totalNumberOfItems"
          [itemsPerPage]="queryModel.itemsPerPage"
          (pageChange)="pageChanged($event)"
          (itemsPerPageChange)="itemsPerPageChanged($event)">
        </app-table-paging>
      </div>
    </div>
  </div>
  <div class="container-vertical-padding-half" *ngIf="stockItemStock && stockItemStock.length > 0"></div>

  <div class="p-0 ">
    <div class="card" *ngIf="stockItemReservations && stockItemReservations.length > 0">
      <div class="card-header">
        {{'STOCK_ITEM_DETAIL_RESERVATIONS'|translate}}
      </div>

      <!--RESERVATION INFORMATION-->
      <div class="card-body pt-0">

        <!--STOCK ITEM RESERVATIONS-->
        <ng-container *ngFor="let reservation of stockItemReservations">
          <div class="row col-md-8">
            <div class="form-group col-md-4 mb-0 pl-0">
              <label readonly
                     class="col-form-label form-control-label detail-title">{{reservation.company.name}}</label>
            </div>
            <div class="form-group col-md-8 mb-0">
              <label readonly class="col-form-label form-control-label detail-description">
                {{ reservation.amount | formattedNumber }} {{stockItem.unit}} <span
                *ngIf="packageEnabled">({{StockItemUtils.convertToPackageStr(reservation.amount, m.package_data)}}
                )</span>
              </label>
            </div>
          </div>
        </ng-container>

      </div>
    </div>
  </div>
</div>
