import { Component, OnInit, ViewChild } from '@angular/core';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { StateName } from '../../../app.state-names';
import { TranslateService } from '@ngx-translate/core';
import { Transition, UIRouter } from '@uirouter/angular';
import { Shipment, ShipmentGroup, ShipmentGroupService } from '../../../lib/shipment-group/shipment-group.service';
import {
  ShipmentGroupEndpoints,
  ShipmentGroupModel,
  ShipmentGroupOuttakeModel,
  ShipmentGroupShippingDemandModel
} from './shipment-group.model';
import { Dates } from '../../../lib/util/dates';
import { ShippingDemand } from '../../../lib/shipping-demand/shipping-demand.service';
import { ComponentStateResolver } from '../../../util/component-state/component-state-resolver';
import { Address } from '../../../lib/address';
import { ConfigurationService } from '../../../lib/core-ext/configuration.service';
import { ConfigModel } from '../../../util/task-record-utils';
import { MultiselectOptionItem, SelectUtils, UiConstants } from '../../../util/core-utils';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import {
  ForwardingNgFormRef,
  LocalFormGroupValidationErrors,
  OrderType,
  QueryResult,
  Services
} from '../../../lib/util/services';
import { Company, CompanyService } from '../../../lib/company/company.service';
import { Set } from 'immutable';
import { CompanyLocation, CompanyLocationService } from '../../../lib/company-location/company-location.service';
import { ShipmentGroupDepotListComponent } from './shipment-group-depot-list/shipment-group-depot-list.component';
import { ToasterService } from '../../../fork/angular2-toaster/src/toaster.service';
import { ShipmentRightModel } from '../../../app.rights.shpiment';
import { RightResolver, RightService } from '../../../lib/right.service';
import { RightModel } from '../../../app.rights';
import { User, UserService } from '../../../lib/user.service';
import { Strings } from '../../../lib/util/strings';
import { Angular2Multiselects } from '../../../util/multiselect';

@Component({
  selector: 'app-shipment-group-base',
  templateUrl: './shipment-group-base.component.html',
  styleUrls: ['./shipment-group-base.component.scss']
})
export class ShipmentGroupBaseComponent implements OnInit {

  SelectUtils = SelectUtils;

  @ViewChild('depotListComponent', { static: true })
  depotListComponent: ShipmentGroupDepotListComponent;

  @ViewChild('addDepotDialog', { static: true })
  addDepotDialog: ModalDirective;
  addDepotDialogVisible: boolean = false;

  @ViewChild('forceReopenDialog', { static: true })
  forceReopenDialog: ModalDirective;
  forceReopenDialogVisible: boolean = false;

  @ViewChild('changeAssigneeDialog', { static: true })
  changeAssigneeDialog: ModalDirective;
  changeAssigneeDialogVisible: boolean = false;
  changeAssigneeModel: MultiselectOptionItem<number>[] = [];
  assigneeUsers: MultiselectOptionItem<number>[] = [];
  dropdownSettings: Angular2Multiselects.Settings;

  formGroup: FormGroup;
  private formGroupValidationErrors: LocalFormGroupValidationErrors;

  @ViewChild('f')
  form?: NgForm;

  shipmentGroupId: number;
  shipmentGroupModel: ShipmentGroupModel = new ShipmentGroupModel();
  shipmentRightModel: ShipmentRightModel = ShipmentRightModel.empty();
  rightModel: RightModel = RightModel.empty();

  componentState: ComponentStateResolver;

  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  config: ConfigModel = new ConfigModel();

  addDepotModel: AddDepotModel = new AddDepotModel();
  companies: MultiselectOptionItem<number>[] = [];
  companyLocations: MultiselectOptionItem<number>[] = [];

  constructor(private rightService: RightService,
              private translateService: TranslateService,
              private transition: Transition,
              private uiRouter: UIRouter,
              private userService: UserService,
              private companyService: CompanyService,
              private companyLocationService: CompanyLocationService,
              private shipmentGroupService: ShipmentGroupService,
              private configurationService: ConfigurationService,
              private toasterService: ToasterService,
              fb: FormBuilder) {
    this.shipmentGroupId = this.transition.params().id;
    this.componentState = new ComponentStateResolver(uiRouter, transition,
      'id',
      undefined,
      {stateName: StateName.SHIPMENT_EDIT, stateHeaderKey: 'SHIPMENT_EDIT'},
      {stateName: StateName.SHIPMENT_DETAIL, stateHeaderKey: 'SHIPMENT_DETAILS'});
    this.formGroup = this.createFormGroup(fb);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(this.createForwardingHtmlForm(), this.formGroup);
  }

  ngOnInit() {
    this.loadRightModels();
    this.initBreadcrumb();
    this.initDropdownSettings();
    this.loadConfig(() => {
      this.loadModel();
    });
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.userService.get({
          id: resolver.userProfile!.id
        }).subscribe((user) => {
          this.rightModel = RightModel.of(resolver);
          this.shipmentRightModel = ShipmentRightModel.of({
            rightModel: this.rightModel,
            currentCompanies: user.companies.map(c => c.id)
          });
        });
      }
    );
  }

  private initBreadcrumb() {
    this.translateService.get('MENU_NAVBAR_MENU_SHIPMENTS').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.SHIPMENT_LIST});
      }
    );
    this.translateService.get(this.componentState.getCurrentHeaderKey()).subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
  }

  initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
  }

  private loadConfig(completion: () => void) {
    this.config = this.configurationService.getConfigurationModel();
    completion();
  }

  loadModel() {
    this.shipmentGroupService.get({
      id: this.shipmentGroupId
    }).subscribe((result: ShipmentGroup.ShipmentGroup) => {
      this.shipmentGroupModel = new ShipmentGroupModel();
      this.shipmentGroupModel.id = result.id;
      this.shipmentGroupModel.creationTime = result.demands && result.demands.size > 0 ?
        result.demands.first().creationTime : Dates.emptyOffsetDateTime();
      this.shipmentGroupModel.deliveryNoteNumber = result.deliveryNote.number;
      this.shipmentGroupModel.transporterCompany  = result.transporter;
      this.shipmentGroupModel.demander = result.demander && result.demander.company ?
        result.demander.company.name : '';
      this.shipmentGroupModel.endpoints = new ShipmentGroupEndpoints();
      this.shipmentGroupModel.endpoints.source.name = this.unwrapName(result.source);
      this.shipmentGroupModel.endpoints.source.address = this.unwrapAddress(result.source);
      this.shipmentGroupModel.endpoints.source.coords = this.unwrapCoords(result.source);
      this.shipmentGroupModel.endpoints.destination.name = this.unwrapName(result.destination);
      this.shipmentGroupModel.endpoints.destination.address = this.unwrapAddress(result.destination);
      this.shipmentGroupModel.endpoints.destination.coords = this.unwrapCoords(result.destination);
      this.shipmentGroupModel.stateObject =
        ShipmentGroup.shipmentGroupStates.find(sgs => sgs.state === result.state)!;
      this.shipmentGroupModel.shippingDemands = [];
      if (result.demands) {
        result.demands.forEach((demand: ShippingDemand.ShippingDemand) => {
          const shippingDemand = new ShipmentGroupShippingDemandModel();
          shippingDemand.id = demand.id;
          shippingDemand.deliveryNoteNumber = demand.demandSerial ? demand.demandSerial : '';
          shippingDemand.stateStringKey =
            ShippingDemand.shippingDemandStates.find(sds => sds.state === demand.state)!.stringKey;
          this.shipmentGroupModel.shippingDemands.push(shippingDemand);
        });
      }
      if (result.outtake) {
        const outtake = new ShipmentGroupOuttakeModel();
        outtake.shipmentId = result.outtake!.shipmentId;
        outtake.state = Shipment.shipmentOuttakeStates.find(s => s.state === result.outtake!.state)!;
        outtake.storekeeper = {
          name: result.outtake!.storekeeper ? result.outtake!.storekeeper!.personName : '',
          id: result.outtake!.storekeeper ? result.outtake!.storekeeper!.id : undefined,
          groupId: result.source.stock!.assigneeGroupId
        };
        outtake.progressCount = result.outtake!.progressCount;
        result.outtake.eventLogs.toArray().forEach(l => {
          if (l.eventType === 'OUTTAKE_START') {
            outtake.outtakeStartTime = l.creationTime;
          }
        });
        this.shipmentGroupModel.outtake = outtake;
      }
    });
  }

  unwrapAddress(shippingPlace: ShippingDemand.ShippingPlace): string {
    if (shippingPlace.place && shippingPlace.place.postalAddress) {
      return Address.PostalAddressMapper.toString(shippingPlace.place.postalAddress, this.config.postalAddressFormat);
    }
    else if (shippingPlace.location && shippingPlace.location.place && shippingPlace.location.place.postalAddress) {
      return Address.PostalAddressMapper.toString(shippingPlace.location.place.postalAddress, this.config.postalAddressFormat);
    }
    else {
      return '';
    }
  }

  unwrapName(shippingPlace: ShippingDemand.ShippingPlace): string {
    if (shippingPlace.place) {
      return shippingPlace.place.name;
    }
    else if (shippingPlace.location) {
      return shippingPlace.location.name;
    }
    else {
      return '';
    }
  }

  unwrapCoords(shippingPlace: ShippingDemand.ShippingPlace): google.maps.LatLng | undefined {
    const lat: number | undefined
      = shippingPlace.location && shippingPlace.location.place
      ? shippingPlace.location.place.coordinate
        ? shippingPlace.location.place.coordinate.latitude.toNumber()
        : undefined
      : shippingPlace.place.coordinate
        ? shippingPlace.place.coordinate.latitude.toNumber()
        : undefined;
    const lon: number | undefined
      = shippingPlace.location && shippingPlace.location.place
      ? shippingPlace.location.place.coordinate
        ? shippingPlace.location.place.coordinate.longitude.toNumber()
        : undefined
      : shippingPlace.place.coordinate
        ? shippingPlace.place.coordinate.longitude.toNumber()
        : undefined;
    return lat && lon ? new google.maps.LatLng(lat, lon) : undefined;
  }

  private createFormGroup(fb: FormBuilder): FormGroup {
    return fb.group(
      {
        company: fb.control(
          {value: this.addDepotModel.companyId},
          [
            Validators.required,
          ]
        ),
        companyLocation: fb.control(
          {value: this.addDepotModel.companyLocationId},
          [
            Validators.required,
          ]
        )
      }
    );
  }

  private createForwardingHtmlForm() {
    return new ForwardingNgFormRef({
      formFn: () => {
        return this.form;
      }
    });
  }

  private loadCompanies(completion: () => void) {
    this.companyService.query({
      disabled: false,
      orders: Set.of({field: Company.OrderField.NAME, type: OrderType.ASC})
    }).subscribe((result: QueryResult<Company.Company>) => {
      this.companies = result.items.toArray().map(c => ({id: c.id, itemName: c.name}));
      completion();
    });
  }

  openAddDepotDialog() {
    this.companyLocations = [];
    this.loadCompanies(() => {
      this.formGroup.get('company')!.markAsUntouched();
      this.formGroup.get('companyLocation')!.markAsUntouched();
      this.addDepotDialogVisible = true;
      this.addDepotDialog.show();
    });
  }

  onCompanySelected() {
    this.addDepotModel.companyLocationId = null;
    this.companyLocationService.query({
      companyIds: Set.of(this.addDepotModel.companyId!),
      disabled: false,
      orders: Set.of({field: CompanyLocation.OrderField.NAME, type: OrderType.ASC})
    }).subscribe((result: QueryResult<CompanyLocation.CompanyLocation>) => {
      this.companyLocations = result.items.toArray().map(l => ({id: l.id, itemName: l.name}));
    });
  }

  closeAddDepotDialog() {
    this.addDepotModel.reset();
    this.addDepotDialogVisible = false;
    this.addDepotDialog.hide();
  }

  openForceReopenDialog() {
    this.forceReopenDialogVisible = true;
    this.forceReopenDialog.show();
  }

  forceReopen() {
    this.shipmentGroupService.forceReopenOuttake({
      shipmentId: this.shipmentGroupModel.outtake!.shipmentId
    }).subscribe(() => {
      this.closeForceReopenDialog();
      this.loadModel();
    });
  }

  closeForceReopenDialog() {
    this.forceReopenDialogVisible = false;
    this.forceReopenDialog.hide();
  }

  loadAssigneeUsers(q?: string, completion?: () => void) {
    this.userService.query({
      user_group_ids: this.shipmentGroupModel.outtake!.storekeeper.groupId + '',
      person_name: q ? Strings.undefinedOrNonEmpty(q) : undefined,
      disabled: false,
      fields: ['id', 'person_name'].join(','),
      order: Services.createOrderFieldParameter(User.Keys.toOrderFieldKey,
        Set.of({field: User.OrderField.PERSON_NAME, type: OrderType.ASC})),
      page_number: 1,
      number_of_items: UiConstants.autocompletePageSize,
      no_progress_bar: true
    }).subscribe((result) => {
      this.assigneeUsers = result.items.map(item => ({id: item.id, itemName: item.person_name}));
      if (completion) {
        completion();
      }
    });
  }

  openChangeAssigneeDialog() {
    this.loadAssigneeUsers(undefined, () => {
      if (this.shipmentGroupModel.outtake.storekeeper.id) {
        this.changeAssigneeModel.push({
          id: this.shipmentGroupModel.outtake.storekeeper.id,
          itemName: this.shipmentGroupModel.outtake.storekeeper.name
        });
      }
      this.changeAssigneeDialogVisible = true;
      this.changeAssigneeDialog.show();
    });
  }

  changeAssignee() {
    this.shipmentGroupService.changeOuttakeStorekeeper({
      shipmentId: this.shipmentGroupModel.outtake!.shipmentId,
      userId: this.changeAssigneeModel.length === 1 ? this.changeAssigneeModel[0].id : undefined
    }).subscribe(() => {
      this.closeChangeAssigneeDialog();
      this.loadModel();
    });
  }

  closeChangeAssigneeDialog() {
    this.changeAssigneeModel = [];
    this.changeAssigneeDialogVisible = false;
    this.changeAssigneeDialog.hide();
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  addDepot() {
    this.formGroup.updateValueAndValidity();
    if (this.formGroup.invalid) {
      this.formGroup.get('company')!.markAsTouched();
      this.formGroup.get('companyLocation')!.markAsTouched();
      return;
    }
    this.shipmentGroupService.createDepot({
      shipmentGroupId: this.shipmentGroupId,
      companyLocationId: this.addDepotModel.companyLocationId!
    }).subscribe(() => {
      this.closeAddDepotDialog();
      this.depotListComponent.loadDepots();
    });
  }

  canEditGroup(): boolean {
    if (this.shipmentGroupModel) {
      return this.shipmentGroupModel.stateObject.state !== 'DELIVERED' && this.shipmentRightModel.shipmentGroupDemandRemove
          .hasRightForShipment({transporterId: this.shipmentGroupModel.transporterCompany.id}, undefined)
        || this.shipmentRightModel.shipmentGroupDepotUpdate
          .hasRightForShipment({transporterId: this.shipmentGroupModel.transporterCompany.id}, undefined);
    }
    return false;
  }
}

class AddDepotModel {
  companyId: number | null = null;
  companyLocationId: number | null = null;

  reset() {
    this.companyId = null;
    this.companyLocationId = null;
  }
}
