<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
    <app-breadcrumb-menu>
      <app-breadcrumb-button
        *ngIf="rightModel.companyLocationUpdate.hasRight() && componentState.isDetailView()"
        [iconClass]="'icomoon-modify'"
        [titleStringKey]="'COMMON_BUTTON_EDIT'"
        uiSref="Admin.CompanyLocationEdit"
        [uiParams]="{ id: componentState.id }">
      </app-breadcrumb-button>
    </app-breadcrumb-menu>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>

<div class="container-horizontal-padding container-vertical-padding-half animated fadeIn">
  <responsive-tabset class="fullscreen_tab">
    <responsive-tab heading="{{'COMMON_CARD_HEADER_BASE_DATA'|translate}}">

      <div class="container-custom-padding animated fadeIn">

        <form class="form-horizontal bordered-row" (ngSubmit)="f.form.valid && submit()" #f="ngForm"
              id="company_location_form" novalidate>

          <div class="form-group row mx-0">

            <!--region Basic Details-->
            <div class="col-xs-12 col-sm-12 col-md-6 card-custom-padding">
              <div class="card">
                <div class="card-header">
                  {{ getHeadingDictionaryKey() | translate}}
                </div>
                <div class="card-body">

                  <!--region CompanyName-->
                  <div class="form-group row" [ngClass]="{ 'has-danger': hasLocalFieldError(name) }">
                    <label class="col-form-label form-control-label col-md-4 detail-title" [class.required-field-label]="componentState.isEditable()">
                      {{'COMPANY_LOCATION_NAME' | translate}}
                    </label>
                    <div class="col-md-8">
                      <input *ngIf="componentState.isEditable()"
                             type="text" class="form-control"
                             maxlength="{{UiConstants.maximumVarcharLength}}"
                             placeholder="{{'COMPANY_LOCATION_NAME' | translate}}"
                             [(ngModel)]="editModel.name"
                             [ngClass]="{ 'form-control-danger': hasLocalFieldError(name) }"
                             name="name"
                             id="name"
                             #name="ngModel"
                             required>
                      <div class="form-control-feedback" *ngIf="hasLocalFieldError(name)">
                        {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                      </div>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.name}}
                      </label>
                    </div>
                  </div>
                  <!--endregion CompanyName-->

                  <!--region ExternalId-->
                  <div class="form-group row"
                       [ngClass]="{ 'has-danger': hasLocalFieldError(externalId) || fieldErrors.external_id}">
                    <label class="col-form-label form-control-label col-md-4 detail-title" [class.required-field-label]="componentState.isEditView()">
                      {{'COMMON_EXTERNAL_ID' | translate}}
                    </label>
                    <div class="col-md-8">
                      <input *ngIf="componentState.isEditable()"
                             type="text" class="form-control"
                             maxlength="{{UiConstants.maximumVarcharLength}}"
                             placeholder="{{'COMMON_EXTERNAL_ID' | translate}}"
                             [(ngModel)]="editModel.externalId"
                             (ngModelChange)="removeFieldError(fieldErrors.external_id)"
                             [ngClass]="{ 'form-control-danger': hasLocalFieldError(externalId) || fieldErrors.external_id}"
                             name="externalId"
                             id="externalId"
                             #externalId="ngModel"
                             [required]="componentState.isEditView()">
                      <div class="form-control-feedback" *ngIf="fieldErrors.external_id">
                        {{fieldErrors.external_id.text}}
                      </div>
                      <div class="form-control-feedback" *ngIf="hasLocalFieldError(externalId)">
                        {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                      </div>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.externalId}}
                      </label>
                    </div>
                  </div>
                  <!--endregion ExternalId-->

                  <!--region GateCrossingRequired-->
                  <div class="form-group row">
                    <label
                      class="col-form-label form-control-label col-md-4 detail-title">{{'COMPANY_LOCATION_GATE_CROSSING_REQUIRED' |
                      translate}}</label>
                    <div class="col-md-8">
                      <label class="switch switch-text switch-info mb-0" *ngIf="componentState.isEditable()">
                        <input type="checkbox" class="switch-input"
                               [ngModelOptions]="{standalone: true}"
                               [disabled]="componentState.isReadonly()"
                               [(ngModel)]="componentState.isReadonly() ? detailModel.gateCrossingRequired : editModel.gateCrossingRequired">
                        <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                        <span class="switch-handle"></span>
                      </label>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.gateCrossingRequired ? ('COMMON_YES' | translate) : ('COMMON_NO' | translate)}}
                      </label>
                    </div>
                  </div>
                  <!--endregion GateCrossingRequired-->

                  <!-- region Company -->
                  <div class="form-group row mb-0"
                       [ngClass]="{ 'has-danger': (f.submitted && editModel.company.length === 0)}">
                    <label
                      class="col-form-label form-control-label col-md-4 detail-title" [class.required-field-label]="componentState.isEditable()">
                      {{'COMMON_COMPANY'|translate}}
                    </label>
                    <div class="col-md-8">
                      <angular2-multiselect
                        *ngIf="componentState.isEditable()"
                        [(ngModel)]="editModel.company"
                        [data]="companies"
                        [settings]="dropdownSettings"
                        name="company"
                        id="company"
                        #company="ngModel">
                      </angular2-multiselect>
                      <label *ngIf="componentState.isDetailView()"
                             [ngClass]="{ 'px-0': componentState.isEditable()}"
                             class="form-control detail-description" readonly>{{detailModel.companyName}}
                      </label>
                      <div class="form-control-feedback" *ngIf="(f.submitted && editModel.company.length === 0)">
                        {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                      </div>
                    </div>
                  </div>
                  <!-- endregion Company -->
                </div>
              </div>
            </div>
            <!-- endregion Basic Details-->

            <!--region Postal address card-->
            <div class="col-xs-12 col-sm-12 col-md-6 card-custom-padding">

              <div class="card">
                <div class="card-header" [class.required-field-label]="componentState.isEditable()">
                  {{ 'COMMON_LOCATION_POSTAL_ADDRESS' | translate}}
                </div>

                <div class="card-body" *ngIf="componentState.isEditable() && editModel.postalAddress">

                  <!-- region Postal address complex data -->
                  <div>
                    <app-postal-address-complex
                      id="test_portal_address_component"
                      [form]="f"
                      [model]="editModel.postalAddress"
                      [readonly]="componentState.isReadonly()">
                    </app-postal-address-complex>
                  </div>
                  <!-- endregion Postal address complex data -->

                </div>
                <!-- /.card-body -->

                <!--Appears in detailView-->
                <div *ngIf="componentState.isReadonly()" class="card-body">
                  <label class="form-control detail-description pl-0 mb-1"
                         readonly>{{detailModel.postalAddress}}</label>

                  <!--region Geocode status-->
                  <div class="form-group row" *ngIf="componentState.isReadonly()">
                    <label class="col-form-label form-control-label col-md-4 detail-title">{{'TASK_GEOCODING_STATUS' |
                      translate}}</label>
                    <div class="col-md-8">
                      <!--Appears in detailView-->
                      <label class="form-control detail-description"
                             readonly>{{detailModel.geocodeStatus | translate}}</label>
                    </div>
                  </div>
                  <!--endregion Geocode status-->


                  <ng-container *ngIf="detailModel.hasCoordinates()">

                    <label class="inner-card-title"
                           *ngIf="componentState.isReadonly()">{{'TASK_GEOCODING_COORDINATES' | translate}}</label>

                    <!--region Longitude-->
                    <div class="form-group row" *ngIf="componentState.isReadonly()">
                      <label class="col-form-label form-control-label col-md-4 detail-title">{{'TASK_GEOCODING_LON' |
                        translate}}</label>
                      <div class="col-md-8">
                        <!--Appears in detailView-->
                        <label class="form-control detail-description" readonly>{{detailModel.placeLongitude}}</label>
                      </div>
                    </div>
                    <!--endregion Longitude-->

                    <!--region Latitude-->
                    <div class="form-group row" *ngIf="componentState.isReadonly()">
                      <label class="col-form-label form-control-label col-md-4 detail-title">{{'TASK_GEOCODING_LAT' |
                        translate}}</label>
                      <div class="col-md-8">
                        <!--Appears in detailView-->
                        <label class="form-control detail-description" readonly>{{detailModel.placeLatitude}}</label>
                      </div>
                    </div>
                    <!--endregion Longitude-->

                  </ng-container>
                </div>

              </div>
            </div>
            <!--endregion Postal address card-->

          </div>
          <!-- /.form-group row -->

          <!--Only visible on create/edit views-->
          <div class="col-md-12 d-flex justify-content-end p-0 pl-1" *ngIf="componentState.isEditable()">
            <div>
              <input type="submit" class="btn btn-primary"
                     value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
            </div>
          </div>

        </form>

      </div>
    </responsive-tab>
    <responsive-tab *ngIf="rightModel.companyLocationHistoryLog.hasRight() && componentState.isDetailView()"
           heading="{{'COMMON_HISTORY'|translate}}">
      <app-history-log
        [queryModel]="historyQueryModel"
        [Type]="CompanyLocation"
        [historyList]="historyList"
        (openHistoryDetail)="historyDetailModal.openDetailDialog($event)"
        (itemsPerPageChangedOut)="itemsPerPageChanged($event)"
        (orderByOut)="orderBy($event)"
        (pageChangedOut)="pageChanged($event)"
        #historyComponent>
      </app-history-log>
    </responsive-tab>
  </responsive-tabset>
</div>

<app-history-detail-dialog
  #historyDetailModal>
</app-history-detail-dialog>
