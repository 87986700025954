<div class="container" *ngIf="unsupported">
  <img src="./../../../assets/img/not-supported-screen-size.svg"/>
  <div class="mt-2">
    <h3 class="text-center">{{'NOT_SUPPORTED_DEVICE_INFO' | translate}}</h3>
  </div>
  <div class="mt-2 d-flex justify-content-center">
    <button type="button" class="btn btn-primary" (click)="back()">
      {{'COMMON_BUTTON_BACK' | translate}}
    </button>
  </div>
</div>
