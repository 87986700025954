/* eslint-disable */
import { Injectable } from '@angular/core';
import { Set } from 'immutable';
import { EmptyMessage, } from './util/messages';
import { UIRouter } from '@uirouter/angular';
import { PermissionDefinition } from '../app.permission-definitions';
import { Arrays } from './util/arrays';
import { Observable } from 'rxjs';
import { StateName } from '../app.state-names';
import { BaseHttpService, ResourceHelper } from './util/http-services';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { AuthTokenStorage, LocalStorages, LoginRequiredReason, LoginRequiredReasonStorage } from './util/storages';
import { globalCacheBusterNotifier } from 'ts-cacheable';
import { RightDefinition } from '../app.right-definitions';
import { DataTypeObject } from '../util/core-utils';
import {AppType} from "./util/app-type-helper.service";
/* eslint-enable */

export interface AuthResult {
  user_profile?: UserProfile;
  granted_permissions?: Array<PermissionDefinition>;
  granted_rights?: Array<RightDefinition>;
}

export class Right {

  public static of(permission: PermissionDefinition): Right {
    return new Right(Set.of(permission), 'SINGLE');
  }

  public static each(...permissions: PermissionDefinition[]): Right {
    return new Right(Set.of(...permissions), 'OR');
  }

  public static all(...permissions: PermissionDefinition[]): Right {
    return new Right(Set.of(...permissions), 'AND');
  }

  private constructor(private permissions: Set<PermissionDefinition>, private rightType: RightType) {
  }

  public hasRight(grantedPermissions: Set<PermissionDefinition> | null | undefined): boolean {
    if (!grantedPermissions) {
      return false;
    }
    return this.filter(grantedPermissions).size > 0;
  }

  public filter(l: Set<PermissionDefinition>): Set<PermissionDefinition> {
    switch (this.rightType) {
      case 'SINGLE':
        return this.singleFilter(l);
      case 'AND':
        return this.andFilter(l);
      case 'OR':
        return this.orFilter(l);
      default:
        return Set.of<PermissionDefinition>();
    }
  }

  // SINGLE A -> A,B,C = A
  // SINGLE A -> B,C = 0
  private singleFilter(s: Set<PermissionDefinition>): Set<PermissionDefinition> {
    const sl = s.toArray();
    const pl = this.permissions.toArray();
    if (sl.indexOf(pl[0]) !== -1) {
      return Set.of(pl[0]);
    }
    return Set.of<PermissionDefinition>();
  }

  // AND A,B -> A,B,C = A,B
  // AND A,B -> A,C = 0
  private andFilter(s: Set<PermissionDefinition>): Set<PermissionDefinition> {
    const sl = s.toArray();
    const pl = this.permissions.toArray();
    let containsAll = true;
    Arrays.iterateByIndex(pl, (p) => {
      if (sl.indexOf(p) === -1) {
        containsAll = false;
      }
    });
    if (containsAll) {
      return this.permissions;
    }
    return Set.of<PermissionDefinition>();
  }

  // OR A,B -> A,C = A
  // OR A,B -> A,B,C = A,B
  // OR A -> B = 0
  private orFilter(s: Set<PermissionDefinition>): Set<PermissionDefinition> {
    const sl = s.toArray();
    const pl = this.permissions.toArray();
    const m: PermissionDefinition[] = [];
    Arrays.iterateByIndex(pl, (p) => {
      if (sl.indexOf(p) !== -1) {
        m.push(p);
      }
    });
    return Set.of(...m);
  }

}

export type RightType = 'SINGLE' | 'OR' | 'AND';

export interface UserProfile {
  id: number;
}

export type UserProfileType =
  'USER' |
  'LDAP' ;

export const userProfileTypes: DataTypeObject<UserProfileType>[] = [
  {id: 'USER', stringKey: 'USER_PROFILE_TYPE_USER', iconClass: 'icomoon-user-local'},
  {id: 'LDAP', stringKey: 'USER_PROFILE_TYPE_LDAP', iconClass: 'icomoon-user-ldap'},
];

export interface LoginByCredentialRequest {
  user_name: string;
  password: string;
  application_type: AppType;
}

@Injectable()
export class AuthService extends BaseHttpService {

  check(request: EmptyMessage): Observable<AuthResult> {
    return this.http.get<AuthResult>(this.url, this.parseParams(request))
      .pipe(tap(res => {
      if (!res || !res.user_profile) {
        globalCacheBusterNotifier.next();
        if (this.uiRouter.stateService.current.name !== StateName.LOGIN
          && this.uiRouter.stateService.current.name !== StateName.HELPDESK_LOGIN) {
          LoginRequiredReasonStorage.getInstance().setReason(LoginRequiredReason.INVALID_ACCESS_TOKEN, {
            stateName: this.uiRouter.stateService.$current.name,
            params: this.uiRouter.stateService.params
          });
          LocalStorages.onLogout();
          this.uiRouter.stateService.go(StateName.getLoginStateName(this.uiRouter.stateService.$current.name));
        }
      }
    }));
  }

  userLogin(request: LoginByCredentialRequest): Observable<AuthResult> {
    return this.http.post<AuthResult>(this.url + `user?application_type=${request.application_type}`, request);
  }

  localUserLogin(request: LoginByCredentialRequest): Observable<AuthResult> {
    return this.http.post<AuthResult>(this.url + `user-local?application_type=${request.application_type}`, request);
  }

  ldapUserLogin(request: LoginByCredentialRequest): Observable<AuthResult> {
    return this.http.post<AuthResult>(this.url + `user-ldap?application_type=${request.application_type}`, request);
  }

  logout(request: EmptyMessage): Observable<EmptyMessage> {
    return this.http.delete<EmptyMessage>(this.url);
  }

  constructor(private http: HttpClient, private uiRouter: UIRouter, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/auth/');
  }

}
