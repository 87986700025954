/* eslint-disable */
import { Injectable } from '@angular/core';
import { MasterDataService } from '../masterdata/master-data.service';
import { Form } from './form.service';
import { SurveyService } from '../survey/survey.service';
import { FormTableService } from './form-table.service';
import { TaskService } from '../task/task.service';
import { CustomerService } from '../customer/customer.service';
import { LegacyWorkflowTaskService } from '../legacy-workflow/legacy-workflow-tasks/legacy-workflow-task.service';
import { ProjectService } from '../project/project.service';

/* eslint-enable */

@Injectable()
export class FormServiceFactory {

  constructor(private masterDataService: MasterDataService,
              private surveyService: SurveyService,
              private formTableService: FormTableService,
              private taskService: TaskService,
              private customerService: CustomerService,
              private legacyWorkflowTaskService: LegacyWorkflowTaskService,
              private projectService: ProjectService) {
  }

  public createService(serviceCode: FormServiceCode): Form.Service {
    switch (serviceCode) {
      case FormServiceCode.MASTER_DATA:
        return this.masterDataService;
      case FormServiceCode.SURVEY:
        return this.surveyService;
      case FormServiceCode.FORM_TABLE:
        return this.formTableService;
      case FormServiceCode.TASK:
        return this.taskService;
      case FormServiceCode.CUSTOMER:
        return this.customerService;
      case FormServiceCode.WORKFLOW_TASK:
        return this.legacyWorkflowTaskService;
      case FormServiceCode.PROJECT:
        return this.projectService;
      default:
        throw new Error('Unhandled service code');
    }
  }

}

export enum FormServiceCode {
  TASK,
  MASTER_DATA,
  SURVEY,
  FORM_TABLE,
  CUSTOMER,
  WORKFLOW_TASK,
  PROJECT
}
