/* eslint-disable */
import { MultiselectOptionItem, MultiselectOptionItemWithData, OptionItem } from './core-utils';
import { FieldError } from '../lib/util/errors';
import { Arrays } from '../lib/util/arrays';
import { DisabledItem } from './search-utils';
import { UserGroupItemForDropdown } from './user-utils';
/* eslint-enable */

export class DocumentGroupSearchModel {
  id: number | null = null;
  name: string = '';
  code: string = '';
  parent_id?: number = undefined;
  user_group_id?: number = undefined;
  creation_time: string = '';
  update_time: string = '';
  disabled: DisabledItem | null = null;
  languageCode?: LanguageCodeItem = undefined;

  public isEmpty(): boolean {
    return this.id === null
      && this.name.length === 0
      && this.code.length === 0
      && this.languageCode === undefined
      && (this.disabled ? this.disabled.id === DisabledItem.active().id : true);
  }
}

export interface DocumentGroupFieldErrorMap {
  code?: FieldError;
  name?: FieldError;
  parent_id?: FieldError;
}

export class DocumentGroupEditModel {
  id?: number;
  name: string = '';
  code: string = '';
  language_code: string = '';
  mainGroup: boolean = false;
  parent: MultiselectOptionItemWithData<number>[] = [];
  children: MultiselectOptionItemWithData<number>[] = [];
  user_groups: UserGroupItemForDropdown[] = [];

  public createUserGroupIds(): number[] {
    const ids: number[] = [];
    Arrays.iterateByIndex(this.user_groups, (user_group, index) => {
      const id = user_group.id;
      if (id) {
        ids[index] = id;
      }
    });
    return ids;
  }
}

export class LanguageCodeItem extends OptionItem<string> {

}
