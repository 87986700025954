/* eslint-disable */
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ApplicationDictionaryService, StringItem } from '../../../lib/application-dictionary.service';
import { AuthService } from '../../../lib/auth.service';
import { Arrays } from '../../../lib/util/arrays';
import { ApplicationType } from '../../../lib/root-core.service';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { TranslateService } from '@ngx-translate/core';
import { StateName } from '../../../app.state-names';
import { StringKey } from '../../../app.string-keys';
import { RightModel } from '../../../app.rights';
import { RightResolver, RightService } from '../../../lib/right.service';
import { UiConstants } from '../../../util/core-utils';
/* eslint-enable */

@Component({
  selector: 'app-application-dictionary-list',
  templateUrl: 'application-dictionary-list.component.html',
  styleUrls: ['application-dictionary-list.component.scss']
})
export class ApplicationDictionaryListComponent implements OnInit, AfterViewInit {
  UiConstants = UiConstants;

  model: Model = new Model();
  applicationTypeKeys: string[] = [];
  stringKeys: string[] = [];
  languageCodes: string[] = [];
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  rightModel: RightModel = RightModel.empty();


  private editItem: EditableStringItem | null = null;

  get editItemValueText(): string {
    if (this.editItem && this.editItem.value && this.editItem.value.text) {
      return this.editItem.value.text;
    }
    return '';
  }

  get editItemNoteText(): string {
    if (this.editItem && this.editItem.note && this.editItem.note.text) {
      return this.editItem.note.text;
    }
    return '';
  }

  set editItemValueText(text: string) {
    if (this.editItem) {
      this.editItem.value.text = text;
    }
  }

  set editItemNoteText(text: string) {
    if (this.editItem) {
      this.editItem.note.text = text;
    }
  }

  constructor(
    private authService: AuthService,
    private translateService: TranslateService,
    private dictionaryService: ApplicationDictionaryService,
    private rightService: RightService) {
  }

  ngOnInit() {
    this.loadRightModels();
    this.translateService.get(StringKey.COMMON_DICTIONARY).subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get(StringKey.MENU_NAVBAR_MENU_ADMINISTRATION).subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.loadApplicationTypesThenLanguagesThenKeysAndDictionary();
  }

  ngAfterViewInit(): void {
  }


  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  onItemClick(item: EditableStringItem, text: EditableString) {
    text.editing = true;
  }

  onItemEnter(item: EditableStringItem, text: EditableString) {
    this.dictionaryService.setString({
      applicationTypeKey: this.model.appKey,
      languageCode: this.model.languageCode!,
      key: item.key,
      value: item.value.text,
      note: item.note.text
    }).subscribe(
      (result: any) => {
        text.originalText = text.text;
        text.editing = false;
      },
      (error: any) => {
        text.text = text.originalText;
        text.editing = false;
      }
    );
  }

  onItemEscape(item: EditableStringItem, text: EditableString) {
    text.text = text.originalText;
    text.editing = false;
  }

  openEditDialog(editDialog: any, item: EditableStringItem) {
    this.editItem = item;
    editDialog.show();
  }

  closeEditDialog(editDialog: any) {
    const item = this.editItem!;
    this.dictionaryService.setString({
      applicationTypeKey: this.model.appKey,
      languageCode: this.model.languageCode!,
      key: item.key,
      value: item.value.text,
      note: item.note.text
    }).subscribe(
      (result: any) => {
        item.value.originalText = item.value.text;
        item.note.originalText = item.note.text;
      },
      (error: any) => {
        item.value.text = item.value.originalText;
        item.note.text = item.note.originalText;
    });
    editDialog.hide();
  }

  cancelEditDialog(editDialog: any) {
    const item = this.editItem!;
    item.value.text = item.value.originalText;
    item.note.text = item.note.originalText;
    editDialog.hide();
  }

  onAppKeyChanged() {
    this.loadLanguagesThenKeysAndDictionary();
  }

  onLanguageCodeChanged() {
    this.loadKeysAndDictionary();
  }

  private loadApplicationTypesThenLanguagesThenKeysAndDictionary() {
    const appTypeKeys: string[] = [];
    this.dictionaryService.getApplicationTypes({}).subscribe(
      (result: ApplicationType[]) => {
        Arrays.iterateByIndex(result, (applicationType) => {
          appTypeKeys.push(applicationType.key);
        });
        this.applicationTypeKeys = appTypeKeys;
        if (appTypeKeys.length > 0) {
          this.model.appKey = appTypeKeys[0];
          this.loadLanguagesThenKeysAndDictionary();
        }
      }
    );
  }

  private loadLanguagesThenKeysAndDictionary() {
    this.dictionaryService.getLanguageCodes({
      applicationTypeKey: this.model.appKey
    }).subscribe(
      (result: string[]) => {
        this.languageCodes = result;
        this.model.languageCode = this.languageCodes[0];
        this.loadKeysAndDictionary();
      }
    );
  }

  private loadKeysAndDictionary() {
    this.dictionaryService.getStringKeys({
      applicationTypeKey: this.model.appKey
    }).subscribe(
      (result: string[]) => {
        this.stringKeys = result;
        this.loadDictionary();
      }
    );
  }

  private loadDictionary() {
    this.dictionaryService.getDictionary({
      applicationTypeKey: this.model.appKey!,
      languageCode: this.model.languageCode!
    }).subscribe(
      (result: StringItem[]) => {
        const managedKeys: string[] = [];
        const editables: EditableStringItem[] = [];

        Arrays.iterateByIndex(result, (item: StringItem) => {
          const valueText = new EditableString();
          valueText.text = item.value;
          valueText.originalText = item.value;
          valueText.editing = false;

          const noteText = new EditableString();
          noteText.text = item.note;
          noteText.originalText = item.note;
          noteText.editing = false;

          const editable = new EditableStringItem();
          editable.key = item.key;
          editable.value = valueText;
          editable.note = noteText;

          editables.push(editable);
          managedKeys.push(item.key);
        });

        Arrays.iterateByIndex(this.stringKeys, (key: string) => {
          if (Arrays.inArray(managedKeys, key)) {
            return;
          }

          const valueText = new EditableString();
          valueText.text = '';
          valueText.originalText = '';
          valueText.editing = false;

          const noteText = new EditableString();
          noteText.text = '';
          noteText.originalText = '';
          noteText.editing = false;

          const editable = new EditableStringItem();
          editable.key = key;
          editable.value = valueText;
          editable.note = noteText;

          editables.push(editable);
          managedKeys.push(key);
        });

        editables.sort(this.compareItemByKey);
        this.model.items = editables;
      }
    );
  }

  private compareItemByKey(l: EditableStringItem, r: EditableStringItem) {
    if (l.key < r.key) {
      return -1;
    }
    if (l.key > r.key) {
      return 1;
    }
    return 0;
  }

  getTextMaximumLength(): number {
    return UiConstants.maximumVarcharLength;
  }

}

class EditableStringItem {
  key: string;
  value: EditableString;
  note: EditableString;
}

class EditableString {
  text: string;
  originalText: string;
  editing: boolean;
}

class Model {
  appKey: string = '';
  items: EditableStringItem[] = [];
  languageCode: string | null = null;
}
