<div class="card">
  <div class="card-header">
    {{'SHIPMENT_DETAILS'|translate}}
  </div>

  <div class="card-body mb-0">
    <div class="form-horizontal">

        <div class="row">
          <div class="form-group col-md-4">
            <label readonly class="detail-title">{{'SHIPMENT_LIST_GROUP_DELIVERY_NOTE_NUMBER'|translate}}</label>
          </div>
          <div class="form-group col-md-8">
            <input type="text" class="form-control col-xl-8"
                   [value]="shipmentGroupModel.deliveryNoteNumber"
                   disabled>
          </div>
        </div>

      <div class="row">
        <div class="form-group col-md-4">
          <label readonly class="detail-title">{{'COMPANY_TYPE_DEMANDER'|translate}}</label>
        </div>
        <div class="form-group col-md-8">
          <input type="text" class="form-control col-xl-8"
                 [value]="shipmentGroupModel.demander"
                 disabled>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-4">
          <label readonly class="detail-title">{{'COMMON_CREATION_TIME'|translate}}</label>
        </div>
        <div class="form-group col-md-8">
          <input type="text" class="form-control col-xl-8"
                 [value]="shipmentGroupModel.creationTime.toUtcIsoString() | date:'short'"
                 disabled>
        </div>
      </div>

      <div class="row" *ngIf="shipmentGroupModel.stateObject">
        <div class="form-group col-md-4">
          <label readonly class="detail-title">{{'COMMON_STATE' | translate}}</label>
        </div>
        <div class="form-group ml-1">
          <app-table-badge
            [badgeStyle]="shipmentGroupModel.stateObject.badgeStyle"
            [iconClass]="shipmentGroupModel.stateObject.iconClass"
            [textKey]="shipmentGroupModel.stateObject.stringKey">
          </app-table-badge>
        </div>
      </div>

    </div>
  </div>
</div>
