<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0">
    <responsive-tabset class="fullscreen_tab">
      <responsive-tab heading="{{'TASK_RECORD_MESSAGE_LIST'|translate}}"
                      id="task"
                      *ngIf="rightModel.taskRecordChatRead.hasRight()">
        <app-task-record-message-list>
        </app-task-record-message-list>
      </responsive-tab>
      <responsive-tab heading="{{'PROCESS_MESSAGE_LIST'|translate}}"
                      id="process"
                      *ngIf="rightModel.processRead.hasRight()">
        <app-process-message-list>
        </app-process-message-list>
      </responsive-tab>
    </responsive-tabset>
  </div>
</div>
