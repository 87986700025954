import { Injectable } from '@angular/core';
import { MultiselectProvider } from '../multiselect-provider';
import { Observable, Observer } from 'rxjs';
import { MultiselectOptionItem, UiConstants } from '../../util/core-utils';
import { OrderType, QueryResult } from '../util/services';
import { Set } from 'immutable';
import { MasterDataRecord, MasterDataRecordService } from './master-data-record.service';

@Injectable()
export class MasterDataRecordMultiselectProvider implements MultiselectProvider {

  private multiselectQueryRequest: MasterDataRecord.GlobalQueryRequest = {
    paging: {
      pageNumber: 1,
      numberOfItems: UiConstants.autocompletePageSize
    },
    noProgressBar: true,
    fields: Set.of('id', 'name', 'external_id', 'disabled'),
    orders: Set.of({field: MasterDataRecord.OrderField.NAME, type: OrderType.ASC})
  };

  constructor(private masterDataRecordService: MasterDataRecordService) {
  }

  load(queryRequest: MasterDataRecord.GlobalQueryRequest): Observable<MultiselectOptionItem<number>[]> {
    return Observable.create((observer: Observer<MultiselectOptionItem<number>[]>) => {
      this.masterDataRecordService.globalQuery(queryRequest).subscribe((result: QueryResult<MasterDataRecord.MasterDataRecord>) => {
          observer.next(result.items.toArray().map(item => this.toMultiselectOptionItem(item!)));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }
  loadAll(predicate?: string): Observable<MultiselectOptionItem<number>[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.queryText = predicate;
    return this.load(queryRequest);
  }

  loadActive(predicate?: string): Observable<MultiselectOptionItem<number>[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.queryText = predicate;
    queryRequest.disabled = false;
    return this.load(queryRequest);
  }

  getById(id: number): Observable<MultiselectOptionItem<number>> {
    return Observable.create((observer: Observer<MultiselectOptionItem<number>>) => {
      this.masterDataRecordService.globalQuery({
        masterDataRecordIdSet: Set.of(id),
        paging: {
          numberOfItems: 1,
          pageNumber: 1
        }
      }).subscribe((result: QueryResult<MasterDataRecord.MasterDataRecord>) => {
          observer.next(this.toMultiselectOptionItem(result.items[0]!));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  getByIds(ids: number[]): Observable<MultiselectOptionItem<number>[]> {
    const queryRequest = Object.assign([], this.multiselectQueryRequest);
    queryRequest.masterDataRecordIdSet = Set.of(...ids);
    return this.load(queryRequest);
  }

  toMultiselectOptionItem(item: MasterDataRecord.MasterDataRecord): MultiselectOptionItem<number> {
    const ret = new MultiselectOptionItem<number>();
    ret.id = item.masterDataRecordId;
    ret.itemName = item.name ? item.name : item.externalId!;
    ret.itemSubtitle = item.name ? item.externalId : undefined;
    ret.disabled = item.disabled;
    return ret;
  }

}
