<div class="form-group">
  <label class="form-control-label text-break" >{{ model.title }}
    <i *ngIf="model.hint !== ''" class="icon icon-info popover-info-icon" title="{{ model.hint }}"></i>
  </label>
  <app-animated-checkbox
    [readonly]="nonEditable"
    [ngClass]="{ 'cursor-not-allowed': nonEditable}"
    [(selected)]="model.value"
    (clickEvent)="onFieldClick()"
    (selectedChanged)="onFieldChanged()">
  </app-animated-checkbox>
</div>
