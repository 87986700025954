<div class="form-group">
  <div class="d-inline">
    <label class="d-flex justify-content-between">
      {{expression.criteria.field!.title}}
      <i class="icomoon icomoon-exit align-self-center" *ngIf="!disableDelete" (click)="onDelete.emit(expression)"></i>
    </label>
    <label class="form-control">
      {{expression.criteria.operation!.stringKey | translate}}
    </label>
  </div>
</div>
