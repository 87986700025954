/* eslint-disable */
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FileItem, FileUploader, FileUploaderOptions, ParsedResponseHeaders } from 'ng2-file-upload';
import { FileUploaderUtil, FileUploadListener } from '../../../util/file-uploader-util';
import { UploadErrorLocalizer } from '../../../util/upload-error-localizer';
import { Strings } from '../../../lib/util/strings';
import { BaseHttpService, HeaderKeys, ResourceHelper } from '../../../lib/util/http-services';
import { FileSize, FileTypeModel, FileTypeModelFactory } from '../../../util/file-attachment.model';
import { UploadMimeType } from '../upload-mime-type';
import { PopoverDirective } from 'ngx-bootstrap/popover';

/* eslint-enable */

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit, FileUploadListener {
  UploadMimeType = UploadMimeType;

  _uploader: FileUploader;

  @Input()
  set uploader(uploader: FileUploader) {
    if (uploader) {
      this._uploader = uploader;
      if (Strings.contains(this.uploadPath, BaseHttpService.LOCATION_ON)) {
        this.tryAddLocationHeaders();
      }
    }
  }

  get uploader(): FileUploader {
    return this._uploader;
  }

  _uploadPath: string = '';

  @Input()
  set uploadPath(path: string) {
    this._uploadPath = path;
    this.createUploader();
    const options: FileUploaderOptions = this._uploader.options;
    options.url = this.createUrl(path);
    this._uploader.setOptions(options);
  }

  @Input()
  acceptedMimeType?: UploadMimeType;

  @Input()
  disabled: boolean = false;

  @Input()
  uploadOnDrop: boolean = true;

  @Input()
  hideQueue: boolean = false;

  @Output()
  onFileDropped: EventEmitter<File[]> = new EventEmitter();

  @Output()
  onFileRemoved: EventEmitter<void> = new EventEmitter();

  @Output()
  onResult: EventEmitter<boolean> = new EventEmitter();

  @Output()
  responseContent: EventEmitter<UploadResponse> = new EventEmitter();

  @ViewChild('errorPopoverContainer', {static: false})
  errorPopover: PopoverDirective;

  uploadGlobalErrors: string;
  uploadCollectionFieldErrors: string;
  importInProgress = false;
  visible: boolean = false;

  fileHovering: boolean = false;

  constructor(private fileUploaderUtil: FileUploaderUtil,
              private uploadErrorLocalizer: UploadErrorLocalizer,
              private resourceHelper: ResourceHelper) {

  }

  get uploadPath() {
    return this._uploadPath;
  }

  get file(): FileItem | undefined {
    return this._uploader && this._uploader.queue.length === 1
      ? this._uploader.queue[0]
      : undefined;
  }

  ngOnInit() {
    this.createUploader();
  }

  private createUploader() {
    if (!this.uploader) {
      this.uploader = this.fileUploaderUtil.createUploader(this.uploadPath, this);
    }
  }

  private createUrl(rawUrl: string): string {
    const baseUrl: string = this.resourceHelper.getBaseUrl();
    if (rawUrl.startsWith(baseUrl)) {
      return rawUrl;
    }
    return baseUrl + rawUrl;
  }

  private tryAddLocationHeaders() {
    if (window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(pos => {
        this._uploader.setOptions({
          headers: [
            {
              name: HeaderKeys.GPS_LATITUDE,
              value: pos.coords.latitude.toString()
            },
            {
              name: HeaderKeys.GPS_LONGITUDE,
              value: pos.coords.longitude.toString()
            }
          ]
        });
      });
    }
  }

  onBeforeUpload() {
    this.importInProgress = true;
  }

  onSuccess(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) {
    this.importInProgress = false;
    this.onResult.emit(true);
    this.responseContent.emit({
      response: response,
      status: status
    });
  }

  onUploadError(response: string, status: number) {
    this.importInProgress = false;
    this.responseContent.emit({
      response: response,
      status: status
    });
    const localizedError = this.uploadErrorLocalizer.localizeError(response);
    this.uploadGlobalErrors = localizedError.globalErrors;
    this.uploadCollectionFieldErrors = localizedError.collectionFieldErrors;
    this.errorPopover.show();
    this.onResult.emit(false);
  }

  onFileOver(event: any) {
    this.fileHovering = event;
  }

  onFileDrop(event: any) {
    if (!this.disabled) {
      this.onFileDropped.emit(event);
      if (this.uploadOnDrop) {
        this._uploader.uploadAll();
      }
    }
    else {
      this._uploader.clearQueue();
    }
  }

  getFileTypeModel(): FileTypeModel {
    return FileTypeModelFactory.createFileTypeFromContentType(this.file!.file.type);
  }

  getFileSizeModel(): FileSize {
    return FileTypeModelFactory.createFileSizeFromBytesBy10(this.file!.file.size);
  }

  clearQueue() {
    this._uploader.clearQueue();
    this.onFileRemoved.emit();
  }
}

export interface UploadResponse {
  response: string;
  status: number;
}
