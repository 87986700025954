/* eslint-disable */
import { Component } from '@angular/core';
import {
  FormRecordFieldContext,
  FormRecordFieldValueUpdateArgs,
  FormRecordFieldView,
  FormRecordFieldViewContext,
  FormRecordFieldViewModel,
  SetTmpReadonlyArgs,
  SetTmpReadonlyResult
} from '../../../../../util/form/form-utils';
import { Form } from '../../../../../lib/form/form.service';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
  FormRef,
  ForwardingFormRef,
  LocalFormGroupValidationErrors,
  ResourceQueryResult
} from '../../../../../lib/util/services';
import { Angular2Multiselects } from '../../../../../util/multiselect';
import { FieldActivationState, FieldActivationStateResolver } from '../../../../../util/form/form-editors';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from '../../../../../lib/settings.service';
import { Command } from '../../../../../util/command';
import { Models } from '../../../../../util/model-utils';
import { StringKey } from '../../../../../app.string-keys';
import { OwnerUserItem, UiConstants } from '../../../../../util/core-utils';
import { TranslateUtils } from '../../../../../util/translate';
import { FormRecord } from '../../../../../lib/form/form-record.service';
import { AppValidators } from '../../../../../util/app-validators';
import { List, Set } from 'immutable';
import { Strings } from '../../../../../lib/util/strings';
import { ofCondition } from '../../../../../util/wait';
import { FormRecordInactivityManager } from '../../manager/form-record-inactivity-manager';
import { Arrays } from '../../../../../lib/util/arrays';
import { UserGroup, UserGroupService } from '../../../../../lib/user-group.service';
import { User, UserService } from '../../../../../lib/user.service';
import { combineLatest, Observable, Observer, of as observableOf } from 'rxjs';
import { StateName } from '../../../../../app.state-names';
import { UIRouter } from '@uirouter/angular';

/* eslint-enable */

@Component({
  selector: 'app-form-record-user-field',
  templateUrl: './form-record-user-field.component.html',
  styleUrls: ['./form-record-user-field.component.scss']
})
export class FormRecordUserFieldComponent implements FormRecordFieldView {

  public readonly selector: Form.FieldDataTypeSelector.USER;

  private loaded = false;

  formGroup: FormGroup;

  model: Model = new Model();

  // context fields are always optional
  formRecordFieldContext?: FormRecordFieldContext;
  formRecordInactivityManager?: FormRecordInactivityManager;
  private fieldId?: number;
  private htmlForm?: FormRef;

  tmpReadonly: boolean = false;

  private optionalValue: boolean = false;

  userList: UserItem[] = [];
  defaultUserList: UserItem[] = [];

  private userGroupIds?: number[];

  dropdownSettings: Angular2Multiselects.Settings;

  private defText: string;
  private formGroupValidationErrors: LocalFormGroupValidationErrors;

  private readonlyFormFn: () => boolean = () => true;
  private readonlyFieldFn: () => boolean = () => false;
  private hiddenFieldFn: () => boolean = () => false;
get nonEditable(): boolean {
    return FieldActivationStateResolver.isNonEditable(
      this.fieldActivationState,
    );
  }

  get requiredDisabled(): boolean {
    return FieldActivationStateResolver.isRequiredDisabled(
      this.fieldActivationState,
    );
  }

  get required(): boolean {
    const optional = this.optionalValue;
    const requiredDisabled = this.requiredDisabled;
    return !optional && !requiredDisabled;
  }


  private get fieldActivationState(): FieldActivationState {
    return FieldActivationStateResolver.resolveFieldActivationState({
      readonlyFormFn: () => this.readonlyFormFn(),
      readonlyFieldFn: () => this.readonlyFieldFn(),
      tmpReadonlyFieldFn: () => this.tmpReadonly,
    });
  }

  private get canApplyDefaultValue(): boolean {
    return FieldActivationStateResolver.canApplyDefaultValue({
      formRecordFieldContext: () => {
        return this.formRecordFieldContext;
      },
      fieldActivationState: () => {
        return this.fieldActivationState;
      },
    });
  }

  private get valueFormControl(): AbstractControl {
    return this.formGroup.get('value')!;
  }

  private get reqContext() {
    return this.formRecordFieldContext!;
  }

  private get reqAttrs(): Form.FieldDataTypeUserAttributes {
    return this.formRecordFieldContext!.field.dataType.userAttributes!;
  }

  private get reqDefaultValue(): UserItem[] {
    return []; // for now it has no default value
  }

  constructor(fb: FormBuilder,
              private translateService: TranslateService,
              private settingsService: SettingsService,
              private userService: UserService,
              private uiRouter: UIRouter) {
    this.formGroup = this.createFormGroup(fb);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(
      this.createForwardingHtmlForm(),
      this.formGroup,
    );
    this.initDefText();
  }

  setTmpReadonly(args: SetTmpReadonlyArgs): Command<SetTmpReadonlyResult> {
    const userItemToIdText = (item: UserItem) => {
      return item.id === null ? null : item.id.toString();
    };
    let previousTmpReadonly;
    let previousValues;
    return {
      execute: async () => {
        await ofCondition({
          condition: () => {
            return this.loaded;
          }
        });
        previousTmpReadonly = this.tmpReadonly;
        previousValues = List.of(...this.model.values).toArray();
        const inactiveValue: UserItem[] = [];
        const defaultValue: UserItem[] = this.reqDefaultValue;
        let changed = false;
        if (this.tmpReadonly !== args.tmpReadonly) {
          if (args.tmpReadonly) {
            changed = FieldActivationStateResolver.inactivationChangesTheValue({
              debugId: this.reqContext.field.title,
              valueIsEmpty: Arrays.equals(this.model.values, inactiveValue, userItemToIdText),
              valueEqualsDefaultValue: Arrays.equals(this.model.values, defaultValue, userItemToIdText),
              defaultValueIsEmpty: Arrays.equals(defaultValue, inactiveValue, userItemToIdText),
              canApplyDefaultValue: this.canApplyDefaultValue
            });
            this.model.values = inactiveValue;
            this.tmpReadonly = args.tmpReadonly; // last
          }
          else {
            this.tmpReadonly = args.tmpReadonly; // first
            this.applyDefaultValue();
          }
          this.valueFormControl.updateValueAndValidity();
        }
        return {
          changed: changed
        };
      },
      undo: async () => {
        await ofCondition({
          condition: () => {
            return this.loaded;
          }
        });
        this.tmpReadonly = previousTmpReadonly;
        this.model.values = List.of(...previousValues).toArray();
        this.valueFormControl.updateValueAndValidity();
      }
    };
  }

  registerField(context: FormRecordFieldContext, originalModel?: any): any {
    if (originalModel) {
      this.model = originalModel;
    }
    this.formRecordFieldContext = context;
    this.fieldId = context.field.fieldId;
    this.htmlForm = context.htmlForm;
    this.readonlyFormFn = context.readonly;
    this.hiddenFieldFn = () => Form.FormFieldValidationType.HIDDEN === context.validationType;
    this.readonlyFieldFn = () => Form.FormFieldValidationType.READONLY === context.validationType
    || Form.FormFieldValidationType.HIDDEN === context.validationType;
    const attrs = context.field.dataType.userAttributes!;
    this.optionalValue = Form.FormFieldValidationType.REQUIRED !== context.validationType;
    this.model.title = context.field.title;
    this.model.hint = Models.optToString(context.field.hint);
    this.model.placeholder = Models.optToString(attrs.hint);
    this.model.multiSelect = attrs.multiSelect;
    this.userGroupIds = attrs.userGroupIds;
    this.loadFieldData();
    return this.model;
  }

  private loadFieldData() {
    const context = this.formRecordFieldContext!;
    if (this.canApplyDefaultValue) {
      const defaultValue = []; // for now it has no default value
      this.setValuesBeforeLoad(Set.of<number>(...defaultValue));
    }
    if (context.fieldRecord) {
      const dataAttrs = context.fieldRecord.data.userAttributes!;
      this.setValuesBeforeLoad(dataAttrs.values);
    }
    this.loadUserGroup(this.reqContext.field.formFieldWidthPercent);
  }

  private setValuesBeforeLoad(ids: Set<number>) {
    this.model.prevValueIds = ids;
  }

  private applyDefaultValue() {
    if (this.canApplyDefaultValue) {
      this.model.values = this.defaultUserList;
    }
  }

  onManualChange() {
    this.formRecordInactivityManager!.onGeneralFieldChangedByUser(this);
  }

  registerFieldViews(context: FormRecordFieldViewContext): void {
    this.formRecordInactivityManager = context.inactivityManager;
  }

  initDropDown(isSearchable: boolean, widthPercent: number) {
    this.translateService.get([
      StringKey.COMMON_PLACEHOLDER_SEARCH,
      StringKey.COMMON_PLACEHOLDER_EMPTY_ITEM_LIST_ARG,
      StringKey.COMMON_PLACEHOLDER_ITEM_NOT_SELECTED_YET_ARG,
      StringKey.COMMON_PLACEHOLDER_SELECT_ALL_VISIBLE_ITEM_ARG,
      StringKey.COMMON_USER
    ]).subscribe((o) => {
      this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
        .singleSelection(!this.model.multiSelect)
        .text(this.model.placeholder ? this.model.placeholder : this.defText)
        .enableSearchFilter(isSearchable)
        .enableCheckAll(true)
        .badgeShowLimit(UiConstants.calculateMultiselectMaxBadge(widthPercent))
        .noDataLabel(TranslateUtils.replaceArguments(
          TranslateUtils.extractValueFromObject(o, StringKey.COMMON_PLACEHOLDER_EMPTY_ITEM_LIST_ARG), {
            item: TranslateUtils.extractValueFromObject(o, StringKey.COMMON_USER)
          }))
        .selectAllText(TranslateUtils.replaceArguments(
          TranslateUtils.extractValueFromObject(o, StringKey.COMMON_PLACEHOLDER_SELECT_ALL_VISIBLE_ITEM_ARG), {
            item: TranslateUtils.extractValueFromObject(o, StringKey.COMMON_USER)
          }))
        .unSelectAllText(TranslateUtils.replaceArguments(
          TranslateUtils.extractValueFromObject(o, StringKey.COMMON_PLACEHOLDER_SELECT_ALL_VISIBLE_ITEM_ARG), {
            item: TranslateUtils.extractValueFromObject(o, StringKey.COMMON_USER)
          }))
        .searchPlaceholderText(TranslateUtils.extractValueFromObject(o, StringKey.COMMON_PLACEHOLDER_SEARCH))
        .remoteSearch(true)
        .build();
    });
  }

  initDefText() {
    this.translateService.get(StringKey.COMMON_VALUE_UNSELECTED).subscribe(
      (text: string) => {
        this.defText = text;
      },
    );
  }

  registerFieldData(fieldRecord: FormRecord.FieldComposed): void {
    const attrs = fieldRecord.data.userAttributes!;
    if (fieldRecord) {
      if (attrs.values) {
        if (this.readonlyFieldFn() && this.reqContext.cloning()) {
          // Do not use read-only data for cloning.
          // Note that readonly field is filtered from the service request.
          this.model.prevValueIds = undefined;
        }
        else {
          this.model.prevValueIds = attrs.values;
        }
      }
      else {
        this.model.prevValueIds = undefined;
      }
    }
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  validateWithInterrupt(): boolean {
    return false;
  }

  shouldNotifyAfterCreation(): boolean {
    return false;
  }

  afterFormRecordCreation(formRecordId: number): Observable<any> | undefined {
    return undefined;
  }

  createModel(): FormRecordFieldViewModel {
    if (this.fieldId === undefined) {
      throw new Error('Field ID is undefined');
    }
    const attrs: FormRecord.FieldDataUserAttributes = {
      values: this.model.getValueIdSet(),
    };
    return {
      fieldEditRequest: {
        fieldId: this.fieldId,
        data: {
          userAttributes: attrs,
        },
      },
    };
  }

  private createForwardingHtmlForm() {
    return new ForwardingFormRef({
      formFn: () => {
        return this.htmlForm;
      },
    });
  }

  private createFormGroup(fb: FormBuilder) {
    return fb.group(
      {
        value: fb.control(
          {value: this.model.values},
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => {
                return !this.required;
              },
            }),
            AppValidators.tempValidator({
              validator: AppValidators.maxArraySize({
                array: () => {
                  return this.model.values;
                },
                maxSize: 1
              }),
              disabled: () => {
                const requiredDisabled = this.requiredDisabled;
                return this.model.multiSelect || requiredDisabled;
              },
            }),
            AppValidators.validateEnabledItems
          ],
        ),
      },
    );
  }

  private loadUserGroup(widthPercent: number) {
    this.model.values = [];
    this.getUserObservable(false).subscribe(result => {
        const items = result.items;
        this.processUsers(items, true);
        this.initDropDown(true, widthPercent);
        this.valueFormControl.updateValueAndValidity({
          onlySelf: false,
          emitEvent: true
        });
      });
  }

  getUserObservable(includeDisabled: boolean, q?: string, ids?: number[]):
    Observable<ResourceQueryResult<User>> {
    if (this.readonlyFormFn() && this.model.prevValueIds && this.model.prevValueIds.size > 0 || !this.readonlyFormFn()) {
      const idSet = this.readonlyFormFn() ? this.model.prevValueIds : ids ? Set.of(...ids) : undefined;
      return this.userService.query({
        disabled: includeDisabled ? undefined : false,
        user_group_ids: this.userGroupIds ? this.userGroupIds.join() : undefined,
        person_name: q,
        fields: Set.of('id', 'person_name', 'disabled').join(),
        id: idSet ? idSet.join() : undefined,
        number_of_items: 100,
        page_number: 1,
        no_progress_bar: true
      });
    }
    else {
      return Observable.create((observer: Observer<ResourceQueryResult<User>>) => {
        observer.next({
            items: [],
          pagingResult:
            {
              currentNumberOfItems: 0,
              numberOfPages: 0,
              totalNumberOfItems: 0
            },
          otherHeaders: new Map<string, string>()
        });
      });
    }
  }

  loadUsers(q?: string) {
    this.getUserObservable(false, q ? Strings.undefinedOrNonEmpty(q) : undefined)
      .subscribe((result: ResourceQueryResult<User>) => {
        this.processUsers(result.items, false);
      });
  }

  processUsers(items: User[], firstLoad: boolean) {
    this.userList = [];
    this.defaultUserList = [];
    items.forEach(item => {
      if (item) {
        const i = {
          id: item.id,
          itemName: item.person_name,
          disabled: item.disabled
        };
        if (!i.disabled) {
          this.userList.push(i);
        }
        // NOTE: for now it has no default value, so defaultUserList is empty
        if (this.model.prevValueIds
          && this.model.prevValueIds.contains(i.id)
          && !this.model.values.map(u => u.id).includes(i.id)) {
          if (firstLoad) {
            this.model.values.push(i);
          }
        }
      }
    });
    if (firstLoad && this.model.prevValueIds) {
      const notFoundIds: number[] = this.model.prevValueIds.filter((id) => {
        return this.model.values.findIndex((value) => value.id === id) < 0;
      }).toArray();
      // load missing, previously selected users, that are not in the top 100
      if (notFoundIds.length > 0) {
        this.getUserObservable(true, undefined, notFoundIds)
          .subscribe((result: ResourceQueryResult<User>) => {
            result.items.forEach((item) => {
              if (item) {
                const i = {
                  id: item.id,
                  itemName: item.person_name,
                  disabled: item.disabled
                };
                this.model.values.push(i);
                this.formGroup.controls['value'].updateValueAndValidity();
              }
            });
          });
      }
    }
    this.loaded = true;
  }

  updateValue(data: FormRecordFieldValueUpdateArgs) {
  }

  navigateToUserDetail(item: UserItem) {
    this.uiRouter.stateService.go(StateName.USER_DETAIL, {id: item.id});
  }

}

class UserItem {
  id: number | null = null;
  itemName: string;
  disabled: boolean = false;
}

export class Model {

  title: string = '';
  hint: string = '';
  placeholder: string = '';
  prevValueIds?: Set<number>;
  values: UserItem[] = [];
  multiSelect: boolean = false;

  getValueIdSet(): Set<number> {
    const ret: number[] = [];
    if (this.values) {
      this.values.forEach((value) => {
        if (value.id !== null) {
          ret.push(value.id!);
        }
      });
    }
    return ret.length > 0 ? Set.of<number>(...ret) : Set.of<number>();
  }

}
