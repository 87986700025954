<div class="modal-content">
  <div class="modal-header">
    <p class="modal-title"><strong>{{titleKey | translate}}</strong></p>
    <button type="button" class="close" (click)="closeDialog()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <mat-dialog-content style="overflow:visible">
      <form #f="ngForm" (ngSubmit)="save()" novalidate [formGroup]="formGroup">
        <div class="form-group row"
             [ngClass]="{ 'has-danger': hasLocalFieldError('stockInput')}">
          <label
            class="col-form-label col-md-4 text-md-right required-field-label">{{'STOCK_SELECTOR_DIALOG_STOCK' | translate}}</label>
          <div class="col-md-8">
            <angular2-multiselect
              (onRemoteSearch)="loadStocks($event.target.value)"
              [(ngModel)]="_selectedStock"
              [data]="stocks"
              [ngClass]="{ 'form-control-danger': hasLocalFieldError('stockInput') }"
              [settings]="dropdownSettings"
              formControlName="stockInput"
              required>
            </angular2-multiselect>
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('stockInput')">
              {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
            </div>
          </div>
        </div>
      </form>
    </mat-dialog-content>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()">
      {{'COMMON_BUTTON_CANCEL' | translate}}
    </button>
    <button type="button" class="btn btn-primary" (click)="save()">
      {{'COMMON_BUTTON_CONTINUE' | translate}}
    </button>
  </div>
</div>
