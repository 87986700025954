import {Trigger} from './trigger.service';
import {TriggerResource} from './trigger-resource.service';
import {Services} from '../util/services';
import {TriggerInstance} from './trigger-instance.service';
import {TriggerInstanceResource} from './trigger-instance-resource.service';
import {List, Map as ImmutableMap, Set} from 'immutable';
import {TransportDocument} from '../transport/transport-document/transport-document.service';
import {GrantedPermissionSet} from '../right.service';
import {RightModel} from '../../app.rights';
import {COMMA, DASH, ENTER} from '@angular/cdk/keycodes';
import {TaskRecord} from '../task/task-record.service';
import {WorkflowRuleUtils} from '../form/workflow-rule-utils';
import TriggerScope = TriggerUtils.TriggerScope;

export namespace TriggerUtils {

  // <editor-fold desc="Public to Resource functions">

  import TransportDocumentType = TransportDocument.TransportDocumentType;
  import TaskRecordBulkCloneField = TaskRecord.TaskRecordBulkCloneField;

  export function toResourceTriggerCreateRequest(request: Trigger.TriggerCreateRequest): TriggerResource.TriggerCreateRequest {
    return {
      trigger_parent_id: request.triggerParentId,
      email: toResourceEmailCreateRequest(request.email),
      event: request.event,
      message: toResourceMessageCreateRequest(request.message),
      name: request.name,
      pdf: toResourcePdfCreateRequest(request.pdf),
      receipt: toResourceReceiptCreateRequest(request.receipt),
      clone: toResourceCloneCreateRequest(request.clone),
      rules: request.rules.map(r => WorkflowRuleUtils.toResourceRule(r))
    };
  }

  export function toResourceTriggerUpdateRequest(request: Trigger.TriggerUpdateRequest): TriggerResource.TriggerUpdateRequest {
    return {
      id: request.id,
      trigger_parent_id: request.triggerParentId,
      name: request.name,
      event: request.event,
      email: toResourceEmailCreateRequest(request.email),
      message: toResourceMessageCreateRequest(request.message),
      pdf: toResourcePdfCreateRequest(request.pdf),
      receipt: toResourceReceiptCreateRequest(request.receipt),
      clone: toResourceCloneCreateRequest(request.clone),
      rules: request.rules.map(r => WorkflowRuleUtils.toResourceRule(r))
    };
  }

  export function toResourceTriggerDeleteRequest(request: Trigger.TriggerDeleteRequest): TriggerResource.TriggerDeleteRequest {
    return {
      id: request.id,
      trigger_parent_id: request.triggerParentId
    };
  }

  export function toResourceTriggerDisableRequest(request: Trigger.TriggerDisableRequest): TriggerResource.TriggerDisableRequest {
    return {
      id: request.id,
      trigger_parent_id: request.triggerParentId,
      disabled: request.disabled
    };
  }

  export function toResourceTriggerGetRequest(request: Trigger.TriggerGetRequest): TriggerResource.TriggerGetRequest {
    return {
      id: request.id,
      trigger_parent_id: request.triggerParentId
    };
  }

  export function toResourceTriggerInstanceRequest(request: Trigger.TriggerInstanceRequest): TriggerResource.TriggerInstanceRequest {
    return {
      id: request.id,
      trigger_parent_id: request.triggerParentId,
      page_number: request.paging.pageNumber,
      number_of_items: request.paging.numberOfItems
    };
  }

  export function toResourceTriggerQueryRequest(request: Trigger.TriggerQueryRequest): TriggerResource.TriggerQueryRequest {
    return {
      name_sw_ic: request.nameSwIc,
      trigger_parent_id: request.triggerParentId,
      disabled: request.disabled,
      order: Services.createOrderFieldParameter(Trigger.Keys.toTriggerOrderFieldKey, request.order),
      no_progress_bar: request.noProgressBar,
      page_number: request.paging ? request.paging.pageNumber : undefined,
      number_of_items: request.paging ? request.paging.numberOfItems : undefined,
    };
  }

  export function toResourceTriggerInstanceHistoryRequest(request: TriggerInstance.TriggerInstanceHistoryRequest):
    TriggerInstanceResource.TriggerInstanceHistoryRequest {
    return {
      instance_parent_id: request.instanceParentId,
      trigger_parent_id: request.triggerParentId,
      order: Services.createOrderFieldParameter(Trigger.Keys.toTriggerInstanceOrderFieldKey, request.order),
      page_number: request.paging ? request.paging.pageNumber : undefined,
      number_of_items: request.paging ? request.paging.numberOfItems : undefined
    };
  }

  export function toResourceEmailCreateRequest(request?: Trigger.EmailCreateRequest): TriggerResource.EmailCreateRequest | undefined {
    if (request) {
      return {
        email_template_id: request.emailTemplateId,
        ignored_with_empty_recipients: request.ignoredWithEmptyRecipients,
        attachment: {
          document_group_ids: request.attachment.documentGroupIds.toArray(),
          file_document_ids: request.attachment.fileDocumentIds.toArray(),
          pdf_trigger_ids: request.attachment.pdfTriggerIds ? request.attachment.pdfTriggerIds.toArray() : undefined,
          transport_document_types: request.attachment.transportDocumentTypes ?
            request.attachment.transportDocumentTypes.toArray() : undefined,
          attach_each_shipment_document: request.attachment.attachEachShipmentDocument,
          attach_invoices: request.attachment.attachInvoices,
          attach_task_attachments: request.attachment.attachTaskAttachments,
          document_form_field_ids: request.attachment.documentFormFieldIds.toArray()
        },
        recipient: {
          send_to_everyone: request.recipient.sendToEveryone,
          related_person_types: request.recipient.relatedPersonTypes.toArray(),
          user_ids: request.recipient.userIds.toArray(),
          user_group_ids: request.recipient.userGroupIds.toArray(),
          email_addresses: request.recipient.emailAddresses.toArray(),
          email_form_field_ids: request.recipient.emailFormFieldIds.toArray(),
          email_address_types: request.recipient.emailAddressTypeIds.toArray(),
          customer_form_field_email_address_types:
            toResourceCustomerFormFieldEmailAddressTypes(request.recipient.customerFormFieldEmailAddressTypes),
          user_form_field_ids: request.recipient.userFormFieldIds.toArray()
        }
      };
    }
    return undefined;
  }

  export function toResourceCustomerFormFieldEmailAddressTypes(customerFormFieldEmailAddressTypes: ImmutableMap<number, Set<number>>):
    any {
    const map = {};
    customerFormFieldEmailAddressTypes.forEach((value, key) => {
      if (key && value && value.size > 0) {
        map[key] = value.toArray();
      }
    })
    return map;
  }

  export function toResourceMessageCreateRequest(request?: Trigger.MessageCreateRequest): TriggerResource.MessageCreateRequest | undefined {
    if (request) {
      return {
        ignored_with_empty_recipients: request.ignoredWithEmptyRecipients,
        message_template_id: request.messageTemplateId,
        recipient: {
          send_to_everyone: request.recipient.sendToEveryone,
          mobile_application_ids: request.recipient.mobileApplicationIds.toArray(),
          related_person_types: request.recipient.relatedPersonTypes.toArray(),
          user_group_ids: request.recipient.userGroupIds.toArray(),
          user_ids: request.recipient.userIds.toArray(),
          user_form_field_ids: request.recipient.userFormFieldIds.toArray()
        }
      };
    }
    return undefined;
  }

  export function toResourcePdfCreateRequest(request?: Trigger.PdfCreateRequest): TriggerResource.PdfCreateRequest | undefined {
    if (request) {
      return {
        name_pattern: request.namePattern,
        pdf_template_id: request.pdfTemplateId
      };
    }
    return undefined;
  }

  export function toResourceReceiptCreateRequest(request?: Trigger.ReceiptCreateRequest): TriggerResource.ReceiptCreateRequest | undefined {
    if (request) {
      return {
        name_pattern: request.namePattern,
        receipt_template_id: request.receiptTemplateId
      };
    }
    return undefined;
  }

  export function toResourceCloneCreateRequest(request?: Trigger.CloneCreateRequest): TriggerResource.CloneCreateRequest | undefined {
    if (request) {
      return {
        open_if_possible: request.openIfPossible,
        default_deadline_additional_days: request.defaultDeadlineAdditionalDays,
        destination_task_id: request.destinationTaskId,
        clone_fields: request.cloneFields.toArray(),
        clone_form_fields: request.cloneFormFields ? request.cloneFormFields.toArray() : undefined
      };
    }
    return undefined;
  }

  // </editor-fold>

  // <editor-fold desc="Resource to Public functions">

  export function toPublicTriggerList(resourceList: TriggerResource.Trigger[]): List<Trigger.Trigger> {
    return List.of(...resourceList.map((r) => toPublicTrigger(r)));
  }

  export function toPublicTrigger(r: TriggerResource.Trigger): Trigger.Trigger {
    return {
      id: r.id,
      name: r.name,
      creationTime: Services.toOffsetDateTime(r.creation_time),
      updateTime: Services.toOffsetDateTime(r.update_time),
      disabled: r.disabled,
      type: <TriggerType>r.type,
      event: <NotificationEvent>r.event,
      email: toPublicEmail(r.email),
      message: toPublicMessage(r.message),
      pdf: toPublicPdf(r.pdf),
      receipt: toPublicReceipt(r.receipt),
      clone: toPublicClone(r.clone),
      rules: r.rules.map(r => WorkflowRuleUtils.toPublicRule(r))
    };
  }

  export function toPublicMessage(r?: TriggerResource.Message): Trigger.Message | undefined {
    if (r) {
      return {
        ignoredWithEmptyRecipients: r.ignored_with_empty_recipients,
        messageTemplate: {
          id: r.message_template.id,
          name: r.message_template.name
        },
        recipient: {
          userIds: Set.of(...r.recipient.user_ids),
          userGroupIds: Set.of(...r.recipient.user_group_ids),
          mobileApplicationIds: Set.of(...r.recipient.mobile_application_ids),
          relatedPersonTypes: Set.of(...r.recipient.related_person_types.map((r) => <RelatedPersonType>r)),
          sendToEveryone: r.recipient.send_to_everyone,
          userFormFieldIds: r.recipient.user_form_field_ids ? Set.of(...r.recipient.user_form_field_ids) : Set.of()
        }
      };
    }
    return undefined;
  }

  export function toPublicEmail(r?: TriggerResource.Email): Trigger.Email | undefined {
    if (r) {
      return {
        ignoredWithEmptyRecipients: r.ignored_with_empty_recipients,
        attachment: {
          pdfTriggerIds: r.attachment.pdf_trigger_ids ? Set.of(...r.attachment.pdf_trigger_ids) : undefined,
          fileDocumentIds: Set.of(...r.attachment.file_document_ids),
          documentGroupIds: Set.of(...r.attachment.document_group_ids),
          transportDocumentTypes: r.attachment.transport_document_types ?
            Set.of(...r.attachment.transport_document_types.map((t) => <TransportDocumentType>t)) : undefined,
          attachInvoices: r.attachment.attach_invoices === undefined ? false : r.attachment.attach_invoices,
          attachTaskAttachments: r.attachment.attach_task_attachments === undefined ? false : r.attachment.attach_task_attachments,
          documentFormFieldIds: r.attachment.document_form_field_ids ? Set.of(...r.attachment.document_form_field_ids) : Set.of()
        },
        emailTemplate: {
          id: r.email_template.id,
          name: r.email_template.name
        },
        recipient: {
          emailAddresses: List.of(...r.recipient.email_addresses),
          userIds: Set.of(...r.recipient.user_ids),
          userGroupIds: Set.of(...r.recipient.user_group_ids),
          relatedPersonTypes: Set.of(...r.recipient.related_person_types.map((r) => <RelatedPersonType>r)),
          emailFormFieldIds: r.recipient.email_form_field_ids ? Set.of(...r.recipient.email_form_field_ids) : Set.of(),
          emailAddressTypeIds: Set.of(...r.recipient.email_address_types),
          sendToEveryone: r.recipient.send_to_everyone,
          customerFormFieldEmailAddressTypes:
            toPublicCustomerFormFieldEmailAddressTypes(r.recipient.customer_form_field_email_address_types),
          userFormFieldIds: r.recipient.user_form_field_ids ? Set.of(...r.recipient.user_form_field_ids) : Set.of()
        }
      };
    }
    return undefined;
  }

  export function toPublicCustomerFormFieldEmailAddressTypes(c?: Map<number, number[]>): ImmutableMap<number, Set<number>> {
    const arr: any[] = [];
    if (c) {
      Object.keys(c).forEach((key) => {
        arr.push([key, c[key]]);
      });
    }
    return ImmutableMap(arr);
  }

  export function toPublicPdf(r?: TriggerResource.Pdf): Trigger.Pdf | undefined {
    if (r) {
      return {
        namePattern: r.name_pattern,
        pdfTemplate: {
          id: r.pdf_template.id,
          code: r.pdf_template.code
        }
      };
    }
    return undefined;
  }

  export function toPublicReceipt(r?: TriggerResource.Receipt): Trigger.Receipt | undefined {
    if (r) {
      return {
        namePattern: r.name_pattern,
        receiptTemplate: {
          id: r.receipt_template.id,
          code: r.receipt_template.code,
          printerType: <PrinterType>r.receipt_template.printer_type
        }
      };
    }
    return undefined;
  }

  export function toPublicClone(r?: TriggerResource.Clone): Trigger.Clone | undefined {
    if (r) {
      return {
        openIfPossible: r.open_if_possible,
        defaultDeadlineAdditionalDays: r.default_deadline_additional_days,
        destinationTaskId: r.destination_task_id,
        cloneFields: Set.of(...r.clone_fields.map(f => <TaskRecordBulkCloneField>f)),
        cloneFormFields: r.clone_form_fields ? Set.of(...r.clone_form_fields) : undefined
      }
    }
    return undefined;
  }

  export function toPublicTriggerInstanceList(resourceList: TriggerInstanceResource.TriggerInstance[]):
    List<TriggerInstance.TriggerInstance> {
    return List.of(...resourceList.map((r) => toPublicTriggerInstance(r)));
  }

  export function toPublicTriggerInstance(r: TriggerInstanceResource.TriggerInstance): TriggerInstance.TriggerInstance {
    return {
      creationTime: Services.toOffsetDateTime(r.creation_time),
      taskRecord: toPublicTriggerTaskRecord(r.task_record),
      transport: toPublicTriggerTransport(r.transport),
      trigger: {
        disabled: r.trigger.disabled,
        event: <NotificationEvent>r.trigger.event,
        name: r.trigger.name
      },
      type: <TriggerType>r.type
    };
  }

  export function toPublicTriggerTaskRecord(r?: TriggerInstanceResource.TriggerTaskRecord): TriggerInstance.TriggerTaskRecord | undefined {
    if (r) {
      return {
        id: r.id,
        name: r.name
      };
    }
    return undefined;
  }

  export function toPublicTriggerTransport(r?: TriggerInstanceResource.TriggerTransport): TriggerInstance.TriggerTransport | undefined {
    if (r) {
      return {
        id: r.id,
        externalId: r.external_id
      };
    }
    return undefined;
  }

  export function createEmailAddressList(emailAddresses: string): List<string> {
    const emailArray: string[] = emailAddresses.split(',');
    return List.of(...emailArray);
  }

  // </editor-fold>

  export type RelatedPersonType =
    'ASSIGNEE' |
    'ASSIGNEE_GROUP' |
    'CREATOR' |
    'PROCESS_CREATOR' |
    'SHIPMENT_RECIPIENT' |
    'DISPATCHER' |
    'DRIVER';

  export const allRelatedPersonTypesSet =
    Set.of(...['ASSIGNEE', 'SHIPMENT_RECIPIENT', 'DRIVER'].map(r => <RelatedPersonType>r));

  export const taskRelatedPersonTypesSet =
    Set.of(...['ASSIGNEE', 'ASSIGNEE_GROUP', 'CREATOR', 'PROCESS_CREATOR'].map(r => <RelatedPersonType>r));

  export const transportRelatedPersonTypesSet =
    Set.of(...['DISPATCHER', 'DRIVER', 'SHIPMENT_RECIPIENT'].map(r => <RelatedPersonType>r));

  export class RelatedPersonTypeObject {
    type: RelatedPersonType;
    stringKey: string;
    scope: TriggerScope;
    supportedTriggerTypes: Set<TriggerType>;
  }

  export const relatedPersonTypes: RelatedPersonTypeObject[] = [
    {
      type: 'ASSIGNEE',
      stringKey: 'RELATED_PERSON_TYPE_ASSIGNEE',
      scope: 'TASK',
      supportedTriggerTypes: Set.of(...['EMAIL', 'MESSAGE', 'PDF', 'RECEIPT'].map(r => <TriggerType>r))
    },
    {
      type: 'ASSIGNEE_GROUP',
      stringKey: 'RELATED_PERSON_TYPE_ASSIGNEE_GROUP',
      scope: 'TASK',
      supportedTriggerTypes: Set.of(...['EMAIL', 'MESSAGE', 'PDF', 'RECEIPT'].map(r => <TriggerType>r))
    },
    {
      type: 'DRIVER',
      stringKey: 'TRANSPORT_DRIVER',
      scope: 'TRANSPORT',
      supportedTriggerTypes: Set.of(...['EMAIL', 'MESSAGE'].map(r => <TriggerType>r))
    },
    {
      type: 'SHIPMENT_RECIPIENT',
      stringKey: 'ORDER_TABLE_HEADER_RECIPIENT',
      scope: 'TRANSPORT',
      supportedTriggerTypes: Set.of(...['EMAIL'].map(r => <TriggerType>r))
    },
    {
      type: 'DISPATCHER',
      stringKey: 'RELATED_PERSON_TYPE_DISPATCHER',
      scope: 'TRANSPORT',
      supportedTriggerTypes: Set.of(...['EMAIL', 'MESSAGE'].map(r => <TriggerType>r))
    },
  ];

  export type TriggerType =
    'EMAIL' |
    'MESSAGE' |
    'PDF' |
    'RECEIPT' |
    'CLONE';

  export class TriggerTypeObject {
    type: TriggerType;
    stringKey: string;
    iconClass: string;
    scope: Set<TriggerScope>;
  }

  export const triggerTypes: TriggerTypeObject[] = [
    {
      type: 'PDF',
      stringKey: 'TRIGGER_TYPE_PDF',
      iconClass: 'icomoon-file-pdf',
      scope: Set.of(...['TASK' as TriggerScope])
    },
    {
      type: 'EMAIL',
      stringKey: 'TRIGGER_TYPE_EMAIL',
      iconClass: 'icomoon-email-send',
      scope: Set.of(...['TASK' as TriggerScope, 'TRANSPORT' as TriggerScope])
    },
    {
      type: 'MESSAGE',
      stringKey: 'TRIGGER_TYPE_MESSAGE',
      iconClass: 'icomoon-push-notification',
      scope: Set.of(...['TASK' as TriggerScope, 'TRANSPORT' as TriggerScope])
    },
    {
      type: 'CLONE',
      stringKey: 'TRIGGER_TYPE_CLONE',
      iconClass: 'icomoon-new-task-customer',
      scope: Set.of(...['TASK' as TriggerScope])
    },
    {
      type: 'RECEIPT',
      stringKey: 'TRIGGER_TYPE_RECEIPT',
      iconClass: 'icomoon-thermal-paper',
      scope: Set.of(...['TASK' as TriggerScope])
    },
  ];

  export type NotificationEvent =
    'MESSAGE_CREATE' |
    'FILE_DOCUMENT_UPDATE' |
    'TASK_RECORD_CREATE' |
    'TASK_RECORD_OPEN' |
    'TASK_RECORD_START' |
    'TASK_RECORD_PAUSE' |
    'TASK_RECORD_RESUME' |
    'TASK_RECORD_RESTART' |
    'TASK_RECORD_RECALL' |
    'TASK_RECORD_REJECT' |
    'TASK_RECORD_SUBMIT' |
    'TASK_RECORD_FINISH' |
    'TASK_RECORD_VALIDATE' |
    'TASK_RECORD_INVALIDATE' |
    'TASK_RECORD_ARCHIVE' |
    'TASK_RECORD_CHAT_MESSAGE_CREATE' |
    'TASK_RECORD_UNSUBMIT' |
    'PROCESS_TASK_OPEN' |
    'TRANSPORT_CHAT_MESSAGE_CREATE' |
    'TRANSPORT_OPEN' |
    'TRANSPORT_REOPEN' |
    'TRANSPORT_START' |
    'TRANSPORT_GO' |
    'TRANSPORT_ARRIVE' |
    'TRANSPORT_PLAN' |
    'TRANSPORT_REPLAN' |
    'TRANSPORT_CONTINUE' |
    'TRANSPORT_FINISH' |
    'TRANSPORT_CLOSE' |
    'TRANSPORT_HANDOVER_FINISH_OR_FAIL';

  export interface NotificationEventObject {
    emailSupport: boolean;
    pushSupport: boolean;
    notificationEvent: NotificationEvent;
    stringKey: string;
    scope?: TriggerScope;
    supportedTriggerTypes?: Set<TriggerType>;
  }

  export type TriggerScope =
    'TASK' |
    'TRANSPORT';

  export const notificationEvents: NotificationEventObject[] = [
    {
      pushSupport: true, emailSupport: false,
      notificationEvent: 'MESSAGE_CREATE', stringKey: 'NOTIFICATION_CREATE'
    },
    {
      pushSupport: true, emailSupport: false, notificationEvent: 'FILE_DOCUMENT_UPDATE',
      stringKey: 'FILE_DOCUMENT_UPDATE'
    },
    {
      pushSupport: true, emailSupport: true, notificationEvent: 'TASK_RECORD_CREATE',
      stringKey: 'TASK_RECORD_CREATE', scope: 'TASK',
      supportedTriggerTypes: Set.of(...['EMAIL', 'MESSAGE', 'PDF', 'RECEIPT'].map(r => <TriggerType>r))
    },
    {
      pushSupport: true, emailSupport: true, notificationEvent: 'TASK_RECORD_OPEN',
      stringKey: 'TASK_RECORD_OPEN', scope: 'TASK'
    },
    {
      pushSupport: true, emailSupport: true, notificationEvent: 'TASK_RECORD_START',
      stringKey: 'TASK_RECORD_START', scope: 'TASK'
    },
    {
      pushSupport: true, emailSupport: true, notificationEvent: 'TASK_RECORD_PAUSE',
      stringKey: 'TASK_RECORD_PAUSE', scope: 'TASK'
    },
    {
      pushSupport: true, emailSupport: true, notificationEvent: 'TASK_RECORD_RESUME',
      stringKey: 'TASK_RECORD_RESUME', scope: 'TASK'
    },
    {
      pushSupport: true, emailSupport: true, notificationEvent: 'TASK_RECORD_RESTART',
      stringKey: 'TASK_RECORD_RESTART', scope: 'TASK'
    },
    {
      pushSupport: true, emailSupport: true, notificationEvent: 'TASK_RECORD_RECALL',
      stringKey: 'TASK_RECORD_RECALL', scope: 'TASK'
    },
    {
      pushSupport: true, emailSupport: true, notificationEvent: 'TASK_RECORD_REJECT',
      stringKey: 'TASK_RECORD_REJECT', scope: 'TASK'
    },
    {
      pushSupport: true, emailSupport: true, notificationEvent: 'TASK_RECORD_SUBMIT',
      stringKey: 'TASK_RECORD_SUBMIT', scope: 'TASK'
    },
    {
      pushSupport: true, emailSupport: true, notificationEvent: 'TASK_RECORD_FINISH',
      stringKey: 'TASK_RECORD_FINISH', scope: 'TASK'
    },
    {
      pushSupport: true, emailSupport: true, notificationEvent: 'TASK_RECORD_VALIDATE',
      stringKey: 'TASK_RECORD_VALIDATE', scope: 'TASK'
    },
    {
      pushSupport: true, emailSupport: true, notificationEvent: 'TASK_RECORD_INVALIDATE',
      stringKey: 'TASK_RECORD_INVALIDATE', scope: 'TASK'
    },
    {
      pushSupport: true, emailSupport: true, notificationEvent: 'TASK_RECORD_ARCHIVE',
      stringKey: 'TASK_RECORD_ARCHIVE', scope: 'TASK'
    },
    {
      pushSupport: true, emailSupport: true, notificationEvent: 'TASK_RECORD_CHAT_MESSAGE_CREATE',
      stringKey: 'TASK_RECORD_CHAT_MESSAGE_CREATE', scope: 'TASK'
    },
    {
      pushSupport: true, emailSupport: true, notificationEvent: 'TASK_RECORD_UNSUBMIT',
      stringKey: 'TASK_RECORD_UNSUBMIT', scope: 'TASK'
    },
    {
      pushSupport: true, emailSupport: false, notificationEvent: 'PROCESS_TASK_OPEN',
      stringKey: 'PROCESS_TASK_OPEN'
    },
    {
      pushSupport: true, emailSupport: false, notificationEvent: 'TRANSPORT_CHAT_MESSAGE_CREATE',
      stringKey: 'TRANSPORT_CHAT_MESSAGE_CREATE', scope: 'TRANSPORT'
    },
    {
      pushSupport: true, emailSupport: true, notificationEvent: 'TRANSPORT_OPEN',
      stringKey: 'TRANSPORT_LOG_TYPE_TRANSPORT_OPEN', scope: 'TRANSPORT'
    },
    {
      pushSupport: true, emailSupport: true, notificationEvent: 'TRANSPORT_REOPEN',
      stringKey: 'TRANSPORT_LOG_TYPE_TRANSPORT_REOPEN', scope: 'TRANSPORT'
    },
    {
      pushSupport: true, emailSupport: true, notificationEvent: 'TRANSPORT_START',
      stringKey: 'TRANSPORT_LOG_TYPE_TRANSPORT_START', scope: 'TRANSPORT'
    },
    {
      pushSupport: true, emailSupport: true, notificationEvent: 'TRANSPORT_GO',
      stringKey: 'TRANSPORT_LOG_TYPE_TRANSPORT_GO', scope: 'TRANSPORT'
    },
    {
      pushSupport: true, emailSupport: true, notificationEvent: 'TRANSPORT_ARRIVE',
      stringKey: 'TRANSPORT_LOG_TYPE_TRANSPORT_ARRIVE', scope: 'TRANSPORT'
    },
    {
      pushSupport: true, emailSupport: true, notificationEvent: 'TRANSPORT_PLAN',
      stringKey: 'TRANSPORT_LOG_TYPE_TRANSPORT_PLAN', scope: 'TRANSPORT'
    },
    {
      pushSupport: true, emailSupport: true, notificationEvent: 'TRANSPORT_REPLAN',
      stringKey: 'TRANSPORT_LOG_TYPE_TRANSPORT_REPLAN', scope: 'TRANSPORT'
    },
    {
      pushSupport: true, emailSupport: true, notificationEvent: 'TRANSPORT_CONTINUE',
      stringKey: 'TRANSPORT_CONTINUE', scope: 'TRANSPORT'
    },
    {
      pushSupport: true, emailSupport: true, notificationEvent: 'TRANSPORT_FINISH',
      stringKey: 'TRANSPORT_LOG_TYPE_TRANSPORT_FINISH', scope: 'TRANSPORT'
    },
    {
      pushSupport: true, emailSupport: true, notificationEvent: 'TRANSPORT_CLOSE',
      stringKey: 'TRANSPORT_LOG_TYPE_TRANSPORT_CLOSE', scope: 'TRANSPORT'
    },
    {
      pushSupport: true, emailSupport: true, notificationEvent: 'TRANSPORT_HANDOVER_FINISH_OR_FAIL',
      stringKey: 'TRANSPORT_HANDOVER_FINISH_OR_FAIL', scope: 'TRANSPORT'
    },
  ];

  export const shipmentRelatedNotificationEvents: NotificationEvent[] = ['TRANSPORT_HANDOVER_FINISH_OR_FAIL'];

  export type PrinterType = 'CASHINO_PTP_2';

  export const availableRelatedPersonTypes: ImmutableMap<NotificationEvent, Set<RelatedPersonType>> = ImmutableMap.of(
    'MESSAGE_CREATE', allRelatedPersonTypesSet,
    'FILE_DOCUMENT_UPDATE', allRelatedPersonTypesSet,
    'TASK_RECORD_CREATE', taskRelatedPersonTypesSet,
    'TASK_RECORD_OPEN', taskRelatedPersonTypesSet,
    'TASK_RECORD_START', taskRelatedPersonTypesSet,
    'TASK_RECORD_PAUSE', taskRelatedPersonTypesSet,
    'TASK_RECORD_RESUME', taskRelatedPersonTypesSet,
    'TASK_RECORD_RESTART', taskRelatedPersonTypesSet,
    'TASK_RECORD_RECALL', taskRelatedPersonTypesSet,
    'TASK_RECORD_REJECT', taskRelatedPersonTypesSet,
    'TASK_RECORD_SUBMIT', taskRelatedPersonTypesSet,
    'TASK_RECORD_FINISH', taskRelatedPersonTypesSet,
    'TASK_RECORD_VALIDATE', taskRelatedPersonTypesSet,
    'TASK_RECORD_INVALIDATE', taskRelatedPersonTypesSet,
    'TASK_RECORD_ARCHIVE', taskRelatedPersonTypesSet,
    'TASK_RECORD_CHAT_MESSAGE_CREATE', taskRelatedPersonTypesSet,
    'TASK_RECORD_UNSUBMIT', taskRelatedPersonTypesSet,
    'PROCESS_TASK_OPEN', allRelatedPersonTypesSet,
    'TRANSPORT_CHAT_MESSAGE_CREATE', transportRelatedPersonTypesSet,
    'TRANSPORT_OPEN', transportRelatedPersonTypesSet,
    'TRANSPORT_REOPEN', transportRelatedPersonTypesSet,
    'TRANSPORT_START', transportRelatedPersonTypesSet,
    'TRANSPORT_GO', transportRelatedPersonTypesSet,
    'TRANSPORT_ARRIVE', transportRelatedPersonTypesSet,
    'TRANSPORT_PLAN', transportRelatedPersonTypesSet,
    'TRANSPORT_REPLAN', transportRelatedPersonTypesSet,
    'TRANSPORT_CONTINUE', transportRelatedPersonTypesSet,
    'TRANSPORT_FINISH', transportRelatedPersonTypesSet,
    'TRANSPORT_CLOSE', transportRelatedPersonTypesSet,
    'TRANSPORT_HANDOVER_FINISH_OR_FAIL', transportRelatedPersonTypesSet,
  )

}

export class TriggerRightModel {

  public triggerRead: GrantedPermissionSet = GrantedPermissionSet.empty();
  public triggerDisable: GrantedPermissionSet = GrantedPermissionSet.empty();
  public triggerDelete: GrantedPermissionSet = GrantedPermissionSet.empty();
  public triggerCreate: GrantedPermissionSet = GrantedPermissionSet.empty();
  public triggerUpdate: GrantedPermissionSet = GrantedPermissionSet.empty();
  public triggerInstanceRead: GrantedPermissionSet = GrantedPermissionSet.empty();

  private rightModel: RightModel = RightModel.empty();

  private rightImmutableMap: ImmutableMap<GrantedPermissionSet, ImmutableMap<TriggerScope, GrantedPermissionSet>>;

  init(rightModel: RightModel, scope: TriggerScope) {
    this.rightModel = rightModel;
    this.initRightImmutableMap();
    this.triggerRead = this.getPermissionForScope(this.triggerRead, scope);
    this.triggerDisable = this.getPermissionForScope(this.triggerDisable, scope);
    this.triggerDelete = this.getPermissionForScope(this.triggerDelete, scope);
    this.triggerCreate = this.getPermissionForScope(this.triggerCreate, scope);
    this.triggerUpdate = this.getPermissionForScope(this.triggerUpdate, scope);
    this.triggerInstanceRead = this.getPermissionForScope(this.triggerInstanceRead, scope);
  }

  getPermissionForScope(permission: GrantedPermissionSet, scope: TriggerScope): GrantedPermissionSet {
    if (this.rightImmutableMap.get(permission) && this.rightImmutableMap.get(permission)!.get(scope)) {
      return this.rightImmutableMap.get(permission)!.get(scope)!;
    }
    return GrantedPermissionSet.empty();
  }

  initRightImmutableMap() {
    this.rightImmutableMap = ImmutableMap.of(
      this.triggerRead, ImmutableMap.of(
        'TASK' as TriggerScope, this.rightModel.taskTriggerRead,
        'TRANSPORT' as TriggerScope, this.rightModel.transportTriggerRead
      ),
      this.triggerDisable, ImmutableMap.of(
        'TASK' as TriggerScope, this.rightModel.taskTriggerDisable,
        'TRANSPORT' as TriggerScope, this.rightModel.transportTriggerDisable
      ),
      this.triggerDelete, ImmutableMap.of(
        'TASK' as TriggerScope, this.rightModel.taskTriggerDelete,
        'TRANSPORT' as TriggerScope, this.rightModel.transportTriggerDelete
      ),
      this.triggerCreate, ImmutableMap.of(
        'TASK' as TriggerScope, this.rightModel.taskTriggerCreate,
        'TRANSPORT' as TriggerScope, this.rightModel.transportTriggerCreate
      ),
      this.triggerUpdate, ImmutableMap.of(
        'TASK' as TriggerScope, this.rightModel.taskTriggerUpdate,
        'TRANSPORT' as TriggerScope, this.rightModel.transportTriggerUpdate
      ),
      this.triggerInstanceRead, ImmutableMap.of(
        'TASK' as TriggerScope, this.rightModel.taskTriggerInstanceRead,
        'TRANSPORT' as TriggerScope, this.rightModel.transportTriggerInstanceRead
      )
    );
  }

}

export namespace TaskTriggerFields {
  const COUNTER: string = '{{conter}}';
  const YEAR: string = '{{year}}';
  const YEAR_SHORT: string = '{{year:short}}';
  const TASK_NAME: string = '{{TaskName}}';
  const POC_ADDRESS: string = '{{PocAddress}}';
  const FINALIZATION_USER_NAME: string = '{{FinalizationUserName}}';
  const FINALIZATION_USER_PERSON_NAME: string = '{{FinalizationUserPersonName}}';
  const ASSIGNEE_USER_GROUP_NAME: string = '{{AssigneeUserGroupName}}';
  const ASSIGNEE_USER_NAME: string = '{{AssigneeUserName}}';
  const ASSIGNEE_USER_PERSON_NAME: string = '{{AssigneeUserPersonName}}';
  const CREATOR_USER_NAME: string = '{{CreatorUserName}}';
  const CREATOR_USER_PERSON_NAME: string = '{{CreatorUserPersonName}}';
  const ACTUAL_DATE: string = '{{ActualDate}}';
  const AGREED_TIME: string = '{{AgreedTime}}';
  const CUSTOMER_RECORD_NAME: string = '{{CustomerRecordName}}';
  const CUSTOMER_RECORD_EXTERNAL_ID: string = '{{CustomerRecordExternalId}}';
  const CUSTOMER_RECORD_PHONE_NUMBERS: string = '{{CustomerRecordPhoneNumbers}}';
  const CUSTOMER_NAME: string = '{{CustomerName}}';
  const CUSTOMER_EXTERNAL_ID: string = '{{CustomerExternalId}}';
  const TASK_RECORD_ID: string = '{{TaskRecordId}}';
  const TASK_RECORD_EXTERNAL_ID: string = '{{TaskRecordExternalId}}';
  const TASK_RECORD_NAME: string = '{{TaskRecordName}}';
  const PROCESS_NAME: string = '{{ProcessName}}';
  const PROCESS_EXTERNAL_ID: string = '{{ProcessExternalId}}';
  const WORKFLOW_NAME: string = '{{WorkflowName}}';
  const WORKFLOW_EXTERNAL_ID: string = '{{WorkflowExternalId}}';
  const PROJECT_RECORD_NAME: string = '{{ProjectRecordName}}';
  const PROJECT_RECORD_EXTERNAL_ID: string = '{{ProjectRecordExternalId}}';
  const TASK_RECORD_CURRENT_STATE_CHANGE_MESSAGE: string = '{{TaskRecordCurrentStateChangeMessage}}';
  const TASK_RECORD_HYPERLINK: string = '{{TaskRecordHyperlink}}';
  const TASK_RECORD_HELPDESK_HYPERLINK: string = '{{TaskRecordHelpdeskHyperlink}}';
  const TASK_RECORD_ADDRESS_NAME: string = '{{TaskRecordAddressName}}';
  const TASK_RECORD_CONTRACT_NUMBER: string = '{{TaskRecordContractNumber}}';
  const TASK_EXTERNAL_ID: string = '{{TaskExternalId}}';


  export const separatorCharacter = '_';
  export const separatorKeyCodes: number[] = [ENTER, COMMA, DASH];

  export const availableItems: string[] = [
    TASK_NAME,
    POC_ADDRESS,
    FINALIZATION_USER_NAME,
    FINALIZATION_USER_PERSON_NAME,
    ASSIGNEE_USER_NAME,
    ASSIGNEE_USER_PERSON_NAME,
    CREATOR_USER_NAME,
    CREATOR_USER_PERSON_NAME,
    ASSIGNEE_USER_GROUP_NAME,
    ACTUAL_DATE,
    AGREED_TIME,
    CUSTOMER_RECORD_NAME,
    CUSTOMER_NAME,
    CUSTOMER_RECORD_EXTERNAL_ID,
    CUSTOMER_RECORD_PHONE_NUMBERS,
    CUSTOMER_EXTERNAL_ID,
    TASK_RECORD_ID,
    TASK_RECORD_EXTERNAL_ID,
    TASK_RECORD_NAME,
    PROJECT_RECORD_NAME,
    PROJECT_RECORD_EXTERNAL_ID,
    YEAR,
    YEAR_SHORT,
    COUNTER,
    PROCESS_NAME,
    PROCESS_EXTERNAL_ID,
    WORKFLOW_NAME,
    WORKFLOW_EXTERNAL_ID,
    TASK_RECORD_CURRENT_STATE_CHANGE_MESSAGE,
    TASK_RECORD_HYPERLINK,
    TASK_RECORD_HELPDESK_HYPERLINK,
    TASK_RECORD_ADDRESS_NAME,
    TASK_RECORD_CONTRACT_NUMBER,
    TASK_EXTERNAL_ID,
  ];

}
