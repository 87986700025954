/* eslint-disable */
import { Dates, LocalDateInput, LocalDateTemplate, OffsetDateTimeInput, OffsetDateTimeTemplate } from '../lib/util/dates';
import { SettingsService } from '../lib/settings.service';
import { Injectable } from '@angular/core';
/* eslint-enable */

@Injectable()
export class DatePickerInputFactory {

  constructor(private settings: SettingsService) {}

  public createFromDateAndTime(dateText: string, timeText: string): DatePickerInput {
    return DatePickerInput.createFromDateAndTime(this.settings.getLanguageCode(), dateText, timeText);
  }

  public createFromDate(dateText: string): DatePickerInput {
    return DatePickerInput.createFromDate(this.settings.getLanguageCode(), dateText);
  }

}

@Injectable()
export class DatePickerTemplateFactory {

  constructor(private settings: SettingsService) {}

  public createDateTemplate(): OffsetDateTimeTemplate {
    return new DatePickerOffsetDateTimeDateTemplate(this.settings.getLanguageCode());
  }

  public createTimeTemplate(): OffsetDateTimeTemplate {
    return new DatePickerOffsetDateTimeTimeTemplate();
  }

}

export class DatePickerInput implements LocalDateInput, OffsetDateTimeInput {

  private timeZoneName: string;

  public static createFromDateAndTime(languageCode: string, dateText: string, timeText: string): DatePickerInput {
    if (!timeText) {
      return DatePickerInput.createFromDate(languageCode, dateText);
    }
    const pattern = DatePickers.getPattern(languageCode) + ' ' + TimePickers.getPattern();
    const text = dateText + ' ' + timeText;
    return new DatePickerInput(pattern, text);
  }

  public static createFromDate(languageCode: string, dateText: string): DatePickerInput {
    const pattern = DatePickers.getPattern(languageCode);
    return new DatePickerInput(pattern, dateText);
  }

  private constructor(private pattern: string, private text: string) {
    this.timeZoneName = Dates.guessLocalTimeZoneName();
  }

  getText(): string|any|any {
    return this.text;
  }

  getPattern(): string {
    return this.pattern;
  }

  getTimeZoneName(): string {
    return this.timeZoneName;
  }

}

export class DatePickerLocalDateTemplate implements LocalDateTemplate {

  private pattern: string;

  constructor(private languageCode: string) {
    this.pattern = DatePickers.getPattern(languageCode);
  }

  getPattern(): string {
    return this.pattern;
  }

}

export class DatePickerOffsetDateTimeDateTemplate implements OffsetDateTimeTemplate {

  private pattern: string;
  private timeZoneName: string;

  constructor(private languageCode: string) {
    this.pattern = DatePickers.getPattern(languageCode);
    this.timeZoneName = Dates.guessLocalTimeZoneName();
  }

  getPattern(): string {
    return this.pattern;
  }

  getTimeZoneName(): string {
    return this.timeZoneName;
  }

}

export class DatePickerOffsetDateTimeTimeTemplate implements OffsetDateTimeTemplate {

  private pattern: string;
  private timeZoneName: string;

  constructor() {
    this.pattern = TimePickers.getPattern();
    this.timeZoneName = Dates.guessLocalTimeZoneName();
  }

  getPattern(): string {
    return this.pattern;
  }

  getTimeZoneName(): string {
    return this.timeZoneName;
  }

}

class DatePickers {

  private static readonly patterns = {
    'en': 'DD/MM/YYYY',
    'hu': 'YYYY.MM.DD.',
  };

  public static getPattern(languageCode: string): string {
    return DatePickers.patterns[languageCode];
  }

  private constructor() {}

}

class TimePickers {

  public static getPattern(): string {
    return 'H:mm';
  }

  private constructor() {}

}
