import {Injectable} from '@angular/core';
import {CustomerRecord, CustomerRecordService} from '../../../../../lib/customer/customer-record.service';
import {GrantedPermissionSetResolver} from '../../../../../lib/right.service';
import {Observable} from 'rxjs';
import {List, Set} from 'immutable';
import {OperationRights} from '../../../../../app.right-definitions';
import {map} from 'rxjs/operators';
import {Strings} from '../../../../../lib/util/strings';
import {Order, OrderType, PagingRequest, QueryResult} from '../../../../../lib/util/services';
import {CustomerRecordRightModel} from "../../../../../util/customer-record-utils";
import {Address} from "../../../../../lib/address";

@Injectable()
export class FormRecordCustomerFieldLoader {

  constructor(private customerRecordService: CustomerRecordService,
  ) {
  }


  getCustomerRecordObservable(customerId: number, includeDisabled: boolean, withRights: boolean,
                              ids?: number[], q?: string,
                              ownerCustomerRecords?: Set<number>, ownerLocations?: Set<number>, paging?: PagingRequest,
                              orders?: Set<Order<CustomerRecord.OrderField>>):
    Observable<QueryResult<CustomerRecordFormFieldModel>> {
    const idSet = ids ? Set.of(...ids) : undefined;
    return this.customerRecordService.query({
      disabled: includeDisabled ? undefined : false,
      queryText: Strings.undefinedOrNonEmpty(q),
      customerId: customerId,
      withFormRecord: true,
      fields: Set.of('id', 'name', 'external_id', 'email_addresses', 'phone_numbers'),
      customerRecordIdSet: idSet,
      ownerCustomerRecordId: ownerCustomerRecords,
      ownerContactLocationId: ownerLocations,
      orders: orders ? orders : Set.of({field: CustomerRecord.OrderField.NAME, type: OrderType.ASC}),
      paging: paging ? paging : {
        pageNumber: 1,
        numberOfItems: 100
      },
      noProgressBar: true,
      rights: Set.of(
        OperationRights.CUSTOMER_RECORD_READ,
        OperationRights.CUSTOMER_RECORD_UPDATE,
        OperationRights.CUSTOMER_RECORD_DISABLE
      )
    }).pipe(map(value => {
      return {
        items: List.of(...value.items.map(cr => {
          return this.mapCustomerRecordModel(cr!);
        }).toArray()),
        pagingResult: value.pagingResult
      };
    }));
  }

  public mapCustomerRecordModel(cr: CustomerRecord.CustomerRecord): CustomerRecordFormFieldModel {
    const cx: CustomerRecordFormFieldModel = cr;
    cx.rights = new CustomerRecordRightModel(GrantedPermissionSetResolver.byGrantedRights(cr.grantedRights));
    cx.firstEmailAddress = cr.emailAddresses.get(0, undefined);
    cx.firstPhoneNumber = cr.phoneNumbers.get(0, undefined);
    return cx;
  }
}

export interface CustomerRecordFormFieldModel extends CustomerRecord.CustomerRecord {
  rights?: CustomerRecordRightModel;
  firstEmailAddress?: Address.EmailAddressData;
  firstPhoneNumber?: Address.PhoneNumberData;
}
