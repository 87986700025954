/* eslint-disable */
import { Injectable } from '@angular/core';
import { OrderField } from '../../query/orderfields';
import { OrderFieldModel, OrderFunctionResolver } from '../../../util/core-utils';
import { NewBaseSearch, NewBaseSearchService } from '../../new-base.search-service';
import { OrderType } from '../../util/services';
import { SearchUtils } from '../../../util/search-utils';
import { LoggedInUserStorage } from '../../util/storages';
import { InvoiceSpending } from './invoice-spending.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

/* eslint-enable */

@Injectable()
export class InvoiceSpendingSearchService extends NewBaseSearchService<OrderField.InvoiceSpending> {

  protected createStorage(): InvoiceSpendingSearch.Storage {
    return new InvoiceSpendingSearch.Storage();
  }

}

export namespace InvoiceSpendingSearch {

  export class Model extends SearchUtils.SearchModel {

    public static defaultSelectedState = {id: null, stringKey: 'COMMON_VALUE_UNSELECTED'};

    showSearch: boolean = false;

    id?: number;
    spendingNumber: string;
    eventName: string;
    invoiceSpendingType: SpendingTypeFilterOption;
    creationTimeFrom: NgbDateStruct | null;
    creationTimeTo: NgbDateStruct | null;

    constructor() {
      super();
      this.reset();
    }

    reset() {
      this.id = undefined;
      this.spendingNumber = '';
      this.eventName = '';
      this.invoiceSpendingType = Model.defaultSelectedState;
      this.creationTimeFrom = null;
      this.creationTimeTo = null;
    }

    isEmpty(): boolean {
      return this.id === undefined &&
        this.spendingNumber === '' &&
        this.eventName === '' &&
        this.invoiceSpendingType.id === null &&
        this.creationTimeFrom === null &&
        this.creationTimeTo === null ;
    }

  }

  export namespace OrderFunctions {
    export const ID = (f: OrderField.InvoiceSpending) => f.id;
    export const EVENT_NAME = (f: OrderField.InvoiceSpending) => f.eventName;
    export const SPENDING_NUMBER = (f: OrderField.InvoiceSpending) => f.spendingNumber;
    export const CREATION_TIME = (f: OrderField.InvoiceSpending) => f.creationTime;
    export const ISSUE_DATE = (f: OrderField.InvoiceSpending) => f.creationTime;
    export const AMOUNT = (f: OrderField.InvoiceSpending) => f.amount;

    export const VALUES = OrderFunctionResolver.builder<OrderField.InvoiceSpending>()
      .add(ID, 'id')
      .add(SPENDING_NUMBER, 'spending_number')
      .add(EVENT_NAME, 'event_name')
      .add(CREATION_TIME, 'creation_time')
      .add(ISSUE_DATE, 'issue_date')
      .add(AMOUNT, 'amount')
      .build();
  }

  export interface SpendingTypeFilterOption {
    id: InvoiceSpending.InvoiceSpendingType | null;
    stringKey: string;
  }

  export interface SearchDataResult {
    searchData: SearchData;
  }

  export interface SearchData extends NewBaseSearch.SearchData<OrderField.InvoiceSpending> {
    searchModel: Model;
  }

  interface StoredSearchRawData extends NewBaseSearch.StoredSearchRawData<OrderField.InvoiceSpending> {
    id?: number;
    spendingNumber: string;
    eventName: string;
    invoiceSpendingType: SpendingTypeFilterOption;
    creationTimeFrom: NgbDateStruct | null;
    creationTimeTo: NgbDateStruct | null;
  }

  export class Storage extends NewBaseSearch.Storage<OrderField.InvoiceSpending> {

    constructor() {
      const defaultSearchData: SearchData = {
        queryModel: new OrderFieldModel(OrderFunctions.ID, OrderType.DESC),
        searchModel: new Model(),
      };
      /**
       * Increment this version if you change the SearchData interface.
       */
      const versionNumber: number = 1;
      super(defaultSearchData,
        versionNumber);
    }

    protected generateKey(): string {
      return 'UserId(' + LoggedInUserStorage.getInstance().getUserId() + ')-Search-InvoiceSpending';
    }

    protected fromRaw(data: StoredSearchRawData): NewBaseSearch.SearchDataResult<OrderField.InvoiceSpending> {
      const searchModel = new Model();
      searchModel.id = data.id;
      searchModel.spendingNumber = data.spendingNumber;
      searchModel.eventName = data.eventName;
      searchModel.invoiceSpendingType = data.invoiceSpendingType;
      searchModel.creationTimeFrom = data.creationTimeFrom;
      searchModel.creationTimeTo = data.creationTimeTo;
      const searchData: SearchData = {
        queryModel: OrderFieldModel.create<OrderField.InvoiceSpending>(OrderFunctions.VALUES, data.order, data.queryModel),
        searchModel: searchModel,
      };
      return {
        searchData: searchData
      };
    }

    protected toRaw(data: SearchData): StoredSearchRawData {
      return {
        version: this.VERSION,
        queryModel: data.queryModel,
        order: data.queryModel.createOrder(OrderFunctions.VALUES),
        id: data.searchModel.id,
        spendingNumber: data.searchModel.spendingNumber,
        eventName: data.searchModel.eventName,
        invoiceSpendingType: data.searchModel.invoiceSpendingType,
        creationTimeFrom: data.searchModel.creationTimeFrom,
        creationTimeTo: data.searchModel.creationTimeTo
      };
    }

  }

}
