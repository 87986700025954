<div class="breadcrumb-button-icon-container cursor-pointer"
     *ngIf="allowInvoice" appPreventDoubleClick (throttledClick)="invoiceClicked()">
  <a class="btn-setting d-flex align-items-center" title="{{'TASK_RECORD_CREATE_INVOICE' | translate}}">
    <i class="icomoon icomoon-sidebar-tasks"></i>
    <span class="breadcrumb-button-text-visible">{{'TASK_RECORD_CREATE_INVOICE' | translate}}</span>
  </a>
</div>

<div class="breadcrumb-button-icon-container cursor-pointer"
     *ngIf="sendMessage" appPreventDoubleClick (throttledClick)="messageClicked()">
  <a class="btn-setting d-flex align-items-center" title="{{'TASK_RECORD_EDIT_SEND_NEW_MESSAGE' | translate}}">
    <i class="icomoon icomoon-chat-message"></i>
    <span class="breadcrumb-button-text-visible">{{'TASK_RECORD_EDIT_SEND_NEW_MESSAGE' | translate}}</span>
  </a>
</div>

<div class="breadcrumb-button-icon-container cursor-pointer"
     *ngIf="taskRecordDefined && allowClone" appPreventDoubleClick (throttledClick)="cloneTaskRecord()">
  <a class="btn-setting d-flex align-items-center" title="{{'TASK_RECORD_CLONE_TITLE' | translate}}">
    <i class="icomoon icomoon-clone"></i>
    <span class="breadcrumb-button-text-visible">{{'TASK_RECORD_CLONE_TITLE' | translate}}</span>
  </a>
</div>

<div class="breadcrumb-button-icon-container cursor-pointer"
     *ngIf="taskRecordDefined && allowBulkClone" appPreventDoubleClick (throttledClick)="bulkCloneTaskRecord()">
  <a class="btn-setting d-flex align-items-center" title="{{'TASK_RECORD_BULK_CLONE_TITLE' | translate}}">
    <i class="icomoon icomoon-clone"></i>
    <span class="breadcrumb-button-text-visible">{{'TASK_RECORD_BULK_CLONE_TITLE' | translate}}</span>
  </a>
</div>

<div class="breadcrumb-button-icon-container cursor-pointer"
     *ngIf="taskRecordDefined && allowAssigneeChangeInProgress" appPreventDoubleClick (throttledClick)="changeAssigneeInProgress()">
  <a class="btn-setting d-flex align-items-center" title="{{'COMMON_BUTTON_ASSIGNEE_MODIFICATION' | translate}}">
    <i class="icomoon icomoon-deadline-alt"></i>
    <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_ASSIGNEE_MODIFICATION' | translate}}</span>
  </a>
</div>

<div class="breadcrumb-button-icon-container cursor-pointer"
     *ngIf="taskRecordDefined && allowGeocode && readonly" appPreventDoubleClick (throttledClick)="geocode()">
  <a class="btn-setting d-flex align-items-center" title="{{'TASK_GEOCODING_TITLE'|translate}}">
    <i class="icomoon icomoon-location"></i>
    <span class="breadcrumb-button-text-visible">{{'TASK_GEOCODING_TITLE' | translate}}</span>
  </a>
</div>

<div class="breadcrumb-button-icon-container cursor-pointer"
     *ngIf="taskRecordDefined && allowStartApproval()" appPreventDoubleClick (throttledClick)="startApproval.emit()">
  <a class="btn-setting d-flex align-items-center" title="{{'TASK_RECORD_START_APPROVAL'|translate}}">
    <i class="icomoon icomoon-new-state-pending-approval"></i>
    <span class="breadcrumb-button-text-visible">{{'TASK_RECORD_START_APPROVAL' | translate}}</span>
  </a>
</div>

<div class="breadcrumb-button-icon-container cursor-pointer"
     *ngIf="taskRecordDefined && allowCancelApproval()" appPreventDoubleClick (throttledClick)="cancelApproval.emit()">
  <a class="btn-setting d-flex align-items-center" title="{{'TASK_RECORD_CANCEL_APPROVAL'|translate}}">
    <i class="icomoon icomoon-restart"></i>
    <span class="breadcrumb-button-text-visible">{{'TASK_RECORD_CANCEL_APPROVAL' | translate}}</span>
  </a>
</div>

<div class="breadcrumb-button-icon-container cursor-pointer"
     *ngIf="settableStateList.length > 0" appPreventDoubleClick (throttledClick)="changeState(settableStateList[0].id)">
  <a class="btn-setting d-flex align-items-center" title="{{settableStateList[0].text}}">
    <i class="{{settableStateList[0].stateObject.iconEditClass}}"></i>
    <span class="breadcrumb-button-text-visible">{{settableStateList[0].text}}</span>
  </a>
</div>
<!--DROPDOWN FOR EXTRA STATES-->
<div class="breadcrumb-button-icon-container-no-text cursor-pointer p-0"
     *ngIf="settableStateList.length > 1">
  <a class="dropdown dropdown-arrow-padding"
     dropdown title="{{'PROCESS_EDIT_STATE_CHANGE' | translate}}"
     href="#" onclick="return false" dropdownToggle
     data-toggle="dropdown" role="button"
     aria-haspopup="true" aria-expanded="false">
    <i class="icomoon icomoon-dropdown">
    </i>
    <div class="dropdown-menu dropdown-menu-right"
         *dropdownMenu
         aria-labelledby="simple-dropdown">
          <span appPreventDoubleClick (throttledClick)="changeState(state.id)" *ngFor="let state of settableStateList | slice:1"
                class="btn-setting dropdown-item d-flex align-items-center"
                title="{{state.text}}">
            <i class="{{state.stateObject.iconEditClass}}"></i>
            <span>{{state.text}}</span>
          </span>
    </div>
  </a>
</div>

<div class="breadcrumb-button-icon-container cursor-pointer"
     *ngIf="taskRecordDefined && allowEdit" appPreventDoubleClick (throttledClick)="editTaskRecord()">
  <a class="btn-setting d-flex align-items-center" title="{{'TASK_RECORD_EDIT_TITLE' | translate}}">
    <i class="icomoon icomoon-modify"></i>
    <span class="breadcrumb-button-text-visible">{{'TASK_RECORD_EDIT_TITLE' | translate}}</span>
  </a>
</div>
