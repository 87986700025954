<div class="container-vertical-padding-half">

  <div class="card col-lg-6 col-md-12 col-xs-12 col-sm-12 p-0">

    <div class="card-header">
      {{headingDictionaryKey | translate}}
    </div>

    <div class="card-body" [formGroup]="formGroup">

      <!-- region Name -->
      <div class="form-group row mb-0" [ngStyle]="fieldStyle" [ngClass]="{ 'has-danger': hasFieldError(SurveyRecord.ValidatedField.NAME) || hasLocalFieldError('name') }">
        <label class="col-form-label form-control-label col-md-4 detail-title">{{'SURVEY_RECORD_NAME' | translate}}</label>
        <div class="col-md-8">
          <input type="text" class="form-control" placeholder="{{'SURVEY_RECORD_NAME' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 [readonly]="readonly" formControlName="name"
                 trim="blur" validateOnBlur [validateFormControl]="formGroup.controls['name']"
                 [ngClass]="{ 'form-control-danger': hasFieldError(SurveyRecord.ValidatedField.NAME) || hasLocalFieldError('name'), 'detail-description' : readonly }"
                 [(ngModel)]="model.name" (ngModelChange)="removeFieldError(SurveyRecord.ValidatedField.NAME)">
          <div class="form-control-feedback" *ngIf="hasFieldError(SurveyRecord.ValidatedField.NAME)">
            {{getFieldErrorText(SurveyRecord.ValidatedField.NAME)}}
          </div>
          <div class="form-control-feedback" *ngIf="hasLocalFieldError('name')">
            <span *ngIf="hasLocalFieldError('name', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
          </div>
        </div>
      </div>
      <!-- endregion Name -->

      <!-- region Owner User -->
      <div class="form-group row mb-0" [ngStyle]="fieldStyle" *ngIf="model.ownerUserId">
        <label class="col-form-label form-control-label col-md-4 detail-title">{{'SURVEY_OWNER_USER_NAME' | translate}}</label>
        <div class="col-md-8">
          <input type="text" class="form-control" id="user" value="{{getOwnerUserName()}}" readonly
          [ngClass]="{ 'detail-description' : readonly }">
        </div>
      </div>
      <!-- endregion Owner User -->

      <!-- region Customer -->
      <div class="form-group mb-0" [ngStyle]="fieldStyle" *ngIf="!(readonly && model._customerRecord.length === 0)">
        <div class="row">
          <label class="col-form-label form-control-label col-md-4 detail-title">{{'TASK_LABEL_CUSTOMER' |
            translate}}</label>
          <div class="col-md-8" *ngIf="!readonly">
            <angular2-multiselect [data]="customers" [settings]="customerDropdownSettings"
                                  class="form-control"
                                  (onRemoteSearch)="getCustomers([], $event)"
                                  [(ngModel)]="model._customerRecord"
                                  [ngModelOptions]="{standalone: true}">
            </angular2-multiselect>
          </div>
          <div class="col-md-8" *ngIf="readonly">
            <label class="col-form-label form-control detail-description" readonly>{{model.customerRecord ? model.customerRecord.itemName
              : ''}}</label>
          </div>
        </div>
      </div>
      <!-- endregion Customer -->

    </div>

    <input type="submit" class="btn btn-primary floating_save_button" *ngIf="!readonly" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>

  </div>

</div>
