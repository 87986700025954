/* eslint-disable */
import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { Node, Tree } from '../../util/tree/tree-utils';
/* eslint-enable */

/** To use this component you have to input two arrays that have the type of `TwoWayMultiselectItem`.
 *  If you need other fields in your items then you can create a new interface that extends
 *  the `TwoWayMultiSelectItem` interface.
 *  The two arrays have to be sets and complementary to each other (by combining them you should
 *  get the whole dataset).
 *  The input arrays do not have to be sorted, the component takes care of that.
 *  The arrays are sorted and searched by their `text` field.
 *  The `id` field needs to be of a type that works with the basic comparison operators.
 **/

@Component({
  selector: 'app-two-way-tree-multiselect',
  templateUrl: './two-way-tree-multiselect.component.html',
  styleUrls: ['./two-way-tree-multiselect.component.scss']
})
export class TwoWayTreeMultiselectComponent {

  @Input()
  unselectedTreeTitleStringKey: string;

  @Input()
  selectedTreeTitleStringKey: string;

  unselectedTree: Tree = new Tree([]);
  selectedTree: Tree = new Tree([]);

  unselectedSearch: string = '';
  selectedSearch: string = '';

  constructor() {}

  setUnselectedData(data: Node[]) {
    this.unselectedTree.setData(data);
  }

  setSelectedData(data: Node[]) {
    this.selectedTree.setData(data);
  }

  getData(): Node[] {
    return this.selectedTree.leafs;
  }

  addSelected() {
    this.resetSearchFields();
    this.selectedTree.mergeData(this.unselectedTree.getSelectedTree());
    this.unselectedTree.removeSelectedTree();
    this.selectedTree.sort();
    this.unselectedTree.sort();
  }

  removeSelected() {
    this.resetSearchFields();
    this.unselectedTree.mergeData(this.selectedTree.getSelectedTree());
    this.selectedTree.removeSelectedTree();
    this.unselectedTree.sort();
    this.selectedTree.sort();
  }

  searchUnselected() {
    this.unselectedTree.filter(this.unselectedSearch);
  }

  searchSelected() {
    this.selectedTree.filter(this.selectedSearch);
  }

  private resetSearchFields() {
    this.unselectedSearch = '';
    this.selectedSearch = '';
    this.searchUnselected();
    this.searchSelected();
  }

  toggleAllSelectUnselected() {
    setTimeout(() => {
      this.unselectedTree.toggleAll();
    });
  }

  toggleAllSelectSelected() {
    setTimeout(() => {
      this.selectedTree.toggleAll();
    });
  }

}


