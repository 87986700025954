/* eslint-disable */
import { Dates, LocalDate, } from '../../util/dates';
import { Observable, Observer } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Order, OrderType, Services, } from '../../util/services';
import { MemStorage } from '../../util/mem-storage';
import { Injectable } from '@angular/core';
import { UiConstants } from '../../../util/core-utils';
import { TaskLog } from './task-log.service';
import { RuntimeConfiguration } from '../../runtime-configuration';
import { LoggedInUserStorage } from '../../util/storages';
/* eslint-enable */

@Injectable()
export class TaskLogSearchService {

  constructor() {
  }

  getSearchData(request: TaskLogSearch.SearchDataGetRequest): Observable<TaskLogSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<TaskLogSearch.SearchDataResult>) => {
      try {
        observer.next(storage.read(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  setSearchData(request: TaskLogSearch.SearchDataSetRequest): Observable<TaskLogSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<TaskLogSearch.SearchDataResult>) => {
      try {
        observer.next(storage.save(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  resetSearchData(request: TaskLogSearch.SearchDataGetRequest): Observable<TaskLogSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<TaskLogSearch.SearchDataResult>) => {
      try {
        observer.next(storage.reset(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  private createStorage(): TaskLogSearch.Storage {
    return new TaskLogSearch.Storage();
  }

}

export namespace TaskLogSearch {

  export interface SearchDataGetRequest {
  }

  export interface SearchDataSetRequest {
    searchData: SearchData;
  }

  export interface SearchDataResult {
    searchData: SearchData;
  }

  export interface SearchData {
    itemsPerPage: number;
    pageNumber: number;
    order: Order<TaskLog.OrderField>;
    userName: string;
    applicationTypeId?: number;
    taskTypeName: string;
    taskName: string;
    logType: string;
    message: string;
    logTimeFrom: LocalDate;
    logTimeTo: LocalDate;
  }

  interface StoredSearchRawDataBase {
    version: number;
  }

  interface StoredSearchRawData extends StoredSearchRawDataBase {
    itemsPerPage: number;
    pageNumber: number;
    order: Order<TaskLog.OrderField>;
    userName: string;
    applicationTypeId?: number;
    taskTypeName: string;
    taskName: string;
    logType: string;
    message: string;
    logTimeFrom?: string;
    logTimeTo?: string;
  }

  export class Storage {

    /**
     * Increment this version if you change the SearchData interface.
     */
    private static readonly VERSION: number = 2;

    private static readonly DEFAULT_SEARCH_DATA: SearchData = {
      itemsPerPage: UiConstants.pageNumbers[0],
      pageNumber: 1,
      order: {
        field: TaskLog.OrderField.LOG_TIME,
        type: OrderType.DESC
      },
      userName: '',
      applicationTypeId: undefined,
      taskTypeName: '',
      taskName: '',
      logType: '',
      message: '',
      logTimeFrom: Dates.emptyLocalDate(),
      logTimeTo: Dates.emptyLocalDate()
    };

    constructor() {
    }

    public read(request: SearchDataGetRequest): SearchDataResult {
      let rawBase: StoredSearchRawDataBase;
      try {
        const key = this.generateKey();
        const json = MemStorage.getItem(key);
        if (!json) {
          return this.createDefaultResult();
        }
        rawBase = JSON.parse(json);
      }
      catch (error) {
        return this.createDefaultResult();
      }
      if (Storage.VERSION === rawBase.version) {
        const raw: StoredSearchRawData = <StoredSearchRawData> rawBase;
        return this.fromRaw(raw);
      }
      return this.createDefaultResult();
    }

    public reset(request: SearchDataGetRequest): SearchDataResult {
      return this.save({
        searchData: Storage.DEFAULT_SEARCH_DATA,
      });
    }

    public save(request: SearchDataSetRequest): SearchDataResult {
      const key = this.generateKey();
      const raw = this.toRaw(request.searchData);
      const json = JSON.stringify(raw);
      MemStorage.setItem(key, json);
      return this.read(request);
    }

    private generateKey(): string {
      return 'UserId(' + LoggedInUserStorage.getInstance().getUserId() + ')-Search-Task-Log';
    }

    private createDefaultResult(): SearchDataResult {
      return {
        searchData: Storage.DEFAULT_SEARCH_DATA,
      };
    }

    private fromRaw(data: StoredSearchRawData): SearchDataResult {
      return {
        searchData: {
          itemsPerPage: data.itemsPerPage,
          pageNumber: data.pageNumber,
          order: data.order,
          userName: data.userName,
          applicationTypeId: data.applicationTypeId,
          taskTypeName: data.taskTypeName,
          taskName: data.taskName,
          logType: data.logType,
          message: data.message,
          logTimeFrom: Services.toLocalDate(data.logTimeFrom),
          logTimeTo: Services.toLocalDate(data.logTimeTo)
        }
      };
    }

    private toRaw(data: SearchData): StoredSearchRawData {
      return {
        version: Storage.VERSION,
        itemsPerPage: data.itemsPerPage,
        pageNumber: data.pageNumber,
        order: data.order,
        userName: data.userName,
        applicationTypeId: data.applicationTypeId,
        taskTypeName: data.taskTypeName,
        taskName: data.taskName,
        logType: data.logType,
        message: data.message,
        logTimeFrom: Services.localDateToString(data.logTimeFrom),
        logTimeTo: Services.localDateToString(data.logTimeTo)
      };
    }

  }

}
