/* eslint-disable */
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Transition, UIRouter } from '@uirouter/angular';
import { StateName } from '../../../../app.state-names';
import {
  listItemParamTypes,
  ListItemResourceService,
  ListItemTypeFieldErrorMap, ListItemTypeFilterType,
  ListItemTypeParam,
  RawListTypeItem
} from '../../../../lib/list-item/list-item-resource.service';
import { FieldError, FieldErrors, ObservableErrorResourceParser } from '../../../../lib/util/errors';
import { ListItemParamTypeItem, ListItemTypeEditModel } from '../../../../util/list-item-utils';
import { RootCoreService } from '../../../../lib/root-core.service';
import { BreadcrumbParent } from '../../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { TranslateService } from '@ngx-translate/core';
import { MultiselectOptionItem, SelectUtils, UiConstants } from '../../../../util/core-utils';
import { StringKey } from '../../../../app.string-keys';
import { ToasterService } from '../../../../fork/angular2-toaster/src/toaster.service';
import { Angular2Multiselects } from '../../../../util/multiselect';
import { Strings } from '../../../../lib/util/strings';
import { NgModel } from '@angular/forms';
import { RightModel } from '../../../../app.rights';
import { RightResolver, RightService } from '../../../../lib/right.service';
import { ListItemMapService } from '../../../../lib/list-item/list-item-map.service';
/* eslint-enable */

@Component({
  selector: 'app-list-item-type-edit',
  templateUrl: 'list-item-type-edit.component.html',
  styleUrls: ['list-item-type-edit.component.css']
})
export class ListItemTypeEditComponent implements OnInit, AfterViewInit {
  SelectUtils = SelectUtils;
  UiConstants = UiConstants;

  @ViewChild('filterType', { static: true })
  filterTypeInput: NgModel;

  model: ListItemTypeEditModel = new ListItemTypeEditModel();
  paramTypes: ListItemParamTypeItem[];
  fieldErrors: ListItemTypeFieldErrorMap;
  listItemId: number;
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  filterTypes: MultiselectOptionItem<number>[] = [];
  dropdownSettings: Angular2Multiselects.Settings;
  localSingleDropdown: Angular2Multiselects.Settings = Angular2Multiselects.LOCAL_SINGLE_SELECT_TRANSLATE;
  rightModel: RightModel = RightModel.empty();

  constructor( private rootService: RootCoreService,
               private listItemService: ListItemResourceService,
               private listItemMapService: ListItemMapService,
               private translateService: TranslateService,
               private uiRouter: UIRouter,
               private rightService: RightService,
               private transition: Transition,
               private toasterService: ToasterService) {
    this.listItemId = this.transition.params().id;
    this.fieldErrors = {};
  }

  ngOnInit() {
    this.initDropdownSettings();
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('MENU_NAVBAR_LIST_ITEM_TYPE').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.LIST_ITEM_TYPE_LIST});
      }
    );
  }

  ngAfterViewInit(): void {
    this.loadParamTypes();
    this.loadModel();
    this.loadRightModels();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
  }

  private loadParamTypes() {
    this.paramTypes = [];
    listItemParamTypes.forEach((type) => {
      const item = {
        id: type.type,
        text: '....'
      };
      this.paramTypes.push(item);
      this.translateService.get(type.stringKey).subscribe((text: string) => {
        item.text = text;
      })
    });
  }

  loadModel() {
    this.listItemService.getType({
      id: this.listItemId
    }).subscribe( (listTypeItem: RawListTypeItem) => {
      this.breadcrumbSelf = listTypeItem.key;
      this.model.key = listTypeItem.key;
      listTypeItem.params.forEach((param) => {
        this.model.params.push({
          key: param.key,
          paramType: param.param_type,
          defaultValue: this.getParamDefaultValue(param)
        })
      });
      this.model._orderField = this.model.selectableOrderFields.filter(i => i.id === listTypeItem.list_item_order_field);
      this.model._orderType = this.model.selectableOrderTypes.filter(i => i.id === listTypeItem.list_item_order_type);
      this.loadFilterTypes(undefined, listTypeItem.filter_type);
    });
  }

  loadFilterTypes(q?: string, filter?: ListItemTypeFilterType) {
    this.listItemService.getTypeList({
      key: q ? Strings.undefinedOrNonEmpty(q) : undefined,
      order: '+key',
      disabled: false,
      page_number: 1,
      number_of_items: UiConstants.autocompletePageSize,
      no_progress_bar: true
    }).subscribe(result => {
      this.filterTypes = result.items.filter(t => t.id !== +this.listItemId).map(t => ({id: t.id, itemName: t.key}));
      if (filter) {
        const f = this.filterTypes.find(t => t.id === filter.id);
        if (f) {
          this.model.filterType.push(f);
        }
        else {
          this.listItemService.getType({
            id: filter.id
          }).subscribe(result => {
            this.model.filterType.push({
              id: result.id,
              itemName: result.key,
              disabled: result.disabled
            });
            this.filterTypeInput.control.updateValueAndValidity();
          });
        }
      }
    });
  }

  private getParamDefaultValue(param: ListItemTypeParam): string | undefined {
    if (param.param_type === 'STRING') {
      return param.default_string_value;
    }
    return param.default_number_value !== undefined ? param.default_number_value.toString() : undefined;
  }

  addNewParam() {
    this.model.params.push({
      key: '',
      paramType: 'STRING',
      defaultValue: ''
    });
  }

  deleteParam(i: number) {
    this.model.params.splice(i, 1);
  }

  back() {
    window.history.back();
  }

  removeFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.fieldErrors, fieldError);
  }

  private getParamsList(): ListItemTypeParam[] {
    const result: ListItemTypeParam[] = [];
    this.model.params.forEach((param) => {
      result.push({
        key: param.key,
        param_type: param.paramType,
        default_string_value: param.paramType === 'STRING' ? param.defaultValue : undefined,
        default_number_value: param.paramType === 'NUMBER' ? <number | undefined> param.defaultValue : undefined
      });
    });
    return result;
  }

  update() {
    for (let i = 0; i < this.model.params.length; i++) {
      if (!this.isParamKeyUnique(this.model.params[i].key, i)) {
        return;
      }
    }
    this.listItemService.updateType({
      id: this.listItemId,
      key: this.model.key,
      params: this.getParamsList(),
      filter_type_id: this.model.filterTypeId,
      list_item_order_type: this.model.orderType!,
      list_item_order_field: this.model.orderField!
    }).subscribe( response => {
      this.listItemMapService.loadMap();
      this.uiRouter.stateService.go(StateName.LIST_ITEM_TYPE_LIST);
    },
      (error: any) => {
        const res = ObservableErrorResourceParser.parseError(error);
        this.fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
        this.toasterService.pop({
          timeout: UiConstants.ToastTimeoutLong,
          type: UiConstants.toastTypeError,
          title: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_TITLE),
          body: this.fieldErrors.params
            ? this.fieldErrors.params.text
            : this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_MESSAGE)
        });
      }
    );
  }

  isParamKeyUnique(key: string, index: number): boolean {
    for (let i = 0; i < this.model.params.length; i++) {
      if (index !== i && this.model.params[i].key === key) {
        return false;
      }
    }
    return true;
  }
}
