<!--gallery container-->
<div class="gallery-container">
  <!--wrapper class for image boxes-->
  <div class="wrap">
    <!--Print all boxes-->
    <div class="box" *ngIf="displayUpload">
      <!--inner div to hold content-->
      <div class="boxInner">
        <!--inner content container-->
        <div class="content-container" style="overflow: hidden">
          <app-file-upload #fileUploader
                           [disabled]="!uploadAllowed"
                           [uploadPath]="loaderFactory.getUploadPath()"
                           [hideQueue]="multiQueue"
                           [uploadOnDrop]="!multiQueue"
                           [uploader]="customFileUploader"
                           (responseContent)="onResponseContentReceived($event)"
                           (onResult)="onAttachmentUploadSuccess($event)"></app-file-upload>
        </div>
      </div>
    </div>
    <div class="p-2 text-center text-muted"
         *ngIf="attachments.length === 0 && fileQueue.length === 0 && !displayUpload">
      <h6>{{ "COMMON_NO_ATTACHMENT" | translate }}</h6>
    </div>

    <div class="box" *ngFor="let image of attachments">
      <!--inner div to hold content-->
      <div class="boxInner">
        <!--inner content container-->
        <div class="content-container">

          <div *ngIf="displayAsImage(image)"
               class="attachment-image-container"
               [ngStyle]="{
              'cursor': 'pointer',
              'background-image': 'url(\'' + getPreviewDataUrl(image) + '\')'
            }"
               (click)="onImageClick(image)">
          </div>

          <div *ngIf="displayAsPlaceholder(image)"
               class="attachment-image-container"
               [ngStyle]="{
                 'background-size': 'contain',
                 'background-color': '#E3E8EC',
                 'background-image': 'url(\'' + image.placeholderImageSrc + '\')'
               }">
          </div>

          <div class="wrapper-button">
            <div class="attachment-button" *ngIf="!!deleteAllowed && hasDeleteRight(image)">
              <a (click)="openConfirmDeleteDialog(image)"
                 title="{{'COMMON_BUTTON_DELETE' | translate}}">
                <i class="icon-standard icomoon icomoon-trash cursor-pointer"></i>
              </a>
            </div>
            <div class="attachment-button">
              <a *ngIf="image.downloading">
                <i class="icon-standard icomoon icomoon-order-state-under-processing cursor-pointer"></i>
              </a>
              <a *ngIf="image.downloaded" (click)="downloadFile(image)"
                 title="{{'COMMON_BUTTON_DOWNLOAD' | translate}}">
                <i class="icon-standard icomoon icomoon-import cursor-pointer" style="cursor: pointer"></i>
              </a>
              <a *ngIf="image.downloadError" (click)="retryDownloadFile(image)">
                <i class="icon-standard icomoon icomoon-process-reopen cursor-pointer" style="cursor: pointer"></i>
              </a>
              <a *ngIf="image.downloadNotStarted" (click)="downloadFileThenSave(image)"
                 title="{{'COMMON_BUTTON_DOWNLOAD' | translate}}">
                <i class="icon-standard icomoon icomoon-import cursor-pointer" style="cursor: pointer"></i>
              </a>
            </div>
          </div>

        </div>
        <!--inner content container-->
        <div class="wrapper-title">
          <a class="attachment-title-label"
             [popover]="attachmentNamePopover" triggers="click" container="body" #attachmentNamePopoverTrigger="bs-popover"
             [ngClass]="{'id-text': updateAllowed, 'cursor-pointer': updateAllowed}">
            {{image.attachment.fullFileName}}
          </a>
          <ng-template #attachmentNamePopover>
            <app-material-attachment-name-edit
              class="pointer-events-auto"
              [currentFileName]="image.attachment.fileName"
              [fileExtension]="image.attachment.fileExtension"
              (onCloseButtonClicked)="updateAttachmentName(image, attachmentNamePopoverTrigger, $event)"
            >
            </app-material-attachment-name-edit>
          </ng-template>
          <div class="attachment-subtitle-label">
            {{image.attachment.fullOriginalFileName}}
          </div>
        </div>
      </div>
      <!--inner div to hold content end-->
    </div>
    <!--Print all boxes end-->
  </div>
  <!--wrapper class for image boxes end-->

  <!--wrapper class for image boxes-->
  <label class="form-control-label"
         *ngIf="multiQueue && fileQueue.length > 0">{{'FORM_FIELD_DOCUMENT_QUEUED_FILES' | translate}}</label>
  <div class="wrap" *ngIf="multiQueue">
    <div class="box" *ngFor="let image of fileQueue">
      <!--inner div to hold content-->
      <div class="boxInner">
        <!--inner content container-->
        <div class="content-container">

          <div appImgPreview [image]="image?._file" class="attachment-image-container"
               [ngStyle]="{
                  'cursor': 'pointer',
                  'background-size': 'cover'
               }"
               *ngIf="displayAsImageQueue(image)"></div>

          <div *ngIf="displayAsPlaceholderQueue(image)"
               class="attachment-image-container"
               [ngStyle]="{
                 'background-size': 'cover',
                 'background-color': '#E3E8EC',
                 'background-image': 'url(\'' + FileTypeModelFactory.DEFAULT.placeholderImageSrc + '\')'
               }">
          </div>
          <div class="wrapper-button">
            <div class="attachment-button" *ngIf="image.isUploading">
              <a>
                <i class="icon-standard icomoon icomoon-order-state-under-processing cursor-pointer"></i>
              </a>
            </div>
            <div class="attachment-button">
              <a (click)="removeFromQueue(image)"
                 title="{{'COMMON_BUTTON_DELETE' | translate}}">
                <i class="icon-standard icomoon icomoon-trash cursor-pointer"></i>
              </a>
            </div>
          </div>
        </div>
        <div class="wrapper-title">
          <div class="attachment-title-label">
            {{image.file.name}}
          </div>
        </div>

      </div>
      <!--inner content container-->
    </div>
    <!--inner div to hold content end-->
  </div>
  <!--Print all boxes end-->
</div>

<!--gallery container end-->
