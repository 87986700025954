export namespace CustomerFilterType {

  export type CustomerFilterType =
    'GLOBAL' |
    'CUSTOMER' |
    'LOCATION';

  export class CustomerFilterTypeObject {
    type: CustomerFilterType;
    stringKey: string;
  }

  export const customerFilterTypes: CustomerFilterTypeObject[] = [
    {type: 'LOCATION', stringKey: 'MASTER_DATA_FILTER_TYPE_LOCATION'},
    {type: 'CUSTOMER', stringKey: 'MASTER_DATA_FILTER_TYPE_CUSTOMER'},
    {type: 'GLOBAL', stringKey: 'MASTER_DATA_FILTER_TYPE_GLOBAL'},
  ];

}
