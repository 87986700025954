<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div class="breadcrumb-menu">
    <!--CREATE-->
    <div *ngIf="rightModel.masterDataCreate.hasRight()"
         class="breadcrumb-button-icon-container cursor-pointer negative-margin-right"
         uiSref="Admin.MasterdataCreate">
      <a class="btn-setting d-flex align-items-center" title="{{'COMMON_BUTTON_ADD' | translate}}">
        <i class="icomoon icomoon-add"></i>
        <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_ADD' | translate}}</span>
      </a>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="card">

    <div class="card-header">
      {{'MASTERDATA_PANEL_HEADING_LIST' | translate}}
      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()" title="{{'COMMON_SHOW_SEARCH_HINT' | translate}}"><i class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>

    <div class="card-body border-bottom" *ngIf="showSearch">
      <div class="row form-group">
        <div class="col-md-3">
          <label class="search-label">{{'MASTERDATA_SEARCH_FIELD_ID' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'MASTERDATA_SEARCH_FIELD_ID' | translate}}"
                 maxlength="{{UiConstants.maxInputLongLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [textMask]="{mask: InputMask.NATURAL_INTEGER, guide: false}"
                 [(ngModel)]="searchModel.id" name="id" id="id" #id="ngModel">
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'COMMON_NAME' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.name" name="name" id="name" #name="ngModel">
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'MASTERDATA_SEARCH_FIELD_EXTERNAL_ID' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'MASTERDATA_SEARCH_FIELD_EXTERNAL_ID' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.externalId" name="externalId" id="externalId" #externalId="ngModel">
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'MASTERDATA_SEARCH_FIELD_DISABLED' | translate}}</label>
          <select class="form-control" [compareWith]="SelectUtils.compareObjects" [(ngModel)]="searchModel.disabled"
                  id="disabled" name="disabled" #disabled="ngModel">
            <option *ngFor="let item of masterdataDisabledItems" [ngValue]="item">{{item.text}}</option>
          </select>
        </div>
      </div>
      <div class="col-md-12 d-flex justify-content-md-end align-items-end px-0">
        <div class="btn-group" role="group">
          <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
            {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
          </button>
          <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
            {{'COMMON_BUTTON_SEARCH' | translate}}
          </button>
        </div>
      </div>
    </div><!-- /.card-body-->
    <div class="card-body">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header hidden-xs-down"><app-table-field-sorter [orderField]="MasterData.OrderField.ID" [orderType]="queryModel.getOrderType(MasterData.OrderField.ID)" (orderChange)="orderBy($event)" [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter></th>
          <th class="table-sorter-header long"><app-table-field-sorter [orderField]="MasterData.OrderField.NAME" [orderType]="queryModel.getOrderType(MasterData.OrderField.NAME)" (orderChange)="orderBy($event)" [text]="'COMMON_NAME' | translate"></app-table-field-sorter></th>
          <th class="table-sorter-header hidden-sm-down"><app-table-sorter-no-op [text]="'MASTERDATA_REQUIRED_ID_FIELD' | translate"></app-table-sorter-no-op></th>
          <th class="table-sorter-header hidden-md-down"><app-table-field-sorter [orderField]="MasterData.OrderField.CREATION_TIME" [orderType]="queryModel.getOrderType(MasterData.OrderField.CREATION_TIME)" (orderChange)="orderBy($event)" [text]="'COMMON_TABLE_HEADER_CREATION_TIME' | translate"></app-table-field-sorter></th>
          <th class="table-sorter-header hidden-md-down"><app-table-field-sorter  [orderField]="MasterData.OrderField.UPDATE_TIME" [orderType]="queryModel.getOrderType(MasterData.OrderField.UPDATE_TIME)" (orderChange)="orderBy($event)" [text]="'COMMON_TABLE_HEADER_UPDATE_TIME' | translate"></app-table-field-sorter></th>
          <th class="table-sorter-header w-table-options-menu"><app-table-sorter-no-op></app-table-sorter-no-op></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let masterData of masterDataList | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }" [disabledTableRow]="masterData.disabled">
          <td class="fit hidden-xs-down align-middle">{{masterData.masterDataId}}</td>
          <td class="responsive-table-column long">
            <div class="d-flex justify-content-start align-items-center">
              <div>
                <a *ngIf="rightModel.masterDataUpdate.hasRight()"
                   class="id-text cursor-pointer"
                   href="#" onclick="return false"
                   uiSref="Admin.MasterdataEdit"
                   [uiParams]="{ id: masterData.masterDataId }"
                   title="{{'COMMON_BUTTON_EDIT' | translate}}">
                  {{masterData.name}}
                </a>
                <span *ngIf="!rightModel.masterDataUpdate.hasRight()">{{masterData.name}}</span>
                <div class="table-text-subtitle">
                  {{masterData.externalId}}
                </div>
              </div>
            </div>
          </td>
          <td class="fit hidden-sm-down align-middle">{{('COMMON_' + masterData.clientRequiredIdField) | translate}}</td>
          <td class="fit hidden-md-down align-middle">{{masterData.creationTime.toUtcIsoString() | date:'short'}}</td>
          <td class="fit hidden-md-down align-middle">{{masterData.updateTime.toUtcIsoString() | date:'short'}}</td>
          <td class="align-middle">
            <app-table-options-menu>
              <app-dropdown-item
                *ngIf="rightModel.masterDataUpdate.hasRight()"
                [iconClass]="'icomoon-modify'"
                [titleStringKey]="'COMMON_BUTTON_EDIT'"
                class="cursor-pointer"
                uiSref="Admin.MasterdataEdit"
                [uiParams]="{ id: masterData.masterDataId }">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="masterData.disabled && rightModel.masterDataDisable.hasRight()"
                [iconClass]="'icomoon-enable'"
                [titleStringKey]="'COMMON_BUTTON_ENABLE'"
                [itemType]="DropdownItemType.ENABLE"
                class="cursor-pointer"
                (click)="setDisabled(masterData.masterDataId, false)">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="!masterData.disabled && rightModel.masterDataDisable.hasRight()"
                [iconClass]="'icomoon-disable'"
                [titleStringKey]="'COMMON_BUTTON_DISABLE'"
                [itemType]="DropdownItemType.DISABLE"
                class="cursor-pointer"
                (click)="disableDialog.showDialog(masterData.masterDataId)">
              </app-dropdown-item>
            </app-table-options-menu>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header hidden-xs-down"><app-table-field-sorter [orderField]="MasterData.OrderField.ID" [orderType]="queryModel.getOrderType(MasterData.OrderField.ID)" (orderChange)="orderBy($event)" [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter></th>
          <th class="table-sorter-header long"><app-table-field-sorter [orderField]="MasterData.OrderField.NAME" [orderType]="queryModel.getOrderType(MasterData.OrderField.NAME)" (orderChange)="orderBy($event)" [text]="'COMMON_NAME' | translate"></app-table-field-sorter></th>
          <th class="table-sorter-header hidden-sm-down"><app-table-sorter-no-op [text]="'MASTERDATA_REQUIRED_ID_FIELD' | translate"></app-table-sorter-no-op></th>
          <th class="table-sorter-header hidden-md-down"><app-table-field-sorter [orderField]="MasterData.OrderField.CREATION_TIME" [orderType]="queryModel.getOrderType(MasterData.OrderField.CREATION_TIME)" (orderChange)="orderBy($event)" [text]="'COMMON_TABLE_HEADER_CREATION_TIME' | translate"></app-table-field-sorter></th>
          <th class="table-sorter-header hidden-md-down"><app-table-field-sorter  [orderField]="MasterData.OrderField.UPDATE_TIME" [orderType]="queryModel.getOrderType(MasterData.OrderField.UPDATE_TIME)" (orderChange)="orderBy($event)" [text]="'COMMON_TABLE_HEADER_UPDATE_TIME' | translate"></app-table-field-sorter></th>
          <th class="table-sorter-header w-table-options-menu"><app-table-sorter-no-op></app-table-sorter-no-op></th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>
  </div>
</div>

<app-confirm-dialog
  #disableDialog
  (onConfirm)="setDisabled($event, true)"
  [title]="'COMMON_WARNING_TITLE' | translate"
  [message]="'MASTER_DATA_LIST_DISABLED_WARNING_MESSAGE' | translate"
  [positiveButton]="'COMMON_YES' | translate"
  [negativeButton]="'COMMON_NO' | translate">
</app-confirm-dialog>
