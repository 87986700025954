<div class="card">

  <div class="d-flex flex-row justify-content-between base-data-header">
    <div class="d-flex flex-row flex-wrap">
      <div class="d-flex flex-row px-1 py-third-quarter align-items-center">
        <!-- todo: correct icon -->
        <i class="icomoon icomoon-calendar pr-half"></i>
        <div>{{baseData.serialNumber}}</div>
      </div>
      <div class="d-flex flex-row px-1 py-third-quarter align-items-center">
        <i class="icomoon icomoon-receipt-alt pr-half"></i>
        <div>{{baseData.demander | translate}}</div>
      </div>
      <div class="d-flex flex-row px-1 py-third-quarter align-items-center">
        <!-- todo: correct icon -->
        <i class="icomoon icomoon-stock pr-half"></i>
        <div>{{baseData.transporter}}</div>
      </div>
      <div class="d-flex flex-row px-1 py-third-quarter align-items-center">
        <i class="icomoon pr-half" [ngClass]="baseData.stateIconClass"></i>
        <div>{{baseData.state | translate}}</div>
      </div>
    </div>
    <div class="d-flex flex-row flex-wrap">
      <div class="border-left d-flex flex-row px-1 py-third-quarter align-items-center cursor-pointer"
           (click)="onOpenBaseDataDetailButtonClicked()">
        <i class="icomoon icomoon-detail pr-half"></i>
        <div>{{'FORM_ITEM_CREATE_DOCUMENT_EXTRA_DATA' | translate}}</div>
      </div>
    </div>
  </div>

</div><!-- /.card -->
