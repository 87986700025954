<div [class.container-custom-padding-outer]="!dashboard">
  <div class="card-custom-padding-complete col-12">
    <div class="card">
      <div class="card-header" [class.pb-0]="dashboard">
        {{(dashboard ? 'HELPDESK_DASHBOARD_BUG_REPORT_LIST' : 'HELPDESK_BUG_REPORT_LIST') | translate}}
        <button type="button" class="btn btn-primary float-right" *ngIf="dashboard"
                uiSref="Helpdesk.BugReportList">
          {{'COMMON_SHOW_ALL' | translate}}
        </button>
      </div>
      <div class="card-body">
        <div class="row form-group" *ngIf="!dashboard">
          <div class="col-md-6 form-group">
            <input type="text" class="form-control w-100"
                   maxlength="{{UiConstants.maxInputLongLength}}"
                   (keyup.enter)="loadList(1)"
                   placeholder="{{'HELPDESK_BUG_REPOST_LIST_SEARCH_QUERY' | translate}}"
                   [(ngModel)]="searchModel.query">
          </div>
          <div class="col-md-2 form-group">
            <angular2-multiselect [data]="states" [settings]="statesDropdownSettings"
                                  class="form-control w-100"
                                  [(ngModel)]="searchModel.states"
                                  [ngModelOptions]="{standalone: true}">
            </angular2-multiselect>
          </div>
          <div class="col-md-4 d-flex justify-content-end">
            <button type="button" class="btn btn-primary mr-05 px-3" (click)="loadList(1)">{{'COMMON_BUTTON_SEARCH' | translate}}</button>
            <button type="button" class="btn btn-secondary font-xl" (click)="onSearchReset()">
              <i class="icomoon icomoon-trash"></i>
            </button>
          </div>
        </div>
        <table class="table table-borderless">
          <thead>
          <tr>
            <th class="table-sorter-header">
              <app-table-sorter-no-op [text]="'HELPDESK_BUG_REPORT_HEADER' | translate"></app-table-sorter-no-op>
            </th>
            <th class="table-sorter-header hidden-xs-down">
              <app-table-sorter-no-op [text]="'HELPDESK_BUG_REPORT_REPORT_TIME' | translate"></app-table-sorter-no-op>
            </th>
            <th class="table-sorter-header hidden-sm-down">
              <app-table-sorter-no-op [text]="'HELPDESK_BUG_REPORT_ASSIGNEE' | translate"></app-table-sorter-no-op>
            </th>
            <th class="table-sorter-header header-center">
              <app-table-sorter-no-op [text]="'HELPDESK_BUG_REPORT_STATE' | translate"></app-table-sorter-no-op>
            </th>
            <th class="table-sorter-header w-table-options-menu">
              <app-table-sorter-no-op></app-table-sorter-no-op>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let model of list | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }">
            <td class="responsive-table-column long">
              <div class="d-flex align-items-center">
                <app-type-icon [icon]="model.taskIcon" class="mr-05"></app-type-icon>
                <div>
                  <div class="task-name">{{model.taskRecord.task.name}}</div>
                  <div class="task-record-name"
                       uiSref="Helpdesk.BugReportDetail"
                       [uiParams]="{taskId: model.taskRecord.taskId, taskRecordId: model.taskRecord.taskRecordId}">
                    {{model.taskRecord.name}}
                  </div>
                  <div class="task-record-description">{{model.taskRecord.description}}</div>
                </div>
              </div>
            </td>
            <td class="fit align-middle hidden-xs-down">
              {{model.taskRecord.creationTime.toUtcIsoString() | date:'short'}}
              <div class="text-truncate table-text-subtitle" *ngIf="model.taskRecord.creatorUser">
                {{model.taskRecord.creatorUser.personName}}
              </div>
            </td>
            <td class="fit align-middle hidden-sm-down">
              <app-assignee-table-cell *ngIf="config.assigneeWithUser"
                [assigneeType]="AssigneeType.USER"
                [user]="model.assignee.assigneeUser"
                [userGroup]="model.assignee.assigneeUserGroup"
                [rightModel]="rightModel"
                [helpdeskMode]="true">
              </app-assignee-table-cell>
              <app-assignee-table-cell *ngIf="config.assigneeWithMobileApp"
                [assigneeType]="AssigneeType.MOBILE"
                [device]="model.assignee.assigneeMobileApp"
                [userGroup]="model.assignee.assigneeUserGroup"
                [rightModel]="rightModel"
                [helpdeskMode]="true">
              </app-assignee-table-cell>
            </td>
            <td class="fit align-middle">
              <app-table-badge
                *ngIf="!model.hasProcess"
                [badgeStyle]="model.stateObject.badgeStyle"
                [text]="model.stateObject.stringKey | translate">
              </app-table-badge>
              <app-table-badge
                *ngIf="model.hasProcess"
                [badgeStyle]="model.helpdeskState.badgeStyle"
                [text]="model.helpdeskState.stringKey | translate">
              </app-table-badge>
            </td>
            <td class="align-middle">
              <app-table-options-menu>
                <app-dropdown-item
                  *ngIf="rightModel.taskRecordUpdate.hasRight() && canModify(model.taskRecord, model.assignee.assigneeUser)"
                  [iconClass]="'icomoon-modify'"
                  [titleStringKey]="'COMMON_BUTTON_EDIT'"
                  class="cursor-pointer"
                  uiSref="Helpdesk.BugReportEdit"
                  [uiParams]="{ taskId: model.taskRecord.taskId, taskRecordId: model.taskRecord.taskRecordId }">
                </app-dropdown-item>
                <app-dropdown-item
                  *ngIf="canValidate(model.taskRecord)"
                  [iconClass]="'icomoon-helpdesk-validate'"
                  [titleStringKey]="'HELPDESK_BUG_REPORT_ACTION_VALIDATE'"
                  class="cursor-pointer"
                  (click)="setBugReportState('FINISHED', model.taskRecord.taskRecordId)">
                </app-dropdown-item>
                <app-dropdown-item
                  *ngIf="canInvalidate(model.taskRecord)"
                  [iconClass]="'icomoon-helpdesk-reject'"
                  [titleStringKey]="'HELPDESK_BUG_REPORT_ACTION_INVALIDATE'"
                  class="cursor-pointer"
                  (click)="setBugReportState('UNSUBMITTED', model.taskRecord.taskRecordId)">
                </app-dropdown-item>
              </app-table-options-menu>
            </td>
          </tr>
          </tbody>
        </table>
        <div *ngIf="list.length === 0" class="p-2 d-flex flex-column align-items-center justify-content-center text-muted">
          <i class="icomoon icomoon-helpdesk-bug-list font-4xl"></i>
          <div class="font-weight-bold font-xl">{{'HELPDESK_DASHBOARD_NO_BUG_REPORTS' | translate}}</div>
        </div>
        <app-table-paging *ngIf="!dashboard"
          [currentNumberOfItems]="queryModel.currentNumberOfItems"
          [totalNumberOfItems]="queryModel.totalNumberOfItems"
          [hideNumberOfItems]="true"
          [itemsPerPage]="queryModel.itemsPerPage"
          (pageChange)="pageChanged($event)"
          (itemsPerPageChange)="itemsPerPageChanged($event)">
        </app-table-paging>
      </div>
    </div>
  </div>
</div>
