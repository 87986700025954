<div class="card">
  <div class="card-header">{{'HELPDESK_DASHBOARD_NEWEST_MESSAGES' | translate}}</div>
  <div class="card-body">
    <div *ngIf="list.length === 0" class="p-2 d-flex flex-column align-items-center justify-content-center text-muted">
      <i class="icomoon icomoon-helpdesk-message font-4xl"></i>
      <div class="font-weight-bold font-xl">{{'HELPDESK_DASHBOARD_NO_MESSAGES' | translate}}</div>
    </div>
    <div *ngFor="let item of list" class="container"
         uiSref="Helpdesk.BugReportDetail"
         [uiParams]="{taskId: item.taskRecord.taskId, taskRecordId: item.taskRecord.taskRecordId, '#': 'messages'}">
      <div class="title-bar">
        <app-type-icon [icon]="item.taskRecord.task.icon"></app-type-icon>
        <div class="names">
          <div class="task-record-name">{{item.taskRecord.name}}</div>
          <div class="task-name">{{item.taskRecord.task.name}}</div>
        </div>
        <app-table-badge
          [badgeStyle]="getStateObject(item.taskRecord.state).badgeStyle"
          [text]="getStateObject(item.taskRecord.state).stringKey | translate">
        </app-table-badge>
      </div>
      <div class="content line-clamp-2">{{item.chatMessage.content}}</div>
      <div class="info-bar">
        <i class="icomoon icomoon-helpdesk-send-message"></i>
        <div class="user">
          {{item.chatMessage.userPersonName}}
        </div>
        <i class="icomoon icomoon-helpdesk-clock"></i>
        <div class="creation-time">
          {{item.chatMessage.creationTime.toUtcIsoString() | date:'short'}}
        </div>
      </div>
    </div>
  </div>
</div>
