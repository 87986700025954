<!--Notification list-->
<div *ngIf="visible">
  <h6 class="p-1 mb-0 flex-shrink-0">{{'NOTIFICATION_SIDEBAR_TITLE' | translate}}</h6>
  <ul class="list-group">
    <li class="list-group-item d-flex justify-content-center border-left-0 border-right-0" *ngIf="notificationList.length === 0">
      <div class="text-center mt-1 mb-1">
        <span class="icomoon icomoon-message-unread empty-list-icon"></span>
        <p class="mb-0 empty-list-text">{{ 'EMPTY_DASHBOARD_NO_NOTIFICATION' | translate}}</p>
      </div>
    </li>
    <ng-container *ngIf="notificationList.length > 0">
      <li class="list-group-item border-left-0 border-right-0" *ngFor="let notification of notificationList"
          (click)="onNotificationSelected(notification)">
        <div class="media w-100 cursor-pointer" #media>
          <span class="align-self-center icomoon list-icon mr-1 float-left {{notification.iconClass}}"></span>
          <div class="media-body">
            <div class="d-flex w-100 justify-content-between">
              <p class="message-heading mb-0">{{ notification.title }} </p>
              <p class="message-date mb-0">{{notification.creationTime.fromNow()}}</p>
            </div>
            <p class="message-text mb-0">{{notification.content}}</p>
          </div>
        </div>
      </li>
    </ng-container>
    <div  *ngIf="!endOfNotificationList" class="d-flex p-1 align-items-center justify-content-center">
      <button type="button" class="btn btn-secondary" (click)="loadPrevious($event)">
        {{'NOTIFICATION_SIDEBAR_PREVIOUS' | translate}}
      </button>
    </div>
  </ul>
</div>
<!--Notification list end-->
