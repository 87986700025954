/* eslint-disable */
import { Injectable } from '@angular/core';
import { List, Map, Set, } from 'immutable';
import { Observable, Observer } from 'rxjs';
import { FieldValidationError, Order, PagingRequest, QueryResult, ResourceQueryResult, Services, } from '../util/services';
import {
  CustomerRecordAttachmentResourceService,
  CustomerRecordBillingInfoResource,
  CustomerRecordBillingInfoResourceService,
  CustomerRecordContactLocationResourceService,
  CustomerRecordContactPersonResourceService,
  CustomerRecordGlobalResourceService,
  CustomerRecordResource,
  CustomerRecordResourceService
} from './customer-record-resource.service';
import { OffsetDateTime } from '../util/dates';
import { FormRecord } from '../form/form-record.service';
import { Form } from '../form/form.service';
import { Address, AddressResource, } from '../address';
import { Customer } from './customer.service';
import { Strings } from '../util/strings';
import { NgbDatePickerParserFormatter } from '../../util/ngb-datepicker';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { CustomerRecordLogResource, CustomerRecordLogResourceService } from './customer-record-log-resource.service';
import { CountMessage, EmptyMessage, IdentityMessage } from '../util/messages';
import { ObservableErrorResourceParser } from '../util/errors';
import { HistoryLog } from '../history-log/history-log.service';
import { UserItem, UserItemResource } from '../user.service';
import { DownloadedFile, FileNameBuilder, NamedBlobFile, NamedBlobFileDecorator } from '../util/downloaded-files';
import { FileAttachment, FileAttachmentResource, FileAttachments, FileAttachmentUpdateRequest } from '../util/file-attachments';
import { map } from 'rxjs/operators';
import { CustomerRecordFieldType } from '../../util/customer-record-utils';
import { LocalizedTypeObject } from '../../util/core-utils';
import { DqlQuery } from '../query/field';
import { LedgerNumber } from '../ledger/number/ledger-number.service';
import CustomerRecordHistoryType = CustomerRecord.CustomerRecordHistoryType;
import {
  CustomerRecordBillingInfoModel
} from '../../admin/customer/customer-record-billing-info/customer-record-billing-info-base/customer-record-billing-info-base.component';

/* eslint-enable */

@Injectable()
export class CustomerRecordService implements CustomerRecord.Service {

  private formRecordMapper: FormRecord.ResourceMapper;

  constructor(private resourceService: CustomerRecordResourceService,
              private logResourceService: CustomerRecordLogResourceService,
              private globalResourceService: CustomerRecordGlobalResourceService,
              private locationResourceService: CustomerRecordContactLocationResourceService,
              private personResourceService: CustomerRecordContactPersonResourceService,
              private attachmentResourceService: CustomerRecordAttachmentResourceService,
              private billingInfoResourceService: CustomerRecordBillingInfoResourceService,
              private ngbDatePickerParserFormatter: NgbDatePickerParserFormatter) {
    this.formRecordMapper = new FormRecord.ResourceMapper(new Form.ResourceMapper());
  }

  // <editor-fold desc="CRUD">

  query(request: CustomerRecord.QueryRequest): Observable<QueryResult<CustomerRecord.CustomerRecord>> {
    return Observable.create((observer: Observer<QueryResult<CustomerRecord.CustomerRecord>>) => {
      const resourceRequest = this.toResourceQueryRequest(request);
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<CustomerRecordResource.CustomerRecord>) => {
          observer.next({
            items: this.toPublicList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  globalQuery(request: CustomerRecord.GlobalQueryRequest): Observable<QueryResult<CustomerRecord.CustomerRecord>> {
    return Observable.create((observer: Observer<QueryResult<CustomerRecord.CustomerRecord>>) => {
      const resourceRequest: CustomerRecordResource.GlobalQueryRequest = this.toResourceGlobalQueryRequest(request);
      return this.globalResourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<CustomerRecordResource.CustomerRecord>) => {
          observer.next({
            items: this.toPublicList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  listQuery(request: CustomerRecord.GlobalQueryRequest): Observable<CustomerRecord.CustomerRecord[]> {
    return Observable.create((observer: Observer<CustomerRecord.CustomerRecord[]>) => {
      if (!request.customerRecordIdSet || request.customerRecordIdSet.size === 0) {
        observer.next([]);
      }
      else {
        const resourceRequest: CustomerRecordResource.GlobalQueryRequest = {
          fields: request.fields ? request.fields.join(',') : undefined,
          with_form_record: this.toResourceArgumentWithFormRecord(request.withFormRecord),
          parent_disabled: request.parentDisabled,
          id: Services.createIdParameter(request.customerRecordIdSet),
          disabled: request.disabled,
          name: request.name,
          comment: request.comment,
          email_address: request.emailAddress,
          postal_address_zip_code: request.postalAddressZipCode,
          postal_address_city: request.postalAddressCity,
          postal_address_address: request.postalAddressStreet,
          postal_address_house_number: request.postalAddressHouseNumber,
          owner_group_ids: Services.createIdParameter(request.ownerUserGroupIds),
          owner_user_ids: Services.createIdParameter(request.ownerUserIds),
          q: request.queryText,
          order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
          page_number: request.paging ? request.paging.pageNumber : undefined,
          number_of_items: request.paging ? request.paging.numberOfItems : undefined,
        };
        return this.globalResourceService.query(resourceRequest).subscribe(
          (result: ResourceQueryResult<CustomerRecordResource.CustomerRecord>) => {
            observer.next(
              this.toPublicList(result.items).toArray()
            );
          },
          (error: Error) => {
            observer.error(this.translateError(error));
          },
          () => {
            observer.complete();
          });
      }
    });
  }

  globalCount(request: CustomerRecord.GlobalQueryRequest): Observable<number> {
    return Observable.create((observer: Observer<number>) => {
      const resourceRequest: CustomerRecordResource.GlobalQueryRequest = {
        fields: request.fields ? request.fields.join(',') : undefined,
        with_form_record: this.toResourceArgumentWithFormRecord(request.withFormRecord),
        parent_disabled: request.parentDisabled,
        id: Services.createIdParameter(request.customerRecordIdSet),
        disabled: request.disabled,
        name: request.name,
        comment: request.comment,
        email_address: request.emailAddress,
        postal_address_zip_code: request.postalAddressZipCode,
        postal_address_city: request.postalAddressCity,
        postal_address_address: request.postalAddressStreet,
        postal_address_house_number: request.postalAddressHouseNumber,
        owner_group_ids: Services.createIdParameter(request.ownerUserGroupIds),
        owner_user_ids: Services.createIdParameter(request.ownerUserIds),
        q: request.queryText,
        order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
      };
      return this.globalResourceService.count(resourceRequest).subscribe(
        (result: CountMessage) => {
          observer.next(result.current_number_of_items);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  get(request: CustomerRecord.GetRequest): Observable<CustomerRecord.CustomerRecord> {
    return Observable.create((observer: Observer<CustomerRecord.CustomerRecord>) => {
      const resourceRequest: CustomerRecordResource.GetRequest = {
        fields: request.fields ? request.fields.join(',') : undefined,
        with_form_record: this.toResourceArgumentWithFormRecord(request.withFormRecord),
        customer_id: request.customerId,
        customer_record_id: request.customerRecordId,
        rights: DqlQuery.RightRequestSerializer.getInstance().serialize(request.rights),
      };
      return this.resourceService.get(resourceRequest).subscribe(
        (result: CustomerRecordResource.CustomerRecord) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  create(request: CustomerRecord.CreateRequest): Observable<CustomerRecord.CustomerRecord> {
    return Observable.create((observer: Observer<CustomerRecord.CustomerRecord>) => {
      const resourceRequest: CustomerRecordResource.CreateRequest = {
        with_form_record: this.toResourceArgumentWithFormRecord(request.withFormRecord),
        customer_id: request.customerId,
        external_id: Strings.undefinedOrNonEmpty(request.externalId),
        name: Strings.undefinedOrNonEmpty(request.name),
        first_name: Strings.undefinedOrNonEmpty(request.firstName),
        last_name: Strings.undefinedOrNonEmpty(request.lastName),
        comment: Strings.undefinedOrNonEmpty(request.comment),
        position: Strings.undefinedOrNonEmpty(request.position),
        web_address: Strings.undefinedOrNonEmpty(request.webAddress),
        tax_number: Strings.undefinedOrNonEmpty(request.taxNumber),
        eu_tax_number: Strings.undefinedOrNonEmpty(request.euTaxNumber),
        owner_user_ids: request.ownerUserIds.toArray(),
        owner_group_ids: request.ownerGroupIds.toArray(),
        company_id: request.companyId,
        postal_address: AddressResource.Mapper.fromPublicPostalAddressOpt(request.postalAddress),
        notification_address: AddressResource.Mapper.fromPublicPostalAddressOpt(request.notificationAddress),
        notification_address_eq_postal: request.notificationAddressEqPostal,
        email_addresses: AddressResource.Mapper.fromPublicEmailAddressList(request.emailAddresses),
        phone_numbers: AddressResource.Mapper.fromPublicPhoneNumberList(request.phoneNumbers),
        form_record: this.formRecordMapper.toResourceCreateRequest(request.formRecord),
        parent_id: request.parentId,
        invoice_deadline_additional_days: request.invoiceDeadlineAdditionalDays,
        task_record_deadline_additional_days: request.taskRecordDeadlineAdditionalDays,
        ledger_number: request.ledgerNumber,
        main_contract_number: Strings.undefinedOrNonEmpty(request.mainContractNumber),
        // PERSON type
        place_of_birth: Strings.undefinedOrNonEmpty(request.placeOfBirth),
        date_of_birth: Services.localDateToString(this.ngbDatePickerParserFormatter.toLocalDate(request.dateOfBirth)),
        birth_name: Strings.undefinedOrNonEmpty(request.birthName),
        gender: Strings.undefinedOrNonEmpty(request.gender),
        mothers_name: Strings.undefinedOrNonEmpty(request.mothersName),
        identity_document_type: Strings.undefinedOrNonEmpty(request.identityDocumentType),
        identity_document_number: Strings.undefinedOrNonEmpty(request.identityDocumentNumber),
        billing_info: request.billingInfo ? {
          customer_record_id: request.billingInfo.customerRecordId,
          customer_id: request.billingInfo.customerId,
          name: request.billingInfo.name,
          tax_number: request.billingInfo.taxNumber,
          eu_tax_number: request.billingInfo.euTaxNumber,
          bank_account: request.billingInfo.bankAccount,
          invoice_vat_status: request.billingInfo.invoiceVatStatus,
          invoice_address: AddressResource.Mapper.fromPublicPostalAddress(request.billingInfo.invoiceAddress)
        } : undefined
      };
      return this.resourceService.create(resourceRequest).subscribe(
        (result: CustomerRecordResource.CustomerRecord) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  quickCreate(request: CustomerRecord.QuickCreateRequest): Observable<CustomerRecord.CustomerRecord> {
    return Observable.create((observer: Observer<CustomerRecord.CustomerRecord>) => {
      const resourceRequest: CustomerRecordResource.QuickCreateRequest = {
        with_form_record: this.toResourceArgumentWithFormRecord(request.withFormRecord),
        customer_id: request.customerId,
        external_id: Strings.undefinedOrNonEmpty(request.externalId),
        name: Strings.undefinedOrNonEmpty(request.name),
        first_name: Strings.undefinedOrNonEmpty(request.firstName),
        last_name: Strings.undefinedOrNonEmpty(request.lastName),
        comment: Strings.undefinedOrNonEmpty(request.comment),
        position: Strings.undefinedOrNonEmpty(request.position),
        web_address: Strings.undefinedOrNonEmpty(request.webAddress),
        tax_number: Strings.undefinedOrNonEmpty(request.taxNumber),
        eu_tax_number: Strings.undefinedOrNonEmpty(request.euTaxNumber),
        owner_user_ids: request.ownerUserIds.toArray(),
        owner_group_ids: request.ownerGroupIds.toArray(),
        company_id: request.companyId,
        postal_address: AddressResource.Mapper.fromPublicPostalAddressOpt(request.postalAddress),
        notification_address: AddressResource.Mapper.fromPublicPostalAddressOpt(request.notificationAddress),
        notification_address_eq_postal: request.notificationAddressEqPostal,
        email_addresses: AddressResource.Mapper.fromPublicEmailAddressList(request.emailAddresses),
        phone_numbers: AddressResource.Mapper.fromPublicPhoneNumberList(request.phoneNumbers),
        parent_id: request.parentId,
        invoice_deadline_additional_days: request.invoiceDeadlineAdditionalDays,
        task_record_deadline_additional_days: request.taskRecordDeadlineAdditionalDays,
        ledger_number: request.ledgerNumber,
        main_contract_number: Strings.undefinedOrNonEmpty(request.mainContractNumber),
        // PERSON type
        place_of_birth: Strings.undefinedOrNonEmpty(request.placeOfBirth),
        date_of_birth: Services.localDateToString(this.ngbDatePickerParserFormatter.toLocalDate(request.dateOfBirth)),
        birth_name: Strings.undefinedOrNonEmpty(request.birthName),
        gender: Strings.undefinedOrNonEmpty(request.gender),
        mothers_name: Strings.undefinedOrNonEmpty(request.mothersName),
        identity_document_type: Strings.undefinedOrNonEmpty(request.identityDocumentType),
        identity_document_number: Strings.undefinedOrNonEmpty(request.identityDocumentNumber),
        rights: DqlQuery.RightRequestSerializer.getInstance().serialize(request.rights),
      };
      return this.resourceService.quickCreate(resourceRequest).subscribe(
        (result: CustomerRecordResource.CustomerRecord) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  update(request: CustomerRecord.UpdateRequest): Observable<CustomerRecord.CustomerRecord> {
    return Observable.create((observer: Observer<CustomerRecord.CustomerRecord>) => {
      const resourceRequest: CustomerRecordResource.UpdateRequest = {
        with_form_record: this.toResourceArgumentWithFormRecord(request.withFormRecord),
        customer_id: request.customerId,
        customer_record_id: request.customerRecordId,
        external_id: Strings.undefinedOrNonEmpty(request.externalId),
        name: Strings.undefinedOrNonEmpty(request.name),
        first_name: Strings.undefinedOrNonEmpty(request.firstName),
        last_name: Strings.undefinedOrNonEmpty(request.lastName),
        comment: Strings.undefinedOrNonEmpty(request.comment),
        position: Strings.undefinedOrNonEmpty(request.position),
        web_address: Strings.undefinedOrNonEmpty(request.webAddress),
        tax_number: Strings.undefinedOrNonEmpty(request.taxNumber),
        eu_tax_number: Strings.undefinedOrNonEmpty(request.euTaxNumber),
        owner_user_ids: request.ownerUserIds.toArray(),
        owner_group_ids: request.ownerGroupIds.toArray(),
        company_id: request.companyId,
        postal_address: AddressResource.Mapper.fromPublicPostalAddressOpt(request.postalAddress),
        notification_address: AddressResource.Mapper.fromPublicPostalAddressOpt(request.notificationAddress),
        notification_address_eq_postal: request.notificationAddressEqPostal,
        email_addresses: AddressResource.Mapper.fromPublicEmailAddressList(request.emailAddresses),
        phone_numbers: AddressResource.Mapper.fromPublicPhoneNumberList(request.phoneNumbers),
        form_record: this.formRecordMapper.toResourceUpdateRequest(request.formRecord),
        parent_id: request.parentId,
        invoice_deadline_additional_days: request.invoiceDeadlineAdditionalDays,
        task_record_deadline_additional_days: request.taskRecordDeadlineAdditionalDays,
        ledger_number: request.ledgerNumber,
        main_contract_number: Strings.undefinedOrNonEmpty(request.mainContractNumber),
        // PERSON type
        place_of_birth: Strings.undefinedOrNonEmpty(request.placeOfBirth),
        date_of_birth: Services.localDateToString(this.ngbDatePickerParserFormatter.toLocalDate(request.dateOfBirth)),
        birth_name: Strings.undefinedOrNonEmpty(request.birthName),
        gender: Strings.undefinedOrNonEmpty(request.gender),
        mothers_name: Strings.undefinedOrNonEmpty(request.mothersName),
        identity_document_type: Strings.undefinedOrNonEmpty(request.identityDocumentType),
        identity_document_number: Strings.undefinedOrNonEmpty(request.identityDocumentNumber),
      };
      return this.resourceService.update(resourceRequest).subscribe(
        (result: CustomerRecordResource.CustomerRecord) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  setDisabled(request: CustomerRecord.DisableRequest): Observable<CustomerRecord.CustomerRecord> {
    return Observable.create((observer: Observer<CustomerRecord.CustomerRecord>) => {
      const resourceRequest: CustomerRecordResource.DisableRequest = {
        with_form_record: this.toResourceArgumentWithFormRecord(request.withFormRecord),
        customer_id: request.customerId,
        customer_record_id: request.customerRecordId,
        disabled: request.disabled
      };
      return this.resourceService.setDisabled(resourceRequest).subscribe(
        (result: CustomerRecordResource.CustomerRecord) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  addContactPersons(request: CustomerRecord.ContactPersonsRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: CustomerRecordResource.ContactPersonsRequest = {
        customer_id: request.customerId,
        customer_record_id: request.customerRecordId,
        contact_person_ids: request.contactPersonIds.toArray()
      };
      return this.resourceService.addContactPersons(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  removeContactPerson(request: CustomerRecord.ContactPersonRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: CustomerRecordResource.ContactPersonRequest = {
        customer_id: request.customerId,
        customer_record_id: request.customerRecordId,
        contact_person_id: request.contactPersonId
      };
      return this.resourceService.removeContactPerson(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  changeOwners(request: CustomerRecord.OwnerChangeRequest): Observable<EmptyMessage> {
    return this.globalResourceService.changeOwners({
      customer_record_ids: request.customerRecordIds.toArray(),
      owner_user_ids: request.ownerUserIds ? request.ownerUserIds.toArray() : undefined,
      owner_group_ids: request.ownerUserGroupIds ? request.ownerUserGroupIds.toArray() : undefined,
      change_type: request.changeType
    });
  }

  history(request: CustomerRecord.HistoryRequest): Observable<QueryResult<CustomerRecord.HistoryItem>> {
    return Observable.create((observer: Observer<QueryResult<CustomerRecord.HistoryItem>>) => {
      const resourceRequest: CustomerRecordLogResource.HistoryRequest = {
        customer_id: request.customerId,
        customer_record_id: request.customerRecordId,
        with_read: request.withRead,
        q: request.queryText,
        order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
      };
      return this.logResourceService.history(resourceRequest).subscribe(
        (result: ResourceQueryResult<CustomerRecordLogResource.HistoryItem>) => {
          observer.next({
            items: this.toPublicHistoryList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });

    });
  }

  exportXls(request: CustomerRecord.QueryRequest): Observable<DownloadedFile> {
    const resourceRequest: CustomerRecordResource.QueryRequest = this.toResourceQueryRequest(request);
    return this.resourceService.exportXls(resourceRequest);
  }

  exportXlsTemplate(request: IdentityMessage): Observable<DownloadedFile> {
    return this.resourceService.exportXlsTemplate(request);
  }

  queryContactLocations(request: CustomerRecordContactLocation.QueryRequest): Observable<QueryResult<Address.ContactLocation>> {
    return Observable.create((observer: Observer<QueryResult<Address.ContactLocation>>) => {
      return this.locationResourceService.query({
        customer_id: request.customerId,
        customer_record_id: request.customerRecordId,
        contract_number_ids: Services.createListParameter(request.contractNumberIds),
        ids: Services.createListParameter(request.ids),
        order: Services.createOrderFieldParameter(Keys.toLocationOrderFieldKey, request.orders),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
        no_progress_bar: request.noProgressBar
      }).subscribe((result: ResourceQueryResult<AddressResource.ContactLocationResource>) => {
          observer.next({
            items: AddressResource.Mapper.toPublicContactLocationList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  createContactLocation(request: CustomerRecordContactLocation.CreateRequest): Observable<IdentityMessage> {
    return Observable.create((observer: Observer<IdentityMessage>) => {
      return this.locationResourceService.create({
        customer_id: request.customerId,
        customer_record_id: request.customerRecordId,
        name: request.name,
        external_id: request.externalId,
        opening_times: request.openingTimes,
        comment: request.comment,
        contract_number: request.contractNumber,
        postal_address: AddressResource.Mapper.fromPublicPostalAddressOpt(request.postalAddress)!,
        contact_persons: request.contactPersons ? request.contactPersons.toArray() : []
      }).subscribe((result: IdentityMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  updateContactLocation(request: CustomerRecordContactLocation.UpdateRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.locationResourceService.update({
        customer_id: request.customerId,
        customer_record_id: request.customerRecordId,
        id: request.id,
        name: request.name,
        external_id: request.externalId,
        opening_times: request.openingTimes,
        comment: request.comment,
        contract_number: request.contractNumber,
        postal_address: AddressResource.Mapper.fromPublicPostalAddressOpt(request.postalAddress)!,
        contact_persons: request.contactPersons ? request.contactPersons.toArray() : []
      }).subscribe((result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  deleteContactLocation(request: CustomerRecordContactLocation.DeleteRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.locationResourceService.delete({
        id: request.id,
        customer_id: request.customerId,
        customer_record_id: request.customerRecordId
      }).subscribe((result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  downloadContactLocationsTemplate(request: EmptyMessage): Observable<DownloadedFile> {
    return this.locationResourceService.downloadTemplate({});
  }

  updateContactLocationsContactPerson(request: CustomerRecord.ContactLocationContactPersonChangeRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.globalResourceService.changeContactLocationsConactPerson({
        customer_record_id: request.customerRecordId,
        contact_person_customer_record_id: request.contactPersonCustomerRecordId,
        related_contact_location_id: request.relatedContactLocationId
      }).subscribe((result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  queryContactPersons(request: CustomerRecordContactPerson.QueryRequest): Observable<QueryResult<CustomerRecord.CustomerRecord>> {
    return Observable.create((observer: Observer<QueryResult<CustomerRecord.CustomerRecord>>) => {
      return this.personResourceService.query({
        customer_id: request.customerId,
        customer_record_id: request.customerRecordId,
        order: Services.createOrderFieldParameter(Keys.toLocationOrderFieldKey, request.orders),
        fields: Services.createListParameter(request.fields),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
        no_progress_bar: request.noProgressBar
      }).subscribe((result: ResourceQueryResult<CustomerRecordResource.CustomerRecord>) => {
          observer.next({
            items: this.toPublicList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  getAttachments(request: CustomerRecord.QueryAttachmentRequest): Observable<FileAttachment[]> {
    return Observable.create((observer: Observer<FileAttachment[]>) => {
      const resourceRequest: CustomerRecordResource.QueryAttachmentRequest = {
        customer_id: request.customerId,
        customer_record_id: request.customerRecordId
      };
      this.attachmentResourceService.getAttachments(resourceRequest).subscribe(
        (result: FileAttachmentResource[]) => {
          observer.next(FileAttachments.toPublicList(result));
        });
    });
  }

  downloadAttachment(request: CustomerRecord.DownloadAttachmentRequest): Observable<NamedBlobFile> {
    const fileName = new FileNameBuilder()
      .addString(request.attachment.fullFileName)
      .addOffsetDateTime(Services.toOffsetDateTime(request.attachment.contentUpdateTime))
      .build();
    return this.attachmentResourceService.downloadAttachment({
      customer_id: request.customerId,
      customer_record_id: request.customerRecordId,
      file_id: request.attachment.id
    }).pipe(map((file: DownloadedFile) => {
      return new NamedBlobFileDecorator(file, fileName);
    }));
  }

  downloadAttachmentThumbnail(request: CustomerRecord.DownloadAttachmentRequest): Observable<NamedBlobFile> {
    const fileName = new FileNameBuilder()
      .addString(request.attachment.fullFileName)
      .addOffsetDateTime(Services.toOffsetDateTime(request.attachment.contentUpdateTime))
      .build();
    return this.attachmentResourceService.downloadAttachment({
      customer_id: request.customerId,
      customer_record_id: request.customerRecordId,
      file_id: request.attachment.id,
      thumbnail: true
    }).pipe(map((file: DownloadedFile) => {
      return new NamedBlobFileDecorator(file, fileName);
    }));
  }

  deleteAttachment(request: CustomerRecord.AttachmentRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.attachmentResourceService.deleteAttachment({
        customer_record_id: request.customerRecordId,
        customer_id: request.customerId,
        file_id: request.fileId,
      }).subscribe((result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });

    });
  }

  updateAttachment(request: CustomerRecord.UpdateAttachmentRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.attachmentResourceService.updateAttachment({
        customer_record_id: request.customerRecordId,
        customer_id: request.customerId,
        file_id: request.fileId,
        name: request.name
      }).subscribe((result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });

    });
  }

  inviteToHelpdesk(request: CustomerRecord.HelpdeskInviteRequest): Observable<CustomerRecord.HelpdeskInviteResponse> {
    return Observable.create((observer: Observer<CustomerRecord.HelpdeskInviteResponse>) => {
      return this.globalResourceService.inviteToHelpdesk({
        customer_record_ids: request.customerRecordIds.toArray(),
      }).subscribe((r: CustomerRecordResource.HelpdeskInviteResponse) => {
          observer.next({
            invalidEmail: Set.of(...this.toPublicList(r.invalid_email).toArray()),
            invited: Set.of(...this.toPublicList(r.invited).toArray()),
            alreadyRegistered: Set.of(...this.toPublicList(r.already_registered).toArray())
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });

    });
  }

  disableHelpdeskUser(request: CustomerRecord.HelpdeskInviteRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.globalResourceService.disableHelpdeskUser({
        customer_record_ids: request.customerRecordIds.toArray()
      }).subscribe((result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });

    });
  }

  enableHelpdeskUser(request: CustomerRecord.HelpdeskInviteRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.globalResourceService.enableHelpdeskUser({
        customer_record_ids: request.customerRecordIds.toArray()
      }).subscribe((result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });

    });
  }

  getHelpdeskRegistrationData(request: CustomerRecord.HelpdeskRegistrationDataRequest):
    Observable<CustomerRecord.HelpdeskRegistrationDataResponse> {
    return Observable.create((observer: Observer<CustomerRecord.HelpdeskRegistrationDataResponse>) => {
      return this.globalResourceService.getHelpdeskRegistrationData({
        helpdesk_token: request.helpdeskToken
      }).subscribe((result: CustomerRecordResource.HelpdeskRegistrationDataResponse) => {
          observer.next({
            id: result.id,
            name: result.name,
            emailAddress: result.email_address,
            customerNames: result.customer_names
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });

    });
  }

  listBillingInfo(request: CustomerRecord.BillingInfoReadRequest): Observable<CustomerRecord.BillingInfo[]> {
    return Observable.create((observer: Observer<CustomerRecord.BillingInfo[]>) => {
      return this.billingInfoResourceService.readList({
        customer_record_id: request.customerRecordId,
        customer_id: request.customerId,
        disabled: request.disabled
      }).subscribe((result: CustomerRecordBillingInfoResource.BillingInfo[]) => {
          observer.next(result.map(i => CustomerRecordService.toPublicBillingInfo(i)));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });

    });
  }

  readBillingInfo(request: CustomerRecord.BillingInfoReadOneRequest): Observable<CustomerRecord.BillingInfo> {
    return Observable.create((observer: Observer<CustomerRecord.BillingInfo>) => {
      return this.billingInfoResourceService.readOne({
        customer_record_id: request.customerRecordId,
        customer_id: request.customerId,
        id: request.id
      }).subscribe((result: CustomerRecordBillingInfoResource.BillingInfo) => {
          observer.next(CustomerRecordService.toPublicBillingInfo(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });

    });
  }

  createBillingInfo(request: CustomerRecord.BillingInfoCreateRequest): Observable<CustomerRecord.BillingInfo> {
    return Observable.create((observer: Observer<CustomerRecord.BillingInfo>) => {
      return this.billingInfoResourceService.create({
        customer_record_id: request.customerRecordId,
        customer_id: request.customerId,
        name: request.name,
        tax_number: request.taxNumber,
        eu_tax_number: request.euTaxNumber,
        bank_account: request.bankAccount,
        invoice_vat_status: request.invoiceVatStatus,
        invoice_address: AddressResource.Mapper.fromPublicPostalAddress(request.invoiceAddress)
      }).subscribe((result: CustomerRecordBillingInfoResource.BillingInfo) => {
          observer.next(CustomerRecordService.toPublicBillingInfo(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });

    });
  }

  updateBillingInfo(request: CustomerRecord.BillingInfoUpdateRequest): Observable<CustomerRecord.BillingInfo> {
    return Observable.create((observer: Observer<CustomerRecord.BillingInfo>) => {
      return this.billingInfoResourceService.update({
        customer_record_id: request.customerRecordId,
        customer_id: request.customerId,
        id: request.id,
        name: request.name,
        tax_number: request.taxNumber,
        eu_tax_number: request.euTaxNumber,
        bank_account: request.bankAccount,
        invoice_vat_status: request.invoiceVatStatus,
        invoice_address: AddressResource.Mapper.fromPublicPostalAddress(request.invoiceAddress)
      }).subscribe((result: CustomerRecordBillingInfoResource.BillingInfo) => {
          observer.next(CustomerRecordService.toPublicBillingInfo(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });

    });
  }

  setBillingInfoDisabled(request: CustomerRecord.BillingInfoDisableRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.billingInfoResourceService.setDisabled({
        customer_record_id: request.customerRecordId,
        customer_id: request.customerId,
        id: request.id,
        disabled: request.disabled
      }).subscribe((result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });

    });
  }

  toResourceGlobalQueryRequest(request: CustomerRecord.GlobalQueryRequest): CustomerRecordResource.GlobalQueryRequest {
    return {
      fields: request.fields ? request.fields.join(',') : undefined,
      rights: DqlQuery.RightRequestSerializer.getInstance().serialize(request.rights),
      with_form_record: this.toResourceArgumentWithFormRecord(request.withFormRecord),
      parent_disabled: request.parentDisabled,
      contact_person: request.contactPerson,
      contact_person_type: request.contactPersonType,
      contact_person_id: request.contactPersonId,
      id: Services.createIdParameter(request.customerRecordIdSet),
      excluded_customer_record_ids: Services.createIdParameter(request.excludedCustomerRecordIds),
      disabled: request.disabled,
      name: request.name,
      external_id: request.externalId,
      comment: request.comment,
      customer_types: request.customerTypes ? request.customerTypes.join(',') : undefined,
      customer_ids: request.customerIds ? Services.createListParameter(request.customerIds) : undefined,
      email_address: request.emailAddress,
      phone_number: request.phoneNumber,
      postal_address_zip_code: request.postalAddressZipCode,
      postal_address_city: request.postalAddressCity,
      postal_address_address: request.postalAddressStreet,
      postal_address_house_number: request.postalAddressHouseNumber,
      owner_group_ids: Services.createIdParameter(request.ownerUserGroupIds),
      owner_user_ids: Services.createIdParameter(request.ownerUserIds),
      contract_number_id: Services.createListParameter(request.contractNumberId),
      parent_id: request.parentId,
      q: request.queryText,
      dql: request.dqlText,
      order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
      page_number: request.paging ? request.paging.pageNumber : undefined,
      number_of_items: request.paging ? request.paging.numberOfItems : undefined,
      no_progress_bar: request.noProgressBar
    };
  }

  toResourceQueryRequest(request: CustomerRecord.QueryRequest): CustomerRecordResource.QueryRequest {
    return {
      customer_id: request.customerId,
      fields: request.fields ? request.fields.join(',') : undefined,
      rights: DqlQuery.RightRequestSerializer.getInstance().serialize(request.rights),
      with_form_record: this.toResourceArgumentWithFormRecord(request.withFormRecord),
      contact_person: request.contactPerson,
      contact_person_type: request.contactPersonType,
      contact_person_id: request.contactPersonId,
      id: Services.createIdParameter(request.customerRecordIdSet),
      disabled: request.disabled,
      name: request.name,
      external_id: request.externalId,
      comment: request.comment,
      email_address: request.emailAddress,
      phone_number: request.phoneNumber,
      postal_address_zip_code: request.postalAddressZipCode,
      postal_address_city: request.postalAddressCity,
      postal_address_address: request.postalAddressStreet,
      postal_address_house_number: request.postalAddressHouseNumber,
      owner_group_ids: Services.createIdParameter(request.ownerUserGroupIds),
      owner_user_ids: Services.createIdParameter(request.ownerUserIds),
      owner_customer_record_id: Services.createIdParameter(request.ownerCustomerRecordId),
      owner_contact_location_id: Services.createIdParameter(request.ownerContactLocationId),
      contract_number_id: Services.createListParameter(request.contractNumberId),
      q: request.queryText,
      dql: request.dqlText,
      order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
      page_number: request.paging ? request.paging.pageNumber : undefined,
      number_of_items: request.paging ? request.paging.numberOfItems : undefined,
      no_progress_bar: request.noProgressBar
    };
  }

  private toPublicHistoryList(resourceList: CustomerRecordLogResource.HistoryItem[]): List<CustomerRecord.HistoryItem> {
    return List.of(...resourceList.map((r) => this.toPublicHistory(r)));
  }

  private toPublicHistory(r: CustomerRecordLogResource.HistoryItem): CustomerRecord.HistoryItem {
    return {
      id: r.id,
      creationTime: Services.toOffsetDateTime(r.creation_time),
      type: <CustomerRecordHistoryType>r.type,
      changeLog: r.change_log,
      applicationClassification: r.application_classification,
      user: this.toPublicUser(r.user),
      mobileApplication: this.toPublicMobileApplication(r.mobile_application)
    };
  }

  private toPublicUser(r: UserItemResource): UserItem {
    return {
      id: r.id,
      personName: r.person_name
    };
  }

  private toPublicMobileApplication(r: HistoryLog.MobileApplicationItemResource | undefined):
    HistoryLog.MobileApplicationItem | undefined {
    if (r) {
      return {
        id: r.id,
        applicationId: r.application_id
      };
    }
    return undefined;
  }

  // </editor-fold>

  // <editor-fold desc="Internal">

  private toResourceArgumentWithFormRecord(withFormRecord?: boolean): boolean {
    return withFormRecord === undefined ? false : withFormRecord;
  }

  private toPublicList(resourceList: CustomerRecordResource.CustomerRecord[]): List<CustomerRecord.CustomerRecord> {
    return List.of(...resourceList.map((r) => this.toPublic(r)));
  }

  private toPublic(r: CustomerRecordResource.CustomerRecord): CustomerRecord.CustomerRecord {
    // This service does not send field attribute in the request so we can provide non-null fields as non-optional,
    // without check (using ! sign)
    // (Except 'formRecord' field because query param 'withFormRecord' is used.)
    // NOTE:
    // If 'shorter' versions are required, create a new CustomerRecord type.
    // Keep this as 'full' version. We don't like optional fields in the UI layer.
    return {
      customerRecordId: r.id!,
      customerId: r.customer_id!,
      externalId: r.external_id,
      disabled: r.disabled!,
      creationTime: Services.toOffsetDateTime(r.creation_time!),
      updateTime: Services.toOffsetDateTime(r.update_time!),
      name: Services.optToString(r.name),
      firstName: r.first_name,
      lastName: r.last_name,
      comment: Services.optToString(r.comment),
      position: Services.optToString(r.position),
      webAddress: Services.optToString(r.web_address),
      taxNumber: Services.optToString(r.tax_number),
      euTaxNumber: Services.optToString(r.eu_tax_number),
      ownerUserIds: Services.optArrayToSet(r.owner_user_ids),
      ownerUsers: r.owner_users
        ? List.of(...r.owner_users.map(u => ({id: u.id, personName: u.person_name, userName: u.user_name})))
        : List.of(),
      ownerGroupIds: Services.optArrayToSet(r.owner_group_ids),
      ownerGroups: r.owner_groups
        ? List.of(...r.owner_groups.map(g => ({id: g.id, name: g.name})))
        : List.of(),
      companyId: r.company_id,
      postalAddress: AddressResource.Mapper.toPublicPostalAddressOpt(r.postal_address),
      notificationAddress: AddressResource.Mapper.toPublicPostalAddressOpt(r.notification_address),
      notificationAddressEqPostal: r.notification_address && r.postal_address ? r.notification_address.id === r.postal_address.id : false,
      emailAddresses: AddressResource.Mapper.toPublicEmailAddressList(r.email_addresses!),
      phoneNumbers: AddressResource.Mapper.toPublicPhoneNumberList(r.phone_numbers!),
      relatedLocations: r.related_locations ? AddressResource.Mapper.toPublicContactLocationList(r.related_locations) : undefined,
      formRecord: this.formRecordMapper.toPublic(r.form_record),
      parentId: r.parent_id,
      invoiceDeadlineAdditionalDays: r.invoice_deadline_additional_days,
      taskRecordDeadlineAdditionalDays: r.task_record_deadline_additional_days,
      ledgerNumber: r.ledger_number ? LedgerNumber.toPublic(r.ledger_number) : undefined,
      mainContractNumber: r.main_contract_number,
      // PERSON type
      placeOfBirth: r.place_of_birth,
      dateOfBirth: this.ngbDatePickerParserFormatter.fromLocalDate(Services.toLocalDate(r.date_of_birth)),
      birthName: r.birth_name,
      gender: <CustomerRecord.GenderType>r.gender,
      mothersName: r.mothers_name,
      identityDocumentType: r.identity_document_type,
      identityDocumentNumber: r.identity_document_number,
      customer: CustomerRecord.Mapper.toPublicCustomer(r.customer),
      helpdesk: this.toPublicHelpdesk(r.helpdesk),
      grantedRights: r.granted_rights ? Set.of(...r.granted_rights) : Set.of(),
    };
  }

  private toPublicHelpdesk(r?: CustomerRecordResource.HelpdeskData): CustomerRecord.HelpdeskData | undefined {
    return r ? {
      user: r.user ? {
        id: r.user.id,
        personName: r.user.person_name,
        userName: r.user.user_name,
        disabled: r.user.disabled
      } : undefined,
      invitationTime: r.invitation_time ? Services.toOffsetDateTime(r.invitation_time) : undefined
    } : undefined;
  }

  public static toPublicBillingInfo(r: CustomerRecordBillingInfoResource.BillingInfo): CustomerRecord.BillingInfo {
    return {
      id: r.id,
      disabled: r.disabled,
      name: r.name,
      taxNumber: r.tax_number,
      euTaxNumber: r.eu_tax_number,
      bankAccount: r.bank_account,
      invoiceVatStatus: <CustomerRecord.InvoiceVatStatus>r.invoice_vat_status,
      invoiceAddress: AddressResource.Mapper.toPublicPostalAddress(r.invoice_address)
    }
  }

  private translateError(error: any): any {
    const res = ObservableErrorResourceParser.parseError(error);
    const fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
    const fieldErrorMap = ObservableErrorResourceParser.toFieldErrorMap(Keys.toValidatedField, fieldErrors);
    if (!fieldErrorMap.isEmpty()) {
      return FieldValidationError.of(fieldErrorMap);
    }
    return error;
  }

  // </editor-fold>

}

export namespace CustomerRecord {

  import CustomerType = Customer.CustomerType;

  export class Mapper {

    public static toPublic(r?: CustomerRecordResource.CustomerRecord): CustomerRecord.CustomerRecord | undefined {
      // This is the same function as the private toPublic() mapper in the service, without the formRecordMapper
      // so that the method can be static and be reached from other services.
      if (r) {
        return {
          customerRecordId: r.id!,
          customerId: r.customer_id!,
          externalId: r.external_id,
          disabled: r.disabled!,
          creationTime: Services.toOffsetDateTime(r.creation_time!),
          updateTime: Services.toOffsetDateTime(r.update_time!),
          name: Services.optToString(r.name),
          firstName: r.first_name,
          lastName: r.last_name,
          comment: Services.optToString(r.comment),
          webAddress: Services.optToString(r.web_address),
          taxNumber: Services.optToString(r.tax_number),
          euTaxNumber: Services.optToString(r.eu_tax_number),
          ownerUserIds: Services.optArrayToSet(r.owner_user_ids),
          ownerUsers: r.owner_users
            ? List.of(...r.owner_users.map(u => ({id: u.id, personName: u.person_name, userName: u.user_name})))
            : List.of(),
          ownerGroupIds: Services.optArrayToSet(r.owner_group_ids),
          ownerGroups: r.owner_groups
            ? List.of(...r.owner_groups.map(g => ({id: g.id, name: g.name})))
            : List.of(),
          postalAddress: AddressResource.Mapper.toPublicPostalAddressOpt(r.postal_address),
          notificationAddress: AddressResource.Mapper.toPublicPostalAddressOpt(r.notification_address),
          notificationAddressEqPostal:
            r.notification_address && r.postal_address ? r.notification_address.id === r.postal_address.id : false,
          emailAddresses: AddressResource.Mapper.toPublicEmailAddressList(r.email_addresses!),
          phoneNumbers: AddressResource.Mapper.toPublicPhoneNumberList(r.phone_numbers!),
          // PERSON type
          placeOfBirth: r.place_of_birth,
          // dateOfBirth: this.ngbDatePickerParserFormatter.fromLocalDate(Services.toLocalDate(r.date_of_birth)),
          dateOfBirth: null,
          birthName: r.birth_name,
          gender: <CustomerRecord.GenderType>r.gender,
          mothersName: r.mothers_name,
          identityDocumentType: r.identity_document_type,
          identityDocumentNumber: r.identity_document_number,
          customer: CustomerRecord.Mapper.toPublicCustomer(r.customer),
          invoiceDeadlineAdditionalDays: r.invoice_deadline_additional_days,
          ledgerNumber: r.ledger_number ? LedgerNumber.toPublic(r.ledger_number) : undefined,
          mainContractNumber: r.main_contract_number,
          grantedRights: r.granted_rights ? Set.of(...r.granted_rights) : Set.of(),
        };
      }
      return undefined;
    }

    public static toPublicCustomer(r?: CustomerRecordResource.Customer): CustomerRecord.Customer | undefined {
      if (!r) {
        return undefined;
      }
      return {
        customerId: r.id,
        name: r.name,
        complexName: r.complex_name,
        type: <Customer.CustomerType>r.type,
        emailAddressRequired: r.email_address_required,
        useDefaultManagedFields: r.use_default_managed_fields,
        managedFields: Set.of(...r.managed_fields.map(f => <CustomerRecordFieldType>f)),
        contactPerson: r.contact_person
      };
    }

  }


  export interface Service {

    // <editor-fold desc="CRUD">

    query(request: QueryRequest): Observable<QueryResult<CustomerRecord>>;

    get(request: GetRequest): Observable<CustomerRecord>;

    create(request: CreateRequest): Observable<CustomerRecord>;

    update(request: UpdateRequest): Observable<CustomerRecord>;

    setDisabled(request: DisableRequest): Observable<CustomerRecord>;

    history(request: HistoryRequest): Observable<QueryResult<HistoryItem>>;

    // </editor-fold>

  }

  export enum OrderField {
    ID,
    EXTERNAL_ID,
    MAIN_CONTRACT_NUMBER,
    DISABLED,
    CREATION_TIME,
    UPDATE_TIME,
    NAME,
    DESCRIPTION,
    CUSTOMER_NAME,
    HISTORY_CREATION_TIME,
    HISTORY_USER_PERSON_NAME,
    HISTORY_MOBILE_APPLICATION_APPLICATION_ID
  }

  export enum ValidatedField {
    UNKNOWN = 'unknown',

    EXTERNAL_ID = 'externalId',
    NAME = 'name',
    FIRST_NAME = 'firstName',
    LAST_NAME = 'lastName',
    TAX_NUMBER = 'taxNumber',
    EU_TAX_NUMBER = 'euTaxNumber',
    COMPANY = 'company'
  }

  export interface CustomerRecord {
    customerRecordId: number;
    customerId: number;
    customer?: Customer;
    externalId?: string;
    disabled: boolean;
    creationTime: OffsetDateTime;
    updateTime: OffsetDateTime;
    name: string;
    firstName?: string;
    lastName?: string;
    comment?: string;
    position?: string;
    webAddress?: string;
    taxNumber?: string;
    euTaxNumber?: string;
    ownerUserIds: Set<number>;
    ownerUsers: List<OwnerUserData>;
    ownerGroupIds: Set<number>;
    ownerGroups: List<OwnerGroupData>;
    companyId?: number;
    postalAddress?: Address.PostalAddressData;
    notificationAddress?: Address.PostalAddressData;
    notificationAddressEqPostal: boolean;
    emailAddresses: List<Address.EmailAddressData>;
    phoneNumbers: List<Address.PhoneNumberData>;
    relatedLocations?: List<Address.ContactLocation>;
    formRecord?: FormRecord.FormRecord; // non-null if requested
    parentId?: number;
    invoiceDeadlineAdditionalDays?: number;
    taskRecordDeadlineAdditionalDays?: number;
    ledgerNumber?: LedgerNumber.LedgerNumber;
    mainContractNumber?: string;
    // PERSON type
    placeOfBirth?: string;
    dateOfBirth: NgbDateStruct | null;
    birthName?: string;
    gender?: GenderType;
    mothersName?: string;
    identityDocumentType?: string;
    identityDocumentNumber?: string;
    helpdesk?: HelpdeskData;
    grantedRights: Set<string>;
  }

  export interface Customer {
    customerId: number;
    name: string;
    type: Customer.CustomerType;
    complexName: boolean;
    emailAddressRequired: boolean;
    useDefaultManagedFields: boolean;
    managedFields: Set<CustomerRecordFieldType>;
    contactPerson: boolean;
  }

  export interface QueryRequest {
    fields?: Set<string>;
    rights?: Set<string>;
    withFormRecord?: boolean;

    contactPerson?: boolean;
    contactPersonType?: boolean;
    contactPersonId?: number;
    customerId: number;
    customerRecordIdSet?: Set<number>;
    disabled?: boolean;
    name?: string;
    externalId?: string;
    comment?: string;
    emailAddress?: string;
    phoneNumber?: string;
    postalAddressCity?: string;
    postalAddressZipCode?: string;
    postalAddressStreet?: string;
    postalAddressHouseNumber?: string;
    ownerUserIds?: Set<number>;
    ownerUserGroupIds?: Set<number>;
    ownerCustomerRecordId?: Set<number>;
    ownerContactLocationId?: Set<number>;
    contractNumberId?: Set<number>;
    dqlText?: string;
    queryText?: string;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
    noProgressBar?: boolean;
  }

  export interface GlobalQueryRequest {
    fields?: Set<string>;
    rights?: Set<string>;
    withFormRecord?: boolean;
    parentDisabled?: boolean;

    contactPerson?: boolean;
    contactPersonType?: boolean;
    contactPersonId?: number;
    customerRecordIdSet?: Set<number>;
    excludedCustomerRecordIds?: Set<number>;
    disabled?: boolean;
    name?: string;
    externalId?: string;
    comment?: string;
    customerTypes?: CustomerType[];
    customerIds?: Set<number>;
    emailAddress?: string;
    phoneNumber?: string;
    postalAddressCity?: string;
    postalAddressZipCode?: string;
    postalAddressStreet?: string;
    postalAddressHouseNumber?: string;
    ownerUserIds?: Set<number>;
    ownerUserGroupIds?: Set<number>;
    contractNumberId?: Set<number>;
    parentId?: number;
    queryText?: string;
    dqlText?: string;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
    noProgressBar?: boolean;
  }

  export interface GetRequest {
    fields?: Set<string>;
    rights?: Set<string>;
    withFormRecord?: boolean;

    customerId: number;
    customerRecordId: number;
  }

  export interface CreateRequest extends QuickCreateRequest {
    formRecord: FormRecord.FormRecordCreateRequest;
  }

  export interface QuickCreateRequest {
    withFormRecord?: boolean;
    rights?: Set<string>;

    customerId: number;
    name?: string;
    firstName?: string;
    lastName?: string;
    externalId?: string;
    comment?: string;
    position?: string;
    webAddress?: string;
    taxNumber?: string;
    euTaxNumber?: string;
    ownerUserIds: Set<number>;
    ownerGroupIds: Set<number>;
    companyId?: number;
    postalAddress?: Address.PostalAddressData;
    notificationAddress?: Address.PostalAddressData;
    notificationAddressEqPostal: boolean;
    emailAddresses: List<Address.EmailAddressData>;
    phoneNumbers: List<Address.PhoneNumberData>;
    parentId?: number;
    invoiceDeadlineAdditionalDays?: number;
    taskRecordDeadlineAdditionalDays?: number;
    ledgerNumber?: number;
    mainContractNumber?: string;
    // PERSON type
    placeOfBirth?: string;
    dateOfBirth?: NgbDateStruct | null;
    birthName?: string;
    gender?: GenderType;
    mothersName?: string;
    identityDocumentType?: string;
    identityDocumentNumber?: string;
    billingInfo?: BillingInfoCreateRequest;
  }

  export interface UpdateRequest {
    withFormRecord?: boolean;

    customerRecordId: number;
    customerId: number;
    externalId?: string;
    name?: string;
    firstName?: string;
    lastName?: string;
    comment?: string;
    position?: string;
    webAddress?: string;
    taxNumber?: string;
    euTaxNumber?: string;
    ownerUserIds: Set<number>;
    ownerGroupIds: Set<number>;
    companyId?: number;
    postalAddress?: Address.PostalAddressData;
    notificationAddress?: Address.PostalAddressData;
    notificationAddressEqPostal: boolean;
    emailAddresses: List<Address.EmailAddressData>;
    phoneNumbers: List<Address.PhoneNumberData>;
    formRecord: FormRecord.FormRecordUpdateRequest;
    parentId?: number;
    invoiceDeadlineAdditionalDays?: number;
    taskRecordDeadlineAdditionalDays?: number;
    ledgerNumber?: number;
    mainContractNumber?: string;
    // PERSON type
    placeOfBirth?: string;
    dateOfBirth?: NgbDateStruct | null;
    birthName?: string;
    gender?: GenderType;
    mothersName?: string;
    identityDocumentType?: string;
    identityDocumentNumber?: string;
  }

  export interface DisableRequest {
    withFormRecord?: boolean;

    customerId: number;
    customerRecordId: number;
    disabled: boolean;
  }

  export interface ContactPersonRequest {
    customerId: number;
    customerRecordId: number;
    contactPersonId: number;
  }

  export interface ContactPersonsRequest {
    customerId: number;
    customerRecordId: number;
    contactPersonIds: Set<number>;
  }

  export interface OwnerChangeRequest {
    customerRecordIds: Set<number>;
    ownerUserIds?: Set<number>;
    ownerUserGroupIds?: Set<number>;
    changeType: OwnerChangeType;
  }

  export interface ContactLocationContactPersonChangeRequest {
    customerRecordId: number;
    contactPersonCustomerRecordId: number;
    relatedContactLocationId?: number;
  }

  export interface QueryAttachmentRequest {
    customerId: number;
    customerRecordId: number;
  }

  export interface AttachmentRequest extends QueryAttachmentRequest {
    fileId: number;
  }

  export interface UpdateAttachmentRequest extends QueryAttachmentRequest, FileAttachmentUpdateRequest {
  }

  export interface DownloadAttachmentRequest extends QueryAttachmentRequest {
    attachment: FileAttachment;
  }

  export interface HelpdeskData {
    user?: {
      id: number;
      personName: string;
      userName: string;
      disabled: boolean;
    };
    invitationTime?: OffsetDateTime;
  }

  export interface HelpdeskInviteRequest {
    customerRecordIds: Set<number>;
  }

  export interface HelpdeskInviteResponse {
    invited: Set<CustomerRecord>;
    alreadyRegistered: Set<CustomerRecord>;
    invalidEmail: Set<CustomerRecord>;
  }

  export interface HelpdeskRegistrationDataRequest {
    helpdeskToken: string;
  }

  export interface HelpdeskRegistrationDataResponse {
    id: number;
    name: string;
    emailAddress: string;
    customerNames?: string[];
  }

  export interface OwnerUserData {
    id: number;
    personName: string;
    userName: string;
  }

  export interface OwnerGroupData {
    id: number;
    name: string;
  }

  export interface BillingInfo {
    id: number;
    disabled: boolean;
    name: string;
    taxNumber?: string;
    euTaxNumber?: string;
    bankAccount?: string;
    invoiceVatStatus: InvoiceVatStatus;
    invoiceAddress: Address.PostalAddressData;
  }

  export interface BillingInfoReadRequest {
    customerId: number;
    customerRecordId: number;
    disabled?: boolean;
  }

  export interface BillingInfoReadOneRequest extends BillingInfoReadRequest {
    id: number;
  }

  export interface BillingInfoDisableRequest extends BillingInfoReadOneRequest {
    disabled: boolean;
  }

  export interface BillingInfoCreateRequest extends BillingInfoReadRequest {
    name: string;
    taxNumber?: string;
    euTaxNumber?: string;
    bankAccount?: string;
    invoiceVatStatus: InvoiceVatStatus;
    invoiceAddress: Address.PostalAddressData;
  }

  export interface BillingInfoUpdateRequest extends BillingInfoCreateRequest {
    id: number;
  }

  export enum InvoiceVatStatus {
    DOMESTIC = 'DOMESTIC',
    OTHER = 'OTHER',
    PRIVATE_PERSON = 'PRIVATE_PERSON'
  }

  export const invoiceVatStatuses: LocalizedTypeObject<InvoiceVatStatus>[] = [
    {type: InvoiceVatStatus.DOMESTIC, stringKey: 'INVOICE_VAT_STATUS_DOMESTIC'},
    {type: InvoiceVatStatus.PRIVATE_PERSON, stringKey: 'INVOICE_VAT_STATUS_PRIVATE_PERSON'},
    {type: InvoiceVatStatus.OTHER, stringKey: 'INVOICE_VAT_STATUS_OTHER'},
  ];

  export type OwnerChangeType = 'ADD' | 'OVERRIDE';

  export type GenderType = 'FEMALE' | 'MALE' | 'OTHER';

  export class GenderTypeObject {
    type: GenderType;
    stringKey: string;
  }

  export const genderTypes: GenderTypeObject[] = [
    {type: 'FEMALE', stringKey: 'CUSTOMER_RECORD_GENDER_TYPE_FEMALE'},
    {type: 'MALE', stringKey: 'CUSTOMER_RECORD_GENDER_TYPE_MALE'},
    {type: 'OTHER', stringKey: 'CUSTOMER_RECORD_GENDER_TYPE_OTHER'}
  ];

  export interface HistoryRequest {
    customerId: number;
    customerRecordId: number;
    withRead: boolean;
    queryText?: string;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
  }

  export interface HistoryItem extends HistoryLog.HistoryItemBase {
    type: CustomerRecordHistoryType;
    user: UserItem;
  }

  export type CustomerRecordHistoryType =
    'READ' |
    'CREATE_REGULAR' |
    'CREATE_QUICK' |
    'CREATE_IMPORT_DOCUMENT' |
    'CREATE_IMPORT_API' |
    'UPDATE_REGULAR' |
    'UPDATE_IMPORT_DOCUMENT' |
    'UPDATE_IMPORT_API' |
    'DISABLE' |
    'ENABLE' |
    'CHANGE_OWNER';

  export class CustomerRecordHistoryTypeObject {
    type: CustomerRecordHistoryType;
    stringKey: string;
  }

  export const customerRecordHistoryTypes: CustomerRecordHistoryTypeObject[] = [
    {type: 'READ', stringKey: 'CUSTOMER_RECORD_HISTORY_TYPE_READ'},
    {type: 'CREATE_REGULAR', stringKey: 'CUSTOMER_RECORD_HISTORY_TYPE_CREATE_REGULAR'},
    {type: 'CREATE_IMPORT_DOCUMENT', stringKey: 'CUSTOMER_RECORD_HISTORY_TYPE_CREATE_IMPORT_DOCUMENT'},
    {type: 'CREATE_IMPORT_API', stringKey: 'CUSTOMER_RECORD_HISTORY_TYPE_CREATE_IMPORT_API'},
    {type: 'UPDATE_REGULAR', stringKey: 'CUSTOMER_RECORD_HISTORY_TYPE_UPDATE_REGULAR'},
    {type: 'UPDATE_IMPORT_DOCUMENT', stringKey: 'CUSTOMER_RECORD_HISTORY_TYPE_UPDATE_IMPORT_DOCUMENT'},
    {type: 'UPDATE_IMPORT_API', stringKey: 'CUSTOMER_RECORD_HISTORY_TYPE_UPDATE_IMPORT_API'},
    {type: 'DISABLE', stringKey: 'CUSTOMER_RECORD_HISTORY_TYPE_DISABLE'},
    {type: 'ENABLE', stringKey: 'CUSTOMER_RECORD_HISTORY_TYPE_ENABLE'},
    {type: 'CHANGE_OWNER', stringKey: 'CUSTOMER_RECORD_HISTORY_TYPE_CHANGE_OWNER'}
  ];

  export const editFields: Set<string> = Set.of(
    'id',
    'customer_id',
    'customer',
    'external_id',
    'disabled',
    'contact_person',
    'creation_time',
    'update_time',
    'owner_group_ids',
    'owner_user_ids',
    'phone_numbers',
    'email_addresses',
    'notification_address_eq_postal',
    'invoice_address_eq_postal',
    'notification_address',
    'main_contract_number',
    'invoice_address',
    'postal_address',
    'name',
    'first_name',
    'last_name',
    'company_id',
    'tax_number',
    'invoice_deadline_additional_days',
    'task_record_deadline_additional_days',
    'comment',
    'position',
    'identity_document_number',
    'identity_document_type',
    'mothers_name',
    'gender',
    'birth_name',
    'date_of_birth',
    'place_of_birth',
    'eu_tax_number',
    'web_address',
    'helpdesk',
    'main_customer_record_id',
    'ledger_number'
  );

  export const detailFields: Set<string> = editFields;

}

export namespace CustomerRecordContactLocation {

  export interface QueryRequest {
    customerId: number;
    customerRecordId: number;
    ids?: Set<number>;
    contractNumberIds?: Set<number>;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
    noProgressBar?: boolean;
  }

  interface BaseRequest {
    customerId: number;
    customerRecordId: number;
    contractNumber?: string;
    name: string;
    openingTimes?: string;
    comment?: string;
    postalAddress: Address.PostalAddressData;
    contactPersons?: List<number>;
  }

  export interface CreateRequest extends BaseRequest {
    externalId?: string;
  }

  export interface UpdateRequest extends BaseRequest {
    id: number;
    externalId: string;
  }

  export interface DeleteRequest {
    customerId: number;
    customerRecordId: number;
    id: number;
  }

  export enum OrderField {
    ID,
    NAME,
    CONTRACT_NUMBER,
    EXTERNAL_ID,
    COMMENT
  }

}

export namespace CustomerRecordContactPerson {

  export interface QueryRequest {
    customerId: number;
    customerRecordId: number;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
    noProgressBar?: boolean;
    fields?: Set<string>;
  }

  export enum OrderField {
    ID,
    NAME,
    EXTERNAL_ID
  }

}

// <editor-fold desc="Internal">

class Keys {

  private static readonly ID = 'id';
  private static readonly EXTERNAL_ID = 'external_id';
  private static readonly MAIN_CONTRACT_NUMBER = 'main_contract_number';
  private static readonly DISABLED = 'disabled';
  private static readonly CREATION_TIME = 'creation_time';
  private static readonly UPDATE_TIME = 'update_time';
  private static readonly NAME = 'name';
  private static readonly CONTRACT_NUMBER = 'contract_number';
  private static readonly FIRST_NAME = 'first_name';
  private static readonly LAST_NAME = 'las_name';
  private static readonly TAX_NUMBER = 'tax_number';
  private static readonly EU_TAX_NUMBER = 'eu_vat_number';
  private static readonly DESCRIPTION = 'comment';
  private static readonly CUSTOMER_NAME = 'customer_name';
  private static readonly HISTORY_CREATION_TIME = 'creation_time';
  private static readonly HISTORY_USER_PERSON_NAME = 'user_person_name';
  private static readonly HISTORY_MOBILE_APPLICATION_APPLICATION_ID = 'mobile_application_application_id';
  private static readonly COMMENT = 'comment';
  private static readonly COMPANY = 'company';

  private static readonly orderFieldKeyMap: Map<CustomerRecord.OrderField, string> = Map.of(
    CustomerRecord.OrderField.ID, Keys.ID,
    CustomerRecord.OrderField.EXTERNAL_ID, Keys.EXTERNAL_ID,
    CustomerRecord.OrderField.MAIN_CONTRACT_NUMBER, Keys.MAIN_CONTRACT_NUMBER,
    CustomerRecord.OrderField.DISABLED, Keys.DISABLED,
    CustomerRecord.OrderField.CREATION_TIME, Keys.CREATION_TIME,
    CustomerRecord.OrderField.UPDATE_TIME, Keys.UPDATE_TIME,
    CustomerRecord.OrderField.NAME, Keys.NAME,
    CustomerRecord.OrderField.DESCRIPTION, Keys.DESCRIPTION,
    CustomerRecord.OrderField.CUSTOMER_NAME, Keys.CUSTOMER_NAME,
    CustomerRecord.OrderField.HISTORY_CREATION_TIME, Keys.HISTORY_CREATION_TIME,
    CustomerRecord.OrderField.HISTORY_USER_PERSON_NAME, Keys.HISTORY_USER_PERSON_NAME,
    CustomerRecord.OrderField.HISTORY_MOBILE_APPLICATION_APPLICATION_ID, Keys.HISTORY_MOBILE_APPLICATION_APPLICATION_ID,
  );

  private static readonly locationOrderFieldKeyMap: Map<CustomerRecordContactLocation.OrderField, string> = Map.of(
    CustomerRecordContactLocation.OrderField.ID, Keys.ID,
    CustomerRecordContactLocation.OrderField.EXTERNAL_ID, Keys.EXTERNAL_ID,
    CustomerRecordContactLocation.OrderField.NAME, Keys.NAME,
    CustomerRecordContactLocation.OrderField.CONTRACT_NUMBER, Keys.CONTRACT_NUMBER,
    CustomerRecordContactLocation.OrderField.COMMENT, Keys.COMMENT,
  );

  private static readonly keyValidatedFieldMap: Map<string, CustomerRecord.ValidatedField> = Map.of(
    Keys.EXTERNAL_ID, CustomerRecord.ValidatedField.EXTERNAL_ID,
    Keys.NAME, CustomerRecord.ValidatedField.NAME,
    Keys.FIRST_NAME, CustomerRecord.ValidatedField.FIRST_NAME,
    Keys.LAST_NAME, CustomerRecord.ValidatedField.LAST_NAME,
    Keys.TAX_NUMBER, CustomerRecord.ValidatedField.TAX_NUMBER,
    Keys.EU_TAX_NUMBER, CustomerRecord.ValidatedField.EU_TAX_NUMBER,
    Keys.COMPANY, CustomerRecord.ValidatedField.COMPANY,
  );

  public static toOrderFieldKey(field: CustomerRecord.OrderField): string {
    return Keys.orderFieldKeyMap.get(field)!;
  }

  public static toLocationOrderFieldKey(field: CustomerRecordContactLocation.OrderField): string {
    return Keys.locationOrderFieldKeyMap.get(field)!;
  }

  public static toValidatedField(fieldKey: string): CustomerRecord.ValidatedField {
    return Keys.keyValidatedFieldMap.get(fieldKey, CustomerRecord.ValidatedField.UNKNOWN);
  }

}

// </editor-fold>
