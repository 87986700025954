/* eslint-disable */
import { ImmutableUserGroupItem, OptionItem } from './core-utils';
import { DisabledItem } from './search-utils';
import { CustomerOwnerTypeItem } from './customer-utils';
import { List } from 'immutable';
/* eslint-enable */

export class MasterdataEditModel {

  readonly userGroupItems: ImmutableUserGroupItem[] = [];

  name: string = '';
  description: string = '';
  externalId: string = '';
  requiredMasterDataId: string = '';
  mobileOwnerType?: CustomerOwnerTypeItem = undefined;

  userGroups: ImmutableUserGroupItem[] = [];
  notFoundUserGroupIds: number[] = [];

  getUserGroupIds(): number[] {
    return this.userGroups.map((userGroup) => {
      return userGroup.id!;
    });
  }

  loadUserGroupItems(userGroupItems: List<ImmutableUserGroupItem>) {
    this.userGroupItems.splice(0, this.userGroupItems.length);
    this.userGroupItems.push(...userGroupItems.toArray());
  }

  loadUserGroupItem(ownerUserGroupIds: number[]) {
    this.notFoundUserGroupIds = [];
    this.userGroups = [];
    ownerUserGroupIds.forEach((id) => {
      const item: ImmutableUserGroupItem | undefined = this.userGroupItems.find((i) => i.id === id);
      if (item) {
        this.userGroups.push(item);
      }
      else {
        this.notFoundUserGroupIds.push(id);
      }
    });
  }
}

export class MasterdataSearchModel {
  id: string;
  name: string;
  externalId: string;
  disabled?: DisabledItem;

  public isEmpty(): boolean {
    return this.id.length === 0
      && this.name.length === 0
      && this.externalId.length === 0
      && OptionItem.idOrUndefined(this.disabled) === undefined
      ;
  }
}

export class MasterdataCreateModel extends MasterdataEditModel {
}

export class MasterdataDisabledItem extends OptionItem<MasterdataDisabled> {
}

export class MasterdataDisabledItems {
  public static readonly values: MasterdataDisabled[] = ['ALL', 'ACTIVE', 'INACTIVE'];
}

export type MasterdataDisabled =
  'ALL' |
  'ACTIVE' |
  'INACTIVE';


export const RequiredMasterDataIdsItems: RequiredMasterDataIdsItem[] = [
  {id: 'NAME', name: 'COMMON_NAME'},
  {id: 'EXTERNAL_ID', name: 'COMMON_EXTERNAL_ID'},
];

interface RequiredMasterDataIdsItem {
  id: RequiredMasterDataIds;
  name: string;
}

export type RequiredMasterDataIds =
  'NAME' |
  'EXTERNAL_ID';
