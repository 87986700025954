<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
    <div class="breadcrumb-menu d-flex">
      <div class="breadcrumb-button-icon-container cursor-pointer"
           (click)="openWorkflowImportDialog()" *ngIf="rightModel.workflowCreate.hasRight()">
        <a class="btn-setting d-flex align-items-center" title="{{'COMMON_BUTTON_IMPORT' | translate}}">
          <i class="icomoon icomoon-csv-import"></i>
          <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_IMPORT' | translate}}</span>
        </a>
      </div>
      <div *ngIf="rightModel.workflowCreate.hasRight()"
           class="breadcrumb-button-icon-container cursor-pointer negative-margin-right"
           (click)="openWorkflowCreateDialog()">
        <a class="btn-setting" title="{{'WORKFLOW_CREATE' | translate}}">
          <i class="icomoon icomoon-add"></i>
          <span class="breadcrumb-button-text-visible">{{'WORKFLOW_CREATE' | translate}}</span>
        </a>
      </div>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="card">
    <div class="card-header">
      {{'MENU_NAVBAR_NAV_TITLE_WORKFLOWS' | translate}}

      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()" title="{{'COMMON_BUTTON_SEARCH' | translate}}"><i
          class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>

    <div class="card-body border-bottom" *ngIf="showSearch">
      <div class="row form-group">
        <div class="col-md-3">
          <label class="search-label">{{'COMMON_TABLE_HEADER_ID' | translate}}</label>
          <input type="number" class="form-control"
                 placeholder="{{'COMMON_TABLE_HEADER_ID' | translate}}"
                 maxlength="{{UiConstants.maxInputNumberLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.id">
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'COMMON_TABLE_HEADER_EXTERNAL_ID' | translate}}</label>
          <input type="text" class="form-control"
                 placeholder="{{'COMMON_TABLE_HEADER_EXTERNAL_ID' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.externalId">
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'COMMON_NAME' | translate}}</label>
          <input type="text" class="form-control"
                 placeholder="{{'COMMON_NAME' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.name">
        </div>
        <div class="col-md-3">
          <label class="search-label"> {{ 'COMMON_STATE' | translate }} </label>
          <select
            class="form-control"
            [(ngModel)]="searchModel.versionState" [compareWith]="SelectUtils.compareObjects">
            <option *ngFor="let item of states" [ngValue]="item">{{item.stringKey | translate}}</option>
          </select>
        </div>
      </div>
      <div class="col-md-12 d-flex justify-content-md-end align-items-end px-0">
        <div class="btn-group" role="group">
          <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
            {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
          </button>
          <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
            {{'COMMON_BUTTON_SEARCH' | translate}}
          </button>
        </div>
      </div>
    </div><!-- /.search card-->

    <div class="card-body">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="WorkflowSearch.OrderFunctions.ID"
                                    [orderType]="queryModel.getOrderType(WorkflowSearch.OrderFunctions.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="WorkflowSearch.OrderFunctions.NAME"
                                    [orderType]="queryModel.getOrderType(WorkflowSearch.OrderFunctions.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-field-sorter [orderField]="WorkflowSearch.OrderFunctions.EXTERNAL_ID"
                                    [orderType]="queryModel.getOrderType(WorkflowSearch.OrderFunctions.EXTERNAL_ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_EXTERNAL_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-field-sorter [orderField]="WorkflowSearch.OrderFunctions.UPDATE_TIME"
                                    [orderType]="queryModel.getOrderType(WorkflowSearch.OrderFunctions.UPDATE_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_UPDATE_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op
              [text]="'COMMON_VERSION' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-sorter-no-op
              [text]="'COMMON_STATE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let workflow of workflowList | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }"
            [disabledTableRow]="isInactive(workflow.versionState)">
          <td class="fit align-middle">{{workflow.id}}</td>
          <td class="responsive-table-column long align-middle">
            <div class="d-flex justify-content-start align-items-center">
              <app-type-icon [icon]="workflow.icon" class="mr-05"></app-type-icon>
              <a class="id-text cursor-pointer"
                 href="#" onclick="return false"
                 uiSref="Admin.WorkflowDetail"
                 [uiParams]="{ id: workflow.id }"
                 title="{{'COMMON_BUTTON_DETAILS' | translate}}">
                {{workflow.name}}
              </a>
            </div>
          </td>
          <td class="responsive-table-column hidden-md-down align-middle">{{workflow.externalId}}</td>
          <td class="responsive-table-column hidden-lg-down">
            <div class="text-truncate">
              <span class="text-truncate">{{workflow.updateTime.toUtcIsoString() |
                date:'short'}}</span>
              <br>
              <span class="text-truncate table-text-subtitle">{{workflow.getLastModifierName()}}</span>
            </div>
          </td>
          <td class="responsive-table-column hidden-sm-down align-middle">v{{workflow.version}}</td>
          <td class="fit hidden-xs-down align-middle">
            <app-table-badge
              *ngIf="workflow.stateObject"
              [badgeStyle]="workflow.stateObject.badgeStyle"
              [iconClass]="workflow.stateObject.iconClass"
              [text]="workflow.stateObject.stringKey | translate">
            </app-table-badge>
          </td>
          <td class="align-middle">
            <app-table-options-menu>
              <app-dropdown-item
                [iconClass]="'icomoon-detail'"
                [titleStringKey]="'COMMON_BUTTON_DETAILS'"
                class="cursor-pointer"
                uiSref="Admin.WorkflowDetail"
                [uiParams]="{ id: workflow.id }">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="workflow.rights.update.hasRight()"
                [iconClass]="'icomoon-modify'"
                [titleStringKey]="'COMMON_BUTTON_EDIT'"
                class="cursor-pointer"
                uiSref="Admin.WorkflowEdit"
                [uiParams]="{ id: workflow.id }">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="workflow.rights.activate.hasRight() && isInactive(workflow.versionState)"
                [iconClass]="'icomoon-enable'"
                [titleStringKey]="'COMMON_BUTTON_ENABLE'"
                [itemType]="DropdownItemType.ENABLE"
                class="cursor-pointer"
                (click)="activateWorkflow(workflow.id)">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="workflow.rights.activate.hasRight() && !isDraft(workflow.versionState) && !isInactive(workflow.versionState)"
                [iconClass]="'icomoon-disable'"
                [titleStringKey]="'COMMON_BUTTON_DISABLE'"
                [itemType]="DropdownItemType.DISABLE"
                class="cursor-pointer"
                (click)="inactivateWorkflow(workflow.id)">
              </app-dropdown-item>
            </app-table-options-menu>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="WorkflowSearch.OrderFunctions.ID"
                                    [orderType]="queryModel.getOrderType(WorkflowSearch.OrderFunctions.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="WorkflowSearch.OrderFunctions.NAME"
                                    [orderType]="queryModel.getOrderType(WorkflowSearch.OrderFunctions.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-field-sorter [orderField]="WorkflowSearch.OrderFunctions.EXTERNAL_ID"
                                    [orderType]="queryModel.getOrderType(WorkflowSearch.OrderFunctions.EXTERNAL_ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_EXTERNAL_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-field-sorter [orderField]="WorkflowSearch.OrderFunctions.UPDATE_TIME"
                                    [orderType]="queryModel.getOrderType(WorkflowSearch.OrderFunctions.UPDATE_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_UPDATE_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op
              [text]="'COMMON_VERSION' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-sorter-no-op
              [text]="'COMMON_STATE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>

  </div>

</div>

<app-workflow-import-dialog
  (onWorkflowImported)="onWorkflowImported($event)"
  #importDialog>
</app-workflow-import-dialog>
