import { Arrays } from '../lib/util/arrays';
import { TranslateService } from '@ngx-translate/core';
import { Injector } from '@angular/core';

export namespace SearchUtils {
  export abstract class SearchableList<SearchModelType extends SearchModel> {
    protected searchModel: SearchModelType;
    private firstOpen: boolean = true;
    protected translateService: TranslateService;

    protected constructor(ctor: NoParamConstructor<SearchModelType>,
                          injector: Injector) {
      this.searchModel = new ctor();
      this.translateService = injector.get(TranslateService);
    }

    get showSearch(): boolean {
      return this.searchModel.showSearch;
    }

    initSearch() {
      this.loadSearch(() => {
        if (!this.searchModel.isEmpty()) {
          this.toggleSearch();
        }
        this.loadList();
      });
    }

    toggleSearch() {
      if (this.firstOpen) {
        this.firstOpen = false;
        this.onFirstSearchOpen()
      }
      this.searchModel.toggleSearch();
    }

    openSearchPanel() {
      if (!this.searchModel.showSearch) {
        this.toggleSearch();
      }
    }

    initDisabledOptions() {
      const disabledItems: DisabledItem[] = [];
      const disabledEnums: DisabledEnum[] = [DisabledEnum.NONE, DisabledEnum.FALSE, DisabledEnum.TRUE];
      Arrays.iterateByIndex(disabledEnums, (key) => {
        const item = new DisabledItem();
        item.id = key;
        this.translateService.get('COMMON_VALUE_DISABLED_ENUM_' + key).subscribe(
          (text: string) => {
            item.text = text;
          }
        );
        disabledItems.push(item);
      });
      return disabledItems;
    }

    parseDisabled(disabled: DisabledEnum): boolean | undefined {
      if (disabled === DisabledEnum.TRUE) {
        return true;
      }
      if (disabled === DisabledEnum.FALSE) {
        return false;
      }
      return undefined;
    }

    abstract loadSearch(completion: () => void): void;

    abstract onFirstSearchOpen(): void;

    abstract loadList(): void;
  }

  type NoParamConstructor<T> = new () => T;

  export abstract class SearchModel {
    showSearch: boolean = false;

    toggleSearch() {
      this.showSearch = !this.showSearch;
    }

    abstract isEmpty(): boolean;
  }
}

export enum DisabledEnum {
  FALSE, // 0 === false
  TRUE, // 1 === true ... -.-
  NONE
}

export class DisabledItem {

  id: DisabledEnum;
  text: string;
  disabled?: boolean;

  public static empty(text: string): DisabledItem {
    const i = new DisabledItem();
    i.id = DisabledEnum.NONE;
    i.text = text;
    return i;
  }

  public static active(): DisabledItem {
    const i = new DisabledItem();
    i.id = DisabledEnum.FALSE;
    return i;
  }


  public toBoolean(): boolean | undefined {
    if (this.id === null || this.id === DisabledEnum.NONE) {
      return undefined;
    }
    return this.id === DisabledEnum.TRUE;
  }

}

export enum SearchBooleanItem {
  ALL,
  YES,
  NO
}

export interface SearchBooleanItemObject {
  value: SearchBooleanItem;
  stringKey: string;
  bool?: boolean;
}

export const searchBooleanItemObjects: SearchBooleanItemObject[] = [
  {value: SearchBooleanItem.ALL, stringKey: 'COMMON_VALUE_ALL', bool: undefined},
  {value: SearchBooleanItem.YES, stringKey: 'COMMON_YES', bool: true},
  {value: SearchBooleanItem.NO, stringKey: 'COMMON_NO', bool: false},
];
