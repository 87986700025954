export class StringKey {
  public static readonly INDEX_HTML_TITLE = 'INDEX_HTML_TITLE';
  public static readonly LOGIN_REQUIRED_REASON_TITLE_LOGIN_REQUIRED = 'LOGIN_REQUIRED_REASON_TITLE_LOGIN_REQUIRED';
  public static readonly LOGIN_REQUIRED_REASON_TEXT_SESSION_EXPIRED = 'LOGIN_REQUIRED_REASON_TEXT_SESSION_EXPIRED';
  public static readonly LOGIN_REQUIRED_REASON_TEXT_UNKNOWN_USER = 'LOGIN_REQUIRED_REASON_TEXT_UNKNOWN_USER';
  public static readonly REGISTRATION_SUCCESSFUL_TEXT = 'REGISTRATION_SUCCESSFUL_TEXT';
  public static readonly REGISTRATION_HELPDESK_SUCCESSFUL_TEXT = 'REGISTRATION_HELPDESK_SUCCESSFUL_TEXT';
  public static readonly REGISTRATION_SUCCESSFUL_TITLE = 'REGISTRATION_SUCCESSFUL_TITLE';
  public static readonly SHOP_RENTER_SHOP_LOGIN_REQUIRED = 'SHOP_RENTER_SHOP_LOGIN_REQUIRED';
  public static readonly USER_ACTIVATION_LOGIN_REQUIRED = 'USER_ACTIVATION_LOGIN_REQUIRED';
  public static readonly USER_ACTIVATION_ALREADY_ACTIVATED_LOGIN_REQUIRED = 'USER_ACTIVATION_ALREADY_ACTIVATED_LOGIN_REQUIRED';
  public static readonly COMMON_BUTTON_ADD = 'COMMON_BUTTON_ADD';
  public static readonly COMMON_BUTTON_DELETE = 'COMMON_BUTTON_DELETE';
  public static readonly COMMON_VALUE_UNSELECTED = 'COMMON_VALUE_UNSELECTED';
  public static readonly COMMON_VALUE_OTHER = 'COMMON_VALUE_OTHER';
  public static readonly COMMON_VALUE_UNSELECTED_COUNTRY = 'COMMON_VALUE_UNSELECTED_COUNTRY';
  public static readonly COMMON_VALIDATION_MESSAGE_REQUIRED = 'COMMON_VALIDATION_MESSAGE_REQUIRED';
  public static readonly COMMON_VALIDATION_MESSAGE_UNIQUE_KEY = 'COMMON_VALIDATION_MESSAGE_UNIQUE_KEY';
  public static readonly COMMON_FORM_VALIDATION_ERROR_TOAST_TITLE = 'COMMON_FORM_VALIDATION_ERROR_TOAST_TITLE';
  public static readonly COMMON_FORM_VALIDATION_ERROR_TOAST_MESSAGE = 'COMMON_FORM_VALIDATION_ERROR_TOAST_MESSAGE';
  public static readonly LOGIN_METHOD_NAME_LOCAL_USER = 'LOGIN_METHOD_NAME_LOCAL_USER';
  public static readonly LOGIN_METHOD_NAME_LDAP_USER = 'LOGIN_METHOD_NAME_LDAP_USER';
  public static readonly DEVICE_EDIT_TITLE = 'DEVICE_EDIT_TITLE';
  public static readonly DEVICE_EDIT_MESSAGE = 'DEVICE_EDIT_MESSAGE';
  public static readonly TASKS_DATA_MODIFY = 'TASKS_DATA_MODIFY';
  public static readonly TASK_DATA_MODIFY_SUCCESS = 'TASK_DATA_MODIFY_SUCCESS';
  public static readonly CUSTOMER_RECORD_DATA_MODIFY = 'CUSTOMER_RECORD_DATA_MODIFY';
  public static readonly CUSTOMER_RECORD_DATA_MODIFY_SUCCESS = 'CUSTOMER_RECORD_DATA_MODIFY_SUCCESS';
  public static readonly TASKS_STATE_MODIFY = 'TASKS_STATE_MODIFY';
  public static readonly TASK_STATE_CHANGE_ERROR = 'TASK_STATE_CHANGE_ERROR';
  public static readonly TASK_STATE_CHANGE_SUCCESS = 'TASK_STATE_CHANGE_SUCCESS';
  public static readonly TASK_DATA_MODIFY_PLEASE_SELECT = 'TASK_DATA_MODIFY_PLEASE_SELECT';
  public static readonly GEOCODING_DATA_MODIFY_SUCCESS = 'GEOCODING_DATA_MODIFY_SUCCESS';
  public static readonly TASK_ATTACHMENT_DELETE_MESSAGE = 'TASK_ATTACHMENT_DELETE_MESSAGE';
  public static readonly TASK_ATTACHMENT_UPLOAD_MESSAGE = 'TASK_ATTACHMENT_UPLOAD_MESSAGE';
  public static readonly TASK_ATTACHMENT_TITLE = 'TASK_ATTACHMENT_TITLE';
  public static readonly ATTACHMENT_TITLE = 'ATTACHMENT_DOWNLOAD_TITLE';
  public static readonly PDF_DOWNLOAD_ERROR_MESSAGE = 'PDF_DOWNLOAD_ERROR_MESSAGE';
  public static readonly TASK_EMAIL_SEND = 'TASK_EMAIL_SEND';
  public static readonly GENERAL_EMAIL_SEND_SUCCESS = 'GENERAL_EMAIL_SEND_SUCCESS';
  public static readonly GENERAL_EMAIL_SEND_RESULT_FAILED = 'GENERAL_EMAIL_SEND_RESULT_FAILED';
  public static readonly GENERAL_EMAIL_SEND_RESULT_INVALID_RECIPIENT = 'GENERAL_EMAIL_SEND_RESULT_INVALID_RECIPIENT';
  public static readonly COMMON_NO_PERMISSION = 'COMMON_NO_PERMISSION';
  public static readonly MOMENT_SHORT_DATE_TIME_PATTERN = 'MOMENT_SHORT_DATE_TIME_PATTERN';
  public static readonly MOMENT_SHORT_DATE_PATTERN = 'MOMENT_SHORT_DATE_PATTERN';
  public static readonly COMMON_PLACEHOLDER_EMPTY_LIST = 'COMMON_PLACEHOLDER_EMPTY_LIST';
  public static readonly COMMON_PLACEHOLDER_SELECT_ALL = 'COMMON_PLACEHOLDER_SELECT_ALL';
  public static readonly COMMON_PLACEHOLDER_SELECT_ALL_FILTERED = 'COMMON_PLACEHOLDER_SELECT_ALL_FILTERED';
  public static readonly COMMON_PLACEHOLDER_SEARCH = 'COMMON_PLACEHOLDER_SEARCH';
  public static readonly COMMON_PLACEHOLDER_USER_NOT_SELECTED_YET = 'COMMON_PLACEHOLDER_USER_NOT_SELECTED_YET';
  public static readonly COMMON_PLACEHOLDER_SELECT_ALL_USER = 'COMMON_PLACEHOLDER_SELECT_ALL_USER';
  public static readonly COMMON_PLACEHOLDER_EMPTY_USER_LIST = 'COMMON_PLACEHOLDER_EMPTY_USER_LIST';
  public static readonly COMMON_PLACEHOLDER_SELECT_ITEMS = 'COMMON_PLACEHOLDER_SELECT_ITEMS';
  public static readonly COMMON_PLACEHOLDER_SELECT_VISIBLE_ITEMS = 'COMMON_PLACEHOLDER_SELECT_VISIBLE_ITEMS';
  public static readonly COMMON_PLACEHOLDER_USER_GROUP_NOT_SELECTED_YET = 'COMMON_PLACEHOLDER_USER_GROUP_NOT_SELECTED_YET';
  public static readonly COMMON_PLACEHOLDER_SELECT_ALL_USER_GROUP = 'COMMON_PLACEHOLDER_SELECT_ALL_USER_GROUP';
  public static readonly COMMON_PLACEHOLDER_EMPTY_USER_GROUP_LIST = 'COMMON_PLACEHOLDER_EMPTY_USER_GROUP_LIST';
  public static readonly COMMON_PLACEHOLDER_EMPTY_ITEM_LIST_ARG = 'COMMON_PLACEHOLDER_EMPTY_ITEM_LIST_ARG';
  public static readonly MASTER_DATA_PLACEHOLDER_EMPTY_ITEM_LIST_ARG = 'MASTER_DATA_PLACEHOLDER_EMPTY_ITEM_LIST_ARG';
  public static readonly COMMON_PLACEHOLDER_ITEM_NOT_SELECTED_YET_ARG = 'COMMON_PLACEHOLDER_ITEM_NOT_SELECTED_YET_ARG';
  public static readonly COMMON_PLACEHOLDER_SELECT_ALL_ITEM_ARG = 'COMMON_PLACEHOLDER_SELECT_ALL_ITEM_ARG';
  public static readonly COMMON_PLACEHOLDER_SELECT_ALL_VISIBLE_ITEM_ARG = 'COMMON_PLACEHOLDER_SELECT_ALL_VISIBLE_ITEM_ARG';
  public static readonly COMMON_NAME = 'COMMON_NAME';
  public static readonly COMMON_USER = 'COMMON_USER';
  public static readonly PLACEHOLDER_STATE_NOT_SELECTED_YET = 'PLACEHOLDER_STATE_NOT_SELECTED_YET';
  public static readonly PLACEHOLDER_SELECT_ALL_STATE = 'PLACEHOLDER_SELECT_ALL_STATE';
  public static readonly PLACEHOLDER_EMPTY_STATE_LIST = 'PLACEHOLDER_EMPTY_STATE_LIST';
  public static readonly MESSAGE_LABEL_DEVICE = 'NOTIFICATION_LABEL_DEVICE';
  public static readonly MESSAGE_LABEL_USER = 'NOTIFICATION_LABEL_USER';
  public static readonly MESSAGE_LABEL_GROUP = 'NOTIFICATION_LABEL_GROUP';
  public static readonly INVOICE_TOAST_TITLE = 'INVOICE_TOAST_TITLE';
  public static readonly INVOICE_TOAST_MESSAGE = 'INVOICE_TOAST_MESSAGE';
  public static readonly TASK_RECORD_PANEL_HEADING_CREATE = 'TASK_RECORD_PANEL_HEADING_CREATE';
  public static readonly COMMON_CUSTOMERS = 'COMMON_CUSTOMERS';
  public static readonly CUSTOMER_RECORD_PANEL_HEADING_CREATE = 'CUSTOMER_RECORD_PANEL_HEADING_CREATE';
  public static readonly MENU_NAVBAR_SURVEY_RECORD = 'MENU_NAVBAR_SURVEY_RECORD';
  public static readonly COMMON_ERROR_DIALOG_TITLE = 'COMMON_ERROR_DIALOG_TITLE';
  public static readonly COMMON_ERROR_DIALOG_MESSAGE = 'COMMON_ERROR_DIALOG_MESSAGE';
  public static readonly TASK_RECORD_PDF_GENERATION_ERROR_MESSAGE = 'TASK_RECORD_PDF_GENERATION_ERROR_MESSAGE';
  public static readonly COMMON_DICTIONARY = 'COMMON_DICTIONARY';
  public static readonly MENU_NAVBAR_MENU_ADMINISTRATION = 'MENU_NAVBAR_MENU_ADMINISTRATION';
  public static readonly COMMON_VALIDATION_ERROR = 'COMMON_VALIDATION_ERROR';
  public static readonly USER_PLACEHOLDER_USER_GROUPS = 'USER_PLACEHOLDER_USER_GROUPS';
  public static readonly TASK_RECORD_POC_ADDRESS_NONE = 'TASK_RECORD_POC_ADDRESS_NONE';
  public static readonly TASK_RECORD_POC_ADDRESS_NEW = 'TASK_RECORD_POC_ADDRESS_NEW';
  public static readonly USER_PLACEHOLDER_CUSTOMER_RECORD = 'USER_PLACEHOLDER_CUSTOMER_RECORD';
  public static readonly USER_PLACEHOLDER_COMPANY = 'USER_PLACEHOLDER_COMPANY';
  public static readonly STOCK_ITEM_MOVE_DESTINATION = 'STOCK_ITEM_MOVE_DESTINATION';
  public static readonly STOCK_ITEM_MOVE_SOURCE = 'STOCK_ITEM_MOVE_SOURCE';
  public static readonly COMMON_SUCCESS = 'COMMON_SUCCESS';
  public static readonly COMMON_IMPORT_SUCCESS = 'COMMON_IMPORT_SUCCESS';
  public static readonly FORM_DOCUMENT_SUCCESSFUL_UPLOAD = 'FORM_DOCUMENT_SUCCESSFUL_UPLOAD';
  public static readonly FORM_DOCUMENT_UPLOAD_ERROR = 'FORM_DOCUMENT_UPLOAD_ERROR';
  public static readonly FORM_DOCUMENT_SUCCESSFUL_DELETE = 'FORM_DOCUMENT_SUCCESSFUL_DELETE';
  public static readonly FORM_DOCUMENT_DELETE_ERROR = 'FORM_DOCUMENT_DELETE_ERROR';
  public static readonly FORM_DOCUMENT_FILE_LIMIT_EXCEEDED = 'FORM_DOCUMENT_FILE_LIMIT_EXCEEDED';
  public static readonly FORM_DOCUMENT_ERROR_UNSAVED = 'FORM_DOCUMENT_ERROR_UNSAVED';
  public static readonly FORM_RECORD_INVOICE_FIELD_COPY_NO_OTHER = 'FORM_RECORD_INVOICE_FIELD_COPY_NO_OTHER';
  public static readonly PROCESS_CREATE_ERROR_NO_ORDER = 'PROCESS_CREATE_ERROR_NO_ORDER';
  public static readonly PROCESS_CREATE_ERROR_ORDER_WITH_PROCESS = 'PROCESS_CREATE_ERROR_ORDER_WITH_PROCESS';
  public static readonly PROCESS_CREATE_ERROR_NOT_ALLOWED_ORDER_STATE = 'PROCESS_CREATE_ERROR_NOT_ALLOWED_ORDER_STATE';
  public static readonly PROCESS_EDIT_DELETE_ORDER_ERROR = 'PROCESS_EDIT_DELETE_ORDER_ERROR';
  public static readonly COMMON_VALUE_ALL = 'COMMON_VALUE_ALL';
  public static readonly FORM_RECORD_STOCK_DISCOUNT_CUSTOM = 'FORM_RECORD_STOCK_DISCOUNT_CUSTOM';
  public static readonly FORM_RECORD_STOCK_DISCOUNT_NONE = 'FORM_RECORD_STOCK_DISCOUNT_NONE';
  public static readonly STOCK_LABEL_OWNER_USER = 'STOCK_LABEL_OWNER_USER';
  public static readonly ORDER_CREATE_ERROR_NO_INTERNAL_USER = 'ORDER_CREATE_ERROR_NO_INTERNAL_USER';
  public static readonly PROCESS_EDIT_PROCESS_START_SUCCESS = 'PROCESS_EDIT_PROCESS_START_SUCCESS';
  public static readonly ORDER_LIST_NO_OPEN_PROCESSES = 'ORDER_LIST_NO_OPEN_PROCESSES';
  public static readonly FORM_RECORD_STOCK_CURRENCY_ERROR = 'FORM_RECORD_STOCK_CURRENCY_ERROR';
  public static readonly FORM_RECORD_STOCK_DISPERSION_PERCENT_ERROR = 'FORM_RECORD_STOCK_DISPERSION_PERCENT_ERROR';
  public static readonly COMMON_PLACEHOLDER_ASSIGNEE_NOT_SELECTED_YET = 'COMMON_PLACEHOLDER_ASSIGNEE_NOT_SELECTED_YET';
  public static readonly ORDER_EDIT_FINALIZE_ERROR = 'ORDER_EDIT_FINALIZE_ERROR';
  public static readonly SHIPPING_DEMAND_SAVE_ERROR = 'SHIPPING_DEMAND_SAVE_ERROR';
  public static readonly SHIPMENT_CHANGE_SUBTRANSPORTER_ERROR = 'SHIPMENT_CHANGE_SUBTRANSPORTER_ERROR';
  public static readonly ORDER_EDIT_FINALIZE_INSUFFICIENT_ERROR = 'ORDER_EDIT_FINALIZE_INSUFFICIENT_ERROR';
  public static readonly PROCESS_TASK_NO_ASSIGNEE_SET = 'PROCESS_TASK_NO_ASSIGNEE_SET';
  public static readonly XLSX_IMPORT_ERROR = 'XLSX_IMPORT_ERROR';
  public static readonly FORM_ITEM_READONLY_PICTURE_NO_IMAGE_MESSAGE = 'FORM_ITEM_READONLY_PICTURE_NO_IMAGE_MESSAGE';
  public static readonly FORM_ITEM_READONLY_PICTURE_NO_IMAGE_TITLE = 'FORM_ITEM_READONLY_PICTURE_NO_IMAGE_TITLE';
  public static readonly FORM_ITEM_CUSTOMER_NO_CUSTOMER_TYPE_MESSAGE = 'FORM_ITEM_CUSTOMER_NO_CUSTOMER_TYPE_MESSAGE';
  public static readonly FORM_ITEM_USER_NO_USER_GROUP_MESSAGE = 'FORM_ITEM_USER_NO_USER_GROUP_MESSAGE';
  public static readonly FORM_ITEM_CUSTOMER_NO_CUSTOMER_TYPE_IMAGE_TITLE = 'FORM_ITEM_CUSTOMER_NO_CUSTOMER_TYPE_IMAGE_TITLE';
  public static readonly FORM_ITEM_USER_NO_USER_GROUP_IMAGE_TITLE = 'FORM_ITEM_USER_NO_USER_GROUP_IMAGE_TITLE';
  public static readonly COMMON_WARNING_TITLE = 'COMMON_WARNING_TITLE';
  public static readonly MESSAGE_SELECT_LIST_ITEM_TYPE = 'NOTIFICATION_SELECT_LIST_ITEM_TYPE';
  public static readonly FORM_ITEM_CREATE_CHECKBOX_ENABLE_FIELD_IF_CHECKED = 'FORM_ITEM_CREATE_CHECKBOX_ENABLE_FIELD_IF_CHECKED';
  public static readonly FORM_EDIT_LIST_ITEM_ACTIVATOR_LIST_ITEM = 'FORM_EDIT_LIST_ITEM_ACTIVATOR_LIST_ITEM';
  public static readonly FORM_EDIT_LIST_ITEM_ACTIVATED_FORM_FIELD = 'FORM_EDIT_LIST_ITEM_ACTIVATED_FORM_FIELD';
  public static readonly FORM_EDIT_LIST_ITEM_ACTIVATED_FORM_GROUP = 'FORM_EDIT_LIST_ITEM_ACTIVATED_FORM_GROUP';
  public static readonly PROCESS_TASK_NO_ORDERS = 'PROCESS_TASK_NO_ORDERS';
  public static readonly TASK_BULK_MODIFY_ERROR = 'TASK_BULK_MODIFY_ERROR';
  public static readonly SHIPPING_DEMAND_ERROR_WRONG_STATE = 'SHIPPING_DEMAND_ERROR_WRONG_STATE';
  public static readonly SHIPPING_DEMAND_ERROR_HAS_TRANSPORT = 'SHIPPING_DEMAND_ERROR_HAS_TRANSPORT';
  public static readonly SHIPPING_DEMAND_ERROR_HAS_NO_TRANSPORTER = 'SHIPPING_DEMAND_ERROR_HAS_NO_TRANSPORTER';
  public static readonly SHIPPING_DEMAND_ERROR_NO_TRANSPORT_AVAILABLE = 'SHIPPING_DEMAND_ERROR_NO_TRANSPORT_AVAILABLE';
  public static readonly TRANSPORT_OPEN_ERROR_TITLE = 'TRANSPORT_OPEN_ERROR_TITLE';
  public static readonly TRANSPORT_OPEN_ERROR_MESSAGE = 'TRANSPORT_OPEN_ERROR_MESSAGE';
  public static readonly SHIPPING_DEMAND_ERROR_NO_VEHICLES = 'SHIPPING_DEMAND_ERROR_NO_VEHICLES';
  public static readonly SHIPPING_DEMAND_ERROR_NO_SHIPPING_DEMAND = 'SHIPPING_DEMAND_ERROR_NO_SHIPPING_DEMAND';
  public static readonly FORM_ITEM_STOCK_ALL_CATEGORIES = 'FORM_ITEM_STOCK_ALL_CATEGORIES';
  public static readonly TABLE_DOCUMENT_SCHEMA_FIELD_DATA_TYPE = 'TABLE_DOCUMENT_SCHEMA_FIELD_DATA_TYPE_';
  public static readonly COMMON_PLACEHOLDER_CUSTOMER_RECORD_NOT_SELECTED_YET = 'COMMON_PLACEHOLDER_CUSTOMER_RECORD_NOT_SELECTED_YET';
  public static readonly COMMON_PLACEHOLDER_EMPTY_CUSTOMER_RECORD_LIST = 'COMMON_PLACEHOLDER_EMPTY_CUSTOMER_RECORD_LIST';
  public static readonly TASK_RECORD_CREATE_ERROR_TITLE = 'TASK_RECORD_CREATE_ERROR_TITLE';
  public static readonly TASK_RECORD_CREATE_ERROR_MESSAGE_NO_PERMISSION = 'TASK_RECORD_CREATE_ERROR_MESSAGE_NO_PERMISSION';
  public static readonly TASK_RECORD_CREATE_ERROR_MESSAGE_NO_TASK = 'TASK_RECORD_CREATE_ERROR_MESSAGE_NO_TASK';
  public static readonly TABLE_DOCUMENT_SCHEMA_EDIT_SUCCESS = 'TABLE_DOCUMENT_SCHEMA_EDIT_SUCCESS';
  public static readonly TRANSPORT_TASK_SHIPMENT_MOVE_ERROR = 'TRANSPORT_TASK_SHIPMENT_MOVE_ERROR';
  public static readonly ORDER_CREATE_ERROR_TITLE = 'ORDER_CREATE_ERROR_TITLE';
  public static readonly ORDER_CREATE_ERROR_NO_COMPANY = 'ORDER_CREATE_ERROR_NO_COMPANY';
  public static readonly TOAST_BUTTON_VIEW_NOTIFICATION = 'TOAST_BUTTON_VIEW_NOTIFICATION';
  public static readonly COMMON_VALIDATION_MESSAGE_EMAIL = 'COMMON_VALIDATION_MESSAGE_EMAIL';
  public static readonly TRANSPORT_NOT_ENOUGH_WAYPOINTS = 'TRANSPORT_NOT_ENOUGH_WAYPOINTS';
  public static readonly FORM_EDITOR_FIELD_CANNOT_BE_DISABLED_ACTIVATOR_ERROR_TITLE = 'FORM_EDITOR_FIELD_CANNOT_BE_DISABLED_ACTIVATOR_ERROR_TITLE';
  public static readonly FORM_EDITOR_FIELD_CANNOT_BE_DISABLED_ACTIVATOR_ERROR_MESSAGE = 'FORM_EDITOR_FIELD_CANNOT_BE_DISABLED_ACTIVATOR_ERROR_MESSAGE';
  public static readonly FORM_EDITOR_FIELD_CANNOT_BE_DISABLED_ACTIVATEE_ERROR_TITLE = 'FORM_EDITOR_FIELD_CANNOT_BE_DISABLED_ACTIVATEE_ERROR_TITLE';
  public static readonly FORM_EDITOR_FIELD_CANNOT_BE_DISABLED_ACTIVATEE_ERROR_MESSAGE = 'FORM_EDITOR_FIELD_CANNOT_BE_DISABLED_ACTIVATEE_ERROR_MESSAGE';
  public static readonly FORM_EDITOR_GROUP_CANNOT_BE_DISABLED_ACTIVATOR_ERROR_TITLE = 'FORM_EDITOR_GROUP_CANNOT_BE_DISABLED_ACTIVATOR_ERROR_TITLE';
  public static readonly FORM_EDITOR_GROUP_CANNOT_BE_DISABLED_ACTIVATOR_ERROR_MESSAGE = 'FORM_EDITOR_GROUP_CANNOT_BE_DISABLED_ACTIVATOR_ERROR_MESSAGE';
  public static readonly FORM_EDITOR_GROUP_CANNOT_BE_DISABLED_ACTIVATEE_ERROR_TITLE = 'FORM_EDITOR_GROUP_CANNOT_BE_DISABLED_ACTIVATEE_ERROR_TITLE';
  public static readonly FORM_EDITOR_GROUP_CANNOT_BE_DISABLED_ACTIVATEE_ERROR_MESSAGE = 'FORM_EDITOR_GROUP_CANNOT_BE_DISABLED_ACTIVATEE_ERROR_MESSAGE';
  public static readonly ROLE_CREATE_UPDATE_AVAILABLE_ROLES = 'ROLE_CREATE_UPDATE_AVAILABLE_ROLES';
  public static readonly ROLE_CREATE_UPDATE_SELECTED_ROLES = 'ROLE_CREATE_UPDATE_SELECTED_ROLES';
  public static readonly TRANSPORT_EMPTY_CREATE_NO_TRANSPORTER_COMPANIES = 'TRANSPORT_EMPTY_CREATE_NO_TRANSPORTER_COMPANIES';
  public static readonly MULTISELECT_PLACEHOLDER_UNKNOWN_ITEM = 'MULTISELECT_PLACEHOLDER_UNKNOWN_ITEM';
  public static readonly SHIPPING_DEMAND_SPLIT__ERROR_MIN = 'SHIPPING_DEMAND_SPLIT__ERROR_MIN';
  public static readonly SHIPMENT_ERROR_NO_VEHICLES = 'SHIPMENT_ERROR_NO_VEHICLES';
  public static readonly SHIPMENT_ERROR_NO_SHIPMENT = 'SHIPMENT_ERROR_NO_SHIPMENT';
  public static readonly SHIPMENT_LIST_SHIPMENT_DELETED = 'SHIPMENT_LIST_SHIPMENT_DELETED';
  public static readonly SHOPRENTER_LOGIN_NOT_OWNER = 'SHOPRENTER_LOGIN_NOT_OWNER';
  public static readonly SHOPRENTER_LOGIN_CONNECT_FAILED = 'SHOPRENTER_LOGIN_CONNECT_FAILED';
  public static readonly CUSTOMER_RECORD_EMAIL_ADDRESSES_REQUIRED = 'CUSTOMER_RECORD_EMAIL_ADDRESSES_REQUIRED';
  public static readonly CUSTOMER_RECORD_PHONE_NUMBERS_REQUIRED = 'CUSTOMER_RECORD_PHONE_NUMBERS_REQUIRED';
  public static readonly ORDER_IMPORT_ERROR_SUCCESSFUL_REJECT = 'ORDER_IMPORT_ERROR_SUCCESSFUL_REJECT';
  public static readonly TASK_RECORD_MANUAL_INVOICE_CREATE_NO_ITEMS_SELECTED_ERROR =
    'TASK_RECORD_MANUAL_INVOICE_CREATE_NO_ITEMS_SELECTED_ERROR';
  public static readonly TASK_RECORD_MANUAL_INVOICE_CREATE_ERROR = 'TASK_RECORD_MANUAL_INVOICE_CREATE_ERROR';
  public static readonly TASK_RECORD_MANUAL_INVOICE_CREATE_SUCCESS_TITLE = 'TASK_RECORD_MANUAL_INVOICE_CREATE_SUCCESS_TITLE';
  public static readonly TASK_RECORD_MANUAL_INVOICE_CREATE_SUCCESS_BODY = 'TASK_RECORD_MANUAL_INVOICE_CREATE_SUCCESS_BODY';
  public static readonly ANDROID_VERSION_SUCCESS_BODY = 'ANDROID_VERSION_SUCCESS_BODY';
  public static readonly ANDROID_VERSION_DELETE_SUCCESS_BODY = 'ANDROID_VERSION_DELETE_SUCCESS_BODY';
  public static readonly TASK_RECORD_QUICK_CREATE_BATCH_CUSTOMER_RECORD_ERROR_ZERO =
    'TASK_RECORD_QUICK_CREATE_BATCH_CUSTOMER_RECORD_ERROR_ZERO';
  public static readonly TASK_RECORD_QUICK_CREATE_BATCH_CUSTOMER_RECORD_ERROR = 'TASK_RECORD_QUICK_CREATE_BATCH_CUSTOMER_RECORD_ERROR';
  public static readonly TASK_RECORD_QUICK_CREATE_BATCH_CUSTOMER_RECORD_SUCCESS = 'TASK_RECORD_QUICK_CREATE_BATCH_CUSTOMER_RECORD_SUCCESS';
  public static readonly STOCK_ITEM_PICTURE = 'STOCK_ITEM_PICTURE';
  public static readonly STOCK_ITEM_PICTURE_DELETE_MESSAGE = 'STOCK_ITEM_PICTURE_DELETE_MESSAGE';
  public static readonly COLONNADE_CLIENT_DOCUMENT_STAT_XLSX_EXPORT_NO_RESULT = 'COLONNADE_CLIENT_DOCUMENT_STAT_XLSX_EXPORT_NO_RESULT';
  public static readonly FORM_RECORD_STOCK_VALUE_ERROR = 'FORM_RECORD_STOCK_VALUE_ERROR';
  public static readonly WORKFLOW_EDIT_TRANSITION_FORM_FIELD_RULE_OPERATOR_REQUIRED
    = 'WORKFLOW_EDIT_TRANSITION_FORM_FIELD_RULE_OPERATOR_REQUIRED';
  public static readonly WORKFLOW_EDITOR_TASK_SAVE_SUCCESS = 'WORKFLOW_EDITOR_TASK_SAVE_SUCCESS';
  public static readonly WORKFLOW_EDITOR_TASK_SAVE_ERROR = 'WORKFLOW_EDITOR_TASK_SAVE_ERROR';
  public static readonly WORKFLOW_EDITOR_GROUP_SAVE_SUCCESS = 'WORKFLOW_EDITOR_GROUP_SAVE_SUCCESS';
  public static readonly WORKFLOW_EDITOR_GROUP_SAVE_ERROR = 'WORKFLOW_EDITOR_GROUP_SAVE_ERROR';
  public static readonly WORKFLOW_EDITOR_TRANSITION_SAVE_SUCCESS = 'WORKFLOW_EDITOR_TRANSITION_SAVE_SUCCESS';
  public static readonly WORKFLOW_EDITOR_TRANSITION_SAVE_ERROR = 'WORKFLOW_EDITOR_TRANSITION_SAVE_ERROR';
  public static readonly SHIPPING_DEMAND_ITEM_CODES_ERROR = 'SHIPPING_DEMAND_ITEM_CODES_ERROR';
  public static readonly FORM_ITEM_STOCK_AVAILABLE_DISCOUNTS_ERROR = 'FORM_ITEM_STOCK_AVAILABLE_DISCOUNTS_ERROR';
  public static readonly TASK_RECORD_PLACE_OF_CONSUMPTION_CONTACT_LOCATION_REQUIRED
    = 'TASK_RECORD_PLACE_OF_CONSUMPTION_CONTACT_LOCATION_REQUIRED';
  public static readonly TASK_RECORD_PLACE_OF_CONSUMPTION_REQUIRED = 'TASK_RECORD_PLACE_OF_CONSUMPTION_REQUIRED';
  public static readonly TASK_RECORD_QUICK_CREATE_PLACE_OF_CONSUMPTION_ERROR = 'TASK_RECORD_QUICK_CREATE_PLACE_OF_CONSUMPTION_ERROR';
  public static readonly CUSTOMER_RECORD_INVITE_TO_HELPDESK_UNSELECTED_ERROR = 'CUSTOMER_RECORD_INVITE_TO_HELPDESK_UNSELECTED_ERROR';
  public static readonly STOCK_ITEM_CHANGE_ERROR = 'STOCK_ITEM_CHANGE_ERROR';
  public static readonly STOCK_ITEM_CHANGE_ERROR_TITLE = 'STOCK_ITEM_CHANGE_ERROR_TITLE';
}
