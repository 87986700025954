import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  @Input()
  titleEnabled?: boolean = true;

  @Input()
  title?: string;

  @Input()
  messageEnabled?: boolean = true;

  @Input()
  message?: string;

  @Input()
  positiveButtonEnabled?: boolean = true;

  @Input()
  negativeButtonEnabled?: boolean = true;

  @Input()
  positiveButton?: string;

  @Input()
  negativeButton?: string;

  @Input()
  hideOnConfirm: boolean = true;

  @Input()
  alertType?: AlertType = AlertType.INFO;

  @Output()
  onConfirm: EventEmitter<any> = new EventEmitter();

  @Output()
  onCancel: EventEmitter<any> = new EventEmitter();

  @ViewChild('dialog', {static: true})
  dialog: ModalDirective;

  dialogVisible = false;

  emitterValue?: any;

  constructor(private translateService: TranslateService) {
    if (!this.title) {
      this.translateService.get('CONFIRM_DIALOG_TITLE_DELETE').subscribe((result) => this.title = result);
    }
    if (!this.message) {
      this.translateService.get('CONFIRM_DIALOG_MESSAGE_DELETE').subscribe((result) => this.message = result);
    }
    if (!this.positiveButton) {
      this.translateService.get('COMMON_BUTTON_DELETE').subscribe((result) => this.positiveButton = result);
    }
    if (!this.negativeButton) {
      this.translateService.get('COMMON_BUTTON_CANCEL').subscribe((result) => this.negativeButton = result);
    }
  }

  ngOnInit() {
  }

  showDialog(value?: any) {
    this.emitterValue = value;
    this.dialogVisible = true;
    this.dialog.show();
  }

  closeDialog() {
    this.emitterValue = undefined;
    this.dialogVisible = false;
    this.dialog.hide();
  }

  confirmed() {
    this.onConfirm.emit(this.emitterValue);
    if (this.hideOnConfirm) {
      this.closeDialog();
    }
  }

  canceled() {
    this.onCancel.emit();
    this.closeDialog();
  }

}

export enum AlertType {
  INFO = 'info',
  WARNING = 'warning',
  DANGER = 'danger'
}
