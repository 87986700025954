import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { GrantedPermissionSet, GrantedPermissionSetResolver, RightResolver, RightService } from '../../../lib/right.service';
import { Dates, OffsetDateTime } from '../../../lib/util/dates';
import { SearchUtils } from '../../../util/search-utils';
import { MultiselectOptionItem, OrderFieldFunction, OrderFieldModel, SelectUtils, UiConstants } from '../../../util/core-utils';
import { OrderField } from '../../../lib/query/orderfields';
import { ExteriorTransport, ExteriorTransportService } from '../../../lib/exterior-transport/exterior-transport.service';
import Company = ExteriorTransport.Company;
import ExteriorTransportState = ExteriorTransport.ExteriorTransportState;
import { OrderType, QueryResult } from '../../../lib/util/services';
import { Angular2Multiselects } from '../../../util/multiselect';
import { RightModel } from '../../../app.rights';
import { ExteriorTransportSearch, ExteriorTransportSearchService } from '../../../lib/exterior-transport/exterior-transport-search-service';
import { Set } from 'immutable';
import { CompanyService } from '../../../lib/company/company.service';
import { FilterField } from '../../../lib/query/filterfields';
import { CriteriaBuilder } from '../../../util/model-utils';
import { CompanyMultiselectProvider } from '../../../lib/company/company-multiselect.provider';
import { OperationRights } from '../../../app.right-definitions';
import { ExteriorTransportRightModel } from '../../../lib/exterior-transport/exterior-transport-right.model';

@Component({
  selector: 'app-exterior-transport-list',
  templateUrl: './exterior-transport-list.component.html',
  styleUrls: ['./exterior-transport-list.component.scss']
})
export class ExteriorTransportListComponent extends SearchUtils.SearchableList<ExteriorTransportSearch.Model> implements OnInit, OnDestroy {

  UiConstants = UiConstants;
  SelectUtils = SelectUtils;
  ExteriorTransportSearch = ExteriorTransportSearch;

  queryModel: OrderFieldModel<OrderField.ExteriorTransport>
    = new OrderFieldModel(ExteriorTransportSearch.OrderFunctions.ID, OrderType.DESC);
  searchModel: ExteriorTransportSearch.Model = new ExteriorTransportSearch.Model();
  transporterCompanies: MultiselectOptionItem<number>[] = [];

  dropdownSettings: Angular2Multiselects.Settings;

  exteriorTransportList: ExteriorTransportModel[] = [];
  states: ExteriorTransportSearch.StateFilterOption[] = [];
  rightModel: RightModel = RightModel.empty();

  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  constructor(
    private exteriorTransportService: ExteriorTransportService,
    private exteriorTransportSearchService: ExteriorTransportSearchService,
    private companyMultiselectProvider: CompanyMultiselectProvider,
    private companyService: CompanyService,
    private rightService: RightService,
    injector: Injector) {
    super(ExteriorTransportSearch.Model, injector);
  }

  ngOnInit() {
    this.loadRightModels();
    this.initBreadcrumb();
    this.initSearch();
    this.initDropdownSettings();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  initBreadcrumb() {
    this.translateService.get('MENU_NAVBAR_NAV_TITLE_EXTERIOR_TRANSPORT').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
  }

  initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .remoteSearch(true)
      .enableCheckAll(false)
      .build();
  }

  loadSearch(completion: () => void) {
    this.exteriorTransportSearchService.getSearchData({})
      .subscribe(
        (result: ExteriorTransportSearch.SearchDataResult) => {
          this.queryModel = result.searchData.queryModel;
          this.searchModel = result.searchData.searchModel;
          completion();
        }
      );
  }

  onFirstSearchOpen(): void {
    this.loadStateFilterOptions();
    this.onTransporterCompanySearch();
  }

  onTransporterCompanySearch(predicate?: string) {
    this.companyMultiselectProvider.searchTransporterCompanies(predicate).subscribe((companies) => {
      this.transporterCompanies = companies;
    })
  }

  private loadStateFilterOptions() {
    this.states = [];
    this.states.push(ExteriorTransportSearch.Model.defaultSelectedState);
    ExteriorTransport.exteriorTransportStates.forEach((stateObject) => {
      this.states.push({
        id: stateObject.state,
        stringKey: stateObject.stringKey
      });
    });
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: OrderFieldFunction<OrderField.ExteriorTransport>) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const rights = Set.of(
      OperationRights.EXTERIOR_TRANSPORT_DELETE
    );
    const order = this.queryModel.createOrderFunction();
    const filter = this.createFilter();
    this.exteriorTransportService.query({
      fields: f => f.each,
      order: order,
      filter: filter,
      rights: rights,
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined,
    }).subscribe((result: QueryResult<ExteriorTransport.ExteriorTransport>) => {
      this.exteriorTransportList = [];
      result.items.forEach((transport: ExteriorTransport.ExteriorTransport) => {
        const model = new ExteriorTransportModel();
        model.id = transport.id;
        model.rights = new ExteriorTransportRightModel(GrantedPermissionSetResolver.byGrantedRights(transport.grantedRights));
        model.serialNumber = transport.serialNumber;
        model.demanderCompany = transport.demanderCompany;
        model.transporterCompany = transport.transporterCompany;
        model.state = transport.state;
        const stateObject = ExteriorTransport.exteriorTransportStates.find(state => state.state === transport.state);
        if (stateObject) {
          model.stateStringKey = stateObject.stringKey;
        }
        model.creationTime = transport.creationTime;
        this.exteriorTransportList.push(model);
      });
      this.queryModel.currentPage = requestedPage;
      this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
    });
  }

  private createFilter() {
    return (f: FilterField.ExteriorTransport) => CriteriaBuilder.builder()
      .addString((serialNumber) => f.serial.number.containsIgnoreCase(serialNumber), this.searchModel.serialNumber)
      .addNumber((transporterCompanyId) => f.transporterCompany.id.eq(transporterCompanyId), this.searchModel.transporterCompanyId)
      .addEnum((state) => f.state.eq(state), this.searchModel.state.id)
      .build();
  }

  onSearchClicked() {
    this.loadList(1);
  }

  onSearchReset() {
    this.exteriorTransportSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.toggleSearch();
          this.loadList(1);
        });
      }
    );
  }

  deleteExteriorTransport(id: number) {
    this.exteriorTransportService.delete({id: id}).subscribe((result) => {
      this.loadList();
    });
  }

  ngOnDestroy() {
    this.saveSearch();
  }

  private saveSearch() {
    const request = {
        searchData: {
          queryModel: this.queryModel,
          searchModel: this.searchModel
        }
      }
    ;
    this.exteriorTransportSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

}

export class ExteriorTransportModel {
  id: number;
  rights: ExteriorTransportRightModel = new ExteriorTransportRightModel(GrantedPermissionSetResolver.empty());
  serialNumber: string = '';
  demanderCompany?: Company;
  transporterCompany: Company;
  state: ExteriorTransportState;
  stateStringKey: string = '';
  creationTime: OffsetDateTime = Dates.emptyOffsetDateTime();

  get demanderCompanyName(): string | undefined {
    return this.demanderCompany ? this.demanderCompany.name : 'EXTERIOR_TRANSPORT_MULTIPLE_DEMANDERS';
  }
}
