// Each class should be an interface, but it is easier to define the constant fields in the constructors.
// For now we have only one query language so it is not a problem.
// Always use the public Query namespace for variable definitions so we will be able to introduce other language easily.

import { DqlQuery, Query } from '../field';

export class TaskRecordSubTask {

  readonly id: Query.OrderField;
  readonly name: Query.OrderField;
  readonly type: Query.OrderField;
  readonly note: Query.OrderField;
  readonly state: Query.OrderField;

  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this.id = new DqlQuery.OrderField(prefix + 'id');
    this.name = new DqlQuery.OrderField(prefix + 'name');
    this.type = new DqlQuery.OrderField(prefix + 'type');
    this.note = new DqlQuery.OrderField(prefix + 'note');
    this.state = new DqlQuery.OrderField(prefix + 'state');
  }

}
