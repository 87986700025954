<div>
  <!--lightbox container-->
  <div>
    <div class="image-lightbox">
      <!--close button-->
      <span class="close cursor" (click)="closeImageViewerWindow()">&times;</span>
      <!--image to display-->
      <img src="{{ getAttachedImageUrl() }}" alt="">
      <!--navigation arrows-->
      <a class="prev" (click)="onPreviousClicked()">&#10094;</a>
      <a class="next" (click)="onNextClicked()">&#10095;</a>
    </div>
  </div>
  <!--lightbox container end-->
</div>
