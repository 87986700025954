/* eslint-disable */
import { Observable, Observer } from 'rxjs';
import { Injectable } from '@angular/core';
import { FieldValidationError, Order, PagingRequest, QueryResult, ResourceQueryResult, Services } from '../util/services';
import { List, Map, Set } from 'immutable';
import { OffsetDateTime } from '../util/dates';
import { DqlQuery, Query } from '../query/field';
import { FilterField } from '../query/filterfields';
import { OrderField } from '../query/orderfields';
import { ExteriorTransportResource, ExteriorTransportResourceService } from './exterior-transport-resource.service';
import { Transport } from '../transport/transport.service';
import { EmptyMessage, IdentityMessage } from '../util/messages';
import { ObservableErrorResourceParser } from '../util/errors';
import { Keys as ShipmentGroupKeys, ShipmentGroup, ShipmentGroupMapper } from '../shipment-group/shipment-group.service';
import { ShipmentGroupResource } from '../shipment-group/shipment-group-resource.service';
import { DownloadedFile } from '../util/downloaded-files';
/* eslint-enable */

export namespace ExteriorTransport {

  export interface Service {
    query(request: QueryRequest): Observable<QueryResult<ExteriorTransport>>;

    get(request: GetRequest): Observable<ExteriorTransport>;
  }

  export interface ExteriorTransport {
    id: number;
    externalId: string;
    grantedRights: Set<string>;
    state: ExteriorTransportState;
    creationTime: OffsetDateTime;
    startTime?: OffsetDateTime;
    finishTime?: OffsetDateTime;
    serialNumber: string;
    transporterCompany: Company;
    demanderCompany?: Company;
  }

  export enum ExteriorTransportState {
    NEW = 'NEW',
    IN_PROGRESS = 'IN_PROGRESS',
    FINISHED = 'FINISHED',
  }

  export interface ExteriorTransportStateObject {
    state: ExteriorTransportState;
    stringKey: string;
    iconClass: string;
  }

  export const exteriorTransportStates: ExteriorTransportStateObject[] = [
    {state: ExteriorTransportState.NEW, stringKey: 'EXTERIOR_TRANSPORT_STATE_NEW', iconClass: 'icomoon-new-state-new'},
    {
      state: ExteriorTransportState.IN_PROGRESS,
      stringKey: 'EXTERIOR_TRANSPORT_STATE_IN_PROGRESS',
      iconClass: 'icomoon-new-state-in-progress'
    },
    {state: ExteriorTransportState.FINISHED, stringKey: 'EXTERIOR_TRANSPORT_STATE_FINISHED', iconClass: 'icomoon-state-action-validate'},
  ];

  export const ValidExteriorTransportStatesForDelete: Set<ExteriorTransportState> = Set.of(
    ExteriorTransportState.NEW
  );

  export interface Company {
    id: number;
    name: string;
  }

  export interface QueryRequest {
    fields?: Query.FieldFunction<Fields.ExteriorTransport>;
    filter?: Query.FilterFunction<FilterField.ExteriorTransport>;
    order?: Query.OrderFunction<OrderField.ExteriorTransport>;
    paging?: PagingRequest;
    rights?: Set<string>;
  }

  export interface GetRequest {
    id: number;
    rights?: Set<string>;
  }

  export interface CreateByDemandsRequest {
    demandIds: Set<number>;
  }

  export interface CreateByShipmentsRequest {
    shipmentIds: Set<number>;
  }

  export interface RemoveShipmentRequest {
    exteriorTransportId: number;
    shipmentId: number;
    resetTrackingNumber: boolean;
    trackingNumberPresent: boolean;
  }

  export interface GetShipmentsRequest {
    exteriorTransportId: number;
    q?: string;
    orders?: Set<Order<ShipmentGroup.OrderField>>;
    paging?: PagingRequest;
  }

  export interface EventLogRequest {
    id: number;
    orders?: Set<Order<EventLogOrderField>>;
    paging?: PagingRequest;
  }

  export interface EventLog {
    id: number;
    creationTime: OffsetDateTime;
    type: EventLogType;
    user: {
      id: number;
      personName: string;
    };
  }

  export type EventLogType = 'CREATE'
    | 'START'
    | 'FINISH'
    | 'HANDOVER_FINISH'
    | 'TAKEOVER_FINISH'
    | 'MPL_EXPORT'
    | 'MPL_IMPORT'
    | 'DOCUMENT_UPLOAD'
    | 'DOCUMENT_DELETE'
    | 'SHIPMENT_ADD'
    | 'SHIPMENT_REMOVE';

  export interface EventLogTypeObject {
    type: EventLogType;
    stringKey: string;
  }

  export const eventLogTypes: EventLogTypeObject[] = [
    {type: 'CREATE', stringKey: 'EXTERIOR_TRANSPORT_EVENT_TYPE_CREATE'},
    {type: 'START', stringKey: 'EXTERIOR_TRANSPORT_EVENT_TYPE_START'},
    {type: 'FINISH', stringKey: 'EXTERIOR_TRANSPORT_EVENT_TYPE_FINISH'},
    {type: 'HANDOVER_FINISH', stringKey: 'EXTERIOR_TRANSPORT_EVENT_TYPE_HANDOVER_FINISH'},
    {type: 'TAKEOVER_FINISH', stringKey: 'EXTERIOR_TRANSPORT_EVENT_TYPE_TAKEOVER_FINISH'},
    {type: 'MPL_EXPORT', stringKey: 'EXTERIOR_TRANSPORT_EVENT_TYPE_MPL_EXPORT'},
    {type: 'MPL_IMPORT', stringKey: 'EXTERIOR_TRANSPORT_EVENT_TYPE_MPL_IMPORT'},
    {type: 'DOCUMENT_UPLOAD', stringKey: 'EXTERIOR_TRANSPORT_EVENT_TYPE_DOCUMENT_UPLOAD'},
    {type: 'DOCUMENT_DELETE', stringKey: 'EXTERIOR_TRANSPORT_EVENT_TYPE_DOCUMENT_DELETE'},
    {type: 'SHIPMENT_ADD', stringKey: 'EXTERIOR_TRANSPORT_EVENT_TYPE_SHIPMENT_ADD'},
    {type: 'SHIPMENT_REMOVE', stringKey: 'EXTERIOR_TRANSPORT_EVENT_TYPE_SHIPMENT_REMOVE'},
  ];

  export enum EventLogOrderField {
    CREATION_TIME
  }

  export function toPublic(r: ExteriorTransportResource.ExteriorTransport): ExteriorTransport {
    return {
      id: r.id,
      externalId: r.external_id,
      grantedRights: r.granted_rights ? Set.of(...r.granted_rights) : Set.of(),
      state: ExteriorTransportState[r.state],
      creationTime: Services.toOffsetDateTime(r.creation_time),
      startTime: Services.toOffsetDateTime(r.start_time),
      finishTime: Services.toOffsetDateTime(r.finish_time),
      serialNumber: r.serial_number,
      transporterCompany: r.transporter_company,
      demanderCompany: r.demander_company
    };
  }

  export function toPublicEventLogList(resourceList: ExteriorTransportResource.EventLog[]): List<ExteriorTransport.EventLog> {
    return List.of(...resourceList.map((r) => this.toPublicEventLog(r)));
  }

  export function toPublicEventLog(r: ExteriorTransportResource.EventLog): ExteriorTransport.EventLog {
    return {
      id: r.id,
      creationTime: Services.toOffsetDateTime(r.creation_time),
      type: <EventLogType>r.type,
      user: {
        id: r.user.id,
        personName: r.user.person_name
      }
    };
  }

  export function translateError(error: any): any {
    const res = ObservableErrorResourceParser.parseError(error);
    const fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
    const fieldErrorMap = ObservableErrorResourceParser.toFieldErrorMap(Keys.toValidatedField, fieldErrors);
    if (!fieldErrorMap.isEmpty()) {
      return FieldValidationError.of(fieldErrorMap);
    }
    return error;
  }

  export namespace Fields {

    export class ExteriorTransport {

      readonly id: Query.Field = new DqlQuery.Field('id');
      readonly grantedRights: Query.Field = new DqlQuery.Field('granted_rights');
      readonly externalId: Query.Field = new DqlQuery.Field('external_id');
      readonly state: Query.Field = new DqlQuery.Field('state');
      readonly creationTime: Query.Field = new DqlQuery.Field('creation_time');
      readonly startTime: Query.Field = new DqlQuery.Field('start_time');
      readonly finishTime: Query.Field = new DqlQuery.Field('finish_time');
      readonly serialNumber: Query.Field = new DqlQuery.Field('serial_number');
      readonly demanderCompany: Query.Field = new DqlQuery.Field('demander_company');
      readonly transporterCompany: Query.Field = new DqlQuery.Field('transporter_company');

      get each(): Set<Query.Field> {
        return Set.of(
          this.id,
          this.grantedRights,
          this.externalId,
          this.state,
          this.creationTime,
          this.startTime,
          this.finishTime,
          this.serialNumber,
          this.demanderCompany,
          this.transporterCompany
        );
      }

    }

  }

  class Keys {

    private static readonly keyValidatedFieldMap: Map<string, Transport.ValidatedField> = Map.of(
    );

    public static toValidatedField(fieldKey: string): Transport.ValidatedField {
      return Keys.keyValidatedFieldMap.get(fieldKey, Transport.ValidatedField.UNKNOWN);
    }

  }

}

class EventLogKeys {

  private static readonly CREATION_TIME = 'creation_time';


  private static readonly orderFieldKeyMap: Map<ExteriorTransport.EventLogOrderField, string> = Map.of(
    ExteriorTransport.EventLogOrderField.CREATION_TIME, EventLogKeys.CREATION_TIME,
  );

  public static toOrderFieldKey(field: ExteriorTransport.EventLogOrderField): string {
    return EventLogKeys.orderFieldKeyMap.get(field)!;
  }

}

@Injectable()
export class ExteriorTransportService implements ExteriorTransport.Service {

  private readonly filterField = new FilterField.ExteriorTransport();
  private readonly orderField = new OrderField.ExteriorTransport();
  private readonly fields = new ExteriorTransport.Fields.ExteriorTransport();

  constructor(private resourceService: ExteriorTransportResourceService) {
  }

  query(request: ExteriorTransport.QueryRequest): Observable<QueryResult<ExteriorTransport.ExteriorTransport>> {
    const filter: string | undefined = DqlQuery.toOptionalFilter(this.filterField, request.filter);
    const order: string | undefined = DqlQuery.toOptionalOrder(this.orderField, request.order);
    const fields: string | undefined = DqlQuery.toOptionalFields(this.fields, request.fields);
    return Observable.create((observer: Observer<QueryResult<ExteriorTransport.ExteriorTransport>>) => {
      const resourceRequest: ExteriorTransportResource.QueryRequest = {
        filter: filter,
        order: order,
        fields: fields,
        rights: DqlQuery.RightRequestSerializer.getInstance().serialize(request.rights),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined
      };
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<ExteriorTransportResource.ExteriorTransport>) => {
          observer.next({
            items: List.of(...result.items.map((item) => ExteriorTransport.toPublic(item))),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  get(request: ExteriorTransport.GetRequest): Observable<ExteriorTransport.ExteriorTransport> {
    return Observable.create((observer: Observer<ExteriorTransport.ExteriorTransport>) => {
      const resourceRequest: ExteriorTransportResource.GetRequest = {
        id: request.id,
        rights: DqlQuery.RightRequestSerializer.getInstance().serialize(request.rights)
      };
      return this.resourceService.get(resourceRequest).subscribe(
        (result: ExteriorTransportResource.ExteriorTransport) => {
          observer.next(ExteriorTransport.toPublic(result));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  delete(request: IdentityMessage): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.resourceService.delete(request).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  createByDemands(request: ExteriorTransport.CreateByDemandsRequest): Observable<IdentityMessage> {
    return Observable.create((observer: Observer<IdentityMessage>) => {
      const resourceRequest: ExteriorTransportResource.CreateByDemandsRequest = {
        demand_ids: request.demandIds.toArray()
      };
      return this.resourceService.createByDemands(resourceRequest).subscribe(
        (result: IdentityMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(ExteriorTransport.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  createByShipments(request: ExteriorTransport.CreateByShipmentsRequest): Observable<IdentityMessage> {
    return Observable.create((observer: Observer<IdentityMessage>) => {
      const resourceRequest: ExteriorTransportResource.CreateByShipmentsRequest = {
        shipment_ids: request.shipmentIds.toArray()
      };
      return this.resourceService.createByShipments(resourceRequest).subscribe(
        (result: IdentityMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(ExteriorTransport.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  removeShipment(request: ExteriorTransport.RemoveShipmentRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: ExteriorTransportResource.RemoveShipmentRequest = {
        exterior_transport_id: request.exteriorTransportId,
        shipment_id: request.shipmentId,
        reset_tracking_number: request.resetTrackingNumber
      };
      return this.resourceService.removeShipment(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        });
    });
  }

  start(request: IdentityMessage): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.resourceService.start(request).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        });
    });
  }

  getShipments(request: ExteriorTransport.GetShipmentsRequest): Observable<QueryResult<ShipmentGroup.ShipmentGroup>> {
    return Observable.create((observer: Observer<QueryResult<ShipmentGroup.ShipmentGroup>>) => {
      const resourceRequest: ExteriorTransportResource.GetShipmentsRequest = {
        exterior_transport_id: request.exteriorTransportId,
        q: request.q,
        order: Services.createOrderFieldParameter(ShipmentGroupKeys.toShipmentGroupOrderFieldKey, request.orders),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
      };
      return this.resourceService.getShipments(resourceRequest).subscribe(
        (result: ResourceQueryResult<ShipmentGroupResource.ShipmentGroup>) => {
          observer.next({
            items: ShipmentGroupMapper.toPublicShipmentGroupList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  mplExport(request: ExteriorTransport.GetRequest): Observable<DownloadedFile> {
    const resourceRequest: ExteriorTransportResource.GetRequest = {
      id: request.id,
    };
    return this.resourceService.mplExport(resourceRequest);
  }

  getEventLog(request: ExteriorTransport.EventLogRequest): Observable<QueryResult<ExteriorTransport.EventLog>> {
    return Observable.create((observer: Observer<QueryResult<ExteriorTransport.EventLog>>) => {
      const resourceRequest: ExteriorTransportResource.EventLogRequest = {
        id: request.id,
        order: Services.createOrderFieldParameter(EventLogKeys.toOrderFieldKey, request.orders),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
      };
      return this.resourceService.getEventLog(resourceRequest).subscribe(
        (result: ResourceQueryResult<ExteriorTransportResource.EventLog>) => {
          observer.next({
            items: ExteriorTransport.toPublicEventLogList(result.items),
            pagingResult: result.pagingResult
          });
        });
    });
  }

}
