/* eslint-disable */
import { Observable, Observer } from 'rxjs';
import { Injectable } from '@angular/core';
import { PagingRequest, QueryResult, ResourceQueryResult, Services } from '../util/services';
import { List, Map as ImmutableMap, Set } from 'immutable';
import { OffsetDateTime } from '../util/dates';
import { DqlQuery, Query } from '../query/field';
import { FilterField } from '../query/filterfields';
import { OrderField } from '../query/orderfields';
import { ManualWorklogResourceService, WorklogResource, WorklogResourceService } from './worklog-resource.service';
import { OrderFunctionResolver } from '../../util/core-utils';
import { DownloadedFile } from '../util/downloaded-files';
import { EmptyMessage } from '../util/messages';
import { InvoiceTag } from '../invoice/tag/invoice-tag.service';
/* eslint-enable */

export namespace Worklog {

  export interface Service {
    query(request: QueryRequest): Observable<QueryResult<Worklog>>;

    update(request: UpdateRequest): Observable<EmptyMessage>;
  }

  export interface Worklog {
    id: number;
    creationTime: OffsetDateTime;
    updateTime: OffsetDateTime;
    startTime: OffsetDateTime;
    endTime: OffsetDateTime;
    loggedTimeInMinutes?: number;
    estimatedTimeInMinutes?: number;
    worklogUserType: WorklogUserType;
    loggerUser: {
      id: number;
      personName: string;
      userName: string;
    };
    workerUser: {
      id: number;
      // Just to support assignee table cell
      personName: string;
      userName: string;
      userGroupIds: number[];
      profilePictureHash?: string;
    };
    taskRecord: {
      id: number;
      taskId: number;
      name: string;
    };
    overtime?: boolean;
  }

  export enum WorklogUserType {
    ASSIGNEE = 'ASSIGNEE',
    COLLABORATOR = 'COLLABORATOR',
  }

  export interface WorklogUserTypeObject {
    type: WorklogUserType;
    stringKey: string;
  }

  export const worklogUserTypes: WorklogUserTypeObject[] = [
    {type: WorklogUserType.ASSIGNEE, stringKey: 'WORKLOG_USER_TYPE_ASSIGNEE'},
    {type: WorklogUserType.COLLABORATOR, stringKey: 'WORKLOG_USER_TYPE_COLLABORATOR'},
  ];

  export interface QueryRequest {
    worklogScope?: WorklogScope;
    userId?: number;
    taskRecordId?: number;
    fields?: Query.FieldFunction<Fields.Worklog>;
    filter?: Query.FilterFunction<FilterField.Worklog>;
    order?: Query.OrderFunction<OrderField.Worklog>;
    paging?: PagingRequest;
  }

  export interface UpdateRequest {
    id: number;
    startTime: OffsetDateTime;
    endTime: OffsetDateTime;
    loggedTimeInMinutes?: number;
    overtime: boolean;
  }

  export interface CreateRequest {
    taskId: number;
    taskRecordId: number;
    userIds: Set<number>;
    startTime: OffsetDateTime;
    endTime: OffsetDateTime;
    loggedTimeInMinutes?: number;
    overtime: boolean;
  }

  export interface DeleteRequest {
    id: number;
    taskId: number;
    taskRecordId: number;
  }

  export enum WorklogScope {
    USER = 'USER',
    TASK_RECORD = 'TASK_RECORD',
  }

  export function toPublic(r: WorklogResource.Worklog): Worklog {
    return {
      id: r.id,
      creationTime: Services.toOffsetDateTime(r.creation_time),
      updateTime: Services.toOffsetDateTime(r.update_time),
      startTime: Services.toOffsetDateTime(r.start_time),
      endTime: Services.toOffsetDateTime(r.end_time),
      loggedTimeInMinutes: r.logged_time_in_minutes,
      estimatedTimeInMinutes: r.estimated_time_in_minutes,
      worklogUserType: WorklogUserType[r.worklog_user_type],
      loggerUser: {
        id: r.logger_user.id,
        userName: r.logger_user.user_name,
        personName: r.logger_user.person_name
      },
      workerUser: {
        id: r.worker_user.id,
        userName: r.worker_user.user_name,
        personName: r.worker_user.person_name,
        userGroupIds: r.worker_user.user_group_ids,
        profilePictureHash: r.worker_user.profile_picture_hash
      },
      taskRecord: {
        id: r.task_record.id,
        taskId: r.task_record.task_id,
        name: r.task_record.name
      },
      overtime: r.overtime
    }
  }

  export enum ValidatedField {
    UNKNOWN,
    USER_IDS,
    START_TIME,
    END_TIME,
    LOGGED_TIME,
  }

  export namespace Fields {

    export class Worklog {

      readonly id: Query.Field = new DqlQuery.Field('id');
      readonly creationTime: Query.Field = new DqlQuery.Field('creation_time');
      readonly updateTime: Query.Field = new DqlQuery.Field('update_time');
      readonly startTime: Query.Field = new DqlQuery.Field('start_time');
      readonly endTime: Query.Field = new DqlQuery.Field('end_time');
      readonly loggedTimeInMinutes: Query.Field = new DqlQuery.Field('logged_time_in_minutes');
      readonly estimatedTimeInMinutes: Query.Field = new DqlQuery.Field('estimated_time_in_minutes');
      readonly worklogUserType: Query.Field = new DqlQuery.Field('worklog_user_type');
      readonly loggerUser: Query.Field = new DqlQuery.Field('logger_user');
      readonly workerUser: Query.Field = new DqlQuery.Field('worker_user');
      readonly taskRecord: Query.Field = new DqlQuery.Field('task_record');
      readonly overtime: Query.Field = new DqlQuery.Field('overtime');

      get each(): Set<Query.Field> {
        return Set.of(
          this.id,
          this.creationTime,
          this.updateTime,
          this.startTime,
          this.endTime,
          this.loggedTimeInMinutes,
          this.estimatedTimeInMinutes,
          this.worklogUserType,
          this.loggerUser,
          this.workerUser,
          this.taskRecord,
          this.overtime
        );
      }

    }

  }

  export namespace OrderFunctions {
    export const ID = (f: OrderField.Worklog) => f.id;
    export const LOGGED_TIME_IN_MINUTES = (f: OrderField.Worklog) => f.loggedTimeInMinutes;
    export const ESTIMATED_TIME_IN_MINUTES = (f: OrderField.Worklog) => f.estimatedTimeInMinutes;
    export const START_TIME = (f: OrderField.Worklog) => f.startTime;
    export const END_TIME = (f: OrderField.Worklog) => f.endTime;

    export const VALUES = OrderFunctionResolver.builder<OrderField.Worklog>()
      .add(ID, 'id')
      .add(LOGGED_TIME_IN_MINUTES, 'logged_time_in_minutes')
      .add(ESTIMATED_TIME_IN_MINUTES, 'estimated_time_in_minutes')
      .add(START_TIME, 'start_time')
      .add(END_TIME, 'end_time')
      .build();
  }
}

class Keys {

  private static readonly USER_IDS = 'user_ids';
  private static readonly START_TIME = 'start_time';
  private static readonly END_TIME = 'end_time';
  private static readonly LOGGED_TIME = 'logged_time_in_minutes';

  private static readonly keyValidatedFieldMap: ImmutableMap<string, Worklog.ValidatedField> = ImmutableMap.of(
    Keys.USER_IDS, Worklog.ValidatedField.USER_IDS,
    Keys.START_TIME, Worklog.ValidatedField.START_TIME,
    Keys.END_TIME, Worklog.ValidatedField.END_TIME,
    Keys.LOGGED_TIME, Worklog.ValidatedField.LOGGED_TIME,
  );

  public static toValidatedField(fieldKey: string): Worklog.ValidatedField {
    return Keys.keyValidatedFieldMap.get(fieldKey, Worklog.ValidatedField.UNKNOWN);
  }

}


@Injectable()
export class WorklogService implements Worklog.Service {

  private readonly filterField = new FilterField.Worklog();
  private readonly orderField = new OrderField.Worklog();
  private readonly fields = new Worklog.Fields.Worklog();

  constructor(private resourceService: WorklogResourceService,
              private manualWorklogResourceService: ManualWorklogResourceService) {
  }

  query(request: Worklog.QueryRequest): Observable<QueryResult<Worklog.Worklog>> {
    return Observable.create((observer: Observer<QueryResult<Worklog.Worklog>>) => {
      const resourceRequest: WorklogResource.QueryRequest = this.toResourceQueryRequest(request);
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<WorklogResource.Worklog>) => {
          observer.next({
            items: List.of(...result.items.map((item) => Worklog.toPublic(item))),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  update(request: Worklog.UpdateRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: WorklogResource.UpdateRequest = this.toResourceUpdateRequest(request);
      return this.resourceService.update(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

   create(request: Worklog.CreateRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: WorklogResource.CreateRequest = {
        task_id: request.taskId,
        task_record_id: request.taskRecordId,
        user_ids: request.userIds.toArray(),
        start_time: Services.offsetDateTimeToString(request.startTime)!,
        end_time: Services.offsetDateTimeToString(request.endTime)!,
        logged_time_in_minutes: request.loggedTimeInMinutes,
        overtime: request.overtime
      }
      return this.manualWorklogResourceService.create(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

   delete(request: Worklog.DeleteRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: WorklogResource.DeleteRequest = {
        task_id: request.taskId,
        task_record_id: request.taskRecordId,
        id: request.id
      }
      return this.manualWorklogResourceService.delete(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  exportXls(request: Worklog.QueryRequest): Observable<DownloadedFile> {
    const resourceRequest: WorklogResource.QueryRequest = this.toResourceQueryRequest(request);
    return this.resourceService.exportXls(resourceRequest);
  }

  exportMaconomy(request: Worklog.QueryRequest): Observable<DownloadedFile> {
    const resourceRequest: WorklogResource.QueryRequest = this.toResourceQueryRequest(request);
    return this.resourceService.exportMaconomy(resourceRequest);
  }

  toResourceQueryRequest(request: Worklog.QueryRequest): WorklogResource.QueryRequest {
    const filter: string | undefined = DqlQuery.toOptionalFilter(this.filterField, request.filter);
    const order: string | undefined = DqlQuery.toOptionalOrder(this.orderField, request.order);
    const fields: string | undefined = DqlQuery.toOptionalFields(this.fields, request.fields);
    return {
      worklog_scope: request.worklogScope !== undefined ? request.worklogScope.toString() : undefined,
      user_id: request.userId,
      task_record_id: request.taskRecordId,
      filter: filter,
      order: order,
      fields: fields,
      page_number: request.paging ? request.paging.pageNumber : undefined,
      number_of_items: request.paging ? request.paging.numberOfItems : undefined
    };
  }

  toResourceUpdateRequest(request: Worklog.UpdateRequest): WorklogResource.UpdateRequest {
    return {
      id: request.id,
      start_time: Services.offsetDateTimeToString(request.startTime)!,
      end_time: Services.offsetDateTimeToString(request.endTime)!,
      logged_time_in_minutes: request.loggedTimeInMinutes,
      overtime: request.overtime
    };
  }

}
