/* eslint-disable */
import { Stack } from 'immutable';
/* eslint-enable */

export interface Command<T> {
  execute: () => Promise<T>;
  undo?: () => Promise<void>;
}

export class CommandManager<T> {

  private commandStack: Stack<Command<T>> = Stack<Command<T>>();

  constructor() {
  }

  /**
   * @returns {T} result forwarded from the command
   */
  public async executeCommand(cmd: Command<T>): Promise<T> {
    const result: T = await cmd.execute();
    if (cmd.undo) {
      this.commandStack = this.commandStack.push(cmd);
    }
    return result;
  }

  public canUndo(): boolean {
    return this.size() > 0;
  }

  public async undo(): Promise<void> {
    if (this.canUndo()) {
      const cmd = this.commandStack.first();
      this.commandStack = this.commandStack.pop();
      await cmd.undo!();
    }
  }

  public async undoAll(): Promise<void> {
    while (this.canUndo()) {
      await this.undo();
    }
  }

  public clear(): void {
    this.commandStack = Stack<Command<T>>();
  }

  public size(): number {
    return this.commandStack.size;
  }

}
