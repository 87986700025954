/* eslint-disable */
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UIRouter } from '@uirouter/angular';
import { StateName } from '../../app.state-names';
import { ConnectionError } from '../../lib/util/errors';
import { ServiceErrorStorage } from '../../lib/util/storages';

/* eslint-enable */

@Component({
  selector: 'app-connection-error-template',
  templateUrl: 'connection-error-page.component.html',
  styleUrls: ['connection-error-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConnectionErrorPageComponent implements OnInit {

  private model: ConnectionError | null;

  constructor(private router: UIRouter) {
    this.model = ServiceErrorStorage.getInstance().getConnectionError();
  }

  ngOnInit() {
    if (!this.model) {
      this.home();
    }
  }

  back() {
    window.history.back();
  }

  home() {
    const destination = ServiceErrorStorage.getInstance().getStateObject();
    if (destination) {
      this.router.stateService.go(StateName.getLoginStateName(destination.stateName));
    }
    else {
      this.router.stateService.go(StateName.LOGIN);
    }
  }

}
