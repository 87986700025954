/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { EmptyMessage } from '../util/messages';
import { AddressResource } from '../address';
/* eslint-enable */

@Injectable()
export class RegistrationResourceService extends BaseHttpService {

  requestRegistration(request: RegistrationResource.RegistrationRequest): Observable<EmptyMessage> {
    return this.http.post<EmptyMessage>(this.url + 'request_user', request);
  }

  registerUserShoprenter(request: RegistrationResource.RegistrationUserShoprenterRequest): Observable<EmptyMessage> {
    return this.http.post<EmptyMessage>(this.url + 'create_user_shoprenter', request);
  }

  registerUserDemo(request: RegistrationResource.RegistrationUserDemoRequest): Observable<EmptyMessage> {
    return this.http.post<EmptyMessage>(this.url + 'create_user_demo', request);
  }

  registerUserHelpdesk(request: RegistrationResource.RegistrationHelpdeskRequest): Observable<EmptyMessage> {
    return this.http.post<EmptyMessage>(this.url + 'helpdesk', request);
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, 'registration/');
  }

}

export namespace RegistrationResource {

  export interface RegistrationRequest {
    person_name: string;
    email_address: string;
    phone_number?: string;
    company: CompanyData;
  }

  export interface RegistrationUserShoprenterRequest extends RegistrationRequest {
    user: UserData;
    shoprenter_webshop: string;
  }

  export interface RegistrationUserDemoRequest extends RegistrationRequest {
    user: UserData;
  }

  export interface RegistrationHelpdeskRequest {
    person_name: string;
    email_address: string;
    phone_number?: string;
    user: UserData;
    helpdesk_token: string;
  }

  export interface CompanyData {
    name: string;
    address: AddressResource.PostalAddressResource;
  }

  export interface UserData {
    user_name: string;
    password: string;
  }

}
