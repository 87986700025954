import { Component, Input, OnInit } from '@angular/core';
import { Node } from '../../../util/tree/tree-utils';

@Component({
  selector: 'app-tree-node-component',
  templateUrl: './tree-node.component.html',
  styleUrls: ['./tree-node.component.scss']
})
export class TreeNodeComponent implements OnInit {

  @Input()
  node: Node;

  @Input()
  readonly: boolean = false;

  constructor() { }

  ngOnInit() {
  }

}
