/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResourceQueryResult } from '../../util/services';
import { BaseHttpService, ResourceHelper } from '../../util/http-services';
import { HttpClient } from '@angular/common/http';
import { DisableRequest, EmptyMessage, IdentityMessage } from '../../util/messages';
import { IconResource } from '../../task/icon.service';
import { AddressResource } from '../../address';
import { FormRecordResource } from '../../form/form-record-resource.service';
import { DownloadedFile } from '../../util/downloaded-files';
import { UrlBuilder } from '../../../util/url-builder';
import { map } from 'rxjs/operators';
import { FormResource } from '../../form/form-resource.service';

/* eslint-enable */

@Injectable()
export class ProjectRecordResourceService extends BaseHttpService {

  query(request: ProjectRecordResource.QueryRequest): Observable<ResourceQueryResult<ProjectRecordResource.ProjectRecord>> {
    return this.http.get<ResourceQueryResult<ProjectRecordResource.ProjectRecord>>(
      this.url + `${request.project_id}/records`, this.parseParams(request));
  }

  get(request: ProjectRecordResource.GetRequest): Observable<ProjectRecordResource.ProjectRecord> {
    return this.http.get<ProjectRecordResource.ProjectRecord>(this.url + `${request.project_id}/records/${request.id}`,
      this.parseParams(request));
  }

  create(request: ProjectRecordResource.CreateRequest): Observable<IdentityMessage> {
    return this.http.post<IdentityMessage>(this.url + `${request.project_id}/records`, request);
  }

  update(request: ProjectRecordResource.UpdateRequest): Observable<EmptyMessage> {
    return this.http.put(this.url + `${request.project_id}/records/${request.id}`, request);
  }

  setDisabled(request: ProjectRecordResource.DisableRequest): Observable<EmptyMessage> {
    return this.http.patch(this.url + `${request.project_id}/records/${request.id}/disabled`, request);
  }

  exportXls(request: ProjectRecordResource.ExportXlsRequest): Observable<DownloadedFile> {
    const url = UrlBuilder.create('projects/:projectId/records/export-xls')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .namedNumber('projectId', request.project_id)
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob',
      params: this.parseParams(request).params
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  constructor(private http: HttpClient, private resourceHelper: ResourceHelper) {
    super(resourceHelper, '/projects/');
  }

}

@Injectable()
export class ProjectRecordGlobalResourceService extends BaseHttpService {

  query(request: ProjectRecordResource.GlobalQueryRequest): Observable<ResourceQueryResult<ProjectRecordResource.ProjectRecord>> {
    return this.http.get<ResourceQueryResult<ProjectRecordResource.ProjectRecord>>(this.url, this.parseParams(request));
  }

  get(request: ProjectRecordResource.GlobalGetRequest): Observable<ProjectRecordResource.ProjectRecord> {
    return this.http.get<ProjectRecordResource.ProjectRecord>(this.url + `${request.id}`, this.parseParams(request));
  }

  setDisabled(request: DisableRequest): Observable<EmptyMessage> {
    return this.http.patch<EmptyMessage>(this.url + `${request.id}/disabled`, request);
  }

  statistics(request: ProjectRecordResource.StatisticsRequest): Observable<ResourceQueryResult<ProjectRecordResource.ProjectRecord>> {
    return this.http.get<ResourceQueryResult<ProjectRecordResource.ProjectRecord>>(this.url + `statistics`, this.parseParams(request));
  }

  invoiceStatistics(request: EmptyMessage): Observable<ResourceQueryResult<ProjectRecordResource.InvoiceStatistic>> {
    return this.http.get<ResourceQueryResult<ProjectRecordResource.InvoiceStatistic>>(
      this.url + `statistics/invoice-stat`, this.parseParams(request));
  }

  constructor(private http: HttpClient, private resourceHelper: ResourceHelper) {
    super(resourceHelper, '/project-records/');
  }

}

export namespace ProjectRecordResource {

  export interface GlobalQueryRequest {
    q?: string;
    fields?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
    no_progress_bar?: boolean;
    with_form_record?: boolean;
    rights?: string;

    id?: string;
    name?: string;
    disabled?: boolean;
    external_id?: string;
    dql?: string;
    start_date_from?: string;
    start_date_to?: string;
    end_date_from?: string;
    end_date_to?: string;
    assignee_user_id?: number;
    postal_address?: string;
  }

  export interface QueryRequest extends GlobalQueryRequest {
    project_id: number;
  }

  export interface GlobalGetRequest {
    id: number;
    rights?: string;
  }

  export interface GetRequest extends GlobalGetRequest {
    project_id: number;
  }

  export interface BaseRequest {
    project_id: number;
    name: string;
    start_date?: string;
    end_date?: string;
    amount?: number;
    billing_start_date?: string;
    billing_end_date?: string;
    billing_type?: string;
    billing_period_in_months?: number;
    assignee_user_id?: number;
    postal_address?: AddressResource.PostalAddressResource;
  }

  export interface CreateRequest extends BaseRequest {
    external_id?: string;
    form_record: FormRecordResource.FormRecordCreateRequest;
  }

  export interface UpdateRequest extends BaseRequest {
    id: number;
    external_id: string;
    form_record: FormRecordResource.FormRecordUpdateRequest;
  }

  export interface DisableRequest {
    project_id: number;
    id: number;
    disabled: boolean;
  }

  export interface ProjectRecord {
    id: number;
    name: string;
    external_id: string;
    project: {
      id: number;
      name: string;
      icon?: IconResource.Icon;
      displayed_form_field?: FormResource.Field;
    };
    start_date?: string;
    end_date?: string;
    billing_start_date?: string;
    billing_end_date?: string;
    billing_type?: string;
    billing_period_in_months?: number;
    amount?: number;
    creation_time: string;
    update_time: string;
    update_count: number;
    disabled: boolean;
    statistics?: Map<string, number>;
    assignee_user?: {
      id: number;
      user_name: string;
      person_name: string;
    };
    postal_address?: AddressResource.PostalAddressResource;
    form_record?: FormRecordResource.FormRecord;
    displayed_form_field_record?: FormRecordResource.Field;
    granted_rights?: string[];
  }

  export interface StatisticsRequest {
    statistics_type?: string;
    disabled?: boolean;
  }

  export interface InvoiceStatistic {
    project_id: number;
    project_name: string;
    task_record_count: number;
    net_sum: number;
    gross_sum: number;
  }

  export interface ExportXlsRequest {
    project_id: number;
    id: string;
  }
}

