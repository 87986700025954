import { Injectable } from '@angular/core';
import { BaseHttpService, ResourceHelper } from '../../util/http-services';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResourceQueryResult } from '../../util/services';
import { EmptyMessage, IdentityMessage } from '../../util/messages';
import { DownloadedFile } from '../../util/downloaded-files';
import { UrlBuilder } from '../../../util/url-builder';
import { map } from 'rxjs/operators';
import { OrderDocument } from '../../order-document/order-document.service';
import { ExteriorTransportDocument } from './exterior-transport-document.service';


@Injectable()
export class ExteriorTransportDocumentResourceService extends BaseHttpService {

  private static readonly baseUrl: string = '/exterior-transports/';

  query(request: ExteriorTransportDocumentResource.QueryRequest):
    Observable<ResourceQueryResult<ExteriorTransportDocumentResource.ExteriorTransportDocument>> {
    return this.http.get<ResourceQueryResult<ExteriorTransportDocumentResource.ExteriorTransportDocument>>(
      this.url + `${request.exterior_transport_id}/documents`, this.parseParams(request)
    );
  }

  get(request: ExteriorTransportDocumentResource.GetRequest): Observable<ExteriorTransportDocumentResource.ExteriorTransportDocument> {
    return this.http.get<ExteriorTransportDocumentResource.ExteriorTransportDocument>(
      this.url + `${request.exterior_transport_id}/documents/${request.id}`
    );
  }

  update(request: ExteriorTransportDocumentResource.UpdateRequest): Observable<IdentityMessage> {
    return this.http.put<IdentityMessage>(this.url + `${request.exterior_transport_id}/documents/${request.id}`, request);
  }

  delete(request: ExteriorTransportDocumentResource.DeleteRequest): Observable<EmptyMessage> {
    return this.http.delete<EmptyMessage>(this.url + `${request.exterior_transport_id}/documents/${request.id}`);
  }

  download(request: ExteriorTransportDocumentResource.DownloadRequest): Observable<DownloadedFile> {
    const url = UrlBuilder.create('exterior-transports/:exterior_transport_id/documents/:id/download')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .namedString('exterior_transport_id', request.exterior_transport_id.toString())
      .namedString('id', request.id.toString())
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob',
      params: this.parseParams(request).params
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  constructor(private http: HttpClient, private resourceHelper: ResourceHelper) {
    super(resourceHelper, ExteriorTransportDocumentResourceService.baseUrl);
  }

}

export namespace ExteriorTransportDocumentResource {

  import UUIDMessage = OrderDocument.UUIDMessage;
  import ExteriorTransportDocumentType = ExteriorTransportDocument.ExteriorTransportDocumentType;

  export interface ExteriorTransportDocument {
    id: string;
    creation_time: string;
    serial: string;
    uploader_user_id?: number;
    original_file_name: string;
    content_hash: string;
    content_size: number;
    content_type: string;
    meta: Meta;
  }

  export interface Meta {
    type: ExteriorTransportDocumentType;
    comment?: string;
    update_time: string;
    version: number;
  }

  export interface QueryRequest {
    exterior_transport_id: number;
    fields?: string;
    filter?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
  }

  export interface GetRequest extends UUIDMessage {
    exterior_transport_id: number;
  }

  export interface UpdateRequest extends UUIDMessage {
    exterior_transport_id: number;
    version: number;
    type: ExteriorTransportDocumentType;
    comment?: string;
  }

  export interface DeleteRequest extends UUIDMessage {
    exterior_transport_id: number;
  }

  export interface DownloadRequest extends UUIDMessage {
    exterior_transport_id: number;
  }
}
