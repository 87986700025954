import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-user-profile-picture',
  templateUrl: './user-profile-picture.component.html',
  styleUrls: ['./user-profile-picture.component.scss']
})
export class UserProfilePictureComponent implements OnInit {

  @Input()
  size?: number = 36;

  @Input()
  userPersonName: string;

  @Input()
  profilePicture: any | undefined = undefined;

  constructor(public sanitizer: DomSanitizer) { }

  ngOnInit() {
  }

}
