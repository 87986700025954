/* eslint-disable */
import {Observable} from 'rxjs';
import {List, Map, Set} from 'immutable';
import {LocalDate, OffsetDateTime,} from '../util/dates';
import {PhoneNumber} from '../util/phone-number';
import {EmailAddress} from '../util/email-address';
import {Decimal} from 'decimal.js';
import {ObservableErrorResourceParser} from '../util/errors';
import {FieldValidationError, Services,} from '../util/services';
import {FormResource} from './form-resource.service';
import {FieldPaymentType} from '../../util/form/form-field-payment-type';
import {DocumentFileType} from '../../util/form/form-field-document';
import {Invoice} from '../invoice/invoice/invoice.service';
import {MasterDataFilterType} from '../../util/form/form-field-masterdata-filter-type';
import {StockTypeName} from '../../util/stock/stock-utils';
import {CustomerFilterType} from '../../util/form/form-field-customer-filter-type';
import {UserSignatureType} from '../user.service';
/* eslint-enable */

export namespace Form {

  // NOTE:
  // parentId: the ID of the parent resource (Task, MasterData etc.)

  import InvoicePaymentType = Invoice.InvoicePaymentType;

  export interface Service {
    getForm(request: GetFormRequest): Observable<Form>;

    createGroup(request: CreateGroupRequest): Observable<CreateGroupResponse>;

    updateGroup(request: UpdateGroupRequest): Observable<UpdateGroupResponse>;

    disableGroup(request: DisableGroupRequest): Observable<DisableGroupResponse>;

    moveGroup(request: MoveGroupRequest): Observable<MoveGroupResponse>;

    createField(request: CreateFieldRequest): Observable<CreateFieldResponse>;

    updateField(request: UpdateFieldRequest): Observable<UpdateFieldResponse>;

    disableField(request: DisableFieldRequest): Observable<DisableFieldResponse>;

    moveFieldToGroup(request: MoveFieldToGroupRequest): Observable<MoveFieldToGroupResponse>;

    importForm(request: ImportRequest): Observable<Form>;

    exportForm(request: ExportRequest): Observable<Form.FormImportDocument>;
  }

  export interface GetFormRequest {
    parentId: number;
  }

  export interface CreateGroupRequest {
    parentId: number;
    title: string;
    shortTitle?: string;
    apiExportName?: string;
  }

  export interface CreateGroupResponse {
    groupId: number;
  }

  export interface UpdateGroupRequest {
    parentId: number;
    groupId: number;
    title: string;
    shortTitle?: string;
    apiExportName: string;
  }

  export interface UpdateGroupResponse {
  }

  export interface DisableGroupRequest {
    parentId: number;
    groupId: number;
    disabled: boolean;
  }

  export interface DisableGroupResponse {
  }

  export interface MoveGroupRequest {
    parentId: number;
    groupId: number;
    index: number;
  }

  export interface MoveGroupResponse {
  }

  export interface CreateFieldRequest {
    parentId: number;
    groupId: number;
    title: string;
    description?: string;
    hint?: string;
    pdfExportName?: string;
    apiExportName?: string;
    dataTypeSelector: FieldDataTypeSelector;
    dataType: FieldDataType;
    formFieldWidthPercent: number;
    displayOnNewRow: boolean;
    admin: FieldAdmin;
    helpdesk: FieldHelpdesk;
    mobile: FieldMobile;
    showOnReceipt?: boolean;
  }

  export interface CreateFieldResponse {
    fieldId: number;
  }

  export interface UpdateFieldRequest {
    parentId: number;
    groupId: number;
    fieldId: number;
    title: string;
    description?: string;
    hint?: string;
    pdfExportName?: string;
    apiExportName?: string;
    dataTypeSelector: FieldDataTypeSelector;
    dataType: FieldDataType;
    formFieldWidthPercent: number;
    displayOnNewRow: boolean;
    admin: FieldAdmin;
    helpdesk: FieldHelpdesk;
    mobile: FieldMobile;
    showOnReceipt?: boolean;
  }

  export interface UpdateFieldResponse {
  }

  export interface DisableFieldRequest {
    parentId: number;
    groupId: number;
    fieldId: number;
    disabled: boolean;
  }

  export interface DisableFieldResponse {
  }

  export interface MoveFieldToGroupRequest {
    parentId: number;
    groupId: number;
    fieldId: number;
    index: number;
    newGroupId: number;
  }

  export interface MoveFieldToGroupResponse {
  }

  export interface ImportRequest {
    parentId: number;
    ignoreDisabledFields: boolean;
    form: string;
    version: string;
  }

  export interface ExportRequest {
    parentId: number;
  }

  export interface Form {
    groups: List<Group>;
  }

  export class Forms {

    public static hasVisibleGroup(form: Form) {
      return form.groups.toArray()
        .filter((group) => {
          return group && !group.disabled;
        })
        .length > 0;
    }

    private constructor() {
    }

  }

  export interface Group {
    groupId: number;
    creationTime: OffsetDateTime;
    updateTime: OffsetDateTime;
    title: string;
    shortTitle?: string;
    apiExportName: string;
    disabled: boolean;
    fields: List<Field>;
  }

  export interface Field {
    fieldId: number;
    disabled: boolean;
    creationTime: OffsetDateTime;
    updateTime: OffsetDateTime;
    title: string;
    description?: string;
    hint?: string;
    pdfExportName?: string;
    apiExportName?: string;
    dataTypeSelector: FieldDataTypeSelector;
    dataType: FieldDataType;
    formFieldWidthPercent: number;
    displayOnNewRow: boolean;
    admin: FieldAdmin;
    helpdesk: FieldHelpdesk;
    mobile: FieldMobile;
    showOnReceipt?: boolean;
  }

  export interface FieldAdmin {
    visibleOnFormDetail: boolean;
    validationType: FormFieldValidationType;
  }

  export interface FieldHelpdesk {
    validationType: FormFieldValidationType;
  }

  export interface FieldMobile {
    visibleOnMasterDetail: boolean;
    visibleOnFormDetail: boolean;
    validationType: FormFieldValidationType;
  }

  export enum FormFieldValidationType {
    OPTIONAL,
    REQUIRED,
    READONLY,
    HIDDEN
  }

  export enum FieldDataTypeSelector {
    UNKNOWN,

    STRING,
    NUMBER,
    DECIMAL,
    DATE,
    DATE_TIME,
    BOOLEAN,
    LIST_ITEM,
    LIST_MULTI_ITEM,
    EMAIL_ADDRESS,
    PHONE_NUMBER,
    MASTER_DATA,
    STOCK,
    STOCK_INTAKE,
    STOCK_MOVE,
    READONLY_TEXT,
    READONLY_HTML,
    READONLY_PICTURE,
    FORM_TABLE,
    PAYMENT_TYPE,
    CUSTOMER,
    USER,
    DOCUMENT,
    PHOTO,
    PROCESS_ORDER_STOCK_OUTTAKE,
    PROCESS_ORDER_STOCK_OUTTAKE_CHECK,
    PROCESS_ORDER_PACKAGING,
    INVOICE,
  }

  export enum FormFieldInputCapitalizeMode {
    NONE,
    CHARACTERS,
    SENTENCES,
    WORDS
  }

  export interface FieldDataType {
    stringAttributes?: FieldDataTypeStringAttributes;
    numberAttributes?: FieldDataTypeNumberAttributes;
    decimalAttributes?: FieldDataTypeDecimalAttributes;
    dateAttributes?: FieldDataTypeDateAttributes;
    dateTimeAttributes?: FieldDataTypeDateTimeAttributes;
    booleanAttributes?: FieldDataTypeBooleanAttributes;
    listItemAttributes?: FieldDataTypeListItemAttributes;
    listMultiItemAttributes?: FieldDataTypeListMultiItemAttributes;
    emailAddressAttributes?: FieldDataTypeEmailAddressAttributes;
    phoneNumberAttributes?: FieldDataTypePhoneNumberAttributes;
    masterDataAttributes?: FieldDataTypeMasterDataAttributes;
    readonlyTextAttributes?: FieldDataTypeReadonlyTextAttributes;
    readonlyPictureAttributes?: FieldDataTypeReadonlyPictureAttributes;
    stockAttributes?: FieldDataTypeStockAttributes;
    stockIntakeAttributes?: FieldDataTypeStockIntakeAttributes;
    stockMoveAttributes?: FieldDataTypeStockMoveAttributes;
    formTableAttributes?: FieldDataTypeFormTableAttributes;
    paymentTypeAttributes?: FieldDataTypePaymentTypeAttributes;
    customerAttributes?: FieldDataTypeCustomerAttributes;
    userAttributes?: FieldDataTypeUserAttributes;
    documentAttributes?: FieldDataTypeDocumentAttributes;
    photoAttributes?: FieldDataTypePhotoAttributes;
    processOrderStockOuttakeAttributes?: FieldDataTypeProcessOrderStockOuttakeAttributes;
    processOrderStockOuttakeCheckAttributes?: FieldDataTypeProcessOrderStockOuttakeCheckAttributes;
    processOrderPackagingAttributes?: FieldDataTypeProcessOrderPackagingAttributes;
    invoiceAttributes?: FieldDataTypeInvoiceAttributes;
  }

  export interface FieldDataTypeStringAttributes {
    inputCapitalizeMode: FormFieldInputCapitalizeMode;
    hint?: string;
    minLength?: number; // int
    maxLength?: number; // int
    defaultValue?: string;
    multiLine?: boolean;
  }

  export interface FieldDataTypeNumberAttributes {
    hint?: string;
    minValue?: number; // long
    maxValue?: number; // long
    defaultValue?: number; // long
    thousandSeparatorEnabled?: boolean;
  }

  export interface FieldDataTypeDecimalAttributes {
    hint?: string;
    minValue?: Decimal;
    maxValue?: Decimal;
    defaultValue?: Decimal;
    thousandSeparatorEnabled?: boolean;
  }

  export interface FieldDataTypeDateAttributes {
    hint?: string;
    minValue?: LocalDate;
    maxValue?: LocalDate;
    defaultValue?: LocalDate;
    defaultToday: boolean;
    disableFuture: boolean;
    disablePast: boolean;
  }

  export interface FieldDataTypeDateTimeAttributes {
    hint?: string;
    minValue: OffsetDateTime;
    maxValue: OffsetDateTime;
    defaultValue: OffsetDateTime;
    defaultToday: boolean;
    disableFuture: boolean;
    disablePast: boolean;
  }

  export interface FieldDataTypeBooleanAttributes {
    defaultValue?: boolean;
    enableOtherFields: boolean;
    fieldIdsToBeEnabled: Set<number>; // long
    groupIdsToBeEnabled: Set<number>; // long
  }

  export interface FieldDataTypeListItemAttributes {
    hint?: string;
    listItemTypeKey: string;
    defaultListItemId?: number; // long
    fieldActivationEnabled: boolean;
    activatedFieldIdsByItemId: Map<number, Set<number>>;
    activatedGroupIdsByItemId: Map<number, Set<number>>;
    filterFieldId?: number;
  }

  export interface FieldDataTypeListMultiItemAttributes {
    hint?: string;
    listItemTypeKey: string;
    multiSelect: boolean;
    defaultValue: number[];
    fieldActivationEnabled: boolean;
    activatedFieldIdsByItemId: Map<number, Set<number>>;
    activatedGroupIdsByItemId: Map<number, Set<number>>;
    filterFieldId?: number;
  }

  export interface FieldDataTypeEmailAddressAttributes {
    hint?: string;
    defaultValue: EmailAddress;
  }

  export interface FieldDataTypePhoneNumberAttributes {
    hint?: string;
    defaultValue: PhoneNumber;
  }

  export interface FieldDataTypeMasterDataAttributes {
    hint?: string;
    multiSelect: boolean;
    masterDataId: number;
    filterTypes: Set<MasterDataFilterType.MasterDataFilterType>;
    autoJoinOwnerOnFinish: boolean;
    displayedFormField?: Field;
    displayedFormFieldId?: number;
  }

  export interface FieldDataTypeCustomerAttributes {
    hint?: string;
    multiSelect: boolean;
    filterTypes: Set<CustomerFilterType.CustomerFilterType>;
    autoJoinOwnerOnFinish: boolean;
    customerId: number;
  }

  export interface FieldDataTypeUserAttributes {
    hint?: string;
    multiSelect: boolean;
    userGroupIds?: number[];
    generateSignature?: UserSignatureType;
  }

  export interface FieldDataTypeDocumentAttributes {
    multiSelect: boolean;
    documentFileType: DocumentFileType,
    validMimeTypes?: string[];
  }

  export interface FieldDataTypePhotoAttributes {
    multiSelect: boolean;
  }

  export interface FieldDataTypeReadonlyTextAttributes {
    defaultValue?: string;
  }

  export interface FieldDataTypeReadonlyPictureAttributes {
    value: number;
  }

  export interface FieldDataTypeStockAttributes {
    advancedModeEnabled: boolean;
    hidePricesOverview: boolean;
    hidePricesEdit: boolean;
    availableDiscounts: number[];
    usableStockItemCategories: number[];
    sourceStockType?: StockTypeName;
    stockSourceFilters: number[];
    modifyStoredAmount: boolean;
    uniqueStockRecords: boolean;
    dispersionPercentEnabled: boolean;
    commentEnabled: boolean;
    commentRequired?: boolean;
    commentCustomLabel?: string;
    exactCategoryMatch: boolean;
    fillAssigneeStock: boolean;
    defaultFilterAssignee: boolean;
    clearOnReopen: boolean;
    usedForInvoice: boolean;
  }

  export interface FieldDataTypeStockIntakeAttributes {
    stockType?: StockTypeName;
    usableStockItemCategories: number[];
    stockDestinationFilters: number[];
    clearOnReopen: boolean;
  }

  export interface FieldDataTypeStockMoveAttributes {
    sourceStockType?: StockTypeName;
    destinationStockType?: StockTypeName;
    usableStockItemCategories: number[];
    stockSourceFilters: number[];
    stockDestinationFilters: number[];
    clearOnReopen: boolean;
  }

  export interface FieldDataTypeProcessOrderStockOuttakeAttributes {
  }

  export interface FieldDataTypeProcessOrderStockOuttakeCheckAttributes {
  }

  export interface FieldDataTypeProcessOrderPackagingAttributes {
  }

  export interface FieldDataTypeInvoiceAttributes {
    availableDiscounts: number[];
    usedForInvoice: boolean;
    copyData: boolean;
    mergeData: boolean;
  }

  export interface FieldDataTypeFormTableAttributes {
    formTableId: number;
  }

  export interface FieldDataTypePaymentTypeAttributes {
    validInvoicePaymentTypes: Set<FieldPaymentType>
  }

  export interface FormImportDocument {
    form: string;
    version: string;
  }

  export enum GroupValidatedField {
    UNKNOWN,
    TITLE,
  }

  export enum FieldValidatedField {
    UNKNOWN,
    // field:
    TITLE,
    API_EXPORT_NAME,
    PDF_EXPORT_NAME,
    // types:
    MIN_LENGTH,
    MAX_LENGTH,
    DEFAULT_VALUE,
    MIN_VALUE,
    MAX_VALUE,

    FORM_TABLE,

    LIST_ITEM_TYPE,
    MASTER_DATA_ID,
    DISPLAYED_FORM_FIELD_ID,
    FILE_DOCUMENT_ID,
    USER_GROUP_ID
  }

  // Injectable is not allowed here. This class is in a namespace.
  export class ResourceMapper {

    public toPublicFieldDataTypeSelector(s: FormResource.FieldDataTypeSelector): FieldDataTypeSelector {
      return Keys.toFieldDataTypeSelector(s);
    }

    public toPublicFormList(resources: FormResource.Form[]): List<Form> {
      return List.of(...resources.map((r) => this.toPublicForm(r)));
    }

    public toPublicForm(r: FormResource.Form): Form {
      return {
        groups: this.toPublicGroupList(r.groups)
      };
    }

    public toPublicGroupList(resources: FormResource.Group[]): List<Group> {
      return List.of(...resources.map((r) => this.toPublicGroup(r)));
    }

    public toPublicGroup(r: FormResource.Group): Group {
      return {
        groupId: r.id,
        creationTime: Services.toOffsetDateTime(r.creation_time),
        updateTime: Services.toOffsetDateTime(r.update_time),
        title: r.title,
        shortTitle: r.short_title,
        apiExportName: r.api_export_name,
        disabled: r.disabled,
        fields: this.toPublicFieldList(r.fields ? r.fields : [])
      };
    }

    public toPublicFieldList(resources: FormResource.Field[]): List<Field> {
      return List.of(...resources.map((r) => this.toPublicField(r)));
    }

    public toPublicField(r: FormResource.Field): Field {
      return {
        fieldId: r.id,
        disabled: r.disabled,
        creationTime: Services.toOffsetDateTime(r.creation_time),
        updateTime: Services.toOffsetDateTime(r.update_time),
        title: r.title,
        description: r.description,
        hint: r.hint,
        pdfExportName: r.pdf_export_name,
        apiExportName: r.api_export_name,
        dataTypeSelector: this.toPublicFieldDataTypeSelector(r.data_type_selector),
        dataType: this.toPublicFieldDataType(r.data_type),
        formFieldWidthPercent: r.field_width,
        displayOnNewRow: r.display_on_new_row,
        admin: {
          visibleOnFormDetail: r.admin.visible_on_form_detail,
          validationType: this.toPublicFormFieldValidationType(r.admin.validation_type),
        },
        helpdesk: {
          validationType: this.toPublicFormFieldValidationType(r.helpdesk.validation_type),
        },
        mobile: {
          visibleOnMasterDetail: r.mobile.visible_on_master_detail,
          visibleOnFormDetail: r.mobile.visible_on_form_detail,
          validationType: this.toPublicFormFieldValidationType(r.mobile.validation_type),
        },
        showOnReceipt: r.show_on_receipt
      };
    }

    public toPublicCreateGroupResponse(result: FormResource.CreateGroupResponse): Form.CreateGroupResponse {
      return {
        groupId: result.id
      }
    }

    public toPublicUpdateGroupResponse(result: FormResource.UpdateGroupResponse): Form.UpdateGroupResponse {
      return {};
    }

    public toPublicDisableGroupResponse(result: FormResource.DisableGroupResponse): Form.DisableGroupResponse {
      return {};
    }

    public toPublicMoveGroupResponse(result: FormResource.MoveGroupResponse): Form.MoveGroupResponse {
      return {};
    }

    public toPublicCreateFieldResponse(result: FormResource.CreateFieldResponse): CreateFieldResponse {
      return {
        fieldId: result.id
      }
    }

    public toPublicUpdateFieldResponse(result: FormResource.UpdateFieldResponse): Form.UpdateFieldResponse {
      return {};
    }

    public toPublicDisableFieldResponse(result: FormResource.DisableFieldResponse): Form.DisableFieldResponse {
      return {};
    }

    public toPublicMoveFieldToGroupResponse(result: FormResource.MoveFieldToGroupResponse): Form.MoveFieldToGroupResponse {
      return {};
    }

    public toResourceGetFormRequest(request: GetFormRequest): FormResource.GetFormRequest {
      return {
        parent_id: request.parentId,
      };
    }

    public toResourceCreateGroupRequest(request: CreateGroupRequest): FormResource.CreateGroupRequest {
      return {
        parent_id: request.parentId,
        title: request.title,
        short_title: request.shortTitle,
        api_export_name: request.apiExportName
      };
    }

    public toResourceUpdateGroupRequest(request: Form.UpdateGroupRequest): FormResource.UpdateGroupRequest {
      return {
        parent_id: request.parentId,
        group_id: request.groupId,
        title: request.title,
        short_title: request.shortTitle,
        api_export_name: request.apiExportName
      };
    }

    public toResourceDisableGroupRequest(request: Form.DisableGroupRequest): FormResource.DisableGroupRequest {
      return {
        parent_id: request.parentId,
        group_id: request.groupId,
        disabled: request.disabled
      };
    }

    public toResourceMoveGroupRequest(request: Form.MoveGroupRequest): FormResource.MoveGroupRequest {
      return {
        parent_id: request.parentId,
        group_id: request.groupId,
        index: request.index
      };
    }

    public toResourceCreateFieldRequest(request: CreateFieldRequest): FormResource.CreateFieldRequest {
      return {
        parent_id: request.parentId,
        group_id: request.groupId,
        title: request.title,
        description: request.description,
        hint: request.hint,
        pdf_export_name: request.pdfExportName,
        api_export_name: request.apiExportName,
        data_type_selector: this.toResourceDataTypeSelector(request.dataTypeSelector),
        data_type: this.toResourceDataType(request.dataType),
        field_width: request.formFieldWidthPercent,
        display_on_new_row: request.displayOnNewRow,
        admin: {
          visible_on_form_detail: request.admin.visibleOnFormDetail,
          validation_type: this.toResourceFormFieldValidationType(request.admin.validationType)
        },
        helpdesk: {
          validation_type: this.toResourceFormFieldValidationType(request.helpdesk.validationType),
        },
        mobile: {
          visible_on_master_detail: request.mobile.visibleOnMasterDetail,
          visible_on_form_detail: request.mobile.visibleOnFormDetail,
          validation_type: this.toResourceFormFieldValidationType(request.mobile.validationType)
        },
        show_on_receipt: request.showOnReceipt
      };
    }

    public toResourceUpdateFieldRequest(request: Form.UpdateFieldRequest): FormResource.UpdateFieldRequest {
      return {
        parent_id: request.parentId,
        group_id: request.groupId,
        field_id: request.fieldId,
        title: request.title,
        description: request.description,
        hint: request.hint,
        pdf_export_name: request.pdfExportName,
        api_export_name: request.apiExportName,
        data_type_selector: this.toResourceDataTypeSelector(request.dataTypeSelector),
        data_type: this.toResourceDataType(request.dataType),
        field_width: request.formFieldWidthPercent,
        display_on_new_row: request.displayOnNewRow,
        admin: {
          visible_on_form_detail: request.admin.visibleOnFormDetail,
          validation_type: this.toResourceFormFieldValidationType(request.admin.validationType),
        },
        helpdesk: {
          validation_type: this.toResourceFormFieldValidationType(request.helpdesk.validationType),
        },
        mobile: {
          visible_on_master_detail: request.mobile.visibleOnMasterDetail,
          visible_on_form_detail: request.mobile.visibleOnFormDetail,
          validation_type: this.toResourceFormFieldValidationType(request.mobile.validationType)
        },
        show_on_receipt: request.showOnReceipt
      };
    }

    public toResourceDisableFieldRequest(request: Form.DisableFieldRequest): FormResource.DisableFieldRequest {
      return {
        parent_id: request.parentId,
        group_id: request.groupId,
        field_id: request.fieldId,
        disabled: request.disabled
      };
    }

    public toResourceMoveFieldToGroupRequest(request: Form.MoveFieldToGroupRequest): FormResource.MoveFieldToGroupRequest {
      return {
        parent_id: request.parentId,
        group_id: request.groupId,
        field_id: request.fieldId,
        index: request.index,
        new_group_id: request.newGroupId
      };
    }

    public translateGetFormError(error: any): any {
      return error;
    }

    public translateCreateGroupError(error: any): any {
      return this.translateModifyGroupError(error);
    }

    public translateUpdateGroupError(error: any): any {
      return this.translateModifyGroupError(error);
    }

    public translateDisableGroupError(error: any): any {
      return error;
    }

    public translateMoveGroupError(error: any): any {
      return error;
    }

    public translateCreateFieldError(error: any): any {
      return this.translateModifyFieldError(error);
    }

    public translateUpdateFieldError(error: any): any {
      return this.translateModifyFieldError(error);
    }

    public translateDisableFieldError(error: any): any {
      return error;
    }

    public translateMoveFieldToGroupError(error: any): any {
      return error;
    }

    // <editor-fold desc="Internal">


    private translateModifyGroupError(error: any): any {
      const res = ObservableErrorResourceParser.parseError(error);
      const fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
      const fieldErrorMap = ObservableErrorResourceParser.toFieldErrorMap(Keys.toValidatedGroupField, fieldErrors);
      if (!fieldErrorMap.isEmpty()) {
        return FieldValidationError.of(fieldErrorMap);
      }
      return error;
    }

    private translateModifyFieldError(error: any): any {
      const res = ObservableErrorResourceParser.parseError(error);
      const fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
      const fieldErrorMap = ObservableErrorResourceParser.toFieldErrorMap(Keys.toValidatedFieldField, fieldErrors);
      if (!fieldErrorMap.isEmpty()) {
        return FieldValidationError.of(fieldErrorMap);
      }
      return error;
    }

    private toPublicFieldDataType(r: FormResource.FieldDataType): FieldDataType {
      return {
        stringAttributes: r.string_attributes
          ? this.toPublicStringAttributes(r.string_attributes) : undefined,
        numberAttributes: r.number_attributes
          ? this.toPublicNumberAttributes(r.number_attributes) : undefined,
        decimalAttributes: r.decimal_attributes
          ? this.toPublicDecimalAttributes(r.decimal_attributes) : undefined,
        dateAttributes: r.date_attributes
          ? this.toPublicDateAttributes(r.date_attributes) : undefined,
        dateTimeAttributes: r.date_time_attributes
          ? this.toPublicDateTimeAttributes(r.date_time_attributes) : undefined,
        booleanAttributes: r.boolean_attributes
          ? this.toPublicBooleanAttributes(r.boolean_attributes) : undefined,
        listItemAttributes: r.list_item_attributes
          ? this.toPublicListItemAttributes(r.list_item_attributes) : undefined,
        listMultiItemAttributes: r.list_multi_item_attributes
          ? this.toPublicListMultiItemAttributes(r.list_multi_item_attributes) : undefined,
        emailAddressAttributes: r.email_address_attributes
          ? this.toPublicEmailAddressAttributes(r.email_address_attributes) : undefined,
        phoneNumberAttributes: r.phone_number_attributes
          ? this.toPublicPhoneNumberAttributes(r.phone_number_attributes) : undefined,
        masterDataAttributes: r.master_data_attributes
          ? this.toPublicMasterDataAttributes(r.master_data_attributes) : undefined,
        readonlyTextAttributes: r.readonly_text_attributes
          ? this.toPublicReadonlyTextAttributes(r.readonly_text_attributes) : undefined,
        readonlyPictureAttributes: r.readonly_picture_attributes
          ? this.toPublicReadonlyPictureAttributes(r.readonly_picture_attributes) : undefined,
        stockAttributes: r.stock_attributes
          ? this.toPublicStockAttributes(r.stock_attributes) : undefined,
        stockIntakeAttributes: r.stock_intake_attributes
          ? this.toPublicStockIntakeAttributes(r.stock_intake_attributes) : undefined,
        stockMoveAttributes: r.stock_move_attributes
          ? this.toPublicStockMoveAttributes(r.stock_move_attributes) : undefined,
        formTableAttributes: r.form_table_attributes ? this.toPublicFormTableAttributes(r.form_table_attributes) : undefined,
        paymentTypeAttributes: r.invoice_payment_type_attributes
          ? this.toPublicPaymentTypeAttributes(r.invoice_payment_type_attributes) : undefined,
        customerAttributes: r.customer_attributes
          ? this.toPublicCustomerAttributes(r.customer_attributes) : undefined,
        userAttributes: r.user_attributes
          ? this.toPublicUserAttributes(r.user_attributes) : undefined,
        documentAttributes: r.document_attributes
          ? this.toPublicDocumentAttributes(r.document_attributes) : undefined,
        photoAttributes: r.photo_attributes
          ? this.toPublicPhotoAttributes(r.photo_attributes) : undefined,
        processOrderStockOuttakeAttributes: r.process_order_stock_outtake_attributes
          ? this.toPublicProcessOrderStockOuttakeAttributes(r.process_order_stock_outtake_attributes) : undefined,
        processOrderStockOuttakeCheckAttributes: r.process_order_stock_outtake_check_attributes
          ? this.toPublicProcessOrderStockOuttakeCheckAttributes(r.process_order_stock_outtake_check_attributes) : undefined,
        processOrderPackagingAttributes: r.process_order_packaging_attributes
          ? this.toPublicProcessOrderPackagingAttributes(r.process_order_packaging_attributes) : undefined,
        invoiceAttributes: r.invoice_attributes
          ? this.toPublicInvoiceAttributes(r.invoice_attributes) : undefined,
      };
    }

    private toPublicStringAttributes(r: FormResource.FieldDataTypeStringAttributes): FieldDataTypeStringAttributes {
      return {
        inputCapitalizeMode: this.toPublicFormFieldInputCapitalizeMode(r.input_capitalize_mode),
        hint: r.hint,
        minLength: r.min_length,
        maxLength: r.max_length,
        defaultValue: r.default_value,
        multiLine: r.multi_line
      };
    }

    private toPublicNumberAttributes(r: FormResource.FieldDataTypeNumberAttributes): FieldDataTypeNumberAttributes {
      return {
        hint: r.hint,
        minValue: r.min_value,
        maxValue: r.max_value,
        defaultValue: r.default_value,
        thousandSeparatorEnabled: r.thousand_separator_enabled
      };
    }

    private toPublicDecimalAttributes(r: FormResource.FieldDataTypeDecimalAttributes): FieldDataTypeDecimalAttributes {
      return {
        hint: r.hint,
        minValue: Services.toDecimal(r.min_value),
        maxValue: Services.toDecimal(r.max_value),
        defaultValue: Services.toDecimal(r.default_value),
        thousandSeparatorEnabled: r.thousand_separator_enabled
      };
    }

    private toPublicDateAttributes(r: FormResource.FieldDataTypeDateAttributes): FieldDataTypeDateAttributes {
      return {
        hint: r.hint,
        minValue: Services.toLocalDate(r.min_value),
        maxValue: Services.toLocalDate(r.max_value),
        defaultValue: Services.toLocalDate(r.default_value),
        defaultToday: r.default_today,
        disableFuture: r.disable_future_date,
        disablePast: r.disable_past_date
      };
    }

    private toPublicDateTimeAttributes(r: FormResource.FieldDataTypeDateTimeAttributes): FieldDataTypeDateTimeAttributes {
      return {
        hint: r.hint,
        minValue: Services.toOffsetDateTime(r.min_value),
        maxValue: Services.toOffsetDateTime(r.max_value),
        defaultValue: Services.toOffsetDateTime(r.default_value),
        defaultToday: r.default_today,
        disableFuture: r.disable_future_date,
        disablePast: r.disable_past_date
      };
    }

    private toPublicBooleanAttributes(r: FormResource.FieldDataTypeBooleanAttributes): FieldDataTypeBooleanAttributes {
      return {
        defaultValue: r.default_value,
        enableOtherFields: r.enable_other_fields,
        fieldIdsToBeEnabled: Set.of(...r.fields_to_be_enabled),
        groupIdsToBeEnabled: Set.of(...r.groups_to_be_enabled)
      };
    }

    private toPublicListItemAttributes(r: FormResource.FieldDataTypeListItemAttributes): FieldDataTypeListItemAttributes {
      return {
        hint: r.hint,
        listItemTypeKey: r.type,
        defaultListItemId: r.default_value,
        fieldActivationEnabled: r.enable_other_fields,
        activatedFieldIdsByItemId: Services.resourceToNumberMultiset(r.fields_to_be_enabled),
        activatedGroupIdsByItemId: Services.resourceToNumberMultiset(r.groups_to_be_enabled),
        filterFieldId: r.filter_form_field
      };
    }

    private toPublicListMultiItemAttributes(r: FormResource.FieldDataTypeListMultiItemAttributes): FieldDataTypeListMultiItemAttributes {
      return {
        hint: r.hint,
        listItemTypeKey: r.type,
        multiSelect: r.multi_select,
        defaultValue: r.default_value,
        fieldActivationEnabled: r.enable_other_fields,
        activatedFieldIdsByItemId: Services.resourceToNumberMultiset(r.fields_to_be_enabled),
        activatedGroupIdsByItemId: Services.resourceToNumberMultiset(r.groups_to_be_enabled),
        filterFieldId: r.filter_form_field
      }
    }

    private toPublicEmailAddressAttributes(r: FormResource.FieldDataTypeEmailAddressAttributes): FieldDataTypeEmailAddressAttributes {
      return {
        hint: r.hint,
        defaultValue: Services.toEmailAddress(r.default_value),
      };
    }

    private toPublicPhoneNumberAttributes(r: FormResource.FieldDataTypePhoneNumberAttributes): FieldDataTypePhoneNumberAttributes {
      return {
        hint: r.hint,
        defaultValue: Services.toPhoneNumber(r.default_value)
      };
    }

    private toPublicMasterDataAttributes(r: FormResource.FieldDataTypeMasterDataAttributes): FieldDataTypeMasterDataAttributes {
      return {
        hint: r.hint,
        multiSelect: r.multi_select,
        masterDataId: r.master_data_id,
        autoJoinOwnerOnFinish: r.auto_join_owner_on_finish,
        displayedFormFieldId: r.displayed_form_field_id,
        displayedFormField: r.displayed_form_field ? this.toPublicField(r.displayed_form_field) : undefined,
        filterTypes: Set.of(...r.filter_types.map(r => <MasterDataFilterType.MasterDataFilterType>r))
      };
    }

    private toPublicCustomerAttributes(r: FormResource.FieldDataTypeCustomerAttributes): FieldDataTypeCustomerAttributes {
      return {
        hint: r.hint,
        multiSelect: r.multi_select,
        customerId: r.customer_id,
        autoJoinOwnerOnFinish: r.auto_join_owner_on_finish,
        filterTypes: Set.of(...r.filter_types.map(r => <CustomerFilterType.CustomerFilterType>r))
      };
    }

    private toPublicUserAttributes(r: FormResource.FieldDataTypeUserAttributes): FieldDataTypeUserAttributes {
      return {
        hint: r.hint,
        multiSelect: r.multi_select,
        userGroupIds: r.user_group_ids,
        generateSignature: <UserSignatureType>r.generate_signature
      };
    }

    private toPublicDocumentAttributes(r: FormResource.FieldDataTypeDocumentAttributes): FieldDataTypeDocumentAttributes {
      return {
        multiSelect: r.multi_select,
        documentFileType: DocumentFileType.fromStringValue(r.document_file_type),
        validMimeTypes: r.valid_mime_types ? r.valid_mime_types : [],
      };
    }

    private toPublicPhotoAttributes(r: FormResource.FieldDataTypePhotoAttributes): FieldDataTypePhotoAttributes {
      return {
        multiSelect: r.multi_select,
      };
    }

    private toPublicReadonlyTextAttributes(r: FormResource.FieldDataTypeReadonlyTextAttributes): FieldDataTypeReadonlyTextAttributes {
      return {
        defaultValue: r.default_value
      };
    }

    private toPublicReadonlyPictureAttributes(r: FormResource.FieldDataTypeReadonlyPictureAttributes):
      FieldDataTypeReadonlyPictureAttributes {
      return {
        value: r.value
      };
    }

    private toPublicStockAttributes(r: FormResource.FieldDataTypeStockAttributes): FieldDataTypeStockAttributes {
      return {
        hidePricesOverview: r.hide_prices_overview,
        advancedModeEnabled: r.advanced_mode_enabled,
        hidePricesEdit: r.hide_prices_edit,
        availableDiscounts: r.available_discounts,
        usableStockItemCategories: r.usable_stock_item_categories,
        sourceStockType: r.source_stock_type ? <StockTypeName>r.source_stock_type : undefined,
        stockSourceFilters: r.stock_source_filter_ids,
        modifyStoredAmount: r.modify_stored_amount,
        uniqueStockRecords: r.unique_stock_records,
        dispersionPercentEnabled: r.dispersion_percent_enabled,
        commentEnabled: r.comment_enabled,
        commentRequired: r.comment_required,
        commentCustomLabel: r.comment_custom_label,
        exactCategoryMatch: r.exact_category_match,
        fillAssigneeStock: r.fill_assignee_stock,
        defaultFilterAssignee: r.default_filter_assignee,
        clearOnReopen: r.clear_on_reopen,
        usedForInvoice: r.used_for_invoice,
      };
    }

    private toPublicStockIntakeAttributes(r: FormResource.FieldDataTypeStockIntakeAttributes): FieldDataTypeStockIntakeAttributes {
      return {
        stockType: r.stock_type ? <StockTypeName>r.stock_type : undefined,
        usableStockItemCategories: r.usable_stock_item_categories,
        stockDestinationFilters: r.stock_destination_filter_ids,
        clearOnReopen: r.clear_on_reopen
      };
    }

    private toPublicStockMoveAttributes(r: FormResource.FieldDataTypeStockMoveAttributes): FieldDataTypeStockMoveAttributes {
      return {
        sourceStockType: r.source_stock_type ? <StockTypeName>r.source_stock_type : undefined,
        destinationStockType: r.destination_stock_type ? <StockTypeName>r.destination_stock_type : undefined,
        usableStockItemCategories: r.usable_stock_item_categories,
        stockSourceFilters: r.stock_source_filter_ids,
        stockDestinationFilters: r.stock_destination_filter_ids,
        clearOnReopen: r.clear_on_reopen
      };
    }

    private toPublicFormTableAttributes(r: FormResource.FieldDataTypeFormTableAttributes): FieldDataTypeFormTableAttributes {
      return {
        formTableId: r.form_table
      };
    }

    private toPublicPaymentTypeAttributes(r: FormResource.FieldDataTypePaymentTypeAttributes): FieldDataTypePaymentTypeAttributes {
      return {
        validInvoicePaymentTypes:
          r.valid_invoice_payment_types ? Set.of(...r.valid_invoice_payment_types.map(t => FieldPaymentType.fromStringValue(<InvoicePaymentType>t)!))
            : Set.of(),
      }
    }

    private toPublicProcessOrderStockOuttakeAttributes(
      r: FormResource.FieldDataTypeProcessOrderStockOuttakeAttributes): FieldDataTypeProcessOrderStockOuttakeAttributes {
      return {};
    }

    private toPublicProcessOrderStockOuttakeCheckAttributes(
      r: FormResource.FieldDataTypeProcessOrderStockOuttakeCheckAttributes): FieldDataTypeProcessOrderStockOuttakeCheckAttributes {
      return {};
    }

    private toPublicProcessOrderPackagingAttributes(
      r: FormResource.FieldDataTypeProcessOrderPackagingAttributes): FieldDataTypeProcessOrderPackagingAttributes {
      return {};
    }

    private toPublicInvoiceAttributes(
      r: FormResource.FieldDataTypeInvoiceAttributes): FieldDataTypeInvoiceAttributes {
      return {
        availableDiscounts: r.available_discounts,
        usedForInvoice: r.used_for_invoice,
        copyData: r.copy_data,
        mergeData: r.merge_data,
      };
    }

    private toResourceDataTypeSelector(dataTypeSelector: Form.FieldDataTypeSelector): FormResource.FieldDataTypeSelector {
      return Keys.fieldDataTypeSelectorToKey(dataTypeSelector);
    }

    private toResourceDataType(o: Form.FieldDataType): FormResource.FieldDataType {
      return {
        string_attributes: o.stringAttributes
          ? this.toResourceStringAttributes(o.stringAttributes) : undefined,
        number_attributes: o.numberAttributes
          ? this.toResourceNumberAttributes(o.numberAttributes) : undefined,
        decimal_attributes: o.decimalAttributes
          ? this.toResourceDecimalAttributes(o.decimalAttributes) : undefined,
        date_attributes: o.dateAttributes
          ? this.toResourceDateAttributes(o.dateAttributes) : undefined,
        date_time_attributes: o.dateTimeAttributes
          ? this.toResourceDateTimeAttributes(o.dateTimeAttributes) : undefined,
        boolean_attributes: o.booleanAttributes
          ? this.toResourceBooleanAttributes(o.booleanAttributes) : undefined,
        list_item_attributes: o.listItemAttributes
          ? this.toResourceListItemAttributes(o.listItemAttributes) : undefined,
        list_multi_item_attributes: o.listMultiItemAttributes
          ? this.toResourceListMultiItemAttributes(o.listMultiItemAttributes) : undefined,
        email_address_attributes: o.emailAddressAttributes
          ? this.toResourceEmailAddressAttributes(o.emailAddressAttributes) : undefined,
        phone_number_attributes: o.phoneNumberAttributes
          ? this.toResourcePhoneNumberAttributes(o.phoneNumberAttributes) : undefined,
        master_data_attributes: o.masterDataAttributes
          ? this.toResourceMasterDataAttributes(o.masterDataAttributes) : undefined,
        readonly_text_attributes: o.readonlyTextAttributes
          ? this.toResourceReadonlyTextAttributes(o.readonlyTextAttributes) : undefined,
        readonly_picture_attributes: o.readonlyPictureAttributes
          ? this.toResourceReadonlyPictureAttributes(o.readonlyPictureAttributes) : undefined,
        stock_attributes: o.stockAttributes
          ? this.toResourceStockAttributes(o.stockAttributes) : undefined,
        stock_intake_attributes: o.stockIntakeAttributes
          ? this.toResourceStockIntakeAttributes(o.stockIntakeAttributes) : undefined,
        stock_move_attributes: o.stockMoveAttributes
          ? this.toResourceStockMoveAttributes(o.stockMoveAttributes) : undefined,
        form_table_attributes: o.formTableAttributes
          ? this.toResourceFormTableAttributes(o.formTableAttributes) : undefined,
        invoice_payment_type_attributes: o.paymentTypeAttributes
          ? this.toResourcePaymentTypeAttributes(o.paymentTypeAttributes) : undefined,
        customer_attributes: o.customerAttributes
          ? this.toResourceCustomerAttributes(o.customerAttributes) : undefined,
        user_attributes: o.userAttributes
          ? this.toResourceUserAttributes(o.userAttributes) : undefined,
        document_attributes: o.documentAttributes
          ? this.toResourceDocumentAttributes(o.documentAttributes) : undefined,
        photo_attributes: o.photoAttributes
          ? this.toResourcePhotoAttributes(o.photoAttributes) : undefined,
        process_order_stock_outtake_attributes: o.processOrderStockOuttakeAttributes
          ? this.toResourceProcessOrderStockOuttakeAttributes(o.processOrderStockOuttakeAttributes) : undefined,
        process_order_stock_outtake_check_attributes: o.processOrderStockOuttakeCheckAttributes
          ? this.toResourceProcessOrderStockOuttakeCheckAttributes(o.processOrderStockOuttakeCheckAttributes) : undefined,
        process_order_packaging_attributes: o.processOrderPackagingAttributes
          ? this.toResourceProcessOrderPackagingAttributes(o.processOrderPackagingAttributes) : undefined,
        invoice_attributes: o.invoiceAttributes
          ? this.toResourceInvoiceAttributes(o.invoiceAttributes) : undefined,
      };
    }

    private toResourceStringAttributes(a: Form.FieldDataTypeStringAttributes): FormResource.FieldDataTypeStringAttributes {
      return {
        input_capitalize_mode: this.toResourceFormFieldInputCapitalizeMode(a.inputCapitalizeMode),
        hint: a.hint,
        min_length: a.minLength,
        max_length: a.maxLength,
        default_value: a.defaultValue,
        multi_line: a.multiLine
      };
    }

    private toResourceNumberAttributes(a: Form.FieldDataTypeNumberAttributes): FormResource.FieldDataTypeNumberAttributes {
      return {
        hint: a.hint,
        min_value: a.minValue,
        max_value: a.maxValue,
        default_value: a.defaultValue,
        thousand_separator_enabled: a.thousandSeparatorEnabled
      };
    }

    private toResourceDecimalAttributes(a: Form.FieldDataTypeDecimalAttributes): FormResource.FieldDataTypeDecimalAttributes {
      return {
        hint: a.hint,
        min_value: Services.decimalToString(a.minValue),
        max_value: Services.decimalToString(a.maxValue),
        default_value: Services.decimalToString(a.defaultValue),
        thousand_separator_enabled: a.thousandSeparatorEnabled
      };
    }

    private toResourceDateAttributes(a: Form.FieldDataTypeDateAttributes): FormResource.FieldDataTypeDateAttributes {
      return {
        hint: a.hint,
        min_value: Services.localDateToString(a.minValue),
        max_value: Services.localDateToString(a.maxValue),
        default_value: Services.localDateToString(a.defaultValue),
        default_today: a.defaultToday,
        disable_future_date: a.disableFuture,
        disable_past_date: a.disablePast
      };
    }

    private toResourceDateTimeAttributes(a: Form.FieldDataTypeDateTimeAttributes): FormResource.FieldDataTypeDateTimeAttributes {
      return {
        hint: a.hint,
        min_value: Services.offsetDateTimeToString(a.minValue),
        max_value: Services.offsetDateTimeToString(a.maxValue),
        default_value: Services.offsetDateTimeToString(a.defaultValue),
        default_today: a.defaultToday,
        disable_future_date: a.disableFuture,
        disable_past_date: a.disablePast
      };
    }

    private toResourceBooleanAttributes(a: Form.FieldDataTypeBooleanAttributes): FormResource.FieldDataTypeBooleanAttributes {
      return {
        default_value: a.defaultValue,
        enable_other_fields: a.enableOtherFields,
        fields_to_be_enabled: a.fieldIdsToBeEnabled.toArray(),
        groups_to_be_enabled: a.groupIdsToBeEnabled.toArray()
      };
    }

    private toResourceListItemAttributes(a: Form.FieldDataTypeListItemAttributes): FormResource.FieldDataTypeListItemAttributes {
      return {
        hint: a.hint,
        type: a.listItemTypeKey,
        default_value: a.defaultListItemId,
        enable_other_fields: a.fieldActivationEnabled,
        fields_to_be_enabled: Services.numberMultisetToResource(a.activatedFieldIdsByItemId),
        groups_to_be_enabled: Services.numberMultisetToResource(a.activatedGroupIdsByItemId),
        filter_form_field: a.filterFieldId
      };
    }

    private toResourceListMultiItemAttributes(a: Form.FieldDataTypeListMultiItemAttributes):
      FormResource.FieldDataTypeListMultiItemAttributes {
      return {
        hint: a.hint,
        type: a.listItemTypeKey,
        multi_select: a.multiSelect,
        default_value: a.defaultValue,
        enable_other_fields: a.fieldActivationEnabled,
        fields_to_be_enabled: Services.numberMultisetToResource(a.activatedFieldIdsByItemId),
        groups_to_be_enabled: Services.numberMultisetToResource(a.activatedGroupIdsByItemId),
        filter_form_field: a.filterFieldId
      };
    }

    private toResourceEmailAddressAttributes(a: Form.FieldDataTypeEmailAddressAttributes):
      FormResource.FieldDataTypeEmailAddressAttributes {
      return {
        hint: a.hint,
        default_value: Services.emailAddressToString(a.defaultValue),
      };
    }

    private toResourcePhoneNumberAttributes(a: Form.FieldDataTypePhoneNumberAttributes): FormResource.FieldDataTypePhoneNumberAttributes {
      return {
        hint: a.hint,
        default_value: Services.phoneNumberToString(a.defaultValue)
      };
    }

    private toResourceMasterDataAttributes(a: Form.FieldDataTypeMasterDataAttributes): FormResource.FieldDataTypeMasterDataAttributes {
      return {
        hint: a.hint,
        multi_select: a.multiSelect,
        master_data_id: a.masterDataId,
        auto_join_owner_on_finish: a.autoJoinOwnerOnFinish,
        displayed_form_field_id: a.displayedFormFieldId,
        filter_types: a.filterTypes.toArray()
      };
    }

    private toResourceCustomerAttributes(a: Form.FieldDataTypeCustomerAttributes): FormResource.FieldDataTypeCustomerAttributes {
      return {
        hint: a.hint,
        multi_select: a.multiSelect,
        customer_id: a.customerId,
        auto_join_owner_on_finish: a.autoJoinOwnerOnFinish,
        filter_types: a.filterTypes.toArray()
      };
    }

    private toResourceUserAttributes(a: Form.FieldDataTypeUserAttributes): FormResource.FieldDataTypeUserAttributes {
      return {
        hint: a.hint,
        multi_select: a.multiSelect,
        user_group_ids: a.userGroupIds,
        generate_signature: a.generateSignature
      };
    }

    private toResourceDocumentAttributes(a: Form.FieldDataTypeDocumentAttributes): FormResource.FieldDataTypeDocumentAttributes {
      return {
        multi_select: a.multiSelect,
        document_file_type: DocumentFileType.toStringValue(a.documentFileType)
      };
    }

    private toResourcePhotoAttributes(a: Form.FieldDataTypePhotoAttributes): FormResource.FieldDataTypePhotoAttributes {
      return {
        multi_select: a.multiSelect
      };
    }

    private toResourceReadonlyTextAttributes(a: Form.FieldDataTypeReadonlyTextAttributes):
      FormResource.FieldDataTypeReadonlyTextAttributes {
      return {
        default_value: a.defaultValue
      };
    }

    private toResourceReadonlyPictureAttributes(a: Form.FieldDataTypeReadonlyPictureAttributes):
      FormResource.FieldDataTypeReadonlyPictureAttributes {
      return {
        value: a.value
      };
    }

    private toResourceStockAttributes(a: Form.FieldDataTypeStockAttributes): FormResource.FieldDataTypeStockAttributes {
      return {
        advanced_mode_enabled: a.advancedModeEnabled,
        hide_prices_overview: a.hidePricesOverview,
        hide_prices_edit: a.hidePricesEdit,
        available_discounts: a.availableDiscounts,
        usable_stock_item_categories: a.usableStockItemCategories,
        modify_stored_amount: a.modifyStoredAmount,
        unique_stock_records: a.uniqueStockRecords,
        dispersion_percent_enabled: a.dispersionPercentEnabled,
        comment_enabled: a.commentEnabled,
        comment_required: a.commentRequired,
        comment_custom_label: a.commentCustomLabel,
        exact_category_match: a.exactCategoryMatch,
        fill_assignee_stock: a.fillAssigneeStock,
        default_filter_assignee: a.defaultFilterAssignee,
        clear_on_reopen: a.clearOnReopen,
        source_stock_type: a.sourceStockType,
        stock_source_filter_ids: a.stockSourceFilters,
        used_for_invoice: a.usedForInvoice,
      };
    }

    private toResourceStockIntakeAttributes(a: Form.FieldDataTypeStockIntakeAttributes): FormResource.FieldDataTypeStockIntakeAttributes {
      return {
        stock_type: a.stockType,
        usable_stock_item_categories: a.usableStockItemCategories,
        stock_destination_filter_ids: a.stockDestinationFilters,
        clear_on_reopen: a.clearOnReopen,
      };
    }

    private toResourceStockMoveAttributes(a: Form.FieldDataTypeStockMoveAttributes): FormResource.FieldDataTypeStockMoveAttributes {
      return {
        source_stock_type: a.sourceStockType,
        destination_stock_type: a.destinationStockType,
        usable_stock_item_categories: a.usableStockItemCategories,
        stock_source_filter_ids: a.stockSourceFilters,
        stock_destination_filter_ids: a.stockDestinationFilters,
        clear_on_reopen: a.clearOnReopen,
      };
    }

    private toResourceFormTableAttributes(a: Form.FieldDataTypeFormTableAttributes): FormResource.FieldDataTypeFormTableAttributes {
      return {form_table: a.formTableId};
    }

    private toResourcePaymentTypeAttributes(a: Form.FieldDataTypePaymentTypeAttributes): FormResource.FieldDataTypePaymentTypeAttributes {
      return {};
    }

    private toResourceProcessOrderStockOuttakeAttributes(
      a: Form.FieldDataTypeProcessOrderStockOuttakeAttributes): FormResource.FieldDataTypeProcessOrderStockOuttakeAttributes {
      return {};
    }

    private toResourceProcessOrderStockOuttakeCheckAttributes(
      a: Form.FieldDataTypeProcessOrderStockOuttakeCheckAttributes): FormResource.FieldDataTypeProcessOrderStockOuttakeCheckAttributes {
      return {};
    }

    private toResourceProcessOrderPackagingAttributes(
      a: Form.FieldDataTypeProcessOrderPackagingAttributes): FormResource.FieldDataTypeProcessOrderPackagingAttributes {
      return {};
    }

    private toResourceInvoiceAttributes(
      a: Form.FieldDataTypeInvoiceAttributes): FormResource.FieldDataTypeInvoiceAttributes {
      return {
        available_discounts: a.availableDiscounts,
        used_for_invoice: a.usedForInvoice,
        copy_data: a.copyData,
        merge_data: a.mergeData
      };
    }

    private toPublicFormFieldValidationType(text: FormResource.FormFieldValidationType): FormFieldValidationType {
      switch (text) {
        case 'OPTIONAL':
          return FormFieldValidationType.OPTIONAL;
        case 'READONLY':
          return FormFieldValidationType.READONLY;
        case 'HIDDEN':
          return FormFieldValidationType.HIDDEN;
        case 'REQUIRED':
          return FormFieldValidationType.REQUIRED;
        default:
          throw new Error('Unhandled FormFieldValidationType');
      }
    }

    private toResourceFormFieldValidationType(type: FormFieldValidationType): FormResource.FormFieldValidationType {
      switch (type) {
        case FormFieldValidationType.OPTIONAL:
          return 'OPTIONAL';
        case FormFieldValidationType.READONLY:
          return 'READONLY';
        case FormFieldValidationType.HIDDEN:
          return 'HIDDEN';
        case FormFieldValidationType.REQUIRED:
          return 'REQUIRED';
        default:
          throw new Error('Unhandled FormFieldValidationType');
      }
    }

    private toPublicFormFieldInputCapitalizeMode(text: FormResource.FormFieldInputCapitalizeMode): FormFieldInputCapitalizeMode {
      switch (text) {
        case 'NONE':
          return FormFieldInputCapitalizeMode.NONE;
        case 'CHARACTERS':
          return FormFieldInputCapitalizeMode.CHARACTERS;
        case 'SENTENCES':
          return FormFieldInputCapitalizeMode.SENTENCES;
        case 'WORDS':
          return FormFieldInputCapitalizeMode.WORDS;
        default:
          throw new Error('Unhandled FormFieldInputCapitalizeMode');
      }
    }

    private toResourceFormFieldInputCapitalizeMode(type: FormFieldInputCapitalizeMode): FormResource.FormFieldInputCapitalizeMode {
      switch (type) {
        case FormFieldInputCapitalizeMode.NONE:
          return 'NONE';
        case FormFieldInputCapitalizeMode.CHARACTERS:
          return 'CHARACTERS';
        case FormFieldInputCapitalizeMode.SENTENCES:
          return 'SENTENCES';
        case FormFieldInputCapitalizeMode.WORDS:
          return 'WORDS';
        default:
          throw new Error('Unhandled FormFieldInputCapitalizeMode');
      }
    }

    // </editor-fold>

  }

  // <editor-fold desc="Internal">

  class Keys {

    private static readonly GROUP_TITLE = 'title';

    private static readonly FIELD_TITLE = 'title';
    private static readonly FIELD_API_EXPORT_NAME = 'api_export_name';
    private static readonly FIELD_PDF_EXPORT_NAME = 'pdf_export_name';
    private static readonly FIELD_MIN_LENGTH = 'min_length';
    private static readonly FIELD_MAX_LENGTH = 'max_length';
    private static readonly FIELD_MIN_VALUE = 'min_value';
    private static readonly FIELD_MAX_VALUE = 'max_value';
    private static readonly FIELD_DEFAULT_VALUE = 'default_value';
    private static readonly FIELD_FORM_TABLE = 'form_table';
    private static readonly FIELD_LIST_ITEM_TYPE = 'type';
    private static readonly FIELD_MASTER_DATA_ID = 'master_data_id';
    private static readonly FIELD_DISPLAYED_FORM_FIELD_ID = 'displayed_form_field_id';
    private static readonly CUSTOMER_ID = 'customer_id';

    private static readonly keyFieldDataTypeSelectorMap: Map<FormResource.FieldDataTypeSelector, FieldDataTypeSelector> = Map.of(
      'STRING', FieldDataTypeSelector.STRING,
      'NUMBER', FieldDataTypeSelector.NUMBER,
      'DECIMAL', FieldDataTypeSelector.DECIMAL,
      'DATE', FieldDataTypeSelector.DATE,
      'DATE_TIME', FieldDataTypeSelector.DATE_TIME,
      'BOOLEAN', FieldDataTypeSelector.BOOLEAN,
      'LIST_ITEM', FieldDataTypeSelector.LIST_ITEM,
      'EMAIL_ADDRESS', FieldDataTypeSelector.EMAIL_ADDRESS,
      'PHONE_NUMBER', FieldDataTypeSelector.PHONE_NUMBER,
      'MASTER_DATA', FieldDataTypeSelector.MASTER_DATA,
      'LIST_MULTI_ITEM', FieldDataTypeSelector.LIST_MULTI_ITEM,
      'READONLY_TEXT', FieldDataTypeSelector.READONLY_TEXT,
      'READONLY_HTML', FieldDataTypeSelector.READONLY_HTML,
      'READONLY_PICTURE', FieldDataTypeSelector.READONLY_PICTURE,
      'STOCK', FieldDataTypeSelector.STOCK,
      'STOCK_INTAKE', FieldDataTypeSelector.STOCK_INTAKE,
      'STOCK_MOVE', FieldDataTypeSelector.STOCK_MOVE,
      'FORM_TABLE', FieldDataTypeSelector.FORM_TABLE,
      'PAYMENT_TYPE', FieldDataTypeSelector.PAYMENT_TYPE,
      'CUSTOMER', FieldDataTypeSelector.CUSTOMER,
      'USER', FieldDataTypeSelector.USER,
      'DOCUMENT', FieldDataTypeSelector.DOCUMENT,
      'PHOTO', FieldDataTypeSelector.PHOTO,
      'PROCESS_ORDER_STOCK_OUTTAKE', FieldDataTypeSelector.PROCESS_ORDER_STOCK_OUTTAKE,
      'PROCESS_ORDER_STOCK_OUTTAKE_CHECK', FieldDataTypeSelector.PROCESS_ORDER_STOCK_OUTTAKE_CHECK,
      'PROCESS_ORDER_PACKAGING', FieldDataTypeSelector.PROCESS_ORDER_PACKAGING,
      'INVOICE', FieldDataTypeSelector.INVOICE,
    );

    private static readonly keyGroupValidatedFieldMap: Map<string, GroupValidatedField> = Map.of(
      Keys.GROUP_TITLE, GroupValidatedField.TITLE,
    );

    private static readonly keyFieldValidatedFieldMap: Map<string, FieldValidatedField> = Map.of(
      Keys.FIELD_TITLE, FieldValidatedField.TITLE,
      Keys.FIELD_API_EXPORT_NAME, FieldValidatedField.API_EXPORT_NAME,
      Keys.FIELD_PDF_EXPORT_NAME, FieldValidatedField.PDF_EXPORT_NAME,
      Keys.FIELD_MIN_LENGTH, FieldValidatedField.MIN_LENGTH,
      Keys.FIELD_MAX_LENGTH, FieldValidatedField.MAX_LENGTH,
      Keys.FIELD_MIN_VALUE, FieldValidatedField.MIN_VALUE,
      Keys.FIELD_MAX_VALUE, FieldValidatedField.MAX_VALUE,
      Keys.FIELD_DEFAULT_VALUE, FieldValidatedField.DEFAULT_VALUE,
      Keys.FIELD_FORM_TABLE, FieldValidatedField.FORM_TABLE,
      Keys.FIELD_LIST_ITEM_TYPE, FieldValidatedField.LIST_ITEM_TYPE,
      Keys.FIELD_MASTER_DATA_ID, FieldValidatedField.MASTER_DATA_ID,
      Keys.FIELD_DISPLAYED_FORM_FIELD_ID, FieldValidatedField.DISPLAYED_FORM_FIELD_ID,
    );

    public static toFieldDataTypeSelector(selectorKey: FormResource.FieldDataTypeSelector): FieldDataTypeSelector {
      return Keys.keyFieldDataTypeSelectorMap.get(selectorKey, FieldDataTypeSelector.UNKNOWN);
    }

    public static toValidatedGroupField(key: string): GroupValidatedField {
      return Keys.keyGroupValidatedFieldMap.get(key, GroupValidatedField.UNKNOWN);
    }

    public static toValidatedFieldField(key: string): FieldValidatedField {
      return Keys.keyFieldValidatedFieldMap.get(key, FieldValidatedField.UNKNOWN);
    }

    public static fieldDataTypeSelectorToKey(selector: FieldDataTypeSelector): FormResource.FieldDataTypeSelector {
      let result;
      Keys.keyFieldDataTypeSelectorMap.forEach((value: FieldDataTypeSelector, key: FormResource.FieldDataTypeSelector) => {
        if (selector === value) {
          result = key;
        }
      });
      return result;
    }

  }

  // </editor-fold>

}
