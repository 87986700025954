import * as draw2d from '../../../../../../../node_modules/draw2d/dist/draw2d.js';
import { LineSelectionFeedbackPolicy } from '../policy/line-selection-feedback-policy';

// Custom policy for line selection to be green
export class ConnectionRouter extends draw2d.layout.connection.InteractiveManhattanConnectionRouter {

  constructor() {
    super();
  }

  onInstall(conn) {
    super.onInstall(conn);
    // This install the custom policy for line selection
    conn.installEditPolicy(new LineSelectionFeedbackPolicy())
  }

}
