/* eslint-disable */
import { ComponentFactoryResolver, ComponentRef, Directive, ViewContainerRef, } from '@angular/core';
import { FormRecordFieldWrapperComponent } from './field-wrapper/form-record-field-wrapper.component';
import { FormRecordFieldLineBreakComponent } from './line-break/form-record-field-line-break.component';
import { FormRecordFieldView } from '../../../../util/form/form-utils';
import { FormRecordDisabledFieldComponent } from '../fields/disabled/form-record-disabled-field.component';
import { Form } from '../../../../lib/form/form.service';
/* eslint-enable */

/* eslint-disable */
@Directive({
  selector: '[app-form-record-field-holder]',
})
/* eslint-enable */
export class FormRecordFieldHolderDirective {

  constructor(public viewContainerRef: ViewContainerRef, private cfr: ComponentFactoryResolver) {
  }

  createFieldWrapperView(): ComponentRef<FormRecordFieldWrapperComponent> {
    const cmp = this.cfr.resolveComponentFactory(FormRecordFieldWrapperComponent)
      .create(this.viewContainerRef.injector);
    return cmp;
  }

  createLineBreakView(): ComponentRef<FormRecordFieldLineBreakComponent> {
    const cmp = this.cfr.resolveComponentFactory(FormRecordFieldLineBreakComponent)
      .create(this.viewContainerRef.injector);
    return cmp;
  }

  createDisabledFieldView(selector: Form.FieldDataTypeSelector): ComponentRef<FormRecordFieldView> {
    const cmp = this.cfr.resolveComponentFactory(FormRecordDisabledFieldComponent)
      .create(this.viewContainerRef.injector);
    cmp.instance.setFieldDataTypeSelector(selector);
    return cmp;
  }

}
