/* eslint-disable */
import { AfterViewInit, Component, ViewChild, } from '@angular/core';
import {
  FieldDataTypeSelectors,
  FormEditFieldCreateDialogView,
  FormEditFieldDialogContext,
  FormEditFieldUpdateDialogView,
  FormGroupModel,
  FormModel,
} from '../../../../../../../util/form/form-utils';
import { InputMask } from '../../../../../../../util/input-masks';
import { Form } from '../../../../../../../lib/form/form.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FieldWidthType } from '../../../../../../../util/form/form-field-width-type';
import {
  FieldValidationError,
  FormRef,
  LocalFieldValidationErrors,
  LocalFieldValidationErrorsFactory,
} from '../../../../../../../lib/util/services';
import { NgModel } from '@angular/forms';
import { List, Set } from 'immutable';
import { OptionItem, UiConstants } from '../../../../../../../util/core-utils';
import {
  FieldValidationType,
  FormFieldValidationTypeModel,
} from '../../../../../../../util/form/form-field-validation-type';
import { Models } from '../../../../../../../util/model-utils';
import { Angular2Multiselects } from '../../../../../../../util/multiselect';
import { TranslateService } from '@ngx-translate/core';

/* eslint-enable */

@Component({
  selector: 'app-form-edit-create-update-boolean-field-dialog',
  templateUrl: 'form-edit-create-update-boolean-field-dialog.component.html',
  styleUrls: ['form-edit-create-update-boolean-field-dialog.component.scss'],
})
export class FormEditCreateUpdateBooleanFieldDialogComponent
  implements FormEditFieldCreateDialogView, FormEditFieldUpdateDialogView, FormRef, AfterViewInit {

  public readonly selector = Form.FieldDataTypeSelector.BOOLEAN;
  public readonly createTitle = 'FORM_ITEM_CREATE_BOOLEAN_TITLE';
  public readonly updateTitle = 'FORM_ITEM_UPDATE_BOOLEAN_TITLE';
  public readonly cloneTitle = 'FORM_ITEM_CLONE_BOOLEAN_TITLE';

  InputMask = InputMask;
  Form = Form;
  UiConstants = UiConstants;

  @ViewChild('fieldDialog', { static: true })
  dialogComponent: ModalDirective;

  @ViewChild('title')
  titleInput: NgModel;

  @ViewChild('api_export_name')
  apiExportNameInput: NgModel;

  visible: boolean = false;
  fieldModel: Model = new Model();
  dialogTitleDictionaryKey: string = '';
  latestApiExportName = '';

  fieldWidthTypes: FieldWidthType[] = FieldWidthType.VALUES.toArray();
  fieldValidationTypes: Form.FormFieldValidationType[] = FieldValidationType.VALUES.toArray();

  formFieldItemsToActivate: FormFieldItem[] = [];
  formGroupItemsToActivate: FormGroupItem[] = [];

  private fieldErrors: FieldValidationError<Form.FieldValidatedField> =
    FieldValidationError.empty<Form.FieldValidatedField>();

  private validatedInputs: LocalFieldValidationErrors<NgModel> =
    LocalFieldValidationErrorsFactory.empty();

  private fieldDataTypeSelectors: FieldDataTypeSelectors;
  private context: FormEditFieldDialogContext;
  private group: FormGroupModel;
  private field?: Form.Field;
  private clone?: boolean;
  private submitted: boolean = false;

  dropdownSettings: Angular2Multiselects.Settings;

  constructor(private translateService: TranslateService) {}

  getForm() {
    return {
      submitted: this.submitted
    };
  }

  appInit(fieldDataTypeSelectors: FieldDataTypeSelectors): void {
    this.fieldDataTypeSelectors = fieldDataTypeSelectors;
  }

  ngAfterViewInit(): void {
    this.loadLocalFieldValidationErrors();
    this.initDropDown();
  }
  private initDropDown() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .labelKey('text')
      .build();
  }

  registerContext(context: FormEditFieldDialogContext): void {
    this.context = context;
  }

  showDialog(form: FormModel, group: FormGroupModel, field?: Form.Field, clone?: boolean): void {
    this.latestApiExportName = '';
    this.visible = true;
    this.group = group;
    this.clone = clone;
    const fieldsToActivate = this.createFieldsToActivate(form);
    const groupsToActivate = this.createGroupsToActivate(form);
    if (field && clone) {
      this.dialogTitleDictionaryKey = this.cloneTitle;
      this.loadField(field, fieldsToActivate, groupsToActivate);
    }
    else if (field) {
      this.dialogTitleDictionaryKey = this.updateTitle;
      this.loadField(field, fieldsToActivate, groupsToActivate);
    }
    else {
      this.dialogTitleDictionaryKey = this.createTitle;
    }
    this.formFieldItemsToActivate = this.filterFieldsToActivate(fieldsToActivate);
    this.formGroupItemsToActivate = this.filterGroupsToActivate(groupsToActivate);
    this.dialogComponent.show();
  }

  onModalHide(event: ModalDirective) {
    if (event.dismissReason === 'backdrop-click' || event.dismissReason === 'esc') {
      this.visible = false;
      this.submitted = false;
      this.fieldModel = new Model();
    }
  }

  closeDialog(): void {
    this.visible = false;
    this.submitted = false;
    this.dialogComponent.hide();
    this.fieldModel = new Model();
    this.formFieldItemsToActivate = [];
  }

  saveField() {
    this.submitted = true;
    if (this.hasLocalFieldError(this.titleInput) || this.hasLocalFieldError(this.apiExportNameInput)) {
      return;
    }
    if (this.field && this.clone) {
      this.createField();
    }
    else if (this.field) {
      this.updateField(this.field);
    }
    else {
      this.createField();
    }
  }

  hasLocalFieldError(field?: NgModel): boolean {
    return this.validatedInputs.hasLocalError(field);
  }

  hasFieldError(field?: Form.FieldValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: Form.FieldValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: Form.FieldValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  getFieldValidationTypeDictionaryKey(vt: Form.FormFieldValidationType): string {
    return FieldValidationType.getFieldValidationTypeDictionaryKey(vt);
  }

  onAdminFormFieldValidationTypeChanged() {
    FieldValidationType.onAdminFormFieldValidationTypeChanged(this.fieldModel);
  }

  onMobileFormFieldValidationTypeChanged() {
    FieldValidationType.onMobileFormFieldValidationTypeChanged(this.fieldModel);
  }

  private loadLocalFieldValidationErrors() {
    const validatedInputs = List.of(this.titleInput, this.apiExportNameInput);
    this.validatedInputs = LocalFieldValidationErrorsFactory.ofFields(this, validatedInputs);
  }

  private loadField(field: Form.Field, fieldsToActivate: FormFieldItem[], groupsToActivate: FormGroupItem[]) {
    this.field = field;
    const attrs = field.dataType.booleanAttributes!;
    const enableOtherFields = attrs.enableOtherFields;
    const fieldIdsToBeEnabled = attrs.fieldIdsToBeEnabled;
    const groupIdsToBeEnabled = attrs.groupIdsToBeEnabled;
    const defaultValue = attrs.defaultValue;
    const fieldWidth = FieldWidthType.fromPercentValue(field.formFieldWidthPercent);
    this.fieldModel.title = field.title;
    this.fieldModel.description = field.description ? field.description : '';
    this.fieldModel.hint = field.hint ? field.hint : '';
    this.fieldModel.apiExportName = field.apiExportName ? field.apiExportName : '';
    this.fieldModel.pdfExportName = field.pdfExportName ? field.pdfExportName : '';
    this.fieldModel.enableOtherFields = enableOtherFields;
    this.fieldModel.fieldIdsToBeEnabled = this.fieldIdsToFieldItems(fieldsToActivate, fieldIdsToBeEnabled.toArray());
    this.fieldModel.groupIdsToBeEnabled = this.groupIdsToGroupItems(groupsToActivate, groupIdsToBeEnabled.toArray());
    this.fieldModel.defaultValue = defaultValue ? defaultValue : undefined;
    this.fieldModel.fieldWidthType = fieldWidth ? fieldWidth : FieldWidthType.DEFAULT;
    this.fieldModel.displayOnNewRow = field.displayOnNewRow;
    this.fieldModel.adminVisibleOnFormDetail = field.admin.visibleOnFormDetail;
    this.fieldModel.mobileVisibleOnFormDetail = field.mobile.visibleOnFormDetail;
    this.fieldModel.mobileVisibleOnMasterDetail = field.mobile.visibleOnMasterDetail;
    this.fieldModel.adminFormFieldValidationType = field.admin.validationType;
    this.fieldModel.helpdeskFormFieldValidationType = field.helpdesk.validationType;
    this.fieldModel.mobileFormFieldValidationType = field.mobile.validationType;
    this.fieldModel.showOnReceipt = field.showOnReceipt;
  }

  private createField() {
    this.context.formService.createField({
      parentId: this.context.parentId,
      groupId: this.group.groupId,
      title: this.fieldModel.title,
      description: this.fieldModel.description,
      hint: this.fieldModel.hint,
      pdfExportName: this.fieldModel.pdfExportName,
      apiExportName: this.fieldModel.apiExportName,
      dataTypeSelector: this.selector,
      dataType: this.getDataTypeRequest(),
      formFieldWidthPercent: this.fieldModel.fieldWidthType.percentValue,
      displayOnNewRow: this.fieldModel.displayOnNewRow,
      admin: {
        visibleOnFormDetail: this.fieldModel.adminVisibleOnFormDetail,
        validationType: this.fieldModel.adminFormFieldValidationType
      },
      helpdesk: {
        validationType: this.fieldModel.helpdeskFormFieldValidationType
      },
      mobile: {
        visibleOnFormDetail: this.fieldModel.mobileVisibleOnFormDetail,
        visibleOnMasterDetail: this.fieldModel.mobileVisibleOnMasterDetail,
        validationType: this.fieldModel.mobileFormFieldValidationType
      },
      showOnReceipt: this.fieldModel.showOnReceipt
    }).subscribe(
      (result) => {
        this.context.formView.reloadForm();
        this.closeDialog();
      },
      (error) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withFormRef(this);
        }
      }
    );
  }

  private updateField(field: Form.Field) {
    this.context.formService.updateField({
      parentId: this.context.parentId,
      groupId: this.group.groupId,
      fieldId: field.fieldId,
      title: this.fieldModel.title,
      description: this.fieldModel.description,
      hint: this.fieldModel.hint,
      pdfExportName: this.fieldModel.pdfExportName,
      apiExportName: this.fieldModel.apiExportName,
      dataTypeSelector: this.selector,
      dataType: this.getDataTypeRequest(),
      formFieldWidthPercent: this.fieldModel.fieldWidthType.percentValue,
      displayOnNewRow: this.fieldModel.displayOnNewRow,
      admin: {
        visibleOnFormDetail: this.fieldModel.adminVisibleOnFormDetail,
        validationType: this.fieldModel.adminFormFieldValidationType
      },
      helpdesk: {
        validationType: this.fieldModel.helpdeskFormFieldValidationType
      },
      mobile: {
        visibleOnFormDetail: this.fieldModel.mobileVisibleOnFormDetail,
        visibleOnMasterDetail: this.fieldModel.mobileVisibleOnMasterDetail,
        validationType: this.fieldModel.mobileFormFieldValidationType
      },
      showOnReceipt: this.fieldModel.showOnReceipt
    }).subscribe(
      (result) => {
        this.context.formView.reloadForm();
        this.closeDialog();
      },
      (error) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withFormRef(this);
        }
      }
    );
  }

  private getDataTypeRequest(): Form.FieldDataType {
    return {
      booleanAttributes: {
        defaultValue: this.fieldModel.defaultValue,
        enableOtherFields: this.fieldModel.enableOtherFields,
        fieldIdsToBeEnabled: Set.of(... this.fieldItemsToFieldIds(this.fieldModel.fieldIdsToBeEnabled)),
        groupIdsToBeEnabled: Set.of(... this.groupItemsToGroupIds(this.fieldModel.groupIdsToBeEnabled))
      }
    };
  }

  private createFieldsToActivate(form: FormModel): FormFieldItem[] {
    const items: FormFieldItem[] = [];
    form.formGroups.forEach(g => {
      g.fields.forEach(f => {
        items.push({
          id: f.fieldId,
          text: f.title,
          itemSubtitle: g.title + ' (' + g.groupId + ')',
          field: f,
        });
      });
    });
    return items;
  }

  private filterFieldsToActivate(items: FormFieldItem[]): FormFieldItem[] {
    const filtered: FormFieldItem[] = [];
    items.forEach(item => {
      if (!this.field || (this.field && item.field.fieldId !== this.field.fieldId))  {
        const descriptor = this.fieldDataTypeSelectors.findDescriptor(item.field.dataTypeSelector);
        if (descriptor.canBeInactivated) {
          filtered.push(item);
        }
      }
    });
    return filtered;
  }

  refreshSelectedFieldList(value: FormFieldItem[]): void {
    this.fieldModel.fieldIdsToBeEnabled = value;
  }

  fieldIdsToFieldItems(items: FormFieldItem[], values: number[]): FormFieldItem[] {
    const ret: FormFieldItem[] = [];
    values.forEach(id => {
      const item: FormFieldItem | undefined = items.find(f => {
        return id === f.id;
      });
      if (item) {
        ret.push(item);
      }
    });
    return ret;
  }

  fieldItemsToFieldIds(values: FormFieldItem[]): number[] {
    const ret: number[] = [];
    values.forEach(f => {
      if (f.id) {
        ret.push(f.id);
      }
    });
    return ret;
  }

  private createGroupsToActivate(form: FormModel): FormGroupItem[] {
    const items: FormGroupItem[] = [];
    form.formGroups.forEach(g => {
      items.push({
        id: g.groupId,
        text: g.title,
        group: g,
      });
    });
    return items;
  }

  private filterGroupsToActivate(items: FormGroupItem[]): FormGroupItem[] {
    const filtered: FormGroupItem[] = [];
    items.forEach(item => {
      if (!this.group || (this.group && item.group.groupId !== this.group.groupId))  {
        filtered.push(item);
      }
    });
    return filtered;
  }

  refreshSelectedGroupList(value: FormGroupItem[]): void {
    this.fieldModel.groupIdsToBeEnabled = value;
  }

  groupIdsToGroupItems(items: FormGroupItem[], values: number[]): FormGroupItem[] {
    const ret: FormGroupItem[] = [];
    values.forEach(id => {
      const item: FormGroupItem | undefined = items.find(g => {
        return id === g.id;
      });
      if (item) {
        ret.push(item);
      }
    });
    return ret;
  }

  groupItemsToGroupIds(values: FormGroupItem[]): number[] {
    const ret: number[] = [];
    values.forEach(g => {
      if (g.id) {
        ret.push(g.id);
      }
    });
    return ret;
  }

  onTitleBlur() {
    const normalizedText = Models.normalizeText(this.fieldModel.title);
    if (this.fieldModel.apiExportName === '') {
      this.fieldModel.apiExportName = normalizedText;
    }
    if (this.fieldModel.pdfExportName === '') {
      this.fieldModel.pdfExportName = normalizedText;
    }
  }
}

export class Model implements FormFieldValidationTypeModel {
  title: string = '';
  description: string = '';
  apiExportName: string = '';
  pdfExportName: string = '';
  defaultValue?: boolean;
  enableOtherFields: boolean;
  fieldIdsToBeEnabled: FormFieldItem[] = [];
  groupIdsToBeEnabled: FormGroupItem[] = [];
  fieldWidthType: FieldWidthType = FieldWidthType.DEFAULT;
  displayOnNewRow: boolean = false;
  hint: string = '';
  adminVisibleOnFormDetail: boolean = true;
  mobileVisibleOnFormDetail: boolean = true;
  mobileVisibleOnMasterDetail: boolean = false;
  adminFormFieldValidationType: Form.FormFieldValidationType = Form.FormFieldValidationType.OPTIONAL;
  helpdeskFormFieldValidationType: Form.FormFieldValidationType = Form.FormFieldValidationType.OPTIONAL;
  mobileFormFieldValidationType: Form.FormFieldValidationType = Form.FormFieldValidationType.OPTIONAL;
  showOnReceipt?: boolean = false;
}

export class FormFieldItem extends OptionItem<number> {
  field: Form.Field;
  itemSubtitle: string;
}

export class FormGroupItem extends OptionItem<number> {
  group: FormGroupModel;
}
