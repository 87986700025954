import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { RightModel } from '../../../app.rights';
import { StockItemService } from '../../../lib/stock/stock-item.service';
import { DisabledEnum, DisabledItem, SearchUtils } from '../../../util/search-utils';
import { StockItemMeasurementSearch, StockItemMeasurementSearchService } from '../../../lib/stock/stock-item-measurement-search-service';
import { QueryFieldModel, UiConstants } from '../../../util/core-utils';
import { InputMask } from '../../../util/input-masks';
import { OrderType, ResourceQueryResult, Services } from '../../../lib/util/services';
import { Set } from 'immutable';
import { DropdownItemType } from '../../../shared/dropdown/dropdown-item/dropdown-item-type';
import { StockItemUnitOfMeasure } from '../../../lib/stock/stock-item-unit-of-measure';
import { MatDialog } from '@angular/material/dialog';
import { StockItemMeasurementDialogComponent } from './stock-item-measurement-dialog/stock-item-measurement-dialog.component';

@Component({
  selector: 'app-stock-item-measurement-list',
  templateUrl: './stock-item-measurement-list.component.html',
  styleUrls: ['./stock-item-measurement-list.component.scss']
})
export class StockItemMeasurementListComponent extends SearchUtils.SearchableList<StockItemMeasurementSearch.Model>
  implements OnInit, OnDestroy {

  StockItemUnitOfMeasure = StockItemUnitOfMeasure;
  UiConstants = UiConstants;
  InputMask = InputMask;
  DropdownItemType = DropdownItemType;

  @Input()
  stockItemId: number;

  @Input()
  rightModel: RightModel = RightModel.empty();

  @Input()
  readonly: boolean;

  searchModel: StockItemMeasurementSearch.Model = new StockItemMeasurementSearch.Model();

  list: StockItemUnitOfMeasure.StockItemUnitOfMeasure[] = [];
  queryModel: QueryFieldModel<StockItemUnitOfMeasure.OrderField>
    = new QueryFieldModel(StockItemUnitOfMeasure.OrderField.ID, OrderType.DESC);

  disabledItems: DisabledItem[] = [];


  constructor(private stockItemService: StockItemService,
              private dialog: MatDialog,
              private searchService: StockItemMeasurementSearchService,
              injector: Injector) {
    super(StockItemMeasurementSearch.Model, injector);
  }

  ngOnInit(): void {
    this.initSearch();
  }

  loadList(pageNumber?: number): void {
    const disabled: boolean | undefined = !this.searchModel.disabled ||
    this.searchModel.disabled.id === DisabledEnum.NONE ?
      undefined : this.searchModel.disabled.id === DisabledEnum.TRUE;
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();
    this.stockItemService.queryUnitOfMeasures({
      stock_item_id: this.stockItemId,
      page_number: requestedPage,
      number_of_items: this.queryModel.itemsPerPage,
      order: Services.createOrderFieldParameter(StockItemUnitOfMeasure.Keys.toOrderFieldKey, Set.of(order)),
      include_base_unit: this.searchModel.includeBaseUnit,
      disabled: disabled
    }).subscribe((result: ResourceQueryResult<StockItemUnitOfMeasure.StockItemUnitOfMeasure>) => {
      this.list = result.items;
      this.queryModel.currentPage = requestedPage;
      this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
    });

  }

  loadSearch(completion: () => void): void {
    this.searchService.getSearchData({stockItemId: this.stockItemId})
      .subscribe(
        (result: StockItemMeasurementSearch.SearchDataResult) => {
          this.queryModel.itemsPerPage = result.searchData.itemsPerPage;
          this.queryModel.currentPage = result.searchData.pageNumber;
          this.queryModel.setOrder(result.searchData.order);
          this.searchModel.disabled = result.searchData.disabled;
          this.searchModel.includeBaseUnit = result.searchData.includeBaseUnit;
          completion();
        }
      );
  }

  onFirstSearchOpen(): void {
    this.disabledItems = this.initDisabledOptions();
  }


  orderBy(field: StockItemUnitOfMeasure.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  onSearchClicked() {
    this.loadList(1);
  }

  onSearchReset() {
    this.searchService.resetSearchData({stockItemId: this.stockItemId}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.loadList(1);
        });
      }
    );
  }

  private saveSearch() {
    const request = {
      stockItemId: this.stockItemId,
      searchData: {
        itemsPerPage: this.queryModel.itemsPerPage,
        pageNumber: this.queryModel.currentPage,
        order: this.queryModel.getOrder(),
        includeBaseUnit: this.searchModel.includeBaseUnit,
        disabled: this.searchModel.disabled,
      }
    };
    this.searchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  onDisableClicked(measurement: StockItemUnitOfMeasure.StockItemUnitOfMeasure, disabled: boolean) {
    this.stockItemService.disableUnitOfMeasure({
      stock_item_id: this.stockItemId,
      id: measurement.id,
      disabled: disabled
    }).subscribe(() => {
      this.loadList(1);
    });
  }

  onEditClicked(measurement: StockItemUnitOfMeasure.StockItemUnitOfMeasure) {
    StockItemMeasurementDialogComponent.openDialog(this.dialog, {
        readonly: false,
        stockItemId: this.stockItemId,
        measurementId: measurement.id
      },
      (result) => {
        if (result?.modified) {
          this.loadList()
        }
      });
  }

  onCreateClicked() {
    StockItemMeasurementDialogComponent.openDialog(this.dialog, {
        readonly: false,
        stockItemId: this.stockItemId
      },
      (result) => {
        if (result?.modified) {
          this.loadList()
        }
      });
  }

  ngOnDestroy(): void {
    this.saveSearch();
  }

}
