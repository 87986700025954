/* eslint-disable */
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Transition } from '@uirouter/angular';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DocumentGroup, DocumentGroupService } from '../../../../lib/document/document-group.service';
import { StringKey } from '../../../../app.string-keys';
import { Arrays } from '../../../../lib/util/arrays';
import { LanguageService } from '../../../../lib/language.service';
import { DocumentFileService } from '../../../../lib/document/document-file.service';
import { DocumentFileReadModel } from '../../../../util/document/document-file-utils';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ContentTypeParser, DownloadedFile } from '../../../../lib/util/downloaded-files';
import { BreadcrumbParent } from '../../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { StateName } from '../../../../app.state-names';
import { ResourceQueryResult } from '../../../../lib/util/services';
import { MultiselectOptionItem, UiConstants } from '../../../../util/core-utils';
/* eslint-enable */

@Component({
  selector: 'app-document-file-read',
  templateUrl: 'document-file-read.component.html',
  styleUrls: ['document-file-read.component.scss']
})
export class DocumentFileReadComponent implements OnInit, AfterViewInit {
  UiConstants = UiConstants;

  model: DocumentFileReadModel;
  documentId: number;
  documentGroupHierarchy: MultiselectOptionItem<number>[] = [];
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  imageToShow: any | null = null;
  thumbnailToShow: any | null = null;
  showFiles: boolean = true;
  hasThumbnailText: string = '';

  thumbnailLoaded = false;
  thumbnailSrc: SafeStyle;

  imageLoaded = false;
  imageSrc: SafeStyle;

  ctp: ContentTypeParser;

  @ViewChild('fileIcon') fileIcon: ElementRef;
  @ViewChild('thumbnailDialog', { static: true }) thumbnailDialog: ModalDirective;
  @ViewChild('imageDialog', { static: true }) imageDialog: ModalDirective;

  loadLanguageCodes(language_code?: string) {
    this.translateService.get(StringKey.COMMON_VALUE_UNSELECTED).subscribe(
      (text: string) => {
        this.model.language_code = text;
      }
    );

    if (!language_code) {
      return;
    }

    this.languageService.query({}).subscribe((result: ResourceQueryResult<string>) => {
      Arrays.iterateByIndex( result.items, language => {
        if (language === language_code) {
          this.model.language_code = language_code;
        }
      });
    });
  }

  loadModel() {
    this.fileDocumentService.get({
      id: this.documentId
    }).subscribe( fileDocument => {
      const model = new DocumentFileReadModel();
      model.id = fileDocument.id;
      model.external_id = fileDocument.external_id;
      model.name = fileDocument.name;
      model.size = fileDocument.content_size;
      model.user_id = fileDocument.owner_user;
      model.language_code = fileDocument.language_code;
      model.document_group_id = fileDocument.document_group_id;
      model.file_type = fileDocument.content_type;
      model.content_update_time = fileDocument.content_update_time;
      model.creation_time = fileDocument.creation_time;
      model.update_time = fileDocument.update_time;
      model.disabled = fileDocument.disabled;
      model.description = fileDocument.description;
      model.has_thumbnail = fileDocument.has_thumbnail;
      model.contentType = fileDocument.content_type;
      this.ctp = new ContentTypeParser(fileDocument.content_type);

      if (model.document_group_id) {
        this.loadDocumentGroupHierarchy(model.document_group_id);
      }

      if (this.ctp.isImageType) {
        this.downloadImageForPreview();
      }

      if (model.has_thumbnail) {
        this.downloadThumbnailForPreview();
        this.translateService.get('DOCUMENT_THUMBNAIL_UPLOADED').subscribe(text => {
          this.hasThumbnailText = text;
        });
      }
      else {
        this.translateService.get('DOCUMENT_THUMBNAIL_NOT_UPLOADED').subscribe(text => {
          this.hasThumbnailText = text;
        });
      }

      this.model = model;
      this.breadcrumbSelf = model.name;
    });
  }

  ngOnInit() {
    this.translateService.get('COMMON_DOCUMENTS').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.DOCUMENT_DASHBOARD});
      }
    );
    this.translateService.get('MENU_NAVBAR_DOCUMENT_FILE').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.DOCUMENT_FILE_LIST});
      }
    );
  }

  ngAfterViewInit() {
    this.loadLanguageCodes();
    this.loadModel();
  }

  private loadDocumentGroupHierarchy(documentGroupId: number) {
    this.documentGroupService.query({}).subscribe((result: ResourceQueryResult<DocumentGroup>) => {
      const groups = result.items;
      this.extractGroupAndParent(groups, documentGroupId);
    });
  }

  private extractGroupAndParent(groups: DocumentGroup[], groupId: number) {
    const group = groups.find(g => g.id === groupId)!;
    this.documentGroupHierarchy.unshift({
      id: group.id!,
      itemName: group.name
    });
    if (group.parent_id) {
      this.extractGroupAndParent(groups, group.parent_id);
    }
  }

  downloadThumbnail() {
    this.thumbnailDialog.show();
    this.fileDocumentService.downloadThumbnail(this.documentId).subscribe(
      (res: DownloadedFile) => {
        this.imageToShow = URL.createObjectURL(res.getBlob());
      }
    );
  }

  downloadFile() {
    this.fileDocumentService.downloadContent(this.documentId).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName(this.model.name));
      }
    );
  }

  downloadThumbnailForPreview() {
    this.thumbnailLoaded = false;
    this.fileDocumentService.downloadThumbnail(this.documentId).subscribe(
      (res: DownloadedFile) => {
        this.thumbnailToShow = URL.createObjectURL(res.getBlob());
        this.thumbnailSrc = this.sanitizer.bypassSecurityTrustStyle(`url(${this.thumbnailToShow})`);
        this.thumbnailLoaded = true;
      }
    );
  }

  downloadImageForPreview() {
    this.imageLoaded = false;
    this.fileDocumentService.downloadContent(this.documentId).subscribe(
      (res: DownloadedFile) => {
        this.imageToShow = URL.createObjectURL(res.getBlob());
        this.imageSrc = this.sanitizer.bypassSecurityTrustStyle(`url(${this.imageToShow})`);
        this.imageLoaded = true;
      }
    );
  }

  downloadImage() {
    this.fileDocumentService.downloadContent(this.documentId).subscribe(
      (res: DownloadedFile) => {
        this.imageToShow = URL.createObjectURL(res.getBlob());
        this.imageDialog.show();
      }
    );
  }

  downloadThumbnailAsFile() {
    this.fileDocumentService.downloadThumbnail(this.documentId).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName(this.model.name));
      }
    );
  }

  back() {
    window.history.back();
  }

  constructor(
    private sanitizer: DomSanitizer,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private documentGroupService: DocumentGroupService,
    private fileDocumentService: DocumentFileService,
    private transition: Transition) {
    this.documentId = this.transition.params().id;
    this.model = new DocumentFileReadModel();
  }
}
