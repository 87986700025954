import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TaskResource } from '../../../lib/task/task-resource.service';
import { MultiselectOptionItem, UiConstants } from '../../../util/core-utils';
import { TaskService } from '../../../lib/task/task.service';
import { ForwardingNgFormRef, LocalFormGroupValidationErrors } from '../../../lib/util/services';
import { AppValidators } from '../../../util/app-validators';
import { Angular2Multiselects } from '../../../util/multiselect';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-task-export-dialog',
  templateUrl: './task-export-dialog.component.html',
  styleUrls: ['./task-export-dialog.component.scss']
})
export class TaskExportDialogComponent implements OnInit {

  UiConstants = UiConstants;
  @ViewChild('f') form: NgForm;
  formGroup: FormGroup;
  private formGroupValidationErrors: LocalFormGroupValidationErrors;

  model: TaskImportModel = new TaskImportModel();

  exportInProgress: boolean = false;
  offlineDropdownSettings: Angular2Multiselects.Settings;

  constructor(
    private translateService: TranslateService,
    private taskService: TaskService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<TaskExportDialogComponent, TaskExportDialogResult>,
    @Inject(MAT_DIALOG_DATA) public data: TaskExportDialogData
  ) {

    this.formGroup = this.createFormGroup(formBuilder);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(
      this.createForwardingHtmlForm(),
      this.formGroup
    );

  }

  public static openDialog(
    dialog: MatDialog,
    data: TaskExportDialogData,
    resultCallback: (result?: TaskExportDialogResult) => void) {
    const dialogRef = dialog.open(TaskExportDialogComponent, {
      closeOnNavigation: true,
      data: data,
      width: '60%',
      height: 'auto',
      panelClass: 'custom-dialog-container'
    });
    dialogRef.afterClosed().subscribe((result?: TaskExportDialogResult) => {
      resultCallback(result);
    });
  }

  ngOnInit(): void {
    this.offlineDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .remoteSearch(false)
      .searchBy(['itemName'])
      .translate(true)
      .build();
  }

  closeDialog(taskId?: number) {
    if (taskId) {
      this.dialogRef.close({taskId: taskId});
    }
    else {
      this.dialogRef.close();
    }
  }

  exportTask() {
    if (this.hasLocalFieldError()) {
      return;
    }
    this.exportInProgress = true;
    this.taskService.exportJson({
      taskId: this.data.taskId,
      includeBaseData: this.model.isBaseDataExportable(),
      includeRoles: this.model.isRoleExportable(),
      includeTriggers: this.model.isTriggerExportable(),
      includeFormData: this.model.isFormExportable()
    }).subscribe(result => {
      const blob = new Blob([JSON.stringify(result)], {type: 'text/json;charset=utf-8'});
      saveAs(blob, `task_${this.data.taskId}_${this.model.generateTypeList()}.json`);
      this.exportInProgress = false;
      this.closeDialog();
    });
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  private createForwardingHtmlForm() {
    return new ForwardingNgFormRef({
      formFn: () => {
        return this.form;
      }
    });
  }

  private createFormGroup(fb: FormBuilder): FormGroup {
    return fb.group({
      exportableDataTypes: fb.control({
          value: this.model.exportableDataTypes
        }, [Validators.required]
      )
    });
  }

}

export interface TaskExportDialogResult {
}

export interface TaskExportDialogData {
  taskId: number;
}

export enum TaskImportDataType {
  BASE_DATA = 'BASE_DATA',
  FORM = 'FORM',
  TRIGGERS = 'TRIGGERS',
  ROLES = 'ROLES'
}

class TaskImportModel {
  availableDataTypes: MultiselectOptionItem<TaskImportDataType>[] = [];
  exportableDataTypes: MultiselectOptionItem<TaskImportDataType>[] = [];

  constructor() {
    this.reset();
  }

  calcAvailableDataTypes() {
      this.availableDataTypes.push({id: TaskImportDataType.BASE_DATA, itemName: 'TASK_IMPORT_TYPE_BASE_DATA'});
      this.availableDataTypes.push({id: TaskImportDataType.FORM, itemName: 'TASK_IMPORT_TYPE_FORM'});
      this.availableDataTypes.push({id: TaskImportDataType.TRIGGERS, itemName: 'TASK_IMPORT_TYPE_TRIGGERS'});
      this.availableDataTypes.push({id: TaskImportDataType.ROLES, itemName: 'TASK_IMPORT_TYPE_ROLES'});
  }

  isFormExportable(): boolean {
    return this.exportableDataTypes.find(t => t.id === TaskImportDataType.FORM) !== undefined;
  }

  isTriggerExportable(): boolean {
    return this.exportableDataTypes.find(t => t.id === TaskImportDataType.TRIGGERS) !== undefined;
  }

  isRoleExportable(): boolean {
    return this.exportableDataTypes.find(t => t.id === TaskImportDataType.ROLES) !== undefined;
  }

  isBaseDataExportable(): boolean {
    return this.exportableDataTypes.find(t => t.id === TaskImportDataType.BASE_DATA) !== undefined;
  }

  generateTypeList(): string {
    return this.exportableDataTypes.map(t => '' + t.id).map(t => t.toLowerCase()).join('_');
  }

  reset() {
    this.availableDataTypes = [];
    this.exportableDataTypes = [];
    this.calcAvailableDataTypes();
    this.availableDataTypes.forEach(t => {
      this.exportableDataTypes.push(t);
    });
  }
}
