<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="card col-lg-8 col-md-8 col-xs-12 col-sm-12 p-0">

    <div class="card-header">
      {{'SURVEY_PANEL_HEADING_ADD'|translate}}
    </div>

    <div class="card-body">
    <form class="form-horizontal bordered-row" (ngSubmit)="f.form.valid && createSurvey()" #f="ngForm" novalidate>

      <div class="form-group row" [ngClass]="{ 'has-danger': hasFieldError(Survey.ValidatedField.NAME) || (f.submitted && !name.valid) }">
        <label class="col-form-label form-control-label col-md-4 detail-title required-field-label">
          {{'COMMON_NAME' | translate}}
        </label>
        <div class="col-md-8">
          <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 [ngClass]="{ 'form-control-danger': hasFieldError(Survey.ValidatedField.NAME) || (f.submitted && !name.valid) }"
                 [(ngModel)]="model.name" (ngModelChange)="removeFieldError(Survey.ValidatedField.NAME)" name="name" id="name" #name="ngModel" required>
          <div class="form-control-feedback" *ngIf="hasFieldError(Survey.ValidatedField.NAME)">{{getFieldErrorText(Survey.ValidatedField.NAME)}}</div>
          <div class="form-control-feedback" *ngIf="f.submitted && !name.valid">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
        </div>
      </div>

      <div class="form-group row" [ngClass]="{ 'has-danger': hasFieldError(Survey.ValidatedField.EXTERNAL_ID) || (f.submitted && !externalId.valid) }">
        <label class="col-form-label form-control-label col-md-4 detail-title required-field-label">
          {{'COMMON_EXTERNAL_ID' | translate}}
        </label>
        <div class="col-md-8">
          <input type="text" class="form-control" placeholder="{{'COMMON_EXTERNAL_ID' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 [ngClass]="{ 'form-control-danger': hasFieldError(Survey.ValidatedField.EXTERNAL_ID) || (f.submitted && !externalId.valid) }"
                 [(ngModel)]="model.externalId" (ngModelChange)="removeFieldError(Survey.ValidatedField.EXTERNAL_ID)" name="externalId" id="externalId" #externalId="ngModel">
          <div class="form-control-feedback" *ngIf="hasFieldError(Survey.ValidatedField.EXTERNAL_ID)">{{getFieldErrorText(Survey.ValidatedField.EXTERNAL_ID)}}</div>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-form-label form-control-label col-md-4 detail-title">{{'SURVEY_DESCRIPTION' | translate}}</label>
        <div class="col-md-8">
          <input type="text" class="form-control" placeholder="{{'SURVEY_DESCRIPTION' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 [(ngModel)]="model.description" name="description" id="description" #description="ngModel">
        </div>
      </div>

      <div>
        <div class="d-flex justify-content-end">
          <input type="submit" class="btn btn-primary" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
        </div>
      </div>

    </form>
    </div>
  </div>

</div>
