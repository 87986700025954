<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0">
  <div class="card" [ngClass]="{ 'border-bottom-0': messageSendEnable }">
    <div class="card-header">{{'COMMON_MESSAGES'|translate}}
      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="loadMessages()" title="{{'CHAT_REFRESH_MESSAGES' | translate}}">
          <i class="icomoon icomoon-process-reopen"></i>
        </a>
      </div><!-- /.card-actions -->
    </div>
    <!-- /.card-header -->
    <div class="card-body">
      <div *ngIf="messageList.length === 0">
        <div class="p-2 text-center text-muted">
          <h6>{{ "CHAT_NO_MESSAGE" | translate }}</h6>
        </div>
      </div>
      <div *ngIf="messageList.length > 0 && messageList.length < totalNumberOfItems">
        <div class="pb-2 text-center">
          <button type="button" class="btn btn-secondary" (click)="loadMessages(true)">{{'CHAT_MESSAGE_LOAD_PREVIOUS' | translate}}
          </button>
        </div>
      </div>
      <div class="message-container" *ngFor="let message of messageList; let last = last"
           [ngClass]="{ 'message-bottom-margin': !last }">
        <div class="d-flex justify-content-between flex-row flex-wrap">
          <label class="message-sender mb-0">{{ message.userPersonName }}</label>
          <label>{{ message.creationTime.toUtcIsoString() | date:'short' }}</label>
        </div>
        <div class="mw-100 text-ellipsis break-word">
          {{ message.content }}
        </div>
      </div>
    </div>
    <!-- /.card-body -->
  </div>
  <div [ngClass]="{ 'has-danger': newMessageError }" *ngIf="messageSendEnable">
    <div class="input-group">
      <input class="form-control" type="text" placeholder="{{'CHAT_NEW_MESSAGE' | translate}}"
             [(ngModel)]="newMessage"
             (ngModelChange)="removeNewMessageError()"
             (keyup.enter)="sendMessage()"
             name="newMessage" id="newMessage">
      <span class="add-on input-group-addon cursor-pointer" (click)="sendMessage()">
            <i class="icomoon icomoon-message-send"></i>
          </span>
    </div>
    <div class="form-control-feedback" *ngIf="newMessageError">{{'CHAT_MESSAGE_REQUIRED' | translate}}</div>
  </div>
  <!-- /.card -->
</div>
