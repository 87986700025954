import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { Services } from '../util/services';
import { EmptyMessage } from '../util/messages';
import { OffsetDateTime } from '../util/dates';
import { NfcCardResource, NfcCardResourceService } from './nfc-card-resource.service';

@Injectable()
export class NfcCardService implements NfcCard.Service {

  constructor(private resourceService: NfcCardResourceService) {
  }

  query(request: NfcCard.GetRequest): Observable<NfcCard.NfcCard[]> {
    return Observable.create((observer: Observer<NfcCard.NfcCard[]>) => {
      const resourceRequest: NfcCardResource.QueryRequest = {
        user_id: request.userId
      };
      return this.resourceService.query(resourceRequest).subscribe(
        (result: NfcCardResource.NfcCard[]) => {
          observer.next(this.toPublicList(result));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  create(request: NfcCard.CreateRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: NfcCardResource.CreateRequest = {
        user_id: request.userId,
        hex_id: request.hexId
      };
      return this.resourceService.create(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  delete(request: NfcCard.DeleteRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: NfcCardResource.DeleteRequest = {
        user_id: request.userId,
        nfc_card_id: request.nfcCardId
      };
      return this.resourceService.delete(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  private toPublicList(resourceList: NfcCardResource.NfcCard[]): NfcCard.NfcCard[] {
    return resourceList.map((r) => this.toPublic(r));
  }

  private toPublic(r: NfcCardResource.NfcCard): NfcCard.NfcCard {
    return {
      id: r.id,
      hexId: r.hex_id,
      creationTime: Services.toOffsetDateTime(r.creation_time),
      mobileApplicationRegistrationCount: r.mobile_application_registration_count
    };
  }

}

export namespace NfcCard {

  export interface Service {

    // <editor-fold desc="CRUD">

    query(request: GetRequest): Observable<NfcCard.NfcCard[]>;

    create(request: CreateRequest): Observable<EmptyMessage>;

    delete(request: DeleteRequest): Observable<EmptyMessage>;

    // </editor-fold>

  }

  export interface NfcCard {
    id: number;
    hexId: string;
    creationTime: OffsetDateTime;
    mobileApplicationRegistrationCount: number;
  }

  export interface GetRequest {
    userId: number;
  }

  export interface CreateRequest {
    userId: number;
    hexId: string;
  }

  export interface DeleteRequest {
    userId: number;
    nfcCardId: number;
  }

}
