/* eslint-disable */
import { FormRecord } from '../../../lib/form/form-record.service';
import { Form } from '../../../lib/form/form.service';
import { Set } from 'immutable';
import { ConfigurationResource } from '../../../lib/core-ext/configuration.service';
import { FormRecordFieldValueUpdateArgs } from '../../../util/form/form-utils';
import { Observable } from 'rxjs';
/* eslint-enable */

export namespace FormRecordContainer {

  export interface View {
    loadFormRecord(args: Args);

    createModel(): Model;

    hasLocalFieldError(): boolean;

    validateWithInterrupt(): boolean;

    shouldNotifyAfterCreation(): boolean;

    afterFormRecordCreation(formRecordId: number): Observable<any>;

    updateFormRecordValues(args: UpdateArgs);
  }

  export interface Args {
    configuration: ConfigurationResource.Configuration;
    form: Form.Form;
    record?: FormRecord.FormRecord;
    other?: any;
  }

  export interface UpdateArgs {
    fields: FieldUpdateArgs[];
  }

  export interface FieldUpdateArgs {
    fieldIdentifier: {
      groupId: number,
      fieldId: number
    }
    data: FormRecordFieldValueUpdateArgs;
  }

  // Not injected so valid inside the namespace.
  export class Model {
    fields: Set<FormRecord.FieldEditRequest> = Set.of<FormRecord.FieldEditRequest>();
  }

}
