import {DqlQuery, Query} from '../field';
import {FormRecord} from './form-record';
import {TaskRecordStateMachine} from '../../task/task-record-statemachine';

// Each class should be an interface, but it is easier to define the constant fields in the constructors.
// For now we have only one query language so it is not a problem.
// Always use the public Query namespace for variable definitions so we will be able to introduce other language easily.

export class Task {

  readonly id: Query.NumberField;
  readonly name: Query.StringField;

  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this.id = new DqlQuery.NumberField(prefix + 'id');
    this.name = new DqlQuery.StringField(prefix + 'name');
  }

}
