import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ForwardingNgFormRef, LocalFormGroupValidationErrors } from '../../../../lib/util/services';
import { UiConstants } from '../../../../util/core-utils';
import { Invoice } from '../../../../lib/invoice/invoice/invoice.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { InvoiceSettings } from '../../../../lib/invoice/invoice-settings/invoice-settings.service';
import { AppValidators } from '../../../../util/app-validators';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbDatePickerParserFormatter } from '../../../../util/ngb-datepicker';
import { EmptyMessage } from '../../../../lib/util/messages';
import { InvoiceBalanceService } from '../../../../lib/invoice/balance/invoice-balance.service';
import { Set } from 'immutable';
import { InputMask } from '../../../../util/input-masks';

@Component({
  selector: 'app-invoice-balance-create-dialog',
  templateUrl: './invoice-balance-create-dialog.component.html',
  styleUrls: ['./invoice-balance-create-dialog.component.scss']
})
export class InvoiceBalanceCreateDialogComponent implements OnInit {

  Invoice = Invoice;
  UiConstants = UiConstants;
  InvoiceSettings = InvoiceSettings;
  InputMask = InputMask;

  model: InvoiceBalanceCreateModel = new InvoiceBalanceCreateModel();

  @ViewChild('f', {static: true})
  form: NgForm;
  formGroup: FormGroup;
  private formGroupValidationErrors: LocalFormGroupValidationErrors;

  constructor(public dialogRef: MatDialogRef<InvoiceBalanceCreateDialogComponent>,
              private translateService: TranslateService,
              private datePickerParserFormatter: NgbDatePickerParserFormatter,
              private fb: FormBuilder,
              private invoiceBalanceService: InvoiceBalanceService,
              @Inject(MAT_DIALOG_DATA) public data: InvoiceBalanceCreateDialogData) {
    this.formGroup = this.createFormGroup(this.fb);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(
      this.createForwardingHtmlForm(),
      this.formGroup
    );
  }

  ngOnInit() {
    this.loadModel();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  save() {
    if (this.hasLocalFieldError()) {
      return;
    }
    else {
      this.invoiceBalanceCreate();
    }
  }

  hasFieldError(field: Invoice.ValidatedField): boolean {
    return false;
  }

  removeFieldError(field: Invoice.ValidatedField) {
  }

  getFieldErrorText(field: Invoice.ValidatedField): string {
    return '';
  }

  showIconDialog() {

  }

  private createForwardingHtmlForm() {
    return new ForwardingNgFormRef({
      formFn: () => {
        return this.form;
      }
    });
  }

  private createFormGroup(fb: FormBuilder): FormGroup {
    return fb.group(
      {
        startingDate: fb.control(
          {value: this.model.startingDate},
          [Validators.required]
        ),
        startingBalance: fb.control(
          {value: this.model.startingBalance},
          [Validators.required, AppValidators.validateOptionalPositiveOrZeroNumber]
        ),
      }
    );
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  invoiceBalanceCreate() {
    this.invoiceBalanceService.create({
      startingDate: this.datePickerParserFormatter.toLocalDate(this.model.startingDate),
      startingBalance: this.model.startingBalance,
      validInvoicePaymentTypes: Set.of('CASH'),
      currencyCode: 'HUF'
    }).subscribe(
      (result: EmptyMessage) => {
        this.closeDialog();
      }
    );
  }

  private loadModel() {
    this.model = new InvoiceBalanceCreateModel();
  }
}

class InvoiceBalanceCreateModel {
  startingDate: NgbDateStruct;
  startingBalance: number;
}

export interface InvoiceBalanceCreateDialogData {
}
