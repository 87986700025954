<div class="card">

  <div class="card-header">
    {{'MENU_NAVBAR_MILEAGE_RECORDS' | translate}}

    <div class="card-actions">
      <a class="btn-setting table-header-action-button d-flex align-items-center px-1 w-auto font-weight-normal" title="{{'COMMON_BUTTON_EXPORT' | translate}}"
         (click)="exportXls()">
        <i class="icomoon pr-1 icomoon-csv-export"></i>
        <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_EXPORT' | translate}}</span>
      </a>
      <a class="btn-setting table-header-action-button d-flex align-items-center px-1 w-auto font-weight-normal" title="{{'COMMON_BUTTON_EXPORT' | translate}}"
         (click)="exportMaconomy()" *ngIf="maconomyEnabled">
        <i class="icomoon pr-1 icomoon-csv-export"></i>
        <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_MACONOMY_EXPORT' | translate}}</span>
      </a>
    </div><!-- /.card-actions -->

  </div>

  <div class="card-body">
    <table class="table table-striped table-bordered">
      <thead>
      <tr>
        <th class="table-sorter-header">
          <app-table-field-sorter [orderField]="MileageRecordSearch.OrderFunctions.ID"
                                  [orderType]="queryModel.getOrderType(MileageRecordSearch.OrderFunctions.ID)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header hidden-md-down">
          <app-table-field-sorter [orderField]="MileageRecordSearch.OrderFunctions.USER_NAME"
                                  [orderType]="queryModel.getOrderType(MileageRecordSearch.OrderFunctions.USER_NAME)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'TASK_LABEL_OWNER_USER' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header hidden-md-down">
          <app-table-field-sorter [orderField]="MileageRecordSearch.OrderFunctions.VEHICLE_LICENCE_PLATE"
                                  [orderType]="queryModel.getOrderType(MileageRecordSearch.OrderFunctions.VEHICLE_LICENCE_PLATE)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'TRANSPORT_VEHICLE' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header">
          <app-table-sorter-no-op
            [text]="'MILEAGE_RECORD_KILOMETER_COUNT' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header hidden-lg-down">
          <app-table-field-sorter [orderField]="MileageRecordSearch.OrderFunctions.CREATION_TIME"
                                  [orderType]="queryModel.getOrderType(MileageRecordSearch.OrderFunctions.CREATION_TIME)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'MILEAGE_RECORD_CREATION_TIME' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header hidden-lg-down">
          <app-table-field-sorter [orderField]="MileageRecordSearch.OrderFunctions.FINISH_TIME"
                                  [orderType]="queryModel.getOrderType(MileageRecordSearch.OrderFunctions.FINISH_TIME)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'MILEAGE_RECORD_FINISH_TIME' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header hidden-lg-down">
          <app-table-sorter-no-op [text]="'COMMON_COMMENT' | translate"></app-table-sorter-no-op>
          <app-table-sorter-no-op class="table-text-subtitle" [text]="'MILEAGE_RECORD_JOURNEY_TYPE' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header w-table-actions">
          <app-table-sorter-no-op></app-table-sorter-no-op>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let mileageRecord of mileageRecordList | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }">
        <td class="responsive-table-column">{{mileageRecord.id}}</td>
        <td class="responsive-table-column hidden-md-down">{{mileageRecord.user.personName + ' (' + mileageRecord.user.userName + ')'}}</td>
        <td class="responsive-table-column hidden-md-down">{{mileageRecord.vehicleLicencePlate}}</td>
        <td class="responsive-table-column">{{mileageRecord.kilometerCountText}}
          <p class="table-text-subtitle mb-0">{{mileageRecord.kilometerIntervalText}}</p>
        </td>
        <td class="responsive-table-column hidden-lg-down">{{mileageRecord.creationTime.toUtcIsoString() | date: 'short'}}</td>
        <td class="responsive-table-column hidden-lg-down">{{mileageRecord.finishTime.toUtcIsoString() | date: 'short'}}</td>
        <td class="responsive-table-column hidden-lg-down">
          {{mileageRecord.note}}
          <p class="table-text-subtitle mb-0">{{mileageRecord.journeyType}}</p>
        </td>
        <td class="responsive-table-column">
          <a class="icons-table-group cursor-pointer" uiSref="Admin.MileageRecordDetail"
             [uiParams]="{ mileageRecordId: mileageRecord.id }">
            <i class="icomoon icons-table-item icomoon-detail icomoon-detail-table"
               title="{{'COMMON_BUTTON_DETAILS' | translate}}"></i>
          </a>
          <a class="icons-table-group cursor-pointer"
             *ngIf="mileageRecord.finishTime.isValid() && rightModel.mileageRecordUpdate.hasRight()"
             (click)="onMileageRecordEditClicked.emit(mileageRecord)">
            <i class="icomoon icons-table-item icomoon-modify icomoon-modify-table"
               title="{{'COMMON_BUTTON_EDIT' | translate}}"></i>
          </a>
        </td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <th class="table-sorter-header">
          <app-table-field-sorter [orderField]="MileageRecordSearch.OrderFunctions.ID"
                                  [orderType]="queryModel.getOrderType(MileageRecordSearch.OrderFunctions.ID)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header hidden-md-down">
          <app-table-field-sorter [orderField]="MileageRecordSearch.OrderFunctions.USER_NAME"
                                  [orderType]="queryModel.getOrderType(MileageRecordSearch.OrderFunctions.USER_NAME)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'TASK_LABEL_OWNER_USER' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header hidden-md-down">
          <app-table-field-sorter [orderField]="MileageRecordSearch.OrderFunctions.VEHICLE_LICENCE_PLATE"
                                  [orderType]="queryModel.getOrderType(MileageRecordSearch.OrderFunctions.VEHICLE_LICENCE_PLATE)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'TRANSPORT_VEHICLE' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header">
          <app-table-sorter-no-op
            [text]="'MILEAGE_RECORD_KILOMETER_COUNT' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header hidden-lg-down">
          <app-table-field-sorter [orderField]="MileageRecordSearch.OrderFunctions.CREATION_TIME"
                                  [orderType]="queryModel.getOrderType(MileageRecordSearch.OrderFunctions.CREATION_TIME)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'MILEAGE_RECORD_CREATION_TIME' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header hidden-lg-down">
          <app-table-field-sorter [orderField]="MileageRecordSearch.OrderFunctions.FINISH_TIME"
                                  [orderType]="queryModel.getOrderType(MileageRecordSearch.OrderFunctions.FINISH_TIME)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'MILEAGE_RECORD_FINISH_TIME' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header hidden-lg-down">
          <app-table-sorter-no-op [text]="'COMMON_COMMENT' | translate"></app-table-sorter-no-op>
          <app-table-sorter-no-op class="table-text-subtitle" [text]="'MILEAGE_RECORD_JOURNEY_TYPE' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header w-table-actions">
          <app-table-sorter-no-op></app-table-sorter-no-op>
        </th>
      </tr>
      </tfoot>
    </table>
    <app-table-paging
      [currentNumberOfItems]="queryModel.currentNumberOfItems"
      [totalNumberOfItems]="queryModel.totalNumberOfItems"
      [itemsPerPage]="queryModel.itemsPerPage"
      (pageChange)="pageChanged($event)"
      (itemsPerPageChange)="itemsPerPageChanged($event)">
    </app-table-paging>
  </div>
</div>
