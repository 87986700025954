import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { DisabledItem, SearchUtils } from '../../../../util/search-utils';
import { OrderFieldFunction, OrderFieldModel, SelectUtils, UiConstants } from '../../../../util/core-utils';
import { BadgeStyle } from '../../../../shared/table-badge/badge-style';
import { InputMask } from '../../../../util/input-masks';
import { OrderField } from '../../../../lib/query/orderfields';
import { OrderType, QueryResult } from '../../../../lib/util/services';
import { Angular2Multiselects } from '../../../../util/multiselect';
import { RightModel } from '../../../../app.rights';
import { BreadcrumbParent } from '../../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { GrantedPermissionSetResolver, RightResolver, RightService } from '../../../../lib/right.service';
import { MatDialog } from '@angular/material/dialog';
import { UIRouter } from '@uirouter/core';
import { FilterField } from '../../../../lib/query/filterfields';
import { CriteriaBuilder, Models } from '../../../../util/model-utils';
import { LedgerNumberSearch, LedgerNumberSearchService } from '../../../../lib/ledger/number/ledger-number-search.service';
import { LedgerNumberListModel } from './ledger-number-list.model';
import { LedgerNumber, LedgerNumberService } from '../../../../lib/ledger/number/ledger-number.service';
import { LedgerNumberRightModel } from '../../../../lib/ledger/number/ledger-number-right.model';
import { LedgerNumberDialogComponent } from '../ledger-number-dialog/ledger-number-dialog.component';
import { DropdownItemType } from '../../../../shared/dropdown/dropdown-item/dropdown-item-type';
import { OperationRights } from '../../../../app.right-definitions';
import { Set } from 'immutable';
import { StateName } from '../../../../app.state-names';

@Component({
  selector: 'app-ledger-number-list',
  templateUrl: './ledger-number-list.component.html',
  styleUrls: ['./ledger-number-list.component.scss']
})
export class LedgerNumberListComponent extends SearchUtils.SearchableList<LedgerNumberSearch.Model> implements OnInit, OnDestroy {

  UiConstants = UiConstants;
  BadgeStyle = BadgeStyle;
  InputMask = InputMask;
  SelectUtils = SelectUtils;
  LedgerNumberSearch = LedgerNumberSearch;
  DropdownItemType = DropdownItemType;

  queryModel: OrderFieldModel<OrderField.LedgerNumber>
    = new OrderFieldModel(LedgerNumberSearch.OrderFunctions.ID, OrderType.DESC);
  searchModel: LedgerNumberSearch.Model = new LedgerNumberSearch.Model();

  dropdownSettings: Angular2Multiselects.Settings;

  ledgerNumberList: LedgerNumberListModel[] = [];
  rightModel: RightModel = RightModel.empty();

  breadcrumbSelf: string;
  breadcrumbParents: BreadcrumbParent[] = [];
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  disabledItems: DisabledItem[] = [];


  constructor(private ledgerNumberService: LedgerNumberService,
              private ledgerNumberSearchService: LedgerNumberSearchService,
              private rightService: RightService,
              private dialog: MatDialog,
              private uiRouter: UIRouter,
              injector: Injector) {
    super(LedgerNumberSearch.Model, injector);
  }

  ngOnInit() {
    this.disabledItems = this.initDisabledOptions();
    this.loadRightModels();
    this.initBreadcrumb();
    this.initSearch();
    this.initDropdownSettings();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  initBreadcrumb() {
    this.translateService.get('MENU_NAVBAR_MENU_LEDGER_NUMBER_LIST').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
  }

  initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .remoteSearch(true)
      .enableCheckAll(false)
      .build();
  }

  loadSearch(completion: () => void) {
    this.ledgerNumberSearchService.getSearchData({})
      .subscribe(
        (result: LedgerNumberSearch.SearchDataResult) => {
          this.queryModel = result.searchData.queryModel;
          this.searchModel = result.searchData.searchModel;
          completion();
        }
      );
  }

  onFirstSearchOpen(): void {
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: OrderFieldFunction<OrderField.LedgerNumber>) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const rights = Set.of(OperationRights.LEDGER_NUMBER_DISABLE,
      OperationRights.LEDGER_NUMBER_UPDATE);
    const order = this.queryModel.createOrderFunction();
    const filter = this.createFilter();
    this.ledgerNumberService.query({
      fields: f => f.forList,
      order: order,
      filter: filter,
      rights: rights,
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined,
    }).subscribe((result: QueryResult<LedgerNumber.LedgerNumber>) => {
      this.ledgerNumberList = [];
      result.items.forEach((ledgerNumber: LedgerNumber.LedgerNumber) => {
        const model = new LedgerNumberListModel();
        model.id = ledgerNumber.id;
        model.rights = new LedgerNumberRightModel(GrantedPermissionSetResolver.byGrantedRights(ledgerNumber.grantedRights));
        model.creationTime = ledgerNumber.creationTime;
        model.updateTime = ledgerNumber.updateTime;
        model.ledgerNumber = ledgerNumber.ledgerNumber;
        model.name = ledgerNumber.name;
        model.disabled = ledgerNumber.disabled;
        this.ledgerNumberList.push(model);
      });
      this.queryModel.currentPage = requestedPage;
      this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
    });
  }

  private createFilter() {
    return (f: FilterField.LedgerNumber) => CriteriaBuilder.builder()
      .addNumber((id) => f.id.eq(id), this.searchModel.id)
      .addString((ledgerNumber) => f.ledgerNumber.containsIgnoreCase(ledgerNumber), this.searchModel.ledgerNumber)
      .addString((name) => f.name.containsIgnoreCase(name), this.searchModel.name)
      .addDateTime((creationTime) => f.creationTime.before(creationTime), Models.parseDateTimeTo(this.searchModel.creationTimeTo))
      .addDateTime((creationTime) => f.creationTime.after(creationTime), Models.parseDateTimeFrom(this.searchModel.creationTimeFrom))
      .addBoolean((disabled) => disabled ? f.disabled.isTrue() : f.disabled.isFalse(), this.parseDisabled(this.searchModel.disabled))
      .build();
  }

  onSearchClicked() {
    this.loadList(1);
  }

  onSearchReset() {
    this.ledgerNumberSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.toggleSearch();
          this.loadList(1);
        });
      }
    );
  }


  ngOnDestroy(): void {
    this.saveSearch();
  }

  private saveSearch() {
    const request = {
        searchData: {
          queryModel: this.queryModel,
          searchModel: this.searchModel
        }
      }
    ;
    this.ledgerNumberSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  openLedgerNumberCreateDialog() {
    const dialogRef = this.dialog.open(LedgerNumberDialogComponent, {
      width: '60%',
      data: {readonly: false}
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.modified) {
        this.loadList();
      }
    });
  }

  openLedgerNumberUpdateDialog(id: number) {
    const dialogRef = this.dialog.open(LedgerNumberDialogComponent, {
      width: '60%',
      data: {readonly: false, ledgerNumberId: id}
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.modified) {
        this.loadList();
      }
    });
  }

  openLedgerNumberDetailDialog(id: number) {
    const dialogRef = this.dialog.open(LedgerNumberDialogComponent, {
      width: '60%',
      data: {readonly: true, ledgerNumberId: id}
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.modified) {
        this.loadList();
      }
    });
  }

  setDisabled(id: number, disabled: boolean) {
    this.ledgerNumberService.disable({id: id, disabled: disabled})
      .subscribe(() => this.loadList());
  }

}
