/* eslint-disable */
import { FormRecordFieldView, FormRecordFieldViewFactory, } from './form-utils';
import { ComponentFactoryResolver, ComponentRef, Injector, } from '@angular/core';
import { FormRecordReadonlyHtmlFieldComponent } from '../../admin/form/form-record/fields/readonly/html/form-record-readonly-html-field.component';
/* eslint-enable */

export class FormRecordReadonlyHtmlFieldViewFactory implements FormRecordFieldViewFactory {

  constructor(
    private injector: Injector,
    private cfr: ComponentFactoryResolver) {
  }

  createView(): ComponentRef<FormRecordFieldView> {
    const cmp = this.cfr.resolveComponentFactory(FormRecordReadonlyHtmlFieldComponent)
      .create(this.injector);
    return cmp;
  }

}
