import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { Set } from 'immutable';
import { MultiselectProvider } from '../multiselect-provider';
import { Task, TaskService } from './task.service';
import { MultiselectOptionItem, UiConstants } from '../../util/core-utils';
import { OrderType, QueryResult } from '../util/services';
import { TaskRecordStateMachine } from './task-record-statemachine';
import { TaskRecord } from './task-record.service';

@Injectable()
export class TaskMultiselectProvider implements MultiselectProvider {

  private multiselectQueryRequest: Task.QueryRequest = {
    paging: {
      pageNumber: 1,
      numberOfItems: UiConstants.autocompletePageSize
    },
    noProgressBar: true,
    orders: Set.of({field: Task.OrderField.NAME, type: OrderType.ASC}),
    disabled: undefined,
    queryText: undefined
  };

  constructor(private taskService: TaskService) {
  }

  load(queryRequest: Task.QueryRequest): Observable<TaskMultiselectOptionItem[]> {
    return Observable.create((observer: Observer<TaskMultiselectOptionItem[]>) => {
      this.taskService.query(queryRequest).subscribe((result: QueryResult<Task.Task>) => {
          observer.next(result.items.toArray().map(item => this.toMultiselectOptionItem(item)));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  loadAll(predicate?: string): Observable<TaskMultiselectOptionItem[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.name = predicate;
    return this.load(queryRequest);
  }

  loadActive(predicate?: string): Observable<TaskMultiselectOptionItem[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.name = predicate;
    queryRequest.disabled = false;
    return this.load(queryRequest);
  }

  loadActiveWithRights(rights: Set<string>, predicate?: string): Observable<TaskMultiselectOptionItem[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.name = predicate;
    queryRequest.disabled = false;
    queryRequest.rights = rights;
    return this.load(queryRequest);
  }

  loadWithPartialRequest(request: Partial<Task.QueryRequest>): Observable<TaskMultiselectOptionItem[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    for (const requestKey in request) {
      if (request.hasOwnProperty(requestKey) && request[requestKey] !== undefined) {
        queryRequest[requestKey] = request[requestKey];
      }
    }
    return this.load(queryRequest);
  }


  getById(id: number): Observable<TaskMultiselectOptionItem> {
    return Observable.create((observer: Observer<TaskMultiselectOptionItem>) => {
      this.taskService.get({
        taskId: id
      }).subscribe((result: Task.Task) => {
          observer.next(this.toMultiselectOptionItem(result));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  getByIds(ids: number[]): Observable<TaskMultiselectOptionItem[]> {
    const queryRequest = Object.assign([], this.multiselectQueryRequest);
    queryRequest.taskIdSet = Set.of(...ids);
    return this.load(queryRequest);
  }

  toMultiselectOptionItem(item: Task.Task): TaskMultiselectOptionItem {
    const ret = new TaskMultiselectOptionItem();
    ret.id = item.taskId;
    ret.itemName = item.name;
    ret.itemSubtitle = item.externalId;
    ret.disabled = item.disabled;
    ret.defaultEstimatedTimeInMinutes = item.defaultEstimatedTimeInMinutes;
    ret.defaultState = item.defaultState;
    ret.defaultStateForceNew = item.defaultStateForceNew;
    ret.placeOfConsumptionRequirement = item.placeOfConsumptionRequirement;
    ret.webCreateDefaultAssigneeType = item.webCreateDefaultAssigneeType;
    ret.autoInvoiceGenerate = item.autoInvoiceGenerate;
    ret.manualInvoiceEnabled = item.manualInvoiceEnabled;
    ret.managedFields = item.managedFields;
    ret.requiredFields = item.requiredFields;
    ret.enabledAssignees = item.enabledAssignees;
    ret.grantedRights = item.grantedRights;
    ret.taskRecordNameTemplate = item.taskRecordNameTemplate;

    return ret;
  }

}

export class TaskMultiselectOptionItem extends MultiselectOptionItem<number> {
  defaultEstimatedTimeInMinutes?: number;
  defaultState: TaskRecordStateMachine.State;
  defaultStateForceNew: boolean;
  placeOfConsumptionRequirement: Task.PlaceOfConsumptionRequirement;
  webCreateDefaultAssigneeType: Task.TaskDefaultAssigneeType;
  autoInvoiceGenerate: boolean;
  manualInvoiceEnabled: boolean;
  managedFields: Set<TaskRecord.TaskRecordField>;
  requiredFields: Set<TaskRecord.TaskRecordField>;
  enabledAssignees: Task.EnabledAssignees;
  grantedRights: Set<string>;
  taskRecordNameTemplate?: string;
}
