/* eslint-disable */
import { List } from 'immutable';
import { Invoice } from '../../lib/invoice/invoice/invoice.service';
import { DqlModel } from '../../lib/dql/dql.model';

/* eslint-enable */

export class FieldPaymentType implements DqlModel.EnumType {

  public static readonly CASH = new FieldPaymentType('INVOICE_PAYMENT_TYPE_CASH', 'CASH');
  public static readonly CREDIT_CARD = new FieldPaymentType('INVOICE_PAYMENT_TYPE_CREDIT_CARD', 'CREDIT_CARD');
  public static readonly TRANSFER = new FieldPaymentType('INVOICE_PAYMENT_TYPE_TRANSFER', 'TRANSFER');
  public static readonly VOUCHER = new FieldPaymentType('INVOICE_PAYMENT_TYPE_VOUCHER', 'VOUCHER');
  public static readonly COD = new FieldPaymentType('INVOICE_PAYMENT_TYPE_COD', 'COD');
  public static readonly OTHER = new FieldPaymentType('INVOICE_PAYMENT_TYPE_OTHER', 'OTHER');

  public static readonly DEFAULT = FieldPaymentType.CASH;

  public static readonly VALUES: List<FieldPaymentType> = List.of(
    FieldPaymentType.CASH,
    FieldPaymentType.CREDIT_CARD,
    FieldPaymentType.TRANSFER,
    FieldPaymentType.VOUCHER,
    FieldPaymentType.COD,
    FieldPaymentType.OTHER,
  );

  public static fromStringValue(value?: Invoice.InvoicePaymentType): FieldPaymentType | undefined {
    if (value) {
      const res = FieldPaymentType.VALUES.toArray().filter((v) => {
        return v.value === value;
      });
      if (res.length < 1) {
        return undefined;
      }
      return res[0];
    }
    return undefined;
  }

  public static toStringValue(item?: FieldPaymentType): string | undefined {
    if (item) {
      return item.value;
    }
    return undefined;
  }

  public get stringKey() {
    return this.textDictionaryKey;
  }

  public get id(): string {
    return this.value;
  }

  private constructor(
    public readonly textDictionaryKey: string,
    public readonly value: Invoice.InvoicePaymentType) {
  }

}
