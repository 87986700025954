import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {FieldError, FieldErrors, ObservableErrorResourceParser} from '../../../../../lib/util/errors';
import {MultiselectOptionItem, UiConstants} from '../../../../../util/core-utils';
import {Angular2Multiselects} from '../../../../../util/multiselect';
import {
  TaskRecordSubTask,
  TaskRecordSubTaskService
} from '../../../../../lib/task/task-record-subtask/task-record-sub-task.service';
import {ResourceQueryResult, Services} from '../../../../../lib/util/services';
import {User, UserService} from '../../../../../lib/user.service';
import {ToasterService} from '../../../../../fork/angular2-toaster/src/toaster.service';
import {ErrorMessageService} from '../../../../../lib/error-message-parser.service';
import {TranslateService} from '@ngx-translate/core';
import {TaskRecord, TaskRecordService} from '../../../../../lib/task/task-record.service';
import {Strings} from '../../../../../lib/util/strings';
import {Set} from 'immutable';

@Component({
  selector: 'app-task-record-sub-task-create-edit-dialog',
  templateUrl: './task-record-sub-task-create-edit-dialog.component.html',
  styleUrls: ['./task-record-sub-task-create-edit-dialog.component.scss']
})
export class TaskRecordSubTaskCreateEditDialogComponent implements OnInit {
  UiConstants = UiConstants;
  TaskRecordSubTask = TaskRecordSubTask;

  headingDictionaryKey: string = '';

  subTaskCreateEditForm: FormGroup;

  subTaskCreateEditFieldErrors: SubTaskCreateEditFieldErrorMap;

  @ViewChild('subTaskCreateEditDialog', {static: true}) subTaskCreateEditDialog: ModalDirective;
  subTaskCreateEditDialogVisible = false;

  @Input()
  taskId: number;

  @Input()
  taskRecordId: number;

  @Output()
  subTaskUpdated: EventEmitter<any> = new EventEmitter<any>();

  subTaskId: number | undefined = undefined;

  model: SubTaskCreateEditModel = new SubTaskCreateEditModel();

  dropdownSettings: Angular2Multiselects.Settings;

  users: MultiselectOptionItem<number>[] = [];

  taskRecordAssigneeUserId?: number;
  taskRecordAssigneeUserGroupId?: number;

  constructor(
    private formBuilder: FormBuilder,
    private taskRecordSubTaskService: TaskRecordSubTaskService,
    private userService: UserService,
    private toasterService: ToasterService,
    private errorMessageService: ErrorMessageService,
    private translateService: TranslateService,
    private taskRecordService: TaskRecordService) {
  }

  private createFormGroup() {
    this.subTaskCreateEditForm = this.formBuilder.group({
      name: ['', Validators.required],
      type: [''],
      note: [''],
      workerUser: [[]],
    });
    this.subTaskCreateEditFieldErrors = {};
  }

  private loadSubTask() {
    this.taskRecordSubTaskService.get({
      taskId: this.taskId,
      taskRecordId: this.taskRecordId,
      id: this.subTaskId!
    }).subscribe((subTask: TaskRecordSubTask.SubTask) => {
      this.model = new SubTaskCreateEditModel();
      this.model.name = subTask.name;
      this.model.type = subTask.type ? subTask.type : '';
      this.model.note = subTask.note ? subTask.note : '';
      this.model.version = subTask.version;
      this.model.state = subTask.state;
      const workerUserId = subTask.workerUser ? subTask.workerUser.id : undefined;
      this.loadTaskRecord(() => {
        this.loadUsers(workerUserId, undefined);
      });
    });
  }

  private loadTaskRecord(completion: () => void) {
    this.taskRecordService.get({
      taskId: this.taskId,
      taskRecordId: this.taskRecordId
    }).subscribe((taskRecord: TaskRecord.TaskRecord) => {
      this.taskRecordAssigneeUserId = taskRecord.assignee ? taskRecord.assignee.userId : undefined;
      this.taskRecordAssigneeUserGroupId = taskRecord.userGroupId;
      completion();
    });
  }

  loadUsers(workerUserId?: number, searchValue?: string) {
    this.userService.query({
      q: Strings.undefinedOrNonEmpty(searchValue),
      fields: 'id,user_name,person_name,disabled',
      disabled: false,
      user_group_ids: this.taskRecordAssigneeUserGroupId ? this.taskRecordAssigneeUserGroupId.toString() : undefined,
      number_of_items: UiConstants.autocompletePageSize,
      page_number: 1,
      order: Services.createOrderFieldParameter(User.Keys.toOrderFieldKey, Set.of(User.DEFAULT_ORDER)),
      no_progress_bar: true,
    }).subscribe((users: ResourceQueryResult<User>) => {
      this.users = [];
      if (searchValue === undefined) {
        this.model.workerUser = [];
      }
      users.items.forEach(user => {
        const item = {
          id: user.id,
          itemName: user.person_name + ' (' + user.user_name + ')',
          disabled: user.disabled
        };
        this.users.push(item);
        if (item.id === workerUserId) {
          this.model.workerUser.push(item);
        }
      });
      if (searchValue === undefined && workerUserId && this.model.workerUser.length < 1) {
        this.loadSelectedUser(workerUserId);
      }
    });
  }

  loadSelectedUser(workerUserId: number) {
    this.userService.get({
      id: workerUserId,
    }).subscribe(
      (user: User) => {
        this.model.workerUser = [];
        const item = {
          id: user.id,
          itemName: user.person_name + ' (' + user.user_name + ')',
          disabled: user.disabled
        };
        this.model.workerUser.push(item);
      });
  }

  saveSubTask() {
    if (!this.subTaskCreateEditForm.valid) {
      this.subTaskCreateEditForm.get('name')!.markAsTouched();
      return;
    }
    if (this.subTaskId) {
      this.updateSubTask();
    } else {
      this.createSubTask();
    }
  }

  createSubTask() {
    const workerUserId = this.model.workerUser && this.model.workerUser.length > 0 && this.model.workerUser[0].id !== null ?
      this.model.workerUser[0].id : undefined;
    this.taskRecordSubTaskService.create({
      taskId: this.taskId,
      taskRecordId: this.taskRecordId,
      name: this.model.name,
      type: this.model.type,
      note: this.model.note,
      workerUserId: workerUserId,
    }).subscribe((result) => {
      this.subTaskUpdated.emit();
      this.closeSubTaskCreateEditDialog();
    }, ((error: any) => {
      const res = ObservableErrorResourceParser.parseError(error);
      this.subTaskCreateEditFieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
    }));
  }

  updateSubTask() {
    const workerUserId = this.model.workerUser && this.model.workerUser.length > 0 && this.model.workerUser[0].id !== null ?
      this.model.workerUser[0].id : undefined;
    this.taskRecordSubTaskService.update({
      id: this.subTaskId!,
      taskId: this.taskId,
      taskRecordId: this.taskRecordId,
      name: this.model.name,
      type: this.model.type,
      note: this.model.note,
      workerUserId: workerUserId,
      version: this.model.version
    }).subscribe((result) => {
      this.subTaskUpdated.emit();
      this.closeSubTaskCreateEditDialog();
    }, ((error: any) => {
      const res = ObservableErrorResourceParser.parseError(error);
      this.subTaskCreateEditFieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
    }));
  }

  showSubTaskCreateEditDialog(subTaskId?: number) {
    this.subTaskId = subTaskId;
    this.subTaskCreateEditDialogVisible = true;
    this.createFormGroup();
    this.headingDictionaryKey = 'TASK_RECORD_SUB_TASK_CREATE';
    if (this.subTaskId) {
      this.loadSubTask();
      this.headingDictionaryKey = 'TASK_RECORD_SUB_TASK_EDIT';
    } else {
      this.loadTaskRecord(() => {
        this.loadUsers();
      })
    }
    this.subTaskCreateEditDialog.show();
  }

  closeSubTaskCreateEditDialog() {
    this.subTaskCreateEditDialogVisible = false;
    this.subTaskCreateEditDialog.hide();
  }

  onModalHidden() {
    this.subTaskCreateEditForm.reset();
  }

  removeFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.subTaskCreateEditFieldErrors, fieldError);
  }

  private initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .build();
  }

  ngOnInit() {
    this.initDropdownSettings();
  }

}

class SubTaskCreateEditModel {
  state: TaskRecordSubTask.SubTaskState;
  name: string = '';
  type: string = '';
  note: string = '';
  version: number;
  workerUser: MultiselectOptionItem<number>[] = [];
}

interface SubTaskCreateEditFieldErrorMap {
  name?: FieldError;
  worker_user_id?: FieldError;
}
