<div>
  <table class="table table-sm order-stock-item-table">
    <thead>
    <tr>
      <th class="responsive-table-column w-5 text-center align-middle">{{'COMMON_TABLE_HEADER_ID' | translate}}</th>
      <th class="responsive-table-column w-40">{{'COMMON_NAME' | translate}}</th>
      <th class="responsive-table-column w-15 hidden-sm-down">{{'ORDER_LABEL_EXTERNAL_ID' | translate}}</th>
      <th class="responsive-table-column w-10 hidden-sm-down">{{'ORDER_STOCK_ITEM_WEIGHT' | translate}}</th>
      <th class="responsive-table-column w-15 hidden-sm-down">{{'ORDER_STOCK_ITEM_NET_SUM_PRICE' | translate}}</th>
      <th class="responsive-table-column w-10">{{'ORDER_STOCK_ITEM_AMOUNT' | translate}}</th>
      <th class="responsive-table-column w-5"></th>
    </tr>
    </thead>
    <tbody *ngFor="let item of stockItems; index as i;">
    <tr [class.error_tr] = " hasItemError(item.externalId) ">
      <td class="responsive-table-column w-5 text-center align-middle">{{item.itemId}}</td>
      <td class="responsive-table-column w-40">{{item.name}}</td>
      <td class="responsive-table-column w-15 hidden-sm-down">{{item.externalId ? item.externalId : ''}}</td>
      <td class="responsive-table-column w-10 hidden-sm-down">{{item.weightInGrams ? WeightFactory.createWeightFromGram(item.weightInGrams).toString() : ''}}</td>
      <td class="responsive-table-column w-15 hidden-sm-down">{{item.unitPrice.value | number:'':'hu-HU'}} {{item.unitPrice.currency_code}}</td>
      <td class="responsive-table-column w-10">{{item.amount}} {{item.unit}}</td>
      <td class="responsive-table-column w-5 align-middle" (click)="toggleEdit(i)"><i
        class="icomoon icomoon-detail cursor-pointer"></i></td>
    </tr>
    <tr *ngIf="item.editing">
      <td colspan="9" class="stock-item-edit">
        <app-order-stock-item-edit
          [orderCompanyId]="_orderCompanyId"
          [model]="item"
          [itemError]="getItemError(item.externalId)"
          (remove)="removeItem(i, $event)"
          (cancel)="closeRow(i)"
          (save)="saveItem(i, $event)"></app-order-stock-item-edit>
      </td>
    </tr>
    </tbody>
    <tbody *ngIf="!addModel && (!stockItems || stockItems.length === 0)">
    <tr>
      <td colspan="9">
        <div class="text-center text-muted p-2">
          <label class="m-0">{{'ORDER_STOCK_ITEM_NO_ITEM' | translate}}</label>
        </div>
      </td>
    </tr>
    </tbody>
    <tbody *ngIf="addModel">
    <tr>
      <td colspan="9" class="p-0">
        <app-order-stock-item-edit
          [orderCompanyId]="_orderCompanyId"
          [model]="addModel"
          [isRemovable]="false"
          (cancel)="cancelNewItem()"
          (save)="saveNewItem($event)"></app-order-stock-item-edit>
      </td>
    </tr>
    </tbody>
    <tr *ngIf="!addModel">
      <td colspan="9">
        <input class="btn btn-add-record cursor-pointer" type="button"
               value="{{'ORDER_STOCK_ITEM_ADD_BUTTON' | translate}}" (click)="newItem()">
      </td>
    </tr>
  </table>

  <div>
    <h5>{{'ORDER_STOCK_ITEM_SUM' | translate}}</h5>
    <hr>
    <table class="d-flex justify-content-end mb-1">
      <tr *ngIf="data.weight !== undefined">
        <td class="summary_td text-right text-muted">{{'ORDER_STOCK_ITEM_SUM_WEIGHT' | translate}}</td>
        <td class="summary_td">{{WeightFactory.createWeightFromGram(data.weight).toString()}}</td>
      </tr>
      <tr>
        <td class="summary_td text-right text-muted"><h6>{{'ORDER_STOCK_ITEM_SUM_PRICE' | translate}}</h6></td>
        <td class="summary_td"><h6>{{data.price | number:'':'hu-HU'}} {{data.currencyCode}}</h6></td>
      </tr>
    </table>
  </div>

</div>
