import {AfterViewInit, Component, OnInit} from '@angular/core';
import {RightModel} from "../../../app.rights";
import {RightResolver, RightService} from "../../../lib/right.service";
import {BreadcrumbParent} from "../../../shared/breadcrumb/breadcrumb/breadcrumb.component";
import {TranslateService} from "@ngx-translate/core";
import {TabAnchors} from "../../../shared/tab/tab-anchors";
import {Transition} from "@uirouter/angular";
import {TabSelectService} from "../../../lib/util/tab-select.service";

@Component({
  selector: 'app-message-lists',
  templateUrl: './message-lists.component.html',
  styleUrls: ['./message-lists.component.scss']
})
export class MessageListsComponent extends TabAnchors implements OnInit, AfterViewInit {

  rightModel: RightModel = RightModel.empty();

  breadcrumbSelf: string;
  breadcrumbParents: BreadcrumbParent[] = [];
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  constructor(private rightService: RightService,
              private transition: Transition,
              private tabSelectService: TabSelectService,
              private translateService: TranslateService) {
    super(transition, tabSelectService);
    this.initBreadcrumb();
  }

  ngOnInit(): void {
  }


  ngAfterViewInit(): void {
    this.loadRightModels(()=> {
      this.resolveAnchor();
    });
  }

  initBreadcrumb() {
    this.translateService.get('MENU_NAVBAR_MESSAGES').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
  }

  private loadRightModels(completion: ()=> void) {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        completion();
      }
    );
  }
}
