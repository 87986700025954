<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div class="breadcrumb-menu">
    <!--CREATE-->
    <div *ngIf="rightModel.invoiceBookCreate.hasRight()"
         class="breadcrumb-button-icon-container cursor-pointer negative-margin-right"
         uiSref="Admin.InvoiceBookCreate">
      <a class="btn-setting d-flex align-items-center" title="{{'COMMON_BUTTON_ADD' | translate}}">
        <i class="icomoon icomoon-add"></i>
        <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_ADD' | translate}}</span>
      </a>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-vertical-padding container-horizontal-padding animated fadeIn">
  <div class="card">
    <div class="card-header">
      {{'INVOICE_BOOK_HEADING_LIST' | translate}}
      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()"
           title="{{'COMMON_SHOW_SEARCH_HINT' | translate}}">
          <i #searchIcon class="icomoon icomoon-search"></i>
        </a>
      </div>
    </div>

    <div class="card-body border-bottom" *ngIf="showSearch">
      <div class="row form-group">
        <div class="col-md-3">
          <label class="search-label">{{'INVOICE_CREATE_NUMBERING_TEMPLATE' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'INVOICE_CREATE_NUMBERING_TEMPLATE' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.prefix" name="prefix" id="prefix" #prefix="ngModel">
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'COMMON_COMMENT' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'COMMON_COMMENT' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.comment" name="comment" id="comment" #comment="ngModel">
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'COMMON_EXTERNAL_ID' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'COMMON_EXTERNAL_ID' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.externalId" name="externalId" id="externalId" #externalId="ngModel">
        </div>
        <div class="col-md-3">
          <label class="search-label"> {{ 'INVOCIE_BOOK_SEARCH_FIELD_DISABLED' | translate }} </label>
          <select
            class="form-control"
            [(ngModel)]="searchModel.disabled"
            [compareWith]="SelectUtils.compareObjects"
            id="disabled"
            name="disabled"
            #disabled="ngModel">
            <option *ngFor="let item of disabledItems" [ngValue]="item">{{item.text}}</option>
          </select>
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'INVOICE_CREATE_INVOICE_SETTINGS'|translate}}</label>
          <angular2-multiselect
            [(ngModel)]="searchModel.invoiceSettings"
            [ngModelOptions]="{standalone: true}"
            [data]="invoiceSettings"
            (onRemoteSearch)="loadInvoiceSettings($event.target.value)"
            [settings]="dropdownSettings">
          </angular2-multiselect>
        </div>
      </div>
      <!-- /.row form-group -->
      <div class="col-md-12 d-flex justify-content-md-end align-items-end pr-1 pl-0 mb-1">
        <div class="btn-group" role="group">
          <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
            {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
          </button>
          <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
            {{'COMMON_BUTTON_SEARCH' | translate}}
          </button>
        </div>
      </div>
    </div><!-- /.card-body-->

    <div class="card-body">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header long">
            <app-table-field-sorter [orderField]="InvoiceBook.OrderField.PREFIX"
                                    [orderType]="queryModel.getOrderType(InvoiceBook.OrderField.PREFIX)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'INVOICE_BOOK_LAST_ISSUED_INVOICE_NUMBER' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-sorter-no-op
              [text]="'TASK_RECORD_MANUAL_INVOICE_CREATE_INVOICE_SETTINGS' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-sorter-no-op
              [text]="'INVOICE_BOOK_LAST_ISSUED_INVOICE_DATE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-field-sorter [orderField]="InvoiceBook.OrderField.COMMENT"
                                    [orderType]="queryModel.getOrderType(InvoiceBook.OrderField.COMMENT)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_COMMENT' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let invoiceBook of invoiceBookList | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }" [disabledTableRow]="invoiceBook.disabled">
          <td class="responsive-table-column">
            <a class="id-text cursor-pointer"
               href="#" onclick="return false"
               uiSref="Admin.InvoiceBookEdit"
               [uiParams]="{ id: invoiceBook.id }"
               title="{{'COMMON_BUTTON_EDIT' | translate}}">
              {{invoiceBook.lastInvoiceString}}
            </a>
            <br/><span class="table-text-subtitle">{{invoiceBook.externalId}}</span></td>
          <td class="fit hidden-xs-down align-middle">{{invoiceBook.invoiceSettings.name}}</td>
          <td class="fit hidden-xs-down align-middle">{{invoiceBook.lastInvoiceDate.toIsoString() | date:'shortDate' }}</td>
          <td class="responsive-table-column hidden-sm-down align-middle">{{invoiceBook.comment}}</td>
          <td class="align-middle">
            <app-table-options-menu>
              <app-dropdown-item
                *ngIf="rightModel.invoiceBookUpdate.hasRight()"
                [iconClass]="'icomoon-modify'"
                [titleStringKey]="'COMMON_BUTTON_EDIT'"
                class="cursor-pointer"
                uiSref="Admin.InvoiceBookEdit"
                [uiParams]="{ id: invoiceBook.id }">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="invoiceBook.disabled && rightModel.invoiceBookDisable.hasRight()"
                [iconClass]="'icomoon-enable'"
                [titleStringKey]="'COMMON_BUTTON_ENABLE'"
                [itemType]="DropdownItemType.ENABLE"
                class="cursor-pointer"
                (click)="setDisabled($event, invoiceBook, false)">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="!invoiceBook.disabled && rightModel.invoiceBookDisable.hasRight()"
                [iconClass]="'icomoon-disable'"
                [titleStringKey]="'COMMON_BUTTON_DISABLE'"
                [itemType]="DropdownItemType.DISABLE"
                class="cursor-pointer"
                (click)="setDisabled($event, invoiceBook, true)">
              </app-dropdown-item>
            </app-table-options-menu>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header long">
            <app-table-field-sorter [orderField]="InvoiceBook.OrderField.PREFIX"
                                    [orderType]="queryModel.getOrderType(InvoiceBook.OrderField.PREFIX)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'INVOICE_BOOK_LAST_ISSUED_INVOICE_NUMBER' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-sorter-no-op
              [text]="'TASK_RECORD_MANUAL_INVOICE_CREATE_INVOICE_SETTINGS' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-sorter-no-op
              [text]="'INVOICE_BOOK_LAST_ISSUED_INVOICE_DATE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-field-sorter [orderField]="InvoiceBook.OrderField.COMMENT"
                                    [orderType]="queryModel.getOrderType(InvoiceBook.OrderField.COMMENT)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_COMMENT' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>
  </div>
</div>
