import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { Observable } from 'rxjs';
import { ResourceQueryResult } from '../util/services';
import { EmptyMessage, IdentityMessage } from '../util/messages';

/**
 * not injectable
 */
export class DqlStoredQueryResourceService extends BaseHttpService {

  protected _dqlPath = `/dql/queries`;

  protected getDqlPath() {
    return this._dqlPath;
  }

  fetchDqlQueryList(request: DqlStoredQueryResource.DqlStoredQueryListRequest):
    Observable<ResourceQueryResult<DqlStoredQueryResource.DqlStoredQuery>> {
    return this.http
      .get<ResourceQueryResult<DqlStoredQueryResource.DqlStoredQuery>>(this.url + `${this.getDqlPath()}`, this.parseParams(request));
  }

  createDqlQuery(request: DqlStoredQueryResource.DqlStoredQueryCreateRequest): Observable<IdentityMessage> {
    return this.http.post<IdentityMessage>(this.url + `${this.getDqlPath()}`, request);
  }

  updateDqlQuery(id: number, request: DqlStoredQueryResource.DqlStoredQueryCreateRequest): Observable<EmptyMessage> {
    return this.http.put<EmptyMessage>(this.url + `${this.getDqlPath()}/${id}`, request);
  }

  deleteDqlQuery(id: number): Observable<EmptyMessage> {
    return this.http.delete<EmptyMessage>(this.url + `${this.getDqlPath()}/${id}`);
  }

  constructor(protected http: HttpClient,
              private resourceHelper: ResourceHelper,
              private baseUrl: string) {
    super(resourceHelper, baseUrl);
  }

}

export namespace DqlStoredQueryResource {

  export interface DqlStoredQueryListRequest {
    id?: string;
    parent_id?: number;
    document_type?: string;
    name?: string;
    creator_user_id?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
    no_progress_bar?: boolean;
  }

  export interface DqlStoredQuery {
    id: number;
    name: string;
    query: string;
    creator_user_id: number;
    parent_id?: number;
    parent_name?: string;
    user_group_ids: number[];
  }

  export interface DqlStoredQueryCreateRequest {
    name: string;
    query: string;
    parent_id?: number;
    document_type?: string;
    user_group_ids: number[];
  }

}
