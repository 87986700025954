import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FileItem, FileUploader, ParsedResponseHeaders } from 'ng2-file-upload';
import { Subject } from 'rxjs';
import { UiConstants } from '../../../../util/core-utils';
import { FileUploaderUtil } from '../../../../util/file-uploader-util';
import { Workflow, WorkflowService } from '../../../../lib/workflow/workflow.service';
import { ErrorMessageService } from '../../../../lib/error-message-parser.service';

@Component({
  selector: 'app-workflow-import-dialog',
  templateUrl: './workflow-import-dialog.component.html',
  styleUrls: ['./workflow-import-dialog.component.scss']
})
export class WorkflowImportDialogComponent implements OnInit {
  UiConstants = UiConstants;

  @ViewChild('importDialog', { static: true }) importDialog: ModalDirective;
  importDialogVisible: boolean = false;
  importDnDVisible: boolean = true;
  model: WorkflowImportModel = new WorkflowImportModel();
  uploadGlobalErrors: string;

  @Output()
  onWorkflowImported: EventEmitter<number> = new EventEmitter();

  constructor(private uploadUtil: FileUploaderUtil,
              private workflowService: WorkflowService,
              private errorMessageService: ErrorMessageService) {
  }

  ngOnInit() {
  }

  openImportDialog() {
    this.importDialogVisible = true;
    this.importDnDVisible = true;
    this.importDialog.show();
    this.model = new WorkflowImportModel();
  }

  closeImportDialog() {
    this.importDialogVisible = false;
    this.importDialog.hide();
  }

  onImportableWorkflowSelected(files: File[]) {
    this.model.importResult = undefined;
    this.model.existingWorkflows = undefined;
    const file = files[0];
    this.model.fileName = file.name;
    const fr = new FileReader();
    const subject: Subject<any> = new Subject<any>();
    fr.onloadend = (function (f) {
      return function (e) {
        try {
          const res = JSON.parse(this.result);
          subject.next(res);
        }
        catch (e) {
          subject.error('');
        }
      };
    })(file);
    fr.readAsText(file);
    subject.asObservable().subscribe((workflow: any) => {
      if (workflow) {
        this.model.parsedWorkflow = workflow;
        this.model.importResult = undefined;
        this.model.existingWorkflows = undefined;
        this.importDnDVisible = false;
        if (workflow['external_id']) {
          this.workflowService.query({
            filter: field => field.externalId.eq(workflow['external_id']),
            latestVersionsOnly: true
          }).subscribe(result => {
            if (result.items.size > 0) {
              this.model.importResult = 'EXISTING_WORKFLOW_WARNING';
              this.model.existingWorkflows = result.items.toArray();
            }
          });
        }
      }
      else {
        this.model.importResult = 'FORMAT_ERROR';
        this.importDnDVisible = true;
      }
    }, error => {
      this.model.importResult = 'FORMAT_ERROR';
      this.importDnDVisible = true;
    });
  }

  importWorkflow() {
    this.workflowService.import(this.model.parsedWorkflow).subscribe((result) => {
      this.onWorkflowImported.emit(result.id);
      this.closeImportDialog();
    }, (error: any) => {
      this.model.importResult = 'ERROR';
      this.importDnDVisible = true;
      const localizedError = this.errorMessageService.parseError(error);
      this.uploadGlobalErrors = localizedError!.globalErrors.join(',');
    });
  }

}

export class WorkflowImportModel {
  fileName: string = '';
  importResult?: string = undefined;
  parsedWorkflow: string = '';
  existingWorkflows?: Workflow.Workflow[];

  get existingWorkflowString(): string {
    if (!this.existingWorkflows || this.existingWorkflows.length === 0) {
      return '';
    }
    return this.existingWorkflows.map(w => `${w.name}(${w.externalId})`).join(', ');
  }

  get canImport() {
    return this.importResult === undefined || this.importResult === 'EXISTING_WORKFLOW_WARNING';
  }
}
