/* eslint-disable */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Invoice, InvoiceService } from '../../../../lib/invoice/invoice/invoice.service';
import { TranslateService } from '@ngx-translate/core';
import { StockItemService } from '../../../../lib/stock/stock-item.service';
import { CustomerRecord, CustomerRecordService } from '../../../../lib/customer/customer-record.service';
import { InvoiceSettings } from '../../../../lib/invoice/invoice-settings/invoice-settings.service';
import { NgForm } from '@angular/forms';
import { FieldValidationError, QueryResult } from '../../../../lib/util/services';
import { CustomerMultiItem, InvoiceCreateCloneModel } from './invoice-create-clone.model';
import { InputMask } from '../../../../util/input-masks';
import { StringKey } from '../../../../app.string-keys';
import { Angular2Multiselects } from '../../../../util/multiselect';
import { OptionItem, UiConstants } from '../../../../util/core-utils';
import { Transition, UIRouter } from '@uirouter/angular';
import { Models } from '../../../../util/model-utils';
import { ConfigurationService } from '../../../../lib/core-ext/configuration.service';
import { Address } from '../../../../lib/address';
import { InvoiceRecordContainerComponent } from '../invoice-record-container/invoice-record-container.component';
import { ToasterService } from '../../../../fork/angular2-toaster/angular2-toaster';
import { BreadcrumbParent } from '../../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { StateName } from '../../../../app.state-names';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { ConfirmLeaveModalComponent } from '../../../../shared/confirm-leave-modal/confirm-leave-modal.component';
import { VatRate, VatRateService } from '../../../../lib/vat-rate.service';
import { InvoiceTagMultiselectProvider } from '../../../../lib/invoice/tag/invoice-tag-multiselect-provider.service';
import { RightResolver, RightService } from '../../../../lib/right.service';
import { RightModel } from '../../../../app.rights';
import {
  CustomerRecordQuickCreateMaterialDialogComponent
} from '../../../customer/customer-record-quick-create-material/customer-record-quick-create-material-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import {
  CustomerRecordBillingInfoBaseDialogComponent
} from '../../../customer/customer-record-billing-info/customer-record-billing-info-base-dialog/customer-record-billing-info-base-dialog.component';
import {
  CustomerRecordBillingInfoComponentMode
} from '../../../customer/customer-record-billing-info/customer-record-billing-info-base/customer-record-billing-info-base.component';
import { NgbDatePickerParserFormatter } from '../../../../util/ngb-datepicker';
import { InvoiceSettingsMultiselectProvider } from '../../../../lib/invoice/invoice-settings/invoice-settings-multiselect-provider.service';
import { InvoiceBookMultiselectProvider } from '../../../../lib/invoice/invoice-book/invoice-book-multiselect-provider.service';
import PostalAddressData = Address.PostalAddressData;
import ZeroVatRateType = VatRate.ZeroVatRateType;
import InvoiceVatStatus = CustomerRecord.InvoiceVatStatus;
import InvoiceDirectionType = Invoice.InvoiceDirectionType;

/* eslint-enable */

@Component({
  selector: 'app-invoice-create',
  templateUrl: './invoice-create-clone.component.html',
  styleUrls: ['./invoice-create-clone.component.scss']
})
export class InvoiceCreateCloneComponent implements OnInit, OnDestroy {

  InputMask = InputMask;
  Invoice = Invoice;
  InvoiceSettings = InvoiceSettings;
  InvoiceVatStatus = InvoiceVatStatus;

  @ViewChild('f', {static: true})
  fForm: NgForm;

  model: InvoiceCreateCloneModel;
  fieldErrors: FieldValidationError<Invoice.ValidatedField>;

  @ViewChild(InvoiceRecordContainerComponent, {static: true})
  recordContainer: InvoiceRecordContainerComponent;

  searchableDropdownSettings: Angular2Multiselects.Settings;
  simpleDropdownSettings: Angular2Multiselects.Settings;

  private postalAddressFormat: string;

  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  deregisterWarningModal;
  saveButtonClicked: boolean = false;

  selectableVatRates: VatRate.VatRate[] = [];
  selectableZeroVatRateTypes: OptionItem<VatRate.ZeroVatRateType>[] = [];

  rightModel: RightModel = RightModel.empty();

  originalInvoiceId?: number;

  constructor(private invoiceService: InvoiceService,
              private translateService: TranslateService,
              private stockItemService: StockItemService,
              private modalService: BsModalService,
              private toasterService: ToasterService,
              private customerRecordService: CustomerRecordService,
              private invoiceSettingsService: InvoiceSettingsMultiselectProvider,
              private invoiceBookService: InvoiceBookMultiselectProvider,
              private invoiceTagMultiselectProvider: InvoiceTagMultiselectProvider,
              private datePickerParserFormatter: NgbDatePickerParserFormatter,
              private router: UIRouter,
              private transition: Transition,
              private configService: ConfigurationService,
              private rightService: RightService,
              private dialog: MatDialog,
              private vatRateService: VatRateService) {
    this.model = new InvoiceCreateCloneModel(this.customerRecordService, this.configService, this.invoiceSettingsService,
      this.invoiceBookService, this.invoiceTagMultiselectProvider, this.datePickerParserFormatter, this.translateService,
      <InvoiceDirectionType>this.transition.params().type);
    this.fieldErrors = FieldValidationError.empty<Invoice.ValidatedField>();
    this.postalAddressFormat = this.configService.getPostalAddressFormat();
    this.initDropDown();
    this.originalInvoiceId = this.transition.params().id;
  }

  private loadRightModel() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }


  ngOnInit() {
    this.translateService.get('INVOICE_CREATE_HEADING').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_INVOICES').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.INVOICE_LIST});
      }
    );
    const context = this;
    this.deregisterWarningModal = this.router.transitionService.onStart({}, function (transition) {
      if (context.saveButtonClicked
        || transition.targetState().name() === StateName.CONNECTION_ERROR
        || transition.targetState().name() === StateName.SERVER_ERROR
        || transition.targetState().name() === StateName.LOGIN) {
        return true;
      }
      else {
        const subject = new Subject<boolean>();
        const modal = context.modalService.show(ConfirmLeaveModalComponent, {backdrop: 'static', keyboard: false});
        modal.content!.subject = subject;
        return subject.asObservable().toPromise();
      }
    });
    this.loadVatRates();
    this.loadZeroVatRateTypes();
    this.loadRightModel();
    this.loadInvoice();
  }

  loadInvoice() {
    if (!this.originalInvoiceId) {
      return;
    }

    this.invoiceService.get({id: this.originalInvoiceId}).subscribe(i => {
      this.model.load(i);
    });
  }

  hasFieldError(field: Invoice.ValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: Invoice.ValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: Invoice.ValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  back() {
    this.router.stateService.go(StateName.INVOICE_LIST);
  }

  private initDropDown() {
    this.searchableDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
    this.simpleDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(false)
      .enableCheckAll(false)
      .translate(true)
      .build();
  }

  deadlineAddDays(days: number) {
    const deliveryDate = Models.ngbDateToLocalDate(this.model._deliveryDate);
    if (deliveryDate) {
      const deadline = Models.localDateToNgbDate(deliveryDate.plusDays(days));
      this.model._deadline = deadline ? deadline : null;
    }
  }

  formatPostalAddress(postalAddress?: PostalAddressData): string {
    return postalAddress ? Address.PostalAddressMapper.toString(postalAddress, this.postalAddressFormat) : '';
  }

  openCustomerRecordQuickCreateDialog() {
    CustomerRecordQuickCreateMaterialDialogComponent.openDialog(this.dialog,
      {}, (result) => {
        if (result?.customerRecord) {
          this.model._customerRecord = [];
          const item = InvoiceCreateCloneModel.mapCustomerRecord(result.customerRecord, this.postalAddressFormat);
          this.model._customerRecord.push(item);
          this.onCustomerChange(item);
        }
      });
  }

  openBillingInfoCreateDialog() {
    CustomerRecordBillingInfoBaseDialogComponent.openDialog(this.dialog,
      {
        customerId: this.model.customerRecord ? this.model.customerRecord.customerId! : 0,
        customerRecordId: this.model.customerRecord ? this.model.customerRecord.id! : 0,
        mode: CustomerRecordBillingInfoComponentMode.CREATE
      }, result => {
        if (result) {
          this.model._billingInfo = [];
          const item = {
            id: result.id,
            itemName: result.name,
            itemSubtitle: this.model.createBillingInfoSubtitle(result),
            data: result
          };
          this.model._billingInfo.push(item);
          this.model.billingInfos.push(item);
        }
      });
  }


  onCustomerChange(customer?: CustomerMultiItem) {
    this.toasterService.clear();
    this.model._billingInfo = [];
    if (customer) {
      this.model.loadBillingInfos(customer);
    }
    else {
      this.model.billingInfos = [];
      this.model._billingInfo = [];
    }
  }

  get invoiceAddress(): Address.PostalAddressData | undefined {
    return this.model.billingInfo
      ? this.model.billingInfo.data.invoiceAddress
      : undefined;
  }

  get taxNumber(): string | undefined {
    return this.model.billingInfo
      ? this.model.billingInfo.data.invoiceVatStatus === InvoiceVatStatus.DOMESTIC
        ? this.model.billingInfo.data.taxNumber
        : this.model.billingInfo.data.invoiceVatStatus === InvoiceVatStatus.PRIVATE_PERSON
          ? ''
          : undefined
      : '';
  }

  create() {
    if (!this.recordContainer.zeroPricedRecordsAllowed && this.recordContainer.hasZeroPrice()) {
      return;
    }
    this.invoiceService.create({
      invoiceNumber: this.model._directionType === 'INBOUND' ? this.model._invoiceNumber : undefined,
      invoiceSettingsId: this.model.invoiceSettings!.id,
      invoiceBookId: this.model.invoiceBook ? this.model.invoiceBook.id : undefined,
      invoiceTagId: this.model.invoiceTag ? this.model.invoiceTag.id : undefined,
      appearanceType: this.model._appearanceType,
      commentTemplate: this.model._commentTemplate,
      currencyCode: this.model._currencyCode,
      paymentType: this.model.paymentType!.id,
      issueDate: Models.ngbDateToLocalDate(this.model._issueDate),
      deliveryDate: Models.ngbDateToLocalDate(this.model._deliveryDate),
      deadline: Models.ngbDateToLocalDate(this.model._deadline),
      customerRecordId: this.model.customerRecord!.id,
      billingInfoId: this.model.billingInfoId,
      directionType: this.model._directionType,
      records: this.model.getRecordCreateRequests()
    }).subscribe((result) => {
        this.saveButtonClicked = true;
        this.back();
      },
      (error: any) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withForm(this.fForm);
          if (this.fieldErrors.hasError(Invoice.ValidatedField.UNKNOWN)) {
            this.translateService.get(StringKey.COMMON_VALIDATION_ERROR).subscribe(text => {
              this.toasterService.pop({
                timeout: UiConstants.ToastTimeoutLong,
                type: UiConstants.toastTypeError,
                title: text,
                body: this.fieldErrors.getErrorText(Invoice.ValidatedField.UNKNOWN)
              });
            });
          }
        }
      }
    );
  }

  loadVatRates() {
    this.vatRateService.query().subscribe((result: QueryResult<VatRate.VatRate>) => {
      this.selectableVatRates = result.items.toArray();
    });
  }

  loadZeroVatRateTypes() {
    this.selectableZeroVatRateTypes = [];
    VatRate.zeroVatRateTypes.forEach(type => {
      const item = new OptionItem<ZeroVatRateType>();
      item.id = type.type;
      item.disabled = type.disabled;
      this.translateService.get(type.stringKey).subscribe((text) => {
        item.text = text;
      });
      this.selectableZeroVatRateTypes.push(item);
    });
  }

  onInvoiceSettingsChanged() {
    if (this.model.invoiceSettings) {
      this.model._appearanceType = this.model.invoiceSettings.defaultAppearanceType;
      if (this.model.invoiceSettings.integrationType === InvoiceSettings.InvoiceIntegrationType.APPWORKS) {
        this.model.onInvoiceBookSearch();
      }
      this.model._paymentType = this.model.paymentTypeList.filter(t => t.id === this.model.invoiceSettings!.defaultPaymentType);
      if (this.model.invoiceSettings.commentTemplate) {
        this.model._commentTemplate = this.model.invoiceSettings.commentTemplate;
      }
    }
    this.removeFieldError(Invoice.ValidatedField.INVOICE_SETTINGS);
  }

  ngOnDestroy() {
    this.deregisterWarningModal();
  }

}
