<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div class="breadcrumb-menu d-flex">
    <div class="breadcrumb-button-icon-container cursor-pointer"
         (click)="openImportDialog()">
      <a class="btn-setting d-flex align-items-center" title="{{'COMMON_BUTTON_IMPORT' | translate}}">
        <i class="icomoon icomoon-csv-import"></i>
        <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_IMPORT' | translate}}</span>
      </a>
    </div>
    <div class="breadcrumb-button-icon-container cursor-pointer negative-margin-right"
         (click)="exportForm()">
      <a class="btn-setting d-flex align-items-center" title="{{'COMMON_BUTTON_EXPORT' | translate}}">
        <i class="icomoon icomoon-csv-export"></i>
        <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_EXPORT' | translate}}</span>
      </a>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<!-- region Content -->
<div class="container-horizontal-padding-half container-vertical-padding-half animated fadeIn">

  <!-- region Survey card -->
  <div class="container-vertical-padding-half card-custom-padding-outer">
    <div class="card col-lg-8 col-md-8 col-xs-12 col-sm-12 p-0">

      <div class="card-header">
        {{'SURVEY_PANEL_HEADING_EDIT'|translate}}
      </div><!-- /.card-header -->

      <div class="card-body">
        <form class="form-horizontal bordered-row" (ngSubmit)="update()" #f="ngForm" novalidate>

          <!-- region Name -->
          <div class="form-group row"
               [ngClass]="{ 'has-danger': hasFieldError(Survey.ValidatedField.NAME) || hasLocalFieldError(name) }">
            <label class="col-form-label form-control-label col-md-4 detail-title required-field-label">
              {{'COMMON_NAME' | translate}}
            </label>
            <div class="col-md-8">
              <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     [ngClass]="{ 'form-control-danger': hasFieldError(Survey.ValidatedField.NAME) || hasLocalFieldError(name) }"
                     [(ngModel)]="model.name" (ngModelChange)="removeFieldError(Survey.ValidatedField.NAME)" name="name"
                     id="name" #name="ngModel" required>
              <div class="form-control-feedback" *ngIf="hasFieldError(Survey.ValidatedField.NAME)">
                {{getFieldErrorText(Survey.ValidatedField.NAME)}}
              </div>
              <div class="form-control-feedback" *ngIf="hasLocalFieldError(name)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED'
                | translate}}
              </div>
            </div><!-- /.edit-form-input-group -->
          </div><!-- /.form-group -->
          <!-- endregion Name -->

          <!-- region ExternalId -->
          <div class="form-group row"
               [ngClass]="{ 'has-danger': hasFieldError(Survey.ValidatedField.EXTERNAL_ID) || hasLocalFieldError(externalId) }">
            <label class="col-form-label form-control-label col-md-4 detail-title required-field-label">
              {{'COMMON_EXTERNAL_ID' | translate}}
            </label>
            <div class="col-md-8">
              <input type="text" class="form-control" placeholder="{{'COMMON_EXTERNAL_ID' | translate}}"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     [ngClass]="{ 'form-control-danger': hasFieldError(Survey.ValidatedField.EXTERNAL_ID) || hasLocalFieldError(externalId) }"
                     [(ngModel)]="model.externalId" (ngModelChange)="removeFieldError(Survey.ValidatedField.EXTERNAL_ID)" name="externalId"
                     id="externalId" #externalId="ngModel" required>
              <div class="form-control-feedback" *ngIf="hasFieldError(Survey.ValidatedField.EXTERNAL_ID)">
                {{getFieldErrorText(Survey.ValidatedField.EXTERNAL_ID)}}
              </div>
              <div class="form-control-feedback" *ngIf="hasLocalFieldError(externalId)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED'
                | translate}}
              </div>
            </div><!-- /.edit-form-input-group -->
          </div><!-- /.form-group -->
          <!-- endregion ExternalId -->

          <!-- region Description -->
          <div class="form-group row">
            <label class="col-form-label form-control-label col-md-4 detail-title">{{'SURVEY_DESCRIPTION' |
              translate}}</label>
            <div class="col-md-8">
              <input type="text" class="form-control" placeholder="{{'SURVEY_DESCRIPTION' | translate}}"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     [(ngModel)]="model.description" name="description" id="description" #description="ngModel">
            </div><!-- /.edit-form-input-group -->
          </div><!-- /.form-group -->
          <!-- endregion Description -->

          <!-- region Bottom buttons -->
          <div>
            <div class="d-flex justify-content-end">
              <input type="submit" class="btn btn-primary" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
            </div><!-- /.edit-form-button-group -->
          </div>
          <!-- endregion Bottom buttons -->

        </form>
      </div><!-- /.card-body -->

    </div><!-- /.card -->
  </div><!-- /.container-vertical-padding-half -->
  <!-- endregion Survey -->

  <!--FILE-->
  <div class="container-vertical-padding-half">
    <div class="row quarter-padding">

      <div class="col-md-6 p-0">
        <div class="card quarter-margin">
          <div class="card-header">
            {{'FORM_PDF_TEMPLATE_HEADING'|translate}}
          </div>
          <div *ngIf="!model.hasPdfTemplate" class="card-body">
            <app-file-upload
              [uploadPath]="pdfTemplateUploadPath"
              (onResult)="model.hasPdfTemplate = $event">
            </app-file-upload>
          </div> <!-- ./card-body end -->
          <div *ngIf="model.hasPdfTemplate" class="card-body">
            <input *ngIf="rightModel.surveyAttachmentDownloadFile.hasRight()"
                   type="button" class="btn btn-primary" value="{{'COMMON_BUTTON_DOWNLOAD'|translate}}"
                   (click)="downloadPdfTemplate()"/>
            <input *ngIf="rightModel.surveyAttachmentDeleteFile.hasRight()"
                   type="button" class="btn btn-primary" value="{{'COMMON_BUTTON_DELETE'|translate}}"
                   (click)="deletePdfTemplate()"/>
          </div>
        </div> <!-- ./card end -->
      </div>
      <!-- /.col-md-6 -->

      <div class="col-md-6 p-0">
        <div class="card quarter-margin">
          <div class="card-header">
            {{'FORM_THUMBNAIL_HEADING'|translate}}
          </div>
          <div *ngIf="!model.hasThumbnail" class="card-body">
            <app-file-upload
              [uploadPath]="thumbnailUploadPath"
              (onResult)="onThumbnailUpload($event)">
            </app-file-upload>
          </div> <!-- ./card-body end -->
          <div *ngIf="model.hasThumbnail" class="card-body">
            <div class="thumbnail-background mb-1" *ngIf="thumbnailLoaded" [style.background-image]="thumbnailSrc"></div>
            <div class="thumbnail-background-placeholder mb-1" *ngIf="!thumbnailLoaded"></div>
            <input *ngIf="rightModel.surveyAttachmentDownloadFile.hasRight()"
                   type="button" class="btn btn-primary" value="{{'COMMON_BUTTON_DOWNLOAD'|translate}}"
                   (click)="downloadThumbnail()"/>
            <input *ngIf="rightModel.surveyAttachmentDeleteFile.hasRight()"
                   type="button" class="btn btn-primary" value="{{'COMMON_BUTTON_DELETE'|translate}}"
                   (click)="deleteThumbnail()"/>
          </div>
        </div> <!-- ./card end -->
      </div>
      <!-- /.col-md-6 -->

    </div>
    <!-- /.row -->
  </div>
  <!-- region Form card -->
  <div class="container-vertical-padding-half card-custom-padding-outer form-editor-card-container" id="form-editor-card-container">
    <app-form-edit #formEdit
                   [formServiceCode]="FormServiceCode.SURVEY"
                   [formRights]="formRights"
                   [parentId]="surveyId"
                   [dialogContainer]="dialogContainer">
    </app-form-edit>
  </div><!-- /.container-vertical-padding -->
  <!-- endregion Form card -->

</div>
<!-- endregion Content -->


<!-- modal -->
<div bsModal [config]="UiConstants.modalConfig" #importDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="importDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'COMMON_IMPORT_TITLE' | translate}}</h4>
        <button type="button" class="close" (click)="closeImportDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body pt-0">
        <div class="row" *ngIf="!importDnDVisible">
          <div class="col-md-4">
            <label
              class="mw-100 col-form-label form-control-label">{{'COMMON_NAME' | translate}}</label>
          </div>
          <div class="col-md-8">
            <label
              class="mw-100 col-form-label form-control-label">{{surveyImportModel.fileName}}</label>
          </div>
        </div>
        <div class="col-sm-12 p-0">
          <app-file-upload
            #fileUploadComponent
            [uploadPath]="''"
            [uploadOnDrop]="false"
            (onFileDropped)="onImportableSurveySelected($event)">
          </app-file-upload>
        </div>
        <div class="col-sm-12" *ngIf="surveyImportModel.importResult">
          <div class="alert alert-success" *ngIf="surveyImportModel.importResult === 'SUCCESS'">
            <h4 class="alert-title">{{'ALERT_TEXT_UPLOAD_SUCCESSFUL' | translate}}</h4>
            <p>{{'ALERT_TEXT_SUCCESSFULLY_UPLOADED' | translate}}</p>
          </div>
          <div class="alert alert-danger" *ngIf="surveyImportModel.importResult === 'ERROR'">
            <h4 class="alert-title">{{'ALERT_TEXT_UPLOAD_FAILED' | translate}}</h4>
            <p>{{uploadGlobalErrors}}</p>
          </div>
          <div class="alert alert-danger" *ngIf="surveyImportModel.importResult === 'FORMAT_ERROR'">
            <h4 class="alert-title">{{'ALERT_TEXT_FILE_ERROR' | translate}}</h4>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeImportDialog()">
          {{'COMMON_BUTTON_CANCEL' | translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="importSurveys()">{{'COMMON_BUTTON_IMPORT' |
          translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<!-- region Dialogs -->
<app-form-edit-dialog-container #dialogContainer></app-form-edit-dialog-container>
<!-- endregion Dialogs -->
