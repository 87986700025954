import { Injectable } from '@angular/core';
import { ListItemQuery, ListItemResourceService, RawListItem, RawListTypeItem } from './list-item-resource.service';
import { Observable, throwError } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { QueryResult, ResourceQueryResult } from '../util/services';
import { List, Map as ImmutableMap, Set } from 'immutable';
import { SettingsService } from '../settings.service';


@Injectable()
export class ListItemMapService {

  private observable: Observable<ListItemMap>;
  private locale: string;

  constructor(private resourceService: ListItemResourceService,
              private settingsService: SettingsService) {

  }

  loadMap() {
    this.locale = this.settingsService.getLanguageCode();
    this.observable = this.resourceService.getMap().pipe(map(m => new ListItemMap(m, this.locale)),
      shareReplay());
  }

  getList(request: ListItemQuery): Observable<QueryResult<RawListItem>> {
    return this.getMap().pipe(map(m => {
      if (!m) {
        throwError('ListItemMap not found');
      }
      return m!.getList(request, this.locale);
    }));
  }

  get(request: { id: number }): Observable<RawListItem> {
    return this.getMap().pipe(map(m => {
      if (!m) {
        throwError('ListItemMap not found');
      }
      const item = m.all().find(l => l.id === request.id);
      if (!item) {
        throwError('listItem not found')
      }
      return item!;
    }));
  }

  query(request: { id: Set<number> }): Observable<ResourceQueryResult<RawListItem>> {
    return this.getMap().pipe(map(m => {
      if (!m) {
        throwError('ListItemMap not found');
      }
      const all = m.all();
      const items = all.filter(l => request.id.has(l.id));
      return {
        items: items,
        pagingResult: {
          numberOfPages: 1,
          currentNumberOfItems: items.length,
          totalNumberOfItems: all.length
        },
        otherHeaders: new Map<string, string>()
      }
    }));
  }

  private getMap(): Observable<ListItemMap> {
    if (!this.observable) {
      this.loadMap();
    }
    return this.observable;
  }
}

export class ListItemMap {

  private readonly itemMap: ImmutableMap<RawListTypeItem, RawListItem[]>;
  private readonly flatList: List<RawListItem>;

  constructor(result: {type: RawListTypeItem, items: RawListItem[]}[], locale: string) {
    const keyValues: any[] = [];
    const flat: RawListItem[] = [];
    console.log(typeof  result);
    result.forEach((value) => {
      const items = this.sortList(value.type, value.items, locale);
      keyValues.push(value.type, items);
      flat.push(...value.items);
    })
    this.itemMap = ImmutableMap.of(...keyValues);
    this.flatList = List.of(...flat);
  }

  public fromType(type: string): TypedList {
    const tempMap = this.itemMap.filter((value, key) => key?.key === type)
      .toMap();
    const items: RawListItem[] = [];
    tempMap.forEach(value => {
      if (value) {
        items.push(...value)
      }
    });
    return {type: tempMap.keys()[0], items: items};
  }

  public all(): RawListItem[] {
    return this.flatList.toArray();
  }

  public getList(request: ListItemQuery, locale?: string): QueryResult<RawListItem> {
    let items: RawListItem[] = [];
    let type: RawListTypeItem | undefined = undefined;
    if (request.type_key || request.type_text) {
      const temp = this.fromType(request.type_key ? request.type_key! : request.type_text!);
      type = temp.type;
      items = temp.items;
    }
    else {
      items = this.all();
    }

    const allCount = items.length;

    if (request.disabled !== undefined) {
      items = items.filter(i => i.disabled === request.disabled);
    }

    if (request.filter_item_id !== undefined) {
      const itemIds: number[] = request.filter_item_id.split(',').map(i => +i);
      items = items.filter(i => i.filter_item_ids.filter(fi => itemIds.filter(ffi => ffi === fi).length > 0).length > 0);
    }

    if (request.text !== undefined) {
      items = items.filter(i => i.text.toLocaleLowerCase().includes(request.text!.trim().toLocaleLowerCase()));
    }

    if (request.type_text !== undefined) {
      items = items.filter(i => i.type.toLocaleLowerCase().includes(request.type_text!.toLocaleLowerCase()));
    }

    if (request.type_id !== undefined) {
      items = items.filter(i => i.type_id === request.type_id!);
    }

    if (request.q !== undefined) {
      items = items.filter(i => i.text.toLocaleLowerCase().includes(request.q!.trim().toLocaleLowerCase()));
    }

    if (request.id !== undefined) {
      const itemIds: number[] = request.id.split(',').map(i => +i);
      items = items.filter(i => itemIds.filter(ii => ii === i.id).length > 0);
    }

    if (!request.type_id && !request.type_key) {
      if (!request.order || request.order.endsWith('text')) {
        items = items.sort(((a, b) => a.text.localeCompare(b.text, locale, {numeric: true})));
      }
    }


    const pageNumber = request.page_number ? request.page_number : 1;
    const numberOfItems = request.number_of_items ? request.number_of_items : items.length;
    const startNumber = (pageNumber - 1) * numberOfItems;
    const pageItems = items.slice(startNumber, startNumber + numberOfItems);

    return {
      items: List.of(...pageItems),
      pagingResult: {
        totalNumberOfItems: allCount,
        currentNumberOfItems: items.length,
        numberOfPages: Math.ceil(allCount / (request.page_number ? request.page_number : 1))
      }
    };
  }

  private sortList(type: RawListTypeItem, items: RawListItem[], locale: string) {
    if (type.list_item_order_field === 'ID') {
      items = items.sort(((a, b) => a.id - b.id));
    }
    if (type.list_item_order_field === 'TEXT') {
      items = items.sort(((a, b) => a.text.localeCompare(b.text, locale, {numeric: true})));
    }
    if (type.list_item_order_field === 'CODE') {
      items = items.sort(((a, b) => a.code!.localeCompare(b.code!, locale, {numeric: true})));
    }
    if (type.list_item_order_type === 'DESC') {
      items = items.reverse();
    }
    return items;
  }
}

interface TypedList {
  type: RawListTypeItem;
  items: RawListItem[];
}
