<div class="modal-content">
  <div class="modal-header">
    <p class="modal-title"><strong>{{getDialogTitle() | translate}}</strong></p>
    <button type="button" class="close" (click)="closeDialog(false)" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <mat-dialog-content style="overflow:visible">
      <form (ngSubmit)="save()" #f="ngForm" id="form"
            [formGroup]="formGroup" novalidate>

        <div class="row form-group"
             [ngClass]="{ 'has-danger': hasLocalFieldError('name') || hasFieldError(ValidatedField.UNIT_NAME)}">
          <label
            class="col-form-label form-control-label col-md-4 detail-title"
            [class.required-field-label]="true">{{'COMMON_NAME' | translate}}</label>
          <div class="col-md-8">
            <div class="mw-100">
              <input type="text"
                     [(ngModel)]="model.name"
                     [ngClass]="{ 'form-control-danger': hasLocalFieldError('name') || hasFieldError(ValidatedField.UNIT_NAME) }"
                     (blur)="onNameBlur()"
                     trim="blur" validateOnBlur
                     class="form-control"
                     name="name"
                     id="name"
                     formControlName="name"
                     (ngModelChange)="removeFieldError(ValidatedField.UNIT_NAME)"
                     placeholder="{{'COMMON_NAME'|translate}}">
              <div class="form-control-feedback" *ngIf="hasLocalFieldError('name')">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
              <div class="form-control-feedback" *ngIf="hasFieldError(ValidatedField.UNIT_NAME)">
                {{getFieldErrorText(ValidatedField.UNIT_NAME)}}
              </div>
            </div>
          </div>
        </div>
        <div class="row form-group"
             [ngClass]="{ 'has-danger': hasLocalFieldError('code') || hasFieldError(ValidatedField.CODE)}">
          <label
            class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_EXTERNAL_ID' | translate}}</label>
          <div class="col-md-8">
            <div class="mw-100">
              <input type="text"
                     [(ngModel)]="model.code"
                     [ngClass]="{ 'form-control-danger': hasLocalFieldError('code') || hasFieldError(ValidatedField.CODE) }"
                     trim="blur"
                     validateOnBlur
                     class="form-control"
                     name="code"
                     id="code"
                     formControlName="code"
                     (ngModelChange)="removeFieldError(ValidatedField.CODE)"
                     placeholder="{{'COMMON_EXTERNAL_ID'|translate}}">
              <div class="form-control-feedback" *ngIf="hasLocalFieldError('code')">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
              <div class="form-control-feedback" *ngIf="hasFieldError(ValidatedField.CODE)">
                {{getFieldErrorText(ValidatedField.CODE)}}
              </div>
            </div>
          </div>
        </div>
        <div class="row form-group"
             [ngClass]="{ 'has-danger': hasLocalFieldError('conversion') || hasFieldError(ValidatedField.UNIT_CONVERSION)}">
          <label
            class="col-form-label form-control-label col-md-4 detail-title">{{'STOCK_ITEM_MEASUREMENT_CONVERSION' | translate}}</label>
          <div class="col-md-8">
            <div class="mw-100">
              <input type="text"
                     [(ngModel)]="model.conversion"
                     [ngClass]="{ 'form-control-danger': hasLocalFieldError('conversion') || hasFieldError(ValidatedField.UNIT_CONVERSION) }"
                     validateOnBlur
                     [textMask]="{mask: InputMask.NATURAL_FLOAT_WITH_THOUSAND_SEPARATOR, guide: false}"
                     class="form-control"
                     name="conversion"
                     id="conversion"
                     formControlName="conversion"
                     (ngModelChange)="removeFieldError(ValidatedField.UNIT_CONVERSION)"
                     placeholder="{{'STOCK_ITEM_MEASUREMENT_CONVERSION'|translate}}">
              <div class="form-control-feedback" *ngIf="hasLocalFieldError('conversion', 'required')">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
              <div class="form-control-feedback" *ngIf="hasLocalFieldError('conversion', 'numberNotPositive')">
                {{'COMMON_VALIDATION_MESSAGE_NUMBER_NOT_POSITIVE' | translate}}
              </div>
              <div class="form-control-feedback" *ngIf="hasFieldError(ValidatedField.UNIT_CONVERSION)">
                {{getFieldErrorText(ValidatedField.UNIT_CONVERSION)}}
              </div>
            </div>
          </div>
        </div>
      </form>
    </mat-dialog-content>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="closeDialog(false)">
      {{'COMMON_BUTTON_CANCEL' | translate}}
    </button>
    <button type="submit" class="btn btn-primary" form="form">
      {{'COMMON_BUTTON_SAVE' | translate}}
    </button>
  </div>
</div>
