<div bsModal [config]="UiConstants.modalConfig" #orderDocumentCreateDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="orderDocumentCreateDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title">{{'TABLE_DOCUMENT_CREATE_TITLE' | translate}}</p>
        <button type="button" class="close" (click)="closeOrderDocumentCreateDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" [formGroup]="formGroup" #f="ngForm">

        <div class="form-group row"
             [ngClass]="{ 'has-danger': hasLocalFieldError('type') }">
          <label class="col-form-label col-md-4">{{'COMMON_TYPE' | translate}}</label>
          <div class="col-md-8">
            <input type="text" class="form-control"
                   placeholder="{{'COMMON_TYPE' | translate}}"
                   maxlength="{{UiConstants.maximumVarcharLength}}"
                   validateOnBlur [validateFormControl]="formGroup.controls['type']"
                   [ngClass]="{ 'form-control-danger': hasLocalFieldError('type') }"
                   [(ngModel)]="orderDocumentCreateModel.type"
                   formControlName="type">
            <div class="form-control-feedback"
                 *ngIf="hasLocalFieldError('type')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
          </div>
          <!-- /.col-md-8 -->
        </div>

        <div class="form-group row"
             [ngClass]="{ 'has-danger': hasLocalFieldError('comment') }">
          <label class="col-form-label col-md-4">{{'COMMON_COMMENT' | translate}}</label>
          <div class="col-md-8">
            <input type="text" class="form-control"
                   placeholder="{{'COMMON_COMMENT' | translate}}"
                   maxlength="{{UiConstants.maximumVarcharLength}}"
                   validateOnBlur [validateFormControl]="formGroup.controls['comment']"
                   [ngClass]="{ 'form-control-danger': hasLocalFieldError('comment') }"
                   [(ngModel)]="orderDocumentCreateModel.comment"
                   formControlName="comment">
            <div class="form-control-feedback"
                 *ngIf="hasLocalFieldError('comment')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
          </div>
          <!-- /.col-md-8 -->
        </div>

        <div class="modal-body pt-0">
          <app-file-upload
            #fileUploadComponent
            [uploadPath]="''"
            [uploadOnDrop]="false"
            (onFileDropped)="fileUploadEmptyError = false"
            (responseContent)="onUploadResult($event)">
          </app-file-upload>
          <div class="has-danger"
               *ngIf="fileUploadEmptyError">
            <div class="form-control-feedback">
              {{'COMMON_VALIDATION_MESSAGE_NO_FILE_UPLOADED' | translate}}
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeOrderDocumentCreateDialog()">
          {{'COMMON_BUTTON_CANCEL' | translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="createOrderDocument()">{{'COMMON_BUTTON_SAVE' |
            translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
