/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { Order, PagingRequest, QueryResult, ResourceQueryResult, Services } from '../util/services';
import { DownloadedFile } from '../util/downloaded-files';
import { List, Map, Set } from 'immutable';
import { OffsetDateTime } from '../util/dates';
import { GeneralPrinterTemplateResource, GeneralPrinterTemplateResourceService } from './general-printer-template-resource.service';
import { EmptyMessage } from '../util/messages';
/* eslint-enable */

@Injectable()
export class GeneralPrinterTemplateService implements GeneralPrinterTemplate.Service {

  constructor(private resourceService: GeneralPrinterTemplateResourceService) {}

  query(request: GeneralPrinterTemplate.QueryRequest): Observable<QueryResult<GeneralPrinterTemplate.GeneralPrinterTemplate>> {
    return Observable.create((observer: Observer<QueryResult<GeneralPrinterTemplate.GeneralPrinterTemplate>>) => {
      const resourceRequest: GeneralPrinterTemplateResource.QueryRequest = {
        ids: Services.createIdParameter(request.ids),
        code: request.code,
        disabled: request.disabled,
        order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
        no_progress_bar: request.noProgressBar
      };
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<GeneralPrinterTemplateResource.GeneralPrinterTemplate>) => {
          observer.next({
            items: this.toPublicList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(/*this.translateError(*/error/*)*/);
        },
        () => {
          observer.complete();
        });
    });
  }

  get(request: GeneralPrinterTemplate.GetRequest): Observable<GeneralPrinterTemplate.GeneralPrinterTemplate> {
    return Observable.create((observer: Observer<GeneralPrinterTemplate.GeneralPrinterTemplate>) => {
      const resourceRequest: GeneralPrinterTemplateResource.GetRequest = {
        code: request.code
      };
      return this.resourceService.get(resourceRequest).subscribe(
        (result: GeneralPrinterTemplateResource.GeneralPrinterTemplate) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(/*this.translateError(*/error/*)*/);
        },
        () => {
          observer.complete();
        });
    });
  }

  export(request: GeneralPrinterTemplate.DownloadRequest): Observable<DownloadedFile> {
    return this.resourceService.download({
      code: request.code,
    });
  }

  setDisabled(request: GeneralPrinterTemplate.DisableRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: GeneralPrinterTemplateResource.DisableRequest = {
        code: request.code,
        disabled: request.disabled
      };
      return this.resourceService.setDisabled(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(/*this.translateError(*/error/*)*/);
        },
        () => {
          observer.complete();
        });
    });
  }

  private toPublicList(resourceList: GeneralPrinterTemplateResource.GeneralPrinterTemplate[]):
    List<GeneralPrinterTemplate.GeneralPrinterTemplate> {
    return List.of(...resourceList.map((r) => this.toPublic(r)));
  }

  private toPublic(r: GeneralPrinterTemplateResource.GeneralPrinterTemplate): GeneralPrinterTemplate.GeneralPrinterTemplate {
    return {
      id: r.id,
      code: r.code,
      contentHash: r.content_hash,
      version: r.version,
      creationTime: Services.toOffsetDateTime(r.creation_time),
      updateTime: Services.toOffsetDateTime(r.update_time),
      disabled: r.disabled
    };
  }
}

export namespace GeneralPrinterTemplate {

  export interface Service {

    // <editor-fold desc="CRUD">

    query(request: QueryRequest): Observable<QueryResult<GeneralPrinterTemplate>>;

    get(request: GetRequest): Observable<EmptyMessage>;

    export(request: DownloadRequest): Observable<DownloadedFile>;

    setDisabled(request: DisableRequest): Observable<EmptyMessage>;

    // </editor-fold>

  }

  export interface GeneralPrinterTemplate {
    id: number;
    code: string;
    contentHash: string;
    version: number;
    creationTime?: OffsetDateTime;
    updateTime?: OffsetDateTime;
    disabled: boolean;
  }

  export interface QueryRequest {
    ids?: Set<number>;
    code?: string;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
    disabled?: boolean;
    noProgressBar?: boolean;
  }

  export interface GetRequest {
    code: string;
  }

  export interface DownloadRequest {
    code: string;
  }

  export interface DisableRequest {
    code: string;
    disabled: boolean;
  }

  export enum OrderField {
    ID,
    CODE,
  }

  export enum ValidatedField {
    UNKNOWN,
    CODE
  }

}


class Keys {

  private static readonly ID = 'id';
  private static readonly CODE = 'code';

  private static readonly orderFieldKeyMap: Map<GeneralPrinterTemplate.OrderField, string> = Map.of(
    GeneralPrinterTemplate.OrderField.ID, Keys.ID,
    GeneralPrinterTemplate.OrderField.CODE, Keys.CODE,
  );

  private static readonly keyValidatedFieldMap: Map<string, GeneralPrinterTemplate.ValidatedField> = Map.of(
    GeneralPrinterTemplate.ValidatedField.CODE, Keys.CODE,
  );

  public static toOrderFieldKey(field: GeneralPrinterTemplate.OrderField): string {
    return Keys.orderFieldKeyMap.get(field)!;
  }

  public static toValidatedField(fieldKey: string): GeneralPrinterTemplate.ValidatedField {
    return Keys.keyValidatedFieldMap.get(fieldKey, GeneralPrinterTemplate.ValidatedField.UNKNOWN);
  }

}
