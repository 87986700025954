/* eslint-disable */
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { StateParams, UIRouter } from '@uirouter/angular';
import { StateName, StateRequestParam } from '../../app.state-names';
import { UserActivationRequest, UserService } from '../../lib/user.service';
import { EmptyMessage } from '../../lib/util/messages';
import {
  LocalStorages,
  LoginRequiredReason,
  LoginRequiredReasonStorage
} from '../../lib/util/storages';
import { ShopRenterTokenResource } from '../../lib/shoprenter/shoprenter.service';
import { toasterConfig } from '../../fork/angular2-toaster/src/toaster-config';

/* eslint-enable */

@Component({
  selector: 'app-user-activation',
  templateUrl: './user-activation.component.html',
  styleUrls: ['./user-activation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserActivationComponent implements OnInit {
  toasterConfig = toasterConfig;

  private _params: ActivationModel;

  private _activating = false;
  private _activationFailed = false;
  private _activationDone = false;
  private _invalidArguments: boolean = false;

  get params(): ActivationModel {
    return this._params;
  }

  get invalidArguments(): boolean {
    return this._invalidArguments;
  }

  get activating(): boolean {
    return this._activating;
  }

  get activationDone(): boolean {
    return this._activationDone;
  }

  get activationFailed(): boolean {
    return this._activationFailed;
  }

  constructor(
    private userService: UserService,
    private router: UIRouter) {
  }

  ngOnInit(): void {
    const params = this.parseParams();
    this._params = params; // debug
    this.activateUser(params);
  }

  private parseParams(): ActivationModel {
    return new ActivationModel(this.router.stateService.params);
  }

  private activateUser(params: ActivationModel) {
    if (params.token) {
      const activationRequest: UserActivationRequest = {
        registration_token: params.token
      };
      this._activating = true;
      this._activationDone = false;
      this._activationFailed = false;
      this.userService.activateUser(activationRequest).subscribe(
        (result: EmptyMessage) => {
          this._activating = false;
          this._activationDone = true;
          this.handleSuccess();
        },
        (error) => {
          this._activating = false;
          this._activationFailed = true;
          this.handleError();
        }
      );
    }
    else {
      this._invalidArguments = true;
    }
  }

  private handleSuccess() {
    // Log out if logged in.
    LoginRequiredReasonStorage.getInstance().setReason(LoginRequiredReason.USER_ACTIVATION_LOGIN);
    if (this.params.shoprenterParams) {
      this.router.stateService.go(StateName.SHOPRENTER_ENTRY, this.params.shoprenterParams);
    }
    else {
      this.router.stateService.go(StateName.LOGIN);
    }
  }

  private handleError() {
    // Log out if logged in.
    LoginRequiredReasonStorage.getInstance().setReason(LoginRequiredReason.USER_ACTIVATION_FAILED_LOGIN);
    LocalStorages.onLogout();
    this.router.stateService.go(StateName.LOGIN);
  }

}

class ActivationModel {
  readonly userToken: string | null;
  readonly shoprenterToken: string | null;

  constructor(params: StateParams) {
    this.userToken = params[StateRequestParam.USER_ACTIVATION_TOKEN]
      ? decodeURIComponent(params[StateRequestParam.USER_ACTIVATION_TOKEN])
      : null;
    this.shoprenterToken = params[StateRequestParam.SHOPRENTER_USER_ACTIVATION_TOKEN]
      ? decodeURIComponent(params[StateRequestParam.SHOPRENTER_USER_ACTIVATION_TOKEN])
      : null;
  }

  get token(): string {
    return this.userToken ? this.userToken : this.shoprenterToken!;
  }

  get shoprenterParams(): any {
    if (this.shoprenterToken) {
      const shoprenterResource: ShopRenterTokenResource = JSON.parse(window.atob(this.shoprenterToken));
      const params = [];
      params[StateRequestParam.SHOPRENTER_AUTH_SHOP_NAME] = shoprenterResource.shop_name;
      params[StateRequestParam.SHOPRENTER_AUTH_CODE] = shoprenterResource.code;
      params[StateRequestParam.SHOPRENTER_AUTH_HMAC] = shoprenterResource.hmac;
      params[StateRequestParam.SHOPRENTER_AUTH_TIMESTAMP] = shoprenterResource.timestamp;
      return params;
    }
    return null;
  }
}
