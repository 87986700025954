<div>
  <table class="table table-sm shipping-demand-item-table">
    <thead>
    <tr>
      <th class="responsive-table-column w-5 text-center align-middle"></th>
      <th class="responsive-table-column w-25">{{'COMMON_NAME' | translate}}</th>
      <th class="responsive-table-column w-15 hidden-sm-down"
          *ngIf="isEnabledField(EditableField.ITEM_CATEGORY)">{{'SHIPPING_DEMAND_ITEM_CATEGORY' | translate}}</th>
      <th class="responsive-table-column w-10 hidden-sm-down"
          *ngIf="isEnabledField(EditableField.ITEM_AMOUNT)">{{'SHIPPING_DEMAND_ITEM_AMOUNT' | translate}}</th>
      <th class="responsive-table-column w-10 hidden-sm-down"
          *ngIf="isEnabledField(EditableField.ITEM_WEIGHT_IN_GRAM)">{{'SHIPPING_DEMAND_ITEM_WEIGHT' | translate}}</th>
      <th class="responsive-table-column w-10 hidden-xs-down"
          *ngIf="isEnabledField(EditableField.ITEM_PRICE)">{{'SHIPPING_DEMAND_ITEM_PRICE_AMOUNT' | translate}}</th>
      <th class="responsive-table-column w-15 hidden-xs-down"
          *ngIf="isEnabledField(EditableField.ITEM_COMMENT)">{{'COMMON_COMMENT' | translate}}</th>
      <th class="responsive-table-column w-5"></th>
    </tr>
    </thead>
    <tbody *ngFor="let item of shippingItems; index as i;">
    <tr [class.error_tr] = " hasItemError(item.externalId) ">
      <td class="responsive-table-column w-5 text-center align-middle">{{item.id}}</td>
      <td class="responsive-table-column w-25">{{item.name}}</td>
      <td class="responsive-table-column w-15 hidden-sm-down"
          *ngIf="isEnabledField(EditableField.ITEM_CATEGORY)">{{item.category}}</td>
      <td class="responsive-table-column w-10 hidden-sm-down"
          *ngIf="isEnabledField(EditableField.ITEM_AMOUNT)">{{item.amount.value}} {{item.amount.unit}}</td>
      <td class="responsive-table-column w-10 hidden-sm-down"
          *ngIf="isEnabledField(EditableField.ITEM_WEIGHT_IN_GRAM)">{{item.weightInGram ? WeightFactory.createWeightFromGram(item.weightInGram).toString() : ''}}</td>
      <td class="responsive-table-column w-10 hidden-xs-down"
          *ngIf="isEnabledField(EditableField.ITEM_PRICE)">
        <span *ngIf="item.price && item.price.amount !== '' && item.price.amount !== null">
          {{item.price.amount | number:'':'hu-HU'}} {{item.price.currencyCode}}
        </span>
      </td>
      <td class="responsive-table-column w-15 hidden-xs-down"
          *ngIf="isEnabledField(EditableField.ITEM_COMMENT)">{{item.comment}}</td>
      <td class="responsive-table-column w-5 align-middle" (click)="toggleEdit(i)"><i
        class="icomoon icomoon-detail cursor-pointer"></i></td>
    </tr>
    <tr *ngIf="item.editing">
      <td colspan="9" class="shipping-demand-item-edit">
        <app-shipping-demand-item-edit
          [model]="item"
          [itemError]="getItemError(item.externalId)"
          [isRemovable]="true"
          (remove)="removeItem(i, $event)"
          (cancel)="closeRow(i)"
          (save)="saveItem(i, $event)"
          [currencyList]="currencyList"
          [shippingItemExternalIds]="shippingItemExternalIds"
          [componentState]="componentState"
          [rightModel]="rightModel"
          [editableFieldModel]="editableFieldModel"
          [disabled]="disabled"
        #shippingItemEdit></app-shipping-demand-item-edit>
      </td>
    </tr>
    </tbody>
    <tbody *ngIf="!addModel && (!shippingItems || shippingItems.length === 0)">
    <tr>
      <td colspan="9">
        <div class="text-center text-muted p-2">
          <label *ngIf="componentState.isEditable()"
                 class="m-0">{{'SHIPPING_DEMAND_ITEM_NO_ITEM' | translate}}
          </label>
        </div>
      </td>
    </tr>
    </tbody>
    <tbody *ngIf="addModel">
    <tr>
      <td colspan="9" class="shipping-demand-item-edit p-0">
        <app-shipping-demand-item-edit
          [model]="addModel"
          [isRemovable]="false"
          (cancel)="cancelNewItem()"
          (save)="saveNewItem($event)"
          [currencyList]="currencyList"
          [shippingItemExternalIds]="shippingItemExternalIds"
          [componentState]="componentState"
          [rightModel]="rightModel"
          [editableFieldModel]="editableFieldModel"
          #shippingItemCreate></app-shipping-demand-item-edit>
      </td>
    </tr>
    </tbody>
    <tr *ngIf="!addModel && componentState.isEditable() && !disabled">
      <td colspan="9">
        <input class="btn btn-add-record cursor-pointer" type="button"
               value="{{'SHIPPING_DEMAND_ITEM_ADD_BUTTON' | translate}}" (click)="newItem()">
      </td>
    </tr>
  </table>

</div>
