<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb"
     *ngIf="!isComponentEmbedded">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div class="breadcrumb-menu d-flex">

    <!--IMPORT-->
    <div *ngIf="rightModel.listItemImportXls.hasRight()"
         class="breadcrumb-button-icon-container cursor-pointer"
         href="#" onclick="return false"
         (click)="showImportDialog()">
      <a class="btn-setting d-flex align-items-center" title="{{'COMMON_BUTTON_IMPORT' | translate}}">
        <i class="icomoon icomoon-csv-import"></i>
        <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_IMPORT' | translate}}</span>
      </a>
    </div>

    <!--EXPORT-->
    <div class="breadcrumb-button-icon-container cursor-pointer"
         (click)="exportXls()">
      <a class="btn-setting d-flex align-items-center" title="{{'COMMON_BUTTON_EXPORT' | translate}}">
        <i class="icomoon icomoon-csv-export"></i>
        <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_EXPORT' | translate}}</span>
      </a>
    </div>

    <!--CREATE-->
    <div *ngIf="rightModel.listItemCreate.hasRight()"
         class="breadcrumb-button-icon-container cursor-pointer negative-margin-right"
         uiSref="Admin.ListItemCreate">
      <a class="btn-setting d-flex align-items-center" title="{{'COMMON_BUTTON_ADD' | translate}}">
        <i class="icomoon icomoon-add"></i>
        <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_ADD' | translate}}</span>
      </a>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder" *ngIf="!isComponentEmbedded"></div>
<div class="animated fadeIn"
     [ngClass]="{
      'container-horizontal-padding container-vertical-padding': !isComponentEmbedded,
      'container-horizontal-padding-half container-vertical-padding-half': isComponentEmbedded
     }">

  <div class="card">

    <div class="card-header">
      {{'LIST_ITEM_PANEL_HEADING_LIST' | translate}}

      <div class="card-actions d-flex">
        <app-breadcrumb-menu *ngIf="isComponentEmbedded">
          <app-breadcrumb-button
            *ngIf="rightModel.listItemImportXls.hasRight()"
            [iconClass]="'icomoon-csv-import'"
            [titleStringKey]="'COMMON_BUTTON_IMPORT'"
            (click)="showImportDialog()">
          </app-breadcrumb-button>
          <app-breadcrumb-button
            [iconClass]="'icomoon-csv-export'"
            [titleStringKey]="'COMMON_BUTTON_EXPORT'"
            (click)="exportXls()">
          </app-breadcrumb-button>
          <app-breadcrumb-button
            *ngIf="rightModel.listItemCreate.hasRight()"
            [iconClass]="'icomoon-add'"
            [titleStringKey]="'COMMON_BUTTON_ADD'"
            (click)="showCreate()">
          </app-breadcrumb-button>
        </app-breadcrumb-menu>
        <a class="btn-setting  cursor-pointer" (click)="toggleSearch()"
           title="{{'COMMON_SHOW_SEARCH_HINT' | translate}}"><i #searchIcon class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>

    <div class="card-body border-bottom" *ngIf="showSearch">
      <div class="row form-group">

        <div class="col">
          <label class="search-label">{{'LIST_ITEM_SEARCH_TYPE_TEXT' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control" placeholder="{{'LIST_ITEM_SEARCH_TYPE_TEXT' | translate}}"
                   maxlength="{{UiConstants.maximumVarcharLength}}"
                   (keyup.enter)="onSearchClicked()"
                   [(ngModel)]="searchModel.text" name="text" id="text" #text="ngModel">
          </div>
        </div>

        <div class="col" *ngIf="!typeId">
          <label class="search-label">{{'COMMON_TYPE' | translate}}</label>
          <div class="input-group">
            <angular2-multiselect
              class="w-100"
              [(ngModel)]="searchModel.type"
              (onRemoteSearch)="loadListItemTypes($event.target.value)"
              [data]="listItemTypes"
              [settings]="dropdownSettings"
              id="type" name="type" #type="ngModel">
            </angular2-multiselect>
          </div>
        </div>
        <div class="col">
          <label class="search-label"> {{ 'COMMON_STATE' | translate }} </label>
          <select
            class="form-control"
            [(ngModel)]="searchModel.disabled" [compareWith]="SelectUtils.compareObjects">
            <option *ngFor="let item of disabledItems" [ngValue]="item">{{item.text}}</option>
          </select>
        </div>

      </div>
      <div class="col-md-12 d-flex justify-content-md-end align-items-end px-0">
        <div class="btn-group" role="group">
          <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
            {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
          </button>
          <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
            {{'COMMON_BUTTON_SEARCH' | translate}}
          </button>
        </div>
      </div>
    </div>

    <div class="card-body">

      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header w-5">
            <app-table-field-sorter [orderField]="ListItemList.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(ListItemList.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [checkAllEnabled]="true"
                                    [checkAllSelected]="eachListItemSelected"
                                    (onCheckAllClicked)="toggleEachListItem()"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-25 hidden-xs-down" *ngIf="!typeId">
            <app-table-field-sorter [orderField]="ListItemList.OrderField.TYPE_KEY"
                                    [orderType]="queryModel.getOrderType(ListItemList.OrderField.TYPE_KEY)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TYPE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-25 hidden-xs-down">
            <app-table-field-sorter [orderField]="ListItemList.OrderField.CODE"
                                    [orderType]="queryModel.getOrderType(ListItemList.OrderField.CODE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'LISTITEM_TABLE_HEADER_CODE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header long">
            <app-table-field-sorter [orderField]="ListItemList.OrderField.TEXT"
                                    [orderType]="queryModel.getOrderType(ListItemList.OrderField.TEXT)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'LISTITEM_TABLE_HEADER_TEXT' | translate"></app-table-field-sorter>
          </th>
          <th *ngIf="false" class="table-sorter-header w-20 hidden-xs-down">
            <app-table-sorter-no-op [text]="'LISTITEM_TABLE_HEADER_PARAMS' | translate"></app-table-sorter-no-op>
          </th>
          <th class="w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let listItem of listItemList | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }"
            [disabledTableRow]="listItem.item.disabled">

          <td class="fit align-middle">
            <div class="d-flex">
              <app-animated-checkbox
                class="toggle-checkbox"
                [(selected)]="listItem.selected">
              </app-animated-checkbox>
              {{listItem.item.id}}
            </div>
          </td>
          <td class="responsive-table-column hidden-xs-down" *ngIf="!typeId">
            <a class="id-text cursor-pointer" *ngIf="rightModel.listItemTypeUpdate.hasRight()"
               href="#" onclick="return false"
               uiSref="Admin.ListItemTypeEdit"
               [uiParams]="{ id: listItem.item.type_id}"
               title="{{'COMMON_BUTTON_EDIT' | translate}}">
              {{listItem.item.type}}
            </a>
            <span *ngIf="!rightModel.listItemTypeUpdate.hasRight()">{{listItem.item.type}}</span>
          </td>
          <td class="responsive-table-column hidden-xs-down">{{listItem.item.code}}</td>
          <td class="responsive-table-column long">{{listItem.item.text}}</td>
          <td *ngIf="false" class="responsive-table-column">{{getParams(listItem.item)}}</td>
          <td class="align-middle">
            <app-table-options-menu>
              <app-dropdown-item
                *ngIf="rightModel.listItemUpdate.hasRight() && !isComponentEmbedded"
                [iconClass]="'icomoon-modify'"
                [titleStringKey]="'COMMON_BUTTON_EDIT'"
                class="cursor-pointer"
                uiSref="Admin.ListItemEdit"
                [uiParams]="{ id: listItem.item.id }">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="rightModel.listItemUpdate.hasRight() && isComponentEmbedded"
                [iconClass]="'icomoon-modify'"
                [titleStringKey]="'COMMON_BUTTON_EDIT'"
                class="cursor-pointer"
                (click)="showEdit(listItem.item.id)">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="listItem.item.disabled && rightModel.listItemDisable.hasRight()"
                [iconClass]="'icomoon-enable'"
                [titleStringKey]="'COMMON_BUTTON_ENABLE'"
                [itemType]="DropdownItemType.ENABLE"
                class="cursor-pointer"
                (click)="setDisabled($event, listItem.item, false)">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="!listItem.item.disabled && rightModel.listItemDisable.hasRight()"
                [iconClass]="'icomoon-disable'"
                [titleStringKey]="'COMMON_BUTTON_DISABLE'"
                [itemType]="DropdownItemType.DISABLE"
                class="cursor-pointer"
                (click)="setDisabled($event, listItem.item, true)">
              </app-dropdown-item>
            </app-table-options-menu>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header w-5">
            <app-table-field-sorter [orderField]="ListItemList.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(ListItemList.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [checkAllEnabled]="true"
                                    [checkAllSelected]="eachListItemSelected"
                                    (onCheckAllClicked)="toggleEachListItem()"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-25 hidden-xs-down" *ngIf="!typeId">
            <app-table-field-sorter [orderField]="ListItemList.OrderField.TYPE_KEY"
                                    [orderType]="queryModel.getOrderType(ListItemList.OrderField.TYPE_KEY)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TYPE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-25 hidden-xs-down">
            <app-table-field-sorter [orderField]="ListItemList.OrderField.CODE"
                                    [orderType]="queryModel.getOrderType(ListItemList.OrderField.CODE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'LISTITEM_TABLE_HEADER_CODE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header long">
            <app-table-field-sorter [orderField]="ListItemList.OrderField.TEXT"
                                    [orderType]="queryModel.getOrderType(ListItemList.OrderField.TEXT)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'LISTITEM_TABLE_HEADER_TEXT' | translate"></app-table-field-sorter>
          </th>
          <th *ngIf="false" class="table-sorter-header w-20 hidden-xs-down">
            <app-table-sorter-no-op [text]="'LISTITEM_TABLE_HEADER_PARAMS' | translate"></app-table-sorter-no-op>
          </th>
          <th class="w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>
  </div>
</div>

<app-file-upload-dialog
  [uploadPath]="uploadPath"
  (onResult)="onImportSuccess($event)"
  #importDialog>
</app-file-upload-dialog>
