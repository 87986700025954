import { Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { OrderFieldFunction, OrderFieldModel, SelectUtils, UiConstants } from '../../../util/core-utils';
import { SearchUtils } from '../../../util/search-utils';
import { WorkflowSearch, WorkflowSearchService } from '../../../lib/workflow/workflow-search-service';
import { OrderField } from '../../../lib/query/orderfields';
import { OrderType, QueryResult } from '../../../lib/util/services';
import { Angular2Multiselects } from '../../../util/multiselect';
import { RightModel } from '../../../app.rights';
import { WorkflowListModel } from './workflow-list.model';
import { GrantedPermissionSetResolver, RightResolver, RightService } from '../../../lib/right.service';
import { Workflow, WorkflowService } from '../../../lib/workflow/workflow.service';
import { Set } from 'immutable';
import { OperationRights } from '../../../app.right-definitions';
import { FilterField } from '../../../lib/query/filterfields';
import { CriteriaBuilder } from '../../../util/model-utils';
import { WorkflowRightModel } from '../../../lib/workflow/workflow-right.model';
import { BadgeStyle } from '../../../shared/table-badge/badge-style';
import { InputMask } from '../../../util/input-masks';
import { MatDialog } from '@angular/material/dialog';
import { EmptyMessage, IdentityMessage } from '../../../lib/util/messages';
import { StateName } from '../../../app.state-names';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { UIRouter } from '@uirouter/angular';
import { WorkflowBaseDialogComponent } from '../workflow-base-dialog/workflow-base-dialog.component';
import { WorkflowImportDialogComponent } from './workflow-import/workflow-import-dialog.component';
import { DropdownItemType } from '../../../shared/dropdown/dropdown-item/dropdown-item-type';

@Component({
  selector: 'app-workflow-list',
  templateUrl: './workflow-list.component.html',
  styleUrls: ['./workflow-list.component.scss']
})
export class WorkflowListComponent extends SearchUtils.SearchableList<WorkflowSearch.Model> implements OnInit, OnDestroy {

  UiConstants = UiConstants;
  BadgeStyle = BadgeStyle;
  InputMask = InputMask;
  SelectUtils = SelectUtils;
  WorkflowSearch = WorkflowSearch;
  DropdownItemType = DropdownItemType;

  queryModel: OrderFieldModel<OrderField.Workflow>
    = new OrderFieldModel(WorkflowSearch.OrderFunctions.ID, OrderType.DESC);
  searchModel: WorkflowSearch.Model = new WorkflowSearch.Model();

  dropdownSettings: Angular2Multiselects.Settings;

  workflowList: WorkflowListModel[] = [];
  states: WorkflowSearch.StateFilterOption[] = [];
  rightModel: RightModel = RightModel.empty();

  breadcrumbSelf: string;
  breadcrumbParents: BreadcrumbParent[] = [];
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  @ViewChild('importDialog', { static: true })
  importDialog: WorkflowImportDialogComponent;

  constructor(private workflowService: WorkflowService,
              private workflowSearchService: WorkflowSearchService,
              private rightService: RightService,
              private dialog: MatDialog,
              private uiRouter: UIRouter,
              injector: Injector) {
    super(WorkflowSearch.Model, injector);
  }

  ngOnInit() {
    this.loadRightModels();
    this.initBreadcrumb();
    this.initSearch();
    this.initDropdownSettings();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  initBreadcrumb() {
    this.translateService.get('MENU_NAVBAR_NAV_TITLE_WORKFLOWS').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
  }

  initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .remoteSearch(true)
      .enableCheckAll(false)
      .build();
  }

  loadSearch(completion: () => void) {
    this.workflowSearchService.getSearchData({})
      .subscribe(
        (result: WorkflowSearch.SearchDataResult) => {
          this.queryModel = result.searchData.queryModel;
          this.searchModel = result.searchData.searchModel;
          completion();
        }
      );
  }

  onFirstSearchOpen(): void {
    this.loadStateFilterOptions();
  }

  private loadStateFilterOptions() {
    this.states = [];
    this.states.push(WorkflowSearch.Model.defaultSelectedState);
    Workflow.versionStates.forEach((stateObject) => {
      this.states.push({
        id: stateObject.state,
        stringKey: stateObject.stringKey
      });
    });
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: OrderFieldFunction<OrderField.Workflow>) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const rights = Set.of(
      OperationRights.WORKFLOW_UPDATE,
      OperationRights.WORKFLOW_ACTIVATE
    );
    const order = this.queryModel.createOrderFunction();
    const filter = this.createFilter();
    this.workflowService.query({
      fields: f => f.forList,
      order: order,
      filter: filter,
      rights: rights,
      latestVersionsOnly: true,
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined,
    }).subscribe((result: QueryResult<Workflow.Workflow>) => {
      this.workflowList = [];
      result.items.forEach((workflow: Workflow.Workflow) => {
        const model = new WorkflowListModel();
        model.id = workflow.id;
        model.rights = new WorkflowRightModel(GrantedPermissionSetResolver.byGrantedRights(workflow.grantedRights));
        model.name = workflow.name;
        model.externalId = workflow.externalId;
        model.version = workflow.version;
        model.versionState = workflow.versionState;
        const stateObject = Workflow.versionStates.find(state => state.state === workflow.versionState);
        if (stateObject) {
          model.stateObject = stateObject;
        }
        model.icon = workflow.icon;
        model.updateTime = workflow.updateTime;
        model.lastModifiedUser = workflow.lastModifiedUser!;
        this.workflowList.push(model);
      });
      this.queryModel.currentPage = requestedPage;
      this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
    });
  }

  private createFilter() {
    return (f: FilterField.Workflow) => CriteriaBuilder.builder()
      .addNumber((id) => f.id.eq(id), this.searchModel.id)
      .addString((externalId) => f.externalId.containsIgnoreCase(externalId), this.searchModel.externalId)
      .addString((name) => f.name.containsIgnoreCase(name), this.searchModel.name)
      .addEnum((versionState) => f.versionState.eq(versionState), this.searchModel.versionState.id)
      .build();
  }

  onSearchClicked() {
    this.loadList(1);
  }

  onSearchReset() {
    this.workflowSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.toggleSearch();
          this.loadList(1);
        });
      }
    );
  }


  ngOnDestroy(): void {
    this.saveSearch();
  }

  private saveSearch() {
    const request = {
        searchData: {
          queryModel: this.queryModel,
          searchModel: this.searchModel
        }
      }
    ;
    this.workflowSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  openWorkflowCreateDialog() {
    const dialogRef = this.dialog.open(WorkflowBaseDialogComponent, {
      panelClass: 'custom-dialog-container',
      width: '60%',
      maxHeight: '100vh',
      data: {readonly: false}
    });

    dialogRef.afterClosed().subscribe((result: IdentityMessage) => {
      if (result) {
        this.uiRouter.stateService.go(StateName.WORKFLOW_EDIT, {id: result.id});
      }
    });
  }

  openWorkflowImportDialog() {
    this.importDialog.openImportDialog();
  }

  onWorkflowImported(id: number) {
    this.uiRouter.stateService.go(StateName.WORKFLOW_EDIT, {id: id});
  }

  inactivateWorkflow(id: number) {
    this.workflowService.inactivate({id: id})
      .subscribe((result: EmptyMessage) => {
        this.loadList();
      });
  }

  activateWorkflow(id: number) {
    this.workflowService.activate({id: id})
      .subscribe((result: EmptyMessage) => {
        this.loadList();
      });
  }

  isDraft(state: Workflow.VersionState): boolean {
    return state === Workflow.VersionState.DRAFT;
  }

  isInactive(state: Workflow.VersionState): boolean {
    return state === Workflow.VersionState.INACTIVE;
  }
}
