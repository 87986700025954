import {Component, OnInit} from '@angular/core';
import {TaskStatistics, TaskStatisticsService} from '../../../lib/statistics/task-statistics/task-statistics.service';
import {Map} from 'immutable';
import {UIRouter} from '@uirouter/angular';
import {BugReportSearchService} from '../../../lib/helpdesk/bug-report/bug-report-search-service';
import {StateName} from '../../../app.state-names';
import {HelpdeskState} from "../../../lib/statistics/task-statistics/helpdesk.state";

@Component({
  selector: 'app-helpdesk-dashboard-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss']
})
export class HelpdeskDashboardStatsComponent implements OnInit {

  stats: Stat[] = [
    {
      key: 'sum',
      title: 'HELPDESK_DASHBOARD_STATS_SUM',
      icon: 'icomoon-helpdesk-bug-reported',
      color: 'blue',
      states: []
    },
    {
      key: 'done',
      title: 'HELPDESK_DASHBOARD_STATS_DONE',
      icon: 'icomoon-helpdesk-bug-done',
      color: 'green',
      states: [HelpdeskState.State.DONE]
    },
    {
      key: 'waitingForWork',
      title: 'HELPDESK_DASHBOARD_STATS_WAITING_FOR_WORK',
      icon: 'icomoon-helpdesk-bug-todo',
      color: 'none',
      states: [HelpdeskState.State.NEW, HelpdeskState.State.IN_PROGRESS]
    },
    {
      key: 'waitingForIntervention',
      title: 'HELPDESK_DASHBOARD_STATS_WAITING_FOR_INTERVENTION',
      icon: 'icomoon-helpdesk-bug-intervention',
      color: 'yellow',
      states: [HelpdeskState.State.WAITING]
    }
  ];
  model: Model = new Model();

  constructor(
    private taskStatisticsService: TaskStatisticsService,
    private bugReportSearchService: BugReportSearchService,
    private router: UIRouter
  ) { }

  ngOnInit(): void {
    this.loadStats();
  }

  private loadStats(): void {
    this.taskStatisticsService.getHelpdeskStat().subscribe(result => {
      this.model.done = this.sumFromMap(result, HelpdeskState.State.DONE);
      this.model.waitingForIntervention = this.sumFromMap(result, HelpdeskState.State.WAITING);
      this.model.waitingForWork = this.sumFromMap(result, HelpdeskState.State.NEW, HelpdeskState.State.IN_PROGRESS);
      this.model.sum = this.model.done + this.model.waitingForIntervention + this.model.waitingForWork;
    });
  }

  private sumFromMap(map: Map<string, number>, ...states: HelpdeskState.State[]): number {
    let sum = 0;
    states.forEach(s => {
      const count = map[s];
      if (count) {
        sum += count;
      }
    });
    return sum;
  }

  navigateToBugReportList(states: HelpdeskState.State[]) {
    this.bugReportSearchService.setStates(states).subscribe(result => {
      this.router.stateService.go(StateName.HELPDESK_BUG_REPORT_LIST);
    });
  }

}

class Model {
  sum: number = 0;
  done: number = 0;
  waitingForIntervention: number = 0; // -.-
  waitingForWork: number = 0; // -.-
}

interface Stat {
  key: string;
  title: string;
  icon: string;
  color: string;
  states: HelpdeskState.State[];
}
