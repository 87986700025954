import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StockItemModel } from '../../../admin/form/form-record/fields/stock/form-record-stock-field.component';

@Component({
  selector: 'app-material-attachment-name-edit',
  templateUrl: './material-attachment-name-edit.component.html',
  styleUrls: ['./material-attachment-name-edit.component.scss']
})
export class MaterialAttachmentNameEditComponent implements OnInit {

  @Input()
  currentFileName: string;
  @Input()
  fileExtension: string;
  @Output()
  onCloseButtonClicked: EventEmitter<string | undefined> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  close() {
    this.onCloseButtonClicked.emit();
  }

  save() {
    this.onCloseButtonClicked.emit(this.currentFileName);
  }
}
