/* eslint-disable */
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FileUploadComponent, UploadResponse } from '../flat/file-upload.component';
import { UiConstants } from '../../../util/core-utils';
import { UploadMimeType } from '../upload-mime-type';
/* eslint-enable */

@Component({
  selector: 'app-file-upload-dialog',
  templateUrl: './file-upload-dialog.component.html',
  styleUrls: ['./file-upload-dialog.component.scss']
})
export class FileUploadDialogComponent {
  UiConstants = UiConstants;

  _uploadPath: string;

  @Input()
  acceptedMimeType?: UploadMimeType;

  @Output()
  onResult: EventEmitter<boolean> = new EventEmitter();

  @Output()
  responseContent: EventEmitter<UploadResponse> = new EventEmitter();

  visible: boolean = false;

  @Input()
  titleKey: string = 'COMMON_IMPORT_TITLE';

  @ViewChild('uploadDialog', { static: true }) uploadDialog: ModalDirective;

  @ViewChild('uploadComponent') uploadComponent: FileUploadComponent;

  constructor() {

  }

  get uploadPath() {
    return this._uploadPath;
  }

  @Input()
  set uploadPath(path: string) {
    this._uploadPath = path;
  }

  public showDialog() {
    this.uploadDialog.show();
    this.visible = true;
  }

  public closeDialog() {
    this.uploadComponent.uploader.cancelAll();
    this.uploadComponent.uploader.clearQueue();
    this.uploadDialog.hide();
    this.visible = false;
  }

  public toggleDialog() {
    if (this.visible) {
      this.closeDialog();
    }
    else {
      this.showDialog();
    }
  }

}
