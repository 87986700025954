<span class="select-none cursor-pointer text-nowrap d-flex">
  <app-animated-checkbox
    *ngIf="checkAllEnabled"
    [selected]="checkAllSelected"
    (clickEvent)="onCheckAllClicked.emit()">
  </app-animated-checkbox>
  <span (click)="onOrderChanged()">
  <span class="table-sorter-item">{{text}}</span>
  <span class="table-sorter-item icomoon" [ngClass]="{ 'icomoon-sort-ascending-alt': isAsc(), 'icomoon-sort-descending-alt': isDesc(), 'icomoon-sort-nosort-alt': isNone() }"></span>
  </span>
</span>
