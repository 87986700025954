/* eslint-disable */
import { Component } from '@angular/core';
import {
  FormRecordFieldContext,
  FormRecordFieldValueUpdateArgs,
  FormRecordFieldView,
  FormRecordFieldViewContext,
  FormRecordFieldViewModel,
  SetTmpReadonlyArgs,
  SetTmpReadonlyResult
} from '../../../../../../util/form/form-utils';
import { Form } from '../../../../../../lib/form/form.service';
import { Command } from '../../../../../../util/command';
import { DownloadedFile } from '../../../../../../lib/util/downloaded-files';
import { DocumentFileService } from '../../../../../../lib/document/document-file.service';
import { noOpCommand } from '../../../manager/form-record-inactivity-manager';
import { Observable } from 'rxjs';

/* eslint-enable */

@Component({
  selector: 'app-form-record-readonly-picture-field',
  templateUrl: './form-record-readonly-picture-field.component.html',
  styleUrls: ['./form-record-readonly-picture-field.component.scss']
})
export class FormRecordReadonlyPictureFieldComponent implements FormRecordFieldView {

  public readonly selector: Form.FieldDataTypeSelector.READONLY_PICTURE;

  formRecordFieldContext?: FormRecordFieldContext;

  model: Model = new Model();
  tmpReadonly: boolean = false;

  loadingFinished = false;
  failedToLoadImage = false;

  constructor(private fileDocumentService: DocumentFileService) {
  }

  setTmpReadonly(args: SetTmpReadonlyArgs): Command<SetTmpReadonlyResult> {
    return noOpCommand;
  }

  registerField(context: FormRecordFieldContext, originalModel?: any): any {
    if (originalModel) {
      this.model = originalModel;
    }
    this.formRecordFieldContext = context;
    const attrs = context.field.dataType.readonlyPictureAttributes!;
    this.loadImage(attrs.value);
    return this.model;
  }

  loadImage(fileDocumentId: number) {
    this.fileDocumentService.downloadContent(fileDocumentId).subscribe(
      (res: DownloadedFile) => {
        this.convertBlobToBase64(res.getBlob());
      }, () => {
        this.loadingFinished = true;
        this.failedToLoadImage = true;
      }
    );
  }

  convertBlobToBase64(blob: Blob) {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      this.model.imageBase64Data = reader.result + '';
      this.loadingFinished = true;
    };
  }


  registerFieldViews(context: FormRecordFieldViewContext): void {
  }

  hasLocalFieldError(): boolean {
    return false;
  }

  validateWithInterrupt(): boolean {
    return false;
  }

  shouldNotifyAfterCreation(): boolean {
    return false;
  }

  afterFormRecordCreation(formRecordId: number): Observable<any> | undefined {
    return undefined;
  }

  createModel(): FormRecordFieldViewModel | undefined {
    return undefined;
  }

  updateValue(data: FormRecordFieldValueUpdateArgs) {
  }

}

export class Model {
  imageBase64Data: string;
}
