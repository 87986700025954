/* eslint-disable */
import { Injectable } from '@angular/core';
import { MultiselectOptionItem, UiConstants } from '../../util/core-utils';
import { Order, OrderType } from '../util/services';
import { DisabledEnum, SearchUtils } from '../../util/search-utils';
import { BaseSearch, BaseSearchService } from '../base.search-service';
import { StockRecord } from './stock-record.service';
import { StockItemType } from './stock-item.service';
import { LoggedInUserStorage } from '../util/storages';

/* eslint-enable */

@Injectable()
export class StockStockRecordSearchService extends BaseSearchService {

  protected createStorage(): StockStockRecordSearch.Storage {
    return new StockStockRecordSearch.Storage();
  }

}

export namespace StockStockRecordSearch {

  export class Model extends SearchUtils.SearchModel {

    stockItemName: string = '';
    stockItemExternalId: string = '';
    stockItemProductCode: string = '';
    stockItemType: StockItemType | null = null;
    stockItemSerialCode: string = '';
    stockItemCategories: MultiselectOptionItem<number>[] = [];
    amountFrom: string = '';
    amountTo: string = '';
    disabled?: DisabledEnum = DisabledEnum.FALSE;
    lotModeOn: boolean = false;

    isEmpty(): boolean {
      return this.stockItemName === ''
        && this.stockItemExternalId === ''
        && this.stockItemType === null
        && this.stockItemSerialCode === ''
        && this.stockItemCategories.length === 0
        && this.amountFrom === ''
        && this.amountTo === ''
        && this.disabled === DisabledEnum.FALSE;
    }

    reset() {
      this.stockItemName = '';
      this.stockItemExternalId = '';
      this.stockItemProductCode = '';
      this.stockItemType = null;
      this.stockItemSerialCode = '';
      this.stockItemCategories = [];
      this.amountFrom = '';
      this.amountTo = '';
      this.disabled = DisabledEnum.FALSE;
      this.lotModeOn = false;
    }

  }

  export interface SearchDataGetRequest extends BaseSearch.SearchDataGetRequest {
    stockId: number;
  }

  export interface SearchDataResult {
    searchData: SearchData;
  }

  export interface SearchDataSetRequest extends BaseSearch.SearchDataSetRequest {
    stockId: number;
  }

  export interface SearchData extends BaseSearch.SearchData {
    order: Order<StockRecord.OrderField>;
    stockItemName: string;
    stockItemExternalId;
    stockItemProductCode;
    stockItemType: StockItemType | null;
    stockItemSerialCode: string;
    stockItemCategories: MultiselectOptionItem<number>[];
    amountFrom: string;
    amountTo: string;
    disabled?: DisabledEnum;
  }

  interface StoredSearchRawData extends BaseSearch.StoredSearchRawData {
    order: Order<StockRecord.OrderField>;
    stockItemName: string;
    stockItemExternalId;
    stockItemProductCode;
    stockItemType: StockItemType | null;
    stockItemSerialCode: string;
    stockItemCategories: MultiselectOptionItem<number>[];
    amountFrom: string;
    amountTo: string;
    disabled?: DisabledEnum;
  }

  export class Storage extends BaseSearch.Storage {

    constructor() {
      const defaultSearchData = {
        itemsPerPage: UiConstants.pageNumbers[0],
        pageNumber: 1,
        order: {
          field: StockRecord.OrderField.ID,
          type: OrderType.DESC
        },
        stockItemName: '',
        stockItemExternalId: '',
        stockItemProductCode: '',
        stockItemType: null,
        stockItemSerialCode: '',
        stockItemCategories: [],
        amountFrom: '',
        amountTo: '',
        disabled: DisabledEnum.FALSE
      };
      /**
       * Increment this version if you change the SearchData interface.
       */
      const versionNumber: number = 3;
      super(defaultSearchData,
        versionNumber);
    }

    protected generateKey(request: SearchDataGetRequest | SearchDataSetRequest): string {
      return 'UserId(' + LoggedInUserStorage.getInstance().getUserId() + ')-Search-StockStockRecord-StockId(' + request.stockId + ')';
    }

    protected fromRaw(data: StoredSearchRawData): BaseSearch.SearchDataResult {
      const searchData: SearchData = {
        itemsPerPage: data.itemsPerPage,
        pageNumber: data.pageNumber,
        order: data.order,
        stockItemName: data.stockItemName,
        stockItemExternalId: data.stockItemExternalId,
        stockItemProductCode: data.stockItemProductCode,
        stockItemSerialCode: data.stockItemSerialCode,
        stockItemType: data.stockItemType,
        stockItemCategories: data.stockItemCategories,
        amountFrom: data.amountFrom,
        amountTo: data.amountTo,
        disabled: data.disabled,
      };
      return {
        searchData: searchData
      };
    }

    protected toRaw(data: SearchData): StoredSearchRawData {
      return {
        version: this.VERSION,
        itemsPerPage: data.itemsPerPage,
        pageNumber: data.pageNumber,
        order: data.order,
        stockItemName: data.stockItemName,
        stockItemExternalId: data.stockItemExternalId,
        stockItemProductCode: data.stockItemProductCode,
        stockItemSerialCode: data.stockItemSerialCode,
        stockItemType: data.stockItemType,
        stockItemCategories: data.stockItemCategories,
        amountFrom: data.amountFrom,
        amountTo: data.amountTo,
        disabled: data.disabled,
      };
    }

  }

}
