<app-loading-spinner *ngIf="loading"></app-loading-spinner>
<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
    <div class="breadcrumb-menu d-flex">
      <div *ngIf="rightModel.documentFileUpdate.hasRight()"
           class="breadcrumb-button-icon-container cursor-pointer negative-margin-right"
           (click)="delete()">
        <a class="btn-setting d-flex align-items-center"
           title="{{'COMMON_BUTTON_DELETE' | translate}}">
          <i class="icomoon icomoon-trash"></i>
          <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_DELETE' | translate}}</span>
        </a>
      </div>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>

<div class="container-horizontal-padding container-vertical-padding animated fadeIn" *ngIf="!loading">
  <div class="col-md-8 col-sm-12 px-0">
    <div class="card">
      <div class="card-header">
        {{'DOCUMENT_PANEL_HEADING_EDIT'|translate}}
      </div>
      <div class="card-body">

        <form class="form-horizontal bordered-row" (ngSubmit)="f.form.valid && update()" #f="ngForm" novalidate>

          <!--NAME-->
          <div class="form-group row"  [ngClass]="{ 'has-danger': (f.submitted && !name.valid) }">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label required-field-label">
                {{'COMMON_NAME' | translate}}
              </label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <input
                  type="text"
                  class="form-control"
                  maxlength="{{getTextMaximumLength()}}"
                  placeholder="{{'COMMON_NAME' | translate}}"
                  [(ngModel)]="model.name"
                  [ngClass]="{ 'form-control-danger': (f.submitted && !name.valid) }"
                  name="name"
                  id="name"
                  #name="ngModel"
                  required>
                <div class="form-control-feedback" *ngIf="f.submitted && !name.valid">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
              </div>
            </div>
            <!-- /.col-md-4 -->
          </div>

          <!--EXTERNAL ID-->
          <div class="form-group row"  [ngClass]="{ 'has-danger': fieldErrors.external_id || (f.submitted && !external_id.valid) }">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label required-field-label">
                {{'COMMON_EXTERNAL_ID' | translate}}
              </label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <input
                  type="text"
                  class="form-control"
                  maxlength="{{getTextMaximumLength()}}"
                  placeholder="{{'COMMON_EXTERNAL_ID' | translate}}"
                  [(ngModel)]="model.external_id"
                  (ngModelChange)="removeFieldError(fieldErrors.external_id)"
                  [ngClass]="{ 'form-control-danger': fieldErrors.external_id || (f.submitted && !external_id.valid) }"
                  name="external_id"
                  id="external_id"
                  #external_id="ngModel"
                  required>
                <div class="form-control-feedback" *ngIf="f.submitted && !external_id.valid">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                <div class="form-control-feedback" *ngIf="fieldErrors.external_id">{{fieldErrors.external_id.text}}</div>
              </div>
            </div>
            <!-- /.col-md-4 -->
          </div>

          <!--DESCRIPTION-->
          <div class="form-group row">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label">{{'COMMON_DESCRIPTION' | translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <input
                  type="text"
                  class="form-control"
                  maxlength="{{getTextMaximumLength()}}"
                  placeholder="{{'COMMON_DESCRIPTION' | translate}}"
                  [(ngModel)]="model.description"
                  name="description"
                  id="description"
                  #description="ngModel">
              </div>
            </div>
          </div>

          <!--GROUP-->
          <div class="form-group row"
               [ngClass]="{ 'has-danger': f.submitted && !documentGroup.valid }">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label">{{'DOCUMENT_GROUP' | translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  [(ngModel)]="model.document_group"
                  [data]="documentGroupList"
                  [settings]="dropdownSettings"
                  (onRemoteSearch)="loadDocumentGroupList($event.target.value)"
                  name="documentGroup"
                  id="documentGroup"
                  #documentGroup="ngModel"
                  validateEnabledItems>
                </angular2-multiselect>
                <div class="form-control-feedback" *ngIf="f.submitted && !documentGroup.valid">
                  {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
                </div>
              </div>
            </div>
          </div>

          <!--SEND BUTTON-->
          <div>
            <div *ngIf="rightModel.documentFileUpdate.hasRight()" class="d-flex justify-content-end">
              <input type="submit" class="btn btn-primary" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
            </div>
          </div>
        </form>
      </div>  <!-- ./card-body end -->
    </div> <!--./card-->
  </div> <!--./container-->

  <div class="container-vertical-padding-half"></div>

  <div class="col-md-8 col-sm-12 px-0">
    <div class="card">
      <div class="card-header cursor-pointer">
        {{'DOCUMENT_PANEL_HEADING_FILES' | translate}}
      </div>

      <div class="card-body" *ngIf="showFiles">

        <!--FILE-->
        <div class="row" *ngIf="!ctp || !ctp.isImageType">
          <label class="form-control-label col-md-4 col-form-label"> {{ 'DOCUMENT_FILE_NAME' | translate }} </label>
          <div class="input-group col-md-8">
            <span *ngIf="rightModel.documentFileFileDownload.hasRight()" class="input-group-btn" style="margin-left: 12px">
            <button class="btn table-header-action-button cursor-pointer file-action-button" type="submit" (click)="downloadFile()"
                    title="{{'COMMON_BUTTON_DOWNLOAD' | translate}}"
            ><i class="icomoon icomoon-download"></i></button>
          </span>
            <span class="input-group-btn" style="margin-left: 5px">
            <button class="btn table-header-action-button cursor-pointer file-action-button" type="submit" (click)="editFile()"
                    title="{{'COMMON_BUTTON_EDIT' | translate}}"><i class="icomoon icomoon-modify"></i></button>
          </span>
          </div>
        </div>

        <div class="container-vertical-padding-half"></div>

        <div class="row mb-1" *ngIf="ctp && ctp.isImageType">
          <label class="form-control-label col-md-4 col-form-label"> {{ 'DOCUMENT_FILE_NAME' | translate }} </label>
          <div class="input-group col-md-8">
            <div class="thumbnail-container">
              <div class="thumbnail-background" *ngIf="imageLoaded" [style.background-image]="imageSrc"></div>
              <div class="thumbnail-background-placeholder" *ngIf="!imageLoaded"></div>
              <div class="thumbnail-action-buttons d-flex flex-row justify-content-end">
                <div class="thumbnail-action-button cursor-pointer" (click)="downloadImage()"
                     title="{{'COMMON_BUTTON_COLLAPSE_SHOW' | translate}}">
                  <i class="icomoon icomoon-enable">
                  </i>
                </div>
                <div *ngIf="rightModel.documentFileFileDownload.hasRight()"
                     class="thumbnail-action-button cursor-pointer" (click)="downloadFile()"
                     title="{{'COMMON_BUTTON_DOWNLOAD' | translate}}">
                  <i class="icomoon icomoon-download">
                  </i>
                </div>
                <div class="thumbnail-action-button cursor-pointer" (click)="editFile()"
                     title="{{'COMMON_BUTTON_EDIT' | translate}}">
                  <i class="icomoon icomoon-modify">
                  </i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--THUMBNAIL-->

        <div class="row" *ngIf="!model.has_thumbnail">
          <label class="form-control-label col-md-4 col-form-label"> {{ 'DOCUMENT_THUMBNAIL_MODIFICATION' | translate }} </label>
          <div class="col-md-8">
            <app-file-upload
              [uploadPath]="''"
              [uploadOnDrop]="false"
              [acceptedMimeType]="UploadMimeType.IMAGE"
              (onFileDropped)="thumbnailUploadDialog.onImageSelected($event)">
            </app-file-upload>
          </div>
        </div>

        <div class="row" *ngIf="model.has_thumbnail">
          <label class="form-control-label col-md-4 col-form-label"> {{ 'DOCUMENT_THUMBNAIL_MODIFICATION' | translate }} </label>
          <div class="input-group col-md-8">
            <div class="thumbnail-container">
              <div class="thumbnail-background" *ngIf="thumbnailLoaded" [style.background-image]="thumbnailSrc"></div>
              <div class="thumbnail-background-placeholder" *ngIf="!thumbnailLoaded"></div>
              <div class="thumbnail-action-buttons d-flex flex-row justify-content-end">
                <div class="thumbnail-action-button cursor-pointer" (click)="downloadThumbnail()"
                     title="{{'COMMON_BUTTON_COLLAPSE_SHOW' | translate}}">
                  <i class="icomoon icomoon-enable">
                  </i>
                </div>
                <div class="thumbnail-action-button cursor-pointer" (click)="downloadThumbnailAsFile()"
                     title="{{'COMMON_BUTTON_DOWNLOAD' | translate}}">
                  <i class="icomoon icomoon-download">
                  </i>
                </div>
                <div class="thumbnail-action-button cursor-pointer" (click)="deleteThumbnail()"
                     title="{{'COMMON_BUTTON_DELETE' | translate}}">
                  <i class="icomoon icomoon-trash">
                  </i>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div><!-- /.card-body-->
    </div><!-- /.card --> <!--./FILE AND THUMBNAIL-->
  </div>
  <!-- /.div -->
  <!--FILE AND THUMBNAIL-->
</div>

<!--THUMBNAIL DIALOG-->
<div bsModal [config]="UiConstants.modalConfig" #thumbnailDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title"> {{ 'COMMON_THUMBNAIL_DIALOG_TITLE' | translate }} </h4>
        <button type="button" class="close" (click)="thumbnailDialog.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="align-middle">
          <img class="img-responsive" style="width: 100%;"
               [src]="sanitizer.bypassSecurityTrustUrl(thumbnailToShow)"
               alt="Place image title"
               *ngIf="thumbnailToShow">
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="thumbnailDialog.hide()"> {{ 'COMMON_BUTTON_CLOSE' | translate }} </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div> <!-- /.modal --> <!--./THUMBNAIL DIALOG-->

<!--IMAGE DIALOG-->
<div bsModal [config]="UiConstants.modalConfig" #imageDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title"> {{ 'COMMON_IMAGE_DIALOG_TITLE' | translate }} </h4>
        <button type="button" class="close" (click)="imageDialog.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="align-middle">
          <img class="img-responsive" style="width: 100%;"
               [src]="sanitizer.bypassSecurityTrustUrl(imageToShow)"
               alt="Place image title"
               *ngIf="imageToShow">
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="imageDialog.hide()"> {{ 'COMMON_BUTTON_CLOSE' | translate }} </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div> <!-- /.modal --> <!--./THUMBNAIL DIALOG-->

<!--FILE EDIT DIALOG-->
<div bsModal [config]="UiConstants.modalConfig" #fileEditDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title"> {{ 'DOCUMENT_FILE_EDIT_DIALOG_TITLE' | translate }} </h4>
        <button type="button" class="close" (click)="hideFileEditDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-file-upload
          #fileEditUploadComponent
          [uploadPath]="fileUploadPath"
          (onResult)="$event && loadModel()">
        </app-file-upload>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="hideFileEditDialog()"> {{ 'COMMON_BUTTON_CLOSE' | translate }} </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div> <!-- /.modal --> <!--./FILE EDIT DIALOG-->

<app-cropped-image-uploader-dialog
  #thumbnailUploadDialog
  [titleKey]="'DOCUMENT_GROUP_UPLOAD_THUMBNAIL'"
  [fixedRatioKey]="'DOCUMENT_GROUP_DOCUMENT_LIBRARY_ASPECT_RATIO'"
  [fixedRatioSwitchEnabled]="true"
  [fileName]="'file-document-' + documentId + '-thumbnail.png'"
  [uploadPath]="thumbnailUploadPath"
  [aspectRatio]="1 / 1"
  (onImageUploaded)="onThumbnailUploaded()">
</app-cropped-image-uploader-dialog>
