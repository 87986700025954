/* eslint-disable */
import { Directive, ViewContainerRef } from '@angular/core';
/* eslint-enable */

/* eslint-disable */
@Directive({
  selector: '[app-form-edit-dialog-holder]',
})
/* eslint-enable */
export class FormEditDialogHolderDirective {
  constructor(public viewContainerRef: ViewContainerRef) { }
}
