/* eslint-disable */
import { FormRecordFieldView, FormRecordFieldViewFactory } from './form-utils';
import { ComponentFactoryResolver, ComponentRef, Injector } from '@angular/core';
import { FormRecordProcessOrderPackagingFieldComponent } from '../../admin/form/form-record/fields/process-order-packaging/form-record-process-order-packaging-field.component';
/* eslint-enable */

export class FormRecordProcessOrderPackagingFieldViewFactory implements FormRecordFieldViewFactory {

  constructor(
    private injector: Injector,
    private cfr: ComponentFactoryResolver) {
  }

  createView(): ComponentRef<FormRecordFieldView> {
    const cmp = this.cfr.resolveComponentFactory(FormRecordProcessOrderPackagingFieldComponent)
      .create(this.injector);
    return cmp;
  }

}
