import {Models} from "./model-utils";

export class Weight {

  public static zero(unit?: string): Weight {
    return new Weight(unit ? unit : 'g', 0);
  }

  constructor(
    public readonly unit: string,
    public readonly value: number) {
  }

  public toString(precision?: number): string {
    return Models.decimalToFormattedString(this.round(this.value, precision)) + ' ' + this.unit;
  }

  public toGrams(): number {
    switch (this.unit) {
      case 'g':
        return this.value;
      case 'dkg':
        return this.value * 10;
      case 'kg':
        return this.value * 1000;
      case 't':
        return this.value * 1000 * 1000;
      default:
        throw new Error('Invalid weight unit: ' + this.unit);
    }
  }

  private round(value, precision?: number): number {
    const multiplier = Math.pow(10, precision ? precision : 2);
    return Math.round(value * multiplier) / multiplier;
  }

}

export class WeightFactory {

  public static createWeightFromGram(weight: number | string, unit?: string): Weight {
    let wNumber = 0;
    if (typeof weight === 'string') {
      wNumber = Models.decimalToNumber(Models.parseDecimal(weight))!;
    } else {
      wNumber = weight;
    }
    if (wNumber <= 0) {
      return Weight.zero(unit);
    }
    if (unit) {
      switch (unit) {
        case 'g':
          return new Weight(unit, wNumber);
        case 'dkg':
          return new Weight(unit, wNumber / 10);
        case 'kg':
          return new Weight(unit, wNumber / 1000);
        case 't':
          return new Weight(unit, wNumber / 1000 / 1000);
        default:
          throw new Error('Invalid wNumber unit: ' + unit);
      }
    }
    return this.gramToWeight(wNumber);
  }

  public static createWeightFromUnit(weight: number | string, unit: string): Weight {
    let wNumber = 0;
    if (typeof weight === 'string') {
      wNumber = Models.decimalToNumber(Models.parseDecimal(weight))!;
    } else {
      wNumber = weight;
    }
    if (wNumber <= 0) {
      return Weight.zero(unit);
    }
    if (unit) {
      switch (unit) {
        case 'g':
          break;
        case 'dkg':
          wNumber = wNumber * 10;
          break;
        case 'kg':
          wNumber = wNumber * 1000;
          break;
        case 't':
          wNumber = wNumber * 1000 * 1000;
          break;
        default:
          throw new Error('Invalid wNumber unit: ' + unit);
      }
    }
    return this.gramToWeight(wNumber);
  }


  public static getOptions(): string[] {
    return ['g', 'dkg', 'kg', 't'];
  }

  private static gramToWeight(wNumber: number) {
    if (wNumber < 100) {
      return new Weight('g', wNumber);
    }
    if (wNumber < 1000) {
      return new Weight('dkg', wNumber / 10);
    }
    if (wNumber < 1000 * 1000) {
      return new Weight('kg', wNumber / 1000);
    }
    return new Weight('t', wNumber / 1000 / 1000);
  }
}
