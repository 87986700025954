import { Observable, Observer } from 'rxjs';
import { PagingRequest, QueryResult, ResourceQueryResult, Services } from '../../util/services';
import { EmptyMessage, IdentityMessage } from '../../util/messages';
import { List, Set, Map } from 'immutable';
import { OffsetDateTime } from '../../util/dates';
import { DqlQuery, Query } from '../../query/field';
import { FilterField } from '../../query/filterfields';
import { OrderField } from '../../query/orderfields';
import {
  ExteriorTransportDocumentResource,
  ExteriorTransportDocumentResourceService
} from './exterior-transport-document-resource.service';
import { Injectable } from '@angular/core';
import { DownloadedFile } from '../../util/downloaded-files';
import { OrderDocument } from '../../order-document/order-document.service';

export namespace ExteriorTransportDocument {

  import UUIDMessage = OrderDocument.UUIDMessage;

  export interface Service {
    query(request: QueryRequest): Observable<QueryResult<ExteriorTransportDocument>>;
    get(request: GetRequest): Observable<ExteriorTransportDocument>;
    update(request: UpdateRequest): Observable<EmptyMessage>;
    delete(request: DeleteRequest): Observable<EmptyMessage>;
    download(request: DownloadRequest): Observable<DownloadedFile>;
  }

  export interface ExteriorTransportDocument {
    id: string;
    creationTime: OffsetDateTime;
    serial: string;
    uploaderUserId?: number;
    originalFileName: string;
    contentHash: string;
    contentSize: number;
    contentType: string;
    meta: Meta;
  }

  export interface Meta {
    type: ExteriorTransportDocumentType;
    comment?: string;
    updateTime: OffsetDateTime;
    version: number;
  }

  export type ExteriorTransportDocumentType = 'TRANSPORT_SUMMARY' | 'WAYBILL' | 'OTHER';

  export interface ExteriorTransportDocumentTypeObject {
    type: ExteriorTransportDocumentType;
    stringKey: string;
  }

  export const exteriorTransportDocumentTypes: ExteriorTransportDocumentTypeObject[] = [
    {type: 'TRANSPORT_SUMMARY', stringKey: 'EXTERIOR_TRANSPORT_DOCUMENT_TYPE_TRANSPORT_SUMMARY'},
    {type: 'WAYBILL', stringKey: 'EXTERIOR_TRANSPORT_DOCUMENT_TYPE_WAYBILL'},
    {type: 'OTHER', stringKey: 'EXTERIOR_TRANSPORT_DOCUMENT_TYPE_OTHER'}
  ];

  export interface QueryRequest {
    exteriorTransportId: number;
    fields?: Query.FieldFunction<Fields.ExteriorTransportDocument>;
    filter?: Query.FilterFunction<FilterField.ExteriorTransportDocument>;
    order?: Query.OrderFunction<OrderField.ExteriorTransportDocument>;
    paging?: PagingRequest;
    rights?: Set<string>;
  }

  export interface GetRequest extends UUIDMessage {
    exteriorTransportId: number;
  }

  export interface UpdateRequest extends UUIDMessage {
    exteriorTransportId: number;
    version: number;
    type: ExteriorTransportDocumentType;
    comment?: string;
  }

  export interface DeleteRequest extends UUIDMessage {
    exteriorTransportId: number;
  }

  export interface DownloadRequest extends UUIDMessage {
    exteriorTransportId: number;
  }

  export function toPublicExteriorTransportDocument(r: ExteriorTransportDocumentResource.ExteriorTransportDocument):
    ExteriorTransportDocument.ExteriorTransportDocument {
    return {
      id: r.id,
      creationTime: Services.toOffsetDateTime(r.creation_time),
      serial: r.serial,
      uploaderUserId: r.uploader_user_id,
      originalFileName: r.original_file_name,
      contentHash: r.content_hash,
      contentSize: r.content_size,
      contentType: r.content_type,
      meta: this.toPublicExteriorTransportDocumentMeta(r.meta)
    }
  }

  export function toPublicExteriorTransportDocumentMeta(r: ExteriorTransportDocumentResource.Meta): ExteriorTransportDocument.Meta {
    return {
      type: r.type,
      comment: r.comment,
      updateTime: Services.toOffsetDateTime(r.update_time),
      version: r.version
    }
  }

  export function toResourceUpdateRequest(request: ExteriorTransportDocument.UpdateRequest):
    ExteriorTransportDocumentResource.UpdateRequest {
    return {
      exterior_transport_id: request.exteriorTransportId,
      id: request.id,
      version: request.version,
      type: request.type,
      comment: request.comment
    }
  }

  export function toResourceDeleteRequest(request: ExteriorTransportDocument.DeleteRequest):
    ExteriorTransportDocumentResource.DeleteRequest {
    return {
      exterior_transport_id: request.exteriorTransportId,
      id: request.id
    }
  }

  export namespace Fields {

    // Each class should be an interface, but it is easier to define the constant fields in the constructors.
    // For now we have only one query language so it is not a problem.
    // Always use the public Query namespace for variable definitions so we will be able to introduce other language easily.

    export class ExteriorTransportDocument {

      readonly id: Query.Field = new DqlQuery.Field('id');
      readonly creationTime: Query.Field = new DqlQuery.Field('creation_time');
      readonly originalFileName: Query.Field = new DqlQuery.Field('original_file_name');
      readonly contentHash: Query.Field = new DqlQuery.Field('content_hash');
      readonly contentSize: Query.Field = new DqlQuery.Field('content_size');
      readonly contentType: Query.Field = new DqlQuery.Field('content_type');
      readonly meta: Query.Field = new DqlQuery.Field('meta');
      readonly serial: Query.Field = new DqlQuery.Field('serial');
      readonly uploaderUserId: Query.Field = new DqlQuery.Field('uploader_user_id');

      get each(): Set<Query.Field> {
        return Set.of(
          this.id, this.creationTime,
          this.originalFileName, this.contentHash, this.contentSize,
          this.contentType, this.meta, this.serial,
          this.uploaderUserId
        );
      }

    }

  }

}

@Injectable()
export class ExteriorTransportDocumentService implements ExteriorTransportDocument.Service {

  private readonly filterField = new FilterField.ExteriorTransportDocument();
  private readonly orderField = new OrderField.ExteriorTransportDocument();
  private readonly fields = new ExteriorTransportDocument.Fields.ExteriorTransportDocument();

  constructor(private resourceService: ExteriorTransportDocumentResourceService) {
  }

  query(request: ExteriorTransportDocument.QueryRequest): Observable<QueryResult<ExteriorTransportDocument.ExteriorTransportDocument>> {
    const filter: string | undefined = DqlQuery.toOptionalFilter(this.filterField, request.filter);
    const order: string | undefined = DqlQuery.toOptionalOrder(this.orderField, request.order);
    const fields: string | undefined = DqlQuery.toOptionalFields(this.fields, request.fields);
    return Observable.create((observer: Observer<QueryResult<ExteriorTransportDocument.ExteriorTransportDocument>>) => {
      const resourceRequest: ExteriorTransportDocumentResource.QueryRequest = {
        exterior_transport_id: request.exteriorTransportId,
        filter: filter,
        order: order,
        fields: fields,
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined
      };
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<ExteriorTransportDocumentResource.ExteriorTransportDocument>) => {
          observer.next({
            items: List.of(...result.items.map((item) => ExteriorTransportDocument.toPublicExteriorTransportDocument(item))),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  get(request: ExteriorTransportDocument.GetRequest): Observable<ExteriorTransportDocument.ExteriorTransportDocument> {
    return Observable.create((observer: Observer<ExteriorTransportDocument.ExteriorTransportDocument>) => {
      return this.resourceService.get({
        exterior_transport_id: request.exteriorTransportId,
        id: request.id
      }).subscribe(
        (result: ExteriorTransportDocumentResource.ExteriorTransportDocument) => {
          observer.next(ExteriorTransportDocument.toPublicExteriorTransportDocument(result));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  update(request: ExteriorTransportDocument.UpdateRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: ExteriorTransportDocumentResource.UpdateRequest = ExteriorTransportDocument.toResourceUpdateRequest(request);
      return this.resourceService.update(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  delete(request: ExteriorTransportDocument.DeleteRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: ExteriorTransportDocumentResource.DeleteRequest = ExteriorTransportDocument.toResourceDeleteRequest(request);
      return this.resourceService.delete(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  download(request: ExteriorTransportDocument.DownloadRequest): Observable<DownloadedFile> {
    return this.resourceService.download({
      exterior_transport_id: request.exteriorTransportId,
      id: request.id
    });
  }

}
