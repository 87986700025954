/* eslint-disable */
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Transition, UIRouter } from '@uirouter/angular';
import { TranslateService } from '@ngx-translate/core';
import { OptionItems, OwnerUserItem, OwnerUserItemFactory, UiConstants, } from '../../../util/core-utils';
import { UserData, UserDataLoader, UserDataLoaderPermissionDeniedStrategy } from '../../../lib/user-data-loader';
import { StateName } from '../../../app.state-names';
import { Arrays } from '../../../lib/util/arrays';
import { InputMask } from '../../../util/input-masks';
import { Stock, StockService } from '../../../lib/stock/stock.service';
import { StockEditModel, StockType, } from '../../../util/stock/stock-utils';
import { StockSubStockRecordService } from '../../../lib/stock/stock-sub-stock-record.service';
import { StockItemService } from '../../../lib/stock/stock-item.service';
import { StockRecordService } from '../../../lib/stock/stock-record.service';
import { RightModel } from '../../../app.rights';
import { RightResolver, RightService } from '../../../lib/right.service';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { Set } from 'immutable';
import { Models } from '../../../util/model-utils';
import { CompanyService } from '../../../lib/company/company.service';
import { DownloadedFile } from '../../../lib/util/downloaded-files';
import { saveAs } from 'file-saver';
import { StockLogListType } from '../../stock-log/stock-log-list/stock-log-list.component';
import { QueryResult } from '../../../lib/util/services';
import { Currency, CurrencyService } from '../../../lib/currency.service';
import {
  CustomerRecordContactLocationMultiselectProvider
} from '../../../lib/customer/contact/customer-record-contact-location-multiselect.provider';
import { CustomerRecordMultiselectProvider } from '../../../lib/customer/customer-record-multiselect-provider.service';

/* eslint-enable */

@Component({
  selector: 'app-stock-edit',
  templateUrl: 'stock-detail.component.html',
  styleUrls: ['stock-detail.component.scss']
})
export class StockDetailComponent implements OnInit, AfterViewInit {
  Models = Models;
  UiConstants = UiConstants;
  StockLogListType = StockLogListType;
  StockType = StockType;

  InputMask = InputMask;
  model: StockEditModel;
  stockId: number;
  stock: Stock;
  rightModel: RightModel = RightModel.empty();

  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  currencies: Currency.Currency[] = [];

  ngOnInit() {
    this.translateService.get('MENU_NAVBAR_STOCK').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.STOCK_LIST});
      }
    );
  }

  ngAfterViewInit() {
    this.loadRightModels(() => {
      this.loadModel();
    });
  }

  private loadRightModels(completion: () => void) {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        completion();
      }
    );
  }

  private loadModel() {
    const fields: string[] =
      ['id', 'name', 'owner_user_ids', 'company_id', 'customer_record_id', 'contact_location_id', 'central', 'waste', 'external_id', 'type'];
    this.stockService.get({
      id: this.stockId,
      with_sums: true,
      fields: fields.join(',')
    }).subscribe(stock => {
      this.breadcrumbSelf = stock.name;
      this.model.name = stock.name;
      this.model.externalId = stock.external_id;
      this.model.type = stock.type;
      this.stock = stock;
      this.model.waste = stock.waste;
      this.model.central = stock.central;
      this.model.sums = stock.stock_sums;
      this.loadOwnerUsers(stock.owner_user_ids);
      if (stock.company_id && this.rightModel.companyReadAll.hasRight()) {
        this.loadCompany(stock.company_id);
      }
      if (stock.customer_record_id && this.rightModel.customerRecordRead.hasRight()) {
        this.loadCustomerRecord(stock.customer_record_id);
      }
      if (stock.contact_location_id && this.rightModel.customerRecordRead.hasRight()) {
        this.loadContactLocation(stock.contact_location_id);
      }
    });
  }

  private loadCompany(company_id: number) {
    this.companyService.query({
      disabled: false,
      id: Set.of(company_id)
    }).subscribe(result => {
      Arrays.iterateByIndex(result.items.toArray(), c => {
        if (c) {
          const item = {
            id: c.id,
            itemName: c.name
          };
          if (company_id && item.id === company_id) {
            this.model.company[0] = item;
          }
        }
      });
    });
  }

  private loadCustomerRecord(customerRecordId: number) {
    this.customerRecordService.getById(customerRecordId).subscribe(result => {
      this.model.customerRecord = [result];
    });
  }

  private loadContactLocation(contactLocationId: number) {
    this.contactLocationService.getById(contactLocationId).subscribe(result => {
      this.model.contactLocation = [result];
    });
  }


  back() {
    window.history.back();
  }

  private loadOwnerUsers(ownerUserIds: number[]) {
    if (ownerUserIds.length > 0) {
      this.userDataLoader.loadAll(UserDataLoaderPermissionDeniedStrategy.MISS_ALL).subscribe(
        (users: UserData[]) => {
          const items = this.ownerUserItemFactory.createAll(users);
          const sortedOwnerUsers: OwnerUserItem[] = items;
          sortedOwnerUsers.sort(OptionItems.createNameComparator());
          Arrays.iterateByIndex(sortedOwnerUsers, (sortedItem) => {
            const item = new StockOwnerUserItem();
            item.id = sortedItem.id;
            item.itemName = sortedItem.text;
            ownerUserIds.forEach(id => {
              if (item.id === id) {
                this.model.owner_users.push(item);
              }
            });
          });
        }
      );
    }
  }

  getOwnerUsersString(): string {
    return this.model.owner_users.map((u) => u.itemName).join(', ');
  }

  exportXls() {
    this.stockService.exportXls(this.stockId).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName(this.model.name + '_export.xlsx'));
      }
    );
  }

  private loadCurrencies() {
    this.currencies = [];
    this.currencyService.query({}).subscribe((result: QueryResult<Currency.Currency>) => {
      result.items.forEach(c => {
        if (c) {
          this.currencies.push(c);
        }
      });
    });
  }

  getCurrencyName(currencyCode: string) {
    const cc = this.currencies.find(c => c.currencyCode === currencyCode);
    return cc ? cc.localizedName : currencyCode;
  }

  getCurrency(currencyCode: string) {
    const cc = this.currencies.find(c => c.currencyCode === currencyCode);
    return cc!;
  }


  constructor(private ownerUserItemFactory: OwnerUserItemFactory,
              private userDataLoader: UserDataLoader,
              private translateService: TranslateService,
              private stockItemService: StockItemService,
              private stockService: StockService,
              private stockSubStockRecordService: StockSubStockRecordService,
              private stockRecordService: StockRecordService,
              private rightService: RightService,
              private uiRouter: UIRouter,
              private transition: Transition,
              private currencyService: CurrencyService,
              private companyService: CompanyService,
              private customerRecordService: CustomerRecordMultiselectProvider,
              private contactLocationService: CustomerRecordContactLocationMultiselectProvider
  ) {
    this.model = new StockEditModel();
    this.model.type = StockType.DEFAULT.name;
    this.stockId = this.transition.params().id;
    this.loadCurrencies();
  }

}

export class StockOwnerUserItem {
  id: number | null;
  itemName: string;
}
