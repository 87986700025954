<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div class="breadcrumb-menu d-flex">
    <div *ngIf="!protectedUser && !isLdapUser() && componentState.isEditView()" (click)="showPasswordChangeDialog()"
         class="breadcrumb-button-icon-container negative-margin-right cursor-pointer">
      <a class="btn-setting" title="{{'USER_PANEL_HEADING_CHANGE_PASSWORD' | translate}}">
        <i class="icomoon icomoon-password icon-standard"></i>
        <span class="breadcrumb-button-text-visible">{{'USER_PANEL_HEADING_CHANGE_PASSWORD' | translate}}</span>
      </a>
    </div>
    <app-breadcrumb-button
      class="negative-margin-right"
      *ngIf="!this.model.isProtected && userRightModel.userUpdate.hasRightForUser(userForRightModel) && componentState.isDetailView()"
      [iconClass]="'icomoon-modify'"
      [titleStringKey]="'COMMON_BUTTON_EDIT'"
      uiSref="Admin.UserEdit"
      [uiParams]="{ id: componentState.id}">
    </app-breadcrumb-button>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding-half animated fadeIn">

  <responsive-tabset class="fullscreen_tab">
    <responsive-tab heading="{{'COMMON_CARD_HEADER_BASE_DATA'|translate}}">
      <div class="container-vertical-padding-half col-md-12 px-0">
        <div class="card">
          <div class="card-header">
            {{getHeadingDictionaryKey() | translate}}
          </div>
          <form (ngSubmit)="submit()" #f="ngForm"
                [formGroup]="formGroup" novalidate>
            <div class="card-body">
              <div class="col-xs-12 col-sm-12 col-md-10 col-lg-8 col-xl-6">
                <h5 class="section-header">{{'COMMON_CARD_HEADER_BASE_DATA' | translate}}</h5>

                <div class="form-group row">
                  <label class="col-md-4 col-form-label form-control-label inverted-label-align">
                    {{'USER_LABEL_PROFILE_PICTURE'|translate}}
                  </label>
                  <div class="col-md-8">
                    <div class="profile-picture d-flex justify-content-center align-items-center" *ngIf="componentState.isCreateView() && !model.selectedProfilePicture">
                      <i class="icomoon icomoon-user-local"></i>
                    </div>
                    <app-user-profile-picture *ngIf="!componentState.isCreateView() && !model.selectedProfilePicture"
                                              [userPersonName]="model.personName"
                                              [profilePicture]="model.profilePicture"
                                              [size]="150">
                    </app-user-profile-picture>
                    <img class="profile-picture" [src]="model.selectedProfilePicture" *ngIf="model.selectedProfilePicture"/>
                    <div class="profile-picture-menu-container" dropdown *ngIf="componentState.isEditable()"
                         href="#" onclick="return false" dropdownToggle
                         data-toggle="dropdown" role="button"
                         aria-haspopup="true" aria-expanded="false">
                      <i class="icomoon icomoon-add-nocircle profile-picture-menu-icon" *ngIf="!model.selectedProfilePicture && !model.profilePicture"></i>
                      <i class="icomoon icomoon-about profile-picture-menu-icon" *ngIf="model.selectedProfilePicture || model.profilePicture"></i>
                      <div class="dropdown-menu dropdown-menu-right border-bottom-0"
                           *dropdownMenu aria-labelledby="simple-dropdown">
                        <app-dropdown-item
                          [iconClass]="'icomoon-upload-photo'"
                          [titleStringKey]="'USER_LABEL_UPLOAD_PHOTO'"
                          (click)="openProfilePictureUploadDialog(ProfilePictureDialogMode.FILE)">
                        </app-dropdown-item>
                        <app-dropdown-item
                          [iconClass]="'icomoon-take-photo'"
                          [titleStringKey]="'USER_LABEL_TAKE_PHOTO'"
                          (click)="openProfilePictureUploadDialog(ProfilePictureDialogMode.WEBCAM)">
                        </app-dropdown-item>
                        <app-dropdown-item
                          *ngIf="model.selectedProfilePicture || model.profilePicture"
                          [iconClass]="'icomoon-trash'"
                          [titleStringKey]="'COMMON_BUTTON_DELETE'"
                          (click)="deleteProfilePicture()">
                        </app-dropdown-item>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-md-4 col-form-label form-control-label inverted-label-align required-field-label">
                    {{'USER_LABEL_COLOR'|translate}}
                  </label>
                  <div class="col-md-8">
                    <div class="mw-100">
                      <palette-color-picker
                        [readonly]="componentState.isReadonly()"
                        [(color)]="model.color">
                      </palette-color-picker>
                    </div>
                  </div>
                </div>

                <div class="form-group row" [ngClass]="{ 'has-danger': fieldErrors.user_name }">
                  <label for="user_name" class="col-md-4 col-form-label form-control-label inverted-label-align"
                         [class.required-field-label]="componentState.isCreateView()">
                    {{'USER_LABEL_USER_NAME'|translate}}
                  </label>
                  <div class="col-md-8">
                    <div class="mw-100">
                      <input type="text" *ngIf="componentState.isEditable()"
                             [(ngModel)]="model.userName" [ngModelOptions]="{standalone: true}"
                             class="form-control" maxlength="{{getTextMaximumLength()}}" name="user_name" id="user_name"
                             [ngClass]="{ 'form-control-danger': fieldErrors.user_name }"
                             (ngModelChange)="removeFieldError(fieldErrors.user_name)"
                             placeholder="{{'USER_PLACEHOLDER_USER_NAME'|translate}}"
                             [disabled]="componentState.isEditView()"/>
                    </div>
                    <div class="form-control-feedback"
                         *ngIf="fieldErrors.user_name">{{fieldErrors.user_name.text}}</div>
                    <!--Appears in detailView-->
                    <label
                      *ngIf="componentState.isReadonly()" class="form-control detail-description"
                      readonly>{{model.userName}}
                    </label>
                  </div>
                </div>

                <div class="form-group row"
                     [ngClass]="{ 'has-danger': (fieldErrors.external_id) || hasLocalFieldError('externalId') }">
                  <label for="person_name" class="col-md-4 col-form-label form-control-label inverted-label-align"
                         [class.required-field-label]="componentState.isEditView()">
                    {{'USER_LABEL_EXTERNAL_ID'|translate}}
                  </label>
                  <div class="col-md-8">
                    <div class="mw-100">
                      <input type="text" *ngIf="componentState.isEditable()"
                             [(ngModel)]="model.externalId"
                             maxlength="{{getTextMaximumLength()}}"
                             (ngModelChange)="removeFieldError(fieldErrors.external_id)"
                             formControlName="externalId"
                             trim="blur"
                             validateOnBlur
                             [validateFormControl]="formGroup.controls['externalId']"
                             [ngClass]="{ 'form-control-danger': (fieldErrors.external_id) || hasLocalFieldError('externalId') }"
                             class="form-control"
                             name="external_id"
                             id="external_id"
                             placeholder="{{'USER_LABEL_EXTERNAL_ID'|translate}}">
                      <div class="form-control-feedback"
                           *ngIf="fieldErrors.external_id">{{fieldErrors.external_id.text}}
                      </div>
                      <div class="form-control-feedback" *ngIf="hasLocalFieldError('externalId')">
                      <span
                        *ngIf="hasLocalFieldError('externalId', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
                      </div>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{model.externalId}}
                      </label>
                    </div>
                  </div>
                </div>

                <div class="form-group row" [ngClass]="{ 'has-danger': fieldErrors.password }"
                     *ngIf="componentState.isCreateView()">
                  <label for="password"
                         class="col-md-4 col-form-label form-control-label required-field-label inverted-label-align">{{'USER_LABEL_PASSWORD'|translate}}</label>
                  <div class="col-md-8">
                    <div class="mw-100">
                      <div class="input-group">
                        <input
                          type="{{passwordVisible ? 'text' : 'password'}}"
                          [(ngModel)]="model.password"
                          maxlength="{{getTextMaximumLength()}}"
                          (ngModelChange)="removeFieldError(fieldErrors.password)"
                          formControlName="password"
                          class="form-control"
                          [ngClass]="{ 'form-control-danger': fieldErrors.password }" name="password" id="password"
                          placeholder="{{'USER_PLACEHOLDER_PASSWORD'|translate}}"/>
                        <span (click)="togglePasswordVisibility()"
                              class="input-group-addon-gray icomoon icomoon-enable cursor-pointer font-2xl"></span>
                      </div>
                      <div class="form-control-feedback"
                           *ngIf="fieldErrors.password">{{fieldErrors.password.text}}</div>
                    </div>
                  </div>
                </div>

                <div class="form-group row" [ngClass]="{ 'has-danger': fieldErrors.person_name }">
                  <label for="person_name"
                         class="col-md-4 col-form-label form-control-label inverted-label-align"
                         [class.required-field-label]="componentState.isEditable()">
                    {{'USER_LABEL_PERSON_NAME'|translate}}
                  </label>
                  <div class="col-md-8">
                    <div class="mw-100">
                      <input type="text" *ngIf="componentState.isEditable()"
                             [(ngModel)]="model.personName" [ngModelOptions]="{standalone: true}"
                             (ngModelChange)="removeFieldError(fieldErrors.person_name)" class="form-control"
                             maxlength="{{getTextMaximumLength()}}"
                             [ngClass]="{ 'form-control-danger': fieldErrors.person_name }" name="person_name"
                             id="person_name" placeholder="{{'USER_PLACEHOLDER_PERSON_NAME'|translate}}" required
                             [disabled]="isLdapUser()"/>
                      <div class="form-control-feedback"
                           *ngIf="fieldErrors.person_name">{{fieldErrors.person_name.text}}
                      </div>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{model.personName}}
                      </label>
                    </div>
                  </div>
                </div>

                <div class="form-group row" *ngIf="componentState.isEditable() || (model.phoneNumber && model.phoneNumber.length > 0)"
                     [ngClass]="{ 'has-danger': fieldErrors.phone_number }">
                  <label
                    class="col-md-4 col-form-label form-control-label inverted-label-align">{{'COMMON_PHONE_NUMBER' | translate}}</label>
                  <div class="col-md-8">
                    <div class="mw-100">
                      <input type="text" *ngIf="componentState.isEditable()"
                             class="form-control" placeholder="{{'COMMON_PHONE_NUMBER' | translate}}"
                             maxlength="{{getTextMaximumLength()}}"
                             [ngClass]="{ 'form-control-danger': fieldErrors.phone_number }"
                             [(ngModel)]="model.phoneNumber" [ngModelOptions]="{standalone: true}" name="phone_number"
                             id="phone_number" #phone_number="ngModel" validatePhoneNumber
                             [disabled]="handledByLdap(LdapFeature.PHONE_NUMBER)">
                      <div class="form-control-feedback"
                           *ngIf="fieldErrors.phone_number">{{'COMMON_VALIDATION_MESSAGE_PHONE'
                        | translate}}
                      </div>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{model.phoneNumber}}
                      </label>
                    </div>
                  </div>
                </div>

                <div class="form-group row" *ngIf="componentState.isEditable() || (model.emailAddress && model.emailAddress.length > 0)"
                     [ngClass]="{'has-danger': (fieldErrors.email_address) || hasLocalFieldError('emailAddress')}">
                  <label for="email_address"
                         class="col-md-4 col-form-label form-control-label inverted-label-align"
                         [class.required-field-label]="componentState.isEditable() && !handledByLdap(LdapFeature.EMAIL) && emailAddressRequired">
                    {{'COMMON_EMAIL_ADDRESS'|translate}}
                  </label>
                  <div class="col-md-8">
                    <div class="mw-100">
                      <input type="text" *ngIf="componentState.isEditable() && !handledByLdap(LdapFeature.EMAIL)"
                             [(ngModel)]="model.emailAddress"
                             (ngModelChange)="removeFieldError(fieldErrors.email_address)"
                             maxlength="{{getTextMaximumLength()}}"
                             class="form-control" [ngClass]="{ 'form-control-danger': fieldErrors.email_address }"
                             formControlName="emailAddress"
                             name="email_address" id="email_address"
                             placeholder="{{'USER_PLACEHOLDER_EMAIL_ADDRESS'|translate}}"
                             validateOnBlur
                             [validateFormControl]="formGroup.controls['emailAddress']"/>
                      <div class="form-control-feedback" *ngIf="fieldErrors.email_address">
                        {{fieldErrors.email_address.text}}
                      </div>
                      <div class="form-control-feedback" *ngIf="hasLocalFieldError('emailAddress')">
                      <span
                        *ngIf="hasLocalFieldError('emailAddress', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
                      </div>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly() || handledByLdap(LdapFeature.EMAIL)" class="form-control detail-description"
                        readonly>{{model.emailAddress}}
                      </label>
                    </div>
                  </div>
                </div>

                <h5 class="section-header">{{'USER_LABEL_FURTHER_DATA' | translate}}</h5>

                <div class="form-group row" *ngIf="!componentState.isReadonly() || model.userGroups.length > 0"
                     [ngClass]="{ 'has-danger': fieldErrors.user_group_ids || hasLocalFieldError('userGroup')}">
                  <label
                    class="col-md-4 col-form-label form-control-label inverted-label-align"
                    [class.required-field-label]="componentState.isEditable()">
                    {{'USER_LABEL_USER_GROUPS'|translate}}
                  </label>
                  <div class="col-md-8">
                    <div class="mw-100">
                      <angular2-multiselect *ngIf="!protectedUser"
                                            id="test_user_user_group_angular_select"
                                            formControlName="userGroup"
                                            [(ngModel)]="model.userGroups"
                                            [data]="userGroups"
                                            [settings]="userGroupDropdownSettings"
                                            (onRemoteSearch)="loadUserGroups($event.target.value)"
                                            (ngModelChange)="removeFieldError(fieldErrors.user_group_ids)"
                                            (onReadonlyItemClick)="navigateToUserGroupDetail($event)"
                                            [ngClass]="{ 'form-control-danger': fieldErrors.user_group_ids || hasLocalFieldError('userGroup')}"
                                            validateEnabledItems [readonly]="componentState.isReadonly()">
                      </angular2-multiselect>
                      <div class="form-control-feedback" *ngIf="fieldErrors.user_group_ids">
                        {{fieldErrors.user_group_ids.text}}
                      </div>
                      <div class="form-control-feedback" *ngIf="hasLocalFieldError('userGroup')">
                  <span
                    *ngIf="hasLocalFieldError('userGroup', 'hasDisabledItem')">{{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}</span>
                      </div>
                      <div *ngIf="protectedUser">
                        <label class="form-control" readonly>{{selectedUserGroupsText}}</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group row" *ngIf="componentState.isEditable() || model.inactivationDate"
                     [ngClass]="{ 'has-danger': (f.submitted && !permission_expiry_date.valid) }">
                  <label
                    class="col-md-4 col-form-label form-control-label inverted-label-align">{{'USER_LABEL_PERMISSION_EXPIRY_DATE'|translate}}</label>
                  <div class="col-md-8">
                    <div class="row">
                      <div class="input-group col-md-6 mb-1 mb-md-0">
                        <input placeholder="{{'USER_LABEL_PERMISSION_EXPIRY_DATE' | translate}}" type="text"
                               class="form-control"
                               [class.detail-description]="componentState.isReadonly()"
                               [(ngModel)]="model.inactivationDate"
                               [ngClass]="{ 'form-control-danger': (f.submitted && !permission_expiry_date.valid) }"
                               [ngModelOptions]="{standalone: true}"
                               ngbDatepicker
                               validateLocalDate
                               #permission_expiry_date_picker="ngbDatepicker"
                               #permission_expiry_date="ngModel"
                               id="permission_expiry_date"
                               name="permission_expiry_date">
                        <span class="add-on input-group-addon-gray" *ngIf="componentState.isEditable()"
                              (click)="permission_expiry_date_picker.toggle()">
                        <i class="icomoon icomoon-calendar"></i>
                       </span>
                      </div>
                      <div class="input-group col-md-6">
                        <input placeholder="{{'USER_LABEL_PERMISSION_EXPIRY_DATE' | translate}}" type="text"
                               class="form-control hidden-value-when-disabled"
                               [class.detail-description]="componentState.isReadonly()"
                               [(ngModel)]="model.inactivationTime"
                               [ngModelOptions]="{standalone: true}"
                               [seconds]="false"
                               [allowEmptyValue]="false"
                               [disabled]="model.inactivationDate === null || model.inactivationDate === undefined"
                               ngbTimepicker #permission_expiry_time_picker="ngbTimepicker"
                               [toggleButtonId]="'permission_expiry_time_toggle_button'"
                               [spinners]="true" [hourStep]="1" [minuteStep]="10">
                        <span class="add-on input-group-addon-gray" *ngIf="componentState.isEditable()"
                              id="permission_expiry_time_toggle_button"
                              (click)="permission_expiry_time_picker.toggle()">
                        <i class="icomoon icomoon-time"></i>
                      </span>
                      </div>
                    </div>

                    <div class="form-control-feedback" *ngIf="(f.submitted && !permission_expiry_date.valid)">
                      {{'FORM_FIELD_VALIDATION_DATE_INVALID' | translate}}
                    </div>
                  </div>
                </div>

                <div *ngIf="userDriverEnabled">
                  <div class="form-group row">
                    <label
                      class="col-md-4 col-form-label form-control-label inverted-label-align">{{'USER_LABEL_IS_DRIVER'|translate}}</label>
                    <div class="col-md-8">
                      <label class="switch switch-text switch-info mb-0" *ngIf="componentState.isEditable()">
                        <input type="checkbox" class="switch-input"
                               [ngModelOptions]="{standalone: true}"
                               [disabled]="forceDriver"
                               [(ngModel)]="model.isDriver">
                        <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                        <span class="switch-handle"></span>
                      </label>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{(model.isDriver ? 'COMMON_YES' : 'COMMON_NO') | translate}}
                      </label>
                    </div>
                  </div>

                  <div *ngIf="model.isDriver">
                    <div class="form-group row"
                         [ngClass]="{ 'has-danger': fieldErrors.licence_number || hasLocalFieldError('licenceNumber') }">
                      <label for="licence_number"
                             class="col-md-4 col-form-label form-control-label inverted-label-align"
                             [class.required-field-label]="componentState.isEditable()">
                        {{'USER_LABEL_DRIVING_LICENCE_NUMBER'|translate}}
                      </label>
                      <div class="col-md-8">
                        <div class="mw-100">
                          <input type="text" *ngIf="componentState.isEditable()"
                                 [(ngModel)]="model.driver.drivingLicenceNumber"
                                 maxlength="{{getTextMaximumLength()}}"
                                 (ngModelChange)="removeFieldError(fieldErrors.licence_number)" class="form-control"
                                 [ngClass]="{ 'form-control-danger': fieldErrors.licence_number || hasLocalFieldError('licenceNumber')}"
                                 formControlName="licenceNumber"
                                 id="licence_number" placeholder="{{'USER_LABEL_DRIVING_LICENCE_NUMBER'|translate}}"
                          />
                          <div class="form-control-feedback" *ngIf="fieldErrors.licence_number">
                            {{fieldErrors.licence_number.text}}
                          </div>
                          <div class="form-control-feedback" *ngIf="hasLocalFieldError('licenceNumber')">
                            {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                          </div>
                          <!--Appears in detailView-->
                          <label
                            *ngIf="componentState.isReadonly()" class="form-control detail-description"
                            readonly>{{model.driver.drivingLicenceNumber}}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row" [ngClass]="{ 'has-danger': hasLocalFieldError('licenceCategories') }">
                      <label for="licence_categories"
                             class="col-md-4 col-form-label form-control-label inverted-label-align"
                             [class.required-field-label]="componentState.isEditable()">
                        {{'USER_LABEL_DRIVING_LICENCE_CATEGORIES'|translate}}
                      </label>
                      <div class="col-md-8">
                        <div class="mw-100">
                          <input type="text" *ngIf="componentState.isEditable()"
                                 [(ngModel)]="model.driver.drivingLicenceCategories"
                                 maxlength="{{getTextMaximumLength()}}"
                                 class="form-control"
                                 [ngClass]="{ 'form-control-danger': hasLocalFieldError('licenceCategories')}"
                                 name="licence_categories"
                                 id="licence_categories"
                                 placeholder="{{'USER_LABEL_DRIVING_LICENCE_CATEGORIES'|translate}}"
                                 formControlName="licenceCategories"
                          />
                          <div class="form-control-feedback" *ngIf="hasLocalFieldError('licenceCategories')">
                            {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                          </div>
                          <!--Appears in detailView-->
                          <label
                            *ngIf="componentState.isReadonly()" class="form-control detail-description"
                            readonly>{{model.driver.drivingLicenceCategories}}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row" [ngClass]="{ 'has-danger': hasLocalFieldError('licenceExpiryDate') }">
                      <label
                        class="col-md-4 col-form-label form-control-label inverted-label-align"
                        [class.required-field-label]="componentState.isEditable()">
                        {{'USER_LABEL_DRIVING_LICENCE_EXPIRY_DATE'|translate}}
                      </label>
                      <div class="col-md-8">
                        <div class="input-group">
                          <input placeholder="{{'USER_LABEL_DRIVING_LICENCE_EXPIRY_DATE' | translate }}" type="text"
                                 class="form-control"
                                 [class.detail-description]="componentState.isReadonly()"
                                 formControlName="licenceExpiryDate"
                                 [(ngModel)]="model.driver.drivingLicenceExpiryDate"
                                 [ngClass]="{ 'form-control-danger': hasLocalFieldError('licenceExpiryDate') }"
                                 ngbDatepicker #licence_expiry_date_picker="ngbDatepicker"
                                 id="licence_expiry_date" name="licence_expiry_date">
                          <span class="add-on input-group-addon-gray" *ngIf="componentState.isEditable()"
                                (click)="licence_expiry_date_picker.toggle()">
                      <i class="icomoon icomoon-calendar"></i>
                  </span>
                        </div>
                        <div class="form-control-feedback" *ngIf="hasLocalFieldError('licenceExpiryDate', 'required')">
                          {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                        </div>
                        <div class="form-control-feedback"
                             *ngIf="hasLocalFieldError('licenceExpiryDate', 'validateLocalDate')">
                          {{'FORM_FIELD_VALIDATION_DATE_INVALID' | translate}}
                        </div>
                      </div>
                    </div>

                    <div class="form-group row" *ngIf="componentState.isEditable() || model.driver.medicalExpiryDate"
                         [ngClass]="{ 'has-danger': hasLocalFieldError('medicalExpiryDate') }">
                      <label
                        class="col-md-4 col-form-label form-control-label inverted-label-align">{{'USER_LABEL_MEDICAL_EXPIRY_DATE'|translate}}</label>
                      <div class="col-md-8">
                        <div class="input-group">
                          <input placeholder="{{'USER_LABEL_MEDICAL_EXPIRY_DATE' | translate }}" type="text"
                                 class="form-control"
                                 [class.detail-description]="componentState.isReadonly()"
                                 [(ngModel)]="model.driver.medicalExpiryDate"
                                 [ngClass]="{ 'form-control-danger': hasLocalFieldError('medicalExpiryDate') }"
                                 ngbDatepicker #medical_expiry_date_pricker="ngbDatepicker"
                                 formControlName="medicalExpiryDate" id="medical_expiry_date"
                                 name="medical_expiry_date">
                          <span class="add-on input-group-addon-gray" *ngIf="componentState.isEditable()"
                                (click)="medical_expiry_date_pricker.toggle()">
                      <i class="icomoon icomoon-calendar"></i>
                  </span>
                        </div>
                        <div class="form-control-feedback" *ngIf="hasLocalFieldError('medicalExpiryDate')">
                          {{'FORM_FIELD_VALIDATION_DATE_INVALID' | translate}}
                        </div>
                      </div>
                    </div>
                    <!--<div class="form-group row" [ngClass]="{ 'has-danger': hasLocalFieldError('permissionExpiryDate') }">-->
                    <!--<label class="col-md-4 col-form-label form-control-label inverted-label-align">{{'USER_LABEL_PERMISSION_EXPIRY_DATE'|translate}}</label>-->
                    <!--<div class="col-md-8">-->
                    <!--<div class="input-group">-->
                    <!--<input placeholder="{{'USER_LABEL_PERMISSION_EXPIRY_DATE' | translate }}" type="text"-->
                    <!--class="form-control"-->
                    <!--[class.detail-description]="componentState.isReadonly()"-->
                    <!--[(ngModel)]="model.driver.permissionExpiryDate"-->
                    <!--[ngClass]="{ 'form-control-danger': hasLocalFieldError('permissionExpiryDate') }"-->
                    <!--ngbDatepicker #permission_expiry_date_picker="ngbDatepicker"-->
                    <!--formControlName="permissionExpiryDate" id="permission_expiry_date" name="permission_expiry_date">-->
                    <!--<span class="add-on input-group-addon-gray" *ngIf="componentState.isEditable()"-->
                    <!--(click)="permission_expiry_date_picker.toggle()">-->
                    <!--<i class="icomoon icomoon-calendar"></i>-->
                    <!--</span>-->
                    <!--</div>-->
                    <!--<div class="form-control-feedback" *ngIf="hasLocalFieldError('permissionExpiryDate')">-->
                    <!--{{'FORM_FIELD_VALIDATION_DATE_INVALID' | translate}}-->
                    <!--</div>-->
                    <!--</div>-->
                    <!--</div>-->

                  </div>
                </div>

                <div class="form-group row" *ngIf="componentState.isEditable() || model.vehicle.length > 0">
                  <label for="licence_number"
                         class="col-md-4 col-form-label form-control-label inverted-label-align">{{'TRANSPORT_VEHICLE'|translate}}</label>
                  <div class="col-md-8">
                    <div class="mw-100">
                      <angular2-multiselect [data]="vehicles"
                                            [settings]="vehicleDropdownSettings"
                                            [(ngModel)]="model.vehicle"
                                            (onRemoteSearch)="loadVehicles($event.target.value)"
                                            [readonly]="componentState.isReadonly()"
                                            formControlName="vehicle">
                      </angular2-multiselect>
                    </div>
                  </div>
                </div>

                <div class="form-group row" *ngIf="componentState.isEditable() || model.allowedDevices.length > 0"
                     [ngClass]="{ 'has-danger': hasLocalFieldError('allowedDevices')}">
                  <label
                    class="col-md-4 col-form-label form-control-label inverted-label-align">{{'USER_LABEL_ALLOWED_DEVICES'|translate}}</label>
                  <div class="col-md-8">
                    <div class="mw-100">
                      <angular2-multiselect id="test_user_allowed_devices_angular_select"
                                            formControlName="allowedDevices"
                                            [(ngModel)]="model.allowedDevices"
                                            [data]="deviceList"
                                            [settings]="mobileAppDropdownSettings"
                                            (onRemoteSearch)="loadDevices($event.target.value)"
                                            (ngModelChange)="removeFieldError(fieldErrors.user_group_ids)"
                                            [readonly]="componentState.isReadonly()"
                                            [ngClass]="{ 'form-control-danger': hasLocalFieldError('allowedDevices')}"
                                            validateEnabledItems>
                      </angular2-multiselect>
                      <div class="form-control-feedback" *ngIf="hasLocalFieldError('allowedDevices')">
                  <span
                    *ngIf="hasLocalFieldError('allowedDevices', 'hasDisabledItem')">{{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div *ngIf="model.isHelpdesk">
                  <h5 class="section-header">{{'USER_LABEL_HELPDESK_DATA' | translate}}</h5>

                  <div class="form-group row">
                    <label
                      class="col-md-4 col-form-label form-control-label inverted-label-align">{{'USER_LABEL_HELPDESK_CONTACT_PERSON'|translate}}</label>
                    <div class="col-md-8">
                      <div class="mw-100">
                        <angular2-multiselect [(ngModel)]="model.contactPerson"
                                              [ngModelOptions]="{standalone: true}"
                                              [data]="[]"
                                              [settings]="helpdeskDropdownSettings"
                                              [readonly]="true"
                                              (onReadonlyItemClick)="navigateToCustomerRecordDetail($event)">
                        </angular2-multiselect>
                      </div>
                    </div>
                  </div>

                  <div class="form-group row" *ngIf="model.contactLocations.length > 0">
                    <label
                      class="col-md-4 col-form-label form-control-label inverted-label-align">{{'USER_LABEL_HELPDESK_CONTACT_LOCATIONS'|translate}}</label>
                    <div class="col-md-8">
                      <div class="mw-100">
                        <angular2-multiselect [(ngModel)]="model.contactLocations"
                                              [ngModelOptions]="{standalone: true}"
                                              [data]="[]"
                                              [settings]="helpdeskDropdownSettings"
                                              [readonly]="true">
                        </angular2-multiselect>
                      </div>
                    </div>
                  </div>

                  <div class="form-group row" *ngIf="model.customerRecord.length > 0">
                    <label
                      class="col-md-4 col-form-label form-control-label inverted-label-align">{{'USER_LABEL_HELPDESK_CUSTOMER_RECORD'|translate}}</label>
                    <div class="col-md-8">
                      <div class="mw-100">
                        <angular2-multiselect [(ngModel)]="model.customerRecord"
                                              [ngModelOptions]="{standalone: true}"
                                              [data]="[]"
                                              [settings]="helpdeskDropdownSettings"
                                              [readonly]="true"
                                              (onReadonlyItemClick)="navigateToCustomerRecordDetail($event)">
                        </angular2-multiselect>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="model.ldap">
                  <h5 class="section-header">{{'USER_LABEL_LDAP_DATA' | translate}}</h5>

                  <div class="form-group row" *ngIf="model.ldap.company">
                    <label
                      class="col-md-4 col-form-label form-control-label inverted-label-align">{{'USER_LABEL_LDAP_COMPANY'|translate}}</label>
                    <div class="col-md-8">
                      <label class="form-control detail-description">{{model.ldap.company}}</label>

                    </div>
                  </div>
                  <div class="form-group row" *ngIf="model.ldap.department">
                    <label
                      class="col-md-4 col-form-label form-control-label inverted-label-align">{{'USER_LABEL_LDAP_DEPARTMENT'|translate}}</label>
                    <div class="col-md-8">
                      <label class="form-control detail-description">{{model.ldap.department}}</label>

                    </div>
                  </div>
                  <div class="form-group row">
                    <label
                      class="col-md-4 col-form-label form-control-label inverted-label-align">{{'USER_LABEL_LDAP_DN'|translate}}</label>
                    <div class="col-md-8">
                      <label class="form-control detail-description">{{model.ldap.dn}}</label>

                    </div>
                  </div>
                  <div class="form-group row">
                    <label
                      class="col-md-4 col-form-label form-control-label inverted-label-align">{{'USER_LABEL_LDAP_DELETED'|translate}}</label>
                    <div class="col-md-8">
                      <label
                        class="form-control detail-description">{{(model.ldap.deleted ? 'COMMON_YES' : 'COMMON_NO') | translate}}
                      </label>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div class="card-footer" *ngIf="componentState.isEditable()">
              <div class="row col-xs-12 col-sm-12 col-md-10 col-lg-8 col-xl-6">
                <div class="col-md-4"></div>
                <div class="col-md-8">
                  <input type="submit" class="btn btn-primary" value="{{'USER_LABEL_SAVE_DATA'|translate}}"/>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </responsive-tab>
    <responsive-tab *ngIf="componentState.id && rightModel.userNfcCardRead.hasRight()"
                      heading="{{'NFC_CARD_LIST_TABLE_HEADER'|translate}}">
      <app-nfc-card-list
        [readonly]="componentState.isReadonly() || handledByLdap(LdapFeature.NFC_CARD)"
        [userId]="componentState.id"
        (addNfcCardEventEmitter)="showAddNfcCardDialog()"
        (deleteNfcCardEventEmitter)="showDeleteNfcCardDialog($event)">
      </app-nfc-card-list>
    </responsive-tab>
    <responsive-tab *ngIf="componentState.id && userRightModel.userSignatureRead.hasRightForUser(userForRightModel)"
                    heading="{{'USER_SIGNATURE_TAB_HEADER'|translate}}">
      <app-user-signature-list
        [readonly]="componentState.isReadonly()"
        [userId]="componentState.id!">
      </app-user-signature-list>
    </responsive-tab>
    <responsive-tab *ngIf="componentState.isDetailView() && rightModel.userHistoryLog.hasRight()"
                      heading="{{'COMMON_HISTORY'|translate}}">
      <app-history-log
        [queryModel]="historyQueryModel"
        [Type]="User"
        [historyList]="userHistoryList"
        (openHistoryDetail)="historyDetailModal.openDetailDialog($event)"
        (itemsPerPageChangedOut)="itemsPerPageChanged($event)"
        (orderByOut)="orderBy($event)"
        (pageChangedOut)="pageChanged($event)"
        #historyComponent>
      </app-history-log>
    </responsive-tab>
    <responsive-tab heading="{{'WORKLOG_PANEL_HEADING_LIST'|translate}}"
                    *ngIf="componentState.isDetailView() && rightModel.worklogRead.hasRight()">
      <app-worklog-list
        [scope]="WorklogScope.USER"
        [editable]="true"
        [createEnabled]="false"
        [resourceId]="componentState.id!">
      </app-worklog-list>
    </responsive-tab>
  </responsive-tabset>

</div>

<div bsModal [config]="UiConstants.modalConfig" #addNfcCardDialog="bs-modal" class="modal fade" tabindex="-1"
     role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true"
     (onHide)="onModalHidden()">
  <div class="modal-dialog modal-lg" role="document" *ngIf="addNfcCardDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title"><strong>{{'NFC_CARD_ADD' | translate}}</strong></p>
        <button type="button" class="close" (click)="closeAddNfcCardDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form [formGroup]='addNfcCardForm' (ngSubmit)="addNfcCard()" class="modal-body border-top-1">
        <div class="form-group row"
             [ngClass]="{ 'has-danger': addNfcCardForm.controls['hexId'].touched && !addNfcCardForm.controls['hexId'].valid}">
          <div class="col-md-4">
            <label
              class="col-form-label mw-100 form-control-label required-field-label">
              {{'NFC_CARD_HEX_ID'|translate}}
            </label>
          </div>
          <div class="col-md-8">
            <input type="text" class="form-control" placeholder="{{'NFC_CARD_HEX_ID' | translate}}"
                   [textMask]="{mask: InputMask.NFC_HEX_ID, guide: false}"
                   formControlName="hexId"
                   [(ngModel)]="nfcCardHexId">
            <div class="form-control-feedback"
                 *ngIf="addNfcCardForm.controls['hexId'].touched && addNfcCardForm.controls['hexId'].hasError('required')">
              {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
            </div>
            <div class="form-control-feedback" *ngIf="addNfcCardForm.controls['hexId'].touched &&
            (addNfcCardForm.controls['hexId'].hasError('pattern'))">
              {{'NFC_CARD_LENGTH_VALIDATION_MESSAGE' | translate}}
            </div>
          </div>
        </div>
      </form>
      <!-- /.modal-body -->
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary" (click)="closeAddNfcCardDialog()">
          {{'COMMON_BUTTON_CANCEL' | translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="addNfcCard()">{{'COMMON_BUTTON_ADD' |
          translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<div bsModal [config]="UiConstants.modalConfig" #deleteNfcCardDialog="bs-modal" class="modal fade" tabindex="-1"
     role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="deleteNfcCardDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title"><strong>{{'NFC_CARD_DELETE' | translate}}</strong></p>
        <button type="button" class="close" (click)="closeDeleteNfcCardDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div>
          <label>{{'NFC_CARD_APPROVE_DELETE_MESSAGE' | translate}}</label>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary" (click)="closeDeleteNfcCardDialog()">
          {{'COMMON_BUTTON_CANCEL' | translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="deleteNfcCard()">{{'COMMON_BUTTON_DELETE' |
          translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<div bsModal [config]="UiConstants.modalConfig" #passwordChangeDialog="bs-modal" class="modal fade" tabindex="-1"
     role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true"
     (onHide)="onModalHidden()">
  <div class="modal-dialog modal-lg" role="document" *ngIf="passwordChangeDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title"><strong>{{'USER_PANEL_HEADING_CHANGE_PASSWORD' | translate}}</strong></p>
        <button type="button" class="close" (click)="closePasswordChangeDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form class="form-horizontal bordered-row" (ngSubmit)="changePassword()" [formGroup]="passwordChangeForm">
        <div class="modal-body">
          <div class="form-group row" [ngClass]="{ 'has-danger': passwordFieldError.password ||
                                                  (passwordChangeForm.controls['password'].touched
                                                  && !passwordChangeForm.controls['password'].valid)
                                                  || (passwordChangeFieldErrors.password)
                                                  || (passwordChangeForm.controls['confirm_password'].touched
                                                   && passwordChangeForm.controls['confirm_password'].hasError('no_match'))
                                                   }">
            <div class="col-md-4">
              <label for="password"
                     class="mw-100 col-form-label form-control-label required-field-label">
                {{'USER_LABEL_PASSWORD_NEW'|translate}}
              </label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <div class="input-group">
                  <input
                    type="{{passwordVisible ? 'text' : 'password'}}"
                    [(ngModel)]="password"
                    (ngModelChange)="onPasswordModelChange()"
                    formControlName="password"
                    class="form-control" maxlength="{{getTextMaximumLength()}}"
                    [ngClass]="{ 'form-control-danger': passwordFieldError.password }"
                    name="password_2" id="password_2"
                    placeholder="{{'USER_PLACEHOLDER_PASSWORD_NEW'|translate}}"
                    required/>
                  <span (click)="togglePasswordVisibility()"
                        class="input-group-addon-gray icomoon icomoon-enable cursor-pointer font-2xl"></span>
                </div>
                <div class="form-control-feedback" *ngIf="passwordFieldError.password">
                  {{passwordFieldError.password.text}}
                </div>
                <div class="form-control-feedback" *ngIf="passwordChangeForm.controls['password'].touched
                                                   && passwordChangeForm.controls['password'].hasError('required')">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row" [ngClass]="{ 'has-danger': (passwordChangeForm.controls['confirm_password'].touched
                                                  && !passwordChangeForm.controls['confirm_password'].valid)
                                                  || (passwordChangeFieldErrors.confirm_password)
          }">
            <div class="col-md-4">
              <label for="confirm_password"
                     class="mw-100 col-form-label form-control-label required-field-label">
                {{'USER_PLACEHOLDER_PASSWORD_NEW_CONFIRM'|translate}}
              </label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <div class="input-group">
                  <input type="{{passwordVisible ? 'text' : 'password'}}"
                         (ngModelChange)="resetPasswordFieldError()"
                         formControlName="confirm_password"
                         class="form-control" maxlength="{{getTextMaximumLength()}}"
                         [ngClass]="{ 'form-control-danger': passwordFieldError.confirm_password }"
                         name="confirm_password" id="confirm_password"
                         placeholder="{{'USER_PLACEHOLDER_PASSWORD_NEW_CONFIRM'|translate}}"
                         required/>
                  <span (click)="togglePasswordVisibility()"
                        class="input-group-addon-gray icomoon icomoon-enable cursor-pointer font-2xl"></span>
                </div>
                <div class="form-control-feedback" *ngIf="passwordFieldError.confirm_password">
                  {{passwordFieldError.confirm_password.text}}
                </div>
                <div class="form-control-feedback" *ngIf="passwordChangeForm.controls['confirm_password'].touched
                                                   && passwordChangeForm.controls['confirm_password'].hasError('required')">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
                <div class="form-control-feedback" *ngIf="passwordChangeForm.controls['confirm_password'].touched
                                                   && passwordChangeForm.controls['confirm_password'].hasError('no_match')">
                  {{'COMMON_VALIDATION_MESSAGE_PASSWORD_MATCH' | translate}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /.modal-body -->
        <div class="modal-footer">
          <div class="d-flex justify-content-end">
            <input type="button" class="btn btn-outline-primary" value="{{'COMMON_BUTTON_CANCEL'|translate}}"
                   (click)="closePasswordChangeDialog()"/>
          </div>
          <div class="d-flex justify-content-end">
            <input type="submit" class="btn btn-primary pl-1" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
          </div>
        </div>
      </form>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<app-history-detail-dialog
  #historyDetailModal>
</app-history-detail-dialog>


<div bsModal [config]="UiConstants.modalConfig" #profilePictureUploadDialog="bs-modal" class="modal fade" tabindex="-1"
     role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true"
     (onHide)="onProfilePictureUploadDialogHidden()">
  <div class="modal-dialog modal-lg" role="document" *ngIf="profilePictureUploadDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title"><strong>{{'USER_LABEL_UPLOAD_PROFILE_PICTURE' | translate}}</strong></p>
        <button type="button" class="close" (click)="closeProfilePictureUploadDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body d-flex flex-column align-items-center">
        <input type="file" *ngIf="!imageSelected && profilePictureDialogMode === ProfilePictureDialogMode.FILE"
               (change)="fileChangeEvent($event)"
               accept="image/png, image/jpeg, image/gif"/>
        <webcam *ngIf="!imageSelected && profilePictureDialogMode === ProfilePictureDialogMode.WEBCAM"
                [class.d-none]="webcamInitError"
                [trigger]="webcamCaptureTrigger"
                (imageCapture)="onWebcamImageCapture($event)"
                (initError)="onWebcamInitError($event)">
        </webcam>
        <input type="button" *ngIf="!imageSelected && profilePictureDialogMode === ProfilePictureDialogMode.WEBCAM && !webcamInitError"
               class="btn btn-primary"
               (click)="webcamCaptureTrigger.next()"
               value="{{'USER_PROFILE_PICTURE_WEBCAM_CAPTURE' | translate}}"/>
        <div class="alert alert-danger mb-0" *ngIf="webcamInitError && webcamInitError.mediaStreamError && webcamInitError.mediaStreamError.name === 'NotAllowedError'">
          <span>{{'USER_PROFILE_PICTURE_WEBCAM_NOT_ALLOWED' | translate}}</span>
        </div>
        <div class="alert alert-danger mb-0" *ngIf="webcamInitError && webcamInitError.mediaStreamError && webcamInitError.mediaStreamError.name === 'NotFoundError'">
          <span>{{'USER_PROFILE_PICTURE_WEBCAM_NOT_FOUND' | translate}}</span>
        </div>
        <div [class.d-none]="!imageSelected">
          <image-cropper [imageChangedEvent]="imageChangedEvent"
                         [roundCropper]="true"
                         [backgroundColor]="'#E3E8EC'"
                         (imageCropped)="imageCropped($event)"
                         (imageLoaded)="imageLoaded()">
          </image-cropper>
        </div>
      </div>
      <div class="modal-footer">
        <div class="w-100 d-flex justify-content-between">
          <div>
            <input type="button" class="btn btn-secondary mr-1" *ngIf="imageSelected && profilePictureDialogMode === ProfilePictureDialogMode.WEBCAM"
                   (click)="retakeWebcamPicture()" value="{{'USER_PROFILE_PICTURE_WEBCAM_RETAKE'|translate}}"/>
          </div>
          <div>
            <input type="button" class="btn btn-secondary mr-1" (click)="closeProfilePictureUploadDialog()" value="{{'COMMON_BUTTON_CANCEL'|translate}}"/>
            <input type="button" class="btn btn-primary" (click)="saveProfilePicture()" [disabled]="!imageSelected" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
          </div>
        </div>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
