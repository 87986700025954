export namespace ToasterContainer {

  export class ViewReference {

    private static reference?: View;

    static get get(): View | undefined {
      return this.reference;
    }

    static set(view: View) {
      ViewReference.reference = view;
    }

  }

  export interface View {
    getToastTimeout(): number;
    setToastTimeout(timeout: number);
  }

  export class Utils {

    private Utils() {
    }

  }

}
