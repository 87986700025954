/* eslint-disable */
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { DownloadedFile, } from '../util/downloaded-files';
import { Observable } from 'rxjs';
import { FormAttachment } from './form-attachment.service';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { FormRecordResource } from './form-record-resource.service';
import { FileAttachment, FileAttachmentResource } from '../util/file-attachments';
import { EmptyMessage } from '../util/messages';
import { CustomerRecordResource } from '../customer/customer-record-resource.service';

/* eslint-enable */

@Injectable()
export class FormAttachmentResourceService extends BaseHttpService {

  deleteFile(request: FormAttachmentResource.DeleteRequest): Observable<FileAttachmentResource[]> {
    return this.http.delete<FileAttachmentResource[]>(
      this.url + `${request.form_record_id}/field/${request.field_id}/attachments/${request.file_id}`,
      this.parseParams(request)
    );
  }

  updateAttachment(request: FormAttachmentResource.AttachmentUpdateRequest): Observable<EmptyMessage> {
    return this.http.patch<EmptyMessage>(
      this.url + `${request.form_record_id}/field/${request.field_id}/attachments/${request.file_id}`, request);
  }

  downloadFile(request: FormAttachmentResource.DownloadFileRequest): Observable<DownloadedFile> {
    return this.http.get(
      this.url + `${request.formRecordId}/field/${request.fieldId}/attachments/${request.fileId}`,
      {
        observe: 'response',
        responseType: 'blob'
      }
    ).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  downloadThumbnail(request: FormAttachmentResource.DownloadFileRequest): Observable<DownloadedFile> {
    return this.http.get(
      this.url + `${request.formRecordId}/field/${request.fieldId}/attachments/${request.fileId}/thumbnail`,
      {
        observe: 'response',
        responseType: 'blob'
      }
    ).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/form-records/');
  }

}

export namespace FormAttachmentResource {

  export interface DeleteRequest {
    form_record_id: number;
    field_id: number;
    file_id: number;
  }

  export interface DownloadFileRequest {
    formRecordId: number;
    fieldId: number;
    fileId: number;
  }

  export interface AttachmentUpdateRequest {
    form_record_id: number;
    field_id: number;
    file_id: number;
    name: string;
  }

}
