/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import {
  FieldValidationError,
  Order,
  PagingRequest,
  QueryResult,
  ResourceQueryResult,
  Services
} from '../util/services';
import { ObservableErrorResourceParser } from '../util/errors';
import { LocalDate, OffsetDateTime } from '../util/dates';
import { List, Map, Set } from 'immutable';
import { VehicleResource, VehicleResourceService } from './vehicle-resource.service';
import { EmptyMessage, IdentityMessage } from '../util/messages';
import { CompanyResource } from '../company/company-resource.service';
import { UserItem, UserItemResource } from '../user.service';
import { HistoryLog } from '../history-log/history-log.service';
import VehicleHistoryType = Vehicle.VehicleHistoryType;

/* eslint-enable */

@Injectable()
export class VehicleService implements Vehicle.Service {

  constructor(private resourceService: VehicleResourceService) {
  }

  query(request: Vehicle.QueryRequest): Observable<QueryResult<Vehicle.Vehicle>> {
    return Observable.create((observer: Observer<QueryResult<Vehicle.Vehicle>>) => {
      const resourceRequest: VehicleResource.QueryRequest = {
        id: Services.createIdParameter(request.id),
        external_id: request.externalId,
        manufacturer: request.manufacturer,
        model: request.model,
        registration_type: request.registrationType,
        licence_plate: request.licencePlate,
        registration_date_from: request.registrationDateFrom
        && request.registrationDateFrom.isValid() ? request.registrationDateFrom.toIsoString() : undefined,
        registration_date_to: request.registrationDateTo
        && request.registrationDateTo.isValid() ? request.registrationDateTo.toIsoString() : undefined,
        payload_kg_from: request.payloadKgFrom,
        payload_kg_to: request.payloadKgTo,
        total_weight_kg_from: request.totalWeightKgFrom,
        total_weight_kg_to: request.totalWeightKgTo,
        etoll_required: request.etollRequired,
        disabled: request.disabled,
        company_ids: Services.createIdParameter(request.companyIds),
        q: request.queryText,
        order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
        no_progress_bar: request.noProgressBar
      };
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<VehicleResource.Vehicle>) => {
          observer.next({
            items: this.toPublicList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  get(request: Vehicle.GetRequest): Observable<Vehicle.Vehicle> {
    return Observable.create((observer: Observer<Vehicle.Vehicle>) => {
      const resourceRequest: VehicleResource.GetRequest = {
        id: request.id
      };
      return this.resourceService.get(resourceRequest).subscribe(
        (result: VehicleResource.Vehicle) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  create(request: Vehicle.CreateRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: VehicleResource.CreateRequest = {
        external_id: request.externalId,
        manufacturer: request.manufacturer,
        model: request.model,
        licence_plate: request.licencePlate,
        registration_date: Services.localDateToString(request.registrationDate)!,
        payload_kg: request.payloadKg,
        total_weight_kg: request.totalWeightKg,
        number_of_seats: request.numberOfSeats,
        color: request.color,
        comment: request.comment,
        etoll_required: request.etollRequired,
        registration_type: request.registrationType,
        owner_person_name: request.ownerPersonName,
        keeper_person_name: request.keeperPersonName,
        maturity_expiry_date: Services.localDateToString(request.maturityExpiryDate),
        company_id: request.companyId,
        km_counter: request.kmCounter,
        passes: this.toResourceVehiclePassList(request.passes)
      };
      return this.resourceService.create(resourceRequest).subscribe(
        (result: IdentityMessage) => {
          observer.next(result);
        },
        (error: any) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  update(request: Vehicle.UpdateRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: VehicleResource.UpdateRequest = {
        id: request.id,
        external_id: request.externalId,
        manufacturer: request.manufacturer,
        model: request.model,
        licence_plate: request.licencePlate,
        registration_date: Services.localDateToString(request.registrationDate)!,
        payload_kg: request.payloadKg,
        total_weight_kg: request.totalWeightKg,
        number_of_seats: request.numberOfSeats,
        color: request.color,
        comment: request.comment,
        etoll_required: request.etollRequired,
        registration_type: request.registrationType,
        owner_person_name: request.ownerPersonName,
        keeper_person_name: request.keeperPersonName,
        maturity_expiry_date: Services.localDateToString(request.maturityExpiryDate),
        company_id: request.companyId,
        km_counter: request.kmCounter,
        passes: this.toResourceVehiclePassList(request.passes)
      };
      return this.resourceService.update(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  setDisabled(request: Vehicle.DisableRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: VehicleResource.DisableRequest = {
        id: request.id,
        disabled: request.disabled
      };
      return this.resourceService.setDisabled(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }


  private translateError(error: any): any {
    const res = ObservableErrorResourceParser.parseError(error);
    const fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
    const fieldErrorMap = ObservableErrorResourceParser.toFieldErrorMap(Keys.toValidatedField, fieldErrors);
    if (!fieldErrorMap.isEmpty()) {
      return FieldValidationError.of(fieldErrorMap);
    }
    return error;
  }

  private toPublicList(resourceList: VehicleResource.Vehicle[]): List<Vehicle.Vehicle> {
    return List.of(...resourceList.map((r) => this.toPublic(r)));
  }

  private toPublic(r: VehicleResource.Vehicle): Vehicle.Vehicle {
    return {
      id: r.id,
      externalId: r.external_id,
      manufacturer: r.manufacturer,
      model: r.model,
      licencePlate: r.licence_plate,
      registrationDate: Services.toLocalDate(r.registration_date),
      payloadKg: r.payload_kg,
      totalWeightKg: r.total_weight_kg,
      numberOfSeats: r.number_of_seats,
      color: r.color,
      comment: r.comment,
      etollRequired: r.etoll_required,
      registrationType: r.registration_type,
      ownerPersonName: r.owner_person_name,
      keeperPersonName: r.keeper_person_name,
      maturityExpiryDate: Services.toLocalDate(r.maturity_expiry_date),
      company: this.toVehicleCompany(r.company),
      kmCounter: r.km_counter,
      disabled: r.disabled,
      passes: this.toPublicVehiclePassList(r.passes)
    };
  }

  private toVehicleCompany(r?: VehicleResource.VehicleCompany): Vehicle.VehicleCompany {
    if (r) {
      return {
        name: r.name,
        id: r.id,
      };
    }
    return {
      name: '',
      id: 0,
    };
  }

  private toPublicVehiclePassList(resourceList: VehicleResource.VehiclePass[]): Vehicle.VehiclePass[] {
    return resourceList.map((r) => this.toPublicVehiclePass(r));
  }

  private toPublicVehiclePass(r: VehicleResource.VehiclePass): Vehicle.VehiclePass {
    return {
      type: r.type,
      name: r.name,
      validityStartDate: Services.toOffsetDateTime(r.validity_start_date),
      validityEndDate: Services.toOffsetDateTime(r.validity_end_date),
      note: r.note
    };
  }

  history(request: Vehicle.HistoryRequest): Observable<QueryResult<Vehicle.HistoryItem>> {
    return Observable.create((observer: Observer<QueryResult<Vehicle.HistoryItem>>) => {
      const resourceRequest: CompanyResource.HistoryRequest = {
        id: request.id,
        with_read: request.withRead,
        q: request.queryText,
        order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
      };
      return this.resourceService.history(resourceRequest).subscribe(
        (result: ResourceQueryResult<CompanyResource.HistoryItem>) => {
          observer.next({
            items: this.toPublicHistoryList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });

    });
  }

  private toPublicHistoryList(resourceList: CompanyResource.HistoryItem[]): List<Vehicle.HistoryItem> {
    return List.of(...resourceList.map((r) => this.toPublicHistory(r)));
  }

  private toPublicHistory(r: CompanyResource.HistoryItem): Vehicle.HistoryItem {
    return {
      id: r.id,
      creationTime: Services.toOffsetDateTime(r.creation_time),
      type: <VehicleHistoryType>r.type,
      changeLog: r.change_log,
      applicationClassification: r.application_classification,
      issuerUser: this.toPublicUser(r.issuer_user!),
      mobileApplication: this.toPublicMobileApplication(r.mobile_application)
    };
  }

  private toPublicUser(r: UserItemResource): UserItem {
    return {
      id: r.id,
      personName: r.person_name
    };
  }

  private toPublicMobileApplication(r: HistoryLog.MobileApplicationItemResource | undefined):
    HistoryLog.MobileApplicationItem | undefined {
    if (r) {
      return {
        id: r.id,
        applicationId: r.application_id
      };
    }
    return undefined;
  }

  private toResourceVehiclePassList(resourceList: Vehicle.VehiclePass[]): VehicleResource.VehiclePass[] {
    return resourceList.map((r) => this.toResourceVehiclePass(r));
  }

  private toResourceVehiclePass(p: Vehicle.VehiclePass): VehicleResource.VehiclePass {
    return {
      type: p.type,
      name: p.name,
      validity_start_date: Services.offsetDateTimeToString(p.validityStartDate)!,
      validity_end_date: Services.offsetDateTimeToString(p.validityEndDate)!,
      note: p.note
    };
  }


}

export namespace Vehicle {

  export interface Service {

    // <editor-fold desc="CRUD">

    query(request: QueryRequest): Observable<QueryResult<Vehicle>>;

    get(request: GetRequest): EmptyMessage;

    create(request: CreateRequest): EmptyMessage;

    update(request: UpdateRequest): EmptyMessage;

    setDisabled(request: DisableRequest): EmptyMessage;

    // </editor-fold>

  }

  export interface Vehicle {
    id: number;
    externalId: string;
    manufacturer?: string;
    model?: string;
    licencePlate: string;
    registrationDate?: LocalDate;
    payloadKg?: number;
    totalWeightKg?: number;
    numberOfSeats?: number;
    color?: string;
    comment?: string;
    vignettes?: string;
    entryPermissions?: string;
    etollRequired: boolean;
    registrationType?: string;
    ownerPersonName?: string;
    keeperPersonName?: string;
    maturityExpiryDate?: LocalDate;
    company: VehicleCompany;
    kmCounter: number;
    disabled: boolean;
    passes: VehiclePass[];
  }

  export interface QueryRequest {
    id?: Set<number>;
    externalId?: string;
    manufacturer?: string;
    model?: string;
    registrationType?: string;
    licencePlate?: string;
    registrationDateFrom?: LocalDate;
    registrationDateTo?: LocalDate;
    payloadKgFrom?: number;
    payloadKgTo?: number;
    totalWeightKgFrom?: number;
    totalWeightKgTo?: number;
    etollRequired?: boolean;
    disabled?: boolean;
    companyIds?: Set<number>;
    queryText?: string;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
    noProgressBar?: boolean;
  }

  export interface GetRequest {
    id: number;
  }

  export interface CreateRequest {
    externalId?: string;
    manufacturer: string;
    model: string;
    licencePlate: string;
    registrationDate: LocalDate;
    payloadKg: number;
    totalWeightKg: number;
    numberOfSeats?: number;
    color?: string;
    comment?: string;
    vignettes?: string;
    entryPermissions?: string;
    etollRequired: boolean;
    registrationType: string;
    ownerPersonName: string;
    keeperPersonName?: string;
    maturityExpiryDate?: LocalDate;
    companyId: number;
    kmCounter: number;
    passes: VehiclePass[];
  }

  export interface UpdateRequest {
    id: number;
    externalId: string;
    manufacturer: string;
    model: string;
    licencePlate: string;
    registrationDate: LocalDate;
    payloadKg: number;
    totalWeightKg: number;
    numberOfSeats?: number;
    color?: string;
    comment?: string;
    vignettes?: string;
    entryPermissions?: string;
    etollRequired: boolean;
    registrationType: string;
    ownerPersonName: string;
    keeperPersonName?: string;
    maturityExpiryDate?: LocalDate;
    companyId: number;
    kmCounter: number;
    passes: VehiclePass[];
  }

  export interface DisableRequest {
    id: number;
    disabled: boolean;
  }

  export interface VehicleCompany {
    id: number;
    name: string;
  }

  export interface VehiclePass {
    type: VehiclePassType;
    name: string;
    validityStartDate: OffsetDateTime;
    validityEndDate: OffsetDateTime;
    note?: string;
  }

  export type VehiclePassType = 'MOTORWAY' | 'PARKING' | 'ENTRY';

  export class VehiclePassTypeObject {
    type: VehiclePassType;
    stringKey: string;
  }

  export const vehiclePassTypes: VehiclePassTypeObject[] = [
    {type: 'MOTORWAY', stringKey: 'VEHICLE_PASS_TYPE_MOTORWAY'},
    {type: 'PARKING', stringKey: 'VEHICLE_PASS_TYPE_PARKING'},
    {type: 'ENTRY', stringKey: 'VEHICLE_PASS_TYPE_ENTRY'},
  ];

  export interface HistoryRequest {
    id: number;
    withRead: boolean;
    queryText?: string;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
  }

  export interface HistoryItem extends HistoryLog.HistoryItemBase {
    type: VehicleHistoryType;
  }

  export type VehicleHistoryType =
    'READ' |
    'CREATE' |
    'UPDATE' |
    'DISABLE' |
    'ENABLE' |
    'ROLE_UPDATE';

  export class VehicleHistoryTypeObject {
    type: VehicleHistoryType;
    stringKey: string;
  }

  export const vehicleHistoryTypes: VehicleHistoryTypeObject[] = [
    {type: 'READ', stringKey: 'VEHICLE_HISTORY_TYPE_READ'},
    {type: 'CREATE', stringKey: 'VEHICLE_HISTORY_TYPE_CREATE'},
    {type: 'UPDATE', stringKey: 'VEHICLE_HISTORY_TYPE_UPDATE'},
    {type: 'DISABLE', stringKey: 'VEHICLE_HISTORY_TYPE_DISABLE'},
    {type: 'ENABLE', stringKey: 'VEHICLE_HISTORY_TYPE_ENABLE'},
  ];

  export enum OrderField {
    ID,
    NAME,
    EXTERNAL_ID,
    COMPANY,
    LICENCE_PLATE,
    HISTORY_CREATION_TIME,
    HISTORY_ISSUER_USER_PERSON_NAME,
    HISTORY_MOBILE_APPLICATION_APPLICTAION_ID
  }

  export enum ValidatedField {
    UNKNOWN,

    EXTERNAL_ID,
    NAME,
    LICENCE_PLATE,
    REGISTRATION_DATE,
    PAYLOAD_KG,
    VIGNETTES,
    ENTRY_PERMISSIONS,
    REGISTRATION_TYPE,
    COMPANY_ID,
    TOTAL_WEIGHT_KG,
    ETOLL_REQUIRED,
    KM_COUNTER

  }
}


class Keys {

  private static readonly ID = 'id';
  private static readonly EXTERNAL_ID = 'external_id';
  private static readonly NAME = 'name';
  private static readonly VIGNETTES = 'vignettes';
  private static readonly ENTRY_PERMISSIONS = 'entry_permissions';
  private static readonly REGISTRATION_TYPE = 'registration_type';
  private static readonly COMPANY_ID = 'company_id';
  private static readonly COMPANY = 'company';
  private static readonly LICENCE_PLATE = 'licence_plate';
  private static readonly REGISTRATION_DATE = 'registration_date';
  private static readonly PAYLOAD_KG = 'payload_kg';
  private static readonly KM_COUNTER = 'km_counter';
  private static readonly TOTAL_WEIGHT_KG = 'total_weight_kg';
  private static readonly ETOLL_REQUIRED = 'etoll_required';
  private static readonly HISTORY_CREATION_TIME = 'creation_time';
  private static readonly HISTORY_ISSUER_USER_PERSON_NAME = 'issuer_user_person_name';
  private static readonly HISTORY_MOBILE_APPLICATION_APPLICTAION_ID = 'mobile_application_application_id';


  private static readonly orderFieldKeyMap: Map<Vehicle.OrderField, string> = Map.of(
    Vehicle.OrderField.ID, Keys.ID,
    Vehicle.OrderField.NAME, Keys.NAME,
    Vehicle.OrderField.EXTERNAL_ID, Keys.EXTERNAL_ID,
    Vehicle.OrderField.COMPANY, Keys.COMPANY,
    Vehicle.OrderField.LICENCE_PLATE, Keys.LICENCE_PLATE,
    Vehicle.OrderField.HISTORY_CREATION_TIME, Keys.HISTORY_CREATION_TIME,
    Vehicle.OrderField.HISTORY_ISSUER_USER_PERSON_NAME, Keys.HISTORY_ISSUER_USER_PERSON_NAME,
    Vehicle.OrderField.HISTORY_MOBILE_APPLICATION_APPLICTAION_ID, Keys.HISTORY_MOBILE_APPLICATION_APPLICTAION_ID,
  );

  private static readonly keyValidatedFieldMap: Map<string, Vehicle.ValidatedField> = Map.of(
    Keys.EXTERNAL_ID, Vehicle.ValidatedField.EXTERNAL_ID,
    Keys.NAME, Vehicle.ValidatedField.NAME,
    Keys.LICENCE_PLATE, Vehicle.ValidatedField.LICENCE_PLATE,
    Keys.VIGNETTES, Vehicle.ValidatedField.VIGNETTES,
    Keys.ENTRY_PERMISSIONS, Vehicle.ValidatedField.ENTRY_PERMISSIONS,
    Keys.REGISTRATION_TYPE, Vehicle.ValidatedField.REGISTRATION_TYPE,
    Keys.COMPANY_ID, Vehicle.ValidatedField.COMPANY_ID,
    Keys.REGISTRATION_DATE, Vehicle.ValidatedField.REGISTRATION_DATE,
    Keys.PAYLOAD_KG, Vehicle.ValidatedField.PAYLOAD_KG,
    Keys.KM_COUNTER, Vehicle.ValidatedField.KM_COUNTER,
    Keys.TOTAL_WEIGHT_KG, Vehicle.ValidatedField.TOTAL_WEIGHT_KG,
    Keys.ETOLL_REQUIRED, Vehicle.ValidatedField.ETOLL_REQUIRED,
  );

  public static toOrderFieldKey(field: Vehicle.OrderField): string {
    return Keys.orderFieldKeyMap.get(field)!;
  }

  public static toValidatedField(fieldKey: string): Vehicle.ValidatedField {
    return Keys.keyValidatedFieldMap.get(fieldKey, Vehicle.ValidatedField.UNKNOWN);
  }

}
