/* eslint-disable */
import { map } from 'rxjs/operators';
import { DownloadedFile, } from '../util/downloaded-files';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlBuilder } from '../../util/url-builder';
import { ResourceQueryResult } from '../util/services';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { EmptyMessage, IdentityMessage } from '../util/messages';

/* eslint-enable */

@Injectable()
export class LegacyProcessResourceService extends BaseHttpService {

  query(request: LegacyProcessResource.QueryRequest): Observable<ResourceQueryResult<LegacyProcessResource.LegacyProcess>> {
    return this.http.get<ResourceQueryResult<LegacyProcessResource.LegacyProcess>>(this.url, this.parseParams(request));
  }

  get(request: LegacyProcessResource.GetRequest): Observable<LegacyProcessResource.LegacyProcess> {
    return this.http.get<LegacyProcessResource.LegacyProcess>(this.url + `${request.id}`, this.parseParams(request));
  }

  create(request: LegacyProcessResource.CreateRequest): Observable<LegacyProcessResource.CreateResponse> {
    return this.http.post<LegacyProcessResource.CreateResponse>(this.url, request);
  }

  update(request: LegacyProcessResource.UpdateRequest): Observable<EmptyMessage> {
    return this.http.put(this.url + `${request.id}`, request);
  }

  changeLog(request: LegacyProcessResource.ChangeLogRequest): Observable<ResourceQueryResult<LegacyProcessResource.ChangeLog>> {
    return this.http.get<ResourceQueryResult<LegacyProcessResource.ChangeLog>>(this.url + `${request.id}/change-logs`);
  }

  startProcess(request: IdentityMessage): Observable<EmptyMessage> {
    return this.http.post<EmptyMessage>(this.url + `${request.id}/start`, null);
  }

  closeProcess(request: LegacyProcessResource.CloseProcessRequest): Observable<EmptyMessage> {
    return this.http.post<EmptyMessage>(this.url + `${request.id}/close?reject-orders=${request.reject_orders}`, null);
  }

  continueCurrentTask(request: IdentityMessage): Observable<EmptyMessage> {
    return this.http.post<EmptyMessage>(this.url + `${request.id}/continue-current-task`, null);
  }

  continuePreviousTask(request: IdentityMessage): Observable<EmptyMessage> {
    return this.http.post<EmptyMessage>(this.url + `${request.id}/continue-previous-task`, null);
  }

  reopenProcess(request: IdentityMessage): Observable<EmptyMessage> {
    return this.http.post<EmptyMessage>(this.url + `${request.id}/reopen`, null);
  }

  deleteOrder(request: LegacyProcessResource.DeleteOrderRequest): Observable<EmptyMessage> {
    return this.http.request('delete', this.url + `${request.process_id}/orders`, {body: request});
  }

  addOrders(request: LegacyProcessResource.AddOrderRequest): Observable<EmptyMessage> {
    return this.http.post<EmptyMessage>(this.url + `${request.process_id}/orders`, request);
  }

  mplExport(request: LegacyProcessResource.MplExportRequest): Observable<DownloadedFile> {
    const url = UrlBuilder.create('/legacy-processes/:processId/mpl-request-document-generate?{:export_rows_with_shipment_tracking_number}')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .namedNumber('processId', request.process_id)
      .queryString('export_rows_with_shipment_tracking_number', 'true')
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob',
      params: this.parseParams(request).params
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  downloadOrderDocumentZip(request: LegacyProcessResource.OrderDocumentZipDownloadRequest): Observable<DownloadedFile> {
    const url = UrlBuilder.create('/legacy-processes/:processId/order_documents/zip')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .namedNumber('processId', request.process_id)
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob',
      params: this.parseParams(request).params
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  constructor(private http: HttpClient, private resourceHelper: ResourceHelper) {
    super(resourceHelper, '/legacy-processes/');
  }

}

export namespace LegacyProcessResource {

  export interface GetRequest {
    id: number;
    with_order_count: boolean;
    with_stock_items: boolean;
  }

  export interface QueryRequest {
    fields?: string;
    process_id?: string;
    state?: string;
    workflow_name?: string;
    name?: string;
    deadline_from?: string;
    deadline_to?: string;
    external_id?: string;
    number_of_orders_from?: string;
    number_of_orders_to?: string;
    creation_time_from?: string;
    creation_time_to?: string;
    with_order_count: boolean;
    order?: string;
    page_number?: number;
    number_of_items?: number;
  }

  export interface CreateRequest {
    workflow_id: number;
    usage_type: string;
    order_ids: number[];
    name?: string;
    external_id?: string;
    description?: string;
    deadline?: string;
  }

  export interface CreateResponse {
    process_id: number;
  }

  export interface UpdateRequest {
    id: number;
    name?: string;
    external_id: string;
    description?: string;
    deadline?: string;
  }

  export interface ChangeLogRequest {
    id: number;
    order?: string;
    page_number?: number;
    number_of_items?: number;
  }

  export interface CloseProcessRequest {
    id: number;
    reject_orders: boolean;
  }

  export interface DeleteOrderRequest {
    process_id: number;
    order_ids: number[];
  }

  export interface AddOrderRequest {
    process_id: number;
    order_ids: number[];
  }

  export interface LegacyProcess {
    process_id: number;
    version: number;
    workflow_usage_type: string;
    state: string;
    workflow_id: number;
    order_count?: number;
    display_name?: string;
    external_id: string;
    display_description: string;
    deadline?: string;
    creation_time: string;
    update_time: string;
  }

  export interface ChangeLog {
    type: string;
    creation_time: string;
    process_task_id?: number;
    user_id: number;
  }

  export interface OrderDocumentZipDownloadRequest {
    process_id: number;
    pdf_merge_method: string;
  }

  export interface MplExportRequest {
    process_id: number;
    group_by_identical_items: boolean;
  }

}
