/* eslint-disable */
import { DqlModel } from '../dql/dql.model';
import { Map } from 'immutable';
import { ShippingDemand } from './shipping-demand.service';
/* eslint-enable */

export class ShippingDemandDqlFieldTextProvider {
  public static getHelper(): DqlModel.DqlStaticFieldTextHelper {
    const fields: Map<string, DqlModel.DqlFieldTexts> = Map.of(
      'name', {stringKey: 'COMMON_NAME'},
      'id', {stringKey: 'COMMON_TABLE_HEADER_ID'},
      'external_id', {stringKey: 'COMMON_EXTERNAL_ID'},
      'creation_time', {stringKey: 'COMMON_TABLE_HEADER_CREATION_TIME'},
      'update_time', {stringKey: 'COMMON_TABLE_HEADER_UPDATE_TIME'},
      'state', {
        stringKey: 'COMMON_STATUS', enumType: ShippingDemand.shippingDemandStates.map((value: ShippingDemand.ShippingDemandStateObject) => {
          return {id: <string>value.state, stringKey: value.stringKey};
        })
      },
      'safety_shipping', {stringKey: 'SHIPPING_DEMAND_SEARCH_SAFETY_SHIPPING'},
      'recipient_name', {stringKey: 'SHIPPING_DEMAND_RECIPIENT'},
      'transferor_name', {stringKey: 'SHIPPING_DEMAND_TRANSFEROR'},
      'transporter_id', {stringKey: 'SHIPPING_DEMAND_SHIPMENT_TRANSPORT_COMPANY'},
      'demander_id', {stringKey: 'SHIPPING_DEMAND_SHIPMENT_DEMANDER_COMPANY'},
      'source_name', {stringKey: 'SHIPPING_DEMAND_SOURCE_NAME'},
      'destination_name', {stringKey: 'SHIPPING_DEMAND_DESTINATION_NAME'},
      'source_location_id', {stringKey: 'SHIPPING_DEMAND_SOURCE_LOCATION'},
      'destination_location_id', {stringKey: 'SHIPPING_DEMAND_DESTINATION_LOCATION'},
      'source_postal_address', {stringKey: 'SHIPPING_DEMAND_SEARCH_SOURCE_ADDRESS'},
      'destination_postal_address', {stringKey: 'SHIPPING_DEMAND_SEARCH_DESTINATION_ADDRESS'},
      'ekaer_id', {stringKey: 'SHIPPING_DEMAND_SHIPMENT_EKAER_ID'},
      'ekaer_required', {stringKey: 'SHIPPING_DEMAND_SHIPMENT_EKAER_REQUIRED'},
      'tour_route', {stringKey: 'SHIPPING_DEMAND_SEARCH_TOUR_ROUTE'},
      'shipping_item', {stringKey: 'SHIPPING_ITEM_SEARCH_FIELD'},
    );
    return new DqlModel.DqlStaticFieldTextHelper(fields);
  }
}
