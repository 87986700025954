/* eslint-disable */
import { Injectable } from '@angular/core';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResourceQueryResult } from '../util/services';
import { EmptyMessage, IdentityMessage } from '../util/messages';
/* eslint-enable */

@Injectable()
export class ShopRenterResourceService extends BaseHttpService {

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/shoprenter/');
  }

  query(request: ShopRenterResource.QueryRequest): Observable<ResourceQueryResult<ShopRenterResource.ShopRenterShop>> {
    return this.http.get<ResourceQueryResult<ShopRenterResource.ShopRenterShop>>(this.url + 'shops', this.parseParams(request));
  }

  get(request: IdentityMessage): Observable<ShopRenterResource.ShopRenterShop> {
    return this.http.get<ShopRenterResource.ShopRenterShop>(this.url + 'shops/' + `${request.id}`);
  }

  sync(id: number): Observable<EmptyMessage> {
    const requestParameters = new HttpParams();
    return this.http.post<EmptyMessage>(`${this.url}shops/${id}/sync`, requestParameters);
  }

  import(id: number): Observable<EmptyMessage> {
    const requestParameters = new HttpParams();
    return this.http.post<EmptyMessage>(`${this.url}shops/${id}/import-products`, requestParameters);
  }

  update(request: ShopRenterResource.UpdateRequest): Observable<EmptyMessage> {
    return this.http.put<ShopRenterResource.ShopRenterShop>(this.url + 'shops/' + `${request.id}`, request);
  }

  installShop(request: ShopRenterResource.InstallShopRequest): Observable<ShopRenterResource.InstallShopResponse> {
    return this.http.post<ShopRenterResource.InstallShopResponse>(this.url + 'shops', request);
  }

  findShop(request: ShopRenterResource.FindShopRequest): Observable<ShopRenterResource.FindShopResponse> {
    return this.http.post<ShopRenterResource.FindShopResponse>(this.url + 'shops/find', request);
  }

  connectShopToMe(request: ShopRenterResource.ConnectShopRequest): Observable<ShopRenterResource.ConnectShopResponse> {
    return this.http.post<ShopRenterResource.FindShopResponse>(this.url + 'shops/connect', request);
  }

}

export namespace ShopRenterResource {

  export interface QueryRequest {
    fields?: string;
    filter?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
    rights?: string;
  }

  export interface ShopRenterShop {
    id: number;
    granted_rights?: string[];
    name: string;
    human_name?: string;
    company?: Company;
    user?: User;
    import_active: boolean;
    order_statuses: OrderStatus[];
    shipping_modes: ShippingMode[];
    mpl_shipping_mode_id?: number;
    importable_order_status_ids?: number[];
    imported_order_status_id?: number;
    unknown_product_order_status_id?: number;
    invalid_order_order_status_id?: number;
    insufficient_amount_order_status_id?: number;
    canceled_order_status_id?: number;
    returned_order_status_id?: number;
    delivered_order_status_id?: number;
    rejected_order_status_id?: number;
    reopened_order_status_id?: number;
    process_started_order_status_id?: number;
    process_finished_order_status_id?: number;
  }

  export interface User {
    id: number;
    person_name: string;
  }

  export interface Company {
    id: number;
    name: string;
  }

  export interface OrderStatus {
    id: number;
    name: string;
  }

  export interface ShippingMode {
    id: number;
    name: string;
    delivery_method?: {
      id: number;
      name: string;
    };
  }

  export interface ShippingModeUpdate {
    shop_renter_shipping_mode_id: number;
    delivery_method_id: number;
  }

  export interface UpdateRequest {
    id: number;
    human_name?: string;
    company_id?: number;
    import_active: boolean;
    shipping_modes: ShippingModeUpdate[];
    importable_order_status_ids: number[];
    imported_order_status_id: number;
    unknown_product_order_status_id?: number;
    invalid_order_order_status_id: number;
    insufficient_amount_order_status_id: number;
    canceled_order_status_id: number;
    returned_order_status_id: number;
    delivered_order_status_id: number;
    rejected_order_status_id: number;
    reopened_order_status_id?: number;
    process_started_order_status_id?: number;
    process_finished_order_status_id?: number;
  }

  export interface InstallShopRequest {
    referrer: string;
    shop_name: string;
    code: string;
    hmac: string;
    timestamp: number;
  }

  export interface InstallShopResponse {
    redirect_url: string;
  }

  export interface FindShopRequest {
    shop_name: string;
    code: string;
    hmac: string;
    timestamp: number;
  }

  export interface FindShopResponse {
    shop_id: number;
    user_id?: number;
    company_id?: number;
    current_user_id?: number;
  }

  export interface ConnectShopRequest {
    shop_id: number;
    shop_name: string;
    code: string;
    hmac: string;
    timestamp: number;
  }

  export interface ConnectShopResponse {
  }

}
