<div bsModal [config]="UiConstants.modalConfig" #dialog="bs-modal" class="modal fade"
     tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="dialogVisible && model">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title"><strong>{{'CUSTOMER_RECORD_CONTACT_LOCATION_DETAIL' | translate}}</strong></p>
        <button type="button" class="close" (click)="closeDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h3>{{model.name}}</h3>
        <div>{{formatPostalAddress(model.postalAddress)}}</div>
        <div class="text-muted">{{model.externalId}}</div>
        <div class="my-1" *ngIf="model.contractNumber">{{model.contractNumber}}</div>
        <div class="my-1" *ngIf="model.comment">{{model.comment}}</div>
        <div class="row m-0" *ngIf="model.openingTimes">
          <span class="mr-3">{{'COMMON_LOCATION_OPENING_TIME_HTML_TITLE' | translate}}</span>
          <div [innerHTML]="model.openingTimes"></div>
        </div>

        <h6 class="mt-3" *ngIf="contacts.length > 0">{{'CUSTOMER_RECORD_CONTACT_LOCATION_CONTACT_PERSONS' | translate}}</h6>
        <div class="row mx-0 my-1">
          <div class="col-md-6 p-0" *ngFor="let c of contacts">
            <app-customer-record-table-cell
              [customerRecord]="c"
              [imageSize]="48"
              [borderEnabled]="true">
            </app-customer-record-table-cell>
          </div>
        </div>

        <div class="col-md-12 d-flex justify-content-between p-0">
          <button type="button" class="btn btn-outline-primary" (click)="removeContactLocation()" *ngIf="!readonly">
            {{'COMMON_BUTTON_REMOVE' | translate}}
          </button>
          <button type="button" class="btn btn-primary ml-1" (click)="closeDialog()">
            {{'COMMON_BUTTON_CLOSE' | translate}}
          </button>
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal -->
  </div>
</div>
