/* eslint-disable */
import { defer as observableDefer, Observable, of as observableOf } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';
import { List, Map as ImmutableMap, Set } from 'immutable';
import { LoggerFactory } from '../../../../../../util/logger-factory';
import { ImmutableOptionItem, UiConstants } from '../../../../../../util/core-utils';
import { TranslateService } from '@ngx-translate/core';
import { StringKey } from '../../../../../../app.string-keys';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Form } from '../../../../../../lib/form/form.service';
import { ListItemResourceService, RawListItem } from '../../../../../../lib/list-item/list-item-resource.service';
import { ListItemTypeItem } from '../../../../../../util/list-item-utils';
import { ofCondition } from '../../../../../../util/wait';
import { FormGroupModel, FormModel } from '../../../../../../util/form/form-utils';
import { Angular2Multiselects } from '../../../../../../util/multiselect';
import { ToasterService } from '../../../../../../fork/angular2-toaster/angular2-toaster';
import { Arrays } from '../../../../../../lib/util/arrays';
import { AppValidators } from '../../../../../../util/app-validators';
import { FormRef, LocalFormGroupValidationErrors, ResourceQueryResult } from '../../../../../../lib/util/services';

/* eslint-enable */

@Component({
  selector: 'app-form-edit-create-update-list-item-activation-rule',
  templateUrl: 'form-edit-create-update-list-item-activation-rule.component.html',
  styleUrls: ['form-edit-create-update-list-item-activation-rule.component.scss'],
})
export class FormEditCreateUpdateListItemActivationRuleComponent implements OnInit, FormRef {

  RuleFormGroupKey = RuleFormGroupKey;
  RuleValidatorKey = RuleValidatorKey;
  StringKey = StringKey;

  private readonly logger = LoggerFactory.createLogger('FormEditCreateUpdateListItemActivationRuleComponent');

  private readonly fieldValidationErrors: Map<FormGroup, LocalFormGroupValidationErrors> = new Map();
  private readonly groupValidationErrors: Map<FormGroup, LocalFormGroupValidationErrors> = new Map();

  private listItemTypeId: string | null = null;
  private loadedAvailableListItems = false;
  private loadedAvailableFields = false;
  private loadedAvailableGroups = false;

  private submittedForm = false;

  readonly availableListItems: ListItem[] = [];
  readonly availableFields: Field[] = [];
  readonly availableGroups: Field[] = [];
  readonly fieldModel: Model;

  activatorDropdownSettings?: Angular2Multiselects.Settings;
  activatedFieldDropdownSettings?: Angular2Multiselects.Settings;

  private _parentFieldId: number;
  @Input()
  set parentFieldId(id: number | undefined) {
    if (!id) {
      return;
    }
    this._parentFieldId = id;
    if (this._formModel) {
      this.initAvailableFields(this._formModel);
    }
  }

  private _parentGroupId: number;
  @Input()
  set parentGroupId(id: number | undefined) {
    if (!id) {
      return;
    }
    this._parentGroupId = id;
    if (this._formModel) {
      this.initAvailableGroups(this._formModel);
    }
  }

  private _formModel: FormModel;
  @Input()
  set formModel(formModel: FormModel | undefined) {
    // Changes when the user selects an other FormField.
    this.logger.debug('set formModel', formModel);
    if (!formModel) {
      // The model is never undefined, but it is not loaded yet.
      // We will get it later.
      return;
    }
    this._formModel = formModel;
    this.initAvailableFields(formModel);
    this.initAvailableGroups(formModel);
  }

  @Input()
  set listItemTypeItem(listItemTypeItem: ListItemTypeItem | undefined) {
    // Changes when the user selects an other FormField or selects an other ListItem.
    this.logger.debug('set listItemTypeItem', listItemTypeItem);
    if (!listItemTypeItem) {
      // The item is never undefined, but it is not loaded yet.
      // We will get it later.
      return;
    }
    if (this.listItemTypeId !== listItemTypeItem.id) {
      this.clearAllRules();
      this.fetchAvailableListItems(listItemTypeItem.id);
      this.listItemTypeId = listItemTypeItem.id;
    }
  }

  @Input()
  set fieldActivationEnabled(fieldActivationEnabled: boolean) {
    // Changes when the user selects an other FormField and the field data is loaded from the server.
    this.logger.debug('set fieldActivationEnabled', fieldActivationEnabled);
    this.fieldModel.fieldActivationEnabled = fieldActivationEnabled;
  }

  @Input()
  set activatedFieldIdsByItemId(activatedFieldIdsByItemId: ImmutableMap<number, Set<number>>) {
    // Changes when the user selects an other FormField and the field data is loaded from the server.
    this.logger.debug('set activatedFieldIdsByItemId', activatedFieldIdsByItemId);
    this.restoreFields(activatedFieldIdsByItemId);
  }

  @Input()
  set activatedGroupIdsByItemId(activatedGroupIdsByItemId: ImmutableMap<number, Set<number>>) {
    // Changes when the user selects an other FormField and the field data is loaded from the server.
    this.logger.debug('set activatedGroupIdsByItemId', activatedGroupIdsByItemId);
    this.restoreGroups(activatedGroupIdsByItemId);
  }

  @Input()
  set submitted(submitted: boolean) {
    this.submittedForm = submitted;
  }

  get fieldRuleFormGroups(): FormGroup[] {
    return <FormGroup[]> this.fieldModel.fieldRules.controls;
  }

  get groupRuleFormGroups(): FormGroup[] {
    return <FormGroup[]> this.fieldModel.groupRules.controls;
  }

  get fieldActivationEnabled() {
    return this.fieldModel.fieldActivationEnabled;
  }

  get activatedFieldIdsByItemId(): ImmutableMap<number, Set<number>> {
    const map: Map<number, Set<number>> = new Map();
    this.fieldModel.fieldRules.controls.forEach((ruleFormGroup: FormGroup) => {
      const activator: ListItem = this.getActivatorValue(ruleFormGroup);
      const activatedFields: Field[] = this.getActivatedFields(ruleFormGroup);
      map.set(activator.id!, Set(activatedFields.map((activatedField) => {
        return activatedField.id!;
      })));
    });
    return ImmutableMap(map);
  }

  get activatedGroupIdsByItemId(): ImmutableMap<number, Set<number>> {
    const map: Map<number, Set<number>> = new Map();
    this.fieldModel.groupRules.controls.forEach((ruleFormGroup: FormGroup) => {
      const activator: ListItem = this.getActivatorValue(ruleFormGroup);
      const activatedGroups: Group[] = this.getActivatedGroups(ruleFormGroup);
      map.set(activator.id!, Set(activatedGroups.map((activatedField) => {
        return activatedField.id!;
      })));
    });
    return ImmutableMap(map);
  }

  get invalid() {
    this.logger.debug('get invalid');
    this.updateValueAndValidityOfActivators();
    let invalid = this.fieldModel.fieldRules.invalid;
    invalid = invalid || this.fieldModel.groupRules.invalid;
    if (invalid) {
      // If the form is invalid, we display the validation errors below the fields.
      // But the fields are hidden when the field activation is disabled.
      // So we enable the field activation.
      this.fieldModel.fieldActivationEnabled = true;
    }
    return invalid;
  }

  constructor(
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private listItemService: ListItemResourceService,
    private toasterService: ToasterService) {
    this.fieldModel = new Model(this.formBuilder);
  }

  ngOnInit() {
    this.initDropdownSettings();
  }

  onActivatorChange(ruleFormGroup: FormGroup) {
    this.updateValueAndValidityOfActivators();
  }

  getForm() {
    return {
      submitted: this.submittedForm
    };
  }

  getActivatorControl(ruleFormGroup: FormGroup): AbstractControl {
    return ruleFormGroup.controls[RuleFormGroupKey.ACTIVATOR]
  }

  getActivatedFieldsControl(ruleFormGroup: FormGroup): AbstractControl {
    return ruleFormGroup.controls[RuleFormGroupKey.ACTIVATED_FIELDS]
  }

  getActivatedGroupsControl(ruleFormGroup: FormGroup): AbstractControl {
    return ruleFormGroup.controls[RuleFormGroupKey.ACTIVATED_GROUPS]
  }

  getActivatorValue(ruleFormGroup: FormGroup): ListItem {
    return this.getActivatorControl(ruleFormGroup).value[0]
  }

  getActivatedFields(ruleFormGroup: FormGroup): Field[] {
    return this.getActivatedFieldsControl(ruleFormGroup).value
  }

  getActivatedGroups(ruleFormGroup: FormGroup): Field[] {
    return this.getActivatedGroupsControl(ruleFormGroup).value
  }

  hasLocalFieldFieldError(ruleFormGroup: FormGroup, formControlName?: string, errorCode?: string): boolean {
    const errors = this.fieldValidationErrors.get(ruleFormGroup);
    if (!errors) {
      return false;
    }
    return errors.hasFieldError(formControlName, errorCode);
  }

  hasLocalGroupFieldError(ruleFormGroup: FormGroup, formControlName?: string, errorCode?: string): boolean {
    const errors = this.fieldValidationErrors.get(ruleFormGroup);
    if (!errors) {
      return false;
    }
    return errors.hasFieldError(formControlName, errorCode);
  }

  reset() {
    this.logger.debug('reset');
    this.listItemTypeId = null;
    this.loadedAvailableListItems = false;
    this.loadedAvailableFields = false;
    this.loadedAvailableGroups = false;
    this.submittedForm = false;
    Arrays.clear(this.availableListItems);
    Arrays.clear(this.availableFields);
    Arrays.clear(this.availableGroups);
    this.clearAllRules();
    this.fieldModel.fieldActivationEnabled = false;
  }

  addFieldRuleItem(): void {
    if (this.listItemTypeId === null) {
      this.showSelectListItemTypeToast();
      return;
    }
    const activator: ListItem[] = [];
    const activatedFields: Field[] = [];
    this.pushFieldRuleItem(this.createFieldRuleItem(activator, activatedFields));
  }

  removeFieldRuleItem(ruleFormGroupIndex: number) {
    this.fieldModel.fieldRules.removeAt(ruleFormGroupIndex);
    this.updateValueAndValidityOfActivators();
  }

  addGroupRuleItem(): void {
    if (this.listItemTypeId === null) {
      this.showSelectListItemTypeToast();
      return;
    }
    const activator: ListItem[] = [];
    const activatedGroups: Group[] = [];
    this.pushGroupRuleItem(this.createGroupRuleItem(activator, activatedGroups));
  }

  removeGroupRuleItem(ruleFormGroupIndex: number) {
    this.fieldModel.groupRules.removeAt(ruleFormGroupIndex);
    this.updateValueAndValidityOfActivators();
  }

  private updateValueAndValidityOfActivators() {
    Arrays.forEachFormArray(this.fieldModel.fieldRules, (ruleFormGroup) => {
      this.getActivatorControl(<FormGroup>ruleFormGroup).updateValueAndValidity();
    });
    Arrays.forEachFormArray(this.fieldModel.groupRules, (ruleFormGroup) => {
      this.getActivatorControl(<FormGroup>ruleFormGroup).updateValueAndValidity();
    });
  }

  private showSelectListItemTypeToast() {
    this.toasterService.pop({
      timeout: UiConstants.ToastTimeoutLong,
      type: UiConstants.toastTypeWarning,
      title: this.translateService.instant(StringKey.COMMON_WARNING_TITLE),
      body: this.translateService.instant(StringKey.MESSAGE_SELECT_LIST_ITEM_TYPE),
    });
  }

  private clearAllRules() {
    this.clearFieldRules();
    this.clearGroupRules();
  }

  private clearFieldRules() {
    Arrays.clearFormArray(this.fieldModel.fieldRules);
    this.fieldValidationErrors.clear();
  }

  private clearGroupRules() {
    Arrays.clearFormArray(this.fieldModel.groupRules);
    this.groupValidationErrors.clear();
  }

  private fetchAvailableListItems(listItemTypeKey: string | null) {
    this.loadedAvailableListItems = false;
    if (listItemTypeKey === null) {
      this.loadedAvailableListItems = true;
      return;
    }
    Arrays.clear(this.availableListItems);
    this.listItemService.getList({
      type_key: listItemTypeKey,
      disabled: false
    }).subscribe(
      (result: ResourceQueryResult<RawListItem>) => {
        result.items.forEach((item) => {
          this.availableListItems.push(ListItem.fromRawListItem(item));
        });
        this.loadedAvailableListItems = true;
      },
      (error) => {
        this.loadedAvailableListItems = true;
      }
    );
  }

  private initAvailableFields(formModel: FormModel) {
    this.loadedAvailableFields = false;
    Arrays.clear(this.availableFields);
    formModel.formGroups.forEach(group => {
      group.fields.forEach((field) => {
        if ((this._parentFieldId && this._parentFieldId !== field.fieldId) || !this._parentFieldId) {
          this.availableFields.push(Field.fromFormField(field, group));
        }
      });
    });
    this.loadedAvailableFields = true;
  }

  private initAvailableGroups(formModel: FormModel) {
    this.loadedAvailableGroups = false;
    Arrays.clear(this.availableGroups);
    formModel.formGroups.forEach(group => {
      if ((this._parentGroupId && this._parentGroupId !== group.groupId) || !this._parentGroupId) {
        this.availableGroups.push(Group.fromFormGroup(group));
      }
    });
    this.loadedAvailableGroups = true;
  }

  private async restoreFields(activatedFieldIdsByItemId: ImmutableMap<number, Set<number>>) {
    this.logger.debug('restoreFields', activatedFieldIdsByItemId);
    if (activatedFieldIdsByItemId.size > 0) {
      this.logger.debug('restoreFields - await start');
      await ofCondition({
        condition: () => {
          return this.loadedAvailableListItems && this.loadedAvailableFields;
        }
      });
      this.logger.debug('restoreFields - await done');
    }
    this.clearFieldRules();
    activatedFieldIdsByItemId.forEach((fieldIds: Set<number>, listItemId: number) => {
      const activator: ListItem[] = this.findListItemById(listItemId);
      const activatedFields: Field[] = this.findFieldsById(fieldIds);
      this.pushFieldRuleItem(this.createFieldRuleItem(activator, activatedFields));
    });
  }

  private pushFieldRuleItem(ruleItem: FormGroup) {
    this.fieldModel.fieldRules.push(ruleItem);
    this.fieldValidationErrors.set(ruleItem, this.createLocalFormGroupValidationErrors(ruleItem));
  }

  private createFieldRuleItem(activator: ListItem[], activatedFields: Field[]): FormGroup {
    return this.formBuilder.group(
      {
        activator: this.formBuilder.control(
          activator,
          [
            AppValidators.notNull<number>({
              mapper: (value: ListItem) => {
                return value.id;
              }
            }),
            AppValidators.validateUniqueKey<number, ListItem>({
              keyProvider: (listItem: ListItem) => {
                return listItem.id;
              },
              keys: () => {
                return this.collectFieldActivatorIds();
              }
            })
          ]
        ),
        activatedFields: this.formBuilder.control(
          activatedFields,
          [
            AppValidators.minArraySizeControl({
              minSize: 1
            })
          ]
        )
      }
    );
  }

  private async restoreGroups(activatedGroupIdsByItemId: ImmutableMap<number, Set<number>>) {
    this.logger.debug('restoreGroups', activatedGroupIdsByItemId);
    if (activatedGroupIdsByItemId.size > 0) {
      this.logger.debug('restoreGroups - await start');
      await ofCondition({
        condition: () => {
          return this.loadedAvailableListItems && this.loadedAvailableGroups;
        }
      });
      this.logger.debug('restoreGroups - await done');
    }
    this.clearGroupRules();
    activatedGroupIdsByItemId.forEach((groupIds: Set<number>, listItemId: number) => {
      const activator: ListItem[] = this.findListItemById(listItemId);
      const activatedGroups: Group[] = this.findGroupsById(groupIds);
      this.pushGroupRuleItem(this.createGroupRuleItem(activator, activatedGroups));
    });
  }

  private pushGroupRuleItem(ruleItem: FormGroup) {
    this.fieldModel.groupRules.push(ruleItem);
    this.groupValidationErrors.set(ruleItem, this.createLocalFormGroupValidationErrors(ruleItem));
  }

  private createGroupRuleItem(activator: ListItem[], activatedGroups: Group[]): FormGroup {
    return this.formBuilder.group(
      {
        activator: this.formBuilder.control(
          activator,
          [
            AppValidators.notNull<number>({
              mapper: (value: ListItem) => {
                return value.id;
              }
            }),
            AppValidators.validateUniqueKey<number, ListItem>({
              keyProvider: (listItem: ListItem) => {
                return listItem.id;
              },
              keys: () => {
                return this.collectGroupActivatorIds();
              }
            })
          ]
        ),
        activatedGroups: this.formBuilder.control(
          activatedGroups,
          [
            AppValidators.minArraySizeControl({
              minSize: 1
            })
          ]
        )
      }
    );
  }

  private createLocalFormGroupValidationErrors(formGroup: FormGroup) {
    return LocalFormGroupValidationErrors.ofForm(this, formGroup);
  }

  private findListItemById(id: number): ListItem[] {
    const array = this.availableListItems.filter((field) => {
      return field.id === id;
    });
    if (array.length === 1) {
      return array;
    }
    return [];
  }

  private findFieldsById(ids: Set<number>): Field[] {
    return this.availableFields.filter((field) => {
      return ids.contains(field!.id!);
    });
  }

  private findGroupsById(ids: Set<number>): Group[] {
    return this.availableGroups.filter((group) => {
      return ids.contains(group!.id!);
    });
  }

  private collectFieldActivatorIds(): number[] {
    const ids: number[] = [];
    Arrays.forEachFormArray(this.fieldModel.fieldRules, (rule) => {
      const ruleFormGroup = <FormGroup>rule;
      const item = this.getActivatorValue(ruleFormGroup);
      if (item && item.id) {
        ids.push(item.id);
      }
    });
    return ids;
  }

  private collectGroupActivatorIds(): number[] {
    const ids: number[] = [];
    Arrays.forEachFormArray(this.fieldModel.groupRules, (rule) => {
      const ruleFormGroup = <FormGroup>rule;
      const item = this.getActivatorValue(ruleFormGroup);
      if (item && item.id) {
        ids.push(item.id);
      }
    });
    return ids;
  }

  private initDropdownSettings() {
    this.activatorDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .build();
    this.activatedFieldDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .badgeShowLimit(10)
      .build();
  }

}

class Model {

  fieldActivationEnabled: boolean = false;
  fieldRules: FormArray;
  groupRules: FormArray;

  constructor(formBuilder: FormBuilder) {
    this.fieldRules = formBuilder.array([]);
    this.groupRules = formBuilder.array([]);
  }

}

class Field extends ImmutableOptionItem<number> {

  public static fromFormField(field: Form.Field, group: FormGroupModel): Field {
    const localizedNameObs = observableDefer(() => observableOf(field.title));
    const subtitleNameObs = observableDefer(() => observableOf(group.title + ' (' + group.groupId + ')'));
    return new Field(field.fieldId, localizedNameObs, subtitleNameObs);
  }

}

class Group extends ImmutableOptionItem<number> {

  public static fromFormGroup(group: FormGroupModel): Field {
    const localizedNameObs = observableDefer(() => observableOf(group.title));
    return new Group(group.groupId, localizedNameObs);
  }

}

class ListItem extends ImmutableOptionItem<number> {

  public static absent(text: Observable<string>): ListItem {
    return new ListItem(null, text);
  }

  public static fromRawListItem(item: RawListItem): ListItem {
    const nameObs = observableDefer(() => observableOf(item.text));
    return new Field(item.id, nameObs);
  }

  public static fromRawListItemArray(itemArray: RawListItem[], languageCode: string): List<ListItem> {
    return List.of(...itemArray.map((item) => {
      return ListItem.fromRawListItem(item);
    }));
  }

}

class RuleFormGroupKey {
  public static readonly ACTIVATOR = 'activator';
  public static readonly ACTIVATED_FIELDS = 'activatedFields';
  public static readonly ACTIVATED_GROUPS = 'activatedGroups';
}

class RuleValidatorKey {
  public static readonly REQUIRED_ACTIVATOR = 'notNull';
  public static readonly REQUIRED_ACTIVATED_FIELDS = 'minArraySizeControl';
  public static readonly UNIQUE_ACTIVATOR = 'validateUniqueKey';
}
