/* eslint-disable */
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Toast } from './toast';
import { BodyOutputType } from './bodyOutputType';
import { StringKey } from '../../../app.string-keys';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: '[toastComp]',
    template: `
        <i class="toaster-icon" [ngClass]="iconClass"></i>
        <div class="d-flex justify-content-between align-items-center">
          <div class="toast-content">
            <div [ngClass]="titleClass">{{toast.title}}</div>
            <div [ngClass]="messageClass" [ngSwitch]="toast.bodyOutputType">
              <div *ngSwitchCase="bodyOutputType.Component" #componentBody></div>
              <div *ngSwitchCase="bodyOutputType.TrustedHtml" [innerHTML]="safeBodyHtml"></div>
              <div *ngSwitchCase="bodyOutputType.Default">{{toast.body}}</div>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-center" *ngIf="toast.buttonClickHandler">
            <button type="button" class="btn position-relative cursor-pointer" (click)="buttonClickEvent.emit()">
              {{toastButtonText}}
            </button>
          </div>
        </div>
        <div class="toast-close-button" *ngIf="toast.showCloseButton" (click)="click($event, toast)"
            [innerHTML]="safeCloseHtml">
        </div>`
})

export class ToastComponent implements OnInit, AfterViewInit {

    @Input() toast: Toast;
    @Input() iconClass: string;
    @Input() titleClass: string;
    @Input() messageClass: string;
    @ViewChild('componentBody', { read: ViewContainerRef }) componentBody: ViewContainerRef;

    safeCloseHtml: SafeHtml;
    safeBodyHtml: SafeHtml;

    public bodyOutputType = BodyOutputType;

    @Output()
    public clickEvent = new EventEmitter();

    @Output()
    public buttonClickEvent = new EventEmitter();

    toastButtonText: string = '';

    constructor(
      private sanitizer: DomSanitizer,
      private componentFactoryResolver: ComponentFactoryResolver,
      private changeDetectorRef: ChangeDetectorRef,
      private translateService: TranslateService
    ) {}

    ngOnInit() {
        if (this.toast.closeHtml) {
            this.safeCloseHtml = this.sanitizer.bypassSecurityTrustHtml(this.toast.closeHtml);
        }
        if (this.toast.bodyOutputType === BodyOutputType.TrustedHtml) {
            this.safeBodyHtml = this.sanitizer.bypassSecurityTrustHtml(this.toast.body);
        }
        this.toastButtonText = this.toast.buttonStringKey
          ? this.translateService.instant(this.toast.buttonStringKey)
          : this.translateService.instant(StringKey.TOAST_BUTTON_VIEW_NOTIFICATION);
    }

    ngAfterViewInit() {
        if (this.toast.bodyOutputType === this.bodyOutputType.Component) {
            const component = this.componentFactoryResolver.resolveComponentFactory(this.toast.body);
            const componentInstance: any = this.componentBody.createComponent(component, undefined, this.componentBody.injector);
            componentInstance.instance.toast = this.toast;
            this.changeDetectorRef.detectChanges();
        }
    }

    click(event: MouseEvent, toast: Toast) {
        event.stopPropagation();
        this.clickEvent.emit({
            value : { toast: toast, isCloseButton: true}
        });
    }
}
