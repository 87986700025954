/* eslint-disable */
import { List, Map, Set } from 'immutable';
import { Injectable } from '@angular/core';
import { OffsetDateTime } from '../util/dates';
import { FieldValidationError, Order, PagingRequest, QueryResult, ResourceQueryResult, Services } from '../util/services';
import { Observable, Observer } from 'rxjs';
import { DownloadedFile } from '../util/downloaded-files';
import { LegacyProcessResource, LegacyProcessResourceService } from './legacy-process-resource.service';
import { EmptyMessage, IdentityMessage } from '../util/messages';
import { FieldError, ObservableErrorResourceParser } from '../util/errors';
import ProcessState = LegacyProcess.LegacyProcessState;
import ChangeLogType = LegacyProcess.ChangeLogType;

/* eslint-enable */

@Injectable()
export class LegacyProcessService implements LegacyProcess.Service {

  query(request: LegacyProcess.QueryRequest): Observable<QueryResult<LegacyProcess.LegacyProcess>> {
    return Observable.create((observer: Observer<QueryResult<LegacyProcess.LegacyProcess>>) => {
      const resourceRequest: LegacyProcessResource.QueryRequest = {
        fields: request.fields ? request.fields.join(',') : undefined,
        process_id: request.processId,
        state: request.state,
        workflow_name: request.workflowName,
        name: request.name,
        deadline_from: request.deadlineFrom && request.deadlineFrom.isValid() ? request.deadlineFrom.toUtcIsoString() : undefined,
        deadline_to: request.deadlineTo && request.deadlineTo.isValid() ? request.deadlineTo.toUtcIsoString() : undefined,
        external_id: request.externalId,
        number_of_orders_from: request.numberOfOrdersFrom,
        number_of_orders_to: request.numberOfOrdersTo,
        creation_time_from: request.creationTimeFrom && request.creationTimeFrom.isValid()
          ? request.creationTimeFrom.toUtcIsoString()
          : undefined,
        creation_time_to: request.creationTimeTo && request.creationTimeTo.isValid() ? request.creationTimeTo.toUtcIsoString() : undefined,
        with_order_count: request.withOrderCount === undefined ? false : request.withOrderCount,
        order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
      };
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<LegacyProcessResource.LegacyProcess>) => {
          observer.next({
            items: this.toPublicList(result.items),
            pagingResult: result.pagingResult
          });
        });
    });
  }

  create(request: LegacyProcess.CreateRequest): Observable<LegacyProcess.CreateResponse> {
    return Observable.create((observer: Observer<LegacyProcess.CreateResponse>) => {
      const resourceRequest: LegacyProcessResource.CreateRequest = {
        workflow_id: request.workflowId,
        usage_type: request.usageType,
        order_ids: request.orderIds,
        name: request.name,
        external_id: request.externalId,
        description: request.description,
        deadline: request.deadline && request.deadline.isValid() ? request.deadline.toUtcIsoString() : undefined
      };
      return this.resourceService.create(resourceRequest).subscribe(
        (result: LegacyProcessResource.CreateResponse) => {
          observer.next({
            processId: result.process_id
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        }
      );
    });
  }

  get(request: LegacyProcess.GetRequest): Observable<LegacyProcess.LegacyProcess> {
    return Observable.create((observer: Observer<LegacyProcess.LegacyProcess>) => {
      const resourceRequest: LegacyProcessResource.GetRequest = {
        id: request.id,
        with_order_count: request.withOrderCount === undefined ? false : request.withOrderCount,
        with_stock_items: request.withStockItems === undefined ? false : request.withStockItems,
      };
      return this.resourceService.get(resourceRequest).subscribe(
        (result: LegacyProcessResource.LegacyProcess) => {
          observer.next(this.toPublic(result));
        });
    });
  }

  update(request: LegacyProcess.UpdateRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: LegacyProcessResource.UpdateRequest = {
        id: request.id,
        name: request.name,
        external_id: request.externalId,
        description: request.description,
        deadline: request.deadline
      };
      return this.resourceService.update(resourceRequest).subscribe(
        () => {
          observer.next({});
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  changeLog(request: LegacyProcess.ChangeLogRequest): Observable<QueryResult<LegacyProcess.ChangeLog>> {
    return Observable.create((observer: Observer<QueryResult<LegacyProcess.ChangeLog>>) => {
      const resourceRequest: LegacyProcessResource.ChangeLogRequest = {
        id: request.id,
        order: Services.createOrderFieldParameter(Keys.toChangeLogOrderFieldKey, request.orders),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
      };
      return this.resourceService.changeLog(resourceRequest).subscribe(
        (result: ResourceQueryResult<LegacyProcessResource.ChangeLog>) => {
          observer.next({
            items: this.toPublicChangeLogList(result.items),
            pagingResult: result.pagingResult
          });
        });
    });
  }

  startProcess(request: IdentityMessage): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: IdentityMessage = {
        id: request.id
      };
      return this.resourceService.startProcess(resourceRequest).subscribe(
        () => {
          observer.next({});
        },
        (error) => {
          observer.error(error);
        });
    });
  }

  closeProcess(request: LegacyProcess.CloseProcessRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: LegacyProcessResource.CloseProcessRequest = {
        id: request.id,
        reject_orders: request.rejectOrders
      };
      return this.resourceService.closeProcess(resourceRequest).subscribe(
        () => {
          observer.next({});
        });
    });
  }

  continueCurrentTask(request: IdentityMessage): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: IdentityMessage = {
        id: request.id
      };
      return this.resourceService.continueCurrentTask(resourceRequest).subscribe(
        () => {
          observer.next({});
        });
    });
  }

  continuePreviousTask(request: IdentityMessage): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: IdentityMessage = {
        id: request.id
      };
      return this.resourceService.continuePreviousTask(resourceRequest).subscribe(
        () => {
          observer.next({});
        });
    });
  }

  reopenProcess(request: IdentityMessage): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: IdentityMessage = {
        id: request.id
      };
      return this.resourceService.reopenProcess(resourceRequest).subscribe(
        () => {
          observer.next({});
        });
    });
  }

  deleteOrder(request: LegacyProcess.DeleteOrderRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: LegacyProcessResource.DeleteOrderRequest = {
        process_id: request.processId,
        order_ids: request.orderIds
      };
      return this.resourceService.deleteOrder(resourceRequest).subscribe(
        () => {
          observer.next({});
        });
    });
  }

  addOrders(request: LegacyProcess.AddOrderRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: LegacyProcessResource.AddOrderRequest = {
        process_id: request.processId,
        order_ids: request.orderIds
      };
      return this.resourceService.addOrders(resourceRequest).subscribe(
        () => {
          observer.next({});
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        }
      );
    });
  }

  mplExport(request: LegacyProcess.MplExportRequest): Observable<DownloadedFile> {
    const resourceRequest: LegacyProcessResource.MplExportRequest = {
      process_id: request.processId,
      group_by_identical_items: request.groupByIdenticalItems
    };
    return this.resourceService.mplExport(resourceRequest);
  }

  downloadOrderDocumentZip(request: LegacyProcess.OrderDocumentZipDownloadRequest): Observable<DownloadedFile> {
    const resourceRequest: LegacyProcessResource.OrderDocumentZipDownloadRequest = {
      process_id: request.processId,
      pdf_merge_method: request.pdfMergeMethod
    };
    return this.resourceService.downloadOrderDocumentZip(resourceRequest);
  }

  constructor(
    private resourceService: LegacyProcessResourceService
  ) {
  }

  private toPublicList(resourceList: LegacyProcessResource.LegacyProcess[]): List<LegacyProcess.LegacyProcess> {
    return List.of(...resourceList.map((r) => this.toPublic(r)));
  }

  private toPublic(r: LegacyProcessResource.LegacyProcess): LegacyProcess.LegacyProcess {
    return {
      processId: r.process_id,
      version: r.version,
      workflowUsageType: r.workflow_usage_type,
      state: <ProcessState>r.state,
      workflowId: r.workflow_id,
      orderCount: r.order_count,
      displayName: r.display_name,
      externalId: r.external_id,
      displayDescription: r.display_description,
      deadline: Services.toOffsetDateTime(r.deadline),
      creationTime: Services.toOffsetDateTime(r.creation_time),
      updateTime: Services.toOffsetDateTime(r.update_time)
    };
  }

  private toPublicChangeLogList(resourceList: LegacyProcessResource.ChangeLog[]): List<LegacyProcess.ChangeLog> {
    return List.of(...resourceList.map((r) => this.toPublicChangeLog(r)));
  }

  private toPublicChangeLog(r: LegacyProcessResource.ChangeLog): LegacyProcess.ChangeLog {
    return {
      type: <ChangeLogType>r.type,
      creationTime: Services.toOffsetDateTime(r.creation_time),
      processTaskId: r.process_task_id,
      userId: r.user_id
    };
  }

  private translateError(error: any): any {
    const res = ObservableErrorResourceParser.parseError(error);
    const fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
    const fieldErrorMap = ObservableErrorResourceParser.toFieldErrorMap(Keys.toValidatedField, fieldErrors);
    if (!fieldErrorMap.isEmpty()) {
      return FieldValidationError.of(fieldErrorMap);
    }
    return error;
  }

}

export namespace LegacyProcess {

  export interface Service {

    query(request: QueryRequest): Observable<QueryResult<LegacyProcess>>;

    create(request: CreateRequest): Observable<LegacyProcess.CreateResponse>;

    get(request: GetRequest): Observable<LegacyProcess>;

    update(request: UpdateRequest): Observable<EmptyMessage>;

    changeLog(request: ChangeLogRequest): Observable<QueryResult<LegacyProcess.ChangeLog>>;

    startProcess(request: IdentityMessage): Observable<EmptyMessage>;

    closeProcess(request: CloseProcessRequest): Observable<EmptyMessage>;

    continueCurrentTask(request: IdentityMessage): Observable<EmptyMessage>;

    continuePreviousTask(request: IdentityMessage): Observable<EmptyMessage>;

    reopenProcess(request: IdentityMessage): Observable<EmptyMessage>;

    deleteOrder(request: DeleteOrderRequest): Observable<EmptyMessage>;

    addOrders(request: AddOrderRequest): Observable<EmptyMessage>;

  }

  export interface GetRequest {
    id: number;
    withOrderCount?: boolean;
    withStockItems?: boolean;
  }

  export interface QueryRequest {
    fields?: Set<string>;
    processId?: string;
    state?: LegacyProcessState;
    workflowName?: string;
    name?: string;
    deadlineFrom?: OffsetDateTime;
    deadlineTo?: OffsetDateTime;
    externalId?: string;
    numberOfOrdersFrom?: string;
    numberOfOrdersTo?: string;
    creationTimeFrom?: OffsetDateTime;
    creationTimeTo?: OffsetDateTime;
    withOrderCount?: boolean;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
  }

  export interface CreateRequest {
    workflowId: number;
    usageType: string;
    orderIds: number[];
    name?: string;
    externalId?: string;
    description?: string;
    deadline?: OffsetDateTime;
  }

  export interface CreateResponse {
    processId: number;
  }

  export interface UpdateRequest {
    id: number;
    name?: string;
    externalId: string;
    description?: string;
    deadline?: string;
  }

  export interface ChangeLogRequest {
    id: number;
    orders?: Set<Order<ChangeLogOrderField>>;
    paging?: PagingRequest;
  }

  export interface CloseProcessRequest {
    id: number;
    rejectOrders: boolean;
  }

  export interface DeleteOrderRequest {
    processId: number;
    orderIds: number[];
  }

  export interface AddOrderRequest {
    processId: number;
    orderIds: number[];
  }

  export interface LegacyProcess {
    processId: number;
    version: number;
    workflowUsageType: string;
    state: LegacyProcessState;
    workflowId: number;
    orderCount?: number;
    displayName?: string;
    externalId: string;
    displayDescription: string;
    deadline?: OffsetDateTime;
    creationTime: OffsetDateTime;
    updateTime: OffsetDateTime;
  }

  export interface ChangeLog {
    type: ChangeLogType;
    creationTime: OffsetDateTime;
    processTaskId?: number;
    processTaskName?: string;
    userId: number;
  }

  export interface MplExportRequest {
    processId: number;
    groupByIdenticalItems: boolean;
  }

  export interface OrderDocumentZipDownloadRequest {
    processId: number;
    pdfMergeMethod: PdfMergeMethod;
  }

  export type ChangeLogType =
    'PROCESS_START' |
    'PROCESS_CLOSE' |
    'PROCESS_REOPEN' |
    'PROCESS_CONTINUE_CURRENT' |
    'PROCESS_CONTINUE_PREVIOUS' |
    'TASK_START' |
    'TASK_FAIL' |
    'TASK_FINISH' |
    'PROCESS_FINISH';

  export class ChangeLogTypeObject {
    type: ChangeLogType;
    stringKey: string;
  }

  export const changeLogTypes: ChangeLogTypeObject[] = [
    {type: 'PROCESS_START', stringKey: 'PROCESS_CHANGE_LOG_TYPE_PROCESS_START'},
    {type: 'PROCESS_CLOSE', stringKey: 'PROCESS_CHANGE_LOG_TYPE_PROCESS_CLOSE'},
    {type: 'PROCESS_REOPEN', stringKey: 'PROCESS_CHANGE_LOG_TYPE_PROCESS_REOPEN'},
    {type: 'PROCESS_CONTINUE_CURRENT', stringKey: 'PROCESS_CHANGE_LOG_TYPE_PROCESS_CONTINUE_CURRENT'},
    {type: 'PROCESS_CONTINUE_PREVIOUS', stringKey: 'PROCESS_CHANGE_LOG_TYPE_PROCESS_CONTINUE_PREVIOUS'},
    {type: 'TASK_START', stringKey: 'PROCESS_CHANGE_LOG_TYPE_TASK_START'},
    {type: 'TASK_FAIL', stringKey: 'PROCESS_CHANGE_LOG_TYPE_TASK_FAIL'},
    {type: 'TASK_FINISH', stringKey: 'PROCESS_CHANGE_LOG_TYPE_TASK_FINISH'},
    {type: 'PROCESS_FINISH', stringKey: 'PROCESS_CHANGE_LOG_TYPE_PROCESS_FINISH'},
  ];

  export type LegacyProcessState = 'OPEN' | 'IN_PROGRESS' | 'CLOSED' | 'FINISHED' | 'FAILED';

  export class LegacyProcessStateObject {
    state: LegacyProcessState;
    stringKey: string;
    icon: string;
  }

  export const processStates: LegacyProcessStateObject[] = [
    {state: 'OPEN', stringKey: 'PROCESS_STATE_OPEN', icon: 'icomoon-new-state-change-open'},
    {state: 'IN_PROGRESS', stringKey: 'PROCESS_STATE_IN_PROGRESS', icon: 'icomoon-new-state-in-progress'},
    {state: 'CLOSED', stringKey: 'PROCESS_STATE_CLOSED', icon: 'icomoon-state-action-archive-alt'},
    {state: 'FINISHED', stringKey: 'PROCESS_STATE_FINISHED', icon: 'icomoon-new-state-finished'},
    {state: 'FAILED', stringKey: 'PROCESS_STATE_FAILED', icon: 'icomoon-state-action-invalidate'},
  ];

  export type PdfMergeMethod = 'OFF' | 'MERGE' | 'GROUPED_MERGE'

  export enum OrderField {
    PROCESS_ID,
    WORKFLOW_NAME,
    NAME,
    EXTERNAL_ID,
    DEADLINE,
    STATE,
    NUMBER_OF_ORDERS,
    CREATION_TIME
  }

  export enum ValidatedField {
    UNKNOWN,
    WORKFLOW_ID,
    NAME,
    EXTERNAL_ID
  }

  export enum ChangeLogOrderField {
    CREATION_TIME
  }

  export const MPL_STATE_IMPORT_PATH: string = '/legacy-processes/{process_id}/mpl-response-document-import' +
    '?overwrite_shipment_tracking_number=true&ignore_orders_outside_process=true';

}

class Keys {

  private static readonly PROCESS_ID = 'process_id';
  private static readonly WORKFLOW_NAME = 'workflow_name';
  private static readonly WORKFLOW_ID = 'workflow_id';
  private static readonly NAME = 'name';
  private static readonly EXTERNAL_ID = 'external_id';
  private static readonly DEADLINE = 'deadline';
  private static readonly STATE = 'state';
  private static readonly NUMBER_OF_ORDERS = 'number_of_orders';
  private static readonly CREATION_TIME = 'creation_time';

  private static readonly orderFieldKeyMap: Map<LegacyProcess.OrderField, string> = Map.of(
    LegacyProcess.OrderField.PROCESS_ID, Keys.PROCESS_ID,
    LegacyProcess.OrderField.WORKFLOW_NAME, Keys.WORKFLOW_NAME,
    LegacyProcess.OrderField.NAME, Keys.NAME,
    LegacyProcess.OrderField.EXTERNAL_ID, Keys.EXTERNAL_ID,
    LegacyProcess.OrderField.DEADLINE, Keys.DEADLINE,
    LegacyProcess.OrderField.STATE, Keys.STATE,
    LegacyProcess.OrderField.NUMBER_OF_ORDERS, Keys.NUMBER_OF_ORDERS,
    LegacyProcess.OrderField.CREATION_TIME, Keys.CREATION_TIME,
  );

  private static readonly validatedFieldKeyMap: Map<string, LegacyProcess.ValidatedField> = Map.of(
    Keys.WORKFLOW_ID, LegacyProcess.ValidatedField.WORKFLOW_ID,
    Keys.NAME, LegacyProcess.ValidatedField.NAME,
    Keys.EXTERNAL_ID, LegacyProcess.ValidatedField.EXTERNAL_ID,
  );

  private static readonly changeLogOrderFieldKeyMap: Map<LegacyProcess.ChangeLogOrderField, string> = Map.of(
    LegacyProcess.OrderField.CREATION_TIME, Keys.CREATION_TIME,
  );

  public static toOrderFieldKey(field: LegacyProcess.OrderField): string {
    return Keys.orderFieldKeyMap.get(field)!;
  }

  public static toValidatedField(fieldKey: string): LegacyProcess.ValidatedField {
    return Keys.validatedFieldKeyMap.get(fieldKey, LegacyProcess.ValidatedField.UNKNOWN);
  }

  public static toChangeLogOrderFieldKey(field: LegacyProcess.ChangeLogOrderField): string {
    return Keys.changeLogOrderFieldKeyMap.get(field)!;
  }

}

export interface ProcessEditFieldErrorMap {
  name?: FieldError;
  externalId?: FieldError;
}
