/* eslint-disable */
import { Component, OnInit, } from '@angular/core';
import { PermissionResource, PermissionResourceService, } from '../../lib/permission-resource.service';
import { AuthService } from '../../lib/auth.service';
/* eslint-enable */

@Component({
  selector: 'app-permission-list',
  templateUrl: 'permission-list.component.html',
  styleUrls: ['permission-list.component.scss'],
})
export class PermissionListComponent implements OnInit {

  showSearch: boolean = true;
  completePermissionList: PermissionResource.Permission[] = [];

  private searchPermissionList: PermissionResource.Permission[] = [];
  private userPermissionList: PermissionResource.Permission[] = [];

  searchedOnce: boolean = false;
  model: Model = new Model();

  constructor(
    private authService: AuthService,
    private permissionService: PermissionResourceService) {
  }

  ngOnInit() {
    this.authService.check({}).subscribe(
      (result) => {
        this.userPermissionList = result.granted_permissions!.map((pd: string) => {
          return {
            definition: pd,
            short_name: pd
          };
        });
      }
    );
    this.permissionService.query({
      explicit: false
    }).subscribe(
      (perms) => {
        this.completePermissionList = perms;
      }
    );
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  search() {
    this.permissionService.query({
      explicit: false,
      privilege_definition: this.model.privilegeDefinition
    }).subscribe(
      (perms) => {
        this.searchPermissionList = perms;
        this.searchedOnce = true;
      }
    );
  }

  isSearchMatch(permission: PermissionResource.Permission): boolean {
    return this.searchPermissionList
      .filter((p) => p.definition === permission.definition)
      .length > 0;
  }

  isCurrentUserMatch(permission: PermissionResource.Permission): boolean {
    return this.userPermissionList
      .filter((p) => p.definition === permission.definition)
      .length > 0;
  }

}

class Model {
  privilegeDefinition: string = '';
}
