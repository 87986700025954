<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <!--SEARCH-->
  <div class="card">
    <div class="card-header">
      {{'TASKS_SEARCH_PANEL_HEADING_LIST' | translate}}

      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()"
           title="{{'COMMON_SHOW_SEARCH_HINT' | translate}}"><i #searchIcon class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>

    <div class="card-body" *ngIf="showSearch">

      <div class="row form-group">

        <!--NAME-->
        <div class="col-md-4">
          <label class="search-label">{{'COMMON_NAME' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
                 [(ngModel)]="searchModel.name" name="name" id="name" #name="ngModel">
        </div>

        <!--CODE-->
        <div class="col-md-4">
          <label class="search-label">{{'DOCUMENT_HTML_SEARCH_CODE' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'DOCUMENT_HTML_SEARCH_CODE' | translate}}"
                 [(ngModel)]="searchModel.code" name="code" id="code" #name="ngModel">
        </div>

        <!--VALUE-->
        <div class="col-md-4">
          <label class="search-label">{{'DOCUMENT_HTML_SEARCH_VALUE' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'DOCUMENT_HTML_SEARCH_VALUE' | translate}}"
                 [(ngModel)]="searchModel.value" name="value" id="value" #name="ngModel">
        </div>
      </div>

      <div class="row form-group">

        <!--LANGUAGE-->
        <div class="col-md-4">
          <label class="search-label">{{'DOCUMENT_HTML_SEARCH_LANGUAGE' | translate}}</label>
          <select class="form-control"
                  [(ngModel)]="searchModel.language_code"
                  id="language"
                  name="language"
                  #state="ngModel">
            <option *ngFor="let item of documentLanguageList" [ngValue]="item">{{item.text}}</option>
          </select>
        </div>

        <!--DOCUMENT GROUP-->
        <div *ngIf="ConfigurationService.isEnabledByServer('DOCUMENTS', configuration)" class="col-md-4">
          <label class="search-label">{{'DOCUMENT_HTML_SEARCH_DOCUMENT_GROUP' | translate}}</label>
          <select class="form-control"
                  [(ngModel)]="searchModel.document_group"
                  id="documentGroup"
                  name="documentGroup"
                  #documentGroup="ngModel">
            <option *ngFor="let item of documentGroupList" [ngValue]="item">{{item.text}}</option>
          </select>
        </div>

        <!--DISABLED-->
        <div class="col-md-3">
          <label class="search-label">{{'COMMON_STATE' | translate}}</label>
          <select class="form-control"
                  [(ngModel)]="searchModel.disabled"
                  id="disabled"
                  name="disabled"
                  #disabled="ngModel">
            <option *ngFor="let item of disabledItems" [ngValue]="item">{{item.text}}</option>
          </select>
        </div>

        <!--SEARCH-->
        <div class="col-md-1">
          <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
            {{'COMMON_BUTTON_SEARCH' | translate}}
          </button>
        </div>
      </div>

    </div><!-- /.card-body-->
  </div><!-- /.card --> <!--./SEARCH-->

  <div class="container-vertical-padding"></div>

  <div class="card">

    <div class="card-header">

      {{'COMMON_DOCUMENTS' | translate}}

      <div class="card-actions">
        <a class="btn-setting table-header-action-button" uiSref="Admin.DocumentHTMLCreate"
           title="{{'COMMON_BUTTON_ADD' | translate}}">
          <i class="icomoon icomoon-add"></i>
        </a>
      </div><!-- /.card-actions -->

    </div><!-- /.card-header -->

    <div class="card-body">

      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header w-15 hidden-xs-down">
            <app-table-field-sorter [orderField]="DocumentHTML.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(DocumentHTML.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-15 hidden-xs-down">
            <app-table-field-sorter [orderField]="DocumentHTML.OrderField.NAME"
                                    [orderType]="queryModel.getOrderType(DocumentHTML.OrderField.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-15 hidden-xs-down">
            <app-table-field-sorter [orderField]="DocumentHTML.OrderField.CODE"
                                    [orderType]="queryModel.getOrderType(DocumentHTML.OrderField.CODE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'DOCUMENT_HTML_TABLE_HEADER_DOCUMENT_CODE' | translate"></app-table-field-sorter>
          </th>
          <th *ngIf="ConfigurationService.isEnabledByServer('DOCUMENTS', configuration)" class="table-sorter-header w-15 hidden-xs-down">
            <app-table-field-sorter [orderField]="DocumentHTML.OrderField.DOCUMENT_GROUP_NAME"
                                    [orderType]="queryModel.getOrderType(DocumentHTML.OrderField.DOCUMENT_GROUP_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_DOCUMENT_GROUP' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-15 hidden-xs-down">
            <app-table-field-sorter [orderField]="DocumentHTML.OrderField.UPDATE_TIME"
                                    [orderType]="queryModel.getOrderType(DocumentHTML.OrderField.UPDATE_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_UPDATE_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-15 hidden-sm-down">
            <app-table-field-sorter [orderField]="DocumentHTML.OrderField.CREATION_TIME"
                                    [orderType]="queryModel.getOrderType(DocumentHTML.OrderField.CREATION_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_CREATION_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-10">
            <app-table-sorter-no-op [text]="'COMMON_TABLE_HEADER_ACTIONS' | translate"></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>

        <tbody>
        <tr *ngFor="let documentModel of documentListModel | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }" [disabledTableRow]="documentModel.disabled">

          <td class="responsive-table-column"> {{ documentModel.id }}</td>
          <td class="responsive-table-column hidden-xs-down long"> {{ documentModel.name }}</td>
          <td class="responsive-table-column hidden-xs-down"> {{ documentModel.code }}</td>
          <td *ngIf="ConfigurationService.isEnabledByServer('DOCUMENTS', configuration)" class="responsive-table-column hidden-xs-down"> {{
            documentModel.document_group.text }}
          </td>
          <td class="responsive-table-column hidden-xs-down"> {{ documentModel.update_time | date: 'short'}}</td>
          <td class="responsive-table-column hidden-sm-down"> {{ documentModel.creation_time | date: 'short' }}</td>
          <td class="responsive-table-column ">
            <a class="icons-table-group" href="#" onclick="return false"
               (click)="setDisabled($event, documentModel, true)"
               *ngIf="!documentModel.disabled && rightModel.documentHtmlDisable.hasRight()">
              <i class="icomoon icons-table-item icomoon-disable icomoon-disable-table"
                 title="{{'COMMON_BUTTON_DISABLE' | translate}}"></i>
            </a>
            <a class="icons-table-group" href="#" onclick="return false"
               (click)="setDisabled($event, documentModel, false)"
               *ngIf="documentModel.disabled && rightModel.documentHtmlDisable.hasRight()">
              <i class="icomoon icons-table-item icomoon-enable icomoon-enable-table"
                 title="{{'COMMON_BUTTON_ENABLE' | translate}}"></i>
            </a>
            <a class="icons-table-group" uiSref="Admin.DocumentHTMLRead" [uiParams]="{ id: documentModel.id }">
              <i class="icomoon icons-table-item icomoon-detail icomoon-detail-table"
                 title="{{'COMMON_BUTTON_DETAILS' | translate}}"></i>
            </a>
            <a *ngIf="rightModel.documentHtmlUpdate.hasRight()" class="icons-table-group"
               uiSref="Admin.DocumentHTMLEdit" [uiParams]="{ id: documentModel.id }">
              <i class="icomoon icons-table-item icomoon-modify icomoon-modify-table"
                 title="{{'COMMON_BUTTON_EDIT' | translate}}"></i>
            </a>
          </td>

        </tr>
        </tbody>

        <tfoot>
        <tr>
          <th class="table-sorter-header w-15 hidden-xs-down">
            <app-table-field-sorter [orderField]="DocumentHTML.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(DocumentHTML.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-15 hidden-xs-down">
            <app-table-field-sorter [orderField]="DocumentHTML.OrderField.NAME"
                                    [orderType]="queryModel.getOrderType(DocumentHTML.OrderField.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-15 hidden-xs-down">
            <app-table-field-sorter [orderField]="DocumentHTML.OrderField.CODE"
                                    [orderType]="queryModel.getOrderType(DocumentHTML.OrderField.CODE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'DOCUMENT_HTML_TABLE_HEADER_DOCUMENT_CODE' | translate"></app-table-field-sorter>
          </th>
          <th *ngIf="ConfigurationService.isEnabledByServer('DOCUMENTS', configuration)" class="table-sorter-header w-15 hidden-xs-down">
            <app-table-field-sorter [orderField]="DocumentHTML.OrderField.DOCUMENT_GROUP_NAME"
                                    [orderType]="queryModel.getOrderType(DocumentHTML.OrderField.DOCUMENT_GROUP_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_DOCUMENT_GROUP' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-15 hidden-xs-down">
            <app-table-field-sorter [orderField]="DocumentHTML.OrderField.UPDATE_TIME"
                                    [orderType]="queryModel.getOrderType(DocumentHTML.OrderField.UPDATE_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_UPDATE_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-15 hidden-sm-down">
            <app-table-field-sorter [orderField]="DocumentHTML.OrderField.CREATION_TIME"
                                    [orderType]="queryModel.getOrderType(DocumentHTML.OrderField.CREATION_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_CREATION_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-10">
            <app-table-sorter-no-op [text]="'COMMON_TABLE_HEADER_ACTIONS' | translate"></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>

      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>
  </div>
</div>

