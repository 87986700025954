/* eslint-disable */
import { TranslateLoader } from '@ngx-translate/core';
import { LOCALE_ID } from '@angular/core';
import { SettingsService } from './lib/settings.service';
import { AppTranslateLoader } from './util/app-translate-loader';
import { HttpBackend, HttpClient } from '@angular/common/http';
/* eslint-enable */

export const APP_TRANSLATE_LOADER = {
  provide: TranslateLoader,
  useFactory: (createTranslateLoader),
  deps: [HttpBackend]
};

export function createTranslateLoader(http: HttpBackend) {
  return new AppTranslateLoader(new HttpClient(http));
}

export const LOCALE_CODE_PROVIDER = {
  provide: LOCALE_ID,
  useFactory: (createLocaleCode),
  deps: [SettingsService]
};

export function createLocaleCode(settingsService: SettingsService) {
  return settingsService.getLocaleCode();
}
