import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { UiConstants } from '../../../util/core-utils';
import { ServiceInfo, ServiceInfoService } from '../../../lib/service-info/service-info.service';
import { AppConfigurationService } from '../../../lib/app-configuration.service';

@Component({
  selector: 'app-service-info-dialog',
  templateUrl: './service-info-dialog.component.html',
  styleUrls: ['./service-info-dialog.component.scss']
})
export class ServiceInfoDialogComponent implements OnInit {
  UiConstants = UiConstants;

  @ViewChild('dialog', { static: true })
  dialog: ModalDirective;
  dialogVisible: boolean = false;

  model?: ServiceInfo.ServiceInfo;

  constructor(private serviceInfoService: ServiceInfoService,
              private appConfigurationService: AppConfigurationService) {
  }

  ngOnInit() {
  }

  private loadModel(completion: () => void) {
    if (this.model) {
      completion();
      return;
    }
    this.serviceInfoService.get().subscribe((model) => {
      this.model = model;
      completion();
    })
  }

  showDialog() {
    this.loadModel(() => {
      this.dialogVisible = true;
      this.dialog.show();
    });
  }

  closeDialog() {
    this.dialogVisible = false;
    this.dialog.hide();
  }

  get adminVersion(): string {
    return this.appConfigurationService.getFormattedAppVersion();
  }

  goToBitrise(): void {
    if (this.model?.androidAppInfo) {
      window.open(this.model?.androidAppInfo.publicInstallPageUrl, "_blank");
    }
  }
}
