<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div class="breadcrumb-menu negative-margin-right">
    <!--CREATE-->
    <div class="breadcrumb-button-icon-container cursor-pointer"
         *ngIf="model.invoiceIntegrationType === InvoiceSettings.InvoiceIntegrationType.APPWORKS && componentState.isEditView()"
         (click)="openUploadDialog()">
      <a class="btn-setting d-flex align-items-center"
         title="{{'FILE_UPLOADER_UPLOAD_QUEUE_BUTTON_TEXT_UPLOAD' | translate}}">
        <i class="icomoon icomoon-pdf-upload"></i>
        <span
          class="breadcrumb-button-text-visible">{{'FILE_UPLOADER_UPLOAD_QUEUE_BUTTON_TEXT_UPLOAD' | translate}}</span>
      </a>
    </div>
    <app-breadcrumb-button
      *ngIf="rightModel.invoiceSettingUpdate.hasRight() && componentState.isDetailView()"
      [iconClass]="'icomoon-modify'"
      [titleStringKey]="'COMMON_BUTTON_EDIT'"
      uiSref="Admin.InvoiceSettingsEdit"
      [uiParams]="{ id: componentState.id}">
    </app-breadcrumb-button>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-custom-padding animated fadeIn">
  <form class="form-horizontal bordered-row" (ngSubmit)="f.form.valid" #f="ngForm" [formGroup]="formGroup" novalidate>
    <div class="form-group row mx-0">
      <div class="col-xs-12 col-sm-12 col-md-6 card-custom-padding">
        <div class="card">
          <div class="card-header">
            {{'INVOICE_SETTINGS_PROFILE_DATA' | translate}}
          </div>
          <div class="card-body">

            <!--region IntegrationType-->
            <div class="form-group row">
              <label
                class="col-form-label form-control-label col-md-4 detail-title">{{'INVOICE_SETTINGS_INTEGRATION_TYPE' |
                translate}}</label>
              <div class="col-md-8">
                <img *ngIf="hasIntegrationTypeIcon(model.invoiceIntegrationType)"
                     src="{{getIntegrationTypeIcon(model.invoiceIntegrationType)}}"
                     class="w-100 invoice-integration-icon"
                     alt=" {{getIntegrationTypeString(model.invoiceIntegrationType) | translate}} ">
                <span
                  *ngIf="!hasIntegrationTypeIcon(model.invoiceIntegrationType)"> {{getIntegrationTypeString(model.invoiceIntegrationType) | translate}} </span>
              </div>
            </div>
            <!--endregion IntegrationType-->

            <!--region ProfileName-->
            <div class="form-group row"
                 [ngClass]="{ 'has-danger': hasLocalFieldError('profileName') || hasFieldError(InvoiceSettings.ValidatedField.PROFILE_NAME) }">
              <label class="col-form-label form-control-label col-md-4 detail-title required-field-label">
                {{'INVOICE_SETTINGS_PROFILE_NAME' | translate}}
              </label>
              <div class="col-md-8">
                <input *ngIf="componentState.isEditable()"
                       type="text" class="form-control"
                       placeholder="{{'INVOICE_SETTINGS_PROFILE_NAME' | translate}}"
                       [(ngModel)]="model.profileName"
                       (ngModelChange)="removeFieldError(InvoiceSettings.ValidatedField.PROFILE_NAME)"
                       [ngClass]="{ 'form-control-danger': hasLocalFieldError('profileName') || hasFieldError(InvoiceSettings.ValidatedField.PROFILE_NAME) }"
                       formControlName="profileName"
                       id="profileName">
                <div class="form-control-feedback" *ngIf="hasLocalFieldError('profileName')">
                    <span
                      *ngIf="hasLocalFieldError('profileName', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
                </div>
                <div class="form-control-feedback" *ngIf="hasFieldError(InvoiceSettings.ValidatedField.PROFILE_NAME)">
                  <span>{{fieldErrors.getErrorText(InvoiceSettings.ValidatedField.PROFILE_NAME)}}</span>
                </div>
                <label
                  *ngIf="componentState.isReadonly()" class="form-control detail-description"
                  readonly>{{model.profileName}}
                </label>
              </div>
            </div>
            <!--endregion ProfileName-->

            <!--region DefaultInvoiceAppearanceType-->
            <div class="form-group row">
              <label
                class="col-form-label form-control-label col-md-4 detail-title">{{'INVOICE_SETTINGS_DEFAULT_APPEARANCE_TYPE' |
                translate}}</label>
              <div class="col-md-8">
                <select class="form-control" [(ngModel)]="model.defaultInvoiceAppearanceType"
                        [ngModelOptions]="{standalone: true}"
                        [disabled]="model.invoiceIntegrationType === InvoiceSettings.InvoiceIntegrationType.APPWORKS"
                        id="defaultAppearanceType" name="defaultAppearanceType" #defaultAppearanceType="ngModel">
                  <option *ngFor="let appearanceType of InvoiceSettings.invoiceAppearanceTypes"
                          [ngValue]="appearanceType.type">{{appearanceType.stringKey |
                    translate}}
                  </option>
                </select>
              </div>
            </div>
            <!--endregion DefaultInvoiceAppearanceType-->

            <!--region DefaultPaymentType-->
            <div class="form-group row">
              <label
                class="col-form-label form-control-label col-md-4 detail-title">{{'INVOICE_SETTINGS_DEFAULT_PAYMENT_TYPE' |
                translate}}</label>
              <div class="col-md-8">
                <angular2-multiselect
                  [(ngModel)]="model.defaultPaymentType"
                  [ngModelOptions]="{standalone: true}"
                  [data]="model.paymentTypes"
                  [readonly]="this.componentState.isReadonly()"
                  [settings]="dropdownSettings">
                </angular2-multiselect>
              </div>
            </div>
            <!--endregion DefaultPaymentType-->

            <!--region Language-->
            <div class="form-group row"
                 *ngIf="model.invoiceIntegrationType === InvoiceSettings.InvoiceIntegrationType.SZAMLAZZ_HU">
              <label class="col-form-label form-control-label col-md-4 detail-title">{{'INVOICE_SETTINGS_LANGUAGE' |
                translate}}</label>
              <div class="col-md-8">
                <select class="form-control" [(ngModel)]="model.languageCode"
                        [ngModelOptions]="{standalone: true}"
                        id="language" name="language" #language="ngModel">
                  <option *ngFor="let languageObject of InvoiceSettings.invoiceLanguages"
                          [ngValue]="languageObject.code">{{languageObject.stringKey |
                    translate}}
                  </option>
                </select>
              </div>
            </div>
            <!--endregion Language-->

            <div class="form-group row">
              <div class="col-md-4">
                <label
                  class="col-form-label mw-100 form-control-label">{{'INVOICE_SETTINGS_SUMMARY_ROUNDING'|translate}}
                  <i class="icomoon icomoon-info info-icon"
                     popover="{{'INVOICE_SETTINGS_SUMMARY_ROUNDING_INFO' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
              </div>
              <div class="col-md-8">
                <label class="switch switch-text switch-info mb-0"
                       [ngClass]="{'cursor-not-allowed': !componentState.isEditable()}">
                  <input type="checkbox" class="switch-input"
                         [(ngModel)]="model.summaryRoundingEnabled"
                         [disabled]="!componentState.isEditable()"
                         id="summary_rounding" [ngModelOptions]="{standalone: true}">
                  <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                  <span class="switch-handle"></span>
                </label>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-4">
                <label
                  class="col-form-label mw-100 form-control-label">{{'INVOICE_SETTINGS_RECORD_ROUNDING'|translate}}
                  <i class="icomoon icomoon-info info-icon"
                     popover="{{'INVOICE_SETTINGS_RECORD_ROUNDING_INFO' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
              </div>
              <div class="col-md-8">
                <label class="switch switch-text switch-info mb-0"
                       [ngClass]="{'cursor-not-allowed': !componentState.isEditable()}">
                  <input type="checkbox" class="switch-input"
                         [(ngModel)]="model.recordRoundingEnabled"
                         [disabled]="!componentState.isEditable()"
                         id="record_rounding" [ngModelOptions]="{standalone: true}">
                  <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                  <span class="switch-handle"></span>
                </label>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-4">
                <label
                  class="col-form-label mw-100 form-control-label">{{'INVOICE_SETTINGS_ZERO_PRICE_ALLOWED'|translate}}
                  <i class="icomoon icomoon-info info-icon"
                     popover="{{'INVOICE_SETTINGS_ZERO_PRICE_ALLOWED_INFO' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
              </div>
              <div class="col-md-8">
                <label class="switch switch-text switch-info mb-0"
                       [ngClass]="{'cursor-not-allowed': !componentState.isEditable()}">
                  <input type="checkbox" class="switch-input"
                         [(ngModel)]="model.zeroPricedRecordsAllowed"
                         [disabled]="!componentState.isEditable()"
                         id="zero_price" [ngModelOptions]="{standalone: true}">
                  <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                  <span class="switch-handle"></span>
                </label>
              </div>
            </div>

            <!--region InboundStock-->
            <div class="form-group row" *ngIf="model.invoiceDirectionType === 'INBOUND'">
              <label
                class="col-form-label form-control-label col-md-4 detail-title">{{'INVOICE_SETTINGS_INBOUND_STOCK' |
                translate}}</label>
              <div class="col-md-8">
                <angular2-multiselect
                  [(ngModel)]="model._inboundStock"
                  [ngModelOptions]="{standalone: true}"
                  [data]="stocks"
                  (onRemoteSearch)="loadStocks($event.target.value)"
                  [readonly]="this.componentState.isReadonly()"
                  [settings]="stockDropdownSettings">
                </angular2-multiselect>
              </div>
            </div>
            <!--endregion InboundStock-->

            <!--region ExternalId-->
            <div class="form-group row"
                 [ngClass]="{ 'has-danger': hasLocalFieldError('externalId') || hasFieldError(InvoiceSettings.ValidatedField.EXTERNAL_ID) }">
              <label class="col-form-label form-control-label col-md-4 detail-title"
                     [class.required-field-label]="componentState.isEditView()">
                {{'COMMON_EXTERNAL_ID' | translate}}
              </label>
              <div class="col-md-8">
                <input *ngIf="componentState.isEditable()"
                       type="text" class="form-control"
                       placeholder="{{'COMMON_EXTERNAL_ID' | translate}}"
                       [(ngModel)]="model.externalId"
                       (ngModelChange)="removeFieldError(InvoiceSettings.ValidatedField.EXTERNAL_ID)"
                       [ngClass]="{ 'form-control-danger': hasLocalFieldError('externalId') || hasFieldError(InvoiceSettings.ValidatedField.EXTERNAL_ID) }"
                       formControlName="externalId"
                       id="externalId">
                <div class="form-control-feedback" *ngIf="hasLocalFieldError('externalId')">
                    <span
                      *ngIf="hasLocalFieldError('externalId', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
                </div>
                <div class="form-control-feedback" *ngIf="hasFieldError(InvoiceSettings.ValidatedField.EXTERNAL_ID)">
                  <span>{{fieldErrors.getErrorText(InvoiceSettings.ValidatedField.EXTERNAL_ID)}}</span>
                </div>
                <label
                  *ngIf="componentState.isReadonly()" class="form-control detail-description"
                  readonly>{{model.externalId}}
                </label>
              </div>
            </div>
            <!--endregion ExternalId-->

            <!--region InvoiceNotification-->
            <div class="form-group row">
              <label
                class="col-form-label form-control-label col-md-4 detail-title">{{'INVOICE_SETTINGS_INVOICE_NOTIFICATION'
                | translate}}
                <i class="icomoon icomoon-info info-icon" popover="{{'INVOICE_SETTINGS_INVOICE_NOTIFICATION_INFO' | translate}}"
                   triggers="mouseenter:mouseleave" container="body" placement="right"></i>
              </label>
              <div class="col-md-8">
                <textarea *ngIf="componentState.isEditable()"
                          type="text" class="form-control"
                          placeholder="{{'INVOICE_SETTINGS_INVOICE_NOTIFICATION' | translate}}"
                          [(ngModel)]="model.commentTemplate"
                          [ngModelOptions]="{standalone: true}">
                </textarea>
                <label
                  *ngIf="componentState.isReadonly()" class="form-control detail-description"
                  readonly>{{model.commentTemplate}}
                </label>
              </div>
            </div>
            <!--endregion InvoiceNotification-->

            <!--region FooterText-->
            <div class="form-group row">
              <label
                class="col-form-label form-control-label col-md-4 detail-title">{{'INVOICE_SETTINGS_INVOICE_FOOTER_TEXT'
                | translate}}
                <i class="icomoon icomoon-info info-icon" popover="{{'INVOICE_SETTINGS_INVOICE_FOOTER_TEXT_INFO' | translate}}"
                   triggers="mouseenter:mouseleave" container="body" placement="right"></i>
              </label>
              <div class="col-md-8">
                <textarea *ngIf="componentState.isEditable()"
                          type="text" class="form-control"
                          placeholder="{{'INVOICE_SETTINGS_INVOICE_FOOTER_TEXT' | translate}}"
                          [(ngModel)]="model.footerText"
                          [ngModelOptions]="{standalone: true}">
                </textarea>
                <label
                  *ngIf="componentState.isReadonly()" class="form-control detail-description"
                  readonly>{{model.footerText}}
                </label>
              </div>
            </div>
            <!--endregion FooterText-->
          </div>
        </div>
        <div class="container-vertical-padding-half"></div>
        <div class="card" *ngIf="model.invoiceIntegrationType === InvoiceSettings.InvoiceIntegrationType.APPWORKS">
          <div class="card-header">
            {{'INVOICE_SETTINGS_HEADING' | translate}}
          </div>
          <div class="card-body">


            <!--region transferDeadlineDeliveryDays-->
            <div>
              <div *ngFor="let item of model.paymentTypeExtraItems">
                <div class="form-group row"
                     [ngClass]="{ 'has-danger': hasLocalFieldError('invoicePaymentType' + item.paymentType.id) }">
                  <label
                    class="col-form-label form-control-label col-md-4 detail-title"
                    [class.required-field-label]="(item.paymentType.id === 'TRANSFER' || item.paymentType.id === 'COD') && model.invoiceIntegrationType === InvoiceIntegrationType.APPWORKS">
                    {{item.paymentType.itemName | translate}} {{'INVOICE_SETTINGS_DEADLINE_ISSUE_DATE' | translate }}
                  </label>
                  <div class="col-md-8">
                    <input *ngIf="componentState.isEditable()"
                           type="number" class="form-control"
                           placeholder="{{item.paymentType.itemName | translate}}"
                           [(ngModel)]="item.issueDeadlinePlusDays"
                           [textMask]="{mask: InputMask.NATURAL_INTEGER, guide: false}"
                           [ngClass]="{ 'form-control-danger': hasLocalFieldError('invoicePaymentType' + item.paymentType.id) }"
                           [name]="item.paymentType.id"
                           [formControlName]="'invoicePaymentType' + item.paymentType.id">
                    <div class="form-control-feedback"
                         *ngIf="hasLocalFieldError('invoicePaymentType' + item.paymentType.id)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                    <label
                      *ngIf="componentState.isReadonly()" class="form-control detail-description"
                      readonly>{{item.issueDeadlinePlusDays}}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <!--endregion transferDeadlineDeliveryDays-->

            <div class="form-group row"
                 *ngIf="model.invoiceIntegrationType === InvoiceSettings.InvoiceIntegrationType.APPWORKS">
              <div class="col-md-4">
                <label class="col-form-label mw-100 form-control-label">{{'NAV_ONLINE'|translate}}</label>
              </div>
              <div class="col-md-8">
                <label class="switch switch-text switch-info mb-0"
                       [ngClass]="{'cursor-not-allowed': !componentState.isEditable()}">
                  <input type="checkbox" class="switch-input"
                         [(ngModel)]="model.technicalUser.enabled"
                         [disabled]="!componentState.isEditable()"
                         id="is_driver" [ngModelOptions]="{standalone: true}">
                  <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                  <span class="switch-handle"></span>
                </label>
              </div>
            </div>

            <div *ngIf="model.technicalUser.enabled">
              <!--region UserName-->
              <div class="form-group row" [ngClass]="{ 'has-danger': hasLocalFieldError('userName') }">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title required-field-label">
                  {{'INVOICE_SETTINGS_TECHNICAL_USER_NAME' | translate}}
                </label>
                <div class="col-md-8">
                  <input *ngIf="componentState.isEditable()"
                         type="text" class="form-control"
                         placeholder="{{'INVOICE_SETTINGS_TECHNICAL_USER_NAME' | translate}}"
                         [(ngModel)]="model.technicalUser.userName"
                         [ngClass]="{ 'form-control-danger': hasLocalFieldError('userName') }"
                         formControlName="userName"
                         id="userName">
                  <div class="form-control-feedback"
                       *ngIf="hasLocalFieldError('userName')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                  <label
                    *ngIf="componentState.isReadonly()" class="form-control detail-description"
                    readonly>{{model.technicalUser.userName}}
                  </label>
                </div>
              </div>
              <!--endregion UserName-->

              <!--region Password-->
              <div class="form-group row" *ngIf="componentState.isEditable()"
                   [ngClass]="{ 'has-danger': hasLocalFieldError('password') || hasFieldError(InvoiceSettings.ValidatedField.PASSWORD) }">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title"
                  [class.required-field-label]="hadTechnicalUser">
                  {{'INVOICE_SETTINGS_TECHNICAL_USER_PASSWORD' | translate}}
                </label>
                <div class="col-md-8">
                  <div class="input-group">
                    <input
                      type="{{passwordVisible ? 'text' : 'password'}}"
                      class="form-control"
                      placeholder="{{'INVOICE_SETTINGS_TECHNICAL_USER_PASSWORD' | translate}}"
                      [(ngModel)]="model.technicalUser.password"
                      (ngModelChange)="removeFieldError(InvoiceSettings.ValidatedField.PASSWORD)"
                      [ngClass]="{ 'form-control-danger':  hasLocalFieldError('password') || hasFieldError(InvoiceSettings.ValidatedField.PASSWORD) }"
                      formControlName="password"
                      id="password">
                    <span (click)="togglePasswordVisibility()"
                          class="input-group-addon-gray icomoon icomoon-enable cursor-pointer font-2xl"></span>
                  </div>
                  <div class="form-control-feedback"
                       *ngIf="hasLocalFieldError('password')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                  <div class="form-control-feedback" *ngIf="hasFieldError(InvoiceSettings.ValidatedField.PASSWORD)">
                    <span>{{fieldErrors.getErrorText(InvoiceSettings.ValidatedField.PASSWORD)}}</span>
                  </div>
                </div>
              </div>
              <!--endregion Password-->

              <!--region SignatureKey-->
              <div class="form-group row" [ngClass]="{ 'has-danger': hasLocalFieldError('signatureKey') }">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title required-field-label">
                  {{'INVOICE_SETTINGS_TECHNICAL_USER_SIGNATURE_KEY' | translate}}</label>
                <div class="col-md-8">
                  <input *ngIf="componentState.isEditable()"
                         type="text" class="form-control"
                         placeholder="{{'INVOICE_SETTINGS_TECHNICAL_USER_SIGNATURE_KEY' | translate}}"
                         [(ngModel)]="model.technicalUser.signatureKey"
                         [ngClass]="{ 'form-control-danger': hasLocalFieldError('signatureKey') }"
                         formControlName="signatureKey"
                         id="signatureKey">
                  <div class="form-control-feedback"
                       *ngIf="hasLocalFieldError('signatureKey')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                  <label
                    *ngIf="componentState.isReadonly()" class="form-control detail-description"
                    readonly>{{model.technicalUser.signatureKey}}
                  </label>
                </div>
              </div>
              <!--endregion SignatureKey-->

              <!--region ExchangeKey-->
              <div class="form-group row" [ngClass]="{ 'has-danger': hasLocalFieldError('exchangeKey') }">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title required-field-label">
                  {{'INVOICE_SETTINGS_TECHNICAL_USER_EXCHANGE_KEY' | translate}}
                </label>
                <div class="col-md-8">
                  <input *ngIf="componentState.isEditable()"
                         type="text" class="form-control"
                         placeholder="{{'INVOICE_SETTINGS_TECHNICAL_USER_EXCHANGE_KEY' | translate}}"
                         [(ngModel)]="model.technicalUser.exchangeKey"
                         [ngClass]="{ 'form-control-danger': hasLocalFieldError('exchangeKey') }"
                         formControlName="exchangeKey"
                         id="exchangeKey">
                  <div class="form-control-feedback"
                       *ngIf="hasLocalFieldError('exchangeKey')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                  <label
                    *ngIf="componentState.isReadonly()" class="form-control detail-description"
                    readonly>{{model.technicalUser.exchangeKey}}
                  </label>
                </div>
              </div>
              <!--endregion ExchangeKey-->

            </div>
          </div>
        </div>
        <div class="card" *ngIf="model.invoiceIntegrationType === InvoiceSettings.InvoiceIntegrationType.SZAMLAZZ_HU">
          <div class="card-header">
            {{'INVOICE_SETTINGS_SZAMLAZZ_HU_SETTINGS' | translate}}
          </div>
          <div class="card-body">
            <!--region SzamlazzHuAgentKey-->
            <div class="form-group row"
                 [ngClass]="{ 'has-danger': hasLocalFieldError('szamlazzHuAgentKey') }">
              <label class="col-form-label form-control-label col-md-4 detail-title required-field-label">{{'INVOICE_SETTINGS_AGENT_KEY' |
                translate}}</label>
              <div class="col-md-8">
                <input *ngIf="componentState.isEditable()"
                       type="text" class="form-control"
                       placeholder="{{'INVOICE_SETTINGS_AGENT_KEY' | translate}}"
                       [(ngModel)]="model.szamlazzHu.agentKey"
                       [ngClass]="{ 'form-control-danger': hasLocalFieldError('szamlazzHuAgentKey') }"
                       formControlName="szamlazzHuAgentKey"
                       id="szamlazzHuAgentKey">
                <div class="form-control-feedback" *ngIf="hasLocalFieldError('szamlazzHuAgentKey')">
                    <span
                      *ngIf="hasLocalFieldError('szamlazzHuAgentKey', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
                </div>
                <label
                  *ngIf="componentState.isReadonly()" class="form-control detail-description"
                  readonly>{{model.szamlazzHu.agentKey}}
                </label>
              </div>
            </div>
            <!--endregion SzamlazzHuAgentKey-->

            <div class="form-group row">
              <div class="col-md-4">
                <label
                  class="col-form-label mw-100 form-control-label">{{'INVOICE_SETTINGS_SZAMLAZZ_HU_SEND_EMAIL'|translate}}</label>
                <i class="icomoon icomoon-info info-icon"
                   popover="{{'INVOICE_SETTINGS_SZAMLAZZ_HU_SEND_EMAIL_INFO' | translate}}"
                   triggers="mouseenter:mouseleave" container="body" placement="right"></i>
              </div>
              <div class="col-md-8">
                <label class="switch switch-text switch-info mb-0"
                       [ngClass]="{'cursor-not-allowed': !componentState.isEditable()}">
                  <input type="checkbox" class="switch-input"
                         [(ngModel)]="model.szamlazzHu.sendEmail"
                         [disabled]="!componentState.isEditable()"
                         id="sendEmail" [ngModelOptions]="{standalone: true}">
                  <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                  <span class="switch-handle"></span>
                </label>
              </div>
            </div>

            <!--region EmailSubject-->
            <div class="form-group row" *ngIf="model.szamlazzHu.sendEmail">
              <label
                class="col-form-label form-control-label col-md-4 detail-title">{{'INVOICE_SETTINGS_SZAMLAZZ_HU_EMAIL_SUBJECT'
                | translate}}
                <i class="icomoon icomoon-info info-icon"
                   popover="{{'INVOICE_SETTINGS_SZAMLAZZ_HU_EMAIL_SUBJECT_INFO' | translate}}"
                   triggers="mouseenter:mouseleave" container="body" placement="right"></i>
              </label>
              <div class="col-md-8">
                <textarea *ngIf="componentState.isEditable()"
                          type="text" class="form-control"
                          placeholder="{{'INVOICE_SETTINGS_SZAMLAZZ_HU_EMAIL_SUBJECT' | translate}}"
                          [(ngModel)]="model.szamlazzHu.emailSubject"
                          [ngModelOptions]="{standalone: true}">
                </textarea>
                <label
                  *ngIf="componentState.isReadonly()" class="form-control detail-description"
                  readonly>{{model.szamlazzHu.emailSubject}}
                </label>
              </div>
            </div>
            <!--endregion EmailSubject-->

            <!--region EmailTemplate-->
            <div class="form-group row" *ngIf="model.szamlazzHu.sendEmail">
              <label
                class="col-form-label form-control-label col-md-4 detail-title">{{'INVOICE_SETTINGS_SZAMLAZZ_HU_EMAIL_TEMPLATE'
                | translate}}
              </label>
              <div class="col-md-8">
                <textarea *ngIf="componentState.isEditable()"
                          type="text" class="form-control"
                          placeholder="{{'INVOICE_SETTINGS_SZAMLAZZ_HU_EMAIL_TEMPLATE' | translate}}"
                          [(ngModel)]="model.szamlazzHu.emailTemplate"
                          [ngModelOptions]="{standalone: true}">
                </textarea>
                <label
                  *ngIf="componentState.isReadonly()" class="form-control detail-description"
                  readonly>{{model.szamlazzHu.emailTemplate}}
                </label>
              </div>
            </div>
            <!--endregion EmailTemplate-->

            <!--region CustomerEmailAddressType-->
            <div class="form-group row" *ngIf="model.szamlazzHu.sendEmail">
              <label
                class="col-form-label form-control-label col-md-4 detail-title">{{'INVOICE_SETTINGS_SZAMLAZZ_HU_CUSTOMER_EMAIL_ADDRESS_TYPE' |
                translate}}</label>
              <div class="col-md-8">
                <angular2-multiselect
                  [(ngModel)]="model.szamlazzHu._customerEmailAddressType"
                  [ngModelOptions]="{standalone: true}"
                  [data]="customerEmailAddressTypes"
                  [readonly]="this.componentState.isReadonly()"
                  [settings]="dropdownSettings">
                </angular2-multiselect>
              </div>
            </div>
            <!--endregion CustomerEmailAddressType-->

            <!--region Email-->
            <div class="form-group row" [ngClass]="{ 'has-danger': hasLocalFieldError('notificationEmailAddress') }"
                 *ngIf="model.szamlazzHu.sendEmail">
              <label
                class="col-form-label form-control-label col-md-4 detail-title">{{'INVOICE_SETTINGS_SZAMLAZZ_HU_NOTIFICATION_EMAIL_ADDRESS'
                | translate}}</label>
              <div class="col-md-8">
                <div class="input-group">
                  <input *ngIf="componentState.isEditable()"
                         type="text" class="form-control"
                         placeholder="{{'INVOICE_SETTINGS_SZAMLAZZ_HU_NOTIFICATION_EMAIL_ADDRESS' | translate}}"
                         [(ngModel)]="model.szamlazzHu.notificationEmailAddress"
                         [ngClass]="{ 'form-control-danger': hasLocalFieldError('notificationEmailAddress') }"
                         formControlName="notificationEmailAddress"
                         id="notificationEmailAddress">
                  <span *ngIf="componentState.isEditable()"
                        class="input-group-addon-gray icomoon icomoon-message-unread" id="basic-email"></span>
                </div>
                <div class="form-control-feedback"
                     *ngIf="hasLocalFieldError('notificationEmailAddress', 'email')">{{'COMMON_VALIDATION_MESSAGE_EMAIL' | translate}}</div>
                <label
                  *ngIf="componentState.isReadonly()" class="form-control detail-description"
                  readonly>{{model.szamlazzHu.notificationEmailAddress}}
                </label>
              </div>
            </div>
            <!--endregion Email-->

          </div>

        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 card-custom-padding">
        <div class="card">
          <div class="card-header">
            {{'INVOICE_SETTINGS_COMPANY_DATA' | translate}}
          </div>
          <div class="card-body">

            <!--region CompanyName-->
            <div class="form-group row"
                 [ngClass]="{ 'has-danger': hasLocalFieldError('companyName') || hasFieldError(InvoiceSettings.ValidatedField.COMPANY_NAME) }">
              <label class="col-form-label form-control-label col-md-4 detail-title required-field-label">
                {{'INVOICE_SETTINGS_COMPANY_NAME' | translate}}
              </label>
              <div class="col-md-8">
                <input *ngIf="componentState.isEditable()"
                       type="text" class="form-control"
                       placeholder="{{'INVOICE_SETTINGS_COMPANY_NAME' | translate}}"
                       [(ngModel)]="model.companyName"
                       (ngModelChange)="removeFieldError(InvoiceSettings.ValidatedField.COMPANY_NAME)"
                       [ngClass]="{ 'form-control-danger': hasLocalFieldError('companyName') || hasFieldError(InvoiceSettings.ValidatedField.COMPANY_NAME) }"
                       formControlName="companyName"
                       id="companyName">
                <div class="form-control-feedback" *ngIf="hasLocalFieldError('companyName')">
                    <span
                      *ngIf="hasLocalFieldError('companyName', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
                </div>
                <div class="form-control-feedback" *ngIf="hasFieldError(InvoiceSettings.ValidatedField.COMPANY_NAME)">
                  <span>{{fieldErrors.getErrorText(InvoiceSettings.ValidatedField.COMPANY_NAME)}}</span>
                </div>
                <label
                  *ngIf="componentState.isReadonly()" class="form-control detail-description"
                  readonly>{{model.companyName}}
                </label>
              </div>
            </div>
            <!--endregion CompanyName-->

            <!--region CompanyAddress-->
            <div class="form-group row">
              <label
                class="col-form-label form-control-label col-md-4 detail-title">{{'INVOICE_SETTINGS_COMPANY_ADDRESS'
                | translate}}</label>
              <div class="col-md-8">
                <app-postal-address-complex
                  *ngIf="!componentState.isReadonly()"
                  [form]="f"
                  [model]="model.address"
                  [readonly]="componentState.isReadonly()">
                </app-postal-address-complex>
                <!--Appears in detailView-->
                <label *ngIf="componentState.isReadonly()"
                       class="form-control detail-description" readonly>
                  {{model.addressString}}
                </label>
              </div>
            </div>
            <!--endregion CompanyAddress-->

            <!--region TaxNumber-->
            <div class="form-group row"
                 [ngClass]="{ 'has-danger': hasFieldError(InvoiceSettings.ValidatedField.TAX_NUMBER) || hasLocalFieldError('taxNumber') }">
              <label class="col-form-label form-control-label col-md-4 detail-title required-field-label">
                {{'INVOICE_SETTINGS_TAX_NUMBER' | translate}}
              </label>
              <div class="col-md-8">
                <input *ngIf="componentState.isEditable()"
                       type="text" class="form-control"
                       placeholder="{{'INVOICE_SETTINGS_TAX_NUMBER' | translate}}"
                       [(ngModel)]="model.taxNumber.raw"
                       (ngModelChange)="removeFieldError(InvoiceSettings.ValidatedField.TAX_NUMBER)"
                       [ngClass]="{ 'form-control-danger': hasFieldError(InvoiceSettings.ValidatedField.TAX_NUMBER) || hasLocalFieldError('taxNumber') }"
                       formControlName="taxNumber"
                       id="taxNumber"
                       [textMask]="{mask: InputMask.HU_TAX_NUMBER, guide: true}">
                <div class="form-control-feedback"
                     *ngIf="hasFieldError(InvoiceSettings.ValidatedField.TAX_NUMBER)">{{getFieldErrorText(InvoiceSettings.ValidatedField.TAX_NUMBER)}}</div>
                <div class="form-control-feedback"
                     *ngIf="hasLocalFieldError('taxNumber', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                <label
                  *ngIf="componentState.isReadonly()" class="form-control detail-description"
                  readonly>{{model.taxNumber.truncated}}
                </label>
              </div>
            </div>
            <!--endregion TaxNumber-->

            <!--region EuTaxNumber-->
            <div class="form-group row"
                 [ngClass]="{ 'has-danger': hasFieldError(InvoiceSettings.ValidatedField.EU_VAT_NUMBER) }">
              <label
                class="col-form-label form-control-label col-md-4 detail-title">{{'INVOICE_SETTINGS_EU_TAX_NUMBER' |
                translate}}</label>
              <div class="col-md-8">
                <input *ngIf="componentState.isEditable()"
                       type="text" class="form-control"
                       placeholder="{{'INVOICE_SETTINGS_EU_TAX_NUMBER' | translate}}"
                       [(ngModel)]="model.euVatNumber.raw"
                       (ngModelChange)="removeFieldError(InvoiceSettings.ValidatedField.EU_VAT_NUMBER)"
                       [ngModelOptions]="{standalone: true}"
                       [ngClass]="{ 'form-control-danger': hasFieldError(InvoiceSettings.ValidatedField.EU_VAT_NUMBER) }"
                       id="euVatNumber">
                <div class="form-control-feedback"
                     *ngIf="hasFieldError(InvoiceSettings.ValidatedField.EU_VAT_NUMBER)">{{getFieldErrorText(InvoiceSettings.ValidatedField.EU_VAT_NUMBER)}}</div>
                <label
                  *ngIf="componentState.isReadonly()" class="form-control detail-description"
                  readonly>{{model.euVatNumber.truncated}}
                </label>
              </div>
            </div>
            <!--endregion EuTaxNumber-->

            <!--region Email-->
            <div class="form-group row"
                 [ngClass]="{ 'has-danger': hasLocalFieldError('emailAddress') || hasFieldError(InvoiceSettings.ValidatedField.EMAIL_ADDRESS) }">
              <label
                class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_CONTACT_PERSON_EMAIL_ADDRESS'
                | translate}}</label>
              <div class="col-md-8">
                <div class="input-group">
                  <input *ngIf="componentState.isEditable()"
                         type="text" class="form-control"
                         placeholder="{{'COMMON_CONTACT_PERSON_EMAIL_ADDRESS' | translate}}"
                         [(ngModel)]="model.emailAddress"
                         (ngModelChange)="removeFieldError(InvoiceSettings.ValidatedField.EMAIL_ADDRESS)"
                         [ngClass]="{ 'form-control-danger': hasLocalFieldError('emailAddress') || hasFieldError(InvoiceSettings.ValidatedField.EMAIL_ADDRESS) }"
                         formControlName="emailAddress"
                         id="emailAddress">
                  <span *ngIf="componentState.isEditable()"
                        class="input-group-addon-gray icomoon icomoon-message-unread" id="basic-addon"></span>
                </div>
                <div class="form-control-feedback"
                     *ngIf="hasLocalFieldError('emailAddress')">{{'COMMON_VALIDATION_MESSAGE_EMAIL' | translate}}</div>
                <div class="form-control-feedback"
                     *ngIf="hasFieldError(InvoiceSettings.ValidatedField.EMAIL_ADDRESS)">{{getFieldErrorText(InvoiceSettings.ValidatedField.EMAIL_ADDRESS)}}</div>
                <label
                  *ngIf="componentState.isReadonly()" class="form-control detail-description"
                  readonly>{{model.emailAddress}}
                </label>
              </div>
            </div>
            <!--endregion Email-->

            <!--region Phone-->
            <div class="form-group row"
                 [ngClass]="{ 'has-danger': hasLocalFieldError('phoneNumber') || hasFieldError(InvoiceSettings.ValidatedField.PHONE_NUMBER) }">
              <label
                class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_CONTACT_PERSON_PHONE_NUMBER'
                | translate}}</label>
              <div class="col-md-8">
                <div class="input-group">
                  <input *ngIf="componentState.isEditable()"
                         type="text" class="form-control"
                         placeholder="{{'COMMON_CONTACT_PERSON_PHONE_NUMBER' | translate}}"
                         [(ngModel)]="model.phoneNumber"
                         (ngModelChange)="removeFieldError(InvoiceSettings.ValidatedField.PHONE_NUMBER)"
                         [ngClass]="{ 'form-control-danger': hasLocalFieldError('phoneNumber') || hasFieldError(InvoiceSettings.ValidatedField.PHONE_NUMBER) }"
                         formControlName="phoneNumber"
                         id="phoneNumber">
                  <span *ngIf="componentState.isEditable()" class="input-group-addon-gray icomoon icomoon-phone"
                        id="basic-addon2"></span>
                </div>
                <div class="form-control-feedback"
                     *ngIf="hasLocalFieldError('phoneNumber')">{{'COMMON_VALIDATION_MESSAGE_PHONE' | translate}}</div>
                <div class="form-control-feedback"
                     *ngIf="hasFieldError(InvoiceSettings.ValidatedField.PHONE_NUMBER)">{{getFieldErrorText(InvoiceSettings.ValidatedField.PHONE_NUMBER)}}</div>
                <label
                  *ngIf="componentState.isReadonly()" class="form-control detail-description"
                  readonly>{{model.phoneNumber}}
                </label>
              </div>
            </div>
            <!--endregion Phone-->

            <!--region BankName-->
            <div class="form-group row"
                 [ngClass]="{ 'has-danger': hasLocalFieldError('bankName') || hasFieldError(InvoiceSettings.ValidatedField.BANK_NAME) }">
              <label class="col-form-label form-control-label col-md-4 detail-title"
                     [class.required-field-label]="model && model.bankAccount.length > 0">
                {{'INVOICE_SETTINGS_BANK_NAME' | translate}}
              </label>
              <div class="col-md-8">
                <input *ngIf="componentState.isEditable()"
                       type="text" class="form-control"
                       placeholder="{{'INVOICE_SETTINGS_BANK_NAME' | translate}}"
                       [(ngModel)]="model.bankName"
                       (ngModelChange)="removeFieldError(InvoiceSettings.ValidatedField.BANK_NAME)"
                       [ngClass]="{ 'form-control-danger': hasLocalFieldError('bankName') || hasFieldError(InvoiceSettings.ValidatedField.BANK_NAME) }"
                       formControlName="bankName"
                       id="bankName">
                <div class="form-control-feedback"
                     *ngIf="hasLocalFieldError('bankName')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                <div class="form-control-feedback"
                     *ngIf="hasFieldError(InvoiceSettings.ValidatedField.BANK_NAME)">{{getFieldErrorText(InvoiceSettings.ValidatedField.BANK_NAME)}}</div>
                <label
                  *ngIf="componentState.isReadonly()" class="form-control detail-description"
                  readonly>{{model.bankName}}
                </label>
              </div>
            </div>
            <!--endregion BankName-->

            <!--region BankAccountNumber-->
            <div class="form-group row"
                 [ngClass]="{ 'has-danger': hasFieldError(InvoiceSettings.ValidatedField.BANK_ACCOUNT) || hasLocalFieldError('bankAccount') }">
              <label
                class="col-form-label form-control-label col-md-4 detail-title"
                [class.required-field-label]="model && model.bankName.length > 0">
                {{'INVOICE_SETTINGS_BANK_ACCOUNT_NUMBER' | translate}}
              </label>
              <div class="col-md-8">
                <input *ngIf="componentState.isEditable()"
                       type="text" class="form-control"
                       placeholder="{{'INVOICE_SETTINGS_BANK_ACCOUNT_NUMBER' | translate}}"
                       [(ngModel)]="model.bankAccount"
                       (ngModelChange)="removeFieldError(InvoiceSettings.ValidatedField.BANK_ACCOUNT)"
                       [ngClass]="{ 'form-control-danger': hasFieldError(InvoiceSettings.ValidatedField.BANK_ACCOUNT) || hasLocalFieldError('bankAccount') }"
                       formControlName="bankAccount"
                       id="bankAccount">
                <div class="form-control-feedback"
                     *ngIf="hasFieldError(InvoiceSettings.ValidatedField.BANK_ACCOUNT)">{{getFieldErrorText(InvoiceSettings.ValidatedField.BANK_ACCOUNT)}}</div>
                <div class="form-control-feedback"
                     *ngIf="hasLocalFieldError('bankAccount')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                <label
                  *ngIf="componentState.isReadonly()" class="form-control detail-description"
                  readonly>{{model.bankAccount}}
                </label>
              </div>
            </div>
            <!--endregion BankAccountNumber-->

            <!--region WebAddress-->
            <div class="form-group row"
                 [ngClass]="{ 'has-danger': hasFieldError(InvoiceSettings.ValidatedField.WEB_ADDRESS) }">
              <label
                class="col-form-label form-control-label col-md-4 detail-title">{{'INVOICE_SETTINGS_WEB_ADDRESS' | translate}}</label>
              <div class="col-md-8">
                <input *ngIf="componentState.isEditable()"
                       type="text" class="form-control"
                       placeholder="{{'INVOICE_SETTINGS_WEB_ADDRESS' | translate}}"
                       [(ngModel)]="model.webAddress"
                       (ngModelChange)="removeFieldError(InvoiceSettings.ValidatedField.WEB_ADDRESS)"
                       [ngModelOptions]="{standalone: true}"
                       [ngClass]="{ 'form-control-danger': hasFieldError(InvoiceSettings.ValidatedField.WEB_ADDRESS) }"
                       id="webAddress">
                <div class="form-control-feedback"
                     *ngIf="hasFieldError(InvoiceSettings.ValidatedField.WEB_ADDRESS)">{{getFieldErrorText(InvoiceSettings.ValidatedField.WEB_ADDRESS)}}</div>
                <label
                  *ngIf="componentState.isReadonly()" class="form-control detail-description"
                  readonly>{{model.webAddress}}
                </label>
              </div>
            </div>
            <!--endregion WebAddress-->

            <!--region RegistrationNumber-->
            <div class="form-group row"
                 [ngClass]="{ 'has-danger': hasFieldError(InvoiceSettings.ValidatedField.REGISTRATION_NUMBER) }">
              <label
                class="col-form-label form-control-label col-md-4 detail-title">{{'INVOICE_SETTINGS_REGISTRATION_NUMBER' | translate}}</label>
              <div class="col-md-8">
                <input *ngIf="componentState.isEditable()"
                       type="text" class="form-control"
                       placeholder="{{'INVOICE_SETTINGS_REGISTRATION_NUMBER' | translate}}"
                       [(ngModel)]="model.registrationNumber"
                       (ngModelChange)="removeFieldError(InvoiceSettings.ValidatedField.REGISTRATION_NUMBER)"
                       [ngModelOptions]="{standalone: true}"
                       [ngClass]="{ 'form-control-danger': hasFieldError(InvoiceSettings.ValidatedField.REGISTRATION_NUMBER) }"
                       id="registrationNumber">
                <div class="form-control-feedback"
                     *ngIf="hasFieldError(InvoiceSettings.ValidatedField.REGISTRATION_NUMBER)">{{getFieldErrorText(InvoiceSettings.ValidatedField.REGISTRATION_NUMBER)}}</div>
                <label
                  *ngIf="componentState.isReadonly()" class="form-control detail-description"
                  readonly>{{model.registrationNumber}}
                </label>
              </div>
            </div>
            <!--endregion RegistrationNumber-->
          </div>
        </div>
      </div>
    </div>
    <input type="submit" class="btn btn-primary-outline floating_apply_button" *ngIf="componentState.isEditable()"
           (click)="submit(false)" value="{{'COMMON_BUTTON_APPLY'|translate}}"/>
    <input type="submit" class="btn btn-primary floating_save_button" *ngIf="componentState.isEditable()"
           (click)="submit(true)" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
  </form>
</div>

<div bsModal [config]="UiConstants.modalConfig" #uploadDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="uploadDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'INVOICE_SETTINGS_UPLOAD_MODAL_TITLE' | translate}}</h4>
        <button type="button" class="close" (click)="closeUploadDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body p-0">
        <div class="col-md-12 p-1">
          <div class="form-group mb-0">
            <div *ngIf="!model.pdfTemplateId" class="uploader-container">
              <app-file-upload [uploadPath]="uploadUrl">
              </app-file-upload>
            </div>
            <div *ngIf="model.pdfTemplateId" class="text-container text-center">
              <h4>{{'INVOICE_SETTINGS_MODAL_BODY' | translate}}</h4>
              <br>
              <a class="button-icon cursor-pointer" (click)="downloadTemplate()">
                <i class="icomoon icomoon-download"></i>
              </a>
              <a class="button-icon cursor-pointer" (click)="deleteTemplate()"
                 title="{{'COMMON_BUTTON_DELETE' | translate}}">
                <i class="icomoon icomoon-trash"></i>
              </a>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary cursor-pointer"
                  (click)="closeUploadDialog()">{{'COMMON_BUTTON_OK' |
            translate}}
          </button>
        </div>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
