/* eslint-disable */
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ShopRenter, ShopRenterService, ShopRenterStorage } from '../../lib/shoprenter/shoprenter.service';
import { Transition, UIRouter } from '@uirouter/angular';
import { ShopRenterModel } from '../shoprenter';
import { StateName } from '../../app.state-names';
import {
  AuthTokenStorage,
  LocalStorages,
  LoginRequiredReason,
  LoginRequiredReasonStorage
} from '../../lib/util/storages';
/* eslint-enable */

@Component({
  selector: 'app-shoprenter-entry',
  templateUrl: './shoprenter-entry.component.html',
  styleUrls: ['./shoprenter-entry.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ShopRenterEntryComponent implements OnInit {

  private storage = ShopRenterStorage.getInstance();

  private _params: ShopRenterModel.ShopRenterAuthRequestParam;

  private _searching = false;
  private _searchFailed = false;
  private _searchDone = false;
  private _invalidArguments: boolean = false;

  get params(): ShopRenterModel.ShopRenterAuthRequestParam {
    return this._params;
  }

  get invalidArguments(): boolean {
    return this._invalidArguments;
  }

  get searching(): boolean {
    return this._searching;
  }

  get searchDone(): boolean {
    return this._searchDone;
  }

  get searchFailed(): boolean {
    return this._searchFailed;
  }

  constructor(
    private shopRenterService: ShopRenterService,
    private router: UIRouter,
    private transition: Transition) {
  }

  ngOnInit(): void {
    const params = this.parseParams();
    this._params = params; // debug
    this.searchShop(params);
  }

  private parseParams(): ShopRenterModel.ShopRenterAuthRequestParam {
    return ShopRenterModel.parseShopRenterAuthRequestParam(this.transition);
  }

  private searchShop(params: ShopRenterModel.ShopRenterAuthRequestParam) {
    if (params.shopName && params.code && params.hmac && params.timestamp) {
      const findRequest: ShopRenter.FindShopRequest = {
        shopName: params.shopName,
        code: params.code,
        hmac: params.hmac,
        timestamp: params.timestamp
      };
      this._searching = true;
      this._searchDone = false;
      this._searchFailed = false;
      this.storage.clear();
      this.shopRenterService.findShop(findRequest).subscribe(
        (result: ShopRenter.FindShopResponse) => {
          this._searching = false;
          this._searchDone = true;
          this.handleShop(findRequest, result);
        },
        (error) => {
          this._searching = false;
          this._searchFailed = true;
        }
      );
    }
    else {
      this._invalidArguments = true;
    }
  }

  private handleShop(request: ShopRenter.FindShopRequest, response: ShopRenter.FindShopResponse) {
    if (response.userId && response.currentUserId === response.userId) {
      // User is logged in and he is the owner of the shop.
      this.router.stateService.go(StateName.SHOP_RENTER_SHOP_EDIT, {id: response.shopId});
      return;
    }

    // Log out if logged in.
    LocalStorages.onLogout();

    // Go to login.
    // Store ShopRenter signature so login can register the user after log-in.
    this.storage.setShopId(response.shopId);
    this.storage.setUserId(response.userId);
    this.storage.setShopName(request.shopName);
    this.storage.setCode(request.code);
    this.storage.setTimestamp(request.timestamp);
    this.storage.setHmac(request.hmac);
    LoginRequiredReasonStorage.getInstance().setReason(LoginRequiredReason.SHOP_RENTER_LOGIN);
    this.router.stateService.go(StateName.LOGIN);
  }

}
