/* eslint-disable */
import { List, Map } from 'immutable';
import { DqlModel } from '../../../lib/dql/dql.model';
import { Subject } from 'rxjs';

/* eslint-enable */

export interface DqlFieldModel {
  title: string;
  id: string;
  dataType: DqlModel.QueryableFieldDataType;
  values?: DqlModel.EnumType[];
  listItemTypeId?: number;
}

export interface DqlOperationModel {
  stringKey: string;
  operation: string;
}

export enum DqlLogicalOperation {
  AND = '&',
  OR = '|'
}

export class DqlLogicalOperationObject {
  operation: DqlLogicalOperation;

  constructor(op: DqlLogicalOperation) {
    this.operation = op;
  }

}

export interface DqlCriteriaModel {
  operation?: DqlOperationModel;
  field?: DqlFieldModel;
  value: string;

  isAddable(): boolean;

  isComplete(): boolean;

  getCriteriaString(isTyped: boolean): string | undefined;

  operandType(): DqlOperandCount;

  setValue(value: string): void;

  getValue(): string;

  operationChanged(): Subject<DqlOperationModel>;
}

export class DqlBinaryCriteriaModel implements DqlCriteriaModel {
  field?: DqlFieldModel;
  private _operation?: DqlOperationModel;
  private _value: string = '';
  private _operationChanged: Subject<DqlOperationModel> = new Subject<DqlOperationModel>();
  public isAddable(): boolean {
    return this.field !== undefined && this.operation !== undefined;
  }

  public isComplete(): boolean {
    return this.field !== undefined && this.operation !== undefined && this.value.length > 0;
  }

  public getCriteriaString(isTyped: boolean): string | undefined {
    if (this.isComplete()) {
      const dt: string = isTyped ? this.field!.dataType : '';
      return this.field!.id + ' ' + this.operation!.operation + ' ' + '"' + this.value + '"' + dt;
    }
    else {
      return undefined;
    }
  }

  public operandType(): DqlOperandCount {
    return DqlOperandCount.BINARY;
  }

  get value(): string {
    return this.getValue();
  }

  set value(value: string) {
    this.setValue(value);
  }

  public setValue(value: string): void {
    this._value = value;
  }

  public getValue(): string {
    return this._value;
  }

  get operation(): DqlOperationModel | undefined {
    return this._operation;
  }

  set operation(operation: DqlOperationModel | undefined) {
    this._operation = operation;
    this._operationChanged.next(this._operation);
  }

  operationChanged(): Subject<DqlOperationModel> {
    return this._operationChanged;
  }
}

export class DqlUnaryCriteriaModel implements DqlCriteriaModel {
  field?: DqlFieldModel;
  _operation?: DqlOperationModel;
  private _operationChanged: Subject<DqlOperationModel> = new Subject<DqlOperationModel>();

  public isAddable(): boolean {
    return this.field !== undefined && this.operation !== undefined;
  }

  public isComplete(): boolean {
    return this.isAddable();
  }

  public getCriteriaString(isTyped: boolean): string | undefined {
    if (this.isComplete()) {
      return this.field!.id + this.operation!.operation;
    }
    else {
      return undefined;
    }
  }

  public operandType(): DqlOperandCount {
    return DqlOperandCount.UNARY;
  }

  get value(): string {
    return this.getValue();
  }

  set value(value: string) {
    this.setValue(value);
  }

  public setValue(value: string): void {
    // nothing to do
  }

  public getValue(): string {
    return '';
  }

  get operation(): DqlOperationModel | undefined {
    return this._operation;
  }

  set operation(operation: DqlOperationModel | undefined) {
    this._operation = operation;
    this._operationChanged.next(this._operation);
  }

  operationChanged(): Subject<DqlOperationModel> {
    return this._operationChanged;
  }

}

export enum DqlOperandCount {
  BINARY, UNARY
}

export enum expressionType {
  SIMPLE, COMPLEX
}

export interface DqlExpressionBuilder {

  getQueryString(isTyped: boolean): string | undefined;

  addCriteria(criteria: DqlCriteriaModel): void;

  addLogical(logical: DqlLogicalOperationObject): void;

  addExpression(expressionBuilder: DqlExpressionBuilder): void;

  closeExpression(): void;

  isOpen(): boolean;

  expressionType(): expressionType;

  getFirstCriteria(): DqlCriteriaModel | undefined;
  changeCriteriaOperation(op: DqlOperationModel | undefined): void;
}

export class SimpleDqlExpressionBuilder implements DqlExpressionBuilder {

  private _criteria?: DqlCriteriaModel;
  private _isOpen: boolean = true;

  addCriteria(criteria: DqlCriteriaModel): void {
    this._criteria = criteria;
  }

  addExpression(expressionBuilder: DqlExpressionBuilder): void {
    throw new Error('cannot add expression to simple expression');
  }

  addLogical(logical: DqlLogicalOperationObject): void {
    throw new Error('cannot add expression to simple expression');
  }

  closeExpression(): void {
    this._isOpen = false;
  }

  getQueryString(isTyped: boolean): string | undefined {
    let q: string | undefined;
    q = this._criteria ? this._criteria.getCriteriaString(isTyped) : undefined;
    return q;
  }

  isOpen(): boolean {
    return this._isOpen;
  }

  expressionType(): expressionType {
    return expressionType.SIMPLE;
  }

  get criteria(): DqlCriteriaModel {
    return this._criteria!;
  }

  getFirstCriteria(): DqlCriteriaModel | undefined {
    return this._criteria;
  }

  changeCriteriaOperation(op: DqlOperationModel | undefined): void {
    this.criteria.operation = op;
  }
}

export class ComplexDqlExpressionBuilder implements DqlExpressionBuilder {


  private _expressionList: DqlExpressionBuilder[] = [];
  private _logicalList: DqlLogicalOperationObject[] = [];
  private _isOpen: boolean = true;

  public getQueryString(isTyped: boolean): string | undefined {
    let q: string | undefined = undefined;
    this._expressionList.forEach((exp, index: number) => {
      const cString: string | undefined = exp.getQueryString(isTyped);
      if (q && cString) {
        q = q + ' ' + this._logicalList[index - 1].operation + ' ' + cString;
      }
      else if (cString) {
        q = cString;
      }
    });
    if (this.hasParenthesis() && q) {
      q = '(' + q + ')';
    }
    return q;

  }

  addCriteria(criteria: DqlCriteriaModel): void {
    const exp = this.lastOpenExpression;
    if (exp === this) {
      throw new Error('cannot add expression to complex expression');
    }
    exp.addCriteria(criteria);
  }

  addExpression(expressionBuilder: DqlExpressionBuilder): void {
    const exp = this.lastOpenExpression;
    if (exp === this) {
      this._expressionList.push(expressionBuilder);
    }
    else {
      exp.addExpression(expressionBuilder);
    }
  }

  addLogical(logical: DqlLogicalOperationObject): void {
    const exp = this.lastOpenExpression;
    if (exp === this) {
      this._logicalList.push(logical);
    }
    else {
      exp.addLogical(logical);
    }
  }

  closeExpression(): void {
    const exp = this.lastOpenExpression;
    if (exp === this) {
      this._isOpen = false;
    }
    else {
      exp.closeExpression();
    }
  }

  isOpen(): boolean {
    return this._isOpen;
  }

  expressionType(): expressionType {
    return expressionType.COMPLEX;
  }

  private get lastOpenExpression(): DqlExpressionBuilder {
    for (let i = this._expressionList.length - 1; i >= 0; i--) {
      const exp = this._expressionList[i];
      if (exp.isOpen()) {
        return exp;
      }
    }
    return this;
  }

  isEmpty() {
    return this._logicalList.length === 0 && this._expressionList.length === 0;
  }

  get expressionList(): DqlExpressionBuilder[] {
    return this._expressionList;
  }

  get logicalList(): DqlLogicalOperationObject[] {
    return this._logicalList;
  }

  removeExpression(expr: DqlExpressionBuilder) {
    const index = this._expressionList.findIndex(e => e === expr);
    if (index >= 0) {
      if (this._logicalList.length > 0) {
        if (index === 0) {
          this._logicalList.splice(0, 1);
        }
        else {
          this._logicalList.splice(index - 1, 0);
        }
      }
      this._expressionList.splice(index, 1);
    }
    else {
      this._expressionList.forEach(e => {
        if (e instanceof ComplexDqlExpressionBuilder) {
          e.removeExpression(expr);
        }
      });
    }
  }

  // test method
  get expressionCount(): number {
    return this._expressionList.length;
  }

  protected hasParenthesis(): boolean {
    return true;
  }

  getFirstCriteria(): DqlCriteriaModel | undefined {
    let crit: DqlCriteriaModel | undefined = undefined;
    for (let i = 0; i < this.expressionList.length; i++) {
      crit = this.expressionList[i].getFirstCriteria();
      if (crit) {
        return crit;
      }
    }
    return undefined;
  }

  changeCriteriaOperation(op: DqlOperationModel | undefined) {
    this.expressionList.forEach(exp => exp.changeCriteriaOperation(op));
  }
}

export class PureDqlQueryBuilder extends ComplexDqlExpressionBuilder {

  getQueryString(isTyped: boolean): string | undefined {
    const q = super.getQueryString(isTyped);
    if (q) {
      return q + ';';
    }
    return q;
  }

  protected hasParenthesis(): boolean {
    return false;
  }
}

export class DqlOperations {
  public static EQUALS: DqlOperationModel = {stringKey: 'DQL_EQUALS', operation: '='};
  public static NOT_EQUALS: DqlOperationModel = {stringKey: 'DQL_NOT_EQUALS', operation: '!='};
  public static CONTAINS: DqlOperationModel = {stringKey: 'DQL_CONTAINS', operation: '~'};
  public static NOT_CONTAINS: DqlOperationModel = {stringKey: 'DQL_NOT_CONTAINS', operation: '!~'};
  public static LESS_THAN: DqlOperationModel = {stringKey: 'DQL_LESS_THAN', operation: '<'};
  public static GREATER_THAN: DqlOperationModel = {stringKey: 'DQL_GREATER_THAN', operation: '>'};
  public static STARTS_WITH: DqlOperationModel = {stringKey: 'DQL_STARTS_WITH', operation: '<'};
  public static ENDS_WITH: DqlOperationModel = {stringKey: 'DQL_ENDS_WITH', operation: '>'};
  public static LESS_THAN_EQUALS: DqlOperationModel = {stringKey: 'DQL_LESS_THAN_EQUALS', operation: '<='};
  public static GREATER_THAN_EQUALS: DqlOperationModel = {stringKey: 'DQL_GREATER_THAN_EQUALS', operation: '>='};
  public static RANGE: DqlOperationModel = {stringKey: 'DQL_RANGE', operation: 'range'};
  public static NULL: DqlOperationModel = {stringKey: 'DQL_NULL', operation: '#'};
  public static NOT_NULL: DqlOperationModel = {stringKey: 'DQL_NOT_NULL', operation: '!#'};

  public static realOperations(): List<DqlOperationModel> {
    return List.of(this.EQUALS, this.NOT_EQUALS, this.CONTAINS, this.NOT_CONTAINS, this.LESS_THAN_EQUALS, this.GREATER_THAN_EQUALS,
      this.STARTS_WITH, this.NULL, this.NOT_NULL);
  }
};


export const AvailableDqlOperations: Map<DqlModel.QueryableFieldDataType, List<DqlOperationModel>> = Map.of(
  DqlModel.QueryableFieldDataType.STRING, List.of(DqlOperations.CONTAINS, DqlOperations.EQUALS, DqlOperations.STARTS_WITH),
  DqlModel.QueryableFieldDataType.NUMBER, List.of(DqlOperations.RANGE),
  DqlModel.QueryableFieldDataType.DECIMAL, List.of(DqlOperations.RANGE),
  DqlModel.QueryableFieldDataType.DATE, List.of(DqlOperations.RANGE),
  DqlModel.QueryableFieldDataType.DATE_TIME, List.of(DqlOperations.RANGE),
  DqlModel.QueryableFieldDataType.BOOLEAN, List.of(DqlOperations.EQUALS, DqlOperations.NOT_EQUALS),
  DqlModel.QueryableFieldDataType.LIST_ITEM, List.of(DqlOperations.EQUALS, DqlOperations.NOT_EQUALS),
  DqlModel.QueryableFieldDataType.LIST_MULTI_ITEM, List.of(DqlOperations.CONTAINS, DqlOperations.NOT_CONTAINS),
  DqlModel.QueryableFieldDataType.EMAIL_ADDRESS, List.of(DqlOperations.CONTAINS, DqlOperations.EQUALS, DqlOperations.STARTS_WITH),
  DqlModel.QueryableFieldDataType.PHONE_NUMBER, List.of(DqlOperations.CONTAINS, DqlOperations.EQUALS, DqlOperations.STARTS_WITH),
  DqlModel.QueryableFieldDataType.ENUM, List.of(DqlOperations.EQUALS, DqlOperations.NOT_EQUALS),
  DqlModel.QueryableFieldDataType.PAYMENT_TYPE, List.of(DqlOperations.EQUALS, DqlOperations.NOT_EQUALS),
  DqlModel.QueryableFieldDataType.USER, List.of(DqlOperations.EQUALS, DqlOperations.NOT_EQUALS),
  DqlModel.QueryableFieldDataType.USER_GROUP, List.of(DqlOperations.EQUALS, DqlOperations.NOT_EQUALS),
  DqlModel.QueryableFieldDataType.MOBILE_APP, List.of(DqlOperations.EQUALS, DqlOperations.NOT_EQUALS),
  DqlModel.QueryableFieldDataType.CUSTOMER, List.of(DqlOperations.EQUALS, DqlOperations.NOT_EQUALS),
  DqlModel.QueryableFieldDataType.CONTACT_LOCATION, List.of(DqlOperations.EQUALS, DqlOperations.NOT_EQUALS),
  DqlModel.QueryableFieldDataType.COMPANY, List.of(DqlOperations.EQUALS, DqlOperations.NOT_EQUALS),
  DqlModel.QueryableFieldDataType.COMPANY_LOCATION, List.of(DqlOperations.EQUALS, DqlOperations.NOT_EQUALS),
  DqlModel.QueryableFieldDataType.PROCESS, List.of(DqlOperations.EQUALS, DqlOperations.NOT_EQUALS),
  DqlModel.QueryableFieldDataType.CONTRACT_NUMBER, List.of(DqlOperations.EQUALS, DqlOperations.NOT_EQUALS),
);

export const AvailableUnaryDqlOperations: List<DqlOperationModel> = List.of(
  DqlOperations.NOT_NULL, DqlOperations.NULL
);
