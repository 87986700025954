/* eslint-disable */
import { map } from 'rxjs/operators';
import { DownloadedFile } from '../../util/downloaded-files';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlBuilder } from '../../../util/url-builder';
import { ResourceQueryResult } from '../../util/services';
import { BaseHttpService, ResourceHelper } from '../../util/http-services';
import { HttpClient } from '@angular/common/http';

/* eslint-enable */

@Injectable()
export class TransportDocumentResourceService extends BaseHttpService {

  query(request: TransportDocumentResource.QueryRequest): Observable<ResourceQueryResult<TransportDocumentResource.TransportDocument>> {
    return this.http.get<ResourceQueryResult<TransportDocumentResource.TransportDocument>>(
      this.url + `${request.transport_id}/documents`,
      this.parseParams(request)
    );
  }

  downloadDocument(request: TransportDocumentResource.DownloadDocumentRequest): Observable<DownloadedFile> {
    const url = UrlBuilder.create(
      '/transports/:transport_id/documents/:id/download')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .namedNumber('transport_id', request.transport_id)
      .namedNumber('id', request.id)
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob'
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  constructor(private http: HttpClient, private resourceHelper: ResourceHelper) {
    super(resourceHelper, '/transports/');
  }
}


export namespace TransportDocumentResource {

  export interface TransportDocument {
    id: number;
    serial: string;
    version: number;
    name: string;
    document_type: string;
    creation_time: string;
    content_hash: string;
    shipment?: ShipmentData;
    transport_id: number;
  }

  export interface ShipmentData {
    id: number;
    external_id: string;
    delivery_note_number: string;
  }

  export interface QueryRequest {
    only_most_recent: boolean;
    shipment_id?: string;
    document_type?: string;
    transport_id: number;
    q?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
  }

  export interface DownloadDocumentRequest {
    transport_id: number;
    id: number;
  }

}
