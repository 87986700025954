<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<!-- region Content -->
<div class="container-horizontal-padding-half container-vertical-padding-half animated fadeIn">

  <div class="row ml-0 mr-0">

    <!-- region WorkflowTask card -->
    <div class="col-xs-12 col-sm-12 col-md-6 card-custom-padding">
      <div class="container-vertical-padding-half">
        <div class="card">

          <div class="card-header">
            {{'WORKFLOW_TASK_EDIT'|translate}}
          </div><!-- /.card-header -->

          <div class="card-body">
            <form class="form-horizontal bordered-row" (ngSubmit)="update()" #f="ngForm" novalidate>

              <!-- region Name -->
              <div class="form-group row"
                   [ngClass]="{ 'has-danger': hasFieldError(WorkflowTask.ValidatedField.NAME) || hasLocalFieldError(name) }">
                <div class="col-md-4">
                  <label
                    class="mw-100 col-form-label form-control-label">{{'COMMON_NAME' | translate}}</label>
                </div>
                <div class="col-md-8">
                  <div class="mw-100">
                    <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
                           [ngClass]="{ 'form-control-danger': hasFieldError(WorkflowTask.ValidatedField.NAME) || hasLocalFieldError(name) }"
                           [(ngModel)]="model.name" (ngModelChange)="removeFieldError(WorkflowTask.ValidatedField.NAME)"
                           name="name" id="name" #name="ngModel" required>
                    <div class="form-control-feedback"
                         *ngIf="hasFieldError(WorkflowTask.ValidatedField.NAME)">{{getFieldErrorText(WorkflowTask.ValidatedField.NAME)}}</div>
                    <div class="form-control-feedback"
                         *ngIf="hasLocalFieldError(name)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                  </div><!-- /.edit-form-input-group -->
                </div>
              </div><!-- /.form-group -->
              <!-- endregion Name -->


              <!-- region External id -->
              <div class="form-group row"
                   [ngClass]="{ 'has-danger': hasFieldError(WorkflowTask.ValidatedField.EXTERNAL_ID) || hasLocalFieldError(external_id) }">
                <div class="col-md-4">
                  <label class="mw-100 col-form-label form-control-label">{{'COMMON_EXTERNAL_ID' | translate}}</label>
                </div>
                <div class="col-md-8">
                  <div class="mw-100">
                    <input type="text" class="form-control" placeholder="{{'COMMON_EXTERNAL_ID' | translate}}"
                           [ngClass]="{ 'form-control-danger': hasFieldError(WorkflowTask.ValidatedField.EXTERNAL_ID) || hasLocalFieldError(external_id) }"
                           [(ngModel)]="model.externalId"
                           (ngModelChange)="removeFieldError(WorkflowTask.ValidatedField.EXTERNAL_ID)"
                           name="external_id" id="external_id" #external_id="ngModel" required>
                    <div class="form-control-feedback"
                         *ngIf="hasFieldError(WorkflowTask.ValidatedField.EXTERNAL_ID)">{{getFieldErrorText(WorkflowTask.ValidatedField.EXTERNAL_ID)}}</div>
                    <div class="form-control-feedback"
                         *ngIf="hasLocalFieldError(external_id)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                  </div><!-- /.edit-form-input-group -->
                </div>
              </div><!-- /.form-group -->
              <!-- endregion External id -->

              <!-- region Description -->
              <div class="form-group row">
                <div class="col-md-4">
                  <label
                    class="mw-100 col-form-label form-control-label">{{'COMMON_DESCRIPTION' | translate}}</label>
                </div>
                <div class="col-md-8">
                  <div class="mw-100">
                    <input type="text" class="form-control"
                           placeholder="{{'COMMON_DESCRIPTION' | translate}}"
                           [(ngModel)]="model.description" name="description" id="description" #description="ngModel">
                  </div><!-- /.edit-form-input-group -->
                </div>
              </div><!-- /.form-group -->
              <!-- endregion Description -->

              <!-- region inProgressStateName -->
              <div class="form-group row" [ngClass]="{ 'has-danger': hasLocalFieldError(inProgressStateName) }">
                <div class="col-md-4">
                  <label
                    class="mw-100 col-form-label form-control-label">{{'WORKFLOW_TASK_STATE_NAME_IN_PROGRESS' | translate}}</label>
                </div>
                <div class="col-md-8">
                  <div class="mw-100">
                    <input type="text" class="form-control"
                           placeholder="{{'WORKFLOW_TASK_STATE_NAME_IN_PROGRESS' | translate}}"
                           [ngClass]="{ 'form-control-danger': hasLocalFieldError(inProgressStateName) }"
                           [(ngModel)]="model.inProgressStateName"
                           name="inProgressStateName"
                           id="inProgressStateName"
                           #inProgressStateName="ngModel"
                           required>
                    <div class="form-control-feedback" *ngIf="hasLocalFieldError(inProgressStateName)">
                      {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                    </div>
                  </div><!-- /.edit-form-input-group -->
                </div>
              </div><!-- /.form-group -->
              <!-- endregion inProgressStateName -->

              <!-- region finishedStateName -->
              <div class="form-group row" [ngClass]="{ 'has-danger': hasLocalFieldError(finishedStateName) }">
                <div class="col-md-4">
                  <label
                    class="mw-100 col-form-label form-control-label">{{'WORKFLOW_TASK_STATE_NAME_FINISHED' | translate}}</label>
                </div>
                <div class="col-md-8">
                  <div class="mw-100">
                    <input type="text" class="form-control"
                           placeholder="{{'WORKFLOW_TASK_STATE_NAME_FINISHED' | translate}}"
                           [ngClass]="{ 'form-control-danger': hasLocalFieldError(finishedStateName) }"
                           [(ngModel)]="model.finishedStateName"
                           name="finishedStateName"
                           id="finishedStateName"
                           #finishedStateName="ngModel"
                           required>
                    <div class="form-control-feedback" *ngIf="hasLocalFieldError(finishedStateName)">
                      {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                    </div>
                  </div><!-- /.edit-form-input-group -->
                </div>
              </div><!-- /.form-group -->
              <!-- endregion finishedStateName -->

              <!-- region customerSignature -->
              <div class="form-group row">
                <div class="col-md-4">
                  <label
                    class="col-form-label mw-100 form-control-label">{{'WORKFLOW_TASK_CUSTOMER_SIGNATURE'|translate}}</label>
                </div>
                <div class="col-md-8">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.customerSignature">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>
              <!-- endregion customerSignature -->

              <!-- region userSignature -->
              <div class="form-group row">
                <div class="col-md-4">
                  <label
                    class="col-form-label mw-100 form-control-label">{{'WORKFLOW_TASK_USER_SIGNATURE'|translate}}</label>
                </div>
                <div class="col-md-8">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.userSignature">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>
              <!-- endregion userSignature -->

              <!-- region Bottom buttons -->
              <div>
                <div class="d-flex justify-content-end">
                  <input type="submit" class="btn btn-primary floating_save_button"
                         value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
                </div><!-- /.edit-form-button-group -->
              </div>
              <!-- endregion Bottom buttons -->

            </form>
          </div><!-- /.card-body -->

        </div><!-- /.card -->
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 card-custom-padding">

      <div class="container-vertical-padding-half">

        <div class="card">
          <div class="card-header">
            {{'WORKFLOW_TASK_PDF_TEMPLATES'|translate}}
            <div class="card-actions">
              <a class="btn-setting table-header-action-button" title="{{'COMMON_BUTTON_ADD' | translate}}"
                 (click)="openUploadPdfDialog()"><i
                class="icomoon icomoon-add"></i></a>
            </div><!-- /.card-actions -->
          </div>
          <div class="card-body">
            <div class="col-sm-12 p-0">

              <table class="table table-striped table-bordered" *ngIf="pdfs.length !== 0">
                <thead>
                <tr>
                  <th>{{'COMMON_NAME' | translate}}</th>
                  <th>{{'FORM_EDIT_DYNAMIC_PDF_STATES' | translate}}</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr class="table" *ngFor="let row of pdfs">
                  <td>{{row.name}}</td>
                  <td>{{taskStateMapper(row.allowedTaskStatesForGeneration)}}</td>
                  <td nowrap>
                    <div class="pull-right">
                      <a class="icons-table-group cursor-pointer"
                         (click)="downloadPdf(row.pdfTemplateId, row.name)">
                        <i class="icomoon icons-table-item icomoon-download icomoon-detail-table"
                           title="{{'COMMON_BUTTON_DOWNLOAD' | translate}}"></i>
                      </a>
                      <a class="icons-table-group cursor-pointer"
                         (click)="openEditPdfDialog(row.pdfTemplateId, row.name, row.allowedTaskStatesForGeneration)">
                        <i class="icomoon icons-table-item icomoon-modify icomoon-modify-table"
                           title="{{'COMMON_BUTTON_EDIT' | translate}}"></i>
                      </a>
                      <a class="icons-table-group cursor-pointer" (click)="openDeletePdfDialog(row.pdfTemplateId)">
                        <i class="icomoon icons-table-item icomoon-trash icomoon-disable-table"
                           title="{{'COMMON_BUTTON_DELETE' | translate}}"></i>
                      </a>
                    </div>
                  </td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                  <th>{{'COMMON_NAME' | translate}}</th>
                  <th>{{'FORM_EDIT_DYNAMIC_PDF_STATES' | translate}}</th>
                  <th></th>
                </tr>
                </tfoot>
              </table>
              <div class="text-center" *ngIf="pdfs.length === 0">
                <p class="empty-form-hint-text">{{ 'PDF_LIST_ADD_TEXT' | translate }}</p>
                <button type="button" class="btn btn-secondary"
                        (click)="openUploadPdfDialog()">{{ 'COMMON_BUTTON_ADD' | translate }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div><!-- /.container-vertical-padding-half -->
  <!-- endregion Workflow Task -->

  <!-- region Form card -->
  <div class="container-vertical-padding-half card-custom-padding-outer form-editor-card-container" id="form-editor-card-container">
    <app-form-edit #formEdit
                   [formServiceCode]="FormServiceCode.WORKFLOW_TASK"
                   [formRights]="formRights"
                   [parentId]="workflowTaskId"
                   [dialogContainer]="dialogContainer">
    </app-form-edit>
  </div><!-- /.container-vertical-padding -->
  <!-- endregion Form card -->

</div>
<!-- endregion Content -->

<!-- region Dialogs -->
<app-form-edit-dialog-container #dialogContainer></app-form-edit-dialog-container>
<!-- endregion Dialogs -->

<div bsModal [config]="UiConstants.modalConfig" #uploadPdfDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="uploadPdfDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'WORKFLOW_TASK_PDF_TEMPLATE_ADD' | translate}}</h4>
        <button type="button" class="close" (click)="closeUploadPdfDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body p-0">
        <div class="col-md-12 p-1">
          <div class="form-group mb-0">
            <label>{{'COMMON_NAME' | translate}}</label>
            <br>
            <input class="form-control" type="text" placeholder="{{'COMMON_NAME' | translate}}"
                   name="upload_static_pdf_name"
                   id="upload_static_pdf_name" [(ngModel)]="currentPdfName" required>
            <br>
            <label>{{'TASK_RECORD_TABLE_HEADER_STATE' | translate}}</label>
            <br>
            <angular2-multiselect
              [(ngModel)]="selectedStates"
              [ngModelOptions]="{standalone: true}"
              [data]="taskStates"
              [settings]="dropdownSettings">
            </angular2-multiselect>
            <br>
            <app-file-upload
              #fileUploadComponent
              [uploadPath]="uploadPath"
              (responseContent)="onUploadSuccess($event)">
            </app-file-upload>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="closeUploadPdfDialog(true)">
            {{'COMMON_BUTTON_CANCEL' | translate}}
          </button>
          <button type="button" class="btn btn-primary" [disabled]="!uploadSuccessful"
                  (click)="uploadPdfFinish()">{{'COMMON_BUTTON_SAVE' |
            translate}}
          </button>
        </div>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<div bsModal [config]="UiConstants.modalConfig" #editPdfDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="editPdfDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'WORKFLOW_TASK_PDF_TEMPLATE_UPDATE' | translate}}</h4>
        <button type="button" class="close" (click)="closeEditPdfDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group col-md-12">
          <label>{{'COMMON_NAME' | translate}}</label>
          <br>
          <input class="form-control" type="text" placeholder="{{'COMMON_NAME' | translate}}"
                 name="edit_static_pdf_name"
                 id="edit_static_pdf_name" required [(ngModel)]="currentPdfName">
          <br>
          <label>{{'TASK_RECORD_TABLE_HEADER_STATE' | translate}}</label>
          <br>
          <angular2-multiselect
            [(ngModel)]="selectedStates"
            [ngModelOptions]="{standalone: true}"
            [data]="taskStates"
            [settings]="dropdownSettings">
          </angular2-multiselect>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeEditPdfDialog()">
          {{'COMMON_BUTTON_CANCEL' | translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="updatePdf()">{{'COMMON_BUTTON_SAVE' |
          translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<div bsModal [config]="UiConstants.modalConfig" #deletePdfDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="deletePdfDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'WORKFLOW_TASK_PDF_TEMPLATE_DELETE' | translate}}</h4>
        <button type="button" class="close" (click)="closeDeletePdfDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group col-md-12 mb-0">
          <label>{{'WORKFLOW_TASK_DELETE_PDF_DIALOG_LABEL' | translate}}</label>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeDeletePdfDialog()">
          {{'COMMON_BUTTON_CANCEL' | translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="deletePdf()">{{'COMMON_BUTTON_DELETE' |
          translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
