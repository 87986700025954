/* eslint-disable */
import { Component, OnDestroy, } from '@angular/core';
import {
  FormRecordFieldContext,
  FormRecordFieldValueReference,
  FormRecordFieldValueUpdateArgs,
  FormRecordFieldViewContext,
  FormRecordFieldViewModel,
  FormRecordListMultiItemFieldView,
  FormRecordListMultiItemValue,
  SetTmpReadonlyArgs,
  SetTmpReadonlyResult,
} from '../../../../../util/form/form-utils';
import { Form } from '../../../../../lib/form/form.service';
import {AbstractControl, FormBuilder, FormGroup, Validators,} from '@angular/forms';
import {
  FormRef,
  ForwardingFormRef,
  LocalFormGroupValidationErrors,
  QueryResult,
} from '../../../../../lib/util/services';
import { FormRecord } from '../../../../../lib/form/form-record.service';
import { AppValidators } from '../../../../../util/app-validators';
import { Models } from '../../../../../util/model-utils';
import { TranslateService } from '@ngx-translate/core';
import { RawListItem } from '../../../../../lib/list-item/list-item-resource.service';
import { List, Set, } from 'immutable';
import { FieldActivationState, FieldActivationStateResolver, } from '../../../../../util/form/form-editors';
import { Command } from '../../../../../util/command';
import { StringKey } from '../../../../../app.string-keys';
import { UiConstants } from '../../../../../util/core-utils';
import { Angular2Multiselects } from '../../../../../util/multiselect';
import { ofCondition } from '../../../../../util/wait';
import { FormRecordInactivityManager } from '../../manager/form-record-inactivity-manager';
import { Arrays } from '../../../../../lib/util/arrays';
import { debounceTime } from 'rxjs/operators';
import { Observable, Subject, Subscription } from 'rxjs';
import {
  FieldNotification,
  FormRecordFieldNotifierService
} from '../../../../../lib/form/form-record-field-notifier.service';
import { ListItemMapService } from '../../../../../lib/list-item/list-item-map.service';

/* eslint-enable */

@Component({
  selector: 'app-form-record-multi-item-field',
  templateUrl: 'form-record-multi-item-field.component.html',
  styleUrls: ['form-record-multi-item-field.component.scss'],
})
export class FormRecordMultiItemFieldComponent implements FormRecordListMultiItemFieldView, OnDestroy {

  get nonEditable(): boolean {
    return FieldActivationStateResolver.isNonEditable(
      this.fieldActivationState
    );
  }

  get requiredDisabled(): boolean {
    return FieldActivationStateResolver.isRequiredDisabled(
      this.fieldActivationState
    );
  }

  get required(): boolean {
    const optional = this.optionalValue;
    const requiredDisabled = this.requiredDisabled;
    return !optional && !requiredDisabled;
  }

  private get fieldActivationState(): FieldActivationState {
    return FieldActivationStateResolver.resolveFieldActivationState({
      readonlyFormFn: () => this.readonlyFormFn(),
      readonlyFieldFn: () => this.readonlyFieldFn(),
      tmpReadonlyFieldFn: () => this.tmpReadonly,
    });
  }

  private get canApplyDefaultValue(): boolean {
    return FieldActivationStateResolver.canApplyDefaultValue({
      formRecordFieldContext: () => {
        return this.formRecordFieldContext;
      },
      fieldActivationState: () => {
        return this.fieldActivationState;
      },
    });
  }

  private get willBeAbleToApplyDefaultValue(): boolean {
    return FieldActivationStateResolver.willBeAbleToApplyDefaultValue({
      formRecordFieldContext: () => {
        return this.formRecordFieldContext;
      },
      fieldActivationState: () => {
        return this.fieldActivationState;
      },
    });
  }

  private get valueFormControl(): AbstractControl {
    return this.formGroup.get('value')!;
  }

  private get reqContext() {
    return this.formRecordFieldContext!;
  }

  private get reqAttrs(): Form.FieldDataTypeListMultiItemAttributes {
    return this.formRecordFieldContext!.field.dataType.listMultiItemAttributes!;
  }

  private get reqDefaultValue(): ListItemItem[] {
    const ids = this.reqAttrs.defaultValue;
    return this.listItems.filter((item) => {
      return Arrays.inArray(ids, item.id);
    });
  }

  public readonly selector: Form.FieldDataTypeSelector.LIST_MULTI_ITEM;

  private loaded = false;
  private dropdownDisabled: boolean = false;
  private formFieldWidthPercent: number = 100;

  formGroup: FormGroup;

  model: Model = new Model(
    () => this.tmpReadonly,
    () => this.willBeAbleToApplyDefaultValue,
    () => this.defaultListItems
  );
  valueReference: ValueReference;

  // context fields are always optional
  formRecordFieldContext?: FormRecordFieldContext;
  formRecordInactivityManager?: FormRecordInactivityManager;
  private fieldId?: number;
  private groupId?: number;
  private htmlForm?: FormRef;

  tmpReadonly: boolean = false;

  private optionalValue: boolean = false;

  listItems: ListItemItem[] = [];
  defaultListItems: ListItemItem[] = [];
  private filterItemIds: number[] = [];

  dropdownSettings: Angular2Multiselects.Settings;

  private defText: string;
  private formGroupValidationErrors: LocalFormGroupValidationErrors;

  private querySubject: Subject<string> = new Subject<string>();
  private filterNotificationSubscription: Subscription;

  private readonlyFormFn: () => boolean = () => true;
  private readonlyFieldFn: () => boolean = () => false;
  private hiddenFieldFn: () => boolean = () => false;
constructor(fb: FormBuilder,
              private translateService: TranslateService,
              private formRecordFieldNotifierService: FormRecordFieldNotifierService,
              private listItemService: ListItemMapService) {
    this.valueReference = this.createValueReference();
    this.formGroup = this.createFormGroup(fb);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(
      this.createForwardingHtmlForm(),
      this.formGroup
    );
    this.initDefText();
    this.initListItemFilter();
    this.filterNotificationSubscription
      = this.formRecordFieldNotifierService.subscribe(n => this.onFieldNotification(n));
  }

  setTmpReadonly(args: SetTmpReadonlyArgs): Command<SetTmpReadonlyResult> {
    const listItemItemToIdText = (item: ListItemItem) => {
      return item.id === null ? null : item.id.toString();
    };
    let previousTmpReadonly;
    let previousValues;
    return {
      execute: async () => {
        await ofCondition({
          condition: () => {
            return this.loaded;
          }
        });
        previousTmpReadonly = this.tmpReadonly;
        previousValues = List.of(...this.model.values).toArray();
        const inactiveValue: ListItemItem[] = [];
        const defaultValue: ListItemItem[] = this.reqDefaultValue;
        let changed = false;
        if (this.tmpReadonly !== args.tmpReadonly) {
          if (args.tmpReadonly) {
            changed = FieldActivationStateResolver.inactivationChangesTheValue({
              debugId: this.reqContext.field.title,
              valueIsEmpty: Arrays.equals(this.model.values, inactiveValue, listItemItemToIdText),
              valueEqualsDefaultValue: Arrays.equals(this.model.values, defaultValue, listItemItemToIdText),
              defaultValueIsEmpty: Arrays.equals(defaultValue, inactiveValue, listItemItemToIdText),
              canApplyDefaultValue: this.canApplyDefaultValue
            });
            this.model.values = inactiveValue;
            this.tmpReadonly = args.tmpReadonly; // last
          }
          else {
            this.tmpReadonly = args.tmpReadonly; // first
            this.applyDefaultValue();
          }
          this.valueFormControl.updateValueAndValidity();
        }
        return {
          changed: changed
        };
      },
      undo: async () => {
        await ofCondition({
          condition: () => {
            return this.loaded;
          }
        });
        this.tmpReadonly = previousTmpReadonly;
        this.model.values = List.of(...previousValues).toArray();
        this.valueFormControl.updateValueAndValidity();
      }
    };
  }

  registerField(context: FormRecordFieldContext, originalModel?: any): any {
    if (originalModel) {
      this.model = originalModel;
      this.valueReference = this.createValueReference();
    }
    this.formRecordFieldContext = context;
    this.fieldId = context.field.fieldId;
    this.groupId = context.group.groupId;
    this.htmlForm = context.htmlForm;
    this.readonlyFormFn = context.readonly;
    this.hiddenFieldFn = () => Form.FormFieldValidationType.HIDDEN === context.validationType;
    this.readonlyFieldFn = () => Form.FormFieldValidationType.READONLY === context.validationType
    || Form.FormFieldValidationType.HIDDEN === context.validationType;
    const attrs = context.field.dataType.listMultiItemAttributes!;
    this.optionalValue = Form.FormFieldValidationType.REQUIRED !== context.validationType;
    this.model.title = context.field.title;
    this.model.hint = Models.optToString(context.field.hint);
    this.model.placeholder = Models.optToString(attrs.hint);
    this.model.multiSelect = attrs.multiSelect;
    this.model.filterFieldId = attrs.filterFieldId;
    if (this.model.filterFieldId) {
      this.setDropdownDisabled(true);
    }
    this.loadFieldData();
    return this.model;
  }

  private loadFieldData() {
    const context = this.formRecordFieldContext!;
    this.formFieldWidthPercent = context.field.formFieldWidthPercent;
    if (this.canApplyDefaultValue) {
      const defaultValue = this.reqAttrs.defaultValue;
      this.setValuesBeforeLoad(Set.of<number>(...defaultValue));
    }
    if (context.fieldRecord) {
      const dataAttrs = context.fieldRecord.data.listMultiItemAttributes!;
      this.setValuesBeforeLoad(dataAttrs.values);
    }
    this.initDropdown(this.formFieldWidthPercent, this.dropdownDisabled);
    this.loadListItems();
  }

  private setValuesBeforeLoad(ids: Set<number>) {
    this.model.prevValueIds = ids;
  }

  private applyDefaultValue() {
    if (this.canApplyDefaultValue) {
      this.model.values = this.defaultListItems;
    }
  }

  onManualChange() {
    this.formRecordInactivityManager!.onListMultiItemFieldChangedByUser(this);
    this.onModelChange();
  }

  registerFieldViews(context: FormRecordFieldViewContext): void {
    this.formRecordInactivityManager = context.inactivityManager;
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  validateWithInterrupt(): boolean {
    return false;
  }

  shouldNotifyAfterCreation(): boolean {
    return false;
  }

  afterFormRecordCreation(formRecordId: number): Observable<any> | undefined {
    return undefined;
  }

  createModel(): FormRecordFieldViewModel {
    if (this.fieldId === undefined) {
      throw new Error('Field ID is undefined');
    }
    const attrs: FormRecord.FieldDataListMultiItemAttributes = {
      values: this.model.getValueIdSet()
    };
    return {
      fieldEditRequest: {
        fieldId: this.fieldId,
        data: {
          listMultiItemAttributes: attrs
        }
      }
    };
  }

  private createValueReference() {
    return new ValueReference(this.model, () => {
      return this.loaded;
    });
  }

  private createForwardingHtmlForm() {
    return new ForwardingFormRef({
      formFn: () => {
        return this.htmlForm;
      }
    });
  }

  private createFormGroup(fb: FormBuilder) {
    return fb.group(
      {
        value: fb.control(
          {value: this.model.values},
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => {
                return !this.required;
              }
            }),
            AppValidators.tempValidator({
              validator: AppValidators.maxArraySize({
                array: () => {
                  return this.model.values;
                },
                maxSize: 1
              }),
              disabled: () => {
                const requiredDisabled = this.requiredDisabled;
                return this.model.multiSelect || requiredDisabled;
              },
            }),
            AppValidators.validateEnabledItems
          ]
        )
      }
    );
  }

  private onListItemSearch(q?: string) {
    this.listItemService.getList({
      type_key: this.reqAttrs.listItemTypeKey,
      disabled: false,
      page_number: 1,
      number_of_items: UiConstants.autocompletePageSize,
      text: q,
      filter_item_id: this.filterItemIds && this.filterItemIds.length > 0 ? this.filterItemIds.join(',') : undefined,
      no_progress_bar: true
    }).subscribe((result: QueryResult<RawListItem>) => {
      this.listItems = [];
      result.items.forEach(item => {
        if (item === undefined) {
          return;
        }
        const i = {
          id: item.id,
          itemName: item.text,
          disabled: item.disabled,
          filterItemIds: item.filter_item_ids
        };
        this.listItems.push(i);
      });
    });
  }

  private loadListItems() {
    const defaultValue = this.reqAttrs.defaultValue;
    this.defaultListItems = [];
    this.model.values = [];
    this.listItemService.getList({
      type_key: this.reqAttrs.listItemTypeKey,
      disabled: false,
      page_number: 1,
      number_of_items: UiConstants.autocompletePageSize,
      order: 'text',
    }).subscribe((result: QueryResult<RawListItem>) => {
      this.listItems = [];
      result.items.forEach(item => {
        if (item === undefined) {
          return;
        }
        const i = {
          id: item.id,
          itemName: item.text,
          disabled: item.disabled,
          filterItemIds: item.filter_item_ids
        };
        if (!i.disabled) {
          this.listItems.push(i);
        }
        if (item.id !== null && Arrays.inArray(defaultValue, item.id)) {
          this.defaultListItems.push(i);
        }
        if (!this.tmpReadonly) {
          if (this.model.prevValueIds && this.model.prevValueIds.contains(i.id)) {
            this.applyValue(i);
          }
        }
      });
      this.onModelChange();
      if (defaultValue.length !== this.defaultListItems.length) {
        this.listItemService.getList({id: defaultValue.join(',')}).subscribe((result: QueryResult<RawListItem>) => {
          result.items.forEach(item => {
            if (item === undefined) {
              return;
            }
            this.defaultListItems = [];
            const i = {
              id: item.id,
              itemName: item.text,
              disabled: item.disabled,
              filterItemIds: item.filter_item_ids
            };
            if (!i.disabled) {
              this.defaultListItems.push(i);
            }
          });
          this.onModelChange();
        });
      }
      if (!this.loaded && this.model.prevValueIds && this.model.values.length < this.model.prevValueIds.size) {
        this.listItemService.getList({id: this.model.prevValueIds.join(',')}).subscribe((result: QueryResult<RawListItem>) => {
          result.items.forEach(item => {
            if (item === undefined) {
              return;
            }
            const i = {
              id: item.id,
              itemName: item.text,
              disabled: item.disabled,
              filterItemIds: item.filter_item_ids
            };
            if (!i.disabled && this.listItems.findIndex(item => item.id === i.id) === -1) {
              this.listItems.push(i);
            }
            const index = this.model.values.findIndex(item => item.id === i.id);
            if (index === -1) {
              this.applyValue(i);
            }
          });
          this.onModelChange();
          this.valueFormControl.updateValueAndValidity({
            onlySelf: false,
            emitEvent: true
          });
          this.loaded = true;
        });
      }
      else if (!this.loaded) {
        this.valueFormControl.updateValueAndValidity({
          onlySelf: false,
          emitEvent: true
        });
        this.loaded = true;
      }
    });
  }

  // Used by only the loader function.
  private applyValue(value: ListItemItem) {
    if (this.readonlyFieldFn() && this.reqContext.cloning()) {
      // Do not use read-only data for cloning.
      // Note that readonly field is filtered from the service request.
      return;
    }
    this.model.values.push(value);
  }

  private initDropdown(widthPercent: number, disabled: boolean) {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(!this.model.multiSelect)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .disabled(disabled)
      .badgeShowLimit(UiConstants.calculateMultiselectMaxBadge(widthPercent))
      .text(this.model.placeholder ? this.model.placeholder : this.defText)
      .remoteSearch(true)
      .build();
  }

  private initDefText() {
    this.translateService.get(StringKey.COMMON_VALUE_UNSELECTED).subscribe(
      (text: string) => {
        this.defText = text;
      }
    );
  }

  private initListItemFilter() {
    this.querySubject.pipe(debounceTime(UiConstants.autocompleteDebounceTime)).subscribe(q => {
      this.onListItemSearch(q);
    });
  }

  filterListItems(value?: string) {
    this.querySubject.next(value);
  }

  updateValue(data: FormRecordFieldValueUpdateArgs) {
  }

  onModelChange() {
    this.formRecordFieldNotifierService.notify({
      groupId: this.groupId!,
      fieldId: this.fieldId!,
      data: {
        filterItemIds: [...this.model.values.filter(v => v.id !== null).map(v => v.id)]
      }
    });
  }

  ngOnDestroy(): void {
    this.formRecordFieldNotifierService.unsubscribe(this.filterNotificationSubscription);
  }

  private onFieldNotification(n: FieldNotification) {
    if (n.data && this.fieldId && n.fieldId === this.model.filterFieldId) {
      if (n.data.filterItemIds === undefined || n.data.filterItemIds.length === 0) {
        this.model.values.splice(0, this.model.values.length);
        this.filterItemIds.splice(0, this.filterItemIds.length);
        this.setDropdownDisabled(true);
      }
      else {
        this.filterItemIds = [...n.data.filterItemIds];
        const newModel: ListItemItem[] = [];
        this.model.values.forEach(value => {
          if (value.filterItemIds) {
            value.filterItemIds.forEach(filterItemId => {
              if (this.filterItemIds.find(v => v === filterItemId)) {
                newModel.push(value);
              }
            });
          }
        });
        this.model.values = newModel;
        this.setDropdownDisabled(false);
        this.onListItemSearch();
      }
    }
  }

  private setDropdownDisabled(disabled: boolean) {
    this.dropdownDisabled = disabled;
    this.initDropdown(this.formFieldWidthPercent, this.dropdownDisabled);
  }
}

export class Model {

  private _values: ListItemItem[] = [];
  private _previousValues: ListItemItem[] = [];

  get previousValues() {
    return this._previousValues;
  }

  get values() {
    return this._values;
  }

  set values(values: ListItemItem[]) {
    this._previousValues = Array.from(this._values);
    this._values = Array.from(values);
  }

  title: string = '';
  hint: string = '';
  placeholder: string = '';
  prevValueIds?: Set<number>;
  multiSelect: boolean;
  filterFieldId?: number;

  getValueIdSet(): Set<number> {
    const ret: number[] = [];
    if (this.values) {
      this.values.forEach((value) => {
        if (value.id !== null) {
          ret.push(value.id!);
        }
      });
    }
    return ret.length > 0 ? Set.of<number>(...ret) : Set.of<number>();
  }

  constructor(
    public readonly tmpReadonly: () => boolean,
    public readonly willBeAbleToApplyDefaultValue: () => boolean,
    public readonly defaultValue: () => FormRecordListMultiItemValue[]) {
  }

}

export class ListItemItem implements FormRecordListMultiItemValue {
  id: number | null = null;
  itemName: string;
  disabled: boolean = false;
  filterItemIds?: number[] = [];
}

class ValueReference implements FormRecordFieldValueReference<FormRecordListMultiItemValue[]> {

  constructor(private readonly model: Model, private readonly loadedRef: () => boolean) {
  }

  async get(): Promise<FormRecordListMultiItemValue[]> {
    await ofCondition({
      condition: () => {
        return this.loadedRef();
      }
    });
    if (this.model.tmpReadonly() && this.model.willBeAbleToApplyDefaultValue()) {
      return this.model.defaultValue();
    }
    return this.model.values;
  }

  set(values: FormRecordListMultiItemValue[]) {
    const val: ListItemItem[] = <ListItemItem[]>values;
    this.model.values = val;
  }

  previous(): FormRecordListMultiItemValue[] {
    return this.model.previousValues;
  }

}
