/* eslint-disable */
import { Identity } from './messages';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';

/* eslint-enable */

export class Arrays {

  public static clear<T>(array: T[]) {
    array.splice(0, array.length);
  }

  public static clearFormArray(formArray: FormArray) {
    while (formArray.length !== 0) {
      formArray.removeAt(0)
    }
  }

  public static forEachFormArray(formArray: FormArray, callback: (item: AbstractControl) => void) {
    for (let i = 0; i < formArray.length; i++) {
      const g: FormGroup = <FormGroup>formArray.at(i);
      callback(g);
    }
  }

  public static inArray<T>(array: T[], obj: T) {
    return array.indexOf(obj) !== -1;
  }

  public static equals<T>(a: T[], b: T[], mapper: (val: T) => string | null) {
    if (a.length !== b.length) {
      return false;
    }
    for (let i = 0; i < a.length; i++) {
      const ai: string | null = mapper(a[i]);
      const bi: string | null = mapper(b[i]);
      if (ai !== bi) {
        return false;
      }
    }
    return true;
  }

  public static iterateByIndex<T>(array: T[], callback: (item: T, index: number, hasNext: boolean) => boolean | void): void {
    for (let i = 0; i < array.length; i++) {
      const item = array[i];
      if (typeof item === 'undefined') {
        continue;
      }
      const hasNext = i + 1 < array.length;
      const res = callback(item, i, hasNext);
      if (typeof res !== 'undefined') {
        if (!res) {
          break;
        }
      }
    }
  }

  public static findById<T extends Identity>(array: T[], id?: number): T | null {
    if (typeof id === 'undefined') {
      return null;
    }
    if (id === null) {
      return null;
    }
    let result: T | null = null;
    Arrays.iterateByIndex(array, (item) => {
      if (id === item.id) {
        result = item;
        return false;
      }
    });
    return result;
  }

  public static clone<T>(array: T[]): T[] {
    const clone: T[] = [];
    array.forEach(i => clone.push(i));
    return clone;
  }

  public static cloneContent<T>(array: T[]): T[] {
    const clone: T[] = [];
    array.forEach(i => clone.push(Object.assign({}, i)));
    return clone;
  }

  public static flatten<T>(array: T[][]): T[] {
    return array.reduce((result, current) => result.concat(current), []);
  }

  private constructor() {
  }

}

