<div class="animated fadeIn">

  <div class="col-md-12 col-lg-6 col-xl-6 p-0">
    <div class="card">

      <div class="card-header">
        {{'CUSTOMER_RECORD_MAIN_CUSTOMER' | translate}}
      </div>

      <div class="card-body">
        <table class="table table-striped table-bordered">
          <thead>
          <tr>
            <th class="table-sorter-header">
              <app-table-field-sorter [orderField]="CustomerRecord.OrderField.ID"
                                      [orderType]="mainQueryModel.getOrderType(CustomerRecord.OrderField.ID)"
                                      (orderChange)="orderBy($event)"
                                      [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
            </th>
            <th class="table-sorter-header">
              <app-table-sorter-no-op
                [text]="'COMMON_NAME' | translate"></app-table-sorter-no-op>
            </th>
            <th class="table-sorter-header">
              <app-table-sorter-no-op
                [text]="'COMMON_ADDRESS' | translate"></app-table-sorter-no-op>
            </th>
            <th class="table-sorter-header w-table-options-menu">
              <app-table-sorter-no-op></app-table-sorter-no-op>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let mainCustomerRecord of mainCustomerRecords | paginate: {
                                                      id: mainPagingId,
                                                      itemsPerPage: mainQueryModel.itemsPerPage,
                                                      currentPage: mainQueryModel.currentPage,
                                                      totalItems: mainQueryModel.currentNumberOfItems }">
            <td class="fit">{{mainCustomerRecord.data.customerRecordId}}</td>
            <td class="responsive-table-column long">
              <a class="id-text cursor-pointer"
                 href="#" onclick="return false"
                 uiSref="Admin.CustomerRecordDetail"
                 [uiParams]="{customerId: mainCustomerRecord.data.customerId, customerRecordId: mainCustomerRecord.data.customerRecordId}"
                 title="{{'COMMON_BUTTON_DETAILS' | translate}}">
                <span>{{mainCustomerRecord.data.name}}</span>
              </a>
              <br>
              <span class="text-truncate table-text-subtitle">
              {{mainCustomerRecord.data.externalId}}
            </span>
            </td>
            <td class="responsive-table-column long">
              {{formatPostalAddress(mainCustomerRecord.data.postalAddress)}}
            </td>
            <td class="align-middle">
              <app-table-options-menu>
                <app-dropdown-item
                  [iconClass]="'icomoon-detail'"
                  [titleStringKey]="'COMMON_BUTTON_DETAILS'"
                  class="cursor-pointer"
                  uiSref="Admin.CustomerRecordDetail"
                  [uiParams]="{ customerId: mainCustomerRecord.data.customerId, customerRecordId: mainCustomerRecord.data.customerRecordId }">
                </app-dropdown-item>
                <app-dropdown-item
                  *ngIf="mainCustomerRecord.rights.update.hasRight()"
                  [iconClass]="'icomoon-modify'"
                  [titleStringKey]="'COMMON_BUTTON_EDIT'"
                  class="cursor-pointer"
                  uiSref="Admin.CustomerRecordEdit"
                  [uiParams]="{ customerId: mainCustomerRecord.data.customerId, customerRecordId: mainCustomerRecord.data.customerRecordId }">
                </app-dropdown-item>
              </app-table-options-menu>
            </td>
          </tr>
          </tbody>
          <tfoot>
          <tr>
            <th class="table-sorter-header">
              <app-table-field-sorter [orderField]="CustomerRecord.OrderField.ID"
                                      [orderType]="mainQueryModel.getOrderType(CustomerRecord.OrderField.ID)"
                                      (orderChange)="mainOrderBy($event)"
                                      [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
            </th>
            <th class="table-sorter-header">
              <app-table-sorter-no-op
                [text]="'COMMON_NAME' | translate"></app-table-sorter-no-op>
            </th>
            <th class="table-sorter-header">
              <app-table-sorter-no-op
                [text]="'COMMON_ADDRESS' | translate"></app-table-sorter-no-op>
            </th>
            <th class="table-sorter-header w-table-options-menu">
              <app-table-sorter-no-op></app-table-sorter-no-op>
            </th>
          </tr>
          </tfoot>
        </table>
        <app-table-paging
          [id]="mainPagingId"
          [currentNumberOfItems]="mainQueryModel.currentNumberOfItems"
          [totalNumberOfItems]="mainQueryModel.totalNumberOfItems"
          [itemsPerPage]="mainQueryModel.itemsPerPage"
          (pageChange)="mainPageChanged($event)"
          (itemsPerPageChange)="mainItemsPerPageChanged($event)">
        </app-table-paging>
      </div>

    </div>
  </div>

  <div class="container-vertical-padding-half"></div>

  <div class="card">

    <div class="card-header">
      {{'CUSTOMER_RECORD_LOCATIONS' | translate}}
    </div>

    <div class="card-body">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="NewCustomerRecordContact.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(NewCustomerRecordContact.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op
              [text]="'COMMON_NAME' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op
              [text]="'COMMON_ADDRESS' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op
              [text]="'CUSTOMER_RECORD_MAIN_CUSTOMER' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op
              [text]="'COMMON_LOCATION_OPENING_TIME_HTML_TITLE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op
              [text]="'COMMON_COMMENT' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let location of locationList | paginate: {
                                                      id: pagingId,
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }">
          <td class="fit">{{location.id}}</td>
          <td class="responsive-table-column long">
            <a class="cursor-pointer" href="#" onclick="return false" (click)="detailContactLocation.emit(location)">{{location.name}}</a>
            <br>
            <span class="text-truncate table-text-subtitle">
              {{location.externalId}}
            </span>
          </td>
          <td class="responsive-table-column long">
            {{formatPostalAddress(location.postalAddress)}}
          </td>
          <td class="responsive-table-column">
            {{location.customerRecordName}}
          </td>
          <td class="responsive-table-column hidden-sm-down">
            <div [popover]="openingTimesPopover" triggers="mouseenter:mouseleave" container="body">
              <div *ngFor="let line of getOpeningTimeLines(location.openingTimes)">
                <span>{{line}}</span>
              </div>
            </div>
            <ng-template #openingTimesPopover>
              <div [innerHTML]="location.openingTimes"></div>
            </ng-template>
          </td>
          <td class="responsive-table-column hidden-md-down">
            {{location.comment}}
          </td>
          <td class="align-middle">
            <app-table-options-menu>
              <app-dropdown-item
                [iconClass]="'icomoon-detail'"
                [titleStringKey]="'COMMON_BUTTON_DETAILS'"
                class="cursor-pointer"
                (click)="detailContactLocation.emit(location)">
              </app-dropdown-item>
            </app-table-options-menu>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="NewCustomerRecordContact.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(NewCustomerRecordContact.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op
              [text]="'COMMON_NAME' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op
              [text]="'COMMON_ADDRESS' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op
              [text]="'CUSTOMER_RECORD_MAIN_CUSTOMER' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op
              [text]="'COMMON_LOCATION_OPENING_TIME_HTML_TITLE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op
              [text]="'COMMON_COMMENT' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [id]="pagingId"
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>
  </div>
</div>

