/* eslint-disable */
import { Injectable } from '@angular/core';
import { MemStorage } from '../util/mem-storage';
import { Observable, Observer } from 'rxjs';
import { LoggedInUserStorage } from '../util/storages';
import { CalendarView } from 'angular-calendar';
import { CustomCalendarView } from '../../fork/angular-calendar/src';
import { TaskRecordStateItem } from '../../util/task-record-utils';
import { Calendar } from './calendar.service';
import { MultiselectOptionItem } from '../../util/core-utils';
import {TaskRecordStateMachine} from "../task/task-record-statemachine";

/* eslint-enable */

@Injectable()
export class CalendarSearchService {

  constructor() {
  }

  getSearchData(request: CalendarSearch.SearchDataGetRequest): Observable<CalendarSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<CalendarSearch.SearchDataResult>) => {
      try {
        observer.next(storage.read(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  setSearchData(request: CalendarSearch.SearchDataSetRequest): Observable<CalendarSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<CalendarSearch.SearchDataResult>) => {
      try {
        observer.next(storage.save(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  resetSearchData(request: CalendarSearch.SearchDataGetRequest): Observable<CalendarSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<CalendarSearch.SearchDataResult>) => {
      try {
        observer.next(storage.reset(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  private createStorage(): CalendarSearch.Storage {
    return new CalendarSearch.Storage();
  }

}

export namespace CalendarSearch {

  export interface SearchDataGetRequest {
  }

  export interface SearchDataSetRequest {
    searchData: SearchData;
  }

  export interface SearchDataResult {
    searchData: SearchData;
  }

  export interface SearchData {
    taskId?: number;
    userIds: MultiselectOptionItem<number>[];
    userGroupIds: MultiselectOptionItem<number>[];
    deviceIds: MultiselectOptionItem<number>[];
    taskStates: MultiselectOptionItem<TaskRecordStateMachine.State>[];
    assigneeFilters: Calendar.CalendarAssigneeType[];
    availableTime?: boolean;
    daysInWeek: number;
    view: CalendarView | CustomCalendarView;
  }

  interface StoredSearchRawDataBase {
    version: number;
  }

  interface StoredSearchRawData extends StoredSearchRawDataBase {
    taskId?: number;
    userIds: MultiselectOptionItem<number>[];
    userGroupIds: MultiselectOptionItem<number>[];
    deviceIds: MultiselectOptionItem<number>[];
    taskStates: MultiselectOptionItem<TaskRecordStateMachine.State>[];
    assigneeFilters: Calendar.CalendarAssigneeType[];
    availableTime?: boolean;
    daysInWeek: number;
    view: CalendarView | CustomCalendarView;
  }

  export class Storage {

    /**
     * Increment this version if you change the SearchData interface.
     */
    private static readonly VERSION: number = 5;

    private static readonly DEFAULT_SEARCH_DATA: SearchData = {
      taskId: undefined,
      userIds: [],
      userGroupIds: [],
      deviceIds: [],
      availableTime: undefined,
      daysInWeek: 7,
      taskStates: [],
      assigneeFilters: [],
      view: CalendarView.Week
    };

    constructor() {
    }

    public read(request: SearchDataGetRequest): SearchDataResult {
      let rawBase: StoredSearchRawDataBase;
      try {
        const key = this.generateKey();
        const json = MemStorage.getItem(key);
        if (!json) {
          return this.createDefaultResult();
        }
        rawBase = JSON.parse(json);
      }
      catch (error) {
        return this.createDefaultResult();
      }
      if (Storage.VERSION === rawBase.version) {
        const raw: StoredSearchRawData = <StoredSearchRawData>rawBase;
        return this.fromRaw(raw);
      }
      return this.createDefaultResult();
    }

    public reset(request: SearchDataGetRequest): SearchDataResult {
      return this.save({
        searchData: Storage.DEFAULT_SEARCH_DATA,
      });
    }

    public save(request: SearchDataSetRequest): SearchDataResult {
      const key = this.generateKey();
      const raw = this.toRaw(request.searchData);
      const json = JSON.stringify(raw);
      MemStorage.setItem(key, json);
      return this.read(request);
    }

    private generateKey(): string {
      return 'UserId(' + LoggedInUserStorage.getInstance().getUserId() + ')-Search-Calendar';
    }

    private createDefaultResult(): SearchDataResult {
      return {
        searchData: Storage.DEFAULT_SEARCH_DATA,
      };
    }

    private fromRaw(data: StoredSearchRawData): SearchDataResult {
      return {
        searchData: {
          taskId: data.taskId,
          userIds: data.userIds,
          userGroupIds: data.userGroupIds,
          deviceIds: data.deviceIds,
          availableTime: data.availableTime,
          daysInWeek: data.daysInWeek,
          view: data.view,
          taskStates: data.taskStates,
          assigneeFilters: data.assigneeFilters
        }
      };
    }

    private toRaw(data: SearchData): StoredSearchRawData {
      return {
        version: Storage.VERSION,
        taskId: data.taskId,
        userIds: data.userIds,
        userGroupIds: data.userGroupIds,
        deviceIds: data.deviceIds,
        availableTime: data.availableTime,
        daysInWeek: data.daysInWeek,
        view: data.view,
        taskStates: data.taskStates,
        assigneeFilters: data.assigneeFilters
      };
    }

  }

}
