/* eslint-disable */
import { List } from 'immutable';
import { OffsetDateTime } from '../../lib/util/dates';
import { OwnerUserItem } from '../core-utils';
/* eslint-enable */

export class DocumentFileType {

  public static readonly MEDIA = new DocumentFileType('FORM_ITEM_CREATE_DOCUMENT_FILE_TYPE_MEDIA', 'MEDIA');
  public static readonly DOCUMENT = new DocumentFileType('FORM_ITEM_CREATE_DOCUMENT_FILE_TYPE_DOCUMENT', 'DOCUMENT');
  public static readonly ANY = new DocumentFileType('FORM_ITEM_CREATE_DOCUMENT_FILE_TYPE_ANY', 'ANY');

  public static readonly DEFAULT = DocumentFileType.ANY;

  public static readonly VALUES: List<DocumentFileType> = List.of(
    DocumentFileType.MEDIA,
    DocumentFileType.DOCUMENT,
    DocumentFileType.ANY,
  );

  public static fromStringValue(value?: string): DocumentFileType {
    if (value) {
      const res = DocumentFileType.VALUES.toArray().filter((v) => {
        return v.value === value;
      });
      if (res.length < 1) {
        return DocumentFileType.ANY;
      }
      return res[0];
    }
    return DocumentFileType.ANY;
  }

  public static toStringValue(item: DocumentFileType): string {
    return item.value;
  }

  private constructor(
    public readonly textDictionaryKey: string,
    public readonly value: string) {
  }

}
