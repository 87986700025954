import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MultiselectOptionItem, QueryFieldModel, UiConstants, } from '../../../util/core-utils';
import { OrderType, QueryResult, ResourceQueryResult, Services } from '../../../lib/util/services';
import { StockRecord, StockRecordFacade, StockRecordService } from '../../../lib/stock/stock-record.service';
import { RightModel } from '../../../app.rights';
import { StockItemType, stockItemTypes } from '../../../lib/stock/stock-item.service';
import { Strings } from '../../../lib/util/strings';
import { Numbers } from '../../../lib/util/numbers';
import { Set } from 'immutable';
import { Models } from '../../../util/model-utils';
import { SearchUtils } from '../../../util/search-utils';
import { InputMask } from '../../../util/input-masks';
import { StockStockRecordSearch, StockStockRecordSearchService } from '../../../lib/stock/stock-stock-record-search-service';
import { StockItemTypeItem, StockItemUtils } from '../../../util/stock/stock-item-utils';
import { StockItemCategoryMultiselectProvider } from '../../../lib/stock-item-category/stock-item-category-multiselect.provider';
import { Angular2Multiselects } from '../../../util/multiselect';
import { StockItemCreateTypeSelectorDialogComponent } from '../../stock-item/stock-item-create-type-selector/stock-item-create-type-selector-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { StockRecordLotEditDialogComponent } from '../stock-record-lot-edit-dialog/stock-record-lot-edit-dialog.component';
import { Currency, CurrencyService } from '../../../lib/currency.service';

@Component({
  selector: 'app-stock-stock-record-list',
  templateUrl: './stock-stock-record-list.component.html',
  styleUrls: ['./stock-stock-record-list.component.scss']
})
export class StockStockRecordListComponent extends SearchUtils.SearchableList<StockStockRecordSearch.Model> implements OnInit, OnDestroy {
  StockRecord = StockRecord;
  UiConstants = UiConstants;
  InputMask = InputMask;
  StockItemUtils = StockItemUtils;
  pagingId = 'StockStockRecordList';

  @Input()
  stockId: number;

  @Input()
  rightModel: RightModel = RightModel.empty();

  @Input()
  readonly: boolean;

  @Output()
  onNewStockRecord: EventEmitter<StockItemType> = new EventEmitter<StockItemType>();

  @Output()
  onExport: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  onEdit: EventEmitter<number> = new EventEmitter<number>();

  currencies: Currency.Currency[] = [];

  searchModel: StockStockRecordSearch.Model = new StockStockRecordSearch.Model();

  stockRecordList: StockRecordFacade[] = [];
  queryModel: QueryFieldModel<StockRecord.OrderField> = new QueryFieldModel(StockRecord.OrderField.ID, OrderType.DESC);
  stockItemTypes: StockItemTypeItem[] = [];
  stockItemCategories: MultiselectOptionItem<number>[] = [];

  dropdownSettings: Angular2Multiselects.Settings;
  lotModeOn: boolean = true;

  constructor(
    private stockRecordService: StockRecordService,
    private stockStockRecordSearchService: StockStockRecordSearchService,
    private stockItemCategoryMultiselectProvider: StockItemCategoryMultiselectProvider,
    private dialog: MatDialog,
    private currencyService: CurrencyService,
    injector: Injector
  ) {
    super(StockStockRecordSearch.Model, injector);
    this.loadCurrencies();
  }

  ngOnInit() {
    this.initDropdownSettings();
    this.loadStockItemTypes(() => {
      this.initSearch();
    });
    this.loadStockItemCategories();
  }

  private initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .remoteSearch(true)
      .enableCheckAll(true)
      .build();
  }

  private loadStockItemTypes(completion: () => void) {
    this.stockItemTypes = [];
    stockItemTypes.forEach(t => {
      const item: StockItemTypeItem = {
        id: t.type,
        text: '....',
        iconRes: t.iconRes
      };
      this.stockItemTypes.push(item);
      this.translateService.get(t.stringKey).subscribe((text: string) => {
        item.text = text;
      });
    });
    completion();
  }

  loadStockItemCategories(q?: string) {
    this.stockItemCategoryMultiselectProvider.loadActive(q).subscribe(result => {
      this.stockItemCategories = result;
    });
  }

  onFirstSearchOpen(): void {
  }

  loadSearch(completion: () => void) {
    this.stockStockRecordSearchService.getSearchData({stockId: this.stockId})
      .subscribe(
        (result: StockStockRecordSearch.SearchDataResult) => {
          this.queryModel.itemsPerPage = result.searchData.itemsPerPage;
          this.queryModel.currentPage = result.searchData.pageNumber;
          this.queryModel.setOrder(result.searchData.order);
          this.searchModel.stockItemName = result.searchData.stockItemName;
          this.searchModel.stockItemExternalId = result.searchData.stockItemExternalId;
          this.searchModel.stockItemProductCode = result.searchData.stockItemProductCode;
          this.searchModel.stockItemSerialCode = result.searchData.stockItemSerialCode;
          this.searchModel.stockItemType = result.searchData.stockItemType;
          this.searchModel.stockItemCategories = result.searchData.stockItemCategories;
          this.searchModel.amountFrom = result.searchData.amountFrom;
          this.searchModel.amountTo = result.searchData.amountTo;
          this.searchModel.disabled = result.searchData.disabled;
          completion();
        }
      );
  }

  loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();
    this.stockRecordService.facadeQuery({
      fields: ['stock_record_id', 'stock_item', 'amount'].join(','),
      stock_ids: this.stockId.toString(),
      stock_item_name: Strings.undefinedOrNonEmpty(this.searchModel.stockItemName),
      stock_item_external_id: Strings.undefinedOrNonEmpty(this.searchModel.stockItemExternalId),
      stock_item_product_code: Strings.undefinedOrNonEmpty(this.searchModel.stockItemProductCode),
      stock_item_type: this.searchModel.stockItemType !== null ? this.searchModel.stockItemType : undefined,
      stock_item_serial_code: Strings.undefinedOrNonEmpty(this.searchModel.stockItemSerialCode),
      stock_item_category_ids: this.searchModel.stockItemCategories.length > 0
        ? this.searchModel.stockItemCategories.map(c => c.id).join()
        : undefined,
      amount_from: Numbers.undefinedOrNonEmpty(Models.parseNumber(this.searchModel.amountFrom)),
      amount_to: Numbers.undefinedOrNonEmpty(Models.parseNumber(this.searchModel.amountTo)),
      stock_item_disabled: false,
      include_reservations: true,
      include_lots: this.lotModeOn,
      page_number: requestedPage,
      number_of_items: this.queryModel.itemsPerPage,
      order: Services.createOrderFieldParameter(StockRecord.Keys.toOrderFieldKey, Set.of(order)),
    }).subscribe((result: ResourceQueryResult<StockRecordFacade>) => {
      this.stockRecordList = result.items;
      this.queryModel.currentPage = requestedPage;
      this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
    });
  }

  orderBy(field: StockRecord.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  onSearchClicked() {
    this.loadList(1);
  }

  onSearchReset() {
    this.stockStockRecordSearchService.resetSearchData({stockId: this.stockId}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.loadList(1);
        });
      }
    );
  }

  private saveSearch() {
    const request = {
      stockId: this.stockId,
      searchData: {
        itemsPerPage: this.queryModel.itemsPerPage,
        pageNumber: this.queryModel.currentPage,
        order: this.queryModel.getOrder(),
        stockItemName: this.searchModel.stockItemName,
        stockItemExternalId: this.searchModel.stockItemExternalId,
        stockItemProductCode: this.searchModel.stockItemProductCode,
        stockItemSerialCode: this.searchModel.stockItemSerialCode,
        stockItemType: this.searchModel.stockItemType,
        stockItemCategories: this.searchModel.stockItemCategories,
        amountFrom: this.searchModel.amountFrom,
        amountTo: this.searchModel.amountTo,
        disabled: this.searchModel.disabled
      }
    };
    this.stockStockRecordSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  getStockItemTypeText(type: StockItemType): string {
    const item = this.stockItemTypes.find(t => t.id === type);
    return item ? item.text : '';
  }

  getStockItemTypeIconRes(type: StockItemType): string {
    const item = this.stockItemTypes.find(t => t.id === type);
    return item ? item.iconRes : '';
  }

  onEditClicked(record: StockRecordFacade) {
    const dialogRef = this.dialog.open(StockRecordLotEditDialogComponent, {
      width: '60%',
      data: {stockRecord: record, stockRecordLot: record.lots![0]}
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.modified) {
        this.loadList();
        this.onEdit.emit(record.stock_record_id);
      }
    });
  }

  onLotEdited(recordId: number) {
    this.onEdit.emit(recordId);
    this.loadList();
  }

  openNewStockRecordTypeSelectorDialog() {
    const dialogRef = this.dialog.open(StockItemCreateTypeSelectorDialogComponent);
    dialogRef.afterClosed().subscribe(type => {
      if (type) {
        this.onNewStockRecordClicked(type);
      }
    });
  }

  toggleLotMode() {
    this.lotModeOn = !this.lotModeOn;
    this.loadList();
  }

  onNewStockRecordClicked(type: StockItemType) {
    this.onNewStockRecord.emit(type);
  }

  onExportClicked() {
    this.onExport.emit();
  }

  getCurrencyName(currencyCode: string) {
    const cc = this.currencies.find(c => c.currencyCode === currencyCode);
    return cc ? cc.localizedName : currencyCode;
  }

  getCurrency(currencyCode: string) {
    const cc = this.currencies.find(c => c.currencyCode === currencyCode);
    return cc!;
  }

  private loadCurrencies() {
    this.currencies = [];
    this.currencyService.query({}).subscribe((result: QueryResult<Currency.Currency>) => {
      result.items.forEach(c => {
        if (c) {
          this.currencies.push(c);
        }
      });
    });
  }

  ngOnDestroy() {
    this.saveSearch();
  }

}
