import { Injectable } from '@angular/core';
import { MultiselectProvider } from '../../multiselect-provider';
import { Observable, Observer } from 'rxjs';
import { MultiselectOptionItem, UiConstants } from '../../../util/core-utils';
import { OrderType, QueryResult } from '../../util/services';
import { Set } from 'immutable';
import { Query } from '../../query/field';
import { InvoiceSettings, InvoiceSettingsService } from './invoice-settings.service';
import { Task } from '../../task/task.service';
import { TaskMultiselectOptionItem } from '../../task/task-multiselect.provider';
import { Invoice } from '../invoice/invoice.service';

@Injectable()
export class InvoiceSettingsMultiselectProvider implements MultiselectProvider {

  private multiselectQueryRequest: InvoiceSettings.QueryRequest = {
    orders: Set.of({type: OrderType.ASC, field: InvoiceSettings.OrderField.PROFILE_NAME}),
    paging: {
      pageNumber: 1,
      numberOfItems: UiConstants.autocompletePageSize,
    },
    noProgressBar: true,
  };

  constructor(private invoiceSettingsService: InvoiceSettingsService) {
  }

  load(queryRequest: InvoiceSettings.QueryRequest): Observable<InvoiceSettingsMultiselectOptionItem[]> {
    return Observable.create((observer: Observer<InvoiceSettingsMultiselectOptionItem[]>) => {
      this.invoiceSettingsService.query(queryRequest).subscribe((result: QueryResult<InvoiceSettings.InvoiceSettings>) => {
          observer.next(result.items.map(item => this.toMultiselectOptionItem(item!)).toArray());
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  loadWithPartialRequest(request: Partial<InvoiceSettings.QueryRequest>): Observable<InvoiceSettingsMultiselectOptionItem[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    for (const requestKey in request) {
      if (request.hasOwnProperty(requestKey) && request[requestKey] !== undefined) {
        queryRequest[requestKey] = request[requestKey];
      }
    }
    return this.load(queryRequest);
  }

  loadAll(predicate?: string): Observable<InvoiceSettingsMultiselectOptionItem[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.profileName = predicate;
    return this.load(queryRequest);
  }

  loadActive(predicate?: string): Observable<InvoiceSettingsMultiselectOptionItem[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.disabled = false;
    queryRequest.profileName = predicate;
    return this.load(queryRequest);
  }

  getById(id: number): Observable<InvoiceSettingsMultiselectOptionItem> {
    return Observable.create((observer: Observer<InvoiceSettingsMultiselectOptionItem>) => {
      this.invoiceSettingsService.get({
        id: id
      }).subscribe((result: InvoiceSettings.InvoiceSettings) => {
          observer.next(this.toMultiselectOptionItem(result!));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  getByIds(ids: number[]): Observable<InvoiceSettingsMultiselectOptionItem[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    const filters: Query.Criteria[] = [];
    queryRequest.id = Set.of(...ids);
    return this.load(queryRequest);
  }

  toMultiselectOptionItem(item: InvoiceSettings.InvoiceSettings): InvoiceSettingsMultiselectOptionItem {
    return InvoiceSettingsMultiselectProvider.toPublic(item);
  }

  static toPublic(item: InvoiceSettings.InvoiceSettings): InvoiceSettingsMultiselectOptionItem {
    const ret = new InvoiceSettingsMultiselectOptionItem();
    ret.id = item.id;
    ret.itemName = item.profileName;
    ret.itemSubtitle = item.companyName;
    ret.disabled = item.disabled;
    ret.integrationType = item.invoiceIntegrationType;
    ret.defaultAppearanceType = item.defaultInvoiceAppearanceType;
    ret.defaultPaymentType = item.defaultPaymentType;
    ret.commentTemplate = item.commentTemplate;
    ret.summaryRoundingEnabled = item.summaryRoundingEnabled;
    ret.recordRoundingEnabled = item.recordRoundingEnabled;
    ret.zeroPricedRecordsAllowed = item.zeroPricedRecordsAllowed;
    return ret;
  }
}

export class InvoiceSettingsMultiselectOptionItem extends MultiselectOptionItem<number> {
  integrationType: InvoiceSettings.InvoiceIntegrationType;
  defaultAppearanceType: InvoiceSettings.InvoiceAppearanceType;
  defaultPaymentType?: Invoice.InvoicePaymentType;
  commentTemplate?: string;
  summaryRoundingEnabled: boolean = false;
  recordRoundingEnabled: boolean = false;
  zeroPricedRecordsAllowed: boolean = true;
}
