/* eslint-disable */
import { Component, OnInit, ViewChild } from '@angular/core';
import { LegacyProcess, LegacyProcessService } from '../../../lib/legacy-process/legacy-process.service';
import { Transition, UIRouter } from '@uirouter/angular';
import { OrderType, QueryResult, ResourceQueryResult } from '../../../lib/util/services';
import { QueryFieldModel } from '../../../util/core-utils';
import { Order, OrderService } from '../../../lib/order/order.service';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { LegacyProcessTask, LegacyProcessTaskService } from '../../../lib/legacy-process/legacy-process-task.service';
import { User, UserService } from '../../../lib/user.service';
import { TranslateService } from '@ngx-translate/core';
import { StateName } from '../../../app.state-names';
import { Set } from 'immutable';
import { OffsetDateTime } from '../../../lib/util/dates';
import { StringKey } from '../../../app.string-keys';
import { combineLatest, Observable } from 'rxjs';
import { AssigneeItem } from '../../../util/task-record-utils';
import { Company, CompanyService } from '../../../lib/company/company.service';
import { RightResolver, RightService } from '../../../lib/right.service';
import { RightModel } from '../../../app.rights';
import { LegacyProcessMplExportDialogComponent } from '../legacy-process-mpl-export-dialog/legacy-process-mpl-export-dialog.component';

/* eslint-enable */

@Component({
  selector: 'app-legacy-process-detail',
  templateUrl: './legacy-process-detail.component.html',
  styleUrls: ['./legacy-process-detail.component.scss']
})
export class LegacyProcessDetailComponent implements OnInit {

  processId: number;
  model: LegacyProcess.LegacyProcess;
  baseDataModel: LegacyProcessDetailModel = new LegacyProcessDetailModel();
  processStates: LegacyProcessStateItem[] = [];
  processTaskStates: LegacyProcessTaskStateItem[] = [];
  users: AssigneeItem[] = [];
  orderQueryModel: QueryFieldModel<Order.OrderField> = new QueryFieldModel(Order.OrderField.ID, OrderType.DESC);
  orderList: Order.Order[] = [];
  companyIds: number[] = [];
  companyList: CompanyItem[] = [];
  processTasks: LegacyProcessTask.LegacyProcessTask[] = [];
  openTask?: string;
  rightModel: RightModel = RightModel.empty();

  @ViewChild('mplExportDialog', { static: true })
  mplExportDialog: LegacyProcessMplExportDialogComponent;
  mplStateImportPath = '';

  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  constructor(
    private processService: LegacyProcessService,
    private orderService: OrderService,
    private companyService: CompanyService,
    private transition: Transition,
    private userService: UserService,
    private translateService: TranslateService,
    private uiRouter: UIRouter,
    private processTaskService: LegacyProcessTaskService,
    private rightService: RightService
  ) {
    this.processId = this.transition.params().id;
    this.mplStateImportPath = LegacyProcess.MPL_STATE_IMPORT_PATH.replace('{process_id}', this.processId.toString());
  }

  ngOnInit() {
    this.translateService.get('MENU_NAVBAR_LEGACY_PROCESSES').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.LEGACY_PROCESS_LIST});
      }
    );
    this.loadRightModels();
    this.loadModel();
    this.loadProcessStates();
    this.loadProcessTaskStates();
    this.loadOrders();
    this.loadProcessTasksWithUserNames();
  }

  private loadModel() {
    this.processService.get({
      id: this.processId
    }).subscribe(
      (process: LegacyProcess.LegacyProcess) => {
        this.breadcrumbSelf = process.displayName ? process.displayName : process.processId.toString();
        this.model = process;
        this.baseDataModel.name = process.displayName;
        this.baseDataModel.externalId = process.externalId;
        this.baseDataModel.description = process.displayDescription;
        this.baseDataModel.deadline = process.deadline;
      }
    );
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  private loadProcessTasksWithUserNames() {
    this.createCombinedObservable().subscribe(
      (result: CombinedResult) => {
        this.users = [];
        result.users.items.forEach((user) => {
          const item = {id: user.id, itemName: user.person_name + ' (' + user.user_name + ')'};
          this.users.push(item);
        });
        this.processTasks = [];
        this.processTasks = result.processTasks.items.toArray();
        this.openTask = this.loadOpenProcessTask();
      },
      () => {
      },
      () => {
      }
    );
  }

  private createCombinedObservable(): Observable<CombinedResult> {
    const fields: string[] = ['id', 'person_name', 'user_name'];
    return combineLatest(
      this.processTaskService.query({
        processId: this.processId
      }),
      this.userService.query({
        fields: fields.join(',')
      }),
      (
        processTasks: QueryResult<LegacyProcessTask.LegacyProcessTask>,
        users: ResourceQueryResult<User>) => {
        return {
          users: users,
          processTasks: processTasks
        };
      }
    );
  }

  private loadOpenProcessTask(): string | undefined {
    this.processTasks.forEach((task) => {
      if (task.state === 'IN_PROGRESS') {
        return task.displayName;
      }
    });
    return undefined;
  }

  private loadProcessStates() {
    this.processStates = [];
    LegacyProcess.processStates.forEach((state) => {
      const item = {
        id: state.state,
        stringKey: '...',
        icon: state.icon
      };
      this.processStates.push(item);
      this.translateService.get(state.stringKey).subscribe((text: string) => {
        item.stringKey = text;
      });
    });
  }

  private loadProcessTaskStates() {
    this.processTaskStates = [];
    LegacyProcessTask.processTaskStates.forEach((state) => {
      const item = {
        id: state.state,
        stringKey: '...'
      };
      this.processTaskStates.push(item);
      this.translateService.get(state.stringKey).subscribe((text: string) => {
        item.stringKey = text;
      });
    });
  }

  private loadOrders(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.orderQueryModel.currentPage;
    const order = this.orderQueryModel.getOrder();
    this.orderService.query({
      processId: this.processId,
      orders: Set.of(order),
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.orderQueryModel.itemsPerPage
      } : undefined
    }).subscribe(
      (result: QueryResult<Order.Order>) => {
        this.orderList = result.items.toArray();
        this.companyIds = [];
        this.orderList.forEach((item) => {
          this.companyIds.push(item.ownerCompanyId);
        });
        this.loadCompanys();
        this.orderQueryModel.currentPage = requestedPage;
        this.orderQueryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
        this.orderQueryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
      });
  }

  private loadCompanys() {
    this.companyService.query({
      id: Set.of(...this.companyIds)
    }).subscribe((result: QueryResult<Company.Company>) => {
      this.companyList = [];
      result.items.forEach((cr) => {
        if (cr) {
          this.companyList.push({
            id: cr.id,
            name: cr.name
          });
        }
      });
    });
  }

  onImportSuccess(succeeded: boolean) {
    if (succeeded) {
      this.loadOrders(1);
    }
  }

  orderPageChanged(selectedPage: number) {
    this.loadOrders(selectedPage);
  }

  orderItemsPerPageChanged(itemsPerPage: number) {
    this.orderQueryModel.itemsPerPage = itemsPerPage;
    this.loadOrders(1);
  }

  getProcessStateName(state: string): string {
    const filtered_states = this.processStates.filter(current_state => current_state.id === state);
    if (filtered_states === undefined || filtered_states.length === 0) {
      return '';
    }
    return filtered_states[0].stringKey;
  }

  getProcessTaskStateName(state: string): string {
    const filtered_states = this.processTaskStates.filter(current_state => current_state.id === state);
    if (filtered_states === undefined || filtered_states.length === 0) {
      return '';
    }
    return filtered_states[0].stringKey;
  }

  private getCompanyName(id: number): string {
    const filtered_records = this.companyList.filter(current_record => current_record.id === id);
    if (filtered_records === undefined || filtered_records.length === 0) {
      return '';
    }
    return filtered_records[0].name;
  }

  getProcessStateIcon(state: string): string {
    const filtered_states = this.processStates.filter(current_state => current_state.id === state);
    if (filtered_states === undefined || filtered_states.length === 0) {
      return '';
    }
    return filtered_states[0].icon;
  }

  getProcessTaskStateColor(state: string) {
    switch (state) {
      case 'TO_DO':
        return {
          'border': '1px solid #9b9b9b',
          'color': '#9b9b9b'
        };
      case 'OPEN':
        return {
          'border': '1px solid #00727b',
          'color': '#00727b'
        };
      case 'IN_PROGRESS':
        return {
          'border': '1px solid #01aee8',
          'color': '#01aee8'
        };
      case 'PENDING_APPROVAL':
        return {
          'border': '1px solid #f0ad4e',
          'color': '#f0ad4e'
        };
      case 'FINISHED':
        return {
          'border': '1px solid #8cc152',
          'color': '#8cc152'
        };
      case 'FAILED':
        return {
          'border': '1px solid #d0021b',
          'color': '#d0021b'
        };
      default:
        return {
          'border': '1px solid gray',
          'color': 'gray'
        };
    }
  }

  getUserName(id: number): string {
    const filtered_users = this.users.filter(current_user => current_user.id === id);
    if (filtered_users === undefined || filtered_users.length === 0) {
      return this.translateService.instant(StringKey.COMMON_PLACEHOLDER_ASSIGNEE_NOT_SELECTED_YET);
    }
    return filtered_users[0].itemName;
  }
}

class LegacyProcessDetailModel {
  name?: string = undefined;
  externalId: string = '';
  description?: string = undefined;
  deadline?: OffsetDateTime = undefined;
}

interface LegacyProcessStateItem {
  id: string;
  stringKey: string;
  icon: string;
}

interface LegacyProcessTaskStateItem {
  id: string;
  stringKey: string;
}

interface UserItem {
  id: number;
  name: string;
}

interface CompanyItem {
  id: number;
  name: string;
}

interface CombinedResult {
  users: ResourceQueryResult<User>;
  processTasks: QueryResult<LegacyProcessTask.LegacyProcessTask>;
}
