/* eslint-disable */
import {DataTypeObject, ImmutableOptionItem, MultiselectOptionItem, OptionItem,} from './core-utils';
import {Icon} from '../lib/task/icon.service';
import {TaskResource} from '../lib/task/task-resource.service';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {Injectable} from '@angular/core';
import {List, Map, Set} from 'immutable';
import {Invoice} from '../lib/invoice/invoice/invoice.service';
import {InvoicePaymentTypeMultiItem} from '../admin/invoicing/invoices/invoice-create/invoice-create-clone.model';
import {FieldError} from '../lib/util/errors';
import {DisabledItem, SearchBooleanItem} from './search-utils';
import {Task} from '../lib/task/task.service';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {InvoiceSettings} from '../lib/invoice/invoice-settings/invoice-settings.service';
import {ConfigurationService} from '../lib/core-ext/configuration.service';
import {TaskRecord} from '../lib/task/task-record.service';
import {TaskRecordStateMachine} from '../lib/task/task-record-statemachine';
import {GrantedPermissionSet, GrantedPermissionSetResolver} from '../lib/right.service';
import {OperationRights} from '../app.right-definitions';
import TaskValidationType = TaskResource.TaskValidationType;
import DayType = TaskResource.DayType;
import InvoiceIntegrationType = InvoiceSettings.InvoiceIntegrationType;
import PlaceOfConsumptionRequirement = Task.PlaceOfConsumptionRequirement;
import TaskSignatureValidationType = TaskResource.TaskSignatureValidationType;
import TaskDefaultAssigneeType = Task.TaskDefaultAssigneeType;
import TaskManualTimeTrackingType = TaskResource.TaskManualTimeTrackingType;

/* eslint-enable */

export class TaskEditModel {

  name: string = '';
  externalId: string = '';
  description: string = '';
  validationType: ValidationTypeItem[] = [];
  _customerSignature: MultiselectOptionItem<TaskResource.TaskSignatureValidationType>[] = [taskSignatureValidationTypes[0]];
  _userSignature: MultiselectOptionItem<TaskResource.TaskSignatureValidationType>[] = [taskSignatureValidationTypes[0]];
  pictureUpload: boolean = true;
  photoConfirmDialogEnabled: boolean = false;
  photoEnabledInStates: MultiselectOptionItem<string>[] = droidStates.filter(s => s.id === 'IN_PROGRESS');
  minNumOfPics: string = '';
  maxNumOfPics: string = '';
  customerAttachmentEnabled: boolean = true;
  parallelWorkRejected: boolean = false;
  icon?: Icon.Icon = {
    iconType: 'ICON_SET',
    iconSet: {
      setCode: 'google_material',
      setClass: 'material-icons',
      iconCode: 'assignment_turned_in',
      usageType: 'TEXT'
    }
  };
  taskTimeTrackingType: TaskTimeTrackingType = TaskTimeTrackingType.NONE;
  manualTimeTrackingRequired: boolean = false;
  manualTimeTrackingType: TaskResource.TaskManualTimeTrackingType = TaskManualTimeTrackingType.WEEK;
  collaboratorTimeTrackingEnabled: boolean = false;
  showPicturesOnForm: boolean = true;
  defaultEstimatedTimeInMinutes: string = '';
  mileageRecordEnabled: boolean = false;
  mileageRecordRequired: boolean = false;
  geofencingEnabled: boolean = false;
  geofencingRadiusInMeters: number = 0;
  pdfTemplateId?: number;
  taskInvoiceGenerationType: TaskInvoiceGenerationType = TaskInvoiceGenerationType.NONE;
  invoiceSettings: TaskInvoiceSettingsItem[] = [];
  invoiceBook: MultiselectOptionItem<number>[] = [];
  invoiceTag: MultiselectOptionItem<number>[] = [];
  printInvoiceCount: string = '';
  defaultLinkedSurveys: MultiselectOptionItem<number>[] = [];
  creatorUserGroups: MultiselectOptionItem<number>[] = [];
  enabledUserGroups: MultiselectOptionItem<number>[] = [];
  _validInvoicePaymentTypes: InvoicePaymentTypeMultiItem[] = [];
  _generatingInvoicePaymentTypes: InvoicePaymentTypeMultiItem[] = [];
  hasDefaultDeadline: boolean = false;
  defaultDeadlineType: DefaultDeadlineTypeItem;
  offset: number;
  taskRecordExternalIdSequence: string = '';
  withinYear: boolean = false;
  taskRecordNameTemplate: string = '';
  useDefaultManagedFields: boolean = true;
  managedFields: MultiselectOptionItem<TaskRecord.TaskRecordField>[] = [];
  helpdeskManagedFields: MultiselectOptionItem<TaskRecord.TaskRecordField>[] = [];
  requiredFields: MultiselectOptionItem<TaskRecord.TaskRecordField>[] = [];
  helpdeskRequiredFields: MultiselectOptionItem<TaskRecord.TaskRecordField>[] = [];
  defaultState: MultiselectOptionItem<TaskRecordStateMachine.State>[] = [];
  defaultStateForceNew: boolean = false;
  explicitOrderNumber: string = '';
  showOnDashboard: boolean = true;
  _pocRequirement: DataTypeObject<Task.PlaceOfConsumptionRequirement>[] = [];
  _webCreateDefaultAssigneeType: DataTypeObject<Task.TaskDefaultAssigneeType>[] = [];
  helpdeskEnabled: boolean = false;
  setCustomerOwnerUserAfterFinish: boolean = false;
  setCustomerOwnerUserGroupAfterFinish: boolean = false;
  submittedFilters: SubmittedFilterModel[] = [];
  smsRatingEnabled: boolean = false;
  inProgressSignatureEnabled: boolean = false;
  smsRatingDeliveryScheduleMin: string = '';
  smsRatingTemplate: MultiselectOptionItem<number>[] = [];
  stateFilters: StateFilterModel[] = [];
  adminEditableStates: MultiselectOptionItem<TaskRecordStateMachine.State>[] = [];
  color: number = 0;

  readonly paymentTypes: InvoicePaymentTypeMultiItem[] = [];

  constructor(validationTypeItem: ValidationTypeItem,
              defaultDeadlineTypeItem: DefaultDeadlineTypeItem,
              private translateService: TranslateService,
              private configService: ConfigurationService) {
    this.validationType = [validationTypeItem];
    this.defaultDeadlineType = defaultDeadlineTypeItem;
    this.geofencingRadiusInMeters = this.configService.getConfiguration().feature_flags.geofencing_default_radius_in_meters;
    this.initPaymentTypes();
  }

  get validationTypeItem(): ValidationTypeItem | undefined {
    return this.validationType.length > 0 ? this.validationType[0] : undefined;
  }

  get validInvoicePaymentTypes(): Set<Invoice.InvoicePaymentType> {
    return Set.of(...this._validInvoicePaymentTypes.map(item => item.id));
  }

  set validInvoicePaymentTypes(types: Set<Invoice.InvoicePaymentType>) {
    this._validInvoicePaymentTypes = [];
    types.forEach(t => {
      const temp = this.paymentTypes.find(pt => pt.id === t);
      if (temp) {
        this._validInvoicePaymentTypes.push(temp);
      }
    })
  }

  get generatingInvoicePaymentTypes(): Set<Invoice.InvoicePaymentType> {
    return Set.of(...this._generatingInvoicePaymentTypes.map(item => item.id));
  }

  set generatingInvoicePaymentTypes(types: Set<Invoice.InvoicePaymentType>) {
    this._generatingInvoicePaymentTypes = [];
    types.forEach(t => {
      const temp = this.paymentTypes.find(pt => pt.id === t);
      if (temp) {
        this._generatingInvoicePaymentTypes.push(temp);
      }
    })
  }

  refreshGeneratingPaymentTypes(): void {
    this._generatingInvoicePaymentTypes = this._generatingInvoicePaymentTypes
      .filter(i => this._validInvoicePaymentTypes.includes(i));
  }

  get invoiceSettingsId(): number | undefined {
    return this.invoiceSettings.length !== 0
      ? this.invoiceSettings[0].id
      : undefined;
  }

  get invoiceBookId(): number | undefined {
    return this.invoiceBook.length !== 0
      ? this.invoiceBook[0].id
      : undefined;
  }

  get invoiceTagId(): number | undefined {
    return this.invoiceTag.length !== 0
      ? this.invoiceTag[0].id
      : undefined;
  }

  private initPaymentTypes() {
    InvoicePaymentTypeMultiItem.loadItems()
      .subscribe((paymentTypes) => this.paymentTypes.push(...paymentTypes));
  }

  get defaultLinkedSurveyIds(): Set<number> {
    return Set.of(...this.defaultLinkedSurveys.map(s => s.id));
  }

  get creatorUserGroupIds(): Set<number> {
    return Set.of(...this.creatorUserGroups.map(s => s.id));
  }

  get enabledUserGroupIds(): Set<number> {
    return Set.of(...this.enabledUserGroups.map(s => s.id));
  }

  getDefaultState(): TaskRecordStateMachine.State {
    return this.defaultStateForceNew ? 'NEW' : this.defaultState[0].id;
  }

  get pocRequirement(): Task.PlaceOfConsumptionRequirement | undefined {
    return this._pocRequirement.length === 1 ? this._pocRequirement[0].id : undefined;
  }

  get webCreateDefaultAssigneeType(): Task.TaskDefaultAssigneeType | undefined {
    return this._webCreateDefaultAssigneeType.length === 1 ? this._webCreateDefaultAssigneeType[0].id : undefined;
  }

  get smsRatingTemplateId(): number | undefined {
    return this.smsRatingTemplate.length === 1 ? this.smsRatingTemplate[0].id : undefined;
  }

  get customerSignature(): TaskResource.TaskSignatureValidationType {
    return this._customerSignature[0] ? this._customerSignature[0].id : 'MOBILE_REQUIRED_ADMIN_CLOSABLE';
  }

  get userSignature(): TaskResource.TaskSignatureValidationType {
    return this._userSignature[0] ? this._userSignature[0].id : 'MOBILE_REQUIRED_ADMIN_CLOSABLE';
  }

  get photoEnabledInStatesSet(): Set<string> {
    return this.pictureUpload ? Set.of(...this.photoEnabledInStates.map(s => s.id)) : Set.of();
  }

  set photoEnabledInStatesSet(value: Set<string>) {
    this.photoEnabledInStates = droidStates.filter(s => value.contains(s.id));
  }
}

export class TaskSearchModel {
  id: string;
  name: string;
  disabled?: DisabledItem;
  showOnDashboard: SearchBooleanItem = SearchBooleanItem.ALL;
  helpdeskEnabled: SearchBooleanItem = SearchBooleanItem.ALL;

  public isEmpty(): boolean {
    return this.id.length === 0
      && this.name.length === 0
      && OptionItem.idOrUndefined(this.disabled) === undefined
      && this.showOnDashboard === SearchBooleanItem.ALL
      && this.helpdeskEnabled === SearchBooleanItem.ALL
      ;
  }
}

export interface TaskInvoiceSettingsItem extends MultiselectOptionItem<number> {
  integrationType: InvoiceIntegrationType;
}

export class TaskCreateModel extends TaskEditModel {

  constructor(validationTypeItem: ValidationTypeItem,
              defaultDeadlineType: DefaultDeadlineTypeItem,
              translateService: TranslateService,
              configService: ConfigurationService) {
    super(validationTypeItem, defaultDeadlineType, translateService, configService);
    this._pocRequirement.push(Task.placeOfConsumptionRequirements.find(r => r.id === PlaceOfConsumptionRequirement.NOT_REQUIRED)!);
    this._webCreateDefaultAssigneeType.push(Task.taskDefaultAssigneeTypes.find(r => r.id === TaskDefaultAssigneeType.NONE)!);
  }

}

export class SubmittedFilterModel {
  userGroup: MultiselectOptionItem<number>[] = [];
  days: string = '';

  get userGroupId(): number | undefined {
    return this.userGroup.length === 1 ? this.userGroup[0].id : undefined;
  }
}

export class StateFilterModel {
  userGroup: MultiselectOptionItem<number>[] = [];
  states: MultiselectOptionItem<TaskRecordStateMachine.State>[]
    = TaskRecordStateMachine.getOrderedStates(true).toArray()
    .filter(s => ['OPEN', 'IN_PROGRESS', 'PAUSED', 'PENDING_APPROVAL'].includes(s.state))
    .map(s => ({id: s.state, itemName: s.stringKey}));

  get userGroupId(): number | undefined {
    return this.userGroup.length === 1 ? this.userGroup[0].id : undefined;
  }

  get rawStates(): Set<TaskRecordStateMachine.State> {
    return Set.of(...this.states.map(s => s.id));
  }
}

export class TaskDisabledItem extends OptionItem<TaskDisabled> {
}

export class TaskDisabledItems {
  public static readonly values: TaskDisabled[] = ['ALL', 'ACTIVE', 'INACTIVE'];
}

export type TaskDisabled =
  'ALL' |
  'ACTIVE' |
  'INACTIVE';

export class TaskItem extends OptionItem<number> {
  disabled: boolean = false;
  icon: string = '';
}

export enum TaskInvoiceGenerationType {
  NONE = ('NONE'),
  AUTOMATIC = ('AUTOMATIC'),
  MANUAL = ('MANUAL')
}

export const taskInvoiceGenerationTypes: TaskInvoiceGenerationType[] = [
  TaskInvoiceGenerationType.NONE,
  TaskInvoiceGenerationType.AUTOMATIC,
  TaskInvoiceGenerationType.MANUAL
];

export enum TaskTimeTrackingType {
  NONE = ('NONE'),
  AUTOMATIC = ('AUTOMATIC'),
  MANUAL = ('MANUAL')
}

export const taskTimeTrackingTypes: TaskTimeTrackingType[] = [
  TaskTimeTrackingType.NONE,
  TaskTimeTrackingType.AUTOMATIC,
  TaskTimeTrackingType.MANUAL,
];

export const manualTimeTrackingTypes: TaskResource.TaskManualTimeTrackingType[] = [
  TaskResource.TaskManualTimeTrackingType.WEEK,
  TaskResource.TaskManualTimeTrackingType.SIMPLE,
];


export const taskSignatureValidationTypes: MultiselectOptionItem<TaskSignatureValidationType>[] = [
  {id: 'MOBILE_REQUIRED_ADMIN_CLOSABLE', itemName: 'TASK_SIGNATURE_VALIDATION_MOBILE_REQUIRED_ADMIN_CLOSABLE'},
  {id: 'MOBILE_REQUIRED_ADMIN_NOT_CLOSABLE', itemName: 'TASK_SIGNATURE_VALIDATION_MOBILE_REQUIRED_ADMIN_NOT_CLOSABLE'},
  {id: 'MOBILE_REQUIRED_ADMIN_REQUIRED', itemName: 'TASK_SIGNATURE_VALIDATION_MOBILE_REQUIRED_ADMIN_REQUIRED'},
  {id: 'MOBILE_CLOSABLE_ADMIN_REQUIRED', itemName: 'TASK_SIGNATURE_VALIDATION_MOBILE_CLOSABLE_ADMIN_REQUIRED'},
  {id: 'OFF', itemName: 'TASK_SIGNATURE_VALIDATION_OFF'}
]

export const droidStates: MultiselectOptionItem<string>[] = [
  {id: 'OPEN', itemName: 'COMMON_VALUE_TASK_STATE_OPEN'},
  {id: 'IN_PROGRESS', itemName: 'COMMON_VALUE_TASK_STATE_IN_PROGRESS'},
  {id: 'DONE', itemName: 'COMMON_VALUE_TASK_STATE_DONE'}
]

export namespace TaskRecordExternalIdSequence {

  export const YEAR: string = '{{year}}';
  export const YEAR_SHORT: string = '{{year:short}}';
  export const COUNTER: string = '{{counter}}';
  export const COUNTER_06: string = '{{counter:06}}';

  export const sequenceBlockSeparator = '/';
  export const separatorKeysCodes: number[] = [ENTER, COMMA];

  export const availableSequenceBlocks: string[] = [
    YEAR,
    YEAR_SHORT,
    COUNTER,
    COUNTER_06
  ];

  export const sequenceBlockExampleMap: Map<string, string> = Map.of(
    YEAR, new Date().getFullYear().toString(),
    YEAR_SHORT, (new Date().getFullYear() % 100).toString(),
    COUNTER, '1',
    COUNTER_06, '000001'
  );

}

export namespace TaskRecordQuickCreateNameTemplate {
  export const YEAR: string = '{{year}}';
  export const YEAR_SHORT: string = '{{year:short}}';
  export const COUNTER: string = '{{counter}}';
  export const TASK_NAME: string = '{{TaskName}}';
  export const POC_ADDRESS: string = '{{PocAddress}}';
  export const FINALIZATION_USER_NAME: string = '{{FinalizationUserName}}';
  export const FINALIZATION_USER_PERSON_NAME: string = '{{FinalizationUserPersonName}}';
  export const ASSIGNEE_USER_NAME: string = '{{AssigneeUserName}}';
  export const ASSIGNEE_USER_PERSON_NAME: string = '{{AssigneeUserPersonName}}';
  export const ASSIGNEE_USER_GROUP_NAME: string = '{{AssigneeUserGroupName}}';
  export const CREATOR_USER_NAME: string = '{{CreatorUserName}}';
  export const CREATOR_USER_PERSON_NAME: string = '{{CreatorUserPersonName}}';
  export const ACTUAL_DATE: string = '{{ActualDate}}';
  export const AGREED_TIME: string = '{{AgreedTime}}';
  export const CUSTOMER_RECORD_NAME: string = '{{CustomerRecordName}}';
  export const CUSTOMER_NAME: string = '{{CustomerName}}';
  export const CUSTOMER_RECORD_EXTERNAL_ID: string = '{{CustomerRecordExternalId}}';
  export const CUSTOMER_RECORD_PHONE_NUMBERS: string = '{{CustomerRecordPhoneNumbers}}';
  export const CUSTOMER_EXTERNAL_ID: string = '{{CustomerExternalId}}';
  export const TASK_RECORD_ID: string = '{{TaskRecordId}}';
  export const PROJECT_RECORD_NAME: string = '{{ProjectRecordName}}';
  export const PROJECT_RECORD_EXTERNAL_ID: string = '{{ProjectRecordExternalId}}';

  export const templateBlockSeparator = '/';
  export const separatorKeysCodes: number[] = [ENTER, COMMA];

  export const availableTemplateBlocks: string[] = [
    YEAR,
    YEAR_SHORT,
    COUNTER,
    TASK_NAME,
    POC_ADDRESS,
    FINALIZATION_USER_NAME,
    FINALIZATION_USER_PERSON_NAME,
    ASSIGNEE_USER_GROUP_NAME,
    ASSIGNEE_USER_NAME,
    ASSIGNEE_USER_PERSON_NAME,
    CREATOR_USER_NAME,
    CREATOR_USER_PERSON_NAME,
    ACTUAL_DATE,
    AGREED_TIME,
    CUSTOMER_RECORD_NAME,
    CUSTOMER_NAME,
    CUSTOMER_RECORD_EXTERNAL_ID,
    CUSTOMER_RECORD_PHONE_NUMBERS,
    CUSTOMER_EXTERNAL_ID,
    TASK_RECORD_ID,
    PROJECT_RECORD_NAME,
    PROJECT_RECORD_EXTERNAL_ID,
  ];

  export const templateBlockExampleMap: Map<string, string> = Map.of(
    YEAR, new Date().getFullYear().toString(),
    YEAR_SHORT, (new Date().getFullYear() % 100).toString(),
    COUNTER, '1',
    TASK_NAME, 'TASK_RECORD_CREATE_NAME_TEMPLATE_EXAMPLE_TASK_NAME',
    POC_ADDRESS, 'TASK_RECORD_CREATE_NAME_TEMPLATE_EXAMPLE_POC_ADDRESS',
    FINALIZATION_USER_NAME, 'TASK_RECORD_CREATE_NAME_TEMPLATE_EXAMPLE_FINALIZATION_USER_NAME',
    FINALIZATION_USER_PERSON_NAME, 'TASK_RECORD_CREATE_NAME_TEMPLATE_EXAMPLE_FINALIZATION_USER_PERSON_NAME',
    ASSIGNEE_USER_NAME, 'TASK_RECORD_CREATE_NAME_TEMPLATE_EXAMPLE_ASSIGNEE_USER_NAME',
    ASSIGNEE_USER_PERSON_NAME, 'TASK_RECORD_CREATE_NAME_TEMPLATE_EXAMPLE_ASSIGNEE_USER_PERSON_NAME',
    ASSIGNEE_USER_GROUP_NAME, 'TASK_RECORD_CREATE_NAME_TEMPLATE_EXAMPLE_ASSIGNEE_USER_GROUP_NAME',
    CREATOR_USER_NAME, 'TASK_RECORD_CREATE_NAME_TEMPLATE_EXAMPLE_ASSIGNEE_USER_NAME',
    CREATOR_USER_PERSON_NAME, 'TASK_RECORD_CREATE_NAME_TEMPLATE_EXAMPLE_ASSIGNEE_USER_PERSON_NAME',
    ACTUAL_DATE, 'TASK_RECORD_CREATE_NAME_TEMPLATE_EXAMPLE_ACTUAL_DATE',
    AGREED_TIME, 'TASK_RECORD_CREATE_NAME_TEMPLATE_EXAMPLE_AGREED_TIME',
    CUSTOMER_RECORD_NAME, 'TASK_RECORD_CREATE_NAME_TEMPLATE_EXAMPLE_CUSTOMER_RECORD_NAME',
    CUSTOMER_NAME, 'TASK_RECORD_CREATE_NAME_TEMPLATE_EXAMPLE_CUSTOMER_NAME',
    CUSTOMER_RECORD_EXTERNAL_ID, 'TASK_RECORD_CREATE_NAME_TEMPLATE_EXAMPLE_CUSTOMER_RECORD_EXTERNAL_ID',
    CUSTOMER_RECORD_PHONE_NUMBERS, 'TASK_RECORD_CREATE_NAME_TEMPLATE_EXAMPLE_CUSTOMER_RECORD_PHONE_NUBMERS',
    CUSTOMER_EXTERNAL_ID, 'TASK_RECORD_CREATE_NAME_TEMPLATE_EXAMPLE_CUSTOMER_EXTERNAL_ID',
    PROJECT_RECORD_NAME, 'TASK_RECORD_CREATE_NAME_TEMPLATE_EXAMPLE_PROJECT_RECORD_NAME',
    PROJECT_RECORD_EXTERNAL_ID, 'TASK_RECORD_CREATE_NAME_TEMPLATE_EXAMPLE_PROJECT_RECORD_EXTERNAL_ID',
    TASK_RECORD_ID, '154'
  );

}

export class ValidationTypeItem extends ImmutableOptionItem<TaskValidationType> {

  public static fromType(
    type: TaskValidationType,
    textResolver: (type: TaskValidationType) => Observable<string>): ValidationTypeItem {
    return new ValidationTypeItem(type, textResolver(type));
  }

  public static fromTypeList(
    list: List<TaskValidationType>,
    textResolver: (type: TaskValidationType) => Observable<string>): List<ValidationTypeItem> {
    return List.of(...list.toArray().map((item) => {
      return ValidationTypeItem.fromType(item, textResolver);
    }));
  }

}

export class DefaultDeadlineTypeItem extends ImmutableOptionItem<DayType> {

  public static fromType(
    type: DayType,
    textResolver: (type: DayType) => Observable<string>): DefaultDeadlineTypeItem {
    return new DefaultDeadlineTypeItem(type, textResolver(type));
  }

  public static fromTypeList(
    list: List<DayType>,
    textResolver: (type: DayType) => Observable<string>): List<DefaultDeadlineTypeItem> {
    return List.of(...list.toArray().map((item) => {
      return DefaultDeadlineTypeItem.fromType(item, textResolver);
    }));
  }

}

export interface PdfUploadFieldErrorMap {
  name?: FieldError;
}

@Injectable()
export class TaskValidationTypeTextResolverFactory {

  constructor(private translateService: TranslateService) {
  }

  public createFunction(): (type: TaskValidationType) => Observable<string> {
    return (type) => {
      return this.translateService.get('TASK_VALIDATION_TYPE_' + type);
    };
  }

}

@Injectable()
export class DefaultDeadlineTypeTextResolverFactory {

  constructor(private translateService: TranslateService) {
  }

  public createFunction(): (type: DayType) => Observable<string> {
    return (type) => {
      return this.translateService.get('TASK_DEFAULT_DEADLINE_TYPE_' + type);
    };
  }

}

export class TaskRightModel {

  public readonly taskRecordCreate: GrantedPermissionSet;
  public readonly update: GrantedPermissionSet;
  public readonly disable: GrantedPermissionSet;

  constructor(resolver: GrantedPermissionSetResolver) {
    this.update = resolver.of(OperationRights.TASK_UPDATE);
    this.disable = resolver.of(OperationRights.TASK_DISABLE);
    this.taskRecordCreate = resolver.of(OperationRights.TASK_RECORD_CREATE);
  }
}
