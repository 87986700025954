import { Injectable } from '@angular/core';
import { ReplaySubject, Subject, Subscription } from 'rxjs';

@Injectable()
export class FormRecordFieldNotifierService {

  private _subject: Subject<FieldNotification> = new ReplaySubject();

  constructor() {
  }

  subscribe(next?: (value: FieldNotification) => void, error?: (error: any) => void, complete?: () => void): Subscription {
    return this._subject.subscribe(next, error, complete);
  }

  unsubscribe(subscription: Subscription) {
    subscription.unsubscribe();
  }

  clear() {
    this._subject.unsubscribe();
    this._subject = new ReplaySubject();
  }

  notify(notification: FieldNotification) {
    this._subject.next(notification);
  }

}

export interface FieldNotification {
  groupId: number;
  fieldId: number;
  data: any;
}
