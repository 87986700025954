/* eslint-disable */
import { AfterViewInit, Component, ViewChild, } from '@angular/core';
import {
  FieldDataTypeSelectors,
  FormEditFieldCreateDialogView,
  FormEditFieldDialogContext,
  FormEditFieldUpdateDialogView,
  FormGroupModel,
  FormModel,
  FormRecordSerializer,
} from '../../../../../../../util/form/form-utils';
import { InputMask } from '../../../../../../../util/input-masks';
import { Form } from '../../../../../../../lib/form/form.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FieldWidthType } from '../../../../../../../util/form/form-field-width-type';
import {
  FieldValidationError,
  FormRef,
  LocalFieldValidationErrors,
  LocalFieldValidationErrorsFactory,
} from '../../../../../../../lib/util/services';
import { NgModel } from '@angular/forms';
import { List, Set } from 'immutable';
import { MultiselectOptionItem, OptionItem, TypeItem, UiConstants } from '../../../../../../../util/core-utils';
import { MasterDataService } from '../../../../../../../lib/masterdata/master-data.service';
import { FieldValidationType, FormFieldValidationTypeModel, } from '../../../../../../../util/form/form-field-validation-type';
import { Models } from '../../../../../../../util/model-utils';
import { Angular2Multiselects } from '../../../../../../../util/multiselect';
import { Strings } from '../../../../../../../lib/util/strings';
import { MasterDataFilterType } from '../../../../../../../util/form/form-field-masterdata-filter-type';
import { TranslateService } from '@ngx-translate/core';

/* eslint-enable */

@Component({
  selector: 'app-form-edit-create-update-masterdata-field-dialog',
  templateUrl: 'form-edit-create-update-masterdata-field-dialog.component.html',
  styleUrls: ['form-edit-create-update-masterdata-field-dialog.component.scss'],
})
export class FormEditCreateUpdateMasterDataFieldDialogComponent
  implements FormEditFieldCreateDialogView, FormEditFieldUpdateDialogView, FormRef, AfterViewInit {

  public readonly selector = Form.FieldDataTypeSelector.MASTER_DATA;
  public readonly createTitle = 'FORM_ITEM_CREATE_MASTER_DATA_TITLE';
  public readonly updateTitle = 'FORM_ITEM_UPDATE_MASTER_DATA_TITLE';
  public readonly cloneTitle = 'FORM_ITEM_CLONE_MASTER_DATA_TITLE';

  InputMask = InputMask;
  Form = Form;
  UiConstants = UiConstants;

  @ViewChild('fieldDialog', {static: true})
  dialogComponent: ModalDirective;

  @ViewChild('title')
  titleInput: NgModel;

  @ViewChild('masterData')
  masterDataInput: NgModel;

  @ViewChild('api_export_name')
  apiExportNameInput: NgModel;

  @ViewChild('filterType')
  filterTypeInput: NgModel;

  visible: boolean = false;
  fieldModel: Model = new Model();
  dialogTitleDictionaryKey: string = '';
  latestApiExportName = '';

  fieldWidthTypes: FieldWidthType[] = FieldWidthType.VALUES.toArray();
  fieldValidationTypes: Form.FormFieldValidationType[] = FieldValidationType.VALUES.toArray();

  private fieldErrors: FieldValidationError<Form.FieldValidatedField> =
    FieldValidationError.empty<Form.FieldValidatedField>();

  private validatedInputs: LocalFieldValidationErrors<NgModel> =
    LocalFieldValidationErrorsFactory.empty();

  private context: FormEditFieldDialogContext;
  private group: FormGroupModel;
  private field?: Form.Field;
  private clone?: boolean;
  private submitted: boolean = false;

  availableMasterData: TypeItem[] = [];
  filterTypes: MultiselectOptionItem<MasterDataFilterType.MasterDataFilterType>[] = [];
  availableMasterDataFields: MultiselectOptionItem<number>[] = [];
  remoteDropdownSettings?: Angular2Multiselects.Settings;
  localDropdownSettings?: Angular2Multiselects.Settings;
  localSingleDropdownSettings?: Angular2Multiselects.Settings;

  getForm() {
    return {
      submitted: this.submitted
    };
  }

  public constructor(private masterDataService: MasterDataService,
                     private translateService: TranslateService) {
  }

  ngAfterViewInit(): void {
    this.loadLocalFieldValidationErrors();
    this.initDropdownSettings();
  }

  initDropdownSettings() {
    this.remoteDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .labelKey(OptionItem.KEY_TEXT)
      .build();
    this.localDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(false)
      .enableCheckAll(true)
      .remoteSearch(false)
      .build();
    this.localSingleDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(false)
      .translate(false)
      .build();
  }

  registerContext(context: FormEditFieldDialogContext): void {
    this.context = context;
  }

  showDialog(form: FormModel, group: FormGroupModel, field?: Form.Field, clone?: boolean): void {
    this.latestApiExportName = '';
    this.visible = true;
    this.clone = clone;
    this.loadMasterDataTypesAndShowDialog(form, group, field, clone);
  }

  onModalHide(event: ModalDirective) {
    if (event.dismissReason === 'backdrop-click' || event.dismissReason === 'esc') {
      this.visible = false;
      this.submitted = false;
      this.fieldModel = new Model();
    }
  }

  closeDialog(): void {
    this.visible = false;
    this.submitted = false;
    this.dialogComponent.hide();
    this.fieldModel = new Model();
    this.availableMasterData = [];
  }

  saveField() {
    this.submitted = true;
    if (this.hasLocalFieldError(this.titleInput)
      || this.hasLocalFieldError(this.masterDataInput)
      || this.hasLocalFieldError(this.filterTypeInput)
      || this.hasLocalFieldError(this.apiExportNameInput)) {
      return;
    }
    if (this.field && this.clone) {
      this.createField();
    }
    else if (this.field) {
      this.updateField(this.field);
    }
    else {
      this.createField();
    }
  }

  hasLocalFieldError(field?: NgModel): boolean {
    return this.validatedInputs.hasLocalError(field);
  }

  hasFieldError(field?: Form.FieldValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: Form.FieldValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: Form.FieldValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  getFieldValidationTypeDictionaryKey(vt: Form.FormFieldValidationType): string {
    return FieldValidationType.getFieldValidationTypeDictionaryKey(vt);
  }

  onAdminFormFieldValidationTypeChanged() {
    FieldValidationType.onAdminFormFieldValidationTypeChanged(this.fieldModel);
  }

  onMobileFormFieldValidationTypeChanged() {
    FieldValidationType.onMobileFormFieldValidationTypeChanged(this.fieldModel);
  }

  private loadLocalFieldValidationErrors() {
    const validatedInputs = List.of(this.titleInput, this.masterDataInput, this.filterTypeInput, this.apiExportNameInput);
    this.validatedInputs = LocalFieldValidationErrorsFactory.ofFields(this, validatedInputs);
  }

  private loadMasterDataTypesAndShowDialog(form: FormModel, group: FormGroupModel, field?: Form.Field, clone?: boolean) {
    this.initMasterDataFilterTypes();
    this.loadMasterDataTypes(() => {
      this.loadDialog(form, group, field, clone);
    })
  }

  private loadMasterDataTypes(completion: () => void, searchValue?: string) {
    this.masterDataService.query({
      name: Strings.undefinedOrNonEmpty(searchValue),
      disabled: false,
      paging: {
        numberOfItems: UiConstants.autocompletePageSize,
        pageNumber: 1
      },
      noProgressBar: true
    }).subscribe(masterDataList => {
      this.availableMasterData = [];
      masterDataList.items.forEach((masterData) => {
        if (masterData) {
          const item = new TypeItem();
          item.id = masterData.masterDataId;
          item.text = masterData.name;
          item.disabled = masterData.disabled;
          this.availableMasterData.push(item);
        }
      });
      if (completion) {
        completion();
      }
    });
  }


  private loadDialog(form: FormModel, group: FormGroupModel, field?: Form.Field, clone?: boolean) {
    this.group = group;
    this.dialogComponent.show();
    if (field && clone) {
      this.dialogTitleDictionaryKey = this.cloneTitle;
      this.loadField(field);
    }
    else if (field) {
      this.dialogTitleDictionaryKey = this.updateTitle;
      this.loadField(field);
    }
    else {
      this.dialogTitleDictionaryKey = this.createTitle;
    }
  }

  private loadField(field: Form.Field) {
    this.field = field;
    const attrs = field.dataType.masterDataAttributes!;
    const masterData = this.getMasterDataById(attrs.masterDataId);
    const placeholder = attrs.hint;
    const fieldWidth = FieldWidthType.fromPercentValue(field.formFieldWidthPercent);
    this.fieldModel.title = field.title;
    this.fieldModel.description = field.description ? field.description : '';
    this.fieldModel.hint = field.hint ? field.hint : '';
    this.fieldModel.apiExportName = field.apiExportName ? field.apiExportName : '';
    this.fieldModel.pdfExportName = field.pdfExportName ? field.pdfExportName : '';
    this.fieldModel.fieldWidthType = fieldWidth ? fieldWidth : FieldWidthType.DEFAULT;
    this.fieldModel.displayOnNewRow = field.displayOnNewRow;
    this.fieldModel.placeholder = placeholder ? placeholder : '';
    this.fieldModel.multiSelect = attrs.multiSelect;
    this.fieldModel.autoJoinOwnerOnFinish = attrs.autoJoinOwnerOnFinish;
    this.fieldModel.displayedFormField = [];
    this.fieldModel.adminVisibleOnFormDetail = field.admin.visibleOnFormDetail;
    this.fieldModel.mobileVisibleOnFormDetail = field.mobile.visibleOnFormDetail;
    this.fieldModel.mobileVisibleOnMasterDetail = field.mobile.visibleOnMasterDetail;
    this.fieldModel.adminFormFieldValidationType = field.admin.validationType;
    this.fieldModel.helpdeskFormFieldValidationType = field.helpdesk.validationType;
    this.fieldModel.mobileFormFieldValidationType = field.mobile.validationType;
    this.fieldModel.showOnReceipt = field.showOnReceipt;
    this.fieldModel.masterDataItem = [];
    this.loadMasterDataFilterTypes(attrs.filterTypes);
    if (masterData) {
      this.fieldModel.masterDataItem.push(masterData);
      this.loadMasterDataFormFields(masterData);
    }
    else {
      this.masterDataService.get({
        masterDataId: attrs.masterDataId
      }).subscribe(md => {
        if (md) {
          const item = new TypeItem();
          item.id = md.masterDataId;
          item.text = md.name;
          item.disabled = md.disabled;
          this.fieldModel.masterDataItem.push(item);
          this.masterDataInput.control.updateValueAndValidity();
          this.loadMasterDataFormFields(item);
        }
      })
    }
    if (attrs.displayedFormField) {
      this.fieldModel.displayedFormField = [this.fieldToOptionItem(attrs.displayedFormField)];
    }
  }

  private getMasterDataById(masterDataId: number): TypeItem | undefined {
    let item: TypeItem | undefined = undefined;
    this.availableMasterData.forEach(i => {
      if (i.id === masterDataId) {
        item = i;
      }
    });
    return item;
  }

  private createField() {
    this.context.formService.createField({
      parentId: this.context.parentId,
      groupId: this.group.groupId,
      title: this.fieldModel.title,
      description: this.fieldModel.description,
      hint: this.fieldModel.hint,
      pdfExportName: this.fieldModel.pdfExportName,
      apiExportName: this.fieldModel.apiExportName,
      dataTypeSelector: this.selector,
      dataType: this.getDataTypeRequest(),
      formFieldWidthPercent: this.fieldModel.fieldWidthType.percentValue,
      displayOnNewRow: this.fieldModel.displayOnNewRow,
      admin: {
        visibleOnFormDetail: this.fieldModel.adminVisibleOnFormDetail,
        validationType: this.fieldModel.adminFormFieldValidationType
      },
      helpdesk: {
        validationType: this.fieldModel.helpdeskFormFieldValidationType
      },
      mobile: {
        visibleOnFormDetail: this.fieldModel.mobileVisibleOnFormDetail,
        visibleOnMasterDetail: this.fieldModel.mobileVisibleOnMasterDetail,
        validationType: this.fieldModel.mobileFormFieldValidationType
      },
      showOnReceipt: this.fieldModel.showOnReceipt
    }).subscribe(
      (result) => {
        this.context.formView.reloadForm();
        this.closeDialog();
      },
      (error) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withFormRef(this);
        }
      }
    );
  }

  private updateField(field: Form.Field) {
    this.context.formService.updateField({
      parentId: this.context.parentId,
      groupId: this.group.groupId,
      fieldId: field.fieldId,
      title: this.fieldModel.title,
      description: this.fieldModel.description,
      hint: this.fieldModel.hint,
      pdfExportName: this.fieldModel.pdfExportName,
      apiExportName: this.fieldModel.apiExportName,
      dataTypeSelector: this.selector,
      dataType: this.getDataTypeRequest(),
      formFieldWidthPercent: this.fieldModel.fieldWidthType.percentValue,
      displayOnNewRow: this.fieldModel.displayOnNewRow,
      admin: {
        visibleOnFormDetail: this.fieldModel.adminVisibleOnFormDetail,
        validationType: this.fieldModel.adminFormFieldValidationType
      },
      helpdesk: {
        validationType: this.fieldModel.helpdeskFormFieldValidationType
      },
      mobile: {
        visibleOnFormDetail: this.fieldModel.mobileVisibleOnFormDetail,
        visibleOnMasterDetail: this.fieldModel.mobileVisibleOnMasterDetail,
        validationType: this.fieldModel.mobileFormFieldValidationType
      },
      showOnReceipt: this.fieldModel.showOnReceipt
    }).subscribe(
      (result) => {
        this.context.formView.reloadForm();
        this.closeDialog();
      },
      (error) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withFormRef(this);
        }
      }
    );
  }

  private getDataTypeRequest(): Form.FieldDataType {
    return {
      masterDataAttributes: {
        masterDataId: this.fieldModel.masterDataItem[0]!.id!,
        hint: this.fieldModel.placeholder,
        multiSelect: this.fieldModel.multiSelect,
        filterTypes: this.fieldModel.getFilterTypes(),
        autoJoinOwnerOnFinish: this.fieldModel.autoJoinOwnerOnFinish,
        displayedFormFieldId: this.fieldModel.displayedFormFieldId
      }
    };
  }

  onTitleBlur() {
    const normalizedText = Models.normalizeText(this.fieldModel.title);
    if (this.fieldModel.apiExportName === '') {
      this.fieldModel.apiExportName = normalizedText;
    }
    if (this.fieldModel.pdfExportName === '') {
      this.fieldModel.pdfExportName = normalizedText;
    }
  }

  private initMasterDataFilterTypes() {
    this.filterTypes = [];
    MasterDataFilterType.masterDataFilterTypes.forEach((filterType) => {
      const item = new MultiselectOptionItem<MasterDataFilterType.MasterDataFilterType>()
      item.id = filterType.type;
      this.translateService.get(filterType.stringKey).subscribe(
        (result: string) => {
          item.itemName = result;
        }
      );
      this.filterTypes.push(item);
    })
  }

  private loadMasterDataFilterTypes(filterTypes: Set<MasterDataFilterType.MasterDataFilterType>) {
    filterTypes.forEach((filterType) => {
      const item = this.filterTypes.find(ft => ft.id === filterType);
      if (item) {
        this.fieldModel.filterTypes.push(item);
        this.filterTypeInput.control.updateValueAndValidity();
      }
    })
  }

  private fieldToOptionItem(field: Form.Field): MultiselectOptionItem<number> {
    const item = {
      id: field.fieldId,
      itemName: field.title,
      itemSubtitle: '',
      disabled: field.disabled
    }

    this.translateService.get(FieldDataTypeSelectors.getFormFieldNameKey(field.dataTypeSelector)).subscribe(result => {
      item.itemSubtitle = result;
    });

    return item;
  }

  loadMasterDataFormFields(masterDataItem?: TypeItem) {
    this.availableMasterDataFields = [];
    if (masterDataItem) {
      this.masterDataService.getForm({parentId: masterDataItem.id!}).subscribe(form => {
        const fields = form.groups.toArray().map(g => g.fields.toArray())
          .reduce((previousValue, currentValue) => {
            previousValue.push(...currentValue);
            return previousValue;
          }, []);
        this.availableMasterDataFields = fields
          .filter(f => !f.disabled && FormRecordSerializer.isSerializableField(f))
          .sort((a, b) => a.title.localeCompare(b.title))
          .map(f => this.fieldToOptionItem(f));
        if (this.fieldModel.displayedFormFieldId) {
          if(!this.availableMasterDataFields.find(f => f.id === this.fieldModel.displayedFormFieldId)) {
            this.fieldModel.displayedFormField = [];
          }
        }
      });
    }
    else {
      this.fieldModel.displayedFormField = [];
    }
  }
}


export class Model implements FormFieldValidationTypeModel {
  title: string = '';
  description: string = '';
  apiExportName: string = '';
  pdfExportName: string = '';
  fieldWidthType: FieldWidthType = FieldWidthType.FULL_SCREEN;
  displayOnNewRow: boolean = false;
  hint?: string;
  placeholder?: string;
  masterDataItem: TypeItem[] = [];
  displayedFormField: MultiselectOptionItem<number>[] = [];
  multiSelect: boolean = false;
  autoJoinOwnerOnFinish: boolean = false;
  filterTypes: MultiselectOptionItem<MasterDataFilterType.MasterDataFilterType>[] = [];
  adminVisibleOnFormDetail: boolean = true;
  mobileVisibleOnFormDetail: boolean = true;
  mobileVisibleOnMasterDetail: boolean = false;
  adminFormFieldValidationType: Form.FormFieldValidationType = Form.FormFieldValidationType.OPTIONAL;
  helpdeskFormFieldValidationType: Form.FormFieldValidationType = Form.FormFieldValidationType.OPTIONAL;
  mobileFormFieldValidationType: Form.FormFieldValidationType = Form.FormFieldValidationType.OPTIONAL;
  showOnReceipt?: boolean = false;

  getFilterTypes(): Set<MasterDataFilterType.MasterDataFilterType> {
    return Set.of(...this.filterTypes.map(ft => ft.id))
  }

  get displayedFormFieldId(): number | undefined {
    return this.displayedFormField.length === 0 ? undefined : this.displayedFormField[0].id;
  }

  isMasterDataSelected(): boolean {
    return this.masterDataItem.length > 0;
  }
}
