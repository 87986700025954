/* eslint-disable */
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ErrorDetail, ErrorResource, ObservableErrorResourceParser, ObservableErrorResponse } from '../lib/util/errors';
import { CredentialLoginMethod, CredentialLoginMethodFactory } from '../lib/login-methods';
import { TranslateService } from '@ngx-translate/core';
import { AuthResult, AuthService } from '../lib/auth.service';
import { Transition, UIRouter } from '@uirouter/angular';
import { StateName } from '../app.state-names';
import { AppConfigurationService } from '../lib/app-configuration.service';
import { ConfigurationResource, ConfigurationService, } from '../lib/core-ext/configuration.service';
import { Language, SettingsService, } from '../lib/settings.service';
import { Logger, LoggerFactory } from '../util/logger-factory';
import {
  AuthTokenStorage,
  LoggedInUserStorage,
  LoginRequiredReason,
  LoginRequiredReasonStorage,
  ServiceErrorStorage
} from '../lib/util/storages';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { RouterStateObject, UiConstants } from '../util/core-utils';
import { ShopRenterService, ShopRenterStorage } from '../lib/shoprenter/shoprenter.service';
import { ConfirmDialogComponent } from '../shared/confirm-dialog/confirm-dialog.component';
import { StringKey } from '../app.string-keys';
import { toasterConfig } from '../fork/angular2-toaster/src/toaster-config';
import { ResourceHelper } from '../lib/util/http-services';
import { Dates } from '../lib/util/dates';

import RegistrationType = ConfigurationResource.RegistrationType;
import {AppType} from "../lib/util/app-type-helper.service";

/* eslint-enable */

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {

  UiConstants = UiConstants;
  toasterConfig = toasterConfig;
  RegistrationType = RegistrationType;

  @ViewChild('loginForm')
  loginForm: ElementRef;

  @ViewChild('loginMethodSelectDialog', { static: true })
  loginMethodSelectDialog: ModalDirective;
  loginMethodSelectDialogVisible: boolean = false;

  @ViewChild('loginInfoDialog', { static: true })
  loginInfoDialog: ConfirmDialogComponent;

  infoMessage: string = '';

  loginMethods: CredentialLoginMethod[] = [];

  private _loading = false;

  model: LoginModel;
  loggingIn = false;
  loginFail: ErrorDetail | null = null;

  noScrollStyleElement: HTMLStyleElement;

  toRegister: boolean = true;
  fromRegister: boolean = false;

  get loading(): boolean {
    return this._loading;
  }

  get authTokenStorageAvailable(): boolean {
    return AuthTokenStorage.getInstance().isStorageAvailable();
  }

  get brandLogoLoginSrc(): string | undefined {
    return this.resourceHelper.getBrandLogoLoginSrc()
  }

  get loginBackgroundSrc(): string | undefined {
    return this.resourceHelper.getLoginBackgroundSrc()
  }

  get languages(): Language[] {
    return this.settingsService.getAvailableLanguages().toArray();
  }

  get shopRenterCase(): boolean {
    return this.shopRenterStorage.getShopId() !== null;
  }

  get shopRenterShopName(): string {
    return this.shopRenterStorage.getShopName()!;
  }

  get registrationType(): RegistrationType | undefined {
    return this.configurationService.getConfiguration().feature_flags.registration_enabled;
  }

  get demoModeEnabled(): boolean {
    return this.configurationService.getConfiguration().feature_flags.demo_mode_enabled;
  }

  get registrationEnabled(): boolean {
    if (this.registrationType) {
      if (this.registrationType === RegistrationType.CREATE_USER_SHOPRENTER) {
        return this.shopRenterCase;
      }
      if (this.registrationType === RegistrationType.CREATE_USER_DEMO) {
        return this.demoModeEnabled;
      }
      return true;
    }
    return false;
  }

  private logger: Logger;
  private shopRenterStorage = ShopRenterStorage.getInstance();
  passwordVisible: boolean = false;

  constructor(
    private router: UIRouter,
    private transition: Transition,
    private authService: AuthService,
    private translateService: TranslateService,
    private appConfigurationService: AppConfigurationService,
    private configurationService: ConfigurationService,
    private settingsService: SettingsService,
    private shopRenterService: ShopRenterService,
    private resourceHelper: ResourceHelper,
    private credentialLoginMethodFactory: CredentialLoginMethodFactory) {
    this.checkConfiguration();
    this.loginMethods = credentialLoginMethodFactory.createAvailableLoginMethods();
    this.model = new LoginModel();
    this.logger = LoggerFactory.createLogger('LoginComponent');
    this.fromRegister = this.transition.params().fromRegister;
    if (transition.options().custom.fromHelpdesk) {
      window.location.reload();
    }
  }

  ngOnInit() {
    this._loading = true;
    this.authService.check({})
      .subscribe(
        (authResult: AuthResult) => {
          this._loading = false;
          if (authResult.user_profile) {
            this.logger.debug('initConfig done, init');
            this.router.stateService.go(StateName.STARTUP);
          }
        },
        (error: any) => {
          this._loading = false;
        }
      );
    this.disableScrolling();
  }

  ngAfterViewInit(): void {
    this.toRegister = false;
    setTimeout(() => this.loginForm.nativeElement.classList.remove('login-out'));
  }

  isCurrentLanguage(language: Language): boolean {
    return this.settingsService.isCurrentLanguage(language);
  }

  isHelpdeskEnabled(): boolean {
    return this.configurationService.getConfiguration().feature_flags.helpdesk_enabled;
  }
  getLoginRequiredReason(): LoginRequiredReason | null {
    return LoginRequiredReasonStorage.getInstance().getReason();
  }

  getFormattedAppVersion(): string {
    return this.appConfigurationService.getFormattedAppVersion();
  }

  getAppVersion(): string {
    return this.appConfigurationService.getVersion();
  }

  getAppRevision(): string {
    return this.appConfigurationService.getRevision();
  }

  getServerVersion(): string {
    return this.configurationService.getServerVersion();
  }

  changeLanguage(language: Language) {
    this.settingsService.setLocaleCode(language.localeCode);
    this.translateService.use(language.localeCode).subscribe(
      () => {
        // This does not work:
        // this.router.stateService.reload(StateName.LOGIN);
        // Reload the Angular application instead:
        window.location.reload();
        // Reason:
        // Changing LOCALE_ID (in @angular/core, provided by LOCALE_CODE_PROVIDER) in runtime
        // does no change formatting of pipes.
      },
      () => {
        this.logger.warn('Failed to change language');
      });
  }

  login() {
    this.loggingIn = true;
    this.loginFail = null;
    this.logger.debug('initConfig done, login');
    const destination: RouterStateObject = LoginRequiredReasonStorage.getInstance().getStateObject();
    LoginRequiredReasonStorage.getInstance().setReason(null);
    this.authService.userLogin({
      user_name: this.model.username,
      password: this.model.password,
      application_type: AppType.WEBDMIN
    }).subscribe(
      (authResult: AuthResult) => {
        this.loggingIn = false;
        this.afterLogin(authResult, destination);
      },
      (error: ObservableErrorResponse) => {
        this.loggingIn = false;
        if (error.status === 409) {
          this.showLoginMethodSelectDialog();
          return;
        }
        const body: ErrorResource | null = ObservableErrorResourceParser.parseError(error);
        if (body && body.detail) {
          this.loginFail = body.detail;
        }
      }
    );
  }

  loginWithMethod(loginMethod: CredentialLoginMethod) {
    this.loginMethodSelectDialog.hide();
    loginMethod.login({
      user_name: this.model.username,
      password: this.model.password,
      application_type: AppType.WEBDMIN
    }).subscribe(
      (authResult: AuthResult) => {
        this.loggingIn = false;
        this.afterLogin(authResult);
      },
      (error: ObservableErrorResponse) => {
        this.loggingIn = false;
        const body: ErrorResource | null = ObservableErrorResourceParser.parseError(error);
        if (body && body.detail) {
          this.loginFail = body.detail;
        }
      }
    );
  }

  private afterLogin(authResult: AuthResult, destination?: RouterStateObject) {
    LoggedInUserStorage.getInstance().setUserId(authResult.user_profile!.id);
    if (this.shopRenterCase) {
      this.handleShopRenter(authResult);
    }
    else if (destination) {
      this.router.stateService.go(destination.stateName, destination.params);
    }
    else {
      this.router.stateService.go(StateName.STARTUP);
    }
  }

  private handleShopRenter(authResult: AuthResult) {
    const userId = this.shopRenterStorage.getUserId();
    const currentUserId = authResult.user_profile!.id;
    if (userId !== null) {
      if (userId !== currentUserId) {
        this.shopRenterStorage.clear();
        this.translateService.get(StringKey.SHOPRENTER_LOGIN_NOT_OWNER).subscribe(text => {
          this.showInfoDialog(text);
        });
      }
      else {
        const shopId = this.shopRenterStorage.getShopId()!;
        this.shopRenterStorage.clear();
        this.router.stateService.go(StateName.SHOP_RENTER_SHOP_EDIT, {id: shopId});
      }
    }
    else {
      const shopId: number = this.shopRenterStorage.getShopId()!;
      const shopName: string = this.shopRenterStorage.getShopName()!;
      const code: string = this.shopRenterStorage.getCode()!;
      const hmac: string = this.shopRenterStorage.getHmac()!;
      const timestamp: number = this.shopRenterStorage.getTimestamp()!;
      const request = {
        shopId: shopId,
        shopName: shopName,
        code: code,
        hmac: hmac,
        timestamp: timestamp
      };
      this.shopRenterStorage.clear();
      this.shopRenterService.connectShopToMe(request).subscribe(
        (result) => {
          this.router.stateService.go(StateName.SHOP_RENTER_SHOP_EDIT, {id: shopId});
        },
        (error) => {
          this.translateService.get(StringKey.SHOPRENTER_LOGIN_CONNECT_FAILED).subscribe(text => {
            this.showInfoDialog(text);
          });
        }
      );
    }
  }

  showInfoDialog(message: string) {
    this.infoMessage = message;
    this.loginInfoDialog.showDialog();
  }

  navigateToHome(param?: any) {
    this.router.stateService.go(StateName.STARTUP);
  }

  private checkConfiguration() {
    if (!this.configurationService.loaded) {
      localStorage.setItem('configurationError', 'true');
      ServiceErrorStorage.getInstance().setStateObject({
        stateName: this.router.stateService.$current.name,
        params: this.router.stateService.params
      });
      this.router.stateService.go(StateName.CONFIGURATION_LOAD_ERROR);
    }
  }

  showLoginMethodSelectDialog() {
    this.loginMethodSelectDialogVisible = true;
    this.loginMethodSelectDialog.show();
  }

  closeLoginMethodSelectDialog() {
    this.loginMethodSelectDialogVisible = false;
    this.loginMethodSelectDialog.hide();
  }

  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }

  get isSantaOn(): boolean {
    return this.configurationService.getConfiguration().santa === undefined ? false : this.configurationService.getConfiguration().santa!;
  }

  get isAlmostChristmas() {
    return Dates.isAlmostChristmas();
  }

  navigateToRegister() {
    this.toRegister = true;
    this.loginForm.nativeElement.classList.add('login-out');
    setTimeout(() => this.router.stateService.go(StateName.REGISTER), 300);
  }

  private disableScrolling() {
    this.noScrollStyleElement = document.createElement('style');
    this.noScrollStyleElement.type = 'text/css';
    this.noScrollStyleElement.textContent = `
      body {
        overflow-x: hidden !important;
        overflow-y: scroll !important;
      }
    `;
    document.body.appendChild(this.noScrollStyleElement);
  }

  ngOnDestroy() {
    if (this.noScrollStyleElement) {
      document.body.removeChild(this.noScrollStyleElement);
    }
  }

}

export class LoginModel {

  username: string = '';
  password: string = '';

  constructor() {
  }

}
