import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StockRecordFacade, StockRecordLot } from '../../../lib/stock/stock-record.service';
import { StockRecordLotEditDialogComponent } from '../stock-record-lot-edit-dialog/stock-record-lot-edit-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Currency } from '../../../lib/currency.service';
import { StockItemUtils } from '../../../util/stock/stock-item-utils';

@Component({
  selector: 'app-stock-stock-record-lot-list',
  templateUrl: './stock-stock-record-lot-list.component.html',
  styleUrls: ['./stock-stock-record-lot-list.component.scss']
})
export class StockStockRecordLotListComponent implements OnInit {

  StockItemUtils = StockItemUtils;
  @Input()
  lots: StockRecordLot[] = [];

  @Input()
  stockRecord?: StockRecordFacade;

  @Input()
  readonly: boolean = false;

  @Output()
  onEdit: EventEmitter<number> = new EventEmitter<number>();

  @Input()
  currencies: Currency.Currency[] = [];

  constructor(private dialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  onEditClicked(lot: StockRecordLot) {
    const dialogRef = this.dialog.open(StockRecordLotEditDialogComponent, {
      width: '60%',
      data: {stockRecord: this.stockRecord!, stockRecordLot: lot}
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.modified) {
        this.onEdit.emit(lot.id);
      }
    });
  }

  calcSumPrice(amount: number, intake_unit_net_price?: number): number | undefined {
    if (!intake_unit_net_price) {
      return undefined;
    }
    const sum = amount * intake_unit_net_price;
    return sum;
  }

  getCurrencyName(currencyCode: string) {
    const cc = this.currencies.find(c => c.currencyCode === currencyCode);
    return cc ? cc.localizedName : currencyCode;
  }

  getCurrency(currencyCode: string) {
    const cc = this.currencies.find(c => c.currencyCode === currencyCode);
    return cc!;
  }

}
