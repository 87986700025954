import { DqlQuery, Query } from '../field';

export class OrderDocument {

  readonly creationTime: Query.OrderField;
  readonly contentSize: Query.OrderField;
  readonly contentType: Query.OrderField;
  readonly originalFileName: Query.OrderField;
  readonly meta: OrderDocumentMeta;

  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this.creationTime = new DqlQuery.OrderField(prefix + 'creation_time');
    this.contentSize = new DqlQuery.OrderField(prefix + 'content_size');
    this.contentType = new DqlQuery.OrderField(prefix + 'content_type');
    this.originalFileName = new DqlQuery.OrderField(prefix + 'original_file_name');
    this.meta = new OrderDocumentMeta('meta.');
  }

}

export class OrderDocumentMeta {

  readonly type: Query.OrderField;
  readonly updateTime: Query.OrderField;

  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this.type = new DqlQuery.OrderField(prefix + 'type');
    this.updateTime = new DqlQuery.OrderField(prefix + 'update_time');
  }

}
