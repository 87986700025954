/* eslint-disable */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Order, OrderService } from '../../../lib/order/order.service';
import { StockItem, StockItemService, UnitPrice } from '../../../lib/stock/stock-item.service';
import { WeightFactory } from '../../../util/weight-utils';
import { Logger, LoggerFactory } from '../../../util/logger-factory';
import { ResourceQueryResult } from '../../../lib/util/services';
/* eslint-enable */

export const STOCK_ITEM_VALID_CURRENCY_CODE = 'HUF';

@Component({
  selector: 'app-order-stock-item-container',
  templateUrl: './order-stock-item-container.component.html',
  styleUrls: ['./order-stock-item-container.component.scss']
})
export class OrderStockItemContainerComponent implements OnInit {

  WeightFactory = WeightFactory;

  private readonly logger: Logger;

  @Input() items: Order.OrderItem[] = [];
  @Output() stockItemsLengthChanged: EventEmitter<number> = new EventEmitter<number>();

  @Input()
  orderId: number;

  @Input()
  set orderCompanyId(id: number) {
    this._orderCompanyId = id;
  }

  _orderCompanyId: number;

  _itemErrors: any;

  @Input()
  set itemErrors(v: any) {
    this._itemErrors = v;
    this.logger.debug(this._itemErrors);
  }

  get itemErrors(): any {
    return this._itemErrors;
  }

  stockItems: StockItemForOrder[] = [];
  stockItemsRaw: StockItem[] = [];

  addModel?: OrderStockItemCreateModel;

  data: { price: number, weight?: number, currencyCode: string } = {
    price: 0,
    weight: 0,
    currencyCode: 'HUF',
  };

  constructor(
    private stockItemService: StockItemService,
    private orderService: OrderService
  ) {
    this.logger = LoggerFactory.createLogger('OrderStockItemContainerComponent');
  }

  ngOnInit() {
    this.loadItems();
  }

  loadItems() {
    const itemIds: number[] = [];
    this.items.forEach((item) => {
      itemIds.push(item.stockItemId);
    });
    this.stockItemsRaw = [];
    this.stockItemService.query({id: itemIds.join(',')})
      .subscribe((result: ResourceQueryResult<StockItem>) => {
      this.stockItemsRaw = result.items;
      this.mergeItemLists();
    });
  }

  private mergeItemLists() {
    this.stockItems = [];
    this.items.forEach((item) => {
      this.stockItemsRaw.forEach((itemRaw) => {
        if (item.stockItemId === itemRaw.id) {
          const currentItem: StockItemForOrder = {
            itemId: item.itemId,
            stockItemId: item.stockItemId,
            name: itemRaw.name,
            unitPrice: itemRaw.unit_price,
            amount: item.amount,
            unit: itemRaw.unit,
            weightInGrams: itemRaw.weight_in_grams,
            serialCode: itemRaw.serial_code,
            externalId: itemRaw.external_id,
            editing: false
          };
          this.stockItems.push(currentItem);
        }
      });
    });
    if (this.stockItems.length > 0) {
      this.data.currencyCode = this.stockItems[0].unitPrice.currency_code;
    }
    this.refreshData();
  }

  newItem() {
    this.addModel = new OrderStockItemCreateModel();
  }

  saveNewItem(model: OrderStockItemCreateModel) {
    this.orderService.createStockItem({
      orderId: this.orderId,
      stockItemId: model.stockItemId,
      amount: model.amount
    }).subscribe((result) => {
      model.itemId = result.item_id;
      model.editing = false;
      this.stockItems.push(model);
      this.addModel = undefined;
      this.refreshData();
      this.stockItemsLengthChanged.emit(this.stockItems.length);
    });
  }

  cancelNewItem() {
    this.addModel = undefined;
  }

  toggleEdit(index: number) {
    this.stockItems[index].editing = !this.stockItems[index].editing;
  }

  closeRow(index: number) {
    this.stockItems[index].editing = false;
  }

  saveItem(index: number, model: OrderStockItemCreateModel) {
    this.orderService.updateStockItem({
      orderId: this.orderId,
      itemId: model.itemId,
      stockItemId: model.stockItemId,
      amount: model.amount
    }).subscribe(() => {
      this.stockItems[index] = model;
      this.closeRow(index);
      this.refreshData();
      this.stockItemsLengthChanged.emit(this.stockItems.length);
    });
  }

  removeItem(index: number, itemId: number) {
    this.orderService.deleteStockItem({
      orderId: this.orderId,
      itemId: itemId
    }).subscribe(() => {
      this.closeRow(index);
      this.stockItems.splice(index, 1);
      this.stockItemsLengthChanged.emit(this.stockItems.length);
    });
  }

  refreshData() {
    this.itemErrors = null;
    this.data.price = 0;
    this.data.weight = 0;
    this.stockItems.forEach(i => {
      this.data.price += i.unitPrice.value * i.amount;
      if (i.weightInGrams && this.data.weight !== undefined) {
        this.data.weight += i.weightInGrams * i.amount;
      }
      else if (!i.weightInGrams) {
        this.data.weight = undefined;
      }
    });
  }

  hasItemError(externalId: string): boolean {
    if (this.itemErrors && this.itemErrors[externalId]) {
      return true;
    }
    return false;
  }

  getItemError(externalId: string) {
    if (this.hasItemError(externalId)) {
      return this.itemErrors[externalId];
    }
    return null;
  }
}

interface StockItemForOrder {
  itemId: number;
  stockItemId: number;
  name: string;
  unitPrice: UnitPrice;
  amount: number;
  unit: string;
  weightInGrams?: number;
  serialCode: string;
  externalId: string;
  editing: boolean;
}

export class OrderStockItemCreateModel {
  itemId: number = -1;
  stockItemId: number = 0;
  name: string = '';
  unitPrice: UnitPrice;
  amount: number;
  unit: string = '';
  weightInGrams?: number = 0;
  serialCode: string = '';
  editing: boolean = false;
  externalId: string = '';
}

