  <div id="snow_canvas" class="wrapper" [ngClass]="{'christmas': isAlmostChristmas, 'rudolph': !isAlmostChristmas}">
    <div *ngIf="snowEnabled">
    <!--  <div class="snow layer1 a"></div>-->
    <!--  <div class="snow layer1"></div>-->
    <div class="snow layer2 a"></div>
    <div class="snow layer2"></div>
    <!--  <div class="snow layer3 a"></div>-->
    <!--  <div class="snow layer3"></div>-->
    </div>
  </div>
