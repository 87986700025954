/* eslint-disable */
import { GrantedPermissionSet } from './lib/right.service';
import { UserProfile, } from './lib/auth.service';
import { PermissionDefinition, PermissionDefinitions } from './app.permission-definitions';
import { RightModel } from './app.rights';
import { Order } from './lib/order/order.service';
import { UserCompany } from './lib/user.service';

/* eslint-enable */

export interface OrderRightModelContext {
  rightModel: RightModel;
  currentUser?: UserProfile;
  currentCompanies?: UserCompany[];
}

/**
 * Extension for user rights.
 * @see UserGrantedPermissionSet
 */
export class OrderRightModel {

  public readonly orderRead: OrderReadGrantedPermissionSet;
  public readonly orderUpdate: OrderUpdateGrantedPermissionSet;
  public readonly orderNoteExtraRead: OrderNoteExtraReadGrantedPermissionSet;
  public readonly orderNoteExtraUpdate: OrderNoteExtraUpdateGrantedPermissionSet;
  public readonly orderNoteInternalRead: OrderNoteInternalReadGrantedPermissionSet;
  public readonly orderNoteInternalUpdate: OrderNoteInternalUpdateGrantedPermissionSet;

  static empty() {
    return new OrderRightModel();
  }

  static of(context: OrderRightModelContext) {
    return new OrderRightModel(context);
  }

  private static map<T>(
    presentFactory: (context: OrderRightModelContext) => T, absentFactory: () => T, context?: OrderRightModelContext): T {
    if (context) {
      return presentFactory(context);
    }
    else {
      return absentFactory();
    }
  }

  private constructor(context?: OrderRightModelContext) {
    this.orderRead = OrderRightModel.map(
      (c: OrderRightModelContext) => OrderReadGrantedPermissionSet.of(c.rightModel, c.currentUser, c.currentCompanies),
      () => OrderReadGrantedPermissionSet.empty(),
      context);
    this.orderUpdate = OrderRightModel.map(
      (c: OrderRightModelContext) => OrderUpdateGrantedPermissionSet.of(c.rightModel, c.currentUser, c.currentCompanies),
      () => OrderUpdateGrantedPermissionSet.empty(),
      context);
    this.orderNoteExtraRead = OrderRightModel.map(
      (c: OrderRightModelContext) => OrderNoteExtraReadGrantedPermissionSet.of(c.rightModel, c.currentUser, c.currentCompanies),
      () => OrderNoteExtraReadGrantedPermissionSet.empty(),
      context);
    this.orderNoteExtraUpdate = OrderRightModel.map(
      (c: OrderRightModelContext) => OrderNoteExtraUpdateGrantedPermissionSet.of(c.rightModel, c.currentUser, c.currentCompanies),
      () => OrderNoteExtraUpdateGrantedPermissionSet.empty(),
      context);
    this.orderNoteInternalRead = OrderRightModel.map(
      (c: OrderRightModelContext) => OrderNoteInternalReadGrantedPermissionSet.of(c.rightModel, c.currentUser, c.currentCompanies),
      () => OrderNoteInternalReadGrantedPermissionSet.empty(),
      context);
    this.orderNoteInternalUpdate = OrderRightModel.map(
      (c: OrderRightModelContext) => OrderNoteInternalUpdateGrantedPermissionSet.of(c.rightModel, c.currentUser, c.currentCompanies),
      () => OrderNoteInternalUpdateGrantedPermissionSet.empty(),
      context);
  }

}

class OrderGrantedPermissionSet implements GrantedPermissionSet {

  constructor(private delegate: GrantedPermissionSet,
              private currentUser?: UserProfile,
              private currentCompanies?: UserCompany[],
              private allPermission?: string,
              private minePermission?: string,
              private myCompanyPermission?: string) {
  }

  public hasRight(): boolean {
    return this.delegate.hasRight();
  }

  public contains(permission: PermissionDefinition): boolean {
    return this.delegate.contains(permission);
  }

  public hasRightForOrder(order?: Order.Order): boolean {
    if (this.hasAllPermission()) {
      return true;
    }
    if (this.hasMinePermission(order)) {
      return true;
    }
    if (this.hasMyCustomerPermission(order)) {
      return true;
    }
    return false;
  }

  private hasAllPermission(): boolean {
    if (!this.allPermission) {
      return false;
    }
    return this.delegate.contains(this.allPermission);
  }

  private hasMinePermission(order?: Order.Order): boolean {
    if (!order) {
      return false;
    }
    if (!this.currentUser) {
      return false;
    }
    if (!this.minePermission) {
      return false;
    }
    if (this.currentUser.id === order.ownerUserId) {
      return this.delegate.contains(this.minePermission);
    }
    return false;
  }

  private hasMyCustomerPermission(order?: Order.Order): boolean {
    if (!order) {
      return false;
    }
    if (!this.currentUser) {
      return false;
    }
    if (!this.myCompanyPermission) {
      return false;
    }
    if (this.currentCompanies && this.currentCompanies.find(c => c.id === order.ownerCompanyId)) {
      return this.delegate.contains(this.myCompanyPermission);
    }
    return false;
  }

}

export class OrderReadGrantedPermissionSet extends OrderGrantedPermissionSet {

  static empty(): OrderReadGrantedPermissionSet {
    return new OrderReadGrantedPermissionSet(GrantedPermissionSet.empty());
  }

  static of(rightModel: RightModel, currentUser?: UserProfile, currentCompanies?: UserCompany[]): OrderReadGrantedPermissionSet {
    return new OrderReadGrantedPermissionSet(
      rightModel.orderRead,
      currentUser,
      currentCompanies,
      PermissionDefinitions.ORDER_READ_ALL,
      PermissionDefinitions.ORDER_READ_MINE,
      PermissionDefinitions.ORDER_READ_MY_COMPANY);
  }

}

export class OrderUpdateGrantedPermissionSet extends OrderGrantedPermissionSet {

  static empty(): OrderUpdateGrantedPermissionSet {
    return new OrderUpdateGrantedPermissionSet(GrantedPermissionSet.empty());
  }

  static of(rightModel: RightModel, currentUser?: UserProfile, currentCompanies?: UserCompany[]): OrderUpdateGrantedPermissionSet {
    return new OrderUpdateGrantedPermissionSet(
      rightModel.orderUpdate,
      currentUser,
      currentCompanies,
      PermissionDefinitions.ORDER_UPDATE_ALL,
      PermissionDefinitions.ORDER_UPDATE_MINE,
      PermissionDefinitions.ORDER_READ_MY_COMPANY);
  }

}

export class OrderNoteExtraReadGrantedPermissionSet extends OrderGrantedPermissionSet {

  static empty(): OrderNoteExtraReadGrantedPermissionSet {
    return new OrderNoteExtraReadGrantedPermissionSet(GrantedPermissionSet.empty());
  }

  static of(rightModel: RightModel, currentUser?: UserProfile, currentCompanies?: UserCompany[]): OrderNoteExtraReadGrantedPermissionSet {
    return new OrderNoteExtraReadGrantedPermissionSet(
      rightModel.orderNoteExtraRead,
      currentUser,
      currentCompanies,
      PermissionDefinitions.ORDER_NOTE_EXTRA_READ_ALL,
      PermissionDefinitions.ORDER_NOTE_EXTRA_READ_MINE,
      PermissionDefinitions.ORDER_NOTE_EXTRA_READ_MY_COMPANY);
  }

}

export class OrderNoteExtraUpdateGrantedPermissionSet extends OrderGrantedPermissionSet {

  static empty(): OrderNoteExtraUpdateGrantedPermissionSet {
    return new OrderNoteExtraUpdateGrantedPermissionSet(GrantedPermissionSet.empty());
  }

  static of(rightModel: RightModel, currentUser?: UserProfile, currentCompanies?: UserCompany[]): OrderNoteExtraUpdateGrantedPermissionSet {
    return new OrderNoteExtraUpdateGrantedPermissionSet(
      rightModel.orderNoteExtraUpdate,
      currentUser,
      currentCompanies,
      PermissionDefinitions.ORDER_NOTE_EXTRA_UPDATE_ALL,
      PermissionDefinitions.ORDER_NOTE_EXTRA_UPDATE_MINE,
      PermissionDefinitions.ORDER_NOTE_EXTRA_UPDATE_MY_COMPANY);
  }

}

export class OrderNoteInternalReadGrantedPermissionSet extends OrderGrantedPermissionSet {

  static empty(): OrderNoteInternalReadGrantedPermissionSet {
    return new OrderNoteInternalReadGrantedPermissionSet(GrantedPermissionSet.empty());
  }

  static of(rightModel: RightModel, currentUser?: UserProfile, currentCompanies?: UserCompany[]):
    OrderNoteInternalReadGrantedPermissionSet {
    return new OrderNoteInternalReadGrantedPermissionSet(
      rightModel.orderNoteInternalRead,
      currentUser,
      currentCompanies,
      PermissionDefinitions.ORDER_NOTE_INTERNAL_READ_ALL,
      PermissionDefinitions.ORDER_NOTE_INTERNAL_READ_MINE,
      PermissionDefinitions.ORDER_NOTE_INTERNAL_READ_MY_COMPANY);
  }

}

export class OrderNoteInternalUpdateGrantedPermissionSet extends OrderGrantedPermissionSet {

  static empty(): OrderNoteInternalUpdateGrantedPermissionSet {
    return new OrderNoteInternalUpdateGrantedPermissionSet(GrantedPermissionSet.empty());
  }

  static of(rightModel: RightModel, currentUser?: UserProfile, currentCompanies?: UserCompany[]):
    OrderNoteInternalUpdateGrantedPermissionSet {
    return new OrderNoteInternalUpdateGrantedPermissionSet(
      rightModel.orderNoteInternalUpdate,
      currentUser,
      currentCompanies,
      PermissionDefinitions.ORDER_NOTE_INTERNAL_UPDATE_ALL,
      PermissionDefinitions.ORDER_NOTE_INTERNAL_UPDATE_MINE,
      PermissionDefinitions.ORDER_NOTE_INTERNAL_UPDATE_MY_COMPANY);
  }

}
