/* eslint-disable */
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Transition, UIRouter } from '@uirouter/angular';
import { TranslateService } from '@ngx-translate/core';
import { DocumentLanguageItem, DocumentTextEditModel } from '../../../../util/document/document-utils';
import { Arrays } from '../../../../lib/util/arrays';
import { StringKey } from '../../../../app.string-keys';
import { EmptyMessage } from '../../../../lib/util/messages';
import { DocumentLanguageService } from '../../../../lib/document/document-language.service';
import { DocumentLanguageUtil } from '../../../../util/document/document-language-utils';
import { DocumentGroupService } from '../../../../lib/document/document-group.service';
import { DocumentGroupItem, LanguageItem } from '../../../../util/document/document-file-utils';
import { DocumentLink, DocumentLinkService } from '../../../../lib/document/document-link.service';
import { BreadcrumbParent } from '../../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { DocumentGroup } from '../../../../lib/document/document-group.service';
import { UiConstants } from '../../../../util/core-utils';
import { ResourceQueryResult } from '../../../../lib/util/services';
import { StateName } from '../../../../app.state-names';
/* eslint-enable */

@Component({
  selector: 'app-document-link-edit',
  templateUrl: 'document-link-edit.component.html',
  styleUrls: ['document-link-edit.component.scss'],
})
export class DocumentLinkEditComponent implements OnInit, AfterViewInit {

  languageItems: LanguageItem[] = [];
  documentId: number;
  model: DocumentTextEditModel;
  documentGroupList: DocumentGroupItem[] = [];
  documentLanguageList: DocumentLanguageItem[] = [];
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  public options: Object = {
    placeholderText: '',
    charCounterCount: false,
    toolbarButtons: ['undo', 'redo', '|', 'bold', 'italic', 'underline', 'strikeThrough', 'outdent', 'indent', 'clearFormatting'],
    quickInsertButtons: [],
    pluginsEnabled: []
  };

  constructor(private documentLanguageService: DocumentLanguageService,
              private documentLanguageUtil: DocumentLanguageUtil,
              private translateService: TranslateService,
              private documentGroupService: DocumentGroupService,
              private documentService: DocumentLinkService,
              private uiRouter: UIRouter,
              private transition: Transition) {
    this.documentId = this.transition.params().id;
    this.model = new DocumentTextEditModel();
  }

  ngOnInit() {
    this.translateService.get('COMMON_DOCUMENTS').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.DOCUMENT_DASHBOARD});
      }
    );
    this.translateService.get('MENU_NAVBAR_DOCUMENT_LINK').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.DOCUMENT_LINK_LIST});
      }
    );
  }

  ngAfterViewInit(): void {
    this.loadModel();
  }

  private loadModel() {
    this.documentService.get({id: this.documentId})
      .subscribe(
      (documentLink: DocumentLink) => {
        this.breadcrumbSelf = documentLink.name;
        const readModel = new DocumentTextEditModel();
        readModel.id = documentLink.id;
        readModel.name = documentLink.name;
        readModel.code = documentLink.code;
        readModel.value = documentLink.value;
        readModel.comment = documentLink.comment;
        readModel.creation_time = documentLink.creation_time;
        readModel.update_time = documentLink.update_time;
        readModel.disabled = documentLink.disabled;
        this.model = readModel;

        this.loadDocumentGroupList(documentLink.document_group_id);
        this.loadLanguageCodes(documentLink.language_code);
      }
    );
  }

  private loadLanguageCodes(language_code?: string) {
    this.documentLanguageService.query().subscribe((result: string[]) => {
      const def = this.createDefaultLanguageCode();
      this.model.language_code = def;
      this.documentLanguageList.push(def);

      Arrays.iterateByIndex(result, language => {
        const languageItem = new DocumentLanguageItem();
        languageItem.id = language;
        languageItem.text = this.documentLanguageUtil.getDocumentLanguageName(language);

        if (languageItem.id === language_code) {
          this.model.language_code = languageItem;
        }
        this.documentLanguageList.push(languageItem);
      });
    });
  }

  private loadDocumentGroupList(groupId?: number) {
    this.documentGroupService.query({}).subscribe((result: ResourceQueryResult<DocumentGroup>) => {
      const def = this.createDefaultDocumentGroup();
      this.model.document_group = def;
      this.documentGroupList.push(def);
      Arrays.iterateByIndex(result.items, documentGroup => {
        const documentGroupItem = new DocumentGroupItem();
        documentGroupItem.id = documentGroup.id;
        documentGroupItem.text = documentGroup.name;
        if (documentGroup.id === groupId) {
          this.model.document_group = documentGroupItem;
        }
        this.documentGroupList.push(documentGroupItem);
      });
    });
  }

  private createDefaultDocumentGroup(): DocumentGroupItem {
    const def = new DocumentGroupItem();
    def.id = null;
    def.text = '';
    this.translateService.get(StringKey.COMMON_VALUE_UNSELECTED).subscribe(
      (text: string) => {
        def.text = text;
      }
    );
    return def;
  }

  private createDefaultLanguageCode(): LanguageItem {
    const def = new LanguageItem();
    def.id = null;
    def.text = '';
    this.translateService.get(StringKey.COMMON_VALUE_UNSELECTED).subscribe(
      (text: string) => {
        def.text = text;
      }
    );
    return def;
  }

  getTextMaximumLength(): number {
    return UiConstants.maximumVarcharLength;
  }

  update() {
    if (!this.model.id) {
      return;
    }

    const code = (this.model.code && this.model.code.length > 0) ? this.model.code : null;
    const languageCodeId = (this.model.language_code) ? this.model.language_code.id : null;
    const document_group_id = (this.model.document_group) ? this.model.document_group.id : null;

    this.documentService.update({
      id: this.model.id,
      name: this.model.name,
      code: code ? code : undefined,
      value: this.model.value,
      language_code: languageCodeId ? languageCodeId : undefined,
      document_group_id: document_group_id ? document_group_id : undefined,
      comment: this.model.comment

    }).subscribe(
      (response: EmptyMessage) => {
        this.uiRouter.stateService.go(StateName.DOCUMENT_LINK_LIST);
      }
    );
  }

  back() {
    window.history.back();
  }
}
