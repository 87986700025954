/* eslint-disable */
import { Injectable } from '@angular/core';
import { List, Map as ImmutableMap, Set } from 'immutable';
import { Observable, Observer } from 'rxjs';
import { FieldValidationError, Order, PagingRequest, QueryResult, ResourceQueryResult, Services } from '../../util/services';
import { LocalDate, OffsetDateTime, } from '../../util/dates';
import { Form } from '../../form/form.service';
import { FormRecord } from '../../form/form-record.service';
import { ObservableErrorResourceParser } from '../../util/errors';
import { ProjectRecordGlobalResourceService, ProjectRecordResource, ProjectRecordResourceService } from './project-record-resource.service';
import { Icon, IconService } from '../../task/icon.service';
import { LocalizedTypeObject } from '../../../util/core-utils';
import { Address, AddressResource } from '../../address';
import { TaskRecordStateMachine } from '../../task/task-record-statemachine';
import { Task } from '../../task/task.service';
import { DisableRequest as GlobalDisableRequest, EmptyMessage, IdentityMessage } from '../../util/messages';
import { DownloadedFile } from '../../util/downloaded-files';

/* eslint-enable */

@Injectable()
export class ProjectRecordService implements ProjectRecord.Service {

  private formRecordMapper: FormRecord.ResourceMapper;
  private formMapper: Form.ResourceMapper;

  constructor(
    private resourceService: ProjectRecordResourceService,
    private globalResourceService: ProjectRecordGlobalResourceService,
    private iconService: IconService
  ) {
    this.formMapper = new Form.ResourceMapper();
    this.formRecordMapper = new FormRecord.ResourceMapper(this.formMapper);

  }

  // <editor-fold desc="CRUD">

  globalQuery(request: ProjectRecord.GlobalQueryRequest): Observable<QueryResult<ProjectRecord.ProjectRecord>> {
    return Observable.create((observer: Observer<QueryResult<ProjectRecord.ProjectRecord>>) => {
      const resourceRequest: ProjectRecordResource.GlobalQueryRequest = {
        q: request.queryText,
        order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
        no_progress_bar: request.noProgressBar,
        with_form_record: this.toResourceArgumentWithFormRecord(request.withFormRecord),
        rights: Services.createListParameter(request.rights),

        id: Services.createIdParameter(request.projectRecordIdSet),
        name: request.name,
        disabled: request.disabled,
        external_id: request.externalId,
        start_date_from: Services.localDateToString(request.startDateFrom),
        start_date_to: Services.localDateToString(request.startDateTo),
        end_date_from: Services.localDateToString(request.endDateFrom),
        end_date_to: Services.localDateToString(request.endDateTo),
        assignee_user_id: request.assigneeUserId,
        postal_address: request.postalAddress,
      };
      return this.globalResourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<ProjectRecordResource.ProjectRecord>) => {
          observer.next({
            items: this.toPublicList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  query(request: ProjectRecord.QueryRequest): Observable<QueryResult<ProjectRecord.ProjectRecord>> {
    return Observable.create((observer: Observer<QueryResult<ProjectRecord.ProjectRecord>>) => {
      const resourceRequest: ProjectRecordResource.QueryRequest = {
        project_id: request.projectId,

        q: request.queryText,
        order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
        no_progress_bar: request.noProgressBar,
        with_form_record: this.toResourceArgumentWithFormRecord(request.withFormRecord),
        rights: Services.createListParameter(request.rights),

        id: Services.createIdParameter(request.projectRecordIdSet),
        name: request.name,
        disabled: request.disabled,
        external_id: request.externalId,
        dql: request.dqlText,
        start_date_from: Services.localDateToString(request.startDateFrom),
        start_date_to: Services.localDateToString(request.startDateTo),
        end_date_from: Services.localDateToString(request.endDateFrom),
        end_date_to: Services.localDateToString(request.endDateTo),
        assignee_user_id: request.assigneeUserId,
        postal_address: request.postalAddress,
      };
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<ProjectRecordResource.ProjectRecord>) => {
          observer.next({
            items: this.toPublicList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  get(request: ProjectRecord.GetRequest): Observable<ProjectRecord.ProjectRecord> {
    return Observable.create((observer: Observer<ProjectRecord.ProjectRecord>) => {
      const resourceRequest: ProjectRecordResource.GetRequest = {
        project_id: request.projectId,
        id: request.id,
        rights: Services.createListParameter(request.rights),
      };
      return this.resourceService.get(resourceRequest).subscribe(
        (result: ProjectRecordResource.ProjectRecord) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  globalGet(request: ProjectRecord.GlobalGetRequest): Observable<ProjectRecord.ProjectRecord> {
    return Observable.create((observer: Observer<ProjectRecord.ProjectRecord>) => {
      return this.globalResourceService.get({
        id: request.id, rights: Services.createListParameter(request.rights),
      }).subscribe(
        (result: ProjectRecordResource.ProjectRecord) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  create(request: ProjectRecord.CreateRequest): Observable<IdentityMessage> {
    return Observable.create((observer: Observer<IdentityMessage>) => {
      const resourceRequest: ProjectRecordResource.CreateRequest = {
        project_id: request.projectId,
        name: request.name,
        external_id: request.externalId,
        start_date: Services.localDateToString(request.startDate),
        end_date: Services.localDateToString(request.endDate),
        amount: request.amount,
        billing_start_date: Services.localDateToString(request.billingStartDate),
        billing_end_date: Services.localDateToString(request.billingEndDate),
        billing_type: request.billingType ? request.billingType.toString() : undefined,
        billing_period_in_months: request.billingPeriodInMonths,
        assignee_user_id: request.assigneeUserId,
        postal_address: AddressResource.Mapper.fromPublicPostalAddressOpt(request.postalAddress),
        form_record: this.formRecordMapper.toResourceCreateRequest(request.formRecord)
      };
      return this.resourceService.create(resourceRequest).subscribe(
        (result: IdentityMessage) => {
          observer.next(result);
        },
        (error: any) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  update(request: ProjectRecord.UpdateRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: ProjectRecordResource.UpdateRequest = {
        project_id: request.projectId,
        id: request.id,
        name: request.name,
        external_id: request.externalId,
        start_date: Services.localDateToString(request.startDate),
        end_date: Services.localDateToString(request.endDate),
        amount: request.amount,
        billing_start_date: Services.localDateToString(request.billingStartDate),
        billing_end_date: Services.localDateToString(request.billingEndDate),
        billing_type: request.billingType ? request.billingType.toString() : undefined,
        billing_period_in_months: request.billingPeriodInMonths,
        assignee_user_id: request.assigneeUserId,
        postal_address: AddressResource.Mapper.fromPublicPostalAddressOpt(request.postalAddress),
        form_record: this.formRecordMapper.toResourceUpdateRequest(request.formRecord)
      };
      return this.resourceService.update(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  setDisabled(request: ProjectRecord.DisableRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: ProjectRecordResource.DisableRequest = {
        project_id: request.projectId,
        id: request.id,
        disabled: request.disabled
      };
      return this.resourceService.setDisabled(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  globalSetDisabled(request: GlobalDisableRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: GlobalDisableRequest = {
        id: request.id,
        disabled: request.disabled
      };
      return this.globalResourceService.setDisabled(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  statistics(request: ProjectRecord.StatisticsRequest): Observable<QueryResult<ProjectRecord.ProjectRecord>> {
    return Observable.create((observer: Observer<QueryResult<ProjectRecord.ProjectRecord>>) => {
      const resourceRequest: ProjectRecordResource.StatisticsRequest = {
        statistics_type: request.statisticsType,
        disabled: request.disabled
      };
      return this.globalResourceService.statistics(resourceRequest).subscribe(
        (result: ResourceQueryResult<ProjectRecordResource.ProjectRecord>) => {
          observer.next({
            items: this.toPublicList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  invoiceStatistics(request: EmptyMessage): Observable<QueryResult<ProjectRecord.InvoiceStatistic>> {
    return Observable.create((observer: Observer<QueryResult<ProjectRecord.InvoiceStatistic>>) => {
      return this.globalResourceService.invoiceStatistics(request).subscribe(
        (result: ResourceQueryResult<ProjectRecordResource.InvoiceStatistic>) => {
          observer.next({
            items: this.toPublicInvoiceStatisticList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  exportXls(request: ProjectRecord.ExportXlsRequest): Observable<DownloadedFile> {
    return this.resourceService.exportXls({
      project_id: request.projectId,
      id: request.ids.join()
    });
  }

  // </editor-fold>

  // <editor-fold desc="Internal">

  private toResourceArgumentWithFormRecord(withFormRecord?: boolean): boolean {
    return withFormRecord === undefined ? false : withFormRecord;
  }

  private translateError(error: any): any {
    const res = ObservableErrorResourceParser.parseError(error);
    const fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
    const fieldErrorMap = ObservableErrorResourceParser.toFieldErrorMap(Keys.toValidatedField, fieldErrors);
    if (!fieldErrorMap.isEmpty()) {
      return FieldValidationError.of(fieldErrorMap);
    }
    return error;
  }

  private toPublicList(resourceList: ProjectRecordResource.ProjectRecord[]): List<ProjectRecord.ProjectRecord> {
    return List.of(...resourceList.map((r) => this.toPublic(r)));
  }

  private toPublic(r: ProjectRecordResource.ProjectRecord): ProjectRecord.ProjectRecord {
    return {
      id: r.id,
      name: r.name,
      externalId: r.external_id,
      project: {
        id: r.project.id,
        name: r.project.name,
        icon: this.iconService.toPublicIcon(r.project.icon),
        displayedFormField: r.project.displayed_form_field ? this.formMapper.toPublicField(r.project.displayed_form_field) : undefined,
      },
      startDate: Services.toLocalDate(r.start_date),
      endDate: Services.toLocalDate(r.end_date),
      billingStartDate: Services.toLocalDate(r.billing_start_date),
      billingEndDate: Services.toLocalDate(r.billing_end_date),
      billingType: <ProjectRecord.BillingType>r.billing_type,
      billingPeriodInMonths: r.billing_period_in_months,
      amount: r.amount,
      creationTime: Services.toOffsetDateTime(r.creation_time),
      updateTime: Services.toOffsetDateTime(r.update_time),
      updateCount: r.update_count,
      disabled: r.disabled,
      statistics: this.toPublicStatisticsMap(r.statistics),
      assigneeUser: r.assignee_user ? {
        id: r.assignee_user.id,
        userName: r.assignee_user.user_name,
        personName: r.assignee_user.person_name
      } : undefined,
      postalAddress: AddressResource.Mapper.toPublicPostalAddressOpt(r.postal_address),
      formRecord: this.formRecordMapper.toPublic(r.form_record),
      displayedFormFieldRecord: r.displayed_form_field_record
        ? this.formRecordMapper.toPublicField(r.displayed_form_field_record)
        : undefined,
      grantedRights: r.granted_rights ? Set.of(...r.granted_rights) : Set.of(),
    };
  }

  private toPublicStatisticsMap(r?: Map<string, number>): Map<TaskRecordStateMachine.State, number> | undefined {
    if (r) {
      const result = new Map<TaskRecordStateMachine.State, number>();
      for (const pair of r) {
        result.set(<TaskRecordStateMachine.State>pair[0], pair[1]);
      }
      return result;
    }
    return undefined;
  }

  private toPublicInvoiceStatisticList(resourceList: ProjectRecordResource.InvoiceStatistic[]): List<ProjectRecord.InvoiceStatistic> {
    return List.of(...resourceList.map((r) => this.toPublicInvoiceStatistic(r)));
  }

  private toPublicInvoiceStatistic(r: ProjectRecordResource.InvoiceStatistic): ProjectRecord.InvoiceStatistic {
    return {
      projectId: r.project_id,
      projectName: r.project_name,
      taskRecordCount: r.task_record_count,
      netSum: r.net_sum,
      grossSum: r.gross_sum
    };
  }

  // </editor-fold>

}

export namespace ProjectRecord {

  import TaskStatisticsType = Task.TaskStatisticsType;

  export interface Service {

    // <editor-fold desc="CRUD">

    globalQuery(request: GlobalQueryRequest): Observable<QueryResult<ProjectRecord>>;

    query(request: QueryRequest): Observable<QueryResult<ProjectRecord>>;

    globalGet(request: IdentityMessage): Observable<ProjectRecord>;

    get(request: GetRequest): Observable<ProjectRecord>;

    create(request: CreateRequest): Observable<IdentityMessage>;

    update(request: UpdateRequest): Observable<EmptyMessage>;

    setDisabled(request: DisableRequest): Observable<EmptyMessage>;

    globalSetDisabled(request: GlobalDisableRequest): Observable<EmptyMessage>;

    statistics(request: StatisticsRequest): Observable<QueryResult<ProjectRecord>>;

    invoiceStatistics(request: EmptyMessage): Observable<QueryResult<InvoiceStatistic>>;

    // </editor-fold>

  }

  // <editor-fold desc="CRUD">

  export interface GlobalQueryRequest {
    queryText?: string;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
    noProgressBar?: boolean;
    withFormRecord?: boolean;
    rights?: Set<string>;

    projectRecordIdSet?: Set<number>;
    name?: string;
    disabled?: boolean;
    externalId?: string;
    dqlText?: string;
    startDateFrom?: LocalDate;
    startDateTo?: LocalDate;
    endDateFrom?: LocalDate;
    endDateTo?: LocalDate;
    assigneeUserId?: number;
    postalAddress?: string;
  }

  export interface QueryRequest extends GlobalQueryRequest {
    projectId: number
  }

  export interface GlobalGetRequest {
    id: number;
    rights?: Set<string>;
  }
  export interface GetRequest extends GlobalGetRequest {
    projectId: number;
  }

  export interface BaseRequest {
    projectId: number;
    name: string;
    startDate?: LocalDate;
    endDate?: LocalDate;
    amount?: number;
    billingStartDate?: LocalDate;
    billingEndDate?: LocalDate;
    billingType?: BillingType;
    billingPeriodInMonths?: number;
    assigneeUserId?: number;
    postalAddress?: Address.PostalAddressData;
  }

  export interface CreateRequest extends BaseRequest {
    externalId?: string;
    formRecord: FormRecord.FormRecordCreateRequest;
  }

  export interface UpdateRequest extends BaseRequest {
    id: number;
    externalId: string;
    formRecord: FormRecord.FormRecordUpdateRequest;
  }

  export interface DisableRequest {
    projectId: number;
    id: number;
    disabled: boolean;
  }

  export interface ProjectRecord {
    id: number;
    name: string;
    externalId: string;
    project: {
      id: number;
      name: string;
      icon?: Icon.Icon;
      displayedFormField?: Form.Field;
    };
    startDate?: LocalDate;
    endDate?: LocalDate;
    billingStartDate?: LocalDate;
    billingEndDate?: LocalDate;
    billingType?: BillingType;
    billingPeriodInMonths?: number;
    amount?: number;
    creationTime: OffsetDateTime;
    updateTime: OffsetDateTime;
    updateCount: number;
    disabled: boolean;
    statistics?: Map<TaskRecordStateMachine.State, number>;
    assigneeUser?: {
      id: number;
      userName: string;
      personName: string;
    };
    postalAddress?: Address.PostalAddressData;
    formRecord?: FormRecord.FormRecord;
    displayedFormFieldRecord?: FormRecord.Field;
    grantedRights: Set<string>;
  }

  export interface StatisticsRequest {
    statisticsType?: TaskStatisticsType;
    disabled?: boolean;
  }

  export interface InvoiceStatistic {
    projectId: number;
    projectName: string;
    taskRecordCount: number;
    netSum: number;
    grossSum: number;
  }

  export interface ExportXlsRequest {
    projectId: number;
    ids: Set<number>;
  }

  export enum BillingType {
    ONE_TIME = 'ONE_TIME',
    PERMANENT = 'PERMANENT',
    UNKNOWN = 'UNKNOWN',
  }

  export const billingTypes: LocalizedTypeObject<BillingType>[] = [
    {type: BillingType.ONE_TIME, stringKey: 'PROJECT_RECORD_BILLING_TYPE_ONE_TIME'},
    {type: BillingType.PERMANENT, stringKey: 'PROJECT_RECORD_BILLING_TYPE_PERMANENT'},
    {type: BillingType.UNKNOWN, stringKey: 'PROJECT_RECORD_BILLING_TYPE_UNKNOWN'},
  ];

  export enum OrderField {
    ID,
    EXTERNAL_ID,
    NAME,
    START_DATE,
    END_DATE,
    AMOUNT,
  }

  export enum ValidatedField {
    UNKNOWN,
    NAME,
    EXTERNAL_ID
  }

  // </editor-fold>

}

// <editor-fold desc="Internal">

class Keys {

  private static readonly ID = 'id';
  private static readonly EXTERNAL_ID = 'external_id';
  private static readonly NAME = 'name';
  private static readonly START_DATE = 'start_date';
  private static readonly END_DATE = 'end_date';
  private static readonly AMOUNT = 'amount';

  private static readonly orderFieldKeyMap: ImmutableMap<ProjectRecord.OrderField, string> = ImmutableMap.of(
    ProjectRecord.OrderField.ID, Keys.ID,
    ProjectRecord.OrderField.EXTERNAL_ID, Keys.EXTERNAL_ID,
    ProjectRecord.OrderField.NAME, Keys.NAME,
    ProjectRecord.OrderField.START_DATE, Keys.START_DATE,
    ProjectRecord.OrderField.END_DATE, Keys.END_DATE,
    ProjectRecord.OrderField.AMOUNT, Keys.AMOUNT,
  );

  private static readonly keyValidatedFieldMap: ImmutableMap<string, ProjectRecord.ValidatedField> = ImmutableMap.of(
    Keys.NAME, ProjectRecord.ValidatedField.NAME,
    Keys.EXTERNAL_ID, ProjectRecord.ValidatedField.EXTERNAL_ID,
  );

  public static toOrderFieldKey(field: ProjectRecord.OrderField): string {
    return Keys.orderFieldKeyMap.get(field)!;
  }

  public static toValidatedField(fieldKey: string): ProjectRecord.ValidatedField {
    return Keys.keyValidatedFieldMap.get(fieldKey, ProjectRecord.ValidatedField.UNKNOWN);
  }

}

// </editor-fold>
