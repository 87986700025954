import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { ComponentStateResolver } from '../../../util/component-state/component-state-resolver';
import { TranslateService } from '@ngx-translate/core';
import { StateName } from '../../../app.state-names';
import { Transition, UIRouter } from '@uirouter/angular';
import { GrantedPermissionSetResolver, RightResolver, RightService } from '../../../lib/right.service';
import { MultiselectOptionItem, UiConstants } from '../../../util/core-utils';
import { ShopRenterRightModel } from '../../../lib/shoprenter/shop-renter-right.model';
import { ShopRenter, ShopRenterService } from '../../../lib/shoprenter/shoprenter.service';
import { CompanyMultiselectProvider } from '../../../lib/company/company-multiselect.provider';
import { Angular2Multiselects } from '../../../util/multiselect';
import { List, Set } from 'immutable';
import { ToasterService } from '../../../fork/angular2-toaster/src/toaster.service';
import { FieldError, FieldErrors, ObservableErrorResourceParser } from '../../../lib/util/errors';
import {
  LocalFieldValidationErrors,
  LocalFieldValidationErrorsFactory,
  OrderType,
  QueryResult
} from '../../../lib/util/services';
import { NgForm, NgModel } from '@angular/forms';
import { Arrays } from '../../../lib/util/arrays';
import { RightModel } from '../../../app.rights';
import { Company, CompanyService } from '../../../lib/company/company.service';
import { DeliveryMethod, DeliveryMethodService } from '../../../lib/delivery-method/delivery-method.service';
import { CriteriaBuilder } from '../../../util/model-utils';
import { FilterField } from '../../../lib/query/filterfields';
import ShippingModeUpdate = ShopRenter.ShippingModeUpdate;

@Component({
  selector: 'app-shop-renter-shop-base',
  templateUrl: './shop-renter-shop-base.component.html',
  styleUrls: ['./shop-renter-shop-base.component.scss']
})
export class ShopRenterShopBaseComponent implements OnInit, AfterViewInit {

  UiConstants = UiConstants;

  // Variables used for breadcrumb
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  // Component state resolver, determines the state of the component
  componentState: ComponentStateResolver;

  model: ShopRenterShopModel = new ShopRenterShopModel();
  rightModel: RightModel = RightModel.empty();
  fieldErrors: ShopRenter.ShopFieldErrorMap;

  companies: MultiselectOptionItem<number>[] = [];
  shippingModes: MultiselectOptionItem<number>[] = [];
  dropdownSettings: Angular2Multiselects.Settings;
  remoteDropdownSettings: Angular2Multiselects.Settings;
  multiDropdownSettings: Angular2Multiselects.Settings;

  shopId: number;

  private validatedInputs: LocalFieldValidationErrors<NgModel> =
    LocalFieldValidationErrorsFactory.empty();

  // Form for validation
  @ViewChild('f', { static: true })
  fForm: NgForm;

  // Validated inputs
  @ViewChild('importableOrderStatuses')
  importableOrderStatuses: NgModel;

  @ViewChild('importedOrderStatus')
  importedOrderStatus: NgModel;

  @ViewChild('invalidOrderOrderStatus')
  invalidOrderOrderStatus: NgModel;

  @ViewChild('insufficientAmountOrderStatus')
  insufficientAmountOrderStatus: NgModel;

  @ViewChild('returnedOrderStatus')
  returnedOrderStatus: NgModel;

  @ViewChild('canceledOrderStatus')
  canceledOrderStatus: NgModel;

  @ViewChild('deliveredOrderStatus')
  deliveredOrderStatus: NgModel;

  @ViewChild('rejectedOrderStatus')
  rejectedOrderStatus: NgModel;

  constructor(
    private shopRenterService: ShopRenterService,
    private companyService: CompanyService,
    private companyMultiselectProvider: CompanyMultiselectProvider,
    private deliveryMethodService: DeliveryMethodService,
    private translateService: TranslateService,
    private uiRouter: UIRouter,
    private rightService: RightService,
    private transition: Transition,
    private toasterService: ToasterService
  ) {
    this.shopId = this.transition.params().id;
    this.componentState = new ComponentStateResolver(uiRouter, transition,
      'id',
      undefined,
      {stateName: StateName.SHOP_RENTER_SHOP_EDIT, stateHeaderKey: 'SHOP_RENTER_SHOP_EDIT'},
      {stateName: StateName.SHOP_RENTER_SHOP_DETAIL, stateHeaderKey: 'SHOP_RENTER_SHOP_DETAIL'});
    this.fieldErrors = {};
  }

  ngOnInit() {
    this.initBreadcrumb();
    this.initDropdown();
    this.loadModel();
    this.loadCompanies();
    this.loadRightModels();
  }

  ngAfterViewInit() {
    this.loadLocalFieldValidationErrors();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  private loadLocalFieldValidationErrors() {
    const validatedInputs = List.of(this.importableOrderStatuses,
      this.importedOrderStatus,
      this.invalidOrderOrderStatus,
      this.insufficientAmountOrderStatus,
      this.returnedOrderStatus,
      this.canceledOrderStatus,
      this.deliveredOrderStatus,
      this.rejectedOrderStatus
    );
    this.validatedInputs = LocalFieldValidationErrorsFactory.ofFormFields(this.fForm, validatedInputs);
  }

  initBreadcrumb() {
    this.breadcrumbSelf = this.componentState.id!.toString();
    this.translateService.get('SHOP_RENTER_SHOP_LIST').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.SHOP_RENTER_SHOP_LIST});
      }
    );
  }

  private initDropdown() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(false)
      .build();
    this.remoteDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
    this.multiDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .remoteSearch(false)
      .build();
  }

  private loadModel() {
    this.shopRenterService.get({
      id: this.componentState.id!
    }).subscribe((result: ShopRenter.ShopRenterShop) => {
      this.model.id = this.componentState.id!;
      this.model.rights = new ShopRenterRightModel(GrantedPermissionSetResolver.byGrantedRights(result.grantedRights));
      this.model.name = result.name;
      this.model.humanName = result.humanName ? result.humanName : '';
      this.model.userPersonName = result.user ? result.user.personName : '';
      this.model.importActive = result.importActive;
      Arrays.clear(this.model.company);
      if (result.company) {
        this.model.company.push({
          id: result.company.id,
          itemName: result.company.name
        });
      }
      this.model.orderStatuses = result.orderStatuses.map(s => {
        return {
          id: s.id,
          itemName: s.name
        };
      });
      this.shippingModes = result.shippingModes.map(m => ({id: m.id, itemName: m.name}));
      this.model.shippingModes = result.shippingModes;
      this.loadTransporterCompanies();
      this.model._importableOrderStatuses = this.model.orderStatuses.filter(s => result.importableOrderStatusIds.has(s.id));
      this.model._importedOrderStatus = this.model.orderStatuses.filter(s => result.importedOrderStatusId === s.id);
      this.model._unknownProductOrderStatus = this.model.orderStatuses.filter(s => result.unknownProductOrderStatusId === s.id);
      this.model._invalidOrderOrderStatus = this.model.orderStatuses.filter(s => result.invalidOrderOrderStatusId === s.id);
      this.model._insufficientAmountOrderStatus = this.model.orderStatuses.filter(s => result.insufficientAmountOrderStatusId === s.id);
      this.model._canceledOrderStatus = this.model.orderStatuses.filter(s => result.canceledOrderStatusId === s.id);
      this.model._returnedOrderStatus = this.model.orderStatuses.filter(s => result.returnedOrderStatusId === s.id);
      this.model._deliveredOrderStatus = this.model.orderStatuses.filter(s => result.deliveredOrderStatusId === s.id);
      this.model._rejectedOrderStatus = this.model.orderStatuses.filter(s => result.rejectedOrderStatusId === s.id);
      this.model._reopenedOrderStatus = this.model.orderStatuses.filter(s => result.reopenedOrderStatusId === s.id);
      this.model._processStartedOrderStatus = this.model.orderStatuses.filter(s => result.processStartedOrderStatusId === s.id);
      this.model._processFinishedOrderStatus = this.model.orderStatuses.filter(s => result.processFinishedOrderStatusId === s.id);
    });
  }

  private loadTransporterCompanies() {
    if (this.model.companyId) {
      this.companyService.query({
        disabled: false,
        type: Set.of(<Company.CompanyType>'TRANSPORTER'),
        demanderCompanyIds: Set.of(this.model.companyId),
        orders: Set.of({field: Company.OrderField.NAME, type: OrderType.ASC})
      }).subscribe((result: QueryResult<Company.Company>) => {
        this.model.transporterCompanies = result.items.toArray().map(c => ({id: c.id, itemName: c.name, deliveryMethods: []}));
        this.loadDeliveryMethods();
      });
    }
    else {
      this.model.transporterCompanies = [];
    }
  }

  private loadDeliveryMethods() {
    if (this.model.transporterCompanies.length > 0) {
      const transporterCompanyIds = List.of(...this.model.transporterCompanies.map(c => c.id));
      this.deliveryMethodService.query({
        fields: f => Set.of(f.id, f.name, f.transporterCompany),
        order: f => List.of(f.name.asc().nullsLast()),
        filter: (f: FilterField.DeliveryMethod) => CriteriaBuilder.builder()
          .addNumberList((id) => f.transporterCompany.id.in(id), transporterCompanyIds)
          .addBoolean(() => f.disabled.isFalse())
          .build()
      }).subscribe((result: QueryResult<DeliveryMethod.DeliveryMethod>) => {
        this.model.transporterCompanies.forEach(c => {
          c.deliveryMethods = result.items.toArray()
            .filter(m => m.transporterCompany!.id === c.id)
            .map(m => ({id: m.id, itemName: m.name, shippingMode: []}));
        });
        this.model.transporterCompanies = this.model.transporterCompanies.filter(c => c.deliveryMethods.length > 0);

        this.model.shippingModes.forEach(mode => {
          if (mode.deliveryMethod) {
            for (const company of this.model.transporterCompanies) {
              let found: boolean = false;
              for (const method of company.deliveryMethods) {
                method.shippingMode = [];
                if (method.id === mode.deliveryMethod.id) {
                  method.shippingMode.push({
                    id: mode.id,
                    itemName: mode.name
                  });
                  found = true;
                  break;
                }
              }
              if (found) {
                break;
              }
            }
          }
        });
      });
    }
  }

  loadCompanies(q?: string) {
    this.companyMultiselectProvider.searchCompanies(q).subscribe((result) => {
      this.companies = result;
    });
  }

  sync() {
    this.shopRenterService.sync({
      id: this.shopId
    })
    .subscribe(
      (result) => {
        this.loadModel();
      }
    );
  }

  import() {
    this.shopRenterService.import({
      id: this.shopId
    })
      .subscribe(
        (result) => {
          this.loadModel();
        }
      );
  }

  update() {
    if (this.hasLocalFieldError()) {
      return;
    }
    const shippingModes: ShippingModeUpdate[] = [];
    this.model.transporterCompanies.forEach(c => {
      c.deliveryMethods.forEach(m => {
        if (m.shippingMode.length > 0) {
          shippingModes.push({
            shopRenterShippingModeId: m.shippingMode[0].id,
            deliveryMethodId: m.id
          });
        }
      });
    });
    this.shopRenterService.update({
      id: this.shopId,
      humanName: this.model.humanName,
      companyId: this.model.companyId,
      importActive: this.model.importActive,
      shippingModes: shippingModes,
      importableOrderStatusIds: this.model.importableOrderStatusIds,
      importedOrderStatusId: this.model.importedOrderStatusId!,
      unknownProductOrderStatusId: this.model.unknownProductOrderStatusId!,
      invalidOrderOrderStatusId: this.model.invalidOrderOrderStatusId!,
      insufficientAmountOrderStatusId: this.model.insufficientAmountOrderStatusId!,
      canceledOrderStatusId: this.model.canceledOrderStatusId!,
      returnedOrderStatusId: this.model.returnedOrderStatusId!,
      deliveredOrderStatusId: this.model.deliveredOrderStatusId!,
      rejectedOrderStatusId: this.model.rejectedOrderStatusId!,
      reopenedOrderStatusId: this.model.reopenedOrderStatusId,
      processStartedOrderStatusId: this.model.processStartedOrderStatusId,
      processFinishedOrderStatusId: this.model.processFinishedOrderStatusId,
    }).subscribe(result => {
        this.uiRouter.stateService.go(StateName.SHOP_RENTER_SHOP_LIST);
    },
      (error: any) => {
        const res = ObservableErrorResourceParser.parseError(error);
        this.fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
      });
  }

  hasLocalFieldError(field?: NgModel): boolean {
    return this.validatedInputs.hasLocalError(field);
  }

  removeFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.fieldErrors, fieldError);
  }

}

class ShopRenterShopModel {

  id: number;
  rights: ShopRenterRightModel = new ShopRenterRightModel(GrantedPermissionSetResolver.empty());
  name: string = '';
  humanName: string = '';
  userPersonName: string = '';
  company: MultiselectOptionItem<number>[] = [];
  importActive: boolean = false;
  orderStatuses: MultiselectOptionItem<number>[] = [];
  shippingModes: ShopRenter.ShippingMode[] = [];
  transporterCompanies: TransporterCompanyItem[] = [];
  _importableOrderStatuses: MultiselectOptionItem<number>[] = [];
  _importedOrderStatus: MultiselectOptionItem<number>[] = [];
  _unknownProductOrderStatus: MultiselectOptionItem<number>[] = [];
  _invalidOrderOrderStatus: MultiselectOptionItem<number>[] = [];
  _insufficientAmountOrderStatus: MultiselectOptionItem<number>[] = [];
  _canceledOrderStatus: MultiselectOptionItem<number>[] = [];
  _returnedOrderStatus: MultiselectOptionItem<number>[] = [];
  _deliveredOrderStatus: MultiselectOptionItem<number>[] = [];
  _rejectedOrderStatus: MultiselectOptionItem<number>[] = [];
  _reopenedOrderStatus: MultiselectOptionItem<number>[] = [];
  _processStartedOrderStatus: MultiselectOptionItem<number>[] = [];
  _processFinishedOrderStatus: MultiselectOptionItem<number>[] = [];

  get companyName() {
    return this.company.length === 0 ? undefined : this.company[0].itemName;
  }

  get companyId() {
    return this.company.length === 0 ? undefined : this.company[0].id;
  }

  get importableOrderStatusIds(): Set<number> {
    return this._importableOrderStatuses.length === 0 ? Set.of() : Set.of(...this._importableOrderStatuses.map(i => i.id));
  }

  get importableOrderStatusNames(): string | undefined {
    return this._importableOrderStatuses.length === 0 ? undefined : this._importableOrderStatuses.map(i => i.itemName).join(', ');
  }

  get importedOrderStatusId(): number | undefined {
    return this._importedOrderStatus.length === 0 ? undefined : this._importedOrderStatus[0].id;
  }

  get importedOrderStatusName(): string | undefined {
    return this._importedOrderStatus.length === 0 ? undefined : this._importedOrderStatus[0].itemName;
  }

  get unknownProductOrderStatusId(): number | undefined {
    return this._unknownProductOrderStatus.length === 0 ? undefined : this._unknownProductOrderStatus[0].id;
  }

  get unknownProductOrderStatusName(): string | undefined {
    return this._unknownProductOrderStatus.length === 0 ? undefined : this._unknownProductOrderStatus[0].itemName;
  }

  get invalidOrderOrderStatusId(): number | undefined {
    return this._invalidOrderOrderStatus.length === 0 ? undefined : this._invalidOrderOrderStatus[0].id;
  }

  get invalidOrderOrderStatusName(): string | undefined {
    return this._invalidOrderOrderStatus.length === 0 ? undefined : this._invalidOrderOrderStatus[0].itemName;
  }

  get insufficientAmountOrderStatusId(): number | undefined {
    return this._insufficientAmountOrderStatus.length === 0 ? undefined : this._insufficientAmountOrderStatus[0].id;
  }

  get insufficientAmountOrderStatusName(): string | undefined {
    return this._insufficientAmountOrderStatus.length === 0 ? undefined : this._insufficientAmountOrderStatus[0].itemName;
  }

  get canceledOrderStatusId(): number | undefined {
    return this._canceledOrderStatus.length === 0 ? undefined : this._canceledOrderStatus[0].id;
  }

  get canceledOrderStatusName(): string | undefined {
    return this._canceledOrderStatus.length === 0 ? undefined : this._canceledOrderStatus[0].itemName;
  }

  get returnedOrderStatusId(): number | undefined {
    return this._returnedOrderStatus.length === 0 ? undefined : this._returnedOrderStatus[0].id;
  }

  get returnedOrderStatusName(): string | undefined {
    return this._returnedOrderStatus.length === 0 ? undefined : this._returnedOrderStatus[0].itemName;
  }

  get deliveredOrderStatusId(): number | undefined {
    return this._deliveredOrderStatus.length === 0 ? undefined : this._deliveredOrderStatus[0].id;
  }

  get deliveredOrderStatusName(): string | undefined {
    return this._deliveredOrderStatus.length === 0 ? undefined : this._deliveredOrderStatus[0].itemName;
  }

  get rejectedOrderStatusId(): number | undefined {
    return this._rejectedOrderStatus.length === 0 ? undefined : this._rejectedOrderStatus[0].id;
  }

  get rejectedOrderStatusName(): string | undefined {
    return this._rejectedOrderStatus.length === 0 ? undefined : this._rejectedOrderStatus[0].itemName;
  }

  get reopenedOrderStatusId(): number | undefined {
    return this._reopenedOrderStatus.length === 0 ? undefined : this._reopenedOrderStatus[0].id;
  }

  get reopenedOrderStatusName(): string | undefined {
    return this._reopenedOrderStatus.length === 0 ? undefined : this._reopenedOrderStatus[0].itemName;
  }

  get processStartedOrderStatusId(): number | undefined {
    return this._processStartedOrderStatus.length === 0 ? undefined : this._processStartedOrderStatus[0].id;
  }

  get processStartedOrderStatusName(): string | undefined {
    return this._processStartedOrderStatus.length === 0 ? undefined : this._processStartedOrderStatus[0].itemName;
  }

  get processFinishedOrderStatusId(): number | undefined {
    return this._processFinishedOrderStatus.length === 0 ? undefined : this._processFinishedOrderStatus[0].id;
  }

  get processFinishedOrderStatusName(): string | undefined {
    return this._processFinishedOrderStatus.length === 0 ? undefined : this._processFinishedOrderStatus[0].itemName;
  }

}

class TransporterCompanyItem extends MultiselectOptionItem<number> {
  deliveryMethods: DeliveryMethodItem[] = [];
}

class DeliveryMethodItem extends MultiselectOptionItem<number> {
  shippingMode: MultiselectOptionItem<number>[] = [];
}
