<div class="card">
  <div class="card-header">

    <!--{{headingDictionaryKey | translate}}-->
    {{'TASK_RECORD_HISTORY_TITLE' | translate}}

  </div>

  <div class="card-body">

    <div class="row">

      <div class="col-md-6">
        <table class="table table-striped table-bordered">
          <thead>
          <tr>
            <th class="table-sorter-header long">
              <app-table-sorter-no-op></app-table-sorter-no-op>
            </th>
            <th class="table-sorter-header long">
              <app-table-sorter-no-op
                [text]="'TASK_RECORD_HISTORY_TABLE_HEADER_CHANGE_TYPE' | translate"></app-table-sorter-no-op>
            </th>
            <th class="table-sorter-header long">
              <app-table-sorter-no-op
                [text]="'TASK_RECORD_HISTORY_TABLE_HEADER_CHANGE_TIME' | translate"></app-table-sorter-no-op>
            </th>
            <th class="table-sorter-header long hidden-sm-down">
              <app-table-sorter-no-op
                [text]="'TASK_RECORD_HISTORY_TABLE_HEADER_USER_HUMAN_NAME' | translate"></app-table-sorter-no-op>
            </th>
            <th>
              <app-table-sorter-no-op></app-table-sorter-no-op>
            </th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let taskRecordHistory of taskRecordHistoryList | paginate: {
                                                      id: pagingId,
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems };
                                                      let i = index">
            <tr class="table-row-selectable" [class.table-row-selected]="selectedIndex === i">
              <td class="fit">{{i+1}}</td>
              <td>
                {{ taskRecordHistory.changeType | translate }}
                <i *ngIf="taskRecordHistory.message" class="icomoon icomoon-state-change-message cursor-pointer ml-1"
                   popover="{{taskRecordHistory.message}}" triggers="mouseenter:mouseleave"></i>
              </td>
              <td>{{ taskRecordHistory.changeTime | date:'short' }}</td>
              <td class="hidden-sm-down">{{ taskRecordHistory.userHumanName }}</td>
              <td class="w-table-actions-single">
                <i class="icomoon icomoon-pin-on-location icomoon-modify-table cursor-pointer"
                   *ngIf="taskRecordHistory.coordinate"
                   (click)="historyMapComponent.highlightMarker(taskRecordHistory)"></i>
              </td>
            </tr>
          </ng-container>
          </tbody>
          <tfoot>
          <tr>
            <th class="table-sorter-header long">
              <app-table-sorter-no-op></app-table-sorter-no-op>
            </th>
            <th class="table-sorter-header long">
              <app-table-sorter-no-op
                [text]="'TASK_RECORD_HISTORY_TABLE_HEADER_CHANGE_TYPE' | translate"></app-table-sorter-no-op>
            </th>
            <th class="table-sorter-header long">
              <app-table-sorter-no-op
                [text]="'TASK_RECORD_HISTORY_TABLE_HEADER_CHANGE_TIME' | translate"></app-table-sorter-no-op>
            </th>
            <th class="table-sorter-header long hidden-sm-down">
              <app-table-sorter-no-op
                [text]="'TASK_RECORD_HISTORY_TABLE_HEADER_USER_HUMAN_NAME' | translate"></app-table-sorter-no-op>
            </th>
            <th>
              <app-table-sorter-no-op></app-table-sorter-no-op>
            </th>
          </tr>
          </tfoot>
        </table>
        <app-table-paging
          [id]="pagingId"
          [currentNumberOfItems]="queryModel.currentNumberOfItems"
          [totalNumberOfItems]="queryModel.totalNumberOfItems"
          [itemsPerPage]="queryModel.itemsPerPage"
          (pageChange)="pageChanged($event)"
          (itemsPerPageChange)="itemsPerPageChanged($event)">
        </app-table-paging>
      </div>

      <div class="col-md-6">
        <app-task-record-history-map #historyMapComponent
                                     [logs]="taskRecordHistoryList"
                                     (highlightTableRow)="highlightTableRow($event)">
        </app-task-record-history-map>
      </div>
    </div>

  </div><!-- /.card-body -->
</div>
