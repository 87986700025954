/* eslint-disable */
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {DownloadedFile,} from '../util/downloaded-files';
import {Observable} from 'rxjs';
import {FormRecordResource} from '../form/form-record-resource.service';
import {AddressResource} from '../address';
import {ResourceQueryResult} from '../util/services';
import {UrlBuilder} from '../../util/url-builder';
import {FileAttachmentResource} from '../util/file-attachments';
import {CustomerRecordBillingInfoResource, CustomerRecordResource} from '../customer/customer-record-resource.service';
import {
  BaseHttpService,
  EchoHeader,
  EchoHeaderBuilder,
  HeaderKeys,
  HeaderValues,
  ResourceHelper
} from '../util/http-services';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ChatResource} from '../chat/chat.utils';
import {EmptyMessage, IdentityMessage} from '../util/messages';
import {TriggerInstanceResource} from '../trigger/trigger-instance-resource.service';
import {IconResource} from './icon.service';
import TriggerInstanceResourceService = TriggerInstanceResource.TriggerInstanceResourceService;
import {ContractNumberResource} from "../customer/contract-number/contract-number-resource.service";

/* eslint-enable */


@Injectable()
export class TaskRecordResourceService extends BaseHttpService implements TriggerInstanceResourceService {

  // <editor-fold desc="CRUD">

  query(request: TaskRecordResource.GlobalQueryRequest): Observable<ResourceQueryResult<TaskRecordResource.TaskRecord>> {
    return this.http.get<ResourceQueryResult<TaskRecordResource.TaskRecord>>
    (this.url + `${request.task_id}/records/`, this.parseParams(request));
  }

  get(request: TaskRecordResource.GetRequest): Observable<TaskRecordResource.TaskRecord> {
    const echoHeader: EchoHeader = new EchoHeaderBuilder().ignorePermissionDenied(
      request.ignore_permission_denied !== undefined ? request.ignore_permission_denied : false).build();
    const headers = new HttpHeaders().set(HeaderKeys.ECHO, HeaderValues.serializeEchoHeader(echoHeader));
    return this.http.get<TaskRecordResource.TaskRecord>
    (this.url + `${request.task_id}/records/${request.task_record_id}`, this.parseParams(request));
  }

  create(request: TaskRecordResource.CreateRequest): Observable<IdentityMessage> {
    return this.http.post<IdentityMessage>
    (this.url + `${request.task_id}/records?with_form_record=${request.with_form_record}`,
      request, this.parseLocation(request));
  }

  quickCreate(request: TaskRecordResource.QuickCreateRequest): Observable<EmptyMessage> {
    return this.http.post<EmptyMessage>
    (this.url + `${request.task_id}/records/quick-create`,
      request, this.parseLocation(request));
  }

  update(request: TaskRecordResource.UpdateRequest): Observable<EmptyMessage> {
    return this.http.put<EmptyMessage>
    (this.url + `${request.task_id}/records/${request.task_record_id}?with_form_record=${request.with_form_record}`, request);
  }

  quickUpdate(request: TaskRecordResource.QuickUpdateRequest): Observable<EmptyMessage> {
    return this.http.put<EmptyMessage>
    (this.url + `${request.task_id}/records/${request.task_record_id}/quick-update`, request);
  }

  // </editor-fold>

  sendEmail(request: TaskRecordResource.SendEmailRequest): Observable<TaskRecordResource.TaskRecordEmailResult> {
    return this.http.post<TaskRecordResource.TaskRecordEmailResult>
    (this.url + `${request.task_id}/records/${request.task_record_id}/send-email`, request);
  }

  getAttachments(request: TaskRecordResource.GetAttachmentRequest): Observable<FileAttachmentResource[]> {
    return this.http.get<FileAttachmentResource[]>
    (this.url + `${request.task_id}/records/${request.task_record_id}/attachments`, this.parseParams(request));
  }

  getLogHistory(request: TaskRecordResource.GetLogHistoryRequest): Observable<ResourceQueryResult<TaskRecordResource.LogResource>> {
    return this.http.get<ResourceQueryResult<TaskRecordResource.LogResource>>
    (this.url + `${request.task_id}/records/${request.task_record_id}/log-history`, this.parseParams(request));
  }

  getRelatedInvoices(request: TaskRecordResource.RelatedInvoiceGetRequest): Observable<TaskRecordResource.RelatedInvoiceResource[]> {
    return this.http.get<TaskRecordResource.RelatedInvoiceResource[]>
    (this.url + `${request.task_id}/records/${request.task_record_id}/attachments/invoices`, this.parseParams(request));
  }

  messageQuery(request: TaskRecordResource.MessageQueryRequest): Observable<ResourceQueryResult<ChatResource.Message>> {
    return this.http.get<ResourceQueryResult<ChatResource.Message>>
    (this.url + `${request.task_id}/records/${request.task_record_id}/chat`, this.parseParams(request));
  }

  sendMessage(request: TaskRecordResource.SendMessageRequest): Observable<IdentityMessage> {
    return this.http.post<IdentityMessage>(this.url + `${request.task_id}/records/${request.task_record_id}/chat`, request);
  }

  getTriggerInstanceHistory(request: TriggerInstanceResource.TriggerInstanceHistoryRequest):
    Observable<ResourceQueryResult<TriggerInstanceResource.TriggerInstance>> {
    return this.http.get<ResourceQueryResult<TriggerInstanceResource.TriggerInstance>>(
      this.url + `${request.trigger_parent_id}/records/${request.instance_parent_id}/triggers`
    );
  }

  downloadAttachment(taskId: number, taskRecordId: number, attachmentId: number, thumbnail?: boolean): Observable<DownloadedFile> {
    const url = UrlBuilder.create('tasks/:taskId/records/:taskRecordId/attachments/:attachmentId' + (thumbnail ? '/thumbnail' : ''))
      .baseUrl(this.resourceHelper.getBaseUrl())
      .namedNumber('taskId', taskId)
      .namedNumber('taskRecordId', taskRecordId)
      .namedNumber('attachmentId', attachmentId)
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob',
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  deleteAttachment(request: TaskRecordResource.DeleteAttachmentRequest): Observable<EmptyMessage> {
    return this.http.delete<IdentityMessage>(
      this.url + `${request.task_id}/records/${request.task_record_id}/attachments/${request.file_id}`);
  }

  updateAttachment(request: TaskRecordResource.UpdateAttachmentRequest): Observable<EmptyMessage> {
    return this.http.patch<EmptyMessage>(
      this.url + `${request.task_id}/records/${request.task_record_id}/attachments/${request.file_id}`, request);
  }

  downloadCsv(request: TaskRecordResource.GlobalQueryRequest): Observable<DownloadedFile> {
    const url = UrlBuilder.create('tasks/:taskId/records/csv-export')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .namedNumber('taskId', <number>request.task_id!)
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob',
      params: this.parseParams(request).params
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  exportXlsTemplate(request: IdentityMessage): Observable<DownloadedFile> {
    const url = UrlBuilder.create('tasks/:taskId/records/xls-template')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .namedNumber('taskId', <number>request.id)
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob',
      params: this.parseParams(request).params
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  getInvoiceableItems(request: TaskRecordResource.GetAttachmentRequest): Observable<TaskRecordResource.InvoiceableItem[]> {
    return this.http.get<TaskRecordResource.InvoiceableItem[]>(
      this.url + `${request.task_id}/records/${request.task_record_id}/invoice/items`,
      this.parseParams(request));
  }

  createManualInvoice(request: TaskRecordResource.ManualInvoiceCreateRequest): Observable<IdentityMessage> {
    return this.http.post<IdentityMessage>(this.url + `${request.task_id}/records/${request.task_record_id}/invoice`, request);
  }

  quickCreateByCustomerRecords(request: TaskRecordResource.QuickCreateByCustomerRecordsRequest): Observable<IdentityMessage[]> {
    return this.http.post<IdentityMessage[]>(this.url + `${request.task_id}/records/quick-create/by-customer-record`, request);
  }

  getPdfs(request: TaskRecordResource.GetPdfsRequest):
    Observable<ResourceQueryResult<TaskRecordResource.Pdf>> {
    return this.http.get<ResourceQueryResult<TaskRecordResource.Pdf>>(
      this.url + `${request.task_id}/records/${request.task_record_id}/pdf-documents`,
      this.parseParams(request)
    );
  }

  downloadPdf(request: TaskRecordResource.DownloadPdfRequest): Observable<DownloadedFile> {
    return this.http.get(this.url + `${request.task_id}/records/${request.task_record_id}/pdf-documents/${request.document_id}/download`, {
      observe: 'response',
      responseType: 'blob',
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  readLinks(request: TaskRecordResource.ReadLinksRequest): Observable<TaskRecordResource.Link[]> {
    return this.http.get<TaskRecordResource.Link[]>(
      this.url + `${request.task_id}/records/${request.task_record_id}/links`,
      this.parseParams(request));
  }

  changeAssigneeInProgress(request: TaskRecordResource.AssigneeChangeInProgressRequest): Observable<EmptyMessage> {
    return this.http.patch<EmptyMessage>(
      this.url + `${request.task_id}/records/${request.task_record_id}/assignee-in-progress`,
      request);
  }

  downloadSignature(taskId: number, taskRecordId: number, type: 'user' | 'customer'): Observable<DownloadedFile> {
    const echoHeader: EchoHeader = new EchoHeaderBuilder()
      .ignoreEntityNotFound(true)
      .ignorePermissionDenied(true)
      .build();
    const headers = new HttpHeaders().set(HeaderKeys.ECHO, HeaderValues.serializeEchoHeader(echoHeader));
    const url = UrlBuilder.create('tasks/:taskId/records/:taskRecordId/:type-signature')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .namedNumber('taskId', taskId)
      .namedNumber('taskRecordId', taskRecordId)
      .namedString('type', type)
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob',
      headers: headers
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }


  uploadSignature(request: { file: Blob; task_id: number; task_record_id: number, type: 'user' | 'customer' }) {
    const formData = new FormData();
    formData.append('file', request.file, `${request.task_record_id}_${request.type}_signature.png`);
    return this.http.post<EmptyMessage>(this.url + `${request.task_id}/records/${request.task_record_id}/${request.type}-signature/`,
      formData);

  }

  constructor(private http: HttpClient, private resourceHelper: ResourceHelper) {
    super(resourceHelper, '/tasks/');
  }
}

@Injectable()
export class TaskRecordGlobalResourceService extends BaseHttpService {

  // <editor-fold desc="CRUD">

  query(request: TaskRecordResource.GlobalQueryRequest): Observable<ResourceQueryResult<TaskRecordResource.TaskRecord>> {
    return this.http.get<ResourceQueryResult<TaskRecordResource.TaskRecord>>(this.url, this.parseParams(request));
  }

  getLogHistory(request: TaskRecordResource.GlobalLogHistoryRequest): Observable<ResourceQueryResult<TaskRecordResource.LogResource>> {
    return this.http.get<ResourceQueryResult<TaskRecordResource.LogResource>>(this.url + 'log-history', this.parseParams(request));
  }

  queryTaskRecordNames(request: TaskRecordResource.QueryTaskRecordNamesRequest): Observable<string[]> {
    return this.http.get<string[]>(this.url + 'names', this.parseParams(request));
  }

  exportToExternalSystem(request: TaskRecordResource.ExternalExportRequest): Observable<TaskRecordResource.ExternalExportResult> {
    return this.http.post<TaskRecordResource.ExternalExportResult>(this.url + 'export-to-external-system',
      request, this.parseLocation(request));
  }

  setState(request: TaskRecordResource.ChangeStateRequest): Observable<EmptyMessage> {
    const params = this.parseLocation(request).params;
    const echoHeader: EchoHeader = new EchoHeaderBuilder().ignoreGlobalErrors(true).build();
    const headers = new HttpHeaders().set(HeaderKeys.ECHO, HeaderValues.serializeEchoHeader(echoHeader));
    return this.http.post<EmptyMessage>(this.url + `state/${request.new_state}`,
      request, {params: params, headers: headers});
  }

  checkState(request: TaskRecordResource.CheckStateQuery): Observable<number[]> {
    return this.http.get<number[]>(this.url + 'state/check-state', this.parseParams(request));
  }

  checkBulkChangeAssignee(request: TaskRecordResource.GlobalQueryRequest): Observable<number[]> {
    return this.http.get<number[]>(this.url + 'bulk/check-bulk-change-assignee', this.parseParams(request));
  }

  checkBulkChangeDeadline(request: TaskRecordResource.GlobalQueryRequest): Observable<number[]> {
    return this.http.get<number[]>(this.url + 'bulk/check-bulk-change-deadline', this.parseParams(request));
  }

  importanceBulkUpdate(request: TaskRecordResource.ChangeImportanceRequest): Observable<EmptyMessage> {
    return this.http.patch<EmptyMessage>(this.url + 'bulk/bulk-importance', request);
  }

  assigneeBulkUpdate(request: TaskRecordResource.AssigneeChangeRequest): Observable<EmptyMessage> {
    return this.http.patch<EmptyMessage>(this.url + 'bulk/bulk-assignee', request);
  }

  deadlineBulkUpdate(request: TaskRecordResource.DeadlineChangeRequest): Observable<EmptyMessage> {
    return this.http.patch<EmptyMessage>(this.url + 'bulk/bulk-deadline', request);
  }

  geocode(request: TaskRecordResource.TaskRecordIdSetRequest): Observable<EmptyMessage> {
    return this.http.patch<EmptyMessage>(this.url + 'bulk/bulk-geocoding', request);
  }

  confirm(request: TaskRecordResource.ConfirmRequest): Observable<EmptyMessage> {
    return this.http.patch<EmptyMessage>(this.url + 'bulk/bulk-confirm', request);
  }

  startApproval(request: TaskRecordResource.GetRequest): Observable<EmptyMessage> {
    return this.http.post<EmptyMessage>(this.url + `state/start-approval?id=${request.task_record_id}`, {});
  }

  cancelApproval(request: TaskRecordResource.GetRequest): Observable<EmptyMessage> {
    return this.http.post<EmptyMessage>(this.url + `state/cancel-approval?id=${request.task_record_id}`, {});
  }

  downloadAttachmentZip(request: TaskRecordResource.TaskRecordIdSetRequest): Observable<DownloadedFile> {
    const url = this.url + 'attachments/download';
    return this.http.post(url, request, {
      observe: 'response',
      responseType: 'blob',
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  downloadPdfZip(request: TaskRecordResource.TaskRecordIdSetRequest): Observable<DownloadedFile> {
    const url = this.url + 'pdf-documents/zip';
    return this.http.post(url, request, {
      observe: 'response',
      responseType: 'blob',
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  downloadMergedPdf(request: TaskRecordResource.TaskRecordIdSetRequest): Observable<DownloadedFile> {
    const url = this.url + 'pdf-documents/merge';
    return this.http.post(url, request, {
      observe: 'response',
      responseType: 'blob',
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  downloadTableXls(request: TaskRecordResource.TaskRecordIdSetRequest): Observable<DownloadedFile> {
    const url = this.url + 'export-table-xls';
    return this.http.post(url, request, {
      observe: 'response',
      responseType: 'blob',
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  getRecentMessages(request: TaskRecordResource.RecentMessageQueryRequest):
    Observable<ResourceQueryResult<TaskRecordResource.RecentMessage>> {
    return this.http.get<ResourceQueryResult<TaskRecordResource.RecentMessage>>(this.url + '/recent-messages', this.parseParams(request));
  }

  getClusters(request: TaskRecordResource.ClustersRequest): Observable<TaskRecordResource.ClustersResponse[]> {
    return this.http.get<TaskRecordResource.ClustersResponse[]>(this.url + 'poi', this.parseParams(request));
  }

  clone(request: TaskRecordResource.CloneRequest): Observable<number[]> {
    return this.http.post<number[]>(this.url + '/clone', request);
  }

  exportXls(request: TaskRecordResource.GlobalQueryRequest): Observable<DownloadedFile> {
    const url = UrlBuilder.create('task-records/export-xls')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob',
      params: this.parseParams(request).params
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  exportCustomXls(request: TaskRecordResource.GlobalQueryRequest): Observable<DownloadedFile> {
    const url = UrlBuilder.create('task-records/export-custom-xls')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob',
      params: this.parseParams(request).params
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  exportSupInvoice(ids: string): Observable<DownloadedFile> {
    const url = UrlBuilder.create('task-records/export-sup-invoice-xml')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob',
      params: this.parseParams({id: ids}).params
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  exportSupCreate(request: TaskRecordResource.TaskRecordSupCreateRequest): Observable<DownloadedFile> {
    const url = UrlBuilder.create('task-records/export-sup-create-xml')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .build();
    return this.http.post(url, request, {
      observe: 'response',
      responseType: 'blob',

    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  exportMaconomy(request: TaskRecordResource.GlobalQueryRequest): Observable<DownloadedFile> {
    const url = UrlBuilder.create('task-records/export-maconomy')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob',
      params: this.parseParams(request).params
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  exportStockXls(request: TaskRecordResource.GlobalQueryRequest): Observable<DownloadedFile> {
    const url = UrlBuilder.create('task-records/export-stock-xls')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob',
      params: this.parseParams(request).params
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  constructor(private http: HttpClient, private resourceHelper: ResourceHelper) {
    super(resourceHelper, '/task-records');
  }

}

export namespace TaskRecordResource {

  // <editor-fold desc="CRUD">

  export interface QueryTaskRecordNamesRequest {
    name: string;
    number_of_items?: number;
    no_progress_bar?: boolean;
  }

  export interface GlobalQueryRequest {
    rights?: string;
    fields?: string;
    customer_fields?: string;
    with_form_record: boolean;
    parent_disabled?: boolean;

    task_id?: string | number;
    task_name?: string;
    id?: string;
    name?: string;
    external_id?: string;
    description?: string;
    state?: string;
    helpdesk_state?: string;
    assignee_user_id?: number;
    assignee_user_group_id?: number;
    assignee_mobile_app_id?: string;
    customer_id?: number;
    customer_name?: string;
    customer_phone_number?: string;
    project_name?: string;
    project_id?: string;
    process_id?: string;
    importance?: string;
    export_state?: string;
    place_of_consumption_zip_code?: string;
    place_of_consumption_city?: string;
    place_of_consumption_address?: string;
    place_of_consumption_house_number?: string;
    deadline_from?: string;
    deadline_to?: string;
    creation_time_from?: string;
    creation_time_to?: string;
    update_time_from?: string;
    update_time_to?: string;
    release_time_from?: string;
    release_time_to?: string;
    agreed_time_from?: string;
    agreed_time_to?: string;
    finished_time_from?: string;
    finished_time_to?: string;
    stock_id?: number;
    helpdesk_enabled?: boolean;
    base_project?: number;
    base_process?: number;
    base_master_data_record?: number;
    base_customer_record?: number;
    base_customer_record_by_phone?: number;
    base_task_record?: number;
    creator_user_id?: string;
    empty_intake_prices?: boolean;
    contract_number_id?: string;
    q?: string;
    dql?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
    no_progress_bar?: boolean;
  }

  export interface RecentMessageQueryRequest {
    helpdesk_enabled?: boolean;
    fields?: string;
    customer_fields?: string;
    page_number?: number;
    number_of_items?: number;
    no_progress_bar?: boolean;
  }

  export interface GlobalLogHistoryRequest {
    order?: string;
    page_number?: number;
    number_of_items?: number;
    process_id?: number;
    helpdesk_enabled?: boolean;
  }

  export interface GetRequest {
    fields?: string;
    rights?: string;
    customer_fields?: string;
    with_form_record: boolean;
    ignore_permission_denied?: boolean;

    task_id: number;
    task_record_id: number;
  }

  export interface CreateRequest {
    fields?: string;
    with_form_record: boolean;

    task_id: number;
    external_id?: string;
    name?: string;
    description?: string;
    importance: string;
    owner_user_id?: number;
    customer_id?: number;
    billing_info_id?: number;
    contact_location_id?: number;
    project_id?: number;
    agreed_time?: string;
    deadline?: string;
    user_group_id?: number;
    assignee: Assignee;
    place_of_consumption?: PlaceOfConsumption;
    form_record: FormRecordResource.FormRecordCreateRequest;
    text_document_ids?: number[];
    file_document_ids?: number[];
    linked_survey_ids?: number[];
    estimated_time_in_minutes?: number;
    contract_number_id?: number;
    with_location: boolean;
  }

  export interface QuickCreateRequest {
    task_id: number;
    external_id?: string;
    state: string;
    name?: string;
    importance: string;
    description?: string;
    customer_id?: number;
    billing_info_id?: number;
    project?: number;
    contact_location_id?: number;
    agreed_time?: string;
    deadline?: string;
    user_group_id?: number;
    assignee?: AssigneeChangeRequest;
    estimated_time_in_minutes?: number;
    place_of_consumption?: PlaceOfConsumption;
    contract_number_id?: number;
    with_location: boolean;
  }

  export interface QuickCreateByCustomerRecordsRequest {
    task_id: number;
    name_template: string;
    state: string;
    description?: string;
    importance: string;
    customer_ids: number[];
    project?: number;
    agreed_time?: string;
    deadline?: string;
    assignee?: AssigneeChangeRequest;
    user_group_id?: number;
    estimated_time_in_minutes?: number;
  }

  export interface UpdateRequest {
    fields?: string;
    with_form_record: boolean;

    task_id: number;
    task_record_id: number;
    external_id?: string;
    name: string;
    description?: string;
    importance: string;
    owner_user_id?: number;
    customer_id?: number;
    billing_info_id?: number;
    contact_location_id?: number;
    agreed_time?: string;
    deadline?: string;
    user_group_id?: number;
    assignee: Assignee;
    place_of_consumption?: PlaceOfConsumption;
    project_id?: number;
    form_record: FormRecordResource.FormRecordUpdateRequest;
    text_document_ids?: number[];
    file_document_ids?: number[];
    linked_survey_ids?: number[];
    contract_number_id?: number;
    estimated_time_in_minutes?: number;
  }


  export interface QuickUpdateRequest {

    task_id: number;
    task_record_id: number;
    external_id?: string;
    name: string;
    description?: string;
    importance: string;
    owner_user_id?: number;
    customer_id?: number;
    billing_info_id?: number;
    contact_location_id?: number;
    agreed_time?: string;
    deadline?: string;
    user_group_id?: number;
    assignee: Assignee;
    place_of_consumption?: PlaceOfConsumption;
    project_id?: number;
    text_document_ids?: number[];
    file_document_ids?: number[];
    linked_survey_ids?: number[];
    contract_number_id?: number;
    estimated_time_in_minutes?: number;
  }

  export interface TaskRecord {
    id: number;
    task_id: number;
    project_id?: number;
    creation_time: string;
    update_time: string;
    disabled: boolean
    state: string;
    helpdesk_state?: string;
    importance: string;
    external_id?: string;
    name: string;
    description?: string;
    owner_user?: OwnerUser;
    customer_id?: number;
    contact_location_id?: number;
    customer_record?: CustomerRecordResource.CustomerRecord;
    agreed_time?: string;
    deadline?: string;
    user_group_id?: number;
    user_group_name?: string;
    assignee?: Assignee;
    place_of_consumption?: PlaceOfConsumption;
    form_record?: FormRecordResource.FormRecord;
    state_change_log?: LogResource;
    text_document_ids?: number[];
    file_document_ids?: number[];
    invoice_ids?: number[];
    export_state?: string;
    export_time?: string;
    finalization_time?: string;
    previous_export_error?: PreviousExportError;
    linked_survey_ids?: number[];
    linked_survey_record_ids?: Map<number, number[]>;
    estimated_time_in_minutes?: number;
    time_spent_in_minutes?: number;
    chat_message_count?: number;
    last_chat_message?: ChatResource.Message;
    attachment_count?: number;
    confirmed: boolean;
    process?: ProcessData;
    task?: TaskData;
    creator_user?: AssigneeUserData;
    billing_info?: CustomerRecordBillingInfoResource.BillingInfo;
    sms_rating?: number;
    sms_content?: string[];
    granted_rights?: string[];
    contract_number?: ContractNumberResource.ContractNumber;
  }

  export interface Assignee {
    mobile_application_id?: number;
    user_id?: number;
    user_data?: AssigneeUserData;
  }

  export interface AssigneeUserData {
    id: number;
    user_name: string;
    person_name: string;
  }

  export interface PlaceOfConsumption {
    address?: AddressResource.PostalAddressResource;
    coordinate?: AddressResource.CoordinateResource;
    geocode_status?: string;
  }

  export interface PreviousExportError {
    code?: string;
    message?: string;
    audit_log_response_id?: number;
  }

  export interface OwnerUser {
    id: number;
    type: string;
  }

  export interface ProcessData {
    id: number;
    name: string;
    external_id: string;
    state: string;
    creator_user: AssigneeUserData;
  }

  export interface TaskData {
    id: number;
    name: string;
    icon?: IconResource.Icon;
    admin_editable_states: string[];
    task_record_color: number;
  }

  export interface ChangeStateRequest {
    id: number[];
    new_state: string;
    state_change_message?: string;
    with_location: boolean;
    check_up_date?: string;
  }

  export interface ChangeImportanceRequest {
    task_record_ids: number[];
    importance: string;
  }

  export interface AssigneeChangeRequest {
    task_record_ids?: number[];
    user_id?: number;
    mobile_application_string_id?: number;
    mobile_application_id?: number;
  }

  export interface AssigneeChangeInProgressRequest {
    task_id: number;
    task_record_id: number;
    task_record_ids: number[];
    user_id?: number;
    mobile_application_string_id?: number;
    mobile_application_id?: number;
  }

  export interface DeadlineChangeRequest {
    task_record_ids: number[];
    deadline: string;
  }

  export interface TaskRecordIdSetRequest {
    task_record_ids: number[];
  }

  export interface ConfirmRequest {
    task_record_ids: number[];
    confirmed: boolean;
  }

  export interface CheckStateQuery extends GlobalQueryRequest {
    state_change?: string;
  }

  export interface SendEmailRequest {
    task_id: number;
    task_record_id: number;
    send_to_me: boolean;
    send_to_assignee: boolean;
    send_to_customer: boolean;
    other_recipients?: string[];
  }

  export interface TaskRecordEmailResult {
    email_result: string;
  }

  export interface GetAttachmentRequest {
    task_id: number;
    task_record_id: number;
  }

  export interface DeleteAttachmentRequest {
    task_id: number;
    task_record_id: number;
    file_id: number;
  }

  export interface UpdateAttachmentRequest {
    task_id: number;
    task_record_id: number;
    file_id: number;
    name: string;
  }

  export interface GetLogHistoryRequest {
    task_id: number;
    task_record_id: number;
    with_coordinates_only?: boolean;
    order?: string;
    page_number?: number;
    number_of_items?: number;
  }

  export interface LogResource {
    id: number;
    log_type: string;
    log_time: string;
    user_profile_id: number;
    taskrecord_id: number;
    task_id: number;
    taskrecord_name: string;
    taskrecord_external_id: string;
    message?: string;
    coordinate?: AddressResource.CoordinateResource;
    user: {
      id: number;
      user_name: string;
      person_name: string;
    }
  }

  export interface RelatedInvoiceGetRequest {
    task_id: number;
    task_record_id: number;
  }

  export interface RelatedInvoiceResource {
    id: number;
    category_type: string;
    invoice_number: string;
    creation_time: string;
    appearance_type: string;
    invoice_settings: {
      id: number;
      profile_name: string;
      company_name?: string;
      invoice_integration_type: string;
    };
  }

  export interface ExternalExportRequest {
    task_record_ids: number[];
    with_location: boolean;
  }

  export interface ExternalExportResult {
    result: ExternalExportResultCode;
  }

  export interface TaskRecordSupCreateRequest {
    task_record_ids: number[];
    journal_code: string;
    payment_type: string;
    issue_date: string;
    delivery_date: string;
    deadline_offset: number;
  }

  export type ExternalExportResultCode =
    'SKIPPED'
    | 'EMPTY_ID_SET'
    | 'INVALID_TASK_RECORD_STATE'
    | 'INVALID_TASK_RECORD_TYPE'
    | 'INIT_ERROR'
    | 'FAILED_TO_SAVE_EXPORT_STATE'
    | 'EACH_EXPORT_FAILED'
    | 'SOME_EXPORT_DONE'
    | 'EACH_EXPORT_DONE';


  export interface RevertResultResponse {
    result: RevertResult;
  }

  export type RevertResult =
    'EMPTY_ID_SET'
    | 'INVALID_TASK_RECORD_STATE'
    | 'INIT_ERROR'
    | 'FAILED_TO_SAVE_REVERT_STATE'
    | 'EACH_REVERT_FAILED'
    | 'SOME_REVERT_DONE'
    | 'EACH_REVERT_DONE';

  export interface MessageQueryRequest extends ChatResource.MessageQueryRequest {
    task_id: number;
    task_record_id: number;
  }

  export interface SendMessageRequest extends ChatResource.SendMessageRequest {
    task_id: number;
    task_record_id: number;
  }

  export interface RecentMessage {
    task_record: TaskRecord;
    chat_message: ChatResource.Message;
  }

  export interface ManualInvoiceCreateRequest {
    task_id: number;
    task_record_id: number;
    invoice_book_id?: number;
    invoice_settings_id: number;
    invoice_tag_id?: number;
    billing_info_id?: number;
    payment_type: string;
    invoice_appearance_type: string;
    comment_template?: string;
    issue_date: string;
    delivery_date: string;
    deadline: string;
    stock_item_ids: number[];
    invoice_item_ids: number[];
  }

  export interface InvoiceableItem {
    invoice_item?: FormRecordResource.InvoiceItem;
    stock_item?: FormRecordResource.StockItem;
  }

  export interface ClustersRequest {
    fields?: string;
    customer_fields?: string;

    sw_lat: string;
    sw_lon: string;
    ne_lat: string;
    ne_lon: string;
    task_id?: number;

    assignee_user_id?: string;
    assignee_user_group_id?: string;
    state?: string;

    no_progress_bar?: boolean;
  }

  export interface ClustersResponse {
    cluster_id: number;
    count: number;
    center: AddressResource.CoordinateResource;
    task_record?: TaskRecord;
  }

  export interface GetPdfsRequest {
    task_id: number;
    task_record_id: number;
    order?: string;
    page_number?: number;
    number_of_items?: number;
  }

  export interface Pdf {
    document_id: number;
    creation_time: string;
    template_name: string;
    content_hash: string;
    trigger?: {
      trigger_id: number;
      instance_id: number;
      name: string;
      event: string;
    };
    original_task_record?: {
      task_id: number;
      id: number;
      name: string;
      external_id: string;
    }
  }

  export interface DownloadPdfRequest {
    task_id: number;
    task_record_id: number;
    document_id: number;
  }

  export interface CloneRequest {
    task_record_ids: number[];
    target_task_id: number;
    name_template?: string;
    assignee?: AssigneeChangeRequest;
    user_group_id?: number;
    clone_fields: string[];
    open_task_records: boolean;
  }

  export interface ReadLinksRequest {
    task_id: number;
    task_record_id: number;
    fields?: string;
    customer_fields?: string;
  }

  export interface Link {
    destination: TaskRecord;
    type: string;
    creation_time: string;
  }

  // </editor-fold>

}
