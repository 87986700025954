import {Injectable} from '@angular/core';
import {MultiselectProvider} from '../../multiselect-provider';
import {Observable, Observer} from 'rxjs';
import {MultiselectOptionItem, UiConstants} from '../../../util/core-utils';
import {QueryResult} from '../../util/services';
import {ContractNumber, ContractNumberService} from './contract-number.service';
import {List} from 'immutable';
import {FilterField} from '../../query/filterfields';
import {CriteriaBuilder} from '../../../util/model-utils';
import {Query} from '../../query/field';

@Injectable()
export class ContractNumberMultiselectProvider implements MultiselectProvider {

  private multiselectQueryRequest: ContractNumber.QueryRequest = {
    fields: f => f.multiselect,
    order: f => List.of(f.number.asc().nullsLast()),
    paging: {
      pageNumber: 1,
      numberOfItems: UiConstants.autocompletePageSize,
    },
    noProgressBar: true,
  };

  constructor(private contractNumberService: ContractNumberService) {
  }

  static toPublic(item: ContractNumber.ContractNumber): MultiselectOptionItem<number> {
    const ret = new MultiselectOptionItem<number>();
    ret.id = item.id;
    ret.itemName = item.number;
    return ret;
  }

  load(queryRequest: ContractNumber.QueryRequest): Observable<MultiselectOptionItem<number>[]> {
    return Observable.create((observer: Observer<MultiselectOptionItem<number>[]>) => {
      this.contractNumberService.query(queryRequest).subscribe((result: QueryResult<ContractNumber.ContractNumber>) => {
          observer.next(result.items.map(item => this.toMultiselectOptionItem(item!)).toArray());
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  loadAll(predicate?: string): Observable<MultiselectOptionItem<number>[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.filter = (f: FilterField.ContractNumber) => CriteriaBuilder.builder()
      .addString((predicate) => f.number.startsWithIgnoreCase(predicate), predicate)
      .build();
    return this.load(queryRequest);
  }

  loadActive(predicate?: string): Observable<MultiselectOptionItem<number>[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.filter = (f: FilterField.ContractNumber) => CriteriaBuilder.builder()
      .addString((predicate) => f.number.startsWithIgnoreCase(predicate), predicate)
      .build();
    return this.load(queryRequest);
  }

  getById(id: number): Observable<MultiselectOptionItem<number>> {
    return Observable.create((observer: Observer<MultiselectOptionItem<number>>) => {
      this.contractNumberService.get({
        id: id,
        fields: f => f.multiselect
      }).subscribe((result: ContractNumber.ContractNumber) => {
          observer.next(this.toMultiselectOptionItem(result!));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  getByIds(ids: number[]): Observable<MultiselectOptionItem<number>[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    const filters: Query.Criteria[] = [];
    const f: FilterField.ContractNumber = new FilterField.ContractNumber();
    ids.forEach(id => {
      filters.push(f.id.eq(id));
    });
    queryRequest.filter = f => Query.Criterias.anyOf(List.of(...filters));
    return this.load(queryRequest);
  }

  toMultiselectOptionItem(item: ContractNumber.ContractNumber): MultiselectOptionItem<number> {
    return ContractNumberMultiselectProvider.toPublic(item);
  }
}
