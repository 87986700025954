/* eslint-disable */
import { Injectable } from '@angular/core';
import { OrderField } from '../query/orderfields';
import { MultiselectOptionItem, OrderFieldModel, OrderFunctionResolver } from '../../util/core-utils';
import { environment } from '../../../environments/environment';
import { NewBaseSearch, NewBaseSearchService } from '../new-base.search-service';
import { OrderType } from '../util/services';
import { SearchUtils } from '../../util/search-utils';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { LoggedInUserStorage } from '../util/storages';
import { List } from 'immutable';
import { TaskRecordStateMachine } from '../task/task-record-statemachine';
/* eslint-enable */

@Injectable()
export class MileageRecordSearchService extends NewBaseSearchService<OrderField.MileageRecord> {

  protected createStorage(): MileageRecordSearch.Storage {
    return new MileageRecordSearch.Storage();
  }

}

export namespace MileageRecordSearch {

  export class Model extends SearchUtils.SearchModel {

    showSearch: boolean = false;

    taskRecordState: MultiselectOptionItem<TaskRecordStateMachine.State>[];
    creationTimeFrom: NgbDateStruct | null;
    creationTimeTo: NgbDateStruct | null;
    finishTimeFrom: NgbDateStruct | null;
    finishTimeTo: NgbDateStruct | null;
    journeyType: string;
    taskRecord: MultiselectOptionItem<number>[];
    user: MultiselectOptionItem<number>[];
    vehicle: MultiselectOptionItem<number>[];

    get taskRecordId(): number | undefined {
      if (this.taskRecord && this.taskRecord.length > 0 && this.taskRecord[0].id !== null) {
        return this.taskRecord[0].id!;
      }
      return undefined;
    }

    get taskRecordStates(): List<TaskRecordStateMachine.State> | null {
      if (this.taskRecordState.length > 0) {
        return List.of(...this.taskRecordState.map(s => s.id));
      }
      return null;
    }

    get userId(): number | undefined {
      if (this.user && this.user.length > 0 && this.user[0].id !== null) {
        return this.user[0].id!;
      }
      return undefined;
    }

    get vehicleId(): number | undefined {
      if (this.vehicle && this.vehicle.length > 0 && this.vehicle[0].id !== null) {
        return this.vehicle[0].id!;
      }
      return undefined;
    }

    constructor() {
      super();
      this.reset();
    }

    reset() {
      this.creationTimeFrom = null;
      this.creationTimeTo = null;
      this.finishTimeFrom = null;
      this.finishTimeTo = null;
      this.journeyType = '';
      this.taskRecord = [];
      this.taskRecordState = [];
      this.user = [];
      this.vehicle = [];
    }

    isEmpty(): boolean {
      return this.journeyType === '' &&
        this.creationTimeFrom === null &&
        this.creationTimeTo === null &&
        this.finishTimeFrom === null &&
        this.finishTimeTo === null &&
        this.taskRecord.length === 0 &&
        this.taskRecordState.length === 0 &&
        this.user.length === 0 &&
        this.vehicle.length === 0;
    }

  }

  export namespace OrderFunctions {
    export const ID = (f: OrderField.MileageRecord) => f.id;
    export const TASK_RECORD_NAME = (f: OrderField.MileageRecord) => f.taskRecord.name;
    export const USER_NAME = (f: OrderField.MileageRecord) => f.user.userName;
    export const VEHICLE_LICENCE_PLATE = (f: OrderField.MileageRecord) => f.vehicle.licencePlate;
    export const CREATION_TIME = (f: OrderField.MileageRecord) => f.creationTime;
    export const FINISH_TIME = (f: OrderField.MileageRecord) => f.finishTime;

    export const VALUES = OrderFunctionResolver.builder<OrderField.MileageRecord>()
      .add(ID, 'id')
      .add(TASK_RECORD_NAME, 'task_record.name')
      .add(USER_NAME, 'user.user_name')
      .add(VEHICLE_LICENCE_PLATE, 'vehicle.licence_plate')
      .add(CREATION_TIME, 'creation_time')
      .add(FINISH_TIME, 'finish_time')
      .build();
  }

  export interface SearchDataResult {
    searchData: SearchData;
  }

  export interface SearchData extends NewBaseSearch.SearchData<OrderField.MileageRecord> {
    searchModel: Model;
  }

  interface StoredSearchRawData extends NewBaseSearch.StoredSearchRawData<OrderField.MileageRecord> {
    creationTimeFrom: NgbDateStruct | null;
    creationTimeTo: NgbDateStruct | null;
    finishTimeFrom: NgbDateStruct | null;
    finishTimeTo: NgbDateStruct | null;
    journeyType: string;
    taskRecord: MultiselectOptionItem<number>[];
    taskRecordState: MultiselectOptionItem<TaskRecordStateMachine.State>[];
    user: MultiselectOptionItem<number>[];
    vehicle: MultiselectOptionItem<number>[];
  }

  export class Storage extends NewBaseSearch.Storage<OrderField.MileageRecord> {

    constructor() {
      const defaultSearchData: SearchData = {
        queryModel: new OrderFieldModel(OrderFunctions.ID, OrderType.DESC),
        searchModel: new Model(),
      };
      /**
       * Increment this version if you change the SearchData interface.
       */
      const versionNumber: number = 3;
      super(defaultSearchData,
        versionNumber);
    }

    protected generateKey(): string {
      return 'UserId(' + LoggedInUserStorage.getInstance().getUserId() + ')-Search-Mileage-Record';
    }

    protected fromRaw(data: StoredSearchRawData): NewBaseSearch.SearchDataResult<OrderField.MileageRecord> {
      const searchModel = new Model();
      searchModel.creationTimeFrom = data.creationTimeFrom;
      searchModel.creationTimeTo = data.creationTimeTo;
      searchModel.finishTimeFrom = data.finishTimeFrom;
      searchModel.finishTimeTo = data.finishTimeTo;
      searchModel.journeyType = data.journeyType;
      searchModel.taskRecord = data.taskRecord;
      searchModel.taskRecordState = data.taskRecordState;
      searchModel.user = data.user;
      searchModel.vehicle = data.vehicle;
      const searchData: SearchData = {
        queryModel: OrderFieldModel.create<OrderField.MileageRecord>(OrderFunctions.VALUES, data.order, data.queryModel),
        searchModel: searchModel,
      };
      return {
        searchData: searchData
      };
    }

    protected toRaw(data: SearchData): StoredSearchRawData {
      return {
        version: this.VERSION,
        queryModel: data.queryModel,
        order: data.queryModel.createOrder(OrderFunctions.VALUES),
        creationTimeFrom: data.searchModel.creationTimeFrom,
        creationTimeTo: data.searchModel.creationTimeTo,
        finishTimeFrom: data.searchModel.finishTimeFrom,
        finishTimeTo: data.searchModel.finishTimeTo,
        journeyType: data.searchModel.journeyType,
        taskRecord: data.searchModel.taskRecord,
        taskRecordState: data.searchModel.taskRecordState,
        user: data.searchModel.user,
        vehicle: data.searchModel.vehicle,
      };
    }

  }

}
