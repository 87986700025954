<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="card">

    <div class="card-header">
      {{'COMMON_DEVICES' | translate}}

      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()"
           title="{{'COMMON_SHOW_SEARCH_HINT' | translate}}"><i #searchIcon
                                                                class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>

    <div class="card-body border-bottom" *ngIf="showSearch">
      <div class="form-group row">
        <div class="col-md-4 col-lg-3">
          <label class="col-form-label">{{'COMMON_DEVICE_NAME' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'COMMON_DEVICE_NAME' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.name" name="name" id="name" #name="ngModel">
        </div>
        <div class="col-md-4 col-lg-3">
          <label class="col-form-label">{{'DEVICE_MANAGEMENT_SEARCH_DEVICE_MODEL' | translate}}</label>
          <input type="text" class="form-control"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 placeholder="{{'DEVICE_MANAGEMENT_SEARCH_DEVICE_MODEL' | translate}}"
                 [(ngModel)]="searchModel.device_model" name="device_model" id="device_model" #description="ngModel">
        </div>
        <div class="col-md-4 col-lg-3">
          <label class="col-form-label">{{'DEVICE_MANAGEMENT_SEARCH_LAST_REQUEST_USER' | translate}}</label>
          <input type="text" class="form-control"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 placeholder="{{'DEVICE_MANAGEMENT_SEARCH_LAST_REQUEST_USER' | translate}}"
                 [(ngModel)]="searchModel.latest_login_user_name" name="latest_request_user_name"
                 id="latest_request_user_name" #description="ngModel">
        </div>
        <div class="col-md-4 col-lg-3">
          <label class="col-form-label">{{'DEVICE_SEARCH_FIELD_DISABLED' | translate}}</label>
          <select class="form-control" [compareWith]="SelectUtils.compareObjects" [(ngModel)]="searchModel.disabled"
                  id="disabled" name="disabled" #disabled="ngModel">
            <option *ngFor="let item of deviceDisabledItems" [ngValue]="item">{{item.text}}</option>
          </select>
        </div>
        <div class="col-md-4 col-lg-3">
          <label class="col-form-label">{{'DEVICE_MANAGEMENT_SEARCH_LAST_REQUEST_FROM' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control date"
                   placeholder="{{'DEVICE_MANAGEMENT_SEARCH_LAST_REQUEST_FROM' | translate}}"
                   [(ngModel)]="searchModel.latest_login_from" ngbDatepicker name="latest_request"
                   #latest_request_from="ngbDatepicker"
                   id="latest_request_from">
            <div class="input-group-addon-gray" (click)="latest_request_from.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-lg-3">
          <label class="col-form-label">{{'DEVICE_MANAGEMENT_SEARCH_LAST_REQUEST_TO' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control date"
                   placeholder="{{'DEVICE_MANAGEMENT_SEARCH_LAST_REQUEST_TO' | translate}}"
                   [(ngModel)]="searchModel.latest_login_to" ngbDatepicker name="latest_request"
                   #latest_request_to="ngbDatepicker"
                   id="latest_request_to">
            <div class="input-group-addon-gray" (click)="latest_request_to.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 d-flex justify-content-md-end align-items-end px-0">
        <div class="btn-group" role="group">
          <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
            {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
          </button>
          <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
            {{'COMMON_BUTTON_SEARCH' | translate}}
          </button>
        </div>
      </div>
    </div>

    <div class="card-body">

      <table class="table table-striped table-bordered">

        <thead>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="DeviceManagement.OrderField.NAME"
                                    [orderType]="queryModel.getOrderType(DeviceManagement.OrderField.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'APP_DEVICE_MANAGEMENT_TABLE_HEADER_DEVICE_NAME' | translate">
            </app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-field-sorter [orderField]="DeviceManagement.OrderField.DEVICE_MODEL"
                                    [orderType]="queryModel.getOrderType(DeviceManagement.OrderField.DEVICE_MODEL)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'APP_DEVICE_MANAGEMENT_TABLE_HEADER_DEVICE_MODEL' | translate">
            </app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-field-sorter [orderField]="DeviceManagement.OrderField.LATEST_LOGIN_USER_NAME"
                                    [orderType]="queryModel.getOrderType(DeviceManagement.OrderField.LATEST_LOGIN_USER_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'APP_DEVICE_MANAGEMENT_TABLE_HEADER_LAST_REQUEST_USER_NAME' | translate">
            </app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-field-sorter [orderField]="DeviceManagement.OrderField.LATEST_LOGIN_TIME"
                                    [orderType]="queryModel.getOrderType(DeviceManagement.OrderField.LATEST_LOGIN_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'APP_DEVICE_MANAGEMENT_TABLE_HEADER_LAST_REQUEST' | translate">
            </app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op [text]="'DEVICE_SEARCH_FIELD_DISABLED' | translate">
            </app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>

        <tbody>
        <tr *ngFor="let index = index; let item of model.items | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }" [disabledTableRow]="item.disabled">
          <td>
            <div class="text-truncate">
              <span class="text-truncate">{{ item.name }}</span>
              <br>
              <div class="text-truncate table-text-subtitle d-flex align-items-center">
                <i class="mr-05 icon-medium icomoon icomoon-device-app-id"></i>
                <div>{{item.application_id}}</div>
              </div>
            </div>
          </td>
          <td class="hidden-lg-down responsive-table-column align-middle">{{ item.device_model }}</td>
          <td class="hidden-sm-down responsive-table-column align-middle">
            <app-assignee-table-cell
              *ngIf="item.latest_login_user_id && users.length > 0"
              [assigneeType]="AssigneeType.USER"
              [user]="getUserById(item.latest_login_user_id)"
              [userGroups]="relatedUserGroups"
              [rightModel]="rightModel">
            </app-assignee-table-cell>
          </td>
          <td class="hidden-xs-down responsive-table-column align-middle">{{ item.latest_login_time | date:'short' }}</td>
          <td class="hidden-sm-down fit text-center align-middle">
            <app-table-badge
            [badgeStyle]="getActivationStateObject(item).badgeStyle"
            [iconClass]="getActivationStateObject(item).iconClass"
            [text]="getActivationStateObject(item).stringKey | translate">
          </app-table-badge>
          </td>
          <td class="align-middle">
            <app-table-options-menu>
              <app-dropdown-item
                [iconClass]="'icomoon-detail'"
                [titleStringKey]="'COMMON_BUTTON_DETAILS'"
                class="cursor-pointer"
                (click)="openDetailDialog(index)">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="rightModel.mobileAppChangeName.hasRight()"
                [iconClass]="'icomoon-modify'"
                [titleStringKey]="'COMMON_BUTTON_EDIT'"
                class="cursor-pointer"
                (click)="openEditDialog(editDialog, item)">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="item.disabled && rightModel.mobileAppDisable.hasRight()"
                [iconClass]="'icomoon-device-active'"
                [titleStringKey]="'COMMON_BUTTON_ENABLE'"
                [itemType]="DropdownItemType.ENABLE"
                class="cursor-pointer"
                (click)="setDisabled($event, item, false)">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="!item.disabled && rightModel.mobileAppDisable.hasRight()"
                [iconClass]="'icomoon-device-disabled'"
                [titleStringKey]="'COMMON_BUTTON_DISABLE'"
                [itemType]="DropdownItemType.DISABLE"
                class="cursor-pointer"
                (click)="setDisabled($event, item, true)">
              </app-dropdown-item>
            </app-table-options-menu>
          </td>
        </tr>
        </tbody>

        <tfoot>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="DeviceManagement.OrderField.NAME"
                                    [orderType]="queryModel.getOrderType(DeviceManagement.OrderField.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'APP_DEVICE_MANAGEMENT_TABLE_HEADER_DEVICE_NAME' | translate">
            </app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-field-sorter [orderField]="DeviceManagement.OrderField.DEVICE_MODEL"
                                    [orderType]="queryModel.getOrderType(DeviceManagement.OrderField.DEVICE_MODEL)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'APP_DEVICE_MANAGEMENT_TABLE_HEADER_DEVICE_MODEL' | translate">
            </app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-field-sorter [orderField]="DeviceManagement.OrderField.LATEST_LOGIN_USER_NAME"
                                    [orderType]="queryModel.getOrderType(DeviceManagement.OrderField.LATEST_LOGIN_USER_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'APP_DEVICE_MANAGEMENT_TABLE_HEADER_LAST_REQUEST_USER_NAME' | translate">
            </app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-field-sorter [orderField]="DeviceManagement.OrderField.LATEST_LOGIN_TIME"
                                    [orderType]="queryModel.getOrderType(DeviceManagement.OrderField.LATEST_LOGIN_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'APP_DEVICE_MANAGEMENT_TABLE_HEADER_LAST_REQUEST' | translate">
            </app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op [text]="'DEVICE_SEARCH_FIELD_DISABLED' | translate">
            </app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>

      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>
  </div>
</div>

<div bsModal [config]="UiConstants.modalConfig" #editDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'COMMON_BUTTON_EDIT' | translate}}</h4>
        <button type="button" class="close" (click)="closeEditDialog(editDialog)" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <div class="form-group"
             [ngClass]="{ 'has-danger': (fieldErrors.name) || (submitted && !edit_item_name.valid && !validName) }">
          <label class="edit-form-label form-control-label">{{'COMMON_DEVICE_NAME' | translate}}</label>
          <div class="edit-form-input-group">
            <input type="text" class="form-control"
                   placeholder="{{'APP_DEVICE_MANAGEMENT_TABLE_HEADER_DEVICE_NAME' | translate}}"
                   [ngClass]="{ 'form-control-danger': (fieldErrors.name) || (submitted && !edit_item_name.valid && !validName) }"
                   [(ngModel)]="editItem.name" (ngModelChange)="removeFieldError(fieldErrors.name)"
                   name="edit_item_name" id="edit_item_name" #edit_item_name="ngModel" required>
            <div class="form-control-feedback" *ngIf="fieldErrors.name">{{fieldErrors.name.text}}</div>
            <div class="form-control-feedback" *ngIf="(submitted && !edit_item_name.valid && !validName)">
              {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
            </div>
          </div>
        </div>
        <div class="form-group">
          <label class="edit-form-label form-control-label">{{'DEVICE_MANAGEMENT_DEVICE_ID' | translate}}</label>
          <div class="edit-form-input-group">
            <input type="text" class="form-control" id="device_id" value="{{editItemDeviceIdText()}}" readonly>
          </div>
        </div>
        <div class="form-group">
          <label class="edit-form-label form-control-label">{{'DEVICE_MANAGEMENT_OS_TYPE' | translate}}</label>
          <div class="edit-form-input-group">
            <input type="text" class="form-control" id="os_type" value="{{editItemOpSystemText()}}" readonly>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeEditDialog(editDialog)">{{'COMMON_BUTTON_CANCEL'
          | translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="saveEditDialog(editDialog)">
          {{'COMMON_BUTTON_SAVE_CHANGES' | translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<div bsModal [config]="UiConstants.modalConfig" #detailDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="detailDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title"><strong>{{'COMMON_BUTTON_DETAILS' | translate}}</strong></h4>
        <button type="button" class="close" (click)="closeDetailDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <div class="row" *ngIf="model.items[selectedDeviceIndex].id">
          <div class="form-group col-md-4 mb-0">
            <label readonly
                   class="col-form-label form-control-label detail-title">{{'COMMON_TABLE_HEADER_ID'|translate}}</label>
          </div>
          <div class="form-group col-md-8 mb-0">
            <label readonly class="col-form-label form-control-label detail-description"> {{ model.items[selectedDeviceIndex].id }} </label>
          </div>
        </div>

        <div class="row" *ngIf="model.items[selectedDeviceIndex].application_id !== ''">
          <div class="form-group col-md-4 mb-0">
            <label readonly
                   class="col-form-label form-control-label detail-title">{{'APP_DEVICE_MANAGEMENT_TABLE_HEADER_APP_ID'|translate}}</label>
          </div>
          <div class="form-group col-md-8 mb-0">
            <label readonly class="col-form-label form-control-label detail-description"> {{ model.items[selectedDeviceIndex].application_id }} </label>
          </div>
        </div>

        <div class="row" *ngIf="model.items[selectedDeviceIndex].device_id !== ''">
          <div class="form-group col-md-4 mb-0">
            <label readonly
                   class="col-form-label form-control-label detail-title">{{'DEVICE_MANAGEMENT_DEVICE_ID'|translate}}</label>
          </div>
          <div class="form-group col-md-8 mb-0">
            <label readonly class="col-form-label form-control-label detail-description"> {{ model.items[selectedDeviceIndex].device_id }} </label>
          </div>
        </div>

        <div class="row" *ngIf="model.items[selectedDeviceIndex].name !== ''">
          <div class="form-group col-md-4 mb-0">
            <label readonly
                   class="col-form-label form-control-label detail-title">{{'COMMON_DEVICE_NAME'|translate}}</label>
          </div>
          <div class="form-group col-md-8 mb-0">
            <label readonly class="col-form-label form-control-label detail-description"> {{ model.items[selectedDeviceIndex].name }} </label>
          </div>
        </div>

        <div class="row" *ngIf="model.items[selectedDeviceIndex].device_model !== ''">
          <div class="form-group col-md-4 mb-0">
            <label readonly
                   class="col-form-label form-control-label detail-title">{{'APP_DEVICE_MANAGEMENT_TABLE_HEADER_DEVICE_MODEL'|translate}}</label>
          </div>
          <div class="form-group col-md-8 mb-0">
            <label readonly class="col-form-label form-control-label detail-description"> {{ model.items[selectedDeviceIndex].device_model }} </label>
          </div>
        </div>

        <div class="row" *ngIf="model.items[selectedDeviceIndex].operation_system_type !== '' && model.items[selectedDeviceIndex].operation_system_version !== ''">
          <div class="form-group col-md-4 mb-0">
            <label readonly
                   class="col-form-label form-control-label detail-title">{{'DEVICE_MANAGEMENT_OS_TYPE'|translate}}</label>
          </div>
          <div class="form-group col-md-8 mb-0">
            <label readonly class="col-form-label form-control-label detail-description"> {{ model.items[selectedDeviceIndex].operation_system_type }} {{model.items[selectedDeviceIndex].operation_system_version}}</label>
          </div>
        </div>

        <div class="row" *ngIf="model.items[selectedDeviceIndex].latest_login_time !== ''">
          <div class="form-group col-md-4 mb-0">
            <label readonly
                   class="col-form-label form-control-label detail-title">{{'APP_DEVICE_MANAGEMENT_TABLE_HEADER_LAST_REQUEST'|translate}}</label>
          </div>
          <div class="form-group col-md-8 mb-0">
            <label readonly class="col-form-label form-control-label detail-description"> {{ model.items[selectedDeviceIndex].latest_login_time | date:'short'}}</label>
          </div>
        </div>

        <div class="row" *ngIf="model.items[selectedDeviceIndex].latest_login_user_name !== ''">
          <div class="form-group col-md-4 mb-0">
            <label readonly
                   class="col-form-label form-control-label detail-title">{{'APP_DEVICE_MANAGEMENT_TABLE_HEADER_LAST_REQUEST_USER_NAME'|translate}}</label>
          </div>
          <div class="form-group col-md-8 mb-0">
            <label readonly class="col-form-label form-control-label detail-description"> {{ model.items[selectedDeviceIndex].latest_login_user_name }}</label>
          </div>
        </div>
      </div>

      <div class="modal-footer justify-content-right">
        <button type="button" class="btn btn-outline-primary" (click)="closeDetailDialog()">
          {{'COMMON_BUTTON_CLOSE' | translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
