import { Component, Input, OnInit } from '@angular/core';
import { TaskRecordBoardModel } from '../models/task-record-board.model';
import { DomSanitizer } from '@angular/platform-browser';
import { PaletteColorPickerComponent } from '../../../../../shared/palette-color-picker/palette-color-picker.component';
import {
  CalendarTaskRecordDetailDialogComponent
} from '../../../../calendar/calendar-task-record-detail-dialog/calendar-task-record-detail-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-task-record-board-card',
  templateUrl: './task-record-board-card.component.html',
  styleUrls: ['./task-record-board-card.component.scss']
})
export class TaskRecordBoardCardComponent implements OnInit {

  @Input()
  model?: TaskRecordBoardModel;

  constructor(private sanitizer: DomSanitizer,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  getBackgroundColor() {
    if (!this.model || this.model.task.taskRecordColor === 0) {
      return '';
    }
    return PaletteColorPickerComponent.getListColorOdd(this.model!.task.taskRecordColor);
  }

  getBorderColor() {
    if (!this.model || this.model.task.taskRecordColor === 0) {
      return '';
    }
    return PaletteColorPickerComponent.resolveColor(this.model!.task.taskRecordColor);
  }

  onCardClicked() {
    console.log(this.model?.taskRecordId)
    if (this.model) {
      CalendarTaskRecordDetailDialogComponent.openDialog(this.dialog, {taskRecordId: this.model.taskRecordId}, () => {
      });
    }
  }

}
