<div class="state-outer-container row m-0" *ngIf="stateList.length > 0">
  <div class="state-container {{state.type === OrderDetailStateType.CURRENT ? state.displayState.style : ''}}"
       *ngFor="let state of stateList">
    <div class="state-indicator-container">
      <div class="state-indicator-line"></div>
      <div class="state-indicator-base"
           [ngClass]="{
             'state-indicator-waiting': state.type === OrderDetailStateType.WAITING,
             'state-indicator-current': state.type === OrderDetailStateType.CURRENT,
             'state-indicator-completed': state.type === OrderDetailStateType.COMPLETED
           }">
      </div>
    </div>
    <div class="state-card"
         [ngClass]="{
           'state-card-waiting': state.type === OrderDetailStateType.WAITING,
           'state-card-current': state.type === OrderDetailStateType.CURRENT,
           'state-card-completed': state.type === OrderDetailStateType.COMPLETED
         }">
      <div class="state-icon">
        <i class="icomoon {{state.displayState.icon}}" *ngIf="state.displayState.icon"></i>
      </div>
      <div class="state-text">{{state.displayState.stringKey | translate}}</div>
    </div>
  </div>
</div>
