<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div class="breadcrumb-menu">
    <!--CREATE-->
    <div class="breadcrumb-button-icon-container cursor-pointer negative-margin-right"
         uiSref="Admin.FormTableCreate" *ngIf="rightModel.formTableCreate.hasRight()">
      <a class="btn-setting d-flex align-items-center" title="{{'COMMON_BUTTON_ADD' | translate}}">
        <i class="icomoon icomoon-add"></i>
        <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_ADD' | translate}}</span>
      </a>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="card">

    <div class="card-header">
      {{'FORM_TABLE_PANEL_HEADING_LIST' | translate}}

      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()" title="{{'COMMON_SHOW_SEARCH_HINT' | translate}}"><i class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>

    <div class="card-body border-bottom" *ngIf="showSearch">
      <div class="row form-group">
        <div class="col-md-4">
          <label class="search-label">{{'FORM_TABLE_SEARCH_FIELD_ID' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'FORM_TABLE_SEARCH_FIELD_ID' | translate}}"
                 maxlength="{{UiConstants.maxInputLongLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [textMask]="{mask: InputMask.NATURAL_INTEGER, guide: false}"
                 [(ngModel)]="searchModel.id" name="id" id="id" #id="ngModel">
        </div>
        <div class="col-md-4">
          <label class="search-label">{{'COMMON_NAME' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.name" name="name" id="name" #name="ngModel">
        </div>
        <div class="col-md-4">
          <label class="search-label">{{'COMMON_EXTERNAL_ID' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'COMMON_EXTERNAL_ID' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.externalId" name="externalId" id="externalId" #externalId="ngModel">
        </div>
        <div class="col-md-4">
          <label class="search-label">{{'FORM_TABLE_SEARCH_FIELD_DISABLED' | translate}}</label>
          <select class="form-control" [compareWith]="SelectUtils.compareObjects" [(ngModel)]="searchModel.disabled"
                  id="disabled" name="disabled" #disabled="ngModel">
            <option *ngFor="let item of formTableDisabledItems" [ngValue]="item">{{item.text}}</option>
          </select>
        </div>
      </div>
      <div class="col-md-12 d-flex justify-content-md-end align-items-end px-0">
        <div class="btn-group" role="group">
          <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
            {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
          </button>
          <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
            {{'COMMON_BUTTON_SEARCH' | translate}}
          </button>
        </div>
      </div>
    </div> <!-- ./card-body end -->

    <div class="card-body">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header hidden-xs-down"><app-table-field-sorter [orderField]="FormTable.OrderField.ID" [orderType]="queryModel.getOrderType(FormTable.OrderField.ID)" (orderChange)="orderBy($event)" [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter></th>
          <th class="table-sorter-header long"><app-table-field-sorter [orderField]="FormTable.OrderField.NAME" [orderType]="queryModel.getOrderType(FormTable.OrderField.NAME)" (orderChange)="orderBy($event)" [text]="'COMMON_NAME' | translate"></app-table-field-sorter></th>
          <th class="table-sorter-header hidden-md-down"><app-table-field-sorter [orderField]="FormTable.OrderField.CREATION_TIME" [orderType]="queryModel.getOrderType(FormTable.OrderField.CREATION_TIME)" (orderChange)="orderBy($event)" [text]="'COMMON_TABLE_HEADER_CREATION_TIME' | translate"></app-table-field-sorter></th>
          <th class="table-sorter-header hidden-md-down"><app-table-field-sorter  [orderField]="FormTable.OrderField.UPDATE_TIME" [orderType]="queryModel.getOrderType(FormTable.OrderField.UPDATE_TIME)" (orderChange)="orderBy($event)" [text]="'COMMON_TABLE_HEADER_UPDATE_TIME' | translate"></app-table-field-sorter></th>
          <th class="table-sorter-header w-table-options-menu"><app-table-sorter-no-op></app-table-sorter-no-op></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let formTable of formTableList | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }" [disabledTableRow]="formTable.disabled">
          <td class="fit hidden-xs-down align-middle">{{formTable.id}}</td>
          <td class="responsive-table-column long">
            <div class="d-flex justify-content-start align-items-center">
              <div>
                <a *ngIf="rightModel.formTableUpdate.hasRight()"
                   class="id-text cursor-pointer"
                   href="#" onclick="return false"
                   uiSref="Admin.FormTableEdit"
                   [uiParams]="{ id: formTable.id }"
                   title="{{'COMMON_BUTTON_EDIT' | translate}}">
                  {{formTable.name}}
                </a>
                <span *ngIf="!rightModel.formTableUpdate.hasRight()">{{formTable.name}}</span>
                <div class="table-text-subtitle">
                  {{formTable.externalId}}
                </div>
              </div>
            </div>
          </td>
          <td class="fit hidden-md-down align-middle">{{formTable.creationTime.toUtcIsoString() | date:'short'}}</td>
          <td class="fit hidden-md-down align-middle">{{formTable.updateTime.toUtcIsoString() | date:'short'}}</td>
          <td class="align-middle">
            <app-table-options-menu>
              <app-dropdown-item
                *ngIf="rightModel.formTableUpdate.hasRight()"
                [iconClass]="'icomoon-modify'"
                [titleStringKey]="'COMMON_BUTTON_EDIT'"
                class="cursor-pointer"
                uiSref="Admin.FormTableEdit"
                [uiParams]="{ id: formTable.id }">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="formTable.disabled && rightModel.formTableDisable.hasRight()"
                [iconClass]="'icomoon-enable'"
                [titleStringKey]="'COMMON_BUTTON_ENABLE'"
                [itemType]="DropdownItemType.ENABLE"
                class="cursor-pointer"
                (click)="setDisabled($event, formTable, false)">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="!formTable.disabled && rightModel.formTableDisable.hasRight()"
                [iconClass]="'icomoon-disable'"
                [titleStringKey]="'COMMON_BUTTON_DISABLE'"
                [itemType]="DropdownItemType.DISABLE"
                class="cursor-pointer"
                (click)="setDisabled($event, formTable, true)">
              </app-dropdown-item>
            </app-table-options-menu>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header hidden-xs-down"><app-table-field-sorter [orderField]="FormTable.OrderField.ID" [orderType]="queryModel.getOrderType(FormTable.OrderField.ID)" (orderChange)="orderBy($event)" [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter></th>
          <th class="table-sorter-header long"><app-table-field-sorter [orderField]="FormTable.OrderField.NAME" [orderType]="queryModel.getOrderType(FormTable.OrderField.NAME)" (orderChange)="orderBy($event)" [text]="'COMMON_NAME' | translate"></app-table-field-sorter></th>
          <th class="table-sorter-header hidden-md-down"><app-table-field-sorter [orderField]="FormTable.OrderField.CREATION_TIME" [orderType]="queryModel.getOrderType(FormTable.OrderField.CREATION_TIME)" (orderChange)="orderBy($event)" [text]="'COMMON_TABLE_HEADER_CREATION_TIME' | translate"></app-table-field-sorter></th>
          <th class="table-sorter-header hidden-md-down"><app-table-field-sorter  [orderField]="FormTable.OrderField.UPDATE_TIME" [orderType]="queryModel.getOrderType(FormTable.OrderField.UPDATE_TIME)" (orderChange)="orderBy($event)" [text]="'COMMON_TABLE_HEADER_UPDATE_TIME' | translate"></app-table-field-sorter></th>
          <th class="table-sorter-header w-table-options-menu"><app-table-sorter-no-op></app-table-sorter-no-op></th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>
  </div>
</div>
