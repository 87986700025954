import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProjectRecord, ProjectRecordService } from '../../../lib/project/record/project-record.service';
import { RightModel } from '../../../app.rights';
import { RightResolver, RightService } from '../../../lib/right.service';
import { Models } from '../../../util/model-utils';
import { LocalDate } from '../../../lib/util/dates';
import { Address } from '../../../lib/address';
import { ConfigModel } from '../../../util/task-record-utils';
import { ConfigurationService } from '../../../lib/core-ext/configuration.service';
import { FormRecordSerializer } from '../../../util/form/form-utils';
import { StateName } from '../../../app.state-names';
import PostalAddressMapper = Address.PostalAddressMapper;
import { UIRouter } from '@uirouter/angular';

@Component({
  selector: 'app-calendar-project-record-detail-dialog',
  templateUrl: './calendar-project-record-detail-dialog.component.html',
  styleUrls: ['./calendar-project-record-detail-dialog.component.scss']
})
export class CalendarProjectRecordDetailDialogComponent implements OnInit {

  rightModel: RightModel = RightModel.empty();
  detailModel: DetailModel = new DetailModel();
  config: ConfigModel = new ConfigModel();

  constructor(
    private rightService: RightService,
    private projectRecordService: ProjectRecordService,
    private uiRouter: UIRouter,
    private configService: ConfigurationService,
    private formRecordSerializer: FormRecordSerializer,
    public dialogRef: MatDialogRef<CalendarProjectRecordDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CalendarProjectRecordDetailDialogData
  ) {
    this.config = this.configService.getConfigurationModel();
    this.loadRightModels(() => this.loadProjectRecord());
  }

  private loadRightModels(completion: () => void) {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        completion();
      }
    );
  }

  public static openDialog(
    dialog: MatDialog,
    data: CalendarProjectRecordDetailDialogData,
    resultCallback: () => void) {
    const dialogRef = dialog.open(CalendarProjectRecordDetailDialogComponent, {
      closeOnNavigation: true,
      data: data,
      width: '60%',
      height: 'auto',
      panelClass: 'custom-dialog-container'
    });
    dialogRef.afterClosed().subscribe(() => {
      resultCallback();
    });
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close();
  }

  navigateToDetail() {
    this.uiRouter.stateService.go(StateName.PROJECT_RECORD_DETAIL, {
      projectId: this.detailModel.projectId,
      id: this.detailModel.projectRecordId
    });
    this.closeDialog();
  }

  navigateToEdit() {
    this.uiRouter.stateService.go(StateName.PROJECT_RECORD_EDIT, {
      projectId: this.detailModel.projectId,
      id: this.detailModel.projectRecordId
    });
    this.closeDialog();
  }


  private loadProjectRecord() {
    this.projectRecordService.globalGet({
      id: this.data.projectRecordId
    }).subscribe((result: ProjectRecord.ProjectRecord) => {
      this.detailModel.projectName = result.project.name;
      this.detailModel.projectRecordName = result.name;
      this.detailModel.projectId = result.project.id;
      this.detailModel.projectRecordId = result.id;
      this.detailModel.projectRecordExternalId = result.externalId;
      this.detailModel.startDate = result.startDate;
      this.detailModel.endDate = result.endDate;
      this.detailModel.billingStartDate = result.billingStartDate;
      this.detailModel.billingEndDate = result.billingEndDate;
      this.detailModel.billingType = result.billingType ? ProjectRecord.billingTypes.find(t => t.type === result.billingType)!.stringKey : '';
      this.detailModel.amount = result.amount ? Models.numberToString(result.amount) + ' Ft' : '';
      this.detailModel.assigneeUser = result.assigneeUser ? result.assigneeUser.personName + ' (' + result.assigneeUser.userName + ')' : '';
      this.detailModel.billingPeriodInMonths = Models.numberToString(result.billingPeriodInMonths);
      this.detailModel.postalAddress = result.postalAddress
        ? PostalAddressMapper.toString(result.postalAddress, this.config.postalAddressFormat)
        : '';
      if (result.displayedFormFieldRecord) {
        this.formRecordSerializer.serializeField(result.project.displayedFormField, result.displayedFormFieldRecord)
          .subscribe(r => this.detailModel.displayedFormFieldRecord = r);
      }
    });
  }
}

export class CalendarProjectRecordDetailDialogData {
  projectRecordId: number;
}

class DetailModel {
  // Project record
  projectName: string = '';
  projectRecordName: string = '';
  projectRecordExternalId: string = '';
  projectId?: number;
  projectRecordId?: number;
  startDate?: LocalDate;
  endDate?: LocalDate;
  amount: string = '';
  assigneeUser: string = '';
  billingStartDate?: LocalDate;
  billingEndDate?: LocalDate;
  billingType: string = '';
  billingPeriodInMonths: string = '';
  postalAddress: string = '';
  displayedFormFieldRecord: string = '';
}
