/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Map as ImmutableMap } from 'immutable';
import { IdentityMessage, ListQuery } from '../util/messages';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { ResourceQueryResult } from '../util/services';
import { StockItemUnitOfMeasure } from './stock-item-unit-of-measure';
/* eslint-enable */

export namespace StockRecordLog {

  export enum OrderField {
    ID,
    STOCK_NAME,
    TASK_RECORD_NAME,
    STOCK_ITEM_NAME,
    STOCK_ITEM_PRODUCT_CODE,
    AMOUNT_FROM,
    AMOUNT_TO,
    DIFFERENCE,
    CREATION_TIME,
    EDITOR_PERSON_NAME,
    SOURCE
  }

  export class Keys {

    private static readonly ID = 'id';
    private static readonly STOCK_NAME = 'stock_name';
    private static readonly TASK_RECORD_NAME = 'task_record_name';
    private static readonly STOCK_ITEM_NAME = 'stock_item_name';
    private static readonly STOCK_ITEM_PRODUCT_CODE = 'stock_item_product_code';
    private static readonly AMOUNT_FROM = 'amount_from';
    private static readonly AMOUNT_TO = 'amount_to';
    private static readonly DIFFERENCE = 'amount_to';
    private static readonly CREATION_TIME = 'creation_time';
    private static readonly EDITOR_PERSON_NAME = 'editor_person_name';
    private static readonly SOURCE = 'source';

    private static readonly orderFieldKeyMap: ImmutableMap<StockRecordLog.OrderField, string> = ImmutableMap.of(
      StockRecordLog.OrderField.ID, Keys.ID,
      StockRecordLog.OrderField.STOCK_NAME, Keys.STOCK_NAME,
      StockRecordLog.OrderField.TASK_RECORD_NAME, Keys.TASK_RECORD_NAME,
      StockRecordLog.OrderField.STOCK_ITEM_NAME, Keys.STOCK_ITEM_NAME,
      StockRecordLog.OrderField.STOCK_ITEM_PRODUCT_CODE, Keys.STOCK_ITEM_PRODUCT_CODE,
      StockRecordLog.OrderField.AMOUNT_FROM, Keys.AMOUNT_FROM,
      StockRecordLog.OrderField.AMOUNT_TO, Keys.AMOUNT_TO,
      StockRecordLog.OrderField.DIFFERENCE, Keys.DIFFERENCE,
      StockRecordLog.OrderField.CREATION_TIME, Keys.CREATION_TIME,
      StockRecordLog.OrderField.EDITOR_PERSON_NAME, Keys.EDITOR_PERSON_NAME,
      StockRecordLog.OrderField.SOURCE, Keys.SOURCE,
    );

    public static toOrderFieldKey(field: StockRecordLog.OrderField): string {
      return Keys.orderFieldKeyMap.get(field)!;
    }

  }

}

export interface StockRecordLogQuery extends ListQuery.Paging, ListQuery.Order, ListQuery.QueryText, ListQuery.Fields {
  stock_id?: string;
  task_record_id?: string;
  editor_id?: string;
  stock_item_id?: string;
  creation_time_from?: string;
  creation_time_to?: string;
  source?: string;
}

export interface StockRecordLog {
  id: number;
  source: StockRecordLogSource;
  stock: {
    id: number;
    name: string;
  };
  stock_record: {
    id: number;
    stock_item: {
      id: number;
      external_id: string;
      product_code: string;
      name: string;
      unit: string;
      package_data?: StockItemUnitOfMeasure.StockItemUnitOfMeasure;
    };
  };
  editor?: {
    id: number;
    person_name: string;
    user_name: string;
    profile_picture_hash?: string;
  };
  task_record?: {
    id: number;
    task_id: number;
    name: string;
  };
  amount_from: number;
  amount_to: number;
  difference: number;
  creation_time: string;
}

export type StockRecordLogSource =
  'TASK_SUBMIT'
  | 'TASK_FINISH'
  | 'TASK_VALIDATE'
  | 'TASK_REVERT'
  | 'CREATE'
  | 'UPDATE'
  | 'MOVEMENT'
  | 'DISPOSAL'
  | 'TASK_INTAKE'
  | 'INVOICE_INTAKE'
  | 'INVOICE_STORNO'
  | 'TASK_MOVEMENT'
  | 'TASK_OPEN'
  | 'TASK_UNSUBMIT'
  | 'INTAKE'
  | 'OUTTAKE'
  | 'OUTTAKE_REVERSAL'
  | 'STOCK_TAKING'
  | 'API_IMPORT';

export class StockRecordLogSourceObject {
  source: StockRecordLogSource;
  stringKey: string;
}

export const stockRecordLogSources: StockRecordLogSourceObject[] = [
  {source: 'TASK_SUBMIT', stringKey: 'STOCK_RECORD_LOG_SOURCE_TASK_SUBMIT'},
  {source: 'TASK_UNSUBMIT', stringKey: 'STOCK_RECORD_LOG_SOURCE_TASK_UNSUBMIT'},
  {source: 'TASK_FINISH', stringKey: 'STOCK_RECORD_LOG_SOURCE_TASK_FINISH'},
  {source: 'TASK_VALIDATE', stringKey: 'STOCK_RECORD_LOG_SOURCE_TASK_VALIDATE'},
  {source: 'TASK_REVERT', stringKey: 'STOCK_RECORD_LOG_SOURCE_TASK_REVERT'},
  {source: 'CREATE', stringKey: 'STOCK_RECORD_LOG_SOURCE_CREATE'},
  {source: 'UPDATE', stringKey: 'STOCK_RECORD_LOG_SOURCE_UPDATE'},
  {source: 'MOVEMENT', stringKey: 'STOCK_RECORD_LOG_SOURCE_MOVEMENT'},
  {source: 'DISPOSAL', stringKey: 'STOCK_RECORD_LOG_SOURCE_DISPOSAL'},
  {source: 'TASK_INTAKE', stringKey: 'STOCK_RECORD_LOG_SOURCE_TASK_INTAKE'},
  {source: 'INVOICE_INTAKE', stringKey: 'STOCK_RECORD_LOG_SOURCE_INVOICE_INTAKE'},
  {source: 'INVOICE_STORNO', stringKey: 'STOCK_RECORD_LOG_SOURCE_INVOICE_STORNO'},
  {source: 'TASK_MOVEMENT', stringKey: 'STOCK_RECORD_LOG_SOURCE_TASK_MOVEMENT'},
  {source: 'TASK_OPEN', stringKey: 'STOCK_RECORD_LOG_SOURCE_TASK_OPEN'},
  {source: 'INTAKE', stringKey: 'STOCK_RECORD_LOG_SOURCE_INTAKE'},
  {source: 'OUTTAKE', stringKey: 'STOCK_RECORD_LOG_SOURCE_OUTTAKE'},
  {source: 'OUTTAKE_REVERSAL', stringKey: 'STOCK_RECORD_LOG_SOURCE_OUTTAKE_REVERSAL'},
  {source: 'STOCK_TAKING', stringKey: 'STOCK_RECORD_LOG_SOURCE_STOCK_TAKING'},
  {source: 'API_IMPORT', stringKey: 'STOCK_RECORD_LOG_SOURCE_API_IMPORT'},
];

@Injectable()
export class StockRecordLogService extends BaseHttpService {

  query(request: StockRecordLogQuery): Observable<ResourceQueryResult<StockRecordLog>> {
    return this.http.get<ResourceQueryResult<StockRecordLog>>(this.url, this.parseParams(request));
  }

  get(request: IdentityMessage): Observable<StockRecordLog> {
    return this.http.get<StockRecordLog>(this.url + `${request.id}`);
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/stock-record-logs/');
  }

}
