import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Form } from '../../../lib/form/form.service';
import { FileItem, FileUploader, ParsedResponseHeaders } from 'ng2-file-upload';
import { FileUploaderUtil } from '../../../util/file-uploader-util';
import { Subject } from 'rxjs';
import { ErrorMessageService } from '../../../lib/error-message-parser.service';
import { UiConstants } from '../../../util/core-utils';

@Component({
  selector: 'app-form-import-dialog',
  templateUrl: './form-import-dialog.component.html',
  styleUrls: ['./form-import-dialog.component.scss']
})
export class FormImportDialogComponent implements OnInit {
  UiConstants = UiConstants;

  @ViewChild('importDialog', { static: true }) importDialog: ModalDirective;
  importDialogVisible: boolean = false;
  importDnDVisible: boolean = true;
  formImportModel: FormImportModel = new FormImportModel();
  uploadGlobalErrors: string;

  @Input()
  formService: Form.Service;

  @Input()
  parentId: number;

  @Output()
  onFormImported: EventEmitter<any> = new EventEmitter();

  constructor(private uploadUtil: FileUploaderUtil,
              private errorMessageService: ErrorMessageService) {
  }

  ngOnInit() {
  }

  openImportDialog() {
    this.importDialogVisible = true;
    this.importDnDVisible = true;
    this.importDialog.show();
    this.formImportModel = new FormImportModel();
  }

  closeImportDialog() {
    this.importDialogVisible = false;
    this.importDialog.hide();
    this.formImportModel.ignoreDisabledItems = true;
  }

  onImportableFormSelected(files: File[]) {
    this.formImportModel.importResult = undefined;
    const file = files[0];
    this.formImportModel.fileName = file.name;
    const fr = new FileReader();
    const subject: Subject<any> = new Subject<any>();
    fr.onloadend = (function (f) {
      return function (e) {
        try {
          const res = JSON.parse(this.result);
          subject.next(res);
        }
        catch (e) {
          subject.error('');
        }
      };
    })(file);
    fr.readAsText(file);
    subject.asObservable().subscribe((form: any) => {
      if (form && form['form'] && form['version']) {
        this.formImportModel.parsedForm = form;
        this.formImportModel.importResult = undefined;
        this.importDnDVisible = false;
      }
      else {
        this.formImportModel.importResult = 'FORMAT_ERROR';
        this.importDnDVisible = true;
      }
    }, error => {
      this.formImportModel.importResult = 'FORMAT_ERROR';
      this.importDnDVisible = true;
    });
  }

  importForm() {
    this.formService.importForm({
      parentId: this.parentId,
      ignoreDisabledFields: this.formImportModel.ignoreDisabledItems,
      form: this.formImportModel.parsedForm['form'],
      version: this.formImportModel.parsedForm['version']
    }).subscribe((result) => {
      this.onFormImported.emit();
      this.closeImportDialog();
    }, (error: any) => {
      this.formImportModel.importResult = 'ERROR';
      this.importDnDVisible = true;
      const localizedError = this.errorMessageService.parseError(error);
      this.uploadGlobalErrors = localizedError!.globalErrors.join(',');
    });
  }

}

export class FormImportModel {
  fileName: string = '';
  importResult?: string = undefined;
  ignoreDisabledItems: boolean = true;
  parsedForm: string = '';
}
