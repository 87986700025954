<ng-container *ngIf="currentNumberOfItems > 0">

  <!--for small screens and desktops-->
  <div class="row" id="pagination_container">
    <div class="col-md-8 col-sm-8 col-middle" id="pagination">
      <!-- NOTE: Do not change/remove the key of translated texts. Change the value in the JSON dictionary. -->
      <app-pagination-controls class="app-pagination" (pageChange)="onPageChange($event)"
                               [id]="id"
                               [maxSize]="paginationMaxSize"
                               [directionLinks]="previousNextVisible"
                               [autoHide]="false"
                               [previousLabel]="'COMMON_PAGINATION_PREVIOUS_LABEL' | translate"
                               [nextLabel]="'COMMON_PAGINATION_NEXT_LABEL' | translate">
      </app-pagination-controls>
    </div>
    <div class="col-md-4 d-flex justify-content-end mt-1 mt-sm-1 mt-md-0 mt-lg-0 align-items-center" id="number_of_items">
        <span class="text-nowrap mr-1">{{formatNumberOfItems()}}</span>
        <select *ngIf="!hideItemsPerPage" class="form-control number-of-items-select" [(ngModel)]="itemsPerPage" (ngModelChange)="onItemsPerPageChange($event)" id="itemPerPage" name="itemPerPage" #itemPerPage="ngModel">
          <option *ngFor="let pageNumber of pageNumbers" [ngValue]="pageNumber">{{pageNumber}}</option>
        </select>
    </div>
  </div>
  <!--for small screens and desktops-->

</ng-container>

