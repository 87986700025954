/* eslint-disable */
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FileAttachmentGalleryComponent } from '../../../shared/file-attachment-gallery/file-attachment-gallery.component';
import { FileAttachmentModel } from '../../../util/file-attachment.model';
import { FileAttachment } from '../../../lib/util/file-attachments';
import { LegacyProcessTaskService } from '../../../lib/legacy-process/legacy-process-task.service';
import { Observable } from 'rxjs';
import { NamedBlobFile } from '../../../lib/util/downloaded-files';
import { AttachmentContainer } from '../../../shared/file-attachment-gallery/attachment-container';

/* eslint-enable */

@Component({
  selector: 'app-legacy-process-task-attachment',
  templateUrl: './legacy-process-task-attachment.component.html',
  styleUrls: ['./legacy-process-task-attachment.component.scss']
})
export class LegacyProcessTaskAttachmentComponent implements OnInit, AttachmentContainer {

  @Input()
  processId: number;

  @Input()
  processTaskId: number;

  @ViewChild(FileAttachmentGalleryComponent)
  galleryView: FileAttachmentGalleryComponent;

  @Output()
  private openImageViewerWindowEvent = new EventEmitter();

  @Output()
  private clickedImageEventEmitter: EventEmitter<FileAttachmentModel> = new EventEmitter();

  fileLoaderFactory = this;

  attachments: FileAttachment[] = [];

  constructor(private processTaskService: LegacyProcessTaskService) {
  }

  ngOnInit() {
    this.processTaskService.getAttachments({
      processTaskId: this.processTaskId,
      processId: this.processId,
    }).subscribe((result: FileAttachment[]) => {
      this.attachments = result;
    });
  }

  activateView() {
    this.galleryView.activateView();
  }

  public createFileLoader(attachment: FileAttachment): Observable<NamedBlobFile> {
    return this.processTaskService.downloadAttachment({
      processId: this.processId,
      processTaskId: this.processTaskId,
      attachment: attachment,
    });
  }

  openImageViewerWindow() {
    this.openImageViewerWindowEvent.emit();
  }

  onNextClicked() {
    this.galleryView.onNextClicked(this.galleryView.clickedImage);
  }

  onPreviousClicked() {
    this.galleryView.onPreviousClicked(this.galleryView.clickedImage);
  }

  clickedImageEventEmitted(clickedImage: FileAttachmentModel) {
    this.clickedImageEventEmitter.emit(clickedImage);
  }

}
