<div class="d-flex flex-row flex-wrap justify-content-between breadcrumb position-relative p-0">
  <div class="d-flex align-items-center pl-1 mt-1">
    <ol class="list-style-none pl-0 d-flex">
      <li class="breadcrumb-item" *ngFor="let item of parents"><a uiSref="{{item.uiSref}}" [uiParams]="item.uiParam">{{item.name}}</a></li>
      <li class="breadcrumb-item active">{{self}}</li>
    </ol>
  </div>
  <ng-content></ng-content>
</div>
<!-- /.d-flex fle-row flex-wrap -->
