import { DqlQuery, Query } from '../field';
import { LazyReference } from '../../../util/lazy';
import { User } from './user';
import { OrderImportError as OrderImportErrorNamespace } from '../../order-import-error/order-import-error.service';
import { Company } from './company';
import NumberField = DqlQuery.NumberField;
import DateTimeField = DqlQuery.DateTimeField;
import StringField = DqlQuery.StringField;

// Each class should be an interface, but it is easier to define the constant fields in the constructors.
// For now we have only one query language so it is not a problem.
// Always use the public Query namespace for variable definitions so we will be able to introduce other language easily.

export class OrderImportError {

  private readonly companyRef: LazyReference<Company>;

  private readonly userRef: LazyReference<User>;

  readonly id: Query.NumberField;

  readonly realm: Query.EnumField<OrderImportErrorNamespace.OrderImportErrorRealm>;

  readonly state: Query.EnumField<OrderImportErrorNamespace.OrderImportErrorState>;

  readonly errorReason: Query.EnumField<OrderImportErrorNamespace.OrderImportErrorReason>;

  readonly creationTime: Query.DateTimeField;

  readonly updateTime: Query.DateTimeField;

  readonly externalId1: Query.StringField;

  readonly externalId2: Query.StringField;

  readonly extraId1: Query.StringField;

  get user(): User {
    return this.userRef.get();
  }

  get company(): Company {
    return this.companyRef.get();
  }

  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this.userRef = LazyReference.of(
      () => new User(prefix + 'user.')
    );
    this.companyRef = LazyReference.of(
      () => new Company(prefix + 'company.')
    );
    this.id = new NumberField(prefix + 'id');
    this.realm =
      new DqlQuery.EnumField<OrderImportErrorNamespace.OrderImportErrorRealm>(prefix + 'realm', (sts) => sts);
    this.state =
      new DqlQuery.EnumField<OrderImportErrorNamespace.OrderImportErrorState>(prefix + 'state', (sts) => sts);
    this.errorReason =
      new DqlQuery.EnumField<OrderImportErrorNamespace.OrderImportErrorReason>(prefix + 'error_reason', (sts) => sts);
    this.creationTime = new DateTimeField(prefix + 'creation_time');
    this.updateTime = new DateTimeField(prefix + 'update_time');
    this.externalId1 = new StringField(prefix + 'external_id1');
    this.externalId2 = new StringField(prefix + 'external_id2');
    this.extraId1 = new StringField(prefix + 'extra_id1');
  }

}
