import { Component, OnInit } from '@angular/core';
import { ConfigurationResource, ConfigurationService } from '../../../lib/core-ext/configuration.service';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import Configuration = ConfigurationResource.Configuration;
import { StateName } from '../../../app.state-names';
import { TranslateService } from '@ngx-translate/core';
import registrationTypes = ConfigurationResource.registrationTypes;
import RegistrationType = ConfigurationResource.RegistrationType;

@Component({
  selector: 'app-registration-settings-container',
  templateUrl: './registration-settings-container.component.html',
  styleUrls: ['./registration-settings-container.component.scss']
})
export class RegistrationSettingsContainerComponent implements OnInit {

  RegistrationType = RegistrationType;

  configuration: Configuration;
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  get isRegistrationEnabled(): boolean {
    return this.configurationService.isRegistrationEnabled();
  }

  constructor(
    private configurationService: ConfigurationService,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
    this.translateService.get('MENU_NAVBAR_REGISTRATION_SETTINGS').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.loadConfiguration();
  }

  private loadConfiguration() {
    this.configuration = this.configurationService.getConfiguration();
  }

  getRegistrationTypeKey(): string {
    return registrationTypes.find(t => t.type === this.configuration.feature_flags.registration_enabled!)!.stringKey;
  }

}
