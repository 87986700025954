import * as draw2d from '../../../../../../../node_modules/draw2d/dist/draw2d.js';
import { LineSelectionFeedbackPolicy } from '../policy/line-selection-feedback-policy';
import { ConnectionRouter } from '../router/connection-router';
import { Workflow } from '../../../../../lib/workflow/workflow.service';
import { fromEvent, Subscription } from 'rxjs';
import { Group } from '../shape/group';
import { Label } from '../shape/label';

export class Connection extends draw2d.Connection {

  private movedSubscription?: Subscription;

  constructor(readonly?: boolean) {
    super();
    super.setStroke(1);
    super.setOutlineStroke(0);
    super.setOutlineColor('#303030');
    super.setColor('#8D949C');
    super.installEditPolicy(new LineSelectionFeedbackPolicy());
    super.setRouter(new ConnectionRouter());
    super.setDeleteable(false);
    super.setDraggable(!readonly);
    this.createAndSetArrowDecorator();
    super.on('added', () => {
      // @ts-ignore
      if (this.canvas && (!this.getUserData() || !this.getUserData().addedByPainter)) {
        // @ts-ignore
        if (this.sourcePort.parent.id === this.targetPort.parent.id) {
          // @ts-ignore
          const height = this.sourcePort.parent.height;
          // @ts-ignore
          const requiredY = this.sourcePort.parent.y + height + 20;
          const vertices = super.getVertices();
          const first = vertices.get(2);
          if (first.y !== requiredY) {
            first.y = first.y + height / 2 + 20;
            const second = super.getVertex(3);
            second.y = second.y + height / 2 + 20;
            super.setVertices(vertices);
            // @ts-ignore
            this.canvas.createTransition(this);
          }
        }
        else {
          // @ts-ignore
          this.canvas.createTransition(this);
        }
      }
    });
    if (!readonly) {
      setTimeout(() => {
        super.on('move', () => {
          if (!this.movedSubscription) {
            this.movedSubscription = fromEvent(document, 'mouseup').subscribe((event: MouseEvent) => {
              // @ts-ignore
              if (typeof this.getId() === 'number') {
                // @ts-ignore
                this.canvas.updateTransitionPoints(this);
              }
              this.movedSubscription!.unsubscribe();
              this.movedSubscription = undefined;
            });
          }
        });
      }, 1000);
    }
  }

  // Arrow decorator for connection line
  private createAndSetArrowDecorator() {
    const decorator = new draw2d.decoration.connection.ArrowDecorator();
    decorator.setDimension(5, 8);
    decorator.setColor('#8D949C');
    decorator.setBackgroundColor('#8D949C');
    super.setTargetDecorator(decorator);
  }

  onSelect(any) {

  }

  setSource(port) {
    super.setSource(port);
  }

  setTarget(port) {
    super.setTarget(port);
  }

  setUserData(data) {
    super.setUserData(data);
  }

  getUserData() {
    return super.getUserData();
  }

  setId(id) {
    super.setId(id);
  }

  setSelectable(flag: boolean) {
    super.setSelectable(flag);
  }

}

export interface ConnectionUserData {
  addedByPainter: boolean;
  transition: Workflow.Transition;
}
