/* eslint-disable */
import { Injectable } from '@angular/core';
import { OrderField } from '../../query/orderfields';
import { OrderFieldModel, OrderFunctionResolver } from '../../../util/core-utils';
import { NewBaseSearch, NewBaseSearchService } from '../../new-base.search-service';
import { OrderType } from '../../util/services';
import { DisabledEnum, DisabledItem, SearchUtils } from '../../../util/search-utils';
import { LoggedInUserStorage } from '../../util/storages';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

/* eslint-enable */

@Injectable()
export class LedgerNumberSearchService extends NewBaseSearchService<OrderField.LedgerNumber> {

  protected createStorage(): LedgerNumberSearch.Storage {
    return new LedgerNumberSearch.Storage();
  }

}

export namespace LedgerNumberSearch {

  export class Model extends SearchUtils.SearchModel {

    showSearch: boolean = false;

    id?: number;
    ledgerNumber: string;
    name: string;
    creationTimeFrom: NgbDateStruct | null;
    creationTimeTo: NgbDateStruct | null;
    disabled: DisabledEnum;

    constructor() {
      super();
      this.reset();
    }

    reset() {
      this.id = undefined;
      this.ledgerNumber = '';
      this.name = '';
      this.creationTimeFrom = null;
      this.creationTimeTo = null;
      this.disabled = DisabledEnum.FALSE;
    }

    isEmpty(): boolean {
      return this.id === undefined &&
        this.ledgerNumber === '' &&
        this.name === '' &&
        this.creationTimeFrom === null &&
        this.creationTimeTo === null &&
        this.disabled === DisabledEnum.FALSE;
    }

  }

  export namespace OrderFunctions {
    export const ID = (f: OrderField.LedgerNumber) => f.id;
    export const NAME = (f: OrderField.LedgerNumber) => f.name;
    export const LEDGER_NUMBER = (f: OrderField.LedgerNumber) => f.ledgerNumber;
    export const CREATION_TIME = (f: OrderField.LedgerNumber) => f.creationTime;
    export const ISSUE_DATE = (f: OrderField.LedgerNumber) => f.creationTime;

    export const VALUES = OrderFunctionResolver.builder<OrderField.LedgerNumber>()
      .add(ID, 'id')
      .add(LEDGER_NUMBER, 'ledger_number')
      .add(NAME, 'name')
      .add(CREATION_TIME, 'creation_time')
      .add(ISSUE_DATE, 'issue_date')
      .build();
  }

  export interface SearchDataResult {
    searchData: SearchData;
  }

  export interface SearchData extends NewBaseSearch.SearchData<OrderField.LedgerNumber> {
    searchModel: Model;
  }

  interface StoredSearchRawData extends NewBaseSearch.StoredSearchRawData<OrderField.LedgerNumber> {
    id?: number;
    ledgerNumber: string;
    name: string;
    creationTimeFrom: NgbDateStruct | null;
    creationTimeTo: NgbDateStruct | null;
    disabled: DisabledEnum;
  }

  export class Storage extends NewBaseSearch.Storage<OrderField.LedgerNumber> {

    constructor() {
      const defaultSearchData: SearchData = {
        queryModel: new OrderFieldModel(OrderFunctions.ID, OrderType.DESC),
        searchModel: new Model(),
      };
      /**
       * Increment this version if you change the SearchData interface.
       */
      const versionNumber: number = 1;
      super(defaultSearchData,
        versionNumber);
    }

    protected generateKey(): string {
      return 'UserId(' + LoggedInUserStorage.getInstance().getUserId() + ')-Search-LedgerNumber';
    }

    protected fromRaw(data: StoredSearchRawData): NewBaseSearch.SearchDataResult<OrderField.LedgerNumber> {
      const searchModel = new Model();
      searchModel.id = data.id;
      searchModel.ledgerNumber = data.ledgerNumber;
      searchModel.name = data.name;
      searchModel.creationTimeFrom = data.creationTimeFrom;
      searchModel.creationTimeTo = data.creationTimeTo;
      searchModel.disabled = data.disabled;
      const searchData: SearchData = {
        queryModel: OrderFieldModel.create<OrderField.LedgerNumber>(OrderFunctions.VALUES, data.order, data.queryModel),
        searchModel: searchModel,
      };
      return {
        searchData: searchData
      };
    }

    protected toRaw(data: SearchData): StoredSearchRawData {
      return {
        version: this.VERSION,
        queryModel: data.queryModel,
        order: data.queryModel.createOrder(OrderFunctions.VALUES),
        id: data.searchModel.id,
        ledgerNumber: data.searchModel.ledgerNumber,
        name: data.searchModel.name,
        creationTimeFrom: data.searchModel.creationTimeFrom,
        creationTimeTo: data.searchModel.creationTimeTo,
        disabled: data.searchModel.disabled
      };
    }

  }

}
