/* eslint-disable */
import { combineLatest, Observable, of as observableOf } from 'rxjs';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TaskService } from '../../../../lib/task/task.service';
import { saveAs } from 'file-saver';
import { TaskRecord, TaskRecordService } from '../../../../lib/task/task-record.service';
import { ToasterService } from '../../../../fork/angular2-toaster/angular2-toaster';
import { TranslateService } from '@ngx-translate/core';
import { Survey, SurveyService } from '../../../../lib/survey/survey.service';
import { SurveyRecord, SurveyRecordService } from '../../../../lib/survey/survey-record.service';
import { QueryResult } from '../../../../lib/util/services';
import { Map, Set } from 'immutable';
import { UIRouter } from '@uirouter/angular';
import { StateName } from '../../../../app.state-names';
import { RightService } from '../../../../lib/right.service';
import { RightModel } from '../../../../app.rights';
import { map } from 'rxjs/operators';
/* eslint-enable */

@Component({
  selector: 'app-task-record-survey',
  templateUrl: './task-record-survey.component.html',
  styleUrls: ['./task-record-survey.component.scss']
})
export class TaskRecordSurveyComponent implements OnInit {

  @Output()
  refresh: EventEmitter<void> = new EventEmitter<void>();

  private _taskRecord: TaskRecord.TaskRecord;

  private surveyMap: Map<Survey.Survey, SurveyRecord.SurveyRecord[]> = Map.of();

  rightModel: RightModel = RightModel.empty();

  constructor(
    private taskService: TaskService,
    private toasterService: ToasterService,
    private translateService: TranslateService,
    private taskRecordService: TaskRecordService,
    private surveyService: SurveyService,
    private surveyRecordService: SurveyRecordService,
    private uiRouter: UIRouter,
    private rightService: RightService
  ) {
  }

  ngOnInit() {
    this.loadRightModels();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe((value) => {
      this.rightModel = RightModel.of(value);
    });
  }

  set taskRecord(tr: TaskRecord.TaskRecord) {
    this._taskRecord = tr;
    this.loadSurveys();
  }

  get taskRecord() {
    return this._taskRecord;
  }

  get surveys(): Survey.Survey[] {
    return this.surveyMap.keySeq().toArray();
  }

  getSurveyRecords(survey: Survey.Survey) {
    return this.surveyMap.get(survey, []);
  }

  private loadSurveys() {
    this.surveyMap = Map.of();
    if (this.rightModel.surveyRead.hasRight() && this.rightModel.surveyRecordRead.hasRight()) {
      if (this.taskRecord.linkedSurveyIds.size > 0) {
          this.surveyService.query({
            surveyIdSet: this.taskRecord.linkedSurveyIds
          }).subscribe(result => {
            const keyValues: any[] = [];
            result.items.forEach(survey => {
              if (survey) {
                const records: SurveyRecord.SurveyRecord[] = [];
                keyValues.push(survey);
                keyValues.push(records);
              }
            });
            this.surveyMap = Map.of(...keyValues);
            const surveyRecordObservables = this.getSurveyRecordObservables();
            if (surveyRecordObservables.length > 0) {
              surveyRecordObservables.forEach(obs => {
                obs.subscribe(result => {
                  const sid = result.items.get(0).surveyId;
                  const survey = this.surveyMap.findKey((va, k) => k !== undefined && k.id === sid);
                  if (survey) {
                    this.surveyMap.get(survey).push(...result.items.toArray());
                  }
                });
              });
            }
          });
      }
    }
  }

  private getSurveyRecordObservables(): Observable<QueryResult<SurveyRecord.SurveyRecord>>[] {
    const obs: Observable<QueryResult<SurveyRecord.SurveyRecord>>[] = [];
    this.taskRecord.linkedSurveyRecordIds
    // .filter((value, id) => id !== undefined && this.taskRecord.linkedSurveyIds.find(s => s === id) > 0)
      .forEach((recordIds: number[], surveyId: number) => {
        obs.push(this.surveyRecordService.query({surveyId: surveyId, surveyRecordIdSet: Set.of(...recordIds)}))
      });
    return obs;
  }

  showSurveyRecord(surveyId: number, recordId: number) {
    this.uiRouter.stateService.go(StateName.SURVEY_RECORD_DETAIL, {surveyId: surveyId, id: recordId});
  }

  setSurveyRecordDisabled(surveyId: number, recordId: number) {
    this.surveyRecordService.setDisabled({surveyId: surveyId, surveyRecordId: recordId, disabled: true}).subscribe(result => {
        this.refresh.emit();
      },
      error => {
      });
  }

}
