<div class="d-flex justify-content-center">
  <ng-container *ngIf="!signatureImg; else hasSignature">
    <button type="button" class="btn btn-outline-primary" (click)="openSignatureDialog()" [disabled]="readonly">
      <i class="icomoon icomoon-new-state-pending-approval"></i>
    </button>
  </ng-container>

  <ng-template #hasSignature>
    <img class="signature-image" [src]="sanitizer.bypassSecurityTrustUrl(signatureImg)" (click)="openSignatureDialog()" *ngIf="!readonly"/>
    <img class="signature-image" [src]="sanitizer.bypassSecurityTrustUrl(signatureImg)" *ngIf="readonly"/>
  </ng-template>
</div>
