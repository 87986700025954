/* eslint-disable */
import { Component, OnInit, ViewChild, } from '@angular/core';
import { Transition, UIRouter } from '@uirouter/angular';
import { OwnerUserItem, UiConstants } from '../../../util/core-utils';
import { StateName } from '../../../app.state-names';
import { InputMask } from '../../../util/input-masks';
import { SurveyCreateModel } from '../../../util/survey-utils';
import { Survey, SurveyService } from '../../../lib/survey/survey.service';
import { FieldValidationError } from '../../../lib/util/services';
import { NgForm } from '@angular/forms';
import { LoadingHandler } from '../../../lib/loading-handler';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '../../../fork/angular2-toaster/src/toaster.service';
import { StringKey } from '../../../app.string-keys';

/* eslint-enable */

@Component({
  selector: 'app-survey-create',
  templateUrl: 'survey-create.component.html',
  styleUrls: ['survey-create.component.scss']
})
export class SurveyCreateComponent implements OnInit {

  InputMask = InputMask;
  Survey = Survey;
  UiConstants = UiConstants;

  @ViewChild('f', {static: true})
  fForm: NgForm;

  model: SurveyCreateModel;
  ownerUsers: OwnerUserItem[];
  fieldErrors: FieldValidationError<Survey.ValidatedField>;
  customerType: string;
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  constructor(
    private surveyService: SurveyService,
    private uiRouter: UIRouter,
    private toasterService: ToasterService,
    private translateService: TranslateService,
    private transition: Transition) {
    this.model = new SurveyCreateModel();
    this.ownerUsers = [];
    this.fieldErrors = FieldValidationError.empty<Survey.ValidatedField>();
    this.customerType = this.transition.params().type;
  }

  ngOnInit() {
    this.translateService.get('SURVEY_PANEL_HEADING_ADD').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('MENU_NAVBAR_SURVEY').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.SURVEY_LIST});
      }
    );
  }

  createSurvey() {
    this.surveyService.create({
      name: this.model.name,
      description: this.model.description,
      externalId: this.model.externalId
    }).subscribe(
      (response: Survey.Survey) => {
        LoadingHandler.getInstance().refresh();
        this.uiRouter.stateService.go(StateName.SURVEY_EDIT, {id: response.id});
      },
      (error: any) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withForm(this.fForm);
          this.showValidationErrorToast();
        }
      }
    );
  }

  private showValidationErrorToast() {
    this.toasterService.pop({
      timeout: UiConstants.ToastTimeoutLong,
      type: UiConstants.toastTypeError,
      title: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_TITLE),
      body: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_MESSAGE)
    });
  }

  hasFieldError(field: Survey.ValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: Survey.ValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: Survey.ValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  back() {
    window.history.back();
  }

}
