import { Observable, Observer } from 'rxjs';
import { MemStorage } from './util/mem-storage';

export abstract class BaseSearchService {

  getSearchData(request: BaseSearch.SearchDataGetRequest): Observable<BaseSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<BaseSearch.SearchDataResult>) => {
      try {
        observer.next(storage.read(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  setSearchData<T extends BaseSearch.SearchDataSetRequest>(request: T): Observable<BaseSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<BaseSearch.SearchDataResult>) => {
      try {
        observer.next(storage.save(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  resetSearchData(request: BaseSearch.SearchDataGetRequest): Observable<BaseSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<BaseSearch.SearchDataResult>) => {
      try {
        observer.next(storage.reset(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  protected abstract createStorage(): BaseSearch.Storage;

}



export namespace BaseSearch {

  export interface SearchDataGetRequest {
  }

  export interface SearchDataSetRequest {
    searchData: SearchData;
  }

  export interface SearchDataResult {
    searchData: SearchData;
  }

  export interface SearchData {
    itemsPerPage: number;
    pageNumber: number;
  }

  interface StoredSearchRawDataBase {
    version: number;
  }

  export interface StoredSearchRawData extends StoredSearchRawDataBase {
    itemsPerPage: number;
    pageNumber: number;
  }

  export abstract class Storage {

    /**
     * Increment this version if you change the SearchData interface.
     */
    protected readonly VERSION: number;

    protected readonly DEFAULT_SEARCH_DATA: SearchData;

    constructor(defaultSearchData: SearchData, versionNumber: number) {
      this.DEFAULT_SEARCH_DATA = defaultSearchData;
      this.VERSION = versionNumber;
    }

    public read(request: SearchDataGetRequest): SearchDataResult {
      let rawBase: StoredSearchRawDataBase;
      try {
        const key = this.generateKey(request);
        const json = MemStorage.getItem(key);
        if (!json) {
          return this.createDefaultResult();
        }
        rawBase = JSON.parse(json);
      }
      catch (error) {
        return this.createDefaultResult();
      }
      if (this.VERSION === rawBase.version) {
        const raw: StoredSearchRawData = <StoredSearchRawData>rawBase;
        return this.fromRaw(raw);
      }
      return this.createDefaultResult();
    }

    public reset(request: SearchDataSetRequest | SearchDataGetRequest): SearchDataResult {
      return this.save(request, true);
    }

    public save(request: SearchDataSetRequest | SearchDataGetRequest, reset?: boolean): SearchDataResult {
      let raw: StoredSearchRawData;
      if (reset) {
        raw = this.toRaw(this.DEFAULT_SEARCH_DATA);
      }
      else {
        try {
          raw = this.toRaw((<SearchDataSetRequest> request).searchData);
        }
        catch (error) {
          raw = this.toRaw(this.DEFAULT_SEARCH_DATA);
        }
      }
      const key = this.generateKey(request);
      const json = JSON.stringify(raw);
      MemStorage.setItem(key, json);
      return this.read(request);
    }

    protected abstract generateKey(request: SearchDataGetRequest | SearchDataSetRequest): string;

    private createDefaultResult(): SearchDataResult {
      return {
        searchData: this.DEFAULT_SEARCH_DATA,
      };
    }

    protected abstract fromRaw(data: StoredSearchRawData): SearchDataResult;

    protected abstract toRaw(data: SearchData): StoredSearchRawData;

  }

}
