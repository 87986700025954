import { Component, OnInit } from '@angular/core';
import { TaskRecord, TaskRecordService } from '../../../lib/task/task-record.service';
import { TaskRecordStateMachine } from '../../../lib/task/task-record-statemachine';
import RecentMessage = TaskRecord.RecentMessage;
import {RightResolver, RightService} from "../../../lib/right.service";
import {RightModel} from "../../../app.rights";

@Component({
  selector: 'app-helpdesk-messages-card',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class HelpdeskMessagesCardComponent implements OnInit {

  list: RecentMessage[] = [];

  rightModel: RightModel = RightModel.empty();

  constructor(
    private rightService: RightService,
    private taskRecordService: TaskRecordService
  ) {
  }

  ngOnInit(): void {
    this.loadRightModels(() => this.loadMessages());
  }

  private loadRightModels(completion: () => void) {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        completion();
      }
    );
  }

  private loadMessages(): void {
    if (!this.rightModel.taskRecordChatRead.hasRight()) {
      return;
    }
    this.taskRecordService.getRecentMessages({
      helpdesk: true,
      paging: {
        pageNumber: 1,
        numberOfItems: 3
      }
    }).subscribe(result => {
      this.list = result.items.toArray();
    });
  }

  getStateObject(state: TaskRecordStateMachine.State): TaskRecordStateMachine.StateObject {
    return TaskRecordStateMachine.taskRecordStates.get(state);
  }

}
