import { Injectable } from '@angular/core';
import { BaseHttpService, ResourceHelper } from '../../util/http-services';
import { Observable } from 'rxjs';
import { ResourceQueryResult } from '../../util/services';
import { HttpClient } from '@angular/common/http';
import { AddressResource } from '../../address';

@Injectable()
export class CustomerRecordContactResourceService extends BaseHttpService {

  getLocations(request: CustomerRecordContactResource.LocationQueryRequest):
    Observable<ResourceQueryResult<AddressResource.ContactLocationResource>> {
    return this.http.get<ResourceQueryResult<AddressResource.ContactLocationResource>>(this.url + 'locations', this.parseParams(request));
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/customer-records/contacts/');
  }

}

export namespace CustomerRecordContactResource {

  export interface LocationQueryRequest {
    q?: string;
    id?: string;
    external_id?: string;
    name?: string;
    customer_record_id?: number;
    contact_customer_record_id?: number;
    order?: string;
    page_number?: number;
    number_of_items?: number;
    no_progress_bar?: boolean;
  }

}
