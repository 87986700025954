/* eslint-disable */
import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { UiConstants } from '../../util/core-utils';
import { NumberOfItemsFormatter } from '../../util/number-of-items-formatter';
import { ScreenSizes } from '../../util/screen-size';
/* eslint-enable */

@Component({
  selector: 'app-table-paging',
  templateUrl: './table-paging.component.html',
  styleUrls: ['./table-paging.component.scss']
})
export class TablePagingComponent implements OnInit, AfterViewInit {

  @Input()
  id: string;

  @Input()
  currentNumberOfItems: number;

  @Input()
  totalNumberOfItems: number;

  @Input()
  itemsPerPage: number;

  @Input()
  readonly pageNumbers: number[] = UiConstants.pageNumbers;

  @Input()
  hideItemsPerPage?: boolean;

  @Input()
  hideNumberOfItems?: boolean;

  @Output()
  pageChange: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  itemsPerPageChange: EventEmitter<number> = new EventEmitter<number>();

  paginationMaxSize: number = UiConstants.paginationMaxSize;
  previousNextVisible: boolean = true;

  constructor(private numberOfItemsFormatter: NumberOfItemsFormatter) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.updatePagination();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updatePagination();
  }

  onPageChange(event: number) {
    this.pageChange.emit(event);
  }

  onItemsPerPageChange(event: number) {
    this.itemsPerPageChange.emit(event);
  }

  formatNumberOfItems(): string {
    if (this.hideNumberOfItems) {
      return '';
    }
    return this.numberOfItemsFormatter.format(this.currentNumberOfItems, this.totalNumberOfItems);
  }

  private updatePagination() {
    this.previousNextVisible = window.innerWidth > ScreenSizes.WIDTH_MOBILE_M;
    this.paginationMaxSize = UiConstants.paginationMaxSize;
  }

}
