<div class="card">

  <div class="card-header">
    {{'USER_SIGNATURE_TAB_HEADER' | translate}}

    <div class="card-actions">
    </div><!-- /.card-actions -->
  </div>

  <div class="card-body">
    <table class="table table-striped table-bordered" *ngIf="signatures.length > 0">
      <thead>
      <tr>
        <th class="table-sorter-header long">
          <app-table-sorter-no-op [text]="'COMMON_TYPE' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header w-20 hidden-sm-down">
          <app-table-sorter-no-op [text]="'USER_SIGNATURE_UPLOADED' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header w-table-actions-single" *ngIf="!readonly">
          <app-table-sorter-no-op [text]="'COMMON_TABLE_HEADER_ACTIONS' | translate"></app-table-sorter-no-op>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let signature of signatures">
        <td>{{getTypeKey(signature.signatureType) | translate}}</td>
        <td class="hidden-sm-down">
          <i class="icomoon icons-table-item icomoon-visible-on-detail-true icomoon-table-icon" *ngIf="signature.data"></i>
          <i class="icomoon icons-table-item icomoon-visible-on-detail-false icomoon-table-icon" *ngIf="!signature.data"></i>
        </td>
        <td  *ngIf="!readonly">
          <a class="icons-table-group" href="#" onclick="return false" (click)="deleteSignature(signature.signatureType)"
             *ngIf="signature.data && userRightModel.userSignatureDelete.hasRightForUser(user)">
            <i class="icomoon icons-table-item icomoon-trash icomoon-table-icon"
               title="{{'COMMON_BUTTON_DELETE' | translate}}"></i>
          </a>
          <a class="icons-table-group" href="#" onclick="return false" (click)="uploadOfficialSignature()"
             *ngIf="signature.signatureType === 'OFFICIAL' && userRightModel.userSignatureUpload.hasRightForUser(user)">
            <i class="icomoon icons-table-item icomoon-upload-photo icomoon-table-icon"
               title="{{'COMMON_BUTTON_UPLOAD' | translate}}"></i>
          </a>
          <a class="icons-table-group" href="#" onclick="return false" (click)="downloadSignature(signature.signatureType)"
             *ngIf="signature.data">
            <i class="icomoon icons-table-item icomoon-download icomoon-table-icon"
               title="{{'COMMON_BUTTON_DOWNLOAD' | translate}}"></i>
          </a>
        </td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <th class="table-sorter-header long">
          <app-table-sorter-no-op [text]="'COMMON_TYPE' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header w-20">
          <app-table-sorter-no-op [text]="'USER_SIGNATURE_UPLOADED' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header w-table-actions-single" *ngIf="!readonly">
          <app-table-sorter-no-op [text]="'COMMON_TABLE_HEADER_ACTIONS' | translate"></app-table-sorter-no-op>
        </th>
      </tr>
      </tfoot>
    </table>

  </div><!-- /.card-body -->

</div><!-- /.card -->
