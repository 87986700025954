/* eslint-disable */
import { Injectable } from '@angular/core';
import { ShopRenterResource, ShopRenterResourceService } from './shoprenter-resource.service';
import { Observable, Observer } from 'rxjs';
import { Storages } from '../../util/repo/storages';
import { DqlQuery, Query } from '../query/field';
import { FilterField } from '../query/filterfields';
import { OrderField } from '../query/orderfields';
import { PagingRequest, QueryResult, ResourceQueryResult } from '../util/services';
import { List, Set } from 'immutable';
import { EmptyMessage, IdentityMessage } from '../util/messages';
import { FieldError } from '../util/errors';
import { Registration } from '../registration/registration.service';

/* eslint-enable */

@Injectable()
export class ShopRenterService {

  private readonly filterField = new FilterField.ShopRenter();
  private readonly orderField = new OrderField.ShopRenter();
  private readonly fields = new ShopRenter.Fields.ShopRenter();

  constructor(private resourceService: ShopRenterResourceService) {
  }

  query(request: ShopRenter.QueryRequest): Observable<QueryResult<ShopRenter.ShopRenterShop>> {
    const filter: string | undefined = DqlQuery.toOptionalFilter(this.filterField, request.filter);
    const order: string | undefined = DqlQuery.toOptionalOrder(this.orderField, request.order);
    const fields: string | undefined = DqlQuery.toOptionalFields(this.fields, request.fields);
    return Observable.create((observer: Observer<QueryResult<ShopRenter.ShopRenterShop>>) => {
      const resourceRequest: ShopRenterResource.QueryRequest = {
        filter: filter,
        order: order,
        fields: fields,
        rights: DqlQuery.RightRequestSerializer.getInstance().serialize(request.rights),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined
      };
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<ShopRenterResource.ShopRenterShop>) => {
          observer.next({
            items: List.of(...result.items.map((item) => this.toPublic(item))),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  get(request: IdentityMessage): Observable<ShopRenter.ShopRenterShop> {
    return Observable.create((observer: Observer<ShopRenter.ShopRenterShop>) => {
      return this.resourceService.get(request).subscribe(
        (result: ShopRenterResource.ShopRenterShop) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  sync(request: ShopRenter.SyncRequest): Observable<EmptyMessage> {
    return new Observable((observer: Observer<EmptyMessage>) => {
      return this.resourceService.sync(request.id).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  import(request: ShopRenter.ImportRequest): Observable<EmptyMessage> {
    return new Observable((observer: Observer<EmptyMessage>) => {
      return this.resourceService.import(request.id).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  update(request: ShopRenter.UpdateRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: ShopRenterResource.UpdateRequest = {
        id: request.id,
        human_name: request.humanName,
        company_id: request.companyId,
        import_active: request.importActive,
        shipping_modes: request.shippingModes.map(mode => this.toResourceShippingMode(mode)),
        importable_order_status_ids: request.importableOrderStatusIds.size > 0 ? request.importableOrderStatusIds.toArray() : [],
        imported_order_status_id: request.importedOrderStatusId,
        unknown_product_order_status_id: request.unknownProductOrderStatusId,
        invalid_order_order_status_id: request.invalidOrderOrderStatusId,
        insufficient_amount_order_status_id: request.insufficientAmountOrderStatusId,
        canceled_order_status_id: request.canceledOrderStatusId,
        returned_order_status_id: request.returnedOrderStatusId,
        delivered_order_status_id: request.deliveredOrderStatusId,
        rejected_order_status_id: request.rejectedOrderStatusId,
        reopened_order_status_id: request.reopenedOrderStatusId,
        process_started_order_status_id: request.processStartedOrderStatusId,
        process_finished_order_status_id: request.processFinishedOrderStatusId,
      };
      return this.resourceService.update(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  installShop(request: ShopRenter.InstallShopRequest): Observable<ShopRenter.InstallShopResponse> {
    return new Observable((observer: Observer<ShopRenter.InstallShopResponse>) => {
      const resourceRequest: ShopRenterResource.InstallShopRequest = {
        referrer: request.referrer,
        shop_name: request.shopName,
        code: request.code,
        hmac: request.hmac,
        timestamp: request.timestamp
      };
      return this.resourceService.installShop(resourceRequest).subscribe(
        (result: ShopRenterResource.InstallShopResponse) => {
          observer.next({
            redirectUrl: result.redirect_url
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  findShop(request: ShopRenter.FindShopRequest): Observable<ShopRenter.FindShopResponse> {
    return new Observable((observer: Observer<ShopRenter.FindShopResponse>) => {
      const resourceRequest: ShopRenterResource.FindShopRequest = {
        shop_name: request.shopName,
        code: request.code,
        hmac: request.hmac,
        timestamp: request.timestamp
      };
      return this.resourceService.findShop(resourceRequest).subscribe(
        (result: ShopRenterResource.FindShopResponse) => {
          observer.next({
            shopId: result.shop_id,
            userId: result.user_id,
            companyId: result.company_id,
            currentUserId: result.current_user_id,
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  connectShopToMe(request: ShopRenter.ConnectShopRequest): Observable<ShopRenter.ConnectShopResponse> {
    return new Observable((observer: Observer<ShopRenter.ConnectShopResponse>) => {
      const resourceRequest: ShopRenterResource.ConnectShopRequest = {
        shop_id: request.shopId,
        shop_name: request.shopName,
        code: request.code,
        hmac: request.hmac,
        timestamp: request.timestamp
      };
      return this.resourceService.connectShopToMe(resourceRequest).subscribe(
        (result: ShopRenterResource.ConnectShopResponse) => {
          observer.next({});
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  private translateError(error: any): any {
    return error;
  }

  private toPublic(r: ShopRenterResource.ShopRenterShop): ShopRenter.ShopRenterShop {
    return {
      id: r.id,
      name: r.name,
      humanName: r.human_name,
      grantedRights: r.granted_rights ? Set.of(...r.granted_rights) : Set.of(),
      user: r.user ? {
        id: r.user.id,
        personName: r.user.person_name
      } : undefined,
      company: r.company ? {
        id: r.company.id,
        name: r.company.name
      } : undefined,
      importActive: r.import_active,
      orderStatuses: r.order_statuses,
      shippingModes: r.shipping_modes.map((mode) => this.toPublicShippingMode(mode)),
      importableOrderStatusIds: r.importable_order_status_ids ? Set.of(...r.importable_order_status_ids) : Set.of(),
      importedOrderStatusId: r.imported_order_status_id,
      unknownProductOrderStatusId: r.unknown_product_order_status_id,
      invalidOrderOrderStatusId: r.invalid_order_order_status_id,
      insufficientAmountOrderStatusId: r.insufficient_amount_order_status_id,
      canceledOrderStatusId: r.canceled_order_status_id,
      returnedOrderStatusId: r.returned_order_status_id,
      deliveredOrderStatusId: r.delivered_order_status_id,
      rejectedOrderStatusId: r.rejected_order_status_id,
      reopenedOrderStatusId: r.reopened_order_status_id,
      processStartedOrderStatusId: r.process_started_order_status_id,
      processFinishedOrderStatusId: r.process_finished_order_status_id,
    };
  }

  private toPublicShippingMode(r: ShopRenterResource.ShippingMode): ShopRenter.ShippingMode {
    return {
      id: r.id,
      name: r.name,
      deliveryMethod: r.delivery_method ? {
        id: r.delivery_method.id,
        name: r.delivery_method.name
      } : undefined
    };
  }

  private toResourceShippingMode(r: ShopRenter.ShippingModeUpdate): ShopRenterResource.ShippingModeUpdate {
    return {
      shop_renter_shipping_mode_id: r.shopRenterShippingModeId,
      delivery_method_id: r.deliveryMethodId
    };
  }

}

export namespace ShopRenter {

  export interface QueryRequest {
    fields?: Query.FieldFunction<Fields.ShopRenter>;
    filter?: Query.FilterFunction<FilterField.ShopRenter>;
    order?: Query.OrderFunction<OrderField.ShopRenter>;
    paging?: PagingRequest;
    rights?: Set<string>;
  }

  export interface ShopRenterShop {
    id: number;
    grantedRights: Set<string>;
    name: string;
    humanName?: string;
    company?: Company;
    user?: User;
    importActive: boolean;
    orderStatuses: OrderStatus[];
    shippingModes: ShippingMode[];
    importableOrderStatusIds: Set<number>;
    importedOrderStatusId?: number;
    unknownProductOrderStatusId?: number;
    invalidOrderOrderStatusId?: number;
    insufficientAmountOrderStatusId?: number;
    canceledOrderStatusId?: number;
    returnedOrderStatusId?: number;
    deliveredOrderStatusId?: number;
    rejectedOrderStatusId?: number;
    reopenedOrderStatusId?: number;
    processStartedOrderStatusId?: number;
    processFinishedOrderStatusId?: number;
  }

  export interface User {
    id: number;
    personName: string;
  }

  export interface Company {
    id: number;
    name: string;
  }

  export interface OrderStatus {
    id: number;
    name: string;
  }

  export interface ShippingMode {
    id: number;
    name: string;
    deliveryMethod?: {
      id: number;
      name: string;
    };
  }

  export interface ShippingModeUpdate {
    shopRenterShippingModeId: number;
    deliveryMethodId: number;
  }

  export interface SyncRequest {
    id: number;
  }

  export interface ImportRequest {
    id: number;
  }

  export interface UpdateRequest {
    id: number;
    humanName?: string;
    companyId?: number;
    importActive: boolean;
    shippingModes: ShippingModeUpdate[];
    importableOrderStatusIds: Set<number>;
    importedOrderStatusId: number;
    unknownProductOrderStatusId?: number;
    invalidOrderOrderStatusId: number;
    insufficientAmountOrderStatusId: number;
    canceledOrderStatusId: number;
    returnedOrderStatusId: number;
    deliveredOrderStatusId: number;
    rejectedOrderStatusId: number;
    reopenedOrderStatusId?: number;
    processStartedOrderStatusId?: number;
    processFinishedOrderStatusId?: number;
  }

  export interface InstallShopRequest {
    referrer: string;
    shopName: string;
    code: string;
    hmac: string;
    timestamp: number;
  }

  export interface InstallShopResponse {
    redirectUrl: string;
  }

  export interface FindShopRequest {
    shopName: string;
    code: string;
    hmac: string;
    timestamp: number;
  }

  export interface FindShopResponse {
    shopId: number;
    userId?: number;
    companyId?: number;
    currentUserId?: number;
  }

  export interface ConnectShopRequest {
    shopId: number;
    shopName: string;
    code: string;
    hmac: string;
    timestamp: number;
  }

  export interface ConnectShopResponse {
  }

  export interface ShopFieldErrorMap {
    company_id?: FieldError;
    human_name?: FieldError;
  }

  export namespace Fields {

    // Each class should be an interface, but it is easier to define the constant fields in the constructors.
    // For now we have only one query language so it is not a problem.
    // Always use the public Query namespace for variable definitions so we will be able to introduce other language easily.

    export class ShopRenter {

      readonly id: Query.Field = new DqlQuery.Field('id');
      readonly name: Query.Field = new DqlQuery.Field('name');
      readonly humanName: Query.Field = new DqlQuery.Field('human_name');
      readonly company: Query.Field = new DqlQuery.Field('company');
      readonly user: Query.Field = new DqlQuery.Field('user');
      readonly orderStatuses: Query.Field = new DqlQuery.Field('order_statuses');
      readonly shippingModes: Query.Field = new DqlQuery.Field('shipping_modes');

      get each(): Set<Query.Field> {
        return Set.of(this.id, this.name, this.humanName, this.company, this.user, this.orderStatuses, this.shippingModes);
      }

    }

  }

}

export class ShopRenterStorage {

  private static readonly KEY_SHOP_ID = 'shoprenter_shop_id';
  private static readonly KEY_USER_ID = 'shoprenter_user_id';
  private static readonly KEY_SHOP_NAME = 'shoprenter_shop_name';
  private static readonly KEY_CODE = 'shoprenter_code';
  private static readonly KEY_TIMESTAMP = 'shoprenter_timestamp';
  private static readonly KEY_HMAC = 'shoprenter_hmac';

  private static instance = new ShopRenterStorage();

  private storageAvailable: boolean | null = null;

  public static getInstance(): ShopRenterStorage {
    return ShopRenterStorage.instance;
  }

  public clear(): void {
    try {
      localStorage.removeItem(ShopRenterStorage.KEY_SHOP_ID);
      localStorage.removeItem(ShopRenterStorage.KEY_USER_ID);
      localStorage.removeItem(ShopRenterStorage.KEY_SHOP_NAME);
      localStorage.removeItem(ShopRenterStorage.KEY_CODE);
      localStorage.removeItem(ShopRenterStorage.KEY_TIMESTAMP);
      localStorage.removeItem(ShopRenterStorage.KEY_HMAC);
    }
    catch (error) {
      this.storageAvailable = false;
    }
  }

  public getShopId(): number | null {
    return this.getNumber(ShopRenterStorage.KEY_SHOP_ID);
  }

  public getUserId(): number | null {
    return this.getNumber(ShopRenterStorage.KEY_USER_ID);
  }

  public getShopName(): string | null {
    return this.getString(ShopRenterStorage.KEY_SHOP_NAME);
  }

  public getCode(): string | null {
    return this.getString(ShopRenterStorage.KEY_CODE);
  }

  public getTimestamp(): number | null {
    return this.getNumber(ShopRenterStorage.KEY_TIMESTAMP);
  }

  public getHmac(): string | null {
    return this.getString(ShopRenterStorage.KEY_HMAC);
  }

  public setShopId(shopId: number): void {
    this.setNumber(ShopRenterStorage.KEY_SHOP_ID, shopId);
  }

  public setUserId(userId?: number): void {
    this.setNumber(ShopRenterStorage.KEY_USER_ID, userId);
  }

  public setShopName(shopName: string): void {
    this.setString(ShopRenterStorage.KEY_SHOP_NAME, shopName);
  }

  public setCode(code: string): void {
    this.setString(ShopRenterStorage.KEY_CODE, code);
  }

  public setTimestamp(timestamp: number): void {
    this.setNumber(ShopRenterStorage.KEY_TIMESTAMP, timestamp);
  }

  public setHmac(hmac: string): void {
    this.setString(ShopRenterStorage.KEY_HMAC, hmac);
  }

  private getNumber(key: string): number | null {
    let text;
    try {
      text = localStorage.getItem(key);
      if (text === null) {
        return null;
      }
    }
    catch (error) {
      this.storageAvailable = false;
      return null;
    }
    return Number(text);
  }

  private setNumber(key: string, value?: number): void {
    try {
      if (value === undefined) {
        localStorage.removeItem(key);
      }
      else {
        localStorage.setItem(key, value.toString());
      }
    }
    catch (error) {
      this.storageAvailable = false;
    }
  }

  private getString(key: string): string | null {
    try {
      return localStorage.getItem(key);
    }
    catch (error) {
      this.storageAvailable = false;
      return null;
    }
  }

  private setString(key: string, value?: string): void {
    try {
      if (value === undefined) {
        localStorage.removeItem(key);
      }
      else {
        localStorage.setItem(key, value);
      }
    }
    catch (error) {
      this.storageAvailable = false;
    }
  }

  getShopRenterToken(): ShopRenterToken {
    return {
      shopName: this.getShopName()!,
      code: this.getCode()!,
      timestamp: this.getTimestamp()!,
      hmac: this.getHmac()!
    }
  }

  getShopRenterTokenResource(): ShopRenterTokenResource {
    return {
      shop_name: this.getShopName()!,
      code: this.getCode()!,
      timestamp: this.getTimestamp()!,
      hmac: this.getHmac()!
    }
  }

  isShopRenterTokenAvailable(): boolean {
      return this.getShopName() !== null
      && this.getCode() !== null
      && this.getTimestamp() !== null
      && this.getHmac() !== null;
  }

  public isStorageAvailable(): boolean {
    if (this.storageAvailable === null) {
      this.storageAvailable = Storages.checkStorageAvailable();
    }
    return this.storageAvailable;
  }

  private constructor() {
  }

}

export interface ShopRenterToken {
  shopName: string;
  code: string;
  timestamp: number;
  hmac: string;
}

export interface ShopRenterTokenResource {
  shop_name: string;
  code: string;
  timestamp: number;
  hmac: string;
}
