<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-custom-padding-outer animated fadeIn">
  <form class="form-horizontal" (ngSubmit)="f.form.valid && create()" #f="ngForm" novalidate>
    <div class="row m-0">

      <div class="col-md-6 card-custom-padding-complete">
        <div class="card">
          <div class="card-header">
            {{'INVOICE_CREATE_HEADING_INVOICE_DETAILS' | translate}}
          </div>
          <div class="card-body">
            <!-- region InvoiceSettings -->
            <div class="form-group row"
                 [ngClass]="{ 'has-danger': hasFieldError(Invoice.ValidatedField.INVOICE_SETTINGS) || (f.submitted && !invoiceSettings.valid) }">
              <label class="col-4 col-form-label required-field-label">
                {{'INVOICE_CREATE_INVOICE_SETTINGS' | translate}}
              </label>
              <div class="col-8">
                <angular2-multiselect [data]="model.invoiceSettingsList" [settings]="searchableDropdownSettings"
                                      class="form-control"
                                      [(ngModel)]="model._invoiceSettings"
                                      [ngClass]="{ 'form-control-danger': hasFieldError(Invoice.ValidatedField.INVOICE_SETTINGS) || (f.submitted && !invoiceSettings.valid) }"
                                      (ngModelChange)="onInvoiceSettingsChanged()"
                                      (onRemoteSearch)="model.onInvoiceSettingsSearch($event.target.value)"
                                      required #invoiceSettings="ngModel" name="invoiceSettings" id="invoiceSettings">
                </angular2-multiselect>
                <div class="form-control-feedback" *ngIf="hasFieldError(Invoice.ValidatedField.INVOICE_SETTINGS)">
                  {{getFieldErrorText(Invoice.ValidatedField.INVOICE_SETTINGS)}}
                </div>
                <div class="form-control-feedback" *ngIf="f.submitted && !invoiceSettings.valid">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
              </div>
            </div>
            <!-- endregion InvoiceSettings -->

            <div class="form-group row" *ngIf="model._directionType === 'INBOUND'"
                 [ngClass]="{ 'has-danger': hasFieldError(Invoice.ValidatedField.INVOICE_NUMBER) || (f.submitted && !invoiceNumber.valid) }">
              <label class="col-4 col-form-label required-field-label">
                {{'INVOICE_DETAIL_INVOICE_NUMBER'|translate}}
              </label>
              <div class="col-md-8">
                <input type="text" class="form-control" placeholder="{{'INVOICE_DETAIL_INVOICE_NUMBER' | translate}}"
                       [(ngModel)]="model._invoiceNumber"
                       name="invoiceNumber"
                       id="invoiceNumber"
                       (ngModelChange)="removeFieldError(Invoice.ValidatedField.INVOICE_NUMBER)"
                       [ngClass]="{ 'form-control-danger': hasFieldError(Invoice.ValidatedField.INVOICE_NUMBER) || (f.submitted && !invoiceNumber.valid) }"
                       [required]="model._directionType === 'INBOUND'"
                       #invoiceNumber="ngModel">
              </div>
              <div class="form-control-feedback" *ngIf="hasFieldError(Invoice.ValidatedField.INVOICE_NUMBER)">
                {{getFieldErrorText(Invoice.ValidatedField.INVOICE_NUMBER)}}
              </div>
              <div class="form-control-feedback" *ngIf="f.submitted && !invoiceNumber.valid">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>

            <!-- region InvoiceBook -->
            <div class="form-group row"
                 *ngIf="model.invoiceSettings && model.invoiceSettings.integrationType === InvoiceSettings.InvoiceIntegrationType.APPWORKS"
                 [ngClass]="{ 'has-danger': hasFieldError(Invoice.ValidatedField.INVOICE_BOOK) || (f.submitted && !invoiceBook.valid) }">
              <label class="col-4 col-form-label required-field-label">
                {{'INVOICE_CREATE_INVOICE_BOOK' | translate}}
              </label>
              <div class="col-8">
                <angular2-multiselect [data]="model.invoiceBookList" [settings]="searchableDropdownSettings"
                                      class="form-control"
                                      [(ngModel)]="model._invoiceBook"
                                      [ngClass]="{ 'form-control-danger': hasFieldError(Invoice.ValidatedField.INVOICE_BOOK) || (f.submitted && !invoiceBook.valid) }"
                                      (ngModelChange)="removeFieldError(Invoice.ValidatedField.INVOICE_BOOK)"
                                      (onRemoteSearch)="model.onInvoiceBookSearch($event.target.value)"
                                      required #invoiceBook="ngModel" name="invoiceBook" id="invoiceBook">
                </angular2-multiselect>
                <div class="form-control-feedback" *ngIf="hasFieldError(Invoice.ValidatedField.INVOICE_BOOK)">
                  {{getFieldErrorText(Invoice.ValidatedField.INVOICE_BOOK)}}
                </div>
                <div class="form-control-feedback" *ngIf="f.submitted && !invoiceBook.valid">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
              </div>
            </div>
            <!-- endregion InvoiceBook -->

            <!--region AppearanceType-->
            <div class="form-group row">
              <label
                class="col-form-label form-control-label col-md-4 detail-title required-field-label">
                {{'INVOICE_CREATE_APPEARANCE_TYPE' | translate}}
              </label>
              <div class="col-md-8">
                <mat-radio-group class="col-form-label d-flex flex-column" [(ngModel)]="model._appearanceType"
                                 [ngModelOptions]="{standalone: true}"
                                 [disabled]="!model.invoiceSettings || model.invoiceSettings.integrationType === InvoiceSettings.InvoiceIntegrationType.APPWORKS"
                                 id="appearanceType" name="appearanceType" #appearanceType="ngModel">
                  <mat-radio-button *ngFor="let appearanceType of InvoiceSettings.invoiceAppearanceTypes"
                                    [value]="appearanceType.type" [disableRipple]="true">
                    {{appearanceType.stringKey | translate}}
                  </mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
            <!--endregion AppearanceType-->

            <!-- region issueDate -->
            <div class="form-group row"
                 [ngClass]="{ 'has-danger': hasFieldError(Invoice.ValidatedField.ISSUE_DATE) || (f.submitted && !model._issueDate) }">
              <label class="col-4 col-form-label required-field-label">
                {{'INVOICE_CREATE_ISSUE_DATE' | translate}}
              </label>
              <div class="col-8">
                <div class="input-group">
                  <input class="form-control" type="text" placeholder="{{'INVOICE_CREATE_ISSUE_DATE' | translate}}"
                         [ngClass]="{ 'form-control-danger': hasFieldError(Invoice.ValidatedField.ISSUE_DATE) || (f.submitted && !model._issueDate) }"
                         [(ngModel)]="model._issueDate"
                         (ngModelChange)="removeFieldError(Invoice.ValidatedField.ISSUE_DATE)"
                         #issueDate="ngbDatepicker"
                         [readonly]="model._directionType === 'OUTBOUND'"
                         [disabled]="model._directionType === 'OUTBOUND'"
                         name="issueDate" id="issueDate" required ngbDatepicker validateLocalDate>
                  <span class="add-on input-group-addon-gray" (click)="issueDate.toggle()">
                                      <i class="icomoon icomoon-calendar"></i>
                                  </span>
                </div>
                <div class="form-control-feedback" *ngIf="hasFieldError(Invoice.ValidatedField.ISSUE_DATE)">
                  {{getFieldErrorText(Invoice.ValidatedField.ISSUE_DATE)}}
                </div>
                <div class="form-control-feedback" *ngIf="f.submitted && !model._issueDate">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
              </div>
            </div>
            <!-- endregion issueDate -->

            <!-- region deliveryDate -->
            <div class="form-group row"
                 [ngClass]="{ 'has-danger': hasFieldError(Invoice.ValidatedField.DELIVERY_DATE) || (f.submitted && !model._deliveryDate) }">
              <label class="col-4 col-form-label required-field-label">
                {{'INVOICE_CREATE_DELIVERY_DATE' | translate}}
              </label>
              <div class="col-8">
                <div class="input-group">
                  <input class="form-control" type="text" placeholder="{{'INVOICE_CREATE_DELIVERY_DATE' | translate}}"
                         [ngClass]="{ 'form-control-danger': hasFieldError(Invoice.ValidatedField.DELIVERY_DATE) || (f.submitted && !model._deliveryDate) }"
                         [(ngModel)]="model._deliveryDate"
                         (ngModelChange)="removeFieldError(Invoice.ValidatedField.DELIVERY_DATE)"
                         #deliveryDate="ngbDatepicker"
                         name="deliveryDate" id="deliveryDate" required ngbDatepicker validateLocalDate>
                  <span class="add-on input-group-addon-gray" (click)="deliveryDate.toggle()">
                                      <i class="icomoon icomoon-calendar"></i>
                                  </span>
                </div>
                <div class="form-control-feedback" *ngIf="hasFieldError(Invoice.ValidatedField.DELIVERY_DATE)">
                  {{getFieldErrorText(Invoice.ValidatedField.DELIVERY_DATE)}}
                </div>
                <div class="form-control-feedback" *ngIf="f.submitted && !model._deliveryDate">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
              </div>
            </div>
            <!-- endregion deliveryDate -->

            <!-- region deadline -->
            <div class="form-group row"
                 [ngClass]="{ 'has-danger': hasFieldError(Invoice.ValidatedField.DEADLINE) || (f.submitted && !model._deadline) }">
              <label class="col-4 col-form-label required-field-label">
                {{'INVOICE_CREATE_DEADLINE' | translate}}
              </label>
              <div class="col-8">
                <div class="input-group">
                  <input class="form-control" type="text" placeholder="{{'INVOICE_CREATE_DEADLINE' | translate}}"
                         [ngClass]="{ 'form-control-danger': hasFieldError(Invoice.ValidatedField.DEADLINE) || (f.submitted && !model._deadline) }"
                         [(ngModel)]="model._deadline"
                         (ngModelChange)="removeFieldError(Invoice.ValidatedField.DEADLINE)"
                         #deadline="ngbDatepicker"
                         name="deadline" id="deadline" required ngbDatepicker validateLocalDate>
                  <span class="add-on input-group-addon-gray" (click)="deadline.toggle()">
                                        <i class="icomoon icomoon-calendar"></i>
                                    </span>
                </div>
                <div class="form-control-feedback" *ngIf="hasFieldError(Invoice.ValidatedField.DEADLINE)">
                  {{getFieldErrorText(Invoice.ValidatedField.DEADLINE)}}
                </div>
                <div class="form-control-feedback" *ngIf="f.submitted && !model._deadline">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
              </div>
            </div>
            <div class="row d-flex justify-content-end mr-0">
              <div>
                <input [disabled]="!model._deliveryDate" *ngFor="let add of model.deadlineAdds" type="button"
                       (click)="deadlineAddDays(add)" class="btn btn-secondary mb-1 ml-1"
                       value="{{'INVOICE_CREATE_DEADLINE_ADD' | translate: {days: ''+add} }}">
              </div>
            </div>
            <!--endregion deadline -->

            <!-- region paymentType -->
            <div class="form-group row"
                 [ngClass]="{ 'has-danger': (f.submitted && !paymentType.valid) }">
              <label class="col-4 col-form-label required-field-label">
                {{'INVOICE_CREATE_PAYMENT_TYPE' | translate}}
              </label>
              <div class="col-8">
                <angular2-multiselect [data]="model.paymentTypeList" [settings]="simpleDropdownSettings"
                                      class="form-control"
                                      [(ngModel)]="model._paymentType"
                                      [ngClass]="{ 'form-control-danger': (f.submitted && !paymentType.valid) }"
                                      required #paymentType="ngModel" name="paymentType" id="paymentType">
                </angular2-multiselect>
                <div class="form-control-feedback" *ngIf="f.submitted && !paymentType.valid">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
              </div>
            </div>
            <!-- endregion paymentType -->

            <!-- region InvoiceTag -->
            <div class="form-group row"
                 *ngIf="rightModel.invoiceTagRead.hasRight()"
                 [ngClass]="{ 'has-danger': hasFieldError(Invoice.ValidatedField.INVOICE_TAG) || (f.submitted && !invoiceTag.valid) }">
              <label class="col-4 col-form-label">
                {{'INVOICE_CREATE_INVOICE_TAG' | translate}}
              </label>
              <div class="col-8">
                <angular2-multiselect [data]="model.invoiceTagList" [settings]="searchableDropdownSettings"
                                      class="form-control"
                                      [(ngModel)]="model._invoiceTag"
                                      [ngClass]="{ 'form-control-danger': hasFieldError(Invoice.ValidatedField.INVOICE_TAG) || (f.submitted && !invoiceTag.valid) }"
                                      (onRemoteSearch)="model.onInvoiceTagSearch($event.target.value)"
                                      #invoiceTag="ngModel" name="invoiceTag" id="invoiceTag">
                </angular2-multiselect>
                <div class="form-control-feedback" *ngIf="hasFieldError(Invoice.ValidatedField.INVOICE_TAG)">
                  {{getFieldErrorText(Invoice.ValidatedField.INVOICE_TAG)}}
                </div>
                <div class="form-control-feedback" *ngIf="f.submitted && !invoiceTag.valid">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
              </div>
            </div>
            <!-- endregion InvoiceTag -->

            <!-- region comment-->
            <div class="form-group row">
              <label class="col-4 col-form-label">{{'COMMON_COMMENT' | translate}}</label>
              <div class="col-8">
              <textarea placeholder="{{'COMMON_COMMENT' | translate}}" [(ngModel)]="model._commentTemplate"
                        class="form-control" name="comment" id="comment" #comment="ngModel" rows="5">
              </textarea>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6 card-custom-padding-complete">
        <div class="card">
          <div class="card-header" *ngIf="model._directionType === 'OUTBOUND'">
            {{'INVOICE_CREATE_CUSTOMER_OUTBOUND' | translate}}
          </div>
          <div class="card-header" *ngIf="model._directionType === 'INBOUND'">
            {{'INVOICE_CREATE_CUSTOMER_INBOUND' | translate}}
          </div>
          <div class="card-body">
            <div class="form-group row"
                 [ngClass]="{ 'has-danger': hasFieldError(Invoice.ValidatedField.CUSTOMER_RECORD) || (f.submitted && !customerRecord.valid) }">
              <label class="col-4 col-form-label required-field-label">
                {{'INVOICE_CREATE_CUSTOMER_RECORD' | translate}}
              </label>
              <div class="col-8">
                <div class="d-flex">
                  <div class="flex-grow-1">
                    <angular2-multiselect [data]="model.customerRecordList" [settings]="searchableDropdownSettings"
                                          class="form-control"
                                          [(ngModel)]="model._customerRecord"
                                          [ngClass]="{ 'form-control-danger': hasFieldError(Invoice.ValidatedField.CUSTOMER_RECORD) || (f.submitted && !customerRecord.valid) }"
                                          (ngModelChange)="removeFieldError(Invoice.ValidatedField.CUSTOMER_RECORD)"
                                          (onSelect)="onCustomerChange($event)"
                                          (onDeSelect)="onCustomerChange()"
                                          (onRemoteSearch)="model.onCustomerRecordSearch($event.target.value)"
                                          required #customerRecord="ngModel" name="customerRecord" id="customerRecord">
                    </angular2-multiselect>
                  </div>
                  <button type="button" class="btn btn-secondary ml-1"
                          *ngIf="rightModel.customerRecordCreate.hasRight()"
                          (click)="openCustomerRecordQuickCreateDialog()">
                    <i class="icomoon icomoon-add"></i>
                  </button>
                </div>
                <div class="form-control-feedback" *ngIf="hasFieldError(Invoice.ValidatedField.CUSTOMER_RECORD)">
                  {{getFieldErrorText(Invoice.ValidatedField.CUSTOMER_RECORD)}}
                </div>
                <div class="form-control-feedback" *ngIf="f.submitted && !customerRecord.valid">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
              </div>
            </div>
            <div class="form-group row"
                 [ngClass]="{ 'has-danger': (f.submitted && !billingInfo.valid) }">
              <label class="col-4 col-form-label required-field-label">
                {{'CUSTOMER_RECORD_BILLING_INFO' | translate}}
              </label>
              <div class="col-8">
                <div class="d-flex">
                  <div class="flex-grow-1">
                    <angular2-multiselect [data]="model.billingInfos" [settings]="simpleDropdownSettings"
                                          class="form-control"
                                          [ngClass]="{ 'form-control-danger': (f.submitted && !billingInfo.valid) }"
                                          [(ngModel)]="model._billingInfo"
                                          required
                                          #billingInfo="ngModel" name="billingInfo" id="billingInfo">
                    </angular2-multiselect>
                  </div>
                  <button type="button" class="btn btn-secondary ml-1"
                          *ngIf="rightModel.billingInfoCreate.hasRight() && model.customerRecord"
                          (click)="openBillingInfoCreateDialog()">
                    <i class="icomoon icomoon-add"></i>
                  </button>
                </div>

                <div class="form-control-feedback" *ngIf="f.submitted && !billingInfo.valid">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-4 col-form-label"
                     [ngClass]="{'invoice-create-billing-address-danger': model.customerRecord && !invoiceAddress}">
                {{'INVOICE_CREATE_INVOICE_ADDRESS' | translate}}
              </label>
              <div class="col-8">
                <input class="form-control" type="text"
                       [value]="formatPostalAddress(invoiceAddress)"
                       disabled>
              </div>
            </div>

            <div class="form-group row" *ngIf="taxNumber !== undefined">
              <label class="col-4 col-form-label">
                {{'INVOICE_CREATE_CUSTOMER_TAX_NUMBER' | translate}}
              </label>
              <div class="col-8">
                <input class="form-control" type="text"
                       [value]="taxNumber"
                       disabled>
              </div>
            </div>

            <div class="form-group row"
                 *ngIf="model.billingInfo && model.billingInfo.data['invoiceVatStatus'] === InvoiceVatStatus.OTHER">
              <label class="col-4 col-form-label">
                {{'CUSTOMER_RECORD_EU_TAX_NUMBER' | translate}}
              </label>
              <div class="col-8">
                <input class="form-control" type="text"
                       [value]="model.billingInfo.data['euTaxNumber']"
                       disabled>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-4 col-form-label">
                {{'INVOICE_CREATE_POSTAL_ADDRESS' | translate}}
              </label>
              <div class="col-8">
                <input class="form-control" type="text"
                       [value]="formatPostalAddress(model.customerRecord?.postalAddress)"
                       disabled>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="col-12 card-custom-padding-complete">
      <div class="card">
        <div class="card-header">
          {{'INVOICE_RECORDS' | translate}}
        </div>
        <div class="card-body">

          <app-invoice-record-container
            [recordCreateModels]="model._records"
            [selectableVatRates]="selectableVatRates"
            [selectableZeroVatRateTypes]="selectableZeroVatRateTypes"
            [readonly]="false"
            [showSummary]="true"
            [rightModel]="rightModel"
            [paymentType]="model.paymentType?.id"
            [summaryRoundingEnabled]="model.invoiceSettings?.summaryRoundingEnabled"
            [recordRoundingEnabled]="model.invoiceSettings?.recordRoundingEnabled"
            [zeroPricedRecordsAllowed]="model.invoiceSettings?.zeroPricedRecordsAllowed"
            class="col-12">
          </app-invoice-record-container>
        </div>
      </div>
    </div>
    <input type="submit" class="btn btn-primary floating_button" value="{{'INVOICE_CREATE_SAVE_BUTTON'|translate}}"/>
  </form>
</div>
