/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from './util/http-services';
import { Observable } from 'rxjs';
/* eslint-enable */

@Injectable()
export class PermissionResourceService extends BaseHttpService {

  query(request: PermissionResource.QueryRequest): Observable<PermissionResource.Permission[]> {
    return this.http.get<PermissionResource.Permission[]>(this.url, this.parseParams(request));
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/permissions/');
  }

}

export namespace PermissionResource {

  export interface Permission {
    definition: string;
    short_name: string;
  }

  export interface QueryRequest {
    privilege_definition?: string;
    explicit?: boolean;
  }

}
