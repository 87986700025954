<div class="modal-content">
  <div class="modal-header" *ngIf="titleEnabled">
    <p class="modal-title"><strong>{{titleKey | translate}}</strong></p>
    <button type="button" class="close" (click)="closeDialog()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="alert alert-{{alertType.toString()}}" *ngIf="messageEnabled">
      <p class="alert-title mb-0">{{messageKey | translate}}</p>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="closeDialog()" *ngIf="negativeButtonEnabled">
      {{'COMMON_BUTTON_CANCEL' | translate}}
    </button>
    <button type="button" class="btn btn-primary" mat-dialog-close="{{true}}" cdkFocusInitial *ngIf="positiveButtonEnabled">
      {{'COMMON_BUTTON_OK' | translate}}
    </button>
  </div>
</div>
