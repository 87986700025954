/* eslint-disable */
import {
  FormEditFieldCreateDialogView,
  FormEditFieldDialogViewFactory,
  FormEditFieldUpdateDialogView,
} from './form-utils';
import { ComponentFactoryResolver, ComponentRef, Injector, } from '@angular/core';
import { FormEditCreateUpdateStockMoveFieldDialogComponent } from '../../admin/form/form-edit/dialogs/fields/stock-move/create-update/form-edit-create-update-stock-move-field-dialog.component';

/* eslint-enable */

/**
 * Stock move field implementation.
 */
export class FormEditStockMoveFieldDialogViewFactory implements FormEditFieldDialogViewFactory {

  constructor(
    private injector: Injector,
    private cfr: ComponentFactoryResolver) {
  }

  createCreateView(): ComponentRef<FormEditFieldCreateDialogView> {
    return this.createView();
  }

  createUpdateView(): ComponentRef<FormEditFieldUpdateDialogView> {
    return this.createView();
  }

  private createView(): ComponentRef<FormEditCreateUpdateStockMoveFieldDialogComponent> {
    return this.cfr.resolveComponentFactory(FormEditCreateUpdateStockMoveFieldDialogComponent)
      .create(this.injector);
  }

}
