<div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12 p-0">
  <div class="card" *ngIf="showSignature('user') || showSignature('customer')">
    <div class="card-header">{{'SIGNATURE_TITLE' | translate}}</div>
    <div class="card-body d-flex justify-content-between">
      <div *ngIf="showSignature('user')">
        <label>{{"FORM_CREATE_USER_SIGNATURE" | translate}}</label>
        <app-signature [readonly]="!canEditSignature('user')"
                       [signatureImg]="userSignature"
                       (signatureImgChange)="signatureSave($event, 'user')"></app-signature>
      </div>
      <div *ngIf="showSignature('customer')">
        <label>{{"FORM_CREATE_CUSTOMER_SIGNATURE" | translate}}</label>
        <app-signature [readonly]="!canEditSignature('customer')"
                       [signatureImg]="customerSignature"
                       (signatureImgChange)="signatureSave($event, 'customer')"></app-signature>
      </div>
    </div>
  </div>
</div>
