/* eslint-disable */
import { DqlModel } from '../dql/dql.model';
import { Map } from 'immutable';

/* eslint-enable */

export class MasterDataRecordDqlFieldTextProvider {
  public static getHelper(): DqlModel.DqlStaticFieldTextHelper {
    const fields: Map<string, DqlModel.DqlFieldTexts> = Map.of(
      'name', {stringKey: 'COMMON_NAME'},
      'id', {stringKey: 'COMMON_TABLE_HEADER_ID'},
      'parent_id', {stringKey: 'DQL_PARENT_ID'},
      'creation_time', {stringKey: 'COMMON_TABLE_HEADER_CREATION_TIME'},
      'update_time', {stringKey: 'COMMON_TABLE_HEADER_UPDATE_TIME'},
      'description', {stringKey: 'COMMON_DESCRIPTION'},
      'external_id', {stringKey: 'COMMON_EXTERNAL_ID'},
      'customer_record_id', {stringKey: 'TASK_RECORD_SEARCH_CUSTOMER_NAME'},
      'contact_location_id', {stringKey: 'TASK_RECORD_SEARCH_CONTACT_LOCATION'},
    );
    return new DqlModel.DqlStaticFieldTextHelper(fields);
  }
}
