<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
    <app-breadcrumb-menu>
      <app-breadcrumb-button
        *ngIf="rightModel.messageTemplateUpdate.hasRight() && componentState.isDetailView()"
        [iconClass]="'icomoon-modify'"
        [titleStringKey]="'COMMON_BUTTON_EDIT'"
        uiSref="Admin.NotificationTemplateEdit"
        [uiParams]="{ id: componentState.id }">
      </app-breadcrumb-button>
    </app-breadcrumb-menu>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>

<div class="container-custom-padding animated fadeIn">

  <div class="form-group row mx-0">

    <!--region Basic Details-->
    <div class="col-xs-12 col-sm-12 col-md-6 card-custom-padding">
      <div class="card">
        <div class="card-header">
          {{ getHeadingDictionaryKey() | translate}}
        </div>
        <div class="card-body">
          <form class="form-horizontal bordered-row" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>

            <!--region Template name-->
            <div class="form-group row" [ngClass]="{ 'has-danger': hasLocalFieldError(name) || fieldErrors.name}">
              <label class="col-form-label form-control-label col-md-4 detail-title" [class.required-field-label]="componentState.isEditable()">
                {{'NOTIFICATION_TEMPLATE_NAME' | translate}}
              </label>
              <div class="col-md-8">
                <input *ngIf="componentState.isEditable()"
                       type="text" class="form-control"
                       maxlength="{{UiConstants.maximumVarcharLength}}"
                       placeholder="{{'NOTIFICATION_TEMPLATE_NAME' | translate}}"
                       [(ngModel)]="editModel.name"
                       (ngModelChange)="removeFieldError(fieldErrors.name)"
                       [ngClass]="{ 'form-control-danger': hasLocalFieldError(name) || fieldErrors.name}"
                       name="name"
                       id="name"
                       #name="ngModel"
                       required>
                <div class="form-control-feedback"
                     *ngIf="hasLocalFieldError(name)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                <div class="form-control-feedback"
                     *ngIf="fieldErrors.name">{{fieldErrors.name.text}}</div>
                <!--Appears in detailView-->
                <label
                  *ngIf="componentState.isReadonly()" class="form-control detail-description"
                  readonly>{{detailModel.name}}
                </label>
              </div>
            </div>
            <!--endregion Template name-->

            <!--region ExternalId-->
            <div class="form-group row"
                 [ngClass]="{ 'has-danger': hasLocalFieldError(externalId) || fieldErrors.external_id}">
              <label class="col-form-label form-control-label col-md-4 detail-title" [class.required-field-label]="componentState.isEditView()">
                {{'COMMON_EXTERNAL_ID' | translate}}
              </label>
              <div class="col-md-8">
                <input *ngIf="componentState.isEditable()"
                       type="text" class="form-control"
                       maxlength="{{UiConstants.maximumVarcharLength}}"
                       placeholder="{{'COMMON_EXTERNAL_ID' | translate}}"
                       [(ngModel)]="editModel.externalId"
                       (ngModelChange)="removeFieldError(fieldErrors.external_id)"
                       [ngClass]="{ 'form-control-danger': hasLocalFieldError(externalId) || fieldErrors.external_id}"
                       name="externalId"
                       id="externalId"
                       #externalId="ngModel"
                       [required]="componentState.isEditView()">
                <div class="form-control-feedback"
                     *ngIf="fieldErrors.external_id">{{fieldErrors.external_id.text}}</div>
                <div class="form-control-feedback"
                     *ngIf="hasLocalFieldError(externalId)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                <!--Appears in detailView-->
                <label
                  *ngIf="componentState.isReadonly()" class="form-control detail-description"
                  readonly>{{detailModel.externalId}}
                </label>
              </div>
            </div>
            <!--endregion ExternalId-->

            <!--region Title-->
            <div class="form-group row"
                 [ngClass]="{ 'has-danger': hasLocalFieldError(title) || fieldErrors.title}">
              <label class="col-form-label form-control-label col-md-4 detail-title" [class.required-field-label]="componentState.isEditable()">
                {{'NOTIFICATION_TEMPLATE_TEMPLATE_TITLE' | translate}}
              </label>
              <div class="col-md-8">
                <input *ngIf="componentState.isEditable()"
                       type="text" class="form-control"
                       maxlength="{{UiConstants.maximumVarcharLength}}"
                       placeholder="{{'NOTIFICATION_TEMPLATE_TEMPLATE_TITLE' | translate}}"
                       [(ngModel)]="editModel.title"
                       (ngModelChange)="removeFieldError(fieldErrors.title)"
                       [ngClass]="{ 'form-control-danger': hasLocalFieldError(externalId) || fieldErrors.title}"
                       name="title"
                       id="title"
                       #title="ngModel"
                       required>
                <div class="form-control-feedback"
                     *ngIf="fieldErrors.external_id">{{fieldErrors.title.text}}</div>
                <div class="form-control-feedback"
                     *ngIf="hasLocalFieldError(title)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                <!--Appears in detailView-->
                <label
                  *ngIf="componentState.isReadonly()" class="form-control detail-description"
                  readonly>{{detailModel.title}}
                </label>
              </div>
            </div>
            <!--endregion Title-->

            <!--region creation time-->
            <!--Visible only on detail view-->
            <div class="form-group row" *ngIf="componentState.isDetailView()">
              <label class="col-form-label form-control-label col-md-4 detail-title">
                {{'COMMON_TABLE_HEADER_CREATION_TIME' | translate}}
              </label>
              <div class="col-md-8">
                <label
                  class="form-control detail-description"
                  readonly>{{detailModel.creationTime.toUtcIsoString() | date:'short'}}
                </label>
              </div>
            </div>
            <!--endregion creation time-->

            <!--region update time-->
            <!--Visible only on detail view-->
            <div class="form-group row" *ngIf="componentState.isDetailView()">
              <label class="col-form-label form-control-label col-md-4 detail-title">
                {{'COMMON_TABLE_HEADER_UPDATE_TIME' | translate}}
              </label>
              <div class="col-md-8">
                <label
                  class="form-control detail-description"
                  readonly>{{detailModel.updateTime.toUtcIsoString() | date:'short'}}
                </label>
              </div>
            </div>
            <!--endregion update time-->

            <!--region template-->
            <div class="form-group row"
                 [ngClass]="{ 'has-danger': hasLocalFieldError(template)}">
              <label class="col-form-label form-control-label col-md-4 detail-title" [class.required-field-label]="componentState.isEditable()">
                {{'NOTIFICATION_TEMPLATE_TEMPLATE_PLACEHOLDER' | translate}}
              </label>
              <div class="col-md-8">
                <textarea
                  *ngIf="componentState.isEditable()"
                  class="form-control"
                  rows="5"
                  placeholder="{{'NOTIFICATION_TEMPLATE_TEMPLATE_PLACEHOLDER' | translate}}"
                  [(ngModel)]="editModel.template"
                  [ngClass]="{ 'form-control-danger': hasLocalFieldError(template)}"
                  name="template"
                  id="template"
                  #template="ngModel"
                  required>
                </textarea>
                <div class="form-control-feedback"
                     *ngIf="hasLocalFieldError(template)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                <!--Appears in detailView-->
                <label
                  *ngIf="componentState.isReadonly()" class="form-control detail-description"
                  readonly>{{detailModel.template}}
                </label>
              </div>
            </div>
            <!--endregion template-->

            <!--Only visible on create/edit views-->
            <div class="col-md-12 d-flex justify-content-end p-0 pl-1" *ngIf="componentState.isEditable()">
              <div>
                <input type="button" class="btn btn-secondary mr-1" (click)="back()"
                       value="{{'COMMON_BUTTON_CANCEL'|translate}}"/>
                <input type="submit" class="btn btn-primary" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- endregion Basic Details-->
  </div>

</div>
