import { DqlQuery, Query } from '../field';

export class Vehicle {

  readonly id: Query.OrderField;
  readonly licencePlate: Query.OrderField;

  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this.id = new DqlQuery.OrderField(prefix + 'id');
    this.licencePlate = new DqlQuery.OrderField(prefix + 'licence_plate');
  }

}
