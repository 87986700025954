import { DqlQuery, Query } from '../field';
import { InvoiceSpending as InvoiceSpendingNS } from '../../invoice/spending/invoice-spending.service';

export class InvoiceSpending {

  readonly id: Query.NumberField;
  readonly spendingNumber: Query.StringField;
  readonly eventName: Query.StringField;
  readonly creationTime: Query.DateTimeField;
  readonly invoiceSpendingType: Query.EnumField<InvoiceSpendingNS.InvoiceSpendingType>;

  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this.id = new DqlQuery.NumberField(prefix + 'id');
    this.spendingNumber = new DqlQuery.StringField(prefix + 'spending_number');
    this.eventName = new DqlQuery.StringField(prefix + 'event_name');
    this.invoiceSpendingType = new DqlQuery.EnumField(prefix + 'invoice_spending_type', type => type);
    this.creationTime = new DqlQuery.DateTimeField(prefix + 'creation_time');
  }

}
