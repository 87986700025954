import { Injectable } from '@angular/core';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class GeneralEmailResourceService extends BaseHttpService {

  send(request: GeneralEmailResource.EmailSendRequest): Observable<GeneralEmailResource.Result> {
    return this.http.post<GeneralEmailResource.Result>(this.url + 'send-email', request);
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/email-templates');
  }
}

export namespace GeneralEmailResource {

  export interface EmailSendRequest {
    template_external_id: string;
    fields: Map<string, string>;
    recipients: Recipient[];
    general_pdf_ids?: number[];
    document_external_ids?: string[];
    document_group_codes?: string[];
  }

  export interface Recipient {
    name: string;
    email_address: string;
  }

  export interface Result {
    email_result: string;
  }
}
