import {Component, OnDestroy, OnInit} from '@angular/core';
import {BreadcrumbParent} from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import {StateName} from '../../../app.state-names';
import {Transition, UIRouter} from '@uirouter/core';
import {TranslateService} from '@ngx-translate/core';
import {Set} from 'immutable';
import {OperationRights} from '../../../app.right-definitions';
import {IdentityMessage} from '../../../lib/util/messages';
import {MatDialog} from '@angular/material/dialog';
import {GrantedPermissionSetResolver, RightResolver, RightService} from '../../../lib/right.service';
import {Process, ProcessService} from '../../../lib/process/process.service';
import {ProcessBaseDialogComponent} from '../process-base-dialog/process-base-dialog.component';
import {ProcessRightModel} from '../../../lib/process/process-right.model';
import {TaskRecordStateMachine} from '../../../lib/task/task-record-statemachine';
import {OffsetDateTime} from '../../../lib/util/dates';
import {Subscription} from 'rxjs';
import {
  CanvasSelectionService,
  CanvasSelectionType
} from '../../workflow/workflow-create-editor/utils/service/canvas-selection-service';
import {ConfigurationService} from '../../../lib/core-ext/configuration.service';
import {Address} from '../../../lib/address';
import {TaskRecord} from '../../../lib/task/task-record.service';
import {Icon} from '../../../lib/task/icon.service';
import {RightModel} from '../../../app.rights';
import {TaskRecordRightModel} from "../../../util/task-record-utils";
import {ChatService} from "../../../shared/chat/chat-service.interface";
import {TaskRecordSearch, TaskRecordSearchService} from "../../../lib/task/task-record-search.service";

@Component({
  selector: 'app-process-detail',
  templateUrl: './process-detail.component.html',
  styleUrls: ['./process-detail.component.scss']
})
export class ProcessDetailComponent implements OnInit, OnDestroy {

  // Variables used for breadcrumb
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  processRightModel: ProcessRightModel;
  rightModel: RightModel = RightModel.empty();

  processId: number;
  model: Process.Process;
  stateCount: { state: TaskRecordStateMachine.StateObject, count: number }[];
  taskStateMap: Map<number, { state: TaskRecordStateMachine.State, creationTime: OffsetDateTime }> = new Map();

  canvasSubscription: Subscription;

  selectedTaskRecords?: TaskRecordModel[];
  selectedTaskRecordIndex?: number;

  postalAddressFormat: string;

  constructor(private uiRouter: UIRouter,
              private transition: Transition,
              private translateService: TranslateService,
              private canvasSelectionService: CanvasSelectionService,
              private configService: ConfigurationService,
              private dialog: MatDialog,
              private rightService: RightService,
              private processService: ProcessService,
              private taskRecordSearchService: TaskRecordSearchService
  ) {
    this.processId = this.transition.params().id;
    this.postalAddressFormat = this.configService.getConfigurationModel().postalAddressFormat;
    this.canvasSubscription = this.canvasSelectionService.subscribe(data => {
      if (data.type === CanvasSelectionType.TASK) {
        this.selectedTaskRecords = this.model.taskRecords!.filter(r => r.workflowTask.id === data.figure!.getUserData().id).map(r => {
          return {
            id: r.id,
            taskId: r.taskId,
            name: r.name,
            state: r.state,
            creationTime: r.creationTime,
            workflowTask: r.workflowTask,
            assigneeUser: r.assigneeUser,
            assigneeUserGroup: r.assigneeUserGroup,
            customerRecord: r.customerRecord,
            importance: r.importance,
            deadline: r.deadline,
            placeOfConsumption: r.placeOfConsumption,
            grantedRights: r.grantedRights,
            stateObject: TaskRecordStateMachine.taskRecordStates.get(r.state),
            pocString: r.placeOfConsumption && r.placeOfConsumption.address
              ? Address.PostalAddressMapper.toString(r.placeOfConsumption.address, this.postalAddressFormat)
              : undefined,
            importanceStringKey: TaskRecord.taskRecordImportances.find(i => i.importance === r.importance)!.stringKey,
            rightModel: new TaskRecordRightModel(GrantedPermissionSetResolver.byGrantedRights(r.grantedRights))
          };
        });
        if (this.selectedTaskRecords.length > 0) {
          this.selectedTaskRecordIndex = 0;
        } else {
          this.selectedTaskRecordIndex = undefined;
        }
      } else {
        this.selectedTaskRecords = undefined;
        this.selectedTaskRecordIndex = undefined;
      }

    });
  }

  ngOnInit() {
    this.initBreadcrumb();
    this.loadModel();
    this.loadStateCount();
    this.loadRightModels();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  initBreadcrumb() {
    this.translateService.get('MENU_NAVBAR_NAV_TITLE_PROCESSS').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.PROCESS_LIST});
      }
    );
  }

  loadModel() {
    const rights = Set.of(
      OperationRights.PROCESS_CREATE,
      OperationRights.PROCESS_READ,
      OperationRights.PROCESS_UPDATE,
    );
    const taskRecordRights = Set.of(
      OperationRights.TASK_RECORD_READ
    );
    this.processService.get({
      id: this.processId,
      fields: f => f.each,
      rights: rights,
      taskRecordRights: taskRecordRights,
    }).subscribe(process => {
      this.breadcrumbSelf = process.name;
      if (process.taskRecords && process.taskRecords.length > 0) {
        process.taskRecords.forEach(t => {
          const existing = this.taskStateMap.get(t.workflowTask.id);
          if (!existing || existing.creationTime.isBefore(t.creationTime)) {
            this.taskStateMap.set(t.workflowTask.id, {state: t.state, creationTime: t.creationTime});
          }
        });
      }
      this.model = process;
      this.processRightModel = new ProcessRightModel(GrantedPermissionSetResolver.byGrantedRights(process.grantedRights));
    });
  }

  private loadStateCount() {
    this.processService.stateCount({
      id: this.processId
    }).subscribe(result => {
      this.stateCount = [];
      const keys = Object.keys(result);
      keys.forEach(key => {
        this.stateCount.push({
          state: TaskRecordStateMachine.taskRecordStates.get(<TaskRecordStateMachine.State>key),
          count: result[key]
        });
      });
    });
  }

  onOpenBaseDataDetailButtonClicked() {
    this.dialog.open(ProcessBaseDialogComponent, {
      width: '60%',
      data: {readonly: true, process: this.model}
    });
  }

  onModifyBaseDataButtonClicked() {
    const dialogRef = this.dialog.open(ProcessBaseDialogComponent, {
      width: '60%',
      data: {readonly: false, process: this.model}
    });

    dialogRef.afterClosed().subscribe((result: IdentityMessage) => {
      if (result) {
        this.loadModel();
      }
    });
  }

  getIcon(): Icon.Icon | undefined {
    if (!this.model) {
      return undefined;
    }
    return this.model.workflow!.icon;
  }

  getProcessStateObject() {
    if (this.model) {
      return Process.processStates.find(s => s.state === this.model.state);
    }
    return undefined;
  }

  showTaskRecords() {
    this.taskRecordSearchService.resetSearchData({
      taskId: undefined
    }).subscribe((result: TaskRecordSearch.SearchDataResult) => {
      result.searchData.processes = [{
        id: this.model.id,
        itemName: this.model.name
      }];
      this.taskRecordSearchService.setSearchData({
        taskId: undefined,
        searchData: result.searchData
      }).subscribe(result => {
        this.uiRouter.stateService.go(StateName.TASK_RECORD_GLOBAL_LIST);
      })
    });
  }

  getChatService(): ChatService {
    return this.processService;
  }

  ngOnDestroy() {
    this.canvasSubscription.unsubscribe();
  }
}

interface TaskRecordModel extends Process.TaskRecord {
  stateObject: TaskRecordStateMachine.StateObject;
  pocString?: string;
  importanceStringKey: string;
  rightModel: TaskRecordRightModel;
}
