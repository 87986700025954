<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]=breadcrumbParents
      [self]="breadcrumbSelf">
    </app-breadcrumb>
    <div class="breadcrumb-menu d-flex">
      <app-customer-record-breadcrumb-buttons
        class="d-flex"
        [readonly]="false"
        [allowEdit]="false"
        [allowTaskRecordQuickCreate]="rightModel.taskRecordCreate.hasRight()"
        [customerId]="customerId"
        [customerRecordId]="customerRecordId"
        [form]="f"
        (onTaskRecordCreateClicked)="openTaskRecordQuickCreateDialog($event)">
      </app-customer-record-breadcrumb-buttons>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-custom-padding-outer animated fadeIn">
  <responsive-tabset class="fullscreen_tab">
    <responsive-tab heading="{{'COMMON_CARD_HEADER_BASE_DATA'|translate}}">
      <form class="form-horizontal bordered-row" (ngSubmit)="updateCustomerRecord()" #f="ngForm" novalidate>
        <app-customer-record-base
          headingDictionaryKey="CUSTOMER_RECORD_PANEL_HEADING_UPDATE"
          [readonly]="false"
          [allowClone]="true"
          [externalIdRequired]="true"
          [form]="f"
          [customerItem]="combinedResult ? combinedResult.customer : undefined"
          (showDialog)="openWarningDialog()"
          (submitted)="submit($event)">
        </app-customer-record-base>
        <div class="card-custom-padding-outer">
          <app-form-record-container
            [readonly]="false"
            [cloning]="false"
            [form]="f">
          </app-form-record-container>
        </div>
      </form>
    </responsive-tab>
    <responsive-tab heading="{{'COMMON_PANEL_HEADING_ATTACHMENT'|translate}}"
                    *ngIf="grantedRights && grantedRights.attachmentRead.hasRight()"
    (selectTab)="attachmentView.initComponent()">
      <app-customer-record-attachment #attachmentView
                                      [grantedRights]="grantedRights"
                                      [customerId]="customerId"
                                      [customerRecordId]="customerRecordId"
                                      [readonly]="false">
      </app-customer-record-attachment>
    </responsive-tab>
    <responsive-tab heading="{{'COMMON_LOCATION_CONTACT_PERSONS' | translate}}"
                    *ngIf="combinedResult && combinedResult.customer && combinedResult.customer.type === 'COMPANY'
                    && baseView.getFieldModel().isManaged(CustomerRecordFieldType.CONTACT_PERSONS)"
    (selectTab)="contactPersonsComponent.initComponent()">
      <app-customer-record-contact-persons
        #contactPersonsComponent
        [readonly]="false"
        [rightModel]="rightModel"
        [customerId]="customerId"
        [customerRecordId]="customerRecordId"
        (addContactPerson)="openAddContactPersonDialog()"
        (contactPersonCreated)="onContactPersonCreated($event)"
        (inviteToHelpdesk)="contactInviteToHelpdeskDialog.showDialog($event)">
      </app-customer-record-contact-persons>
    </responsive-tab>
    <responsive-tab heading="{{'CUSTOMER_RECORD_LOCATIONS' | translate}}"
                    *ngIf="combinedResult && combinedResult.customer && combinedResult.customer.type === 'COMPANY'
                    && baseView.getFieldModel().isManaged(CustomerRecordFieldType.CONTACT_LOCATIONS)"
    (selectTab)="contactLocationsListComponent.initComponent()">
      <app-customer-record-contact-locations-list
        #contactLocationsListComponent
        [readonly]="false"
        [customerId]="customerId"
        [customerRecordId]="customerRecordId"
        (createContactLocation)="contactLocationsCreateEditDialog.showDialog()"
        (importContactLocation)="contactLocationImportDialog.showDialog()"
        (editContactLocation)="contactLocationsCreateEditDialog.showDialog($event)"
        (detailContactLocation)="contactLocationsDetailDialog.showDialog($event)">
      </app-customer-record-contact-locations-list>
    </responsive-tab>
    <responsive-tab heading="{{'CUSTOMER_RECORD_CONNECTED_MASTER_DATA'|translate}}"
                    *ngIf="rightModel.masterDataRecordRead.hasRight"
                    (selectTab)="masterDataListComponent.initComponent()">
      <app-customer-owner-master-data-list
        #masterDataListComponent
        [readonly]="false">
      </app-customer-owner-master-data-list>
    </responsive-tab>
    <responsive-tab heading="{{'INVOICE_CREATE_RELATED_TASKS'|translate}}"
                    *ngIf="rightModel.taskRecordRead.hasRight() && customerRecordId">
      <!--      init should be implemented here too-->
      <app-new-task-record-list
        [customerRecordId]="customerRecordId"
        [_headerStringKey]="'INVOICE_CREATE_RELATED_TASKS'">
      </app-new-task-record-list>
    </responsive-tab>
    <responsive-tab heading="{{'CUSTOMER_RECORD_PHONE_NUMBER_RELATED'|translate}}"
                    *ngIf="rightModel.taskRecordRead.hasRight() && customerRecordId
                    && config.taskRecordPhoneNumberRelatedList">
      <!--      init should be implemented here too-->
      <app-new-task-record-list
        [baseCustomerRecordByPhoneNumberId]="customerRecordId"
        [_headerStringKey]="'CUSTOMER_RECORD_PHONE_NUMBER_RELATED'">
      </app-new-task-record-list>
    </responsive-tab>
    <responsive-tab heading="{{'CUSTOMER_RECORD_HELPDESK_DATA' | translate}}"
                    *ngIf="combinedResult && combinedResult.customerRecord && combinedResult.customer.contactPerson"
    (selectTab)="helpdeskComponent.initComponent()">
      <app-customer-record-helpdesk
        #helpdeskComponent
        [rightModel]="rightModel"
        [customerRecord]="combinedResult.customerRecord">
      </app-customer-record-helpdesk>
    </responsive-tab>
    <responsive-tab heading="{{'CUSTOMER_RECORD_BILLING_INFO' | translate}}"
    (selectTab)="billingInfoListComponent.initComponent()">
      <app-customer-record-billing-info-list
        #billingInfoListComponent
        [readonly]="false"
        [customerId]="customerId"
        [customerRecordId]="customerRecordId"
        [rightModel]="rightModel">
      </app-customer-record-billing-info-list>
    </responsive-tab>
  </responsive-tabset>
</div>

<div bsModal [config]="UiConstants.modalConfig" #warningDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="warningDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'INVOICE_ADDRESS_MODAL_HEADER' | translate}}</h4>
        <button type="button" class="close" (click)="closeWarningDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group col-md-12 mb-0">
          <label>{{'INVOICE_ADDRESS_MODAL_BODY' | translate}}</label>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="closeWarningDialog()">{{'COMMON_BUTTON_OK' |
          translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<div bsModal [config]="UiConstants.modalConfig" #addContactPersonDialog="bs-modal" class="modal fade" tabindex="-1"
     role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="addContactPersonDialogVisible">
    <div class="modal-content">
      <form class="form-horizontal bordered-row" (ngSubmit)="addContactPersonForm.form.valid && addContactPersons()"
            #addContactPersonForm="ngForm" novalidate>
        <div class="modal-header">
          <h4 class="modal-title">{{'CUSTOMER_RECORD_CONTACT_PERSON_ADD' | translate}}</h4>
          <button type="button" class="close" (click)="closeAddContactPersonDialog()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group row"
               [ngClass]="{ 'has-danger': addContactPersonForm.submitted && !addContactPersonInput.valid }">
            <label
              class="col-form-label col-md-4 text-md-right">{{'COMMON_LOCATION_CONTACT_PERSONS' | translate}}</label>
            <div class="col-md-8">
              <angular2-multiselect
                class="form-control"
                [(ngModel)]="addCustomerRecords"
                [data]="personCustomerRecords"
                [settings]="addDropdownSettings"
                (onRemoteSearch)="loadPersonCustomerRecords($event.target.value)"
                name="addContactPersonInput"
                id="addContactPersonInput"
                #addContactPersonInput="ngModel"
                required>
              </angular2-multiselect>
              <div class="form-control-feedback" *ngIf="addContactPersonForm.submitted && !addContactPersonInput.valid">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
          </div>
          <div class="row m-0">
            <div class="col-md-6 p-0" *ngFor="let customerRecord of addCustomerRecords">
              <app-customer-record-table-cell
                [customerRecordName]="customerRecord.itemName"
                [customerName]="customerRecord.customerName"
                [customerRecordPosition]="customerRecord.itemSubtitle + ' ' + customerRecord.position"
                [imageSize]="48"
                [borderEnabled]="true"
                [rightModel]="rightModel">
              </app-customer-record-table-cell>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="closeAddContactPersonDialog()">
            {{'COMMON_BUTTON_CANCEL' | translate}}
          </button>
          <button type="submit" class="btn btn-primary">{{'COMMON_BUTTON_SAVE' |
            translate}}
          </button>
        </div>
      </form>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<app-customer-record-contact-locations-create-edit-dialog
  #contactLocationsCreateEditDialog
  [customerId]="customerId"
  [customerRecordId]="customerRecordId"
  (locationSubmitted)="onContactPersonCreated($event)">
</app-customer-record-contact-locations-create-edit-dialog>

<app-customer-record-contact-locations-detail-dialog
  #contactLocationsDetailDialog
  [customerId]="customerId"
  [customerRecordId]="customerRecordId"
  [readonly]="false"
  (locationRemoved)="contactLocationsComponent.loadList()">
</app-customer-record-contact-locations-detail-dialog>

<app-file-upload-dialog
  [uploadPath]="contactLocationUploadPath"
  (onResult)="onContactLocationImported($event)"
  #contactLocationImportDialog
></app-file-upload-dialog>

<app-confirm-dialog
  #contactInviteToHelpdeskDialog
  [titleEnabled]="true"
  [title]="'CUSTOMER_RECORD_INVITE_TO_HELPDESK_DIALOG_TITLE' | translate"
  [messageEnabled]="false"
  [negativeButtonEnabled]="!contactInviteResult"
  [positiveButton]="(contactInviteResult ? 'COMMON_BUTTON_OK' : 'COMMON_BUTTON_CONTINUE') | translate"
  [hideOnConfirm]="!!contactInviteResult"
  (onConfirm)="contactInviteResult ? contactInviteResult = null : inviteToHelpdesk($event)">
  <div class="alert alert-warning" *ngIf="!contactInviteResult">
    <label>{{'CUSTOMER_RECORD_INVITE_TO_HELPDESK_DIALOG_WARNING' | translate: {count: contactInviteToHelpdeskDialog.emitterValue ? contactInviteToHelpdeskDialog.emitterValue.length : 0} }}</label>
  </div>
  <div *ngIf="contactInviteResult">
    <div class="alert alert-success" *ngIf="contactInviteResult.invited.length > 0">
      <label>{{'CUSTOMER_RECORD_INVITE_TO_HELPDESK_DIALOG_INVITED' | translate }}
        : {{contactInviteResult.invited}}</label>
    </div>
    <div class="alert alert-info" *ngIf="contactInviteResult.alreadyRegistered.length > 0">
      <label>{{'CUSTOMER_RECORD_INVITE_TO_HELPDESK_DIALOG_ALREADY_REGISTERED' | translate }}
        : {{contactInviteResult.alreadyRegistered}}</label>
    </div>
    <div class="alert alert-danger" *ngIf="contactInviteResult.invalidEmail.length > 0">
      <label>{{'CUSTOMER_RECORD_INVITE_TO_HELPDESK_DIALOG_INVALID_EMAIL' | translate }}
        : {{contactInviteResult.invalidEmail}}</label>
    </div>
  </div>
</app-confirm-dialog>
