<div bsModal [config]="UiConstants.modalConfig" #fieldDialog="bs-modal" (onHide)="onModalHide($event)"
     class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content" *ngIf="visible">
      <div class="modal-header">
        <h4 class="modal-title">{{dialogTitleDictionaryKey | translate}}</h4>
        <button type="button" class="close" (click)="closeDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="card-body p-0">

          <div class="row">

            <!-- region Title -->
            <div class="form-group col-md-12"
                 [ngClass]="{ 'has-danger': hasFieldError(Form.FieldValidatedField.TITLE) || hasLocalFieldError(title)}">
              <div class="row">
                <label class="col-md-4">{{'COMMON_NAME' | translate}}</label>
                <div class="col-md-8 m-0 p-0">
                  <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
                         maxlength="{{UiConstants.maximumVarcharLength}}"
                         [ngClass]="{ 'form-control-danger': hasFieldError(Form.FieldValidatedField.TITLE) || hasLocalFieldError(title) }"
                         [(ngModel)]="fieldModel.title"
                         (ngModelChange)="removeFieldError(Form.FieldValidatedField.TITLE)"
                         (blur)="onTitleBlur()"
                         name="title" id="title" #title="ngModel" required>
                  <div class="form-control-feedback" *ngIf="hasFieldError(Form.FieldValidatedField.TITLE)">
                    {{getFieldErrorText(Form.FieldValidatedField.TITLE)}}
                  </div>
                  <div class="form-control-feedback" *ngIf="hasLocalFieldError(title)">
                    {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                  </div>
                </div>
              </div>
            </div>
            <!-- endregion Title -->

            <!-- region field width - DISABLED -->
            <div class="form-group col-md-12">
              <div class="row">
                <label class="col-md-4">{{'FORM_ITEM_COMMON_FIELD_WIDTH' | translate}}</label>
                <select class="col-md-8 m-0 form-control" [(ngModel)]="fieldModel.fieldWidthType"
                        id="fieldWidth" name="fieldWidth" #fieldWidth="ngModel"
                        [disabled]="true">
                  <option *ngFor="let widthType of fieldWidthTypes"
                          [ngValue]="widthType">{{widthType.textDictionaryKey | translate}}</option>
                </select>
              </div>
            </div>
            <!-- endregion field width - DISABLED -->

          </div> <!-- ./row end -->

          <div class="row">

            <!-- region Description -->
            <div class="form-group col-md-12">
              <div class="row">
                <label class="col-md-4">{{'COMMON_DESCRIPTION' | translate}}</label>
                <input type="text" class="form-control col-md-8" [(ngModel)]="fieldModel.description"
                       maxlength="{{UiConstants.maximumVarcharLength}}"
                       #description="ngModel"
                       placeholder="{{'COMMON_DESCRIPTION' | translate}}"
                       name="description" id="description">
              </div>
            </div>
            <!-- endregion Description -->

          </div> <!-- ./row end -->

          <div class="row">

            <!-- region API export name -->
            <div class="form-group col-md-12"
                 [ngClass]="{ 'has-danger': hasFieldError(Form.FieldValidatedField.API_EXPORT_NAME) || hasLocalFieldError(api_export_name) }">
              <div class="row">
                <label class="col-md-4">{{'FORM_ITEM_COMMON_EXPORT_NAME' | translate}}</label>
                <div class="col-md-8 m-0 p-0">
                  <input type="text" class="form-control" [(ngModel)]="fieldModel.apiExportName"
                         maxlength="{{UiConstants.maximumVarcharLength}}"
                         #api_export_name="ngModel"
                         [ngClass]="{ 'form-control-danger': hasFieldError(Form.FieldValidatedField.API_EXPORT_NAME) || hasLocalFieldError(api_export_name) }"
                         (ngModelChange)="removeFieldError(Form.FieldValidatedField.API_EXPORT_NAME)"
                         placeholder="{{'FORM_ITEM_COMMON_EXPORT_NAME' | translate}}"
                         name="api_export_name" id="api_export_name"
                         required>
                  <div class="form-control-feedback" *ngIf="hasFieldError(Form.FieldValidatedField.API_EXPORT_NAME)">
                    {{getFieldErrorText(Form.FieldValidatedField.API_EXPORT_NAME)}}
                  </div>
                  <div class="form-control-feedback" *ngIf="hasLocalFieldError(api_export_name)">
                    {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                  </div>
                </div>
              </div>
            </div>
            <!-- endregion API export name -->

          </div> <!-- ./row end -->

          <div class="row">

            <!-- region PDF export name -->
            <div class="form-group col-md-12"
                 [ngClass]="{ 'has-danger': hasFieldError(Form.FieldValidatedField.PDF_EXPORT_NAME) }">
              <div class="row">
                <label class="col-md-4">{{'FORM_ITEM_COMMON_PDF_EXPORT_NAME' | translate}}</label>
                <div class="col-md-8 m-0 p-0">
                  <input type="text" class="form-control" [(ngModel)]="fieldModel.pdfExportName"
                         maxlength="{{UiConstants.maximumVarcharLength}}"
                         #pdfExportName="ngModel" name="pdfExportName" id="pdfExportName"
                         (ngModelChange)="removeFieldError(Form.FieldValidatedField.PDF_EXPORT_NAME)"
                         placeholder="{{'FORM_ITEM_COMMON_PDF_EXPORT_NAME' | translate}}"
                         [ngClass]="{ 'form-control-danger': hasFieldError(Form.FieldValidatedField.PDF_EXPORT_NAME) }">
                  <div class="form-control-feedback" *ngIf="hasFieldError(Form.FieldValidatedField.PDF_EXPORT_NAME)">
                    {{getFieldErrorText(Form.FieldValidatedField.PDF_EXPORT_NAME)}}
                  </div>
                </div>
              </div>
            </div>
            <!-- endregion PDF export name -->

          </div> <!-- ./row end -->

          <div class="row">

            <!-- region Field validation type (admin) -->
            <div class="form-group col-md-12">
              <div class="row">
                <label class="col-md-4">{{'FORM_ITEM_COMMON_ADMIN_FIELD_VALIDATION_TYPE' | translate}}</label>
                <select class="form-control col-md-8"
                        [(ngModel)]="fieldModel.adminFormFieldValidationType"
                        (change)="onAdminFormFieldValidationTypeChanged()"
                        #adminFormFieldValidationType="ngModel" id="adminFormFieldValidationType"
                        name="adminFormFieldValidationType">
                  <option *ngFor="let vt of fieldValidationTypes"
                          [ngValue]="vt">{{getFieldValidationTypeDictionaryKey(vt) | translate}}</option>
                </select>
              </div>
            </div>
            <!-- endregion Field validation type (admin) -->

            <!-- region Field validation type (mobile) -->
            <div class="form-group col-md-12">
              <div class="row">
                <label class="col-md-4">{{'FORM_ITEM_COMMON_MOBILE_FIELD_VALIDATION_TYPE' | translate}}</label>
                <select class="form-control col-md-8"
                        [(ngModel)]="fieldModel.mobileFormFieldValidationType"
                        (change)="onMobileFormFieldValidationTypeChanged()"
                        #adminFormFieldValidationType="ngModel" id="mobileFormFieldValidationType"
                        name="mobileFormFieldValidationType">
                  <option *ngFor="let vt of fieldValidationTypes" [ngValue]="vt">
                    {{getFieldValidationTypeDictionaryKey(vt) | translate}}
                  </option>
                </select>
              </div>
            </div>
            <!-- endregion Field validation type (mobile) -->

            <!-- region Field validation type (helpdesk) -->
            <div class="form-group col-md-12">
              <div class="row">
              <label class="col-md-4">{{'FORM_ITEM_COMMON_HELPDESK_FIELD_VALIDATION_TYPE' | translate}}</label>
              <select class="form-control col-md-8"
                      [(ngModel)]="fieldModel.helpdeskFormFieldValidationType"
                      #helpdeskFormFieldValidationType="ngModel" id="helpdeskFormFieldValidationType" name="helpdeskFormFieldValidationType">
                <option *ngFor="let vt of fieldValidationTypes" [ngValue]="vt">{{getFieldValidationTypeDictionaryKey(vt) | translate}}</option>
                </select>
              </div>
            </div>
            <!-- endregion Field validation type (helpdesk) -->

          </div> <!-- ./row end -->

          <div class="row">

            <!-- region available discounts -->
            <div class="form-group col-md-12">
              <div class="row">
                <label class="col-md-4">
                  {{'FORM_ITEM_STOCK_AVAILABLE_DISCOUNTS' | translate}}
                  <i class="icomoon icomoon-info info-icon"
                     popover="{{'FORM_ITEM_STOCK_AVAILABLE_DISCOUNTS_HINT' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-8 px-0">
                  <app-material-tag-input
                    name="availableDiscountsTagInput"
                    id="availableDiscountsTagInput"
                    #availableDiscountsTagInput
                    [(ngModel)]="fieldModel.availableDiscounts"
                    (modelChange)="checkAvailableDiscounts()"
                    [textMaskInput]="{mask: InputMask.RELATIVE_INTEGER, guide: false}"
                    [placeholderStringKey]="'FORM_ITEM_STOCK_AVAILABLE_DISCOUNTS'"
                    [separatorCharacter]="StockFormFieldAvailableDiscountsSequence.sequenceBlockSeparator"
                    [separatorKeyCodes]="StockFormFieldAvailableDiscountsSequence.separatorKeysCodes"
                    [shouldGenerateExample]="false">
                  </app-material-tag-input>
                </div>
              </div>
            </div>
            <!-- endregion available discounts -->

          </div> <!-- ./row end -->

          <div class="row">

            <!-- region usable stock categories -->
            <div class="form-group col-md-12">
              <div class="row">
                <label class="col-md-4">
                  {{'FORM_ITEM_STOCK_USABLE_STOCK_CATEGORIES' | translate}}
                  <i class="icomoon icomoon-info info-icon"
                     popover="{{'FORM_ITEM_STOCK_USABLE_STOCK_CATEGORIES_HINT' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-8 px-0">
                  <angular2-multiselect
                    class="col-md-8"
                    [(ngModel)]="fieldModel.usableStockItemCategories"
                    [data]="stockItemCategoryItems"
                    [settings]="dropdownSettings"
                    (onRemoteSearch)="loadStockItemCategories($event.target.value)"
                    #usableStockItemCategories="ngModel"
                    id="usableStockItemCategories"
                    name="usableStockItemCategories"
                    validateEnabledItems>
                  </angular2-multiselect>
                </div>
              </div>
            </div>
            <!-- endregion usable stock categories -->

            <div class="form-group col-md-12">
              <div class="row">
                <label class="col-md-4">
                  {{'FORM_ITEM_STOCK_MOVE_SOURCE_STOCK_TYPE' | translate}}
                </label>
                <div class="col-md-8 px-0">
                  <angular2-multiselect
                    class="col-md-8"
                    [(ngModel)]="fieldModel.sourceStockType"
                    [data]="stockTypes"
                    (onSelect)="sourceStockTypeChanged($event)"
                    (onDeSelect)="sourceStockTypeChanged()"
                    [settings]="typeDropdownSettings"
                    #sourceStockTypeInput="ngModel"
                    id="sourceStockType"
                    name="sourceStockType">
                  </angular2-multiselect>
                </div>
              </div>
            </div>

            <!-- region stock source filter -->
            <div class="form-group col-md-12">
              <div class="row">
                <label class="col-md-4">
                  {{'FORM_ITEM_STOCK_STOCK_SOURCE_FILTER' | translate}}
                  <i class="icomoon icomoon-info info-icon"
                     popover="{{'FORM_ITEM_STOCK_STOCK_SOURCE_FILTER_HINT' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-8 px-0">
                  <angular2-multiselect
                    class="col-md-8"
                    [(ngModel)]="fieldModel.stockSourceFilters"
                    [data]="sourceStocks"
                    [settings]="stockDropdownSettings"
                    (onRemoteSearch)="loadSourceStocks($event.target.value, fieldModel.sourceStockTypeName)"
                    #stockSourceFilters="ngModel"
                    id="stockSourceFilters"
                    name="stockSourceFilters"
                    validateEnabledItems>
                  </angular2-multiselect>
                </div>
              </div>
            </div>
            <!-- endregion stock source filter -->

          </div> <!-- ./row end -->

          <div class="row">

            <!-- region modify store amount -->
            <div class="form-group col-md-12">
              <div class="row">
                <label class="col-md-4">{{'FORM_ITEM_STOCK_MODIFY_STORED_AMOUNT' | translate}}
                  <i class="icomoon icomoon-info info-icon"
                     popover="{{'FORM_ITEM_STOCK_MODIFY_STORED_AMOUNT_HINT' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i></label>
                <label class="switch switch-text switch-info mb-0">
                  <input type="checkbox" class="switch-input" [(ngModel)]="fieldModel.modifyStoredAmount"
                         [disabled]="!fieldModel.modifyStoredAmountEnabled"
                         #modifyStoredAmount="ngModel" id="modifyStoredAmount" name="modifyStoredAmount">
                  <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                  <span class="switch-handle"></span>
                </label>
              </div>
            </div>
            <!-- endregion modify store amount -->

          </div> <!-- ./row end -->

          <div class="row">

            <!--            &lt;!&ndash; region show on receipt &ndash;&gt;-->
            <!--            <div class="form-group col-md-12">-->
            <!--              <div class="row">-->
            <!--                <label class="col-md-4">{{'FORM_ITEM_COMMON_SHOW_ON_RECEIPT' | translate}}</label>-->
            <!--                <label class="switch switch-text switch-info mb-0">-->
            <!--                  <input type="checkbox" class="switch-input" [(ngModel)]="fieldModel.showOnReceipt"-->
            <!--                         #showOnReceipt="ngModel" id="showOnReceipt" name="showOnReceipt">-->
            <!--                  <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>-->
            <!--                  <span class="switch-handle"></span>-->
            <!--                </label>-->
            <!--              </div>-->
            <!--            </div>-->
            <!--            &lt;!&ndash; endregion show on receipt &ndash;&gt;-->

          </div> <!-- ./row end -->

          <div class="row">
            <!-- region hide prices overview -->
            <div class="form-group col-md-12">
              <div class="row">
                <label class="col-md-4">{{'FORM_ITEM_STOCK_HIDE_PRICES_OVERVIEW' | translate}}
                  <i class="icomoon icomoon-info info-icon"
                     popover="{{'FORM_ITEM_STOCK_HIDE_PRICES_OVERVIEW_HINT' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i></label>
                <label class="switch switch-text switch-info mb-0">
                  <input type="checkbox" class="switch-input" [(ngModel)]="fieldModel.hidePricesOverview"
                         #hidePricesOverview="ngModel" id="hidePricesOverview" name="hidePricesOverview">
                  <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                  <span class="switch-handle"></span>
                </label>
              </div>
            </div>
            <!-- endregion show on receipt -->
          </div>
          <div class="row">
            <!-- region hide prices edit -->
            <div class="form-group col-md-12">
              <div class="row">
                <label class="col-md-4">{{'FORM_ITEM_STOCK_HIDE_PRICES_EDIT' | translate}}
                  <i class="icomoon icomoon-info info-icon"
                     popover="{{'FORM_ITEM_STOCK_HIDE_PRICES_EDIT_HINT' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i></label>
                <label class="switch switch-text switch-info mb-0">
                  <input type="checkbox" class="switch-input" [(ngModel)]="fieldModel.hidePricesEdit"
                         #hidePricesEdit="ngModel" id="hidePricesEdit" name="hidePricesEdit">
                  <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                  <span class="switch-handle"></span>
                </label>
              </div>
            </div>
            <!-- endregion show on receipt -->
          </div>
          <!-- /.row -->
          <!-- /.row -->
          <div class="row">
            <!-- region advancedModeEnabled -->
            <div class="form-group col-md-12">
              <div class="row">
                <label class="col-md-4">{{'FORM_ITEM_STOCK_ADVANCED_MODE_ENABLED' | translate}}
                  <i class="icomoon icomoon-info info-icon"
                     popover="{{'FORM_ITEM_STOCK_ADVANCED_MODE_ENABLED_HINT' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i></label>
                <label class="switch switch-text switch-info mb-0">
                  <input type="checkbox" class="switch-input" [(ngModel)]="fieldModel.advancedModeEnabled"
                         #advancedModeEnabled="ngModel" id="advancedModeEnabled" name="advancedModeEnabled">
                  <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                  <span class="switch-handle"></span>
                </label>
              </div>
            </div>
            <!-- endregion show on receipt -->
          </div>
          <!-- /.row -->
          <div class="row">
            <!-- region hide prices edit -->
            <div class="form-group col-md-12">
              <div class="row">
                <label class="col-md-4">{{'FORM_ITEM_STOCK_UNIQUE_STOCK_RECORDS' | translate}}
                  <i class="icomoon icomoon-info info-icon"
                     popover="{{'FORM_ITEM_STOCK_UNIQUE_STOCK_RECORDS_HINT' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i></label>
                <label class="switch switch-text switch-info mb-0">
                  <input type="checkbox" class="switch-input" [(ngModel)]="fieldModel.uniqueStockRecords"
                         #uniqueStockRecords="ngModel" id="uniqueStockRecords" name="uniqueStockRecords">
                  <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                  <span class="switch-handle"></span>
                </label>
              </div>
            </div>
            <!-- endregion show on receipt -->
          </div>
          <!-- /.row -->
          <div class="row">
            <!-- region hide prices edit -->
            <div class="form-group col-md-12">
              <div class="row">
                <label class="col-md-4">{{'FORM_ITEM_STOCK_DISPERSION_PERCENT_ENABLED' | translate}}
                  <i class="icomoon icomoon-info info-icon"
                     popover="{{'FORM_ITEM_STOCK_DISPERSION_PERCENT_ENABLED_HINT' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i></label>
                <label class="switch switch-text switch-info mb-0">
                  <input type="checkbox" class="switch-input" [(ngModel)]="fieldModel.dispersionPercentEnabled"
                         (ngModelChange)="onDispersionPercentEnabledChange($event)"
                         #dispersionPercentEnabled="ngModel" id="dispersionPercentEnabled"
                         name="dispersionPercentEnabled">
                  <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                  <span class="switch-handle"></span>
                </label>
              </div>
            </div>
            <!-- endregion show on receipt -->
          </div>
          <!-- /.row -->
          <div class="row">
            <!-- region comment edit -->
            <div class="form-group col-md-12">
              <div class="row">
                <label class="col-md-4">{{'FORM_ITEM_STOCK_COMMENT_ENABLED' | translate}}
                  <i class="icomoon icomoon-info info-icon"
                     popover="{{'FORM_ITEM_STOCK_COMMENT_ENABLED_HINT' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i></label>
                <label class="switch switch-text switch-info mb-0">
                  <input type="checkbox" class="switch-input" [(ngModel)]="fieldModel.commentEnabled"
                         #commentEnabled="ngModel" id="commentEnabled" name="commentEnabled">
                  <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                  <span class="switch-handle"></span>
                </label>
              </div>
            </div>
            <div class="form-group col-md-12" *ngIf="fieldModel.commentEnabled">
              <div class="row">
                <label class="col-md-4">{{'FORM_ITEM_STOCK_COMMENT_REQUIRED' | translate}}
                  <i class="icomoon icomoon-info info-icon"
                     popover="{{'FORM_ITEM_STOCK_COMMENT_REQUIRED_HINT' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i></label>
                <label class="switch switch-text switch-info mb-0">
                  <input type="checkbox" class="switch-input" [(ngModel)]="fieldModel.commentRequired"
                         #commentRequired="ngModel" id="commentRequired" name="commentRequired">
                  <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                  <span class="switch-handle"></span>
                </label>
              </div>
            </div>
            <div class="form-group col-md-12" *ngIf="fieldModel.commentEnabled">
              <div class="row">
                <label class="col-md-4">{{'FORM_ITEM_STOCK_COMMENT_CUSTOM_LABEL' | translate}}</label>
                <input type="text" class="form-control col-md-8" [(ngModel)]="fieldModel.commentCustomLabel"
                       maxlength="{{UiConstants.maximumVarcharLength}}"
                       #commentCustomLabel="ngModel"
                       placeholder="{{'FORM_ITEM_STOCK_COMMENT_CUSTOM_LABEL' | translate}}"
                       name="commentCustomLabel" id="commentCustomLabel">
              </div>
            </div>
            <!-- endregion comment -->
          </div>
          <!-- /.row -->
          <div class="row">
            <!-- region exactCategoryMatch edit -->
            <div class="form-group col-md-12">
              <div class="row">
                <label class="col-md-4">{{'FORM_ITEM_STOCK_EXACT_CATEGORY_MATCH' | translate}}
                  <i class="icomoon icomoon-info info-icon"
                     popover="{{'FORM_ITEM_STOCK_EXACT_CATEGORY_MATCH_HINT' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i></label>
                <label class="switch switch-text switch-info mb-0">
                  <input type="checkbox" class="switch-input" [(ngModel)]="fieldModel.exactCategoryMatch"
                         #exactCategoryMatch="ngModel" id="exactCategoryMatch" name="exactCategoryMatch">
                  <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                  <span class="switch-handle"></span>
                </label>
              </div>
            </div>
            <!-- endregion show on receipt -->
          </div>
          <!-- /.row -->
          <div class="row">
            <!-- region fillAssigneeStock edit -->
            <div class="form-group col-md-12">
              <div class="row">
                <label class="col-md-4">{{'FORM_ITEM_STOCK_FILL_ASSIGNEE_STOCK' | translate}}
                  <i class="icomoon icomoon-info info-icon"
                     popover="{{'FORM_ITEM_STOCK_FILL_ASSIGNEE_STOCK_HINT' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i></label>
                <label class="switch switch-text switch-info mb-0">
                  <input type="checkbox" class="switch-input" [(ngModel)]="fieldModel.fillAssigneeStock"
                         #fillAssigneeStock="ngModel" id="fillAssigneeStock" name="fillAssigneeStock">
                  <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                  <span class="switch-handle"></span>
                </label>
              </div>
              <!-- endregion FillAssigneeStock -->
            </div>
          </div>
          <div class="row">
            <!-- region defaultAssigneeFilter edit -->
            <div class="form-group col-md-12">
              <div class="row">
                <label class="col-md-4">{{'FORM_ITEM_DEFAULT_ASSIGNEE_FILTER' | translate}}
                  <i class="icomoon icomoon-info info-icon"
                     popover="{{'FORM_ITEM_DEFAULT_ASSIGNEE_FILTER_HINT' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i></label>
                <label class="switch switch-text switch-info mb-0">
                  <input type="checkbox" class="switch-input" [(ngModel)]="fieldModel.defaultFilterAssignee"
                         [disabled]="fieldModel.sourceStockTypeName !== 'PERSONAL' && fieldModel.sourceStockTypeName !== undefined"
                         #defaultFilterAssignee="ngModel" id="defaultFilterAssignee" name="defaultFilterAssignee">
                  <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                  <span class="switch-handle"></span>
                </label>
              </div>
              <!-- endregion defaultAssigneeFilter edit -->
            </div>
          </div>
          <div class="row">
            <!-- region clearOnReopen edit -->
            <div class="form-group col-md-12">
              <div class="row">
                <label class="col-md-4">{{'FORM_ITEM_CLEAR_ON_REOPEN' | translate}}</label>
                <label class="switch switch-text switch-info mb-0">
                  <input type="checkbox" class="switch-input" [(ngModel)]="fieldModel.clearOnReopen"
                         #clearOnReopen="ngModel" id="clearOnReopen" name="clearOnReopen">
                  <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                  <span class="switch-handle"></span>
                </label>
              </div>
              <!-- endregion clearOnReopen edit -->
            </div>
          </div>
          <!-- /.row -->

          <!-- region used for invoice -->
          <div class="row">
          <div class="form-group col-md-12">
            <div class="row">
              <label class="col-md-4">{{'FORM_ITEM_INVOICE_USED_FOR_INVOICE' | translate}}</label>
              <label class="switch switch-text switch-info mb-0">
                <input type="checkbox" class="switch-input" [(ngModel)]="fieldModel.usedForInvoice"
                       #usedForInvoice="ngModel" id="usedForInvoice" name="usedForInvoice">
                <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                <span class="switch-handle"></span>
              </label>
            </div>
          </div>
          </div>
          <!-- endregion used for invoice -->

        </div> <!-- ./card-body end -->
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeDialog()">{{'COMMON_BUTTON_CANCEL' | translate}}
        </button>
        <button type="button" class="btn btn-primary" #functionButton (click)="saveField()">{{'COMMON_BUTTON_SAVE' |
          translate}}
        </button>
      </div>

    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
