/* eslint-disable */
import { Component, Input, OnInit } from '@angular/core';
import { MultiselectOptionItem, SelectUtils, UiConstants } from '../../../../util/core-utils';
import { NgForm } from '@angular/forms';
import { AddressModel } from '../../../../lib/address';
import { TranslateService } from '@ngx-translate/core';
import { OrderType, QueryResult } from '../../../../lib/util/services';
import { Country, CountryService } from '../../../../lib/country.service';
import { List, Set } from 'immutable';
import { CompanyStock, CompanyStockService } from '../../../../lib/company-stock/company-stock.service';
import { ShippingDemand } from '../../../../lib/shipping-demand/shipping-demand.service';
import { ConfigurationService } from '../../../../lib/core-ext/configuration.service';
import { ComponentStateResolver } from '../../../../util/component-state/component-state-resolver';
import { RightModel } from '../../../../app.rights';
import { CompanyMultiselectProvider } from '../../../../lib/company/company-multiselect.provider';
import { Angular2Multiselects } from '../../../../util/multiselect';
import { CompanyLocationMultiselectProvider } from '../../../../lib/company-location/company-location-multiselect.provider';
import { addressTypes, ShippingDemandAddressModel } from './shipping-demand-address.model';
import { Strings } from "../../../../lib/util/strings";

/* eslint-enable */

@Component({
  selector: 'app-shipping-demand-address',
  templateUrl: './shipping-demand-address.component.html',
  styleUrls: ['./shipping-demand-address.component.scss']
})
export class ShippingDemandAddressComponent implements OnInit {

  SelectUtils = SelectUtils;
  EditableField = ShippingDemand.EditableField;
  addressTypes = addressTypes;

  @Input()
  model: ShippingDemandAddressModel;

  @Input()
  form: NgForm;

  @Input()
  componentState: ComponentStateResolver;

  @Input()
  rightModel: RightModel = RightModel.empty();

  @Input()
  editableFieldModel: ShippingDemand.EditableFields;

  _disabled: boolean = false;

  @Input()
  set disabled(value: boolean) {
    this._disabled = value;
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .disabled(value)
      .build();
    this.addressTypeDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(false)
      .enableCheckAll(false)
      .translate(true)
      .disabled(value)
      .build();
  }

  get disabled() {
    return this._disabled;
  }

  // Country items for postal address
  countryItems: List<AddressModel.CountryItem>;

  companyItems: MultiselectOptionItem<number>[] = [];
  dropdownSettings: Angular2Multiselects.Settings;
  addressTypeDropdownSettings: Angular2Multiselects.Settings;

  locationItems: MultiselectOptionItem<number>[] = [];

  stockItems: MultiselectOptionItem<number>[] = [];

  constructor(
    private translateService: TranslateService,
    private countryService: CountryService,
    private stockService: CompanyStockService,
    private configService: ConfigurationService,
    private companyMultiselectProvider: CompanyMultiselectProvider,
    private companyLocationMultiselectProvider: CompanyLocationMultiselectProvider
  ) {
  }

  ngOnInit() {
    this.loadCountries();
    this.loadCompanies();
    this.initDropdownSettings();
    this.model.companyLoadedObservable.subscribe((result: boolean) => {
      if (result) {
        this.loadLocations();
        this.loadStocks(false);
      }
    });
  }

  initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
    this.addressTypeDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(false)
      .enableCheckAll(false)
      .translate(true)
      .build();
  }

  private loadCountries() {
    this.countryService.query({}).subscribe((result: QueryResult<Country.Country>) => {
      this.countryItems = AddressModel.CountryItem.fromCountryList(result.items);
      this.model.postalAddress.loadCountryItems(this.countryItems, this.configService.getDefaultSelectedCountryCode());
    });
  }

  private loadCompanies() {
    this.companyMultiselectProvider.loadAll().subscribe((companies) => {
      this.companyItems = companies;
    })
  }

  private loadLocations(autofill?: boolean) {
    if (this.model.companyItem.length > 0) {
      const companyIds = this.model.companyItem.length > 0 ? Set.of(this.model.companyItem[0].id) : undefined;
      this.companyLocationMultiselectProvider.loadCompanyLocationsWithCompanyIds(companyIds).subscribe((locations) => {
        this.locationItems = locations;
      });
      this.locationItems.forEach(item => {
        if (this.model.locationItem.length > 0 && this.model.locationItem[0].id === item.id) {
          this.model.locationItem[0] = item;
        }
      });
      if (this.locationItems.length === 1) {
        this.model.locationItem.splice(0, this.model.locationItem.length);
        if (!!autofill) {
          this.model.locationItem.push(this.locationItems[0]);
        }
        this.loadStocks(autofill);
      }
      else {
        this.stockItems = [];
      }
    }
    else {
      this.locationItems.splice(0, this.locationItems.length);
    }
  }

  private loadStocks(autofillStock?: boolean) {
    if (this.model.locationItem) {
      this.stockItems = [];
      this.stockService.query({
        disabled: false,
        companyLocationIds: this.model.locationItem.length > 0 ? Set.of(this.model.locationItem[0].id) : undefined,
        orders: Set.of({field: CompanyStock.OrderField.NAME, type: OrderType.ASC}),
        paging: {
          pageNumber: 1,
          numberOfItems: UiConstants.autocompletePageSize
        }
      }).subscribe(
        (result: QueryResult<CompanyStock.CompanyStock>) => {
          result.items.forEach((stock: CompanyStock.CompanyStock) => {
            const item = {
              id: stock.id,
              itemName: stock.name
            };
            this.stockItems.push(item);
            if (this.model.stockItem[0] && this.model.stockItem[0].id === stock.id) {
              this.model.stockItem[0].itemName = stock.name;
            }
          });
          if (this.stockItems.length === 1
            && !!autofillStock
            && ((this.isEnabledField(ShippingDemand.EditableField.SOURCE_STOCK) && this.model.isSource())
              || (this.isEnabledField(ShippingDemand.EditableField.DESTINATION_STOCK) && this.model.isDestination()))) {
            this.model.stockItem.splice(0, this.model.stockItem.length);
            this.model.stockItem.push(...this.stockItems);
          }
        });
    }
  }

  onAddressTypeChanged() {
    this.model.locationItem = [];
    this.model.stockItem = [];
    this.model.addressName = '';
    this.model.postalAddress.reset();
    this.model.postalAddress.type = AddressModel.PostalAddressModelType.COMPLEX;
  }

  onCompanyChanged() {
    this.model.locationItem = [];
    this.model.stockItem = [];
    this.loadLocations(true);
  }

  onLocationChanged() {
    this.model.stockItem = [];
    if (this.model.locationItem.length === 1) {
      this.loadStocks(true);
    }
    else {
      this.stockItems = [];
    }
  }

  onCompanySearch(predicate?: string) {
    this.companyMultiselectProvider.searchCompanies(predicate).subscribe((companies) => {
      this.companyItems = companies;
    })
  }

  onLocationSearch(predicate?: string) {
    const companyId = this.model.companyItem.length > 0 ? this.model.companyItem[0].id : undefined;
    this.companyLocationMultiselectProvider
      .searchCompanyLocationsWithCompanyIds(predicate, companyId ? Set.of(companyId) : undefined)
      .subscribe((locations) => {
      this.locationItems = locations;
    })
  }

  onStockSearch(predicate?: string) {
    this.stockService.query({
      name: predicate ? Strings.undefinedOrNonEmpty(predicate) : undefined,
      disabled: false,
      companyLocationIds: this.model.locationItem.length > 0 ? Set.of(this.model.locationItem[0].id) : undefined,
      orders: Set.of({field: CompanyStock.OrderField.NAME, type: OrderType.ASC}),
      paging: {
        pageNumber: 1,
        numberOfItems: UiConstants.autocompletePageSize
      },
      noProgressBar: true
    }).subscribe(
      (result: QueryResult<CompanyStock.CompanyStock>) => {
        this.stockItems = result.items.toArray().map(s => ({id: s.id, itemName: s.name}));
      });
  }

  isEnabledField(field: ShippingDemand.EditableField): boolean {
    if (this.componentState.isCreateView() || this.componentState.isCloneView()) {
      if (this.rightModel.shippingDemandCreate.hasRight()) {
        return this.editableFieldModel.isEnabledByServer(false, field);
      }
      if (this.rightModel.shippingDemandCreateSimple.hasRight()) {
        return this.editableFieldModel.isEnabledByServer(true, field);
      }
      return false;
    }
    if (this.componentState.isEditView()) {
      if (this.rightModel.shippingDemandUpdate.hasRight()) {
        return this.editableFieldModel.isEnabledByServer(false, field);
      }
      if (this.rightModel.shippingDemandUpdateSimple.hasRight()) {
        return this.editableFieldModel.isEnabledByServer(true, field);
      }
      return false;
    }
    return false;
  }

}
