/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from '../../util/http-services';
import { ResourceQueryResult } from '../../util/services';
import { EmptyMessage } from '../../util/messages';

/* eslint-enable */

@Injectable()
export class InvoiceBookResourceService extends BaseHttpService {

  query(request: InvoiceBookResource.QueryRequest): Observable<ResourceQueryResult<InvoiceBookResource.InvoiceBook>> {
    return this.http.get<ResourceQueryResult<InvoiceBookResource.InvoiceBook>>(this.url, this.parseParams(request));
  }

  get(request: InvoiceBookResource.GetRequest): Observable<InvoiceBookResource.InvoiceBook> {
    return this.http.get<InvoiceBookResource.InvoiceBook>(this.url + `${request.invoice_book_id}`);
  }

  create(request: InvoiceBookResource.CreateRequest): Observable<InvoiceBookResource.InvoiceBook> {
    return this.http.post<InvoiceBookResource.InvoiceBook>(this.url, request);
  }

  update(request: InvoiceBookResource.UpdateRequest): Observable<InvoiceBookResource.InvoiceBook> {
    return this.http.put<InvoiceBookResource.InvoiceBook>(this.url + `${request.invoice_book_id}`, request);
  }

  setDisabled(request: InvoiceBookResource.DisableRequest): Observable<EmptyMessage> {
    return this.http.patch<EmptyMessage>(this.url + `${request.invoice_book_id}/disabled`, request);
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/invoice/books/');
  }

}

export namespace InvoiceBookResource {

  export interface InvoiceBook {
    id: number,
    prefix: string,
    external_id: string,
    start_number: number,
    next_invoice_number: number,
    last_invoice_string: string,
    last_invoice_date?: string,
    invoice_settings: {
      id: number;
      name: string;
    };
    comment: string,
    disabled: boolean,
    creation_time: string,
    update_time: string
  }

  export interface QueryRequest {
    id?: string,
    disabled?: boolean,
    comment?: string,
    prefix?: string,
    external_id?: string,
    q?: string,
    invoice_settings_id?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
    no_progress_bar?: boolean;
  }

  export interface GetRequest {
    invoice_book_id: number;
  }

  export interface CreateRequest {
    prefix: string,
    external_id?: string,
    start_number: number,
    comment?: string;
    invoice_settings_id: number;
  }

  export interface UpdateRequest {
    invoice_book_id: number,
    external_id: string,
    comment?: string;
  }

  export interface DisableRequest {
    invoice_book_id: number;
    disabled: boolean;
  }

}
