/* eslint-disable */
import { Component, Injector, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Transition, UIRouter } from '@uirouter/angular';
import { DashboardCount } from '../../lib/util/dashboard-count.service';
/* eslint-enable */

@Component({
  selector: 'app-simple-dashboard',
  templateUrl: './simple-dashboard.component.html',
  styleUrls: ['./simple-dashboard.component.scss']
})
export class SimpleDashboardComponent implements OnInit {

  private countService: DashboardCount.Service;
  destination: string;
  private headerKey: string;

  list: DashboardCount.Item[] = [];

  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  constructor(
    private translateService: TranslateService,
    private transition: Transition,
    injector: Injector,
    private router: UIRouter
  ) {
    this.countService = injector.get(transition.params().countService);
    this.destination = transition.params().destination;
    this.headerKey = transition.params().headerKey;
  }

  ngOnInit() {
    this.translateService.get(this.headerKey).subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.loadCounts();
  }

  private loadCounts() {
    this.countService.getDashboardCount({}).subscribe((result) => {
      if (result.length === 1) {
        this.router.stateService.go(this.destination, { id: result[0].getId() });
      }
      this.list = result;
    })
  }

}
