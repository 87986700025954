<div class="card">

  <div class="card-header">
    {{'COMMON_PANEL_HEADING_ATTACHMENT' | translate}}
  </div>
  <div class="card-body">
    <app-material-attachment-gallery [loaderFactory]="fileLoaderFactory"
                                     [deleteAllowed]="grantedRights.attachmentDeleteFile.hasRight() && !readonly"
                                     [updateAllowed]="grantedRights.attachmentUpdateData.hasRight() && !readonly"
                                     [displayUpload]="grantedRights.attachmentUploadFile.hasRight() && !readonly">
    </app-material-attachment-gallery>
  </div>

</div><!-- /.card -->
