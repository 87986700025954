<div class="card">
  <div class="card-header">
    {{'FORM_PDF_HEADING'|translate}}
    <div class="card-actions">
      <a class="btn-setting table-header-action-button d-flex align-items-center px-1 w-auto font-weight-normal" title="{{'COMMON_BUTTON_PRINT' | translate}}"
         (click)="printPdfs()">
        <i class="icomoon pr-1 icomoon-thermal-paper"></i>
        <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_PRINT' | translate}}</span>
      </a>
    </div><!-- /.card-actions -->
  </div>
  <div class="card-body">
    <div class="col-sm-12 p-0" *ngIf="list.length > 0">

      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="TaskRecord.PdfOrderField.DOCUMENT_ID"
                                    [orderType]="queryModel.getOrderType(TaskRecord.PdfOrderField.DOCUMENT_ID)"
                                    (orderChange)="orderBy($event)"
                                    [checkAllEnabled]="true"
                                    [checkAllSelected]="eachPdfSelected"
                                    (onCheckAllClicked)="toggleEachPdf()"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="TaskRecord.PdfOrderField.TEMPLATE_NAME"
                                    [orderType]="queryModel.getOrderType(TaskRecord.PdfOrderField.TEMPLATE_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-field-sorter [orderField]="TaskRecord.PdfOrderField.CREATION_TIME"
                                    [orderType]="queryModel.getOrderType(TaskRecord.PdfOrderField.CREATION_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_CREATION_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op
              [text]="'TASK_RECORD_PANEL_PDF_TRIGGER_DATA' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op
              [text]="'TASK_RECORD_PANEL_PDF_ORIGINAL_TASK_RECORD' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr class="table" *ngFor="let row of list | paginate: {
                                                      id: 'task-record-pdf-list',
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }">
          <td class="fit align-middle">
            <div class="d-flex">
              <app-animated-checkbox
                class="toggle-checkbox"
                [(selected)]="row.selected">
              </app-animated-checkbox>
              {{row.pdf.pdfDocumentId}}
            </div>
          </td>
          <td class="responsive-table-column align-middle">{{row.pdf.templateName}}</td>
          <td class="fit hidden-md-down align-middle">{{row.pdf.creationTime.toUtcIsoString() | date:'short' }}</td>
          <td class="responsive-table-column align-middle">
            <div *ngIf="row.pdf.trigger">
              {{getTriggerEventStringKey(row.pdf.trigger.event) | translate}}<br/>
              <span class="table-text-subtitle">{{row.pdf.trigger.name}}</span>
            </div>
          </td>
          <td class="responsive-table-column align-middle">
            <div *ngIf="row.pdf.originalTaskRecord && +row.pdf.originalTaskRecord.id !== +taskRecordId">
              <a class="id-text cursor-pointer"
                 href="#" onclick="return false"
                 uiSref="Admin.TaskRecordDetail"
                 [uiParams]="{ taskId: row.pdf.originalTaskRecord.taskId, taskRecordId: row.pdf.originalTaskRecord.id }"
                 title="{{'COMMON_BUTTON_DETAILS' | translate}}">
                {{ row.pdf.originalTaskRecord.name }}
              </a><br/>
              <span class="text-truncate table-text-subtitle">
                  {{ row.pdf.originalTaskRecord.externalId }}
                </span>
            </div>
          </td>
          <td class="align-middle">
            <app-table-options-menu>
              <app-dropdown-item
                [iconClass]="'icomoon-download'"
                [titleStringKey]="'COMMON_BUTTON_DOWNLOAD'"
                class="cursor-pointer"
                (click)="downloadPdf(row.pdf.pdfDocumentId, false)">
              </app-dropdown-item>
              <app-dropdown-item
                [iconClass]="'icomoon-thermal-paper'"
                [titleStringKey]="'COMMON_BUTTON_PRINT'"
                class="cursor-pointer"
                (click)="downloadPdf(row.pdf.pdfDocumentId, true)">
              </app-dropdown-item>
            </app-table-options-menu>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="TaskRecord.PdfOrderField.DOCUMENT_ID"
                                    [orderType]="queryModel.getOrderType(TaskRecord.PdfOrderField.DOCUMENT_ID)"
                                    (orderChange)="orderBy($event)"
                                    [checkAllEnabled]="true"
                                    [checkAllSelected]="eachPdfSelected"
                                    (onCheckAllClicked)="toggleEachPdf()"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="TaskRecord.PdfOrderField.TEMPLATE_NAME"
                                    [orderType]="queryModel.getOrderType(TaskRecord.PdfOrderField.TEMPLATE_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-field-sorter [orderField]="TaskRecord.PdfOrderField.CREATION_TIME"
                                    [orderType]="queryModel.getOrderType(TaskRecord.PdfOrderField.CREATION_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_CREATION_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op
              [text]="'TASK_RECORD_PANEL_PDF_TRIGGER_DATA' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op
              [text]="'TASK_RECORD_PANEL_PDF_ORIGINAL_TASK_RECORD' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>

      <app-table-paging
        id="task-record-pdf-list"
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>
    <div class="p-2 text-center text-muted"
         *ngIf="list.length === 0">
      <h6>{{ "TASK_RECORD_NO_PDFS" | translate }}</h6>
    </div>
  </div>
</div>

<div class="container-vertical-padding-half"></div>


