import { Injectable } from '@angular/core';
import { UIRouter } from '@uirouter/angular';

@Injectable()
export class AppTypeHelperService {

  constructor(private uiRouter: UIRouter) {}

  isHelpdesk(): boolean {
    return this.uiRouter.stateService.$current.name.startsWith('Helpdesk');
  }

  appType(): AppType | undefined {
    if (this.uiRouter.stateService.$current.name.length === 0) {
      return undefined;
    }
    return this.isHelpdesk() ? AppType.HELPDESK : AppType.WEBDMIN;
  }
}

export enum AppType {
  WEBDMIN = 'webadmin',
  HELPDESK = 'helpdesk'
}
