/* eslint-disable */
import { Component, Input, TemplateRef } from '@angular/core';
import { CalendarUtils } from '../../../../../util/calendar/calendar-utils';
import LocalCalendarEvent = CalendarUtils.LocalCalendarEvent;

@Component({
  selector: 'mwl-calendar-event-title',
  template: `
    <ng-template #defaultTemplate let-event="event" let-view="view">
<!--      <div [style.color]="event.color.primary"-->
<!--           [style.font-size]="'0.75rem'"-->
<!--           [style.text-overflow]="'ellipsis'"-->
<!--           [style.overflow]="'hidden'">-->
<!--        {{event.start | date: 'shortTime'}} - {{event.end | date: 'shortTime'}}-->
<!--      </div>-->
      <div [style.color]="event.color.primary"
           [style.font-size]="'0.875rem'"
           [style.font-weight]="'bold'"
           [style.text-overflow]="'ellipsis'"
           [style.overflow]="'hidden'"
           class="d-flex align-items-center"
           *ngIf="event.line1 || event.iconClass">
        <i *ngIf="event.iconClass"
           class="{{event.iconClass}} font-xs mr-05">
        </i>
        <span class="mr-05" style="flex-grow: 9999; overflow: hidden; text-overflow: ellipsis">{{event.line1}}</span>
        <span>{{event.line1Initials}}</span>
      </div>
      <div [style.color]="event.color.primary"
           [style.font-size]="'0.75rem'"
           [style.text-overflow]="'ellipsis'"
           [style.overflow]="'hidden'"
           *ngIf="event.line2">
        {{event.line2}}
      </div>
      <div [style.color]="event.color.primary"
           [style.font-size]="'0.75rem'"
           [style.text-overflow]="'ellipsis'"
           [style.overflow]="'hidden'"
           *ngIf="event.line3">
        {{event.line3}}
      </div>
    </ng-template>
    <ng-template
      [ngTemplateOutlet]="customTemplate || defaultTemplate"
      [ngTemplateOutletContext]="{
        event: event,
        view: view
      }"
    >
    </ng-template>
  `
})
export class CalendarEventTitleComponent {

  @Input() event: LocalCalendarEvent;

  @Input() customTemplate: TemplateRef<any>;

  @Input() view: string;
}
