<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="card">

    <div class="card-header">
      {{'AUDIT_LOG_HEADING_LIST' | translate}}

      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()"
           title="{{'COMMON_SHOW_SEARCH_HINT' | translate}}"><i #searchIcon
                                                                class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>

    <div class="card-body border-bottom" *ngIf="showSearch">

      <div class="row form-group">
        <div class="col-md-3">
          <label class="search-label">{{'AUDIT_LOG_SEARCH_USER_NAME' | translate}}</label>
          <angular2-multiselect [data]="users" [settings]="dropdownSettings"
                                class="form-control"
                                [(ngModel)]="searchModel.user"
                                (onRemoteSearch)="onUserSearch($event.target.value)"
                                #userMulti="ngModel" name="userMulti" id="userMulti">
          </angular2-multiselect>
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'COMMON_METHOD' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'COMMON_METHOD' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.request_method" name="request_method" id="request_method" #method="ngModel">
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'AUDIT_LOG_SEARCH_REQUEST_URI' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'AUDIT_LOG_SEARCH_REQUEST_URI' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.request_uri" name="request_uri" id="request_uri" #uri="ngModel">
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'COMMON_CREATION_TIME' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control date"
                   placeholder="{{'COMMON_CREATION_TIME' | translate}}"
                   [(ngModel)]="searchModel.creation_time" ngbDatepicker name="creation_time" id="creation_time"
                   #creation_time="ngbDatepicker">
            <div class="input-group-addon-gray" (click)="creation_time.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-3" *ngIf="rightModel.mobileAppRead.hasRight()">
          <label class="search-label">{{'COMMON_APP_ID' | translate}}</label>
          <select class="form-control" [compareWith]="SelectUtils.compareObjects"
                  [(ngModel)]="searchModel.application_id"
                  id="application_id" name="application_id" #application_id="ngModel">
            <option *ngFor="let item of applicationIdItems" [ngValue]="item">{{item.text}}</option>
          </select>
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'AUDIT_LOG_SEARCH_METHOD_RESULT' | translate}}</label>
          <select class="form-control" [compareWith]="SelectUtils.compareObjects" [(ngModel)]="searchModel.result"
                  id="result" name="result" #result="ngModel">
            <option *ngFor="let item of auditLogResultItems" [ngValue]="item">{{item.text}}</option>
          </select>
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'AUDIT_LOG_SEARCH_CALL_TYPE' | translate}}</label>
          <select class="form-control" [compareWith]="SelectUtils.compareObjects" [(ngModel)]="searchModel.callType"
                  id="callType" name="callType" #callType="ngModel">
            <option *ngFor="let item of auditLogCallTypeItems" [ngValue]="item">{{item.text}}</option>
          </select>
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'AUDIT_LOG_SEARCH_METHOD_DEVICE_TYPE' | translate}}</label>
          <select class="form-control" [compareWith]="SelectUtils.compareObjects"
                  [(ngModel)]="searchModel.request_device_type"
                  id="device_id" name="device_id" #device_type="ngModel">
            <option *ngFor="let item of auditLogDeviceTypeItems" [ngValue]="item">{{item.text}}</option>
          </select>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-4">
          <label class="search-label">{{'AUDIT_LOG_SEARCH_REQUEST_RESPONSE' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'AUDIT_LOG_SEARCH_REQUEST_RESPONSE' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.q" name="q" id="q" #q="ngModel">
        </div>
        <div class="col-md-4 d-flex align-items-end">
          <div class="row">
            <app-animated-checkbox class="search-checkbox"
                                   [(selected)]="searchModel.exclude_health_check">
            </app-animated-checkbox>
            <label class="search-label">{{'AUDIT_LOG_SEARCH_EXCLUDE_HEALTH_CHECK' | translate}}</label>
          </div>
        </div>
        <div class="col-md-4 d-flex align-items-end">
          <div class="row">
            <app-animated-checkbox class="search-checkbox"
                                   [(selected)]="searchModel.exclude_su">
            </app-animated-checkbox>
            <label class="search-label">{{'AUDIT_LOG_SEARCH_EXCLUDE_SU' | translate}}</label>
          </div>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-12 d-flex justify-content-md-end align-items-end pr-1 pl-0">
          <div class="btn-group" role="group">
            <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
              {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
            </button>
            <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
              {{'COMMON_BUTTON_SEARCH' | translate}}
            </button>
          </div>
        </div>
      </div>
    </div><!-- /.card-body-->

    <div class="card-body">

      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-field-sorter [orderField]="AuditLogList.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(AuditLogList.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-field-sorter [orderField]="AuditLogList.OrderField.USER_NAME"
                                    [orderType]="queryModel.getOrderType(AuditLogList.OrderField.USER_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'AUDIT_LOG_TABLE_HEADER_USER_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-wxga-down" *ngIf="rightModel.mobileAppRead.hasRight()">
            <app-table-field-sorter [orderField]="AuditLogList.OrderField.APPLICATION_ID"
                                    [orderType]="queryModel.getOrderType(AuditLogList.OrderField.APPLICATION_ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_APP_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header long">
            <app-table-sorter-no-op [text]="'COMMON_METHOD' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header long hidden-lg-down">
            <app-table-sorter-no-op [text]="'AUDIT_LOG_TABLE_HEADER_REQUEST_URI' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op [text]="'COMMON_METHOD_RESULT' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="AuditLogList.OrderField.CREATION_TIME"
                                    [orderType]="queryModel.getOrderType(AuditLogList.OrderField.CREATION_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_CREATION_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-table-actions-single">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let auditLogModel of auditLogModelList | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }">
          <td class="hidden-xs-down">{{ auditLogModel.id }}</td>
          <td class="hidden-md-down">{{ auditLogModel.user_name }}</td>
          <td class="hidden-wxga-down" *ngIf="rightModel.mobileAppRead.hasRight()">{{ auditLogModel.application_id }}</td>
          <td>{{ auditLogModel.request_method }}</td>
          <td class="hidden-lg-down long responsive-table-column text-break">{{ auditLogModel.request_uri }}</td>
          <td>{{ auditLogModel.result }}</td>
          <td>{{ auditLogModel.creation_time | date:'medium'}}</td>
          <td class="w-table-actions-single">
            <span class="text-nowrap">
                <a class="icons-table-group" uiSref="Admin.AuditLogDetail" [uiParams]="{ id: auditLogModel.id }">
                  <i class="icomoon icons-table-item icomoon-detail icomoon-detail-table"
                     title="{{'COMMON_BUTTON_DETAILS' | translate}}"></i>
                </a>
            </span>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-field-sorter [orderField]="AuditLogList.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(AuditLogList.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-field-sorter [orderField]="AuditLogList.OrderField.USER_NAME"
                                    [orderType]="queryModel.getOrderType(AuditLogList.OrderField.USER_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'AUDIT_LOG_TABLE_HEADER_USER_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-wxga-down" *ngIf="rightModel.mobileAppRead.hasRight()">
            <app-table-field-sorter [orderField]="AuditLogList.OrderField.APPLICATION_ID"
                                    [orderType]="queryModel.getOrderType(AuditLogList.OrderField.APPLICATION_ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_APP_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header long">
            <app-table-sorter-no-op [text]="'COMMON_METHOD' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header long hidden-lg-down">
            <app-table-sorter-no-op [text]="'AUDIT_LOG_TABLE_HEADER_REQUEST_URI' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op [text]="'COMMON_METHOD_RESULT' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="AuditLogList.OrderField.CREATION_TIME"
                                    [orderType]="queryModel.getOrderType(AuditLogList.OrderField.CREATION_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_CREATION_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-table-actions-single">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        [hideNumberOfItems]="true"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>

    </div><!-- /.card-body -->
  </div><!-- /.card-->
</div>
