<div class="container-horizontal-padding-half container-vertical-padding-half animated fadeIn">
  <ng-container *ngIf="baseDataModel">
    <!--BASE DATA-->
    <div class="container-horizontal-padding-half container-vertical-padding-half">
      <div class="card">
        <div class="card-header">
          {{'MPL_BASE_DATA' | translate}}
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group row">
                <label class="col-form-label form-control-label col-sm-4 detail-title">{{'MPL_CASH_ON_DELIVERY_PRICE' | translate}}</label>
                <label class="form-control col-sm-8 detail-description">{{baseDataModel.cashOnDeliveryPrice ? baseDataModel.cashOnDeliveryPrice + ' Ft' : ''}}</label>
              </div>
              <div class="form-group row">
                <label class="col-form-label form-control-label col-sm-4 detail-title">{{'MPL_INSURANCE_PRICE' | translate}}</label>
                <label class="form-control col-sm-8 detail-description">{{baseDataModel.insurancePrice ? baseDataModel.insurancePrice + ' Ft' : ''}}</label>
              </div>
              <div class="form-group row">
                <label class="col-form-label form-control-label col-sm-4 detail-title">{{'MPL_MASS' | translate}}</label>
                <label class="form-control col-sm-8 detail-description">{{baseDataModel.massGram ? WeightFactory.createWeightFromGram(baseDataModel.massGram.toNumber()).toString() : ''}}</label>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label class="col-form-label form-control-label col-sm-4 detail-title">{{'MPL_DELIVERY_MODE' | translate}}</label>
                <label class="form-control col-sm-8 detail-description">{{baseDataModel.deliveryMode}}</label>
              </div>
              <div class="form-group row">
                <label class="col-form-label form-control-label col-sm-4 detail-title">{{'MPL_RETENTION_PERIOD' | translate}}</label>
                <label class="form-control col-sm-8 detail-description">{{baseDataModel.retentionPeriodDays}}</label>
              </div>
              <div class="form-group row">
                <label class="col-form-label form-control-label col-sm-4 detail-title">{{'MPL_TRACKING_NUMBER' | translate}}</label>
                <label class="form-control col-sm-8 detail-description">{{baseDataModel.trackingNumber}}</label>
              </div>
              <!--      todo: state        -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--END BASE DATA-->
    <!--MPL LIST-->
    <div class="container-horizontal-padding-half container-vertical-padding-half">
      <div class="card">
        <div class="card-header">
          {{'MPL_LIST' | translate}}
        </div>
        <div class="card-body">
          <table class="table table-striped table-bordered mb-0">
            <thead>
            <tr>
              <th class="table-sorter-header">
                <app-table-field-sorter [orderField]="MplTracking.OrderFunctions.TIME"
                                        [orderType]="queryModel.getOrderType(MplTracking.OrderFunctions.TIME)"
                                        (orderChange)="orderBy($event)"
                                        [text]="'MPL_TIME' | translate"></app-table-field-sorter>
              </th>
              <th class="table-sorter-header">
                <app-table-sorter-no-op [text]="'MPL_CATEGORY' | translate"></app-table-sorter-no-op>
              </th>
              <th class="table-sorter-header hidden-sm-down">
                <app-table-sorter-no-op [text]="'MPL_DESCRIPTION' | translate"></app-table-sorter-no-op>
              </th>
              <th class="table-sorter-header hidden-1200-down">
                <app-table-sorter-no-op [text]="'MPL_POST_OFFICE_NAME' | translate"></app-table-sorter-no-op>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of trackingData">
              <td class="responsive-table-column">{{item.time.toUtcIsoString() | date: 'short'}}</td>
              <td class="responsive-table-column">{{item.category}}</td>
              <td class="responsive-table-column hidden-sm-down">{{item.description}}</td>
              <td class="responsive-table-column hidden-1200-down">{{item.postOfficeName}}</td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
              <th class="table-sorter-header">
                <app-table-field-sorter [orderField]="MplTracking.OrderFunctions.TIME"
                                        [orderType]="queryModel.getOrderType(MplTracking.OrderFunctions.TIME)"
                                        (orderChange)="orderBy($event)"
                                        [text]="'MPL_TIME' | translate"></app-table-field-sorter>
              </th>
              <th class="table-sorter-header">
                <app-table-sorter-no-op [text]="'MPL_CATEGORY' | translate"></app-table-sorter-no-op>
              </th>
              <th class="table-sorter-header hidden-sm-down">
                <app-table-sorter-no-op [text]="'MPL_DESCRIPTION' | translate"></app-table-sorter-no-op>
              </th>
              <th class="table-sorter-header hidden-1200-down">
                <app-table-sorter-no-op [text]="'MPL_POST_OFFICE_NAME' | translate"></app-table-sorter-no-op>
              </th>
            </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
    <!--END MPL LIST-->
  </ng-container>
  <div class="p-2 text-center text-muted" *ngIf="!baseDataModel">
    <h6>{{ "MPL_EMPTY_STATISTICS" | translate }}</h6>
  </div>
</div>
