/* eslint-disable */
import { Component, OnInit, ViewChild, } from '@angular/core';
import { Transition, UIRouter } from '@uirouter/angular';
import { ImmutableUserGroupItem, MultiselectOptionItem, OwnerUserItem, UiConstants } from '../../../util/core-utils';
import { StateName } from '../../../app.state-names';
import { InputMask } from '../../../util/input-masks';
import { CustomerCreateModel, CustomerOwnerTypeItem, CustomerTypeItem } from '../../../util/customer-utils';
import { Customer, CustomerService } from '../../../lib/customer/customer.service';
import { FieldValidationError, ResourceQueryResult, Services } from '../../../lib/util/services';
import { NgForm } from '@angular/forms';
import { LoadingHandler } from '../../../lib/loading-handler';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { TranslateUtils } from '../../../util/translate';
import { UserGroup, UserGroupService } from '../../../lib/user-group.service';
import { Angular2Multiselects } from '../../../util/multiselect';
import { Set } from 'immutable';
import { Strings } from '../../../lib/util/strings';
import { ConfigurationService } from '../../../lib/core-ext/configuration.service';
import { CustomerRecordField, CustomerRecordFieldType } from '../../../util/customer-record-utils';
import { Models } from '../../../util/model-utils';
import { Numbers } from '../../../lib/util/numbers';
import { StringKey } from '../../../app.string-keys';
import { ToasterService } from '../../../fork/angular2-toaster/src/toaster.service';
import { UserGroupMultiselectProvider } from '../../../lib/user-group/user-group-multiselect.provider';
import { RightModel } from '../../../app.rights';
import { RightResolver, RightService } from '../../../lib/right.service';
import { LedgerNumberMultiselectProvider } from '../../../lib/ledger/number/ledger-number-multiselect.provider';
import { UserMultiselectProvider } from '../../../lib/user/user-multiselect.provider';

/* eslint-enable */

@Component({
  selector: 'app-customer-create',
  templateUrl: 'customer-create.component.html',
  styleUrls: ['customer-create.component.scss']
})
export class CustomerCreateComponent implements OnInit {

  InputMask = InputMask;
  Customer = Customer;
  UiConstants = UiConstants;

  @ViewChild('f', {static: true})
  fForm: NgForm;

  ordinalNumberMask = [/[1-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

  model: CustomerCreateModel;
  ownerUsers: MultiselectOptionItem<number>[] = [];
  customerTypes: CustomerTypeItem[] = [];
  mobileOwnerTypes: CustomerOwnerTypeItem[] = [];
  webOwnerTypes: MultiselectOptionItem<Customer.CustomerOwnerType>[] = [];
  defaultManagedFields: CustomerRecordFieldType[] = [];
  manageablePersonFields: MultiselectOptionItem<CustomerRecordFieldType>[] = [];
  manageableCompanyFields: MultiselectOptionItem<CustomerRecordFieldType>[] = [];
  userGroups: MultiselectOptionItem<number>[] = [];
  ledgerNumberList: MultiselectOptionItem<number>[] = [];

  rightModel: RightModel = RightModel.empty();

  get manageableFields(): MultiselectOptionItem<CustomerRecordFieldType>[] {
    if (!this.model.getCustomerType()) {
      return [];
    }
    if (this.model.getCustomerType() === 'PERSON') {
      return this.manageablePersonFields;
    }
    return this.manageableCompanyFields;
  }

  requirablePersonFields: MultiselectOptionItem<CustomerRecordFieldType>[] = [];
  requirableCompanyFields: MultiselectOptionItem<CustomerRecordFieldType>[] = [];

  get requirableFields(): MultiselectOptionItem<CustomerRecordFieldType>[] {
    if (this.model.useDefaultManagedFields) {
      if (this.model.getCustomerType() === 'PERSON') {
        return this.requirablePersonFields.filter(f => this.defaultManagedFields.includes(f.id));
      }
      else {
        return this.requirableCompanyFields.filter(f => this.defaultManagedFields.includes(f.id));
      }
    }
    else {
      if (this.model.getCustomerType() === 'PERSON') {
        return this.requirablePersonFields.filter(f => this.model.managedFields.map(f => f.id).includes(f.id));
      }
      else {
        return this.requirableCompanyFields.filter(f => this.model.managedFields.map(f => f.id).includes(f.id));
      }
    }
  }

  fieldErrors: FieldValidationError<Customer.ValidatedField>;

  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');


  userGroupDropdownSettings: Angular2Multiselects.Settings;
  ledgerNumberDropdownSettings: Angular2Multiselects.Settings;
  fieldsDropdownSettings: Angular2Multiselects.Settings;

  constructor(private customerService: CustomerService,
              private uiRouter: UIRouter,
              private toasterService: ToasterService,
              private transition: Transition,
              private translateService: TranslateService,
              private configService: ConfigurationService,
              private rightService: RightService,
              private userGroupMultiselectProvider: UserGroupMultiselectProvider,
              private userMultiselectProvider: UserMultiselectProvider,
              private ledgerNumberMultiselectProvider: LedgerNumberMultiselectProvider) {
    this.model = new CustomerCreateModel();
    this.fieldErrors = FieldValidationError.empty<Customer.ValidatedField>();
    this.loadCustomerTypes();
    this.loadCustomerOwnerTypes();
    this.loadUserGroups();
    this.loadUsers();
    this.loadCustomerRecordFields();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  ngOnInit() {
    this.translateService.get('CUSTOMER_PANEL_HEADING_ADD').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_CUSTOMER_TYPES').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.CUSTOMER_LIST});
      }
    );
    this.initDropdown();
    this.loadRightModels();
  }

  initDropdown() {
    this.userGroupDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .remoteSearch(true)
      .build();
    this.fieldsDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .translate(true)
      .build();
    this.ledgerNumberDropdownSettings = Angular2Multiselects.REMOTE_SINGLE_SELECT;
  }

  loadCustomerTypes() {
    const keys: string[] = [];
    Customer.customerTypes.forEach((type) => {
      this.customerTypes.push({id: type.type, text: '', key: type.stringKey});
      keys.push(type.stringKey);
    });
    this.translateService.get(keys).subscribe((texts) => {
      this.customerTypes.forEach((type) => {
        type.text = TranslateUtils.extractValueFromObject(texts, type.key);
      });
      this.model.type = this.customerTypes[0];
    });
  }

  loadCustomerOwnerTypes() {
    const keys: string[] = [];
    this.mobileOwnerTypes = [];
    this.webOwnerTypes = [];
    Customer.customerMobileOwnerTypes.forEach((type) => {
      this.mobileOwnerTypes.push({id: type.type, text: '', key: type.stringKey});
      keys.push(type.stringKey);
    });
    this.translateService.get(keys).subscribe((texts) => {
      this.mobileOwnerTypes.forEach((type) => {
        type.text = TranslateUtils.extractValueFromObject(texts, type.key);
      });
      this.model.mobileOwnerType = this.mobileOwnerTypes[0];
    });
    Customer.customerWebOwnerTypes.forEach((type) => {
      this.webOwnerTypes.push({id: type.type, itemName: type.stringKey});
    });
  }

  private loadCustomerRecordFields() {
    this.defaultManagedFields
      = this.configService.getConfiguration().feature_flags.customer_record_managed_fields.map(f => <CustomerRecordFieldType>f);
    CustomerRecordField.getManageablePersonFields().forEach(f => {
      if (this.defaultManagedFields.includes(f.type)) {
        this.manageablePersonFields.push({
          id: f.type,
          itemName: f.stringKey
        });
      }
    });
    CustomerRecordField.getManageableCompanyFields().forEach(f => {
      if (this.defaultManagedFields.includes(f.type)) {
        this.manageableCompanyFields.push({
          id: f.type,
          itemName: f.stringKey
        });
      }
    });
    CustomerRecordField.getRequirablePersonFields().forEach(f => {
      this.requirablePersonFields.push({
        id: f.type,
        itemName: f.stringKey
      });
    });
    CustomerRecordField.getRequirableCompanyFields().forEach(f => {
      this.requirableCompanyFields.push({
        id: f.type,
        itemName: f.stringKey
      });
    });
  }

  loadUserGroups(q?: string) {
    this.userGroupMultiselectProvider.loadActive(q)
      .subscribe((result) => {
        this.userGroups = result;
      });
  }

  loadUsers(q?: string) {
    this.userMultiselectProvider.loadActive(q)
      .subscribe((result) => {
        this.ownerUsers = result;
      });
  }

  loadLedgerNumbers(q?: string) {
    if (this.rightModel.ledgerNumberRead.hasRight()) {
      this.ledgerNumberMultiselectProvider.loadActive(q)
        .subscribe((result) => {
          this.ledgerNumberList = result;
        });
    }
  }

  createCustomer() {
    if (this.model.type && this.model.type.id
      && this.model.mobileOwnerType && this.model.mobileOwnerType.id) {
      this.customerService.create({
        name: this.model.name,
        complexName: this.model.type.id === 'COMPANY' ? false : this.model.complexName,
        description: this.model.description,
        externalId: this.model.externalId,
        defaultLedgerNumber: this.model.defaultLedgerNumberId,
        type: this.model.type.id,
        icon: this.model.icon,
        mobileOwnerType: this.model.mobileOwnerType.id,
        webOwnerTypes: this.model.mapWebOwnerTypes(),
        ownerUserGroupIds: this.model.getUserGroupIds(),
        useDefaultManagedFields: this.model.useDefaultManagedFields,
        explicitOrderNumber: Models.parseNumber(Numbers.undefinedOrNonEmpty(this.model.explicitOrderNumber)),
        managedFields: this.model.useDefaultManagedFields ? undefined : Set.of(...this.model.managedFields.map(f => f.id)),
        requiredFields: Set.of(...this.model.requiredFields.map(f => f.id)),
        contactPerson: this.model.type.id === 'COMPANY' ? false : this.model.contactPerson
      }).subscribe(
        (response: Customer.Customer) => {
          LoadingHandler.getInstance().refresh();
          this.uiRouter.stateService.go(StateName.CUSTOMER_EDIT, {id: response.customerId});
        },
        (error: any) => {
          if (error instanceof FieldValidationError) {
            this.fieldErrors = error.withForm(this.fForm);
            this.showValidationErrorToast();
          }
        }
      );
    }
    else {
      this.showValidationErrorToast();
    }
  }

  private showValidationErrorToast() {
    this.toasterService.pop({
      timeout: UiConstants.ToastTimeoutLong,
      type: UiConstants.toastTypeError,
      title: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_TITLE),
      body: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_MESSAGE)
    });
  }

  hasFieldError(field: Customer.ValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: Customer.ValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: Customer.ValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  back() {
    window.history.back();
  }

  onCustomerTypeChanged() {
    this.removeFieldError(Customer.ValidatedField.TYPE);
    this.model.managedFields = [];
  }

  onManagedFieldsChanged() {
    this.model.requiredFields = this.model.requiredFields.filter(f => this.model.managedFields.map(f => f.id).includes(f.id));
  }

}
