import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';

@Injectable()
export class SidebarTogglerService {

  private _subject: Subject<boolean> = new BehaviorSubject<boolean>(true);

  constructor() {
  }

  subscribe(next?: (value: boolean) => void, error?: (error: any) => void, complete?: () => void): Subscription {
    return this._subject.subscribe(next, error, complete);
  }

  unsubscribe(subscription: Subscription) {
    subscription.unsubscribe();
  }

  toggle() {
    const bodyElement = document.querySelector('body');
    const headerElement = document.querySelector('header');
    const breadcrumbElement = document.getElementById('breadcrumb');
    const formEditorCardContainerElement = document.getElementById('form-editor-card-container');
    const logo = document.getElementById('navbar-brand');
    const brandLogo = document.getElementById('navbar-asset-brand');
    if (bodyElement) {
      bodyElement.classList.toggle('sidebar-compact');
      headerElement!.classList.toggle('app-header-compact');
      if (breadcrumbElement) {
        if (bodyElement.classList.contains('sidebar-compact')) {
          breadcrumbElement.classList.add('breadcrumb-container-compact');
        } else {
          breadcrumbElement.classList.remove('breadcrumb-container-compact');
        }
      }
      if (formEditorCardContainerElement) {
        if (bodyElement.classList.contains('sidebar-compact')) {
          formEditorCardContainerElement.classList.add('form-editor-card-container-compact');
        } else {
          formEditorCardContainerElement.classList.remove('form-editor-card-container-compact');
        }
      }
      if (logo) {
        logo!.classList.toggle('navbar-brand-small');
      }
      if (brandLogo) {
        brandLogo!.classList.toggle('navbar-brand-small');
      }
      this._subject.next(!bodyElement.classList.contains('sidebar-compact'));
    }
  }

  get observable(): Observable<boolean> {
    return this._subject;
  }
}
