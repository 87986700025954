<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-custom-padding-outer animated fadeIn">
  <div class="row ml-0 mr-0">
    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12 card-custom-padding-complete"
         *ngFor="let item of list">
      <div class="card card-top-color cursor-pointer"
           uiSref="{{destination}}"
           [uiParams]="{ id: item.getId() }">
        <div class="card-body">
          <div class="h4 mb-0">{{item.getCount()}}</div>
          <small class="text-uppercase font-weight-bold">{{item.getTitle()}}</small>
          <br>
          <small class="text-muted">{{item.getId()}}</small>
        </div>
      </div>
    </div>
  </div>
</div>
