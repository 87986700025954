<div class="container-vertical-padding-half">

  <div class="card col-xs-12 col-sm-12 col-md-8 p-0">

    <div class="card-header">
      {{headingDictionaryKey | translate}}
    </div>

    <div class="card-body" [formGroup]="formGroup">

      <!-- region Name -->
      <div class="form-group row mb-0" [ngStyle]="fieldStyle"
           [ngClass]="{ 'has-danger': hasFieldError(MasterDataRecord.ValidatedField.NAME) || hasLocalFieldError('name') }">
        <label class="col-form-label form-control-label col-md-4 detail-title" [class.required-field-label]="!readonly && requiredId === 'NAME'">
          {{'COMMON_NAME' | translate}}
        </label>
        <div class="col-md-8">
          <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 [readonly]="readonly" formControlName="name"
                 trim="blur" validateOnBlur [validateFormControl]="formGroup.controls['name']"
                 [ngClass]="{ 'form-control-danger': hasFieldError(MasterDataRecord.ValidatedField.NAME) || hasLocalFieldError('name'), 'detail-description' : readonly }"
                 [(ngModel)]="model.name" (ngModelChange)="removeFieldError(MasterDataRecord.ValidatedField.NAME)">
          <div class="form-control-feedback"
               *ngIf="hasFieldError(MasterDataRecord.ValidatedField.NAME)">{{getFieldErrorText(MasterDataRecord.ValidatedField.NAME)}}</div>
          <div class="form-control-feedback" *ngIf="hasLocalFieldError('name')">
            <span
              *ngIf="hasLocalFieldError('name', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
          </div>
        </div>
      </div>
      <!-- endregion Name -->

      <!-- region External ID -->
      <div class="form-group row mb-0" [ngStyle]="fieldStyle"
           [ngClass]="{ 'has-danger': hasFieldError(MasterDataRecord.ValidatedField.EXTERNAL_ID) || hasLocalFieldError('externalId') }">
        <label class="col-form-label form-control-label col-md-4 detail-title" [class.required-field-label]="!readonly && (externalIdRequired === true || requiredId === 'EXTERNAL_ID')">
          {{'MASTERDATA_RECORD_EXTERNAL_ID' | translate}}
        </label>
        <div class="col-md-8">
          <input type="text" class="form-control" placeholder="{{'MASTERDATA_RECORD_EXTERNAL_ID' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 [readonly]="readonly" formControlName="externalId"
                 trim="blur" validateOnBlur [validateFormControl]="formGroup.controls['externalId']"
                 [ngClass]="{ 'form-control-danger': hasFieldError(MasterDataRecord.ValidatedField.EXTERNAL_ID) || hasLocalFieldError('externalId'), 'detail-description' : readonly }"
                 [(ngModel)]="model.externalId"
                 (ngModelChange)="removeFieldError(MasterDataRecord.ValidatedField.EXTERNAL_ID)">
          <div class="form-control-feedback"
               *ngIf="hasFieldError(MasterDataRecord.ValidatedField.EXTERNAL_ID)">{{getFieldErrorText(MasterDataRecord.ValidatedField.EXTERNAL_ID)}}</div>
          <div class="form-control-feedback" *ngIf="hasLocalFieldError('externalId')">
            <span
              *ngIf="hasLocalFieldError('externalId', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
          </div>
        </div>
      </div>
      <!-- endregion External ID -->

      <!-- region Description -->
      <div class="form-group row mb-0" [ngStyle]="fieldStyle">
        <label
          class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_DESCRIPTION' | translate}}</label>
        <div class="col-md-8">
          <input type="text" class="form-control" placeholder="{{'COMMON_DESCRIPTION' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 [readonly]="readonly"
                 [ngClass]="{ 'detail-description' : readonly }"
                 [(ngModel)]="model.description"
                 [ngModelOptions]="{standalone: true}">
        </div>
      </div>
      <!-- endregion Description --><!-- region Users -->
      <div [ngStyle]="readonly ? '' : fieldStyle" [ngClass]="{ 'has-danger': hasLocalFieldError('user') }">
        <div class="form-group row mb-0">
          <label
            class="col-form-label form-control-label col-md-4 detail-title">{{'CUSTOMER_RECORD_USERS'|translate}}</label>
          <div class="col-md-8">
            <angular2-multiselect
              formControlName="user"
              [(ngModel)]="model.users"
              [data]="model.userItems"
              [settings]="userDropdownSettings"
              [readonly]="readonly"
              (onRemoteSearch)="loadUserSearch($event.target.value)">
            </angular2-multiselect>
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('user', 'hasDisabledItem')">
              <label>{{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}</label>
            </div>
          </div>
        </div>
      </div>
      <!-- endregion Users -->

      <!-- region User groups -->
      <div [ngStyle]="readonly ? '' : fieldStyle" [ngClass]="{ 'has-danger': hasLocalFieldError('userGroup') }">
        <div class="form-group row mb-0">
          <label
            class="col-form-label form-control-label col-md-4 detail-title">{{'CUSTOMER_RECORD_USER_GROUPS'|translate}}</label>
          <div class="col-md-8">
            <angular2-multiselect
              formControlName="userGroup"
              [(ngModel)]="model.userGroups"
              [data]="model.userGroupItems"
              [settings]="userGroupDropdownSettings"
              [readonly]="readonly"
              (onRemoteSearch)="loadUserGroupItems(undefined, $event.target.value)">
            </angular2-multiselect>
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('userGroup', 'hasDisabledItem')">
              <label>{{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}</label>
            </div>
          </div>
        </div>
      </div>
      <!-- endregion User groups -->

      <!-- region OwnerCustomerRecord -->
      <div class="form-group row mb-0" [ngStyle]="fieldStyle" [ngClass]="{ 'has-danger': hasLocalFieldError('ownerCustomer')}">
        <label
          class="col-form-label form-control-label col-md-4 detail-title">{{'MASTERDATA_RECORD_OWNER_CUSTOMER_RECORD' | translate}}</label>
        <div class="col-md-8">
          <angular2-multiselect
            (onRemoteSearch)="onCustomerRecordSearch($event.target.value)"
            [data]="ownerCustomerItems"
            [ngClass]="{ 'form-control-danger': hasLocalFieldError('ownerCustomer')}"
            [settings]="dropdownSettings"
            class="form-control"
            [readonly]="readonly"
            formControlName="ownerCustomer"
            (onReadonlyItemClick)="navigateToCustomerRecord($event)"
            [(ngModel)]="model._ownerCustomerRecord"
            (ngModelChange)="onOwnerCustomerRecordChanged()">
          </angular2-multiselect>
          <div class="form-control-feedback" *ngIf="hasLocalFieldError('ownerCustomer')">
            <span
              *ngIf="hasLocalFieldError('ownerCustomer', 'hasDisabledItem')">{{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}</span>
          </div>
        </div>
      </div>
      <!-- endregion OwnerCustomerRecord -->

      <!-- region OwnerContactLocation -->
      <div *ngIf="model.ownerCustomerRecord"
           class="form-group row mb-0" [ngStyle]="fieldStyle" [ngClass]="{ 'has-danger': hasLocalFieldError('ownerContactLocation')}">
        <label
          class="col-form-label form-control-label col-md-4 detail-title">{{'COMPANY_LOCATION' | translate}}</label>
        <div class="col-md-8">
          <angular2-multiselect
            (onRemoteSearch)="onOwnerContactLocationSearch($event.target.value)"
            [data]="ownerContactLocations"
            [ngClass]="{ 'form-control-danger': hasLocalFieldError('ownerContactLocation')}"
            [settings]="dropdownSettings"
            class="form-control"
            formControlName="ownerContactLocation"
            [(ngModel)]="model._ownerContactLocation"
            [readonly]="readonly">
          </angular2-multiselect>
        </div>
      </div>
      <!-- endregion OwnerContactLocation -->

    </div>

    <input type="submit" class="btn btn-primary-outline floating_apply_button" *ngIf="!readonly"
           (click)="onSaveButtonClicked(false)" value="{{'COMMON_BUTTON_APPLY'|translate}}"/>
    <input type="submit" class="btn btn-primary floating_save_button" *ngIf="!readonly"
           (click)="onSaveButtonClicked(true)" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>

  </div>

</div>
