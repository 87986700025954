import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColorEvent } from 'ngx-color';
import { lighten } from '@wessberg/color';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'palette-color-picker',
  templateUrl: './palette-color-picker.component.html',
  styleUrls: ['./palette-color-picker.component.scss']
})
export class PaletteColorPickerComponent implements OnInit {

  static readonly colors: string[] = [
    '#00727b',
    '#f44336',
    '#e91e63',
    '#9c27b0',
    '#673ab7',
    '#3f51b5',
    '#2196f3',
    '#03a9f4',
    '#00bcd4',
    '#009688',
    '#4caf50',
    '#73a03c',
    '#a5b12d',
    '#ffc107',
    '#ff9800',
    '#ff5722',
    '#795548',
    '#607d8b'
  ];

  get colors(): string[] {
    return PaletteColorPickerComponent.colors;
  }

  @Input()
  readonly: boolean = false;

  @Input()
  set color(index: number) {
    this._color = PaletteColorPickerComponent.colors[index];
  }

  @Output()
  colorChange = new EventEmitter<number>();

  _color: string = '#fff';

  public static resolveColor(index: number): string {
    return this.colors[index];
  }

  constructor() {
  }

  ngOnInit() {
  }

  onColorChanged(event: ColorEvent) {
    this._color = event.color.hex;
    this.colorChange.emit(PaletteColorPickerComponent.colors.indexOf(this._color));
  }


  static getListColorOdd(index: number): string {
    const color = PaletteColorPickerComponent.colors[index];
    return lighten(color, 40);
  }

  static getListColorEven(index: number): string {
    const color = PaletteColorPickerComponent.colors[index];
    return lighten(color, 30);
  }
}
