<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div class="breadcrumb-menu d-flex">
  </div>
</div>

<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="card">

    <div class="card-header">
      {{'MENU_NAVBAR_TASK_RECORD_BOARD' | translate}}

      <div class="card-actions">
      </div><!-- /.card-actions -->
    </div>
    <div class="card-body">

      <div class="board">
        <div class="board-wrapper">
          <div class="board-columns">

            <div cdkDropList
                 cdkDropListOrientation="horizontal" class="board-columns">
              <div
                class="board-column"
                *ngFor="let column of board.columns">
                <div class="column-title" cdkDragHandle>
                  {{ column.stringKey | translate }}
                </div>
                <div class="tasks-container"
                     id="{{column.id}}"
                     [cdkDropListConnectedTo]="getAvailableColumnIds(column.id)"
                     cdkDropList
                     cdkDropListSortingDisabled
                     [cdkDropListData]="column.items"
                     (cdkDropListDropped)="drop($event)">
                  <app-task-record-board-card *ngFor="let item of column.items" cdkDrag [model]="item">
                    <div class="custom-placeholder" *cdkDragPlaceholder></div>
                  </app-task-record-board-card>
                </div>
                <button *ngIf="column.canLoadMore()"  class="btn btn-outline-primary" (click)="column.loadMore()">+{{'TASK_RECORD_BOARD_SHOW_MORE' | translate}}</button>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  </div>
</div>
