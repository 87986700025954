/* eslint-disable */
import { Form } from '../../../../../lib/form/form.service';
import { FieldActivationStrategy } from '../form-record-inactivity-manager-api';
import { NoOpFieldActivationStrategy } from './no-op-field-activation-strategy';
import { ListMultiItemFieldActivationStrategy } from './list-multi-item-field-activation-strategy';
import { ListItemFieldActivationStrategy } from './list-item-field-activation-strategy';
import { BooleanFieldActivationStrategy } from './boolean-field-activation-strategy';
import { Injectable } from '@angular/core';

/* eslint-enable */

@Injectable()
export class FieldActivationStrategyFactory {

  constructor(
    private noOpFieldActivationStrategy: NoOpFieldActivationStrategy,
    private booleanFieldActivationStrategy: BooleanFieldActivationStrategy,
    private listItemFieldActivationStrategy: ListItemFieldActivationStrategy,
    private listMultiItemFieldActivationStrategy: ListMultiItemFieldActivationStrategy) {
  }

  getStrategy(selector: Form.FieldDataTypeSelector): FieldActivationStrategy {
    switch (selector) {
      case Form.FieldDataTypeSelector.BOOLEAN:
        return this.booleanFieldActivationStrategy;
      case Form.FieldDataTypeSelector.LIST_ITEM:
        return this.listItemFieldActivationStrategy;
      case Form.FieldDataTypeSelector.LIST_MULTI_ITEM:
        return this.listMultiItemFieldActivationStrategy;
      default:
        return this.noOpFieldActivationStrategy;
    }
  }

}
