/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { Order, PagingRequest, QueryResult, ResourceQueryResult, Services } from '../../util/services';
import { OffsetDateTime } from '../../util/dates';
import { List, Map, Set } from 'immutable';
import { TaskLogResource, TaskLogResourceService } from './task-log-resource.service';
import LogType = TaskLog.LogType;
/* eslint-enable */

@Injectable()
export class TaskLogService implements TaskLog.Service {

  query(request: TaskLog.QueryRequest): Observable<QueryResult<TaskLog.TaskLog>> {
    return Observable.create((observer: Observer<QueryResult<TaskLog.TaskLog>>) => {
      const resourceRequest: TaskLogResource.QueryRequest = {
        id: Services.createIdParameter(request.taskLogIdSet),
        task_type_name: request.taskTypeName,
        task_name: request.taskName,
        log_type: request.logType,
        log_time_from: request.logTimeFrom,
        log_time_to: request.logTimeTo,
        user_name: request.userName,
        message: request.message,
        helpdesk_enabled: request.helpdesk,
        application_type_id: request.applicationTypeId,
        q: request.q,
        order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.order),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
      };
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<TaskLogResource.TaskLog>) => {
          observer.next({
            items: this.toPublicList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  constructor(private resourceService: TaskLogResourceService) {}

  private toPublicList(resourceList: TaskLogResource.TaskLog[]): List<TaskLog.TaskLog> {
    return List.of(...resourceList.map((r) => this.toPublic(r)));
  }

  private toPublic(r: TaskLogResource.TaskLog): TaskLog.TaskLog {
    return {
      id: r.id,
      taskId: r.task_id,
      taskRecordId: r.taskrecord_id,
      logType: <LogType> r.log_type,
      logTime: Services.toOffsetDateTime(r.log_time),
      userProfileId: r.user_profile_id,
      message: r.message,
      applicationTypeId: r.application_type_id
    };
  }

}

export namespace TaskLog {

  export interface Service {

    query(request: QueryRequest): Observable<QueryResult<TaskLog>>;

  }

  export interface QueryRequest {
    taskLogIdSet?: Set<number>;
    taskTypeName?: string;
    taskName?: string;
    logType?: string;
    logTimeFrom?: string;
    logTimeTo?: string;
    userName?: string;
    message?: string;
    helpdesk?: boolean;
    applicationTypeId?: string;
    q?: string,
    order?: Set<Order<OrderField>>;
    paging?: PagingRequest;
    number_of_items?: number;
  }

  export interface TaskLog {
    id: number;
    taskId: number;
    taskRecordId: number;
    taskRecordName?: string;
    logType: LogType;
    logTime: OffsetDateTime;
    userProfileId?: number;
    userName?: string;
    message?: string;
    applicationTypeId?: number;
    deviceType?: string;
  }

  export type LogType =
    'CREATE_QUICK' |
    'CREATE_REGULAR' |
    'CREATE_IMPORT' |
    'STATE_OPEN' |
    'STATE_ARCHIVE' |
    'STATE_RECALL' |
    'STATE_REJECT' |
    'STATE_START' |
    'STATE_SUBMIT' |
    'STATE_UNSUBMIT' |
    'STATE_FINISH' |
    'STATE_VALIDATE' |
    'STATE_INVALIDATE' |
    'STATE_RESTART' |
    'ATTACHMENT_CREATE' |
    'ATTACHMENT_DELETE';

  export const LogTypeMap: Map<TaskLog.LogType, string> = Map.of(
    'CREATE_QUICK', 'TASK_RECORD_LOG_TYPE_CREATE_QUICK',
    'CREATE_REGULAR', 'TASK_RECORD_LOG_TYPE_CREATE_REGULAR',
    'CREATE_IMPORT', 'TASK_RECORD_LOG_TYPE_CREATE_IMPORT',
    'STATE_OPEN', 'TASK_RECORD_LOG_TYPE_STATE_OPEN',
    'STATE_REOPEN', 'TASK_RECORD_LOG_TYPE_STATE_REOPEN',
    'STATE_ARCHIVE', 'TASK_RECORD_LOG_TYPE_STATE_ARCHIVE',
    'STATE_RECALL', 'TASK_RECORD_LOG_TYPE_STATE_RECALL',
    'STATE_REJECT', 'TASK_RECORD_LOG_TYPE_STATE_REJECT',
    'STATE_START', 'TASK_RECORD_LOG_TYPE_STATE_START',
    'STATE_SUBMIT', 'TASK_RECORD_LOG_TYPE_STATE_SUBMIT',
    'STATE_UNSUBMIT', 'TASK_RECORD_LOG_TYPE_STATE_UNSUBMIT',
    'STATE_FINISH', 'TASK_RECORD_LOG_TYPE_STATE_FINISH',
    'STATE_VALIDATE', 'TASK_RECORD_LOG_TYPE_STATE_VALIDATE',
    'STATE_INVALIDATE', 'TASK_RECORD_LOG_TYPE_STATE_INVALIDATE',
    'STATE_RESTART', 'TASK_RECORD_LOG_TYPE_STATE_RESTART',
    'ATTACHMENT_CREATE', 'TASK_RECORD_LOG_TYPE_ATTACHMENT_CREATE',
    'ATTACHMENT_DELETE', 'TASK_RECORD_LOG_TYPE_ATTACHMENT_DELETE',
    'EXPORT_FAILED', 'TASK_RECORD_LOG_TYPE_EXPORT_FAILED',
    'EXPORT_SUCCEED', 'TASK_RECORD_LOG_TYPE_EXPORTED',
    'REVERT_FAILED', 'TASK_RECORD_LOG_TYPE_REVERT_FAILED',
    'REVERT_SUCCEED', 'TASK_RECORD_LOG_TYPE_REVERTED'
  );

  export enum OrderField {
    USER_NAME,
    DEVICE,
    TASK_ID,
    TASK_TYPE_NAME,
    TASK_NAME,
    LOG_TYPE,
    MESSAGE,
    LOG_TIME
  }

}

class Keys {

  private static readonly USER_NAME = 'user_name';
  private static readonly DEVICE = 'application_type_id';
  private static readonly TASK_ID = 'task_id';
  private static readonly TASK_TYPE_NAME = 'task_type_name';
  private static readonly TASK_NAME = 'task_name';
  private static readonly LOG_TYPE = 'log_type';
  private static readonly MESSAGE = 'message';
  private static readonly LOG_TIME = 'log_time';

  private static readonly orderFieldKeyMap: Map<TaskLog.OrderField, string> = Map.of(
    TaskLog.OrderField.USER_NAME, Keys.USER_NAME,
    TaskLog.OrderField.DEVICE, Keys.DEVICE,
    TaskLog.OrderField.TASK_ID, Keys.TASK_ID,
    TaskLog.OrderField.TASK_TYPE_NAME, Keys.TASK_TYPE_NAME,
    TaskLog.OrderField.TASK_NAME, Keys.TASK_NAME,
    TaskLog.OrderField.LOG_TYPE, Keys.LOG_TYPE,
    TaskLog.OrderField.MESSAGE, Keys.MESSAGE,
    TaskLog.OrderField.LOG_TIME, Keys.LOG_TIME,
  )

  public static toOrderFieldKey(field: TaskLog.OrderField): string {
    return Keys.orderFieldKeyMap.get(field)!;
  }

}
