<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]=breadcrumbParents
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding-half animated fadeIn">
  <form class="form-horizontal bordered-row" (ngSubmit)="createSurveyRecord()" #f="ngForm" novalidate>
    <app-survey-record-base
      headingDictionaryKey="SURVEY_RECORD_PANEL_HEADING_CREATE"
      [readonly]="false"
      [allowClone]="false"
      [allowExport]="false"
      [form]="f"></app-survey-record-base>
    <app-form-record-container
      [readonly]="false"
      [cloning]="false"
      [form]="f"></app-form-record-container>
  </form>
</div>
