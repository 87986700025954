/* eslint-disable */
import { Injectable } from '@angular/core';
import { HumanizeDuration, HumanizeDurationLanguage } from 'humanize-duration-ts';
import { SettingsService } from '../lib/settings.service';
/* eslint-enable */

@Injectable()
export class DurationHumanizerService {

  private langService: HumanizeDurationLanguage;
  private humanizer: HumanizeDuration;

  constructor(private settings: SettingsService) {
    this.langService = new HumanizeDurationLanguage();
    this.humanizer = new HumanizeDuration(this.langService);
    this.humanizer.setOptions({language: this.settings.getLanguageCode(), round: true, units: ['y', 'mo', 'w', 'd', 'h', 'm']});

  }

  localizedHumanize(millisec: number) {
    return (millisec < 0 ? '-' : '') + this.humanizer.humanize(millisec);
  }
}
