<div class="container-vertical-padding-half">

  <div class="card col-xs-12 col-sm-12 col-md-8 p-0">

    <div class="card-header">
      {{headingDictionaryKey | translate}}
    </div>

    <div class="card-body" [formGroup]="formGroup">

      <!-- region Name -->
      <div *ngIf="!readonly || (readonly && model.name && model.name !== '')"
           class="form-group row mb-0" [ngStyle]="fieldStyle" [ngClass]="{ 'has-danger': hasFieldError(ProcessTask.ValidatedField.NAME) }">
        <label class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_NAME' | translate}}</label>
        <div class="col-md-8">
          <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
                 [readonly]="readonly" formControlName="name"
                 trim="blur" validateOnBlur [validateFormControl]="formGroup.controls['name']"
                 [ngClass]="{ 'form-control-danger': hasFieldError(ProcessTask.ValidatedField.NAME), 'detail-description' : readonly }"
                 [(ngModel)]="model.name" (ngModelChange)="removeFieldError(ProcessTask.ValidatedField.NAME)">
          <div class="form-control-feedback" *ngIf="hasFieldError(ProcessTask.ValidatedField.NAME)">{{getFieldErrorText(ProcessTask.ValidatedField.NAME)}}</div>
        </div>
      </div>
      <!-- endregion Name -->

      <!-- region External ID -->
      <div class="form-group row mb-0" [ngStyle]="fieldStyle" [ngClass]="{ 'has-danger': hasFieldError(ProcessTask.ValidatedField.EXTERNAL_ID) || hasLocalFieldError('externalId') }">
        <label class="col-form-label form-control-label col-md-4 detail-title"
               [class.required-field-label]="getExternalIdRequirement()">
          {{'PROCESS_EDIT_EXTERNAL_ID' | translate}}
        </label>
        <div class="col-md-8">
          <input type="text" class="form-control" placeholder="{{'PROCESS_EDIT_EXTERNAL_ID' | translate}}"
                 [readonly]="readonly" formControlName="externalId"
                 trim="blur" validateOnBlur [validateFormControl]="formGroup.controls['externalId']"
                 [ngClass]="{ 'form-control-danger': hasFieldError(ProcessTask.ValidatedField.EXTERNAL_ID) || hasLocalFieldError('externalId'), 'detail-description' : readonly }"
                 [(ngModel)]="model.externalId" (ngModelChange)="removeFieldError(ProcessTask.ValidatedField.EXTERNAL_ID)">
          <div class="form-control-feedback" *ngIf="hasFieldError(ProcessTask.ValidatedField.EXTERNAL_ID)">{{getFieldErrorText(ProcessTask.ValidatedField.EXTERNAL_ID)}}</div>
          <div class="form-control-feedback" *ngIf="hasLocalFieldError('externalId')">
            <span *ngIf="hasLocalFieldError('externalId', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
          </div>
        </div>
      </div>
      <!-- endregion External ID -->

      <!-- region Description -->
      <div class="form-group row mb-0" [ngStyle]="fieldStyle" *ngIf="!readonly || (readonly && model.description !== '')">
        <label class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_DESCRIPTION' | translate}}</label>
        <div class="col-md-8">
          <input type="text" class="form-control" placeholder="{{'COMMON_DESCRIPTION' | translate}}"
                 [readonly]="readonly"
                 [ngClass]="{ 'detail-description' : readonly }"
                 [(ngModel)]="model.description"
                 [ngModelOptions]="{standalone: true}">
        </div>
      </div>
      <!-- endregion Description -->

      <!-- region Assignee -->
      <div class="form-group row mb-0" [ngStyle]="fieldStyle" *ngIf="!readonly">
        <label class="col-form-label form-control-label col-md-4 detail-title">{{'STOCK_LABEL_OWNER_USER' | translate}}</label>
        <div class="col-md-8">
          <angular2-multiselect
            [(ngModel)]="selectedUser"
            (onSelect)="onUserSelected()"
            (onDeSelect)="onUserSelected()"
            [ngModelOptions]="{standalone: true}"
            [data]="users"
            [settings]="dropdownSettings">
          </angular2-multiselect>
        </div>
      </div>

      <div class="form-group row mb-0" [ngStyle]="fieldStyle" *ngIf="readonly && model.assigneeUserId">
        <label class="col-form-label form-control-label col-md-4 detail-title">{{'STOCK_LABEL_OWNER_USER' | translate}}</label>
        <div class="col-md-8">
        <input type="text" class="form-control"
               [readonly]="readonly"
               [ngClass]="{ 'detail-description' : readonly }"
               [(ngModel)]="assigneeName"
               [ngModelOptions]="{standalone: true}">
        </div>
      </div>
      <!-- endregion Assignee -->

    </div>

    <input type="submit" class="btn btn-primary floating_save_button" *ngIf="!readonly" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>

  </div>

</div>

