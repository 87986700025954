/* eslint-disable */
/* eslint-enable */

import { AppNgbTimeStruct } from '../../util/ngb-datepicker';
import { Injectable } from '@angular/core';

/**
 * Abstract type serving as a DI token for the service converting from your application Time model to internal
 * AppNgbTimeStruct model.
 * A default implementation converting from and to AppNgbTimeStruct is provided for retro-compatibility,
 * but you can provide another implementation to use an alternative format.
 */
export abstract class NgbTimeAdapter<T> {
  /**
   * Converts user-model time into an AppNgbTimeStruct for internal use in the library
   * @param  {any}     value any value that end user uses as the time model, ie: AppNgbTimeStruct, "hh:ss"
   * @return {AppNgbTimeStruct}
   */
  abstract fromModel(value: T): AppNgbTimeStruct;
  /**
   * Converts internal time value AppNgbTimeStruct to user-model time
   * The returned type is supposed to be of the same type as fromModel() input-value param
   * @param  {AppNgbTimeStruct} time internal AppNgbTimeStruct time representation
   * @return {any}
   */
  abstract toModel(time: AppNgbTimeStruct): T;
}

@Injectable()
export class AppNgbTimeStructAdapter extends NgbTimeAdapter<AppNgbTimeStruct> {
  fromModel(value: AppNgbTimeStruct): AppNgbTimeStruct {
    return value;
  }

  toModel(time: AppNgbTimeStruct): AppNgbTimeStruct {
    return time;
  }
}

export const TIMEPICKER_ADAPTER_PROVIDER = {
  provide: NgbTimeAdapter, useClass: AppNgbTimeStructAdapter
};
