/* eslint-disable */
import { Injectable } from '@angular/core';
import { DisableRequest, EmptyMessage, IdentityMessage, ListQuery } from '../util/messages';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { ResourceQueryResult } from '../util/services';
import { DownloadedFile } from '../util/downloaded-files';
import { UrlBuilder } from '../../util/url-builder';
import { map } from 'rxjs/operators';
import { Map as ImmutableMap } from 'immutable';
/* eslint-enable */

export namespace DocumentGroup {

  export const DOCUMENT_LIBRARY_ASPECT_RATIO: number = 206 / 122;
  export const DOCUMENT_LIBRARY_ASPECT_RATIO_STR: string = '206/122';
  export enum OrderField {
    ID,
    CODE,
    NAME
  }

  export class Keys {

    private static readonly ID = 'id';
    private static readonly CODE = 'code';
    private static readonly NAME = 'name';

    private static readonly orderFieldKeyMap: ImmutableMap<DocumentGroup.OrderField, string> = ImmutableMap.of(
      DocumentGroup.OrderField.ID, Keys.ID,
      DocumentGroup.OrderField.CODE, Keys.CODE,
      DocumentGroup.OrderField.NAME, Keys.NAME,
    );

    public static toOrderFieldKey(field: DocumentGroup.OrderField): string {
      return Keys.orderFieldKeyMap.get(field)!;
    }

  }

}

// Groups
export interface DocumentGroup {
  id: number | null;
  name: string;
  code: string;
  language_code: string;
  parent_id?: number;
  user_group_id?: number[];
  creation_time: string;
  update_time: string;
  disabled: boolean;
  picture?: {
    content_type?: string;
    content_hash?: string;
  };
}


export interface DocumentGroupQuery extends ListQuery.Paging, ListQuery.Order, ListQuery.QueryText, ListQuery.Fields {
  id?: any | null;
  name?: string;
  code?: string;
  language_code?: string;
  parent_id?: number;
  only_root?: boolean;
  user_group_id?: number;
  disabled?: boolean;
  no_progress_bar?: boolean;
}

export interface DocumentGroupCreateRequest {
  name: string;
  language_code: string;
  code: string;
  parent_id?: number;
  user_group_id: number[];
}

export interface DocumentGroupEditRequest  extends DocumentGroupCreateRequest {
  id: number;
}

@Injectable()
export class DocumentGroupService extends BaseHttpService {

  query(request: DocumentGroupQuery): Observable<ResourceQueryResult<DocumentGroup>> {
    return this.http.get<ResourceQueryResult<DocumentGroup>>(this.url, this.parseParams(request));
  }

  updateGroup(request: DocumentGroupEditRequest): Observable<IdentityMessage> {
    return this.http.put<IdentityMessage>(this.url + `${request.id}`, request);
  }

  setDisabled(request: DisableRequest): Observable<EmptyMessage> {
    return this.http.patch(this.url + `${request.id}/disabled`, request);
  }

  get(request: IdentityMessage): Observable<DocumentGroup> {
    return this.http.get<DocumentGroup>(this.url + `${request.id}`);
  }

  create(request: DocumentGroupCreateRequest): Observable<IdentityMessage> {
    return this.http.post<IdentityMessage>(this.url, request);
  }

  downloadThumbnail(documentGroupId: number, no_progress_bar?: boolean): Observable<DownloadedFile> {
    const url = UrlBuilder.create('document-groups/:documentGroupId/picture?{:no_progress_bar}')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .namedNumber('documentGroupId', documentGroupId)
      .queryString('no_progress_bar', no_progress_bar === undefined ? undefined : ''+no_progress_bar)
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob',
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  deleteThumbnail(request: IdentityMessage): Observable<EmptyMessage> {
    return this.http.delete<EmptyMessage>(this.url + `${request.id}/picture`);
  }

  constructor(private http: HttpClient, private resourceHelper: ResourceHelper) {
    super(resourceHelper, '/document-groups/');
  }
}
