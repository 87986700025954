/* eslint-disable */
import { AfterViewInit, Component, OnInit, ViewChild, } from '@angular/core';
import { Transition, UIRouter } from '@uirouter/angular';
import { ImmutableUserGroupItem, OwnerUserItem, SelectUtils, UiConstants } from '../../../util/core-utils';
import { StateName } from '../../../app.state-names';
import { InputMask } from '../../../util/input-masks';
import { MasterdataCreateModel, MasterdataEditModel, RequiredMasterDataIdsItems } from '../../../util/masterdata-utils';
import { MasterData, MasterDataService } from '../../../lib/masterdata/master-data.service';
import {
  FieldValidationError,
  LocalFieldValidationErrors,
  LocalFieldValidationErrorsFactory,
  ResourceQueryResult,
  Services,
} from '../../../lib/util/services';
import { FormServiceCode } from '../../../lib/form/form-service-factory';
import { NgForm, NgModel, } from '@angular/forms';
import { List, Set } from 'immutable';
import { LoadingHandler } from '../../../lib/loading-handler';
import { FormRights } from '../../../util/form/form-utils';
import { Rights } from '../../../app.rights';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { TranslateService } from '@ngx-translate/core';
import { Form } from '../../../lib/form/form.service';
import { saveAs } from 'file-saver';
import { FormEditComponent } from '../../form/form-edit/form-edit.component';
import { FormImportDialogComponent } from '../../form/form-import/form-import-dialog.component';
import { CustomerOwnerTypeItem } from '../../../util/customer-utils';
import { Angular2Multiselects } from '../../../util/multiselect';
import { UserGroup, UserGroupService } from '../../../lib/user-group.service';
import { combineLatest } from 'rxjs';
import { Strings } from '../../../lib/util/strings';
import { Customer } from '../../../lib/customer/customer.service';
import { ToasterService } from '../../../fork/angular2-toaster/src/toaster.service';
import { StringKey } from '../../../app.string-keys';

/* eslint-enable */

@Component({
  selector: 'app-masterdata-edit',
  templateUrl: 'masterdata-edit.component.html',
  styleUrls: ['masterdata-edit.component.scss']
})
export class MasterdataEditComponent implements OnInit, AfterViewInit {

  InputMask = InputMask;
  MasterData = MasterData;
  FormServiceCode = FormServiceCode;
  RequiredMasterDataIdsItems = RequiredMasterDataIdsItems;
  SelectUtils = SelectUtils;
  UiConstants = UiConstants;

  formRights: FormRights = {
    formRead: Rights.MASTER_DATA_FORM_READ,
    formGroupUpdate: Rights.MASTER_DATA_FORM_GROUP_UPDATE,
    formFieldUpdate: Rights.MASTER_DATA_FORM_FIELD_UPDATE,
    formGroupCreate: Rights.MASTER_DATA_FORM_GROUP_CREATE,
    formFieldCreate: Rights.MASTER_DATA_FORM_FIELD_CREATE,
    formGroupDisable: Rights.MASTER_DATA_FORM_GROUP_DISABLE,
    formFieldDisable: Rights.MASTER_DATA_FORM_FIELD_DISABLE,
    formGroupMove: Rights.MASTER_DATA_FORM_GROUP_MOVE,
    formFieldMove: Rights.MASTER_DATA_FORM_FIELD_MOVE,
  };

  @ViewChild('f', {static: true})
  fForm: NgForm;

  @ViewChild('formEdit', {static: true})
  formEdit: FormEditComponent;

  @ViewChild('name', {static: true})
  nameInput: NgModel;

  @ViewChild('external_id', {static: true})
  externalIdInput: NgModel;

  @ViewChild('formImportDialog', {static: true})
  formImportDialog: FormImportDialogComponent;

  @ViewChild('ownerGroups', {static: true})
  ownerGroupsInput: NgModel;

  model: MasterdataEditModel = new MasterdataCreateModel();
  ownerUsers: OwnerUserItem[] = [];
  ownerTypes: CustomerOwnerTypeItem[] = [];
  masterdataId: number;
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  userGroupDropdownSettings: Angular2Multiselects.Settings;

  private fieldErrors: FieldValidationError<MasterData.ValidatedField> =
    FieldValidationError.empty<MasterData.ValidatedField>();

  private validatedInputs: LocalFieldValidationErrors<NgModel> =
    LocalFieldValidationErrorsFactory.empty();

  get formService(): Form.Service {
    return this.masterDataService;
  }

  constructor(
    private masterDataService: MasterDataService,
    private uiRouter: UIRouter,
    private toasterService: ToasterService,
    private translateService: TranslateService,
    private transition: Transition,
    private userGroupService: UserGroupService) {
    this.masterdataId = this.transition.params().id;
  }

  ngOnInit() {
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('MENU_NAVBAR_MASTERDATA').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.MASTERDATA_LIST});
      }
    );
    this.initDropdown();
  }

  ngAfterViewInit(): void {
    this.loadLocalFieldValidationErrors();
    combineLatest(
      this.userGroupService.query({
          disabled: false,
          page_number: 1,
          number_of_items: 30,
          order: Services.createOrderFieldParameter(UserGroup.Keys.toOrderFieldKey, Set.of(UserGroup.DEFAULT_ORDER))
        }
      ),
      this.masterDataService.get({
        masterDataId: this.masterdataId
      }),
      (userGroups: ResourceQueryResult<UserGroup>, masterData: MasterData.MasterData) => {
        return {userGroups: userGroups, masterData: masterData}
      }).subscribe((result: { userGroups: ResourceQueryResult<UserGroup>, masterData: MasterData.MasterData }) => {
      this.breadcrumbSelf = result.masterData.name;
      this.loadUserGroups(result.userGroups.items);
      this.loadModel(result.masterData);
    });
  }

  private loadNotFoundUserGroups(ids: number[]) {
    this.userGroupService.query({
      id: ids.join(','),
      order: Services.createOrderFieldParameter(UserGroup.Keys.toOrderFieldKey, Set.of(UserGroup.DEFAULT_ORDER))
    }).subscribe((userGroups: ResourceQueryResult<UserGroup>) => {
      userGroups.items.forEach((group) => {
        this.model.userGroups.unshift(ImmutableUserGroupItem.fromUserGroupWithDisabled(group));
      });
    });
  }

  loadUserGroups(userGroups: UserGroup[]) {
    this.model.loadUserGroupItems(
      ImmutableUserGroupItem.fromUserGroupArray(userGroups)
    );
  }

  loadCustomerOwnerTypes() {
    const keys: string[] = [];
    Customer.customerMobileOwnerTypes.forEach((type) => {
      const item = {id: type.type, text: '', key: type.stringKey};
      this.ownerTypes.push(item);
      if (this.model.mobileOwnerType && this.model.mobileOwnerType.id === type.type) {
        this.model.mobileOwnerType = item;
      }
      keys.push(type.stringKey);
    });
    this.translateService.get(keys).subscribe((texts) => {
      this.ownerTypes.forEach((type) => {
        type.text = texts[type.key];
      });
    });
  }

  searchUserGroups(q?: any) {
    this.userGroupService.query({
        name: q ? Strings.undefinedOrNonEmpty(q.target.value) : undefined,
        disabled: false,
        page_number: 1,
        number_of_items: 30,
        order: Services.createOrderFieldParameter(UserGroup.Keys.toOrderFieldKey, Set.of(UserGroup.DEFAULT_ORDER)),
        no_progress_bar: true
      }
    ).subscribe((ugs: ResourceQueryResult<UserGroup>) => {
      this.loadUserGroups(ugs.items);
    });
  }

  initDropdown() {
    this.userGroupDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .remoteSearch(true)
      .build();
  }

  update() {
    if (this.hasLocalFieldError()) {
      this.showValidationErrorToast();
      return;
    }
    this.masterDataService.update({
      masterDataId: this.masterdataId,
      name: this.model.name,
      externalId: this.model.externalId,
      description: this.model.description,
      requiredMasterDataId: this.model.requiredMasterDataId,
      mobileOwnerType: this.model.mobileOwnerType!.id!,
      ownerUserGroupIds: this.model.getUserGroupIds(),
    }).subscribe(
      (response: MasterData.MasterData) => {
        LoadingHandler.getInstance().refresh();
        this.uiRouter.stateService.go(StateName.MASTERDATA_LIST);
      },
      (error: any) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withForm(this.fForm);
          this.showValidationErrorToast();
        }
      });
  }

  private showValidationErrorToast() {
    this.toasterService.pop({
      timeout: UiConstants.ToastTimeoutLong,
      type: UiConstants.toastTypeError,
      title: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_TITLE),
      body: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_MESSAGE)
    });
  }

  hasLocalFieldError(field?: NgModel): boolean {
    return this.validatedInputs.hasLocalError(field);
  }

  hasFieldError(field?: MasterData.ValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: MasterData.ValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: MasterData.ValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  back() {
    window.history.back();
  }

  private loadLocalFieldValidationErrors() {
    const validatedInputs = List.of(this.nameInput, this.externalIdInput);
    this.validatedInputs = LocalFieldValidationErrorsFactory.ofFormFields(this.fForm, validatedInputs);
  }

  private loadModel(masterData: MasterData.MasterData) {
    this.model.name = masterData.name;
    this.model.description = masterData.description ? masterData.description : '';
    this.model.externalId = masterData.externalId ? masterData.externalId : '';
    this.model.requiredMasterDataId = masterData.clientRequiredIdField;
    this.model.mobileOwnerType = {id: masterData.mobileOwnerType, text: '', key: ''};
    this.loadCustomerOwnerTypes();
    this.model.loadUserGroupItem(masterData.ownerUserGroupIds);
    this.loadNotFoundUserGroups(this.model.notFoundUserGroupIds);
  }

  exportForm() {
    this.masterDataService.exportForm({
      parentId: this.masterdataId
    }).subscribe((result: Form.FormImportDocument) => {
      const blob = new Blob([JSON.stringify(result)], {type: 'text/json;charset=utf-8'});
      saveAs(blob, 'masterData_' + this.masterdataId + '.json');
    });
  }

}
