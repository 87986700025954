import {MultiselectOptionItem} from "../../../util/core-utils";
import {BadgeStyle} from "../../../shared/table-badge/badge-style";

export namespace HelpdeskState {

  export enum State {
    NEW = 'NEW',
    IN_PROGRESS = 'IN_PROGRESS',
    DONE = 'DONE',
    WAITING = 'WAITING'
  }

  export class StateObject {
    state: State;
    stringKey: string;
    iconClass: string = '';
    badgeStyle: BadgeStyle;
  }

  export const stateObjects: StateObject[] = [
    {
      state: State.NEW,
      stringKey: 'COMMON_VALUE_TASK_STATE_NEW',
      iconClass: 'icomoon icomoon-new-state-new',
      badgeStyle: BadgeStyle.PRIMARY
    },

    {
      state: State.IN_PROGRESS,
      stringKey: 'COMMON_VALUE_TASK_STATE_IN_PROGRESS',
      iconClass: 'icomoon icomoon-new-state-in-progress',
      badgeStyle: BadgeStyle.SECONDARY
    },

    {
      state: State.WAITING,
      stringKey: 'COMMON_VALUE_TASK_STATE_PAUSED',
      iconClass: 'icomoon icomoon-new-state-rejected',
      badgeStyle: BadgeStyle.WARNING
    },

    {
      state: State.DONE,
      stringKey: 'COMMON_VALUE_TASK_STATE_FINISHED',
      iconClass: 'icomoon icomoon-new-state-finished',
      badgeStyle: BadgeStyle.SUCCESS
    },

  ]

  export const orderedHelpdeskStates: MultiselectOptionItem<State>[] = stateObjects.map(s => {
    return {id: s.state, itemName: s.stringKey}
  });


  export function getStateObject(state: HelpdeskState.State): StateObject {
    return stateObjects.find(s => s.state === state)!;
  }
}


