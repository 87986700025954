<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <!--TODO: Breadcrumb menu in V2-->
  <!--<div class="breadcrumb-menu">-->
    <!--<div class="breadcrumb-button-container">-->
      <!--<a class="btn-setting" *ngIf="rightModel.customerCreate.hasRight()" uiSref="Admin.CustomerCreate"  title="{{'COMMON_BUTTON_ADD' | translate}}">-->
        <!--<i class="icomoon icomoon-add"></i>-->
      <!--</a>-->
    <!--</div>-->
  <!--</div>-->
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="card">

    <div class="card-header">
      {{'STOCK_CHANGE_PANEL_HEADING_LIST' | translate}}
      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()" title="{{'SEARCH_PANEL_HEADING_TITLE' | translate}}"><i class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>
    <div class="card-body border-bottom" *ngIf="showSearch">
      <div class="row form-group">
        <div class="col">
          <label class="search-label">{{'STOCK_CHANGE_TABLE_HEADER_TRX_ID' | translate}}</label>
          <input type="text" class="form-control"
                 maxlength="{{UiConstants.maxInputLongLength}}"
                 (keyup.enter)="onSearchClicked()"
                 placeholder="{{'STOCK_CHANGE_TABLE_HEADER_TRX_ID' | translate}}"
                 [(ngModel)]="searchModel.trxId">
        </div>
        <div class="col">
          <label class="search-label">{{'STOCK_CHANGE_TABLE_HEADER_ASSIGNEE_NAME' | translate}}</label>
          <angular2-multiselect [data]="userList" [settings]="dropdownSettings"
                                class="form-control"
                                [(ngModel)]="searchModel.selectedUser"
                                #userMulti="ngModel" name="userMulti" id="userMulti">
          </angular2-multiselect>
        </div>
        <div class="col">
          <label class="search-label">{{'STOCK_CHANGE_TABLE_HEADER_CHANGE_TYPE' | translate}}</label>
          <select class="form-control" [(ngModel)]="searchModel.selectedStockChangeType"
                  id="changeType" name="changeType" #changeType="ngModel"
                  [compareWith]="SelectUtils.compareObjects">
            <option *ngFor="let changeType of stockChangeTypes" [ngValue]="changeType">{{changeType.text}}</option>
          </select>
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'STOCK_CHANGE_TABLE_HEADER_TIME_FROM' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control date" placeholder="{{'STOCK_CHANGE_TABLE_HEADER_TIME_FROM' | translate}}"
                   [(ngModel)]="searchModel.startDate" ngbDatepicker name="startDate" id="startDate" #startDate="ngbDatepicker">
            <div class="input-group-addon-gray" (click)="startDate.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <label class="search-label">{{'STOCK_CHANGE_TABLE_HEADER_TIME_TO' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control date"
                   placeholder="{{'STOCK_CHANGE_TABLE_HEADER_TIME_TO' | translate}}"
                   [(ngModel)]="searchModel.endDate" ngbDatepicker name="endDate" #endDate="ngbDatepicker" id="endDate">
            <div class="input-group-addon-gray" (click)="endDate.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 d-flex justify-content-md-end align-items-end px-0">
        <div class="btn-group" role="group">
          <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
            {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
          </button>
          <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
            {{'COMMON_BUTTON_SEARCH' | translate}}
          </button>
        </div>
      </div>
    </div> <!-- ./card-body end -->
    <div class="card-body">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header w-5 hidden-xs-down"><app-table-field-sorter [orderField]="StockChange.OrderField.ID" [orderType]="queryModel.getOrderType(StockChange.OrderField.ID)" (orderChange)="orderBy($event)" [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter></th>
          <th class="table-sorter-header w-15"><app-table-field-sorter [orderField]="StockChange.OrderField.TRX_ID" [orderType]="queryModel.getOrderType(StockChange.OrderField.TRX_ID)" (orderChange)="orderBy($event)" [text]="'STOCK_CHANGE_TABLE_HEADER_TRX_ID' | translate"></app-table-field-sorter></th>
          <th class="table-sorter-header long"><app-table-sorter-no-op [text]="'STOCK_CHANGE_TABLE_HEADER_CHANGE_TYPE' | translate"></app-table-sorter-no-op></th>
          <th class="table-sorter-header w-20 hidden-md-down"><app-table-sorter-no-op [text]="'STOCK_CHANGE_TABLE_HEADER_ASSIGNEE_NAME' | translate"></app-table-sorter-no-op></th>
          <th class="table-sorter-header w-15 hidden-md-down"><app-table-field-sorter [orderField]="StockChange.OrderField.CREATION_TIME" [orderType]="queryModel.getOrderType(StockChange.OrderField.CREATION_TIME)" (orderChange)="orderBy($event)" [text]="'STOCK_CHANGE_TABLE_HEADER_TIME' | translate"></app-table-field-sorter></th>
          <th class="table-sorter-header w-table-actions-single"><app-table-sorter-no-op></app-table-sorter-no-op></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let stockChange of stockChangeList | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }">
          <td class="responsive-table-column hidden-xs-down">{{stockChange.id}}</td>
          <td class="responsive-table-column">{{stockChange.trxId}}</td>
          <td class="responsive-table-column">
            <i class="icomoon icomoon-state-icons" [ngClass]="stockChange.stockChangeType.iconClass"></i>
            {{stockChange.stockChangeType.key | translate}}
          </td>
          <td class="responsive-table-column hidden-md-down">{{stockChange.assigneeName}}</td>
          <td class="responsive-table-column hidden-md-down">{{stockChange.changeTime.toUtcIsoString() | date:'short'}}</td>
          <td class="responsive-table-column">
            <a class="icons-table-group" uiSref="Admin.StockChangeDetail"
               [uiParams]="{ id: stockChange.id }">
              <i class="icomoon icons-table-item icomoon-detail icomoon-detail-table"
                 title="{{'COMMON_BUTTON_EDIT' | translate}}"></i>
            </a>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header w-5 hidden-xs-down"><app-table-field-sorter [orderField]="StockChange.OrderField.ID" [orderType]="queryModel.getOrderType(StockChange.OrderField.ID)" (orderChange)="orderBy($event)" [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter></th>
          <th class="table-sorter-header w-15"><app-table-field-sorter [orderField]="StockChange.OrderField.TRX_ID" [orderType]="queryModel.getOrderType(StockChange.OrderField.TRX_ID)" (orderChange)="orderBy($event)" [text]="'STOCK_CHANGE_TABLE_HEADER_TRX_ID' | translate"></app-table-field-sorter></th>
          <th class="table-sorter-header long"><app-table-sorter-no-op [text]="'STOCK_CHANGE_TABLE_HEADER_CHANGE_TYPE' | translate"></app-table-sorter-no-op></th>
          <th class="table-sorter-header w-20 hidden-md-down"><app-table-sorter-no-op [text]="'STOCK_CHANGE_TABLE_HEADER_ASSIGNEE_NAME' | translate"></app-table-sorter-no-op></th>
          <th class="table-sorter-header w-15 hidden-md-down"><app-table-field-sorter [orderField]="StockChange.OrderField.CREATION_TIME" [orderType]="queryModel.getOrderType(StockChange.OrderField.CREATION_TIME)" (orderChange)="orderBy($event)" [text]="'STOCK_CHANGE_TABLE_HEADER_TIME' | translate"></app-table-field-sorter></th>
          <th class="table-sorter-header w-table-actions-single"><app-table-sorter-no-op></app-table-sorter-no-op></th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>
  </div>
</div>
