import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { DetailTab } from '../../../transport/transport-detail/detail-tab';
import { TaskRecordSubTask, TaskRecordSubTaskService } from '../../../../lib/task/task-record-subtask/task-record-sub-task.service';
import { OrderType, QueryResult, ResourceQueryResult, Services } from '../../../../lib/util/services';
import { Dates, OffsetDateTime } from '../../../../lib/util/dates';
import {
  MultiselectOptionItem,
  OptionItem,
  OrderFieldFunction,
  OrderFieldModel, OrderFunctionResolver,
  UiConstants,
} from '../../../../util/core-utils';
import { Set, Map as IM } from 'immutable';
import { OrderField } from '../../../../lib/query/orderfields';
import { InputMask } from '../../../../util/input-masks';
import { SearchUtils } from '../../../../util/search-utils';
import { User, UserService } from '../../../../lib/user.service';
import { Angular2Multiselects } from '../../../../util/multiselect';
import { FilterField } from '../../../../lib/query/filterfields';
import { CriteriaBuilder, Models } from '../../../../util/model-utils';
import { TaskRecordStateMachine } from '../../../../lib/task/task-record-statemachine';
import { TaskRecord, TaskRecordService } from '../../../../lib/task/task-record.service';
import {
  GrantedPermissionSet,
  GrantedPermissionSetResolver,
  RightResolver,
  RightService,
} from '../../../../lib/right.service';
import { RightModel } from '../../../../app.rights';
import { OperationRights } from '../../../../app.right-definitions';
import { Strings } from '../../../../lib/util/strings';

@Component({
  selector: 'app-task-record-sub-task',
  templateUrl: './task-record-sub-task.component.html',
  styleUrls: ['./task-record-sub-task.component.scss']
})
export class TaskRecordSubTaskComponent extends SearchUtils.SearchableList<SubTaskSearchModel> implements OnInit, DetailTab {
  InputMask = InputMask;
  UiConstants = UiConstants;
  OrderFunctions = OrderFunctions;
  TaskRecordSubTask = TaskRecordSubTask;

  queryModel: OrderFieldModel<OrderField.TaskRecordSubTask> = new OrderFieldModel(OrderFunctions.ID, OrderType.DESC);
  searchModel: SubTaskSearchModel = new SubTaskSearchModel();

  @Input()
  taskId: number;

  @Input()
  taskRecordId: number;

  @Input()
  taskRecordState?: TaskRecordStateMachine.State;

  @Output()
  onSubTaskCreateEditDialogButtonClicked: EventEmitter<number | undefined> = new EventEmitter<number|undefined>();

  @Output()
  onSubTaskDetailDialogButtonClicked: EventEmitter<SubTaskModel> = new EventEmitter<SubTaskModel>();

  @Output()
  onSubTaskDeleteDialogButtonClicked: EventEmitter<number> = new EventEmitter<number>();

  readonly pagingId = 'taskRecordSubTaskPaging';
  dropdownSettings: Angular2Multiselects.Settings;

  subTaskList: SubTaskModel[] = [];
  states: TaskRecordSubTaskStateFilterOption[] = [];
  users: MultiselectOptionItem<number>[] = [];
  rightModel: RightModel = RightModel.empty();

  constructor(private taskRecordSubTaskService: TaskRecordSubTaskService,
              private userService: UserService,
              private taskRecordService: TaskRecordService,
              private rightService: RightService,
              injector: Injector) {
    super(SubTaskSearchModel, injector);
  }

  ngOnInit() {
    this.loadRightModels();
    this.initDropdownSettings();
    this.loadTaskRecordState();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  initComponent() {
    this.initSearch();
  }

  loadTaskRecordState() {
    this.taskRecordService.get({
      fields: Set.of('state'),
      taskId: this.taskId,
      taskRecordId: this.taskRecordId
    }).subscribe((tr: TaskRecord.TaskRecord) => {
      this.taskRecordState = tr.state;
    })
  }

  loadList(requestedPage?: number) {
    const subTaskRights = Set.of(
      OperationRights.TASK_RECORD_SUB_TASK_UPDATE,
      OperationRights.TASK_RECORD_SUB_TASK_DELETE
    );

    const order = this.queryModel.createOrderFunction();
    const filter = this.createFilter();
    this.taskRecordSubTaskService.query({
      fields: f => f.each,
      taskId: this.taskId,
      taskRecordId: this.taskRecordId,
      order: order,
      filter: filter,
      rights: subTaskRights,
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined,
    }).subscribe((result: QueryResult<TaskRecordSubTask.SubTask>) => {
      this.subTaskList = [];
      result.items.forEach((subTask: TaskRecordSubTask.SubTask) => {
        const subTaskModel = new SubTaskModel();
        subTaskModel.id = subTask.id;
        subTaskModel.rights = new SubTaskRightModel(GrantedPermissionSetResolver.byGrantedRights(subTask.grantedRights));
        subTaskModel.name = subTask.name;
        subTaskModel.type = subTask.type ? subTask.type : '';
        subTaskModel.note = subTask.note ? subTask.note : '';
        subTaskModel.workerUser =
          subTask.workerUser ? subTask.workerUser.personName + ' (' + subTask.workerUser.userName + ')' : '';
        subTaskModel.state = subTask.state;
        const stateObject = TaskRecordSubTask.subTaskStates.find(state => state.state === subTask.state);
        if (stateObject) {
          subTaskModel.stateStringKey = stateObject.stringKey;
          subTaskModel.stateIconClass = stateObject.iconClass;
        }
        subTaskModel.creationTime = subTask.creationTime;
        subTaskModel.startTime = subTask.startTime;
        subTaskModel.endTime = subTask.endTime;
        if (subTask.startTime.isValid()) {
          if (subTask.endTime.isValid()) {
            subTaskModel.timeSpent = subTask.endTime.diffDuration(subTask.startTime)!.humanize(true);
          }
          else {
            subTaskModel.timeSpent = Dates.now().diffDuration(subTask.startTime)!.humanize(true);
          }
        }
        this.subTaskList.push(subTaskModel);
      });
    });
  }

  private createFilter() {
    return (f: FilterField.TaskRecordSubTask) => CriteriaBuilder.builder()
        .addNumber((id) => f.id.eq(id), Models.parseNumber(this.searchModel.id))
        .addString((name) => f.name.containsIgnoreCase(name), this.searchModel.name)
        .addString((type) => f.type.containsIgnoreCase(type), this.searchModel.type)
        .addString((note) => f.note.containsIgnoreCase(note), this.searchModel.note)
        .addEnum((state) => f.state.eq(state), this.searchModel.state.id)
        .addNumber((workerUserId) => f.workerUser.id.eq(workerUserId), this.searchModel.workerUserId)
        .build();
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: OrderFieldFunction<OrderField.TaskRecordSubTask>) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  onSearchClicked() {
    this.loadList(1);
  }

  onSearchReset() {
    this.searchModel.reset();
    this.loadList(1);
  }

  loadSearch(completion: () => void): void {
    completion();
  }

  onFirstSearchOpen(): void {
    this.loadTaskRecordSubTaskStateFilterOptions();
    this.loadWorkerUsers();
  }

  private loadTaskRecordSubTaskStateFilterOptions() {
    this.states = [];
    this.states.push(SubTaskSearchModel.defaultSelectedState);
    TaskRecordSubTask.subTaskStates.forEach((stateObject) => {
      this.states.push({
        id: stateObject.state,
        stringKey: stateObject.stringKey
      });
    });
  }

  /*
    FIXME: Only load users that are part of assigned user group or assignee of taskRecord.
    The fix is blocked by APPWORKS-5650
   */
  loadWorkerUsers(searchValue?: string) {
    this.userService.query({
      q: Strings.undefinedOrNonEmpty(searchValue),
      fields: 'id,user_name,person_name,disabled',
      disabled: false,
      number_of_items: UiConstants.autocompletePageSize,
      page_number: 1,
      order: Services.createOrderFieldParameter(User.Keys.toOrderFieldKey, Set.of(User.DEFAULT_ORDER)),
      no_progress_bar: true,
    }).subscribe((users: ResourceQueryResult<User>) => {
      this.users = [];
      users.items.forEach(user => {
        const item = {
          id: user.id,
          itemName: user.person_name + ' (' + user.user_name + ')',
          disabled: user.disabled
        };
        this.users.push(item);
      });
    });
  }

  initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .remoteSearch(true)
      .enableCheckAll(false)
      .build();
  }

}

export class SubTaskModel {
  id: number;
  rights: SubTaskRightModel = new SubTaskRightModel(GrantedPermissionSetResolver.empty());
  name: string = '';
  type: string = '';
  note: string = '';
  workerUser: string = '';
  state: TaskRecordSubTask.SubTaskState;
  stateStringKey: string = '';
  stateIconClass: string = '';
  timeSpent: string = '';
  creationTime: OffsetDateTime = Dates.emptyOffsetDateTime();
  startTime: OffsetDateTime = Dates.emptyOffsetDateTime();
  endTime: OffsetDateTime = Dates.emptyOffsetDateTime();
}

export class SubTaskRightModel {

  public readonly update: GrantedPermissionSet;
  public readonly delete: GrantedPermissionSet;

  constructor(resolver: GrantedPermissionSetResolver) {
    this.update = resolver.of(OperationRights.TASK_RECORD_SUB_TASK_UPDATE);
    this.delete = resolver.of(OperationRights.TASK_RECORD_SUB_TASK_DELETE);
  }

}

class SubTaskSearchModel extends SearchUtils.SearchModel {

  public static defaultSelectedState = { id: null, stringKey: 'COMMON_VALUE_UNSELECTED' };

  showSearch: boolean = false;

  id: string;
  name: string;
  type: string;
  note: string;
  state: TaskRecordSubTaskStateFilterOption;
  workerUser: OptionItem<number>[];

  get workerUserId(): number | undefined {
    if (this.workerUser.length > 0 && this.workerUser[0].id !== null) {
      return this.workerUser[0].id!;
    }
    return undefined;
  }

  constructor() {
    super();
    this.reset();
  }

  reset() {
    this.id = '';
    this.name = '';
    this.type = '';
    this.note = '';
    this.state = SubTaskSearchModel.defaultSelectedState;
    this.workerUser = [];
  }

  isEmpty(): boolean {
    return this.id === '' &&
    this.name === '' &&
    this.type === '' &&
    this.note === '' &&
    this.state.id === null &&
    this.workerUser.length === 0;
  }

}

interface TaskRecordSubTaskStateFilterOption {
  id: TaskRecordSubTask.SubTaskState | null;
  stringKey: string;
}

export namespace OrderFunctions {

  export const ID = (f: OrderField.TaskRecordSubTask) => f.id;
  export const NAME = (f: OrderField.TaskRecordSubTask) => f.name;
  export const TYPE = (f: OrderField.TaskRecordSubTask) => f.type;

  export const VALUES = OrderFunctionResolver.builder<OrderField.TaskRecordSubTask>()
    .add(ID, 'id')
    .add(NAME, 'name')
    .add(TYPE, 'type')
    .build();

}
