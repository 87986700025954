/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DisableRequest, EmptyMessage, IdentityMessage, ListQuery } from './util/messages';
import { Map as ImmutableMap } from 'immutable';
import { BaseHttpService, ResourceHelper } from './util/http-services';
import { HttpClient } from '@angular/common/http';
import { ResourceQueryResult } from './util/services';
/* eslint-enable */

export interface NoteQuery extends ListQuery.Paging, ListQuery.Order, ListQuery.QueryText, ListQuery.Fields {
  id?: string;
  content?: string;
  customer_id?: number;
  customer_name?: string;
  external_id?: string;
  owner_user_id?: number;
  owner_user_name?: string;
  task_record_name?: string;
  disabled?: boolean;
}

export interface CustomerNoteResource {
  id: number;
  disabled: boolean;
  creation_time: string;
  update_time: string;
  content: string;
  customer_id: number;
  owner_user_id: number;
  task_record_id?: number;
  attachment_ids: number[];
}

@Injectable()
export class NoteService extends BaseHttpService {

  query(request: NoteQuery): Observable<ResourceQueryResult<CustomerNoteResource>> {
    return this.http.get<ResourceQueryResult<CustomerNoteResource>>(this.url, this.parseParams(request));
  }

  get(request: IdentityMessage): Observable<CustomerNoteResource> {
    return this.http.get<CustomerNoteResource>(this.url + `${request.id}`);
  }

  delete(request: IdentityMessage): Observable<EmptyMessage> {
    return this.http.delete<EmptyMessage>(this.url + `${request.id}`);
  }

  setDisabled(request: DisableRequest): Observable<EmptyMessage> {
    return this.http.patch(this.url + `${request.id}/disabled`, request);
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/notes/');
  }
}

export namespace NoteList {

  export enum OrderField {
  ID,
  CONTENT,
  OWNER_USER_ID,
  CUSTOMER_ID,
  TASK_RECORD_ID,
  CREATION_TIME,
  UPDATE_TIME
  }

  export class Keys {

    private static readonly ID = 'id';
    private static readonly CONTENT = 'content';
    private static readonly OWNER_USER_ID = 'owner_user_id';
    private static readonly CUSTOMER_ID = 'customer_id';
    private static readonly TASK_RECORD_ID = 'task_record_id';
    private static readonly CREATION_TIME = 'creation_time';
    private static readonly UPDATE_TIME = 'update_time';

    private static readonly orderFieldKeyMap: ImmutableMap<NoteList.OrderField, string> = ImmutableMap.of(
      NoteList.OrderField.ID, Keys.ID,
      NoteList.OrderField.CONTENT, Keys.CONTENT,
      NoteList.OrderField.OWNER_USER_ID, Keys.OWNER_USER_ID,
      NoteList.OrderField.CUSTOMER_ID, Keys.CUSTOMER_ID,
      NoteList.OrderField.TASK_RECORD_ID, Keys.TASK_RECORD_ID,
      NoteList.OrderField.CREATION_TIME, Keys.CREATION_TIME,
      NoteList.OrderField.UPDATE_TIME, Keys.UPDATE_TIME,
    );

    public static toOrderFieldKey(field: NoteList.OrderField): string {
      return Keys.orderFieldKeyMap.get(field)!;
    }

  }

}

