/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResourceQueryResult } from '../util/services';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { HttpClient } from '@angular/common/http';
import { HistoryLog } from '../history-log/history-log.service';

/* eslint-enable */

@Injectable()
export class RoleLogResourceService extends BaseHttpService {

  history(request: RoleLogResource.HistoryRequest): Observable<ResourceQueryResult<RoleLogResource.HistoryItem>> {
    return this.http.get<ResourceQueryResult<RoleLogResource.HistoryItem>>(
      this.url + `${request.role_id}/history-logs?with_read=${request.with_read}`,
      this.parseParams(request)
    );
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/roles/');
  }
}


export namespace RoleLogResource {

  // <editor-fold desc="CRUD">

  export interface HistoryRequest {
    role_id: number;
    with_read: boolean;
    q?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
  }

  export interface HistoryItem extends HistoryLog.HistoryItemBaseResource {
  }


  // </editor-fold>

}
