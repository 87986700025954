/* eslint-disable */
import { AfterViewInit, Component, OnInit, ViewChild, } from '@angular/core';
import { Transition, UIRouter } from '@uirouter/angular';
import { MultiselectOptionItem, OwnerUserItem, UiConstants, } from '../../../util/core-utils';
import { StateName } from '../../../app.state-names';
import { InputMask } from '../../../util/input-masks';
import {
  DefaultDeadlineTypeItem,
  DefaultDeadlineTypeTextResolverFactory,
  droidStates,
  manualTimeTrackingTypes,
  StateFilterModel,
  SubmittedFilterModel,
  TaskEditModel,
  TaskInvoiceGenerationType,
  taskInvoiceGenerationTypes,
  TaskInvoiceSettingsItem,
  TaskRecordExternalIdSequence,
  TaskRecordQuickCreateNameTemplate,
  taskSignatureValidationTypes,
  TaskTimeTrackingType,
  taskTimeTrackingTypes,
  TaskValidationTypeTextResolverFactory,
  ValidationTypeItem
} from '../../../util/task-utils';
import { Task, TaskService } from '../../../lib/task/task.service';
import {
  FieldValidationError,
  LocalFieldValidationErrors,
  LocalFieldValidationErrorsFactory,
  OrderType,
  QueryResult,
  ResourceQueryResult,
  Services
} from '../../../lib/util/services';
import { NgForm, NgModel } from '@angular/forms';
import { LoadingHandler } from '../../../lib/loading-handler';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormServiceCode } from '../../../lib/form/form-service-factory';
import { List, Set } from 'immutable';
import { FileUploaderUtil } from '../../../util/file-uploader-util';
import { StringKey } from '../../../app.string-keys';
import { ToasterService } from '../../../fork/angular2-toaster/angular2-toaster';
import { TranslateService } from '@ngx-translate/core';
import { UploadErrorLocalizer } from '../../../util/upload-error-localizer';
import { RightModel, Rights } from '../../../app.rights';
import { RightResolver, RightService } from '../../../lib/right.service';
import { FormRights } from '../../../util/form/form-utils';
import { Form } from '../../../lib/form/form.service';
import { FormEditComponent } from '../../form/form-edit/form-edit.component';
import { TaskResource } from '../../../lib/task/task-resource.service';
import { TaskRecordStateMachine } from '../../../lib/task/task-record-statemachine';
import { Angular2Multiselects } from '../../../util/multiselect';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { Models } from '../../../util/model-utils';
import { Survey, SurveyService } from '../../../lib/survey/survey.service';
import { UploadResponse } from '../../../shared/file-upload/flat/file-upload.component';
import { UserGroup, UserGroupService } from '../../../lib/user-group.service';
import { combineLatest } from 'rxjs';
import { EmptyMessage } from '../../../lib/util/messages';
import { Strings } from '../../../lib/util/strings';
import { TriggerPreselectDialogComponent } from '../../trigger/trigger-preselect-dialog/trigger-preselect-dialog.component';
import { TriggerEditDialogComponent } from '../../trigger/trigger-edit-dialog/trigger-edit-dialog.component';
import { TriggerListComponent } from '../../trigger/trigger-list/trigger-list.component';
import { InvoiceSettings, InvoiceSettingsService } from '../../../lib/invoice/invoice-settings/invoice-settings.service';
import { InvoiceBook, InvoiceBookService } from '../../../lib/invoice/invoice-book/invoice-book.service';
import { ConfigurationService } from '../../../lib/core-ext/configuration.service';
import { TaskRecord } from '../../../lib/task/task-record.service';
import { MessageTemplate, MessageTemplateService } from '../../../lib/message-template/message-template.service';
import {
  UserGroupRoleType
} from '../../usergroup/usergroup-type-based-roles/user-group-type-based-role-card/user-group-type-based-role-card.component';
import { UserService } from '../../../lib/user.service';
import { InvoiceTagMultiselectProvider } from '../../../lib/invoice/tag/invoice-tag-multiselect-provider.service';
import { TaskImportDialogComponent } from '../task-import-dialog/task-import-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TaskExportDialogComponent } from '../task-export-dialog/task-export-dialog.component';
import SubmittedFilter = Task.SubmittedFilter;
import StateFilter = Task.StateFilter;
/* eslint-enable */


@Component({
  selector: 'app-new-task-edit',
  templateUrl: 'task-edit.component.html',
  styleUrls: ['task-edit.component.scss']
})
export class TaskEditComponent implements OnInit, AfterViewInit {

  InputMask = InputMask;
  Task = Task;
  FormServiceCode = FormServiceCode;
  UiConstants = UiConstants;
  TaskRecordExternalIdSequence = TaskRecordExternalIdSequence;
  TaskRecordQuickCreateNameTemplate = TaskRecordQuickCreateNameTemplate;
  InvoiceSettings = InvoiceSettings;
  TaskInvoiceGenerationType = TaskInvoiceGenerationType;
  TaskTimeTrackingType = TaskTimeTrackingType;
  taskTimeTrackingTypes = taskTimeTrackingTypes;
  manualTimeTrackingTypes = manualTimeTrackingTypes;
  taskInvoiceGenerationTypes = taskInvoiceGenerationTypes;
  pocRequirements = Task.placeOfConsumptionRequirements;
  defaultAssigneeTypes = Task.taskDefaultAssigneeTypes;
  UserGroupRoleType = UserGroupRoleType;

  taskStates: StateSelect[] = [];
  selectedStates: StateSelect[] = [];
  pdfStates: StateSelect[] = [];
  stateDropdownSettings: Angular2Multiselects.Settings;
  currentPdfId: number;
  currentPdfName: string;
  uploadSuccessful?: boolean = false;

  @ViewChild('f', {static: true})
  fForm: NgForm;

  @ViewChild('externalIdTagInput', {static: true})
  externalIdTagInput: NgModel;
  @ViewChild('taskRecordNameTemplate', {static: true})
  taskRecordNameTemplate: NgModel;

  @ViewChild('pocRequirement', {static: true})
  pocRequirementInput: NgModel;

  @ViewChild('webCreateDefaultAssigneeType', {static: true})
  webCreateDefaultAssigneeTypeInput: NgModel;

  @ViewChild('formEdit', {static: true})
  formEdit: FormEditComponent;

  @ViewChild('triggerPreselectDialog', {static: true})
  triggerPreselectDialog: TriggerPreselectDialogComponent;

  @ViewChild('triggerEditDialog', {static: true})
  triggerEditDialog: TriggerEditDialogComponent;

  @ViewChild('triggerList')
  triggerList: TriggerListComponent;

  @ViewChild('defaultLinkedSurveys', {static: true})
  defaultLinkedSurveysInput: NgModel;

  @ViewChild('creatorUserGroups', {static: true})
  creatorUserGroupsInput: NgModel;

  @ViewChild('invoiceSettingsInput')
  invoiceSettingsInput: NgModel;

  @ViewChild('invoiceBookInput')
  invoiceBookInput: NgModel;

  @ViewChild('smsRatingTemplate', {static: false})
  smsRatingTemplateInput: NgModel;

  model: TaskEditModel;
  ownerUsers: OwnerUserItem[] = [];
  validationTypeItems: ValidationTypeItem[];
  signatureValidationTypeItems: MultiselectOptionItem<TaskResource.TaskSignatureValidationType>[] = taskSignatureValidationTypes;
  photoEnabledInStateItems: MultiselectOptionItem<string>[] = droidStates;
  defaultDeadlineTypeItems: DefaultDeadlineTypeItem[];

  surveys: MultiselectOptionItem<number>[] = [];

  userGroups: MultiselectOptionItem<number>[] = [];
  users: MultiselectOptionItem<number>[] = [];
  invoiceBooks: MultiselectOptionItem<number>[] = [];
  invoiceTags: MultiselectOptionItem<number>[] = [];
  invoiceSettings: TaskInvoiceSettingsItem[] = [];
  managableFields: MultiselectOptionItem<TaskRecord.TaskRecordField>[] = [];
  _requirableFields: MultiselectOptionItem<TaskRecord.TaskRecordField>[] = [];
  get requirableFields(): MultiselectOptionItem<TaskRecord.TaskRecordField>[] {
    if (this.model.useDefaultManagedFields) {
      return this._requirableFields.filter(f => this.managableFields.map(f => f.id).includes(f.id));
    }
    return this._requirableFields.filter(f => this.model.managedFields.map(f => f.id).includes(f.id));
  }

  get helpdeskRequirableFields(): MultiselectOptionItem<TaskRecord.TaskRecordField>[] {
    return this._requirableFields.filter(f => this.model.helpdeskManagedFields.map(f => f.id).includes(f.id));
  }

  defaultStateOptions: MultiselectOptionItem<TaskRecordStateMachine.State>[] = [];
  editableStateOptions: MultiselectOptionItem<TaskRecordStateMachine.State>[] = [];
  messageTemplates: MultiselectOptionItem<number>[] = [];
  states: MultiselectOptionItem<TaskRecordStateMachine.State>[]
    = TaskRecordStateMachine.getOrderedStates(true).toArray()
    .map(s => ({id: s.state, itemName: s.stringKey}));


  @ViewChild('importDialog') importDialog: ModalDirective;
  taskId: number;
  taskForm?: Form.Form;

  templateType: Task.TaskDocumentType = Task.TaskDocumentType.PDF;
  templateTypes: Task.TaskDocumentType[] = Task.TaskDocumentType.VALUES.toArray();

  paymentDropdownSettings: Angular2Multiselects.Settings;
  invoiceDropdownSettings: Angular2Multiselects.Settings;
  searchDropdownSettings: Angular2Multiselects.Settings;
  managedFieldsDropdownSettings: Angular2Multiselects.Settings;
  defaultStateDropdownSettings: Angular2Multiselects.Settings;
  editableStateDropdownSettings: Angular2Multiselects.Settings;
  pocRequirementDropdownSettings: Angular2Multiselects.Settings;
  submittedFilterDropdownSettings: Angular2Multiselects.Settings;
  stateFilterDropdownSettings: Angular2Multiselects.Settings;
  signatureValidationDropdownSettings: Angular2Multiselects.Settings;
  photoEnabledInStatesDropdownSettings: Angular2Multiselects.Settings;

  @ViewChild('name', {static: true})
  nameInput: NgModel;

  @ViewChild('external_id', {static: true})
  externalIdInput: NgModel;

  @ViewChild('defaultEstimatedTimeInMinutes')
  defaultEstimatedTimeInMinutesInput: NgModel;

  @ViewChild('geofencingRadiusInMeters')
  geofencingRadiusInMeters: NgModel;

  @ViewChild('validInvoicePaymentTypes')
  validInvoicePaymentTypesInput: NgModel;

  @ViewChild('generatingInvoicePaymentTypes')
  generatingInvoicePaymentTypesInput: NgModel;

  @ViewChild('validationType')
  validationTypeInput: NgModel;

  @ViewChild('offset')
  offset: NgModel;

  @ViewChild('defaultState')
  defaultState: NgModel;

  @ViewChild('printInvoiceCount')
  printInvoiceCount: NgModel;


  // pdf template

  rightModel: RightModel = RightModel.empty();
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  formRights: FormRights = {
    formRead: Rights.TASK_FORM_READ,
    formGroupUpdate: Rights.TASK_FORM_GROUP_UPDATE,
    formFieldUpdate: Rights.TASK_FORM_FIELD_UPDATE,
    formGroupCreate: Rights.TASK_FORM_GROUP_CREATE,
    formFieldCreate: Rights.TASK_FORM_FIELD_CREATE,
    formGroupDisable: Rights.TASK_FORM_GROUP_DISABLE,
    formFieldDisable: Rights.TASK_FORM_FIELD_DISABLE,
    formGroupMove: Rights.TASK_FORM_GROUP_MOVE,
    formFieldMove: Rights.TASK_FORM_FIELD_MOVE,
  };


  private fieldErrors: FieldValidationError<Task.ValidatedField> =
    FieldValidationError.empty<Task.ValidatedField>();

  private validatedInputs: LocalFieldValidationErrors<NgModel> =
    LocalFieldValidationErrorsFactory.empty();

  get formService(): Form.Service {
    return this.taskService;
  }

  constructor(
    validationTypeTextResolverFactory: TaskValidationTypeTextResolverFactory,
    defaultDeadlineTypeTextResolverFactory: DefaultDeadlineTypeTextResolverFactory,
    private taskService: TaskService,
    private dialog: MatDialog,
    private uiRouter: UIRouter,
    private transition: Transition,
    private fileUploaderUtil: FileUploaderUtil,
    private toasterService: ToasterService,
    private translateService: TranslateService,
    private uploadErrorLocalizer: UploadErrorLocalizer,
    private rightService: RightService,
    private invoiceSettingsService: InvoiceSettingsService,
    private invoiceBookService: InvoiceBookService,
    private invoiceTagMultiselectProvider: InvoiceTagMultiselectProvider,
    private surveyService: SurveyService,
    private messageTemplateService: MessageTemplateService,
    public configService: ConfigurationService,
    private userService: UserService,
    private userGroupService: UserGroupService) {
    const validationTypeTextResolver = validationTypeTextResolverFactory.createFunction();
    const defaultDeadlineTypeTextResolver = defaultDeadlineTypeTextResolverFactory.createFunction();
    this.validationTypeItems = ValidationTypeItem.fromTypeList(List.of<TaskResource.TaskValidationType>(
      'REQUIRED', 'REQUEST_POSSIBLE', 'DISABLED'
    ), validationTypeTextResolver).toArray();
    this.defaultDeadlineTypeItems = DefaultDeadlineTypeItem.fromTypeList(List.of<TaskResource.DayType>(
      'BUSINESS_DAY', 'CALENDAR_DAY'
    ), defaultDeadlineTypeTextResolver).toArray();
    this.model =
      new TaskEditModel(this.validationTypeItems[0], this.defaultDeadlineTypeItems[0], this.translateService, this.configService);
    this.taskId = this.transition.params().id;
  }

  onFormLoaded(form: Form.Form) {
    this.taskForm = form;
  }

  onTemplateUpload(response: UploadResponse) {
    if (response.status === 200) {
      this.uploadSuccessful = true;
      const responseObject = JSON.parse(response.response);
      this.currentPdfId = responseObject.template_id;
    }
    else {
      this.uploadSuccessful = false;
    }
  }

  ngOnInit() {
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('MENU_FORMS').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.TASK_LIST});
      }
    );
    this.loadTaskRecordFields();
  }

  initDropDowns() {
    this.paymentDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(false)
      .enableCheckAll(true)
      .translate(true)
      .build();
    this.invoiceDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
    this.searchDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .remoteSearch(true)
      .build();
    this.stateDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(false)
      .enableCheckAll(false)
      .text(StringKey.PLACEHOLDER_STATE_NOT_SELECTED_YET)
      .selectAllText(StringKey.PLACEHOLDER_SELECT_ALL_STATE)
      .unSelectAllText(StringKey.PLACEHOLDER_SELECT_ALL_STATE)
      .noDataLabel(StringKey.PLACEHOLDER_EMPTY_STATE_LIST)
      .build();
    this.managedFieldsDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .translate(true)
      .build();
    this.defaultStateDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(false)
      .enableCheckAll(false)
      .translate(true)
      .build();
    this.editableStateDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .translate(true)
      .build();
    this.pocRequirementDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(false)
      .enableCheckAll(false)
      .translate(true)
      .labelKey('stringKey')
      .build();
    this.submittedFilterDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .text('TASK_SUBMITTED_FILTER_USER_GROUP')
      .build();
    this.stateFilterDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(false)
      .enableCheckAll(true)
      .translate(true)
      .text('TASK_STATE_FILTER_STATES')
      .build();
    this.signatureValidationDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .translate(true)
      .text('TASK_SIGNATURE_VALIDATION')
      .build();
    this.photoEnabledInStatesDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .remoteSearch(false)
      .translate(true)
      .build();
  }

  ngAfterViewInit(): void {
    this.loadTaskStates();
    this.loadRightModels();
    this.loadLocalFieldValidationErrors();
    this.loadResources();
    this.initDropDowns();
  }

  private loadTaskRecordFields() {
    const managableFields = this.configService.getConfiguration().feature_flags.task_record_managed_fields;
    this.managableFields = managableFields.map(f => {
      const stringKey = TaskRecord.taskRecordFields.find(field => field.field === f)!.stringKey;
      return {
        id: f,
        itemName: stringKey
      };
    });
    this._requirableFields = TaskRecord.taskRecordRequirableFields.map(f => {
      return {
        id: f.field,
        itemName: f.stringKey
      };
    });
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  private loadTaskStates() {
    this.taskStates = [];
    TaskRecordStateMachine.getOrderedStates(true).forEach(
      (state: TaskRecordStateMachine.StateObject) => {
        const item = {
          id: state.state,
          itemName: state.stringKey,
        };
        this.taskStates.push(item);
      });
    this.taskStates.forEach(
      (state: StateSelect) => {
        this.translateService.get(state.itemName).subscribe((stateName: string) => {
            state.itemName = stateName;
          }
        )
      });
  }

  stringArrayToSelectedStates(string_array) {
    this.selectedStates = [];
    string_array.forEach((item: TaskRecordStateMachine.State) => {
      this.taskStates.forEach((state: StateSelect) => {
        if (state.id === item) {
          this.selectedStates.push(state);
        }
      });
    });
  }

  selectedStatesToStringArray() {
    const string_array: TaskRecordStateMachine.State[] = [];
    this.selectedStates.forEach((state: StateSelect) => {
      string_array.push(state.id);
    });
    return Set.of<TaskRecordStateMachine.State>(...string_array);
  }

  taskTranslator(states: Set<TaskRecordStateMachine.State>) {
    this.pdfStates = [];
    states.forEach((string) => {
      this.taskStates.forEach((item) => {
        if (item.id === string) {
          this.pdfStates.push(item);
        }
      });
    });
    let result = '';
    this.pdfStates.forEach((item) => {
      result += ', ' + item.itemName;
    });
    result = result.substring(2);
    return result;
  }


  update() {
    this.defaultState.control.updateValueAndValidity();
    this.pocRequirementInput.control.updateValueAndValidity();
    this.webCreateDefaultAssigneeTypeInput.control.updateValueAndValidity();
    this.defaultLinkedSurveysInput.control.updateValueAndValidity();
    this.creatorUserGroupsInput.control.updateValueAndValidity();
    if (this.invoiceSettingsInput) {
      this.invoiceSettingsInput.control.updateValueAndValidity();
    }
    if (this.invoiceBookInput) {
      this.invoiceBookInput.control.updateValueAndValidity();
    }
    if (this.smsRatingTemplateInput) {
      this.smsRatingTemplateInput.control.updateValueAndValidity();
    }
    if (!this.fForm.valid || this.hasLocalFieldError() || (this.model.hasDefaultDeadline && !this.fForm.getControl(this.offset).valid)
      || (!this.model.defaultStateForceNew && !this.fForm.getControl(this.defaultState).valid) ||
      (this.model.taskInvoiceGenerationType === TaskInvoiceGenerationType.AUTOMATIC
        && !this.fForm.getControl(this.printInvoiceCount).valid)) {
      this.showValidationErrorToast();
      return;
    }
    let defaultDeadline: Task.TaskDefaultDeadline;
    if (this.model.hasDefaultDeadline && this.model.defaultDeadlineType.id) {
      defaultDeadline = {
        dayType: this.model.defaultDeadlineType.id,
        offset: this.model.offset
      };
    }
    this.taskService.update({
      taskId: this.taskId,
      name: this.model.name,
      externalId: this.model.externalId,
      description: this.model.description,
      validationType: this.model.validationTypeItem!.id!,
      customerSignature: this.model.customerSignature,
      userSignature: this.model.userSignature,
      pictureUpload: this.model.pictureUpload,
      minNumOfPics: Models.parseNumber(this.model.minNumOfPics),
      maxNumOfPics: Models.parseNumber(this.model.maxNumOfPics),
      customerAttachmentEnabled: this.model.customerAttachmentEnabled,
      parallelWorkRejected: this.model.parallelWorkRejected,
      icon: this.model.icon,
      timeTrackingEnabled: this.model.taskTimeTrackingType === TaskTimeTrackingType.AUTOMATIC,
      manualTimeTrackingEnabled: this.model.taskTimeTrackingType === TaskTimeTrackingType.MANUAL,
      manualTimeTrackingRequired: this.getManualTimeTrackingRequired(),
      manualTimeTrackingType: this.getManualTimeTrackingType(),
      collaboratorTimeTrackingEnabled:
        this.model.taskTimeTrackingType === TaskTimeTrackingType.AUTOMATIC
          ? this.model.collaboratorTimeTrackingEnabled
          : false,
      showPicturesOnForm: this.model.showPicturesOnForm,
      photoConfirmDialogEnabled: this.model.photoConfirmDialogEnabled,
      photoEnabledInStates: this.model.photoEnabledInStatesSet,
      defaultEstimatedTimeInMinutes: Models.parseNumber(this.model.defaultEstimatedTimeInMinutes),
      mileageRecordEnabled: this.model.mileageRecordEnabled,
      mileageRecordRequired: this.model.mileageRecordRequired,
      geofencingEnabled: this.model.geofencingEnabled,
      geofencingRadiusInMeters: this.model.geofencingEnabled ? this.model.geofencingRadiusInMeters : undefined,
      autoInvoiceGenerate: this.model.taskInvoiceGenerationType === TaskInvoiceGenerationType.AUTOMATIC,
      manualInvoiceEnabled: this.model.taskInvoiceGenerationType === TaskInvoiceGenerationType.MANUAL,
      invoiceSettingsId: this.model.invoiceSettingsId,
      invoiceBookId: this.model.invoiceBookId,
      invoiceTagId: this.model.invoiceTagId,
      printInvoiceCount: Models.parseNumber(this.model.printInvoiceCount),
      validInvoicePaymentTypes: this.model.validInvoicePaymentTypes,
      generatingInvoicePaymentTypes: this.model.generatingInvoicePaymentTypes,
      defaultLinkedSurveyIds: this.model.defaultLinkedSurveyIds,
      defaultDeadline: this.model.hasDefaultDeadline ? defaultDeadline! : undefined,
      creatorUserGroups: this.model.creatorUserGroupIds,
      taskRecordExternalIdSequenceData: this.model.taskRecordExternalIdSequence.length > 0 ? {
        template: this.model.taskRecordExternalIdSequence,
        withinYear: this.model.withinYear
      } : undefined,
      taskRecordNameTemplate: Strings.undefinedOrNonEmpty(this.model.taskRecordNameTemplate),
      useDefaultManagedFields: this.model.useDefaultManagedFields,
      managedFields: this.model.useDefaultManagedFields ? undefined : Set.of(...this.model.managedFields.map(f => f.id)),
      helpdeskManagedFields: !this.model.helpdeskEnabled ? undefined : Set.of(...this.model.helpdeskManagedFields.map(f => f.id)),
      requiredFields: Set.of(...this.model.requiredFields.map(f => f.id)),
      helpdeskRequiredFields: !this.model.helpdeskEnabled ? undefined : Set.of(...this.model.helpdeskRequiredFields.map(f => f.id)),
      defaultState: this.model.getDefaultState(),
      defaultStateForceNew: this.model.defaultStateForceNew,
      showOnDashboard: this.model.showOnDashboard,
      explicitOrderNumber: Models.parseNumber(this.model.explicitOrderNumber),
      placeOfConsumptionRequirement: this.model.pocRequirement!,
      webCreateDefaultAssigneeType: this.model.webCreateDefaultAssigneeType!,
      helpdeskEnabled: this.model.helpdeskEnabled,
      setCustomerOwnerUserAfterFinish: this.model.setCustomerOwnerUserAfterFinish,
      setCustomerOwnerUserGroupAfterFinish: this.model.setCustomerOwnerUserGroupAfterFinish,
      submittedFilters: this.model.submittedFilters
        .map(f => ({userGroupId: f.userGroupId!, days: Models.parseNumber(f.days)!})),
      inProgressSignatureEnabled: this.model.inProgressSignatureEnabled,
      smsRatingEnabled:
        this.configService.getConfiguration().feature_flags.sms_futar_enabled
          ? this.model.smsRatingEnabled
          : false,
      smsRatingDeliveryScheduleMin:
        this.configService.getConfiguration().feature_flags.sms_futar_enabled && this.model.smsRatingEnabled
          ? Models.parseNumber(this.model.smsRatingDeliveryScheduleMin)
          : undefined,
      smsRatingTemplateId:
        this.configService.getConfiguration().feature_flags.sms_futar_enabled && this.model.smsRatingEnabled
          ? this.model.smsRatingTemplateId
          : undefined,
      stateFilters: this.model.stateFilters
        .map(f => ({userGroupId: f.userGroupId!, states: f.rawStates})),
      adminEditableStates: this.model.adminEditableStates.map(s => s.id),
      enabledAssignees: {
        userGroups: this.model.enabledUserGroupIds
      },
      taskRecordColor: this.model.color
    }).subscribe(
      (response: EmptyMessage) => {
        LoadingHandler.getInstance().refresh();
        this.uiRouter.stateService.go(StateName.TASK_LIST);
      },
      (error: any) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withForm(this.fForm);
          this.showValidationErrorToast();
        }
      }
    );
  }

  private showValidationErrorToast() {
    this.toasterService.pop({
      timeout: UiConstants.ToastTimeoutLong,
      type: UiConstants.toastTypeError,
      title: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_TITLE),
      body: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_MESSAGE)
    });
  }

  loadResources() {
    combineLatest(
      this.surveyService.query({
        disabled: false,
        orders: Set.of({type: OrderType.ASC, field: Survey.OrderField.NAME})
      }),
      this.userGroupService.query({
        disabled: false,
        order: Services.createOrderFieldParameter(UserGroup.Keys.toOrderFieldKey, Set.of(UserGroup.DEFAULT_ORDER))
      }),
      this.invoiceSettingsService.query({
        disabled: false,
        invoiceDirectionType: 'OUTBOUND',
        orders: Set.of({field: InvoiceSettings.OrderField.PROFILE_NAME, type: OrderType.ASC}),
        paging: {
          numberOfItems: UiConstants.autocompletePageSize,
          pageNumber: 1
        }
      }),
      (
        surveys: QueryResult<Survey.Survey>,
        userGroups: ResourceQueryResult<UserGroup>,
        invoiceSettings: QueryResult<InvoiceSettings.InvoiceSettings>
      ) => {
        return {surveys: surveys.items, userGroups: userGroups, invoiceSettings: invoiceSettings.items};
      }
    ).subscribe(result => {
      this.surveys = [];
      result.surveys.forEach(survey => {
        if (survey) {
          this.surveys.push({id: survey.id, itemName: survey.name, itemSubtitle: survey.externalId});
        }
      });
      this.userGroups = [];
      result.userGroups.items.forEach(ug => {
        if (ug) {
          this.userGroups.push({id: ug.id, itemName: ug.name});
        }
      });
      this.invoiceSettings = [];
      result.invoiceSettings.forEach(is => {
        if (is) {
          this.invoiceSettings.push({id: is.id, itemName: is.profileName, integrationType: is.invoiceIntegrationType});
        }
      });
      const newObject = TaskRecordStateMachine.taskRecordStates.get('NEW');
      const newItem = {
        id: newObject.state,
        itemName: newObject.stringKey
      };
      this.defaultStateOptions.push(newItem);
      const openObject = TaskRecordStateMachine.taskRecordStates.get('OPEN');
      const openItem = {
        id: openObject.state,
        itemName: openObject.stringKey
      };
      this.defaultStateOptions.push(openItem);
      this.loadEditableStateOptions();
      this.loadModel();
    })
  }

  private loadEditableStateOptions() {
    this.editableStateOptions = [];
    TaskRecordStateMachine.taskRecordStates.filter(s => s !== undefined && s.isPresentable && s.state !== 'PENDING_APPROVAL')
      .forEach(s => {
        if (s) {
          this.editableStateOptions.push({
            id: s.state,
            itemName: s.stringKey
          });
        }
      });
  }

  pictureUploadChanged() {
    this.model.minNumOfPics = '';
    this.model.maxNumOfPics = '';
    this.model.showPicturesOnForm = false;
    this.model.photoConfirmDialogEnabled = false;
    this.removeFieldError(Task.ValidatedField.MIN_NUM_OF_PICS);
    if (this.model.pictureUpload) {
      this.model.photoEnabledInStates = droidStates.filter(s => s.id === 'IN_PROGRESS');
    }
    else {
      this.model.photoEnabledInStates = [];
    }
  }

  timeTrackingEnabledChanged() {
    this.model.defaultEstimatedTimeInMinutes = '';
    this.model.collaboratorTimeTrackingEnabled = false;
  }

  geofencingEnabledChanged() {
    this.model.geofencingRadiusInMeters = this.configService.getConfiguration().feature_flags.geofencing_default_radius_in_meters;
  }

  mileageRecordEnabledChanged() {
    this.model.mileageRecordRequired = false;
  }

  hasDefaultDeadlineChanged() {
    this.model.hasDefaultDeadline = !this.model.hasDefaultDeadline;
  }

  defaultStateForceNewChanged() {
    this.model.defaultStateForceNew = !this.model.defaultStateForceNew;
    if (this.model.defaultStateForceNew) {
      this.model.defaultState = this.defaultStateOptions.filter(s => s.id === 'NEW');
    }
  }

  onDefaultDeadlineTypeChange(value) {
    this.model.defaultDeadlineType = value;
  }

  hasLocalFieldError(field?: NgModel): boolean {
    return this.validatedInputs.hasLocalError(field);
  }

  hasFieldError(field?: Task.ValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: Task.ValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: Task.ValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  back() {
    window.history.back();
  }

  private loadLocalFieldValidationErrors() {
    const validatedInputs = List.of(this.nameInput, this.defaultEstimatedTimeInMinutesInput, this.geofencingRadiusInMeters,
      this.validInvoicePaymentTypesInput, this.generatingInvoicePaymentTypesInput, this.externalIdInput, this.offset, this.defaultState,
      this.validationTypeInput,
      this.printInvoiceCount, this.externalIdTagInput, this.taskRecordNameTemplate);
    this.validatedInputs = LocalFieldValidationErrorsFactory.ofFormFields(this.fForm, validatedInputs);
  }

  private loadModel() {
    this.taskService.get({
      taskId: this.taskId
    }).subscribe(
      (task: Task.Task) => {
        this.breadcrumbSelf = task.name;
        this.model.name = task.name;
        this.model.description = task.description ? task.description : '';
        this.model.validationType = [this.findValidationTypeItem(task.validationType)];
        this.model.defaultDeadlineType = this.findDefaultDeadlineTypeItem(task.defaultDeadline);
        this.model.externalId = task.externalId ? task.externalId : '';
        this.model._customerSignature = this.findSignatureValidationTypeItem(task.customerSignature);
        this.model._userSignature = this.findSignatureValidationTypeItem(task.userSignature);
        this.model.pictureUpload = task.pictureUpload;
        this.model.minNumOfPics = Models.numberToString(task.minNumOfPics);
        this.model.maxNumOfPics = Models.numberToString(task.maxNumOfPics);
        this.model.customerAttachmentEnabled = task.customerAttachmentEnabled;
        this.model.parallelWorkRejected = task.parallelWorkRejected;
        this.model.taskTimeTrackingType = task.timeTrackingEnabled
          ? TaskTimeTrackingType.AUTOMATIC
          : task.manualTimeTrackingEnabled
            ? TaskTimeTrackingType.MANUAL
            : TaskTimeTrackingType.NONE;
        if (task.manualTimeTrackingEnabled) {
          this.model.manualTimeTrackingRequired = task.manualTimeTrackingRequired ? task.manualTimeTrackingRequired : false;
          this.model.manualTimeTrackingType = task.manualTimeTrackingType ? task.manualTimeTrackingType : TaskResource.TaskManualTimeTrackingType.WEEK;
        }
        this.model.collaboratorTimeTrackingEnabled = task.collaboratorTimeTrackingEnabled;
        this.model.defaultEstimatedTimeInMinutes = Models.numberToString(task.defaultEstimatedTimeInMinutes);
        this.model.mileageRecordEnabled = task.mileageRecordEnabled;
        this.model.mileageRecordRequired = task.mileageRecordRequired;
        this.model.geofencingEnabled = task.geofencingEnabled;
        this.model.geofencingRadiusInMeters = task.geofencingRadiusInMeters ?
          task.geofencingRadiusInMeters : this.model.geofencingRadiusInMeters;
        this.model.showPicturesOnForm = task.showPicturesOnForm;
        this.model.photoConfirmDialogEnabled = task.photoConfirmDialogEnabled;
        this.model.photoEnabledInStatesSet = task.photoEnabledInStates;
        this.model.pdfTemplateId = task.pdfTemplateId;
        this.model.icon = task.icon;
        if (task.autoInvoiceGenerate) {
          this.model.taskInvoiceGenerationType = TaskInvoiceGenerationType.AUTOMATIC;
        }
        else if (task.manualInvoiceEnabled) {
          this.model.taskInvoiceGenerationType = TaskInvoiceGenerationType.MANUAL;
        }
        if (task.invoiceSettingsId) {
          this.loadInvoiceSettings(task.invoiceSettingsId);
        }
        if (task.invoiceBookId) {
          this.loadInvoiceBooks(undefined, task.invoiceBookId);
        }
        if (task.invoiceTagId) {
          this.invoiceTagMultiselectProvider.getById(task.invoiceTagId).subscribe(tag => {
            this.model.invoiceTag = [tag];
          });
        }
        this.model.printInvoiceCount = Models.numberToString(task.printInvoiceCount);
        this.model.validInvoicePaymentTypes = task.validInvoicePaymentTypes;
        this.model.generatingInvoicePaymentTypes = task.generatingInvoicePaymentTypes;
        this.loadSurveys(task.defaultLinkedSurveyIds);
        this.loadUserGroups(task.creatorUserGroups, task.enabledAssignees, task.submittedFilters, task.stateFilters);
        if (task.defaultDeadline) {
          this.model.hasDefaultDeadline = true;
          this.model.offset = task.defaultDeadline.offset;
        }
        if (task.taskRecordExternalIdSequenceData) {
          this.model.withinYear = task.taskRecordExternalIdSequenceData.withinYear;
          this.model.taskRecordExternalIdSequence = task.taskRecordExternalIdSequenceData.template;
        }
        this.model.taskRecordNameTemplate = task.taskRecordNameTemplate ? task.taskRecordNameTemplate : '';
        this.model.color = task.taskRecordColor;
        this.model.useDefaultManagedFields = task.useDefaultManagedFields;
        this.model.managedFields = task.managedFields.toArray().map(f => {
          const stringKey = TaskRecord.taskRecordFields.find(field => field.field === f)!.stringKey;
          return {
            id: f,
            itemName: stringKey
          };
        });
        this.model.helpdeskManagedFields = task.helpdeskManagedFields ? task.helpdeskManagedFields.toArray().map(f => {
          const stringKey = TaskRecord.taskRecordFields.find(field => field.field === f)!.stringKey;
          return {
            id: f,
            itemName: stringKey
          };
        }) : [];
        this.model.requiredFields = task.requiredFields.toArray().map(f => {
          const stringKey = TaskRecord.taskRecordRequirableFields.find(field => field.field === f)!.stringKey;
          return {
            id: f,
            itemName: stringKey
          };
        });
        this.model.helpdeskRequiredFields = task.helpdeskRequiredFields ? task.helpdeskRequiredFields.toArray().map(f => {
          const stringKey = TaskRecord.taskRecordRequirableFields.find(field => field.field === f)!.stringKey;
          return {
            id: f,
            itemName: stringKey
          };
        }) : [];
        this.model.showOnDashboard = task.showOnDashboard;
        this.model.explicitOrderNumber = Models.numberToString(task.explicitOrderNumber);
        this.model.defaultStateForceNew = task.defaultStateForceNew;
        if (!this.model.defaultStateForceNew) {
          this.model.defaultState.push(this.defaultStateOptions.find(o => o.id === task.defaultState)!);
        }
        else {
          this.model.defaultState.push(this.defaultStateOptions.find(o => o.id === 'NEW')!);
        }
        this.model._pocRequirement.push(this.pocRequirements.find(o => o.id === task.placeOfConsumptionRequirement)!);
        this.model._webCreateDefaultAssigneeType.push(this.defaultAssigneeTypes.find(o => o.id === task.webCreateDefaultAssigneeType)!);
        this.model.helpdeskEnabled = task.helpdeskEnabled;
        this.model.setCustomerOwnerUserAfterFinish = task.setCustomerOwnerUserAfterFinish;
        this.model.setCustomerOwnerUserGroupAfterFinish = task.setCustomerOwnerUserGroupAfterFinish;
        this.model.smsRatingEnabled = task.smsRatingEnabled;
        this.model.inProgressSignatureEnabled = task.inProgressSignatureEnabled;
        this.model.smsRatingDeliveryScheduleMin = Models.numberToString(task.smsRatingDeliveryScheduleMin);
        this.loadMessageTemplates(undefined, task.smsRatingTemplateId);
        this.model.adminEditableStates = [];
        this.model.adminEditableStates.push(...this.editableStateOptions.filter(o => {
          let idx = -1;
          if (o) {
            idx = task.adminEditableStates.findIndex(s => s === o.id);
          }
          return idx >= 0;
        }));
      });
  }

  openImportDialog() {
    TaskImportDialogComponent.openDialog(this.dialog, {
      taskId: this.taskId
    }, result => {
      if (result && result.taskId) {
        this.uiRouter.stateService.reload();
      }
    });
  }

  exportTaskJson() {
    TaskExportDialogComponent.openDialog(this.dialog, {
      taskId: this.taskId
    }, result => {
    });
  }

  private findValidationTypeItem(validationType: TaskResource.TaskValidationType) {
    return this.validationTypeItems.filter((item) => {
      return validationType === item.id;
    })[0];
  }

  private findSignatureValidationTypeItem(validationType: TaskResource.TaskSignatureValidationType) {
    return this.signatureValidationTypeItems.filter((item) => {
      return validationType === item.id;
    });
  }

  private findDefaultDeadlineTypeItem(taskDefaultDeadline?: Task.TaskDefaultDeadline) {
    if (taskDefaultDeadline) {
      return this.defaultDeadlineTypeItems.filter((item) => {
        return taskDefaultDeadline.dayType === item.id;
      })[0];
    }
    return this.defaultDeadlineTypeItems[0];
  }

  private loadInvoiceSettings(defaultInvoiceSettingsId: number) {
    this.model.invoiceSettings = [];
    const found = this.invoiceSettings.find(s => s.id === defaultInvoiceSettingsId);
    if (found) {
      this.model.invoiceSettings.push(found);
    }
    else {
      this.invoiceSettingsService.get({
        id: defaultInvoiceSettingsId
      }).subscribe((result: InvoiceSettings.InvoiceSettings) => {
        this.model.invoiceSettings.push({
          id: result.id,
          itemName: result.profileName,
          disabled: result.disabled,
          integrationType: result.invoiceIntegrationType
        });
      });
    }
  }

  searchInvoiceSettings(q?: any) {
    this.invoiceSettingsService.query({
        invoiceDirectionType: 'OUTBOUND',
        profileName: q ? Strings.undefinedOrNonEmpty(q.target.value) : undefined,
        disabled: false,
        paging: {
          pageNumber: 1,
          numberOfItems: 30
        },
        orders: Set.of({field: InvoiceSettings.OrderField.PROFILE_NAME, type: OrderType.ASC}),
        noProgressBar: true
      }
    ).subscribe((result: QueryResult<InvoiceSettings.InvoiceSettings>) => {
      this.invoiceSettings = [];
      result.items.forEach((s) => {
        this.invoiceSettings.push({
          id: s!.id,
          itemName: s!.profileName,
          integrationType: s!.invoiceIntegrationType
        });
      });
    });
  }

  loadInvoiceBooks(q?: string, defaultInvoiceBookId?: number) {
    this.invoiceBookService.query({
        prefix: q ? Strings.undefinedOrNonEmpty(q) : undefined,
        invoiceSettingsIds: Set.of(this.model.invoiceSettingsId!),
        disabled: false,
        paging: {
          pageNumber: 1,
          numberOfItems: 30
        },
        orders: Set.of({field: InvoiceBook.OrderField.PREFIX, type: OrderType.ASC}),
        noProgressBar: true
      }
    ).subscribe((result: QueryResult<InvoiceBook.InvoiceBook>) => {
      this.invoiceBooks = [];
      result.items.forEach((b) => {
        this.invoiceBooks.push({
          id: b!.id,
          itemName: b!.prefix
        });
      });
      if (defaultInvoiceBookId) {
        const item = this.invoiceBooks.find((b) => b.id === defaultInvoiceBookId);
        if (item) {
          this.model.invoiceBook.push(item);
        }
        else {
          this.invoiceBookService.get({id: defaultInvoiceBookId}).subscribe(result => {
            this.model.invoiceBook.push({
              id: result.id,
              itemName: result.prefix,
              disabled: result.disabled
            });
          });
        }
      }
    });
  }

  onInvoiceTagSearch(q?: string) {
    if (this.rightModel.invoiceTagRead.hasRight()) {
      this.invoiceTagMultiselectProvider.loadActive(q).subscribe(tags => {
        this.invoiceTags = tags;
      });
    }
  }


  private loadSurveys(defaultLinkedSurveyIds: Set<number>) {
    this.model.defaultLinkedSurveys = [];
    const notFoundSurveyIds: number[] = [];
    defaultLinkedSurveyIds.toArray().forEach((id) => {
      const survey = this.surveys.find((s) => s.id === id);
      if (survey) {
        this.model.defaultLinkedSurveys.push(survey);
      }
      else {
        notFoundSurveyIds.push(id);
      }
    });
    if (notFoundSurveyIds.length > 0) {
      this.surveyService.query({
        surveyIdSet: Set.of(...notFoundSurveyIds)
      }).subscribe((result: QueryResult<Survey.Survey>) => {
        result.items.forEach((survey) => {
          if (survey) {
            const s: MultiselectOptionItem<number> = {
              id: survey.id,
              itemName: survey.name,
              disabled: survey.disabled
            };
            if (s.disabled) {
              this.model.defaultLinkedSurveys.unshift(s);
            }
            else {
              this.model.defaultLinkedSurveys.push(s);
            }
          }
        });
      });
    }
  }

  searchSurveys(q?: any) {
    this.surveyService.query({
        name: q ? Strings.undefinedOrNonEmpty(q.target.value) : undefined,
        disabled: false,
        paging: {
          pageNumber: 1,
          numberOfItems: 30
        },
        orders: Set.of({field: Survey.OrderField.NAME, type: OrderType.ASC}),
        noProgressBar: true
      }
    ).subscribe((surveys: QueryResult<Survey.Survey>) => {
      this.surveys = [];
      surveys.items.forEach((s) => {
        this.surveys.push({
          id: s!.id,
          itemName: s!.name
        });
      });
    });
  }

  private loadUserGroups(creatorUserGroups: Set<number>,
                         enabledAssignees: Task.EnabledAssignees,
                         submittedFilters: SubmittedFilter[],
                         stateFilters: StateFilter[]) {
    this.model.creatorUserGroups = [];
    const notFoundUserGroupIds: number[] = [];
    creatorUserGroups.toArray().forEach((id) => {
      const userGroup = this.userGroups.find((ug) => ug.id === id);
      if (userGroup) {
        this.model.creatorUserGroups.push(userGroup);
      }
      else {
        notFoundUserGroupIds.push(id);
      }
    });
    this.model.enabledUserGroups = [];
    enabledAssignees.userGroups.toArray().forEach((id) => {
      const userGroup = this.userGroups.find((ug) => ug.id === id);
      if (userGroup) {
        this.model.enabledUserGroups.push(userGroup);
      }
      else {
        notFoundUserGroupIds.push(id);
      }
    });
    submittedFilters.forEach(f => {
      const userGroup = this.userGroups.find((ug) => ug.id === f.userGroupId);
      if (userGroup) {
        const model = new SubmittedFilterModel();
        model.userGroup.push(userGroup);
        model.days = Models.numberToString(f.days);
        this.model.submittedFilters.push(model);
      }
      else {
        notFoundUserGroupIds.push(f.userGroupId);
      }
    });
    stateFilters.forEach(f => {
      const userGroup = this.userGroups.find((ug) => ug.id === f.userGroupId);
      if (userGroup) {
        const model = new StateFilterModel();
        model.userGroup.push(userGroup);
        model.states = f.states.toArray().map(s => this.states.find(o => o.id === s)!);
        this.model.stateFilters.push(model);
      }
      else {
        notFoundUserGroupIds.push(f.userGroupId);
      }
    });
    if (notFoundUserGroupIds.length > 0) {
      this.userGroupService.query({
        id: notFoundUserGroupIds.join(','),
        order: Services.createOrderFieldParameter(UserGroup.Keys.toOrderFieldKey, Set.of(UserGroup.DEFAULT_ORDER))
      }).subscribe((result: ResourceQueryResult<UserGroup>) => {
        result.items.forEach((userGroup) => {
          const ug: MultiselectOptionItem<number> = {
            id: userGroup.id,
            itemName: userGroup.name,
            disabled: userGroup.disabled
          };
          if (creatorUserGroups.includes(ug.id)) {
            if (ug.disabled) {
              this.model.creatorUserGroups.unshift(ug);
            }
            else {
              this.model.creatorUserGroups.push(ug);
            }
          }
          if (enabledAssignees.userGroups.includes(ug.id)) {
            if (ug.disabled) {
              this.model.enabledUserGroups.unshift(ug);
            }
            else {
              this.model.enabledUserGroups.push(ug);
            }
          }
          if (submittedFilters.map(f => f.userGroupId).includes(ug.id)) {
            const filter = submittedFilters.find(f => f.userGroupId === ug.id);
            if (filter) {
              const model = new SubmittedFilterModel();
              model.userGroup.push(ug);
              model.days = Models.numberToString(filter.days);
              this.model.submittedFilters.push(model);
            }
          }
          if (stateFilters.map(f => f.userGroupId).includes(ug.id)) {
            const filter = stateFilters.find(f => f.userGroupId === ug.id);
            if (filter) {
              const model = new StateFilterModel();
              model.userGroup.push(ug);
              model.states = filter.states.toArray().map(s => this.states.find(o => o.id === s)!);
              this.model.stateFilters.push(model);
            }
          }
        });
      });
    }
  }

  searchUserGroups(q?: any) {
    this.userGroupService.query({
        name: q ? Strings.undefinedOrNonEmpty(q.target.value) : undefined,
        disabled: false,
        page_number: 1,
        number_of_items: 30,
        order: Services.createOrderFieldParameter(UserGroup.Keys.toOrderFieldKey, Set.of(UserGroup.DEFAULT_ORDER)),
        no_progress_bar: true
      }
    ).subscribe((ugs: ResourceQueryResult<UserGroup>) => {
      this.userGroups = [];
      ugs.items.forEach((ug) => {
        this.userGroups.push({
          id: ug.id,
          itemName: ug.name
        });
      });
    });
  }

  onTriggerCreated() {
    this.triggerList.onTriggerCreated();
  }

  getTaskService(): TaskService {
    return this.taskService;
  }

  onInvoiceGenerationTypeChanged() {
    if (this.model.taskInvoiceGenerationType !== TaskInvoiceGenerationType.AUTOMATIC) {
      this.model._validInvoicePaymentTypes = [];
      this.model._generatingInvoicePaymentTypes = [];
      this.model.invoiceSettings = [];
      this.model.invoiceBook = [];
      this.model.printInvoiceCount = '';
    }
  }

  onInvoiceSettingsChanged() {
    this.loadInvoiceBooks();
    this.model.invoiceBook = [];
    if (this.invoiceBookInput) {
      this.invoiceBookInput.control.updateValueAndValidity();
    }
  }

  addSubmittedFilter() {
    this.model.submittedFilters.push(new SubmittedFilterModel());
  }

  deleteSubmittedFilter(i: number) {
    this.model.submittedFilters.splice(i, 1);
  }

  addStateFilter() {
    this.model.stateFilters.push(new StateFilterModel());
  }

  deleteStateFilter(i: number) {
    this.model.stateFilters.splice(i, 1);
  }

  loadMessageTemplates(q?: string, id?: number) {
    this.messageTemplateService.query({
      name: Strings.undefinedOrNonEmpty(q),
      disabled: false,
      orders: Set.of({field: MessageTemplate.OrderField.NAME, type: OrderType.ASC}),
      paging: {
        pageNumber: 1,
        numberOfItems: UiConstants.autocompletePageSize
      },
      noProgressBar: true
    }).subscribe(result => {
      this.messageTemplates = result.items.toArray().map(t => ({id: t.id, itemName: t.name, itemSubtitle: t.externalId}))
      if (id) {
        const item = this.messageTemplates.find(t => t.id === id);
        if (item) {
          this.model.smsRatingTemplate.push(item);
        }
        else {
          this.messageTemplateService.get({
            id: id
          }).subscribe(result => {
            this.model.smsRatingTemplate.push({
              id: result.id,
              itemName: result.name,
              itemSubtitle: result.externalId,
              disabled: result.disabled
            })
          });
        }
      }
    });
  }

  onManagedFieldsChanged() {
    this.model.requiredFields = this.model.requiredFields.filter(f => this.model.managedFields.map(f => f.id).includes(f.id));
  }

  onHelpdeskManagedFieldsChanged() {
    this.model.helpdeskRequiredFields
      = this.model.helpdeskRequiredFields.filter(f => this.model.helpdeskManagedFields.map(f => f.id).includes(f.id));
  }

  private getManualTimeTrackingRequired() {
    let manualTimeTrackingRequired: boolean | undefined;
    if (this.model.taskTimeTrackingType === TaskTimeTrackingType.MANUAL) {
      manualTimeTrackingRequired = this.model.manualTimeTrackingRequired;
    }
    return manualTimeTrackingRequired;
  }

  private getManualTimeTrackingType() {
    let manualTimeTrackingType: TaskResource.TaskManualTimeTrackingType | undefined;
    if (this.model.taskTimeTrackingType === TaskTimeTrackingType.MANUAL) {
      manualTimeTrackingType = this.model.manualTimeTrackingType;
    }
    return manualTimeTrackingType;
  }
}

export class StateSelect {
  id: TaskRecordStateMachine.State;
  itemName: string;
}
