import { DqlQuery, Query } from '../field';

export class Workflow {

  readonly id: Query.OrderField;
  readonly externalId: Query.OrderField;
  readonly version: Query.OrderField;
  readonly name: Query.OrderField;
  readonly creationTime: Query.OrderField;
  readonly updateTime: Query.OrderField;

  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this.id = new DqlQuery.OrderField(prefix + 'id');
    this.externalId = new DqlQuery.OrderField(prefix + 'external_id');
    this.version = new DqlQuery.OrderField(prefix + 'version');
    this.name = new DqlQuery.OrderField(prefix + 'name');
    this.creationTime = new DqlQuery.OrderField(prefix + 'creation_time');
    this.updateTime = new DqlQuery.OrderField(prefix + 'update_time');
  }

}
