/* eslint-disable */
import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Transition, UIRouter} from '@uirouter/angular';
import {combineLatest, Observable} from 'rxjs';
import {TaskRecord, TaskRecordService} from '../../../../lib/task/task-record.service';
import {TaskRecordBase} from '../task-record-base/task-record-base-view';
import {TaskRecordBaseComponent} from '../task-record-base/task-record-base.component';
import {FormRecordContainer} from '../../../form/form-record/form-record-container';
import {FormRecordContainerComponent} from '../../../form/form-record/form-record-container.component';
import {Form} from '../../../../lib/form/form.service';
import {ConfigurationService,} from '../../../../lib/core-ext/configuration.service';
import {Task, TaskService} from '../../../../lib/task/task.service';
import {EmptyMessage, IdentityMessage} from '../../../../lib/util/messages';
import {Set} from 'immutable';
import {
  AssigneeDeviceItem,
  AssigneeItem,
  AssigneeModificationModel,
  ConfigModel,
  TaskRecordEmailRecipientModel,
  TaskRecordRightModel,
  TaskRecordStateItem
} from '../../../../util/task-record-utils';
import {StringKey} from '../../../../app.string-keys';
import {BodyOutputType, ToasterService} from '../../../../fork/angular2-toaster/angular2-toaster';
import {TranslateService} from '@ngx-translate/core';
import {TaskRecordStateMachine} from '../../../../lib/task/task-record-statemachine';
import {Country, CountryService} from '../../../../lib/country.service';
import {QueryResult, ResourceQueryResult, Services} from '../../../../lib/util/services';
import {AddressModel} from '../../../../lib/address';
import {UiConstants} from '../../../../util/core-utils';
import {StateName} from '../../../../app.state-names';
import {BreadcrumbParent} from '../../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import {TaskRecordSurveyComponent} from '../task-record-survey/task-record-survey.component';
import {GrantedPermissionSetResolver, RightResolver, RightService} from '../../../../lib/right.service';
import {RightModel} from '../../../../app.rights';
import {ChatService} from '../../../../shared/chat/chat-service.interface';
import {TaskRecordSubTaskComponent} from '../task-record-sub-task/task-record-sub-task.component';
import {
  TaskRecordSubTaskCreateEditDialogComponent
} from '../task-record-sub-task/task-record-sub-task-create-edit-dialog/task-record-sub-task-create-edit-dialog.component';
import {
  TaskRecordSubTaskDetailDialogComponent
} from '../task-record-sub-task/task-record-sub-task-detail-dialog/task-record-sub-task-detail-dialog.component';
import {Worklog} from '../../../../lib/worklog/worklog.service';
import {
  TaskRecordInvoiceCreateDialogComponent
} from '../task-record-invoice-create-dialog/task-record-invoice-create-dialog.component';
import {WorklogListComponent} from '../../../worklog/worklog-list/worklog-list.component';
import {TaskRecordMileageRecordComponent} from '../task-record-mileage-record/task-record-mileage-record.component';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {TabAnchors} from '../../../../shared/tab/tab-anchors';
import {AppTypeHelperService} from '../../../../lib/util/app-type-helper.service';
import {TabSelectService} from '../../../../lib/util/tab-select.service';
import {Strings} from '../../../../lib/util/strings';
import {User, UserService} from '../../../../lib/user.service';
import {Device, DeviceManagementService} from '../../../../lib/device-management.service';
import {Angular2Multiselects} from '../../../../util/multiselect';
import {BulkCloneResult} from '../task-record-bulk-clone-dialog/task-record-bulk-clone-dialog.component';
import {ErrorMessage} from '../../../../lib/error-message-parser.service';
import {OperationRights} from '../../../../app.right-definitions';
import {UserMeService} from '../../../../lib/user/user-me.service';
import {TaskRightModel} from '../../../../util/task-utils';
import {TaskRecordFormFieldUpdater} from '../task-record-base/task-record-form-field-updater';
import {MatDialog} from "@angular/material/dialog";
import {
  TaskRecordStateChangeDialogComponent
} from "../task-record-state-change-dialog/task-record-state-change-dialog.component";

/* eslint-enable */

@Component({
  selector: 'app-new-task-record-detail',
  templateUrl: 'task-record-detail.component.html',
  styleUrls: ['task-record-detail.component.scss'],
})
export class TaskRecordDetailComponent extends TabAnchors implements OnInit, AfterViewInit {
  UiConstants = UiConstants;
  WorklogScope = Worklog.WorklogScope;

  @ViewChild(TaskRecordBaseComponent, {static: true})
  baseView: TaskRecordBase.View;

  @ViewChild(FormRecordContainerComponent, {static: true})
  formRecordContainerView: FormRecordContainer.View;

  @ViewChild('f', {static: true})
  fForm: NgForm;

  @ViewChild('sendMessageDialog')
  sendMessageDialog: ModalDirective;
  sendMessageDialogVisible: boolean = false;
  newMessage: string = '';
  messageSubmitted: boolean = false;

  @ViewChild('assigneeModificationDialog', {static: true})
  assigneeModificationDialog: ModalDirective;
  assigneeModificationDialogVisible: boolean = false;
  assigneeModificationModel: AssigneeModificationModel = new AssigneeModificationModel();

  forceSaveButton: boolean = false;

  mileageRecordEnabled: boolean = false;

  @ViewChild('surveyView') surveyView: TaskRecordSurveyComponent;

  @ViewChild('taskRecordSubTask') taskRecordSubTask: TaskRecordSubTaskComponent;

  @ViewChild('taskRecordSubTaskCreateEditDialog', {static: true})
  taskRecordSubTaskCreateEditDialog: TaskRecordSubTaskCreateEditDialogComponent;

  @ViewChild('taskRecordSubTaskDetailDialog', {static: true}) taskRecordSubTaskDetailDialog: TaskRecordSubTaskDetailDialogComponent;

  @ViewChild('taskRecordSubTaskDeleteDialog', {static: true}) taskRecordSubTaskDeleteDialog: TaskRecordSubTaskDetailDialogComponent;

  @ViewChild('taskRecordInvoiceCreateDialog', {static: true}) taskRecordInvoiceCreateDialog: TaskRecordInvoiceCreateDialogComponent;

  @ViewChild('worklogList') worklogList: WorklogListComponent;

  @ViewChild('mileageRecordList') mileageRecordList: TaskRecordMileageRecordComponent;

  emailModel: TaskRecordEmailRecipientModel = new TaskRecordEmailRecipientModel();

  taskId: number;
  taskName: string;
  task?: Task.Task;
  taskRecordName: string;
  taskRecordId: number;
  customerRecordId?: number;
  taskRecordState: TaskRecordStateMachine.State;
  settableStateList: TaskRecordStateItem[] = [];
  combinedResult?: CombinedResult;
  breadcrumbParents: BreadcrumbParent[] = [];
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  rightModel: RightModel = RightModel.empty();
  grantedRights: TaskRecordRightModel = new TaskRecordRightModel(GrantedPermissionSetResolver.empty());
  taskGrantedRights: TaskRightModel = new TaskRightModel(GrantedPermissionSetResolver.empty());

  config: ConfigModel = new ConfigModel();

  devices: AssigneeDeviceItem[] = [];
  users: AssigneeItem[] = [];
  dropdownSettingsWithRemoteSearch: Angular2Multiselects.Settings;

  private formFieldUpdater: TaskRecordFormFieldUpdater;

  constructor(public configurationService: ConfigurationService,
              private dialog: MatDialog,
              private taskService: TaskService,
              private taskRecordService: TaskRecordService,
              private uiRouter: UIRouter,
              private rightService: RightService,
              private transition: Transition,
              private userService: UserService,
              private userMeService: UserMeService,
              private deviceManagementService: DeviceManagementService,
              private toasterService: ToasterService,
              private countryService: CountryService,
              public appTypeHelperService: AppTypeHelperService,
              private tabSelectService: TabSelectService,
              private translateService: TranslateService) {
    super(transition, tabSelectService);
    this.taskId = this.transition.params().taskId;
    this.taskRecordId = this.transition.params().taskRecordId;
  }

  ngOnInit() {
    this.loadConfig();
    this.initDropdownSettings();
  }

  ngAfterViewInit(): void {
    this.loadRightModels(() => {
      this.resolveAnchor();
      this.load();
    });
  }

  private loadConfig() {
    this.config = this.configurationService.getConfigurationModel();
  }

  private initDropdownSettings() {
    this.dropdownSettingsWithRemoteSearch = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
  }

  isTaskRecordManagedField(field): boolean {
    return this.baseView.isManagedField(field);
  }

  get assigneeMatch() {
    if (this.combinedResult) {
      return this.combinedResult.taskRecord.assignee?.userId === this.combinedResult.currentUser.id;
    }
    return false;
  }

  canModify(state: TaskRecordStateMachine.State): boolean {
    const inState = TaskRecordStateMachine.StateMachine.canEdit(this.combinedResult?.task, state);
    if (inState && state === 'IN_PROGRESS') {
      return this.assigneeMatch;
    }
    return inState;
  }

  canValidate(fromState: TaskRecordStateMachine.State): boolean {
    return fromState === 'SUBMITTED' && TaskRecordStateMachine.StateMachine.canChangeState(fromState, 'FINISHED')
      && TaskRecordStateMachine.StateMachine.hasGrantedRightForStateChange(this.grantedRights, fromState, 'FINISHED');
  }

  canInvalidate(fromState: TaskRecordStateMachine.State): boolean {
    return fromState === 'SUBMITTED' && this.grantedRights.changeStateUnsubmit.hasRight();
  }

  setBugReportState(state: TaskRecordStateMachine.State, id: number) {
    this.taskRecordService.setState({
      taskRecordIdSet: Set.of(...[id]),
      newState: TaskRecordStateMachine.taskRecordStates.get(state)
    }).subscribe(result => {
      this.uiRouter.stateService.go(StateName.TASK_RECORD_LIST, {taskId: this.taskId});
    });
  }

  private loadRightModels(completion: () => void) {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        completion();
      }
    );
  }

  load() {
    this.createCombinedObservable().subscribe(
      (result: CombinedResult) => {
        this.formFieldUpdater = new TaskRecordFormFieldUpdater(result.form, this.formRecordContainerView);
        this.taskRecordState = result.taskRecord.state;
        this.grantedRights = new TaskRecordRightModel(GrantedPermissionSetResolver.byGrantedRights(result.taskRecord.grantedRights));
        this.taskGrantedRights = new TaskRightModel(GrantedPermissionSetResolver.byGrantedRights(result.task.grantedRights));
        this.taskName = result.task.name;
        this.task = result.task;
        this.taskRecordName = result.taskRecord.name;
        this.customerRecordId = result.taskRecord.customerRecord ? result.taskRecord.customerRecord.customerRecordId : undefined;
        this.baseView.loadTaskRecord({
          taskRecord: result.taskRecord,
          task: result.task,
          countryItems: AddressModel.CountryItem.fromCountryList(result.countries.items),
          rightModel: this.rightModel,
          grantedRights: this.grantedRights
        });
        this.formRecordContainerView.loadFormRecord({
          configuration: this.configurationService.getConfiguration(),
          form: result.form,
          record: result.taskRecord.formRecord,
        });
        this.combinedResult = result;
        this.mileageRecordEnabled = result.task.mileageRecordEnabled;
        this.forceSaveButton = !Form.Forms.hasVisibleGroup(result.form);
        this.breadcrumbParents = [];
        this.translateService.get('MENU_NAVBAR_FORMS').subscribe(
          (result: string) => {
            this.breadcrumbParents.push({name: result, uiSref: StateName.TASK_DASHBOARD});
          }
        );
        this.breadcrumbParents.push({
          name: this.taskName,
          uiSref: StateName.TASK_RECORD_LIST,
          uiParam: {taskId: this.taskId}
        });
        if (this.rightModel.surveyRead.hasRight()) {
          this.surveyView.taskRecord = result.taskRecord;
        }
      },
      () => {
      }
    );
  }

  nothing() {
  }

  private createCombinedObservable(): Observable<CombinedResult> {
    return combineLatest(
      this.taskService.get({
        taskId: this.taskId,
        rights: Set.of(OperationRights.TASK_RECORD_CREATE)
      }),
      this.taskRecordService.get({
        withFormRecord: true,
        taskId: this.taskId,
        taskRecordId: this.taskRecordId,
        customerFields: TaskRecordBase.customerQueryFields,
        rights: TaskRecordBase.rightRequest
      }),
      this.countryService.query({}),
      this.userMeService.loadMe(),
      (task: Task.Task,
       taskRecord: TaskRecord.TaskRecord,
       countries: QueryResult<Country.Country>,
       me: User) => {
        return {
          form: task.form!,
          task: task,
          taskRecord: taskRecord,
          countries: countries,
          currentUser: me
        }
      }
    );
  }

  preStateChange(newState: TaskRecordStateMachine.State) {
    const stateObject: TaskRecordStateMachine.StateObject | undefined =
      TaskRecordStateMachine.taskRecordStates.find((s) => s !== undefined && s.state === newState);
    if (stateObject) {
      if (stateObject.state === 'REVERTED' || stateObject.state === 'ARCHIVED') {
        TaskRecordStateChangeDialogComponent.openDialog(this.dialog, {
          newState: stateObject,
          ids: Set.of(this.taskRecordId)
        }, result => {
          if (result?.success) {
            if (this.config.navigateOnStateChange) {
              this.uiRouter.stateService.go(StateName.TASK_RECORD_LIST, {taskId: this.taskId});
            } else {
              this.reload();
            }
          }
        });
      } else {
        this.stateChange(stateObject);
      }
    }
  }

  stateChange(stateObject: TaskRecordStateMachine.StateObject) {
    this.taskRecordService.setState({
      taskRecordIdSet: Set.of(this.taskRecordId),
      newState: stateObject,
    }).subscribe((result: EmptyMessage) => {
        this.toasterService.pop({
          timeout: UiConstants.ToastTimeoutShort,
          type: UiConstants.toastTypeSuccess,
          title: this.translateService.instant(StringKey.TASKS_STATE_MODIFY),
          body: this.translateService.instant(StringKey.TASK_STATE_CHANGE_SUCCESS)
        });
        if (this.config.navigateOnStateChange) {
          this.uiRouter.stateService.go(StateName.TASK_RECORD_LIST, {taskId: this.taskId});
        } else {
          this.reload();
        }
      },
      (error: ErrorMessage | TaskRecord.TaskRecordInvoiceGeneratorException | TaskRecord.TaskRecordStockItemChangeException) => {
        let errorMessage: string = '';
        let errorTitleKey: string = StringKey.TASKS_STATE_MODIFY;
        if (error instanceof TaskRecord.TaskRecordInvoiceGeneratorException) {
          error.errors.forEach(value => {
            errorMessage += value.taskRecordId + ':<br/>';
            errorMessage += value.messages.join('<br/>');
            errorMessage += '<br/>';
          });
          errorMessage.trim();
        }
        if (error instanceof TaskRecord.TaskRecordStockItemChangeException) {
          errorTitleKey = StringKey.STOCK_ITEM_CHANGE_ERROR_TITLE;
          error.errors.forEach(value => {
            errorMessage += value.errors.map(e => this.translateService.instant(StringKey.STOCK_ITEM_CHANGE_ERROR, {
              taskRecordId: value.taskRecordId,
              stockRecordName: e.stockRecordName,
              newAmount: e.newAmount,
              currentAmount: e.currentAmount,
            }))
              .join('<br/>');
            errorMessage += '<br/>';
          });
          errorMessage.trim();
        }
        if (error instanceof ErrorMessage) {
          error.globalErrors.forEach(e => {
            errorMessage += e + '<br/>';
          });
          errorMessage.trim();
        }

        this.toasterService.pop({
          timeout: UiConstants.ToastTimeoutLong,
          type: UiConstants.toastTypeError,
          bodyOutputType: BodyOutputType.TrustedHtml,
          title: this.translateService.instant(errorTitleKey),
          body: errorMessage.length > 0 ? errorMessage : this.translateService.instant(StringKey.TASK_STATE_CHANGE_ERROR)
        });
      });
  }

  settableStateListLoader(settableStateList: TaskRecordStateItem[]) {
    this.settableStateList = settableStateList;
  }

  getChatService(): ChatService {
    return this.taskRecordService;
  }

  onRefresh() {
    this.load();
  }

  getTaskRecordService(): TaskRecordService {
    return this.taskRecordService;
  }

  openInvoiceCreateDialog() {
    this.taskRecordInvoiceCreateDialog.show();
  }

  onInvoiceCreateResult(success: boolean) {
    if (success) {
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutLong,
        type: UiConstants.toastTypeSuccess,
        title: this.translateService.instant(StringKey.TASK_RECORD_MANUAL_INVOICE_CREATE_SUCCESS_TITLE),
        body: this.translateService.instant(StringKey.TASK_RECORD_MANUAL_INVOICE_CREATE_SUCCESS_BODY)
      });
      this.load();
    } else {
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutLong,
        type: UiConstants.toastTypeError,
        title: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_TITLE),
        body: this.translateService.instant(StringKey.TASK_RECORD_MANUAL_INVOICE_CREATE_ERROR)
      });
    }
  }

  reloadWorklogList() {
    if (this.worklogList) {
      this.worklogList.loadList();
    }
  }

  openSendMessageDialog() {
    this.newMessage = '';
    this.messageSubmitted = false;
    this.sendMessageDialogVisible = true;
    this.sendMessageDialog.show();
  }

  closeSendMessageDialog() {
    this.sendMessageDialogVisible = false;
    this.sendMessageDialog.hide();
  }

  private sendMessage() {
    this.messageSubmitted = true;
    if (this.newMessage.length === 0) {
      return;
    }
    this.taskRecordService.sendMessage({
      parentId: this.taskId,
      id: this.taskRecordId,
      content: this.newMessage
    }).subscribe((result: IdentityMessage) => {
      this.closeSendMessageDialog()
    });
  }

  showAssigneeModificationDialog() {
    if (this.config.assigneeWithUser) {
      this.onAssigneeUserSearch();
    }
    if (this.config.assigneeWithMobileApp) {
      this.onDeviceSearch();
    }
    this.assigneeModificationDialogVisible = true;
    this.assigneeModificationDialog.show();
  }

  closeAssigneeModificationDialog() {
    this.assigneeModificationModel = new AssigneeModificationModel();
    this.assigneeModificationDialogVisible = false;
    this.assigneeModificationDialog.hide();
  }

  onAssigneeUserSearch(searchValue?: string) {
    const groupIds: number[] = this.combinedResult?.taskRecord.userGroupId && this.combinedResult?.taskRecord.userGroupId
      ? [this.combinedResult?.taskRecord.userGroupId]
      : [];

    if (groupIds.length === 0 && this.combinedResult?.task) {
      groupIds.push(...this.combinedResult?.task.enabledAssignees.userGroups.toArray());
    }
    this.userService.query({
      q: Strings.undefinedOrNonEmpty(searchValue),
      fields: 'id,user_name,person_name,disabled',
      disabled: false,
      number_of_items: UiConstants.autocompletePageSize,
      page_number: 1,
      user_group_ids: groupIds.length > 0 ? groupIds.join(',') : undefined,
      order: Services.createOrderFieldParameter(User.Keys.toOrderFieldKey, Set.of(User.DEFAULT_ORDER)),
      no_progress_bar: true,
    }).subscribe((users: ResourceQueryResult<User>) => {
      this.users = [];
      users.items.forEach(user => {
        const item = {
          id: user.id,
          itemName: user.person_name + ' (' + user.user_name + ')',
          disabled: user.disabled
        };
        this.users.push(item);
      });
    });
  }

  onDeviceSearch(searchValue?: string) {
    this.deviceManagementService.query({
      q: Strings.undefinedOrNonEmpty(searchValue),
      disabled: false,
      number_of_items: UiConstants.autocompletePageSize,
      page_number: 1,
      no_progress_bar: true
    }).subscribe((devices: ResourceQueryResult<Device>) => {
      this.devices = [];
      devices.items.forEach(device => {
        const item = {
          id: device.id,
          itemName: device.name ? device.name : device.application_id,
          appId: device.application_id,
          disabled: device.disabled,
        };
        this.devices.push(item);
      });
    });
  }

  changeAssigneeInProgress() {
    this.taskRecordService.changeAssigneeInProgress({
      taskId: this.taskId,
      taskRecordId: this.taskRecordId,
      userId: this.assigneeModificationModel.assigneeUser && this.assigneeModificationModel.assigneeUser.id
        ? this.assigneeModificationModel.assigneeUser.id
        : undefined,
      mobileApplicationId: this.assigneeModificationModel.assigneeMobileApp && this.assigneeModificationModel.assigneeMobileApp.id
        ? this.assigneeModificationModel.assigneeMobileApp.id
        : undefined
    }).subscribe(result => {
        this.toasterService.pop({
          timeout: UiConstants.ToastTimeoutShort,
          type: UiConstants.toastTypeSuccess,
          title: this.translateService.instant(StringKey.TASKS_DATA_MODIFY),
          body: this.translateService.instant(StringKey.TASK_DATA_MODIFY_SUCCESS)
        });
        this.load();
      },
      error => {
        this.toasterService.pop({
          timeout: UiConstants.ToastTimeoutLong,
          type: UiConstants.toastTypeError,
          title: this.translateService.instant(StringKey.TASKS_DATA_MODIFY),
          body: this.translateService.instant(StringKey.TASK_BULK_MODIFY_ERROR)
        });
      });
    this.closeAssigneeModificationDialog();
  }

  onBulkCloneSuccess(result: BulkCloneResult) {
    this.uiRouter.stateService.go(StateName.TASK_RECORD_EDIT, {
      taskId: result.taskId,
      taskRecordId: result.taskRecordIds[0]
    });
  }

  canStartApproval() {
    return this.grantedRights.changeStateSubmit.hasRight() &&
      TaskRecordStateMachine.StateMachine.canStartApproval(this.taskRecordState, this.combinedResult?.task);
  }

  canCancelApproval() {
    return this.grantedRights.changeStateSubmit.hasRight() &&
      TaskRecordStateMachine.StateMachine.canCancelApproval(this.taskRecordState, this.combinedResult?.task);
  }

  onStartApproval() {
    this.taskRecordService.startApproval({
      taskId: this.taskId,
      taskRecordId: this.taskRecordId
    }).subscribe(result => {
      this.reload();
    });
  }

  onCancelApproval() {
    this.taskRecordService.cancelApproval({
      taskId: this.taskId,
      taskRecordId: this.taskRecordId
    }).subscribe(result => {
      this.reload();
    });
  }

  onCustomerRecordChanged() {
    this.formFieldUpdater.onCustomerRecordChanged(this.baseView.getModel().customer, this.baseView.getModel().contactLocationId);
  }

  onAssigneeUserChanged() {
    this.formFieldUpdater.onAssigneeUserChanged(this.baseView.getModel().assignee.user);
  }

  onSelectedAddressChanged() {
    this.formFieldUpdater.onContactLocationChanged(this.baseView.getModel().contactLocationId);
  }

  reload() {
    this.uiRouter.stateService.reload();
  }
}

interface CombinedResult {
  form: Form.Form;
  task: Task.Task;
  taskRecord: TaskRecord.TaskRecord;
  countries: QueryResult<Country.Country>;
  currentUser: User;
}
