<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div class="breadcrumb-menu d-flex">
    <div *ngIf="isFinalized() && !componentState.isCloneView()"
         class="breadcrumb-button-icon-container cursor-pointer"
         (click)="createNextSchema()">
      <a class="btn-setting d-flex align-items-center" title="{{'TABLE_DOCUMENT_SCHEMA_CREATE_NEW' | translate}}">
        <i class="icomoon icomoon-document-schema-copy-fields"></i>
        <span class="breadcrumb-button-text-visible">{{'TABLE_DOCUMENT_SCHEMA_CREATE_NEW' | translate}}</span>
      </a>
    </div>

    <div *ngIf="isDraft() && !componentState.isCloneView()"
         class="breadcrumb-button-icon-container cursor-pointer"
         (click)="openFinalizeModal()">
      <a class="btn-setting d-flex align-items-center" title="{{'TABLE_DOCUMENT_SCHEMA_FINALIZE' | translate}}">
        <i class="icomoon icomoon-state-action-validate"></i>
        <span class="breadcrumb-button-text-visible">{{'TABLE_DOCUMENT_SCHEMA_FINALIZE' | translate}}</span>
      </a>
    </div>
    <div *ngIf="isFinalized() && !componentState.isCloneView()"
         class="breadcrumb-button-icon-container cursor-pointer"
         (click)="openDeprecateModal()">
      <a class="btn-setting d-flex align-items-center" title="{{'TABLE_DOCUMENT_SCHEMA_DEPRECATE' | translate}}">
        <i class="icomoon icomoon-document-schema-deprecated"></i>
        <span class="breadcrumb-button-text-visible">{{'TABLE_DOCUMENT_SCHEMA_DEPRECATE' | translate}}</span>
      </a>
    </div>
    <div *ngIf="isFinalized() && !componentState.isCloneView()"
         class="breadcrumb-button-icon-container cursor-pointer"
         (click)="exportSchema()">
      <a class="btn-setting d-flex align-items-center" title="{{'COMMON_BUTTON_EXPORT' | translate}}">
        <i class="icomoon icomoon-csv-export"></i>
        <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_EXPORT' | translate}}</span>
      </a>
    </div>
    <app-breadcrumb-button
      class="negative-margin-right"
      *ngIf="rightModel.tableDocumentSchemaUpdate.hasRight() && componentState.isDetailView()"
      [iconClass]="'icomoon-modify'"
      [titleStringKey]="'COMMON_BUTTON_EDIT'"
      uiSref="Admin.TableDocumentSchemaEdit"
      [uiParams]="{ id: componentState.id }">
    </app-breadcrumb-button>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding-half animated fadeIn">
  <div class="container-vertical-padding-half">
    <form class="form-horizontal bordered-row form-group" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>
      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 p-0">
        <div class="card">

          <div class="card-header">
            {{ getHeadingDictionaryKey() | translate}}
          </div>
          <div class="card-body">
            <!--CODE-->
            <div class="form-group row" [ngClass]="{ 'has-danger': hasLocalFieldError(code) || fieldErrors.code }">
              <div class="col-md-4">
                <label class="mw-100 col-form-label form-control-label">{{'LIST_ITEM_CODE' | translate}}</label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <input *ngIf="(componentState.isEditable() && isFirstVersion()) || componentState.isCloneView()"
                         type="text"
                         class="form-control"
                         maxlength="{{UiConstants.maximumVarcharLength}}"
                         [ngClass]="{ 'form-control-danger': hasLocalFieldError(code) }"
                         (ngModelChange)="removeFieldError(fieldErrors.code)"
                         placeholder="{{'LIST_ITEM_CODE' | translate}}"
                         [(ngModel)]="editModel.code"
                         name="code"
                         id="code"
                         #code="ngModel"
                         required>
                  <div class="form-control-feedback"
                       *ngIf="fieldErrors.code">{{fieldErrors.code.text}}</div>
                  <div class="form-control-feedback"
                       *ngIf="hasLocalFieldError(code)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                  <!--Appears in detailView-->
                  <label
                    *ngIf="componentState.isReadonly()" class="form-control detail-description"
                    readonly>{{detailModel.code}}
                  </label>
                  <label
                    *ngIf="componentState.isEditable() && !isFirstVersion() && !componentState.isCloneView()" class="form-control detail-description"
                    readonly>{{editModel.code}}
                  </label>
                </div>
              </div>
            </div>
            <!--NAME-->
            <div class="form-group row" [ngClass]="{ 'has-danger': hasLocalFieldError(name) || fieldErrors.name }">
              <div class="col-md-4">
                <label class="mw-100 col-form-label form-control-label">{{'COMMON_NAME' | translate}}</label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <input *ngIf="(componentState.isEditable() && isFirstVersion()) || componentState.isCloneView()"
                         type="text"
                         class="form-control"
                         maxlength="{{UiConstants.maximumVarcharLength}}"
                         [ngClass]="{ 'form-control-danger': hasLocalFieldError(name) }"
                         (ngModelChange)="removeFieldError(fieldErrors.name)"
                         placeholder="{{'COMMON_NAME' | translate}}"
                         [(ngModel)]="editModel.name"
                         name="name"
                         id="name"
                         #name="ngModel"
                         required>
                  <div class="form-control-feedback"
                       *ngIf="fieldErrors.name">{{fieldErrors.name.text}}</div>
                  <div class="form-control-feedback"
                       *ngIf="hasLocalFieldError(name)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                  <!--Appears in detailView-->
                  <label
                    *ngIf="componentState.isReadonly()" class="form-control detail-description"
                    readonly>{{detailModel.name}}
                  </label>
                  <label
                    *ngIf="componentState.isEditable() && !isFirstVersion() && !componentState.isCloneView()" class="form-control detail-description"
                    readonly>{{editModel.name}}
                  </label>
                </div>
              </div>
            </div>

            <!--NOTE-->
            <div class="form-group row">
              <div class="col-md-4">
                <label
                  class="mw-100 col-form-label form-control-label">{{'TABLE_DOCUMENT_SCHEMA_FIELD_NOTE' | translate}}</label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <textarea *ngIf="componentState.isEditable()"
                            type="text"
                            class="form-control"
                            rows="4"
                            [ngModelOptions]="{standalone: true}"
                            maxlength="{{UiConstants.maximumVarcharLength}}"
                            placeholder="{{'TABLE_DOCUMENT_SCHEMA_FIELD_NOTE' | translate}}"
                            [(ngModel)]="editModel.note">
                    </textarea>
                  <!--Appears in detailView-->
                  <label
                    *ngIf="componentState.isReadonly()" class="form-control detail-description"
                    readonly>{{detailModel.note}}
                  </label>
                </div>
              </div>
            </div>

            <div class="col-md-12 d-flex justify-content-end p-0 pl-1" *ngIf="componentState.isEditable()">
              <input type="submit" class="btn btn-primary" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
            </div>
          </div> <!-- card block end -->

        </div> <!-- card end -->

        <div class="container-vertical-padding-half"></div>

        <!--NEXT CARD-->
        <div class="card"
             *ngIf="(componentState.isEditView() || componentState.isDetailView() || componentState.isCloneView()) && editModel && editModel.versionState || detailModel">

          <div class="card-header">
            {{'TABLE_DOCUMENT_SCHEMA_FIELDS' | translate}}
          </div>
          <div class="card-body">
            <!--TABLE-->
            <table class="table table-sm field-table">
              <thead>
              <tr>
                <th class="responsive-table-column w-5"></th>
                <th class="responsive-table-column">{{'LIST_ITEM_CODE' | translate}}</th>
                <th class="responsive-table-column">{{'COMMON_NAME' | translate}}</th>
                <th class="responsive-table-column">{{'TABLE_DOCUMENT_SCHEMA_FIELD_TYPE' | translate}}</th>
                <th class="responsive-table-column">{{'COMMON_FORM_FIELD_VALIDATION_TYPE_REQUIRED' | translate}}</th>
                <th
                  class="responsive-table-column hidden-xs-down">{{'TABLE_DOCUMENT_SCHEMA_FIELD_NOTE' | translate}}</th>
                <th class="responsive-table-column w-5" *ngIf="!nonEditable"></th>
              </tr>
              </thead>
              <tbody *ngFor="let item of tableDocumentSchemaFieldList; index as i;">
              <tr>
                <td class="responsive-table-column w-5 text-center align-middle">{{i + 1}}</td>
                <td class="responsive-table-column">{{item.code}}</td>
                <td class="responsive-table-column">{{item.name}}</td>
                <td class="responsive-table-column">{{item.dataTypeText | translate}}</td>
                <td class="responsive-table-column">{{item.requiredText | translate}}</td>
                <td class="responsive-table-column hidden-xs-down">{{item.note}}</td>
                <td class="responsive-table-column w-5 align-middle" (click)="toggleEdit(i)" *ngIf="!nonEditable"><i
                  class="icomoon icomoon-detail cursor-pointer"></i></td>
              </tr>
              <tr *ngIf="item.editing">
                <td colspan="7" style="padding: 0">
                  <app-table-document-schema-field-base
                    [model]="item"
                    [fieldList]="tableDocumentSchemaFieldList"
                    (remove)="removeRecord(i)"
                    (cancel)="closeRow(i)"
                    (save)="saveRecord(i, $event)">
                  </app-table-document-schema-field-base>
                </td>
              </tr>
              </tbody>
              <tbody *ngIf="!addModel && (tableDocumentSchemaFieldList.length === 0)">
              <tr>
                <td colspan="7">
                  <div class="text-center text-muted p-2">
                    <label class="m-0" *ngIf="!nonEditable">{{'TABLE_DOCUMENT_SCHEMA_NO_ITEM' | translate}}</label>
                    <label class="m-0"
                           *ngIf="nonEditable">{{'TABLE_DOCUMENT_SCHEMA_NO_ITEM_READONLY' | translate}}</label>
                  </div>
                </td>
              </tr>
              </tbody>
              <tbody *ngIf="addModel">
              <tr>
                <td colspan="7" class="p-0">
                  <app-table-document-schema-field-base
                    [model]="addModel"
                    [isRemovable]="false"
                    [fieldList]="tableDocumentSchemaFieldList"
                    (cancel)="cancelNewRecord()"
                    (save)="saveNewRecord($event)">
                  </app-table-document-schema-field-base>
                </td>
              </tr>
              </tbody>
              <tr *ngIf="!nonEditable && !addModel">
                <td colspan="9">
                  <input class="btn btn-add-record cursor-pointer" type="button"
                         value="{{'TABLE_DOCUMENT_SCHEMA_FIELD_ADD' | translate}}" (click)="newField()">
                </td>
              </tr>
            </table>
            <!--TABLE END-->
          </div> <!-- card block end -->

        </div> <!-- card end -->
      </div>
    </form>

  </div>
</div>

<div bsModal [config]="UiConstants.modalConfig" #finalizeModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="finalizeModalVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'TABLE_DOCUMENT_SCHEMA_FINALIZE' | translate}}</h4>
        <button type="button" class="close" (click)="closeFinalizeModal()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group col-md-12 mb-0">
          <label>{{'TABLE_DOCUMENT_SCHEMA_FINALIZE_LABEL' | translate}}</label>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeFinalizeModal()">
          {{'COMMON_BUTTON_CANCEL' | translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="finalize()">{{'TABLE_DOCUMENT_SCHEMA_FINALIZE_BUTTON' |
          translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<div bsModal [config]="UiConstants.modalConfig" #deprecateModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="deprecateModalVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'TABLE_DOCUMENT_SCHEMA_DEPRECATE' | translate}}</h4>
        <button type="button" class="close" (click)="closeDeprecateModal()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group col-md-12 mb-0">
          <label>{{'TABLE_DOCUMENT_SCHEMA_DEPRECATE_LABEL' | translate}}</label>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeDeprecateModal()">
          {{'COMMON_BUTTON_CANCEL' | translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="deprecate()">{{'TABLE_DOCUMENT_SCHEMA_DEPRECATE_BUTTON' |
          translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
