// Not injected so valid inside the namespace.
import { MultiselectOptionItem, MultiselectOptionItemWithData } from '../../../../util/core-utils';
import { MasterDataRecord } from '../../../../lib/masterdata/master-data-record.service';
import { Set } from 'immutable';

export class MasterdataRecordQuickCreateDialogModel {

  name: string = '';
  externalId: string = '';
  description: string = '';
  _users: MultiselectOptionItem<number>[] = [];
  _userGroups: MultiselectOptionItem<number>[] = [];
  _ownerCustomerRecord: MultiselectOptionItem<number>[] = [];
  _ownerContactLocation: MultiselectOptionItem<number>[] = [];
  _masterData: MultiselectOptionItemWithData<number>[] = [];

  get masterDataIdRequirement(): string | undefined {
    if (!this.masterData) {
      return undefined;
    }
    return <string>this.masterData.data;
  }

  load(record: MasterDataRecord.MasterDataRecord) {
    this.name = record.name ? record.name : '';
    this.externalId = record.externalId ? record.externalId : '';
    this.description = record.description ? record.description : '';
  }

  get userIds(): Set<number> {
    return Set.of(...this._users.map((user) => {
      return user.id!;
    }));
  }

  get userGroupIds(): Set<number> {
    return Set.of(...this._userGroups.map((userGroup) => {
      return userGroup.id!;
    }));
  }

  get ownerCustomerRecord(): MultiselectOptionItem<number> | undefined {
    return this._ownerCustomerRecord.length > 0 ? this._ownerCustomerRecord[0] : undefined;
  }

  get masterData(): MultiselectOptionItemWithData<number> | undefined {
    return this._masterData.length > 0 ? this._masterData[0] : undefined;
  }

  get ownerContactLocation(): MultiselectOptionItem<number> | undefined {
    return this.ownerCustomerRecord && this._ownerContactLocation.length > 0 ? this._ownerContactLocation[0] : undefined;
  }

  hasCustomer(): boolean {
    return this._ownerCustomerRecord.length !== 0;
  }

}
