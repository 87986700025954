<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div class="breadcrumb-menu d-flex">
    <div class="breadcrumb-button-icon-container cursor-pointer"
         (click)="formImportDialog.openImportDialog()">
      <a class="btn-setting d-flex align-items-center" title="{{'COMMON_BUTTON_IMPORT' | translate}}">
        <i class="icomoon icomoon-csv-import"></i>
        <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_IMPORT' | translate}}</span>
      </a>
    </div>
    <div class="breadcrumb-button-icon-container cursor-pointer negative-margin-right"
         (click)="exportForm()">
      <a class="btn-setting d-flex align-items-center" title="{{'COMMON_BUTTON_EXPORT' | translate}}">
        <i class="icomoon icomoon-csv-export"></i>
        <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_EXPORT' | translate}}</span>
      </a>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<!-- region Content -->
<div class="container-horizontal-padding-half container-vertical-padding-half animated fadeIn">

  <!-- region MasterData card -->
  <div class="container-vertical-padding-half card-custom-padding-outer">
    <div class="card col-lg-8 col-md-8 col-xs-12 col-sm-12 p-0">

      <div class="card-header">
        {{'MASTERDATA_PANEL_HEADING_EDIT'|translate}}
      </div><!-- /.card-header -->

      <div class="card-body">
        <form class="form-horizontal bordered-row" (ngSubmit)="update()" #f="ngForm" novalidate>

          <!-- region Name -->
          <div class="form-group row" [ngClass]="{ 'has-danger': hasFieldError(MasterData.ValidatedField.NAME) || hasLocalFieldError(name) }">
            <label class="col-form-label form-control-label col-md-4 detail-title required-field-label">
              {{'COMMON_NAME' | translate}}
            </label>
            <div class="col-md-8">
              <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     [ngClass]="{ 'form-control-danger': hasFieldError(MasterData.ValidatedField.NAME) || hasLocalFieldError(name) }"
                     [(ngModel)]="model.name" (ngModelChange)="removeFieldError(MasterData.ValidatedField.NAME)" name="name" id="name" #name="ngModel" required>
              <div class="form-control-feedback" *ngIf="hasFieldError(MasterData.ValidatedField.NAME)">{{getFieldErrorText(MasterData.ValidatedField.NAME)}}</div>
              <div class="form-control-feedback" *ngIf="hasLocalFieldError(name)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
            </div><!-- /.edit-form-input-group -->
          </div><!-- /.form-group -->
          <!-- endregion Name -->

          <!-- region External id -->
          <div class="form-group row" [ngClass]="{ 'has-danger': hasFieldError(MasterData.ValidatedField.EXTERNAL_ID) || hasLocalFieldError(external_id) }">
            <label class="col-form-label form-control-label col-md-4 detail-title required-field-label">
              {{'COMMON_EXTERNAL_ID' | translate}}
            </label>
            <div class="col-md-8">
              <input type="text" class="form-control" placeholder="{{'COMMON_EXTERNAL_ID' | translate}}"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     [ngClass]="{ 'form-control-danger': hasFieldError(MasterData.ValidatedField.EXTERNAL_ID) || hasLocalFieldError(external_id) }"
                     [(ngModel)]="model.externalId" (ngModelChange)="removeFieldError(MasterData.ValidatedField.EXTERNAL_ID)" name="external_id" id="external_id" #external_id="ngModel" required>
              <div class="form-control-feedback" *ngIf="hasFieldError(MasterData.ValidatedField.EXTERNAL_ID)">{{getFieldErrorText(MasterData.ValidatedField.EXTERNAL_ID)}}</div>
              <div class="form-control-feedback" *ngIf="hasLocalFieldError(external_id)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
            </div><!-- /.edit-form-input-group -->
          </div><!-- /.form-group -->
          <!-- endregion External id -->

          <!-- region Description -->
          <div class="form-group row">
            <label class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_DESCRIPTION' | translate}}</label>
            <div class="col-md-8">
              <input type="text" class="form-control" placeholder="{{'COMMON_DESCRIPTION' | translate}}"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     [(ngModel)]="model.description" name="description" id="description" #description="ngModel">
            </div><!-- /.edit-form-input-group -->
          </div><!-- /.form-group -->
          <!-- endregion Description -->

          <!-- region requiredMasterDataId -->
          <div class="form-group row" [ngClass]="{ 'has-danger': hasFieldError(MasterData.ValidatedField.CLIENT_REQUIRED_ID_FIELD) || f.submitted && !requiredMasterDataId.valid }">
            <label class="col-form-label form-control-label col-md-4 detail-title required-field-label">
              {{'MASTERDATA_REQUIRED_ID_FIELD' | translate}}
            </label>
            <div class="col-md-8">
              <select
                class="form-control"
                [(ngModel)]="model.requiredMasterDataId"
                [compareWith]="SelectUtils.compareStrings"
                [ngClass]="{ 'form-control-danger': hasFieldError(MasterData.ValidatedField.CLIENT_REQUIRED_ID_FIELD) || (f.submitted && !requiredMasterDataId.valid) }"
                name="requiredMasterDataId"
                id="requiredMasterDataId"
                #requiredMasterDataId="ngModel"
                required>
                <option *ngFor="let item of RequiredMasterDataIdsItems"
                        [ngValue]="item.id">{{item.name | translate}}</option>
              </select>
              <div class="form-control-feedback" *ngIf="f.submitted && !requiredMasterDataId.valid">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
            </div>
          </div>
          <!-- endregion requiredMasterDataId -->

          <!-- region User groups -->
          <div class="form-group row"
               [ngClass]="{ 'has-danger': hasFieldError(MasterData.ValidatedField.OWNER_USER_GROUPS) || (f.submitted && !ownerGroups.valid) }">
            <div class="col-md-4">
              <label class="mw-100 col-form-label form-control-label required-field-label">
                {{'CUSTOMER_RECORD_USER_GROUPS' | translate}}
                <i class="icomoon icomoon-info info-icon" popover="{{'CUSTOMER_RECORD_USER_GROUPS_HINT' | translate}}"
                   triggers="mouseenter:mouseleave" container="body" placement="right">
                </i>
              </label>
            </div>
            <div class="col-md-8">
              <angular2-multiselect
                [(ngModel)]="model.userGroups"
                [ngClass]="{ 'has-danger': hasFieldError(MasterData.ValidatedField.OWNER_USER_GROUPS) || (f.submitted && !ownerGroups.valid) }"
                (ngModelChange)="removeFieldError(MasterData.ValidatedField.OWNER_USER_GROUPS)"
                (onRemoteSearch)="searchUserGroups($event)"
                name="ownerGroups" id="ownerGroups" #ownerGroups="ngModel" required validateEnabledItems
                [data]="model.userGroupItems" [settings]="userGroupDropdownSettings">
              </angular2-multiselect>
              <div class="form-control-feedback" *ngIf="f.submitted && !ownerGroups.valid && ownerGroups.errors['required']">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
              <div class="form-control-feedback" *ngIf="f.submitted && !ownerGroups.valid && ownerGroups.errors['hasDisabledItem']">
                {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
              </div>
              <div class="form-control-feedback" *ngIf="hasFieldError(MasterData.ValidatedField.OWNER_USER_GROUPS)">
                {{getFieldErrorText(MasterData.ValidatedField.OWNER_USER_GROUPS)}}
              </div>
            </div>
          </div>
          <!-- endregion User groups -->

          <!-- region Mobile owner type -->
          <div class="form-group row" [ngClass]="{ 'has-danger': hasFieldError(MasterData.ValidatedField.MOBILE_OWNER_TYPE) || (f.submitted && !mobileOwnerType.valid) }">
            <div class="col-md-4">
              <label class="mw-100 col-form-label form-control-label required-field-label">
                {{'CUSTOMER_MOBILE_OWNER_TYPE' | translate}}
                <i class="icomoon icomoon-info info-icon" popover="{{'CUSTOMER_MOBILE_OWNER_TYPE_HINT' | translate}}"
                   triggers="mouseenter:mouseleave" container="body" placement="right">
                </i>
              </label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <select type="text" class="form-control"
                        [compareWith]="SelectUtils.compareObjects"
                        [ngClass]="{ 'form-control-danger': hasFieldError(MasterData.ValidatedField.MOBILE_OWNER_TYPE) || (f.submitted && !mobileOwnerType.valid) }"
                        [(ngModel)]="model.mobileOwnerType" (ngModelChange)="removeFieldError(MasterData.ValidatedField.MOBILE_OWNER_TYPE)" name="mobileOwnerType" id="mobileOwnerType" #mobileOwnerType="ngModel" required>
                  <option *ngFor="let mobileOwnerType of ownerTypes" [ngValue]="mobileOwnerType">{{mobileOwnerType.text}}</option>
                </select>
                <div class="form-control-feedback" *ngIf="hasFieldError(MasterData.ValidatedField.MOBILE_OWNER_TYPE)">{{getFieldErrorText(MasterData.ValidatedField.MOBILE_OWNER_TYPE)}}</div>
                <div class="form-control-feedback" *ngIf="f.submitted && !mobileOwnerType.valid">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
              </div>
            </div>
          </div>
          <!-- endregion Mobile owner type -->

          <!-- region Bottom buttons -->
          <div>
            <div class="edit-form-button-group pl-0">
              <input type="submit" class="btn btn-primary" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
            </div><!-- /.edit-form-button-group -->
          </div>
          <!-- endregion Bottom buttons -->

        </form>
      </div><!-- /.card-body -->

    </div><!-- /.card -->
  </div><!-- /.container-vertical-padding-half -->
  <!-- endregion MasterData -->

  <!-- region Form card -->
  <div class="container-vertical-padding-half card-custom-padding-outer form-editor-card-container" id="form-editor-card-container">
    <app-form-edit #formEdit
      [formServiceCode]="FormServiceCode.MASTER_DATA"
      [formRights]="formRights"
      [parentId]="masterdataId"
      [dialogContainer]="dialogContainer">
    </app-form-edit>
  </div><!-- /.container-vertical-padding -->
  <!-- endregion Form card -->

</div>
<!-- endregion Content -->

<!-- region Dialogs -->
<app-form-edit-dialog-container #dialogContainer></app-form-edit-dialog-container>

<app-form-import-dialog
  #formImportDialog
  [parentId]="masterdataId"
  [formService]="formService"
  (onFormImported)="formEdit.reloadForm()">
</app-form-import-dialog>
<!-- endregion Dialogs -->
