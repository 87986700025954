/* eslint-disable */
import { FormRecordFieldView, FormRecordFieldViewFactory, } from './form-utils';
import { ComponentFactoryResolver, ComponentRef, Injector, } from '@angular/core';
import { FormRecordReadonlyPictureFieldComponent } from '../../admin/form/form-record/fields/readonly/picture/form-record-readonly-picture-field.component';
/* eslint-enable */

export class FormRecordReadonlyPictureFieldViewFactory implements FormRecordFieldViewFactory {

  constructor(
    private injector: Injector,
    private cfr: ComponentFactoryResolver) {
  }

  createView(): ComponentRef<FormRecordFieldView> {
    const cmp = this.cfr.resolveComponentFactory(FormRecordReadonlyPictureFieldComponent)
      .create(this.injector);
    return cmp;
  }

}
