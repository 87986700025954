<div class="pb-0">

  <div class="row ml-0 mr-0">

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 card-custom-padding" [formGroup]="formGroup">

      <div class="container-vertical-padding-half">
        <div class="card">

          <div class="card-header">
            {{'CUSTOMER_RECORD_BASIC_DATA' | translate}}
          </div>

          <div class="card-body">

            <!-- region Name -->
            <div
              [ngStyle]="(readonly || complexName) ? '' : fieldStyle"
              [ngClass]="{ 'has-danger': hasFieldError(CustomerRecord.ValidatedField.NAME) || hasLocalFieldError('name') }">
              <div class="form-group row mb-0">
                <label class="col-form-label form-control-label col-md-4 detail-title"
                       [class.required-field-label]="!readonly && !complexName">
                  {{'CUSTOMER_RECORD_NAME' | translate}}
                </label>
                <div class="col-md-8">
                  <input type="text" class="form-control"
                         placeholder="{{'CUSTOMER_RECORD_NAME' | translate}}"
                         maxlength="{{UiConstants.maximumVarcharLength}}"
                         [readonly]="readonly || complexName" formControlName="name"
                         trim="blur" validateOnBlur [validateFormControl]="formGroup.controls['name']"
                         [ngClass]="{ 'form-control-danger': hasFieldError(CustomerRecord.ValidatedField.NAME) || hasLocalFieldError('name'), 'detail-description' : (readonly || complexName)}"
                         [(ngModel)]="m.fullName"
                         (ngModelChange)="removeFieldError(CustomerRecord.ValidatedField.NAME)">
                  <div class="form-control-feedback" *ngIf="hasFieldError(CustomerRecord.ValidatedField.NAME)">
                    {{getFieldErrorText(CustomerRecord.ValidatedField.NAME)}}
                  </div>
                  <div class="form-control-feedback" *ngIf="hasLocalFieldError('name')">
                    <span
                      *ngIf="hasLocalFieldError('name', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- endregion Name -->

            <div *ngIf="complexName">
              <!-- region FirstName-->
              <div
                [ngStyle]="readonly ? '' : fieldStyle"
                [ngClass]="{ 'has-danger': hasFieldError(CustomerRecord.ValidatedField.FIRST_NAME) || hasLocalFieldError('firstName') }">
                <div class="form-group row mb-0">
                  <label class="col-form-label form-control-label col-md-4 detail-title"
                         [class.required-field-label]="!readonly && complexName">
                    {{'CUSTOMER_RECORD_FIRST_NAME' | translate}}
                  </label>
                  <div class="col-md-8">
                    <input type="text" class="form-control"
                           placeholder="{{'CUSTOMER_RECORD_FIRST_NAME' | translate}}"
                           maxlength="{{UiConstants.maximumVarcharLength}}"
                           [readonly]="readonly" formControlName="firstName"
                           trim="blur" validateOnBlur [validateFormControl]="formGroup.controls['firstName']"
                           [ngClass]="{ 'form-control-danger': hasFieldError(CustomerRecord.ValidatedField.FIRST_NAME) || hasLocalFieldError('firstName'), 'detail-description' : readonly}"
                           [(ngModel)]="m.firstName"
                           (ngModelChange)="removeFieldError(CustomerRecord.ValidatedField.FIRST_NAME)">
                    <div class="form-control-feedback" *ngIf="hasFieldError(CustomerRecord.ValidatedField.FIRST_NAME)">
                      {{getFieldErrorText(CustomerRecord.ValidatedField.FIRST_NAME)}}
                    </div>
                    <div class="form-control-feedback" *ngIf="hasLocalFieldError('firstName')">
                      <span
                        *ngIf="hasLocalFieldError('firstName', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- endregion FirstName-->
              <!-- region LastName-->
              <div
                [ngStyle]="readonly ? '' : fieldStyle"
                [ngClass]="{ 'has-danger': hasFieldError(CustomerRecord.ValidatedField.LAST_NAME) || hasLocalFieldError('lastName') }">
                <div class="form-group row mb-0">
                  <label class="col-form-label form-control-label col-md-4 detail-title"
                         [class.required-field-label]="!readonly && complexName">
                    {{'CUSTOMER_RECORD_LAST_NAME' | translate}}
                  </label>
                  <div class="col-md-8">
                    <input type="text" class="form-control"
                           maxlength="{{UiConstants.maximumVarcharLength}}"
                           placeholder="{{'CUSTOMER_RECORD_LAST_NAME' | translate}}"
                           [readonly]="readonly" formControlName="lastName"
                           trim="blur" validateOnBlur [validateFormControl]="formGroup.controls['lastName']"
                           [ngClass]="{ 'form-control-danger': hasFieldError(CustomerRecord.ValidatedField.LAST_NAME) || hasLocalFieldError('lastName'), 'detail-description' : readonly}"
                           [(ngModel)]="m.lastName"
                           (ngModelChange)="removeFieldError(CustomerRecord.ValidatedField.LAST_NAME)">
                    <div class="form-control-feedback" *ngIf="hasFieldError(CustomerRecord.ValidatedField.LAST_NAME)">
                      {{getFieldErrorText(CustomerRecord.ValidatedField.LAST_NAME)}}
                    </div>
                    <div class="form-control-feedback" *ngIf="hasLocalFieldError('lastName')">
                      <span
                        *ngIf="hasLocalFieldError('lastName', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- endregion LastName-->
            </div>

            <!-- region External ID -->
            <div [ngStyle]="readonly ? '' : fieldStyle"
                 *ngIf="readonly ? (m.externalId !== undefined) && fieldModel.isManaged(CustomerRecordFieldType.EXTERNAL_ID) : fieldModel.isManaged(CustomerRecordFieldType.EXTERNAL_ID)"
                 [ngClass]="{ 'has-danger': hasFieldError(CustomerRecord.ValidatedField.EXTERNAL_ID) || hasLocalFieldError('externalId') }">
              <div class="form-group row mb-0">
                <label class="col-form-label form-control-label col-md-4 detail-title"
                       [class.required-field-label]="!readonly && externalIdRequired">
                  {{'CUSTOMER_RECORD_EXTERNAL_ID' | translate}}
                </label>
                <div class="col-md-8">
                  <input type="text" class="form-control" placeholder="{{'CUSTOMER_RECORD_EXTERNAL_ID' | translate}}"
                         maxlength="{{UiConstants.maximumVarcharLength}}"
                         [readonly]="readonly" formControlName="externalId"
                         trim="blur" validateOnBlur [validateFormControl]="formGroup.controls['externalId']"
                         [ngClass]="{ 'form-control-danger': hasFieldError(CustomerRecord.ValidatedField.EXTERNAL_ID) || hasLocalFieldError('externalId'), 'detail-description' : readonly }"
                         [(ngModel)]="m.externalId"
                         (ngModelChange)="removeFieldError(CustomerRecord.ValidatedField.EXTERNAL_ID)">
                  <div class="form-control-feedback" *ngIf="hasFieldError(CustomerRecord.ValidatedField.EXTERNAL_ID)">
                    {{getFieldErrorText(CustomerRecord.ValidatedField.EXTERNAL_ID)}}
                  </div>
                  <div class="form-control-feedback" *ngIf="hasLocalFieldError('externalId')">
                    <span
                      *ngIf="hasLocalFieldError('externalId', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- endregion External ID -->

            <!-- region Comment -->
            <div [ngStyle]="readonly ? '' : fieldStyle" [ngClass]="{ 'has-danger': hasLocalFieldError('comment') }"
                 *ngIf="readonly ? (m.comment !== undefined) && fieldModel.isManaged(CustomerRecordFieldType.COMMENT) : fieldModel.isManaged(CustomerRecordFieldType.COMMENT)">
              <div class="form-group row mb-0">
                <label class="col-form-label form-control-label col-md-4 detail-title"
                       [class.required-field-label]="fieldModel.isRequired(CustomerRecordFieldType.COMMENT)">{{'COMMON_COMMENT' |
                  translate}}</label>
                <div class="col-md-8">
                  <input type="text" class="form-control" placeholder="{{'COMMON_COMMENT' | translate}}"
                         [readonly]="readonly"
                         [(ngModel)]="m.comment"
                         [ngClass]="{ 'form-control-danger': hasLocalFieldError('comment'), 'detail-description' : readonly }"
                         [ngModelOptions]="{standalone: true}">
                  <div class="form-control-feedback"
                       *ngIf="hasLocalFieldError('comment', 'required')">
                    <label>{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</label>
                  </div>
                </div>
              </div>
            </div>
            <!-- endregion Comment -->

            <!-- region Position -->
            <div [ngStyle]="readonly ? '' : fieldStyle"
                 [ngClass]="{ 'has-danger': hasLocalFieldError('position') }"
                 *ngIf="readonly ? (m.position !== undefined) && fieldModel.isManaged(CustomerRecordFieldType.POSITION) : fieldModel.isManaged(CustomerRecordFieldType.POSITION)">
              <div class="form-group row mb-0">
                <label class="col-form-label form-control-label col-md-4 detail-title"
                       [class.required-field-label]="fieldModel.isRequired(CustomerRecordFieldType.POSITION)">{{'CUSTOMER_RECORD_POSITION' |
                  translate}}</label>
                <div class="col-md-8">
                  <input type="text" class="form-control" placeholder="{{'CUSTOMER_RECORD_POSITION' | translate}}"
                         [readonly]="readonly"
                         [(ngModel)]="m.position"
                         [ngClass]="{ 'form-control-danger': hasLocalFieldError('position'), 'detail-description' : readonly }"
                         [ngModelOptions]="{standalone: true}">
                  <div class="form-control-feedback"
                       *ngIf="hasLocalFieldError('position', 'required')">
                    <label>{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</label>
                  </div>
                </div>
              </div>
            </div>
            <!-- endregion Position -->

            <div [class.assignee-required]="!readonly && fieldModel.isRequired(CustomerRecordFieldType.ASSIGNEE)"
                 *ngIf="readonly ? (m.users.length > 0 || m.userGroups.length > 0) && fieldModel.isManaged(CustomerRecordFieldType.ASSIGNEE) : fieldModel.isManaged(CustomerRecordFieldType.ASSIGNEE)"
                 [class.assignee-required-danger]="fieldModel.isRequired(CustomerRecordFieldType.ASSIGNEE) && form && form.submitted && model.users.length === 0 && model.userGroups.length === 0">

              <!-- region Users -->
              <div [ngStyle]="readonly ? '' : fieldStyle" [ngClass]="{ 'has-danger': hasLocalFieldError('user') }">
                <div class="form-group row mb-0">
                  <label
                    class="col-form-label form-control-label col-md-4 detail-title">{{'CUSTOMER_RECORD_USERS'|translate}}</label>
                  <div class="col-md-8">
                    <angular2-multiselect
                      formControlName="user"
                      [(ngModel)]="model.users"
                      [data]="model.userItems"
                      [settings]="userDropdownSettings"
                      [readonly]="readonly"
                      (onRemoteSearch)="loadUserSearch($event.target.value)">
                    </angular2-multiselect>
                    <div class="form-control-feedback" *ngIf="hasLocalFieldError('user', 'hasDisabledItem')">
                      <label>{{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}</label>
                    </div>
                  </div>
                </div>
              </div>
              <!-- endregion Users -->

              <!-- region User groups -->
              <div [ngStyle]="readonly ? '' : fieldStyle" [ngClass]="{ 'has-danger': hasLocalFieldError('userGroup') }">
                <div class="form-group row mb-0">
                  <label
                    class="col-form-label form-control-label col-md-4 detail-title">{{'CUSTOMER_RECORD_USER_GROUPS'|translate}}</label>
                  <div class="col-md-8">
                    <angular2-multiselect
                      formControlName="userGroup"
                      [(ngModel)]="model.userGroups"
                      [data]="model.userGroupItems"
                      [settings]="userGroupDropdownSettings"
                      [readonly]="readonly"
                      (onRemoteSearch)="loadUserGroupSearch($event.target.value)">
                    </angular2-multiselect>
                    <div class="form-control-feedback" *ngIf="hasLocalFieldError('userGroup', 'hasDisabledItem')">
                      <label>{{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}</label>
                    </div>
                  </div>
                </div>
              </div>
              <!-- endregion User groups -->

              <div class="form-control-feedback assignee-required-error"
                   *ngIf="fieldModel.isRequired(CustomerRecordFieldType.ASSIGNEE) && form && form.submitted && model.users.length === 0 && model.userGroups.length === 0">
                <label>{{'CUSTOMER_RECORD_ASSIGNEE_REQUIRED' | translate}}</label>
              </div>

            </div>

            <!-- region Customer Record Parents -->
            <div [ngStyle]="readonly ? '' : fieldStyle"
                 *ngIf="readonly ? (m.customerRecordParents.length > 0) && fieldModel.isManaged(CustomerRecordFieldType.PARENT_CUSTOMER_RECORD) : fieldModel.isManaged(CustomerRecordFieldType.PARENT_CUSTOMER_RECORD)"
                 [ngClass]="{ 'has-danger': hasLocalFieldError('parentCustomerRecord') }">
              <div class="form-group row mb-0">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title"
                  [class.required-field-label]="fieldModel.isRequired(CustomerRecordFieldType.PARENT_CUSTOMER_RECORD)">{{'CUSTOMER_RECORD_PARENT'|translate}}</label>
                <div class="col-md-8">
                  <angular2-multiselect
                    formControlName="parentCustomerRecord"
                    [data]="customers"
                    [settings]="customerRecordParentDropdownSettings"
                    class="form-control"
                    [(ngModel)]="model.customerRecordParents"
                    (onSelect)="onCustomerChanged()"
                    (onDeSelect)="onCustomerChanged()"
                    (onRemoteSearch)="loadParentsCustomerRecord([], $event)"
                    [readonly]="readonly"
                    (onReadonlyItemClick)="navigateToCustomerRecordDetail($event)">
                  </angular2-multiselect>
                  <div class="form-control-feedback"
                       *ngIf="hasLocalFieldError('parentCustomerRecord', 'hasDisabledItem')">
                    <label>{{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}</label>
                  </div>
                  <div class="form-control-feedback"
                       *ngIf="hasLocalFieldError('parentCustomerRecord', 'required')">
                    <label>{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</label>
                  </div>
                </div>
              </div>
            </div>
            <!-- endregion Customer Record Parents -->

            <!-- region Customer Record Children -->
            <div [ngStyle]="readonly ? '' : fieldStyle" *ngIf="(readonly && childCustomerRecordList.length !== 0)">
              <div class="form-group row mb-0">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'CUSTOMER_RECORD_CHILD'|translate}}</label>
                <div class="col-md-8">
                  <angular2-multiselect
                    [data]="customers"
                    [settings]="customerRecordChildrenDropdownSettings"
                    class="form-control"
                    [(ngModel)]="childCustomerRecordList"
                    [ngModelOptions]="{standalone: true}"
                    [readonly]="readonly"
                    (onReadonlyItemClick)="navigateToCustomerRecordDetail($event)">
                  </angular2-multiselect>
                </div>
              </div>
            </div>
            <!-- endregion Customer Record Children -->

            <!-- region Company -->
            <div [ngStyle]="readonly ? '' : fieldStyle" [ngClass]="{ 'has-danger': hasLocalFieldError('company') }"
                 *ngIf="((readonly && model.getCompanyId()) || !readonly) && fieldModel.isManaged(CustomerRecordFieldType.COMPANY)">
              <div class="form-group row mb-0">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title"
                  [class.required-field-label]="fieldModel.isRequired(CustomerRecordFieldType.COMPANY)">
                  {{'COMMON_COMPANY'|translate}}
                </label>
                <div class="col-md-8">
                  <angular2-multiselect
                    formControlName="company"
                    [data]="companies"
                    [settings]="singleRemoteDropdownSettings"
                    class="form-control"
                    [(ngModel)]="model.company"
                    (onRemoteSearch)="loadCompanies($event.target.value)"
                    [readonly]="readonly">
                  </angular2-multiselect>
                  <div class="form-control-feedback" *ngIf="hasLocalFieldError('company', 'hasDisabledItem')">
                    <label>{{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}</label>
                  </div>
                  <div class="form-control-feedback" *ngIf="hasLocalFieldError('company', 'required')">
                    <label>{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</label>
                  </div>
                </div>
              </div>
            </div>
            <!-- endregion Company -->

            <!-- region ContractNumber -->
            <div [ngStyle]="readonly ? '' : fieldStyle"
                 *ngIf="!(readonly && m.mainContractNumber === '') && fieldModel.isManaged(CustomerRecordFieldType.MAIN_CONTRACT_NUMBER)"
                 [ngClass]="{ 'has-danger': hasLocalFieldError('mainContractNumber') }">
              <div class="form-group row mb-0">
                <label class="col-form-label form-control-label col-md-4 detail-title"
                       [class.required-field-label]="fieldModel.isRequired(CustomerRecordFieldType.MAIN_CONTRACT_NUMBER)">
                  {{'CUSTOMER_RECORD_CONTRACT_NUMBER' | translate}}
                </label>
                <div class="col-md-8">
                  <input type="text" class="form-control" placeholder="{{'CUSTOMER_RECORD_CONTRACT_NUMBER' | translate}}"
                         maxlength="{{UiConstants.maximumVarcharLength}}"
                         [readOnly]="readonly"
                         [(ngModel)]="m.mainContractNumber" formControlName="mainContractNumber"
                         [ngClass]="{ 'form-control-danger': hasLocalFieldError('mainContractNumber'),  'detail-description' : readonly }">
                  <div class="form-control-feedback" *ngIf="hasLocalFieldError('mainContractNumber')">
                    <span
                      *ngIf="hasLocalFieldError('mainContractNumber', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- endregion ContractNumber -->

            <!-- region Web address -->
            <div [ngStyle]="readonly ? '' : fieldStyle"
                 *ngIf="!(readonly && m.webAddress === '') && fieldModel.isManaged(CustomerRecordFieldType.WEB_ADDRESS)"
                 [ngClass]="{ 'has-danger': hasLocalFieldError('webAddress') }">
              <div class="form-group row mb-0">
                <label class="col-form-label form-control-label col-md-4 detail-title"
                       [class.required-field-label]="fieldModel.isRequired(CustomerRecordFieldType.WEB_ADDRESS)">
                  {{'CUSTOMER_RECORD_WEB_ADDRESS' | translate}}
                </label>
                <div class="col-md-8">
                  <input type="text" class="form-control" placeholder="{{'CUSTOMER_RECORD_WEB_ADDRESS' | translate}}"
                         maxlength="{{UiConstants.maximumVarcharLength}}"
                         [readOnly]="readonly"
                         [(ngModel)]="m.webAddress" formControlName="webAddress"
                         [ngClass]="{ 'form-control-danger': hasLocalFieldError('webAddress'),  'detail-description' : readonly }">
                  <div class="form-control-feedback" *ngIf="hasLocalFieldError('webAddress')">
                    <span
                      *ngIf="hasLocalFieldError('webAddress', 'validateUrl')">{{'COMMON_VALIDATION_MESSAGE_INVALID_URL' | translate}}</span>
                    <span
                      *ngIf="hasLocalFieldError('webAddress', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- endregion Web address -->

            <!-- region TAX number -->
            <div [ngStyle]="readonly ? '' : fieldStyle"
                 *ngIf="!(readonly && m.taxNumber.raw === '') && fieldModel.isManaged(CustomerRecordFieldType.TAX_NUMBER)"
                 [ngClass]="{ 'has-danger': hasLocalFieldError('taxNumber') || hasFieldError(CustomerRecord.ValidatedField.TAX_NUMBER) }">
              <div class="form-group row mb-0">
                <label class="col-form-label form-control-label col-md-4 detail-title"
                       [class.required-field-label]="fieldModel.isRequired(CustomerRecordFieldType.TAX_NUMBER)">
                  {{'CUSTOMER_RECORD_TAX_NUMBER' | translate}}
                </label>
                <div class="col-md-8">
                  <input type="text" class="form-control" placeholder="{{'CUSTOMER_RECORD_TAX_NUMBER' | translate}}"
                         [textMask]="{mask: InputMask.HU_TAX_NUMBER, guide: !readonly}"
                         [readonly]="readonly"
                         [ngClass]="{ 'form-control-danger': hasLocalFieldError('taxNumber') || hasFieldError(CustomerRecord.ValidatedField.TAX_NUMBER), 'detail-description' : readonly }"
                         [(ngModel)]="m.taxNumber.raw" formControlName="taxNumber"
                         (ngModelChange)="removeFieldError(CustomerRecord.ValidatedField.TAX_NUMBER)">
                  <div class="form-control-feedback" *ngIf="hasFieldError(CustomerRecord.ValidatedField.TAX_NUMBER)">
                    {{getFieldErrorText(CustomerRecord.ValidatedField.TAX_NUMBER)}}
                  </div>
                  <div class="form-control-feedback" *ngIf="hasLocalFieldError('taxNumber')">
                    <span
                      *ngIf="hasLocalFieldError('taxNumber', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- endregion TAX number -->

            <!-- region EU TAX number -->
            <div [ngStyle]="readonly ? '' : fieldStyle"
                 *ngIf="!(readonly && m.euTaxNumber.raw === '') && fieldModel.isManaged(CustomerRecordFieldType.EU_TAX_NUMBER)"
                 [ngClass]="{ 'has-danger': hasLocalFieldError('euTaxNumber') || hasFieldError(CustomerRecord.ValidatedField.EU_TAX_NUMBER) }">
              <div class="form-group row mb-0">
                <label class="col-form-label form-control-label col-md-4 detail-title"
                       [class.required-field-label]="fieldModel.isRequired(CustomerRecordFieldType.EU_TAX_NUMBER)">
                  {{'CUSTOMER_RECORD_EU_TAX_NUMBER' | translate}}
                </label>
                <div class="col-md-8">
                  <input type="text" class="form-control" placeholder="{{'CUSTOMER_RECORD_EU_TAX_NUMBER' | translate}}"
                         [readonly]="readonly"
                         [ngClass]="{ 'form-control-danger': hasLocalFieldError('euTaxNumber') || hasFieldError(CustomerRecord.ValidatedField.EU_TAX_NUMBER), 'detail-description' : readonly }"
                         [(ngModel)]="m.euTaxNumber.raw" formControlName="euTaxNumber"
                         (ngModelChange)="removeFieldError(CustomerRecord.ValidatedField.EU_TAX_NUMBER)">
                  <div class="form-control-feedback" *ngIf="hasFieldError(CustomerRecord.ValidatedField.EU_TAX_NUMBER)">
                    {{getFieldErrorText(CustomerRecord.ValidatedField.EU_TAX_NUMBER)}}
                  </div>
                  <div class="form-control-feedback" *ngIf="hasLocalFieldError('euTaxNumber', 'required')">
                    <span
                      *ngIf="hasLocalFieldError('euTaxNumber', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
                  </div>
                  <div class="form-control-feedback"
                       *ngIf="hasLocalFieldError('euTaxNumber', 'validateEuVatNumber')">{{'COMMON_VALIDATION_MESSAGE_INVALID_EU_VAT_NUMBER' | translate}}
                  </div>
                </div>
              </div>
            </div>
            <!-- endregion EU TAX number -->

            <!-- region LedgerNumber -->
            <div [ngStyle]="readonly ? '' : fieldStyle" [ngClass]="{ 'has-danger': hasLocalFieldError('ledgerNumber') }"
                 *ngIf="((readonly && model.ledgerNumber.length > 0) || !readonly) && rightModel.ledgerNumberRead.hasRight() && fieldModel.isManaged(CustomerRecordFieldType.LEDGER_NUMBER)">
              <div class="form-group row mb-0">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'LEDGER_NUMBER_LEDGER_NUMBER'|translate}}</label>
                <div class="col-md-8">
                  <angular2-multiselect
                    formControlName="ledgerNumber"
                    [data]="ledgerNumbers"
                    [settings]="singleRemoteDropdownSettings"
                    class="form-control"
                    [(ngModel)]="model.ledgerNumber"
                    (onRemoteSearch)="loadLedgerNumbers($event.target.value)"
                    [readonly]="readonly">
                  </angular2-multiselect>
                  <div class="form-control-feedback" *ngIf="hasLocalFieldError('ledgerNumber', 'hasDisabledItem')">
                    <label>{{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}</label>
                  </div>
                </div>
              </div>
            </div>
            <!-- endregion Customer Record Parents -->

          </div>

        </div>
      </div>
      <!--card end-->

      <div class="container-vertical-padding-half" *ngIf="customerItem && customerItem.type === 'PERSON' && getPersonalDataCardVisible()">
        <div class="card">

          <div class="card-header">
            {{'CUSTOMER_RECORD_PERSONAL_DATA' | translate}}
          </div>

          <div class="card-body">

            <!--Person Type-->
            <div *ngIf="isPersonType() && fieldModel.isManaged(CustomerRecordFieldType.PLACE_OF_BIRTH)">
              <!-- region Place of birth -->
              <div [ngStyle]="readonly ? '' : fieldStyle"
                   *ngIf="!(readonly && m.placeOfBirth === '')">
                <div class="form-group row mb-0"
                     [ngClass]="{ 'has-danger': hasLocalFieldError('placeOfBirth') }">
                  <label
                    class="col-form-label form-control-label col-md-4 detail-title"
                    [class.required-field-label]="fieldModel.isRequired(CustomerRecordFieldType.PLACE_OF_BIRTH)">
                    {{'CUSTOMER_RECORD_PLACE_OF_BIRTH' | translate}}
                  </label>
                  <div class="col-md-8">
                    <input type="text"
                           class="form-control"
                           placeholder="{{'CUSTOMER_RECORD_PLACE_OF_BIRTH' | translate}}"
                           [readOnly]="readonly"
                           [ngClass]="{ 'form-control-danger': hasLocalFieldError('placeOfBirth'), 'detail-description' : readonly }"
                           [(ngModel)]="m.placeOfBirth" formControlName="placeOfBirth">
                    <div class="form-control-feedback" *ngIf="hasLocalFieldError('placeOfBirth')">
                      <span
                        *ngIf="hasLocalFieldError('placeOfBirth', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- endregion Place of birth -->

            <!-- region date of birth -->
            <div [ngStyle]="readonly ? '' : fieldStyle"
                 *ngIf="!(readonly && m.dateOfBirth === null) && fieldModel.isManaged(CustomerRecordFieldType.DATE_OF_BIRTH)">
              <div class="form-group row mb-0"
                   [ngClass]="{ 'has-danger': hasLocalFieldError('dateOfBirth') }">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title"
                  [class.required-field-label]="fieldModel.isRequired(CustomerRecordFieldType.DATE_OF_BIRTH)">
                  {{'CUSTOMER_RECORD_DATE_OF_BIRTH' | translate}}
                </label>
                <div class="col-md-8">
                  <div class="input-group">
                    <input placeholder="{{'CUSTOMER_RECORD_DATE_OF_BIRTH' | translate}}" type="text"
                           class="form-control"
                           [readOnly]="readonly"
                           [ngClass]="{ 'form-control-danger': hasLocalFieldError('dateOfBirth'), 'detail-description' : readonly }"
                           [(ngModel)]="m.dateOfBirth" formControlName="dateOfBirth"
                           [minDate]="CustomerRecordUtils.DateOfBirthMinDate"
                           [maxDate]="CustomerRecordUtils.DateOfBirthMaxDate"
                           [startDate]="CustomerRecordUtils.DateOfBirthDefaultDate"
                           ngbDatepicker #date_picker="ngbDatepicker"
                           validateLocalDate>
                    <span *ngIf="!readonly" class="add-on input-group-addon-gray" (click)="date_picker.toggle()">
                  <i class="icomoon icomoon-calendar"></i>
                     </span>
                  </div>
                  <div class="form-control-feedback" *ngIf="hasLocalFieldError('dateOfBirth')">
                      <span
                        *ngIf="hasLocalFieldError('dateOfBirth', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- endregion date of birth -->

            <!-- region Birth Name -->
            <div [ngStyle]="readonly ? '' : fieldStyle"
                 *ngIf="!(readonly && m.birthName === '') && fieldModel.isManaged(CustomerRecordFieldType.BIRTH_NAME)">
              <div class="form-group row mb-0"
                   [ngClass]="{ 'has-danger': hasLocalFieldError('birthName') }">
                <label class="col-form-label form-control-label col-md-4 detail-title"
                       [class.required-field-label]="fieldModel.isRequired(CustomerRecordFieldType.DATE_OF_BIRTH)">
                  {{'CUSTOMER_RECORD_BIRTH_NAME' | translate}}
                </label>
                <div class="col-md-8">
                  <input type="text"
                         class="form-control"
                         placeholder="{{'CUSTOMER_RECORD_BIRTH_NAME' | translate}}"
                         [readOnly]="readonly"
                         [ngClass]="{ 'form-control-danger': hasLocalFieldError('birthName'), 'detail-description' : readonly }"
                         [(ngModel)]="m.birthName" formControlName="birthName">
                  <div class="form-control-feedback" *ngIf="hasLocalFieldError('birthName')">
                      <span
                        *ngIf="hasLocalFieldError('birthName', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- endregion birth name -->

            <!-- region Gender -->
            <div [ngStyle]="readonly ? '' : fieldStyle"
                 *ngIf="!(readonly && m.gender === undefined) && fieldModel.isManaged(CustomerRecordFieldType.GENDER)">
              <div class="form-group row mb-0"
                   [ngClass]="{ 'has-danger': hasLocalFieldError('gender') }">
                <label class="col-form-label form-control-label col-md-4 detail-title"
                       [class.required-field-label]="fieldModel.isRequired(CustomerRecordFieldType.GENDER)">
                  {{'CUSTOMER_RECORD_GENDER' | translate}}
                </label>
                <div class="col-md-8">
                  <select *ngIf="!readonly"
                          class="form-control"
                          [compareWith]="SelectUtils.compareStrings"
                          [(ngModel)]="m.gender" formControlName="gender"
                          [ngClass]="{ 'form-control-danger': hasLocalFieldError('gender') }">
                    <option *ngFor="let item of genderTypes" [ngValue]="item.id">{{item.text}}</option>
                  </select>
                  <div class="form-control-feedback" *ngIf="hasLocalFieldError('gender')">
                      <span
                        *ngIf="hasLocalFieldError('gender', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
                  </div>
                  <label
                    *ngIf="readonly" class="form-control detail-description"
                    readonly>{{genderText}}
                  </label>
                </div>
              </div>
            </div>
            <!-- endregion Gender -->

            <!-- region Mothers name -->
            <div [ngStyle]="readonly ? '' : fieldStyle"
                 *ngIf="!(readonly && m.mothersName === '') && fieldModel.isManaged(CustomerRecordFieldType.MOTHERS_NAME)">
              <div class="form-group row mb-0"
                   [ngClass]="{ 'has-danger': hasLocalFieldError('mothersName') }">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title"
                  [class.required-field-label]="fieldModel.isRequired(CustomerRecordFieldType.MOTHERS_NAME)">
                  {{'CUSTOMER_RECORD_MOTHERS_NAME' | translate}}
                </label>
                <div class="col-md-8">
                  <input type="text"
                         class="form-control"
                         placeholder="{{'CUSTOMER_RECORD_MOTHERS_NAME' | translate}}"
                         [readOnly]="readonly"
                         [ngClass]="{ 'form-control-danger': hasLocalFieldError('mothersName'), 'detail-description' : readonly }"
                         [(ngModel)]="m.mothersName" formControlName="mothersName">
                  <div class="form-control-feedback" *ngIf="hasLocalFieldError('mothersName')">
                      <span
                        *ngIf="hasLocalFieldError('mothersName', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- endregion Mothers name -->

            <!-- region IDENTITY_DOCUMENT_TYPE -->
            <div [ngStyle]="readonly ? '' : fieldStyle"
                 *ngIf="!(readonly && m.identityDocumentType === '') && fieldModel.isManaged(CustomerRecordFieldType.IDENTITY_DOCUMENT_TYPE)">
              <div class="form-group row mb-0"
                   [ngClass]="{ 'has-danger': hasLocalFieldError('identityDocumentType') }">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title"
                  [class.required-field-label]="fieldModel.isRequired(CustomerRecordFieldType.IDENTITY_DOCUMENT_TYPE)">
                  {{'CUSTOMER_RECORD_IDENTITY_DOCUMENT_TYPE' | translate}}
                </label>
                <div class="col-md-8">
                  <input type="text"
                         class="form-control"
                         placeholder="{{'CUSTOMER_RECORD_IDENTITY_DOCUMENT_TYPE' | translate}}"
                         [readOnly]="readonly"
                         [ngClass]="{ 'form-control-danger': hasLocalFieldError('identityDocumentType'), 'detail-description' : readonly }"
                         [(ngModel)]="m.identityDocumentType" formControlName="identityDocumentType">
                  <div class="form-control-feedback" *ngIf="hasLocalFieldError('identityDocumentType')">
                      <span
                        *ngIf="hasLocalFieldError('identityDocumentType', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- endregion IDENTITY_DOCUMENT_TYPE -->

            <!-- region IDENTITY_DOCUMENT_NUMBER -->
            <div [ngStyle]="readonly ? '' : fieldStyle"
                 *ngIf="!(readonly && m.identityDocumentNumber === '') && fieldModel.isManaged(CustomerRecordFieldType.IDENTITY_DOCUMENT_NUMBER)">
              <div class="form-group row mb-0"
                   [ngClass]="{ 'has-danger': hasLocalFieldError('identityDocumentNumber') }">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title"
                  [class.required-field-label]="fieldModel.isRequired(CustomerRecordFieldType.IDENTITY_DOCUMENT_NUMBER)">
                  {{'CUSTOMER_RECORD_IDENTITY_DOCUMENT_NUMBER' | translate}}
                </label>
                <div class="col-md-8">
                  <input type="text"
                         class="form-control"
                         placeholder="{{'CUSTOMER_RECORD_IDENTITY_DOCUMENT_NUMBER' | translate}}"
                         [readOnly]="readonly"
                         [ngClass]="{ 'form-control-danger': hasLocalFieldError('identityDocumentNumber'), 'detail-description' : readonly }"
                         [(ngModel)]="m.identityDocumentNumber" formControlName="identityDocumentNumber">
                  <div class="form-control-feedback" *ngIf="hasLocalFieldError('identityDocumentNumber')">
                      <span
                        *ngIf="hasLocalFieldError('identityDocumentNumber', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- endregion IDENTITY_DOCUMENT_NUMBER -->
          </div>
        </div>
      </div>

      <div class="container-vertical-padding-half" *ngIf="getOtherDataCardVisible()">
        <div class="card">

          <div class="card-header">
            {{'CUSTOMER_RECORD_OTHER_DATA' | translate}}
          </div>

          <div class="card-body">

            <!-- region INVOICE_DEADLINE_ADDITIONAL_DAYS -->
            <div [ngStyle]="readonly ? '' : fieldStyle"
                 *ngIf="readonly ? (m.invoiceDeadlineAdditionalDays !== undefined) && fieldModel.isManaged(CustomerRecordFieldType.INVOICE_DEADLINE_ADDITIONAL_DAYS) : fieldModel.isManaged(CustomerRecordFieldType.INVOICE_DEADLINE_ADDITIONAL_DAYS)">
              <div class="form-group row mb-0"
                   [ngClass]="{ 'has-danger': hasLocalFieldError('invoiceDeadlineAdditionalDays') }">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title"
                  [class.required-field-label]="fieldModel.isRequired(CustomerRecordFieldType.INVOICE_DEADLINE_ADDITIONAL_DAYS)">
                  {{'CUSTOMER_RECORD_INVOICE_DEADLINE_ADDITIONAL_DAYS' | translate}}
                  <i class="icomoon icomoon-info info-icon"
                     popover="{{'CUSTOMER_RECORD_INVOICE_DEADLINE_ADDITIONAL_DAYS_INFO' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-8">
                  <input type="text"
                         [textMask]="{mask: InputMask.NATURAL_INTEGER, guide: false}"
                         class="form-control"
                         placeholder="{{'CUSTOMER_RECORD_INVOICE_DEADLINE_ADDITIONAL_DAYS' | translate}}"
                         [readOnly]="readonly"
                         [ngClass]="{ 'form-control-danger': hasLocalFieldError('invoiceDeadlineAdditionalDays'), 'detail-description' : readonly }"
                         [(ngModel)]="m.invoiceDeadlineAdditionalDays" formControlName="invoiceDeadlineAdditionalDays">
                  <div class="form-control-feedback" *ngIf="hasLocalFieldError('invoiceDeadlineAdditionalDays')">
                      <span
                        *ngIf="hasLocalFieldError('invoiceDeadlineAdditionalDays', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- endregion INVOICE_DEADLINE_ADDITIONAL_DAYS -->

            <!-- region TASK_RECORD_DEADLINE_ADDITIONAL_DAYS -->
            <div [ngStyle]="readonly ? '' : fieldStyle"
                 *ngIf="readonly ? (m.taskRecordDeadlineAdditionalDays !== undefined) && fieldModel.isManaged(CustomerRecordFieldType.TASK_RECORD_DEADLINE_ADDITIONAL_DAYS) : fieldModel.isManaged(CustomerRecordFieldType.TASK_RECORD_DEADLINE_ADDITIONAL_DAYS)">
              <div class="form-group row mb-0"
                   [ngClass]="{ 'has-danger': hasLocalFieldError('taskRecordDeadlineAdditionalDays') }">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title"
                  [class.required-field-label]="fieldModel.isRequired(CustomerRecordFieldType.TASK_RECORD_DEADLINE_ADDITIONAL_DAYS)">
                  {{'CUSTOMER_RECORD_TASK_RECORD_DEADLINE_ADDITIONAL_DAYS' | translate}}
                  <i class="icomoon icomoon-info info-icon"
                     popover="{{'CUSTOMER_RECORD_TASK_RECORD_DEADLINE_ADDITIONAL_DAYS_INFO' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-8">
                  <input type="text"
                         [textMask]="{mask: InputMask.NATURAL_INTEGER, guide: false}" maxlength="5"
                         class="form-control"
                         placeholder="{{'CUSTOMER_RECORD_TASK_RECORD_DEADLINE_ADDITIONAL_DAYS' | translate}}"
                         [readOnly]="readonly"
                         [ngClass]="{ 'form-control-danger': hasLocalFieldError('taskRecordDeadlineAdditionalDays'), 'detail-description' : readonly }"
                         [(ngModel)]="m.taskRecordDeadlineAdditionalDays"
                         formControlName="taskRecordDeadlineAdditionalDays">
                  <div class="form-control-feedback" *ngIf="hasLocalFieldError('taskRecordDeadlineAdditionalDays')">
                      <span
                        *ngIf="hasLocalFieldError('taskRecordDeadlineAdditionalDays', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- endregion TASK_RECORD_DEADLINE_ADDITIONAL_DAYS -->
          </div>
        </div>
      </div>

    </div>
    <!--col end-->

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 card-custom-padding">

      <div
        *ngIf="(!readonly || postalAddressPresent())  && fieldModel.isManaged(CustomerRecordFieldType.POSTAL_ADDRESS_AND_INVOICE_ADDRESS_AND_NOTIFICATION_ADDRESS)"
        class="container-vertical-padding-half">
        <!-- region Postal address -->
        <app-postal-address-field
          [model]="m.postalAddress"
          [fieldStyle]="fieldStyle"
          [label]="'COMMON_LOCATION_POSTAL_ADDRESS' | translate"
          [readonly]="readonly"
          [requiredField]="fieldModel.isRequired(CustomerRecordFieldType.POSTAL_ADDRESS_AND_INVOICE_ADDRESS_AND_NOTIFICATION_ADDRESS)"
          [autoAdd]="fieldModel.isRequired(CustomerRecordFieldType.POSTAL_ADDRESS_AND_INVOICE_ADDRESS_AND_NOTIFICATION_ADDRESS)"
          [form]="form">
        </app-postal-address-field>
        <!-- endregion Postal address -->
      </div>

      <div
        *ngIf="(!readonly || notificationAddressPresent())  && fieldModel.isManaged(CustomerRecordFieldType.POSTAL_ADDRESS_AND_INVOICE_ADDRESS_AND_NOTIFICATION_ADDRESS)"
        class="container-vertical-padding-half">
        <!-- region notification address -->
        <app-billing-address
          [model]="m.notificationAddress"
          [postalAddress]="m.postalAddress"
          [addressEqPostal]="m.notificationAddressEqPostal"
          [postalAddressType]="m.postalAddress.type"
          [readonly]="readonly"
          [forceComplex]="false"
          [required]="false"
          [addressNameStringKey]="'NOTIFICATION_ADDRESS_HEADING'"
          [form]="form"
          (checkboxFalse)="onNotificationAddressCheckboxFalse($event)"
          (checkboxTrue)="onNotificationAddressCheckboxTrue($event)"
          (showDialog)="showDialogEmitter()" #notificationAddress>
        </app-billing-address>
        <!-- endregion notification address -->
      </div>

      <div
        *ngIf="(!readonly && !customerRecord)  && fieldModel.isManaged(CustomerRecordFieldType.POSTAL_ADDRESS_AND_INVOICE_ADDRESS_AND_NOTIFICATION_ADDRESS)"
        class="container-vertical-padding-half">
        <div class="card">
          <div class="card-header">
            {{'CUSTOMER_RECORD_BILLING_INFO_CREATE' | translate}}
          </div>

        <div class="card-body">
          <div class="d-flex justify-content-between">
            <label
              class="col-form-label form-control-label detail-title">{{'BILLING_ADDRESS_HEADING' | translate}} {{'SAME_AS_POSTAL_ADDRESS' |
              translate}}</label>
            <label *ngIf="!readonly" class="switch switch-text switch-info" style="margin-left: 0.3rem">
              <input type="checkbox" class="switch-input"
                     [(ngModel)]="m.billingInfoMatchesCustomerData"
                     [disabled]="readonly">
              <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
              <span class="switch-handle"></span>
            </label>
          </div>
          <app-customer-record-billing-info-base
            #billingInfo
            *ngIf="!m.billingInfoMatchesCustomerData"
            [model]="m.billingInfoModel"
            [form]="form">
          </app-customer-record-billing-info-base>
        </div>
        </div>
      </div>

      <div
        *ngIf="(m.emailAddresses.length > 0 || !readonly) && fieldModel.isManaged(CustomerRecordFieldType.EMAIL_ADDRESSES)"
        class="container-vertical-padding-half">
        <app-email-address-list
          [items]="m.emailAddresses"
          [fieldStyle]="fieldStyle"
          [label]="'CUSTOMER_RECORD_EMAIL_ADDRESSES' | translate"
          [readonly]="readonly"
          [form]="form"
          [required]="!readonly && this.fieldModel.isRequired(CustomerRecordFieldType.EMAIL_ADDRESSES)">
        </app-email-address-list>
      </div>

      <div
        *ngIf="(m.phoneNumbers.length > 0 || !readonly) && fieldModel.isManaged(CustomerRecordFieldType.PHONE_NUMBERS)"
        class="container-vertical-padding-half">
        <app-phone-number-list
          [items]="m.phoneNumbers"
          [fieldStyle]="fieldStyle"
          [label]="'CUSTOMER_RECORD_PHONE_NUMBERS' | translate"
          [readonly]="readonly"
          [form]="form"
          [required]="!readonly && this.fieldModel.isRequired(CustomerRecordFieldType.PHONE_NUMBERS)">
        </app-phone-number-list>
      </div>

    </div>

  </div>
  <!--row end-->

  <input type="submit" class="btn btn-primary-outline floating_apply_button" *ngIf="!readonly"
         (click)="onSaveButtonClicked(false)" value="{{'COMMON_BUTTON_APPLY'|translate}}"/>
  <input type="submit" class="btn btn-primary floating_save_button" *ngIf="!readonly"
         (click)="onSaveButtonClicked(true)" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>

</div>
