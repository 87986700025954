export class Strings {

  public static join(delimiter: string, texts: string[]): string {
    return texts.filter(Boolean).join(delimiter);
  }

  public static contains(text: string, what: string) {
    return text.indexOf(what) !== -1;
  }

  public static endsWith(text: string, suffix: string) {
    return text.indexOf(suffix, text.length - suffix.length) !== -1;
  }

  public static trimRight(text: string, what: string): string | undefined {
    if (typeof text !== 'string') {
      return undefined;
    }
    return text; // TODO: implementation
  }

  public static undefinedOrNonEmpty(text: string | undefined | null): string | undefined {
    if (!text || text === null) {
      return undefined;
    }
    text = text.toString().trim();
    if (text.length < 1) {
      return undefined;
    }
    return text;
  }

  public static nullOrNonEmpty(text: string | undefined | null): string | null {
    if (!text || text === null) {
      return null;
    }
    text = text.toString().trim();
    if (text.length < 1) {
      return null;
    }
    return text;
  }

  public static optToString(text?: string): string {
    return text ? text : '';
  }

}
