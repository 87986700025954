<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
    <app-breadcrumb-menu>
      <!-- region EDIT-->
      <app-breadcrumb-button
        *ngIf="rightModel.userGroupUpdate.hasRight()"
        [iconClass]="'icomoon-modify'"
        [titleStringKey]="'COMMON_BUTTON_EDIT'"
        uiSref="Admin.UserGroupEdit"
        [uiParams]="{ id: userGroupId }">
      </app-breadcrumb-button>
      <!-- endregion EDIT-->
    </app-breadcrumb-menu>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="card-custom-padding-complete animated fadeIn">

  <responsive-tabset class="fullscreen_tab">
    <responsive-tab heading="{{'COMMON_CARD_HEADER_BASE_DATA'|translate}}">

      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 card-custom-padding-complete">
        <div class="card">

          <div class="card-header">
            {{'USER_GROUP_PANEL_HEADING_DETAIL'|translate}}
          </div>

          <div class="card-body">

            <!-- region Name -->
            <div class="form-group row">
              <div class="col-md-4">
                <label class="mw-100 col-form-label form-control-label">{{'COMMON_NAME'|translate}}</label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <label class="form-control detail-description">{{model.name}}</label>
                </div>
              </div>
            </div>
            <!-- endregion Name -->

            <!-- region ExternalId -->
            <div class="form-group row">
              <div class="col-md-4">
                <label class="mw-100 col-form-label form-control-label">{{'COMMON_EXTERNAL_ID'|translate}}</label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <label class="form-control detail-description">{{model.externalId}}</label>
                </div>
              </div>
            </div>
            <!-- endregion ExternalId -->

            <!-- region Company -->
            <div class="form-group row" *ngIf="!protectedGroup && model.companies.length > 0">
              <div class="col-md-4">
                <label class="mw-100 col-form-label form-control-label">{{'COMMON_COMPANY'|translate}}</label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <angular2-multiselect
                    [(ngModel)]="model.companies"
                    (onReadonlyItemClick)="navigateToCompanyDetail($event)"
                    [data]="model.companies"
                    [settings]="multiDropdownSettings"
                    [readonly]="true">
                  </angular2-multiselect>
                </div>
              </div>
            </div>
            <!-- endregion Company -->

            <!-- region Roles -->
            <div class="form-group row" *ngIf="model.roles.length > 0">
              <div class="col-md-4">
                <label class="mw-100 col-form-label form-control-label">{{'ROLE_LIST_HEADING'|translate}}</label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <angular2-multiselect
                    [(ngModel)]="model.roles"
                    [data]="model.roles"
                    [settings]="multiDropdownSettings"
                    [readonly]="true"
                    (onReadonlyItemClick)="navigateToRoleDetail($event)">
                  </angular2-multiselect>
                </div>
              </div>
            </div>
            <!-- endregion Roles -->

            <!-- region Application types -->
            <div class="form-group row" *ngIf="model.application_types.length > 0">
              <div class="col-md-4">
                <label class="mw-100 col-form-label form-control-label">{{'USER_GROUP_LABEL_SCOPE'|translate}}</label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <angular2-multiselect
                    [(ngModel)]="model.application_types"
                    [data]="model.application_types"
                    [settings]="multiDropdownSettings"
                    [readonly]="true">
                  </angular2-multiselect>
                </div>
              </div>
            </div>
            <!-- endregion Application types -->

            <!-- region Users -->
            <div class="form-group row" *ngIf="model.users.length > 0">
              <div class="col-md-4">
                <label class="mw-100 col-form-label form-control-label">{{'COMMON_USERS'|translate}}</label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <angular2-multiselect
                    [(ngModel)]="model.users"
                    [data]="model.users"
                    [settings]="multiDropdownSettings"
                    (onReadonlyItemClick)="navigateToUserDetail($event)"
                    [readonly]="true">
                  </angular2-multiselect>
                </div>
              </div>
            </div>
            <!-- endregion Users -->

            <!-- region mapped ldap groups -->
            <div class="form-group row"
                 *ngIf="rightModel.userGroupLdapMapRead.hasRight() && mappedLdapGroupsText.length > 0">
              <div class="col-md-4">
                <label
                  class="mw-100 col-form-label form-control-label">{{'USER_GROUP_LDAP_LIST_HEADING'|translate}}</label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <div>
                    <label class="form-control detail-description"
                           readonly>{{mappedLdapGroupsText}}</label>
                  </div>
                </div>
              </div>
            </div>
            <!-- endregion mapped ldap groups -->

          </div> <!--card-body-->
        </div> <!--card-->
      </div>

      <app-usergroup-type-based-roles
        [userGroupId]="userGroupId"
        [readonly]="true"
        [rightModel]="rightModel">
      </app-usergroup-type-based-roles>
    </responsive-tab>
    <responsive-tab *ngIf="rightModel.userGroupHistoryLog.hasRight()"
                    heading="{{'COMMON_HISTORY'|translate}}">
      <app-history-log
        [queryModel]="historyQueryModel"
        [Type]="UserGroup"
        [historyList]="historyList"
        (openHistoryDetail)="historyDetailModal.openDetailDialog($event)"
        (itemsPerPageChangedOut)="itemsPerPageChanged($event)"
        (orderByOut)="orderBy($event)"
        (pageChangedOut)="pageChanged($event)"
        #historyComponent>
      </app-history-log>
    </responsive-tab>

  </responsive-tabset>
</div>

<app-history-detail-dialog
  #historyDetailModal>
</app-history-detail-dialog>
