/* eslint-disable */
import { Injectable } from '@angular/core';
import { UiConstants } from '../../util/core-utils';
import { Order, OrderType } from '../util/services';
import { DisabledItem, SearchUtils } from '../../util/search-utils';
import { BaseSearch, BaseSearchService } from '../base.search-service';
import { LoggedInUserStorage } from '../util/storages';
import { StockItemUnitOfMeasure } from './stock-item-unit-of-measure';

/* eslint-enable */

@Injectable()
export class StockItemMeasurementSearchService extends BaseSearchService {

  protected createStorage(): StockItemMeasurementSearch.Storage {
    return new StockItemMeasurementSearch.Storage();
  }

}

export namespace StockItemMeasurementSearch {

  export class Model extends SearchUtils.SearchModel {

    includeBaseUnit: boolean = false;
    disabled?: DisabledItem = DisabledItem.active();

    isEmpty(): boolean {
      return !this.includeBaseUnit
        && (this.disabled ? this.disabled.id === DisabledItem.active().id : true);
    }

    reset() {
      this.includeBaseUnit = false;
      this.disabled = DisabledItem.active();
    }

  }

  export interface SearchDataGetRequest extends BaseSearch.SearchDataGetRequest {
    stockItemId: number;
  }

  export interface SearchDataResult {
    searchData: SearchData;
  }

  export interface SearchDataSetRequest extends BaseSearch.SearchDataSetRequest {
    stockItemId: number;
  }

  export interface SearchData extends BaseSearch.SearchData {
    order: Order<StockItemUnitOfMeasure.OrderField>;
    includeBaseUnit: boolean;
    disabled?: DisabledItem
  }

  interface StoredSearchRawData extends BaseSearch.StoredSearchRawData {
    order: Order<StockItemUnitOfMeasure.OrderField>;
    includeBaseUnit: boolean;
    disabled?: DisabledItem
  }

  export class Storage extends BaseSearch.Storage {

    constructor() {
      const defaultSearchData = {
        itemsPerPage: UiConstants.pageNumbers[0],
        pageNumber: 1,
        order: {
          field: StockItemUnitOfMeasure.OrderField.ID,
          type: OrderType.DESC
        },
        includeBaseUnit: false,
        disabled: DisabledItem.active()
      };
      /**
       * Increment this version if you change the SearchData interface.
       */
      const versionNumber: number = 1;
      super(defaultSearchData,
        versionNumber);
    }

    protected generateKey(request: SearchDataGetRequest | SearchDataSetRequest): string {
      return 'UserId(' + LoggedInUserStorage.getInstance().getUserId()
        + ')-Search-StockItemMeasurement-StockItemId(' + request.stockItemId + ')';
    }

    protected fromRaw(data: StoredSearchRawData): BaseSearch.SearchDataResult {
      const searchData: SearchData = {
        itemsPerPage: data.itemsPerPage,
        pageNumber: data.pageNumber,
        order: data.order,
        includeBaseUnit: data.includeBaseUnit,
        disabled: data.disabled
      };
      return {
        searchData: searchData
      };
    }

    protected toRaw(data: SearchData): StoredSearchRawData {
      return {
        version: this.VERSION,
        itemsPerPage: data.itemsPerPage,
        pageNumber: data.pageNumber,
        order: data.order,
        includeBaseUnit: data.includeBaseUnit,
        disabled: data.disabled
      };
    }

  }

}
