export class Node {
  id: string = '';
  name: string = '';
  children: Node[] = [];
  isLeaf: boolean = false;
  isSelected: boolean = false;
  isVisible: boolean = true;
  childrenShown: boolean = false;

  get selected(): boolean {
    if (this.isLeaf) {
      return this.isSelected && this.isVisible;
    }
    return this.isAllDescendantsSelected();
  }

  set selected(selected: boolean) {
    if (this.isLeaf) {
      this.isSelected = selected && this.visible;
      return;
    }
    this.children.forEach((child) => {
      child.selected = selected;
    });
  }

  toggleSelected() {
    const prevValue = this.selected;
    setTimeout(() => {
      this.selected = !prevValue;
    });
  }

  private isAllDescendantsSelected(): boolean {
    for (let i = 0; i < this.children.length; i++) {
      if (!this.children[i].selected && this.children[i].visible) {
        return false;
      }
    }
    return true;
  }

  get partSelected(): boolean {
    if (this.isLeaf) {
      return false;
    }
    return !this.selected && this.isAnyDescendantSelected();
  }

  private isAnyDescendantSelected(): boolean {
    for (let i = 0; i < this.children.length; i++) {
      const child: Node = this.children[i];
      if ((child.selected || child.partSelected) && child.visible) {
        return true;
      }
    }
    return false;
  }

  sortChildren() {
    this.children.sort((a, b) => a.name.localeCompare(b.name));
    this.children.forEach((node) => {
      node.sortChildren();
    });
  }

  get hasChildren(): boolean {
    return this.children.length > 0;
  }

  copy(): Node {
    const newNode: Node = new Node;
    newNode.id = this.id;
    newNode.name = this.name;
    newNode.children = Object.assign([], this.children);
    newNode.isLeaf = this.isLeaf;
    newNode.isSelected = this.isSelected;
    newNode.isVisible = this.isVisible;
    newNode.childrenShown = this.childrenShown;
    return newNode;
  }

  toggleChildrenShown() {
    this.childrenShown = !this.childrenShown;
  }

  filterSelectedChildren() {
    const newChildren: Node[] = [];
    this.children.forEach((child) => {
      if (child.selected || child.partSelected) {
        const newChild: Node = child.copy();
        newChild.filterSelectedChildren();
        newChildren.push(newChild);
      }
    });
    this.children = newChildren;
  }

  mergeChildren(children: Node[]) {
    children.forEach((child) => {
      if (this.getChild(child.id)) {
        this.getChild(child.id)!.mergeChildren(child.children);
      }
      else {
        child.selected = false;
        child.childrenShown = false;
        this.children.push(child);
      }
    });
  }

  getChild(id: string): Node | undefined {
    for (let i = 0; i < this.children.length; i++) {
      if (this.children[i].id === id) {
        return this.children[i];
      }
    }
    return undefined;
  }

  removeSelectedChildren() {
    for (let i = 0; i < this.children.length; i++) {
      const child: Node = this.children[i];
      if (child.isLeaf) {
        if (child.isSelected) {
          const index = this.children.indexOf(child);
          this.children.splice(index, 1);
          i--;
        }
      }
      else if (child.selected && !child.hasInvisibleDescendant) {
        const index = this.children.indexOf(child);
        this.children.splice(index, 1);
        i--;
      }
      else if (child.partSelected) {
        child.removeSelectedChildren();
      }
    };
  }

  filterChildren(q: string) {
    this.isVisible = this.id.toLowerCase().includes(q.toLowerCase());
    this.children.forEach((child) => {
      child.filterChildren(q);
    });
  }

  get visible(): boolean {
    if (this.isLeaf) {
      return this.isVisible;
    }
    return this.isAnyDescendantVisible();
  }

  private isAnyDescendantVisible(): boolean {
    for (let i = 0; i < this.children.length; i++) {
      if (this.children[i].visible) {
        return true;
      }
    }
    return false;
  }

  get hasInvisibleDescendant(): boolean {
    for (let i = 0; i < this.children.length; i++) {
      const child: Node = this.children[i];
      if (!child.visible) {
        return true;
      }
      if (child.hasInvisibleDescendant) {
        return true;
      }
    }
    return false;
  }

  countLeafs(counter) {
    if (this.isLeaf) {
      counter.count++;
      return;
    }
    this.children.forEach((child) => {
      child.countLeafs(counter);
    });
  }

  getLeafs(result: Node[]) {
    if (this.isLeaf) {
      result.push(this);
      return;
    }
    this.children.forEach((child) => {
      child.getLeafs(result);
    });
  }

  countSelectedLeafs(counter) {
    if (this.isLeaf && this.isSelected) {
      counter.count++;
      return;
    }
    this.children.forEach((child) => {
      child.countSelectedLeafs(counter);
    });
  }
}

export class Tree {

  data: Node[];

  constructor(data: Node[]) {
    this.data = data;
  }

  setData(data: Node[]) {
    this.data = data;
    this.sort();
  }

  getNode(id: string): Node | undefined {
    return this.findNode(id, this.data);
  }

  private findNode(id: string, data: Node[]) {
    for (let i = 0; i < data.length; i++) {
      if (id === data[i].id) {
        return data[i];
      }
      if (id.startsWith(data[i].id)) {
        this.findNode(id, data[i].children);
      }
    }
    return undefined;
  }

  getSelectedTree(): Node[] {
    const newTree: Node[] = [];
    this.data.forEach((node) => {
      if (node.selected || node.partSelected) {
        const newNode: Node = node.copy();
        newNode.filterSelectedChildren();
        newTree.push(newNode);
      }
    });
    return newTree;
  }

  sort() {
    this.data.sort((a, b) => a.name.localeCompare(b.name));
    this.data.forEach((node) => {
      node.sortChildren();
    });
  }

  mergeData(data: Node[]) {
    data.forEach((node) => {
      if (this.getNode(node.id)) {
        this.getNode(node.id)!.mergeChildren(node.children);
      }
      else {
        node.selected = false;
        node.childrenShown = false;
        this.data.push(node);
      }
    });
  }

  removeSelectedTree() {
    for (let i = 0; i < this.data.length; i++) {
      const node: Node = this.data[i];
      if (node.selected && !node.hasInvisibleDescendant) {
        const index = this.data.indexOf(node);
        this.data.splice(index, 1);
        i--;
      }
      else if (node.partSelected) {
        node.removeSelectedChildren();
      }
    }
  }

  filter(q: string) {
    this.data.forEach((node) => {
      node.isVisible = node.id.toLowerCase().includes(q.toLowerCase());
      node.filterChildren(q);
    });
  }

  toggleAll() {
    const select: boolean = this.selectedLeafCount < this.leafCount;
    this.data.forEach((node) => {
      node.selected = select;
    });
  }

  get leafCount(): number {
    const counter = {
      count: 0
    };
    this.data.forEach((node) => {
      node.countLeafs(counter);
    });
    return counter.count;
  }

  get leafs(): Node[] {
    const result: Node[] = [];
    this.data.forEach((node) => {
      node.getLeafs(result);
    });
    return result;
  }

  get selectedLeafCount(): number {
    const counter = {
      count: 0
    };
    this.data.forEach((node) => {
      node.countSelectedLeafs(counter);
    });
    return counter.count;
  }
}

export class TreeUtils {
  static treePut(level: Node[], parents: string[], item: string, descendants: string[], definition?: string) {
    if (level[item]) {
      this.processDescendants(level, parents, item, descendants, definition);
    }
    else {
      const node: Node = new Node();
      node.id = parents.length > 0 ? parents.join(':') + ':' + item : item;
      node.name = item;
      level[item] = node;
      this.processDescendants(level, parents, item, descendants, definition);
    }
  }

  static processDescendants(level: Node[], parents: string[], item: string, descendants: string[], definition?: string) {
    if (descendants.length > 0) {
      parents.push(item);
      const nextItem: string = descendants.shift()!;
      this.treePut(level[item].children, parents, nextItem, descendants, definition);
    }
    else {
      level[item].id = definition;
      level[item].isLeaf = true;
    }
  }

  static convertArray(tree: Node[]): Node[] {
    const result: Node[] = [];
    Object.keys(tree).forEach((k) => {
      result.push(this.convertNode(tree[k]));
    });
    return result;
  }

  static convertNode(node: Node): Node {
    const newNode = node.copy();
    newNode.children = [];
    Object.keys(node.children).forEach((c) => {
      newNode.children.push(this.convertNode(node.children[c]));
    });
    return newNode;
  }
}
