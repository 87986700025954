/* eslint-disable */
import { Component, ViewChild, } from '@angular/core';
import {
  FormRecordFieldContext,
  FormRecordFieldValueUpdateArgs,
  FormRecordFieldView,
  FormRecordFieldViewContext,
  FormRecordFieldViewModel,
  SetTmpReadonlyArgs,
  SetTmpReadonlyResult,
} from '../../../../../util/form/form-utils';
import { Form } from '../../../../../lib/form/form.service';
import { AbstractControl, FormBuilder, FormGroup, Validators, } from '@angular/forms';
import { FormRef, ForwardingFormRef, LocalFormGroupValidationErrors, } from '../../../../../lib/util/services';
import { FormRecord } from '../../../../../lib/form/form-record.service';
import { AppValidators } from '../../../../../util/app-validators';
import { Models } from '../../../../../util/model-utils';
import { NgbDatepicker, NgbDateStruct, } from '@ng-bootstrap/ng-bootstrap';
import { Dates, DefaultOffsetDateTimeTemplate, OffsetDateTime, } from '../../../../../lib/util/dates';
import { AppNgbTimeStruct, NgbDatePickerParserFormatter } from '../../../../../util/ngb-datepicker';
import { FieldActivationState, FieldActivationStateResolver, } from '../../../../../util/form/form-editors';
import { Command } from '../../../../../util/command';
import { TranslateService } from '@ngx-translate/core';
import { StringKey } from '../../../../../app.string-keys';
import { FormRecordInactivityManager } from '../../manager/form-record-inactivity-manager';
import { Equals } from '../../../../../util/equals';
import { Observable } from 'rxjs';

/* eslint-enable */

@Component({
  selector: 'app-form-record-date-time-field',
  templateUrl: 'form-record-date-time-field.component.html',
  styleUrls: ['form-record-date-time-field.component.scss'],
})
export class FormRecordDateTimeFieldComponent implements FormRecordFieldView {

  public readonly selector: Form.FieldDataTypeSelector.DATE_TIME;

  formGroup: FormGroup;

  model: Model = new Model();

  @ViewChild('date_picker', { static: true })
  datePicker: NgbDatepicker;

  // context fields are always optional
  formRecordFieldContext?: FormRecordFieldContext;
  formRecordInactivityManager?: FormRecordInactivityManager;
  private fieldId?: number;
  private htmlForm?: FormRef;

  tmpReadonly: boolean = false;

  private optionalValue: boolean = false;

  private formGroupValidationErrors: LocalFormGroupValidationErrors;

  private readonlyFormFn: () => boolean = () => true;
  private readonlyFieldFn: () => boolean = () => false;
  private hiddenFieldFn: () => boolean = () => false;
get nonEditable(): boolean {
    return FieldActivationStateResolver.isNonEditable(
      this.fieldActivationState
    );
  }

  get requiredDisabled(): boolean {
    return FieldActivationStateResolver.isRequiredDisabled(
      this.fieldActivationState
    );
  }

  get required(): boolean {
    const optional = this.optionalValue;
    const requiredDisabled = this.requiredDisabled;
    return !optional && !requiredDisabled;
  }

  private get fieldActivationState(): FieldActivationState {
    return FieldActivationStateResolver.resolveFieldActivationState({
      readonlyFormFn: () => this.readonlyFormFn(),
      readonlyFieldFn: () => this.readonlyFieldFn(),
      tmpReadonlyFieldFn: () => this.tmpReadonly,
    });
  }

  private get canApplyDefaultValue(): boolean {
    return FieldActivationStateResolver.canApplyDefaultValue({
      formRecordFieldContext: () => {
        return this.formRecordFieldContext;
      },
      fieldActivationState: () => {
        return this.fieldActivationState;
      },
    });
  }

  private get dateValueFormControl(): AbstractControl {
    return this.formGroup.get('dateValue')!;
  }

  private get timeValueFormControl(): AbstractControl {
    return this.formGroup.get('timeValue')!;
  }

  private get reqContext() {
    return this.formRecordFieldContext!;
  }

  private get reqAttrs(): Form.FieldDataTypeDateTimeAttributes {
    return this.formRecordFieldContext!.field.dataType.dateTimeAttributes!;
  }

  private get reqDefaultValue(): DateAndTime {
    const attrs = this.reqAttrs;
    const nowDateTimeValue = this.datePickerParserFormatter.fromOffsetDateTime(Dates.now())!;
    const defaultDateTimeValue = this.datePickerParserFormatter.fromOffsetDateTime(attrs.defaultValue);
    const dateValue = attrs.defaultToday
      ? nowDateTimeValue
      : defaultDateTimeValue;
    const timeValue = attrs.defaultToday
      ? nowDateTimeValue
      : Models.optToNgbTime(defaultDateTimeValue);
    return new DateAndTime(dateValue, timeValue);
  }

  constructor(fb: FormBuilder,
              private translateService: TranslateService,
              private datePickerParserFormatter: NgbDatePickerParserFormatter) {
    this.formGroup = this.createFormGroup(fb);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(
      this.createForwardingHtmlForm(),
      this.formGroup,
    );
  }

  setTmpReadonly(args: SetTmpReadonlyArgs): Command<SetTmpReadonlyResult> {
    const previousTmpReadonly = this.tmpReadonly;
    const previousValue = this.model.value;
    return {
      execute: async () => {
        const inactiveValue: DateAndTime = DateAndTime.empty();
        const defaultValue: DateAndTime = this.reqDefaultValue;
        const value: DateAndTime = this.model.createDateAndTime();
        let changed = false;
        if (this.tmpReadonly !== args.tmpReadonly) {
          if (args.tmpReadonly) {
            changed = FieldActivationStateResolver.inactivationChangesTheValue({
              debugId: this.reqContext.field.title,
              valueIsEmpty: value.equals(inactiveValue),
              valueEqualsDefaultValue: value.equals(defaultValue),
              defaultValueIsEmpty: defaultValue.equals(inactiveValue),
              canApplyDefaultValue: this.canApplyDefaultValue
            });
            this.model.setDateAndTime(inactiveValue);
            this.tmpReadonly = args.tmpReadonly; // last
          }
          else {
            this.tmpReadonly = args.tmpReadonly; // first
            this.applyDefaultValue();
          }
          this.dateValueFormControl.updateValueAndValidity();
          this.timeValueFormControl.updateValueAndValidity();
        }
        return {
          changed: changed
        };
      },
      undo: async () => {
        this.tmpReadonly = previousTmpReadonly;
        this.model.value = previousValue;
        this.dateValueFormControl.updateValueAndValidity();
        this.timeValueFormControl.updateValueAndValidity();
      }
    };
  }

  registerField(context: FormRecordFieldContext, originalModel?: any): any {
    if (originalModel) {
      this.model = originalModel;
    }
    this.formRecordFieldContext = context;
    this.fieldId = context.field.fieldId;
    this.htmlForm = context.htmlForm;
    this.readonlyFormFn = context.readonly;
    this.hiddenFieldFn = () => Form.FormFieldValidationType.HIDDEN === context.validationType;
    this.readonlyFieldFn = () => Form.FormFieldValidationType.READONLY === context.validationType
    || Form.FormFieldValidationType.HIDDEN === context.validationType;
    const attrs = context.field.dataType.dateTimeAttributes!;
    this.optionalValue = Form.FormFieldValidationType.REQUIRED !== context.validationType;
    this.model.title = context.field.title;
    this.model.hint = Models.optToString(context.field.hint);
    this.model.placeholder = Models.optToString(attrs.hint);
    this.applyDefaultValue();
    if (context.fieldRecord) {
      this.registerFieldData(context.fieldRecord);
    }
    return this.model;
  }

  private applyDefaultValue() {
    if (this.canApplyDefaultValue) {
      this.model.setDateAndTime(this.reqDefaultValue);
    }
  }

  private registerFieldData(fieldRecord: FormRecord.FieldComposed): void {
    const attrs = fieldRecord.data.dateTimeAttributes!;
    if (fieldRecord) {
      if (attrs.value) {
        if (this.readonlyFieldFn() && this.reqContext.cloning()) {
          // Do not use read-only data for cloning.
          // Note that readonly field is filtered from the service request.
          this.model.value = null;
        }
        else {
          this.model.value = this.datePickerParserFormatter.fromOffsetDateTime(attrs.value);
        }
      }
      else {
        this.model.value = null;
      }
    }
  }

  registerFieldViews(context: FormRecordFieldViewContext): void {
    this.formRecordInactivityManager = context.inactivityManager;
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    this.formGroup.updateValueAndValidity(); // now() is always different, revalidation required each time
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  validateWithInterrupt(): boolean {
    return false;
  }

  shouldNotifyAfterCreation(): boolean {
    return false;
  }

  afterFormRecordCreation(formRecordId: number): Observable<any> | undefined {
    return undefined;
  }

  createModel(): FormRecordFieldViewModel {
    if (this.fieldId === undefined) {
      throw new Error('Field ID is undefined');
    }
    const attrs: FormRecord.FieldDataDateTimeAttributes = {
      value: this.datePickerParserFormatter.toOffsetDateTime(this.model.dateValue, this.model.timeValue),
    };
    return {
      fieldEditRequest: {
        fieldId: this.fieldId,
        data: {
          dateTimeAttributes: attrs
        }
      }
    };
  }

  onSelect() {
    this.formRecordInactivityManager!.onGeneralFieldChangedByUser(this);
  }

  onInput() {
    this.formRecordInactivityManager!.onGeneralFieldChangedByUser(this);
  }

  private createForwardingHtmlForm() {
    return new ForwardingFormRef({
      formFn: () => {
        return this.htmlForm;
      }
    });
  }

  private createFormGroup(fb: FormBuilder) {
    const valueValidator = AppValidators.validateNgbDateTime({
      datePickerParserFormatter: this.datePickerParserFormatter,
      dateValue: () => this.model.dateValue,
      timeValue: () => this.model.timeValue,
    });
    const rangeValidator = AppValidators.validateOffsetDateTimeRange({
      datePickerParserFormatter: this.datePickerParserFormatter,
      minDate: () => this.minNgbValue,
      minTime: () => this.minNgbValue,
      maxDate: () => this.maxNgbValue,
      maxTime: () => this.maxNgbValue,
      dateValue: () => this.model.dateValue,
      timeValue: () => this.model.timeValue
    });
    return fb.group(
      {
        dateValue: fb.control(
          {value: this.model.dateValue},
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => {
                return !this.required;
              }
            }),
            valueValidator,
            rangeValidator
          ]
        ),
        timeValue: fb.control(
          {value: this.model.timeValue},
          [
            valueValidator,
            rangeValidator
          ]
        ),
      },
    );
  }

  private get minNgbValue(): NgbDateStruct & AppNgbTimeStruct | null {
    return this.datePickerParserFormatter.fromOffsetDateTime(this.minValue);
  }

  private get minValue(): OffsetDateTime | null {
    if (!this.formRecordFieldContext) {
      return null;
    }
    const attrs = this.formRecordFieldContext.field.dataType.dateTimeAttributes!;
    if (attrs.disablePast) {
      return Dates.now();
    }
    return attrs.minValue;
  }

  get minValueText(): string {
    const minValue = this.minValue;
    if (!minValue) {
      return 'min'; // should not happen :P
    }
    return this.format(minValue);
  }

  private get maxNgbValue(): NgbDateStruct & AppNgbTimeStruct | null {
    return this.datePickerParserFormatter.fromOffsetDateTime(this.maxValue);
  }

  private get maxValue(): OffsetDateTime | null {
    if (!this.formRecordFieldContext) {
      return null;
    }
    const attrs = this.formRecordFieldContext.field.dataType.dateTimeAttributes!;
    if (attrs.disableFuture) {
      return Dates.now();
    }
    return attrs.maxValue;
  }

  get maxValueText(): string {
    const maxValue = this.maxValue;
    if (!maxValue) {
      return 'max'; // should not happen :P
    }
    return this.format(maxValue);
  }

  private format(minValue: OffsetDateTime): string {
    const pattern = this.translateService.instant(StringKey.MOMENT_SHORT_DATE_TIME_PATTERN);
    const template = new DefaultOffsetDateTimeTemplate(pattern);
    return minValue.format(template);
  }

  updateValue(data: FormRecordFieldValueUpdateArgs) {
  }

  onValueChanged() {
    this.formGroup.controls['dateValue'].updateValueAndValidity();
    this.formGroup.controls['timeValue'].updateValueAndValidity();
  }

}

export class Model {

  title: string = '';
  hint: string = '';
  placeholder: string = '';
  dateValue: NgbDateStruct | null = null;
  timeValue: AppNgbTimeStruct = Models.zeroNgbTime();

  get value(): NgbDateStruct &  AppNgbTimeStruct | null {
    return Models.mergeNgbDateAndTime(this.dateValue, this.timeValue);
  }

  set value(value: NgbDateStruct &  AppNgbTimeStruct | null) {
    this.dateValue = value;
    this.timeValue = value ? value : Models.zeroNgbTime();
  }

  createDateAndTime() {
    return new DateAndTime(this.dateValue, this.timeValue);
  }

  setDateAndTime(o: DateAndTime) {
    this.dateValue = o.dateValue;
    this.timeValue = o.timeValue;
  }

}

class DateAndTime {

  public static empty() {
    return new DateAndTime(null, Models.zeroNgbTime());
  }

  constructor(
      public readonly dateValue: NgbDateStruct | null,
      public readonly timeValue: AppNgbTimeStruct) {
  }

  public equals(o: DateAndTime) {
    if (this.dateValue === null && o.dateValue === null) {
      return true;
    }
    return Equals.deepEqual(this, o);
  }

}
