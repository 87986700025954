import { Component, Input, OnInit } from '@angular/core';
import { Icon } from '../../../lib/task/icon.service';

@Component({
  selector: 'app-type-icon',
  templateUrl: './type-icon.component.html',
  styleUrls: ['./type-icon.component.scss']
})
export class TypeIconComponent implements OnInit {

  private _icon?: Icon.Icon;

  @Input()
  set iconModel(value: Icon.IconModel) {
    this._icon = Icon.IconModel.toServiceIcon(value);
  }

  @Input()
  selected: boolean = false;

  @Input()
  big: boolean = false;

  @Input()
  set icon(value: Icon.Icon | undefined) {
    this._icon = value;
  }
  get icon(): Icon.Icon | undefined {
    return this._icon;
  }


  constructor() {
  }

  ngOnInit(): void {
  }

  getClasses(icon: Icon.Icon) {
    let classes = icon.iconSet.setClass;
    if (icon.iconSet.usageType === 'CLASS') {
      classes += ' ' + icon.iconSet.iconCode;
    }
    return classes;
  }

  getText(icon: Icon.Icon) {
    return icon.iconSet.usageType === 'TEXT' ? icon.iconSet.iconCode : '';
  }

}
