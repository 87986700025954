/* eslint-disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {BaseHttpService, ResourceHelper} from '../../util/http-services';
import {ResourceQueryResult} from '../../util/services';

/* eslint-enable */

@Injectable()
export class ContractNumberResourceService extends BaseHttpService {

  // <editor-fold desc="CRUD">

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/contract-numbers/');
  }

  query(request: ContractNumberResource.QueryRequest): Observable<ResourceQueryResult<ContractNumberResource.ContractNumber>> {
    return this.http.get<ResourceQueryResult<ContractNumberResource.ContractNumber>>(this.url, this.parseParams(request));
  }

  // </editor-fold>

  get(request: ContractNumberResource.GetRequest): Observable<ContractNumberResource.ContractNumber> {
    return this.http.get<ContractNumberResource.ContractNumber>(this.url + `${request.id}`, this.parseParams(request));
  }

}

export namespace ContractNumberResource {

  // <editor-fold desc="CRUD">

  export interface QueryRequest {
    fields?: string;
    filter?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
    rights?: string;
    no_progress_bar?: boolean;
  }

  export interface ContractNumber {
    id: number;
    granted_rights: string[];
    number: string;
  }

  export interface GetRequest {
    id: number;
    fields?: string;
    rights?: string;
  }

  // </editor-fold>

}
