<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 p-0">
    <div class="card">

      <div class="card-header">
        {{'USER_GROUP_PANEL_HEADING_CLONE'|translate}}
      </div>

      <div class="card-body">

        <form class="form-horizontal bordered-row" (ngSubmit)="clone()">

          <!-- region Name -->
          <div class="form-group row" [ngClass]="{ 'has-danger': fieldErrors.name }">
            <div class="col-md-4">
              <label for="name"
                     class="mw-100 col-form-label form-control-label required-field-label">
                {{'COMMON_NAME'|translate}}
              </label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <input type="text" [(ngModel)]="model.name" (ngModelChange)="removeFieldError(fieldErrors.name)"
                       maxlength="{{getTextMaximumLength()}}" class="form-control"
                       [ngClass]="{ 'form-control-danger': fieldErrors.name }" name="name" id="name"
                       placeholder="{{'USER_GROUP_PLACEHOLDER_NAME'|translate}}" required/>
                <div class="form-control-feedback" *ngIf="fieldErrors.name">{{fieldErrors.name.text}}</div>
              </div>
            </div>
          </div>
          <!-- endregion Name -->

          <!-- region ExternalId -->
          <div class="form-group row" [ngClass]="{ 'has-danger': fieldErrors.external_id }">
            <div class="col-md-4">
              <label for="external_id"
                     class="mw-100 col-form-label form-control-label required-field-label">
                {{'COMMON_EXTERNAL_ID'|translate}}
              </label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <input type="text" [(ngModel)]="model.externalId" (ngModelChange)="removeFieldError(fieldErrors.external_id)"
                       maxlength="{{getTextMaximumLength()}}" class="form-control"
                       [ngClass]="{ 'form-control-danger': fieldErrors.external_id }" name="external_id" id="external_id"
                       placeholder="{{'COMMON_EXTERNAL_ID'|translate}}"/>
                <div class="form-control-feedback" *ngIf="fieldErrors.external_id">{{fieldErrors.external_id.text}}</div>
              </div>
            </div>
          </div>
          <!-- endregion ExternalId -->
          <!-- region Company -->
          <div class="form-group row" [ngClass]="{ 'has-danger': fieldErrors.company_ids }">
            <div class="col-md-4">
              <label class="mw-100 col-form-label form-control-label">{{'COMMON_COMPANY'|translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  *ngIf="!companyDisabled"
                  [(ngModel)]="model.companies"
                  [ngModelOptions]="{standalone: true}"
                  [ngClass]="{ 'form-control-danger': fieldErrors.company_ids }"
                  (ngModelChange)="removeFieldError(fieldErrors.company_ids)"
                  (onSelect)="onCompanyChanged()"
                  (onDeSelect)="onCompanyChanged()"
                  [data]="companies"
                  [settings]="dropdownSettingsForCompany">
                </angular2-multiselect>
                <label *ngIf="companyDisabled">{{model.companies[0].text}}</label>
                <div class="form-control-feedback" *ngIf="fieldErrors.company_ids">{{fieldErrors.company_ids.text}}
                </div>
              </div>
            </div>
          </div>
          <!-- endregion Company -->

          <!-- region Roles -->
          <div class="form-group row"
               [ngClass]="{ 'has-danger': fieldErrors.roles }">
            <div class="col-md-4">
              <label class="mw-100 col-form-label form-control-label">{{'ROLE_LIST_HEADING'|translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  [(ngModel)]="model.roles"
                  [ngModelOptions]="{standalone: true}"
                  [data]="selectableRoles"
                  [settings]="dropdownSettings"                  (ngModelChange)="removeFieldError(fieldErrors.roles)"
                  [ngClass]="{ 'form-control-danger': fieldErrors.roles }">
                </angular2-multiselect>
                <div class="form-control-feedback" *ngIf="fieldErrors.roles">
                  {{fieldErrors.roles.text}}
                </div>
              </div>
            </div>
          </div>
          <!-- endregion Roles -->

          <!-- region Application types -->
          <div class="form-group row">
            <div class="col-md-4">
              <label class="mw-100 col-form-label form-control-label">{{'USER_GROUP_LABEL_SCOPE'|translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  [(ngModel)]="model.application_types"
                  [ngModelOptions]="{standalone: true}"
                  [data]="applicationTypes"
                  [settings]="dropdownSettings">
                </angular2-multiselect>
              </div>
            </div>
          </div>
          <!-- endregion Application types -->

          <!-- region Users -->
          <div class="form-group row">
            <div class="col-md-4">
              <label class="mw-100 col-form-label form-control-label">{{'COMMON_USERS'|translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  [(ngModel)]="model.users"
                  [ngModelOptions]="{standalone: true}"
                  [data]="selectableUsers"
                  [settings]="dropdownSettingsForUser"
                  (onSelect)="onUserSelect($event)"
                  (onDeSelect)="onUserDeSelect($event)"
                  (onSelectAll)="onSelectAllUser($event)"
                  (onDeSelectAll)="onDeSelectAllUser($event)">
                </angular2-multiselect>
              </div>
            </div>
          </div>
          <!-- endregion Users -->

          <div>
            <div class="d-flex justify-content-end">
              <input type="submit" class="btn btn-primary" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
            </div>
          </div>

        </form>
      </div> <!--card-body-->
    </div> <!--card-->
  </div>

</div>
