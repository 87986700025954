/* eslint-disable */
import { Injectable } from '@angular/core';
import { Order, OrderType, Services } from '../../util/services';
import { Dates, LocalDate } from '../../util/dates';
import { MultiselectOptionItem, OptionItem, UiConstants } from '../../../util/core-utils';
import { DisabledEnum, DisabledItem, SearchUtils } from '../../../util/search-utils';
import { LoggedInUserStorage } from '../../util/storages';
import { BaseSearch, BaseSearchService } from '../../base.search-service';
import { ProjectRecord } from './project-record.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

/* eslint-enable */

@Injectable()
export class ProjectRecordSearchService extends BaseSearchService {

  protected createStorage(): ProjectRecordSearch.Storage {
    return new ProjectRecordSearch.Storage();
  }

}

export namespace ProjectRecordSearch {

  export class Model extends SearchUtils.SearchModel {
    id: string = '';
    name: string = '';
    externalId: string = '';
    dqlText?: string = undefined;
    startDateFrom: NgbDateStruct | null = null;
    startDateTo: NgbDateStruct | null = null;
    endDateFrom: NgbDateStruct | null = null;
    endDateTo: NgbDateStruct | null = null;
    disabled?: DisabledItem;
    assigneeUser: MultiselectOptionItem<number>[] = [];
    postalAddress: string = '';

    public isEmpty(): boolean {
      return this.externalId.length === 0
        && this.startDateFrom === null
        && this.startDateTo === null
        && this.endDateFrom === null
        && this.endDateTo === null
        && OptionItem.idOrUndefined(this.disabled) === undefined
        && this.dqlText === undefined
        && this.id.length === 0
        && this.name.length === 0
        && this.assigneeUser.length === 0
        && this.postalAddress.length === 0
        ;
    }

    clear() {
      this.id = '';
      this.name = '';
      this.externalId = '';
      this.startDateFrom = null;
      this.startDateTo = null;
      this.endDateFrom = null;
      this.endDateTo = null;
      this.disabled = undefined;
      this.dqlText = undefined;
      this.assigneeUser = [];
      this.postalAddress = '';
    }

    get assigneeUserId(): number | undefined {
      return this.assigneeUser.length === 1 ? this.assigneeUser[0].id : undefined;
    }
  }

  export interface SearchDataGetRequest extends BaseSearch.SearchDataGetRequest {
    projectId?: number;
  }

  export interface SearchDataSetRequest extends BaseSearch.SearchDataSetRequest {
    projectId?: number;
    searchData: SearchData;
  }

  export interface SearchDataResult extends BaseSearch.SearchDataResult {
    searchData: SearchData;
  }

  export interface SearchData extends BaseSearch.SearchData {
    order: Order<ProjectRecord.OrderField>;
    id: string;
    name: string;
    externalId: string;
    dqlText?: string;
    disabled?: DisabledItem;
    startDateFrom: LocalDate;
    startDateTo: LocalDate;
    endDateFrom: LocalDate;
    endDateTo: LocalDate;
    assigneeUser: MultiselectOptionItem<number>[];
    postalAddress: string;
  }

  interface StoredSearchRawData extends BaseSearch.StoredSearchRawData {
    order: Order<ProjectRecord.OrderField>;
    id: string;
    name: string;
    externalId: string;
    dqlText?: string;
    disabled?: DisabledItem;
    startDateFrom?: string;
    startDateTo?: string;
    endDateFrom?: string;
    endDateTo?: string;
    assigneeUser: MultiselectOptionItem<number>[];
    postalAddress: string;
  }

  export class Storage extends BaseSearch.Storage {

    /**
     * Increment this version if you change the SearchData interface.
     */
    private static readonly VERSION: number = 7;

    private static readonly DEFAULT_SEARCH_DATA: SearchData = {
      itemsPerPage: UiConstants.pageNumbers[0],
      pageNumber: 1,
      order: {
        field: ProjectRecord.OrderField.ID,
        type: OrderType.DESC
      },
      id: '',
      name: '',
      externalId: '',
      dqlText: undefined,
      disabled: DisabledItem.active(),
      startDateFrom: Dates.emptyLocalDate(),
      startDateTo: Dates.emptyLocalDate(),
      endDateFrom: Dates.emptyLocalDate(),
      endDateTo: Dates.emptyLocalDate(),
      assigneeUser: [],
      postalAddress: ''
    };

    constructor() {
      super(Storage.DEFAULT_SEARCH_DATA, Storage.VERSION);
    }

    protected generateKey(request: SearchDataGetRequest | SearchDataSetRequest): string {
      return request.projectId
        ? 'UserId(' + LoggedInUserStorage.getInstance().getUserId() + ')-Search-ProjectRecord-ProjectId(' + request.projectId + ')'
        : 'UserId(' + LoggedInUserStorage.getInstance().getUserId() + ')-Search-ProjectRecord-Global';
    }

    protected fromRaw(data: StoredSearchRawData): SearchDataResult {
      return {
        searchData: {
          itemsPerPage: data.itemsPerPage,
          pageNumber: data.pageNumber,
          order: data.order,
          id: data.id,
          name: data.name,
          externalId: data.externalId,
          dqlText: data.dqlText,
          disabled: data.disabled,
          startDateFrom: Services.toLocalDate(data.startDateFrom),
          startDateTo: Services.toLocalDate(data.startDateTo),
          endDateFrom: Services.toLocalDate(data.endDateFrom),
          endDateTo: Services.toLocalDate(data.endDateTo),
          assigneeUser: data.assigneeUser,
          postalAddress: data.postalAddress,
        }
      };
    }

    protected toRaw(data: SearchData): StoredSearchRawData {
      return {
        version: Storage.VERSION,
        itemsPerPage: data.itemsPerPage,
        order: data.order,
        pageNumber: data.pageNumber,
        id: data.id,
        name: data.name,
        externalId: data.externalId,
        dqlText: data.dqlText,
        disabled: data.disabled,
        startDateFrom: Services.localDateToString(data.startDateFrom),
        startDateTo: Services.localDateToString(data.startDateTo),
        endDateFrom: Services.localDateToString(data.endDateFrom),
        endDateTo: Services.localDateToString(data.endDateTo),
        assigneeUser: data.assigneeUser,
        postalAddress: data.postalAddress,
      };
    }

  }

}
