import { Component, OnInit, ViewChild } from '@angular/core';
import { RightModel } from '../../../../app.rights';
import {
  ColonnadeClientDocumentStatSearchComponent,
  ColonnadeClientDocumentStatSearchSearchScope
} from '../colonnade-client-document-stat-search/colonnade-client-document-stat-search.component';
import { RightResolver, RightService } from '../../../../lib/right.service';
import {
  ColonnadeClientDocumentStat,
  ColonnadeClientDocumentStatService
} from '../../../../lib/colonnade-client-document-stat/colonnade-client-document-stat.service';
import { DownloadedFile } from '../../../../lib/util/downloaded-files';
import { saveAs } from 'file-saver';
import { StringKey } from '../../../../app.string-keys';
import { UiConstants } from '../../../../util/core-utils';
import { ToasterService } from '../../../../fork/angular2-toaster/src/toaster.service';
import { TranslateService } from '@ngx-translate/core';
import Decimal from 'decimal.js';

@Component({
  selector: 'app-colonnade-client-document-stat-product-report',
  templateUrl: './colonnade-client-document-stat-product-report.component.html',
  styleUrls: ['./colonnade-client-document-stat-product-report.component.scss']
})
export class ColonnadeClientDocumentStatProductReportComponent implements OnInit {
  ColonnadeClientDocumentStatSearchSearchScope = ColonnadeClientDocumentStatSearchSearchScope;

  @ViewChild('searchComponent', { static: true })
  searchComponent: ColonnadeClientDocumentStatSearchComponent;

  @ViewChild('presentationChart') presentationChart;
  presentationChartData;

  @ViewChild('contractChart') contractChart;
  contractChartData;

  @ViewChild('productChart') productChart;
  productChartData;

  rightModel: RightModel = RightModel.empty();
  currentUserId?: number;

  productReports: ColonnadeClientDocumentStat.ColonnadeB2BByProductValue[] = [];

  constructor(private rightService: RightService,
              private toasterService: ToasterService,
              private translateService: TranslateService,
              private colonnadeClientDocumentStatService: ColonnadeClientDocumentStatService) {
  }

  ngOnInit() {
    this.loadRightModel();
  }

  private loadRightModel() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        this.currentUserId = resolver.userProfile!.id;
      }
    );
  }

  downloadXlsx() {
    if (!this.searchComponent.fForm.submitted || !this.searchComponent.validate()) {
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutLong,
        type: UiConstants.toastTypeError,
        title: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_TITLE),
        body: this.translateService.instant(StringKey.COLONNADE_CLIENT_DOCUMENT_STAT_XLSX_EXPORT_NO_RESULT)
      });
      return;
    }
    const request = this.searchComponent.getSearchRequest();
    this.colonnadeClientDocumentStatService.getProductReportXlsx(request).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName('product_report.xlsx'));
      }
    );
  }

  loadList() {
    this.colonnadeClientDocumentStatService.getProductReport(this.searchComponent.getSearchRequest())
      .subscribe((result) => {
        this.productReports = result;
        this.updateChartData();
      });
  }

  private updateChartData() {
    // presentationChart
    const presentationChartDataTable: any[] = [];
    const presentationFirstRow: any[] = [];
    presentationFirstRow.push('Product');
    presentationFirstRow.push('ContractCount');
    presentationChartDataTable.push(presentationFirstRow);
    const productMap = new Map<string, ProductData>();
    for (const productReport of this.productReports) {
      let data = productMap.get(productReport.productCode);
      if (data === undefined) {
        data = {
          productText: productReport.productText,
          productCode: productReport.productCode,
          insuranceCount: productReport.insuranceCount,
          sumPrice: productReport.sumPrice,
          presentedCount: productReport.presentedCount
        };
      }
      else {
        data.insuranceCount = data.insuranceCount + productReport.insuranceCount;
        data.presentedCount = data.presentedCount + productReport.presentedCount;
        data.sumPrice = data.sumPrice.add(productReport.sumPrice);
      }
      productMap.set(productReport.productCode, data);
    }
    for (const productReport of productMap.values()) {
      const row: any[] = [];
      row.push(productReport.productText);
      row.push(productReport.presentedCount);
      presentationChartDataTable.push(row);
    }
    this.presentationChartData = {
      chartType: 'PieChart',
      dataTable: presentationChartDataTable,
      options: {
        'is3D': true,
        'height': 500,
        'pieSliceText': 'value',
      }
    };

    // contractChart
    const contractChartDataTable: any[] = [];
    const contractFirstRow: any[] = [];
    contractFirstRow.push('Product');
    contractFirstRow.push('ContractCount');
    contractChartDataTable.push(contractFirstRow);
    for (const productReport of productMap.values()) {
      const row: any[] = [];
      row.push(productReport.productText);
      row.push(productReport.insuranceCount);
      contractChartDataTable.push(row);
    }
    this.contractChartData = {
      chartType: 'PieChart',
      dataTable: contractChartDataTable,
      options: {
        'is3D': true,
        'height': 500,
        'pieSliceText': 'value',
      }
    };

    // productChart
    const productChartDataTable: any[] = [];
    const productFirstRow: any[] = [];
    productFirstRow.push('Product');
    productFirstRow.push('SumCount');
    productChartDataTable.push(productFirstRow);
    for (const productReport of productMap.values()) {
      const row: any[] = [];
      row.push(productReport.productText);
      row.push(productReport.sumPrice.toNumber());
      productChartDataTable.push(row);
    }
    this.productChartData = {
      chartType: 'PieChart',
      dataTable: productChartDataTable,
      options: {
        'is3D': true,
        'height': 500,
        'pieSliceText': 'value',
      }
    };
  }
}

interface ProductData {
  productText: string;
  productCode: string;
  insuranceCount: number;
  sumPrice: Decimal;
  presentedCount: number;
}
