import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {

  private _event: Subject<GlobalErrorEvent>;
  private _event$: Observable<GlobalErrorEvent>;

  constructor(private injector: Injector) {
    super();
    this._event = new Subject();
    this._event$ = this._event.asObservable();
  }

  get events(): Observable<GlobalErrorEvent> {
    return this._event$;
  }

  handleError(error) {
    super.handleError(error);
    if (this.ignored(error)) {
      return;
    }
    const e = new GlobalErrorEvent();
    this._event.next(e);
  }

  private ignored(error): boolean {
    const code = error.code;
    if (code) {
      if (code === 'messaging/notifications-blocked') {
        // Push notifications are not enabled. Not an error.
        return true;
      }
    }
    return false;
  }

}

export class GlobalErrorEvent {

}
