import { Component, OnInit } from '@angular/core';
import { RightModel } from '../../../app.rights';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { RightResolver, RightService } from '../../../lib/right.service';
import { StateName } from '../../../app.state-names';
import { EmptyMessage } from '../../../lib/util/messages';
import { EmailAddressType, EmailAddressTypeService } from '../../../lib/email-address-type/email-address-type.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-email-address-type-list',
  templateUrl: './email-address-type-list.component.html',
  styleUrls: ['./email-address-type-list.component.scss']
})
export class EmailAddressTypeListComponent implements OnInit {

  emailAddressTypesList: EmailAddressType.EmailAddressType[] = [];
  rightModel: RightModel = RightModel.empty();

  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  constructor(
    private emailAddressTypeService: EmailAddressTypeService,
    private translateService: TranslateService,
    private rightService: RightService) {}

  ngOnInit() {
    this.loadRightModels();
    this.initBreadcrumb();
    this.loadList();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  initBreadcrumb() {
    this.translateService.get('MENU_NAVBAR_EMAIL_ADDRESS_TYPES').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
  }

  loadList() {
    this.emailAddressTypeService.getList().subscribe((result: EmailAddressType.EmailAddressType[]) => {
      this.emailAddressTypesList = result;
    });
  }

  setDisabled(event: any, emailAddressTypeId: number, disabled: boolean) {
    this.emailAddressTypeService.setDisabled({
      id: emailAddressTypeId,
      disabled: disabled
    })
      .subscribe(
        (result: EmptyMessage) => {
          this.loadList();
        },
        (error: any) => {
          this.loadList();
        }
      );
  }

}
