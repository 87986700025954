import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FileItem, FileUploader, FileUploaderOptions, ParsedResponseHeaders } from 'ng2-file-upload';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { FileUtils } from '../../../util/file-utils';
import { FileUploaderUtil } from '../../../util/file-uploader-util';
import { ResourceHelper } from '../../../lib/util/http-services';
import { UiConstants } from '../../../util/core-utils';

@Component({
  selector: 'app-cropped-image-uploader-dialog',
  templateUrl: './cropped-image-uploader-dialog.component.html',
  styleUrls: ['./cropped-image-uploader-dialog.component.scss']
})
export class CroppedImageUploaderDialogComponent implements OnInit {

  UiConstants = UiConstants;

  @Input()
  fileName: string;

  @Input()
  uploadPath: string;

  @Input()
  aspectRatio: number = 1 / 1;

  @Input()
  titleKey: string;

  @Input()
  fixedRatioSwitchEnabled: boolean = false;

  @Input()
  fixedRatioForced: boolean = false;

  @Input()
  fixedRatioKey: string;

  @Output()
  onImageUploaded: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('dialog', { static: true })
  dialog: ModalDirective;

  @ViewChild(ImageCropperComponent)
  cropperComponent: ImageCropperComponent;

  dialogVisible = false;
  selectedImageSource: File | null = null;
  croppedImage: string | null | undefined;
  simpleFileUploader: FileUploader;
  fixedRatioEnabled: boolean = false;

  constructor(
    private uploadUtil: FileUploaderUtil,
    private resourceHelper: ResourceHelper) { }

  ngOnInit(): void {
    this.simpleFileUploader = this.uploadUtil.createUploader('', {
      onBeforeUpload: () => {
      },
      onSuccess: (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
        this.hideDialog();
        this.onImageUploaded.emit();
      },
      onUploadError: (response: string) => {
      }
    });
  }

  onImageSelected(files: File[]) {
    this.selectedImageSource = files[0];
    this.showDialog();
  }

  showDialog() {
    this.dialogVisible = true;
    this.dialog.show();
  }

  hideDialog() {
    this.dialogVisible = false;
    this.dialog.hide();
    this.fixedRatioEnabled = false;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  uploadImage() {
    const file: any = FileUtils.dataURIToBlob(this.croppedImage!);
    file.lastModifiedDate = new Date();
    file.name = this.fileName;
    const url = this.resourceHelper.getBaseUrl() + this.uploadPath;
    const options: FileUploaderOptions = this.simpleFileUploader.options;
    options.url = url;
    this.simpleFileUploader.addToQueue([file], options);
    this.simpleFileUploader.uploadAll();
  }

  onFixedRatioEnabledChanged() {
    this.cropperComponent.resetCropperPosition();
  }

}
