<!-- stock record edit -->
<div class="modal-content">
  <div class="modal-header">
    <p class="modal-title"><strong>{{'STOCK_RECORD_EDIT' | translate}}</strong></p>
    <button type="button" class="close" (click)="closeDialog(false)" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <mat-dialog-content style="overflow:visible">
      <form class="form-horizontal bordered-row" (ngSubmit)="f.form.valid && updateStockItem()" #f="ngForm" id="form"
            novalidate>
        <div class="form-group row"
             [ngClass]="{ 'has-danger': (f.submitted && !edit_stock_record_amount.valid) || stockItemUpdateFieldErrors.amount}">
          <label class="col-md-4 col-form-label required-field-label">{{'STOCK_RECORD_AMOUNT' | translate}}</label>
          <div class="col-md-8">
            <input type="text" class="form-control"
                   maxlength="21"
                   [ngClass]="{ 'has-danger': (f.submitted && !edit_stock_record_amount.valid)}"
                   [(ngModel)]="editStockRecordModel.amount" #edit_stock_record_amount="ngModel"
                   (ngModelChange)="resetStockItemUpdateFieldErrors()"
                   placeholder="{{'STOCK_RECORD_AMOUNT' | translate}}"
                   [textMask]="{mask: InputMask.NATURAL_FLOAT_WITH_THOUSAND_SEPARATOR, guide: false}"
                   name="edit_stock_record_amount" id="edit_stock_record_amount" required>
            <div class="form-control-feedback" *ngIf="f.submitted && !edit_stock_record_amount.valid">
              {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
            </div>
            <div class="form-control-feedback" *ngIf="stockItemUpdateFieldErrors.amount">
              {{stockItemUpdateFieldErrors.amount.text}}
            </div>
            <div>
              {{'STOCK_ITEM_RESERVED_MESSAGE' | translate }} {{editStockRecordModel.reservedAmount | formattedNumber}} {{editStockRecordModel.unit}}
            </div>
          </div>
        </div>

        <div class="form-group row"
             [ngClass]="{ 'has-danger': (f.submitted && !edit_stock_record_intake_price.valid) || stockItemUpdateFieldErrors.amount}">
          <label
            class="col-md-4 col-form-label">{{'STOCK_RECORD_LOT_INTAKE_PRICE' | translate}}</label>
          <div class="col-md-8">
            <input type="text" class="form-control"
                   maxlength="21"
                   [ngClass]="{ 'has-danger': (f.submitted && !edit_stock_record_intake_price.valid)}"
                   [(ngModel)]="editStockRecordModel.intakePrice" #edit_stock_record_intake_price="ngModel"
                   (ngModelChange)="resetStockItemUpdateFieldErrors()"
                   placeholder="{{'STOCK_RECORD_LOT_INTAKE_PRICE' | translate}}"
                   [textMask]="{mask: InputMask.NATURAL_FLOAT_WITH_THOUSAND_SEPARATOR, guide: false}"
                   name="edit_stock_record_intake_price" id="edit_stock_record_intake_price"
                   validateOptionalPositiveNumber>
            <div class="form-control-feedback" *ngIf="f.submitted && !edit_stock_record_intake_price.valid">
              {{'COMMON_VALIDATION_MESSAGE_NUMBER_NOT_POSITIVE' | translate}}
            </div>
            <div class="form-control-feedback" *ngIf="stockItemUpdateFieldErrors.intake_unit_net_price">
              {{stockItemUpdateFieldErrors.intake_unit_net_price.text}}
            </div>
          </div>
        </div>

        <div *ngIf="data && data.stockRecordLot">
        <div class="form-group row">
          <label class="col-md-4 col-form-label">{{'FORM_RECORD_STOCK_INTAKE_HEADER_SHIPPING_NUMBER' | translate}}</label>
          <div class="col-md-8">
            <div class="detail-small-label">
              {{data.stockRecordLot.shipping_number}}
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-4 col-form-label">{{'STOCK_RECORD_LOT_INTAKE_DATE' | translate}}</label>
          <div class="col-md-8">
            <div class="detail-small-label">
              {{data.stockRecordLot.intake_date | date}}
            </div>
          </div>
        </div>
        </div>
      </form>
    </mat-dialog-content>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="closeDialog(false)">
      {{'COMMON_BUTTON_CANCEL' | translate}}
    </button>
    <button type="submit" class="btn btn-primary" form="form">
      {{'COMMON_BUTTON_SAVE' | translate}}
    </button>
  </div>
</div>
