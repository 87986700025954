import { Injectable } from '@angular/core';
import { MultiselectProvider } from '../multiselect-provider';
import { MultiselectOptionItem, UiConstants } from '../../util/core-utils';
import { Observable, Observer } from 'rxjs';
import { OrderType, QueryResult } from '../util/services';
import { Survey, SurveyService } from './survey.service';
import { Set } from 'immutable';

@Injectable()
export class SurveyMultiselectProvider implements MultiselectProvider {

  private multiselectQueryRequest: Survey.QueryRequest = {
    paging: {
      pageNumber: 1,
      numberOfItems: UiConstants.autocompletePageSize
    },
    orders: Set.of({field: Survey.OrderField.NAME, type: OrderType.ASC}),
    disabled: undefined,
    noProgressBar: true,
    queryText: undefined
  };

  constructor(private surveyService: SurveyService) {
  }

  load(queryRequest: Survey.QueryRequest): Observable<MultiselectOptionItem<number>[]> {
    return Observable.create((observer: Observer<MultiselectOptionItem<number>[]>) => {
      this.surveyService.query(queryRequest).subscribe((result: QueryResult<Survey.Survey>) => {
          observer.next(result.items.map(item => this.toMultiselectOptionItem(item!)).toArray());
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  loadAll(predicate?: string): Observable<MultiselectOptionItem<number>[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.queryText = predicate;
    return this.load(queryRequest);
  }

  loadActive(predicate?: string): Observable<MultiselectOptionItem<number>[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.queryText = predicate;
    queryRequest.disabled = false;
    return this.load(queryRequest);
  }

  loadForTask(ids: number[], predicate?: string): Observable<MultiselectOptionItem<number>[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.queryText = predicate;
    queryRequest.surveyIdSet = Set.of(...ids);
    return this.load(queryRequest);
  }

  getById(id: number): Observable<MultiselectOptionItem<number>> {
    return Observable.create((observer: Observer<MultiselectOptionItem<number>>) => {
      this.surveyService.get({
        surveyId: id
      }).subscribe((result: Survey.Survey) => {
          observer.next(this.toMultiselectOptionItem(result!));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  getByIds(ids: number[]): Observable<MultiselectOptionItem<number>[]> {
    const queryRequest = Object.assign([], this.multiselectQueryRequest);
    queryRequest.surveyIdSet = Set.of(...ids);
    return this.load(queryRequest);
  }

  toMultiselectOptionItem(item: Survey.Survey): MultiselectOptionItem<number> {
    const ret = new MultiselectOptionItem<number>();
    ret.id = item.id;
    ret.itemName = item.name;
    ret.itemSubtitle = item.externalId;
    ret.disabled = item.disabled;
    return ret;
  }

}
