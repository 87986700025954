<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div *ngIf="model" class="breadcrumb-menu d-flex">
    <div class="breadcrumb-button-icon-container cursor-pointer"
         (click)="stateImportDialog.toggleDialog()"
         *ngIf="model.state === 'OPEN' || model.state === 'IN_PROGRESS'">
      <a class="btn-setting" title="{{'ORDER_MPL_IMPORT' | translate}}">
        <i class="icomoon icomoon-csv-import"></i> {{'ORDER_MPL_IMPORT' | translate}}
      </a>
    </div>
    <div class="breadcrumb-button-icon-container cursor-pointer"
         (click)="mplExportDialog.showDialog()"
         *ngIf="model.state === 'OPEN' || model.state === 'IN_PROGRESS'">
      <a class="btn-setting" title="{{'ORDER_MPL_EXPORT' | translate}}">
        <i class="icomoon icomoon-csv-export"></i> {{'ORDER_MPL_EXPORT' | translate}}
      </a>
    </div>
    <div class="breadcrumb-button-icon-container cursor-pointer"
         (click)="orderDocumentZipDownloadDialog.showDialog()"
         *ngIf="rightModel.orderDocumentDownload.hasRight()">
      <a class="btn-setting" title="{{'ORDER_DOCUMENT_DOWNLOAD_ALL' | translate}}">
        <i class="icomoon icomoon-csv-export"></i> {{'ORDER_DOCUMENT_DOWNLOAD_ALL' | translate}}
      </a>
    </div>
    <app-breadcrumb-button
      class="negative-margin-right"
      *ngIf="rightModel.legacyProcessUpdate.hasRight()"
      [iconClass]="'icomoon-modify'"
      [titleStringKey]="'COMMON_BUTTON_EDIT'"
      uiSref="Admin.LegacyProcessEdit"
      [uiParams]="{ id: processId }">
    </app-breadcrumb-button>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-custom-padding-outer animated fadeIn">

  <div class="row ml-0 mr-0">

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 card-custom-padding">

      <div class="container-vertical-padding-half">

        <!--BASIC DATA-->
        <div class="card">
          <div class="card-header">
            {{'PROCESS_EDIT_BASEDATA_HEADER' | translate}}
          </div>

          <div class="card-body" *ngIf="model">

            <div class="form-group row">
              <div class="col-md-4 col-form-label">
                <label class="col-form-label mw-100 form-control-label">{{'PROCESS_EDIT_STATE'|translate}}</label>
              </div>
              <!-- /.col-md-4 -->
              <div class="col-md-8">
                <div class="row m-0 d-flex align-items-center">
                  <i class="icomoon {{getProcessStateIcon(model.state)}} state-icon"></i>
                  <span class="state-name">{{getProcessStateName(model.state)}}</span>
                  <span class="text-muted" *ngIf="openTask">{{'PROCESS_EDIT_SUBTASK_IN_PROGRESS' | translate: { taskName: openTask} }}</span>
                </div>
                <!-- /.row -->
              </div>
              <!-- /.col-md-8 -->
            </div>
            <!-- /.form-group -->

            <div class="form-group row">
              <div class="col-md-4">
                <label
                  class="col-form-label mw-100 form-control-label">{{'COMMON_NAME'|translate}}</label>
              </div>
              <div class="col-md-8">
                <label
                  class="col-form-label mw-100 form-control-label">{{baseDataModel.name}}</label>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-4">
                <label
                  class="col-form-label mw-100 form-control-label">{{'PROCESS_EDIT_EXTERNAL_ID'|translate}}</label>
              </div>
              <div class="col-md-8">
                <label
                  class="col-form-label mw-100 form-control-label">{{baseDataModel.externalId}}</label>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_DESCRIPTION' |
                translate}}</label>
              <div class="col-md-8">
                <label
                  class="col-form-label mw-100 form-control-label">{{baseDataModel.description}}</label>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-form-label form-control-label col-md-4 detail-title">{{'PROCESS_EDIT_DEADLINE' |
                translate}}</label>
              <div class="col-md-8">
                <label
                  class="col-form-label mw-100 form-control-label">{{baseDataModel.deadline.toUtcIsoString() | date:'short'}}</label>
              </div>
            </div>

          </div> <!--./card-body-->
        </div>
        <!--./BASIC DATA-->

      </div>
      <!-- /.container-vertical-padding-half -->

    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 card-custom-padding">

      <div class="container-vertical-padding-half">

        <!--STATE HISTORY-->
        <app-legacy-process-edit-state-history
          [processId]="processId">
        </app-legacy-process-edit-state-history>
        <!--./STATE HISTORY-->

      </div>
      <!-- /.continer-vertical-padding-half -->

    </div>

  </div>

  <div class="container-vertical-padding-half card-custom-padding">

    <!--SUBTASKS-->
    <div class="card">
      <div class="card-header">
        {{'PROCESS_EDIT_SUBTASKS_HEADER' | translate}}
      </div>

      <div class="card-body pt-0">
        <div class="row">
          <div *ngFor="let processTask of processTasks; let last = last;"
               class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
            <div class="process-task-container mt-1 mb-0"
                 [ngClass]="{'process-task-container-triangle': !last}">
              <div class="d-flex justify-content-between">
                <span class="text-truncate process-task-title">
                  {{ processTask.displayName }}
                </span>
                <span>
                  <i class="icomoon icomoon-detail cursor-pointer"
                     uiSref="Admin.LegacyProcessTaskDetail"
                     [uiParams]="{ processId: processId, id: processTask.processTaskId }"
                     title="{{'COMMON_BUTTON_DETAILS' | translate}}">
                  </i>
                </span>
              </div>
              <span class="process-task-state-container" [ngStyle]="getProcessTaskStateColor(processTask.state)">
                {{ getProcessTaskStateName(processTask.state) }}
              </span>
              <span class="d-flex mt-2 form-control-label margin-bottom-half">
                <i class="icomoon icomoon-sidebar-customers padding-right-half"></i>
                <span class="text-truncate">
                  {{ getUserName(processTask.assigneeUserId) }}
                </span>
              </span>
              <!-- /.process-task-state-container -->
            </div>
          </div>
        </div>
      </div> <!--./card-body-->
    </div>
    <!--./SUBTASKS-->

  </div>

  <div class="container-vertical-padding-half card-custom-padding">

    <!--ORDERS-->
    <div class="card">
      <div class="card-header">
        {{'PROCESS_EDIT_ORDERS_HEADER' | translate}}
      </div>

      <div class="card-body">

        <table class="table table-striped table-bordered">
          <thead>
          <tr>
            <th class="table-sorter-header">
              <app-table-sorter-no-op [text]="'PROCESS_EDIT_ORDER_ID' | translate"></app-table-sorter-no-op>
            </th>
            <th class="table-sorter-header">
              <app-table-sorter-no-op [text]="'PROCESS_EDIT_ORDER_COMPANY' | translate"></app-table-sorter-no-op>
            </th>
            <th class="table-sorter-header">
              <app-table-sorter-no-op [text]="'PROCESS_EDIT_ORDER_EXTERNAL_ID' | translate"></app-table-sorter-no-op>
            </th>
            <th class="table-sorter-header">
              <app-table-sorter-no-op [text]="'PROCESS_EDIT_ORDER_SUBMIT_TIME' | translate"></app-table-sorter-no-op>
            </th>
            <th class="table-sorter-header">
              <app-table-sorter-no-op
                [text]="'PROCESS_EDIT_ORDER_CASH_ON_DELIVERY_PRICE' | translate"></app-table-sorter-no-op>
            </th>
            <th class="table-sorter-header">
              <app-table-sorter-no-op [text]="'PROCESS_EDIT_ORDER_RECIPIENT_NAME' | translate"></app-table-sorter-no-op>
            </th>
            <th class="table-sorter-header">
              <app-table-sorter-no-op></app-table-sorter-no-op>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let order of orderList | paginate: {
                                                      itemsPerPage: orderQueryModel.itemsPerPage,
                                                      currentPage: orderQueryModel.currentPage,
                                                      totalItems: orderQueryModel.currentNumberOfItems }">
            <td class="responsive-table-column">{{order.orderId}}</td>
            <td class="responsive-table-column">{{getCompanyName(order.ownerCompanyId)}}</td>
            <td class="responsive-table-column">{{order.externalId}}</td>
            <td class="responsive-table-column">{{order.submitTime.toUtcIsoString() | date:'short'}}</td>
            <!--todo: create seperate order model in component and handle optionals there-->
            <td class="responsive-table-column">
              <span *ngIf="order.cashOnDeliveryPrice">
                {{order.cashOnDeliveryPrice.amount}} {{order.cashOnDeliveryPrice.currencyCode}}
              </span>
            </td>
            <td class="responsive-table-column">
              <span *ngIf="order.recipient">
                {{order.recipient.name}}
              </span>
            </td>
            <td>
              <a class="icons-table-group cursor-pointer"
                 uiSref="Admin.OrderDetailWithProcessDetail"
                 [uiParams]="{ id: order.orderId, processId: processId }">
                <i class="icomoon icons-table-item icomoon-detail icomoon-detail-table"
                   title="{{'COMMON_BUTTON_EDIT' | translate}}"></i>
              </a>
            </td>
          </tr>
          </tbody>
          <tfoot>
          <tr>
            <th class="table-sorter-header">
              <app-table-sorter-no-op [text]="'PROCESS_EDIT_ORDER_ID' | translate"></app-table-sorter-no-op>
            </th>
            <th class="table-sorter-header">
              <app-table-sorter-no-op [text]="'PROCESS_EDIT_ORDER_COMPANY' | translate"></app-table-sorter-no-op>
            </th>
            <th class="table-sorter-header">
              <app-table-sorter-no-op [text]="'PROCESS_EDIT_ORDER_EXTERNAL_ID' | translate"></app-table-sorter-no-op>
            </th>
            <th class="table-sorter-header">
              <app-table-sorter-no-op [text]="'PROCESS_EDIT_ORDER_SUBMIT_TIME' | translate"></app-table-sorter-no-op>
            </th>
            <th class="table-sorter-header">
              <app-table-sorter-no-op
                [text]="'PROCESS_EDIT_ORDER_CASH_ON_DELIVERY_PRICE' | translate"></app-table-sorter-no-op>
            </th>
            <th class="table-sorter-header">
              <app-table-sorter-no-op [text]="'PROCESS_EDIT_ORDER_RECIPIENT_NAME' | translate"></app-table-sorter-no-op>
            </th>
            <th class="table-sorter-header">
              <app-table-sorter-no-op></app-table-sorter-no-op>
            </th>
          </tr>
          </tfoot>
        </table>
        <app-table-paging
          [currentNumberOfItems]="orderQueryModel.currentNumberOfItems"
          [totalNumberOfItems]="orderQueryModel.totalNumberOfItems"
          [itemsPerPage]="orderQueryModel.itemsPerPage"
          (pageChange)="orderPageChanged($event)"
          (itemsPerPageChange)="orderItemsPerPageChanged($event)">
        </app-table-paging>

      </div> <!--./card-body-->
    </div>
    <!--./ORDERS-->

  </div>

</div>

<app-order-document-zip-download-dialog
    #orderDocumentZipDownloadDialog
    [processId]="processId">
</app-order-document-zip-download-dialog>

<app-legacy-process-mpl-export-dialog
  #mplExportDialog
  [processId]="processId">
</app-legacy-process-mpl-export-dialog>

<app-file-upload-dialog
  [uploadPath]="mplStateImportPath"
  (onResult)="onImportSuccess($event)"
  #stateImportDialog>
</app-file-upload-dialog>
