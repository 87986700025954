<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]=breadcrumbParents
      [self]="breadcrumbSelf">
    </app-breadcrumb>
    <app-breadcrumb-menu>
    <!-- region CREATE-->
      <app-breadcrumb-button
        *ngIf="rightModel.surveyRecordCreate.hasRight()"
        [iconClass]="'icomoon-add'"
        [titleStringKey]="'COMMON_BUTTON_ADD'"
        uiSref="Admin.SurveyRecordCreate"
        [uiParams]="{ surveyId: surveyId }">
      </app-breadcrumb-button>
    <!-- endregion CREATE-->
    </app-breadcrumb-menu>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="card">

    <div class="card-header">
      {{'SURVEY_PANEL_HEADING_LIST' | translate}}

      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()" title="{{'COMMON_SHOW_SEARCH_HINT' | translate}}"><i
          class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>

    <div class="card-body border-bottom" *ngIf="showSearch">
      <div class="row form-group">
        <!--NAME-->
        <div class="col-md-4">
          <label class="search-label">{{'COMMON_NAME' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.name" name="name" id="name" #name="ngModel">
        </div>
        <!--OWNER USER-->
        <div class="col-md-4">
          <label class="search-label">{{'SURVEY_OWNER_USER' | translate}}</label>
          <select type="text" class="form-control" title="{{'SURVEY_OWNER_USER' | translate}}"
                  [(ngModel)]="searchModel.ownerUser" name="userId" id="userId" #name="ngModel"
                  [compareWith]="SelectUtils.compareObjects">
            <option *ngFor="let owner of ownerUsers" [ngValue]="owner">{{owner.text}}</option>
          </select>
        </div>
        <div class="col-md-4">
          <label class="search-label"> {{ 'COMMON_STATE' | translate }} </label>
          <select
            class="form-control"
            [(ngModel)]="searchModel.disabled" [compareWith]="SelectUtils.compareObjects">
            <option *ngFor="let item of disabledItems" [ngValue]="item">{{item.text}}</option>
          </select>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-4">
          <label class="search-label">{{'SURVEY_SEARCH_UPDATE_TIME_FROM' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control date" placeholder="{{'SURVEY_SEARCH_UPDATE_TIME_FROM' | translate}}"
                   [(ngModel)]="searchModel.updateTimeFrom" ngbDatepicker name="updateTimeFrom" id="updateTimeFrom"
                   #updateTimeFrom="ngbDatepicker">
            <div class="input-group-addon-gray" (click)="updateTimeFrom.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <label class="search-label">{{'SURVEY_SEARCH_UPDATE_TIME_TO' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control date"
                   placeholder="{{'SURVEY_SEARCH_UPDATE_TIME_TO' | translate}}"
                   [(ngModel)]="searchModel.updateTimeTo" ngbDatepicker name="updateTimeTo" #updateTimeTo="ngbDatepicker"
                   id="updateTimeTo">
            <div class="input-group-addon-gray" (click)="updateTimeTo.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>
      </div>
      <!--Button-->
      <div class="col-md-12 d-flex justify-content-md-end align-items-end px-0">
        <div class="btn-group" role="group">
          <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
            {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
          </button>
          <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
            {{'COMMON_BUTTON_SEARCH' | translate}}
          </button>
        </div>
      </div>
    </div> <!-- ./card-body end -->
    <div class="card-body">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header w-5 hidden-xs-down">
            <app-table-field-sorter [orderField]="SurveyRecord.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(SurveyRecord.OrderField.ID)"
                                    (orderChange)="orderBy($event)" [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header long">
            <app-table-field-sorter [orderField]="SurveyRecord.OrderField.NAME"
                                    [orderType]="queryModel.getOrderType(SurveyRecord.OrderField.NAME)"
                                    (orderChange)="orderBy($event)" [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-10">
            <app-table-field-sorter [orderField]="SurveyRecord.OrderField.OWNER_USER"
                                    [orderType]="queryModel.getOrderType(SurveyRecord.OrderField.OWNER_USER)"
                                    (orderChange)="orderBy($event)" [text]="'SURVEY_OWNER_USER_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-10 hidden-sm-down">
            <app-table-field-sorter [orderField]="SurveyRecord.OrderField.CUSTOMER_RECORD_NAME"
                                    [orderType]="queryModel.getOrderType(SurveyRecord.OrderField.CUSTOMER_RECORD_NAME)"
                                    (orderChange)="orderBy($event)" [text]="'SURVEY_CUSTOMER_RECORD_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-15 hidden-md-down">
            <app-table-field-sorter [orderField]="SurveyRecord.OrderField.CREATION_TIME"
                                    [orderType]="queryModel.getOrderType(SurveyRecord.OrderField.CREATION_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_CREATION_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-15 hidden-md-down">
            <app-table-field-sorter [orderField]="SurveyRecord.OrderField.UPDATE_TIME"
                                    [orderType]="queryModel.getOrderType(SurveyRecord.OrderField.UPDATE_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_UPDATE_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-10">
            <app-table-sorter-no-op [text]="'COMMON_TABLE_HEADER_ACTIONS' | translate"></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let surveyRecord of surveyRecordList | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }"
            [disabledTableRow]="surveyRecord.disabled">
          <td class="responsive-table-column hidden-xs-down">{{surveyRecord.id}}</td>
          <td class="responsive-table-column long">{{surveyRecord.name}}</td>
          <td class="responsive-table-column">{{getUserName(surveyRecord.ownerUserId)}}</td>
          <td class="responsive-table-column hidden-sm-down">{{getCustomerRecordName(surveyRecord.customerRecordId)}}</td>
          <td class="responsive-table-column hidden-md-down">{{surveyRecord.creationTime.toUtcIsoString() | date:'short'}}</td>
          <td class="responsive-table-column hidden-md-down">{{surveyRecord.updateTime.toUtcIsoString() | date:'short'}}</td>
          <td class="responsive-table-column">
            <a class="icons-table-group" href="#" onclick="return false"
               (click)="setDisabled($event, surveyRecord, true)"
               *ngIf="rightModel.surveyRecordDisable.hasRight() && !surveyRecord.disabled">
              <i class="icomoon icons-table-item icomoon-disable icomoon-disable-table"
                 title="{{'COMMON_BUTTON_DISABLE' | translate}}"></i>
            </a>
            <a class="icons-table-group" href="#" onclick="return false"
               (click)="setDisabled($event, surveyRecord, false)"
               *ngIf="rightModel.surveyRecordDisable.hasRight() && surveyRecord.disabled">
              <i class="icomoon icons-table-item icomoon-enable icomoon-enable-table"
                 title="{{'COMMON_BUTTON_ENABLE' | translate}}"></i>
            </a>
            <a class="icons-table-group" uiSref="Admin.SurveyRecordDetail" [uiParams]="{ surveyId: surveyRecord.surveyId, id: surveyRecord.id }">
              <i class="icomoon icons-table-item icomoon-detail icomoon-detail-table" title="{{'COMMON_BUTTON_DETAILS' | translate}}"></i>
            </a>
            <a *ngIf="rightModel.surveyRecordCreate.hasRight()"
               class="icons-table-group" uiSref="Admin.SurveyRecordClone" [uiParams]="{ surveyId: surveyRecord.surveyId, id: surveyRecord.id }">
              <i class="icomoon icons-table-item icomoon-clone icomoon-documents-table" title="{{'COMMON_BUTTON_CLONE' | translate}}"></i>
            </a>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header w-5 hidden-xs-down">
            <app-table-field-sorter [orderField]="SurveyRecord.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(SurveyRecord.OrderField.ID)"
                                    (orderChange)="orderBy($event)" [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header long">
            <app-table-field-sorter [orderField]="SurveyRecord.OrderField.NAME"
                                    [orderType]="queryModel.getOrderType(SurveyRecord.OrderField.NAME)"
                                    (orderChange)="orderBy($event)" [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-10">
            <app-table-field-sorter [orderField]="SurveyRecord.OrderField.OWNER_USER"
                                    [orderType]="queryModel.getOrderType(SurveyRecord.OrderField.OWNER_USER)"
                                    (orderChange)="orderBy($event)" [text]="'SURVEY_OWNER_USER_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-10 hidden-sm-down">
            <app-table-field-sorter [orderField]="SurveyRecord.OrderField.CUSTOMER_RECORD_NAME"
                                    [orderType]="queryModel.getOrderType(SurveyRecord.OrderField.CUSTOMER_RECORD_NAME)"
                                    (orderChange)="orderBy($event)" [text]="'SURVEY_CUSTOMER_RECORD_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-15 hidden-md-down">
            <app-table-field-sorter [orderField]="SurveyRecord.OrderField.CREATION_TIME"
                                    [orderType]="queryModel.getOrderType(SurveyRecord.OrderField.CREATION_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_CREATION_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-15 hidden-md-down">
            <app-table-field-sorter [orderField]="SurveyRecord.OrderField.UPDATE_TIME"
                                    [orderType]="queryModel.getOrderType(SurveyRecord.OrderField.UPDATE_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_UPDATE_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-10">
            <app-table-sorter-no-op [text]="'COMMON_TABLE_HEADER_ACTIONS' | translate"></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>
  </div>
</div>
