/* eslint-disable */
import { Injectable } from '@angular/core';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { Observable } from 'rxjs';
import { ResourceQueryResult } from '../util/services';
import { EmptyMessage } from '../util/messages';
import { HttpClient } from '@angular/common/http';
/* eslint-enable */

@Injectable()
export class MessageTemplateResourceService extends BaseHttpService {

  query(request: MessageTemplateResource.QueryRequest): Observable<ResourceQueryResult<MessageTemplateResource.MessageTemplate>> {
    return this.http.get<ResourceQueryResult<MessageTemplateResource.MessageTemplate>>(this.url, this.parseParams(request));
  }

  get(request: MessageTemplateResource.GetRequest): Observable<MessageTemplateResource.MessageTemplate> {
    return this.http.get<MessageTemplateResource.MessageTemplate>(this.url + `${request.id}`);
  }

  create(request: MessageTemplateResource.CreateRequest): Observable<EmptyMessage> {
    return this.http.post(this.url, request);
  }

  update(request: MessageTemplateResource.UpdateRequest): Observable<EmptyMessage> {
    return this.http.put(this.url + `${request.id}`, request);
  }

  disable(request: MessageTemplateResource.DisableRequest): Observable<EmptyMessage> {
    return this.http.patch(this.url + `${request.id}` + '/disabled', request);
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/message-templates/');
  }

}

export namespace MessageTemplateResource {

  export interface MessageTemplate {
    id: number;
    external_id: string;
    name: string;
    title: string;
    template: string;
    disabled: boolean;
    creation_time: string;
    update_time: string;
  }

  export interface QueryRequest {
    id?: string;
    external_id?: string;
    name?: string;
    title?: string;
    disabled?: boolean;
    order?: string;
    page_number?: number;
    number_of_items?: number;
    no_progress_bar?: boolean;
  }

  export interface GetRequest {
    id: number;
  }

  export interface CreateRequest {
    external_id?: string;
    name: string;
    title: string;
    template: string;
  }

  export interface UpdateRequest {
    id: number;
    external_id: string;
    name: string;
    title: string;
    template: string;
  }

  export interface DisableRequest {
    id: number;
    disabled: boolean;
  }

}
