/* eslint-disable */
import { Component, OnInit, ViewChild } from '@angular/core';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { StateName } from '../../../app.state-names';
import { TranslateService } from '@ngx-translate/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OptionItem, OptionItemWithName, RoleItem, UiConstants } from '../../../util/core-utils';
import { Angular2Multiselects } from '../../../util/multiselect';
import { Set } from 'immutable';
import { Company, CompanyService } from '../../../lib/company/company.service';
import { OrderType, QueryResult, ResourceQueryResult, Services } from '../../../lib/util/services';
import { Address } from '../../../lib/address';
import { ConfigurationService } from '../../../lib/core-ext/configuration.service';
import { CompanyLocation, CompanyLocationService } from '../../../lib/company-location/company-location.service';
import { UserGroup, UserGroupService } from '../../../lib/user-group.service';
import { Models } from '../../../util/model-utils';
import { Strings } from '../../../lib/util/strings';
import { EmptyMessage } from '../../../lib/util/messages';
import { FieldError, FieldErrors, ObservableErrorResourceParser } from '../../../lib/util/errors';
import { CompanyStockFieldErrorMap, CompanyStockService } from '../../../lib/company-stock/company-stock.service';
import { UIRouter } from '@uirouter/angular';
import { CompanyCreateModalComponent } from '../../company/company-create-modal/company-create-modal.component';
import { CompanyLocationCreateModalComponent } from '../../company-location/company-location-create-modal/company-location-create-modal.component';
import { UserGroupCreateModalComponent } from '../../usergroup/usergroup-create-modal/user-group-create-modal.component';
import { RightModel } from '../../../app.rights';
import { RightResolver, RightService } from '../../../lib/right.service';

/* eslint-enable */

@Component({
  selector: 'app-company-stock-create',
  templateUrl: './company-stock-create.component.html',
  styleUrls: ['./company-stock-create.component.scss']
})
export class CompanyStockCreateComponent implements OnInit {
  UiConstants = UiConstants;

  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;

  dropdownSettings: Angular2Multiselects.Settings;
  usergroupDropdownSettings: Angular2Multiselects.Settings;

  isLinear = true;
  overflowVisible = true;
  companyFormGroup: FormGroup;
  companyLocationFormGroup: FormGroup;
  userGroupFormGroup: FormGroup;
  companyStockFormGroup: FormGroup;
  rightModel: RightModel = RightModel.empty();

  createModel: CreateModel = new CreateModel();
  @ViewChild('companyCreateModal', { static: true }) companyCreateModal: CompanyCreateModalComponent;
  @ViewChild('companyLocationCreateModal', { static: true }) companyLocationCreateModal: CompanyLocationCreateModalComponent;
  @ViewChild('userGroupCreateModal', { static: true }) userGroupCreateModal: UserGroupCreateModalComponent;

  // Values are loaded based on previous choices
  companies: OptionItemWithName<number>[] = [];
  companyLocations: OptionItemWithName<number>[] = [];
  userGroups: OptionItem<number>[] = [];
  roles: RoleItem[] = [];
  fieldErrors: CompanyStockFieldErrorMap;

  constructor(
    private translateService: TranslateService,
    private formBuilder: FormBuilder,
    private configurationService: ConfigurationService,
    private companyService: CompanyService,
    private companyLocationService: CompanyLocationService,
    private companyStockService: CompanyStockService,
    private userGroupService: UserGroupService,
    private rightService: RightService,
    private uiRouter: UIRouter) {
    this.fieldErrors = {};
  }

  ngOnInit() {
    this.loadRightModels(() => {
      this.loadCompanies();
    });
    this.initBreadcrumb();
    this.createFormGroups();
    this.initDropdown();
  }

  private loadRightModels(completion: () => void) {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        completion();
      }
    );
  }

  initBreadcrumb() {
    this.translateService.get('COMPANY_STOCK_CREATE').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_COMPANY_STOCK').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.COMPANY_STOCK_LIST});
      }
    );
  }

  initDropdown() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .labelKey(OptionItem.KEY_TEXT)
      .enableCheckAll(false)
      .build();
    this.usergroupDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .remoteSearch(true)
      .labelKey(OptionItem.KEY_TEXT)
      .enableCheckAll(false)
      .build();
  }

  createFormGroups() {
    this.companyFormGroup = this.formBuilder.group({
      companyControl: [[], Validators.required]
    });
    this.companyLocationFormGroup = this.formBuilder.group({
      companyLocationControl: [[], Validators.required]
    });
    this.userGroupFormGroup = this.formBuilder.group({
      userGroupControl: [[], Validators.required]
    });
    this.companyStockFormGroup = this.formBuilder.group({
      externalId: [''],
      name: ['', Validators.required]
    });
  }

  onSelectedCompanyChanged() {
    if (this.createModel.company.length > 0 && this.createModel.company[0]!.id !== null) {
      this.createModel.selectedCompanyText = this.createModel.company[0]!.text;
      const companyId = this.createModel.company[0]!.id!;
      this.loadCompanyLocations(companyId);
      this.loadUserGroups();
    }
    else {
      this.createModel.selectedCompanyText = '';
      this.createModel.companyLocation = [];
    }
  }

  onSelectedCompanyLocationChanged() {
    if (this.createModel.companyLocation.length > 0 && this.createModel.companyLocation[0]!.id !== null) {
      this.createModel.selectedCompanyLocationText = this.createModel.companyLocation[0]!.text;
    }
    else {
      this.createModel.selectedCompanyLocationText = '';
    }
    this.generateNameAndExternalId();
  }

  onSelectedUserGroupChanged() {
    if (this.createModel.userGroup.length > 0 && this.createModel.userGroup[0]!.id !== null) {
      this.createModel.selectedUserGroupText = this.createModel.userGroup[0]!.text;
    }
    else {
      this.createModel.selectedUserGroupText = '';
    }
  }

  onCompanyCreated(createdCompanyId: number) {
    this.loadCompanies(createdCompanyId);
  }

  onCompanyLocationCreated(createdCompanyLocationId: number) {
    if (this.createModel.company.length > 0 && this.createModel.company[0]!.id !== null) {
      const companyId = this.createModel.company[0]!.id!;
      this.loadCompanyLocations(companyId, createdCompanyLocationId);
    }
  }

  onUserGroupCreated(createdUserGroupId: number) {
    if (this.createModel.companyId) {
      this.loadUserGroups(undefined, createdUserGroupId);
    }
  }

  getSelectedCompanyId(): number | undefined {
    if (this.createModel.company.length > 0 && this.createModel.company[0]!.id !== null) {
      return this.createModel.company[0]!.id!;
    }
    return undefined;
  }

  generateNameAndExternalId() {
    if (this.createModel.company.length > 0 && this.createModel.companyLocation.length > 0) {
      const companyName = this.createModel.company[0]!.name;
      const companyLocationName = this.createModel.companyLocation[0]!.name;
      this.createModel.name = companyName + ' ' + companyLocationName + ' ' + this.translateService.instant('COMMON_STOCK');
      this.createModel.externalId = Models.normalizeText(this.createModel.name);
    }
  }

  loadCompanies(createdCompanyId?: number) {
    if (!this.rightModel.companyRead.hasRight()) {
      return;
    }
    this.companyService.query({
      disabled: false,
      type: Set.of('TRANSPORTER' as Company.CompanyType),
      orders: Set.of({field: Company.OrderField.NAME, type: OrderType.ASC})
    }).subscribe(
      (result: QueryResult<Company.Company>) => {
        this.companies = [];
        this.createModel.company = [];
        result.items.forEach((company: Company.Company) => {
          const item = new OptionItemWithName<number>();
          item.id = company.id;
          item.text =
            company.name
            + ' ('
            + Address.PostalAddressMapper.toString(company.postalAddress, this.configurationService.getPostalAddressFormat())
            + ')';
          item.name = company.name;
          this.companies.push(item);
          if (createdCompanyId === item.id) {
            this.createModel.company.push(item);
          }
        });
      },
      (error: any) => {
        // Ignored. The interceptor handles the global errors.
      }
    );
  }

  loadCompanyLocations(companyId: number, createdCompanyLocationId?: number) {
    if (!this.rightModel.companyLocationRead.hasRight()) {
      return;
    }
    const companyIdSet: number[] = [companyId];
    this.companyLocationService.query({
      companyIds: Set.of(...companyIdSet),
      disabled: false
    }).subscribe((result: QueryResult<CompanyLocation.CompanyLocation>) => {
      this.companyLocations = [];
      this.createModel.companyLocation = [];
      result.items.forEach((companyLocation: CompanyLocation.CompanyLocation) => {
        const item = new OptionItemWithName<number>();
        item.id = companyLocation.id;
        item.text = item.text =
          companyLocation.name
          + ' ('
          + Address.PostalAddressMapper.toString(companyLocation.place.postalAddress!, this.configurationService.getPostalAddressFormat())
          + ')';
        item.name = companyLocation.name;
        this.companyLocations.push(item);
        if (createdCompanyLocationId === item.id) {
          this.createModel.companyLocation.push(item);
        }
      });
    });
  }

  loadUserGroups(q?: string, createdUserGroupId?: number) {
    if (!this.rightModel.userGroupRead.hasRight()) {
      return;
    }
    this.userGroupService.query({
      name: Strings.undefinedOrNonEmpty(q),
      disabled: false,
      company_id: this.createModel.companyId! + '',
      page_number: 1,
      number_of_items: UiConstants.autocompletePageSize,
      no_progress_bar: true,
      order: Services.createOrderFieldParameter(UserGroup.Keys.toOrderFieldKey, Set.of(UserGroup.DEFAULT_ORDER))
    }).subscribe((result: ResourceQueryResult<UserGroup>) => {
        this.userGroups = result.items.map(g => ({id: g.id, text: g.name}));
        if (createdUserGroupId) {
          this.createModel.userGroup = [];
          const createdUserGroup = this.userGroups.find(g => g.id === createdUserGroupId);
          if (createdUserGroup) {
            this.createModel.userGroup.push(createdUserGroup);
          }
          else {
            this.userGroupService.get({id: createdUserGroupId}).subscribe(result => {
              this.createModel.userGroup.push({
                id: result.id,
                text: result.name
              });
            });
          }
        }
      }
    );
  }

  createStock() {
    this.companyStockFormGroup.get('externalId')!.markAsTouched();
    this.companyStockFormGroup.get('name')!.markAsTouched();
    this.companyStockService.create({
      name: this.createModel.name,
      externalId: Strings.undefinedOrNonEmpty(this.createModel.externalId),
      companyLocationId: this.createModel.companyLocationId!,
      assigneeUserGroupId: this.createModel.userGroupId
    }).subscribe(
      (response: EmptyMessage) => {
        this.uiRouter.stateService.go(StateName.COMPANY_STOCK_LIST);
      },
      (error: any) => {
        const res = ObservableErrorResourceParser.parseError(error);
        this.fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
      });
  }

  markAsTouched(abstractControl: AbstractControl) {
    abstractControl.markAsTouched();
  }

  hideOverflow() {
    this.overflowVisible = false;
  }

  showOverflow() {
    const that = this;
    // this is required to prevent flickering elements
    setTimeout(function () {
      that.overflowVisible = true;
    }, 250);
  }

  removeFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.fieldErrors, fieldError);
  }

}

class CreateModel {

  // Selected options from the stepper
  company: OptionItemWithName<number>[] = [];
  companyLocation: OptionItemWithName<number>[] = [];
  userGroup: OptionItem<number>[] = [];

  // Details of the company stock that will be created
  name: string = '';
  externalId: string = '';

  // Text to display selected values after editing finished
  selectedCompanyText: string = '';
  selectedCompanyLocationText: string = '';
  selectedUserGroupText: string = '';

  get companyId(): number | undefined {
    return this.company.length > 0 ? this.company[0].id! : undefined;
  }

  get companyLocationId(): number | undefined {
    return this.companyLocation.length > 0 ? this.companyLocation[0].id! : undefined;
  }

  get userGroupId(): number | undefined {
    return this.userGroup.length > 0 ? this.userGroup[0].id! : undefined;
  }

}
