/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { ResourceQueryResult } from '../util/services';
import { DisableRequest, EmptyMessage, IdentityMessage } from '../util/messages';

/* eslint-enable */

@Injectable()
export class GlobalCalendarEventResourceService extends BaseHttpService {

  // <editor-fold desc="CRUD">

  query(request: GlobalCalendarEventResource.QueryRequest): Observable<ResourceQueryResult<GlobalCalendarEventResource.GlobalCalendarEvent>> {
    return this.http.get<ResourceQueryResult<GlobalCalendarEventResource.GlobalCalendarEvent>>(this.url, this.parseParams(request));
  }

  get(request: GlobalCalendarEventResource.GetRequest): Observable<GlobalCalendarEventResource.GlobalCalendarEvent> {
    return this.http.get<GlobalCalendarEventResource.GlobalCalendarEvent>(this.url + `${request.id}`, this.parseParams(request));
  }

  create(request: GlobalCalendarEventResource.CreateRequest): Observable<IdentityMessage> {
    return this.http.post<IdentityMessage>(this.url, request);
  }

  update(request: GlobalCalendarEventResource.UpdateRequest): Observable<IdentityMessage> {
    return this.http.put<IdentityMessage>(this.url + `${request.id}`, request);
  }

  delete(request: GlobalCalendarEventResource.DeleteRequest): Observable<EmptyMessage> {
    return this.http.delete<EmptyMessage>(this.url + `${request.id}`);
  }

  // </editor-fold>

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/global-calendar/');
  }

}

export namespace GlobalCalendarEventResource {

  // <editor-fold desc="CRUD">

  export interface QueryRequest {
    fields?: string;
    filter?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
    rights?: string;
    no_progress_bar?: boolean;
  }

  export interface GlobalCalendarEvent {
    id: number;
    granted_rights: string[];
    start_time: string;
    end_time: string;
    creation_time: string;
    update_time: string;
    title: string;
    comment?: string;
    color: number;
    creator_user: {
      id: number;
      person_name: string;
      user_name: string;
    }
  }

  export interface GetRequest {
    id: number;
    fields?: string;
    rights?: string;
  }

  export interface DeleteRequest {
    id: number;
  }

  export interface CreateRequest {
    start_time: string;
    end_time: string;
    title: string;
    comment?: string;
    color: number;
  }

  export interface UpdateRequest {
    id: number;
    start_time: string;
    end_time: string;
    title: string;
    comment?: string;
    color: number;
  }

  // </editor-fold>

}
