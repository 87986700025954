/* eslint-disable */
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MultiselectOptionItem, UiConstants } from '../../../../util/core-utils';
import { TranslateService } from '@ngx-translate/core';
import { Angular2Multiselects } from '../../../../util/multiselect';
import { FieldValidationError, OrderType, QueryResult } from '../../../../lib/util/services';
import { TaskRecord, TaskRecordService } from '../../../../lib/task/task-record.service';
import { Address } from '../../../../lib/address';
import { Set } from 'immutable';
import { Strings } from '../../../../lib/util/strings';
import { ConfigurationService } from '../../../../lib/core-ext/configuration.service';
import { AppValidators } from '../../../../util/app-validators';
import { InvoiceSettings, InvoiceSettingsService } from '../../../../lib/invoice/invoice-settings/invoice-settings.service';
import { InvoiceBook, InvoiceBookService } from '../../../../lib/invoice/invoice-book/invoice-book.service';
import {
  INVOICE_VALID_CURRENCY_CODE,
  InvoicePaymentTypeMultiItem
} from '../../../invoicing/invoices/invoice-create/invoice-create-clone.model';
import { Invoice } from '../../../../lib/invoice/invoice/invoice.service';
import { Decimal } from 'decimal.js';
import { StockRecordFacade, StockRecordService } from '../../../../lib/stock/stock-record.service';
import { FormRecord } from '../../../../lib/form/form-record.service';
import { Models } from '../../../../util/model-utils';
import { CustomerRecordItem } from '../../../../util/customer-record-utils';
import { NgbDatePickerParserFormatter } from '../../../../util/ngb-datepicker';
import { FieldNotification, FormRecordFieldNotifierService } from '../../../../lib/form/form-record-field-notifier.service';
import { Subscription } from 'rxjs';
import { ToasterService } from '../../../../fork/angular2-toaster/src/toaster.service';
import { StringKey } from '../../../../app.string-keys';
import { Dates } from '../../../../lib/util/dates';
import { CompanyMultiselectProvider } from '../../../../lib/company/company-multiselect.provider';
import { CustomerRecord, CustomerRecordService } from '../../../../lib/customer/customer-record.service';
import { InvoiceTagMultiselectProvider } from '../../../../lib/invoice/tag/invoice-tag-multiselect-provider.service';
import PostalAddressData = Address.PostalAddressData;
import InvoicePaymentType = Invoice.InvoicePaymentType;
import { StockItemUtils } from '../../../../util/stock/stock-item-utils';

/* eslint-enable */

@Component({
  selector: 'app-task-record-invoice-create-dialog',
  templateUrl: './task-record-invoice-create-dialog.component.html',
  styleUrls: ['./task-record-invoice-create-dialog.component.scss']
})
export class TaskRecordInvoiceCreateDialogComponent implements OnInit, OnDestroy {

  UiConstants = UiConstants;
  InvoiceSettings = InvoiceSettings;
  TaskRecord = TaskRecord;

  @Input()
  taskId: number;

  @Input()
  taskRecordId: number;

  @Input()
  selectedCustomerRecord?: CustomerRecordItem;

  @Input()
  billingInfo?: MultiselectOptionItem<number>;

  private selectedPaymentType: InvoicePaymentType | null = null;

  @Output()
  onResult: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('dialog', {static: true})
  dialog: ModalDirective;
  isVisible: boolean = false;

  @ViewChild('f')
  fForm: NgForm;

  formGroup: FormGroup;
  submitted: boolean = false;

  items: InvoiceableItem[] = [];
  itemSummary: { net: string, gross: string, currencyCode: string } = {
    net: '',
    gross: '',
    currencyCode: INVOICE_VALID_CURRENCY_CODE,
  };

  dropdownSettings: Angular2Multiselects.Settings;
  simpleDropdownSettings: Angular2Multiselects.Settings;
  private postalAddressFormat: string;
  private paymentTypeFieldNotifSubscription: Subscription;

  creationInProgress: boolean;

  model: TaskRecordInvoiceCreateModel = new TaskRecordInvoiceCreateModel();

  invoiceSettingsList: InvoiceSettingsItem[] = [];
  invoiceBookList: MultiselectOptionItem<number>[] = [];
  invoiceTagList: MultiselectOptionItem<number>[] = [];
  billingInfoList: MultiselectOptionItem<number>[] = [];
  paymentTypes: InvoicePaymentTypeMultiItem[] = [];


  private fieldErrors: FieldValidationError<TaskRecord.ManualInvoiceValidatedField> =
    FieldValidationError.empty<TaskRecord.ManualInvoiceValidatedField>();

  constructor(
    private translateService: TranslateService,
    private formBuilder: FormBuilder,
    private configService: ConfigurationService,
    private invoiceSettingsService: InvoiceSettingsService,
    private invoiceBookService: InvoiceBookService,
    private invoiceTagMultiselectProvider: InvoiceTagMultiselectProvider,
    private taskRecordService: TaskRecordService,
    private companyMultiselectProvider: CompanyMultiselectProvider,
    private customerRecordService: CustomerRecordService,
    private datePickerParserFormatter: NgbDatePickerParserFormatter,
    private formRecordFieldNotifierService: FormRecordFieldNotifierService,
    private toasterService: ToasterService,
    private stockRecordService: StockRecordService
  ) {
    this.formGroup = formBuilder.group({
      invoiceSettings: [
        [],
        Validators.required
      ],
      billingInfo: [
        [],
        Validators.required
      ],
      invoiceBook: [
        [],
        AppValidators.required({
          value: () => {
            return this.model.invoiceBookId ? this.model.invoiceBook[0].itemName : '';
          },
          disabled: () => {
            return this.model.invoiceSettingsId
              ? this.model.invoiceSettings[0].integrationType !== InvoiceSettings.InvoiceIntegrationType.APPWORKS
              : true;
          }
        })
      ],
      appearanceType: [
        {value: this.model.appearanceType},
        Validators.required
      ],
      invoiceTag: [
        {value: this.model.invoiceTag},
        []
      ],
      languageCode: [
        [],
        Validators.required
      ],
      paymentType: [
        [],
        Validators.required
      ],
      issueDate: [
        [],
        [Validators.required, AppValidators.validateLocalDate]
      ],
      deliveryDate: [
        [],
        [Validators.required, AppValidators.validateLocalDate]
      ],
      deadline: [
        [],
        [Validators.required, AppValidators.validateLocalDate]
      ],
    });
    this.postalAddressFormat = this.configService.getPostalAddressFormat();
    this.paymentTypeFieldNotifSubscription
      = this.formRecordFieldNotifierService.subscribe(n => this.onFieldNotification(n));
  }

  ngOnInit() {
    this.initDropdownSettings();
    this.initPaymentTypes();
    this.dialog.onShow.subscribe(() => {
      this.loadInvoiceSettings();
      this.loadCustomerCompany();
      this.loadCustomerBillingInfo();
      this.loadItems();
    });
  }

  loadInvoiceSettings(q?: string) {
    this.invoiceSettingsService.query({
      profileName: q ? Strings.undefinedOrNonEmpty(q) : undefined,
      invoiceDirectionType: 'OUTBOUND',
      disabled: false,
      orders: Set.of({type: OrderType.ASC, field: InvoiceSettings.OrderField.PROFILE_NAME}),
      paging: {
        numberOfItems: UiConstants.autocompletePageSize,
        pageNumber: 1
      },
      noProgressBar: true
    }).subscribe((result: QueryResult<InvoiceSettings.InvoiceSettings>) => {
      this.invoiceSettingsList = result.items.toArray().map(s => ({
        id: s.id,
        itemName: s.profileName,
        integrationType: s.invoiceIntegrationType,
        defaultAppearanceType: s.defaultInvoiceAppearanceType,
        defaultPaymentType: s.defaultPaymentType,
        languageCode: s.languageCode,
        address: s.address,
        companyName: s.companyName,
        taxNumber: s.taxNumber,
        commentTemplate: s.commentTemplate
      }));
    });
  }

  onInvoiceSettingsChanged() {
    this.removeFieldError(TaskRecord.ManualInvoiceValidatedField.INVOICE_SETTINGS);
    if (this.model.invoiceSettingsId) {
      this.model.appearanceType = this.model.invoiceSettings[0].defaultAppearanceType;
      this.model.languageCode = this.model.invoiceSettings[0].languageCode;
      this.model.paymentType = this.model.invoiceSettings[0].defaultPaymentType ? this.model.invoiceSettings[0].defaultPaymentType! : null;
      if (this.model.invoiceSettings[0].integrationType === InvoiceSettings.InvoiceIntegrationType.APPWORKS) {
        this.loadInvoiceBooks();
      }
      if (this.model.invoiceSettings[0].commentTemplate) {
        this.model.commentTemplate = this.model.invoiceSettings[0].commentTemplate!;
      }
    }
    else {
      this.model.invoiceBook = [];
      this.model.appearanceType = null;
      this.model.languageCode = null;
      this.model.paymentType = null;
    }
  }

  onInvoiceBookChanged() {
    this.removeFieldError(TaskRecord.ManualInvoiceValidatedField.INVOICE_BOOK);
    this.formGroup.controls['invoiceBook'].updateValueAndValidity();
  }

  loadInvoiceBooks(q?: string) {
    this.model.invoiceBook = [];
    if (this.model.invoiceSettingsId) {
      this.invoiceBookService.query({
        invoiceSettingsIds: Set.of(this.model.invoiceSettingsId),
        prefix: q ? Strings.undefinedOrNonEmpty(q) : undefined,
        disabled: false,
        orders: Set.of({type: OrderType.ASC, field: InvoiceBook.OrderField.PREFIX}),
        paging: {
          numberOfItems: UiConstants.autocompletePageSize,
          pageNumber: 1
        },
        noProgressBar: true
      }).subscribe((result: QueryResult<InvoiceBook.InvoiceBook>) => {
        this.invoiceBookList = result.items.toArray().map(s => ({id: s.id, itemName: s.prefix}));
      });
    }
  }

  onInvoiceTagSearch(q?: string) {
    this.invoiceTagMultiselectProvider.loadActive(q).subscribe(tags => {
      this.invoiceTagList = tags;
    });
  }

  private loadCustomerCompany() {
    if (this.selectedCustomerRecord && this.selectedCustomerRecord.companyId) {
      this.companyMultiselectProvider.getById(this.selectedCustomerRecord.companyId)
        .subscribe(result => this.model.customerCompany = result.itemName);
    }
  }

  private loadCustomerBillingInfo() {
    if (this.selectedCustomerRecord) {
      this.customerRecordService.listBillingInfo({
        customerId: this.selectedCustomerRecord.customerId!,
        customerRecordId: this.selectedCustomerRecord.id!,
        disabled: false
      }).subscribe(result => {
        this.billingInfoList = result.map(i => {
          return {
            id: i.id,
            itemName: i.name,
            itemSubtitle: this.createBillingInfoSubtitle(i),
            data: i
          }
        });
        if (this.billingInfo) {
          this.model.billingInfo = this.billingInfoList.filter(b => b.id === this.billingInfo?.id);
        }
        else if (this.billingInfoList.length === 1) {
          this.model.billingInfo = this.billingInfoList;
        }
      });

    }
  }

  createBillingInfoSubtitle(info: CustomerRecord.BillingInfo) {
    switch (info.invoiceVatStatus) {
      case CustomerRecord.InvoiceVatStatus.PRIVATE_PERSON:
        return this.translateService.instant('INVOICE_VAT_STATUS_PRIVATE_PERSON');
      case CustomerRecord.InvoiceVatStatus.DOMESTIC:
        return this.translateService.instant('INVOICE_VAT_STATUS_DOMESTIC') + ' / ' + info.taxNumber;
      case CustomerRecord.InvoiceVatStatus.OTHER:
        return this.translateService.instant('INVOICE_VAT_STATUS_OTHER') + ' / ' + info.euTaxNumber;
    }
  }

  private loadItems() {
    this.items = [];
    this.taskRecordService.getInvoiceableItems({
      taskId: this.taskId,
      taskRecordId: this.taskRecordId
    }).subscribe((result: TaskRecord.InvoiceableItem[]) => {
      const stockRecordIds: number[] = [];
      result.forEach(i => {
        if (i.stockItem) {
          stockRecordIds.push(i.stockItem.stockRecordId);
        }
      });
      if (stockRecordIds.length > 0) {
        this.stockRecordService.facadeQuery({
          stock_record_ids: stockRecordIds.join(',')
        }).subscribe((res) => {
          result.forEach(i => {
            let invoiceableItem: InvoiceableItem;
            if (i.stockItem) {
              const facadeItem = res.items.find(fi => fi.stock_record_id === i.stockItem!.stockRecordId)!;
              invoiceableItem = this.createStockItem(i.stockItem, facadeItem);
            }
            else {
              invoiceableItem = this.createInvoiceItem(i.invoiceItem!);
            }
            this.items.push(invoiceableItem);
          });
          this.calculateSummary();
        });
      }
      else {
        this.items = result.map(i => this.createInvoiceItem(i.invoiceItem!));
        this.calculateSummary();
      }
    });
  }

  private createInvoiceItem(item: FormRecord.InvoiceItem): InvoiceableItem {
    return {
      selected: false,
      id: item.id,
      type: InvoiceableItemType.INVOICE,
      name: item.recordName,
      amount: item.amount,
      netPrice: item.netUnitPrice.mul(item.amount),
      grossPrice: (item.netUnitPrice.mul((item.vatRate.add(100)).div(100))).mul(item.amount),
      vatRate: item.vatRate,
      currencyCode: item.currencyCode
    };
  }

  private createStockItem(rawItem: FormRecord.StockItem, facadeItem: StockRecordFacade): InvoiceableItem {
    const discountPercent = rawItem.discountPercent ? rawItem.discountPercent : 0;
    const unitNetPrice = rawItem.customNetPrice ? rawItem.customNetPrice : rawItem.lastSavedStockItemNetPrice;
    const discountedUnitPrice = unitNetPrice.sub(unitNetPrice.mul(discountPercent / 100));
    const baseAmount = new Decimal(StockItemUtils.convertToBase(rawItem.amount.toNumber(), facadeItem.stock_item.measurements!.find(i => i.id === rawItem.unitOfMeasureId )!));
    return {
      selected: false,
      id: rawItem.id!,
      type: InvoiceableItemType.STOCK,
      name: facadeItem.stock_item.name,
      amount: baseAmount,
      netPrice: discountedUnitPrice.mul(baseAmount),
      grossPrice: (discountedUnitPrice.mul((facadeItem.stock_item.unit_price.vat_rate + 100) / 100)).mul(baseAmount),
      vatRate: new Decimal(facadeItem.stock_item.unit_price.vat_rate),
      currencyCode: facadeItem.stock_item.unit_price.currency_code
    };
  }

  private calculateSummary() {
    let sumNet: Decimal = new Decimal('0');
    let sumGross: Decimal = new Decimal('0');
    this.items.forEach(r => {
      const rawNet = r.netPrice;
      const rawGross = r.grossPrice;
      if (rawNet) {
        sumNet = sumNet.add(rawNet.toString());
      }
      if (rawGross) {
        sumGross = sumGross.add(rawGross.toString());
      }
    });
    this.itemSummary.net = Models.decimalToFormattedString(sumNet);
    this.itemSummary.gross = Models.decimalToFormattedString(sumGross);
  }

  get eachItemSelected(): boolean {
    let result: boolean = true;
    this.items.forEach(item => {
      result = result && item.selected;
    });
    return result;
  }

  toggleEachItem() {
    const result = !this.eachItemSelected;
    this.items.forEach(item => {
      item.selected = result;
    });
  }

  create() {
    this.submitted = true;
    if (this.formGroup.invalid || this.creationInProgress) {
      return;
    }
    const selectedInvoiceItems: number[] = this.getSelectedItemIds(InvoiceableItemType.INVOICE);
    const selectedStockItems: number[] = this.getSelectedItemIds(InvoiceableItemType.STOCK);
    if (selectedInvoiceItems.length + selectedStockItems.length === 0) {
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutLong,
        type: UiConstants.toastTypeError,
        title: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_TITLE),
        body: this.translateService.instant(StringKey.TASK_RECORD_MANUAL_INVOICE_CREATE_NO_ITEMS_SELECTED_ERROR)
      });
      return;
    }
    this.creationInProgress = true;
    this.taskRecordService.createManualInvoice({
      taskId: this.taskId,
      taskRecordId: this.taskRecordId,
      invoiceSettingsId: this.model.invoiceSettingsId!,
      invoiceBookId: this.model.invoiceBookId,
      invoiceTagId: this.model.invoiceTagId,
      billingInfoId: this.model.billingInfoId,
      paymentType: this.model.paymentType!,
      invoiceAppearanceType: this.model.appearanceType!,
      commentTemplate: Strings.undefinedOrNonEmpty(this.model.commentTemplate),
      issueDate: this.datePickerParserFormatter.toLocalDate(this.model.issueDate),
      deliveryDate: this.datePickerParserFormatter.toLocalDate(this.model.deliveryDate),
      deadline: this.datePickerParserFormatter.toLocalDate(this.model.deadline),
      stockItemIds: Set.of(...selectedStockItems),
      invoiceItemIds: Set.of(...selectedInvoiceItems)
    }).subscribe(result => {
        this.creationInProgress = false;
        this.onResult.emit(true);
        this.hide();
      },
      (error) => {
        this.creationInProgress = false;
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withForm(this.fForm);
          if (this.hasFieldError(TaskRecord.ManualInvoiceValidatedField.UNKNOWN)) {
            this.toasterService.pop({
              timeout: UiConstants.ToastTimeoutLong,
              type: UiConstants.toastTypeError,
              title: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_TITLE),
              body: this.getFieldErrorText(TaskRecord.ManualInvoiceValidatedField.UNKNOWN)
            });
          }
          else {
            this.onResult.emit(false);
          }
        }
      });
  }

  hasLocalFieldError(control: string, error?: string) {
    if (error) {
      return (this.submitted || this.formGroup.controls[control].touched) && this.formGroup.controls[control].hasError(error);
    }
    return (this.submitted || this.formGroup.controls[control].touched) && !this.formGroup.controls[control].valid;
  }

  hasFieldError(field?: TaskRecord.ManualInvoiceValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: TaskRecord.ManualInvoiceValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: TaskRecord.ManualInvoiceValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  show() {
    this.isVisible = true;
    this.submitted = false;
    this.formGroup.reset();
    this.model = new TaskRecordInvoiceCreateModel();
    this.model.paymentType = this.selectedPaymentType;
    this.dialog.show();
  }

  hide() {
    this.isVisible = false;
    this.dialog.hide();
  }

  private initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .translate(true)
      .build();
    this.simpleDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(false)
      .translate(true)
      .build();
  }

  private initPaymentTypes() {
    InvoicePaymentTypeMultiItem.loadItems().subscribe(paymentTypes => this.paymentTypes.push(...paymentTypes));
  }

  formatPostalAddress(postalAddress?: PostalAddressData): string {
    return postalAddress ? Address.PostalAddressMapper.toString(postalAddress, this.postalAddressFormat) : '';
  }

  onIssueDateChanged() {
    this.removeFieldError(TaskRecord.ManualInvoiceValidatedField.ISSUE_DATE);
    if (this.selectedCustomerRecord && this.selectedCustomerRecord.invoiceDeadlineAdditionalDays) {
      const issueDate = this.datePickerParserFormatter.toLocalDate(this.model.issueDate);
      if (issueDate.isValid()) {
        this.model.deadline
          = this.datePickerParserFormatter.fromLocalDate(
          issueDate.plusDays(
            this.selectedCustomerRecord.invoiceDeadlineAdditionalDays))!;
      }
    }
  }

  private onFieldNotification(notification: FieldNotification) {
    if (notification.data && notification.data['selectedPaymentType'] !== undefined) {
      this.selectedPaymentType = notification.data['selectedPaymentType'];
    }
  }

  ngOnDestroy(): void {
    this.formRecordFieldNotifierService.unsubscribe(this.paymentTypeFieldNotifSubscription);
  }

  private getSelectedItemIds(type: InvoiceableItemType): number[] {
    const result: number[] = [];
    this.items.forEach(i => {
      if (i.selected && i.type === type) {
        result.push(i.id);
      }
    });
    return result;
  }

}

export class TaskRecordInvoiceCreateModel {
  invoiceSettings: InvoiceSettingsItem[];
  invoiceBook: MultiselectOptionItem<number>[];
  invoiceTag: MultiselectOptionItem<number>[];
  appearanceType: InvoiceSettings.InvoiceAppearanceType | null;
  languageCode: string | null;
  paymentType: Invoice.InvoicePaymentType | null;
  issueDate?: NgbDateStruct;
  deliveryDate?: NgbDateStruct;
  deadline?: NgbDateStruct;
  commentTemplate: string;
  customerCompany: string = '';
  billingInfo: MultiselectOptionItem<number>[];

  constructor() {
    this.reset();
  }

  get invoiceSettingsId(): number | undefined {
    return this.invoiceSettings.length === 1 ? this.invoiceSettings[0].id : undefined;
  }

  get invoiceBookId(): number | undefined {
    return this.invoiceBook.length === 1 ? this.invoiceBook[0].id : undefined;
  }

  get invoiceTagId(): number | undefined {
    return this.invoiceTag.length === 1 ? this.invoiceTag[0].id : undefined;
  }

  get billingInfoId(): number | undefined {
    return this.billingInfo.length === 1 ? this.billingInfo[0].id : undefined;
  }

  reset() {
    const today = Dates.today();
    this.invoiceSettings = [];
    this.invoiceBook = [];
    this.invoiceTag = [];
    this.billingInfo = [];
    this.appearanceType = null;
    this.languageCode = null;
    this.paymentType = null;
    this.issueDate = {year: today.getYear(), month: today.getMonth(), day: today.getDay()};
    this.deliveryDate = undefined;
    this.deadline = undefined;
    this.commentTemplate = '';
    this.customerCompany = '';
  }
}

class InvoiceSettingsItem extends MultiselectOptionItem<number> {
  integrationType: InvoiceSettings.InvoiceIntegrationType;
  defaultAppearanceType: InvoiceSettings.InvoiceAppearanceType;
  defaultPaymentType?: Invoice.InvoicePaymentType;
  languageCode: string;
  address: Address.PostalAddressData;
  companyName: string;
  taxNumber: string;
  commentTemplate?: string;
}

interface InvoiceableItem {
  selected: boolean;
  id: number;
  type: InvoiceableItemType;
  name: string;
  amount: Decimal;
  netPrice: Decimal;
  grossPrice: Decimal;
  vatRate: Decimal;
  currencyCode: string;
}

enum InvoiceableItemType {
  STOCK,
  INVOICE
}
