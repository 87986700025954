import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Dates, OffsetDateTime } from '../../../../lib/util/dates';
import { OrderField } from '../../../../lib/query/orderfields';
import { OrderType, QueryResult } from '../../../../lib/util/services';
import { OrderFieldFunction } from '../../../../util/core-utils';
import { DownloadedFile } from '../../../../lib/util/downloaded-files';
import { EmptyMessage } from '../../../../lib/util/messages';
import { saveAs } from 'file-saver';
import { Query } from '../../../../lib/query/field';
import { List } from 'immutable';
import {
  ExteriorTransportDocument,
  ExteriorTransportDocumentService
} from '../../../../lib/exterior-transport/exterior-transport-document/exterior-transport-document.service';
import ExteriorTransportDocumentType = ExteriorTransportDocument.ExteriorTransportDocumentType;
import exteriorTransportDocumentTypes = ExteriorTransportDocument.exteriorTransportDocumentTypes;
import { ExteriorTransportRightModel } from '../../../../lib/exterior-transport/exterior-transport-right.model';

@Component({
  selector: 'app-exterior-transport-documents',
  templateUrl: './exterior-transport-documents.component.html',
  styleUrls: ['./exterior-transport-documents.component.scss']
})
export class ExteriorTransportDocumentsComponent implements OnInit {

  OrderFunctions = OrderFunctions;

  @Input()
  exteriorTransportId: number;

  @Input()
  readonly: boolean;

  @Output()
  onDocumentCreate: EventEmitter<any> =  new EventEmitter<any>();

  @Input()
  exteriorTransportRights: ExteriorTransportRightModel;

  documentList: ExteriorTransportDocumentModel[] = [];

  private orderField: OrderFieldFunction<OrderField.ExteriorTransportDocument> = OrderFunctions.CREATION_TIME;
  private orderType: OrderType = OrderType.DESC;

  constructor(
    private exteriorTransportDocumentService: ExteriorTransportDocumentService
  ) { }

  ngOnInit() {
    this.loadList();
  }

  loadList() {
    const order = this.createOrderFunction();
    this.exteriorTransportDocumentService.query({
      fields: f => f.each,
      exteriorTransportId: this.exteriorTransportId,
      order: order
    }).subscribe((result: QueryResult<ExteriorTransportDocument.ExteriorTransportDocument>) => {
      this.documentList = [];
      result.items.forEach((document: ExteriorTransportDocument.ExteriorTransportDocument) => {
        const documentModel = new ExteriorTransportDocumentModel();
        documentModel.id = document.id;
        documentModel.type = document.meta.type;
        documentModel.originalFileName = document.originalFileName ? document.originalFileName : '';
        documentModel.creationTime = document.creationTime;
        documentModel.comment = document.meta.comment ? document.meta.comment : '';
        documentModel.uploadedByUser = !!document.uploaderUserId;
        this.documentList.push(documentModel);
      });
    });
  }

  downloadFile(document: ExteriorTransportDocumentModel) {
    this.exteriorTransportDocumentService.download({
      exteriorTransportId: this.exteriorTransportId,
      id: document.id
    }).subscribe((res: DownloadedFile) => {
      saveAs(res.getBlob(), res.getFileName(document.originalFileName));
    });
  }

  deleteFile(documentId: string) {
    this.exteriorTransportDocumentService.delete({
      exteriorTransportId: this.exteriorTransportId,
      id: documentId
    }).subscribe((res: EmptyMessage) => {
      this.loadList();
    });
  }

  onOrderFieldChanged(orderField: OrderFieldFunction<OrderField.ExteriorTransportDocument>) {
    if (orderField === this.orderField) {
      this.orderType = this.orderType === OrderType.ASC ? OrderType.DESC : OrderType.ASC;
    }
    else {
      this.orderField = orderField;
      this.orderType = OrderType.ASC;
    }
  }

  getOrderType(field: OrderFieldFunction<OrderField.ExteriorTransportDocument>): OrderType | undefined {
    if (field === this.orderField) {
      return this.orderType;
    }
    return undefined;
  }

  orderBy(field: OrderFieldFunction<OrderField.OrderDocument>) {
    this.onOrderFieldChanged(field);
    this.loadList();
  }

  public createOrderFunction(): Query.OrderFunction<OrderField.ExteriorTransportDocument> {
    if (OrderType.ASC === this.orderType) {
      return (t) => List.of(this.orderField(t).asc().nullsLast());
    }
    return (t) => List.of(this.orderField(t).desc().nullsLast());
  }

  getTypeKey(type: ExteriorTransportDocumentType) {
    return exteriorTransportDocumentTypes.find(t => t.type === type)!.stringKey;
  }

}

export class ExteriorTransportDocumentModel {
  id: string = '';
  type: ExteriorTransportDocumentType;
  originalFileName: string = '';
  creationTime: OffsetDateTime = Dates.emptyOffsetDateTime();
  comment: string = '';
  uploadedByUser: boolean = false;
}

export namespace OrderFunctions {
  export const TYPE = (f: OrderField.ExteriorTransportDocument) => f.meta.type;
  export const CREATION_TIME = (f: OrderField.ExteriorTransportDocument) => f.creationTime;
}
