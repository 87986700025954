<div class="card">

  <div class="card-header">
    {{'TRANSPORT_ATTACHMENTS' | translate}}
  </div>
  <div class="card-body">
    <app-file-attachment-gallery
      #galleryView
      *ngIf="attachments.length > 0"
      [loaderFactory]="fileLoaderFactory"
      [fileAttachments]="attachments"
      (openImageViewerWindowEvent)="openImageViewerWindow()"
      (clickedImageEventEmitter)="clickedImageEventEmitted($event)">
    </app-file-attachment-gallery>
    <div class="p-2 text-center text-muted"
         *ngIf="attachments.length === 0">
      <h6>{{ "COMMON_NO_ATTACHMENT" | translate }}</h6>
    </div>
  </div>

</div><!-- /.card -->
