export class Storages {

  private static readonly KEY_AVAILABILITY_TEST = 'availability-test';

  public static checkStorageAvailable(): boolean {
    try {
      const test = 'yes';
      localStorage.setItem(Storages.KEY_AVAILABILITY_TEST, test);
      const result = localStorage.getItem(Storages.KEY_AVAILABILITY_TEST);
      const pass = test === result;
      localStorage.removeItem(Storages.KEY_AVAILABILITY_TEST);
      return pass;
    }
    catch (error) {
      return false;
    }
  }

}
