/* eslint-disable */
import { FieldValidationError } from '../../../../lib/util/services';
import { MasterDataRecord } from '../../../../lib/masterdata/master-data-record.service';
import { FormGroup } from '@angular/forms';
import {
  ImmutableUserGroupItem,
  ImmutableUserItem,
  MultiselectOptionItem,
} from '../../../../util/core-utils';
import { Set } from 'immutable';
import {CustomerRecordMultiselectItem} from "../../../../lib/customer/customer-record-multiselect-provider.service";
/* eslint-enable */

export namespace MasterDataRecordBase {

  export interface View {
    loadMasterDataRecord(args: Args);

    getModel(): Model;

    getFormGroup(): FormGroup;

    hasLocalFieldError(): boolean;

    onFieldValidationError(fieldErrors: FieldValidationError<MasterDataRecord.ValidatedField>);
  }

  export interface Args {
    masterDataRecord?: MasterDataRecord.MasterDataRecord;
  }

  // Not injected so valid inside the namespace.
  export class Model {

    readonly userItems: ImmutableUserItem[] = [];
    readonly userGroupItems: ImmutableUserGroupItem[] = [];

    name: string = '';
    externalId: string = '';
    description: string = '';
    users: ImmutableUserItem[] = [];
    userGroups: ImmutableUserGroupItem[] = [];
    _ownerCustomerRecord: CustomerRecordMultiselectItem[] = [];
    _ownerContactLocation: MultiselectOptionItem<number>[] = [];

    load(record: MasterDataRecord.MasterDataRecord) {
      this.name = record.name ? record.name : '';
      this.externalId = record.externalId ? record.externalId : '';
      this.description = record.description ? record.description : '';
    }

    get userIds(): Set<number> {
      return Set.of(...this.users.map((user) => {
        return user.id!;
      }));
    }

    get userGroupIds(): Set<number> {
      return Set.of(...this.userGroups.map((userGroup) => {
        return userGroup.id!;
      }));
    }

    setUsers(users: ImmutableUserItem[]) {
      this.users.splice(0, this.users.length);
      this.users.push(...users);
    }

    setUserItems(userItems: ImmutableUserItem[]) {
      this.userItems.splice(0, this.userItems.length);
      this.userItems.push(...userItems);
    }

    setUserGroups(userGroups: ImmutableUserGroupItem[]) {
      this.userGroups.splice(0, this.userGroups.length);
      this.userGroups.push(...userGroups);
    }

    setUserGroupItems(userGroupItems: ImmutableUserGroupItem[]) {
      this.userGroupItems.splice(0, this.userGroupItems.length);
      this.userGroupItems.push(...userGroupItems);
    }

    get ownerCustomerRecord(): CustomerRecordMultiselectItem | undefined {
      return this._ownerCustomerRecord.length > 0 ? this._ownerCustomerRecord[0] : undefined;
    }

    set ownerCustomerRecord(customerRecord: CustomerRecordMultiselectItem | undefined) {
      this._ownerCustomerRecord.splice(0, this._ownerCustomerRecord.length);
      if (customerRecord) {
        this._ownerCustomerRecord.push(customerRecord);
      }
    }

    get ownerContactLocation(): MultiselectOptionItem<number> | undefined {
      return this.ownerCustomerRecord && this._ownerContactLocation.length > 0 ? this._ownerContactLocation[0] : undefined;
    }

    set ownerContactLocation(contactLocation: MultiselectOptionItem<number> | undefined) {
      this._ownerContactLocation.splice(0, this._ownerContactLocation.length);
      if (contactLocation) {
        this._ownerContactLocation.push(contactLocation);
      }
    }
  }
}
