import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Company, CompanyService } from '../../../lib/company/company.service';
import { TranslateService } from '@ngx-translate/core';
import { Country, CountryService } from '../../../lib/country.service';
import { ConfigurationService } from '../../../lib/core-ext/configuration.service';
import { Role, RoleService } from '../../../lib/role/role.service';
import { RightResolver, RightService } from '../../../lib/right.service';
import { ToasterService } from '../../../fork/angular2-toaster/angular2-toaster';
import { NgForm, NgModel } from '@angular/forms';
import {
  LocalFieldValidationErrors,
  LocalFieldValidationErrorsFactory,
  OrderType,
  QueryResult
} from '../../../lib/util/services';
import { RightModel } from '../../../app.rights';
import { List, Set } from 'immutable';
import { AddressModel } from '../../../lib/address';
import { Angular2Multiselects } from '../../../util/multiselect';
import { OptionItem, SelectUtils, UiConstants } from '../../../util/core-utils';
import { InputMask } from '../../../util/input-masks';
import { CompanyEditModel, CompanyFieldErrorMap, RoleItem, TypeModel } from '../company-base/company-base.component';
import { Transition, UIRouter } from '@uirouter/angular';
import { StringKey } from '../../../app.string-keys';
import { TranslateUtils } from '../../../util/translate';
import { FieldError, FieldErrors, ObservableErrorResourceParser } from '../../../lib/util/errors';
import { Strings } from '../../../lib/util/strings';
import { EmptyMessage, IdentityMessage } from '../../../lib/util/messages';

@Component({
  selector: 'app-company-create-modal',
  templateUrl: './company-create-modal.component.html',
  styleUrls: ['./company-create-modal.component.scss']
})
export class CompanyCreateModalComponent implements OnInit, AfterViewInit {

  // Event emitter to notify parent component of successful company creation
  @Output()
  private companyCreated = new EventEmitter<number>();

  // If you need to access certain classes from HTML, declare them here
  SelectUtils = SelectUtils;
  AddressModel = AddressModel;
  InputMask = InputMask;
  Company = Company;
  UiConstants = UiConstants;

  // Declare models before use
  editModel: CompanyEditModel;
  modelLoaded = false;

  typeModels: TypeModel[] = [
    {id: 'DEMANDER', text: 'DEMANDER'},
    {id: 'TRANSPORTER', text: 'TRANSPORTER'}
  ];

  // Form for validation
  @ViewChild('f')
  fForm: NgForm;

  // Validated inputs
  @ViewChild('name')
  name: NgModel;

  @ViewChild('externalId')
  externalId: NgModel;

  @ViewChild('type')
  type: NgModel;

  @ViewChild('taxNumber')
  taxNumber: NgModel;

  // Field errors for server validation
  fieldErrors: CompanyFieldErrorMap;

  private validatedInputs: LocalFieldValidationErrors<NgModel> =
    LocalFieldValidationErrorsFactory.empty();

  // Right model to store the rights available to the User
  rightModel: RightModel = RightModel.empty();

  // Format of postal address, received from server
  postalAddressFormat: string;

  // Country items for postal address
  countryItems: List<AddressModel.CountryItem>;

  // Available roles and selected roles for multiselect dropdown
  roles: RoleItem[] = [];
  selectedRoles: RoleItem[] = [];

  // Settings for the multiselect dropdown
  dropdownSettings: Angular2Multiselects.Settings;

  @ViewChild('companyCreateDialog', { static: true }) companyCreateDialog: ModalDirective;
  companyCreateDialogVisible = true;

  constructor(private uiRouter: UIRouter,
              private transition: Transition,
              private companyService: CompanyService,
              private translateService: TranslateService,
              private countryService: CountryService,
              private configService: ConfigurationService,
              private roleService: RoleService,
              private rightService: RightService,
              private toasterService: ToasterService) {
    this.postalAddressFormat = this.configService.getPostalAddressFormat();
    this.fieldErrors = {};
  }

  ngOnInit() {
    this.editModel = new CompanyEditModel();
    this.loadRightModels();
    this.initDropdownSettings();
  }

  ngAfterViewInit() {
  }

  // Loads user rights
  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  private loadRoles() {
    this.roleService.query({
      disabled: false,
      orders: Set.of({field: Role.OrderField.NAME, type: OrderType.ASC})
    }).subscribe(
      (result: QueryResult<Role.Role>) => {
        this.roles = [];
        this.selectedRoles = [];
        result.items.toArray().forEach((role: Role.Role) => {
          const roleItem = {
            id: role.id,
            text: role.name
          };
          this.roles.push(roleItem);
        });
      });
  }

  private loadLocalFieldValidationErrors() {
    const validatedInputs = List.of(this.name,
      this.taxNumber,
      this.externalId,
      this.type
    );
    this.validatedInputs = LocalFieldValidationErrorsFactory.ofFormFields(this.fForm, validatedInputs);
  }

  private loadCountries(completion: () => void) {
    this.countryService.query({}).subscribe((result: QueryResult<Country.Country>) => {
      this.countryItems = AddressModel.CountryItem.fromCountryList(result.items);
      completion();
    });
  }

  createCompany() {
    if (this.hasLocalFieldError()) {
      return;
    }
    if (!this.editModel.postalAddress.valid) {
      return;
    }

    if (this.editModel.type.id === 'UNKNOWN') {
      return
    }

    this.companyService.create({
      name: this.editModel.name,
      externalId: Strings.undefinedOrNonEmpty(this.editModel.externalId),
      type: this.editModel.type.id,
      taxNumber: this.editModel.taxNumber.truncated ? this.editModel.taxNumber.truncated : '',
      euTaxNumber: this.editModel.euTaxNumber.truncated ? this.editModel.euTaxNumber.truncated : '',
      postalAddress: this.editModel.postalAddress.toData()!,
      transporterCompanyIds: Set.of(),
      allowedRoleIds: Set.of(... this.selectedRoles.map((selectedRole) => selectedRole.id!))
    }).subscribe(
      (response: IdentityMessage) => {
        this.companyService.updateRole({
        id: response.id,
        forceRemove: false,
        allowedRoleIds: Set.of(... this.selectedRoles.map((selectedRole) => selectedRole.id!))
      }).subscribe(
        () => {
          this.fForm.resetForm();
          this.editModel.postalAddress.reset();
          this.closeCompanyCreateDialog();
          this.companyCreated.emit(response.id);
        });
      },
      (error: any) => {
        const res = ObservableErrorResourceParser.parseError(error);
        this.fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
      });
  }

  showCompanyCreateDialog() {
    this.editModel = new CompanyEditModel();
    if (!this.modelLoaded) {
      this.loadCountries(() => {
        this.editModel.postalAddress.loadCountryItems(this.countryItems, this.configService.getDefaultSelectedCountryCode());
        this.loadRoles();
        this.modelLoaded = true;
      });
    }
    else {
      this.editModel.postalAddress.loadCountryItems(this.countryItems, this.configService.getDefaultSelectedCountryCode());
    }
    this.companyCreateDialogVisible = true;
    this.companyCreateDialog.show();
  }

  closeCompanyCreateDialog() {
    this.companyCreateDialogVisible = false;
    this.companyCreateDialog.hide();
  }

  onModalHidden() {
    this.fForm.resetForm();
    this.editModel.postalAddress.reset();
  }

  onModalShown() {
    this.loadLocalFieldValidationErrors();
  }

  hasLocalFieldError(field?: NgModel): boolean {
    return this.validatedInputs.hasLocalError(field);
  }

  removeFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.fieldErrors, fieldError);
  }

  // Initializes settings for the multiselect dropdown
  initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .labelKey(OptionItem.KEY_TEXT)
      .enableCheckAll(false)
      .build();
  }

}
