/* eslint-disable */
import { OffsetDateTime } from '../util/dates';
import { EmailAddress } from '../util/email-address';
import { PhoneNumber } from '../util/phone-number';
import { Address, AddressResource } from '../address';
import { List, Map, Set } from 'immutable';
import { Order, PagingRequest, QueryResult, ResourceQueryResult, Services } from '../util/services';
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { EmptyMessage, IdentityMessage } from '../util/messages';
import {
  ShippingDemandResource,
  ShippingDemandResourceService
} from './shipping-demand-resource.service';
import { Decimal } from 'decimal.js';
import { Models } from '../../util/model-utils';
import { DqlStoredQuery, DqlStoredQueryService } from '../dql/dql-stored-query.service';
import { DqlModel } from '../dql/dql.model';
import { DqlResourceModel } from '../dql/dql-resource.model';
import { HistoryLog } from '../history-log/history-log.service';
import { UserItem, UserItemResource } from '../user.service';
import ShippingDemandState = ShippingDemand.ShippingDemandState;
import { DownloadedFile } from '../util/downloaded-files';
import { TransportDocument } from '../transport/transport-document/transport-document.service';
import { BadgeStyle } from '../../shared/table-badge/badge-style';

/* eslint-enable */

@Injectable()
export class ShippingDemandService implements ShippingDemand.Service, DqlStoredQuery.HolderService {

  private readonly _dqlStoredQueryService: DqlStoredQueryService;

  constructor(private resourceService: ShippingDemandResourceService) {
    this._dqlStoredQueryService = new DqlStoredQueryService(resourceService.dqlStoredResourceService);
  }

  get dqlStoredQueryService(): DqlStoredQueryService {
    return this._dqlStoredQueryService;
  }

  query(request: ShippingDemand.QueryRequest): Observable<QueryResult<ShippingDemand.ShippingDemand>> {
    return Observable.create((observer: Observer<QueryResult<ShippingDemand.ShippingDemand>>) => {
      const resourceRequest: ShippingDemandResource.QueryRequest = {
        has_transport: request.hasTransport,
        demand_id: request.demandId,
        tour_route: request.tourRoute,
        transporter_name: request.transporterName,
        demand_category: request.demandCategory,
        source_name: request.sourceName,
        source_postal_address: request.sourceAddress,
        safety_shipping: request.safetyShipping,
        deadline_from: Services.offsetDateTimeToString(request.deadlineFrom),
        deadline_to: Services.offsetDateTimeToString(request.deadlineTo),
        destination_postal_address: request.destinationAddress,
        destination_name: request.destinationName,
        demand_state: Services.createListParameter(request.state),
        shipping_item: request.shippingItem,
        q: request.queryText,
        dql: request.dqlText,
        order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
      };
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<ShippingDemandResource.ShippingDemand>) => {
          observer.next({
            items: ShippingDemandMapper.toPublicList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  idQuery(request: ShippingDemand.QueryRequest): Observable<QueryResult<ShippingDemand.ShippingDemandId>> {
    return Observable.create((observer: Observer<QueryResult<ShippingDemand.ShippingDemandId>>) => {
      const resourceRequest: ShippingDemandResource.QueryRequest = {
        has_transport: request.hasTransport,
        demand_id: request.demandId,
        tour_route: request.tourRoute,
        transporter_name: request.transporterName,
        demand_category: request.demandCategory,
        source_name: request.sourceName,
        source_postal_address: request.sourceAddress,
        safety_shipping: request.safetyShipping,
        deadline_from: Services.offsetDateTimeToString(request.deadlineFrom),
        deadline_to: Services.offsetDateTimeToString(request.deadlineTo),
        destination_postal_address: request.destinationAddress,
        destination_name: request.destinationName,
        demand_state: Services.createListParameter(request.state),
        shipping_item: request.shippingItem,
        q: request.queryText,
        dql: request.dqlText,
        order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
        fields: 'id,safety_shipping'
      };
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<ShippingDemandResource.ShippingDemand>) => {
          observer.next({
            items: ShippingDemandMapper.toPublicIdList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  get(request: ShippingDemand.GetRequest): Observable<ShippingDemand.ShippingDemand> {
    return Observable.create((observer: Observer<ShippingDemand.ShippingDemand>) => {
      const resourceRequest: ShippingDemandResource.GetRequest = {
        demand_id: request.demandId
      };
      return this.resourceService.get(resourceRequest).subscribe(
        (result: ShippingDemandResource.ShippingDemand) => {
          observer.next(ShippingDemandMapper.toPublic(result));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  create(request: ShippingDemand.CreateRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: ShippingDemandResource.CreateRequest = {
        external_id: request.externalId,
        safety_shipping: request.safetyShipping,
        recipient: ShippingDemandMapper.toResourceExchangingPerson(request.recipient),
        transferor: ShippingDemandMapper.toResourceExchangingPerson(request.transferor),
        transporter_id: request.transporterId,
        demander_id: request.demanderId,
        source: ShippingDemandMapper.toResourceSourceDestination(request.source),
        destination: ShippingDemandMapper.toResourceSourceDestination(request.destination),
        source_system_name: request.sourceSystemName,
        comment: request.comment,
        demand_category: request.demandCategory,
        ekaer_id: request.ekaerId,
        ekaer_required: request.ekaerRequired,
        tour_route: request.tourRoute,
        deadline: Services.offsetDateTimeToString(request.deadline),
        weight_in_gram: request.weightInGram,
        items: ShippingDemandMapper.toResourceItemArray(request.items),
        cash_on_delivery_price: ShippingDemandMapper.toResourceCashOnDeliveryPrice(request.cashOnDeliveryPrice),
        insurance_price: ShippingDemandMapper.toResourceInsurancePrice(request.insurancePrice),
        packaging_attributes: ShippingDemandMapper.toResourcePackagingAttributes(request.packagingAttributes),
        carriage_by_demander_company: request.carriageByDemanderCompany
      };
      return this.resourceService.create(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  update(request: ShippingDemand.UpdateRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: ShippingDemandResource.UpdateRequest = {
        id: request.id,
        external_id: request.externalId,
        safety_shipping: request.safetyShipping,
        recipient: ShippingDemandMapper.toResourceExchangingPerson(request.recipient),
        transferor: ShippingDemandMapper.toResourceExchangingPerson(request.transferor),
        transporter_id: request.transporterId,
        demander_id: request.demanderId,
        source: ShippingDemandMapper.toResourceSourceDestination(request.source),
        destination: ShippingDemandMapper.toResourceSourceDestination(request.destination),
        source_system_name: request.sourceSystemName,
        comment: request.comment,
        demand_category: request.demandCategory,
        ekaer_id: request.ekaerId,
        ekaer_required: request.ekaerRequired,
        tour_route: request.tourRoute,
        deadline: Services.offsetDateTimeToString(request.deadline),
        weight_in_gram: request.weightInGram,
        items: ShippingDemandMapper.toResourceItemArray(request.items),
        cash_on_delivery_price: ShippingDemandMapper.toResourceCashOnDeliveryPrice(request.cashOnDeliveryPrice),
        insurance_price: ShippingDemandMapper.toResourceInsurancePrice(request.insurancePrice),
        packaging_attributes: ShippingDemandMapper.toResourcePackagingAttributes(request.packagingAttributes),
        carriage_by_demander_company: request.carriageByDemanderCompany
      };
      return this.resourceService.update(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  cancel(request: IdentityMessage): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: IdentityMessage = {
        id: request.id
      };
      return this.resourceService.cancel(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  split(request: ShippingDemand.SplitRequest): Observable<IdentityMessage> {
    return Observable.create((observer: Observer<IdentityMessage>) => {
      const resourceRequest: ShippingDemandResource.SplitRequest = {
        shipping_demand_id: request.shippingDemandId,
        items: request.items.map(i => ({external_id: i.externalId, amount: i.amount}))
      };
      return this.resourceService.split(resourceRequest).subscribe(
        (result: IdentityMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  getEditableFields(): Observable<ShippingDemand.EditableFields> {
    return Observable.create((observer: Observer<ShippingDemand.EditableFields>) => {
      return this.resourceService.getEditableFields().subscribe(
        (result) => {
          observer.next((new ShippingDemand.EditableFields(result.editable_normal_fields, result.editable_simple_fields)));
        }
      )
    });
  }

  getDqlModel(): Observable<DqlModel.QueryableModel> {
    return Observable.create((observer: Observer<DqlModel.QueryableModel>) => {
      return this.resourceService.getDqlModel().subscribe((result: DqlResourceModel.QueryableModel) => {
          observer.next(new DqlModel.DqlQueryableModelConverter(false).toPublic(result));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        }
      );
    });
  }

  history(request: ShippingDemand.HistoryRequest): Observable<QueryResult<ShippingDemand.HistoryItem>> {
    return Observable.create((observer: Observer<QueryResult<ShippingDemand.HistoryItem>>) => {
      const resourceRequest: ShippingDemandResource.HistoryRequest = {
        id: request.id,
        q: request.queryText,
        order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
      };
      return this.resourceService.history(resourceRequest).subscribe(
        (result: ResourceQueryResult<HistoryLog.HistoryItemBaseResource>) => {
          observer.next({
            items: ShippingDemandMapper.toPublicHistoryList(result.items),
            pagingResult: result.pagingResult
          });
        });

    });
  }

  generateCarriageTagPdfDocument(request: ShippingDemand.GenerateCarriageTagPdfDocumentRequest): Observable<DownloadedFile> {
    return this.resourceService.generateCarriageTagPdfDocument({
      demand_ids: request.demandIds
    });
  }

  downloadCarriageReceiptPdfDocument(request: IdentityMessage): Observable<DownloadedFile> {
    return this.resourceService.downloadCarriageReceiptPdfDocument(request);
  }

}

export namespace ShippingDemand {

  export interface Service {

    // <editor-fold desc="CRUD">
    query(request: QueryRequest): Observable<QueryResult<ShippingDemand>>;

    get(request: GetRequest): Observable<EmptyMessage>;

    create(request: CreateRequest): Observable<EmptyMessage>;

    update(request: UpdateRequest): Observable<EmptyMessage>;

    // </editor-fold>

  }

  export interface QueryRequest {
    hasTransport?: boolean;
    demandId?: number;
    tourRoute?: string;
    transporterName?: string;
    demandCategory?: string;
    sourceName?: string;
    sourceAddress?: string;
    destinationName?: string;
    safetyShipping?: boolean;
    deadlineFrom?: OffsetDateTime;
    deadlineTo?: OffsetDateTime;
    destinationAddress?: string;
    state?: Set<ShippingDemandState>;
    shippingItem?: string;
    queryText?: string;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
    disabled?: boolean;
    dqlText?: string;
  }

  export interface GetRequest {
    demandId: number;
  }

  export interface ShippingDemand {
    id: number;
    externalId: string;
    creationTime: OffsetDateTime;
    updateTime: OffsetDateTime;
    state: ShippingDemandState;
    safetyShipping: boolean;
    recipient?: ExchangingPerson;
    transferor?: ExchangingPerson;
    transporter?: Company;
    demander?: Demander;
    source: ShippingPlace;
    destination: ShippingPlace;
    transports: Set<Transport>;
    shipmentGroup?: ShipmentGroup;
    sourceSystemName?: string;
    comment?: string;
    demandCategory?: string;
    ekaerId?: string;
    ekaerRequired?: boolean;
    tourRoute?: string;
    deadline?: OffsetDateTime;
    weightInGram?: number;
    deliveryNoteNumber?: string;
    insurancePrice?: InsurancePrice;
    cashOnDeliveryPrice?: CashOnDeliveryPrice;
    demandGroupSerial: string;
    demandSerial: string;
    packagingAttributes: PackagingAttributeType[];
    carriageState?: CarriageState;
  }

  export interface ShippingDemandId {
    id: number;
    safetyShipping: boolean;
  }

  export interface ExchangingPerson {
    id: number;
    name: string;
    licenseCategory: string;
    licenseNumber: string;
    comment?: string;
    emailAddress?: EmailAddress;
    phoneNumber?: PhoneNumber;
  }

  export interface UpdateExchangingPerson {
    name: string;
    licenseCategory?: string;
    licenseNumber?: string;
    comment?: string;
    emailAddress?: EmailAddress;
    phoneNumber?: PhoneNumber;
  }

  export interface Company {
    id: number;
    name: string;
    address?: Address.PostalAddressData;
  }

  export interface Demander {
    company?: Company;
  }

  export interface ShippingPlace {
    id: number;
    stock?: Stock;
    place: Address.Place;
    location?: CompanyLocation;
    locationChanged: boolean;
  }

  export interface Transport {
    id: number;
    waybillNumber: string;
    securityGuardName?: string;
  }

  export interface ShipmentGroup {
    id: number;
    shipments: Shipment[];
  }

  export interface Shipment {
    id: number;
    deliveryNoteNumber: string;
  }

  export interface Stock {
    id: number;
    name: string;
    assigneeGroupId: number;
  }

  export interface CompanyLocation {
    id: number;
    name: string;
    place?: Address.Place;
  }

  export interface CashOnDeliveryPrice {
    amount: Decimal;
    currencyCode: string;
  }

  export interface InsurancePrice {
    amount: Decimal;
    currencyCode: string;
  }

  export type PackagingAttributeType =
    'FRAGILE'
    | 'DOCUMENT_HANDLING'
    | 'PACKAGE_EXCHANGE'
    | 'HANDOVER_BY_ITEMS'
    | 'DELIVERY_TIME_WINDOW'
    | 'OVERSIZED'
    | 'INVERSE';

  export interface PackagingAttributeTypeObject {
    type: PackagingAttributeType;
    stringKey: string;
  }

  export const packagingAttributeTypes: PackagingAttributeTypeObject[] =
    [
      {type: 'FRAGILE', stringKey: 'SHIPPING_DEMAND_PACKAGING_ATTRIBUTE_FRAGILE'},
      {type: 'DOCUMENT_HANDLING', stringKey: 'SHIPPING_DEMAND_PACKAGING_ATTRIBUTE_DOCUMENT_HANDLING'},
      {type: 'PACKAGE_EXCHANGE', stringKey: 'SHIPPING_DEMAND_PACKAGING_ATTRIBUTE_PACKAGE_EXCHANGE'},
      {type: 'HANDOVER_BY_ITEMS', stringKey: 'SHIPPING_DEMAND_PACKAGING_ATTRIBUTE_HANDOVER_BY_ITEMS'},
      {type: 'DELIVERY_TIME_WINDOW', stringKey: 'SHIPPING_DEMAND_PACKAGING_ATTRIBUTE_DELIVERY_TIME_WINDOW'},
      {type: 'OVERSIZED', stringKey: 'SHIPPING_DEMAND_PACKAGING_ATTRIBUTE_OVERSIZED'},
      {type: 'INVERSE', stringKey: 'SHIPPING_DEMAND_PACKAGING_ATTRIBUTE_INVERSE'},
    ];

  export type CarriageState = 'OPEN' | 'DEMAND_CANCELED' | 'IN_PROGRESS' | 'PENDING_APPROVAL' | 'SUCCEEDED';

  export interface CreateRequest {
    externalId?: string;
    safetyShipping: boolean;
    recipient?: UpdateExchangingPerson;
    transferor?: UpdateExchangingPerson;
    transporterId?: number;
    demanderId?: number;
    source: SourceDestination;
    destination: SourceDestination;
    sourceSystemName?: string;
    comment?: string;
    demandCategory?: string;
    ekaerId?: string;
    ekaerRequired?: boolean;
    tourRoute?: string;
    deadline?: OffsetDateTime;
    weightInGram?: number;
    items: Item[];
    insurancePrice?: InsurancePrice;
    cashOnDeliveryPrice?: CashOnDeliveryPrice;
    packagingAttributes: PackagingAttributeType[];
    carriageByDemanderCompany: boolean;
  }

  export interface SourceDestination {
    stockId?: number;
    place?: Address.PlaceCreateRequest;
    locationId?: number;
  }

  export interface Item {
    id?: number;
    externalId?: string;
    name: string;
    category?: string;
    productCode?: string;
    amount?: Amount;
    comment?: string;
    boxCode?: string;
    labelCode?: string;
    weightInGram?: number;
    itemSize?: ItemSize;
    price?: Price;
  }

  export interface Amount {
    value: number;
    unit: string;
  }

  export interface ItemSize {
    width: Decimal;
    height: Decimal;
    depth: Decimal;
  }

  export interface Price {
    amount: string;
    currencyCode: string;
  }

  export interface UpdateRequest {
    id: number;
    externalId?: string;
    safetyShipping: boolean;
    recipient?: UpdateExchangingPerson;
    transferor?: UpdateExchangingPerson;
    transporterId?: number;
    demanderId?: number;
    source: SourceDestination;
    destination: SourceDestination;
    sourceSystemName?: string;
    comment?: string;
    demandCategory?: string;
    ekaerId?: string;
    ekaerRequired?: boolean;
    tourRoute?: string;
    deadline?: OffsetDateTime;
    weightInGram?: number;
    items: Item[];
    insurancePrice?: InsurancePrice;
    cashOnDeliveryPrice?: CashOnDeliveryPrice;
    packagingAttributes: PackagingAttributeType[];
    carriageByDemanderCompany: boolean;
  }

  export interface SplitRequest {
    shippingDemandId: number;
    items: SplitItem[];
  }

  export interface SplitItem {
    externalId: string;
    amount: number;
  }

  export interface HistoryRequest {
    id: number;
    queryText?: string;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
  }

  export interface HistoryItem extends HistoryLog.HistoryItemBase {
    state: ShippingDemandState;
  }

  export interface GenerateCarriageTagPdfDocumentRequest {
    demandIds: number[];
  }

  export class EditableFields {
    editableNormalFields: string[];
    editableSimpleFields: string[];

    constructor(normalFields: string[], simpleFields: string[]) {
      this.editableNormalFields = normalFields;
      this.editableSimpleFields = simpleFields;
    }

    isEnabledByServer(isSimple: boolean, field: EditableField): boolean {
      return isSimple
        ? this.editableSimpleFields.includes(editableFieldMap.get(field))
        : this.editableNormalFields.includes(editableFieldMap.get(field));
    }
  }

  export enum EditableField {
    SAFETY_SHIPPING,
    TRANSPORTER,
    DEMANDER,
    SOURCE_SYSTEM_NAME,
    COMMENT,
    DEMAND_CATEGORY,
    EKAER_ID,
    EKAER_REQUIRED,
    TOUR_ROUTE,
    DEADLINE,
    WEIGHT_IN_GRAM,
    CASH_ON_DELIVERY_PRICE,
    INSURANCE_PRICE,
    PACKAGING_ATTRIBUTES,
    RECIPIENT_COMMENT,
    RECIPIENT_LICENSE_NUMBER,
    RECIPIENT_LICENSE_CATEGORY,
    RECIPIENT_EMAIL,
    RECIPIENT_PHONE,
    TRANSFEROR_COMMENT,
    TRANSFEROR_LICENSE_NUMBER,
    TRANSFEROR_LICENSE_CATEGORY,
    TRANSFEROR_EMAIL,
    TRANSFEROR_PHONE,
    SOURCE_STOCK,
    DESTINATION_STOCK,
    ITEM_CATEGORY,
    ITEM_PRODUCT_CODE,
    ITEM_AMOUNT,
    ITEM_COMMENT,
    ITEM_BOX_CODE,
    ITEM_LABEL_CODE,
    ITEM_WEIGHT_IN_GRAM,
    ITEM_ITEM_SIZE,
    ITEM_PRICE
  }

  const editableFieldMap: Map<EditableField, string> = Map.of(
    EditableField.SAFETY_SHIPPING, 'safety_shipping',
    EditableField.TRANSPORTER, 'transporter',
    EditableField.DEMANDER, 'demander',
    EditableField.SOURCE_SYSTEM_NAME, 'source_system_name',
    EditableField.COMMENT, 'comment',
    EditableField.DEMAND_CATEGORY, 'demand_category',
    EditableField.EKAER_ID, 'ekaer_id',
    EditableField.EKAER_REQUIRED, 'ekaer_required',
    EditableField.TOUR_ROUTE, 'tour_route',
    EditableField.DEADLINE, 'deadline',
    EditableField.WEIGHT_IN_GRAM, 'weight_in_gram',
    EditableField.CASH_ON_DELIVERY_PRICE, 'cash_on_delivery_price',
    EditableField.INSURANCE_PRICE, 'insurance_price',
    EditableField.PACKAGING_ATTRIBUTES, 'packaging_attributes',
    EditableField.RECIPIENT_COMMENT, 'recipient.comment',
    EditableField.RECIPIENT_LICENSE_NUMBER, 'recipient.license_number',
    EditableField.RECIPIENT_LICENSE_CATEGORY, 'recipient.license_category',
    EditableField.RECIPIENT_EMAIL, 'recipient.email_address',
    EditableField.RECIPIENT_PHONE, 'recipient.phone_number',
    EditableField.TRANSFEROR_COMMENT, 'transferor.comment',
    EditableField.TRANSFEROR_LICENSE_NUMBER, 'transferor.license_number',
    EditableField.TRANSFEROR_LICENSE_CATEGORY, 'transferor.license_category',
    EditableField.TRANSFEROR_EMAIL, 'transferor.email_address',
    EditableField.TRANSFEROR_PHONE, 'transferor.phone_number',
    EditableField.SOURCE_STOCK, 'source.stock_id',
    EditableField.DESTINATION_STOCK, 'destination.stock_id',
    EditableField.ITEM_CATEGORY, 'items.category',
    EditableField.ITEM_PRODUCT_CODE, 'items.product_code',
    EditableField.ITEM_AMOUNT, 'items.amount',
    EditableField.ITEM_COMMENT, 'items.comment',
    EditableField.ITEM_BOX_CODE, 'items.box_code',
    EditableField.ITEM_LABEL_CODE, 'items.label_code',
    EditableField.ITEM_WEIGHT_IN_GRAM, 'items.weight_in_gram',
    EditableField.ITEM_ITEM_SIZE, 'items.item_size',
    EditableField.ITEM_PRICE, 'items.price',
  );

  export type ShippingDemandState = 'NEW' | 'UNDER_CONSTRUCTION' | 'IN_PROGRESS' | 'DELIVERED' | 'HANDOVER_FAILED' | 'CANCELED'
    | 'TAKEOVER_FAILED' | 'WAITING_FOR_CARRIAGE';

  export class ShippingDemandStateObject {
    state: ShippingDemandState;
    stringKey: string;
    iconClass: string;
    badgeStyle: string;
  }

  export const shippingDemandStates: ShippingDemandStateObject[] = [
    {
      state: 'NEW',
      stringKey: 'SHIPPING_DEMAND_STATE_NEW',
      iconClass: 'icomoon-shipment-new',
      badgeStyle: BadgeStyle.PRIMARY
    },
    {
      state: 'UNDER_CONSTRUCTION',
      stringKey: 'SHIPPING_DEMAND_STATE_UNDER_CONSTRUCTION',
      iconClass: 'icomoon-demand-in-progress',
      badgeStyle: BadgeStyle.SECONDARY
    },
    {
      state: 'IN_PROGRESS',
      stringKey: 'SHIPPING_DEMAND_STATE_IN_PROGRESS',
      iconClass: 'icomoon-order-state-under-processing',
      badgeStyle: BadgeStyle.SECONDARY
    },
    {
      state: 'DELIVERED',
      stringKey: 'SHIPPING_DEMAND_STATE_DELIVERED',
      iconClass: 'icomoon-order-state-delivered',
      badgeStyle: BadgeStyle.SUCCESS
    },
    {
      state: 'HANDOVER_FAILED',
      stringKey: 'SHIPPING_DEMAND_STATE_HANDOVER_FAILED',
      iconClass: 'icomoon-order-state-delivery-failed',
      badgeStyle: BadgeStyle.DANGER
    },
    {
      state: 'CANCELED',
      stringKey: 'SHIPPING_DEMAND_STATE_CANCELED',
      iconClass: 'icomoon-reject-manually',
      badgeStyle: BadgeStyle.DANGER
    },
    {
      state: 'TAKEOVER_FAILED',
      stringKey: 'SHIPPING_DEMAND_STATE_TAKEOVER_FAILED',
      iconClass: 'icomoon-takeover-failed',
      badgeStyle: BadgeStyle.DANGER
    },
    {
      state: 'WAITING_FOR_CARRIAGE',
      stringKey: 'SHIPPING_DEMAND_STATE_WAITING_FOR_CARRIAGE',
      iconClass: 'icomoon-demand-waiting-for-carriage',
      badgeStyle: BadgeStyle.SECONDARY
    },
  ];

  export enum OrderField {
    DEMAND_ID,
    TOUR_ROUTE,
    CUSTOMER_NAME,
    DEADLINE,
    TRANSPORTER_NAME,
    SOURCE_NAME,
    DESTINATION_NAME,
    HISTORY_CREATION_TIME,
    HISTORY_ISSUER_USER_PERSON_NAME,
    HISTORY_MOBILE_APPLICATION_APPLICTAION_ID
  }

  export enum ValidatedField {
    UNKNOWN,

    EXTERNAL_ID,
  }
}

class Keys {

  private static readonly DEMAND_ID = 'demand_id';
  private static readonly TOUR_ROUTE = 'tour_route';
  private static readonly CUSTOMER_NAME = 'customer_name';
  private static readonly DEADLINE = 'deadline';
  private static readonly TRANSPORTER_NAME = 'transporter_name';
  private static readonly SOURCE_NAME = 'source_name';
  private static readonly DESTINATION_NAME = 'destination_name';
  private static readonly HISTORY_CREATION_TIME = 'creation_time';
  private static readonly HISTORY_ISSUER_USER_PERSON_NAME = 'issuer_user_person_name';
  private static readonly HISTORY_MOBILE_APPLICATION_APPLICTAION_ID = 'mobile_application_application_id';

  private static readonly EXTERNAL_ID = 'external_id';

  private static readonly orderFieldKeyMap: Map<ShippingDemand.OrderField, string> = Map.of(
    ShippingDemand.OrderField.DEMAND_ID, Keys.DEMAND_ID,
    ShippingDemand.OrderField.TOUR_ROUTE, Keys.TOUR_ROUTE,
    ShippingDemand.OrderField.CUSTOMER_NAME, Keys.CUSTOMER_NAME,
    ShippingDemand.OrderField.DEADLINE, Keys.DEADLINE,
    ShippingDemand.OrderField.TRANSPORTER_NAME, Keys.TRANSPORTER_NAME,
    ShippingDemand.OrderField.SOURCE_NAME, Keys.SOURCE_NAME,
    ShippingDemand.OrderField.DESTINATION_NAME, Keys.DESTINATION_NAME,
    ShippingDemand.OrderField.HISTORY_CREATION_TIME, Keys.HISTORY_CREATION_TIME,
    ShippingDemand.OrderField.HISTORY_ISSUER_USER_PERSON_NAME, Keys.HISTORY_ISSUER_USER_PERSON_NAME,
    ShippingDemand.OrderField.HISTORY_MOBILE_APPLICATION_APPLICTAION_ID, Keys.HISTORY_MOBILE_APPLICATION_APPLICTAION_ID,
  );

  private static readonly keyValidatedFieldMap: Map<string, ShippingDemand.ValidatedField> = Map.of(
    ShippingDemand.ValidatedField.EXTERNAL_ID, Keys.EXTERNAL_ID,
  );

  public static toOrderFieldKey(field: ShippingDemand.OrderField): string {
    return Keys.orderFieldKeyMap.get(field)!;
  }

  public static toValidatedField(fieldKey: string): ShippingDemand.ValidatedField {
    return Keys.keyValidatedFieldMap.get(fieldKey, ShippingDemand.ValidatedField.UNKNOWN);
  }

}


// TODO: Use this mapper instead of local functions once merge conflicts have been resolved
export class ShippingDemandMapper {

  public static toPublicList(resourceList: ShippingDemandResource.ShippingDemand[]): List<ShippingDemand.ShippingDemand> {
    return List.of(...resourceList.map((r) => this.toPublic(r)));
  }

  public static toPublic(r: ShippingDemandResource.ShippingDemand): ShippingDemand.ShippingDemand {
    return {
      id: r.id,
      externalId: r.external_id,
      creationTime: Services.toOffsetDateTime(r.creation_time),
      updateTime: Services.toOffsetDateTime(r.update_time),
      state: <ShippingDemand.ShippingDemandState> r.state,
      safetyShipping: r.safety_shipping,
      recipient: this.toPublicExchangingPerson(r.recipient),
      transferor: this.toPublicExchangingPerson(r.transferor),
      transporter: this.toPublicCompany(r.transporter),
      demander: this.toPublicDemander(r.demander),
      source: this.toPublicShippingPlace(r.source),
      destination: this.toPublicShippingPlace(r.destination),
      transports: this.toPublicTransportList(r.transports),
      shipmentGroup: this.toPublicShipmentGroup(r.shipment_group),
      sourceSystemName: r.source_system_name,
      comment: r.comment,
      demandCategory: r.demand_category,
      ekaerId: r.ekaer_id,
      ekaerRequired: r.ekaer_required,
      tourRoute: r.tour_route,
      deadline: Services.toOffsetDateTime(r.deadline),
      weightInGram: r.weight_in_gram,
      deliveryNoteNumber: r.delivery_note_number,
      cashOnDeliveryPrice: this.toPublicCashOnDeliveryPrice(r.cash_on_delivery_price),
      insurancePrice: this.toPublicInsurancePrice(r.insurance_price),
      demandGroupSerial: r.demand_group_serial,
      demandSerial: r.demand_serial,
      packagingAttributes: this.toPublicPackagingAttributes(r.packaging_attributes),
      carriageState: r.carriage_state ? <ShippingDemand.CarriageState>r.carriage_state : undefined
    };
  }

  public static toPublicIdList(resourceList: ShippingDemandResource.ShippingDemand[]): List<ShippingDemand.ShippingDemandId> {
    return List.of(...resourceList.map((r) => this.toPublicId(r)));
  }

  public static toPublicId(r: ShippingDemandResource.ShippingDemand): ShippingDemand.ShippingDemandId {
    return {
      id: r.id,
      safetyShipping: r.safety_shipping,
    };
  }

  public static toPublicExchangingPerson(r?: ShippingDemandResource.ExchangingPerson): ShippingDemand.ExchangingPerson | undefined {
    if (r) {
      return {
        id: r.id,
        name: r.name,
        licenseCategory: r.license_category,
        licenseNumber: r.license_number,
        comment: r.comment,
        emailAddress: Services.toEmailAddress(r.email_address),
        phoneNumber: Services.toPhoneNumber(r.phone_number),
      };
    }
    return undefined;
  }

  public static toResourceExchangingPerson(request?: ShippingDemand.UpdateExchangingPerson):
    ShippingDemandResource.UpdateExchangingPerson | undefined {
    if (request) {
      return {
        name: request.name,
        license_category: request.licenseCategory,
        license_number: request.licenseNumber,
        comment: request.comment,
        email_address: Services.emailAddressToString(request.emailAddress),
        phone_number: Services.phoneNumberToString(request.phoneNumber),
      };
    }
    return undefined;
  }

  public static toPublicInsurancePrice(r?: ShippingDemandResource.InsurancePrice): ShippingDemand.InsurancePrice | undefined {
    if (r) {
      return {
        amount: Services.reqDecimal(r.amount),
        currencyCode: r.currency_code
      }
    }
    return undefined;
  }

  public static toPublicPackagingAttributes(r: ShippingDemandResource.PackagingAttributes): ShippingDemand.PackagingAttributeType[] {
    const result: ShippingDemand.PackagingAttributeType[] = [];
    if (r.fragile) {
      result.push('FRAGILE');
    }
    if (r.document_handling) {
      result.push('DOCUMENT_HANDLING');
    }
    if (r.package_exchange) {
      result.push('PACKAGE_EXCHANGE');
    }
    if (r.handover_by_items) {
      result.push('HANDOVER_BY_ITEMS');
    }
    if (r.delivery_time_window) {
      result.push('DELIVERY_TIME_WINDOW');
    }
    if (r.oversized) {
      result.push('OVERSIZED');
    }
    if (r.inverse) {
      result.push('INVERSE');
    }
    return result;
  }

  public static toResourcePackagingAttributes(r: ShippingDemand.PackagingAttributeType[]):
    ShippingDemandResource.PackagingAttributes {
    return {
      fragile: r.includes('FRAGILE'),
      document_handling: r.includes('DOCUMENT_HANDLING'),
      package_exchange: r.includes('PACKAGE_EXCHANGE'),
      handover_by_items: r.includes('HANDOVER_BY_ITEMS'),
      delivery_time_window: r.includes('DELIVERY_TIME_WINDOW'),
      oversized: r.includes('OVERSIZED'),
      inverse: r.includes('INVERSE')
    }
  }

  public static toResourceInsurancePrice(r?: ShippingDemand.InsurancePrice): ShippingDemandResource.InsurancePrice | undefined {
    if (!r) {
      return undefined;
    }
    return {
      amount: Services.decimalToString(r.amount)!,
      currency_code: r.currencyCode
    }
  }

  public static toPublicCashOnDeliveryPrice(r?: ShippingDemandResource.CashOnDeliveryPrice):
    ShippingDemand.CashOnDeliveryPrice | undefined {
    if (r) {
      return {
        amount: Services.toDecimal(r.amount)!,
        currencyCode: r.currency_code
      }
    }
    return undefined;
  }

  public static toResourceCashOnDeliveryPrice(r?: ShippingDemand.CashOnDeliveryPrice):
    ShippingDemandResource.CashOnDeliveryPrice | undefined {
    if (r) {
      return {
        amount: Services.decimalToString(r.amount)!,
        currency_code: r.currencyCode
      }
    }
    return undefined;
  }

  public static toPublicCompany(r?: ShippingDemandResource.Company): ShippingDemand.Company | undefined {
    if (r) {
      return {
        id: r.id,
        name: r.name,
        address: AddressResource.Mapper.toPublicPostalAddressOpt(r.address)
      };
    }
    return undefined;
  }

  public static toPublicDemander(r?: ShippingDemandResource.Demander): ShippingDemand.Demander | undefined {
    if (r) {
      return {
        company: this.toPublicCompany(r.company)
      };
    }
    return undefined;
  }

  public static toPublicShippingPlace(r: ShippingDemandResource.ShippingPlace): ShippingDemand.ShippingPlace {
    return {
      id: r.id,
      stock: this.toPublicStock(r.stock),
      place: this.toPublicPlace(r.place)!,
      location: this.toPublicCompanyLocation(r.location),
      locationChanged: r.location_changed
    };
  }

  public static toPublicTransportList(r: ShippingDemandResource.Transport[]): Set<ShippingDemand.Transport> {
    return Set.of(...r.map(transport => this.toPublicTransport(transport)));
  }

  public static toPublicTransport(r: ShippingDemandResource.Transport): ShippingDemand.Transport {
    return {
      id: r.id,
      securityGuardName: r.security_guard_name,
      waybillNumber: r.waybill_number
    };
  }

  public static toPublicShipmentGroup(r?: ShippingDemandResource.ShipmentGroup): ShippingDemand.ShipmentGroup | undefined {
    if (r) {
      return {
        id: r.id,
        shipments: r.shipments.map(shipment => this.toPublicShipment(shipment))
      };
    }
    return undefined;
  }

  public static toPublicShipment(r: ShippingDemandResource.Shipment): ShippingDemand.Shipment {
    return {
      id: r.id,
      deliveryNoteNumber: r.delivery_note_number
    };
  }

  public static toPublicStock(r?: ShippingDemandResource.Stock): ShippingDemand.Stock | undefined {
    if (r) {
      return {
        id: r.id,
        name: r.name,
        assigneeGroupId: r.assignee_group_id
      };
    }
    return undefined;
  }

  public static toPublicPlace(r?: AddressResource.PlaceResource): Address.Place | undefined {
    if (r) {
      return AddressResource.Mapper.toPublicPlace(r)
    }
    return undefined;
  }

  public static toPublicCompanyLocation(r?: ShippingDemandResource.CompanyLocation): ShippingDemand.CompanyLocation | undefined {
    if (r) {
      return {
        id: r.id,
        name: r.name,
        place: this.toPublicPlace(r.place)
      };
    }
    return undefined;
  }

  public static toResourceSourceDestination(request: ShippingDemand.SourceDestination): ShippingDemandResource.SourceDestination {
    return {
      stock_id: request.stockId,
      place: request.place ? this.toResourcePlace(request.place) : undefined,
      location_id: request.locationId
    };
  }

  public static toResourcePlace(request?: Address.PlaceCreateRequest): AddressResource.PlaceCreateResourceRequest | undefined {
    if (request) {
      return AddressResource.Mapper.fromPublicPlace(request)
    }
    return undefined;
  }

  public static toResourcePrice(request?: ShippingDemand.Price): ShippingDemandResource.Price | undefined {
    if (request) {
      return {
        amount: request.amount,
        currency_code: request.currencyCode
      };
    }
    return undefined;
  }

  public static toResourceItemSize(request?: ShippingDemand.ItemSize): ShippingDemandResource.ItemSize | undefined {
    if (request) {
      return {
        width_in_meter: Models.decimalToString(request.width),
        height_in_meter: Models.decimalToString(request.height),
        depth_in_meter: Models.decimalToString(request.depth)
      };
    }
    return undefined;
  }

  public static toResourceItemArray(resourceItems: ShippingDemand.Item[]): ShippingDemandResource.Item[] {
    const items: ShippingDemandResource.Item[] = [];
    resourceItems.forEach((item) => {
      items.push(this.toResourceItem(item));
    });
    return items;
  }

  public static toResourceItem(request: ShippingDemand.Item): ShippingDemandResource.Item {
    return {
      id: request.id,
      external_id: request.externalId,
      name: request.name,
      category: request.category,
      product_code: request.productCode,
      amount: request.amount,
      comment: request.comment,
      box_code: request.boxCode,
      label_code: request.labelCode,
      weight_in_gram: request.weightInGram,
      item_size: this.toResourceItemSize(request.itemSize),
      price: this.toResourcePrice(request.price)
    };
  }

  public static toPublicHistoryList(resourceList: HistoryLog.HistoryItemBaseResource[]): List<ShippingDemand.HistoryItem> {
    return List.of(...resourceList.map((r) => this.toPublicHistory(r)));
  }

  private static toPublicHistory(r: HistoryLog.HistoryItemBaseResource): ShippingDemand.HistoryItem {
    return {
      id: r.id,
      creationTime: Services.toOffsetDateTime(r.creation_time),
      state: <ShippingDemandState>r.state,
      changeLog: r.change_log,
      applicationClassification: r.application_classification,
      issuerUser: this.toPublicUser(r.issuer_user!),
      mobileApplication: this.toPublicMobileApplication(r.mobile_application)
    };
  }

  private static toPublicUser(r: UserItemResource): UserItem {
    return {
      id: r.id,
      personName: r.person_name
    };
  }

  private static toPublicMobileApplication(r: HistoryLog.MobileApplicationItemResource | undefined):
    HistoryLog.MobileApplicationItem | undefined {
    if (r) {
      return {
        id: r.id,
        applicationId: r.application_id
      };
    }
    return undefined;
  }

}
