/* eslint-disable */
import { Transition, UIRouter } from '@uirouter/angular';

/* eslint-enable */

/**
 *
 */
export class ComponentStateResolver {

  private entityId?: number;

  private readonly: boolean = false;
  private clone: boolean = false;
  private currentState: ComponentStateObject = {stateName: '', stateHeaderKey: ''};

  constructor(private uiRouter: UIRouter,
              private transition: Transition,
              private transitionIdString: string,
              private createState?: ComponentStateObject,
              private  editState?: ComponentStateObject,
              private  detailState?: ComponentStateObject,
              private  cloneState?: ComponentStateObject,
  ) {
    const currentStateName = this.uiRouter.stateService.current.name;
    if (createState && currentStateName === createState.stateName) {
      this.readonly = false;
      this.currentState = createState;
    }
    else if (editState && currentStateName === editState.stateName) {
      this.readonly = false;
      this.currentState = editState;
    }
    else if (detailState && currentStateName === detailState.stateName) {
      this.readonly = true;
      this.currentState = detailState;
    }
    else if (cloneState && currentStateName === cloneState.stateName) {
      this.readonly = false;
      this.clone = true;
      this.currentState = cloneState;
    }
    this.entityId = this.transition.params()[transitionIdString];
  }

  get id(): number | undefined {
    return this.entityId;
  }

  isReadonly() {
    return this.readonly;
  }

  isEditable() {
    return !this.readonly;
  }

  isEditView() {
    return !this.readonly && this.entityId !== undefined && !this.clone;
  }

  isCreateView() {
    return !this.readonly && this.entityId === undefined;
  }

  isDetailView() {
    return this.readonly;
  }

  isCloneView() {
    return this.clone && this.entityId !== undefined;
  }

  getCurrentHeaderKey(): string {
    return this.currentState.stateHeaderKey;
  }

  hasEntity() {
    return this.entityId !== undefined;
  }
}

export interface ComponentStateObject {
  stateName: string;
  stateHeaderKey: string;
}
