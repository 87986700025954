<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]=breadcrumbParents
      [self]="breadcrumbSelf">
    </app-breadcrumb>
    <div class="breadcrumb-menu d-flex">
      <app-customer-record-breadcrumb-buttons
        class="d-flex"
        [readonly]="true"
        [allowEdit]="allowEdit"
        [allowTaskRecordQuickCreate]="rightModel.taskRecordCreate.hasRight()"
        [customerId]="customerId"
        [customerRecordId]="customerRecordId"
        [form]="f"
        (onTaskRecordCreateClicked)="openTaskRecordQuickCreateDialog($event)">
      </app-customer-record-breadcrumb-buttons>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-custom-padding-outer animated fadeIn">
  <responsive-tabset class="fullscreen_tab">
    <responsive-tab heading="{{'COMMON_CARD_HEADER_BASE_DATA'|translate}}">
      <form class="form-horizontal bordered-row" (ngSubmit)="nothing()" #f="ngForm" novalidate>
        <app-customer-record-base
          headingDictionaryKey="CUSTOMER_RECORD_PANEL_HEADING_DETAIL"
          [readonly]="true"
          [allowClone]="true"
          [form]="f"
          [customerItem]="combinedResult ? combinedResult.customer : undefined"></app-customer-record-base>
        <div class="card-custom-padding-outer">
          <app-form-record-container
            [readonly]="true"
            [cloning]="false"
            [form]="f">
          </app-form-record-container>
        </div>
      </form>
    </responsive-tab>
    <responsive-tab heading="{{'COMMON_PANEL_HEADING_ATTACHMENT'|translate}}"
                    *ngIf="grantedRights && grantedRights.attachmentRead.hasRight()"
                    (selectTab)="attachmentComponent.initComponent()">
      <app-customer-record-attachment
        #attachmentComponent
        [grantedRights]="grantedRights"
        [customerId]="customerId"
        [customerRecordId]="customerRecordId"
        [readonly]="true">
      </app-customer-record-attachment>
    </responsive-tab>
    <responsive-tab heading="{{'COMMON_LOCATION_CONTACT_PERSONS' | translate}}"
                    *ngIf="combinedResult && combinedResult.customer && combinedResult.customer.type === 'COMPANY'
                    && baseView.getFieldModel().isManaged(CustomerRecordFieldType.CONTACT_PERSONS)"
                    (selectTab)="contactPersonsComponent.initComponent()">
      <app-customer-record-contact-persons
        #contactPersonsComponent
        [readonly]="true"
        [rightModel]="rightModel"
        [customerId]="customerId"
        [customerRecordId]="customerRecordId"
        (inviteToHelpdesk)="contactInviteToHelpdeskDialog.showDialog($event)">
      </app-customer-record-contact-persons>
    </responsive-tab>
    <responsive-tab heading="{{'CUSTOMER_RECORD_LOCATIONS' | translate}}"
                    *ngIf="combinedResult && combinedResult.customer && combinedResult.customer.type === 'COMPANY'
                    && baseView.getFieldModel().isManaged(CustomerRecordFieldType.CONTACT_LOCATIONS)"
                    (selectTab)="locationsListComponent.initComponent()">
      <app-customer-record-contact-locations-list
        #locationsListComponent
        [readonly]="true"
        [customerId]="customerId"
        [customerRecordId]="customerRecordId"
        (detailContactLocation)="contactLocationsDetailDialog.showDialog($event)">
      </app-customer-record-contact-locations-list>
    </responsive-tab>
    <responsive-tab heading="{{'CUSTOMER_RECORD_MAIN_CUSTOMER' | translate}}"
                    *ngIf="combinedResult && combinedResult.customerRecord && combinedResult.customer.contactPerson"
                    (selectTab)="mainCustomerRecordComponent.initComponent()">
      <app-customer-record-main-customer-record
        #mainCustomerRecordComponent
        [customerRecordId]="customerRecordId"
        (detailContactLocation)="contactLocationsDetailDialog.showDialog($event)">
      </app-customer-record-main-customer-record>
    </responsive-tab>
    <responsive-tab heading="{{'CUSTOMER_RECORD_CONNECTED_MASTER_DATA'|translate}}"
                    *ngIf="rightModel.masterDataRecordRead.hasRight()"
                    (selectTab)="masterDataListComponent.initComponent()">
      <app-customer-owner-master-data-list
        #masterDataListComponent
        [readonly]="true">
      </app-customer-owner-master-data-list>
    </responsive-tab>
    <responsive-tab heading="{{'COMMON_HISTORY'|translate}}"
                    *ngIf="rightModel.customerRecordHistoryLog.hasRight()"
                    (selectTab)="historyComponent.initComponent()">
      <app-customer-record-history
        #historyComponent
        [customerId]="customerId"
        [customerRecordId]="customerRecordId"
        (openHistoryDetail)="historyDetailModal.openDetailDialog($event)">
      </app-customer-record-history>
    </responsive-tab>
    <responsive-tab heading="{{'INVOICE_CREATE_RELATED_TASKS'|translate}}"
                    *ngIf="rightModel.taskRecordRead.hasRight()">
      <!--      init should be implemented here too-->
      <app-new-task-record-list
        [customerRecordId]="customerRecordId"
        [_headerStringKey]="'INVOICE_CREATE_RELATED_TASKS'">
      </app-new-task-record-list>
    </responsive-tab>
    <responsive-tab heading="{{'CUSTOMER_RECORD_PHONE_NUMBER_RELATED'|translate}}"
                    *ngIf="rightModel.taskRecordRead.hasRight() && config.taskRecordPhoneNumberRelatedList">
      <!--      init should be implemented here too-->
      <app-new-task-record-list
        [baseCustomerRecordByPhoneNumberId]="customerRecordId"
        [_headerStringKey]="'CUSTOMER_RECORD_PHONE_NUMBER_RELATED'">
      </app-new-task-record-list>
    </responsive-tab>
    <responsive-tab heading="{{'CUSTOMER_RECORD_HELPDESK_DATA' | translate}}"
                    *ngIf="combinedResult && combinedResult.customerRecord && combinedResult.customer.contactPerson"
    (selectTab)="helpdeskComponent.initComponent()">
      <app-customer-record-helpdesk
        #helpdeskComponent
        [rightModel]="rightModel"
        [customerRecord]="combinedResult.customerRecord">
      </app-customer-record-helpdesk>
    </responsive-tab>
    <responsive-tab heading="{{'CUSTOMER_RECORD_BILLING_INFO' | translate}}"
    (selectTab)="billingInfoListComponent.initComponent()">
      <app-customer-record-billing-info-list
        #billingInfoListComponent
        [readonly]="true"
        [customerId]="customerId"
        [customerRecordId]="customerRecordId">
      </app-customer-record-billing-info-list>
    </responsive-tab>
  </responsive-tabset>
</div>

<app-history-detail-dialog
  #historyDetailModal>
</app-history-detail-dialog>

<app-customer-record-contact-locations-detail-dialog
  #contactLocationsDetailDialog
  [customerId]="customerId"
  [customerRecordId]="customerRecordId"
  [readonly]="true">
</app-customer-record-contact-locations-detail-dialog>

<app-confirm-dialog
  #contactInviteToHelpdeskDialog
  [titleEnabled]="true"
  [title]="'CUSTOMER_RECORD_INVITE_TO_HELPDESK_DIALOG_TITLE' | translate"
  [messageEnabled]="false"
  [negativeButtonEnabled]="!contactInviteResult"
  [positiveButton]="(contactInviteResult ? 'COMMON_BUTTON_OK' : 'COMMON_BUTTON_CONTINUE') | translate"
  [hideOnConfirm]="!!contactInviteResult"
  (onConfirm)="contactInviteResult ? contactInviteResult = null : inviteToHelpdesk($event)">
  <div class="alert alert-warning" *ngIf="!contactInviteResult">
    <label>{{'CUSTOMER_RECORD_INVITE_TO_HELPDESK_DIALOG_WARNING' | translate: {count: contactInviteToHelpdeskDialog.emitterValue ? contactInviteToHelpdeskDialog.emitterValue.length : 0} }}</label>
  </div>
  <div *ngIf="contactInviteResult">
    <div class="alert alert-success" *ngIf="contactInviteResult.invited.length > 0">
      <label>{{'CUSTOMER_RECORD_INVITE_TO_HELPDESK_DIALOG_INVITED' | translate }}
        : {{contactInviteResult.invited}}</label>
    </div>
    <div class="alert alert-info" *ngIf="contactInviteResult.alreadyRegistered.length > 0">
      <label>{{'CUSTOMER_RECORD_INVITE_TO_HELPDESK_DIALOG_ALREADY_REGISTERED' | translate }}
        : {{contactInviteResult.alreadyRegistered}}</label>
    </div>
    <div class="alert alert-danger" *ngIf="contactInviteResult.invalidEmail.length > 0">
      <label>{{'CUSTOMER_RECORD_INVITE_TO_HELPDESK_DIALOG_INVALID_EMAIL' | translate }}
        : {{contactInviteResult.invalidEmail}}</label>
    </div>
  </div>
</app-confirm-dialog>
