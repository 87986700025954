/* eslint-disable */
import { Injectable } from '@angular/core';
import { OrderField } from '../query/orderfields';
import { MultiselectOptionItem, OrderFieldModel, OrderFunctionResolver } from '../../util/core-utils';
import { environment } from '../../../environments/environment';
import { NewBaseSearch, NewBaseSearchService } from '../new-base.search-service';
import { OrderType } from '../util/services';
import { SearchUtils } from '../../util/search-utils';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { LoggedInUserStorage } from '../util/storages';
/* eslint-enable */

@Injectable()
export class ParcelCollectionPointSearchService extends NewBaseSearchService<OrderField.ParcelCollectionPoint> {

  protected createStorage(): ParcelCollectionPointSearch.Storage {
    return new ParcelCollectionPointSearch.Storage();
  }

}

export namespace ParcelCollectionPointSearch {

  export class Model extends SearchUtils.SearchModel {

    showSearch: boolean = false;

    name: string;
    code: string;
    city: string;
    deliveryMethod: MultiselectOptionItem<number>[];
    coordinatesMissing: boolean;

    get deliveryMethodId(): number | undefined {
      if (this.deliveryMethod && this.deliveryMethod.length > 0 && this.deliveryMethod[0].id !== null) {
        return this.deliveryMethod[0].id!;
      }
      return undefined;
    }

    constructor() {
      super();
      this.reset();
    }

    reset() {
      this.name = '';
      this.code = '';
      this.city = '';
      this.deliveryMethod = [];
      this.coordinatesMissing = false;
    }

    isEmpty(): boolean {
      return this.name === '' &&
        this.code === '' &&
        this.city === '' &&
        this.deliveryMethod.length === 0 &&
        !this.coordinatesMissing;
    }

  }

  export namespace OrderFunctions {
    export const ID = (f: OrderField.ParcelCollectionPoint) => f.id;
    export const CREATION_TIME = (f: OrderField.ParcelCollectionPoint) => f.creationTime;
    export const UPDATE_TIME = (f: OrderField.ParcelCollectionPoint) => f.updateTime;
    export const NAME = (f: OrderField.ParcelCollectionPoint) => f.name;
    export const CODE = (f: OrderField.ParcelCollectionPoint) => f.code;

    export const VALUES = OrderFunctionResolver.builder<OrderField.ParcelCollectionPoint>()
      .add(ID, 'id')
      .add(CREATION_TIME, 'creation_time')
      .add(UPDATE_TIME, 'update_time')
      .add(NAME, 'name')
      .add(CODE, 'code')
      .build();
  }

  export interface SearchDataResult {
    searchData: SearchData;
  }

  export interface SearchData extends NewBaseSearch.SearchData<OrderField.ParcelCollectionPoint> {
    searchModel: Model;
  }

  interface StoredSearchRawData extends NewBaseSearch.StoredSearchRawData<OrderField.ParcelCollectionPoint> {
    name: string;
    code: string;
    city: string;
    deliveryMethod: MultiselectOptionItem<number>[];
    coordinatesMissing: boolean;
  }

  export class Storage extends NewBaseSearch.Storage<OrderField.ParcelCollectionPoint> {

    constructor() {
      const defaultSearchData: SearchData = {
        queryModel: new OrderFieldModel(OrderFunctions.ID, OrderType.DESC),
        searchModel: new Model(),
      };
      /**
       * Increment this version if you change the SearchData interface.
       */
      const versionNumber: number = 3;
      super(defaultSearchData,
        versionNumber);
    }

    protected generateKey(): string {
      return 'UserId(' + LoggedInUserStorage.getInstance().getUserId() + ')-Search-Parcel-Collection-Point';
    }

    protected fromRaw(data: StoredSearchRawData): NewBaseSearch.SearchDataResult<OrderField.ParcelCollectionPoint> {
      const searchModel = new Model();
      searchModel.name = data.name;
      searchModel.code = data.code;
      searchModel.city = data.city;
      searchModel.deliveryMethod = data.deliveryMethod;
      searchModel.coordinatesMissing = data.coordinatesMissing;
      const searchData: SearchData = {
        queryModel: OrderFieldModel.create<OrderField.ParcelCollectionPoint>(OrderFunctions.VALUES, data.order, data.queryModel),
        searchModel: searchModel,
      };
      return {
        searchData: searchData
      };
    }

    protected toRaw(data: SearchData): StoredSearchRawData {
      return {
        version: this.VERSION,
        queryModel: data.queryModel,
        order: data.queryModel.createOrder(OrderFunctions.VALUES),
        name: data.searchModel.name,
        code: data.searchModel.code,
        city: data.searchModel.city,
        deliveryMethod: data.searchModel.deliveryMethod,
        coordinatesMissing: data.searchModel.coordinatesMissing,
      };
    }

  }

}
