import { Injectable } from '@angular/core';
import { MultiselectProvider } from '../../multiselect-provider';
import { Observable, Observer } from 'rxjs';
import { MultiselectOptionItem, UiConstants } from '../../../util/core-utils';
import { OrderType, QueryResult } from '../../util/services';
import { InvoiceBook, InvoiceBookService } from './invoice-book.service';
import { Set } from 'immutable';
import { Query } from '../../query/field';

@Injectable()
export class InvoiceBookMultiselectProvider implements MultiselectProvider {

  private multiselectQueryRequest: InvoiceBook.QueryRequest = {
    orders: Set.of({type: OrderType.ASC, field: InvoiceBook.OrderField.PREFIX}),
    paging: {
      pageNumber: 1,
      numberOfItems: UiConstants.autocompletePageSize,
    },
    noProgressBar: true,
  };

  constructor(private invoiceBookService: InvoiceBookService) {
  }

  load(queryRequest: InvoiceBook.QueryRequest): Observable<InvoiceBookMultiselectOptionItem[]> {
    return Observable.create((observer: Observer<InvoiceBookMultiselectOptionItem[]>) => {
      this.invoiceBookService.query(queryRequest).subscribe((result: QueryResult<InvoiceBook.InvoiceBook>) => {
          observer.next(result.items.map(item => this.toMultiselectOptionItem(item!)).toArray());
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  loadAll(predicate?: string): Observable<InvoiceBookMultiselectOptionItem[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.queryText = predicate;
    return this.load(queryRequest);
  }

  loadActive(predicate?: string): Observable<InvoiceBookMultiselectOptionItem[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.disabled = false;
    queryRequest.queryText = predicate;
    return this.load(queryRequest);
  }

  loadActiveWithSettings(settingsId: number, predicate?: string) {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.disabled = false;
    queryRequest.queryText = predicate;
    queryRequest.invoiceSettingsIds = Set.of(settingsId);
    return this.load(queryRequest);
  }

  getById(id: number): Observable<InvoiceBookMultiselectOptionItem> {
    return Observable.create((observer: Observer<InvoiceBookMultiselectOptionItem>) => {
      this.invoiceBookService.get({
        id: id
      }).subscribe((result: InvoiceBook.InvoiceBook) => {
          observer.next(this.toMultiselectOptionItem(result!));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  getByIds(ids: number[]): Observable<InvoiceBookMultiselectOptionItem[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    const filters: Query.Criteria[] = [];
    queryRequest.invoiceBookIdSet = Set.of(...ids);
    return this.load(queryRequest);
  }

  toMultiselectOptionItem(item: InvoiceBook.InvoiceBook): InvoiceBookMultiselectOptionItem {
    return InvoiceBookMultiselectProvider.toPublic(item);
  }

  static toPublic(item: InvoiceBook.InvoiceBook): InvoiceBookMultiselectOptionItem {
    const ret = new InvoiceBookMultiselectOptionItem();
    ret.id = item.id;
    ret.itemName = item.prefix;
    ret.disabled = item.disabled;
    ret.minNum = item.startNumber;
    ret.maxNum = item.nextInvoiceNumber === item.startNumber ? item.nextInvoiceNumber : item.nextInvoiceNumber - 1;
    return ret;
  }
}

export class InvoiceBookMultiselectOptionItem extends MultiselectOptionItem<number> {
  minNum: number;
  maxNum: number;
}
