<div [formGroup]="formGroup" [ngClass]="{'mt-0 mb-0': readonly}">
  <ng-container *ngIf="!readonly">
    <div class="row">
      <div class="form-group col-sm-8" [ngClass]="{ 'has-danger': hasLocalFieldError('value') }">
        <div class="input-group">
          <input type="text" class="form-control"
                 formControlName="value"
                 [ngClass]="{ 'form-control-danger': hasLocalFieldError('value') }"
                 [readonly]="readonly"
                 [(ngModel)]="m.value"
                 trim="blur" validateOnBlur [validateFormControl]="formGroup.controls['value']"
                 placeholder="{{ 'COMMON_PLACEHOLDER_PHONE_VALUE' | translate }}">
          <span class="input-group-addon-gray icomoon icomoon-phone" id="basic-addon2"></span>
        </div>
        <div class="form-control-feedback" *ngIf="hasLocalFieldError('value')">
        <span *ngIf="hasLocalFieldError('value', 'required')">
          {{ 'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate }}
        </span>
          <span *ngIf="hasLocalFieldError('value', 'validatePhoneNumber')">
          {{ 'COMMON_VALIDATION_MESSAGE_PHONE' | translate }}
        </span>
        </div>
      </div>
      <div class="form-group col-sm-4">
        <select type="text" class="form-control item-selected"
                [ngModelOptions]="{standalone: true}"
                [disabled]="readonly"
                [(ngModel)]="m.type">
          <option *ngFor="let type of m.types" [ngValue]="type">{{ getTypeDictionaryCode(type) | translate }}</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="form-group" [ngClass]="(deletable && !readonly) ? 'col-sm-11' : 'col-sm-12'">
        <input type="text" class="form-control"
               maxlength="{{UiConstants.maximumVarcharLength}}"
               [readonly]="readonly"
               [(ngModel)]="m.description"
               [ngModelOptions]="{standalone: true}"
               placeholder="{{ 'COMMON_NOTE' | translate }}">
      </div>
      <div class="col-sm-1 form-group d-flex justify-content-center align-items-center" *ngIf="(deletable && !readonly)">
        <i class="icomoon icomoon-trash cursor-pointer" (click)="emitDeleteEvent()"
           title="{{ 'COMMON_BUTTON_DELETE' | translate }}"></i>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="readonly">

    <!--phone number start-->
    <div class="row">
      <div class="form-group col-md-4 mb-0">
        <label readonly class="detail-title col-form-label">{{'COMMON_CONTACT_PERSON_PHONE_NUMBER'|translate}}</label>
      </div>
      <div class="form-group col-md-8 mb-0">
        <label readonly class="detail-description form-control">{{ m.value }}</label>
        <label readonly class="detail-small-label">{{ getTypeDictionaryCode(m.type) | translate }}</label>
      </div>
    </div>
  <!--phone number end-->

  <!--comment start-->
  <div class="row" *ngIf="!(m.description === '')">
    <div class="form-group col-md-4 mb-0">
      <label readonly class="detail-title col-form-label">{{'COMMON_NOTE'|translate}}</label>
    </div>
    <div class="form-group col-md-8 mb-0">
      <label readonly class="detail-description form-control">{{ m.description }}</label>
    </div>
  </div>
  <!--comment end-->



  </ng-container>
</div>
