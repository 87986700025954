/* eslint-disable */
import { Injectable } from '@angular/core';
import { EmptyMessage } from './util/messages';
import { ApplicationType } from './root-core.service';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from './util/http-services';
import { Observable } from 'rxjs';
/* eslint-enable */

export interface LanguageRequest {
  applicationTypeKey: string;
}

export interface StringKeyRequest {
  applicationTypeKey: string;
}

export interface DictionaryRequest {
  languageCode: string;
  applicationTypeKey: string;
}

export interface SetStringRequest {
  languageCode: string;
  applicationTypeKey: string;
  key: string;
  value: string;
  note: string;
}

export interface StringItem {
  key: string;
  value: string;
  note: string;
}

@Injectable()
export class ApplicationDictionaryService extends BaseHttpService {

  getLanguageCodes(request: LanguageRequest): Observable<string[]> {
    return this.http.get<string[]>(this.url + `language-codes`, this.parseParams(request));
  }

  getApplicationTypes(request: EmptyMessage): Observable<ApplicationType[]> {
    return this.http.get<ApplicationType[]>(this.url + `application-types`, this.parseParams(request));
  }

  getStringKeys(request: StringKeyRequest): Observable<string[]> {
    return this.http.get<string[]>(this.url + `string-keys`, this.parseParams(request));
  }

  getDictionary(request: DictionaryRequest): Observable<StringItem[]> {
    return this.http.get<StringItem[]>(this.url + `strings`, this.parseParams(request));
  }

  setString(request: SetStringRequest): Observable<EmptyMessage> {
    return this.http.post<EmptyMessage>(
      this.url + `?languageCode=${request.languageCode}&applicationTypeKey=${request.applicationTypeKey}`, request
    );
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/app-dictionary/');
  }

}
