import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {Set} from 'immutable';
import {UiConstants} from "../../../../util/core-utils";
import {FormBuilder, FormGroup, NgForm, Validators} from "@angular/forms";
import {Angular2Multiselects} from "../../../../util/multiselect";
import {ForwardingNgFormRef, LocalFormGroupValidationErrors} from "../../../../lib/util/services";
import {TranslateService} from "@ngx-translate/core";
import {TaskRecordService} from "../../../../lib/task/task-record.service";
import {NgbDatePickerParserFormatter} from "../../../../util/ngb-datepicker";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {DownloadedFile} from "../../../../lib/util/downloaded-files";
import {saveAs} from 'file-saver';
import {InvoicePaymentTypeMultiItem} from "../../../invoicing/invoices/invoice-create/invoice-create-clone.model";
import {Dates} from "../../../../lib/util/dates";

@Component({
  selector: 'app-task-record-sup-export-dialog',
  templateUrl: './task-record-sup-export-dialog.component.html',
  styleUrls: ['./task-record-sup-export-dialog.component.scss']
})
export class TaskRecordSupExportDialogComponent implements OnInit {

  UiConstants = UiConstants;

  @ViewChild('f') form: NgForm;

  formGroup: FormGroup;

  offlineDropdownSettings: Angular2Multiselects.Settings;

  private formGroupValidationErrors: LocalFormGroupValidationErrors;

  paymentTypes: InvoicePaymentTypeMultiItem[] = [];

  model: TaskRecordSupExportModel = new TaskRecordSupExportModel();

  exportInProgress: boolean = false;

  constructor(private translateService: TranslateService,
              private formBuilder: FormBuilder,
              private taskRecordService: TaskRecordService,
              private datePickerParserFormatter: NgbDatePickerParserFormatter,
              public dialogRef: MatDialogRef<TaskRecordSupExportDialogComponent, TaskRecordSupExportDialogResult>,
              @Inject(MAT_DIALOG_DATA) public data: TaskRecordSupExportDialogData
  ) {
    this.formGroup = this.createFormGroup(formBuilder);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(
      this.createForwardingHtmlForm(),
      this.formGroup
    );
    this.offlineDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(false)
      .searchBy(['itemName'])
      .autoPosition(true)
      .translate(true)
      .build();

    this.model.deliveryDate = this.datePickerParserFormatter.fromLocalDate(Dates.today())!;
    this.model.issueDate = this.datePickerParserFormatter.fromLocalDate(Dates.today())!;
  }

  ngOnInit(): void {
    this.initPaymentTypes();
  }

  private initPaymentTypes() {
    InvoicePaymentTypeMultiItem.loadItems().subscribe(paymentTypes => {
      this.paymentTypes.push(...paymentTypes);
      this.model.paymentType = [];
      const transfer = this.paymentTypes.find(pt => pt.id === 'TRANSFER');
      if (transfer) {
        this.model.paymentType.push(transfer);
      }
    });
  }

  public static openDialog(
    dialog: MatDialog,
    data: TaskRecordSupExportDialogData,
    resultCallback: (result?: TaskRecordSupExportDialogResult) => void) {
    if (data.taskRecordIds.size === 0) {
      return;
    }
    const dialogRef = dialog.open(TaskRecordSupExportDialogComponent, {
      closeOnNavigation: true,
      data: data,
      height: 'auto',
      panelClass: 'custom-dialog-container'
    });
    dialogRef.afterClosed().subscribe((result: TaskRecordSupExportDialogResult) => {
      resultCallback(result);
    });
  }

  closeDialog(success: boolean) {
    this.dialogRef.close({success: success});
  }

  private createForwardingHtmlForm() {
    return new ForwardingNgFormRef({
      formFn: () => {
        return this.form;
      }
    });
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  private createFormGroup(fb: FormBuilder): FormGroup {
    return fb.group({
      paymentType: [
        [],
        Validators.required
      ],
      journalCode: [
        [],
        Validators.required
      ],
      issueDatePicker: [
        [],
        Validators.required
      ],
      deliveryDatePicker: [
        [],
        Validators.required
      ],
      deadlineOffset: [
        [],
        Validators.required
      ],
    });
  }

  generateXml() {
    if (this.hasLocalFieldError()) {
      return;
    }
    this.exportInProgress = true;
    this.taskRecordService.exportSupCreate({
      taskRecordIds: this.data.taskRecordIds,
      paymentType: this.model.paymentType[0]!.id,
      journalCode: this.model.journalCode,
      deadlineOffset: this.model.deadlineOffset,
      deliveryDate: this.datePickerParserFormatter.toLocalDate(this.model.deliveryDate)!,
      issueDate: this.datePickerParserFormatter.toLocalDate(this.model.issueDate)!
    }).subscribe((res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName('task_record_sup_create.xml'));
        this.closeDialog(true);
        this.exportInProgress = false;
      },
      error => {
        this.exportInProgress = false;
      }
    );
  }

}

export class TaskRecordSupExportDialogData {
  taskRecordIds: Set<number>;
}

export class TaskRecordSupExportDialogResult {
  success: boolean;
}

class TaskRecordSupExportModel {
  paymentType: InvoicePaymentTypeMultiItem[] = [];
  journalCode: string = '401';
  deadlineOffset: number = 0;
  issueDate: NgbDateStruct | null = null;
  deliveryDate: NgbDateStruct | null = null;
}
