/* eslint-disable */
import { ComponentFactoryResolver, Directive, ViewContainerRef, } from '@angular/core';
/* eslint-enable */

/* eslint-disable */
@Directive({
  selector: '[app-form-record-field-wrapper-holder]',
})
/* eslint-enable */
export class FormRecordFieldWrapperHolderDirective {

  constructor(public viewContainerRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
  }

}
