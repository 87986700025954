/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { Order, PagingRequest, QueryResult, ResourceQueryResult, Services } from '../util/services';
import { DownloadedFile } from '../util/downloaded-files';
import { List, Map, Set } from 'immutable';
import { TableDocumentResource, TableDocumentResourceService } from './table-document-resource.service';
import { OffsetDateTime } from '../util/dates';
import { EmptyMessage } from '../util/messages';
/* eslint-enable */

@Injectable()
export class TableDocumentService implements TableDocument.Service {

  constructor(private resourceService: TableDocumentResourceService) {}

  query(request: TableDocument.QueryRequest): Observable<QueryResult<TableDocument.TableDocument>> {
    return Observable.create((observer: Observer<QueryResult<TableDocument.TableDocument>>) => {
      const resourceRequest: TableDocumentResource.QueryRequest = {
        order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
        name: request.name,
        code: request.code,
        schema_name: request.schemaName,
        schema_code: request.schemaCode,
        schema_version: request.schemaVersion,
        schema_id: request.schemaId
      };
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<TableDocumentResource.TableDocument>) => {
          observer.next({
            items: this.toPublicList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  get(request: TableDocument.GetRequest): Observable<TableDocument.TableDocument> {
    return Observable.create((observer: Observer<TableDocument.TableDocument>) => {
      const resourceRequest: TableDocumentResource.GetRequest = {
        schema_id: request.schemaId,
        document_id: request.documentId
      };
      return this.resourceService.get(resourceRequest).subscribe(
        (result: TableDocumentResource.TableDocument) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  create(request: TableDocument.CreateRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: TableDocumentResource.CreateRequest = {
        schema_id: request.schemaId,
        code: request.code,
        name: request.name,
        note: request.note,
      };
      return this.resourceService.create(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  update(request: TableDocument.UpdateRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: TableDocumentResource.UpdateRequest = {
        schema_id: request.schemaId,
        document_id: request.documentId,
        name: request.name,
        note: request.note,
      };
      return this.resourceService.update(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  delete(request: TableDocument.DeleteRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: TableDocumentResource.DeleteRequest = {
        schema_id: request.schemaId,
        document_id: request.documentId
      };
      return this.resourceService.delete(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  export(request: TableDocument.ExportRequest): Observable<DownloadedFile> {
    return this.resourceService.export({
      schema_id: request.schemaId,
      document_id: request.documentId,
    });
  }

  private toPublicList(resourceList: TableDocumentResource.TableDocument[]): List<TableDocument.TableDocument> {
    return List.of(...resourceList.map((r) => this.toPublic(r)));
  }

  private toPublic(r: TableDocumentResource.TableDocument): TableDocument.TableDocument {
    return {
      documentId: r.document_id,
      creationTime: Services.toOffsetDateTime(r.creation_time),
      contentUpdateTime: Services.toOffsetDateTime(r.content_update_time),
      code: r.code,
      name: r.name,
      note: r.note,
      exportable: r.exportable,
      schema: this.toPublicSchema(r.schema)
    };
  }

  private toPublicSchema(r: TableDocumentResource.TableDocumentSchema): TableDocument.TableDocumentSchema {
    return {
      schemaId: r.schema_id,
      code: r.code,
      name: r.name,
      version: r.version,
      versionState: r.version_state
    };
  }
}

export namespace TableDocument {

  export interface Service {

    // <editor-fold desc="CRUD">

    query(request: QueryRequest): Observable<QueryResult<TableDocument>>;

    get(request: GetRequest): Observable<EmptyMessage>;

    create(request: CreateRequest): Observable<EmptyMessage>;

    update(request: UpdateRequest): Observable<EmptyMessage>;

    delete(request: DeleteRequest): Observable<EmptyMessage>;

    // export(request: ExportRequest): Observable<ImportDocument>;

    // </editor-fold>

  }

  export interface TableDocument {
    documentId: number;
    creationTime?: OffsetDateTime;
    contentUpdateTime?: OffsetDateTime;
    code: string;
    name: string;
    note: string;
    exportable: boolean;
    schema: TableDocumentSchema
  }

  export interface TableDocumentSchema {
    schemaId: number;
    version: number;
    versionState: string;
    code: string;
    name: string;
  }

  export interface QueryRequest {
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
    name?: string;
    code?: string;
    schemaName?: string;
    schemaCode?: string;
    schemaVersion?: number;
    schemaId?: number;
  }

  export interface GetRequest {
    schemaId: number;
    documentId: number;
  }

  export interface CreateRequest {
    schemaId: number;
    code: string;
    name: string;
    note: string;
  }

  export interface UpdateRequest {
    schemaId: number;
    documentId: number;
    name: string;
    note: string;
  }

  export interface DeleteRequest {
    schemaId: number;
    documentId: number;
  }

  export interface ImportDocument {
    version: string;
    descriptor: string;
  }

  export interface ExportRequest {
    schemaId: number;
    documentId: number;
  }

  export enum OrderField {
    ID,
    CODE,
    NAME
  }

  export enum ValidatedField {
    UNKNOWN,

    CODE,
    NAME,
  }
}

class Keys {

  private static readonly SCHEMA_ID = 'document_id';
  private static readonly CODE = 'code';
  private static readonly NAME = 'name';

  private static readonly orderFieldKeyMap: Map<TableDocument.OrderField, string> = Map.of(
    TableDocument.OrderField.ID, Keys.SCHEMA_ID,
    TableDocument.OrderField.CODE, Keys.CODE,
    TableDocument.OrderField.NAME, Keys.NAME,
  );

  private static readonly keyValidatedFieldMap: Map<string, TableDocument.ValidatedField> = Map.of(
    TableDocument.ValidatedField.CODE, Keys.CODE,
    TableDocument.ValidatedField.NAME, Keys.NAME,
  );

  public static toOrderFieldKey(field: TableDocument.OrderField): string {
    return Keys.orderFieldKeyMap.get(field)!;
  }

  public static toValidatedField(fieldKey: string): TableDocument.ValidatedField {
    return Keys.keyValidatedFieldMap.get(fieldKey, TableDocument.ValidatedField.UNKNOWN);
  }

}
