<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div class="breadcrumb-menu d-flex negative-margin-right">
    <div class="breadcrumb-button-icon-container cursor-pointer"
         (click)="openWorkflowCloneDialog()" *ngIf="rightModel.workflowCreate.hasRight()">
      <a class="btn-setting d-flex align-items-center" title="{{'COMMON_BUTTON_CLONE' | translate}}">
        <i class="icomoon icomoon-clone"></i>
        <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_CLONE' | translate}}</span>
      </a>
    </div>
    <div class="breadcrumb-button-icon-container cursor-pointer"
         *ngIf="!isDraft()"
         (click)="exportWorkflow()">
      <a class="btn-setting d-flex align-items-center" title="{{'COMMON_BUTTON_EXPORT' | translate}}">
        <i class="icomoon icomoon-csv-export"></i>
        <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_EXPORT' | translate}}</span>
      </a>
    </div>
    <div class="breadcrumb-button-icon-container cursor-pointer"
         (click)="editWorkflow()" *ngIf="isReadonly() && model.rights.update.hasRight() && isDraft()">
      <a class="btn-setting d-flex align-items-center" title="{{'COMMON_BUTTON_EDIT' | translate}}">
        <i class="icomoon icomoon-modify"></i>
        <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_EDIT' | translate}}</span>
      </a>
    </div>
    <div class="breadcrumb-button-icon-container cursor-pointer"
         (click)="editNewVersion()" *ngIf="model.rights.createNewVersion.hasRight() && !isDraft() && !isInactive()">
      <a class="btn-setting d-flex align-items-center" title="{{'WORKFLOW_EDIT_NEW_VERSION' | translate}}">
        <i class="icomoon icomoon-edit-new-version"></i>
        <span class="breadcrumb-button-text-visible">{{'WORKFLOW_EDIT_NEW_VERSION' | translate}}</span>
      </a>
    </div>
    <div class="breadcrumb-button-icon-container cursor-pointer"
         (click)="inactivateWorkflow()" *ngIf="model.rights.activate.hasRight() && !isDraft() && !isInactive()">
      <a class="btn-setting d-flex align-items-center" title="{{'COMMON_BUTTON_DISABLE' | translate}}">
        <i class="icomoon icomoon-disable"></i>
        <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_DISABLE' | translate}}</span>
      </a>
    </div>
    <div class="breadcrumb-button-icon-container cursor-pointer"
         (click)="activateWorkflow()" *ngIf="model.rights.activate.hasRight() && isInactive()">
      <a class="btn-setting d-flex align-items-center" title="{{'COMMON_BUTTON_ENABLE' | translate}}">
        <i class="icomoon icomoon-enable"></i>
        <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_ENABLE' | translate}}</span>
      </a>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>

<div class="p-1 animated fadeIn" id="workflow-base-data">
  <div class="card" *ngIf="model.workflow">

    <div class="d-flex flex-row justify-content-between base-data-header">
      <div class="d-flex flex-row flex-wrap">
        <div class="d-flex flex-row px-1 py-half align-items-center">
          <app-type-icon class="pr-half" [icon]="model.getIcon()"></app-type-icon>
        {{model.workflow.name}}
      </div>
      <div class="d-flex flex-row px-1 py-third-quarter align-items-center cursor-pointer"
           popover="{{'WORKFLOW_DETAIL_UPDATE_TIME' | translate}}" triggers="mouseenter:mouseleave" container="body"
           placement="top">
        <i class="icomoon pr-half icomoon-date-time info-card-icon"></i>
        <div>{{model.workflow.updateTime.toUtcIsoString() |date:'short'}}</div>
      </div>
      <div class="d-flex flex-row px-1 py-third-quarter align-items-center cursor-pointer"
           popover="{{'WORKFLOW_DETAIL_LAST_MODIFIER' | translate}}" triggers="mouseenter:mouseleave" container="body"
           placement="top">
        <i class="icomoon pr-half icomoon-last-modifier-user info-card-icon"></i>
        <div>{{model.getLastModifierName()}}</div>
      </div>
      <div class="d-flex flex-row px-1 py-third-quarter align-items-center cursor-pointer"
           popover="{{'WORKFLOW_DETAIL_VERSION' | translate}}" triggers="mouseenter:mouseleave" container="body"
           placement="top">
        <i class="icomoon pr-half icomoon-version info-card-icon"></i>
        <div>v{{model.workflow.version}}</div>
      </div>
      <div class="d-flex flex-row px-1 py-third-quarter align-items-center cursor-pointer"
           popover="{{'WORKFLOW_DETAIL_VERSION_STATE' | translate}}" triggers="mouseenter:mouseleave" container="body"
           placement="top">
        <i class="icomoon pr-half icomoon-state-flag info-card-icon"></i>
        <div>{{model.versionStateKey | translate}}</div>
      </div>
      <div class="d-flex flex-row px-1 py-third-quarter align-items-center cursor-pointer"
           popover="{{'WORKFLOW_DETAIL_INHERITANCE_MODE' | translate}}" triggers="mouseenter:mouseleave"
           container="body" placement="top">
        <i class="icomoon pr-half icomoon-inheritance-mode info-card-icon"></i>
        <div>{{model.inheritanceModeKey | translate}}</div>
      </div>
    </div>
    <div class="d-flex flex-row flex-wrap">
      <div class="border-left d-flex flex-row px-1 py-third-quarter align-items-center cursor-pointer"
           (click)="onOpenBaseDataDetailButtonClicked()">
        <i class="icomoon icomoon-detail"></i>
      </div>
      <div *ngIf="!isReadonly() && model.rights.update.hasRight()" (click)="onModifyBaseDataButtonClicked()"
           class="border-left d-flex flex-row px-1 py-half align-items-center cursor-pointer">
        <i class="icomoon icomoon-modify"></i>
      </div>
    </div>
  </div>

</div><!-- /.card -->

<app-workflow-create-editor
  [readonly]="isReadonly()"
  [workflow]="model.workflow"
  [workflowId]="componentState.id!">
</app-workflow-create-editor>

</div>
<div *ngIf="isFooterEnabled()" class="d-flex justify-content-between app-footer fix-footer"
     [ngClass]="{'fix-footer-compact': (sidebarCompact | async) === false }">
  <button type="button" class="btn btn-primary-applion-green-gray" (click)="deleteWorkflow()" *ngIf="isDeletable()">
    <i class="icomoon icomoon-trash"></i>
    {{"WORKFLOW_VERSION_DELETE" | translate}}
  </button>
  <button type="button" class="btn btn-primary " *ngIf="isDraft()" (click)="finalizeWorkflow()">
    <i class="icomoon icomoon-transport-state-done"></i>
    {{"COMMON_BUTTON_FINALIZE" | translate}}
  </button>
</div>
<app-unsupported-device>
</app-unsupported-device>
