import { Injectable } from '@angular/core';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ServiceInfoResourceService extends BaseHttpService {

  private static readonly baseUrl = '/service-info/';

  get(): Observable<ServiceInfoResource.ServiceInfo> {
    return this.http.get<ServiceInfoResource.ServiceInfo>(this.url);
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, ServiceInfoResourceService.baseUrl);
  }

}

export namespace ServiceInfoResource {

  export interface ServiceInfo {
    base: string,
    app_version: string,
    revision: string,
    commit_year: string,
    commit_time: string,
    internal_api_version: string,
    external_api_version: string,
    license_api_version: string,
    license_name: string,
    supported_database: string,
    server_info: string,
    os_name: string,
    jre_version: string,
    hostname: string,
    timezone: string,
    android_app_info?: {
      version_name: string;
      version_code: string;
      public_install_page_url: string;
    }
  }

}
