/* eslint-disable */
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MultiselectOptionItem, QueryFieldModel, SelectUtils, UiConstants } from '../../../../util/core-utils';
import { AuthService } from '../../../../lib/auth.service';
import { DownloadedFile } from '../../../../lib/util/downloaded-files';
import { Set } from 'immutable';
import { Arrays } from '../../../../lib/util/arrays';
import {
  ListItemList,
  ListItemQuery,
  ListItemResourceService,
  RawListItem,
  RawListTypeItem,
} from '../../../../lib/list-item/list-item-resource.service';
import { SettingsService } from '../../../../lib/settings.service';
import { InputMask } from '../../../../util/input-masks';
import { TranslateService } from '@ngx-translate/core';
import { Strings } from '../../../../lib/util/strings';
import { ListItemSearchModel } from '../../../../util/list-item-utils';
import { RootCoreService } from '../../../../lib/root-core.service';
import { RightModel } from '../../../../app.rights';
import { RightResolver, RightService } from '../../../../lib/right.service';
import { FileUploadDialogComponent } from '../../../../shared/file-upload/dialog/file-upload-dialog.component';
import { BreadcrumbParent } from '../../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { StateName } from '../../../../app.state-names';
import { combineLatest, Observable } from 'rxjs';
import { ListItemSearch, ListItemSearchService } from '../../../../lib/list-item-search-service';
import { OrderType, ResourceQueryResult, Services } from '../../../../lib/util/services';
import { saveAs } from 'file-saver';
import { DisabledEnum, DisabledItem } from '../../../../util/search-utils';
import { DropdownItemType } from '../../../../shared/dropdown/dropdown-item/dropdown-item-type';
import { Angular2Multiselects } from '../../../../util/multiselect';
import { MatDialog } from '@angular/material/dialog';
import { ListItemCreateComponent } from '../list-item-create/list-item-create.component';
import { ListItemEditComponent } from '../list-item-edit/list-item-edit.component';
import * as $ from '../../../../../../node_modules/jquery/dist/jquery.js';

/* eslint-enable */

@Component({
  selector: 'app-list-item-list',
  templateUrl: 'list-item-list.component.html',
  styleUrls: ['list-item-list.component.scss']
})
export class ListItemListComponent implements OnInit, AfterViewInit, OnDestroy {
  ListItemList = ListItemList;
  UiConstants = UiConstants;
  DropdownItemType = DropdownItemType;
  SelectUtils = SelectUtils;

  @Input()
  typeId?: number;

  isComponentEmbedded: boolean;

  @ViewChild('searchIcon', {static: true}) searchIcon: ElementRef;

  queryModel: QueryFieldModel<ListItemList.OrderField> = new QueryFieldModel(ListItemList.OrderField.ID, OrderType.DESC);
  listItemList: {
    item: RawListItem,
    selected: boolean
  }[] = [];
  languageCodes: string[] = [];
  showSearch: boolean = false;
  InputMask = InputMask;
  searchModel: ListItemSearchModel = new ListItemSearchModel();
  disabledItems: DisabledItem[] = [];
  listItemTypes: MultiselectOptionItem<number>[] = [];
  rightModel: RightModel = RightModel.empty();
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  dropdownSettings: Angular2Multiselects.Settings;

  @ViewChild('importDialog', { static: true }) importDialog: FileUploadDialogComponent;
  uploadPath: string;

  ngOnInit() {
    this.translateService.get('MENU_NAVBAR_LIST_ITEM').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.initDropdown();
    this.initComponentEmbedded();
  }

  ngAfterViewInit(): void {
    this.loadRightModels();
    this.loadListItemTypes();
    if (!this.typeId) {
      this.loadSearch(() => {
        this.showSearch = !this.searchModel.isEmpty();
        this.initDisabledOptions(DisabledEnum.FALSE);
        this.loadList();
      });
    } else {
      this.initDisabledOptions(DisabledEnum.FALSE);
      this.loadList();
    }
  }

  initDropdown() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
  }

  private initComponentEmbedded() {
    this.isComponentEmbedded = !!this.typeId;
  }

  private loadSearch(completion: () => void) {
    const obs: Observable<SearchLoadResult> = combineLatest(
      this.listItemSearchService.getSearchData({}),
      (storedSearchData: ListItemSearch.SearchDataResult) => {
        const result: SearchLoadResult = {
          storedSearchData: storedSearchData
        };
        return result;
      }
    );
    obs.subscribe(
      (result: SearchLoadResult) => {
        this.postInitSearch(result.storedSearchData);
        completion();
      }
    );
  }

  private initDisabledOptions(initValue: DisabledEnum) {
    this.disabledItems = [];
    const disabledEnums: DisabledEnum[] = [DisabledEnum.NONE, DisabledEnum.FALSE, DisabledEnum.TRUE];
    Arrays.iterateByIndex(disabledEnums, (key) => {
      const item = new DisabledItem();
      item.id = key;
      this.translateService.get('COMMON_VALUE_DISABLED_ENUM_' + key).subscribe(
        (text: string) => {
          item.text = text;
        }
      );
      this.disabledItems.push(item);
      if (!this.searchModel.disabled) {
        if (key === initValue) {
          this.searchModel.disabled = item;
        }
      }
    });
  }

  private postInitSearch(storedSearchData: ListItemSearch.SearchDataResult) {
    this.queryModel.itemsPerPage = storedSearchData.searchData.itemsPerPage;
    this.queryModel.currentPage = storedSearchData.searchData.pageNumber;
    this.queryModel.setOrder(storedSearchData.searchData.order);
    this.searchModel.text = storedSearchData.searchData.text;
    this.searchModel.type = storedSearchData.searchData.type;
    this.searchModel.disabled = storedSearchData.searchData.disabled;
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  onSearchClicked() {
    this.loadList();
  }

  onSearchReset() {
    this.listItemSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.showSearch = true;
          this.loadList(1);
        });
      }
    );
  }

  private saveSearch() {
    const request = {
      searchData: {
        itemsPerPage: this.queryModel.itemsPerPage,
        pageNumber: this.queryModel.currentPage,
        order: this.queryModel.getOrder(),
        text: this.searchModel.text,
        type: this.searchModel.type,
        disabled: this.searchModel.disabled
      }
    };
    this.listItemSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  // todo
  // getParams(listItem: RawListItem): string {
  //   let paramResult = '';
  //
  //   if (listItem.params === undefined) {
  //     return paramResult;
  //   }
  //
  //   Arrays.iterateByIndex(listItem.params, param => {
  //     paramResult = paramResult + param.key + ': ' + param.value + '\n';
  //   });
  //   return paramResult;
  // }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: ListItemList.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  hasOperationForModule(module: any, operation: any): boolean {
    return true;
  }


  private loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    this.listItemService.getList(this.getQueryRequest(pageNumber))
      .subscribe(
      (result: ResourceQueryResult<RawListItem>) => {
        this.listItemList = result.items.map(i => ({item: i, selected: false}));
        this.queryModel.currentPage = requestedPage;
        this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
        this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
      }
    );
  }

  setDisabled(event: any, listItem: RawListItem, disabled: boolean) {
    this.listItemService.setDisabledItem({
      id: listItem.id,
      disabled: disabled
    }).subscribe(
      (result: RawListItem) => {
        this.loadList();
      },
      (error: any) => {
        this.loadList();
      }
    );
  }

  loadListItemTypes(q?: string) {
    this.listItemService.getTypeList({
      key: q,
      order: '+key',
      page_number: 1,
      number_of_items: UiConstants.autocompletePageSize,
      no_progress_bar: true
    }).subscribe((result: ResourceQueryResult<RawListTypeItem>) => {
      this.listItemTypes = result.items.map(t => ({id: t.id, itemName: t.key}))
    });
  }

  public onImportSuccess(succeeded: boolean) {
    if (succeeded) {
      this.loadList();
    }
  }

  exportXls() {
    const ids: number[] = this.listItemList.filter(item => item.selected).map(item => item.item.id);
    let request: ListItemQuery;
    if (ids.length > 0) {
      request = {
        id: ids.join()
      };
    }
    else {
      request = this.getQueryRequest();
    }
    this.listItemService.listItemExportXls(request).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName('list_item_export.xlsx'));
      }
    );
  }

  private getQueryRequest(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();
    const disabled: boolean | undefined = !this.searchModel.disabled ||
    this.searchModel.disabled.id === DisabledEnum.NONE ?
      undefined : this.searchModel.disabled.id === DisabledEnum.TRUE;

    return {
      type_disabled: false,
      type_text: undefined,
      type_id: !!this.typeId
        ? this.typeId
        : this.searchModel.type.length === 1
          ? this.searchModel.type[0].id
          : undefined,
      disabled: disabled,
      text: Strings.undefinedOrNonEmpty(this.searchModel.text),
      page_number: requestedPage,
      number_of_items: this.queryModel.itemsPerPage,
      order: Services.createOrderFieldParameter(ListItemList.Keys.toOrderFieldKey, Set.of(order))
    };
  }

  toggleEachListItem() {
    const eachListItemSelected = !this.eachListItemSelected;
    this.listItemList.forEach((item) => {
      item.selected = eachListItemSelected;
    });
  }

  get eachListItemSelected(): boolean {
    if (this.listItemList.length === 0) {
      return false;
    }
    let selected: boolean | undefined = true;
    this.listItemList.forEach((item) => {
      selected = selected && item.selected;
    });
    return selected;
  }

  showCreate() {
    const dialogRef = this.dialog.open(ListItemCreateComponent, {data: {typeId: this.typeId}, width: '80vw'});
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadList();
      }
    });
  }

  showEdit(id: number) {
    const dialogRef = this.dialog.open(ListItemEditComponent, {data: {id: id}, width: '80vw'});
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadList();
      }
    });
  }

  showImportDialog() {
    this.importDialog.toggleDialog();
    $('#uploadDialog').appendTo($('body'));
  }

  ngOnDestroy() {
    if (!this.typeId) {
      this.saveSearch();
    }
  }

  constructor(private translateService: TranslateService,
              private settingsService: SettingsService,
              private rootService: RootCoreService,
              private listItemService: ListItemResourceService,
              private listItemSearchService: ListItemSearchService,
              private rightService: RightService,
              private dialog: MatDialog,
              private authService: AuthService) {
    this.searchModel = new ListItemSearchModel();
    this.uploadPath = '/list-items/import-xls';
  }
}

interface SearchLoadResult {
  storedSearchData: ListItemSearch.SearchDataResult,
}
