/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { Order, PagingRequest, QueryResult, ResourceQueryResult, Services } from '../../util/services';
import { DownloadedFile } from '../../util/downloaded-files';
import { List, Map, Set } from 'immutable';
import { OffsetDateTime } from '../../util/dates';
import { TransportDocumentResource, TransportDocumentResourceService } from './transport-document-resource.service';
import TransportDocumentType = TransportDocument.TransportDocumentType;

/* eslint-enable */

@Injectable()
export class TransportDocumentService implements TransportDocument.Service {

  constructor(private resourceService: TransportDocumentResourceService) {
  }

  query(request: TransportDocument.QueryRequest): Observable<QueryResult<TransportDocument.TransportDocument>> {
    return Observable.create((observer: Observer<QueryResult<TransportDocument.TransportDocument>>) => {
      const resourceRequest: TransportDocumentResource.QueryRequest = {
        only_most_recent: request.onlyMostRecent,
        shipment_id: Services.createIdParameter(request.shipmentId),
        document_type: Services.createListParameter(request.documentType),
        transport_id: request.transportId,
        q: request.queryText,
        order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
      };
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<TransportDocumentResource.TransportDocument>) => {
          observer.next({
            items: this.toPublicList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  downloadDocument(request: TransportDocument.DownloadDocumentRequest): Observable<DownloadedFile> {
    return this.resourceService.downloadDocument({
      transport_id: request.transportId,
      id: request.id
    });
  }

  private toPublicList(resourceList: TransportDocumentResource.TransportDocument[]): List<TransportDocument.TransportDocument> {
    return List.of(...resourceList.map((r) => this.toPublic(r)));
  }

  private toPublic(r: TransportDocumentResource.TransportDocument): TransportDocument.TransportDocument {
    return {
      id: r.id,
      serial: r.serial,
      creationTime: Services.toOffsetDateTime(r.creation_time),
      name: r.name,
      documentType: <TransportDocumentType>r.document_type,
      version: r.version,
      contentHash: r.content_hash,
      shipment: this.toPublicShipmentData(r.shipment),
      transportId: r.transport_id
    };
  }

  private toPublicShipmentData(r?: TransportDocumentResource.ShipmentData): TransportDocument.ShipmentData | undefined {
    if (!r) {
      return undefined;
    }
    return {
      id: r.id,
      externalId: r.external_id,
      deliveryNoteNumber: r.delivery_note_number
    };
  }

}

export namespace TransportDocument {

  export interface Service {

    // <editor-fold desc="CRUD">

    query(request: QueryRequest): Observable<QueryResult<TransportDocument>>;

    downloadDocument(request: DownloadDocumentRequest): Observable<DownloadedFile>;

    // </editor-fold>

  }

  export interface TransportDocument {
    id: number;
    serial: string;
    version: number;
    name: string;
    documentType: TransportDocumentType;
    creationTime: OffsetDateTime;
    contentHash: string;
    shipment?: ShipmentData;
    transportId: number;
  }

  export interface ShipmentData {
    id: number;
    externalId: string;
    deliveryNoteNumber: string;
  }

  export interface QueryRequest {
    onlyMostRecent: boolean;
    shipmentId?: Set<number>;
    documentType?: Set<TransportDocumentType>;
    transportId: number;
    queryText?: string;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
  }

  export interface DownloadDocumentRequest {
    transportId: number;
    id: number;
  }

  export enum OrderField {
    CREATION_TIME,
    SHIPMENT_DELIVERY_NOTE,
    TYPE,
  }

  export type TransportDocumentType =
    'TRANSPORT_SUMMARY'
    | 'SHIPMENT_WAYBILL'
    | 'SHIPMENT_HANDOVER'
    | 'SHIPMENT_TAKEOVER'

  export class TransportDocumentTypeObject {
    type: TransportDocumentType;
    stringKey: string;
  }

  export const transportDocumentTypes: TransportDocumentTypeObject[] = [
    {type: 'TRANSPORT_SUMMARY', stringKey: 'TRANSPORT_DOCUMENT_TYPE_TRANSPORT_SUMMARY'},
    {type: 'SHIPMENT_WAYBILL', stringKey: 'TRANSPORT_DOCUMENT_TYPE_SHIPMENT_WAYBILL'},
    {type: 'SHIPMENT_HANDOVER', stringKey: 'TRANSPORT_DOCUMENT_TYPE_SHIPMENT_HANDOVER'},
    {type: 'SHIPMENT_TAKEOVER', stringKey: 'TRANSPORT_DOCUMENT_TYPE_SHIPMENT_TAKEOVER'}
  ];

}

class Keys {

  private static readonly CREATION_TIME = 'creation_time';
  private static readonly SHIPMENT_DELIVERY_NOTE = 'shipment_delivery_note_number';
  private static readonly TYPE = 'type';


  private static readonly orderFieldKeyMap: Map<TransportDocument.OrderField, string> = Map.of(
    TransportDocument.OrderField.CREATION_TIME, Keys.CREATION_TIME,
    TransportDocument.OrderField.SHIPMENT_DELIVERY_NOTE, Keys.SHIPMENT_DELIVERY_NOTE,
    TransportDocument.OrderField.TYPE, Keys.TYPE
  );

  public static toOrderFieldKey(field: TransportDocument.OrderField): string {
    return Keys.orderFieldKeyMap.get(field)!;
  }

}
