/* eslint-disable */
import { EmptyMessage, IdentityMessage, ListQuery } from '../util/messages';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Map as ImmutableMap } from 'immutable';
import { ResourceQueryResult } from '../util/services';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
/* eslint-enable */

export namespace DocumentHTML {

  export enum OrderField {
    ID,
    NAME,
    CODE,
    DOCUMENT_GROUP_NAME,
    UPDATE_TIME,
    CREATION_TIME
  }

  export class Keys {

    private static readonly ID = 'id';
    private static readonly NAME = 'name';
    private static readonly CODE = 'code';
    private static readonly DOCUMENT_GROUP_NAME = 'document_group_name';
    private static readonly UPDATE_TIME = 'update_time';
    private static readonly CREATION_TIME = 'creation_time';

    private static readonly orderFieldKeyMap: ImmutableMap<DocumentHTML.OrderField, string> = ImmutableMap.of(
      DocumentHTML.OrderField.ID, Keys.ID,
      DocumentHTML.OrderField.NAME, Keys.NAME,
      DocumentHTML.OrderField.CODE, Keys.CODE,
      DocumentHTML.OrderField.DOCUMENT_GROUP_NAME, Keys.DOCUMENT_GROUP_NAME,
      DocumentHTML.OrderField.UPDATE_TIME, Keys.UPDATE_TIME,
      DocumentHTML.OrderField.CREATION_TIME, Keys.CREATION_TIME,
    );

    public static toOrderFieldKey(field: DocumentHTML.OrderField): string {
      return Keys.orderFieldKeyMap.get(field)!;
    }

  }

}


export interface DocumentHTMLQuery extends ListQuery.Paging, ListQuery.Order, ListQuery.QueryText, ListQuery.Fields {
  name?: string;
  code?: string;
  value?: string;
  language_code?: string;
  document_group_id?: number;
  disabled?: boolean;
}

export interface CreateDocumentHTMLRequest {
  code: string;
  name: string,
  value: string;
  comment: string;
  document_group_id?: number;
  language_code?: string;
}

export interface UpdateDocumentHTMLRequest {
  id: number;
  name: string;
  code: string;
  value: string;
  comment: string;
  document_group_id?: number;
  language_code?: string;
}

export interface DocumentHTML {
  id: number;
  name: string;
  code: string;
  value: string;
  comment: string;
  document_group_id: number;
  language_code: string;
  creation_time: string;
  update_time: string;
  disabled: boolean;
}

export interface DisableRequest extends IdentityMessage {
  disabled: boolean;
}

@Injectable()
export class DocumentHTMLService extends BaseHttpService {

  query(request: DocumentHTMLQuery): Observable<ResourceQueryResult<DocumentHTML>> {
    return this.http.get<ResourceQueryResult<DocumentHTML>>(this.url, this.parseParams(request));
  }

  get(request: IdentityMessage): Observable<DocumentHTML> {
    return this.http.get<DocumentHTML>(this.url + `${request.id}`);
  }

  create(request: CreateDocumentHTMLRequest): Observable<EmptyMessage> {
    return this.http.post(this.url, request);
  }

  update(request: UpdateDocumentHTMLRequest): Observable<EmptyMessage> {
    return this.http.put(this.url + `${request.id}`, request);
  }

  setDisabled(request: DisableRequest): Observable<EmptyMessage> {
    return this.http.patch(this.url + `${request.id}/disabled`, request);
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/documents/htmls/');
  }
}
