export class Length {

  public static zero(unit?: string): Length {
    return new Length(unit ? unit : 'm', 0);
  }

  constructor(
    public readonly unit: string,
    public readonly value: number) {}

  public toString(precision?: number): string {
    return this.round(this.value).toString() + ' ' + this.unit;
  }

  public toMeters(): number {
    switch (this.unit) {
      case 'mm':
        return this.value / 1000;
      case 'cm':
        return this.value / 100;
      case 'm':
        return this.value;
      default:
        throw new Error('Invalid length unit: ' + this.unit);
    }
  }

  private round(value, precision?: number): number {
    const multiplier = Math.pow(10, precision ? precision : 2);
    return Math.round(value * multiplier) / multiplier;
  }

}

export class LengthFactory {

  public static createLengthFromMeters(lengthInMeters: number, unit?: string): Length {
    if (lengthInMeters <= 0) {
      return Length.zero(unit);
    }
    if (unit) {
      switch (unit) {
        case 'mm':
          return new Length(unit, lengthInMeters * 1000);
        case 'cm':
          return new Length(unit, lengthInMeters * 100);
        case 'm':
          return new Length(unit, lengthInMeters);
        default:
          throw new Error('Invalid length unit: ' + unit);
      }
    }
    if (lengthInMeters < 0.01) {
      return new Length('mm', lengthInMeters * 1000);
    }
    if (lengthInMeters < 1) {
      return new Length('cm', lengthInMeters * 100);
    }
    return new Length('m', lengthInMeters);
  }

  public static getOptions(): string[] {
    return [ 'mm', 'cm', 'm' ];
  }

}
