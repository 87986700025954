<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]=breadcrumbParents
      [self]="breadcrumbSelf">
    </app-breadcrumb>
    <app-masterdata-record-breadcrumb-buttons
      [allowClone]="masterDataRecordRightModel.create.hasRight()"
      [allowEdit]="masterDataRecordRightModel.update.hasRight()"
      [masterdataId]="masterDataId"
      [masterdataRecordId]="masterDataRecordId"
      [form]="f">
    </app-masterdata-record-breadcrumb-buttons>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding-half animated fadeIn">
  <responsive-tabset class="fullscreen_tab">
    <responsive-tab heading="{{'MASTER_DATA_RECORD_PANEL_HEADING_DETAIL'|translate}}">
      <form class="form-horizontal bordered-row" (ngSubmit)="nothing()" #f="ngForm" novalidate>
        <app-masterdata-record-base
          headingDictionaryKey="MASTERDATA_RECORD_PANEL_HEADING_DETAIL"
          [readonly]="true"
          [allowClone]="true"
          [form]="f"></app-masterdata-record-base>
        <app-form-record-container
          [readonly]="true"
          [cloning]="false"
          [form]="f"></app-form-record-container>
      </form>
    </responsive-tab>
    <responsive-tab heading="{{'COMMON_PANEL_HEADING_ATTACHMENT'|translate}}">
      <app-masterdata-record-attachment #attachmentView
                                        [masterDataId]="masterDataId"
                                        [masterDataRecordId]="masterDataRecordId"
                                        [readonly]="true">
      </app-masterdata-record-attachment>
    </responsive-tab>
    <responsive-tab heading="{{'INVOICE_CREATE_RELATED_TASKS'|translate}}"
                    *ngIf="rightModel.taskRecordRead.hasRight()">
      <app-new-task-record-list
        [masterDataRecordId]="masterDataRecordId"
        [_headerStringKey]="'INVOICE_CREATE_RELATED_TASKS'">
      </app-new-task-record-list>
    </responsive-tab>
  </responsive-tabset>
</div>
