<!-- stock record edit -->
<div class="modal-content">
  <div class="modal-header">
    <p class="modal-title"><strong>{{'FORM_RECORD_STOCK_INTAKE_HEADER' | translate}}</strong></p>
    <button type="button" class="close" (click)="closeDialog(false)" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <mat-dialog-content style="overflow:visible">
      <form class="form-horizontal bordered-row" (ngSubmit)="f.form.valid && closeDialog(true)" #f="ngForm" id="form"
            novalidate>
        <!--region IntakeDate-->
        <div class="form-group"
             [ngClass]="{ 'has-danger': !intakeDate.valid}">
          <div class="row">
            <label class="col-form-label form-control-label col-md-4 detail-title required-field-label">
              {{'STOCK_RECORD_LOT_INTAKE_DATE' | translate}}
            </label>
            <div class="col-md-8">

              <div class="input-group">
                <input placeholder="{{'DATE_TIME_PICKER_PLACEHOLDER_DATE' | translate}}" type="text"
                       id="intakeDate"
                       name="intakeDate"
                       #intakeDate="ngModel"
                       class="form-control"
                       [(ngModel)]="model.intakeDate"
                       [readonly]="readonly"
                       [ngClass]="{ 'has-danger': !intakeDate.valid }"
                       ngbDatepicker #date_picker="ngbDatepicker"
                       [required]="!readonly"
                       validateLocalDate>
                <span *ngIf="!readonly"
                      class="add-on input-group-addon-gray" (click)="date_picker.toggle()">
                          <i class="icomoon icomoon-calendar"></i>
                       </span>
              </div>
              <div class="form-control-feedback" *ngIf="f.submitted && !intakeDate.valid">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
          </div>
        </div>
        <!--endregion IntakeDate-->

        <div class="form-group row"
             [ngClass]="{ 'has-danger': (f.submitted && !shippingNumber.valid)}">
          <label
            class="col-md-4 col-form-label required-field-label">{{'FORM_RECORD_STOCK_INTAKE_HEADER_SHIPPING_NUMBER' | translate}}</label>
          <div class="col-md-8">
            <input type="text" class="form-control"
                   [ngClass]="{ 'has-danger': (f.submitted && !shippingNumber.valid)}"
                   [(ngModel)]="model.shippingNumber" #shippingNumber="ngModel"
                   placeholder="{{'FORM_RECORD_STOCK_INTAKE_HEADER_SHIPPING_NUMBER' | translate}}"
                   name="shippingNumber" id="shippingNumber"
                   [readonly]="readonly"
                   [required]="!readonly">
            <div class="form-control-feedback" *ngIf="f.submitted && !shippingNumber.valid">
              {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
            </div>
          </div>
        </div>

        <div class="form-group row"
             [ngClass]="{ 'has-danger': (f.submitted && !shippingCompany.valid)}">
          <label
            class="col-md-4 col-form-label">{{'FORM_RECORD_STOCK_INTAKE_HEADER_SHIPPING_COMPANY' | translate}}</label>
          <div class="col-md-8">
            <input type="text" class="form-control"
                   [ngClass]="{ 'has-danger': (f.submitted && !shippingCompany.valid)}"
                   [(ngModel)]="model.shippingCompany" #shippingCompany="ngModel"
                   placeholder="{{'FORM_RECORD_STOCK_INTAKE_HEADER_SHIPPING_COMPANY' | translate}}"
                   name="shippingCompany" id="shippingCompany"
                   [readonly]="readonly">
          </div>
        </div>

        <div class="form-group row"
             [ngClass]="{ 'has-danger': (f.submitted && !invoiceNumber.valid)}">
          <label
            class="col-md-4 col-form-label">{{'FORM_RECORD_STOCK_INTAKE_HEADER_INVOICE_NUMBER' | translate}}</label>
          <div class="col-md-8">
            <input type="text" class="form-control"
                   [ngClass]="{ 'has-danger': (f.submitted && !invoiceNumber.valid)}"
                   [(ngModel)]="model.invoiceNumber" #invoiceNumber="ngModel"
                   placeholder="{{'FORM_RECORD_STOCK_INTAKE_HEADER_INVOICE_NUMBER' | translate}}"
                   name="invoiceNumber" id="invoiceNumber"
                   [readonly]="readonly">
          </div>
        </div>

      </form>
    </mat-dialog-content>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="closeDialog(false)">
      {{'COMMON_BUTTON_CANCEL' | translate}}
    </button>
    <button type="submit" class="btn btn-primary" form="form" *ngIf="!readonly">
      {{'COMMON_BUTTON_SAVE' | translate}}
    </button>
  </div>
</div>
