import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  WorkflowTransitionFormFieldRuleModel,
  WorkflowTransitionRuleModel
} from '../workflow-create-editor-data-edit.component';
import { Workflow } from '../../../../../lib/workflow/workflow.service';
import { TranslateService } from '@ngx-translate/core';
import { MultiselectOptionItem, UiConstants } from '../../../../../util/core-utils';
import { FormServiceCode, FormServiceFactory } from '../../../../../lib/form/form-service-factory';
import { Form } from '../../../../../lib/form/form.service';
import { Angular2Multiselects } from '../../../../../util/multiselect';
import { ListItemResourceService } from '../../../../../lib/list-item/list-item-resource.service';
import { Strings } from '../../../../../lib/util/strings';
import { StringKey } from '../../../../../app.string-keys';
import { ToasterService } from '../../../../../fork/angular2-toaster/src/toaster.service';
import RuleType = Workflow.FormFieldRuleType;
import FieldDataTypeSelector = Form.FieldDataTypeSelector;

@Component({
  selector: 'app-form-field-rule-edit',
  templateUrl: './form-field-rule-edit.component.html',
  styleUrls: ['./form-field-rule-edit.component.scss']
})
export class FormFieldRuleEditComponent implements OnInit {

  RuleOperator = Workflow.RuleOperator;
  RuleType = Workflow.FormFieldRuleType;
  UiConstants = UiConstants;

  @Input()
  model: WorkflowTransitionFormFieldRuleModel;

  @Input()
  operatorRequired: boolean = true;

  @Input()
  create: boolean = false;

  @Input()
  taskIds: number[] = [];

  @Input()
  form: Form.Form;

  @Input()
  readonly: boolean = false;

  @Output()
  createSubmitted: EventEmitter<boolean> = new EventEmitter();

  @Output()
  deleteRule: EventEmitter<any> = new EventEmitter();

  formFields: FormFieldItem[] = [];
  usableFormFieldTypes: Form.FieldDataTypeSelector[] = [
    FieldDataTypeSelector.STRING,
    FieldDataTypeSelector.NUMBER,
    FieldDataTypeSelector.DECIMAL,
    FieldDataTypeSelector.BOOLEAN,
    FieldDataTypeSelector.LIST_ITEM,
    FieldDataTypeSelector.LIST_MULTI_ITEM
  ];
  commands: MultiselectOptionItem<Workflow.RuleCommand>[] = [];
  listItems: MultiselectOptionItem<number>[] = [];

  dropdownSettingsForFormField: Angular2Multiselects.Settings;
  dropdownSettingsForCommand: Angular2Multiselects.Settings;
  dropdownSettingsForListItem: Angular2Multiselects.Settings;

  private formService: Form.Service;

  constructor(
    private translateService: TranslateService,
    private formServiceFactory: FormServiceFactory,
    private listItemService: ListItemResourceService,
    private toasterService: ToasterService
  ) {
  }

  ngOnInit() {
    this.formService = this.formServiceFactory.createService(FormServiceCode.TASK);
    this.initDropDownSettings();
    this.loadFormFields(() => {
      if (this.model
        && this.model.getFormField()
        && [
          Form.FieldDataTypeSelector.LIST_ITEM,
          Form.FieldDataTypeSelector.LIST_MULTI_ITEM
        ].includes(this.model.getFormField()!.selector)) {
        this.loadListItems();
      }
    });
    this.setCommands();
  }

  private initDropDownSettings() {
    this.dropdownSettingsForFormField = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(false)
      .build();
    this.dropdownSettingsForCommand = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(false)
      .enableCheckAll(false)
      .remoteSearch(false)
      .translate(true)
      .build();
    this.dropdownSettingsForListItem = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .remoteSearch(true)
      .build();
  }

  private loadFormFields(completion: () => void) {
    if (this.form) {
      this.loadFieldsOfForm(this.form, completion);
    }
    else {
      this.taskIds.forEach(id => {
        this.formService.getForm({
          parentId: id
        }).subscribe((result: Form.Form) => {
          this.loadFieldsOfForm(result, completion);
        });
      });
    }
  }

  private loadFieldsOfForm(form: Form.Form, completion: () => void) {
    form.groups.toArray().filter(group => !group.disabled).forEach(group => {
      group.fields.toArray().filter(field => !field.disabled).forEach(field => {
        if (this.usableFormFieldTypes.includes(field.dataTypeSelector)) {
          this.formFields.push(this.parseField(field));
        }
      });
    });
    if (this.model.getFormField()) {
      const id = this.model.getFormField()!.id;
      const ff = this.formFields.find(ff => ff.id === id);
      if (ff) {
        this.model.formField = [ff];
      }
      else {
        form.groups.toArray().forEach(group => {
          group.fields.toArray().forEach(field => {
            if (field.fieldId === id) {
              const ffModel = this.parseField(field);
              ffModel.disabled = true;
              this.model.formField = [ffModel];
            }
          });
        });
      }
    }
    completion();
  }

  private parseField(field: Form.Field): FormFieldItem {
    return {
      id: field.fieldId,
      itemName: field.title,
      selector: field.dataTypeSelector,
      listItemTypeKey:
        field.dataTypeSelector === FieldDataTypeSelector.LIST_ITEM
          ? field.dataType.listItemAttributes!.listItemTypeKey
          : field.dataTypeSelector === FieldDataTypeSelector.LIST_MULTI_ITEM
          ? field.dataType.listMultiItemAttributes!.listItemTypeKey
          : undefined
    };
  }

  loadListItems(q?: string) {
    this.listItemService.getList({
      type_key: this.model.getFormField()!.listItemTypeKey,
      text: q ? Strings.undefinedOrNonEmpty(q) : undefined,
      disabled: false,
      page_number: 1,
      number_of_items: UiConstants.autocompletePageSize,
      no_progress_bar: true
    }).subscribe(result => {
      this.listItems = result.items.map(i => ({id: i.id, itemName: i.text}));
    });
  }

  getName(): string {
    let result: string = '';
    if (this.model.operator) {
      const operator = Workflow.ruleOperators.find(o => o.operator === this.model.operator)!;
      result = this.translateService.instant(operator.stringKey) + ' ';
    }
    result += this.model.getFormField() ? this.model.getFormField()!.itemName + ' ' : '';
    result += this.model.getCommand() ? this.translateService.instant(this.model.getCommand()!.itemName) : '';
    result += this.model.getCommand() && this.model.type && this.model.type !== RuleType.BOOLEAN ? ': ' : '';
    switch (this.model.type) {
      case Workflow.FormFieldRuleType.STRING:
        if (this.model.stringValue) {
          result += '"' + this.model.stringValue + '"';
        }
        break;
      case Workflow.FormFieldRuleType.NUMBER:
        if (this.model.numberValue) {
          result += this.model.numberValue;
        }
        break;
      case Workflow.FormFieldRuleType.LIST:
        result += this.model.listItemValue.map(i => i.itemName).join(', ');
    }
    return result;
  }

  setOperator(operator: Workflow.RuleOperator) {
    this.model.operator = operator;
  }

  onFormFieldChanged() {
    if (this.model.getFormField()) {
      switch (this.model.getFormField()!.selector) {
        case Form.FieldDataTypeSelector.STRING:
          this.model.type = RuleType.STRING;
          break;
        case Form.FieldDataTypeSelector.BOOLEAN:
          this.model.type = RuleType.BOOLEAN;
          break;
        case Form.FieldDataTypeSelector.NUMBER:
        case Form.FieldDataTypeSelector.DECIMAL:
          this.model.type = RuleType.NUMBER;
          break;
        case Form.FieldDataTypeSelector.LIST_ITEM:
        case Form.FieldDataTypeSelector.LIST_MULTI_ITEM:
          this.model.type = RuleType.LIST;
          this.loadListItems();
          break;
      }
    }
    else {
      this.model.type = undefined;
    }
    this.setCommands();
    this.resetValues();
  }

  setCommands() {
    const commands: Workflow.RuleCommand[] = [];
    switch (this.model.type) {
      case Workflow.FormFieldRuleType.STRING:
        commands.push(Workflow.RuleCommand.EQUALS);
        commands.push(Workflow.RuleCommand.NOT_EQUAL);
        break;
      case Workflow.FormFieldRuleType.BOOLEAN:
        commands.push(Workflow.RuleCommand.IS_TRUE);
        commands.push(Workflow.RuleCommand.IS_FALSE);
        break;
      case Workflow.FormFieldRuleType.NUMBER:
        commands.push(Workflow.RuleCommand.EQUALS);
        commands.push(Workflow.RuleCommand.NOT_EQUAL);
        commands.push(Workflow.RuleCommand.LESS_THAN);
        commands.push(Workflow.RuleCommand.LESS_OR_EQUAL_THAN);
        commands.push(Workflow.RuleCommand.GREATER_THAN);
        commands.push(Workflow.RuleCommand.GREATER_OR_EQUAL_THAN);
        break;
      case Workflow.FormFieldRuleType.LIST:
        commands.push(Workflow.RuleCommand.ALL);
        commands.push(Workflow.RuleCommand.ANY_OF);
        commands.push(Workflow.RuleCommand.NONE_OF);
        break;
    }
    const result: MultiselectOptionItem<Workflow.RuleCommand>[] = [];
    commands.forEach(c => {
      const item = Workflow.ruleCommands.find(i => i.command === c)!;
      result.push({
        id: item.command,
        itemName: item.stringKey
      });
    });
    this.commands = result;
  }

  private resetValues() {
    this.model.command = [];
    this.model.stringValue = undefined;
    this.model.numberValue = undefined;
    this.model.listItemValue = [];
  }

  save() {
    if (this.operatorRequired && !this.model.operator) {
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutLong,
        type: UiConstants.toastTypeError,
        title: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_TITLE),
        body: this.translateService.instant(StringKey.WORKFLOW_EDIT_TRANSITION_FORM_FIELD_RULE_OPERATOR_REQUIRED)
      });
      return;
    }
    this.model.name = this.getName();
    if (this.create) {
      this.createSubmitted.emit(true);
    }
    else {
      this.model.editing = false;
    }
  }

}

export interface FormFieldItem extends MultiselectOptionItem<number> {
  selector: Form.FieldDataTypeSelector;
  listItemTypeKey?: string;
}
