/* eslint-disable */
import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {UIRouter} from '@uirouter/angular';
import {
  ApplicationTypeItem,
  CompanyItem,
  OptionItem,
  RoleItem,
  UiConstants,
  UserGroupEditModel,
  UserItem,
} from '../../../util/core-utils';
import {UserGroupFieldErrorMap, UserGroupService} from '../../../lib/user-group.service';
import {ApplicationType, RootCoreService} from '../../../lib/root-core.service';
import {FieldError, FieldErrors, ObservableErrorResourceParser,} from '../../../lib/util/errors';
import {StateName} from '../../../app.state-names';
import {Arrays} from '../../../lib/util/arrays';
import {List, Set} from 'immutable';
import {User, UserService,} from '../../../lib/user.service';
import {Angular2Multiselects} from '../../../util/multiselect';
import {TranslateService} from '@ngx-translate/core';
import {StringKey} from '../../../app.string-keys';
import {BreadcrumbParent} from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import {Role, RoleService} from '../../../lib/role/role.service';
import {OrderType, QueryResult, ResourceQueryResult, Services} from '../../../lib/util/services';
import {Company, CompanyService} from '../../../lib/company/company.service';
import {RightResolver, RightService} from '../../../lib/right.service';
import {RightModel} from '../../../app.rights';
import {UserProfile} from '../../../lib/auth.service';
import {EmptyMessage, IdentityMessage} from '../../../lib/util/messages';
import {Strings} from '../../../lib/util/strings';
import {UserMeService} from '../../../lib/user/user-me.service';
import {NgForm, NgModel} from '@angular/forms';
import {ToasterService} from "../../../fork/angular2-toaster/src/toaster.service";

/* eslint-enable */

@Component({
  selector: 'app-usergroup-create',
  templateUrl: 'usergroup-create.component.html',
  styleUrls: ['usergroup-create.component.scss']
})
export class UserGroupCreateComponent implements OnInit, AfterViewInit {

  @ViewChild('f', {static: true})
  fForm: NgForm;

  @ViewChild('company', {static: true})
  companyInput: NgModel;

  model: UserGroupEditModel = new UserGroupEditModel();
  fieldErrors: UserGroupFieldErrorMap = {};
  roles: RoleItem[] = [];
  selectableRoles: RoleItem[] = [];
  companies: CompanyItem[] = [];
  applicationTypes: ApplicationTypeItem[] = [];
  userItems: UserItem[] = [];
  selectableUsers: UserItem[] = [];

  rightModel: RightModel = RightModel.empty();
  userProfile: UserProfile;
  users: List<User> = List.of<User>();

  dropdownSettings?: Angular2Multiselects.Settings;
  dropdownSettingsForRole?: Angular2Multiselects.Settings;
  dropdownSettingsForCompany?: Angular2Multiselects.Settings;
  dropdownSettingsForUser?: Angular2Multiselects.Settings;
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  currentUserCompanyIds: number[] = [];
  private _currentUserProtected: boolean = false;

  constructor(
    private translateService: TranslateService,
    private rootCoreService: RootCoreService,
    private userService: UserService,
    private userMeService: UserMeService,
    private userGroupService: UserGroupService,
    private uiRouter: UIRouter,
    private toasterService: ToasterService,
    private roleService: RoleService,
    private companyService: CompanyService,
    private rightService: RightService) {
  }

  ngOnInit(): void {
    this.translateService.get('USER_GROUP_PANEL_HEADING_CREATE').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_USER_GROUPS').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.USER_GROUP_LIST});
      }
    );
  }

  ngAfterViewInit(): void {
    this.loadRightModels(() => {
      this.loadModel();
    });
  }

  private initDropdown(enableCheckAllUser: boolean) {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .labelKey(OptionItem.KEY_TEXT)
      .enableCheckAll(true)
      .build();
    this.dropdownSettingsForRole = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .remoteSearch(true)
      .labelKey(OptionItem.KEY_TEXT)
      .enableCheckAll(true)
      .build();
    this.dropdownSettingsForCompany = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .remoteSearch(true)
      .labelKey(OptionItem.KEY_TEXT)
      .enableCheckAll(false)
      .build();
    this.dropdownSettingsForUser = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .remoteSearch(true)
      .labelKey(OptionItem.KEY_TEXT)
      .enableCheckAll(enableCheckAllUser)
      .build();
  }

  private loadModel() {
    this.loadRoles();
    this.loadCurrentUserCompanyIds(() => {
      this.loadCompanies();
    });
    this.loadScopes();
    this.loadUsers();
  }

  private loadRightModels(completion: () => void) {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        this.userProfile = resolver.userProfile!;
        this.userMeService.amIProtected().subscribe(p => {
          this._currentUserProtected = p;
          completion();
        });
      }
    );
  }

  loadRoles(q?: string) {
    this.roleService.query({
      name: q ? Strings.undefinedOrNonEmpty(q) : undefined,
      disabled: false,
      orders: Set.of({field: Role.OrderField.NAME, type: OrderType.ASC}),
      paging: {
        pageNumber: 1,
        numberOfItems: UiConstants.autocompletePageSize
      },
      noProgressBar: true
    }).subscribe(
      (result: QueryResult<Role.Role>) => {
        this.roles = [];
        if (q === undefined) {
          this.model.roles = [];
        }
        result.items.forEach((role: Role.Role) => {
          const item = new RoleItem();
          item.id = role.id;
          item.text = role.name;
          item.disabled = role.protectedRole && !this._currentUserProtected;
          if (!item.disabled) {
            this.roles.push(item);
          }
        });
        this.selectableRoles = this.roles;
        this.filterRoles();
      },
    );
  }

  private loadCurrentUserCompanyIds(completion: () => void) {
    this.userService.get({
      id: this.userProfile.id
    }).subscribe((user: User) => {
      this.currentUserCompanyIds = user.companies.map(c => c.id);
      completion();
    });
  }

  loadCompanies(searchValue?: string) {
    if (this.rightModel.userGroupCreateAll.hasRight()) {
      this.queryCompanies(searchValue);
    } else {
      if (this.currentUserCompanyIds.length === 0) {
        this.emptyModels(searchValue !== undefined);
      } else {
        this.queryCompanies(searchValue, this.currentUserCompanyIds, () => {
          if (this.currentUserCompanyIds.length === 1 && searchValue === undefined) {
            this.loadUserCompany(this.currentUserCompanyIds);
          }
        });
      }
    }
  }

  private queryCompanies(searchValue?: string, companyIds?: number[], completion?: () => void) {
    this.companyService.query({
      id: companyIds ? Set.of(...companyIds) : undefined,
      name: Strings.undefinedOrNonEmpty(searchValue),
      orders: Set.of({field: Company.OrderField.NAME, type: OrderType.ASC}),
      disabled: false,
      paging: {
        pageNumber: 1,
        numberOfItems: UiConstants.autocompletePageSize
      },
      noProgressBar: true
    }).subscribe((result: QueryResult<Company.Company>) => {
      this.emptyModels(searchValue !== undefined);
      result.items.forEach((company: Company.Company) => {
        const item = new CompanyItem();
        item.id = company.id;
        item.text = company.name;
        item.allowedRoleIds = company.allowedRoleIds!;
        item.disabled = company.disabled;
        this.companies.push(item);
      });
      if (completion) {
        completion();
      }
    });
  }

  private loadUserCompany(companyIds: number[]) {
    this.companyService.query({
      id: Set.of(...companyIds)
    }).subscribe((result: QueryResult<Company.Company>) => {
      result.items.forEach(company => {
        const item = new CompanyItem();
        item.id = company!.id;
        item.text = company!.name;
        item.allowedRoleIds = company!.allowedRoleIds!;
        item.disabled = company!.disabled;
        this.model.companies.push(item);
      });
      this.onCompanyChanged();
    });
  }

  private emptyModels(isSearching: boolean) {
    if (!isSearching) {
      this.model.companies = [];
    }
    this.companies = [];
  }

  private loadScopes() {
    this.rootCoreService.getApplicationTypes({})
      .subscribe(
        (applicationTypes: ApplicationType[]) => {
          this.applicationTypes = [];
          this.model.application_types = [];
          Arrays.iterateByIndex(applicationTypes, (scope) => {
            const item = new ApplicationTypeItem();
            item.id = scope.key;
            item.text = scope.name;
            this.applicationTypes.push(item);
          });
        },
        (error: any) => {
          // Ignored. The interceptor handles the global errors.
        }
      );
  }

  loadUsers(q?: string) {
    this.userService.query({
      q: q ? Strings.undefinedOrNonEmpty(q) : undefined,
      disabled: false,
      order: Services.createOrderFieldParameter(User.Keys.toOrderFieldKey,
        Set.of({field: User.OrderField.PERSON_NAME, type: OrderType.ASC})),
      page_number: 1,
      number_of_items: UiConstants.autocompletePageSize,
      no_progress_bar: true
    }).subscribe(
      (result: ResourceQueryResult<User>) => {
        this.userItems = [];
        if (q === undefined) {
          this.model.users = [];
        }
        this.selectableUsers = [];
        Arrays.iterateByIndex(result.items, (user) => {
          const item = this.toUserItem(user);
          if (!user.protected_user && user.type !== 'LDAP') {
            // Filter the protected user from the dropdown.
            this.userItems.push(item);
          }
        });
        this.users = List.of(...result.items);
        this.selectableUsers = this.userItems;
        this.initDropdown(true);
      }
    );
  }

  onUserSelect(event: UserItem) {
    const selectedUser = this.findUserById(event.id!);
    if (selectedUser !== null && selectedUser.protected_user) {
      // Protected user is added to the group, undo!
      const index = this.model.users.indexOf(event);
      this.model.users.splice(index, 1);
    }
  }

  onUserDeSelect(event: UserItem) {
    const selectedUser = this.findUserById(event.id!);
    if (selectedUser !== null && selectedUser.protected_user) {
      // Protected user is removed from the group, undo!
      this.model.users.push(event);
    }
  }

  onSelectAllUser(event: UserItem[]) {
    const ua: User[] = [];
    this.users.forEach((u) => {
      if (!u!.protected_user) {
        ua.push(u!);
      }
    });
    const items = ua.map((u) => {
      return this.toUserItem(u!);
    });
    this.model.users = items;
  }

  onDeSelectAllUser(event: UserItem[]) {
    // No filter needed.
  }

  onCompanyChanged() {
    this.filterRoles();
    this.filterUsers();
  }

  filterRoles() {
    if (this.model.companies.length > 0) {
      this.selectableRoles = [];
      const filteredRoles: RoleItem[] = [];
      Set.of(...this.model.companies.map(c => c.allowedRoleIds.toArray())
        .reduce((previousValue, currentValue) => previousValue.concat(currentValue), []))
        .forEach((roleId: number) => {
          this.roles.forEach((role: RoleItem) => {
            if (role.id === roleId) {
              this.selectableRoles.push(role);
            }
          });
          this.model.roles.forEach((selectedRole: RoleItem) => {
            if (roleId === selectedRole.id) {
              filteredRoles.push(selectedRole);
            }
          });
        });
      this.model.roles = filteredRoles;
    } else {
      this.selectableRoles = this.roles;
    }
  }

  filterUsers() {
    this.selectableUsers = this.userItems;
  }

  get companyIds(): number[] | undefined {
    if (this.model.companies.length > 0) {
      return this.model.companies.map(c => c.id!);
    }
    return undefined;
  }

  create() {
    this.companyInput.control.updateValueAndValidity();
    if (!this.fForm.valid || this.companyInput.control.invalid) {
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutLong,
        type: UiConstants.toastTypeError,
        title: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_TITLE),
        body: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_MESSAGE)
      });
      return;
    }
    this.userGroupService.create({
      name: this.model.name,
      external_id: this.model.externalId,
      roles: this.model.createRoleKeys(),
      application_types: this.model.createApplicationTypeKeys(),
      company_ids: this.companyIds
    }).subscribe(
      (createResult: IdentityMessage) => {
        this.userGroupService.updateUsers({
          id: createResult.id,
          user_ids: this.model.createUserIds()
        }).subscribe((userUpdateResult: EmptyMessage) => {
          this.uiRouter.stateService.go(StateName.USER_GROUP_LIST);
        });
      },
      (error: any) => {
        const res = ObservableErrorResourceParser.parseError(error);
        this.fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
      }
    );
  }

  back() {
    window.history.back();
  }

  removeFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.fieldErrors, fieldError);
  }

  getTextMaximumLength(): number {
    return UiConstants.maximumVarcharLength;
  }

  get selectedUsersText(): string {
    return this.selectedOptionsText(this.model.users);
  }

  get selectedApplicationTypesText(): string {
    return this.selectedOptionsText(this.model.application_types);
  }

  private selectedOptionsText<T>(items: OptionItem<T>[]): string {
    return items
      .map((o) => {
        return o.text;
      })
      .join(', ');
  }

  private findUserById(id: number): User | null {
    let user: User | null = null;
    this.users.forEach((u: User) => {
      if (u.id === id) {
        user = u;
        return false;
      }
    });
    return user;
  }

  private toUserItem(user: User) {
    const item = new UserItem();
    item.id = user.id;
    item.text = user.person_name + ' (' + user.user_name + ')';
    item.disabled = user.disabled;
    return item;
  }

}
