import { GrantedPermissionSet, GrantedPermissionSetResolver } from '../lib/right.service';
import { OperationRights } from '../app.right-definitions';

export class ProjectRightModel {

  public readonly projectRecordCreate: GrantedPermissionSet;
  public readonly projectRecordImport: GrantedPermissionSet;
  public readonly projectRecordExport: GrantedPermissionSet;

  constructor(resolver: GrantedPermissionSetResolver) {
    this.projectRecordCreate = resolver.of(OperationRights.PROJECT_RECORD_CREATE);
    this.projectRecordImport = resolver.of(OperationRights.PROJECT_RECORD_IMPORT);
    this.projectRecordExport = resolver.of(OperationRights.PROJECT_RECORD_EXPORT);
  }
}

export class ProjectRecordRightModel {

  public readonly projectRecordUpdate: GrantedPermissionSet;
  public readonly projectRecordDisable: GrantedPermissionSet;

  constructor(resolver: GrantedPermissionSetResolver) {
    this.projectRecordUpdate = resolver.of(OperationRights.PROJECT_RECORD_UPDATE);
    this.projectRecordDisable = resolver.of(OperationRights.PROJECT_RECORD_DISABLE);
  }
}
