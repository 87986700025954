/* eslint-disable */
import { Observable, Observer } from 'rxjs';
import { environment } from '../../environments/environment';
import { Order, OrderType, } from './util/services';
import { MemStorage } from './util/mem-storage';
import { Injectable } from '@angular/core';
import { MultiselectOptionItem, UiConstants } from '../util/core-utils';
import { StockItem, StockItemType } from './stock/stock-item.service';
import { DisabledItem } from '../util/search-utils';
import { RuntimeConfiguration } from './runtime-configuration';
import { LoggedInUserStorage } from './util/storages';
/* eslint-enable */

@Injectable()
export class StockItemSearchService {

  constructor() {}

  getSearchData(request: StockItemSearch.SearchDataGetRequest): Observable<StockItemSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<StockItemSearch.SearchDataResult>) => {
      try {
        observer.next(storage.read(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  setSearchData(request: StockItemSearch.SearchDataSetRequest): Observable<StockItemSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<StockItemSearch.SearchDataResult>) => {
      try {
        observer.next(storage.save(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  resetSearchData(request: StockItemSearch.SearchDataGetRequest): Observable<StockItemSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<StockItemSearch.SearchDataResult>) => {
      try {
        observer.next(storage.reset(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  private createStorage(): StockItemSearch.Storage {
    return new StockItemSearch.Storage();
  }
}

export namespace StockItemSearch {

  export interface SearchDataGetRequest {
  }

  export interface SearchDataSetRequest {
    searchData: SearchData;
  }

  export interface SearchDataResult {
    searchData: SearchData;
  }

  export interface SearchData {
    itemsPerPage: number;
    pageNumber: number;
    order: Order<StockItem.OrderField>;
    name: string | undefined;
    type?: StockItemType;
    externalId: string | undefined;
    productCode: string | undefined;
    companies: MultiselectOptionItem<number>[];
    ledgerNumbers: MultiselectOptionItem<number>[];
    createdOn: MultiselectOptionItem<string>[];
    unit: string | undefined;
    description: string | undefined;
    vtszNumber: string | undefined;
    unitPriceFrom: number | undefined;
    unitPriceTo: number | undefined;
    unitPriceCurrencyCode: string | undefined;
    unitPriceVatRate: number | undefined;
    productCategory: string | undefined;
    weightFrom: number | undefined;
    weightTo: number | undefined;
    place: string | undefined;
    serialCode: string | undefined;
    categories: MultiselectOptionItem<number>[];
    disabled: DisabledItem | null;
  }

  interface StoredSearchRawDataBase {
    version: number;
  }

  interface StoredSearchRawData extends StoredSearchRawDataBase {
    itemsPerPage: number;
    pageNumber: number;
    order: Order<StockItem.OrderField>;
    name: string | undefined;
    type?: StockItemType;
    externalId: string | undefined;
    productCode: string | undefined;
    companies: MultiselectOptionItem<number>[];
    ledgerNumbers: MultiselectOptionItem<number>[];
    createdOn: MultiselectOptionItem<string>[];
    unit: string | undefined;
    description: string | undefined;
    vtszNumber: string | undefined;
    unitPriceFrom: number | undefined;
    unitPriceTo: number |undefined;
    unitPriceCurrencyCode: string | undefined;
    unitPriceVatRate: number | undefined;
    productCategory: string | undefined;
    weightFrom: number | undefined;
    weightTo: number | undefined;
    place: string | undefined;
    serialCode: string | undefined;
    categories: MultiselectOptionItem<number>[];
    disabled: DisabledItem | null;
  }

  export class Storage {

    /**
     * Increment this version if you change the SearchData interface.
     */
    private static readonly VERSION: number = 10;

    private static readonly DEFAULT_SEARCH_DATA: SearchData = {
      itemsPerPage: UiConstants.pageNumbers[0],
      pageNumber: 1,
      order: {
        field: StockItem.OrderField.ID,
        type: OrderType.DESC
      },
      name: undefined,
      type: undefined,
      externalId: undefined,
      productCode: undefined,
      companies: [],
      ledgerNumbers: [],
      createdOn: [],
      unit: undefined,
      description: undefined,
      vtszNumber: undefined,
      unitPriceFrom: undefined,
      unitPriceTo: undefined,
      unitPriceCurrencyCode: undefined,
      unitPriceVatRate: undefined,
      productCategory: undefined,
      weightFrom: undefined,
      weightTo: undefined,
      place: undefined,
      serialCode: undefined,
      categories: [],
      disabled: DisabledItem.active()
    };

    constructor() {
    }

    public read(request: SearchDataGetRequest): SearchDataResult {
      let rawBase: StoredSearchRawDataBase;
      try {
        const key = this.generateKey();
        const json = MemStorage.getItem(key);
        if (!json) {
          return this.createDefaultResult();
        }
        rawBase = JSON.parse(json);
      }
      catch (error) {
        return this.createDefaultResult();
      }
      if (Storage.VERSION === rawBase.version) {
        const raw: StoredSearchRawData = <StoredSearchRawData> rawBase;
        return this.fromRaw(raw);
      }
      return this.createDefaultResult();
    }

    public reset(request: SearchDataGetRequest): SearchDataResult {
      return this.save({
        searchData: Storage.DEFAULT_SEARCH_DATA,
      });
    }

    public save(request: SearchDataSetRequest): SearchDataResult {
      const key = this.generateKey();
      const raw = this.toRaw(request.searchData);
      const json = JSON.stringify(raw);
      MemStorage.setItem(key, json);
      return this.read(request);
    }

    private generateKey(): string {
      return 'UserId(' + LoggedInUserStorage.getInstance().getUserId() + ')-Search-Stock-Item';
    }

    private createDefaultResult(): SearchDataResult {
      return {
        searchData: Storage.DEFAULT_SEARCH_DATA,
      };
    }

    private fromRaw(data: StoredSearchRawData): SearchDataResult {
      return {
        searchData: {
          itemsPerPage: data.itemsPerPage,
          pageNumber: data.pageNumber,
          order: data.order,
          name: data.name,
          type: data.type,
          externalId: data.externalId,
          productCode: data.productCode,
          companies: data.companies,
          ledgerNumbers: data.ledgerNumbers,
          createdOn: data.createdOn,
          unit: data.unit,
          description: data.description,
          vtszNumber: data.vtszNumber,
          unitPriceFrom: data.unitPriceFrom,
          unitPriceTo: data.unitPriceTo,
          unitPriceCurrencyCode: data.unitPriceCurrencyCode,
          unitPriceVatRate: data.unitPriceVatRate,
          productCategory: data.productCategory,
          weightFrom: data.weightFrom,
          weightTo: data.weightTo,
          place: data.place,
          serialCode: data.serialCode,
          categories: data.categories,
          disabled: data.disabled
        }
      };
    }

    private toRaw(data: SearchData): StoredSearchRawData {
      return {
        version: Storage.VERSION,
        itemsPerPage: data.itemsPerPage,
        pageNumber: data.pageNumber,
        order: data.order,
        name: data.name,
        type: data.type,
        externalId: data.externalId,
        productCode: data.productCode,
        companies: data.companies,
        ledgerNumbers: data.ledgerNumbers,
        createdOn: data.createdOn,
        unit: data.unit,
        description: data.description,
        vtszNumber: data.vtszNumber,
        unitPriceFrom: data.unitPriceFrom,
        unitPriceTo: data.unitPriceTo,
        unitPriceCurrencyCode: data.unitPriceCurrencyCode,
        unitPriceVatRate: data.unitPriceVatRate,
        productCategory: data.productCategory,
        weightFrom: data.weightFrom,
        weightTo: data.weightTo,
        place: data.place,
        serialCode: data.serialCode,
        categories: data.categories,
        disabled: data.disabled
      };
    }
  }
}
