<div class="card">

    <div class="card-header">
      {{'TRIGGER_TABLE_HEADER' | translate}}

      <div class="card-actions">
        <a *ngIf="!listVisible && expandable" class="btn-setting cursor-pointer" (click)="toggleListVisible()"
           title="{{'COMMON_BUTTON_COLLAPSE_SHOW' | translate}}"><i class="icomoon icomoon-dropdown"></i></a>
        <a *ngIf="listVisible && expandable" class="btn-setting cursor-pointer" (click)="toggleListVisible()"
           title="{{'COMMON_BUTTON_COLLAPSE_HIDE' | translate}}"><i class="icomoon icomoon-arrow-up"></i></a>
        <a *ngIf="triggerRightModel.triggerCreate.hasRight()" class="btn-setting cursor-pointer" (click)="onTriggerCreateClicked()"
           title="{{'COMMON_ADD' | translate}}"><i class="icomoon icomoon-add"></i></a>
      </div><!-- /.card-actions -->
    </div>

    <div class="card-body" *ngIf="listVisible || !expandable">

      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header w-10 hidden-sm-down">
            <app-table-field-sorter [orderField]="TriggerOrderField.CREATION_TIME"
                                    [orderType]="queryModel.getOrderType(TriggerOrderField.CREATION_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="TriggerOrderField.NAME"
                                    [orderType]="queryModel.getOrderType(TriggerOrderField.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-field-sorter [orderField]="TriggerOrderField.TYPE"
                                    [orderType]="queryModel.getOrderType(TriggerOrderField.TYPE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TYPE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op [text]="'COMMON_TABLE_HEADER_EVENT' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op [text]="'TRIGGER_RELATED_TEMPLATE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op [text]="'COMMON_TABLE_HEADER_ACTIONS' | translate"></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let trigger of triggerList| paginate: {
                      itemsPerPage: queryModel.itemsPerPage,
                      currentPage: queryModel.currentPage,
                      totalItems: queryModel.currentNumberOfItems }">
          <td class="hidden-sm-down">{{trigger.id}}</td>
          <td class="long">{{trigger.name}}</td>
          <td class="hidden-sm-down">{{trigger.typeStringKey | translate}}</td>
          <td class="hidden-sm-down">{{trigger.event | translate}}</td>
          <td class="long">{{trigger.templateName}}</td>
          <td>
            <a *ngIf="triggerRightModel.triggerCreate.hasRight()" (click)="onTriggerCloneClicked(trigger)">
              <i class="icomoon icons-table-item icomoon-clone icomoon-detail-table cursor-pointer"
                 title="{{'COMMON_BUTTON_CLONE' | translate}}"></i>
            </a>
            <a *ngIf="triggerRightModel.triggerRead.hasRight()"
               class="icons-table-group cursor-pointer"
               (click)="onTriggerDetailClicked(trigger)">
              <i class="icomoon icons-table-item icomoon-detail icomoon-detail-table"
                 title="{{'COMMON_BUTTON_DETAILS' | translate}}"></i>
            </a>
            <a *ngIf="triggerRightModel.triggerUpdate.hasRight()" (click)="onTriggerEditClicked(trigger)">
              <i class="icomoon icons-table-item icomoon-modify icomoon-detail-table cursor-pointer"
                 title="{{'COMMON_BUTTON_EDIT' | translate}}"></i>
            </a>
            <a class="icons-table-group" href="#" onclick="return false" (click)="setDisabled($event, trigger, true)"
               *ngIf="!trigger.disabled && triggerRightModel.triggerDisable.hasRight()">
              <i class="icomoon icons-table-item icomoon-disable icomoon-disable-table"
                 title="{{'COMMON_BUTTON_DISABLE' | translate}}"></i>
            </a>
            <a class="icons-table-group" href="#" onclick="return false" (click)="setDisabled($event, trigger, false)"
               *ngIf="trigger.disabled && triggerRightModel.triggerDisable.hasRight()">
              <i class="icomoon icons-table-item icomoon-enable icomoon-enable-table"
                 title="{{'COMMON_BUTTON_ENABLE' | translate}}"></i>
            </a>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header w-10 hidden-sm-down">
            <app-table-field-sorter [orderField]="TriggerOrderField.CREATION_TIME"
                                    [orderType]="queryModel.getOrderType(TriggerOrderField.CREATION_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="TriggerOrderField.NAME"
                                    [orderType]="queryModel.getOrderType(TriggerOrderField.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-field-sorter [orderField]="TriggerOrderField.TYPE"
                                    [orderType]="queryModel.getOrderType(TriggerOrderField.TYPE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TYPE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op [text]="'COMMON_TABLE_HEADER_EVENT' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op [text]="'TRIGGER_RELATED_TEMPLATE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op [text]="'COMMON_TABLE_HEADER_ACTIONS' | translate"></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>

      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>

    </div><!-- /.card-body -->

</div><!-- /.card -->
