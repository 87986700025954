/* eslint-disable */
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UIRouter } from '@uirouter/angular';
import { DocumentGroupService } from '../../../lib/document/document-group.service';
import { AuthService } from '../../../lib/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { DocumentGroupFieldErrorMap } from '../../../util/document-group.utils';
import { UserGroup, UserGroupService } from '../../../lib/user-group.service';
import { MultiselectOptionItemWithData, UiConstants } from '../../../util/core-utils';
import { Arrays } from '../../../lib/util/arrays';
import { StateName } from '../../../app.state-names';
import { DocumentGroupCreateModel } from '../../../util/document/document-utils';
import { RootCoreService } from '../../../lib/root-core.service';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { Angular2Multiselects } from '../../../util/multiselect';
import { ResourceQueryResult, Services } from '../../../lib/util/services';
import { Set } from 'immutable';
import { FieldError, FieldErrors, ObservableErrorResourceParser } from '../../../lib/util/errors';
import { Strings } from '../../../lib/util/strings';
import { UserGroupItemForDropdown } from '../../../util/user-utils';
import { forkJoin, Observable } from 'rxjs';
import { EmptyMessage } from '../../../lib/util/messages';

/* eslint-enable */

@Component({
  selector: 'app-document-group-create',
  templateUrl: 'document-group-create.component.html',
  styleUrls: ['document-group-create.component.scss']
})
export class DocumentGroupCreateComponent implements OnInit, AfterViewInit {

  UiConstants = UiConstants;

  languageCodes: string[] = [];
  model: Model = new Model();
  fieldErrors: DocumentGroupFieldErrorMap = {};
  userGroupList: UserGroupItemForDropdown[] = [];
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  dropdownSettings: Angular2Multiselects.Settings;
  parentDropdownSettings: Angular2Multiselects.Settings;

  groups: MultiselectOptionItemWithData<number>[] = [];

  ngOnInit() {
    this.translateService.get('DOCUMENT_GROUP_CREATE_TITLE').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('COMMON_DOCUMENT_GROUPS').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.DOCUMENT_GROUP_LIST});
      }
    );
  }

  ngAfterViewInit() {
    this.getLanguages();
    this.loadUserGroups();
    this.loadGroups();
    this.initDropDown();
  }

  create() {
    this.documentGroupService.create({
      name: this.model.item.name,
      code: this.model.item.code,
      user_group_id: this.model.item.createUserGroupIds(),
      parent_id: this.model.item.mainGroup ? undefined : this.model.item.parent[0].id,
      language_code: this.model.item.language_code
    }).subscribe(result => {
      if (this.model.item.children.length > 0) {
        const updateObservables: Observable<EmptyMessage>[] = [];
        this.model.item.children.forEach(g => {
          updateObservables.push(
            this.documentGroupService.updateGroup({
              id: g.data.id,
              name: g.data.name,
              language_code: g.data.language_code,
              code: g.data.code,
              user_group_id: g.data.user_group_id,
              parent_id: result.id
            })
          );
        });
        forkJoin(updateObservables).subscribe(result => {
            this.uiRouter.stateService.go(StateName.DOCUMENT_GROUP_LIST);
          },
          error => {
            // todo
          }
        );
      }
      else {
        this.uiRouter.stateService.go(StateName.DOCUMENT_GROUP_LIST);
      }
    }, (error: any) => {
      const res = ObservableErrorResourceParser.parseError(error);
      this.fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
    });
  }

  getLanguages() {
    this.rootService.getLanguageCodes({}).subscribe(
      (result: string[]) => {
        this.languageCodes = result;
        this.model.item.language_code = this.languageCodes[0];
      });
  }

  loadUserGroups(q?: any) {
    this.userGroupService.query({
        name: q ? Strings.undefinedOrNonEmpty(q) : undefined,
        disabled: false,
        order: Services.createOrderFieldParameter(UserGroup.Keys.toOrderFieldKey, Set.of(UserGroup.DEFAULT_ORDER)),
        page_number: 1,
        number_of_items: 30,
        no_progress_bar: true
      }
    ).subscribe((result: ResourceQueryResult<UserGroup>) => {
      this.userGroupList = [];
      Arrays.iterateByIndex(result.items, (userGroup) => {
        const item = new UserGroupItemForDropdown();
        item.id = userGroup.id;
        item.itemName = userGroup.name;
        this.userGroupList.push(item);
      });
    });
  }

  loadGroups(q?: string) {
    this.documentGroupService.query({
      name: q ? Strings.undefinedOrNonEmpty(q) : undefined,
      disabled: false,
      page_number: 1,
      number_of_items: UiConstants.autocompletePageSize,
      no_progress_bar: true
    }).subscribe(result => {
      this.groups = result.items.map(g => ({id: g.id!, itemName: g.name, data: g}));
    });
  }

  initDropDown() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .remoteSearch(true)
      .build();
    this.parentDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
  }

  removeFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.fieldErrors, fieldError);
  }

  onMainGroupChanged() {
    this.model.item.user_groups = [];
  }

  constructor(private translateService: TranslateService,
              private authService: AuthService,
              private uiRouter: UIRouter,
              private rootService: RootCoreService,
              private userGroupService: UserGroupService,
              private documentGroupService: DocumentGroupService) {
  }
}

class Model {
  item: DocumentGroupCreateModel = new DocumentGroupCreateModel();
  languageCode: string | null = null;
}
