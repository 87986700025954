/* eslint-disable */
import { Component, OnInit } from '@angular/core';
import { UIRouter } from '@uirouter/angular';
import { StateName } from '../../app.state-names';
import { ConfigurationService } from '../../lib/core-ext/configuration.service';
import { ServiceErrorStorage } from '../../lib/util/storages';

/* eslint-enable */

@Component({
  selector: 'app-configuration-load-error',
  templateUrl: './configuration-load-error.component.html',
  styleUrls: ['./configuration-load-error.component.scss']
})
export class ConfigurationLoadErrorComponent implements OnInit {

  isLoading: boolean = false;

  constructor(private uiRouter: UIRouter,
              private configurationService: ConfigurationService) {
    if (!localStorage.getItem('configurationError')) {
      this.uiRouter.stateService.go(StateName.LOGIN);
    }
    else {
      localStorage.removeItem('configurationError');
    }
  }

  ngOnInit() {
  }

  refresh() {
    if (!this.isLoading) {
      this.isLoading = true;
      this.configurationService.load()
        .then(() => {
          const destination = ServiceErrorStorage.getInstance().getStateObject();
          if (destination) {
            this.uiRouter.stateService.go(destination.stateName, destination.params);
          }
          else {
            this.uiRouter.stateService.go(StateName.LOGIN);
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    }
  }

}
