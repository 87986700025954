import { ExteriorTransport } from '../../../../lib/exterior-transport/exterior-transport.service';
import { Dates, OffsetDateTime } from '../../../../lib/util/dates';
import { ExteriorTransportRightModel } from '../../../../lib/exterior-transport/exterior-transport-right.model';
import { GrantedPermissionSetResolver } from '../../../../lib/right.service';

export class ExteriorTransportBaseDataModel {
  id: string = '';
  externalId: string = '';
  serialNumber: string = '';
  demander: string = '';
  transporter: string = '';
  state: string = '';
  stateEnum: ExteriorTransport.ExteriorTransportState = ExteriorTransport.ExteriorTransportState.NEW;
  stateIconClass: string = '';
  creationTime: OffsetDateTime = Dates.emptyOffsetDateTime();
  startTime: OffsetDateTime | undefined = undefined;
  finishTime: OffsetDateTime | undefined = undefined;
  rights: ExteriorTransportRightModel = new ExteriorTransportRightModel(GrantedPermissionSetResolver.empty());

  isNew(): boolean {
    return this.stateEnum === ExteriorTransport.ExteriorTransportState.NEW;
  }
}
