<div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12 p-0" [formGroup]="formGroup"
     id="task-record-base-card">
  <div class="card">

    <div class="card-header" id="task-record-base-card-header">
        <span *ngIf="!readonly">
        {{headingDictionaryKey | translate}}
          </span>
      <span *ngIf="readonly">
          {{m.name}}
        </span>
      <span class="badge importance-badge font-weight-normal {{m.importance.badgeClass}}"
            *ngIf="m.importance">{{m.importance.text}}</span>
      <br>
      <div *ngIf="m.state && !(isHelpdesk() && m.process !== undefined)" class="state-container font-weight-normal">
        <i class="state-icon {{m.state.stateObject.iconClass}}"></i>
        {{m.state.text}}
      </div>
      <div *ngIf="isHelpdesk() && m.process !== undefined && m.helpdeskState" class="state-container font-weight-normal">
        <i class="state-icon {{m.helpdeskState.iconClass}}"></i>
        {{m.helpdeskState.stringKey | translate}}
      </div>
    </div>

    <div class="card-body">

      <!-- region ContractNumber -->
      <div class="form-group mb-0" [ngStyle]="fieldStyle" [ngClass]="{ 'has-danger': hasLocalFieldError('contractNumber') }"
           id="task-record-base-contractNumber-group"
           *ngIf="isManagedField('CONTRACT_NUMBER') && m.contractNumber && ((!readonly)
                    || m.contractNumber[0]) && rightModel.contractNumberRead.hasRight()">
        <div class="row">
          <label class="col-form-label form-control-label col-md-4 detail-title"
                 [class.required-field-label]="isRequiredField('CONTRACT_NUMBER')">
            {{'CUSTOMER_RECORD_CONTRACT_NUMBER' | translate}}
          </label>
          <div class="col-md-8">
            <angular2-multiselect
              id="task-record-base-contract-number-input"
              class="form-control"
              [(ngModel)]="m.contractNumber"
              [data]="contractNumbers"
              [settings]="singleDropdownSettings"
              (onRemoteSearch)="onContractNumberSearch($event.target.value)"
              [readonly]="readonly || !TaskRecordStateMachine.StateMachine.canChangeContractNumber(currentState)"
              (onSelect)="contractNumberSelected($event)"
              formControlName="contractNumber">
            </angular2-multiselect>
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('contractNumber', 'hasDisabledItem')">
              <label>{{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}</label>
            </div>
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('contractNumber', 'required')">
              <label>{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</label>
            </div>
          </div>
        </div>
      </div>
      <!-- endregion ContractNumber -->

      <!-- region Name -->
      <div class="form-group mb-0" [ngStyle]="fieldStyle"
           id="task-record-base-name-group"
           *ngIf="!hideName()"
           [ngClass]="{ 'has-danger': hasFieldError(TaskRecord.ValidatedField.NAME) || hasLocalFieldError('name') }">
        <div class="row">
          <label class="col-form-label form-control-label col-md-4 detail-title"
                 [class.required-field-label]="!readonly && !isNameOptional()">
            {{'TASK_RECORD_CREATE_NAME' | translate}}
            <i class="icomoon icomoon-info info-icon" popover="{{'TASK_RECORD_NAME_OPTIONAL_TASK_HINT' | translate}}"
               *ngIf="isNameOptional()"
               triggers="mouseenter:mouseleave" placement="right">
            </i>
          </label>
          <div class="col-md-8">
            <!-- NOTE: trim="blur" has removed because it conflicts with material auto-complete -->
            <input type="text" class="form-control" placeholder="{{'TASK_RECORD_CREATE_NAME' | translate}}"
                   [readonly]="!isNameEditable()"
                   id="task-record-base-name-input"
                   formControlName="name"
                   maxlength="{{UiConstants.maximumVarcharLength}}"
                   validateOnBlur [validateFormControl]="formGroup.controls['name']"
                   [matAutocomplete]="nameAutoGroup"
                   [ngClass]="{ 'form-control-danger': hasFieldError(TaskRecord.ValidatedField.NAME) || hasLocalFieldError('name'), 'detail-description' : readonly }"
                   [(ngModel)]="m.name" (ngModelChange)="removeFieldError(TaskRecord.ValidatedField.NAME)"
                   (click)="onAutoCompleteClick('name')">
            <mat-autocomplete #nameAutoGroup="matAutocomplete">
              <mat-option *ngFor="let nameOption of nameOptions$ | async" [value]="nameOption">
                {{nameOption}}
              </mat-option>
            </mat-autocomplete>
            <div class="form-control-feedback" *ngIf="hasFieldError(TaskRecord.ValidatedField.NAME)">
              {{getFieldErrorText(TaskRecord.ValidatedField.NAME)}}
            </div>
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('name')">
            <span
              *ngIf="hasLocalFieldError('name', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- endregion Name -->

      <!-- region External ID -->
      <div class="form-group mb-0" [ngStyle]="fieldStyle"
           *ngIf="!(readonly && m.externalId === '') && isManagedField('EXTERNAL_ID')"
           id="task-record-base-externalid-group"
           [ngClass]="{ 'has-danger': hasFieldError(TaskRecord.ValidatedField.EXTERNAL_ID) || hasLocalFieldError('externalId') }">
        <div class="row">
          <label class="col-form-label form-control-label col-md-4 detail-title"
                 [class.required-field-label]="!readonly && externalIdRequired">
            {{'TASK_RECORD_EXTERNAL_ID' | translate}}
          </label>
          <div class="col-md-8">
            <input type="text" class="form-control" placeholder="{{'TASK_RECORD_EXTERNAL_ID' | translate}}"
                   id="task-record-base-externalid-input"
                   maxlength="{{UiConstants.maximumVarcharLength}}"
                   [readonly]="readonly" formControlName="externalId"
                   trim="blur" validateOnBlur [validateFormControl]="formGroup.controls['externalId']"
                   [ngClass]="{ 'form-control-danger': hasFieldError(TaskRecord.ValidatedField.EXTERNAL_ID) || hasLocalFieldError('externalId'), 'detail-description' : readonly }"
                   [(ngModel)]="m.externalId"
                   (ngModelChange)="removeFieldError(TaskRecord.ValidatedField.EXTERNAL_ID)">
            <div class="form-control-feedback" *ngIf="hasFieldError(TaskRecord.ValidatedField.EXTERNAL_ID)">
              {{getFieldErrorText(TaskRecord.ValidatedField.EXTERNAL_ID)}}
            </div>
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('externalId')">
                <span
                  *ngIf="hasLocalFieldError('externalId', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- endregion External ID -->

      <!-- region Description -->
      <div class="form-group mb-0" [ngStyle]="fieldStyle"
           [ngClass]="{ 'has-danger': hasLocalFieldError('description') }"
           *ngIf="!(readonly && m.description === '') && isManagedField('DESCRIPTION')"
           id="task-record-base-description-group">
        <div class="row">
          <label class="col-form-label form-control-label col-md-4 detail-title"
                 [class.required-field-label]="isRequiredField('DESCRIPTION')">
            {{'COMMON_DESCRIPTION' | translate}}
          </label>
          <div class="col-md-8">
            <input type="text" class="form-control" placeholder="{{'COMMON_DESCRIPTION' | translate}}" *ngIf="!readonly"
                   maxlength="{{UiConstants.maximumVarcharLength}}"
                   id="task-record-base-decription-input"
                   [(ngModel)]="m.description"
                   [ngClass]="{ 'detail-description' : readonly, 'form-control-danger': hasLocalFieldError('description') }"
                   formControlName="description">
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('description', 'required')">
              <label>{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</label>
            </div>
            <label class="detail-description detail-input-padding" *ngIf="readonly">
              {{m.description}}
            </label>
          </div>
        </div>
      </div>
      <!-- endregion Description -->

      <!-- region Importance -->
      <div class="form-group mb-0" [ngStyle]="fieldStyle" *ngIf="!readonly && isManagedField('IMPORTANCE')"
           [ngClass]="{ 'has-danger': hasLocalFieldError('importance') }"
           id="task-record-base-importance-group">
        <div class="row">
          <label class="col-form-label form-control-label col-md-4 detail-title"
                 [class.required-field-label]="isRequiredField('IMPORTANCE')">
            {{'TASK_IMPORTANCE_TITLE' | translate}}
          </label>
          <div class="col-md-8" *ngIf="TaskRecordStateMachine.StateMachine.canChangeImportance(currentState)">
            <select class="form-control"
                    id="task-record-base-importance-input"
                    [(ngModel)]="m.importance"
                    formControlName="importance"
                    [ngClass]="{ 'form-control-danger': hasLocalFieldError('importance') }">
              <option *ngFor="let importance of taskImportances" [ngValue]="importance">{{importance.text}}</option>
            </select>
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('importance', 'required')">
              <label>{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</label>
            </div>
          </div>
          <div class="col-md-8" *ngIf="!TaskRecordStateMachine.StateMachine.canChangeImportance(currentState)">
            <label class="col-form-label form-control detail-description" readonly>{{m.importance ? m.importance.text
              :
              '&nbsp;'}}</label>
          </div>
        </div>
      </div>
      <!-- endregion Importance -->

      <!-- region Usergroup -->
      <div class="form-group mb-0" [ngStyle]="fieldStyle" [ngClass]="{ 'has-danger': hasLocalFieldError('usergroup') }"
           id="task-record-base-assignee-group-group"
           *ngIf="isManagedField('ASSIGNEE_USER_GROUP') && ((m.usergroup && m.usergroup.id) || (!readonly && TaskRecordStateMachine.StateMachine.canChangeAssignee(currentState)))">
        <div class="row">
          <label class="col-form-label form-control-label col-md-4 detail-title"
                 [class.required-field-label]="isRequiredField('ASSIGNEE_USER_GROUP')">
            {{'TASK_DATA_MODIFICATION_USER_GROUP' | translate}}
          </label>
          <div class="col-md-8">
            <angular2-multiselect [data]="userGroups" [settings]="singleDropdownSettings"
                                  id="task-record-base-assignee-group-input"
                                  class="form-control"
                                  (onRemoteSearch)="loadUserGroups($event.target.value)"
                                  [(ngModel)]="m.userGroup"
                                  (ngModelChange)="onUserGroupModelChange()"
                                  [readonly]="readonly || !TaskRecordStateMachine.StateMachine.canChangeAssignee(currentState)"
                                  (onReadonlyItemClick)="navigateToUserGroupDetail($event)"
                                  formControlName="usergroup">
            </angular2-multiselect>
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('usergroup', 'hasDisabledItem')">
              <label>{{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}</label>
            </div>
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('usergroup', 'required')">
              <label>{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</label>
            </div>
          </div>
        </div>
      </div>
      <!-- endregion Usergroup -->

      <!-- region Assignee user -->
      <div class="form-group mb-0" [ngStyle]="fieldStyle"
           [ngClass]="{ 'has-danger': hasLocalFieldError('assigneeUser') }"
           id="task-record-base-assignee-user-group"
           *ngIf="isManagedField('ASSIGNEE_USER_APPLICATION') && config.assigneeWithUser &&  ((m.assignee.user && m.assignee.user.id) || (!readonly && TaskRecordStateMachine.StateMachine.canChangeAssignee(currentState)))">
        <div class="row">
          <label class="col-form-label form-control-label col-md-4 detail-title"
                 [class.required-field-label]="isRequiredField('ASSIGNEE_USER_APPLICATION')">
            {{'TASK_LABEL_OWNER_USER' | translate}}
          </label>
          <div class="col-md-8">
            <angular2-multiselect [data]="users" [settings]="assigneeDropdownSettings"
                                  id="task-record-base-assignee-user-input"
                                  class="form-control"
                                  (onRemoteSearch)="loadUsers($event.target.value)"
                                  [(ngModel)]="m.assignee._user"
                                  (ngModelChange)="onAssigneeChange()"
                                  (onSelect)="onAssigneeCustomerChanged()"
                                  (onDeSelect)="onAssigneeCustomerChanged()"
                                  [readonly]="readonly || !TaskRecordStateMachine.StateMachine.canChangeAssignee(currentState)"
                                  (onReadonlyItemClick)="navigateToUserDetail($event)"
                                  formControlName="assigneeUser">
            </angular2-multiselect>
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('assigneeUser')">
              <label
                *ngIf="hasLocalFieldError('assigneeUser', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</label>
              <label
                *ngIf="hasLocalFieldError('assigneeUser', 'hasDisabledItem')">{{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}</label>
            </div>
          </div>
        </div>
      </div>
      <!-- endregion Assignee user -->

      <!-- region Assignee device -->
      <div class="form-group mb-0" [ngStyle]="fieldStyle" [ngClass]="{ 'has-danger': hasLocalFieldError('device') }"
           id="task-record-base-assignee-mobile-group"
           *ngIf="isManagedField('ASSIGNEE_USER_APPLICATION') && config.assigneeWithMobileApp &&  ((m.assignee.device && m.assignee.device.id) || (!readonly && TaskRecordStateMachine.StateMachine.canChangeAssignee(currentState)))">
        <div class="row">
          <label class="col-form-label form-control-label col-md-4 detail-title"
                 [class.required-field-label]="isRequiredField('ASSIGNEE_USER_APPLICATION')">
            {{'TASK_DATA_MODIFICATION_DEVICE' | translate}}
          </label>
          <div class="col-md-8">
            <angular2-multiselect [data]="devices" [settings]="assigneeDropdownSettings"
                                  id="task-record-base-assignee-mobile-input"
                                  class="form-control"
                                  (onRemoteSearch)="loadDevices($event.target.value)"
                                  [(ngModel)]="m.assignee._device"
                                  (ngModelChange)="onAssigneeChange()"
                                  [readonly]="readonly || !TaskRecordStateMachine.StateMachine.canChangeAssignee(currentState)"
                                  formControlName="device">
            </angular2-multiselect>
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('device')">
              <label
                *ngIf="hasLocalFieldError('device', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</label>
              <label
                *ngIf="hasLocalFieldError('device', 'hasDisabledItem')">{{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}</label>
            </div>
          </div>
        </div>
      </div>
      <!-- endregion Assignee device -->

      <!-- region Assignee with device name -->
      <div class="form-group mb-0" [ngStyle]="fieldStyle"
           *ngIf="isManagedField('ASSIGNEE_USER_APPLICATION') && config.assigneeWithMobileApp && m.assignee.user && (readonly || !TaskRecordStateMachine.StateMachine.canChangeAssignee(currentState))">
        <div class="row">
          <label class="col-form-label form-control-label col-md-4 detail-title">{{'TASK_LABEL_OWNER_USER' |
            translate}}</label>
          <div class="col-md-8"
               *ngIf="(readonly || !TaskRecordStateMachine.StateMachine.canChangeAssignee(currentState))">
            <label class="col-form-label form-control detail-description" readonly>{{m.assignee.user.text}}</label>
          </div>
        </div>
      </div>
      <!-- region Assignee with device name -->

      <!-- region Customer -->
      <div class="form-group mb-0" [ngStyle]="fieldStyle" [ngClass]="{ 'has-danger': hasLocalFieldError('customer') }"
           id="task-record-base-customer-group"
           *ngIf="isManagedField('CUSTOMER') && !((readonly || !TaskRecordStateMachine.StateMachine.canChangeCustomerRecord(currentState)) && m.customerM.length === 0)">
        <div class="row">
          <label class="col-form-label form-control-label col-md-4 detail-title"
                 [class.required-field-label]="isRequiredField('CUSTOMER')">
            {{'TASK_LABEL_CUSTOMER' | translate}}
          </label>
          <div class="col-md-8" *ngIf="rightModel.customerRead && rightModel.customerRead.hasRight()">
            <div class="d-flex">
              <div class="flex-grow-1">
                <angular2-multiselect [data]="customers" [settings]="customerDropdownSettings"
                                      id="task-record-base-customer-input"
                                      class="form-control"
                                      [(ngModel)]="m.customerM"
                                      (onSelect)="onCustomerChanged()"
                                      (onDeSelect)="onCustomerChanged()"
                                      (onRemoteSearch)="getCustomers([], $event)"
                                      [readonly]="readonly || !TaskRecordStateMachine.StateMachine.canChangeCustomerRecord(currentState)"
                                      (onReadonlyItemClick)="navigateToCustomerRecordDetail($event)"
                                      formControlName="customer">
                </angular2-multiselect>
              </div>
              <button type="button" class="btn btn-secondary ml-1"
                      *ngIf="!readonly && rightModel.customerRecordCreate.hasRight() && TaskRecordStateMachine.StateMachine.canChangeCustomerRecord(currentState)"
                      (click)="openCustomerRecordQuickCreateDialog()">
                {{'CUSTOMER_RECORD_CREATE_BREADCRUMB' | translate}}
              </button>
            </div>
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('customer', 'hasDisabledItem')">
              <label>{{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}</label>
            </div>
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('customer', 'required')">
              <label>{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</label>
            </div>
          </div>
        </div>
      </div>
      <!-- endregion Customer -->

      <!-- region Billing info -->
      <div class="form-group mb-0" [ngStyle]="fieldStyle"
           [ngClass]="{ 'has-danger': hasLocalFieldError('billingInfo') }"
           *ngIf="(task && (task.autoInvoiceGenerate || task.manualInvoiceEnabled))
                  && isManagedField('CUSTOMER')
                  && !((readonly || !TaskRecordStateMachine.StateMachine.canChangeCustomerRecord(currentState)) && m.billingInfo.length === 0)">
        <div class="row">
          <label class="col-form-label form-control-label col-md-4 detail-title"
                 [class.required-field-label]="!readonly && task.autoInvoiceGenerate">
            {{'CUSTOMER_RECORD_BILLING_INFO' | translate}}
          </label>
          <div class="col-md-8" *ngIf="rightModel.customerRead && rightModel.customerRead.hasRight()">
            <div class="d-flex">
              <div class="flex-grow-1">
                <angular2-multiselect [data]="billingInfos" [settings]="billingInfoDropdownSettings"
                                      class="form-control"
                                      [(ngModel)]="m.billingInfo"
                                      [readonly]="readonly || !TaskRecordStateMachine.StateMachine.canChangeCustomerRecord(currentState)"
                                      formControlName="billingInfo">
                </angular2-multiselect>
              </div>
              <button type="button" class="btn btn-secondary ml-1"
                      *ngIf="!readonly && rightModel.billingInfoCreate.hasRight() && TaskRecordStateMachine.StateMachine.canChangeCustomerRecord(currentState)"
                      (click)="openBillingInfoCreateDialog()"
                      [disabled]="!m.customer">
                {{'CUSTOMER_RECORD_BILLING_INFO_CREATE' | translate}}
              </button>
            </div>
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('billingInfo', 'hasDisabledItem')">
              <label>{{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}</label>
            </div>
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('billingInfo', 'required')">
              <label>{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</label>
            </div>
          </div>
        </div>
      </div>
      <!-- endregion Billing info -->

      <!-- region Project -->
      <div class="form-group mb-0" [ngStyle]="fieldStyle" [ngClass]="{ 'has-danger': hasLocalFieldError('project') }"
           id="task-record-base-project-group"
           *ngIf="isManagedField('PROJECT') && m.project && ((!readonly && TaskRecordStateMachine.StateMachine.canChangeProject(currentState))
                    || m.project[0] && m.project[0].id !== null) && rightModel.projectRecordRead.hasRight()">
        <div class="row">
          <label class="col-form-label form-control-label col-md-4 detail-title"
                 [class.required-field-label]="isRequiredField('PROJECT')">
            {{'TASK_LABEL_PROJECT' | translate}}
          </label>
          <div class="col-md-8">
            <angular2-multiselect
              id="task-record-base-project-input"
              class="form-control"
              [(ngModel)]="m.project"
              [data]="projects"
              [settings]="singleDropdownSettings"
              (onRemoteSearch)="onProjectSearch($event.target.value)"
              [readonly]="readonly || !TaskRecordStateMachine.StateMachine.canChangeProject(currentState)"
              (onReadonlyItemClick)="navigateToProjectDetail($event)"
              formControlName="project">
            </angular2-multiselect>
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('project', 'hasDisabledItem')">
              <label>{{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}</label>
            </div>
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('project', 'required')">
              <label>{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</label>
            </div>
          </div>
        </div>
      </div>
      <!-- endregion Project -->

      <!--region CREATOR USER-->
      <div class="form-group mb-0" [ngStyle]="fieldStyle"
           *ngIf="m && m.creatorUser && m.creatorUser.length > 0">
        <div class="row">
          <label
            class="col-form-label form-control-label col-md-4 detail-title">{{'TASK_RECORD_TABLE_HEADER_CREATOR_USER' |
            translate}}</label>
          <div class="col-md-8">
            <angular2-multiselect
              id="task-record-base-creator-input"
              class="form-control"
              [(ngModel)]="m.creatorUser"
              [data]="m.creatorUser"
              [ngModelOptions]="{standalone: true}"
              [settings]="assigneeDropdownSettings"
              [readonly]="true"
              (onReadonlyItemClick)="navigateToUserDetail($event)">
            </angular2-multiselect>
          </div>
        </div>
      </div>
      <!--endregion CREATOR USER-->

      <!-- region Process -->
      <div class="form-group mb-0" [ngStyle]="fieldStyle"
           id="task-record-base-process-group"
           *ngIf="m.process && rightModel.processRead.hasRight()">
        <div class="row">
          <label
            class="col-form-label form-control-label col-md-4 detail-title">{{'TASK_RECORD_SEARCH_PROCESS' | translate}}</label>
          <div class="col-md-8">
            <angular2-multiselect
              id="task-record-base-process-input"
              class="form-control"
              [(ngModel)]="m.process"
              [data]="m.process"
              [ngModelOptions]="{standalone: true}"
              [settings]="singleDropdownSettings"
              [readonly]="true"
              (onReadonlyItemClick)="navigateToProcessDetail($event)">
            </angular2-multiselect>
          </div>
        </div>
      </div>
      <!-- endregion Process -->

      <!--region CREATOR USER-->
      <div class="form-group mb-0" [ngStyle]="fieldStyle"
           *ngIf="m.processCreatorUser && m.processCreatorUser.length > 0 && rightModel.processRead.hasRight() && rightModel.userRead.hasRight()">
        <div class="row">
          <label
            class="col-form-label form-control-label col-md-4 detail-title">{{'PROCESS_CREATOR_USER' |
            translate}}</label>
          <div class="col-md-8">
            <angular2-multiselect
              id="task-record-base-process-creator-input"
              class="form-control"
              [(ngModel)]="m.processCreatorUser"
              [data]="m.processCreatorUser"
              [ngModelOptions]="{standalone: true}"
              [settings]="assigneeDropdownSettings"
              [readonly]="true"
              (onReadonlyItemClick)="navigateToUserDetail($event)">
            </angular2-multiselect>
          </div>
        </div>
      </div>
      <!--endregion CREATOR USER-->

      <!--region Creation Time-->
      <div class="form-group mb-0" [ngStyle]="fieldStyle"
           *ngIf="m.creationTime">
        <div class="row">
          <label class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_CREATION_TIME' |
            translate}}</label>
          <div class="col-md-8">
            <label class="col-form-label form-control detail-description">{{m.creationTime ?
              (m.creationTime.toUtcIsoString() | date:'short') : ''}}</label>
          </div>
        </div>
      </div>
      <!--endregion Creation Time-->

      <!--region Update Time-->
      <div class="form-group mb-0" [ngStyle]="fieldStyle"
           *ngIf="m.updateTime">
        <div class="row">
          <label class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_UPDATE_TIME' |
            translate}}</label>
          <div class="col-md-8">
            <label class="col-form-label form-control detail-description">{{m.updateTime ?
              (m.updateTime.toUtcIsoString() | date:'short') : ''}}</label>
          </div>
        </div>
      </div>
      <!--endregion Update Time-->

      <!--region Deadline-->
      <div class="form-group mb-0" [ngStyle]="fieldStyle"
           [ngClass]="{ 'has-danger': hasLocalFieldError('deadlineDate') || hasLocalFieldError('deadlineTime') }"
           id="task-record-base-deadline-group"
           *ngIf="isManagedField('DEADLINE') && (!readonly && TaskRecordStateMachine.StateMachine.canChangeDeadline(currentState)) || ((readonly || !TaskRecordStateMachine.StateMachine.canChangeDeadline(currentState)) && (getDeadlineDateTime() !== ''))">
        <div class="row">
          <label class="col-form-label form-control-label col-md-4 detail-title"
                 [class.required-field-label]="isRequiredField('DEADLINE')">
            {{'TASK_LABEL_DEADLINE' | translate}}
          </label>
          <div class="col-md-8">
            <div class="clearfix row">
              <div class="col-sm">
                <div *ngIf="!readonly && TaskRecordStateMachine.StateMachine.canChangeDeadline(currentState)">

                  <div class="row">

                    <div class="input-group col-md-6 mb-1 mb-md-0">
                      <input placeholder="{{'DATE_TIME_PICKER_PLACEHOLDER_DATE' | translate}}" type="text"
                             id="task-record-base-deadline-date-input"
                             class="form-control"
                             [(ngModel)]="m.deadlineDate"
                             (ngModelChange)="onDeadlineDateChanged()"
                             formControlName="deadlineDate"
                             [readonly]="readonly || !TaskRecordStateMachine.StateMachine.canChangeDeadline(currentState)"
                             [ngClass]="{ 'has-danger': hasLocalFieldError('deadlineDate') }"
                             ngbDatepicker #date_picker="ngbDatepicker"
                             validateLocalDate>
                      <span *ngIf="!readonly && TaskRecordStateMachine.StateMachine.canChangeDeadline(currentState)"
                            class="add-on input-group-addon-gray" (click)="date_picker.toggle()">
                          <i class="icomoon icomoon-calendar"></i>
                       </span>
                    </div>

                    <div class="input-group col-md-6">
                      <input placeholder="{{'DATE_TIME_PICKER_PLACEHOLDER_TIME' | translate}}" type="text"
                             id="task-record-base-deadline-time-input"
                             class="form-control hidden-value-when-disabled"
                             [(ngModel)]="m.deadlineTime"
                             formControlName="deadlineTime"
                             [readonly]="readonly || !TaskRecordStateMachine.StateMachine.canChangeDeadline(currentState)"
                             [ngClass]="{ 'has-danger': hasLocalFieldError('deadlineTime') }"
                             [seconds]="false"
                             [disabled]="m.deadlineDate === null || m.deadlineDate === undefined"
                             [allowEmptyValue]="false"
                             ngbTimepicker #time_picker="ngbTimepicker" [toggleButtonId]="'deadline_toggle_button'"
                             [spinners]="true" [hourStep]="1" [minuteStep]="10">
                      <span *ngIf="!readonly && TaskRecordStateMachine.StateMachine.canChangeDeadline(currentState)"
                            id="deadline_toggle_button"
                            class="add-on input-group-addon-gray"
                            (click)="time_picker.toggle()">
                          <i class="icomoon icomoon-time"></i>
                        </span>
                    </div>

                  </div>
                  <div class="form-control-feedback"
                       *ngIf="hasLocalFieldError('deadlineDate', 'required') || hasLocalFieldError('deadlineTime', 'required')">
                    <label>{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</label>
                  </div>
                </div>
                <div class="input-group"
                     *ngIf="readonly || !TaskRecordStateMachine.StateMachine.canChangeDeadline(currentState)">
                  <label class="col-form-label form-control detail-description"
                         readonly>{{ getDeadlineDateTime() | date:'short'}}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--endregion Deadline-->

      <!--region Estimated Time-->
      <div *ngIf="isManagedField('ESTIMATED_TIME')" class="form-group mb-0 row" [ngStyle]="fieldStyle"
           [ngClass]="{ 'has-danger': hasLocalFieldError('estimatedTimeHour') || hasLocalFieldError('estimatedTimeMinute') }"
           id="task-record-base-estimated-time-group">
        <label class="col-form-label form-control-label col-md-4 detail-title"
               [class.required-field-label]="isRequiredField('ESTIMATED_TIME')">
          {{'TASK_ESTIMATED_TIME' | translate}}
        </label>
        <div class="col-md-8">
          <!--on create/edit screens-->
          <div *ngIf="!readonly && TaskRecordStateMachine.StateMachine.canChangeEstimatedTime(currentState)"
               class="row">
            <div class="col-md-6 d-flex w-100 align-items-center">
              <input type="number" class="form-control mr-1 text-right"
                     id="task-record-base-estimated-time-hour-input"
                     min="0"
                     formControlName="estimatedTimeHour"
                     [(ngModel)]="m.estimatedTime.hours"
                     [ngClass]="{ 'form-control-danger': hasLocalFieldError('estimatedTimeHour') }">
              <span>{{'TASK_ESTIMATED_TIME_HOURS' | translate}}</span>
            </div>
            <div class="col-md-6 d-flex w-100 align-items-center">
              <input type="number" class="form-control mr-1 text-right"
                     id="task-record-base-estimated-time-min-input"
                     min="0"
                     max="59"
                     step="5"
                     formControlName="estimatedTimeMinute"
                     [(ngModel)]="m.estimatedTime.minutes"
                     [ngClass]="{ 'form-control-danger': hasLocalFieldError('estimatedTimeMinute') }">
              <span>{{'TASK_ESTIMATED_TIME_MINUTES' | translate}}</span>
            </div>
          </div>
          <div class="form-control-feedback"
               *ngIf="hasLocalFieldError('estimatedTimeHour', 'required') || hasLocalFieldError('estimatedTimeMinute', 'required')">
            <label>{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</label>
          </div>
          <!--on detail screen-->
          <label *ngIf="readonly || !TaskRecordStateMachine.StateMachine.canChangeEstimatedTime(currentState)"
                 class="col-form-label form-control detail-description" readonly>
            <span
              *ngIf="m.estimatedTime.hours > 0">{{m.estimatedTime.hours}} {{'TASK_ESTIMATED_TIME_HOURS' | translate}}</span>
            <span *ngIf="m.estimatedTime.minutes > 0 || (m.estimatedTime.hours > 0 && m.estimatedTime.minutes === 0)">
                {{m.estimatedTime.minutes}} {{'TASK_ESTIMATED_TIME_MINUTES' | translate}}
              </span>
          </label>
        </div>
      </div>
      <!--endregion Estimated Time-->

      <!--region Time spent in task-->
      <div *ngIf="isManagedField('ESTIMATED_TIME') && taskRecord" class="form-group mb-0 row"
           [ngStyle]="fieldStyle">
        <label class="col-form-label form-control-label col-md-4 detail-title">{{'TASK_TIME_SPENT' |
          translate}}</label>
        <div class="col-md-8">
          <!--on detail screen-->
          <label class="col-form-label form-control detail-description" readonly
                 [ngClass]="{'pl-0' : !readonly}">
            <span *ngIf="m.timeSpent.hours > 0">{{m.timeSpent.hours}} {{'TASK_ESTIMATED_TIME_HOURS' | translate}}</span>
            <span *ngIf="m.timeSpent.minutes > 0 || (m.timeSpent.hours === 0 && m.timeSpent.minutes === 0)">
                {{m.timeSpent.minutes}} {{'TASK_ESTIMATED_TIME_MINUTES' | translate}}
              </span>
          </label>
        </div>
      </div>
      <!--endregion Time spent in task-->

      <!--region Agreed Time-->
      <div class="form-group mb-0" [ngStyle]="fieldStyle"
           [ngClass]="{ 'has-danger': hasLocalFieldError('agreedTimeDate') || hasLocalFieldError('agreedTimeTime') }"
           id="task-record-base-agreed-time-group"
           *ngIf="isManagedField('AGREED_TIME') && (!readonly && TaskRecordStateMachine.StateMachine.canChangeAgreedTime(currentState)) || ((readonly || !TaskRecordStateMachine.StateMachine.canChangeAgreedTime(currentState)) && (getAgreedDateTime() !== ''))">
        <div class="row">
          <label class="col-form-label form-control-label col-md-4 detail-title"
                 [class.required-field-label]="isRequiredField('AGREED_TIME')">
            {{'TASK_LABEL_AGREED_TIME' | translate}}
          </label>
          <div class="col-md-8">
            <div class="clearfix row">
              <div class="col-sm">
                <div *ngIf="!readonly && TaskRecordStateMachine.StateMachine.canChangeAgreedTime(currentState)">

                  <div class="row">

                    <div class="input-group col-md-6 mb-1 mb-md-0">
                      <input placeholder="{{'DATE_TIME_PICKER_PLACEHOLDER_DATE' | translate}}" type="text"
                             id="task-record-base-agreed-time-date-input"
                             class="form-control"
                             [(ngModel)]="m.agreedTimeDate"
                             (ngModelChange)="onAgreedTimeDateChanged()"
                             formControlName="agreedTimeDate"
                             [readonly]="readonly || !TaskRecordStateMachine.StateMachine.canChangeAgreedTime(currentState)"
                             [ngClass]="{ 'form-control-danger': hasLocalFieldError('agreedTimeDate') }"
                             ngbDatepicker #agreed_date_picker="ngbDatepicker"
                             validateLocalDate>
                      <span *ngIf="!readonly && TaskRecordStateMachine.StateMachine.canChangeAgreedTime(currentState)"
                            class="add-on input-group-addon-gray" (click)="agreed_date_picker.toggle()">
                        <i class="icomoon icomoon-calendar"></i>
                       </span>
                    </div>

                    <div class="input-group col-md-6">
                      <input placeholder="{{'DATE_TIME_PICKER_PLACEHOLDER_TIME' | translate}}" type="text"
                             id="task-record-base-agreed-time-time-input"
                             class="form-control hidden-value-when-disabled"
                             [(ngModel)]="m.agreedTimeTime"
                             formControlName="agreedTimeTime"
                             [readonly]="readonly || !TaskRecordStateMachine.StateMachine.canChangeAgreedTime(currentState)"
                             [ngClass]="{ 'form-control-danger': hasLocalFieldError('agreedTimeTime') }"
                             [seconds]="false"
                             [allowEmptyValue]="false"
                             [disabled]="m.agreedTimeDate === null || m.agreedTimeDate === undefined"
                             ngbTimepicker #agreed_time_picker="ngbTimepicker"
                             [toggleButtonId]="'agreed_time_toggle_button'"
                             [spinners]="true" [hourStep]="1" [minuteStep]="10">
                      <span *ngIf="!readonly && TaskRecordStateMachine.StateMachine.canChangeAgreedTime(currentState)"
                            id="agreed_time_toggle_button"
                            class="add-on input-group-addon-gray"
                            (click)="agreed_time_picker.toggle()">
                        <i class="icomoon icomoon-time"></i>
                      </span>
                    </div>

                  </div>
                  <div class="form-control-feedback"
                       *ngIf="hasLocalFieldError('agreedTimeDate', 'required') || hasLocalFieldError('agreedTimeTime', 'required')">
                    <label>{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</label>
                  </div>
                </div>
                <div class="input-group"
                     *ngIf="readonly || !TaskRecordStateMachine.StateMachine.canChangeAgreedTime(currentState)">
                  <label class="col-form-label form-control detail-description"
                         readonly>{{ getAgreedDateTime() | date:'short'}}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--endregion Agreed Time-->

      <!--region export-->
      <div class="form-group mb-0" [ngStyle]="fieldStyle" *ngIf="readonly && m.exportState !== 'NOT_EXPORTED'"
           [ngClass]="{ 'export-succeeded' : m.exportState === 'EXPORTED', 'export-failed' : m.exportState === 'EXPORT_FAILED' }">
        <div class="row mb-0">
          <label class="col-form-label form-control-label col-md-4 detail-title">{{'TASK_RECORD_DETAIL_EXPORT_TIME'
            |
            translate}}</label>
          <div class="col-md-8">
            <label class="col-form-label form-control detail-description" readonly>
              {{m.exportTime ? (m.exportTime.toUtcIsoString() | date:'short') : ''}}
            </label>
          </div>
        </div>
        <div class="container-vertical-padding-half"></div>
        <div class="row mb-0">
          <label class="col-form-label form-control-label col-md-4 detail-title">{{'TASK_RECORD_DETAIL_EXPORT_STATE'
            |
            translate}}</label>
          <div class="col-md-8">
            <label class="col-form-label form-control detail-description" readonly>
              {{ getExportStateString(m.exportState) | translate }}
            </label>
          </div>
        </div>
        <ng-container *ngIf="m.exportState === 'EXPORT_FAILED'">
          <div class="container-vertical-padding-half"></div>
          <div class="row mb-0">
            <label
              class="col-form-label form-control-label col-md-4 detail-title">{{'TASK_RECORD_DETAIL_EXPORT_ERROR_CODE'
              |
              translate}}</label>
            <div class="col-md-8 d-flex">
              <label class="col-form-label form-control detail-description flex-grow-1 text-truncate" readonly>
                {{m.previousExportError.code}}
              </label>
            </div>
          </div>
          <div class="container-vertical-padding-half"></div>
          <div class="row mb-0">
            <label
              class="col-form-label form-control-label col-md-4 detail-title">{{'TASK_RECORD_DETAIL_EXPORT_ERROR_MESSAGE'
              |
              translate}}</label>
            <div class="col-md-8">
              <label class="col-form-label form-control detail-description" readonly>
                {{m.previousExportError.message}}
              </label>
            </div>
          </div>
          <div class="row mb-0">
            <div class="col-md-4"></div>
            <div class="col-md-8">
              <button *ngIf="m.previousExportError.audit_log_response_id"
                      uiSref="Admin.AuditLogDetail" [uiParams]="{ id: m.previousExportError.audit_log_response_id }"
                      type="button" class="btn btn-secondary ml-1">
                {{'MENU_NAVBAR_AUDIT_LOG' | translate}}
              </button>
            </div>
          </div>
        </ng-container>
      </div>
      <!--endregion export-->

      <!--region file-document multiselect-->
      <div class="form-group mb-0" [ngStyle]="fieldStyle"
           [ngClass]="{ 'has-danger': hasLocalFieldError('fileDocument') }"
           id="task-record-base-file-group"
           *ngIf="isManagedField('FILE_DOCUMENTS') && rightModel.documentFileRead.hasRight() && (!readonly || (readonly && m.fileDocuments.length > 0))">
        <div class="row mb-0">
          <label class="col-form-label form-control-label col-md-4 detail-title"
                 [class.required-field-label]="isRequiredField('FILE_DOCUMENTS')">
            {{'TASK_RECORD_DETAIL_LINK_FILE' | translate}}
          </label>
          <div class="col-md-8">
            <angular2-multiselect
              id="task-record-base-file-input"
              [(ngModel)]="m.fileDocuments"
              [data]="fileListItems"
              [settings]="multiDropdownSettings"
              (onRemoteSearch)="loadFileDocuments($event.target.value)"
              (onReadonlyItemClick)="navigateToFileDocument($event)"
              [readonly]="readonly"
              formControlName="fileDocument">
            </angular2-multiselect>
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('fileDocument', 'hasDisabledItem')">
              <label>{{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}</label>
            </div>
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('fileDocument', 'required')">
              <label>{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</label>
            </div>
          </div>
        </div>
      </div>

      <!--endregion file-document multiselect-->

      <!--region link-document multiselect-->
      <div class="form-group mb-0" [ngStyle]="fieldStyle"
           [ngClass]="{ 'has-danger': hasLocalFieldError('linkDocument') }"
           id="task-record-base-link-group"
           *ngIf="isManagedField('TEXT_DOCUMENTS') && rightModel.documentLinkRead.hasRight() && (!readonly || (readonly && m.linkDocuments.length > 0))">
        <div class="row">
          <label class="col-form-label form-control-label col-md-4 detail-title"
                 [class.required-field-label]="isRequiredField('TEXT_DOCUMENTS')">
            {{'TASK_RECORD_DETAIL_LINK_LINK' | translate}}
          </label>
          <div class="col-md-8">
            <angular2-multiselect
              id="task-record-base-link-input"
              [(ngModel)]="m.linkDocuments"
              [data]="linkListItems"
              (onRemoteSearch)="loadLinkDocuments($event.target.value)"
              [settings]="multiDropdownSettings"
              (onReadonlyItemClick)="navigateToLinkDocument($event)"
              [readonly]="readonly"
              formControlName="linkDocument">
            </angular2-multiselect>
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('linkDocument', 'hasDisabledItem')">
              <label>{{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}</label>
            </div>
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('linkDocument', 'required')">
              <label>{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</label>
            </div>
          </div>
        </div>
      </div>

      <!--endregion link-document multiselect-->

      <!--region linked-surveys multiselect-->
      <div class="form-group mb-0" [ngStyle]="fieldStyle" [ngClass]="{ 'has-danger': hasLocalFieldError('survey') }"
           id="task-record-base-survey-group"
           *ngIf="isManagedField('SURVEYS') && rightModel.surveyRead.hasRight() && (!readonly || (readonly && m.linkedSurveys.length > 0))">
        <div class="row">
          <label
            class="col-form-label form-control-label col-md-4 detail-title"
            [class.required-field-label]="isRequiredField('SURVEYS')">
            {{'FORM_CREATE_DEFAULT_LINKED_SURVEYS' | translate}}
          </label>
          <div class="col-md-8">
            <angular2-multiselect
              id="task-record-base-survey-input"
              [(ngModel)]="m.linkedSurveys"
              [data]="surveys"
              (onRemoteSearch)="onSurveySearch($event.target.value)"
              [settings]="multiDropdownSettings"
              (onReadonlyItemClick)="navigateToSurvey($event)"
              [readonly]="readonly || !TaskRecordStateMachine.StateMachine.canChangeLinkedSurveys(currentState)"
              formControlName="survey">
            </angular2-multiselect>
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('survey', 'hasDisabledItem')">
              <label>{{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}</label>
            </div>
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('survey', 'required')">
              <label>{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</label>
            </div>
          </div>
        </div>
      </div>
      <!--endregion linked-surveys multiselect-->

      <!-- region Comment -->
      <div class="form-group mb-0" [ngStyle]="fieldStyle" *ngIf="readonly && m.stateChangeMessage">
        <div class="row">
          <label class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_COMMENT' |
            translate}}</label>
          <div class="col-md-8">
          <textarea type="text" class="form-control state-change-message"
                    placeholder="{{'COMMON_COMMENT' | translate}}"
                    [readonly]="readonly"
                    [ngClass]="{ 'detail-description' : readonly }"
                    [(ngModel)]="m.stateChangeMessage"
                    [ngModelOptions]="{standalone: true}"></textarea>
          </div>
        </div>
      </div>
      <!-- endregion Comment -->
    </div>

    <div class="card-footer d-flex flex-row-reverse" *ngIf="quickUpdateEnabled">
      <input type="submit" class="btn btn-primary"
             id="task-record-base-quick-update-button"
             (click)="onQuickUpdateClicked()" value="{{'COMMON_SAVE_BASE_DATA'|translate}}"/>
    </div>
  </div>

  <div class="container-vertical-padding-half"></div>

  <div class="card"
       *ngIf="isManagedField('PLACE_OF_CONSUMPTION') && !((readonly || !TaskRecordStateMachine.StateMachine.canChangePoc(currentState)) && !m.placeOfConsumption.address.id)"
       id="task-record-base-poc-card"
  >
    <div class="card-header"
         [class.required-field-label]="!readonly && task.placeOfConsumptionRequirement !== PlaceOfConsumptionRequirement.NOT_REQUIRED">
      {{'TASK_ADDRESS' | translate}}
    </div>
    <div class="card-body">
      <!-- region POC address -->
      <div class="form-group mb-0 row" [ngStyle]="fieldStyle">
        <label class="col-form-label form-control-label col-md-4 detail-title"
               [class.required-field-label]="!readonly && task.placeOfConsumptionRequirement !== PlaceOfConsumptionRequirement.NOT_REQUIRED">
          {{'TASK_ADDRESS' | translate}}
        </label>
        <div class="col-md-8" *ngIf="!readonly && TaskRecordStateMachine.StateMachine.canChangePoc(currentState)">
          <angular2-multiselect class="form-control"
                                [data]="selectableAddresses"
                                [settings]="pocDropdownSettings"
                                [(ngModel)]="_selectedAddress"
                                [ngModelOptions]="{standalone: true}"
                                (onSelect)="onSelectedAddressChanged()"
                                (onDeSelect)="onSelectedAddressChanged()">
          </angular2-multiselect>
        </div>
        <div class="col-md-8" *ngIf="readonly || !TaskRecordStateMachine.StateMachine.canChangePoc(currentState)">
          <label class="form-control detail-description"
                 readonly>{{selectedAddress.itemName + ' (' + selectedAddress.itemSubtitle + ')'}}</label>
        </div>
      </div>
      <div *ngIf="showPOCAddress()" class="poc-address-display" [ngClass]="{'readonly-padding': readonly}">
        <app-postal-address-field
          id="task-record-base-poc-address-input"
          [label]="'TASK_SELECTED_POC'"
          [model]="m.placeOfConsumption.address"
          [fieldStyle]="fieldStyle"
          [innerField]="true"
          [readonly]="(readonly || !TaskRecordStateMachine.StateMachine.canChangePoc(currentState)) || (selectedAddress && selectedAddress.id !== -1)"
          [requiredField]="false"
          [fieldRatio]="8"
          [form]="form">
        </app-postal-address-field>
      </div>
      <!-- endregion POC address -->

      <!-- region POC lat-->
      <div *ngIf="isManagedField('COORDINATES') && latLonVisible">
        <div class="form-group mb-0 row" [ngStyle]="fieldStyle"
             [ngClass]="{ 'has-danger': hasFieldError(TaskRecord.ValidatedField.LATITUDE) || hasLocalFieldError('latitude') }">
          <label class="col-form-label form-control-label col-md-4 detail-title">{{'TASK_GEOCODING_COORDINATES' |
            translate}}</label>
          <div class="col-md-8">
            <input type="number" class="form-control" placeholder="{{'TASK_GEOCODING_LAT' | translate}}"
                   [readonly]="readonly || !TaskRecordStateMachine.StateMachine.canChangePoc(currentState)"
                   formControlName="latitude"
                   validateOnBlur [validateFormControl]="formGroup.controls['latitude']"
                   [ngClass]="{ 'form-control-danger': hasFieldError(TaskRecord.ValidatedField.LATITUDE) || hasLocalFieldError('latitude'), 'detail-description' : readonly }"
                   [(ngModel)]="m.placeOfConsumption.latitude"
                   (ngModelChange)="removeFieldError(TaskRecord.ValidatedField.LATITUDE)">
            <div class="form-control-feedback" *ngIf="hasFieldError(TaskRecord.ValidatedField.LATITUDE)">
              {{getFieldErrorText(TaskRecord.ValidatedField.LATITUDE)}}
            </div>
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('latitude')">
              <span>{{'COMMON_VALIDATION_MESSAGE_INVALID_LATITUDE' | translate}}</span>
            </div>
            <!-- endregion POC lat-->
          </div>
        </div>
        <!-- region POC lon-->
        <div class="form-group mb-0 row" [ngStyle]="fieldStyle"
             [ngClass]="{ 'has-danger': hasFieldError(TaskRecord.ValidatedField.LONGITUDE) || hasLocalFieldError('longitude') }">
          <div class="col-md-4"></div>
          <div class="col-md-8">
            <input type="number" class="form-control" placeholder="{{'TASK_GEOCODING_LON' | translate}}"
                   [readonly]="readonly || !TaskRecordStateMachine.StateMachine.canChangePoc(currentState)"
                   formControlName="longitude"
                   validateOnBlur [validateFormControl]="formGroup.controls['longitude']"
                   [ngClass]="{ 'form-control-danger': hasFieldError(TaskRecord.ValidatedField.LONGITUDE) || hasLocalFieldError('longitude'), 'detail-description' : readonly }"
                   [(ngModel)]="m.placeOfConsumption.longitude"
                   (ngModelChange)="removeFieldError(TaskRecord.ValidatedField.LONGITUDE)">
            <div class="form-control-feedback" *ngIf="hasFieldError(TaskRecord.ValidatedField.LONGITUDE)">
              {{getFieldErrorText(TaskRecord.ValidatedField.LONGITUDE)}}
            </div>
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('longitude')">
              <span>{{'COMMON_VALIDATION_MESSAGE_INVALID_LONGITUDE' | translate}}</span>
            </div>
          </div>
        </div>
        <!-- endregion POC lon-->
      </div>
    </div>
  </div>
  <input type="submit" class="btn btn-primary-outline floating_apply_button" *ngIf="!readonly"
         id="task-record-base-apply-button"
         (click)="onSaveButtonClicked(false)" value="{{'COMMON_BUTTON_APPLY'|translate}}"/>
  <input type="submit" class="btn btn-primary floating_save_button" *ngIf="!readonly"
         id="task-record-base-save-button"
         (click)="onSaveButtonClicked(true)" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
</div>
