/* eslint-disable */
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, } from '@angular/core';
import { CustomerRecord, CustomerRecordService } from '../../../lib/customer/customer-record.service';
import { FormBuilder, FormGroup, NgForm, NgModel, Validators, } from '@angular/forms';
import {
  FieldValidationError,
  ForwardingNgFormRef,
  LocalFormGroupValidationErrors,
  OrderType,
  QueryResult,
} from '../../../lib/util/services';
import { CustomerRecordBase, } from './customer-record-base-view';
import { ImmutableCustomerRecord, MultiselectOptionItem, OptionItem, SelectUtils, UiConstants, } from '../../../util/core-utils';
import { UIRouter } from '@uirouter/angular';
import { AppValidators } from '../../../util/app-validators';
import { Address, AddressModel } from '../../../lib/address';
import { List, Set, } from 'immutable';
import { TranslateService } from '@ngx-translate/core';
import { StringKey } from '../../../app.string-keys';
import { PostalAddressFieldComponent } from '../../../shared/address/postal-address/postal-address-field.component';
import { Angular2Multiselects } from '../../../util/multiselect';
import { CountryService } from '../../../lib/country.service';
import { BillingAddressComponent } from '../../../shared/address/billing-address/billing-address.component';
import { InputMask } from '../../../util/input-masks';
import { Customer, CustomerService } from '../../../lib/customer/customer.service';
import { empty, Observable } from 'rxjs';
import { Strings } from '../../../lib/util/strings';
import {
  CustomerRecordFieldModel,
  CustomerRecordFieldType,
  CustomerRecordItem,
  CustomerRecordUtils
} from '../../../util/customer-record-utils';
import { ConfigModel } from '../../../util/task-record-utils';
import { ConfigurationService } from '../../../lib/core-ext/configuration.service';
import { StateName } from '../../../app.state-names';
import { CompanyMultiselectProvider } from '../../../lib/company/company-multiselect.provider';
import { LedgerNumberMultiselectProvider } from '../../../lib/ledger/number/ledger-number-multiselect.provider';
import { RightResolver, RightService } from '../../../lib/right.service';
import { RightModel } from '../../../app.rights';
import { UserGroupMultiselectProvider } from '../../../lib/user-group/user-group-multiselect.provider';
import { UserMultiselectProvider } from '../../../lib/user/user-multiselect.provider';
import { UserMeService } from '../../../lib/user/user-me.service';
import { User } from '../../../lib/user.service';
import {
  CustomerRecordBillingInfoBaseComponent
} from '../customer-record-billing-info/customer-record-billing-info-base/customer-record-billing-info-base.component';
import GenderType = CustomerRecord.GenderType;
import genderTypes = CustomerRecord.genderTypes;

/* eslint-enable */

@Component({
  selector: 'app-customer-record-base',
  templateUrl: 'customer-record-base.component.html',
  styleUrls: ['customer-record-base.component.scss'],
})
export class CustomerRecordBaseComponent implements OnInit, CustomerRecordBase.View {

  CustomerRecord = CustomerRecord;
  InputMask = InputMask;
  UiConstants = UiConstants;
  SelectUtils = SelectUtils;
  CustomerRecordUtils = CustomerRecordUtils;
  CustomerRecordFieldType = CustomerRecordFieldType;

  @ViewChild('name')
  nameInput?: NgModel;

  @ViewChild(PostalAddressFieldComponent)
  postalAddressView?: PostalAddressFieldComponent;

  @ViewChild('notificationAddress')
  notificationAddressView?: BillingAddressComponent;

  @ViewChild('billingInfo')
  billingInfoView: CustomerRecordBillingInfoBaseComponent;

  @Input()
  form?: NgForm;

  @Input()
  headingDictionaryKey?: string;

  @Input()
  readonly: boolean;

  @Input()
  allowClone: boolean;

  @Input()
  externalIdRequired?: boolean;

  @Input()
  customerItem?: Customer.Customer;

  @Output() showDialog: EventEmitter<any> = new EventEmitter();

  @Output()
  submitted = new EventEmitter<boolean>();

  countryItems?: List<AddressModel.CountryItem>;

  userDropdownSettings: Angular2Multiselects.Settings;
  userGroupDropdownSettings: Angular2Multiselects.Settings;
  customerRecordParentDropdownSettings: Angular2Multiselects.Settings;
  customerRecordChildrenDropdownSettings: Angular2Multiselects.Settings;
  singleRemoteDropdownSettings: Angular2Multiselects.Settings;

  fieldStyle = {
    'padding': UiConstants.formRecordFieldPadding
  };

  formGroup: FormGroup;

  model: CustomerRecordBase.Model;
  customerRecord?: CustomerRecord.CustomerRecord;
  customers: CustomerRecordItem[] = [];
  companies: MultiselectOptionItem<number>[] = [];
  defaultSelectedCountryCode?: string;
  ledgerNumbers: MultiselectOptionItem<number>[] = [];
  fieldModel: CustomerRecordFieldModel = CustomerRecordFieldModel.empty();

  private fieldErrors: FieldValidationError<CustomerRecord.ValidatedField> =
    FieldValidationError.empty<CustomerRecord.ValidatedField>();

  private formGroupValidationErrors: LocalFormGroupValidationErrors;

  private countryService: CountryService;
  complexName: boolean = false;

  genderTypes: GenderTypeItem[];
  genderText = '';

  childCustomerRecordList: CustomerRecordItem[] = [];

  config: ConfigModel = new ConfigModel();
  rightModel: RightModel = RightModel.empty();

  /**
   * Only for HTML binding!
   */
  get m(): CustomerRecordBase.Model {
    return this.model!;
  }

  constructor(private fb: FormBuilder,
              private uiRouter: UIRouter,
              private translateService: TranslateService,
              private customerRecordService: CustomerRecordService,
              private customerService: CustomerService,
              public configService: ConfigurationService,
              private companyMultiselectProvider: CompanyMultiselectProvider,
              private ledgerNumberMultiselectProvider: LedgerNumberMultiselectProvider,
              private rightService: RightService,
              private userGroupMultiselectProvider: UserGroupMultiselectProvider,
              private userMultiselectProvider: UserMultiselectProvider,
              private userMeService: UserMeService
  ) {
    this.model = new CustomerRecordBase.Model();
  }

  showDialogEmitter() {
    this.showDialog.emit(null);
  }

  getModel(): CustomerRecordBase.Model {
    return this.model;
  }

  loadCustomerRecord(args: CustomerRecordBase.Args) {
    // Parent CustomerRecord Load
    if (args.customerRecord && args.customerRecord.parentId) {
      this.customerRecordService.globalQuery(
        {
          customerRecordIdSet: Set.of(...[args.customerRecord.parentId]),
          orders: Set.of({field: CustomerRecord.OrderField.NAME, type: OrderType.ASC})
        }
      ).subscribe((result: QueryResult<CustomerRecord.CustomerRecord>) => {
        this.model.loadCustomerRecordItems(ImmutableCustomerRecord.fromCustomerRecordArray(result.items), args.customerRecord);
        this.loadModel(args);
      });
    }
    else {
      this.loadModel(args);
    }

    // Child CustomerRecord Load
    if (args.customerRecord && args.customerRecord.customerRecordId && this.readonly) {
      this.customerRecordService.globalQuery(
        {
          parentId: args.customerRecord.customerRecordId,
          orders: Set.of({field: CustomerRecord.OrderField.NAME, type: OrderType.ASC})
        }
      ).subscribe((result: QueryResult<CustomerRecord.CustomerRecord>) => {
        this.childCustomerRecordList = [];
        result.items.forEach((customer) => {
          if (customer) {
            const addr = !customer.postalAddress
              ? ''
              : Address.PostalAddressMapper.toString(customer.postalAddress, this.config.postalAddressFormat);
            const item = {
              id: customer.customerRecordId,
              text: customer.name,
              itemName: customer.name,
              customerRecord: customer,
              address: addr,
              disabled: customer.disabled
            };
            if (!this.customerRecord || (this.customerRecord && this.customerRecord.customerRecordId !== item.id)) {
              this.childCustomerRecordList.push(item);
            }
          }
        });
      })
    }
    this.loadConfig();
    this.fieldModel = CustomerRecordFieldModel.ofCustomer(args.customer);
    if (this.fieldModel.isManaged(CustomerRecordFieldType.COMPANY)) {
      this.loadCompanies(undefined, args.customerRecord ? args.customerRecord.companyId : undefined);
    }
    if (this.fieldModel.isManaged(CustomerRecordFieldType.PARENT_CUSTOMER_RECORD)) {
      this.loadParentsCustomerRecord(args.customerRecord && args.customerRecord.parentId ? [args.customerRecord.parentId] : []);
    }
  }

  loadModel(args: CustomerRecordBase.Args) {
    this.model.load(args);
    if (args.customerRecord) {
      this.loadCurrentUsers(args.customerRecord ? args.customerRecord.ownerUserIds : undefined);
      this.loadCurrentUserGroups(args.customerRecord ? args.customerRecord.ownerGroupIds : undefined);
    }
    else if (args.isCreate && args.customer.webOwnerTypes && args.customer.webOwnerTypes.size > 0) {
      this.loadDefaultOwners(args.customer.webOwnerTypes);
    }
    this.formGroup.controls['parentCustomerRecord'].updateValueAndValidity();
    if (this.readonly) {
      this.setGenderText();
    }
    this.customerRecord = args.customerRecord;
    this.countryItems = args.countryItems;
    this.complexName = args.customer.complexName;
    this.defaultSelectedCountryCode = args.defaultSelectedCountryCode;
  }

  loadCurrentUsers(ownerUserIds?: Set<number>) {
    if (ownerUserIds && ownerUserIds.size > 0) {
      this.userMultiselectProvider.getByIds(ownerUserIds.toArray()).subscribe(users => this.model.setUsers(users));
    }
  }

  loadUserSearch(searchValue?: string) {
    this.userMultiselectProvider.loadActive(searchValue).subscribe(users => this.model.setUserItems(users));
  }

  loadCurrentUserGroups(ownerGroupIds?: Set<number>) {
    if (ownerGroupIds && ownerGroupIds.size > 0) {
      this.userGroupMultiselectProvider.getByIds(ownerGroupIds.toArray()).subscribe(groups => this.model.setUserGroups(groups));
    }
  }

  loadUserGroupSearch(searchValue?: string) {
    this.userGroupMultiselectProvider.loadActive(searchValue).subscribe(groups => this.model.setUserGroupItems(groups));
  }

  private loadDefaultOwners(webOwnerTypes: Set<Customer.WebOwnerType>) {
    const userIds: number[] = [];
    const userGroupIds: number[] = [];
    const loadMe = webOwnerTypes.filter(t => t !== undefined
      && t.ownerType === 'CURRENT_USER').size > 0;
    const loadMyGroups = webOwnerTypes.filter(t => t !== undefined
      && t.ownerType === 'CURRENT_USER_GROUP').size > 0;
    let obs: Observable<User> = empty();
    if (loadMe || loadMyGroups) {
      obs = this.userMeService.loadMe();
    }
    webOwnerTypes.forEach(t => {
      if (t) {
        if (t.ownerType === 'PRESET_USER' && t.presetUserIds) {
          userIds.push(...t.presetUserIds.toArray());
        }
        if (t.ownerType === 'PRESET_USER_GROUP' && t.presetUserGroupIds) {
          userGroupIds.push(...t.presetUserGroupIds.toArray());
        }
      }
    });
    obs.subscribe({
      next: (me) => {
        if (loadMe) {
          userIds.push(me.id);
        }
        if (loadMyGroups) {
          userGroupIds.push(...me.user_group_ids);
        }
      },
      complete: () => {
        this.loadCurrentUsers(Set.of(...userIds));
        this.loadCurrentUserGroups(Set.of(...userGroupIds));
      }
    });

  }

  loadParentsCustomerRecord(custIds: number[], q?: any) {
    this.queryCustomers(custIds, q).subscribe((customers: QueryResult<CustomerRecord.CustomerRecord>) => {
      this.customers = [];
      customers.items.forEach((customer) => {
        if (customer) {
          const addr = !customer.postalAddress
            ? ''
            : Address.PostalAddressMapper.toString(customer.postalAddress, this.config.postalAddressFormat);
          const item = {
            id: customer.customerRecordId,
            text: customer.name,
            itemName: customer.name + ' (' + addr + ')',
            customerRecord: customer,
            address: addr
          };
          if (!this.customerRecord || (this.customerRecord && this.customerRecord.customerRecordId !== item.id)) {
            this.customers.push(item);
          }
        }
      });
    });
  }

  private loadConfig() {
    this.config = this.configService.getConfigurationModel();
  }

  loadCompanies(q?: string, companyId?: number) {
    this.companyMultiselectProvider.searchCompanies(q).subscribe(result => {
      this.companies = result;
    });
    if (companyId) {
      this.companyMultiselectProvider.getById(companyId).subscribe(result => {
        this.model.company = [];
        this.model.company.push(result);
      });
    }
  }

  loadLedgerNumbers(q?: string) {
    if (this.rightModel.ledgerNumberRead.hasRight()) {
      this.ledgerNumberMultiselectProvider.loadActive(q).subscribe(result => {
        this.ledgerNumbers = result;
      });
    }
  }

  onCustomerChanged() {

  }

  private queryCustomers(custIds: number[], q?: any):
    Observable<QueryResult<CustomerRecord.CustomerRecord>> {
    return this.customerRecordService.globalQuery({
      withFormRecord: false,
      customerRecordIdSet: Set.of(...custIds),
      disabled: this.readonly || custIds.length === 0 ? false : undefined,
      fields: Set.of('id', 'name', 'postal_address'),
      orders: Set.of({type: OrderType.ASC, field: CustomerRecord.OrderField.NAME}),
      paging: {
        numberOfItems: UiConstants.autocompletePageSize,
        pageNumber: 1
      },
      name: q ? Strings.undefinedOrNonEmpty(q.target.value) : undefined,
      noProgressBar: true
    });
  }

  onFieldValidationError(fieldErrors: FieldValidationError<CustomerRecord.ValidatedField>) {
    this.fieldErrors = fieldErrors;
  }

  ngOnInit(): void {
    this.formGroup = this.createFormGroup(this.fb);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(
      this.createForwardingHtmlForm(),
      this.formGroup
    );
    this.loadGenderTypes();
    this.userDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .text(StringKey.COMMON_PLACEHOLDER_USER_NOT_SELECTED_YET)
      .selectAllText(StringKey.COMMON_PLACEHOLDER_SELECT_ALL_USER)
      .unSelectAllText(StringKey.COMMON_PLACEHOLDER_SELECT_ALL_USER)
      .noDataLabel(StringKey.COMMON_PLACEHOLDER_EMPTY_USER_LIST)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .remoteSearch(true)
      .build();
    this.userGroupDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .text(StringKey.COMMON_PLACEHOLDER_USER_GROUP_NOT_SELECTED_YET)
      .selectAllText(StringKey.COMMON_PLACEHOLDER_SELECT_ALL_USER_GROUP)
      .unSelectAllText(StringKey.COMMON_PLACEHOLDER_SELECT_ALL_USER_GROUP)
      .noDataLabel(StringKey.COMMON_PLACEHOLDER_EMPTY_USER_GROUP_LIST)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .remoteSearch(true)
      .build();
    this.customerRecordParentDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .text(StringKey.COMMON_PLACEHOLDER_CUSTOMER_RECORD_NOT_SELECTED_YET)
      .noDataLabel(StringKey.COMMON_PLACEHOLDER_EMPTY_CUSTOMER_RECORD_LIST)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
    this.singleRemoteDropdownSettings = Angular2Multiselects.REMOTE_SINGLE_SELECT;
    this.customerRecordChildrenDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .build();
    this.loadRightModels();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  private loadGenderTypes() {
    this.genderTypes = [];
    genderTypes.forEach((type) => {
      const item = {
        id: type.type,
        text: '....'
      };
      this.genderTypes.push(item);
      this.translateService.get(type.stringKey).subscribe((text: string) => {
        item.text = text;
      })
    });
  }

  setGenderText() {
    let text = '';
    this.genderTypes.forEach((state: GenderTypeItem) => {
      if (state.id === this.m.gender) {
        text = state.text
      }
    });
    this.genderText = text;
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    if (!formControlName) {
      this.formGroup.controls['company'].updateValueAndValidity();
      if (this.billingInfoView && this.billingInfoView.hasLocalFieldError()) {
        return true;
      }
      if (this.postalAddressView && this.postalAddressView.hasLocalFieldError()) {
        return true;
      }
      if (this.notificationAddressView && this.notificationAddressView.hasLocalFieldError()) {
        return true;
      }
      if (!AddressModel.EmailAddressModel.isValid(this.model.emailAddresses)) {
        return true;
      }
      if (this.fieldModel.isRequired(CustomerRecordFieldType.EMAIL_ADDRESSES) && this.model.emailAddresses.length === 0) {
        return true;
      }
      if (!AddressModel.PhoneNumberModel.isValid(this.model.phoneNumbers)) {
        return true;
      }
      if (this.fieldModel.isRequired(CustomerRecordFieldType.PHONE_NUMBERS) && this.model.phoneNumbers.length === 0) {
        return true;
      }
    }
    if (formControlName === 'emailAddresses') {
      return this.fieldModel.isRequired(CustomerRecordFieldType.EMAIL_ADDRESSES) && this.model.emailAddresses.length === 0;
    }
    if (formControlName === 'phoneNumbers') {
      return this.fieldModel.isRequired(CustomerRecordFieldType.PHONE_NUMBERS) && this.model.phoneNumbers.length === 0;
    }
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  hasLocalFormError(errorCode: string): boolean {
    return this.formGroupValidationErrors.hasFormError(errorCode);
  }

  hasFieldError(field: CustomerRecord.ValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: CustomerRecord.ValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: CustomerRecord.ValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  private createForwardingHtmlForm() {
    return new ForwardingNgFormRef({
      formFn: () => {
        return this.form;
      }
    });
  }

  private createFormGroup(fb: FormBuilder): FormGroup {
    return fb.group(
      {
        name: fb.control(
          {value: this.model.name},
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => this.complexName
            })
          ]
        ),
        firstName: fb.control(
          {value: this.model.firstName},
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => !this.complexName
            })
          ]
        ),
        lastName: fb.control(
          {value: this.model.lastName},
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => !this.complexName
            })
          ]
        ),
        externalId: fb.control(
          {value: this.model.externalId},
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => !this.externalIdRequired
            })
          ]
        ),
        user: fb.control(
          {value: this.model.users},
          [
            AppValidators.validateEnabledItems
          ]
        ),
        parentCustomerRecord: fb.control(
          {value: this.model.customerRecordParents},
          [
            AppValidators.validateEnabledItems,
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => !this.fieldModel.isRequired(CustomerRecordFieldType.PARENT_CUSTOMER_RECORD)
            })

          ]
        ),
        userGroup: fb.control(
          {value: this.model.userGroups},
          [
            AppValidators.validateEnabledItems
          ]
        ),
        company: fb.control(
          {value: this.model.company},
          [
            AppValidators.tempValidator({
              validator: AppValidators.validateEnabledItems,
              disabled: () => !this.fieldModel.isManaged(CustomerRecordFieldType.COMPANY)
            }),
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => !this.fieldModel.isRequired(CustomerRecordFieldType.COMPANY)
            })
          ]
        ),
        webAddress: fb.control(
          {value: this.model.webAddress},
          [
            AppValidators.tempValidator({
              validator: AppValidators.validateUrl,
              disabled: () => !this.fieldModel.isManaged(CustomerRecordFieldType.WEB_ADDRESS)
            }),
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => !this.fieldModel.isRequired(CustomerRecordFieldType.WEB_ADDRESS)
            })
          ]
        ),
        mainContractNumber: fb.control(
          '',
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => !this.fieldModel.isRequired(CustomerRecordFieldType.MAIN_CONTRACT_NUMBER)
            })
          ]
        ),
        taxNumber: fb.control(
          '',
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => !this.fieldModel.isRequired(CustomerRecordFieldType.TAX_NUMBER)
            })
          ]
        ),
        euTaxNumber: fb.control(
          '',
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => !this.fieldModel.isRequired(CustomerRecordFieldType.EU_TAX_NUMBER)
            }),
            AppValidators.validateEuVatNumber
          ]
        ),
        ledgerNumber: fb.control(
          '',
          [
            AppValidators.tempValidator({
              validator: AppValidators.validateEnabledItems,
              disabled: () => !this.fieldModel.isManaged(CustomerRecordFieldType.LEDGER_NUMBER)
            }),
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => !this.fieldModel.isRequired(CustomerRecordFieldType.LEDGER_NUMBER)
            })
          ]
        ),
        placeOfBirth: fb.control(
          {value: this.model.placeOfBirth},
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => !this.fieldModel.isRequired(CustomerRecordFieldType.PLACE_OF_BIRTH)
            })
          ]
        ),
        dateOfBirth: fb.control(
          {value: this.model.dateOfBirth},
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => !this.fieldModel.isRequired(CustomerRecordFieldType.DATE_OF_BIRTH)
            })
          ]
        ),
        birthName: fb.control(
          {value: this.model.birthName},
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => !this.fieldModel.isRequired(CustomerRecordFieldType.BIRTH_NAME)
            })
          ]
        ),
        gender: fb.control(
          {value: this.model.gender},
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => !this.fieldModel.isRequired(CustomerRecordFieldType.GENDER)
            })
          ]
        ),
        mothersName: fb.control(
          {value: this.model.mothersName},
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => !this.fieldModel.isRequired(CustomerRecordFieldType.MOTHERS_NAME)
            })
          ]
        ),
        identityDocumentType: fb.control(
          {value: this.model.identityDocumentType},
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => !this.fieldModel.isRequired(CustomerRecordFieldType.IDENTITY_DOCUMENT_TYPE)
            })
          ]
        ),
        identityDocumentNumber: fb.control(
          {value: this.model.identityDocumentNumber},
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => !this.fieldModel.isRequired(CustomerRecordFieldType.IDENTITY_DOCUMENT_NUMBER)
            })
          ]
        ),
        invoiceDeadlineAdditionalDays: fb.control(
          '',
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => !this.fieldModel.isRequired(CustomerRecordFieldType.INVOICE_DEADLINE_ADDITIONAL_DAYS)
            })
          ]
        ),
        taskRecordDeadlineAdditionalDays: fb.control(
          '',
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => !this.fieldModel.isRequired(CustomerRecordFieldType.TASK_RECORD_DEADLINE_ADDITIONAL_DAYS)
            })
          ]
        ),
        comment: fb.control(
          '',
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => !this.fieldModel.isRequired(CustomerRecordFieldType.COMMENT)
            })
          ]
        ),
        position: fb.control(
          '',
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => !this.fieldModel.isRequired(CustomerRecordFieldType.POSITION)
            })
          ]
        ),
      }
    );
  }

  onNotificationAddressCheckboxFalse(event: any) {
    this.model.notificationAddressEqPostal = false;
  }

  onNotificationAddressCheckboxTrue(event: any) {
    this.model.notificationAddressEqPostal = true;
  }

  onSaveButtonClicked(navigateOnSubmit: boolean) {
    this.submitted.emit(navigateOnSubmit);
  }

  postalAddressPresent(): boolean {
    if (AddressModel.PostalAddressModelType.NONE !== this.model!.postalAddress.type) {
      return true;
    }
    return false;
  }

  notificationAddressPresent(): boolean {
    if (AddressModel.PostalAddressModelType.NONE !== this.model!.notificationAddress.type) {
      return true;
    }
    return false;
  }

  isPersonType(): boolean {
    if (this.customerItem && this.customerItem.type === 'PERSON') {
      return true;
    }
    return false;
  }

  navigateToCustomerRecordDetail(item: CustomerRecordItem) {
    this.uiRouter.stateService.go(StateName.CUSTOMER_RECORD_DETAIL,
      {customerId: item.customerRecord!.customerId, customerRecordId: item.customerRecord!.customerRecordId});
  }

  getFieldModel(): CustomerRecordFieldModel {
    return this.fieldModel;
  }

  getPersonalDataCardVisible() {
    return this.fieldModel.isManaged(CustomerRecordFieldType.PLACE_OF_BIRTH) ||
    this.fieldModel.isManaged(CustomerRecordFieldType.DATE_OF_BIRTH) ||
    this.fieldModel.isManaged(CustomerRecordFieldType.BIRTH_NAME) ||
    this.fieldModel.isManaged(CustomerRecordFieldType.GENDER) ||
    this.fieldModel.isManaged(CustomerRecordFieldType.MOTHERS_NAME) ||
    this.fieldModel.isManaged(CustomerRecordFieldType.IDENTITY_DOCUMENT_TYPE) ||
    this.fieldModel.isManaged(CustomerRecordFieldType.IDENTITY_DOCUMENT_NUMBER);
  }

  getOtherDataCardVisible() {
    return this.fieldModel.isManaged(CustomerRecordFieldType.INVOICE_DEADLINE_ADDITIONAL_DAYS) ||
      this.fieldModel.isManaged(CustomerRecordFieldType.TASK_RECORD_DEADLINE_ADDITIONAL_DAYS);
  }
}

export class GenderTypeItem extends OptionItem<GenderType> {
}
