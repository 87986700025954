/* eslint-disable */
import { Customer } from '../lib/customer/customer.service';
import { MultiselectOptionItem, OptionItem } from './core-utils';
import { Icon } from '../lib/task/icon.service';
import { UserDisabled } from '../lib/user.service';
import { DisabledItem } from './search-utils';
import { CustomerRecordFieldModel, CustomerRecordFieldType } from './customer-record-utils';
import { Set } from 'immutable';
import CustomerDisabled = Customer.CustomerDisabled;
import CustomerTypeForSearch = Customer.CustomerTypeForSearch;
import CustomerType = Customer.CustomerType;
import { GrantedPermissionSet, GrantedPermissionSetResolver } from '../lib/right.service';
import { OperationRights } from '../app.right-definitions';

/* eslint-enable */

export class CustomerEditModel {

  name: string = '';
  complexName: boolean = false;
  description: string = '';
  externalId: string = '';
  defaultLedgerNumber: MultiselectOptionItem<number>[] = [];
  type?: CustomerTypeItem = undefined;
  mobileOwnerType?: CustomerOwnerTypeItem = undefined;
  webOwnerTypes: MultiselectOptionItem<Customer.CustomerOwnerType>[] = [];
  webOwnerPresetUsers: MultiselectOptionItem<number>[] = [];
  webOwnerPresetUserGroups: MultiselectOptionItem<number>[] = [];
  icon?: Icon.Icon = {
    iconType: 'ICON_SET',
    iconSet: {
      setCode: 'google_material',
      setClass: 'material-icons',
      iconCode: 'person',
      usageType: 'TEXT'
    }
  };
  useDefaultManagedFields: boolean = true;
  managedFields: MultiselectOptionItem<CustomerRecordFieldType>[] = [];
  requiredFields: MultiselectOptionItem<CustomerRecordFieldType>[] = [];
  explicitOrderNumber?: number;
  contactPerson: boolean = false;

  userGroups: MultiselectOptionItem<number>[] = [];

  getCustomerType(): CustomerType | null | undefined {
    return this.type ? this.type.id : undefined;
  }

  getUserGroupIds(): number[] {
    return this.userGroups.map((userGroup) => {
      return userGroup.id!;
    });
  }

  get defaultLedgerNumberId(): number | undefined {
    return this.defaultLedgerNumber.length > 0 ? this.defaultLedgerNumber[0].id : undefined;
  }

  get presetWebUsersEnabled(): boolean {
    return this.webOwnerTypes.findIndex(o => o.id === 'PRESET_USER') > -1;
  }

  get presetWebUserGroupsEnabled(): boolean {
    return this.webOwnerTypes.findIndex(o => o.id === 'PRESET_USER_GROUP') > -1;
  }

  mapWebOwnerTypes(): Set<Customer.WebOwnerType> | undefined {
    if (this.webOwnerTypes.length === 0) {
      return undefined;
    }
    const ret: Customer.WebOwnerType[] = [];
    this.webOwnerTypes.forEach(w => {
      switch (w.id) {
        case 'CURRENT_USER':
        case 'CURRENT_USER_GROUP':
          ret.push({ownerType: w.id});
          break;
        case 'PRESET_USER':
          ret.push({ownerType: w.id, presetUserIds: Set.of(...this.webOwnerPresetUsers.map(u => u.id))});
          break;
        case 'PRESET_USER_GROUP':
          ret.push({ownerType: w.id, presetUserGroupIds: Set.of(...this.webOwnerPresetUserGroups.map(u => u.id))});
          break;

      }
    });
    return ret.length > 0 ? Set.of(...ret) : undefined;
  }

}

export class CustomerSearchModel {
  id: string;
  name: string;
  type?: CustomerTypeForSearchItem;
  externalId: string;
  disabled?: DisabledItem;

  public isEmpty(): boolean {
    return this.id.length === 0
      && this.name.length === 0
      && this.externalId.length === 0
      && (this.disabled ? this.disabled.id === DisabledItem.active().id : true)
      && OptionItem.idOrUndefined(this.type) === undefined
      ;
  }
}

export class CustomerTypeItem extends OptionItem<Customer.CustomerType> {
  key: string = '';
}

export class CustomerOwnerTypeItem extends OptionItem<Customer.CustomerOwnerType> {
  key: string = '';
}

export class CustomerTypeItemForSearch extends OptionItem<Customer.CustomerTypeForSearch> {
}

export class CustomerCreateModel extends CustomerEditModel {
}

export class CustomerItem extends OptionItem<number> {
  fieldModel?: CustomerRecordFieldModel;
  complexNameRequired?: boolean;
  type?: CustomerType;
  webOwnerTypes?: Set<Customer.WebOwnerType>;
}

export class CustomerDisabledItem extends OptionItem<CustomerDisabled> {
}

export class CustomerTypeForSearchItem extends OptionItem<CustomerTypeForSearch> {
}

export class CustomerDisabledItems {
  public static readonly values: UserDisabled[] = ['ALL', 'ACTIVE', 'INACTIVE'];
}

export class CustomerTypesForSearchItems {
  public static readonly values: CustomerTypeForSearch[] = ['ALL', 'PERSON', 'COMPANY'];
}

export class CustomerRightModel {

  public readonly customerRecordCreate: GrantedPermissionSet;
  public readonly update: GrantedPermissionSet;
  public readonly disable: GrantedPermissionSet;

  constructor(resolver: GrantedPermissionSetResolver) {
    this.update = resolver.of(OperationRights.CUSTOMER_UPDATE);
    this.disable = resolver.of(OperationRights.CUSTOMER_DISABLE);
    this.customerRecordCreate = resolver.of(OperationRights.CUSTOMER_RECORD_CREATE);
  }
}
