import { Component, Input, OnInit } from '@angular/core';
import { MplTracking, MplTrackingService } from '../../../lib/mpl-tracking/mpl-tracking.service';
import { FilterField } from '../../../lib/query/filterfields';
import { CriteriaBuilder } from '../../../util/model-utils';
import { OrderFieldFunction, OrderFieldModel } from '../../../util/core-utils';
import { OrderField } from '../../../lib/query/orderfields';
import { OrderType } from '../../../lib/util/services';
import { WeightFactory } from '../../../util/weight-utils';
import { OffsetDateTime } from '../../../lib/util/dates';

@Component({
  selector: 'app-order-mpl-statistics',
  templateUrl: './order-mpl-statistics.component.html',
  styleUrls: ['./order-mpl-statistics.component.scss']
})
export class OrderMplStatisticsComponent implements OnInit {

  MplTracking = MplTracking;
  WeightFactory = WeightFactory;

  @Input()
  orderId: number;

  @Input()
  lastSyncTime?: OffsetDateTime;

  queryModel: OrderFieldModel<OrderField.MplTracking>
    = new OrderFieldModel(MplTracking.OrderFunctions.TIME, OrderType.DESC);

  trackingData: MplTracking.MplTracking[];

  get baseDataModel(): MplTracking.MplTracking | undefined {
    return this.trackingData && this.trackingData.length > 0 ? this.trackingData[0] : undefined;
  }

  constructor(
    private mplTrackingService: MplTrackingService
  ) { }

  ngOnInit() {
    this.loadTracking();
  }

  loadTracking() {
    this.mplTrackingService.query({
      order: this.queryModel.createOrderFunction(),
      filter: this.createFilter()
    }).subscribe((result: MplTracking.MplTracking[]) => {
      this.trackingData = result;
    });
  }

  private createFilter() {
    return (f: FilterField.MplTracking) => CriteriaBuilder.builder()
      .addNumber((orderId) => f.order.id.eq(orderId), this.orderId)
      .build();
  }

  orderBy(field: OrderFieldFunction<OrderField.MplTracking>) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadTracking();
  }
}
