/* eslint-disable */
import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {BreadcrumbParent} from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import {TranslateService} from '@ngx-translate/core';
import {
  StockItem,
  StockItemDisposeFieldErrorMap,
  StockItemMoveFieldErrorMap,
  StockItemService,
  StockItemType,
  stockItemTypes
} from '../../../lib/stock/stock-item.service';
import {Transition, UIRouter} from '@uirouter/angular';
import {StateName} from '../../../app.state-names';
import {Currency, CurrencyService} from '../../../lib/currency.service';
import {QueryResult, ResourceQueryResult} from '../../../lib/util/services';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {MultiselectOptionItem, OptionItem, UiConstants} from '../../../util/core-utils';
import {Strings} from '../../../lib/util/strings';
import {Stock, StockService} from '../../../lib/stock/stock.service';
import {FieldError, FieldErrors, ObservableErrorResourceParser} from '../../../lib/util/errors';
import {Angular2Multiselects} from '../../../util/multiselect';
import {StringKey} from '../../../app.string-keys';
import {InputMask} from '../../../util/input-masks';
import {Models} from '../../../util/model-utils';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {WeightFactory} from '../../../util/weight-utils';
import {AppValidators} from '../../../util/app-validators';
import {RightModel} from '../../../app.rights';
import {RightResolver, RightService} from '../../../lib/right.service';
import {StockLogListType} from '../../stock-log/stock-log-list/stock-log-list.component';
import {DomSanitizer, SafeHtml, SafeStyle} from '@angular/platform-browser';
import {DownloadedFile} from '../../../lib/util/downloaded-files';
import {saveAs} from 'file-saver';
import {
  StockItemCategoryMultiselectProvider
} from '../../../lib/stock-item-category/stock-item-category-multiselect.provider';
import {CompanyMultiselectProvider} from '../../../lib/company/company-multiselect.provider';
import {StockItemUtils} from '../../../util/stock/stock-item-utils';
import {StockItemStockListComponent} from '../stock-item-stock-list/stock-item-stock-list.component';

/* eslint-enable */

@Component({
  selector: 'app-stock-item-detail',
  templateUrl: './stock-item-detail.component.html',
  styleUrls: ['./stock-item-detail.component.scss']
})
export class StockItemDetailComponent implements OnInit, AfterViewInit {
  UiConstants = UiConstants;
  WeightFactory = WeightFactory;
  Models = Models;
  StockLogListType = StockLogListType;
  StockItemUtils = StockItemUtils;

  stockMoveForm: FormGroup;
  stockDisposeForm: FormGroup;

  @ViewChild('stockItemMoveDialog', {static: true}) stockItemMoveDialog: ModalDirective;
  @ViewChild('stockItemDisposeDialog', {static: true}) stockItemDisposeDialog: ModalDirective;
  @ViewChild(StockItemStockListComponent) stockItemStockListComponent: StockItemStockListComponent;
  stockItemMoveDialogVisible = false;
  stockItemDisposeDialogVisible = false;

  selectedSource?: StockOptionItem;
  selectedDestination?: StockOptionItem;
  amountToMove = '';
  note = '';

  allStocks: StockOptionItem[] = [];
  nonWasteStocks: StockOptionItem[] = [];
  wasteStocks: StockOptionItem[] = [];
  stockItemTypes: OptionItem<StockItemType>[] = [];

  breadcrumbSelf: string;
  breadcrumbParents: BreadcrumbParent[] = [];
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  stockItemId: number;
  stockItem: StockItem;
  selectedCompany: MultiselectOptionItem<number>[] = [];
  selectedCategories: MultiselectOptionItem<number>[] = [];
  currencies: Currency.Currency[] = [];

  sourceDropdownSettings: Angular2Multiselects.Settings;
  destinationDropdownSettings: Angular2Multiselects.Settings;
  InputMask = InputMask;

  moveFieldErrors: StockItemMoveFieldErrorMap;
  disposeFieldErrors: StockItemDisposeFieldErrorMap;

  rightModel: RightModel = RightModel.empty();

  dropdownSettings: Angular2Multiselects.Settings;

  pictureLoaded = false;
  pictureSrc: SafeStyle;

  get totalAmountOfProductsInStock(): number | undefined {
    return this.stockItemStockListComponent ? this.stockItemStockListComponent.totalAmountOfProductsInStock : undefined;
  }

  get intakeNetPriceSum(): number | undefined {
    return this.stockItemStockListComponent ? this.stockItemStockListComponent.intakeNetPriceSum : undefined;
  }

  get intakeNetPriceAvg(): number | undefined {
    return this.stockItemStockListComponent ? this.stockItemStockListComponent.intakeNetPriceAvg : undefined;
  }

  get containingStocks(): StockOptionItem[] {
    return this.stockItemStockListComponent.containingStocks;
  }

  get packageEnabled(): boolean {
    return this.m && this.m.package_data !== undefined;
  }

  constructor(private stockItemService: StockItemService,
              private stockService: StockService,
              private translateService: TranslateService,
              private currencyService: CurrencyService,
              private uiRouter: UIRouter,
              private rightService: RightService,
              private transition: Transition,
              private formBuilder: FormBuilder,
              private stockItemCategoryMultiselectProvider: StockItemCategoryMultiselectProvider,
              private companyMultiselectProvider: CompanyMultiselectProvider,
              private sanitizer: DomSanitizer) {
    this.stockItemId = this.transition.params().id;
    this.stockMoveForm = formBuilder.group({
      moveSource: [[], [Validators.required, AppValidators.validateEnabledItems]],
      moveDestination: [[], Validators.required],
      moveAmount: ['', [Validators.required, Validators.min(1)]],
    }, {validator: validateMoveSources});
    this.stockDisposeForm = formBuilder.group({
      disposeSource: [[], Validators.required],
      disposeDestination: [[], Validators.required],
      disposeAmount: ['', [Validators.required, Validators.min(1)]],
    });
    this.moveFieldErrors = {};
    this.disposeFieldErrors = {};
  }

  ngOnInit() {
    this.initBreadcrumb();
    this.initDropdownSettings();
    this.loadRightModels();
  }

  ngAfterViewInit(): void {
    this.loadModel();
    this.loadStockList();
    this.loadCurrencies();
    this.loadStockItemTypes();
    this.initDropDown();
  }

  get m(): StockItem {
    return this.stockItem;
  }

  initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  private loadModel() {
    this.stockItemService.get({id: this.stockItemId}).subscribe((stockItem: StockItem) => {
      this.stockItem = stockItem;
      this.breadcrumbSelf = stockItem.name;
      if (stockItem.picture) {
        this.downloadPictureForPreview();
      }
      this.loadCompany();
      this.loadCategories();
    });
  }

  private loadCompany() {
    if (this.stockItem.company) {
      this.companyMultiselectProvider.getById(this.stockItem.company.id)
        .subscribe((company: MultiselectOptionItem<number>) => {
          this.selectedCompany.push(company);
        });
    }
  }

  private loadCategories() {
    if (this.stockItem.category_ids && this.stockItem.category_ids.length > 0) {
      this.stockItemCategoryMultiselectProvider.getByIds(this.stockItem.category_ids)
        .subscribe((result: MultiselectOptionItem<number>[]) => {
          this.selectedCategories = result.map(r =>
            ({itemName: `${r.itemName} (${r.itemSubtitle})`, id: r.id}));
        })
    }
  }

  private loadStockItemTypes() {
    this.stockItemTypes = [];
    stockItemTypes.forEach(t => {
      const item: OptionItem<StockItemType> = {
        id: t.type,
        text: '....'
      };
      this.stockItemTypes.push(item);
      this.translateService.get(t.stringKey).subscribe((text: string) => {
        item.text = text;
      });
    });
  }

  getStockItemTypeText(type: StockItemType): string {
    const item = this.stockItemTypes.find(t => t.id === type);
    return item ? item.text : '';
  }

  initBreadcrumb() {
    if (this.uiRouter.stateService.current.name === StateName.STOCK_EDIT_WITH_STOCK_ITEM_DETAIL) {
      this.translateService.get('MENU_NAVBAR_STOCK').subscribe(
        (result: string) => {
          this.breadcrumbParents.push({name: result, uiSref: StateName.STOCK_LIST});
        }
      );
      this.translateService.get('STOCK_PANEL_HEADING_EDIT').subscribe(
        (result: string) => {
          this.breadcrumbParents.push({
            name: result,
            uiSref: StateName.STOCK_EDIT,
            uiParam: {id: this.transition.params().stockId}
          });
        }
      );
    } else if (this.uiRouter.stateService.current.name === StateName.STOCK_DETAIL_WITH_STOCK_ITEM_DETAIL) {
      this.translateService.get('MENU_NAVBAR_STOCK').subscribe(
        (result: string) => {
          this.breadcrumbParents.push({name: result, uiSref: StateName.STOCK_LIST});
        }
      );
      this.translateService.get('STOCK_PANEL_HEADING_DETAIL').subscribe(
        (result: string) => {
          this.breadcrumbParents.push({
            name: result,
            uiSref: StateName.STOCK_DETAIL,
            uiParam: {id: this.transition.params().stockId}
          });
        }
      );
    } else {
      this.translateService.get('STOCK_ITEM_PANEL_HEADING_LIST').subscribe(
        (result: string) => {
          this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
            (result: string) => {
              this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
            }
          );
          this.breadcrumbParents.push({name: result, uiSref: StateName.STOCK_ITEM_LIST});
        }
      );
    }
  }

  initDropDown() {
    this.sourceDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(false)
      .enableCheckAll(false)
      .labelKey(OptionItem.KEY_TEXT)
      .text(StringKey.STOCK_ITEM_MOVE_SOURCE)
      .build();
    this.destinationDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(false)
      .enableCheckAll(false)
      .labelKey(OptionItem.KEY_TEXT)
      .text(StringKey.STOCK_ITEM_MOVE_DESTINATION)
      .build();
  }

  private loadStockList() {
    this.stockService.query({}).subscribe((result: ResourceQueryResult<Stock>) => {
        result.items.forEach((stock) => {
          const item = {
            id: stock.id,
            text: stock.name
          };
          this.allStocks.push(item);
          stock.waste ? this.wasteStocks.push(item) : this.nonWasteStocks.push(item);
        })
      }
    );
  }

  private loadCurrencies() {
    this.currencies = [];
    this.currencyService.query({}).subscribe((result: QueryResult<Currency.Currency>) => {
      result.items.forEach(c => {
        if (c) {
          this.currencies.push(c);
        }
      });
    });
  }

  getCurrencyName(currencyCode: string) {
    const cc = this.currencies.find(c => c.currencyCode === currencyCode);
    return cc ? cc.localizedName : currencyCode;
  }

  getCurrency(currencyCode: string) {
    const cc = this.currencies.find(c => c.currencyCode === currencyCode);
    return cc!;
  }

  showStockItemMoveDialog() {
    this.stockItemMoveDialogVisible = true;
    this.stockItemMoveDialog.show();
  }

  closeStockItemMoveDialog() {
    this.stockItemMoveDialogVisible = false;
    this.stockItemMoveDialog.hide();
  }

  showStockItemDisposeDialog() {
    this.stockItemDisposeDialogVisible = true;
    this.stockItemDisposeDialog.show();
  }

  closeStockItemDisposeDialog() {
    this.stockItemDisposeDialogVisible = false;
    this.stockItemDisposeDialog.hide();
  }

  onModalHidden() {
    this.selectedSource = undefined;
    this.selectedDestination = undefined;
    this.amountToMove = '';
    this.note = '';
    this.stockMoveForm.reset();
    this.stockDisposeForm.reset();
  }

  moveStockItem() {
    if (!this.stockMoveForm.valid) {
      this.stockMoveForm.get('moveSource')!.markAsTouched();
      this.stockMoveForm.get('moveDestination')!.markAsTouched();
      this.stockMoveForm.get('moveAmount')!.markAsTouched();
      return;
    } else {
      this.stockItemService.move({
        id: this.stockItemId,
        source_stock_id: this.selectedSource![0].id,
        destination_stock_id: this.selectedDestination![0].id,
        amount: Models.parseNumber(this.amountToMove) ? Models.parseNumber(this.amountToMove)! : 0,
        note: Strings.undefinedOrNonEmpty(this.note),
      }).subscribe(() => {
          this.closeStockItemMoveDialog();
          this.stockItemStockListComponent.reload();
        },
        (error: any) => {
          const res = ObservableErrorResourceParser.parseError(error);
          this.moveFieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
        });
    }
  }

  disposeStockItem() {
    if (!this.stockDisposeForm.valid) {
      this.stockDisposeForm.get('disposeSource')!.markAsTouched();
      this.stockDisposeForm.get('disposeDestination')!.markAsTouched();
      this.stockDisposeForm.get('disposeAmount')!.markAsTouched();
      return;
    }
    this.stockItemService.dispose({
      id: this.stockItemId,
      source_stock_id: this.selectedSource![0].id,
      destination_stock_id: this.selectedDestination![0].id,
      amount: Models.parseNumber(this.amountToMove) ? Models.parseNumber(this.amountToMove)! : 0,
      note: Strings.undefinedOrNonEmpty(this.note),
    }).subscribe(() => {
        this.closeStockItemDisposeDialog();
        this.stockItemStockListComponent.reload();
      },
      (error: any) => {
        const res = ObservableErrorResourceParser.parseError(error);
        this.disposeFieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
      });
  }

  removeMoveFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.moveFieldErrors, fieldError);
  }

  removeDisposeFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.disposeFieldErrors, fieldError);
  }

  getQRCodeUrl(productCode: string): string {
    return 'https://chart.googleapis.com/chart?cht=qr&chl=' + productCode + '&chs=180x180&choe=UTF-8&chld=L%7C2';
  }

  sanitizeHtml(unsafeHtml: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(unsafeHtml);
  }

  decodeHTMLEntities(text) {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = text;
    return textArea.value;
  }

  getCreatorUserData() {
    if (!this.stockItem.creator_user) {
      return '';
    }
    return `${this.stockItem.creator_user.person_name} (${this.stockItem.creator_user.user_name})`;
  }

  private downloadPictureForPreview() {
    this.pictureLoaded = false;
    this.stockItemService.downloadPicture({
      id: this.stockItemId,
    }).subscribe((res: DownloadedFile) => {
        this.pictureSrc = this.sanitizer.bypassSecurityTrustStyle(`url(${URL.createObjectURL(res.getBlob())})`);
        this.pictureLoaded = true;
      }
    );
  }

  downloadPicture() {
    this.stockItemService.downloadPicture({
      id: this.stockItemId
    }).subscribe((res: DownloadedFile) => {
      saveAs(res.getBlob(), res.getFileName(this.m.name + '.png'));
    });
  }

  isCreatedOnMobile() {
    return this.stockItem.created_on === 'MOBILE';
  }

  navigateToCategory(category: MultiselectOptionItem<number>) {
    if (this.rightModel.stockItemCategoryRead.hasRight()) {
      this.uiRouter.stateService.go(StateName.STOCK_ITEM_CATEGORY_DETAIL, {id: category.id});
    }

  }
}

export class StockOptionItem extends OptionItem<number> {
}

export function validateMoveSources(form: AbstractControl): { [key: string]: boolean } | null {
  if (form.get('moveSource') !== null && form.get('moveDestination') !== null) {
    if (form.get('moveSource')!.value
      && form.get('moveDestination')!.value
      && form.get('moveSource')!.value[0]
      && form.get('moveDestination')!.value[0]
      && form.get('moveSource')!.value[0].id === form.get('moveDestination')!.value[0].id) {
      return {'equals': true};
    }
  }
  return null;
}
