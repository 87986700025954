<div class="modal-content">
  <div class="modal-header">
    <p class="modal-title"><strong>{{'COMMON_BUTTON_IMPORT' | translate}}</strong></p>
    <button type="button" class="close" (click)="closeDialog()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form [formGroup]='formGroup' (ngSubmit)="importTask()" #f="ngForm">
    <div class="modal-body">
      <mat-dialog-content>
        <div class="col-sm-12 p-0">
          <app-file-upload
            [uploadPath]="''"
            [uploadOnDrop]="false"
            (onFileDropped)="onImportableFileDropped($event)"
          (onFileRemoved)="onFileRemoved()">
          </app-file-upload>
        </div>
        <div class="col-sm-12 mt-1 p-0">
          <div class="alert alert-success" *ngIf="importResult === 'SUCCESS'">
            <h4 class="alert-title">{{'ALERT_TEXT_UPLOAD_SUCCESSFUL' | translate}}</h4>
            <p>{{'ALERT_TEXT_SUCCESSFULLY_UPLOADED' | translate}}</p>
          </div>
          <div class="alert alert-danger" *ngIf="importResult === 'ERROR'">
            <h4 class="alert-title">{{'ALERT_TEXT_UPLOAD_FAILED' | translate}}</h4>
            <p *ngIf="uploadGlobalErrors" [innerHTML]="uploadGlobalErrors"></p>
            <p *ngIf="fieldErrors" [innerHTML]="fieldErrors"></p>
          </div>
          <div class="alert alert-danger" *ngIf="importResult === 'FORMAT_ERROR'">
            <h4 class="alert-title">{{'ALERT_TEXT_FILE_ERROR' | translate}}</h4>
          </div>
          <div class="alert alert-danger" *ngIf="importResult === 'BASE_DATA_MISSING' || (model.document && !data.taskId && !model.isBaseDataImportable())">
            <h4 class="alert-title">{{'TASK_IMPORT_NO_BASE_DATA_ERROR' | translate}}</h4>
          </div>
        </div>


        <div *ngIf="!importDnDVisible">
          <div class="form-group row mt-1 mr-0 ml-0"
               [ngClass]="{ 'has-danger': hasLocalFieldError('importableDataTypes')}">
              <label class="col-form-label col-md-4 required-field-label">
                {{'TASK_IMPORT_IMPORTABLE_DATA'|translate}}
              </label>
            <div class="col-md-8">
              <angular2-multiselect [data]="model.availableDataTypes" [settings]="offlineDropdownSettings"
                                    class="form-control"
                                    [(ngModel)]="model.importableDataTypes"
                                    formControlName="importableDataTypes">
              </angular2-multiselect>
              <div class="form-control-feedback"
                   *ngIf="hasLocalFieldError('importableDataTypes')">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
          </div>

          <div class="row mr-0 ml-0 mt-1" *ngIf="model.isFormImportable()">
            <label class="col-form-label col-md-4">{{'TASK_FORM_IMPORT_DISABLED_LABEL' | translate}}</label>
            <div class="form-group col-md-8">
              <label class="switch switch-text switch-info mb-0">
                <input type="checkbox" class="switch-input"
                       formControlName="formIgnoreDisabledItems"
                       [(ngModel)]="model.formIgnoreDisabledItems">
                <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                <span class="switch-handle"></span>
              </label>
            </div>
          </div>

          <div class="row mr-0 ml-0 mt-1" *ngIf="model.isTriggerImportable()">
            <label class="col-form-label col-md-4">{{'TASK_IMPORT_TRIGGER_DISABLED_LABEL' | translate}}</label>
            <div class="form-group col-md-8">
              <label class="switch switch-text switch-info mb-0">
                <input type="checkbox" class="switch-input"
                       formControlName="triggerIgnoreDisabledItems"
                       [(ngModel)]="model.triggerIgnoreDisabledItems">
                <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                <span class="switch-handle"></span>
              </label>
            </div>
          </div>

          <div class="row mr-0 ml-0 mt-1" *ngIf="model.isTriggerImportable()">
            <label
              class="col-form-label col-md-4">{{'TASK_IMPORT_TRIGGER_REMOVE_EXISTING_TRIGGERS_LABEL' | translate}}</label>
            <div class="form-group col-md-8">
              <label class="switch switch-text switch-info mb-0">
                <input type="checkbox" class="switch-input"
                       formControlName="triggerRemoveExistingTriggers"
                       [(ngModel)]="model.triggerRemoveExistingTriggers">
                <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                <span class="switch-handle"></span>
              </label>
            </div>
          </div>

          <div *ngIf="model.isBaseDataImportable()">
            <div class="row mr-0 ml-0 mt-1">
              <label class="col-form-label col-md-4">{{'TASK_IMPORT_BASE_DATA_OVERRIDE_NAME' | translate}}</label>
              <div class="form-group col-md-8">
                <label class="switch switch-text switch-info mb-0">
                  <input type="checkbox" class="switch-input"
                         formControlName="overrideName"
                         [(ngModel)]="model.overrideName">
                  <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                  <span class="switch-handle"></span>
                </label>
              </div>
            </div>

            <div class="form-group row mr-0 ml-0 mt-1" *ngIf="model.overrideName" [ngClass]="{ 'has-danger': hasLocalFieldError('overriddenName')}">
              <label class="col-form-label col-md-4 required-field-label">{{'COMMON_NAME'|translate}}</label>
              <div class="col-md-8">
                <div class="mw-100">
                  <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
                         maxlength="{{UiConstants.maximumVarcharLength}}"
                         [(ngModel)]="model.overriddenName"
                         formControlName="overriddenName">
                </div>
                <div class="form-control-feedback"
                     *ngIf="hasLocalFieldError('overriddenName', 'required')">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
              </div>
            </div>

            <div class="row mr-0 ml-0 mt-1">
              <label class="col-form-label col-md-4">{{'TASK_IMPORT_BASE_DATA_OVERRIDE_EXTERNAL_ID' | translate}}</label>
              <div class="form-group col-md-8">
                <label class="switch switch-text switch-info mb-0">
                  <input type="checkbox" class="switch-input"
                         formControlName="overrideExternalId"
                         [(ngModel)]="model.overrideExternalId">
                  <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                  <span class="switch-handle"></span>
                </label>
              </div>
            </div>

            <div class="form-group row mr-0 ml-0 mt-1" *ngIf="model.overrideExternalId" [ngClass]="{ 'has-danger': hasLocalFieldError('overriddenExternalId')}">
                <label class="col-form-label col-md-4 required-field-label">{{'COMMON_EXTERNAL_ID'|translate}}</label>
              <div class="col-md-8">
                <div class="mw-100">
                  <input type="text" class="form-control" placeholder="{{'COMMON_EXTERNAL_ID' | translate}}"
                         maxlength="{{UiConstants.maximumVarcharLength}}"
                         [(ngModel)]="model.overriddenExternalId"
                  formControlName="overriddenExternalId">
                </div>
                <div class="form-control-feedback"
                     *ngIf="hasLocalFieldError('overriddenExternalId', 'required')">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>

              </div>
            </div>


          </div>

        </div>
      </mat-dialog-content>
    </div>
    <div class="modal-footer justify-content-end">
        <span>
          <button type="button" class="btn btn-outline-primary" (click)="closeDialog()">
            {{'COMMON_BUTTON_CANCEL' | translate}}
          </button>
          <button type="submit" class="btn btn-primary ml-1"
                  [ladda]="importInProgress"
                  [disabled]="importInProgress">
            {{'COMMON_BUTTON_IMPORT' | translate}}
          </button>
        </span>
    </div>
  </form>
</div>
