/* eslint-disable */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ShippingDemand, ShippingDemandService } from '../../../lib/shipping-demand/shipping-demand.service';
import { OrderType, QueryResult, ResourceQueryResult, Services } from '../../../lib/util/services';
import { MultiselectOptionItem, QueryFieldModel, SelectUtils, UiConstants } from '../../../util/core-utils';
import { RightModel } from '../../../app.rights';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../lib/auth.service';
import { ConfigurationResource, ConfigurationService } from '../../../lib/core-ext/configuration.service';
import { ToasterService } from '../../../fork/angular2-toaster/angular2-toaster';
import { RightResolver, RightService } from '../../../lib/right.service';
import { UIRouter } from '@uirouter/angular';
import { combineLatest, Observable } from 'rxjs';
import { Models } from '../../../util/model-utils';
import { Set } from 'immutable';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ShippingDemandSearch, ShippingDemandSearchService } from '../../../lib/shipping-demand/shipping-demand-search.service';
import { Strings } from '../../../lib/util/strings';
import { Vehicle, VehicleService } from '../../../lib/vehicles/vehicle.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { Transport, TransportService } from '../../../lib/transport/transport.service';
import { User, UserService } from '../../../lib/user.service';
import { StateName } from '../../../app.state-names';
import { StringKey } from '../../../app.string-keys';
import { FileUploadDialogComponent } from '../../../shared/file-upload/dialog/file-upload-dialog.component';
import { Address, AddressResource } from '../../../lib/address';
import { Logger, LoggerFactory } from '../../../util/logger-factory';
import { Angular2Multiselects } from '../../../util/multiselect';
import { ShipmentGroup, ShipmentGroupService } from '../../../lib/shipment-group/shipment-group.service';
import { DqlStoredQueryArgs } from '../../dql-search/dql-search-container/dql-stored-query.args';
import { DqlSearchContainerComponent } from '../../dql-search/dql-search-container/dql-search-container.component';
import { ShippingDemandDqlFieldTextProvider } from '../../../lib/shipping-demand/shipping-demand.dql.service';
import { IdentityMessage } from '../../../lib/util/messages';
import { ExteriorTransportService } from '../../../lib/exterior-transport/exterior-transport.service';
import { InputMask } from '../../../util/input-masks';
import ShippingDemandState = ShippingDemand.ShippingDemandState;
import TransportState = Transport.TransportState;
import { DownloadedFile } from '../../../lib/util/downloaded-files';
import { saveAs } from 'file-saver';
import { Dates, OffsetDateTime } from '../../../lib/util/dates';
import { TaskRecordListModel } from '../../../util/task-record-utils';
import shippingDemandStates = ShippingDemand.shippingDemandStates;
import { BadgeStyle } from '../../../shared/table-badge/badge-style';

/* eslint-enable */

@Component({
  selector: 'app-shipping-demand-list',
  templateUrl: './shipping-demand-list.component.html',
  styleUrls: ['./shipping-demand-list.component.scss']
})
export class ShippingDemandListComponent implements OnInit, OnDestroy {

  ShippingDemand = ShippingDemand;
  Transport = Transport;
  SelectUtils = SelectUtils;
  UiConstants = UiConstants;
  InputMask = InputMask;
  EditableField = ShippingDemand.EditableField;
  BadgeStyle = BadgeStyle;

  private readonly logger: Logger;

  queryModel: QueryFieldModel<ShippingDemand.OrderField> = new QueryFieldModel(ShippingDemand.OrderField.DEMAND_ID, OrderType.DESC);
  shippingDemandList: ShippingDemandModel[] = [];

  searchModel: ShippingDemandSearchModel = new ShippingDemandSearchModel();
  storedSearchData: ShippingDemandSearch.SearchDataResult;
  showSearch: boolean = false;
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  @ViewChild('transportCreateDialog', { static: true }) transportCreateDialog: ModalDirective;
  transportCreateDialogVisible: boolean = false;
  @ViewChild('addToTransportDialog', { static: true }) addToTransportDialog: ModalDirective;
  addToTransportDialogVisible: boolean = false;
  @ViewChild('exteriorTransportCreateDialog', { static: true }) exteriorTransportCreateDialog: ModalDirective;
  exteriorTransportCreateDialogVisible: boolean = false;
  exteriorTransportCreateModel: ExteriorTransportCreateModel = new ExteriorTransportCreateModel();
  @ViewChild('deleteDemand', { static: true }) deleteDemandModal: ModalDirective;
  deleteDemandVisible: boolean = false;
  currentDemand: number;
  @ViewChild('shipmentCreateDialog', { static: true }) shipmentCreateDialog: ModalDirective;
  shipmentCreateDialogVisible: boolean = false;

  shipmentCreateDemands: number[] = [];

  transportCreateModel: TransportCreateModel = new TransportCreateModel();
  vehicleList: MultiselectOptionItem<number>[] = [];
  driverList: MultiselectOptionItem<number>[] = [];

  singleDropdownSettings: Angular2Multiselects.Settings;

  @ViewChild('shippingDemandImportDialog', { static: true }) shippingDemandImportDialog: FileUploadDialogComponent;
  importBaseUrl: string = '/shipping-demands/import-xls?force_update=';
  shippingDemandImportPath: string;

  addToTransportModel: AddToTransportModel = new AddToTransportModel();
  addToTransportSubmitted: boolean = false;
  transportList: TransportItem[] = [];

  dropdownSettingsForState: Angular2Multiselects.Settings;
  statesForSearch: ShippingDemandSearch.StateItem[] = [];
  states: ShippingDemandSearch.StateItem[] = [];

  rightModel: RightModel = RightModel.empty();
  editableFieldModel: ShippingDemand.EditableFields;
  configuration: ConfigurationResource.Configuration;

  dqlStoredArgs: DqlStoredQueryArgs;
  activeSearchTab: string = 'simple';

  private _searchTabs?: TabsetComponent;

  @ViewChild('searchTabs') set searchTabs(c: TabsetComponent) {
    if (c && !this._searchTabs) {
      this._searchTabs = c;
      if (this.searchModel.dqlText) {
        this.activeSearchTab = 'dql';
        this._searchTabs.tabs[1].active = true;
      }
      else {
        this.activeSearchTab = 'simple';
        this._searchTabs.tabs[0].active = true;
      }
    }
    if (!c) {
      this._searchTabs = c;
    }
  }

  private dqlSearchContainer?: DqlSearchContainerComponent;

  @ViewChild('dqlSearchContainer') set dqlContainer(c: DqlSearchContainerComponent) {
    if (c) {
      if (!this.dqlSearchContainer) {
        const qt = this.searchModel.dqlText;
        this.dqlSearchContainer = c;
        this.loadDqlModel(() => {
          this.loadDqlSearch(qt);
          this.dqlSearchContainer!.loadContent();
        });
      }
    }
    else {
      this.dqlSearchContainer = undefined;
    }
  }


  constructor(private shippingDemandService: ShippingDemandService,
              private shippingDemandSearchService: ShippingDemandSearchService,
              private translateService: TranslateService,
              private authService: AuthService,
              private vehicleService: VehicleService,
              private userService: UserService,
              private transportService: TransportService,
              private exteriorTransportService: ExteriorTransportService,
              private configService: ConfigurationService,
              private shipmentGroupService: ShipmentGroupService,
              private uiRouter: UIRouter,
              private toasterService: ToasterService,
              private rightService: RightService) {
    this.logger = LoggerFactory.createLogger('ShippingDemandListComponent');
    this.dqlStoredArgs = {service: shippingDemandService.dqlStoredQueryService, parentId: undefined, documentType: undefined};
  }

  ngOnInit() {
    this.initBreadcrumb();
    this.initDropdownSettings();
    this.loadEditableFields(() => {
      this.loadRightModels(() => {
        if (this.rightModel.vehicleRead.hasRight()) {
          this.loadVehicles();
        }
        if (this.rightModel.userRead.hasRight()) {
          this.loadDrivers();
        }
        if (this.rightModel.transportRead.hasRight()) {
          this.loadTransports();
        }
        this.loadConfig(() => {
          this.loadSearch(() => {
            this.showSearch = !this.searchModel.isEmpty();
            this.loadList();
          });
        });
      });
    });
  }

  initBreadcrumb() {
    this.translateService.get('MENU_NAVBAR_MENU_SHIPPING_DEMAND').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
  }

  private loadEditableFields(completion: () => void) {
    this.shippingDemandService.getEditableFields().subscribe(result => {
      this.editableFieldModel = result;
      completion();
    });
  }

  private loadRightModels(completion: () => void) {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        completion();
      }
    );
  }

  private loadVehicles(q?: string) {
    this.vehicleService.query({
      licencePlate: q ? Strings.undefinedOrNonEmpty(q) : undefined,
      disabled: false,
      paging: {
        numberOfItems: UiConstants.autocompletePageSize,
        pageNumber: 1
      },
      noProgressBar: true
    }).subscribe((result: QueryResult<Vehicle.Vehicle>) => {
      this.vehicleList = result.items.toArray().map(v => ({id: v.id, itemName: v.licencePlate + '(' + v.company.name + ')'}));
    });
  }

  private loadDrivers(q?: string) {
    this.userService.query({
      person_name: q ? Strings.undefinedOrNonEmpty(q) : undefined,
      disabled: false,
      is_driver: true,
      number_of_items: UiConstants.autocompletePageSize,
      page_number: 1,
      order: Services.createOrderFieldParameter(User.Keys.toOrderFieldKey, Set.of(User.DEFAULT_ORDER)),
      no_progress_bar: true
    }).subscribe((result: ResourceQueryResult<User>) => {
      this.driverList = result.items.map(u => ({id: u.driver!.driver_id!, itemName: u.person_name}));
    });
  }

  private loadSearch(completion: () => void) {
    const obs: Observable<SearchLoadResult> = combineLatest(
      this.shippingDemandSearchService.getSearchData({}),
      (storedSearchData: ShippingDemandSearch.SearchDataResult) => {
        const result: SearchLoadResult = {
          storedSearchData: storedSearchData
        };
        return result;
      }
    );
    obs.subscribe(
      (result: SearchLoadResult) => {
        this.storedSearchData = result.storedSearchData;
        this.loadStatesForSearch(result.storedSearchData, () => {
          this.postInitSearch(result.storedSearchData);
          completion();
        });
      }
    );
  }

  private loadStatesForSearch(storedSearchData: ShippingDemandSearch.SearchDataResult, completion: () => void) {
    this.searchModel.stateIds = [];
    this.loadStates(() => {
      this.statesForSearch = [];
      this.states.forEach((state) => {
        const item = new ShippingDemandSearch.StateItem();
        item.id = state.id;
        item.name = state.name;
        this.statesForSearch.push(item);
        if (storedSearchData.searchData.stateIds.find(t => t.id === item.id)) {
          this.searchModel.stateIds.push(item);
        }
      });
      completion();
    });
  }

  private loadStates(completion: () => void) {
    this.states = [];
    ShippingDemand.shippingDemandStates.forEach(
      (state) => {
        const item = {
          id: state.state,
          itemName: state.stringKey,
          name: state.stringKey,
        };
        this.states.push(item);
      });
    this.states.forEach(
      (state: ShippingDemandSearch.StateItem) => {
        this.translateService.get(state.name).subscribe((stateName: string) => {
            state.name = stateName;
          }
        );
      });
    completion();
  }

  initDropdownSettings() {
    this.dropdownSettingsForState = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .build();
    this.singleDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
  }

  private loadConfig(completion: () => void) {
    this.configuration = this.configService.getConfiguration();
    completion();
  }

  private postInitSearch(storedSearchData: ShippingDemandSearch.SearchDataResult) {
    this.queryModel.itemsPerPage = storedSearchData.searchData.itemsPerPage;
    this.queryModel.currentPage = storedSearchData.searchData.pageNumber;
    this.queryModel.setOrder(storedSearchData.searchData.order);
    this.searchModel.dqlText = storedSearchData.searchData.dqlText;
    this.searchModel.id = storedSearchData.searchData.id;
    this.searchModel.tourRoute = storedSearchData.searchData.tourRoute;
    this.searchModel.transporterName = storedSearchData.searchData.transporterName;
    this.searchModel.demandCategory = storedSearchData.searchData.demandCategory;
    this.searchModel.sourceName = storedSearchData.searchData.sourceName;
    this.searchModel.sourceAddress = storedSearchData.searchData.sourceAddress;
    this.searchModel.destinationName = storedSearchData.searchData.destinationName;
    this.searchModel.destinationAddress = storedSearchData.searchData.destinationAddress;
    this.searchModel.deadlineFrom = Models.localDateToNgbDate(storedSearchData.searchData.deadlineFrom);
    this.searchModel.deadlineTo = Models.localDateToNgbDate(storedSearchData.searchData.deadlineTo);
    this.searchModel.safetyShipping = storedSearchData.searchData.safetyShipping ?
      storedSearchData.searchData.safetyShipping : undefined;
    this.searchModel.hasTransport = storedSearchData.searchData.hasTransport ?
      storedSearchData.searchData.hasTransport : undefined;
    this.searchModel.shippingItem = storedSearchData.searchData.shippingItem;
  }

  private saveSearch() {
    const request = {
      searchData: {
        itemsPerPage: this.queryModel.itemsPerPage,
        pageNumber: this.queryModel.currentPage,
        order: this.queryModel.getOrder(),
        id: this.searchModel.id,
        dqlText: this.searchModel.dqlText,
        tourRoute: this.searchModel.tourRoute,
        transporterName: this.searchModel.transporterName,
        demandCategory: this.searchModel.demandCategory,
        sourceName: this.searchModel.sourceName,
        sourceAddress: this.searchModel.sourceAddress,
        destinationName: this.searchModel.destinationName,
        destinationAddress: this.searchModel.destinationAddress,
        deadlineFrom: Models.ngbDateToLocalDate(this.searchModel.deadlineFrom),
        deadlineTo: Models.ngbDateToLocalDate(this.searchModel.deadlineTo),
        stateIds: this.searchModel.stateIds,
        safetyShipping: this.searchModel.safetyShipping ? this.searchModel.safetyShipping : undefined,
        hasTransport: this.searchModel.hasTransport ? this.searchModel.hasTransport : undefined,
        shippingItem: this.searchModel.shippingItem
      }
    };
    this.shippingDemandSearchService.setSearchData(request).subscribe();
  }

  loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();
    this.shippingDemandService.query({
      demandId: Strings.undefinedOrNonEmpty(this.searchModel.id) ? +this.searchModel.id : undefined,
      tourRoute: Strings.undefinedOrNonEmpty(this.searchModel.tourRoute),
      transporterName: Strings.undefinedOrNonEmpty(this.searchModel.transporterName),
      demandCategory: Strings.undefinedOrNonEmpty(this.searchModel.demandCategory),
      sourceName: Strings.undefinedOrNonEmpty(this.searchModel.sourceName), // equal with address on purpose
      sourceAddress: Strings.undefinedOrNonEmpty(this.searchModel.sourceAddress),
      destinationName: Strings.undefinedOrNonEmpty(this.searchModel.destinationName),
      destinationAddress: Strings.undefinedOrNonEmpty(this.searchModel.destinationAddress),
      deadlineFrom: Models.parseDateTimeFrom(this.searchModel.deadlineFrom),
      deadlineTo: Models.parseDateTimeTo(this.searchModel.deadlineTo),
      state: this.searchModel.stateSet,
      safetyShipping: this.searchModel.safetyShipping ? this.searchModel.safetyShipping : undefined,
      hasTransport: this.searchModel.hasTransport ? this.searchModel.hasTransport : undefined,
      shippingItem: Strings.undefinedOrNonEmpty(this.searchModel.shippingItem),
      orders: Set.of(order),
      dqlText: this.searchModel.dqlText,
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined
    }).subscribe((result: QueryResult<ShippingDemand.ShippingDemand>) => {
      this.shippingDemandList = [];
      result.items.forEach((shippingDemand: ShippingDemand.ShippingDemand) => {
        const shippingDemandItem = new ShippingDemandModel();
        shippingDemandItem.id = shippingDemand.id;
        shippingDemandItem.tourRoute = shippingDemand.tourRoute ? shippingDemand.tourRoute : '';
        shippingDemandItem.transporterName = shippingDemand.transporter ? shippingDemand.transporter.name : '';
        shippingDemandItem.sourceName = this.unwrapName(shippingDemand.source);
        shippingDemandItem.sourceAddress = this.unwrapAddress(shippingDemand.source);
        shippingDemandItem.sourceGeocodeStatus = this.unwrapGeocodeStatus(shippingDemand.source);
        shippingDemandItem.sourceCoordinates = this.unwrapCoordinates(shippingDemand.source);
        shippingDemandItem.destinationName = this.unwrapName(shippingDemand.destination);
        shippingDemandItem.destinationAddress = this.unwrapAddress(shippingDemand.destination);
        shippingDemandItem.destinationGeocodeStatus = this.unwrapGeocodeStatus(shippingDemand.destination);
        shippingDemandItem.destinationCoordinates = this.unwrapCoordinates(shippingDemand.destination);
        shippingDemandItem.ekaerId = shippingDemand.ekaerId ? shippingDemand.ekaerId : '';
        shippingDemandItem.demandGroupSerial = shippingDemand.demandGroupSerial;
        shippingDemandItem.demanderName =
          shippingDemand.demander && shippingDemand.demander.company ? shippingDemand.demander.company.name : '';
        shippingDemandItem.safetyShipping = shippingDemand.safetyShipping;
        shippingDemandItem.transports = shippingDemand.transports.toArray();
        shippingDemandItem.shipmentGroup = shippingDemand.shipmentGroup;
        shippingDemandItem.state = shippingDemand.state;
        shippingDemandItem.stateObject = shippingDemandStates.find(state => state.state === shippingDemand.state)!;
        shippingDemandItem.deadline = shippingDemand.deadline;
        this.shippingDemandList.push(shippingDemandItem);
      });
      this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
      this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.queryModel.currentPage = requestedPage;
    });
  }


  private demandIdListObservable() {
    return this.shippingDemandService.idQuery({
      demandId: Strings.undefinedOrNonEmpty(this.searchModel.id) ? +this.searchModel.id : undefined,
      tourRoute: Strings.undefinedOrNonEmpty(this.searchModel.tourRoute),
      transporterName: Strings.undefinedOrNonEmpty(this.searchModel.transporterName),
      demandCategory: Strings.undefinedOrNonEmpty(this.searchModel.demandCategory),
      sourceName: Strings.undefinedOrNonEmpty(this.searchModel.sourceName),
      sourceAddress: Strings.undefinedOrNonEmpty(this.searchModel.sourceAddress), // equal with address on purpose
      destinationName: Strings.undefinedOrNonEmpty(this.searchModel.destinationName),
      destinationAddress: Strings.undefinedOrNonEmpty(this.searchModel.destinationAddress),
      deadlineFrom: Models.parseDateTimeFrom(this.searchModel.deadlineFrom),
      deadlineTo: Models.parseDateTimeTo(this.searchModel.deadlineTo),
      state: this.searchModel.stateSet,
      safetyShipping: this.searchModel.safetyShipping ? this.searchModel.safetyShipping : undefined,
      hasTransport: this.searchModel.hasTransport ? this.searchModel.hasTransport : undefined,
      dqlText: this.searchModel.dqlText,
      shippingItem: Strings.undefinedOrNonEmpty(this.searchModel.shippingItem)
    });
  }

  private loadTransports(q?: string, completion?: () => void) {
    const validStatesForAdd: TransportState[] = [
      'UNDER_PLANNING',
      'UNDER_REPLANNING'
    ];
    const transportQueryOrder = new QueryFieldModel(Transport.OrderField.ID, OrderType.DESC).getOrder();
    this.transportService.query({
      waybillNumber: q ? Strings.undefinedOrNonEmpty(q) : undefined,
      orders: Set.of(...[transportQueryOrder]),
      state: Set.of(...validStatesForAdd),
      paging: {
        numberOfItems: UiConstants.autocompletePageSize,
        pageNumber: 1
      },
      noProgressBar: true
    }).subscribe((result: QueryResult<Transport.Transport>) => {
      this.transportList = result.items.toArray().map((transport) => ({
        id: transport.id,
        itemName: transport.waybillNumber
          + ' - ' + (transport.driver ? transport.driver.personName : '')
          + ' - ' + (transport.vehicle ? transport.vehicle.licencePlate : ''),
        securityGuard: transport.securityGuard ? transport.securityGuard.personName : '-',
        safetyShipping: transport.safetyShipping,
        comment: transport.comment ? transport.comment : '-'
      }));
      if (completion) {
        completion();
      }
    });
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: ShippingDemand.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  onSearchClicked() {
    this.searchModel.dqlText = undefined;
    this.loadList();
  }

  onSearchReset() {
    this.shippingDemandSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.showSearch = true;
          this.loadList(1);
        });
      }
    );
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  loadDqlSearch(queryString?: string) {
    if (queryString) {
      this.dqlSearchContainer!.loadQuery(queryString);
    }
  }

  loadDqlModel(completion?: () => void) {
    this.dqlSearchContainer!.setFields(this.shippingDemandService.getDqlModel(),
      ShippingDemandDqlFieldTextProvider.getHelper(),
      completion ? completion : () => {
      });
  }

  onDqlSearchClicked() {
    this.deselectShippingDemands();
    const dqlQueryString = this.dqlSearchContainer!.getQueryString();
    if (dqlQueryString) {
      this.searchModel = new ShippingDemandSearchModel();
    }
    this.searchModel.dqlText = dqlQueryString;
    this.loadList(1);
  }

  openDeleteDemand(id) {
    this.currentDemand = id;
    this.deleteDemandVisible = true;
    this.deleteDemandModal.show();
  }

  closeDeleteDemand() {
    this.deleteDemandVisible = false;
    this.deleteDemandModal.hide();
  }

  cancelShippingDemand() {
    this.deleteDemandModal.hide();
    this.shippingDemandService.cancel({
      id: this.currentDemand
    }).subscribe(() => {
      this.loadList();
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutShort,
        type: UiConstants.toastTypeSuccess,
        title: this.translateService.instant(StringKey.COMMON_SUCCESS)
      });
    });
  }

  getSelectedItems(): ShippingDemandModel[] {
    const items: ShippingDemandModel[] = [];
    this.shippingDemandList.forEach((tr) => {
      if (tr.selected) {
        items.push(tr);
      }
    });
    return items;
  }

  getSelectedIds(): number[] {
    const ids: number[] = [];
    this.shippingDemandList.forEach((tr) => {
      if (tr.selected) {
        ids.push(tr.id);
      }
    });
    return ids;
  }

  toggleEachShippingDemand() {
    const eachShippingDemandSelected = !this.eachShippingDemandSelected;
    this.shippingDemandList.forEach((sd) => {
      sd.selected = eachShippingDemandSelected;
    });
  }

  get eachShippingDemandSelected(): boolean {
    if (this.shippingDemandList.length === 0) {
      return false;
    }
    let selected = true;
    this.shippingDemandList.forEach((sd) => {
      selected = selected && sd.selected;
    });
    return selected;
  }

  deselectShippingDemands() {
    this.shippingDemandList.forEach((tr) => {
      tr.selected = false;
    });
  }

  isAnyShippingDemandSelected(): boolean {
    const selected: ShippingDemandModel[] = this.shippingDemandList.filter((tr) => tr.selected);
    return selected.length > 0;
  }

  getSelectedShippingDemandCount(): number {
    let selectedItems = 0;
    this.shippingDemandList.forEach((shippingDemand) => {
      if (shippingDemand.selected) {
        selectedItems++;
      }
    });
    return selectedItems;
  }

  private isSafetyShipping(): boolean {
    let found: boolean = false;
    this.transportCreateModel.demands.forEach((demand) => {
      if (demand.safetyShipping) {
        found = true;
      }
    });
    return found;
  }

  openTransportCreateDialog() {
    if (this.vehicleList.length === 0) {
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutLong,
        type: UiConstants.toastTypeError,
        title: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_TITLE),
        body: this.translateService.instant(StringKey.SHIPPING_DEMAND_ERROR_NO_VEHICLES)
      });
      return;
    }
    if (this.shippingDemandList.length === 0) {
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutLong,
        type: UiConstants.toastTypeError,
        title: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_TITLE),
        body: this.translateService.instant(StringKey.SHIPPING_DEMAND_ERROR_NO_SHIPPING_DEMAND)
      });
      return;
    }
    if (this.isAnyShippingDemandSelected()) {
      this.transportCreateModel.demands = this.getSelectedItems();
      this.transportCreateModel.safetyShipping = this.isSafetyShipping();
      this.transportCreateDialogVisible = true;
      this.transportCreateDialog.show();
      this.logger.debug(this.transportCreateModel);
    }
    else {
      this.demandIdListObservable().subscribe((result: QueryResult<ShippingDemand.ShippingDemandId>) => {
        this.transportCreateModel.demands = [];
        result.items.forEach((shippingDemand: ShippingDemand.ShippingDemandId) => {
          if (shippingDemand) {
            const shippingDemandItem = new ShippingDemandModel();
            shippingDemandItem.id = shippingDemand.id;
            shippingDemandItem.safetyShipping = shippingDemand.safetyShipping;
            this.transportCreateModel.demands.push(shippingDemandItem);
          }
        });
        this.transportCreateModel.safetyShipping = this.isSafetyShipping();
        this.transportCreateModel.safetyShippingSettable = !this.isSafetyShipping();
        this.transportCreateDialogVisible = true;
        this.transportCreateDialog.show();
        this.logger.debug(this.transportCreateModel);
      });
    }
  }

  closeTransportCreateDialog() {
    this.transportCreateModel.reset();
    this.transportCreateDialogVisible = false;
    this.transportCreateDialog.hide();
  }

  createTransport() {
    this.logger.debug(this.transportCreateModel);
    const demandIds: number[] = [];
    this.transportCreateModel.demands.forEach((demand) => {
      demandIds.push(demand.id);
    });
    this.transportService.createByDemands({
      demandIds: Set.of(...demandIds),
      vehicleId: this.transportCreateModel.vehicleId,
      driverId: this.transportCreateModel.driverId,
      safetyShipping: this.transportCreateModel.safetyShipping,
      comment: this.transportCreateModel.comment
    }).subscribe(
      (response: Transport.CreateResponse) => {
        this.uiRouter.stateService.go(StateName.TRANSPORT_DETAIL, {id: response.transportId});
      }
    );
  }

  openShipmentCreateDialog() {
    if (this.shippingDemandList.length === 0) {
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutLong,
        type: UiConstants.toastTypeError,
        title: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_TITLE),
        body: this.translateService.instant(StringKey.SHIPPING_DEMAND_ERROR_NO_SHIPPING_DEMAND)
      });
      return;
    }
    if (this.isAnyShippingDemandSelected()) {
      this.shipmentCreateDemands = this.getSelectedItems().map(d => d.id);
      this.shipmentCreateDialogVisible = true;
      this.shipmentCreateDialog.show();
    }
    else {
      this.demandIdListObservable().subscribe((result: QueryResult<ShippingDemand.ShippingDemandId>) => {
        this.shipmentCreateDemands = result.items.toArray().map(d => d.id);
        this.shipmentCreateDialogVisible = true;
        this.shipmentCreateDialog.show();
      });
    }
  }

  closeShipmentCreateDialog() {
    this.shipmentCreateDemands = [];
    this.shipmentCreateDialogVisible = false;
    this.shipmentCreateDialog.hide();
  }

  createShipment() {
    this.shipmentGroupService.createByDemands({
      demandIds: Set.of(...this.shipmentCreateDemands)
    }).subscribe(
      (response: ShipmentGroup.CreateByDemandsResponse) => {
        // TODO: if only 1 shipment was created then go to that shipment's detail
        this.uiRouter.stateService.go(StateName.SHIPMENT_LIST);
      }
    );
  }

  addToTransport() {
    this.addToTransportSubmitted = true;
    if (!this.addToTransportModel.transportId) {
      return;
    }
    this.transportService.addDemands({
      id: this.addToTransportModel.transportId!,
      demandIds: Set.of(...this.addToTransportModel.demands.map(value => value.id))
    }).subscribe(
      () => {
        this.uiRouter.stateService.go(StateName.TRANSPORT_DETAIL, {id: this.addToTransportModel.transportId});
      }
    );
  }

  openAddToTransportDialog() {
    if (this.isAnyShippingDemandSelected()) {
      this.addToTransportModel.demands = this.getSelectedItems();
      this.addToTransportDialogVisible = true;
      this.addToTransportDialog.show();
      this.logger.debug(this.addToTransportModel);
    }
    else {
      this.demandIdListObservable().subscribe((result: QueryResult<ShippingDemand.ShippingDemandId>) => {
        this.addToTransportModel.demands = [];
        result.items.forEach((shippingDemand: ShippingDemand.ShippingDemandId) => {
          if (shippingDemand) {
            const shippingDemandItem = new ShippingDemandModel();
            shippingDemandItem.id = shippingDemand.id;
            shippingDemandItem.safetyShipping = shippingDemand.safetyShipping;
            this.addToTransportModel.demands.push(shippingDemandItem);
          }
        });
        this.addToTransportDialogVisible = true;
        this.addToTransportDialog.show();
        this.logger.debug(this.addToTransportModel);
      });
    }
  }

  closeAddToTransportDialog() {
    this.addToTransportSubmitted = false;
    this.addToTransportModel.reset();
    this.addToTransportDialogVisible = false;
    this.addToTransportDialog.hide();
  }

  public onImportSuccess(succeeded: boolean) {
    if (succeeded) {
      this.loadList(1);
    }
  }

  onTransportChosen() {
    if (this.addToTransportModel.transportId) {
      this.addToTransportModel.safetyShipping = !!this.addToTransportModel.transport[0].safetyShipping;
      this.addToTransportModel.securityGuard = this.addToTransportModel.transport[0].securityGuard
        ? this.addToTransportModel.transport[0].securityGuard! : '-';
      this.addToTransportModel.comment = this.addToTransportModel.transport[0].comment
        ? this.addToTransportModel.transport[0].comment! : '-';
    }
  }

  unwrapName(shippingPlace: ShippingDemand.ShippingPlace): string {
    if (shippingPlace.place) {
      return shippingPlace.place.name;
    }
    else if (shippingPlace.location) {
      return shippingPlace.location.name;
    }
    else {
      return '';
    }
  }

  private unwrapGeocodeStatus(shippingPlace: ShippingDemand.ShippingPlace): AddressResource.GeocodeStatus | undefined {
    if (shippingPlace.place) {
      return shippingPlace.place.geocodeStatus;
    }
    else if (shippingPlace.location) {
      return shippingPlace.location.place ? shippingPlace.location.place.geocodeStatus : undefined;
    }
    else {
      return undefined;
    }
  }

  private unwrapCoordinates(shippingPlace: ShippingDemand.ShippingPlace): Address.Coordinate | undefined {
    if (shippingPlace.place) {
      return shippingPlace.place.coordinate;
    }
    else if (shippingPlace.location) {
      return shippingPlace.location.place ? shippingPlace.location.place.coordinate : undefined;
    }
    else {
      return undefined;
    }
  }

  unwrapAddress(shippingPlace: ShippingDemand.ShippingPlace): string {
    if (shippingPlace.place && shippingPlace.place.postalAddress) {
      return Address.PostalAddressMapper.toString(shippingPlace.place.postalAddress, this.configuration.postal_address_format);
    }
    else if (shippingPlace.location && shippingPlace.location.place && shippingPlace.location.place.postalAddress) {
      return Address.PostalAddressMapper.toString(shippingPlace.location.place.postalAddress, this.configuration.postal_address_format);
    }
    else {
      return '';
    }
  }

  getGeocodeStatusIcon(geocodeStatus: AddressResource.GeocodeStatus): string {
    const so = AddressResource.geocodeStatuses.find(s => s.status === geocodeStatus);
    return so ? so.iconClass : '';
  }

  getGeocodeStatusNameKey(geocodeStatus: AddressResource.GeocodeStatus): string {
    const so = AddressResource.geocodeStatuses.find(s => s.status === geocodeStatus);
    return so ? so.stringKey : '';
  }

  getGeocodeStatusCoordinateText(coordinates?: Address.Coordinate): string {
    return coordinates ? coordinates.latitude.toString() + ', ' + coordinates.longitude.toString() : '';
  }

  openImportDialog(forceUpdate: boolean) {
    this.shippingDemandImportPath = this.importBaseUrl + forceUpdate;
    this.shippingDemandImportDialog.toggleDialog();
  }

  isEnabledField(field: ShippingDemand.EditableField): boolean {
    if (this.rightModel.shippingDemandRead.hasRight()) {
      return this.editableFieldModel.isEnabledByServer(false, field);
    }
    if (this.rightModel.shippingDemandReadSimple.hasRight()) {
      return this.editableFieldModel.isEnabledByServer(true, field);
    }
    return false;
  }

  isOverdue(shippingDemand: ShippingDemandModel): boolean {
    if (shippingDemand.state === 'NEW' || shippingDemand.state === 'UNDER_CONSTRUCTION' || shippingDemand.state === 'IN_PROGRESS') {
      if (shippingDemand.deadline) {
        if (Dates.now().isAfter(shippingDemand.deadline)) {
          return true;
        }
      }
    }
    return false;
  }

  openExteriorTransportCreateDialog() {
    if (this.isAnyShippingDemandSelected()) {
      this.exteriorTransportCreateModel.demandIds = this.getSelectedIds();
      this.exteriorTransportCreateDialogVisible = true;
      this.exteriorTransportCreateDialog.show();
    }
    else {
      this.demandIdListObservable().subscribe((result: QueryResult<ShippingDemand.ShippingDemandId>) => {
        this.exteriorTransportCreateModel.demandIds = [];
        result.items.forEach((shippingDemand: ShippingDemand.ShippingDemandId) => {
          if (shippingDemand) {
            this.exteriorTransportCreateModel.demandIds.push(shippingDemand.id);
          }
        });
        this.exteriorTransportCreateDialogVisible = true;
        this.exteriorTransportCreateDialog.show();
      });
    }
  }

  closeExteriorTransportCreateDialog() {
    this.exteriorTransportCreateModel.reset();
    this.exteriorTransportCreateDialogVisible = false;
    this.exteriorTransportCreateDialog.hide();
  }

  createExteriorTransport() {
    this.exteriorTransportService.createByDemands({
      demandIds: Set.of(...this.exteriorTransportCreateModel.demandIds)
    }).subscribe(
      (response: IdentityMessage) => {
        this.uiRouter.stateService.go(StateName.EXTERIOR_TRANSPORT_DETAIL, {id: response.id});
      }
    );
  }

  isExteriorTransportEnabledByServer(): boolean {
    return this.configuration && ConfigurationService.isEnabledByServer('EXTERIOR_TRANSPORT', this.configuration);
  }

  generateCarriageTagPdfDocument() {
    if (this.isAnyShippingDemandSelected()) {
      this.shippingDemandService.generateCarriageTagPdfDocument({
        demandIds: this.getSelectedIds()
      }).subscribe((res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName('shipping-demand-carriage-tag-document.pdf'));
      });
    }
    else {
      this.demandIdListObservable().subscribe((result: QueryResult<ShippingDemand.ShippingDemandId>) => {
        this.shippingDemandService.generateCarriageTagPdfDocument({
          demandIds: result.items.toArray().map(id => id.id)
        }).subscribe((res: DownloadedFile) => {
          saveAs(res.getBlob(), res.getFileName('shipping-demand-carriage-tag-document.pdf'));
        });
      });
    }
  }

  ngOnDestroy() {
    this.saveSearch();
  }
}

export class ShippingDemandModel {
  id: number;
  state: ShippingDemand.ShippingDemandState;
  stateObject: ShippingDemand.ShippingDemandStateObject;
  tourRoute: string = '';
  transporterName: string = '';
  sourceName: string = '';
  sourceAddress: string = '';
  sourceGeocodeStatus?: AddressResource.GeocodeStatus;
  sourceCoordinates?: Address.Coordinate;
  destinationName: string = '';
  destinationAddress: string = '';
  destinationGeocodeStatus?: AddressResource.GeocodeStatus;
  destinationCoordinates?: Address.Coordinate;
  ekaerId: string = '';
  demandGroupSerial: string = '';
  demanderName: string = '';
  selected: boolean = false;
  safetyShipping: boolean = false;
  transports: ShippingDemand.Transport[];
  shipmentGroup?: ShippingDemand.ShipmentGroup;
  deadline?: OffsetDateTime;
}

export class ShippingDemandSearchModel {

  id: string = '';
  tourRoute: string = '';
  transporterName: string = '';
  demandCategory: string = '';
  sourceName: string = '';
  sourceAddress: string = '';
  destinationName: string = '';
  destinationAddress: string = '';
  deadlineFrom: NgbDateStruct | null = null;
  deadlineTo: NgbDateStruct | null = null;
  stateIds: ShippingDemandSearch.StateItem[] = [];
  safetyShipping?: boolean = undefined;
  hasTransport?: boolean = undefined;
  shippingItem: string = '';
  dqlText?: string = undefined;

  public isEmpty(): boolean {
    return this.id.length === 0
      && this.tourRoute.length === 0
      && this.transporterName.length === 0
      && this.demandCategory.length === 0
      && this.sourceName.length === 0
      && this.sourceAddress.length === 0
      && this.destinationName.length === 0
      && this.destinationAddress.length === 0
      && this.deadlineFrom === null
      && this.deadlineTo === null
      && this.stateIds.length === 0
      && this.safetyShipping === undefined
      && this.hasTransport === undefined
      && this.shippingItem.length === 0
      && this.dqlText === undefined
      ;
  }

  get stateSet(): Set<ShippingDemandState> | undefined {
    const stateSet = this.stateIds.map((item) => {
      return ShippingDemand.shippingDemandStates.find((state) => state.state === item.id)!.state;
    });
    return stateSet.length > 0 ? Set.of(...stateSet) : undefined;
  }
}

interface SearchLoadResult {
  storedSearchData: ShippingDemandSearch.SearchDataResult,
}

class TransportCreateModel {
  vehicle: MultiselectOptionItem<number>[] = [];
  driver: MultiselectOptionItem<number>[] = [];
  safetyShipping: boolean = false;
  safetyShippingSettable: boolean = false;
  comment: string = '';
  demands: ShippingDemandModel[] = [];

  get vehicleId(): number | undefined {
    return this.vehicle.length === 1 ? this.vehicle[0].id : undefined;
  }

  get driverId(): number | undefined {
    return this.driver.length === 1 ? this.driver[0].id : undefined;
  }

  reset() {
    this.vehicle = [];
    this.driver = [];
    this.comment = '';
  }
}

class AddToTransportModel {
  transport: TransportItem[] = [];
  demands: ShippingDemandModel[] = [];
  safetyShipping: boolean = false;
  securityGuard: string = '';
  comment: string = '';

  get transportId(): number | undefined {
    return this.transport.length === 1 ? this.transport[0].id : undefined;
  }

  reset() {
    this.transport = [];
    this.demands = [];
  }
}

interface TransportItem extends MultiselectOptionItem<number> {
  safetyShipping?: boolean;
  securityGuard?: string;
  comment?: string;
}

class ExteriorTransportCreateModel {
  demandIds: number[] = [];

  reset() {
    this.demandIds = [];
  }
}
