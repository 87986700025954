/* eslint-disable */
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { DocumentLanguageItem } from './document-utils';
/* eslint-enable */

@Injectable()
export class DocumentLanguageUtil {

  constructor(private translateService: TranslateService) {
  }

  public getDocumentLanguageName(documentLanguageCode: string | null): string {
    let languageName = (documentLanguageCode) ? documentLanguageCode : '';
    if (!documentLanguageCode) {
      return languageName;
    }


    languageName = this.translateService.instant('DOCUMENT_LANGUAGE_NAME_' + documentLanguageCode.toUpperCase());
    return languageName;
  }

  public createDefaultLanguageCode(): DocumentLanguageItem {
    const def = new DocumentLanguageItem();
    def.id = null;
    def.text = '';
    this.translateService.get('DOCUMENT_LANGUAGE_NAME_ALL').subscribe(
      (text: string) => {
        def.text = text;
      }
    );
    return def;
  }

}
