/* eslint-disable */
import { AfterViewInit, Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { List, Set } from 'immutable';
import { Transition, UIRouter } from '@uirouter/angular';
import {MultiselectOptionItem, OptionItem, QueryFieldModel, SelectUtils, UiConstants,} from '../../../util/core-utils';
import { CustomerRecord, CustomerRecordService } from '../../../lib/customer/customer-record.service';
import { OrderType, QueryResult, ResourceQueryResult, Services } from '../../../lib/util/services';
import { RightModel } from '../../../app.rights';
import { GrantedPermissionSetResolver, RightResolver, RightService } from '../../../lib/right.service';
import { FileUploadDialogComponent } from '../../../shared/file-upload/dialog/file-upload-dialog.component';
import {
  CustomerItem,
  CustomerItemForSearch,
  CustomerRecordFieldModel,
  CustomerRecordFieldType,
  CustomerRecordListModel,
  CustomerRecordRightModel, CustomerRecordTaskRecordData
} from '../../../util/customer-record-utils';
import { Customer, CustomerService } from '../../../lib/customer/customer.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { StateName } from '../../../app.state-names';
import { Strings } from '../../../lib/util/strings';
import { Observable } from 'rxjs';
import { CustomerRecordSearch, CustomerRecordSearchService } from '../../../lib/customer-record-search-service';
import { IdentityArray } from '../../../lib/util/messages';
import { UserData, UserDataLoader, UserDataLoaderPermissionDeniedStrategy } from '../../../lib/user-data-loader';
import { AssigneeItem, ConfigModel } from '../../../util/task-record-utils';
import { UserGroup, UserGroupService } from '../../../lib/user-group.service';
import { Angular2Multiselects } from '../../../util/multiselect';
import { StringKey } from '../../../app.string-keys';
import { ToasterService } from '../../../fork/angular2-toaster/angular2-toaster';
import { Address, AddressModel } from '../../../lib/address';
import { Country, CountryService } from '../../../lib/country.service';
import { ConfigurationService } from '../../../lib/core-ext/configuration.service';
import { DqlSearchContainerComponent } from '../../dql-search/dql-search-container/dql-search-container.component';
import { CustomerRecordDqlFieldTextProvider } from '../../../lib/customer/customer-record.dql.service';
import { User, UserService } from '../../../lib/user.service';
import { saveAs } from 'file-saver';
import { DownloadedFile } from '../../../lib/util/downloaded-files';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { DqlStoredQueryArgs } from '../../dql-search/dql-search-container/dql-stored-query.args';
import { DisabledEnum, DisabledItem, SearchBooleanItem, searchBooleanItemObjects, SearchUtils } from '../../../util/search-utils';
import {
  DemoModeFeatureDisabledDialogComponent
} from '../../../shared/demo-mode-feature-disabled-dialog/demo-mode-feature-disabled-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DropdownItemType } from '../../../shared/dropdown/dropdown-item/dropdown-item-type';
import { OperationRights } from '../../../app.right-definitions';
import { CustomerRightModel } from '../../../util/customer-utils';
import {
  TaskRecordCreateDialogMode,
  TaskRecordCreateMaterialDialogComponent
} from '../../task/task-record/task-record-create-material-dialog/task-record-create-material-dialog.component';
import {
  CustomerRecordQuickCreateMaterialDialogComponent
} from '../customer-record-quick-create-material/customer-record-quick-create-material-dialog.component';
import { TaskRecord, TaskRecordService } from '../../../lib/task/task-record.service';
import SearchModel = SearchUtils.SearchModel;
import SearchableList = SearchUtils.SearchableList;
import { TaskRecordStateMachine } from '../../../lib/task/task-record-statemachine';
import {
  ContractNumberMultiselectProvider
} from "../../../lib/customer/contract-number/contract-number-multiselect-provider.service";

/* eslint-enable */

@Component({
  selector: 'app-customer-record-list',
  templateUrl: 'customer-record-list.component.html',
  styleUrls: ['customer-record-list.component.scss']
})
export class CustomerRecordListComponent extends SearchableList<CustomerRecordSearchModel> implements OnInit, AfterViewInit, OnDestroy {

  CustomerRecord = CustomerRecord;
  UiConstants = UiConstants;
  BulkActionType = BulkActionType;
  DropdownItemType = DropdownItemType;
  searchBooleanItemObjects = searchBooleanItemObjects;
  CustomerRecordFieldType = CustomerRecordFieldType;

  @ViewChild('importDialog', {static: true})
  importDialog: FileUploadDialogComponent;

  @ViewChild('contactLocationImportDialog', {static: true})
  contactLocationImportDialog: FileUploadDialogComponent;

  @ViewChild('importPreselectDialog', {static: true})
  importPreselectDialog: ModalDirective;

  @ViewChild('createPreselectDialog', {static: true})
  createPreselectDialog: ModalDirective;

  @ViewChild('ownerChangeDialog', {static: true})
  ownerChangeDialog: ModalDirective;

  private _searchTabs?: TabsetComponent;

  @ViewChild('searchTabs') set searchTabs(c: TabsetComponent) {
    if (c && !this._searchTabs) {
      this._searchTabs = c;
      if (this.searchModel.dqlText) {
        this.activeSearchTab = 'dql';
        this._searchTabs.tabs[1].active = true;
      }
      else {
        this.activeSearchTab = 'simple';
        this._searchTabs.tabs[0].active = true;
      }
    }
    if (!c) {
      this._searchTabs = c;
    }
  }

  private dqlSearchContainer?: DqlSearchContainerComponent;

  @ViewChild('dqlSearchContainer') set dqlContainer(c: DqlSearchContainerComponent) {
    if (c) {
      if (!this.dqlSearchContainer) {
        const qt = this.searchModel.dqlText;
        this.dqlSearchContainer = c;
        this.loadDqlModel(() => {
          this.loadDqlSearch(qt);
          this.dqlSearchContainer!.loadContent();
        });
      }
    }
    else {
      this.dqlSearchContainer = undefined;
    }
  }


  queryModel: QueryFieldModel<CustomerRecord.OrderField> = new QueryFieldModel(
    CustomerRecord.OrderField.ID, OrderType.DESC);

  dqlStoredArgs: DqlStoredQueryArgs;

  config: ConfigModel = new ConfigModel();

  customerRecordList: Array<CustomerRecordListModel> = [];
  countryItems: List<AddressModel.CountryItem>;
  activeSearchTab: string = 'simple';
  rightModel: RightModel = RightModel.empty();

  defaultItem = {
    id: null,
    text: '',
    itemName: '',
    key: ''
  };

  disabledItems: DisabledItem[] = [];
  customerList: Customer.Customer[] = [];
  private customersForCreate: Customer.Customer[] = [];
  importableCustomer?: CustomerItem = undefined;
  customers: CustomerItemForSearch[] = [];
  contractNumbers: MultiselectOptionItem<number>[] = [];
  fieldModel: CustomerRecordFieldModel = CustomerRecordFieldModel.empty();

  importPreselectDialogVisible: boolean = false;
  createPreselectDialogVisible: boolean = false;
  ownerChangeDialogVisible: boolean = false;

  ownerChangeModel: OwnerChangeModel = new OwnerChangeModel();

  uploadPath: string;
  contactLocationUploadPath: string = '/customer-record-contact-locations/import-xlsx';
  customerName: string;
  customerContactPerson: boolean;
  breadcrumbParents: BreadcrumbParent[] = [];
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  usersForSearch: AssigneeItem[] = [];
  groupsForSearch: AssigneeItem[] = [];
  usersForChange: AssigneeItem[] = [];
  groupsForChange: AssigneeItem[] = [];

  SelectUtils = SelectUtils;
  Address = Address;

  dropdownSettings: Angular2Multiselects.Settings;
  simpleDropdownSettings: Angular2Multiselects.Settings;
  customerDropdownSettings: Angular2Multiselects.Settings;
  remoteSearchDropdownSettings: Angular2Multiselects.Settings;

  readonly customerId?: number;

  get selectedIds(): number[] {
    return this.customerRecordList.filter(cr => cr.selected).map(cr => cr.customerRecordId);
  }

  get selectedNonContactIds(): number[] {
    return this.customerRecordList.filter(cr => cr.selected && !cr.contactPerson).map(cr => cr.customerRecordId);
  }

  get demoModeEnabled(): boolean {
    return this.configService.getConfiguration().feature_flags.demo_mode_enabled;
  }

  constructor(private transition: Transition,
              injector: Injector,
              private rightService: RightService,
              private customerRecordService: CustomerRecordService,
              private taskRecordService: TaskRecordService,
              private customerRecordSearchService: CustomerRecordSearchService,
              private contractNumberMultiselectProvider: ContractNumberMultiselectProvider,
              private customerService: CustomerService,
              private uiRouter: UIRouter,
              private dialog: MatDialog,
              private userDataLoader: UserDataLoader,
              private userService: UserService,
              private userGroupService: UserGroupService,
              private toasterService: ToasterService,
              private countryService: CountryService,
              private configService: ConfigurationService) {
    super(CustomerRecordSearchModel, injector);
    this.customerId = this.transition.params().customerId ? this.transition.params().customerId : undefined;
    this.uploadPath = '';
    this.dqlStoredArgs = {service: customerService.dqlStoredQueryService, parentId: this.customerId, documentType: undefined};
  }

  getCustomerType(type: Customer.CustomerType): string {
    if (type === 'PERSON') {
      return 'CUSTOMER_RECORD_CREATE_PRESELECT_DIALOG_PERSON';
    }
    else {
      return 'CUSTOMER_RECORD_CREATE_PRESELECT_DIALOG_COMPANY';
    }
  }

  ngOnInit() {
    this.loadConfig();
    this.loadRightModels();
    this.loadCustomer();
    this.loadCountries(() => this.initSearch());
    this.initDropdownSettings();
  }

  initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .build();
    this.customerDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .remoteSearch(true)
      .enableCheckAll(true)
      .disabled(!!this.customerId)
      .labelKey(OptionItem.KEY_TEXT)
      .build();
    this.remoteSearchDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .remoteSearch(true)
      .enableCheckAll(true)
      .build();
    this.simpleDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .build();
  }

  ngAfterViewInit() {
    this.loadDefaultText().subscribe((text: string) => {
      this.defaultItem = this.loadDefaultItem(text);
    });
  }

  private loadCustomer() {
    this.translateService.get('COMMON_CUSTOMERS').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.CUSTOMER_DASHBOARD});
      }
    );
    if (this.customerId) {
      this.customerService.get({
        customerId: this.customerId,
        rights: Set.of(OperationRights.CUSTOMER_RECORD_CREATE)
      }).subscribe(
        (result: Customer.Customer) => {
          this.customerName = result.name;
          this.customerContactPerson = result.contactPerson;
          this.fieldModel = CustomerRecordFieldModel.ofManaged(result.managedFields);
          this.customersForCreate = [];
          const customerGrantedRights = new CustomerRightModel(GrantedPermissionSetResolver.byGrantedRights(result.grantedRights));
          if (customerGrantedRights.customerRecordCreate.hasRight()) {
            this.customersForCreate.push(result);
          }
        }
      );
    }
    else {
      this.fieldModel = CustomerRecordFieldModel.ofManaged(Set.of(...this.config.customerRecordManagedFields));
      this.translateService.get('CUSTOMER_RECORD_GLOBAL_LIST_TITLE').subscribe(
        (result: string) => {
          this.customerName = result;
        }
      );
      this.customerService.query({
        disabled: false,
        rights: Set.of(OperationRights.CUSTOMER_RECORD_CREATE)
      }).subscribe((customerResult: QueryResult<Customer.Customer>) => {
        this.customerList = customerResult.items.toArray();
        this.customersForCreate = [];
        customerResult.items.forEach(customer => {
          if (customer) {
            const customerGrantedRights = new CustomerRightModel(GrantedPermissionSetResolver.byGrantedRights(customer.grantedRights));
            if (customerGrantedRights.customerRecordCreate.hasRight()) {
              this.customersForCreate.push(customer);
            }
          }
        });
      });
    }
  }

  private loadConfig() {
    this.config = this.configService.getConfigurationModel();
  }

  loadSearch(completion: () => void) {
    this.customerRecordSearchService.getSearchData({customerId: this.customerId})
      .subscribe(
        (result: CustomerRecordSearch.SearchDataResult) => {
          this.queryModel.itemsPerPage = result.searchData.itemsPerPage;
          this.queryModel.currentPage = result.searchData.pageNumber;
          this.queryModel.setOrder(result.searchData.order);
          this.searchModel.name = result.searchData.name;
          this.searchModel.externalId = result.searchData.externalId;
          this.searchModel.comment = result.searchData.comment;
          this.searchModel.emailAddress = result.searchData.emailAddress;
          this.searchModel.phoneNumber = result.searchData.phoneNumber;
          this.searchModel.postalAddressCity = result.searchData.postalAddressCity;
          this.searchModel.postalAddressZipCode = result.searchData.postalAddressZipCode;
          this.searchModel.postalAddressStreet = result.searchData.postalAddressStreet;
          this.searchModel.postalAddressHouseNumber = result.searchData.postalAddressHouseNumber;
          this.searchModel.customers = result.searchData.customers;
          this.searchModel.contractNumbers = result.searchData.contractNumbers;
          this.searchModel.ownerUsers = result.searchData.users;
          this.searchModel.ownerUserGroups = result.searchData.userGroups;
          this.searchModel.contactPerson = result.searchData.contactPerson;
          this.searchModel.disabled = result.searchData.disabled;
          this.searchModel.dqlText = result.searchData.dqlText;
          this.searchModel.hasCustomerId = this.customerId !== undefined;
          completion();
        }
      );
  }

  onFirstSearchOpen(): void {
    this.disabledItems = this.initDisabledOptions();
    this.loadCustomerTypes();
    this.loadOwnerUsersForSearch();
    this.loadOwnerUserGroupsForSearch();
  }

  loadCustomerTypes(q?: string) {
    this.customerService.query({
      name: q ? Strings.undefinedOrNonEmpty(q) : undefined,
      orders: Set.of({field: Customer.OrderField.NAME, type: OrderType.ASC}),
      paging: {
        pageNumber: 1,
        numberOfItems: 30
      },
      noProgressBar: true
    }).subscribe(
      (result: QueryResult<Customer.Customer>) => {
        this.customers = result.items.map((c) => {
          return {
            id: c!.customerId,
            text: c!.name,
            disabled: c!.disabled
          };
        }).toArray();
      }
    );
  }

  loadContractNumbers(q?: string) {
    this.contractNumberMultiselectProvider.loadActive(q).subscribe(result => {
      this.contractNumbers = result;
    })
  }

  private loadCountries(completion: () => void) {
    this.countryService.query({}).subscribe((result: QueryResult<Country.Country>) => {
      this.countryItems = AddressModel.CountryItem.fromCountryList(result.items);
      completion();
    });
  }

  loadDqlSearch(queryString?: string) {
    if (queryString) {
      this.dqlSearchContainer!.loadQuery(queryString);
    }
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  onImportSuccess(succeeded: boolean) {
    if (succeeded) {
      this.loadList(1);
    }
  }

  exportXls() {
    const request = this.isAnyCustomerRecordSelected() ? {
      customerId: this.customerId!,
      customerRecordIdSet: this.getSelectedIdSet()
    } : this.getQueryRequest();
    this.customerRecordService.exportXls(request).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName('customer_record.xlsx'));
      }
    );
  }

  exportXlsTemplate() {
    this.customerRecordService.exportXlsTemplate({id: this.customerId!}).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName('customer_record_template.xlsx'));
      }
    );
  }

  orderBy(field: CustomerRecord.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  loadDqlModel(completion?: () => void) {
    this.dqlSearchContainer!.setFields(this.customerService.getDqlModel({customerId: this.customerId}),
      CustomerRecordDqlFieldTextProvider.getHelper(),
      completion ? completion : () => {
      });
  }

  setDisabled(event: any, customerRecord: CustomerRecord.CustomerRecord, disabled: boolean) {
    this.customerRecordService.setDisabled({
      customerId: customerRecord.customerId,
      customerRecordId: customerRecord.customerRecordId,
      disabled: disabled
    }).subscribe(
      (result: CustomerRecord.CustomerRecord) => {
        this.loadList();
      },
      (error: any) => {
        this.loadList();
      }
    );
  }

  loadOwnerUsersForSearch() {
    this.userDataLoader.loadAllWithFields(UserDataLoaderPermissionDeniedStrategy.MISS_ALL,
      undefined, Set.of('id', 'person_name', 'user_name', 'disabled'))
      .subscribe((result: IdentityArray<UserData>) => {
        this.usersForSearch = result.map((u) => {
          return {
            id: u.id,
            itemName: u.person_name + '(' + u.user_name + ')',
            disabled: u.disabled
          };
        });
      });
  }

  loadOwnerUserGroupsForSearch(q?: string) {
    this.userGroupService.query({
      name: q ? Strings.undefinedOrNonEmpty(q) : undefined,
      order: Services.createOrderFieldParameter(UserGroup.Keys.toOrderFieldKey, Set.of(UserGroup.DEFAULT_ORDER)),
      page_number: 1,
      number_of_items: 30,
      no_progress_bar: true
    }).subscribe((result: ResourceQueryResult<UserGroup>) => {
      this.groupsForSearch = result.items.map((g) => {
        return {
          id: g.id,
          itemName: g.name,
          disabled: g.disabled
        };
      });
    });
  }

  loadOwnerUsersForChange(q?: string) {
    this.userService.query({
      q: q ? Strings.undefinedOrNonEmpty(q) : undefined,
      disabled: false,
      order: Services.createOrderFieldParameter(User.Keys.toOrderFieldKey, Set.of(User.DEFAULT_ORDER)),
      page_number: 1,
      number_of_items: 30,
      no_progress_bar: true
    }).subscribe((result: ResourceQueryResult<User>) => {
      this.usersForChange = result.items.map((u) => {
        return {
          id: u.id,
          itemName: u.person_name + '(' + u.user_name + ')',
          disabled: u.disabled
        };
      });
    });
  }

  loadOwnerUserGroupsForChange(q?: string) {
    this.userGroupService.query({
      name: q ? Strings.undefinedOrNonEmpty(q) : undefined,
      order: Services.createOrderFieldParameter(UserGroup.Keys.toOrderFieldKey, Set.of(UserGroup.DEFAULT_ORDER)),
      disabled: false,
      page_number: 1,
      number_of_items: 30,
      no_progress_bar: true
    }).subscribe((result: ResourceQueryResult<UserGroup>) => {
      this.groupsForChange = result.items.map((g) => {
        return {
          id: g.id,
          itemName: g.name,
          disabled: g.disabled
        };
      });
    });
  }

  importCustomer() {
    if (this.customerId) {
      this.openImportDialog(this.customerId);
    }
    else {
      this.customerListForCreate.length === 1
        ? this.openImportDialog(this.customerListForCreate[0].customerId)
        : this.openImportPreselectDialog();
    }
  }

  openImportPreselectDialog() {
    this.importPreselectDialogVisible = true;
    this.importableCustomer = undefined;
    this.importPreselectDialog.show();
  }

  closeImportPreSelectDialog() {
    this.importPreselectDialogVisible = false;
    this.importableCustomer = undefined;
    this.importPreselectDialog.hide();
  }

  openImportDialog(customerId: number) {
    this.closeImportPreSelectDialog();
    this.uploadPath = '/customers/' + customerId + '/records/import-xls';
    this.importDialog.showDialog();
  }

  openCreatePreselectDialog() {
    this.createPreselectDialogVisible = true;
    this.createPreselectDialog.show();
  }

  closeCreatePreselectDialog() {
    this.createPreselectDialogVisible = false;
    this.createPreselectDialog.hide();
  }

  createNewCustomer(customer?: Customer.Customer) {
    this.closeCreatePreselectDialog();
    if (customer) {
      this.uiRouter.stateService.go(StateName.CUSTOMER_RECORD_CREATE, {customerId: customer.customerId});
    }
  }

  onCustomerRecordPopoverShown(customerRecord: CustomerRecordListModel) {
    if (customerRecord.taskRecordData) {
      return;
    }
    this.taskRecordService.globalQuery({
      baseCustomerRecord: customerRecord.customerRecordId,
      paging: {
        pageNumber: 1,
        numberOfItems: 1
      },
      fields: Set.of('id', 'name', 'state', 'creation_time', 'task_id'),
      orders: Set.of({field: TaskRecord.OrderField.CREATION_TIME, type: OrderType.DESC}),
      noProgressBar: true
    }).subscribe(result => {
      const data: CustomerRecordTaskRecordData = new CustomerRecordTaskRecordData();
      data.taskRecordCount = result.pagingResult.currentNumberOfItems;
      if (result.items.size > 0) {
        const tr = result.items.get(0)!;
        data.latestTaskRecordData = {
          id: tr.taskRecordId,
          taskId: tr.taskRecordId,
          name: tr.name,
          creationTime: tr.creationTime,
          state: TaskRecordStateMachine.taskRecordStates.get(tr.state)
        };
      }
      customerRecord.taskRecordData = data;
    });
  }

  checkCustomerRecordIds(type: BulkActionType) {
    if (type === BulkActionType.OWNER_CHANGE) {
      this.ownerChangeModel = new OwnerChangeModel();
    }
    else {
      this.searchModel.contactPersonType = SearchBooleanItem.NO;
    }
    if (!this.isAnyCustomerRecordSelected()) {
      this.createListObservable(undefined, Set.of('id'))
        .subscribe((result: QueryResult<CustomerRecord.CustomerRecord>) => {
          const ids = result.items.toArray().map(cr => cr.customerRecordId);
          if (type === BulkActionType.OWNER_CHANGE) {
            this.ownerChangeModel.customerRecordIds = ids;
            this.openOwnerChangeDialog();
          }
          else {
            if (ids.length > 100) {
              this.toasterService.pop({
                timeout: UiConstants.ToastTimeoutLong,
                type: UiConstants.toastTypeError,
                title: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_TITLE),
                body: this.translateService.instant(StringKey.TASK_RECORD_QUICK_CREATE_BATCH_CUSTOMER_RECORD_ERROR)
              });
              return;
            }
            if (ids.length === 0) {
              this.toasterService.pop({
                timeout: UiConstants.ToastTimeoutLong,
                type: UiConstants.toastTypeError,
                title: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_TITLE),
                body: this.translateService.instant(StringKey.TASK_RECORD_QUICK_CREATE_BATCH_CUSTOMER_RECORD_ERROR_ZERO)
              });
              return;
            }
            TaskRecordCreateMaterialDialogComponent.openDialog(this.dialog, {
              mode: TaskRecordCreateDialogMode.QUICK_CREATE_BY_CUSTOMER_RECORDS,
              customerRecordIds: ids,
            }, (result) => {
              if (result?.success) {
              }
            });
          }
        });
    }
    else {
      if (type === BulkActionType.OWNER_CHANGE) {
        this.ownerChangeModel.customerRecordIds = this.customerRecordList.filter(cr => cr.selected).map(cr => cr.customerRecordId);
        this.openOwnerChangeDialog();
      }
      else {
        TaskRecordCreateMaterialDialogComponent.openDialog(this.dialog, {
          mode: TaskRecordCreateDialogMode.QUICK_CREATE_BY_CUSTOMER_RECORDS,
          customerRecordIds: this.selectedNonContactIds,
        }, (result) => {
          if (result?.success) {
          }
        });
      }
    }
  }

  openOwnerChangeDialog() {
    this.loadOwnerUsersForChange();
    this.loadOwnerUserGroupsForChange();
    this.ownerChangeDialogVisible = true;
    this.ownerChangeDialog.show();
  }

  closeOwnerChangeDialog() {
    this.ownerChangeDialogVisible = false;
    this.ownerChangeDialog.hide();
  }

  changeOwners() {
    this.customerRecordService.changeOwners({
      customerRecordIds: Set.of(...this.ownerChangeModel.customerRecordIds),
      changeType: this.ownerChangeModel.changeType,
      ownerUserIds: this.ownerChangeModel.changeUsers ? Set.of(...this.ownerChangeModel.ownerUsers.map(u => u.id)) : undefined,
      ownerUserGroupIds: this.ownerChangeModel.changeUserGroups ?
        Set.of(...this.ownerChangeModel.ownerUserGroups.map(u => u.id)) : undefined,
    }).subscribe(result => {
      this.loadList();
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutShort,
        type: UiConstants.toastTypeSuccess,
        title: this.translateService.instant(StringKey.CUSTOMER_RECORD_DATA_MODIFY),
        body: this.translateService.instant(StringKey.CUSTOMER_RECORD_DATA_MODIFY_SUCCESS)
      });
    });
    this.closeOwnerChangeDialog();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  loadList(pageNumber?: number) {
    this.deselectAll();
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    this.createListObservable(requestedPage, Set.of('id',
      'name',
      'external_id',
      'disabled',
      'creation_time',
      'update_time',
      'customer_id',
      'phone_numbers',
      'email_addresses',
      'postal_address',
      'owner_users',
      'main_contract_number',
      'owner_groups'
    )).subscribe(
      (result: QueryResult<CustomerRecord.CustomerRecord>) => {
        this.customerRecordList = this.toModel(result.items.toArray());
        this.queryModel.currentPage = requestedPage;
        this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
        this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
      });
  }

  private createListObservable(requestedPage?: number, fields?: Set<string>) {
    if (this.customerId) {
      return this.customerRecordService.query(this.getQueryRequest(requestedPage, fields));
    }
    else {
      return this.customerRecordService.globalQuery(this.getGlobalQueryRequest(requestedPage, fields));
    }
  }

  getQueryRequest(requestedPage?: number, fields?: Set<string>): CustomerRecord.QueryRequest {
    const order = this.queryModel.getOrder();
    return {
      customerId: this.customerId!,
      orders: Set.of(order),
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined,
      disabled: this.getDisabled(),
      name: Strings.undefinedOrNonEmpty(this.searchModel.name),
      externalId: Strings.undefinedOrNonEmpty(this.searchModel.externalId),
      comment: Strings.undefinedOrNonEmpty(this.searchModel.comment),
      contractNumberId: Set.of(...this.searchModel.contractNumbers.map(c => c.id!)),
      emailAddress: Strings.undefinedOrNonEmpty(this.searchModel.emailAddress),
      phoneNumber: Strings.undefinedOrNonEmpty(this.searchModel.phoneNumber),
      postalAddressCity: Strings.undefinedOrNonEmpty(this.searchModel.postalAddressCity),
      postalAddressZipCode: Strings.undefinedOrNonEmpty(this.searchModel.postalAddressZipCode),
      postalAddressStreet: Strings.undefinedOrNonEmpty(this.searchModel.postalAddressStreet),
      postalAddressHouseNumber: Strings.undefinedOrNonEmpty(this.searchModel.postalAddressHouseNumber),
      ownerUserIds: Set.of(...this.searchModel.ownerUsers.map(user => user.id)),
      ownerUserGroupIds: Set.of(...this.searchModel.ownerUserGroups.map(group => group.id)),
      contactPerson: this.searchModel.contactPerson !== SearchBooleanItem.ALL
        ? this.searchModel.contactPerson === SearchBooleanItem.YES
        : undefined,
      contactPersonType: undefined,
      fields: fields,
      rights: Set.of(...[OperationRights.CUSTOMER_RECORD_UPDATE, OperationRights.CUSTOMER_RECORD_DISABLE]),
      dqlText: this.searchModel.dqlText,
    };
  }

  getGlobalQueryRequest(requestedPage?: number, fields?: Set<string>): CustomerRecord.GlobalQueryRequest {
    const order = this.queryModel.getOrder();
    return {
      parentDisabled: false,
      orders: Set.of(order),
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined,
      disabled: this.getDisabled(),
      name: Strings.undefinedOrNonEmpty(this.searchModel.name),
      externalId: Strings.undefinedOrNonEmpty(this.searchModel.externalId),
      comment: Strings.undefinedOrNonEmpty(this.searchModel.comment),
      emailAddress: Strings.undefinedOrNonEmpty(this.searchModel.emailAddress),
      phoneNumber: Strings.undefinedOrNonEmpty(this.searchModel.phoneNumber),
      postalAddressCity: Strings.undefinedOrNonEmpty(this.searchModel.postalAddressCity),
      postalAddressZipCode: Strings.undefinedOrNonEmpty(this.searchModel.postalAddressZipCode),
      postalAddressStreet: Strings.undefinedOrNonEmpty(this.searchModel.postalAddressStreet),
      postalAddressHouseNumber: Strings.undefinedOrNonEmpty(this.searchModel.postalAddressHouseNumber),
      customerIds: Set.of(...this.searchModel.customers.map(c => c.id!)),
      contractNumberId: Set.of(...this.searchModel.contractNumbers.map(c => c.id!)),
      ownerUserIds: Set.of(...this.searchModel.ownerUsers.map(user => user.id)),
      ownerUserGroupIds: Set.of(...this.searchModel.ownerUserGroups.map(group => group.id)),
      contactPerson: undefined,
      contactPersonType: this.searchModel.contactPersonType !== SearchBooleanItem.ALL
        ? this.searchModel.contactPersonType === SearchBooleanItem.YES
        : undefined,
      fields: fields,
      rights: Set.of(...[OperationRights.CUSTOMER_RECORD_UPDATE, OperationRights.CUSTOMER_RECORD_DISABLE]),
      dqlText: this.searchModel.dqlText,
    };
  }

  private toModel(customerRecordList: CustomerRecord.CustomerRecord[]): CustomerRecordListModel[] {
    return customerRecordList.map((record: CustomerRecord.CustomerRecord): CustomerRecordListModel => {
      const customer: Customer.Customer | undefined = this.customerList.find((c) => c.customerId === record.customerId);
      const phoneNumbers: AddressModel.PhoneNumberModel[] = [];
      record.phoneNumbers.forEach((address) => {
        const model = new AddressModel.PhoneNumberModel();
        model.load(address);
        phoneNumbers.push(model);
      });
      const emailAddresses: AddressModel.EmailAddressModel[] = [];
      record.emailAddresses.forEach((address) => {
        const model = new AddressModel.EmailAddressModel();
        model.load(address);
        emailAddresses.push(model);
      });
      const postalAddress = new AddressModel.PostalAddressModel();
      postalAddress.loadCountryItems(this.countryItems, this.configService.getDefaultSelectedCountryCode());
      postalAddress.load(record.postalAddress);
      return {
        selected: false,
        customerRecordId: record.customerRecordId,
        customerId: record.customerId,
        customerIcon: customer ? customer.icon : undefined,
        customerName: customer ? customer.name : undefined,
        customerRecord: record,
        externalId: record.externalId,
        disabled: record.disabled,
        creationTime: record.creationTime,
        updateTime: record.updateTime,
        name: record.name,
        contactPerson: customer ? customer.contactPerson : false,
        comment: record.comment,
        mainContractNumber: record.mainContractNumber,
        phoneNumbers: phoneNumbers,
        emailAddresses: emailAddresses,
        ownerUsers: record.ownerUsers,
        ownerGroups: record.ownerGroups,
        postalAddress: postalAddress,
        rights: new CustomerRecordRightModel(GrantedPermissionSetResolver.byGrantedRights(record.grantedRights))
      };
    });
  }

  loadDefaultText(): Observable<string> {
    return this.translateService.get(StringKey.COMMON_VALUE_UNSELECTED);
  }

  loadDefaultItem(text: string) {
    return {
      id: null,
      text: text,
      itemName: text,
      key: text
    };
  }

  getDisabled(): boolean | undefined {
    if (this.searchModel.disabled === DisabledEnum.FALSE) {
      return false;
    }
    else if (this.searchModel.disabled === DisabledEnum.TRUE) {
      return true;
    }
    return undefined;
  }

  onSearchClicked() {
    this.searchModel.dqlText = undefined;
    this.loadList(1);
  }

  onDqlSearchClicked() {
    const dqlQueryString = this.dqlSearchContainer!.getQueryString();
    if (dqlQueryString) {
      this.searchModel.clear();
    }
    this.searchModel.dqlText = dqlQueryString;
    this.loadList(1);
  }

  onSearchReset() {
    this.customerRecordSearchService.resetSearchData({customerId: this.customerId}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.loadList(1);
        });
      }
    );
    if (this.dqlSearchContainer) {
      this.dqlSearchContainer.clearSearch();
    }
  }

  private saveSearch() {
    const request = {
      customerId: this.customerId,
      searchData: {
        itemsPerPage: this.queryModel.itemsPerPage,
        pageNumber: this.queryModel.currentPage,
        order: this.queryModel.getOrder(),
        name: this.searchModel.name,
        externalId: this.searchModel.externalId,
        comment: this.searchModel.comment,
        emailAddress: this.searchModel.emailAddress,
        phoneNumber: this.searchModel.phoneNumber,
        postalAddressCity: this.searchModel.postalAddressCity,
        postalAddressZipCode: this.searchModel.postalAddressZipCode,
        postalAddressStreet: this.searchModel.postalAddressStreet,
        postalAddressHouseNumber: this.searchModel.postalAddressHouseNumber,
        customers: this.searchModel.customers,
        contractNumbers: this.searchModel.contractNumbers,
        disabled: this.searchModel.disabled,
        users: this.searchModel.ownerUsers,
        userGroups: this.searchModel.ownerUserGroups,
        contactPerson: this.searchModel.contactPerson,
        contactPersonType: this.searchModel.contactPersonType,
        dqlText: this.searchModel.dqlText
      }
    };
    this.customerRecordSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  get customerListForCreate() {
    return this.customersForCreate;
  }

  createCustomer() {
    this.customerListForCreate.length === 1 ? this.createNewCustomer(this.customerListForCreate[0]) : this.openCreatePreselectDialog();
  }

  toggleEachCustomerRecord() {
    const eachCustomerRecordSelected = !this.eachCustomerRecordSelected;
    this.customerRecordList.forEach(cr => {
      cr.selected = eachCustomerRecordSelected;
    });
  }

  deselectAll() {
    this.customerRecordList.forEach(cr => cr.selected = false);
  }

  isAnyCustomerRecordSelected(): boolean {
    return this.customerRecordList.filter(cr => cr.selected).length > 0;
  }

  getSelectedIdSet(): Set<number> {
    const ids: number[] = [];
    this.customerRecordList.forEach((cr) => {
      if (cr.selected) {
        ids.push(cr.customerRecordId);
      }
    });
    return Set.of(...ids);
  }

  get eachCustomerRecordSelected(): boolean {
    let selected = this.customerRecordList.length > 0;
    this.customerRecordList.forEach((cr) => {
      selected = selected && cr.selected;
    });
    return selected;
  }

  openQuickCreateDialog() {
    CustomerRecordQuickCreateMaterialDialogComponent.openDialog(
      this.dialog,
      {customerId: this.customerId},
      (result) => {
        if (result?.success) {
          this.onCustomerRecordCreated();
        }
      }
    )
  }

  onCustomerRecordCreated() {
    this.loadList(1);
  }

  showDisabledDialog() {
    this.dialog.open(DemoModeFeatureDisabledDialogComponent);
  }

  ngOnDestroy() {
    this.saveSearch();
  }

  showTaskCreate(customer: CustomerRecordListModel) {
    const addr = !customer.postalAddress
      ? ''
      : Address.PostalAddressMapper.toString(customer.postalAddress, this.config.postalAddressFormat);

    const item = {
      id: customer.customerRecordId,
      text: customer.name,
      itemName: customer.name + ' (' + addr + ')',
      customerRecord: customer.customerRecord,
      address: addr
    };

    TaskRecordCreateMaterialDialogComponent.openDialog(this.dialog, {
      mode: TaskRecordCreateDialogMode.QUICK_CREATE,
      customerRecordItem: item,
      navigateToTaskRecordEdit: true
    }, (result) => {
      if (result?.success) {
      }
    });
  }

  downloadContactLocationsTemplate() {
    this.customerRecordService.downloadContactLocationsTemplate({}).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName('customer-record-contact-locations-xlsx-template.xlsx'));
      });
  }
}

export class CustomerRecordSearchModel extends SearchModel {

  _hasCustomerId: boolean;

  name: string;
  externalId: string;
  comment: string;
  emailAddress: string;
  phoneNumber: string;
  postalAddressCity: string;
  postalAddressZipCode: string;
  postalAddressStreet: string;
  postalAddressHouseNumber: string;
  customers: CustomerItemForSearch[];
  contractNumbers: MultiselectOptionItem<number>[];
  disabled?: DisabledEnum;
  ownerUsers: AssigneeItem[];
  ownerUserGroups: AssigneeItem[];
  contactPerson: SearchBooleanItem = SearchBooleanItem.ALL;
  contactPersonType: SearchBooleanItem = SearchBooleanItem.ALL;
  dqlText?: string = undefined;

  set hasCustomerId(value: boolean) {
    if (value) {
      this.contactPersonType = SearchBooleanItem.ALL;
    }
    else {
      this.contactPersonType = SearchBooleanItem.NO;
    }
    this._hasCustomerId = value;
  }

  public isEmpty(): boolean {
    return this.name.length === 0
      && this.externalId.length === 0
      && this.comment.length === 0
      && this.emailAddress.length === 0
      && this.phoneNumber.length === 0
      && this.postalAddressCity.length === 0
      && this.postalAddressZipCode.length === 0
      && this.postalAddressStreet.length === 0
      && this.postalAddressHouseNumber.length === 0
      && this.customers.length === 0
      && this.contractNumbers.length === 0
      && this.disabled === DisabledEnum.FALSE
      && this.ownerUsers.length === 0
      && this.ownerUserGroups.length === 0
      && this.contactPerson === SearchBooleanItem.ALL
      && ((!this._hasCustomerId && this.contactPersonType === SearchBooleanItem.NO) ||
        (this._hasCustomerId && this.contactPersonType === SearchBooleanItem.ALL))
      && this.dqlText === undefined
      ;
  }

  public clear() {
    this.name = '';
    this.externalId = '';
    this.comment = '';
    this.emailAddress = '';
    this.phoneNumber = '';
    this.postalAddressCity = '';
    this.postalAddressZipCode = '';
    this.postalAddressStreet = '';
    this.postalAddressHouseNumber = '';
    this.customers = [];
    this.contractNumbers = [];
    this.disabled = DisabledEnum.FALSE;
    this.ownerUsers = [];
    this.ownerUserGroups = [];
    this.contactPerson = SearchBooleanItem.ALL;
    this.contactPersonType = this._hasCustomerId ? SearchBooleanItem.ALL : SearchBooleanItem.NO;
    this.dqlText = undefined;
  }

}

class OwnerChangeModel {

  customerRecordIds: number[] = [];

  ownerUsers: AssigneeItem[] = [];

  ownerUserGroups: AssigneeItem[] = [];

  changeUsers: boolean = false;

  changeUserGroups: boolean = false;

  changeType: CustomerRecord.OwnerChangeType = 'ADD';

}

enum BulkActionType {
  OWNER_CHANGE,
  TASK_RECORD_CREATE,
}
