import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { TaskRecord } from '../../lib/task/task-record.service';
import { RightModel } from '../../app.rights';
import { RightResolver, RightService } from '../../lib/right.service';

@Component({
  selector: 'app-sidebar-right',
  templateUrl: './sidebar-right.component.html',
  styleUrls: ['./sidebar-right.component.scss']
})
export class SidebarRightComponent implements OnInit, AfterViewInit {

  @Input()
  brandLogoSrc: string | undefined;

  @ViewChild('notification_list', { static: true })
  notificationList: RightSidebarElement;

  sidebarElements: RightSidebarElement[] = [];

  rightSidebarClosed = true;

  rightModel: RightModel = RightModel.empty();

  get hasNewNotification(): boolean {
    let hasNewNotification = false;
    this.sidebarElements.forEach((element) => {
      hasNewNotification = hasNewNotification || element.hasNewNotification;
    })
    return hasNewNotification;
  }

  constructor(private rightService: RightService) { }

  ngOnInit() {
  }

  private loadRightModels(completion: () => void) {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        completion();
      }
    );
  }

  ngAfterViewInit(): void {
    this.loadRightModels(() => {
      if (this.rightModel.messageRead.hasRight()) {
        this.sidebarElements.push(this.notificationList);
      }
      this.sidebarElements.forEach(e => e.initComponent());
    })
  }

  toggleRightSidebar(elementType: RightSidebarElementType) {
    const sidebarClosed = this.rightSidebarClosed;
    setTimeout(() => {
      this.rightSidebarClosed = !sidebarClosed;
      this.sidebarElements.find((element) => element.elementType === elementType)!.onSidebarToggle(!this.rightSidebarClosed);
    });
  }

  closeRightSidebar() {
    this.hideAllElements();
    this.rightSidebarClosed = true;
  }

  refresh() {
    this.sidebarElements.forEach((element) => {
      element.refresh();
    })
  }

  hideAllElements() {
    this.sidebarElements.forEach((element) => {
      element.setVisibility(false);
    })
  }

}

export interface RightSidebarElement {
  elementType: RightSidebarElementType;
  visible: boolean;
  hasNewNotification: boolean;
  setVisibility(visible: boolean): void;
  initComponent(): void;
  onSidebarToggle(visible: boolean): void;
  refresh(): void;
}

export type RightSidebarElementType = 'NOTIFICATION_LIST';
