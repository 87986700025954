/* eslint-disable */
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { QueryFieldModel, UiConstants } from '../../../util/core-utils';
import { User, UserService } from '../../../lib/user.service';
import { OrderType, QueryResult, ResourceQueryResult, Services } from '../../../lib/util/services';
import { ClientDocument, ClientDocumentService } from '../../../lib/client-document/client-document.service';
import { Dates, OffsetDateTime } from '../../../lib/util/dates';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { CustomerRecord, CustomerRecordService } from '../../../lib/customer/customer-record.service';
import { combineLatest, empty, Observable } from 'rxjs';
import { Set } from 'immutable';
import { TranslateService } from '@ngx-translate/core';
import { DownloadedFile } from '../../../lib/util/downloaded-files';
import { saveAs } from 'file-saver';
import { DqlSearchContainerComponent } from '../../dql-search/dql-search-container/dql-search-container.component';
import { ColonnadeDqlService } from '../../../lib/colonnade-insurance/colonnade-dql.service';
import {
  ColonnadeInsuranceSearch,
  ColonnadeInsuranceSearchService
} from '../../../lib/colonnade-insurance/colonnade-insurance-search.service';
import { map } from 'rxjs/operators';
import { DqlStoredQueryArgs } from '../../dql-search/dql-search-container/dql-stored-query.args';
import { RightModel } from '../../../app.rights';
import { RightResolver, RightService } from '../../../lib/right.service';
import { GeneralEmail, GeneralEmailService } from '../../../lib/email-template/general-email.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppValidators } from '../../../util/app-validators';
import { StringKey } from '../../../app.string-keys';
import { ToasterService } from '../../../fork/angular2-toaster/src/toaster.service';

/* eslint-enable */

@Component({
  selector: 'app-colonnade-insurance-list',
  templateUrl: './colonnade-insurance-list.component.html',
  styleUrls: ['./colonnade-insurance-list.component.scss']
})
export class ColonnadeInsuranceListComponent implements OnInit, AfterViewInit, OnDestroy {
  static readonly manualEmailTemplate = 'colonnade_mail_template_manual';
  private readonly documentType = 'colonnade_insurance_1';

  ClientDocument = ClientDocument;
  UiConstants = UiConstants;
  queryModel: QueryFieldModel<ClientDocument.OrderField> = new QueryFieldModel(ClientDocument.OrderField.CREATION_TIME, OrderType.DESC);

  insuranceList: ClientDocumentModel[] = [];

  dqlStoredArgs: DqlStoredQueryArgs;
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  rightModel: RightModel = RightModel.empty();
  showSearch: boolean = true;
  searchModel: ColonnadeInsuranceListSearchModel = new ColonnadeInsuranceListSearchModel();
  private dqlSearchContainer?: DqlSearchContainerComponent;

  @ViewChild('dqlSearchContainer') set dqlContainer(c: DqlSearchContainerComponent) {
    if (c) {
      if (!this.dqlSearchContainer) {
        const qt = this.searchModel.dqlText;
        this.dqlSearchContainer = c;
        this.loadDqlModel(() => {
          this.loadDqlSearch(qt);
          this.dqlSearchContainer!.loadContent();
        });
      }
    }
    else {
      this.dqlSearchContainer = undefined;
    }
  }

  @ViewChild('emailSendDialog', {static: true}) emailSendDialog: ModalDirective;

  private _selectedInsuraces: string[] = [];
  emailSendDialogVisible: boolean = false;

  emailSendForm: FormGroup;
  emailSendModel: EmailSendModel = new EmailSendModel();

  private paymentTypes = [
    {id: 'csoportos', stringKey: 'INSURANCE_FORM_PAYMENT_TYPE_GROUP', iconClass: 'icomoon-insurance-payment-group'},
    {id: 'atutalas', stringKey: 'INSURANCE_FORM_PAYMENT_TYPE_TRANSFER', iconClass: 'icomoon-insurance-payment-transfer'},
    {id: 'munkaberbol_levonas', stringKey: 'INSURANCE_FORM_PAYMENT_TYPE_PAYROLL', iconClass: 'icomoon-insurance-payment-payroll'},
  ];

  constructor(private userService: UserService,
              private fb: FormBuilder,
              private translateService: TranslateService,
              private rightService: RightService,
              private customerRecordService: CustomerRecordService,
              private clientDocumentService: ClientDocumentService,
              private colonnadeInsuranceSearchService: ColonnadeInsuranceSearchService,
              private toasterService: ToasterService,
              private emailSerivce: GeneralEmailService) {
    this.dqlStoredArgs = {service: clientDocumentService.dqlStoredQueryService, parentId: undefined, documentType: this.documentType};
    this.emailSendForm = fb.group({
      recipientName: ['', Validators.required],
      recipientEmailAddress: ['', [Validators.required, AppValidators.validateOptionalEmail]]
    });

  }

  ngOnInit() {
    this.initBreadcrumb();
    this.loadRightModel(() => {
      this.loadSearch(() => {
        this.showSearch = !this.searchModel.isEmpty();
        this.loadList();
      });
    });
  }

  ngAfterViewInit() {
  }

  private loadRightModel(completion: () => void) {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        completion();
      }
    );
  }

  private initBreadcrumb() {
    this.translateService.get('INSURANCE_LIST').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
  }


  private loadSearch(completion: () => void) {
    let obs: Observable<SearchLoadResult>;
    obs = this.colonnadeInsuranceSearchService.getSearchData({}).pipe(map((storedSearchData) => {
      const result: SearchLoadResult = {
        storedSearchData: storedSearchData
      };
      return result;
    }));
    obs.subscribe(
      (result: SearchLoadResult) => {
        this.postInitSearch(result.storedSearchData);
        completion();
      }
    );
  }

  private loadList(pageNumber?: number) {
    if (this.rightModel.clientDocumentRead.hasRight()) {
      const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
      const order = this.queryModel.getOrder();
      this.insuranceList = [];
      this.clientDocumentService.query({
        submittedOnly: true,
        dql: this.searchModel.dqlText,
        documentType: this.documentType,
        orders: Set.of(order),
        paging: requestedPage ? {
          pageNumber: requestedPage,
          numberOfItems: this.queryModel.itemsPerPage
        } : undefined
      }).subscribe((result: QueryResult<ClientDocument.ClientDocument>) => {
        this._selectedInsuraces = [];
        const customerRecordIds: number[] = [];
        const userIds: number[] = [];
        result.items.forEach((item: ClientDocument.ClientDocument) => {
          if (item.customerRecordId) {
            customerRecordIds.push(item.customerRecordId);
          }
        });
        result.items.forEach((item: ClientDocument.ClientDocument) => {
          if (item.submitterUserId) {
            userIds.push(item.submitterUserId);
          }
        });
        this.createCombinedObservable(customerRecordIds, userIds).subscribe(
          (combinedResult: CombinedResult) => {
            this.insuranceList = [];
            result.items.forEach((item: ClientDocument.ClientDocument) => {
              const client = combinedResult.customerRecord.find((c) => c!.customerRecordId === item.customerRecordId);
              const submitter = combinedResult.user.items.find((u) => u.id === item.submitterUserId);
              const insuranceItem = new ClientDocumentModel();
              insuranceItem.id = item.id;
              insuranceItem.externalId = item.externalId;
              insuranceItem.clientName = client ? client.name : '';
              insuranceItem.creatorName = submitter ? submitter.person_name : '';
              if (item.form.insuranceProduct && item.form.insuranceProduct.text) {
                insuranceItem.insuranceProductType += item.form.insuranceProduct.text;
              }
              if (item.form.sponsor && item.form.sponsor.text) {
                insuranceItem.sponsorName += item.form.sponsor.text;
              }
              if (item.form.insuranceType && item.form.insuranceType.text) {
                insuranceItem.insuranceMode += item.form.insuranceType.text;
              }
              if (item.form.insuranceLevel && item.form.insuranceLevel.level) {
                insuranceItem.insuranceLevel += item.form.insuranceLevel.level;
              }
              if (item.form.paymentType && item.form.paymentType.code) {
                insuranceItem.paymentTypeCode = item.form.paymentType.code;
              }
              if (item.form.paymentType && item.form.paymentType.text) {
                insuranceItem.paymentTypeText = item.form.paymentType.text;
              }
              insuranceItem.hasPdf = item.hasPdf;
              insuranceItem.submitTime = item.submitTime;
              insuranceItem.deleted = item.state === ClientDocument.State.DELETED;
              insuranceItem.deletionCause = item.form.deletionCause;
              insuranceItem.deletionDate = item.form.deletionDate;
              this.insuranceList.push(insuranceItem);
            });
          });
        this.queryModel.currentPage = requestedPage;
        this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
        this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
      });
    }
  }

  loadDqlSearch(queryString?: string) {
    this.dqlSearchContainer!.loadQuery(queryString);
  }

  loadDqlModel(completion?: () => void) {
    this.dqlSearchContainer!.setFields(ColonnadeDqlService.getDqlModel(),
      ColonnadeDqlService.getHelper(),
      completion ? completion : () => {
      });
  }

  private postInitSearch(storedSearchData: ColonnadeInsuranceSearch.SearchDataResult) {
    this.searchModel.dqlText = storedSearchData.searchData.dqlText;
  }

  private createCombinedObservable(customerRecordIds: number[], userIds: number[]): Observable<CombinedResult> {
    return combineLatest(
      userIds.length === 0 ? empty() : this.userService.query({id: userIds.join(',')}),
      this.customerRecordService.listQuery({
        customerRecordIdSet: customerRecordIds.length > 0 ? Set.of(...customerRecordIds) : undefined
      }),
      (user: ResourceQueryResult<User>,
       customerRecord: CustomerRecord.CustomerRecord[]) => {
        return {
          user: user,
          customerRecord: customerRecord
        };
      }
    );
  }

  private downloadPdf(id: string) {
    this.clientDocumentService.downloadPdf({
      id: id,
      documentType: this.documentType
    }).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName('colonnade-finance-1.pdf'));
      });
  }

  downloadZip() {
    this.clientDocumentService.downloadZip({
      ids: this._selectedInsuraces.length > 0 ? Set.of(...this._selectedInsuraces) : undefined,
      submittedOnly: true,
      dql: this.searchModel.dqlText,
      documentType: this.documentType,
    }).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName('colonnade-finance-1.zip'));
      });
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: ClientDocument.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  onDqlSearchClicked() {
    this.searchModel.clear();
    this.searchModel.dqlText = this.dqlSearchContainer!.getQueryString();
    this.loadList(1);
  }

  private saveSearch() {
    const searchData = {
      dqlText: this.searchModel.dqlText
    };
    this.colonnadeInsuranceSearchService.setSearchData({searchData: searchData}).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  get allInsurancesSelected(): boolean {
    return this._selectedInsuraces.length === this.insuranceList.length && this.insuranceList.length !== 0;
  }

  allSelected() {
    if (!this.allInsurancesSelected) {
      this._selectedInsuraces = this.insuranceList.map(i => i.id);
    }
    else {
      this._selectedInsuraces = [];
    }
  }

  toggleSelected(id: string) {
    const idx = this._selectedInsuraces.findIndex(value => value === id);
    if (idx > -1) {
      this._selectedInsuraces.splice(idx, 1);
    }
    else {
      this._selectedInsuraces.push(id);
    }
  }

  isSelected(id: string) {
    const idx = this._selectedInsuraces.findIndex(value => value === id);
    return idx >= 0;
  }

  openEmailDialog() {
    this.queryPdfIds().subscribe((ids: number[]) => {
      this.emailSendModel = new EmailSendModel();
      this.emailSendModel.generalPdfIds = ids;
      this.emailSendDialogVisible = true;
      this.emailSendDialog.show();
    });
  }

  closeEmailSendDialog() {
    this.emailSendForm.reset();
    this.emailSendDialogVisible = false;
    this.emailSendDialog.hide();
  }

  sendEmail() {
    if (!this.emailSendForm.valid) {
      this.emailSendForm.get('recipientName')!.markAsTouched();
      this.emailSendForm.get('recipientEmailAddress')!.markAsTouched();
      return;
    }
    const request = this.emailSendModel.toRequest();
    this.closeEmailSendDialog();
    this.emailSerivce.sendEmail(request).subscribe((result: GeneralEmail.Result) => {
      switch (result.emailResult) {
        case 'SUCCEED':
          this.toasterService.pop({
            timeout: UiConstants.ToastTimeoutLong,
            type: UiConstants.toastTypeSuccess,
            title: this.translateService.instant(StringKey.COMMON_SUCCESS),
            body: this.translateService.instant(StringKey.GENERAL_EMAIL_SEND_SUCCESS)
          });
          break;
        case 'FAILED':
        case 'INVALID_REQUEST':
          this.toasterService.pop({
            timeout: UiConstants.ToastTimeoutLong,
            type: UiConstants.toastTypeError,
            title: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_TITLE),
            body: this.translateService.instant(StringKey.GENERAL_EMAIL_SEND_RESULT_FAILED)
          });
          break;
        case 'INVALID_RECIPIENT':
          this.toasterService.pop({
            timeout: UiConstants.ToastTimeoutLong,
            type: UiConstants.toastTypeError,
            title: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_TITLE),
            body: this.translateService.instant(StringKey.GENERAL_EMAIL_SEND_RESULT_INVALID_RECIPIENT)
          });
          break;

      }
    }, error => {
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutLong,
        type: UiConstants.toastTypeError,
        title: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_TITLE),
        body: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_MESSAGE)
      });
    });
  }

  queryPdfIds(): Observable<number[]> {
    return this.clientDocumentService.query({
      ids: this._selectedInsuraces.length > 0 ? Set.of(...this._selectedInsuraces) : undefined,
      submittedOnly: true,
      dql: this.searchModel.dqlText,
      documentType: this.documentType,
    }).pipe(map((result: QueryResult<ClientDocument.ClientDocument>) => {
      const pdfIds: number[] = result.items.filter(i => i !== null && i !== undefined && i.hasPdf).map(i => i!.pdfId!).toArray();
      return pdfIds;
    }));
  }

  getPaymentTypeIcon(paymentTypeCode: string) {
    const item = this.paymentTypes.find(p => p.id === paymentTypeCode);
    if (!item) {
      return '';
    }
    return item.iconClass;
  }

  ngOnDestroy(): void {
    this.saveSearch();
  }

}

interface CombinedResult {
  user: ResourceQueryResult<User>;
  customerRecord: CustomerRecord.CustomerRecord[];
}

export class ClientDocumentModel {
  id: string;
  externalId: string = '';
  clientName: string = '';
  sponsorName: string = '';
  insuranceProductType: string = '';
  paymentTypeCode: string = '';
  paymentTypeText: string = '';
  insuranceMode: string = '';
  insuranceLevel: string = '';
  creatorName: string = '';
  hasPdf: boolean = false;
  submitTime: OffsetDateTime = Dates.emptyOffsetDateTime();
  deleted?: boolean = false;
  deletionCause?: string;
  deletionDate: OffsetDateTime = Dates.emptyOffsetDateTime();
}

export class ColonnadeInsuranceListSearchModel {
  dqlText?: string = undefined;

  public isEmpty(): boolean {
    return this.dqlText === undefined;
  }

  public clear() {
    this.dqlText = undefined;
  }

}

interface SearchLoadResult {
  storedSearchData: ColonnadeInsuranceSearch.SearchDataResult,
}

class EmailSendModel {
  readonly templateExternalId: string = ColonnadeInsuranceListComponent.manualEmailTemplate;
  readonly fields: Map<string, string> = new Map();
  recipientName: string = '';
  recipientEmailAddress: string = '';
  generalPdfIds: number[] = [];

  toRequest(): GeneralEmail.EmailSendRequest {
    return {
      templateExternalId: this.templateExternalId,
      fields: this.fields,
      recipients: [{name: this.recipientName, emailAddress: Services.toEmailAddress(this.recipientEmailAddress)}],
      generalPdfIds: this.generalPdfIds
    };
  }

  isDangerous(): boolean {
    return this.generalPdfIds.length === 0 || this.generalPdfIds.length > 10;
  }
}
