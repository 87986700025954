<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div class="breadcrumb-menu d-flex">
    <div *ngIf="ldapEnabled &&
                (model.ldapMapped || (!model.ldapMapped && currentUsers.length === 0)) &&
                rightModel.userGroupLdapRead.hasRight() &&
                rightModel.userGroupLdapSync.hasRight() &&
                rightModel.userGroupLdapMapRead.hasRight() &&
                rightModel.userGroupLdapMapUpdate.hasRight()"
         (click)="showLdapUserGroupMapDialog()"
         class="breadcrumb-button-icon-container cursor-pointer">
      <a class="btn-setting" title="{{'USER_GROUP_PANEL_HEADING_LDAP_MAP' | translate}}">
        <i class="icomoon icomoon-csv-import"></i>
        <span class="breadcrumb-button-text-visible">{{'USER_GROUP_PANEL_HEADING_LDAP_MAP' | translate}}</span>
      </a>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="card-custom-padding-complete animated fadeIn">

  <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 card-custom-padding-complete">
    <div class="card">

      <div class="card-header">
        {{'USER_GROUP_PANEL_HEADING_EDIT'|translate}}
      </div>

      <div class="card-body">

        <form class="form-horizontal bordered-row" (ngSubmit)="update()" #f="ngForm" novalidate>

          <!-- region Name -->
          <div class="form-group row" [ngClass]="{ 'has-danger': fieldErrors.name }">
            <div class="col-md-4">
              <label for="name"
                     class="mw-100 col-form-label form-control-label required-field-label">
                {{'COMMON_NAME'|translate}}
              </label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <input type="text" [(ngModel)]="model.name" (ngModelChange)="removeFieldError(fieldErrors.name)"
                       maxlength="{{getTextMaximumLength()}}" class="form-control"
                       [ngClass]="{ 'form-control-danger': fieldErrors.name }" name="name" id="name"
                       placeholder="{{'USER_GROUP_PLACEHOLDER_NAME'|translate}}" required/>
                <div class="form-control-feedback" *ngIf="fieldErrors.name">{{fieldErrors.name.text}}</div>
              </div>
            </div>
          </div>
          <!-- endregion Name -->

          <!-- region ExternalId -->
          <div class="form-group row" [ngClass]="{ 'has-danger': fieldErrors.external_id }">
            <div class="col-md-4">
              <label for="external_id"
                     class="mw-100 col-form-label form-control-label required-field-label">
                {{'COMMON_EXTERNAL_ID'|translate}}
              </label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <input type="text" [(ngModel)]="model.externalId" (ngModelChange)="removeFieldError(fieldErrors.external_id)"
                       maxlength="{{getTextMaximumLength()}}" class="form-control"
                       [ngClass]="{ 'form-control-danger': fieldErrors.external_id }" name="external_id" id="external_id" #external_id="ngModel"
                       placeholder="{{'COMMON_EXTERNAL_ID'|translate}}" required/>
                <div class="form-control-feedback" *ngIf="fieldErrors.external_id">{{fieldErrors.external_id.text}}</div>
                <div class="form-control-feedback"
                     *ngIf="f.submitted && !external_id.valid && external_id.errors['required']">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
              </div>
            </div>
          </div>
          <!-- endregion ExternalId -->

          <!-- region Company -->
          <div class="form-group row"
               [ngClass]="{ 'has-danger': fieldErrors.company_ids || f.submitted && !companyInput.valid }"
               *ngIf="!protectedGroup">
            <div class="col-md-4">
              <label class="mw-100 col-form-label form-control-label" [class.required-field-label]="currentUserCompanyIds.length > 0">
                {{'COMMON_COMPANY'|translate}}
              </label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  id="test_user_group_company_angular_select"
                  name="companyInput" #companyInput="ngModel"
                  [(ngModel)]="model.companies"
                  [ngModelOptions]="{standalone: true}"
                  [ngClass]="{ 'form-control-danger': fieldErrors.company_ids || f.submitted && !companyInput.valid }"
                  (ngModelChange)="removeFieldError(fieldErrors.company_ids)"
                  (onSelect)="onCompanyChanged()"
                  (onDeSelect)="onCompanyChanged()"
                  (onRemoteSearch)="loadCompanies($event.target.value)"
                  [data]="companies"
                  [settings]="dropdownSettingsForCompany"
                  [required]="currentUserCompanyIds.length > 0"
                  validateEnabledItems>
                </angular2-multiselect>
                <div class="form-control-feedback" *ngIf="fieldErrors.company_ids">{{fieldErrors.company_ids.text}}
                </div>
                <div class="form-control-feedback"
                     *ngIf="f.submitted && !companyInput.valid && companyInput.errors['hasDisabledItem']">
                  {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
                </div>
                <div class="form-control-feedback"
                     *ngIf="f.submitted && !companyInput.valid && companyInput.errors['required']">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
              </div>
            </div>
          </div>
          <!-- endregion Company -->

          <!-- region Roles -->
          <div class="form-group row"
               [ngClass]="{ 'has-danger': fieldErrors.roles || f.submitted && !rolesInput.valid }">
            <div class="col-md-4">
              <label class="mw-100 col-form-label form-control-label">{{'ROLE_LIST_HEADING'|translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  *ngIf="!protectedGroup"
                  id="test_user_group_role_angular_select"
                  name="rolesInput" #rolesInput="ngModel"
                  [(ngModel)]="model.roles"
                  [data]="selectableRoles"
                  [settings]="dropdownSettingsForRole"
                  (onRemoteSearch)="loadRoles($event.target.value)"
                  (ngModelChange)="removeFieldError(fieldErrors.roles)"
                  [ngClass]="{ 'form-control-danger': fieldErrors.roles || f.submitted && !rolesInput.valid }"
                  validateEnabledItems>
                </angular2-multiselect>
                <div class="form-control-feedback" *ngIf="fieldErrors.roles">
                  {{fieldErrors.roles.text}}
                </div>
                <div class="form-control-feedback"
                     *ngIf="f.submitted && !rolesInput.valid && rolesInput.errors && rolesInput.errors['hasDisabledItem']">
                  {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
                </div>
                <div *ngIf="protectedGroup">
                  <label class="form-control" readonly>{{selectedRolesText}}</label>
                </div>
              </div>
            </div>
          </div>
          <!-- endregion Roles -->

          <!-- region Application types -->
          <div class="form-group row">
            <div class="col-md-4">
              <label class="mw-100 col-form-label form-control-label">{{'USER_GROUP_LABEL_SCOPE'|translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect *ngIf="!protectedGroup"
                                      [(ngModel)]="model.application_types"
                                      [ngModelOptions]="{standalone: true}"
                                      [data]="applicationTypes"
                                      [settings]="dropdownSettings">
                </angular2-multiselect>
                <div *ngIf="protectedGroup">
                  <label class="form-control" readonly>{{selectedApplicationTypesText}}</label>
                </div>
              </div>
            </div>
          </div>
          <!-- endregion Application types -->

          <!-- region Users -->
          <div *ngIf="canEditUserList()"
               class="form-group row" [ngClass]="{ 'has-danger': f.submitted && !users.valid }">
            <div class="col-md-4">
              <label class="mw-100 col-form-label form-control-label">{{'COMMON_USERS'|translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  id="user_group_edit_users_select"
                  name="users" #users="ngModel"
                  [(ngModel)]="model.users"
                  [ngModelOptions]="{standalone: true}"
                  [data]="selectableUsers"
                  [ngClass]="{ 'form-control-danger': f.submitted && !users.valid }"
                  [settings]="dropdownSettingsForUser"
                  (onRemoteSearch)="loadUsers($event.target.value)"
                  (onSelect)="onUserSelect($event)"
                  (onDeSelect)="onUserDeSelect($event)"
                  (onSelectAll)="onSelectAllUser($event)"
                  (onDeSelectAll)="onDeSelectAllUser($event)"
                  validateEnabledItems>
                </angular2-multiselect>
                <div class="form-control-feedback"
                     *ngIf="f.submitted && !users.valid && users.errors['hasDisabledItem']">
                  {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!canEditUserList() && !this.model.ldapMapped"
               class="form-group row">
            <div class="col-md-4">
              <label class="mw-100 col-form-label form-control-label">{{'COMMON_USERS'|translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <div>
                  <label class="form-control" readonly>{{selectedUsersText}}</label>
                </div>
              </div>
            </div>
          </div>
          <!-- endregion Users -->

          <!-- region mapped ldap groups -->
          <div class="form-group row"
               *ngIf="rightModel.userGroupLdapMapRead.hasRight() && mappedLdapGroupsText.length > 0">
            <div class="col-md-4">
              <label
                class="mw-100 col-form-label form-control-label">{{'USER_GROUP_LDAP_LIST_HEADING'|translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <div>
                  <label class="form-control detail-description"
                         readonly>{{mappedLdapGroupsText}}</label>
                </div>
              </div>
            </div>
          </div>
          <!-- endregion mapped ldap groups -->

          <div>
            <div class="d-flex justify-content-end">
              <input type="submit" class="btn btn-primary" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
            </div>
          </div>

        </form>
      </div> <!--card-body-->
    </div> <!--card-->
  </div>

  <app-usergroup-type-based-roles
    [userGroupId]="userGroupId"
    [readonly]="false"
    [rightModel]="rightModel">
  </app-usergroup-type-based-roles>
</div>

<div bsModal [config]="UiConstants.modalConfig" #ldapUserGroupMapDialog="bs-modal" class="modal fade" tabindex="-1"
     role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true"
     (onHide)="onModalHidden()">
  <div class="modal-dialog modal-lg" role="document" *ngIf="ldapUserGroupMapDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title"><strong>{{'USER_GROUP_PANEL_HEADING_LDAP_MAP' | translate}}</strong></p>
        <button type="button" class="close" (click)="closeLdapUserGroupMapDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form-horizontal bordered-row" (ngSubmit)="ldapForm.form.valid && mapLdapUserGroups()"
              #ldapForm="ngForm" novalidate>

          <div class="form-group row"
               [ngClass]="{ 'has-danger': hasLocalFieldError(ldapUserGroupField) || ldapUserGroupMapFieldErrors.ldap_group_ids }">
            <div class="col-md-4">
              <label
                class="col-form-label mw-100 form-control-label">{{'USER_GROUP_LDAP_LIST_HEADING'|translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  [(ngModel)]="selectedLdapUserGroups"
                  [data]="ldapUserGroups"
                  [settings]="dropdownSettings"
                  (ngModelChange)="removeLdapUserGroupMapFieldError(ldapUserGroupMapFieldErrors.ldap_group_ids)"
                  name="ldapUserGroupField"
                  id="ldapUserGroupField"
                  #ldapUserGroupField="ngModel">
                </angular2-multiselect>
                <div class="form-control-feedback" *ngIf="hasLocalFieldError(ldapUserGroupField)">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
                <div class="form-control-feedback" *ngIf="ldapUserGroupMapFieldErrors.ldap_group_ids">
                  {{ldapUserGroupMapFieldErrors.ldap_group_ids.text}}
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 d-flex justify-content-end p-0 pl-1">
            <div>
              <button type="button" class="btn btn-outline-primary" (click)="closeLdapUserGroupMapDialog()">
                {{'COMMON_BUTTON_CANCEL' | translate}}
              </button>
              <button type="submit" class="btn btn-primary ml-1">{{'COMMON_BUTTON_SAVE' |
                  translate}}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
