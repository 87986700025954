import { HttpClient } from '@angular/common/http';
import { DqlStoredQueryResourceService } from './dql-stored-query-resource.service';
import { ResourceHelper } from '../util/http-services';

/**
 * not injectable
 */
export class GeneralDqlStoredQueryResourceService extends DqlStoredQueryResourceService {

  protected getDqlPath() {
    return super.getDqlPath() + `/${this.parentType}`;
  }

  constructor(http: HttpClient,
              resourceHelper: ResourceHelper,
              private parentType: GeneralDqlStoredQueryResource.ParentType) {
    super(http, resourceHelper, '');
  }
}

export namespace GeneralDqlStoredQueryResource {
  export type ParentType = 'SHIPPING_DEMAND' | 'SHIPMENT' | 'TRANSPORT';
}
