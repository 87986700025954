<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div *ngIf="rightModel.tableDocumentSchemaCreate.hasRight()"
       class="breadcrumb-menu d-flex">
    <div class="breadcrumb-button-icon-container cursor-pointer"
         (click)="openImportDialog()">
      <a class="btn-setting d-flex align-items-center" title="{{'COMMON_BUTTON_IMPORT' | translate}}">
        <i class="icomoon icomoon-csv-import"></i>
        <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_IMPORT' | translate}}</span>
      </a>
    </div>
    <div *ngIf="rightModel.tableDocumentSchemaCreate.hasRight()"
         uiSref="Admin.TableDocumentSchemaCreate"
         class="breadcrumb-button-icon-container negative-margin-right cursor-pointer"
    >
      <a class="btn-setting" title="{{'TABLE_DOCUMENT_SCHEMA_CREATE' | translate}}">
        <i class="icomoon icomoon-add"></i>
        <span class="breadcrumb-button-text-visible">{{'TABLE_DOCUMENT_SCHEMA_CREATE' | translate}}</span>
      </a>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>

<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="card">

    <div class="card-header">
      {{'MENU_NAVBAR_TABLE_DOCUMENT_SCHEMA' | translate}}
      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()"
           title="{{'COMMON_SHOW_SEARCH_HINT' | translate}}"><i class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>

    <div class="card-body border-bottom" *ngIf="showSearch">
      <div class="row form-group">
        <div class="col-md-3">
          <label class="search-label">{{'LIST_ITEM_CODE' | translate}}</label>
          <input type="text" class="form-control"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 placeholder="{{'LIST_ITEM_CODE' | translate}}"
                 [(ngModel)]="searchModel.code">
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'COMMON_NAME' | translate}}</label>
          <input type="text" class="form-control"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 placeholder="{{'COMMON_NAME' | translate}}"
                 [(ngModel)]="searchModel.name">
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'COMMON_VERSION' | translate}}</label>
          <input type="text" class="form-control"
                 maxlength="{{UiConstants.maxInputIntLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [textMask]="{mask: InputMask.NATURAL_INTEGER, guide: false}"
                 placeholder="{{'COMMON_VERSION' | translate}}"
                 [(ngModel)]="searchModel.schemaVersion">
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'COMMON_STATUS' | translate}}</label>
          <select class="form-control" [compareWith]="SelectUtils.compareObjects" [(ngModel)]="searchModel.versionState"
                  id="versionState" name="versionState" #versionState="ngModel">
            <option *ngFor="let item of schemaVersionStateForSearchItems" [ngValue]="item">{{item.text}}</option>
          </select>
        </div>
      </div>
      <div class="col-md-12 d-flex justify-content-md-end align-items-end px-0">
        <div class="btn-group" role="group">
          <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
            {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
          </button>
          <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
            {{'COMMON_BUTTON_SEARCH' | translate}}
          </button>
        </div>
      </div>
    </div><!-- /.card-body-->

    <div class="card-body">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header hidden-sm-down w-5">
            <app-table-field-sorter [orderField]="TableDocumentSchema.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(TableDocumentSchema.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-field-sorter [orderField]="TableDocumentSchema.OrderField.CODE"
                                    [orderType]="queryModel.getOrderType(TableDocumentSchema.OrderField.CODE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'LIST_ITEM_CODE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="TableDocumentSchema.OrderField.NAME"
                                    [orderType]="queryModel.getOrderType(TableDocumentSchema.OrderField.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-5 hidden-xs-down">
            <app-table-sorter-no-op [text]="'COMMON_VERSION' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-lg-down w-15">
            <app-table-sorter-no-op [text]="'COMMON_CREATION_TIME' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op [text]="'COMMON_STATUS' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-actions">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let tableDocumentSchema of tableDocumentSchemaList | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }">
          <td class="responsive-table-column hidden-sm-down w-5">{{tableDocumentSchema.schemaId}}</td>
          <td class="responsive-table-column hidden-md-down">{{tableDocumentSchema.code}}</td>
          <td class="responsive-table-column">{{tableDocumentSchema.name}}</td>
          <td class="responsive-table-column hidden-xs-down">{{tableDocumentSchema.version}}</td>
          <td class="responsive-table-column hidden-lg-down w-15">{{tableDocumentSchema.creationTime.toUtcIsoString() | date:'short'}}</td>
          <td
            class="responsive-table-column">{{'TABLE_DOCUMENT_SCHEMA_TYPE_' + tableDocumentSchema.versionState | translate}}</td>
          <td *ngIf="rightModel.tableDocumentSchemaRead.hasRight()" class="responsive-table-column">
            <a class="icons-table-group cursor-pointer"
               uiSref="Admin.TableDocumentSchemaDetail"
               [uiParams]="{ id: tableDocumentSchema.schemaId }">
              <i class="icomoon icons-table-item icomoon-detail icomoon-detail-table"
                 title="{{'COMMON_BUTTON_DETAILS' | translate}}"></i>
            </a>
            <a *ngIf="rightModel.tableDocumentSchemaUpdate.hasRight() &&
                showSchemaEdit(tableDocumentSchema)"
               class="icons-table-group cursor-pointer"
               uiSref="Admin.TableDocumentSchemaEdit"
               [uiParams]="{ id: tableDocumentSchema.schemaId }">
              <i class="icomoon icons-table-item icomoon-modify icomoon-modify-table"
                 title="{{'COMMON_BUTTON_EDIT' | translate}}"></i>
            </a>
            <a *ngIf="rightModel.tableDocumentSchemaDelete.hasRight() &&
            showSchemaDelete(tableDocumentSchema)"
               class="icons-table-group cursor-pointer"
               (click)="openDeleteModal(tableDocumentSchema)">
              <i class="icomoon icons-table-item icomoon-trash icomoon-disable-table"
                 title="{{'COMMON_BUTTON_DELETE' | translate}}"></i>
            </a>
            <a *ngIf="rightModel.tableDocumentSchemaCreate.hasRight()"
               class="icons-table-group cursor-pointer"
               uiSref="Admin.TableDocumentSchemaClone"
               [uiParams]="{ id: tableDocumentSchema.schemaId }">
              <i class="icomoon icons-table-item icomoon-clone icomoon-modify-table detail-icon"
                 title="{{'COMMON_BUTTON_CLONE' | translate}}"></i>
            </a>
            <a *ngIf="tableDocumentSchema.versionState === 'FINALIZED' "
               class="icons-table-group cursor-pointer"
               (click)="exportSchema(tableDocumentSchema)">
              <i class="icomoon icons-table-item icons-table-item icomoon-csv-export"
                 title="{{'COMMON_BUTTON_EXPORT' | translate}}"></i>
            </a>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header hidden-sm-down w-5">
            <app-table-field-sorter [orderField]="TableDocumentSchema.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(TableDocumentSchema.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-field-sorter [orderField]="TableDocumentSchema.OrderField.CODE"
                                    [orderType]="queryModel.getOrderType(TableDocumentSchema.OrderField.CODE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'LIST_ITEM_CODE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="TableDocumentSchema.OrderField.NAME"
                                    [orderType]="queryModel.getOrderType(TableDocumentSchema.OrderField.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-5 hidden-xs-down">
            <app-table-sorter-no-op [text]="'COMMON_VERSION' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-lg-down w-15">
            <app-table-sorter-no-op [text]="'COMMON_CREATION_TIME' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op [text]="'COMMON_STATUS' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-actions">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>
  </div>
</div>

<!-- modal -->
<div bsModal [config]="UiConstants.modalConfig" #importDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="importDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'COMMON_IMPORT_TITLE' | translate}}</h4>
        <button type="button" class="close" (click)="closeImportDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body pt-0">
        <div class="row" *ngIf="!importDnDVisible">
          <div class="col-md-4">
            <label
              class="mw-100 col-form-label form-control-label">{{'COMMON_NAME' | translate}}</label>
          </div>
          <div class="col-md-8">
            <label
              class="mw-100 col-form-label form-control-label">{{tableDocumentSchemaImportModel.fileName}}</label>
          </div>
        </div>
        <div class="col-sm-12 p-0">
          <app-file-upload
            #fileUploadComponent
            [uploadPath]="''"
            [uploadOnDrop]="false"
            (onFileDropped)="onImportableSurveySelected($event)">
          </app-file-upload>
        </div>
        <div class="col-sm-12" *ngIf="tableDocumentSchemaImportModel.importResult">
          <div class="alert alert-success" *ngIf="tableDocumentSchemaImportModel.importResult === 'SUCCESS'">
            <h4 class="alert-title">{{'ALERT_TEXT_UPLOAD_SUCCESSFUL' | translate}}</h4>
            <p>{{'ALERT_TEXT_SUCCESSFULLY_UPLOADED' | translate}}</p>
          </div>
          <div class="alert alert-danger" *ngIf="tableDocumentSchemaImportModel.importResult === 'ERROR'">
            <h4 class="alert-title">{{'ALERT_TEXT_UPLOAD_FAILED' | translate}}</h4>
            <p style="white-space: pre-line">{{uploadGlobalErrors}}</p>
          </div>
          <div class="alert alert-danger" *ngIf="tableDocumentSchemaImportModel.importResult === 'FORMAT_ERROR'">
            <h4 class="alert-title">{{'ALERT_TEXT_FILE_ERROR' | translate}}</h4>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeImportDialog()">
          {{'COMMON_BUTTON_CANCEL' | translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="importSchema()">{{'COMMON_BUTTON_IMPORT' |
          translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<div bsModal [config]="UiConstants.modalConfig" #deleteModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="deleteModalVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'TABLE_DOCUMENT_SCHEMA_DELETE' | translate}}</h4>
        <button type="button" class="close" (click)="closeDeleteModal()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group col-md-12 mb-0">
          <label>{{'TABLE_DOCUMENT_SCHEMA_DELETE_LABEL' | translate}}</label>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeDeleteModal()">
          {{'COMMON_BUTTON_CANCEL' | translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="deleteSchema()">{{'COMMON_BUTTON_DELETE' |
          translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
