// Generated from src/app/util/generated/dql/typed/TypedDql.g4 by ANTLR 4.7.3-SNAPSHOT
/* eslint-disable */


import { ATN } from "antlr4ts/atn/ATN";
import { ATNDeserializer } from "antlr4ts/atn/ATNDeserializer";
import { FailedPredicateException } from "antlr4ts/FailedPredicateException";
import { NotNull } from "antlr4ts/Decorators";
import { NoViableAltException } from "antlr4ts/NoViableAltException";
import { Override } from "antlr4ts/Decorators";
import { Parser } from "antlr4ts/Parser";
import { ParserRuleContext } from "antlr4ts/ParserRuleContext";
import { ParserATNSimulator } from "antlr4ts/atn/ParserATNSimulator";
import { ParseTreeListener } from "antlr4ts/tree/ParseTreeListener";
import { ParseTreeVisitor } from "antlr4ts/tree/ParseTreeVisitor";
import { RecognitionException } from "antlr4ts/RecognitionException";
import { RuleContext } from "antlr4ts/RuleContext";
//import { RuleVersion } from "antlr4ts/RuleVersion";
import { TerminalNode } from "antlr4ts/tree/TerminalNode";
import { Token } from "antlr4ts/Token";
import { TokenStream } from "antlr4ts/TokenStream";
import { Vocabulary } from "antlr4ts/Vocabulary";
import { VocabularyImpl } from "antlr4ts/VocabularyImpl";

import * as Utils from "antlr4ts/misc/Utils";

import { TypedDqlListener } from "./TypedDqlListener";
import { TypedDqlVisitor } from "./TypedDqlVisitor";


export class TypedDqlParser extends Parser {
	public static readonly T__0 = 1;
	public static readonly DATATYPE = 2;
	public static readonly NUMBER = 3;
	public static readonly WORD = 4;
	public static readonly OPERATION = 5;
	public static readonly UNARY_OPERATION = 6;
	public static readonly LOGICAL = 7;
	public static readonly WS = 8;
	public static readonly VALUE = 9;
	public static readonly SEMICOLON = 10;
	public static readonly LPAR = 11;
	public static readonly RPAR = 12;
	public static readonly RULE_sentence = 0;
	public static readonly RULE_expression = 1;
	public static readonly RULE_criteria = 2;
	public static readonly RULE_field = 3;
	public static readonly RULE_relationalOperator = 4;
	public static readonly RULE_unaryRelationalOperator = 5;
	public static readonly RULE_value = 6;
	public static readonly RULE_logicalOperator = 7;
	/* eslint-disable no-trailing-spaces */
	public static readonly ruleNames: string[] = [
		"sentence", "expression", "criteria", "field", "relationalOperator", "unaryRelationalOperator",
		"value", "logicalOperator",
	];

	private static readonly _LITERAL_NAMES: Array<string | undefined> = [
		undefined, "'.'", undefined, undefined, undefined, undefined, undefined,
		undefined, undefined, undefined, "';'", "'('", "')'",
	];
	private static readonly _SYMBOLIC_NAMES: Array<string | undefined> = [
		undefined, undefined, "DATATYPE", "NUMBER", "WORD", "OPERATION", "UNARY_OPERATION",
		"LOGICAL", "WS", "VALUE", "SEMICOLON", "LPAR", "RPAR",
	];
	public static readonly VOCABULARY: Vocabulary = new VocabularyImpl(TypedDqlParser._LITERAL_NAMES, TypedDqlParser._SYMBOLIC_NAMES, []);

	// @Override
	// @NotNull
	public get vocabulary(): Vocabulary {
		return TypedDqlParser.VOCABULARY;
	}
	/* eslint-enable no-trailing-spaces */

	// @Override
	public get grammarFileName(): string { return "TypedDql.g4"; }

	// @Override
	public get ruleNames(): string[] { return TypedDqlParser.ruleNames; }

	// @Override
	public get serializedATN(): string { return TypedDqlParser._serializedATN; }

	constructor(input: TokenStream) {
		super(input);
		this._interp = new ParserATNSimulator(TypedDqlParser._ATN, this);
	}
	// @RuleVersion(0)
	public sentence(): SentenceContext {
		let _localctx: SentenceContext = new SentenceContext(this._ctx, this.state);
		this.enterRule(_localctx, 0, TypedDqlParser.RULE_sentence);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 16;
			this.expression();
			this.state = 22;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			while (_la === TypedDqlParser.LOGICAL || _la === TypedDqlParser.WS) {
				{
				{
				this.state = 17;
				this.logicalOperator();
				this.state = 18;
				this.expression();
				}
				}
				this.state = 24;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			}
			this.state = 25;
			this.match(TypedDqlParser.SEMICOLON);
			this.state = 26;
			this.match(TypedDqlParser.EOF);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public expression(): ExpressionContext {
		let _localctx: ExpressionContext = new ExpressionContext(this._ctx, this.state);
		this.enterRule(_localctx, 2, TypedDqlParser.RULE_expression);
		let _la: number;
		try {
			this.state = 41;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case TypedDqlParser.LPAR:
				_localctx = new ComplexExpressionContext(_localctx);
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 28;
				this.match(TypedDqlParser.LPAR);
				this.state = 29;
				(_localctx as ComplexExpressionContext)._left = this.expression();
				this.state = 35;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				while (_la === TypedDqlParser.LOGICAL || _la === TypedDqlParser.WS) {
					{
					{
					this.state = 30;
					this.logicalOperator();
					this.state = 31;
					(_localctx as ComplexExpressionContext)._expression = this.expression();
					(_localctx as ComplexExpressionContext)._rest.push((_localctx as ComplexExpressionContext)._expression);
					}
					}
					this.state = 37;
					this._errHandler.sync(this);
					_la = this._input.LA(1);
				}
				this.state = 38;
				this.match(TypedDqlParser.RPAR);
				}
				break;
			case TypedDqlParser.WORD:
				_localctx = new SimpleExpressionContext(_localctx);
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 40;
				this.criteria();
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public criteria(): CriteriaContext {
		let _localctx: CriteriaContext = new CriteriaContext(this._ctx, this.state);
		this.enterRule(_localctx, 4, TypedDqlParser.RULE_criteria);
		try {
			this.state = 50;
			this._errHandler.sync(this);
			switch ( this.interpreter.adaptivePredict(this._input, 3, this._ctx) ) {
			case 1:
				_localctx = new BinaryCriteriaContext(_localctx);
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 43;
				this.field();
				this.state = 44;
				this.relationalOperator();
				this.state = 45;
				this.value();
				}
				break;

			case 2:
				_localctx = new UnaryCriteriaContext(_localctx);
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 47;
				this.field();
				this.state = 48;
				this.unaryRelationalOperator();
				}
				break;
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public field(): FieldContext {
		let _localctx: FieldContext = new FieldContext(this._ctx, this.state);
		this.enterRule(_localctx, 6, TypedDqlParser.RULE_field);
		try {
			let _alt: number;
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 56;
			this._errHandler.sync(this);
			_alt = this.interpreter.adaptivePredict(this._input, 4, this._ctx);
			while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
				if (_alt === 1) {
					{
					{
					this.state = 52;
					this.match(TypedDqlParser.WORD);
					this.state = 53;
					this.match(TypedDqlParser.T__0);
					}
					}
				}
				this.state = 58;
				this._errHandler.sync(this);
				_alt = this.interpreter.adaptivePredict(this._input, 4, this._ctx);
			}
			this.state = 59;
			this.match(TypedDqlParser.WORD);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public relationalOperator(): RelationalOperatorContext {
		let _localctx: RelationalOperatorContext = new RelationalOperatorContext(this._ctx, this.state);
		this.enterRule(_localctx, 8, TypedDqlParser.RULE_relationalOperator);
		let _la: number;
		try {
			let _alt: number;
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 64;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			while (_la === TypedDqlParser.WS) {
				{
				{
				this.state = 61;
				this.match(TypedDqlParser.WS);
				}
				}
				this.state = 66;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			}
			this.state = 67;
			this.match(TypedDqlParser.OPERATION);
			this.state = 71;
			this._errHandler.sync(this);
			_alt = this.interpreter.adaptivePredict(this._input, 6, this._ctx);
			while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
				if (_alt === 1) {
					{
					{
					this.state = 68;
					this.match(TypedDqlParser.WS);
					}
					}
				}
				this.state = 73;
				this._errHandler.sync(this);
				_alt = this.interpreter.adaptivePredict(this._input, 6, this._ctx);
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public unaryRelationalOperator(): UnaryRelationalOperatorContext {
		let _localctx: UnaryRelationalOperatorContext = new UnaryRelationalOperatorContext(this._ctx, this.state);
		this.enterRule(_localctx, 10, TypedDqlParser.RULE_unaryRelationalOperator);
		try {
			let _alt: number;
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 74;
			this.match(TypedDqlParser.UNARY_OPERATION);
			this.state = 78;
			this._errHandler.sync(this);
			_alt = this.interpreter.adaptivePredict(this._input, 7, this._ctx);
			while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
				if (_alt === 1) {
					{
					{
					this.state = 75;
					this.match(TypedDqlParser.WS);
					}
					}
				}
				this.state = 80;
				this._errHandler.sync(this);
				_alt = this.interpreter.adaptivePredict(this._input, 7, this._ctx);
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public value(): ValueContext {
		let _localctx: ValueContext = new ValueContext(this._ctx, this.state);
		this.enterRule(_localctx, 12, TypedDqlParser.RULE_value);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 84;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			while (_la === TypedDqlParser.WS) {
				{
				{
				this.state = 81;
				this.match(TypedDqlParser.WS);
				}
				}
				this.state = 86;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			}
			this.state = 87;
			this.match(TypedDqlParser.VALUE);
			this.state = 88;
			this.match(TypedDqlParser.DATATYPE);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public logicalOperator(): LogicalOperatorContext {
		let _localctx: LogicalOperatorContext = new LogicalOperatorContext(this._ctx, this.state);
		this.enterRule(_localctx, 14, TypedDqlParser.RULE_logicalOperator);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 93;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			while (_la === TypedDqlParser.WS) {
				{
				{
				this.state = 90;
				this.match(TypedDqlParser.WS);
				}
				}
				this.state = 95;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			}
			this.state = 96;
			this.match(TypedDqlParser.LOGICAL);
			this.state = 100;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			while (_la === TypedDqlParser.WS) {
				{
				{
				this.state = 97;
				this.match(TypedDqlParser.WS);
				}
				}
				this.state = 102;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}

	public static readonly _serializedATN: string =
		"\x03\uC91D\uCABA\u058D\uAFBA\u4F53\u0607\uEA8B\uC241\x03\x0Ej\x04\x02" +
		"\t\x02\x04\x03\t\x03\x04\x04\t\x04\x04\x05\t\x05\x04\x06\t\x06\x04\x07" +
		"\t\x07\x04\b\t\b\x04\t\t\t\x03\x02\x03\x02\x03\x02\x03\x02\x07\x02\x17" +
		"\n\x02\f\x02\x0E\x02\x1A\v\x02\x03\x02\x03\x02\x03\x02\x03\x03\x03\x03" +
		"\x03\x03\x03\x03\x03\x03\x07\x03$\n\x03\f\x03\x0E\x03\'\v\x03\x03\x03" +
		"\x03\x03\x03\x03\x05\x03,\n\x03\x03\x04\x03\x04\x03\x04\x03\x04\x03\x04" +
		"\x03\x04\x03\x04\x05\x045\n\x04\x03\x05\x03\x05\x07\x059\n\x05\f\x05\x0E" +
		"\x05<\v\x05\x03\x05\x03\x05\x03\x06\x07\x06A\n\x06\f\x06\x0E\x06D\v\x06" +
		"\x03\x06\x03\x06\x07\x06H\n\x06\f\x06\x0E\x06K\v\x06\x03\x07\x03\x07\x07" +
		"\x07O\n\x07\f\x07\x0E\x07R\v\x07\x03\b\x07\bU\n\b\f\b\x0E\bX\v\b\x03\b" +
		"\x03\b\x03\b\x03\t\x07\t^\n\t\f\t\x0E\ta\v\t\x03\t\x03\t\x07\te\n\t\f" +
		"\t\x0E\th\v\t\x03\t\x02\x02\x02\n\x02\x02\x04\x02\x06\x02\b\x02\n\x02" +
		"\f\x02\x0E\x02\x10\x02\x02\x02\x02l\x02\x12\x03\x02\x02\x02\x04+\x03\x02" +
		"\x02\x02\x064\x03\x02\x02\x02\b:\x03\x02\x02\x02\nB\x03\x02\x02\x02\f" +
		"L\x03\x02\x02\x02\x0EV\x03\x02\x02\x02\x10_\x03\x02\x02\x02\x12\x18\x05" +
		"\x04\x03\x02\x13\x14\x05\x10\t\x02\x14\x15\x05\x04\x03\x02\x15\x17\x03" +
		"\x02\x02\x02\x16\x13\x03\x02\x02\x02\x17\x1A\x03\x02\x02\x02\x18\x16\x03" +
		"\x02\x02\x02\x18\x19\x03\x02\x02\x02\x19\x1B\x03\x02\x02\x02\x1A\x18\x03" +
		"\x02\x02\x02\x1B\x1C\x07\f\x02\x02\x1C\x1D\x07\x02\x02\x03\x1D\x03\x03" +
		"\x02\x02\x02\x1E\x1F\x07\r\x02\x02\x1F%\x05\x04\x03\x02 !\x05\x10\t\x02" +
		"!\"\x05\x04\x03\x02\"$\x03\x02\x02\x02# \x03\x02\x02\x02$\'\x03\x02\x02" +
		"\x02%#\x03\x02\x02\x02%&\x03\x02\x02\x02&(\x03\x02\x02\x02\'%\x03\x02" +
		"\x02\x02()\x07\x0E\x02\x02),\x03\x02\x02\x02*,\x05\x06\x04\x02+\x1E\x03" +
		"\x02\x02\x02+*\x03\x02\x02\x02,\x05\x03\x02\x02\x02-.\x05\b\x05\x02./" +
		"\x05\n\x06\x02/0\x05\x0E\b\x0205\x03\x02\x02\x0212\x05\b\x05\x0223\x05" +
		"\f\x07\x0235\x03\x02\x02\x024-\x03\x02\x02\x0241\x03\x02\x02\x025\x07" +
		"\x03\x02\x02\x0267\x07\x06\x02\x0279\x07\x03\x02\x0286\x03\x02\x02\x02" +
		"9<\x03\x02\x02\x02:8\x03\x02\x02\x02:;\x03\x02\x02\x02;=\x03\x02\x02\x02" +
		"<:\x03\x02\x02\x02=>\x07\x06\x02\x02>\t\x03\x02\x02\x02?A\x07\n\x02\x02" +
		"@?\x03\x02\x02\x02AD\x03\x02\x02\x02B@\x03\x02\x02\x02BC\x03\x02\x02\x02" +
		"CE\x03\x02\x02\x02DB\x03\x02\x02\x02EI\x07\x07\x02\x02FH\x07\n\x02\x02" +
		"GF\x03\x02\x02\x02HK\x03\x02\x02\x02IG\x03\x02\x02\x02IJ\x03\x02\x02\x02" +
		"J\v\x03\x02\x02\x02KI\x03\x02\x02\x02LP\x07\b\x02\x02MO\x07\n\x02\x02" +
		"NM\x03\x02\x02\x02OR\x03\x02\x02\x02PN\x03\x02\x02\x02PQ\x03\x02\x02\x02" +
		"Q\r\x03\x02\x02\x02RP\x03\x02\x02\x02SU\x07\n\x02\x02TS\x03\x02\x02\x02" +
		"UX\x03\x02\x02\x02VT\x03\x02\x02\x02VW\x03\x02\x02\x02WY\x03\x02\x02\x02" +
		"XV\x03\x02\x02\x02YZ\x07\v\x02\x02Z[\x07\x04\x02\x02[\x0F\x03\x02\x02" +
		"\x02\\^\x07\n\x02\x02]\\\x03\x02\x02\x02^a\x03\x02\x02\x02_]\x03\x02\x02" +
		"\x02_`\x03\x02\x02\x02`b\x03\x02\x02\x02a_\x03\x02\x02\x02bf\x07\t\x02" +
		"\x02ce\x07\n\x02\x02dc\x03\x02\x02\x02eh\x03\x02\x02\x02fd\x03\x02\x02" +
		"\x02fg\x03\x02\x02\x02g\x11\x03\x02\x02\x02hf\x03\x02\x02\x02\r\x18%+" +
		"4:BIPV_f";
	public static __ATN: ATN;
	public static get _ATN(): ATN {
		if (!TypedDqlParser.__ATN) {
			TypedDqlParser.__ATN = new ATNDeserializer().deserialize(Utils.toCharArray(TypedDqlParser._serializedATN));
		}

		return TypedDqlParser.__ATN;
	}

}

export class SentenceContext extends ParserRuleContext {
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	public SEMICOLON(): TerminalNode { return this.getToken(TypedDqlParser.SEMICOLON, 0); }
	public EOF(): TerminalNode { return this.getToken(TypedDqlParser.EOF, 0); }
	public logicalOperator(): LogicalOperatorContext[];
	public logicalOperator(i: number): LogicalOperatorContext;
	public logicalOperator(i?: number): LogicalOperatorContext | LogicalOperatorContext[] {
		if (i === undefined) {
			return this.getRuleContexts(LogicalOperatorContext);
		} else {
			return this.getRuleContext(i, LogicalOperatorContext);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return TypedDqlParser.RULE_sentence; }
	// @Override
	public enterRule(listener: TypedDqlListener): void {
		if (listener.enterSentence) {
			listener.enterSentence(this);
		}
	}
	// @Override
	public exitRule(listener: TypedDqlListener): void {
		if (listener.exitSentence) {
			listener.exitSentence(this);
		}
	}
	// @Override
	public accept<Result>(visitor: TypedDqlVisitor<Result>): Result {
		if (visitor.visitSentence) {
			return visitor.visitSentence(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class ExpressionContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return TypedDqlParser.RULE_expression; }
	public copyFrom(ctx: ExpressionContext): void {
		super.copyFrom(ctx);
	}
}
export class ComplexExpressionContext extends ExpressionContext {
	public _left: ExpressionContext;
	public _expression: ExpressionContext;
	public _rest: ExpressionContext[] = [];
	public LPAR(): TerminalNode { return this.getToken(TypedDqlParser.LPAR, 0); }
	public RPAR(): TerminalNode { return this.getToken(TypedDqlParser.RPAR, 0); }
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	public logicalOperator(): LogicalOperatorContext[];
	public logicalOperator(i: number): LogicalOperatorContext;
	public logicalOperator(i?: number): LogicalOperatorContext | LogicalOperatorContext[] {
		if (i === undefined) {
			return this.getRuleContexts(LogicalOperatorContext);
		} else {
			return this.getRuleContext(i, LogicalOperatorContext);
		}
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: TypedDqlListener): void {
		if (listener.enterComplexExpression) {
			listener.enterComplexExpression(this);
		}
	}
	// @Override
	public exitRule(listener: TypedDqlListener): void {
		if (listener.exitComplexExpression) {
			listener.exitComplexExpression(this);
		}
	}
	// @Override
	public accept<Result>(visitor: TypedDqlVisitor<Result>): Result {
		if (visitor.visitComplexExpression) {
			return visitor.visitComplexExpression(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class SimpleExpressionContext extends ExpressionContext {
	public criteria(): CriteriaContext {
		return this.getRuleContext(0, CriteriaContext);
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: TypedDqlListener): void {
		if (listener.enterSimpleExpression) {
			listener.enterSimpleExpression(this);
		}
	}
	// @Override
	public exitRule(listener: TypedDqlListener): void {
		if (listener.exitSimpleExpression) {
			listener.exitSimpleExpression(this);
		}
	}
	// @Override
	public accept<Result>(visitor: TypedDqlVisitor<Result>): Result {
		if (visitor.visitSimpleExpression) {
			return visitor.visitSimpleExpression(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class CriteriaContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return TypedDqlParser.RULE_criteria; }
	public copyFrom(ctx: CriteriaContext): void {
		super.copyFrom(ctx);
	}
}
export class BinaryCriteriaContext extends CriteriaContext {
	public field(): FieldContext {
		return this.getRuleContext(0, FieldContext);
	}
	public relationalOperator(): RelationalOperatorContext {
		return this.getRuleContext(0, RelationalOperatorContext);
	}
	public value(): ValueContext {
		return this.getRuleContext(0, ValueContext);
	}
	constructor(ctx: CriteriaContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: TypedDqlListener): void {
		if (listener.enterBinaryCriteria) {
			listener.enterBinaryCriteria(this);
		}
	}
	// @Override
	public exitRule(listener: TypedDqlListener): void {
		if (listener.exitBinaryCriteria) {
			listener.exitBinaryCriteria(this);
		}
	}
	// @Override
	public accept<Result>(visitor: TypedDqlVisitor<Result>): Result {
		if (visitor.visitBinaryCriteria) {
			return visitor.visitBinaryCriteria(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class UnaryCriteriaContext extends CriteriaContext {
	public field(): FieldContext {
		return this.getRuleContext(0, FieldContext);
	}
	public unaryRelationalOperator(): UnaryRelationalOperatorContext {
		return this.getRuleContext(0, UnaryRelationalOperatorContext);
	}
	constructor(ctx: CriteriaContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: TypedDqlListener): void {
		if (listener.enterUnaryCriteria) {
			listener.enterUnaryCriteria(this);
		}
	}
	// @Override
	public exitRule(listener: TypedDqlListener): void {
		if (listener.exitUnaryCriteria) {
			listener.exitUnaryCriteria(this);
		}
	}
	// @Override
	public accept<Result>(visitor: TypedDqlVisitor<Result>): Result {
		if (visitor.visitUnaryCriteria) {
			return visitor.visitUnaryCriteria(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class FieldContext extends ParserRuleContext {
	public WORD(): TerminalNode[];
	public WORD(i: number): TerminalNode;
	public WORD(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(TypedDqlParser.WORD);
		} else {
			return this.getToken(TypedDqlParser.WORD, i);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return TypedDqlParser.RULE_field; }
	// @Override
	public enterRule(listener: TypedDqlListener): void {
		if (listener.enterField) {
			listener.enterField(this);
		}
	}
	// @Override
	public exitRule(listener: TypedDqlListener): void {
		if (listener.exitField) {
			listener.exitField(this);
		}
	}
	// @Override
	public accept<Result>(visitor: TypedDqlVisitor<Result>): Result {
		if (visitor.visitField) {
			return visitor.visitField(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class RelationalOperatorContext extends ParserRuleContext {
	public OPERATION(): TerminalNode { return this.getToken(TypedDqlParser.OPERATION, 0); }
	public WS(): TerminalNode[];
	public WS(i: number): TerminalNode;
	public WS(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(TypedDqlParser.WS);
		} else {
			return this.getToken(TypedDqlParser.WS, i);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return TypedDqlParser.RULE_relationalOperator; }
	// @Override
	public enterRule(listener: TypedDqlListener): void {
		if (listener.enterRelationalOperator) {
			listener.enterRelationalOperator(this);
		}
	}
	// @Override
	public exitRule(listener: TypedDqlListener): void {
		if (listener.exitRelationalOperator) {
			listener.exitRelationalOperator(this);
		}
	}
	// @Override
	public accept<Result>(visitor: TypedDqlVisitor<Result>): Result {
		if (visitor.visitRelationalOperator) {
			return visitor.visitRelationalOperator(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class UnaryRelationalOperatorContext extends ParserRuleContext {
	public UNARY_OPERATION(): TerminalNode { return this.getToken(TypedDqlParser.UNARY_OPERATION, 0); }
	public WS(): TerminalNode[];
	public WS(i: number): TerminalNode;
	public WS(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(TypedDqlParser.WS);
		} else {
			return this.getToken(TypedDqlParser.WS, i);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return TypedDqlParser.RULE_unaryRelationalOperator; }
	// @Override
	public enterRule(listener: TypedDqlListener): void {
		if (listener.enterUnaryRelationalOperator) {
			listener.enterUnaryRelationalOperator(this);
		}
	}
	// @Override
	public exitRule(listener: TypedDqlListener): void {
		if (listener.exitUnaryRelationalOperator) {
			listener.exitUnaryRelationalOperator(this);
		}
	}
	// @Override
	public accept<Result>(visitor: TypedDqlVisitor<Result>): Result {
		if (visitor.visitUnaryRelationalOperator) {
			return visitor.visitUnaryRelationalOperator(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class ValueContext extends ParserRuleContext {
	public VALUE(): TerminalNode { return this.getToken(TypedDqlParser.VALUE, 0); }
	public DATATYPE(): TerminalNode { return this.getToken(TypedDqlParser.DATATYPE, 0); }
	public WS(): TerminalNode[];
	public WS(i: number): TerminalNode;
	public WS(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(TypedDqlParser.WS);
		} else {
			return this.getToken(TypedDqlParser.WS, i);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return TypedDqlParser.RULE_value; }
	// @Override
	public enterRule(listener: TypedDqlListener): void {
		if (listener.enterValue) {
			listener.enterValue(this);
		}
	}
	// @Override
	public exitRule(listener: TypedDqlListener): void {
		if (listener.exitValue) {
			listener.exitValue(this);
		}
	}
	// @Override
	public accept<Result>(visitor: TypedDqlVisitor<Result>): Result {
		if (visitor.visitValue) {
			return visitor.visitValue(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class LogicalOperatorContext extends ParserRuleContext {
	public LOGICAL(): TerminalNode { return this.getToken(TypedDqlParser.LOGICAL, 0); }
	public WS(): TerminalNode[];
	public WS(i: number): TerminalNode;
	public WS(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(TypedDqlParser.WS);
		} else {
			return this.getToken(TypedDqlParser.WS, i);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return TypedDqlParser.RULE_logicalOperator; }
	// @Override
	public enterRule(listener: TypedDqlListener): void {
		if (listener.enterLogicalOperator) {
			listener.enterLogicalOperator(this);
		}
	}
	// @Override
	public exitRule(listener: TypedDqlListener): void {
		if (listener.exitLogicalOperator) {
			listener.exitLogicalOperator(this);
		}
	}
	// @Override
	public accept<Result>(visitor: TypedDqlVisitor<Result>): Result {
		if (visitor.visitLogicalOperator) {
			return visitor.visitLogicalOperator(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


/* eslint-enable */
