/* eslint-disable */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrderType, } from '../../lib/util/services';
/* eslint-enable */

@Component({
  selector: 'app-table-field-sorter',
  templateUrl: './table-field-sorter.component.html',
  styleUrls: ['./table-field-sorter.component.scss']
})
export class TableFieldSorterComponent<T> implements OnInit {

  @Input()
  text: string;

  @Input()
  orderField: T;

  @Input()
  orderType: OrderType;

  @Input()
  checkAllEnabled?: boolean = false;

  @Input()
  checkAllSelected?: boolean = false;

  @Output()
  orderChange: EventEmitter<T> = new EventEmitter<T>();

  @Output()
  onCheckAllClicked: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {
  }

  onOrderChanged() {
      this.orderChange.emit(this.orderField);
  }

  public isAsc(): boolean {
    return this.orderType === OrderType.ASC;
  }

  public isDesc(): boolean {
    return this.orderType === OrderType.DESC;
  }

  public isNone(): boolean {
    return !this.orderType;
  }

}
