/* eslint-disable */
import { Injectable } from '@angular/core';
import { EmptyMessage, IdentityMessage, } from '../util/messages';
import { Observable } from 'rxjs';
import { Calendar } from './calendar.service';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { ResourceQueryResult } from '../util/services';
import { HttpClient } from '@angular/common/http';
import { TaskRecordResource } from '../task/task-record-resource.service';
import { AddressResource } from '../address';

/* eslint-enable */

@Injectable()
export class CalendarResourceService extends BaseHttpService {

  query(request: CalendarResource.QueryRequest): Observable<ResourceQueryResult<CalendarResource.CalendarEvent>> {
    return this.http.get<ResourceQueryResult<CalendarResource.CalendarEvent>>(this.url, this.parseParams(request));
  }

  timeline(request: CalendarResource.QueryRequest): Observable<ResourceQueryResult<CalendarResource.CalendarEvent>> {
    return this.http.get<ResourceQueryResult<CalendarResource.CalendarEvent>>(this.url + `timeline`, this.parseParams(request));
  }

  get(request: IdentityMessage): Observable<CalendarResource.CalendarEvent> {
    return this.http.get<CalendarResource.CalendarEvent>(this.url + `${request.id}`);
  }

  createAvailable(request: CalendarResource.CreateAvailableRequest): Observable<CalendarResource.CreateAvailableResponse> {
    return this.http.post<CalendarResource.CreateAvailableResponse>(this.url + `available`, request);
  }

  updateAvailable(request: CalendarResource.UpdateAvailableRequest): Observable<EmptyMessage> {
    return this.http.put(this.url + `available/${request.id}`, request);
  }

  deleteAvailable(request: CalendarResource.DeleteAvailableRequest): Observable<EmptyMessage> {
    return this.http.delete(this.url + `${request.id}`);
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/calendar/');
  }

}

export namespace CalendarResource {

  import CalendarEventType = Calendar.CalendarEventType;

  export interface QueryRequest {
    id?: string;
    owner_user_group_id?: string;
    owner_user_id?: string;
    owner_mobile_app_id?: string;
    type?: string;
    task_record_state?: string;
    assignee_type?: string;
    from_time?: string;
    to_time?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
    task_record_task_id?: string;
  }

  export interface CreateAvailableRequest {
    start_time: string;
    end_time: string;
    comment?: string;
  }

  export interface CreateAvailableResponse {
    id: number;
  }

  export interface UpdateAvailableRequest {
    id: number;
    start_time: string;
    end_time: string;
    comment?: string;
  }

  export interface DeleteAvailableRequest {
    id: number;
  }

  export interface CalendarEvent {
    id: number;
    start_time: string;
    end_time: string;
    type: CalendarEventType;
    comment?: string;
    task_record?: CalendarEventTaskRecord;
    project_record?: CalendarEventProjectRecord;
    owner: CalendarEventOwner;
  }

  export interface CalendarEventTaskRecord {
    id: number;
    name: string;
    confirmed: boolean;
    state: string;
    place_of_consumption?: TaskRecordResource.PlaceOfConsumption;
    customer_record?: {
      id: number;
      name: string;
      postal_address?: AddressResource.PostalAddressResource;
      phone_numbers: AddressResource.PhoneNumberResource[];
    };
    user?: {
      id: number;
      name: string;
    };
    user_group?: {
      id: number;
      name: string;
    };
    mobile_app?: {
      id: number;
      name: string;
    };
  }

  export interface CalendarEventProjectRecord {
    project_id: number;
    project_record_id: number;
    name: string;
    external_id: string;
    address?: AddressResource.PostalAddressResource;
    task_count: number;
  }

  export interface CalendarEventOwner {
    user_id?: number;
    mobile_app_id?: number;
    name?: string;
    color?: number;
  }

}
