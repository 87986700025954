<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
    <div class="breadcrumb-menu d-flex">
      <div *ngIf="rightModel.documentFileUpdate.hasRight() && fileDocumentId"
           class="breadcrumb-button-icon-container cursor-pointer negative-margin-right"
           (click)="delete()">
        <a class="btn-setting d-flex align-items-center"
           title="{{'COMMON_BUTTON_DELETE' | translate}}">
          <i class="icomoon icomoon-trash"></i>
          <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_DELETE' | translate}}</span>
        </a>
      </div>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <form class="form-horizontal bordered-row" (ngSubmit)="f.form.valid && update()" #f="ngForm" novalidate>

    <div class="card">
      <div class="card-header">
        {{'DOCUMENT_PANEL_HEADING_ADD'|translate}}
      </div>

      <div class="card-body">

        <div class="w-100 mb-1">
          <app-file-upload
            [uploadPath]="fileUploadPath"
            (responseContent)="onUploadSuccess($event)">
          </app-file-upload>
        </div>

        <h5 class="mb-1">{{'DOCUMENT_HTML_PANEL_HEADING_DETAIL' | translate}}</h5>

        <!--NAME-->
        <div class="form-group row" [ngClass]="{ 'has-danger': (f.submitted && !name.valid) }">
          <label class="col-md-4 form-control-label required-field-label">
            {{'COMMON_NAME' | translate}}
          </label>
          <div class="col-md-8">
            <input
              type="text"
              class="form-control"
              maxlength="{{getTextMaximumLength()}}"
              placeholder="{{'COMMON_NAME' | translate}}" [(ngModel)]="model.name"
              [ngClass]="{ 'form-control-danger': (f.submitted && !name.valid) }"
              name="name"
              id="name"
              #name="ngModel"
              required
              [disabled]="!fileDocumentId">
            <div class="form-control-feedback"
                 *ngIf="f.submitted && !name.valid">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
          </div>
        </div>

        <!--EXTERNAL ID-->
        <div class="form-group row"
             [ngClass]="{ 'has-danger': fieldErrors.external_id || (f.submitted && !external_id.valid) }">
          <label class="col-md-4 form-control-label">{{'COMMON_EXTERNAL_ID' | translate}}</label>
          <div class="col-md-8">
            <input
              type="text"
              class="form-control"
              maxlength="{{getTextMaximumLength()}}"
              placeholder="{{'COMMON_EXTERNAL_ID' | translate}}"
              [(ngModel)]="model.external_id"
              (ngModelChange)="removeFieldError(fieldErrors.external_id)"
              [ngClass]="{ 'form-control-danger': fieldErrors.external_id || (f.submitted && !external_id.valid) }"
              name="external_id"
              id="external_id"
              #external_id="ngModel"
              [disabled]="!fileDocumentId">
            <div class="form-control-feedback"
                 *ngIf="f.submitted && !external_id.valid">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
            <div class="form-control-feedback" *ngIf="fieldErrors.external_id">{{fieldErrors.external_id.text}}</div>
          </div>
        </div>

        <!--DESCRIPTION-->
        <div class="form-group row">
          <label class="col-md-4 form-control-label">{{'COMMON_DESCRIPTION' | translate}}</label>
          <div class="col-md-8">
            <input
              type="text"
              class="form-control"
              maxlength="{{getTextMaximumLength()}}"
              placeholder="{{'COMMON_DESCRIPTION' | translate}}"
              [(ngModel)]="model.description"
              name="description"
              id="description"
              #description="ngModel"
              [disabled]="!fileDocumentId">
          </div>
        </div>

        <!--GROUP-->
        <div class="form-group row">
          <label class="col-md-4 form-control-label">{{'DOCUMENT_GROUP' | translate}}</label>
          <div class="col-md-8">
            <angular2-multiselect
              [(ngModel)]="model.document_group"
              [ngModelOptions]="{standalone: true}"
              [data]="documentGroupList"
              [settings]="dropdownSettings"
              (onRemoteSearch)="loadDocumentGroupList($event.target.value)"
              [readonly]="!fileDocumentId"
              [readonlyBorderEnabled]="true">
            </angular2-multiselect>
          </div>
        </div>

        <!--THUMBNAIL-->
        <div class="form-group row mb-0">
          <label class="col-md-4 form-control-label">{{'DOCUMENT_THUMBNAIL' | translate}}</label>
          <div class="col-md-8">
            <div *ngIf="!thumbnailSuccessfullyUploaded">
              <app-file-upload
                [uploadPath]="''"
                [uploadOnDrop]="false"
                [acceptedMimeType]="UploadMimeType.IMAGE"
                (onFileDropped)="thumbnailUploadDialog.onImageSelected($event)"
                [disabled]="!fileDocumentId">
              </app-file-upload>
            </div>

            <div *ngIf="thumbnailSuccessfullyUploaded">
              <div class="alert alert-success">
                <h4 class="alert-title">{{'ALERT_TEXT_UPLOAD_SUCCESSFUL' | translate}}</h4>
                <p>{{'ALERT_TEXT_SUCCESSFULLY_UPLOADED' | translate}}</p>
              </div>
            </div>
          </div>
        </div>


      </div>  <!-- ./card-body end -->

      <div class="card-footer d-flex justify-content-end">
        <input type="submit" class="btn btn-primary" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
      </div>
    </div> <!-- ./card end -->
  </form>
</div>

<app-cropped-image-uploader-dialog
  #thumbnailUploadDialog
  [titleKey]="'DOCUMENT_GROUP_UPLOAD_THUMBNAIL'"
  [fixedRatioKey]="'DOCUMENT_GROUP_DOCUMENT_LIBRARY_ASPECT_RATIO'"
  [fixedRatioSwitchEnabled]="true"
  [fileName]="'file-document-' + fileDocumentId + '-thumbnail.png'"
  [uploadPath]="thumbnailUploadPath"
  [aspectRatio]="1 / 1"
  (onImageUploaded)="onThumbnailUploaded()">
</app-cropped-image-uploader-dialog>
