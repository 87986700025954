<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>

<div class="container-custom-padding animated fadeIn">
  <responsive-tabset class="fullscreen_tab" #transportTabs>
    <responsive-tab heading="{{'SHIPMENT_TASKS'|translate}}" (selectTab)="activateTab('SHIPMENT_TASKS')">
      <ng-container [ngTemplateOutlet]="transportBaseDataTemplate"></ng-container>
      <div class="container-vertical-padding-half"></div>
      <app-transport-task-list #transportTaskList
                               (transportDetailClicked)="taskDetailDialogContainer.openTransportTaskDetailDialog($event)"
                               (transportCreateClicked)="taskDetailDialogContainer.openTransportTaskCreateDialog($event)"
                               (transportArrivalClicked)="taskDetailDialogContainer.timePlan($event)"
                               (waypointOptimizeClicked)="taskDetailDialogContainer.openTransportOptimizeWaypointsDialog($event)"
                               (ReloadTransportEvent)="reload()"
                               [postalAddressFormat]="postalAddressFormat"
                               [transportId]="transportId"
                               [transportState]="transportBaseData.stateEnum">
      </app-transport-task-list>
    </responsive-tab>
    <responsive-tab heading="{{'SHIPMENTS'|translate}}" (selectTab)="activateTab('SHIPMENTS')">
      <ng-container [ngTemplateOutlet]="transportBaseDataTemplate"></ng-container>
      <div class="container-vertical-padding-half"></div>
      <app-transport-shipment-list
        #transportShipmentList
        [transportId]="transportId"
        [transportState]="transportBaseData.stateEnum">
      </app-transport-shipment-list>
    </responsive-tab>
    <responsive-tab heading="{{'COMMON_MESSAGES'|translate}}" (selectTab)="activateTab('COMMON_MESSAGES')">
      <ng-container [ngTemplateOutlet]="transportBaseDataTemplate"></ng-container>
      <div class="container-vertical-padding-half"></div>
      <app-chat
        #transportMessageList
        [id]="transportId"
        [messageSendEnable]="getMessageSendEnabled()"
        [chatService]="getChatService()">
      </app-chat>
    </responsive-tab>
    <responsive-tab heading="{{'COMMON_DOCUMENTS'|translate}}" (selectTab)="activateTab('COMMON_DOCUMENTS')">
      <ng-container [ngTemplateOutlet]="transportBaseDataTemplate"></ng-container>
      <div class="container-vertical-padding-half"></div>
      <app-transport-document-list #transportDocumentList
                                   [transportWaybillNumber]="transportBaseData.waybillNumber">
      </app-transport-document-list>
    </responsive-tab>
    <responsive-tab heading="{{'TRANSPORT_HISTORY'|translate}}" (selectTab)="activateTab('TRANSPORT_HISTORY')">
      <ng-container [ngTemplateOutlet]="transportBaseDataTemplate"></ng-container>
      <div class="container-vertical-padding-half"></div>
      <app-transport-log-list
        #transportLogList
        [postalAddressFormat]="postalAddressFormat"
        (transportLogModalShow)="showTransportLogDetailDialog($event)">
      </app-transport-log-list>
      <div class="container-vertical-padding-half"></div>
      <app-trigger-history
        *ngIf="rightModel.transportTriggerInstanceRead.hasRight()"
        [triggerService]="getTransportService()"
        [instanceParentId]="transportId"
        [scope]="'TRANSPORT'">
      </app-trigger-history>
    </responsive-tab>
    <responsive-tab [disabled]="transportBaseData.stateEnum === 'UNDER_PLANNING' || transportBaseData.stateEnum === 'OPEN'"
           heading="{{'TRANSPORT_POSITION_LOG_MAP'|translate}}"
         (selectTab)="activateTab('TRANSPORT_POSITION_LOG_MAP')">
      <ng-container [ngTemplateOutlet]="transportBaseDataTemplate"></ng-container>
      <div class="container-vertical-padding-half"></div>
      <app-transport-positionlog-map #transportPositionLogMap [transportId]="transportId">
      </app-transport-positionlog-map>
    </responsive-tab>
    <responsive-tab heading="{{'TRANSPORT_ATTACHMENTS'|translate}}" (selectTab)="activateTab('TRANSPORT_ATTACHMENT')">
      <ng-container [ngTemplateOutlet]="transportBaseDataTemplate"></ng-container>
      <div class="container-vertical-padding-half"></div>
      <app-transport-attachment
        #transportAttachment
        [transportId]="transportId"
        (openImageViewerWindowEvent)="imageViewerWindow.openImageViewerWindow()"
        (clickedImageEventEmitter)="imageViewerWindow.setClickedImage($event)">
      </app-transport-attachment>
    </responsive-tab>
  </responsive-tabset>
</div>

<!--Transport base data component template-->
<ng-template #transportBaseDataTemplate>
  <app-transport-base-data
    [transportBaseData]="transportBaseData"
    (openBaseDataDetailDialog)="openTransportBaseDataDialog()"
    (openModifyBaseDataDialog)="openTransportModifyDialog()"
    (openDriverDialog)="showSetDriverDialog()"
    (openVehicleDialog)="showSetVehicleDialog()"
    (openSecurityGuardDialog)="showSetSecurityGuardDialog()">
  </app-transport-base-data>
</ng-template>
<!--Transport base data component template end-->

<!--TransportLogDetailModal-->
<app-transport-log-detail-modal
  #transportLogDetail
  [transportId]="transportId">
</app-transport-log-detail-modal>
<!--/.TransportLogDetailModal-->

<div bsModal [config]="UiConstants.modalConfig" #transportModifyDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="transportModifyDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'TRANSPORT_MODIFY_BASE_DATA' | translate}}</h4>
        <button type="button" class="close" (click)="closeTransportModifyDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" [formGroup]="formGroup" #f="ngForm">

        <div class="form-group row"
             [ngClass]="{ 'has-danger': hasLocalFieldError('externalId')}">
          <label class="col-form-label col-md-4 required-field-label">
            {{'COMMON_EXTERNAL_ID' | translate}}
          </label>
          <div class="col-md-8">
            <input type="text" class="form-control" placeholder="{{'COMMON_EXTERNAL_ID' | translate}}"
                   [ngClass]="{ 'form-control-danger': hasLocalFieldError('externalId') }"
                   [(ngModel)]="transportModifyModel.externalId"
                   formControlName="externalId"
                   (ngModelChange)="removeFieldError(Transport.ValidatedField.EXTERNAL_ID)">
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('externalId')">
              {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
            </div>
          </div>
        </div>

        <div class="form-group row" *ngIf="underPlanning && transportModifyModel.transporterCompanyUserSettable"
             [ngClass]="{ 'has-danger': hasLocalFieldError('transporterCompany')}">
          <label class="col-form-label col-md-4 required-field-label">
            {{'TRANSPORT_CREATE_TRANSPORTER_COMPANY' | translate}}
          </label>
          <div class="col-md-8">
            <angular2-multiselect
              class="form-control"
              [(ngModel)]="transportModifyModel.transporterCompany"
              [data]="transporterCompanies"
              [settings]="dropdownSettings"
              (ngModelChange)="onTransporterCompanyChange()"
              (onRemoteSearch)="loadTransporterCompanies($event.target.value)"
              trim="blur" validateOnBlur [validateFormControl]="formGroup.controls['transporterCompany']"
              formControlName="transporterCompany">
            </angular2-multiselect>
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('transporterCompany')">
              {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
            </div>
          </div>
          <!-- /.col-md-8 -->
        </div>

        <div class="form-group row" *ngIf="underPlanning"
             [ngClass]="{ 'has-danger': hasLocalFieldError('vehicle')}">
          <label class="col-form-label col-md-4" [class.required-field-label]="!underPlanning">
            {{'TRANSPORT_CREATE_VEHICLE' | translate}}
          </label>
          <div class="col-md-8">
            <angular2-multiselect
              class="form-control"
              [(ngModel)]="transportModifyModel.vehicle"
              [data]="vehicleList"
              [settings]="dropdownSettings"
              (ngModelChange)="removeFieldError(Transport.ValidatedField.VEHICLE)"
              (onRemoteSearch)="loadVehicles($event.target.value)"
              trim="blur" validateOnBlur [validateFormControl]="formGroup.controls['vehicle']"
              formControlName="vehicle">
            </angular2-multiselect>
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('vehicle')">
              {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
            </div>
          </div>
          <!-- /.col-md-8 -->
        </div>

        <div class="form-group row" *ngIf="underPlanning"
             [ngClass]="{ 'has-danger': hasLocalFieldError('driver')}">
          <label class="col-form-label col-md-4" [class.required-field-label]="!underPlanning">
            {{'TRANSPORT_CREATE_DRIVER' | translate}}
          </label>
          <div class="col-md-8">
            <angular2-multiselect
              class="form-control"
              [(ngModel)]="transportModifyModel.driver"
              [data]="driverList"
              [settings]="dropdownSettings"
              (ngModelChange)="removeFieldError(Transport.ValidatedField.DRIVER)"
              (onRemoteSearch)="loadDrivers($event.target.value)"
              trim="blur" validateOnBlur [validateFormControl]="formGroup.controls['driver']"
              formControlName="driver">
            </angular2-multiselect>
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('driver')">
              {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
            </div>
          </div>
          <!-- /.col-md-8 -->
        </div>

        <div class="form-group row" *ngIf="underPlanning">
          <label class="col-form-label col-md-4">{{'TRANSPORT_CREATE_SAFETY_SHIPPING' | translate}}</label>
          <div class="col-md-8">
            <app-animated-checkbox
              [(selected)]="transportModifyModel.safetyShipping"
              [readonly]="!transportModifyModel.safetyShippingUserSettable">
            </app-animated-checkbox>
          </div>
          <!-- /.col-md-8 -->
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-4">{{'COMMON_COMMENT' | translate}}</label>
          <div class="col-md-8">
            <input type="text" class="form-control" placeholder="{{'COMMON_COMMENT' | translate}}"
                   [(ngModel)]="transportModifyModel.comment"
                   [ngModelOptions]="{standalone: true}">
          </div>
          <!-- /.col-md-8 -->
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeTransportModifyDialog()">
          {{'COMMON_BUTTON_CANCEL' | translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="modifyTransport()">{{'COMMON_BUTTON_SAVE' |
          translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<app-transport-task-dialog-container
  (reload)="transportTaskList.reload($event)"
  [transportId]="transportId"
  [postalAddressFormat]="postalAddressFormat" #taskDetailDialogContainer>
</app-transport-task-dialog-container>

<app-image-viewer-window
  #imageViewerWindow
  [attachmentView]="transportAttachment">
</app-image-viewer-window>

<div bsModal [config]="UiConstants.modalConfig" #setSecurityGuardDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true"
     (onHide)="onModalHidden()">
  <div class="modal-dialog modal-lg" role="document" *ngIf="setSecurityGuardDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title"><strong>{{'TRANSPORT_SET_SECURITY_GUARD' | translate}}</strong></p>
        <button type="button" class="close" (click)="closeSetSecurityGuardDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form-horizontal bordered-row" (ngSubmit)="securityGuardForm.form.valid && setSecurityGuard()" #securityGuardForm="ngForm" novalidate>

          <!--region security guard name-->
          <div class="form-group row" [ngClass]="{ 'has-danger': hasSecurityGuardLocalFieldError(securityGuard) }">
            <label class="col-form-label form-control-label col-md-4 detail-title required-field-label">
              {{'COMMON_NAME' | translate}}
            </label>
            <div class="col-md-8">
              <input
                type="text" class="form-control"
                placeholder="{{'COMMON_NAME' | translate}}"
                [(ngModel)]="securityGuardName"
                [ngClass]="{ 'form-control-danger': hasSecurityGuardLocalFieldError(securityGuard) }"
                name="securityGuard"
                id="securityGuard"
                #securityGuard="ngModel"
                required>
              <div class="form-control-feedback"
                   *ngIf="hasSecurityGuardLocalFieldError(securityGuard)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
          </div>
          <!--endregion security guard name-->

          <div class="col-md-12 d-flex justify-content-end p-0 pl-1">
            <div>
              <button type="button" class="btn btn-outline-primary" (click)="closeSetSecurityGuardDialog()">
                {{'COMMON_BUTTON_CANCEL' | translate}}
              </button>
              <button type="submit" class="btn btn-primary ml-1">{{'COMMON_BUTTON_SAVE' |
                  translate}}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<div bsModal #setDriverDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true"
     (onHide)="onModalHidden()">
  <div class="modal-dialog modal-lg" role="document" *ngIf="setDriverDialogVisible">
    <div class="modal-content">
      <form class="form-horizontal bordered-row" (ngSubmit)="driverForm.form.valid && setDriver()" #driverForm="ngForm" novalidate>
        <div class="modal-header">
          <p class="modal-title"><strong>{{'TRANSPORT_SET_DRIVER' | translate}}</strong></p>
          <button type="button" class="close" (click)="closeSetDriverDialog()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group row"
               [ngClass]="{ 'has-danger': driverForm.submitted && !driverControl.valid}">
            <label class="col-form-label form-control-label col-md-4 detail-title required-field-label">
              {{'TRANSPORT_DRIVER' | translate}}
            </label>
            <div class="col-md-8">
              <angular2-multiselect
                class="form-control"
                [(ngModel)]="driverUser"
                [data]="driverList"
                [settings]="dropdownSettings"
                (onRemoteSearch)="loadDrivers($event.target.value)"
                id="driverControl" name="driverControl" #driverControl="ngModel"
                required>
              </angular2-multiselect>
              <div class="form-control-feedback" *ngIf="driverForm.submitted && !driverControl.valid">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
          </div>
          <div class="col-md-12 d-flex justify-content-end p-0 pl-1">
            <div>
              <button type="button" class="btn btn-outline-primary" (click)="closeSetDriverDialog()">
                {{'COMMON_BUTTON_CANCEL' | translate}}
              </button>
              <button type="submit" class="btn btn-primary ml-1">{{'COMMON_BUTTON_SAVE' |
                  translate}}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<div bsModal #setVehicleDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true"
     (onHide)="onModalHidden()">
  <div class="modal-dialog modal-lg" role="document" *ngIf="setVehicleDialogVisible">
    <div class="modal-content">
      <form class="form-horizontal bordered-row" (ngSubmit)="vehicleForm.form.valid && setVehicle()" #vehicleForm="ngForm" novalidate>
        <div class="modal-header">
          <p class="modal-title"><strong>{{'TRANSPORT_SET_VEHICLE' | translate}}</strong></p>
          <button type="button" class="close" (click)="closeSetVehicleDialog()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group row"
               [ngClass]="{ 'has-danger': vehicleForm.submitted && !vehicleControl.valid}">
            <label class="col-form-label form-control-label col-md-4 detail-title required-field-label">
              {{'TRANSPORT_VEHICLE' | translate}}
            </label>
            <div class="col-md-8">
              <angular2-multiselect
                class="form-control"
                [(ngModel)]="vehicle"
                [data]="vehicleList"
                [settings]="dropdownSettings"
                (onRemoteSearch)="loadVehicles($event.target.value)"
                id="vehicleControl" name="vehicleControl" #vehicleControl="ngModel"
                required>
              </angular2-multiselect>
              <div class="form-control-feedback" *ngIf="vehicleForm.submitted && !vehicleControl.valid">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
          </div>
          <div class="col-md-12 d-flex justify-content-end p-0 pl-1">
            <div>
              <button type="button" class="btn btn-outline-primary" (click)="closeSetVehicleDialog()">
                {{'COMMON_BUTTON_CANCEL' | translate}}
              </button>
              <button type="submit" class="btn btn-primary ml-1">{{'COMMON_BUTTON_SAVE' |
                  translate}}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<div bsModal [config]="UiConstants.modalConfig" #transportBaseDataDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true"
     (onHide)="onModalHidden()">
  <div class="modal-dialog modal-lg" role="document" *ngIf="transportBaseDataDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title"><strong>{{'FORM_ITEM_CREATE_DOCUMENT_EXTRA_DATA' | translate}}</strong></p>
        <button type="button" class="close" (click)="closeTransportBaseDataDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-4 d-flex align-items-center justify-content-center">
            <div class="d-flex flex-column justify-content-center align-items-center">
              <i class="icomoon icon-xl" [ngClass]="transportBaseData.stateIconClass"></i>
              <h5>{{transportBaseData.state | translate}}</h5>
            </div>
          </div>
          <div class="col-md-8 mb-2">
            <div class="d-flex flex-row align-items-baseline">
              <div>{{transportBaseData.waybillNumber}}</div>
            </div>
            <div class="d-flex flex-row align-items-baseline">
              <i class="icomoon icon-large icomoon-company pr-half"></i>
              <div class="transport-label-small pr-quarter">{{'TRANSPORT_CREATE_TRANSPORTER_COMPANY' | translate}}{{':'}}</div>
              <div>{{transportBaseData.transporterCompany}}</div>
            </div>
            <div class="d-flex flex-row align-items-baseline">
              <i class="icomoon icon-large icomoon-driver pr-half"></i>
              <div class="transport-label-small pr-quarter">{{'TRANSPORT_DRIVER' | translate}}{{':'}}</div>
              <div>{{transportBaseData.driver ? transportBaseData.driver : '-'}}</div>
            </div>
            <div class="d-flex flex-row align-items-baseline">
              <i class="icomoon icon-large icomoon-vehicles pr-half"></i>
              <div class="transport-label-small pr-quarter">{{'TRANSPORT_CREATE_VEHICLE' | translate}}{{':'}}</div>
              <div>{{transportBaseData.vehicle ? transportBaseData.vehicle : '-'}}</div>
            </div>
            <div class="d-flex flex-row align-items-baseline">
              <i class="icomoon icon-large icomoon-security-guard pr-half"></i>
              <div class="transport-label-small pr-quarter">{{'TRANSPORT_SECURITY_GUARD' | translate}}</div>
              <div>{{transportBaseData.securityGuard ? transportBaseData.securityGuard : '-'}}</div>
            </div>
            <div class="d-flex flex-row align-items-baseline">
              <i class="icomoon icon-large icomoon-safety-shipping pr-half"></i>
              <div class="transport-label-small pr-quarter">{{'TRANSPORT_SAFETY_SHIPPING' | translate}}{{':'}}</div>
              <div>{{(transportBaseData.safetyShipping ? 'COMMON_YES' : 'COMMON_NO') | translate}}</div>
            </div>
          </div>
        </div>
        <div class="row mb-1">
          <div class="col-md-3">
            <div class="transport-label-small">{{'COMMON_TABLE_HEADER_ID' | translate}}</div>
            <div>{{transportBaseData.id}}</div>
          </div>
          <div class="col-md-9">
            <div class="transport-label-small">{{'COMMON_EXTERNAL_ID' | translate}}</div>
            <div>{{transportBaseData.externalId}}</div>
          </div>
        </div>
        <div class="row mb-1">
          <div class="col-md-3">
              <div class="transport-label-small">{{'COMMON_CREATION_TIME' | translate}}</div>
              <div>{{transportBaseData.creationTime.toUtcIsoString() | date:'short'}}</div>
          </div>
          <div class="col-md-3">
            <div class="transport-label-small">{{'TRANSPORT_OPEN_TIME' | translate}}</div>
            <div>{{(transportBaseData.openTime?.toUtcIsoString() | date:'short') || '-'}}</div>
          </div>
          <div class="col-md-3">
            <div class="transport-label-small">{{'TRANSPORT_START_TIME' | translate}}</div>
            <div>{{(transportBaseData.startTime?.toUtcIsoString() | date:'short') || '-'}}</div>
          </div>
          <div class="col-md-3">
            <div class="transport-label-small">{{'TRANSPORT_CLOSE_TIME' | translate}}</div>
            <div>{{(transportBaseData.closeTime?.toUtcIsoString() | date:'short') || '-'}}</div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="transport-label-small">{{'COMMON_COMMENT' | translate}}</div>
            <div>{{transportBaseData.comment}}</div>
          </div>
        </div>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
