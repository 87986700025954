import { Injectable } from '@angular/core';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResourceQueryResult } from '../util/services';
import { EmptyMessage } from '../util/messages';
import { DownloadedFile } from '../util/downloaded-files';
import { UrlBuilder } from '../../util/url-builder';
import { map } from 'rxjs/operators';


@Injectable()
export class OrderDocumentResourceService extends BaseHttpService {

  private static readonly baseUrl: string = '/orders-documents/';

  query(request: OrderDocumentResource.QueryRequest): Observable<ResourceQueryResult<OrderDocumentResource.OrderDocument>> {
    return this.http.get<ResourceQueryResult<OrderDocumentResource.OrderDocument>>(
      this.url, this.parseParams(request)
    );
  }

  get(request: OrderDocumentResource.GetRequest): Observable<OrderDocumentResource.OrderDocument> {
    return this.http.get<OrderDocumentResource.OrderDocument>(
      this.url + `${request.id}`
    );
  }

  update(request: OrderDocumentResource.UpdateRequest): Observable<OrderDocumentResource.UUIDMessage> {
    return this.http.put<OrderDocumentResource.UUIDMessage>(this.url + `${request.id}`, request);
  }

  delete(request: OrderDocumentResource.DeleteRequest): Observable<EmptyMessage> {
    return this.http.delete<EmptyMessage>(this.url + `${request.id}`);
  }

  download(request: OrderDocumentResource.UUIDMessage): Observable<DownloadedFile> {
    const url = UrlBuilder.create('orders-documents/:id/download')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .namedString('id', <string> request.id)
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob',
      params: this.parseParams(request).params
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  constructor(private http: HttpClient, private resourceHelper: ResourceHelper) {
    super(resourceHelper, OrderDocumentResourceService.baseUrl);
  }

}

export namespace OrderDocumentResource {

  export interface OrderDocument {
    id: string;
    creation_time: string;
    granted_rights?: string[];
    order_id: number;
    uploader_user_id?: number;
    original_file_name: string;
    content_hash: string;
    content_size: number;
    content_type: string;
    meta: Meta;
  }

  export interface Meta {
    type?: string;
    comment?: string;
    update_time: string;
    version: number;
  }

  export interface QueryRequest {
    fields?: string;
    filter?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
    rights?: string;
  }

  export interface GetRequest extends UUIDMessage {
  }

  export interface UpdateRequest extends UUIDMessage {
    version: number;
    type?: string;
    comment?: string;
  }

  export interface DeleteRequest extends UUIDMessage {
  }

  export interface UUIDMessage {
    id: string;
  }

}
