/* eslint-disable */
import { Component, OnInit } from '@angular/core';
import { StateName } from '../../../app.state-names';
import { TranslateService } from '@ngx-translate/core';
import { Transition } from '@uirouter/angular';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { Dates, OffsetDateTime } from '../../../lib/util/dates';
import { StockChangeTypeItem } from '../stock-change-list/stock-change-list.component';
import { StockChange, StockChangeService } from '../../../lib/stock/stock-change.service';
import { User, UserService } from '../../../lib/user.service';
import { Arrays } from '../../../lib/util/arrays';
import { CustomerRecordService } from '../../../lib/customer/customer-record.service';
import { List, Set } from 'immutable';
import { Stock, StockService } from '../../../lib/stock/stock.service';
import { StockItem, StockItemService, StockItemType, stockItemTypes } from '../../../lib/stock/stock-item.service';
import { WeightFactory } from '../../../util/weight-utils';
import { ResourceQueryResult } from '../../../lib/util/services';
import StockChangeItem = StockChange.StockChangeItem;
import { OptionItem } from '../../../util/core-utils';
/* eslint-enable */

@Component({
  selector: 'app-stock-change-detail',
  templateUrl: './stock-change-detail.component.html',
  styleUrls: ['./stock-change-detail.component.scss']
})
export class StockChangeDetailComponent implements OnInit {

  WeightFactory = WeightFactory;

  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  stockChangeId: number;
  model: StockChangeModel = new StockChangeModel();
  tableDisplayOptions?: TableDisplayOptionsModel;
  productList: ProductModel[] = [];
  stockNameList: StockName[] = [];
  mixedCurrency: boolean = false;
  stockItemTypes: OptionItem<StockItemType>[] = [];

  constructor(private translateService: TranslateService,
              private stockChangeService: StockChangeService,
              private customerRecordService: CustomerRecordService,
              private stockService: StockService,
              private stockItemService: StockItemService,
              private userService: UserService,
              private transition: Transition) { }

  ngOnInit() {
    this.stockChangeId = this.transition.params().id;
    this.initBreadcrumb();
    this.loadModel();
    this.loadStockItemTypes();
  }

  private initBreadcrumb() {
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_STOCK_CHANGE').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.STOCK_CHANGE_LIST});
        this.breadcrumbSelf = this.stockChangeId.toString();
      }
    );
  }

  private loadModel() {
    this.stockChangeService.get({
      withItems: true,
      id: this.stockChangeId
    }).subscribe((stockChange: StockChange.StockChange) => {
      this.model.trxId = stockChange.trxId;
      this.model.changeTime = stockChange.changeTime;
      this.model.note = stockChange.note;
      this.model.items = stockChange.items;
      this.loadStockData(() => {
        this.loadStockItems();
      });
      this.loadAssignee(stockChange.assigneeUserId);
      this.loadStockChangeType(stockChange.changeType);
      if (stockChange.changeType === 'INTAKE') {
        this.loadSupplierName(stockChange.supplierId);
        this.model.acquisitionId = stockChange.acquisitionId;
      }
    });
  }

  private loadStockData(completion: () => void) {
    this.stockService.query({
      fields: ['id', 'name'].join(',')
    })
      .subscribe(
      (result: ResourceQueryResult<Stock>) => {
        result.items.forEach((stock) => {
          this.stockNameList.push({
            id: stock.id,
            name: stock.name
          });
        });
        completion();
      }
    );
  }

  private loadStockItems() {
    if (this.model.items) {
      const stockItemIds: number[] = [];
      this.model.items.forEach((item) => {
        if (item) {
          stockItemIds.push(item.stockItemId);
        }
      });
      this.stockItemService.query({id: stockItemIds.join(',')})
        .subscribe((result: ResourceQueryResult<StockItem>) => {
        result.items.forEach((stockItem) => {
          this.model.items!.forEach((stockChangeItem) => {
            if (stockChangeItem && stockItem.id === stockChangeItem.stockItemId) {
              const sourceStock = this.stockNameList.find((stock) => stock.id === stockChangeItem.sourceStockId);
              const destinationStock = this.stockNameList.find((stock) => stock.id === stockChangeItem.destinationStockId);
              let sumPrice = 0;
              if (stockItem.unit_price && stockItem.unit_price.value && stockChangeItem.amount) {
                sumPrice = stockChangeItem.amount.toNumber() * stockItem.unit_price.value;
              }
              let currencyCode = '';
              if (stockItem.unit_price && stockItem.unit_price.currency_code) {
                currencyCode = stockItem.unit_price.currency_code;
              }
              this.model.sumPrice += sumPrice;
              if (stockChangeItem.amount && stockItem.weight_in_grams && stockItem.weight_in_grams > 0) {
                this.model.sumWeight += (stockItem.weight_in_grams * stockChangeItem.amount.toNumber());
              }
              const productItem: ProductModel = {
                stockItemId: stockItem.id,
                stockItemName: stockItem.name,
                stockItemType: this.getStockItemTypeText(stockItem.type),
                externalId: stockItem.external_id,
                sourceStockName: sourceStock ? sourceStock.name : '',
                destinationStockName: destinationStock ? destinationStock.name : '',
                amount: stockChangeItem.amount ? String(stockChangeItem.amount) : '',
                unit: stockItem.unit,
                expiryDate: stockChangeItem.expiryDate ? stockChangeItem.expiryDate : Dates.emptyOffsetDateTime(),
                sumPrice: sumPrice,
                currencyCode: currencyCode
              };
              this.productList.push(productItem);
              this.model.sumPriceCurrencyCode = productItem.currencyCode;
            }
          })
        });
        this.mixedCurrency = !(this.productList.every( (val, i, arr) => val.currencyCode === arr[0].currencyCode ));
      });
    }
  }

  private loadAssignee(assigneeUserId: number) {
    this.userService.get({
      id: assigneeUserId,
    }).subscribe(
      (user: User) => {
        this.model.assigneeName = user.person_name;
      });
  }

  private loadStockChangeType(changeType: StockChange.StockChangeType) {
    const ret = StockChange.stockChangeTypes.find((stockChangeType => stockChangeType.type === changeType))!;
    this.translateService.get(ret.stringKey).subscribe((result) => {
      this.model.stockChangeType = {
        id: ret.type,
        key: ret.stringKey,
        text: result,
        iconClass: ''
      };
      this.initTableDisplayOptions();
    });
  }

  private loadStockItemTypes() {
    this.stockItemTypes = [];
    stockItemTypes.forEach(t => {
      const item: OptionItem<StockItemType> = {
        id: t.type,
        text: '....'
      };
      this.stockItemTypes.push(item);
      this.translateService.get(t.stringKey).subscribe((text: string) => {
        item.text = text;
      });
    });
  }

  getStockItemTypeText(type: StockItemType): string {
    const item = this.stockItemTypes.find(t => t.id === type);
    return item ? item.text : '';
  }

  private initTableDisplayOptions() {
    this.tableDisplayOptions = new TableDisplayOptionsModel();
    switch (this.model.stockChangeType.id) {
      case 'INTAKE':
        this.tableDisplayOptions.showExpiryDate = true;
        this.tableDisplayOptions.showSourceStock = false;
        this.tableDisplayOptions.showDestinationStock = true;
        break;
      case 'ORDER_INTAKE':
        this.tableDisplayOptions.showExpiryDate = false;
        this.tableDisplayOptions.showSourceStock = false;
        this.tableDisplayOptions.showDestinationStock = true;
        break;
      case 'OUTTAKE':
        this.tableDisplayOptions.showExpiryDate = false;
        this.tableDisplayOptions.showSourceStock = true;
        this.tableDisplayOptions.showDestinationStock = false;
        break;
      case 'OUTTAKE_REVERSAL':
        this.tableDisplayOptions.showExpiryDate = false;
        this.tableDisplayOptions.showSourceStock = false;
        this.tableDisplayOptions.showDestinationStock = true;
        break;
      case 'MOVEMENT':
        this.tableDisplayOptions.showExpiryDate = false;
        this.tableDisplayOptions.showSourceStock = true;
        this.tableDisplayOptions.showDestinationStock = true;
        break;
      case 'DISPOSAL':
        this.tableDisplayOptions.showExpiryDate = false;
        this.tableDisplayOptions.showSourceStock = true;
        this.tableDisplayOptions.showDestinationStock = true;
        break;
      default:
        this.tableDisplayOptions.showExpiryDate = false;
        this.tableDisplayOptions.showSourceStock = false;
        this.tableDisplayOptions.showDestinationStock = false;
        break;
    }
  }

  private loadSupplierName(supplierId: number | undefined) {
      if (!supplierId) {
        this.model.supplierName = '';
        return;
      }
      const customerRecordIdSet: number[] = [];
      customerRecordIdSet.push(supplierId);
      this.customerRecordService.globalQuery({
        withFormRecord: false,
        customerRecordIdSet: Set.of(...customerRecordIdSet)
      }).subscribe( customers => {
        Arrays.iterateByIndex(customers.items.toArray(), customer => {
          this.model.supplierName = customer.name
        });
      });
    }
}

export class StockChangeModel {
  trxId: string;
  stockChangeType: StockChangeTypeItem;
  assigneeName: string;
  changeTime: OffsetDateTime = Dates.emptyOffsetDateTime();
  note?: string;
  sumPrice: number = 0;
  sumPriceCurrencyCode: string;
  sumWeight: number = 0;
  acquisitionId?: number;
  supplierName: string;
  items?: List<StockChangeItem>;
}

export class ProductModel {
  stockItemId: number;
  stockItemName: string;
  stockItemType: string;
  externalId: string;
  sourceStockName?: string;
  destinationStockName?: string;
  amount: string;
  unit: string;
  expiryDate: OffsetDateTime;
  sumPrice: number;
  currencyCode: string;
}

export class TableDisplayOptionsModel {
  showExpiryDate?: boolean = undefined;
  showSourceStock?: boolean = undefined;
  showDestinationStock?: boolean = undefined;
}

export interface StockName {
  id: number;
  name: string;
}
