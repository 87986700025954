/* eslint-disable */
import { Component } from '@angular/core';
import {
  FormRecordFieldContext,
  FormRecordFieldValueUpdateArgs,
  FormRecordFieldView,
  FormRecordFieldViewContext,
  SetTmpReadonlyArgs,
  SetTmpReadonlyResult
} from '../../../../../util/form/form-utils';
import { Form } from '../../../../../lib/form/form.service';
import { InputMask } from '../../../../../util/input-masks';
import { FormGroup } from '@angular/forms';
import { FormRef, QueryResult } from '../../../../../lib/util/services';
import { FieldActivationState, FieldActivationStateResolver } from '../../../../../util/form/form-editors';
import { Command } from '../../../../../util/command';
import { FormRecord } from '../../../../../lib/form/form-record.service';
import { Order, OrderService } from '../../../../../lib/order/order.service';
import { Set } from 'immutable';
import { Currency, CurrencyService } from '../../../../../lib/currency.service';
import { FormRecordInactivityManager, noOpCommand } from '../../manager/form-record-inactivity-manager';
import { Company, CompanyService } from '../../../../../lib/company/company.service';
import { Observable } from 'rxjs';

/* eslint-enable */

@Component({
  selector: 'app-form-record-process-order-packaging-field',
  templateUrl: './form-record-process-order-packaging-field.component.html',
  styleUrls: ['./form-record-process-order-packaging-field.component.scss']
})
export class FormRecordProcessOrderPackagingFieldComponent implements FormRecordFieldView {

  public readonly selector: Form.FieldDataTypeSelector.PROCESS_ORDER_PACKAGING;

  formRecordFieldContext?: FormRecordFieldContext;
  formRecordInactivityManager?: FormRecordInactivityManager;

  model: Model = new Model();

  InputMask = InputMask;

  formGroup: FormGroup;
  private fieldId?: number;
  private htmlForm?: FormRef;
  tmpReadonly: boolean = false;

  private currencies: Currency.Currency[] = [];
  private processId?: number;

  hide: () => boolean = () => true;
  private readonlyFormFn: () => boolean = () => true;
  private readonlyFieldFn: () => boolean = () => false;
  private hiddenFieldFn: () => boolean = () => false;
get nonEditable(): boolean {
    return FieldActivationStateResolver.isNonEditable(
      this.fieldActivationState
    );
  }

  private get readonlyForm(): boolean {
    return this.readonlyFormFn();
  }

  private get fieldActivationState(): FieldActivationState {
    return FieldActivationStateResolver.resolveFieldActivationState({
      readonlyFormFn: () => this.readonlyFormFn(),
      readonlyFieldFn: () => this.readonlyFieldFn(),
      tmpReadonlyFieldFn: () => this.tmpReadonly,
    });
  }

  setTmpReadonly(args: SetTmpReadonlyArgs): Command<SetTmpReadonlyResult> {
    return noOpCommand;
  }

  registerField(context: FormRecordFieldContext, originalModel?: any): any {
    if (originalModel) {
      this.model = originalModel;
    }
    this.formRecordFieldContext = context;
    this.fieldId = context.field.fieldId;
    this.htmlForm = context.htmlForm;
    this.model.title = context.field.title;
    this.readonlyFormFn = context.readonly;
    this.hiddenFieldFn = () => Form.FormFieldValidationType.HIDDEN === context.validationType;
    this.readonlyFieldFn = () => Form.FormFieldValidationType.READONLY === context.validationType
    || Form.FormFieldValidationType.HIDDEN === context.validationType;
    this.hide = context.cloning;
    if (context.fieldRecord && !this.hide()) {
      this.registerFieldData(context.fieldRecord);
    }
    if (context.other) {
      this.processId = context.other.processId!;
      this.loadOrders();
    }
    return this.model;
  }

  registerFieldData(fieldRecord: FormRecord.FieldComposed): void {
  }

  registerFieldViews(context: FormRecordFieldViewContext): void {
    this.formRecordInactivityManager = context.inactivityManager;
  }

  hasLocalFieldError(): boolean {
    return false;
  }

  validateWithInterrupt(): boolean {
    return false;
  }

  shouldNotifyAfterCreation(): boolean {
    return false;
  }

  afterFormRecordCreation(formRecordId: number): Observable<any> | undefined {
    return undefined;
  }

  loadOrders() {
    const companyIds: number[] = [];
    this.orderService.query({processId: this.processId, withItems: true}).subscribe((result: QueryResult<Order.Order>) => {
      result.items.forEach((order: Order.Order) => {
        if (order) {
          companyIds.push(order.ownerCompanyId);
          this.model.orders.push({order: order});
        }
      });
      this.loadCompanies(companyIds);
    });
  }

  loadCompanies(ids: number[]) {
    this.companyService.query({
      id: Set.of(...ids)
    }).subscribe((customers: QueryResult<Company.Company>) => {
      customers.items.forEach((c) => {
        if (c) {
          this.model.orders
            .filter(o => o.order.ownerCompanyId === c.id)
            .forEach(o => o.customerName = c.name);
        }
      });
    });
  }

  loadCurrencies() {
    this.currencyService.query({}).subscribe(result => {
      this.currencies = result.items.toArray();
    })
  }

  getCurrencyName(currencyCode: string) {
    const ret = this.currencies.find(c => c.currencyCode === currencyCode);
    return ret ? ret.localizedName : currencyCode;
  }

  createModel(): undefined {
    if (this.fieldId === undefined) {
      throw new Error('Field ID is undefined');
    }
    return undefined;
  }

  updateValue(data: FormRecordFieldValueUpdateArgs) {
  }

  constructor(private orderService: OrderService,
              private companyService: CompanyService,
              private currencyService: CurrencyService) {
    this.loadCurrencies();
  }
}

export class Model {
  title: string = '';
  orders: { order: Order.Order, customerName?: string } [] = [];
}
