/* eslint-disable */
import { AfterViewInit, Component, OnInit, ViewChild, } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Transition, UIRouter } from '@uirouter/angular';
import { combineLatest, Observable } from 'rxjs';
import { CustomerRecord, CustomerRecordService } from '../../../lib/customer/customer-record.service';
import { Customer, CustomerService } from '../../../lib/customer/customer.service';
import { CustomerRecordBase } from '../customer-record-base/customer-record-base-view';
import { CustomerRecordBaseComponent } from '../customer-record-base/customer-record-base.component';
import { FormRecordContainer } from '../../form/form-record/form-record-container';
import { FormRecordContainerComponent } from '../../form/form-record/form-record-container.component';
import { Form } from '../../../lib/form/form.service';
import { ConfigurationService, } from '../../../lib/core-ext/configuration.service';
import { QueryResult } from '../../../lib/util/services';
import { Country, CountryService, } from '../../../lib/country.service';
import { Address, AddressModel } from '../../../lib/address';
import { UserDataLoader, } from '../../../lib/user-data-loader';
import { UserGroupService, } from '../../../lib/user-group.service';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { TranslateService } from '@ngx-translate/core';
import { StateName } from '../../../app.state-names';
import { map } from 'rxjs/operators';
import { CustomerRecordHistoryComponent } from '../customer-record-history/customer-record-history.component';
import { HistoryDetailDialogComponent } from '../../history-log/history-detail-dialog/history-detail-dialog.component';
import { GrantedPermissionSetResolver, RightResolver, RightService } from '../../../lib/right.service';
import { RightModel } from '../../../app.rights';
import { ConfigModel } from '../../../util/task-record-utils';
import { Set } from 'immutable';
import { CustomerRecordContactPersonsComponent } from '../customer-record-contact-persons/customer-record-contact-persons.component';
import { CustomerRecordFieldType, CustomerRecordRightModel } from '../../../util/customer-record-utils';
import { ConfirmDialogComponent } from '../../../shared/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import {
  TaskRecordCreateDialogMode,
  TaskRecordCreateMaterialDialogComponent
} from '../../task/task-record/task-record-create-material-dialog/task-record-create-material-dialog.component';
import {
  CustomerRecordBillingInfoComponentMode
} from '../customer-record-billing-info/customer-record-billing-info-base/customer-record-billing-info-base.component';
import { OperationRights } from '../../../app.right-definitions';

/* eslint-enable */

@Component({
  selector: 'app-customer-record-detail',
  templateUrl: 'customer-record-detail.component.html',
  styleUrls: ['customer-record-detail.component.scss'],
})
export class CustomerRecordDetailComponent implements OnInit, AfterViewInit {
  CustomerRecordFieldType = CustomerRecordFieldType;
  CustomerRecordBillingInfoDialogMode = CustomerRecordBillingInfoComponentMode;

  @ViewChild(CustomerRecordBaseComponent, {static: true})
  baseView: CustomerRecordBase.View;

  @ViewChild(FormRecordContainerComponent, {static: true})
  formRecordContainerView: FormRecordContainer.View;

  @ViewChild('f', {static: true})
  fForm: NgForm;

  @ViewChild('historyDetailModal', {static: true})
  historyDetailModal: HistoryDetailDialogComponent;

  @ViewChild(CustomerRecordContactPersonsComponent)
  contactPersonsComponent: CustomerRecordContactPersonsComponent;

  @ViewChild('contactInviteToHelpdeskDialog', {static: true})
  contactInviteToHelpdeskDialog: ConfirmDialogComponent;

  customerId: number;
  customerRecordId: number;
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  combinedResult?: CombinedResult;
  rightModel: RightModel = RightModel.empty();
  grantedRights?: CustomerRecordRightModel;
  config: ConfigModel = new ConfigModel();

  contactInviteResult: {
    invited: string,
    alreadyRegistered: string,
    invalidEmail: string
  } | null = null;

  get allowEdit(): boolean {
    return this.grantedRights !== undefined && this.grantedRights.update.hasRight();
  }

  constructor(
    private userDataLoader: UserDataLoader,
    private configurationService: ConfigurationService,
    private countryService: CountryService,
    private customerService: CustomerService,
    private customerRecordService: CustomerRecordService,
    private uiRouter: UIRouter,
    private translateService: TranslateService,
    private transition: Transition,
    private rightService: RightService,
    private dialog: MatDialog,
    private configService: ConfigurationService) {
    this.customerId = this.transition.params().customerId;
    this.customerRecordId = this.transition.params().customerRecordId;
    this.config = this.configService.getConfigurationModel();
  }

  ngOnInit() {
    this.initBreadcrumb();
    this.loadRightModel();
  }

  ngAfterViewInit(): void {
    this.createCombinedObservable().subscribe(
      (result: CombinedResult) => {
        this.breadcrumbSelf = result.customerRecord.name;
        this.baseView.loadCustomerRecord({
          customerRecord: result.customerRecord,
          countryItems: AddressModel.CountryItem.fromCountryList(result.countries.items),
          customer: result.customer,
          isClone: false,
          isCreate: false
        });
        this.formRecordContainerView.loadFormRecord({
          configuration: this.configurationService.getConfiguration(),
          form: result.form,
          record: result.customerRecord.formRecord,
        });
        this.combinedResult = result;
        this.grantedRights =
          new CustomerRecordRightModel(GrantedPermissionSetResolver.byGrantedRights(result.customerRecord.grantedRights));
      }
    );
  }

  private loadRightModel() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  private initBreadcrumb() {
    this.translateService.get('COMMON_CUSTOMERS').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.CUSTOMER_DASHBOARD});
      }
    );
    if (this.customerId) {
      this.customerService.get({
        customerId: this.customerId,
      }).subscribe(
        (result: Customer.Customer) => {
          this.breadcrumbParents.push({name: result.name, uiSref: StateName.CUSTOMER_RECORD_LIST, uiParam: {customerId: this.customerId}});
        }
      )
    }
    else {
      this.translateService.get('COMMON_CUSTOMERS').subscribe(
        (result: string) => {
          this.breadcrumbParents.push({name: result, uiSref: StateName.CUSTOMER_RECORD_GLOBAL_LIST});
        }
      );
    }
  }

  nothing() {
  }

  private createCombinedObservable(): Observable<CombinedResult> {
    return combineLatest(
      this.customerService.get({
        customerId: this.customerId,
        withForm: true
      }),
      this.customerRecordService.get({
        fields: CustomerRecord.detailFields,
        withFormRecord: true,
        customerId: this.customerId,
        customerRecordId: this.customerRecordId,
        rights: Set.of(OperationRights.CUSTOMER_RECORD_ATTACHMENT_READ, OperationRights.CUSTOMER_RECORD_UPDATE)
      }),
      this.countryService.query({}),
    ).pipe(map((
        [
          customer,
          customerRecord,
          countries]) => {
        return {
          form: customer.form!,
          customer: customer,
          customerRecord: customerRecord,
          countries: countries
        }
      }
    ));
  }

  openTaskRecordQuickCreateDialog(event: any) {
    const addr = !this.baseView.getModel().postalAddress
      ? ''
      : Address.PostalAddressMapper.toString(this.baseView.getModel().postalAddress, this.config.postalAddressFormat);
    const item = {
      id: this.customerRecordId,
      text: this.baseView.getModel().fullName,
      itemName: this.baseView.getModel().fullName + ' (' + addr + ')',
      address: addr,
      customerRecord: this.combinedResult!.customerRecord,
    };
    TaskRecordCreateMaterialDialogComponent.openDialog(this.dialog, {
      mode: TaskRecordCreateDialogMode.QUICK_CREATE,
      customerRecordItem: item
    }, (result) => {
      if (result?.success) {
      }
    });
  }

  inviteToHelpdesk(ids: number[]) {
    this.customerRecordService.inviteToHelpdesk({
      customerRecordIds: Set.of(...ids)
    }).subscribe(result => {
      this.contactInviteResult = {
        invited: result.invited.toArray().map(cr => cr.name).join(', '),
        alreadyRegistered: result.alreadyRegistered.toArray().map(cr => cr.name).join(', '),
        invalidEmail: result.invalidEmail.toArray().map(cr => cr.name).join(', ')
      };
    });
  }

}

interface CombinedResult {
  form: Form.Form;
  customer: Customer.Customer;
  customerRecord: CustomerRecord.CustomerRecord;
  countries: QueryResult<Country.Country>;
}
