<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
    <div class="breadcrumb-menu d-flex">
      <div *ngIf="rightModel.ledgerNumberCreate.hasRight()"
           class="breadcrumb-button-icon-container cursor-pointer negative-margin-right"
           (click)="openLedgerNumberCreateDialog()">
        <a class="btn-setting" title="{{'LEDGER_NUMBER_CREATE' | translate}}">
          <i class="icomoon icomoon-add"></i>
          <span class="breadcrumb-button-text-visible">{{'LEDGER_NUMBER_CREATE' | translate}}</span>
        </a>
      </div>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">
  <div class="card">
    <div class="card-header">
      {{'MENU_NAVBAR_MENU_LEDGER_NUMBER_LIST' | translate}}

      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()" title="{{'COMMON_BUTTON_SEARCH' | translate}}"><i
          class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>

    <div class="card-body border-bottom" *ngIf="showSearch">
      <div class="row form-group">
        <div class="col-md-3">
          <label class="search-label">{{'COMMON_TABLE_HEADER_ID' | translate}}</label>
          <input type="number" class="form-control"
                 placeholder="{{'COMMON_TABLE_HEADER_ID' | translate}}"
                 maxlength="{{UiConstants.maxInputNumberLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.id">
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'LEDGER_NUMBER_LEDGER_NUMBER' | translate}}</label>
          <input type="text" class="form-control"
                 placeholder="{{'LEDGER_NUMBER_LEDGER_NUMBER' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.ledgerNumber">
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'COMMON_NAME' | translate}}</label>
          <input type="text" class="form-control"
                 placeholder="{{'COMMON_NAME' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.name">
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'COMMON_SEARCH_CREATION_TIME_FROM' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control date"
                   placeholder="{{'COMMON_SEARCH_CREATION_TIME_FROM' | translate}}"
                   [(ngModel)]="searchModel.creationTimeFrom" ngbDatepicker #creation_time_from="ngbDatepicker">
            <div class="input-group-addon-gray" (click)="creation_time_from.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <label class="search-label">{{'COMMON_SEARCH_CREATION_TIME_TO' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control date"
                   placeholder="{{'COMMON_SEARCH_CREATION_TIME_TO' | translate}}"
                   [(ngModel)]="searchModel.creationTimeTo" ngbDatepicker #creation_time_to="ngbDatepicker">
            <div class="input-group-addon-gray" (click)="creation_time_to.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <label class="search-label"> {{ 'COMMON_DISABLED_SEARCH' | translate }} </label>
          <select class="form-control" [(ngModel)]="searchModel.disabled"
                  id="disabled" name="disabled" #disabled="ngModel">
            <option *ngFor="let item of disabledItems" [ngValue]="item.id">{{item.text}}</option>
          </select>
        </div>
      </div>
      <div class="col-md-12 d-flex justify-content-md-end align-items-end px-0">
        <div class="btn-group" role="group">
          <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
            {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
          </button>
          <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
            {{'COMMON_BUTTON_SEARCH' | translate}}
          </button>
        </div>
      </div>
    </div><!-- /.search card-->

    <div class="card-body">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="LedgerNumberSearch.OrderFunctions.ID"
                                    [orderType]="queryModel.getOrderType(LedgerNumberSearch.OrderFunctions.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="LedgerNumberSearch.OrderFunctions.LEDGER_NUMBER"
                                    [orderType]="queryModel.getOrderType(LedgerNumberSearch.OrderFunctions.LEDGER_NUMBER)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'LEDGER_NUMBER_LEDGER_NUMBER' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-field-sorter [orderField]="LedgerNumberSearch.OrderFunctions.NAME"
                                    [orderType]="queryModel.getOrderType(LedgerNumberSearch.OrderFunctions.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-field-sorter [orderField]="LedgerNumberSearch.OrderFunctions.CREATION_TIME"
                                    [orderType]="queryModel.getOrderType(LedgerNumberSearch.OrderFunctions.CREATION_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_CREATION_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let ledgerNumber of ledgerNumberList | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }"
            [disabledTableRow]="ledgerNumber.disabled">
          <td class="fit align-middle">{{ledgerNumber.id}}</td>
          <td class="responsive-table-column align-middle">
            <div class="d-flex justify-content-start align-items-center">
              <div>
                <a class="id-text cursor-pointer"
                   href="#" onclick="return false"
                   (click)="openLedgerNumberDetailDialog(ledgerNumber.id)"
                   title="{{'COMMON_BUTTON_DETAILS' | translate}}">
                  {{ledgerNumber.ledgerNumber}}
                </a>
              </div>
            </div>
          </td>
          <td class="responsive-table-column hidden-xs-down align-middle">
            {{ledgerNumber.name}}
          </td>
          <td class="fit hidden-md-down align-middle">
              <span class="text-truncate">{{ledgerNumber.creationTime.toUtcIsoString() |
                date:'short'}}</span>
          </td>
          <td>
            <app-table-options-menu>
              <app-dropdown-item
                [iconClass]="'icomoon-detail'"
                [titleStringKey]="'COMMON_BUTTON_DETAILS'"
                class="cursor-pointer"
                (click)="openLedgerNumberDetailDialog(ledgerNumber.id)">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="ledgerNumber.rights.update.hasRight()"
                [iconClass]="'icomoon-modify'"
                [titleStringKey]="'COMMON_BUTTON_EDIT'"
                class="cursor-pointer"
                (click)="openLedgerNumberUpdateDialog(ledgerNumber.id)">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="ledgerNumber.disabled && ledgerNumber.rights.disable.hasRight()"
                [iconClass]="'icomoon-enable'"
                [titleStringKey]="'COMMON_BUTTON_ENABLE'"
                [itemType]="DropdownItemType.ENABLE"
                class="cursor-pointer"
                (click)="setDisabled(ledgerNumber.id, false)">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="!ledgerNumber.disabled && ledgerNumber.rights.disable.hasRight()"
                [iconClass]="'icomoon-disable'"
                [titleStringKey]="'COMMON_BUTTON_DISABLE'"
                [itemType]="DropdownItemType.DISABLE"
                class="cursor-pointer"
                (click)="setDisabled(ledgerNumber.id, true)">
              </app-dropdown-item>
            </app-table-options-menu>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="LedgerNumberSearch.OrderFunctions.ID"
                                    [orderType]="queryModel.getOrderType(LedgerNumberSearch.OrderFunctions.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="LedgerNumberSearch.OrderFunctions.LEDGER_NUMBER"
                                    [orderType]="queryModel.getOrderType(LedgerNumberSearch.OrderFunctions.LEDGER_NUMBER)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'LEDGER_NUMBER_LEDGER_NUMBER' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-field-sorter [orderField]="LedgerNumberSearch.OrderFunctions.NAME"
                                    [orderType]="queryModel.getOrderType(LedgerNumberSearch.OrderFunctions.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-field-sorter [orderField]="LedgerNumberSearch.OrderFunctions.CREATION_TIME"
                                    [orderType]="queryModel.getOrderType(LedgerNumberSearch.OrderFunctions.CREATION_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_CREATION_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>

  </div>

</div>

