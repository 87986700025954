/* eslint-disable */
import { OffsetDateTime } from '../util/dates';
import {List, Map, Set} from 'immutable';
import {Order, PagingRequest, Services} from '../util/services';
import {Transport} from "../transport/transport.service";
/* eslint-enable */

export namespace Chat {

  export interface MessageQueryRequest {
    id: number;
    parentId?: number;
    content?: string;
    userProfileId?: number;
    creationTimeFrom?: OffsetDateTime;
    creationTimeTo?: OffsetDateTime;

    order?: Set<Order<OrderField>>;
    paging?: PagingRequest;
  }

  export interface Message {
    id: number;
    creationTime: OffsetDateTime;
    content: string;
    userProfileId: number;
    userName: string;
    userPersonName: string;
  }

  export interface SendMessageRequest {
    id: number;
    parentId?: number;
    content: string;
  }

  export enum OrderField {
    CREATION_TIME
  }

  export class Keys {

    private static readonly CREATION_TIME = 'creation_time';

    private static readonly orderFieldKeyMap: Map<Chat.OrderField, string> = Map.of(
      Chat.OrderField.CREATION_TIME, Keys.CREATION_TIME,
    );
    public static toOrderFieldKey(field: Chat.OrderField): string {
      return Keys.orderFieldKeyMap.get(field)!;
    }
  }

  export class Mapper {

    public static toPublicMessageList(resourceList: ChatResource.Message[]): List<Chat.Message> {
      return List.of(...resourceList.map((r) => this.toPublicMessage(r)));
    }

    public static toPublicMessage(r: ChatResource.Message): Chat.Message {
      return {
        id: r.id,
        content: r.content,
        creationTime: Services.toOffsetDateTime(r.creation_time),
        userProfileId: r.user_profile_id,
        userName: r.user_name,
        userPersonName: r.user_person_name
      };
    }

  }

}

export namespace ChatResource {

  export interface MessageQueryRequest {
    content?: string;
    user_profile_id?: number;
    creation_time_from?: string;
    creation_time_to?: string;

    order?: string;
    page_number?: number;
    number_of_items?: number;
  }

  export interface Message {
    id: number;
    creation_time: string;
    content: string;
    user_profile_id: number;
    user_name: string;
    user_person_name: string;
  }


  export interface SendMessageRequest {
    content: string;
  }

}
