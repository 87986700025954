/* eslint-disable */
import { Component, OnInit } from '@angular/core';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { TranslateService } from '@ngx-translate/core';
import { User, UserService } from '../../../lib/user.service';
import { Stock, StockService } from '../../../lib/stock/stock.service';
import { StockTakings, StockTakingsService } from '../../../lib/stock-takings/stock-takings.service';
import { StateName } from '../../../app.state-names';
import { DownloadedFile } from '../../../lib/util/downloaded-files';
import { saveAs } from 'file-saver';
import { Transition } from '@uirouter/angular';
import { OffsetDateTime } from '../../../lib/util/dates';
import { ResourceQueryResult } from '../../../lib/util/services';
import { MultiselectOptionItem } from '../../../util/core-utils';
import { Angular2Multiselects } from '../../../util/multiselect';
import { RightResolver, RightService } from '../../../lib/right.service';
import { RightModel } from '../../../app.rights';

/* eslint-enable */

@Component({
  selector: 'app-stock-takings-detail',
  templateUrl: './stock-takings-detail.component.html',
  styleUrls: ['./stock-takings-detail.component.scss']
})
export class StockTakingsDetailComponent implements OnInit {

  stockTakingId: number;
  model: StockTakingDetailModel = new StockTakingDetailModel();
  rightModel: RightModel = RightModel.empty();

  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  dropdownSettings: Angular2Multiselects.Settings;

  constructor(
    private transition: Transition,
    private translateService: TranslateService,
    private userService: UserService,
    private stockService: StockService,
    private stockTakingsService: StockTakingsService,
    private rightService: RightService) {
    this.stockTakingId = this.transition.params().id;
  }

  ngOnInit() {
    this.initDropdownSettings();
    this.loadRightModels();
    this.translateService.get('MENU_NAVBAR_STOCK_TAKINGS').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.STOCK_TAKINGS_LIST});
      }
    );
    this.loadModel();
  }

  initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  private loadModel() {
    this.stockTakingsService.get({
      stockTakingId: this.stockTakingId
    }).subscribe((result: StockTakings.StockTaking) => {
      this.breadcrumbSelf = result.name;
      this.model.name = result.name;
      this.model.trxId = result.trxId;
      this.loadOwnerUser(result.ownerUserId);
      this.model.creationTime = result.creationTime;
      this.loadStocks(result.stockIds);
      this.model.pdfTemplate = !!result.pdfTemplate;
    });
  }

  private loadOwnerUser(id: number) {
    this.userService.get({
      id: id
    }).subscribe((result: User) => {
      this.model.ownerUser.push({
        id: result.id,
        itemName: result.person_name,
        disabled: result.disabled
      });
    });
  }

  private loadStocks(ids: number[]) {
    if (ids.length > 0) {
      this.stockService.query({
        fields: ['id', 'name', 'disabled'].join(','),
        id: ids.join(',')
      }).subscribe((result: ResourceQueryResult<Stock>) => {
        result.items.forEach((s) => {
          this.model.stocks.push({
            id: s.id,
            itemName: s.name,
            disabled: s.disabled
          });
        });
      });
    }
  }

  downloadTemplate() {
    this.stockTakingsService.downloadTemplate(this.stockTakingId).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName('stock-taking-' + this.stockTakingId + '-pdf-template.fodt'));
      });
  }

}

class StockTakingDetailModel {
  name: string = '';
  trxId: string = '';
  ownerUser: MultiselectOptionItem<number>[] = [];
  creationTime?: OffsetDateTime = undefined;
  stocks: MultiselectOptionItem<number>[] = [];
  pdfTemplate: boolean = false;
}
