<div class="d-flex align-items-center" *ngIf="_user || _userGroup || _device">
    <div class="picture-container" *ngIf="AssigneeType.USER && _user">
      <app-user-profile-picture
        [userPersonName]="personName"
        [profilePicture]="profilePicture ? profilePicture.profilePicture : undefined"
        [size]="36">
      </app-user-profile-picture>
    </div>
    <div class="picture-container" *ngIf="AssigneeType.MOBILE && _device">
      <div class="icon-background rounded-circle"></div>
      <i class="icon-centered icomoon icomoon-mobile"></i>
    </div>
    <div class="picture-container" *ngIf="((AssigneeType.MOBILE && !_device) || (AssigneeType.USER && !_user)) && _userGroup">
      <div class="icon-background rounded-circle"></div>
      <i class="icon-centered icomoon icomoon-administration-user-management-alt"></i>
    </div>
    <div class="text-break">
      <a uiSref="#" class="cursor-pointer id-text" (click)="navigateToAssigneeDetail()" *ngIf="!helpdeskMode"
         [popover]="_user ? assigneeUserPopover : undefined" triggers="mouseenter:mouseleave" container="body">
        {{cellTitle}}
      </a>
      <div class="id-text" *ngIf="helpdeskMode">
        {{cellTitle}}
      </div>
      <div class="table-text-subtitle" *ngIf="!helpdeskMode || !_user">{{cellSubtitle}}</div>
    </div>
</div>

<ng-template #assigneeUserPopover>
  <div class="d-flex" *ngIf="_user || _userGroup || _device"
       [popover]="_user ? assigneeUserPopover : undefined" triggers="mouseenter:mouseleave" container="body">
    <div class="popover-picture-container" *ngIf="AssigneeType.USER && _user">
      <app-user-profile-picture
        [userPersonName]="personName"
        [profilePicture]="profilePicture ? profilePicture.profilePicture : undefined"
        [size]="70">
      </app-user-profile-picture>
    </div>
    <div class="d-flex justify-content-between">
      <div class="text-break">
        <h6 class="mb-0">{{cellTitle}}</h6>
        <div class="table-text-subtitle">{{cellSubtitle}}</div>
        <ng-container *ngIf="userGroups.length > 0">
          <div class="table-text-subtitle mt-1">{{'MENU_NAVBAR_MENU_USER_GROUPS' | translate}}</div>
          <div *ngFor="let userGroup of getUserGroups()">
            <span>{{userGroup.itemName}}</span>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>
