/* eslint-disable */
import { List, Map, Set } from 'immutable';
import { ComponentFactoryResolver, ComponentRef, Injectable, Injector, } from '@angular/core';
import {Form} from '../../lib/form/form.service';
import { FormRef, OrderType, Services, } from '../../lib/util/services';
import { FormEditUnknownFieldDialogViewFactory } from './form-utils.unknown';
import { FormEditStringFieldDialogViewFactory } from './form-utils.string';
import { FormEditNumberFieldDialogViewFactory } from './form-utils.number';
import { FormEditPhoneNumberFieldDialogViewFactory } from './form-utils.phonenumber';
import { FormEditDecimalFieldDialogViewFactory } from './form-utils.decimal';
import { FormEditDateFieldDialogViewFactory } from './form-utils.date';
import { FormEditBooleanFieldDialogViewFactory } from './form-utils.boolean';
import { OffsetDateTime } from '../../lib/util/dates';
import { FormServiceCode } from '../../lib/form/form-service-factory';
import { FormRecordUnknownFieldViewFactory } from './form-utils-field.unknown';
import { FormRecord } from '../../lib/form/form-record.service';
import { FormRecordStringFieldViewFactory } from './form-utils-field.string';
import { ConfigurationResource } from '../../lib/core-ext/configuration.service';
import { Command, CommandManager, } from '../command';
import { FormRecordNumberFieldViewFactory } from './form-utils-field.number';
import { FormRecordDecimalFieldViewFactory } from './form-utils-field.decimal';
import { FormRecordBooleanFieldViewFactory } from './form-utils-field.boolean';
import { FormRecordEmailAddressFieldViewFactory } from './form-utils-field.emailaddress';
import { FormRecordPhoneNumberFieldViewFactory } from './form-utils-field.phonenumber';
import { FormRecordFormTableFieldViewFactory } from './form-utils-field.form-table';
import { FormRecordReadonlyTextFieldViewFactory } from './form-utils-field.readonly-text';
import { FormRecordReadonlyHtmlFieldViewFactory } from './form-utils-field.readonly-html';
import { FormRecordListItemFieldViewFactory } from './form-utils-field.list-item';
import { FormRecordMultiItemFieldViewFactory } from './form-utils-field.multi-item';
import { FormEditDateTimeFieldDialogViewFactory } from './form-utils.datetime';
import { FormRecordDateFieldViewFactory } from './form-utils-field.date';
import { FormEditMasterDataFieldDialogViewFactory } from './form-utils.masterdata';
import { FormRecordMasterDataFieldViewFactory } from './form-utils-field.master-data';
import { CommandResultStore } from '../../admin/form/form-record/command-result-store';
import { FormRecordDateTimeFieldViewFactory } from './form-utils-field.datetime';
import { Right } from '../../lib/auth.service';
import { FormRecordStockFieldViewFactory } from './form-utils-field.stock';
import { FormEditPaymentTypeFieldDialogViewFactory } from './form-utils.payment-type';
import { FormRecordPaymentTypeFieldViewFactory } from './form-utils-field.payment-type';
import { FormEditCustomerFieldDialogViewFactory } from './form-utils.customer';
import { FormRecordCustomerFieldViewFactory } from './form-utils-field.customer';
import { FormEditDocumentFieldDialogViewFactory } from './form-utils.document';
import { FormRecordDocumentFieldViewFactory } from './form-utils-field.document';
import { FormEditProcessOrderStockOuttakeFieldDialogViewFactory } from './form-utils.process-order-stock-outtake';
import { FormEditProcessOrderStockOuttakeCheckFieldDialogViewFactory } from './form-utils.process-order-stock-outtake-check';
import { FormEditProcessOrderPackagingFieldDialogViewFactory } from './form-utils.process-order-packaging';
import { FormRecordProcessOrderStockOuttakeCheckFieldViewFactory } from './form-utils-field.process-order-stock-outtake-check';
import { FormRecordProcessOrderPackagingFieldViewFactory } from './form-utils-field.process-order-packaging';
import { FormEditReadonlyPictureFieldDialogViewFactory } from './form-utils.readonly-picture';
import { FormRecordReadonlyPictureFieldViewFactory } from './form-utils-field.readonly-picture';
import { FormRecordInactivityManager } from '../../admin/form/form-record/manager/form-record-inactivity-manager';
import { FormEditListItemFieldDialogViewFactory } from './form-utils.list-item';
import { FormEditListMultiItemFieldDialogViewFactory } from './form-utils.list-multi-item';
import { FormEditEmailAddressFieldDialogViewFactory } from './form-utils.emailaddress';
import { FormEditReadonlyTextFieldDialogViewFactory } from './form-utils.readonly-text';
import { FormEditReadonlyHtmlFieldDialogViewFactory } from './form-utils.readonly-html';
import { FormEditStockFieldDialogViewFactory } from './form-utils.stock';
import { FormEditFormTableFieldDialogViewFactory } from './form-utils.form-table';
import { FormRecordProcessOrderStockOuttakeFieldViewFactory } from './form-utils-field.process-order-stock-outtake';
import { FormRecordInvoiceFieldViewFactory } from './form-utils-field.invoice';
import { FormEditInvoiceFieldDialogViewFactory } from './form-utils.invoice';
import { FormEditUserFieldDialogViewFactory } from './form-utils.user';
import { FormRecordUserFieldViewFactory } from './form-utils-field.user';
import { Models } from '../model-utils';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Observable, ReplaySubject } from 'rxjs';
import { UserMultiselectProvider } from '../../lib/user/user-multiselect.provider';
import { Arrays } from '../../lib/util/arrays';
import { FormEditStockIntakeFieldDialogViewFactory } from './form-utils.stock-intake';
import { FormRecordStockIntakeFieldViewFactory } from './form-utils-field.stock-intake';
import { FormEditStockMoveFieldDialogViewFactory } from './form-utils.stock-move';
import { FormRecordStockMoveFieldViewFactory } from './form-utils-field.stock-move';
import { FormEditPhotoFieldDialogViewFactory } from './form-utils.photo';
import { FormRecordPhotoFieldViewFactory } from './form-utils-field.photo';
import { ListItemMapService } from '../../lib/list-item/list-item-map.service';
import { MasterDataRecordMultiselectProvider } from '../../lib/masterdata/masterdata-record-multiselect-provider.service';
import { map } from 'rxjs/operators';
import { CustomerRecord, CustomerRecordService } from '../../lib/customer/customer-record.service';
import FieldDataTypeSelector = Form.FieldDataTypeSelector;
import { DqlModel } from '../../lib/dql/dql.model';
import { MasterDataRecord, MasterDataRecordService } from '../../lib/masterdata/master-data-record.service';
import { UserService } from '../../lib/user.service';

/* eslint-enable */

/**
 * UI layer utils.
 * This is the only place where any item of FieldDataTypeSelector is used.
 */
@Injectable()
export class FieldDataTypeSelectors {

  private static readonly formFieldNameKeys: Map<Form.FieldDataTypeSelector, string> = Map.of(
    Form.FieldDataTypeSelector.STRING, 'FORM_ITEM_LIST_STRING_TYPE',
    Form.FieldDataTypeSelector.NUMBER, 'FORM_ITEM_LIST_NUMBER_TYPE',
    Form.FieldDataTypeSelector.DECIMAL, 'FORM_ITEM_LIST_DECIMAL_TYPE',
    Form.FieldDataTypeSelector.DATE, 'FORM_ITEM_LIST_DATE_TYPE',
    Form.FieldDataTypeSelector.DATE_TIME, 'FORM_ITEM_LIST_DATE_TIME_TYPE',
    Form.FieldDataTypeSelector.BOOLEAN, 'FORM_ITEM_LIST_BOOLEAN_TYPE',
    Form.FieldDataTypeSelector.LIST_ITEM, 'FORM_ITEM_LIST_LIST_ITEM_TYPE',
    Form.FieldDataTypeSelector.LIST_MULTI_ITEM, 'FORM_ITEM_LIST_LIST_MULTI_ITEM_TYPE',
    Form.FieldDataTypeSelector.EMAIL_ADDRESS, 'FORM_ITEM_LIST_EMAIL_ADDRESS_TYPE',
    Form.FieldDataTypeSelector.PHONE_NUMBER, 'FORM_ITEM_LIST_PHONE_NUMBER_TYPE',
    Form.FieldDataTypeSelector.MASTER_DATA, 'FORM_ITEM_LIST_MASTER_DATA_TYPE',
    Form.FieldDataTypeSelector.READONLY_TEXT, 'FORM_ITEM_LIST_READONLY_TEXT_TYPE',
    Form.FieldDataTypeSelector.READONLY_HTML, 'FORM_ITEM_LIST_READONLY_HTML_TYPE',
    Form.FieldDataTypeSelector.STOCK, 'FORM_ITEM_LIST_STOCK_TYPE',
    Form.FieldDataTypeSelector.STOCK_INTAKE, 'FORM_ITEM_LIST_STOCK_INTAKE_TYPE',
    Form.FieldDataTypeSelector.STOCK_MOVE, 'FORM_ITEM_LIST_STOCK_MOVE_TYPE',
    Form.FieldDataTypeSelector.FORM_TABLE, 'FORM_ITEM_LIST_FORM_TABLE_TYPE',
    Form.FieldDataTypeSelector.PAYMENT_TYPE, 'FORM_ITEM_LIST_PAYMENT_TYPE_TYPE',
    Form.FieldDataTypeSelector.CUSTOMER, 'FORM_ITEM_LIST_CUSTOMER_TYPE',
    Form.FieldDataTypeSelector.USER, 'FORM_ITEM_LIST_USER_TYPE',
    Form.FieldDataTypeSelector.DOCUMENT, 'FORM_ITEM_LIST_DOCUMENT_TYPE',
    Form.FieldDataTypeSelector.PHOTO, 'FORM_ITEM_LIST_PHOTO_TYPE',
    Form.FieldDataTypeSelector.PROCESS_ORDER_STOCK_OUTTAKE, 'FORM_ITEM_LIST_PROCESS_ORDER_STOCK_OUTTAKE_TYPE',
    Form.FieldDataTypeSelector.PROCESS_ORDER_STOCK_OUTTAKE_CHECK, 'FORM_ITEM_LIST_PROCESS_ORDER_STOCK_OUTTAKE_CHECK_TYPE',
    Form.FieldDataTypeSelector.PROCESS_ORDER_PACKAGING, 'FORM_ITEM_LIST_PROCESS_ORDER_PACKAGING_TYPE',
    Form.FieldDataTypeSelector.READONLY_PICTURE, 'FORM_ITEM_LIST_READONLY_PICTURE_TYPE',
    Form.FieldDataTypeSelector.INVOICE, 'FORM_ITEM_LIST_INVOICE_TYPE',
  );

  public static getFormFieldNameKey(selector: Form.FieldDataTypeSelector): string {
    return this.formFieldNameKeys.get(selector);
  }

  public readonly descriptors: List<FieldDataTypeDescriptor>;

  constructor(private injector: Injector, private cfr: ComponentFactoryResolver) {
    this.descriptors = List.of<FieldDataTypeDescriptor>(
      {
        selector: Form.FieldDataTypeSelector.UNKNOWN,
        formFieldDialogFactory: new FormEditUnknownFieldDialogViewFactory(
          Form.FieldDataTypeSelector.UNKNOWN, injector, cfr
        ),
        formRecordFieldViewFactory: new FormRecordUnknownFieldViewFactory(
          Form.FieldDataTypeSelector.UNKNOWN, injector, cfr
        ),
        formFieldNameDictionaryKey: '',
        validInServices: Set.of<FormServiceCode>(),
        canBeInactivated: true,
      },
      {
        selector: Form.FieldDataTypeSelector.STRING,
        formFieldDialogFactory: new FormEditStringFieldDialogViewFactory(
          injector, cfr
        ),
        formRecordFieldViewFactory: new FormRecordStringFieldViewFactory(
          injector, cfr
        ),
        formFieldNameDictionaryKey: 'FORM_ITEM_LIST_STRING_TYPE',
        validInServices: undefined,
        canBeInactivated: true,
      },
      {
        selector: Form.FieldDataTypeSelector.NUMBER,
        formFieldDialogFactory: new FormEditNumberFieldDialogViewFactory(
          injector, cfr
        ),
        formRecordFieldViewFactory: new FormRecordNumberFieldViewFactory(
          injector, cfr
        ),
        formFieldNameDictionaryKey: 'FORM_ITEM_LIST_NUMBER_TYPE',
        validInServices: undefined,
        canBeInactivated: true,
      },
      {
        selector: Form.FieldDataTypeSelector.DECIMAL,
        formFieldDialogFactory: new FormEditDecimalFieldDialogViewFactory(
          injector, cfr
        ),
        formRecordFieldViewFactory: new FormRecordDecimalFieldViewFactory(
          injector, cfr
        ),
        formFieldNameDictionaryKey: 'FORM_ITEM_LIST_DECIMAL_TYPE',
        validInServices: undefined,
        canBeInactivated: true,
      },
      {
        selector: Form.FieldDataTypeSelector.DATE,
        formFieldDialogFactory: new FormEditDateFieldDialogViewFactory(
          injector, cfr
        ),
        formRecordFieldViewFactory: new FormRecordDateFieldViewFactory(
          injector, cfr
        ),
        formFieldNameDictionaryKey: 'FORM_ITEM_LIST_DATE_TYPE',
        validInServices: undefined,
        canBeInactivated: true,
      },
      {
        selector: Form.FieldDataTypeSelector.DATE_TIME,
        formFieldDialogFactory: new FormEditDateTimeFieldDialogViewFactory(
          injector, cfr
        ),
        formRecordFieldViewFactory: new FormRecordDateTimeFieldViewFactory(
          injector, cfr
        ),
        formFieldNameDictionaryKey: 'FORM_ITEM_LIST_DATE_TIME_TYPE',
        validInServices: undefined,
        canBeInactivated: true,
      },
      {
        selector: Form.FieldDataTypeSelector.BOOLEAN,
        formFieldDialogFactory: new FormEditBooleanFieldDialogViewFactory(
          this, injector, cfr
        ),
        formRecordFieldViewFactory: new FormRecordBooleanFieldViewFactory(
          injector, cfr
        ),
        formFieldNameDictionaryKey: 'FORM_ITEM_LIST_BOOLEAN_TYPE',
        validInServices: undefined,
        canBeInactivated: true,
      },
      {
        selector: Form.FieldDataTypeSelector.LIST_ITEM,
        formFieldDialogFactory: new FormEditListItemFieldDialogViewFactory(
          injector, cfr
        ),
        formRecordFieldViewFactory: new FormRecordListItemFieldViewFactory(
          injector, cfr
        ),
        formFieldNameDictionaryKey: 'FORM_ITEM_LIST_LIST_ITEM_TYPE',
        validInServices: undefined,
        canBeInactivated: true,
      },
      {
        selector: Form.FieldDataTypeSelector.LIST_MULTI_ITEM,
        formFieldDialogFactory: new FormEditListMultiItemFieldDialogViewFactory(
          injector, cfr
        ),
        formRecordFieldViewFactory: new FormRecordMultiItemFieldViewFactory(
          injector, cfr
        ),
        formFieldNameDictionaryKey: 'FORM_ITEM_LIST_LIST_MULTI_ITEM_TYPE',
        validInServices: undefined,
        canBeInactivated: true,
      },
      {
        selector: Form.FieldDataTypeSelector.EMAIL_ADDRESS,
        formFieldDialogFactory: new FormEditEmailAddressFieldDialogViewFactory(
          injector, cfr
        ),
        formRecordFieldViewFactory: new FormRecordEmailAddressFieldViewFactory(
          injector, cfr
        ),
        formFieldNameDictionaryKey: 'FORM_ITEM_LIST_EMAIL_ADDRESS_TYPE',
        validInServices: Set.of<FormServiceCode>(
          FormServiceCode.TASK,
          FormServiceCode.MASTER_DATA,
          FormServiceCode.SURVEY,
          FormServiceCode.FORM_TABLE,
          FormServiceCode.PROJECT,
        ),
        canBeInactivated: true,
      },
      {
        selector: Form.FieldDataTypeSelector.PHONE_NUMBER,
        formFieldDialogFactory: new FormEditPhoneNumberFieldDialogViewFactory(
          injector, cfr
        ),
        formRecordFieldViewFactory: new FormRecordPhoneNumberFieldViewFactory(
          injector, cfr
        ),
        formFieldNameDictionaryKey: 'FORM_ITEM_LIST_PHONE_NUMBER_TYPE',
        validInServices: Set.of<FormServiceCode>(
          FormServiceCode.TASK,
          FormServiceCode.MASTER_DATA,
          FormServiceCode.SURVEY,
          FormServiceCode.FORM_TABLE,
          FormServiceCode.PROJECT,
        ),
        canBeInactivated: true,
      },
      {
        selector: Form.FieldDataTypeSelector.MASTER_DATA,
        formFieldDialogFactory: new FormEditMasterDataFieldDialogViewFactory(
          injector, cfr
        ),
        formRecordFieldViewFactory: new FormRecordMasterDataFieldViewFactory(
          injector, cfr
        ),
        formFieldNameDictionaryKey: 'FORM_ITEM_LIST_MASTER_DATA_TYPE',
        validInServices: Set.of<FormServiceCode>(
          FormServiceCode.TASK,
          FormServiceCode.SURVEY,
          FormServiceCode.FORM_TABLE,
          FormServiceCode.PROJECT,
        ),
        canBeInactivated: true,
      },
      {
        selector: Form.FieldDataTypeSelector.READONLY_TEXT,
        formFieldDialogFactory: new FormEditReadonlyTextFieldDialogViewFactory(
          injector, cfr
        ),
        formRecordFieldViewFactory: new FormRecordReadonlyTextFieldViewFactory(
          injector, cfr
        ),
        formFieldNameDictionaryKey: 'FORM_ITEM_LIST_READONLY_TEXT_TYPE',
        validInServices: Set.of<FormServiceCode>(
          FormServiceCode.TASK,
          FormServiceCode.MASTER_DATA,
          FormServiceCode.SURVEY,
          FormServiceCode.FORM_TABLE,
          FormServiceCode.PROJECT,
        ),
        canBeInactivated: true, // readonly, always has a value
      },
      {
        selector: Form.FieldDataTypeSelector.READONLY_HTML,
        formFieldDialogFactory: new FormEditReadonlyHtmlFieldDialogViewFactory(
          injector, cfr
        ),
        formRecordFieldViewFactory: new FormRecordReadonlyHtmlFieldViewFactory(
          injector, cfr
        ),
        formFieldNameDictionaryKey: 'FORM_ITEM_LIST_READONLY_HTML_TYPE',
        validInServices: Set.of<FormServiceCode>(
          FormServiceCode.TASK,
          FormServiceCode.MASTER_DATA,
          FormServiceCode.SURVEY,
          FormServiceCode.FORM_TABLE,
          FormServiceCode.PROJECT,
        ),
        canBeInactivated: true, // readonly, always has a value
      },
      {
        selector: Form.FieldDataTypeSelector.STOCK,
        formFieldDialogFactory: new FormEditStockFieldDialogViewFactory(
          injector, cfr
        ),
        formRecordFieldViewFactory: new FormRecordStockFieldViewFactory(
          injector, cfr
        ),
        formFieldNameDictionaryKey: 'FORM_ITEM_LIST_STOCK_TYPE',
        validInServices: Set.of<FormServiceCode>(FormServiceCode.TASK),
        canBeInactivated: true,
      },
      {
        selector: Form.FieldDataTypeSelector.STOCK_INTAKE,
        formFieldDialogFactory: new FormEditStockIntakeFieldDialogViewFactory(
          injector, cfr
        ),
        formRecordFieldViewFactory: new FormRecordStockIntakeFieldViewFactory(
          injector, cfr
        ),
        formFieldNameDictionaryKey: 'FORM_ITEM_LIST_STOCK_INTAKE_TYPE',
        validInServices: Set.of<FormServiceCode>(FormServiceCode.TASK),
        canBeInactivated: true,
      },
      {
        selector: Form.FieldDataTypeSelector.STOCK_MOVE,
        formFieldDialogFactory: new FormEditStockMoveFieldDialogViewFactory(
          injector, cfr
        ),
        formRecordFieldViewFactory: new FormRecordStockMoveFieldViewFactory(
          injector, cfr
        ),
        formFieldNameDictionaryKey: 'FORM_ITEM_LIST_STOCK_MOVE_TYPE',
        validInServices: Set.of<FormServiceCode>(FormServiceCode.TASK),
        canBeInactivated: true,
      },
      {
        selector: Form.FieldDataTypeSelector.FORM_TABLE,
        formFieldDialogFactory: new FormEditFormTableFieldDialogViewFactory(
          injector, cfr
        ),
        formRecordFieldViewFactory: new FormRecordFormTableFieldViewFactory(
          this, injector, cfr
        ),
        formFieldNameDictionaryKey: 'FORM_ITEM_LIST_FORM_TABLE_TYPE',
        validInServices: Set.of<FormServiceCode>(
          FormServiceCode.TASK,
          FormServiceCode.SURVEY,
          FormServiceCode.PROJECT,
        ),
        canBeInactivated: false, // not implemented yet (undo support is missing)
      },
      {
        selector: Form.FieldDataTypeSelector.PAYMENT_TYPE,
        formFieldDialogFactory: new FormEditPaymentTypeFieldDialogViewFactory(
          injector, cfr
        ),
        formRecordFieldViewFactory: new FormRecordPaymentTypeFieldViewFactory(
          injector, cfr
        ),
        formFieldNameDictionaryKey: 'FORM_ITEM_LIST_PAYMENT_TYPE_TYPE',
        validInServices: Set.of<FormServiceCode>(FormServiceCode.TASK),
        canBeInactivated: true,
      },
      {
        selector: Form.FieldDataTypeSelector.CUSTOMER,
        formFieldDialogFactory: new FormEditCustomerFieldDialogViewFactory(
          injector, cfr
        ),
        formRecordFieldViewFactory: new FormRecordCustomerFieldViewFactory(
          injector, cfr
        ),
        formFieldNameDictionaryKey: 'FORM_ITEM_LIST_CUSTOMER_TYPE',
        validInServices: Set.of<FormServiceCode>(
          FormServiceCode.TASK,
          FormServiceCode.MASTER_DATA,
          FormServiceCode.SURVEY,
          FormServiceCode.PROJECT,
        ),
        canBeInactivated: true,
      },
      {
        selector: Form.FieldDataTypeSelector.USER,
        formFieldDialogFactory: new FormEditUserFieldDialogViewFactory(
          injector, cfr
        ),
        formRecordFieldViewFactory: new FormRecordUserFieldViewFactory(
          injector, cfr
        ),
        formFieldNameDictionaryKey: 'FORM_ITEM_LIST_USER_TYPE',
        validInServices: Set.of<FormServiceCode>(
          FormServiceCode.TASK,
          FormServiceCode.MASTER_DATA,
          FormServiceCode.SURVEY,
          FormServiceCode.FORM_TABLE,
          FormServiceCode.PROJECT,
        ),
        canBeInactivated: true,
      },
      {
        selector: Form.FieldDataTypeSelector.DOCUMENT,
        formFieldDialogFactory: new FormEditDocumentFieldDialogViewFactory(
          injector, cfr
        ),
        formRecordFieldViewFactory: new FormRecordDocumentFieldViewFactory(
          injector, cfr
        ),
        formFieldNameDictionaryKey: 'FORM_ITEM_LIST_DOCUMENT_TYPE',
        validInServices: Set.of<FormServiceCode>(
          FormServiceCode.TASK,
          FormServiceCode.PROJECT,
        ),
        canBeInactivated: true,
      },
      {
        selector: Form.FieldDataTypeSelector.PHOTO,
        formFieldDialogFactory: new FormEditPhotoFieldDialogViewFactory(
          injector, cfr
        ),
        formRecordFieldViewFactory: new FormRecordPhotoFieldViewFactory(
          injector, cfr
        ),
        formFieldNameDictionaryKey: 'FORM_ITEM_LIST_PHOTO_TYPE',
        validInServices: Set.of<FormServiceCode>(
          FormServiceCode.TASK,
          FormServiceCode.PROJECT,
        ),
        canBeInactivated: true,
      },
      {
        selector: Form.FieldDataTypeSelector.PROCESS_ORDER_STOCK_OUTTAKE,
        formFieldDialogFactory: new FormEditProcessOrderStockOuttakeFieldDialogViewFactory(
          injector, cfr
        ),
        formRecordFieldViewFactory: new FormRecordProcessOrderStockOuttakeFieldViewFactory(
          injector, cfr
        ),
        formFieldNameDictionaryKey: 'FORM_ITEM_LIST_PROCESS_ORDER_STOCK_OUTTAKE_TYPE',
        validInServices: Set.of<FormServiceCode>(
          FormServiceCode.WORKFLOW_TASK
        ),
        canBeInactivated: false, // not implemented yet
      },
      {
        selector: Form.FieldDataTypeSelector.PROCESS_ORDER_STOCK_OUTTAKE_CHECK,
        formFieldDialogFactory: new FormEditProcessOrderStockOuttakeCheckFieldDialogViewFactory(
          injector, cfr
        ),
        formRecordFieldViewFactory: new FormRecordProcessOrderStockOuttakeCheckFieldViewFactory(
          injector, cfr
        ),
        formFieldNameDictionaryKey: 'FORM_ITEM_LIST_PROCESS_ORDER_STOCK_OUTTAKE_CHECK_TYPE',
        validInServices: Set.of<FormServiceCode>(
          FormServiceCode.WORKFLOW_TASK
        ),
        canBeInactivated: true,
      },
      {
        selector: Form.FieldDataTypeSelector.PROCESS_ORDER_PACKAGING,
        formFieldDialogFactory: new FormEditProcessOrderPackagingFieldDialogViewFactory(
          injector, cfr
        ),
        formRecordFieldViewFactory: new FormRecordProcessOrderPackagingFieldViewFactory(
          injector, cfr
        ),
        formFieldNameDictionaryKey: 'FORM_ITEM_LIST_PROCESS_ORDER_PACKAGING_TYPE',
        validInServices: Set.of<FormServiceCode>(
          FormServiceCode.WORKFLOW_TASK
        ),
        canBeInactivated: true,
      },
      {
        selector: Form.FieldDataTypeSelector.READONLY_PICTURE,
        formFieldDialogFactory: new FormEditReadonlyPictureFieldDialogViewFactory(
          injector, cfr
        ),
        formRecordFieldViewFactory: new FormRecordReadonlyPictureFieldViewFactory(
          injector, cfr
        ),
        formFieldNameDictionaryKey: 'FORM_ITEM_LIST_READONLY_PICTURE_TYPE',
        validInServices: Set.of<FormServiceCode>(
          FormServiceCode.TASK,
          FormServiceCode.MASTER_DATA,
          FormServiceCode.SURVEY,
          FormServiceCode.FORM_TABLE,
          FormServiceCode.PROJECT,
        ),
        canBeInactivated: true,
      },
      {
        selector: Form.FieldDataTypeSelector.INVOICE,
        formFieldDialogFactory: new FormEditInvoiceFieldDialogViewFactory(
          injector, cfr
        ),
        formRecordFieldViewFactory: new FormRecordInvoiceFieldViewFactory(
          injector, cfr
        ),
        formFieldNameDictionaryKey: 'FORM_ITEM_LIST_INVOICE_TYPE',
        validInServices: Set.of<FormServiceCode>(FormServiceCode.TASK),
        canBeInactivated: true,
      },
    );
  }

  public get descriptorArray(): FieldDataTypeDescriptor[] {
    return this.descriptors.toArray();
  }

  public findDescriptor(selector: Form.FieldDataTypeSelector): FieldDataTypeDescriptor {
    const d = this.descriptors.toArray()
      .filter((descriptor) => {
        return selector === descriptor.selector;
      })[0];
    if (d === undefined || d === null) {
      throw new Error('Descriptor has not found. Incomplete descriptor list. Programming error.');
    }
    return d;
  }

  public isValidInService(descriptor: FieldDataTypeDescriptor, serviceCode: FormServiceCode) {
    if (descriptor.validInServices === undefined) {
      return true;
    }
    return descriptor.validInServices.contains(serviceCode);
  }

  public static isActivatorField(field: Form.Field): boolean {
    switch (field.dataTypeSelector) {
      case Form.FieldDataTypeSelector.BOOLEAN:
        return field.dataType.booleanAttributes!.enableOtherFields;
      case Form.FieldDataTypeSelector.LIST_ITEM:
        return field.dataType.listItemAttributes!.fieldActivationEnabled;
      case Form.FieldDataTypeSelector.LIST_MULTI_ITEM:
        return field.dataType.listMultiItemAttributes!.fieldActivationEnabled;
      default:
        return false;
    }
  }

  public static getEnabledByThisFieldIds(field: Form.Field): number[] {
    switch (field.dataTypeSelector) {
      case Form.FieldDataTypeSelector.BOOLEAN:
        return field.dataType.booleanAttributes!.fieldIdsToBeEnabled.toArray();
      case Form.FieldDataTypeSelector.LIST_ITEM:
        return Arrays.flatten(field.dataType.listItemAttributes!.activatedFieldIdsByItemId.toArray().map(s => s.toArray()));
      case Form.FieldDataTypeSelector.LIST_MULTI_ITEM:
        return Arrays.flatten(field.dataType.listMultiItemAttributes!.activatedFieldIdsByItemId.toArray().map(s => s.toArray()));
      default:
        return [];
    }
  }

  public static getEnabledByThisFieldGroupIds(field: Form.Field): number[] {
    switch (field.dataTypeSelector) {
      case Form.FieldDataTypeSelector.BOOLEAN:
        return field.dataType.booleanAttributes!.groupIdsToBeEnabled.toArray();
      case Form.FieldDataTypeSelector.LIST_ITEM:
        return Arrays.flatten(field.dataType.listItemAttributes!.activatedGroupIdsByItemId.toArray().map(s => s.toArray()));
      case Form.FieldDataTypeSelector.LIST_MULTI_ITEM:
        return Arrays.flatten(field.dataType.listMultiItemAttributes!.activatedGroupIdsByItemId.toArray().map(s => s.toArray()));
      default:
        return [];
    }
  }

}

export interface FieldDataTypeDescriptor {
  selector: Form.FieldDataTypeSelector;
  formFieldDialogFactory: FormEditFieldDialogViewFactory;
  formRecordFieldViewFactory: FormRecordFieldViewFactory;
  formFieldNameDictionaryKey: string;
  validInServices?: Set<FormServiceCode>
  canBeInactivated: boolean;
}

// <editor-fold desc="Form">

export interface FormEditFieldDialogViewFactory {
  createCreateView(): ComponentRef<FormEditFieldCreateDialogView>;

  createUpdateView(): ComponentRef<FormEditFieldUpdateDialogView>;
}

export interface FormEditFieldCreateDialogView extends FormEditFieldDialogView {
  showDialog(form: FormModel, group: FormGroupModel): void;
}

export interface FormEditFieldUpdateDialogView extends FormEditFieldDialogView {
  showDialog(form: FormModel, group: FormGroupModel, field: Form.Field, clone?: boolean): void;
}

export interface FormEditFieldDialogView {
  readonly selector: Form.FieldDataTypeSelector;

  registerContext(context: FormEditFieldDialogContext): void;
}

export interface FormEditContext {
  formView: FormEditView;
  formService: Form.Service;
  parentId: number;
}

export interface FormEditFieldDialogContext extends FormEditContext {
}

export interface FormEditView {
  reloadForm(): void;
}

export interface FormModel {
  formGroups: FormGroupModel[];
}

export interface FormGroupModel {
  groupId: number;
  creationTime: OffsetDateTime;
  updateTime: OffsetDateTime;
  title: string;
  shortTitle?: string;
  apiExportName: string
  disabled: boolean;
  fields: Form.Field[];
}

export interface FormRights {
  readonly formRead: Right;
  readonly formGroupUpdate: Right;
  readonly formFieldUpdate: Right;
  readonly formGroupCreate: Right;
  readonly formFieldCreate: Right;
  readonly formGroupDisable: Right;
  readonly formFieldDisable: Right;
  readonly formGroupMove: Right;
  readonly formFieldMove: Right;
}

// </editor-fold>

// <editor-fold desc="Form record">

export interface FormRecordGroupViewFactory {
  createView(context: FormRecordGroupViewContext): ComponentRef<FormRecordGroupView>;
}

export interface FormRecordFieldViewFactory {
  createView(): ComponentRef<FormRecordFieldView>;
}

export interface FormRecordGroupViewContext {
  form: Form.Form;
  group: Form.Group;
}

export interface FormRecordGroupView {
  registerFields(context: FormRecordGroupViewFieldsContext): void;

  hasLocalFieldError(): boolean;

  validateWithInterrupt(): boolean;

  shouldNotifyAfterCreation(): boolean;

  afterFormRecordCreation(formRecordId: number): Observable<any>;

  createModel(): FormRecordGroupViewModel;

  getGroupId(): number;

  updateFieldData(fieldId: number, data: any);
}

export interface FormRecordGroupViewFieldsContext {
  commandManager: CommandManager<SetTmpReadonlyResult>;
  commandResultStore: CommandResultStore;
  inactivityManager: FormRecordInactivityManager;
  htmlForm: FormRef;
  readonly: () => boolean;
  cloning: () => boolean;
  configuration: ConfigurationResource.Configuration;
  form: Form.Form;
  group: Form.Group;
  formRecord?: FormRecord.FormRecord;
  other?: any;
}

export interface FormRecordGroupViewModel {
  fieldEditRequests: Set<FormRecord.FieldEditRequest>;
}

export interface FormRecordFieldView {

  readonly selector: Form.FieldDataTypeSelector;

  readonly formRecordFieldContext?: FormRecordFieldContext;

  readonly valueReference?: FormRecordFieldValueReference<any>;

  readonly tmpReadonly: boolean;

  setTmpReadonly(args: SetTmpReadonlyArgs): Command<SetTmpReadonlyResult>;

  registerField(context: FormRecordFieldContext, originalModel?: any): any;

  registerFieldViews(context: FormRecordFieldViewContext): void;

  hasLocalFieldError(): boolean;

  validateWithInterrupt(): boolean;

  shouldNotifyAfterCreation(): boolean;

  afterFormRecordCreation(formRecordId: number): Observable<any> | undefined;

  updateValue(data: FormRecordFieldValueUpdateArgs);

  /**
   * @returns {FormRecordFieldViewModel} or undefined, but undefined is allowed only for unknown or readonly field type
   */
  createModel(): FormRecordFieldViewModel | undefined;

}

export interface FormRecordBooleanFieldView extends FormRecordFieldView {
  // used by FormRecordInactivityManager
  readonly valueReference: FormRecordFieldValueReference<boolean>;
}

export interface FormRecordListItemFieldView extends FormRecordFieldView {
  // used by FormRecordInactivityManager
  readonly valueReference: FormRecordFieldValueReference<FormRecordListItemValue | null>;
}

export interface FormRecordListMultiItemFieldView extends FormRecordFieldView {
  // used by FormRecordInactivityManager
  readonly valueReference: FormRecordFieldValueReference<FormRecordListMultiItemValue[]>;
}

export interface FormRecordListItemValue {
  id: number | null;
}

export interface FormRecordListMultiItemValue {
  id: number | null;
}

export interface FormRecordFieldValueUpdateArgs {

}

export interface FormRecordFieldContext {
  commandManager: CommandManager<SetTmpReadonlyResult>;
  commandResultStore: CommandResultStore;
  inactivityManager: FormRecordInactivityManager;
  htmlForm: FormRef;
  readonly: () => boolean;
  cloning: () => boolean;
  configuration: ConfigurationResource.Configuration;
  validationType: Form.FormFieldValidationType;
  form: Form.Form;
  group: Form.Group;
  field: Form.Field;
  fieldRecord?: FormRecord.FieldComposed;
  formRecordId?: number;
  other?: any;
}

export interface FormRecordFieldValueReference<T> {

  /**
   * Returns the current value or the future value if there is an applicable default value and the field is inactive
   * so the activation logic can work with the correct value.
   */
  get(): Promise<T>;

  set(value: T);

  previous(): T;

}

export interface FormRecordFieldViewContext {
  fieldViews: List<FormRecordFieldView>;
  inactivityManager: FormRecordInactivityManager;
}

export interface FormRecordFieldViewModel {
  fieldEditRequest: FormRecord.FieldEditRequest;
}

export interface SetTmpReadonlyArgs {
  tmpReadonly: boolean;
}

export interface SetTmpReadonlyResult {

  /**
   * true if the data inside the input is "changed" (changed means data loss)
   */
  changed: boolean;

}

@Injectable()
export class FormRecordSerializer {

  public static readonly MAX_DISPLAY_COUNT = 15;

  private static readonly serializableDataTypes: Form.FieldDataTypeSelector[] = [
    Form.FieldDataTypeSelector.STRING,
    Form.FieldDataTypeSelector.NUMBER,
    Form.FieldDataTypeSelector.DECIMAL,
    Form.FieldDataTypeSelector.DATE,
    Form.FieldDataTypeSelector.DATE_TIME,
    Form.FieldDataTypeSelector.BOOLEAN,
    Form.FieldDataTypeSelector.LIST_ITEM,
    Form.FieldDataTypeSelector.LIST_MULTI_ITEM,
    Form.FieldDataTypeSelector.EMAIL_ADDRESS,
    Form.FieldDataTypeSelector.PHONE_NUMBER,
    Form.FieldDataTypeSelector.PAYMENT_TYPE,
    Form.FieldDataTypeSelector.CUSTOMER,
    Form.FieldDataTypeSelector.USER,
  ];

  public static isSerializableField(field: Form.Field): boolean {
    return FormRecordSerializer.isSerializableDataType(field.dataTypeSelector);
  }

  public static isSerializableDataType(type: Form.FieldDataTypeSelector): boolean {
    return FormRecordSerializer.serializableDataTypes.includes(type);
  }

  constructor(
    private datePipe: DatePipe,
    private translateService: TranslateService,
    private listItemService: ListItemMapService,
    private customerRecordService: CustomerRecordService,
    private masterDataRecordService: MasterDataRecordService,
    private userService: UserService
  ) {
  }

  public serializeField(field?: Form.Field, fieldRecord?: FormRecord.Field): Observable<string> {
    return this.serializeFieldModel(field, fieldRecord).pipe(map(m => m.displayedValue));
  }

  public serializeFieldModel(field?: Form.Field, fieldRecord?: FormRecord.Field): Observable<DisplayedFormFieldModel> {
    const result: ReplaySubject<DisplayedFormFieldModel> = new ReplaySubject<DisplayedFormFieldModel>();
    if (fieldRecord) {
      switch (fieldRecord.dataTypeSelector) {
        case FieldDataTypeSelector.STRING:
          this.mapStringValue(result, field, fieldRecord);
          break;
        case FieldDataTypeSelector.NUMBER:
          this.mapNumberValue(result, field, fieldRecord);
          break;
        case FieldDataTypeSelector.DECIMAL:
          this.mapDecimalValue(result, field, fieldRecord);
          break;
        case FieldDataTypeSelector.DATE:
          this.mapDateValue(result, field, fieldRecord);
          break;
        case FieldDataTypeSelector.DATE_TIME:
          this.mapDateTimeValue(result, field, fieldRecord);
          break;
        case FieldDataTypeSelector.BOOLEAN:
          this.mapBooleanValue(result, field, fieldRecord);
          break;
        case FieldDataTypeSelector.EMAIL_ADDRESS:
          this.mapEmailAddressValue(result, field, fieldRecord);
          break;
        case FieldDataTypeSelector.PHONE_NUMBER:
          this.mapPhoneNumberValue(result, field, fieldRecord);
          break;
        case FieldDataTypeSelector.PAYMENT_TYPE:
          this.mapPaymentType(result, field, fieldRecord);
          break;
        case FieldDataTypeSelector.LIST_ITEM:
          this.mapListItemValue(result, field, fieldRecord);
          break;
        case FieldDataTypeSelector.LIST_MULTI_ITEM:
          this.mapListMultiItemValue(result, field, fieldRecord);
          break;
        case FieldDataTypeSelector.CUSTOMER:
          this.mapCustomerRecordValue(result, field, fieldRecord);
          break;
        case FieldDataTypeSelector.MASTER_DATA:
          this.mapMasterDataRecordValue(result, field, fieldRecord);
          break;
        case FieldDataTypeSelector.USER:
          this.mapUserValue(result, field, fieldRecord);
          break;
        default:
          result.next(new DisplayedFormFieldModel(fieldRecord));
          break;
      }
    } else {
      result.next(DisplayedFormFieldModel.empty());
    }
    return result;
  }

  private mapStringValue(result: ReplaySubject<DisplayedFormFieldModel>, field?: Form.Field, fieldRecord?: FormRecord.Field) {
    if (fieldRecord && field && fieldRecord.data.stringAttributes!.value) {
      result.next(new DisplayedFormFieldModel(fieldRecord, [fieldRecord.data.stringAttributes!.value]));
    }
    else {
      result.next(DisplayedFormFieldModel.empty(fieldRecord));
    }
  }

  private mapNumberValue(result: ReplaySubject<DisplayedFormFieldModel>, field?: Form.Field, fieldRecord?: FormRecord.Field) {
    if (fieldRecord && field && fieldRecord.data.numberAttributes!.value) {
      result.next(new DisplayedFormFieldModel(fieldRecord, [Models.numberToString(fieldRecord.data.numberAttributes!.value)]));
    }
    else {
      result.next(DisplayedFormFieldModel.empty(fieldRecord));
    }
  }

  private mapDecimalValue(result: ReplaySubject<DisplayedFormFieldModel>, field?: Form.Field, fieldRecord?: FormRecord.Field) {
    if (fieldRecord && field && fieldRecord.data.decimalAttributes!.value) {
      result.next(new DisplayedFormFieldModel(fieldRecord, [Models.decimalToString(fieldRecord.data.decimalAttributes!.value)]));
    }
    else {
      result.next(DisplayedFormFieldModel.empty(fieldRecord));
    }
  }

  private mapDateValue(result: ReplaySubject<DisplayedFormFieldModel>, field?: Form.Field, fieldRecord?: FormRecord.Field) {
    if (fieldRecord && field && fieldRecord.data.dateAttributes!.value) {
      result.next(new DisplayedFormFieldModel(fieldRecord, [this.datePipe.transform(fieldRecord.data.dateAttributes!.value.toIsoString(), 'mediumDate')!]));
    }
    else {
      result.next(DisplayedFormFieldModel.empty(fieldRecord));
    }
  }

  private mapDateTimeValue(result: ReplaySubject<DisplayedFormFieldModel>, field?: Form.Field, fieldRecord?: FormRecord.Field) {
    if (fieldRecord && field && fieldRecord.data.dateTimeAttributes!.value) {
      result.next(new DisplayedFormFieldModel(fieldRecord, [this.datePipe.transform(fieldRecord.data.dateTimeAttributes!.value.toUtcIsoString(), 'short')!]));
    }
    else {
      result.next(DisplayedFormFieldModel.empty(fieldRecord));
    }
  }

  private mapBooleanValue(result: ReplaySubject<DisplayedFormFieldModel>, field?: Form.Field, fieldRecord?: FormRecord.Field) {
    if (fieldRecord && field && fieldRecord.data.booleanAttributes!.value !== undefined) {
      this.translateService.get(fieldRecord.data.booleanAttributes!.value ? 'COMMON_YES' : 'COMMON_NO')
        .subscribe(b =>
          result.next(new DisplayedFormFieldModel(fieldRecord, [b])));
    }
    else {
      result.next(DisplayedFormFieldModel.empty(fieldRecord));
    }
  }

  private mapEmailAddressValue(result: ReplaySubject<DisplayedFormFieldModel>, field?: Form.Field, fieldRecord?: FormRecord.Field) {
    if (fieldRecord && field && fieldRecord.data.emailAddressAttributes!.value) {
      result.next(new DisplayedFormFieldModel(fieldRecord, [fieldRecord.data.emailAddressAttributes!.value.format()]));
    }
    else {
      result.next({formFieldRecord: fieldRecord, currentSelectedDataCount: 0, displayedValue: ''});
    }
  }

  private mapPhoneNumberValue(result: ReplaySubject<DisplayedFormFieldModel>, field?: Form.Field, fieldRecord?: FormRecord.Field) {
    if (fieldRecord && field && fieldRecord.data.phoneNumberAttributes!.value) {
      result.next(new DisplayedFormFieldModel(fieldRecord, [fieldRecord.data.phoneNumberAttributes!.value.format()]));
    }
    else {
      result.next(DisplayedFormFieldModel.empty(fieldRecord));
    }
  }

  private mapPaymentType(result: ReplaySubject<DisplayedFormFieldModel>, field?: Form.Field, fieldRecord?: FormRecord.Field) {
    if (fieldRecord && field && fieldRecord.data.paymentTypeAttributes!.paymentType) {
      this.translateService.get(fieldRecord.data.paymentTypeAttributes!.paymentType.stringKey)
        .subscribe(r =>
          result.next(new DisplayedFormFieldModel(fieldRecord, [r])));
    }
    else {
      result.next(DisplayedFormFieldModel.empty(fieldRecord));
    }
  }

  private mapListItemValue(result: ReplaySubject<DisplayedFormFieldModel>, field?: Form.Field, fieldRecord?: FormRecord.Field) {

    if (fieldRecord && field && fieldRecord.data.listItemAttributes!.value) {
      this.listItemService.getList({
        type_key: field.dataType.listItemAttributes!.listItemTypeKey,
        order: '+text'
      }).subscribe(items => {
        const selected = items.items.find(i => i!.id === fieldRecord.data.listItemAttributes!.value);
        let notSelected: string[] = [];
        const notDisabled = items.items.filter(i => !i!.disabled);
        notSelected = notDisabled.toArray().filter(i => i !== selected).map(i => i!.text);
        const totalNumber = notSelected.length + 1;
        result.next(new DisplayedFormFieldModel(fieldRecord, [selected.text], totalNumber <= FormRecordSerializer.MAX_DISPLAY_COUNT ? notSelected : [], notDisabled.size));
      })
    }
    else {
      result.next(DisplayedFormFieldModel.empty(fieldRecord));
    }
  }

  private mapListMultiItemValue(result: ReplaySubject<DisplayedFormFieldModel>, field?: Form.Field, fieldRecord?: FormRecord.Field) {
    if (fieldRecord && field && fieldRecord.data.listMultiItemAttributes!.values) {
      this.listItemService.getList({
        type_key: field.dataType.listMultiItemAttributes?.listItemTypeKey,
        order: '+text'
      })
        .subscribe((items) => {
          const selected = items.items.filter(i => fieldRecord.data.listMultiItemAttributes!.values.contains(i!.id));
          let notSelected: string[] = [];
          const notDisabled = items.items.filter(i => !i!.disabled);
          notSelected = notDisabled.toArray().filter(i => !selected.contains(i)).map(i => i!.text);
          const totalNumber = notSelected.length + selected.size;
          result.next(new DisplayedFormFieldModel(fieldRecord, selected.map(i => i!.text).toArray(), totalNumber <= FormRecordSerializer.MAX_DISPLAY_COUNT ? notSelected : [], totalNumber));
        });
    }
    else {
      result.next(DisplayedFormFieldModel.empty(fieldRecord));
    }
  }

  private mapCustomerRecordValue(result: ReplaySubject<DisplayedFormFieldModel>, field?: Form.Field, fieldRecord?: FormRecord.Field) {
    if (fieldRecord && field && fieldRecord.data.customerAttributes!.values && fieldRecord.data.customerAttributes!.values.size > 0) {
      this.customerRecordService.query({
        customerId: field.dataType.customerAttributes!.customerId,
        fields: Set.of('id', 'name'),
        customerRecordIdSet: fieldRecord.data.customerAttributes!.values,
        orders: Set.of({field: CustomerRecord.OrderField.NAME, type: OrderType.ASC})
      })
        .subscribe(selected => {
          if (selected.pagingResult.totalNumberOfItems <= FormRecordSerializer.MAX_DISPLAY_COUNT) {
            this.customerRecordService.query({
              customerId: field.dataType.customerAttributes!.customerId,
              disabled: false,
              fields: Set.of('id', 'name'),
            }).subscribe(all => {
              const notSelected = all.items.toArray().filter(i => !selected.items.contains(i)).map(i=>i.name);
              result.next(new DisplayedFormFieldModel(fieldRecord, selected.items.map(i => i!.name).toArray(), notSelected, all.pagingResult.totalNumberOfItems));
            })
          } else {
            result.next(new DisplayedFormFieldModel(fieldRecord, selected.items.map(i => i!.name).toArray(), [], selected.pagingResult.totalNumberOfItems));
          }
        });
    }
    else {
      result.next(DisplayedFormFieldModel.empty(fieldRecord));
    }
  }

  private mapMasterDataRecordValue(result: ReplaySubject<DisplayedFormFieldModel>, field?: Form.Field, fieldRecord?: FormRecord.Field) {
    if (fieldRecord && field && fieldRecord.data.masterDataAttributes!.values && fieldRecord.data.masterDataAttributes!.values.size > 0) {
      this.masterDataRecordService.query({
        masterDataId: field.dataType.masterDataAttributes!.masterDataId,
        fields: Set.of('id', 'name'),
        masterDataRecordIdSet: fieldRecord.data.masterDataAttributes!.values,
        orders: Set.of({field: MasterDataRecord.OrderField.NAME, type: OrderType.ASC})
      })
        .subscribe(selected => {
          if (selected.pagingResult.totalNumberOfItems <= FormRecordSerializer.MAX_DISPLAY_COUNT) {
            this.masterDataRecordService.query({
              masterDataId: field.dataType.masterDataAttributes!.masterDataId,
              disabled: false,
              fields: Set.of('id', 'name'),
            }).subscribe(all => {
              const notSelected = all.items.toArray().filter(i => !selected.items.contains(i)).map(i=>[i.name, i.externalId].join(' - '));
              result.next(new DisplayedFormFieldModel(fieldRecord, selected.items.map(i => [i!.name, i!.externalId].join(' - ')).toArray(), notSelected, all.pagingResult.totalNumberOfItems));
            })
          } else {
            result.next(new DisplayedFormFieldModel(fieldRecord, selected.items.map(i => [i!.name, i!.externalId].join(' - ')).toArray(), [], selected.pagingResult.totalNumberOfItems));
          }
        });
    }
    else {
      result.next(DisplayedFormFieldModel.empty(fieldRecord));
    }
  }

  private mapUserValue(result: ReplaySubject<DisplayedFormFieldModel>, field?: Form.Field, fieldRecord?: FormRecord.Field) {
    if (fieldRecord && field && fieldRecord.data.userAttributes!.values && fieldRecord.data.userAttributes!.values.size > 0) {
      this.userService.query({
        id: Services.createIdParameter(fieldRecord.data.userAttributes!.values),
        user_group_ids: field.dataType.userAttributes?.userGroupIds?.join(','),
        fields: 'id,person_name',
        order: '+person_name'
      })
        .subscribe(selected => {
          if (selected.pagingResult.totalNumberOfItems <= FormRecordSerializer.MAX_DISPLAY_COUNT) {
            this.userService.query({
              disabled: false,
              user_group_ids: field.dataType.userAttributes?.userGroupIds?.join(','),
              fields: 'id,name'
            }).subscribe(all => {
              const notSelected = all.items.filter(i => !selected.items.find(x => x.id === i.id)).map(i=> i.person_name);
              result.next(new DisplayedFormFieldModel(fieldRecord, selected.items.map(i => i.person_name), notSelected, all.pagingResult.totalNumberOfItems));
            })
          } else {
            result.next(new DisplayedFormFieldModel(fieldRecord, selected.items.map(i => i.person_name), [], selected.pagingResult.totalNumberOfItems));
          }
        });
    }
    else {
      result.next(DisplayedFormFieldModel.empty(fieldRecord));
    }
  }
}

export class DisplayedFormFieldModel {
  formFieldRecord?: FormRecord.Field;
  totalNumberOfSelectableData?: number;
  displayedValues?: string[] = [];
  notSelectedValues?: string[] = [];

  static empty(formFieldRecord?: FormRecord.Field): DisplayedFormFieldModel {
    return new DisplayedFormFieldModel(formFieldRecord);
  }

  constructor(formFieldRecord?: FormRecord.Field, displayedValues?: string[], notSelectedValues?: string[], totalNumberOfSelectableData?: number) {
    this.formFieldRecord = formFieldRecord;
    if (totalNumberOfSelectableData) {
      this.totalNumberOfSelectableData = totalNumberOfSelectableData;
    }
    if (displayedValues) {
      this.displayedValues = displayedValues;
    }
    if (notSelectedValues) {
      this.notSelectedValues = notSelectedValues;
    }
  }

  get displayedValue(): string {
    if (!this.displayedValues) {
      return '';
    }
    return this.displayedValues.join('\n');
  }

  get currentSelectedDataCount(): number {
    return this.displayedValues ? this.displayedValues.length : 0;
  }
}

// </editor-fold>
