/* eslint-disable */
import { Logger, LoggerFactory } from '../../../../../util/logger-factory';
import { Form } from '../../../../../lib/form/form.service';
import { FormRecordBooleanFieldView } from '../../../../../util/form/form-utils';
import {
  FieldActivationStrategy,
  FieldActivationStrategyCalculateInactivatesArgs,
  FieldActivationStrategyPutRulesArgs,
  FieldViewItem,
} from '../form-record-inactivity-manager-api';
import { Injectable } from '@angular/core';

/* eslint-enable */

@Injectable()
export class BooleanFieldActivationStrategy implements FieldActivationStrategy {

  private logger: Logger = LoggerFactory.createLogger('BooleanFieldActivationStrategy');

  constructor() {
  }

  putRules(args: FieldActivationStrategyPutRulesArgs) {
    const attrs: Form.FieldDataTypeBooleanAttributes = args.viewItem.field.dataType.booleanAttributes!;
    if (attrs.enableOtherFields) {
      const ids: Set<number> = new Set();
      attrs.fieldIdsToBeEnabled.forEach(fieldId => ids.add(fieldId!));
      attrs.groupIdsToBeEnabled.forEach(groupId => {
        args.form.groups
          .find(group => group!.groupId === groupId)!
          .fields.forEach(field => ids.add(field!.fieldId));
      });
      ids.forEach(id => {
        const fieldViewToActivate = args.viewItems.findByFieldId(id);
        if (fieldViewToActivate) {
          this.logger.debug('Boolean "%s" activates "%s"', args.viewItem.fieldTitle, fieldViewToActivate.fieldTitle);
          args.builder.put(args.viewItem, fieldViewToActivate);
        }
      });
    }
  }

  async calculateInactivates(args: FieldActivationStrategyCalculateInactivatesArgs): Promise<FieldViewItem[]> {
    const view: FormRecordBooleanFieldView = <FormRecordBooleanFieldView> args.viewItem.view;
    const value: boolean = await view.valueReference.get();
    if (value) {
      return [];
    }
    else {
      return args.activatees;
    }
  }

}
