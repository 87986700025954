/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { TransportDashboardResource, TransportDashboardResourceService } from './transport-dashboard-resource.service';
import { OffsetDateTime } from '../util/dates';
import { Services } from '../util/services';
/* eslint-enable */

@Injectable()
export class TransportDashboardService implements TransportDashboard.Service {

  constructor(private resourceService: TransportDashboardResourceService) {
  }

  get(request: TransportDashboard.GetRequest): Observable<TransportDashboard.Item> {
    return Observable.create((observer: Observer<TransportDashboard.Item>) => {
      const resourceRequest: TransportDashboard.GetRequest = {
      };
      return this.resourceService.get(resourceRequest).subscribe(
        (result: TransportDashboardResource.Item) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(/*this.translateError(*/error/*)*/);
        },
        () => {
          observer.complete();
        });
    });
  }

  private toPublic(r: TransportDashboardResource.Item): TransportDashboard.Item {
    return {
      demandStatistics: this.toPublicDemandStatistics(r.demand_statistics),
      transportStatistics: this.toPublicTransportStatistics(r.transport_statistics),
      recentMessages: this.toPublicRecentMessages(r.recent_messages),
      lostDriver: this.toPublicDriverItems(r.lost_drivers)
    };
  }

  toPublicDemandStatistics(demandStatistics: TransportDashboardResource.DemandStatistics): TransportDashboard.DemandStatistics {

    const NEW: number = demandStatistics.NEW ? demandStatistics.NEW : 0;
    const UNDER_CONSTRUCTION: number = demandStatistics.UNDER_CONSTRUCTION ? demandStatistics.UNDER_CONSTRUCTION : 0;
    const IN_PROGRESS: number = demandStatistics.IN_PROGRESS ? demandStatistics.IN_PROGRESS : 0;
    const DELIVERED: number = demandStatistics.DELIVERED ? demandStatistics.DELIVERED : 0;
    const HANDOVER_FAILED: number = demandStatistics.HANDOVER_FAILED ? demandStatistics.HANDOVER_FAILED : 0;
    const CANCELED: number = demandStatistics.CANCELED ? demandStatistics.CANCELED : 0;

    const stats = new Map<TransportDashboard.TransportDashboardState, TransportDashboard.StateStat>();

    const newStat = new TransportDashboard.StateStat();
    newStat.count = NEW;
    stats['NEW'] = newStat;

    const inPorgressStat = new TransportDashboard.StateStat();
    inPorgressStat.count = IN_PROGRESS + UNDER_CONSTRUCTION;
    stats['IN_PROGRESS'] = inPorgressStat;

    const openStat = new TransportDashboard.StateStat();
    openStat.count = DELIVERED;
    stats['OPEN'] = openStat;

    const finishedStat = new TransportDashboard.StateStat();
    finishedStat.count = HANDOVER_FAILED + CANCELED;
    stats['FINISHED'] = finishedStat;

    const taskRecordCount =
      NEW + IN_PROGRESS + UNDER_CONSTRUCTION + DELIVERED + HANDOVER_FAILED + CANCELED;

    return {
      NEW: NEW,
      UNDER_CONSTRUCTION: UNDER_CONSTRUCTION,
      IN_PROGRESS: IN_PROGRESS,
      DELIVERED: DELIVERED,
      HANDOVER_FAILED: HANDOVER_FAILED,
      CANCELED: CANCELED,
      stats: stats,
      taskRecordCount: taskRecordCount
    }
  }

  toPublicTransportStatistics(transportStatistics: TransportDashboardResource.TransportStatistics): TransportDashboard.TransportStatistics {

    const UNDER_PLANNING: number = transportStatistics.UNDER_PLANNING ? transportStatistics.UNDER_PLANNING : 0;
    const OPEN: number = transportStatistics.OPEN ? transportStatistics.OPEN : 0;
    const RUNNING: number = transportStatistics.RUNNING ? transportStatistics.RUNNING : 0;
    const UNDER_REPLANNING: number = transportStatistics.UNDER_REPLANNING ? transportStatistics.UNDER_REPLANNING : 0;
    const FINISHED: number = transportStatistics.FINISHED ? transportStatistics.FINISHED : 0;
    const CLOSED: number = transportStatistics.CLOSED ? transportStatistics.CLOSED : 0;

    const stats = new Map<TransportDashboard.TransportDashboardState, TransportDashboard.StateStat>();

    const newStat = new TransportDashboard.StateStat();
    newStat.count = UNDER_PLANNING + OPEN + UNDER_REPLANNING;
    stats['NEW'] = newStat;

    const inPorgressStat = new TransportDashboard.StateStat();
    inPorgressStat.count = RUNNING;
    stats['IN_PROGRESS'] = inPorgressStat;

    const openStat = new TransportDashboard.StateStat();
    openStat.count = FINISHED;
    stats['OPEN'] = openStat;

    const finishedStat = new TransportDashboard.StateStat();
    finishedStat.count = CLOSED;
    stats['FINISHED'] = finishedStat;

    const taskRecordCount =
      UNDER_PLANNING + OPEN + RUNNING + UNDER_REPLANNING + FINISHED + CLOSED;


    return {
      UNDER_PLANNING: UNDER_PLANNING,
      OPEN: OPEN,
      RUNNING: RUNNING,
      UNDER_REPLANNING: UNDER_REPLANNING,
      FINISHED: FINISHED,
      CLOSED: CLOSED,
      stats: stats,
      taskRecordCount: taskRecordCount

    }
  }

  toPublicRecentMessages(messages: TransportDashboardResource.Message[]): TransportDashboard.Message[] {
    return messages.map((r) => this.toPublicRecentMessage(r))
  }

  toPublicRecentMessage(message: TransportDashboardResource.Message): TransportDashboard.Message {
    return {
      transportId: message.transport_id,
      chatMessage: this.toPublicChatMessage(message.chat_message)
    }
  }

  toPublicChatMessage(message: TransportDashboardResource.TransportMessage): TransportDashboard.TransportMessage {
    return {
      id: message.id,
      creationTime: Services.toOffsetDateTime(message.creation_time),
      content: message.content,
      userProfileId: message.user_profile_id,
      userName: message.user_name,
      userPersonName: message.user_person_name,
    }
  }

  toPublicDriverItems(drivers: TransportDashboardResource.DriverItem[]): TransportDashboard.DriverItem[] {
    return drivers.map((r) => this.toPublicDriverItem(r))
  }

  toPublicDriverItem(driver: TransportDashboardResource.DriverItem): TransportDashboard.DriverItem {
    return {
      creationTime: Services.toOffsetDateTime(driver.creation_time),
      driver: this.toPublicDriver(driver.driver),
      transportId: driver.transport_id,
      transportWaybillNumber: driver.transport_waybill_number,
      vehicleLicencePlate: driver.vehicle_licence_plate
    }
  }

  toPublicDriver(driver: TransportDashboardResource.Driver): TransportDashboard.Driver {
    return {
      id: driver.id,
      personName: driver.person_name
    }
  }

}


export namespace TransportDashboard {

  export interface Service {

    // <editor-fold desc="CRUD">

    get(request: GetRequest): Observable<Item>;

    // </editor-fold>

  }

  export interface Item {
    demandStatistics: DemandStatistics;
    transportStatistics: TransportStatistics;
    recentMessages: Message[];
    lostDriver: DriverItem[];
  }

  export interface GetRequest {
  }

  export interface DemandStatistics {
    NEW: number;
    UNDER_CONSTRUCTION: number;
    IN_PROGRESS: number;
    DELIVERED: number;
    HANDOVER_FAILED: number;
    CANCELED: number;
    stats: Map<TransportDashboardState, StateStat>;
    taskRecordCount: number;
  }

  export interface TransportStatistics {
    UNDER_PLANNING: number;
    OPEN: number;
    RUNNING: number;
    UNDER_REPLANNING: number;
    FINISHED: number;
    CLOSED: number;
    stats: Map<TransportDashboardState, StateStat>;
    taskRecordCount: number;
  }

  export class StateStat {
    count: number;
  }

  // Google Chart use this state
  export type TransportDashboardState = 'NEW' | 'IN_PROGRESS' | 'OPEN' | 'FINISHED';

  export interface Message {
    transportId: number;
    chatMessage: TransportMessage;
  }

  export interface TransportMessage {
    id: number;
    creationTime: OffsetDateTime;
    content: string;
    userProfileId: number;
    userName: string;
    userPersonName: string;
  }

  export interface DriverItem {
    creationTime: OffsetDateTime;
    driver: Driver;
    transportId: number;
    transportWaybillNumber: string;
    vehicleLicencePlate: string;
  }

  export interface Driver {
    id: number;
    personName: string;
  }
}
