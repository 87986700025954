<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <responsive-tabset class="fullscreen_tab" #insuranceTabs>

    <responsive-tab *ngIf="rightModel.clientDocumentRead.hasRight()"
                    heading="{{'INSURANCE_LIST'|translate}}">
      <div class="card">

        <div class="card-header">
          {{'INSURANCE_LIST' | translate}}
          <div class="card-actions">
            <a class="btn-setting table-header-action-button d-flex align-items-center px-1 w-auto font-weight-normal"
               title="{{'ATTACHMENT_DOWNLOAD_TITLE' | translate}}"
               (click)="downloadZip()">
              <i class="icomoon pr-1 icomoon-download"></i>
              <span class="breadcrumb-button-text-visible">{{'ATTACHMENT_DOWNLOAD_TITLE' | translate}}</span>
            </a>
            <a class="btn-setting table-header-action-button d-flex align-items-center px-1 w-auto font-weight-normal"
               title="{{'COMMON_BUTTON_EMAIL' | translate}}"
               (click)="openEmailDialog()">
              <i class="icomoon pr-1 icomoon-email-send"></i>
              <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_EMAIL' | translate}}</span>
            </a>
            <a class="btn-setting cursor-pointer" (click)="toggleSearch()"
               title="{{'COMMON_SHOW_SEARCH_HINT' | translate}}">
              <i #searchIcon class="icomoon icomoon-search"></i>
            </a>
          </div><!-- /.card-actions -->
        </div>
        <div class="card-body border-bottom" *ngIf="showSearch">
          <app-dql-search-container #dqlSearchContainer
                                    (onSearch)="onDqlSearchClicked()"
                                    [rightModel]="rightModel"
                                    [dqlStoredQueryArgs]="dqlStoredArgs"

                                    [dialogContainer]="dqlDialogHolder"
                                    [isTyped]="true">
          </app-dql-search-container>
        </div>
        <div class="card-body">

          <table class="table table-striped table-bordered">
            <thead>
            <tr>
              <th class="table-sorter-header w-5 hidden-xs-down">
                <app-animated-checkbox [selected]="allInsurancesSelected"
                                       (clickEvent)="allSelected()"></app-animated-checkbox>
              </th>
              <th class="table-sorter-header">
                <app-table-field-sorter [orderField]="ClientDocument.OrderField.EXTERNAL_ID"
                                        [orderType]="queryModel.getOrderType(ClientDocument.OrderField.EXTERNAL_ID)"
                                        (orderChange)="orderBy($event)"
                                        [text]="'INSURANCE_LIST_TABLE_HEADER_EXTERNAL_ID' | translate"></app-table-field-sorter>
              </th>
              <th class="table-sorter-header hidden-sm-down">
                <app-table-sorter-no-op
                  [text]="'INSURANCE_LIST_TABLE_HEADER_SPONSOR' | translate"></app-table-sorter-no-op>
              </th>
              <th class="table-sorter-header hidden-sm-down">
                <app-table-sorter-no-op
                  [text]="'INSURANCE_LIST_TABLE_HEADER_CLIENT_NAME' | translate"></app-table-sorter-no-op>
              </th>
              <th class="table-sorter-header hidden-sm-down">
                <app-table-sorter-no-op
                  [text]="'INSURANCE_LIST_TABLE_HEADER_INSURANCE_DETAILS' | translate"></app-table-sorter-no-op>
              </th>
              <th class="table-sorter-header hidden-md-down">
                <app-table-sorter-no-op
                  [text]="'INSURANCE_LIST_TABLE_HEADER_SUBMITTER_NAME' | translate"></app-table-sorter-no-op>
              </th>
              <th class="table-sorter-header hidden-md-down">
                <app-table-field-sorter [orderField]="ClientDocument.OrderField.SUBMIT_TIME"
                                        [orderType]="queryModel.getOrderType(ClientDocument.OrderField.SUBMIT_TIME)"
                                        (orderChange)="orderBy($event)"
                                        [text]="'INSURANCE_LIST_TABLE_HEADER_SUBMIT_TIME' | translate"></app-table-field-sorter>
              </th>
              <th class="table-sorter-header w-table-options-menu">
                <app-table-sorter-no-op
                  [text]="'INSURANCE_LIST_TABLE_HEADER_PDF_DOWNLOAD' | translate"></app-table-sorter-no-op>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let insurance of insuranceList | paginate: {
                      itemsPerPage: queryModel.itemsPerPage,
                      currentPage: queryModel.currentPage,
                      totalItems: queryModel.currentNumberOfItems }">
              <td class="hidden-xs-down">
                <app-animated-checkbox (clickEvent)="toggleSelected(insurance.id)"
                                       [selected]="isSelected(insurance.id)"></app-animated-checkbox>
              </td>
              <td>
                <i class="icomoon icomoon-trash icomoon-disable-table" *ngIf="insurance.deleted"
                   [popover]="deletionData" triggers="mouseenter:mouseleave"></i>
                <ng-template #deletionData>
                  {{insurance.deletionCause}}<br/>
                  {{insurance.deletionDate.toUtcIsoString() | date:'shortDate'}}
                </ng-template>
                {{insurance.externalId}}
              </td>
              <td class="hidden-sm-down">{{insurance.sponsorName}}</td>
              <td class="hidden-sm-down">{{insurance.clientName}}</td>
              <ng-template #paymentTypePopover>
                <i class="icomoon {{getPaymentTypeIcon(insurance.paymentTypeCode)}}"></i>
                {{insurance.paymentTypeText}}
              </ng-template>
              <td class="hidden-sm-down">
                {{insurance.insuranceProductType}} - {{insurance.insuranceMode}}
                - {{insurance.insuranceLevel}} {{'INSURANCE_LEVEL' | translate}}
                <i class="icomoon {{getPaymentTypeIcon(insurance.paymentTypeCode)}}" [popover]="paymentTypePopover"
                   triggers="mouseenter:mouseleave"></i>
              </td>
              <td class="hidden-md-down">{{insurance.creatorName}}</td>
              <td class="hidden-md-down">{{insurance.submitTime.toUtcIsoString() | date:'short'}}</td>
              <td>
                <a *ngIf="insurance.hasPdf" class="icons-table-group" (click)="downloadPdf(insurance.id)">
                  <i class="icomoon icons-table-item icomoon-download icomoon-modify-table cursor-pointer"
                     title="{{'COMMON_BUTTON_DOWNLOAD' | translate}}"></i>
                </a>
              </td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
              <th class="table-sorter-header w-5 hidden-xs-down">
                <app-animated-checkbox [selected]="allInsurancesSelected"
                                       (clickEvent)="allSelected()"></app-animated-checkbox>
              </th>
              <th class="table-sorter-header">
                <app-table-field-sorter [orderField]="ClientDocument.OrderField.EXTERNAL_ID"
                                        [orderType]="queryModel.getOrderType(ClientDocument.OrderField.EXTERNAL_ID)"
                                        (orderChange)="orderBy($event)"
                                        [text]="'INSURANCE_LIST_TABLE_HEADER_EXTERNAL_ID' | translate"></app-table-field-sorter>
              </th>
              <th class="table-sorter-header hidden-sm-down">
                <app-table-sorter-no-op
                  [text]="'INSURANCE_LIST_TABLE_HEADER_SPONSOR' | translate"></app-table-sorter-no-op>
              </th>
              <th class="table-sorter-header hidden-sm-down">
                <app-table-sorter-no-op
                  [text]="'INSURANCE_LIST_TABLE_HEADER_CLIENT_NAME' | translate"></app-table-sorter-no-op>
              </th>
              <th class="table-sorter-header hidden-sm-down">
                <app-table-sorter-no-op
                  [text]="'INSURANCE_LIST_TABLE_HEADER_INSURANCE_DETAILS' | translate"></app-table-sorter-no-op>
              </th>
              <th class="table-sorter-header hidden-md-down">
                <app-table-sorter-no-op
                  [text]="'INSURANCE_LIST_TABLE_HEADER_SUBMITTER_NAME' | translate"></app-table-sorter-no-op>
              </th>
              <th class="table-sorter-header hidden-md-down">
                <app-table-field-sorter [orderField]="ClientDocument.OrderField.SUBMIT_TIME"
                                        [orderType]="queryModel.getOrderType(ClientDocument.OrderField.SUBMIT_TIME)"
                                        (orderChange)="orderBy($event)"
                                        [text]="'INSURANCE_LIST_TABLE_HEADER_SUBMIT_TIME' | translate"></app-table-field-sorter>
              </th>
              <th class="table-sorter-header w-table-options-menu">
                <app-table-sorter-no-op
                  [text]="'INSURANCE_LIST_TABLE_HEADER_PDF_DOWNLOAD' | translate"></app-table-sorter-no-op>
              </th>
            </tr>
            </tfoot>
          </table>

          <app-table-paging
            [currentNumberOfItems]="queryModel.currentNumberOfItems"
            [totalNumberOfItems]="queryModel.totalNumberOfItems"
            [itemsPerPage]="queryModel.itemsPerPage"
            (pageChange)="pageChanged($event)"
            (itemsPerPageChange)="itemsPerPageChanged($event)">
          </app-table-paging>

        </div><!-- /.card-body -->

      </div><!-- /.card -->
    </responsive-tab>

    <responsive-tab *ngIf="rightModel.colonnadeB2bRead.hasRight()"
                    heading="{{'COLONNADE_CLIENT_DOCUMENT_STAT_ENROLLER_REPORT'|translate}}">
      <app-colonnade-client-document-stat-enroller-report>
      </app-colonnade-client-document-stat-enroller-report>
    </responsive-tab>

    <responsive-tab *ngIf="rightModel.colonnadeB2bRead.hasRight()"
                    heading="{{'COLONNADE_CLIENT_DOCUMENT_STAT_PRODUCT_REPORT'|translate}}">
      <app-colonnade-client-document-stat-product-report>
      </app-colonnade-client-document-stat-product-report>
    </responsive-tab>

    <responsive-tab *ngIf="rightModel.colonnadeB2bRead.hasRight()"
                    heading="{{'COLONNADE_CLIENT_DOCUMENT_STAT_ENROLLER_SUMMARY'|translate}}">
      <app-colonnade-client-document-stat-enroller-summary>
      </app-colonnade-client-document-stat-enroller-summary>
    </responsive-tab>

  </responsive-tabset>


</div><!-- /container -->

<app-dql-dialog-container #dqlDialogHolder></app-dql-dialog-container>

<div bsModal [config]="UiConstants.modalConfig" #emailSendDialog="bs-modal" class="modal fade" tabindex="-1"
     role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="emailSendDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'COMMON_BUTTON_EMAIL' | translate}}</h4>
        <button type="button" class="close" (click)="closeEmailSendDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form [formGroup]='emailSendForm' (ngSubmit)="sendEmail()" class="modal-body border-top-1">
        <div class="row form-group"
             [ngClass]="{ 'has-danger': emailSendForm.controls['recipientName'].touched && !emailSendForm.controls['recipientName'].valid}">
          <div class="col-md-4">
            <label class="col-form-label mw-100 form-control-label">{{'COMMON_RECIPIENT_NAME' | translate}}</label>
          </div>
          <div class="col-md-8">
            <input type="text" class="form-control" placeholder="{{'COMMON_RECIPIENT_NAME' | translate}}"
                   [(ngModel)]="emailSendModel.recipientName"
                   [ngClass]="{ 'form-control-danger': emailSendForm.controls['recipientName'].touched && !emailSendForm.controls['recipientName'].valid}"
                   formControlName="recipientName"
                   maxlength="{{UiConstants.maximumVarcharLength}}">
            <div class="form-control-feedback"
                 *ngIf="emailSendForm.controls['recipientName'].touched && !emailSendForm.controls['recipientName'].valid">
              {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
            </div>
          </div>
        </div>
        <div class="row form-group"
             [ngClass]="{ 'has-danger': emailSendForm.controls['recipientEmailAddress'].touched && !emailSendForm.controls['recipientEmailAddress'].valid}">
          <div class="col-md-4">
            <label class="col-form-label mw-100 form-control-label">{{'COMMON_RECIPIENT_EMAIL' | translate}}</label>
          </div>
          <div class="col-md-8">
            <input type="text" class="form-control" placeholder="{{'COMMON_RECIPIENT_EMAIL' | translate}}"
                   formControlName="recipientEmailAddress"
                   [(ngModel)]="emailSendModel.recipientEmailAddress"
                   [ngClass]="{ 'form-control-danger': emailSendForm.controls['recipientEmailAddress'].touched && !emailSendForm.controls['recipientEmailAddress'].valid}"
                   maxlength="{{UiConstants.maximumVarcharLength}}">
            <div class="form-control-feedback"
                 *ngIf="emailSendForm.controls['recipientEmailAddress'].touched && !emailSendForm.controls['recipientEmailAddress'].valid">
              {{'COMMON_VALIDATION_MESSAGE_EMAIL' | translate}}
            </div>
          </div>
        </div>

        <div class="alert mb-0"
             [ngClass]="{ 'alert-danger': emailSendModel.isDangerous(), 'alert-info': !emailSendModel.isDangerous()}">
          <h6 class="alert-title">{{'CLIENT_DOCUMENT_SEND_EMAIL_NOTIFICATION' | translate: {
            count:
            emailSendModel.generalPdfIds.length
          } }}</h6>
        </div>
      </form>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="sendEmail()">{{'COMMON_BUTTON_EMAIL' |
          translate}}
        </button>
        <button type="button" class="btn btn-secondary" (click)="closeEmailSendDialog()">{{'COMMON_BUTTON_CANCEL' |
          translate}}
        </button>

      </div><!-- /.modal-footer -->
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

