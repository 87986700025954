<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-vertical-padding animated fadeIn">
  <div class="col-xs-12 col-sm-12 col-md-8">
    <div class="card">

      <div class="card-header">
        {{'DOCUMENT_PANEL_HEADING_DETAIL' | translate}}
      </div>

      <div class="card-body">

        <!--ID-->
        <div class="form-group row mb-0">
          <label class="col-form-label form-control-label col-md-4 detail-title"> {{ 'DOCUMENT_ID' | translate
            }} </label>
          <div class="col-md-8">
            <input
              type="text"
              class="form-control detail-description"
              [(ngModel)]="model.id"
              name="id"
              id="id"
              #id="ngModel"
              readonly>
          </div>
        </div>

        <!--EXTERNAL ID-->
        <div class="form-group row mb-0">
          <label class="col-form-label form-control-label col-md-4 detail-title"> {{ 'COMMON_EXTERNAL_ID' | translate
            }} </label>
          <div class="col-md-8">
            <input
              type="text"
              class="form-control detail-description"
              [(ngModel)]="model.external_id"
              name="external_id"
              id="external_id"
              #external_id="ngModel"
              readonly>
          </div>
        </div>

        <!--NAME-->
        <div class="form-group row mb-0">
          <label class="col-form-label form-control-label col-md-4 detail-title"> {{ 'COMMON_NAME' | translate
            }} </label>
          <div class="col-md-8">
            <input
              type="text"
              class="form-control detail-description"
              [(ngModel)]="model.name"
              name="name"
              id="name"
              #name="ngModel"
              readonly>
          </div>
        </div>

        <!--DESCRIPTION-->
        <div *ngIf="model.description" class="form-group row mb-0">
          <label class="col-form-label form-control-label col-md-4 detail-title"> {{'COMMON_DESCRIPTION' |
            translate}} </label>
          <div class="col-md-8">
            <input
              type="text"
              class="form-control detail-description"
              placeholder="{{'COMMON_DESCRIPTION' | translate}}"
              [(ngModel)]="model.description"
              name="description"
              id="description"
              #description="ngModel"
              readonly>
          </div>
        </div>

        <!--GROUP-->
        <div *ngIf="model.document_group_id" class="form-group row mb-0">
          <label class="col-form-label form-control-label col-md-4 detail-title"> {{ 'FILE_DOCUMENT_HIERARCHY' | translate
            }} </label>
          <div class="col-md-8">
            <span *ngFor="let group of documentGroupHierarchy; let last = last">
              <a class="id-text cursor-pointer"
                 href="#" onclick="return false"
                 uiSref="Admin.DocumentGroupEdit"
                 [uiParams]="{id: group.id}"
                 title="{{'COMMON_BUTTON_EDIT' | translate}}">
                {{ group.itemName }}
              </a>
              <span *ngIf="!last"> / </span>
            </span>
          </div>
        </div>

      </div> <!-- ./card-body end -->
    </div>  <!-- ./card end -->

    <div class="container-vertical-padding-half"></div>

    <!--FILE AND THUMBNAIL-->
    <div class="card">
      <div class="card-header cursor-pointer">
        {{'DOCUMENT_PANEL_HEADING_FILES' | translate}}
      </div>

      <div class="card-body" *ngIf="showFiles">

        <!--FILE-->
        <div class="row" *ngIf="!ctp || !ctp.isImageType">

        <label class="col-form-label form-control-label col-md-4 detail-title" *ngIf="!!model.size"> {{ 'DOCUMENT_FILE_NAME' | translate }} </label>
          <div class="col-md-8">
            <div class="row" *ngIf="!!model.size">
              <div class="input-group px-1">
                <span class="input-group-btn" style="margin-left: 5px">
                  <button class="btn detail-description table-header-action-button cursor-pointer" type="submit" (click)="downloadFile()"
                          title="{{'COMMON_BUTTON_DOWNLOAD' | translate}}">
                    <i class="icomoon icomoon-download"></i>
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-1" *ngIf="ctp && ctp.isImageType">
          <label class="form-control-label col-md-4 col-form-label"> {{ 'DOCUMENT_FILE_NAME' | translate }} </label>
          <div class="input-group col-md-8">
            <div class="thumbnail-container">
              <div class="thumbnail-background" *ngIf="imageLoaded" [style.background-image]="imageSrc"></div>
              <div class="thumbnail-background-placeholder" *ngIf="!imageLoaded"></div>
              <div class="thumbnail-action-buttons d-flex flex-row justify-content-end">
                <div class="thumbnail-action-button cursor-pointer" (click)="downloadImage()"
                     title="{{'COMMON_BUTTON_COLLAPSE_SHOW' | translate}}">
                  <i class="icomoon icomoon-enable">
                  </i>
                </div>
                <div class="thumbnail-action-button cursor-pointer" (click)="downloadFile()"
                     title="{{'COMMON_BUTTON_DOWNLOAD' | translate}}">
                  <i class="icomoon icomoon-download">
                  </i>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="container-vertical-padding-half" *ngIf="model.has_thumbnail"></div>

        <!--THUMBNAIL-->
        <div class="row" *ngIf="model.has_thumbnail">
          <label class="form-control-label col-md-4 col-form-label"> {{ 'COMMON_THUMBNAIL_DIALOG_TITLE' | translate }} </label>
          <div class="input-group col-md-8">
            <div class="thumbnail-container">
              <div class="thumbnail-background" *ngIf="thumbnailLoaded" [style.background-image]="thumbnailSrc"></div>
              <div class="thumbnail-background-placeholder" *ngIf="!thumbnailLoaded"></div>
              <div class="thumbnail-action-buttons d-flex flex-row justify-content-end">
                <div class="thumbnail-action-button cursor-pointer" (click)="downloadThumbnail()"
                     title="{{'COMMON_BUTTON_COLLAPSE_SHOW' | translate}}">
                  <i class="icomoon icomoon-enable">
                  </i>
                </div>
                <div class="thumbnail-action-button cursor-pointer" (click)="downloadThumbnailAsFile()"
                     title="{{'COMMON_BUTTON_DOWNLOAD' | translate}}">
                  <i class="icomoon icomoon-download">
                  </i>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div><!-- /.card-body-->
    </div><!-- /.card -->
  </div> <!--./FILE AND THUMBNAIL-->
</div>
<!--THUMBNAIL DIALOG-->
<div bsModal [config]="UiConstants.modalConfig" #thumbnailDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title"> {{ 'COMMON_THUMBNAIL_DIALOG_TITLE' | translate }} </h4>
        <button type="button" class="close" (click)="thumbnailDialog.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
      <div class="align-middle">
        <img class="img-responsive" style="width: 100%;"
             [src]="sanitizer.bypassSecurityTrustUrl(thumbnailToShow)"
             alt="Place image title"
             *ngIf="thumbnailToShow">
      </div>
    </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="thumbnailDialog.hide()"> {{ 'COMMON_BUTTON_CLOSE' | translate }} </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div> <!-- /.modal --> <!--./THUMBNAIL DIALOG-->

<!--IMAGE DIALOG-->
<div bsModal [config]="UiConstants.modalConfig" #imageDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title"> {{ 'COMMON_IMAGE_DIALOG_TITLE' | translate }} </h4>
        <button type="button" class="close" (click)="imageDialog.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="align-middle">
          <img class="img-responsive" style="width: 100%;"
               [src]="sanitizer.bypassSecurityTrustUrl(imageToShow)"
               alt="Place image title"
               *ngIf="imageToShow">
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="imageDialog.hide()"> {{ 'COMMON_BUTTON_CLOSE' |
          translate }}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div> <!-- /.modal --> <!--./THUMBNAIL DIALOG-->
