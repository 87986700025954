<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>

<!-- region Content -->
<div class="container-horizontal-padding animated fadeIn">

  <div class="container-custom-padding">

    <div class="row">

      <!-- region SHIPMENT GROUP BASE DATA -->
      <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12 card-custom-padding">
        <app-shipment-group-base-data
          [shipmentGroupModel]="shipmentGroupModel">
        </app-shipment-group-base-data>
      </div>
      <!-- endregion SHIPMENT GROUP BASE DATA -->

      <!-- region SHIPMENT GROUP OUTTAKE -->
      <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12 card-custom-padding" *ngIf="shipmentGroupModel.outtake">
        <app-shipment-group-outtake
          [readonly]="componentState.isReadonly()"
          [rightModel]="rightModel"
          [outtakeModel]="shipmentGroupModel.outtake"
          (openForceReopenDialog)="openForceReopenDialog()"
          (openStorekeeperChangeDialog)="openChangeAssigneeDialog()">
        </app-shipment-group-outtake>
      </div>
      <!-- region SHIPMENT GROUP OUTTAKE -->

    </div>

    <div class="row container-vertical-padding">

      <!-- region SHIPMENT DEPOT LIST -->
      <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 card-custom-padding">
        <app-shipment-group-depot-list
          #depotListComponent
          [shipmentGroupId]="shipmentGroupId"
          [readonly]="componentState.isReadonly()"
          [endpoints]="shipmentGroupModel.endpoints"
          [shipmentGroupModel]="shipmentGroupModel"
          (openAddShipmentDepotDialog)="openAddDepotDialog()">
        </app-shipment-group-depot-list>
      </div>
      <!-- endregion SHIPMENT DEPOT LIST -->

    </div>

    <div class="row">

      <!-- region SHIPMENT GROUP SHIPPING DEMAND LIST -->
      <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 card-custom-padding">
        <app-shipment-group-shipping-demand-list
          [shipmentGroupModel]="shipmentGroupModel"
          (shippingDemandListChanged)="loadModel()"
          [readonly]="componentState.isReadonly()">
        </app-shipment-group-shipping-demand-list>
      </div>
      <!-- endregion SHIPMENT GROUP SHIPPING DEMAND LIST -->

    </div>

    <div class="container-vertical-padding-half"></div>

  </div>

</div>
<!-- endregion Content -->

<!-- region Dialogs -->

<div bsModal #addDepotDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="addDepotDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'SHIPMENT_DEPOT_CREATE_TITLE' | translate}}</h4>
        <button type="button" class="close" (click)="closeAddDepotDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" [formGroup]="formGroup" #f="ngForm">

        <div class="form-group row"
             [ngClass]="{ 'has-danger': hasLocalFieldError('company')}">
          <label class="col-form-label col-md-4">{{'SHIPMENT_DEPOT_CREATE_COMPANY' | translate}}</label>
          <div class="col-md-8">
            <select class="form-control" [compareWith]="SelectUtils.compareNumbersWithNull"
                    [(ngModel)]="addDepotModel.companyId"
                    (ngModelChange)="onCompanySelected()"
                    [ngClass]="{ 'form-control-danger': hasLocalFieldError('company') }"
                    formControlName="company"
                    trim="blur" validateOnBlur [validateFormControl]="formGroup.controls['company']">
              <option disabled hidden [value]="null">{{'COMMON_VALUE_PLEASE_SELECT' | translate}}</option>
              <option *ngFor="let item of companies" [ngValue]="item.id">{{item.itemName}}
              </option>
            </select>
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('company')">
              {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
            </div>
          </div>
          <!-- /.col-md-8 -->
        </div>

        <div class="form-group row"
             [ngClass]="{ 'has-danger': hasLocalFieldError('companyLocation')}">
          <label class="col-form-label col-md-4">{{'SHIPMENT_DEPOT_CREATE_COMPANY_LOCATION' | translate}}</label>
          <div class="col-md-8">
            <select class="form-control" [compareWith]="SelectUtils.compareNumbersWithNull"
                    [(ngModel)]="addDepotModel.companyLocationId"
                    [ngClass]="{ 'form-control-danger': hasLocalFieldError('companyLocation') }"
                    formControlName="companyLocation"
                    trim="blur" validateOnBlur [validateFormControl]="formGroup.controls['companyLocation']">
              <option disabled hidden [value]="null">{{'COMMON_VALUE_PLEASE_SELECT' | translate}}</option>
              <option *ngFor="let item of companyLocations" [ngValue]="item.id">{{item.itemName}}
              </option>
            </select>
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('companyLocation')">
              {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
            </div>
          </div>
          <!-- /.col-md-8 -->
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeAddDepotDialog()">
          {{'COMMON_BUTTON_CANCEL' | translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="addDepot()">{{'COMMON_BUTTON_SAVE' |
          translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<div bsModal #forceReopenDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="forceReopenDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'SHIPMENT_OUTTAKE_FORCE_REOPEN' | translate}}</h4>
        <button type="button" class="close" (click)="closeForceReopenDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <div class="alert alert-warning mb-0">
          <p class="mb-0">
              {{'SHIPMENT_OUTTAKE_FORCE_REOPEN_WARNING' | translate}}
          </p>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeAddDepotDialog()">
          {{'COMMON_BUTTON_CANCEL' | translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="forceReopen()">{{'COMMON_BUTTON_CONTINUE' |
          translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<div bsModal #changeAssigneeDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="changeAssigneeDialogVisible">
    <div class="modal-content">
      <form class="form-horizontal bordered-row" (ngSubmit)="changeAssigneeForm.form.valid && changeAssignee()" #changeAssigneeForm="ngForm" novalidate>
        <div class="modal-header">
          <h4 class="modal-title">{{'SHIPMENT_OUTTAKE_CHANGE_ASSIGNEE' | translate}}</h4>
          <button type="button" class="close" (click)="closeChangeAssigneeDialog()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">

          <div class="form-group row"
               [ngClass]="{ 'has-danger': changeAssigneeForm.submitted && !changeAssigneeInput.valid}">
            <label class="col-form-label col-md-4" [class.required-field-label]="shipmentGroupModel.outtake.state.state !== 'OPEN'">
              {{'SHIPMENT_OUTTAKE_ASSIGNEE' | translate}}
            </label>
            <div class="col-md-8">
              <angular2-multiselect [data]="assigneeUsers" [settings]="dropdownSettings"
                                    class="form-control"
                                    [(ngModel)]="changeAssigneeModel"
                                    (onRemoteSearch)="loadAssigneeUsers($event.target.value)"
                                    #changeAssigneeInput="ngModel" name="changeAssigneeInput" id="changeAssigneeInput"
                                    [required]="shipmentGroupModel.outtake.state.state !== 'OPEN'">
              </angular2-multiselect>
              <div class="form-control-feedback" *ngIf="changeAssigneeForm.submitted && !changeAssigneeInput.valid">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
            <!-- /.col-md-8 -->
          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="closeChangeAssigneeDialog()">
            {{'COMMON_BUTTON_CANCEL' | translate}}
          </button>
          <button type="submit" class="btn btn-primary">{{'COMMON_BUTTON_SAVE' |
            translate}}
          </button>
        </div>
      </form>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<!-- endregion Dialogs -->
