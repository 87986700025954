/* eslint-disable */
import { Component, OnInit } from '@angular/core';
import { Survey, SurveyService } from '../../../lib/survey/survey.service';
import { QueryResult } from '../../../lib/util/services';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { DownloadedFile } from '../../../lib/util/downloaded-files';
import SurveyCount = Survey.SurveyCount;
import { StateName } from '../../../app.state-names';
import { UIRouter } from '@uirouter/angular';
/* eslint-enable */

@Component({
  selector: 'app-survey-dashboard',
  templateUrl: './survey-dashboard.component.html',
  styleUrls: ['./survey-dashboard.component.scss']
})
export class SurveyDashboardComponent implements OnInit {

  surveyList: SurveyItem[] = [];
  counts: SurveyCount[] = [];

  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  constructor(
    private surveyService: SurveyService,
    private sanitizer: DomSanitizer,
    private translateService: TranslateService,
    private router: UIRouter
  ) {
  }

  ngOnInit() {
    this.translateService.get('MENU_NAVBAR_SURVEY_RECORD').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.loadSurveys();
    this.loadSurveyCounts();
  }

  private loadSurveys() {
    this.surveyService.query({
      disabled: false,
    }).subscribe((result: QueryResult<Survey.Survey>) => {
      if (result.items.size === 1) {
        this.router.stateService.go(StateName.SURVEY_RECORD_LIST, { id: result.items.get(0).id });
      }
      result.items.toArray().forEach((s) => {
        const item = new SurveyItem();
        item.id = s.id;
        item.name = s.name;
        item.hasThumbnail = s.hasThumbnail;
        this.surveyList.push(item);
      });
      this.loadThumbnails();
    })
  }

  private loadSurveyCounts() {
    this.surveyService.counts({}).subscribe((result) => {
      this.counts = result.toArray();
    })
  }

  private loadThumbnails() {
    this.surveyList.forEach((survey) => {
      if (survey.hasThumbnail) {
        survey.thumbnailLoaded = false;
        this.surveyService.downloadThumbnail({
          surveyId: survey.id,
        }).subscribe((res: DownloadedFile) => {
            survey.imageToShow = URL.createObjectURL(res.getBlob());
            survey.thumbnailSrc = this.sanitizer.bypassSecurityTrustStyle(`url(${survey.imageToShow})`);
            survey.thumbnailLoaded = true;
          }
        );
      }
    });
  }

  getSurveyCount(id: number): number {
    const filtered = this.counts.filter(current => current.surveyId === id);
    if (filtered === undefined || filtered.length === 0) {
      return 0;
    }
    return filtered[0].surveyRecordCount;
  }

}

class SurveyItem {
  id: number;
  name: string;
  hasThumbnail: boolean;
  imageToShow: any | null = null;
  thumbnailLoaded: boolean = false;
  thumbnailSrc: SafeStyle;
}
