/* eslint-disable */
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FileAttachmentModel } from '../../util/file-attachment.model';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AttachmentContainer } from '../file-attachment-gallery/attachment-container';
import { UiConstants } from '../../util/core-utils';
/* eslint-enable */

@Component({
  selector: 'app-image-viewer-window',
  templateUrl: './image-viewer-window.component.html',
  styleUrls: ['./image-viewer-window.component.scss']
})
export class ImageViewerWindowComponent implements OnInit {
  UiConstants = UiConstants;

  @ViewChild('imageViewerWindow', { static: true }) imageViewerWindow: ModalDirective;
  imageViewerWindowVisible: boolean = false;

  @Input()
  attachmentView: AttachmentContainer;

  clickedImage: FileAttachmentModel;

  constructor() { }

  ngOnInit() {
  }

  openImageViewerWindow() {
    this.imageViewerWindow.show();
    this.imageViewerWindowVisible = true;
  }

  closeImageViewerWindow() {
    this.imageViewerWindowVisible = false;
    this.imageViewerWindow.hide();
  }

  getAttachedImageUrl(): string {
    if (this.clickedImage && this.clickedImage.base64dataUrl) {
      return this.clickedImage.base64dataUrl;
    }
    return '';
  }

  setClickedImage(clickedImage: FileAttachmentModel) {
    this.clickedImage = clickedImage;
  }

}
