<div class="animated fadeIn">

  <div class="card">

    <div class="card-header">
      {{'CUSTOMER_RECORD_LOCATIONS' | translate}}
      <div class="card-actions">
        <a class="btn-setting table-header-action-button d-flex align-items-center px-1 w-auto font-weight-normal"
           (click)="importContactLocation.emit()"
           *ngIf="!readonly"
           title="{{'COMMON_BUTTON_IMPORT' | translate}}">
          <i class="icomoon pr-1 icomoon-csv-import"></i>{{'COMMON_BUTTON_IMPORT' | translate}}
        </a>
        <a class="btn-setting table-header-action-button d-flex align-items-center px-1 w-auto font-weight-normal"
           (click)="createContactLocation.emit()"
           *ngIf="!readonly"
           title="{{'CUSTOMER_RECORD_CONTACT_LOCATION_CREATE' | translate}}">
          <i class="icomoon pr-1 icomoon-add"></i>{{'CUSTOMER_RECORD_CONTACT_LOCATION_CREATE' | translate}}
        </a>
      </div><!-- /.card-actions -->
    </div>

    <div class="card-body">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="CustomerRecordContactLocation.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(CustomerRecordContactLocation.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="CustomerRecordContactLocation.OrderField.NAME"
                                    [orderType]="queryModel.getOrderType(CustomerRecordContactLocation.OrderField.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="CustomerRecordContactLocation.OrderField.CONTRACT_NUMBER"
                                    [orderType]="queryModel.getOrderType(CustomerRecordContactLocation.OrderField.CONTRACT_NUMBER)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'CUSTOMER_RECORD_CONTRACT_NUMBER' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op
              [text]="'COMMON_ADDRESS' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op
              [text]="'COMMON_LOCATION_CONTACT_PERSONS' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op
              [text]="'COMMON_LOCATION_OPENING_TIME_HTML_TITLE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-field-sorter [orderField]="CustomerRecordContactLocation.OrderField.COMMENT"
                                    [orderType]="queryModel.getOrderType(CustomerRecordContactLocation.OrderField.COMMENT)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_COMMENT' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let location of locationList | paginate: {
                                                      id: pagingId,
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }">
          <td class="fit">{{location.id}}</td>
          <td class="responsive-table-column long">
            <a class="cursor-pointer" href="#" onclick="return false" (click)="detailContactLocation.emit(location)">{{location.name}}</a>
            <br>
            <span class="text-truncate table-text-subtitle">
              {{location.externalId}}
            </span>
          </td>
          <td class="responsive-table-column">
            {{location.contractNumber}}
          </td>
          <td class="responsive-table-column long">
            {{formatPostalAddress(location.postalAddress)}}
          </td>
          <td class="responsive-table-column">
            {{getContactPersons(location.contactPersons)}}
          </td>
          <td class="responsive-table-column hidden-sm-down">
            <div [popover]="openingTimesPopover" triggers="mouseenter:mouseleave" container="body">
              <div *ngFor="let line of getOpeningTimeLines(location.openingTimes)">
                <span>{{line}}</span>
              </div>
            </div>
            <ng-template #openingTimesPopover>
              <div [innerHTML]="location.openingTimes"></div>
            </ng-template>
          </td>
          <td class="responsive-table-column hidden-md-down">
            {{location.comment}}
          </td>
          <td class="align-middle">
            <app-table-options-menu>
              <app-dropdown-item
                [iconClass]="'icomoon-detail'"
                [titleStringKey]="'COMMON_BUTTON_DETAILS'"
                class="cursor-pointer"
                (click)="detailContactLocation.emit(location)">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="!readonly"
                [iconClass]="'icomoon-modify'"
                [titleStringKey]="'COMMON_BUTTON_EDIT'"
                class="cursor-pointer"
                (click)="editContactLocation.emit(location)">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="!readonly"
                [iconClass]="'icomoon-trash'"
                [titleStringKey]="'COMMON_BUTTON_REMOVE'"
                class="cursor-pointer"
                (click)="removeContactLocation(location.id)">
              </app-dropdown-item>
            </app-table-options-menu>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="CustomerRecordContactLocation.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(CustomerRecordContactLocation.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="CustomerRecordContactLocation.OrderField.NAME"
                                    [orderType]="queryModel.getOrderType(CustomerRecordContactLocation.OrderField.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="CustomerRecordContactLocation.OrderField.CONTRACT_NUMBER"
                                    [orderType]="queryModel.getOrderType(CustomerRecordContactLocation.OrderField.CONTRACT_NUMBER)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'CUSTOMER_RECORD_CONTRACT_NUMBER' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op
              [text]="'COMMON_ADDRESS' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op
              [text]="'COMMON_LOCATION_CONTACT_PERSONS' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op
              [text]="'COMMON_LOCATION_OPENING_TIME_HTML_TITLE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-field-sorter [orderField]="CustomerRecordContactLocation.OrderField.COMMENT"
                                    [orderType]="queryModel.getOrderType(CustomerRecordContactLocation.OrderField.COMMENT)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_COMMENT' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [id]="pagingId"
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>
  </div>
</div>
