/* eslint-disable */
import { Injectable } from '@angular/core';
import { EmptyMessage } from '../../util/messages';
import { Observable } from 'rxjs';
import { AddressResource } from '../../address';
import { ShippingDemandResource } from '../../shipping-demand/shipping-demand-resource.service';
import { BaseHttpService, ResourceHelper } from '../../util/http-services';
import { HttpClient } from '@angular/common/http';
import { FieldError } from '../../util/services';
import WaypointOptimizationResponse = TransportTaskResource.WaypointOptimizationResponse;

/* eslint-enable */

@Injectable()
export class TransportTaskResourceService extends BaseHttpService {

  query(request: TransportTaskResource.QueryRequest): Observable<TransportTaskResource.TransportTask[]> {
    return this.http.get<TransportTaskResource.TransportTask[]>
    (this.url + `${request.transport_id}/task-chain`, this.parseParams(request));
  }

  get(request: TransportTaskResource.GetRequest): Observable<TransportTaskResource.TransportTask> {
    return this.http.get<TransportTaskResource.TransportTask>
    (this.url + `${request.transport_id}/task-chain/${request.id}`);
  }

  create(request: TransportTaskResource.CreateRequest): Observable<EmptyMessage> {
    return this.http.post<EmptyMessage>
    (this.url + `${request.transport_id}/task-chain`, request);
  }

  moveTask(request: TransportTaskResource.MoveTaskRequest): Observable<EmptyMessage> {
    return this.http.post<EmptyMessage>
    (this.url + `${request.transport_id}/task-chain/${request.task_id}/move-task?new_index=${request.new_index}`, null);
  }

  moveShipment(request: TransportTaskResource.MoveShipmentRequest): Observable<EmptyMessage> {
    return this.http.post<EmptyMessage>
    (this.url +
      `${request.transport_id}/task-chain/${request.task_id}/move-shipment/${request.shipment_id}?new_task_id=${request.new_task_id}`,
      null
    );
  }

  deleteTask(request: TransportTaskResource.GetRequest): Observable<EmptyMessage> {
    return this.http.delete<EmptyMessage>(this.url + `${request.transport_id}/task-chain/${request.id}`);
  }

  replanRouteAfter(request: TransportTaskResource.GetRequest): Observable<EmptyMessage> {
    return this.http.post<EmptyMessage>(this.url + `${request.transport_id}/task-chain/${request.id}/replan`, null);
  }

  optimizeWaypoints(request: TransportTaskResource.WaypointOptimizationRequest): Observable<WaypointOptimizationResponse> {
    return this.http.post<WaypointOptimizationResponse>(this.url +
      `${request.transport_id}/task-chain/optimize-waypoints?from_index=${request.from_index}&to_index=${request.to_index}`, null);
  }

  updateTimetable(request: TransportTaskResource.TimetableUpdateRequest): Observable<EmptyMessage> {
    return this.http.patch<EmptyMessage>(this.url + `${request.transport_id}/task-chain/${request.id}/timetable`, request);
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, 'transports/');
  }
}


export namespace TransportTaskResource {


  export interface TransportTask {
    id: number;
    index: number;
    state: string;
    shipping_place: ShippingPlaceData;
    shipments: ShipmentData[];
    timetable: TimetableData;
  }

  export interface ShippingPlaceData {
    id: number;
    place_id: number;
    name: string;
    postal_address: AddressResource.PostalAddressResource;
    coordinate?: AddressResource.CoordinateResource;
    geocode_status: string;
    stock_id?: number;
    location_id?: number;
  }

  export interface ShipmentData {
    id: number;
    state: string;
    delivery_note_number: string;
    demander_company?: CompanyData;
    outtake_id?: number;
    ekaers?: string[];
    recipient?: PersonData;
    transferor?: PersonData;
    type: string;
  }

  export interface PersonData {
    name: string;
    phone_numbers: string[];
  }

  export interface CompanyData {
    name: string;
    phone_number: string;
  }

  export interface TimetableData {
    planned_estimated_time_of_arrival?: string;
    planned_estimated_time_of_departure?: string;
    current_estimated_time_of_arrival?: string;
    current_estimated_time_of_departure?: string;
    real_arrival_time?: string;
    real_departure_time?: string;
    delay_minutes?: number;
  }

  export interface QueryRequest {
    transport_id: number;
  }

  export interface GetRequest {
    transport_id: number;
    id: number;
  }

  export interface CreateRequest {
    transport_id: number;
    index: number;
    shipping_place: ShippingDemandResource.SourceDestination;
  }

  export interface MoveTaskRequest {
    transport_id: number;
    task_id: number;
    new_index: number;
  }

  export interface WaypointOptimizationRequest {
    transport_id: number;
    from_index: number;
    to_index: number;
  }

  export interface WaypointOptimizationResponse {
    duration_sec: number;
    distance_meter: number;
    polyline: string;
    consistency_error_messages?: Map<string, FieldError>;
  }

  export interface MoveShipmentRequest {
    transport_id: number;
    task_id: number;
    shipment_id: number;
    new_task_id: number;
  }

  export interface TimetableUpdateRequest {
    transport_id: number;
    id: number;
    planned_estimated_time_of_arrival: string;
    planned_estimated_time_of_departure: string;
  }
}
