import { Map } from 'immutable';

export namespace TimeZones {

  const COUNTRY_CODES_BY_TZ: Map<string, string> = Map.of(
    'Asia/Kabul', 'AF',
    'Europe/Mariehamn', 'AX',
    'Europe/Tirane', 'AL',
    'Africa/Algiers', 'DZ',
    'Pacific/Pago_Pago', 'AS',
    'Europe/Andorra', 'AD',
    'Africa/Luanda', 'AO',
    'America/Anguilla', 'AI',
    'Antarctica/Casey', 'AQ',
    'Antarctica/Davis', 'AQ',
    'Antarctica/DumontDUrville', 'AQ',
    'Antarctica/Mawson', 'AQ',
    'Antarctica/McMurdo', 'AQ',
    'Antarctica/Palmer', 'AQ',
    'Antarctica/Rothera', 'AQ',
    'Antarctica/Syowa', 'AQ',
    'Antarctica/Troll', 'AQ',
    'Antarctica/Vostok', 'AQ',
    'America/Antigua', 'AG',
    'America/Argentina/Buenos_Aires', 'AR',
    'America/Argentina/Catamarca', 'AR',
    'America/Argentina/Cordoba', 'AR',
    'America/Argentina/Jujuy', 'AR',
    'America/Argentina/La_Rioja', 'AR',
    'America/Argentina/Mendoza', 'AR',
    'America/Argentina/Rio_Gallegos', 'AR',
    'America/Argentina/Salta', 'AR',
    'America/Argentina/San_Juan', 'AR',
    'America/Argentina/San_Luis', 'AR',
    'America/Argentina/Tucuman', 'AR',
    'America/Argentina/Ushuaia', 'AR',
    'Asia/Yerevan', 'AM',
    'America/Aruba', 'AW',
    'Antarctica/Macquarie', 'AU',
    'Australia/Adelaide', 'AU',
    'Australia/Brisbane', 'AU',
    'Australia/Broken_Hill', 'AU',
    'Australia/Currie', 'AU',
    'Australia/Darwin', 'AU',
    'Australia/Eucla', 'AU',
    'Australia/Hobart', 'AU',
    'Australia/Lindeman', 'AU',
    'Australia/Lord_Howe', 'AU',
    'Australia/Melbourne', 'AU',
    'Australia/Perth', 'AU',
    'Australia/Sydney', 'AU',
    'Europe/Vienna', 'AT',
    'Asia/Baku', 'AZ',
    'America/Nassau', 'BS',
    'Asia/Bahrain', 'BH',
    'Asia/Dhaka', 'BD',
    'America/Barbados', 'BB',
    'Europe/Minsk', 'BY',
    'Europe/Brussels', 'BE',
    'America/Belize', 'BZ',
    'Africa/Porto-Novo', 'BJ',
    'Atlantic/Bermuda', 'BM',
    'Asia/Thimphu', 'BT',
    'America/La_Paz', 'BO',
    'America/Kralendijk', 'BQ',
    'Europe/Sarajevo', 'BA',
    'Africa/Gaborone', 'BW',
    'America/Araguaina', 'BR',
    'America/Bahia', 'BR',
    'America/Belem', 'BR',
    'America/Boa_Vista', 'BR',
    'America/Campo_Grande', 'BR',
    'America/Cuiaba', 'BR',
    'America/Eirunepe', 'BR',
    'America/Fortaleza', 'BR',
    'America/Maceio', 'BR',
    'America/Manaus', 'BR',
    'America/Noronha', 'BR',
    'America/Porto_Velho', 'BR',
    'America/Recife', 'BR',
    'America/Rio_Branco', 'BR',
    'America/Santarem', 'BR',
    'America/Sao_Paulo', 'BR',
    'Indian/Chagos', 'IO',
    'America/Tortola', 'VG',
    'Asia/Brunei', 'BN',
    'Europe/Sofia', 'BG',
    'Africa/Ouagadougou', 'BF',
    'Africa/Bujumbura', 'BI',
    'Asia/Phnom_Penh', 'KH',
    'Africa/Douala', 'CM',
    'America/Atikokan', 'CA',
    'America/Blanc-Sablon', 'CA',
    'America/Cambridge_Bay', 'CA',
    'America/Creston', 'CA',
    'America/Dawson', 'CA',
    'America/Dawson_Creek', 'CA',
    'America/Edmonton', 'CA',
    'America/Fort_Nelson', 'CA',
    'America/Glace_Bay', 'CA',
    'America/Goose_Bay', 'CA',
    'America/Halifax', 'CA',
    'America/Inuvik', 'CA',
    'America/Iqaluit', 'CA',
    'America/Moncton', 'CA',
    'America/Nipigon', 'CA',
    'America/Pangnirtung', 'CA',
    'America/Rainy_River', 'CA',
    'America/Rankin_Inlet', 'CA',
    'America/Regina', 'CA',
    'America/Resolute', 'CA',
    'America/St_Johns', 'CA',
    'America/Swift_Current', 'CA',
    'America/Thunder_Bay', 'CA',
    'America/Toronto', 'CA',
    'America/Vancouver', 'CA',
    'America/Whitehorse', 'CA',
    'America/Winnipeg', 'CA',
    'America/Yellowknife', 'CA',
    'Atlantic/Cape_Verde', 'CV',
    'America/Cayman', 'KY',
    'Africa/Bangui', 'CF',
    'Africa/Ndjamena', 'TD',
    'America/Punta_Arenas', 'CL',
    'America/Santiago', 'CL',
    'Pacific/Easter', 'CL',
    'Asia/Shanghai', 'CN',
    'Asia/Urumqi', 'CN',
    'Indian/Christmas', 'CX',
    'Indian/Cocos', 'CC',
    'America/Bogota', 'CO',
    'Indian/Comoro', 'KM',
    'Pacific/Rarotonga', 'CK',
    'America/Costa_Rica', 'CR',
    'Europe/Zagreb', 'HR',
    'America/Havana', 'CU',
    'America/Curacao', 'CW',
    'Asia/Famagusta', 'CY',
    'Asia/Nicosia', 'CY',
    'Europe/Prague', 'CZ',
    'Africa/Kinshasa', 'CD',
    'Africa/Lubumbashi', 'CD',
    'Europe/Copenhagen', 'DK',
    'Africa/Djibouti', 'DJ',
    'America/Dominica', 'DM',
    'America/Santo_Domingo', 'DO',
    'Asia/Dili', 'TL',
    'America/Guayaquil', 'EC',
    'Pacific/Galapagos', 'EC',
    'Africa/Cairo', 'EG',
    'America/El_Salvador', 'SV',
    'Africa/Malabo', 'GQ',
    'Africa/Asmara', 'ER',
    'Europe/Tallinn', 'EE',
    'Africa/Addis_Ababa', 'ET',
    'Atlantic/Stanley', 'FK',
    'Atlantic/Faroe', 'FO',
    'Pacific/Fiji', 'FJ',
    'Europe/Helsinki', 'FI',
    'Europe/Paris', 'FR',
    'America/Cayenne', 'GF',
    'Pacific/Gambier', 'PF',
    'Pacific/Marquesas', 'PF',
    'Pacific/Tahiti', 'PF',
    'Indian/Kerguelen', 'TF',
    'Africa/Libreville', 'GA',
    'Africa/Banjul', 'GM',
    'Asia/Tbilisi', 'GE',
    'Europe/Berlin', 'DE',
    'Europe/Busingen', 'DE',
    'Africa/Accra', 'GH',
    'Europe/Gibraltar', 'GI',
    'Europe/Athens', 'GR',
    'America/Danmarkshavn', 'GL',
    'America/Godthab', 'GL',
    'America/Scoresbysund', 'GL',
    'America/Thule', 'GL',
    'America/Grenada', 'GD',
    'America/Guadeloupe', 'GP',
    'Pacific/Guam', 'GU',
    'America/Guatemala', 'GT',
    'Europe/Guernsey', 'GG',
    'Africa/Conakry', 'GN',
    'Africa/Bissau', 'GW',
    'America/Guyana', 'GY',
    'America/Port-au-Prince', 'HT',
    'America/Tegucigalpa', 'HN',
    'Asia/Hong_Kong', 'HK',
    'Europe/Budapest', 'HU',
    'Atlantic/Reykjavik', 'IS',
    'Asia/Kolkata', 'IN',
    'Asia/Jakarta', 'ID',
    'Asia/Jayapura', 'ID',
    'Asia/Makassar', 'ID',
    'Asia/Pontianak', 'ID',
    'Asia/Tehran', 'IR',
    'Asia/Baghdad', 'IQ',
    'Europe/Dublin', 'IE',
    'Europe/Isle_of_Man', 'IM',
    'Asia/Jerusalem', 'IL',
    'Europe/Rome', 'IT',
    'Africa/Abidjan', 'CI',
    'America/Jamaica', 'JM',
    'Asia/Tokyo', 'JP',
    'Europe/Jersey', 'JE',
    'Asia/Amman', 'JO',
    'Asia/Almaty', 'KZ',
    'Asia/Aqtau', 'KZ',
    'Asia/Aqtobe', 'KZ',
    'Asia/Atyrau', 'KZ',
    'Asia/Oral', 'KZ',
    'Asia/Qostanay', 'KZ',
    'Asia/Qyzylorda', 'KZ',
    'Africa/Nairobi', 'KE',
    'Pacific/Enderbury', 'KI',
    'Pacific/Kiritimati', 'KI',
    'Pacific/Tarawa', 'KI',
    'Asia/Kuwait', 'KW',
    'Asia/Bishkek', 'KG',
    'Asia/Vientiane', 'LA',
    'Europe/Riga', 'LV',
    'Asia/Beirut', 'LB',
    'Africa/Maseru', 'LS',
    'Africa/Monrovia', 'LR',
    'Africa/Tripoli', 'LY',
    'Europe/Vaduz', 'LI',
    'Europe/Vilnius', 'LT',
    'Europe/Luxembourg', 'LU',
    'Asia/Macau', 'MO',
    'Europe/Skopje', 'MK',
    'Indian/Antananarivo', 'MG',
    'Africa/Blantyre', 'MW',
    'Asia/Kuala_Lumpur', 'MY',
    'Asia/Kuching', 'MY',
    'Indian/Maldives', 'MV',
    'Africa/Bamako', 'ML',
    'Europe/Malta', 'MT',
    'Pacific/Kwajalein', 'MH',
    'Pacific/Majuro', 'MH',
    'America/Martinique', 'MQ',
    'Africa/Nouakchott', 'MR',
    'Indian/Mauritius', 'MU',
    'Indian/Mayotte', 'YT',
    'America/Bahia_Banderas', 'MX',
    'America/Cancun', 'MX',
    'America/Chihuahua', 'MX',
    'America/Hermosillo', 'MX',
    'America/Matamoros', 'MX',
    'America/Mazatlan', 'MX',
    'America/Merida', 'MX',
    'America/Mexico_City', 'MX',
    'America/Monterrey', 'MX',
    'America/Ojinaga', 'MX',
    'America/Tijuana', 'MX',
    'Pacific/Chuuk', 'FM',
    'Pacific/Kosrae', 'FM',
    'Pacific/Pohnpei', 'FM',
    'Europe/Chisinau', 'MD',
    'Europe/Monaco', 'MC',
    'Asia/Choibalsan', 'MN',
    'Asia/Hovd', 'MN',
    'Asia/Ulaanbaatar', 'MN',
    'Europe/Podgorica', 'ME',
    'America/Montserrat', 'MS',
    'Africa/Casablanca', 'MA',
    'Africa/Maputo', 'MZ',
    'Asia/Yangon', 'MM',
    'Africa/Windhoek', 'NA',
    'Pacific/Nauru', 'NR',
    'Asia/Kathmandu', 'NP',
    'Europe/Amsterdam', 'NL',
    'Pacific/Noumea', 'NC',
    'Pacific/Auckland', 'NZ',
    'Pacific/Chatham', 'NZ',
    'America/Managua', 'NI',
    'Africa/Niamey', 'NE',
    'Africa/Lagos', 'NG',
    'Pacific/Niue', 'NU',
    'Pacific/Norfolk', 'NF',
    'Asia/Pyongyang', 'KP',
    'Pacific/Saipan', 'MP',
    'Europe/Oslo', 'NO',
    'Asia/Muscat', 'OM',
    'Asia/Karachi', 'PK',
    'Pacific/Palau', 'PW',
    'Asia/Gaza', 'PS',
    'Asia/Hebron', 'PS',
    'America/Panama', 'PA',
    'Pacific/Bougainville', 'PG',
    'Pacific/Port_Moresby', 'PG',
    'America/Asuncion', 'PY',
    'America/Lima', 'PE',
    'Asia/Manila', 'PH',
    'Pacific/Pitcairn', 'PN',
    'Europe/Warsaw', 'PL',
    'Atlantic/Azores', 'PT',
    'Atlantic/Madeira', 'PT',
    'Europe/Lisbon', 'PT',
    'America/Puerto_Rico', 'PR',
    'Asia/Qatar', 'QA',
    'Africa/Brazzaville', 'CG',
    'Indian/Reunion', 'RE',
    'Europe/Bucharest', 'RO',
    'Asia/Anadyr', 'RU',
    'Asia/Barnaul', 'RU',
    'Asia/Chita', 'RU',
    'Asia/Irkutsk', 'RU',
    'Asia/Kamchatka', 'RU',
    'Asia/Khandyga', 'RU',
    'Asia/Krasnoyarsk', 'RU',
    'Asia/Magadan', 'RU',
    'Asia/Novokuznetsk', 'RU',
    'Asia/Novosibirsk', 'RU',
    'Asia/Omsk', 'RU',
    'Asia/Sakhalin', 'RU',
    'Asia/Srednekolymsk', 'RU',
    'Asia/Tomsk', 'RU',
    'Asia/Ust-Nera', 'RU',
    'Asia/Vladivostok', 'RU',
    'Asia/Yakutsk', 'RU',
    'Asia/Yekaterinburg', 'RU',
    'Europe/Astrakhan', 'RU',
    'Europe/Kaliningrad', 'RU',
    'Europe/Kirov', 'RU',
    'Europe/Moscow', 'RU',
    'Europe/Samara', 'RU',
    'Europe/Saratov', 'RU',
    'Europe/Ulyanovsk', 'RU',
    'Europe/Volgograd', 'RU',
    'Africa/Kigali', 'RW',
    'America/St_Barthelemy', 'BL',
    'Atlantic/St_Helena', 'SH',
    'America/St_Kitts', 'KN',
    'America/St_Lucia', 'LC',
    'America/Marigot', 'MF',
    'America/Miquelon', 'PM',
    'America/St_Vincent', 'VC',
    'Pacific/Apia', 'WS',
    'Europe/San_Marino', 'SM',
    'Africa/Sao_Tome', 'ST',
    'Asia/Riyadh', 'SA',
    'Africa/Dakar', 'SN',
    'Europe/Belgrade', 'RS',
    'Indian/Mahe', 'SC',
    'Africa/Freetown', 'SL',
    'Asia/Singapore', 'SG',
    'America/Lower_Princes', 'SX',
    'Europe/Bratislava', 'SK',
    'Europe/Ljubljana', 'SI',
    'Pacific/Guadalcanal', 'SB',
    'Africa/Mogadishu', 'SO',
    'Africa/Johannesburg', 'ZA',
    'Atlantic/South_Georgia', 'GS',
    'Asia/Seoul', 'KR',
    'Africa/Juba', 'SS',
    'Africa/Ceuta', 'ES',
    'Atlantic/Canary', 'ES',
    'Europe/Madrid', 'ES',
    'Asia/Colombo', 'LK',
    'Africa/Khartoum', 'SD',
    'America/Paramaribo', 'SR',
    'Arctic/Longyearbyen', 'SJ',
    'Africa/Mbabane', 'SZ',
    'Europe/Stockholm', 'SE',
    'Europe/Zurich', 'CH',
    'Asia/Damascus', 'SY',
    'Asia/Taipei', 'TW',
    'Asia/Dushanbe', 'TJ',
    'Africa/Dar_es_Salaam', 'TZ',
    'Asia/Bangkok', 'TH',
    'Africa/Lome', 'TG',
    'Pacific/Fakaofo', 'TK',
    'Pacific/Tongatapu', 'TO',
    'America/Port_of_Spain', 'TT',
    'Africa/Tunis', 'TN',
    'Europe/Istanbul', 'TR',
    'Asia/Ashgabat', 'TM',
    'America/Grand_Turk', 'TC',
    'Pacific/Funafuti', 'TV',
    'America/St_Thomas', 'VI',
    'Africa/Kampala', 'UG',
    'Europe/Kiev', 'UA',
    'Europe/Simferopol', 'UA',
    'Europe/Uzhgorod', 'UA',
    'Europe/Zaporozhye', 'UA',
    'Asia/Dubai', 'AE',
    'Europe/London', 'GB',
    'America/Adak', 'US',
    'America/Anchorage', 'US',
    'America/Boise', 'US',
    'America/Chicago', 'US',
    'America/Denver', 'US',
    'America/Detroit', 'US',
    'America/Indiana/Indianapolis', 'US',
    'America/Indiana/Knox', 'US',
    'America/Indiana/Marengo', 'US',
    'America/Indiana/Petersburg', 'US',
    'America/Indiana/Tell_City', 'US',
    'America/Indiana/Vevay', 'US',
    'America/Indiana/Vincennes', 'US',
    'America/Indiana/Winamac', 'US',
    'America/Juneau', 'US',
    'America/Kentucky/Louisville', 'US',
    'America/Kentucky/Monticello', 'US',
    'America/Los_Angeles', 'US',
    'America/Menominee', 'US',
    'America/Metlakatla', 'US',
    'America/New_York', 'US',
    'America/Nome', 'US',
    'America/North_Dakota/Beulah', 'US',
    'America/North_Dakota/Center', 'US',
    'America/North_Dakota/New_Salem', 'US',
    'America/Phoenix', 'US',
    'America/Sitka', 'US',
    'America/Yakutat', 'US',
    'Pacific/Honolulu', 'US',
    'Pacific/Midway', 'UM',
    'Pacific/Wake', 'UM',
    'America/Montevideo', 'UY',
    'Asia/Samarkand', 'UZ',
    'Asia/Tashkent', 'UZ',
    'Pacific/Efate', 'VU',
    'Europe/Vatican', 'VA',
    'America/Caracas', 'VE',
    'Asia/Ho_Chi_Minh', 'VN',
    'Pacific/Wallis', 'WF',
    'Africa/El_Aaiun', 'EH',
    'Asia/Aden', 'YE',
    'Africa/Lusaka', 'ZM',
    'Africa/Harare', 'ZW'
  );

  export function timeZoneToCountryCode(timeZone: string): string | undefined {
    return COUNTRY_CODES_BY_TZ.get(timeZone);
  }

}
