import { Component, OnInit } from '@angular/core';
import { Board } from './models/board.model';
import { Column } from './models/column.model';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { BreadcrumbParent } from '../../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { TranslateService } from '@ngx-translate/core';
import { TaskRecordService } from '../../../../lib/task/task-record.service';
import { Set } from 'immutable';
import { TaskRecordBoardModel } from './models/task-record-board.model';
import { TaskRecordStateMachine } from '../../../../lib/task/task-record-statemachine';
import { StringKey } from '../../../../app.string-keys';
import { UiConstants } from '../../../../util/core-utils';
import { ToasterService } from '../../../../fork/angular2-toaster/src/toaster.service';
import { ConfigModel } from '../../../../util/task-record-utils';
import { ConfigurationService } from '../../../../lib/core-ext/configuration.service';
import { TaskColumn } from './models/task.column.model';

@Component({
  selector: 'app-task-record-board',
  templateUrl: './task-record-board.component.html',
  styleUrls: ['./task-record-board.component.scss']
})
export class TaskRecordBoardComponent implements OnInit {

  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');


  public board: Board<TaskRecordBoardModel> = new Board<TaskRecordBoardModel>('Test Board', []);

  configModel: ConfigModel = new ConfigModel();

  constructor(private translateService: TranslateService,
              private taskRecordService: TaskRecordService,
              private toasterService: ToasterService,
              private configService: ConfigurationService) {
    this.configModel = configService.getConfigurationModel();
    this.board.columns = [
      new TaskColumn('NEW', ['NEW', 'RECALLED', 'REJECTED'], this.configModel, this.taskRecordService),
      new TaskColumn('OPEN', ['OPEN'], this.configModel, this.taskRecordService),
      new TaskColumn('IN_PROGRESS', ['IN_PROGRESS', 'PAUSED', 'PENDING_APPROVAL'], this.configModel, this.taskRecordService),
      new TaskColumn('SUBMITTED', ['SUBMITTED'], this.configModel, this.taskRecordService),
      new TaskColumn('NEW_FINISHED', ['NEW_FINISHED', 'FINISHED'], this.configModel, this.taskRecordService),
      // TODO find an other solution for the last column's label when revert is implemented
    ];
  }

  public ngOnInit(): void {
    this.translateService.get('MENU_NAVBAR_TASK_RECORD_BOARD').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.board.columns.forEach(c => c.loadItems());
  }

  public drop(event: CdkDragDrop<TaskRecordBoardModel[]>): void {
    if (event.previousContainer === event.container) {
      // do nothing
    }
    else {
      const fromColumn: Column<TaskRecordBoardModel> = this.board.getColumn(event.previousContainer.id)!;
      const toColumn: TaskColumn = (<TaskColumn>this.board.getColumn(event.container.id))!;
      const taskRecord = event.previousContainer.data[event.previousIndex];
      const toStates = TaskRecordStateMachine.StateMachine.getAvailableStatesFromState(taskRecord.state.state).filter(s => toColumn.stateSet.contains(s));
      if (toStates.length !== 0) {
        const firstState = toStates.shift()!;
        this.tryStateChange(taskRecord, firstState, toStates, fromColumn, toColumn);
      }
    }
  }

  getAvailableColumnIds(id: string): string[] {
    return this.board.columns.map(c => c.id);
  }

  private tryStateChange(taskRecord: TaskRecordBoardModel, toState: TaskRecordStateMachine.State, toStates: TaskRecordStateMachine.State[],
                         fromColumn: Column<TaskRecordBoardModel>, toColumn: Column<TaskRecordBoardModel>) {
    this.taskRecordService.checkState({
      query: {taskRecordIdSet: Set.of(taskRecord.taskRecordId)},
      stateChange: TaskRecordStateMachine.taskRecordStates.get(toState)
    }).subscribe((result: Set<number>) => {
        if (result.size > 0) {
          this.taskRecordService.setState({
            newState: TaskRecordStateMachine.taskRecordStates.get(toState),
            taskRecordIdSet: result,
            stateChangeMessage: undefined,
            checkUpDate: undefined
          }).subscribe(value => {
            fromColumn.reloadItems();
            toColumn.reloadItems();
          }, error => {
            if (toStates.length === 0) {
              this.toasterService.pop({
                timeout: UiConstants.ToastTimeoutLong,
                type: UiConstants.toastTypeError,
                title: this.translateService.instant(StringKey.TASKS_STATE_MODIFY),
                body: this.translateService.instant(StringKey.TASK_STATE_CHANGE_ERROR)
              });
            }
            else {
              this.tryStateChange(taskRecord, toStates.shift()!, toStates, fromColumn, toColumn);
            }
          })
        }
        else {
          if (toStates.length === 0) {
            this.toasterService.pop({
              timeout: UiConstants.ToastTimeoutLong,
              type: UiConstants.toastTypeError,
              title: this.translateService.instant(StringKey.TASKS_STATE_MODIFY),
              body: this.translateService.instant(StringKey.TASK_STATE_CHANGE_ERROR)
            });
          }
          else {
            this.tryStateChange(taskRecord, toStates.shift()!, toStates, fromColumn, toColumn);
          }
        }
      },
      (error: any) => {

      });
  }
}
