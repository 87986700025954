/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  BaseHttpService,
  EchoHeader,
  EchoHeaderBuilder,
  HeaderKeys,
  HeaderValues,
  ResourceHelper,
} from '../../util/http-services';
import { EmptyMessage } from '../../util/messages';
/* eslint-enable */

@Injectable()
export class RegistrationSettingsResourceService extends BaseHttpService {

  get(request: RegistrationSettingsResource.GetRequest): Observable<RegistrationSettingsResource.RegistrationSettings> {
    const echoHeader: EchoHeader = new EchoHeaderBuilder().ignoreEntityNotFound(true).build();
    const headers = new HttpHeaders().set(HeaderKeys.ECHO, HeaderValues.serializeEchoHeader(echoHeader));
    return this.http.get<RegistrationSettingsResource.RegistrationSettings>(this.url + `${request.type}`, {headers: headers});
  }

  update(request: RegistrationSettingsResource.UpdateRequest): Observable<EmptyMessage> {
    return this.http.put<EmptyMessage>(this.url + `${request.type}`, request);
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, 'registration/settings/');
  }

}

export namespace RegistrationSettingsResource {

  export interface RegistrationSettings {
    notification_email_template: number;
    response_email_template: number;
    notification_email_addresses: string[];
    default_role_id?: number;
    default_user_group_id?: number;
    user_group_settings?: UserGroupSettings[];
  }

  export interface UserGroupSettings {
    role_ids: number[];
    application_types: string[];
    name_template: string;
  }

  export interface GetRequest {
    type: string;
  }

  export interface UpdateRequest extends GetRequest {
    notification_email_template: number;
    response_email_template: number;
    notification_email_addresses: string[];
    default_role_id?: number;
    default_user_group_id?: number;
    user_group_settings?: UserGroupSettings[];
  }

}
