<ng-container *ngIf="anyChildVisible()">
  <a class="icons-table-group cursor-pointer"
     title="{{'COMMON_BUTTON_DETAILS' | translate}}"
     dropdown href="#" onclick="return false"
     dropdownToggle container="body"
     data-toggle="dropdown" role="button"
     aria-haspopup="true" aria-expanded="false">
    <i class="icomoon icons-table-item icomoon-about icomoon-detail-table"></i>
    <div class="dropdown-menu dropdown-menu-custom border-bottom-0"
         *dropdownMenu aria-labelledby="simple-dropdown">
      <ng-content></ng-content>
    </div>
  </a>
</ng-container>
