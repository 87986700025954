export class FileAttachments {

  public static toPublic(attachment: FileAttachmentResource): FileAttachment {
    const a = new FileAttachment();

    a.id = attachment.id;
    a.fileName = attachment.file_name;
    a.fileExtension = attachment.file_extension;
    a.originalFileName = attachment.original_file_name;
    a.contentHash = attachment.content_hash;
    a.contentType = attachment.content_type;
    a.contentUpdateTime = attachment.content_update_time;
    a.creationTime = attachment.creation_time;
    a.ownerUser = attachment.owner_user ? {
      id: attachment.owner_user.id,
      personName: attachment.owner_user.person_name
    } : undefined;
    return a;
  }

  public static toPublicList(attachments: FileAttachmentResource[]): FileAttachment[] {
    const list: FileAttachment[] = [];
    attachments.forEach((a) => {
      list.push(this.toPublic(a));
    });
    return list;
  }

}

export class FileAttachment {
  id: number;
  fileName?: string;
  originalFileName: string;
  fileExtension?: string;
  contentHash?: string;
  contentType?: string;
  contentUpdateTime?: string;
  creationTime?: string;
  ownerUser?: OwnerUser;
  hasDeleteRight?: boolean;

  get fullFileName(): string {
    return `${this.fileName ? this.fileName : this.originalFileName}.${this.fileExtension}`;
  }

  get fullOriginalFileName(): string {
    return `${this.originalFileName}.${this.fileExtension}`;
  }
}

export interface FileAttachmentResource {
  id: number;
  file_name?: string;
  file_extension?: string;
  original_file_name: string;
  content_hash?: string;
  content_type?: string;
  content_update_time?: string;
  creation_time?: string;
  owner_user?: OwnerUserResource;
}

export interface FileAttachmentUpdateRequest {
  name: string;
  fileId: number;
}

export interface OwnerUser {
  id: number;
  personName: string;
}

export interface OwnerUserResource {
  id: number;
  person_name: string;
}
