import { Observable, Observer } from 'rxjs';
import { MemStorage } from './util/mem-storage';
import { OrderFieldModel } from '../util/core-utils';
import { Order } from './util/services';

export abstract class NewBaseSearchService<T> {

  getSearchData(request: NewBaseSearch.SearchDataGetRequest): Observable<NewBaseSearch.SearchDataResult<T>> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<NewBaseSearch.SearchDataResult<T>>) => {
      try {
        observer.next(storage.read(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  setSearchData<R extends NewBaseSearch.SearchDataSetRequest<T>>(request: R): Observable<NewBaseSearch.SearchDataResult<T>> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<NewBaseSearch.SearchDataResult<T>>) => {
      try {
        observer.next(storage.save(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  resetSearchData(request: NewBaseSearch.SearchDataGetRequest): Observable<NewBaseSearch.SearchDataResult<T>> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<NewBaseSearch.SearchDataResult<T>>) => {
      try {
        observer.next(storage.reset(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  protected abstract createStorage(): NewBaseSearch.Storage<T>;

}



export namespace NewBaseSearch {

  export interface SearchDataGetRequest {
  }

  export interface SearchDataSetRequest<T> {
    searchData: SearchData<T>;
  }

  export interface SearchDataResult<T> {
    searchData: SearchData<T>;
  }

  export interface SearchData<T> {
    queryModel: OrderFieldModel<T>;
  }

  interface StoredSearchRawDataBase {
    version: number;
  }

  export interface StoredSearchRawData<T> extends StoredSearchRawDataBase {
    queryModel: OrderFieldModel<T>;
    order: Order<string>;
  }

  export abstract class Storage<T> {

    /**
     * Increment this version if you change the SearchData interface.
     */
    protected readonly VERSION: number;

    protected readonly DEFAULT_SEARCH_DATA: SearchData<T>;

    constructor(defaultSearchData: SearchData<T>, versionNumber: number) {
      this.DEFAULT_SEARCH_DATA = defaultSearchData;
      this.VERSION = versionNumber;
    }

    public read(request: SearchDataGetRequest): SearchDataResult<T> {
      let rawBase: StoredSearchRawDataBase;
      try {
        const key = this.generateKey(request);
        const json = MemStorage.getItem(key);
        if (!json) {
          return this.createDefaultResult();
        }
        rawBase = JSON.parse(json);
      }
      catch (error) {
        return this.createDefaultResult();
      }
      if (this.VERSION === rawBase.version) {
        const raw: StoredSearchRawData<T> = <StoredSearchRawData<T>>rawBase;
        return this.fromRaw(raw);
      }
      return this.createDefaultResult();
    }

    public reset(request: SearchDataSetRequest<T> | SearchDataGetRequest): SearchDataResult<T> {
      return this.save(request, true);
    }

    public save(request: SearchDataSetRequest<T> | SearchDataGetRequest, reset?: boolean): SearchDataResult<T> {
      let raw: StoredSearchRawData<T>;
      if (reset) {
        raw = this.toRaw(this.DEFAULT_SEARCH_DATA);
      }
      else {
        try {
          raw = this.toRaw((<SearchDataSetRequest<T>> request).searchData);
        }
        catch (error) {
          raw = this.toRaw(this.DEFAULT_SEARCH_DATA);
        }
      }
      const key = this.generateKey(request);
      const json = JSON.stringify(raw);
      MemStorage.setItem(key, json);
      return this.read(request);
    }

    protected abstract generateKey(request: SearchDataGetRequest | SearchDataSetRequest<T>): string;

    private createDefaultResult(): SearchDataResult<T> {
      return {
        searchData: this.DEFAULT_SEARCH_DATA,
      };
    }

    protected abstract fromRaw(data: StoredSearchRawData<T>): SearchDataResult<T>;

    protected abstract toRaw(data: SearchData<T>): StoredSearchRawData<T>;

  }

}
