<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">
  <responsive-tabset class="fullscreen_tab">
    <responsive-tab heading="{{getRegistrationTypeKey()|translate}}" *ngIf="isRegistrationEnabled">
      <app-registration-settings
        [registrationType]="configuration.feature_flags.registration_enabled">
      </app-registration-settings>
    </responsive-tab>
    <responsive-tab heading="{{'COMMON_HELPDESK'|translate}}" *ngIf="configuration.feature_flags.helpdesk_enabled">
      <app-registration-settings
        [registrationType]="RegistrationType.HELPDESK">
      </app-registration-settings>
    </responsive-tab>
  </responsive-tabset>
</div>
