/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ShippingDemandResource } from '../shipping-demand/shipping-demand-resource.service';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { ResourceQueryResult } from '../util/services';
import { EmptyMessage, IdentityMessage } from '../util/messages';
import { Shipment, Shipment as ShipmentNameSpace } from './/shipment-group.service';
import { DqlStoredQueryResourceService } from '../dql/dql-stored-query-resource.service';
import { DqlResourceModel } from '../dql/dql-resource.model';
import { GeneralDqlStoredQueryResourceService } from '../dql/general-dql-stored-query-resource.service';
import { Cacheable } from 'ts-cacheable';
import { UiConstants } from '../../util/core-utils';
import { TransportLogResource } from '../transport/transport-log/transport-log-resource.service';
/* eslint-enable */

@Injectable()
export class ShipmentGroupResourceService extends BaseHttpService {

  private readonly _dqlStoredResourceService: DqlStoredQueryResourceService;

  globalQuery(request: ShipmentGroupResource.GlobalQueryRequest): Observable<ResourceQueryResult<ShipmentGroupResource.ShipmentGroup>> {
    return this.http.get<ResourceQueryResult<ShipmentGroupResource.ShipmentGroup>>(this.url, this.parseParams(request));
  }

  get(request: IdentityMessage): Observable<ShipmentGroupResource.ShipmentGroup> {
    return this.http.get<ShipmentGroupResource.ShipmentGroup>(this.url + `${request.id}`);
  }

  createByDemands(request: ShipmentGroupResource.CreateByDemandsRequest): Observable<ShipmentGroupResource.CreateByDemandsResponse> {
    return this.http.post<ShipmentGroupResource.CreateByDemandsResponse>(this.url, request);
  }

  removeDemand(request: ShipmentGroupResource.RemoveDemandRequest): Observable<ShipmentGroupResource.RemoveDemandResponse> {
    return this.http.delete<ShipmentGroupResource.RemoveDemandResponse>(this.url + `${request.shipment_group_id}/${request.demand_id}`);
  }

  getDepots(request: IdentityMessage): Observable<ShipmentGroupResource.Depot[]> {
    return this.http.get<ShipmentGroupResource.Depot[]>(this.url + `${request.id}/depots`);
  }

  createDepot(request: ShipmentGroupResource.DepotCreateRequest): Observable<IdentityMessage> {
    return this.http.post<IdentityMessage>(this.url + `${request.shipment_group_id}/depots`, request);
  }

  deleteDepot(request: ShipmentGroupResource.DepotDeleteRequest): Observable<EmptyMessage> {
    return this.http.delete(this.url + `${request.shipment_group_id}/depots/${request.id}`);
  }

  rearrangeDepos(request: ShipmentGroupResource.DepotRearrangeRequest): Observable<EmptyMessage> {
    return this.http.put(this.url + `${request.shipment_group_id}/depots`, request);
  }

  @Cacheable({
    maxAge: UiConstants.defaultCacheMaxAge,
    slidingExpiration: true
  })
  getDqlModel(): Observable<DqlResourceModel.QueryableModel> {
    return this.http.get<DqlResourceModel.QueryableModel>(this.url + `dql-model`);
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/shipment-groups/');
    this._dqlStoredResourceService = new GeneralDqlStoredQueryResourceService(http, resourceHelper, 'SHIPMENT');
  }

  get dqlStoredResourceService(): DqlStoredQueryResourceService {
    return this._dqlStoredResourceService;
  }

}

@Injectable()
export class ShipmentResourceService extends BaseHttpService {

  changeSubtransporter(request: ShipmentResource.ChangeSubtransporterRequest): Observable<EmptyMessage> {
    return this.http.post(this.url + `sub-transporter`, request);
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/shipments/');
  }
}

@Injectable()
export class ShipmentGroupTransportResourceService extends BaseHttpService {

  query(request: ShipmentGroupResource.QueryRequest): Observable<ResourceQueryResult<ShipmentGroupResource.ShipmentGroup>> {
    return this.http.get<ResourceQueryResult<ShipmentGroupResource.ShipmentGroup>>(this.url + `${request.transport_id}/shipment-groups`);
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/transports/');
  }
}

@Injectable()
export class ShipmentOuttakeResourceService extends BaseHttpService {

  changeStorekeeper(request: ShipmentResource.ChangeStorekeeperRequest): Observable<EmptyMessage> {
    return this.http.post(this.url + `${request.shipment_id}/outtake/change-storekeeper`, request);
  }

  forceReopen(request: ShipmentResource.ForceReopenRequest): Observable<EmptyMessage> {
    return this.http.post(this.url + `${request.shipment_id}/outtake/force-reopen`, request);
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/shipments/');
  }
}

export namespace ShipmentGroupResource {

  export interface ShipmentGroup {
    id: number;
    external_id: string;
    state: string;
    demander: ShippingDemandResource.Demander;
    transporter: ShippingDemandResource.Company;
    source: ShippingDemandResource.ShippingPlace;
    destination: ShippingDemandResource.ShippingPlace;
    safety_shipping: boolean;
    delivery_note: ShipmentResource.DeliveryNote;
    demands?: ShippingDemandResource.ShippingDemand[];
    shipments: ShipmentResource.Shipment[];
    outtake?: ShipmentGroupResource.Outtake;
  }

  export interface GlobalQueryRequest {
    id?: string;
    external_id?: string;
    delivery_note_number?: string;
    customer_name?: string;
    ekaer_id?: string;
    deadline_from?: string;
    deadline_to?: string;
    safety_shipping?: boolean;
    transport_external_id?: string;
    exterior_transport_external_id?: string;
    transport_waybill_number?: string;
    source_address?: string;
    destination_address?: string;
    state?: string;
    delayed?: boolean;
    shipping_item?: string;
    with_demands?: boolean;
    q?: string;
    dql?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
  }

  export interface QueryRequest {
    transport_id: number;
    q?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
  }

  export interface CreateByDemandsRequest {
    demand_ids: number[];
  }

  export interface RemoveDemandRequest {
    shipment_group_id: number;
    demand_id: number;
  }

  export interface RemoveDemandResponse {
    shipment_removed: boolean;
  }

  export interface CreateByDemandsResponse {
    shipment_ids: number[];
  }

  export interface Depot {
    id: number;
    stock?: ShippingDemandResource.Stock;
    location: ShippingDemandResource.CompanyLocation;
    in: Shipment;
    out: Shipment;
  }

  export interface DepotCreateRequest {
    shipment_group_id: number;
    company_location_id: number;
  }

  export interface DepotDeleteRequest {
    shipment_group_id: number;
    id: number;
  }

  export interface DepotRearrangeRequest {
    shipment_group_id: number;
    ids: number[];
  }

  export interface Shipment {
    state: ShipmentNameSpace.ShipmentState;
    in_transport: boolean;
    transport_waybill_number?: string;
  }

  export interface Outtake {
    event_logs: TransportLogResource.TransportLog[];
    storekeeper?: {
      id: number;
      user_name: string;
      person_name: string;
      disabled: boolean;
    };
    state: Shipment.ShipmentOuttakeState;
    progress_count: {
      completed_count: number;
      total_count: number;
    };
    shipment_id: number;
  }

}

export namespace ShipmentResource {

  export interface Shipment {
    id: number;
    external_id: string;
    state: string;
    delivery_note: DeliveryNote;
    tracking_number?: string;
    transport?: Transport;
    shipment_group: ShipmentGroup;
    source: ShippingDemandResource.ShippingPlace;
    destination: ShippingDemandResource.ShippingPlace;
    delay?: Delay;
    sub_transporter?: SubTransporter;
  }

  export interface DeliveryNote {
    number: string;
  }

  export interface Transport {
    id: number;
    external_id: string;
    waybill_number: string;
  }

  export interface Delay {
    planned_arrival_time: string;
    delay_minutes: number;
  }

  export interface ShipmentGroup {
    delivery_note: string;
  }

  export interface ChangeSubtransporterRequest {
    shipment_ids: number[];
    sub_transporter_company_id?: number;
  }

  export interface ChangeStorekeeperRequest {
    shipment_id: number;
    user_id?: number;
  }

  export interface ForceReopenRequest {
    shipment_id: number;
  }

  export interface SubTransporter {
    id: number;
    name: string;
  }

}
