<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]=breadcrumbParents
      [self]="breadcrumbSelf">
    </app-breadcrumb>
    <app-breadcrumb-menu>
      <app-breadcrumb-button
        *ngIf="rightModel.projectFormImport.hasRight() && componentState.isEditView()"
        [iconClass]="'icomoon-csv-import'"
        [titleStringKey]="'COMMON_BUTTON_IMPORT'"
        (click)="formImportDialog.openImportDialog()">
      </app-breadcrumb-button>
      <app-breadcrumb-button
        *ngIf="rightModel.projectFormExport.hasRight() && (componentState.hasEntity())"
        [iconClass]="'icomoon-csv-export'"
        [titleStringKey]="'COMMON_BUTTON_EXPORT'"
        (click)="exportForm()">
      </app-breadcrumb-button>
      <app-breadcrumb-button
        *ngIf="rightModel.projectUpdate.hasRight() && componentState.isDetailView()"
        [iconClass]="'icomoon-modify'"
        [titleStringKey]="'COMMON_BUTTON_EDIT'"
        uiSref="Admin.ProjectEdit"
        [uiParams]="{ id: componentState.id }">
      </app-breadcrumb-button>
    </app-breadcrumb-menu>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>

<div class="container-horizontal-padding animated fadeIn">

  <div class="container-custom-padding">

    <div class="form-group">

      <form class="form-horizontal bordered-row row" [formGroup]="formGroup" (ngSubmit)="submit()" #f=ngForm novalidate>

        <!--region Basic Details-->
        <div class="col-xs-12 col-sm-12 col-md-6 card-custom-padding">
          <div class="card">
            <div class="card-header">
              {{ getHeadingDictionaryKey() | translate}}
            </div>
            <div class="card-body">

              <!--region Name-->
              <div class="form-group row"
                   [ngClass]="{ 'has-danger': (hasLocalFieldError('name') || hasFieldError(Project.ValidatedField.NAME)) }">
                <label class="col-form-label form-control-label col-md-4 detail-title required-field-label">
                  {{'COMMON_NAME' | translate}}
                </label>
                <div class="col-md-8">
                  <input *ngIf="componentState.isEditable()"
                         type="text" class="form-control"
                         maxlength="{{UiConstants.maximumVarcharLength}}"
                         placeholder="{{'COMMON_NAME' | translate}}"
                         [(ngModel)]="model.name"
                         (ngModelChange)="removeFieldError(Project.ValidatedField.NAME)"
                         [ngClass]="{ 'form-control-danger': hasLocalFieldError('name') || hasFieldError(Project.ValidatedField.NAME) }"
                         formControlName="name">
                  <div class="form-control-feedback"
                       *ngIf="hasLocalFieldError('name')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                  <div class="form-control-feedback"
                       *ngIf="hasFieldError(Project.ValidatedField.NAME)">{{getFieldErrorText(Project.ValidatedField.NAME)}}</div>
                  <!--Appears in detailView-->
                  <label
                    *ngIf="componentState.isReadonly()" class="form-control detail-description"
                    readonly>{{model.name}}
                  </label>
                </div>
              </div>
              <!--endregion Name-->

              <!--region ExternalId-->
              <div class="form-group row"
                   [ngClass]="{ 'has-danger': (hasLocalFieldError('externalId') || hasFieldError(Project.ValidatedField.EXTERNAL_ID)) }">
                <label class="col-form-label form-control-label col-md-4 detail-title"
                       [class.required-field-label]="componentState.isEditView()">
                  {{'COMMON_EXTERNAL_ID' | translate}}
                </label>
                <div class="col-md-8">
                  <input *ngIf="componentState.isEditable()"
                         type="text" class="form-control"
                         maxlength="{{UiConstants.maximumVarcharLength}}"
                         placeholder="{{'COMMON_EXTERNAL_ID' | translate}}"
                         [(ngModel)]="model.externalId"
                         (ngModelChange)="removeFieldError(Project.ValidatedField.EXTERNAL_ID)"
                         [ngClass]="{ 'form-control-danger': hasLocalFieldError('externalId') || hasFieldError(Project.ValidatedField.EXTERNAL_ID) }"
                         formControlName="externalId">
                  <div class="form-control-feedback"
                       *ngIf="hasLocalFieldError('externalId')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                  <div class="form-control-feedback"
                       *ngIf="hasFieldError(Project.ValidatedField.EXTERNAL_ID)">{{getFieldErrorText(Project.ValidatedField.EXTERNAL_ID)}}</div>
                  <!--Appears in detailView-->
                  <label
                    *ngIf="componentState.isReadonly()" class="form-control detail-description"
                    readonly>{{model.externalId}}
                  </label>
                </div>
              </div>
              <!--endregion ExternalId-->

              <!--region Description-->
              <div class="form-group row" *ngIf="componentState.isEditable() || model.description.length > 0">
                <label class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_DESCRIPTION' |
                  translate}}</label>
                <div class="col-md-8">
                  <input *ngIf="componentState.isEditable()"
                         type="text" class="form-control"
                         maxlength="{{UiConstants.maximumVarcharLength}}"
                         placeholder="{{'COMMON_DESCRIPTION' | translate}}"
                         [(ngModel)]="model.description"
                         [ngModelOptions]="{standalone: true}">
                  <!--Appears in detailView-->
                  <label
                    *ngIf="componentState.isReadonly()" class="form-control detail-description"
                    readonly>{{model.description}}
                  </label>
                </div>
              </div>
              <!--endregion Description-->

              <!--region Icon-->
              <app-icon-selector
              [(selectedIcon)]="model.icon"></app-icon-selector>
              <!--endregion Icon-->

              <!--region ExplicitOrderNumber-->
              <div class="form-group row" *ngIf="componentState.isEditable() || model.explicitOrderNumber.length > 0"
                   [ngClass]="{ 'has-danger': hasLocalFieldError('explicitOrderNumber') || hasFieldError(Project.ValidatedField.EXPLICIT_ORDER_NUMBER) }">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'TASK_DASHBOARD_DISPLAY_SETTINGS_ORDER_NUMBER' |
                  translate}}</label>
                <div class="col-md-8">
                  <input *ngIf="componentState.isEditable()"
                         type="text" class="form-control"
                         maxlength="{{UiConstants.maximumVarcharLength}}"
                         placeholder="{{'TASK_DASHBOARD_DISPLAY_SETTINGS_ORDER_NUMBER' | translate}}"
                         [(ngModel)]="model.explicitOrderNumber"
                         (ngModelChange)="removeFieldError(Project.ValidatedField.EXPLICIT_ORDER_NUMBER)"
                         [ngClass]="{ 'form-control-danger': hasLocalFieldError('explicitOrderNumber') || hasFieldError(Project.ValidatedField.EXPLICIT_ORDER_NUMBER) }"
                         [textMask]="{mask: InputMask.NATURAL_INTEGER, guide: false}"
                         formControlName="explicitOrderNumber">
                  <div class="form-control-feedback"
                       *ngIf="hasLocalFieldError('explicitOrderNumber')">{{'COMMON_VALIDATION_MESSAGE_MIN' | translate: {minValue: 1} }}</div>
                  <div class="form-control-feedback"
                       *ngIf="hasFieldError(Project.ValidatedField.EXPLICIT_ORDER_NUMBER)">{{getFieldErrorText(Project.ValidatedField.EXPLICIT_ORDER_NUMBER)}}</div>
                  <!--Appears in detailView-->
                  <label
                    *ngIf="componentState.isReadonly()" class="form-control detail-description"
                    readonly>{{model.explicitOrderNumber}}
                  </label>
                </div>
              </div>
              <!--endregion ExplicitOrderNumber-->

              <!--region RelatedUserGroups-->
              <div class="form-group row"
                   [ngClass]="{ 'has-danger': hasLocalFieldError('relatedUserGroups') }">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'PROJECT_EDIT_RELATED_USER_GROUPS' |
                  translate}}</label>
                <div class="col-md-8">
                  <angular2-multiselect
                    formControlName="relatedUserGroups"
                    [(ngModel)]="model.relatedUserGroups"
                    [data]="userGroups"
                    [readonly]="componentState.isReadonly()"
                    (onRemoteSearch)="onUserGroupSearch($event.target.value)"
                    [settings]="remoteDropdownSettings">
                  </angular2-multiselect>
                  <div class="form-control-feedback"
                       *ngIf="hasLocalFieldError('relatedUserGroups')">{{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate }}</div>
                </div>
              </div>
              <!--endregion RelatedUserGroups-->

              <!--region DisplayedFormField-->
              <div class="form-group row" *ngIf="componentState.isEditView() || model.displayedFormField.length > 0"
                   [ngClass]="{ 'has-danger': hasLocalFieldError('displayedFormField') }">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'PROJECT_EDIT_DISPLAYED_FORM_FIELD' |
                  translate}}</label>
                <div class="col-md-8">
                  <angular2-multiselect
                    formControlName="displayedFormField"
                    [(ngModel)]="model.displayedFormField"
                    [data]="formFields"
                    [readonly]="componentState.isReadonly()"
                    [settings]="dropdownSettings">
                  </angular2-multiselect>
                  <div class="form-control-feedback"
                       *ngIf="hasLocalFieldError('displayedFormField')">{{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate }}</div>
                </div>
              </div>
              <!--endregion DisplayedFormField-->

              <!--Only visible on create/edit views-->
              <div class="col-md-12 d-flex justify-content-end p-0" *ngIf="componentState.isEditable()">
                <div>
                  <input type="button" class="btn btn-secondary" (click)="back()"
                         value="{{'COMMON_BUTTON_CANCEL'|translate}}"/>
                  <input type="submit" class="btn btn-primary ml-1" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- endregion Basic Details-->
      </form>
    </div>
    <!-- /.form-group row -->
  </div>


  <div class="container-vertical-padding-half form-editor-card-container"
       id="form-editor-card-container" *ngIf="componentState.isEditView()">

    <!-- region Form card -->
    <app-form-edit #formEdit
                   [formServiceCode]="FormServiceCode.PROJECT"
                   [parentId]="componentState.id!"
                   [formRights]="formRights"
                   [dialogContainer]="dialogContainer"
                   (formLoad)="onFormLoaded($event)">
    </app-form-edit>
    <!-- endregion Form card -->

  </div>
</div>

<!-- region Dialogs -->
<app-form-edit-dialog-container #dialogContainer></app-form-edit-dialog-container>
<app-form-import-dialog
  #formImportDialog
  [parentId]="componentState.id"
  [formService]="formService"
  (onFormImported)="reloadForm()">
</app-form-import-dialog>

<!-- endregion Dialogs -->
