/* eslint-disable */
import { Component, ComponentRef, ElementRef, HostListener, ViewChild, } from '@angular/core';
import {
  FieldDataTypeDescriptor,
  FieldDataTypeSelectors, FormRecordFieldContext,
  FormRecordFieldValueUpdateArgs,
  FormRecordFieldView,
  FormRecordGroupView,
  FormRecordGroupViewContext,
  FormRecordGroupViewFieldsContext,
  FormRecordGroupViewModel,
} from '../../../../../util/form/form-utils';
import { Form } from '../../../../../lib/form/form.service';
import { FormRecordFieldHolderDirective } from '../../shared/form-record-field-holder.directive';
import { List, Map as ImmutableMap, Set } from 'immutable';
import { FormRecord } from '../../../../../lib/form/form-record.service';
import { CssStyle } from '../../../../../util/host-styles';
import { UiConstants } from '../../../../../util/core-utils';
import { ScreenSizes } from '../../../../../util/screen-size';
import {
  FormRecordInactivityManager,
  FormRecordInactivityManagerFactory,
} from '../../manager/form-record-inactivity-manager';
import { FormRecordFieldViewModelFactory } from '../../manager/form-record-field-view-model-factory';
import { forkJoin, Observable } from 'rxjs';
import {AppType, AppTypeHelperService} from "../../../../../lib/util/app-type-helper.service";

/* eslint-enable */

@Component({
  selector: 'app-form-record-default-group',
  templateUrl: 'form-record-default-group.component.html',
  styleUrls: ['form-record-default-group.component.scss'],
})
export class FormRecordDefaultGroupComponent implements FormRecordGroupView {

  @ViewChild('groupHost', { static: true })
  groupHost: ElementRef;

  @ViewChild(FormRecordFieldHolderDirective, { static: true })
  fieldHost: FormRecordFieldHolderDirective;

  layoutStyle = {
    'display': 'flex',
    'flex-wrap': 'wrap',
    'width': '100%',
  };

  private context: FormRecordGroupViewContext;

  private fieldItems: FieldItem[] = [];

  private readonlyFn: () => boolean = () => true;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.regenerateEachFieldStyleAttributes();
  }

  get readonly(): boolean {
    return this.readonlyFn();
  }

  constructor(
    private fieldDataTypeSelectors: FieldDataTypeSelectors,
    private formRecordFieldViewModelFactory: FormRecordFieldViewModelFactory,
    private appTypeHelperService: AppTypeHelperService) {
  }

  get group(): Form.Group {
    return this.context.group;
  }

  get lastGroup(): boolean {
    return this.context.group === this.context.form.groups.findLast((gr) => gr ? !gr.disabled : false);
  }

  registerContext(context: FormRecordGroupViewContext) {
    this.context = context;
  }

  registerFields(context: FormRecordGroupViewFieldsContext): void {
    this.readonlyFn = context.readonly;
    this.fieldItems = [];
    this.fieldHost.viewContainerRef.clear();
    const fieldOrder: (number | null)[] = [];
    const appType = this.appTypeHelperService.appType();
    context.group.fields.forEach((field) => {
      if (!field) {
        return true;
      }
      const descriptor = this.fieldDataTypeSelectors.findDescriptor(field.dataTypeSelector);
      const fieldView = this.createFieldView(field, descriptor);
      fieldView.instance.registerField(this.createFieldContext(context, field, appType));
      const fieldItem = {
        fieldView: fieldView,
        field: field
      };
      this.applyFieldStyles(fieldItem);
      this.fieldItems.push(fieldItem);
      if (!field.disabled) {
        // Disabled fields are always invisible, but they are added to the fieldItems array because we keep the data.
        if (field.displayOnNewRow) {
          const lineBreakView = this.fieldHost.createLineBreakView();
          fieldOrder.push(null);
          this.fieldHost.viewContainerRef.insert(lineBreakView.hostView);
        }
        fieldOrder.push(field.fieldId);
        this.fieldHost.viewContainerRef.insert(fieldView.hostView);
      }
    });
    const fieldViews = List.of(...this.fieldItems.map((item) => {
      return item.fieldView.instance;
    }));
    context.inactivityManager.addViews({
      form: context.form,
      groupId: context.group.groupId,
      groupHost: this.groupHost,
      fieldViews: fieldViews,
      fieldOrder: List(fieldOrder),
      fieldHost: this.fieldHost
    });
    this.fieldItems.forEach((fieldItem) => {
      fieldItem.fieldView.instance.registerFieldViews({
        fieldViews: fieldViews,
        inactivityManager: context.inactivityManager
      });
    });
  }

  hasLocalFieldError(): boolean {
    let hasError = false;
    List.of(...this.fieldItems).forEach((fieldItem) => {
      if (!fieldItem) {
        return true;
      }
      if (fieldItem.fieldView.instance.hasLocalFieldError()) {
        hasError = true;
        return false;
      }
    });
    return hasError;
  }

  validateWithInterrupt(): boolean {
    let interrupted = false;
    List.of(...this.fieldItems).forEach((fieldItem) => {
      if (!fieldItem) {
        return true;
      }
      if (fieldItem.fieldView.instance.validateWithInterrupt()) {
        interrupted = true;
        return false;
      }
    });
    return interrupted;
  }

  shouldNotifyAfterCreation(): boolean {
    for (const fieldItem of this.fieldItems) {
      if (fieldItem.fieldView.instance.shouldNotifyAfterCreation()) {
        return true;
      }
    }
    return false;
  }

  afterFormRecordCreation(formRecordId: number): Observable<any> {
    const observables: Observable<any>[] = [];
    this.fieldItems.forEach(fieldItem => {
      if (fieldItem.fieldView.instance.shouldNotifyAfterCreation()) {
        observables.push(fieldItem.fieldView.instance.afterFormRecordCreation(formRecordId)!);
      }
    });
    return forkJoin(observables);
  }

  createModel(): FormRecordGroupViewModel {
    const requests: FormRecord.FieldEditRequest[] = [];
    List.of(...this.fieldItems).forEach((fieldItem) => {
      if (!fieldItem) {
        return true;
      }
      const model = this.formRecordFieldViewModelFactory.createModel(fieldItem.fieldView.instance);
      if (model) {
        requests.push(model.fieldEditRequest);
      }
    });
    return {
      fieldEditRequests: Set.of(...requests),
    };
  }

  getGroupId(): number {
    return this.group.groupId;
  }

  updateFieldData(fieldId: number, data: FormRecordFieldValueUpdateArgs) {
    const field = this.fieldItems.find(f => f.field.fieldId === fieldId);
    if (!field) {
      throw Error('field not in group');
    }
    field.fieldView.instance.updateValue(data);
  }

  getFieldHost(): FormRecordFieldHolderDirective {
    return this.fieldHost;
  }

  private createFieldView(field: Form.Field, descriptor: FieldDataTypeDescriptor):
    ComponentRef<FormRecordFieldView & CssStyle.PropertySetterFnProvider> {
    const fieldWrapper = this.fieldHost.createFieldWrapperView();
    if (field.disabled) {
      const fieldView = this.fieldHost.createDisabledFieldView(descriptor.selector);
      fieldWrapper.instance.setDelegate(fieldView);
      return fieldWrapper;
    }
    else {
      const fieldView = descriptor.formRecordFieldViewFactory.createView();
      fieldWrapper.instance.setDelegate(fieldView);
      return fieldWrapper;
    }
  }

  private createFieldContext(context: FormRecordGroupViewFieldsContext, field, appType): FormRecordFieldContext {
    return {
      inactivityManager: context.inactivityManager,
      commandManager: context.commandManager,
      commandResultStore: context.commandResultStore,
      htmlForm: context.htmlForm,
      readonly: context.readonly,
      cloning: context.cloning,
      configuration: context.configuration,
      form: context.form,
      group: context.group,
      field: field,
      validationType: appType === AppType.HELPDESK ? field.helpdesk.validationType : field.admin.validationType,
      fieldRecord: context.formRecord
        ? FormRecord.FormRecords.findFieldRecord(context.formRecord, field) : undefined,
      formRecordId: context.formRecord ? context.formRecord.recordId : undefined,
      other: context.other
    };
  }

  private generateFieldStyleAttributes(field: Form.Field): ImmutableMap<CssStyle.Property, string> {
    const widthPercent = (window.innerWidth <= ScreenSizes.WIDTH_TABLET ? 100 : field.formFieldWidthPercent) + '%';
    return ImmutableMap.of(
      CssStyle.Property.flex, '1 1 ' + widthPercent,
      CssStyle.Property.maxWidth, widthPercent,
      CssStyle.Property.padding, UiConstants.formRecordFieldPadding,
    );
  }

  private regenerateEachFieldStyleAttributes() {
    List.of(...this.fieldItems).forEach((fieldItem) => {
      if (!fieldItem) {
        return true;
      }
      this.applyFieldStyles(fieldItem);
    });
  }

  private applyFieldStyles(fieldItem: FieldItem) {
    const generatedStyles = this.generateFieldStyleAttributes(fieldItem.field);
    generatedStyles.forEach((value, key) => {
      const fn = fieldItem.fieldView.instance.getPropertySetterFn(key!);
      if (fn) {
        fn(value);
      }
    });
  }

}

interface FieldItem {
  field: Form.Field;
  fieldView: ComponentRef<FormRecordFieldView & CssStyle.PropertySetterFnProvider>;
}
