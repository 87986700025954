<div class="modal-content">
  <div class="modal-header">
    <p class="modal-title"><strong>{{'PROJECT_RECORD_DETAIL' | translate}}</strong></p>
    <button type="button" class="close" (click)="closeDialog()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!--PROJECT NAME-->
    <div class="row" *ngIf="detailModel.projectName !== ''">
      <label readonly
             class="col-form-label form-control-label detail-title text-md-right col-md-4 mb-0">{{'CALENDAR_PROJECT_RECORD_DETAIL_PROJECT_NAME'|translate}}</label>
      <div class="form-group col-md-8 mb-0">
        <label readonly class="col-form-label form-control-label detail-description"> {{ detailModel.projectName
          }} </label>
      </div>
    </div>

    <!--NAME-->
    <div class="row" *ngIf="detailModel.projectRecordName !== ''">
      <label readonly
             class="col-form-label form-control-label detail-title text-md-right col-md-4 mb-0">{{'COMMON_NAME'|translate}}</label>
      <div class="form-group col-md-8 mb-0">
        <label readonly
               class="col-form-label form-control-label detail-description"> {{ detailModel.projectRecordName
          }} </label>
      </div>
    </div>

    <!--EXTERNAL ID-->
    <div class="row" *ngIf="detailModel.projectRecordExternalId !== ''">
      <label readonly
             class="col-form-label form-control-label detail-title text-md-right col-md-4 mb-0">{{'COMMON_EXTERNAL_ID'|translate}}</label>
      <div class="form-group col-md-8 mb-0">
        <label readonly
               class="col-form-label form-control-label detail-description"> {{ detailModel.projectRecordExternalId
          }} </label>
      </div>
    </div>

    <div class="row" *ngIf="detailModel.startDate">
      <label readonly
             class="col-form-label form-control-label detail-title text-md-right col-md-4 mb-0">{{'PROJECT_START_DATE'|translate}}</label>
      <div class="form-group col-md-8 mb-0">
        <label readonly
               class="col-form-label form-control-label detail-description"> {{ detailModel.startDate.toIsoString() | date: 'mediumDate'
          }} </label>
      </div>
    </div>

    <div class="row" *ngIf="detailModel.endDate">
      <label readonly
             class="col-form-label form-control-label detail-title text-md-right col-md-4 mb-0">{{'PROJECT_END_DATE'|translate}}</label>
      <div class="form-group col-md-8 mb-0">
        <label readonly
               class="col-form-label form-control-label detail-description"> {{ detailModel.endDate.toIsoString() | date: 'mediumDate'
          }} </label>
      </div>
    </div>

    <div class="row" *ngIf="detailModel.amount !== ''">
      <label readonly
             class="col-form-label form-control-label detail-title text-md-right col-md-4 mb-0">{{'PROJECT_BUDGET'|translate}}</label>
      <div class="form-group col-md-8 mb-0">
        <label readonly class="col-form-label form-control-label detail-description"> {{ detailModel.amount
          }} </label>
      </div>
    </div>

    <div class="row" *ngIf="detailModel.billingType !== ''">
      <label readonly
             class="col-form-label form-control-label detail-title text-md-right col-md-4 mb-0">{{'COMMON_TYPE'|translate}}</label>
      <div class="form-group col-md-8 mb-0">
        <label readonly
               class="col-form-label form-control-label detail-description"> {{ detailModel.billingType | translate
          }} </label>
      </div>
    </div>

    <div class="row" *ngIf="detailModel.billingStartDate">
      <label readonly
             class="col-form-label form-control-label detail-title text-md-right col-md-4 mb-0">{{'PROJECT_BILLING_START_DATE'|translate}}</label>
      <div class="form-group col-md-8 mb-0">
        <label readonly
               class="col-form-label form-control-label detail-description"> {{ detailModel.billingStartDate.toIsoString() | date: 'mediumDate'
          }} </label>
      </div>
    </div>

    <div class="row" *ngIf="detailModel.billingEndDate">
      <label readonly
             class="col-form-label form-control-label detail-title text-md-right col-md-4 mb-0">{{'PROJECT_BILLING_END_DATE'|translate}}</label>
      <div class="form-group col-md-8 mb-0">
        <label readonly
               class="col-form-label form-control-label detail-description"> {{ detailModel.billingEndDate.toIsoString() | date: 'mediumDate'
          }} </label>
      </div>
    </div>

    <div class="row" *ngIf="detailModel.billingPeriodInMonths !== ''">
      <label readonly
             class="col-form-label form-control-label detail-title text-md-right col-md-4 mb-0">{{'PROJECT_BILLING_PERIOD_IN_MONTHS'|translate}}</label>
      <div class="form-group col-md-8 mb-0">
        <label readonly
               class="col-form-label form-control-label detail-description"> {{ detailModel.billingPeriodInMonths
          }} </label>
      </div>
    </div>

    <div class="row" *ngIf="detailModel.assigneeUser !== ''">
      <label readonly
             class="col-form-label form-control-label detail-title text-md-right col-md-4 mb-0">{{'COMMON_USER'|translate}}</label>
      <div class="form-group col-md-8 mb-0">
        <label readonly class="col-form-label form-control-label detail-description"> {{ detailModel.assigneeUser
          }} </label>
      </div>
    </div>

    <div class="row" *ngIf="detailModel.postalAddress !== ''">
      <label readonly
             class="col-form-label form-control-label detail-title text-md-right col-md-4 mb-0">{{'COMMON_LOCATION_POSTAL_ADDRESS'|translate}}</label>
      <div class="form-group col-md-8 mb-0">
        <label readonly class="col-form-label form-control-label detail-description"> {{ detailModel.postalAddress
          }} </label>
      </div>
    </div>

    <div class="row" *ngIf="detailModel.displayedFormFieldRecord !== ''">
      <label readonly
             class="col-form-label form-control-label detail-title text-md-right col-md-4 mb-0">{{'PROJECT_RECORD_DISPLAYED_FORM_FIELD_RECORD'|translate}}</label>
      <div class="form-group col-md-8 mb-0">
        <label readonly
               class="col-form-label form-control-label detail-description"> {{ detailModel.displayedFormFieldRecord
          }} </label>
      </div>
    </div>
  </div>
  <div class="modal-footer justify-content-between">
        <span>
          <button type="button" class="btn btn-secondary-green-gray mr-1" (click)="navigateToDetail()">
            {{'COMMON_BUTTON_DETAILS' | translate}}
          </button>
          <button *ngIf="rightModel.projectRecordUpdate.hasRight()"
                  type="button" class="btn btn-primary-green-gray" (click)="navigateToEdit()">
            {{'COMMON_BUTTON_EDIT' | translate}}
          </button>
        </span>
    <button type="button" class="btn btn-outline-primary" (click)="closeDialog()">
      {{'COMMON_BUTTON_CLOSE' | translate}}
    </button>
  </div>

</div>
