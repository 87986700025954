/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FormResource, FormResourcePathGenerator } from './form-resource.service';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { ResourceQueryResult } from '../util/services';

/* eslint-enable */

@Injectable()
export class FormTableResourceService extends BaseHttpService {

  // <editor-fold desc="CRUD">

  query(request: FormTableResource.QueryRequest): Observable<ResourceQueryResult<FormTableResource.FormTable>> {
    return this.http.get<ResourceQueryResult<FormTableResource.FormTable>>(this.url, this.parseParams(request));
  }

  get(request: FormTableResource.GetRequest): Observable<FormTableResource.FormTable> {
    return this.http.get<FormTableResource.FormTable>(this.url + `${request.form_table_id}`);
  }

  create(request: FormTableResource.CreateRequest): Observable<FormTableResource.FormTable> {
    return this.http.post<FormTableResource.FormTable>(this.url, request);
  }

  update(request: FormTableResource.UpdateRequest): Observable<FormTableResource.FormTable> {
    return this.http.put<FormTableResource.FormTable>(this.url + `${request.form_table_id}`, request);
  }

  setDisabled(request: FormTableResource.DisableRequest): Observable<FormTableResource.FormTable> {
    return this.http.patch<FormTableResource.FormTable>(this.url + `${request.form_table_id}/disabled`, request);
  }

  // </editor-fold>

  // <editor-fold desc="Form">

  getForm(request: FormResource.GetFormRequest): Observable<FormResource.Form> {
    return this.http.get<FormResource.Form>(this.url + FormResourcePathGenerator.GetForm(request));
  }

  createFormGroup(request: FormResource.CreateGroupRequest): Observable<FormResource.CreateGroupResponse> {
    return this.http.post<FormResource.CreateGroupResponse>(this.url + FormResourcePathGenerator.CreateGroup(request), request);
  }

  updateFormGroup(request: FormResource.UpdateGroupRequest): Observable<FormResource.UpdateGroupResponse> {
    return this.http.put<FormResource.UpdateGroupResponse>(this.url + FormResourcePathGenerator.UpdateGroup(request), request);
  }

  disableFormGroup(request: FormResource.DisableGroupRequest): Observable<FormResource.DisableGroupResponse> {
    return this.http.patch<FormResource.DisableGroupResponse>(this.url + FormResourcePathGenerator.DisableGroup(request), request);
  }

  moveFormGroup(request: FormResource.MoveGroupRequest): Observable<FormResource.MoveGroupResponse> {
    return this.http.post<FormResource.MoveGroupResponse>(this.url + FormResourcePathGenerator.MoveGroup(request), null);
  }

  createFormField(request: FormResource.CreateFieldRequest): Observable<FormResource.CreateFieldResponse> {
    return this.http.post<FormResource.CreateFieldResponse>(this.url + FormResourcePathGenerator.CreateField(request), request);
  }

  updateFormField(request: FormResource.UpdateFieldRequest): Observable<FormResource.UpdateFieldResponse> {
    return this.http.post<FormResource.UpdateFieldResponse>(this.url + FormResourcePathGenerator.UpdateField(request), request);
  }

  disableFormField(request: FormResource.DisableFieldRequest): Observable<FormResource.DisableFieldResponse> {
    return this.http.patch<FormResource.DisableFieldResponse>(this.url + FormResourcePathGenerator.DisableField(request), request);
  }

  moveFormFieldToGroup(request: FormResource.MoveFieldToGroupRequest): Observable<FormResource.MoveFieldToGroupResponse> {
    return this.http.post<FormResource.MoveFieldToGroupResponse>(this.url + FormResourcePathGenerator.MoveFieldToGroup(request), null);
  }

  import(request: FormResource.ImportRequest): Observable<FormResource.Form> {
    return this.http.post<FormResource.Form>(this.url + FormResourcePathGenerator.Import(request), request);
  }

  export(request: FormResource.ExportRequest): Observable<FormResource.FormImportDocument> {
    return this.http.post<FormResource.FormImportDocument>(this.url + FormResourcePathGenerator.Export(request), null);
  }

  // </editor-fold>

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/form-tables/');
  }

}

export namespace FormTableResource {

  // <editor-fold desc="CRUD">

  export interface FormTable {
    id: number;
    external_id: string;
    disabled: boolean;
    creation_time: string;
    update_time: string;
    name: string;
    description: string;
  }

  export interface QueryRequest {
    id?: string;
    disabled?: boolean;
    name?: string;
    external_id?: string;
    q?: string;
    fields?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
    no_progress_bar?: boolean;
  }

  export interface GetRequest {
    form_table_id: number;
  }

  export interface CreateRequest {
    name: string;
    external_id?: string;
    description?: string;
  }

  export interface UpdateRequest {
    form_table_id: number;
    name: string;
    external_id: string;
    description?: string;
  }

  export interface DisableRequest {
    form_table_id: number;
    disabled: boolean;
  }

  // </editor-fold>

}
