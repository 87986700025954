/* eslint-disable */
import {Injectable} from '@angular/core';
import {IconService} from '../task/icon.service';
import {Observable, Observer} from 'rxjs';
import {ObservableErrorResourceParser} from '../util/errors';
import {FieldValidationError, PagingRequest, QueryResult, ResourceQueryResult, Services} from '../util/services';
import {List, Map as ImmutableMap, Set} from 'immutable';
import {OffsetDateTime} from '../util/dates';
import {EmptyMessage, IdentityMessage} from '../util/messages';
import {DqlQuery, Query} from '../query/field';
import {FilterField} from '../query/filterfields';
import {OrderField} from '../query/orderfields';
import {TaskRecordStateMachine} from '../task/task-record-statemachine';
import {ProcessResource, ProcessResourceService} from './process-resource.service';
import {Workflow} from '../workflow/workflow.service';
import {AddressResource} from '../address';
import {WorkflowResource} from '../workflow/workflow-resource.service';
import {BadgeStyle} from '../../shared/table-badge/badge-style';
import {DownloadedFile} from '../util/downloaded-files';
import {ChatService} from "../../shared/chat/chat-service.interface";
import {Chat, ChatResource} from "../chat/chat.utils";
import {TaskRecord, TaskRecordMapper} from "../task/task-record.service";

/* eslint-enable */

@Injectable()
export class ProcessService implements Process.Service, ChatService {

  private readonly filterField = new FilterField.Process();
  private readonly orderField = new OrderField.Process();
  private readonly fields = new Process.Fields.Process();

  constructor(private resourceService: ProcessResourceService,
              private iconService: IconService) {
  }

  query(request: Process.QueryRequest): Observable<QueryResult<Process.Process>> {
    return Observable.create((observer: Observer<QueryResult<Process.Process>>) => {
      const resourceRequest = Process.toResourceQueryRequest(this.filterField, this.orderField, this.fields, request);
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<ProcessResource.Process>) => {
          observer.next({
            items: List.of(...result.items.map((item) => Process.toPublic(this.iconService, item))),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  get(request: Process.GetRequest): Observable<Process.Process> {
    return Observable.create((observer: Observer<Process.Process>) => {
      const resourceRequest = Process.toResourceGetRequest(this.fields, request);
      return this.resourceService.get(resourceRequest).subscribe(
        (result: ProcessResource.Process) => {
          observer.next(Process.toPublic(this.iconService, result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  create(request: Process.CreateRequest): Observable<IdentityMessage> {
    return Observable.create((observer: Observer<IdentityMessage>) => {
      const resourceRequest = Process.toResourceCreateRequest(request);
      return this.resourceService.create(resourceRequest).subscribe(
        (result: IdentityMessage) => {
          observer.next(result);
        },
        (error: any) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  update(request: Process.UpdateRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest = Process.toResourceUpdateRequest(request);
      return this.resourceService.update(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: any) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  stateCount(request: IdentityMessage): Observable<Map<string, number>> {
    return Observable.create((observer: Observer<Map<string, number>>) => {
      return this.resourceService.stateCount(request).subscribe(
        (result: Map<string, number>) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  messageQuery(request: Chat.MessageQueryRequest): Observable<QueryResult<Chat.Message>> {
    return Observable.create((observer: Observer<QueryResult<Chat.Message>>) => {
      const resourceRequest: ProcessResource.MessageQueryRequest = {
        process_id: request.id,
        content: request.content,
        user_profile_id: request.userProfileId,
        creation_time_from: request.creationTimeFrom && request.creationTimeFrom.isValid()
          ? request.creationTimeFrom.toUtcIsoString() : undefined,
        creation_time_to: request.creationTimeTo && request.creationTimeTo.isValid()
          ? request.creationTimeTo.toUtcIsoString() : undefined,
        order: Services.createOrderFieldParameter(Chat.Keys.toOrderFieldKey, request.order),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
      };
      return this.resourceService.messageQuery(resourceRequest).subscribe(
        (result: ResourceQueryResult<ChatResource.Message>) => {
          observer.next({
            items: Chat.Mapper.toPublicMessageList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  sendMessage(request: Chat.SendMessageRequest): Observable<IdentityMessage> {
    return Observable.create((observer: Observer<IdentityMessage>) => {
      const resourceRequest: ProcessResource.SendMessageRequest = {
        process_id: request.id,
        content: request.content,
      };
      return this.resourceService.sendMessage(resourceRequest).subscribe(
        (result: IdentityMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  getRecentMessages(request: Process.RecentMessageQueryRequest): Observable<QueryResult<Process.RecentMessage>> {
    return Observable.create((observer: Observer<QueryResult<Process.RecentMessage>>) => {
      const field: string | undefined = DqlQuery.toOptionalFields(this.fields, request.fields);

      return this.resourceService.getRecentMessages({
        fields: field,
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
        no_progress_bar: request.noProgressBar

      }).subscribe(
        (result: ResourceQueryResult<ProcessResource.RecentMessage>) => {
          observer.next({
            items: List.of(...result.items.map(m => {
              return {
                process: Process.toPublic(this.iconService, m.process),
                chatMessage: Chat.Mapper.toPublicMessage(m.chat_message)
              };
            })),
            pagingResult: result.pagingResult
          });

        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  exportXls(request: Process.XlsExportRequest): Observable<DownloadedFile> {
    const resourceRequest: ProcessResource.XlsExportRequest = Process.toResourceXlsExportRequest(this.filterField, request);
    return this.resourceService.exportXls(resourceRequest);
  }

  private translateError(error: any): any {
    const res = ObservableErrorResourceParser.parseError(error);
    const fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
    const fieldErrorMap = ObservableErrorResourceParser.toFieldErrorMap(Keys.toValidatedField, fieldErrors);
    if (!fieldErrorMap.isEmpty()) {
      return FieldValidationError.of(fieldErrorMap);
    }
    return error;
  }
}

export namespace Process {


  export interface Service {

    // <editor-fold desc="CRUD">

    query(request: QueryRequest): Observable<QueryResult<Process>>;

    get(request: GetRequest): Observable<Process>;

    create(request: CreateRequest): Observable<IdentityMessage>;

    // </editor-fold>

  }

  // <editor-fold desc="CRUD">

  export interface QueryRequest {
    fields?: Query.FieldFunction<Fields.Process>;
    filter?: Query.FilterFunction<FilterField.Process>;
    order?: Query.OrderFunction<OrderField.Process>;
    paging?: PagingRequest;
    rights?: Set<string>;
    noProgressBar?: boolean;
  }

  export interface XlsExportRequest {
    filter?: Query.FilterFunction<FilterField.Process>;
  }

  export interface GetRequest {
    id: number;
    fields?: Query.FieldFunction<Fields.Process>;
    rights?: Set<string>;
    taskRecordRights?: Set<string>;
  }

  export interface RecentMessageQueryRequest {
    paging?: PagingRequest;
    fields?: Query.FieldFunction<Fields.Process>;
    noProgressBar?: boolean;
  }

  export interface Process {
    id: number;
    grantedRights: Set<string>;
    name: string;
    externalId: string;
    description?: string;
    creationTime: OffsetDateTime;
    updateTime: OffsetDateTime;
    startTime?: OffsetDateTime;
    finishTime?: OffsetDateTime;
    lastModifiedUser?: User;
    creatorUser?: User;
    state: ProcessState;
    deadline?: OffsetDateTime;
    taskRecordNamePattern?: string;
    taskRecords?: TaskRecord[];
    workflow?: Workflow.Workflow;
  }

  export interface User {
    id: number;
    userName: string;
    personName: string;
  }

  export interface Project {
    id: number;
    name: string;
    externalId: string;
  }

  export interface TaskRecord {
    id: number;
    taskId: number;
    name: string;
    grantedRights: Set<string>;
    state: TaskRecordStateMachine.State;
    creationTime: OffsetDateTime;
    workflowTask: {
      id: number;
    };
    assigneeUser?: {
      id: number;
      personName: string;
      userName: string;
    };
    assigneeUserGroup?: {
      id: number;
      name: string;
    };
    customerRecord?: {
      id: number;
      name: string;
    };
    contactLocation?: {
      id: number;
      name: string;
    };
    importance: TaskRecord.TaskRecordImportance;
    deadline?: OffsetDateTime;
    placeOfConsumption?: TaskRecord.PlaceOfConsumption;
  }

  export interface RecentMessage {
    process: Process;
    chatMessage: Chat.Message;
  }

  export enum ProcessState {
    NEW = 'NEW',
    IN_PROGRESS = 'IN_PROGRESS',
    INTERRUPTED = 'INTERRUPTED',
    FINISHED = 'FINISHED',
    FINISHED_PARTIALLY = 'FINISHED_PARTIALLY',
    FAILED = 'FAILED',
  }

  export interface ProcessStateObject {
    state: ProcessState;
    stringKey: string;
    iconClass: string;
    badgeStyle: string;
  }

  export const processStates: ProcessStateObject[] = [
    {
      state: ProcessState.NEW,
      stringKey: 'PROCESS_STATE_NEW',
      iconClass: 'icomoon-new-state-new',
      badgeStyle: BadgeStyle.SECONDARY
    },
    {
      state: ProcessState.IN_PROGRESS,
      stringKey: 'PROCESS_STATE_IN_PROGRESS',
      iconClass: 'icomoon-new-state-in-progress',
      badgeStyle: BadgeStyle.PRIMARY
    },
    {
      state: ProcessState.INTERRUPTED,
      stringKey: 'PROCESS_STATE_INTERRUPTED',
      iconClass: 'icomoon-shipment-failed',
      badgeStyle: BadgeStyle.WARNING
    },
    {
      state: ProcessState.FINISHED,
      stringKey: 'PROCESS_STATE_FINISHED',
      iconClass: 'icomoon-transport-state-done',
      badgeStyle: BadgeStyle.SUCCESS
    },
    {
      state: ProcessState.FINISHED_PARTIALLY,
      stringKey: 'PROCESS_STATE_FINISHED_PARTIALLY',
      iconClass: 'icomoon-ban-thin',
      badgeStyle: BadgeStyle.WARNING
    },
    {
      state: ProcessState.FAILED,
      stringKey: 'PROCESS_STATE_FAILED',
      iconClass: 'icomoon-trash',
      badgeStyle: BadgeStyle.DANGER
    },
  ];

  export interface CreateRequest {
    workflowId: number;
    name?: string;
    externalId?: string;
    description?: string;
    deadline?: OffsetDateTime;
    taskRecordNamePattern?: string;
    taskRecordQuickCreateRequest: TaskRecord.QuickCreateRequest;
  }

  export interface UpdateRequest {
    id: number;
    name?: string;
    externalId: string;
    description?: string;
    deadline?: OffsetDateTime;
    taskRecordNamePattern?: string;
  }

  export enum ProcessFieldType {
    NAME = 'NAME',
    EXTERNAL_ID = 'EXTERNAL_ID',
    DESCRIPTION = 'DESCRIPTION',
    DEADLINE = 'DEADLINE'
  }

  export const processFieldTypes = [
    {fieldType: ProcessFieldType.NAME, stringKey: 'COMMON_NAME'},
    {fieldType: ProcessFieldType.EXTERNAL_ID, stringKey: 'COMMON_EXTERNAL_ID'},
    {fieldType: ProcessFieldType.DESCRIPTION, stringKey: 'COMMON_DESCRIPTION'},
    {fieldType: ProcessFieldType.DEADLINE, stringKey: 'TASK_LABEL_DEADLINE'}
  ]

  export function toResourceQueryRequest(
    filterField: FilterField.Process,
    orderField: OrderField.Process,
    fields: Process.Fields.Process,
    request: QueryRequest): ProcessResource.QueryRequest {
    const filter: string | undefined = DqlQuery.toOptionalFilter(filterField, request.filter);
    const order: string | undefined = DqlQuery.toOptionalOrder(orderField, request.order);
    const field: string | undefined = DqlQuery.toOptionalFields(fields, request.fields);
    return {
      filter: filter,
      order: order,
      fields: field,
      page_number: request.paging ? request.paging.pageNumber : undefined,
      number_of_items: request.paging ? request.paging.numberOfItems : undefined,
      rights: DqlQuery.RightRequestSerializer.getInstance().serialize(request.rights),
      no_progress_bar: request.noProgressBar
    };
  }

  export function toResourceXlsExportRequest(
    filterField: FilterField.Process,
    request: QueryRequest): ProcessResource.XlsExportRequest {
    const filter: string | undefined = DqlQuery.toOptionalFilter(filterField, request.filter);
    return {
      filter: filter
    };
  }

  export function toResourceGetRequest(
    fields: Process.Fields.Process,
    request: GetRequest): ProcessResource.GetRequest {
    const field: string | undefined = DqlQuery.toOptionalFields(fields, request.fields);
    return {
      id: request.id,
      fields: field,
      rights: DqlQuery.RightRequestSerializer.getInstance().serialize(request.rights),
      task_record_rights: DqlQuery.RightRequestSerializer.getInstance().serialize(request.taskRecordRights)
    };
  }

  export function toResourceCreateRequest(request: CreateRequest): ProcessResource.CreateRequest {
    return {
      workflow: {id: request.workflowId},
      name: request.name,
      external_id: request.externalId,
      description: request.description,
      deadline: Services.offsetDateTimeToString(request.deadline),
      task_record_name_pattern: request.taskRecordNamePattern,
      task_record_quick_create_request: TaskRecordMapper.toQuickCreateRequest(request.taskRecordQuickCreateRequest)
    };
  }

  export function toResourceUpdateRequest(request: UpdateRequest): ProcessResource.UpdateRequest {
    return {
      id: request.id,
      name: request.name,
      external_id: request.externalId,
      description: request.description,
      deadline: Services.offsetDateTimeToString(request.deadline)
    };
  }

  export function toPublic(iconService: IconService, r: ProcessResource.Process): Process {
    return {
      id: r.id,
      grantedRights: r.granted_rights ? Set.of(...r.granted_rights) : Set.of(),
      name: r.name,
      externalId: r.external_id,
      description: r.description,
      creationTime: Services.toOffsetDateTime(r.creation_time),
      updateTime: Services.toOffsetDateTime(r.update_time),
      startTime: r.start_time ? Services.toOffsetDateTime(r.start_time) : undefined,
      finishTime: r.finish_time ? Services.toOffsetDateTime(r.finish_time) : undefined,
      lastModifiedUser: toPublicUser(r.last_modified_user),
      creatorUser: toPublicUser(r.creator_user),
      state: <ProcessState>r.state,
      deadline: r.deadline ? Services.toOffsetDateTime(r.deadline) : undefined,
      taskRecordNamePattern: r.task_record_name_pattern,
      taskRecords: toPublicTaskRecords(r.task_records),
      workflow: toPublicWorkflow(iconService, r.workflow)
    };
  }

  export function toPublicUser(user: ProcessResource.User | undefined) {
    return user ? {
      id: user.id,
      userName: user.user_name,
      personName: user.person_name
    } : undefined
  }


  export function toPublicWorkflow(iconService: IconService,
                                   r?: WorkflowResource.Workflow): Workflow.Workflow | undefined {
    if (!r) {
      return undefined;
    }
    return Workflow.toPublic(iconService, r);
  }

  export function toPublicTaskRecords(r?: ProcessResource.TaskRecord[]): TaskRecord[] | undefined {
    if (!r) {
      return undefined;
    }
    return r.map(tr => {
      return {
        id: tr.id,
        taskId: tr.task_id,
        name: tr.name,
        grantedRights: tr.granted_rights ? Set.of(...tr.granted_rights) : Set.of(),
        creationTime: Services.toOffsetDateTime(tr.creation_time),
        state: <TaskRecordStateMachine.State>tr.state,
        workflowTask: tr.workflow_task,
        assigneeUser: tr.assignee_user ? {
          id: tr.assignee_user.id,
          personName: tr.assignee_user.person_name,
          userName: tr.assignee_user.user_name
        } : undefined,
        assigneeUserGroup: tr.assignee_user_group ? {
          id: tr.assignee_user_group.id,
          name: tr.assignee_user_group.name
        } : undefined,
        customerRecord: tr.customer_record ? {
          id: tr.customer_record.id,
          name: tr.customer_record.name
        } : undefined,
        contactLocation: tr.contact_location ? {
          id: tr.contact_location.id,
          name: tr.contact_location.name
        } : undefined,
        importance: <TaskRecord.TaskRecordImportance>tr.importance,
        deadline: tr.deadline ? Services.toOffsetDateTime(tr.deadline) : undefined,
        placeOfConsumption: tr.place_of_consumption ? {
          address: AddressResource.Mapper.toPublicPostalAddressOpt(tr.place_of_consumption.address),
          coordinate: AddressResource.Mapper.toPublicCoordinate(tr.place_of_consumption.coordinate),
          geocodeStatus: <TaskRecord.GeocodeStatus>tr.place_of_consumption.geocode_status
        } : undefined
      };
    });
  }

  export enum ValidatedField {
    UNKNOWN,
    NAME,
    EXTERNAL_ID,
    DESCRIPTION,
    DEADLINE
  }

  export namespace Fields {

    export class Process {

      readonly id: Query.Field = new DqlQuery.Field('id');
      readonly grantedRights: Query.Field = new DqlQuery.Field('granted_rights');
      readonly name: Query.Field = new DqlQuery.Field('name');
      readonly externalId: Query.Field = new DqlQuery.Field('external_id');
      readonly description: Query.Field = new DqlQuery.Field('description');
      readonly creationTime: Query.Field = new DqlQuery.Field('creation_time');
      readonly updateTime: Query.Field = new DqlQuery.Field('update_time');
      readonly startTime: Query.Field = new DqlQuery.Field('start_time');
      readonly finishTime: Query.Field = new DqlQuery.Field('finish_time');
      readonly lastModifiedUser: Query.Field = new DqlQuery.Field('last_modified_user');
      readonly creatorUser: Query.Field = new DqlQuery.Field('creator_user');
      readonly state: Query.Field = new DqlQuery.Field('state');
      readonly deadline: Query.Field = new DqlQuery.Field('deadline');
      readonly taskRecordNamePattern: Query.Field = new DqlQuery.Field('task_record_name_pattern');
      readonly taskRecords: Query.Field = new DqlQuery.Field('task_records');
      readonly workflow: Query.Field = new DqlQuery.Field('workflow');
      readonly workflowBase: Query.Field = new DqlQuery.Field(`workflow(${Process.workflowBase()})`);

      get each(): Set<Query.Field> {
        return Set.of(
          this.id,
          this.name,
          this.externalId,
          this.description,
          this.creationTime,
          this.updateTime,
          this.startTime,
          this.finishTime,
          this.lastModifiedUser,
          this.creatorUser,
          this.state,
          this.deadline,
          this.taskRecords,
          this.workflow,
          this.taskRecordNamePattern
        );
      }

      get forList(): Set<Query.Field> {
        return Set.of(
          this.id,
          this.name,
          this.externalId,
          this.deadline,
          this.updateTime,
          this.creationTime,
          this.startTime,
          this.finishTime,
          this.lastModifiedUser,
          this.creatorUser,
          this.state,
          this.workflowBase
        );
      }

      get forMessages(): Set<Query.Field> {
        return Set.of(
          this.id,
          this.name,
          this.externalId,
        );
      }

      static workflowBase(): string {
        return 'id,name,icon';
      }
    }

  }

}

// <editor-fold desc="Internal">


class Keys {

  private static readonly NAME = 'name';
  private static readonly EXTERNAL_ID = 'external_id';
  private static readonly DESCRIPTION = 'description';
  private static readonly DEADLINE = 'deadline';

  private static readonly keyValidatedFieldMap: ImmutableMap<string, Process.ValidatedField> = ImmutableMap.of(
    Keys.NAME, Process.ValidatedField.NAME,
    Keys.EXTERNAL_ID, Process.ValidatedField.EXTERNAL_ID,
    Keys.DESCRIPTION, Process.ValidatedField.DESCRIPTION,
    Keys.DEADLINE, Process.ValidatedField.DEADLINE,
  );

  public static toValidatedField(fieldKey: string): Process.ValidatedField {
    return Keys.keyValidatedFieldMap.get(fieldKey, Process.ValidatedField.UNKNOWN);
  }

}

// </editor-fold>


