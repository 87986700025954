/* eslint-disable */
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../lib/auth.service';
import { QueryFieldModel, SelectUtils, UiConstants } from '../../../util/core-utils';
import { Arrays } from '../../../lib/util/arrays';
import { Strings } from '../../../lib/util/strings';
import { DocumentGroupService } from '../../../lib/document/document-group.service';
import { DocumentGroupSearchModel, LanguageCodeItem } from '../../../util/document-group.utils';
import { RootCoreService } from '../../../lib/root-core.service';
import { StringKey } from '../../../app.string-keys';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { StateName } from '../../../app.state-names';
import { RightModel } from '../../../app.rights';
import { RightResolver, RightService } from '../../../lib/right.service';
import { combineLatest, Observable } from 'rxjs';
import { DocumentGroupSearch, DocumentGroupSearchService } from '../../../lib/document/document-group-search.service';
import { OrderType, ResourceQueryResult, Services } from '../../../lib/util/services';
import { DocumentGroup } from '../../../lib/document/document-group.service';
import { Set } from 'immutable';
import { EmptyMessage } from '../../../lib/util/messages';
import { DisabledEnum, DisabledItem } from '../../../util/search-utils';
/* eslint-enable */

@Component({
  selector: 'app-document-group-list',
  templateUrl: 'document-group-list.component.html',
  styleUrls: ['document-group-list.component.scss']
})
export class DocumentGroupListComponent implements OnInit, AfterViewInit, OnDestroy {
  DocumentGroup = DocumentGroup;
  UiConstants = UiConstants;

  queryModel: QueryFieldModel<DocumentGroup.OrderField> = new QueryFieldModel(DocumentGroup.OrderField.ID, OrderType.DESC);
  searchModel: DocumentGroupSearchModel;
  disabledItems: DisabledItem[] = [];
  showSearch: boolean = false;
  languageCodes: LanguageCodeItem[] = [];

  uploadGlobalErrors: string;
  uploadCollectionFieldErrors: string;
  documentGroups: DocumentGroup[] = [];
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  rightModel: RightModel = RightModel.empty();

  SelectUtils = SelectUtils;

  ngOnInit() {
    this.loadRightModels();
    this.translateService.get('COMMON_DOCUMENT_GROUPS').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.loadSearch(() => {
      this.showSearch = !this.searchModel.isEmpty();
      this.initDisabledOptions(DisabledEnum.FALSE);
      this.loadList();
    });
  }

  ngAfterViewInit(): void {
    this.getLanguageCodes();
    this.loadList();
  }

  onSearchClicked() {
    this.loadList();
  }

  onSearchReset() {
    this.documentGroupSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.showSearch = true;
          this.loadList(1);
        });
      }
    );
  }

  private saveSearch() {
    const request = {
      searchData: {
        itemsPerPage: this.queryModel.itemsPerPage,
        pageNumber: this.queryModel.currentPage,
        order: this.queryModel.getOrder(),
        id: this.searchModel.id,
        name: this.searchModel.name,
        code: this.searchModel.code,
        languageCode: this.searchModel.languageCode,
        disabled: this.searchModel.disabled
      }
    };
    this.documentGroupSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: DocumentGroup.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  private loadSearch(completion: () => void) {
    const obs: Observable<SearchLoadResult> = combineLatest(
      this.documentGroupSearchService.getSearchData({}),
      (storedSearchData: DocumentGroupSearch.SearchDataResult) => {
        const result: SearchLoadResult = {
          storedSearchData: storedSearchData
        };
        return result;
      }
    );
    obs.subscribe(
      (result: SearchLoadResult) => {
        this.postInitSearch(result.storedSearchData);
        completion();
      }
    );
  }

  private postInitSearch(storedSearchData: DocumentGroupSearch.SearchDataResult) {
    this.queryModel.itemsPerPage = storedSearchData.searchData.itemsPerPage;
    this.queryModel.currentPage = storedSearchData.searchData.pageNumber;
    this.queryModel.setOrder(storedSearchData.searchData.order);
    this.searchModel.id = storedSearchData.searchData.id;
    this.searchModel.name = storedSearchData.searchData.name;
    this.searchModel.code = storedSearchData.searchData.code;
    this.searchModel.languageCode = storedSearchData.searchData.languageCode;
    this.searchModel.disabled = storedSearchData.searchData.disabled;
  }

  private getLanguageCodes() {
    this.languageCodes = [];
    this.loadDefaultItem();
    this.rootService.getLanguageCodes({}).subscribe(
      (result: string[]) => {
        this.languageCodes.push(...result.map((lc: string): LanguageCodeItem => {
          return {
            id: lc,
            text: lc
          }
        }));
      }
    );
  }

  private loadDefaultItem() {
    this.translateService.get(StringKey.COMMON_VALUE_UNSELECTED).subscribe((text: string) => {
      const def = {id: null, text: text};
      this.languageCodes.push(def);
      if (!this.searchModel.languageCode) {
        this.searchModel.languageCode = def;
      }
    });
  }

  private initDisabledOptions(initValue: DisabledEnum) {
    this.disabledItems = [];
    const disabledEnums: DisabledEnum[] = [DisabledEnum.NONE, DisabledEnum.FALSE, DisabledEnum.TRUE];
    Arrays.iterateByIndex(disabledEnums, (key) => {
      const item = new DisabledItem();
      item.id = key;
      this.translateService.get('COMMON_VALUE_DISABLED_ENUM_' + key).subscribe(
        (text: string) => {
          item.text = text;
        }
      );
      this.disabledItems.push(item);
      if (!this.searchModel.disabled) {
        if (key === initValue) {
          this.searchModel.disabled = item;
        }
      }
    });
  }

  private loadList(pageNumber?: number) {
    const disabled: boolean | undefined = !this.searchModel.disabled ||
    this.searchModel.disabled.id === DisabledEnum.NONE ?
      undefined : this.searchModel.disabled.id === DisabledEnum.TRUE;

    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();
    this.documentGroupService.query({
      name: Strings.undefinedOrNonEmpty(this.searchModel.name),
      language_code: this.searchModel.languageCode && this.searchModel.languageCode.id ? this.searchModel.languageCode.id : undefined,
      code: Strings.undefinedOrNonEmpty(this.searchModel.code),
      disabled: disabled,
      page_number: requestedPage,
      number_of_items: this.queryModel.itemsPerPage,
      order: Services.createOrderFieldParameter(DocumentGroup.Keys.toOrderFieldKey, Set.of(order)),
    })
      .subscribe(
      (result: ResourceQueryResult<DocumentGroup>) => {
        this.documentGroups = result.items;
        this.queryModel.currentPage = requestedPage;
        this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
        this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
      }
    );
  }

  setDisabled(event: any, documentGroup: DocumentGroup, disabled: boolean) {
    if (documentGroup.id) {
      this.documentGroupService.setDisabled({
        id: documentGroup.id,
        disabled: disabled
      })
        .subscribe(
        (result: EmptyMessage) => {
          this.loadList();
        },
        (error: any) => {
          this.loadList();
        }
      );
    }
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  ngOnDestroy() {
    this.saveSearch();
  }

  constructor(private translateService: TranslateService,
              private authService: AuthService,
              private rootService: RootCoreService,
              private documentGroupService: DocumentGroupService,
              private documentGroupSearchService: DocumentGroupSearchService,
              private rightService: RightService) {
    this.searchModel = new DocumentGroupSearchModel();
  }

}

interface SearchLoadResult {
  storedSearchData: DocumentGroupSearch.SearchDataResult,
}
