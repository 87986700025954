/* eslint-disable */
import { AfterViewInit, Component } from '@angular/core';
import { Transition, UIRouter } from '@uirouter/angular';
import { TranslateService } from '@ngx-translate/core';
import { DocumentHTMLEditModel, DocumentLanguageItem } from '../../../../util/document/document-utils';
import { DocumentHTML, DocumentHTMLService } from '../../../../lib/document/document-html.service';
import { Arrays } from '../../../../lib/util/arrays';
import { StringKey } from '../../../../app.string-keys';
import { DocumentLanguageService } from '../../../../lib/document/document-language.service';
import { DocumentLanguageUtil } from '../../../../util/document/document-language-utils';
import { DocumentGroupService } from '../../../../lib/document/document-group.service';
import { DocumentGroupItem, LanguageItem } from '../../../../util/document/document-file-utils';
import { DocumentGroup } from '../../../../lib/document/document-group.service';
import { ConfigurationResource, ConfigurationService } from '../../../../lib/core-ext/configuration.service';
import { UiConstants } from '../../../../util/core-utils';
import { ResourceQueryResult } from '../../../../lib/util/services';
import { StateName } from '../../../../app.state-names';
import { EmptyMessage } from '../../../../lib/util/messages';
/* eslint-enable */

const ClassicEditor = require( '@ckeditor/ckeditor5-build-classic' );

@Component({
  selector: 'app-document-html-edit',
  templateUrl: 'document-html-edit.component.html',
  styleUrls: ['document-html-edit.component.scss'],
})
export class DocumentHTMLEditComponent implements AfterViewInit {
  ConfigurationService = ConfigurationService;

  documentId: number;
  model: DocumentHTMLEditModel;
  documentGroupList: DocumentGroupItem[] = [];
  documentLanguageList: DocumentLanguageItem[] = [];
  editor: any;
  configuration: ConfigurationResource.Configuration;

  constructor(
    private documentLanguageService: DocumentLanguageService,
    private documentLanguageUtil: DocumentLanguageUtil,
    private translateService: TranslateService,
    private documentGroupService: DocumentGroupService,
    private documentService: DocumentHTMLService,
    private configurationService: ConfigurationService,
    private uiRouter: UIRouter,
    private transition: Transition) {
    this.configuration = this.configurationService.getConfiguration();
    this.documentId = this.transition.params().id;
    this.model = new DocumentHTMLEditModel();
  }

  ngAfterViewInit(): void {
    this.loadModel(() => {
      ClassicEditor.create(document.querySelector('#editor'), {
        toolbar: [ 'headings', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ],
        heading: {
          options: [
            {modelElement: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph'},
            {modelElement: 'heading4', viewElement: 'h4', title: 'Heading', class: 'ck-heading_heading4'}
          ]
        }
      }).then(editor => {
        editor.setData(this.model.value);
        this.editor = editor;

      });
    });

  }

  private loadModel(completion: () => void) {
    this.documentService.get({id: this.documentId})
      .subscribe(
      (documentHTML: DocumentHTML) => {
        const readModel = new DocumentHTMLEditModel();
        readModel.id = documentHTML.id;
        readModel.name = documentHTML.name;
        readModel.code = documentHTML.code;
        readModel.value = documentHTML.value;
        readModel.comment = documentHTML.comment;
        readModel.creation_time = documentHTML.creation_time;
        readModel.update_time = documentHTML.update_time;
        readModel.disabled = documentHTML.disabled;
        this.model = readModel;

        this.loadDocumentGroupList(documentHTML.document_group_id);
        this.loadLanguageCodes(documentHTML.language_code);
        completion();
      }
    );
  }

  private loadLanguageCodes(language_code?: string) {
    this.documentLanguageService.query().subscribe( (result: string[]) => {
      const def = this.createDefaultLanguageCode();
      this.model.language_code = def;
      this.documentLanguageList.push(def);

      Arrays.iterateByIndex( result, language => {
        const languageItem = new DocumentLanguageItem();
        languageItem.id = language;
        languageItem.text = this.documentLanguageUtil.getDocumentLanguageName(language);

        if (languageItem.id === language_code) {
          this.model.language_code = languageItem;
        }
        this.documentLanguageList.push(languageItem);
      });
    });
  }

  private loadDocumentGroupList(groupId?: number) {
    this.documentGroupService.query({}).subscribe((result: ResourceQueryResult<DocumentGroup>) => {
      const def = this.createDefaultDocumentGroup();
      this.model.document_group = def;
      this.documentGroupList.push(def);
      Arrays.iterateByIndex(result.items, documentGroup => {
        const documentGroupItem = new DocumentGroupItem();
        documentGroupItem.id = documentGroup.id;
        documentGroupItem.text = documentGroup.name;
        if (documentGroup.id === groupId) {
          this.model.document_group = documentGroupItem;
        }
        this.documentGroupList.push(documentGroupItem);
      });
    });
  }

  private createDefaultDocumentGroup(): DocumentGroupItem {
    const def = new DocumentGroupItem();
    def.id = null;
    def.text = '';
    this.translateService.get(StringKey.COMMON_VALUE_UNSELECTED).subscribe(
      (text: string) => {
        def.text = text;
      }
    );
    return def;
  }

  private createDefaultLanguageCode(): LanguageItem {
    const def = new LanguageItem();
    def.id = null;
    def.text = '';
    this.translateService.get(StringKey.COMMON_VALUE_UNSELECTED).subscribe(
      (text: string) => {
        def.text = text;
      }
    );
    return def;
  }

  getTextMaximumLength(): number {
    return UiConstants.maximumVarcharLength;
  }

  private validate(): boolean {
    if (!this.model.code || this.model.code.length < 1) {
      return false
    }
    return true;
  }

  update() {

    if (!this.model.id) {
      return;
    }

    if (!this.validate()) {
      return;
    }

    this.model.value = this.editor.data.get();
    const languageCodeId = (this.model.language_code) ? this.model.language_code.id : null;
    const document_group_id = (this.model.document_group) ? this.model.document_group.id : null;

    this.documentService.update({
      id: this.model.id,
      name: this.model.name,
      code: this.model.code,
      value: this.model.value,
      language_code: languageCodeId ? languageCodeId : undefined,
      document_group_id: document_group_id ? document_group_id : undefined,
      comment: this.model.comment

    }).subscribe(
      (response: EmptyMessage) => {
        this.uiRouter.stateService.go(StateName.DOCUMENT_HTML_LIST);
      }
    );
  }

  back() {
    window.history.back();
  }
}
