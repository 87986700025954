/* eslint-disable */
import { Component, ViewChild, } from '@angular/core';
import {
  FormRecordFieldContext,
  FormRecordFieldValueUpdateArgs,
  FormRecordFieldView,
  FormRecordFieldViewContext,
  FormRecordFieldViewModel,
  SetTmpReadonlyArgs,
  SetTmpReadonlyResult,
} from '../../../../../util/form/form-utils';
import { Form } from '../../../../../lib/form/form.service';
import { AbstractControl, FormBuilder, FormGroup, Validators, } from '@angular/forms';
import { FormRef, ForwardingFormRef, LocalFormGroupValidationErrors, } from '../../../../../lib/util/services';
import { FormRecord } from '../../../../../lib/form/form-record.service';
import { AppValidators } from '../../../../../util/app-validators';
import { Models } from '../../../../../util/model-utils';
import { NgbDatepicker, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Dates, DefaultLocalDateTemplate, LocalDate, } from '../../../../../lib/util/dates';
import { NgbDatePickerParserFormatter } from '../../../../../util/ngb-datepicker';
import { FieldActivationState, FieldActivationStateResolver, } from '../../../../../util/form/form-editors';
import { Command } from '../../../../../util/command';
import { StringKey } from '../../../../../app.string-keys';
import { TranslateService } from '@ngx-translate/core';
import { FormRecordInactivityManager } from '../../manager/form-record-inactivity-manager';
import { FormEditCreateUpdateDateFieldDialogComponent } from '../../../form-edit/dialogs/fields/date/create-update/form-edit-create-update-date-field-dialog.component';
import { Observable } from 'rxjs';

/* eslint-enable */

@Component({
  selector: 'app-form-record-date-field',
  templateUrl: 'form-record-date-field.component.html',
  styleUrls: ['form-record-date-field.component.scss'],
})
export class FormRecordDateFieldComponent implements FormRecordFieldView {

  public readonly selector: Form.FieldDataTypeSelector.DATE;

  formGroup: FormGroup;

  model: Model = new Model();

  @ViewChild('date_picker', { static: true })
  datePicker: NgbDatepicker;

  // context fields are always optional
  formRecordFieldContext?: FormRecordFieldContext;
  formRecordInactivityManager?: FormRecordInactivityManager;
  private fieldId?: number;
  private htmlForm?: FormRef;

  tmpReadonly: boolean = false;

  private optionalValue: boolean = false;

  private formGroupValidationErrors: LocalFormGroupValidationErrors;

  private additionalDays?: number;

  private readonlyFormFn: () => boolean = () => true;

  private readonlyFieldFn: () => boolean = () => false;
  private hiddenFieldFn: () => boolean = () => false;
get nonEditable(): boolean {
    return FieldActivationStateResolver.isNonEditable(
      this.fieldActivationState
    );
  }

  get requiredDisabled(): boolean {
    return FieldActivationStateResolver.isRequiredDisabled(
      this.fieldActivationState
    );
  }

  get required(): boolean {
    const optional = this.optionalValue;
    const requiredDisabled = this.requiredDisabled;
    return !optional && !requiredDisabled;
  }

  get refreshable(): boolean {
    return !this.nonEditable &&
      this.reqContext.field.apiExportName === FormEditCreateUpdateDateFieldDialogComponent.DEADLINE_API_NAME;
  }

  private get fieldActivationState(): FieldActivationState {
    return FieldActivationStateResolver.resolveFieldActivationState({
      readonlyFormFn: () => this.readonlyFormFn(),
      readonlyFieldFn: () => this.readonlyFieldFn(),
      tmpReadonlyFieldFn: () => this.tmpReadonly,
    });
  }

  private get canApplyDefaultValue(): boolean {
    return FieldActivationStateResolver.canApplyDefaultValue({
      formRecordFieldContext: () => {
        return this.formRecordFieldContext;
      },
      fieldActivationState: () => {
        return this.fieldActivationState;
      },
    });
  }

  private get valueFormControl(): AbstractControl {
    return this.formGroup.get('value')!;
  }

  private get reqContext() {
    return this.formRecordFieldContext!;
  }

  private get reqAttrs(): Form.FieldDataTypeDateAttributes {
    return this.formRecordFieldContext!.field.dataType.dateAttributes!;
  }

  private get reqDefaultValue(): NgbDateStruct | null {
    return this.reqAttrs.defaultToday
      ? this.today
      : this.datePickerParserFormatter.fromLocalDate(this.reqAttrs.defaultValue);
  }

  private get today(): NgbDateStruct | null {
    const today: LocalDate = Dates.today();
    return this.datePickerParserFormatter.fromLocalDate(today);
  }

  constructor(private fb: FormBuilder,
              private translateService: TranslateService,
              private datePickerParserFormatter: NgbDatePickerParserFormatter) {
    this.formGroup = this.createFormGroup(this.fb);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(
      this.createForwardingHtmlForm(),
      this.formGroup,
    );
  }

  setTmpReadonly(args: SetTmpReadonlyArgs): Command<SetTmpReadonlyResult> {
    const previousTmpReadonly = this.tmpReadonly;
    const previousValue = this.model.value;
    return {
      execute: async () => {
        const inactiveValue: NgbDateStruct | null = null;
        const defaultValue: NgbDateStruct | null = this.reqDefaultValue;
        let changed = false;
        if (this.tmpReadonly !== args.tmpReadonly) {
          if (args.tmpReadonly) {
            changed = FieldActivationStateResolver.inactivationChangesTheValue({
              debugId: this.reqContext.field.title,
              valueIsEmpty: this.model.value === inactiveValue,
              valueEqualsDefaultValue: this.model.value === defaultValue,
              defaultValueIsEmpty: defaultValue === inactiveValue,
              canApplyDefaultValue: this.canApplyDefaultValue
            });
            this.model.value = inactiveValue;
            this.tmpReadonly = args.tmpReadonly; // last
          }
          else {
            this.tmpReadonly = args.tmpReadonly; // first
            this.applyDefaultValue();
          }
          this.valueFormControl.updateValueAndValidity();
        }
        return {
          changed: changed
        };
      },
      undo: async () => {
        this.tmpReadonly = previousTmpReadonly;
        this.model.value = previousValue;
        this.valueFormControl.updateValueAndValidity();
      }
    };
  }

  registerField(context: FormRecordFieldContext, originalModel?: any): any {
    if (originalModel) {
      this.model = originalModel;
    }
    this.formRecordFieldContext = context;
    const today: LocalDate = Dates.today();
    this.fieldId = context.field.fieldId;
    this.htmlForm = context.htmlForm;
    this.readonlyFormFn = context.readonly;
    this.hiddenFieldFn = () => Form.FormFieldValidationType.HIDDEN === context.validationType;
    this.readonlyFieldFn = () => Form.FormFieldValidationType.READONLY === context.validationType
    || Form.FormFieldValidationType.HIDDEN === context.validationType;
    const attrs = context.field.dataType.dateAttributes!;
    this.optionalValue = Form.FormFieldValidationType.REQUIRED !== context.validationType;
    this.model.title = context.field.title;
    this.model.hint = Models.optToString(context.field.hint);
    this.model.placeholder = Models.optToString(attrs.hint);
    this.model.minValue = this.datePickerParserFormatter.fromLocalDate(attrs.minValue);
    this.model.minValue = attrs.disablePast
      ? this.datePickerParserFormatter.fromLocalDate(today) : this.model.minValue;
    this.model.maxValue = this.datePickerParserFormatter.fromLocalDate(attrs.maxValue);
    this.model.maxValue = attrs.disableFuture
      ? this.datePickerParserFormatter.fromLocalDate(today) : this.model.maxValue;
    this.applyDefaultValue();
    if (context.fieldRecord) {
      this.registerFieldData(context.fieldRecord);
    }
    return this.model;
  }

  // If we set min or max date, the date picker will be a required field.
  // It's a side-effect of the component. :(
  private initDatePicker() {
    if (this.model.minValue) {
      this.datePicker.minDate = this.model.minValue;
    }
    if (this.model.maxValue) {
      this.datePicker.maxDate = this.model.maxValue;
    }
  }

  private applyDefaultValue() {
    if (this.canApplyDefaultValue) {
      this.model.value = this.reqDefaultValue;
    }
  }

  private registerFieldData(fieldRecord: FormRecord.FieldComposed): void {
    const attrs = fieldRecord.data.dateAttributes!;
    if (fieldRecord) {
      if (attrs.value) {
        if (this.readonlyFieldFn() && this.reqContext.cloning()) {
          // Do not use read-only data for cloning.
          // Note that readonly field is filtered from the service request.
          this.model.value = null;
        }
        else {
          this.model.value = this.datePickerParserFormatter.fromLocalDate(attrs.value);
        }
      }
      else {
        this.model.value = null;
      }
      this.formGroup.controls.value.updateValueAndValidity();
    }
  }

  registerFieldViews(context: FormRecordFieldViewContext): void {
    this.formRecordInactivityManager = context.inactivityManager;
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  validateWithInterrupt(): boolean {
    return false;
  }

  shouldNotifyAfterCreation(): boolean {
    return false;
  }

  afterFormRecordCreation(formRecordId: number): Observable<any> | undefined {
    return undefined;
  }

  createModel(): FormRecordFieldViewModel {
    if (this.fieldId === undefined) {
      throw new Error('Field ID is undefined');
    }
    const attrs: FormRecord.FieldDataDateAttributes = {
      value: this.datePickerParserFormatter.toLocalDate(this.model.value),
    };
    return {
      fieldEditRequest: {
        fieldId: this.fieldId,
        data: {
          dateAttributes: attrs
        }
      }
    };
  }

  onSelect() {
    this.formRecordInactivityManager!.onGeneralFieldChangedByUser(this);
  }

  onInput() {
    this.formRecordInactivityManager!.onGeneralFieldChangedByUser(this);
  }

  private createForwardingHtmlForm() {
    return new ForwardingFormRef({
      formFn: () => {
        return this.htmlForm;
      }
    });
  }

  private createFormGroup(fb: FormBuilder) {
    return fb.group(
      {
        value: fb.control(
          {value: this.model.value},
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => {
                return !this.required;
              }
            }),
            AppValidators.validateLocalDateValue({
              datePickerParserFormatter: this.datePickerParserFormatter,
              value: () => this.model.value
            }),
            AppValidators.validateLocalDateRange({
              min: () => this.model.minValue,
              max: () => this.model.maxValue,
              value: () => this.model.value,
              datePickerParserFormatter: this.datePickerParserFormatter
            }),
          ]
        ),
      },
    );
  }

  private get minValue(): LocalDate | null {
    if (!this.formRecordFieldContext) {
      return null;
    }
    const attrs = this.formRecordFieldContext.field.dataType.dateAttributes!;
    if (attrs.disablePast) {
      return Dates.today();
    }
    return attrs.minValue ? attrs.minValue : null;
  }

  get minValueText(): string | undefined {
    const minValue = this.minValue;
    if (!minValue || !minValue.isValid()) {
      return undefined; // should not happen :P
    }
    return this.format(minValue);
  }

  private get maxValue(): LocalDate | null {
    if (!this.formRecordFieldContext) {
      return null;
    }
    const attrs = this.formRecordFieldContext.field.dataType.dateAttributes!;
    if (attrs.disableFuture) {
      return Dates.today();
    }
    return attrs.maxValue ? attrs.maxValue : null;
  }

  get maxValueText(): string {
    const maxValue = this.maxValue;
    if (!maxValue) {
      return 'max'; // should not happen :P
    }
    return this.format(maxValue);
  }

  private format(minValue: LocalDate): string {
    const pattern = this.translateService.instant(StringKey.MOMENT_SHORT_DATE_PATTERN);
    const template = new DefaultLocalDateTemplate(pattern);
    return minValue.format(template);
  }

  updateValue(data: FormRecordFieldValueUpdateArgs) {
    if (data instanceof FormRecordDateFieldUpdateArgs) {
      this.additionalDays = data.addDays;
    }
  }

  refreshValue() {
    if (this.nonEditable) {
      return;
    }
    let defValue: NgbDateStruct | null = this.reqDefaultValue;
    if (!defValue) {
      defValue = this.today!;
    }
    let newValue = this.datePickerParserFormatter.toLocalDate(defValue);
    if (this.additionalDays !== undefined) {
      if (this.additionalDays >= 0) {
        newValue = newValue.plusDays(this.additionalDays);
      }
      else {
        newValue = newValue.minusDays(-1 * this.additionalDays);
      }
    }
    this.model.value = this.datePickerParserFormatter.fromLocalDate(newValue);

  }

  onValueChanged() {
    this.formGroup.controls['value'].updateValueAndValidity();
  }

}

export class Model {
  title: string = '';
  hint: string = '';
  placeholder: string = '';
  value: NgbDateStruct | null = null;
  minValue: NgbDateStruct | null = null;
  maxValue: NgbDateStruct | null = null;
}

export class FormRecordDateFieldUpdateArgs implements FormRecordFieldValueUpdateArgs {

  constructor(public addDays?: number) {

  }

}
