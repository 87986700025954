<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div class="breadcrumb-menu d-flex">
    <div
      *ngIf="rightModel.userGroupCreateAll.hasRight() || (rightModel.userGroupCreateMyCompany.hasRight() && userData && userData.companies.length > 0)"
      uiSref="Admin.UserGroupCreate"
      class="breadcrumb-button-icon-container negative-margin-right cursor-pointer"
    >
      <a class="btn-setting" title="{{'USER_GROUP_PANEL_HEADING_CREATE' | translate}}">
        <i class="icomoon icomoon-add"></i>
        <span class="breadcrumb-button-text-visible">{{'USER_GROUP_PANEL_HEADING_CREATE' | translate}}</span>
      </a>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="card">

    <div class="card-header">
      {{'MENU_NAVBAR_MENU_USER_GROUPS' | translate}}

      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()"
           title="{{'COMMON_SHOW_SEARCH_HINT' | translate}}"><i #searchIcon
                                                                class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>

    <div class="card-body border-bottom" *ngIf="showSearch">
      <div class="row form-group">
        <div class="col-md-4">
          <label class="search-label">{{'USER_GROUP_SEARCH_FIELD_ID' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'USER_GROUP_SEARCH_FIELD_ID' | translate}}"
                 maxlength="{{UiConstants.maxInputLongLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [textMask]="{mask: InputMask.NATURAL_INTEGER, guide: false}"
                 [(ngModel)]="searchModel.id" name="id" id="id" #id="ngModel">
        </div>
        <div class="col-md-4">
          <label class="search-label">{{'COMMON_NAME' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.name" name="name" id="name" #name="ngModel">
        </div>
        <div class="col-md-4">
          <label class="search-label">{{'USER_GROUP_SEARCH_FIELD_SCOPE'|translate}}</label>
          <angular2-multiselect
            [(ngModel)]="searchModel.applicationTypes"
            [ngModelOptions]="{standalone: true}"
            [data]="applicationTypes"
            [settings]="applicationTypeDropdownSettings">
          </angular2-multiselect>
        </div>
        <div class="col-md-4">
          <label class="search-label">{{'ROLE_LIST_HEADING'|translate}}</label>
          <angular2-multiselect
            [(ngModel)]="searchModel.roles"
            [ngModelOptions]="{standalone: true}"
            [data]="roleSearchList"
            (onRemoteSearch)="loadRolesForSearch($event.target.value)"
            [settings]="companyDropdownSettings">
          </angular2-multiselect>
        </div>
        <div class="col-md-4">
          <label class="search-label">{{'COMMON_COMPANY'|translate}}</label>
          <angular2-multiselect
            [(ngModel)]="searchModel.companies"
            [ngModelOptions]="{standalone: true}"
            [data]="companiesForSearch"
            (onRemoteSearch)="loadCompaniesForSearch($event.target.value)"
            [settings]="companyDropdownSettings">
          </angular2-multiselect>
        </div>
        <div class="col-md-4">
          <label class="search-label">{{'USER_GROUP_SEARCH_FIELD_DISABLED' | translate}}</label>
          <select class="form-control" [compareWith]="SelectUtils.compareObjects" [(ngModel)]="searchModel.disabled"
                  id="disabled" name="disabled" #disabled="ngModel">
            <option *ngFor="let item of userGroupDisabledItems" [ngValue]="item">{{item.text}}</option>
          </select>
        </div>
      </div>
      <!-- /.row form-group -->
      <div class="col-md-12 d-flex justify-content-md-end align-items-end pr-1 pl-0 mb-1">
        <div class="btn-group" role="group">
          <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
            {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
          </button>
          <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
            {{'COMMON_BUTTON_SEARCH' | translate}}
          </button>
        </div>
      </div>
    </div><!-- /.card-body-->
    <div class="card-body">

      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header w-5 hidden-xs-down">
            <app-table-field-sorter [orderField]="UserGroup.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(UserGroup.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="UserGroup.OrderField.NAME"
                                    [orderType]="queryModel.getOrderType(UserGroup.OrderField.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'USER_GROUPS_TABLE_HEADER_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-sorter-no-op [text]="'USER_GROUP_LABEL_SCOPE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op [text]="'COMMON_COMPANY' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-sorter-no-op [text]="'ROLE_LIST_HEADING' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let userGroup of userGroupList | paginate: {
                        itemsPerPage: queryModel.itemsPerPage,
                        currentPage: queryModel.currentPage,
                        totalItems: queryModel.currentNumberOfItems }"
            [disabledTableRow]="userGroup.disabled">
          <td class="responsive-table-column hidden-xs-down test_user_group_id">{{userGroup.id}}</td>
          <td class ="responsive-table-column test_user_group_name">
            <a uiSref="Admin.UserGroupDetail"
               [uiParams]="{ id: userGroup.id }">
              {{userGroup.name}}
            </a>
            <br/>
            <span class="text-truncate table-text-subtitle">{{userGroup.external_id}}</span>
          </td>
          <td class ="hidden-md-down fit">{{userGroup.application_types.join(', ')}}</td>
          <td class ="responsive-table-column hidden-sm-down">{{userGroup.company ? userGroup.company.name : ''}}</td>
          <td class ="responsive-table-column hidden-md-down text-truncate"
              [popover]="getRoles(userGroup.roles).length > 0 ? rolesPopover : null"
              container="body" triggers="mouseenter:mouseleave">
            {{getFirstRole(userGroup.roles)}}
            <ng-template #rolesPopover>
              <ng-container *ngFor="let role of getRoles(userGroup.roles)">
                <span>{{role}}</span><br>
              </ng-container>
            </ng-template>
          </td>
          <td>
            <app-table-options-menu>
              <app-dropdown-item
                *ngIf="rightModel.userGroupRead.hasRight()"
                [iconClass]="'icomoon-detail'"
                [titleStringKey]="'COMMON_BUTTON_DETAILS'"
                class="cursor-pointer"
                uiSref="Admin.UserGroupDetail"
                [uiParams]="{ id: userGroup.id }">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="!userGroup.protected_group && canUpdateGroup(userGroup)"
                [iconClass]="'icomoon-modify'"
                [titleStringKey]="'COMMON_BUTTON_EDIT'"
                class="cursor-pointer"
                uiSref="Admin.UserGroupEdit"
                [uiParams]="{ id: userGroup.id }">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="!userGroup.protected_group && canCreateGroup(userGroup)"
                [iconClass]="'icomoon-clone'"
                [titleStringKey]="'COMMON_BUTTON_CLONE'"
                class="cursor-pointer"
                uiSref="Admin.UserGroupClone"
                [uiParams]="{ id: userGroup.id }">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="!userGroup.protected_group && !userGroup.disabled  && rightModel.userGroupDisable.hasRight()"
                [iconClass]="'icomoon-disable icomoon-disable-table'"
                [titleStringKey]="'COMMON_BUTTON_DISABLE'"
                class="cursor-pointer"
                href="#" onclick="return false"
                (click)="setDisabled($event, userGroup, true)">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="!userGroup.protected_group && userGroup.disabled  && rightModel.userGroupDisable.hasRight()"
                [iconClass]="'icomoon-enable icomoon-enable-table'"
                [titleStringKey]="'COMMON_BUTTON_ENABLE'"
                class="cursor-pointer"
                href="#" onclick="return false"
                (click)="setDisabled($event, userGroup, false)">
              </app-dropdown-item>
            </app-table-options-menu>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header w-5 hidden-xs-down">
            <app-table-field-sorter [orderField]="UserGroup.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(UserGroup.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="UserGroup.OrderField.NAME"
                                    [orderType]="queryModel.getOrderType(UserGroup.OrderField.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'USER_GROUPS_TABLE_HEADER_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-sorter-no-op [text]="'USER_GROUP_LABEL_SCOPE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op [text]="'COMMON_COMPANY' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-sorter-no-op [text]="'ROLE_LIST_HEADING' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>

      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>

    </div><!-- /.card-body -->

  </div><!-- /.card -->

</div><!-- /container -->

