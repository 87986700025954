/* eslint-disable */
import * as GoogleMapsLoader from 'load-google-maps-api';
import { from, Subscription, TeardownLogic } from 'rxjs';
/* eslint-enable */


export class GoogleMapsLoaderUtil {

  private static mapLoadStarted: boolean = false;
  private static mapLoaded: boolean = false;
  private static mapSubscriber: Subscription;

  static didMapLoad(): boolean {
    return this.mapLoaded;
  }

  static loadMap(apiKey: string): void {
    if (!this.mapLoaded && !this.mapLoadStarted) {
      this.mapLoadStarted = true;
      this.mapSubscriber = from(GoogleMapsLoader({
        key: apiKey,
        libraries: ['geometry']
      })).subscribe((gm) => {
        this.mapLoaded = true;
      });
    }
  }

  static subscribe(sub: TeardownLogic): void {
    if (this.mapSubscriber) {
      this.mapSubscriber.add(sub);
    }
  }
}
