/* eslint-disable */
import { List, Map, Set } from 'immutable';
import { Injectable } from '@angular/core';
import { OffsetDateTime } from '../util/dates';
import { Order, PagingRequest, QueryResult, ResourceQueryResult, Services } from '../util/services';
import { Observable, Observer } from 'rxjs';
import { DownloadedFile } from '../util/downloaded-files';
import { StockTakingsResource, StockTakingsResourceService } from './stock-takings-resource.service';
import { EmptyMessage } from '../util/messages';
/* eslint-enable */

@Injectable()
export class StockTakingsService implements StockTakings.Service {

  query(request: StockTakings.QueryRequest): Observable<QueryResult<StockTakings.StockTaking>> {
    return Observable.create((observer: Observer<QueryResult<StockTakings.StockTaking>>) => {
      const resourceRequest: StockTakingsResource.QueryRequest = {
        stock_taking_id: request.stockTakingId,
        owner_user_id: request.ownerUserId,
        name: request.name,
        trx_id: request.trxId,
        order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
      };
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<StockTakingsResource.StockTaking>) => {
          observer.next({
            items: this.toPublicList(result.items),
            pagingResult: result.pagingResult
          });
        });
    });
  }

  create(request: StockTakings.CreateRequest): Observable<StockTakings.CreateResponse> {
    return Observable.create((observer: Observer<StockTakings.CreateResponse>) => {
      const resourceRequest: StockTakingsResource.CreateRequest = {
        name: request.name,
        stock_ids: request.stockIds
      };
      return this.resourceService.create(resourceRequest).subscribe((result: StockTakingsResource.CreateResponse) => {
          observer.next({
            stockTakingId: result.stock_taking_id
          });
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  get(request: StockTakings.GetRequest): Observable<StockTakings.StockTaking> {
    return Observable.create((observer: Observer<StockTakings.StockTaking>) => {
      const resourceRequest: StockTakingsResource.GetRequest = {
        stock_taking_id: request.stockTakingId
      };
      return this.resourceService.get(resourceRequest).subscribe(
        (result: StockTakingsResource.StockTaking) => {
          observer.next(this.toPublic(result));
        });
    });
  }

  update(request: StockTakings.UpdateRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: StockTakingsResource.UpdateRequest = {
        stock_taking_id: request.stockTakingId,
        name: request.name,
        stock_ids: request.stockIds
      };
      return this.resourceService.update(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  downloadTemplate(stockTakingId: number): Observable<DownloadedFile> {
    return this.resourceService.downloadTemplate({
      stockTakingId: stockTakingId
    });
  }

  deleteTemplate(request: StockTakings.DeleteTemplateRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.resourceService.deleteTemplate({
        stock_taking_id: request.stockTakingId
      }).subscribe((result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  constructor(
    private resourceService: StockTakingsResourceService,
  ) {}

  private toPublicList(resourceList: StockTakingsResource.StockTaking[]): List<StockTakings.StockTaking> {
    return List.of(...resourceList.map((r) => this.toPublic(r)));
  }

  private toPublic(r: StockTakingsResource.StockTaking): StockTakings.StockTaking {
    return {
      stockTakingId: r.stock_taking_id,
      trxId: r.trx_id,
      creationTime: Services.toOffsetDateTime(r.creation_time),
      updateTime: Services.toOffsetDateTime(r.update_time),
      name: r.name,
      ownerUserId: r.owner_user_id,
      stockIds: r.stock_ids,
      pdfTemplate: this.toPublicPdfTemplate(r.pdf_template)
    };
  }

  private toPublicPdfTemplate(r?: StockTakingsResource.PdfTemplate): StockTakings.PdfTemplate | undefined {
    if (r) {
      return {
        creationTime: Services.toOffsetDateTime(r.creation_time),
        updateTime: Services.toOffsetDateTime(r.update_time),
        version: r.version
      }
    }
    return undefined;
  }

}

export namespace StockTakings {

  export interface Service {

    query(request: QueryRequest): Observable<QueryResult<StockTaking>>;

    create(request: CreateRequest): Observable<CreateResponse>;

    get(request: GetRequest): Observable<StockTaking>;

  }

  export interface StockTaking {
    stockTakingId: number;
    trxId: string;
    creationTime: OffsetDateTime;
    updateTime: OffsetDateTime;
    name: string;
    ownerUserId: number;
    stockIds: number[];
    pdfTemplate?: PdfTemplate;
  }

  export interface PdfTemplate {
    creationTime: OffsetDateTime;
    updateTime: OffsetDateTime;
    version: number;
  }

  export interface QueryRequest {
    stockTakingId?: string;
    ownerUserId?: string;
    name?: string;
    trxId?: string;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
  }

  export interface GetRequest {
    stockTakingId: number;
  }

  export interface CreateRequest {
    name: string;
    stockIds: number[];
  }

  export interface CreateResponse {
    stockTakingId: number;
  }

  export interface UpdateRequest {
    stockTakingId: number;
    name: string;
    stockIds: number[];
  }

  export interface DeleteTemplateRequest {
    stockTakingId: number;
  }

  export enum OrderField {
    CREATION_TIME,
    TRX_ID,
    NAME,
    OWNER_USER_PERSON_NAME
  }

}

class Keys {

  private static readonly CREATION_TIME = 'creation_time';
  private static readonly TRX_ID = 'trx_id';
  private static readonly NAME = 'name';
  private static readonly OWNER_USER_PERSON_NAME = 'owner_user_person_name';

  private static readonly orderFieldKeyMap: Map<StockTakings.OrderField, string> = Map.of(
    StockTakings.OrderField.CREATION_TIME, Keys.CREATION_TIME,
    StockTakings.OrderField.TRX_ID, Keys.TRX_ID,
    StockTakings.OrderField.NAME, Keys.NAME,
    StockTakings.OrderField.OWNER_USER_PERSON_NAME, Keys.OWNER_USER_PERSON_NAME,
  );

  public static toOrderFieldKey(field: StockTakings.OrderField): string {
    return Keys.orderFieldKeyMap.get(field)!;
  }

}
