import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { LocalDate } from '../../../../../../lib/util/dates';
import { NgbDatePickerParserFormatter } from '../../../../../../util/ngb-datepicker';
import { Models } from '../../../../../../util/model-utils';

@Component({
  selector: 'app-form-record-stock-intake-header-dialog',
  templateUrl: './form-record-stock-intake-header-dialog.component.html',
  styleUrls: ['./form-record-stock-intake-header-dialog.component.scss']
})
export class FormRecordStockIntakeHeaderDialogComponent implements OnInit {

  model: FormRecordStockIntakeHeaderDialogModel = new FormRecordStockIntakeHeaderDialogModel();

  readonly: boolean = false;

  constructor(private dateParser: NgbDatePickerParserFormatter,
              public dialogRef: MatDialogRef<FormRecordStockIntakeHeaderDialogComponent, FormRecordStockIntakeHeaderDialogData>,
              @Inject(MAT_DIALOG_DATA) public data: FormRecordStockIntakeHeaderDialogData) {
    this.readonly = data.readonly === undefined ? this.readonly : data.readonly;
    this.model = new FormRecordStockIntakeHeaderDialogModel();
    this.model.intakeDate = dateParser.fromLocalDate(data.intakeDate);
    this.model.shippingNumber = data.shippingNumber;
    this.model.shippingCompany = data.shippingCompany;
    this.model.invoiceNumber = data.invoiceNumber;
  }

  ngOnInit(): void {
  }

  closeDialog(modified: boolean) {
    this.dialogRef.close(modified && !this.readonly ? this.toData(this.model) : undefined);
  }

  private toData(model: FormRecordStockIntakeHeaderDialogModel): FormRecordStockIntakeHeaderDialogData {
    return {
      intakeDate: Models.ngbDateToLocalDate(this.model.intakeDate),
      shippingNumber: this.model.shippingNumber,
      shippingCompany: this.model.shippingCompany,
      invoiceNumber: this.model.invoiceNumber,
    }
  }
}

export interface FormRecordStockIntakeHeaderDialogData {
  readonly?: boolean;
  intakeDate?: LocalDate;
  shippingNumber: string;
  shippingCompany: string;
  invoiceNumber: string;

}

class FormRecordStockIntakeHeaderDialogModel {
  intakeDate: NgbDateStruct | null = null;
  shippingNumber: string = '';
  shippingCompany: string = '';
  invoiceNumber: string = '';

  get intakeDateString(): string {
    return Models.ngbDateToLocalDate(this.intakeDate).toIsoString();
  }
}
