/* eslint-disable */
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { DownloadedFile } from '../util/downloaded-files';
import { Observable } from 'rxjs';
import { FormResource, FormResourcePathGenerator } from '../form/form-resource.service';
import { ResourceQueryResult } from '../util/services';
import { UrlBuilder } from '../../util/url-builder';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { HttpClient } from '@angular/common/http';
import { EmptyMessage } from '../util/messages';

/* eslint-enable */

@Injectable()
export class SurveyResourceService extends BaseHttpService {

  // <editor-fold desc="CRUD">

  query(request: SurveyResource.QueryRequest): Observable<ResourceQueryResult<SurveyResource.Survey>> {
    return this.http.get<ResourceQueryResult<SurveyResource.Survey>>(this.url, this.parseParams(request));
  }

  get(request: SurveyResource.GetRequest): Observable<SurveyResource.Survey> {
    return this.http.get<SurveyResource.Survey>(this.url + `${request.survey_id}`);
  }

  create(request: SurveyResource.CreateRequest): Observable<SurveyResource.Survey> {
    return this.http.post<SurveyResource.Survey>(this.url, request);
  }

  update(request: SurveyResource.UpdateRequest): Observable<SurveyResource.Survey> {
    return this.http.put<SurveyResource.Survey>(this.url + `${request.survey_id}`, request);
  }

  setDisabled(request: SurveyResource.DisableRequest): Observable<SurveyResource.Survey> {
    return this.http.patch<SurveyResource.Survey>(this.url + `${request.survey_id}/disabled`, request);
  }

  // </editor-fold>

  // <editor-fold desc="Form">

  getForm(request: FormResource.GetFormRequest): Observable<FormResource.Form> {
    return this.http.get<FormResource.Form>(this.url + FormResourcePathGenerator.GetForm(request));
  }

  createFormGroup(request: FormResource.CreateGroupRequest): Observable<FormResource.CreateGroupResponse> {
    return this.http.post<FormResource.CreateGroupResponse>(this.url + FormResourcePathGenerator.CreateGroup(request), request);
  }

  updateFormGroup(request: FormResource.UpdateGroupRequest): Observable<FormResource.UpdateGroupResponse> {
    return this.http.put<FormResource.UpdateGroupResponse>(this.url + FormResourcePathGenerator.UpdateGroup(request), request);
  }

  disableFormGroup(request: FormResource.DisableGroupRequest): Observable<FormResource.DisableGroupResponse> {
    return this.http.patch<FormResource.DisableGroupResponse>(this.url + FormResourcePathGenerator.DisableGroup(request), request);
  }

  moveFormGroup(request: FormResource.MoveGroupRequest): Observable<FormResource.MoveGroupResponse> {
    return this.http.post<FormResource.MoveGroupResponse>(this.url + FormResourcePathGenerator.MoveGroup(request), request);
  }

  createFormField(request: FormResource.CreateFieldRequest): Observable<FormResource.CreateFieldResponse> {
    return this.http.post<FormResource.CreateFieldResponse>(this.url + FormResourcePathGenerator.CreateField(request), request);
  }

  updateFormField(request: FormResource.UpdateFieldRequest): Observable<FormResource.UpdateFieldResponse> {
    return this.http.post<FormResource.UpdateFieldResponse>(this.url + FormResourcePathGenerator.UpdateField(request), request);
  }

  disableFormField(request: FormResource.DisableFieldRequest): Observable<FormResource.DisableFieldResponse> {
    return this.http.patch<FormResource.DisableFieldResponse>(this.url + FormResourcePathGenerator.DisableField(request), request);
  }

  moveFormFieldToGroup(request: FormResource.MoveFieldToGroupRequest): Observable<FormResource.MoveFieldToGroupResponse> {
    return this.http.post<FormResource.MoveFieldToGroupResponse>(this.url + FormResourcePathGenerator.MoveFieldToGroup(request), request);
  }

  import(request: SurveyResource.ImportRequest): Observable<EmptyMessage> {
    return this.http.post<EmptyMessage>(this.url + `${request.survey_id}/form/import`, request);
  }

  export(request: SurveyResource.ExportRequest): Observable<FormResource.FormImportDocument> {
    return this.http.post<FormResource.FormImportDocument>(this.url + `${request.survey_id}/form/export`, request);
  }

  // </editor-fold>

  // <editor-fold desc="FileAttachment">

  deletePdfTemplate(request: SurveyResource.DeleteAttachmentRequest): Observable<EmptyMessage> {
    return this.http.delete<EmptyMessage>(this.url + `${request.survey_id}/attachments/pdf-template`);
  }

  deleteThumbnail(request: SurveyResource.DeleteAttachmentRequest): Observable<EmptyMessage> {
    return this.http.delete<EmptyMessage>(this.url + `${request.survey_id}/attachments/thumbnail`);
  }

  counts(request: EmptyMessage): Observable<Array<SurveyResource.SurveyCount>> {
    return this.http.get<Array<SurveyResource.SurveyCount>>(this.url + `counts`, this.parseParams(request));
  }

  downloadPdfTemplate(request: SurveyResource.DownloadAttachmentRequest): Observable<DownloadedFile> {
    const url = UrlBuilder.create(
      'surveys/:surveyId/attachments/pdf-template')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .namedNumber('surveyId', request.survey_id)
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob'
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  downloadThumbnail(request: SurveyResource.DownloadAttachmentRequest): Observable<DownloadedFile> {
    const url = UrlBuilder.create(
      'surveys/:surveyId/attachments/thumbnail')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .namedNumber('surveyId', request.survey_id)
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob'
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  // </editor-fold>

  constructor(private http: HttpClient, private resourceHelper: ResourceHelper) {
    super(resourceHelper, '/surveys/');
  }

}

export namespace SurveyResource {

  // <editor-fold desc="CRUD">

  export interface Survey {
    id: number;
    disabled: boolean;
    creation_time: string;
    update_time: string;
    name: string;
    external_id: string;
    description: string;
    has_pdf_template: boolean;
    has_xls_template: boolean;
    has_thumbnail: boolean;
  }

  export interface QueryRequest {
    id?: string;
    disabled?: boolean;
    name?: string;
    q?: string;
    fields?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
    no_progress_bar?: boolean;
  }

  export interface ImportRequest {
    survey_id: number;
    form: string;
    version: string;
  }

  export interface ExportRequest {
    survey_id: number;
  }


  export interface GetRequest {
    survey_id: number;
  }

  export interface CreateRequest {
    name: string;
    description?: string;
    external_id?: string;
  }

  export interface UpdateRequest {
    survey_id: number;
    name: string;
    external_id: string;
    description?: string;
  }

  export interface DisableRequest {
    survey_id: number;
    disabled: boolean;
  }

  export interface DeleteAttachmentRequest {
    survey_id: number;
  }

  export interface DownloadAttachmentRequest {
    survey_id: number;
  }

  export interface SurveyCount {
    survey_id: number;
    survey_name: string;
    survey_record_count: number;
  }

  // </editor-fold>

}
