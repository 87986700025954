import { Component, OnInit } from '@angular/core';
import { RightSidebarElement, RightSidebarElementType } from '../sidebar-right.component';
import { OrderType, QueryResult } from '../../../lib/util/services';
import { Message, MessageService } from '../../../lib/message/message.service';
import { QueryFieldModel } from '../../../util/core-utils';
import { Dates, OffsetDateTime } from '../../../lib/util/dates';
import { Observable, Subscription } from 'rxjs';
import { StateName } from '../../../app.state-names';
import { PushNavigationService } from '../../../util/firebase/push-navigation.service';
import { UIRouter } from '@uirouter/angular';

@Component({
  selector: 'app-sidebar-notification-list',
  templateUrl: './sidebar-notification-list.component.html',
  styleUrls: ['./sidebar-notification-list.component.scss']
})
export class SidebarNotificationListComponent implements OnInit, RightSidebarElement {

  elementType: RightSidebarElementType = 'NOTIFICATION_LIST';

  visible: boolean;

  notificationList: Notification[] = [];
  notificationQueryModel: QueryFieldModel<Message.OrderField> = new QueryFieldModel(Message.OrderField.ID, OrderType.DESC);

  subscription: Subscription;

  get hasNewNotification(): boolean {
    for (let i = 0; i < this.notificationList.length; i++) {
      if (!this.notificationList[i].read) {
        return true;
      }
    }
    return false;
  }

  get endOfNotificationList() {
    const displayedNumberOfItems = (this.notificationQueryModel.currentPage) * this.notificationQueryModel.itemsPerPage;
    return  displayedNumberOfItems >= this.notificationQueryModel.currentNumberOfItems;
  }

  constructor(private messageService: MessageService,
              private pushNavigationService: PushNavigationService) {
    this.notificationQueryModel.itemsPerPage = 5;
  }

  ngOnInit() {
  }

  initComponent(): void {
    this.createMessageListObservable();
    this.loadNotifications();
  }

  refresh(): void {
    this.loadNotifications(false, () => {
      if (this.visible) {
        this.markAllAsRead();
      }
    });
  }

  markAllAsRead() {
    this.notificationList.forEach(message => {
      if (!message.read) {
        this.messageService.setRead({
          id: message.id,
          read: true,
          noProgressBar: true
        }).subscribe(() => {
          message.read = true;
        });
      }
    });
  }

  onSidebarToggle(visible: boolean): void {
    this.setVisibility(visible);
    if (visible) {
      this.refresh();
    }
  }

  setVisibility(visible: boolean): void {
    this.visible = visible;
  }

  loadPrevious(event: any) {
    event.stopPropagation(); // Button click is recognised as outside click and closes sidebar normally
    this.loadNotifications(true);
  }

  createMessageListObservable(): Observable<QueryResult<Message.Message>> {
    return this.messageService.query({
      disabled: false,
      paging: {
        pageNumber: this.notificationQueryModel.currentPage,
        numberOfItems: this.notificationQueryModel.itemsPerPage
      },
      noProgressBar: true
    });
  }

  loadNotifications(withPrevious?: boolean, completion?: () => void) {
    if (!withPrevious) {
      this.notificationQueryModel = new QueryFieldModel(Message.OrderField.ID, OrderType.DESC);
      this.notificationQueryModel.itemsPerPage = 5;
    }
    else {
      this.notificationQueryModel.currentPage++;
    }
    this.subscription = this.createMessageListObservable().subscribe((result: QueryResult<Message.Message>) => {
      if (!withPrevious) {
        this.notificationList = [];
      }
      result.items.forEach((message: Message.Message) => {
        const notification = new Notification();
        notification.id = message.id;
        notification.title = message.title;
        notification.content = message.content;
        notification.creationTime = message.creationTime;
        notification.type = message.type;
        notification.read = message.read;
        notification.iconClass = this.resolveIconClass(message.type);
        notification.data = message.data;
        this.notificationList.push(notification);
      });
      this.notificationQueryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.notificationQueryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
      if (completion) {
        completion();
      };
    });
  }

  resolveIconClass(type: Message.MessageType): string {
    switch (type) {
      case 'SYSTEM':
        return 'icomoon-sidebar-dashboard';
      case 'USER':
        return 'icomoon-message-unread';
      default:
        return '';
    }
  }

  onNotificationSelected(notification: Notification) {
    this.pushNavigationService.messageAction(notification.data);
  }

}

export class Notification {
  id: number;
  title: string = '';
  content: string = '';
  creationTime: OffsetDateTime = Dates.emptyOffsetDateTime();
  type: Message.MessageType;
  iconClass: string = '';
  read: boolean;
  data?: Message.MessageData;
}
