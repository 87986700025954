/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { FieldValidationError, Order, PagingRequest, QueryResult, ResourceQueryResult, Services } from '../../util/services';
import { ObservableErrorResourceParser } from '../../util/errors';
import { List, Map, Set } from 'immutable';
import { InvoiceBalanceResource, InvoiceBalanceResourceService } from './invoice-balance-resource.service';
import { LocalDate, OffsetDateTime } from '../../util/dates';
import { EmptyMessage } from '../../util/messages';
import { Invoice } from '../invoice/invoice.service';
import InvoicePaymentType = Invoice.InvoicePaymentType;

/* eslint-enable */

@Injectable()
export class InvoiceBalanceService implements InvoiceBalance.Service {

  constructor(private resourceService: InvoiceBalanceResourceService) {
  }

  query(request: InvoiceBalance.QueryRequest): Observable<QueryResult<InvoiceBalance.InvoiceBalance>> {
    return Observable.create((observer: Observer<QueryResult<InvoiceBalance.InvoiceBalance>>) => {
      const resourceRequest: InvoiceBalanceResource.QueryRequest = {
        id: Services.createIdParameter(request.invoiceBalanceIdSet),
        starting_date_from: Services.localDateToString(request.startingDateFrom),
        starting_date_to: Services.localDateToString(request.startingDateTo),
        order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
        no_progress_bar: request.noProgressBar
      };
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<InvoiceBalanceResource.InvoiceBalance>) => {
          observer.next({
            items: this.toPublicList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  get(request: InvoiceBalance.GetRequest): Observable<InvoiceBalance.InvoiceBalance> {
    return Observable.create((observer: Observer<InvoiceBalance.InvoiceBalance>) => {
      const resourceRequest: InvoiceBalanceResource.GetRequest = {
        invoice_balance_id: request.id
      };
      return this.resourceService.get(resourceRequest).subscribe(
        (result: InvoiceBalanceResource.InvoiceBalance) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  create(request: InvoiceBalance.CreateRequest): Observable<InvoiceBalance.InvoiceBalance> {
    return Observable.create((observer: Observer<InvoiceBalance.InvoiceBalance>) => {
      const resourceRequest: InvoiceBalanceResource.CreateRequest = {
        starting_balance: request.startingBalance,
        starting_date: Services.localDateToString(request.startingDate)!,
        valid_invoice_payment_types: request.validInvoicePaymentTypes.toArray(),
        currency_code: request.currencyCode
      };
      return this.resourceService.create(resourceRequest).subscribe(
        (result: InvoiceBalanceResource.InvoiceBalance) => {
          observer.next(this.toPublic(result));
        },
        (error: any) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  delete(request: InvoiceBalance.GetRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: InvoiceBalanceResource.GetRequest = {
        invoice_balance_id: request.id
      };
      return this.resourceService.delete(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  private translateError(error: any): any {
    const res = ObservableErrorResourceParser.parseError(error);
    const fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
    const fieldErrorMap = ObservableErrorResourceParser.toFieldErrorMap(Keys.toValidatedField, fieldErrors);
    if (!fieldErrorMap.isEmpty()) {
      return FieldValidationError.of(fieldErrorMap);
    }
    return error;
  }

  private toPublicList(resourceList: InvoiceBalanceResource.InvoiceBalance[]): List<InvoiceBalance.InvoiceBalance> {
    return List.of(...resourceList.map((r) => this.toPublic(r)));
  }

  private toPublic(r: InvoiceBalanceResource.InvoiceBalance): InvoiceBalance.InvoiceBalance {
    return {
      id: r.id,
      startingDate: Services.toLocalDate(r.starting_date),
      startingBalance: r.starting_balance,
      revenue: r.revenue,
      expense: r.expense,
      currentBalance: r.current_balance,
      updateCount: r.update_count,
      validInvoicePaymentTypes: Set.of(...r.valid_invoice_payment_types.map(t => <InvoicePaymentType>t)),
      creationTime: Services.toOffsetDateTime(r.creation_time),
      updateTime: Services.toOffsetDateTime(r.update_time),
      currencyCode: r.currency_code
    };
  }

}

export namespace InvoiceBalance {

  import InvoicePaymentType = Invoice.InvoicePaymentType;

  export interface Service {

    query(request: QueryRequest): Observable<QueryResult<InvoiceBalance>>;

    get(request: GetRequest): Observable<InvoiceBalance>;

    create(request: CreateRequest): Observable<InvoiceBalance>;

    delete(request: GetRequest): Observable<EmptyMessage>;

  }

  export interface InvoiceBalance {
    id: number,
    creationTime: OffsetDateTime,
    updateTime: OffsetDateTime,
    updateCount: number,
    startingDate: LocalDate, // date
    startingBalance: number,
    currentBalance: number,
    revenue: number,
    expense: number,
    validInvoicePaymentTypes: Set<InvoicePaymentType>,
    currencyCode: string
  }

  export interface QueryRequest {
    invoiceBalanceIdSet?: Set<number>;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
    noProgressBar?: boolean;
    startingDateFrom?: LocalDate;
    startingDateTo?: LocalDate;
  }

  export interface GetRequest {
    id: number;
  }

  export interface CreateRequest {
    startingDate: LocalDate, // date
    startingBalance: number,
    validInvoicePaymentTypes: Set<InvoicePaymentType>,
    currencyCode: string
  }

  export enum OrderField {
    ID,
    STARTING_BALANCE,
    STARTING_DATE,
    CREATION_TIME,
    UPDATE_TIME,
  }

  export enum ValidatedField {
    UNKNOWN,
    STARTING_BALANCE,
    STARTING_DATE,
  }

}


class Keys {

  private static readonly ID = 'id';
  private static readonly STARTING_BALANCE = 'starting_balance';
  private static readonly STARTING_DATE = 'starting_date';
  private static readonly CREATION_TIME = 'creation_time';
  private static readonly UPDATE_TIME = 'update_time';

  private static readonly orderFieldKeyMap: Map<InvoiceBalance.OrderField, string> = Map.of(
    InvoiceBalance.OrderField.ID, Keys.ID,
    InvoiceBalance.OrderField.UPDATE_TIME, Keys.UPDATE_TIME,
    InvoiceBalance.OrderField.STARTING_BALANCE, Keys.STARTING_BALANCE,
    InvoiceBalance.OrderField.STARTING_DATE, Keys.STARTING_DATE,
    InvoiceBalance.OrderField.CREATION_TIME, Keys.CREATION_TIME,
  );

  private static readonly keyValidatedFieldMap: Map<string, InvoiceBalance.ValidatedField> = Map.of(
    Keys.STARTING_BALANCE, InvoiceBalance.ValidatedField.STARTING_BALANCE,
    Keys.STARTING_DATE, InvoiceBalance.ValidatedField.STARTING_DATE,
  );

  public static toOrderFieldKey(field: InvoiceBalance.OrderField): string {
    return Keys.orderFieldKeyMap.get(field)!;
  }

  public static toValidatedField(fieldKey: string): InvoiceBalance.ValidatedField {
    return Keys.keyValidatedFieldMap.get(fieldKey, InvoiceBalance.ValidatedField.UNKNOWN);
  }

}
