import { Component, Input, OnInit } from '@angular/core';
import { MplTracking, MplTrackingService } from '../../../../lib/mpl-tracking/mpl-tracking.service';
import { FilterField } from '../../../../lib/query/filterfields';
import { CriteriaBuilder } from '../../../../util/model-utils';
import { OrderFieldFunction, OrderFieldModel } from '../../../../util/core-utils';
import { OrderField } from '../../../../lib/query/orderfields';
import { OrderType } from '../../../../lib/util/services';
import { WeightFactory } from '../../../../util/weight-utils';

@Component({
  selector: 'app-shipping-demand-mpl-statistics',
  templateUrl: './shipping-demand-mpl-statistics.component.html',
  styleUrls: ['./shipping-demand-mpl-statistics.component.scss']
})
export class ShippingDemandMplStatisticsComponent implements OnInit {

  MplTracking = MplTracking;
  WeightFactory = WeightFactory;

  @Input()
  shippingDemandId: number;

  queryModel: OrderFieldModel<OrderField.MplTracking>
    = new OrderFieldModel(MplTracking.OrderFunctions.TIME, OrderType.DESC);

  trackingData: MplTracking.MplTracking[];

  get baseDataModel(): MplTracking.MplTracking | undefined {
    return this.trackingData && this.trackingData.length > 0 ? this.trackingData[0] : undefined;
  }

  constructor(
    private mplTrackingService: MplTrackingService
  ) { }

  ngOnInit() {
    this.loadTracking();
  }

  loadTracking() {
    this.mplTrackingService.query({
      order: this.queryModel.createOrderFunction(),
      filter: this.createFilter()
    }).subscribe((result: MplTracking.MplTracking[]) => {
      this.trackingData = result;
    });
  }

  private createFilter() {
    return (f: FilterField.MplTracking) => CriteriaBuilder.builder()
      .addNumber((demandId) => f.shipment.shipmentGroup.shippingDemand.id.eq(demandId), this.shippingDemandId)
      .build();
  }

  orderBy(field: OrderFieldFunction<OrderField.MplTracking>) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadTracking();
  }
}
