<div class="modal-content">
  <div class="modal-header">
    <p class="modal-title"><strong>{{'INVOICE_BALANCE_CREATE' | translate}}</strong></p>
    <button type="button" class="close" (click)="closeDialog()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <mat-dialog-content style="overflow:visible">
      <form (ngSubmit)="save()" #f="ngForm" id="form"
            [formGroup]="formGroup" novalidate>
        <div class="row form-group" [ngClass]="{ 'has-danger': hasLocalFieldError('startingDate') }">
          <label class="col-form-label form-control-label col-md-4 detail-title required-field-label">
            {{'INVOICE_BALANCE_STARTING_DATE' | translate}}
          </label>
          <div class="col-md-8">
            <div class="input-group">
              <input type="text" class="form-control"
                     [(ngModel)]="model.startingDate"
                     formControlName="startingDate"
                     ngbDatepicker #date_picker="ngbDatepicker"
                     [ngClass]="{ 'form-control-danger': hasLocalFieldError('startingDate')}"
                     placeholder="{{'INVOICE_BALANCE_STARTING_DATE' | translate}}">
              <span class="add-on input-group-addon-gray" (click)="date_picker.toggle()">
                      <i class="icomoon icomoon-calendar"></i>
                </span>
            </div>
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('startingDate')">
              <span
                *ngIf="hasLocalFieldError('startingDate', 'validateLocalDate')">{{'COMMON_VALIDATION_MESSAGE_INVALID_LOCAL_DATE' | translate}}</span>
              <span
                *ngIf="hasLocalFieldError('startingDate', 'validateMaxLocalDate')">{{'COMMON_VALIDATION_MESSAGE_FUTURE_IS_NOT_VALID' | translate}}</span>
              <span
                *ngIf="hasLocalFieldError('startingDate', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
            </div>
          </div>
        </div>
        <div class="row form-group" [ngClass]="{ 'has-danger': hasLocalFieldError('startingBalance') }">
          <label
            class="col-form-label form-control-label col-md-4 detail-title required-field-label">{{'INVOICE_BALANCE_STARTING_BALANCE' | translate}}</label>
          <div class="col-md-8">
            <div class="mw-100">
              <input type="number"
                     [(ngModel)]="model.startingBalance"
                     trim="blur"
                     validateOnBlur
                     [ngClass]="{ 'form-control-danger': hasLocalFieldError('startingBalance')}"
                     class="form-control"
                     name="startingBalance"
                     id="startingBalance"
                     formControlName="startingBalance"
                     placeholder="{{'INVOICE_BALANCE_STARTING_BALANCE'|translate}}">
            </div>
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('startingBalance')">
              <span
                *ngIf="hasLocalFieldError('startingBalance', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
            </div>
          </div>
        </div>
      </form>
    </mat-dialog-content>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="closeDialog()">
      {{'COMMON_BUTTON_CANCEL' | translate}}
    </button>
    <button type="submit" class="btn btn-primary" form="form">
      {{'COMMON_BUTTON_SAVE' | translate}}
    </button>
  </div>
</div>
