<div class="row m-0">
  <div class="col-xl-6 card-custom-padding-complete">
    <app-usergroup-type-based-role-card
      [type]="UserGroupRoleType.USERGROUP_TASK"
      [typeId]="userGroupId"
      [readonly]="readonly"
      [rightModel]="rightModel">
    </app-usergroup-type-based-role-card>
  </div>
  <div class="col-xl-6 card-custom-padding-complete">
    <app-usergroup-type-based-role-card
      [type]="UserGroupRoleType.USERGROUP_CUSTOMER"
      [typeId]="userGroupId"
      [readonly]="readonly"
      [rightModel]="rightModel">
    </app-usergroup-type-based-role-card>
  </div>
</div>
