/* eslint-disable */
import {FieldValidationError} from '../../../../lib/util/services';
import {TaskRecordImportanceItem, TaskRecordRightModel, TaskRecordStateItem} from '../../../../util/task-record-utils';
import {
  DeviceItem,
  MultiselectOptionItem,
  OwnerUserItem,
  ProjectItem,
  UserItem
} from '../../../../util/core-utils';
import {TaskRecord} from '../../../../lib/task/task-record.service';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {AppNgbTimeStruct, NgbDatePickerParserFormatter} from '../../../../util/ngb-datepicker';
import {Address, AddressModel} from '../../../../lib/address';
import {List, Set} from 'immutable';
import {CustomerRecordItem} from '../../../../util/customer-record-utils';
import {OffsetDateTime} from '../../../../lib/util/dates';
import {TaskRecordResource} from '../../../../lib/task/task-record-resource.service';
import {Task} from '../../../../lib/task/task.service';
import {RightModel} from '../../../../app.rights';
import {CustomerRecord} from '../../../../lib/customer/customer-record.service';
import {Models} from '../../../../util/model-utils';
import {TranslateService} from '@ngx-translate/core';
import {User} from '../../../../lib/user.service';
import {HelpdeskState} from "../../../../lib/statistics/task-statistics/helpdesk.state";
import {
  ContractNumberMultiselectProvider
} from "../../../../lib/customer/contract-number/contract-number-multiselect-provider.service";
import {OperationRights} from "../../../../app.right-definitions";
import {GrantedPermissionSetResolver} from "../../../../lib/right.service";

/* eslint-enable */

export namespace TaskRecordBase {

  import PreviousExportError = TaskRecordResource.PreviousExportError;

  export interface View {

    loadTaskRecord(args: Args);

    getModel(): Model;

    hasLocalFieldError(): boolean;

    onFieldValidationError(fieldErrors: FieldValidationError<TaskRecord.ValidatedField>);

    setCustomerRecord(customerRecord: CustomerRecord.CustomerRecord);

    getInvoiceAllowedState(): boolean;

    isManagedField(field: TaskRecord.TaskRecordField): boolean;

    validatePlaceOfConsumption(): boolean;

  }

  export interface Args {
    taskRecord?: TaskRecord.TaskRecord;
    task: Task.Task;
    countryItems: List<AddressModel.CountryItem>;
    defaultDeadline?: Task.TaskDefaultDeadline;
    rightModel?: RightModel;
    defaultUser?: User;
    isClone?: boolean;
    createOptions?: TaskRecordCreateOptions;
    grantedRights?: TaskRecordRightModel;
  }

  export interface TaskRecordCreateOptions {
    projectRecordId?: number;
  }

  // Not injected so valid inside the namespace.
  export class Model {

    name: string = '';
    externalId: string = '';
    description: string = '';
    stateChangeMessage: string = '';
    stateChangeLog?: TaskRecord.Log;
    project: ProjectItem[] = [];
    state?: TaskRecordStateItem = undefined;
    helpdeskState?: HelpdeskState.StateObject = undefined;
    importance?: TaskRecordImportanceItem = undefined;
    ownerUser?: UserItem = undefined;
    customerM: CustomerRecordItem[] = [];
    billingInfo: MultiselectOptionItem<number>[] = [];
    agreedTimeDate?: NgbDateStruct = undefined;
    agreedTimeTime?: AppNgbTimeStruct = undefined;
    creationTime?: OffsetDateTime;
    creatorUser?: OwnerUserItem[] = [];
    updateTime?: OffsetDateTime;
    deadlineDate?: NgbDateStruct = undefined;
    deadlineTime?: AppNgbTimeStruct = undefined;
    userGroup: MultiselectOptionItem<number>[] = [];
    assignee: AssigneeModel = new AssigneeModel();
    placeOfConsumption: PlaceOfConsumptionModel;
    linkDocuments: DocumentItem[] = [];
    linkDocumentIds: number[] = [];
    fileDocuments: DocumentItem[] = [];
    fileDocumentIds: number[] = [];
    exportState?: string = '';
    exportTime?: OffsetDateTime;
    previousExportError?: PreviousExportError = undefined;
    linkedSurveys: MultiselectOptionItem<number>[] = [];
    estimatedTime: ComplexTimeModel = new ComplexTimeModel(0, 0);
    timeSpent: ComplexTimeModel = new ComplexTimeModel(0, 0);
    contactLocationId?: number;
    process?: MultiselectOptionItem<number>[];
    processCreatorUser?: OwnerUserItem[];
    contractNumber: MultiselectOptionItem<number>[] = [];

    isClone: boolean = false;

    get customer(): CustomerRecordItem | undefined {
      return this.customerM.length > 0 ? this.customerM[0] : undefined;
    }

    set customer(customer: CustomerRecordItem | undefined) {
      if (customer) {
        this.customerM[0] = customer;
      }
      if (!customer || (this.customerM.length > 0 && this.customerM[0].id !== customer.id)) {
        this.contactLocationId = undefined;
      }
    }

    get billingInfoId(): number | undefined {
      return this.billingInfo.length > 0 ? this.billingInfo[0].id : undefined;
    }

    get contractNumberId(): number | undefined {
      return this.contractNumber.length > 0 ? this.contractNumber[0].id : undefined;
    }

    get contractNumberStr(): string | undefined {
      return this.contractNumber.length > 0 ? this.contractNumber[0].itemName : undefined;
    }

    get estimatedTimeInMinutes(): number | undefined {
      // time tracking can be turned on/off any time, so we always send it if it is > 0
      return !this.estimatedTime.isZero() ? this.estimatedTime.toMinutes() : undefined;
    }

    get linkedSurveyIds(): number[] | undefined {
      return this.linkedSurveys.length === 0 ? undefined : this.linkedSurveys.map(s => s.id);
    }

    constructor() {
      this.placeOfConsumption = new PlaceOfConsumptionModel();
    }

    get usergroup(): MultiselectOptionItem<number> | undefined {
      return this.userGroup.length > 0 ? this.userGroup[0] : undefined;
    }

    set usergroup(usergroup: MultiselectOptionItem<number> | undefined) {
      this.userGroup = [];
      if (usergroup) {
        this.userGroup.push(usergroup);
      }
    }

    load(args: TaskRecordBase.Args, ngbDatePickerParserFormatter: NgbDatePickerParserFormatter, translateService: TranslateService) {
      const record: TaskRecord.TaskRecord | undefined = args.taskRecord;
      this.isClone = args.isClone ? args.isClone : false;
      if (record) {
        this.name = record.name;
        this.externalId = record.externalId ? record.externalId : '';
        this.description = record.description ? record.description : '';
        this.creationTime = this.isClone ? undefined : record.creationTime;
        this.creatorUser = this.isClone || !record.creatorUser ? [] : [{
          id: record.creatorUser.id,
          text: `${record.creatorUser.personName} (${record.creatorUser.userName})`
        }];
        this.updateTime = this.isClone ? undefined : record.updateTime;
        this.exportState = this.isClone ? undefined : record.exportState;
        this.exportTime = this.isClone ? undefined : record.exportTime;
        this.billingInfo = [];
        if (record.billingInfo) {
          let subtitle = '';
          switch (record.billingInfo.invoiceVatStatus) {
            case CustomerRecord.InvoiceVatStatus.PRIVATE_PERSON:
              subtitle = translateService.instant('INVOICE_VAT_STATUS_PRIVATE_PERSON');
              break;
            case CustomerRecord.InvoiceVatStatus.DOMESTIC:
              subtitle = translateService.instant('INVOICE_VAT_STATUS_DOMESTIC') + ' / ' + record.billingInfo.taxNumber;
              break;
            case CustomerRecord.InvoiceVatStatus.OTHER:
              subtitle = translateService.instant('INVOICE_VAT_STATUS_OTHER') + ' / ' + record.billingInfo.euTaxNumber;
              break;
          }
          this.billingInfo.push({
            id: record.billingInfo.id,
            itemName: record.billingInfo.name,
            itemSubtitle: subtitle,
            disabled: record.billingInfo.disabled
          });
        }
        this.contactLocationId = record.contactLocationId;
        if (!this.isClone && record.previousExportError) {
          this.previousExportError = record.previousExportError;
        }
        this.stateChangeLog = this.isClone ? undefined : record.stateChangeLog;
        const parsedAgreed = ngbDatePickerParserFormatter.fromOffsetDateTime(record.agreedTime);
        if (parsedAgreed) {
          this.agreedTimeDate = {year: parsedAgreed.year, month: parsedAgreed.month, day: parsedAgreed.day};
          this.agreedTimeTime = {
            hour: parsedAgreed.hour,
            minute: parsedAgreed.minute,
            second: parsedAgreed.second,
            millis: parsedAgreed.millis
          };
        }
        const parsedDeadline = ngbDatePickerParserFormatter.fromOffsetDateTime(record.deadline);
        if (parsedDeadline) {
          this.deadlineDate = {year: parsedDeadline.year, month: parsedDeadline.month, day: parsedDeadline.day};
          this.deadlineTime = {
            hour: parsedDeadline.hour,
            minute: parsedDeadline.minute,
            second: parsedDeadline.second,
            millis: parsedDeadline.millis
          };
        }
        if (record.fileDocumentIds) {
          this.fileDocumentIds = record.fileDocumentIds;
        }
        if (record.textDocumentIds) {
          this.linkDocumentIds = record.textDocumentIds;
        }
        if (record.userGroupId) {
          this.usergroup = {id: record.userGroupId, itemName: ''};
        }
        if (record.assignee) {
          this.assignee.user = record.assignee.userId
            ? {
              id: record.assignee.userId,
              text: record.assignee.userData
                ? record.assignee.userData.personName + ' (' + record.assignee.userData.userName + ')'
                : ''
            }
            : undefined;
          this.assignee.device = record.assignee.mobileApplicationId ? {
            id: record.assignee.mobileApplicationId,
            text: ''
          } : undefined;
        }
        if (record.estimatedTimeInMinutes) {
          this.estimatedTime = ComplexTimeModel.minutesToComplexTime(record.estimatedTimeInMinutes);
        }
        if (record.timeSpentInMinutes) {
          this.timeSpent = ComplexTimeModel.minutesToComplexTime(record.timeSpentInMinutes);
        }
        if (record.process) {
          this.process = [];
          this.processCreatorUser = [];
          this.process.push({
            id: record.process.id,
            itemName: record.process.name,
            itemSubtitle: record.process.externalId
          });
          this.processCreatorUser.push({
            id: record.process.creatorUser.id,
            text: `${record.process.creatorUser.personName} (${record.process.creatorUser.userName})`
          });
        }
        if (record.contractNumber) {
          this.contractNumber = [];
          this.contractNumber.push(ContractNumberMultiselectProvider.toPublic(record.contractNumber));
        }
      }
      this.placeOfConsumption.load(args.countryItems, record ? record.placeOfConsumption : undefined);
    }

    getServiceAssignee(): TaskRecord.Assignee {
      return {
        userId: this.assignee.user && this.assignee.user.id ? this.assignee.user.id : undefined,
        mobileApplicationId: this.assignee.device && this.assignee.device.id ? this.assignee.device.id : undefined,
      };
    }

    getServicePoc(): TaskRecord.PlaceOfConsumption | undefined {
      return this.placeOfConsumption.getServicePOC();
    }
  }

  export class AssigneeModel {
    _user?: OwnerUserItem[] = [];
    _device?: DeviceItem[] = [];

    load(user?: OwnerUserItem, device?: DeviceItem) {
      this.user = user;
      this.device = device;
    }

    get user(): OwnerUserItem | undefined {
      if (this._user && this._user.length > 0) {
        return this._user[0];
      }
      return undefined;
    }

    set user(user: OwnerUserItem | undefined) {
      this._user = [];
      if (user) {
        this._user.push(user);
      }
    }

    get device(): DeviceItem | undefined {
      if (this._device && this._device.length > 0) {
        return this._device[0];
      }
      return undefined;
    }

    set device(device: DeviceItem | undefined) {
      if (device) {
        this._device = [];
        this._device.push(device);
      }
    }
  }

  export class PlaceOfConsumptionModel {
    address: AddressModel.PostalAddressModel;
    latitude?: number = undefined;
    longitude?: number = undefined;
    private countryItems: List<AddressModel.CountryItem>;

    constructor() {
      this.address = new AddressModel.PostalAddressModel();
    }

    load(countryItems: List<AddressModel.CountryItem>, poc?: TaskRecord.PlaceOfConsumption) {
      this.reset();
      this.countryItems = countryItems;
      this.address.loadCountryItems(this.countryItems);
      if (!poc) {
        return;
      }
      this.address.load(poc.address);
      this.latitude = poc.coordinate ? poc.coordinate.latitude.toNumber() : undefined;
      this.longitude = poc.coordinate ? poc.coordinate.longitude.toNumber() : undefined;
    }

    getServicePOC(): TaskRecord.PlaceOfConsumption | undefined {
      const poc: TaskRecord.PlaceOfConsumption = {address: undefined, coordinate: undefined};
      if (this.address) {
        poc.address = this.address.toData();
      }
      if (this.latitude && this.longitude) {
        poc.coordinate = {
          latitude: Models.numberToDecimal(this.latitude)!,
          longitude: Models.numberToDecimal(this.longitude)!
        };
      }
      return poc.address || poc.coordinate ? poc : undefined;
    }

    loadPostalAddressData(
      postalAddress?: Address.PostalAddressData,
      defaultSelectedCountryCode?: string,
      countryItems?: List<AddressModel.CountryItem>) {
      this.address.reset();
      this.loadCountryItems(defaultSelectedCountryCode, countryItems);
      if (postalAddress) {
        this.address.load(postalAddress);
      }
    }

    loadCountryItems(
      defaultSelectedCountryCode?: string,
      countryItems?: List<AddressModel.CountryItem>) {
      this.address.loadCountryItems(countryItems ? countryItems : this.countryItems, defaultSelectedCountryCode);
    }

    reset() {
      this.address.reset();
      this.latitude = undefined;
      this.longitude = undefined;
    }
  }

  export class ComplexTimeModel {

    hours: number = 0;
    minutes: number = 0;

    public static minutesToComplexTime(minutes: number): ComplexTimeModel {
      return new ComplexTimeModel(Math.floor(minutes / 60), minutes % 60);
    }

    public toMinutes(): number {
      return this.hours * 60 + this.minutes;
    }

    public isZero(): boolean {
      return this.minutes === 0 && this.hours === 0;
    }

    constructor(hours: number, minutes: number) {
      this.hours = hours;
      this.minutes = minutes;
    }

  }

  export class DocumentItem {
    id: number;
    itemName: string;
  }

  export const customerQueryFields:
    Set<string> = Set.of('id', 'customer_id', 'name', 'postal_address', 'disabled', 'invoice_deadline_additional_days', 'customer', 'main_contract_number');

  export const rightRequest: Set<string> = Set.of(...OperationRights.taskRecordStateChanges,
    OperationRights.TASK_RECORD_UPDATE,
    OperationRights.TASK_RECORD_CHANGE_ASSIGNEE_IN_PROGRESS,
    OperationRights.TASK_RECORD_LOG_READ,
    OperationRights.TASK_RECORD_DOCUMENT_READ,
    OperationRights.TASK_RECORD_CHAT_READ,
    OperationRights.TASK_RECORD_CHAT_MESSAGE_CREATE,
    OperationRights.TASK_RECORD_DOWNLOAD_SIGNATURES,
    OperationRights.TASK_RECORD_UPLOAD_SIGNATURES,
    OperationRights.TASK_RECORD_MANUAL_INVOICE_CREATE);

}
