import { DqlQuery, Query } from '../field';

export class LedgerNumber {

  readonly id: Query.NumberField;
  readonly ledgerNumber: Query.StringField;
  readonly name: Query.StringField;
  readonly creationTime: Query.DateTimeField;
  readonly disabled: Query.BooleanField;

  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this.id = new DqlQuery.NumberField(prefix + 'id');
    this.ledgerNumber = new DqlQuery.StringField(prefix + 'ledger_number');
    this.name = new DqlQuery.StringField(prefix + 'name');
    this.disabled = new DqlQuery.BooleanField(prefix + 'disabled');
    this.creationTime = new DqlQuery.DateTimeField(prefix + 'creation_time');
  }

}
