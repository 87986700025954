import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Angular2Multiselects } from '../../../../../util/multiselect';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FileItem, FileUploader, FileUploaderOptions, ParsedResponseHeaders } from 'ng2-file-upload';
import { ForwardingNgFormRef, LocalFormGroupValidationErrors } from '../../../../../lib/util/services';
import { OrderDocument } from '../../../../../lib/order-document/order-document.service';
import { RightService } from '../../../../../lib/right.service';
import { FileUploaderUtil } from '../../../../../util/file-uploader-util';
import { UploadErrorLocalizer } from '../../../../../util/upload-error-localizer';
import { TranslateService } from '@ngx-translate/core';
import { ResourceHelper } from '../../../../../lib/util/http-services';
import { UiConstants } from '../../../../../util/core-utils';
import { Strings } from '../../../../../lib/util/strings';
import {
  ExteriorTransportDocument,
  ExteriorTransportDocumentService
} from '../../../../../lib/exterior-transport/exterior-transport-document/exterior-transport-document.service';
import ExteriorTransportDocumentType = ExteriorTransportDocument.ExteriorTransportDocumentType;
import { FileUploadComponent, UploadResponse } from '../../../../../shared/file-upload/flat/file-upload.component';

@Component({
  selector: 'app-exterior-transport-document-create-dialog',
  templateUrl: './exterior-transport-document-create-dialog.component.html',
  styleUrls: ['./exterior-transport-document-create-dialog.component.scss']
})
export class ExteriorTransportDocumentCreateDialogComponent implements OnInit {

  UiConstants = UiConstants;
  exteriorTransportDocumentTypes = ExteriorTransportDocument.exteriorTransportDocumentTypes;

  dropdownSettings?: Angular2Multiselects.Settings;

  @Input()
  exteriorTransportId: number;

  @Output()
  onDocumentCreated: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('f')
  fForm: NgForm;

  @ViewChild('dialog', { static: true }) dialog: ModalDirective;
  dialogVisible: boolean = false;
  documentCreateModel: DocumentCreateModel = new DocumentCreateModel();

  @ViewChild('fileUploadComponent', {static: false})
  fileUploadComponent: FileUploadComponent;

  formGroup: FormGroup;
  private formGroupValidationErrors: LocalFormGroupValidationErrors;
  fileUploadEmptyError: boolean = false;

  constructor(private exteriorTransportDocumentService: ExteriorTransportDocumentService,
              private rightService: RightService,
              private formBuilder: FormBuilder,
              private fileUploaderUtil: FileUploaderUtil,
              private uploadErrorLocalizer: UploadErrorLocalizer,
              private translateService: TranslateService,
              private resourceHelper: ResourceHelper) {
    this.formGroup = this.createFormGroup(formBuilder);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(this.createForwardingHtmlForm(), this.formGroup);
  }

  ngOnInit() {
    this.initDropdownSettings();
  }

  // Currently, we do not validate anything, but it can be useful later to have the controls ready.
  private createFormGroup(fb: FormBuilder): FormGroup {
    return fb.group(
      {
        type: fb.control(
          {value: this.documentCreateModel.type},
          Validators.required
        ),
        comment: fb.control(
          {value: this.documentCreateModel.comment},
          [
          ]
        ),
      }
    );
  }

  private initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(false)
      .enableCheckAll(false)
      .remoteSearch(false)
      .build();
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    this.formGroup.updateValueAndValidity();
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  private createForwardingHtmlForm() {
    return new ForwardingNgFormRef({
      formFn: () => {
        return this.fForm;
      }
    });
  }

  openDocumentCreateDialog() {
    this.dialogVisible = true;
    this.dialog.show();
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(this.createForwardingHtmlForm(), this.formGroup);
    this.fileUploadComponent.uploader.clearQueue();
    this.fileUploadComponent.uploader.cancelAll();
    this.fileUploadEmptyError = false;
    this.formGroup.reset();
  }

  closeOrderDocumentCreateDialog() {
    this.dialogVisible = false;
    this.dialog.hide();
    this.formGroup.reset();
  }

  createOrderDocument() {
    let error: boolean = false;
    this.fileUploadEmptyError = false;
    this.formGroup.updateValueAndValidity();
    if (this.formGroup.invalid) {
      this.formGroup.get('type')!.markAsTouched();
      this.formGroup.get('comment')!.markAsTouched();
      error = true;
    }
    if (this.fileUploadComponent.uploader.queue.length === 0) {
      this.fileUploadEmptyError = true;
      error = true;
    }
    if (error) {
      return;
    }
    const url = this.resourceHelper.getBaseUrl() + '/exterior-transports/' + this.exteriorTransportId + '/documents';
    const options: FileUploaderOptions = this.fileUploadComponent.uploader.options;
    options.url = url;
    this.fileUploadComponent.uploader.setOptions(options);
    this.fileUploadComponent.uploader.uploadAll();
  }

  onUploadResponse(response: UploadResponse) {
    this.updateDocumentMeta(JSON.parse(response.response));
  }

  private updateDocumentMeta(response: OrderDocument.UUIDMessage) {
    this.exteriorTransportDocumentService.update({
      exteriorTransportId: this.exteriorTransportId,
      id: response.id,
      version: 1,
      type: this.documentCreateModel.type!,
      comment: Strings.undefinedOrNonEmpty(this.documentCreateModel.comment)
    }).subscribe(() => {
      this.closeOrderDocumentCreateDialog();
      this.onDocumentCreated.emit();
    })
  }

}

class DocumentCreateModel {
  type: ExteriorTransportDocumentType | null = null;
  comment: string = '';
}
