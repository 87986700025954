<div class="form-group" [formGroup]="formGroup" [ngClass]="{ 'has-danger': hasLocalFieldError('value')}">
  <div class="d-flex justify-content-between">
  <label class="form-control-label text-break" [ngClass]="{ 'required-field-label': required }">{{ model.title }}
    <i *ngIf="model.hint !== ''" class="icon icon-info popover-info-icon" title="{{ model.hint }}"></i>
  </label>
  <a *ngIf="refreshable" class="" href="#" onclick="return false" (click)="refreshValue()" title="{{'FORM_RECORD_DATE_FIELD_REFRESH_WITH_DEFAULT' | translate}}">
    <i class="icomoon icomoon-state-action-restart position-relative"></i>
  </a>
  </div>
  <div class="input-group">

    <input placeholder="{{ model.placeholder }}" type="text" class="form-control"
           formControlName="value"
           (input)="onInput()"
           (dateSelect)="onSelect()"
           [(ngModel)]="model.value"
           (ngModelChange)="onValueChanged()"
           [readonly]="nonEditable"
           [ngClass]="{ 'form-control-danger': hasLocalFieldError('value'), 'no-border': nonEditable }"
           ngbDatepicker #date_picker="ngbDatepicker"
           container="body">
    <span *ngIf="!nonEditable" class="add-on input-group-addon-gray" (click)="date_picker.toggle()">
                      <i class="icomoon icomoon-calendar"></i>
    </span>
  </div>
    <div class="form-control-feedback" *ngIf="hasLocalFieldError('value')">
      <span *ngIf="hasLocalFieldError('value', 'required')">{{ 'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate }}</span>
      <span *ngIf="hasLocalFieldError('value', 'validateLocalDate')">{{ 'COMMON_VALIDATION_MESSAGE_INVALID_LOCAL_DATE' | translate }}</span>
      <span *ngIf="hasLocalFieldError('value', 'validateLocalDateRange') && minValueText && maxValueText">{{ 'COMMON_VALIDATION_MESSAGE_INVALID_SPECIFIC_DATE_TIME_RANGE' | translate: {min: minValueText, max: maxValueText} }}</span>
      <span *ngIf="hasLocalFieldError('value', 'validateLocalDateRange') && minValueText && !maxValueText">{{ 'COMMON_VALIDATION_MESSAGE_INVALID_SPECIFIC_DATE_TIME_RANGE_MIN' | translate: {min: minValueText} }}</span>
      <span *ngIf="hasLocalFieldError('value', 'validateLocalDateRange') && !minValueText && maxValueText">{{ 'COMMON_VALIDATION_MESSAGE_INVALID_SPECIFIC_DATE_TIME_RANGE_MAX' | translate: {max: maxValueText} }}</span>
    </div>
</div>
