import {Component, OnInit} from '@angular/core';
import {DropdownItemType} from "../../../shared/dropdown/dropdown-item/dropdown-item-type";
import {DocumentFile, DocumentFileService} from "../../../lib/document/document-file.service";
import {RightModel} from "../../../app.rights";
import {QueryFieldModel, SelectUtils, UiConstants} from "../../../util/core-utils";
import {OrderType, Services} from "../../../lib/util/services";
import {DocumentFileListModel, DocumentFileSearchModel} from "../../../util/document/document-file-utils";
import {DisabledItem} from "../../../util/search-utils";
import {DocumentGroup, DocumentGroupService} from "../../../lib/document/document-group.service";
import {RightResolver, RightService} from "../../../lib/right.service";
import {TranslateService} from "@ngx-translate/core";
import {Set} from "immutable";
import {saveAs} from 'file-saver';
import {DocumentGroupGraph, DocumentGroupGraphNode} from "./document-group-graph";
import {BehaviorSubject, Observable, of} from "rxjs";
import {FileTypeModelFactory} from "../../../util/file-attachment.model";

@Component({
  selector: 'app-document-library',
  templateUrl: './document-library.component.html',
  styleUrls: ['./document-library.component.scss']
})
export class DocumentLibraryComponent implements OnInit {

  DocumentFile = DocumentFile;
  UiConstants = UiConstants;
  DropdownItemType = DropdownItemType;
  SelectUtils = SelectUtils;

  rightModel: RightModel = RightModel.empty();

  queryModel: QueryFieldModel<DocumentFile.OrderField> = new QueryFieldModel(DocumentFile.OrderField.NAME, OrderType.ASC);
  documentListModel: DocumentFileListModel[] = [];
  disabledItems: DisabledItem[] = [];
  documentGroupGraph: DocumentGroupGraph;

  searchModel: DocumentFileSearchModel = new DocumentFileSearchModel();
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  get documentAspectRatio(): string {
    return DocumentFile.DOCUMENT_LIBRARY_ASPECT_RATIO_STR;
  }
  get currentDocumentGroupLevel(): Observable<DocumentGroupGraphNode[]> {
    return this.documentGroupGraph.currentDocumentGroupLevel;
  }
  get selectedGroups(): DocumentGroupGraphNode[] {
    return this.documentGroupGraph.selectedNodes;
  }

  constructor(private rightService: RightService,
              private translateService: TranslateService,
              private documentFileService: DocumentFileService,
              private documentGroupService: DocumentGroupService,
  ) {
    this.documentGroupGraph = new DocumentGroupGraph(this.documentGroupService);
  }

  ngOnInit(): void {
    this.translateService.get('MENU_NAVBAR_DOCUMENT_LIBRARY').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.loadRightModels();
    this.documentGroupGraph.nodeSelected();
    this.documentGroupGraph.currentDocumentGroupLevel.subscribe(res => {
      this.searchModel.documentGroupId = this.documentGroupGraph.getSelectedId();
      this.loadDocuments(this.searchModel.documentGroupId);
    })
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  backGroups() {
    this.documentGroupGraph.back();
  }

  gotoGroup(group: DocumentGroupGraphNode) {
    this.documentGroupGraph.nodeSelected(group);
  }

  orderBy(field: DocumentFile.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    if (this.searchModel.documentGroupId) {
      this.loadDocuments(this.searchModel.documentGroupId);
    }
  }

  private loadDocuments(groupId: number | null) {
    if (!groupId) {
     this.documentListModel = [];
      return;
    }
    const order = this.queryModel.getOrder();
    this.documentFileService.query({
      order: Services.createOrderFieldParameter(DocumentFile.Keys.toOrderFieldKey, Set.of(order)),
      disabled: false,
      document_group_id: groupId,
      no_progress_bar: true
    }).subscribe(result => {
      this.documentListModel = result.items.map((document) => {
        return DocumentFileListModel.fromApi(document);
      });
      this.documentListModel.forEach(document => {
        if (document.hasThumbnail) {
          document.thumbnailFile.download(this.documentFileService.downloadThumbnail(document.id, true));
        }
      })
    })
  }

  downloadFile(documentModel: DocumentFileListModel) {
    this.documentFileService.downloadContent(documentModel.id).subscribe(res => {
      saveAs(res.getBlob(), res.getFileName(documentModel.name));
    })
  }

  canGoBackGroups() {
    return this.documentGroupGraph.canGoBack();
  }

  homeGroups() {
    this.documentGroupGraph.home();
  }

  protected readonly FileTypeModelFactory = FileTypeModelFactory;
}
