/* eslint-disable */
import { Pipe, PipeTransform } from '@angular/core';
import { Models } from '../model-utils';
import { Decimal } from 'decimal.js/decimal';
/* eslint-enable */

@Pipe({
  name: 'formattedNumber'
})
export class FormattedNumberPipe implements PipeTransform {

  transform(val?: Decimal | number | string, separator?: boolean, numberOfDigits?: number): string {
    numberOfDigits = numberOfDigits === undefined ? 2 : numberOfDigits;
    separator = separator ? separator : true;
    return Models.decimalToFormattedString(val, separator, numberOfDigits);
  }

}
