/* eslint-disable */
import { Icon } from '../task/icon.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DisabledItem } from '../../util/search-utils';

/* eslint-enable */

export class LegacyWorkflowCreateModel {
  name: string = '';
  description: string = '';
  externalId: string = '';
  icon?: Icon.Icon = {
    iconType: 'ICON_SET',
    iconSet: {
      setCode: 'google_material',
      setClass: 'material-icons',
      iconCode: 'pie_chart',
      usageType: 'TEXT'
    }
  };
}

export class LegacyWorkflowEditModel extends LegacyWorkflowCreateModel {
  version: number;
}

export class LegacyWorkflowSearchModel {
  id: string;
  name: string;
  externalId: string;
  description: string;
  disabled: DisabledItem | null = null;
  creationTimeFrom: NgbDateStruct | null = null;
  creationTimeTo: NgbDateStruct | null = null;
  updateTimeFrom: NgbDateStruct | null = null;
  updateTimeTo: NgbDateStruct | null = null;

  public isEmpty(): boolean {
    return this.id.length === 0
      && this.name.length === 0
      && this.externalId.length === 0
      && this.description.length === 0
      && this.creationTimeFrom === null
      && this.creationTimeTo === null
      && this.updateTimeFrom === null
      && this.updateTimeTo === null
      && (this.disabled ? this.disabled.id === DisabledItem.active().id : true)
      ;
  }
}

export class LegacyWorkflowTaskCreateModel {
  name: string = '';
  externalId: string = '';
  description: string = '';
  inProgressStateName: string = '';
  finishedStateName: string = '';
  customerSignature: boolean = false;
  userSignature: boolean = false;
}

export class LegacyWorkflowTaskEditModel extends LegacyWorkflowTaskCreateModel {
  version: number = 0;
}
