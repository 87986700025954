<div class="cuppa-dropdown" (clickOutside)="closeDropdownOnClickOut()">
  <div class="selected-list" #selectedList>
    <div class="c-btn" (click)="toggleDropdown($event)" [ngClass]="{'disabled': settings.disabled, 'readonly': readonly && !readonlyBorderEnabled}" [attr.tabindex]="0">
      <span *ngIf="selectedItems?.length === 0">{{settings.text | translate }}</span>
      <div class="c-list" *ngIf="selectedItems?.length > 0 && settings.singleSelection && !badgeTempl">
        <div class="c-token" *ngFor="let item of selectedItems;trackBy: trackByFn.bind(this);let k = index"
             [hidden]="!readonly && k > settings.badgeShowLimit-1"
             [ngClass]="{'disabled' : item['disabled'], 'readonly': readonly}"
             (click)="readonly ? onReadonlyItemClick.emit(item) : ''">
          <span *ngIf="!badgeTempl" class="c-label">
            {{settings.translate ? (item[settings.labelKey] | translate)
            : item['itemSubtitle'] ? item[settings.labelKey] + ' (' + item['itemSubtitle'] + ')' : item[settings.labelKey]}}
          </span>
          <span *ngIf="badgeTempl" class="c-label">
                    <c-templateRenderer [data]="badgeTempl" [item]="item"></c-templateRenderer>
          </span>
          <span class="c-remove" (click)="onItemClick(item,k,$event);$event.stopPropagation()" *ngIf="!readonly">
                    <c-icon [name]="'remove'"></c-icon>
          </span>
        </div>
      </div>
      <span class="c-list" *ngIf="selectedItems?.length > 0 && settings.singleSelection && badgeTempl ">
                <div class="c-token" *ngFor="let item of selectedItems;trackBy: trackByFn.bind(this);let k = index" [ngClass]="{'readonly': readonly}"
                     (click)="readonly ? onReadonlyItemClick.emit(item) : ''">
                <span *ngIf="!badgeTempl" class="c-label">{{settings.translate ? (item[settings.labelKey] | translate) : item[settings.labelKey]}}</span>

                <span *ngIf="badgeTempl" class="c-label">
                            <c-templateRenderer [data]="badgeTempl" [item]="item"></c-templateRenderer>
                </span>
            <span class="c-remove" (click)="onItemClick(item,k,$event);$event.stopPropagation()" *ngIf="!readonly">
                <c-icon [name]="'remove'"></c-icon>
            </span>
              </div>
            </span>
      <div class="c-list" *ngIf="selectedItems?.length > 0 && !settings.singleSelection">
        <div class="c-token" *ngFor="let item of selectedItems;trackBy: trackByFn.bind(this);let k = index"
             [hidden]="!readonly && k > settings.badgeShowLimit-1"
             [ngClass]="{'disabled' : item['disabled'], 'readonly': readonly}"
             (click)="readonly ? onReadonlyItemClick.emit(item) : ''">
          <span *ngIf="!badgeTempl" class="c-label">{{settings.translate ? (item[settings.labelKey] | translate) : item[settings.labelKey]}}</span>
          <span *ngIf="badgeTempl" class="c-label">
                    <c-templateRenderer [data]="badgeTempl" [item]="item"></c-templateRenderer>
          </span>
          <span class="c-remove" (click)="onItemClick(item,k,$event);$event.stopPropagation()" *ngIf="!readonly">
                    <c-icon [name]="'remove'"></c-icon>
          </span>
        </div>
      </div>
      <span class="countplaceholder"
            *ngIf="!readonly && selectedItems?.length > settings.badgeShowLimit">+{{selectedItems?.length - settings.badgeShowLimit }}</span>
      <span *ngIf="!readonly && !isActive" class="c-angle-down">
        <c-icon [name]="'angle-down'"></c-icon>
      </span>
      <span *ngIf="isActive" class="c-angle-up">
            <c-icon [name]="'angle-up'"></c-icon>
      </span>
    </div>
  </div>
  <div #dropdownList class="dropdown-list"
       [ngClass]="{'dropdown-list-top': dropdownListYOffset}"
       [style.bottom.px]="dropdownListYOffset ? dropdownListYOffset : null"
       [hidden]="!isActive">
    <div [ngClass]="{'arrow-up': !dropdownListYOffset, 'arrow-down': dropdownListYOffset}" class="arrow-2"></div>
    <div [ngClass]="{'arrow-up': !dropdownListYOffset, 'arrow-down': dropdownListYOffset}"></div>
    <div class="list-area">
      <div class="list-filter" *ngIf="settings.enableSearchFilter">
            <span class="c-search">
                <c-icon [name]="'search'"></c-icon>
                </span>
        <span *ngIf="!settings.lazyLoading" [hidden]="filter === undefined || filter?.length === 0" class="c-clear" (click)="clearSearch()">
                <c-icon [name]="'clear'"></c-icon>
                </span>
        <span *ngIf="settings.lazyLoading" [hidden]="filter === undefined || filter?.length === 0" class="c-clear"
              (click)="resetInfiniteSearch()">
                <c-icon [name]="'clear'"></c-icon>
                </span>

        <input class="c-input" *ngIf="settings.groupBy && !settings.lazyLoading && !searchTempl" #searchInput type="text"
               [placeholder]="settings.searchPlaceholderText | translate"
               [(ngModel)]="filter" (keyup)="filterGroupedList()">
        <input class="c-input" *ngIf="!settings.groupBy && !settings.lazyLoading && !searchTempl && !settings.remoteSearch" #searchInput type="text"
               [placeholder]="settings.searchPlaceholderText | translate"
               [(ngModel)]="filter">
        <input class="c-input" *ngIf="settings.lazyLoading && !searchTempl" #searchInput type="text"
               [placeholder]="settings.searchPlaceholderText | translate"
               [(ngModel)]="filter" (keyup)="filterInfiniteList($event)">
        <input class="c-input" *ngIf="!settings.groupBy && !settings.lazyLoading && !searchTempl && settings.remoteSearch" #searchInput type="text"
               [placeholder]="settings.searchPlaceholderText | translate"
               [(ngModel)]="filter" (keyup)="searchSubject.next($event)">
        <!--            <input class="c-input" *ngIf="!settings.lazyLoading && !searchTempl && settings.groupBy" #searchInput type="text" [placeholder]="settings.searchPlaceholderText | translate"
            [(ngModel)]="filter" (keyup)="filterGroupList($event)">-->
        <c-templateRenderer *ngIf="searchTempl" [data]="searchTempl" [item]="item" #searchTemplateRenderer></c-templateRenderer>
      </div>
      <div class="pure-checkbox select-all"
           *ngIf="settings.enableCheckAll && !settings.singleSelection && !settings.limitSelection && data?.length > 0 && (filter?.length === 0 || filter === undefined)"
           (click)="toggleSelectAll()">
        <input *ngIf="settings.showCheckbox" type="checkbox" [checked]="isSelectAll"
               [disabled]="settings.limitSelection === selectedItems?.length"
        />
        <label>
          <span [hidden]="isSelectAll">{{settings.selectAllText | translate | translate}}</span>
          <span [hidden]="!isSelectAll">{{settings.unSelectAllText | translate}}</span>
        </label>
        <img class="loading-icon" *ngIf="loading" src="assets/img/loading.gif"/>
      </div>
      <div class="filter-select-all" *ngIf="!settings.remoteSearch && !settings.lazyLoading && settings.enableFilterSelectAll">
        <div class="pure-checkbox select-all" *ngIf="!settings.groupBy && filter?.length > 0 && filterLength > 0"
             (click)="toggleFilterSelectAll()">
          <input type="checkbox" [checked]="isFilterSelectAll" [disabled]="settings.limitSelection === selectedItems?.length"/>
          <label>
            <span [hidden]="isFilterSelectAll">{{settings.filterSelectAllText | translate}}</span>
            <span [hidden]="!isFilterSelectAll">{{settings.filterUnSelectAllText | translate}}</span>
          </label>
        </div>
        <div class="pure-checkbox select-all" *ngIf="settings.groupBy && filter?.length > 0 && groupedData?.length > 0"
             (click)="toggleFilterSelectAll()">
          <input type="checkbox" [checked]="isFilterSelectAll && filter?.length > 0"
                 [disabled]="settings.limitSelection === selectedItems?.length"/>
          <label>
            <span [hidden]="isFilterSelectAll">{{settings.filterSelectAllText | translate}}</span>
            <span [hidden]="!isFilterSelectAll">{{settings.filterUnSelectAllText | translate}}</span>
          </label>
        </div>
        <h5 class="list-message" *ngIf="!settings.groupBy && filterLength === 0 && !settings.remoteSearch"
               [hidden]="filter === undefined || filter?.length === 0">{{settings.noDataLabel | translate}}</h5>
        <label class="nodata-label" *ngIf="settings.groupBy && groupedData?.length === 0 && false"
               [hidden]="filter === undefined || filter?.length === 0">{{settings.noDataLabel | translate}}</label>

        <div class="btn-container" *ngIf="settings.addNewItemOnFilter && filterLength === 0"
             [hidden]="filter === undefined || filter?.length === 0">
          <button class="c-btn btn-iceblue" (click)="addFilterNewItem()">{{settings.addNewButtonText}}</button>
        </div>
      </div>
      <div class="filter-select-all" *ngIf="settings.lazyLoading && settings.enableFilterSelectAll">
        <div class="pure-checkbox select-all" *ngIf="filter?.length > 0 && infiniteFilterLength > 0"
             (click)="toggleInfiniteFilterSelectAll()">
          <input type="checkbox" [checked]="isInfiniteFilterSelectAll" [disabled]="settings.limitSelection === selectedItems?.length"
          />
          <label>
            <span [hidden]="isInfiniteFilterSelectAll">{{settings.filterSelectAllText | translate}}</span>
            <span [hidden]="!isInfiniteFilterSelectAll">{{settings.filterUnSelectAllText | translate}}</span>
          </label>
        </div>
      </div>
      <div class="filter-select-all" *ngIf="settings.remoteSearch && settings.enableFilterSelectAll">
        <div class="pure-checkbox select-all" *ngIf="filter?.length > 0 && data?.length > 0"
             (click)="toggleSelectAll()">
          <input type="checkbox" [checked]="isSelectAll" [disabled]="settings.limitSelection === selectedItems?.length"
          />
          <label>
            <span [hidden]="isInfiniteFilterSelectAll">{{settings.filterSelectAllText | translate}}</span>
            <span [hidden]="!isInfiniteFilterSelectAll">{{settings.filterUnSelectAllText | translate}}</span>
          </label>
        </div>
      </div>

      <div *ngIf="itemTempl === undefined && settings.remoteSearch" [style.maxHeight]="settings.maxHeight+'px'"
           style="overflow: auto;">
        <ul class="lazyContainer">
          <li *ngFor="let item of data; let i = index;" (click)="onItemClick(item,i,$event)"
              class="pure-checkbox" [ngClass]="{'selected-item': isSelected(item) === true }">
            <input *ngIf="settings.showCheckbox" type="checkbox" [checked]="isSelected(item)"
                   [disabled]="settings.limitSelection === selectedItems?.length && !isSelected(item)"
            />
            <label>
              {{settings.translate ? (item[settings.labelKey] | translate) : item[settings.labelKey]}}
              <br *ngIf="item['itemSubtitle']">
              <span *ngIf="item['itemSubtitle']" class="font-xs text-muted">{{item['itemSubtitle']}}</span>
            </label>
          </li>
        </ul>
      </div>
      <div *ngIf="!settings.groupBy && !settings.lazyLoading && itemTempl === undefined && !settings.remoteSearch" [style.maxHeight]="settings.maxHeight+'px'"
           style="overflow: auto;">
        <ul class="lazyContainer">
          <li *ngFor="let item of data | listFilter:filter : settings.searchBy : settings.translate; let i = index;" (click)="onItemClick(item,i,$event)"
              class="pure-checkbox" [ngClass]="{'selected-item': isSelected(item) === true }">
            <input *ngIf="settings.showCheckbox" type="checkbox" [checked]="isSelected(item)"
                   [disabled]="settings.limitSelection === selectedItems?.length && !isSelected(item)"
            />
            <label>
              {{settings.translate ? (item[settings.labelKey] | translate) : item[settings.labelKey]}}
              <br *ngIf="item['itemSubtitle']">
              <span *ngIf="item['itemSubtitle']" class="font-xs text-muted">{{item['itemSubtitle']}}</span>
            </label>
          </li>
        </ul>
      </div>
      <div *ngIf="!settings.groupBy && settings.lazyLoading && itemTempl === undefined && !settings.remoteSearch" [style.maxHeight]="settings.maxHeight+'px'"
           style="overflow: auto;">
        <virtual-scroll [items]="data" (vsUpdate)="viewPortItems = $event" (vsEnd)="onScrollEnd($event)"
                        [ngStyle]="{'height': settings.maxHeight+'px'}">
          <ul class="lazyContainer">
            <li *ngFor="let item of viewPortItems | listFilter:filter : settings.searchBy : settings.translate; let i = index;"
                (click)="onItemClick(item,i,$event)"
                class="pure-checkbox" [ngClass]="{'selected-item': isSelected(item) === true }">
              <input *ngIf="settings.showCheckbox" type="checkbox" [checked]="isSelected(item)"
                     [disabled]="settings.limitSelection === selectedItems?.length && !isSelected(item)"
              />
              <label>
                {{settings.translate ? (item[settings.labelKey] | translate) : item[settings.labelKey]}}
                <br *ngIf="item['itemSubtitle']">
                <span *ngIf="item['itemSubtitle']" class="font-xs text-muted">{{item['itemSubtitle']}}</span>
              </label>
            </li>
          </ul>
        </virtual-scroll>
      </div>
      <div *ngIf="!settings.groupBy && !settings.lazyLoading && itemTempl !== undefined && !settings.remoteSearch" [style.maxHeight]="settings.maxHeight+'px'"
           style="overflow: auto;">
        <ul class="lazyContainer">
          <li *ngFor="let item of data | listFilter:filter : settings.searchBy : settings.translate; let i = index;" (click)="onItemClick(item,i,$event)"
              class="pure-checkbox" [ngClass]="{'selected-item': isSelected(item) === true }">
            <input *ngIf="settings.showCheckbox" type="checkbox" [checked]="isSelected(item)"
                   [disabled]="settings.limitSelection === selectedItems?.length && !isSelected(item)"
            />
            <label></label>
            <c-templateRenderer [data]="itemTempl" [item]="item"></c-templateRenderer>
          </li>
        </ul>
      </div>
      <div *ngIf="!settings.groupBy && settings.lazyLoading && itemTempl !== undefined && !settings.remoteSearch" [style.maxHeight]="settings.maxHeight+'px'"
           style="overflow: auto;">
        <virtual-scroll [items]="data" (vsUpdate)="viewPortItems = $event" (vsEnd)="onScrollEnd($event)"
                        [ngStyle]="{'height': settings.maxHeight+'px'}">

          <ul class="lazyContainer">
            <li *ngFor="let item of viewPortItems | listFilter:filter : settings.searchBy : settings.translate; let i = index;"
                (click)="onItemClick(item,i,$event)"
                class="pure-checkbox" [ngClass]="{'selected-item': isSelected(item) === true }">
              <input *ngIf="settings.showCheckbox" type="checkbox" [checked]="isSelected(item)"
                     [disabled]="settings.limitSelection === selectedItems?.length && !isSelected(item)"
              />
              <label></label>
              <c-templateRenderer [data]="itemTempl" [item]="item"></c-templateRenderer>
            </li>
          </ul>
        </virtual-scroll>
      </div>
      <div *ngIf="settings.groupBy && settings.lazyLoading && itemTempl !== undefined && !settings.remoteSearch" [style.maxHeight]="settings.maxHeight+'px'"
           style="overflow: auto;">
        <virtual-scroll [items]="groupedData" (vsUpdate)="viewPortItems = $event" (vsEnd)="onScrollEnd($event)"
                        [ngStyle]="{'height': settings.maxHeight+'px'}">
          <ul class="lazyContainer">
                <span *ngFor="let item of viewPortItems | listFilter:filter : settings.searchBy : settings.translate; let i = index;">
                <li (click)="onItemClick(item,i,$event)" *ngIf="!item.grpTitle"
                    [ngClass]="{'grp-title': item.grpTitle,'grp-item': !item.grpTitle}" class="pure-checkbox">
                    <input *ngIf="settings.showCheckbox" type="checkbox" [checked]="isSelected(item)"
                           [disabled]="settings.limitSelection === selectedItems?.length && !isSelected(item)"
                    />
                    <label></label>
                    <c-templateRenderer [data]="itemTempl" [item]="item"></c-templateRenderer>
                </li>
                <li *ngIf="item.grpTitle" [ngClass]="{'grp-title': item.grpTitle,'grp-item': !item.grpTitle}" class="pure-checkbox">
                    <input *ngIf="settings.showCheckbox" type="checkbox" [checked]="isSelected(item)"
                           [disabled]="settings.limitSelection === selectedItems?.length && !isSelected(item)"
                    />
                    <label></label>
                    <c-templateRenderer [data]="itemTempl" [item]="item"></c-templateRenderer>
                </li>
                </span>
          </ul>
        </virtual-scroll>
      </div>
      <div *ngIf="settings.groupBy && !settings.lazyLoading && itemTempl !== undefined && !settings.remoteSearch" [style.maxHeight]="settings.maxHeight+'px'"
           style="overflow: auto;">
        <ul class="lazyContainer">
                <span *ngFor="let item of groupedData; let i = index;">
                    <li (click)="selectGroup(item)" [ngClass]="{'grp-title': item.grpTitle,'grp-item': !item.grpTitle}"
                        class="pure-checkbox">
                    <input *ngIf="settings.showCheckbox" type="checkbox" [checked]="item.selected"
                           [disabled]="settings.limitSelection === selectedItems?.length && !isSelected(item)"
                    />
                    <label>{{settings.translate ? (item[settings.labelKey] | translate) : item[settings.labelKey]}}</label>
                    <ul class="lazyContainer">
                        <span *ngFor="let val of item.list ; let j = index;">
                        <li (click)="onItemClick(val,j,$event); $event.stopPropagation()"
                            [ngClass]="{'grp-title': val.grpTitle,'grp-item': !val.grpTitle}" class="pure-checkbox">
                                <input *ngIf="settings.showCheckbox" type="checkbox" [checked]="isSelected(val)"
                                       [disabled]="settings.limitSelection === selectedItems?.length && !isSelected(val)"
                                />
                                <label></label>
                                <c-templateRenderer [data]="itemTempl" [item]="val"></c-templateRenderer>
                            </li>
                            </span>
                </ul>

                </li>
                </span>
        </ul>
      </div>
      <div *ngIf="settings.groupBy && settings.lazyLoading && itemTempl === undefined" [style.maxHeight]="settings.maxHeight+'px'"
           style="overflow: auto;">
        <virtual-scroll [items]="groupedData" (vsUpdate)="viewPortItems = $event" (vsEnd)="onScrollEnd($event)"
                        [ngStyle]="{'height': settings.maxHeight+'px'}">
          <ul class="lazyContainer">
                    <span *ngFor="let item of viewPortItems; let i = index;">
                <li *ngIf="item.grpTitle"
                    [ngClass]="{'grp-title': item.grpTitle,'grp-item': !item.grpTitle, 'selected-item': isSelected(item) === true }"
                    class="pure-checkbox">
                    <input *ngIf="settings.showCheckbox && !item.grpTitle" type="checkbox" [checked]="isSelected(item)"
                           [disabled]="settings.limitSelection === selectedItems?.length && !isSelected(item)"
                    />
                    <label>{{settings.translate ? (item[settings.labelKey] | translate) : item[settings.labelKey]}}</label>
                </li>
                <li (click)="onItemClick(item,i,$event)" *ngIf="!item.grpTitle"
                    [ngClass]="{'grp-title': item.grpTitle,'grp-item': !item.grpTitle, 'selected-item': isSelected(item) === true }"
                    class="pure-checkbox">
                    <input *ngIf="settings.showCheckbox && !item.grpTitle" type="checkbox" [checked]="isSelected(item)"
                           [disabled]="settings.limitSelection === selectedItems?.length && !isSelected(item)"
                    />
                    <label>{{settings.translate ? (item[settings.labelKey] | translate) : item[settings.labelKey]}}</label>
                </li>
                </span>
          </ul>
        </virtual-scroll>
      </div>
      <div *ngIf="settings.groupBy && !settings.lazyLoading && itemTempl === undefined" [style.maxHeight]="settings.maxHeight+'px'"
           style="overflow: auto;">
        <ul class="lazyContainer">
                    <span *ngFor="let item of groupedData ; let i = index;">
                            <li (click)="selectGroup(item)" [ngClass]="{'grp-title': item.grpTitle,'grp-item': !item.grpTitle}"
                                class="pure-checkbox">
                                    <input *ngIf="settings.showCheckbox" type="checkbox" [checked]="item.selected"
                                           [disabled]="settings.limitSelection === selectedItems?.length && !isSelected(item)"
                                    />
                                    <label>{{settings.translate ? (item[settings.labelKey] | translate) : item[settings.labelKey]}}</label>
                                    <ul class="lazyContainer">
                                            <span *ngFor="let val of item.list ; let j = index;">
                                            <li (click)="onItemClick(val,j,$event); $event.stopPropagation()"
                                                [ngClass]="{'grp-title': val.grpTitle,'grp-item': !val.grpTitle}" class="pure-checkbox">
                                                    <input *ngIf="settings.showCheckbox" type="checkbox" [checked]="isSelected(val)"
                                                           [disabled]="settings.limitSelection === selectedItems?.length && !isSelected(val)"
                                                    />
                                                    <label>{{settings.translate ? (val[settings.labelKey] | translate) : val[settings.labelKey]}}</label>
                                                </li>
                                                </span>
                                    </ul>
                                </li>
                    </span>
          <!-- <span *ngFor="let item of groupedData ; let i = index;">
              <li (click)="onItemClick(item,i,$event)" *ngIf="!item.grpTitle" [ngClass]="{'grp-title': item.grpTitle,'grp-item': !item.grpTitle}" class="pure-checkbox">
              <input *ngIf="settings.showCheckbox && !item.grpTitle" type="checkbox" [checked]="isSelected(item)" [disabled]="settings.limitSelection === selectedItems?.length && !isSelected(item)"
              />
              <label>{{settings.translate ? (item[settings.labelKey] | translate) : item[settings.labelKey]}}</label>
          </li>
          <li *ngIf="item.grpTitle && !settings.selectGroup" [ngClass]="{'grp-title': item.grpTitle,'grp-item': !item.grpTitle}" class="pure-checkbox">
              <input *ngIf="settings.showCheckbox && settings.selectGroup" type="checkbox" [checked]="isSelected(item)" [disabled]="settings.limitSelection === selectedItems?.length && !isSelected(item)"
              />
              <label>{{settings.translate ? (item[settings.labelKey] | translate) : item[settings.labelKey]}}</label>
          </li>
           <li  (click)="selectGroup(item)" *ngIf="item.grpTitle && settings.selectGroup" [ngClass]="{'grp-title': item.grpTitle,'grp-item': !item.grpTitle}" class="pure-checkbox">
              <input *ngIf="settings.showCheckbox && settings.selectGroup" type="checkbox" [checked]="item.selected" [disabled]="settings.limitSelection === selectedItems?.length && !isSelected(item)"
              />
              <label>{{settings.translate ? (item[settings.labelKey] | translate) : item[settings.labelKey]}}</label>
          </li>
          </span> -->
        </ul>
      </div>
      <h5 class="list-message debugclass" *ngIf="data?.length === 0">{{settings.noDataLabel | translate}}</h5>
      <h5 class="list-message" *ngIf="!settings.remoteSearch && filterLength === 0 && filter?.length > 0 && !settings.enableCheckAll">{{settings.noDataLabel | translate}}</h5>
    </div>
  </div>
</div>
