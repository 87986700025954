/* eslint-disable */
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { TransportAttachmentService } from '../../../lib/transport/transport-attachment/transport-attachment.service';
import { FileAttachment } from '../../../lib/util/file-attachments';
import { DetailTab } from '../transport-detail/detail-tab';
import {
  FileAttachmentGalleryComponent,
  FileLoaderFactory
} from '../../../shared/file-attachment-gallery/file-attachment-gallery.component';
import { FileAttachmentModel } from '../../../util/file-attachment.model';
import { Observable } from 'rxjs';
import { NamedBlobFile } from '../../../lib/util/downloaded-files';
import { AttachmentContainer } from '../../../shared/file-attachment-gallery/attachment-container';
/* eslint-enable */

@Component({
  selector: 'app-transport-attachment',
  templateUrl: './transport-attachment.component.html',
  styleUrls: ['./transport-attachment.component.scss']
})
export class TransportAttachmentComponent implements OnInit, AfterViewInit, DetailTab, FileLoaderFactory, AttachmentContainer {

  @Input()
  transportId: number;

  @ViewChildren(FileAttachmentGalleryComponent)
  galleryView: QueryList<FileAttachmentGalleryComponent>;

  @Output()
  private openImageViewerWindowEvent = new EventEmitter();

  @Output()
  private clickedImageEventEmitter: EventEmitter<FileAttachmentModel> = new EventEmitter();

  fileLoaderFactory = this;

  attachments: FileAttachment[] = [];

  constructor(private transportAttachmentService: TransportAttachmentService) { }

  ngOnInit(): void {
  }

  initComponent() {
    this.transportAttachmentService.getAttachments({
      transportId: this.transportId
    }).subscribe((result: FileAttachment[]) => {
      this.attachments = result;
    });
  }

  public createFileLoader(attachment: FileAttachment): Observable<NamedBlobFile> {
    return this.transportAttachmentService.downloadAttachment({
      transportId: this.transportId,
      attachment: attachment,
    });
  }

  hasThumbnails(): boolean {
    return false;
  }

  openImageViewerWindow() {
    this.openImageViewerWindowEvent.emit();
  }

  clickedImageEventEmitted(clickedImage: FileAttachmentModel) {
    this.clickedImageEventEmitter.emit(clickedImage);
  }

  ngAfterViewInit(): void {
    this.galleryView.changes.subscribe((comps: QueryList<FileAttachmentGalleryComponent>) => {
      if (this.galleryView.length > 0) {
        this.galleryView.first.activateView();
      }
    });
  }

  onNextClicked() {
    this.galleryView.first.onNextClicked(this.galleryView.first.clickedImage);
  }

  onPreviousClicked() {
    this.galleryView.first.onPreviousClicked(this.galleryView.first.clickedImage);
  }

}
