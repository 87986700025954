<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<!-- region Content -->
<div class="container-horizontal-padding-half container-vertical-padding-half animated fadeIn">

  <div class="row ml-0 mr-0">

    <!-- region StockTaking card -->
    <div class="col-xs-12 col-sm-12 col-md-6 card-custom-padding">
      <div class="container-vertical-padding-half">
        <div class="card">

          <div class="card-header">
            {{'STOCK_TAKING_EDIT'|translate}}
          </div><!-- /.card-header -->

          <div class="card-body">

            <div class="form-group row" [ngClass]="{ 'has-danger': formSubmitted && model.name.length === 0 }">
              <div class="col-md-4">
                <label
                  class="col-form-label mw-100 form-control-label">{{'COMMON_NAME'|translate}}</label>
              </div>
              <div class="col-md-8">
                <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
                       maxlength="{{UiConstants.maximumVarcharLength}}"
                       [(ngModel)]="model.name">
                <div class="form-control-feedback" *ngIf="formSubmitted && model.name.length === 0">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-4">
                <label
                  class="col-form-label mw-100 form-control-label">{{'STOCK_TAKING_TRX_ID'|translate}}</label>
              </div>
              <div class="col-md-8">
                <label
                  class="col-form-label mw-100 form-control-label">{{model.trxId}}</label>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-4">
                <label
                  class="col-form-label mw-100 form-control-label">{{'STOCK_TAKING_OWNER_USER'|translate}}</label>
              </div>
              <div class="col-md-8">
                <label
                  class="col-form-label mw-100 form-control-label">{{model.ownerUserName}}</label>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-4">
                <label
                  class="col-form-label mw-100 form-control-label">{{'STOCK_TAKING_STOCKS'|translate}}</label>
              </div>
              <div class="col-md-8">
                <angular2-multiselect
                  [(ngModel)]="model.stocks"
                  [ngModelOptions]="{standalone: true}"
                  [data]="stocks"
                  (onRemoteSearch)="loadStocks(undefined, $event.target.value)"
                  [settings]="dropdownSettings">
                </angular2-multiselect>
              </div>
            </div>

            <div class="form-group row" *ngIf="model.creationTime">
              <div class="col-md-4">
                <label
                  class="col-form-label mw-100 form-control-label">{{'COMMON_CREATION_TIME'|translate}}</label>
              </div>
              <div class="col-md-8">
                <label
                  class="col-form-label mw-100 form-control-label">{{model.creationTime.toUtcIsoString() | date:'short'}}</label>
              </div>
            </div>

            <div class="row m-0 d-flex justify-content-end">
              <input type="button" class="btn btn-secondary mr-1" value="{{'COMMON_BUTTON_APPLY'|translate}}"
                     (click)="saveData(false)"/>
              <input type="button" class="btn btn-primary" value="{{'COMMON_BUTTON_SAVE'|translate}}"
                     (click)="saveData(true)"/>
            </div>

          </div><!-- /.card-body -->

        </div><!-- /.card -->
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 card-custom-padding">

      <div class="container-vertical-padding-half">

        <div class="card">
          <div class="card-header">
            {{'STOCK_TAKING_PDF_TEMPLATES'|translate}}
            <div class="card-actions">
              <a class="btn-setting table-header-action-button" title="{{'COMMON_BUTTON_ADD' | translate}}"
                 (click)="openUploadPdfDialog()"><i
                class="icomoon icomoon-add"></i></a>
            </div><!-- /.card-actions -->
          </div>
          <div class="card-body">
            <div *ngIf="model.pdfTemplate" class="text-container text-center">
              <h4>{{'STOCK_TAKINGS_PDF_TEMPLATE_UPLOADED' | translate}}</h4>
              <br>
              <a class="button-icon cursor-pointer" (click)="downloadTemplate()">
                <i class="icomoon icomoon-download"></i>
              </a>
              <a class="button-icon cursor-pointer" (click)="deleteTemplate()"
                 title="{{'COMMON_BUTTON_DELETE' | translate}}">
                <i class="icomoon icomoon-trash"></i>
              </a>
            </div>
            <div *ngIf="!model.pdfTemplate" class="text-center">
              <p class="empty-form-hint-text">{{ 'PDF_LIST_ADD_TEXT' | translate }}</p>
              <button type="button" class="btn btn-secondary"
                      (click)="openUploadPdfDialog()">{{ 'COMMON_BUTTON_ADD' | translate }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div><!-- /.container-vertical-padding-half -->
  <!-- endregion StockTaking Task -->

  <!-- region StockTakingSheets card -->
  <app-stock-taking-sheet-list
    *ngIf="stockTakingId"
    [stockTakingId]="stockTakingId"
    [hasPdf]="model.pdfTemplate !== undefined"
    [readonly]="false"
    (showDialog)="showAddStockTakingSheetDialog()">
  </app-stock-taking-sheet-list>
  <!-- endregion StockTakingSheets card -->

</div>
<!-- endregion Content -->

<app-file-upload-dialog
  [uploadPath]="uploadPath"
  (onResult)="onUploadSuccess($event)"
  #uploadDialog
></app-file-upload-dialog>


<div bsModal [config]="UiConstants.modalConfig" #addStockTakingSheetDialog="bs-modal" class="modal fade" tabindex="-1"
     role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true"
     (onHide)="onModalHidden()">
  <div class="modal-dialog modal-lg" role="document" *ngIf="addStockTakingSheetDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title"><strong>{{'STOCK_TAKING_SHEET_ADD_DIALOG_HEADING' | translate}}</strong></p>
        <button type="button" class="close" (click)="closeAddStockTakingSheetDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form [formGroup]='addStockTakingSheetForm' (ngSubmit)="addStockTakingSheet()" class="modal-body border-top-1">
        <div class="form-group row"
             [ngClass]="{ 'has-danger': (addStockTakingSheetForm.controls['stock'].touched
                        && !addStockTakingSheetForm.controls['stock'].valid)
                        || (addFieldErrors.stock)}">
          <div class="col-md-4">
            <label class="col-form-label mw-100 form-control-label">{{'FORM_RECORD_STOCK_STOCK'|translate}}</label>
          </div>
          <div class="col-md-8">
            <div class="mw-100">
              <angular2-multiselect
                [(ngModel)]="selectedStock"
                [data]="initialStocks"
                [settings]="dropdownSettingsForStock"
                formControlName="stock"
                (ngModelChange)="removeFieldError(addFieldErrors.stock)"
                (onSelect)="onSelectedStockChanged()"
                (onDeSelect)="onSelectedStockChanged()">
              </angular2-multiselect>
              <div class="form-control-feedback" *ngIf="addStockTakingSheetForm.controls['stock'].touched
                                                        && addStockTakingSheetForm.controls['stock'].hasError('required')">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
              <div class="form-control-feedback" *ngIf="addFieldErrors.stock">
                {{addFieldErrors.stock.text}}
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row" *ngIf="assigneeSelectorVisible"
             [ngClass]="{ 'has-danger': (addStockTakingSheetForm.controls['assignee'].touched
                        && !addStockTakingSheetForm.controls['assignee'].valid)}">
          <div class="col-md-4">
            <label class="col-form-label mw-100 form-control-label">{{'TASK_RECORD_SEARCH_USER_NAME'|translate}}</label>
          </div>
          <div class="col-md-8">
            <div class="mw-100">
              <angular2-multiselect
                [(ngModel)]="selectedAssignee"
                [data]="selectableAssignee"
                [settings]="dropdownSettingsForStock"
                formControlName="assignee">
              </angular2-multiselect>
              <div class="form-control-feedback" *ngIf="addStockTakingSheetForm.controls['assignee'].touched
                                                        && addStockTakingSheetForm.controls['assignee'].hasError('required')">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary" (click)="closeAddStockTakingSheetDialog()">
          {{'COMMON_BUTTON_CANCEL' | translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="addStockTakingSheet()">{{'COMMON_BUTTON_ADD' |
          translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
