/* eslint-disable */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TransportLog, TransportLogService } from '../../../lib/transport/transport-log/transport-log.service';
import { QueryFieldModel } from '../../../util/core-utils';
import { OrderType, QueryResult } from '../../../lib/util/services';
import { Set } from 'immutable';
import { Transition } from '@uirouter/angular';
import { DetailTab } from '../transport-detail/detail-tab';
import { Address } from '../../../lib/address';
import { TransportTask } from '../../../lib/transport/transport-task/transport-task.service';
/* eslint-enable */


@Component({
  selector: 'app-transport-log-list',
  templateUrl: './transport-log-list.component.html',
  styleUrls: ['./transport-log-list.component.scss']
})
export class TransportLogListComponent implements OnInit, DetailTab {

  @Output()
  transportLogModalShow: EventEmitter<number> = new EventEmitter();

  @Input()
  postalAddressFormat: string = '';


  TransportLog = TransportLog;
  pagingId = 'transportLogList';


  queryModel: QueryFieldModel<TransportLog.OrderField> = new QueryFieldModel(TransportLog.OrderField.ID, OrderType.DESC);

  transportId: number;
  transportLogList: TransportLog.TransportLog[];

  constructor(
    private transportLogService: TransportLogService,
    private transition: Transition,
  ) {
    this.transportId = this.transition.params()['id'];
  }

  ngOnInit() {
  }

  initComponent() {
    this.loadList();
  }

  loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();

    this.transportLogService.query({
      transportId: this.transportId,
      orders: Set.of(order),
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined
    }).subscribe((result: QueryResult<TransportLog.TransportLog>) => {
      this.transportLogList = result.items.toArray();
      this.queryModel.currentPage = requestedPage;
      this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
    })


  }

  getTypeKey(eventType: TransportLog.TransportLogType): string {
    const logtype: TransportLog.TransportLogTypeObject | undefined = TransportLog.transportLogTypes.find((type) => type.type === eventType);
    if (logtype) {
      return logtype.stringKey;
    }
    return 'COMMON_READABLE_EXTENSION_UNKNOWN';
  }

  orderBy(field: TransportLog.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  showTransportLogDetailDialog(id: number) {
    this.transportLogModalShow.emit(id);
  }

  formatAddress(task: TransportTask.TransportTask): string {
    return (task.index + 1) + '. '
      + task.shippingPlace.name + ' ('
      + Address.PostalAddressMapper.toString(task.shippingPlace.postalAddress, this.postalAddressFormat) + ')';
  }

}
