/* eslint-disable */
import { Injectable } from '@angular/core';
import { CompanyResource, CompanyResourceService } from './company-resource.service';
import { Observable, Observer } from 'rxjs';
import {
  FieldValidationError,
  Order,
  PagingRequest,
  QueryResult,
  ResourceQueryResult,
  Services
} from '../util/services';
import { ObservableErrorResourceParser } from '../util/errors';
import { Address, AddressResource } from '../address';
import { List, Map, Set } from 'immutable';
import { OffsetDateTime } from '../util/dates';
import { PhoneNumber } from '../util/phone-number';
import { EmailAddress } from '../util/email-address';
import { EmptyMessage, IdentityMessage } from '../util/messages';
import { UserItem, UserItemResource } from '../user.service';
import { HistoryLog } from '../history-log/history-log.service';
import CompanyHistoryType = Company.CompanyHistoryType;

/* eslint-enable */

@Injectable()
export class CompanyService implements Company.Service {

  constructor(private resourceService: CompanyResourceService) {
  }

  query(request: Company.QueryRequest): Observable<QueryResult<Company.Company>> {
    return Observable.create((observer: Observer<QueryResult<Company.Company>>) => {
      const resourceRequest: CompanyResource.QueryRequest = {
        id: Services.createIdParameter(request.id),
        external_id: request.externalId,
        name: request.name,
        disabled: request.disabled,
        type: Services.createListParameter(request.type),
        transporter_company_ids: Services.createIdParameter(request.transporterCompanyIds),
        demander_company_ids: Services.createIdParameter(request.demanderCompanyIds),
        q: request.queryText,
        order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
        no_progress_bar: request.noProgressBar
      };
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<CompanyResource.Company>) => {
          observer.next({
            items: this.toPublicList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  get(request: Company.GetRequest): Observable<Company.Company> {
    return Observable.create((observer: Observer<Company.Company>) => {
      const resourceRequest: CompanyResource.GetRequest = {
        id: request.id
      };
      return this.resourceService.get(resourceRequest).subscribe(
        (result: CompanyResource.Company) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  create(request: Company.CreateRequest): Observable<IdentityMessage> {
    return Observable.create((observer: Observer<IdentityMessage>) => {
      const resourceRequest: CompanyResource.CreateRequest = {
        external_id: request.externalId,
        name: request.name,
        type: request.type,
        comment: request.comment,
        postal_address: AddressResource.Mapper.fromPublicPostalAddress(request.postalAddress),
        email_address: Services.emailAddressToString(request.emailAddress),
        phone_number: Services.phoneNumberToString(request.phoneNumber),
        tax_number: request.taxNumber,
        eu_tax_number: request.euTaxNumber,
        allowed_role_ids: request.allowedRoleIds ? request.allowedRoleIds.toArray() : undefined,
        transporter_company_ids: request.transporterCompanyIds ? request.transporterCompanyIds.toArray() : undefined
      };
      return this.resourceService.create(resourceRequest).subscribe(
        (result: IdentityMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  update(request: Company.UpdateRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: CompanyResource.UpdateRequest = {
        id: request.id,
        external_id: request.externalId,
        name: request.name,
        comment: request.comment,
        postal_address: AddressResource.Mapper.fromPublicPostalAddress(request.postalAddress),
        email_address: Services.emailAddressToString(request.emailAddress),
        phone_number: Services.phoneNumberToString(request.phoneNumber),
        tax_number: request.taxNumber,
        eu_tax_number: request.euTaxNumber,
        transporter_company_ids: request.transporterCompanyIds ? request.transporterCompanyIds.toArray() : undefined
      };
      return this.resourceService.update(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  setDisabled(request: Company.DisableRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: CompanyResource.DisableRequest = {
        id: request.id,
        disabled: request.disabled
      };
      return this.resourceService.setDisabled(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  updateRole(request: Company.UpdateRoleRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: CompanyResource.UpdateRoleRequest = {
        id: request.id,
        force_remove: request.forceRemove,
        allowed_role_ids: request.allowedRoleIds.toArray()
      };
      return this.resourceService.updateRole(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }


  private translateError(error: any): any {
    const res = ObservableErrorResourceParser.parseError(error);
    const fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
    const fieldErrorMap = ObservableErrorResourceParser.toFieldErrorMap(Keys.toValidatedField, fieldErrors);
    if (!fieldErrorMap.isEmpty()) {
      return FieldValidationError.of(fieldErrorMap);
    }
    return error;
  }

  private toPublicList(resourceList: CompanyResource.Company[]): List<Company.Company> {
    return List.of(...resourceList.map((r) => this.toPublic(r)));
  }

  private toPublic(r: CompanyResource.Company): Company.Company {
    return {
      id: r.id,
      externalId: r.external_id,
      name: r.name,
      type: <Company.CompanyType>r.type,
      disabled: r.disabled,
      comment: Services.optToString(r.comment),
      postalAddress: AddressResource.Mapper.toPublicPostalAddress(r.postal_address),
      phoneNumber: Services.toPhoneNumber(r.phone_number),
      emailAddress: Services.toEmailAddress(r.email_address),
      taxNumber: Services.optToString(r.tax_number),
      euTaxNumber: Services.optToString(r.eu_tax_number),
      allowedRoleIds: Services.optArrayToSet(r.allowed_role_ids),
      transporterCompanyIds: Services.optArrayToSet(r.transporter_company_ids),
      locationIds: Services.optArrayToSet(r.location_ids),
      lastEditorId: r.last_editor_id,
      creationTime: Services.toOffsetDateTime(r.creation_time),
      updateTime: Services.toOffsetDateTime(r.update_time),
      updateCount: r.update_count
    };
  }

  history(request: Company.HistoryRequest): Observable<QueryResult<Company.HistoryItem>> {
    return Observable.create((observer: Observer<QueryResult<Company.HistoryItem>>) => {
      const resourceRequest: CompanyResource.HistoryRequest = {
        id: request.id,
        with_read: request.withRead,
        q: request.queryText,
        order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
      };
      return this.resourceService.history(resourceRequest).subscribe(
        (result: ResourceQueryResult<CompanyResource.HistoryItem>) => {
          observer.next({
            items: this.toPublicHistoryList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });

    });
  }

  private toPublicHistoryList(resourceList: CompanyResource.HistoryItem[]): List<Company.HistoryItem> {
    return List.of(...resourceList.map((r) => this.toPublicHistory(r)));
  }

  private toPublicHistory(r: CompanyResource.HistoryItem): Company.HistoryItem {
    return {
      id: r.id,
      creationTime: Services.toOffsetDateTime(r.creation_time),
      type: <CompanyHistoryType>r.type,
      changeLog: r.change_log,
      applicationClassification: r.application_classification,
      issuerUser: this.toPublicUser(r.issuer_user!),
      mobileApplication: this.toPublicMobileApplication(r.mobile_application)
    };
  }

  private toPublicUser(r: UserItemResource): UserItem {
    return {
      id: r.id,
      personName: r.person_name
    };
  }

  private toPublicMobileApplication(r: HistoryLog.MobileApplicationItemResource | undefined):
    HistoryLog.MobileApplicationItem | undefined {
    if (r) {
      return {
        id: r.id,
        applicationId: r.application_id
      };
    }
    return undefined;
  }

}

export namespace Company {

  export interface Service {

    // <editor-fold desc="CRUD">

    query(request: QueryRequest): Observable<QueryResult<Company>>;

    get(request: GetRequest): Observable<EmptyMessage>;

    create(request: CreateRequest): Observable<EmptyMessage>;

    update(request: UpdateRequest): Observable<EmptyMessage>;

    setDisabled(request: DisableRequest): Observable<EmptyMessage>;

    updateRole(request: UpdateRoleRequest): Observable<EmptyMessage>;

    // </editor-fold>

  }

  export interface Company {
    id: number;
    externalId: string;
    type: CompanyType;
    name: string;
    disabled: boolean;
    comment?: string;
    postalAddress: Address.PostalAddressData;
    phoneNumber?: PhoneNumber;
    emailAddress?: EmailAddress;
    taxNumber?: string;
    euTaxNumber?: string;
    allowedRoleIds?: Set<number>;
    transporterCompanyIds?: Set<number>;
    locationIds?: Set<number>;
    lastEditorId?: number;
    creationTime?: OffsetDateTime;
    updateTime?: OffsetDateTime;
    updateCount?: number;
  }

  export interface QueryRequest {
    id?: Set<number>;
    externalId?: string;
    name?: string;
    queryText?: string;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
    disabled?: boolean;
    type?: Set<CompanyType>;
    transporterCompanyIds?: Set<number>;
    demanderCompanyIds?: Set<number>;
    noProgressBar?: boolean;
  }

  export interface GetRequest {
    id: number;
  }

  export interface CreateRequest {
    externalId?: string;
    name: string;
    type: CompanyType;
    comment?: string;
    postalAddress: Address.PostalAddressData;
    phoneNumber?: PhoneNumber;
    emailAddress?: EmailAddress;
    taxNumber?: string;
    euTaxNumber?: string;
    allowedRoleIds?: Set<number>;
    transporterCompanyIds?: Set<number>;
  }

  export interface UpdateRequest {
    id: number;
    externalId?: string;
    name: string;
    comment?: string;
    postalAddress: Address.PostalAddressData;
    phoneNumber?: PhoneNumber;
    emailAddress?: EmailAddress;
    taxNumber?: string;
    euTaxNumber?: string;
    transporterCompanyIds?: Set<number>;
  }

  export interface DisableRequest {
    id: number;
    disabled: boolean;
  }

  export interface UpdateRoleRequest {
    id: number;
    forceRemove: boolean;
    allowedRoleIds: Set<number>;
  }

  export type CompanyType = 'DEMANDER' | 'TRANSPORTER';

  export class CompanyTypeObject {
    type: CompanyType;
    stringKey: string;
  }

  export const companyTypes: CompanyTypeObject[] = [
    {type: 'DEMANDER', stringKey: 'COMPANY_TYPE_DEMANDER'},
    {type: 'TRANSPORTER', stringKey: 'COMPANY_TYPE_TRANSPORTER'},
  ];

  export interface HistoryRequest {
    id: number;
    withRead: boolean;
    queryText?: string;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
  }

  export interface HistoryItem extends HistoryLog.HistoryItemBase {
    type: CompanyHistoryType;
  }

  export type CompanyHistoryType =
    'READ' |
    'CREATE' |
    'UPDATE' |
    'DISABLE' |
    'ENABLE' |
    'ROLE_UPDATE';

  export class CompanyHistoryTypeObject {
    type: CompanyHistoryType;
    stringKey: string;
  }

  export const companyHistoryTypes: CompanyHistoryTypeObject[] = [
    {type: 'READ', stringKey: 'COMPANY_HISTORY_TYPE_READ'},
    {type: 'CREATE', stringKey: 'COMPANY_HISTORY_TYPE_CREATE'},
    {type: 'UPDATE', stringKey: 'COMPANY_HISTORY_TYPE_UPDATE'},
    {type: 'DISABLE', stringKey: 'COMPANY_HISTORY_TYPE_DISABLE'},
    {type: 'ENABLE', stringKey: 'COMPANY_HISTORY_TYPE_ENABLE'},
    {type: 'ROLE_UPDATE', stringKey: 'COMPANY_HISTORY_TYPE_ROLE_UPDATE'}
  ];

  export enum OrderField {
    ID,
    EXTERNAL_ID,
    CREATION_TIME,
    UPDATE_TIME,
    NAME,
    HISTORY_CREATION_TIME,
    HISTORY_ISSUER_USER_PERSON_NAME,
    HISTORY_MOBILE_APPLICATION_APPLICTAION_ID
  }

  export enum ValidatedField {
    UNKNOWN,

    EXTERNAL_ID,
    NAME,
    TYPE,
    TAX_NUMBER,
    EU_TAX_NUMBER,
    PHONE_NUMBER,
    POSTAL_ADDRESS,
    TRANSPORTER_COMPANY_IDS
  }

}


class Keys {

  private static readonly ID = 'id';
  private static readonly EXTERNAL_ID = 'external_id';
  private static readonly NAME = 'name';
  private static readonly POSTAL_ADDRESS = 'postal_address';
  private static readonly PHONE_NUMBER = 'phone_number';
  private static readonly TAX_NUMBER = 'tax_number';
  private static readonly EU_TAX_NUMBER = 'eu_vat_number';
  private static readonly DISABLED = 'disabled';
  private static readonly CREATION_TIME = 'creation_time';
  private static readonly UPDATE_TIME = 'update_time';
  private static readonly HISTORY_CREATION_TIME = 'creation_time';
  private static readonly HISTORY_ISSUER_USER_PERSON_NAME = 'issuer_user_person_name';
  private static readonly HISTORY_MOBILE_APPLICATION_APPLICTAION_ID = 'mobile_application_application_id';
  private static readonly TRANSPORTER_COMPANY_IDS = 'transporter_company_ids';

  private static readonly orderFieldKeyMap: Map<Company.OrderField, string> = Map.of(
    Company.OrderField.ID, Keys.ID,
    Company.OrderField.EXTERNAL_ID, Keys.EXTERNAL_ID,
    Company.OrderField.CREATION_TIME, Keys.CREATION_TIME,
    Company.OrderField.UPDATE_TIME, Keys.UPDATE_TIME,
    Company.OrderField.NAME, Keys.NAME,
    Company.OrderField.HISTORY_CREATION_TIME, Keys.HISTORY_CREATION_TIME,
    Company.OrderField.HISTORY_ISSUER_USER_PERSON_NAME, Keys.HISTORY_ISSUER_USER_PERSON_NAME,
    Company.OrderField.HISTORY_MOBILE_APPLICATION_APPLICTAION_ID, Keys.HISTORY_MOBILE_APPLICATION_APPLICTAION_ID,
  );

  private static readonly keyValidatedFieldMap: Map<string, Company.ValidatedField> = Map.of(
    Company.ValidatedField.EXTERNAL_ID, Keys.EXTERNAL_ID,
    Company.ValidatedField.NAME, Keys.NAME,
    Company.ValidatedField.TAX_NUMBER, Keys.TAX_NUMBER,
    Company.ValidatedField.EU_TAX_NUMBER, Keys.EU_TAX_NUMBER,
    Company.ValidatedField.POSTAL_ADDRESS, Keys.POSTAL_ADDRESS,
    Company.ValidatedField.PHONE_NUMBER, Keys.PHONE_NUMBER,
    Company.ValidatedField.TRANSPORTER_COMPANY_IDS, Keys.TRANSPORTER_COMPANY_IDS,
  );

  public static toOrderFieldKey(field: Company.OrderField): string {
    return Keys.orderFieldKeyMap.get(field)!;
  }

  public static toValidatedField(fieldKey: string): Company.ValidatedField {
    return Keys.keyValidatedFieldMap.get(fieldKey, Company.ValidatedField.UNKNOWN);
  }

}
