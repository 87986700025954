/* eslint-disable */
import { Component, OnInit, ViewChild } from '@angular/core';
import { InputMask } from '../../../../util/input-masks';
import { NgForm } from '@angular/forms';
import { FieldValidationError, OrderType, QueryResult } from '../../../../lib/util/services';
import { InvoiceBook, InvoiceBookService } from '../../../../lib/invoice/invoice-book/invoice-book.service';
import { UIRouter } from '@uirouter/angular';
import { StateName } from '../../../../app.state-names';
import { MultiselectOptionItem, UiConstants } from '../../../../util/core-utils';
import { TranslateService } from '@ngx-translate/core';
import { Angular2Multiselects } from '../../../../util/multiselect';
import { RightModel } from '../../../../app.rights';
import { RightResolver, RightService } from '../../../../lib/right.service';
import { BreadcrumbParent } from '../../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { Set } from 'immutable';
import { InvoiceSettings, InvoiceSettingsService } from '../../../../lib/invoice/invoice-settings/invoice-settings.service';
import InvoiceIntegrationType = InvoiceSettings.InvoiceIntegrationType;

/* eslint-enable */

@Component({
  selector: 'app-invoice-book-create',
  templateUrl: './invoice-book-create.component.html',
  styleUrls: ['./invoice-book-create.component.scss']
})
export class InvoiceBookCreateComponent implements OnInit {

  InputMask = InputMask;
  InvoiceBook = InvoiceBook;
  UiConstants = UiConstants;

  dropdownSettings: Angular2Multiselects.Settings;

  @ViewChild('f', { static: true })
  fForm: NgForm;

  model: InvoiceBookCreateModel;
  fieldErrors: FieldValidationError<InvoiceBook.ValidatedField>;

  invoiceSettings: MultiselectOptionItem<number>[] = [];

  rightModel: RightModel = RightModel.empty();
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  constructor(
    private invoiceBookService: InvoiceBookService,
    private uiRouter: UIRouter,
    private invoiceSettingsService: InvoiceSettingsService,
    private translateService: TranslateService,
    private rightService: RightService
  ) {
    this.model = new InvoiceBookCreateModel();
    this.fieldErrors = FieldValidationError.empty<InvoiceBook.ValidatedField>();
  }

  ngOnInit() {
    this.translateService.get('INVOICE_BOOK_CREATE_HEADING').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_INVOICE_BOOK').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.INVOICE_BOOK_LIST});
      }
    );
    this.initDropDown();
    this.loadRightModels();
    this.loadInvoiceSettings()
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  loadInvoiceSettings(q?: string) {
    this.invoiceSettingsService.query({
      profileName: q,
      invoiceIntegrationType: InvoiceIntegrationType.APPWORKS,
      invoiceDirectionType: 'OUTBOUND',
      disabled: false,
      orders: Set.of({field: InvoiceSettings.OrderField.PROFILE_NAME, type: OrderType.ASC}),
      paging: {
        numberOfItems: UiConstants.autocompletePageSize,
        pageNumber: 1
      },
      noProgressBar: true
    })
      .subscribe(
        (result: QueryResult<InvoiceSettings.InvoiceSettings>) => {
          this.invoiceSettings = result.items.toArray().map(s => ({id: s.id, itemName: s.profileName}));
        }
      );
  }

  private initDropDown() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .remoteSearch(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
  }

  create() {
    this.createInvoiceBook();
  }

  createInvoiceBook() {
    this.invoiceBookService.create({
      prefix: this.model.prefix,
      externalId: this.model.externalId,
      startNumber: this.model.startNumber,
      comment: this.model.comment,
      invoiceSettingsId: this.model.invoiceSettingsId
    }).subscribe(
      (response: InvoiceBook.InvoiceBook) => {
        this.uiRouter.stateService.go(StateName.INVOICE_BOOK_LIST);
      },
      (error: any) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withForm(this.fForm);
        }
      }
    );
  }

  hasFieldError(field: InvoiceBook.ValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: InvoiceBook.ValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: InvoiceBook.ValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  back() {
    window.history.back();
  }

}

export class InvoiceBookCreateModel {
  prefix: string;
  externalId: string;
  startNumber: number;
  comment: string;
  invoiceSettings: MultiselectOptionItem<number>[];

  get invoiceSettingsId(): number {
    return this.invoiceSettings[0]!.id;
  }
}

export class TaskItemForInvoiceBook {
  itemName: string;
  id: number;
}
