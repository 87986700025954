/* eslint-disable */
import { Observable, Observer } from 'rxjs';
import { environment } from '../../../environments/environment';
import { MemStorage } from '../util/mem-storage';
import { Injectable } from '@angular/core';
import { RuntimeConfiguration } from '../runtime-configuration';
import { LoggedInUserStorage } from '../util/storages';
/* eslint-enable */

@Injectable()
export class ColonnadeInsuranceSearchService {

  constructor() {
  }

  getSearchData(request: ColonnadeInsuranceSearch.SearchDataGetRequest): Observable<ColonnadeInsuranceSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<ColonnadeInsuranceSearch.SearchDataResult>) => {
      try {
        observer.next(storage.read(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  setSearchData(request: ColonnadeInsuranceSearch.SearchDataSetRequest): Observable<ColonnadeInsuranceSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<ColonnadeInsuranceSearch.SearchDataResult>) => {
      try {
        observer.next(storage.save(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  resetSearchData(request: ColonnadeInsuranceSearch.SearchDataGetRequest): Observable<ColonnadeInsuranceSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<ColonnadeInsuranceSearch.SearchDataResult>) => {
      try {
        observer.next(storage.reset(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  private createStorage(): ColonnadeInsuranceSearch.Storage {
    return new ColonnadeInsuranceSearch.Storage();
  }

}

export namespace ColonnadeInsuranceSearch {

  export interface SearchDataGetRequest {
  }

  export interface SearchDataSetRequest {
    searchData: SearchData;
  }

  export interface SearchDataResult {
    searchData: SearchData;
  }

  export interface SearchData {
    dqlText?: string;
  }

  interface StoredSearchRawDataBase {
    version: number;
  }

  interface StoredSearchRawData extends StoredSearchRawDataBase {
    dqlText?: string;
  }

  export class Storage {

    /**
     * Increment this version if you change the SearchData interface.
     */
    private static readonly VERSION: number = 1;

    private static readonly DEFAULT_SEARCH_DATA: SearchData = {
      dqlText: undefined
    };

    constructor() {
    }

    public read(request: SearchDataGetRequest): SearchDataResult {
      const key = this.generateKey();
      let rawBase: StoredSearchRawDataBase;
      try {
        const json = MemStorage.getItem(key);
        if (!json) {
          return this.createDefaultResult();
        }
        rawBase = JSON.parse(json);
      }
      catch (error) {
        return this.createDefaultResult();
      }
      if (Storage.VERSION === rawBase.version) {
        const raw: StoredSearchRawData = <StoredSearchRawData> rawBase;
        return this.fromRaw(raw);
      }
      return this.createDefaultResult();
    }

    public reset(request: SearchDataGetRequest): SearchDataResult {
      return this.save({
        searchData: Storage.DEFAULT_SEARCH_DATA,
      });
    }

    public save(request: SearchDataSetRequest): SearchDataResult {
      const key = this.generateKey();
      const raw = this.toRaw(request.searchData);
      const json = JSON.stringify(raw);
      MemStorage.setItem(key, json);
      return this.read(request);
    }

    private generateKey(): string {
      return 'UserId(' + LoggedInUserStorage.getInstance().getUserId() + ')-Search-Colonnade-Insurance';
    }

    private createDefaultResult(): SearchDataResult {
      return {
        searchData: Storage.DEFAULT_SEARCH_DATA,
      };
    }

    private fromRaw(data: StoredSearchRawData): SearchDataResult {
      return {
        searchData: {
          dqlText: data.dqlText,
        }
      };
    }

    private toRaw(data: SearchData): StoredSearchRawData {
      return {
        version: Storage.VERSION,
        dqlText: data.dqlText,
      }
        ;
    }

  }
}
