import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RightModel } from '../../../../app.rights';
import { ExteriorTransportBaseDataModel } from './exterior-transport-base-data.model';

@Component({
  selector: 'app-exterior-transport-base-data',
  templateUrl: './exterior-transport-base-data.component.html',
  styleUrls: ['./exterior-transport-base-data.component.scss']
})
export class ExteriorTransportBaseDataComponent implements OnInit {

  @Input()
  baseData: ExteriorTransportBaseDataModel = new ExteriorTransportBaseDataModel();

  @Output() openBaseDataDetailDialog: EventEmitter<any> = new EventEmitter();

  rightModel: RightModel = RightModel.empty();

  constructor() { }

  ngOnInit() {
  }

  onOpenBaseDataDetailButtonClicked() {
    this.openBaseDataDetailDialog.emit();
  }
}

