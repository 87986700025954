<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-vertical-padding animated fadeIn">
  <div class="col-xs-12 col-sm-12 col-md-8">
    <div class="card">

      <div class="card-header">
        {{'NOTIFICATION_PANEL_HEADING_CREATE'|translate}}
      </div>

      <div class="card-body">

        <form class="form-horizontal bordered-row" (ngSubmit)="create()" #f="ngForm" novalidate>

          <!--SEND-ALL-SWITCH-->
          <ng-container *ngIf="showSendAllSwitch">

            <div class="form-group row">
              <label class="col-form-label form-control-label col-md-4 detail-title"> {{ 'NOTIFICATION_LABEL_SEND_ALL'|translate }} </label>
              <div class="col-md-8 col-form-label">
                <label class="switch switch-text switch-info mb-0">
                  <input
                    type="checkbox"
                    class="switch-input"
                    [(ngModel)]="model.sendToAll"
                    (change)="sendToAllSwitchChange($event)"
                    #sendToAll="ngModel"
                    id="sendToAll"
                    name="sendToAll">
                  <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                  <span class="switch-handle"></span>
                </label>
              </div>
            </div>
          </ng-container><!--./SEND-ALL-SWITCH-->

          <!--RECIPIENT-FILTERS-->
          <ng-container *ngIf="showRecipientFilter">

            <!--USER-->
            <div class="form-group row" [ngClass]="{ 'has-danger': (fieldErrors.recipient) }">
              <label class="col-form-label form-control-label col-md-4 detail-title">{{'NOTIFICATION_LABEL_USER'|translate}}</label>
              <div class="col-md-8">
                <angular2-multiselect [data]="userList" [settings]="userDropdownSettings"
                                      class="form-control"
                                      [(ngModel)]="selectedUsers"
                                      (onSelect)="removeFieldError(fieldErrors.recipient)"
                                      [ngModelOptions]="{standalone: true}"
                                      (onRemoteSearch)="loadUsers($event.target.value)"
                                      name="user"
                                      id="user">
                </angular2-multiselect>
                <div class="form-control-feedback" *ngIf="fieldErrors.recipient">{{'NOTIFICATION_CREATE_ERROR_RECIPIENT'|translate}}</div>
              </div>
            </div>

            <!--GROUP-->
            <div class="form-group row" [ngClass]="{ 'has-danger': (fieldErrors.recipient) }">
              <label class="col-form-label form-control-label col-md-4 detail-title">{{'NOTIFICATION_LABEL_GROUP'|translate}}</label>
              <div class="col-md-8">
                <angular2-multiselect [data]="userGroupList" [settings]="userGroupDropdownSettings"
                                      class="form-control"
                                      [(ngModel)]="selectedUserGroups"
                                      (onSelect)="removeFieldError(fieldErrors.recipient)"
                                      [ngModelOptions]="{standalone: true}"
                                      (onRemoteSearch)="loadUserGroups($event.target.value)"
                                      name="userGroup"
                                      id="userGroup">
                </angular2-multiselect>
                <div class="form-control-feedback" *ngIf="fieldErrors.recipient">{{'NOTIFICATION_CREATE_ERROR_RECIPIENT'|translate}}</div>
              </div>
            </div>

            <!--DEVICE-->
            <div class="form-group row" [ngClass]="{ 'has-danger': (fieldErrors.recipient) }">
              <label class="col-form-label form-control-label col-md-4 detail-title">{{'NOTIFICATION_LABEL_DEVICE' | translate}}</label>
              <div class="col-md-8">
                <angular2-multiselect [data]="deviceList" [settings]="deviceDropdownSettings"
                                      class="form-control"
                                      [(ngModel)]="selectedDevices"
                                      (onSelect)="removeFieldError(fieldErrors.recipient)"
                                      [ngModelOptions]="{standalone: true}"
                                      (onRemoteSearch)="loadDevices($event.target.value)"
                                      name="devices"
                                      id="devices">
                </angular2-multiselect>
                <div class="form-control-feedback" *ngIf="fieldErrors.recipient">{{'NOTIFICATION_CREATE_ERROR_RECIPIENT'|translate}}</div>
              </div>
            </div>

          </ng-container><!--./RECIPIENT-FILTERS-->

          <!--MESSAGE-DATA-->
          <ng-container>
            <!--TITLE-->
            <div class="form-group row" [ngClass]="{ 'has-danger': (fieldErrors.title) || (f.submitted && !title.valid) }">
              <label class="col-form-label form-control-label col-md-4 detail-title"> {{'NOTIFICATION_LABEL_TITLE' | translate}} </label>
              <div class="col-md-8">
                <input
                  type="text"
                  class="form-control"
                  maxlength="{{UiConstants.maximumVarcharLength}}"
                  [(ngModel)]="model.title"
                  [ngClass]="{ 'form-control-danger': (fieldErrors.title) || (f.submitted && !title.valid) }"
                  (ngModelChange)="removeFieldError(fieldErrors.title)"
                  placeholder="{{'NOTIFICATION_LABEL_TITLE' | translate}}"
                  name="title"
                  id="title"
                  #title="ngModel"
                  required >

                <div class="form-control-feedback" *ngIf="fieldErrors.title">{{fieldErrors.title.text}}</div>
                <div class="form-control-feedback" *ngIf="f.submitted && !title.valid">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
              </div>
            </div>

            <!--MESSAGE-->
            <div class="form-group row" [ngClass]="{ 'has-danger': (fieldErrors.message) || (f.submitted && !message.valid) }">
              <label class="col-form-label form-control-label col-md-4 detail-title">{{'NOTIFICATION_LABEL_MESSAGE'|translate}}</label>
              <div class="col-md-8">
                <textarea
                  id="message"
                  name="message"
                  rows="5"
                  class="form-control"
                  [(ngModel)]="model.content"
                  (ngModelChange)="removeFieldError(fieldErrors.message)"
                  [ngClass]="{ 'form-control-danger': (fieldErrors.message) || (f.submitted && !message.valid) }"
                  placeholder="{{'NOTIFICATION_LABEL_MESSAGE'|translate}}"
                  #message="ngModel"
                  required >
                </textarea>

                <div class="form-control-feedback" *ngIf="fieldErrors.message">{{fieldErrors.message.text}}</div>
                <div class="form-control-feedback" *ngIf="f.submitted && !message.valid">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
              </div>
            </div>
          </ng-container><!--./MESSAGE-DATA-->

          <input type="submit" class="btn btn-primary pull-right" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>

        </form>
      </div> <!--card-body-->

    </div>
  </div>
</div>
