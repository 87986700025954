import { Injectable } from '@angular/core';
import { User, UserService } from '../user.service';
import { AuthResult, AuthService } from '../auth.service';
import { concatMap, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Cacheable } from 'ts-cacheable';
import { UiConstants } from '../../util/core-utils';

@Injectable()
export class UserMeService {

  constructor(private userService: UserService,
              private authService: AuthService) {
  }

  @Cacheable({
    maxAge: UiConstants.defaultCacheMaxAge,
    slidingExpiration: true,
  })
  public loadMe(): Observable<User> {
    return this.authService.check({})
      .pipe(concatMap((result: AuthResult) => {
        if (!result.user_profile) {
          throwError('No user');
        }
        return this.userService.get({id: result.user_profile!.id});
      }));
  }

  public amIProtected(): Observable<boolean> {
    return this.loadMe().pipe(map(u => u.protected_user));
  }

}
