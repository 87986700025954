/* eslint-disable */
import { Dates, LocalDate, } from '../../util/dates';
import { Observable, Observer } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Order, OrderType, Services, } from '../../util/services';
import { MemStorage } from '../../util/mem-storage';
import { Injectable } from '@angular/core';
import { MultiselectOptionItem, OptionItem, UiConstants } from '../../../util/core-utils';
import { AuditLogList } from './audit-log.service';
import { ApplicationIdTypeItem, AuditLogCallTypeItem, AuditLogDeviceTypeItem, AuditLogResultItem } from '../../../util/audit-log-utils';
import { RuntimeConfiguration } from '../../runtime-configuration';
import { LoggedInUserStorage } from '../../util/storages';
import { AssigneeItem } from '../../../util/task-record-utils';
/* eslint-enable */

@Injectable()
export class AuditLogSearchService {

  constructor() {
  }

  getSearchData(request: AuditLogSearch.SearchDataGetRequest): Observable<AuditLogSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<AuditLogSearch.SearchDataResult>) => {
      try {
        observer.next(storage.read(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  setSearchData(request: AuditLogSearch.SearchDataSetRequest): Observable<AuditLogSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<AuditLogSearch.SearchDataResult>) => {
      try {
        observer.next(storage.save(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  resetSearchData(request: AuditLogSearch.SearchDataGetRequest): Observable<AuditLogSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<AuditLogSearch.SearchDataResult>) => {
      try {
        observer.next(storage.reset(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  private createStorage(): AuditLogSearch.Storage {
    return new AuditLogSearch.Storage();
  }

}

export namespace AuditLogSearch {

  export interface SearchDataGetRequest {
  }

  export interface SearchDataSetRequest {
    searchData: SearchData;
  }

  export interface SearchDataResult {
    searchData: SearchData;
  }

  export interface SearchData {
    itemsPerPage: number;
    pageNumber: number;
    order: Order<AuditLogList.OrderField>;
    user_id?: MultiselectOptionItem<number>[];
    exclude_health_check: boolean;
    exclude_su: boolean;
    q: string;
    application_id?: ApplicationIdTypeItem;
    request_method: string;
    request_uri: string;
    result?: AuditLogResultItem;
    callType?: AuditLogCallTypeItem;
    request_device_type?: AuditLogDeviceTypeItem;
    creation_time: LocalDate;
  }

  interface StoredSearchRawDataBase {
    version: number;
  }

  interface StoredSearchRawData extends StoredSearchRawDataBase {
    itemsPerPage: number;
    pageNumber: number;
    order: Order<AuditLogList.OrderField>;
    user_id?: MultiselectOptionItem<number>[];
    exclude_health_check: boolean;
    exclude_su: boolean;
    q: string;
    application_id?: ApplicationIdTypeItem;
    request_method: string;
    request_uri: string;
    result?: AuditLogResultItem;
    callType?: AuditLogCallTypeItem;
    request_device_type?: AuditLogDeviceTypeItem;
    creation_time?: string;
  }

  export class Storage {

    /**
     * Increment this version if you change the SearchData interface.
     */
    private static readonly VERSION: number = 4;

    private static readonly DEFAULT_SEARCH_DATA: SearchData = {
      itemsPerPage: UiConstants.pageNumbers[0],
      pageNumber: 1,
      order: {
        field: AuditLogList.OrderField.ID,
        type: OrderType.DESC
      },
      user_id: undefined,
      exclude_health_check: false,
      exclude_su: false,
      q: '',
      application_id: undefined,
      request_method: '',
      request_uri: '',
      result: undefined,
      callType: undefined,
      request_device_type: undefined,
      creation_time: Dates.emptyLocalDate()
    };

    constructor() {
    }

    public read(request: SearchDataGetRequest): SearchDataResult {
      let rawBase: StoredSearchRawDataBase;
      try {
        const key = this.generateKey();
        const json = MemStorage.getItem(key);
        if (!json) {
          return this.createDefaultResult();
        }
        rawBase = JSON.parse(json);
      }
      catch (error) {
        return this.createDefaultResult();
      }
      if (Storage.VERSION === rawBase.version) {
        const raw: StoredSearchRawData = <StoredSearchRawData> rawBase;
        return this.fromRaw(raw);
      }
      return this.createDefaultResult();
    }

    public reset(request: SearchDataGetRequest): SearchDataResult {
      return this.save({
        searchData: Storage.DEFAULT_SEARCH_DATA,
      });
    }

    public save(request: SearchDataSetRequest): SearchDataResult {
      const key = this.generateKey();
      const raw = this.toRaw(request.searchData);
      const json = JSON.stringify(raw);
      MemStorage.setItem(key, json);
      return this.read(request);
    }

    private generateKey(): string {
      return 'UserId(' + LoggedInUserStorage.getInstance().getUserId() + ')-Search-Audit-Log';
    }

    private createDefaultResult(): SearchDataResult {
      return {
        searchData: Storage.DEFAULT_SEARCH_DATA,
      };
    }

    private fromRaw(data: StoredSearchRawData): SearchDataResult {
      return {
        searchData: {
          itemsPerPage: data.itemsPerPage,
          pageNumber: data.pageNumber,
          order: data.order,
          user_id: data.user_id,
          exclude_health_check: data.exclude_health_check,
          exclude_su: data.exclude_su,
          q: data.q,
          application_id: data.application_id,
          request_method: data.request_method,
          request_uri: data.request_uri,
          result: data.result,
          callType: data.callType,
          request_device_type: data.request_device_type,
          creation_time: Services.toLocalDate(data.creation_time)
        }
      };
    }

    private toRaw(data: SearchData): StoredSearchRawData {
      return {
        version: Storage.VERSION,
        itemsPerPage: data.itemsPerPage,
        pageNumber: data.pageNumber,
        order: data.order,
        user_id: data.user_id,
        exclude_health_check: data.exclude_health_check,
        exclude_su: data.exclude_su,
        q: data.q,
        application_id: data.application_id,
        request_method: data.request_method,
        request_uri: data.request_uri,
        result: data.result,
        callType: data.callType,
        request_device_type: data.request_device_type,
        creation_time: Services.localDateToString(data.creation_time),
      };
    }

  }

}
