/* eslint-disable */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LegacyProcess, LegacyProcessService } from '../../../lib/legacy-process/legacy-process.service';
import { Models } from '../../../util/model-utils';
import { OptionItem, QueryFieldModel, SelectUtils, UiConstants } from '../../../util/core-utils';
import {
  FieldValidationError,
  ForwardingNgFormRef,
  LocalFormGroupValidationErrors,
  OrderType,
  QueryResult
} from '../../../lib/util/services';
import { RightModel } from '../../../app.rights';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../lib/auth.service';
import { RightResolver, RightService } from '../../../lib/right.service';
import { combineLatest, Observable } from 'rxjs';
import { StringKey } from '../../../app.string-keys';
import { Set } from 'immutable';
import { UIRouter } from '@uirouter/angular';
import { Strings } from '../../../lib/util/strings';
import { LegacyProcessSearch, LegacyProcessSearchService } from '../../../lib/legacy-process/legacy-process-search.service';
import { LegacyProcessSearchModel, ProcessStateItem } from '../../../util/legacy-process-utils';
import { LegacyWorkflow, LegacyWorkflowService } from '../../../lib/legacy-workflow/legacy-workflow-service';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { StateName } from '../../../app.state-names';

/* eslint-enable */

@Component({
  selector: 'app-legacy-process-list',
  templateUrl: './legacy-process-list.component.html',
  styleUrls: ['./legacy-process-list.component.scss']
})
export class LegacyProcessListComponent implements OnInit, OnDestroy {
  LegacyProcess = LegacyProcess;
  SelectUtils = SelectUtils;
  UiConstants = UiConstants;

  @ViewChild('processCreateDialog', { static: true }) processCreateDialog: ModalDirective;
  @ViewChild('f') form?: NgForm;
  processCreateDialogVisible: boolean = false;

  processCreateModel: LegacyProcessCreateModel = new LegacyProcessCreateModel();
  queryModel: QueryFieldModel<LegacyProcess.OrderField> = new QueryFieldModel(LegacyProcess.OrderField.PROCESS_ID, OrderType.DESC);
  processList: LegacyProcess.LegacyProcess[] = [];
  searchModel: LegacyProcessSearchModel = new LegacyProcessSearchModel();
  storedSearchData: LegacyProcessSearch.SearchDataResult;
  processStates: ProcessStateItem[] = [];
  workflowItems: LegacyWorkflowItem[] = [];
  showSearch: boolean = false;
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  rightModel: RightModel = RightModel.empty();

  formGroup: FormGroup;
  private submitted: boolean = false;
  private fieldErrors: FieldValidationError<LegacyProcess.ValidatedField> =
    FieldValidationError.empty<LegacyProcess.ValidatedField>();
  private formGroupValidationErrors: LocalFormGroupValidationErrors;

  constructor(
    private translateService: TranslateService,
    private authService: AuthService,
    private processService: LegacyProcessService,
    private processSearchService: LegacyProcessSearchService,
    private legacyWorkflowService: LegacyWorkflowService,
    private uiRouter: UIRouter,
    private rightService: RightService,
    fb: FormBuilder) {
    this.formGroup = this.createFormGroup(fb);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(this.createForwardingHtmlForm(), this.formGroup);
  }

  ngOnInit() {
    this.translateService.get('MENU_NAVBAR_LEGACY_PROCESSES').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.loadSearch(() => {
      this.showSearch = !this.searchModel.isEmpty();
      this.loadList();
    });
    this.loadWorkflowNames();
    this.loadRightModels();
  }

  private loadSearch(completion: () => void) {
    const obs: Observable<SearchLoadResult> = combineLatest(
      this.processSearchService.getSearchData({}),
      (storedSearchData: LegacyProcessSearch.SearchDataResult) => {
        const result: SearchLoadResult = {
          storedSearchData: storedSearchData
        };
        return result;
      }
    );
    obs.subscribe(
      (result: SearchLoadResult) => {
        this.storedSearchData = result.storedSearchData;
        this.loadProcessStatesForSearch(result.storedSearchData);
        this.postInitSearch(result.storedSearchData);
        completion();
      }
    );
  }

  private postInitSearch(storedSearchData: LegacyProcessSearch.SearchDataResult) {
    this.queryModel.itemsPerPage = storedSearchData.searchData.itemsPerPage;
    this.queryModel.currentPage = storedSearchData.searchData.pageNumber;
    this.queryModel.setOrder(storedSearchData.searchData.order);
    this.searchModel.processId = storedSearchData.searchData.processId;
    this.searchModel.workflowName = storedSearchData.searchData.workflowName;
    this.searchModel.name = storedSearchData.searchData.name;
    this.searchModel.deadlineFrom = Models.localDateToNgbDate(storedSearchData.searchData.deadlineFrom);
    this.searchModel.deadlineTo = Models.localDateToNgbDate(storedSearchData.searchData.deadlineTo);
    this.searchModel.externalId = storedSearchData.searchData.externalId;
    this.searchModel.numberOfOrdersFrom = storedSearchData.searchData.numberOfOrdersFrom;
    this.searchModel.numberOfOrdersTo = storedSearchData.searchData.numberOfOrdersTo;
    this.searchModel.creationTimeFrom = Models.localDateToNgbDate(storedSearchData.searchData.creationTimeFrom);
    this.searchModel.creationTimeTo = Models.localDateToNgbDate(storedSearchData.searchData.creationTimeTo);
  }

  private loadProcessStatesForSearch(storedSearchData: LegacyProcessSearch.SearchDataResult) {
    const storedId = storedSearchData.searchData.state;
    const def = this.loadDefaultItem();
    this.processStates = [];
    this.processStates.push(def);
    this.searchModel.state = def;
    LegacyProcess.processStates.forEach((state) => {
      const item = {
        id: state.state,
        text: '...'
      };
      this.processStates.push(item);
      this.translateService.get(state.stringKey).subscribe((text: string) => {
        item.text = text;
      });
    });
    if (storedId) {
      this.processStates.forEach((item) => {
        if (item.id === storedId) {
          this.searchModel.state = item;
        }
      });
    }
  }

  loadDefaultItem() {
    return {id: null, text: this.translateService.instant(StringKey.COMMON_VALUE_UNSELECTED)};
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  loadWorkflowNames() {
    this.workflowItems = [];
    this.legacyWorkflowService.query({
      disabled: false
    }).subscribe((workflows: QueryResult<LegacyWorkflow.Workflow>) => {
      workflows.items.toArray().forEach((workflow) => {
        const item = {
          id: workflow.workflowId,
          name: workflow.name
        };
        this.workflowItems.push(item);
      });
    });
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: LegacyProcess.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }


  private loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();
    this.processService.query({
      processId: Strings.undefinedOrNonEmpty(this.searchModel.processId),
      state: this.searchModel.state && this.searchModel.state.id ? this.searchModel.state.id : undefined,
      workflowName: Strings.undefinedOrNonEmpty(this.searchModel.workflowName),
      name: Strings.undefinedOrNonEmpty(this.searchModel.name),
      deadlineFrom: Models.parseDateTimeFrom(this.searchModel.deadlineFrom),
      deadlineTo: Models.parseDateTimeTo(this.searchModel.deadlineTo),
      externalId: Strings.undefinedOrNonEmpty(this.searchModel.externalId),
      numberOfOrdersFrom: Strings.undefinedOrNonEmpty(this.searchModel.numberOfOrdersFrom),
      numberOfOrdersTo: Strings.undefinedOrNonEmpty(this.searchModel.numberOfOrdersTo),
      creationTimeFrom: Models.parseDateTimeFrom(this.searchModel.creationTimeFrom),
      creationTimeTo: Models.parseDateTimeTo(this.searchModel.creationTimeTo),
      withOrderCount: true,
      orders: Set.of(order),
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined
    }).subscribe(
      (result: QueryResult<LegacyProcess.LegacyProcess>) => {
        this.processList = result.items.toArray();
        this.queryModel.currentPage = requestedPage;
        this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
        this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
      });
  }

  onSearchClicked() {
    this.loadList();
  }

  onSearchReset() {
    this.processSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.showSearch = true;
          this.loadList(1);
        });
      }
    );
  }

  private saveSearch() {
    const request = {
      searchData: {
        itemsPerPage: this.queryModel.itemsPerPage,
        pageNumber: this.queryModel.currentPage,
        order: this.queryModel.getOrder(),
        processId: this.searchModel.processId,
        state: OptionItem.idOrUndefined(this.searchModel.state),
        workflowName: this.searchModel.workflowName,
        name: this.searchModel.name,
        deadlineFrom: Models.ngbDateToLocalDate(this.searchModel.deadlineFrom),
        deadlineTo: Models.ngbDateToLocalDate(this.searchModel.deadlineTo),
        externalId: this.searchModel.externalId,
        numberOfOrdersFrom: this.searchModel.numberOfOrdersFrom,
        numberOfOrdersTo: this.searchModel.numberOfOrdersTo,
        creationTimeFrom: Models.ngbDateToLocalDate(this.searchModel.creationTimeFrom),
        creationTimeTo: Models.ngbDateToLocalDate(this.searchModel.creationTimeTo)
      }
    };
    this.processSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  getProcessStateName(process: LegacyProcess.LegacyProcess): string {
    const filtered_states = this.processStates.filter(current_state => current_state.id === process.state);
    if (filtered_states === undefined || filtered_states.length === 0) {
      return '';
    }
    return filtered_states[0].text;
  }

  getProcessOrderCount(process: LegacyProcess.LegacyProcess): string {
    const orderCount = process.orderCount;
    if (orderCount === undefined) {
      return '';
    }
    return '' + orderCount;
  }

  getWorkflowName(process: LegacyProcess.LegacyProcess): string {
    const filtered_workflows = this.workflowItems.filter(current_workflow => current_workflow.id === process.workflowId);
    if (filtered_workflows === undefined || filtered_workflows.length === 0) {
      return '';
    }
    return filtered_workflows[0].name;
  }

  createProcess() {
    this.submitted = true;
    this.formGroup.updateValueAndValidity();
    if (this.formGroup.invalid) {
      return;
    }
    this.processService.create({
      workflowId: this.processCreateModel.workflowId!,
      usageType: this.processCreateModel.usageType,
      orderIds: [],
      name: Strings.undefinedOrNonEmpty(this.processCreateModel.name),
      externalId: Strings.undefinedOrNonEmpty(this.processCreateModel.externalId),
      description: Strings.undefinedOrNonEmpty(this.processCreateModel.description),
      deadline: Models.parseDateTimeFrom(this.processCreateModel.deadline)
    }).subscribe((response) => {
        this.uiRouter.stateService.go(StateName.LEGACY_PROCESS_EDIT, {id: response.processId});
      },
      (error: any) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withForm(this.form!);
        }
      });
  }

  openProcessCreateDialog() {
    this.processCreateDialogVisible = true;
    this.processCreateDialog.show();
  }

  closeProcessCreateDialog() {
    this.submitted = false;
    this.formGroup.reset();
    this.processCreateModel.reset();
    this.processCreateDialogVisible = false;
    this.processCreateDialog.hide();
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  hasFieldError(field?: LegacyProcess.ValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: LegacyProcess.ValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: LegacyProcess.ValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  private createFormGroup(fb: FormBuilder): FormGroup {
    return fb.group(
      {
        workflowId: fb.control(
          {value: this.processCreateModel.workflowId},
          [
            Validators.required,
          ]
        ),
        name: fb.control(
          {value: this.processCreateModel.name},
          []
        ),
        externalId: fb.control(
          {value: this.processCreateModel.externalId},
          []
        ),
      }
    );
  }

  private createForwardingHtmlForm() {
    return new ForwardingNgFormRef({
      formFn: () => {
        return this.form;
      }
    });
  }

  ngOnDestroy() {
    this.saveSearch();
  }

}

interface LegacyWorkflowItem {
  id: number;
  name: string;
}

interface SearchLoadResult {
  storedSearchData: LegacyProcessSearch.SearchDataResult,
}

class LegacyProcessCreateModel {
  workflowId?: number;
  usageType: string = 'ORDER';
  name: string = '';
  externalId?: string = '';
  description?: string = '';
  deadline: NgbDateStruct | null = null;

  reset() {
    this.workflowId = undefined;
    this.usageType = 'ORDER';
    this.name = '';
    this.externalId = '';
    this.description = '';
    this.deadline = null;
  }
}
