<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [self]="breadcrumbSelf"
      [parents]="breadcrumbParents">
    </app-breadcrumb>
  </div>
  <div class="breadcrumb-menu d-flex">
    <!--XLS IMPORT-->
    <div
      *ngIf="rightModel.parcelCollectionPointImport.hasRight()"
      class="breadcrumb-button-icon-container cursor-pointer"
      (click)="importDialog.showDialog()">
      <a class="btn-setting d-flex align-items-center" title="{{'SHIPPING_DEMAND_XLSX_IMPORT' | translate}}">
        <i class="icomoon icomoon-file-xlsx"></i>
        <span class="breadcrumb-button-text-visible">{{'SHIPPING_DEMAND_XLSX_IMPORT' | translate}}</span>
      </a>
    </div>
    <!--DROPDOWN FOR XLS IMPORT-->
    <div class="breadcrumb-button-icon-container-no-text cursor-pointer p-0"
         *ngIf="rightModel.parcelCollectionPointImport.hasRight()">
      <a class="dropdown dropdown-arrow-padding"
         dropdown title="{{'COMMON_EXPORT_XLSX' | translate}}"
         href="#" onclick="return false" dropdownToggle
         data-toggle="dropdown" role="button"
         aria-haspopup="true" aria-expanded="false">
        <i class="icomoon icomoon-dropdown">
        </i>
        <div class="dropdown-menu dropdown-menu-right"
             *dropdownMenu
             aria-labelledby="simple-dropdown">
          <span (click)="exportXlsTemplate()"
                class="btn-setting dropdown-item d-flex align-items-center"
                title="{{'ORDER_XLS_IMPORT_SAMPLE_DOWNLOAD' | translate}}">
            <i class="icomoon icomoon-file-xlsx"></i>
            <span>{{'ORDER_XLS_IMPORT_SAMPLE_DOWNLOAD' | translate}}</span>
          </span>
        </div>
      </a>
    </div>
    <!--CREATE-->
    <div
      *ngIf="rightModel.parcelCollectionPointCreate.hasRight()"
      class="breadcrumb-button-icon-container cursor-pointer negative-margin-right"
      uiSref="Admin.ParcelCollectionPointCreate">
      <a class="btn-setting d-flex align-items-center" title="{{'COMMON_ADD' | translate}}">
        <i class="icomoon icomoon-add"></i>
        <span class="breadcrumb-button-text-visible">{{'COMMON_ADD' | translate}}</span>
      </a>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="card">

    <div class="card-header">
      {{'MENU_NAVBAR_PARCEL_COLLECTION_POINTS' | translate}}

      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()" title="{{'COMMON_BUTTON_SEARCH' | translate}}"><i
          class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>

    <div class="card-body border-bottom" *ngIf="showSearch">
      <div class="row form-group">

        <div class="col-md-3">
          <label class="search-label">{{'COMMON_NAME' | translate}}</label>
          <input type="text" class="form-control"
                 placeholder="{{'COMMON_NAME' | translate}}"
                 maxlength="{{UiConstants.maxInputLongLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.name">
        </div>

        <div class="col-md-3">
          <label class="search-label">{{'PARCEL_COLLECTION_POINT_CODE' | translate}}</label>
          <input type="text" class="form-control"
                 placeholder="{{'PARCEL_COLLECTION_POINT_CODE' | translate}}"
                 maxlength="{{UiConstants.maxInputLongLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.code">
        </div>

        <div class="col-md-3">
          <label class="search-label">{{'COMMON_TABLE_HEADER_CITY' | translate}}</label>
          <input type="text" class="form-control"
                 placeholder="{{'COMMON_TABLE_HEADER_CITY' | translate}}"
                 maxlength="{{UiConstants.maxInputLongLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.city">
        </div>

        <div class="col-md-3">
          <label class="search-label">{{'DELIVERY_METHOD' | translate}}</label>
          <angular2-multiselect class="form-control"
                                [data]="deliveryMethods"
                                [(ngModel)]="searchModel.deliveryMethod"
                                (onRemoteSearch)="onDeliveryMethodSearch($event.target.value)"
                                [settings]="dropdownSettings">
          </angular2-multiselect>
        </div>

        <div class="col-md-6 d-flex align-items-end">
          <div class="row">
            <app-animated-checkbox class="search-checkbox"
                                   [(selected)]="searchModel.coordinatesMissing">
            </app-animated-checkbox>
            <label class="search-label">{{'PARCEL_COLLECTION_POINT_COORDINATES_MISSING' | translate}}</label>
          </div>
      </div>
      <div class="col-md-12 d-flex justify-content-md-end align-items-end">
        <div class="btn-group" role="group">
          <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
            {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
          </button>
          <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
            {{'COMMON_BUTTON_SEARCH' | translate}}
          </button>
        </div>
      </div>
    </div><!-- /.card-body-->
    </div>
    <div class="card-body">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="ParcelCollectionPointSearch.OrderFunctions.ID"
                                    [orderType]="queryModel.getOrderType(ParcelCollectionPointSearch.OrderFunctions.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="ParcelCollectionPointSearch.OrderFunctions.NAME"
                                    [orderType]="queryModel.getOrderType(ParcelCollectionPointSearch.OrderFunctions.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-field-sorter [orderField]="ParcelCollectionPointSearch.OrderFunctions.CODE"
                                    [orderType]="queryModel.getOrderType(ParcelCollectionPointSearch.OrderFunctions.CODE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'PARCEL_COLLECTION_POINT_CODE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-sorter-no-op
              [text]="'COMMON_ADDRESS' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op
              [text]="'DELIVERY_METHOD' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-field-sorter [orderField]="ParcelCollectionPointSearch.OrderFunctions.UPDATE_TIME"
                                    [orderType]="queryModel.getOrderType(ParcelCollectionPointSearch.OrderFunctions.UPDATE_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_UPDATE_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-table-actions">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let parcelCollectionPoint of parcelCollectionPointList | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }" [disabledTableRow]="parcelCollectionPoint.disabled">
          <td class="responsive-table-column">{{parcelCollectionPoint.id}}</td>
          <td class="responsive-table-column">{{parcelCollectionPoint.name}}</td>
          <td class="responsive-table-column hidden-sm-down">{{parcelCollectionPoint.code}}</td>
          <td class="responsive-table-column hidden-md-down long">{{parcelCollectionPoint.postalAddress}}</td>
          <td class="responsive-table-column hidden-sm-down">{{parcelCollectionPoint.deliveryMethodName}}</td>
          <td class="responsive-table-column hidden-md-down">{{parcelCollectionPoint.updateTime.toUtcIsoString() | date: 'short'}}</td>
          <td class="responsive-table-column">
            <a class="icons-table-group" href="#" onclick="return false" (click)="setDisabled($event, parcelCollectionPoint.id, true)"
               *ngIf="!parcelCollectionPoint.disabled && rightModel.parcelCollectionPointDisable.hasRight()">
              <i class="icomoon icons-table-item icomoon-disable icomoon-disable-table"
                 title="{{'COMMON_BUTTON_DISABLE' | translate}}"></i>
            </a>
            <a class="icons-table-group" href="#" onclick="return false" (click)="setDisabled($event, parcelCollectionPoint.id, false)"
               *ngIf="parcelCollectionPoint.disabled && rightModel.parcelCollectionPointDisable.hasRight()">
              <i class="icomoon icons-table-item icomoon-enable icomoon-enable-table"
                 title="{{'COMMON_BUTTON_ENABLE' | translate}}"></i>
            </a>
            <a class="icons-table-group" uiSref="Admin.ParcelCollectionPointDetail"
               [uiParams]="{ id: parcelCollectionPoint.id }">
              <i class="icomoon icons-table-item icomoon-detail icomoon-detail-table"
                 title="{{'COMMON_BUTTON_DETAILS' | translate}}"></i>
            </a>
            <a class="icons-table-group" uiSref="Admin.ParcelCollectionPointEdit"
               [uiParams]="{ id: parcelCollectionPoint.id }"
               *ngIf="rightModel.stockItemUpdate.hasRight()">
              <i class="icomoon icons-table-item icomoon-modify icomoon-modify-table"
                 title="{{'COMMON_BUTTON_EDIT' | translate}}"></i>
            </a>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="ParcelCollectionPointSearch.OrderFunctions.ID"
                                    [orderType]="queryModel.getOrderType(ParcelCollectionPointSearch.OrderFunctions.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="ParcelCollectionPointSearch.OrderFunctions.NAME"
                                    [orderType]="queryModel.getOrderType(ParcelCollectionPointSearch.OrderFunctions.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-field-sorter [orderField]="ParcelCollectionPointSearch.OrderFunctions.CODE"
                                    [orderType]="queryModel.getOrderType(ParcelCollectionPointSearch.OrderFunctions.CODE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'PARCEL_COLLECTION_POINT_CODE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-sorter-no-op
              [text]="'COMMON_ADDRESS' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op
              [text]="'DELIVERY_METHOD' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-field-sorter [orderField]="ParcelCollectionPointSearch.OrderFunctions.UPDATE_TIME"
                                    [orderType]="queryModel.getOrderType(ParcelCollectionPointSearch.OrderFunctions.UPDATE_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_UPDATE_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-table-actions">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>
  </div>
</div>


<app-file-upload-dialog
  [uploadPath]="uploadPath"
  (onResult)="onImportSuccess($event)"
  #importDialog
></app-file-upload-dialog>
