<div class="form-group mb-0" [ngClass]="{'border-bottom': nonEditable}">
  <label class="form-control-label text-break">{{ model.title }}</label>
</div>
<div class="row">
  <div *ngIf="!nonEditable" class="form-group col-md-6">
    <app-file-upload *ngIf="model.multiSelect || (fileQueue.length === 0 && model.values.length === 0)"
      #fileUploadComponent
      [uploader]="fileUploader"
      [uploadOnDrop]="false"
      [hideQueue]="true"
      [acceptedMimeType]="getValidMimeTypes()">
    </app-file-upload>
  </div>
  <div class="form-group mb-0 col-md-6" [ngClass]="{'mt-05': nonEditable}">
    <label class="form-control-label">{{'FORM_FIELD_DOCUMENT_UPLOADED_FILES' | translate}}</label>
    <label *ngIf="model.values.length === 0" class="form-control-label d-block">{{'FORM_FIELD_DOCUMENT_NO_FILE' | translate}}</label>
    <div class="w-100 mb-05" *ngFor="let value of model.values">
      <div class="d-flex justify-content-between file-field">
          <span class="text-truncate">
            <i class="file-list-icon mr-05" [ngClass]="getFileTypeIcon(value.contentType)"></i>
            <span class="cursor-pointer" (click)="downloadFile(value)">{{value.fullFileName}}</span>
          </span>
        <i *ngIf="!nonEditable"
           class="file-list-icon icomoon icomoon-trash cursor-pointer"
           (click)="deleteFile(value.id)">
        </i>
      </div>
    </div>
    <label class="form-control-label" *ngIf="fileQueue.length > 0">{{'FORM_FIELD_DOCUMENT_QUEUED_FILES' | translate}}</label>
    <div class="w-100 mb-05" *ngFor="let value of fileQueue">
      <div class="d-flex justify-content-between file-field">
          <span class="text-truncate d-flex align-items-center">
            <i class="file-list-icon mr-05" [ngClass]="getFileTypeIcon(value.file.type)"></i>
            <span>{{value.file.name}}</span>
          </span>
        <i *ngIf="!nonEditable"
           class="file-list-icon icomoon icomoon-trash cursor-pointer"
           (click)="fileUploader.removeFromQueue(value)">
        </i>
      </div>
    </div>
  </div>
</div>
