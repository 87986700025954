/* eslint-disable */
import { FormRecordFieldContext, FormRecordFieldView } from '../../../../util/form/form-utils';
import { FieldViewItemChainBuilder } from './field-view-item-chain';
import { FieldViewItemCollection } from './field-view-item-collection';
import { Form } from '../../../../lib/form/form.service';

/* eslint-enable */

export class FieldViewItem {

  public readonly activationData: FieldActivationData = {
    active: true,
    inactivates: [],
    activationChanged: false
  };

  constructor(
    public readonly view: FormRecordFieldView,
    public readonly fieldId: number,
    public readonly formRecordFieldContext: FormRecordFieldContext) {
  }

  get field() {
    return this.formRecordFieldContext.field;
  }

  get fieldTitle() {
    return this.formRecordFieldContext.field.title;
  }

  get groupId(): number {
    return this.formRecordFieldContext.group.groupId;
  }

}

export interface FieldActivationData {
  active: boolean;
  inactivates: FieldViewItem[];
  activationChanged: boolean;
}

export interface FieldActivationStrategy {

  putRules(args: FieldActivationStrategyPutRulesArgs);

  calculateInactivates(args: FieldActivationStrategyCalculateInactivatesArgs): Promise<FieldViewItem[]>;

}

export interface FieldActivationStrategyPutRulesArgs {
  form: Form.Form;
  builder: FieldViewItemChainBuilder;
  viewItems: FieldViewItemCollection;
  viewItem: FieldViewItem;
}

export interface FieldActivationStrategyCalculateInactivatesArgs {
  form: Form.Form;
  viewItem: FieldViewItem;
  activatees: FieldViewItem[];
}
