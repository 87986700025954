/* eslint-disable */
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Transition, UIRouter } from '@uirouter/angular';
import { StateName } from '../../../app.state-names';
import { Company, CompanyService } from '../../../lib/company/company.service';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { TranslateService } from '@ngx-translate/core';
import { Address, AddressModel, AddressResource } from '../../../lib/address';
import { InputMask } from '../../../util/input-masks';
import { NgForm, NgModel } from '@angular/forms';
import { List, Set } from 'immutable';
import { LocalFieldValidationErrors, LocalFieldValidationErrorsFactory, OrderType, QueryResult } from '../../../lib/util/services';
import { Country, CountryService } from '../../../lib/country.service';
import { FieldError, FieldErrors, ObservableErrorResourceParser } from '../../../lib/util/errors';
import { StringKey } from '../../../app.string-keys';
import { OptionItem, QueryFieldModel, SelectUtils, UiConstants } from '../../../util/core-utils';
import { ConfigurationService } from '../../../lib/core-ext/configuration.service';
import { Angular2Multiselects } from '../../../util/multiselect';
import { TranslateUtils } from '../../../util/translate';
import { RightResolver, RightService } from '../../../lib/right.service';
import { RightModel } from '../../../app.rights';
import { ComponentStateResolver } from '../../../util/component-state/component-state-resolver';
import { ToasterService } from '../../../fork/angular2-toaster/angular2-toaster';
import { CompanyLocation, CompanyLocationService } from '../../../lib/company-location/company-location.service';
import { EmptyMessage } from '../../../lib/util/messages';
import { HistoryDetailDialogComponent } from '../../history-log/history-detail-dialog/history-detail-dialog.component';
import { HistoryBaseModel, HistoryLogComponent } from '../../history-log/history-log/history-log.component';
import geocodeStatuses = AddressResource.geocodeStatuses;
import CompanyLocationHistoryType = CompanyLocation.CompanyLocationHistoryType;

/* eslint-enable */

@Component({
  selector: 'app-company-location-base',
  templateUrl: './company-location-base.component.html',
  styleUrls: ['./company-location-base.component.scss']
})
export class CompanyLocationBaseComponent implements OnInit, AfterViewInit {

  @ViewChild('historyComponent')
  historyComponent: HistoryLogComponent;

  @ViewChild('historyDetailModal', { static: true })
  historyDetailModal: HistoryDetailDialogComponent;

  // If you need to access certain classes from HTML, declare them here
  SelectUtils = SelectUtils;
  AddressModel = AddressModel;
  InputMask = InputMask;
  CompanyLocation = CompanyLocation;
  UiConstants = UiConstants;

  // Declare models before use
  editModel: CompanyLocationEditModel;
  detailModel: CompanyLocationDetailModel;

  // Component state resolver, determines the state of the component
  componentState: ComponentStateResolver;

  // Variables used for breadcrumb
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  // Form for validation
  @ViewChild('f', { static: true })
  fForm: NgForm;

  // Validated inputs
  @ViewChild('name')
  name: NgModel;

  @ViewChild('externalId')
  externalId: NgModel;

  @ViewChild('company')
  company: NgModel;

  @ViewChild('longitude')
  longitude: NgModel;

  @ViewChild('latitude')
  latitude: NgModel;

  // Form submission state for Postal Address validation
  formSubmitted: boolean = false;

  // Field errors for server validation
  fieldErrors: CompanyLocationFieldErrorMap;

  private validatedInputs: LocalFieldValidationErrors<NgModel> =
    LocalFieldValidationErrorsFactory.empty();

  // Right model to store the rights available to the User
  rightModel: RightModel = RightModel.empty();

  // Selectable companies for dropdown
  companies: CompanyItem[] = [];

  // Format of postal address, received from server
  postalAddressFormat: string;

  // Country items for postal address
  countryItems: List<AddressModel.CountryItem>;

  // Settings for the multiselect dropdown
  dropdownSettings: Angular2Multiselects.Settings;

  // History

  historyList: CompanyLocationHistoryModel[] = [];

  historyTypes: CompanyLocationHistoryTypeItem[];

  historyQueryModel: QueryFieldModel<CompanyLocation.OrderField> =
    new QueryFieldModel(CompanyLocation.OrderField.HISTORY_CREATION_TIME, OrderType.DESC);

  constructor(private uiRouter: UIRouter,
              private transition: Transition,
              private companyService: CompanyService,
              private translateService: TranslateService,
              private countryService: CountryService,
              private configService: ConfigurationService,
              private rightService: RightService,
              private toasterService: ToasterService,
              private companyLocationService: CompanyLocationService) {
    this.postalAddressFormat = this.configService.getPostalAddressFormat();
    this.fieldErrors = {};
    this.componentState = new ComponentStateResolver(uiRouter, transition,
      'id',
      {stateName: StateName.COMPANY_LOCATION_CREATE, stateHeaderKey: 'COMPANY_LOCATION_CREATE'},
      {stateName: StateName.COMPANY_LOCATION_EDIT, stateHeaderKey: 'COMPANY_LOCATION_EDIT'},
      {stateName: StateName.COMPANY_LOCATION_DETAIL, stateHeaderKey: 'COMPANY_LOCATION_DETAIL'});
  }

  ngOnInit() {
    this.initComponentState(); // Must be called first
    this.loadRightModels();
    this.initBreadcrumb();
    this.initDropdownSettings();
  }

  ngAfterViewInit(): void {
    this.loadLocalFieldValidationErrors();
    this.loadCountries(() => {
      // component state contains the id
      if (this.componentState.id) {
        this.loadModel();
      }
      else {
        // Load country items for create. Otherwise handled in loadModel()
        this.loadCompanies();
        this.editModel.postalAddress.loadCountryItems(this.countryItems, this.configService.getDefaultSelectedCountryCode());
      }
    });
  }

  // Loads user rights
  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  // Called by the init method first
  private loadModel() {
    this.companyLocationService.get({
      id: this.componentState.id!
    }).subscribe((companyLocation: CompanyLocation.CompanyLocation) => {
      if (this.componentState.isEditView()) {
        this.loadEditModel(companyLocation);
      }
      else if (this.componentState.isDetailView()) {
        this.loadDetailModel(companyLocation);
      }
      if (this.componentState.isDetailView() && this.rightModel.companyLocationHistoryLog.hasRight()) {
        this.loadHistoryTypes(() => {
          this.loadHistoryList()
        });
      }
      this.breadcrumbSelf = companyLocation.name;
    });
  }

  private loadEditModel(companyLocation: CompanyLocation.CompanyLocation) {
    this.editModel = new CompanyLocationEditModel();
    this.editModel.name = companyLocation.name;
    this.editModel.externalId = companyLocation.externalId;
    this.editModel.gateCrossingRequired = companyLocation.gateCrossingRequired;
    this.editModel.postalAddress.loadCountryItems(this.countryItems, this.configService.getDefaultSelectedCountryCode());
    this.editModel.postalAddress.load(companyLocation.place.postalAddress);
    this.loadCompanies(companyLocation);
  }

  private loadDetailModel(companyLocation: CompanyLocation.CompanyLocation) {
    this.detailModel = new CompanyLocationDetailModel();
    this.detailModel.name = companyLocation.name;
    this.detailModel.externalId = companyLocation.externalId;
    this.detailModel.gateCrossingRequired = companyLocation.gateCrossingRequired;
    this.detailModel.postalAddress = Address.PostalAddressMapper.toString(companyLocation.place.postalAddress!, this.postalAddressFormat);
    this.detailModel.placeLongitude = companyLocation.place.coordinate ? companyLocation.place.coordinate.longitude.toString() : '';
    this.detailModel.placeLatitude = companyLocation.place.coordinate ? companyLocation.place.coordinate.latitude.toString() : '';
    if (companyLocation.place.geocodeStatus) {
      this.detailModel.geocodeStatus = geocodeStatuses.find(
        (geocodeStatus) => geocodeStatus.status === companyLocation.place.geocodeStatus)!.stringKey;
    }
    this.loadCompanies(companyLocation);
  }

  private loadCompanies(companyLocation?: CompanyLocation.CompanyLocation) {
    const companyIdSet: number[] = [];
    if (this.componentState.isDetailView() && companyLocation) {
      companyIdSet.push(companyLocation.company.id);
    }
    else {
      this.editModel.company = [];
    }
    this.companyService.query({
      id: companyIdSet.length > 0 ? Set.of(...companyIdSet) : undefined,
      disabled: companyIdSet.length > 0 ? undefined : false,
      orders: Set.of({field: Company.OrderField.NAME, type: OrderType.ASC})
    }).subscribe(
      (result: QueryResult<Company.Company>) => {
        this.companies = [];
        result.items.toArray().forEach((c: Company.Company) => {
          const companyItem = {
            id: c.id,
            text: c.name
          };
          this.companies.push(companyItem);
          if (companyLocation) {
            if (companyItem.id === companyLocation.company.id) {
              if (this.componentState.isDetailView()) {
                this.detailModel.companyName = c.name;
              }
              else {
                this.editModel.company.push(companyItem);
              }
            }
          }
        })
      });
  }

  private initComponentState() {
    // Creates the editModel if not readonly
    if (!this.componentState.isDetailView()) {
      this.editModel = new CompanyLocationEditModel();
    }
    else {
      this.detailModel = new CompanyLocationDetailModel()
    }
    // normally you would need to call loadModel after this
  }

  initBreadcrumb() {
    // Set breadcrumbSelf if createView, otherwise set in loadModel()
    if (this.componentState.isCreateView()) {
      this.translateService.get('COMPANY_LOCATION_CREATE').subscribe(
        (result: string) => {
          this.breadcrumbSelf = result;
        }
      );
    }
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_COMPANY_LOCATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.COMPANY_LOCATION_LIST});
      }
    );
  }

  // Get corresponding dictionary key
  getHeadingDictionaryKey(): string {
    return this.componentState.getCurrentHeaderKey();
  }

  hasLocalFieldError(field?: NgModel): boolean {
    return this.validatedInputs.hasLocalError(field);
  }

  removeFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.fieldErrors, fieldError);
  }

  private loadLocalFieldValidationErrors() {
    const validatedInputs = List.of(this.name,
      this.externalId,
      this.company
    );
    this.validatedInputs = LocalFieldValidationErrorsFactory.ofFormFields(this.fForm, validatedInputs);
  }

  private loadCountries(completion: () => void) {
    this.countryService.query({}).subscribe((result: QueryResult<Country.Country>) => {
      this.countryItems = AddressModel.CountryItem.fromCountryList(result.items);
      completion();
    });
  }

  submit() {
    this.formSubmitted = true;
    if (this.editModel.company.length === 0) {
      return;
    }
    if (this.componentState.isCreateView()) {
      this.create();
    }
    else if (this.componentState.isEditView()) {
      this.update();
    }
  }

  create() {
    if (this.hasLocalFieldError()) {
      return;
    }
    if (!this.editModel.postalAddress.valid) {
      return;
    }

    this.companyLocationService.create({
      name: this.editModel.name,
      externalId: this.editModel.externalId,
      gateCrossingRequired: this.editModel.gateCrossingRequired,
      companyId: this.editModel.company[0].id!,
      place: {
        postalAddress: this.editModel.postalAddress.toData()!,
        name: this.editModel.name
      }
    }).subscribe(
      (response: EmptyMessage) => {
        this.uiRouter.stateService.go(StateName.COMPANY_LOCATION_LIST);
      },
      (error: any) => {
        const res = ObservableErrorResourceParser.parseError(error);
        this.fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
      });
  }

  update() {
    if (this.hasLocalFieldError()) {
      return;
    }
    if (!this.editModel.postalAddress.valid) {
      return;
    }
    this.companyLocationService.update({
      id: this.componentState.id!,
      name: this.editModel.name,
      externalId: this.editModel.externalId,
      companyId: this.editModel.company[0].id!,
      gateCrossingRequired: this.editModel.gateCrossingRequired,
      place: {
        postalAddress: this.editModel.postalAddress.toData()!,
        name: this.editModel.name
      }
    }).subscribe(
      (response: EmptyMessage) => {
        this.uiRouter.stateService.go(StateName.COMPANY_LOCATION_LIST);
      },
      (error: any) => {
        const res = ObservableErrorResourceParser.parseError(error);
        this.fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
      });
  }

  // Initializes settings for the multiselect dropdown
  initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .labelKey(OptionItem.KEY_TEXT)
      .enableCheckAll(false)
      .build();
  }

  loadHistoryList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.historyQueryModel.currentPage;
    const order = this.historyQueryModel.getOrder();
    this.companyLocationService.history({
      id: this.componentState.id!,
      withRead: true!,
      orders: Set.of(order),
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.historyQueryModel.itemsPerPage
      } : undefined
    }).subscribe((result: QueryResult<CompanyLocation.HistoryItem>) => {
      this.historyList = [];
      result.items.forEach((history: CompanyLocation.HistoryItem) => {
        const historyModel = new CompanyLocationHistoryModel();
        historyModel.id = history.id;
        historyModel.creationTime = history.creationTime.toUtcIsoString();
        historyModel.type = history.type;
        historyModel.typeString = this.getHistoryType(history.type);
        historyModel.changeLog = history.changeLog;
        historyModel.user = history.issuerUser!;
        historyModel.applicationClassification =
          'HISTORY_APPLICATION_CLASS_TYPE_' + history.applicationClassification;
        historyModel.applicationId =
          history.mobileApplication ? history.mobileApplication.applicationId : '';
        this.historyList.push(historyModel);
      });
      this.historyQueryModel.currentPage = requestedPage;
      this.historyQueryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.historyQueryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
    });
  }

  orderBy(field: CompanyLocation.OrderField) {
    this.historyQueryModel.onOrderFieldChanged(field);
    this.loadHistoryList(1);
  }

  pageChanged(selectedPage: number) {
    this.loadHistoryList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.historyQueryModel.itemsPerPage = itemsPerPage;
    this.loadHistoryList(1);
  }

  getHistoryType(selectedType: CompanyLocationHistoryType): string {
    let text = '';
    this.historyTypes.forEach((type: CompanyLocationHistoryTypeItem) => {
      if (type.id === selectedType) {
        text = type.text;
      }
    });
    return text;
  }

  private loadHistoryTypes(completion: () => void) {
    this.historyTypes = [];
    CompanyLocation.companyLocationHistoryTypes.forEach((type) => {
      const item = {
        id: type.type,
        text: '....'
      };
      this.historyTypes.push(item);
      this.translateService.get(type.stringKey).subscribe((text: string) => {
        item.text = text;
      })
    });
    completion()
  }

}

// Used for create/edit views
export class CompanyLocationEditModel {
  name: string = '';
  externalId: string = '';
  gateCrossingRequired: boolean = false;
  postalAddress: AddressModel.PostalAddressModel;
  company: CompanyItem[] = [];

  constructor() {
    this.postalAddress = new AddressModel.PostalAddressModel();
    this.postalAddress.type = AddressModel.PostalAddressModelType.COMPLEX;
  }

}

// Used for detail view
class CompanyLocationDetailModel {
  name: string = '';
  externalId: string = '';
  companyName: string = '';
  gateCrossingRequired: boolean = false;
  postalAddress: string = '';
  placeLongitude: string = '';
  placeLatitude: string = '';
  geocodeStatus: string = '';

  hasCoordinates(): boolean {
    return this.placeLongitude.length > 0 || this.placeLatitude.length > 0;
  }
}

// CompanyItem for multiselect dropdown
export interface CompanyItem extends OptionItem<number> {
}

export interface CompanyLocationFieldErrorMap {
  external_id?: FieldError;
}

class CompanyLocationHistoryModel extends HistoryBaseModel {
  type: CompanyLocationHistoryType;
}

export class CompanyLocationHistoryTypeItem extends OptionItem<CompanyLocationHistoryType> {
}
