/* eslint-disable */
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { OrderType, QueryResult, ResourceQueryResult } from '../../../../lib/util/services';
import { StockTakingSheet, StockTakingSheetService } from '../../../../lib/stock-takings/stock-taking-sheet.service';
import { Decimal } from 'decimal.js';
import { combineLatest, Observable } from 'rxjs';
import { Stock, StockService } from '../../../../lib/stock/stock.service';
import { User, UserService } from '../../../../lib/user.service';
import { TranslateService } from '@ngx-translate/core';
import { OptionItem, QueryFieldModel, SelectUtils, UiConstants } from '../../../../util/core-utils';
import { Dates, OffsetDateTime } from '../../../../lib/util/dates';
import { DownloadedFile } from '../../../../lib/util/downloaded-files';
import { saveAs } from 'file-saver';
import { ToasterService } from '../../../../fork/angular2-toaster/angular2-toaster';
import { Angular2Multiselects } from '../../../../util/multiselect';
import { StringKey } from '../../../../app.string-keys';
import { Set } from 'immutable';
import { Strings } from '../../../../lib/util/strings';
import { StockTakingSheetSearch, StockTakingSheetSearchService } from '../../../../lib/stock-takings/stock-taking-sheet-search.service';
import { AssigneeItem } from '../../../../util/task-record-utils';
import { UserData, UserDataLoader, UserDataLoaderPermissionDeniedStrategy } from '../../../../lib/user-data-loader';
import { RightModel } from '../../../../app.rights';
import { RightResolver, RightService } from '../../../../lib/right.service';
import { IdentityArray } from '../../../../lib/util/messages';
import StockTakingSheetStateObject = StockTakingSheet.StockTakingSheetStateObject;

/* eslint-enable */

@Component({
  selector: 'app-stock-taking-sheet-list',
  templateUrl: './stock-taking-sheet-list.component.html',
  styleUrls: ['./stock-taking-sheet-list.component.scss']
})
export class StockTakingSheetListComponent implements OnInit, AfterViewInit, OnDestroy {

  StockTakingSheet = StockTakingSheet;
  SelectUtils = SelectUtils;

  @Input()
  stockTakingId: number;

  @Input()
  readonly: boolean;

  @Output() showDialog: EventEmitter<any> = new EventEmitter();

  @Input()
  hasPdf: boolean = false;

  stockTakingSheets: StockTakingSheetModel[] = [];
  stockTakingSheetStates: StockTakingSheetStateObject[] = [];
  stocks: StockItemForSearch[] = [];

  showSearch: boolean = true;
  searchResult: StockTakingSheetSearch.SearchDataResult;
  searchModel: StockTakingSheetSearchModel = new StockTakingSheetSearchModel();
  usersForSearch: AssigneeItem[] = [];

  dropdownSettings: Angular2Multiselects.Settings;
  dropdownSettingsForUser: Angular2Multiselects.Settings;
  queryModel: QueryFieldModel<StockTakingSheet.OrderField> = new QueryFieldModel(StockTakingSheet.OrderField.CREATION_TIME, OrderType.DESC);
  rightModel: RightModel = RightModel.empty();

  defaultItem = {
    state: null,
    stringKey: '',
    text: ''
  };

  constructor(
    private rightService: RightService,
    private stockTakingSheetService: StockTakingSheetService,
    private userService: UserService,
    private stockService: StockService,
    private translateService: TranslateService,
    private toasterService: ToasterService,
    private stockTakingSheetSearchService: StockTakingSheetSearchService,
    private userDataLoader: UserDataLoader) {
  }

  ngOnInit() {
    this.loadRightModels(() =>
      this.loadSearch(() => {
        this.showSearch = !this.searchModel.isEmpty();
        this.loadStockTakingSheetStates();
        this.loadAssigneeData();
        if (this.rightModel.stockRead.hasRight() && this.rightModel.stockTakingSheetRead.hasRight()) {
          this.loadStockNamesForSearch(() => {
            this.loadList();
          });
        }
      })
    );
  }

  ngAfterViewInit() {
    this.initDropdownSettings();
  }

  private loadRightModels(completion: () => void) {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        completion();
      }
    );
  }

  private loadStockNamesForSearch(completion: () => void) {
    this.stockService.query({
      fields: ['id', 'name'].join(',')
    }).subscribe((result: ResourceQueryResult<Stock>) => {
      this.searchModel.stocks = [];
      this.stocks = [];
      const savedStockIds = this.searchModel.stockIds.split(',');
      result.items.forEach((stock) => {
        const item = {
          id: stock.id,
          text: stock.name
        };
        this.stocks.push(item);
        savedStockIds.forEach((stockId) => {
          if (+stockId === item.id) {
            this.searchModel.stocks.push(item);
          }
        });
      })
    });
    completion()
  };

  private loadSearch(completion: () => void) {
    const obs: Observable<SearchLoadResult> = combineLatest(
      this.stockTakingSheetSearchService.getSearchData({}),
      (storedSearchData: StockTakingSheetSearch.SearchDataResult) => {
        const result: SearchLoadResult = {
          storedSearchData: storedSearchData
        };
        return result;
      }
    );
    obs.subscribe(
      (result: SearchLoadResult) => {
        this.searchResult = result.storedSearchData;
        this.postInitSearch(result.storedSearchData, completion);
      }
    );
  }

  private postInitSearch(storedSearchData: StockTakingSheetSearch.SearchDataResult, completion: () => void) {
    this.queryModel.itemsPerPage = storedSearchData.searchData.itemsPerPage;
    this.queryModel.currentPage = storedSearchData.searchData.pageNumber;
    this.queryModel.setOrder(storedSearchData.searchData.order);
    this.searchModel.trxId = storedSearchData.searchData.trxId;
    this.searchModel.stockIds = storedSearchData.searchData.stockIds;
    this.searchModel.selectedState = storedSearchData.searchData.selectedState;
    this.searchModel.assigneeUser = storedSearchData.searchData.ownerUserName;
    completion();
  }

  private loadAssigneeData() {
    this.searchModel._assigneeUserItem = [];
    this.createUserArrayObservable().subscribe((users: IdentityArray<UserData> | null) => {
      this.usersForSearch = users ? users.map((user: UserData) => {
        const item = {id: user.id, itemName: user.person_name + '(' + user.user_name + ')'};
        if (this.searchResult.searchData.ownerUserId === user.id) {
          this.searchModel._assigneeUserItem[0] = item;
        }
        return item;
      }) : [];
    });
  }

  private createUserArrayObservable(): Observable<IdentityArray<UserData> | null> {
    return this.userDataLoader.loadAllWithFields(UserDataLoaderPermissionDeniedStrategy.MISS_ALL,
      undefined, Set.of('id', 'person_name', 'user_name'));
  }

  loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();
    let states: StockTakingSheet.StockTakingSheetState[] | undefined = undefined;
    if (this.searchModel.state && this.searchModel.state.state !== null) {
      states = [];
      states.push(this.searchModel.state.state);
    }
    if (!this.searchModel.state && this.searchModel.selectedState && this.searchModel.selectedState !== null) {
      states = [];
      states.push(this.searchModel.selectedState!);
    }
    const stockIdSetForSearch: number[] = [];
    if (this.searchModel.stocks.length > 0) {
      this.searchModel.stocks.forEach((stock) => {
        if (stock.id !== null) {
          stockIdSetForSearch.push(stock.id);
        }
      });
    }
    else if (this.searchModel.stockIds.length > 0) {
      this.searchModel.stockIds.split(',').forEach((stockId) => {
        stockIdSetForSearch.push(+stockId);
      })
    }
    this.stockTakingSheetService.query({
      stockTakingId: this.stockTakingId,
      withItems: true,
      orders: Set.of(order),
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined,
      trxId: Strings.undefinedOrNonEmpty(this.searchModel.trxId),
      stockId: stockIdSetForSearch[0] ? stockIdSetForSearch[0] : undefined,
      state: states ? Set.of(...states) : undefined,
      assigneeUserId: this.searchModel.assigneeUserItem ? this.searchModel.assigneeUserItem.id : undefined,
    }).subscribe((result: QueryResult<StockTakingSheet.StockTakingSheet>) => {
      const stockIds: number[] = [];
      result.items.forEach((stockTakingSheet: StockTakingSheet.StockTakingSheet) => {
        stockIds.push(stockTakingSheet.stockId);
      });
      const stockIdSet = stockIds.join(',');
      this.createCombinedObservable(stockIdSet).subscribe(
        (combinedResult: CombinedResult) => {
          this.stockTakingSheets = [];
          result.items.forEach((stockTakingSheet: StockTakingSheet.StockTakingSheet) => {
            const stock = combinedResult.stock.items.find((s) => s.id === stockTakingSheet.stockId);
            const ownerUser = combinedResult.user.items.find((user) => user.id === stockTakingSheet.assigneeUserId);
            const state = this.stockTakingSheetStates.find((s) => s.state === stockTakingSheet.state);
            let amountFound: Decimal = new Decimal('0');
            let stockAmount: Decimal = new Decimal('0');
            stockTakingSheet.items!.forEach((item) => {
              if (item) {
                if (item.amountFound) {
                  amountFound = amountFound.add(item.amountFound.toString());
                }
                if (item.stockAmount) {
                  stockAmount = stockAmount.add(item.stockAmount.toString());
                }
              }
            });
            const stockTakingSheetItem = {
              sheetId: stockTakingSheet.sheetId,
              trxId: stockTakingSheet.trxId + '',
              stockName: stock ? stock.name : '',
              state: state ? state.text : '',
              canUpdateStock: state ? state.state === 'CLOSED' : false,
              ownerUserName: ownerUser ? ownerUser.person_name : '',
              amountFound: amountFound.toString(),
              stockAmount: stockAmount.toString(),
              amountDifference: amountFound.minus(stockAmount.toString()).toString(),
              creationTime: stockTakingSheet.creationTime
            };
            this.stockTakingSheets.push(stockTakingSheetItem);
          });
        },
        () => {
        }
      );
    })
  }

  loadStockTakingSheetStates() {
    this.stockTakingSheetStates = [];
    this.defaultItem = {
      state: null,
      stringKey: '',
      text: this.translateService.instant(StringKey.COMMON_VALUE_UNSELECTED)
    };
    this.searchModel.state = this.defaultItem;
    this.stockTakingSheetStates.push(this.defaultItem);
    StockTakingSheet.stockTakingSheetStates.forEach((state) => {
      const item = {
        state: state.state,
        stringKey: state.stringKey,
        text: '...'
      };
      this.stockTakingSheetStates.push(item);
      this.translateService.get(state.stringKey).subscribe((text: string) => {
        item.text = text;
      });
      if (item.state === this.searchResult.searchData.selectedState) {
        this.searchModel.state = item;
      }
    });
  }

  private createCombinedObservable(stockIdSet: string): Observable<CombinedResult> {
    return combineLatest(
      this.stockService.query({
        fields: ['id', 'name'].join(','),
        id: stockIdSet
      }),
      this.userService.query({
        fields: ['id', 'person_name'].join(',')
      }),
      (stock: ResourceQueryResult<Stock>,
       user: ResourceQueryResult<User>) => {
        return {
          stock: stock,
          user: user
        }
      }
    );
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  onSearchClicked() {
    this.loadList(1);
  }

  onSearchReset() {
    this.stockTakingSheetSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.searchModel.clear();
          this.searchModel.state = this.defaultItem;
          this.searchModel.stocks = [];
          this.searchModel._assigneeUserItem = [];
          this.showSearch = true;
          this.loadList(1);
        });
      }
    );
  }

  private saveSearch() {
    const request = {
      searchData: {
        itemsPerPage: this.queryModel.itemsPerPage,
        pageNumber: this.queryModel.currentPage,
        order: this.queryModel.getOrder(),
        trxId: this.searchModel.trxId,
        selectedState: this.searchModel.state ? this.searchModel.state.state : null,
        stockIds: this.encodeSelectedStocks(),
        ownerUserName: this.searchModel.assigneeUser,
        assigneeUserId: this.searchModel.assigneeUserItem ? this.searchModel.assigneeUserItem.id : undefined,
      }
    };
    this.stockTakingSheetSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  encodeSelectedStocks(): string {
    return this.searchModel.stocks.filter((s) => s.id !== null).map((s) => s.id).join(',');
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: StockTakingSheet.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  downloadPdf(sheetId: number) {
    this.stockTakingSheetService.downloadPdf(sheetId).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName('stock_taking_sheet_' + sheetId + '.pdf'));
      },
      (error) => {
        this.toasterService.pop({
          timeout: UiConstants.ToastTimeoutLong,
          type: UiConstants.toastTypeError,
          title: this.translateService.instant('PDF_DOWNLOAD'),
          body: this.translateService.instant('PDF_DOWNLOAD_ERROR_MESSAGE')
        });
      });
  }

  downloadXls(sheetId: number) {
    this.stockTakingSheetService.exportXls(sheetId).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName('stock_taking_sheet_' + sheetId + '.xlsx'));
      },
      (error) => {
        this.toasterService.pop({
          timeout: UiConstants.ToastTimeoutLong,
          type: UiConstants.toastTypeError,
          title: this.translateService.instant('COMMON_BUTTON_EXPORT'),
          body: this.translateService.instant('PDF_DOWNLOAD_ERROR_MESSAGE')
        });
      });
  }

  updateStock(sheetId: number) {
    this.stockTakingSheetService.updateStock({sheetId: sheetId}).subscribe(
      () => {
        this.loadList();
      });
  }

  initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .labelKey(OptionItem.KEY_TEXT)
      .build();
    this.dropdownSettingsForUser = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .build();
  }

  showAddStockTakingSheetDialog() {
    this.showDialog.emit();
  }

  ngOnDestroy() {
    this.saveSearch();
  }

}

interface CombinedResult {
  stock: ResourceQueryResult<Stock>;
  user: ResourceQueryResult<User>;
}

class StockTakingSheetModel {
  sheetId: number;
  trxId: string = '';
  stockName: string = '';
  state: string = '';
  canUpdateStock: boolean = false;
  ownerUserName: string = '';
  stockAmount: string = '';
  amountFound: string = '';
  amountDifference: string = '';
  creationTime: OffsetDateTime = Dates.emptyOffsetDateTime();
}

class StockTakingSheetSearchModel {

  trxId: string = '';

  state: StockTakingSheetStateObject | undefined = undefined;

  stocks: StockItemForSearch[] = [];

  stockIds: string = '';

  selectedState: StockTakingSheet.StockTakingSheetState | null = null;

  assigneeUser: string;

  _assigneeUserItem: AssigneeItem[] = [];

  get assigneeUserItem(): AssigneeItem | undefined {
    return this._assigneeUserItem.length > 0 ? this._assigneeUserItem[0] : undefined;
  }

  public isEmpty(): boolean {
    return this.trxId === ''
      && this.stockIds === ''
      && this.selectedState === null
      && this.assigneeUser.length === 0
      ;
  }

  public clear() {
    this.trxId = '';
    this.stockIds = '';
    this.selectedState = null;
  }

  constructor() {
  }

}

class StockItemForSearch extends OptionItem<number> {
}

interface SearchLoadResult {
  storedSearchData: StockTakingSheetSearch.SearchDataResult,
}
