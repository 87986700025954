/* eslint-disable */
import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Transition, UIRouter} from '@uirouter/angular';
import {UserGroup, UserGroupFieldErrorMap, UserGroupService} from '../../../lib/user-group.service';
import {
  ApplicationTypeItem,
  CompanyItem,
  OptionItem,
  RoleItem,
  UiConstants,
  UserGroupEditModel,
  UserItem,
} from '../../../util/core-utils';
import {FieldError, FieldErrors, ObservableErrorResourceParser,} from '../../../lib/util/errors';
import {StateName} from '../../../app.state-names';
import {ApplicationType, RootCoreService} from '../../../lib/root-core.service';
import {Arrays} from '../../../lib/util/arrays';
import {TranslateService} from '@ngx-translate/core';
import {StringKey} from '../../../app.string-keys';
import {Angular2Multiselects} from '../../../util/multiselect';
import {User, UserService,} from '../../../lib/user.service';
import {List, Set} from 'immutable';
import {BreadcrumbParent} from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import {Role, RoleService} from '../../../lib/role/role.service';
import {Company, CompanyService} from '../../../lib/company/company.service';
import {QueryResult, ResourceQueryResult} from '../../../lib/util/services';
import {RightModel} from '../../../app.rights';
import {UserProfile} from '../../../lib/auth.service';
import {RightResolver, RightService} from '../../../lib/right.service';
import {EmptyMessage, IdentityMessage} from '../../../lib/util/messages';

/* eslint-enable */

@Component({
  selector: 'app-usergroup-clone',
  templateUrl: 'usergroup-clone.component.html',
  styleUrls: ['usergroup-clone.component.scss']
})
export class UserGroupCloneComponent implements OnInit, AfterViewInit {

  userGroupId: number;
  model: UserGroupEditModel = new UserGroupEditModel();
  fieldErrors: UserGroupFieldErrorMap = {};
  roles: RoleItem[] = [];
  selectableRoles: RoleItem[] = [];
  companies: CompanyItem[] = [];
  applicationTypes: ApplicationTypeItem[] = [];
  userItems: UserItem[] = [];
  selectableUsers: UserItem[] = [];
  companyDisabled: boolean = false;
  userIds: number[] = [];

  rightModel: RightModel = RightModel.empty();
  userProfile: UserProfile;
  users: List<User> = List.of<User>();
  currentUsers: List<User> = List.of<User>();

  dropdownSettings?: Angular2Multiselects.Settings;
  dropdownSettingsForCompany?: Angular2Multiselects.Settings;
  dropdownSettingsForUser?: Angular2Multiselects.Settings;
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  constructor(
    private translateService: TranslateService,
    private rootCoreService: RootCoreService,
    private userService: UserService,
    private userGroupService: UserGroupService,
    private uiRouter: UIRouter,
    private transition: Transition,
    private roleService: RoleService,
    private companyService: CompanyService,
    private rightService: RightService) {
    this.userGroupId = this.transition.params().id;
  }

  ngOnInit(): void {
    this.translateService.get('USER_GROUP_PANEL_HEADING_CLONE').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_USER_GROUPS').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.USER_GROUP_LIST});
      }
    );
  }

  ngAfterViewInit(): void {
    this.loadRightModels(() => {
      this.loadModel();
    });
  }

  private initDropdown(enableCheckAllUser: boolean) {
    this.translateService.get([
      StringKey.COMMON_PLACEHOLDER_EMPTY_LIST,
      StringKey.COMMON_PLACEHOLDER_SELECT_ALL,
      StringKey.COMMON_PLACEHOLDER_SEARCH,
    ]).subscribe((o) => {
      this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
        .singleSelection(false)
        .enableSearchFilter(true)
        .enableCheckAll(true)
        .labelKey(OptionItem.KEY_TEXT)
        .build();
      this.dropdownSettingsForCompany = new Angular2Multiselects.SettingsBuilder()
        .singleSelection(false)
        .enableSearchFilter(true)
        .enableCheckAll(true)
        .labelKey(OptionItem.KEY_TEXT)
        .build();
      this.dropdownSettingsForUser = new Angular2Multiselects.SettingsBuilder()
        .singleSelection(false)
        .enableSearchFilter(true)
        .enableCheckAll(enableCheckAllUser)
        .labelKey(OptionItem.KEY_TEXT)
        .build();
    });
  }

  private loadModel() {
    this.userGroupService.get({
      id: this.userGroupId
    }).subscribe(
      (userGroup: UserGroup) => {
        this.userIds = userGroup.user_ids;
        this.model.name = userGroup.name;
        this.model.externalId = userGroup.external_id;
        this.loadScopes(userGroup.application_types);
        this.loadUsers();
        this.loadRoles(userGroup.roles, () => {
          this.loadCompanies(userGroup.company_ids);
        });
      },
      (error: any) => {
        // Ignored. The interceptor handles the global errors.
      }
    );
  }

  private loadRightModels(completion: () => void) {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        this.userProfile = resolver.userProfile!;
        completion();
      }
    );
  }

  private loadRoles(roles: number[], completion: () => void) {
    this.roleService.query({}).subscribe(
      (result: QueryResult<Role.Role>) => {
        this.roles = [];
        this.model.roles = [];
        result.items.forEach((role: Role.Role) => {
          const item = new RoleItem();
          item.id = role.id;
          item.text = role.name;
          this.roles.push(item);
          const selectedRole = roles.find((roleId) => roleId === item.id!);
          if (selectedRole) {
            this.model.roles.push(item);
          }
        });
        // Equal on load, filtered later in onCompanySelected()
        this.selectableRoles = this.roles;
        completion()
      },
      (error: any) => {
        // Ignored. The interceptor handles the global errors.
      }
    );
  }

  private loadCompanies(companyIds?: number[]) {
    this.userService.get({
      id: this.userProfile.id
    }).subscribe((user: User) => {
      if (user.companies.length > 0) {
        this.companyService.query({
          id: Set.of(...user.companies.map(u => u.id))
        }).subscribe(
          (result: QueryResult<Company.Company>) => {
            this.companies = [];
            this.model.companies = [];
            result.items.forEach(c => {
              if (c) {
                const item = new CompanyItem();
                item.id = c.id;
                item.text = c.name;
                item.allowedRoleIds = c.allowedRoleIds!;
                this.companies.push(item);
                if (user.companies.length === 1) {
                  this.model.companies.push(item);
                  this.companyDisabled = true;
                }
              }
            });
            this.onCompanyChanged();
          });
      } else {
        this.companyService.query({disabled: false}).subscribe(
          (result: QueryResult<Company.Company>) => {
            this.companies = [];
            this.model.companies = [];
            result.items.forEach((company: Company.Company) => {
              const item = new CompanyItem();
              item.id = company.id;
              item.text = company.name;
              item.allowedRoleIds = company.allowedRoleIds!;
              this.companies.push(item);
              if (companyIds && companyIds.includes(company.id)) {
                this.model.companies.push(item);
                this.filterRoles();
              }
            });
          },
          (error: any) => {
            // Ignored. The interceptor handles the global errors.
          }
        );
      }
    });
  }

  private loadScopes(scopeKeys: string[]) {
    this.rootCoreService.getApplicationTypes({})
      .subscribe(
        (applicationTypes: ApplicationType[]) => {
          this.applicationTypes = [];
          this.model.application_types = [];
          Arrays.iterateByIndex(applicationTypes, (applicationType) => {
            const item = new ApplicationTypeItem();
            item.id = applicationType.key;
            item.text = applicationType.name;
            if (scopeKeys.indexOf(applicationType.key) !== -1) {
              this.model.application_types.push(item);
            }
            this.applicationTypes.push(item);
          });
        },
        (error: any) => {
          // Ignored. The interceptor handles the global errors.
        }
      );
  }

  private loadUsers() {
    this.userService.query({}).subscribe(
      (result: ResourceQueryResult<User>) => {
        let enableCheckAllUser = true; // By default, allow check all.
        const currentUsers: User[] = [];
        this.userItems = [];
        this.model.users = [];
        this.selectableUsers = [];
        Arrays.iterateByIndex(result.items, (user) => {
          const item = this.toUserItem(user);
          if (this.userIds.indexOf(user.id) !== -1) {
            this.model.users.push(item);
            currentUsers.push(user);
            if (user.protected_user) {
              // There is at least one selected protected user, disable select all, cause it's buggy.
              enableCheckAllUser = false;
            }
          }
          if (!user.protected_user) {
            // Filter the protected user from the dropdown.
            this.userItems.push(item);
          }
        });
        this.currentUsers = List.of(...currentUsers);
        this.users = List.of(...result.items);
        this.selectableUsers = this.userItems;
        this.initDropdown(enableCheckAllUser);
      }
    );
  }

  onUserSelect(event: UserItem) {
    const selectedUser = this.findUserById(event.id!);
    if (selectedUser !== null && selectedUser.protected_user) {
      // Protected user is added to the group, undo!
      const index = this.model.users.indexOf(event);
      this.model.users.splice(index, 1);
    }
  }

  onUserDeSelect(event: UserItem) {
    const selectedUser = this.findUserById(event.id!);
    if (selectedUser !== null && selectedUser.protected_user) {
      // Protected user is removed from the group, undo!
      this.model.users.push(event);
    }
  }

  onSelectAllUser(event: UserItem[]) {
    const ua: User[] = [];
    this.users.forEach((u) => {
      if (u!.protected_user) {
        if (this.currentUsers.contains(u!)) {
          ua.push(u!);
        }
      } else {
        ua.push(u!);
      }
    });
    const items = ua.map((u) => {
      return this.toUserItem(u!);
    });
    this.model.users = items;
  }

  onDeSelectAllUser(event: UserItem[]) {
    const ua: User[] = [];
    this.users.forEach((u) => {
      if (u!.protected_user) {
        if (this.currentUsers.contains(u!)) {
          ua.push(u!);
        }
      }
    });
    const items = ua.map((u) => {
      return this.toUserItem(u!);
    });
    this.model.users = items;
  }

  onCompanyChanged() {
    this.filterRoles();
    this.filterUsers();
  }

  filterRoles() {
    if (this.model.companies.length > 0) {
      this.selectableRoles = [];
      const filteredRoles: RoleItem[] = [];
      Set.of(...this.model.companies.map(c => c.allowedRoleIds.toArray())
        .reduce((previousValue, currentValue) => previousValue.concat(currentValue), []))
        .forEach((roleId: number) => {
          this.roles.forEach((role: RoleItem) => {
            if (role.id === roleId) {
              this.selectableRoles.push(role);
            }
          });
          this.model.roles.forEach((selectedRole: RoleItem) => {
            if (roleId === selectedRole.id) {
              filteredRoles.push(selectedRole);
            }
          });
        });
      this.model.roles = filteredRoles;
    } else {
      this.selectableRoles = this.roles;
    }
  }

  filterUsers() {
    this.selectableUsers = this.userItems;
  }

  get companyIds(): number[] | undefined {
    if (this.model.companies.length > 0 ) {
      return this.model.companies.map(c => c.id!);
    }
    return undefined;
  }

  clone() {
    this.userGroupService.create({
      name: this.model.name,
      external_id: this.model.externalId,
      roles: this.model.createRoleKeys(),
      application_types: this.model.createApplicationTypeKeys(),
      company_ids: this.companyIds
    }).subscribe(
      (createResult: IdentityMessage) => {
        this.userGroupService.updateUsers({
          id: createResult.id,
          user_ids: this.model.createUserIds()
        }).subscribe((userUpdateResult: EmptyMessage) => {
          this.uiRouter.stateService.go(StateName.USER_GROUP_LIST);
        });
      },
      (error: any) => {
        const res = ObservableErrorResourceParser.parseError(error);
        this.fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
      }
    );
  }

  back() {
    window.history.back();
  }

  removeFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.fieldErrors, fieldError);
  }

  getTextMaximumLength(): number {
    return UiConstants.maximumVarcharLength;
  }

  get selectedUsersText(): string {
    return this.selectedOptionsText(this.model.users);
  }

  get selectedApplicationTypesText(): string {
    return this.selectedOptionsText(this.model.application_types);
  }

  get selectedRolesText(): string {
    return this.selectedOptionsText(this.model.roles);
  }

  private selectedOptionsText<T>(items: OptionItem<T>[]): string {
    return items
      .map((o) => {
        return o.text;
      })
      .join(', ');
  }

  private findUserById(id: number): User | null {
    let user: User | null = null;
    this.users.forEach((u: User) => {
      if (u.id === id) {
        user = u;
        return false;
      }
    });
    return user;
  }

  private toUserItem(user: User) {
    const item = new UserItem();
    item.id = user.id;
    item.text = user.person_name + ' (' + user.user_name + ')';
    item.disabled = user.disabled;
    return item;
  }

}
