<div class="form-horizontal bordered-row">

  <div class="form-group row has-warning"
  *ngIf="model.locationChanged">
    <label class="col-form-label form-control-label col-md-12 detail-title"> {{ 'SHIPPING_DEMAND_COMPANY_LOCATION_CHANGED_ERROR' |
      translate }} </label>
  </div>

  <div class="form-group row"
       [ngClass]="{ 'has-danger': (form.submitted  && model.addressType.length === 0)}">
    <label class="col-form-label form-control-label col-md-4 detail-title required-field-label">
      {{ 'SHIPPING_DEMAND_ADDRESS_TYPE' | translate }}
    </label>
    <div class="col-md-8">
      <angular2-multiselect
        [(ngModel)]="model.addressType"
        (ngModelChange)="onAddressTypeChanged()"
        [ngClass]="{ 'form-control-danger': (form.submitted && model.addressType.length === 0)}"
        [data]="addressTypes"
        [settings]="addressTypeDropdownSettings">
      </angular2-multiselect>
      <div class="form-control-feedback" *ngIf="form.submitted && model.addressType.length === 0">{{
        'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
    </div>
  </div>

  <!--region New address-->
  <div *ngIf="model.addressType.length === 1 && model.addressType[0].id === 'NEW_ADDRESS'">
    <!--region Place Name-->
    <div class="form-group row"
         [ngClass]="{ 'has-danger': (form.submitted && !addressName.valid)}">
      <label
        class="col-form-label form-control-label col-md-4 detail-title" [class.required-field-label]="model.addressType === 'NEW_ADDRESS'">
        {{'SHIPPING_DEMAND_ADDRESS_NAME' | translate}}
      </label>
      <div class="col-md-8">
        <input type="text" class="form-control"
               placeholder="{{'SHIPPING_DEMAND_ADDRESS_NAME' | translate}}"
               [(ngModel)]="model.addressName"
               [ngClass]="{ 'form-control-danger': (form.submitted && !addressName.valid)}"
               name="addressName"
               id="addressName"
               #addressName="ngModel"
               [required]="model.addressType === 'NEW_ADDRESS'"
               [disabled]="disabled">
      </div>
    </div>
    <!--endregion Place Name-->

    <!--region Postal Address-->
    <div>
      <app-postal-address-complex
        [form]="form"
        [model]="model.postalAddress"
        [readonly]="disabled"
        [fieldRatio]="8">
      </app-postal-address-complex>
    </div>
    <!--endregion Postal Address-->
  </div>
  <!--endregion New Address-->

  <!--region Location Stock Chooser-->
  <div *ngIf="model.addressType.length === 1 && model.addressType[0].id === 'LOCATION'">
    <!--region Company-->
    <div class="form-group row" [ngClass]="{ 'has-danger': (form.submitted && model.companyItem.length === 0)}">
      <label class="col-form-label form-control-label col-md-4 detail-title required-field-label">
        {{'SHIPPING_DEMAND_ADDRESS_COMPANY' | translate}}
      </label>
      <div class="col-md-8">
        <angular2-multiselect [data]="companyItems"
                              [settings]="dropdownSettings"
                              class="form-control"
                              [ngClass]="{ 'form-control-danger': (form.submitted && model.companyItem.length === 0)}"
                              [(ngModel)]="model.companyItem"
                              (ngModelChange)="onCompanyChanged()"
                              (onRemoteSearch)="onCompanySearch($event.target.value)"
                              [disabled]="disabled">
        </angular2-multiselect>
        <div class="form-control-feedback" *ngIf="form.submitted && model.companyItem.length === 0">{{
            'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
      </div>
    </div>
    <!--endregion Company-->

    <!--region Location-->
    <div class="form-group row" [ngClass]="{ 'has-danger': (form.submitted && model.locationItem.length === 0)}">
      <label class="col-form-label form-control-label col-md-4 detail-title required-field-label">
        {{'SHIPPING_DEMAND_ADDRESS_LOCATION' | translate}}
      </label>
      <div class="col-md-8">
        <angular2-multiselect [data]="locationItems"
                              [settings]="dropdownSettings"
                              class="form-control"
                              [ngClass]="{ 'form-control-danger': (form.submitted && model.locationItem.length === 0)}"
                              [(ngModel)]="model.locationItem"
                              (ngModelChange)="onLocationChanged()"
                              (onRemoteSearch)="onLocationSearch($event.target.value)"
                              [disabled]="disabled">
        </angular2-multiselect>
        <div class="form-control-feedback" *ngIf="form.submitted && model.locationItem.length === 0">{{
            'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
      </div>
    </div>
    <!--endregion Location-->

    <!--region Stock-->
    <div class="form-group row" *ngIf="model.isSource() ? isEnabledField(EditableField.SOURCE_STOCK) : isEnabledField(EditableField.DESTINATION_STOCK)">
      <label class="col-form-label form-control-label col-md-4 detail-title">
        {{'SHIPPING_DEMAND_ADDRESS_STOCK' | translate}}
      </label>
      <div class="col-md-8">
        <angular2-multiselect [data]="stockItems"
                              [settings]="dropdownSettings"
                              class="form-control"
                              [ngClass]="{ 'form-control-danger': (form.submitted && model.stockItem.length === 0)}"
                              [(ngModel)]="model.stockItem"
                              (onRemoteSearch)="onStockSearch($event.target.value)"
                              [disabled]="disabled">
        </angular2-multiselect>
      </div>
    </div>
    <!--endregion Stock-->
  </div>
  <!--endregion Location Stock Chooser-->
</div>
