/* eslint-disable */
import { Observable, Observer } from 'rxjs';
import { Injectable } from '@angular/core';
import { LegacyWorkflow } from './legacy-workflow-service';
import { Order, OrderType, Services } from '../util/services';
import { UiConstants } from '../../util/core-utils';
import { MemStorage } from '../util/mem-storage';
import { environment } from '../../../environments/environment';
import { Dates, LocalDate } from '../util/dates';
import { DisabledItem } from '../../util/search-utils';
import { RuntimeConfiguration } from '../runtime-configuration';
import { LoggedInUserStorage } from '../util/storages';
/* eslint-enable */

@Injectable()
export class LegacyWorkflowSearchService {

  constructor() {
  }

  getSearchData(request: LegacyWorkflowSearch.SearchDataGetRequest): Observable<LegacyWorkflowSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<LegacyWorkflowSearch.SearchDataResult>) => {
      try {
        observer.next(storage.read(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  setSearchData(request: LegacyWorkflowSearch.SearchDataSetRequest): Observable<LegacyWorkflowSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<LegacyWorkflowSearch.SearchDataResult>) => {
      try {
        observer.next(storage.save(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  resetSearchData(request: LegacyWorkflowSearch.SearchDataGetRequest): Observable<LegacyWorkflowSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<LegacyWorkflowSearch.SearchDataResult>) => {
      try {
        observer.next(storage.reset(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  private createStorage(): LegacyWorkflowSearch.Storage {
    return new LegacyWorkflowSearch.Storage();
  }

}

export namespace LegacyWorkflowSearch {

  export interface SearchDataGetRequest {
  }

  export interface SearchDataSetRequest {
    searchData: SearchData;
  }

  export interface SearchDataResult {
    searchData: SearchData;
  }

  export interface SearchData {
    itemsPerPage: number;
    pageNumber: number;
    order: Order<LegacyWorkflow.OrderField>;
    id: string;
    name: string;
    external_id: string;
    description: string;
    disabled: DisabledItem | null;
    creationTimeFrom: LocalDate;
    creationTimeTo: LocalDate;
    updateTimeFrom: LocalDate;
    updateTimeTo: LocalDate;
  }

  interface StoredSearchRawDataBase {
    version: number;
  }

  interface StoredSearchRawData extends StoredSearchRawDataBase {
    itemsPerPage: number;
    pageNumber: number;
    order: Order<LegacyWorkflow.OrderField>;
    id: string;
    name: string;
    external_id: string;
    description: string;
    disabled: DisabledItem | null;
    creationTimeFrom?: string;
    creationTimeTo?: string;
    updateTimeFrom?: string;
    updateTimeTo?: string;
  }

  export class Storage {

    /**
     * Increment this version if you change the SearchData interface.
     */
    private static readonly VERSION: number = 3;

    private static readonly DEFAULT_SEARCH_DATA: SearchData = {
      itemsPerPage: UiConstants.pageNumbers[0],
      pageNumber: 1,
      order: {
        field: LegacyWorkflow.OrderField.ID,
        type: OrderType.DESC
      },
      id: '',
      name: '',
      external_id: '',
      description: '',
      disabled: DisabledItem.active(),
      creationTimeFrom: Dates.emptyLocalDate(),
      creationTimeTo: Dates.emptyLocalDate(),
      updateTimeFrom: Dates.emptyLocalDate(),
      updateTimeTo: Dates.emptyLocalDate(),
    };

    constructor() {
    }

    public read(request: SearchDataGetRequest): SearchDataResult {
      let rawBase: StoredSearchRawDataBase;
      try {
        const key = this.generateKey();
        const json = MemStorage.getItem(key);
        if (!json) {
          return this.createDefaultResult();
        }
        rawBase = JSON.parse(json);
      }
      catch (error) {
        return this.createDefaultResult();
      }
      if (Storage.VERSION === rawBase.version) {
        const raw: StoredSearchRawData = <StoredSearchRawData> rawBase;
        return this.fromRaw(raw);
      }
      return this.createDefaultResult();
    }

    public reset(request: SearchDataGetRequest): SearchDataResult {
      return this.save({
        searchData: Storage.DEFAULT_SEARCH_DATA,
      });
    }

    public save(request: SearchDataSetRequest): SearchDataResult {
      const key = this.generateKey();
      const raw = this.toRaw(request.searchData);
      const json = JSON.stringify(raw);
      MemStorage.setItem(key, json);
      return this.read(request);
    }

    private generateKey(): string {
      return 'UserId(' + LoggedInUserStorage.getInstance().getUserId() + ')-Search-LegacyWorkflow';
    }

    private createDefaultResult(): SearchDataResult {
      return {
        searchData: Storage.DEFAULT_SEARCH_DATA,
      };
    }

    private fromRaw(data: StoredSearchRawData): SearchDataResult {
      return {
        searchData: {
          itemsPerPage: data.itemsPerPage,
          pageNumber: data.pageNumber,
          order: data.order,
          id: data.id,
          name: data.name,
          external_id: data.external_id,
          description: data.description,
          disabled: data.disabled,
          creationTimeFrom: Services.toLocalDate(data.creationTimeFrom),
          creationTimeTo: Services.toLocalDate(data.creationTimeTo),
          updateTimeFrom: Services.toLocalDate(data.updateTimeFrom),
          updateTimeTo: Services.toLocalDate(data.updateTimeTo),
        }
      };
    }

    private toRaw(data: SearchData): StoredSearchRawData {
      return {
        version: Storage.VERSION,
        itemsPerPage: data.itemsPerPage,
        pageNumber: data.pageNumber,
        order: data.order,
        id: data.id,
        name: data.name,
        external_id: data.external_id,
        description: data.description,
        disabled: data.disabled,
        creationTimeFrom: Services.localDateToString(data.creationTimeFrom),
        creationTimeTo: Services.localDateToString(data.creationTimeTo),
        updateTimeFrom: Services.localDateToString(data.updateTimeFrom),
        updateTimeTo: Services.localDateToString(data.updateTimeTo),
      };
    }

  }

}
