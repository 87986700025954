<div class="row m-0">
  <div class="card-custom-padding-complete col-md-{{12/stats.length}} col-sm-{{12/stats.length*2}}" *ngFor="let stat of stats">
    <div class="card clickable-card" (click)="navigateToBugReportList(stat.states)">
      <div class="card-body d-flex align-items-center">
        <div class="overview-icon-container mr-1 icon-container-{{stat.color}}">
          <i class="icomoon {{stat.icon}}"></i>
        </div>
        <div>
          <div class="overview-title">{{stat.title | translate}}</div>
          <div class="overview-counter">{{model[stat.key]}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
