/* eslint-disable */
import { UserProfile } from '../../lib/auth.service';
/* eslint-enable */

export class RecipientsModel {
  user_profiles: UserProfile[] = [];
  user_group_ids: number[] = [];
  mobile_app_ids: number[] = [];
  userNamesAsString: string = '';
  userGroupNamesAsString: string = '';
  mobileAppNamesAsString: string = '';
}

export class RecipientsCreateModel {
  user_ids: number[] = [];
  user_group_ids: number[] = [];
  mobile_app_ids: number[] = [];
}

export class MessageListModel {
  id: number = -1;
  title: string = '';
  content: string = '';
  sent_date: string = '';
  creation_time: string = '';
  recipients: RecipientsModel = new RecipientsModel();
  relevantRecipientName: string = '';
}

export class MessageReadModel {
  title: string = '';
  content: string = '';
  sent_date: string = '';
  recipients: RecipientsModel = new RecipientsModel();
}

export class MessageCreateModel extends MessageReadModel {
  sendToAll: boolean = false;
}
