/* eslint-disable */
import { Dates, LocalDate, } from '../util/dates';
import { Observable, Observer } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Order, OrderType, Services, } from '../util/services';
import { MemStorage } from '../util/mem-storage';
import { Injectable } from '@angular/core';
import { UiConstants } from '../../util/core-utils';
import { ShippingDemand } from './shipping-demand.service';
import { TaskRecordStateMachine } from '../task/task-record-statemachine';
import { RuntimeConfiguration } from '../runtime-configuration';
import { LoggedInUserStorage } from '../util/storages';
/* eslint-enable */

@Injectable()
export class ShippingDemandSearchService {

  constructor() {
  }

  getSearchData(request: ShippingDemandSearch.SearchDataGetRequest): Observable<ShippingDemandSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<ShippingDemandSearch.SearchDataResult>) => {
      try {
        observer.next(storage.read(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  setSearchData(request: ShippingDemandSearch.SearchDataSetRequest): Observable<ShippingDemandSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<ShippingDemandSearch.SearchDataResult>) => {
      try {
        observer.next(storage.save(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  resetSearchData(request: ShippingDemandSearch.SearchDataGetRequest): Observable<ShippingDemandSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<ShippingDemandSearch.SearchDataResult>) => {
      try {
        observer.next(storage.reset(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  private createStorage(): ShippingDemandSearch.Storage {
    return new ShippingDemandSearch.Storage();
  }

}

export namespace ShippingDemandSearch {



  export interface SearchDataGetRequest {
  }

  export interface SearchDataSetRequest {
    searchData: SearchData;
  }

  export interface SearchDataResult {
    searchData: SearchData;
  }

  export interface SearchData {
    itemsPerPage: number;
    pageNumber: number;
    order: Order<ShippingDemand.OrderField>;
    dqlText: string | undefined;
    id: string;
    tourRoute: string;
    transporterName: string;
    demandCategory: string;
    sourceName: string;
    sourceAddress: string;
    destinationName: string;
    destinationAddress: string;
    deadlineFrom: LocalDate;
    deadlineTo: LocalDate;
    stateIds: StateItem[];
    safetyShipping?: boolean;
    hasTransport?: boolean;
    shippingItem: string;
  }

  interface StoredSearchRawDataBase {
    version: number;
  }

  interface StoredSearchRawData extends StoredSearchRawDataBase {
    itemsPerPage: number;
    pageNumber: number;
    order: Order<ShippingDemand.OrderField>;
    dqlText: string | undefined;
    id: string;
    tourRoute: string;
    transporterName: string;
    demandCategory: string;
    sourceName: string;
    sourceAddress: string;
    destinationName: string;
    destinationAddress: string;
    deadlineFrom?: string;
    deadlineTo?: string;
    stateIds: StateItem[];
    safetyShipping?: boolean;
    hasTransport?: boolean;
    shippingItem: string;
  }

  export class Storage {

    /**
     * Increment this version if you change the SearchData interface.
     */
    private static readonly VERSION: number = 6;

    private static readonly DEFAULT_SEARCH_DATA: SearchData = {
      itemsPerPage: UiConstants.pageNumbers[0],
      pageNumber: 1,
      order: {
        field: ShippingDemand.OrderField.DEMAND_ID,
        type: OrderType.DESC
      },
      dqlText: undefined,
      id: '',
      tourRoute: '',
      transporterName: '',
      demandCategory: '',
      sourceName: '',
      sourceAddress: '',
      destinationName: '',
      destinationAddress: '',
      deadlineFrom: Dates.emptyLocalDate(),
      deadlineTo: Dates.emptyLocalDate(),
      stateIds: [],
      safetyShipping: undefined,
      hasTransport: undefined,
      shippingItem: ''
    };

    constructor() {
    }

    public read(request: SearchDataGetRequest): SearchDataResult {
      const key = this.generateKey();
      let rawBase: StoredSearchRawDataBase;
      try {
        const json = MemStorage.getItem(key);
        if (!json) {
          return this.createDefaultResult();
        }
        rawBase = JSON.parse(json);
      }
      catch (error) {
        return this.createDefaultResult();
      }
      if (Storage.VERSION === rawBase.version) {
        const raw: StoredSearchRawData = <StoredSearchRawData> rawBase;
        return this.fromRaw(raw);
      }
      return this.createDefaultResult();
    }

    public reset(request: SearchDataGetRequest): SearchDataResult {
      return this.save({
        searchData: Storage.DEFAULT_SEARCH_DATA,
      });
    }

    public save(request: SearchDataSetRequest): SearchDataResult {
      const key = this.generateKey();
      const raw = this.toRaw(request.searchData);
      const json = JSON.stringify(raw);
      MemStorage.setItem(key, json);
      return this.read(request);
    }

    private generateKey(): string {
      return 'UserId(' + LoggedInUserStorage.getInstance().getUserId() + ')-Search-Shipping-Demand';
    }

    private createDefaultResult(): SearchDataResult {
      return {
        searchData: Storage.DEFAULT_SEARCH_DATA,
      };
    }

    private fromRaw(data: StoredSearchRawData): SearchDataResult {
      return {
        searchData: {
          itemsPerPage: data.itemsPerPage,
          pageNumber: data.pageNumber,
          order: data.order,
          dqlText: data.dqlText,
          id: data.id,
          tourRoute: data.tourRoute,
          transporterName: data.transporterName,
          demandCategory: data.demandCategory,
          sourceName: data.sourceName,
          sourceAddress: data.sourceAddress,
          destinationName: data.destinationName,
          destinationAddress: data.destinationAddress,
          deadlineFrom: Services.toLocalDate(data.deadlineFrom),
          deadlineTo: Services.toLocalDate(data.deadlineTo),
          stateIds: data.stateIds,
          safetyShipping: data.safetyShipping,
          hasTransport: data.hasTransport,
          shippingItem: data.shippingItem,
        }
      };
    }

    private toRaw(data: SearchData): StoredSearchRawData {
      return {
        version: Storage.VERSION,
        itemsPerPage: data.itemsPerPage,
        pageNumber: data.pageNumber,
        order: data.order,
        dqlText: data.dqlText,
        id: data.id,
        tourRoute: data.tourRoute,
        transporterName: data.transporterName,
        demandCategory: data.demandCategory,
        sourceName: data.sourceName,
        sourceAddress: data.sourceAddress,
        destinationName: data.destinationName,
        destinationAddress: data.destinationAddress,
        deadlineFrom: Services.localDateToString(data.deadlineFrom),
        deadlineTo: Services.localDateToString(data.deadlineTo),
        stateIds: data.stateIds,
        safetyShipping: data.safetyShipping,
        hasTransport: data.hasTransport,
        shippingItem: data.shippingItem,
      }
        ;
    }

  }

  export class StateItem {
    id: string;
    name: string;

    get itemName() {
      return this.name;
    }
  }

}
