import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StockItemModel } from '../form-record-stock-field.component';

@Component({
  selector: 'app-form-record-stock-item-custom-price-edit',
  templateUrl: './form-record-stock-item-custom-price-edit.component.html',
  styleUrls: ['./form-record-stock-item-custom-price-edit.component.scss']
})
export class FormRecordStockItemCustomPriceEditComponent implements OnInit {

  @Input()
  discount?: number | null;
  @Input()
  model: StockItemModel;
  @Input()
  disabled: boolean = false;
  @Input()
  currencyCodeString: string = '';

  @Output()
  onPriceChanged: EventEmitter<any> = new EventEmitter();

  @Output()
  onCloseButtonClicked: EventEmitter<any> = new EventEmitter();

  vat?: number;
  netReducedPrice?: number;
  grossReducedPrice?: number;

  constructor() { }

  ngOnInit() {
    this.loadModel();
  }

  setGrossPrice() {
    if (!this.netReducedPrice) {
      this.grossReducedPrice = undefined;
      return;
    }
    if (this.vat !== undefined) {
      this.grossReducedPrice = parseFloat((this.netReducedPrice! * (1 + this.vat! / 100)).toFixed(2));
    }
  }

  setNetPrice() {
    if (!this.grossReducedPrice) {
      this.netReducedPrice = undefined;
      return;
    }
    if (this.vat !== undefined) {
      this.netReducedPrice = parseFloat((this.grossReducedPrice! / (1 + this.vat! / 100)).toFixed(6));
    }
  }

  close() {
    this.onCloseButtonClicked.emit();
  }

  save() {
    this.model.customNetPrice = this.netReducedPrice !== undefined && this.netReducedPrice !== null
      ? this.netReducedPrice.toString() : undefined;
    this.onPriceChanged.emit();
  }

  private loadModel() {
    this.netReducedPrice = this.model.customNetPrice !== undefined ? parseFloat(this.model.customNetPrice) : undefined;
    this.vat = this.model.stockRecord.stockItem.unit_price!.vat_rate;
    if (this.netReducedPrice !== undefined) {
      this.setGrossPrice();
    }
  }
}
