/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { FieldError, Services } from '../../util/services';
import { List, Map as ImmutableMap, Set } from 'immutable';
import { TransportTaskResource, TransportTaskResourceService } from './transport-task-resource.service';
import { Address, AddressResource } from '../../address';
import { PhoneNumber } from '../../util/phone-number';
import { ShippingDemand, ShippingDemandMapper } from '../../shipping-demand/shipping-demand.service';
import { EmptyMessage } from '../../util/messages';
import { OffsetDateTime } from '../../util/dates';
import { Shipment } from '../../shipment-group/shipment-group.service';
import GeocodeStatus = AddressResource.GeocodeStatus;

/* eslint-enable */

@Injectable()
export class TransportTaskService implements TransportTask.Service {

  constructor(private resourceService: TransportTaskResourceService) {
  }

  query(request: TransportTask.QueryRequest): Observable<List<TransportTask.TransportTask>> {
    return Observable.create((observer: Observer<List<TransportTask.TransportTask>>) => {
      const resourceRequest: TransportTaskResource.QueryRequest = {
        transport_id: request.transportId,
      };
      return this.resourceService.query(resourceRequest).subscribe(
        (result: TransportTaskResource.TransportTask[]) => {
          observer.next(
            this.toPublicList(result));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  get(request: TransportTask.GetRequest): Observable<TransportTask.TransportTask> {
    return Observable.create((observer: Observer<TransportTask.TransportTask>) => {
      const resourceRequest: TransportTaskResource.GetRequest = {
        transport_id: request.transportId,
        id: request.id
      };
      return this.resourceService.get(resourceRequest).subscribe(
        (result: TransportTaskResource.TransportTask) => {
          observer.next(TransportTask.ToPublicMapper.toPublic(result));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  create(request: TransportTask.CreateRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: TransportTaskResource.CreateRequest = {
        transport_id: request.transportId,
        index: request.index,
        shipping_place: ShippingDemandMapper.toResourceSourceDestination(request.shippingPlace)
      };
      return this.resourceService.create(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  moveTask(request: TransportTask.MoveTaskRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: TransportTaskResource.MoveTaskRequest = {
        transport_id: request.transportId,
        task_id: request.taskId,
        new_index: request.newIndex
      };
      return this.resourceService.moveTask(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  moveShipment(request: TransportTask.MoveShipmentRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: TransportTaskResource.MoveShipmentRequest = {
        transport_id: request.transportId,
        task_id: request.taskId,
        shipment_id: request.shipmentId,
        new_task_id: request.newTaskId
      };
      return this.resourceService.moveShipment(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  deleteTask(request: TransportTask.GetRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: TransportTaskResource.GetRequest = {
        transport_id: request.transportId,
        id: request.id
      };
      return this.resourceService.deleteTask(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  replanRouteAfter(request: TransportTask.GetRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: TransportTaskResource.GetRequest = {
        transport_id: request.transportId,
        id: request.id
      };
      return this.resourceService.replanRouteAfter(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  optimizeWaypoints(request: TransportTask.WaypointOptimizationRequest): Observable<TransportTask.WaypointOptimizationResponse> {
    return Observable.create((observer: Observer<TransportTask.WaypointOptimizationResponse>) => {
      const resourceRequest: TransportTaskResource.WaypointOptimizationRequest = {
        transport_id: request.transportId,
        from_index: request.fromIndex,
        to_index: request.toIndex
      };
      return this.resourceService.optimizeWaypoints(resourceRequest).subscribe(
        (result: TransportTaskResource.WaypointOptimizationResponse) => {
          observer.next({
            durationSec: result.duration_sec,
            distanceMeter: result.distance_meter,
            polyline: result.polyline,
            consistencyErrorMessages: result.consistency_error_messages
          });
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  updateTimetable(request: TransportTask.TimetableUpdateRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: TransportTaskResource.TimetableUpdateRequest = {
        transport_id: request.transportId,
        id: request.id,
        planned_estimated_time_of_arrival: Services.offsetDateTimeToString(request.plannedETA)!,
        planned_estimated_time_of_departure: Services.offsetDateTimeToString(request.plannedETD)!,
      };
      return this.resourceService.updateTimetable(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }


  private toPublicList(resourceList: TransportTaskResource.TransportTask[]): List<TransportTask.TransportTask> {
    return List.of(...resourceList.map((r) => TransportTask.ToPublicMapper.toPublic(r)));
  }

}


export namespace TransportTask {

  export interface Service {

    // <editor-fold desc="CRUD">

    query(request: QueryRequest): Observable<List<TransportTask>>;

    get(request: GetRequest): Observable<TransportTask>;

    // </editor-fold>

  }

  export interface TransportTask {
    id: number;
    index: number;
    state: TransportTaskState;
    shippingPlace: ShippingPlace;
    shipments: Shipment[];
    timetable: Timetable;
  }

  export interface ShippingPlace {
    id: number;
    placeId: number;
    name: string;
    postalAddress: Address.PostalAddressData;
    coordinate?: Address.Coordinate;
    geocodeStatus: AddressResource.GeocodeStatus;
    stockId?: number;
    locationId?: number;
  }

  export interface Shipment {
    id: number;
    state: Shipment.ShipmentState;
    deliveryNoteNumber: string;
    demanderCompany?: Company;
    outtakeId?: number;
    ekaer?: string;
    recipient?: Person;
    transferor?: Person;
    type: ShipmentType;
  }

  export interface Person {
    name: string;
    phoneNumber: Set<PhoneNumber>;
  }

  export interface Company {
    name: string;
    phoneNumber: PhoneNumber;
  }

  export interface Timetable {
    plannedETA: OffsetDateTime;
    plannedETD: OffsetDateTime;
    currentETA: OffsetDateTime;
    currentETD: OffsetDateTime;
    realArrivalTime: OffsetDateTime;
    realDepartureTime: OffsetDateTime;
    delayMinutes?: number;
  }

  export interface QueryRequest {
    transportId: number;
  }

  export interface GetRequest {
    transportId: number;
    id: number;
  }

  export interface CreateRequest {
    transportId: number;
    index: number;
    shippingPlace: ShippingDemand.SourceDestination;
  }

  export interface MoveTaskRequest {
    transportId: number;
    taskId: number;
    newIndex: number;
  }

  export interface WaypointOptimizationRequest {
    transportId: number;
    fromIndex: number;
    toIndex: number;
  }

  export interface WaypointOptimizationResponse {
    durationSec: number;
    distanceMeter: number;
    polyline: string;
    consistencyErrorMessages?: Map<string, FieldError>;
  }

  export interface MoveShipmentRequest {
    transportId: number;
    taskId: number;
    shipmentId: number;
    newTaskId: number;
  }

  export interface TimetableUpdateRequest {
    transportId: number;
    id: number;
    plannedETA: OffsetDateTime;
    plannedETD: OffsetDateTime;
  }

  export type TransportTaskState = 'QUEUED' | 'ARRIVED' | 'GONE';

  export type ShipmentType = 'HANDOVER' | 'TAKEOVER' | 'STOCK_TAKEOVER';

  export class TransportTaskStateObject {
    type: TransportTaskState;
    iconClass: string;
    stringKey: string;
  }

  export const transportTaskStates: ImmutableMap<TransportTaskState, TransportTaskStateObject> = ImmutableMap.of(
    'QUEUED', {type: 'QUEUED', iconClass: '', stringKey: 'TRANSPORT_TASK_STATE_QUEUED'},
    'ARRIVED', {type: 'ARRIVED', iconClass: 'icomoon-shipment-in-progress', stringKey: 'TRANSPORT_TASK_STATE_ARRIVED'},
    'GONE', {type: 'GONE', iconClass: 'icomoon-visible-on-detail-true', stringKey: 'TRANSPORT_TASK_STATE_GONE'},
  );

  export class ShipmentTypeObject {
    type: ShipmentType;
    iconClass: string;
    stringKey: string;
  }

  export const shipmentTypes: ImmutableMap<ShipmentType, ShipmentTypeObject> = ImmutableMap.of(
    'HANDOVER', {type: 'HANDOVER', iconClass: 'icomoon-package-handover', stringKey: 'SHIPMENT_TYPE_HANDOVER'},
    'TAKEOVER', {type: 'TAKEOVER', iconClass: 'icomoon-package-takeover', stringKey: 'SHIPMENT_TYPE_TAKEOVER'},
    'STOCK_TAKEOVER', {
      type: 'STOCK_HANDOVER',
      iconClass: 'icomoon-any-stocks',
      stringKey: 'SHIPMENT_TYPE_STOCK_TAKEOVER'
    },
  );

  export class ToPublicMapper {
    static toPublic(r: TransportTaskResource.TransportTask): TransportTask.TransportTask {
      return {
        id: r.id,
        index: r.index,
        state: <TransportTask.TransportTaskState>r.state,
        shippingPlace: this.toPublicShippingPlace(r.shipping_place),
        shipments: r.shipments.map((s: TransportTaskResource.ShipmentData) => this.toPublicShipment(s, r.shipping_place.stock_id)),
        timetable: this.toPublicTimeTable(r.timetable)
      };
    }

    static toPublicTimeTable(r: TransportTaskResource.TimetableData): TransportTask.Timetable {
      return {
        plannedETA: Services.toOffsetDateTime(r.planned_estimated_time_of_arrival),
        plannedETD: Services.toOffsetDateTime(r.planned_estimated_time_of_departure),
        currentETA: Services.toOffsetDateTime(r.current_estimated_time_of_arrival),
        currentETD: Services.toOffsetDateTime(r.current_estimated_time_of_departure),
        realArrivalTime: Services.toOffsetDateTime(r.real_arrival_time),
        realDepartureTime: Services.toOffsetDateTime(r.real_departure_time),
        delayMinutes: r.delay_minutes
      };
    }

    static toPublicShippingPlace(r: TransportTaskResource.ShippingPlaceData): TransportTask.ShippingPlace {
      return {
        id: r.id,
        placeId: r.place_id,
        name: r.name,
        postalAddress: AddressResource.Mapper.toPublicPostalAddress(r.postal_address),
        coordinate: AddressResource.Mapper.toPublicCoordinate(r.coordinate),
        geocodeStatus: <GeocodeStatus>r.geocode_status,
        stockId: r.stock_id,
        locationId: r.location_id
      };
    }

    static toPublicShipment(r: TransportTaskResource.ShipmentData, hasStock?: number): TransportTask.Shipment {
      let type = <TransportTask.ShipmentType>r.type;
      if (type === 'TAKEOVER' && hasStock) {
        type = 'STOCK_TAKEOVER';
      }
      return {
        id: r.id,
        state: <Shipment.ShipmentState>r.state,
        demanderCompany: this.toPublicCompany(r.demander_company),
        deliveryNoteNumber: r.delivery_note_number,
        transferor: this.toPublicPerson(r.transferor),
        outtakeId: r.outtake_id,
        ekaer: r.ekaers ? r.ekaers.join(', ') : undefined,
        recipient: this.toPublicPerson(r.recipient),
        type: type
      };
    }

    static toPublicCompany(r?: TransportTaskResource.CompanyData): TransportTask.Company | undefined {
      return r ? {
        name: r.name,
        phoneNumber: Services.toPhoneNumber(r.phone_number)
      } : undefined;
    }

    static toPublicPerson(r?: TransportTaskResource.PersonData): TransportTask.Person | undefined {
      return r ? {
        name: r.name,
        phoneNumber: Set.of(...r.phone_numbers.map(p => Services.toPhoneNumber(p)))
      } : undefined;
    }
  }
}
