import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { RuntimeConfiguration } from './app/lib/runtime-configuration';
import { Strings } from './app/lib/util/strings';

(function () {

  function bootstrapApplication() {
    platformBrowserDynamic().bootstrapModule(AppModule)
  }

  /**
   * WARNING: When you create a new property, do not forget to put it into environment.sample/config.json!
   *          Please, update the docker config files too.
   */
  function parseResponse(data) {
    const brandLogoSrc = data['brandLogoSrc'];
    const brandLogoLoginSrc = data['brandLogoLoginSrc'];
    const brandLogoSmallSrc = data['brandLogoSmallSrc'];
    const loginBackgroundSrc = data['loginBackgroundSrc'];
    const faviconPngSrc = data['faviconPngSrc'];
    const baseUrl = Strings.trimRight(data[ 'baseUrl' ], '/');
    const googleMapsApiKey = data['googleMapsApiKey'];
    const recaptchaV2SiteKey = data['recaptchaV2SiteKey'];
    const recaptchaV3SiteKey = data['recaptchaV3SiteKey'];
    const showBackgroundErrors = data['showBackgroundErrors'];
    const dummyPostalAddress = data['dummyPostalAddress'];
    const firebase = data['firebase'];
    const registrationConversionCode = data['registrationConversionCode'];

    return new RuntimeConfiguration(
      brandLogoSrc,
      brandLogoLoginSrc,
      brandLogoSmallSrc,
      loginBackgroundSrc,
      faviconPngSrc,
      baseUrl,
      googleMapsApiKey,
      recaptchaV2SiteKey,
      recaptchaV3SiteKey,
      showBackgroundErrors,
      dummyPostalAddress,
      firebase,
      registrationConversionCode
    );
  }

  function main() {
    if (environment.production) {
      enableProdMode();
    }
    const xhr = new XMLHttpRequest();
    xhr.open('GET', 'environment/config.json');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.onload = function() {
      if (xhr.status === 200) {
        try {
          const data = JSON.parse(xhr.responseText);
          const cfg = parseResponse(data);
          RuntimeConfiguration.init(cfg);
          bootstrapApplication();
        }
        catch (e) {
          bootstrapApplication();
        }
      }
      else {
        bootstrapApplication();
      }
    };
    xhr.onerror = function() {
      bootstrapApplication();
    };
    xhr.send();
  }

  main();

}());
