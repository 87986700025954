/* eslint-disable */
import { Component } from '@angular/core';
import { Form } from '../../../../../lib/form/form.service';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import {
  FormRecordFieldContext,
  FormRecordFieldValueUpdateArgs,
  FormRecordFieldView,
  FormRecordFieldViewContext,
  FormRecordFieldViewModel,
  SetTmpReadonlyArgs,
  SetTmpReadonlyResult
} from '../../../../../util/form/form-utils';
import { FormRef, ForwardingFormRef, LocalFormGroupValidationErrors } from '../../../../../lib/util/services';
import { FieldActivationState, FieldActivationStateResolver } from '../../../../../util/form/form-editors';
import { Command } from '../../../../../util/command';
import { Models } from '../../../../../util/model-utils';
import { FormRecord } from '../../../../../lib/form/form-record.service';
import { AppValidators } from '../../../../../util/app-validators';
import { StringKey } from '../../../../../app.string-keys';
import { OptionItem } from '../../../../../util/core-utils';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from '../../../../../lib/settings.service';
import { FieldPaymentType } from '../../../../../util/form/form-field-payment-type';
import { Invoice } from '../../../../../lib/invoice/invoice/invoice.service';
import { ofCondition } from '../../../../../util/wait';
import { FormRecordInactivityManager } from '../../manager/form-record-inactivity-manager';
import {
  FieldNotification,
  FormRecordFieldNotifierService
} from '../../../../../lib/form/form-record-field-notifier.service';
import { Observable } from 'rxjs';

/* eslint-enable */

@Component({
  selector: 'app-form-record-payment-type-field',
  templateUrl: './form-record-payment-type-field.component.html',
  styleUrls: ['./form-record-payment-type-field.component.scss']
})
export class FormRecordPaymentTypeFieldComponent implements FormRecordFieldView {

  public readonly selector: Form.FieldDataTypeSelector.PAYMENT_TYPE;

  formGroup: FormGroup;

  model: Model = new Model();

  // context fields are always optional
  formRecordFieldContext?: FormRecordFieldContext;
  formRecordInactivityManager?: FormRecordInactivityManager;
  private groupId?: number;
  private fieldId?: number;
  private htmlForm?: FormRef;

  tmpReadonly: boolean = false;

  private optionalValue: boolean = false;

  listItems: ListItem[] = [];

  private formGroupValidationErrors: LocalFormGroupValidationErrors;

  private readonlyFormFn: () => boolean = () => true;
  private readonlyFieldFn: () => boolean = () => false;
  private hiddenFieldFn: () => boolean = () => false;
get loaded(): boolean {
    // NOTE: +1 is the default unselected item
    return this.reqAttrs.validInvoicePaymentTypes.size + 1 === this.listItems.length;
  }

  get inactive(): boolean {
    return this.tmpReadonly;
  }

  get nonEditable(): boolean {
    return FieldActivationStateResolver.isNonEditable(
      this.fieldActivationState
    );
  }

  get requiredDisabled(): boolean {
    return FieldActivationStateResolver.isRequiredDisabled(
      this.fieldActivationState
    );
  }

  get required(): boolean {
    const optional = this.optionalValue;
    const requiredDisabled = this.requiredDisabled;
    return !optional && !requiredDisabled;
  }


  private get fieldActivationState(): FieldActivationState {
    return FieldActivationStateResolver.resolveFieldActivationState({
      readonlyFormFn: () => this.readonlyFormFn(),
      readonlyFieldFn: () => this.readonlyFieldFn(),
      tmpReadonlyFieldFn: () => this.tmpReadonly,
    });
  }

  private get canApplyDefaultValue(): boolean {
    return FieldActivationStateResolver.canApplyDefaultValue({
      formRecordFieldContext: () => {
        return this.formRecordFieldContext;
      },
      fieldActivationState: () => {
        return this.fieldActivationState;
      },
    });
  }

  private get valueFormControl(): AbstractControl {
    return this.formGroup.get('value')!;
  }

  private get reqContext() {
    return this.formRecordFieldContext!;
  }

  private get reqAttrs(): Form.FieldDataTypePaymentTypeAttributes {
    return this.reqContext.field.dataType.paymentTypeAttributes!;
  }

  private get reqDefaultValue(): ListItem {
      return this.reqEmpty;
  }

  private get reqEmpty(): ListItem {
    return this.listItems[0];
  }

  constructor(fb: FormBuilder,
              private translateService: TranslateService,
              private formRecordFieldNotifierService: FormRecordFieldNotifierService,
              private settingsService: SettingsService) {
    this.formGroup = this.createFormGroup(fb);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(
      this.createForwardingHtmlForm(),
      this.formGroup
    );
  }

  setTmpReadonly(args: SetTmpReadonlyArgs): Command<SetTmpReadonlyResult> {
    let previousTmpReadonly: boolean;
    let previousValue: ListItem;
    return {
      execute: async () => {
        await ofCondition({
          condition: () => {
            return this.loaded;
          }
        });
        previousTmpReadonly = this.tmpReadonly;
        previousValue = this.model.value!;
        const inactiveValue: ListItem = this.reqEmpty;
        const defaultValue: ListItem = this.reqDefaultValue;
        let changed = false;
        if (this.tmpReadonly !== args.tmpReadonly) {
          if (args.tmpReadonly) {
            changed = FieldActivationStateResolver.inactivationChangesTheValue({
              debugId: this.reqContext.field.title,
              valueIsEmpty: this.model.value === inactiveValue,
              valueEqualsDefaultValue: this.model.value === defaultValue,
              defaultValueIsEmpty: defaultValue === inactiveValue,
              canApplyDefaultValue: this.canApplyDefaultValue
            });
            this.model.value = inactiveValue;
            this.tmpReadonly = args.tmpReadonly; // last
          }
          else {
            this.tmpReadonly = args.tmpReadonly; // first
            this.applyDefaultValue();
          }
          this.valueFormControl.updateValueAndValidity();
        }
        return {
          changed: changed
        };
      },
      undo: async () => {
        await ofCondition({
          condition: () => {
            return this.loaded;
          }
        });
        this.tmpReadonly = previousTmpReadonly;
        this.model.value = previousValue;
        this.valueFormControl.updateValueAndValidity();
      }
    };
  }

  registerField(context: FormRecordFieldContext, originalModel?: any): any {
    if (originalModel) {
      this.model = originalModel;
    }
    this.formRecordFieldContext = context;
    this.groupId = context.group.groupId;
    this.fieldId = context.field.fieldId;
    this.htmlForm = context.htmlForm;
    this.readonlyFormFn = context.readonly;
    this.hiddenFieldFn = () => Form.FormFieldValidationType.HIDDEN === context.validationType;
    this.readonlyFieldFn = () => Form.FormFieldValidationType.READONLY === context.validationType
    || Form.FormFieldValidationType.HIDDEN === context.validationType;
    this.optionalValue = Form.FormFieldValidationType.REQUIRED !== context.validationType;
    this.model.title = context.field.title;
    this.model.hint = Models.optToString(context.field.hint);
    this.loadFieldData();
    return this.model;
  }

  private loadFieldData() {
    const context = this.reqContext;
    if (this.canApplyDefaultValue) {
      this.setValueBeforeLoad(undefined);
    }
    if (context.fieldRecord) {
      const dataAttrs = context.fieldRecord.data.paymentTypeAttributes!;
      this.setValueBeforeLoad(dataAttrs.paymentType);
    }
    this.loadListItems();
  }

  private setValueBeforeLoad(value?: FieldPaymentType) {
    this.model.prevValueId = FieldPaymentType.toStringValue(value);
  }

  private applyDefaultValue() {
    if (this.canApplyDefaultValue) {
      this.model.value = this.reqDefaultValue;
    }
  }

  registerFieldViews(context: FormRecordFieldViewContext): void {
    this.formRecordInactivityManager = context.inactivityManager;
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  validateWithInterrupt(): boolean {
    return false;
  }

  shouldNotifyAfterCreation(): boolean {
    return false;
  }

  afterFormRecordCreation(formRecordId: number): Observable<any> | undefined {
    return undefined;
  }

  createModel(): FormRecordFieldViewModel {
    if (this.fieldId === undefined) {
      throw new Error('Field ID is undefined');
    }
    const attrs: FormRecord.FieldDataPaymentTypeAttributes = {
      paymentType: this.model.value ? this.model.value.id ? FieldPaymentType.fromStringValue(this.model.value.id) : undefined : undefined
    };
    return {
      fieldEditRequest: {
        fieldId: this.fieldId,
        data: {
          paymentTypeAttributes: attrs
        }
      }
    };
  }

  onManualChange() {
    this.formGroup.controls.value.updateValueAndValidity();
    this.formRecordInactivityManager!.onGeneralFieldChangedByUser(this);
  }

  onModelChange() {
    const notification: FieldNotification = {
      groupId: this.groupId!,
      fieldId: this.fieldId!,
      data: {
        selectedPaymentType: this.model.value ? this.model.value.id : null
      }
    };
    this.formRecordFieldNotifierService.notify(notification);
  }

  private createForwardingHtmlForm() {
    return new ForwardingFormRef({
      formFn: () => {
        return this.htmlForm;
      }
    });
  }

  private createFormGroup(fb: FormBuilder) {
    return fb.group(
      {
        value: fb.control(
          {value: this.model.value},
          [
            AppValidators.tempValidator({
              validator: AppValidators.requiredListItem(() => this.model.value),
              disabled: () => {
                return !this.required;
              }
            })
          ]
        )
      }
    );
  }

  private loadListItems() {
    const validTypes = this.reqAttrs.validInvoicePaymentTypes;
    this.listItems = [];
    const def = this.createDefItem();
    this.listItems.push(def); // ALWAYS the first item! Required for inactive state.
    this.model.value = def;
    FieldPaymentType.VALUES.toArray().forEach(item => {
      this.translateService.get(item.textDictionaryKey).subscribe(
        (text: string) => {
          const i = {
            id: item.value!,
            text: text
          };
          if (validTypes.includes(item)) {
            this.listItems.push(i);
          }
          if (this.model.prevValueId && this.model.prevValueId === i.id) {
            this.model.value = i;
          }
        }
      );
    });
  }

  private createDefItem(): ListItem {
    const def = new ListItem();
    def.id = null;
    def.text = '';
    this.translateService.get(StringKey.COMMON_VALUE_UNSELECTED).subscribe(
      (text: string) => {
        def.text = text;
      }
    );
    return def;
  }

  updateValue(data: FormRecordFieldValueUpdateArgs) {
  }

}

export class Model {
  title: string = '';
  hint: string = '';
  prevValueId?: string = undefined;
  value: ListItem | null = null;
}

export class ListItem extends OptionItem<Invoice.InvoicePaymentType> {
}
