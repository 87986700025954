/* eslint-disable */
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { QueryModel } from '../../../../util/core-utils';
import { TaskRecord, TaskRecordService } from '../../../../lib/task/task-record.service';
import { QueryResult } from '../../../../lib/util/services';
import { UserService } from '../../../../lib/user.service';
import { Address } from '../../../../lib/address';
import { TaskRecordHistoryMapComponent } from './task-record-history-map/task-record-history-map.component';

/* eslint-enable */

@Component({
  selector: 'app-task-record-history',
  templateUrl: './task-record-history.component.html',
  styleUrls: ['./task-record-history.component.scss']
})
export class TaskRecordHistoryComponent implements OnInit, AfterViewInit {
  pagingId = 'taskRecordHistoryList';

  @ViewChild('historyMapComponent', { static: true })
  historyMapComponent: TaskRecordHistoryMapComponent;

  @Input()
  taskRecordId: number;

  @Input()
  taskId: number;

  taskRecordHistoryList: TaskRecordHistoryModel[] = [];

  queryModel: QueryModel = new QueryModel();

  selectedIndex?: number;

  constructor(private taskRecordService: TaskRecordService) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.loadList();
  }

  loadList(pageNumber?: number) {
    const requestedPage: number = pageNumber ? pageNumber : this.queryModel.currentPage;
    this.taskRecordService.getLogHistory({
      taskRecordId: this.taskRecordId,
      taskId: this.taskId,
      paging: {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      }
    }).subscribe((result: QueryResult<TaskRecord.Log>) => {
      this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
      this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.queryModel.currentPage = requestedPage;
      this.taskRecordHistoryList = result.items.toArray().map(r => {
        return {
          id: r.id,
          changeType: TaskRecord.LogTypeMap.get(r.logType),
          changeTime: r.logTime.toUtcIsoString(),
          userHumanName: r.user.personName,
          message: r.message,
          coordinate: r.coordinate,
          taskRecord: r.taskRecord
        };
      });
    });
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  highlightTableRow(index: number) {
    this.selectedIndex = index;
    setTimeout(() => {
      this.selectedIndex = undefined;
    }, 2000);
  }

}

export interface TaskRecordHistoryModel {
  id: number;
  changeType: string;
  changeTime: string;
  userHumanName: string;
  message?: string;
  coordinate?: Address.Coordinate;
  taskRecord?: {
    id: number;
    name: string;
    externalId: string;
    taskId: number;
  }
}
