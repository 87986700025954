/* eslint-disable */
import { Component, ComponentRef, HostBinding, ViewChild, } from '@angular/core';
import { CssStyle } from '../../../../../util/host-styles';
import {
  FormRecordFieldContext,
  FormRecordFieldValueUpdateArgs,
  FormRecordFieldView,
  FormRecordFieldViewContext,
  FormRecordFieldViewModel,
  SetTmpReadonlyArgs,
  SetTmpReadonlyResult,
} from '../../../../../util/form/form-utils';
import { Form } from '../../../../../lib/form/form.service';
import { FormRecordFieldWrapperHolderDirective } from './form-record-field-wrapper-holder.directive';
import { Command } from '../../../../../util/command';
import { Observable } from 'rxjs';
import FormFieldValidationType = Form.FormFieldValidationType;

/* eslint-enable */

@Component({
  selector: 'app-form-record-field-wrapper',
  templateUrl: 'form-record-field-wrapper.component.html',
  styleUrls: ['form-record-field-wrapper.component.scss'],
})
export class FormRecordFieldWrapperComponent
  implements FormRecordFieldView, CssStyle.PropertySetterFnProvider {

  delegate: ComponentRef<FormRecordFieldView>;

  @ViewChild(FormRecordFieldWrapperHolderDirective, {static: true})
  fieldHost: FormRecordFieldWrapperHolderDirective;

  // <editor-fold desc="Host style binding">

  @HostBinding('style.flex')
  hostStyleFlexAttribute?: string;

  @HostBinding('style.max-width')
  hostStyleMaxWidthAttribute?: string;

  @HostBinding('style.padding')
  hostStylePaddingAttribute?: string;

  @HostBinding('style.display')
  get hostStyleDisplay(): string | undefined {
    if (this.isHidden()) {
      return 'none';
    }
    return '';
  }

  getPropertySetterFn(property: CssStyle.Property): CssStyle.PropertySetterFn | undefined {
    switch (property) {
      case CssStyle.Property.flex: {
        return (propertyValue?: string) => {
          this.hostStyleFlexAttribute = propertyValue;
        };
      }
      case CssStyle.Property.maxWidth: {
        return (propertyValue?: string) => {
          this.hostStyleMaxWidthAttribute = propertyValue;
        };
      }
      case CssStyle.Property.padding: {
        return (propertyValue?: string) => {
          this.hostStylePaddingAttribute = propertyValue;
        };
      }
    }
    return undefined;
  }

  // </editor-fold>

  // <editor-fold desc="FormRecordFieldView delegation">

  get tmpReadonly(): boolean {
    return this.delegate.instance.tmpReadonly;
  }

  get selector(): Form.FieldDataTypeSelector {
    return this.delegate.instance.selector;
  }

  get formRecordFieldContext() {
    return this.delegate.instance.formRecordFieldContext;
  }

  get valueReference() {
    return this.delegate.instance.valueReference;
  }

  setTmpReadonly(args: SetTmpReadonlyArgs): Command<SetTmpReadonlyResult> {
    return this.delegate.instance.setTmpReadonly(args);
  }

  registerField(context: FormRecordFieldContext, orig: any): any {
    return this.delegate.instance.registerField(context, orig);
  }

  registerFieldViews(context: FormRecordFieldViewContext): void {
    this.delegate.instance.registerFieldViews(context);
  }

  hasLocalFieldError(): boolean {
    return this.delegate.instance.hasLocalFieldError();
  }

  validateWithInterrupt(): boolean {
    return this.delegate.instance.validateWithInterrupt();
  }

  shouldNotifyAfterCreation(): boolean {
    return this.delegate.instance.shouldNotifyAfterCreation();
  }

  afterFormRecordCreation(formRecordId: number): Observable<any> | undefined {
    return this.delegate.instance.afterFormRecordCreation(formRecordId);
  }

  createModel(): FormRecordFieldViewModel | undefined {
    return this.delegate.instance.createModel();
  }

  isHidden(): boolean {
    return this.delegate.instance.formRecordFieldContext?.validationType === FormFieldValidationType.HIDDEN;
  }

  // </editor-fold>

  public setDelegate(delegate: ComponentRef<FormRecordFieldView>) {
    this.delegate = delegate;
    this.fieldHost.viewContainerRef.clear();
    this.fieldHost.viewContainerRef.insert(delegate.hostView);
  }

  updateValue(data: FormRecordFieldValueUpdateArgs) {
    this.delegate.instance.updateValue(data);
  }

}
