/* eslint-disable */
import { Component, Input, OnInit } from '@angular/core';
import { TransportDocument, TransportDocumentService } from '../../../../lib/transport/transport-document/transport-document.service';
import { QueryFieldModel, UiConstants } from '../../../../util/core-utils';
import { OrderType, QueryResult } from '../../../../lib/util/services';
import { OffsetDateTime } from '../../../../lib/util/dates';
import { DownloadedFile } from '../../../../lib/util/downloaded-files';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { Set } from 'immutable';
import { Transition } from '@uirouter/angular';
import { Angular2Multiselects } from '../../../../util/multiselect';
import {
  TransportDocumentSearch,
  TransportDocumentSearchService
} from '../../../../lib/transport/transport-document/transport-document-search.service';
import { combineLatest, Observable } from 'rxjs';
import { StringKey } from '../../../../app.string-keys';
import { TranslateUtils } from '../../../../util/translate';
import { DetailTab } from '../../transport-detail/detail-tab';
import transportDocumentTypes = TransportDocument.transportDocumentTypes;
/* eslint-enable */

@Component({
  selector: 'app-transport-document-list',
  templateUrl: './transport-document-list.component.html',
  styleUrls: ['./transport-document-list.component.scss']
})
export class TransportDocumentListComponent implements OnInit, DetailTab {
  TransportDocument = TransportDocument;
  pagingId = 'transportDocumentList';


  queryModel: QueryFieldModel<TransportDocument.OrderField>
    = new QueryFieldModel(TransportDocument.OrderField.CREATION_TIME, OrderType.DESC);
  transportDocumentList: TransportDocumentModel[];
  transportId: number;

  searchModel: TransportDocumentSearchModel = new TransportDocumentSearchModel();
  showSearch: boolean = false;
  searchResult: TransportDocumentSearch.SearchDataResult;

  dropdownSettingsForShipment: Angular2Multiselects.Settings;
  shipmentsForSearch: TransportDocumentSearch.ShipmentItem[] = [];
  readonly shipmentId?: number;
  shipments: TransportDocumentSearch.ShipmentItem[] = [];

  dropdownSettingsForDocumentType: Angular2Multiselects.Settings;
  documentTypesForSearch: TransportDocumentSearch.DocumentTypeItem[] = [];
  readonly documentTypeId?: string;
  documentTypes: TransportDocumentSearch.DocumentTypeItem[] = [];

  @Input()
  transportWaybillNumber: string;

  constructor(
    private translateService: TranslateService,
    private transportDocumentService: TransportDocumentService,
    private transition: Transition,
    private transportDocumentSearchService: TransportDocumentSearchService
  ) {
    this.transportId = this.transition.params()['id'];
    this.shipmentId = this.transition.params().shipmentId ? this.transition.params().shipmentId : undefined;
    this.documentTypeId = this.transition.params().documentTypeId ? this.transition.params().documentTypeId : undefined;
  }

  ngOnInit() {
  }

  initComponent() {
    this.initDropdownSettings();
    this.loadSearch(() => {
      this.loadList()
    });
  }


  private loadSearch(completion: () => void) {
    const obs: Observable<SearchLoadResult> = combineLatest(
      this.transportDocumentSearchService.getSearchData({}),
      (storedSearchData: TransportDocumentSearch.SearchDataResult) => {
        const result: SearchLoadResult = {
          storedSearchData: storedSearchData
        };
        return result;
      }
    );
    obs.subscribe(
      (result: SearchLoadResult) => {
        this.searchResult = result.storedSearchData;
        this.loadDocumentTypesForSearch(result.storedSearchData, () => {
          this.postInitSearch(result.storedSearchData, completion);
        });
      }
    );

  }

  private postInitSearch(storedSearchData: TransportDocumentSearch.SearchDataResult, completion: () => void) {
    this.queryModel.itemsPerPage = storedSearchData.searchData.itemsPerPage;
    this.queryModel.currentPage = storedSearchData.searchData.pageNumber;
    this.queryModel.setOrder(storedSearchData.searchData.order);
    completion()
  }

  loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();

    const documentTypeSet: TransportDocument.TransportDocumentType[] = [];
    this.searchModel.documentTypeIds.forEach((item) => {
      const documentType = transportDocumentTypes.find((type) => type.type === item.id)!.type;
      documentTypeSet.push(documentType);
    });

    this.transportDocumentService.query({
      onlyMostRecent: true,
      shipmentId: undefined,
      documentType: documentTypeSet.length > 0 ? Set.of(...documentTypeSet) : undefined,
      transportId: this.transportId,
      orders: Set.of(order),
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined
    }).subscribe((result: QueryResult<TransportDocument.TransportDocument>) => {
      this.transportDocumentList = [];
      result.items.forEach((document: TransportDocument.TransportDocument) => {
        const documentModel = new TransportDocumentModel();
        documentModel.id = document.id;
        documentModel.name = transportDocumentTypes.find((type) => type.type === document.documentType)!.stringKey;
        documentModel.serial = document.serial;
        documentModel.shipmentExternalId = document.shipment ? document.shipment.externalId : '';
        documentModel.shipmentDeliveryNoteNumber = document.shipment ? document.shipment.deliveryNoteNumber : '';
        documentModel.creationTime = document.creationTime;
        documentModel.transportId = document.transportId;
        this.transportDocumentList.push(documentModel);
      });
      this.queryModel.currentPage = requestedPage;
      this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
    })


  }


  private loadDocumentTypesForSearch(storedSearchData: TransportDocumentSearch.SearchDataResult, completion: () => void) {
    this.searchModel.documentTypeIds = [];
    if (!this.documentTypeId) {
      this.loadDocumentTypes(() => {
        this.documentTypesForSearch = [];
        this.documentTypes.forEach((documentType) => {
          const item = new TransportDocumentSearch.DocumentTypeItem();
          item.id = documentType.id;
          item.name = documentType.name;
          this.documentTypesForSearch.push(item);
          if (storedSearchData.searchData.documentTypeIds.find(t => t.id === item.id)) {
            this.searchModel.documentTypeIds.push(item);
          }
        });
        completion()
      });
    }
  }

  private loadDocumentTypes(completion: () => void) {
    this.documentTypes = [];
    transportDocumentTypes.forEach(
      (type) => {
        const item = {
          id: type.type,
          itemName: type.stringKey,
          name: type.stringKey,
        };
        this.documentTypes.push(item);
      });
    this.documentTypes.forEach(
      (state: TransportDocumentSearch.DocumentTypeItem) => {
        this.translateService.get(state.name).subscribe((stateName: string) => {
            state.name = stateName;
          }
        )
      });
    completion();
  }

  orderBy(field: TransportDocument.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  download(transportId: number, id: number, shipmentDeliveryNoteNumber?: string) {
    this.transportDocumentService.downloadDocument({
      transportId: transportId,
      id: id
    }).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName(shipmentDeliveryNoteNumber
          ? shipmentDeliveryNoteNumber
          : this.transportWaybillNumber));
      }
    );
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  onSearchReset() {
    this.transportDocumentSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.showSearch = true;
          this.loadList(1);
        });
      }
    );
  }

  onSearchClicked() {
    this.loadList(1);
  }

  initDropdownSettings() {
      this.dropdownSettingsForShipment = new Angular2Multiselects.SettingsBuilder()
        .singleSelection(false)
        .enableSearchFilter(true)
        .enableCheckAll(false)
        .build();
      this.dropdownSettingsForDocumentType = new Angular2Multiselects.SettingsBuilder()
        .singleSelection(false)
        .enableSearchFilter(true)
        .enableCheckAll(false)
        .build();
  }


}

export class TransportDocumentModel {
  id: number;
  serial: string = '';
  name: string = '';
  shipmentExternalId: string = '';
  shipmentDeliveryNoteNumber: string = '';
  creationTime?: OffsetDateTime = undefined;
  transportId: number;
}

export class TransportDocumentSearchModel {
  shipmentIds: TransportDocumentSearch.ShipmentItem[] = [];
  documentTypeIds: TransportDocumentSearch.DocumentTypeItem[] = [];


  public isEmpty(): boolean {
    return this.shipmentIds.length === 0
      && this.documentTypeIds.length === 0
      ;
  }
}

interface SearchLoadResult {
  storedSearchData: TransportDocumentSearch.SearchDataResult,
}
