/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { PagingRequest, QueryResult, ResourceQueryResult } from './util/services';
import { List } from 'immutable';
import { CityResource, CityResourceService, CountryResource, CountryResourceService, } from './country-resource.service';
import { HeaderKeys } from './util/http-services';

/* eslint-enable */

@Injectable()
export class CountryService {

  constructor(private countryResourceService: CountryResourceService) {
  }

  query(request: Country.QueryRequest): Observable<QueryResult<Country.Country>> {
    return Observable.create((observer: Observer<QueryResult<Country.Country>>) => {
      const resourceRequest: CountryResource.CountryNameQueryRequest = {
        name: request.q,
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
        no_progress_bar: false
      };
      return this.countryResourceService.countryNameQuery(resourceRequest).subscribe(
        (result: ResourceQueryResult<CountryResource.CountryName>) => {
          observer.next({
            items: List.of(...result.items.map(value => {
              return {
                countryCode: value.country_code,
                localizedName: value.name
              }
            })),
            pagingResult: result.pagingResult
          });
        },
        (error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        }
      );
    });
  }

  addressTypeQuery(request: Country.AddressTypeQueryRequest): Observable<QueryResult<string>> {
    return Observable.create((observer: Observer<QueryResult<string>>) => {
      const resourceRequest: CountryResource.AddressTypeQueryRequest = {
        country_code: request.countryCode,
        q: request.q,
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
        no_progress_bar: true
      };
      return this.countryResourceService.addressTypeQuery(resourceRequest).subscribe(
        (result: ResourceQueryResult<string>) => {
          observer.next({
            items: List.of(...result.items),
            pagingResult: result.pagingResult
          });
        },
        (error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        }
      );
    });
  }

  zipCodeQuery(request: Country.ZipCodeQueryRequest): Observable<QueryResult<string>> {
    return Observable.create((observer: Observer<QueryResult<string>>) => {
      const resourceRequest: CountryResource.ZipCodeQueryRequest = {
        country_code: request.countryCode,
        q: request.q,
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
        no_progress_bar: true
      };
      return this.countryResourceService.zipCodeQuery(resourceRequest).subscribe(
        (result: ResourceQueryResult<string>) => {
          observer.next({
            items: List.of(...result.items),
            pagingResult: result.pagingResult
          });
        },
        (error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        }
      );
    });
  }

  private translateError(error: any): any {
    return error;
  }

}

export namespace Country {

  export interface Country {
    countryCode: string;
    localizedName: string; // localized based on app language
  }

  export interface QueryRequest {
    paging?: PagingRequest;
    q?: string;
  }

  export interface AddressTypeQueryRequest {
    paging?: PagingRequest;
    countryCode: string;
    q?: string;
  }

  export interface ZipCodeQueryRequest {
    paging?: PagingRequest;
    countryCode: string;
    q?: string;
  }

}

@Injectable()
export class CityService {

  constructor(private cityResourceService: CityResourceService) {
  }

  // query without full screen progress dialog
  queryCity(request: City.QueryRequest): Observable<City.CityQueryResult> {
    return Observable.create((observer: Observer<City.CityQueryResult>) => {
      const resourceRequest: CityResource.QueryRequest = {
        with_zip_codes: request.withZipCodes,
        with_streets: request.withStreets,
        native_name: request.nativeName,
        country_code: request.countryCode,
        token: request.token,
        zip_code: request.zipCode,
        zip_code_start: request.zipCodeStart,
        no_progress_bar: true,
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
      };
      return this.cityResourceService.queryCity(resourceRequest).subscribe(
        (result: ResourceQueryResult<CityResource.City>) => {
          observer.next({
            result: {
              items: this.toPublicList(result.items),
              pagingResult: result.pagingResult
            },
            token: result.otherHeaders.get(HeaderKeys.PLACE_AUTOCOMPLETE_TOKEN)
          });
        },
        (error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        }
      );
    });
  }

  private translateError(error: any): any {
    return error;
  }

  private toPublicList(resourceList: CityResource.City[]): List<City.City> {
    return List.of(...resourceList.map((r) => this.toPublic(r)));
  }

  private toPublic(r: CityResource.City): City.City {
    return {
      countryCode: r.country_code,
      localizedName: r.native_name,
      county: r.county,
      zipCodes: r.zip_codes,
      streets: r.streets
        ? r.streets.map(s => ({nativeName: s.native_name, nativeType: s.native_type}))
        : undefined
    };
  }

}

export namespace City {

  export interface City {
    countryCode: string;
    county?: string;
    localizedName: string; // localized based on app language
    zipCodes?: string[];
    streets?: Street[];
  }

  export interface Street {
    nativeName: string;
    nativeType: string;
  }

  export interface QueryRequest {
    countryCode?: string;
    nativeName?: string;
    withZipCodes?: boolean;
    withStreets?: boolean;
    zipCode?: string;
    zipCodeStart?: string;
    paging?: PagingRequest;
    token?: string;
  }

  export interface CityQueryResult {
    result: QueryResult<City.City>;
    token?: string;
  }

}
