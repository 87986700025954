/* eslint-disable */
import { DownloadedFile, } from '../util/downloaded-files';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResourceQueryResult } from '../util/services';
import { map } from 'rxjs/operators';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { HttpClient } from '@angular/common/http';
import { EmptyMessage } from '../util/messages';
/* eslint-enable */

@Injectable()
export class GeneralPrinterTemplateResourceService extends BaseHttpService {

  query(request: GeneralPrinterTemplateResource.QueryRequest):
    Observable<ResourceQueryResult<GeneralPrinterTemplateResource.GeneralPrinterTemplate>> {
    return this.http.get<ResourceQueryResult<GeneralPrinterTemplateResource.GeneralPrinterTemplate>>(this.url, this.parseParams(request));
  }

  get(request: GeneralPrinterTemplateResource.GetRequest): Observable<GeneralPrinterTemplateResource.GeneralPrinterTemplate> {
    return this.http.get<GeneralPrinterTemplateResource.GeneralPrinterTemplate>(this.url + `${request.code}`);
  }

  setDisabled(request: GeneralPrinterTemplateResource.DisableRequest): Observable<EmptyMessage> {
    return this.http.patch(this.url + `${request.code}/disabled`, request);
  }

  download(request: GeneralPrinterTemplateResource.DownloadRequest): Observable<DownloadedFile> {
    return this.http.get(this.url + `${request.code}/download`, {
      observe: 'response',
      responseType: 'blob'
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/general-printer/template/');
  }
}

export namespace GeneralPrinterTemplateResource {

  export interface GeneralPrinterTemplate {
    id: number;
    code: string;
    content_hash: string;
    version: number;
    creation_time?: string;
    update_time?: string;
    disabled: boolean;
  }

  export interface QueryRequest {
    ids?: string;
    code?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
    disabled?: boolean;
    no_progress_bar?: boolean;
  }

  export interface GetRequest {
    code: string;
  }

  export interface DownloadRequest {
    code: string;
  }

  export interface DisableRequest {
    code: string;
    disabled: boolean;
  }

}
