<div class="card">

  <div class="card-header">
    {{'TRIGGER_HISTORY_TABLE_HEADER' | translate}}
  </div>

  <div class="card-body">

    <table class="table table-striped table-bordered">
      <thead>
      <tr>
        <th class="table-sorter-header">
          <app-table-sorter-no-op
            [text]="'COMMON_NAME' | translate">
          </app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header hidden-sm-down">
          <app-table-field-sorter [orderField]="TriggerInstanceOrderField.TYPE"
                                  [orderType]="queryModel.getOrderType(TriggerInstanceOrderField.TYPE)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'COMMON_TYPE' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header hidden-sm-down">
          <app-table-field-sorter [orderField]="TriggerInstanceOrderField.CREATION_TIME"
                                  [orderType]="queryModel.getOrderType(TriggerInstanceOrderField.CREATION_TIME)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'TRIGGER_TABLE_HEADER_TIME_OF_RUN' | translate"></app-table-field-sorter>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let trigger of triggerHistoryList | paginate: {
                                                      id: pagingId,
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }">
        <td class="long">{{trigger.name}}</td>
        <td class="hidden-sm-down">{{trigger.typeStringKey | translate}}</td>
        <td class="hidden-sm-down">{{trigger.creationTime.toUtcIsoString() | date:'short'}}</td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <th class="table-sorter-header">
          <app-table-sorter-no-op
            [text]="'COMMON_NAME' | translate">
          </app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header hidden-sm-down">
          <app-table-field-sorter [orderField]="TriggerInstanceOrderField.TYPE"
                                  [orderType]="queryModel.getOrderType(TriggerInstanceOrderField.TYPE)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'COMMON_TYPE' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header hidden-sm-down">
          <app-table-field-sorter [orderField]="TriggerInstanceOrderField.CREATION_TIME"
                                  [orderType]="queryModel.getOrderType(TriggerInstanceOrderField.CREATION_TIME)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'TRIGGER_TABLE_HEADER_TIME_OF_RUN' | translate"></app-table-field-sorter>
        </th>
      </tr>
      </tfoot>
    </table>
    <app-table-paging
      [id]="pagingId"
      [currentNumberOfItems]="queryModel.currentNumberOfItems"
      [totalNumberOfItems]="queryModel.totalNumberOfItems"
      [itemsPerPage]="queryModel.itemsPerPage"
      (pageChange)="pageChanged($event)"
      (itemsPerPageChange)="itemsPerPageChanged($event)">
    </app-table-paging>
  </div><!-- /.card-body -->

</div><!-- /.card -->
