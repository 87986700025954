/* eslint-disable */
import { DownloadedFile } from '../util/downloaded-files';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlBuilder } from '../../util/url-builder';
import { ResourceQueryResult } from '../util/services';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { EmptyMessage } from '../util/messages';
/* eslint-enable */

@Injectable()
export class ApplicationFileResourceService extends BaseHttpService {

  query(request: ApplicationFileResource.QueryRequest): Observable<ResourceQueryResult<ApplicationFileResource.ApplicationFile>> {
    return this.http.get<ResourceQueryResult<ApplicationFileResource.ApplicationFile>>(this.url, this.parseParams(request));
  }

  get(request: ApplicationFileResource.GetRequest): Observable<ApplicationFileResource.ApplicationFile> {
    return this.http.get<ApplicationFileResource.ApplicationFile>(this.url + `${request.id}`);
  }

  create(request: ApplicationFileResource.CreateRequest): Observable<EmptyMessage> {
    return this.http.post(this.url, request);
  }

  update(request: ApplicationFileResource.UpdateRequest): Observable<EmptyMessage> {
    return this.http.put(this.url + `${request.id}`, request);
  }

  delete(request: ApplicationFileResource.DeleteRequest): Observable<EmptyMessage> {
    return this.http.delete<EmptyMessage>(this.url + `${request.id}`);
  }


  export(request: ApplicationFileResource.ExportRequest): Observable<DownloadedFile> {
    const url = UrlBuilder.create(
      '/application-files/:id/content')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .namedNumber('id', request.id)
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob',
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  constructor(private http: HttpClient, private resourceHelper: ResourceHelper) {
    super(resourceHelper, '/application-files/');
  }
}

export namespace ApplicationFileResource {

  export interface ApplicationFile {
    id: number;
    code: string;
    note?: string;
    language_code?: string;
    content?: Content;
  }

  export interface Content {
    hash: string;
    type: string;
    update_time: string;
  }

  export interface QueryRequest {
    code?: string;
    language_code?: string;
    content_type?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
  }

  export interface GetRequest {
    id: number;
  }

  export interface CreateRequest {
    code: string;
    note?: string;
    language_code?: string;
  }

  export interface UpdateRequest {
    id: number;
    note?: string;
  }

  export interface DeleteRequest {
    id: number;
  }

  export interface ExportRequest {
    id: number;
  }


}
