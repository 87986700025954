import { DqlListener } from '../../../../util/generated/dql/normal/DqlListener';
import * as dql from '../../../../util/generated/dql/normal/DqlParser';
import {
  ComplexDqlExpressionBuilder,
  DqlBinaryCriteriaModel,
  DqlCriteriaModel,
  DqlFieldModel,
  DqlLogicalOperation,
  DqlLogicalOperationObject,
  DqlOperations,
  DqlUnaryCriteriaModel,
  PureDqlQueryBuilder,
  SimpleDqlExpressionBuilder
} from '../../dql-search-container/dql-search-container.model';
import { ParserRuleContext } from 'antlr4ts';
import { ErrorNode, TerminalNode } from 'antlr4ts/tree';

export class NormalDqlListenerImpl implements DqlListener {

  queryBuilder: PureDqlQueryBuilder = new PureDqlQueryBuilder();

  private _lastCriteria: DqlCriteriaModel | undefined;

  constructor(private fields: DqlFieldModel[]) {

  }

  enterSentence: (ctx: dql.SentenceContext) => void = (ctx: dql.SentenceContext) => {
    this.queryBuilder = new PureDqlQueryBuilder();
  };

  enterComplexExpression: (ctx: dql.ComplexExpressionContext) => void = (ctx: dql.ComplexExpressionContext) => {
    this.queryBuilder.addExpression(new ComplexDqlExpressionBuilder());
  };

  enterSimpleExpression: (ctx: dql.SimpleExpressionContext) => void = (ctx: dql.SimpleExpressionContext) => {
    this.queryBuilder.addExpression(new SimpleDqlExpressionBuilder());
  };
  exitComplexExpression: (ctx: dql.ComplexExpressionContext) => void = (ctx: dql.ComplexExpressionContext) => {
    this.queryBuilder.closeExpression();
  };
  exitSimpleExpression: (ctx: dql.SimpleExpressionContext) => void = (ctx: dql.SimpleExpressionContext) => {
    this.queryBuilder.closeExpression();
  };

  enterDynamicDynamicField: (ctx: dql.DynamicDynamicFieldContext) => void = (ctx: dql.DynamicDynamicFieldContext) => {
    this.fieldEntered(ctx!.text);
  };
  enterDynamicField: (ctx: dql.DynamicFieldContext) => void = (ctx: dql.DynamicFieldContext) => {
    this.fieldEntered(ctx!.text);
  };
  enterDynamicStaticField: (ctx: dql.DynamicStaticFieldContext) => void = (ctx: dql.DynamicStaticFieldContext) => {
    this.fieldEntered(ctx!.text);
  };
  enterStaticField: (ctx: dql.StaticFieldContext) => void = (ctx: dql.StaticFieldContext) => {
    this.fieldEntered(ctx!.text);
  };

  enterLogicalOperator: (ctx: dql.LogicalOperatorContext) => void = (ctx: dql.LogicalOperatorContext) => {
    this.queryBuilder.addLogical(ctx.LOGICAL().text === DqlLogicalOperation.AND
      ? new DqlLogicalOperationObject(DqlLogicalOperation.AND)
      : new DqlLogicalOperationObject(DqlLogicalOperation.OR));
  };


  enterBinaryCriteria: (ctx: dql.BinaryCriteriaContext) => void = (ctx: dql.BinaryCriteriaContext) => {
    this._lastCriteria = new DqlBinaryCriteriaModel();
  };

  enterUnaryCriteria: (ctx: dql.UnaryCriteriaContext) => void = (ctx: dql.UnaryCriteriaContext) => {
    this._lastCriteria = new DqlUnaryCriteriaModel();
  };

  exitBinaryCriteria: (ctx: dql.BinaryCriteriaContext) => void = (ctx: dql.BinaryCriteriaContext) => {
    this.queryBuilder.addCriteria(this._lastCriteria!);
    this._lastCriteria = undefined;
  };
  exitUnaryCriteria: (ctx: dql.UnaryCriteriaContext) => void = (ctx: dql.UnaryCriteriaContext) => {
    this.queryBuilder.addCriteria(this._lastCriteria!);
    this._lastCriteria = undefined;
  };
  enterRelationalOperator: (ctx: dql.RelationalOperatorContext) => void = (ctx: dql.RelationalOperatorContext) => {
    const opText = ctx.OPERATION().text;
    const op = DqlOperations.realOperations().find(o => {
      if (o) {
        return o.operation === opText;
      }
      return false;
    });
    this._lastCriteria!.operation = op;
  };

  enterUnaryRelationalOperator: (ctx: dql.UnaryRelationalOperatorContext) => void = (ctx: dql.UnaryRelationalOperatorContext) => {
    const opText = ctx.text;
    const op = DqlOperations.realOperations().find(o => {
      if (o) {
        return o.operation === opText;
      }
      return false;
    });
    this._lastCriteria!.operation = op;
  };

  enterValue: (ctx: dql.ValueContext) => void = (ctx: dql.ValueContext) => {
    this._lastCriteria!.setValue(ctx.VALUE().text.replace(/"/g, ''));
  };

  private fieldEntered(field: string): void {
    this._lastCriteria!.field = this.getField(field)!;
  }


  private getField(fieldId: string): DqlFieldModel | undefined {
    return this.fields.find(f => f.id === fieldId);
  }

  enterEveryRule: (ctx: ParserRuleContext) => void = () => {
  };
  exitEveryRule: (ctx: ParserRuleContext) => void = () => {
  };
  visitErrorNode: (node: ErrorNode) => void = () => {
  };
  visitTerminal: (node: TerminalNode) => void = () => {
  };

}
