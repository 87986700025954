import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ShipmentGroupOuttakeModel } from '../shipment-group.model';
import { RightModel } from '../../../../app.rights';

@Component({
  selector: 'app-shipment-group-outtake',
  templateUrl: './shipment-group-outtake.component.html',
  styleUrls: ['./shipment-group-outtake.component.scss']
})
export class ShipmentGroupOuttakeComponent implements OnInit {

  @Input()
  outtakeModel: ShipmentGroupOuttakeModel;

  @Input()
  readonly: boolean;

  @Input()
  rightModel: RightModel;

  @Output()
  openStorekeeperChangeDialog: EventEmitter<any> = new EventEmitter();

  @Output()
  openForceReopenDialog: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

  canChangeStorekeeper(): boolean {
    return !this.readonly
      && this.rightModel.shipmentOuttakeStorekeeperChange.hasRight()
      && (this.outtakeModel.state.state === 'OPEN' || this.outtakeModel.state.state === 'IN_PROGRESS');
  }

  canForceReopen(): boolean {
    return !this.readonly
      && this.rightModel.shipmentOuttakeReopenForce.hasRight()
      && (this.outtakeModel.state.state === 'OPEN' || this.outtakeModel.state.state === 'IN_PROGRESS');
  }

}
