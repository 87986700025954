/* eslint-disable */
import { GrantedPermissionSet } from './lib/right.service';
import { User } from './lib/user.service';
import { PermissionDefinition, PermissionDefinitions } from './app.permission-definitions';
import { RightModel } from './app.rights';
import { UserData } from './lib/user-data-loader';

/* eslint-enable */

export interface UserRightModelContext {
  rightModel: RightModel;
  currentUser?: UserData;
}

/**
 * Extension for user rights.
 * @see UserGrantedPermissionSet
 */
export class UserRightModel {

  public readonly userCreate: GrantedPermissionSet;
  public readonly userDisable: GrantedPermissionSet;
  public readonly userRead: UserReadGrantedPermissionSet;
  public readonly userUpdate: UserUpdateGrantedPermissionSet;
  public readonly userSignatureRead: UserSignatureReadGrantedPermissionSet;
  public readonly userSignatureUpload: UserSignatureUploadGrantedPermissionSet;
  public readonly userSignatureDelete: UserSignatureDeleteGrantedPermissionSet;

  static empty() {
    return new UserRightModel();
  }

  static of(context: UserRightModelContext) {
    return new UserRightModel(context);
  }

  private static map<T>(
    presentFactory: (context: UserRightModelContext) => T, absentFactory: () => T, context?: UserRightModelContext): T {
    if (context) {
      return presentFactory(context);
    }
    else {
      return absentFactory();
    }
  }

  private constructor(context?: UserRightModelContext) {
    this.userCreate = UserRightModel.map(
      (c: UserRightModelContext) => c.rightModel.userCreate,
      () => GrantedPermissionSet.empty(),
      context);
    this.userDisable = UserRightModel.map(
      (c: UserRightModelContext) => c.rightModel.userDisable,
      () => GrantedPermissionSet.empty(),
      context);
    this.userRead = UserRightModel.map(
      (c: UserRightModelContext) => UserReadGrantedPermissionSet.of(c.rightModel, c.currentUser),
      () => UserReadGrantedPermissionSet.empty(),
      context);
    this.userUpdate = UserRightModel.map(
      (c: UserRightModelContext) => UserUpdateGrantedPermissionSet.of(c.rightModel, c.currentUser),
      () => UserUpdateGrantedPermissionSet.empty(),
      context);
    this.userSignatureRead = UserRightModel.map(
      (c: UserRightModelContext) => UserSignatureReadGrantedPermissionSet.of(c.rightModel, c.currentUser),
      () => UserSignatureReadGrantedPermissionSet.empty(),
      context);
    this.userSignatureDelete = UserRightModel.map(
      (c: UserRightModelContext) => UserSignatureDeleteGrantedPermissionSet.of(c.rightModel, c.currentUser),
      () => UserSignatureDeleteGrantedPermissionSet.empty(),
      context);
    this.userSignatureUpload = UserRightModel.map(
      (c: UserRightModelContext) => UserSignatureUploadGrantedPermissionSet.of(c.rightModel, c.currentUser),
      () => UserSignatureUploadGrantedPermissionSet.empty(),
      context);
  }

}

/**
 * Extension for user rights.
 * Method hasRight() check the right globally, that's why it
 * implements hasRightForUser(User) so we can decide whether we have the requested right for the requested user.
 * If the editing rights (like disable, update) require read right, usage of hasRight() is appropriate,
 * because the server filters the resources based on the read right.
 * Anyway decide user related rights are easy because we know the current user and it is the only extra dependency,
 * so we keep this extension.
 */
class UserGrantedPermissionSet implements GrantedPermissionSet {

  constructor(private delegate: GrantedPermissionSet,
              private currentUser?: UserData,
              private allPermission?: string,
              private myselfPermission?: string,
              private myCompanyPermission?: string) {
  }

  public hasRight(): boolean {
    return this.delegate.hasRight();
  }

  contains(permission: PermissionDefinition): boolean {
    return this.delegate.contains(permission);
  }

  public hasRightForUser(user?: User): boolean {
    if (this.hasAllPermission()) {
      return true;
    }
    if (this.hasMyCompanyPermission(user)) {
      return true;
    }
    return this.hasMyselfPermission(user);
  }

  private hasAllPermission(): boolean {
    if (!this.allPermission) {
      return false;
    }
    return this.delegate.contains(this.allPermission);
  }

  private hasMyselfPermission(user?: User): boolean {
    if (!user) {
      return false;
    }
    if (!this.currentUser) {
      return false;
    }
    if (!this.myselfPermission) {
      return false;
    }
    if (this.currentUser.id === user.id) {
      return this.delegate.contains(this.myselfPermission);
    }
    return false;
  }

  private hasMyCompanyPermission(user?: User): boolean {
    if (!user) {
      return false;
    }
    if (!this.currentUser || this.currentUser.companies.length === 0) {
      return false;
    }
    if (!this.myCompanyPermission) {
      return false;
    }
    if (this.currentUser.companies.map(c => c.id).find(x => user.companies.map(c => c.id).includes(x))) {
      return this.delegate.contains(this.myCompanyPermission);
    }
    return false;
  }

}

export class UserReadGrantedPermissionSet extends UserGrantedPermissionSet {

  static empty(): UserReadGrantedPermissionSet {
    return new UserReadGrantedPermissionSet(GrantedPermissionSet.empty());
  }

  static of(rightModel: RightModel, currentUser?: UserData): UserReadGrantedPermissionSet {
    return new UserReadGrantedPermissionSet(
      rightModel.userRead,
      currentUser,
      PermissionDefinitions.USER_READ_ALL,
      PermissionDefinitions.USER_READ_MYSELF);
  }

}

export class UserUpdateGrantedPermissionSet extends UserGrantedPermissionSet {

  static empty(): UserUpdateGrantedPermissionSet {
    return new UserUpdateGrantedPermissionSet(GrantedPermissionSet.empty());
  }

  static of(rightModel: RightModel, currentUser?: UserData): UserUpdateGrantedPermissionSet {
    return new UserUpdateGrantedPermissionSet(
      rightModel.userUpdate,
      currentUser,
      PermissionDefinitions.USER_UPDATE_ALL,
      PermissionDefinitions.USER_UPDATE_MYSELF,
      PermissionDefinitions.USER_UPDATE_MY_COMPANY);
  }
}

export class UserSignatureReadGrantedPermissionSet extends UserGrantedPermissionSet {

  static empty(): UserSignatureReadGrantedPermissionSet {
    return new UserSignatureReadGrantedPermissionSet(GrantedPermissionSet.empty());
  }

  static of(rightModel: RightModel, currentUser?: UserData): UserSignatureReadGrantedPermissionSet {
    return new UserSignatureReadGrantedPermissionSet(
      rightModel.userSignatureRead,
      currentUser,
      PermissionDefinitions.USER_SIGNATURE_READ_ALL,
      PermissionDefinitions.USER_SIGNATURE_READ_MINE);
  }
}

export class UserSignatureUploadGrantedPermissionSet extends UserGrantedPermissionSet {

  static empty(): UserSignatureUploadGrantedPermissionSet {
    return new UserSignatureUploadGrantedPermissionSet(GrantedPermissionSet.empty());
  }

  static of(rightModel: RightModel, currentUser?: UserData): UserSignatureUploadGrantedPermissionSet {
    return new UserSignatureUploadGrantedPermissionSet(
      rightModel.userSignatureUpload,
      currentUser,
      PermissionDefinitions.USER_SIGNATURE_UPLOAD_ALL,
      PermissionDefinitions.USER_SIGNATURE_UPLOAD_MINE);
  }
}
export class UserSignatureDeleteGrantedPermissionSet extends UserGrantedPermissionSet {

  static empty(): UserSignatureDeleteGrantedPermissionSet {
    return new UserSignatureDeleteGrantedPermissionSet(GrantedPermissionSet.empty());
  }

  static of(rightModel: RightModel, currentUser?: UserData): UserUpdateGrantedPermissionSet {
    return new UserSignatureDeleteGrantedPermissionSet(
      rightModel.userSignatureDelete,
      currentUser,
      PermissionDefinitions.USER_SIGNATURE_DELETE_ALL,
      PermissionDefinitions.USER_SIGNATURE_DELETE_MINE);
  }
}
