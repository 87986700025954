<div bsModal [config]="UiConstants.modalConfig" #dialog="bs-modal" class="modal fade"
     tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="dialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title"><strong>{{'CUSTOMER_RECORD_CONTACT_LOCATION_CREATE' | translate}}</strong></p>
        <button type="button" class="close" (click)="closeDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <stepper-container #stepper
                           (onSave)="submit()">
          <stepper-item #stepBasicData
                        [iconClass]="'icomoon-administration-company-management'"
                        [title]="'CUSTOMER_RECORD_CONTACT_LOCATION_BASIC_DATA' | translate">

            <label class="col-form-label mw-100 form-control-label font-weight-bold">
              {{'CUSTOMER_RECORD_CONTACT_LOCATION_BASIC_DATA'|translate}}
            </label>

            <!-- Name -->
            <div class="form-group row">
              <label
                class="col-form-label col-md-4 form-control-label required-field-label text-md-right">
                {{'COMMON_NAME'|translate}}
              </label>
              <div class="col-md-8" [ngClass]="{ 'has-danger': stepBasicData.submitted && !name.valid }">
                <div class="mw-100">
                  <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
                         [(ngModel)]="model.name"
                         name="name"
                         id="name"
                         #name="ngModel"
                         required>
                  <div class="form-control-feedback"
                       *ngIf="stepBasicData.submitted && !name.valid">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                  </div>
                </div>
              </div>
            </div>

            <!-- External id -->
            <div class="form-group row">
              <label
                class="col-form-label col-md-4 form-control-label text-md-right" [class.required-field-label]="model.mode === CustomerRecordContactLocationDialogMode.EDIT">
                {{'COMMON_EXTERNAL_ID'|translate}}
              </label>
              <div class="col-md-8" [ngClass]="{ 'has-danger': stepBasicData.submitted && !externalId.valid }">
                <input type="text" class="form-control" placeholder="{{'COMMON_EXTERNAL_ID' | translate}}"
                       [(ngModel)]="model.externalId"
                       name="externalId"
                       id="externalId"
                       #externalId="ngModel"
                       [required]="model.mode === CustomerRecordContactLocationDialogMode.EDIT">
                <div class="form-control-feedback"
                     *ngIf="stepBasicData.submitted && !externalId.valid">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
              </div>
            </div>

            <!-- ContractNumber -->
            <div class="form-group row">
              <label
                class="col-form-label col-md-4 form-control-label text-md-right">
                {{'CUSTOMER_RECORD_CONTRACT_NUMBER'|translate}}
              </label>
              <div class="col-md-8">
                <input type="text" class="form-control" placeholder="{{'CUSTOMER_RECORD_CONTRACT_NUMBER' | translate}}"
                       [(ngModel)]="model.contractNumber"
                       name="contractNumber"
                       id="contractNumber"
                       #contractNumber="ngModel">
              </div>
            </div>

            <!-- Comment -->
            <div class="form-group row">
              <label
                class="col-form-label col-md-4 form-control-label text-md-right">
                {{'COMMON_COMMENT'|translate}}
              </label>
              <div class="col-md-8">
                <input type="text" class="form-control" placeholder="{{'COMMON_COMMENT' | translate}}"
                       [(ngModel)]="model.comment"
                       name="comment"
                       id="comment"
                       #comment="ngModel">
              </div>
            </div>

            <!-- Opening times -->
            <div class="form-group row">
              <label
                class="col-form-label col-md-4 form-control-label text-md-right">
                {{'COMMON_LOCATION_OPENING_TIME_HTML_PLACEHOLDER'|translate}}
              </label>
              <div class="col-md-8">
                <ngx-editor-menu [editor]="openingTimeEditor" [toolbar]="openingTimeEditorToolbar"></ngx-editor-menu>
                <ngx-editor [placeholder]="'COMMON_LOCATION_OPENING_TIME_HTML_PLACEHOLDER' | translate"
                            [editor]="openingTimeEditor"
                            [spellcheck]="false"
                            [(ngModel)]="model.openingTimes"
                            name="openingTimes"
                            id="openingTimes"
                            #openingTimes="ngModel">
                </ngx-editor>
              </div>
            </div>

          </stepper-item>
          <stepper-item #stepLocation
                        [iconClass]="'icomoon-location'"
                        [title]="'CUSTOMER_RECORD_CONTACT_LOCATION_POSTAL_ADDRESS' | translate"
                        [additionalValidationRule]="locationStepValid">

            <label class="col-form-label mw-100 form-control-label font-weight-bold">
              {{'CUSTOMER_RECORD_CONTACT_LOCATION_POSTAL_ADDRESS'|translate}}
            </label>

            <!-- postal address-->
            <div class="form-group">
              <app-postal-address-complex
                [form]="stepLocation.form"
                [model]="model.postalAddress"
                [readonly]="false"
                [allowSimpleAddress]="true"
                [fieldRatio]="8">
              </app-postal-address-complex>
            </div>

          </stepper-item>
          <stepper-item #stepContacts
                        [iconClass]="'icomoon-roles'"
                        [title]="'CUSTOMER_RECORD_CONTACT_LOCATION_CONTACT_PERSONS' | translate">

            <label class="col-form-label mw-100 form-control-label font-weight-bold">
              {{'CUSTOMER_RECORD_CONTACT_LOCATION_CONTACT_PERSONS'|translate}}
            </label>

            <div class="form-group row"
                 [ngClass]="{ 'has-danger': stepContacts.submitted && !contactPersons.valid }">
              <label class="col-form-label col-md-4 text-md-right">{{'COMMON_LOCATION_CONTACT_PERSONS' | translate}}</label>
              <div class="col-md-8">
                <angular2-multiselect
                  class="form-control"
                  [(ngModel)]="model.contactPersons"
                  [data]="personCustomerRecords"
                  [settings]="dropdownSettings"
                  (onRemoteSearch)="loadPersonCustomerRecords($event.target.value)"
                  name="contactPersons"
                  id="contactPersons"
                  #contactPersons="ngModel"
                  validateEnabledItems>
                </angular2-multiselect>
                <div class="form-control-feedback" *ngIf="stepContacts.submitted && !contactPersons.valid">
                  {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
                </div>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-md-6 p-0" *ngFor="let customerRecord of model.contactPersons">
                <app-customer-record-table-cell
                  [customerRecordName]="customerRecord.itemName"
                  [customerName]="customerRecord.customerName"
                  [customerRecordPosition]="customerRecord.position"
                  [imageSize]="48"
                  [borderEnabled]="true">
                </app-customer-record-table-cell>
              </div>
            </div>

          </stepper-item>
        </stepper-container>

        <div class="col-md-12 d-flex justify-content-end p-0">
          <button type="button" class="btn btn-outline-primary" (click)="stepper.previous()" *ngIf="!stepper.isFirst">
            {{'COMMON_BUTTON_PREVIOUS' | translate}}
          </button>
          <button type="button" class="btn btn-primary ml-1" (click)="stepper.next()">
            {{(stepper.isLast ? 'COMMON_BUTTON_SAVE' : 'COMMON_BUTTON_NEXT') | translate}}
          </button>
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal -->
  </div>
</div>
