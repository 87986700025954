<app-santa *ngIf="santa"></app-santa>
<ngx-guided-tour
[skipText]="'COMMON_BUTTON_SKIP' | translate"
[nextText]="'COMMON_BUTTON_NEXT' | translate"
[backText]="'COMMON_BUTTON_BACK' | translate"
[doneText]="'COMMON_BUTTON_DONE' | translate"
[closeText]="'COMMON_BUTTON_CLOSE' | translate"

></ngx-guided-tour>
<ui-view></ui-view>
