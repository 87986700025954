<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]=breadcrumbParents
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div class="breadcrumb-menu d-flex">
    <div *ngIf="rightModel.vehicleCreate.hasRight()"
         class="breadcrumb-button-icon-container negative-margin-right cursor-pointer"
         uiSref="Admin.VehicleCreate"
    >
      <a class="btn-setting" title="{{'VEHICLE_LIST_NEW_VEHICLE' | translate}}">
        <i class="icomoon icomoon-add"></i>
        <span class="breadcrumb-button-text-visible">{{'VEHICLE_LIST_NEW_VEHICLE' | translate}}</span>
      </a>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="card">

    <div class="card-header">
      {{'MENU_NAVBAR_NAV_TITLE_VEHICLES' | translate}}

      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()" title="{{'COMMON_BUTTON_SEARCH' | translate}}"><i
          class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>

    <div class="card-body border-bottom" *ngIf="showSearch">
      <div class="row form-group">
        <div class="col-md-4">
          <label class="search-label">{{'COMMON_TABLE_HEADER_ID' | translate}}</label>
          <input type="text" class="form-control"
                 placeholder="{{'COMMON_TABLE_HEADER_ID' | translate}}"
                 maxlength="{{UiConstants.maxInputLongLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [textMask]="{mask: InputMask.NATURAL_INTEGER, guide: false}"
                 [(ngModel)]="searchModel.id">
        </div>
        <div class="col-md-4">
          <label class="search-label">{{'COMMON_EXTERNAL_ID' | translate}}</label>
          <input type="text" class="form-control"
                 placeholder="{{'COMMON_EXTERNAL_ID' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.externalId">
        </div>
        <div class="col-md-4">
          <label class="search-label">{{'VEHICLE_MODEL' | translate}}</label>
          <input type="text" class="form-control"
                 placeholder="{{'VEHICLE_MODEL' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.model">
        </div>
        <div class="col-md-4">
          <label class="search-label">{{'VEHICLE_REGISTRATION_TYPE' | translate}}</label>
          <input type="text" class="form-control"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 placeholder="{{'VEHICLE_REGISTRATION_TYPE' | translate}}"
                 [(ngModel)]="searchModel.registrationType">
        </div>
        <div class="col-md-4">
          <label class="search-label"> {{ 'COMMON_STATE' | translate }} </label>
          <select
            class="form-control"
            [(ngModel)]="searchModel.disabled" [compareWith]="SelectUtils.compareObjects">
            <option *ngFor="let item of disabledItems" [ngValue]="item">{{item.text}}</option>
          </select>
        </div>

      </div>
      <!--Second row-->
      <div class="row form-group">
        <div class="col-md-3">
          <label class="search-label">{{'VEHICLE_LICENCE_PLATE' | translate}}</label>
          <input type="text" class="form-control"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 placeholder="{{'VEHICLE_LICENCE_PLATE' | translate}}"
                 [(ngModel)]="searchModel.licencePlate">
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'COMMON_COMPANY' | translate}}</label>
          <angular2-multiselect [data]="companyForSearch" [settings]="dropdownSettingsForCompany"
                                class="form-control"
                                [(ngModel)]="searchModel.companyIds"
                                #company="ngModel" name="company" id="company">
          </angular2-multiselect>
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'VEHICLE_REGISTRATION_DATE_FROM' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control date" placeholder="{{'VEHICLE_REGISTRATION_DATE_FROM' | translate}}"
                   [(ngModel)]="searchModel.registrationDateFrom" ngbDatepicker name="registrationDateFrom"
                   id="registrationDateFrom"
                   #registrationDateFrom="ngbDatepicker">
            <div class="input-group-addon-gray" (click)="registrationDateFrom.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'VEHICLE_REGISTRATION_DATE_TO' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control date" placeholder="{{'VEHICLE_REGISTRATION_DATE_TO' | translate}}"
                   [(ngModel)]="searchModel.registrationDateTo" ngbDatepicker name="registrationDateTo"
                   id="registrationDateTo"
                   #registrationDateTo="ngbDatepicker">
            <div class="input-group-addon-gray" (click)="registrationDateTo.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>
      </div>
      <!--Third Row-->
      <div class="row form-group">
        <div class="col-md-3">
          <label class="search-label">{{'VEHICLE_PAYLOAD_KG_FROM' | translate}}</label>
          <input type="text" class="form-control"
                 maxlength="{{UiConstants.maxInputIntLength}}"
                 (keyup.enter)="onSearchClicked()"
                 placeholder="{{'VEHICLE_PAYLOAD_KG_FROM' | translate}}"
                 [textMask]="{mask: InputMask.NATURAL_INTEGER, guide: false}"
                 [(ngModel)]="searchModel.payloadKgFrom">
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'VEHICLE_PAYLOAD_KG_TO' | translate}}</label>
          <input type="text" class="form-control"
                 maxlength="{{UiConstants.maxInputIntLength}}"
                 (keyup.enter)="onSearchClicked()"
                 placeholder="{{'VEHICLE_PAYLOAD_KG_TO' | translate}}"
                 [textMask]="{mask: InputMask.NATURAL_INTEGER, guide: false}"
                 [(ngModel)]="searchModel.payloadKgTo">
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'VEHICLE_TOTAL_KG_FROM' | translate}}</label>
          <input type="text" class="form-control"
                 maxlength="{{UiConstants.maxInputIntLength}}"
                 (keyup.enter)="onSearchClicked()"
                 placeholder="{{'VEHICLE_TOTAL_KG_FROM' | translate}}"
                 [textMask]="{mask: InputMask.NATURAL_INTEGER, guide: false}"
                 [(ngModel)]="searchModel.totalWeightKgFrom">
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'VEHICLE_TOTAL_KG_TO' | translate}}</label>
          <input type="text" class="form-control"
                 maxlength="{{UiConstants.maxInputIntLength}}"
                 (keyup.enter)="onSearchClicked()"
                 placeholder="{{'VEHICLE_TOTAL_KG_TO' | translate}}"
                 [textMask]="{mask: InputMask.NATURAL_INTEGER, guide: false}"
                 [(ngModel)]="searchModel.totalWeightKgTo">
        </div>
      </div>
      <!--fourth Row-->
      <div class="row form-group">
        <div class="col-md-3 d-flex align-items-end ">
          <div class="row">
            <app-animated-checkbox class="search-checkbox"
                                   [(selected)]="searchModel.etollRequired">
            </app-animated-checkbox>
            <label class="search-label">{{'VEHICLE_E_TOLL_REQUIRED' | translate}}</label>
          </div>
        </div>
      </div>
      <div class="col-md-12 d-flex justify-content-md-end align-items-end px-0">
        <div class="btn-group" role="group">
          <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
            {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
          </button>
          <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
            {{'COMMON_BUTTON_SEARCH' | translate}}
          </button>
        </div>
      </div>
    </div><!-- /.card-body-->
    <div class="card-body">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="Vehicle.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(Vehicle.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter
            [orderField]="Vehicle.OrderField.LICENCE_PLATE"
            [orderType]="queryModel.getOrderType(Vehicle.OrderField.LICENCE_PLATE)"
            (orderChange)="orderBy($event)"
            [text]="'VEHICLE_LIST_HEADER_LICENCE_PLATE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op
              [text]="'COMMON_TYPE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op
              [text]="'VEHICLE_MILEAGE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-field-sorter [orderField]="Vehicle.OrderField.COMPANY"
                                    [orderType]="queryModel.getOrderType(Vehicle.OrderField.COMPANY)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_COMPANY_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op
              [text]="'VEHICLE_OWNER' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let vehicle of vehicleList | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }" [disabledTableRow]="vehicle.disabled">
          <td class="fit test_vehicle_id align-middle">{{vehicle.id}}</td>
          <td class="responsive-table-column test_vehicle_licence_plate long">
            <div class="d-flex justify-content-start align-items-center">
              <div>
                <a *ngIf="rightModel.vehicleUpdate.hasRight()"
                   class="id-text cursor-pointer"
                   href="#" onclick="return false"
                   uiSref="Admin.VehicleEdit"
                   [uiParams]="{ id: vehicle.id }"
                   title="{{'COMMON_BUTTON_EDIT' | translate}}">
                  {{vehicle.licencePlate}}
                  <ng-template #eTollPopover>
                    <i class="{{getEtollRequiredIcon(vehicle.etollRequired)}} popover-icon"></i>
                    {{'VEHICLE_E_TOLL_REQUIRED' | translate }}
                  </ng-template>

                  <span class="{{getEtollRequiredIcon(vehicle.etollRequired)}}"
                        [popover]="eTollPopover" triggers="mouseenter:mouseleave"></span>
                </a>
                <span *ngIf="!rightModel.vehicleUpdate.hasRight()">
                  {{vehicle.licencePlate}}
                  <ng-template #eTollPopover>
                    <i class="{{getEtollRequiredIcon(vehicle.etollRequired)}} popover-icon"></i>
                          {{'VEHICLE_E_TOLL_REQUIRED' | translate }}
                  </ng-template>

                  <span class="{{getEtollRequiredIcon(vehicle.etollRequired)}}"
                        [popover]="eTollPopover" triggers="mouseenter:mouseleave"></span>
                </span>
                <div class="table-text-subtitle">
                  {{vehicle.externalId}}
                </div>
              </div>
            </div>
          </td>
          <td class="responsive-table-column test_vehicle_type align-middle">{{vehicle.model}}</td>
          <td class="fit align-middle">{{vehicle.kmCounter}} km</td>
          <td class="responsive-table-column hidden-xs-down test_vehicle_company align-middle">{{vehicle.company.name}}</td>
          <td class="fit align-middle">{{vehicle.ownerPersonName}}</td>
          <td class="align-middle">
            <app-table-options-menu>
              <app-dropdown-item
                [iconClass]="'icomoon-detail'"
                [titleStringKey]="'COMMON_BUTTON_DETAILS'"
                class="cursor-pointer"
                uiSref="Admin.VehicleDetail"
                [uiParams]="{ id: vehicle.id }">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="rightModel.vehicleUpdate.hasRight()"
                [iconClass]="'icomoon-modify'"
                [titleStringKey]="'COMMON_BUTTON_EDIT'"
                class="cursor-pointer"
                uiSref="Admin.VehicleEdit"
                [uiParams]="{ id: vehicle.id }">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="vehicle.disabled && rightModel.vehicleDisable.hasRight()"
                [iconClass]="'icomoon-enable'"
                [titleStringKey]="'COMMON_BUTTON_ENABLE'"
                [itemType]="DropdownItemType.ENABLE"
                class="cursor-pointer"
                (click)="setDisabled($event, vehicle, false)">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="!vehicle.disabled && rightModel.vehicleDisable.hasRight()"
                [iconClass]="'icomoon-disable'"
                [titleStringKey]="'COMMON_BUTTON_DISABLE'"
                [itemType]="DropdownItemType.DISABLE"
                class="cursor-pointer"
                (click)="setDisabled($event, vehicle, true)">
              </app-dropdown-item>
            </app-table-options-menu>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="Vehicle.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(Vehicle.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter
              [orderField]="Vehicle.OrderField.LICENCE_PLATE"
              [orderType]="queryModel.getOrderType(Vehicle.OrderField.LICENCE_PLATE)"
              (orderChange)="orderBy($event)"
              [text]="'VEHICLE_LIST_HEADER_LICENCE_PLATE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op
              [text]="'COMMON_TYPE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op
              [text]="'VEHICLE_MILEAGE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-field-sorter [orderField]="Vehicle.OrderField.COMPANY"
                                    [orderType]="queryModel.getOrderType(Vehicle.OrderField.COMPANY)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_COMPANY_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op
              [text]="'VEHICLE_OWNER' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>
  </div>
</div>

