import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { CustomerRecord, CustomerRecordService } from '../../../../lib/customer/customer-record.service';
import { UiConstants } from '../../../../util/core-utils';
import { Strings } from '../../../../lib/util/strings';
import { InputMask } from '../../../../util/input-masks';
import {
  CustomerRecordBillingInfoBaseComponent,
  CustomerRecordBillingInfoComponentMode
} from '../customer-record-billing-info-base/customer-record-billing-info-base.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-customer-record-billing-info-base-dialog',
  templateUrl: './customer-record-billing-info-base-dialog.component.html',
  styleUrls: ['./customer-record-billing-info-base-dialog.component.scss']
})
export class CustomerRecordBillingInfoBaseDialogComponent implements OnInit {
  UiConstants = UiConstants;
  InputMask = InputMask;
  InvoiceVatStatus = CustomerRecord.InvoiceVatStatus;

  @ViewChild(CustomerRecordBillingInfoBaseComponent)
  baseComponent: CustomerRecordBillingInfoBaseComponent;

  get readonly(): boolean {
    return this.data.mode === CustomerRecordBillingInfoComponentMode.DETAIL;
  }

  get headerKey(): string {
    switch (this.data.mode) {
      case CustomerRecordBillingInfoComponentMode.CREATE:
        return 'CUSTOMER_RECORD_BILLING_INFO_CREATE';
      case CustomerRecordBillingInfoComponentMode.EDIT:
        return 'CUSTOMER_RECORD_BILLING_INFO_EDIT';
      case CustomerRecordBillingInfoComponentMode.DETAIL:
        return 'CUSTOMER_RECORD_BILLING_INFO_DETAIL';
    }
    return '';
  }

  constructor(public dialogRef: MatDialogRef<CustomerRecordBillingInfoBaseDialogComponent, CustomerRecordBillingInfoBaseDialogResult>,
              private customerRecordService: CustomerRecordService,
              @Inject(MAT_DIALOG_DATA) public data: CustomerRecordBillingInfoBaseDialogData) {
  }

  public static openDialog(
    dialog: MatDialog,
    data: CustomerRecordBillingInfoBaseDialogData,
    resultCallback: (result?: CustomerRecord.BillingInfo) => void) {
    const dialogRef = dialog.open(CustomerRecordBillingInfoBaseDialogComponent, {
      closeOnNavigation: true,
      data: data,
      width: '60%',
      height: 'auto',
      panelClass: 'custom-dialog-container'
    });
    dialogRef.afterClosed().subscribe((result: CustomerRecordBillingInfoBaseDialogResult) => {
      resultCallback(result.result);
    });
  }

  ngOnInit() {
  }

  submit() {
    if (this.data.mode === CustomerRecordBillingInfoComponentMode.CREATE) {
      this.createBillingInfo();
    }
    else {
      this.editBillingInfo();
    }
  }

  private createBillingInfo() {
    const model = this.baseComponent.model;
    this.customerRecordService.createBillingInfo({
      customerId: this.data.customerId,
      customerRecordId: this.data.customerRecordId,
      name: model.name,
      taxNumber: Strings.undefinedOrNonEmpty(model.taxNumber),
      euTaxNumber: Strings.undefinedOrNonEmpty(model.euTaxNumber),
      bankAccount: Strings.undefinedOrNonEmpty(model.bankAccount),
      invoiceVatStatus: model.invoiceVatStatus!,
      invoiceAddress: model.invoiceAddress.toData()!,
    }).subscribe(result => {
      this.closeDialog(result);
    });
  }

  private editBillingInfo() {
    const model = this.baseComponent.model;
    this.customerRecordService.updateBillingInfo({
      customerId: this.data.customerId,
      customerRecordId: this.data.customerRecordId,
      id: model.id!,
      name: model.name,
      taxNumber: Strings.undefinedOrNonEmpty(model.taxNumber),
      euTaxNumber: Strings.undefinedOrNonEmpty(model.euTaxNumber),
      bankAccount: Strings.undefinedOrNonEmpty(model.bankAccount),
      invoiceVatStatus: model.invoiceVatStatus!,
      invoiceAddress: model.invoiceAddress.toData()!,
    }).subscribe(result => {
      this.closeDialog(result);
    });
  }

  closeDialog(result?: CustomerRecord.BillingInfo) {
    this.dialogRef.close({result: result});
  }

}

export interface CustomerRecordBillingInfoBaseDialogData {
  customerId: number;
  customerRecordId: number;
  mode: CustomerRecordBillingInfoComponentMode;
  data?: CustomerRecord.BillingInfo;
}

export interface CustomerRecordBillingInfoBaseDialogResult {
  result?: CustomerRecord.BillingInfo;
}

