import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Address } from '../../../../lib/address';
import { OrderType } from '../../../../lib/util/services';
import { Set } from 'immutable';
import { CustomerRecord, CustomerRecordService } from '../../../../lib/customer/customer-record.service';
import { UiConstants } from '../../../../util/core-utils';
import { ConfigurationService } from '../../../../lib/core-ext/configuration.service';
import PostalAddressData = Address.PostalAddressData;
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-customer-record-contact-locations-detail-dialog',
  templateUrl: './customer-record-contact-locations-detail-dialog.component.html',
  styleUrls: ['./customer-record-contact-locations-detail-dialog.component.scss']
})
export class CustomerRecordContactLocationsDetailDialogComponent implements OnInit {
  UiConstants = UiConstants;

  @Input()
  customerId: number;

  @Input()
  customerRecordId: number;

  @Input()
  readonly: boolean = true;

  @Output()
  private locationRemoved: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('dialog', { static: true })
  dialog: ModalDirective;
  dialogVisible = true;

  model: Address.ContactLocation;

  contacts: CustomerRecord.CustomerRecord[] = [];

  private postalAddressFormat: string;

  constructor(
    private customerRecordService: CustomerRecordService,
    private configService: ConfigurationService,
    ) {
    this.postalAddressFormat = this.configService.getPostalAddressFormat();
  }

  ngOnInit() {
    this.dialog.onShow.subscribe(() => {
      if (this.model.contactPersons.size > 0) {
        this.loadContacts();
      }
      else {
        this.contacts = [];
      }
    });
  }

  private loadContacts() {
    this.customerRecordService.globalQuery({
      customerRecordIdSet: Set.of(...this.model.contactPersons.toArray()),
      orders: Set.of({type: OrderType.ASC, field: CustomerRecord.OrderField.NAME})
    }).subscribe(result => {
      this.contacts = result.items.toArray();
    });
  }

  removeContactLocation() {
    this.customerRecordService.deleteContactLocation({
      customerId: this.customerId,
      customerRecordId: this.customerRecordId,
      id: this.model.id!
    }).subscribe(() => {
      this.locationRemoved.emit();
      this.closeDialog();
    });
  }

  showDialog(data: Address.ContactLocation) {
    this.model = data;
    this.dialogVisible = true;
    this.dialog.show();
  }

  closeDialog() {
    this.dialogVisible = false;
    this.dialog.hide();
  }

  formatPostalAddress(postalAddress?: PostalAddressData): string {
    return postalAddress ? Address.PostalAddressMapper.toString(postalAddress, this.postalAddressFormat) : '';
  }

}
