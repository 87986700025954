import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Label } from '../shape/label';
import { Group } from '../shape/group';
import { Connection } from '../connection/connection';

@Injectable()
export class CanvasSelectionService {

  private _subject: Subject<CanvasSelectionData> = new Subject();

  constructor() {
  }

  subscribe(next?: (value: CanvasSelectionData) => void, error?: (error: any) => void, complete?: () => void): Subscription {
    return this._subject.subscribe(next, error, complete);
  }

  unsubscribe(subscription: Subscription) {
    subscription.unsubscribe();
  }

  clear() {
    this._subject.unsubscribe();
    this._subject = new Subject();
  }

  onSelectionChanged(data: CanvasSelectionData) {
    this._subject.next(data);
  }

}

export enum CanvasSelectionType {
  NONE,
  TASK,
  GROUP,
  TRANSITION,
  MULTI
}

export interface CanvasSelectionData {
  figure?: Label | Group | Connection;
  type: CanvasSelectionType;
  figures?: (Label | Group | Connection)[];
}
