<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-custom-padding animated fadeIn">
  <form class="form-horizontal bordered-row" (ngSubmit)="f.form.valid && update()" #f="ngForm" novalidate>
    <div class="form-group row mx-0">
      <div class="col-xs-12 col-sm-12 col-md-6 card-custom-padding">
        <div class="card">

          <div class="card-header">
            {{'STOCK_ITEM_HEADING_BASE_DATA'|translate}}
          </div>

          <div class="card-body">

            <div class="form-group row"
                 [ngClass]="{ 'has-danger': (fieldErrors.name) || (f.submitted && !name.valid) }">
              <div class="col-md-4">
                <label class="mw-100 col-form-label form-control-label required-field-label">
                  {{'COMMON_NAME' | translate}}
                </label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
                         maxlength="{{UiConstants.maximumVarcharLength}}"
                         [ngClass]="{ 'form-control-danger': (fieldErrors.name) || (f.submitted && !name.valid) }"
                         [(ngModel)]="model.name" (ngModelChange)="removeFieldError(fieldErrors.name)" name="name"
                         id="name" #name="ngModel" required>
                  <div class="form-control-feedback" *ngIf="fieldErrors.name">{{fieldErrors.name.text}}</div>
                  <div class="form-control-feedback"
                       *ngIf="f.submitted && !name.valid">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                </div>
              </div>
            </div>

            <!--TYPE-->
            <div class="form-group row">
              <div class="form-group col-md-4 mb-0">
                <label readonly
                       class="col-form-label form-control-label detail-title">{{'STOCK_ITEM_TABLE_HEADER_TYPE'|translate}}</label>
              </div>
              <div class="form-group col-md-8 mb-0">
                <label readonly
                       class="col-form-label form-control-label detail-description"> {{ getStockItemTypeText(model.type) }} </label>
              </div>
            </div>

            <div class="form-group row"
                 [ngClass]="{ 'has-danger': (fieldErrors.external_id) || (f.submitted && !external_id.valid) }">
              <div class="col-md-4">
                <label
                  class="mw-100 col-form-label form-control-label required-field-label">
                  {{'STOCK_ITEM_TABLE_HEADER_EXTERNAL_ID' | translate}}
                </label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <input type="text" class="form-control"
                         placeholder="{{'STOCK_ITEM_TABLE_HEADER_EXTERNAL_ID' | translate}}"
                         maxlength="{{UiConstants.maximumVarcharLength}}"
                         [ngClass]="{ 'form-control-danger': (fieldErrors.external_id) || (f.submitted && !external_id.valid) }"
                         [(ngModel)]="model.externalId" (ngModelChange)="removeFieldError(fieldErrors.external_id)"
                         name="external_id" id="external_id" #external_id="ngModel" required>
                  <div class="form-control-feedback"
                       *ngIf="fieldErrors.external_id">{{fieldErrors.external_id.text}}</div>
                  <div class="form-control-feedback"
                       *ngIf="f.submitted && !external_id.valid">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                </div>
              </div>
            </div>

            <div class="form-group row"
                 [ngClass]="{ 'has-danger': (fieldErrors.product_code) || (f.submitted && !product_code.valid) }">
              <div class="col-md-4">
                <label
                  class="mw-100 col-form-label form-control-label required-field-label">
                  {{'STOCK_ITEM_TABLE_HEADER_PRODUCT_CODE' | translate}}
                </label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <input type="text" class="form-control"
                         placeholder="{{'STOCK_ITEM_TABLE_HEADER_PRODUCT_CODE' | translate}}"
                         maxlength="{{UiConstants.maximumVarcharLength}}"
                         [ngClass]="{ 'form-control-danger': (fieldErrors.product_code) || (f.submitted && !product_code.valid) }"
                         [(ngModel)]="model.productCode" (ngModelChange)="removeFieldError(fieldErrors.product_code)"
                         name="product_code" id="product_code" #product_code="ngModel" required>
                  <div class="form-control-feedback" *ngIf="fieldErrors.product_code">{{fieldErrors.product_code.text}}
                  </div>
                  <div class="form-control-feedback" *ngIf="f.submitted && !product_code.valid">
                    {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row"
                 [ngClass]="{ 'has-danger': (fieldErrors.unit) || (f.submitted && !unit.valid) }">
              <div class="col-md-4">
                <label class="mw-100 col-form-label form-control-label required-field-label">
                  {{'STOCK_ITEM_UNIT' | translate}}
                </label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <input type="text" class="form-control" placeholder="{{'STOCK_ITEM_UNIT' | translate}}"
                         maxlength="{{UiConstants.maximumVarcharLength}}"
                         [ngClass]="{ 'form-control-danger': (fieldErrors.unit) || (f.submitted && !unit.valid) }"
                         [(ngModel)]="model.unit" (ngModelChange)="removeFieldError(fieldErrors.unit)"
                         name="unit" id="unit" #unit="ngModel" required>
                  <div class="form-control-feedback" *ngIf="fieldErrors.unit">{{fieldErrors.unit.text}}</div>
                  <div class="form-control-feedback"
                       *ngIf="f.submitted && !unit.valid">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                </div>
              </div>
            </div>

            <div class="form-group row"
                 [ngClass]="{ 'has-danger': (fieldErrors.unit_price) || (f.submitted && !unit_price.valid) }">
              <div class="col-md-4">
                <label class="mw-100 col-form-label form-control-label required-field-label">
                  {{'STOCK_ITEM_UNIT_PRICE' | translate}}
                </label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <input type="text" class="form-control" placeholder="{{'STOCK_ITEM_UNIT_PRICE' | translate}}"
                         maxlength="22"
                         [textMask]="{mask: InputMask.NATURAL_FLOAT_WITH_THOUSAND_SEPARATOR, guide: true}"
                         [ngClass]="{ 'form-control-danger': (fieldErrors.unit_price) || (f.submitted && !unit_price.valid) }"
                         [(ngModel)]="model.unitPrice.value" (ngModelChange)="removeFieldError(fieldErrors.unit_price)"
                         name="unit_price" id="unit_price" #unit_price="ngModel" required>
                  <div class="form-control-feedback"
                       *ngIf="fieldErrors.unit_price">{{fieldErrors.unit_price.text}}</div>
                  <div class="form-control-feedback"
                       *ngIf="f.submitted && !unit_price.valid">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                </div>
              </div>
            </div>

            <div class="form-group row"
                 [ngClass]="{ 'has-danger': (fieldErrors.unit_price_currency_code) || (f.submitted && !unit_price_currency_code.valid) }">
              <div class="col-md-4">
                <label class="mw-100 col-form-label form-control-label required-field-label">
                  {{'STOCK_ITEM_UNIT_PRICE_CURRENCY_CODE' | translate}}
                </label>
              </div>
              <div class="col-md-8">
                <select class="form-control"
                        [ngClass]="{ 'form-control-danger': (fieldErrors.unit_price_currency_code) || (f.submitted && !unit_price_currency_code.valid) }"
                        (ngModelChange)="removeFieldError(fieldErrors.unit_price_currency_code)"
                        [compareWith]="Currency.compareCurrencies"
                        [(ngModel)]="model.unitPrice.currency"
                        name="unit_price_currency_code" id="unit_price_currency_code"
                        #unit_price_currency_code="ngModel" required>
                  <option *ngFor="let currency of selectableCurrencies" [ngValue]="currency">
                    {{currency.localizedName}}
                  </option>
                </select>
                <div class="form-control-feedback" *ngIf="fieldErrors.unit_price_currency_code">
                  {{fieldErrors.unit_price_currency_code.text}}
                </div>
                <div class="form-control-feedback" *ngIf="f.submitted && !unit_price_currency_code.valid">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
              </div>
            </div>

            <div class="form-group row"
                 [ngClass]="{ 'has-danger': (fieldErrors.unit_vat_rate) || (f.submitted && !unit_vat_rate.valid) }">
              <div class="col-md-4">
                <label class="mw-100 col-form-label form-control-label required-field-label">
                  {{'STOCK_ITEM_UNIT_VAT_RATE' | translate}}
                </label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <select class="form-control"
                          [ngClass]="{ 'form-control-danger': (f.submitted && !unit_vat_rate.valid) }"
                          [(ngModel)]="model.unitPrice.vatRate"
                          name="unit_vat_rate" id="unit_vat_rate"
                          #unit_vat_rate="ngModel" required>
                    <option *ngFor="let vatRate of selectableVatRates" [ngValue]="vatRate.value">
                      {{vatRate.value}}%
                    </option>
                  </select>
                  <div class="form-control-feedback" *ngIf="f.submitted && !unit_vat_rate.valid">
                    {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row"
                 *ngIf="model.unitPrice.vatRate === 0"
                 [ngClass]="{ 'has-danger': f.submitted && !zero_vat_rate_reason.valid }">
              <div class="col-md-4">
                <label class="mw-100 col-form-label form-control-label required-field-label">
                  {{'ZERO_VAT_RATE_REASON' | translate}}
                </label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <select class="form-control"
                          [ngClass]="{ 'form-control-danger': f.submitted && !zero_vat_rate_reason.valid }"
                          [(ngModel)]="model.unitPrice.zeroVatRateType"
                          [compareWith]="VatRate.compareZeroVatRateTypeToString"
                          name="zero_vat_rate_reason" id="zero_vat_rate_reason"
                          #zero_vat_rate_reason="ngModel" required>
                    <option *ngFor="let type of selectableZeroVatRateTypes" [ngValue]="type.id">
                      {{type.text}}
                    </option>
                  </select>
                  <div class="form-control-feedback" *ngIf="f.submitted && !unit_vat_rate.valid">
                    {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-4">
                <label class="mw-100 col-form-label form-control-label">{{ 'STOCK_ITEM_PACKAGE' | translate}}</label>
              </div>
              <div class="col-md-8">
                <label class="switch switch-text switch-info mb-0">
                  <input type="checkbox" class="switch-input"
                         [(ngModel)]="model.packageEnabled" name="packageEnabled" id="packageEnabled"
                         #packageEnabled="ngModel">
                  <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                  <span class="switch-handle"></span>
                </label>
              </div>
            </div>
            <div class="form-group row"
                 *ngIf="model.packageEnabled"
                 [ngClass]="{ 'has-danger': fieldErrors.default_package_measurement_id || (f.submitted && !default_package_measurement_id.valid) }">
              <div class="col-md-4">
                <label class="mw-100 col-form-label form-control-label">{{'STOCK_ITEM_PACKAGE' | translate}}</label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <angular2-multiselect [data]="measurements" [settings]="dropdownSettingsForPackage"
                                        [(ngModel)]="model.defaultPackageMeasurement"
                                        (onRemoteSearch)="loadMeasurements($event.target.value)"
                                        [ngClass]="{ 'form-control-danger': fieldErrors.default_package_measurement_id || (f.submitted && !default_package_measurement_id.valid) }"
                                        name="default_package_measurement_id" id="default_package_measurement_id"
                                        #default_package_measurement_id="ngModel" validateEnabledItems
                                        [required]="model.packageEnabled">
                  </angular2-multiselect>
                  <div class="form-control-feedback" *ngIf="fieldErrors.default_package_measurement_id">
                    {{fieldErrors.default_package_measurement_id.text}}
                  </div>
                  <div class="form-control-feedback" *ngIf="f.submitted && !default_package_measurement_id.valid">
                    {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
                  </div>
                </div>
              </div>
            </div>

            <!--region CREATOR USER-->
            <div class="row" *ngIf="model.creatorUserString">
              <div class="form-group col-md-4 mb-0">
                <label
                  class="col-form-label form-control-label detail-title">{{'TASK_RECORD_TABLE_HEADER_CREATOR_USER' |
                  translate}}</label>
              </div>
              <div class="form-group col-md-8 mb-0">
                <label readonly
                       class="col-form-label form-control-label detail-description">{{model.creatorUserString}}</label>
              </div>
            </div>
            <!--endregion CREATOR USER-->
            <!--region CREATED ON-->
            <div class="row">
              <div class="form-group col-md-4 mb-0">
                <label
                  class="col-form-label form-control-label detail-title">{{ 'STOCK_ITEM_CREATED_ON_MOBILE' | translate }}</label>
              </div>
              <div class="form-group col-md-8 mb-0">
                <div class="mw-100">
                  <app-animated-checkbox
                    readonly="true"
                    [selected]="model.createdOnMobile">
                  </app-animated-checkbox>
                </div>
              </div>
            </div>
            <!--endregion CREATED ON-->

            <div class="form-group row">
              <div class="col-md-4">
                <label class="mw-100 col-form-label form-control-label">{{'STOCK_ITEM_PICTURE' | translate}}</label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <div *ngIf="!model.hasPicture">
                    <app-file-upload
                      [uploadPath]="pictureUploadPath"
                      (onResult)="onPictureUpload($event)">
                    </app-file-upload>
                  </div>
                  <div *ngIf="model.hasPicture">
                    <div class="thumbnail-background mb-1" *ngIf="pictureLoaded"
                         [style.background-image]="pictureSrc"></div>
                    <div class="thumbnail-background-placeholder mb-1" *ngIf="!pictureLoaded"></div>
                    <input type="button" class="btn btn-primary" value="{{'COMMON_BUTTON_DOWNLOAD'|translate}}"
                           (click)="downloadPicture()"/>
                    <input type="button" class="btn btn-primary" value="{{'COMMON_BUTTON_DELETE'|translate}}"
                           (click)="deletePicture()"/>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 card-custom-padding">
        <div class="card">

          <div class="card-header">
            {{'STOCK_ITEM_HEADING_MORE_DATA'|translate}}
          </div>

          <div class="card-body">

            <div class="form-group row"
                 [ngClass]="{ 'has-danger': fieldErrors.company_id || (f.submitted && !company.valid) }">
              <div class="col-md-4">
                <label class="mw-100 col-form-label form-control-label">{{'STOCK_ITEM_COMPANY' | translate}}</label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <angular2-multiselect [data]="companies" [settings]="dropdownSettingsForCompanies"
                                        [(ngModel)]="model.company"
                                        [ngClass]="{ 'form-control-danger': fieldErrors.company_id || (f.submitted && !company.valid) }"
                                        (onRemoteSearch)="searchCompanies($event.target.value)"
                                        name="company" id="company" #company="ngModel" validateEnabledItems>
                  </angular2-multiselect>
                  <div class="form-control-feedback" *ngIf="fieldErrors.company_id">
                    {{fieldErrors.company_id.text}}
                  </div>
                  <div class="form-control-feedback" *ngIf="f.submitted && !company.valid">
                    {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row"
                 [ngClass]="{ 'has-danger': (f.submitted && !ledgerNumber.valid) }">
              <div class="col-md-4">
                <label
                  class="mw-100 col-form-label form-control-label">{{'LEDGER_NUMBER_LEDGER_NUMBER' | translate}}</label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <angular2-multiselect [data]="ledgerNumbers" [settings]="dropdownSettingsForCompanies"
                                        [(ngModel)]="model._ledgerNumber"
                                        [ngClass]="{ 'form-control-danger': (f.submitted && !ledgerNumber.valid) }"
                                        (onRemoteSearch)="loadLedgerNumbers($event.target.value)"
                                        name="ledgerNumber" id="ledgerNumber" #ledgerNumber="ngModel"
                                        validateEnabledItems>
                  </angular2-multiselect>
                  <div class="form-control-feedback" *ngIf="f.submitted && !ledgerNumber.valid">
                    {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row"
                 [ngClass]="{ 'has-danger': (fieldErrors.hun_vtsz_number) || (f.submitted && !hun_vtsz_number.valid) }">
              <div class="col-md-4">
                <label class="mw-100 col-form-label form-control-label">{{'STOCK_ITEM_VTSZ_NUMBER' | translate}}</label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <input type="text" class="form-control" placeholder="{{'STOCK_ITEM_VTSZ_NUMBER' | translate}}"
                         maxlength="{{UiConstants.maximumVarcharLength}}"
                         [ngClass]="{ 'form-control-danger': (fieldErrors.hun_vtsz_number) || (f.submitted && !hun_vtsz_number.valid) }"
                         [(ngModel)]="model.vtszNumber" (ngModelChange)="removeFieldError(fieldErrors.hun_vtsz_number)"
                         name="hun_vtsz_number" id="hun_vtsz_number" #hun_vtsz_number="ngModel">
                  <div class="form-control-feedback"
                       *ngIf="fieldErrors.hun_vtsz_number">{{fieldErrors.hun_vtsz_number.text}}</div>
                  <div class="form-control-feedback" *ngIf="f.submitted && !hun_vtsz_number.valid">
                    {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row"
                 [ngClass]="{ 'has-danger': (fieldErrors.product_category)}">
              <div class="col-md-4">
                <label
                  class="mw-100 col-form-label form-control-label">{{'STOCK_ITEM_SHORT_PRODUCT_CODE' | translate}}</label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <input type="text" class="form-control" placeholder="{{'STOCK_ITEM_SHORT_PRODUCT_CODE' | translate}}"
                         [ngClass]="{ 'form-control-danger': (fieldErrors.product_category)}"
                         [(ngModel)]="model.productCategory"
                         (ngModelChange)="removeFieldError(fieldErrors.product_category)"
                         name="product_category" id="product_category" #product_category="ngModel">
                  <div class="form-control-feedback" *ngIf="fieldErrors.product_category">
                    {{fieldErrors.product_category.text}}
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row"
                 [ngClass]="{ 'has-danger': (fieldErrors.category_ids || (f.submitted && !stock_category.valid))}">
              <div class="col-md-4">
                <label
                  class="mw-100 col-form-label form-control-label"
                  [ngClass]="{'required-field-label': categoryRequired}">{{'STOCK_ITEM_PRODUCT_CATEGORY' | translate}}</label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <angular2-multiselect [data]="stockItemCategories" [settings]="dropdownSettingsForProductCategory"
                                        [ngClass]="{ 'form-control-danger': (fieldErrors.category_ids || (f.submitted && !stock_category.valid))}"
                                        [(ngModel)]="model.stockItemCategories"
                                        (onRemoteSearch)="loadStockItemCategories($event.target.value)"
                                        (ngModelChange)="removeFieldError(fieldErrors.category_ids)"
                                        [required]="categoryRequired"
                                        name="stock_category" id="stock_category" #stock_category="ngModel">
                  </angular2-multiselect>
                  <div class="form-control-feedback" *ngIf="fieldErrors.category_ids">
                    {{fieldErrors.category_ids.text}}
                  </div>
                  <div class="form-control-feedback" *ngIf="f.submitted && !stock_category.valid">
                    {{'COMMON_VALIDATION_MESSAGE_REQUIRED'
                    | translate}}
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row"
                 [ngClass]="{ 'has-danger': (fieldErrors.weight_in_grams)}"
                 *ngIf="stockItemType !== 'SERVICE'">
              <div class="col-md-4">
                <label
                  class="mw-100 col-form-label form-control-label">{{'STOCK_ITEM_WEIGHT' | translate}}</label>
              </div>
              <div class="col-md-8">
                <div class="row">
                  <div class="col-md-8 padding-15-15-for-col-md pr-md-0 mb-md-0">
                    <input type="text" class="form-control"
                           placeholder="{{'STOCK_ITEM_WEIGHT' | translate}}"
                           [textMask]="{mask: InputMask.NATURAL_FLOAT_WITH_THOUSAND_SEPARATOR, guide: false}"
                           [(ngModel)]="model.weightValue"
                           name="weight" id="weight" #weight="ngModel">
                  </div>
                  <!-- /.col-md-8 -->
                  <div class="col-md-4 padding-15-15-for-col-md mb-0">
                    <select class="form-control" [compareWith]="SelectUtils.compareStrings"
                            [(ngModel)]="model.weightUnit"
                            [ngModelOptions]="{standalone: true}">
                      <option *ngFor="let item of selectableWeightUnits" [ngValue]="item">{{item}}</option>
                    </select>
                  </div>
                  <!-- /.col-md-4 -->
                  <div class="form-control-feedback" *ngIf="fieldErrors.weight_in_grams">
                    {{fieldErrors.weight_in_grams.text}}
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row"
                 [ngClass]="{ 'has-danger': (fieldErrors.place)}"
                 *ngIf="stockItemType !== 'SERVICE'">
              <div class="col-md-4">
                <label class="mw-100 col-form-label form-control-label">{{'STOCK_ITEM_PLACE' | translate}}</label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <input type="text" class="form-control" placeholder="{{'STOCK_ITEM_PLACE' | translate}}"
                         [ngClass]="{ 'form-control-danger': (fieldErrors.place)}"
                         [(ngModel)]="model.place"
                         (ngModelChange)="removeFieldError(fieldErrors.place)"
                         name="place" id="place" #place="ngModel">
                  <div class="form-control-feedback" *ngIf="fieldErrors.place">
                    {{fieldErrors.place.text}}
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row"
                 [ngClass]="{ 'has-danger': (fieldErrors.serial_code)}">
              <div class="col-md-4">
                <label class="mw-100 col-form-label form-control-label">{{'STOCK_ITEM_SERIAL_CODE' | translate}}</label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <input type="text" class="form-control" placeholder="{{'STOCK_ITEM_SERIAL_CODE' | translate}}"
                         [ngClass]="{ 'form-control-danger': (fieldErrors.serial_code)}"
                         [(ngModel)]="model.serialCode"
                         (ngModelChange)="removeFieldError(fieldErrors.serial_code)"
                         name="serial_code" id="serial_code" #serial_code="ngModel">
                  <div class="form-control-feedback" *ngIf="fieldErrors.serial_code">
                    {{fieldErrors.serial_code.text}}
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-4">
                <label
                  class="mw-100 col-form-label form-control-label">{{ 'STOCK_ITEM_USABLE_FOR_FORM_FIELD' | translate }}</label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <app-animated-checkbox
                    [(selected)]="model.usableForFormField">
                  </app-animated-checkbox>
                </div>
              </div>
            </div>

            <div class="form-group row"
                 [ngClass]="{ 'has-danger': (fieldErrors.description) || (f.submitted && !description.valid) }">
              <div class="col-md-4">
                <label class="mw-100 col-form-label form-control-label">{{ 'COMMON_DESCRIPTION' | translate}}</label>
              </div>
              <div class="col-md-8">
                <!--EDITOR-->
                <div>
                  <quill-editor [styles]="{height: '200px'}"
                                [ngClass]="{ 'form-control-danger': (fieldErrors.description) || (f.submitted && !description.valid) }"
                                [modules]="HTMLEditorModules"
                                [(ngModel)]="model.description"
                                [ngModelOptions]="{standalone: true}"
                                (ngModelChange)="removeFieldError(fieldErrors.description)"
                                name="description" id="description" #description="ngModel"
                                placeholder="{{'COMMON_DESCRIPTION' | translate}}">
                  </quill-editor>
                </div>
                <div class="form-control-feedback"
                     *ngIf="fieldErrors.description">{{fieldErrors.description.text}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 card-custom-padding">
      <app-stock-item-customer-record-price-list
        #customerRecordPriceListComponent
        [stockItemId]="stockItemId"
        [rightModel]="rightModel"
        [readonly]="false"
        [vatRate]="model.unitPrice.vatRate"
        [currency]="model.unitPrice.currency"
        (onCreate)="showCustomerRecordPriceDialog()"
        (onEdit)="showCustomerRecordPriceDialog($event)">
      </app-stock-item-customer-record-price-list>
    </div>
    <div class="container-vertical-padding-half"></div>
    <div class="col-12 card-custom-padding" *ngIf="model">
      <app-stock-item-measurement-list
        [stockItemId]="stockItemId"
        [rightModel]="rightModel"
        [readonly]="false">
      </app-stock-item-measurement-list>
    </div>
    <input type="submit" class="btn btn-primary floating_save_button" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
  </form>
</div>

<!-- customer record price create -->
<div bsModal [config]="UiConstants.modalConfig" #customerRecordPriceDialog="bs-modal" class="modal fade" tabindex="-1"
     role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <form class="form-horizontal bordered-row"
          (ngSubmit)="customerRecordPriceForm.form.valid && saveCustomerRecordPrice()" #customerRecordPriceForm="ngForm"
          novalidate>
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{getCustomerRecordPriceDialogHeading() | translate}}</h4>
          <button type="button" class="close" (click)="closeCustomerRecordPriceDialog()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card-body">
            <div class="form-group row"
                 [ngClass]="{ 'has-danger': (customerRecordPriceForm.submitted && !price.valid)}">
              <label
                class="col-form-label col-md-4">{{'STOCK_ITEM_CUSTOMER_RECORD_PRICE_CUSTOM_UNIT_PRICE' | translate}}</label>
              <div class="col-md-8">
                <div class="row m-0">
                  <input type="text" class="form-control col-md-8"
                         maxlength="21"
                         [ngClass]="{ 'has-danger': (customerRecordPriceForm.submitted && !price.valid)}"
                         [(ngModel)]="customerRecordPriceModel.price" #price="ngModel"
                         placeholder="{{'STOCK_ITEM_CUSTOMER_RECORD_PRICE_CUSTOM_UNIT_PRICE' | translate}}"
                         [textMask]="{mask: InputMask.NATURAL_FLOAT, guide: false}"
                         name="price" id="price" required>
                  <label class="col-form-label col-md-4">{{model.unitPrice.currency.localizedName}}</label>
                </div>
                <div class="form-control-feedback" *ngIf="customerRecordPriceForm.submitted && !price.valid">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
              </div>
            </div>

            <div class="form-group row"
                 [ngClass]="{ 'has-danger': (customerRecordPriceForm.submitted && !customerRecord.valid)}">
              <label
                class="col-form-label col-md-4">{{'STOCK_ITEM_CUSTOMER_RECORD_PRICE_CUSTOMER_RECORD' | translate}}</label>
              <div class="col-md-8">
                <angular2-multiselect [data]="customerRecords" [settings]="dropdownSettingsForCreateEdit"
                                      (onRemoteSearch)="onCustomerRecordSearch($event.target.value)"
                                      class="form-control"
                                      [(ngModel)]="customerRecordPriceModel.customerRecord"
                                      [ngClass]="{ 'has-danger': (customerRecordPriceForm.submitted && !customerRecord.valid)}"
                                      id="customerRecord"
                                      name="customerRecord"
                                      #customerRecord="ngModel"
                                      required validateEnabledItems>
                </angular2-multiselect>
                <div class="form-control-feedback" *ngIf="customerRecordPriceForm.submitted && !customerRecord.valid">
                  <span
                    *ngIf="customerRecord.errors['required']">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
                  <span
                    *ngIf="customerRecord.errors['hasDisabledItem']">{{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}</span>
                </div>
              </div>
            </div>
          </div> <!-- ./card-body end -->
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary"
                  (click)="closeCustomerRecordPriceDialog()">{{'COMMON_BUTTON_CANCEL' |
            translate}}
          </button>
          <button type="submit" class="btn btn-primary">{{'COMMON_BUTTON_SAVE'
            | translate}}
          </button>
        </div>
      </div><!-- /.modal-content -->
    </form>
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
