export class Numbers {

  /**
   * @deprecated use class Models#parseNumber instead
   */
  public static undefinedOrInt(text: string | undefined | null): number | undefined {
    if (!text || text === null) {
      return undefined;
    }
    text = text.toString().trim();
    if (text.length < 1) {
      return undefined;
    }
    text = text.replace(/ /g, '');
    return Number.parseInt(text, 10);
  }

  public static undefinedOrNonEmpty(number: number | undefined | null): string | undefined {
    if (number === undefined || number === null || isNaN(number)) {
      return undefined;
    }
    const tmp: string = number.toString().trim();
    if (tmp.length < 1) {
      return undefined;
    }
    return tmp;
  }

  /**
   * @deprecated use Models#parseNumber instead
   */
  public static undefinedOrFloat(text: string | undefined | null): number | undefined {
    if (!text || text === null) {
      return undefined;
    }
    text = text.toString().trim();
    if (text.length < 1) {
      return undefined;
    }
    text = text.replace(/ /g, '');
    return Number.parseFloat(text);
  }

  /**
   * @deprecated use Models#numberToString instead
   */
  public static toString(number: number | undefined | null): string {
    if (!number && number !== 0) {
      return '';
    }
    return number.toString();
  }


  public static padNumber(num?: number, size?: number, char?: string): string {
    if (num === undefined) {
      return '';
    }
    if (size === undefined) {
      return '' + num;
    }
    if (char === undefined) {
      char = '0';
    }
    let s = num + '';
    while (s.length < size) {
      s = char + s;
    }
    return s;
  }
}
