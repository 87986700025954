<div bsModal [config]="UiConstants.modalConfig" #userGroupCreateDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true"
     (onShown)="onModalShown()">
  <div class="modal-dialog modal-lg" role="document" *ngIf="userGroupCreateDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title"><strong>{{'USER_GROUP_PANEL_HEADING_CREATE' | translate}}</strong></p>
        <button type="button" class="close" (click)="closeUserGroupCreateDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form-horizontal bordered-row" id="user_group_create_form" (ngSubmit)="f.form.valid && create()" #f="ngForm" novalidate>

          <!-- region Name -->
          <div class="form-group row" [ngClass]="{ 'has-danger': fieldErrors.name || hasLocalFieldError(name) }">
            <div class="col-md-4">
              <label for="name" class="mw-100 col-form-label form-control-label required-field-label">
                {{'COMMON_NAME'|translate}}
              </label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <input type="text" [(ngModel)]="model.name" (ngModelChange)="removeFieldError(fieldErrors.name)"
                       maxlength="{{getTextMaximumLength()}}" class="form-control"
                       [ngClass]="{ 'form-control-danger': fieldErrors.name }" name="name" id="name" #name="ngModel"
                       placeholder="{{'USER_GROUP_PLACEHOLDER_NAME'|translate}}" required />
                <div class="form-control-feedback" *ngIf="fieldErrors.name">{{fieldErrors.name.text}}</div>
                <div class="form-control-feedback" *ngIf="hasLocalFieldError(name)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
              </div>
            </div>
          </div>
          <!-- endregion Name -->

          <!-- region Name -->
          <div class="form-group row" [ngClass]="{ 'has-danger': fieldErrors.external_id || hasLocalFieldError(external_id) }">
            <div class="col-md-4">
              <label for="external_id" class="mw-100 col-form-label form-control-label required-field-label">
                {{'COMMON_EXTERNAL_ID'|translate}}
              </label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <input type="text" [(ngModel)]="model.externalId" (ngModelChange)="removeFieldError(fieldErrors.external_id)"
                       maxlength="{{getTextMaximumLength()}}" class="form-control"
                       [ngClass]="{ 'form-control-danger': fieldErrors.external_id }" name="external_id" id="external_id" #external_id="ngModel"
                       placeholder="{{'COMMON_EXTERNAL_ID'|translate}}" />
                <div class="form-control-feedback" *ngIf="fieldErrors.external_id">{{fieldErrors.external_id.text}}</div>
              </div>
            </div>
          </div>
          <!-- endregion Name -->
          <!-- region Company -->
          <div class="form-group row">
            <label class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_COMPANY'|translate}}</label>
            <label class="col-form-label form-control-label col-md-4 detail-title">{{getSelectedCompanyName()}}</label>
          </div>
          <!-- endregion Company -->

          <!-- region Roles -->
          <div class="form-group row">
            <div class="col-md-4">
              <label class="mw-100 col-form-label form-control-label">{{'ROLE_LIST_HEADING'|translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  [(ngModel)]="model.roles"
                  [ngModelOptions]="{standalone: true}"
                  [data]="selectableRoles"
                  [settings]="dropdownSettings">
                </angular2-multiselect>
              </div>
            </div>
          </div>
          <!-- endregion Roles -->

          <!-- region Application types -->
          <div class="form-group row">
            <div class="col-md-4">
              <label class="mw-100 col-form-label form-control-label">{{'USER_GROUP_LABEL_SCOPE'|translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  [(ngModel)]="model.application_types"
                  [ngModelOptions]="{standalone: true}"
                  [data]="applicationTypes"
                  [settings]="dropdownSettings">
                </angular2-multiselect>
              </div>
            </div>
          </div>
          <!-- endregion Application types -->

          <!-- region Users -->
          <div class="form-group row">
            <div class="col-md-4">
              <label class="mw-100 col-form-label form-control-label">{{'COMMON_USERS'|translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  id="user_group_create_users_select"
                  [(ngModel)]="model.users"
                  [ngModelOptions]="{standalone: true}"
                  [data]="selectableUsers"
                  [settings]="dropdownSettingsForUser"
                  (onSelect)="onUserSelect($event)"
                  (onDeSelect)="onUserDeSelect($event)"
                  (onSelectAll)="onSelectAllUser($event)"
                  (onDeSelectAll)="onDeSelectAllUser($event)">
                </angular2-multiselect>
              </div>
            </div>
          </div>
          <!-- endregion Users -->

          <div class="col-md-12 d-flex justify-content-end p-0 pl-1">
            <div>
              <button type="button" class="btn btn-outline-primary" (click)="closeUserGroupCreateDialog()">
                {{'COMMON_BUTTON_CANCEL' | translate}}
              </button>
              <button type="submit" class="btn btn-primary ml-1">{{'COMMON_BUTTON_SAVE' |
                translate}}
              </button>
            </div>
          </div>

        </form>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
