import * as draw2d from '../../../../../../node_modules/draw2d/dist/draw2d.js';
import {Component, Input, OnInit} from '@angular/core';
import {CanvasSelectionData, CanvasSelectionType} from '../utils/service/canvas-selection-service';
import {Workflow, WorkflowService} from '../../../../lib/workflow/workflow.service';
import {MultiselectOptionItem, UiConstants} from '../../../../util/core-utils';
import {Angular2Multiselects} from '../../../../util/multiselect';
import {Strings} from '../../../../lib/util/strings';
import {Set} from 'immutable';
import {WorkflowEditorCanvas} from '../utils/workflow-editor-canvas';
import {TaskRecordStateMachine} from '../../../../lib/task/task-record-statemachine';
import {Connection, ConnectionUserData} from '../utils/connection/connection';
import {TranslateService} from '@ngx-translate/core';
import {Label} from '../utils/shape/label';
import {Group} from '../utils/shape/group';
import {FormFieldItem} from './form-field-rule-edit/form-field-rule-edit.component';
import {ToasterService} from '../../../../fork/angular2-toaster/src/toaster.service';
import {StringKey} from '../../../../app.string-keys';
import {ProcessTaskRecordConfigNameTemplate} from '../../../process/process-base-dialog/process-create.model';
import {UserMultiselectProvider} from '../../../../lib/user/user-multiselect.provider';
import {UserGroupMultiselectProvider} from '../../../../lib/user-group/user-group-multiselect.provider';
import {RightModel} from '../../../../app.rights';
import {RightResolver, RightService} from '../../../../lib/right.service';
import {WorkflowRuleUtils} from "../../../../lib/form/workflow-rule-utils";
import {TaskRecord} from "../../../../lib/task/task-record.service";

@Component({
  selector: 'app-workflow-create-editor-data-edit',
  templateUrl: './workflow-create-editor-data-edit.component.html',
  styleUrls: ['./workflow-create-editor-data-edit.component.scss']
})
export class WorkflowCreateEditorDataEditComponent implements OnInit {

  CanvasSelectionType = CanvasSelectionType;
  UiConstants = UiConstants;
  ProcessTaskRecordConfigNameTemplate = ProcessTaskRecordConfigNameTemplate;
  InheritanceMode = Workflow.InheritanceMode;
  WorkflowTaskEditModel = WorkflowTaskEditModel;

  rightModel: RightModel = RightModel.empty();

  @Input()
  workflowId: number;

  @Input()
  set canvasSelection(data: CanvasSelectionData) {
    this.type = data.type;
    this.figure = data.figure;
    switch (this.type) {
      case CanvasSelectionType.TASK:
        this.createTaskEditModel(data.figure);
        break;
      case CanvasSelectionType.GROUP:
        this.createGroupEditModel(data.figure);
        break;
      case CanvasSelectionType.TRANSITION:
        this.createTransitionEditModel(data.figure);
        break;
      case CanvasSelectionType.MULTI:
        this.createMultiEditModel(data.figures!);
        break;
    }
  }

  @Input()
  canvas: WorkflowEditorCanvas;

  @Input()
  readonly: boolean;

  @Input()
  defaultInheritanceMode?: Workflow.InheritanceMode;

  type: CanvasSelectionType = CanvasSelectionType.NONE;
  figure?: draw2d.Figure;

  taskEditModel: WorkflowTaskEditModel = new WorkflowTaskEditModel();
  groupEditModel: WorkflowGroupEditModel = new WorkflowGroupEditModel();
  transitionEditModel: WorkflowTransitionEditModel = new WorkflowTransitionEditModel();
  multiEditModel: WorkflowMultiEditModel = new WorkflowMultiEditModel();

  dropdownSettingsForInheritanceMode: Angular2Multiselects.Settings;
  dropdownSettingsForWorkflowTask: Angular2Multiselects.Settings;
  dropdownSettingsForTaskRecordState: Angular2Multiselects.Settings;
  dropdownSettingsForAssignee: Angular2Multiselects.Settings;

  inheritanceModes: MultiselectOptionItem<Workflow.InheritanceMode>[]
    = Workflow.inheritanceModes.map(o => ({id: o.mode, itemName: o.stringKey}));
  assigneeInheritanceModes: MultiselectOptionItem<Workflow.InheritanceMode>[]
    = Workflow.assigneeInheritanceModes.map(o => ({id: o.mode, itemName: o.stringKey}));
  taskRecordStates: MultiselectOptionItem<TaskRecordStateMachine.State>[]
    = TaskRecordStateMachine.getOrderedStates(true).toArray()
    .filter(s => ['SUBMITTED', 'FINISHED', 'REVERTED'].includes(s.state))
    .map(s => ({id: s.state, itemName: s.stringKey}));
  users: MultiselectOptionItem<number>[] = [];
  userGroups: MultiselectOptionItem<number>[] = [];
  _workflowTasks: MultiselectOptionItem<number>[] = [];

  constructor(
    private workflowService: WorkflowService,
    private toasterService: ToasterService,
    private rightService: RightService,
    private translateService: TranslateService,
    private userMultiselectProvider: UserMultiselectProvider,
    private usergroupMultiselectProvider: UserGroupMultiselectProvider,
  ) {
  }

  ngOnInit() {
    this.initDropDownSettings();
    this.loadUsers();
    this.loadUserGroups();
    this.loadRightModels();
  }

  private initDropDownSettings() {
    this.dropdownSettingsForInheritanceMode = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(false)
      .enableCheckAll(false)
      .remoteSearch(false)
      .translate(true)
      .build();
    this.dropdownSettingsForWorkflowTask = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(false)
      .translate(false)
      .build();
    this.dropdownSettingsForTaskRecordState = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(false)
      .enableCheckAll(true)
      .remoteSearch(false)
      .translate(true)
      .build();
    this.dropdownSettingsForAssignee = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .remoteSearch(true)
      .enableCheckAll(false)
      .build();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  private createTaskEditModel(figure: draw2d.Figure) {
    const task: Workflow.Task = figure.getUserData();
    this.taskEditModel = new WorkflowTaskEditModel();
    this.taskEditModel.objectId = task.id;
    this.taskEditModel.name = task.task.name;
    this.taskEditModel.id = task.task.id;
    this.taskEditModel.assigneeInheritanceMode = this.getInheritanceModeItemArray(task.assigneeInheritanceMode);
    this.taskEditModel.namePattern = task.namePattern ? task.namePattern : '';
    this.taskEditModel.customerInheritanceMode = this.getInheritanceModeItemArray(task.customerInheritanceMode);
    this.taskEditModel.priorityInheritanceMode = this.getInheritanceModeItemArray(task.priorityInheritanceMode);
    this.taskEditModel.deadlineInheritanceMode = this.getInheritanceModeItemArray(task.deadlineInheritanceMode);
    this.taskEditModel.projectInheritanceMode = this.getInheritanceModeItemArray(task.projectInheritanceMode);
    this.taskEditModel.attachmentInheritanceMode = this.getInheritanceModeItemArray(task.attachmentInheritanceMode);
    this.taskEditModel.pdfInheritanceMode = this.getInheritanceModeItemArray(task.pdfInheritanceMode);
    this.taskEditModel.contractNumberInheritanceMode = this.getInheritanceModeItemArray(task.contractNumberInheritanceMode);
    this.taskEditModel.placeOfConsumptionInheritanceMode = this.getInheritanceModeItemArray(task.placeOfConsumptionInheritanceMode);
    this.taskEditModel.formFieldInheritanceMode = this.getInheritanceModeItemArray(task.formFieldInheritanceMode);
    this.taskEditModel.openTaskRecordOnCreate = task.openTaskRecordOnCreate;
    this.taskEditModel._defaultAssigneeUser = [];
    if (task.defaultAssigneeUser) {
      this.taskEditModel._defaultAssigneeUser.push({
        id: task.defaultAssigneeUser.id,
        itemName: task.defaultAssigneeUser.personName,
        itemSubtitle: task.defaultAssigneeUser.userName
      });
    }
    this.taskEditModel._defaultAssigneeUserGroup = [];
    if (task.defaultAssigneeUserGroup) {
      this.taskEditModel._defaultAssigneeUserGroup.push({
        id: task.defaultAssigneeUserGroup.id,
        itemName: task.defaultAssigneeUserGroup.name
      });
    }
    this.taskEditModel._inheritFromWorkflowTask = [];
    if (task.inheritFromWorkflowTask) {
      this.taskEditModel._inheritFromWorkflowTask.push({
        id: task.inheritFromWorkflowTask.id,
        itemName: task.inheritFromWorkflowTask.name,
        itemSubtitle: '' + task.inheritFromWorkflowTask.id
      });
    }
    this.loadWorkflowTasks();
  }

  private getInheritanceModeItem(mode: Workflow.InheritanceMode): MultiselectOptionItem<Workflow.InheritanceMode> {
    const stringKey = Workflow.assigneeInheritanceModes.find(o => o.mode === mode)!.stringKey;
    return {
      id: mode,
      itemName: stringKey
    };
  }

  private getInheritanceModeItemArray(mode?: Workflow.InheritanceMode): MultiselectOptionItem<Workflow.InheritanceMode>[] {
    if (mode) {
      return [this.getInheritanceModeItem(mode)];
    } else if (this.defaultInheritanceMode) {
      return [this.getInheritanceModeItem(this.defaultInheritanceMode)];
    }
    return [];
  }

  saveTask() {
    this.taskEditModel.saveInProgress = true;
    this.workflowService.updateTask({
      workflowId: this.workflowId,
      taskId: this.taskEditModel.objectId,
      namePattern: Strings.undefinedOrNonEmpty(this.taskEditModel.namePattern),
      openTaskRecordOnCreate: this.taskEditModel.openTaskRecordOnCreate,
      assigneeInheritanceMode: WorkflowTaskEditModel.getInheritanceMode(this.taskEditModel.assigneeInheritanceMode)!,
      customerInheritanceMode: WorkflowTaskEditModel.getInheritanceMode(this.taskEditModel.customerInheritanceMode)!,
      placeOfConsumptionInheritanceMode: WorkflowTaskEditModel.getInheritanceMode(this.taskEditModel.placeOfConsumptionInheritanceMode)!,
      priorityInheritanceMode: WorkflowTaskEditModel.getInheritanceMode(this.taskEditModel.priorityInheritanceMode)!,
      deadlineInheritanceMode: WorkflowTaskEditModel.getInheritanceMode(this.taskEditModel.deadlineInheritanceMode)!,
      projectInheritanceMode: WorkflowTaskEditModel.getInheritanceMode(this.taskEditModel.projectInheritanceMode)!,
      attachmentInheritanceMode: WorkflowTaskEditModel.getInheritanceMode(this.taskEditModel.attachmentInheritanceMode)!,
      pdfInheritanceMode: WorkflowTaskEditModel.getInheritanceMode(this.taskEditModel.pdfInheritanceMode)!,
      contractNumberInheritanceMode: WorkflowTaskEditModel.getInheritanceMode(this.taskEditModel.contractNumberInheritanceMode)!,
      formFieldInheritanceMode: WorkflowTaskEditModel.getInheritanceMode(this.taskEditModel.formFieldInheritanceMode)!,
      defaultAssigneeUser: this.taskEditModel.defaultAssigneeUser ? this.taskEditModel.defaultAssigneeUser.id : undefined,
      defaultAssigneeUserGroup: this.taskEditModel.defaultAssigneeUserGroup ? this.taskEditModel.defaultAssigneeUserGroup.id : undefined,
      inheritFromWorkflowTask: this.taskEditModel.inheritFromWorkflowTask ? this.taskEditModel.inheritFromWorkflowTask.id : undefined
    }).subscribe(result => {
        this.taskEditModel.saveInProgress = false;
        this.figure!.setUserData(this.taskEditModel.getTaskData());
        this.toasterService.pop({
          timeout: UiConstants.ToastTimeoutShort,
          type: UiConstants.toastTypeSuccess,
          title: this.translateService.instant(StringKey.COMMON_SUCCESS),
          body: this.translateService.instant(StringKey.WORKFLOW_EDITOR_TASK_SAVE_SUCCESS)
        });
      },
      error => {
        this.taskEditModel.saveInProgress = false;
        this.toasterService.pop({
          timeout: UiConstants.ToastTimeoutShort,
          type: UiConstants.toastTypeError,
          title: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_TITLE),
          body: this.translateService.instant(StringKey.WORKFLOW_EDITOR_TASK_SAVE_ERROR)
        });
      });
  }

  private createGroupEditModel(figure: draw2d.Figure) {
    const group: Workflow.Group = figure.getUserData();
    const children: draw2d.Figure[] = figure.getChildren().data[1].getChildren().data;
    const tasks = children.map(child => child.getUserData().task);
    this.groupEditModel = new WorkflowGroupEditModel();
    this.groupEditModel.objectId = group.id;
    this.groupEditModel.location = group.location;
    this.groupEditModel.children = children;
    this.groupEditModel.name = group.name;
    this.groupEditModel.tasks = tasks;
  }

  saveGroup() {
    this.groupEditModel.saveInProgress = true;
    this.workflowService.updateGroup({
      workflowId: this.workflowId,
      groupId: this.groupEditModel.objectId,
      name: this.groupEditModel.name
    }).subscribe(result => {
        this.groupEditModel.saveInProgress = false;
        if (this.groupEditModel.name.length > 0) {
          this.figure.setName(this.groupEditModel.name);
        } else {
          // todo: from dictionary
          this.figure.setName('Group');
        }
        this.figure!.setUserData(this.groupEditModel.getGroupData());
        this.toasterService.pop({
          timeout: UiConstants.ToastTimeoutShort,
          type: UiConstants.toastTypeSuccess,
          title: this.translateService.instant(StringKey.COMMON_SUCCESS),
          body: this.translateService.instant(StringKey.WORKFLOW_EDITOR_GROUP_SAVE_SUCCESS)
        });
      },
      error => {
        this.groupEditModel.saveInProgress = false;
        this.toasterService.pop({
          timeout: UiConstants.ToastTimeoutShort,
          type: UiConstants.toastTypeError,
          title: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_TITLE),
          body: this.translateService.instant(StringKey.WORKFLOW_EDITOR_GROUP_SAVE_ERROR)
        });
      });
  }

  deleteTask(figure) {
    this.canvas.deleteObject(figure);
    figure!.getConnections().data.forEach(c => {
      this.canvas.remove(c);
    });
    if (figure.parent) {
      figure.parent.parent.removeChild(figure);
    } else {
      this.canvas.remove(figure);
    }
  }

  deleteGroup(figure) {
    this.canvas.deleteObject(figure);
    figure!.getConnections().data.forEach(c => {
      this.canvas.remove(c);
    });
    this.canvas.remove(figure);
  }

  deleteMulti() {
    for (let i = 0; i < this.multiEditModel.figures.length; i++) {
      const f = this.multiEditModel.figures[i];
      if (f instanceof Label) {
        this.deleteTask(f);
      } else if (f instanceof Group) {
        this.deleteGroup(f);
      }
    }
  }

  private createTransitionEditModel(figure: draw2d.Figure) {
    const userData: ConnectionUserData = figure.getUserData();
    const transition: Workflow.Transition = userData.transition;
    this.transitionEditModel = new WorkflowTransitionEditModel();
    this.transitionEditModel.objectId = transition.id;
    this.transitionEditModel.inputObjectId = transition.inputObjectId;
    this.transitionEditModel.outputObjectId = transition.outputObjectId;
    this.transitionEditModel.addedByPainter = userData.addedByPainter;
    const sourceObject = figure.getSource().getParent();
    if (sourceObject instanceof Label) {
      this.transitionEditModel.taskIds.push(sourceObject.getUserData().task.id);
    } else if (sourceObject instanceof Group) {
      sourceObject.getChildren().data[1].getChildren().data.forEach(c => {
        this.transitionEditModel.taskIds.push(c.getUserData().task.id);
      });
    }

    transition.rule.taskRecordStates.forEach(state => {
      this.transitionEditModel.taskRecordStates.push(this.taskRecordStates.find(s => s.id === state)!);
    });

    transition.rule.rules.forEach(rule => {
      const model = WorkflowTransitionRuleModel.createModel(rule, this.translateService);
      this.transitionEditModel.rules.push(model);
    });
  }
  private createMultiEditModel(figures: (Label | Group | Connection)[]) {
    this.multiEditModel = new WorkflowMultiEditModel();
    this.multiEditModel.figures = figures;
  }

  newFormFieldRule() {
    this.transitionEditModel.addRuleModel = new WorkflowTransitionFormFieldRuleModel(this.translateService, undefined);
  }

  newTaskRule() {
    this.transitionEditModel.addRuleModel = new WorkflowTransitionTaskRuleModel(this.translateService, undefined);
  }

  ruleCreateSubmitted(result: boolean) {
    if (result) {
      this.transitionEditModel.rules.push(this.transitionEditModel.addRuleModel!);
    }
    this.transitionEditModel.addRuleModel = undefined;
  }

  editRule(index: number) {
    this.transitionEditModel.rules.forEach((rule, i) => {
      rule.editing = i === index;
    });
  }

  deleteRule(index: number) {
    this.transitionEditModel.rules.splice(index, 1);
    if (index === 0 && this.transitionEditModel.rules.length > 0) {
      this.transitionEditModel.rules[0].operator = undefined;
      this.transitionEditModel.rules[0].refreshName();
    }
  }

  saveTransition() {
    this.transitionEditModel.saveInProgress = true;
    this.workflowService.updateTransitionRule({
      workflowId: this.workflowId,
      transitionId: this.transitionEditModel.objectId,
      taskRecordStates: Set.of(...this.transitionEditModel.taskRecordStates.map(s => s.id)),
      rules: this.transitionEditModel.rules.map(rule => rule.toRequest())
    }).subscribe(result => {
        this.transitionEditModel.saveInProgress = false;
        this.figure!.setUserData(this.transitionEditModel.getTransitionData());
        this.toasterService.pop({
          timeout: UiConstants.ToastTimeoutShort,
          type: UiConstants.toastTypeSuccess,
          title: this.translateService.instant(StringKey.COMMON_SUCCESS),
          body: this.translateService.instant(StringKey.WORKFLOW_EDITOR_TRANSITION_SAVE_SUCCESS)
        });
      },
      error => {
        this.transitionEditModel.saveInProgress = false;
        this.toasterService.pop({
          timeout: UiConstants.ToastTimeoutShort,
          type: UiConstants.toastTypeError,
          title: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_TITLE),
          body: this.translateService.instant(StringKey.WORKFLOW_EDITOR_TRANSITION_SAVE_ERROR)
        });
      });
  }

  deleteTransition() {
    this.canvas.deleteTransition(this.figure);
    this.canvas.remove(this.figure);
  }

  onAssigneeInheritanceModeChanged() {
    this.taskEditModel._defaultAssigneeUser = [];
    this.taskEditModel._defaultAssigneeUserGroup = [];
  }

  loadUsers(q?: string) {
    this.userMultiselectProvider.loadActive(q).subscribe(result => this.users = result);
  }

  onUserChanged() {
    this.taskEditModel._defaultAssigneeUserGroup = [];
  }

  loadUserGroups(q?: string) {
    this.usergroupMultiselectProvider.loadActive(q).subscribe(result => this.userGroups = result);
  }

  loadWorkflowTasks() {
    this._workflowTasks = [];
    this.workflowService.get({
      id: this.workflowId,
      fields: f => f.each,
    }).subscribe(result => {
      result.stateMachine?.tasks.forEach(t => {
        if (t.id !== this.taskEditModel.objectId) {
          this._workflowTasks.push({
            id: t.id,
            itemName: t.task.name,
            itemSubtitle: '' + t.id
          })
        }
      });
      if (this.taskEditModel.inheritFromWorkflowTask) {
        const inheritId = this.taskEditModel.inheritFromWorkflowTask.id;
        if (this._workflowTasks.filter(tt => tt.id === inheritId).length === 0) {
          this.taskEditModel.inheritFromWorkflowTask.disabled = true;
        }
      }
    });
  }

  onUserGroupChanged() {
    this.taskEditModel._defaultAssigneeUser = [];
  }

}

class WorkflowTaskEditModel {
  objectId: number;
  saveInProgress: boolean = false;

  name: string;
  id: number;
  _inheritFromWorkflowTask: MultiselectOptionItem<number>[];
  _defaultAssigneeUser: MultiselectOptionItem<number>[];
  _defaultAssigneeUserGroup: MultiselectOptionItem<number>[];
  namePattern: string;
  assigneeInheritanceMode: MultiselectOptionItem<Workflow.InheritanceMode>[];
  customerInheritanceMode: MultiselectOptionItem<Workflow.InheritanceMode>[];
  priorityInheritanceMode: MultiselectOptionItem<Workflow.InheritanceMode>[];
  deadlineInheritanceMode: MultiselectOptionItem<Workflow.InheritanceMode>[];
  projectInheritanceMode: MultiselectOptionItem<Workflow.InheritanceMode>[];
  attachmentInheritanceMode: MultiselectOptionItem<Workflow.InheritanceMode>[];
  pdfInheritanceMode: MultiselectOptionItem<Workflow.InheritanceMode>[];
  contractNumberInheritanceMode: MultiselectOptionItem<Workflow.InheritanceMode>[];
  placeOfConsumptionInheritanceMode: MultiselectOptionItem<Workflow.InheritanceMode>[];
  formFieldInheritanceMode: MultiselectOptionItem<Workflow.InheritanceMode>[];
  openTaskRecordOnCreate: boolean;

  get defaultAssigneeUser(): MultiselectOptionItem<number> | undefined {
    return this._defaultAssigneeUser.length === 1 ? this._defaultAssigneeUser[0] : undefined;
  }

  get defaultAssigneeUserGroup(): MultiselectOptionItem<number> | undefined {
    return this._defaultAssigneeUserGroup.length === 1 ? this._defaultAssigneeUserGroup[0] : undefined;
  }

  get inheritFromWorkflowTask(): MultiselectOptionItem<number> | undefined {
    if (!this.allowWorkflowTaskSelector()) {
      return undefined;
    }
    return this._inheritFromWorkflowTask.length === 1 ? this._inheritFromWorkflowTask[0] : undefined;
  }

  static getInheritanceMode(arr: MultiselectOptionItem<Workflow.InheritanceMode>[]): Workflow.InheritanceMode | undefined {
    if (arr && arr.length === 1) {
      return arr[0].id;
    }
    return undefined;
  }

  getTaskData(): Workflow.Task {
    return {
      id: this.objectId,
      task: {
        id: this.id,
        name: this.name
      },
      namePattern: Strings.undefinedOrNonEmpty(this.namePattern),
      openTaskRecordOnCreate: this.openTaskRecordOnCreate,
      assigneeInheritanceMode: WorkflowTaskEditModel.getInheritanceMode(this.assigneeInheritanceMode),
      customerInheritanceMode: WorkflowTaskEditModel.getInheritanceMode(this.customerInheritanceMode),
      placeOfConsumptionInheritanceMode: WorkflowTaskEditModel.getInheritanceMode(this.placeOfConsumptionInheritanceMode),
      priorityInheritanceMode: WorkflowTaskEditModel.getInheritanceMode(this.priorityInheritanceMode),
      deadlineInheritanceMode: WorkflowTaskEditModel.getInheritanceMode(this.deadlineInheritanceMode),
      projectInheritanceMode: WorkflowTaskEditModel.getInheritanceMode(this.projectInheritanceMode),
      attachmentInheritanceMode: WorkflowTaskEditModel.getInheritanceMode(this.attachmentInheritanceMode),
      pdfInheritanceMode: WorkflowTaskEditModel.getInheritanceMode(this.pdfInheritanceMode),
      contractNumberInheritanceMode: WorkflowTaskEditModel.getInheritanceMode(this.contractNumberInheritanceMode),
      formFieldInheritanceMode: WorkflowTaskEditModel.getInheritanceMode(this.formFieldInheritanceMode),
      defaultAssigneeUser: this.defaultAssigneeUser ? {
        id: this.defaultAssigneeUser.id,
        personName: this.defaultAssigneeUser.itemName,
        userName: this.defaultAssigneeUser.itemSubtitle!
      } : undefined,
      defaultAssigneeUserGroup: this.defaultAssigneeUserGroup ? {
        id: this.defaultAssigneeUserGroup.id,
        name: this.defaultAssigneeUserGroup.itemName
      } : undefined,
      inheritFromWorkflowTask: this.inheritFromWorkflowTask ? {
        id: this.inheritFromWorkflowTask.id,
        name: this.inheritFromWorkflowTask.itemName
      } : undefined
    };
  }

  allowWorkflowTaskSelector(): boolean {
    return WorkflowTaskEditModel.getInheritanceMode(this.assigneeInheritanceMode) === Workflow.InheritanceMode.GIVEN_WORKFLOW_TASK ||
      WorkflowTaskEditModel.getInheritanceMode(this.customerInheritanceMode) === Workflow.InheritanceMode.GIVEN_WORKFLOW_TASK ||
      WorkflowTaskEditModel.getInheritanceMode(this.priorityInheritanceMode) === Workflow.InheritanceMode.GIVEN_WORKFLOW_TASK ||
      WorkflowTaskEditModel.getInheritanceMode(this.deadlineInheritanceMode) === Workflow.InheritanceMode.GIVEN_WORKFLOW_TASK ||
      WorkflowTaskEditModel.getInheritanceMode(this.projectInheritanceMode) === Workflow.InheritanceMode.GIVEN_WORKFLOW_TASK ||
      WorkflowTaskEditModel.getInheritanceMode(this.attachmentInheritanceMode) === Workflow.InheritanceMode.GIVEN_WORKFLOW_TASK ||
      WorkflowTaskEditModel.getInheritanceMode(this.pdfInheritanceMode) === Workflow.InheritanceMode.GIVEN_WORKFLOW_TASK ||
      WorkflowTaskEditModel.getInheritanceMode(this.contractNumberInheritanceMode) === Workflow.InheritanceMode.GIVEN_WORKFLOW_TASK ||
      WorkflowTaskEditModel.getInheritanceMode(this.placeOfConsumptionInheritanceMode) === Workflow.InheritanceMode.GIVEN_WORKFLOW_TASK ||
      WorkflowTaskEditModel.getInheritanceMode(this.formFieldInheritanceMode) === Workflow.InheritanceMode.GIVEN_WORKFLOW_TASK;
  }
}

export class WorkflowGroupEditModel {
  objectId: number;
  saveInProgress: boolean = false;
  location: Workflow.Location;
  children: draw2d.Figure[] = [];

  name: string = '';

  tasks: {
    name: string;
    id: number
  }[] = [];

  getGroupData(): Workflow.Group {
    return {
      id: this.objectId,
      name: this.name,
      location: this.location
    };
  }
}

export class WorkflowTransitionEditModel {
  objectId: number;
  saveInProgress: boolean = false;
  inputObjectId: number;
  outputObjectId: number;
  addedByPainter: boolean;
  addRuleModel?: WorkflowTransitionRuleModel;
  taskIds: number[] = [];

  taskRecordStates: MultiselectOptionItem<TaskRecordStateMachine.State>[] = [];
  rules: WorkflowTransitionRuleModel[] = [];

  getTransitionData(): ConnectionUserData {
    return {
      addedByPainter: this.addedByPainter,
      transition: {
        id: this.objectId,
        inputObjectId: this.inputObjectId,
        outputObjectId: this.outputObjectId,
        points: [],
        rule: {
          taskRecordStates: Set.of(...this.taskRecordStates.map(s => s.id)),
          rules: this.rules.map(r => r.toUserData())
        }
      }
    };
  }
}

export abstract class WorkflowTransitionRuleModel {
  editing: boolean = false;
  name: string = '';

  operator?: Workflow.RuleOperator;
  command: MultiselectOptionItem<Workflow.RuleCommand>[] = [];

  constructor(rule?: Workflow.BaseRule) {
    if (rule) {
      this.operator = rule.operator;
      const commandKey = Workflow.ruleCommands.find(c => c.command === rule.command)!.stringKey;
      this.command.push({
        id: rule.command,
        itemName: commandKey
      });
    }
  }

  getCommand(): MultiselectOptionItem<Workflow.RuleCommand> | undefined {
    return this.command.length === 1 ? this.command[0] : undefined;
  }

  abstract toRequest(): Workflow.BaseRule;

  abstract toUserData();

  abstract get disabled(): boolean | undefined;

  abstract refreshName();

  abstract getModelType(): WorkflowTransitionRuleModelType;

  static createModel(rule: Workflow.BaseRule, translateService: TranslateService) {
    if (WorkflowRuleUtils.isPublicFormFieldRule(rule)) {
      return new WorkflowTransitionFormFieldRuleModel(translateService, rule);
    }
    return new WorkflowTransitionTaskRuleModel(translateService, <Workflow.TaskRule>rule);
  }
}

export type WorkflowTransitionRuleModelType = 'FORM_FIELD' | 'TASK';

export class WorkflowTransitionFormFieldRuleModel extends WorkflowTransitionRuleModel {
  getModelType(): WorkflowTransitionRuleModelType {
      return 'FORM_FIELD';
  }
  formField: FormFieldItem[] = [];
  type?: Workflow.FormFieldRuleType;
  stringValue?: string;
  numberValue?: number;
  listItemValue: MultiselectOptionItem<number>[] = [];

  constructor(private translateService: TranslateService, rule?: Workflow.FormFieldRule) {
    super(rule);
    if (rule) {
      this.formField.push({
        id: rule.formField.id,
        itemName: rule.formField.title!,
        disabled: rule.formField.disabled!,
        selector: rule.formField.selector!
      });
      this.type = rule.type;
      this.stringValue = rule.stringValue;
      this.numberValue = rule.numberValue;
      if (rule.listItems) {
        this.listItemValue = rule.listItems.toArray().map(i => ({id: i.id, itemName: i.text!}));
      }
      this.name = this.createRuleName(rule);
    }
  }

  getFormField(): FormFieldItem | undefined {
    return this.formField.length === 1 ? this.formField[0] : undefined;
  }

  getListItemValues(): string | undefined {
    return this.listItemValue.length > 0 ? this.listItemValue.map(i => i.itemName).join(', ') : undefined;
  }

  toRequest(): Workflow.FormFieldRule {
    return {
      operator: this.operator,
      type: this.type!,
      formField: {id: this.getFormField()!.id},
      command: this.getCommand()!.id,
      stringValue: this.stringValue,
      numberValue: this.numberValue,
      listItems: this.type === Workflow.FormFieldRuleType.LIST ? Set.of(...this.listItemValue.map(i => ({id: i.id}))) : undefined
    };
  }

  toUserData() {
    return {
      operator: this.operator,
      type: this.type!,
      formField: {
        id: this.getFormField()!.id,
        title: this.getFormField()!.itemName,
        selector: this.getFormField()!.selector
      },
      command: this.getCommand()!.id,
      stringValue: this.stringValue,
      numberValue: this.numberValue,
      listItems: this.type === Workflow.FormFieldRuleType.LIST ? Set.of(...this.listItemValue.map(i => ({
        id: i.id,
        text: i.itemName
      }))) : undefined
    };
  }

  private createRuleName(rule: Workflow.FormFieldRule): string {
    let result: string = '';
    if (rule.operator) {
      const operator = Workflow.ruleOperators.find(o => o.operator === rule.operator)!;
      result = this.translateService.instant(operator.stringKey) + ' ';
    }
    result += rule.formField.title + ' ';
    const command = Workflow.ruleCommands.find(c => c.command === rule.command)!;
    result += this.translateService.instant(command.stringKey);
    result += rule.type && rule.type !== Workflow.FormFieldRuleType.BOOLEAN ? ': ' : '';
    switch (rule.type) {
      case Workflow.FormFieldRuleType.STRING:
        result += '"' + rule.stringValue + '"';
        break;
      case Workflow.FormFieldRuleType.NUMBER:
        result += rule.numberValue;
        break;
      case Workflow.FormFieldRuleType.LIST:
        result += rule.listItems!.toArray().map(i => i.text).join(', ');
    }
    return result;
  }

  get disabled(): boolean | undefined {
    return this.getFormField() && this.getFormField()!.disabled;
  }

  refreshName() {
    this.name = this.createRuleName(this.toUserData());
  }

}

export class WorkflowTransitionTaskRuleModel extends WorkflowTransitionRuleModel {
  getModelType(): WorkflowTransitionRuleModelType {
    return 'TASK';
  }
  type: MultiselectOptionItem<Workflow.TaskRuleType>[] = [];
  userValue: MultiselectOptionItem<number>[] = [];
  userGroupValue: MultiselectOptionItem<number>[] = [];
  importanceValue: MultiselectOptionItem<TaskRecord.TaskRecordImportance>[] = [];

  constructor(private translateService: TranslateService, rule?: Workflow.TaskRule) {
    super(rule);
    if (rule) {
      this.type = [Workflow.taskRuleTypes.map(t => {
        return {itemName: t.stringKey, id: t.type}
      }).find(t => t.id === rule.type)!];
      if (rule.users) {
        this.userValue = rule.users.toArray().map(i => ({id: i.id, itemName: i.personName!, itemSubtitle: i.userName!}));
      }
      if (rule.userGroups) {
        this.userGroupValue = rule.userGroups.toArray().map(i => ({id: i.id, itemName: i.name!}));
      }
      if (rule.importances) {
        this.importanceValue = rule.importances.toArray().map(i =>
          ({id: i, itemName: TaskRecord.taskRecordImportances.find(x => x.importance === i)!.stringKey}));
      }
      this.name = this.createRuleName(rule);
    }
  }

  getUserValues(): string | undefined {
    return this.userValue.length > 0 ? this.userValue.map(i => i.itemName).join(', ') : undefined;
  }

  getUserGroupValue(): string | undefined {
    return this.userGroupValue.length > 0 ? this.userGroupValue.map(i => i.itemName).join(', ') : undefined;
  }

  getImportanceValue(): string | undefined {
    return this.importanceValue.length > 0 ? this.importanceValue.map(i => this.translateService.instant(i.itemName)).join(', ') : undefined;
  }

  getType(): Workflow.TaskRuleType | undefined {
    return this.type.length === 1 ? this.type[0].id : undefined;
  }
  toRequest(): Workflow.TaskRule {
    return {
      operator: this.operator,
      type: this.getType()!,
      command: this.getCommand()!.id,
      users: this.getType() === Workflow.TaskRuleType.ASSIGNEE_USER ? Set.of(...this.userValue.map(i => ({id: i.id}))) : undefined,
      userGroups: this.getType() === Workflow.TaskRuleType.ASSIGNEE_USER_GROUP
      || this.getType() === Workflow.TaskRuleType.ASSIGNEE_USER_USER_GROUP
        ? Set.of(...this.userGroupValue.map(i => ({id: i.id}))) : undefined,
      importances: this.getType() === Workflow.TaskRuleType.IMPORTANCE ? Set.of(...this.importanceValue.map(i => i.id)) : undefined,
    };
  }

  toUserData() {
    return {
      operator: this.operator,
      type: this.getType()!,
      command: this.getCommand()!.id,
      users: this.getType() === Workflow.TaskRuleType.ASSIGNEE_USER ? Set.of(...this.userValue.map(i => ({id: i.id, personName: i.itemName, userName: i.itemSubtitle}))) : undefined,
      userGroups: this.getType() === Workflow.TaskRuleType.ASSIGNEE_USER_GROUP
      || this.getType() === Workflow.TaskRuleType.ASSIGNEE_USER_USER_GROUP
        ? Set.of(...this.userGroupValue.map(i => ({id: i.id, name: i.itemName}))) : undefined,
      importances: this.getType() === Workflow.TaskRuleType.IMPORTANCE ? Set.of(...this.importanceValue.map(i => i.id)) : undefined,
    };
  }

  get disabled(): boolean {
    return false;
  }

  private createRuleName(rule: Workflow.TaskRule): string {
    let result: string = '';
    if (rule.operator) {
      const operator = Workflow.ruleOperators.find(o => o.operator === rule.operator)!;
      result = this.translateService.instant(operator.stringKey) + ' ';
    }
    result += this.translateService.instant(Workflow.taskRuleTypes.find(t => t.type === rule.type)!.stringKey) + ' ';
    const command = Workflow.ruleCommands.find(c => c.command === rule.command)!;
    result += this.translateService.instant(command.stringKey);
    result += ': ';
    switch (rule.type) {
      case Workflow.TaskRuleType.ASSIGNEE_USER:
        result += '"' + rule.users?.map(u => `${u?.personName} (${u?.userName})`).join(',') + '"';
        break;
      case Workflow.TaskRuleType.ASSIGNEE_USER_GROUP:
      case Workflow.TaskRuleType.ASSIGNEE_USER_USER_GROUP:
        result += '"' + rule.userGroups?.map(u => u?.name).join(',') + '"';
        break;
      case Workflow.TaskRuleType.IMPORTANCE:
        result += '"' + rule.importances!.toArray()
          .map(i => this.translateService.instant(
            TaskRecord.taskRecordImportances.find(ix => ix.importance === i)!.stringKey))
          .join(',') + '"'
        break;
    }
    return result;
  }

  refreshName() {
    this.name = this.createRuleName(this.toUserData());
  }

}

export class WorkflowMultiEditModel {
  figures: (Label | Group | Connection)[] = [];
}
