import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { DisabledEnum, DisabledItem, SearchUtils } from '../../../util/search-utils';
import { QueryFieldModel, SelectUtils, UiConstants } from '../../../util/core-utils';
import { OrderType, QueryResult } from '../../../lib/util/services';
import { RightModel } from '../../../app.rights';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { RightResolver, RightService } from '../../../lib/right.service';
import { StateName } from '../../../app.state-names';
import { EmptyMessage } from '../../../lib/util/messages';
import { ProjectSearch, ProjectSearchService } from '../../../lib/project/project-search.service';
import { Project, ProjectService } from '../../../lib/project/project.service';
import { Set } from 'immutable';
import { Strings } from '../../../lib/util/strings';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss']
})
export class ProjectListComponent extends SearchUtils.SearchableList<ProjectSearch.Model>
  implements OnInit, OnDestroy {

  UiConstants = UiConstants;
  SelectUtils = SelectUtils;
  Project = Project;

  queryModel: QueryFieldModel<Project.OrderField>
    = new QueryFieldModel(Project.OrderField.ID, OrderType.DESC);
  searchModel: ProjectSearch.Model = new ProjectSearch.Model();

  rightModel: RightModel = RightModel.empty();
  disabledItems: DisabledItem[] = [];

  list: Project.Project[] = [];

  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  constructor(
    private projectService: ProjectService,
    private projectSearchService: ProjectSearchService,
    private rightService: RightService,
    injector: Injector) {
    super(ProjectSearch.Model, injector);
  }

  ngOnInit() {
    this.loadRightModels();
    this.initBreadcrumb();
    this.initSearch();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  initBreadcrumb() {
    this.translateService.get('MENU_NAVBAR_PROJECTS').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
  }

  loadSearch(completion: () => void) {
    this.projectSearchService.getSearchData({})
      .subscribe(
        (result: ProjectSearch.SearchDataResult) => {
          this.queryModel.itemsPerPage = result.searchData.itemsPerPage;
          this.queryModel.currentPage = result.searchData.pageNumber;
          this.queryModel.setOrder(result.searchData.order);
          this.searchModel.id = result.searchData.id;
          this.searchModel.name = result.searchData.name;
          this.searchModel.externalId = result.searchData.externalId;
          this.searchModel.disabled = result.searchData.disabled;
          completion();
        }
      );
  }

  onFirstSearchOpen(): void {
    this.disabledItems = this.initDisabledOptions();
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: Project.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const request = this.getQueryRequest(requestedPage);
    this.projectService.query(request).subscribe((result: QueryResult<Project.Project>) => {
      this.list = result.items.toArray();
      this.queryModel.currentPage = requestedPage;
      this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
    });
  }

  private getQueryRequest(requestedPage?: number): Project.QueryRequest {
    const disabled: boolean | undefined
      = !this.searchModel.disabled || this.searchModel.disabled.id === DisabledEnum.NONE
      ? undefined
      : this.searchModel.disabled.id === DisabledEnum.TRUE;
    return {
      id: this.searchModel.id.length > 0 ? Set.of(Number(this.searchModel.id)) : undefined,
      name: Strings.undefinedOrNonEmpty(this.searchModel.name),
      externalId: Strings.undefinedOrNonEmpty(this.searchModel.externalId),
      disabled: disabled,
      orders: Set.of(this.queryModel.getOrder()),
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined
    };
  }

  onSearchClicked() {
    this.loadList(1);
  }

  onSearchReset() {
    this.projectSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.toggleSearch();
          this.loadList(1);
        });
      }
    );
  }

  ngOnDestroy() {
    this.saveSearch();
  }

  private saveSearch() {
    const request = {
      searchData: {
        itemsPerPage: this.queryModel.itemsPerPage,
        pageNumber: this.queryModel.currentPage,
        order: this.queryModel.getOrder(),
        id: this.searchModel.id,
        name: this.searchModel.name,
        externalId: this.searchModel.externalId,
        disabled: this.searchModel.disabled,
      }
    };
    this.projectSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  setDisabled(id: number, disabled: boolean) {
    this.projectService.setDisabled({
      id: id,
      disabled: disabled
    })
      .subscribe(
        (result: EmptyMessage) => {
          this.loadList();
        },
        (error: any) => {
          this.loadList();
        }
      );
  }

}
