/* eslint-disable */
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Shipment, ShipmentGroup, ShipmentGroupService } from '../../../lib/shipment-group/shipment-group.service';
import { MultiselectOptionItem, QueryFieldModel, SelectUtils, UiConstants } from '../../../util/core-utils';
import { OrderType, QueryResult, ResourceQueryResult, Services } from '../../../lib/util/services';
import { Transport, TransportService } from '../../../lib/transport/transport.service';
import { ConfigurationResource, ConfigurationService } from '../../../lib/core-ext/configuration.service';
import { List, Set } from 'immutable';
import { ShippingDemand } from '../../../lib/shipping-demand/shipping-demand.service';
import { Address } from '../../../lib/address';
import { UIRouter } from '@uirouter/angular';
import { TranslateService } from '@ngx-translate/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Company, CompanyService } from '../../../lib/company/company.service';
import { ToasterService } from '../../../fork/angular2-toaster/src/toaster.service';
import { StringKey } from '../../../app.string-keys';
import { RightModel } from '../../../app.rights';
import { RightResolver, RightService } from '../../../lib/right.service';
import { Vehicle, VehicleService } from '../../../lib/vehicles/vehicle.service';
import { User, UserService } from '../../../lib/user.service';
import { StateName } from '../../../app.state-names';
import { Logger, LoggerFactory } from '../../../util/logger-factory';
import { combineLatest, Observable } from 'rxjs';
import { ExteriorTransportService } from '../../../lib/exterior-transport/exterior-transport.service';
import { IdentityMessage } from '../../../lib/util/messages';
import { DqlStoredQueryArgs } from '../../dql-search/dql-search-container/dql-stored-query.args';
import { DqlSearchContainerComponent } from '../../dql-search/dql-search-container/dql-search-container.component';
import { map } from 'rxjs/operators';
import { ShipmentGroupSearch, ShipmentGroupSearchService } from '../../../lib/shipment-group/shipment-group-search.service';
import { ShipmentGroupDqlFieldTextProvider } from '../../../lib/shipment-group/shipment-group.dql.service';
import { ShipmentRightModel } from '../../../app.rights.shpiment';
import ShipmentState = Shipment.ShipmentState;
import ShipmentGroupState = ShipmentGroup.ShipmentGroupState;
import TransportState = Transport.TransportState;
import { Angular2Multiselects } from '../../../util/multiselect';
import { Strings } from '../../../lib/util/strings';
import { Multiselect } from '../../../util/multiselect-helper';

/* eslint-enable */

@Component({
  selector: 'app-shipment-group-list',
  templateUrl: './shipment-group-list.component.html',
  styleUrls: ['./shipment-group-list.component.scss']
})
export class ShipmentGroupListComponent implements OnInit, AfterViewInit, OnDestroy {

  public static readonly LOCAL_STORAGE_RELOAD_KEY = 'shipmentListShowDeleteToastOnReload';

  ShipmentGroup = ShipmentGroup;
  UiConstants = UiConstants;
  SelectUtils = SelectUtils;
  ConfigurationService = ConfigurationService;

  private readonly logger: Logger;

  @ViewChild('changeSubtransporterDialog', { static: true })
  changeSubtransporterDialog: ModalDirective;
  changeSubtransporterDialogVisible: boolean = false;
  changeSubtransporterModel: ChangeSubtransporterModel = new ChangeSubtransporterModel();

  @ViewChild('transportCreateDialog', { static: true })
  transportCreateDialog: ModalDirective;
  transportCreateDialogVisible: boolean = false;

  transportCreateModel: TransportCreateModel = new TransportCreateModel();
  vehicleList: MultiselectOptionItem<number>[] = [];
  driverList: MultiselectOptionItem<number>[] = [];

  singleDropdownSettings: Angular2Multiselects.Settings;

  @ViewChild('addToTransportDialog', { static: true })
  addToTransportDialog: ModalDirective;
  addToTransportDialogVisible: boolean = false;
  addToTransportModel: AddToTransportModel = new AddToTransportModel();
  addToTransportSubmitted: boolean = false;
  transportList: TransportItem[] = [];

  @ViewChild('exteriorTransportCreateDialog', { static: true })
  exteriorTransportCreateDialog: ModalDirective;
  exteriorTransportCreateDialogVisible: boolean = false;
  exteriorTransportCreateModel: ExteriorTransportCreateModel = new ExteriorTransportCreateModel();

  showSearch: boolean = false;
  searchModel: ShipmentGroupSearchModel = new ShipmentGroupSearchModel();
  dqlStoredArgs: DqlStoredQueryArgs;

  private dqlSearchContainer?: DqlSearchContainerComponent;

  @ViewChild('dqlSearchContainer') set dqlContainer(c: DqlSearchContainerComponent) {
    if (c) {
      if (!this.dqlSearchContainer) {
        const qt = this.searchModel.dqlText;
        this.dqlSearchContainer = c;
        this.loadDqlModel(() => {
          this.loadDqlSearch(qt);
          this.dqlSearchContainer!.loadContent();
        });
      }
    }
    else {
      this.dqlSearchContainer = undefined;
    }
  }

  queryModel: QueryFieldModel<ShipmentGroup.OrderField> = new QueryFieldModel(ShipmentGroup.OrderField.ID, OrderType.DESC);

  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  configuration: ConfigurationResource.Configuration;
  shipmentGroupStates: MultiselectOptionItem<ShipmentGroupState>[] = [];
  shipmentStates: MultiselectOptionItem<ShipmentState>[] = [];
  eachShipmentSelected = false;

  shipmentGroupList: TransportShipmentGroupModel[] = [];

  transporterCompanies: MultiselectOptionItem<number | null>[] = [];

  rightModel: RightModel = RightModel.empty();
  shipmentRightModel: ShipmentRightModel = ShipmentRightModel.empty();

  constructor(private uiRouter: UIRouter,
              private transportService: TransportService,
              private shipmentGroupService: ShipmentGroupService,
              private shipmentGroupSearchService: ShipmentGroupSearchService,
              private configurationService: ConfigurationService,
              private companyService: CompanyService,
              private rightService: RightService,
              private vehicleService: VehicleService,
              private userService: UserService,
              private exteriorTransportService: ExteriorTransportService,
              private toasterService: ToasterService,
              private translateService: TranslateService) {
    this.dqlStoredArgs = {service: shipmentGroupService.dqlStoredQueryService, parentId: undefined, documentType: undefined};
    this.logger = LoggerFactory.createLogger('ShipmentListComponent');
  }

  ngOnInit() {
    this.showSuccessToastIfSet();
    this.initBreadcrumb();
    this.initDropdownSettings();
    this.loadRightModels(() => {
      if (this.rightModel.vehicleRead.hasRight()) {
        this.loadVehicles();
      }
      if (this.rightModel.userRead.hasRight()) {
        this.loadDrivers();
      }
      if (this.rightModel.companyRead.hasRight()) {
        this.loadTransporterCompanies();
      }
    });
    this.loadConfig();
    this.loadSearch(() => {
      this.showSearch = !this.searchModel.isEmpty();
      this.loadStates(() => {
        this.loadList();
      });
    });
  }


  ngAfterViewInit() {
  }

  initBreadcrumb() {
    this.translateService.get('SHIPMENTS').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
  }

  initDropdownSettings() {
    this.singleDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
  }

  private loadConfig() {
    this.configuration = this.configurationService.getConfiguration();
  }

  private loadRightModels(completion: () => void) {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.userService.get({
          id: resolver.userProfile!.id
        }).subscribe((user) => {
          this.rightModel = RightModel.of(resolver);
          this.shipmentRightModel = ShipmentRightModel.of({
            rightModel: this.rightModel,
            currentCompanies: user.companies.map(c => c.id)
          });
          completion();
        });
      }
    );
  }

  private loadStates(completion: () => void) {
    this.shipmentGroupStates = [];
    this.shipmentStates = [];
    ShipmentGroup.shipmentGroupStates.forEach(
      (state) => {
        const item = {
          id: state.state,
          itemName: state.stringKey
        };
        this.shipmentGroupStates.push(item);
      });
    this.shipmentGroupStates.forEach(
      (state: MultiselectOptionItem<ShipmentGroupState>) => {
        this.translateService.get(state.itemName).subscribe((stateName: string) => {
            state.itemName = stateName;
          }
        );
      });
    Shipment.shipmentStates.forEach(
      (state) => {
        const item = {
          id: state.state,
          itemName: state.stringKey
        };
        this.shipmentStates.push(item);
      });
    this.shipmentStates.forEach(
      (state: MultiselectOptionItem<ShipmentState>) => {
        this.translateService.get(state.itemName).subscribe((stateName: string) => {
            state.itemName = stateName;
          }
        );
      });
    completion();
  }

  private loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();
    this.shipmentGroupService.globalQuery({
      orders: Set.of(order),
      withDemands: true,
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined,
      dql: this.searchModel.dqlText
    }).subscribe((result: QueryResult<ShipmentGroup.ShipmentGroup>) => {
      this.shipmentGroupList = [];
      result.items.forEach((sg: ShipmentGroup.ShipmentGroup) => {
        const group = new TransportShipmentGroupModel();
        group.id = sg.id;
        group.deliveryNoteNumber = sg.deliveryNote.number;
        group.customer = sg.demander ? sg.demander.company ? sg.demander.company.name : '' : '';
        group.sourceAddress = this.unwrapAddress(sg.source);
        group.transporterCompany = sg.transporter;
        group.destinationAddress = this.unwrapAddress(sg.destination);
        group.state = this.shipmentGroupStates.find(state => state.id === sg.state)!.itemName;
        group.stateObject = ShipmentGroup.shipmentGroupStates.find(state => state.state === sg.state)!
        group.demands = sg.demands ? sg.demands.toArray() : [];
        sg.shipments.forEach((s: Shipment.Shipment) => {
          const shipment = new TransportShipmentModel();
          shipment.id = s.id;
          shipment.deliveryNoteNumber = s.deliveryNote.number;
          shipment.subTransporterName = s.subTransporter ? s.subTransporter.name : undefined;
          shipment.waybillNumber = s.transport ? s.transport.waybillNumber : '';
          shipment.customer = group.customer;
          shipment.sourceAddress = this.unwrapAddress(s.source);
          shipment.destinationAddress = this.unwrapAddress(s.destination);
          shipment.state = this.shipmentStates.find(state => state.id === s.state)!.itemName;
          shipment.stateObject = Shipment.shipmentStates.find(state => state.state === s.state)!;
          shipment.groupIsSafetyShipping = sg.safetyShipping;
          if (s.transport) {
            group.relatedTransports.push(s.transport);
          }
          group.shipments.push(shipment);
        });
        this.shipmentGroupList.push(group);
      });
      this.queryModel.currentPage = requestedPage;
      this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
    });
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: ShipmentGroup.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  unwrapAddress(shippingPlace: ShippingDemand.ShippingPlace): string {
    if (shippingPlace.place && shippingPlace.place.postalAddress) {
      return Address.PostalAddressMapper.toString(shippingPlace.place.postalAddress, this.configuration.postal_address_format);
    }
    else if (shippingPlace.location && shippingPlace.location.place && shippingPlace.location.place.postalAddress) {
      return Address.PostalAddressMapper.toString(shippingPlace.location.place.postalAddress, this.configuration.postal_address_format);
    }
    else {
      return '';
    }
  }

  toggleEachShipment() {
    this.eachShipmentSelected = !this.eachShipmentSelected;
    this.shipmentGroupList.forEach(sg => {
      sg.shipments.forEach(s => {
        s.selected = this.eachShipmentSelected;
      });
    });
  }

  isAnyShipmentSelected(): boolean {
    return this.getSelectedShipments().length > 0;
  }

  getSelectedShipments(): TransportShipmentModel[] {
    const result: TransportShipmentModel[] = [];
    this.shipmentGroupList.forEach(sg => {
      sg.shipments.forEach(s => {
        if (s.selected) {
          result.push(s);
        }
      });
    });
    return result;
  }

  getSelectedShipmentIds(): number[] {
    return this.getSelectedShipments().map(s => s.id);
  }

  canEditGroup(shipmentGroup: TransportShipmentGroupModel) {
    return shipmentGroup.stateObject.state !== 'DELIVERED' && this.shipmentRightModel.shipmentGroupDemandRemove
        .hasRightForShipment({transporterId: shipmentGroup.transporterCompany.id}, undefined)
      || this.shipmentRightModel.shipmentGroupDepotUpdate
        .hasRightForShipment({transporterId: shipmentGroup.transporterCompany.id}, undefined);
  }

  private loadTransporterCompanies(q?: string) {
    this.companyService.query({
      name: q,
      type: Set.of(<Company.CompanyType>'TRANSPORTER'),
      disabled: false,
      orders: Set.of({field: Company.OrderField.NAME, type: OrderType.ASC}),
      paging: {
        numberOfItems: UiConstants.autocompletePageSize,
        pageNumber: 1
      },
      noProgressBar: true
    }).subscribe((result: QueryResult<Company.Company>) => {
      this.transporterCompanies = result.items.toArray().map(c => ({id: c.id, itemName: c.name}));
      this.transporterCompanies.unshift({
        id: null,
        itemName: this.translateService.instant('SHIPMENT_CHANGE_SUBTRANSPORTER_DEFAULT_TRANSPORTER')
      });
    });
  }

  openChangeSubtransporterDialog() {
    this.changeSubtransporterModel.shipmentIds = this.getSelectedShipmentIds();
    if (this.changeSubtransporterModel.shipmentIds.length === 0) {
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutLong,
        type: UiConstants.toastTypeError,
        title: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_TITLE),
        body: this.translateService.instant(StringKey.SHIPMENT_CHANGE_SUBTRANSPORTER_ERROR)
      });
      return;
    }
    this.changeSubtransporterDialogVisible = true;
    this.changeSubtransporterDialog.show();
  }

  closeChangeSubtransporterDialog() {
    this.changeSubtransporterModel.reset();
    this.changeSubtransporterDialogVisible = false;
    this.changeSubtransporterDialog.hide();
  }

  changeSubtransporter() {
    this.shipmentGroupService.changeSubtransporter({
      shipmentIds: Set.of(...this.changeSubtransporterModel.shipmentIds),
      subtransporterCompanyId: this.changeSubtransporterModel.transporterCompanyId
    }).subscribe(() => {
      this.closeChangeSubtransporterDialog();
      this.loadList();
    });
  }

  private loadVehicles(q?: string) {
    this.vehicleService.query({
      licencePlate: q ? Strings.undefinedOrNonEmpty(q) : undefined,
      disabled: false,
      paging: {
        numberOfItems: UiConstants.autocompletePageSize,
        pageNumber: 1
      },
      noProgressBar: true
    }).subscribe((result: QueryResult<Vehicle.Vehicle>) => {
      this.vehicleList = result.items.toArray().map(v => ({id: v.id, itemName: v.licencePlate + '(' + v.company.name + ')'}));
    });
  }

  private loadDrivers(q?: string) {
    this.userService.query({
      person_name: q ? Strings.undefinedOrNonEmpty(q) : undefined,
      disabled: false,
      is_driver: true,
      number_of_items: UiConstants.autocompletePageSize,
      page_number: 1,
      order: Services.createOrderFieldParameter(User.Keys.toOrderFieldKey, Set.of(User.DEFAULT_ORDER)),
      no_progress_bar: true
    }).subscribe((result: ResourceQueryResult<User>) => {
      this.driverList = result.items.map(u => ({id: u.driver!.driver_id!, itemName: u.person_name}));
    });
  }

  openTransportCreateDialog() {
    if (this.vehicleList.length === 0) {
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutLong,
        type: UiConstants.toastTypeError,
        title: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_TITLE),
        body: this.translateService.instant(StringKey.SHIPMENT_ERROR_NO_VEHICLES)
      });
      return;
    }
    if (this.shipmentGroupList.length === 0) {
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutLong,
        type: UiConstants.toastTypeError,
        title: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_TITLE),
        body: this.translateService.instant(StringKey.SHIPMENT_ERROR_NO_SHIPMENT)
      });
      return;
    }
    if (this.isAnyShipmentSelected()) {
      this.transportCreateModel.shipmentIds = this.getSelectedShipmentIds();
      this.transportCreateModel.safetyShippingUserSettable = !this.getSelectedShipments().find(s => s.groupIsSafetyShipping);
      this.transportCreateModel.safetyShipping = !this.transportCreateModel.safetyShippingUserSettable;
      this.transportCreateDialogVisible = true;
      this.transportCreateDialog.show();
      this.logger.debug(this.transportCreateModel);
    }
    else {
      this.shipmentGroupService.globalQuery({}).subscribe((result: QueryResult<ShipmentGroup.ShipmentGroup>) => {
        this.transportCreateModel.shipmentIds = [];
        let safetyShipping = false;
        result.items.forEach((sg) => {
          this.transportCreateModel.shipmentIds.push(...sg!.shipments.toArray().map(s => s.id));
          safetyShipping = safetyShipping || sg!.safetyShipping;
        });
        this.transportCreateModel.safetyShipping = safetyShipping;
        this.transportCreateModel.safetyShippingUserSettable = !safetyShipping;
        this.transportCreateDialogVisible = true;
        this.transportCreateDialog.show();
        this.logger.debug(this.transportCreateModel);
      });
    }
  }

  onDqlSearchClicked() {
    const dqlQueryString = this.dqlSearchContainer!.getQueryString();
    if (dqlQueryString) {
      this.searchModel = new ShipmentGroupSearchModel();
    }
    this.searchModel.dqlText = dqlQueryString;
    this.loadList(1);
  }

  closeTransportCreateDialog() {
    this.transportCreateModel.reset();
    this.transportCreateDialogVisible = false;
    this.transportCreateDialog.hide();
  }

  createTransport() {
    this.logger.debug(this.transportCreateModel);
    this.transportService.createByShipments({
      shipmentIds: Set.of(...this.transportCreateModel.shipmentIds),
      vehicleId: this.transportCreateModel.vehicleId,
      driverId: this.transportCreateModel.driverId,
      safetyShipping: this.transportCreateModel.safetyShipping,
      comment: this.transportCreateModel.comment
    }).subscribe(
      (response: Transport.CreateResponse) => {
        this.uiRouter.stateService.go(StateName.TRANSPORT_DETAIL, {id: response.transportId});
      }
    );
  }

  openExteriorTransportCreateDialog() {
    if (this.isAnyShipmentSelected()) {
      this.exteriorTransportCreateModel.shipmentIds = this.getSelectedShipmentIds();
      this.exteriorTransportCreateDialogVisible = true;
      this.exteriorTransportCreateDialog.show();
    }
    else {
      this.shipmentGroupService.globalQuery({}).subscribe((result: QueryResult<ShipmentGroup.ShipmentGroup>) => {
        this.exteriorTransportCreateModel.shipmentIds = [];
        result.items.forEach((sg) => {
          this.exteriorTransportCreateModel.shipmentIds.push(...sg!.shipments.toArray().map(s => s.id));
        });
        this.exteriorTransportCreateDialogVisible = true;
        this.exteriorTransportCreateDialog.show();
      });
    }
  }

  closeExteriorTransportCreateDialog() {
    this.exteriorTransportCreateModel.reset();
    this.exteriorTransportCreateDialogVisible = false;
    this.exteriorTransportCreateDialog.hide();
  }

  createExteriorTransport() {
    this.exteriorTransportService.createByShipments({
      shipmentIds: Set.of(...this.exteriorTransportCreateModel.shipmentIds)
    }).subscribe(
      (response: IdentityMessage) => {
        this.uiRouter.stateService.go(StateName.EXTERIOR_TRANSPORT_DETAIL, {id: response.id});
      }
    );
  }

  private loadTransports(q?: string, completion?: () => void) {
    const validStatesForAdd: TransportState[] = [
      'UNDER_PLANNING',
      'UNDER_REPLANNING'
    ];
    const transportQueryOrder = new QueryFieldModel(Transport.OrderField.ID, OrderType.DESC).getOrder();
    this.transportService.query({
      waybillNumber: q ? Strings.undefinedOrNonEmpty(q) : undefined,
      orders: Set.of(...[transportQueryOrder]),
      state: Set.of(...validStatesForAdd),
      paging: {
        numberOfItems: UiConstants.autocompletePageSize,
        pageNumber: 1
      },
      noProgressBar: true
    }).subscribe((result: QueryResult<Transport.Transport>) => {
      this.transportList = result.items.toArray().map((transport) => ({
          id: transport.id,
          itemName: transport.waybillNumber
            + ' - ' + (transport.driver ? transport.driver.personName : '')
            + ' - ' + (transport.vehicle ? transport.vehicle.licencePlate : ''),
          securityGuard: transport.securityGuard ? transport.securityGuard.personName : '-',
          safetyShipping: transport.safetyShipping,
          comment: transport.comment ? transport.comment : '-'
        }));
      if (completion) {
        completion();
      }
    });
  }

  addToTransport() {
    this.addToTransportSubmitted = true;
    if (!this.addToTransportModel.transportId) {
      return;
    }
    this.transportService.addShipments({
      id: this.addToTransportModel.transportId!,
      shipmentIds: Set.of(...this.addToTransportModel.shipmentIds)
    }).subscribe(
      () => {
        this.uiRouter.stateService.go(StateName.TRANSPORT_DETAIL, {id: this.addToTransportModel.transportId});
      }
    );
  }

  openAddToTransportDialog() {
    this.loadTransports(undefined, () => {
      if (this.isAnyShipmentSelected()) {
        this.addToTransportModel.shipmentIds = this.getSelectedShipmentIds();
        this.addToTransportDialogVisible = true;
        this.addToTransportDialog.show();
        this.logger.debug(this.addToTransportModel);
      }
      else {
        this.shipmentGroupService.globalQuery({}).subscribe((result: QueryResult<ShipmentGroup.ShipmentGroup>) => {
          this.addToTransportModel.shipmentIds = [];
          result.items.forEach((sg) => {
            this.addToTransportModel.shipmentIds.push(...sg!.shipments.toArray().map(s => s.id));
          });
          this.addToTransportDialogVisible = true;
          this.addToTransportDialog.show();
          this.logger.debug(this.addToTransportModel);
        });
      }
    });
  }

  closeAddToTransportDialog() {
    this.addToTransportSubmitted = false;
    this.addToTransportModel.reset();
    this.addToTransportDialogVisible = false;
    this.addToTransportDialog.hide();
  }

  onTransportChosen() {
    if (this.addToTransportModel.transportId) {
      this.addToTransportModel.safetyShipping = !!this.addToTransportModel.transport[0].safetyShipping;
      this.addToTransportModel.securityGuard = this.addToTransportModel.transport[0].securityGuard
        ? this.addToTransportModel.transport[0].securityGuard! : '-';
      this.addToTransportModel.comment = this.addToTransportModel.transport[0].comment
        ? this.addToTransportModel.transport[0].comment! : '-';
    }
  }

  private loadSearch(completion: () => void) {
    let obs: Observable<SearchLoadResult>;
    obs = this.shipmentGroupSearchService.getSearchData({}).pipe(map((storedSearchData) => {
      const result: SearchLoadResult = {
        storedSearchData: storedSearchData
      };
      return result;
    }));
    obs.subscribe(
      (result: SearchLoadResult) => {
        this.postInitSearch(result.storedSearchData);
        completion();
      }
    );
  }

  loadDqlSearch(queryString?: string) {
    if (queryString) {
      this.dqlSearchContainer!.loadQuery(queryString);
    }
  }

  loadDqlModel(completion?: () => void) {
    this.dqlSearchContainer!.setFields(this.shipmentGroupService.getDqlModel(),
      ShipmentGroupDqlFieldTextProvider.getHelper(),
      completion ? completion : () => {
      });
  }

  private postInitSearch(storedSearchData: ShipmentGroupSearch.SearchDataResult) {
    this.searchModel.dqlText = storedSearchData.searchData.dqlText;
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  isExteriorTransportEnabledByServer(): boolean {
    return this.configuration && ConfigurationService.isEnabledByServer('EXTERIOR_TRANSPORT', this.configuration);
  }

  showSuccessToastIfSet() {
    const showSuccessToastOnReload = localStorage.getItem(ShipmentGroupListComponent.LOCAL_STORAGE_RELOAD_KEY);
    if (showSuccessToastOnReload && showSuccessToastOnReload === 'true') {
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutShort,
        type: UiConstants.toastTypeSuccess,
        title: this.translateService.instant(StringKey.COMMON_SUCCESS),
        body: this.translateService.instant(StringKey.SHIPMENT_LIST_SHIPMENT_DELETED)
      });
    }
    localStorage.setItem(ShipmentGroupListComponent.LOCAL_STORAGE_RELOAD_KEY, 'false');
  }

  private saveSearch() {
    const request = {
      searchData: {
        dqlText: this.searchModel.dqlText
      }
    };
    this.shipmentGroupSearchService.setSearchData(request).subscribe();
  }

  ngOnDestroy() {
    this.saveSearch();
  }

  toggleGroupCollapsed(shipmentGroup: TransportShipmentGroupModel) {
    shipmentGroup.collapsed = !shipmentGroup.collapsed;
  }
}

export class TransportShipmentGroupModel {
  id: number;
  deliveryNoteNumber: string = '';
  customer: string = '';
  sourceAddress: string = '';
  transporterCompany: ShippingDemand.Company;
  destinationAddress: string = '';
  state: string = '';
  stateObject: ShipmentGroup.ShipmentGroupStateObject;
  shipments: TransportShipmentModel[] = [];
  demands?: ShippingDemand.ShippingDemand[] = [];
  delay: number = 0;
  collapsed: boolean = true;
  relatedTransports: Shipment.Transport[] = [];

  get isSingle(): boolean {
    return this.shipments.length === 1;
  }

  get shipment(): TransportShipmentModel {
    return this.shipments[0];
  }
}

export class TransportShipmentModel {
  id: number;
  selected: boolean = false;
  deliveryNoteNumber: string = '';
  trackingNumber: string = '';
  waybillNumber: string = '';
  customer: string = '';
  sourceAddress: string = '';
  destinationAddress: string = '';
  subTransporterName?: string;
  state: string = '';
  stateObject: Shipment.ShipmentStateObject;
  groupIsSafetyShipping: boolean = false;
}

class ChangeSubtransporterModel {
  shipmentIds: number[] = [];
  transporterCompany: MultiselectOptionItem<number | null>[] = [];

  get transporterCompanyId(): number | undefined {
    return this.transporterCompany.length === 1
      ? this.transporterCompany[0].id !== null
        ? this.transporterCompany[0].id!
        : undefined
      : undefined;
  }

  reset() {
    this.shipmentIds = [];
    this.transporterCompany = [];
  }
}

class TransportCreateModel {
  vehicle: MultiselectOptionItem<number>[] = [];
  driver: MultiselectOptionItem<number>[] = [];
  safetyShipping: boolean = false;
  safetyShippingUserSettable: boolean = false;
  comment: string = '';
  shipmentIds: number[] = [];

  get vehicleId(): number | undefined {
    return this.vehicle.length === 1 ? this.vehicle[0].id : undefined;
  }

  get driverId(): number | undefined {
    return this.driver.length === 1 ? this.driver[0].id : undefined;
  }

  reset() {
    this.vehicle = [];
    this.driver = [];
    this.safetyShipping = false;
    this.comment = '';
  }
}

class AddToTransportModel {
  transport: TransportItem[] = [];
  shipmentIds: number[] = [];
  safetyShipping: boolean = false;
  securityGuard: string = '';
  comment: string = '';

  get transportId(): number | undefined {
    return this.transport.length === 1 ? this.transport[0].id : undefined;
  }

  reset() {
    this.transport = [];
    this.shipmentIds = [];
    this.safetyShipping = false;
    this.securityGuard = '';
    this.comment = '';
  }
}

interface TransportItem extends MultiselectOptionItem<number> {
  safetyShipping?: boolean;
  securityGuard?: string;
  comment?: string;
}

class ExteriorTransportCreateModel {
  shipmentIds: number[] = [];

  reset() {
    this.shipmentIds = [];
  }
}

interface SearchLoadResult {
  storedSearchData: ShipmentGroupSearch.SearchDataResult,
}

export class ShipmentGroupSearchModel {
  dqlText?: string = undefined;

  public isEmpty(): boolean {
    return this.dqlText === undefined;
  }

  public clear() {
    this.dqlText = undefined;
  }

}
