/* eslint-disable */
import { AfterViewInit, Component } from '@angular/core';
import { Transition } from '@uirouter/angular';
import { TranslateService } from '@ngx-translate/core';
import { DocumentHTMLReadModel, DocumentLanguageItem } from '../../../../util/document/document-utils';
import { DocumentHTMLService } from '../../../../lib/document/document-html.service';
import { Arrays } from '../../../../lib/util/arrays';
import { DocumentLanguageService } from '../../../../lib/document/document-language.service';
import { DocumentLanguageUtil } from '../../../../util/document/document-language-utils';
import { DocumentGroup } from '../../../../lib/document/document-group.service';
import { DocumentGroupService } from '../../../../lib/document/document-group.service';
import { ConfigurationResource, ConfigurationService } from '../../../../lib/core-ext/configuration.service';
import { ResourceQueryResult } from '../../../../lib/util/services';
import { StringKey } from '../../../../app.string-keys';
/* eslint-enable */

@Component({
  selector: 'app-document-html-read',
  templateUrl: 'document-html-read.component.html',
  styleUrls: ['document-html-read.component.scss']
})
export class DocumentHTMLReadComponent implements AfterViewInit {
  ConfigurationService = ConfigurationService;

  model: DocumentHTMLReadModel;
  documentId: number;
  documentGroupList: DocumentGroup[] = [];
  documentLanguageList: DocumentLanguageItem[] = [];
  editor: any;
  configuration: ConfigurationResource.Configuration;

  constructor(
    private translateService: TranslateService,
    private documentLanguageService: DocumentLanguageService,
    private documentLanguageUtil: DocumentLanguageUtil,
    private documentGroupService: DocumentGroupService,
    private documentHTMLService: DocumentHTMLService,
    private configurationService: ConfigurationService,
    private transition: Transition) {
    this.configuration = this.configurationService.getConfiguration();
    this.documentId = this.transition.params().id;
    this.model = new DocumentHTMLReadModel();
  }

  ngAfterViewInit() {
    this.loadDocumentGroups(() => {
      this.loadModel(() => {
      });
    });
  }

  loadModel(completion: () => void) {
    this.documentHTMLService.get({
      id: this.documentId
    }).subscribe( documentHTML => {
      const readModel = new DocumentHTMLReadModel();
      readModel.id = documentHTML.id;
      readModel.name = documentHTML.name;
      readModel.code = documentHTML.code;
      readModel.value = documentHTML.value;
      readModel.comment = documentHTML.comment;
      readModel.creation_time = documentHTML.creation_time;
      readModel.update_time = documentHTML.update_time;
      readModel.disabled = documentHTML.disabled;
      readModel.document_group = {
        id: documentHTML.document_group_id,
        text: this.getDocumentGroupName(documentHTML.document_group_id)
      };
      this.loadLanguageCodes(documentHTML.language_code);
      this.model = readModel;
      completion();
    });
  }

  private loadLanguageCodes(language_code?: string) {
    this.documentLanguageService.query().subscribe( (result: string[]) => {
      const def = this.createDefaultLanguageCode();
      this.model.language_code = def;
      this.documentLanguageList.push(def);

      Arrays.iterateByIndex( result, language => {
        const languageItem = new DocumentLanguageItem();
        languageItem.id = language;
        languageItem.text = this.documentLanguageUtil.getDocumentLanguageName(language);

        if (languageItem.id === language_code) {
          this.model.language_code = languageItem;
        }
        this.documentLanguageList.push(languageItem);
      });
    });
  }

  private createDefaultLanguageCode(): DocumentLanguageItem {
    const def = new DocumentLanguageItem();
    def.id = null;
    def.text = '';
    this.translateService.get(StringKey.COMMON_VALUE_UNSELECTED).subscribe(
      (text: string) => {
        def.text = text;
      }
    );
    return def;
  }

  loadDocumentGroups(completion: () => void) {
    this.documentGroupService.query({}).subscribe((result: ResourceQueryResult<DocumentGroup>) => {

        result.items.forEach( group => {
          this.documentGroupList.push(group);
        });
        completion();

      }, (error: any) => {
        completion();
      });
  }

  getDocumentGroupName(documentGroupId?: number): string {
    if (!documentGroupId) {
      return '';
    }

    let groupName = '';
    Arrays.iterateByIndex(this.documentGroupList, group => {
        if (group.id === documentGroupId) {
          groupName = group.name;
        }
    });
    return groupName;
  }

  back() {
    window.history.back();
  }

}
