/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResourceQueryResult } from '../../util/services';
import { BaseHttpService, ResourceHelper } from '../../util/http-services';
import { HttpClient } from '@angular/common/http';
/* eslint-enable */

@Injectable()
export class TaskLogResourceService extends BaseHttpService {

  query(request: TaskLogResource.QueryRequest): Observable<ResourceQueryResult<TaskLogResource.TaskLog>> {
    return this.http.get<ResourceQueryResult<TaskLogResource.TaskLog>>(this.url, this.parseParams(request));
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/task-records/log-history/');
  }

}

export namespace TaskLogResource {

  export interface QueryRequest {
    id?: string;
    task_type_name?: string;
    task_name?: string;
    log_type?: string;
    log_time_from?: string;
    log_time_to?: string;
    user_name?: string;
    message?: string;
    application_type_id?: string;
    helpdesk_enabled?: boolean;
    q?: string,
    order?: string;
    page_number?: number;
    number_of_items?: number;
  }

  export interface TaskLog {
    id: number;
    task_id: number;
    taskrecord_id: number;
    log_type?: string;
    log_time: string;
    user_profile_id?: number;
    message?: string;
    application_type_id?: number;
  }
}
