import { AfterViewInit, Component, ContentChildren, Input, OnInit, QueryList } from '@angular/core';
import { MultiselectOptionItem, UiConstants } from '../../../util/core-utils';
import { RightModel } from '../../../app.rights';
import { Angular2Multiselects } from '../../../util/multiselect';
import { FormBuilder, FormControl, FormGroup, NgModel, Validators } from '@angular/forms';
import { LocalFormGroupValidationErrors } from '../../../lib/util/services';
import { UIRouter } from '@uirouter/angular';
import { TranslateService } from '@ngx-translate/core';
import { ConfigurationResource, ConfigurationService } from '../../../lib/core-ext/configuration.service';
import { RightResolver, RightService } from '../../../lib/right.service';
import { RegistrationSettingsService } from '../../../lib/registration/settings/registration-settings.service';
import { AppValidators } from '../../../util/app-validators';
import { EmptyMessage } from '../../../lib/util/messages';
import { EmailTemplateMultiselectProvider } from '../../../lib/email-template/email-template-multiselect.provider';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { EmailAddresses } from '../../../lib/util/email-address';
import { StringKey } from '../../../app.string-keys';
import { MatChipInputEvent } from '@angular/material/chips';
import { ToasterService } from '../../../fork/angular2-toaster/angular2-toaster';
import { RoleMultiselectProvider } from '../../../lib/role/role-multiselect.provider';
import { ApplicationType, RootCoreService } from '../../../lib/root-core.service';
import { Arrays } from '../../../lib/util/arrays';
import Configuration = ConfigurationResource.Configuration;
import RegistrationType = ConfigurationResource.RegistrationType;
import { Map } from 'immutable';
import { UserGroupMultiselectProvider } from '../../../lib/user-group/user-group-multiselect.provider';

@Component({
  selector: 'app-registration-settings',
  templateUrl: './registration-settings.component.html',
  styleUrls: ['./registration-settings.component.scss']
})
export class RegistrationSettingsComponent implements OnInit, AfterViewInit {

  RegistrationType = RegistrationType;
  UserGroupNameSequence = UserGroupNameSequence;

  @Input()
  registrationType: RegistrationType;

  @ContentChildren(NgModel, {descendants: true})
  private controls: QueryList<NgModel>;

  model: RegistrationSettingsModel = new RegistrationSettingsModel();

  readonly: boolean = true;

  notificationTemplates: MultiselectOptionItem<number>[] = [];
  responseTemplates: MultiselectOptionItem<number>[] = [];
  roles: MultiselectOptionItem<number>[] = [];
  userGroups: MultiselectOptionItem<number>[] = [];
  applicationTypes: MultiselectOptionItem<string>[] = [];

  rightModel: RightModel = RightModel.empty();

  singleselectDropdownSettings: Angular2Multiselects.Settings;
  multiselectDropdownSettings: Angular2Multiselects.Settings;

  formGroup: FormGroup;
  submitted: boolean = false;
  private formGroupValidationErrors: LocalFormGroupValidationErrors;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    private uiRouter: UIRouter,
    private translateService: TranslateService,
    private rootCoreService: RootCoreService,
    private toasterService: ToasterService,
    private registrationSettingsService: RegistrationSettingsService,
    private configurationService: ConfigurationService,
    private emailTemplateProvider: EmailTemplateMultiselectProvider,
    private roleProvider: RoleMultiselectProvider,
    private userGroupProvider: UserGroupMultiselectProvider,
    private rightService: RightService,
    private formBuilder: FormBuilder
  ) {
    this.formGroup = this.createFormGroup(formBuilder);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(this, this.formGroup);
  }

  ngOnInit() {
    this.loadRightModels();
  }

  ngAfterViewInit(): void {
    this.loadNotificationTemplates();
    this.loadResponseTemplates();
    this.loadRoles();
    this.loadUserGroups();
    this.loadApplicationTypes(() => {
      this.loadModel();
    });
    this.initDropDown();
  }

  private createFormGroup(fb: FormBuilder): FormGroup {
    return fb.group(
      {
        notificationTemplate: fb.control(
          {value: this.model.notificationTemplate},
          [
            Validators.required,
            AppValidators.validateEnabledItems
          ]
        ),
        responseTemplate: fb.control(
          {value: this.model.responseTemplate},
          [
            Validators.required,
            AppValidators.validateEnabledItems
          ]
        ),
        defaultRole: fb.control({value: this.model.defaultRole},
          [
            AppValidators.tempValidator({
              disabled: () => {
                return this.registrationType === RegistrationType.CREATE_USER_DEMO
                  || this.registrationType === RegistrationType.CREATE_USER_SHOPRENTER;
              },
              validator: AppValidators.validatorChain(Validators.required, AppValidators.validateEnabledItems)
            })
          ]),
        defaultUserGroup: fb.control({value: this.model.defaultUserGroup},
          [
            AppValidators.tempValidator({
              disabled: () => {
                return this.registrationType !== RegistrationType.HELPDESK;
              },
              validator: AppValidators.validatorChain(Validators.required, AppValidators.validateEnabledItems)
            })
          ])
      },
      {
        validator: AppValidators.noOpValidator()
      }
    );
  }

  getForm() {
    return {
      submitted: this.submitted
    };
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        this.readonly = !this.rightModel.registrationSettingsUpdate.hasRight();
      }
    );
  }

  loadNotificationTemplates(query?: string) {
    this.emailTemplateProvider.loadEmailTemplates(false, query)
      .subscribe((result) => {
        this.notificationTemplates = result;
      });
  }

  loadResponseTemplates(query?: string) {
    this.emailTemplateProvider.loadEmailTemplates(false, query)
      .subscribe((result) => {
        this.responseTemplates = result;
      });
  }

  loadRoles(query?: string) {
    this.roleProvider.loadRoles(false, query)
      .subscribe((result) => {
        this.roles = result;
      });
  }

  loadUserGroups(query?: string) {
    this.userGroupProvider.loadActive(query)
      .subscribe((result) => {
        this.userGroups = result;
      });
  }

  private loadApplicationTypes(completion: () => void) {
    this.rootCoreService.getApplicationTypes({})
      .subscribe(
        (applicationTypes: ApplicationType[]) => {
          this.applicationTypes = [];
          Arrays.iterateByIndex(applicationTypes, (t) => {
            this.applicationTypes.push({
              id: t.key,
              itemName: t.name
            });
          });
          completion();
        });
  }

  private loadModel() {
    this.registrationSettingsService.get({
      type: this.registrationType
    }).subscribe((result) => {
      this.emailTemplateProvider.getById(result.notificationEmailTemplate)
        .subscribe((result) => {
          this.model.notificationTemplate.push(result);
        });
      this.emailTemplateProvider.getById(result.responseEmailTemplate)
        .subscribe((result) => {
          this.model.responseTemplate.push(result);
        });
      this.model.notificationAddresses = result.notificationEmailAddresses;
      if (result.defaultRoleId) {
        this.roleProvider.getById(result.defaultRoleId).subscribe((result) => {
          this.model.defaultRole.push(result);
        });
      }
      if (result.defaultUserGroupId) {
        this.userGroupProvider.getById(result.defaultUserGroupId).subscribe((result) => {
          this.model.defaultUserGroup.push(result);
        });
      }
      if (result.userGroupSettings) {
        result.userGroupSettings.forEach(s => {
          const model = new UserGroupSettingsModel();
          this.roleProvider.getByIds(s.roleIds).subscribe(result => {
            model.roles = result;
          });
          model.applicationTypes = this.applicationTypes.filter(t => s.applicationTypes.includes(t.id));
          model.nameTemplate = s.nameTemplate;
          this.addUserGroupSettings(model);
        });
      }
    });
  }

  initDropDown() {
    this.singleselectDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .build();
    this.multiselectDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .remoteSearch(true)
      .enableCheckAll(true)
      .build();
  }

  back() {
    window.history.back();
  }

  update() {
    this.submitted = true;
    this.formGroup.controls['notificationTemplate'].updateValueAndValidity();
    this.formGroup.controls['responseTemplate'].updateValueAndValidity();
    this.formGroup.controls['defaultRole'].updateValueAndValidity();
    this.formGroup.controls['defaultUserGroup'].updateValueAndValidity();
    if (this.formGroup.invalid || this.model.notificationAddresses.length === 0) {
      return;
    }
    this.registrationSettingsService.update({
      type: this.registrationType,
      notificationEmailTemplate: this.model.notificationTemplate[0]!.id,
      responseEmailTemplate: this.model.responseTemplate[0]!.id,
      notificationEmailAddresses: this.model.notificationAddresses,
      defaultRoleId: this.model.defaultRoleId,
      defaultUserGroupId: this.model.defaultUserGroupId,
      userGroupSettings: this.model.userGroupSettings.length > 0
        ? this.model.userGroupSettings.map(s => (
          {
            roleIds: s.roles.map(r => r.id),
            applicationTypes: s.applicationTypes.map(t => t.id),
            nameTemplate: s.nameTemplate
          }))
        : undefined
    }).subscribe(
      (response: EmptyMessage) => {
        this.back();
      });
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  getMultiselectDataReadonlyLabel(data: MultiselectOptionItem<any>[]): string {
    const strings: string[] = [];
    data.forEach((d) => {
      strings.push(d.itemName);
    });
    return strings.join(', ');
  }

  addEmailAddress(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add email address
    if ((value || '').trim()) {
      if (!EmailAddresses.parse(value).isValid()) {
        this.toasterService.pop({
          timeout: UiConstants.ToastTimeoutLong,
          type: UiConstants.toastTypeError,
          title: this.translateService.instant(StringKey.COMMON_VALIDATION_ERROR),
          body: this.translateService.instant(StringKey.COMMON_VALIDATION_MESSAGE_EMAIL)
        });
        return;
      }
      this.model.notificationAddresses.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  removeEmailAddress(emailAddress: string): void {
    const index = this.model.notificationAddresses.indexOf(emailAddress);

    if (index >= 0) {
      this.model.notificationAddresses.splice(index, 1);
    }
  }

  addUserGroupSettings(model?: UserGroupSettingsModel) {
    const index = this.model.userGroupSettings.length;
    this.formGroup.addControl(
      'roles_' + index,
      new FormControl('roles_' + index,
        [
          Validators.required,
          AppValidators.validateEnabledItems
        ]));
    this.formGroup.addControl(
      'applicationTypes_' + index,
      new FormControl(
        'applicationTypes_' + index,
        Validators.required
      ));
    this.formGroup.addControl(
      'nameTemplate_' + index,
      new FormControl(
        'nameTemplate_' + index,
        Validators.required
      ));
    this.model.userGroupSettings.push(model ? model : new UserGroupSettingsModel());
  }

  removeUserGroupSettings(index: number) {
    this.model.userGroupSettings.splice(index, 1);
    this.formGroup.removeControl('roles_' + index);
    this.formGroup.removeControl('applicationTypes_' + index);
    this.formGroup.removeControl('nameTemplate_' + index);
  }

}

class RegistrationSettingsModel {
  notificationTemplate: MultiselectOptionItem<number>[] = [];
  responseTemplate: MultiselectOptionItem<number>[] = [];
  notificationAddresses: string[] = [];
  defaultRole: MultiselectOptionItem<number>[] = [];
  defaultUserGroup: MultiselectOptionItem<number>[] = [];
  userGroupSettings: UserGroupSettingsModel[] = [];

  get defaultRoleId(): number | undefined {
    return this.defaultRole.length > 0 ? this.defaultRole[0].id : undefined;
  }

  get defaultUserGroupId(): number | undefined {
    return this.defaultUserGroup.length > 0 ? this.defaultUserGroup[0].id : undefined;
  }
}

class UserGroupSettingsModel {
  roles: MultiselectOptionItem<number>[] = [];
  applicationTypes: MultiselectOptionItem<string>[] = [];
  nameTemplate: string = '';
}

export namespace UserGroupNameSequence {

  export const ROLE_NAMES: string = '{{RoleNames}}';
  export const COMPANY_NAME: string = '{{CompanyName}}';
  export const COMPANY_ID: string = '{{CompanyId}}';
  export const DEFAULT_USER_NAME: string = '{{DefaultUserName}}';
  export const APPLICATION_TYPES: string = '{{ApplicationTypes}}';

  export const sequenceBlockSeparator = '/';
  export const separatorKeysCodes: number[] = [ENTER, COMMA];

  export const availableSequenceBlocks: string[] = [
    ROLE_NAMES,
    COMPANY_NAME,
    COMPANY_ID,
    DEFAULT_USER_NAME,
    APPLICATION_TYPES,
  ];

  export const sequenceBlockExampleMap: Map<string, string> = Map.of(
    ROLE_NAMES, 'RoleNames',
    COMPANY_NAME, 'CompanyName',
    COMPANY_ID, 'CompanyId',
    DEFAULT_USER_NAME, 'DefaultUserName',
    APPLICATION_TYPES, 'ApplicationTypes',
  );

}
