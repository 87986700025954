import { Injectable } from '@angular/core';
import { MultiselectProvider } from '../multiselect-provider';
import { Observable, Observer } from 'rxjs';
import { MultiselectOptionItem, UiConstants } from '../../util/core-utils';
import { OrderType, ResourceQueryResult, Services } from '../util/services';
import { User, UserQuery, UserService } from '../user.service';
import { Set } from 'immutable';

@Injectable()
export class UserMultiselectProvider implements MultiselectProvider {

  private multiselectQueryRequest: UserQuery = {
    page_number: 1,
    number_of_items: UiConstants.autocompletePageSize,
    no_progress_bar: true,
    order: Services.createOrderFieldParameter(User.Keys.toOrderFieldKey,
      Set.of({field: User.OrderField.USER_NAME, type: OrderType.ASC})),
    disabled: undefined,
    fields: ['id', 'user_name', 'person_name', 'disabled'].join(','),
    q: undefined
  };

  constructor(private userService: UserService) {
  }

  load(queryRequest: UserQuery): Observable<MultiselectOptionItem<number>[]> {
    return Observable.create((observer: Observer<MultiselectOptionItem<number>[]>) => {
      this.userService.query(queryRequest).subscribe((result: ResourceQueryResult<User>) => {
          observer.next(result.items.map(item => this.toMultiselectOptionItem(item!)));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  loadAll(predicate?: string): Observable<MultiselectOptionItem<number>[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.q = predicate;
    return this.load(queryRequest);
  }

  loadActive(predicate?: string): Observable<MultiselectOptionItem<number>[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.q = predicate;
    queryRequest.disabled = false;
    return this.load(queryRequest);
  }

  getById(id: number): Observable<MultiselectOptionItem<number>> {
    return Observable.create((observer: Observer<MultiselectOptionItem<number>>) => {
      this.userService.get({
        id: id
      }).subscribe((result: User) => {
          observer.next(this.toMultiselectOptionItem(result!));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  getByIds(ids: number[]): Observable<MultiselectOptionItem<number>[]> {
    const queryRequest = Object.assign([], this.multiselectQueryRequest);
    queryRequest.id = ids.join(',');
    return this.load(queryRequest);
  }

  toMultiselectOptionItem(item: User): MultiselectOptionItem<number> {
    const ret = new MultiselectOptionItem<number>();
    ret.id = item.id;
    ret.itemName = item.person_name;
    ret.itemSubtitle = item.user_name;
    ret.disabled = item.disabled;
    return ret;
  }

}
