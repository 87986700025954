<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">{{'STOCK_ITEM_CREATE_TITLE' | translate}}</h4>
    <button type="button" class="close" (click)="closeDialog()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" id="modalBody">
    <div class="row m-0">
      <div class="col-6 p-0" *ngFor="let type of types">
        <div class="create-card card card-hover-border cursor-pointer fixed-card-height"
             (click)="closeDialog(type.type)">
          <div class="card-body">
            <div class="d-flex flex-column justify-content-center align-items-center">
              <i class="{{type.iconClass}} task-icon"></i>
              <div class="h4 task-name">{{type.stringKey | translate}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
