<div class="cal-week-view">
  <table class="w-100 mb-1">
    <thead>
      <tr>
        <th class="fit p-0">
          <div class="users-header">
            {{'CALENDAR_TIMELINE_HEADER_USERS' | translate}}
          </div>
        </th>
        <th class="p-0">
          <mwl-calendar-week-view-header
            [days]="days"
            [locale]="locale">
          </mwl-calendar-week-view-header>
        </th>
      </tr>
    </thead>
    <tbody>
    <tr *ngFor="let id of userIds | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }">
      <td class="p-1">
        <div class="d-flex align-items-center text-nowrap">
          <app-user-profile-picture
            class="mr-1"
            [userPersonName]="getUser(id).name"
            [profilePicture]="getProfilePicture(id)">
          </app-user-profile-picture>
          <span class="font-lg">{{getUser(id).name}}</span>
        </div>
      </td>
      <td class="p-0">
        <app-calendar-timeline-row
          [startDate]="viewDate"
          [daysInWeek]="daysInWeek"
          [events]="getEvents(id)"
          (eventClicked)="eventClicked.emit($event)">
        </app-calendar-timeline-row>
      </td>
    </tr>
    <tr *ngIf="userIds.length === 0">
      <td colspan="2">
        <div class="text-center text-muted font-weight-bold p-2">
          <h3>{{'CALENDAR_NO_EVENTS' | translate}}</h3>
        </div>
      </td>
    </tr>
    </tbody>
  </table>

  <app-table-paging [currentNumberOfItems]="queryModel.currentNumberOfItems"
                    [totalNumberOfItems]="queryModel.totalNumberOfItems"
                    [itemsPerPage]="queryModel.itemsPerPage"
                    [hideNumberOfItems]="true"
                    [pageNumbers]="[3, 5, 10]"
                    (pageChange)="pageChange.emit($event)"
                    (itemsPerPageChange)="itemsPerPageChange.emit($event)">
  </app-table-paging>
</div>
