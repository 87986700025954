import { Invoice } from '../../../../lib/invoice/invoice/invoice.service';
import { EuVatNumber, HuTaxNumber } from '../../../../lib/util/tax-numbers';
import { InvoiceSettings } from '../../../../lib/invoice/invoice-settings/invoice-settings.service';
import { InvoicePaymentTypeMultiItem } from '../../invoices/invoice-create/invoice-create-clone.model';
import { Address, AddressModel } from '../../../../lib/address';
import { MultiselectOptionItem } from '../../../../util/core-utils';
import { ConfigurationService } from '../../../../lib/core-ext/configuration.service';
import { Services } from '../../../../lib/util/services';
import { Set } from 'immutable';
import { Strings } from '../../../../lib/util/strings';
import { Models } from '../../../../util/model-utils';
import { EmailAddressTypeService } from '../../../../lib/email-address-type/email-address-type.service';
import InvoiceIntegrationType = InvoiceSettings.InvoiceIntegrationType;

export class InvoiceSettingsModel {

  profileName: string = '';
  companyName: string = '';
  externalId: string = '';
  invoiceIntegrationType: InvoiceSettings.InvoiceIntegrationType;
  invoiceDirectionType: Invoice.InvoiceDirectionType;
  taxNumber: HuTaxNumber = new HuTaxNumber();
  euVatNumber: EuVatNumber = new EuVatNumber();
  emailAddress?: string = '';
  phoneNumber?: string = '';
  bankName: string = '';
  bankAccount: string = '';
  webAddress?: string = '';
  registrationNumber?: string = '';
  commentTemplate?: string = '';
  footerText?: string = '';
  pdfTemplateId?: number;
  defaultInvoiceAppearanceType: InvoiceSettings.InvoiceAppearanceType = InvoiceSettings.InvoiceAppearanceType.PAPER;
  defaultPaymentType: InvoicePaymentTypeMultiItem[] = [];
  languageCode: string = 'hu';
  summaryRoundingEnabled: boolean = false;
  recordRoundingEnabled: boolean = false;
  zeroPricedRecordsAllowed: boolean = true;
  readonly technicalUser: TechnicalUserModel;
  szamlazzHu: SzamlazzHuModel;
  readonly address: AddressModel.PostalAddressModel;
  readonly _prevPaymentTypeExtras: InvoiceSettings.InvoicePaymentTypeExtra[] = [];
  readonly paymentTypes: InvoicePaymentTypeMultiItem[] = [];
  private _defaultPaymentType?: Invoice.InvoicePaymentType = undefined;
  paymentTypeExtraItems: PaymentTypeExtraModel[] = [];
  addressString: string = ''; // Used on detail
  _inboundStock: MultiselectOptionItem<number>[] = [];

  constructor(invoiceIntegrationType: InvoiceSettings.InvoiceIntegrationType | undefined,
              private configService: ConfigurationService,
              private emailAddressTypeService: EmailAddressTypeService) {
    this.address = new AddressModel.PostalAddressModel();
    this.address.type = AddressModel.PostalAddressModelType.COMPLEX;
    this.technicalUser = new TechnicalUserModel();
    if (invoiceIntegrationType) {
      this.setIntegrationType(invoiceIntegrationType);
    } else {
      this.szamlazzHu = new SzamlazzHuModel(this.emailAddressTypeService, InvoiceIntegrationType.NONE); // temp
    }
  }

  setIntegrationType(invoiceIntegrationType: InvoiceSettings.InvoiceIntegrationType) {
    this.szamlazzHu = new SzamlazzHuModel(this.emailAddressTypeService, invoiceIntegrationType);
    this.invoiceIntegrationType = invoiceIntegrationType;
    if (invoiceIntegrationType === InvoiceSettings.InvoiceIntegrationType.NONE) {
      this.invoiceDirectionType = 'INBOUND';
    }
    else {
      this.invoiceDirectionType = 'OUTBOUND';
    }

  }

  load(invoiceSettings: InvoiceSettings.InvoiceSettings) {
    this.setIntegrationType(invoiceSettings.invoiceIntegrationType);
    this.profileName = invoiceSettings.profileName;
    this.companyName = invoiceSettings.companyName;
    this.externalId = invoiceSettings.externalId;
    this.invoiceIntegrationType = invoiceSettings.invoiceIntegrationType;
    this.invoiceDirectionType = invoiceSettings.invoiceDirectionType;
    this.taxNumber.raw = invoiceSettings.taxNumber;
    this.euVatNumber.raw = invoiceSettings.euVatNumber ? invoiceSettings.euVatNumber : '';
    this.emailAddress = Services.emailAddressToString(invoiceSettings.emailAddress);
    this.phoneNumber = Services.phoneNumberToString(invoiceSettings.phoneNumber);
    this.bankName = invoiceSettings.bankName ? invoiceSettings.bankName : '';
    this.bankAccount = invoiceSettings.bankAccount ? invoiceSettings.bankAccount : '';
    this.webAddress = invoiceSettings.webAddress;
    this.registrationNumber = invoiceSettings.registrationNumber;
    this.commentTemplate = invoiceSettings.commentTemplate ? invoiceSettings.commentTemplate : '';
    this.footerText = invoiceSettings.footerText ? invoiceSettings.footerText : '';
    this.pdfTemplateId = invoiceSettings.pdfTemplateId ? invoiceSettings.pdfTemplateId : undefined;
    this.defaultInvoiceAppearanceType = invoiceSettings.defaultInvoiceAppearanceType;
    this._defaultPaymentType = invoiceSettings.defaultPaymentType;
    this.languageCode = invoiceSettings.languageCode;
    this.address.load(invoiceSettings.address);
    this.addressString = Address.PostalAddressMapper.toString(invoiceSettings.address, this.configService.getPostalAddressFormat());
    this._prevPaymentTypeExtras.push(...invoiceSettings.paymentTypeExtras.toArray());
    this.technicalUser.load(invoiceSettings.technicalUser);
    this.szamlazzHu.load(invoiceSettings.szamlazzHu);
    this.summaryRoundingEnabled = invoiceSettings.summaryRoundingEnabled;
    this.recordRoundingEnabled = invoiceSettings.recordRoundingEnabled;
    this.zeroPricedRecordsAllowed = invoiceSettings.zeroPricedRecordsAllowed;
  }

  get paymentTypeExtras(): Set<InvoiceSettings.InvoicePaymentTypeExtra> {
    if (this.invoiceIntegrationType === InvoiceSettings.InvoiceIntegrationType.APPWORKS) {
      return Set.of(...this.paymentTypeExtraItems.map((extra) => {
        return {
          paymentType: extra.paymentType.id,
          issueDeadlinePlusDays: extra.issueDeadlinePlusDays
        };
      }));
    }
    return Set.of();
  }

  public initPaymentTypes(paymentTypeObservable) {
    paymentTypeObservable.subscribe(paymentTypes => {
      this.paymentTypes.splice(0, this.paymentTypes.length);
      this.paymentTypes.push(...paymentTypes);
      // load default payment type
      this.paymentTypes.forEach(paymentType => {
        if (paymentType.id === this._defaultPaymentType) {
          this.defaultPaymentType.push(paymentType);
        }
      });
      this.paymentTypeExtraItems = [];
      const paymentItems = this.paymentTypes.filter(i => i.id === 'TRANSFER' || i.id === 'COD');
      paymentItems.forEach(paymentItem => {
        this.paymentTypeExtraItems.push(
          {
            paymentType: paymentItem,
            issueDeadlinePlusDays: 0
          });
      });
      this._prevPaymentTypeExtras.forEach(extra => {
        const paymentItem = this.paymentTypes.find(i => i.id === extra.paymentType);
        if (paymentItem) {
          const idx = this.paymentTypeExtraItems.findIndex(item => item.paymentType === paymentItem);
          const extraItem = {
            paymentType: paymentItem,
            issueDeadlinePlusDays: extra.issueDeadlinePlusDays
          };
          if (idx >= 0) {
            this.paymentTypeExtraItems.splice(idx, 1, extraItem);
          }
          else {
            this.paymentTypeExtraItems.push(extraItem);
          }
        }
      });
    });
  }

  extraValid(): boolean {
    let valid = true;
    this.paymentTypeExtraItems.forEach(i => {
      if (i.issueDeadlinePlusDays === undefined || i.issueDeadlinePlusDays < 0) {
        valid = false;
      }
    });
    return valid;
  }

  getDefaultPaymentType(): Invoice.InvoicePaymentType | undefined {
    return this.defaultPaymentType.length > 0 ? this.defaultPaymentType[0].id : undefined;
  }

  get inboundStockId(): number | undefined {
    if (this.invoiceDirectionType !== 'INBOUND') {
      return undefined;
    }
    return this._inboundStock.length === 0 ? undefined : this._inboundStock[0].id;
  }
}

export class PaymentTypeExtraModel {
  paymentType: InvoicePaymentTypeMultiItem;
  issueDeadlinePlusDays: number;
}

class TechnicalUserModel {
  userName: string = '';
  password: string = '';
  signatureKey: string = '';
  exchangeKey: string = '';
  enabled: boolean = false;

  load(t: InvoiceSettings.TechnicalUser | undefined) {
    if (t) {
      this.userName = t.userName ? t.userName : '';
      this.signatureKey = t.signatureKey ? t.signatureKey : '';
      this.exchangeKey = t.exchangeKey ? t.exchangeKey : '';
      this.password = '';
      this.enabled = true;
    }
    else {
      this.reset();
      this.enabled = false;
    }
  }

  reset() {
    this.userName = '';
    this.password = '';
    this.signatureKey = '';
    this.exchangeKey = '';
  }

  toRequest(): InvoiceSettings.TechnicalUser | undefined {
    if (this.isEmpty()) {
      return undefined;
    }
    return {
      userName: this.userName,
      password: Strings.undefinedOrNonEmpty(this.password),
      signatureKey: this.signatureKey,
      exchangeKey: this.exchangeKey,
    };
  }

  isEmpty(): boolean {
    return !this.enabled ||
      (this.userName.length === 0 && this.password.length === 0 && this.signatureKey.length === 0 && this.exchangeKey.length === 0);

  }
}

class SzamlazzHuModel {
  agentKey: string = '';
  sendEmail: boolean = false;
  _customerEmailAddressType: MultiselectOptionItem<number>[] = [];
  emailSubject: string = '';
  emailTemplate: string = '';
  notificationEmailAddress: string = '';
  enabled: boolean = false;


  constructor(private emailAddressTypeService: EmailAddressTypeService, invoiceIntegrationType: InvoiceSettings.InvoiceIntegrationType) {
    this.enabled = invoiceIntegrationType === InvoiceIntegrationType.SZAMLAZZ_HU;
  }

  get customerEmailAddressType(): MultiselectOptionItem<number> | undefined {
    return this._customerEmailAddressType.length < 1 ? undefined : this._customerEmailAddressType[0];
  }

  load(t: InvoiceSettings.SzamlazzHu | undefined) {
    if (t) {
      this.agentKey = t.agentKey ? t.agentKey : '';
      this.sendEmail = t.sendEmail;
      this.emailSubject = t.emailSubject ? t.emailSubject : '';
      this.emailTemplate = t.emailTemplate ? t.emailTemplate : '';
      this.notificationEmailAddress = Models.emailAddressToString(t.notificationEmailAddress);
      this.enabled = true;
      if (t.customerEmailAddressTypeId) {
        this.emailAddressTypeService.get({id: t.customerEmailAddressTypeId}).subscribe(e => {
          this._customerEmailAddressType = [{
            id: e.id,
            itemName: e.names.hu
          }];
        });
      }
    }
    else {
      this.reset();
      this.enabled = false;
    }
  }

  reset() {
    this.agentKey = '';
    this.sendEmail = false;
    this.emailSubject = '';
    this.emailTemplate = '';
    this._customerEmailAddressType = [];
    this.notificationEmailAddress = '';
  }

  toRequest(): InvoiceSettings.SzamlazzHu | undefined {
    if (this.isEmpty()) {
      return undefined;
    }
    return {
      agentKey: this.agentKey,
      sendEmail: this.sendEmail,
      emailSubject: Strings.undefinedOrNonEmpty(this.emailSubject),
      emailTemplate: Strings.undefinedOrNonEmpty(this.emailTemplate),
      notificationEmailAddress: Models.parseEmailAddress(this.notificationEmailAddress),
      customerEmailAddressTypeId: this.customerEmailAddressType ? this.customerEmailAddressType.id : undefined
    };
  }

  isEmpty(): boolean {
    return !this.enabled;
  }
}
