/* eslint-disable */
import { Component, OnInit } from '@angular/core';
import { BreadcrumbParent } from '../../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { Transition } from '@uirouter/angular';
import { StateName } from '../../../../app.state-names';
import { TranslateService } from '@ngx-translate/core';
import { Dates, OffsetDateTime } from '../../../../lib/util/dates';
import { StockTakingSheet, StockTakingSheetService } from '../../../../lib/stock-takings/stock-taking-sheet.service';
import { Decimal } from 'decimal.js';
import { combineLatest, Observable } from 'rxjs';
import { Stock, StockService } from '../../../../lib/stock/stock.service';
import { User, UserService } from '../../../../lib/user.service';
import { DownloadedFile } from '../../../../lib/util/downloaded-files';
import { MultiselectOptionItem, UiConstants } from '../../../../util/core-utils';
import { ToasterService } from '../../../../fork/angular2-toaster/angular2-toaster';
import { saveAs } from 'file-saver';
import { Angular2Multiselects } from '../../../../util/multiselect';
import { RightResolver, RightService } from '../../../../lib/right.service';
import { RightModel } from '../../../../app.rights';
import { StockTakings, StockTakingsService } from '../../../../lib/stock-takings/stock-takings.service';
import StockTakingSheetStateObject = StockTakingSheet.StockTakingSheetStateObject;

/* eslint-enable */

@Component({
  selector: 'app-stock-taking-sheet-detail',
  templateUrl: './stock-taking-sheet-detail.component.html',
  styleUrls: ['./stock-taking-sheet-detail.component.scss']
})
export class StockTakingSheetDetailComponent implements OnInit {

  stockTakingId: number;
  sheetId: number;
  model: StockTakingSheetModel = new StockTakingSheetModel();
  stockTakingSheetRecords: StockTakingSheetRecordModel[] = [];
  stockTakingSheetStates: StockTakingSheetStateObject[] = [];

  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  dropdownSettings: Angular2Multiselects.Settings;
  rightModel: RightModel = RightModel.empty();
  hasPdf: boolean = false;


  constructor(private transition: Transition,
              private translateService: TranslateService,
              private stockTakingSheetService: StockTakingSheetService,
              private stockTakingService: StockTakingsService,
              private userService: UserService,
              private stockService: StockService,
              private rightService: RightService,
              private toasterService: ToasterService) {
    this.stockTakingId = this.transition.params().stockTakingId;
    this.sheetId = this.transition.params().id;
  }

  ngOnInit() {
    this.initDropdownSettings();
    this.translateService.get('MENU_NAVBAR_STOCK_TAKINGS').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.STOCK_TAKINGS_LIST});
      }
    );
    this.translateService.get('STOCK_TAKING_SHEETS').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.STOCK_TAKINGS_DETAIL, uiParam: {id: this.stockTakingId}});
      }
    );
    this.loadRightModels(() => {
      this.loadStockTakingSheetStates();
      this.loadModel();
    });
  }

  private loadRightModels(completion: () => void) {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        completion();
      }
    );
  }

  initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
  }

  loadStockTakingSheetStates() {
    this.stockTakingSheetStates = [];
    StockTakingSheet.stockTakingSheetStates.forEach((state) => {
      const item = {
        state: state.state,
        stringKey: state.stringKey,
        text: '...'
      };
      this.stockTakingSheetStates.push(item);
      this.translateService.get(state.stringKey).subscribe((text: string) => {
        item.text = text;
      });
    });
  }

  private loadModel() {
    this.stockTakingSheetService.get({
      sheetId: this.sheetId
    }).subscribe((stockTakingSheet: StockTakingSheet.StockTakingSheet) => {
      this.createCombinedObservable(stockTakingSheet).subscribe(
        (combinedResult: CombinedResult) => {
          const state = this.stockTakingSheetStates.find((s) => s.state === stockTakingSheet.state);
          let amountFound: Decimal = new Decimal('0');
          let stockAmount: Decimal = new Decimal('0');
          stockTakingSheet.items!.forEach((item) => {
            if (item) {
              if (item.amountFound) {
                amountFound = amountFound.add(item.amountFound.toString());
              }
              if (item.stockAmount) {
                stockAmount = stockAmount.add(item.stockAmount.toString());
              }
            }
          });
          this.breadcrumbSelf = stockTakingSheet.trxId;
          this.hasPdf = !!combinedResult.taking.pdfTemplate;

          this.stockTakingSheetRecords = [];
          stockTakingSheet.items!.forEach((stockTakingSheetRecord: StockTakingSheet.StockTakingSheetRecord) => {
            let recordAmountFound: Decimal = new Decimal('0');
            let recordStockAmount: Decimal = new Decimal('0');
            stockTakingSheet.items!.forEach((item) => {
              if (item) {
                if (stockTakingSheetRecord.amountFound) {
                  recordAmountFound = recordAmountFound.add(stockTakingSheetRecord.amountFound.toString());
                }
                if (stockTakingSheetRecord.stockAmount) {
                  recordStockAmount = recordStockAmount.add(stockTakingSheetRecord.stockAmount.toString());
                }
              }
            });
            const stockTakingSheetRecordItem = {
              itemId: stockTakingSheetRecord.itemId + '',
              stockItemName: stockTakingSheetRecord.stockItemName,
              stockItemProductCode: stockTakingSheetRecord.stockItemProductCode ? stockTakingSheetRecord.stockItemProductCode : '',
              stockItemSerialCode: stockTakingSheetRecord.stockItemSerialCode ? stockTakingSheetRecord.stockItemSerialCode : '',
              amountFound: stockTakingSheetRecord.amountFound + '',
              stockAmount: stockTakingSheetRecord.stockAmount + '',
              amountDifference: (stockTakingSheetRecord.amountFound && stockTakingSheetRecord.stockAmount) ?
                stockTakingSheetRecord.amountFound.minus(stockTakingSheetRecord.stockAmount.toString()) + '' : '',
              unit: stockTakingSheetRecord.stockItemUnit ? stockTakingSheetRecord.stockItemUnit : ''
            };
            this.stockTakingSheetRecords.push(stockTakingSheetRecordItem);
          });
          let sameUnits = true;
          let unit = '';
          if (this.stockTakingSheetRecords.length > 0) {
            sameUnits = this.stockTakingSheetRecords.every((record, i, records) => record.unit === records[0].unit);
            unit = this.stockTakingSheetRecords[0].unit;
          }
          this.model.sheetId = stockTakingSheet.sheetId;
          this.model.trxId = stockTakingSheet.trxId;
          this.model.stock.push({
            id: combinedResult.stock.id,
            itemName: combinedResult.stock.name,
            disabled: combinedResult.stock.disabled
          });
          this.model.state = state ? state.text : '';
          this.model.canUpdateStock = stockTakingSheet.state === 'CLOSED';
          this.model.ownerUser.push({
            id: combinedResult.user.id,
            itemName: combinedResult.user.person_name,
            disabled: combinedResult.user.disabled
          });
          this.model.amountFound = amountFound.toString();
          this.model.stockAmount = stockAmount.toString();
          this.model.amountDifference = amountFound.minus(stockAmount.toString()).toString();
          this.model.creationTime = stockTakingSheet.creationTime;
          this.model.unit = sameUnits ? unit : '';
        },
        () => {
        }
      );
    })
  }

  private createCombinedObservable(sheet: StockTakingSheet.StockTakingSheet): Observable<CombinedResult> {
    return combineLatest(
      this.stockService.get({
        id: sheet.stockId
      }),
      this.userService.get({
        id: sheet.assigneeUserId
      }),
      this.stockTakingService.get({
        stockTakingId: this.stockTakingId
      }),
      (stock: Stock,
       user: User,
       taking: StockTakings.StockTaking) => {
        return {
          stock: stock,
          user: user,
          taking: taking
        }
      }
    );
  }

  downloadPdf() {
    this.stockTakingSheetService.downloadPdf(this.sheetId).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName('stock_taking_sheet_' + this.sheetId + '.pdf'));
      },
      (error) => {
        this.toasterService.pop({
          timeout: UiConstants.ToastTimeoutLong,
          type: UiConstants.toastTypeError,
          title: this.translateService.instant('PDF_DOWNLOAD'),
          body: this.translateService.instant('PDF_DOWNLOAD_ERROR_MESSAGE')
        });
      });
  }

  downloadXls() {
    this.stockTakingSheetService.exportXls(this.sheetId).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName('stock_taking_sheet_' + this.sheetId + '.xlsx'));
      },
      (error) => {
        this.toasterService.pop({
          timeout: UiConstants.ToastTimeoutLong,
          type: UiConstants.toastTypeError,
          title: this.translateService.instant('COMMON_BUTTON_EXPORT'),
          body: this.translateService.instant('PDF_DOWNLOAD_ERROR_MESSAGE')
        });
      });
  }

  updateStock() {
    this.stockTakingSheetService.updateStock({sheetId: this.sheetId}).subscribe(
      () => {
        this.loadModel();
      });
  }
}

class StockTakingSheetModel {
  sheetId: number;
  trxId: string = '';
  stock: MultiselectOptionItem<number>[] = [];
  state: string = '';
  canUpdateStock: boolean = false;
  ownerUser: MultiselectOptionItem<number>[] = [];
  stockAmount: string = '';
  amountFound: string = '';
  amountDifference: string = '';
  creationTime: OffsetDateTime = Dates.emptyOffsetDateTime();
  unit: string = '';

  get amountDifferenceNum(): number {
    return +this.amountDifference;
  }
}

class StockTakingSheetRecordModel {
  itemId: string = '';
  stockItemName: string = '';
  stockItemSerialCode: string = '';
  stockItemProductCode: string = '';
  amountFound?: string = '';
  stockAmount?: string = '';
  amountDifference: string = '';
  unit: string = '';
}

interface CombinedResult {
  stock: Stock;
  user: User;
  taking: StockTakings.StockTaking;
}
