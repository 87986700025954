import { DqlQuery, Query } from '../field';

export class LedgerNumber {

  readonly id: Query.OrderField;
  readonly ledgerNumber: Query.OrderField;
  readonly name: Query.OrderField;
  readonly creationTime: Query.OrderField;

  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this.id = new DqlQuery.OrderField(prefix + 'id');
    this.ledgerNumber = new DqlQuery.OrderField(prefix + 'ledger_number');
    this.name = new DqlQuery.OrderField(prefix + 'name');
    this.creationTime = new DqlQuery.OrderField(prefix + 'creation_time');
  }

}
