<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-vertical-padding-half animated fadeIn">
  <div class="container-vertical-padding-half">
    <div class=" col-lg-8 col-md-8 col-xs-12 col-sm-12">
      <div class="card">
        <div class="card-header">
          {{'STOCK_CHANGE_DETAIL_TITLE'|translate}}
        </div>

        <div class="card-body">
          <form class="form-horizontal" #f="ngForm" novalidate>

            <div class="row">
              <div class="form-group col-md-4">
                <label readonly class="detail-title">{{'STOCK_CHANGE_TABLE_HEADER_TRX_ID'|translate}}</label>
              </div>
              <div class="form-group col-md-8">
                <label readonly class="detail-description">{{
                  model.trxId }}</label>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-md-4">
                <label readonly class="detail-title">{{'STOCK_CHANGE_TABLE_HEADER_TIME'|translate}}</label>
              </div>
              <div class="form-group col-md-8">
                <label readonly class="detail-description">{{
                  model.changeTime.toUtcIsoString() | date:'short' }}</label>
              </div>
            </div>

            <div class="row" *ngIf="model.note">
              <div class="form-group col-md-4">
                <label readonly class="detail-title">{{'COMMON_NOTE'|translate}}</label>
              </div>
              <div class="form-group col-md-8">
                <label readonly class="detail-description">{{
                  model.note }}</label>
              </div>
            </div>

            <div class="row" *ngIf="model.stockChangeType">
              <div class="form-group col-md-4">
                <label readonly class="detail-title">{{'STOCK_CHANGE_TABLE_HEADER_CHANGE_TYPE'|translate}}</label>
              </div>
              <div class="form-group col-md-8">
                <label readonly class="detail-description">{{
                  model.stockChangeType.text }}</label>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-md-4">
                <label readonly class="detail-title">{{'STOCK_CHANGE_TABLE_HEADER_ASSIGNEE_NAME'|translate}}</label>
              </div>
              <div class="form-group col-md-8">
                <label readonly class="detail-description">{{
                  model.assigneeName }}</label>
              </div>
            </div>

            <div class="row" *ngIf="model.sumPrice > 0 && !mixedCurrency">
              <div class="form-group col-md-4">
                <label readonly class="detail-title">{{'STOCK_CHANGE_TABLE_HEADER_SUM_PRICE'|translate}}</label>
              </div>
              <div class="form-group col-md-8">
                <label readonly class="detail-description">{{
                  model.sumPrice | formattedNumber }} {{model.sumPriceCurrencyCode}}</label>
              </div>
            </div>

            <div class="row" *ngIf="model.sumWeight > 0">
              <div class="form-group col-md-4">
                <label readonly class="detail-title">{{'STOCK_CHANGE_TABLE_HEADER_SUM_WEIGHT'|translate}}</label>
              </div>
              <div class="form-group col-md-8">
                <label readonly class="col-form-label form-control-label detail-description">{{
                  WeightFactory.createWeightFromGram(model.sumWeight).toString() | formattedNumber}}
                </label>
              </div>
            </div>

            <!--EXTRA FIELDS-->
            <ng-container *ngIf="model.stockChangeType && model.stockChangeType.id === 'INTAKE'">

              <div class="row" *ngIf="model.acquisitionId">
                <div class="form-group col-md-4">
                  <label readonly class="detail-title">{{'STOCK_CHANGE_TABLE_HEADER_ACQUISITION_ID'|translate}}</label>
                </div>
                <div class="form-group col-md-8">
                  <label readonly class="detail-description">{{
                    model.acquisitionId }}</label>
                </div>
              </div>

              <div class="row" *ngIf="model.supplierName">
                <div class="form-group col-md-4">
                  <label readonly class="detail-title">{{'STOCK_CHANGE_TABLE_HEADER_SUPPLIER_NAME'|translate}}</label>
                </div>
                <div class="form-group col-md-8">
                  <label readonly class="detail-description">{{
                    model.supplierName }}</label>
                </div>
              </div>

            </ng-container>

          </form>
        </div>
      </div>
    </div>

    <div class="container-vertical-padding-half"></div>
    <!-- /.container-vertical-padding-half -->

    <div *ngIf="tableDisplayOptions" class="col-lg-12 col-md-12 col-xs-12 col-sm-12">

      <!--TABLE OF PRODUCTS-->
      <div class="card">

        <div class="card-header">
          {{'STOCK_ITEM_PANEL_HEADING_LIST' | translate}}
        </div>
        <div class="card-body">
          <table class="table table-striped table-bordered">
            <thead>
            <tr>
              <th class="table-sorter-header w-5"><app-table-sorter-no-op [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-sorter-no-op></th>
              <th class="table-sorter-header"><app-table-sorter-no-op [text]="'COMMON_NAME' | translate"></app-table-sorter-no-op></th>
              <th class="table-sorter-header">
                <app-table-sorter-no-op [text]="'STOCK_ITEM_TABLE_HEADER_TYPE' | translate"></app-table-sorter-no-op>
              </th>
              <th class="table-sorter-header hidden-xs-down w-20"><app-table-sorter-no-op [text]="'STOCK_ITEM_TABLE_HEADER_EXTERNAL_ID' | translate"></app-table-sorter-no-op></th>
              <th class="table-sorter-header hidden-sm-down w-15" *ngIf="tableDisplayOptions.showExpiryDate"><app-table-sorter-no-op [text]="'STOCK_CHANGE_TABLE_HEADER_BEST_BEFORE' | translate"></app-table-sorter-no-op></th>
              <th class="table-sorter-header hidden-sm-down w-15" *ngIf="tableDisplayOptions.showSourceStock"><app-table-sorter-no-op [text]="'STOCK_ITEM_MOVE_SOURCE' | translate"></app-table-sorter-no-op></th>
              <th class="table-sorter-header hidden-sm-down w-15" *ngIf="tableDisplayOptions.showDestinationStock"><app-table-sorter-no-op [text]="'STOCK_ITEM_MOVE_DESTINATION' | translate"></app-table-sorter-no-op></th>
              <th class="table-sorter-header hidden-xs-down w-10"><app-table-sorter-no-op [text]="'STOCK_RECORD_AMOUNT' | translate"></app-table-sorter-no-op></th>
              <th class="table-sorter-header w-10"><app-table-sorter-no-op [text]="'PROCESS_EDIT_ORDER_CASH_ON_DELIVERY_PRICE' | translate"></app-table-sorter-no-op></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let product of productList">
              <td class="responsive-table-column">{{product.stockItemId}}</td>
              <td class="responsive-table-column long">{{product.stockItemName}}</td>
              <td class="responsive-table-column">{{product.stockItemType}}</td>
              <td class="responsive-table-column hidden-xs-down">{{product.externalId}}</td>
              <td class="responsive-table-column hidden-sm-down" *ngIf="tableDisplayOptions.showExpiryDate">
                <ng-container *ngIf="product.expiryDate">
                  {{product.expiryDate.toUtcIsoString() | date:'short'}}
                </ng-container>
              </td>
              <td class="responsive-table-column hidden-sm-down" *ngIf="tableDisplayOptions.showSourceStock">{{product.sourceStockName}}</td>
              <td class="responsive-table-column hidden-sm-down" *ngIf="tableDisplayOptions.showDestinationStock">{{product.destinationStockName}}</td>
              <td class="responsive-table-column hidden-xs-down">{{product.amount | formattedNumber}} {{product.unit}}</td>
              <td class="responsive-table-column">
                <ng-container *ngIf="product.sumPrice > 0">
                {{product.sumPrice | formattedNumber}} {{product.currencyCode}}
                </ng-container>
              </td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
              <th class="table-sorter-header w-5"><app-table-sorter-no-op [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-sorter-no-op></th>
              <th class="table-sorter-header"><app-table-sorter-no-op [text]="'COMMON_NAME' | translate"></app-table-sorter-no-op></th>
              <th class="table-sorter-header">
                <app-table-sorter-no-op [text]="'STOCK_ITEM_TABLE_HEADER_TYPE' | translate"></app-table-sorter-no-op>
              </th>
              <th class="table-sorter-header hidden-xs-down w-20"><app-table-sorter-no-op [text]="'STOCK_ITEM_TABLE_HEADER_EXTERNAL_ID' | translate"></app-table-sorter-no-op></th>
              <th class="table-sorter-header hidden-sm-down w-15" *ngIf="tableDisplayOptions.showExpiryDate"><app-table-sorter-no-op [text]="'STOCK_CHANGE_TABLE_HEADER_BEST_BEFORE' | translate"></app-table-sorter-no-op></th>
              <th class="table-sorter-header hidden-sm-down w-15" *ngIf="tableDisplayOptions.showSourceStock"><app-table-sorter-no-op [text]="'STOCK_ITEM_MOVE_SOURCE' | translate"></app-table-sorter-no-op></th>
              <th class="table-sorter-header hidden-sm-down w-15" *ngIf="tableDisplayOptions.showDestinationStock"><app-table-sorter-no-op [text]="'STOCK_ITEM_MOVE_DESTINATION' | translate"></app-table-sorter-no-op></th>
              <th class="table-sorter-header hidden-xs-down w-10"><app-table-sorter-no-op [text]="'STOCK_RECORD_AMOUNT' | translate"></app-table-sorter-no-op></th>
              <th class="table-sorter-header w-10"><app-table-sorter-no-op [text]="'PROCESS_EDIT_ORDER_CASH_ON_DELIVERY_PRICE' | translate"></app-table-sorter-no-op></th>
            </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
