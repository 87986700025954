// Generated from src/app/util/generated/dql/normal/Dql.g4 by ANTLR 4.7.3-SNAPSHOT
/* eslint-disable */


import { ATN } from "antlr4ts/atn/ATN";
import { ATNDeserializer } from "antlr4ts/atn/ATNDeserializer";
import { FailedPredicateException } from "antlr4ts/FailedPredicateException";
import { NotNull } from "antlr4ts/Decorators";
import { NoViableAltException } from "antlr4ts/NoViableAltException";
import { Override } from "antlr4ts/Decorators";
import { Parser } from "antlr4ts/Parser";
import { ParserRuleContext } from "antlr4ts/ParserRuleContext";
import { ParserATNSimulator } from "antlr4ts/atn/ParserATNSimulator";
import { ParseTreeListener } from "antlr4ts/tree/ParseTreeListener";
import { ParseTreeVisitor } from "antlr4ts/tree/ParseTreeVisitor";
import { RecognitionException } from "antlr4ts/RecognitionException";
import { RuleContext } from "antlr4ts/RuleContext";
//import { RuleVersion } from "antlr4ts/RuleVersion";
import { TerminalNode } from "antlr4ts/tree/TerminalNode";
import { Token } from "antlr4ts/Token";
import { TokenStream } from "antlr4ts/TokenStream";
import { Vocabulary } from "antlr4ts/Vocabulary";
import { VocabularyImpl } from "antlr4ts/VocabularyImpl";

import * as Utils from "antlr4ts/misc/Utils";

import { DqlListener } from "./DqlListener";
import { DqlVisitor } from "./DqlVisitor";


export class DqlParser extends Parser {
	public static readonly T__0 = 1;
	public static readonly NUMBER = 2;
	public static readonly WORD = 3;
	public static readonly OPERATION = 4;
	public static readonly UNARY_OPERATION = 5;
	public static readonly LOGICAL = 6;
	public static readonly WS = 7;
	public static readonly VALUE = 8;
	public static readonly SEMICOLON = 9;
	public static readonly LPAR = 10;
	public static readonly RPAR = 11;
	public static readonly RULE_sentence = 0;
	public static readonly RULE_expression = 1;
	public static readonly RULE_criteria = 2;
	public static readonly RULE_field = 3;
	public static readonly RULE_relationalOperator = 4;
	public static readonly RULE_unaryRelationalOperator = 5;
	public static readonly RULE_value = 6;
	public static readonly RULE_logicalOperator = 7;
	/* eslint-disable no-trailing-spaces */
	public static readonly ruleNames: string[] = [
		"sentence", "expression", "criteria", "field", "relationalOperator", "unaryRelationalOperator",
		"value", "logicalOperator",
	];

	private static readonly _LITERAL_NAMES: Array<string | undefined> = [
		undefined, "'.'", undefined, undefined, undefined, undefined, undefined,
		undefined, undefined, "';'", "'('", "')'",
	];
	private static readonly _SYMBOLIC_NAMES: Array<string | undefined> = [
		undefined, undefined, "NUMBER", "WORD", "OPERATION", "UNARY_OPERATION",
		"LOGICAL", "WS", "VALUE", "SEMICOLON", "LPAR", "RPAR",
	];
	public static readonly VOCABULARY: Vocabulary = new VocabularyImpl(DqlParser._LITERAL_NAMES, DqlParser._SYMBOLIC_NAMES, []);

	// @Override
	// @NotNull
	public get vocabulary(): Vocabulary {
		return DqlParser.VOCABULARY;
	}
	/* eslint-enable no-trailing-spaces */

	// @Override
	public get grammarFileName(): string { return "Dql.g4"; }

	// @Override
	public get ruleNames(): string[] { return DqlParser.ruleNames; }

	// @Override
	public get serializedATN(): string { return DqlParser._serializedATN; }

	constructor(input: TokenStream) {
		super(input);
		this._interp = new ParserATNSimulator(DqlParser._ATN, this);
	}
	// @RuleVersion(0)
	public sentence(): SentenceContext {
		let _localctx: SentenceContext = new SentenceContext(this._ctx, this.state);
		this.enterRule(_localctx, 0, DqlParser.RULE_sentence);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 16;
			this.expression();
			this.state = 22;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			while (_la === DqlParser.LOGICAL || _la === DqlParser.WS) {
				{
				{
				this.state = 17;
				this.logicalOperator();
				this.state = 18;
				this.expression();
				}
				}
				this.state = 24;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			}
			this.state = 25;
			this.match(DqlParser.SEMICOLON);
			this.state = 26;
			this.match(DqlParser.EOF);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public expression(): ExpressionContext {
		let _localctx: ExpressionContext = new ExpressionContext(this._ctx, this.state);
		this.enterRule(_localctx, 2, DqlParser.RULE_expression);
		let _la: number;
		try {
			this.state = 41;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case DqlParser.LPAR:
				_localctx = new ComplexExpressionContext(_localctx);
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 28;
				this.match(DqlParser.LPAR);
				this.state = 29;
				(_localctx as ComplexExpressionContext)._left = this.expression();
				this.state = 35;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				while (_la === DqlParser.LOGICAL || _la === DqlParser.WS) {
					{
					{
					this.state = 30;
					this.logicalOperator();
					this.state = 31;
					(_localctx as ComplexExpressionContext)._expression = this.expression();
					(_localctx as ComplexExpressionContext)._rest.push((_localctx as ComplexExpressionContext)._expression);
					}
					}
					this.state = 37;
					this._errHandler.sync(this);
					_la = this._input.LA(1);
				}
				this.state = 38;
				this.match(DqlParser.RPAR);
				}
				break;
			case DqlParser.NUMBER:
			case DqlParser.WORD:
			case DqlParser.WS:
				_localctx = new SimpleExpressionContext(_localctx);
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 40;
				this.criteria();
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public criteria(): CriteriaContext {
		let _localctx: CriteriaContext = new CriteriaContext(this._ctx, this.state);
		this.enterRule(_localctx, 4, DqlParser.RULE_criteria);
		try {
			this.state = 50;
			this._errHandler.sync(this);
			switch ( this.interpreter.adaptivePredict(this._input, 3, this._ctx) ) {
			case 1:
				_localctx = new BinaryCriteriaContext(_localctx);
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 43;
				this.field();
				this.state = 44;
				this.relationalOperator();
				this.state = 45;
				this.value();
				}
				break;

			case 2:
				_localctx = new UnaryCriteriaContext(_localctx);
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 47;
				this.field();
				this.state = 48;
				this.unaryRelationalOperator();
				}
				break;
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public field(): FieldContext {
		let _localctx: FieldContext = new FieldContext(this._ctx, this.state);
		this.enterRule(_localctx, 6, DqlParser.RULE_field);
		let _la: number;
		try {
			this.state = 84;
			this._errHandler.sync(this);
			switch ( this.interpreter.adaptivePredict(this._input, 8, this._ctx) ) {
			case 1:
				_localctx = new StaticFieldContext(_localctx);
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 55;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				while (_la === DqlParser.WS) {
					{
					{
					this.state = 52;
					this.match(DqlParser.WS);
					}
					}
					this.state = 57;
					this._errHandler.sync(this);
					_la = this._input.LA(1);
				}
				this.state = 58;
				this.match(DqlParser.WORD);
				}
				break;

			case 2:
				_localctx = new DynamicFieldContext(_localctx);
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 62;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				while (_la === DqlParser.WS) {
					{
					{
					this.state = 59;
					this.match(DqlParser.WS);
					}
					}
					this.state = 64;
					this._errHandler.sync(this);
					_la = this._input.LA(1);
				}
				this.state = 65;
				this.match(DqlParser.NUMBER);
				}
				break;

			case 3:
				_localctx = new DynamicStaticFieldContext(_localctx);
				this.enterOuterAlt(_localctx, 3);
				{
				this.state = 69;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				while (_la === DqlParser.WS) {
					{
					{
					this.state = 66;
					this.match(DqlParser.WS);
					}
					}
					this.state = 71;
					this._errHandler.sync(this);
					_la = this._input.LA(1);
				}
				this.state = 72;
				this.match(DqlParser.NUMBER);
				this.state = 73;
				this.match(DqlParser.T__0);
				this.state = 74;
				this.match(DqlParser.WORD);
				}
				break;

			case 4:
				_localctx = new DynamicDynamicFieldContext(_localctx);
				this.enterOuterAlt(_localctx, 4);
				{
				this.state = 78;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				while (_la === DqlParser.WS) {
					{
					{
					this.state = 75;
					this.match(DqlParser.WS);
					}
					}
					this.state = 80;
					this._errHandler.sync(this);
					_la = this._input.LA(1);
				}
				this.state = 81;
				this.match(DqlParser.NUMBER);
				this.state = 82;
				this.match(DqlParser.T__0);
				this.state = 83;
				this.match(DqlParser.NUMBER);
				}
				break;
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public relationalOperator(): RelationalOperatorContext {
		let _localctx: RelationalOperatorContext = new RelationalOperatorContext(this._ctx, this.state);
		this.enterRule(_localctx, 8, DqlParser.RULE_relationalOperator);
		let _la: number;
		try {
			let _alt: number;
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 89;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			while (_la === DqlParser.WS) {
				{
				{
				this.state = 86;
				this.match(DqlParser.WS);
				}
				}
				this.state = 91;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			}
			this.state = 92;
			this.match(DqlParser.OPERATION);
			this.state = 96;
			this._errHandler.sync(this);
			_alt = this.interpreter.adaptivePredict(this._input, 10, this._ctx);
			while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
				if (_alt === 1) {
					{
					{
					this.state = 93;
					this.match(DqlParser.WS);
					}
					}
				}
				this.state = 98;
				this._errHandler.sync(this);
				_alt = this.interpreter.adaptivePredict(this._input, 10, this._ctx);
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public unaryRelationalOperator(): UnaryRelationalOperatorContext {
		let _localctx: UnaryRelationalOperatorContext = new UnaryRelationalOperatorContext(this._ctx, this.state);
		this.enterRule(_localctx, 10, DqlParser.RULE_unaryRelationalOperator);
		try {
			let _alt: number;
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 99;
			this.match(DqlParser.UNARY_OPERATION);
			this.state = 103;
			this._errHandler.sync(this);
			_alt = this.interpreter.adaptivePredict(this._input, 11, this._ctx);
			while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
				if (_alt === 1) {
					{
					{
					this.state = 100;
					this.match(DqlParser.WS);
					}
					}
				}
				this.state = 105;
				this._errHandler.sync(this);
				_alt = this.interpreter.adaptivePredict(this._input, 11, this._ctx);
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public value(): ValueContext {
		let _localctx: ValueContext = new ValueContext(this._ctx, this.state);
		this.enterRule(_localctx, 12, DqlParser.RULE_value);
		let _la: number;
		try {
			let _alt: number;
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 109;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			while (_la === DqlParser.WS) {
				{
				{
				this.state = 106;
				this.match(DqlParser.WS);
				}
				}
				this.state = 111;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			}
			this.state = 112;
			this.match(DqlParser.VALUE);
			this.state = 116;
			this._errHandler.sync(this);
			_alt = this.interpreter.adaptivePredict(this._input, 13, this._ctx);
			while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
				if (_alt === 1) {
					{
					{
					this.state = 113;
					this.match(DqlParser.WS);
					}
					}
				}
				this.state = 118;
				this._errHandler.sync(this);
				_alt = this.interpreter.adaptivePredict(this._input, 13, this._ctx);
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public logicalOperator(): LogicalOperatorContext {
		let _localctx: LogicalOperatorContext = new LogicalOperatorContext(this._ctx, this.state);
		this.enterRule(_localctx, 14, DqlParser.RULE_logicalOperator);
		let _la: number;
		try {
			let _alt: number;
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 122;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			while (_la === DqlParser.WS) {
				{
				{
				this.state = 119;
				this.match(DqlParser.WS);
				}
				}
				this.state = 124;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			}
			this.state = 125;
			this.match(DqlParser.LOGICAL);
			this.state = 129;
			this._errHandler.sync(this);
			_alt = this.interpreter.adaptivePredict(this._input, 15, this._ctx);
			while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
				if (_alt === 1) {
					{
					{
					this.state = 126;
					this.match(DqlParser.WS);
					}
					}
				}
				this.state = 131;
				this._errHandler.sync(this);
				_alt = this.interpreter.adaptivePredict(this._input, 15, this._ctx);
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}

	public static readonly _serializedATN: string =
		"\x03\uC91D\uCABA\u058D\uAFBA\u4F53\u0607\uEA8B\uC241\x03\r\x87\x04\x02" +
		"\t\x02\x04\x03\t\x03\x04\x04\t\x04\x04\x05\t\x05\x04\x06\t\x06\x04\x07" +
		"\t\x07\x04\b\t\b\x04\t\t\t\x03\x02\x03\x02\x03\x02\x03\x02\x07\x02\x17" +
		"\n\x02\f\x02\x0E\x02\x1A\v\x02\x03\x02\x03\x02\x03\x02\x03\x03\x03\x03" +
		"\x03\x03\x03\x03\x03\x03\x07\x03$\n\x03\f\x03\x0E\x03\'\v\x03\x03\x03" +
		"\x03\x03\x03\x03\x05\x03,\n\x03\x03\x04\x03\x04\x03\x04\x03\x04\x03\x04" +
		"\x03\x04\x03\x04\x05\x045\n\x04\x03\x05\x07\x058\n\x05\f\x05\x0E\x05;" +
		"\v\x05\x03\x05\x03\x05\x07\x05?\n\x05\f\x05\x0E\x05B\v\x05\x03\x05\x03" +
		"\x05\x07\x05F\n\x05\f\x05\x0E\x05I\v\x05\x03\x05\x03\x05\x03\x05\x03\x05" +
		"\x07\x05O\n\x05\f\x05\x0E\x05R\v\x05\x03\x05\x03\x05\x03\x05\x05\x05W" +
		"\n\x05\x03\x06\x07\x06Z\n\x06\f\x06\x0E\x06]\v\x06\x03\x06\x03\x06\x07" +
		"\x06a\n\x06\f\x06\x0E\x06d\v\x06\x03\x07\x03\x07\x07\x07h\n\x07\f\x07" +
		"\x0E\x07k\v\x07\x03\b\x07\bn\n\b\f\b\x0E\bq\v\b\x03\b\x03\b\x07\bu\n\b" +
		"\f\b\x0E\bx\v\b\x03\t\x07\t{\n\t\f\t\x0E\t~\v\t\x03\t\x03\t\x07\t\x82" +
		"\n\t\f\t\x0E\t\x85\v\t\x03\t\x02\x02\x02\n\x02\x02\x04\x02\x06\x02\b\x02" +
		"\n\x02\f\x02\x0E\x02\x10\x02\x02\x02\x02\x90\x02\x12\x03\x02\x02\x02\x04" +
		"+\x03\x02\x02\x02\x064\x03\x02\x02\x02\bV\x03\x02\x02\x02\n[\x03\x02\x02" +
		"\x02\fe\x03\x02\x02\x02\x0Eo\x03\x02\x02\x02\x10|\x03\x02\x02\x02\x12" +
		"\x18\x05\x04\x03\x02\x13\x14\x05\x10\t\x02\x14\x15\x05\x04\x03\x02\x15" +
		"\x17\x03\x02\x02\x02\x16\x13\x03\x02\x02\x02\x17\x1A\x03\x02\x02\x02\x18" +
		"\x16\x03\x02\x02\x02\x18\x19\x03\x02\x02\x02\x19\x1B\x03\x02\x02\x02\x1A" +
		"\x18\x03\x02\x02\x02\x1B\x1C\x07\v\x02\x02\x1C\x1D\x07\x02\x02\x03\x1D" +
		"\x03\x03\x02\x02\x02\x1E\x1F\x07\f\x02\x02\x1F%\x05\x04\x03\x02 !\x05" +
		"\x10\t\x02!\"\x05\x04\x03\x02\"$\x03\x02\x02\x02# \x03\x02\x02\x02$\'" +
		"\x03\x02\x02\x02%#\x03\x02\x02\x02%&\x03\x02\x02\x02&(\x03\x02\x02\x02" +
		"\'%\x03\x02\x02\x02()\x07\r\x02\x02),\x03\x02\x02\x02*,\x05\x06\x04\x02" +
		"+\x1E\x03\x02\x02\x02+*\x03\x02\x02\x02,\x05\x03\x02\x02\x02-.\x05\b\x05" +
		"\x02./\x05\n\x06\x02/0\x05\x0E\b\x0205\x03\x02\x02\x0212\x05\b\x05\x02" +
		"23\x05\f\x07\x0235\x03\x02\x02\x024-\x03\x02\x02\x0241\x03\x02\x02\x02" +
		"5\x07\x03\x02\x02\x0268\x07\t\x02\x0276\x03\x02\x02\x028;\x03\x02\x02" +
		"\x0297\x03\x02\x02\x029:\x03\x02\x02\x02:<\x03\x02\x02\x02;9\x03\x02\x02" +
		"\x02<W\x07\x05\x02\x02=?\x07\t\x02\x02>=\x03\x02\x02\x02?B\x03\x02\x02" +
		"\x02@>\x03\x02\x02\x02@A\x03\x02\x02\x02AC\x03\x02\x02\x02B@\x03\x02\x02" +
		"\x02CW\x07\x04\x02\x02DF\x07\t\x02\x02ED\x03\x02\x02\x02FI\x03\x02\x02" +
		"\x02GE\x03\x02\x02\x02GH\x03\x02\x02\x02HJ\x03\x02\x02\x02IG\x03\x02\x02" +
		"\x02JK\x07\x04\x02\x02KL\x07\x03\x02\x02LW\x07\x05\x02\x02MO\x07\t\x02" +
		"\x02NM\x03\x02\x02\x02OR\x03\x02\x02\x02PN\x03\x02\x02\x02PQ\x03\x02\x02" +
		"\x02QS\x03\x02\x02\x02RP\x03\x02\x02\x02ST\x07\x04\x02\x02TU\x07\x03\x02" +
		"\x02UW\x07\x04\x02\x02V9\x03\x02\x02\x02V@\x03\x02\x02\x02VG\x03\x02\x02" +
		"\x02VP\x03\x02\x02\x02W\t\x03\x02\x02\x02XZ\x07\t\x02\x02YX\x03\x02\x02" +
		"\x02Z]\x03\x02\x02\x02[Y\x03\x02\x02\x02[\\\x03\x02\x02\x02\\^\x03\x02" +
		"\x02\x02][\x03\x02\x02\x02^b\x07\x06\x02\x02_a\x07\t\x02\x02`_\x03\x02" +
		"\x02\x02ad\x03\x02\x02\x02b`\x03\x02\x02\x02bc\x03\x02\x02\x02c\v\x03" +
		"\x02\x02\x02db\x03\x02\x02\x02ei\x07\x07\x02\x02fh\x07\t\x02\x02gf\x03" +
		"\x02\x02\x02hk\x03\x02\x02\x02ig\x03\x02\x02\x02ij\x03\x02\x02\x02j\r" +
		"\x03\x02\x02\x02ki\x03\x02\x02\x02ln\x07\t\x02\x02ml\x03\x02\x02\x02n" +
		"q\x03\x02\x02\x02om\x03\x02\x02\x02op\x03\x02\x02\x02pr\x03\x02\x02\x02" +
		"qo\x03\x02\x02\x02rv\x07\n\x02\x02su\x07\t\x02\x02ts\x03\x02\x02\x02u" +
		"x\x03\x02\x02\x02vt\x03\x02\x02\x02vw\x03\x02\x02\x02w\x0F\x03\x02\x02" +
		"\x02xv\x03\x02\x02\x02y{\x07\t\x02\x02zy\x03\x02\x02\x02{~\x03\x02\x02" +
		"\x02|z\x03\x02\x02\x02|}\x03\x02\x02\x02}\x7F\x03\x02\x02\x02~|\x03\x02" +
		"\x02\x02\x7F\x83\x07\b\x02\x02\x80\x82\x07\t\x02\x02\x81\x80\x03\x02\x02" +
		"\x02\x82\x85\x03\x02\x02\x02\x83\x81\x03\x02\x02\x02\x83\x84\x03\x02\x02" +
		"\x02\x84\x11\x03\x02\x02\x02\x85\x83\x03\x02\x02\x02\x12\x18%+49@GPV[" +
		"biov|\x83";
	public static __ATN: ATN;
	public static get _ATN(): ATN {
		if (!DqlParser.__ATN) {
			DqlParser.__ATN = new ATNDeserializer().deserialize(Utils.toCharArray(DqlParser._serializedATN));
		}

		return DqlParser.__ATN;
	}

}

export class SentenceContext extends ParserRuleContext {
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	public SEMICOLON(): TerminalNode { return this.getToken(DqlParser.SEMICOLON, 0); }
	public EOF(): TerminalNode { return this.getToken(DqlParser.EOF, 0); }
	public logicalOperator(): LogicalOperatorContext[];
	public logicalOperator(i: number): LogicalOperatorContext;
	public logicalOperator(i?: number): LogicalOperatorContext | LogicalOperatorContext[] {
		if (i === undefined) {
			return this.getRuleContexts(LogicalOperatorContext);
		} else {
			return this.getRuleContext(i, LogicalOperatorContext);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return DqlParser.RULE_sentence; }
	// @Override
	public enterRule(listener: DqlListener): void {
		if (listener.enterSentence) {
			listener.enterSentence(this);
		}
	}
	// @Override
	public exitRule(listener: DqlListener): void {
		if (listener.exitSentence) {
			listener.exitSentence(this);
		}
	}
	// @Override
	public accept<Result>(visitor: DqlVisitor<Result>): Result {
		if (visitor.visitSentence) {
			return visitor.visitSentence(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class ExpressionContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return DqlParser.RULE_expression; }
	public copyFrom(ctx: ExpressionContext): void {
		super.copyFrom(ctx);
	}
}
export class ComplexExpressionContext extends ExpressionContext {
	public _left: ExpressionContext;
	public _expression: ExpressionContext;
	public _rest: ExpressionContext[] = [];
	public LPAR(): TerminalNode { return this.getToken(DqlParser.LPAR, 0); }
	public RPAR(): TerminalNode { return this.getToken(DqlParser.RPAR, 0); }
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	public logicalOperator(): LogicalOperatorContext[];
	public logicalOperator(i: number): LogicalOperatorContext;
	public logicalOperator(i?: number): LogicalOperatorContext | LogicalOperatorContext[] {
		if (i === undefined) {
			return this.getRuleContexts(LogicalOperatorContext);
		} else {
			return this.getRuleContext(i, LogicalOperatorContext);
		}
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: DqlListener): void {
		if (listener.enterComplexExpression) {
			listener.enterComplexExpression(this);
		}
	}
	// @Override
	public exitRule(listener: DqlListener): void {
		if (listener.exitComplexExpression) {
			listener.exitComplexExpression(this);
		}
	}
	// @Override
	public accept<Result>(visitor: DqlVisitor<Result>): Result {
		if (visitor.visitComplexExpression) {
			return visitor.visitComplexExpression(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class SimpleExpressionContext extends ExpressionContext {
	public criteria(): CriteriaContext {
		return this.getRuleContext(0, CriteriaContext);
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: DqlListener): void {
		if (listener.enterSimpleExpression) {
			listener.enterSimpleExpression(this);
		}
	}
	// @Override
	public exitRule(listener: DqlListener): void {
		if (listener.exitSimpleExpression) {
			listener.exitSimpleExpression(this);
		}
	}
	// @Override
	public accept<Result>(visitor: DqlVisitor<Result>): Result {
		if (visitor.visitSimpleExpression) {
			return visitor.visitSimpleExpression(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class CriteriaContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return DqlParser.RULE_criteria; }
	public copyFrom(ctx: CriteriaContext): void {
		super.copyFrom(ctx);
	}
}
export class BinaryCriteriaContext extends CriteriaContext {
	public field(): FieldContext {
		return this.getRuleContext(0, FieldContext);
	}
	public relationalOperator(): RelationalOperatorContext {
		return this.getRuleContext(0, RelationalOperatorContext);
	}
	public value(): ValueContext {
		return this.getRuleContext(0, ValueContext);
	}
	constructor(ctx: CriteriaContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: DqlListener): void {
		if (listener.enterBinaryCriteria) {
			listener.enterBinaryCriteria(this);
		}
	}
	// @Override
	public exitRule(listener: DqlListener): void {
		if (listener.exitBinaryCriteria) {
			listener.exitBinaryCriteria(this);
		}
	}
	// @Override
	public accept<Result>(visitor: DqlVisitor<Result>): Result {
		if (visitor.visitBinaryCriteria) {
			return visitor.visitBinaryCriteria(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class UnaryCriteriaContext extends CriteriaContext {
	public field(): FieldContext {
		return this.getRuleContext(0, FieldContext);
	}
	public unaryRelationalOperator(): UnaryRelationalOperatorContext {
		return this.getRuleContext(0, UnaryRelationalOperatorContext);
	}
	constructor(ctx: CriteriaContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: DqlListener): void {
		if (listener.enterUnaryCriteria) {
			listener.enterUnaryCriteria(this);
		}
	}
	// @Override
	public exitRule(listener: DqlListener): void {
		if (listener.exitUnaryCriteria) {
			listener.exitUnaryCriteria(this);
		}
	}
	// @Override
	public accept<Result>(visitor: DqlVisitor<Result>): Result {
		if (visitor.visitUnaryCriteria) {
			return visitor.visitUnaryCriteria(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class FieldContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return DqlParser.RULE_field; }
	public copyFrom(ctx: FieldContext): void {
		super.copyFrom(ctx);
	}
}
export class StaticFieldContext extends FieldContext {
	public WORD(): TerminalNode { return this.getToken(DqlParser.WORD, 0); }
	public WS(): TerminalNode[];
	public WS(i: number): TerminalNode;
	public WS(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(DqlParser.WS);
		} else {
			return this.getToken(DqlParser.WS, i);
		}
	}
	constructor(ctx: FieldContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: DqlListener): void {
		if (listener.enterStaticField) {
			listener.enterStaticField(this);
		}
	}
	// @Override
	public exitRule(listener: DqlListener): void {
		if (listener.exitStaticField) {
			listener.exitStaticField(this);
		}
	}
	// @Override
	public accept<Result>(visitor: DqlVisitor<Result>): Result {
		if (visitor.visitStaticField) {
			return visitor.visitStaticField(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class DynamicFieldContext extends FieldContext {
	public NUMBER(): TerminalNode { return this.getToken(DqlParser.NUMBER, 0); }
	public WS(): TerminalNode[];
	public WS(i: number): TerminalNode;
	public WS(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(DqlParser.WS);
		} else {
			return this.getToken(DqlParser.WS, i);
		}
	}
	constructor(ctx: FieldContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: DqlListener): void {
		if (listener.enterDynamicField) {
			listener.enterDynamicField(this);
		}
	}
	// @Override
	public exitRule(listener: DqlListener): void {
		if (listener.exitDynamicField) {
			listener.exitDynamicField(this);
		}
	}
	// @Override
	public accept<Result>(visitor: DqlVisitor<Result>): Result {
		if (visitor.visitDynamicField) {
			return visitor.visitDynamicField(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class DynamicStaticFieldContext extends FieldContext {
	public NUMBER(): TerminalNode { return this.getToken(DqlParser.NUMBER, 0); }
	public WORD(): TerminalNode { return this.getToken(DqlParser.WORD, 0); }
	public WS(): TerminalNode[];
	public WS(i: number): TerminalNode;
	public WS(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(DqlParser.WS);
		} else {
			return this.getToken(DqlParser.WS, i);
		}
	}
	constructor(ctx: FieldContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: DqlListener): void {
		if (listener.enterDynamicStaticField) {
			listener.enterDynamicStaticField(this);
		}
	}
	// @Override
	public exitRule(listener: DqlListener): void {
		if (listener.exitDynamicStaticField) {
			listener.exitDynamicStaticField(this);
		}
	}
	// @Override
	public accept<Result>(visitor: DqlVisitor<Result>): Result {
		if (visitor.visitDynamicStaticField) {
			return visitor.visitDynamicStaticField(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class DynamicDynamicFieldContext extends FieldContext {
	public NUMBER(): TerminalNode[];
	public NUMBER(i: number): TerminalNode;
	public NUMBER(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(DqlParser.NUMBER);
		} else {
			return this.getToken(DqlParser.NUMBER, i);
		}
	}
	public WS(): TerminalNode[];
	public WS(i: number): TerminalNode;
	public WS(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(DqlParser.WS);
		} else {
			return this.getToken(DqlParser.WS, i);
		}
	}
	constructor(ctx: FieldContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: DqlListener): void {
		if (listener.enterDynamicDynamicField) {
			listener.enterDynamicDynamicField(this);
		}
	}
	// @Override
	public exitRule(listener: DqlListener): void {
		if (listener.exitDynamicDynamicField) {
			listener.exitDynamicDynamicField(this);
		}
	}
	// @Override
	public accept<Result>(visitor: DqlVisitor<Result>): Result {
		if (visitor.visitDynamicDynamicField) {
			return visitor.visitDynamicDynamicField(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class RelationalOperatorContext extends ParserRuleContext {
	public OPERATION(): TerminalNode { return this.getToken(DqlParser.OPERATION, 0); }
	public WS(): TerminalNode[];
	public WS(i: number): TerminalNode;
	public WS(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(DqlParser.WS);
		} else {
			return this.getToken(DqlParser.WS, i);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return DqlParser.RULE_relationalOperator; }
	// @Override
	public enterRule(listener: DqlListener): void {
		if (listener.enterRelationalOperator) {
			listener.enterRelationalOperator(this);
		}
	}
	// @Override
	public exitRule(listener: DqlListener): void {
		if (listener.exitRelationalOperator) {
			listener.exitRelationalOperator(this);
		}
	}
	// @Override
	public accept<Result>(visitor: DqlVisitor<Result>): Result {
		if (visitor.visitRelationalOperator) {
			return visitor.visitRelationalOperator(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class UnaryRelationalOperatorContext extends ParserRuleContext {
	public UNARY_OPERATION(): TerminalNode { return this.getToken(DqlParser.UNARY_OPERATION, 0); }
	public WS(): TerminalNode[];
	public WS(i: number): TerminalNode;
	public WS(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(DqlParser.WS);
		} else {
			return this.getToken(DqlParser.WS, i);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return DqlParser.RULE_unaryRelationalOperator; }
	// @Override
	public enterRule(listener: DqlListener): void {
		if (listener.enterUnaryRelationalOperator) {
			listener.enterUnaryRelationalOperator(this);
		}
	}
	// @Override
	public exitRule(listener: DqlListener): void {
		if (listener.exitUnaryRelationalOperator) {
			listener.exitUnaryRelationalOperator(this);
		}
	}
	// @Override
	public accept<Result>(visitor: DqlVisitor<Result>): Result {
		if (visitor.visitUnaryRelationalOperator) {
			return visitor.visitUnaryRelationalOperator(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class ValueContext extends ParserRuleContext {
	public VALUE(): TerminalNode { return this.getToken(DqlParser.VALUE, 0); }
	public WS(): TerminalNode[];
	public WS(i: number): TerminalNode;
	public WS(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(DqlParser.WS);
		} else {
			return this.getToken(DqlParser.WS, i);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return DqlParser.RULE_value; }
	// @Override
	public enterRule(listener: DqlListener): void {
		if (listener.enterValue) {
			listener.enterValue(this);
		}
	}
	// @Override
	public exitRule(listener: DqlListener): void {
		if (listener.exitValue) {
			listener.exitValue(this);
		}
	}
	// @Override
	public accept<Result>(visitor: DqlVisitor<Result>): Result {
		if (visitor.visitValue) {
			return visitor.visitValue(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class LogicalOperatorContext extends ParserRuleContext {
	public LOGICAL(): TerminalNode { return this.getToken(DqlParser.LOGICAL, 0); }
	public WS(): TerminalNode[];
	public WS(i: number): TerminalNode;
	public WS(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(DqlParser.WS);
		} else {
			return this.getToken(DqlParser.WS, i);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return DqlParser.RULE_logicalOperator; }
	// @Override
	public enterRule(listener: DqlListener): void {
		if (listener.enterLogicalOperator) {
			listener.enterLogicalOperator(this);
		}
	}
	// @Override
	public exitRule(listener: DqlListener): void {
		if (listener.exitLogicalOperator) {
			listener.exitLogicalOperator(this);
		}
	}
	// @Override
	public accept<Result>(visitor: DqlVisitor<Result>): Result {
		if (visitor.visitLogicalOperator) {
			return visitor.visitLogicalOperator(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}

/* eslint-enable */

