import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Trigger } from '../../../lib/trigger/trigger.service';
import TriggerService = Trigger.TriggerService;
import { TriggerRightModel, TriggerUtils } from '../../../lib/trigger/trigger-utils';
import { QueryFieldModel } from '../../../util/core-utils';
import { OrderType } from '../../../lib/util/services';
import { Set } from 'immutable';
import TriggerType = TriggerUtils.TriggerType;
import { Transition, UIRouter } from '@uirouter/angular';
import { StateName } from '../../../app.state-names';
import { RightResolver, RightService } from '../../../lib/right.service';
import { RightModel } from '../../../app.rights';

@Component({
  selector: 'app-trigger-list',
  templateUrl: './trigger-list.component.html',
  styleUrls: ['./trigger-list.component.scss']
})
export class TriggerListComponent implements OnInit {

  TriggerOrderField = Trigger.TriggerOrderField;

  @Input()
  triggerService: TriggerService;

  @Input()
  triggerParentId?: number;

  @Input()
  scope: TriggerUtils.TriggerScope;

  @Input()
  expandable: boolean;

  @Input()
  withPaging: boolean = false;

  @Output()
  triggerCreate: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  triggerEdit: EventEmitter<TriggerModel> = new EventEmitter<TriggerModel>();

  @Output()
  triggerClone: EventEmitter<TriggerModel> = new EventEmitter<TriggerModel>();

  queryModel: QueryFieldModel<Trigger.TriggerOrderField> = new QueryFieldModel(Trigger.TriggerOrderField.CREATION_TIME, OrderType.DESC);

  listVisible: boolean = false;

  triggerList: TriggerModel[] = [];

  triggerRightModel: TriggerRightModel = new TriggerRightModel();

  constructor(
    private router: UIRouter,
    private rightService: RightService) {
  }

  ngOnInit() {
    this.loadRightModels((rightModel) => {
      this.triggerRightModel.init(rightModel, this.scope);
    });
  }

  toggleListVisible() {
    this.listVisible = !this.listVisible;
    if (this.listVisible) {
      this.loadList();
    }
  }

  private loadRightModels(completion: (rightModel: RightModel) => void) {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        completion(RightModel.of(resolver));
      }
    );
  }

  loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    this.triggerService.queryTriggers({
      triggerParentId: this.triggerParentId,
      order: Set.of(this.queryModel.getOrder()),
      paging: {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      }
    }).subscribe(result => {
      this.triggerList = [];
      result.items.forEach(trigger => {
        if (trigger) {
          const triggerType = TriggerUtils.triggerTypes.find((triggerType) => triggerType.type === trigger.type);
          const notificationEvent = TriggerUtils.notificationEvents.find((event) => event.notificationEvent === trigger.event);
          const item = new TriggerModel();
          item.id = trigger.id;
          item.name = trigger.name;
          item.type = triggerType ? triggerType!.type : undefined;
          item.typeStringKey = triggerType ? triggerType.stringKey : '';
          item.event = notificationEvent ? notificationEvent.stringKey : '';
          switch (trigger.type) {
            case 'EMAIL':
              item.templateName = trigger.email!.emailTemplate.name;
              break;
            case 'MESSAGE':
              item.templateName = trigger.message!.messageTemplate.name;
              break;
            case 'PDF':
              item.templateName = trigger.pdf!.pdfTemplate.code;
              break;
            case 'RECEIPT':
              item.templateName = trigger.receipt!.receiptTemplate.code;
              break;
          }
          item.disabled = trigger.disabled;
          this.triggerList.push(item);
        }
      });
      this.queryModel.currentPage = requestedPage;
      this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
    })
  }

  onTriggerCreateClicked() {
    this.triggerCreate.emit();
  }

  onTriggerCloneClicked(trigger: TriggerModel) {
    this.triggerClone.emit(trigger);
  }

  onTriggerEditClicked(trigger: TriggerModel) {
    this.triggerEdit.emit(trigger);
  }

  onTriggerDetailClicked(trigger: TriggerModel) {
    switch (this.scope) {
      case 'TASK':
        this.router.stateService.go(StateName.TASK_TRIGGER_DETAIL, {triggerParentId: this.triggerParentId, id: trigger.id});
        break;
      case 'TRANSPORT':
        this.router.stateService.go(StateName.TRANSPORT_TRIGGER_DETAIL, {triggerParentId: this.triggerParentId, id: trigger.id});
        break;
    }
  }

  onTriggerCreated() {
    this.listVisible || !this.expandable ? this.loadList() : this.toggleListVisible();
  }

  setDisabled(event: any, trigger: TriggerModel, disabled: boolean) {
    this.triggerService.disableTrigger({
      id: trigger.id,
      triggerParentId: this.triggerParentId,
      disabled: disabled
    }).subscribe(() => {
      this.loadList();
    });
  }

  orderBy(field: Trigger.TriggerOrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList();
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

}

class TriggerModel {
  id: number;
  type?: TriggerType;
  name: string = '';
  typeStringKey: string = '';
  event: string = '';
  templateName: string = '';
  disabled: boolean = false;
}
