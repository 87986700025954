import { UserProfileType } from '../lib/auth.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AppNgbTimeStruct } from './ngb-datepicker';
import { Models } from './model-utils';
import { Arrays } from '../lib/util/arrays';
import { DisabledEnum, SearchUtils } from './search-utils';
import { CompanySearchItem, UserGroupSearchItem } from '../admin/user/user-list/user-list.component';
import { MultiselectOptionItem, MultiselectOptionItemWithData } from './core-utils';
import { HistoryBaseModel } from '../admin/history-log/history-log/history-log.component';
import {LdapData, UserHistoryType} from '../lib/user.service';
import { SafeUrl } from '@angular/platform-browser';

export class UserBaseModel {
  externalId: string | null;
  type: UserProfileType;
  userName: string = '';
  password: string = '';
  personName: string = '';
  profilePicture?: string;
  selectedProfilePicture: string | null | undefined;
  emailAddress: string = '';
  phoneNumber: string = '';
  userGroups: UserGroupItemForDropdown[] = [];
  allowedDevices: MultiselectOptionItem<number>[] = [];
  inactivationDate: NgbDateStruct | null = null;
  inactivationTime: AppNgbTimeStruct = Models.zeroNgbTime();
  driver: DriverCreateModel = new DriverCreateModel();
  isDriver: boolean = false;
  hasSignature: boolean = false;
  vehicle: MultiselectOptionItem<number>[] = [];
  isProtected: boolean = false;
  color: number = 0;
  isHelpdesk: boolean = false;

  // Helpdesk
  contactPerson: MultiselectOptionItemWithData<number>[] = [];
  contactLocations: MultiselectOptionItem<number>[] = [];
  customerRecord: MultiselectOptionItemWithData<number>[] = [];

  ldap?: LdapData;

  public createUserGroupIds(): number[] {
    const ids: number[] = [];
    Arrays.iterateByIndex(this.userGroups, (user_group, index) => {
      const id = user_group.id;
      if (id) {
        ids[index] = id;
      }
    });
    return ids;
  }

  get vehicleId(): number | undefined {
    return this.vehicle.length > 0 ? this.vehicle[0].id : undefined;
  }

}

export class DriverCreateModel {
  medicalExpiryDate: NgbDateStruct | null = null;
  permissionExpiryDate: NgbDateStruct | null = null;
  drivingLicenceNumber: string = '';
  drivingLicenceCategories: string = '';
  drivingLicenceExpiryDate: NgbDateStruct | null = null;
}

export class UserGroupItemForDropdown extends MultiselectOptionItem<number> {
  companyIds?: number[];
}

export enum LdapFeature {
  EMAIL,
  PHONE_NUMBER,
  NFC_CARD
}

export class UserSearchModel extends SearchUtils.SearchModel {
  id: string = '';
  username: string = '';
  personName: string = '';
  externalId: string = '';
  emailAddress: string = '';
  disabled: DisabledEnum = DisabledEnum.FALSE;
  companies: CompanySearchItem[] = [];
  isDriver: boolean = false;
  userGroups: UserGroupSearchItem[] = [];
  type: UserTypeFilterOption = 'ALL';

  public isEmpty(): boolean {
    return this.id.length === 0
      && this.username.length === 0
      && this.personName.length === 0
      && this.externalId.length === 0
      && this.emailAddress.length === 0
      && this.companies.length === 0
      && this.userGroups.length === 0
      && !this.isDriver
      && this.disabled === DisabledEnum.FALSE
      && this.type === 'ALL'
      ;
  }
}

export type UserTypeFilterOption = 'ALL' | 'USER' | 'LDAP';

export class UserTypeFilterOptionsObject {
  type: UserTypeFilterOption;
  stringKey: string;
}

export const userTypeFilterOptions: UserTypeFilterOptionsObject[] = [
  {type: 'ALL', stringKey: 'COMMON_VALUE_ALL'},
  {type: 'USER', stringKey: 'LOGIN_METHOD_NAME_LOCAL_USER'},
  {type: 'LDAP', stringKey: 'LOGIN_METHOD_NAME_LDAP_USER'},
];

export class UserHistoryModel extends HistoryBaseModel {
  type: UserHistoryType;
}
