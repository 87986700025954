import { SiteTourBaseComponent, SiteTourFactory, SiteTourId } from '../site-tour.factory';
import { TourStep } from 'ngx-guided-tour';
import { SiteTourStringKeys } from '../site-tour-string-keys';
import { TaskRecordListComponent } from '../../../admin/task/task-record/task-record-list/task-record-list.component';
import {MatDialogRef} from "@angular/material/dialog";
import {
  TaskRecordStateChangeDialogComponent
} from "../../../admin/task/task-record/task-record-state-change-dialog/task-record-state-change-dialog.component";

export class TaskRecordListBaseTourFactory extends SiteTourFactory {

  protected getTourSteps(baseComponent?: SiteTourBaseComponent): TourStep[] {
    return [
      {
        selector: '#task-record-list-panel',
        title: SiteTourStringKeys.SITE_TOUR_TASK_LIST_PANEL_STEP_TITLE,
        content: SiteTourStringKeys.SITE_TOUR_TASK_LIST_PANEL_STEP_CONTENT,
        orientation: 'center',
        action: () => {
        },
        closeAction: () => {
        },
        skipStep: false,
      },
      {
        selector: '#task-record-list-add',
        title: SiteTourStringKeys.SITE_TOUR_TASK_LIST_ADD_STEP_TITLE,
        content: SiteTourStringKeys.SITE_TOUR_TASK_LIST_ADD_STEP_CONTENT,
        orientation: 'bottom-right',
        action: () => {
        },
        closeAction: () => {
        },
        skipStep: false,
      }
    ];
  }

  protected get tourKey(): SiteTourId {
    return SiteTourId.TASK_RECORD_LIST_BASE;
  }

}

export class TaskRecordListOpenTourFactory extends SiteTourFactory {

  protected getTourSteps(baseComponent?: SiteTourBaseComponent): TourStep[] {
    const component = <TaskRecordListComponent>baseComponent;
    return [
      {
        selector: '#task-record-list-panel',
        title: SiteTourStringKeys.SITE_TOUR_TASK_OPEN_PANEL_STEP_TITLE,
        content: SiteTourStringKeys.SITE_TOUR_TASK_OPEN_PANEL_STEP_CONTENT,
        orientation: 'center',
        action: () => {
        },
        closeAction: () => {
        },
        skipStep: false,
      },
      {
        selector: '#task-record-list-search-button',
        title: SiteTourStringKeys.SITE_TOUR_TASK_OPEN_SEARCH_BUTTON_STEP_TITLE,
        content: SiteTourStringKeys.SITE_TOUR_TASK_OPEN_SEARCH_BUTTON_STEP_CONTENT,
        orientation: 'left',
        action: () => {
        },
        closeAction: () => {
          component.openSearchPanel();
        },
        skipStep: false,
      },
      {
        selector: '#task-record-list-search-panel',
        title: SiteTourStringKeys.SITE_TOUR_TASK_OPEN_SEARCH_PANEL_STEP_TITLE,
        content: SiteTourStringKeys.SITE_TOUR_TASK_OPEN_SEARCH_PANEL_STEP_CONTENT,
        orientation: 'center',
        action: () => {
        },
        closeAction: () => {
        },
        skipStep: false,
      },
      {
        selector: '#dql-link',
        title: SiteTourStringKeys.SITE_TOUR_TASK_OPEN_DQL_BUTTON_STEP_TITLE,
        content: SiteTourStringKeys.SITE_TOUR_TASK_OPEN_DQL_BUTTON_STEP_CONTENT,
        orientation: 'bottom-left',
        action: () => {
        },
        closeAction: () => {
          component.toggleSearch();
        },
        skipStep: false,
      },
      {
        selector: '#task-record-list-header-id',
        title: SiteTourStringKeys.SITE_TOUR_TASK_OPEN_SORT_STEP_TITLE,
        content: SiteTourStringKeys.SITE_TOUR_TASK_OPEN_SORT_STEP_CONTENT,
        orientation: 'bottom',
        action: () => {
        },
        closeAction: () => {
        },
        skipStep: false,
      },
      {
        selector: '#task-record-list-row-checkbox-0',
        title: SiteTourStringKeys.SITE_TOUR_TASK_OPEN_CHECK_BOX_STEP_TITLE,
        content: SiteTourStringKeys.SITE_TOUR_TASK_OPEN_CHECK_BOX_STEP_CONTENT,
        orientation: 'top',
        action: () => {
        },
        closeAction: () => {
          component.taskRecordList[0].selected = true;
        },
        skipStep: false,
      },
      {
        selector: '#task-record-list-state-change-open',
        title: SiteTourStringKeys.SITE_TOUR_TASK_OPEN_OPEN_BUTTON_STEP_TITLE,
        content: SiteTourStringKeys.SITE_TOUR_TASK_OPEN_OPEN_BUTTON_STEP_CONTENT,
        orientation: 'bottom-right',
        action: () => {
        },
        closeAction: () => {
          if (component.settableStateOpen.stateObject) {
            component.checkState(component.settableStateOpen.stateObject!);
          }
        },
        skipStep: false,
      },

    ];
  }

  protected get tourKey(): SiteTourId {
    return SiteTourId.TASK_RECORD_LIST_OPEN_1;
  }

}

export class TaskRecordListOpen2TourFactory extends SiteTourFactory {

  protected getTourSteps(baseComponent?: SiteTourBaseComponent, dialogRef?: MatDialogRef<any>): TourStep[] {
    const component = <TaskRecordListComponent>baseComponent;
    return [
      {
        selector: '#task-record-list-approve-state-dialog-content',
        title: SiteTourStringKeys.SITE_TOUR_TASK_OPEN_APPROVE_DIALOG_STEP_TITLE,
        content: SiteTourStringKeys.SITE_TOUR_TASK_OPEN_APPROVE_DIALOG_STEP_CONTENT,
        orientation: 'bottom',
        action: () => {
        },
        closeAction: () => {
        },
        skipStep: false,
      },
      {
        selector: '#task-record-list-approve-state-save',
        title: SiteTourStringKeys.SITE_TOUR_TASK_OPEN_APPROVE_SAVE_STEP_TITLE,
        content: SiteTourStringKeys.SITE_TOUR_TASK_OPEN_APPROVE_SAVE_STEP_CONTENT,
        orientation: 'bottom',
        action: () => {
        },
        closeAction: () => {
          (<MatDialogRef<TaskRecordStateChangeDialogComponent>>dialogRef).componentInstance.tryStateChange();
        },
        skipStep: false,
      },
    ];
  }

  protected get tourKey(): SiteTourId {
    return SiteTourId.TASK_RECORD_LIST_OPEN_2;
  }

  protected get useOrb(): boolean {
    return false;
  }

  public get timeout(): number {
    return 800;
  }

}
