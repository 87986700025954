/* eslint-disable */
import {Component, ComponentRef, HostListener, Input, ViewChild,} from '@angular/core';
import {FormRecordFieldHolderDirective} from '../../shared/form-record-field-holder.directive';
import {FormRef, ForwardingFormRef,} from '../../../../../lib/util/services';
import {
  FieldDataTypeDescriptor,
  FormRecordFieldContext,
  FormRecordFieldView,
  FormRecordFieldViewModel,
} from '../../../../../util/form/form-utils';
import {FormRecord} from '../../../../../lib/form/form-record.service';
import {List, Map as ImmutableMap, Set} from 'immutable';
import {FormRecordComposedContainer} from './form-record-composed-container';
import {CssStyle} from '../../../../../util/host-styles';
import {Form} from '../../../../../lib/form/form.service';
import {ScreenSizes} from '../../../../../util/screen-size';
import {UiConstants} from '../../../../../util/core-utils';
import {FormRecordInactivityManagerFactory,} from '../../manager/form-record-inactivity-manager';
import {FormRecordFieldViewModelFactory} from '../../manager/form-record-field-view-model-factory';
import {AppType, AppTypeHelperService} from "../../../../../lib/util/app-type-helper.service";

/* eslint-enable */

@Component({
  selector: 'app-form-record-composed-container',
  templateUrl: 'form-record-composed-container.component.html',
  styleUrls: ['form-record-composed-container.component.scss'],
})
export class FormRecordComposedContainerComponent
  implements FormRecordComposedContainer.View, FormRecordComposedContainer.PreView {

  @ViewChild(FormRecordFieldHolderDirective, {static: true})
  fieldHost: FormRecordFieldHolderDirective;

  layoutStyle = {
    'display': 'flex',
    'flex-wrap': 'wrap',
    'width': '100%',
  };

  @Input()
  form?: FormRef;

  @Input()
  readonly?: boolean;

  @Input()
  cloning?: boolean;

  private previewMode = false;

  private readonly fieldModel = new Model();

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.regenerateEachFieldStyleAttributes();
  }

  constructor(
    private formRecordInactivityManagerFactory: FormRecordInactivityManagerFactory,
    private formRecordFieldViewModelFactory: FormRecordFieldViewModelFactory,
    private appTypeHelperService: AppTypeHelperService) {
  }

  setPreviewMode(on: boolean) {
    this.previewMode = on;
  }

  createModel(): FormRecordComposedContainer.Model {
    const requests: FormRecord.FieldEditRequest[] = [];
    const model = new FormRecordComposedContainer.Model();
    List.of(...this.fieldModel.fieldItems).forEach((fieldItem) => {
      if (!fieldItem) {
        return true;
      }
      const m: FormRecordFieldViewModel | undefined = this.formRecordFieldViewModelFactory.createModel(fieldItem.fieldView.instance);
      if (m) {
        requests.push(m.fieldEditRequest);
      }
    });
    model.fields = Set.of(...requests);
    return model;
  }

  getFieldHost(): FormRecordFieldHolderDirective {
    return this.fieldHost;
  }

  hasLocalFieldError(): boolean {
    let hasError = false;
    List.of(...this.fieldModel.fieldItems).forEach((fieldItems) => {
      if (!fieldItems) {
        return true;
      }
      if (fieldItems.fieldView.instance.hasLocalFieldError()) {
        hasError = true;
        return false;
      }
    });
    return hasError;
  }

  loadFormRecord(args: FormRecordComposedContainer.Args, contentView?: FormRecordComposedContainer.View) {
    this.fieldModel.fieldItems = [];
    this.fieldHost.viewContainerRef.clear();
    const fieldOrder: (number | null)[] = [];
    const inactivityManager = this.formRecordInactivityManagerFactory.create({
      commandManager: args.commandManager,
      commandResultStore: args.commandResultStore,
      readonlyForm: () => {
        return !!this.readonly;
      },
    });
    const appType = this.appTypeHelperService.appType();
    args.form.groups.forEach((group) => {
      if (!group) { // NOTE: do not ignore group if disabled, cause we must keep the existing data
        return true;
      }
      const fieldOrderInGroup: (number | null)[] = [];
      const fieldItemsInGroup: FieldItem[] = [];
      group.fields.forEach((field) => {
        if (!field) {
          return true;
        }
        let originalModel: any = undefined;
        if (this.previewMode) {
          const cv: any = contentView!;
          const cfis: FieldItem[] = cv.fieldModel.fieldItems;
          const origItem = this.findItemByField(cfis, field);
          originalModel = origItem!.fieldModel; // If this line dies, something is wrong with the preview logic.
        }
        const descriptor = args.fieldDataTypeSelectors.findDescriptor(field.dataTypeSelector);
        const fieldView = this.createFieldView(group, field, descriptor);
        const fieldModel = fieldView.instance.registerField(this.createFieldContext(args, group, field, inactivityManager, appType), originalModel);
        const fieldItem = {
          fieldView: fieldView,
          field: field,
          fieldModel: fieldModel,
        };
        this.applyFieldStyles(fieldItem);
        this.fieldModel.fieldItems.push(fieldItem);
        fieldItemsInGroup.push(fieldItem);
        if (!(group.disabled || field.disabled)) {
          // Disabled fields are always invisible, but they are added to the fieldItems array because we keep the data.
          if (field.displayOnNewRow) {
            const lineBreakView = this.fieldHost.createLineBreakView();
            fieldOrder.push(null);
            fieldOrderInGroup.push(null);
            this.fieldHost.viewContainerRef.insert(lineBreakView.hostView);
          }
          fieldOrder.push(field.fieldId);
          fieldOrderInGroup.push(field.fieldId);
          this.fieldHost.viewContainerRef.insert(fieldView.hostView);
          if (this.previewMode) {
            // Preview mode. Break after the first field.
            return false;
          }
        }
      });
      const fieldViewsInGroup = List.of(...fieldItemsInGroup.map((item) => {
        return item.fieldView.instance;
      }));
      inactivityManager.addViews({
        form: args.form,
        groupId: group.groupId,
        groupHost: undefined,
        fieldViews: fieldViewsInGroup,
        fieldOrder: List(fieldOrderInGroup),
        fieldHost: this.fieldHost
      });
    });
    const fieldViews = List.of(...this.fieldModel.fieldItems.map((item) => {
      return item.fieldView.instance;
    }));
    inactivityManager.createLogic(args.form);
    this.fieldModel.fieldItems.forEach((fieldItem) => {
      fieldItem.fieldView.instance.registerFieldViews({
        fieldViews: fieldViews,
        inactivityManager: inactivityManager
      });
    });
  }

  private createFieldView(group: Form.Group, field: Form.Field, descriptor: FieldDataTypeDescriptor):
    ComponentRef<FormRecordFieldView & CssStyle.PropertySetterFnProvider> {
    const fieldWrapper = this.fieldHost.createFieldWrapperView();
    if (group.disabled || field.disabled) {
      const fieldView = this.fieldHost.createDisabledFieldView(descriptor.selector);
      fieldWrapper.instance.setDelegate(fieldView);
      return fieldWrapper;
    } else {
      const fieldView = descriptor.formRecordFieldViewFactory.createView();
      fieldWrapper.instance.setDelegate(fieldView);
      return fieldWrapper;
    }
  }

  private createFieldContext(args: FormRecordComposedContainer.Args, group, field, inactivityManager, appType): FormRecordFieldContext {
    return {
      commandManager: args.commandManager,
      commandResultStore: args.commandResultStore,
      inactivityManager: inactivityManager,
      htmlForm: this.createForwardingHtmlForm(),
      readonly: () => this.readonly !== undefined ? this.readonly : true,
      cloning: () => this.cloning !== undefined ? this.cloning : false,
      configuration: args.configuration,
      form: args.form,
      group: group,
      field: field,
      validationType: appType === AppType.HELPDESK ? field.helpdesk.validationType : field.admin.validationType,
      fieldRecord: args.record
        ? FormRecord.FormRecords.findComposedFieldRecord(args.record, field) : undefined
    };
  }

  private generateFieldStyleAttributes(field: Form.Field): ImmutableMap<CssStyle.Property, string> {
    const widthPercent = (window.innerWidth <= ScreenSizes.WIDTH_TABLET ? 100 : field.formFieldWidthPercent) + '%';
    return ImmutableMap.of(
      CssStyle.Property.flex, '1 1 ' + widthPercent,
      CssStyle.Property.maxWidth, widthPercent,
      CssStyle.Property.padding, UiConstants.formRecordFieldPadding,
    );
  }

  private regenerateEachFieldStyleAttributes() {
    List.of(...this.fieldModel.fieldItems).forEach((fieldItem) => {
      if (!fieldItem) {
        return true;
      }
      this.applyFieldStyles(fieldItem);
    });
  }

  private applyFieldStyles(fieldItem: FieldItem) {
    const generatedStyles = this.generateFieldStyleAttributes(fieldItem.field);
    generatedStyles.forEach((value, key) => {
      const fn = fieldItem.fieldView.instance.getPropertySetterFn(key!);
      if (fn) {
        fn(value);
      }
    });
  }

  private createForwardingHtmlForm() {
    return new ForwardingFormRef({
      formFn: () => {
        return this.form;
      }
    });
  }

  private findItemByField(fieldItems: FieldItem[], field: Form.Field): FieldItem | undefined {
    let found: FieldItem | undefined = undefined;
    fieldItems.forEach((fieldItem) => {
      if (fieldItem.field.fieldId === field.fieldId) {
        found = fieldItem;
      }
    });
    return found;
  }

}

class Model {
  fieldItems: FieldItem[] = [];
}

interface FieldItem {
  field: Form.Field;
  fieldView: ComponentRef<FormRecordFieldView & CssStyle.PropertySetterFnProvider>;
  fieldModel: any;
}
