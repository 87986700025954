/* eslint-disable */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DqlSearchFieldComponent } from '../dql-search-field.component';
import {
  ComplexDqlExpressionBuilder,
  DqlBinaryCriteriaModel,
  DqlCriteriaModel,
  DqlExpressionBuilder,
  DqlLogicalOperation,
  DqlLogicalOperationObject,
  DqlOperations,
  SimpleDqlExpressionBuilder
} from '../../dql-search-container/dql-search-container.model';
import { TranslateUtils } from '../../../../util/translate';
import { SearchItem } from '../dql-search-field-list/dql-search-field-list.model';
import { TranslateService } from '@ngx-translate/core';
import { Angular2Multiselects } from '../../../../util/multiselect';

/* eslint-enable */

@Component({
  selector: 'app-dql-search-field-enum',
  templateUrl: './dql-search-field-enum.component.html',
  styleUrls: ['./dql-search-field-enum.component.scss']
})
export class DqlSearchFieldEnumComponent implements OnInit, DqlSearchFieldComponent {

  @Output()
  onDelete: EventEmitter<DqlExpressionBuilder> = new EventEmitter<DqlExpressionBuilder>();

  private _expr: ComplexDqlExpressionBuilder;

  criteriaTemplate: DqlCriteriaModel;

  @Input()
  disableDelete: boolean = false;

  items: SearchItem[] = [];
  selectedValue: SearchItem[] = [];

  dropdownSettings?: Angular2Multiselects.Settings;

  private logical = new DqlLogicalOperationObject(DqlLogicalOperation.OR);

  @Input()
  set expression(exp: DqlExpressionBuilder) {
    if (exp instanceof SimpleDqlExpressionBuilder) {
      this.criteriaTemplate = exp.criteria;
      this._expr = new ComplexDqlExpressionBuilder();
      this.onItemSelect();
    }
    if (exp instanceof ComplexDqlExpressionBuilder) {
      this._expr = exp;
      this.criteriaTemplate = (<SimpleDqlExpressionBuilder>this._expr.expressionList[0]).criteria;
      this.loadSelectedValues(this._expr);
    }
    this._expr.closeExpression();
    this.criteriaTemplate.operationChanged().subscribe((op) => {
      if (op === DqlOperations.EQUALS) {
        this.logical.operation = DqlLogicalOperation.OR;
      }
      if (op === DqlOperations.NOT_EQUALS) {
        this.logical.operation = DqlLogicalOperation.AND;
      }
      this._expr.changeCriteriaOperation(op);
    });
  }

  get expression(): DqlExpressionBuilder {
    return this._expr;
  }


  constructor(private translateService: TranslateService) {
  }

  ngOnInit() {
    this.initDropDown();
    if (this.criteriaTemplate.field && this.criteriaTemplate.field.values) {
      const stringKeys: string[] = [];
      this.criteriaTemplate.field.values.forEach((v) => stringKeys.push(v.stringKey));
      this.translateService.get(stringKeys).subscribe((texts) => {
        this.criteriaTemplate.field!.values!.forEach((v) => {
          const i = {
            id: v.id,
            itemName: TranslateUtils.extractValueFromObject(texts, v.stringKey)
          };
          this.items.push(i);
          const selected = this.selectedValue.find(v => v.id === i.id);
          if (selected) {
            selected.itemName = i.itemName;
          }

        });
      });
    }
  }

  initDropDown() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(false)
      .enableCheckAll(true)
      .build();
  }

  private loadSelectedValues(expr: ComplexDqlExpressionBuilder) {
    this.selectedValue = [];
    expr.expressionList.forEach(e => {
      if (e instanceof SimpleDqlExpressionBuilder) {
        const value = e.criteria.getValue();
        if (value.length > 0) {
          this.selectedValue.push({
            id: value,
            itemName: ''
          });
        }
      }
    });
  }

  onItemSelect(item?: SearchItem) {
    if (item) {
      let ex: DqlExpressionBuilder | undefined = undefined;
      this._expr.expressionList.forEach(e => {
        if (e instanceof SimpleDqlExpressionBuilder) {
          const value = e.criteria.getValue();
          if (value.length > 0 && value === item.id) {
            ex = e;
          }
        }
      });
      if (ex) {
        return;
      }
    }

    const criteria = new DqlBinaryCriteriaModel();
    criteria.field = this.criteriaTemplate.field;
    criteria.operation = this.criteriaTemplate.operation;
    const expr = new SimpleDqlExpressionBuilder();
    expr.addCriteria(criteria);
    expr.closeExpression();
    this._expr.addExpression(expr);
    if (this._expr.logicalList.length < this._expr.expressionCount - 1) {
      this._expr.addLogical(this.logical);
    }
    if (item) {
      criteria.value = item.id;
    }
  }

  onItemDeselect(item: SearchItem) {
    let ex: DqlExpressionBuilder | undefined = undefined;
    this._expr.expressionList.forEach(e => {
      if (e instanceof SimpleDqlExpressionBuilder) {
        const value = e.criteria.getValue();
        if (value.length > 0 && value === item.id) {
          ex = e;
        }
      }
    });
    if (ex) {
      this._expr.removeExpression(ex);
    }
  }

  onSelectAll() {
    this.items.forEach(item => {
      this.onItemSelect(item);
    });
  }

  onDeselectAll() {
    this.items.forEach(item => {
      this.onItemDeselect(item);
    });
  }

}
