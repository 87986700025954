/* eslint-disable */
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AuthService } from '../../../lib/auth.service';
import { QueryFieldModel, UiConstants, UserItem } from '../../../util/core-utils';
import { UserData, UserDataLoader } from '../../../lib/user-data-loader';
import { Arrays } from '../../../lib/util/arrays';
import { IdentityArray } from '../../../lib/util/messages';
import { TranslateService } from '@ngx-translate/core';
import { MessageListModel, RecipientsModel } from '../../../util/message/message-utils';
import { Message, MessageList, MessagesService, Recipients, } from '../../../lib/message-parent/messages.service';
import { RightResolver, RightService } from '../../../lib/right.service';
import { RightModel } from '../../../app.rights';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Dates, LocalDate } from '../../../lib/util/dates';
import { OrderType, ResourceQueryResult, Services } from '../../../lib/util/services';
import { Strings } from '../../../lib/util/strings';
import { MessageSearch, MessageSearchService } from '../../../lib/message-search-service';
import { combineLatest, Observable } from 'rxjs';
import { Models } from '../../../util/model-utils';
import { Set } from 'immutable';
import { UserGroup, UserGroupService } from '../../../lib/user-group.service';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { StateName } from '../../../app.state-names';
/* eslint-enable */

@Component({
  selector: 'app-notification-list',
  templateUrl: 'notification-list.component.html',
  styleUrls: ['notification-list.component.scss']
})
export class NotificationListComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('importDialog') importDialog: ModalDirective;

  @ViewChild('searchIcon', { static: true }) searchIcon: ElementRef;

  searchModel: MessageSearchModel = new MessageSearchModel();
  showSearch: boolean = false;
  queryModel: QueryFieldModel<MessageList.OrderField> = new QueryFieldModel(MessageList.OrderField.ID, OrderType.DESC);
  messageList: MessageListModel[] = [];
  ownerUsers: UserData[] = [];
  userList: UserItem[] = [];
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  rightModel: RightModel = RightModel.empty();
  includeSystemMessagesSelected = false;
  MessageList = MessageList;
  UiConstants = UiConstants;

  ngOnInit() {
    this.loadRightModels();
    this.translateService.get('COMMON_NOTIFICATIONS').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.loadSearch(() => {
      this.showSearch = !this.searchModel.isEmpty();
      this.loadList();
    });
  }

  ngAfterViewInit(): void {
    this.loadUsers();
  }

  private loadSearch(completion: () => void) {
    const obs: Observable<SearchLoadResult> = combineLatest(
      this.messageSearchService.getSearchData({}),
      (storedSearchData: MessageSearch.SearchDataResult) => {
        const result: SearchLoadResult = {
          storedSearchData: storedSearchData
        };
        return result;
      }
    );
    obs.subscribe(
      (result: SearchLoadResult) => {
        this.postInitSearch(result.storedSearchData);
        completion();
      }
    );
  }

  private postInitSearch(storedSearchData: MessageSearch.SearchDataResult) {
    this.queryModel.itemsPerPage = storedSearchData.searchData.itemsPerPage;
    this.queryModel.currentPage = storedSearchData.searchData.pageNumber;
    this.queryModel.setOrder(storedSearchData.searchData.order);
    this.searchModel.title = storedSearchData.searchData.title;
    this.searchModel.content = storedSearchData.searchData.content;
    this.searchModel.sender = storedSearchData.searchData.sender;
    this.searchModel.sendDateFrom = Models.localDateToNgbDate(storedSearchData.searchData.sendDateFrom);
    this.searchModel.sendDateTo = Models.localDateToNgbDate(storedSearchData.searchData.sendDateTo);
    this.searchModel.type = storedSearchData.searchData.type;
    (this.searchModel.type && this.searchModel.type === 'USER')
      ? this.includeSystemMessagesSelected = false
      : this.includeSystemMessagesSelected = true
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  systemMessagesChecked() {
    this.includeSystemMessagesSelected = !this.includeSystemMessagesSelected;
    if (this.searchModel.type === 'USER') {
      this.searchModel.type = undefined;
    }
    else {
      this.searchModel.type = 'USER';
    }
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  onSearchClicked() {
    this.loadList();
  }

  onSearchReset() {
    this.messageSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.showSearch = true;
          this.loadList(1);
        });
      }
    );
  }

  private saveSearch() {
    const request = {
      searchData: {
        itemsPerPage: this.queryModel.itemsPerPage,
        pageNumber: this.queryModel.currentPage,
        order: this.queryModel.getOrder(),
        title: this.searchModel.title,
        content: this.searchModel.content,
        sender: this.searchModel.sender,
        sendDateFrom: Models.ngbDateToLocalDate(this.searchModel.sendDateFrom),
        sendDateTo: Models.ngbDateToLocalDate(this.searchModel.sendDateTo),
        type: this.searchModel.type
      }
    };
    this.messageSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  private parseDateFrom(model: NgbDateStruct | null): LocalDate | undefined {
    return !model ? undefined : Dates.createLocalDate({
      year: model.year,
      month: model.month,
      day: model.day
    });
  }

  private parseDateTo(model: NgbDateStruct | null): LocalDate | undefined {
    return !model ? undefined : Dates.createLocalDate({
      year: model.year,
      month: model.month,
      day: model.day
    });
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: MessageList.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }


  private loadList(pageNumber?: number) {
    this.messageList = [];
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();

    this.messageService.query({
      title: Strings.undefinedOrNonEmpty(this.searchModel.title),
      content: Strings.undefinedOrNonEmpty(this.searchModel.content),
      type: this.searchModel.type,
      send_date_from: Services.localDateToString(
        this.searchModel.sendDateFrom
          ? this.parseDateFrom(this.searchModel.sendDateFrom)
          : undefined),
      send_date_to: Services.localDateToString(
        this.searchModel.sendDateTo
          ? this.parseDateFrom(this.searchModel.sendDateTo)
          : undefined),
      sender: Strings.undefinedOrNonEmpty(this.searchModel.sender),
      page_number: requestedPage,
      number_of_items: this.queryModel.itemsPerPage,
      order: Services.createOrderFieldParameter(MessageList.Keys.toOrderFieldKey, Set.of(order)),
    })
    .subscribe(
      (result: ResourceQueryResult<Message>) => {
        Arrays.iterateByIndex(result.items, message => {
          const messageModel = new MessageListModel();
          messageModel.id = message.id;
          messageModel.title = message.title;
          messageModel.content = message.content;
          messageModel.sent_date = message.sent_date;
          messageModel.creation_time = message.creation_time;
          messageModel.recipients = this.createRecipientModel(message.recipients);

          this.messageList.push(messageModel);
        });

        this.getRelevantRecipients();

        this.queryModel.currentPage = requestedPage;
        this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
        this.queryModel.currentNumberOfItems = result.pagingResult.totalNumberOfItems;
      }
    );
  }

  private createRecipientModel(recipient: Recipients): RecipientsModel {
    const recipientsModel = new RecipientsModel();
    recipientsModel.user_group_ids = !recipient.user_group_ids ? [] : recipient.user_group_ids;
    recipientsModel.user_profiles = !recipient.user_profiles ? [] : recipient.user_profiles;
    recipientsModel.mobile_app_ids = !recipient.mobile_app_ids ? [] : recipient.mobile_app_ids;
    return recipientsModel;
  }

  private getRelevantRecipients() {
    Arrays.iterateByIndex(this.messageList, message => {
      this.getRelevantRecipient(message);
    });
  }

  getRelevantRecipient(message: MessageListModel) {
    // Sent to group
    if (message.recipients.user_group_ids !== null && message.recipients.user_group_ids.length > 0) {
      message.relevantRecipientName = this.translateService.instant('NOTIFICATION_LIST_RECIPIENT_MULTIPLE');
    }
    else if (message.recipients.user_profiles !== null && message.recipients.user_profiles.length > 0 && this.userList !== null) {
      for (const userData of this.userList) {

        // Sent to multiple user
        if (message.recipients.user_profiles.length > 1) {
          message.relevantRecipientName = this.translateService.instant('NOTIFICATION_LIST_RECIPIENT_MULTIPLE');
        }
        // Sent to one user
        else if (userData.id === message.recipients.user_profiles[0].id) {
          message.relevantRecipientName = (userData.text !== undefined) ? userData.text : '';
        }
      }
    }
    else if (message.recipients.mobile_app_ids !== null && message.recipients.mobile_app_ids.length > 0) {
      message.relevantRecipientName = this.translateService.instant('NOTIFICATION_LIST_RECIPIENT_MULTIPLE');
    }
  }

  private loadUsers(): void {
    this.userService.loadAll()
      .subscribe(
        (users: IdentityArray<UserData>) => {
          this.userList = [];

          Arrays.iterateByIndex(users, (user) => {
            const item = new UserItem();
            item.id = user.id;
            item.text = user.person_name + ' (' + user.user_name + ')';
            this.userList.push(item);
          });
        }
      );
  }

  constructor(
    private translateService: TranslateService,
    private messageService: MessagesService,
    private messageSearchService: MessageSearchService,
    private authService: AuthService,
    private userGroupService: UserGroupService,
    private userService: UserDataLoader,
    private rightService: RightService) {
  }

  ngOnDestroy() {
    this.saveSearch();
  }
}

export class MessageSearchModel {
  title: string;
  content: string;
  type?: string = 'USER';
  sendDateFrom: NgbDateStruct | null = null;
  sendDateTo: NgbDateStruct | null = null;
  sender: string;

  public isEmpty(): boolean {
    return this.title.length === 0
      && this.content.length === 0
      && this.sender.length === 0
      && this.sendDateFrom === null
      && this.sendDateTo === null
      && this.type === undefined
      ;
  }
}

interface SearchLoadResult {
  storedSearchData: MessageSearch.SearchDataResult,
}
