<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding-half container-vertical-padding-half animated fadeIn">


  <form class="form-horizontal bordered-row" (ngSubmit)="createTask()" #f="ngForm" [formGroup]="formGroup"
        novalidate>

    <div class="row ml-0 mr-0">

      <div class="col-xs-12 col-sm-12 col-md-6 card-custom-padding">

        <div class="container-vertical-padding-half">

          <div class="card">

            <div class="card-header">
              {{'FORM_CREATE_TITLE'|translate}}
            </div>

            <div class="card-body">
              <div class="form-group row"
                   [ngClass]="{ 'has-danger': hasFieldError(Task.ValidatedField.NAME) || hasLocalFieldError('name') }">
                <label class="col-form-label form-control-label col-md-4 detail-title required-field-label">
                  {{'FORM_CREATE_NAME_TITLE' | translate}}
                </label>
                <div class="col-md-8">
                  <input type="text" class="form-control" placeholder="{{'FORM_CREATE_NAME_TITLE' | translate}}"
                         maxlength="{{UiConstants.maximumVarcharLength}}"
                         [ngClass]="{ 'form-control-danger': hasFieldError(Task.ValidatedField.NAME) || hasLocalFieldError('name') }"
                         formControlName="name"
                         [(ngModel)]="model.name"
                         (ngModelChange)="removeFieldError(Task.ValidatedField.NAME)">
                  <div class="form-control-feedback" *ngIf="hasFieldError(Task.ValidatedField.NAME)">
                    {{getFieldErrorText(Task.ValidatedField.NAME)}}
                  </div>
                  <div class="form-control-feedback" *ngIf="hasLocalFieldError('name')">
                    <span
                      *ngIf="hasLocalFieldError('name', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
                  </div>
                </div>
              </div>

              <div class="form-group row" [ngClass]="{ 'has-danger': hasFieldError(Task.ValidatedField.EXTERNAL_ID)}">
                <label class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_EXTERNAL_ID' |
                  translate}}</label>
                <div class="col-md-8">
                  <input type="text" class="form-control" placeholder="{{'COMMON_EXTERNAL_ID' | translate}}"
                         maxlength="{{UiConstants.maximumVarcharLength}}"
                         [ngClass]="{ 'form-control-danger': hasFieldError(Task.ValidatedField.EXTERNAL_ID) }"
                         (ngModelChange)="removeFieldError(Task.ValidatedField.EXTERNAL_ID)"
                         [ngModelOptions]="{standalone: true}"
                         [(ngModel)]="model.externalId">
                  <div class="form-control-feedback" *ngIf="hasFieldError(Task.ValidatedField.EXTERNAL_ID)">
                    {{getFieldErrorText(Task.ValidatedField.EXTERNAL_ID)}}
                  </div>
                </div>
              </div>

              <div class="form-group row"
                   [ngClass]="{ 'has-danger': hasLocalFieldError('externalIdTagInput') || hasFieldError(Task.ValidatedField.TEMPLATE)}">
                <label class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_EXTERNAL_ID_TEMPLATE' |
                  translate}}</label>
                <div class="col-md-8">
                  <app-material-tag-input
                    formControlName="externalIdTagInput"
                    [(ngModel)]="model.taskRecordExternalIdSequence"
                    (modelChange)="removeFieldError(Task.ValidatedField.TEMPLATE)"
                    [placeholderStringKey]="'COMMON_EXTERNAL_ID_TEMPLATE'"
                    [autocompleteItems]="TaskRecordExternalIdSequence.availableSequenceBlocks"
                    [separatorCharacter]="TaskRecordExternalIdSequence.sequenceBlockSeparator"
                    [generatedStringExampleMap]="TaskRecordExternalIdSequence.sequenceBlockExampleMap"
                    [separatorKeyCodes]="TaskRecordExternalIdSequence.separatorKeysCodes"
                    [shouldGenerateExample]="true"
                    [form]="f">
                  </app-material-tag-input>
                  <div class="form-control-feedback" *ngIf="hasFieldError(Task.ValidatedField.TEMPLATE)">
                    {{getFieldErrorText(Task.ValidatedField.TEMPLATE)}}
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'TASK_EXTERNAL_ID_TEMPLATE_WITHIN_YEAR'
                  | translate}}
                  <i class="icomoon icomoon-info info-icon"
                     popover="{{'TASK_INFO_EXTERNAL_ID_TEMPLATE_WITHIN_YEAR' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-8">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.withinYear">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>

              <div class="form-group row"
                   [ngClass]="{ 'has-danger': hasLocalFieldError('taskRecordNameTemplate')}">
                <div class="col-md-4">
                  <label class="col-form-label mw-100 form-control-label">
                    {{'TASK_RECORD_CREATE_NAME_TEMPLATE'|translate}}
                  </label>
                </div>
                <div class="col-md-8">
                  <div class="mw-100">
                    <app-material-tag-input
                      formControlName="taskRecordNameTemplate"
                      [(ngModel)]="model.taskRecordNameTemplate"
                      [placeholderStringKey]="'TASK_RECORD_CREATE_NAME_TEMPLATE'"
                      [autocompleteItems]="TaskRecordQuickCreateNameTemplate.availableTemplateBlocks"
                      [separatorCharacter]="TaskRecordQuickCreateNameTemplate.templateBlockSeparator"
                      [generatedStringExampleMap]="TaskRecordQuickCreateNameTemplate.templateBlockExampleMap"
                      [separatorKeyCodes]="TaskRecordQuickCreateNameTemplate.separatorKeysCodes"
                      [shouldGenerateExample]="true"
                      [form]="f">
                    </app-material-tag-input>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-md-4 col-form-label form-control-label inverted-label-align required-field-label">
                  {{'USER_LABEL_COLOR'|translate}}
                </label>
                <div class="col-md-8">
                  <div class="mw-100">
                    <palette-color-picker
                      [(color)]="model.color">
                    </palette-color-picker>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'TASK_USE_DEFAULT_MANAGED_FIELDS' | translate}}
                </label>
                <div class="col-md-8">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.useDefaultManagedFields">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>

              <div class="form-group row" *ngIf="!model.useDefaultManagedFields">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'TASK_MANAGED_FIELDS' | translate}}
                </label>
                <div class="col-md-8">
                  <angular2-multiselect [data]="managableFields" [settings]="managedFieldsDropdownSettings"
                                        class="form-control"
                                        [(ngModel)]="model.managedFields"
                                        [ngModelOptions]="{standalone: true}"
                                        (ngModelChange)="onManagedFieldsChanged()">
                  </angular2-multiselect>
                </div>
              </div>

              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'TASK_REQUIRED_FIELDS' | translate}}
                </label>
                <div class="col-md-8">
                  <angular2-multiselect [data]="requirableFields" [settings]="managedFieldsDropdownSettings"
                                        class="form-control"
                                        [(ngModel)]="model.requiredFields"
                                        [ngModelOptions]="{standalone: true}">
                  </angular2-multiselect>
                </div>
              </div>

              <!--TASK POC REQUIREMENT-->
              <div class="form-group row"
                   [ngClass]="{ 'has-danger': hasLocalFieldError('pocRequirement') }">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title required-field-label">{{'FORM_CREATE_POC_REQUIREMENT' |
                  translate}}
                </label>
                <div class="col-md-8">
                  <angular2-multiselect [data]="pocRequirements" [settings]="pocRequirementDropdownSettings"
                                        class="form-control"
                                        formControlName="pocRequirement"
                                        [(ngModel)]="model._pocRequirement">
                  </angular2-multiselect>
                  <div class="form-control-feedback" *ngIf="hasLocalFieldError('pocRequirement')">
                    <span
                      *ngIf="hasLocalFieldError('pocRequirement', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_DESCRIPTION' |
                  translate}}</label>
                <div class="col-md-8">
                  <input type="text" class="form-control" placeholder="{{'COMMON_DESCRIPTION' | translate}}"
                         maxlength="{{UiConstants.maximumVarcharLength}}"
                         [ngModelOptions]="{standalone: true}"
                         [(ngModel)]="model.description">
                </div>
              </div>

              <div class="form-group row" [ngClass]="{ 'has-danger': hasLocalFieldError('validationType') }">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title required-field-label">{{'TASK_VALIDATION_TYPE' |
                  translate}}</label>
                <div class="col-md-8">
                  <angular2-multiselect [data]="validationTypeItems" [settings]="defaultStateDropdownSettings"
                                        class="form-control"
                                        [ngClass]="{ 'form-control-danger': hasLocalFieldError('validationType') }"
                                        formControlName="validationType"
                                        [(ngModel)]="model.validationType">
                  </angular2-multiselect>
                  <div class="form-control-feedback" *ngIf="hasLocalFieldError('validationType')">
                    <span
                      *ngIf="hasLocalFieldError('validationType', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
                  </div>
                </div>
              </div>

              <app-icon-selector
                [(selectedIcon)]="model.icon"></app-icon-selector>

              <!--TASK DEFAULT STATE FORCE NEW-->
              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'FORM_CREATE_DEFAULT_STATE_FORCE_NEW' |
                  translate}}
                  <i class="icomoon icomoon-info info-icon"
                     popover="{{'TASK_INFO_DEFAULT_STATE_FORCE_NEW' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-8">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.defaultStateForceNew"
                           (click)="defaultStateForceNewChanged()">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>

              <!--TASK DEFAULT STATE-->
              <div *ngIf="!model.defaultStateForceNew">
                <div class="form-group row"
                     [ngClass]="{ 'has-danger': hasLocalFieldError('defaultState') }">
                  <label
                    class="col-form-label form-control-label col-md-4 detail-title required-field-label">{{'FORM_CREATE_DEFAULT_STATE' |
                    translate}}
                    <i class="icomoon icomoon-info info-icon" popover="{{'TASK_INFO_DEFAULT_STATE' | translate}}"
                       triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                  </label>
                  <div class="col-md-8">
                    <angular2-multiselect [data]="defaultStateOptions" [settings]="defaultStateDropdownSettings"
                                          class="form-control"
                                          [ngClass]="{ 'form-control-danger': hasLocalFieldError('defaultState') }"
                                          formControlName="defaultState"
                                          [(ngModel)]="model.defaultState">
                    </angular2-multiselect>
                    <div class="form-control-feedback" *ngIf="hasLocalFieldError('defaultState')">
                    <span
                      *ngIf="hasLocalFieldError('defaultState', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <!--TASK DEFAULT DEADLINE-->
              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'FORM_CREATE_DEFAULT_DEADLINE' |
                  translate}}
                  <i class="icomoon icomoon-info info-icon" popover="{{'TASK_INFO_DEFAULT_DEADLINE' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-8">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.hasDefaultDeadline"
                           (click)="hasDefaultDeadlineChanged()">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>

              <div *ngIf="model.hasDefaultDeadline">
                <div class="form-group row">
                  <label
                    class="col-form-label form-control-label col-md-4 detail-title">{{'TASK_DEFAULT_DEADLINE_TYPE' |
                    translate}}</label>
                  <div class="col-md-8">
                    <select class="form-control" [ngModel]="model.defaultDeadlineType"
                            [ngModelOptions]="{standalone: true}"
                            (ngModelChange)="onDefaultDeadlineTypeChange($event)" required>
                      <option *ngFor="let item of defaultDeadlineTypeItems" [ngValue]="item">{{item.text}}</option>
                    </select>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <label class="col-form-label form-control-label detail-title">{{'TASK_DEFAULT_DEADLINE_OFFSET' |
                      translate}}</label>
                  </div>
                  <div class="form-group edit-form-input-group col-md-8"
                       [ngClass]="{ 'has-danger': hasLocalFieldError('offset') }">
                    <input type="text" class="form-control"
                           formControlName="offset"
                           [ngClass]="{ 'form-control-danger': hasLocalFieldError('offset') }"
                           [(ngModel)]="model.offset"
                           [textMask]="{mask: InputMask.NATURAL_FLOAT, guide: false}"
                           placeholder="{{'TASK_DEFAULT_DEADLINE_OFFSET' | translate}}">
                    <div class="form-control-feedback" *ngIf="hasLocalFieldError('offset')">
                      <span
                        *ngIf="hasLocalFieldError('offset', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
                      <span
                        *ngIf="hasLocalFieldError('offset', 'min')">{{'COMMON_VALIDATION_MESSAGE_NUMBER_NOT_POSITIVE' | translate}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <!--TASK DEFAULT DEADLINE END-->

              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'SETTINGS_TASK_RECORD_ADMIN_EDITABLE_STATES' |
                  translate}}
                  <i class="icomoon icomoon-info info-icon" popover="{{'TASK_INFO_ADMIN_EDITABLE_STATES' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-8">
                  <angular2-multiselect [data]="editableStateOptions" [settings]="editableStateDropdownSettings"
                                        class="form-control"
                                        formControlName="adminEditableStates"
                                        [(ngModel)]="model.adminEditableStates">
                  </angular2-multiselect>
                </div>
              </div>

              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'FORM_CREATE_PARALLEL_WORK_REJECTED' |
                  translate}}
                  <i class="icomoon icomoon-info info-icon" popover="{{'TASK_INFO_PARALLEL_WORK_REJECTED' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-8">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.parallelWorkRejected">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-form-label form-control-label col-md-4 detail-title">
                  {{'TASK_IN_PROGRESS_SIGNATURE_ENABLED' | translate}}
                </label>
                <div class="col-md-8">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.inProgressSignatureEnabled">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>


              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'FORM_CREATE_CUSTOMER_SIGNATURE' |
                  translate}}
                  <i class="icomoon icomoon-info info-icon" popover="{{'TASK_INFO_CUSTOMER_SIGNATURE' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-8">
                  <angular2-multiselect [data]="signatureValidationTypeItems"
                                        [settings]="signatureValidationDropdownSettings"
                                        class="form-control"
                                        [(ngModel)]="model._customerSignature"
                                        formControlName="customerSignature">
                  </angular2-multiselect>
                  <div class="form-control-feedback" *ngIf="hasLocalFieldError('customerSignature')">
                    <span
                      *ngIf="hasLocalFieldError('customerSignature', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-form-label form-control-label col-md-4 detail-title">{{'FORM_CREATE_USER_SIGNATURE' |
                  translate}}
                  <i class="icomoon icomoon-info info-icon" popover="{{'TASK_INFO_USER_SIGNATURE' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-8">
                  <angular2-multiselect [data]="signatureValidationTypeItems"
                                        [settings]="signatureValidationDropdownSettings"
                                        class="form-control"
                                        [(ngModel)]="model._userSignature"
                                        formControlName="userSignature">
                  </angular2-multiselect>
                  <div class="form-control-feedback" *ngIf="hasLocalFieldError('userSignature')">
                    <span
                      *ngIf="hasLocalFieldError('userSignature', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-form-label form-control-label col-md-4 detail-title">{{'FORM_CREATE_ATTACHMENT' |
                  translate}}
                  <i class="icomoon icomoon-info info-icon" popover="{{'TASK_INFO_ATTACHMENT' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-8">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.customerAttachmentEnabled">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>

              <div class="form-group row">

                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'FORM_CREATE_DEFAULT_LINKED_SURVEYS'
                  | translate}}
                  <i class="icomoon icomoon-info info-icon" popover="{{'TASK_INFO_DEFAULT_LINKED_SURVEYS' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-8">
                  <angular2-multiselect [data]="surveys" [settings]="searchDropdownSettings"
                                        class="form-control"
                                        [(ngModel)]="model.defaultLinkedSurveys"
                                        [ngModelOptions]="{standalone: true}"
                                        (onRemoteSearch)="searchSurveys($event)">
                  </angular2-multiselect>
                </div>
              </div>

              <div class="form-group row">

                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'FORM_CREATE_CREATOR_USER_GROUPS'
                  | translate}}
                  <i class="icomoon icomoon-info info-icon" popover="{{'TASK_INFO_CREATOR_USER_GROUPS' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right" placement="right"></i>
                </label>
                <div class="col-md-8">
                  <angular2-multiselect [data]="userGroups" [settings]="searchDropdownSettings"
                                        class="form-control"
                                        [(ngModel)]="model.creatorUserGroups"
                                        [ngModelOptions]="{standalone: true}"
                                        (onRemoteSearch)="searchUserGroups($event)">
                  </angular2-multiselect>
                </div>
              </div>

              <div class="form-group row">

                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'FORM_CREATE_ENABLED_ASSIGNEE_USER_GROUPS'
                  | translate}}
                  <i class="icomoon icomoon-info info-icon"
                     popover="{{'TASK_INFO_ENABLED_ASSIGNEE_USER_GROUPS' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right" placement="right"></i>
                </label>
                <div class="col-md-8">
                  <angular2-multiselect [data]="userGroups" [settings]="searchDropdownSettings"
                                        class="form-control"
                                        [(ngModel)]="model.enabledUserGroups"
                                        [ngModelOptions]="{standalone: true}"
                                        (onRemoteSearch)="searchUserGroups($event)">
                  </angular2-multiselect>
                </div>
              </div>

              <!--TASK DEFAULT ASSIGNEE-->
              <div class="form-group row"
                   [ngClass]="{ 'has-danger': hasLocalFieldError('webCreateDefaultAssigneeType') }">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title required-field-label">{{'FORM_CREATE_WEB_CREATE_DEFAULT_ASSGINEE' |
                  translate}}
                </label>
                <div class="col-md-8">
                  <angular2-multiselect [data]="defaultAssigneeTypes" [settings]="pocRequirementDropdownSettings"
                                        class="form-control"
                                        formControlName="webCreateDefaultAssigneeType"
                                        [(ngModel)]="model._webCreateDefaultAssigneeType">
                  </angular2-multiselect>
                  <div class="form-control-feedback" *ngIf="hasLocalFieldError('webCreateDefaultAssigneeType')">
                    <span
                      *ngIf="hasLocalFieldError('webCreateDefaultAssigneeType', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'FORM_CREATE_SET_CUSTOMER_OWNER_USER_AFTER_FINISH'
                  | translate}}
                  <i class="icomoon icomoon-info info-icon"
                     popover="{{'TASK_INFO_SET_CUSTOMER_OWNER_USER_AFTER_FINISH' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-8">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.setCustomerOwnerUserAfterFinish">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>

              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'FORM_CREATE_SET_CUSTOMER_OWNER_USER_GROUP_AFTER_FINISH'
                  | translate}}
                  <i class="icomoon icomoon-info info-icon"
                     popover="{{'TASK_INFO_SET_CUSTOMER_OWNER_USER_GROUP_AFTER_FINISH' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-8">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.setCustomerOwnerUserGroupAfterFinish">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>


            </div>
          </div>

        </div>

        <div class="container-vertical-padding-half">

          <div class="card">

            <div class="card-header">
              {{'TASK_SUBMITTED_FILTERS'|translate}}
            </div>

            <div class="card-body">

              <ng-container *ngFor="let submittedFilter of model.submittedFilters; let i = index">
                <div class="form-group row"
                     [ngClass]="{ 'has-danger': hasLocalFieldError('submittedFilterUserGroup' + i) || hasLocalFieldError('submittedFilterDays' + i) }">
                  <div class="col-md-6">
                    <angular2-multiselect [data]="userGroups" [settings]="submittedFilterDropdownSettings"
                                          class="form-control"
                                          [(ngModel)]="submittedFilter.userGroup"
                                          formControlName="submittedFilterUserGroup{{i}}"
                                          (onRemoteSearch)="searchUserGroups($event)">
                    </angular2-multiselect>
                    <div class="form-control-feedback" *ngIf="hasLocalFieldError('submittedFilterUserGroup' + i)">
                      {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                    </div>
                  </div>
                  <div class="col-md-6 d-flex align-items-center">
                    <div>
                      <input type="text" class="form-control"
                             placeholder="{{'TASK_SUBMITTED_FILTER_DAYS' | translate}}"
                             [(ngModel)]="submittedFilter.days"
                             [ngClass]="{ 'form-control-danger': hasLocalFieldError('submittedFilterDays' + i) }"
                             [textMask]="{mask: InputMask.NATURAL_INTEGER, guide: false}"
                             formControlName="submittedFilterDays{{i}}">
                      <div class="form-control-feedback" *ngIf="hasLocalFieldError('submittedFilterDays' + i)">
                        {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                      </div>
                    </div>
                    <div class="cursor-pointer ml-1 font-xl" (click)="deleteSubmittedFilter(i)"
                         title="{{'COMMON_BUTTON_DELETE' | translate}}">
                      <i class="icomoon icomoon-trash">
                      </i>
                    </div>
                  </div>
                </div>
              </ng-container>

              <div class="d-flex justify-content-end">
                <button type="button" (click)="addSubmittedFilter()" class="btn btn-primary">
                  {{'TASK_SUBMITTED_FILTER_ADD_NEW' | translate}}
                </button>
              </div>

            </div>

          </div>

        </div>

        <div class="container-vertical-padding-half">

          <div class="card">

            <div class="card-header">
              {{'TASK_STATE_FILTERS'|translate}}
            </div>

            <div class="card-body">

              <ng-container *ngFor="let stateFilter of model.stateFilters; let i = index">
                <div class="form-group row"
                     [ngClass]="{ 'has-danger': hasLocalFieldError('stateFilterUserGroup' + i) || hasLocalFieldError('stateFilterStates' + i) }">
                  <div class="col-md-6">
                    <angular2-multiselect [data]="userGroups" [settings]="submittedFilterDropdownSettings"
                                          class="form-control"
                                          [(ngModel)]="stateFilter.userGroup"
                                          formControlName="stateFilterUserGroup{{i}}"
                                          (onRemoteSearch)="searchUserGroups($event)">
                    </angular2-multiselect>
                    <div class="form-control-feedback" *ngIf="hasLocalFieldError('stateFilterUserGroup' + i)">
                      {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                    </div>
                  </div>
                  <div class="col-md-6 d-flex align-items-center">
                    <div class="w-100">
                      <angular2-multiselect [data]="states" [settings]="stateFilterDropdownSettings"
                                            class="form-control"
                                            [(ngModel)]="stateFilter.states"
                                            formControlName="stateFilterStates{{i}}">
                      </angular2-multiselect>
                      <div class="form-control-feedback" *ngIf="hasLocalFieldError('stateFilterStates' + i)">
                        {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                      </div>
                    </div>
                    <div class="cursor-pointer ml-1 font-xl" (click)="deleteStateFilter(i)"
                         title="{{'COMMON_BUTTON_DELETE' | translate}}">
                      <i class="icomoon icomoon-trash">
                      </i>
                    </div>
                  </div>
                </div>
              </ng-container>

              <div class="d-flex justify-content-end">
                <button type="button" (click)="addStateFilter()" class="btn btn-primary">
                  {{'TASK_SUBMITTED_FILTER_ADD_NEW' | translate}}
                </button>
              </div>

            </div>

          </div>

        </div>

        <!--TASK SMS RATING-->
        <div class="container-vertical-padding-half"
             *ngIf="configService.getConfiguration().feature_flags.sms_futar_enabled">

          <div class="card">

            <div class="card-header">
              {{'TASK_SMS_RATING'|translate}}
            </div>

            <div class="card-body">

              <div class="form-group row">
                <label class="col-form-label form-control-label col-md-4 detail-title">
                  {{'TASK_SMS_RATING_ENABLED' | translate}}
                </label>
                <div class="col-md-8">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.smsRatingEnabled">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>

              <div *ngIf="model.smsRatingEnabled">
                <div class="form-group row">
                  <label class="col-form-label form-control-label col-md-4 detail-title">
                    {{'TASK_SMS_RATING_DELIVERY_SCHEDULE_MIN' | translate}}
                  </label>
                  <div class="form-group edit-form-input-group col-md-8"
                       [ngClass]="{ 'has-danger': hasLocalFieldError('smsRatingDeliveryScheduleMin') }">
                    <input type="text" class="form-control"
                           formControlName="smsRatingDeliveryScheduleMin"
                           [ngClass]="{ 'form-control-danger': hasLocalFieldError('smsRatingDeliveryScheduleMin') }"
                           [(ngModel)]="model.smsRatingDeliveryScheduleMin"
                           [textMask]="{mask: InputMask.NATURAL_INTEGER, guide: false}"
                           placeholder="{{'TASK_SMS_RATING_DELIVERY_SCHEDULE_MIN' | translate}}">
                    <div class="form-control-feedback" *ngIf="hasLocalFieldError('smsRatingDeliveryScheduleMin')">
                      {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-form-label form-control-label col-md-4 detail-title">
                    {{'TASK_SMS_RATING_TEMPLATE' | translate}}
                  </label>
                  <div class="col-md-8"
                       [ngClass]="{ 'has-danger': hasLocalFieldError('smsRatingTemplate') }">
                    <angular2-multiselect [data]="messageTemplates"
                                          [settings]="invoiceDropdownSettings"
                                          class="form-control"
                                          formControlName="smsRatingTemplate"
                                          (onRemoteSearch)="loadMessageTemplates($event.target.value)"
                                          [(ngModel)]="model.smsRatingTemplate">
                    </angular2-multiselect>
                    <div class="form-control-feedback" *ngIf="hasLocalFieldError('smsRatingTemplate')">
                      {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>

        </div>
        <!--TASK SMS RATING END-->

      </div>

      <div class="col-xs-12 col-sm-12 col-md-6 card-custom-padding">

        <div class="container-vertical-padding-half"
             *ngIf="configService.getConfiguration().feature_flags.helpdesk_enabled">

          <div class="card">

            <div class="card-header">
              {{'COMMON_HELPDESK'|translate}}
            </div>

            <div class="card-body">

              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'FORM_CREATE_HELPDESK_ENABLED'
                  | translate}}
                  <i class="icomoon icomoon-info info-icon" popover="{{'TASK_INFO_HELPDESK' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-8">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.helpdeskEnabled">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>

              <div class="form-group row" *ngIf="model.helpdeskEnabled">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'TASK_HELPDESK_MANAGED_FIELDS' | translate}}
                </label>
                <div class="col-md-8">
                  <angular2-multiselect [data]="managableFields" [settings]="managedFieldsDropdownSettings"
                                        class="form-control"
                                        [(ngModel)]="model.helpdeskManagedFields"
                                        [ngModelOptions]="{standalone: true}"
                                        (ngModelChange)="onHelpdeskManagedFieldsChanged()">
                  </angular2-multiselect>
                </div>
              </div>

              <div class="form-group row" *ngIf="model.helpdeskEnabled">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'TASK_HELPDESK_REQUIRED_FIELDS' | translate}}
                </label>
                <div class="col-md-8">
                  <angular2-multiselect [data]="helpdeskRequirableFields" [settings]="managedFieldsDropdownSettings"
                                        class="form-control"
                                        [(ngModel)]="model.helpdeskRequiredFields"
                                        [ngModelOptions]="{standalone: true}">
                  </angular2-multiselect>
                </div>
              </div>

            </div>

          </div>

        </div>

        <div class="container-vertical-padding-half">

          <div class="card">

            <div class="card-header">
              {{'FORM_CREATE_TIMETRACKING_ENABLED'|translate}}
            </div>

            <div class="card-body">

              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title"
                  [class.required-field-label]="model.taskTimeTrackingType !== TaskTimeTrackingType.NONE">{{'FORM_CREATE_DEFAULT_ESTIMATED_TIME'
                  | translate}}
                </label>
                <div class="form-group col-md-8"
                     [ngClass]="{ 'has-danger': hasLocalFieldError('defaultEstimatedTime') }">
                  <input type="text" class="form-control"
                         formControlName="defaultEstimatedTime"
                         [ngClass]="{ 'form-control-danger': hasLocalFieldError('defaultEstimatedTime') }"
                         [(ngModel)]="model.defaultEstimatedTimeInMinutes"
                         [textMask]="{mask: InputMask.NATURAL_FLOAT, guide: false}"
                         placeholder="{{'FORM_CREATE_DEFAULT_ESTIMATED_TIME' | translate}}">
                  <div class="form-control-feedback" *ngIf="hasLocalFieldError('defaultEstimatedTime')">
                    <span
                      *ngIf="hasLocalFieldError('defaultEstimatedTime', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
                    <span
                      *ngIf="hasLocalFieldError('defaultEstimatedTime', 'min')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'FORM_CREATE_TIMETRACKING_ENABLED'
                  | translate}}
                  <i class="icomoon icomoon-info info-icon" popover="{{'TASK_INFO_TIMETRACKING' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-8">
                  <mat-button-toggle-group
                    [(ngModel)]="model.taskTimeTrackingType"
                    (ngModelChange)="timeTrackingEnabledChanged()"
                    [ngModelOptions]="{standalone: true}">
                    <mat-button-toggle *ngFor="let type of taskTimeTrackingTypes" [value]="type">
                      {{'FORM_CREATE_TIME_TRACKING_TYPE_' + type.toString() | translate}}
                    </mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="form-group row" *ngIf="model.taskTimeTrackingType === TaskTimeTrackingType.AUTOMATIC">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'FORM_CREATE_COLLABORATOR_TIMETRACKING_ENABLED'
                  | translate}}
                  <i class="icomoon icomoon-info info-icon"
                     popover="{{'TASK_INFO_COLLABORATOR_TIMETRACKING' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-8">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.collaboratorTimeTrackingEnabled">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>

              <div class="form-group row" *ngIf="model.taskTimeTrackingType === TaskTimeTrackingType.MANUAL">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'FORM_CREATE_MANUAL_TIMETRACKING_REQUIRED'
                  | translate}}
                </label>
                <div class="col-md-8">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.manualTimeTrackingRequired">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>

              <div class="form-group row" *ngIf="model.taskTimeTrackingType === TaskTimeTrackingType.MANUAL">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'FORM_CREATE_MANUAL_TIMETRACKING_TYPE'
                  | translate}}
                  <i class="icomoon icomoon-info info-icon" popover="{{'TASK_INFO_MANUAL_TIMETRACKING_TYPE' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-8">
                  <mat-button-toggle-group
                    [(ngModel)]="model.manualTimeTrackingType"
                    [ngModelOptions]="{standalone: true}">
                    <mat-button-toggle *ngFor="let type of manualTimeTrackingTypes" [value]="type">
                      {{'FORM_CREATE_MANUAL_TIME_TRACKING_TYPE_' + type.toString() | translate}}
                    </mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

            </div>

          </div>

        </div>

        <div class="container-vertical-padding-half">

          <div class="card">

            <div class="card-header">
              {{'TASK_GEOFENCING'|translate}}
            </div>

            <div class="card-body">

              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'TASK_GEOFENCING'
                  | translate}}
                  <i class="icomoon icomoon-info info-icon" popover="{{'TASK_GEOFENCING_HINT' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-8">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.geofencingEnabled"
                           (click)="geofencingEnabledChanged()">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>

              <div class="row" *ngIf="model.geofencingEnabled">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'TASK_GEOFENCING_RADIUS'
                  | translate}}
                </label>
                <div class="form-group col-md-8"
                     [ngClass]="{ 'has-danger': hasLocalFieldError('geofencingRadiusInMeters') }">
                  <input type="text" class="form-control"
                         formControlName="geofencingRadiusInMeters"
                         [ngClass]="{ 'form-control-danger': hasLocalFieldError('geofencingRadiusInMeters') }"
                         [(ngModel)]="model.geofencingRadiusInMeters"
                         [textMask]="{mask: InputMask.NATURAL_FLOAT, guide: false}"
                         placeholder="{{'TASK_GEOFENCING_RADIUS' | translate}} *">
                  <div class="form-control-feedback" *ngIf="hasLocalFieldError('geofencingRadiusInMeters')">
                    <span
                      *ngIf="hasLocalFieldError('geofencingRadiusInMeters', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
                    <span
                      *ngIf="hasLocalFieldError('geofencingRadiusInMeters', 'min')">{{'TASK_GEOFENCING_RADIUS_MIN' | translate}}
                    </span>
                    <span
                      *ngIf="hasLocalFieldError('geofencingRadiusInMeters', 'max')">{{'TASK_GEOFENCING_RADIUS_MAX' | translate}}
                    </span>
                  </div>
                </div>
              </div>

            </div>

          </div>

        </div>

        <div class="container-vertical-padding-half">

          <div class="card">

            <div class="card-header">
              {{'FORM_CREATE_INVOICE_SETTINGS'|translate}}
            </div>

            <div class="card-body">

              <div class="row form-group">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'FORM_CREATE_INVOICE_GENERATE'
                  | translate}}
                  <i class="icomoon icomoon-info info-icon" popover="{{'TASK_INFO_INVOICE_GENERATE' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-8">
                  <mat-button-toggle-group
                    [(ngModel)]="model.taskInvoiceGenerationType"
                    (ngModelChange)="onInvoiceGenerationTypeChanged()"
                    [ngModelOptions]="{standalone: true}">
                    <mat-button-toggle *ngFor="let type of taskInvoiceGenerationTypes" [value]="type">
                      {{'FORM_CREATE_INVOICE_GENERATION_TYPE_' + type.toString() | translate}}
                    </mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div *ngIf="model.taskInvoiceGenerationType === TaskInvoiceGenerationType.AUTOMATIC">
                <div class="form-group row"
                     [ngClass]="{ 'has-danger':  hasLocalFieldError('validInvoicePaymentTypes') }">
                  <label
                    class="col-form-label form-control-label col-md-4 detail-title required-field-label">
                    {{'FORM_CREATE_VALID_INVOICE_PAYMENT_TYPES' | translate}}
                    <i class="icomoon icomoon-info info-icon"
                       popover="{{'TASK_INFO_VALID_INVOICE_PAYMENT_TYPES' | translate}}"
                       triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                  </label>
                  <div class="col-md-8">
                    <angular2-multiselect [data]="model.paymentTypes" [settings]="paymentDropdownSettings"
                                          class="form-control"
                                          [(ngModel)]="model._validInvoicePaymentTypes"
                                          (onDeSelect)="model.refreshGeneratingPaymentTypes()"
                                          (onDeSelectAll)="model.refreshGeneratingPaymentTypes()"
                                          [ngClass]="{ 'form-control-danger': hasLocalFieldError('validInvoicePaymentTypes') }"
                                          formControlName="validInvoicePaymentTypes">
                    </angular2-multiselect>
                    <div class="form-control-feedback" *ngIf="hasLocalFieldError('validInvoicePaymentTypes')">
                      <span
                        *ngIf="hasLocalFieldError('validInvoicePaymentTypes', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
                    </div>
                  </div>
                </div>
                <div class="form-group row"
                     [ngClass]="{ 'has-danger':  hasLocalFieldError('generatingInvoicePaymentTypes') }">
                  <label
                    class="col-form-label form-control-label col-md-4 detail-title required-field-label">
                    {{'FORM_CREATE_GENERATING_INVOICE_PAYMENT_TYPES' | translate}}
                    <i class="icomoon icomoon-info info-icon"
                       popover="{{'TASK_INFO_GENERATING_INVOICE_PAYMENT_TYPES' | translate}}"
                       triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                  </label>
                  <div class="col-md-8">
                    <angular2-multiselect [data]="model._validInvoicePaymentTypes" [settings]="paymentDropdownSettings"
                                          class="form-control"
                                          [(ngModel)]="model._generatingInvoicePaymentTypes"
                                          [ngClass]="{ 'form-control-danger': hasLocalFieldError('generatingInvoicePaymentTypes') }"
                                          formControlName="generatingInvoicePaymentTypes">
                    </angular2-multiselect>
                    <div class="form-control-feedback" *ngIf="hasLocalFieldError('generatingInvoicePaymentTypes')">
                      <span
                        *ngIf="hasLocalFieldError('generatingInvoicePaymentTypes', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
                    </div>
                  </div>
                </div>
                <div class="form-group row"
                     [ngClass]="{ 'has-danger':  hasLocalFieldError('invoiceSettingsInput') }">
                  <label
                    class="col-form-label form-control-label col-md-4 detail-title">{{'FORM_CREATE_INVOICE_SETTINGS'
                    | translate}}
                  </label>
                  <div class="col-md-8">
                    <angular2-multiselect [data]="invoiceSettings" [settings]="invoiceDropdownSettings"
                                          class="form-control"
                                          [(ngModel)]="model.invoiceSettings"
                                          [ngClass]="{ 'form-control-danger': hasLocalFieldError('invoiceSettingsInput') }"
                                          (onRemoteSearch)="loadInvoiceSettings($event.target.value)"
                                          (ngModelChange)="onInvoiceSettingsChanged()"
                                          formControlName="invoiceSettingsInput">
                    </angular2-multiselect>
                    <div class="form-control-feedback" *ngIf="hasLocalFieldError('invoiceSettingsInput')">
                      <span
                        *ngIf="hasLocalFieldError('invoiceSettingsInput', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
                    </div>
                  </div>
                </div>
                <div class="form-group row"
                     *ngIf="model.invoiceSettings.length > 0 && model.invoiceSettings[0].integrationType === InvoiceSettings.InvoiceIntegrationType.APPWORKS"
                     [ngClass]="{ 'has-danger':  hasLocalFieldError('invoiceBookInput') }">
                  <label
                    class="col-form-label form-control-label col-md-4 detail-title">{{'FORM_CREATE_INVOICE_BOOK'
                    | translate}}
                  </label>
                  <div class="col-md-8">
                    <angular2-multiselect [data]="invoiceBooks" [settings]="invoiceDropdownSettings"
                                          class="form-control"
                                          [(ngModel)]="model.invoiceBook"
                                          [ngClass]="{ 'form-control-danger': hasLocalFieldError('invoiceBookInput') }"
                                          (onRemoteSearch)="loadInvoiceBooks($event.target.value)"
                                          formControlName="invoiceBookInput">
                    </angular2-multiselect>
                    <div class="form-control-feedback" *ngIf="hasLocalFieldError('invoiceBookInput')">
                      <span
                        *ngIf="hasLocalFieldError('invoiceBookInput', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
                    </div>
                  </div>
                </div>
                <div class="form-group row"
                     *ngIf="model.taskInvoiceGenerationType === TaskInvoiceGenerationType.AUTOMATIC"
                     [ngClass]="{ 'has-danger':  hasLocalFieldError('invoiceTagInput') }">
                  <label
                    class="col-form-label form-control-label col-md-4 detail-title">{{'FORM_CREATE_INVOICE_TAG'
                    | translate}}
                  </label>
                  <div class="col-md-8">
                    <angular2-multiselect [data]="invoiceTags" [settings]="invoiceDropdownSettings"
                                          class="form-control"
                                          [(ngModel)]="model.invoiceTag"
                                          [ngClass]="{ 'form-control-danger': hasLocalFieldError('invoiceTagInput') }"
                                          (onRemoteSearch)="loadInvoiceTags($event.target.value)"
                                          formControlName="invoiceTagInput">
                    </angular2-multiselect>
                  </div>
                </div>

                <div class="form-group row"
                     [ngClass]="{ 'has-danger':  hasLocalFieldError('printInvoiceCount') }">
                  <label
                    class="col-form-label form-control-label col-md-4 detail-title required-field-label">
                    {{'FORM_CREATE_PRINT_INVOICE_COUNT' | translate}}
                    <i class="icomoon icomoon-info info-icon" popover="{{'TASK_INFO_PRINT_INVOICE_COUNT' | translate}}"
                       triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                  </label>
                  <div class="col-md-8">
                    <input type="text" class="form-control"
                           [ngClass]="{ 'form-control-danger': hasLocalFieldError('printInvoiceCount')}"
                           [(ngModel)]="model.printInvoiceCount" required maxlength="1" max="5"
                           [textMask]="{mask: InputMask.NATURAL_INTEGER, guide: false}"
                           formControlName="printInvoiceCount">
                    <div class="form-control-feedback" *ngIf="hasLocalFieldError('printInvoiceCount')">
                      <span
                        *ngIf="hasLocalFieldError('printInvoiceCount', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
                      <span
                        *ngIf="hasLocalFieldError('printInvoiceCount', 'max')">{{'COMMON_VALIDATION_MESSAGE_MAX' | translate: {maxValue: 5} }}</span>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>

        </div>

        <div class="container-vertical-padding-half">

          <div class="card">

            <div class="card-header">
              {{'FORM_CREATE_PICTURE_UPLOAD_SETTINGS'|translate}}
            </div>

            <div class="card-body">

              <div class="form-group row">
                <label class="col-form-label form-control-label col-md-4 detail-title">{{'FORM_CREATE_PICTURE_UPLOAD' |
                  translate}}
                  <i class="icomoon icomoon-info info-icon" popover="{{'TASK_INFO_PICTURE_UPLOAD' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-8">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.pictureUpload"
                           (click)="pictureUploadChanged()">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>

              <div *ngIf="model.pictureUpload">
                <div class="row">
                  <div class="col-md-4"></div>
                  <div class="form-group edit-form-input-group col-md-8"
                       [ngClass]="{ 'has-danger': hasFieldError(Task.ValidatedField.MIN_NUM_OF_PICS)}">
                    <input type="text" class="form-control"
                           maxlength="10"
                           [ngModelOptions]="{standalone: true}"
                           [ngClass]="{ 'form-control-danger': hasFieldError(Task.ValidatedField.MIN_NUM_OF_PICS)}"
                           [(ngModel)]="model.minNumOfPics"
                           [textMask]="{mask: InputMask.NATURAL_FLOAT, guide: false}"
                           (ngModelChange)="removeFieldError(Task.ValidatedField.MIN_NUM_OF_PICS)"
                           placeholder="{{'FORM_CREATE_MIN_PICTURE_NUM' | translate}}">
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4"></div>
                  <div class="form-group edit-form-input-group col-md-8"
                       [ngClass]="{ 'has-danger': hasFieldError(Task.ValidatedField.MIN_NUM_OF_PICS)}">
                    <input type="text" class="form-control"
                           maxlength="10"
                           [ngModelOptions]="{standalone: true}"
                           [ngClass]="{ 'form-control-danger': hasFieldError(Task.ValidatedField.MIN_NUM_OF_PICS)}"
                           [(ngModel)]="model.maxNumOfPics"
                           [textMask]="{mask: InputMask.NATURAL_FLOAT, guide: false}"
                           (ngModelChange)="removeFieldError(Task.ValidatedField.MIN_NUM_OF_PICS)"
                           placeholder="{{'FORM_CREATE_MAX_PICTURE_NUM' | translate}}">
                    <div class="form-control-feedback" *ngIf="hasFieldError(Task.ValidatedField.MIN_NUM_OF_PICS)">
                      {{getFieldErrorText(Task.ValidatedField.MIN_NUM_OF_PICS)}}
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label
                    class="col-form-label form-control-label col-md-4 detail-title">{{'FORM_CREATE_PICTURE_SHOW_ON_FORM'
                    | translate}}</label>
                  <div class="col-md-8">
                    <label class="switch switch-text switch-info mb-0">
                      <input type="checkbox" class="switch-input"
                             [ngModelOptions]="{standalone: true}"
                             [(ngModel)]="model.showPicturesOnForm"
                             [disabled]="!model.pictureUpload">
                      <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                      <span class="switch-handle"></span>
                    </label>
                  </div>
                </div>
                <div class="form-group row">
                  <label
                    class="col-form-label form-control-label col-md-4 detail-title">{{'FORM_CREATE_PHOTO_CONFIRM_DIALOG_ENABLED' |
                    translate}}
                    <i class="icomoon icomoon-info info-icon"
                       popover="{{'TASK_INFO_PHOTO_CONFIRM_DIALOG_ENABLED' | translate}}"
                       triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                  </label>
                  <div class="col-md-8">
                    <label class="switch switch-text switch-info mb-0">
                      <input type="checkbox" class="switch-input"
                             [ngModelOptions]="{standalone: true}"
                             [(ngModel)]="model.photoConfirmDialogEnabled"
                             [disabled]="!model.pictureUpload">
                      <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                      <span class="switch-handle"></span>
                    </label>
                  </div>
                </div>

                <div class="form-group row" [ngClass]="{ 'has-danger':  hasLocalFieldError('photoEnabledInStates') }">
                  <label
                    class="col-form-label form-control-label col-md-4 detail-title" [ngClass]="{'required-field-label': model.pictureUpload}">{{'FORM_CREATE_PHOTO_ENABLED_IN_STATES' |
                    translate}}
                    <i class="icomoon icomoon-info info-icon" popover="{{'TASK_INFO_PHOTO_ENABLED_IN_STATES' | translate}}"
                       triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                  </label>
                  <div class="col-md-8">
                    <angular2-multiselect [data]="photoEnabledInStateItems"
                                          [settings]="photoEnabledInStatesDropdownSettings"
                                          [ngClass]="{ 'form-control-danger': hasLocalFieldError('photoEnabledInStates')}"
                                          class="form-control"
                                          [(ngModel)]="model.photoEnabledInStates"
                                          [disabled]="!model.pictureUpload"
                                          formControlName="photoEnabledInStates">
                    </angular2-multiselect>
                    <div class="form-control-feedback" *ngIf="hasLocalFieldError('photoEnabledInStates')">
                    <span
                      *ngIf="hasLocalFieldError('photoEnabledInStates', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
                    </div>
                  </div>
                </div>

              </div>

            </div>

          </div>

        </div>

        <div class="container-vertical-padding-half">

          <div class="card">

            <div class="card-header">
              {{'TASK_DASHBOARD_DISPLAY_SETTINGS'|translate}}
            </div>

            <div class="card-body">

              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'TASK_DASHBOARD_DISPLAY_SETTINGS_SHOW_ON_DASHBOARD'
                  | translate}}
                </label>
                <div class="col-md-8">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.showOnDashboard">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>

              <div class="form-group row"
                   [ngClass]="{ 'has-danger':  hasLocalFieldError('explicitOrderNumber') }">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">
                  {{'TASK_DASHBOARD_DISPLAY_SETTINGS_ORDER_NUMBER' | translate}}
                  <i class="icomoon icomoon-info info-icon"
                     popover="{{'TASK_DASHBOARD_DISPLAY_SETTINGS_ORDER_NUMBER_HINT' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-8">
                  <input type="text" class="form-control"
                         [ngClass]="{ 'form-control-danger': hasLocalFieldError('explicitOrderNumber')}"
                         [(ngModel)]="model.explicitOrderNumber"
                         [textMask]="{mask: InputMask.NATURAL_INTEGER, guide: false}"
                         formControlName="explicitOrderNumber">
                  <div class="form-control-feedback" *ngIf="hasLocalFieldError('explicitOrderNumber')">
                    <span
                      *ngIf="hasLocalFieldError('explicitOrderNumber', 'min')">{{ 'COMMON_VALIDATION_MESSAGE_MIN' | translate: {minValue: 1} }}</span>
                  </div>
                </div>
              </div>

            </div>

          </div>

        </div>

        <div class="container-vertical-padding-half">

          <div class="card">

            <div class="card-header">
              {{'TASK_MILEAGE_RECORD_ENABLED'|translate}}
            </div>

            <div class="card-body">

              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'TASK_MILEAGE_RECORD_ENABLED'
                  | translate}}
                  <i class="icomoon icomoon-info info-icon" popover="{{'TASK_MILEAGE_RECORD_ENABLED_INFO' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-8">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.mileageRecordEnabled"
                           (click)="mileageRecordEnabledChanged()">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>

              <div class="form-group row" *ngIf="model.mileageRecordEnabled">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'TASK_MILEAGE_RECORD_REQUIRED'
                  | translate}}
                  <i class="icomoon icomoon-info info-icon"
                     popover="{{'TASK_MILEAGE_RECORD_REQUIRED_INFO' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-8">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.mileageRecordRequired">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>

            </div>

          </div>

        </div>

      </div>

    </div>

    <input type="submit" class="btn btn-primary floating_save_button" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>

  </form>

</div>
