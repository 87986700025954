/* eslint-disable */
import { FieldValidationError } from '../../../lib/util/services';
import { SurveyRecord } from '../../../lib/survey/survey-record.service';
import { UserProfile } from '../../../lib/auth.service';
import { MultiselectOptionItem } from '../../../util/core-utils';
/* eslint-enable */

export namespace SurveyRecordBase {

  export interface View {
    loadSurveyRecord(args: Args);

    getModel(): Model;

    hasLocalFieldError(): boolean;

    onFieldValidationError(fieldErrors: FieldValidationError<SurveyRecord.ValidatedField>);
  }

  export interface Args {
    surveyRecord?: SurveyRecord.SurveyRecord;
  }

  // Not injected so valid inside the namespace.
  export class Model {

    name?: string = undefined;
    ownerUserId?: UserProfile = undefined;
    _customerRecord: MultiselectOptionItem<number>[] = [];

    get customerRecord(): MultiselectOptionItem<number> | undefined {
      return this._customerRecord.length > 0 ? this._customerRecord[0] : undefined;
    }

    set customerRecord(customer: MultiselectOptionItem<number> | undefined) {
      if (customer) {
        this._customerRecord[0] = customer;
      }
    }

    get customerRecordId() {
      return this._customerRecord.length === 0 ? undefined : this._customerRecord[0].id;
    }

    load(record: SurveyRecord.SurveyRecord) {
      this.name = record.name;
      this.ownerUserId = record.ownerUserId;
    }

  }

}
