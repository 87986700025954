import { Component, OnInit, ViewChild } from '@angular/core';
import { QueryFieldModel, UiConstants } from '../../../../util/core-utils';
import { InvoiceSettings, InvoiceSettingsService } from '../../../../lib/invoice/invoice-settings/invoice-settings.service';
import { BreadcrumbParent } from '../../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { TranslateService } from '@ngx-translate/core';
import { StateName } from '../../../../app.state-names';
import { UIRouter } from '@uirouter/angular';
import { Set } from 'immutable';
import { OrderType, QueryResult } from '../../../../lib/util/services';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { RightModel } from '../../../../app.rights';
import { RightResolver, RightService } from '../../../../lib/right.service';
import { EmptyMessage } from '../../../../lib/util/messages';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { DropdownItemType } from '../../../../shared/dropdown/dropdown-item/dropdown-item-type';
import { Invoice } from '../../../../lib/invoice/invoice/invoice.service';
import { InvoiceListModel } from '../../invoices/invoice-list/invoice-list.model';

@Component({
  selector: 'app-invoice-settings-list',
  templateUrl: './invoice-settings-list.component.html',
  styleUrls: ['./invoice-settings-list.component.scss']
})
export class InvoiceSettingsListComponent implements OnInit {
  InvoiceSettings = InvoiceSettings;
  UiConstants = UiConstants;
  DropdownItemType = DropdownItemType;

  @ViewChild('createPreSelectDialog', { static: true })
  createPreSelectDialog: ModalDirective;
  createPreselectDialogVisible: boolean = false;

  queryModel: QueryFieldModel<InvoiceSettings.OrderField> = new QueryFieldModel(InvoiceSettings.OrderField.ID, OrderType.DESC);
  list: InvoiceSettings.InvoiceSettings[] = [];
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  rightModel: RightModel = RightModel.empty();

  constructor(
    private translateService: TranslateService,
    private rightService: RightService,
    private uiRouter: UIRouter,
    private sanitizer: DomSanitizer,
    private invoiceSettingsService: InvoiceSettingsService
  ) {
  }

  ngOnInit() {
    this.translateService.get('MENU_NAVBAR_MENU_INVOICE_SETTINGS_LIST').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.loadRightModels();
    this.loadList();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  private loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();

    this.invoiceSettingsService.query({
      paging: {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      },
      orders: Set.of(order)
    })
      .subscribe(
        (stats: QueryResult<InvoiceSettings.InvoiceSettings>) => {
          this.list = stats.items.toArray();
          this.queryModel.currentPage = requestedPage;
          this.queryModel.totalNumberOfItems = stats.pagingResult.totalNumberOfItems;
          this.queryModel.currentNumberOfItems = stats.pagingResult.currentNumberOfItems;
        }
      );
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: InvoiceSettings.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  setDisabled(id: number, disabled: boolean) {
    this.invoiceSettingsService.setDisabled({
      id: id,
      disabled: disabled
    }).subscribe(
      (result: EmptyMessage) => {
        this.loadList();
      },
      (error: any) => {
        this.loadList();
      }
    );
  }

  openCreatePreselectDialog() {
    this.createPreselectDialogVisible = true;
    this.createPreSelectDialog.show();
  }

  closeCreatePreselectDialog() {
    this.createPreselectDialogVisible = false;
    this.createPreSelectDialog.hide();
  }

  hasIntegrationTypeIcon(type: InvoiceSettings.InvoiceIntegrationType): boolean {
    return InvoiceSettings.invoiceIntegrationTypes.find(t => t.type === type)!.icon !== undefined;
  }

  getIntegrationTypeIcon(type: InvoiceSettings.InvoiceIntegrationType): string | undefined {
    return InvoiceSettings.invoiceIntegrationTypes.find(t => t.type === type)!.icon;
  }

  getIntegrationTypeString(type: InvoiceSettings.InvoiceIntegrationType): string | undefined {
    return InvoiceSettings.invoiceIntegrationTypes.find(t => t.type === type)!.stringKey;
  }

  create(type: InvoiceSettings.InvoiceIntegrationType) {
    this.uiRouter.stateService.go(StateName.INVOICE_SETTINGS_CREATE, {type: type});
  }

  getSanitizedImageUrl(url: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(`url(${url})`);
  }

  getDirectionType(invoiceDirectionType: Invoice.InvoiceDirectionType) {
    return InvoiceListModel.InvoiceDirectionTypes.get(invoiceDirectionType).stringKey;
  }
}
