/* eslint-disable */
import { Observable, Observer } from 'rxjs';
import { Injectable } from '@angular/core';
import { PagingRequest, QueryResult, ResourceQueryResult, Services } from '../util/services';
import { List, Set } from 'immutable';
import { OffsetDateTime } from '../util/dates';
import { DqlQuery, Query } from '../query/field';
import { FilterField } from '../query/filterfields';
import { OrderField } from '../query/orderfields';
import { Address, AddressResource } from '../address';
import Decimal from '../../../../node_modules/decimal.js';
import { MileageRecordResource, MileageRecordResourceService } from './mileage-record-resource.service';
import { DownloadedFile } from '../util/downloaded-files';
import { EmptyMessage } from '../util/messages';
import { Models } from '../../util/model-utils';
/* eslint-enable */

export namespace MileageRecord {

  export interface Service {
    query(request: QueryRequest): Observable<QueryResult<MileageRecord>>;
    get(request: GetRequest): Observable<MileageRecord>;
    update(request: UpdateRequest): Observable<EmptyMessage>;
  }

  export interface MileageRecord {
    id: number;
    creationTime: OffsetDateTime;
    finishTime?: OffsetDateTime;
    startKilometer: Decimal;
    finishKilometer?: Decimal;
    journeyType?: string;
    note?: string;
    startCoordinates?: Address.Coordinate;
    finishCoordinates?: Address.Coordinate;
    user: User;
    taskRecord?: TaskRecord;
    vehicle: Vehicle;
  }

  export interface User {
    id: number;
    personName: string;
    userName: string;
    userGroupIds: number[];
    profilePictureHash?: string;
  }

  export interface TaskRecord {
    id: number;
    taskId: number;
    name: string;
    customerName?: string;
  }

  export interface Vehicle {
    id: number;
    licencePlate: string;
  }

  export interface QueryRequest {
    fields?: Query.FieldFunction<Fields.MileageRecord>;
    filter?: Query.FilterFunction<FilterField.MileageRecord>;
    order?: Query.OrderFunction<OrderField.MileageRecord>;
    paging?: PagingRequest;
  }

  export interface GetRequest {
    id: number;
  }

  export interface UpdateRequest {
    id: number;
    startKilometer: Decimal;
    finishKilometer: Decimal;
    journeyType?: string;
    note?: string;
    vehicleId: number;
  }

  export function toPublic(r: MileageRecordResource.MileageRecord): MileageRecord {
    return {
      id: r.id,
      creationTime: Services.toOffsetDateTime(r.creation_time),
      finishTime: Services.toOffsetDateTime(r.finish_time),
      startKilometer: new Decimal(r.start_kilometer),
      finishKilometer: r.finish_kilometer ? new Decimal(r.finish_kilometer) : undefined,
      journeyType: r.journey_type,
      note: r.note,
      startCoordinates: AddressResource.Mapper.toPublicCoordinate(r.start_coordinates),
      finishCoordinates: AddressResource.Mapper.toPublicCoordinate(r.finish_coordinates),
      user: {
        id: r.user.id,
        userName: r.user.user_name,
        personName: r.user.person_name,
        userGroupIds: r.user.user_group_ids,
        profilePictureHash: r.user.profile_picture_hash
      },
      taskRecord: r.task_record ? {
        id: r.task_record.id,
        taskId: r.task_record.task_id,
        name: r.task_record.name,
        customerName: r.task_record.customer_name
      } : undefined,
      vehicle: {
        id: r.vehicle.id,
        licencePlate: r.vehicle.licence_plate
      }
    }
  }

  export namespace Fields {

    export class MileageRecord {

      readonly id: Query.Field = new DqlQuery.Field('id');
      readonly creationTime: Query.Field = new DqlQuery.Field('creation_time');
      readonly finishTime: Query.Field = new DqlQuery.Field('finish_time');
      readonly startKilometer: Query.Field = new DqlQuery.Field('start_kilometer');
      readonly finishKilometer: Query.Field = new DqlQuery.Field('finish_kilometer');
      readonly journeyType: Query.Field = new DqlQuery.Field('journey_type');
      readonly note: Query.Field = new DqlQuery.Field('note');
      readonly startCoordinates: Query.Field = new DqlQuery.Field('start_coordinates');
      readonly finishCoordinates: Query.Field = new DqlQuery.Field('finish_coordinates');
      readonly user: Query.Field = new DqlQuery.Field('user');
      readonly vehicle: Query.Field = new DqlQuery.Field('vehicle');
      readonly vehicleId: Query.Field = new DqlQuery.Field('vehicle_id');
      readonly taskRecord: Query.Field = new DqlQuery.Field('task_record');

      get each(): Set<Query.Field> {
        return Set.of(
          this.id,
          this.creationTime,
          this.finishTime,
          this.startKilometer,
          this.finishKilometer,
          this.journeyType,
          this.note,
          this.startCoordinates,
          this.finishCoordinates,
          this.user,
          this.vehicle,
          this.taskRecord
        );
      }

    }

  }

}

@Injectable()
export class MileageRecordService implements MileageRecord.Service {

  private readonly filterField = new FilterField.MileageRecord();
  private readonly orderField = new OrderField.MileageRecord();
  private readonly fields = new MileageRecord.Fields.MileageRecord();

  constructor(private resourceService: MileageRecordResourceService) {
  }

  query(request: MileageRecord.QueryRequest): Observable<QueryResult<MileageRecord.MileageRecord>> {
    return Observable.create((observer: Observer<QueryResult<MileageRecord.MileageRecord>>) => {
      const resourceRequest = this.toResourceQueryRequest(request);
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<MileageRecordResource.MileageRecord>) => {
          observer.next({
            items: List.of(...result.items.map((item) => MileageRecord.toPublic(item))),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  get(request: MileageRecord.GetRequest): Observable<MileageRecord.MileageRecord> {
    return Observable.create((observer: Observer<MileageRecord.MileageRecord>) => {
      const resourceRequest: MileageRecordResource.GetRequest = {
        id: request.id
      };
      return this.resourceService.get(resourceRequest).subscribe(
        (result: MileageRecordResource.MileageRecord) => {
          observer.next(MileageRecord.toPublic(result));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  update(request: MileageRecord.UpdateRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: MileageRecordResource.UpdateRequest = {
        id: request.id,
        start_kilometer: Models.decimalToString(request.startKilometer),
        finish_kilometer: Models.decimalToString(request.finishKilometer),
        note: request.note,
        journey_type: request.journeyType,
        vehicle_id: request.vehicleId
      };
      return this.resourceService.update(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  exportXls(request: MileageRecord.QueryRequest): Observable<DownloadedFile> {
    const resourceRequest: MileageRecordResource.QueryRequest = this.toResourceQueryRequest(request);
    return this.resourceService.exportXls(resourceRequest);
  }

  exportMaconomy(request: MileageRecord.QueryRequest): Observable<DownloadedFile> {
    const resourceRequest: MileageRecordResource.QueryRequest = this.toResourceQueryRequest(request);
    return this.resourceService.exportMaconomy(resourceRequest);
  }

  toResourceQueryRequest(request: MileageRecord.QueryRequest): MileageRecordResource.QueryRequest {
    const filter: string | undefined = DqlQuery.toOptionalFilter(this.filterField, request.filter);
    const order: string | undefined = DqlQuery.toOptionalOrder(this.orderField, request.order);
    const fields: string | undefined = DqlQuery.toOptionalFields(this.fields, request.fields);
    return {
      filter: filter,
      order: order,
      fields: fields,
      page_number: request.paging ? request.paging.pageNumber : undefined,
      number_of_items: request.paging ? request.paging.numberOfItems : undefined
    };
  }

}
