/* eslint-disable */
import { Component, } from '@angular/core';
import {
  FormRecordFieldContext,
  FormRecordFieldValueUpdateArgs,
  FormRecordFieldView,
  FormRecordFieldViewContext,
  FormRecordFieldViewModel,
  SetTmpReadonlyArgs,
  SetTmpReadonlyResult,
} from '../../../../../util/form/form-utils';
import { Form } from '../../../../../lib/form/form.service';
import { AbstractControl, FormBuilder, FormGroup, Validators, } from '@angular/forms';
import { FormRef, ForwardingFormRef, LocalFormGroupValidationErrors, } from '../../../../../lib/util/services';
import { FormRecord } from '../../../../../lib/form/form-record.service';
import { AppValidators } from '../../../../../util/app-validators';
import { Models } from '../../../../../util/model-utils';
import { FieldActivationState, FieldActivationStateResolver, } from '../../../../../util/form/form-editors';
import { Command } from '../../../../../util/command';
import { FormRecordInactivityManager } from '../../manager/form-record-inactivity-manager';
import { LoggerFactory } from '../../../../../util/logger-factory';
import { UiConstants } from '../../../../../util/core-utils';
import { Observable } from 'rxjs';

/* eslint-enable */

@Component({
  selector: 'app-form-record-string-field',
  templateUrl: 'form-record-string-field.component.html',
  styleUrls: ['form-record-string-field.component.scss'],
})
export class FormRecordStringFieldComponent implements FormRecordFieldView {

  UiConstants = UiConstants;

  private readonly logger = LoggerFactory.createLogger('FormRecordStringFieldComponent');

  public readonly selector: Form.FieldDataTypeSelector.STRING;

  formGroup: FormGroup;

  model: Model = new Model();

  // context fields are always optional
  formRecordFieldContext?: FormRecordFieldContext;
  formRecordInactivityManager?: FormRecordInactivityManager;
  private fieldId?: number;
  private htmlForm?: FormRef;

  minLength?: number;
  maxLength?: number;


  tmpReadonly: boolean = false;

  private optionalValue: boolean = false;

  private formGroupValidationErrors: LocalFormGroupValidationErrors;

  private readonlyFormFn: () => boolean = () => true;
  private readonlyFieldFn: () => boolean = () => false;
  private hiddenFieldFn: () => boolean = () => false;
get nonEditable(): boolean {
    return FieldActivationStateResolver.isNonEditable(
      this.fieldActivationState
    );
  }

  get requiredDisabled(): boolean {
    return FieldActivationStateResolver.isRequiredDisabled(
      this.fieldActivationState
    );
  }

  get required(): boolean {
    const optional = this.optionalValue;
    const requiredDisabled = this.requiredDisabled;
    return !optional && !requiredDisabled;
  }

  private get fieldActivationState(): FieldActivationState {
    return FieldActivationStateResolver.resolveFieldActivationState({
      readonlyFormFn: () => this.readonlyFormFn(),
      readonlyFieldFn: () => this.readonlyFieldFn(),
      tmpReadonlyFieldFn: () => this.tmpReadonly,
    });
  }

  private get canApplyDefaultValue(): boolean {
    return FieldActivationStateResolver.canApplyDefaultValue({
      formRecordFieldContext: () => {
        return this.formRecordFieldContext;
      },
      fieldActivationState: () => {
        return this.fieldActivationState;
      },
    });
  }

  private get valueFormControl(): AbstractControl {
    return this.formGroup.get('value')!;
  }

  private get reqContext() {
    return this.formRecordFieldContext!;
  }

  private get reqAttrs(): Form.FieldDataTypeStringAttributes {
    return this.formRecordFieldContext!.field.dataType.stringAttributes!;
  }

  private get reqDefaultValue(): string {
    return Models.optToString(this.reqAttrs.defaultValue);
  }

  constructor(fb: FormBuilder) {
    this.formGroup = this.createFormGroup(fb);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(
      this.createForwardingHtmlForm(),
      this.formGroup,
    );
  }

  setTmpReadonly(args: SetTmpReadonlyArgs): Command<SetTmpReadonlyResult> {
    const previousTmpReadonly = this.tmpReadonly;
    const previousValue = this.model.value;
    return {
      execute: async () => {
        const inactiveValue = '';
        const defaultValue = this.reqDefaultValue;
        let changed = false;
        if (this.tmpReadonly !== args.tmpReadonly) {
          if (args.tmpReadonly) {
            changed = FieldActivationStateResolver.inactivationChangesTheValue({
              debugId: this.reqContext.field.title,
              valueIsEmpty: this.model.value === inactiveValue,
              valueEqualsDefaultValue: this.model.value === defaultValue,
              defaultValueIsEmpty: defaultValue === inactiveValue,
              canApplyDefaultValue: this.canApplyDefaultValue
            });
            this.model.value = inactiveValue;
            this.tmpReadonly = args.tmpReadonly; // last
          }
          else {
            this.tmpReadonly = args.tmpReadonly; // first
            this.applyDefaultValue();
          }
          this.valueFormControl.updateValueAndValidity();
        }
        return {
          changed: changed
        };
      },
      undo: async () => {
        this.tmpReadonly = previousTmpReadonly;
        this.model.value = previousValue;
        this.valueFormControl.updateValueAndValidity();
      }
    };
  }

  registerField(context: FormRecordFieldContext, originalModel?: any): any {
    const readonlyMode = context.readonly();
    this.logger.debug('registerField', 'readonlyMode: ' + readonlyMode);
    if (originalModel) {
      this.model = originalModel;
    }
    this.formRecordFieldContext = context;
    this.fieldId = context.field.fieldId;
    this.htmlForm = context.htmlForm;
    this.readonlyFormFn = context.readonly;
    this.hiddenFieldFn = () => Form.FormFieldValidationType.HIDDEN === context.validationType;
    this.readonlyFieldFn = () => Form.FormFieldValidationType.READONLY === context.validationType
    || Form.FormFieldValidationType.HIDDEN === context.validationType;
    const attrs = context.field.dataType.stringAttributes!;
    this.optionalValue = Form.FormFieldValidationType.REQUIRED !== context.validationType;
    this.model.title = context.field.title;
    this.model.hint = Models.optToString(context.field.hint);
    this.model.placeholder = Models.optToString(attrs.hint);
    this.model.minLength = attrs.minLength;
    this.model.maxLength = attrs.maxLength;
    this.applyDefaultValue();
    if (context.fieldRecord) {
      this.registerFieldData(context.fieldRecord);
    }
    if (attrs.multiLine !== undefined) {
      this.model.multi_line = attrs.multiLine;
    }
    this.model.inputCapitalizeMode = attrs.inputCapitalizeMode;
    return this.model;
  }

  private applyDefaultValue() {
    if (this.canApplyDefaultValue) {
      this.model.value = this.reqDefaultValue;
    }
  }

  private registerFieldData(fieldRecord: FormRecord.FieldComposed): void {
    const attrs = fieldRecord.data.stringAttributes!;
    if (fieldRecord) {
      if (attrs.value) {
        if (this.readonlyFieldFn() && this.reqContext.cloning()) {
          // Do not use read-only data for cloning.
          // String by default empty.
          // Note that readonly field is filtered from the service request.
          this.model.value = '';
        }
        else {
          this.model.value = attrs.value;
        }
      }
      else {
        this.model.value = '';
      }
    }
  }

  registerFieldViews(context: FormRecordFieldViewContext): void {
    this.formRecordInactivityManager = context.inactivityManager;
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    this.formGroup.updateValueAndValidity();
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  validateWithInterrupt(): boolean {
    return false;
  }

  shouldNotifyAfterCreation(): boolean {
    return false;
  }

  afterFormRecordCreation(formRecordId: number): Observable<any> | undefined {
    return undefined;
  }

  createModel(): FormRecordFieldViewModel {
    if (this.fieldId === undefined) {
      throw new Error('Field ID is undefined');
    }
    const attrs: FormRecord.FieldDataStringAttributes = {
      value: this.model.value.length > 0 ? this.model.value : undefined
    };
    return {
      fieldEditRequest: {
        fieldId: this.fieldId,
        data: {
          stringAttributes: attrs
        }
      }
    };
  }

  private createForwardingHtmlForm() {
    return new ForwardingFormRef({
      formFn: () => {
        return this.htmlForm;
      }
    });
  }

  private createFormGroup(fb: FormBuilder) {
    return fb.group(
      {
        value: fb.control(
          {value: this.model.value},
          [
            AppValidators.validatorChain(
              AppValidators.tempValidator({
                validator: Validators.required,
                disabled: () => {
                  return !this.required;
                }
              }),
              AppValidators.optMinLength(() => {
                return this.model.minLength;
              }),
              AppValidators.optMaxLength(() => {
                return this.model.maxLength;
              }),
            )
          ]
        )
      }
    );
  }

  onInput() {
    this.maskText();
    this.formRecordInactivityManager!.onGeneralFieldChangedByUser(this);
  }

  maskText() {
    switch (this.model.inputCapitalizeMode) {
      case Form.FormFieldInputCapitalizeMode.NONE:
        break;
      case Form.FormFieldInputCapitalizeMode.CHARACTERS:
        this.capitalizeCharacters();
        break;
      case Form.FormFieldInputCapitalizeMode.SENTENCES:
        this.capitalizeSentences();
        break;
      case Form.FormFieldInputCapitalizeMode.WORDS:
        this.capitalizeWords();
        break;
      default:
        break;
    }
  }

  capitalizeCharacters() {
    const inputText = this.model.value.toUpperCase();
    this.model.value = inputText;
  }

  capitalizeSentences() {
    let inputText = this.model.value;
    const punctuationMarks = inputText.match(/[^\.!\?]+[\.!\?]+/g);
    if (punctuationMarks) {
      inputText = '';
      punctuationMarks.forEach((newSentence) => {
        newSentence = newSentence.trim();
        newSentence = newSentence.substring(0, 1).toUpperCase() + newSentence.substring(1);
        inputText += newSentence + ' ';
      });
    }
    inputText = inputText.substring(0, 1).toUpperCase() + inputText.substring(1);
    this.model.value = inputText.trim();
  }

  capitalizeWords() {
    const splitInputText = this.model.value.toLowerCase().split(' ');
    for (let i = 0; i < splitInputText.length; i++) {
      splitInputText[i] = splitInputText[i].charAt(0).toUpperCase() + splitInputText[i].substring(1);
    }
    this.model.value = splitInputText.join(' ');
  }

  updateValue(data: FormRecordFieldValueUpdateArgs) {
  }

}

export class Model {
  title: string = '';
  hint: string = '';
  placeholder: string = '';
  value: string = '';
  minLength?: number = undefined;
  maxLength?: number = undefined;
  multi_line: boolean = false;
  inputCapitalizeMode: Form.FormFieldInputCapitalizeMode;
}
