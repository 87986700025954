import { MultiselectOptionItemWithData } from '../../../util/core-utils';
import { AndroidAppVersion } from '../../../lib/andorid-app-version/android-app-version.service';

export class AndroidAppVersionSettingsModel {
  autoUpdateEnabled: boolean = false;
  minimumVersion: MultiselectOptionItemWithData<number>[] = [];

  toRequest(): AndroidAppVersion.VersionSettingsRequest {
    return {
      autoUpdateEnabled: this.autoUpdateEnabled,
      minimumVersionId: this.minimumVersionId
    };
  }

  get minimumVersionId(): number | undefined {
    return this.minimumVersion.length > 0 ? this.minimumVersion[0].id : undefined;
  }

  get minVersionData(): AndroidAppVersion.AndroidAppVersion | undefined {
    return this.minimumVersionId ? this.minimumVersion[0].data! : undefined;
  }
}
