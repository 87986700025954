/* eslint-disable */
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UIRouter } from '@uirouter/angular';
import { TranslateService } from '@ngx-translate/core';
import { DocumentLanguageItem, DocumentTextCreateModel } from '../../../../util/document/document-utils';
import { EmptyMessage } from '../../../../lib/util/messages';
import { StateName } from '../../../../app.state-names';
import { Arrays } from '../../../../lib/util/arrays';
import { DocumentLanguageService } from '../../../../lib/document/document-language.service';
import { DocumentLanguageUtil } from '../../../../util/document/document-language-utils';
import { DocumentGroupService } from '../../../../lib/document/document-group.service';
import { DocumentGroup } from '../../../../lib/document/document-group.service';
import { DocumentGroupItem, LanguageItem } from '../../../../util/document/document-file-utils';
import { DocumentLinkService } from '../../../../lib/document/document-link.service';
import { BreadcrumbParent } from '../../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { UiConstants } from '../../../../util/core-utils';
import { ResourceQueryResult } from '../../../../lib/util/services';
/* eslint-enable */

@Component({
  selector: 'app-document-link-create',
  templateUrl: 'document-link-create.component.html',
  styleUrls: ['document-link-create.component.scss']
})
export class DocumentLinkCreateComponent implements OnInit, AfterViewInit {

  model: DocumentTextCreateModel;
  uploadGlobalErrors: string;
  uploadCollectionFieldErrors: string;
  documentGroupList: DocumentGroupItem[] = [];
  documentLanguageList: DocumentLanguageItem[] = [];
  languageItems: LanguageItem[] = [];
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  public options: Object = {
    placeholderText: '',
    charCounterCount: false,
    toolbarButtons: ['undo', 'redo', '|', 'bold', 'italic', 'underline', 'strikeThrough', 'outdent', 'indent', 'clearFormatting'],
    quickInsertButtons: [],
    pluginsEnabled: []
  };

  constructor(private documentGroupService: DocumentGroupService,
              private documentService: DocumentLinkService,
              private documentLanguageService: DocumentLanguageService,
              private documentLanguageUtil: DocumentLanguageUtil,
              private translateService: TranslateService,
              private uiRouter: UIRouter) {
    this.model = new DocumentTextCreateModel();
  }

  ngOnInit() {
    this.translateService.get('DOCUMENT_LINK_PANEL_HEADING_ADD').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('COMMON_DOCUMENTS').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.DOCUMENT_DASHBOARD});
      }
    );
    this.translateService.get('MENU_NAVBAR_DOCUMENT_LINK').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.DOCUMENT_LINK_LIST});
      }
    );
  }

  ngAfterViewInit(): void {
    this.loadDefaultDocumentGroup(() => {
      this.loadDocumentGroups(() => {
        this.loadDocumentLanguageList(() => {
        });
      });
    });
  }

  loadDefaultDocumentGroup(completion: () => void) {
    this.translateService.get('DOCUMENT_GROUP_FILTER_ALL').subscribe(
      (text: string) => {
        const allGroup: DocumentGroupItem = {
          id: null,
          text: text
        };
        this.model.document_group = allGroup;
        this.documentGroupList.push(allGroup);
        completion();
      }
    );
  }

  loadDocumentGroups(completion: () => void) {
    const arrayTemp: DocumentGroup[] = [];

    this.documentGroupService.query({}).subscribe((result: ResourceQueryResult<DocumentGroup>) => {

        result.items.forEach(group => {
          arrayTemp.push(group);
        });

        const sortedArray = arrayTemp.sort((n1, n2) => {
          if (n1.name > n2.name) {
            return 1;
          }

          if (n1.name < n2.name) {
            return -1;
          }

          return 0;
        });
        sortedArray.forEach(group => {
          const groupItem = {
            id: group.id,
            text: group.name
          };
          this.documentGroupList.push(groupItem);
        });
        completion();

      }, (error: any) => {
        completion();
      });

  }

  getDocumentGroupName(documentGroupId: number | null): string {
    let groupName = '';
    if (!documentGroupId) {
      return groupName;
    }
    Arrays.iterateByIndex(this.documentGroupList, (userGroup) => {
      if (userGroup.id === documentGroupId) {
        groupName = userGroup.text;
      }
    });

    return groupName;
  }

  private loadDocumentLanguageList(completion: () => void) {
    this.documentLanguageService.query().subscribe(
      (result: string[]) => {
        const def = this.documentLanguageUtil.createDefaultLanguageCode();
        this.model.language_code = def;
        this.documentLanguageList.push(def);

        Arrays.iterateByIndex(result, language => {
          const languageItem = new DocumentLanguageItem();
          languageItem.id = language;
          languageItem.text = this.documentLanguageUtil.getDocumentLanguageName(language);
          this.documentLanguageList.push(languageItem);
        });
        completion();

      }, (error: any) => {
        completion();
      });
  }

  update() {

    const code = (this.model.code && this.model.code.length > 0) ? this.model.code : null;
    const languageCodeId = (this.model.language_code) ? this.model.language_code.id : null;
    const document_group_id = (this.model.document_group) ? this.model.document_group.id : null;

    this.documentService.create({
      code: code ? code : undefined,
      name: this.model.name,
      value: this.model.value,
      language_code: languageCodeId ? languageCodeId : undefined,
      document_group_id: document_group_id ? document_group_id : undefined,
      comment: this.model.comment

    }).subscribe(
      (response: EmptyMessage) => {
        this.uiRouter.stateService.go(StateName.DOCUMENT_LINK_LIST);
      }
    );
  }

  back() {
    window.history.back();
  }

  getTextMaximumLength(): number {
    return UiConstants.maximumVarcharLength;
  }

}
