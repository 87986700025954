/* eslint-disable */
import { Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../../../lib/auth.service';
import { MultiselectOptionItem, OwnerUserItemFactory, QueryFieldModel, SelectUtils, UiConstants, } from '../../../../util/core-utils';
import { UserDataLoader } from '../../../../lib/user-data-loader';
import { NgbDatePickerParserFormatter } from '../../../../util/ngb-datepicker';
import { UploadErrorLocalizer } from '../../../../util/upload-error-localizer';
import { Strings } from '../../../../lib/util/strings';
import { InputMask } from '../../../../util/input-masks';
import { RightModel } from '../../../../app.rights';
import { GrantedPermissionSetResolver, RightResolver, RightService } from '../../../../lib/right.service';
import { CustomerRecordService } from '../../../../lib/customer/customer-record.service';
import { ProjectRecordSearch, ProjectRecordSearchService } from '../../../../lib/project/record/project-record-search.service';
import { Models } from '../../../../util/model-utils';
import { OrderType, QueryResult } from '../../../../lib/util/services';
import { Set } from 'immutable';
import { EmptyMessage } from '../../../../lib/util/messages';
import { DisabledEnum, DisabledItem, SearchUtils } from '../../../../util/search-utils';
import { DropdownItemType } from '../../../../shared/dropdown/dropdown-item/dropdown-item-type';
import { Transition } from '@uirouter/angular';
import { ProjectRecord, ProjectRecordService } from '../../../../lib/project/record/project-record.service';
import { BreadcrumbParent } from '../../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { StateName } from '../../../../app.state-names';
import { ProjectService } from '../../../../lib/project/project.service';
import { DisplayedFormFieldModel, FormRecordSerializer } from '../../../../util/form/form-utils';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Task, TaskService } from '../../../../lib/task/task.service';
import { Customer, CustomerService } from '../../../../lib/customer/customer.service';
import { Angular2Multiselects } from '../../../../util/multiselect';
import { FileUploadDialogComponent } from '../../../../shared/file-upload/dialog/file-upload-dialog.component';
import { DqlStoredQueryArgs } from '../../../dql-search/dql-search-container/dql-stored-query.args';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { DqlSearchContainerComponent } from '../../../dql-search/dql-search-container/dql-search-container.component';
import { ProjectRecordDqlFieldTextProvider } from '../../../../lib/project/record/project-record.dql.service';
import { DownloadedFile } from '../../../../lib/util/downloaded-files';
import { UserService } from '../../../../lib/user.service';
import { UserMultiselectProvider } from '../../../../lib/user/user-multiselect.provider';
import { Observable, ReplaySubject } from 'rxjs';
import { saveAs } from 'file-saver';
import { OperationRights } from '../../../../app.right-definitions';
import { ProjectRecordRightModel, ProjectRightModel } from '../../../../util/project-utils';
import { TaskRecordRightModel } from '../../../../util/task-record-utils';
import SearchableList = SearchUtils.SearchableList;
import { Form } from '../../../../lib/form/form.service';

/* eslint-enable */

@Component({
  selector: 'app-project-record-list',
  templateUrl: 'project-record-list.component.html',
  styleUrls: ['project-record-list.component.scss']
})
export class ProjectRecordListComponent extends SearchableList<ProjectRecordSearch.Model> implements OnInit, OnDestroy {

  InputMask = InputMask;
  UiConstants = UiConstants;
  DropdownItemType = DropdownItemType;
  SelectUtils = SelectUtils;
  ProjectRecord = ProjectRecord;

  @ViewChild('heiztechImportDialog', {static: true})
  heiztechImportDialog: ModalDirective;
  heiztechImportDialogVisible: boolean = false;
  heiztechImportModel: HeiztechImportModel;
  tasks: MultiselectOptionItem<number>[] = [];
  customers: MultiselectOptionItem<number>[] = [];
  users: MultiselectOptionItem<number>[] = [];
  dropdownSettings: Angular2Multiselects.Settings;
  @ViewChild('heiztechImportUploadDialog', {static: true})
  heiztechImportUploadDialog: FileUploadDialogComponent;

  profilePictures: Map<number, string | null> = new Map();

  projectId: number;

  queryModel: QueryFieldModel<ProjectRecord.OrderField> = new QueryFieldModel(ProjectRecord.OrderField.ID, OrderType.DESC);
  searchModel: ProjectRecordSearch.Model = new ProjectRecordSearch.Model();

  list: {
    project: ProjectRecord.ProjectRecord,
    selected: boolean,
    displayedFormField?: DisplayedFormFieldModel,
    rights: ProjectRecordRightModel
  }[] = [];
  disabledItems: DisabledItem[] = [];
  billingTypes: MultiselectOptionItem<ProjectRecord.BillingType | undefined>[] = [];

  dqlStoredArgs: DqlStoredQueryArgs;
  activeSearchTab: string = 'simple';
  private _searchTabs?: TabsetComponent;

  displayedFormField?: Form.Field;
  get formFieldName(): string | undefined {
    return this.displayedFormField?.title;
  }
  projectGrantedRights?: ProjectRightModel;

  rightModel: RightModel = RightModel.empty();
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  @ViewChild('searchTabs') set searchTabs(c: TabsetComponent) {
    if (c && !this._searchTabs) {
      this._searchTabs = c;
      if (this.searchModel.dqlText) {
        this.activeSearchTab = 'dql';
        this._searchTabs.tabs[1].active = true;
      }
      else {
        this.activeSearchTab = 'simple';
        this._searchTabs.tabs[0].active = true;
      }
    }
    if (!c) {
      this._searchTabs = c;
    }
  }

  private dqlSearchContainer?: DqlSearchContainerComponent;

  @ViewChild('dqlSearchContainer') set dqlContainer(c: DqlSearchContainerComponent) {
    if (c) {
      if (!this.dqlSearchContainer) {
        const qt = this.searchModel.dqlText;
        this.dqlSearchContainer = c;
        this.loadDqlModel(() => {
          this.loadDqlSearch(qt);
          this.dqlSearchContainer!.loadContent();
        });
      }
    }
    else {
      this.dqlSearchContainer = undefined;
    }
  }

  get heiztechImportUploadPath(): string {
    if (this.heiztechImportModel) {
      return '/projects/' + this.projectId + '/records/heiztech-import-xls' +
        '?task_id=' + this.heiztechImportModel.taskId +
        '&customer_id=' + this.heiztechImportModel.customerId +
        '&task_record_name=' + this.heiztechImportModel.name;
    }
    return '';
  }

  constructor(private rightService: RightService,
              private uploadErrorLocalizer: UploadErrorLocalizer,
              private authService: AuthService,
              private transition: Transition,
              private ownerUserItemFactory: OwnerUserItemFactory,
              private userDataLoader: UserDataLoader,
              private customerRecordService: CustomerRecordService,
              private userMultiselectProvider: UserMultiselectProvider,
              private projectService: ProjectService,
              private datePickerParserFormatter: NgbDatePickerParserFormatter,
              private projectRecordService: ProjectRecordService,
              private projectRecordSearchService: ProjectRecordSearchService,
              public formRecordSerializer: FormRecordSerializer,
              private taskService: TaskService,
              private customerService: CustomerService,
              private userService: UserService,
              injector: Injector) {
    super(ProjectRecordSearch.Model, injector);
    this.projectId = this.transition.params().id;
    this.dqlStoredArgs = {service: projectService.dqlStoredQueryService, parentId: this.projectId, documentType: undefined};
  }

  ngOnInit() {
    this.loadRightModels();
    this.loadProjectData();
    this.initBreadcrumb();
    this.initSearch();
    this.initDropdownSettings();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  private loadProjectData() {
    this.projectService.get({
      id: this.projectId,
      rights: Set.of(OperationRights.PROJECT_RECORD_CREATE, OperationRights.PROJECT_RECORD_IMPORT, OperationRights.PROJECT_RECORD_EXPORT)
    }).subscribe(result => {
      this.breadcrumbSelf = result.name;
      this.displayedFormField = result.displayedFormField;
      this.projectGrantedRights = new ProjectRightModel(GrantedPermissionSetResolver.byGrantedRights(result.grantedRights));
    });
  }

  initBreadcrumb() {
    this.translateService.get('MENU_NAVBAR_PROJECT_RECORD').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.PROJECT_DASHBOARD});
      }
    );
  }

  private initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
  }

  loadDqlModel(completion?: () => void) {
    this.dqlSearchContainer!.setFields(this.projectService.getDqlModel({projectId: this.projectId}),
      ProjectRecordDqlFieldTextProvider.getHelper(),
      completion ? completion : () => {
      });
  }

  loadDqlSearch(queryString?: string) {
    if (queryString) {
      this.dqlSearchContainer!.loadQuery(queryString);
    }
  }

  loadSearch(completion: () => void) {
    this.projectRecordSearchService.getSearchData({
      projectId: this.projectId
    }).subscribe(
      (result: ProjectRecordSearch.SearchDataResult) => {
        this.queryModel.itemsPerPage = result.searchData.itemsPerPage;
        this.queryModel.currentPage = result.searchData.pageNumber;
        this.queryModel.setOrder(result.searchData.order);
        this.searchModel.externalId = result.searchData.externalId;
        this.searchModel.id = result.searchData.id;
        this.searchModel.name = result.searchData.name;
        this.searchModel.disabled = result.searchData.disabled;
        this.searchModel.startDateFrom = Models.localDateToNgbDate(result.searchData.startDateFrom);
        this.searchModel.startDateTo = Models.localDateToNgbDate(result.searchData.startDateTo);
        this.searchModel.endDateFrom = Models.localDateToNgbDate(result.searchData.endDateFrom);
        this.searchModel.endDateTo = Models.localDateToNgbDate(result.searchData.endDateTo);
        this.searchModel.assigneeUser = result.searchData.assigneeUser;
        this.searchModel.postalAddress = result.searchData.postalAddress;
        this.searchModel.dqlText = result.searchData.dqlText;
        completion();
      }
    );
  }

  onFirstSearchOpen(): void {
    this.disabledItems = this.initDisabledOptions();
    this.billingTypes = this.initBillingTypes();
    this.loadUsers();
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: ProjectRecord.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;

    this.projectRecordService.query(this.getQueryRequest(requestedPage, true))
      .subscribe(
        (result: QueryResult<ProjectRecord.ProjectRecord>) => {
          this.list = result.items.toArray().map(p => ({
            project: p,
            selected: false,
            rights: new ProjectRecordRightModel(GrantedPermissionSetResolver.byGrantedRights(p.grantedRights))
          }));
          this.list.forEach(i => {
            if (i.project.assigneeUser) {
              this.loadProfilePicture(i.project.assigneeUser.id);
            }
          });
          this.queryModel.currentPage = requestedPage;
          this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
          this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
          this.loadFormFields();
        }
      );
  }

  private getQueryRequest(requestedPage?: number, requestRights?: boolean): ProjectRecord.QueryRequest {
    const disabled: boolean | undefined
      = !this.searchModel.disabled || this.searchModel.disabled.id === DisabledEnum.NONE
      ? undefined
      : this.searchModel.disabled.id === DisabledEnum.TRUE;
    return {
      projectId: this.projectId,
      projectRecordIdSet: Models.parseNumber(this.searchModel.id) ? Set.of(Models.parseNumber(this.searchModel.id)!) : undefined,
      name: Strings.undefinedOrNonEmpty(this.searchModel.name),
      externalId: Strings.undefinedOrNonEmpty(this.searchModel.externalId),
      dqlText: this.searchModel.dqlText,
      disabled: disabled,
      startDateFrom: this.datePickerParserFormatter.toLocalDate(this.searchModel.startDateFrom),
      startDateTo: this.datePickerParserFormatter.toLocalDate(this.searchModel.startDateTo),
      endDateFrom: this.datePickerParserFormatter.toLocalDate(this.searchModel.endDateFrom),
      endDateTo: this.datePickerParserFormatter.toLocalDate(this.searchModel.endDateTo),
      assigneeUserId: this.searchModel.assigneeUserId,
      postalAddress: Strings.undefinedOrNonEmpty(this.searchModel.postalAddress),
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined,
      orders: Set.of(this.queryModel.getOrder()),
      rights: requestRights ? Set.of(OperationRights.PROJECT_RECORD_UPDATE, OperationRights.PROJECT_RECORD_DISABLE) : undefined
    };
  }

  onSearchClicked() {
    this.searchModel.dqlText = undefined;
    this.loadList(1);
  }

  onDqlSearchClicked() {
    const dqlQueryString = this.dqlSearchContainer!.getQueryString();
    if (dqlQueryString) {
      this.searchModel.clear();
    }
    this.searchModel.dqlText = dqlQueryString;
    this.loadList(1);
  }

  onSearchReset() {
    this.projectRecordSearchService.resetSearchData({projectId: this.projectId}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.toggleSearch();
          this.loadList(1);
        });
      }
    );
  }

  ngOnDestroy() {
    this.saveSearch();
  }

  private saveSearch() {
    const request = {
      projectId: this.projectId,
      searchData: {
        itemsPerPage: this.queryModel.itemsPerPage,
        pageNumber: this.queryModel.currentPage,
        order: this.queryModel.getOrder(),
        id: this.searchModel.id,
        name: this.searchModel.name,
        externalId: this.searchModel.externalId,
        dqlText: this.searchModel.dqlText,
        disabled: this.searchModel.disabled,
        startDateFrom: Models.ngbDateToLocalDate(this.searchModel.startDateFrom),
        startDateTo: Models.ngbDateToLocalDate(this.searchModel.startDateTo),
        endDateFrom: Models.ngbDateToLocalDate(this.searchModel.endDateFrom),
        endDateTo: Models.ngbDateToLocalDate(this.searchModel.endDateTo),
        assigneeUser: this.searchModel.assigneeUser,
        postalAddress: this.searchModel.postalAddress,
      }
    };
    this.projectRecordSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  setDisabled(id: number, disabled: boolean) {
    this.projectRecordService.setDisabled({
      projectId: this.projectId,
      id: id,
      disabled: disabled
    })
      .subscribe(
        (result: EmptyMessage) => {
          this.loadList();
        },
        (error: any) => {
          this.loadList();
        }
      );
  }

  private initBillingTypes(): MultiselectOptionItem<ProjectRecord.BillingType | undefined>[] {
    const result: MultiselectOptionItem<ProjectRecord.BillingType | undefined>[] = [];
    result.push({
      id: undefined,
      itemName: 'COMMON_VALUE_UNSELECTED'
    });
    result.push(...ProjectRecord.billingTypes.map(t => ({id: t.type, itemName: t.stringKey})));
    return result;
  }

  openHeiztechImportDialog() {
    this.loadTasks(undefined, () => {
      this.loadCustomers(undefined, () => {
        this.heiztechImportModel = new HeiztechImportModel();
        this.heiztechImportDialogVisible = true;
        this.heiztechImportDialog.show();
      });
    });
  }

  closeHeiztechImportDialog(importFile: boolean) {
    this.heiztechImportDialogVisible = false;
    this.heiztechImportDialog.hide();
    if (importFile) {
      this.heiztechImportUploadDialog.showDialog();
    }
  }

  loadTasks(q?: string, completion?: () => void) {
    this.taskService.query({
      name: Strings.undefinedOrNonEmpty(q),
      disabled: false,
      orders: Set.of({field: Task.OrderField.NAME, type: OrderType.ASC}),
      paging: {
        pageNumber: 1,
        numberOfItems: UiConstants.autocompletePageSize
      },
      noProgressBar: true
    }).subscribe(result => {
      this.tasks = result.items.toArray().map(t => ({id: t.taskId, itemName: t.name, itemSubtitle: t.externalId}));
      if (completion) {
        completion();
      }
    });
  }

  loadCustomers(q?: string, completion?: () => void) {
    this.customerService.query({
      name: Strings.undefinedOrNonEmpty(q),
      disabled: false,
      orders: Set.of({field: Customer.OrderField.NAME, type: OrderType.ASC}),
      paging: {
        pageNumber: 1,
        numberOfItems: UiConstants.autocompletePageSize
      },
      noProgressBar: true
    }).subscribe(result => {
      this.customers = result.items.toArray().map(t => ({id: t.customerId, itemName: t.name, itemSubtitle: t.externalId}));
      if (completion) {
        completion();
      }
    });
  }

  onHeiztechImportSuccess(succeeded: boolean) {
    if (succeeded) {
      this.loadList();
    }
  }

  getProfilePicture(userId: number): string | undefined {
    const result = this.profilePictures.get(userId);
    return result ? result : undefined;
  }

  private loadProfilePicture(userId: number) {
    if (this.profilePictures.get(userId) === undefined) {
      this.profilePictures.set(userId, null);
      this.userService.downloadProfilePicture(userId).subscribe(
        (res: DownloadedFile) => {
          this.profilePictures.set(userId, URL.createObjectURL(res.getBlob()));
        },
        () => {
        }
      );
    }
  }

  loadUsers(q?: string) {
    this.userMultiselectProvider.loadAll(q).subscribe(result => this.users = result);
  }

  isAnyRowSelected(): boolean {
    const selected = this.list.filter((tr) => tr.selected);
    return selected.length > 0;
  }

  get eachRowSelected(): boolean {
    if (this.list.length === 0) {
      return false;
    }
    let selected = true;
    this.list.forEach((tr) => {
      selected = selected && tr.selected;
    });
    return selected;
  }

  toggleEachRow() {
    const eachRowSelected = !this.eachRowSelected;
    this.list.forEach((i) => {
      i.selected = eachRowSelected;
    });
  }

  getSelectedIdSet(): Set<number> {
    const ids: number[] = [];
    this.list.forEach((i) => {
      if (i.selected) {
        ids.push(i.project.id);
      }
    });
    return Set.of(...ids);
  }

  exportXls() {
    this.getQueryIdSet().subscribe(ids => {
      this.projectRecordService.exportXls({
        projectId: this.projectId,
        ids: ids
      }).subscribe(
        (res: DownloadedFile) => {
          saveAs(res.getBlob(), res.getFileName('project_(' + this.projectId + ')_project_records.xlsx'));
        }
      );
    });
  }

  getQueryIdSet(): Observable<Set<number>> {
    const subject: ReplaySubject<Set<number>> = new ReplaySubject();
    if (this.isAnyRowSelected()) {
      subject.next(this.getSelectedIdSet());
    }
    else {
      this.projectRecordService.query(this.getQueryRequest(undefined, false)).subscribe(result => {
        subject.next(Set.of(...result.items.toArray().map(p => p.id)));
      });
    }
    return subject;
  }

  private loadFormFields() {
    this.list.forEach(item => {
      this.formRecordSerializer.serializeFieldModel(this.displayedFormField, item.project.displayedFormFieldRecord).subscribe(ff => {
        item.displayedFormField = ff;
      });
    })
  }
}

class HeiztechImportModel {
  task: MultiselectOptionItem<number>[] = [];
  customer: MultiselectOptionItem<number>[] = [];
  name: string = '';

  get taskId(): number | undefined {
    return this.task.length === 1 ? this.task[0].id : undefined;
  }

  get customerId(): number | undefined {
    return this.customer.length === 1 ? this.customer[0].id : undefined;
  }
}
