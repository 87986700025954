import { Injectable } from '@angular/core';
import { EmailAddress } from '../util/email-address';
import { GeneralEmailResource, GeneralEmailResourceService } from './general-email-resource.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class GeneralEmailService {

  sendEmail(request: GeneralEmail.EmailSendRequest): Observable<GeneralEmail.Result> {
    const resourceRequest: GeneralEmailResource.EmailSendRequest = {
      template_external_id: request.templateExternalId,
      fields: request.fields,
      recipients: request.recipients.map(r => {
        return {name: r.name, email_address: r.emailAddress.toIso()!};
      }),
      general_pdf_ids: request.generalPdfIds,
      document_external_ids: request.documentExternalIds,
      document_group_codes: request.documentGroupCodes
    };
    return this.resourceService.send(resourceRequest).pipe(map(r => {
      return {emailResult: <GeneralEmail.EmailResult>r.email_result};
    }));
  }

  constructor(private resourceService: GeneralEmailResourceService) {
  }

}

export namespace GeneralEmail {

  export interface EmailSendRequest {
    templateExternalId: string;
    fields: Map<string, string>;
    recipients: Recipient[];
    generalPdfIds?: number[];
    documentExternalIds?: string[];
    documentGroupCodes?: string[];
  }

  export interface Recipient {
    name: string;
    emailAddress: EmailAddress;
  }

  export interface Result {
    emailResult: EmailResult;
  }

  export type EmailResult =
    'SUCCEED' | 'FAILED' | 'INVALID_RECIPIENT' | 'INVALID_REQUEST';
}
