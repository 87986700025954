<!--gallery container-->
<div class="gallery-container">
  <!--wrapper class for image boxes-->
  <div class="wrap">
    <div class="box" *ngFor="let group of documentGroups">
      <!--inner div to hold content-->
      <div class="boxInner">
        <!--inner content container-->
        <div class="content-container" (click)="onGroupClicked(group)">

          <img [style]="{'aspect-ratio': aspectRatio}" *ngIf="displayAsImage(group)" src="{{getPreviewDataUrl(group)}}" alt="{{group.getData().name}}">
          <img [style]="{'aspect-ratio': aspectRatio}" *ngIf="displayAsPlaceholder(group)" src="{{FileTypeModelFactory.DEFAULT.placeholderImageSrc}}" alt="{{group.getData().name}}">
        </div>
        <!--inner content container-->
        <div class="desc">{{group.getData().name}}</div>
      </div>
      <!--inner div to hold content end-->
    </div>
    <!--Print all boxes end-->
  </div>
  <!--wrapper class for image boxes end-->
</div>
<div class="clearfix"></div>

<!--gallery container end-->
