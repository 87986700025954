/* eslint-disable */
import { Component, OnInit, ViewChild, } from '@angular/core';
import { Transition, UIRouter } from '@uirouter/angular';
import { FieldError, FieldErrors, ObservableErrorResourceParser } from '../../../lib/util/errors';
import { StateName } from '../../../app.state-names';
import { InputMask } from '../../../util/input-masks';
import { StockItemFieldErrorMap, StockItemService, StockItemType, StockItemTypeUrlMapper, } from '../../../lib/stock/stock-item.service';
import { StockItemCreateModel } from '../../../util/stock/stock-item-utils';
import { Models } from '../../../util/model-utils';
import { QueryResult } from '../../../lib/util/services';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { TranslateService } from '@ngx-translate/core';
import { Currency, CurrencyService } from '../../../lib/currency.service';
import { Angular2Multiselects } from '../../../util/multiselect';
import {MultiselectOptionItem, OptionItem, SelectUtils, UiConstants} from '../../../util/core-utils';
import { VatRate, VatRateService } from '../../../lib/vat-rate.service';
import { CompanyMultiselectProvider } from '../../../lib/company/company-multiselect.provider';
import { StockItemCategoryMultiselectProvider } from '../../../lib/stock-item-category/stock-item-category-multiselect.provider';
import { LedgerNumberMultiselectProvider } from '../../../lib/ledger/number/ledger-number-multiselect.provider';
import { RightResolver, RightService } from '../../../lib/right.service';
import { RightModel } from '../../../app.rights';
import ZeroVatRateType = VatRate.ZeroVatRateType;
import { ConfigurationResource, ConfigurationService } from '../../../lib/core-ext/configuration.service';
import { Strings } from '../../../lib/util/strings';
import { NgForm } from '@angular/forms';
import {WeightFactory} from "../../../util/weight-utils";

/* eslint-enable */

@Component({
  selector: 'app-stock-item-create',
  templateUrl: 'stock-item-create.component.html',
  styleUrls: ['stock-item-create.component.scss']
})
export class StockItemCreateComponent implements OnInit {

  InputMask = InputMask;
  UiConstants = UiConstants;
  VatRate = VatRate;
  SelectUtils = SelectUtils;

  model: StockItemCreateModel;
  fieldErrors: StockItemFieldErrorMap;
  stockItemType?: StockItemType;
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  dropdownSettingsForCompanies: Angular2Multiselects.Settings;
  companies: MultiselectOptionItem<number>[] = [];
  dropdownSettingsForProductCategory: Angular2Multiselects.Settings;
  stockItemCategories: MultiselectOptionItem<number>[] = [];
  ledgerNumbers: MultiselectOptionItem<number>[] = [];

  selectableCurrencies: Currency.Currency[] = [];
  selectableVatRates: VatRate.VatRate[] = [];
  selectableZeroVatRateTypes: OptionItem<VatRate.ZeroVatRateType>[] = [];
  selectableWeightUnits: string[] = [];

  rightModel: RightModel = RightModel.empty();

  HTMLEditorModules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{'header': [1, 2, 3, 4, 5, 6, false]}],
      ['link', 'image']
    ]
  };

  private readonly config: ConfigurationResource.Configuration;

  get productCodeRequired(): boolean {
    if (!this.config) {
      return true;
    }
    return !this.config.feature_flags.stock_item_product_code_generator_enabled;
  }

  get categoryRequired(): boolean {
    if (!this.config) {
      return false;
    }
    return this.config.feature_flags.stock_item_category_required;
  }

  @ViewChild('f', {static: true})
  form: NgForm;

  constructor(private stockItemService: StockItemService,
              private uiRouter: UIRouter,
              private companyMultiselectProvider: CompanyMultiselectProvider,
              private stockItemCategoryMultiselectProvider: StockItemCategoryMultiselectProvider,
              private translateService: TranslateService,
              private transition: Transition,
              private currencyService: CurrencyService,
              private ledgerNumberMultiselectProvider: LedgerNumberMultiselectProvider,
              private rightService: RightService,
              private configurationService: ConfigurationService,
              private vatRateService: VatRateService) {
    this.model = new StockItemCreateModel();
    this.fieldErrors = {};
    this.stockItemType = StockItemTypeUrlMapper.fromUrl(this.transition.params().stockItemType);
    this.loadRightModel();
    this.config = this.configurationService.getConfiguration();
  }

  ngOnInit() {
    this.initDropdownSettings();
    this.translateService.get('STOCK_PANEL_ITEM_HEADING_CREATE').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('MENU_NAVBAR_PRODUCTS').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.STOCK_ITEM_LIST});
      }
    );
    if (!this.stockItemType) {
      this.uiRouter.stateService.go(StateName.STARTUP);
    }
    this.loadCompanies();
    this.loadStockItemCategories();
    this.currencyService.query({}).subscribe((result: QueryResult<Currency.Currency>) => {
      this.selectableCurrencies = result.items.toArray();
      this.model.unitPrice.currency = this.selectableCurrencies[0];
    });
    this.loadVatRates();
    this.loadZeroVatRateTypes();
    this.selectableWeightUnits = WeightFactory.getOptions();
    this.loadRightModel();
  }

  private loadRightModel() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  initDropdownSettings() {
    this.dropdownSettingsForCompanies = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
    this.dropdownSettingsForProductCategory = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .remoteSearch(true)
      .enableCheckAll(false)
      .build();
  }

  loadCompanies(q?: string) {
    this.companyMultiselectProvider.searchCompanies(q)
      .subscribe((result: MultiselectOptionItem<number>[]) => {
        this.companies = result;
      });
  }

  loadStockItemCategories(predicate?: string) {
    this.stockItemCategoryMultiselectProvider.loadActive(predicate).subscribe((stockItemCategories) => {
      this.stockItemCategories = stockItemCategories;
    });
  };

  loadLedgerNumbers(predicate?: string) {
    if (this.rightModel.ledgerNumberRead.hasRight()) {
      this.ledgerNumberMultiselectProvider.loadActive(predicate).subscribe((ledgerNumbers) => {
        this.ledgerNumbers = ledgerNumbers;
      });
    }
  };

  loadVatRates() {
    this.vatRateService.query().subscribe((result: QueryResult<VatRate.VatRate>) => {
      this.selectableVatRates = result.items.toArray();
    });
    this.model.unitPrice.vatRate = VatRateService.DEFAULT_VAT_RATE;
  }

  loadZeroVatRateTypes() {
    this.selectableZeroVatRateTypes = [];
    VatRate.zeroVatRateTypes.forEach(type => {
      const item = new OptionItem<ZeroVatRateType>();
      item.id = type.type;
      item.disabled = type.disabled;
      this.translateService.get(type.stringKey).subscribe((text) => {
        item.text = text;
      });
      this.selectableZeroVatRateTypes.push(item);
      if (this.selectableZeroVatRateTypes.length > 0) {
        this.model.unitPrice.zeroVatRateType = this.selectableZeroVatRateTypes[0].text;
      }
    });
  }

  create(navigateOnSubmit: boolean) {
    if (!this.form.form.valid) {
      return;
    }
    const unitPriceValue: number = Models.decimalToNumber(Models.parseDecimal(this.model.unitPrice.value))!;
    this.stockItemService.create({
      type: this.stockItemType!,
      usable_for_form_field: this.model.usableForFormField,
      external_id: this.model.externalId,
      product_code: Strings.undefinedOrNonEmpty(this.model.productCode),
      company_id: this.model.companyId,
      name: this.model.name,
      description: this.model.description,
      hun_vtsz_number: this.model.vtszNumber,
      unit: this.model.unit,
      unit_price: {
        value: unitPriceValue,
        vat_rate: this.model.unitPrice.vatRate,
        currency_code: this.model.unitPrice.currency.currencyCode,
        zero_vat_rate_type: this.model.unitPrice.vatRate === 0 ? this.model.unitPrice.zeroVatRateType : undefined
      },
      product_category: this.model.productCategory,
      category_ids: this.model.stockItemCategories.map(c => c.id),
      weight_in_grams: this.model.weightInGrams,
      place: this.model.place,
      serial_code: this.model.serialCode,
      ledger_number: this.model.ledgerNumberId,
      package_data: this.model.packageEnabled ? {
        unit_conversion: Models.parseNumber(this.model.packageConversion)!,
        unit_name: this.model.packageUnitName!,
        code: this.model.packageUnitName!
      } : undefined
    }).subscribe(
      (response) => {
        if (navigateOnSubmit) {
          this.uiRouter.stateService.go(StateName.STOCK_ITEM_LIST);
        } else {
          this.uiRouter.stateService.go(StateName.STOCK_ITEM_EDIT, response);
        }
      },
      (error: any) => {
        const res = ObservableErrorResourceParser.parseError(error);
        this.fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
      }
    );
  }

  removeFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.fieldErrors, fieldError);
  }

  back() {
    window.history.back();
  }

  getTextMaximumLength(): number {
    return UiConstants.maximumVarcharLength;
  }

  onSaveButtonClicked(navigateInSubmit: boolean) {
    this.create(navigateInSubmit);

  }
}
