/* eslint-disable */
import { Component, Input, OnInit, } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AddressModel } from '../../../../lib/address';
import { ListView } from '../../list-view';
import { MultiselectOptionItem } from '../../../../util/core-utils';
import { EmailAddressTypeService } from '../../../../lib/email-address-type/email-address-type.service';
import { TranslateService } from '@ngx-translate/core';
/* eslint-enable */

@Component({
  selector: 'app-email-address-list',
  templateUrl: './email-address-list.component.html',
  styleUrls: ['./email-address-list.component.scss']
})
export class EmailAddressListComponent implements ListView, OnInit {

  @Input()
  form?: NgForm;

  @Input()
  items?: AddressModel.EmailAddressModel[];

  @Input()
  label?: string;

  @Input()
  readonly: boolean;

  @Input()
  fieldStyle?;

  @Input()
  required?: boolean;

  types: MultiselectOptionItem<number>[];

  get m() {
    return this.items!;
  }

  constructor(
    private emailAddressTypeService: EmailAddressTypeService,
    private translateService: TranslateService
  ) {
  }

  ngOnInit() {
    this.emailAddressTypeService.getLocalizedList(this.translateService.currentLang.substr(0, 2))
      .subscribe(result => this.types = result);
  }

  addNewEmptyItem() {
    const items = this.items!;
    this.items!.push(new AddressModel.EmailAddressModel());
  }

  removeItem(item: AddressModel.EmailAddressModel) {
    const items = this.items!;
    items.splice(items.indexOf(item), 1);
  }

  hasLocalError(): boolean {
    if (!this.required || this.readonly || !this.form || !this.items) {
      return false;
    }
    return this.form!.submitted && this.items.length < 1;
  }

}
