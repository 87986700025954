/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FormResource, FormResourcePathGenerator } from '../form/form-resource.service';
import { IconResource } from './icon.service';
import { DqlResourceModel } from '../dql/dql-resource.model';
import { ResourceQueryResult } from '../util/services';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { EmptyMessage, IdentityMessage } from '../util/messages';
import { DqlStoredQueryResourceService } from '../dql/dql-stored-query-resource.service';
import { TriggerResource } from '../trigger/trigger-resource.service';
import { TriggerInstanceResource } from '../trigger/trigger-instance-resource.service';
import { TypeBasedRoleImportDocumentResource, TypeBasedRoleResource } from '../user-group.service';
import TriggerResourceService = TriggerResource.TriggerResourceService;

/* eslint-enable */

@Injectable()
export class TaskResourceService extends BaseHttpService implements TriggerResourceService {

  private static readonly baseUrl: string = '/tasks/';

  private readonly _dqlStoredResourceService: DqlStoredQueryResourceService;

  // <editor-fold desc="CRUD">

  query(request: TaskResource.QueryRequest): Observable<ResourceQueryResult<TaskResource.Task>> {
    return this.http.get<ResourceQueryResult<TaskResource.Task>>(this.url, this.parseParams(request));
  }

  get(request: TaskResource.GetRequest): Observable<TaskResource.Task> {
    return this.http.get<TaskResource.Task>(this.url + `${request.task_id}`, this.parseParams(request));
  }

  create(request: TaskResource.CreateRequest): Observable<IdentityMessage> {
    return this.http.post<TaskResource.Task>(this.url, request);
  }

  update(request: TaskResource.UpdateRequest): Observable<EmptyMessage> {
    return this.http.put<EmptyMessage>(this.url + `${request.task_id}`, request);
  }

  setDisabled(request: TaskResource.DisableRequest): Observable<EmptyMessage> {
    return this.http.patch<EmptyMessage>(this.url + `${request.task_id}/disabled`, request);
  }

  statistics(request: TaskResource.StatisticsQueryRequest): Observable<ResourceQueryResult<TaskResource.Task>> {
    return this.http.get<ResourceQueryResult<TaskResource.Task>>(this.url + `statistics`, this.parseParams(request));
  }

  importForm(request: FormResource.ImportRequest): Observable<FormResource.Form> {
    return this.http.post<FormResource.Form>(this.url + FormResourcePathGenerator.Import(request), request);
  }

  exportForm(request: FormResource.ExportRequest): Observable<FormResource.FormImportDocument> {
    return this.http.post<FormResource.FormImportDocument>(this.url + FormResourcePathGenerator.Export(request), null);
  }

  importJson(request: TaskResource.TaskImportRequest): Observable<IdentityMessage> {
    return this.http.post<IdentityMessage>(this.url + `import-json`, request);
  }

  exportJson(request: TaskResource.ExportRequest): Observable<TaskResource.TaskImportDocument> {
    return this.http.post<TaskResource.TaskImportDocument>(this.url + `${request.task_id}/export-json`, request);
  }

  getDqlModel(request: TaskResource.DqlRequest): Observable<DqlResourceModel.QueryableModel> {
    if (request.task_id) {
      return this.http.get<DqlResourceModel.QueryableModel>(this.url + `dql/model/${request.task_id}` + '?no_progress_bar=true');
    }
    else {
      return this.http.get<DqlResourceModel.QueryableModel>(this.url + `dql/model` + '?no_progress_bar=true');
    }
  }

  setInvoiceBook(request: TaskResource.InvoiceBookPatchRequest): Observable<EmptyMessage> {
    return this.http.patch<EmptyMessage>(this.url + `invoice-book`, request);
  }

  // </editor-fold>

  // <editor-fold desc="Form">

  getForm(request: FormResource.GetFormRequest): Observable<FormResource.Form> {
    return this.http.get<FormResource.Form>(this.url + FormResourcePathGenerator.GetForm(request));
  }

  createFormGroup(request: FormResource.CreateGroupRequest): Observable<FormResource.CreateGroupResponse> {
    return this.http.post<FormResource.CreateGroupResponse>(this.url + FormResourcePathGenerator.CreateGroup(request), request);
  }

  updateFormGroup(request: FormResource.UpdateGroupRequest): Observable<FormResource.UpdateGroupResponse> {
    return this.http.put<FormResource.UpdateGroupResponse>(this.url + FormResourcePathGenerator.UpdateGroup(request), request);
  }

  disableFormGroup(request: FormResource.DisableGroupRequest): Observable<FormResource.DisableGroupResponse> {
    return this.http.patch<FormResource.DisableGroupResponse>(this.url + FormResourcePathGenerator.DisableGroup(request), request);
  }

  moveFormGroup(request: FormResource.MoveGroupRequest): Observable<FormResource.MoveGroupResponse> {
    return this.http.post<FormResource.MoveGroupResponse>(this.url + FormResourcePathGenerator.MoveGroup(request), null);
  }

  createFormField(request: FormResource.CreateFieldRequest): Observable<FormResource.CreateFieldResponse> {
    return this.http.post<FormResource.CreateFieldResponse>(this.url + FormResourcePathGenerator.CreateField(request), request);
  }

  updateFormField(request: FormResource.UpdateFieldRequest): Observable<FormResource.UpdateFieldResponse> {
    return this.http.post<FormResource.UpdateFieldResponse>(this.url + FormResourcePathGenerator.UpdateField(request), request);
  }

  disableFormField(request: FormResource.DisableFieldRequest): Observable<FormResource.DisableFieldResponse> {
    return this.http.patch<FormResource.DisableFieldResponse>(this.url + FormResourcePathGenerator.DisableField(request), request);
  }

  moveFormFieldToGroup(request: FormResource.MoveFieldToGroupRequest): Observable<FormResource.MoveFieldToGroupResponse> {
    return this.http.post<FormResource.MoveFieldToGroupResponse>(this.url + FormResourcePathGenerator.MoveFieldToGroup(request), null);
  }

  // </editor-fold>

  // <editor-fold desc="trigger">

  createTrigger(request: TriggerResource.TriggerCreateRequest): Observable<IdentityMessage> {
    return this.http.post<IdentityMessage>(this.url + `${request.trigger_parent_id}/triggers/`, request);
  }

  deleteTrigger(request: TriggerResource.TriggerDeleteRequest): Observable<EmptyMessage> {
    return this.http.delete<EmptyMessage>(this.url + `${request.trigger_parent_id}/triggers/${request.id}`);
  }

  disableTrigger(request: TriggerResource.TriggerDisableRequest): Observable<EmptyMessage> {
    return this.http.patch<EmptyMessage>(this.url + `${request.trigger_parent_id}/triggers/${request.id}/disabled`, request);
  }

  getTrigger(request: TriggerResource.TriggerGetRequest): Observable<TriggerResource.Trigger> {
    return this.http.get<TriggerResource.Trigger>(this.url + `${request.trigger_parent_id}/triggers/${request.id}`);
  }

  getTriggerInstances(request: TriggerResource.TriggerInstanceRequest):
    Observable<ResourceQueryResult<TriggerInstanceResource.TriggerInstance>> {
    return this.http.get<ResourceQueryResult<TriggerInstanceResource.TriggerInstance>>(
      this.url + `${request.trigger_parent_id}/triggers/${request.id}/instances`, this.parseParams(request)
    );
  }

  queryTriggers(request: TriggerResource.TriggerQueryRequest): Observable<ResourceQueryResult<TriggerResource.Trigger>> {
    return this.http.get<ResourceQueryResult<TriggerResource.Trigger>>(
      this.url + `${request.trigger_parent_id}/triggers`, this.parseParams(request)
    );
  }

  updateTrigger(request: TriggerResource.TriggerUpdateRequest): Observable<EmptyMessage> {
    return this.http.put(this.url + `${request.trigger_parent_id}/triggers/${request.id}`, request);
  }

  // </editor-fold>

  constructor(private http: HttpClient, private resourceHelper: ResourceHelper) {
    super(resourceHelper, TaskResourceService.baseUrl);
    this._dqlStoredResourceService = new DqlStoredQueryResourceService(http, resourceHelper, TaskResourceService.baseUrl);
  }

  get dqlStoredResourceService(): DqlStoredQueryResourceService {
    return this._dqlStoredResourceService;
  }


}

@Injectable()
export class TaskRoleResourceService extends BaseHttpService {

  getRoles(request: IdentityMessage): Observable<TypeBasedRoleResource[]> {
    return this.http.get<TypeBasedRoleResource[]>(this.url + `${request.id}/roles`);
  }

  createRole(request: TypeBasedRoleResource): Observable<IdentityMessage> {
    return this.http.post<IdentityMessage>(this.url + `${request.type_id}/roles`, request);
  }

  updateRole(request: TypeBasedRoleResource): Observable<EmptyMessage> {
    return this.http.put<EmptyMessage>(this.url + `${request.type_id}/roles/${request.id}`, request);
  }

  deleteRole(request: TypeBasedRoleResource): Observable<EmptyMessage> {
    return this.http.delete<EmptyMessage>(this.url + `${request.type_id}/roles/${request.id}`);
  }

  constructor(private http: HttpClient, private resourceHelper: ResourceHelper) {
    super(resourceHelper, '/tasks/');
  }
}

export namespace TaskResource {

  // <editor-fold desc="CRUD">

  export type TaskValidationType = 'REQUIRED' | 'REQUEST_POSSIBLE' | 'DISABLED';

  export enum TaskManualTimeTrackingType {
    WEEK = 'WEEK',
    SIMPLE = 'SIMPLE'
  }
  export type TaskSignatureValidationType =
    'OFF' |
    'MOBILE_REQUIRED_ADMIN_CLOSABLE' |
    'MOBILE_REQUIRED_ADMIN_NOT_CLOSABLE' |
    'MOBILE_REQUIRED_ADMIN_REQUIRED' |
    'MOBILE_CLOSABLE_ADMIN_REQUIRED';

  export type DayType = 'BUSINESS_DAY' | 'CALENDAR_DAY';

  export type TaskDocumentType = 'PDF' | 'PRINTER';

  export interface Task {
    id: number;
    disabled: boolean;
    creation_time: string;
    update_time: string;
    name: string;
    external_id?: string;
    description?: string;
    invoice_book?: number;
    validation_type: TaskValidationType;
    customer_signature: TaskSignatureValidationType;
    user_signature: TaskSignatureValidationType;
    picture_upload: boolean;
    photo_confirm_dialog_enabled: boolean;

    photo_enabled_in_states: string[];
    min_num_of_pics?: number;
    max_num_of_pics?: number;
    customer_attachment_enabled: boolean;
    parallel_work_rejected: boolean;
    icon?: IconResource.Icon;
    send_auto_email_on_submit: boolean;
    send_auto_email_to_user: boolean;
    send_auto_email_to_customer: boolean;
    auto_email_other_recipients?: string[];
    template_id?: number;
    time_tracking_enabled: boolean;
    manual_time_tracking_enabled: boolean;
    manual_time_tracking_required?: boolean;
    manual_time_tracking_type?: string;
    collaborator_time_tracking_enabled: boolean;
    default_estimated_time_in_minutes?: number;
    show_pictures_on_form: boolean;
    mileage_record_enabled: boolean;
    mileage_record_required: boolean;
    geofencing_enabled: boolean;
    geofencing_radius_in_meters?: number;
    statistics?: Map<string, number>;
    overdue_count?: number;
    auto_invoice_generate: boolean;
    manual_invoice_enabled: boolean;
    invoice_settings_id?: number;
    invoice_book_id?: number;
    invoice_tag_id?: number;
    print_invoice_count?: number;
    valid_invoice_payment_types?: string[];
    generating_invoice_payment_types?: string[];
    default_linked_survey_ids?: number[];
    default_deadline?: TaskDefaultDeadline;
    creator_user_groups?: number[];
    task_record_external_id_sequence_data?: TaskRecordExternalIdSequenceData;
    task_record_name_template?: string;
    use_default_managed_fields: boolean;
    managed_fields: string[];
    helpdesk_managed_fields?: string[];
    required_fields: string[];
    helpdesk_required_fields?: string[];
    default_state: string;
    default_state_force_new: boolean;
    web_create_default_assignee_type: string;
    show_on_dashboard: boolean;
    explicit_order_number?: number;
    place_of_consumption_requirement: string;
    form?: FormResource.Form;
    helpdesk_enabled: boolean;
    set_customer_owner_user_after_finish: boolean;
    set_customer_owner_user_group_after_finish: boolean;
    task_record_color: number; // int
    submitted_filters: SubmittedFilter[];
    sms_rating_enabled: boolean;
    in_progress_signature_enabled: boolean;
    sms_rating_delivery_schedule_min?: number;
    sms_rating_template_id?: number;
    state_filters: StateFilter[];
    admin_editable_states: string[];
    enabled_assignees?: EnabledAssignees;
    granted_rights?: string[];
  }

  export interface QueryRequest {
    id?: string;
    disabled?: boolean;
    name?: string;
    external_id?: string;
    creation_time_from?: string;
    creation_time_to?: string;
    update_time_from?: string;
    update_time_to?: string;
    with_form?: boolean;
    with_statistics?: boolean;
    state?: string;
    invoice_book?: number;
    show_on_dashboard?: boolean;
    helpdesk_enabled?: boolean;
    has_default_estimated_time?: boolean;
    q?: string;
    fields?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
    rights?: string;
    no_progress_bar?: boolean;
  }

  export interface StatisticsQueryRequest {
    id?: string;
    disabled?: boolean;
    name?: string;
    external_id?: string;
    with_form?: boolean;
    statistics_type?: string;
    state?: string;
    show_on_dashboard?: boolean;
    q?: string;
    fields?: string;
    order?: string;
    rights?: string;
    page_number?: number;
    number_of_items?: number;
  }

  export interface GetRequest {
    task_id: number;
    rights?: string;
  }

  export interface DqlRequest {
    task_id?: number;
  }

  export interface CreateRequest {
    language_code: string;
    name: string;
    external_id?: string;
    description?: string;
    validation_type: TaskResource.TaskValidationType;
    customer_signature: TaskSignatureValidationType;
    user_signature: TaskSignatureValidationType;
    picture_upload: boolean;
    photo_confirm_dialog_enabled: boolean;
    photo_enabled_in_states: string[];
    min_num_of_pics?: number;
    max_num_of_pics?: number;
    customer_attachment_enabled: boolean;
    parallel_work_rejected: boolean;
    icon?: IconResource.Icon;
    time_tracking_enabled: boolean;
    manual_time_tracking_enabled: boolean;
    manual_time_tracking_required?: boolean;
    manual_time_tracking_type?: string;
    collaborator_time_tracking_enabled: boolean;
    show_pictures_on_form: boolean;
    default_estimated_time_in_minutes?: number;
    mileage_record_enabled: boolean;
    mileage_record_required: boolean;
    geofencing_enabled: boolean;
    geofencing_radius_in_meters?: number;
    auto_invoice_generate: boolean;
    manual_invoice_enabled: boolean;
    invoice_settings_id?: number;
    invoice_book_id?: number;
    invoice_tag_id?: number;
    print_invoice_count?: number;
    valid_invoice_payment_types?: string[];
    generating_invoice_payment_types?: string[];
    default_linked_survey_ids?: number[];
    default_deadline?: TaskDefaultDeadline;
    creator_user_groups?: number[];
    task_record_external_id_sequence_data?: TaskRecordExternalIdSequenceData;
    task_record_name_template?: string;
    use_default_managed_fields: boolean;
    managed_fields?: string[];
    helpdesk_managed_fields?: string[];
    required_fields: string[];
    helpdesk_required_fields?: string[];
    default_state: string;
    default_state_force_new: boolean;
    web_create_default_assignee_type: string;
    show_on_dashboard: boolean;
    explicit_order_number?: number;
    place_of_consumption_requirement: string;
    helpdesk_enabled: boolean;
    set_customer_owner_user_after_finish: boolean;
    set_customer_owner_user_group_after_finish: boolean;
    task_record_color: number; // int
    submitted_filters: SubmittedFilter[];
    sms_rating_enabled: boolean;
    in_progress_signature_enabled: boolean;
    sms_rating_delivery_schedule_min?: number;
    sms_rating_template_id?: number;
    state_filters: StateFilter[];
    admin_editable_states: string[];
    enabled_assignees: EnabledAssignees;
  }

  export interface UpdateRequest {
    task_id: number;
    name: string;
    external_id?: string;
    description?: string;
    validation_type: TaskResource.TaskValidationType;
    customer_signature: TaskSignatureValidationType;
    user_signature: TaskSignatureValidationType;
    picture_upload: boolean;
    photo_confirm_dialog_enabled: boolean;
    photo_enabled_in_states: string[];
    min_num_of_pics?: number;
    max_num_of_pics?: number;
    customer_attachment_enabled: boolean;
    parallel_work_rejected: boolean;
    icon?: IconResource.Icon;
    time_tracking_enabled: boolean;
    manual_time_tracking_enabled: boolean;
    manual_time_tracking_required?: boolean;
    manual_time_tracking_type?: string;
    collaborator_time_tracking_enabled: boolean;
    show_pictures_on_form: boolean;
    default_estimated_time_in_minutes?: number;
    mileage_record_enabled: boolean;
    mileage_record_required: boolean;
    geofencing_enabled: boolean;
    geofencing_radius_in_meters?: number;
    auto_invoice_generate: boolean;
    manual_invoice_enabled: boolean;
    invoice_settings_id?: number;
    invoice_book_id?: number;
    invoice_tag_id?: number;
    print_invoice_count?: number;
    valid_invoice_payment_types?: string[];
    generating_invoice_payment_types?: string[];
    default_linked_survey_ids?: number[];
    default_deadline?: TaskDefaultDeadline;
    creator_user_groups?: number[];
    task_record_external_id_sequence_data?: TaskRecordExternalIdSequenceData;
    task_record_name_template?: string;
    use_default_managed_fields: boolean;
    managed_fields?: string[];
    helpdesk_managed_fields?: string[];
    required_fields: string[];
    helpdesk_required_fields?: string[];
    default_state: string;
    default_state_force_new: boolean;
    web_create_default_assignee_type: string;
    show_on_dashboard: boolean;
    explicit_order_number?: number;
    place_of_consumption_requirement: string;
    helpdesk_enabled: boolean;
    set_customer_owner_user_after_finish: boolean;
    set_customer_owner_user_group_after_finish: boolean;
    task_record_color: number; // int
    submitted_filters: SubmittedFilter[];
    sms_rating_enabled: boolean;
    in_progress_signature_enabled: boolean;
    sms_rating_delivery_schedule_min?: number;
    sms_rating_template_id?: number;
    state_filters: StateFilter[];
    admin_editable_states: string[];
    enabled_assignees: EnabledAssignees;
  }

  export interface EnabledAssignees {
    user_groups: number[];
  }

  export interface InvoiceBookPatchRequest {
    id: number[];
    invoice_book: number;
  }

  export interface DisableRequest {
    task_id: number;
    disabled: boolean;
  }

  export interface TaskDefaultDeadline {
    day_type: DayType;
    offset: number;
  }

  export interface TaskRecordExternalIdSequenceData {
    template: string,
    within_year: boolean
  }

  export interface SubmittedFilter {
    user_group_id: number;
    days: number;
  }

  export interface StateFilter {
    user_group_id: number;
    states: string[];
  }

  export interface ExportRequest {
    task_id: number;
    include_base_data: boolean;
    include_form_data: boolean;
    include_triggers: boolean;
    include_roles: boolean;
  }

  export interface TaskImportRequest {
    task_id?: number;
    document: TaskImportDocument;
    settings: {
      form_ignore_disabled_items: boolean;
      trigger_ignore_disabled_items: boolean;
      trigger_remove_existing_triggers: boolean;
    }
  }

  export interface TaskImportDocument {
    base_data?: TaskImportBaseData;
    form?: FormResource.FormImportDocument;
    triggers?: TriggerResource.TriggerImportDocument[];
    roles?: TypeBasedRoleImportDocumentResource;
  }

  export interface TaskImportBaseData {
    external_id: string;
    name: string;
    language_code: string;
    description?: string;
    validation_type: TaskValidationType;
    icon?: IconResource.Icon;
    customer_signature: TaskSignatureValidationType;
    user_signature: TaskSignatureValidationType;
    picture_upload: boolean;
    photo_confirm_dialog_enabled: boolean;
    helpdesk_enabled: boolean;
    set_customer_owner_user_after_finish: boolean;
    set_customer_owner_user_group_after_finish: boolean;
    task_record_color: number; // int
    admin_editable_states: string[];
    min_num_of_pics?: number;
    max_num_of_pics?: number;
    customer_attachment_enabled: boolean;
    parallel_work_rejected: boolean;
    time_tracking_enabled: boolean;
    manual_time_tracking_enabled: boolean;
    manual_time_tracking_required?: boolean;
    manual_time_tracking_type?: string;
    default_estimated_time_in_minutes?: number;
    collaborator_time_tracking_enabled: boolean;
    show_pictures_on_form: boolean;
    invoice_book_external_id?: string;
    invoice_settings_external_id?: string;
    auto_invoice_generate: boolean;
    manual_invoice_enabled: boolean;
    print_invoice_count?: number;
    mileage_record_enabled: boolean;
    mileage_record_required: boolean;
    geofencing_enabled: boolean;
    geofencing_radius_in_meters?: number;
    valid_invoice_payment_types?: string[];
    generating_invoice_payment_types?: string[];
    default_linked_survey_external_ids?: string[];
    default_deadline?: TaskDefaultDeadline;
    use_default_managed_fields: boolean;
    managed_fields?: string[];
    required_fields: string[];
    helpdesk_managed_fields?: string[];
    helpdesk_required_fields?: string[];
    default_state: string;
    default_state_force_new: boolean;
    web_create_default_assignee_type: string;
    creator_user_group_external_ids?: string[];
    task_record_external_id_sequence_data?: TaskRecordExternalIdSequenceData;
    task_record_name_template?: string;
    show_on_dashboard: boolean;
    explicit_order_number?: number;
    place_of_consumption_requirement: string;
    sms_rating_enabled: boolean;
    in_progress_signature_enabled: boolean;
    sms_rating_delivery_schedule_min?: number;
    sms_rating_template_external_id?: string;
    invoice_tag_external_id?: string;
    submitted_filters: {
      user_group_external_id: string;
      days: number;
    }[];
    state_filters: {
      user_group_external_id: string;
      states: string[];
    }[];
    enabled_assignees?: {
      user_group_external_ids: string[];
    };
  }

  // </editor-fold>

}
