<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="card">
    <div class="card-header">
      {{'NOTE_PANEL_HEADING_LIST' | translate}}

      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()" title="{{'COMMON_SHOW_SEARCH_HINT' | translate}}"><i #searchIcon
                                                                                    class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>

    <div class="card-body border-bottom" *ngIf="showSearch">

      <div class="row form-group">
        <div class="col">
        <label class="search-label">{{'NOTE_LIST_SEARCH_CONTENT' | translate}}</label>
        <input type="text" class="form-control" placeholder="{{'NOTE_LIST_SEARCH_CONTENT' | translate}}"
               maxlength="{{UiConstants.maximumVarcharLength}}"
               (keyup.enter)="onSearchClicked()"
               [(ngModel)]="searchModel.content" name="content" id="content" #content="ngModel">
      </div>

        <div class="col">
          <label class="search-label">{{'COMMON_TASK' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'COMMON_TASK' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.taskName" name="taskName" id="taskName" #taskName="ngModel">
          <!--<select class="form-control" [(ngModel)]="searchModel.task"-->
                  <!--id="task" name="task" #task="ngModel">-->
            <!--<option *ngFor="let task of taskItems" [ngValue]="task">{{task.text}}</option>-->
          <!--</select>-->
        </div>

        <div class="col">
          <label class="search-label">{{'NOTE_LIST_SEARCH_CUSTOMER' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'NOTE_LIST_SEARCH_CUSTOMER' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
          [(ngModel)]="searchModel.customerName" name="customerName" id="customerName" #customerName="ngModel">
          <!--<select class="form-control" [(ngModel)]="searchModel.customer"-->
                  <!--id="customer" name="customer" #customer="ngModel">-->
            <!--<option *ngFor="let customer of customerItems" [ngValue]="customer">{{customer.text}}</option>-->
          <!--</select>-->
        </div>

        <div class="col">
          <label class="search-label">{{'NOTE_LIST_SEARCH_OWNER_USER' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'NOTE_LIST_SEARCH_OWNER_USER' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.ownerUserName" name="ownerUserName" id="ownerUserName" #ownerUserName="ngModel">
          <!--<select class="form-control" [(ngModel)]="searchModel.owner_user"-->
                  <!--id="ownerUser" name="new_customer" #customer="ngModel">-->
            <!--<option *ngFor="let owner of ownerUsers" [ngValue]="owner">{{owner.text}}</option>-->
          <!--</select>-->
        </div>
        <div class="col">
          <label class="search-label">{{'NOTE_LIST_NOTE_STATE' | translate}}</label>
          <select class="form-control" [(ngModel)]="searchModel.disabled"
                  id="disabled" name="disabled" #disabled="ngModel" [compareWith]="SelectUtils.compareObjects">
            <option *ngFor="let item of disabledItems" [ngValue]="item">{{item.text}}</option>
          </select>
        </div>
      </div>
      <div class="col-md-12 d-flex justify-content-md-end align-items-end px-0">
        <div class="btn-group" role="group">
          <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
            {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
          </button>
          <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
            {{'COMMON_BUTTON_SEARCH' | translate}}
          </button>
        </div>
      </div>
    </div> <!-- ./card-body end -->

    <div class="card-body">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header w-5 hidden-lg-down"><app-table-field-sorter [orderField]="NoteList.OrderField.ID"
                                                                               [orderType]="queryModel.getOrderType(NoteList.OrderField.ID)"
                                                                               (orderChange)="orderBy($event)"
                                                                               [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter></th>
          <th class="table-sorter-header w-20 "><app-table-field-sorter [orderField]="NoteList.OrderField.CONTENT"
                                                                                [orderType]="queryModel.getOrderType(NoteList.OrderField.CONTENT)"
                                                                                (orderChange)="orderBy($event)"
                                                                                [text]="'NOTE_LIST_TABLE_CONTENT' | translate"></app-table-field-sorter></th>
          <th class="table-sorter-header w-15 hidden-xs-down"><app-table-field-sorter [orderField]="NoteList.OrderField.OWNER_USER_ID"
                                                                                [orderType]="queryModel.getOrderType(NoteList.OrderField.OWNER_USER_ID)"
                                                                                (orderChange)="orderBy($event)"
                                                                                [text]="'NOTE_LIST_TABLE_OWNER_USER' | translate"></app-table-field-sorter></th>
          <th class="table-sorter-header w-10 "><app-table-field-sorter [orderField]="NoteList.OrderField.CUSTOMER_ID"
                                                                                [orderType]="queryModel.getOrderType(NoteList.OrderField.CUSTOMER_ID)"
                                                                                (orderChange)="orderBy($event)"
                                                                                [text]="'NOTE_LIST_TABLE_CUSTOMER' | translate"></app-table-field-sorter></th>
          <th class="table-sorter-header w-10 hidden-sm-down"><app-table-field-sorter [orderField]="NoteList.OrderField.TASK_RECORD_ID"
                                                                                [orderType]="queryModel.getOrderType(NoteList.OrderField.TASK_RECORD_ID)"
                                                                                (orderChange)="orderBy($event)"
                                                                                [text]="'COMMON_TASK' | translate"></app-table-field-sorter></th>
          <th class="table-sorter-header w-15 hidden-lg-down"><app-table-field-sorter [orderField]="NoteList.OrderField.CREATION_TIME"
                                                                                [orderType]="queryModel.getOrderType(NoteList.OrderField.CREATION_TIME)"
                                                                                (orderChange)="orderBy($event)"
                                                                                [text]="'COMMON_TABLE_HEADER_CREATION_TIME' | translate"></app-table-field-sorter></th>
          <th class="table-sorter-header w-15 hidden-sm-down"><app-table-field-sorter [orderField]="NoteList.OrderField.UPDATE_TIME"
                                                                                [orderType]="queryModel.getOrderType(NoteList.OrderField.UPDATE_TIME)"
                                                                                (orderChange)="orderBy($event)"
                                                                                [text]="'COMMON_TABLE_HEADER_UPDATE_TIME' | translate"></app-table-field-sorter></th>
          <th class="table-sorter-header w-10"><app-table-sorter-no-op [text]="'COMMON_TABLE_HEADER_ACTIONS' | translate"></app-table-sorter-no-op></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let note of noteList | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }" [disabledTableRow]="note.disabled">
          <td class="responsive-table-column hidden-lg-down">{{note.id}}</td>
          <td class="content-wrap responsive-table-column">{{note.content}}</td>
          <td class="responsive-table-column hidden-xs-down">{{note.ownerUserName }}</td>
          <td class="responsive-table-column">{{note.customerName }}</td>
          <td class="responsive-table-column hidden-sm-down">{{note.taskRecordName }}</td>
          <td class="responsive-table-column hidden-lg-down">{{note.creation_time | date:'short'}}</td>
          <td class="responsive-table-column hidden-sm-down">{{note.update_time | date:'short'}}</td>
          <td class="responsive-table-column long">
            <a class="icons-table-group" href="#" onclick="return false"
               (click)="setDisabled($event, note, true)" *ngIf="!note.disabled && rightModel.noteDisable.hasRight()">
              <i class="icomoon icons-table-item icomoon-disable icomoon-disable-table" title="{{'COMMON_BUTTON_DISABLE' | translate}}"></i>
            </a>
            <a class="icons-table-group" href="#" onclick="return false" (click)="setDisabled($event, note, false)"
               *ngIf="note.disabled && rightModel.noteDisable.hasRight()">
              <i class="icomoon icons-table-item icomoon-enable icomoon-enable-table" title="{{'COMMON_BUTTON_ENABLE' | translate}}"></i>
            </a>
            <a class="icons-table-group" uiSref="Admin.NoteDetail" [uiParams]="{ id: note.id }">
              <i class="icomoon icons-table-item icomoon-detail icomoon-detail-table" title="{{'COMMON_BUTTON_DETAILS' | translate}}"></i>
            </a>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header w-5 hidden-lg-down"><app-table-field-sorter [orderField]="NoteList.OrderField.ID"
                                                                               [orderType]="queryModel.getOrderType(NoteList.OrderField.ID)"
                                                                               (orderChange)="orderBy($event)"
                                                                               [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter></th>
          <th class="table-sorter-header w-20 "><app-table-field-sorter [orderField]="NoteList.OrderField.CONTENT"
                                                                                [orderType]="queryModel.getOrderType(NoteList.OrderField.CONTENT)"
                                                                                (orderChange)="orderBy($event)"
                                                                                [text]="'NOTE_LIST_TABLE_CONTENT' | translate"></app-table-field-sorter></th>
          <th class="table-sorter-header w-15 hidden-xs-down"><app-table-field-sorter [orderField]="NoteList.OrderField.OWNER_USER_ID"
                                                                                [orderType]="queryModel.getOrderType(NoteList.OrderField.OWNER_USER_ID)"
                                                                                (orderChange)="orderBy($event)"
                                                                                [text]="'NOTE_LIST_TABLE_OWNER_USER' | translate"></app-table-field-sorter></th>
          <th class="table-sorter-header w-10 "><app-table-field-sorter [orderField]="NoteList.OrderField.CUSTOMER_ID"
                                                                                [orderType]="queryModel.getOrderType(NoteList.OrderField.CUSTOMER_ID)"
                                                                                (orderChange)="orderBy($event)"
                                                                                [text]="'NOTE_LIST_TABLE_CUSTOMER' | translate"></app-table-field-sorter></th>
          <th class="table-sorter-header w-10 hidden-sm-down"><app-table-field-sorter [orderField]="NoteList.OrderField.TASK_RECORD_ID"
                                                                                [orderType]="queryModel.getOrderType(NoteList.OrderField.TASK_RECORD_ID)"
                                                                                (orderChange)="orderBy($event)"
                                                                                [text]="'COMMON_TASK' | translate"></app-table-field-sorter></th>
          <th class="table-sorter-header w-15 hidden-lg-down"><app-table-field-sorter [orderField]="NoteList.OrderField.CREATION_TIME"
                                                                                [orderType]="queryModel.getOrderType(NoteList.OrderField.CREATION_TIME)"
                                                                                (orderChange)="orderBy($event)"
                                                                                [text]="'COMMON_TABLE_HEADER_CREATION_TIME' | translate"></app-table-field-sorter></th>
          <th class="table-sorter-header w-15 hidden-sm-down"><app-table-field-sorter [orderField]="NoteList.OrderField.UPDATE_TIME"
                                                                                [orderType]="queryModel.getOrderType(NoteList.OrderField.UPDATE_TIME)"
                                                                                (orderChange)="orderBy($event)"
                                                                                [text]="'COMMON_TABLE_HEADER_UPDATE_TIME' | translate"></app-table-field-sorter></th>
          <th class="table-sorter-header w-10"><app-table-sorter-no-op [text]="'COMMON_TABLE_HEADER_ACTIONS' | translate"></app-table-sorter-no-op></th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>
  </div>
</div>
