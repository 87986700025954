import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ShipmentGroupModel } from '../shipment-group.model';
import { RightModel } from '../../../../app.rights';
import { UIRouter } from '@uirouter/angular';
import { RightResolver, RightService } from '../../../../lib/right.service';
import { ToasterService } from '../../../../fork/angular2-toaster/src/toaster.service';
import { ShipmentGroup, ShipmentGroupService } from '../../../../lib/shipment-group/shipment-group.service';
import { TranslateService } from '@ngx-translate/core';
import { StateName } from '../../../../app.state-names';
import { UiConstants } from '../../../../util/core-utils';
import { StringKey } from '../../../../app.string-keys';
import { ShipmentGroupListComponent } from '../../shipment-group-list/shipment-group-list.component';
import { ShipmentRightModel } from '../../../../app.rights.shpiment';
import { UserService } from '../../../../lib/user.service';
import RemoveDemandResponse = ShipmentGroup.RemoveDemandResponse;

@Component({
  selector: 'app-shipment-group-shipping-demand-list',
  templateUrl: './shipment-group-shipping-demand-list.component.html',
  styleUrls: ['./shipment-group-shipping-demand-list.component.scss']
})
export class ShipmentGroupShippingDemandListComponent implements OnInit {

  @Input()
  readonly: boolean;

  @Input()
  shipmentGroupModel: ShipmentGroupModel;

  @Output()
  shippingDemandListChanged: EventEmitter<any> = new EventEmitter();

  rightModel: RightModel = RightModel.empty();
  shipmentRightModel: ShipmentRightModel = ShipmentRightModel.empty();

  constructor(
    private uiRouter: UIRouter,
    private rightService: RightService,
    private shipmentGroupService: ShipmentGroupService,
    private translateService: TranslateService,
    private toasterService: ToasterService,
    private userService: UserService
  ) {
  }

  ngOnInit() {
    this.loadRightModels();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.userService.get({
          id: resolver.userProfile!.id
        }).subscribe((user) => {
          this.rightModel = RightModel.of(resolver);
          this.shipmentRightModel = ShipmentRightModel.of({
            rightModel: this.rightModel,
            currentCompanies: user.companies.map(c => c.id)
          });
        });
      }
    );
  }

  deleteShippingDemand(shippingDemandId: number) {
    this.shipmentGroupService.removeDemand({
      shipmentGroupId: this.shipmentGroupModel.id,
      demandId: shippingDemandId
    }).subscribe((result: RemoveDemandResponse) => {
      if (result.shipmentRemoved) {
        localStorage.setItem(ShipmentGroupListComponent.LOCAL_STORAGE_RELOAD_KEY, 'true');
        this.uiRouter.stateService.go(StateName.SHIPMENT_LIST);
      }
      else {
        this.shippingDemandListChanged.emit();
      }
    });
  }

  canDelete() {
    return !this.readonly && this.shipmentRightModel.shipmentGroupDemandRemove
      .hasRightForShipment({transporterId: this.shipmentGroupModel.transporterCompany.id}, undefined);
  }
}
