import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {WorkflowTransitionTaskRuleModel} from "../workflow-create-editor-data-edit.component";
import {Workflow} from "../../../../../lib/workflow/workflow.service";
import {MultiselectOptionItem, UiConstants} from "../../../../../util/core-utils";
import {TaskRecord} from "../../../../../lib/task/task-record.service";
import {Angular2Multiselects} from "../../../../../util/multiselect";
import {TranslateService} from "@ngx-translate/core";
import {UserMultiselectProvider} from "../../../../../lib/user/user-multiselect.provider";
import {ToasterService} from "../../../../../fork/angular2-toaster/src/toaster.service";
import {UserGroupMultiselectProvider} from "../../../../../lib/user-group/user-group-multiselect.provider";
import {StringKey} from "../../../../../app.string-keys";

@Component({
  selector: 'app-task-rule-edit',
  templateUrl: './task-rule-edit.component.html',
  styleUrls: ['./task-rule-edit.component.scss']
})
export class TaskRuleEditComponent implements OnInit {

  RuleOperator = Workflow.RuleOperator;
  UiConstants = UiConstants;
  TaskRuleType = Workflow.TaskRuleType;

  @Input()
  model: WorkflowTransitionTaskRuleModel;

  @Input()
  operatorRequired: boolean = true;

  @Input()
  create: boolean = false;

  @Input()
  readonly: boolean = false;

  @Output()
  createSubmitted: EventEmitter<boolean> = new EventEmitter();

  @Output()
  deleteRule: EventEmitter<any> = new EventEmitter();

  commands: MultiselectOptionItem<Workflow.RuleCommand>[] = [];
  types: MultiselectOptionItem<Workflow.TaskRuleType>[] = [];
  users: MultiselectOptionItem<number>[] = [];
  userGroups: MultiselectOptionItem<number>[] = [];
  importances: MultiselectOptionItem<TaskRecord.TaskRecordImportance>[] = [];

  dropdownSettingsForCommand: Angular2Multiselects.Settings;
  dropdownSettingsForImportance: Angular2Multiselects.Settings;
  dropdownSettingsForLists: Angular2Multiselects.Settings;

  constructor(private translateService: TranslateService,
              private userMultiselectProvider: UserMultiselectProvider,
              private userGroupMultiselectProvider: UserGroupMultiselectProvider,
              private toasterService: ToasterService
  ) {
  }

  ngOnInit(): void {
    this.initDropDownSettings();
    this.loadImportances();
    this.loadTypes();
    this.setCommands();
  }

  private initDropDownSettings() {
    this.dropdownSettingsForCommand = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(false)
      .enableCheckAll(false)
      .remoteSearch(false)
      .translate(true)
      .build();
    this.dropdownSettingsForImportance = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(false)
      .enableCheckAll(true)
      .remoteSearch(false)
      .translate(true)
      .build();
    this.dropdownSettingsForLists = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .remoteSearch(true)
      .build();
  }

  loadUsers(q?: string) {
    this.userMultiselectProvider.loadActive(q).subscribe(u => {
      this.users = u;
    });
  }

  loadGroups(q?: string) {
    this.userGroupMultiselectProvider.loadActive(q).subscribe(u => {
      this.userGroups = u;
    });
  }

  loadImportances() {
    this.importances = TaskRecord.taskRecordImportances.map(i => ({
      id: i.importance,
      itemName: i.stringKey
    }))
  }

  loadTypes() {
    this.types = Workflow.taskRuleTypes.map(i => ({
      id: i.type,
      itemName: i.stringKey
    }))
  }

  getName(): string {
    let result: string = '';
    if (this.model.operator) {
      const operator = Workflow.ruleOperators.find(o => o.operator === this.model.operator)!;
      result = this.translateService.instant(operator.stringKey) + ' ';
    }
    result += this.model.getType() ? this.translateService.instant(this.model.type[0].itemName) + ' ' : '';
    result += this.model.getCommand() ? this.translateService.instant(this.model.getCommand()!.itemName) : '';
    result += this.model.getType() ? ':' : '';
    if (this.model.getType()) {
      switch (this.model.getType()) {
        case Workflow.TaskRuleType.ASSIGNEE_USER:
          if (this.model.getUserValues()) {
            result += '"' + this.model.getUserValues() + '"';
          }
          break;
        case Workflow.TaskRuleType.ASSIGNEE_USER_USER_GROUP:
        case Workflow.TaskRuleType.ASSIGNEE_USER_GROUP:
          if (this.model.getUserGroupValue()) {
            result += '"' + this.model.getUserGroupValue() + '"';
          }
          break;
        case Workflow.TaskRuleType.IMPORTANCE:
          result += '"' + this.model.getImportanceValue() + '"';
      }
    }
    return result;
  }

  setOperator(operator: Workflow.RuleOperator) {
    this.model.operator = operator;
  }

  onTypeChanged() {
    this.resetValues();
    this.setCommands();
  }

  private resetValues() {
    this.model.userValue = [];
    this.model.importanceValue = [];
    this.model.importanceValue = [];
    this.model.command = [];
  }

  setCommands() {
    const commands: Workflow.RuleCommand[] = [];
    switch (this.model.getType()) {
      case Workflow.TaskRuleType.ASSIGNEE_USER:
      case Workflow.TaskRuleType.ASSIGNEE_USER_GROUP:
      case Workflow.TaskRuleType.ASSIGNEE_USER_USER_GROUP:
      case Workflow.TaskRuleType.IMPORTANCE:
        commands.push(Workflow.RuleCommand.ANY_OF);
        commands.push(Workflow.RuleCommand.NONE_OF);
        break;
    }
    const result: MultiselectOptionItem<Workflow.RuleCommand>[] = [];
    commands.forEach(c => {
      const item = Workflow.ruleCommands.find(i => i.command === c)!;
      result.push({
        id: item.command,
        itemName: item.stringKey
      });
    });
    this.commands = result;
  }

  save() {
    if (this.operatorRequired && !this.model.operator) {
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutLong,
        type: UiConstants.toastTypeError,
        title: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_TITLE),
        body: this.translateService.instant(StringKey.WORKFLOW_EDIT_TRANSITION_FORM_FIELD_RULE_OPERATOR_REQUIRED)
      });
      return;
    }
    this.model.refreshName();
    if (this.create) {
      this.createSubmitted.emit(true);
    } else {
      this.model.editing = false;
    }
  }
}
