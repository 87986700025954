<form class="form-group">
  <label class="form-control-label text-break">{{ model.title }}</label>
  <table class="table table-striped table-bordered">
    <thead>
    <tr>
      <th class="w-10">
        <label>{{'COMMON_TABLE_HEADER_ID' | translate}}</label>
      </th>
      <th class="w-20">
        <label>{{'PROCESS_PACKAGE_CUSTOMER_RECORD_NAME' | translate}}</label>
      </th>
      <th class="w-20">
        <label>{{'PROCESS_PACKAGE_EXTERNAL_ID' | translate}}</label>
      </th>
      <th class="w-15">
        <label>{{'PROCESS_PACKAGE_TRACKING_NUMBER' | translate}}</label>
      </th>
      <th class="w-15">
        <label>{{'PROCESS_PACKAGE_CASH_ON_DELIVERY' | translate}}</label>
      </th>
      <th class="w-20">
        <label>{{'PROCESS_PACKAGE_RECIPIENT_NAME' | translate}}</label>
      </th>
    </tr>
    </thead>
    <tbody *ngIf="!hide()">
    <tr *ngFor="let order of model.orders">
      <td>{{order.order.orderId}}</td>
      <td>{{order.customerName}}</td>
      <td>{{order.order.externalId}}</td>
      <td>{{order.order.shipmentTrackingNumber}}</td>
      <td>{{order.order.cashOnDeliveryPrice.amount}} {{getCurrencyName(order.order.cashOnDeliveryPrice.currencyCode)}}</td>
      <td>{{order.order.recipient.name}}</td>
    </tr>
    </tbody>
    <thead>
    <tr>
      <th class="w-10">
        <label>{{'COMMON_TABLE_HEADER_ID' | translate}}</label>
      </th>
      <th class="w-20">
        <label>{{'PROCESS_PACKAGE_CUSTOMER_RECORD_NAME' | translate}}</label>
      </th>
      <th class="w-20">
        <label>{{'PROCESS_PACKAGE_EXTERNAL_ID' | translate}}</label>
      </th>
      <th class="w-15">
        <label>{{'PROCESS_PACKAGE_TRACKING_NUMBER' | translate}}</label>
      </th>
      <th class="w-15">
        <label>{{'PROCESS_PACKAGE_CASH_ON_DELIVERY' | translate}}</label>
      </th>
      <th class="w-20">
        <label>{{'PROCESS_PACKAGE_RECIPIENT_NAME' | translate}}</label>
      </th>
    </thead>
  </table>
</form>

