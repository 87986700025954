/* eslint-disable */
import { Component, OnDestroy, } from '@angular/core';
import {
  FormRecordFieldContext,
  FormRecordFieldValueUpdateArgs,
  FormRecordFieldView,
  FormRecordFieldViewContext,
  FormRecordFieldViewModel,
  SetTmpReadonlyArgs,
  SetTmpReadonlyResult
} from '../../../../../util/form/form-utils';
import { Form } from '../../../../../lib/form/form.service';
import { Command } from '../../../../../util/command';
import { FormRecord } from '../../../../../lib/form/form-record.service';
import {
  StockItemPictureResource,
  StockRecordFacade,
  StockRecordFacadeCategory,
  StockRecordService
} from '../../../../../lib/stock/stock-record.service';
import { StockService } from '../../../../../lib/stock/stock.service';
import { StockItemService, UnitPrice } from '../../../../../lib/stock/stock-item.service';
import { Decimal } from 'decimal.js';
import { InputMask } from '../../../../../util/input-masks';
import { FormRef, LocalFormGroupValidationErrors, QueryResult, ResourceQueryResult, Services } from '../../../../../lib/util/services';
import { FieldActivationState, FieldActivationStateResolver } from '../../../../../util/form/form-editors';
import { List } from 'immutable';
import { OptionItem, SelectUtils, UiConstants } from '../../../../../util/core-utils';
import { Currency, CurrencyService } from '../../../../../lib/currency.service';
import { Models } from '../../../../../util/model-utils';
import { TranslateService } from '@ngx-translate/core';
import { StringKey } from '../../../../../app.string-keys';
import { ToasterService } from '../../../../../fork/angular2-toaster/angular2-toaster';
import { FormRecordInactivityManager } from '../../manager/form-record-inactivity-manager';
import { DownloadedFile } from '../../../../../lib/util/downloaded-files';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { BadgeStyle } from '../../../../../shared/table-badge/badge-style';
import { MatDialog } from '@angular/material/dialog';
import {
  StockRecordSelectorDialogComponent,
  StockRecordSelectorDialogData,
  StockRecordSelectorDialogResult
} from '../../../../stock-record/stock-record-selector-dialog/stock-record-selector-dialog.component';
import { MatConfirmDialogComponent, MatConfirmDialogData } from '../../../../../shared/mat-confirm-dialog/mat-confirm-dialog.component';
import { FormRecordStockPercentValidatorService } from '../../../../../lib/form/form-record-stock-percent-validator.service';
import { AlertType } from '../../../../../shared/confirm-dialog/confirm-dialog.component';
import {
  FormRecordStockAmountValidatorService,
  StockRecordDataCollection
} from '../../../../../lib/form/form-record-stock-amount-validator.service';
import { Observable, Subscription } from 'rxjs';
import { DispersionPercentValidator } from './dispersion-percent-validator';
import { DispersionPercentChangedEvent } from './form-record-stock-field-item-edit/form-record-stock-field-item-edit.component';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { Strings } from '../../../../../lib/util/strings';
import { TaskRecordStateMachine } from '../../../../../lib/task/task-record-statemachine';
import { StockItemUtils } from '../../../../../util/stock/stock-item-utils';
import { StockTypeName } from '../../../../../util/stock/stock-utils';
import { FormRecordFieldUpdateCustomerLocationArgs } from '../master-data/form-record-master-data-field.component';
import { StockItemUnitOfMeasure } from '../../../../../lib/stock/stock-item-unit-of-measure';
import {
  StockItemUnitOfMeasureMultiselectOptionItem,
  StockItemUnitOfMeasureMultiselectProvider
} from '../../../../../lib/stock/stock-item-unit-of-measure-multiselect.provider';
import { Angular2Multiselects } from '../../../../../util/multiselect';
import StockItem = FormRecord.StockItem;
import {WeightFactory} from "../../../../../util/weight-utils";

/* eslint-enable */

@Component({
  selector: 'app-form-record-stock-field',
  templateUrl: 'form-record-stock-field.component.html',
  styleUrls: ['form-record-stock-field.component.scss'],
})
export class FormRecordStockFieldComponent implements FormRecordFieldView, OnDestroy {

  public readonly selector: Form.FieldDataTypeSelector.STOCK;

  SelectUtils = SelectUtils;
  StockItemUtils = StockItemUtils;

  formRecordFieldContext?: FormRecordFieldContext;
  formRecordInactivityManager?: FormRecordInactivityManager;

  model: Model = new Model();
  addModel?: StockItemModel;
  excludedIds: number[] = [];
  uniqueStockRecords: boolean = false;
  usableCategoryIds: number[] = [];
  exactCategoryMatch: boolean = false;
  fillAssigneeStock: boolean = false;
  defaultFilterAssignee: boolean = true;
  availableDiscounts: number[];
  availableDiscountsForDropdown: DropdownItem[] = [];
  readonlyDiscount?: string;
  netSumPrice: number = 0;
  grossSumPrice: number = 0;
  sumWeight: string = '';
  currencies: Currency.Currency[];
  sameCurrency: boolean = true;
  commentEnabled: boolean = false;
  commentRequired: boolean = false;
  commentCustomLabel: string | undefined = undefined;
  modifyStoredAmount: boolean = false;
  dispersionPercentEnabled: boolean = false;
  dispersionPercentValidator: DispersionPercentValidator = new DispersionPercentValidator();
  InputMask = InputMask;
  dropdownSettings: Angular2Multiselects.Settings = Angular2Multiselects.LOCAL_SINGLE_SELECT;
  BadgeStyle = BadgeStyle;

  hasDisabledItem: boolean = false;
  customerRecordId: number | undefined;
  contactLocationId: number | undefined;

  private assigneeUserId: number | undefined;
  private forceStockType?: StockTypeName;
  private forceStockIds?: number[];
  taskRecordState: TaskRecordStateMachine.State = 'NEW';
  private fieldId?: number;
  htmlForm?: FormRef;
  tmpReadonly: boolean = false;
  optionalValue: boolean = false;
  private formGroupValidationErrors: LocalFormGroupValidationErrors;

  private readonly amountValidatorSubscription: Subscription;
  private readonlyFormFn: () => boolean = () => true;
  private readonlyFieldFn: () => boolean = () => false;
  private hiddenFieldFn: () => boolean = () => false;

  get nonEditable(): boolean {
    return FieldActivationStateResolver.isNonEditable(
      this.fieldActivationState
    );
  }

  get requiredDisabled(): boolean {
    return FieldActivationStateResolver.isRequiredDisabled(
      this.fieldActivationState
    );
  }

  get required(): boolean {
    const optional = this.optionalValue;
    const requiredDisabled = this.requiredDisabled;
    return !optional && !requiredDisabled;
  }


  private get readonlyForm(): boolean {
    return this.readonlyFormFn();
  }

  private get fieldActivationState(): FieldActivationState {
    return FieldActivationStateResolver.resolveFieldActivationState({
      readonlyFormFn: () => this.readonlyFormFn(),
      readonlyFieldFn: () => this.readonlyFieldFn(),
      tmpReadonlyFieldFn: () => this.tmpReadonly,
    });
  }


  private get reqContext() {
    return this.formRecordFieldContext!;
  }

  setTmpReadonly(args: SetTmpReadonlyArgs): Command<SetTmpReadonlyResult> {
    const previousTmpReadonly = this.tmpReadonly;
    const previousValue = this.model;
    return {
      execute: async () => {
        let changed = false;
        if (this.tmpReadonly !== args.tmpReadonly) {
          if (args.tmpReadonly) {
            changed = FieldActivationStateResolver.inactivationChangesTheValue({
              debugId: this.reqContext.field.title,
              valueIsEmpty: !this.model.stocks || this.model.stocks.length === 0,
              valueEqualsDefaultValue: !this.model.stocks || this.model.stocks.length === 0,
              defaultValueIsEmpty: true,
              canApplyDefaultValue: false
            });
            this.model = new Model();
            this.tmpReadonly = args.tmpReadonly; // last
          }
          else {
            this.tmpReadonly = args.tmpReadonly; // first
            this.model = new Model();
          }
        }
        return {
          changed: changed
        };
      },
      undo: async () => {
        this.tmpReadonly = previousTmpReadonly;
        this.model = previousValue;
      }
    };
  }

  registerField(context: FormRecordFieldContext, originalModel?: any): any {
    if (originalModel) {
      this.model = originalModel;
    }
    this.formRecordFieldContext = context;
    this.fieldId = context.field.fieldId;
    this.htmlForm = context.htmlForm;
    this.readonlyFormFn = context.readonly;
    this.hiddenFieldFn = () => Form.FormFieldValidationType.HIDDEN === context.validationType;
    this.readonlyFieldFn = () => Form.FormFieldValidationType.READONLY === context.validationType
      || Form.FormFieldValidationType.HIDDEN === context.validationType;
    this.optionalValue = Form.FormFieldValidationType.REQUIRED !== context.validationType;
    if (context.field) {
      const stockAttributes = context.field.dataType.stockAttributes;
      if (stockAttributes) {
        this.usableCategoryIds = stockAttributes.usableStockItemCategories;
        this.exactCategoryMatch = stockAttributes.exactCategoryMatch;
        this.fillAssigneeStock = stockAttributes.fillAssigneeStock;
        this.defaultFilterAssignee = stockAttributes.defaultFilterAssignee;
        this.uniqueStockRecords = stockAttributes.uniqueStockRecords;
        this.dispersionPercentEnabled = stockAttributes.dispersionPercentEnabled;
        this.commentEnabled = stockAttributes.commentEnabled;
        this.commentRequired = stockAttributes.commentEnabled ? stockAttributes.commentRequired! : false;
        this.commentCustomLabel = stockAttributes.commentCustomLabel;
        this.modifyStoredAmount = stockAttributes.modifyStoredAmount;
        this.forceStockType = stockAttributes.sourceStockType;
        this.forceStockIds = stockAttributes.stockSourceFilters.length > 0 ? stockAttributes.stockSourceFilters : undefined;
      }
      this.loadDiscounts(context.field.dataType.stockAttributes);
    }
    if (context.fieldRecord) {
      this.registerFieldData(context.fieldRecord);
    }
    else {
      this.model.discountPercent = null;
      this.model.loaded = true;
    }
    this.loadCurrencies();
    return this.model;
  }

  registerFieldData(fieldRecord: FormRecord.FieldComposed): void {
    const attrs = fieldRecord.data.stockAttributes!;

    this.model.discountPercent = this.dispersionPercentEnabled
      ? null : attrs.discountPercent
        ? Models.decimalToNumber(attrs.discountPercent)! : null;
    this.loadItems(attrs);
  }

  loadItems(attrs: FormRecord.FieldDataStockAttributes) {
    const items: StockItemModel[] = [];
    const recordIds: number[] = [];
    attrs.stocks.forEach((item: FormRecord.StockItem) => {
      recordIds.push(item.stockRecordId);
      this.excludedIds.push(item.stockRecordId);
    });
    if (recordIds.length > 0) {
      this.stockRecordService.facadeQuery({
        stock_record_ids: recordIds.join(','),
        customer_record_id: this.customerRecordId
      }).subscribe((result: ResourceQueryResult<StockRecordFacade>) => {
        attrs.stocks.forEach((i) => {
          if (i && i.amount && i.amount.greaterThan(0.0)) {
            const rec = result.items.find((r) => r.stock_record_id === i.stockRecordId);
            if (rec) {
              items.push(this.createStockItemModelFromStockRecord(rec, i));
            }
          }
        });
        if (items.length > 0) {
          this.model.stocks = items;
        }
        this.setUpDiscountPercent();
        this.refreshHasDisabled();
        this.model.loaded = true;
        this.calculateSummary();
        this.updateDispersionPercent();
        this.notifyStockRecordAmountChanges();
      });
    } else {
      this.model.loaded = true;
    }
  }

  private createStockItemModelFromStockRecord(stockRecordFacade: StockRecordFacade, stockItem?: StockItem): StockItemModel {
    const stockItemModel: StockItemModel = new StockItemModel();
    stockItemModel.id = stockItem ? stockItem.id : undefined;
    stockItemModel.invoiced = stockItem ? stockItem.invoiced : undefined;
    stockItemModel.invoiceNumber = stockItem ? stockItem.invoiceNumber : undefined;
    stockItemModel.dispersionPercent = stockItem && stockItem.dispersionPercent ? stockItem.dispersionPercent : 0;
    stockItemModel.editing = false;
    stockItemModel.amount = this.dispersionPercentEnabled ?
      new Decimal(1) : stockItem ?
        stockItem.amount : new Decimal(1);
    stockItemModel.submitAmount = stockItem?.submitAmount;
    stockItemModel.submitBaseAmount = stockItem?.submitBaseAmount;
    stockItemModel.code = stockItem ? stockItem.code : undefined;
    stockItemModel.comment = stockItem ? stockItem.comment : undefined;
    stockItemModel.customNetPrice = stockItem ? Services.decimalToString(stockItem.customNetPrice) : undefined;
    stockItemModel.lastSavedStockItemNetPrice = stockItem ?
      Services.decimalToString(stockItem.lastSavedStockItemNetPrice)! : stockRecordFacade.stock_item.unit_price.value.toString();

    stockItemModel.stockRecord.id = stockRecordFacade.stock_record_id;
    stockItemModel.stockRecord.stock = stockRecordFacade.stock.stock_id;
    stockItemModel.stockRecord.stockName = stockRecordFacade.stock.name;
    stockItemModel.stockRecord.amount = stockRecordFacade.amount;

    stockItemModel.stockRecord.stockItem.id = stockRecordFacade.stock_item.stock_item_id;
    stockItemModel.stockRecord.stockItem.name = stockRecordFacade.stock_item.name;
    stockItemModel.stockRecord.stockItem.external_id = stockRecordFacade.stock_item.external_id;
    stockItemModel.stockRecord.stockItem.product_code = stockRecordFacade.stock_item.product_code;
    stockItemModel.stockRecord.stockItem.weight_in_grams = stockRecordFacade.stock_item.weight_in_grams;
    stockItemModel.stockRecord.stockItem.unit = stockRecordFacade.stock_item.unit;
    stockItemModel.stockRecord.stockItem.unit_price = stockRecordFacade.stock_item.unit_price;
    stockItemModel.stockRecord.stockItem.picture = stockRecordFacade.stock_item.picture;
    stockItemModel.stockRecord.stockItem.category_chains = stockRecordFacade.stock_item.category_chains;
    stockItemModel.stockRecord.stockItem.package_data = stockRecordFacade.stock_item.package_data;
    stockItemModel.stockRecord.stockItem.measurements = stockRecordFacade.stock_item.measurements!.map(m => {
      return this.unitOfMeasureMultiselectProvider.toMultiselectOptionItem(m)
    });

    stockItemModel.unitOfMeasure = stockItemModel.stockRecord.stockItem.measurements!
      .filter(m => stockItem ? (stockItem.unitOfMeasureId === m.id) : m.baseUnit);
    stockItemModel.disabledItem = stockRecordFacade.stock.disabled || stockRecordFacade.stock_item.disabled;
    stockItemModel.categoryId = this.calculateCategoryId(stockItemModel);
    if (stockRecordFacade.stock_item.picture && stockRecordFacade.stock_item.picture.content_hash) {
      this.loadStockItemImage(stockItemModel);
    }
    else if (stockItemModel.stockRecord.stockItem.picture) {
      stockItemModel.stockRecord.stockItem.picture!.image_loaded = false;
    }
    stockItemModel.service = stockRecordFacade.stock_item.type === 'SERVICE';
    return stockItemModel;
  }

  private setUpDiscountPercent() {
    if (this.nonEditable) {
      if (this.model.discountPercent === null) {
        if (this.model.stocks) {
          let customPriceFound = false;
          this.model.stocks.forEach((item) => {
            if (item.customNetPrice) {
              customPriceFound = true;
            }
          });
          this.readonlyDiscount = customPriceFound ? this.translateService.instant(StringKey.FORM_RECORD_STOCK_DISCOUNT_CUSTOM)
            : this.translateService.instant(StringKey.FORM_RECORD_STOCK_DISCOUNT_NONE);
        }
        else {
          this.readonlyDiscount = this.translateService.instant(StringKey.FORM_RECORD_STOCK_DISCOUNT_NONE);
        }
      }
      else {
        this.readonlyDiscount = this.model.discountPercent + '%';
      }
    }
  }

  private loadStockItemImage(stockItemModel: StockItemModel) {
    this.stockItemService.downloadPicture({
      id: stockItemModel.stockRecord.stockItem.id,
    }).subscribe((res: DownloadedFile) => {
        stockItemModel.stockRecord.stockItem.picture!.picture_src =
          this.sanitizer.bypassSecurityTrustStyle(`url(${URL.createObjectURL(res.getBlob())})`);
        stockItemModel.stockRecord.stockItem.picture!.image_loaded = true;
      },
      () => {
        stockItemModel.stockRecord.stockItem.picture!.image_loaded = false;
      }
    );
  }

  private refreshHasDisabled() {
    this.hasDisabledItem = false;
    this.model.stocks!.forEach((item) => {
      if (item.disabledItem) {
        this.hasDisabledItem = true;
      }
    });
  }

  private loadCurrencies() {
    this.currencyService.query({}).subscribe((result: QueryResult<Currency.Currency>) => {
      this.currencies = result.items.toArray();
    });
  }

  private loadDiscounts(attrs?: Form.FieldDataTypeStockAttributes) {
    this.availableDiscountsForDropdown = [];
    if (attrs && attrs.availableDiscounts) {
      this.availableDiscounts = attrs.availableDiscounts;
    }
    this.availableDiscountsForDropdown.push(this.loadCustomItem());
    this.availableDiscounts.forEach((d) => {
      this.availableDiscountsForDropdown.push({
        id: d,
        text: d + '%'
      });
    });
  }

  private loadCustomItem() {
    return {
      id: null,
      text: this.translateService.instant(StringKey.FORM_RECORD_STOCK_DISCOUNT_CUSTOM)
    };
  }

  private getCustomUnitPrice(stock: StockItemModel): Decimal | undefined {
    return stock.customNetPrice ? new Decimal(stock.customNetPrice.split(' ')[0]) :
      this.model.discountPercent ?
        (new Decimal(this.model.discountPercent).mul(-1).add(100).mul(stock.lastSavedStockItemNetPrice).dividedBy(100))
        : undefined;
  }

  getUnitPrice(stock: StockItemModel): Decimal {
    return Services.toDecimal(stock.lastSavedStockItemNetPrice)!;
  }

  getReducedNetPrice(stock: StockItemModel): string {
    const customUnitPrice = this.getCustomUnitPrice(stock);
    return customUnitPrice ? parseFloat(customUnitPrice.toString()).toFixed(2)
      : stock.lastSavedStockItemNetPrice;
  }

  getRecordNetPrice(stock: StockItemModel): string {
    const customUnitPrice = this.getCustomUnitPrice(stock);
    const unitPrice = this.getUnitPrice(stock);
    if (!stock.baseAmountDecimal) {
      return '0';
    }
    return customUnitPrice
      ? customUnitPrice.mul(stock.baseAmountDecimal).toFixed(2)
      : stock.baseAmountDecimal.mul(unitPrice).toFixed(2);
  }

  getRecordGrossPrice(stock: StockItemModel): string {
    const customUnitPrice = this.getCustomUnitPrice(stock);
    if (!stock.baseAmountDecimal) {
      return '0';
    }
    return customUnitPrice
      ? this.getRecordCustomGrossPrice(stock)!
      : this.getRecordDefaultGrossPrice(stock);
  }

  getRecordCustomGrossPrice(stock: StockItemModel): string | undefined {
    const customUnitPrice = this.getCustomUnitPrice(stock);
    const vat: number = this.getVatRate(stock);
    return stock.baseAmountDecimal && customUnitPrice ?
      parseFloat(customUnitPrice!.mul((100 + vat) / 100).mul(stock.baseAmountDecimal).toString()).toFixed(2) : undefined;
  }

  getRecordDefaultGrossPrice(stock: StockItemModel): string {
    const unitPrice = this.getUnitPrice(stock);
    const vat: number = this.getVatRate(stock);
    return stock.baseAmountDecimal ?
      parseFloat(stock.baseAmountDecimal.mul(unitPrice.mul((100 + vat) / 100)).toString()).toFixed(2) : '0';
  }

  getVatRate(stock: StockItemModel): number {
    return stock.stockRecord.stockItem.unit_price && stock.stockRecord.stockItem.unit_price.vat_rate
      ? stock.stockRecord.stockItem.unit_price.vat_rate
      : 0;
  }

  calculateSummary() {
    let net: number = 0;
    let gross: number = 0;
    let weight: number = 0;
    let sameCurrency = true;
    if (this.model.stocks && this.model.stocks.length > 0) {
      const currencyCode = this.model.stocks[0].stockRecord.stockItem.unit_price!.currency_code;
      this.model.stocks.forEach((stock) => {
        weight += stock.sumWeightInGrams;
        if (stock.stockRecord.stockItem.unit_price!.currency_code === currencyCode) {
          net += parseFloat(this.getRecordNetPrice(stock));
          gross += parseFloat(this.getRecordGrossPrice(stock));
        }
        else {
          sameCurrency = false;
        }
      });
      this.sameCurrency = sameCurrency;
    }
    this.netSumPrice = Math.round(net);
    this.grossSumPrice = Math.round(gross);
    this.sumWeight = WeightFactory.createWeightFromGram(weight, 'kg').toString();
  }

  getCurrencyCode(): string {
    let currencyCode: string = 'HUF';
    if (this.model.stocks && this.model.stocks.length > 0) {
      currencyCode = this.model.stocks[0].stockRecord.stockItem.unit_price!.currency_code;
    }
    return this.getCurrencyString(currencyCode);
  }

  getCurrencyString(currencyCode: string): string {
    const currency = this.currencies.find((c) => c.currencyCode === currencyCode);
    if (currency) {
      return currency.localizedName;
    }
    else {
      return '';
    }
  }

  resetCustomPrices() {
    this.model.stocks!.forEach((s) => {
      s.customNetPrice = undefined;
    });
    this.calculateSummary();
  }

  toggleEdit(index: number) {
    this.model.stocks![index].editing = !this.model.stocks![index].editing;
  }

  closeRow(index: number) {
    this.model.stocks![index].editing = false;
  }

  saveRecord(index: number, model: StockItemModel) {
    this.model.stocks![index] = model;
    this.excludedIds[index] = model.stockRecord.id;
    this.closeRow(index);
    this.calculateSummary();
  }

  private addNewRecords(result: StockRecordSelectorDialogResult) {
    if (!this.model.stocks) {
      this.model.stocks = [];
    }
    if (result.selectedStockRecords && result.selectedStockRecords.length > 0) {
      result.selectedStockRecords.forEach(s => {
        this.model.stocks!.push(this.createStockItemModelFromStockRecord(s.rawRecord));
        this.excludedIds.push(s.id);
      });
      this.setUpDiscountPercent();
      this.refreshHasDisabled();
      this.calculateSummary();
    }
    else if (result.selectedStockRecordIds && result.selectedStockRecordIds.length > 0) {
      this.stockRecordService.facadeQuery({
        stock_record_ids: result.selectedStockRecordIds.join(','),
        customer_record_id: this.customerRecordId
      }).subscribe((result: ResourceQueryResult<StockRecordFacade>) => {
        result.items.forEach((stockRecordFacade) => {
          if (stockRecordFacade) {
            this.model.stocks!.push(this.createStockItemModelFromStockRecord(stockRecordFacade));
            this.excludedIds.push(stockRecordFacade.stock_record_id);
          }
        });
        this.setUpDiscountPercent();
        this.refreshHasDisabled();
        this.calculateSummary();
        this.updateDispersionPercent();
        this.notifyStockRecordAmountChanges();
      });
    }
  }

  removeRecord(index: number) {
    const dialogConfig: MatConfirmDialogData = {
      titleKey: 'CONFIRM_DIALOG_TITLE_DELETE',
      messageKey: 'CONFIRM_DIALOG_MESSAGE_DELETE',
      alertType: AlertType.DANGER
    };
    const dialogRef = this.dialog.open(MatConfirmDialogComponent, {
      data: dialogConfig
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.closeRow(index);
        this.formRecordStockAmountValidatorService.removeStockRecordAmount(this.fieldId!, this.model.stocks![index].stockRecord.id);
        this.dispersionPercentValidator.removeItem(this.model.stocks![index].categoryId, this.model.stocks![index].stockRecord.id);
        this.model.stocks!.splice(index, 1);
        this.excludedIds.splice(index, 1);
        this.updateDispersionPercent();
        this.refreshHasDisabled();
        this.calculateSummary();
      }
    });
  }

  removeAllRecords() {
    const dialogConfig: MatConfirmDialogData = {
      titleKey: 'CONFIRM_DIALOG_TITLE_DELETE',
      messageKey: 'CONFIRM_DIALOG_MESSAGE_DELETE_ALL',
      alertType: AlertType.DANGER
    };
    const dialogRef = this.dialog.open(MatConfirmDialogComponent, {
      data: dialogConfig
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.model.stocks!.forEach(s => {
          this.formRecordStockAmountValidatorService.removeStockRecordAmount(this.fieldId!, s.stockRecord.id);
          this.dispersionPercentValidator.removeItem(s.categoryId, s.stockRecord.id);
        });
        this.model.stocks!.splice(0, this.model.stocks!.length);
        this.excludedIds.splice(0, this.excludedIds.length);
        this.updateDispersionPercent();
        this.refreshHasDisabled();
        this.calculateSummary();
      }
    });
  }

  registerFieldViews(context: FormRecordFieldViewContext): void {
    this.formRecordInactivityManager = context.inactivityManager;
  }

  hasLocalFieldError(): boolean {

    // Check stockAtributes disabled Items

    let hasDisabledItem = false;
    if (this.model.stocks) {
      this.model.stocks.forEach((value) => {
        if (value.disabledItem) {
          hasDisabledItem = true;
        }
      });
    }

    if (hasDisabledItem) {
      return true;
    }
    return false;


  }

  validateWithInterrupt(): boolean {
    if (!this.sameCurrency) {
      this.showDifferentCurrencyErrorToast();
      return true;
    }
    else if (this.dispersionPercentEnabled && !this.dispersionPercentValidator.isValid()) {
      this.showDispersionPercentErrorToast();
      return true;
    }
    else if (!this.dispersionPercentEnabled) {
      if (this.model.stocks ? this.model.stocks.filter(s => !s.validAmount && !s.service).length > 0 : false) {
        this.formRecordStockAmountValidatorService.showAmountSumErrorToast(this.fieldId!);
        return true;
      }
    }
    return false;
  }

  shouldNotifyAfterCreation(): boolean {
    return false;
  }

  afterFormRecordCreation(formRecordId: number): Observable<any> | undefined {
    return undefined;
  }

  createModel(): FormRecordFieldViewModel {
    if (this.fieldId === undefined) {
      throw new Error('Field ID is undefined');
    }
    let attrs: FormRecord.FieldDataStockAttributes;
    if (!this.model.loaded && this.formRecordFieldContext?.fieldRecord?.data) {
      attrs = this.formRecordFieldContext.fieldRecord.data.stockAttributes!;
    } else {
      attrs = {
        discountPercent: this.model.discountPercent !== null ? new Decimal(this.model.discountPercent!) : undefined,
        stocks: List.of(...this.model.stocks!.map((s): FormRecord.StockItem => {
          return {
            id: s.id,
            stockRecordId: s.stockRecord.id,
            customNetPrice: s.customNetPrice && this.model.discountPercent === null ? new Decimal(s.customNetPrice.split(' ')[0]) : undefined,
            lastSavedStockItemNetPrice: new Decimal(s.lastSavedStockItemNetPrice),
            amount: s.amount ? s.amount : new Decimal(0),
            unitOfMeasureId: s.unitOfMeasureId,
            code: s.code,
            dispersionPercent: this.dispersionPercentEnabled ? s.dispersionPercent : undefined,
            comment: this.commentEnabled ? Strings.undefinedOrNonEmpty(s.comment) : undefined
          };
        }))
      };
    }
    return {
      fieldEditRequest: {
        fieldId: this.fieldId,
        data: {
          stockAttributes: attrs
        }
      }
    };
  }

  getTitle(): string {
    if (this.formRecordFieldContext && this.formRecordFieldContext.field) {
      return this.formRecordFieldContext.field.title;
    }
    else {
      this.translateService.get('FORM_RECORD_STOCK_TITLE').subscribe(
        (result: string) => {
          return result;
        }
      );
    }
    return '';
  }

  private showDifferentCurrencyErrorToast() {
    this.toasterService.pop({
      timeout: UiConstants.ToastTimeoutLong,
      type: UiConstants.toastTypeError,
      title: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_TITLE),
      body: this.translateService.instant(StringKey.FORM_RECORD_STOCK_CURRENCY_ERROR)
    });
  }

  private showDispersionPercentErrorToast() {
    this.toasterService.pop({
      timeout: UiConstants.ToastTimeoutLong,
      type: UiConstants.toastTypeError,
      title: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_TITLE),
      body: this.translateService.instant(StringKey.FORM_RECORD_STOCK_DISPERSION_PERCENT_ERROR)
    });
  }

  updateValue(data: FormRecordFieldValueUpdateArgs) {
    if (data instanceof FormRecordFieldUpdateCustomerLocationArgs) {
      if ((<FormRecordFieldUpdateCustomerLocationArgs>data).customerRecordId !== null) {
        this.customerRecordId = (<FormRecordFieldUpdateCustomerLocationArgs>data).customerRecordId!;
      }
      this.contactLocationId = (<FormRecordFieldUpdateCustomerLocationArgs>data).contactLocationId;
    }
    if (data instanceof FormRecordStockFieldUserUpdateArgs) {
      const d = (<FormRecordStockFieldUserUpdateArgs>data);
      this.assigneeUserId = d.assigneeUserId;
      this.taskRecordState = d.taskRecordState ? d.taskRecordState : 'NEW';
    }
    this.refreshUnitPrices();
  }

  onDispersionPercentChanged(item: StockItemModel) {
    this.dispersionPercentValidator.updateItem(item.categoryId, item.stockRecord.id, item.dispersionPercent ? item.dispersionPercent : 0);
    this.updateDispersionPercentValidityInCategory(item.categoryId, this.dispersionPercentValidator.isCategoryValid(item.categoryId));
  }

  onDispersionPercentChangedInEditor(event: DispersionPercentChangedEvent) {
    this.updateDispersionPercentValidityInCategory(event.categoryId, event.valid);
  }

  private refreshUnitPrices() {
    if (this.model.stocks && this.model.stocks.length > 0) {
      const stockRecordIds = this.model.stocks.map(s => s.stockRecord.id);
      this.stockRecordService.facadeQuery({
        stock_record_ids: stockRecordIds.join(','),
        customer_record_id: this.customerRecordId
      }).subscribe((result: ResourceQueryResult<StockRecordFacade>) => {
        this.model.stocks!.forEach(s => {
          const stockRecord = result.items.find(sr => sr.stock_record_id === s.stockRecord.id);
          s.stockRecord.stockItem.unit_price = stockRecord!.stock_item.unit_price;
        });
      });
    }
  }

  isPriceChanged(item: StockItemModel): boolean {
    return parseFloat(item.lastSavedStockItemNetPrice) !== item.stockRecord.stockItem.unit_price.value;
  }

  isAnyPriceChanged(): boolean {
    if (this.model.stocks && this.model.stocks.length > 0) {
      let result: boolean = false;
      for (const item of this.model.stocks) {
        if (this.isPriceChanged(item) && !item.invoiced) {
          result = true;
          break;
        }
      }
      return result;
    }
    return false;
  }

  acceptNewPrice(item: StockItemModel) {
    item.lastSavedStockItemNetPrice = item.stockRecord.stockItem.unit_price.value.toFixed(2);
    this.calculateSummary();
  }

  acceptAllNewPrices() {
    if (this.model.stocks && this.model.stocks.length > 0) {
      for (const item of this.model.stocks) {
        this.acceptNewPrice(item);
      }
    }
  }

  openStockRecordSelectorDialog() {
    const data: StockRecordSelectorDialogData = {
      customerRecordId: this.customerRecordId,
      ownerCustomerRecordId: this.forceStockType === 'CUSTOMER' ? this.customerRecordId : undefined,
      ownerContactLocationId: this.forceStockType === 'CUSTOMER' ? this.contactLocationId : undefined,
      assigneeUserId: (this.taskRecordState === 'NEW' && this.fillAssigneeStock) || !this.defaultFilterAssignee
        ? undefined
        : this.assigneeUserId,
      forceStockType: this.forceStockType,
      forceStockIds: this.forceStockIds,
      usableStockItemCategoryIds: this.usableCategoryIds,
      excludedStockRecordIds: this.uniqueStockRecords ? this.excludedIds : [],
      exactCategoryMatch: this.exactCategoryMatch,
    };
    const dialogRef = this.dialog.open(StockRecordSelectorDialogComponent, {
      panelClass: 'custom-dialog',
      closeOnNavigation: true,
      data: data
    });

    dialogRef.afterClosed().subscribe((result: StockRecordSelectorDialogResult) => {
      if (result) {
        this.addNewRecords(result);
      }
    });
  }

  constructor(private stockRecordService: StockRecordService,
              private unitOfMeasureMultiselectProvider: StockItemUnitOfMeasureMultiselectProvider,
              private stockService: StockService,
              private dialog: MatDialog,
              private toasterService: ToasterService,
              private translateService: TranslateService,
              private sanitizer: DomSanitizer,
              private stockItemService: StockItemService,
              private currencyService: CurrencyService,
              private formRecordStockPercentValidatorService: FormRecordStockPercentValidatorService,
              private formRecordStockAmountValidatorService: FormRecordStockAmountValidatorService) {
    this.amountValidatorSubscription = formRecordStockAmountValidatorService.subscribe(n => this.onRecordDataChanged(n));
  }

  hasAnyInvoicedItem() {
    if (this.model.stocks) {
      for (let i = 0; i < this.model.stocks.length; i++) {
        if (this.model.stocks[i].invoiced) {
          return true;
        }
      }
    }
    return false;
  }

  private validateDispersionPercentSum(): boolean {
    if (this.model.stocks) {
      let sum = 0;
      this.model.stocks.forEach(stock => {
        if (stock.dispersionPercent) {
          sum += stock.dispersionPercent;
        }
      });
      return sum === 0 || sum === 100;
    }
    return true;
  }

  private updateDispersionPercent() {
    if (this.model.stocks) {
      this.model.stocks.forEach(stock => {
        this.dispersionPercentValidator
          .updateItem(stock.categoryId, stock.stockRecord.id, stock.dispersionPercent ? stock.dispersionPercent : 0);
      });
      this.model.stocks.forEach(stock => {
        stock.validDispersionPercent = !stock.hasInvalidPercentage() && this.dispersionPercentValidator.isCategoryValid(stock.categoryId);
      });
    }
  }

  private updateDispersionPercentValidityInCategory(categoryId: number, valid: boolean) {
    if (this.model.stocks) {
      this.model.stocks.forEach(stock => {
        if (stock.categoryId === categoryId) {
          stock.validDispersionPercent = !stock.hasInvalidPercentage() && valid;
        }
      });
    }
  }

  notifyStockRecordAmountChange(model: StockItemModel) {
    this.formRecordStockAmountValidatorService.updateStockRecordAmount(
      this.fieldId!,
      model.stockRecord.id,
      model.baseAmount ? model.baseAmount : 0,
      model.stockRecord.amount ? model.stockRecord.amount : 0,
      model.submitBaseAmount?.toNumber());
    this.calculateSummary();
  }

  private notifyStockRecordAmountChanges() {
    if (this.model.stocks) {
      this.model.stocks.forEach(stock => {
        this.notifyStockRecordAmountChange(stock);
      });
    }
  }

  onRecordDataChanged(stockRecordDataCollection: StockRecordDataCollection) {
    if (this.model.stocks) {
      this.model.stocks.forEach(stock => {
        if (stock.stockRecord.id === stockRecordDataCollection.stockRecordId) {
          if (!this.modifyStoredAmount || this.nonEditable) {
            stock.validAmount = true;
          }
          else {
            stock.validAmount =
              (stock.submitBaseAmount && stock.baseAmount && stock.submitBaseAmount.eq(stock.baseAmount)) ||
              this.formRecordStockAmountValidatorService.validateDataCollection(stockRecordDataCollection);
          }
        }
      })
    }
  }

  calculateCategoryId(model: StockItemModel): number {
    if (model.stockRecord.stockItem.category_chains) {
      if (model.stockRecord.stockItem.category_chains.length === 1) {
        const lastItemIndex = model.stockRecord.stockItem.category_chains[0].length - 1;
        return model.stockRecord.stockItem.category_chains[0][lastItemIndex].id;
      }
    }
    return -1; // -1 represents 'other' category
  }

  shouldDisplayDispersionPercentValidationMessage(): boolean {
    if (this.model.stocks) {
      for (let i = 0; i < this.model.stocks.length; i++) {
        if (!this.model.stocks[i].validDispersionPercent) {
          return true;
        }
      }
    }
    return false;
  }

  onCustomPriceChanged(popover: PopoverDirective) {
    popover.hide();
    this.calculateSummary();
  }

  ngOnDestroy(): void {
    this.formRecordStockAmountValidatorService.unsubscribe(this.amountValidatorSubscription);
  }

  protected readonly undefined = undefined;
}

export class Model {
  loaded: boolean = false;
  stocks?: StockItemModel[] = [];
  discountPercent: number | null = null;

  getDispersionPercent(): number {
    return this.stocks ? this.stocks
        .map(s => s.dispersionPercent)
        .filter(s => s !== undefined)
        .reduce((sum, current) => sum! + current!, 0)!
      : 0;
  }
}

export class StockItemModel {
  id?: number;
  amount?: Decimal = undefined;
  submitAmount?: Decimal = undefined;

  submitBaseAmount?: Decimal = undefined;
  unitOfMeasure: StockItemUnitOfMeasureMultiselectOptionItem[] = [];
  customNetPrice?: string = undefined;
  lastSavedStockItemNetPrice: string = '';
  stockRecord: StockRecordModel = new StockRecordModel();
  editing: boolean = false;
  disabledItem: boolean = false;
  code?: string = undefined;
  invoiced?: boolean;
  invoiceNumber?: string;
  dispersionPercent?: number;
  comment?: string;
  validAmount: boolean = true;
  validDispersionPercent: boolean = true;
  categoryId: number = -1; // Note: -1 represents the 'other' category
  service: boolean = false;

  // this is a helper field for UI, because number picker does not handle decimal.js very well
  private _numberAmount: number | undefined = undefined;

  get numberAmount(): number | undefined {
    return this.amount ? new Decimal(this.amount).toNumber() : undefined;
  }

  set numberAmount(value: number | undefined) {
    this.amount = value ? new Decimal(value) : undefined;
    this._numberAmount = value;
  }

  hasInvalidPercentage(): boolean {
    if (this.dispersionPercent !== undefined) {
      return this.dispersionPercent > 100 || this.dispersionPercent < 0;
    }
    return false;
  }

  get unitOfMeasureId(): number {
    return this.selectedUnitOfMeasure?.id!;
  }

  get selectedUnitOfMeasure(): StockItemUnitOfMeasureMultiselectOptionItem | undefined {
    return this.unitOfMeasure.length > 0 ? this.unitOfMeasure[0] : undefined;
  }

  isBaseUnitSelected(): boolean {
    if (!this.selectedUnitOfMeasure) {
      return true;
    }
    return this.selectedUnitOfMeasure.baseUnit;
  }

  get baseAmount(): number | undefined {
    if (!this.numberAmount || !this.selectedUnitOfMeasure) {
      return undefined;
    }
    return this.numberAmount * this.selectedUnitOfMeasure.conversion;
  }

  get baseAmountDecimal(): Decimal | undefined {
    if (!this.baseAmount) {
      return undefined;
    }
    return new Decimal(this.baseAmount);
  }

  get sumWeight(): string {
    if (!this.stockRecord.stockItem.weight_in_grams) {
      return '';
    }
    if (!this.baseAmount) {
      return ''
    }
    const grams = this.stockRecord.stockItem.weight_in_grams * this.baseAmount;
    return WeightFactory.createWeightFromGram(grams, 'kg').toString();
  }

  get sumWeightInGrams(): number {
    if (!this.stockRecord.stockItem.weight_in_grams) {
      return 0;
    }
    if (!this.baseAmount) {
      return 0;
    }
    return this.stockRecord.stockItem.weight_in_grams * this.baseAmount;
  }
}

export class StockRecordModel {
  id: number;
  amount?: number = undefined;
  stock?: number = undefined;
  stockItem: StockItemFormModel = new StockItemFormModel();
  stockName: string = '';
}

export class StockItemFormModel {
  id: number;
  name?: string;
  external_id?: string;
  product_code?: string;
  weight_in_grams?: number;
  unit?: string;
  unit_price: UnitPrice;
  picture?: StockItemFormModelPicture;
  category_chains?: StockRecordFacadeCategory[][];
  package_data?: StockItemUnitOfMeasure.StockItemUnitOfMeasure;
  measurements?: StockItemUnitOfMeasureMultiselectOptionItem[];


  get baseUnit(): StockItemUnitOfMeasureMultiselectOptionItem | undefined {
    return this.measurements?.find(m => m.baseUnit);
  }
}

interface DropdownItem extends OptionItem<number> {
}

interface StockItemFormModelPicture extends StockItemPictureResource {
  image_loaded?: boolean;
  picture_src?: SafeStyle;
}

export class FormRecordStockFieldUserUpdateArgs implements FormRecordFieldValueUpdateArgs {

  constructor(public assigneeUserId?: number,
              public taskRecordState?: TaskRecordStateMachine.State) {

  }

}
