<div bsModal [config]="UiConstants.modalConfig" #triggerPreselectDialog="bs-modal" class="modal" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document" *ngIf="triggerPreselectDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title"><strong>{{'TRIGGER_CREATE' | translate}}</strong></p>
        <button type="button" class="close" (click)="closeTriggerPreselectDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row justify-content-center m-0">
          <ng-container *ngFor="let triggerType of TriggerUtils.triggerTypes">
            <div class="trigger-type-container col-md-4 col-sm-6 col-xs-12"
                 *ngIf="triggerType.scope.contains(scope)">
              <div class="create-card card card-hover-border cursor-pointer"
                   (click)="openTriggerEditDialog(triggerType)">
                <div class="card-body">
                  <div class="h1 mb-0 d-flex justify-content-center">
                    <i class="icomoon" [ngClass]="triggerType.iconClass"></i>
                  </div>
                  <div><p class="mb-0 text-center">{{triggerType.stringKey | translate}}</p></div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div><!-- /.modal-body -->
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
