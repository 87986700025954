<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
    <div class="breadcrumb-menu d-flex">
      <div *ngIf="rightModel.invoiceSpendingCreate.hasRight()"
           class="breadcrumb-button-icon-container cursor-pointer negative-margin-right"
           (click)="openInvoiceSpendingCreateDialog()">
        <a class="btn-setting" title="{{'INVOICE_SPENDING_CREATE' | translate}}">
          <i class="icomoon icomoon-add"></i>
          <span class="breadcrumb-button-text-visible">{{'INVOICE_SPENDING_CREATE' | translate}}</span>
        </a>
      </div>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <app-invoice-balance-detail *ngIf="rightModel.invoiceBalanceRead.hasRight()">
  </app-invoice-balance-detail>
  <div class="container-vertical-padding-half"></div>

  <div class="card">
    <div class="card-header">
      {{'MENU_NAVBAR_NAV_TITLE_INVOICE_SPENDING' | translate}}

      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()" title="{{'COMMON_BUTTON_SEARCH' | translate}}"><i
          class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>

    <div class="card-body border-bottom" *ngIf="showSearch">
      <div class="row form-group">
        <div class="col-md-3">
          <label class="search-label">{{'COMMON_TABLE_HEADER_ID' | translate}}</label>
          <input type="number" class="form-control"
                 placeholder="{{'COMMON_TABLE_HEADER_ID' | translate}}"
                 maxlength="{{UiConstants.maxInputNumberLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.id">
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'INVOICE_SPENDING_SPENDING_NUMBER' | translate}}</label>
          <input type="text" class="form-control"
                 placeholder="{{'INVOICE_SPENDING_SPENDING_NUMBER' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.spendingNumber">
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'INVOICE_SPENDING_EVENT_NAME' | translate}}</label>
          <input type="text" class="form-control"
                 placeholder="{{'INVOICE_SPENDING_EVENT_NAME' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.eventName">
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'INVOICE_SPENDING_CREATION_TIME_FROM' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control date"
                   placeholder="{{'INVOICE_SPENDING_CREATION_TIME_FROM' | translate}}"
                   [(ngModel)]="searchModel.creationTimeFrom" ngbDatepicker #creation_time_from="ngbDatepicker">
            <div class="input-group-addon-gray" (click)="creation_time_from.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <label class="search-label">{{'INVOICE_SPENDING_CREATION_TIME_TO' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control date"
                   placeholder="{{'INVOICE_SPENDING_CREATION_TIME_TO' | translate}}"
                   [(ngModel)]="searchModel.creationTimeTo" ngbDatepicker #creation_time_to="ngbDatepicker">
            <div class="input-group-addon-gray" (click)="creation_time_to.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <label class="search-label"> {{ 'INVOICE_SPENDING_SPENDING_TYPE' | translate }} </label>
          <select
            class="form-control"
            [(ngModel)]="searchModel.invoiceSpendingType" [compareWith]="SelectUtils.compareObjects">
            <option *ngFor="let item of spendingTypes" [ngValue]="item">{{item.stringKey | translate}}</option>
          </select>
        </div>
      </div>
      <div class="col-md-12 d-flex justify-content-md-end align-items-end px-0">
        <div class="btn-group" role="group">
          <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
            {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
          </button>
          <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
            {{'COMMON_BUTTON_SEARCH' | translate}}
          </button>
        </div>
      </div>
    </div><!-- /.search card-->

    <div class="card-body">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="InvoiceSpendingSearch.OrderFunctions.ID"
                                    [orderType]="queryModel.getOrderType(InvoiceSpendingSearch.OrderFunctions.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="InvoiceSpendingSearch.OrderFunctions.SPENDING_NUMBER"
                                    [orderType]="queryModel.getOrderType(InvoiceSpendingSearch.OrderFunctions.SPENDING_NUMBER)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'INVOICE_SPENDING_SPENDING_NUMBER' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-sorter-no-op [text]="'INVOICES_TABLE_HEADER_INVOICE_NUMBER' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-field-sorter [orderField]="InvoiceSpendingSearch.OrderFunctions.EVENT_NAME"
                                    [orderType]="queryModel.getOrderType(InvoiceSpendingSearch.OrderFunctions.EVENT_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'INVOICE_SPENDING_EVENT_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-field-sorter [orderField]="InvoiceSpendingSearch.OrderFunctions.CREATION_TIME"
                                    [orderType]="queryModel.getOrderType(InvoiceSpendingSearch.OrderFunctions.CREATION_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'INVOICE_SPENDING_CREATION_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-field-sorter [orderField]="InvoiceSpendingSearch.OrderFunctions.AMOUNT"
                                    [orderType]="queryModel.getOrderType(InvoiceSpendingSearch.OrderFunctions.AMOUNT)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'INVOICE_SPENDING_AMOUNT' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-sorter-no-op [text]="'INVOICE_SPENDING_SPENDING_TYPE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let invoiceSpending of invoiceSpendingList | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }">
          <td class="fit align-middle">{{invoiceSpending.id}}</td>
          <td class="responsive-table-column long align-middle">
            <div class="d-flex justify-content-start align-items-center">
              <div>
                <a class="id-text cursor-pointer"
                   (click)="openInvoiceSpendingDetailDialog(invoiceSpending.id)"
                   title="{{'COMMON_BUTTON_DETAILS' | translate}}">
                  {{invoiceSpending.spendingNumber}}
                </a>
              </div>
            </div>
          </td>
          <td class="responsive-table-column hidden-md-down align-middle">
            <div *ngFor="let invoice of invoiceSpending.invoices">
                              <a class="id-text cursor-pointer mr-05"
                                 href="#"
                                 uiSref="Admin.InvoiceDetail"
                                 [uiParams]="{ id: invoice.id }"
                                 title="{{'COMMON_BUTTON_DETAILS' | translate}}">
                  {{invoice.invoiceNumber}}
                </a>
            </div>
          </td>
          <td class="responsive-table-column hidden-lg-down align-middle">
            {{invoiceSpending.eventName}}
          </td>
          <td class="fit hidden-md-down align-middle">
              <span class="text-truncate">{{invoiceSpending.creationTime.toUtcIsoString() |
                date:'short'}}</span>
            <div class="table-text-subtitle">
              {{invoiceSpending.creatorUser.personName}}
            </div>
          </td>
          <td class="fit hidden-xs-down align-middle">
            <span
              class="text-truncate">{{invoiceSpending.amount | formattedNumber}} {{getCurrencyName(invoiceSpending.currencyCode)}}</span>

          </td>
          <td class="fit hidden-xs-down align-middle">
            <app-table-badge
              *ngIf="invoiceSpending.invoiceSpendingTypeObject"
              [badgeStyle]="invoiceSpending.invoiceSpendingTypeObject.badgeStyle"
              [iconClass]="invoiceSpending.invoiceSpendingTypeObject.iconClass"
              [text]="invoiceSpending.invoiceSpendingTypeObject.stringKey | translate">
            </app-table-badge>
          </td>
          <td>
            <app-table-options-menu>
              <app-dropdown-item
                [iconClass]="'icomoon-detail'"
                [titleStringKey]="'COMMON_BUTTON_DETAILS'"
                class="cursor-pointer"
                (click)="openInvoiceSpendingDetailDialog(invoiceSpending.id)">
              </app-dropdown-item>
            </app-table-options-menu>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="InvoiceSpendingSearch.OrderFunctions.ID"
                                    [orderType]="queryModel.getOrderType(InvoiceSpendingSearch.OrderFunctions.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="InvoiceSpendingSearch.OrderFunctions.SPENDING_NUMBER"
                                    [orderType]="queryModel.getOrderType(InvoiceSpendingSearch.OrderFunctions.SPENDING_NUMBER)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'INVOICE_SPENDING_SPENDING_NUMBER' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-sorter-no-op [text]="'INVOICES_TABLE_HEADER_INVOICE_NUMBER' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-field-sorter [orderField]="InvoiceSpendingSearch.OrderFunctions.EVENT_NAME"
                                    [orderType]="queryModel.getOrderType(InvoiceSpendingSearch.OrderFunctions.EVENT_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'INVOICE_SPENDING_EVENT_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-field-sorter [orderField]="InvoiceSpendingSearch.OrderFunctions.CREATION_TIME"
                                    [orderType]="queryModel.getOrderType(InvoiceSpendingSearch.OrderFunctions.CREATION_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'INVOICE_SPENDING_CREATION_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-field-sorter [orderField]="InvoiceSpendingSearch.OrderFunctions.AMOUNT"
                                    [orderType]="queryModel.getOrderType(InvoiceSpendingSearch.OrderFunctions.AMOUNT)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'INVOICE_SPENDING_AMOUNT' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-sorter-no-op [text]="'INVOICE_SPENDING_SPENDING_TYPE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>

  </div>

</div>

