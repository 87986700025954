/* eslint-disable */
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { InputMask } from '../../../../util/input-masks';
import { InvoiceBook, InvoiceBookService } from '../../../../lib/invoice/invoice-book/invoice-book.service';
import { NgForm } from '@angular/forms';
import { FieldValidationError } from '../../../../lib/util/services';
import { Transition, UIRouter } from '@uirouter/angular';
import { StateName } from '../../../../app.state-names';
import { Angular2Multiselects } from '../../../../util/multiselect';
import { UiConstants } from '../../../../util/core-utils';
import { TranslateService } from '@ngx-translate/core';
import { RightModel } from '../../../../app.rights';
import { RightResolver, RightService } from '../../../../lib/right.service';
import { BreadcrumbParent } from '../../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
/* eslint-enable */

@Component({
  selector: 'app-invoice-book-edit',
  templateUrl: './invoice-book-edit.component.html',
  styleUrls: ['./invoice-book-edit.component.scss']
})
export class InvoiceBookEditComponent implements OnInit, AfterViewInit {

  InputMask = InputMask;
  InvoiceBook = InvoiceBook;
  UiConstants = UiConstants;

  dropdownSettings: Angular2Multiselects.Settings;

  @ViewChild('f', { static: true })
  fForm: NgForm;

  model: InvoiceBookEditModel = new InvoiceBookEditModel();

  invoiceBookId: number;

  private fieldErrors: FieldValidationError<InvoiceBook.ValidatedField> =
    FieldValidationError.empty<InvoiceBook.ValidatedField>();

  rightModel: RightModel = RightModel.empty();
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  constructor(
    private invoiceBookService: InvoiceBookService,
    private uiRouter: UIRouter,
    private transition: Transition,
    private translateService: TranslateService,
    private rightService: RightService) {
    this.invoiceBookId = Number(this.transition.params().id);
  }

  ngOnInit() {
    this.breadcrumbSelf = this.invoiceBookId.toString();
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_INVOICE_BOOK').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.INVOICE_BOOK_LIST});
      }
    );
  }

  ngAfterViewInit() {
    this.loadRightModels();
    this.loadModel();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  update() {
    this.invoiceBookService.update({
      invoiceBookId: this.invoiceBookId,
      externalId: this.model.externalId,
      comment: this.model.comment
    }).subscribe(
      (response: InvoiceBook.InvoiceBook) => {
        this.uiRouter.stateService.go(StateName.INVOICE_BOOK_LIST);
      },
      (error: any) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withForm(this.fForm);
        }
      });
  }

  back() {
    window.history.back();
  }

  private loadModel() {
    this.invoiceBookService.get({
      id: this.invoiceBookId
    }).subscribe(
      (invoiceBook: InvoiceBook.InvoiceBook) => {
        this.model.comment = invoiceBook.comment;
        this.model.externalId = invoiceBook.externalId;
        this.model.invoiceSettings = invoiceBook.invoiceSettings.name;
      });
  }

  hasFieldError(field: InvoiceBook.ValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: InvoiceBook.ValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: InvoiceBook.ValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

}

export class InvoiceBookEditModel {
  comment: string = '';
  externalId: string = '';
  invoiceSettings: string = '';
}
