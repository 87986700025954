/* eslint-disable */
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Transition, UIRouter } from '@uirouter/angular';
import { StateName } from '../../../app.state-names';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { TranslateService } from '@ngx-translate/core';
import { Address, AddressModel } from '../../../lib/address';
import { InputMask } from '../../../util/input-masks';
import { NgForm, NgModel } from '@angular/forms';
import { List } from 'immutable';
import {
  FieldValidationError,
  LocalFieldValidationErrors,
  LocalFieldValidationErrorsFactory,
  QueryResult
} from '../../../lib/util/services';
import { Country, CountryService } from '../../../lib/country.service';
import { Strings } from '../../../lib/util/strings';
import { StringKey } from '../../../app.string-keys';
import { MultiselectOptionItem, SelectUtils, UiConstants } from '../../../util/core-utils';
import { ConfigurationService } from '../../../lib/core-ext/configuration.service';
import { RoleService } from '../../../lib/role/role.service';
import { RightResolver, RightService } from '../../../lib/right.service';
import { RightModel } from '../../../app.rights';
import { ComponentStateResolver } from '../../../util/component-state/component-state-resolver';
import { EmptyMessage, IdentityMessage } from '../../../lib/util/messages';
import {
  ParcelCollectionPoint,
  ParcelCollectionPointService
} from '../../../lib/parcel-collection-point/parcel-collection-point.service';
import { ParcelCollectionPointDetailModel, ParcelCollectionPointEditModel } from '../parcel-collection-point.model';
import Decimal from 'decimal.js';
import { GoogleMapsLoaderUtil } from '../../../util/google/google-maps-loader.util';
import { Angular2Multiselects } from '../../../util/multiselect';
import { DeliveryMethodMultiselectProvider } from '../../../lib/delivery-method/delivery-method-multiselect.provider';
import { CompanyDetailModel, CompanyEditModel } from '../../company/company-base/company-base.component';
/* eslint-enable */

@Component({
  selector: 'app-parcel-collection-point-base',
  templateUrl: './parcel-collection-point-base.component.html',
  styleUrls: ['./parcel-collection-point-base.component.scss']
})
export class ParcelCollectionPointBaseComponent implements OnInit, AfterViewInit {

  @ViewChild('gmap') gmapElement: any;
  map: google.maps.Map;
  private markers: google.maps.Marker[] = [];
  private isMapReady: boolean = false;

  // If you need to access certain classes from HTML, declare them here
  SelectUtils = SelectUtils;
  AddressModel = AddressModel;
  Strings = Strings;
  InputMask = InputMask;
  ParcelCollectionPoint = ParcelCollectionPoint;
  UiConstants = UiConstants;

  // Declare models before use
  editModel: ParcelCollectionPointEditModel;
  detailModel: ParcelCollectionPointDetailModel;
  get relevantModel(): ParcelCollectionPointEditModel | ParcelCollectionPointDetailModel {
    return this.componentState.isEditable() ? this.editModel : this.detailModel;
  }

  // Component state resolver, determines the state of the component
  componentState: ComponentStateResolver;

  // Variables used for breadcrumb
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');


  // Form for validation
  @ViewChild('f', { static: true })
  fForm: NgForm;

  // Validated inputs
  @ViewChild('name')
  name: NgModel;

  @ViewChild('code')
  code: NgModel;

  @ViewChild('deliveryMethod', { static: true })
  deliveryMethod: NgModel;

  @ViewChild('latitude')
  latitude: NgModel;

  @ViewChild('longitude')
  longitude: NgModel;

  // Form submission state for Postal Address validation
  formSubmitted: boolean = false;

  private fieldErrors: FieldValidationError<ParcelCollectionPoint.ValidatedField> =
    FieldValidationError.empty<ParcelCollectionPoint.ValidatedField>();

  private validatedInputs: LocalFieldValidationErrors<NgModel> =
    LocalFieldValidationErrorsFactory.empty();

  // Right model to store the rights available to the User
  rightModel: RightModel = RightModel.empty();

  // Format of postal address, received from server
  postalAddressFormat: string;

  // Country items for postal address
  countryItems: List<AddressModel.CountryItem>;

  deliveryMethods: MultiselectOptionItem<number>[] = [];

  dropdownSettings: Angular2Multiselects.Settings;

  constructor(private uiRouter: UIRouter,
              private transition: Transition,
              private parcelCollectionPointService: ParcelCollectionPointService,
              private translateService: TranslateService,
              private countryService: CountryService,
              private configService: ConfigurationService,
              private roleService: RoleService,
              private deliveryMethodMultiselectProvider: DeliveryMethodMultiselectProvider,
              private rightService: RightService) {
    this.postalAddressFormat = this.configService.getPostalAddressFormat();
    this.componentState = new ComponentStateResolver(uiRouter, transition,
      'id',
      {stateName: StateName.PARCEL_COLLECTION_POINT_CREATE, stateHeaderKey: 'PARCEL_COLLECTION_POINT_CREATE'},
      {stateName: StateName.PARCEL_COLLECTION_POINT_EDIT, stateHeaderKey: 'PARCEL_COLLECTION_POINT_EDIT'},
      {stateName: StateName.PARCEL_COLLECTION_POINT_DETAIL, stateHeaderKey: 'PARCEL_COLLECTION_POINT_DETAIL'});
  }

  ngOnInit() {
    this.initComponentState(); // Must be called first
    this.loadRightModels();
    this.initBreadcrumb();
    this.initDropdownSettings();
  }

  ngAfterViewInit(): void {
    this.loadLocalFieldValidationErrors();
    this.onDeliveryMethodSearch();
    this.loadCountries(() => {
      // component state contains the id
      if (this.componentState.id) {
        this.loadModel();
      }
      else {
        // Load roles and country items for create. Otherwise handled in loadModel()
        this.editModel.postalAddress.loadCountryItems(this.countryItems, this.configService.getDefaultSelectedCountryCode());
      }
    });
  }

  initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .remoteSearch(true)
      .enableCheckAll(false)
      .build();
  }

  // Loads user rights
  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  onDeliveryMethodSearch(predicate?: string) {
    this.deliveryMethodMultiselectProvider.loadForParcelCollectionPoint(predicate).subscribe((items) => {
      this.deliveryMethods = items;
    })
  }

  // Called by the init method first
  private loadModel() {
    this.parcelCollectionPointService.get({
      id: this.componentState.id!
    }).subscribe((parcelCollectionPoint: ParcelCollectionPoint.ParcelCollectionPoint) => {
      if (this.componentState.isEditView()) {
        this.loadEditModel(parcelCollectionPoint);
      }
      else if (this.componentState.isDetailView()) {
        this.loadDetailModel(parcelCollectionPoint);
      }
      this.loadDeliveryMethod(parcelCollectionPoint);
      this.breadcrumbSelf = parcelCollectionPoint.name;
    });
  }

  private loadEditModel(parcelCollectionPoint: ParcelCollectionPoint.ParcelCollectionPoint) {
    this.editModel = new ParcelCollectionPointEditModel();
    this.editModel.name = parcelCollectionPoint.name;
    this.editModel.code = parcelCollectionPoint.code!;
    this.editModel.openingTimes = parcelCollectionPoint.openingTimes ? parcelCollectionPoint.openingTimes : '';
    this.editModel.note = parcelCollectionPoint.note ? parcelCollectionPoint.note : '';
    this.editModel.disabled = parcelCollectionPoint.disabled;
    this.editModel.postalAddress.loadCountryItems(this.countryItems, this.configService.getDefaultSelectedCountryCode());
    this.editModel.postalAddress.load(parcelCollectionPoint.postalAddress);
    this.editModel.latitude = parcelCollectionPoint.coordinates ? parcelCollectionPoint.coordinates.latitude.toString() : '';
    this.editModel.longitude = parcelCollectionPoint.coordinates ? parcelCollectionPoint.coordinates.longitude.toString() : '';
  }

  private loadDetailModel(parcelCollectionPoint: ParcelCollectionPoint.ParcelCollectionPoint) {
    this.detailModel = new ParcelCollectionPointDetailModel();
    this.detailModel.name = parcelCollectionPoint.name;
    this.detailModel.code = parcelCollectionPoint.code!;
    this.detailModel.openingTimes = parcelCollectionPoint.openingTimes ? parcelCollectionPoint.openingTimes : '-';
    this.detailModel.note = parcelCollectionPoint.note ? parcelCollectionPoint.note : '-';
    this.detailModel.disabled = parcelCollectionPoint.disabled;
    this.detailModel.postalAddress = Address.PostalAddressMapper.toString(parcelCollectionPoint.postalAddress!, this.postalAddressFormat);
    this.detailModel.latitude = parcelCollectionPoint.coordinates ? parcelCollectionPoint.coordinates.latitude.toString() : '';
    this.detailModel.longitude = parcelCollectionPoint.coordinates ? parcelCollectionPoint.coordinates.longitude.toString() : '';
    if (parcelCollectionPoint.coordinates) {
      this.initMap(() => {
        this.loadMapBounds(parcelCollectionPoint.coordinates);
      });
    }
  }

  private loadDeliveryMethod(parcelCollectionPoint: ParcelCollectionPoint.ParcelCollectionPoint) {
    this.deliveryMethodMultiselectProvider.getById(parcelCollectionPoint.deliveryMethod!.id).subscribe(result => {
      if (this.componentState.isEditView()) {
        this.editModel.deliveryMethod.splice(0, this.editModel.deliveryMethod.length);
        this.editModel.deliveryMethod.push(result);
      }
      else if (this.componentState.isDetailView()) {
        this.detailModel.deliveryMethod.splice(0, this.detailModel.deliveryMethodName.length);
        this.detailModel.deliveryMethod.push(result);
      }
      this.deliveryMethod.control.updateValueAndValidity();
    })
  }

  private initComponentState() {
    // Creates the editModel if not readonly
    if (!this.componentState.isDetailView()) {
      this.editModel = new ParcelCollectionPointEditModel();
    }
    else {
      this.detailModel = new ParcelCollectionPointDetailModel()
    }
    // normally you would need to call loadModel after this
  }

  initBreadcrumb() {
    // Set breadcrumbSelf if createView, otherwise set in loadModel()
    if (this.componentState.isCreateView()) {
      this.translateService.get('PARCEL_COLLECTION_POINT_CREATE').subscribe(
        (result: string) => {
          this.breadcrumbSelf = result;
        }
      );
    }
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('MENU_NAVBAR_PARCEL_COLLECTION_POINTS').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.PARCEL_COLLECTION_POINT_LIST});
      }
    );
  }

  // Get corresponding dictionary key
  getHeadingDictionaryKey(): string {
    return this.componentState.getCurrentHeaderKey();
  }

  hasLocalFieldError(field?: NgModel): boolean {
    return this.validatedInputs.hasLocalError(field);
  }

  hasFieldError(field: ParcelCollectionPoint.ValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: ParcelCollectionPoint.ValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: ParcelCollectionPoint.ValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  private loadLocalFieldValidationErrors() {
    const validatedInputs = List.of(this.name,
      this.code,
      this.deliveryMethod,
      this.latitude,
      this.longitude
    );
    this.validatedInputs = LocalFieldValidationErrorsFactory.ofFormFields(this.fForm, validatedInputs);
  }

  private loadCountries(completion: () => void) {
    this.countryService.query({}).subscribe((result: QueryResult<Country.Country>) => {
      this.countryItems = AddressModel.CountryItem.fromCountryList(result.items);
      completion();
    });
  }

  submit() {
    this.formSubmitted = true;
    if (this.componentState.isCreateView()) {
      this.create();
    }
    else if (this.componentState.isEditView()) {
      this.update();
    }
  }

  create() {
    if (this.hasLocalFieldError()) {
      return;
    }
    if (!this.editModel.postalAddress.valid) {
      return;
    }
    this.parcelCollectionPointService.create({
      name: this.editModel.name,
      code: this.editModel.code,
      deliveryMethodId: this.editModel.deliveryMethodId!,
      openingTimes: Strings.undefinedOrNonEmpty(this.editModel.openingTimes),
      note: Strings.undefinedOrNonEmpty(this.editModel.note),
      postalAddress: this.editModel.postalAddress.toData()!,
      coordinates: this.createCoordinates()
    }).subscribe(
      (response: IdentityMessage) => {
        this.uiRouter.stateService.go(StateName.PARCEL_COLLECTION_POINT_LIST);
      },
      (error: any) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withForm(this.fForm);
        }
      });
  }

  update() {
    if (this.hasLocalFieldError()) {
      return;
    }
    if (!this.editModel.postalAddress.valid) {
      return;
    }
    this.parcelCollectionPointService.update({
      id: this.componentState.id!,
      name: this.editModel.name,
      code: this.editModel.code,
      deliveryMethodId: this.editModel.deliveryMethodId!,
      openingTimes: Strings.undefinedOrNonEmpty(this.editModel.openingTimes),
      note: Strings.undefinedOrNonEmpty(this.editModel.note),
      postalAddress: this.editModel.postalAddress.toData()!,
      coordinates: this.createCoordinates()
    }).subscribe(
      (response: EmptyMessage) => {
        this.uiRouter.stateService.go(StateName.PARCEL_COLLECTION_POINT_LIST);
      },
      (error: any) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withForm(this.fForm);
        }
      });
  }

  back() {
    window.history.back();
  }

  private createCoordinates(): Address.Coordinate | undefined {
    const lat = Strings.undefinedOrNonEmpty(this.editModel.latitude);
    const lon = Strings.undefinedOrNonEmpty(this.editModel.longitude);
    if (lat && lon) {
      return {
        latitude: new Decimal(lat),
        longitude: new Decimal(lon),
      }
    }
    return undefined;
  }

  initMap(completion: () => void) {
    if (GoogleMapsLoaderUtil.didMapLoad()) {
      this.createMap();
      completion();
    }
    else {
      GoogleMapsLoaderUtil.subscribe(() => {
        this.createMap();
        completion();
      });
    }
  }

  createMap() {
    const mapProp = {
      center: new google.maps.LatLng(47.49801, 19.03991),
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      streetViewControl: false,
      mapTypeControl: false
    };
    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
    const context = this;
    google.maps.event.addListenerOnce(this.map, 'idle', function () {
      context.isMapReady = true;
    });
  }

  private loadMapBounds(coordinates?: Address.Coordinate) {
    if (!coordinates) {
      return;
    }
    const bounds = new google.maps.LatLngBounds();
    this.markers.forEach(m => m.setMap(null));
    this.markers = [];
    const position = new google.maps.LatLng(coordinates.latitude.toNumber(), coordinates.longitude.toNumber());
    bounds.extend(position);

    const infoWindow = new google.maps.InfoWindow({
      content: '<center><b>' + this.translateService.instant('TASK_GEOCODING_COORDINATES') + ': '
        + position.lat().toString() + ', ' + position.lng().toString() + '</b></center>'
    });

    const marker = new google.maps.Marker({
      position: position,
      map: this.map,
      icon: {
        url: this.resolvePoiUrl(),
        labelOrigin: this.getLabelOrigin()
      },
      zIndex: 0

    });
    marker.addListener('click', function () {
      infoWindow.open(map, marker);
    });
    this.markers[0] = marker;
    const map = this.map;
    if (!bounds.isEmpty()) {
      if (this.isMapReady) {
        map.fitBounds(bounds);
        map.panToBounds(bounds);
      }
      else {
        google.maps.event.addListenerOnce(this.map, 'idle', function () {
          map.fitBounds(bounds);
          map.panToBounds(bounds);
        });
      }
    }
  }

  private resolvePoiUrl() {
    return '../../../assets/img/poi/ic_map_poi_active.svg';
  }

  private getLabelOrigin() {
    return new google.maps.Point(18, 15);
  }

  // Since map needs to be visible for the component all the time, this is used instead of ngif
  get coordinateStyle() {
    return {
      'display': this.componentState.isEditable() || this.componentState.isDetailView() && this.detailModel.latitude !== '' ?
        'block' : 'none'
    }
  }
}
