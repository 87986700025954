/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { ObservableErrorResourceParser } from '../../util/errors';
import { FieldValidationError, PagingRequest, QueryResult, ResourceQueryResult, Services } from '../../util/services';
import { List, Map as ImmutableMap, Set } from 'immutable';
import { OffsetDateTime } from '../../util/dates';
import { DisableRequest, IdentityMessage } from '../../util/messages';
import { DqlQuery, Query } from '../../query/field';
import { FilterField } from '../../query/filterfields';
import { OrderField } from '../../query/orderfields';
import { InvoiceTagResource, InvoiceTagResourceService } from './invoice-tag-resource.service';

/* eslint-enable */

@Injectable()
export class InvoiceTagService implements InvoiceTag.Service {

  private readonly filterField = new FilterField.InvoiceTag();
  private readonly orderField = new OrderField.InvoiceTag();
  private readonly fields = new InvoiceTag.Fields.InvoiceTag();

  constructor(private resourceService: InvoiceTagResourceService) {
  }

  query(request: InvoiceTag.QueryRequest): Observable<QueryResult<InvoiceTag.InvoiceTag>> {
    return Observable.create((observer: Observer<QueryResult<InvoiceTag.InvoiceTag>>) => {
      const resourceRequest = InvoiceTag.toResourceQueryRequest(this.filterField, this.orderField, this.fields, request);
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<InvoiceTagResource.InvoiceTag>) => {
          observer.next({
            items: List.of(...result.items.map((item) => InvoiceTag.toPublic(item))),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  get(request: InvoiceTag.GetRequest): Observable<InvoiceTag.InvoiceTag> {
    return Observable.create((observer: Observer<InvoiceTag.InvoiceTag>) => {
      const resourceRequest = InvoiceTag.toResourceGetRequest(this.fields, request);
      return this.resourceService.get(resourceRequest).subscribe(
        (result: InvoiceTagResource.InvoiceTag) => {
          observer.next(InvoiceTag.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  create(request: InvoiceTag.CreateRequest): Observable<IdentityMessage> {
    return Observable.create((observer: Observer<IdentityMessage>) => {
      const resourceRequest = InvoiceTag.toResourceCreateRequest(request);
      return this.resourceService.create(resourceRequest).subscribe(
        (result: IdentityMessage) => {
          observer.next(result);
        },
        (error: any) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  update(request: InvoiceTag.UpdateRequest): Observable<IdentityMessage> {
    return Observable.create((observer: Observer<IdentityMessage>) => {
      const resourceRequest = InvoiceTag.toResourceUpdateRequest(request);
      return this.resourceService.update(resourceRequest).subscribe(
        (result: IdentityMessage) => {
          observer.next(result);
        },
        (error: any) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  disable(request: DisableRequest): Observable<IdentityMessage> {
    return Observable.create((observer: Observer<IdentityMessage>) => {
      const resourceRequest = request;
      return this.resourceService.disable(resourceRequest).subscribe(
        (result: IdentityMessage) => {
          observer.next(result);
        },
        (error: any) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  private translateError(error: any): any {
    const res = ObservableErrorResourceParser.parseError(error);
    const fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
    const fieldErrorMap = ObservableErrorResourceParser.toFieldErrorMap(Keys.toValidatedField, fieldErrors);
    if (!fieldErrorMap.isEmpty()) {
      return FieldValidationError.of(fieldErrorMap);
    }
    return error;
  }
}

export namespace InvoiceTag {


  export interface Service {

    // <editor-fold desc="CRUD">

    query(request: QueryRequest): Observable<QueryResult<InvoiceTag>>;

    get(request: GetRequest): Observable<InvoiceTag>;

    create(request: CreateRequest): Observable<IdentityMessage>;

    // </editor-fold>

  }

  // <editor-fold desc="CRUD">

  export interface QueryRequest {
    fields?: Query.FieldFunction<Fields.InvoiceTag>;
    filter?: Query.FilterFunction<FilterField.InvoiceTag>;
    order?: Query.OrderFunction<OrderField.InvoiceTag>;
    paging?: PagingRequest;
    rights?: Set<string>;
    noProgressBar?: boolean;
  }

  export interface GetRequest {
    id: number;
    fields?: Query.FieldFunction<Fields.InvoiceTag>;
    rights?: Set<string>;
  }

  export interface InvoiceTag {
    id: number;
    grantedRights: Set<string>;
    creationTime: OffsetDateTime;
    updateTime: OffsetDateTime;
    invoiceTag: string;
    description?: string;
    disabled: boolean;
  }

  export interface CreateRequest {
    invoiceTag: string;
    description?: string;
  }

  export interface UpdateRequest {
    id: number;
    invoiceTag: string;
    description?: string;
  }

  export function toResourceQueryRequest(
    filterField: FilterField.InvoiceTag,
    orderField: OrderField.InvoiceTag,
    fields: InvoiceTag.Fields.InvoiceTag,
    request: QueryRequest): InvoiceTagResource.QueryRequest {
    const filter: string | undefined = DqlQuery.toOptionalFilter(filterField, request.filter);
    const order: string | undefined = DqlQuery.toOptionalOrder(orderField, request.order);
    const field: string | undefined = DqlQuery.toOptionalFields(fields, request.fields);
    return {
      filter: filter,
      order: order,
      fields: field,
      page_number: request.paging ? request.paging.pageNumber : undefined,
      number_of_items: request.paging ? request.paging.numberOfItems : undefined,
      rights: DqlQuery.RightRequestSerializer.getInstance().serialize(request.rights),
      no_progress_bar: request.noProgressBar
    };
  }

  export function toResourceGetRequest(
    fields: InvoiceTag.Fields.InvoiceTag,
    request: GetRequest): InvoiceTagResource.GetRequest {
    const field: string | undefined = DqlQuery.toOptionalFields(fields, request.fields);
    return {
      id: request.id,
      fields: field,
      rights: DqlQuery.RightRequestSerializer.getInstance().serialize(request.rights)
    };
  }

  export function toResourceCreateRequest(request: CreateRequest): InvoiceTagResource.CreateRequest {
    return {
      invoice_tag: request.invoiceTag,
      description: request.description,
    };
  }

  export function toResourceUpdateRequest(request: UpdateRequest): InvoiceTagResource.UpdateRequest {
    return {
      id: request.id,
      invoice_tag: request.invoiceTag,
      description: request.description,
    };
  }


  export function toPublic(r: InvoiceTagResource.InvoiceTag): InvoiceTag.InvoiceTag {
    return {
      id: r.id,
      grantedRights: r.granted_rights ? Set.of(...r.granted_rights) : Set.of(),
      creationTime: Services.toOffsetDateTime(r.creation_time),
      updateTime: Services.toOffsetDateTime(r.update_time),
      invoiceTag: r.invoice_tag,
      description: r.description,
      disabled: r.disabled
    };
  }

  export enum ValidatedField {
    UNKNOWN,
    DESCRIPTION,
    INVOICE_TAG
  }

  export namespace Fields {

    export class InvoiceTag {

      readonly id: Query.Field = new DqlQuery.Field('id');
      readonly grantedRights: Query.Field = new DqlQuery.Field('granted_rights');
      readonly creationTime: Query.Field = new DqlQuery.Field('creation_time');
      readonly updateTime: Query.Field = new DqlQuery.Field('update_time');
      readonly invoiceTag: Query.Field = new DqlQuery.Field('invoice_tag');
      readonly description: Query.Field = new DqlQuery.Field('description');
      readonly disabled: Query.Field = new DqlQuery.Field('disabled');

      get each(): Set<Query.Field> {
        return Set.of(
          this.id,
          this.creationTime,
          this.updateTime,
          this.invoiceTag,
          this.description,
          this.disabled,
        );
      }

      get forList(): Set<Query.Field> {
        return Set.of(
          this.id,
          this.description,
          this.invoiceTag,
          this.disabled,
          this.creationTime,
        );
      }

      get multiselect(): Set<Query.Field> {
        return Set.of(this.id, this.description, this.invoiceTag, this.disabled);
      }
    }

  }

}

// <editor-fold desc="Internal">


class Keys {

  private static readonly DESCRIPTION = 'description';
  private static readonly INVOICE_TAG = 'invoice_tag';

  private static readonly keyValidatedFieldMap: ImmutableMap<string, InvoiceTag.ValidatedField> = ImmutableMap.of(
    Keys.DESCRIPTION, InvoiceTag.ValidatedField.DESCRIPTION,
    Keys.INVOICE_TAG, InvoiceTag.ValidatedField.INVOICE_TAG,
  );

  public static toValidatedField(fieldKey: string): InvoiceTag.ValidatedField {
    return Keys.keyValidatedFieldMap.get(fieldKey, InvoiceTag.ValidatedField.UNKNOWN);
  }

}

// </editor-fold>


