<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
    <app-breadcrumb-menu>
      <!-- region EDIT-->
      <app-breadcrumb-button
        *ngIf="rightModel.stockTakingUpdate.hasRight()"
        [iconClass]="'icomoon-modify'"
        [titleStringKey]="'COMMON_BUTTON_EDIT'"
        uiSref="Admin.StockTakingsEdit"
        [uiParams]="{ id: stockTakingId }">
      </app-breadcrumb-button>
      <!-- endregion EDIT-->
    </app-breadcrumb-menu>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<!-- region Content -->
<div class="container-horizontal-padding-half container-vertical-padding-half animated fadeIn">

  <div class="row ml-0 mr-0">

    <!-- region StockTaking card -->
    <div class="col-xs-12 col-sm-12 col-md-6 card-custom-padding">
      <div class="container-vertical-padding-half">
        <div class="card">

          <div class="card-header">
            {{'STOCK_TAKING_EDIT'|translate}}
          </div><!-- /.card-header -->

          <div class="card-body">

            <div class="form-group row">
              <div class="col-md-4">
                <label
                  class="col-form-label mw-100 form-control-label">{{'COMMON_NAME'|translate}}</label>
              </div>
              <div class="col-md-8">
                <label
                  class="col-form-label mw-100 form-control-label">{{model.name}}</label>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-4">
                <label
                  class="col-form-label mw-100 form-control-label">{{'STOCK_TAKING_TRX_ID'|translate}}</label>
              </div>
              <div class="col-md-8">
                <label
                  class="col-form-label mw-100 form-control-label">{{model.trxId}}</label>
              </div>
            </div>

            <div class="form-group row" *ngIf="model && model.ownerUser.length > 0">
              <div class="col-md-4">
                <label
                  class="col-form-label mw-100 form-control-label">{{'STOCK_TAKING_OWNER_USER'|translate}}</label>
              </div>
              <div class="col-md-8">
                <angular2-multiselect [data]="model.ownerUser" [settings]="dropdownSettings"
                                      class="form-control"
                                      [(ngModel)]="model.ownerUser"
                                      [ngModelOptions]="{standalone: true}"
                                      [readonly]="true">
                </angular2-multiselect>
              </div>
            </div>

            <div class="form-group row" *ngIf="model && model.stocks.length > 0">
              <div class="col-md-4">
                <label
                  class="col-form-label mw-100 form-control-label">{{'STOCK_TAKING_STOCKS'|translate}}</label>
              </div>
              <div class="col-md-8">
                <angular2-multiselect [data]="model.stocks" [settings]="dropdownSettings"
                                      class="form-control"
                                      [(ngModel)]="model.stocks"
                                      [ngModelOptions]="{standalone: true}"
                                      [readonly]="true">
                </angular2-multiselect>
              </div>
            </div>

            <div class="form-group row" *ngIf="model.creationTime">
              <div class="col-md-4">
                <label
                  class="col-form-label mw-100 form-control-label">{{'COMMON_CREATION_TIME'|translate}}</label>
              </div>
              <div class="col-md-8">
                <label
                  class="col-form-label mw-100 form-control-label">{{model.creationTime.toUtcIsoString() | date:'short'}}</label>
              </div>
            </div>

          </div><!-- /.card-body -->

        </div><!-- /.card -->
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 card-custom-padding">

      <div class="container-vertical-padding-half">

        <div class="card">
          <div class="card-header">
            {{'STOCK_TAKING_PDF_TEMPLATES'|translate}}
          </div>
          <div class="card-body">
            <div *ngIf="model.pdfTemplate" class="text-container text-center">
              <h4>{{'STOCK_TAKINGS_PDF_TEMPLATE_UPLOADED' | translate}}</h4>
              <br>
              <a class="button-icon cursor-pointer" (click)="downloadTemplate()">
                <i class="icomoon icomoon-download"></i>
              </a>
            </div>
            <div *ngIf="!model.pdfTemplate" class="text-center">
              {{'STOCK_TAKINGS_PDF_TEMPLATE_NOT_UPLOADED' | translate}}
            </div>
          </div>
        </div>
      </div>
    </div>

  </div><!-- /.container-vertical-padding-half -->
  <!-- endregion StockTaking Task -->

  <!-- region StockTakingSheets card -->
  <app-stock-taking-sheet-list
    *ngIf="stockTakingId"
    [stockTakingId]="stockTakingId"
    [hasPdf]="model.pdfTemplate"
    [readonly]="true">
  </app-stock-taking-sheet-list>
  <!-- endregion StockTakingSheets card -->

</div>
<!-- endregion Content -->
