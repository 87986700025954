import { Injectable } from '@angular/core';
import { BaseHttpService, ResourceHelper } from '../../util/http-services';
import { HttpClient } from '@angular/common/http';
import { TriggerResource } from '../../trigger/trigger-resource.service';
import { Observable } from 'rxjs';
import { EmptyMessage, IdentityMessage } from '../../util/messages';
import { ResourceQueryResult } from '../../util/services';
import { TriggerInstanceResource } from '../../trigger/trigger-instance-resource.service';

@Injectable()
export class TransportTriggerResourceService extends BaseHttpService implements TriggerResource.TriggerResourceService {

  createTrigger(request: TriggerResource.TriggerCreateRequest): Observable<IdentityMessage> {
    return this.http.post<IdentityMessage>(this.url, request);
  }

  deleteTrigger(request: TriggerResource.TriggerDeleteRequest): Observable<EmptyMessage> {
    return this.http.delete<EmptyMessage>(this.url + `${request.id}`);
  }

  disableTrigger(request: TriggerResource.TriggerDisableRequest): Observable<EmptyMessage> {
    return this.http.patch<EmptyMessage>(this.url + `${request.id}/disabled`, request);
  }

  getTrigger(request: TriggerResource.TriggerGetRequest): Observable<TriggerResource.Trigger> {
    return this.http.get<TriggerResource.Trigger>(this.url + `${request.id}`);
  }

  getTriggerInstances(request: TriggerResource.TriggerInstanceRequest):
    Observable<ResourceQueryResult<TriggerInstanceResource.TriggerInstance>> {
    return this.http.get<ResourceQueryResult<TriggerInstanceResource.TriggerInstance>>(this.url + `${request.id}/instances`);
  }

  queryTriggers(request: TriggerResource.TriggerQueryRequest): Observable<ResourceQueryResult<TriggerResource.Trigger>> {
    return this.http.get<ResourceQueryResult<TriggerResource.Trigger>>(this.url, this.parseParams(request));
  }

  updateTrigger(request: TriggerResource.TriggerUpdateRequest): Observable<EmptyMessage> {
    return this.http.put(this.url + `${request.id}`, request);
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/transport-triggers/');
  }
}
