/* eslint-disable */
import { Component, OnInit, ViewChild } from '@angular/core';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { StateName } from '../../../app.state-names';
import { ComponentStateResolver } from '../../../util/component-state/component-state-resolver';
import { Transition, UIRouter } from '@uirouter/angular';
import { TranslateService } from '@ngx-translate/core';
import {
  TableDocumentSchema,
  TableDocumentSchemaField,
  TableDocumentSchemaFieldModel,
  TableDocumentSchemaImportExport,
  TableDocumentSchemaService
} from '../../../lib/table-document-schema/table-document-schema.service';
import { OffsetDateTime } from '../../../lib/util/dates';
import { UiConstants } from '../../../util/core-utils';
import { NgForm, NgModel } from '@angular/forms';
import { List } from 'immutable';
import { LocalFieldValidationErrors, LocalFieldValidationErrorsFactory } from '../../../lib/util/services';
import { FieldError, FieldErrors, ObservableErrorResourceParser } from '../../../lib/util/errors';
import { Models } from '../../../util/model-utils';
import { saveAs } from 'file-saver';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ErrorDetail, ErrorMessageService } from '../../../lib/error-message-parser.service';
import { ToasterService } from '../../../fork/angular2-toaster/angular2-toaster';
import { StringKey } from '../../../app.string-keys';
import { TranslateUtils } from '../../../util/translate';
import { map } from 'rxjs/operators';
import { EmptyMessage } from '../../../lib/util/messages';
import { RightModel } from '../../../app.rights';
import { RightResolver, RightService } from '../../../lib/right.service';
/* eslint-enable */

@Component({
  selector: 'app-table-document-schema-base',
  templateUrl: './table-document-schema-base.component.html',
  styleUrls: ['./table-document-schema-base.component.scss']
})
export class TableDocumentSchemaBaseComponent implements OnInit {
  TableDocumentSchema = TableDocumentSchema;
  UiConstants = UiConstants;


  @ViewChild('f', { static: true })
  fForm: NgForm;

  @ViewChild('code')
  code: NgModel;

  @ViewChild('name')
  name: NgModel;

  @ViewChild('finalizeModal', { static: true }) finalizeModal: ModalDirective;
  finalizeModalVisible: boolean = false;

  @ViewChild('deprecateModal', { static: true }) deprecateModal: ModalDirective;
  deprecateModalVisible: boolean = false;

  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  componentState: ComponentStateResolver;

  editModel: TableDocumentSchemaEditModel;
  detailModel: TableDocumentSchemaDetailModel;

  tableDocumentSchemaFieldList: TableDocumentSchemaFieldModel[] = [];
  addModel?: TableDocumentSchemaFieldModel;

  rightModel: RightModel = RightModel.empty();

  private validatedInputs: LocalFieldValidationErrors<NgModel> =
    LocalFieldValidationErrorsFactory.empty();
  fieldErrors: TableDocumentSchemaErrorMap;

  get nonEditable(): boolean {
    return this.componentState.isReadonly() || !this.isDraft();
  }

  constructor(private uiRouter: UIRouter,
              private transition: Transition,
              private translateService: TranslateService,
              private tableDocumentSchemaService: TableDocumentSchemaService,
              private errorMessageService: ErrorMessageService,
              private rightService: RightService,
              private toasterService: ToasterService) {
    this.fieldErrors = {};
    this.componentState = new ComponentStateResolver(uiRouter, transition,
      'id',
      {stateName: StateName.TABLE_DOCUMENT_SCHEMA_CREATE, stateHeaderKey: 'TABLE_DOCUMENT_SCHEMA_CREATE'},
      {stateName: StateName.TABLE_DOCUMENT_SCHEMA_EDIT, stateHeaderKey: 'TABLE_DOCUMENT_SCHEMA_EDIT'},
      {stateName: StateName.TABLE_DOCUMENT_SCHEMA_DETAIL, stateHeaderKey: 'TABLE_DOCUMENT_SCHEMA_DETAIL'},
      {stateName: StateName.TABLE_DOCUMENT_SCHEMA_CLONE, stateHeaderKey: 'TABLE_DOCUMENT_SCHEMA_CLONE'}
    )
  }

  ngOnInit() {
    this.initComponentState();
    this.initBreadcrumb();
    this.loadRightModels();
    this.loadLocalFieldValidationErrors();
    if (this.componentState.id) {
      this.loadModel();
    }
    else {
      this.editModel = new TableDocumentSchemaEditModel();
    }
  }

  private initComponentState() {
    // Creates the editModel if not readonly
    if (!this.componentState.isDetailView()) {
      this.editModel = new TableDocumentSchemaEditModel();
    }
    else {
      this.detailModel = new TableDocumentSchemaDetailModel()
    }
    // normally you would need to call loadModel after this
  }


  initBreadcrumb() {
    // Set breadcrumbSelf if createView, otherwise set in loadModel()
    if (this.componentState.isCreateView()) {
      this.translateService.get('TABLE_DOCUMENT_SCHEMA_CREATE').subscribe(
        (result: string) => {
          this.breadcrumbSelf = result;
        }
      );
    }
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('MENU_NAVBAR_TABLE_DOCUMENT_SCHEMA').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.TABLE_DOCUMENT_SCHEMA_LIST});
      }
    );
  }

  // Called by the init method first
  private loadModel() {
    this.tableDocumentSchemaService.get({
      schemaId: this.componentState.id!
    }).subscribe((tableDocumentSchema: TableDocumentSchema.TableDocumentSchema) => {
      if (this.componentState.isEditView() || this.componentState.isCloneView()) {
        this.loadEditModel(tableDocumentSchema);
      }
      else if (this.componentState.isDetailView()) {
        this.loadDetailModel(tableDocumentSchema);
      }
      this.breadcrumbSelf = tableDocumentSchema.name;

      this.loadFields()
    });
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  private loadEditModel(tableDocumentSchema: TableDocumentSchema.TableDocumentSchema) {

    this.editModel = new TableDocumentSchemaEditModel();
    this.editModel.schemaId = tableDocumentSchema.schemaId;
    this.editModel.creationTime = tableDocumentSchema.creationTime;
    this.editModel.version = tableDocumentSchema.version;
    this.editModel.versionState = tableDocumentSchema.versionState;
    this.editModel.code = tableDocumentSchema.code;
    this.editModel.name = tableDocumentSchema.name;
    this.editModel.note = tableDocumentSchema.note;
  }

  private loadDetailModel(tableDocumentSchema: TableDocumentSchema.TableDocumentSchema) {

    this.detailModel = new TableDocumentSchemaDetailModel();
    this.detailModel.schemaId = tableDocumentSchema.schemaId;
    this.detailModel.creationTime = tableDocumentSchema.creationTime;
    this.detailModel.version = tableDocumentSchema.version;
    this.detailModel.versionState = tableDocumentSchema.versionState;
    this.detailModel.code = tableDocumentSchema.code;
    this.detailModel.name = tableDocumentSchema.name;
    this.detailModel.note = tableDocumentSchema.note;
  }

  private loadFields() {
    this.tableDocumentSchemaService.queryField(
      {
        schemaId: this.componentState.id!
      }
    ).subscribe((result: List<TableDocumentSchemaField.TableDocumentSchemaField>) => {
      this.tableDocumentSchemaFieldList = [];
      result.forEach((tableDocumentSchemaField: TableDocumentSchemaField.TableDocumentSchemaField) => {
        const tableDocumentSchemaFieldModel = new TableDocumentSchemaFieldModel();
        tableDocumentSchemaFieldModel.fieldId = tableDocumentSchemaField.fieldId + '';
        tableDocumentSchemaFieldModel.code = tableDocumentSchemaField.code;
        tableDocumentSchemaFieldModel.name = tableDocumentSchemaField.name;
        tableDocumentSchemaFieldModel.note = tableDocumentSchemaField.note;
        tableDocumentSchemaFieldModel.required = tableDocumentSchemaField.required;
        tableDocumentSchemaFieldModel.requiredText = tableDocumentSchemaField.required ? 'COMMON_YES' : 'COMMON_NO';
        tableDocumentSchemaFieldModel.dataType = tableDocumentSchemaField.dataType;
        tableDocumentSchemaFieldModel.dataTypeText = 'TABLE_DOCUMENT_SCHEMA_FIELD_DATA_TYPE_' + tableDocumentSchemaField.dataType;
        this.tableDocumentSchemaFieldList.push(tableDocumentSchemaFieldModel);
      })
    })
  }

  submit() {
    if (this.componentState.isCreateView()) {
      this.create();
    }
    else if (this.componentState.isEditView()) {
      this.update();
    }
    else if (this.componentState.isCloneView()) {
      this.clone();
    }


  }

  create() {
    this.tableDocumentSchemaService.create(
      {
        code: this.editModel.code,
        name: this.editModel.name,
        note: this.editModel.note
      }).subscribe(
      (result: TableDocumentSchema.CreateItem) => {
        this.uiRouter.stateService.go(StateName.TABLE_DOCUMENT_SCHEMA_EDIT, {id: result.schemaId});
      },
      (error: any) => {
        const res = ObservableErrorResourceParser.parseError(error);
        this.fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
      });
  }

  update() {
    this.tableDocumentSchemaService.update(
      {
        schemaId: this.componentState.id!,
        code: this.editModel.code,
        name: this.editModel.name,
        note: this.editModel.note
      }).subscribe(
      (result: EmptyMessage) => {
        this.translateService.get([
          StringKey.COMMON_SUCCESS,
          StringKey.TABLE_DOCUMENT_SCHEMA_EDIT_SUCCESS,
        ]).subscribe((o) => {
          this.toasterService.pop({
            timeout: UiConstants.ToastTimeoutShort,
            type: UiConstants.toastTypeSuccess,
            title: TranslateUtils.extractValueFromObject(o, StringKey.COMMON_SUCCESS),
            body: TranslateUtils.extractValueFromObject(o, StringKey.TABLE_DOCUMENT_SCHEMA_EDIT_SUCCESS)
          });
        });
      },
      (error: any) => {
        const res = ObservableErrorResourceParser.parseError(error);
        this.fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
      });
  }

  clone() {
    this.tableDocumentSchemaService.clone(
      {
        schemaId: this.editModel.schemaId,
        code: this.editModel.code,
        name: this.editModel.name,
        note: this.editModel.note
      }).subscribe(
      (result: TableDocumentSchema.CreateItem) => {
        this.uiRouter.stateService.go(StateName.TABLE_DOCUMENT_SCHEMA_EDIT, {id: result.schemaId});
      },
      (error: any) => {
        const res = ObservableErrorResourceParser.parseError(error);
        this.fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
      });
  }

  getHeadingDictionaryKey(): string {
    return this.componentState.getCurrentHeaderKey();
  }

  hasLocalFieldError(field?: NgModel): boolean {
    return this.validatedInputs.hasLocalError(field);
  }

  private loadLocalFieldValidationErrors() {
    const validatedInputs = List.of(this.code,
      this.name,
    );
    this.validatedInputs = LocalFieldValidationErrorsFactory.ofFormFields(this.fForm, validatedInputs);
  }

  removeFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.fieldErrors, fieldError);
  }

  toggleEdit(index: number) {
    this.tableDocumentSchemaFieldList[index].editing = !this.tableDocumentSchemaFieldList[index].editing;
  }

  closeRow(index: number) {
    this.tableDocumentSchemaFieldList[index].editing = false;
  }

  newField() {
    this.addModel = new TableDocumentSchemaFieldModel();
  }

  cancelNewRecord() {
    this.addModel = undefined;
  }

  removeRecord(index: number) {
    this.tableDocumentSchemaService.deleteField({
      schemaId: Models.numberToString(this.componentState.id),
      schemaFieldId: this.tableDocumentSchemaFieldList[index].fieldId!,
    }).subscribe(value => {
      this.loadFields();
      this.closeRow(index);
    });
  }

  saveRecord(index: number, model: TableDocumentSchemaFieldModel) {
    this.tableDocumentSchemaService.updateField({
      schemaId: Models.numberToString(this.componentState.id),
      schemaFieldId: model.fieldId!,
      code: model.code,
      name: model.name,
      note: model.note,
      dataType: model.dataType,
      required: model.required
    }).subscribe(value => {
      this.loadFields();
      this.closeRow(index);
    });


  }

  saveNewRecord(model: TableDocumentSchemaFieldModel) {
    model.editing = false;
    this.tableDocumentSchemaService.createField({
      schemaId: Models.numberToString(this.componentState.id)!,
      code: model.code,
      name: model.name,
      note: model.note,
      dataType: model.dataType,
      required: model.required
    }).subscribe(value => {
      this.addModel = undefined;
      this.loadFields();
    });
  }

  isDraft(): boolean {
    if (this.componentState.isEditable() &&
      this.editModel.versionState === 'DRAFT') {
      return true
    }
    else if (!this.componentState.isEditable() &&
      this.detailModel.versionState === 'DRAFT') {
      return true
    }
    return false;
  }

  isFinalized(): boolean {
    if (this.componentState.isEditable() &&
      this.editModel.versionState === 'FINALIZED') {
      return true
    }
    else if (!this.componentState.isEditable() &&
      this.detailModel.versionState === 'FINALIZED') {
      return true
    }
    return false;
  }

  isDeprecated(): boolean {
    if (this.componentState.isEditable() &&
      this.editModel.versionState === 'DEPRECATED') {
      return true
    }
    else if (!this.componentState.isEditable() &&
      this.detailModel.versionState === 'DEPRECATED') {
      return true
    }
    return false;
  }

  isFirstVersion(): boolean {
    if (this.componentState.isEditable() && this.editModel.version === 1 ||
      this.componentState.isEditable() && !this.editModel.version) { // CREATE
      return true
    }
    else if (!this.componentState.isEditable() &&
      this.detailModel.version === 1) {
      return true
    }
    return false;
  }

  exportSchema() {

    this.tableDocumentSchemaService.exportSchema({
      schemaId: this.componentState.id!
    }).subscribe((result: TableDocumentSchemaImportExport.ImportDocument) => {
      let name: string = '';
      let version: number;
      if (this.componentState.isDetailView()) {
        name = this.detailModel.name;
        version = this.detailModel.version!;
      }
      else {
        name = this.editModel.name;
        version = this.detailModel.version!;
      }
      const blob = new Blob([JSON.stringify(result)], {type: 'text/json;charset=utf-8'});
      saveAs(blob, 'document_schema_' + name + '(' + version + ').json');
    });
  }

  finalize() {
    this.tableDocumentSchemaService.finalize(
      {
        schemaId: this.componentState.id!
      }
    ).subscribe(
      (response: EmptyMessage) => {
        this.uiRouter.stateService.go(StateName.TABLE_DOCUMENT_SCHEMA_LIST);
      },
      (error: any) => {
        const res = ObservableErrorResourceParser.parseError(error);
        this.fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
      });
  }

  deprecate() {
    this.tableDocumentSchemaService.deprecate(
      {
        schemaId: this.componentState.id!
      }
    ).subscribe(
      (response: EmptyMessage) => {
        this.uiRouter.stateService.go(StateName.TABLE_DOCUMENT_SCHEMA_LIST);
      },
      (error: any) => {
        const res = ObservableErrorResourceParser.parseError(error);
        this.fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
      });
  }

  createNextSchema() {
    this.tableDocumentSchemaService.createNext(
      {
        schemaId: this.detailModel.schemaId,
        withFields: true
      }
    ).subscribe(
      (response) => {
        this.uiRouter.stateService.go(StateName.TABLE_DOCUMENT_SCHEMA_EDIT, {id: response.schema_id});
      }
    )
  }

  openFinalizeModal() {
    this.finalizeModalVisible = true;
    this.finalizeModal.show();
  }

  closeFinalizeModal() {
    this.finalizeModalVisible = false;
    this.finalizeModal.hide();
  }

  openDeprecateModal() {
    this.deprecateModalVisible = true;
    this.deprecateModal.show();
  }

  closeDeprecateModal() {
    this.deprecateModalVisible = false;
    this.deprecateModal.hide();
  }
}

class TableDocumentSchemaEditModel {
  schemaId: number;
  creationTime?: OffsetDateTime;
  version?: number;
  versionState: TableDocumentSchema.TableDocumentSchemaVersionState;
  code: string = '';
  name: string = '';
  note: string = '';

}

// Used for detail view
class TableDocumentSchemaDetailModel {
  schemaId: number;
  creationTime?: OffsetDateTime;
  version?: number;
  versionState: TableDocumentSchema.TableDocumentSchemaVersionState;
  code: string = '';
  name: string = '';
  note: string = '';

}

interface TableDocumentSchemaErrorMap {
  code?: FieldError;
  name?: FieldError;
}

