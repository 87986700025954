/* eslint-disable */
import { Injectable } from '@angular/core';
import { OrderField } from '../query/orderfields';
import { MultiselectOptionItem, OrderFieldModel, OrderFunctionResolver } from '../../util/core-utils';
import { environment } from '../../../environments/environment';
import { NewBaseSearch, NewBaseSearchService } from '../new-base.search-service';
import { OrderType } from '../util/services';
import { SearchUtils } from '../../util/search-utils';
import { DeliveryMethod } from './delivery-method.service';
import { LoggedInUserStorage } from '../util/storages';
/* eslint-enable */

@Injectable()
export class DeliveryMethodSearchService extends NewBaseSearchService<OrderField.DeliveryMethod> {

  protected createStorage(): DeliveryMethodSearch.Storage {
    return new DeliveryMethodSearch.Storage();
  }

}

export namespace DeliveryMethodSearch {

  export class Model extends SearchUtils.SearchModel {

    showSearch: boolean = false;

    name: string;
    externalId: string;
    _type: MultiselectOptionItem<DeliveryMethod.DeliveryMethodType>[];

    get type(): DeliveryMethod.DeliveryMethodType | undefined {
      if (this._type && this._type.length > 0 && this._type[0].id !== null) {
        return this._type[0].id!;
      }
      return undefined;
    }

    constructor() {
      super();
      this.reset();
    }

    reset() {
      this.name = '';
      this.externalId = '';
      this._type = [];
    }

    isEmpty(): boolean {
      return this.name === '' &&
        this.externalId === '' &&
        this._type.length === 0;
    }

  }

  export namespace OrderFunctions {
    export const ID = (f: OrderField.DeliveryMethod) => f.id;
    export const CREATION_TIME = (f: OrderField.DeliveryMethod) => f.creationTime;
    export const NAME = (f: OrderField.DeliveryMethod) => f.name;
    export const EXTERNAL_ID = (f: OrderField.DeliveryMethod) => f.externalId;

    export const VALUES = OrderFunctionResolver.builder<OrderField.DeliveryMethod>()
      .add(ID, 'id')
      .add(CREATION_TIME, 'creation_time')
      .add(NAME, 'name')
      .add(EXTERNAL_ID, 'external_id')
      .build();
  }

  export interface SearchDataResult {
    searchData: SearchData;
  }

  export interface SearchData extends NewBaseSearch.SearchData<OrderField.DeliveryMethod> {
    searchModel: Model;
  }

  interface StoredSearchRawData extends NewBaseSearch.StoredSearchRawData<OrderField.DeliveryMethod> {
    name: string;
    externalId: string;
    type: MultiselectOptionItem<DeliveryMethod.DeliveryMethodType>[];
  }

  export class Storage extends NewBaseSearch.Storage<OrderField.DeliveryMethod> {

    constructor() {
      const defaultSearchData: SearchData = {
        queryModel: new OrderFieldModel(OrderFunctions.ID, OrderType.DESC),
        searchModel: new Model(),
      };
      /**
       * Increment this version if you change the SearchData interface.
       */
      const versionNumber: number = 3;
      super(defaultSearchData,
        versionNumber);
    }

    protected generateKey(): string {
      return 'UserId(' + LoggedInUserStorage.getInstance().getUserId() + ')-Search-Delivery-Method';
    }

    protected fromRaw(data: StoredSearchRawData): NewBaseSearch.SearchDataResult<OrderField.DeliveryMethod> {
      const searchModel = new Model();
      searchModel.name = data.name;
      searchModel.externalId = data.externalId;
      searchModel._type = data.type;
      const searchData: SearchData = {
        queryModel: OrderFieldModel.create<OrderField.DeliveryMethod>(OrderFunctions.VALUES, data.order, data.queryModel),
        searchModel: searchModel,
      };
      return {
        searchData: searchData
      };
    }

    protected toRaw(data: SearchData): StoredSearchRawData {
      return {
        version: this.VERSION,
        queryModel: data.queryModel,
        order: data.queryModel.createOrder(OrderFunctions.VALUES),
        name: data.searchModel.name,
        externalId: data.searchModel.externalId,
        type: data.searchModel._type
      };
    }

  }

}
