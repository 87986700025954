import { Injectable } from '@angular/core';
import { MultiselectProvider } from '../multiselect-provider';
import { Observable, Observer } from 'rxjs';
import { MultiselectOptionItem, UiConstants } from '../../util/core-utils';
import { Set } from 'immutable';
import { OrderType, QueryResult } from '../util/services';
import { Project, ProjectService } from './project.service';

@Injectable()
export class ProjectMultiselectProvider implements MultiselectProvider {

  private multiselectQueryRequest: Project.QueryRequest = {
    paging: {
      pageNumber: 1,
      numberOfItems: UiConstants.autocompletePageSize
    },
    noProgressBar: true,
    orders: Set.of({field: Project.OrderField.NAME, type: OrderType.ASC}),
    disabled: undefined,
    queryText: undefined
  };

  constructor(private service: ProjectService) {
  }

  load(queryRequest: Project.QueryRequest): Observable<MultiselectOptionItem<number>[]> {
    return Observable.create((observer: Observer<MultiselectOptionItem<number>[]>) => {
      this.service.query(queryRequest).subscribe((result: QueryResult<Project.Project>) => {
          observer.next(result.items.map(item => this.toMultiselectOptionItem(item!)).toArray());
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  loadProjects(disabled?: boolean, query?: string): Observable<MultiselectOptionItem<number>[]> {
    const queryRequest = Object.assign([], this.multiselectQueryRequest);
    queryRequest.disabled = disabled;
    queryRequest.queryText = query;
    return this.load(queryRequest);
  }

  getById(id: number): Observable<MultiselectOptionItem<number>> {
    return Observable.create((observer: Observer<MultiselectOptionItem<number>>) => {
      this.service.get({
        id: id
      }).subscribe((result: Project.Project) => {
          observer.next(this.toMultiselectOptionItem(result!));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  getByIds(ids: number[]): Observable<MultiselectOptionItem<number>[]> {
    const queryRequest = Object.assign([], this.multiselectQueryRequest);
    queryRequest.id = Set.of(...ids);
    return this.load(queryRequest);
  }

  toMultiselectOptionItem(item: Project.Project): MultiselectOptionItem<number> {
    const ret = new MultiselectOptionItem<number>();
    ret.id = item.id;
    ret.itemName = item.name;
    ret.itemSubtitle = item.externalId;
    ret.disabled = item.disabled;
    return ret;
  }

}
