/* eslint-disable */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DqlSearchFieldComponent } from '../dql-search-field.component';
import {
  ComplexDqlExpressionBuilder,
  DqlBinaryCriteriaModel,
  DqlCriteriaModel,
  DqlExpressionBuilder,
  DqlLogicalOperation,
  DqlLogicalOperationObject,
  DqlOperations,
  SimpleDqlExpressionBuilder
} from '../../dql-search-container/dql-search-container.model';
import { SearchItem } from './dql-search-field-list.model';
import { Angular2Multiselects } from '../../../../util/multiselect';
import { TranslateService } from '@ngx-translate/core';
import { RawListItem } from '../../../../lib/list-item/list-item-resource.service';
import { QueryResult } from '../../../../lib/util/services';
import { ListItemMapService } from '../../../../lib/list-item/list-item-map.service';

/* eslint-enable */

@Component({
  selector: 'app-dql-search-field-list',
  templateUrl: './dql-search-field-list.component.html',
  styleUrls: ['./dql-search-field-list.component.scss']
})
export class DqlSearchFieldListComponent implements OnInit, DqlSearchFieldComponent {
  @Input()
  disableDelete: boolean = false;

  @Output()
  onDelete: EventEmitter<DqlExpressionBuilder> = new EventEmitter<DqlExpressionBuilder>();

  private _expr: ComplexDqlExpressionBuilder;

  criteriaTemplate: DqlCriteriaModel;

  private logical = new DqlLogicalOperationObject(DqlLogicalOperation.OR);

  @Input()
  set expression(exp: DqlExpressionBuilder) {
    if (exp instanceof SimpleDqlExpressionBuilder) {
      this.criteriaTemplate = exp.criteria;
      this._expr = new ComplexDqlExpressionBuilder();
      this.onItemSelect();
    }
    if (exp instanceof ComplexDqlExpressionBuilder) {
      this._expr = exp;
      this.criteriaTemplate = (<SimpleDqlExpressionBuilder>this._expr.expressionList[0]).criteria;
      this.loadSelectedValues(this._expr);
    }
    this._expr.closeExpression();
    this.criteriaTemplate.operationChanged().subscribe((op) => {
      if (op === DqlOperations.EQUALS) {
        this.logical.operation = DqlLogicalOperation.OR;
      }
      if (op === DqlOperations.NOT_EQUALS) {
        this.logical.operation = DqlLogicalOperation.AND;
      }
      this._expr.changeCriteriaOperation(op);
    });
  }

  get expression(): DqlExpressionBuilder {
    return this._expr;
  }

  items: SearchItem[] = [];
  selectedValue: SearchItem[] = [];

  dropdownSettings?: Angular2Multiselects.Settings;

  constructor(private translateService: TranslateService,
              private listItemService: ListItemMapService) {
  }

  ngOnInit() {
    this.listItemService.getList({
      type_id: this.criteriaTemplate.field!.listItemTypeId
    }).subscribe((result: QueryResult<RawListItem>) => {
      result.items.forEach(item => {
        if (item) {
          const i = {
            id: '' + item.id,
            itemName: item.text
          };
          this.items.push(i);
          const selected = this.selectedValue.find(v => v.id === i.id);
          if (selected) {
            selected.itemName = i.itemName;
          }
        }
      });
    });
    this.initDropDown();
  }

  initDropDown() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .build();
  }

  private loadSelectedValues(expr: ComplexDqlExpressionBuilder) {
    this.selectedValue = [];
    expr.expressionList.forEach(e => {
      if (e instanceof SimpleDqlExpressionBuilder) {
        const value = e.criteria.getValue();
        if (value.length > 0) {
          this.selectedValue.push({
            id: value,
            itemName: ''
          });
        }
      }
    });
  }

  onItemSelect(item?: SearchItem) {

    if (item) {
      let ex: DqlExpressionBuilder | undefined = undefined;
      this._expr.expressionList.forEach(e => {
        if (e instanceof SimpleDqlExpressionBuilder) {
          const value = e.criteria.getValue();
          if (value.length > 0 && value === item.id) {
            ex = e;
          }
        }
      });
      if (ex) {
        return;
      }
    }

    const criteria = new DqlBinaryCriteriaModel();
    criteria.field = this.criteriaTemplate.field;
    criteria.operation = this.criteriaTemplate.operation;
    const expr = new SimpleDqlExpressionBuilder();
    expr.addCriteria(criteria);
    expr.closeExpression();
    this._expr.addExpression(expr);
    if (this._expr.logicalList.length < this._expr.expressionCount - 1) {
      this._expr.addLogical(this.logical);
    }
    if (item) {
      criteria.value = item.id;
    }
  }

  onItemDeselect(item: SearchItem) {
    let ex: DqlExpressionBuilder | undefined = undefined;
    this._expr.expressionList.forEach(e => {
      if (e instanceof SimpleDqlExpressionBuilder) {
        const value = e.criteria.getValue();
        if (value.length > 0 && value === item.id) {
          ex = e;
        }
      }
    });
    if (ex) {
      this._expr.removeExpression(ex);
    }
  }

  onSelectAll() {
    this.items.forEach(item => {
      this.onItemSelect(item);
    });
  }

  onDeselectAll() {
    this.items.forEach(item => {
      this.onItemDeselect(item);
    });
  }

}
