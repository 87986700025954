<div class="modal-content">
  <div class="modal-header">
    <p class="modal-title"><strong>{{'TASK_RECORD_EXPORT_SUP_CREATE' | translate}}</strong></p>
    <button type="button" class="close" (click)="closeDialog(false)" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form [formGroup]='formGroup' (ngSubmit)="generateXml()" #f="ngForm">
    <div class="modal-body">
      <mat-dialog-content [ngStyle]="{'overflow': 'visible'}">
        <div>
          <div class="form-group row"
               [ngClass]="{ 'has-danger': hasLocalFieldError('journalCode')}">
            <div class="col-md-4">
              <label
                class="col-form-label mw-100 form-control-label"
                [class.required-field-label]="true">
                {{'TASK_RECORD_SUP_EXPORT_JOURNAL_CODE'|translate}}
              </label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <input type="text" class="form-control" placeholder="{{'TASK_RECORD_SUP_EXPORT_JOURNAL_CODE' | translate}}"
                       maxlength="3"
                       [(ngModel)]="model.journalCode"
                       formControlName="journalCode">
              </div>
              <div class="form-control-feedback"
                   *ngIf="hasLocalFieldError('journalCode', 'required')">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
          </div>

          <div class="form-group row"
               [ngClass]="{ 'has-danger': hasLocalFieldError('paymentType')}">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label required-field-label">
                {{'INVOICE_CREATE_PAYMENT_TYPE'|translate}}
              </label>
            </div>
            <div class="col-md-8">
              <angular2-multiselect [data]="paymentTypes" [settings]="offlineDropdownSettings"
                                    class="form-control"
                                    [(ngModel)]="model.paymentType"
                                    formControlName="paymentType">
              </angular2-multiselect>
              <div class="form-control-feedback"
                   *ngIf="hasLocalFieldError('paymentType')">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
          </div>
          <div class="form-group row"
               [ngClass]="{ 'has-danger': hasLocalFieldError('issueDatePicker')}">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label"
                     [class.required-field-label]="true">
                {{'INVOICE_CREATE_ISSUE_DATE'|translate}}
              </label>
            </div>
            <div class="col-md-8">
              <div class="clearfix row">
                <div class="col-sm">
                  <div>
                    <div class="row">
                      <div class="input-group col-md-8 mb-1 mb-md-0">
                        <input placeholder="{{'DATE_TIME_PICKER_PLACEHOLDER_DATE' | translate}}" type="text"
                               class="form-control"
                               [(ngModel)]="model.issueDate"
                               formControlName="issueDatePicker"
                               ngbDatepicker #issue_date_picker="ngbDatepicker"
                               validateLocalDate>
                        <span class="add-on input-group-addon-gray" (click)="issue_date_picker.toggle()">
                              <i class="icomoon icomoon-calendar"></i>
                            </span>
                      </div>
                    </div>
                    <div class="form-control-feedback"
                         *ngIf="(hasLocalFieldError('issueDatePicker', 'required'))">
                      {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                    </div>
                    <div class="form-control-feedback"
                         *ngIf="hasLocalFieldError('issueDatePicker', 'validateLocalDate')">
                      {{'COMMON_VALIDATION_MESSAGE_INVALID_LOCAL_DATE' | translate}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row"
               [ngClass]="{ 'has-danger': hasLocalFieldError('deliveryDatePicker')}">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label"
                     [class.required-field-label]="true">
                {{'INVOICE_CREATE_DELIVERY_DATE'|translate}}
              </label>
            </div>
            <div class="col-md-8">
              <div class="clearfix row">
                <div class="col-sm">
                  <div>
                    <div class="row">
                      <div class="input-group col-md-8 mb-1 mb-md-0">
                        <input placeholder="{{'DATE_TIME_PICKER_PLACEHOLDER_DATE' | translate}}" type="text"
                               class="form-control"
                               [(ngModel)]="model.deliveryDate"
                               formControlName="deliveryDatePicker"
                               ngbDatepicker #delivery_date_picker="ngbDatepicker"
                               validateLocalDate>
                        <span class="add-on input-group-addon-gray" (click)="delivery_date_picker.toggle()">
                              <i class="icomoon icomoon-calendar"></i>
                            </span>
                      </div>
                    </div>
                    <div class="form-control-feedback"
                         *ngIf="(hasLocalFieldError('deliveryDatePicker', 'required'))">
                      {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                    </div>
                    <div class="form-control-feedback"
                         *ngIf="hasLocalFieldError('deliveryDatePicker', 'validateLocalDate')">
                      {{'COMMON_VALIDATION_MESSAGE_INVALID_LOCAL_DATE' | translate}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row"
               [ngClass]="{ 'has-danger': hasLocalFieldError('deadlineOffset')}">
            <div class="col-md-4">
              <label
                class="col-form-label mw-100 form-control-label"
                [class.required-field-label]="true">
                {{'TASK_RECORD_SUP_EXPORT_DEADLINE_OFFSET'|translate}}
              </label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <input type="number" class="form-control" placeholder="{{'TASK_RECORD_SUP_EXPORT_DEADLINE_OFFSET' | translate}}"
                       [(ngModel)]="model.deadlineOffset"
                       formControlName="deadlineOffset">
              </div>
              <div class="form-control-feedback"
                   *ngIf="hasLocalFieldError('deadlineOffset', 'required')">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
          </div>


          <div class="alert alert-warning mb-0">
            <p class="mb-0">
            <span class="font-weight-bold">
              {{'TASK_RECORD_SUP_CREATE_EXPORT_WARNING' | translate: {number: data.taskRecordIds.size} }}
            </span>
            </p>
          </div>
        </div>
        <!-- /.modal-body -->
      </mat-dialog-content>
    </div>
    <div class="modal-footer justify-content-end">
        <span>
          <button type="button" class="btn btn-outline-primary" (click)="closeDialog(false)">
            {{'COMMON_BUTTON_CANCEL' | translate}}
          </button>
          <button type="submit" class="btn btn-primary ml-1"
                  [ladda]="exportInProgress"
                  [disabled]="exportInProgress">
            {{'COMMON_BUTTON_DOWNLOAD' | translate}}
          </button>
        </span>
    </div>
  </form>
</div><!-- /.modal-content -->
