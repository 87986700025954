import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UploadMimeType } from '../upload-mime-type';
import { FileUploadComponent } from '../flat/file-upload.component';
import { UiConstants } from '../../../util/core-utils';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-file-upload-material-dialog',
  templateUrl: './file-upload-material-dialog.component.html',
  styleUrls: ['./file-upload-material-dialog.component.scss']
})
export class FileUploadMaterialDialogComponent implements OnInit {
  UiConstants = UiConstants;
  @ViewChild('uploadComponent') uploadComponent: FileUploadComponent;
  result: boolean | undefined = undefined;

  constructor(public dialogRef: MatDialogRef<FileUploadMaterialDialogComponent, boolean>,
              @Inject(MAT_DIALOG_DATA) public data: FileUploadMaterialDialogData) {

  }

  public static openDialog(
    dialog: MatDialog,
    data: FileUploadMaterialDialogData,
    resultCallback: (boolean) => void) {
    const dialogRef = dialog.open(FileUploadMaterialDialogComponent, {
      closeOnNavigation: true,
      data: data,
      width: '60%',
      height: 'auto',
      panelClass: 'custom-dialog-container'
    });

    dialogRef.afterClosed().subscribe((value) => {
      resultCallback(value);
    });
  }

  ngOnInit() {
  }

  get uploadPath() {
    return this.data.uploadPath;
  }

  get acceptedMimeType() {
    return this.data.acceptedMimeType;
  }

  get titleKey() {
    return this.data.titleKey;
  }

  closeDialog() {
    this.uploadComponent.uploader.cancelAll();
    this.uploadComponent.uploader.clearQueue();
    const r = this.result;
    this.result = undefined;
    this.dialogRef.close(r);
  }

  onResult(result: boolean) {
    this.result = result;
  }
}

export class FileUploadMaterialDialogData {
  titleKey: string = 'COMMON_IMPORT_TITLE';
  uploadPath: string;
  acceptedMimeType?: UploadMimeType;
}
