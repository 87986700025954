/* eslint-disable */
import { AfterViewInit, Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { Transition, UIRouter } from '@uirouter/angular';
import { Arrays } from '../../../../lib/util/arrays';
import { StateName } from '../../../../app.state-names';
import { StringKey } from '../../../../app.string-keys';
import { TranslateService } from '@ngx-translate/core';
import {
  ListItemFieldErrorMap,
  ListItemParam,
  listItemParamTypes,
  ListItemResourceService,
  RawListItem,
  RawListTypeItem
} from '../../../../lib/list-item/list-item-resource.service';
import { FieldError, FieldErrors, ObservableErrorResourceParser } from '../../../../lib/util/errors';
import { ListItemEditModel, ListItemParamTypeItem, ListItemTypeItem } from '../../../../util/list-item-utils';
import { RootCoreService } from '../../../../lib/root-core.service';
import { BreadcrumbParent } from '../../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { MultiselectOptionItem, OptionItem, SelectUtils, UiConstants } from '../../../../util/core-utils';
import { ResourceQueryResult } from '../../../../lib/util/services';
import { Angular2Multiselects } from '../../../../util/multiselect';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Strings } from '../../../../lib/util/strings';
import { NgModel } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

/* eslint-enable */

@Component({
  selector: 'app-list-item-edit',
  templateUrl: 'list-item-edit.component.html',
  styleUrls: ['list-item-edit.component.css']
})
export class ListItemEditComponent implements OnInit, AfterViewInit {

  SelectUtils = SelectUtils;
  UiConstants = UiConstants;

  @ViewChild('filterItemsInput')
  filterItemsInput: NgModel;

  componentEmbedded: boolean = false;

  listItemTypes: ListItemTypeItem[] = [];
  filterItems: MultiselectOptionItem<number>[] = [];
  filterItemIdsToLoad: number[] = [];
  paramTypes: ListItemParamTypeItem[];
  model: ListItemEditModel;
  fieldErrors: ListItemFieldErrorMap;
  listItemId: number;
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  dropdownSettings: Angular2Multiselects.Settings;
  filterItemDropdownSettings: Angular2Multiselects.Settings;

  private typeQuerySubject: Subject<string> = new Subject<string>();

  firstLoad: boolean = true;

  constructor(private translateService: TranslateService,
              private rootService: RootCoreService,
              private listItemService: ListItemResourceService,
              private uiRouter: UIRouter,
              @Optional() public dialogRef: MatDialogRef<ListItemEditComponent>,
              @Optional() @Inject(MAT_DIALOG_DATA) private dialogData: any,
              @Optional() private transition: Transition) {
    this.model = new ListItemEditModel();
    this.fieldErrors = {};
    if (this.dialogData) {
      this.componentEmbedded = true;
      this.listItemId = this.dialogData.id;
    } else {
      this.listItemId = this.transition.params().id;
    }
  }

  ngOnInit() {
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('MENU_NAVBAR_LIST_ITEM').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.LIST_ITEM_LIST});
      }
    );
    this.initListItemTypeLoader();
    this.initDropdown();
  }

  ngAfterViewInit(): void {
    this.loadModel();
    this.loadParamTypes();
  }

  private initDropdown() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .labelKey(OptionItem.KEY_TEXT)
      .remoteSearch(true)
      .build();
    this.filterItemDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .remoteSearch(true)
      .build();
  }

  private createDefaultListItemType(): ListItemTypeItem {
    const def = new ListItemTypeItem();
    def.id = null;
    def.text = '';
    this.translateService.get(StringKey.COMMON_VALUE_UNSELECTED).subscribe(
      (text: string) => {
        def.text = text;
      }
    );
    return def;
  }

  private loadParamTypes() {
    this.paramTypes = [];
    listItemParamTypes.forEach((type) => {
      const item = {
        id: type.type,
        text: '....'
      };
      this.paramTypes.push(item);
      this.translateService.get(type.stringKey).subscribe((text: string) => {
        item.text = text;
      });
    });
  }

  loadModel() {
    this.listItemService.get({
      id: this.listItemId
    }).subscribe((listItem: RawListItem) => {
      this.breadcrumbSelf = listItem.code ? listItem.code : listItem.text;
      this.model.code = (listItem.code) ? listItem.code : '';
      this.model.text = listItem.text;
      this.model.params = listItem.params;

      this.filterItemIdsToLoad = listItem.filter_item_ids;
      this.loadListItemTypes(listItem.type);
    });
  }

  initListItemTypeLoader() {
    this.typeQuerySubject.pipe(debounceTime(this.firstLoad ? 0 : UiConstants.autocompleteDebounceTime)).subscribe(q => {
      this.listItemService.getTypeList({
        disabled: false,
        number_of_items: 50,
        page_number: 1,
        order: 'key',
        key: q,
        no_progress_bar: true
      }).subscribe((result: ResourceQueryResult<RawListTypeItem>) => {
        this.listItemTypes = [];
        const type = this.firstLoad ? q : undefined;
        this.firstLoad = false;
        Arrays.iterateByIndex(result.items, (listItemType) => {
          const item = this.itemFromType(listItemType);
          if (listItemType.key === type) {
            this.model.type = item;
            if (this.filterItemIdsToLoad.length > 0) {
              this.loadFilterItems(undefined, this.filterItemIdsToLoad);
            }
          }
          this.listItemTypes.push(item);
        });
        if (type && !this.model.type) {
          this.listItemService.getTypeList({
            number_of_items: 1,
            page_number: 1,
            key: type
          }).subscribe((result: ResourceQueryResult<RawListTypeItem>) => {
            if (result.items.length === 1) {
              const listItemType = result.items[0];
              const item = this.itemFromType(listItemType);
              if (item.id === type) {
                this.model.type = item;
                if (this.filterItemIdsToLoad.length > 0) {
                  this.loadFilterItems(undefined, this.filterItemIdsToLoad);
                }
              }
            }
          });
        }
      });
    });
  }

  private itemFromType(listItemType: RawListTypeItem) {
    const item = new ListItemTypeItem();
    item.id = listItemType.key;
    item.text = listItemType.key;
    item.params = listItemType.params;
    item.disabled = listItemType.disabled;
    item.filterTypeId = listItemType.filter_type ? listItemType.filter_type.id : undefined;
    return item;
  }

  loadListItemTypes(type?: string, q?: string) {
    this.typeQuerySubject.next(type ? type : q);
  }

  loadFilterItems(q?: string, filterItemIds?: number[]) {
    this.listItemService.getList({
      type_id: this.model.type!.filterTypeId,
      text: q ? Strings.undefinedOrNonEmpty(q) : undefined,
      order: '+text',
      disabled: false,
      page_number: 1,
      number_of_items: UiConstants.autocompletePageSize,
      no_progress_bar: true
    }).subscribe(result => {
      this.filterItems = result.items.map(i => ({id: i.id, itemName: i.text}));
      if (filterItemIds && filterItemIds.length > 0) {
        const notFoundIds: number[] = [];
        filterItemIds.forEach(id => {
          const item = this.filterItems.find(i => i.id === id);
          if (item) {
            this.model.filterItems.push(item);
          }
          else {
            notFoundIds.push(id);
          }
        });
        if (notFoundIds.length > 0) {
          this.listItemService.getList({
            type_id: this.model.type!.filterTypeId,
            id: notFoundIds.join()
          }).subscribe(result => {
            result.items.forEach(i => {
              this.model.filterItems.unshift({
                id: i.id,
                itemName: i.text,
                disabled: i.disabled
              });
              this.filterItemsInput.control.updateValueAndValidity();
            });
          });
        }
      }
    });
  }

  onListItemTypeChange() {
    this.removeFieldError(this.fieldErrors.type);
    this.model.params = [];
    if (this.model.type) {
      this.model.type.params.forEach((param) => {
        this.model.params.push({
          param_type: param.param_type,
          key: param.key,
          string_value: param.default_string_value,
          number_value: param.default_number_value
        });
      });
    }
    this.model.filterItems = [];
    if (this.model.type && this.model.type.filterTypeId) {
      this.loadFilterItems();
    }
  }

  getParamTypeString(param: ListItemParam): string {
    const result = this.paramTypes.find((p) => p.id === param.param_type);
    return result!.text;
  }

  back() {
    window.history.back();
  }

  removeFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.fieldErrors, fieldError);
  }

  update() {

    let type: string | undefined = undefined;
    if (this.model.type && this.model.type.id) {
      type = this.model.type.id;
    }

    if (type === undefined) {
      return;
    }

    this.listItemService.update({
      id: this.listItemId,
      code: this.model.code,
      type: type,
      text: this.model.text,
      params: this.model.params,
      filter_item_ids: this.model.filterItemIds
    }).subscribe(response => {
      if (this.componentEmbedded) {
        this.closeDialog(true);
      } else {
        this.uiRouter.stateService.go(StateName.LIST_ITEM_LIST);
      }
    }, error => {
      const res = ObservableErrorResourceParser.parseError(error);
      this.fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
    });
  }

  closeDialog(result: boolean) {
    if (this.dialogRef) {
      this.dialogRef.close(result);
    }
  }
}
