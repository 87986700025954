<div bsModal [config]="UiConstants.modalConfig" #saveDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     (onHide)="onModalHide($event)"
     id="dqlSaveDialog"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="saveDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'DQL_SEARCH_STORED_QUERY_SAVE' | translate}}</h4>
        <button type="button" class="close" (click)="closeSaveDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]='querySaveForm' (ngSubmit)="saveQuery()" class="modal-body border-top-1">

          <div class="form-group row"
               [ngClass]="{ 'has-danger': (querySaveForm.controls['name'].touched && !querySaveForm.controls['name'].valid)}">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label">{{'DQL_SEARCH_STORED_QUERY_NAME'|translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <input type="text" class="form-control" placeholder="{{'DQL_SEARCH_STORED_QUERY_NAME' | translate}}"
                       maxlength="{{UiConstants.maximumVarcharLength}}"
                       [(ngModel)]="model.name"
                       formControlName="name">
                <div class="form-control-feedback" *ngIf="querySaveForm.controls['name'].touched && querySaveForm.controls['name'].hasError('required')">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label">{{'DQL_SEARCH_STORED_QUERY_QUERY'|translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <label class="col-form-label mw-100 form-control-label">{{model.query}}</label>
              </div>
            </div>
          </div>
          <div class="form-group row"
               [ngClass]="{ 'has-danger': (querySaveForm.controls['userGroup'].touched && !querySaveForm.controls['userGroup'].valid)}">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label">{{'DQL_SEARCH_STORED_QUERY_USER_GROUP'|translate}}</label>
            </div>
            <div class="col-md-8">
              <angular2-multiselect [data]="userGroups" [settings]="dropdownSettings"
                                    class="form-control"
                                    [(ngModel)]="model.userGroups"
                                    (onRemoteSearch)="loadUserGroups(undefined, $event.target.value)"
                                    formControlName="userGroup">
              </angular2-multiselect>
              <div class="form-control-feedback" *ngIf="querySaveForm.controls['userGroup'].touched && querySaveForm.controls['userGroup'].hasError('hasDisabledItem')">
                {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
              </div>
            </div>
          </div>

        </form>
      </div>
      <div class="d-flex align-items-center justify-content-between border-top p-1">
        <div>
          <button *ngIf="model.id" type="button" class="btn btn-primary"
                  (click)="deleteStoredQuery()">{{'COMMON_BUTTON_DELETE' |
              translate}}
          </button>
        </div>
        <div>
          <button type="button" class="btn btn-secondary" (click)="closeSaveDialog()">
            {{'COMMON_BUTTON_CANCEL' | translate}}
          </button>
          <button type="submit" class="btn btn-primary-green ml-1"
                  (click)="saveQuery()">{{'COMMON_BUTTON_ADD' |
              translate}}
          </button>
        </div>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
