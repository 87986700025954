/* eslint-disable */
import { FormEditFieldCreateDialogView, FormEditFieldDialogViewFactory, FormEditFieldUpdateDialogView } from './form-utils';
import { ComponentFactoryResolver, ComponentRef, Injector } from '@angular/core';
import { FormEditCreateUpdateDocumentFieldDialogComponent } from '../../admin/form/form-edit/dialogs/fields/document/create-update/form-edit-create-update-document-field-dialog.component';
/* eslint-enable */

export class FormEditDocumentFieldDialogViewFactory implements FormEditFieldDialogViewFactory {

  constructor(
    private injector: Injector,
    private cfr: ComponentFactoryResolver) {
  }

  createCreateView(): ComponentRef<FormEditFieldCreateDialogView> {
    return this.createView();
  }

  createUpdateView(): ComponentRef<FormEditFieldUpdateDialogView> {
    return this.createView();
  }

  private createView(): ComponentRef<FormEditCreateUpdateDocumentFieldDialogComponent> {
    return this.cfr.resolveComponentFactory(FormEditCreateUpdateDocumentFieldDialogComponent)
      .create(this.injector);
  }

}
