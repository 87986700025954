<div class="modal-content">
  <div class="modal-header">
    <p class="modal-title"><strong>{{getTitleKey() | translate}}</strong></p>
    <button type="button" class="close" (click)="closeDialog(false)" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form [formGroup]='formGroup' (ngSubmit)="submitWorklog()" #f="ngForm">
    <div class="modal-body">
      <mat-dialog-content [ngStyle]="{'overflow': 'visible'}">
        <div>
          <div class="form-group row"
               *ngIf="isCreateView()"
               [ngClass]="{ 'has-danger': hasLocalFieldError('users') || hasFieldError(Worklog.ValidatedField.USER_IDS)}">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label required-field-label">
                {{'COMMON_USER'|translate}}
              </label>
            </div>
            <div class="col-md-8">
              <angular2-multiselect [data]="users" [settings]="dropdownSettings"
                                    class="form-control"
                                    [(ngModel)]="model.users"
                                    [ngClass]="{ 'form-control-danger': hasLocalFieldError('users') || hasFieldError(Worklog.ValidatedField.USER_IDS) }"
                                    (onRemoteSearch)="loadUsers($event.target.value)"
                                    formControlName="users"
                                    [readonly]="data.worklog">
              </angular2-multiselect>
              <div class="form-control-feedback"
                   *ngIf="hasLocalFieldError('users')">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
          </div>

          <div class="form-group row"
               [ngClass]="{
                'has-danger': hasLocalFieldError('startTimeDate')
             || hasLocalFieldError('startTimeTime')
             || hasLocalFormError('timeInterval')
             || hasFieldError(Worklog.ValidatedField.START_TIME)}"
          >
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label required-field-label">{{'WORKLOG_START_TIME'|translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="row">

                <div class="input-group col-md-6 mb-1 mb-md-0">
                  <input type="text" class="form-control"
                         formControlName="startTimeDate"
                         [(ngModel)]="model.startTimeDate"
                         (ngModelChange)="updateDateTimeFields()"
                         [ngClass]="{ 'form-control-danger': hasLocalFieldError('startTimeDate') || hasLocalFormError('timeInterval') || hasFieldError(Worklog.ValidatedField.START_TIME)}"
                         ngbDatepicker #date_picker="ngbDatepicker">
                  <span class="add-on input-group-addon-gray" (click)="date_picker.toggle()">
                  <i class="icomoon icomoon-calendar"></i>
                </span>
                </div>

                <div class="input-group col-md-6">
                  <input type="text" class="form-control hidden-value-when-disabled"
                         formControlName="startTimeTime"
                         [(ngModel)]="model.startTimeTime"
                         (ngModelChange)="updateDateTimeFields()"
                         [ngClass]="{ 'form-control-danger': hasLocalFieldError('startTimeTime') || hasLocalFormError('timeInterval') || hasFieldError(Worklog.ValidatedField.START_TIME)}"
                         [seconds]="false"
                         [allowEmptyValue]="false"
                         ngbTimepicker #time_picker="ngbTimepicker" [toggleButtonId]="'time_picker_toggle_button'"
                         [spinners]="true" [hourStep]="1" [minuteStep]="10">
                  <span class="add-on input-group-addon-gray"
                        id="time_picker_toggle_button"
                        (click)="time_picker.toggle()">
                  <i class="icomoon icomoon-time"></i>
                </span>
                </div>
              </div>
              <div class="form-control-feedback"
                   *ngIf="hasLocalFieldError('startTimeDate', 'required')
                 || hasLocalFieldError('startTimeTime', 'required')">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
              <div class="form-control-feedback"
                   *ngIf="hasLocalFieldError('startTimeDate', 'validateMaxNgbDateTime')">
                {{'COMMON_VALIDATION_MESSAGE_INVALID_LOCAL_DATE_FROM_TO' | translate}}
              </div>
              <div class="form-control-feedback" *ngIf="hasFieldError(Worklog.ValidatedField.START_TIME)">
                {{getFieldErrorText(Worklog.ValidatedField.START_TIME)}}
              </div>
            </div>
          </div>

          <div class="form-group row"
               [ngClass]="{ 'has-danger': hasLocalFieldError('endTimeDate')
             || hasLocalFieldError('endTimeTime')
             || hasLocalFormError('timeInterval')
             || hasFieldError(Worklog.ValidatedField.END_TIME)}">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label required-field-label">{{'WORKLOG_END_TIME'|translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="row">

                <div class="input-group col-md-6 mb-1 mb-md-0">
                  <input type="text" class="form-control"
                         formControlName="endTimeDate"
                         [(ngModel)]="model.endTimeDate"
                         (ngModelChange)="updateDateTimeFields()"
                         [ngClass]="{ 'form-control-danger': hasLocalFieldError('endTimeDate') || hasLocalFormError('timeInterval') || hasFieldError(Worklog.ValidatedField.END_TIME)}"
                         ngbDatepicker #end_date_picker="ngbDatepicker">
                  <span class="add-on input-group-addon-gray" (click)="end_date_picker.toggle()">
                  <i class="icomoon icomoon-calendar"></i>
                </span>
                </div>

                <div class="input-group col-md-6">
                  <input type="text" class="form-control hidden-value-when-disabled"
                         formControlName="endTimeTime"
                         [(ngModel)]="model.endTimeTime"
                         (ngModelChange)="updateDateTimeFields()"
                         [ngClass]="{ 'form-control-danger': hasLocalFieldError('endTimeTime') || hasLocalFormError('timeInterval') || hasFieldError(Worklog.ValidatedField.END_TIME)}"
                         [seconds]="false"
                         [allowEmptyValue]="false"
                         ngbTimepicker #end_time_picker="ngbTimepicker"
                         [toggleButtonId]="'end_time_picker_toggle_button'"
                         [spinners]="true" [hourStep]="1" [minuteStep]="10">
                  <span class="add-on input-group-addon-gray"
                        id="end_time_picker_toggle_button"
                        (click)="end_time_picker.toggle()">
                  <i class="icomoon icomoon-time"></i>
                </span>
                </div>
              </div>
              <div class="form-control-feedback"
                   *ngIf="hasLocalFieldError('endTimeDate', 'required')
                 || hasLocalFieldError('endTimeTime', 'required')">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
              <div class="form-control-feedback"
                   *ngIf="hasLocalFieldError('endTimeDate', 'validateMinNgbDateTime')">
                {{'COMMON_VALIDATION_MESSAGE_INVALID_LOCAL_DATE_FROM_TO' | translate}}
              </div>
              <div class="form-control-feedback"
                   *ngIf="hasLocalFieldError('endTimeDate', 'validateMaxNgbDateTime')">
                {{'COMMON_VALIDATION_MESSAGE_FUTURE_IS_NOT_VALID' | translate}}
              </div>
              <div class="form-control-feedback" *ngIf="hasFieldError(Worklog.ValidatedField.END_TIME)">
                {{getFieldErrorText(Worklog.ValidatedField.END_TIME)}}
              </div>
            </div>
          </div>

          <div class="form-group row"
               [ngClass]="{ 'has-danger': hasLocalFieldError('loggedMinutes')
             || hasLocalFieldError('loggedHours')
             || hasFieldError(Worklog.ValidatedField.LOGGED_TIME)}">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label required-field-label">{{'WORKLOG_LOGGED_TIME'|translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="d-flex">
                <div class="flex-grow-1 mr-1">
                  <input type="text" class="form-control mb-1" placeholder="{{'TASK_ESTIMATED_TIME_HOURS' | translate}}"
                         [textMask]="{mask: InputMask.NATURAL_INTEGER, guide: false}"
                         [(ngModel)]="model.loggedHours" formControlName="loggedHours"
                         (ngModelChange)="removeFieldError(Worklog.ValidatedField.LOGGED_TIME)">
                  <input type="text" class="form-control" placeholder="{{'TASK_ESTIMATED_TIME_MINUTES' | translate}}"
                         [textMask]="{mask: InputMask.NATURAL_INTEGER, guide: false}"
                         [(ngModel)]="model.loggedMinutes" formControlName="loggedMinutes"
                         (ngModelChange)="removeFieldError(Worklog.ValidatedField.LOGGED_TIME)">
                </div>
                <div>
                  <div>
                    <label
                      class="col-form-label form-control-label mb-1">{{'TASK_ESTIMATED_TIME_HOURS'|translate}}</label>
                  </div>
                  <div>
                    <label class="col-form-label form-control-label">{{'TASK_ESTIMATED_TIME_MINUTES'|translate}}</label>
                  </div>
                </div>
              </div>
              <div class="form-control-feedback" *ngIf="hasLocalFieldError('loggedMinutes', 'max')">
                {{'COMMON_VALIDATION_MESSAGE_MAX' | translate: {maxValue: '59'} }}
              </div>
              <div class="form-control-feedback" *ngIf="hasFieldError(Worklog.ValidatedField.LOGGED_TIME)">
                {{getFieldErrorText(Worklog.ValidatedField.LOGGED_TIME)}}
              </div>
              <div class="form-control-feedback"
                   *ngIf="hasLocalFieldError('loggedHours', 'required')">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label">
                {{'WORKLOG_OVERTIME' | translate}}
              </label>
            </div>
            <div class="col-md-8">
              <label class="switch switch-text switch-info mb-0">
                <input type="checkbox" class="switch-input"
                       [ngModelOptions]="{standalone: true}"
                       [(ngModel)]="model.overtime">
                <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                <span class="switch-handle"></span>
              </label>
            </div>
          </div>


        </div>
        <!-- /.modal-body -->
      </mat-dialog-content>
    </div>
    <div class="modal-footer justify-content-end">
        <span>
          <button type="button" class="btn btn-outline-primary" (click)="closeDialog(false)">
            {{'COMMON_BUTTON_CANCEL' | translate}}
          </button>
          <button type="submit" class="btn btn-primary ml-1"
                  [ladda]="creationInProgress"
                  [disabled]="creationInProgress">
            {{'COMMON_BUTTON_SAVE' | translate}}
          </button>
        </span>
    </div>
  </form>
</div><!-- /.modal-content -->
