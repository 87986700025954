/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from './http-services';
import { ResourceQueryResult } from './services';

/* eslint-enable */

export interface Api {
  id: number;
  definition: string;
  definition_block: string;
  definition_scope: string;
  short_name: string;
}

export interface GetRequest {
}

@Injectable()
export class ApiOperationService extends BaseHttpService {

  get(request: GetRequest): Observable<ResourceQueryResult<Api>> {
    return this.http.get<ResourceQueryResult<Api>>(this.url, this.parseParams(request));
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/api-operations/');
  }

}
