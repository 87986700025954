/* eslint-disable */
import { Component, EventEmitter } from '@angular/core';
import {TaskRecordMapClusterModel} from "../task-record-list-map.component";
import {TaskRecordStateMachine} from "../../../../../../lib/task/task-record-statemachine";
import {TaskRecord} from "../../../../../../lib/task/task-record.service";
import {Address} from "../../../../../../lib/address";
import PostalAddressDataType = Address.PostalAddressDataType;
import PostalAddressStruct = Address.PostalAddressStruct;
import { DownloadedFile } from '../../../../../../lib/util/downloaded-files';
import { UserService } from '../../../../../../lib/user.service';
import { DomSanitizer } from '@angular/platform-browser';
/* eslint-enable */

@Component({
  selector: 'app-task-record-map-window',
  templateUrl: './task-record-map-info-window.component.html',
  styleUrls: ['./task-record-map-info-window.component.scss']
})
export class TaskRecordMapInfoWindowComponent {

  private _model: TaskRecordMapClusterModel;

  get model(): TaskRecordMapClusterModel {
    return this._model;
  }

  set model(value: TaskRecordMapClusterModel) {
    this._model = value;
    this.downloadProfilePicture();
  }

  profilePicture: any | null = null;

  onTaskRecordClicked: EventEmitter<TaskRecord.TaskRecord> = new EventEmitter<TaskRecord.TaskRecord>();
  onCustomerRecordClicked: EventEmitter<TaskRecord.TaskRecord> = new EventEmitter<TaskRecord.TaskRecord>();

  constructor(
    private userService: UserService,
    private sanitizer: DomSanitizer
  ) {}

  onTaskRecordOpen() {
    this.onTaskRecordClicked.emit(this._model.cluster.taskRecord);
  }

  onCustomerRecordOpen() {
    this.onCustomerRecordClicked.emit(this._model.cluster.taskRecord);
  }

  getTaskRecordStateObject(state: TaskRecordStateMachine.State): TaskRecordStateMachine.StateObject | undefined {
    return TaskRecordStateMachine.taskRecordStates.get(state);
  }

  displayPostalAddress(taskRecord: TaskRecord.TaskRecord): string {
    if (taskRecord.placeOfConsumption && taskRecord.placeOfConsumption.address) {
      if (taskRecord.placeOfConsumption.address.type === PostalAddressDataType.TEXT && taskRecord.placeOfConsumption.address.textValue) {
        return taskRecord.placeOfConsumption.address.textValue;
      }
      if (taskRecord.placeOfConsumption.address.type === PostalAddressDataType.COMPLEX &&
        taskRecord.placeOfConsumption.address.complexValue) {
        return this.complexAddressToString(taskRecord.placeOfConsumption.address.complexValue);
      }
    }
    return '';
  }

  // Only outputs part of the address to save space
  complexAddressToString(complexAddress: PostalAddressStruct): string {
    let simpleAddress = '';
    if (complexAddress.city) {
      simpleAddress = simpleAddress.concat(' ' + complexAddress.city);
    }
    if (complexAddress.address) {
      simpleAddress = simpleAddress.concat(' ' + complexAddress.address);
    }
    if (complexAddress.addressType) {
      simpleAddress = simpleAddress.concat(' ' + complexAddress.addressType);
    }
    if (complexAddress.houseNumber) {
      simpleAddress = simpleAddress.concat(' ' + complexAddress.houseNumber);
    }
    return simpleAddress;
  }

  downloadProfilePicture() {
    if (this.model.cluster.taskRecord
      && this.model.cluster.taskRecord.assignee
      && this.model.cluster.taskRecord.assignee.userData
      && this.model.cluster.taskRecord.assignee.userData.id) {
      this.userService.downloadProfilePicture(this.model.cluster.taskRecord.assignee.userData.id).subscribe(
        (res: DownloadedFile) => {
          this.profilePicture = URL.createObjectURL(res.getBlob());
        }
      );
    }
  }

}
