<div class="d-flex justify-content-around align-items-center step-overview">
  <ng-container *ngFor="let step of steps; let i = index; let last = last">
    <div class="d-flex flex-column justify-content-between align-items-center p-1 step"
         [ngClass]="{'step-active': i <= index, 'cursor-pointer': i < index}"
         (click)="i < index && jump(i)">
      <i class="icomoon {{step.iconClass}} step-icon"></i>
      <p class="step-title">{{step.title}}</p>
    </div>
    <div *ngIf="!last">
      <i class="icomoon icomoon-chevron-right step-chevron"></i>
    </div>
  </ng-container>
</div>
<div class="p-1">
  <ng-content>
  </ng-content>
</div>
