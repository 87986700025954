/* eslint-disable */
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { DownloadedFile, } from '../../util/downloaded-files';
import { Observable } from 'rxjs';
import { IconResource } from '../../task/icon.service';
import { FormResource, FormResourcePathGenerator } from '../../form/form-resource.service';
import { UrlBuilder } from '../../../util/url-builder';
import { ResourceQueryResult } from '../../util/services';
import { BaseHttpService, ResourceHelper } from '../../util/http-services';
import { HttpClient } from '@angular/common/http';
import { EmptyMessage } from '../../util/messages';
/* eslint-enable */

@Injectable()
export class LegacyWorkflowTaskResourceService extends BaseHttpService {

  public static workflow_id: number = 0;

  // <editor-fold desc="CRUD">

  query(request: LegacyWorkflowTaskResource.QueryRequest): Observable<ResourceQueryResult<LegacyWorkflowTaskResource.WorkflowTask>> {
    return this.http.get<ResourceQueryResult<LegacyWorkflowTaskResource.WorkflowTask>>
    (this.url + `${request.workflow_id}/tasks`, this.parseParams(request));
  }

  get(request: LegacyWorkflowTaskResource.GetRequest): Observable<LegacyWorkflowTaskResource.WorkflowTask> {
    return this.http.get<LegacyWorkflowTaskResource.WorkflowTask>
    (this.url + `${request.workflow_id}/tasks/${request.workflow_task_id}`);
  }

  create(request: LegacyWorkflowTaskResource.CreateRequest): Observable<LegacyWorkflowTaskResource.WorkflowTaskIdentity> {
    return this.http.post<LegacyWorkflowTaskResource.WorkflowTaskIdentity>
    (this.url + `${request.workflow_id}/tasks`, request);
  }

  update(request: LegacyWorkflowTaskResource.UpdateRequest): Observable<LegacyWorkflowTaskResource.WorkflowTask> {
    return this.http.put<LegacyWorkflowTaskResource.WorkflowTask>
    (this.url + `${request.workflow_id}/tasks/${request.workflow_task_id}`, request);
  }

  setDisabled(request: LegacyWorkflowTaskResource.DisableRequest): Observable<LegacyWorkflowTaskResource.WorkflowTask> {
    return this.http.patch<LegacyWorkflowTaskResource.WorkflowTask>
    (this.url + `${request.workflow_id}/tasks/${request.workflow_task_id}/disabled`, request);
  }

  moveField(request: LegacyWorkflowTaskResource.MoveFieldRequest): Observable<LegacyWorkflowTaskResource.MoveFieldResponse> {
    return this.http.post<LegacyWorkflowTaskResource.MoveFieldResponse>
    (this.url + `${request.workflow_id}/tasks/${request.workflow_task_id}/move/${request.index}`, request);
  }

  // </editor-fold>

  // <editor-fold desc="Form">

  getForm(request: FormResource.GetFormRequest): Observable<FormResource.Form> {
    return this.http.get<FormResource.Form>(
      this.url + `${LegacyWorkflowTaskResourceService.workflow_id}/tasks/` + FormResourcePathGenerator.GetForm(request));
  }

  createFormGroup(request: FormResource.CreateGroupRequest): Observable<FormResource.CreateGroupResponse> {
    return this.http.post<FormResource.CreateGroupResponse>(
      this.url + `${LegacyWorkflowTaskResourceService.workflow_id}/tasks/` + FormResourcePathGenerator.CreateGroup(request), request
    );
  }

  updateFormGroup(request: FormResource.UpdateGroupRequest): Observable<FormResource.UpdateGroupResponse> {
    return this.http.put<FormResource.UpdateGroupResponse>(
      this.url + `${LegacyWorkflowTaskResourceService.workflow_id}/tasks/` + FormResourcePathGenerator.UpdateGroup(request), request
    );
  }

  disableFormGroup(request: FormResource.DisableGroupRequest): Observable<FormResource.DisableGroupResponse> {
    return this.http.patch<FormResource.DisableGroupResponse>(
      this.url + `${LegacyWorkflowTaskResourceService.workflow_id}/tasks/` + FormResourcePathGenerator.DisableGroup(request), request
    );
  }

  moveFormGroup(request: FormResource.MoveGroupRequest): Observable<FormResource.MoveGroupResponse> {
    return this.http.post<FormResource.MoveGroupResponse>(
      this.url + `${LegacyWorkflowTaskResourceService.workflow_id}/tasks/` + FormResourcePathGenerator.MoveGroup(request), null
    );
  }

  createFormField(request: FormResource.CreateFieldRequest): Observable<FormResource.CreateFieldResponse> {
    return this.http.post<FormResource.CreateFieldResponse>(
      this.url + `${LegacyWorkflowTaskResourceService.workflow_id}/tasks/` + FormResourcePathGenerator.CreateField(request), request
    );
  }

  updateFormField(request: FormResource.UpdateFieldRequest): Observable<FormResource.UpdateFieldResponse> {
    return this.http.post<FormResource.UpdateFieldResponse>(
      this.url + `${LegacyWorkflowTaskResourceService.workflow_id}/tasks/` + FormResourcePathGenerator.UpdateField(request), request
    );
  }

  disableFormField(request: FormResource.DisableFieldRequest): Observable<FormResource.DisableFieldResponse> {
    return this.http.patch<FormResource.DisableFieldResponse>(
      this.url + `${LegacyWorkflowTaskResourceService.workflow_id}/tasks/` + FormResourcePathGenerator.DisableField(request), request
    );
  }

  moveFormFieldToGroup(request: FormResource.MoveFieldToGroupRequest): Observable<FormResource.MoveFieldToGroupResponse> {
    return this.http.post<FormResource.MoveFieldToGroupResponse>(
      this.url + `${LegacyWorkflowTaskResourceService.workflow_id}/tasks/` + FormResourcePathGenerator.MoveFieldToGroup(request), null
    );
  }

  import(request: FormResource.ImportRequest): Observable<FormResource.Form> {
    return this.http.post<FormResource.Form>(
      this.url + `${LegacyWorkflowTaskResourceService.workflow_id}/tasks/` + FormResourcePathGenerator.Import(request), request
    );
  }

  export(request: FormResource.ExportRequest): Observable<FormResource.FormImportDocument> {
    return this.http.post<FormResource.FormImportDocument>(
      this.url + `${LegacyWorkflowTaskResourceService.workflow_id}/tasks/` + FormResourcePathGenerator.Export(request), null
    );
  }

  getPdfs(request: LegacyWorkflowTaskResource.GetPdfsRequest): Observable<ResourceQueryResult<LegacyWorkflowTaskResource.Pdf>> {
    return this.http.get<ResourceQueryResult<LegacyWorkflowTaskResource.Pdf>>
    (this.url + `${request.workflow_id}/tasks/${request.workflow_task_id}/pdf-templates/ad-hoc`);
  }

  deletePdf(request: LegacyWorkflowTaskResource.DeletePdfRequest): Observable<EmptyMessage> {
    return this.http.delete<EmptyMessage>
    (this.url + `${request.workflow_id}/tasks/${request.workflow_task_id}/pdf-templates/ad-hoc/${request.template_id}`);
  }

  updatePdf(request: LegacyWorkflowTaskResource.UpdatePdfRequest): Observable<EmptyMessage> {
    return this.http.put<EmptyMessage>
    (this.url + `${request.workflow_id}/tasks/${request.workflow_task_id}/pdf-templates/ad-hoc/${request.template_id}`, request);
  }

  downloadPdf(request: LegacyWorkflowTaskResource.DownloadPdfRequest): Observable<DownloadedFile> {
    const url = UrlBuilder.create(
      '/legacy-workflows/:workflowId/tasks/:workflowTaskId/pdf-templates/ad-hoc/:pdfTemplateId/download')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .namedNumber('workflowId', request.workflow_id)
      .namedNumber('workflowTaskId', request.workflow_task_id)
      .namedNumber('pdfTemplateId', request.template_id)
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob',
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  // </editor-fold>

  constructor(private http: HttpClient, private resourceHelper: ResourceHelper) {
    super(resourceHelper, '/legacy-workflows/');
  }

}

export namespace LegacyWorkflowTaskResource {

  // <editor-fold desc="CRUD">

  export interface WorkflowTask {
    workflow_id: number;
    workflow_task_id: number;
    disabled: boolean;
    creation_time: string;
    update_time: string;
    name: string;
    description: string;
    external_id: string,
    version: number;
    icon?: IconResource.Icon;
    in_progress_state_name: string;
    finished_state_name: string;
    customer_signature_enabled: boolean;
    user_signature_enabled: boolean;
  }

  export interface QueryRequest {
    workflow_id: number;
    workflow_task_id?: string;
    disabled?: boolean;
    name?: string;
    external_id?: string,
    description?: string,
    in_progress_state_name?: string;
    finished_state_name?: string;
    q?: string;
    fields?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
  }

  export interface GetRequest {
    workflow_id: number;
    workflow_task_id: number;
  }

  export interface WorkflowTaskIdentity {
    workflow_task_id: number;
  }

  export interface CreateRequest {
    workflow_id: number;
    name: string;
    description?: string;
    external_id?: string,
    in_progress_state_name: string;
    finished_state_name: string;
    customer_signature_enabled: boolean;
    user_signature_enabled: boolean;
  }

  export interface UpdateRequest {
    workflow_task_id: number;
    workflow_id: number;
    name: string;
    version: number;
    description?: string;
    external_id?: string,
    in_progress_state_name: string;
    finished_state_name: string;
    customer_signature_enabled: boolean;
    user_signature_enabled: boolean;
  }

  export interface DisableRequest {
    workflow_id: number;
    workflow_task_id: number;
    disabled: boolean;
  }

  export interface MoveFieldRequest {
    workflow_id: number;
    workflow_task_id: number;
    index: number;
  }

  export interface MoveFieldResponse {
  }

  export interface GetPdfsRequest {
    workflow_id: number;
    workflow_task_id: number;
  }

  export interface DeletePdfRequest {
    workflow_id: number;
    workflow_task_id: number;
    template_id: number;
  }

  export interface DownloadPdfRequest {
    workflow_id: number;
    workflow_task_id: number;
    template_id: number;
  }

  export interface UpdatePdfRequest {
    workflow_id: number;
    workflow_task_id: number;
    template_id: number;
    name: string;
    allowed_task_states_for_generation: string[];
  }

  export interface Pdf {
    template_id: number;
    creation_time: string;
    update_time: string;
    version: number;
    name: string;
    allowed_task_states_for_generation: string[];
  }

  // </editor-fold>

}
