<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [self]="breadcrumbSelf"
      [parents]="breadcrumbParents">
    </app-breadcrumb>
  </div>
  <div class="breadcrumb-menu d-flex">
    <!--CREATE-->
    <div
      *ngIf="rightModel.emailAddressTypeCreate.hasRight()"
      class="breadcrumb-button-icon-container cursor-pointer negative-margin-right"
      uiSref="Admin.EmailAddressTypeCreate">
      <a class="btn-setting d-flex align-items-center" title="{{'COMMON_ADD' | translate}}">
        <i class="icomoon icomoon-add"></i>
        <span class="breadcrumb-button-text-visible">{{'COMMON_ADD' | translate}}</span>
      </a>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="card">

    <div class="card-header">
      {{'MENU_NAVBAR_EMAIL_ADDRESS_TYPES' | translate}}
    </div>
    <div class="card-body">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header">
            <app-table-sorter-no-op
              [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op
              [text]="'COMMON_TABLE_HEADER_EXTERNAL_ID' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op
              [text]="'EMAIL_ADDRESS_TYPE_NAME_HU' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op
              [text]="'EMAIL_ADDRESS_TYPE_NAME_EN' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let emailAddressType of emailAddressTypesList" [disabledTableRow]="emailAddressType.disabled">
          <td class="responsive-table-column">
            <a class="id-text cursor-pointer"
               href="#" onclick="return false"
               uiSref="Admin.EmailAddressTypeDetail"
               [uiParams]="{ id: emailAddressType.id }"
               title="{{'COMMON_BUTTON_DETAILS' | translate}}">
              {{emailAddressType.id}}
            </a>
          </td>
          <td class="responsive-table-column">{{emailAddressType.externalId}}</td>
          <td class="responsive-table-column">{{emailAddressType.names.hu}}</td>
          <td class="responsive-table-column">{{emailAddressType.names.en}}</td>
          <td>
            <app-table-options-menu>
              <app-dropdown-item
                [iconClass]="'icomoon-detail'"
                [titleStringKey]="'COMMON_BUTTON_DETAILS'"
                class="cursor-pointer"
                uiSref="Admin.EmailAddressTypeDetail"
                [uiParams]="{ id: emailAddressType.id }">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="rightModel.emailAddressTypeUpdate.hasRight()"
                [iconClass]="'icomoon-modify'"
                [titleStringKey]="'COMMON_BUTTON_EDIT'"
                class="cursor-pointer"
                uiSref="Admin.EmailAddressTypeEdit"
                [uiParams]="{ id: emailAddressType.id }">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="!emailAddressType.disabled && rightModel.emailAddressTypeDisable.hasRight()"
                [iconClass]="'icomoon-disable icomoon-disable-table'"
                [titleStringKey]="'COMMON_BUTTON_DISABLE'"
                class="cursor-pointer"
                href="#" onclick="return false"
                (click)="setDisabled($event, emailAddressType.id, true)">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="emailAddressType.disabled && rightModel.emailAddressTypeDisable.hasRight()"
                [iconClass]="'icomoon-enable icomoon-enable-table'"
                [titleStringKey]="'COMMON_BUTTON_ENABLE'"
                class="cursor-pointer"
                href="#" onclick="return false"
                (click)="setDisabled($event, emailAddressType.id, false)">
              </app-dropdown-item>
            </app-table-options-menu>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header">
            <app-table-sorter-no-op
              [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op
              [text]="'COMMON_TABLE_HEADER_EXTERNAL_ID' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op
              [text]="'EMAIL_ADDRESS_TYPE_NAME_HU' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op
              [text]="'EMAIL_ADDRESS_TYPE_NAME_EN' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>
