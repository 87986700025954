/* eslint-disable */
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { StockItem, StockItemService, UnitPrice } from '../../../lib/stock/stock-item.service';
import {
  OrderStockItemCreateModel,
  STOCK_ITEM_VALID_CURRENCY_CODE
} from '../order-stock-item-container/order-stock-item-container.component';
import { Strings } from '../../../lib/util/strings';
import { StringKey } from '../../../app.string-keys';
import { OptionItem, UiConstants } from '../../../util/core-utils';
import { TranslateUtils } from '../../../util/translate';
import { Angular2Multiselects } from '../../../util/multiselect';
import { TranslateService } from '@ngx-translate/core';
import { NgForm } from '@angular/forms';
import { ResourceQueryResult } from '../../../lib/util/services';

/* eslint-enable */

@Component({
  selector: 'app-order-stock-item-edit',
  templateUrl: './order-stock-item-edit.component.html',
  styleUrls: ['./order-stock-item-edit.component.scss']
})
export class OrderStockItemEditComponent implements OnInit {

  m: OrderStockItemEditModel;

  stockItemsForDropdown: StockItemForDropdown[] = [];
  selectedStockItem: StockItemForDropdown[] = [];
  dropdownSettings: Angular2Multiselects.Settings;

  @Input()
  isRemovable: boolean = true;

  @ViewChild('f', { static: true })
  fForm: NgForm;

  @ViewChild('name', { static: true })
  name;

  @Input()
  itemError: any;

  @Input()
  set orderCompanyId(id: number) {
    this._orderCompanyId = id;
    this.loadStockItemsForDropdown();
  }

  _orderCompanyId: number;

  @Input()
  set model(model: OrderStockItemCreateModel) {
    this.m = {
      itemId: model.itemId,
      stockItemId: model.stockItemId,
      name: model.name,
      unitPrice: model.unitPrice,
      amount: model.amount,
      unit: model.unit,
      weightInGrams: model.weightInGrams,
      serialCode: model.serialCode,
      externalId: model.externalId
    };
  }

  get model(): OrderStockItemCreateModel {
    const model = new OrderStockItemCreateModel();
    model.itemId = this.m.itemId;
    model.stockItemId = this.m.stockItemId;
    model.name = this.m.name;
    model.unitPrice = this.m.unitPrice;
    model.amount = this.m.amount;
    model.unit = this.m.unit;
    model.weightInGrams = this.m.weightInGrams;
    model.serialCode = this.m.serialCode;
    model.externalId = this.m.externalId;
    model.editing = true;
    return model;
  }

  @Output()
  remove: EventEmitter<undefined> = new EventEmitter();

  @Output()
  save: EventEmitter<OrderStockItemCreateModel> = new EventEmitter<OrderStockItemCreateModel>();

  @Output()
  cancel: EventEmitter<undefined> = new EventEmitter();

  constructor(
    private stockItemService: StockItemService,
    private translateService: TranslateService
  ) {
  }

  ngOnInit() {
    this.initDropDown();
  }

  private initDropDown() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
  }

  loadStockItemsForDropdown() {
    this.stockItemsForDropdown = [];
    this.selectedStockItem = [];
    this.stockItemService.query({
      page_number: 1,
      number_of_items: 100,
      company_ids: this._orderCompanyId.toString(),
      order: '+name',
      disabled: false
    }).subscribe((result: ResourceQueryResult<StockItem>) => {
      result.items.forEach((stockItem) => {
        const item = {
          id: stockItem.id,
          itemName: stockItem.unit_price
            ? (stockItem.name
              + ' - ' + stockItem.external_id
              + ' - ' + stockItem.unit_price.value
              + ' ' + stockItem.unit_price.currency_code)
            : (stockItem.name
              + ' - '
              + stockItem.external_id),
          stockItem: stockItem
        };
        if (this.model.stockItemId === item.id) {
          this.selectedStockItem.push(item);
          this.fForm.controls['name'].setErrors(null);
        }
        this.stockItemsForDropdown.push(item);
      });
      // Loads selected stock item if not in first 100 result of search
      if (this.selectedStockItem.length === 0 && this.model.stockItemId > 0) {
        this.stockItemService.get({
          id: this.model.stockItemId
        }).subscribe((stockItem) => {
          const item = {
            id: stockItem.id,
            itemName: stockItem.unit_price
              ? (stockItem.name
                + ' - ' + stockItem.external_id
                + ' - ' + stockItem.unit_price.value
                + ' ' + stockItem.unit_price.currency_code)
              : (stockItem.name
                + ' - '
                + stockItem.external_id),
            stockItem: stockItem
          };
          this.selectedStockItem.push(item);
          this.fForm.controls['name'].setErrors(null);
        });
      }
    });
  }

  loadSelectedStockItem() {
    if (this.selectedStockItem[0].id) {
      const item = this.selectedStockItem[0].stockItem;
      this.m.stockItemId = item.id;
      this.m.name = item.name;
      this.m.unitPrice = item.unit_price;
      this.m.unit = item.unit;
      this.m.weightInGrams = item.weight_in_grams;
      this.m.serialCode = item.serial_code;
      this.m.externalId = item.external_id;
    }
    else {
      this.m.stockItemId = -1;
      this.m.name = '';
      this.m.unitPrice.value = 0;
      this.m.weightInGrams = 0;
      this.m.serialCode = '';
      this.m.externalId = '';
    }
  }

  onSearch(evt?: any) {
    this.stockItemService.query({
      q: evt ? Strings.undefinedOrNonEmpty(evt.target.value) : undefined,
      page_number: 1,
      number_of_items: 100,
      company_ids: this._orderCompanyId.toString(),
      order: '+name',
      disabled: false,
      no_progress_bar: true
    })
      .subscribe((result: ResourceQueryResult<StockItem>) => {
        this.stockItemsForDropdown = [];
        result.items.forEach((stockItem) => {
          const item = {
            id: stockItem.id,
            itemName: stockItem.unit_price
              ? (stockItem.name
                + ' - ' + stockItem.external_id
                + ' - ' + stockItem.unit_price.value
                + ' ' + stockItem.unit_price.currency_code)
              : (stockItem.name
                + ' - '
                + stockItem.external_id),
            stockItem: stockItem
          };
          this.stockItemsForDropdown.push(item);
        });
      });
  }

  saveItem() {
    this.save.emit(this.model);
  }

  hasError(field: string): boolean {
    if (this.itemError && this.itemError[field]) {
      return true;
    }
    return false;
  }

  getError(field: string): string {
    if (this.hasError(field)) {
      return this.itemError[field]['text'];
    }
    return '';
  }

  removeError(field: string) {
    if (this.hasError(field)) {
      this.itemError[field] = null;
    }
  }
}

interface StockItemForDropdown {
  id: number;
  itemName: string;
  stockItem: StockItem;
}

class OrderStockItemEditModel {
  itemId: number = 0;
  stockItemId: number = 0;
  name: string = '';
  unitPrice: UnitPrice = {
    value: 0,
    vat_rate: 0,
    currency_code: STOCK_ITEM_VALID_CURRENCY_CODE
  };
  amount: number = 0;
  unit: string = '';
  weightInGrams?: number = 0;
  serialCode: string = '';
  externalId: string = '';
}
