<td class="fit">
  <div class="d-flex align-items-center">
    <app-animated-checkbox
      (clickEvent)="toggleSelected()"
      [selected]="record.selected || allSelected">
    </app-animated-checkbox>
    <span class="record-id">{{record.id}}</span>
  </div>
</td>
<td class="responsive-table-column long">{{record.name}}
  <br/>
  <div class="table-text-subtitle">{{record.externalId}}</div>
</td>
<td class="responsive-table-column hidden-lg-down w-20">{{record.productCode}}</td>
<td class="responsive-table-column hidden-lg-down w-15">{{record.stockName}}</td>
<td class="responsive-table-column hidden-md-down w-10">{{record.amount}} {{record.unit}}
  <div *ngIf="record.packageData"
       class="table-text-subtitle">{{StockItemUtils.convertToPackageStr(record.amount, record.packageData)}}</div>
</td>
<td class="responsive-table-column hidden-sm-down w-15">{{record.unitPrice | number:undefined:'hu-HU'}} {{record.priceCurrency}}</td>
<td class="fit">
  <i class="icomoon icomoon-info record-info"
     [popover]="infoPopover"
     [outsideClick]="true"
     placement="bottom left"
     container="body"></i>
  <ng-template #infoPopover>
    <div class="record-info-name">{{record.name}}</div>
    <div class="record-info-external-id">{{record.externalId}}</div>
    <div class="record-info-price">
      {{'STOCK_RECORD_SELECTOR_DIALOG_RECORD_PRICE' | translate}} {{record.unitPrice | number:undefined:'hu-HU'}} {{record.priceCurrency}}
    </div>
    <div class="record-info-container">
      <span class="record-info-label">{{'STOCK_RECORD_SELECTOR_DIALOG_STOCK_NAME' | translate}}</span>
      <span class="record-info-description">{{record.stockName}}</span>
    </div>
    <div class="record-info-container">
      <span class="record-info-label">{{'STOCK_RECORD_SELECTOR_DIALOG_RECORD_AMOUNT' | translate}}</span>
      <span class="record-info-description">{{record.amount}} {{record.unit}}</span>
      <span *ngIf="record.packageData"
           class="table-text-subtitle">{{StockItemUtils.convertToPackageStr(record.amount, record.packageData)}}</span>
    </div>
    <div class="record-info-container">
      <span class="record-info-label">{{'STOCK_RECORD_SELECTOR_DIALOG_RECORD_VAT_RATE' | translate}}</span>
      <span class="record-info-description">{{record.vatRate}}%</span>
    </div>
    <div class="record-info-container" *ngIf="record.productCode">
      <span class="record-info-label">{{'STOCK_RECORD_SELECTOR_DIALOG_RECORD_PRODUCT_CODE' | translate}}</span>
      <span class="record-info-description">{{record.productCode}}</span>
    </div>
    <div class="record-info-container" *ngIf="record.hunVtszNumber">
      <span class="record-info-label">{{'STOCK_RECORD_SELECTOR_DIALOG_RECORD_HUN_VTSZ_NUMBER' | translate}}</span>
      <span class="record-info-description">{{record.hunVtszNumber}}</span>
    </div>
    <div class="record-info-container" *ngIf="record.weight">
      <span class="record-info-label">{{'STOCK_RECORD_SELECTOR_DIALOG_RECORD_WEIGHT' | translate}}</span>
      <span class="record-info-description">{{record.weight}}</span>
    </div>
    <div class="record-info-description" *ngIf="record.description">
      <span [innerHTML]="record.description"></span>
    </div>
  </ng-template>
</td>
