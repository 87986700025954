<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="customerName">
    </app-breadcrumb>
    <div class="breadcrumb-menu d-flex">
      <!--BREADCRUMB MENU-->
      <!--BULK CHANGE-->
      <div *ngIf="rightModel.customerRecordChangeOwner.hasRight()"
           class="breadcrumb-button-icon-container cursor-pointer"
           (click)="checkCustomerRecordIds(BulkActionType.OWNER_CHANGE)">
        <a class="btn-setting d-flex align-items-center"
           title="{{'CUSTOMER_RECORD_OWNER_CHANGE_TITLE' | translate}}">
          <i class="icomoon icomoon-deadline-alt"></i>
          <span class="breadcrumb-button-text-visible">{{'CUSTOMER_RECORD_OWNER_CHANGE_TITLE' | translate}}</span>
        </a>
      </div>
      <!--BULK CHANGE END-->
      <!--BULK TASK RECORD CREATE-->
      <div *ngIf="rightModel.taskRecordCreate.hasRight()"
           class="breadcrumb-button-icon-container cursor-pointer"
           (click)="checkCustomerRecordIds(BulkActionType.TASK_RECORD_CREATE)">
        <a class="btn-setting d-flex align-items-center"
           title="{{'CUSTOMER_RECORD_BULK_TASK_RECORD_CREATE' | translate}}">
          <i class="icomoon icomoon-new-task-customer"></i>
          <span class="breadcrumb-button-text-visible">{{'CUSTOMER_RECORD_BULK_TASK_RECORD_CREATE' | translate}}</span>
        </a>
      </div>
      <!--BULK TASK RECORD CREATE END-->
      <!--XLSX EXPORT-->
      <div
        *ngIf="rightModel.customerRecordExportFile.hasRight() && customerId"
        class="breadcrumb-button-icon-container cursor-pointer"
        (click)="exportXls()">
        <a class="btn-setting d-flex align-items-center" title="{{'COMMON_EXPORT_XLSX' | translate}}">
          <i class="icomoon icomoon-file-xlsx"></i>
          <span class="breadcrumb-button-text-visible">{{'COMMON_EXPORT_XLSX' | translate}}</span>
        </a>
      </div>
      <!--DROPDOWN FOR XLS EXPORT-->
      <div class="breadcrumb-button-icon-container-no-text cursor-pointer p-0"
           *ngIf="rightModel.customerRecordImportFile.hasRight() && customerId">
        <a class="dropdown dropdown-arrow-padding"
           dropdown title="{{'COMMON_EXPORT_XLSX' | translate}}"
           href="#" onclick="return false" dropdownToggle
           data-toggle="dropdown" role="button"
           aria-haspopup="true" aria-expanded="false">
          <i class="icomoon icomoon-dropdown">
          </i>
          <div class="dropdown-menu dropdown-menu-right"
               *dropdownMenu
               aria-labelledby="simple-dropdown">
          <span (click)="exportXlsTemplate()"
                class="btn-setting dropdown-item d-flex align-items-center"
                title="{{'CUSTOMER_RECORD_XLSX_IMPORT_SAMPLE_DOWNLOAD' | translate}}">
            <i class="icomoon icomoon-file-xlsx"></i>
            <span>{{'CUSTOMER_RECORD_XLSX_IMPORT_SAMPLE_DOWNLOAD' | translate}}</span>
          </span>
            <span (click)="downloadContactLocationsTemplate()"
                  class="btn-setting dropdown-item d-flex align-items-center"
                  title="{{'CUSTOMER_RECORD_LIST_DOWNLOAD_CONTACT_LOCATIONS_TEMPLATE' | translate}}">
                  <i class="icomoon icomoon-csv-import"></i>
                  <span>{{'CUSTOMER_RECORD_LIST_DOWNLOAD_CONTACT_LOCATIONS_TEMPLATE' | translate}}</span>
                </span>
          </div>
        </a>
      </div>
      <!--CUSTOMER RECORD CREATE-->
      <div *ngIf="customerListForCreate.length > 0"
           class="breadcrumb-button-icon-container cursor-pointer"
           (click)="createCustomer()">
        <a class="btn-setting d-flex align-items-center" title="{{'COMMON_BUTTON_ADD' | translate}}">
          <i class="icomoon icomoon-add"></i>
          <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_ADD' | translate}}</span>
        </a>
      </div>
      <!--CUSTOMER RECORD CREATE END-->
      <!--DROPDOWN FOR CUSTOMER RECORD CREATE-->
      <div *ngIf="customerListForCreate.length > 0 || rightModel.customerRecordImportFile.hasRight() || demoModeEnabled"
           class="breadcrumb-button-icon-container-no-text cursor-pointer p-0 negative-margin-right">
        <a class="dropdown dropdown-arrow-padding"
           dropdown title="{{'COMMON_BUTTON_ADD' | translate}}"
           href="#" onclick="return false" dropdownToggle
           data-toggle="dropdown" role="button"
           aria-haspopup="true" aria-expanded="false">
          <i class="icomoon icomoon-dropdown">
          </i>
          <div class="dropdown-menu dropdown-menu-right"
               *dropdownMenu
               aria-labelledby="simple-dropdown">
                <span (click)="openQuickCreateDialog()"
                      *ngIf="customerListForCreate.length > 0"
                      class="btn-setting dropdown-item d-flex align-items-center"
                      title="{{'CUSTOMER_RECORD_QUICK_CREATE' | translate}}">
                  <i class="icomoon icomoon-add"></i>
                  <span>{{'CUSTOMER_RECORD_QUICK_CREATE' | translate}}</span>
                </span>
            <span *ngIf="rightModel.customerRecordImportFile.hasRight() || demoModeEnabled"
                  (click)="rightModel.customerRecordImportFile.hasRight() ? importCustomer() : showDisabledDialog()"
                  class="btn-setting dropdown-item d-flex align-items-center"
                  title="{{'COMMON_BUTTON_IMPORT' | translate}}">
                  <div class="disabled-overlay"
                       *ngIf="demoModeEnabled && !rightModel.customerRecordImportFile.hasRight()"></div>
                  <i class="icomoon icomoon-csv-import"></i>
                  <span>{{'COMMON_BUTTON_IMPORT' | translate}}</span>
                </span>
            <span *ngIf="rightModel.customerRecordImportFile.hasRight() || demoModeEnabled"
                  (click)="rightModel.customerRecordImportFile.hasRight() ? contactLocationImportDialog.showDialog() : showDisabledDialog()"
                  class="btn-setting dropdown-item d-flex align-items-center"
                  title="{{'CUSTOMER_RECORD_LIST_IMPORT_CONTACT_LOCATIONS' | translate}}">
                  <div class="disabled-overlay"
                       *ngIf="demoModeEnabled && !rightModel.customerRecordImportFile.hasRight()"></div>
                  <i class="icomoon icomoon-csv-import"></i>
                  <span>{{'CUSTOMER_RECORD_LIST_IMPORT_CONTACT_LOCATIONS' | translate}}</span>
                </span>
            <span
              *ngIf="rightModel.customerRecordImportFile.hasRight() && (!customerId || !rightModel.customerRecordExportFile.hasRight())"
              (click)="downloadContactLocationsTemplate()"
              class="btn-setting dropdown-item d-flex align-items-center"
              title="{{'CUSTOMER_RECORD_LIST_DOWNLOAD_CONTACT_LOCATIONS_TEMPLATE' | translate}}">
                  <i class="icomoon icomoon-csv-import"></i>
                  <span>{{'CUSTOMER_RECORD_LIST_DOWNLOAD_CONTACT_LOCATIONS_TEMPLATE' | translate}}</span>
                </span>
          </div>
        </a>
      </div>
      <!--DROPDOWN FOR CUSTOMER RECORD CREATE END-->
      <!--BREADCRUMB MENU END-->
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding-half container-vertical-padding-half animated fadeIn">

  <div class="card">

    <div class="card-header">
      {{'COMMON_CUSTOMERS' | translate}}

      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()"
           title="{{'COMMON_SHOW_SEARCH_HINT' | translate}}"><i class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>

    <div class="card-body border-bottom" *ngIf="showSearch">
      <responsive-tabset #searchTabs>
        <responsive-tab heading="{{'SEARCH_MODE_SIMPLE' | translate}}" (selectTab)="activeSearchTab = 'simple'"
                        id="simple">
          <div class="row form-group">
            <div class="col-md-3">
              <label class="search-label">{{'COMMON_NAME' | translate}}</label>
              <input type="text" class="form-control"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     (keyup.enter)="onSearchClicked()"
                     placeholder="{{'COMMON_NAME' | translate}}"
                     [(ngModel)]="searchModel.name">
            </div>
            <div class="col-md-3">
              <label class="search-label">{{'CUSTOMER_RECORD_SEARCH_EXTERNAL_ID' | translate}}</label>
              <input type="text" class="form-control"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     (keyup.enter)="onSearchClicked()"
                     placeholder="{{'CUSTOMER_RECORD_SEARCH_EXTERNAL_ID' | translate}}"
                     [(ngModel)]="searchModel.externalId">
            </div>
            <div class="col-md-3">
              <label class="search-label">{{'COMMON_COMMENT' | translate}}</label>
              <input type="text" class="form-control"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     (keyup.enter)="onSearchClicked()"
                     placeholder="{{'COMMON_COMMENT' | translate}}"
                     [(ngModel)]="searchModel.comment">
            </div>
            <div class="col-md-3">
              <label class="search-label">{{'COMMON_EMAIL_ADDRESS' | translate}}</label>
              <input type="text" class="form-control"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     (keyup.enter)="onSearchClicked()"
                     placeholder="{{'COMMON_EMAIL_ADDRESS' | translate}}"
                     [(ngModel)]="searchModel.emailAddress">
            </div>
            <div class="col-md-3">
              <label class="search-label">{{'COMMON_PHONE_NUMBER' | translate}}</label>
              <input type="text" class="form-control"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     (keyup.enter)="onSearchClicked()"
                     placeholder="{{'COMMON_PHONE_NUMBER' | translate}}"
                     [(ngModel)]="searchModel.phoneNumber">
            </div>
            <div class="col-md-3">
              <label class="search-label">{{'CUSTOMER_RECORD_SEARCH_POSTAL_ADDRESS_CITY' | translate}}</label>
              <input type="text" class="form-control"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     (keyup.enter)="onSearchClicked()"
                     placeholder="{{'CUSTOMER_RECORD_SEARCH_POSTAL_ADDRESS_CITY' | translate}}"
                     [(ngModel)]="searchModel.postalAddressCity">
            </div>
            <div class="col-md-3">
              <label class="search-label">{{'CUSTOMER_RECORD_SEARCH_POSTAL_ADDRESS_ZIP_CODE' | translate}}</label>
              <input type="text" class="form-control"
                     maxlength="{{UiConstants.maxInputNumberLength}}"
                     (keyup.enter)="onSearchClicked()"
                     placeholder="{{'CUSTOMER_RECORD_SEARCH_POSTAL_ADDRESS_ZIP_CODE' | translate}}"
                     [(ngModel)]="searchModel.postalAddressZipCode">
            </div>
            <div class="col-md-3">
              <label class="search-label">{{'CUSTOMER_RECORD_SEARCH_POSTAL_ADDRESS_STREET' | translate}}</label>
              <input type="text" class="form-control"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     (keyup.enter)="onSearchClicked()"
                     placeholder="{{'CUSTOMER_RECORD_SEARCH_POSTAL_ADDRESS_STREET' | translate}}"
                     [(ngModel)]="searchModel.postalAddressStreet">
            </div>
            <div class="col-md-3">
              <label class="search-label">{{'CUSTOMER_RECORD_SEARCH_POSTAL_ADDRESS_HOUSE_NUMBER' | translate}}</label>
              <input type="text" class="form-control"
                     maxlength="{{UiConstants.maxInputNumberLength}}"
                     (keyup.enter)="onSearchClicked()"
                     placeholder="{{'CUSTOMER_RECORD_SEARCH_POSTAL_ADDRESS_HOUSE_NUMBER' | translate}}"
                     [(ngModel)]="searchModel.postalAddressHouseNumber">
            </div>
            <div class="col-md-3">
              <label class="search-label">{{'CUSTOMER_SEARCH_FIELD_TYPE'|translate}}</label>
              <angular2-multiselect
                [(ngModel)]="searchModel.customers"
                [ngModelOptions]="{standalone: true}"
                [data]="customers"
                (onRemoteSearch)="loadCustomerTypes($event.target.value)"
                [settings]="customerDropdownSettings">
              </angular2-multiselect>
            </div>
            <div class="col-md-3" *ngIf="fieldModel.isManaged(CustomerRecordFieldType.MAIN_CONTRACT_NUMBER) && rightModel.contractNumberRead.hasRight()">
              <label class="search-label">{{'CUSTOMER_RECORD_CONTRACT_NUMBER'|translate}}</label>
              <angular2-multiselect
                [(ngModel)]="searchModel.contractNumbers"
                [ngModelOptions]="{standalone: true}"
                [data]="contractNumbers"
                (onRemoteSearch)="loadContractNumbers($event.target.value)"
                [settings]="remoteSearchDropdownSettings">
              </angular2-multiselect>
            </div>
            <div class="col-md-3">
              <label class="search-label"> {{ 'COMMON_STATE' | translate }} </label>
              <select
                class="form-control"
                [(ngModel)]="searchModel.disabled">
                <option *ngFor="let item of disabledItems" [ngValue]="item.id">{{item.text}}</option>
              </select>
            </div>
            <div class="col-md-3" *ngIf="!customerId">
              <label class="search-label"> {{ 'CUSTOMER_RECORD_SEARCH_CONTACT_PERSON' | translate }} </label>
              <select class="form-control" [(ngModel)]="searchModel.contactPersonType"
                      id="contactPersonType" name="contactPersonType" #contactPersonType="ngModel">
                <option *ngFor="let item of searchBooleanItemObjects"
                        [ngValue]="item.value">{{item.stringKey | translate}}</option>
              </select>
            </div>
            <div class="col-md-3" *ngIf="customerId && customerContactPerson">
              <label class="search-label"> {{ 'CUSTOMER_RECORD_SEARCH_CONTACT_PERSON' | translate }} </label>
              <select class="form-control" [(ngModel)]="searchModel.contactPerson"
                      id="contactPerson" name="contactPerson" #contactPerson="ngModel">
                <option *ngFor="let item of searchBooleanItemObjects"
                        [ngValue]="item.value">{{item.stringKey | translate}}</option>
              </select>
            </div>
            <div class="col-md-3">
              <label class="search-label">{{'CUSTOMER_RECORD_SEARCH_USER' | translate}}</label>
              <angular2-multiselect [data]="usersForSearch" [settings]="simpleDropdownSettings"
                                    class="form-control"
                                    [(ngModel)]="searchModel.ownerUsers"
                                    #userMulti="ngModel" name="userMulti" id="userMulti">
              </angular2-multiselect>
            </div>
            <div class="col-md-3">
              <label class="search-label">{{'CUSTOMER_RECORD_SEARCH_USER_GROUP' | translate}}</label>
              <angular2-multiselect [data]="groupsForSearch" [settings]="remoteSearchDropdownSettings"
                                    class="form-control"
                                    [(ngModel)]="searchModel.ownerUserGroups"
                                    (onRemoteSearch)="loadOwnerUserGroupsForSearch($event.target.value)"
                                    #userGroupMulti="ngModel" name="userGroupMulti" id="userGroupMulti">
              </angular2-multiselect>
            </div>
          </div>
          <div class="col-md-12 d-flex justify-content-md-end align-items-end px-0">
            <div class="btn-group" role="group">
              <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
                {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
              </button>
              <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
                {{'COMMON_BUTTON_SEARCH' | translate}}
              </button>
            </div>
          </div>
        </responsive-tab>
        <responsive-tab heading="{{'SEARCH_MODE_DQL' | translate}}" (selectTab)="activeSearchTab = 'dql'" id="dql">
          <app-dql-search-container #dqlSearchContainer
                                    (onSearch)="onDqlSearchClicked()"
                                    [dqlStoredQueryArgs]="dqlStoredArgs"
                                    [rightModel]="rightModel"
                                    [dialogContainer]="dqlDialogHolder"></app-dql-search-container>
        </responsive-tab>
      </responsive-tabset>
    </div> <!-- ./card-body end -->

    <div class="card-body">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header fit">
            <app-table-field-sorter [orderField]="CustomerRecord.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(CustomerRecord.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [checkAllEnabled]="true"
                                    [checkAllSelected]="eachCustomerRecordSelected"
                                    (onCheckAllClicked)="toggleEachCustomerRecord()"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-xs-down fit" *ngIf="!customerId">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="CustomerRecord.OrderField.NAME"
                                    [orderType]="queryModel.getOrderType(CustomerRecord.OrderField.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'CUSTOMER_RECORD_NAME' | translate"></app-table-field-sorter>
            <app-table-field-sorter [orderField]="CustomerRecord.OrderField.EXTERNAL_ID"
                                    [orderType]="queryModel.getOrderType(CustomerRecord.OrderField.EXTERNAL_ID)"
                                    (orderChange)="orderBy($event)"
                                    class="table-text-subtitle"
                                    *ngIf="fieldModel.isManaged(CustomerRecordFieldType.EXTERNAL_ID)"
                                    [text]="'COMMON_EXTERNAL_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header"
              *ngIf="fieldModel.isManaged(CustomerRecordFieldType.MAIN_CONTRACT_NUMBER)">
            <app-table-field-sorter [orderField]="CustomerRecord.OrderField.MAIN_CONTRACT_NUMBER"
                                    [orderType]="queryModel.getOrderType(CustomerRecord.OrderField.MAIN_CONTRACT_NUMBER)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'CUSTOMER_RECORD_CONTRACT_NUMBER' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-sorter-no-op [text]="'COMMON_ADDRESS' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-sorter-no-op
              [text]="'COMMON_PHONE_NUMBER' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-1100-down">
            <app-table-field-sorter [orderField]="CustomerRecord.OrderField.CREATION_TIME"
                                    [orderType]="queryModel.getOrderType(CustomerRecord.OrderField.CREATION_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_CREATION_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let customerRecord of customerRecordList | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }"
            [disabledTableRow]="customerRecord.disabled">
          <td class="responsive-table-column fit">
            <div class="d-flex">
              <app-animated-checkbox
                class="toggle-checkbox"
                [(selected)]="customerRecord.selected">
              </app-animated-checkbox>
              {{customerRecord.customerRecordId}}
            </div>
          </td>
          <td class="hidden-xs-down text-center fit" *ngIf="!customerId">
            <app-type-icon [icon]="customerRecord.customerIcon"></app-type-icon>
          </td>
          <td class="responsive-table-column">
            <div class="d-flex">
            <span
              class="icomoon icomoon-info popover-trigger-icon icomoon-table-icon"
              (onShown)="onCustomerRecordPopoverShown(customerRecord)"
              [popover]="detailsPopover" triggers="mouseenter:mouseleave" container="body">
            </span>
            <div class="d-flex flex-column">
            <a class="id-text cursor-pointer"
               href="#" onclick="return false"
               uiSref="Admin.CustomerRecordDetail"
               [uiParams]="{customerId: customerRecord.customerId, customerRecordId: customerRecord.customerRecordId}"
               title="{{'COMMON_BUTTON_DETAILS' | translate}}">
              {{customerRecord.name}}
            </a>
              <span class="text-break table-text-subtitle"
                    *ngIf="fieldModel.isManaged(CustomerRecordFieldType.EXTERNAL_ID)">{{customerRecord.externalId}}</span>
            </div>
            </div>
            <ng-template #detailsPopover>
              <div class="font-weight-bold">{{customerRecord.name}}</div>
              <div class="table-text-small"
                   *ngIf="customerRecord.postalAddress && fieldModel.isManaged(CustomerRecordFieldType.POSTAL_ADDRESS_AND_INVOICE_ADDRESS_AND_NOTIFICATION_ADDRESS)">
                {{Address.PostalAddressMapper.toString(customerRecord.postalAddress, config.postalAddressFormat)}}
              </div>
              <div
                *ngIf="customerRecord.phoneNumbers.length > 0 && fieldModel.isManaged(CustomerRecordFieldType.PHONE_NUMBERS)"
                class="table-text-subtitle table-text-small mt-1">{{'CUSTOMER_RECORD_PHONE_NUMBERS' | translate}}</div>
              <div *ngFor="let phoneNumber of customerRecord.phoneNumbers">{{phoneNumber.value}}</div>
              <div
                *ngIf="customerRecord.emailAddresses.length > 0 && fieldModel.isManaged(CustomerRecordFieldType.EMAIL_ADDRESSES)"
                class="table-text-subtitle table-text-small mt-1">{{'CUSTOMER_RECORD_EMAIL_ADDRESSES' | translate}}</div>
              <div *ngFor="let emailAddress of customerRecord.emailAddresses">{{emailAddress.value}}</div>
              <div *ngIf="customerRecord.ownerUsers.size > 0"
                   class="table-text-subtitle table-text-small mt-1">{{'CUSTOMER_RECORD_USERS' | translate}}</div>
              <div *ngFor="let user of customerRecord.ownerUsers">{{user.personName + ' (' + user.userName + ')'}}</div>
              <div *ngIf="customerRecord.ownerGroups.size > 0"
                   class="table-text-subtitle table-text-small mt-1">{{'CUSTOMER_RECORD_USER_GROUPS' | translate}}</div>
              <div *ngFor="let group of customerRecord.ownerGroups">{{group.name}}</div>

              <div *ngIf="customerRecord.taskRecordData && customerRecord.taskRecordData.taskRecordCount > 0">
                <div>
                  <div
                    class="table-text-subtitle table-text-small mt-1">{{'CUSTOMER_RECORD_RELATED_TASKRECORD_COUNT' | translate}}</div>
                  <div>{{customerRecord.taskRecordData.taskRecordCount}}</div>
                </div>
                <div *ngIf="customerRecord.taskRecordData.latestTaskRecordData">
                  <div
                    class="table-text-subtitle table-text-small mt-1">{{'CUSTOMER_RECORD_RELATED_TASKRECORD_LATEST' | translate}}</div>
                  <div class="d-flex flex-column">
                    <div>
                      {{customerRecord.taskRecordData.latestTaskRecordData.name}}
                    </div>
                    <div>{{customerRecord.taskRecordData.latestTaskRecordData.creationTime.toUtcIsoString() | date: 'short'}}</div>
                    <div class="row ml-0">
                      <app-table-badge
                        [badgeStyle]="customerRecord.taskRecordData.latestTaskRecordData.state.badgeStyle"
                        [iconClass]="customerRecord.taskRecordData.latestTaskRecordData.state.iconClass"
                        [breakWord]="true"
                        [textKey]="customerRecord.taskRecordData.latestTaskRecordData.state.stringKey"
                        [fontSize]="'small'">
                      </app-table-badge>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </td>
          <td class="responsive-table-column"
              *ngIf="fieldModel.isManaged(CustomerRecordFieldType.MAIN_CONTRACT_NUMBER)">
            {{customerRecord.mainContractNumber}}
          </td>
          <td class="responsive-table-column long hidden-xs-down">
            <span class="text-break"
              *ngIf="customerRecord.postalAddress && fieldModel.isManaged(CustomerRecordFieldType.POSTAL_ADDRESS_AND_INVOICE_ADDRESS_AND_NOTIFICATION_ADDRESS)">
              {{Address.PostalAddressMapper.toString(customerRecord.postalAddress, config.postalAddressFormat)}}
            </span>
          </td>
          <td class="responsive-table-column hidden-xs-down">
            <span
              *ngIf="customerRecord.phoneNumbers.length > 0 && fieldModel.isManaged(CustomerRecordFieldType.PHONE_NUMBERS)">{{customerRecord.phoneNumbers[0].value}}</span>
          </td>
          <td class="responsive-table-column hidden-1100-down">{{customerRecord.creationTime.toUtcIsoString() |
            date:'short'}}
          </td>
          <td>
            <app-table-options-menu>
              <app-dropdown-item
                [iconClass]="'icomoon-detail'"
                [titleStringKey]="'COMMON_BUTTON_DETAILS'"
                class="cursor-pointer"
                uiSref="Admin.CustomerRecordDetail"
                [uiParams]="{ customerId: customerRecord.customerId, customerRecordId: customerRecord.customerRecordId }">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="customerRecord.rights.update.hasRight()"
                [iconClass]="'icomoon-modify'"
                [titleStringKey]="'COMMON_BUTTON_EDIT'"
                class="cursor-pointer"
                uiSref="Admin.CustomerRecordEdit"
                [uiParams]="{ customerId: customerRecord.customerId, customerRecordId: customerRecord.customerRecordId }">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="!customerRecord.disabled && customerRecord.rights.disable.hasRight()"
                [iconClass]="'icomoon-disable'"
                [titleStringKey]="'COMMON_BUTTON_DISABLE'"
                [itemType]="DropdownItemType.DISABLE"
                class="cursor-pointer"
                (click)="setDisabled($event, customerRecord, true)">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="customerRecord.disabled && customerRecord.rights.disable.hasRight()"
                [iconClass]="'icomoon-enable'"
                [titleStringKey]="'COMMON_BUTTON_ENABLE'"
                [itemType]="DropdownItemType.ENABLE"
                class="cursor-pointer"
                (click)="setDisabled($event, customerRecord, false)">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="rightModel.taskRecordCreate.hasRight() && !customerRecord.contactPerson"
                [iconClass]="'icomoon-new-task-customer'"
                [titleStringKey]="'COMMON_CREATE_TASK'"
                class="cursor-pointer"
                (click)="showTaskCreate(customerRecord)">
              </app-dropdown-item>
            </app-table-options-menu>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header fit">
            <app-table-field-sorter [orderField]="CustomerRecord.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(CustomerRecord.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [checkAllEnabled]="true"
                                    [checkAllSelected]="eachCustomerRecordSelected"
                                    (onCheckAllClicked)="toggleEachCustomerRecord()"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-xs-down fit" *ngIf="!customerId">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="CustomerRecord.OrderField.NAME"
                                    [orderType]="queryModel.getOrderType(CustomerRecord.OrderField.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'CUSTOMER_RECORD_NAME' | translate"></app-table-field-sorter>
            <app-table-field-sorter [orderField]="CustomerRecord.OrderField.EXTERNAL_ID"
                                    [orderType]="queryModel.getOrderType(CustomerRecord.OrderField.EXTERNAL_ID)"
                                    (orderChange)="orderBy($event)"
                                    class="table-text-subtitle"
                                    *ngIf="fieldModel.isManaged(CustomerRecordFieldType.EXTERNAL_ID)"
                                    [text]="'COMMON_EXTERNAL_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header"
              *ngIf="fieldModel.isManaged(CustomerRecordFieldType.MAIN_CONTRACT_NUMBER)">
            <app-table-field-sorter [orderField]="CustomerRecord.OrderField.MAIN_CONTRACT_NUMBER"
                                    [orderType]="queryModel.getOrderType(CustomerRecord.OrderField.MAIN_CONTRACT_NUMBER)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'CUSTOMER_RECORD_CONTRACT_NUMBER' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-sorter-no-op [text]="'COMMON_ADDRESS' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-sorter-no-op
              [text]="'COMMON_PHONE_NUMBER' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-1100-down">
            <app-table-field-sorter [orderField]="CustomerRecord.OrderField.CREATION_TIME"
                                    [orderType]="queryModel.getOrderType(CustomerRecord.OrderField.CREATION_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_CREATION_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>
  </div>
</div>

<app-file-upload-dialog
  [uploadPath]="uploadPath"
  (onResult)="onImportSuccess($event)"
  #importDialog
></app-file-upload-dialog>

<div bsModal [config]="UiConstants.modalConfig" #importPreselectDialog="bs-modal" class="modal fade" tabindex="-1"
     role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="importPreselectDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'CUSTOMER_RECORD_IMPORT_TYPE' | translate}}</h4>
        <button type="button" class="close" (click)="closeImportPreSelectDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row m-0">
          <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 p-0"
               *ngFor="let customer of customerListForCreate">
            <div class="create-card card card-hover-border cursor-pointer fixed-card-height"
                 (click)="openImportDialog(customer.customerId)">
              <div class="card-body d-flex justify-content-start align-items-center">
                <div class="h1 float-left mb-0 d-flex align-items-center">
                  <app-type-icon [icon]="customer.icon" big="true" class="mr-1"></app-type-icon>
                </div>
                <div>
                  <div class="h4 customer-name">{{customer.name}}</div>
                  <small class="text-muted customer-type">{{getCustomerType(customer.type) | translate}}</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div><!-- /.modal-body -->

      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary" (click)="closeImportPreSelectDialog()">
          {{'COMMON_BUTTON_CANCEL' |
          translate}}
        </button>
      </div><!-- /.modal-footer -->
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<div bsModal [config]="UiConstants.modalConfig" #createPreselectDialog="bs-modal" class="modal fade" tabindex="-1"
     role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="createPreselectDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'CUSTOMER_RECORD_CREATE_TYPE' | translate}}</h4>
        <button type="button" class="close" (click)="closeCreatePreselectDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row m-0">
          <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 p-0"
               *ngFor="let customer of customerListForCreate">
            <div class="create-card card card-hover-border cursor-pointer fixed-card-height"
                 (click)="createNewCustomer(customer)">
              <div class="card-body d-flex justify-content-start align-items-center">
                <div class="h1 float-left mb-0 d-flex align-items-center">
                  <app-type-icon [icon]="customer.icon" big="true" class="mr-1"></app-type-icon>
                </div>
                <div>
                  <div class="h4 customer-name">{{customer.name}}</div>
                  <small class="text-muted customer-type">{{getCustomerType(customer.type) | translate}}</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div><!-- /.modal-body -->
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary cursor-pointer" (click)="closeCreatePreselectDialog()">
          {{'COMMON_BUTTON_CANCEL' |
          translate}}
        </button>
      </div><!-- /.modal-footer -->
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<div bsModal [config]="UiConstants.modalConfig" #ownerChangeDialog="bs-modal" class="modal fade" tabindex="-1"
     role="dialog" aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="ownerChangeDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'CUSTOMER_RECORD_OWNER_CHANGE_TITLE' | translate}}</h4>
        <button type="button" class="close" (click)="closeOwnerChangeDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row mr-0 ml-0 mt-1">
          <label class="col-form-label col-md-4">{{'CUSTOMER_RECORD_SEARCH_USER' | translate}}</label>
          <div class="form-group col-md-8">
            <label class="switch switch-text switch-info mb-0">
              <input type="checkbox" class="switch-input"
                     [ngModelOptions]="{standalone: true}"
                     [(ngModel)]="ownerChangeModel.changeUsers">
              <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
              <span class="switch-handle"></span>
            </label>
          </div>
        </div>
        <div class="row m-0" *ngIf="ownerChangeModel.changeUsers">
          <div class="col-md-4"></div>
          <!-- /.col-md-4 -->
          <div class="form-group col-md-8">
            <angular2-multiselect [data]="usersForChange" [settings]="remoteSearchDropdownSettings"
                                  class="form-control"
                                  [(ngModel)]="ownerChangeModel.ownerUsers"
                                  (onRemoteSearch)="loadOwnerUsersForChange($event.target.value)"
                                  #userChange="ngModel" name="userChange" id="userChange">
            </angular2-multiselect>
          </div>
          <!-- /.col-md-8 -->
        </div>
        <!-- /.row -->
        <div class="row m-0">
          <label class="col-form-label col-md-4">{{'CUSTOMER_RECORD_SEARCH_USER_GROUP' | translate}}</label>
          <div class="form-group col-md-8">
            <label class="switch switch-text switch-info mb-0">
              <input type="checkbox" class="switch-input"
                     [ngModelOptions]="{standalone: true}"
                     [(ngModel)]="ownerChangeModel.changeUserGroups">
              <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
              <span class="switch-handle"></span>
            </label>
          </div>
        </div>
        <div class="row m-0" *ngIf="ownerChangeModel.changeUserGroups">
          <div class="col-md-4"></div>
          <!-- /.col-md-4 -->
          <div class="form-group col-md-8">
            <angular2-multiselect [data]="groupsForChange" [settings]="remoteSearchDropdownSettings"
                                  class="form-control"
                                  [(ngModel)]="ownerChangeModel.ownerUserGroups"
                                  (onRemoteSearch)="loadOwnerUserGroupsForChange($event.target.value)"
                                  #userGroupChange="ngModel" name="userGroupChange" id="userGroupChange">
            </angular2-multiselect>
          </div>
        </div>
        <!-- /.row -->
        <div class="row m-0">
          <label class="col-form-label col-md-4">{{'CUSTOMER_RECORD_OWNER_CHANGE' | translate}}</label>
          <div class="col-md-8">
            <mat-radio-group [required]="true" class="d-flex flex-column" [(ngModel)]="ownerChangeModel.changeType">
              <mat-radio-button [value]="'ADD'" [disableRipple]="true">{{'CUSTOMER_RECORD_OWNER_CHANGE_ADD' |
                translate}}
              </mat-radio-button>
              <mat-radio-button [value]="'OVERRIDE'" [disableRipple]="true">{{'CUSTOMER_RECORD_OWNER_CHANGE_OVERRIDE' |
                translate}}
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <!-- /.col-md-8 -->
        </div>
        <div class="alert alert-danger mt-1">
          <h6 class="alert-title">{{'CUSTOMER_RECORD_OWNER_MODIFICATION_MESSAGE' | translate: {
            count:
            ownerChangeModel.customerRecordIds.length
          } }}</h6>
        </div>
      </div><!-- /.modal-body -->
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary cursor-pointer" (click)="closeOwnerChangeDialog()">
          {{'COMMON_BUTTON_CANCEL' |
          translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="changeOwners()">{{'COMMON_BUTTON_NEXT' |
          translate}}
        </button>
      </div><!-- /.modal-footer -->
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div>

<app-dql-dialog-container #dqlDialogHolder></app-dql-dialog-container>


<app-file-upload-dialog
  [uploadPath]="contactLocationUploadPath"
  #contactLocationImportDialog
></app-file-upload-dialog>
