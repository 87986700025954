import { Dates, OffsetDateTime } from '../../lib/util/dates';
import { OrderImportError } from '../../lib/order-import-error/order-import-error.service';

export class OrderImportErrorModel {
  id: number;
  companyName: string = '';
  userName: string = '';
  externalId1: string = '';
  externalId2: string = '';
  extraId1: string = '';
  partnerNameStringKey: string = '';
  realm?: OrderImportError.OrderImportErrorRealm;
  importStateObject: OrderImportError.OrderImportErrorStateObject;
  creationTime: OffsetDateTime = Dates.emptyOffsetDateTime();
  updateTime: OffsetDateTime = Dates.emptyOffsetDateTime();
  recipientName: string = '';
  errorReason?: OrderImportError.OrderImportErrorReasonObject;
  errorMessage: string = '';
  data1?: OrderImportError.Data;
  data2?: OrderImportError.Data;
  data3?: OrderImportError.Data;
  stackTrace?: string = '';
  manualRejectTime?: OffsetDateTime;
  manualRejectUser?: string;

  get errorReasonStringKey(): string {
    return this.errorReason ? this.errorReason.stringKey : '';
  }

}
