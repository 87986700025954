/* eslint-disable */
import { Component, } from '@angular/core';
import {
  FormRecordFieldContext,
  FormRecordFieldValueUpdateArgs,
  FormRecordFieldView,
  FormRecordFieldViewContext,
  FormRecordFieldViewModel,
  SetTmpReadonlyArgs,
  SetTmpReadonlyResult,
} from '../../../../../util/form/form-utils';
import { Form } from '../../../../../lib/form/form.service';
import { FormRecord } from '../../../../../lib/form/form-record.service';
import { FieldActivationState, FieldActivationStateResolver, } from '../../../../../util/form/form-editors';
import { Command } from '../../../../../util/command';
import { Observable } from 'rxjs';

/* eslint-enable */

@Component({
  selector: 'app-form-record-unknown-field',
  templateUrl: 'form-record-unknown-field.component.html',
  styleUrls: ['form-record-unknown-field.component.scss'],
})
export class FormRecordUnknownFieldComponent implements FormRecordFieldView {

  public selector: Form.FieldDataTypeSelector;

  formRecordFieldContext?: FormRecordFieldContext;

  title = '';

  private fieldRecord?: FormRecord.FieldComposed;

  tmpReadonly: boolean = false;

  private optionalValue: boolean = false;

  private readonlyFormFn: () => boolean = () => true;
  private readonlyFieldFn: () => boolean = () => false;
  private hiddenFieldFn: () => boolean = () => false;

  get nonEditable(): boolean {
    return FieldActivationStateResolver.isNonEditable(
      this.fieldActivationState
    );
  }

  get requiredDisabled(): boolean {
    return FieldActivationStateResolver.isRequiredDisabled(
      this.fieldActivationState
    );
  }

  get required(): boolean {
    const optional = this.optionalValue;
    const requiredDisabled = this.requiredDisabled;
    return !optional && !requiredDisabled;
  }


  private get readonlyForm(): boolean {
    return this.readonlyFormFn();
  }

  private get fieldActivationState(): FieldActivationState {
    return FieldActivationStateResolver.resolveFieldActivationState({
      readonlyFormFn: () => this.readonlyFormFn(),
      readonlyFieldFn: () => this.readonlyFieldFn(),
      tmpReadonlyFieldFn: () => this.tmpReadonly,
    });
  }

  constructor() {
  }

  setFieldDataTypeSelector(selector: Form.FieldDataTypeSelector) {
    this.selector = selector;
  }

  setTmpReadonly(args: SetTmpReadonlyArgs): Command<SetTmpReadonlyResult> {
    const previousReadonly = this.tmpReadonly;
    return {
      execute: async () => {
        this.tmpReadonly = args.tmpReadonly;
        return {
          changed: false
        };
      },
      undo: async () => {
        this.tmpReadonly = previousReadonly;
      }
    }
  }

  registerField(context: FormRecordFieldContext) {
    this.formRecordFieldContext = context;
    this.readonlyFormFn = context.readonly;
    this.hiddenFieldFn = () => Form.FormFieldValidationType.HIDDEN === context.validationType;
    this.readonlyFieldFn = () => Form.FormFieldValidationType.READONLY === context.validationType
    || Form.FormFieldValidationType.HIDDEN === context.validationType;
    this.title = context.field.title;
    this.optionalValue = Form.FormFieldValidationType.REQUIRED !== context.validationType;
    this.fieldRecord = context.fieldRecord;
  }

  registerFieldViews(context: FormRecordFieldViewContext): void {
  }

  hasLocalFieldError(): boolean {
    return this.required;
  }

  validateWithInterrupt(): boolean {
    return false;
  }

  shouldNotifyAfterCreation(): boolean {
    return false;
  }

  afterFormRecordCreation(formRecordId: number): Observable<any> | undefined {
    return undefined;
  }

  createModel(): FormRecordFieldViewModel | undefined {
    if (this.fieldRecord === undefined) {
      return undefined;
    }
    return {
      fieldEditRequest: this.fieldRecord
    };
  }

  updateValue(data: FormRecordFieldValueUpdateArgs) {
  }

}
