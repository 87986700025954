/* eslint-disable */
import { Injectable } from '@angular/core';
import { List, Map, Set, } from 'immutable';
import { Observable, Observer } from 'rxjs';
import { FieldValidationError, Order, PagingRequest, QueryResult, ResourceQueryResult, Services, } from '../util/services';
import {
  MasterDataRecordGlobalResourceService,
  MasterDataRecordResource,
  MasterDataRecordResourceService
} from './master-data-record-resource.service';
import { OffsetDateTime } from '../util/dates';
import { FormRecord } from '../form/form-record.service';
import { Form } from '../form/form.service';
import { ObservableErrorResourceParser, } from '../util/errors';
import { FileAttachment, FileAttachmentResource, FileAttachments, FileAttachmentUpdateRequest } from '../util/file-attachments';
import { DownloadedFile, FileNameBuilder, NamedBlobFile, NamedBlobFileDecorator } from '../util/downloaded-files';
import { map } from 'rxjs/operators';
import { EmptyMessage, IdentityMessage } from '../util/messages';
import { DqlQuery } from '../query/field';

/* eslint-enable */

@Injectable()
export class MasterDataRecordService implements MasterDataRecord.Service {

  private formRecordMapper: FormRecord.ResourceMapper;

  constructor(private resourceService: MasterDataRecordResourceService,
              private globalResourceService: MasterDataRecordGlobalResourceService) {
    this.formRecordMapper = new FormRecord.ResourceMapper(new Form.ResourceMapper());
  }

  // <editor-fold desc="CRUD">

  query(request: MasterDataRecord.QueryRequest): Observable<QueryResult<MasterDataRecord.MasterDataRecord>> {
    return Observable.create((observer: Observer<QueryResult<MasterDataRecord.MasterDataRecord>>) => {
      const resourceRequest: MasterDataRecordResource.QueryRequest = this.toResourceQueryRequest(request);
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<MasterDataRecordResource.MasterDataRecord>) => {
          observer.next({
            items: this.toPublicList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  globalQuery(request: MasterDataRecord.GlobalQueryRequest): Observable<QueryResult<MasterDataRecord.MasterDataRecord>> {
    return Observable.create((observer: Observer<QueryResult<MasterDataRecord.MasterDataRecord>>) => {
      const resourceRequest: MasterDataRecordResource.GlobalQueryRequest = this.toResourceGlobalQueryRequest(request);
      return this.globalResourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<MasterDataRecordResource.MasterDataRecord>) => {
          observer.next({
            items: this.toPublicList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  get(request: MasterDataRecord.GetRequest): Observable<MasterDataRecord.MasterDataRecord> {
    return Observable.create((observer: Observer<MasterDataRecord.MasterDataRecord>) => {
      const resourceRequest: MasterDataRecordResource.GetRequest = {
        with_form_record: this.toResourceArgumentWithFormRecord(request.withFormRecord),
        master_data_id: request.masterDataId,
        master_data_record_id: request.masterDataRecordId,
        rights: DqlQuery.RightRequestSerializer.getInstance().serialize(request.rights)
      };
      return this.resourceService.get(resourceRequest).subscribe(
        (result: MasterDataRecord.MasterDataRecord) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  create(request: MasterDataRecord.CreateRequest): Observable<MasterDataRecord.MasterDataRecord> {
    return Observable.create((observer: Observer<MasterDataRecord.MasterDataRecord>) => {
      const resourceRequest: MasterDataRecordResource.CreateRequest = {
        with_form_record: this.toResourceArgumentWithFormRecord(request.withFormRecord),
        master_data_id: request.masterDataId,
        external_id: request.externalId,
        name: request.name,
        description: request.description,
        form_record: this.formRecordMapper.toResourceCreateRequest(request.formRecord),
        owner_user_ids: request.ownerUserIds.toArray(),
        owner_group_ids: request.ownerGroupIds.toArray(),
        owner_customer_record_id: request.ownerCustomerRecordId,
        owner_contact_location_id: request.ownerContactLocationId
      };
      return this.resourceService.create(resourceRequest).subscribe(
        (result: MasterDataRecord.MasterDataRecord) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  quickCreate(request: MasterDataRecord.QuickCreateRequest): Observable<MasterDataRecord.MasterDataRecord> {
    return Observable.create((observer: Observer<MasterDataRecord.MasterDataRecord>) => {
      const resourceRequest: MasterDataRecordResource.QuickCreateRequest = {
        with_form_record: this.toResourceArgumentWithFormRecord(request.withFormRecord),
        master_data_id: request.masterDataId,
        external_id: request.externalId,
        name: request.name,
        description: request.description,
        owner_user_ids: request.ownerUserIds.toArray(),
        owner_group_ids: request.ownerGroupIds.toArray(),
        owner_customer_record_id: request.ownerCustomerRecordId,
        owner_contact_location_id: request.ownerContactLocationId
      };
      return this.resourceService.quickCreate(resourceRequest).subscribe(
        (result: MasterDataRecord.MasterDataRecord) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  update(request: MasterDataRecord.UpdateRequest): Observable<MasterDataRecord.MasterDataRecord> {
    return Observable.create((observer: Observer<MasterDataRecord.MasterDataRecord>) => {
      const resourceRequest: MasterDataRecordResource.UpdateRequest = {
        with_form_record: this.toResourceArgumentWithFormRecord(request.withFormRecord),
        master_data_id: request.masterDataId,
        master_data_record_id: request.masterDataRecordId,
        external_id: request.externalId,
        name: request.name,
        description: request.description,
        form_record: this.formRecordMapper.toResourceUpdateRequest(request.formRecord),
        owner_user_ids: request.ownerUserIds.toArray(),
        owner_group_ids: request.ownerGroupIds.toArray(),
        owner_customer_record_id: request.ownerCustomerRecordId,
        owner_contact_location_id: request.ownerContactLocationId
      };
      return this.resourceService.update(resourceRequest).subscribe(
        (result: MasterDataRecord.MasterDataRecord) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  setDisabled(request: MasterDataRecord.DisableRequest): Observable<MasterDataRecord.MasterDataRecord> {
    return Observable.create((observer: Observer<MasterDataRecord.MasterDataRecord>) => {
      const resourceRequest: MasterDataRecordResource.DisableRequest = {
        with_form_record: this.toResourceArgumentWithFormRecord(request.withFormRecord),
        master_data_id: request.masterDataId,
        master_data_record_id: request.masterDataRecordId,
        disabled: request.disabled
      };
      return this.resourceService.setDisabled(resourceRequest).subscribe(
        (result: MasterDataRecord.MasterDataRecord) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  connectToCustomerRecord(request: MasterDataRecord.ConnectToCustomerRecordRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: MasterDataRecordResource.ConnectToCustomerRecordRequest = {
        ids: request.ids.toArray(),
        owner_customer_record_id: request.ownerCustomerRecordId,
        owner_contact_location_id: request.ownerContactLocationId
      };
      return this.globalResourceService.connectToCustomerRecord(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  getAttachments(request: MasterDataRecord.GetAttachmentRequest): Observable<FileAttachment[]> {
    return Observable.create((observer: Observer<FileAttachment[]>) => {
      const resourceRequest: MasterDataRecordResource.GetAttachmentRequest = {
        master_data_id: request.masterDataId,
        master_data_record_id: request.masterDataRecordId
      };
      this.resourceService.getAttachments(resourceRequest).subscribe(
        (result: FileAttachmentResource[]) => {
          observer.next(FileAttachments.toPublicList(result));
        });
    });
  }

  downloadAttachment(request: MasterDataRecord.DownloadAttachmentRequest): Observable<NamedBlobFile> {
    const fileName = new FileNameBuilder()
      .addString(request.attachment.fullFileName)
      .addOffsetDateTime(Services.toOffsetDateTime(request.attachment.contentUpdateTime))
      .build();
    return this.resourceService.downloadAttachment(
      request.masterDataId,
      request.masterDataRecordId,
      request.attachment.id
    ).pipe(map((file: DownloadedFile) => {
      return new NamedBlobFileDecorator(file, fileName);
    }));
  }

  downloadAttachmentThumbnail(request: MasterDataRecord.DownloadAttachmentRequest): Observable<NamedBlobFile> {
    const fileName = new FileNameBuilder()
      .addString(request.attachment.fullFileName)
      .addOffsetDateTime(Services.toOffsetDateTime(request.attachment.contentUpdateTime))
      .build();
    return this.resourceService.downloadAttachment(
      request.masterDataId,
      request.masterDataRecordId,
      request.attachment.id,
      true
    ).pipe(map((file: DownloadedFile) => {
      return new NamedBlobFileDecorator(file, fileName);
    }));
  }

  deleteAttachment(request: MasterDataRecord.DeleteAttachmentRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.resourceService.deleteAttachment({
        master_data_record_id: request.masterDataRecordId,
        master_data_id: request.masterDataId,
        file_id: request.fileId,
      }).subscribe((result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  updateAttachment(request: MasterDataRecord.UpdateAttachmentRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.resourceService.updateAttachment({
        master_data_record_id: request.masterDataRecordId,
        master_data_id: request.masterDataId,
        file_id: request.fileId,
        name: request.name
      }).subscribe((result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  exportXls(request: MasterDataRecord.QueryRequest): Observable<DownloadedFile> {
    const resourceRequest: MasterDataRecordResource.QueryRequest = this.toResourceQueryRequest(request);
    return this.resourceService.exportXls(resourceRequest);
  }

  exportXlsTemplate(request: IdentityMessage): Observable<DownloadedFile> {
    return this.resourceService.exportXlsTemplate(request);
  }

  downloadAttachmentZip(request: MasterDataRecord.AttachmentZipRequest): Observable<NamedBlobFile> {
    return this.globalResourceService.downloadAttachmentZip(
      {master_data_record_ids: request.masterDataRecordIds.toArray()}
    ).pipe(map((file: DownloadedFile) => {
      return new NamedBlobFileDecorator(file, 'attachments.zip');
    }));
  }

  // </editor-fold>

  // <editor-fold desc="Internal">

  private toResourceArgumentWithFormRecord(withFormRecord?: boolean): boolean {
    return withFormRecord === undefined ? false : withFormRecord;
  }

  private toPublicList(resourceList: MasterDataRecordResource.MasterDataRecord[]): List<MasterDataRecord.MasterDataRecord> {
    return List.of(...resourceList.map((r) => this.toPublic(r)));
  }

  private toPublic(r: MasterDataRecordResource.MasterDataRecord): MasterDataRecord.MasterDataRecord {
    // This service does not send field attribute in the request so we can provide non-null fields as non-optional,
    // without check (using ! sign)
    // (Except 'formRecord' field because query param 'withFormRecord' is used.)
    // NOTE:
    // If 'shorter' versions are required, create a new MasterDataRecord type.
    // Keep this as 'full' version. We don't like optional fields in the UI layer.
    return {
      masterDataRecordId: r.id!,
      masterDataId: r.master_data_id!,
      externalId: r.external_id,
      disabled: r.disabled!,
      creationTime: Services.toOffsetDateTime(r.creation_time!),
      updateTime: Services.toOffsetDateTime(r.update_time!),
      name: r.name,
      description: r.description,
      formRecord: this.formRecordMapper.toPublic(r.form_record),
      ownerUserIds: Services.optArrayToSet(r.owner_user_ids),
      ownerGroupIds: Services.optArrayToSet(r.owner_group_ids),
      ownerCustomerRecordId: r.owner_customer_record_id,
      ownerContactLocationId: r.owner_contact_location_id,
      grantedRights: r.granted_rights ? Set.of(...r.granted_rights) : Set.of()
    };
  }

  private translateError(error: any): any {
    const res = ObservableErrorResourceParser.parseError(error);
    const fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
    const fieldErrorMap = ObservableErrorResourceParser.toFieldErrorMap(Keys.toValidatedField, fieldErrors);
    if (!fieldErrorMap.isEmpty()) {
      return FieldValidationError.of(fieldErrorMap);
    }
    return error;
  }

  // </editor-fold>

  private toResourceQueryRequest(request: MasterDataRecord.QueryRequest) {
    return {
      with_form_record: this.toResourceArgumentWithFormRecord(request.withFormRecord),
      displayed_form_field_id: request.displayedFormFieldId,
      master_data_id: request.masterDataId,
      id: Services.createIdParameter(request.masterDataRecordIdSet),
      disabled: request.disabled,
      name: request.name,
      external_id: request.externalId,
      description: request.description,
      owner_user_ids: Services.createIdParameter(request.ownerUserIds),
      owner_group_ids: Services.createIdParameter(request.ownerUserGroupIds),
      owner_customer_record_id: Services.createIdParameter(request.ownerCustomerRecordId),
      owner_contact_location_id: Services.createIdParameter(request.ownerContactLocationId),
      q: request.queryText,
      dql: request.dqlText,
      order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
      page_number: request.paging ? request.paging.pageNumber : undefined,
      number_of_items: request.paging ? request.paging.numberOfItems : undefined,
      fields: request.fields && request.fields.size > 0 ? request.fields.join(',') : undefined,
      rights: DqlQuery.RightRequestSerializer.getInstance().serialize(request.rights),
      no_progress_bar: request.noProgressBar,
    };
  }

  private toResourceGlobalQueryRequest(request: MasterDataRecord.GlobalQueryRequest) {
    return {
      with_form_record: this.toResourceArgumentWithFormRecord(request.withFormRecord),
      displayed_form_field_id: request.displayedFormFieldId,
      id: Services.createIdParameter(request.masterDataRecordIdSet),
      disabled: request.disabled,
      name: request.name,
      external_id: request.externalId,
      description: request.description,
      owner_user_ids: Services.createIdParameter(request.ownerUserIds),
      owner_group_ids: Services.createIdParameter(request.ownerUserGroupIds),
      owner_customer_record_id: Services.createIdParameter(request.ownerCustomerRecordId),
      owner_contact_location_id: Services.createIdParameter(request.ownerContactLocationId),
      q: request.queryText,
      dql: request.dqlText,
      order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
      page_number: request.paging ? request.paging.pageNumber : undefined,
      number_of_items: request.paging ? request.paging.numberOfItems : undefined,
      fields: request.fields && request.fields.size > 0 ? request.fields.join(',') : undefined,
      rights: DqlQuery.RightRequestSerializer.getInstance().serialize(request.rights),
      no_progress_bar: request.noProgressBar,
    };
  }
}

export namespace MasterDataRecord {

  export interface Service {

    // <editor-fold desc="CRUD">

    query(request: QueryRequest): Observable<QueryResult<MasterDataRecord>>;

    get(request: GetRequest): Observable<MasterDataRecord>;

    create(request: CreateRequest): Observable<MasterDataRecord>;

    update(request: UpdateRequest): Observable<MasterDataRecord>;

    setDisabled(request: DisableRequest): Observable<MasterDataRecord>;

    // </editor-fold>

  }

  export enum OrderField {
    ID,
    EXTERNAL_ID,
    DISABLED,
    CREATION_TIME,
    UPDATE_TIME,
    NAME,
    DESCRIPTION,
  }

  export enum ValidatedField {
    UNKNOWN,

    EXTERNAL_ID,
    NAME,
  }

  export interface MasterDataRecord {
    masterDataRecordId: number;
    masterDataId: number;
    masterData?: {
      name: string,
      id: number
    },
    externalId?: string;
    disabled: boolean;
    creationTime: OffsetDateTime;
    updateTime: OffsetDateTime;
    name?: string;
    description?: string;
    ownerUserIds: Set<number>;
    ownerGroupIds: Set<number>;
    ownerCustomerRecordId?: number;
    ownerContactLocationId?: number;
    formRecord?: FormRecord.FormRecord; // non-null if requested
    grantedRights: Set<string>;
  }

  export interface QueryRequest {
    withFormRecord?: boolean;

    displayedFormFieldId?: number;
    masterDataId: number;
    masterDataRecordIdSet?: Set<number>;
    disabled?: boolean;
    name?: string;
    externalId?: string;
    dqlText?: string;
    description?: string;
    ownerUserIds?: Set<number>;
    ownerUserGroupIds?: Set<number>;
    ownerCustomerRecordId?: Set<number>;
    ownerContactLocationId?: Set<number>;
    queryText?: string;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
    fields?: Set<string>;
    rights?: Set<string>;
    noProgressBar?: boolean;
  }

  export interface GlobalQueryRequest {
    withFormRecord?: boolean;
    displayedFormFieldId?: number;

    masterDataRecordIdSet?: Set<number>;
    disabled?: boolean;
    name?: string;
    externalId?: string;
    dqlText?: string;
    description?: string;
    ownerUserIds?: Set<number>;
    ownerUserGroupIds?: Set<number>;
    ownerCustomerRecordId?: Set<number>;
    ownerContactLocationId?: Set<number>;
    queryText?: string;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
    fields?: Set<string>;
    rights?: Set<string>;
    noProgressBar?: boolean;
  }

  export interface GetRequest {
    withFormRecord?: boolean;
    rights?: Set<string>;

    masterDataId: number;
    masterDataRecordId: number;
  }

  export interface CreateRequest {
    withFormRecord?: boolean;

    masterDataId: number;
    name?: string;
    externalId?: string;
    description?: string;
    formRecord: FormRecord.FormRecordCreateRequest;
    ownerUserIds: Set<number>;
    ownerGroupIds: Set<number>;
    ownerCustomerRecordId?: number;
    ownerContactLocationId?: number;
  }

  export interface QuickCreateRequest {
    withFormRecord?: boolean;

    masterDataId: number;
    name?: string;
    externalId?: string;
    description?: string;
    ownerUserIds: Set<number>;
    ownerGroupIds: Set<number>;
    ownerCustomerRecordId?: number;
    ownerContactLocationId?: number;
  }

  export interface UpdateRequest {
    withFormRecord?: boolean;

    masterDataRecordId: number;
    masterDataId: number;
    externalId?: string;
    name?: string;
    description?: string;
    formRecord: FormRecord.FormRecordUpdateRequest;
    ownerUserIds: Set<number>;
    ownerGroupIds: Set<number>;
    ownerCustomerRecordId?: number;
    ownerContactLocationId?: number;
  }

  export interface DisableRequest {
    withFormRecord?: boolean;

    masterDataId: number;
    masterDataRecordId: number;
    disabled: boolean;
  }

  export interface ConnectToCustomerRecordRequest {
    ids: Set<number>;
    ownerCustomerRecordId?: number;
    ownerContactLocationId?: number;
  }

  export interface GetAttachmentRequest {
    masterDataId: number;
    masterDataRecordId: number;
  }

  export interface DeleteAttachmentRequest {
    masterDataId: number;
    masterDataRecordId: number;
    fileId: number;
  }

  export interface UpdateAttachmentRequest extends FileAttachmentUpdateRequest {
    masterDataId: number;
    masterDataRecordId: number;
  }

  export interface DownloadAttachmentRequest {
    masterDataId: number,
    masterDataRecordId: number,
    attachment: FileAttachment
  }

  export interface AttachmentZipRequest {
    masterDataRecordIds: Set<number>;
  }

}

// <editor-fold desc="Internal">

class Keys {

  private static readonly ID = 'id';
  private static readonly EXTERNAL_ID = 'external_id';
  private static readonly DISABLED = 'disabled';
  private static readonly CREATION_TIME = 'creation_time';
  private static readonly UPDATE_TIME = 'update_time';
  private static readonly NAME = 'name';
  private static readonly DESCRIPTION = 'description';

  private static readonly orderFieldKeyMap: Map<MasterDataRecord.OrderField, string> = Map.of(
    MasterDataRecord.OrderField.ID, Keys.ID,
    MasterDataRecord.OrderField.EXTERNAL_ID, Keys.EXTERNAL_ID,
    MasterDataRecord.OrderField.DISABLED, Keys.DISABLED,
    MasterDataRecord.OrderField.CREATION_TIME, Keys.CREATION_TIME,
    MasterDataRecord.OrderField.UPDATE_TIME, Keys.UPDATE_TIME,
    MasterDataRecord.OrderField.NAME, Keys.NAME,
    MasterDataRecord.OrderField.DESCRIPTION, Keys.DESCRIPTION,
  );

  private static readonly keyValidatedFieldMap: Map<string, MasterDataRecord.ValidatedField> = Map.of(
    Keys.EXTERNAL_ID, MasterDataRecord.ValidatedField.EXTERNAL_ID,
    Keys.NAME, MasterDataRecord.ValidatedField.NAME,
  );

  public static toOrderFieldKey(field: MasterDataRecord.OrderField): string {
    return Keys.orderFieldKeyMap.get(field)!;
  }

  public static toValidatedField(fieldKey: string): MasterDataRecord.ValidatedField {
    return Keys.keyValidatedFieldMap.get(fieldKey, MasterDataRecord.ValidatedField.UNKNOWN);
  }

}

// </editor-fold>
