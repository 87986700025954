/* eslint-disable */
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UiConstants } from '../../../util/core-utils';
import { Transition, UIRouter } from '@uirouter/angular';
import { ComponentStateResolver } from '../../../util/component-state/component-state-resolver';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { AbstractControl, NgForm, NgModel } from '@angular/forms';
import { LocalFieldValidationErrors, LocalFieldValidationErrorsFactory } from '../../../lib/util/services';
import { RightModel } from '../../../app.rights';
import { TranslateService } from '@ngx-translate/core';
import { RightResolver, RightService } from '../../../lib/right.service';
import { EmailTemplate, EmailTemplateService } from '../../../lib/email-template/email-template.service';
import { StateName } from '../../../app.state-names';
import { FieldError, FieldErrors, ObservableErrorResourceParser } from '../../../lib/util/errors';
import { Dates, OffsetDateTime } from '../../../lib/util/dates';
import { List } from 'immutable';
import { Strings } from '../../../lib/util/strings';
import { EmptyMessage } from '../../../lib/util/messages';
import { merge, Observable, Subject } from 'rxjs';
import { debounceTime, flatMap, map } from 'rxjs/operators';
/* eslint-enable */

@Component({
  selector: 'app-email-template-base',
  templateUrl: './email-template-base.component.html',
  styleUrls: ['./email-template-base.component.scss']
})
export class EmailTemplateBaseComponent implements OnInit, AfterViewInit {

  // If you need to access certain classes from HTML, declare them here
  UiConstants = UiConstants;

  // Declare models before use
  editModel: EmailTemplateEditModel;
  detailModel: EmailTemplateDetailModel;

  // Component state resolver, determines the state of the component
  componentState: ComponentStateResolver;

  // Variables used for breadcrumb
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  codeOptions$: Observable<string[]>;
  externalIdClick: Subject<void> = new Subject();

  // Form for validation
  @ViewChild('f', { static: true })
  fForm: NgForm;

  // Validated inputs
  @ViewChild('name')
  name: NgModel;

  @ViewChild('externalId')
  externalId: NgModel;

  @ViewChild('subject')
  subject: NgModel;

  @ViewChild('template')
  template: NgModel;

  // Field errors for server validation
  fieldErrors: EmailTemplateFieldErrorMap;

  private validatedInputs: LocalFieldValidationErrors<NgModel> =
    LocalFieldValidationErrorsFactory.empty();

  // Right model to store the rights available to the User
  rightModel: RightModel = RightModel.empty();

  // Configuration for the HTML Editor
  HTMLEditorModules = {
    toolbar: [
        ['bold', 'italic', 'underline'],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['link', 'image']
      ]
  };
  htmlEditorEmpty = false;
  showEditor: boolean = true;

  constructor(private uiRouter: UIRouter,
              private transition: Transition,
              private emailTemplateService: EmailTemplateService,
              private translateService: TranslateService,
              private rightService: RightService) {
    this.fieldErrors = {};
    this.componentState = new ComponentStateResolver(uiRouter, transition,
      'id',
      {stateName: StateName.EMAIL_TEMPLATE_CREATE, stateHeaderKey: 'EMAIL_TEMPLATE_CREATE'},
      {stateName: StateName.EMAIL_TEMPLATE_EDIT, stateHeaderKey: 'EMAIL_TEMPLATE_EDIT'},
      {stateName: StateName.EMAIL_TEMPLATE_DETAIL, stateHeaderKey: 'EMAIL_TEMPLATE_DETAIL'});
  }

  ngOnInit() {
    this.initComponentState(); // Must be called first
    this.loadRightModels();
    this.initBreadcrumb();
  }

  ngAfterViewInit() {
    this.loadLocalFieldValidationErrors();
    const codeClickEvent: Observable<void> = this.externalIdClick.asObservable();
    const codeChangeEvent: Observable<any> = this.externalId.control.valueChanges;
    this.codeOptions$ = merge(codeClickEvent, codeChangeEvent).pipe(
      debounceTime(UiConstants.autocompleteDebounceTime),
      flatMap(
        (value) => {
          return this.emailTemplateService.getAvailableSystemCodes({
            code: value === null ? undefined : value
          }).pipe(map((result) => {
            return result;
          }));
        }
      )
    );
  }

  private initComponentState() {
    // Creates the editModel if not readonly
    if (!this.componentState.isDetailView()) {
      this.editModel = new EmailTemplateEditModel();
    }
    else {
      this.detailModel = new EmailTemplateDetailModel()
    }
    if (!this.componentState.isCreateView()) {
      this.loadModel();
    }
  }

  initBreadcrumb() {
    // Set breadcrumbSelf if createView, otherwise set in loadModel()
    if (this.componentState.isCreateView()) {
      this.translateService.get('EMAIL_TEMPLATE_CREATE').subscribe(
        (result: string) => {
          this.breadcrumbSelf = result;
        }
      );
    }
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('EMAIL_TEMPLATE_LIST').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.EMAIL_TEMPLATE_LIST});
      }
    );
  }

  // Get corresponding dictionary key
  getHeadingDictionaryKey(): string {
    return this.componentState.getCurrentHeaderKey();
  }

  hasLocalFieldError(field?: NgModel): boolean {
    return this.validatedInputs.hasLocalError(field);
  }

  removeFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.fieldErrors, fieldError);
  }

  private loadLocalFieldValidationErrors() {
    const validatedInputs = List.of(this.name,
      this.externalId,
      this.subject,
      this.template
    );
    this.validatedInputs = LocalFieldValidationErrorsFactory.ofFormFields(this.fForm, validatedInputs);
  }

  // Loads user rights
  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  // Called by the init method first
  private loadModel() {
    this.emailTemplateService.get({
      id: this.componentState.id!
    }).subscribe((emailTemplate: EmailTemplate.EmailTemplate) => {
      if (this.componentState.isEditView()) {
        this.loadEditModel(emailTemplate);
      }
      else if (this.componentState.isDetailView()) {
        this.loadDetailModel(emailTemplate);
      }
      this.breadcrumbSelf = emailTemplate.name;
    });
  }

  private loadEditModel(emailTemplate: EmailTemplate.EmailTemplate) {
    this.editModel = new EmailTemplateEditModel();
    this.editModel.id = emailTemplate.id;
    this.editModel.name = emailTemplate.name;
    this.editModel.externalId = emailTemplate.externalId;
    this.editModel.subject = emailTemplate.subject;
    this.editModel.template = emailTemplate.template;
  }

  private loadDetailModel(emailTemplate: EmailTemplate.EmailTemplate) {
    this.detailModel = new EmailTemplateDetailModel();
    this.detailModel.id = emailTemplate.id;
    this.detailModel.name = emailTemplate.name;
    this.detailModel.externalId = emailTemplate.externalId;
    this.detailModel.subject = emailTemplate.subject;
    this.detailModel.template = emailTemplate.template;
    this.detailModel.creationTime = emailTemplate.creationTime;
    this.detailModel.updateTime = emailTemplate.updateTime;
  }

  submit() {
    if (!this.validateTemplateNonEmpty()) {
      return;
    }
    if (this.componentState.isCreateView()) {
      this.create();
    }
    else if (this.componentState.isEditView()) {
      this.update();
    }
  }

  create() {
    if (this.hasLocalFieldError()) {
      return;
    }
    this.emailTemplateService.create({
      name: this.editModel.name,
      externalId: Strings.undefinedOrNonEmpty(this.editModel.externalId),
      subject: this.editModel.subject,
      template: this.editModel.template
    }).subscribe(
      (response: EmptyMessage) => {
        this.uiRouter.stateService.go(StateName.EMAIL_TEMPLATE_LIST);
      },
      (error: any) => {
        const res = ObservableErrorResourceParser.parseError(error);
        this.fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
      });
  }

  update() {
    if (this.hasLocalFieldError()) {
      return;
    }
    this.emailTemplateService.update({
      id: this.componentState.id!,
      name: this.editModel.name,
      subject: this.editModel.subject,
      externalId: this.editModel.externalId,
      template: this.editModel.template
    }).subscribe(
      (response: EmptyMessage) => {
        this.uiRouter.stateService.go(StateName.EMAIL_TEMPLATE_LIST);
      },
      (error: any) => {
        const res = ObservableErrorResourceParser.parseError(error);
        this.fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
      });
  }

  private validateTemplateNonEmpty(): boolean {
    if (!this.editModel.template || this.editModel.template.length < 1) {
      this.htmlEditorEmpty = true;
      return false;
    }
    this.htmlEditorEmpty = false;
    return true;
  }

  back() {
    window.history.back();
  }

  onAutoCompleteClick() {
    const c: AbstractControl = this.externalId.control;
    const value: string = c.value;
    if (value === null || value.length === 0) {
      this.externalIdClick.next();
    }
  }

}

// Used for create/edit views
class EmailTemplateEditModel {
  id: number;
  name: string = '';
  subject: string = '';
  externalId: string = '';
  template: string = '';

}

// Used for detail view
class EmailTemplateDetailModel {
  id: number;
  name: string = '';
  subject: string = '';
  externalId: string = '';
  template: string = '';
  creationTime: OffsetDateTime = Dates.emptyOffsetDateTime();
  updateTime: OffsetDateTime = Dates.emptyOffsetDateTime();
}

interface EmailTemplateFieldErrorMap {
  name?: FieldError;
  external_id?: FieldError;
  subject?: FieldError;
}
