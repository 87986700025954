import * as draw2d from '../../../../../../../node_modules/draw2d/dist/draw2d.js';

export class LineSelectionFeedbackPolicy extends draw2d.policy.line.OrthogonalSelectionFeedbackPolicy {

  constructor() {
    super();
  }

  // Change line color to green on selection
  onSelect(canvas, connection, isPrimarySelection) {
    connection.setStroke(2);
    connection.setColor(new draw2d.util.Color('#00727B'));
    const decorator = new draw2d.decoration.connection.ArrowDecorator();
    decorator.setDimension(5, 8);
    decorator.setColor('#00727B');
    decorator.setBackgroundColor('#00727B');
    connection.setTargetDecorator(decorator);
  }

  // Set default line color when unselected
  onUnselect(canvas, connection, isPrimarySelection) {
    connection.setStroke(1);
    connection.setColor(new draw2d.util.Color('#8D949C'));
    const decorator = new draw2d.decoration.connection.ArrowDecorator();
    decorator.setDimension(5, 8);
    decorator.setColor('#8D949C');
    decorator.setBackgroundColor('#8D949C');
    connection.setTargetDecorator(decorator);
  }

}
