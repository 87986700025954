import * as draw2d from '../../../../../../../node_modules/draw2d/dist/draw2d.js';
import { CanvasDragLogService } from '../service/canvas-drag-log-service';
import { CanvasDragEventType } from '../canvas-drag-event';
import { Group } from '../shape/group';
import { fromEvent } from 'rxjs';
import { Label } from '../shape/label';

export class DragDropEditPolicy extends draw2d.policy.figure.DragDropEditPolicy {

  constructor(private canvasDragLogService: CanvasDragLogService, attr?, getter?, setter?) {
    super(attr, getter, setter);
  }

  onMouseDown(canvas, x, y, shiftKey, ctrlKey) {
    super.onMouseDown(canvas, x, y, shiftKey, ctrlKey);
  }

  onInstall(canvas) {
    super.onInstall(canvas);
  }

  onUninstall(canvas) {
    super.onUninstall(canvas);
  }

  onClick(figure, mouseX, mouseY, shiftKey, ctrlKey) {
    super.onClick(figure, mouseX, mouseY, shiftKey, ctrlKey);
    }

  onMouseMove(canvas, x, y, shiftKey, ctrlKey) {
    super.onMouseMove(canvas, x, y, shiftKey, ctrlKey);
  }

  onDoubleClick(figure, mouseX, mouseY, shiftKey, ctrlKey) {
    super.onDoubleClick(figure, mouseX, mouseY, shiftKey, ctrlKey)
  }


  onRightMouseDown(figure, x, y, shiftKey, ctrlKey) {
    super.onRightMouseDown(figure, x, y, shiftKey, ctrlKey);
  }

  onMouseWheel(wheelDelta, x, y, shiftKey, ctrlKey) {
    super.onMouseWheel(wheelDelta, x, y, shiftKey, ctrlKey);
  }

  snap(canvas, figure, modifiedPos, originalPos) {
    super.snap(canvas, figure, modifiedPos, originalPos);
  }

  // Event of figure dragging starting
  onDragStart(canvas, figure, x, y, shiftKey, ctrlKey) {
    if (figure instanceof Group) {
      const childLabel = figure.getChildAtPosition(x, y);
      if (childLabel) {
        let firstMove: boolean = true;
        let oldLabel: Label;
        let removedFromGroup: boolean = false;
        const movedSubscription = fromEvent(document, 'mousemove').subscribe((event: MouseEvent) => {
          const label = new Label(true);
          label.setIcon(childLabel.getIcon());
          label.setName(childLabel.getName());
          label.setId(childLabel.getId());
          label.setUserData(childLabel.getUserData());
          label.installEditPolicy(new DragDropEditPolicy(this.canvasDragLogService));
          const coordinates = canvas.fromDocumentToCanvasCoordinate(event.clientX - 35, event.clientY - 19);
          if (firstMove) {
            figure.removeChild(childLabel);
            canvas.add(label, coordinates);
            firstMove = false;
          }
          else {
            canvas.remove(oldLabel);
            canvas.add(label, coordinates);
          }
          removedFromGroup = true;
          oldLabel = label;
        });
        const droppedSubscription = fromEvent(document, 'mouseup').subscribe((event: MouseEvent) => {
          if (removedFromGroup) {
            canvas.removeTaskFromGroup(childLabel, event);
          }
          movedSubscription.unsubscribe();
          droppedSubscription.unsubscribe();
        });
        return false
      }
      return true;
    }
  }

  // Event of object being dragged at the moment
  onDrag( canvas, figure) {
    this.canvasDragLogService.onDrag({canvas, figure, eventType: CanvasDragEventType.DRAG});
  }

  // Event of figure dragging finished
  onDragEnd( canvas, figure, x, y, shiftKey, ctrlKey ) {
    this.canvasDragLogService.onDrag({canvas, figure, eventType: CanvasDragEventType.DRAG_END});
  }
}
