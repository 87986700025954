/* eslint-disable */
import { Observable, Subject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { LoggerFactory } from './logger-factory';
/* eslint-enable */

export interface Condition {
  condition: () => boolean;
}

export function ofCondition(args: Condition): Promise<void> {
  const condition = args.condition;
  const checkDuration = 30;
  return new Promise(function (resolve, reject) {
    (function waitFor() {
      if (condition()) {
        return resolve();
      }
      setTimeout(waitFor, checkDuration);
    })();
  });
}

/**
 * Usage:
 * - Before we start a fixed amount of async work (for example: 2)
 *   latch = new CountdownLatch(2);
 * - When one result is available or failed to load:
 *   latch.countdown() // important to call it in the error case too!
 * - In this case the values are in time:
 *   [2, 1, 0]
 * - using the results in an async function:
 *   await latch.asPromise();
 *   // do the work (now the value in the latch is 0)
 *   // if one of the resource is failed to load, we can handle it here too
 */
export class CountdownLatch {

  private readonly logger = LoggerFactory.createLogger('CountdownLatch');

  private _subject: Subject<number>;
  private _value: number;
  private _interrupted = false;

  constructor(initValue: number) {
    if (initValue <= 0) {
      throw Error('Invalid initValue. initValue > 0');
    }
    this.logger.debug('init value', initValue);
    this._value = initValue;
    this._subject = new Subject<number>();
    this._subject.next(initValue);
  }

  get interrupted() {
    return this._interrupted;
  }

  private get value(): number {
    return this._value;
  }

  private set value(value: number) {
    if (this.interrupted) {
      value = 0;
    }
    if (value < 0) {
      throw Error('Value must be a positive number');
    }
    this.logger.debug('set value', value);
    this._value = value;
    this._subject.next(value);
  }

  /**
   * Countdown.
   * Fails when the next value is -1.
   */
  countdown() {
    this.value = this.value - 1;
  }

  interrupt() {
    this._interrupted = true;
    this.value = 0;
  }

  asObservable(): Observable<number> {
    return this._subject
      .asObservable()
      .pipe(takeWhile((val) => {
        return val === 0;
      }));
  }

  asPromise(): Promise<number> {
    return this.asObservable().toPromise();
  }

}
