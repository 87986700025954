<div bsModal [config]="UiConstants.modalConfig" #dialog="bs-modal" class="modal" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="dialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title"><strong>{{ 'MILEAGE_RECORD_UPDATE' | translate}}</strong></p>
        <button type="button" class="close" (click)="closeDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body" [formGroup]="formGroup">

        <div class="form-group row"
             [ngClass]="{ 'has-danger': hasLocalFieldError('startKilometer')
             || hasLocalFormError('kilometerInterval')
             || mileageRecordFieldErrors.start_kilometer}">
          <div class="col-md-4">
            <label class="col-form-label mw-100 form-control-label">{{'MILEAGE_RECORD_START_KILOMETER'|translate}}</label>
          </div>
          <div class="col-md-8">
            <input type="text" class="form-control" placeholder="{{'MILEAGE_RECORD_START_KILOMETER' | translate}}"
                   [textMask]="{mask: InputMask.NATURAL_INTEGER, guide: false}"
                   [(ngModel)]="model.startKilometer" formControlName="startKilometer"
                   (ngModelChange)="removeFieldError(mileageRecordFieldErrors.start_kilometer)">
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('startKilometer', 'required')">
              {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
            </div>
            <div class="form-control-feedback" *ngIf="hasLocalFormError('kilometerInterval')">
              {{'COMMON_VALIDATION_MESSAGE_INVALID_NUMBER_FROM_TO' | translate}}
            </div>
            <div class="form-control-feedback" *ngIf="mileageRecordFieldErrors.start_kilometer">
              {{mileageRecordFieldErrors.start_kilometer.text}}
            </div>
          </div>
        </div>

        <div class="form-group row"
             [ngClass]="{ 'has-danger': hasLocalFieldError('finishKilometer')
             || hasLocalFormError('kilometerInterval')
             || mileageRecordFieldErrors.finish_kilometer}">
          <div class="col-md-4">
            <label class="col-form-label mw-100 form-control-label">{{'MILEAGE_RECORD_FINISH_KILOMETER'|translate}}</label>
          </div>
          <div class="col-md-8">
            <input type="text" class="form-control" placeholder="{{'MILEAGE_RECORD_FINISH_KILOMETER' | translate}}"
                   [textMask]="{mask: InputMask.NATURAL_INTEGER, guide: false}"
                   [(ngModel)]="model.finishKilometer" formControlName="finishKilometer"
                   (ngModelChange)="removeFieldError(mileageRecordFieldErrors.finish_kilometer)">
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('finishKilometer', 'required')">
              {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
            </div>
            <div class="form-control-feedback" *ngIf="mileageRecordFieldErrors.finish_kilometer">
              {{mileageRecordFieldErrors.finish_kilometer.text}}
            </div>
            <div class="form-control-feedback" *ngIf="hasLocalFormError('kilometerInterval')">
              {{'COMMON_VALIDATION_MESSAGE_INVALID_NUMBER_FROM_TO' | translate}}
            </div>
          </div>
        </div>

        <div class="form-group row"
             [ngClass]="{ 'has-danger': hasLocalFieldError('vehicle') }">
          <div class="col-md-4">
            <label class="col-form-label mw-100 form-control-label">{{'TRANSPORT_VEHICLE'|translate}}
            </label>
          </div>
          <div class="col-md-8">
            <div class="mw-100">
              <angular2-multiselect
                [(ngModel)]="model.vehicle"
                [data]="vehicles"
                [settings]="singleRemoteDropdownSettings"
                (onRemoteSearch)="loadVehicles($event.target.value)"
                formControlName="vehicle">
              </angular2-multiselect>
              <div class="form-control-feedback" *ngIf="hasLocalFieldError('vehicle', 'required')">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-md-4">
            <label class="col-form-label mw-100 form-control-label">{{'COMMON_NOTE'|translate}}</label>
          </div>
          <div class="col-md-8">
            <input type="text" class="form-control" placeholder="{{'COMMON_NOTE' | translate}}"
                   [(ngModel)]="model.note"
                   [ngModelOptions]="{standalone: true}">
          </div>
        </div>

        <div class="form-group row">
          <div class="col-md-4">
            <label class="col-form-label mw-100 form-control-label">{{'MILEAGE_RECORD_JOURNEY_TYPE'|translate}}</label>
          </div>
          <div class="col-md-8">
            <input type="text" class="form-control" placeholder="{{'MILEAGE_RECORD_JOURNEY_TYPE' | translate}}"
                   [(ngModel)]="model.journeyType"
                   [ngModelOptions]="{standalone: true}">
          </div>
        </div>

      </div><!-- /.modal-body -->

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeDialog()">{{'COMMON_BUTTON_CANCEL' |
          translate}}
        </button>
        <button type="submit" (click)="update()" class="btn btn-primary">{{'COMMON_BUTTON_SAVE' |
          translate}}
        </button>
      </div>

    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
