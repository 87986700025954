/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { Order, PagingRequest, QueryResult, ResourceQueryResult, Services } from '../../util/services';
import { List, Map, Set } from 'immutable';
import { TransportLogResource, TransportLogResourceService } from './transport-log-resource.service';
import { OffsetDateTime } from '../../util/dates';
import { TransportTask } from '../transport-task/transport-task.service';
import { Address, AddressResource } from '../../address';
import TransportLogType = TransportLog.TransportLogType;

/* eslint-enable */


@Injectable()
export class TransportLogService implements TransportLog.Service {

  constructor(private resourceService: TransportLogResourceService) {
  }

  query(request: TransportLog.QueryRequest): Observable<QueryResult<TransportLog.TransportLog>> {
    return Observable.create((observer: Observer<QueryResult<TransportLog.TransportLog>>) => {
      const resourceRequest: TransportLogResource.QueryRequest = {
        transport_id: request.transportId,
        q: request.queryText,
        order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
      };
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<TransportLogResource.TransportLog>) => {
          observer.next({
            items: TransportLogMapper.toPublicList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  get(request: TransportLog.GetRequest): Observable<TransportLog.TransportLog> {
    return Observable.create((observer: Observer<TransportLog.TransportLog>) => {
      const resourceRequest: TransportLogResource.GetRequest = {
        transport_id: request.transportId,
        id: request.id
      };
      return this.resourceService.get(resourceRequest).subscribe(
        (result: TransportLogResource.TransportLog) => {
          observer.next(TransportLogMapper.toPublic(result));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

}

export class TransportLogMapper {
  public static toPublicList(resourceList: TransportLogResource.TransportLog[]): List<TransportLog.TransportLog> {
    return List.of(...resourceList.map((r) => this.toPublic(r)));
  }

  public static toPublic(r: TransportLogResource.TransportLog): TransportLog.TransportLog {
    return {
      id: r.id,
      creationTime: Services.toOffsetDateTime(r.creation_time),
      user: this.toPublicUserData(r.user),
      shipment: this.toPublicShipmentData(r.shipment),
      coordinate: AddressResource.Mapper.toPublicCoordinate(r.coordinate),
      eventType: <TransportLogType>r.event_type,
      gateKeeperExternalId: r.gate_keeper_external_id,
      transportTask: this.toPublicTransportTask(r.transport_task)
    };
  }

  public static toPublicUserData(r: TransportLogResource.UserData): TransportLog.UserData {
    return {
      id: r.id,
      personName: r.person_name
    };
  }

  public static toPublicShipmentData(r: TransportLogResource.ShipmentData | undefined): TransportLog.ShipmentData | undefined {
    if (r) {
      return {
        deliveryNoteNumber: r.delivery_note_number
      };
    }
    return undefined;
  }

  public static toPublicTransportTask(r: TransportLogResource.TransportTaskData | undefined) {
    if (!r) {
      return undefined;
    }
    return {
      index: r.index,
      shippingPlace: TransportTask.ToPublicMapper.toPublicShippingPlace(r.shipping_place)
    };
  }
}


export namespace TransportLog {

  export interface Service {

    // <editor-fold desc="CRUD">

    query(request: QueryRequest): Observable<QueryResult<TransportLog>>;

    get(request: GetRequest): Observable<TransportLog>;

    // </editor-fold>

  }

  export interface TransportLog {
    id: number;
    creationTime: OffsetDateTime;
    user: UserData;
    shipment?: ShipmentData;
    coordinate?: Address.Coordinate;
    eventType: TransportLogType;
    gateKeeperExternalId?: string;
    transportTask?: TransportTaskData;
  }

  export interface TransportTaskData {
    index: number;
    shippingPlace: TransportTask.ShippingPlace;
  }

  export interface ShipmentData {
    deliveryNoteNumber: string;
  }

  export interface UserData {
    id: number;
    personName: string;
  }

  export interface QueryRequest {
    transportId: number;
    queryText?: string;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
  }

  export interface GetRequest {
    transportId: number,
    id: number;
  }

  export interface TransportTask {
    index: number;
    shippingPlace: ShippingPlace
  }

  export interface ShippingPlace {
    id: number;
    name: string;
    postalAddress: Address.PostalAddressData;
    coordinate?: Address.Coordinate;
    geocodeStatus: AddressResource.GeocodeStatus;
    stockId?: number;
    locationId?: number;
  }

  export enum OrderField {
    ID,
    CREATION_TIME,
    SHIPMENT_DELIVERY_NOTE_NUMBER,
    USER_PERSON_NAME,
  }

  export type TransportLogType = 'GATE_CROSSING' | 'TRANSPORT_OPEN' | 'TRANSPORT_PLAN' | 'TRANSPORT_START' |
    'TRANSPORT_REOPEN' | 'TRANSPORT_REPLAN' | 'TRANSPORT_CONTINUE' | 'TRANSPORT_FINISH' | 'TRANSPORT_CLOSE' |
    'TRANSPORT_GO' | 'TRANSPORT_ARRIVE' | 'TRANSPORT_CREATE' | 'TRANSPORT_SET_SECURITY_GUARD' | 'TRANSPORT_SET_DRIVER' |
    'TRANSPORT_SET_VEHICLE' | 'OUTTAKE_START' | 'OUTTAKE_REOPEN' | 'OUTTAKE_FINISH' | 'OUTTAKE_FORCE_REOPEN' |
    'OUTTAKE_CHANGE_STOREKEEPER' | 'TAKEOVER_START' | 'TAKEOVER_START_FAIL' | 'TAKEOVER_REOPEN' | 'TAKEOVER_FINISH' |
    'HANDOVER_START' | 'HANDOVER_REOPEN' | 'HANDOVER_FINISH' | 'HANDOVER_FINISH_FAIL' | 'HANDOVER_START_FAIL' |
    'SHIPMENT_CREATE' | 'SHIPMENT_REMOVE' | 'TRANSPORT_TASK_ADDED' | 'TRANSPORT_TASK_REMOVED'
    ;

  export class TransportLogTypeObject {
    type: TransportLogType;
    stringKey: string;
  }

  export const transportLogTypes: TransportLogTypeObject[] = [
    {type: 'GATE_CROSSING', stringKey: 'TRANSPORT_LOG_TYPE_GATE_CROSSING'},
    {type: 'TRANSPORT_OPEN', stringKey: 'TRANSPORT_LOG_TYPE_TRANSPORT_OPEN'},
    {type: 'TRANSPORT_PLAN', stringKey: 'TRANSPORT_LOG_TYPE_TRANSPORT_PLAN'},
    {type: 'TRANSPORT_START', stringKey: 'TRANSPORT_LOG_TYPE_TRANSPORT_START'},
    {type: 'TRANSPORT_REOPEN', stringKey: 'TRANSPORT_LOG_TYPE_TRANSPORT_REOPEN'},
    {type: 'TRANSPORT_REPLAN', stringKey: 'TRANSPORT_LOG_TYPE_TRANSPORT_REPLAN'},
    {type: 'TRANSPORT_CONTINUE', stringKey: 'TRANSPORT_LOG_TYPE_TRANSPORT_CONTINUE'},
    {type: 'TRANSPORT_FINISH', stringKey: 'TRANSPORT_LOG_TYPE_TRANSPORT_FINISH'},
    {type: 'TRANSPORT_CLOSE', stringKey: 'TRANSPORT_LOG_TYPE_TRANSPORT_CLOSE'},
    {type: 'TRANSPORT_GO', stringKey: 'TRANSPORT_LOG_TYPE_TRANSPORT_GO'},
    {type: 'TRANSPORT_ARRIVE', stringKey: 'TRANSPORT_LOG_TYPE_TRANSPORT_ARRIVE'},
    {type: 'TRANSPORT_CREATE', stringKey: 'TRANSPORT_LOG_TYPE_TRANSPORT_CREATE'},
    {type: 'TRANSPORT_SET_SECURITY_GUARD', stringKey: 'TRANSPORT_LOG_TYPE_TRANSPORT_SET_SECURITY_GUARD'},
    {type: 'TRANSPORT_SET_DRIVER', stringKey: 'TRANSPORT_LOG_TYPE_TRANSPORT_SET_DRIVER'},
    {type: 'TRANSPORT_SET_VEHICLE', stringKey: 'TRANSPORT_LOG_TYPE_TRANSPORT_SET_VEHICLE'},
    {type: 'OUTTAKE_START', stringKey: 'TRANSPORT_LOG_TYPE_OUTTAKE_START'},
    {type: 'OUTTAKE_REOPEN', stringKey: 'TRANSPORT_LOG_TYPE_OUTTAKE_REOPEN'},
    {type: 'OUTTAKE_FINISH', stringKey: 'TRANSPORT_LOG_TYPE_OUTTAKE_FINISH'},
    {type: 'OUTTAKE_FORCE_REOPEN', stringKey: 'TRANSPORT_LOG_TYPE_OUTTAKE_FORCE_REOPEN'},
    {type: 'OUTTAKE_CHANGE_STOREKEEPER', stringKey: 'TRANSPORT_LOG_TYPE_OUTTAKE_CHANGE_STOREKEEPER'},
    {type: 'TAKEOVER_START', stringKey: 'TRANSPORT_LOG_TYPE_TAKEOVER_START'},
    {type: 'TAKEOVER_START_FAIL', stringKey: 'TRANSPORT_LOG_TYPE_TAKEOVER_START_FAIL'},
    {type: 'TAKEOVER_REOPEN', stringKey: 'TRANSPORT_LOG_TYPE_TAKEOVER_REOPEN'},
    {type: 'TAKEOVER_FINISH', stringKey: 'TRANSPORT_LOG_TYPE_TAKEOVER_FINISH'},
    {type: 'HANDOVER_START', stringKey: 'TRANSPORT_LOG_TYPE_HANDOVER_START'},
    {type: 'HANDOVER_REOPEN', stringKey: 'TRANSPORT_LOG_TYPE_HANDOVER_REOPEN'},
    {type: 'HANDOVER_FINISH', stringKey: 'TRANSPORT_LOG_TYPE_HANDOVER_FINISH'},
    {type: 'HANDOVER_FINISH_FAIL', stringKey: 'TRANSPORT_LOG_TYPE_HANDOVER_FINISH_FAIL'},
    {type: 'HANDOVER_START_FAIL', stringKey: 'TRANSPORT_LOG_TYPE_HANDOVER_START_FAIL'},
    {type: 'SHIPMENT_CREATE', stringKey: 'TRANSPORT_LOG_TYPE_SHIPMENT_CREATE'},
    {type: 'SHIPMENT_REMOVE', stringKey: 'TRANSPORT_LOG_TYPE_SHIPMENT_REMOVE'},
    {type: 'TRANSPORT_TASK_ADDED', stringKey: 'TRANSPORT_LOG_TYPE_TRANSPORT_TASK_ADDED'},
    {type: 'TRANSPORT_TASK_REMOVED', stringKey: 'TRANSPORT_LOG_TYPE_TRANSPORT_TASK_REMOVED'},
  ];

}

class Keys {

  private static readonly ID = 'id';
  private static readonly CREATION_TIME = 'creation_time';
  private static readonly SHIPMENT_DELIVERY_NOTE_NUMBER = 'shipment_delivery_note_number';
  private static readonly USER_PERSON_NAME = 'user_person_name';


  private static readonly orderFieldKeyMap: Map<TransportLog.OrderField, string> = Map.of(
    TransportLog.OrderField.ID, Keys.ID,
    TransportLog.OrderField.CREATION_TIME, Keys.CREATION_TIME,
    TransportLog.OrderField.SHIPMENT_DELIVERY_NOTE_NUMBER, Keys.SHIPMENT_DELIVERY_NOTE_NUMBER,
    TransportLog.OrderField.USER_PERSON_NAME, Keys.USER_PERSON_NAME,
  );

  public static toOrderFieldKey(field: TransportLog.OrderField): string {
    return Keys.orderFieldKeyMap.get(field)!;
  }

}
