<div>
  <label class="warning-text" *ngIf="!zeroPricedRecordsAllowed && hasZeroPrice()">{{'INVOICE_RECORD_ZERO_PRICE_NOT_ALLOWED' | translate}}</label>
  <table class="table table-sm invoice-create-table">
    <thead>
    <tr>
      <th class="responsive-table-column w-5 hidden-sm-down"></th>
      <th class="responsive-table-column w-15">{{'COMMON_NAME' | translate}}</th>
      <th class="responsive-table-column w-10 hidden-sm-down">{{'INVOICE_RECORD_AMOUNT' | translate}}</th>
      <th class="responsive-table-column w-10 hidden-lg-down">{{'INVOICE_RECORD_HUN_VTSZ_NUMBER' | translate}}</th>
      <th class="responsive-table-column w-10 hidden-sm-down">{{'INVOICE_RECORD_NET_UNIT_PRICE' | translate}}</th>
      <th class="responsive-table-column w-10 hidden-sm-down">{{'INVOICE_RECORD_VAT_RATE' | translate}}</th>
      <th class="responsive-table-column w-10">{{'INVOICE_RECORD_PRICE' | translate}}</th>
      <th class="responsive-table-column w-15 hidden-md-down">{{'COMMON_DESCRIPTION' | translate}}</th>
      <th class="responsive-table-column w-10 hidden-md-down">{{'LEDGER_NUMBER_LEDGER_NUMBER' | translate}}</th>
      <th class="responsive-table-column w-5" *ngIf="!readonly"></th>
    </tr>
    </thead>
    <tbody *ngFor="let record of recordCreateModels; index as i;">
    <tr *ngIf="shouldPresentStockItem(record)">
      <td class="responsive-table-column w-5 text-center align-middle hidden-sm-down">{{i + 1}}</td>
      <td class="responsive-table-column w-15 align-middle">{{record.recordName}}</td>
      <td class="responsive-table-column w-10 align-middle hidden-sm-down">{{record.amount | formattedNumber}} {{record.unitType}}</td>
      <td class="responsive-table-column w-10 align-middle hidden-lg-down">{{record.hunVtszNumber}}</td>
      <td
        class="responsive-table-column w-10 align-middle hidden-md-down">{{record.netUnitPrice | formattedNumber}} {{record.currencyCode}}</td>
      <td class="responsive-table-column w-10 align-middle hidden-sm-down">{{record.vatRate}}%</td>
      <td class="responsive-table-column w-10 align-middle" *ngIf="!readonly">
        <p class="text-muted m-0">net. {{roundRecordPrice(record.rawNetPrice) | formattedNumber}} {{record.currencyCode}}</p>
        {{roundRecordPrice(record.rawGrossPrice) | formattedNumber}} {{record.currencyCode}}
      </td>
      <td class="responsive-table-column w-10 align-middle" *ngIf="readonly">
        <p class="text-muted m-0">net. {{record.readonlyNetPrice | formattedNumber}} {{record.currencyCode}}</p>
        {{record.readonlyGrossPrice | formattedNumber}} {{record.currencyCode}}
      </td>
      <td class="responsive-table-column w-15 align-middle hidden-md-down">{{record.comment}}</td>
      <td class="responsive-table-column w-10 align-middle hidden-md-down">{{record.ledgerNumberString}}</td>
      <td class="responsive-table-column w-5 align-middle" (click)="toggleEdit(i)" *ngIf="!readonly"><i
        class="icomoon icomoon-detail cursor-pointer"></i></td>
    </tr>
    <tr *ngIf="record.editing">
      <td colspan="100%" style="padding: 0">
        <app-invoice-record-edit
          [model]="record"
          [selectableVatRates]="selectableVatRates"
          [selectableZeroVatRateTypes]="selectableZeroVatRateTypes"
          [zeroPricedRecordsAllowed]="zeroPricedRecordsAllowed"
          [rightModel]="rightModel"
          (remove)="removeRecord(i)"
          (cancel)="closeRow(i)"
          (save)="saveRecord(i, $event)"></app-invoice-record-edit>
      </td>
    </tr>
    </tbody>
    <tbody *ngIf="!addModel && (!recordCreateModels || recordCreateModels.length === 0)">
    <tr>
      <td colspan="100%">
        <div class="text-center text-muted p-2">
          <label class="m-0">{{'INVOICE_RECORD_NO_RECORD' | translate}}</label>
        </div>
      </td>
    </tr>
    </tbody>
    <tbody *ngIf="addModel">
    <tr>
      <td colspan="100%" class="p-0">
        <app-invoice-record-edit
          [model]="addModel"
          [isRemovable]="false"
          [rightModel]="rightModel"
          [selectableVatRates]="selectableVatRates"
          [selectableZeroVatRateTypes]="selectableZeroVatRateTypes"
          (cancel)="cancelNewRecord()"
          (save)="saveNewRecord($event)"></app-invoice-record-edit>
      </td>
    </tr>
    </tbody>
    <tr *ngIf="!readonly">
      <td colspan="100%">
        <input class="btn btn-add-record cursor-pointer" type="button" [disabled]="addModel"
               value="{{'INVOICE_RECORD_ADD_BUTTON' | translate}}" (click)="newRecord()">
      </td>
    </tr>
  </table>

  <div *ngIf="showSummary && !summaryModel">
    <h5>{{'INVOICE_SUM' | translate}}</h5>
    <hr>
    <table class="pull-right mb-1">
      <tr>
        <td class="summary_td text-right text-muted"><h6>{{'INVOICE_SUM_GROSS_PRICE' | translate}}</h6></td>
        <td *ngIf="prices.gross" class="summary_td"><h6>{{prices.gross | formattedNumber}} {{prices.currencyCode}}</h6></td>
      </tr>
      <tr>
        <td class="summary_td text-right text-muted">{{'INVOICE_SUM_NET_PRICE' | translate}}</td>
        <td *ngIf="prices.net" class="summary_td text-muted">{{prices.net | formattedNumber}} {{prices.currencyCode}}</td>
      </tr>
    </table>
  </div>

  <div *ngIf="showSummary && summaryModel">
    <table class="pull-right mb-1">
      <tr>
        <td class="summary_td text-right text-secondary"><h6>{{'INVOICE_SUM_GROSS_PRICE' | translate}}</h6>
        </td>
        <td class="summary_td">
          <h6>{{summaryModel.grossSumPrice.toFixed(0) | formattedNumber}} {{summaryModel.currencyCode}}</h6></td>
      </tr>
      <tr>
        <td class="summary_td text-right text-secondary">{{'INVOICE_SUM_NET_PRICE' | translate}}</td>
        <td class="summary_td text-muted">{{summaryModel.netSumPrice.toFixed(0) | formattedNumber}} {{summaryModel.currencyCode}}</td>
      </tr>
    <tr *ngFor="let vatsum of summaryModel.vatSums">
      <td class="summary_td text-right text-secondary">{{vatsum.vatRate | formattedNumber}}
        % {{vatsum.zeroVatRateType ? '(' + vatsum.zeroVatRateType + ')' : ''}}</td>
      <td class="summary_td">{{vatsum.sumVatAmount.toFixed(0) | formattedNumber}} {{summaryModel.currencyCode}}</td>
    </tr>
    </table>
  </div>
</div>
