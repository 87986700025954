<div class="container-horizontal-padding-custom animated fadeIn">
  <div class="container-vertical-padding-custom">

    <div class="row" id="dashboard-user-cards">

      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 cursor-pointer margin-bottom-30" (click)="usersCardClicked()"
           *ngIf="rightModel.userRead.hasRight()" id="dashboard-user-card">
        <div class="card with-top-border">
          <div class="card-body">
            <div class="h1 float-right mb-0">
              <i class="icomoon icomoon-administration-user-management dashboard-customer"></i>
            </div>
            <div class="h4 mb-0">{{userCount}}</div>
            <small class="text-uppercase font-weight-bold">{{'COMMON_USERS' | translate}}</small>
          </div>
        </div>
      </div><!--/.col-->

      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 cursor-pointer margin-bottom-30" (click)="customerCardClicked()"
           *ngIf="rightModel.customerRead.hasRight()" id="dashboard-customer-card">
        <div class="card with-top-border">
          <div class="card-body">
            <div class="h1 float-right mb-0">
              <i class="icomoon icomoon-administration-application dashboard-user"></i>
            </div>
            <div class="h4 mb-0">{{customerCount}}</div>
            <small class="text-uppercase font-weight-bold">{{'COMMON_CUSTOMERS' | translate}}</small>
          </div>
        </div>
      </div><!--/.col-->

      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 cursor-pointer margin-bottom-30" (click)="tasksCardClicked()"
           *ngIf="rightModel.taskRecordRead.hasRight()" id="dashboard-task-card">
        <div class="card with-top-border">
          <div class="card-body">
            <div class="h1 float-right mb-0">
              <i class="icomoon icomoon-dashboard-forms dashboard-task"></i>
            </div>
            <div class="h4 mb-0">{{taskRecordCount}}</div>
            <small class="text-uppercase font-weight-bold">{{'MENU_NAVBAR_FORMS' | translate}}</small>
          </div>
        </div>
      </div><!--/.col-->

      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 cursor-pointer margin-bottom-30"
           (click)="documentFileCardClicked()"
           *ngIf="rightModel.documentRead.hasRight()">
        <div class="card with-top-border">
          <div class="card-body">
            <div class="h1 float-right mb-0">
              <i class="icomoon icomoon-documents dashboard-envelope"></i>
            </div>
            <div class="h4 mb-0">{{documentFileCount}}</div>
            <small class=" text-uppercase font-weight-bold">{{'COMMON_DOCUMENTS' | translate}}</small>
          </div>
        </div>
      </div><!--/.col-->

    </div><!--/.row-->

    <div class="row flex-column-reverse flex-md-row">

      <div class="col-lg-9 col-md-8 col-sm-12 col-xs-12 mb-1">

        <ng-container *ngIf="this.rightModel.taskRead.hasRight()">
          <h5 class="mb-1">{{'DASHBOARD_TASK_GROUPS' | translate}}</h5>

          <div class="text-center mt-2" *ngIf="!isChartVisible || dashboardTaskModels.length === 0">
            <img src="../../../../assets/img/empty-dashboard.png" alt="" class="empty-dashboard-image">
            <h4 class="mt-2">{{'EMPTY_DASHBOARD_NO_TASK_GROUP' | translate}}</h4>
          </div>

          <div class="row" *ngIf="rightModel.taskRead.hasRight() && isChartVisible &&  dashboardTaskModels.length > 0">

            <div *ngFor="let taskModel of dashboardTaskModels; index as i;"
                 class="mt-0 margin-bottom-30 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
              <div class="card" id="dashboard-task-chart-{{i}}">
                <h6 class="card-header">
                  <div class="d-flex align-items-center" [ngStyle]="{'color': getColor(taskModel)}">
                  <app-type-icon [icon]="taskModel.icon"
                                 class="mr-05"></app-type-icon>
                  {{taskModel.name}}
                  </div>
                  <div class="card-actions">
                    <a href="#" onclick="return false" uiSref="Admin.TaskRecordCreate"
                       *ngIf="taskModel.creatable"
                       [uiParams]="{ taskId: taskModel.taskId }">
                      <i class="icomoon icomoon-add"></i>
                    </a>
                    <a href="#" onclick="return false" uiSref="Admin.TaskRecordList"
                       [uiParams]="{ taskId: taskModel.taskId }">
                      <i class="icomoon icomoon-dashboard-task-universal"></i>
                    </a>
                  </div>
                </h6>
                <div class="card-body">
                  <div class="d-flex flex-row flex-wrap justify-content-between align-items-center">
                    <div class="d-flex justify-content-around flex-grow-1 mb-1">
                      <app-dashboard-pie-chart
                        *ngIf="showGoogleCharts"
                        [dashboardTaskModel]="taskModel">
                      </app-dashboard-pie-chart>
                    </div>
                    <div class="d-flex justify-content-around flex-grow-1 align-items-center mb-1">
                      <div>
                        <div class="d-flex mb-1">
                          <div class="d-flex justify-content-start align-items-center">
                            <span class="circle-green px-1"></span>
                          </div>
                          <div class="justify-content-start align-items-center">
                            <h5 class="mb-0">{{taskModel.getStat('NEW').count}}</h5>
                            <p class="mb-0">{{'COMMON_VALUE_TASK_STATE_NEW' | translate}}</p>
                          </div>
                        </div>
                        <div class="d-flex">
                          <div class="d-flex justify-content-start align-items-center">
                            <span class="circle-yellow px-1"></span>
                          </div>
                          <div class="justify-content-start align-items-center">
                            <h5 class="mb-0">{{taskModel.getStat('IN_PROGRESS').count}}</h5>
                            <p class="mb-0">{{'COMMON_VALUE_TASK_STATE_IN_PROGRESS' | translate}}</p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div class="d-flex mb-1">
                          <div class="d-flex justify-content-start align-items-center">
                            <span class="circle-orange px-1"></span>
                          </div>
                          <div class="justify-content-start align-items-center">
                            <h5 class="mb-0">{{taskModel.getStat('OPEN').count}}</h5>
                            <p class="mb-0">{{'COMMON_VALUE_TASK_STATE_OPEN' | translate}}</p>
                          </div>
                        </div>
                        <div class="d-flex">
                          <div class="d-flex justify-content-start align-items-center">
                            <span class="circle-blue px-1"></span>
                          </div>
                          <div class="justify-content-start align-items-center">
                            <h5 class="mb-0">{{taskModel.getStat('FINISHED').count}}</h5>
                            <p class="mb-0">{{'COMMON_VALUE_TASK_STATE_FINISHED' | translate}}</p>
                          </div>
                        </div>
                      </div>
                      <!-- /.d-flex flex-row justify-content-start align-items-center -->
                    </div>
                    <!-- /.row -->
                    <!-- /.d-flex flex-row -->
                  </div>
                  <div class="row">
                    <div class="col-sm-6 mt-1">
                      <div class="d-flex flex-row flex-wrap justify-content-between">
                        <p class="card-text mb-0">{{ 'COMMON_VALUE_TASK_STATE_RECALLED' | translate}}</p>
                        <p class="progress-bar-title mb-0">
                          <span class="font-weight-bold">{{ taskModel.getStat('RECALLED').count }}</span>
                          <span class="dashboard-small-percentage"> ({{ taskModel.getStat('RECALLED').percent }}
                            %)</span>
                        </p>
                      </div>
                      <div class="progress">
                        <div class="progress-bar dashboard-progress-bar" role="progressbar"
                             [ngStyle]="{width: taskModel.getStat('RECALLED').percent + '%'}"
                             [attr.aria-valuenow]="taskModel.getStat('RECALLED').percent" aria-valuemin="0"
                             aria-valuemax="100">
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 mt-1">
                      <div class="d-flex flex-row flex-wrap justify-content-between">
                        <p class="card-text mb-0">{{ 'COMMON_VALUE_TASK_STATE_REJECTED' | translate}}</p>
                        <p class="progress-bar-title mb-0">
                          <span class="font-weight-bold">{{ taskModel.getStat('REJECTED').count }}</span>
                          <span class="dashboard-small-percentage"> ({{ taskModel.getStat('REJECTED').percent }}
                            %)</span>
                        </p>
                      </div>
                      <div class="progress">
                        <div class="progress-bar dashboard-progress-bar" role="progressbar"
                             [ngStyle]="{width: taskModel.getStat('REJECTED').percent + '%'}"
                             [attr.aria-valuenow]="taskModel.getStat('REJECTED').percent" aria-valuemin="0"
                             aria-valuemax="100">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6 mt-1">
                      <div class="d-flex flex-row flex-wrap justify-content-between">
                        <p class="card-text mb-0">{{ 'COMMON_VALUE_TASK_STATE_PENDING_APPROVAL' | translate}}</p>
                        <p class="progress-bar-title mb-0">
                          <span class="font-weight-bold">{{ taskModel.getStat('PENDING_APPROVAL').count }}</span>
                          <span class="dashboard-small-percentage"> ({{ taskModel.getStat('PENDING_APPROVAL').percent }}
                            %)</span>
                        </p>
                      </div>
                      <div class="progress">
                        <div class="progress-bar dashboard-progress-bar" role="progressbar"
                             [ngStyle]="{width: taskModel.getStat('PENDING_APPROVAL').percent + '%'}"
                             [attr.aria-valuenow]="taskModel.getStat('PENDING_APPROVAL').percent" aria-valuemin="0"
                             aria-valuemax="100">
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 mt-1">
                      <div class="d-flex flex-row flex-wrap justify-content-between">
                        <p class="card-text mb-0">{{ 'COMMON_VALUE_TASK_STATE_SUBMITTED' | translate}}</p>
                        <p class="progress-bar-title mb-0">
                          <span class="font-weight-bold">{{ taskModel.getStat('SUBMITTED').count }}</span>
                          <span class="dashboard-small-percentage"> ({{ taskModel.getStat('SUBMITTED').percent }}
                            %)</span>
                        </p>
                      </div>
                      <div class="progress">
                        <div class="progress-bar dashboard-progress-bar" role="progressbar"
                             [ngStyle]="{width: taskModel.getStat('SUBMITTED').percent + '%'}"
                             [attr.aria-valuenow]="taskModel.getStat('SUBMITTED').percent" aria-valuemin="0"
                             aria-valuemax="100">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6 mt-1">
                      <div class="d-flex flex-row flex-wrap justify-content-between">
                        <p class="card-text mb-0">{{ 'COMMON_VALUE_TASK_STATE_EXPORTED' | translate}}</p>
                        <p class="progress-bar-title mb-0">
                          <span class="font-weight-bold">{{ taskModel.getStat('EXPORTED').count }}</span>
                          <span class="dashboard-small-percentage"> ({{ taskModel.getStat('EXPORTED').percent }}
                            %)</span>
                        </p>
                      </div>
                      <div class="progress">
                        <div class="progress-bar dashboard-progress-bar" role="progressbar"
                             [ngStyle]="{width: taskModel.getStat('EXPORTED').percent + '%'}"
                             [attr.aria-valuenow]="taskModel.getStat('EXPORTED').percent" aria-valuemin="0"
                             aria-valuemax="100">
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 mt-1">
                      <div class="d-flex flex-row flex-wrap justify-content-between">
                        <p class="card-text mb-0">{{ 'COMMON_VALUE_TASK_STATE_ARCHIVED' | translate}}</p>
                        <p class="progress-bar-title mb-0">
                          <span class="font-weight-bold">{{ taskModel.getStat('ARCHIVED').count }}</span>
                          <span class="dashboard-small-percentage"> ({{ taskModel.getStat('ARCHIVED').percent }}
                            %)</span>
                        </p>
                      </div>
                      <div class="progress">
                        <div class="progress-bar dashboard-progress-bar" role="progressbar"
                             [ngStyle]="{width: taskModel.getStat('ARCHIVED').percent + '%'}"
                             [attr.aria-valuenow]="taskModel.getStat('ARCHIVED').percent" aria-valuemin="0"
                             aria-valuemax="100">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6 mt-1">
                      <div class="d-flex flex-row flex-wrap justify-content-between">
                        <p class="card-text mb-0">{{ 'COMMON_VALUE_TASK_STATE_REVERTED' | translate}}</p>
                        <p class="progress-bar-title mb-0">
                          <span class="font-weight-bold">{{ taskModel.getStat('REVERTED').count }}</span>
                          <span
                            class="dashboard-small-percentage"> ({{ taskModel.getStat('REVERTED').percent }}%)</span>
                        </p>
                      </div>
                      <div class="progress">
                        <div class="progress-bar dashboard-progress-bar" role="progressbar"
                             [ngStyle]="{width: taskModel.getStat('REVERTED').percent + '%'}"
                             [attr.aria-valuenow]="taskModel.getStat('REVERTED').percent" aria-valuemin="0"
                             aria-valuemax="100">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ml-0 mr-0" *ngIf="rightModel.taskRead.hasRight() && hasTaskMorePages()">
            <div class="d-flex align-items-center justify-content-center">
              <button class="btn btn-outline-primary" (click)="loadTasks()">+{{'TASK_RECORD_BOARD_SHOW_MORE' | translate}}</button>
            </div>
          </div>

        </ng-container>

      </div>

      <div class="col-lg-3 col-md-4 col-sm-12 col-xs-12">
        <div class="row">
          <div class="col-md-12">

            <ng-container *ngIf="rightModel.taskRecordChatRead.hasRight()">
              <h5 class="mb-0">{{ 'TASK_RECORD_MESSAGE_LIST' | translate}}</h5>
              <!--list of messages-->
              <ul class="list-group mt-1 margin-bottom-30">
                <li class="list-group-item d-flex justify-content-center" *ngIf="taskRecordMessageList.length === 0">
                  <div class="text-center mt-1 mb-1">
                    <span class="icomoon icomoon-message-unread empty-list-icon"></span>
                    <p class="mb-0 empty-list-text">{{ 'EMPTY_DASHBOARD_NO_NOTIFICATION' | translate}}</p>
                  </div>
                </li>
                <ng-container *ngIf="taskRecordMessageList.length > 0">
                  <li class="list-group-item" *ngFor="let message of taskRecordMessageList">
                    <div class="media w-100"
                         #media>
                      <div class="media-body d-flex flex-column">
                        <div class="d-flex flex-row flex-wrap justify-content-between">
                          <p
                            class="message-heading mb-0 d-inline-block text-wrap">{{ message.chatMessage.userPersonName }} </p>
                          <p
                            class="message-date mb-0">{{message.chatMessage.creationTime.toUtcIsoString() | date: 'short' }}</p>
                        </div>
                        <p class="message-text mb-0 d-inline-block text-wrap">{{message.chatMessage.content}}</p>
                        <a class="mb-0 d-inline-block text-wrap id-text cursor-pointer"
                           href="#" onclick="return false"
                           uiSref="Admin.ProcessDetail"
                           [uiParams]="{ id: message.taskRecord.process.id}"
                           title="{{'PROCESS' | translate}}"
                           *ngIf="message.taskRecord.process">{{ message.taskRecord.process.name }}:</a>
                        <a class="mb-0 d-inline-block text-wrap id-text cursor-pointer"
                           href="#" onclick="return false"
                           uiSref="Admin.TaskRecordDetail"
                           [uiParams]="{ taskId: message.taskRecord.taskId, taskRecordId: message.taskRecord.taskRecordId, '#': 'messages' }"
                           title="{{'TASK_RECORD' | translate}}"
                        >{{ message.taskRecord.name }}</a>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item">
                    <a class="mb-0 d-inline-block text-wrap cursor-pointer"
                       href="#" onclick="return false"
                       uiSref="Admin.MessageLists"
                       [uiParams]="{'#': 'task'}"
                       title="{{'COMMON_SHOW_ALL' | translate}}"
                    >{{ 'COMMON_SHOW_ALL' | translate }}</a>
                  </li>
                </ng-container>
              </ul>
              <!--list of messages end-->
            </ng-container>

            <ng-container *ngIf="rightModel.processRead.hasRight() && isEnabled('WORKFLOW')">
              <h5 class="mb-0">{{ 'PROCESS_MESSAGE_LIST' | translate}}</h5>
              <!--list of messages-->
              <ul class="list-group mt-1 margin-bottom-30">
                <li class="list-group-item d-flex justify-content-center" *ngIf="processMessageList.length === 0">
                  <div class="text-center mt-1 mb-1">
                    <span class="icomoon icomoon-message-unread empty-list-icon"></span>
                    <p class="mb-0 empty-list-text">{{ 'EMPTY_DASHBOARD_NO_NOTIFICATION' | translate}}</p>
                  </div>
                </li>
                <ng-container *ngIf="processMessageList.length > 0">
                  <li class="list-group-item" *ngFor="let message of processMessageList">
                    <div class="media w-100"
                         #media>
                      <div class="media-body d-flex flex-column">
                        <div class="d-flex flex-row flex-wrap justify-content-between">
                          <p
                            class="message-heading mb-0 d-inline-block text-wrap">{{ message.chatMessage.userPersonName }} </p>
                          <p
                            class="message-date mb-0">{{message.chatMessage.creationTime.toUtcIsoString() | date: 'short' }}</p>
                        </div>
                        <p class="message-text mb-0 d-inline-block text-wrap">{{message.chatMessage.content}}</p>
                        <a class="mb-0 d-inline-block text-wrap id-text cursor-pointer"
                           href="#" onclick="return false"
                           uiSref="Admin.ProcessDetail"
                           [uiParams]="{ id: message.process.id}"
                           title="{{'PROCESS' | translate}}"
                           *ngIf="message.process">{{ message.process.name }}</a>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item">
                    <a class="mb-0 d-inline-block text-wrap cursor-pointer"
                       href="#" onclick="return false"
                       uiSref="Admin.MessageLists"
                       [uiParams]="{'#': 'process'}"
                       title="{{'COMMON_SHOW_ALL' | translate}}"
                    >{{ 'COMMON_SHOW_ALL' | translate }}</a>
                  </li>
                </ng-container>
              </ul>
              <!--list of messages end-->
            </ng-container>

            <ng-container
              *ngIf="rightModel.userRead.hasRight() && rightModel.taskRecordRead.hasRight() && rightModel.taskRecordLogRead.hasRight()">
              <h5 class="mb-0">{{'DASHBOARD_LATEST_ACTIVITY' | translate}}</h5>
              <ul class="list-group mt-1 margin-bottom-30">
                <li class="list-group-item d-flex justify-content-center" *ngIf="logModel.length === 0">
                  <div class="text-center mt-1 mb-1">
                    <span class="icomoon icomoon-recent-activity empty-list-icon"></span>
                    <p class="mb-0 empty-list-text">{{ 'EMPTY_DASHBOARD_NO_LATEST_ACTIVITY' | translate}}</p>
                  </div>
                </li>
                <ng-container *ngIf="logModel.length > 0">
                  <li class="list-group-item" *ngFor="let log of logModel">
                    <div class="media w-100 cursor-pointer" uiSref="Admin.TaskRecordDetail"
                         [uiParams]="{ taskRecordId: log.taskRecordId, taskId: log.taskId }">
                      <app-user-profile-picture
                        class="align-self-center mr-1 float-left"
                        [userPersonName]="log.userName"
                        [profilePicture]="log.profilePicture"
                        [size]="36">
                      </app-user-profile-picture>
                      <div class="media-body">
                        <p class="latest-activity-heading mb-0" [ngSwitch]="log.logType">
                      <span *ngSwitchCase="'CREATE_QUICK'"
                            [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_CREATE_QUICK' | translate: {param_name: log.userName, param_task_name: log.taskRecordName}">
                      </span>
                          <span *ngSwitchCase="'CREATE_REGULAR'"
                                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_CREATE_REGULAR' | translate: {param_name: log.userName, param_task_name: log.taskRecordName}">
                      </span>
                          <span *ngSwitchCase="'CREATE_CLONE'"
                                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_CREATE_CLONE' | translate: {param_name: log.userName, param_task_name: log.taskRecordName}">
                      </span>
                          <span *ngSwitchCase="'CREATE_TRIGGER'"
                                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_CREATE_TRIGGER' | translate: {param_name: log.userName, param_task_name: log.taskRecordName}">
                      </span>
                          <span *ngSwitchCase="'CREATE_PROCESS'"
                                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_CREATE_PROCESS' | translate: {param_name: log.userName, param_task_name: log.taskRecordName}">
                      </span>
                          <span *ngSwitchCase="'CREATE_IMPORT'"
                                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_CREATE_IMPORT' | translate: {param_name: log.userName, param_task_name: log.taskRecordName}">
                      </span>
                          <span *ngSwitchCase="'CREATE_API_IMPORT'"
                                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_CREATE_API_IMPORT' | translate: {param_name: log.userName, param_task_name: log.taskRecordName}">
                      </span>
                          <span *ngSwitchCase="'UPDATE_API_IMPORT'"
                                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_UPDATE_API_IMPORT' | translate: {param_name: log.userName, param_task_name: log.taskRecordName}">
                      </span>
                          <span *ngSwitchCase="'CREATE_DOCUMENT_IMPORT'"
                                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_CREATE_DOCUMENT_IMPORT' | translate: {param_name: log.userName, param_task_name: log.taskRecordName}">
                      </span>
                          <span *ngSwitchCase="'UPDATE_DOCUMENT_IMPORT'"
                                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_UPDATE_DOCUMENT_IMPORT' | translate: {param_name: log.userName, param_task_name: log.taskRecordName}">
                      </span>
                          <span *ngSwitchCase="'STATE_OPEN'"
                                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_STATE_OPEN' | translate: {param_name: log.userName, param_task_name: log.taskRecordName}">
                      </span>
                          <span *ngSwitchCase="'STATE_REOPEN'"
                                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_STATE_REOPEN' | translate: {param_name: log.userName, param_task_name: log.taskRecordName}">
                      </span>
                          <span *ngSwitchCase="'STATE_ARCHIVE'"
                                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_STATE_ARCHIVE' | translate: {param_name: log.userName, param_task_name: log.taskRecordName}">
                      </span>
                          <span *ngSwitchCase="'STATE_RECALL'"
                                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_STATE_RECALL' | translate: {param_name: log.userName, param_task_name: log.taskRecordName}">
                      </span>
                          <span *ngSwitchCase="'STATE_REJECT'"
                                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_STATE_REJECT' | translate: {param_name: log.userName, param_task_name: log.taskRecordName}">
                      </span>
                          <span *ngSwitchCase="'STATE_START'"
                                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_STATE_START' | translate: {param_name: log.userName, param_task_name: log.taskRecordName}">
                      </span>
                          <span *ngSwitchCase="'STATE_PAUSE'"
                                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_STATE_PAUSE' | translate: {param_name: log.userName, param_task_name: log.taskRecordName}">
                      </span>
                          <span *ngSwitchCase="'STATE_RESUME'"
                                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_STATE_RESUME' | translate: {param_name: log.userName, param_task_name: log.taskRecordName}">
                      </span>
                          <span *ngSwitchCase="'STATE_SUBMIT'"
                                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_STATE_SUBMIT' | translate: {param_name: log.userName, param_task_name: log.taskRecordName}">
                      </span>
                          <span *ngSwitchCase="'STATE_UNSUBMIT'"
                                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_STATE_UNSUBMIT' | translate: {param_name: log.userName, param_task_name: log.taskRecordName}">
                      </span>
                          <span *ngSwitchCase="'STATE_FINISH'"
                                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_STATE_FINISH' | translate: {param_name: log.userName, param_task_name: log.taskRecordName}">
                      </span>
                          <span *ngSwitchCase="'STATE_VALIDATE'"
                                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_STATE_VALIDATE' | translate: {param_name: log.userName, param_task_name: log.taskRecordName}">
                      </span>
                          <span *ngSwitchCase="'STATE_INVALIDATE'"
                                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_STATE_INVALIDATE' | translate: {param_name: log.userName, param_task_name: log.taskRecordName}">
                      </span>
                          <span *ngSwitchCase="'STATE_RESTART'"
                                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_STATE_RESTART' | translate: {param_name: log.userName, param_task_name: log.taskRecordName}">
                      </span>
                          <span *ngSwitchCase="'ATTACHMENT_CREATE'"
                                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_ATTACHMENT_CREATE' | translate: {param_name: log.userName, param_task_name: log.taskRecordName}">
                      </span>
                          <span *ngSwitchCase="'ATTACHMENT_DELETE'"
                                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_ATTACHMENT_DELETE' | translate: {param_name: log.userName, param_task_name: log.taskRecordName}">
                      </span>
                          <span *ngSwitchCase="'EXPORT_SUCCEED'"
                                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_EXPORT_SUCCEED' | translate: {param_name: log.userName, param_task_name: log.taskRecordName}">
                      </span>
                          <span *ngSwitchCase="'EXPORT_FAILED'"
                                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_EXPORT_FAILED' | translate: {param_name: log.userName, param_task_name: log.taskRecordName}">
                      </span>
                          <span *ngSwitchCase="'REVERT_SUCCEED'"
                                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_REVERT_SUCCEED' | translate: {param_name: log.userName, param_task_name: log.taskRecordName}">
                      </span>
                          <span *ngSwitchCase="'REVERT_FAILED'"
                                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_REVERT_FAILED' | translate: {param_name: log.userName, param_task_name: log.taskRecordName}">
                      </span>
                          <span *ngSwitchCase="'ASSIGNEE_CHANGE'"
                                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_ASSIGNEE_CHANGE' | translate: {param_name: log.userName, param_task_name: log.taskRecordName}">
                      </span>
                          <span *ngSwitchCase="'ASSIGN_TO_ME'"
                                [innerHTML]="'DASHBOARD_LATEST_ACTIVITY_ASSIGN_TO_ME' | translate: {param_name: log.userName, param_task_name: log.taskRecordName}">
                      </span>
                        </p>
                        <p class="latest-activity-text mb-0">{{log.logTime.fromNow() }}</p>
                      </div>
                    </div>
                  </li>
                </ng-container>

              </ul>
            </ng-container>

            <ng-container *ngIf="rightModel.userRead.hasRight() && rightModel.taskRecordRead.hasRight()">
              <h5 class="mb-0">{{'DASHBOARD_OVERDUE_TASKS' | translate}}</h5>
              <ul class="list-group mt-1 margin-bottom-30">
                <li class="list-group-item d-flex justify-content-center" *ngIf="taskRecordList.length === 0">
                  <div class="text-center mt-1 mb-1">
                    <span class="icomoon icomoon-expired empty-list-icon"></span>
                    <p class="mb-0 empty-list-text">{{ 'EMPTY_DASHBOARD_NO_TASK_GROUP' | translate}}</p>
                  </div>
                </li>
                <ng-container *ngIf="taskRecordList.length > 0">
                  <li class="list-group-item" *ngFor="let taskRecordItem of taskRecordList">
                    <div class="media w-100 cursor-pointer" uiSref="Admin.TaskRecordDetail" #media
                         [uiParams]="{ taskRecordId: taskRecordItem.taskRecord.taskRecordId, taskId: taskRecordItem.taskRecord.taskId }">
                    <span class="align-self-center list-icon mr-1 float-left">
                                    <app-type-icon [icon]="taskRecordItem.icon"
                                                   class="mr-05"></app-type-icon>
                    </span>
                      <div class="media-body">
                        <div class="d-flex flex-row flex-wrap justify-content-between">
                          <p class="overdue-task-heading mb-0 d-inline-block text-truncate"
                             [ngStyle]="{'max-width': media.offsetWidth-113 + 'px'}">{{taskRecordItem.taskRecord.name}}</p>
                          <p class="overdue-task-date mb-0">{{taskRecordItem.taskRecord.deadline.toUtcIsoString() |
                            date:
                            'shortDate'}}</p>
                        </div>
                        <div class="d-flex flex-row flex-wrap justify-content-between">
                          <p class="overdue-task-address mb-0">
                            {{ displayPostalAddress(taskRecordItem.taskRecord) }}
                          </p>
                          <p class="overdue-task-name mb-0">
                            <ng-container *ngIf="taskRecordItem.assigneeModel.assigneeUser">
                              {{taskRecordItem.assigneeModel.assigneeUserName}}
                            </ng-container>
                            <ng-container *ngIf="taskRecordItem.assigneeModel.miscellaneousAssigneeName">
                              {{taskRecordItem.assigneeModel.miscellaneousAssigneeName}}
                            </ng-container>
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                </ng-container>
              </ul>
            </ng-container>

          </div>

        </div>

      </div> <!-- row -->

    </div> <!-- row -->

  </div>

</div>
