/* eslint-disable */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { EmailTemplate, EmailTemplateService } from '../../../lib/email-template/email-template.service';
import { OptionItem, QueryFieldModel, SelectUtils, UiConstants } from '../../../util/core-utils';
import { InputMask } from '../../../util/input-masks';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { RightModel } from '../../../app.rights';
import { OrderType, QueryResult } from '../../../lib/util/services';
import { TranslateService } from '@ngx-translate/core';
import { RightResolver, RightService } from '../../../lib/right.service';
import { combineLatest, Observable } from 'rxjs';
import { Arrays } from '../../../lib/util/arrays';
import { Strings } from '../../../lib/util/strings';
import { Set } from 'immutable';
import { StateName } from '../../../app.state-names';
import { EmptyMessage } from '../../../lib/util/messages';
import { Dates, OffsetDateTime } from '../../../lib/util/dates';
import { EmailTemplateSearch, EmailTemplateSearchService } from '../../../lib/email-template/email-template-search.service';
import { DisabledEnum, DisabledItem } from '../../../util/search-utils';
import { DropdownItemType } from '../../../shared/dropdown/dropdown-item/dropdown-item-type';
/* eslint-enable */

@Component({
  selector: 'app-email-template-list',
  templateUrl: './email-template-list.component.html',
  styleUrls: ['./email-template-list.component.scss']
})
export class EmailTemplateListComponent implements OnInit, OnDestroy {
  EmailTemplate = EmailTemplate;
  SelectUtils = SelectUtils;
  InputMask = InputMask;
  UiConstants = UiConstants;
  DropdownItemType = DropdownItemType;

  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  showSearch: boolean = false;
  rightModel: RightModel = RightModel.empty();
  queryModel: QueryFieldModel<EmailTemplate.OrderField> = new QueryFieldModel(EmailTemplate.OrderField.ID, OrderType.DESC);

  searchResult: EmailTemplateSearch.SearchDataResult;
  searchModel: EmailTemplateSearchModel = new EmailTemplateSearchModel();
  disabledItems: DisabledItem[] = [];

  emailTemplateList: EmailTemplateModel[] = [];

  constructor(private emailTemplateService: EmailTemplateService,
              private translateService: TranslateService,
              private rightService: RightService,
              private emailTemplateSearchService: EmailTemplateSearchService) { }

  ngOnInit() {
    this.loadRightModels();
    this.loadSearch(() => {
      this.showSearch = !this.searchModel.isEmpty();
      this.initDisabledOptions(DisabledEnum.FALSE);
      this.loadList();
    });
    this.initBreadcrumb();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  private loadSearch(completion: () => void) {
    const obs: Observable<SearchLoadResult> = combineLatest(
      this.emailTemplateSearchService.getSearchData({}),
      (storedSearchData: EmailTemplateSearch.SearchDataResult) => {
        const result: SearchLoadResult = {
          storedSearchData: storedSearchData
        };
        return result;
      }
    );
    obs.subscribe(
      (result: SearchLoadResult) => {
        this.searchResult = result.storedSearchData;
        this.postInitSearch(result.storedSearchData, completion);
      }
    );
  }

  private postInitSearch(storedSearchData: EmailTemplateSearch.SearchDataResult, completion: () => void) {
    this.queryModel.itemsPerPage = storedSearchData.searchData.itemsPerPage;
    this.queryModel.currentPage = storedSearchData.searchData.pageNumber;
    this.queryModel.setOrder(storedSearchData.searchData.order);
    this.searchModel.name = storedSearchData.searchData.name;
    this.searchModel.disabled = storedSearchData.searchData.disabled;
    completion()
  }

  private initDisabledOptions(initValue: DisabledEnum) {
    this.disabledItems = [];
    const disabledEnums: DisabledEnum[] = [DisabledEnum.NONE, DisabledEnum.FALSE, DisabledEnum.TRUE];
    Arrays.iterateByIndex(disabledEnums, (key) => {
      const item = new DisabledItem();
      item.id = key;
      this.translateService.get('COMMON_VALUE_DISABLED_ENUM_' + key).subscribe(
        (text: string) => {
          item.text = text;
        }
      );
      this.disabledItems.push(item);
      if (!this.searchModel.disabled) {
        if (key === initValue) {
          this.searchModel.disabled = item;
        }
      }
    });
  }

  loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();
    const disabled: boolean | undefined = !this.searchModel.disabled ||
    this.searchModel.disabled.id === DisabledEnum.NONE ?
      undefined : this.searchModel.disabled.id === DisabledEnum.TRUE;
    this.emailTemplateService.query({
      name: Strings.undefinedOrNonEmpty(this.searchModel.name),
      disabled: disabled,
      orders: Set.of(order),
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined
    }).subscribe((result: QueryResult<EmailTemplate.EmailTemplate>) => {
      this.emailTemplateList = [];
      result.items.forEach((emailTemplate: EmailTemplate.EmailTemplate) => {
        const emailTemplateModel = new EmailTemplateModel();
        emailTemplateModel.id = emailTemplate.id + '';
        emailTemplateModel.externalId = emailTemplate.externalId;
        emailTemplateModel.name = emailTemplate.name;
        emailTemplateModel.creationTime = emailTemplate.creationTime;
        emailTemplateModel.updateTime = emailTemplate.updateTime;
        emailTemplateModel.disabled = emailTemplate.disabled;
        this.emailTemplateList.push(emailTemplateModel);
      });
      this.queryModel.currentPage = requestedPage;
      this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
    });
  }

  onSearchClicked() {
    this.loadList(1);
  }

  onSearchReset() {
    this.emailTemplateSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.showSearch = true;
          this.loadList(1);
        });
      }
    );
  }

  private saveSearch() {
    const request = {
      searchData: {
        itemsPerPage: this.queryModel.itemsPerPage,
        pageNumber: this.queryModel.currentPage,
        order: this.queryModel.getOrder(),
        name: this.searchModel.name,
        disabled: this.searchModel.disabled,
      }
    };
    this.emailTemplateSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  initBreadcrumb() {
    this.translateService.get('EMAIL_TEMPLATE_LIST_HEADER').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  orderBy(field: EmailTemplate.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  setDisabled(event: any, emailTemplate: EmailTemplate.EmailTemplate, disabled: boolean) {
    this.emailTemplateService.setDisabled({
      id: emailTemplate.id,
      disabled: disabled
    }).subscribe(
      (result: EmptyMessage) => {
        this.loadList();
      },
      (error: any) => {
        this.loadList();
      }
    );
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  ngOnDestroy() {
    this.saveSearch();
  }

}

class EmailTemplateModel {
  id: string = '';
  externalId: string = '';
  name: string = '';
  creationTime: OffsetDateTime = Dates.emptyOffsetDateTime();
  updateTime: OffsetDateTime = Dates.emptyOffsetDateTime();
  disabled = false;
}

class EmailTemplateSearchModel {
  name: string;
  disabled?: DisabledItem;

  public isEmpty(): boolean {
    return this.name.length === 0
      && OptionItem.idOrUndefined(this.disabled) === undefined
      ;
  }

  public clear() {
    this.name = '';
    this.disabled = undefined;
  }

  constructor() {
  }

}

interface SearchLoadResult {
  storedSearchData: EmailTemplateSearch.SearchDataResult,
}
