<div class="col-md-6 p-0">
  <div class="card">
    <div class="card-header">
      {{'TASK_SMS_RATING'|translate}}
    </div>
    <div class="card-body">
      <div *ngIf="smsRating">
        <div class="form-group row">
          <label readonly class="col-form-label form-control-label col-md-4 detail-title">
            {{'TASK_RECORD_SMS_RATING_RATING' | translate}}
          </label>
          <label readonly class="col-form-label form-control-label col-md-8 detail-description">
            {{smsRating}}
          </label>
        </div>
      </div>
      <div *ngIf="smsContent">
        <div class="form-group row" *ngFor="let content of smsContent; let first = first">
          <label readonly class="col-form-label form-control-label col-md-4 detail-title">
            {{first ? ('TASK_RECORD_SMS_RATING_CONTENT' | translate) : ''}}
          </label>
          <label readonly class="col-form-label form-control-label col-md-8 detail-description">
            {{content}}
          </label>
        </div>
      </div>
      <div class="p-2 text-center text-muted"
           *ngIf="!smsRating">
        <h6>{{ "TASK_RECORD_NO_SMS_RATING" | translate }}</h6>
      </div>
    </div>
  </div>
</div>
