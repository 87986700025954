<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>

<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="card">

    <div class="card-header">
      {{'MENU_NAVBAR_TOKENS' | translate}}

      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()"
           title="{{'COMMON_SHOW_SEARCH_HINT' | translate}}"><i class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>
    <div class="card-body border-bottom" *ngIf="showSearch">
      <div class="row form-group">
        <div class="col-md-3">
          <label class="search-label">{{'COMMON_USER' | translate}}</label>
          <input type="text" class="form-control"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 placeholder="{{'COMMON_USER' | translate}}"
                 [(ngModel)]="searchModel.userProfilePersonName">
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'COMMON_MOBILE_APPLICATION' | translate}}</label>
          <input type="text" class="form-control"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 placeholder="{{'COMMON_MOBILE_APPLICATION' | translate}}"
                 [(ngModel)]="searchModel.mobileApplicationName">
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'TOKEN_PLATFORM' | translate}}</label>
          <select
            class="form-control"
            title=""
            [(ngModel)]="searchModel.type">
            <option *ngFor="let type of tokenTypeList" [ngValue]="type.id">{{type.text | translate}}</option>
          </select>
        </div>
      </div>
      <div class="col-md-12 d-flex justify-content-md-end align-items-end px-0">
        <div class="btn-group" role="group">
          <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
            {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
          </button>
          <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
            {{'COMMON_BUTTON_SEARCH' | translate}}
          </button>
        </div>
      </div>
    </div><!-- /.card-body-->

    <div class="card-body">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="Token.OrderField.USER_PROFILE_PERSON_NAME"
                                    [orderType]="queryModel.getOrderType(Token.OrderField.USER_PROFILE_PERSON_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_USER' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op [text]="'TOKEN_PLATFORM' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header ">
            <app-table-sorter-no-op [text]="'COMMON_MOBILE_APPLICATION' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-sorter-no-op [text]="'COMMON_LOGIN_TIME' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op [text]="'COMMON_LAST_ACTIVITY_TIME' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-sorter-no-op [text]="'COMMON_BEST_BEFORE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-actions-single">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let token of tokenList | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }">
          <td class="responsive-table-column">{{token.userProfile.personName}}</td>
          <td class="responsive-table-column hidden-sm-down">{{token.type | translate}}</td>
          <td class="responsive-table-column">{{token.mobileApplicationNameApplicationId}}</td>
          <td class="responsive-table-column hidden-lg-down">{{token.createDate | date: 'short'}}</td>
          <td class="responsive-table-column hidden-sm-down">{{token.accessDate | date: 'short'}}</td>
          <td class="responsive-table-column hidden-xs-down">{{token.expiry | date: 'short'}}</td>
          <td class="responsive-table-column">
            <a *ngIf="rightModel.tokenDelete.hasRight()"
               class="icons-table-group cursor-pointer" (click)="openDeleteModal(token)">
              <i class="icomoon icons-table-item icomoon-trash icomoon-disable-table"
                 title="{{'COMMON_BUTTON_DELETE' | translate}}"></i>
            </a>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="Token.OrderField.USER_PROFILE_PERSON_NAME"
                                    [orderType]="queryModel.getOrderType(Token.OrderField.USER_PROFILE_PERSON_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_USER' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op [text]="'TOKEN_PLATFORM' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header ">
            <app-table-sorter-no-op [text]="'COMMON_MOBILE_APPLICATION' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-sorter-no-op [text]="'COMMON_LOGIN_TIME' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op [text]="'COMMON_LAST_ACTIVITY_TIME' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-sorter-no-op [text]="'COMMON_BEST_BEFORE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-actions-single">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        [hideNumberOfItems]="true"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>
  </div>
</div>

<div bsModal [config]="UiConstants.modalConfig" #deleteModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="deleteModalVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'TOKEN_DELETE' | translate}}</h4>
        <button type="button" class="close" (click)="closeDeleteModal()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group col-md-12 mb-0">
          <div class="alert alert-danger">
            <label>{{'TOKEN_DELETE_LABEL' | translate}}</label>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeDeleteModal()">
          {{'COMMON_BUTTON_CANCEL' | translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="deleteToken()">{{'COMMON_BUTTON_DELETE' |
          translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
