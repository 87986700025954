<div class="container-vertical-padding container-horizontal-padding">
  <div class="col-md-12 d-flex justify-content-between" *ngIf="isRemovable">
    <h6 *ngIf="componentState.isEditable()">{{'ORDER_STOCK_ITEM_MODIFY' | translate}}</h6>
    <h6 *ngIf="componentState.isDetailView()">{{'ORDER_STOCK_ITEM_MODIFY' | translate}}</h6>
    <input *ngIf="componentState.isEditable() && !disabled"
      type="button"
           (click)="remove.emit(model.itemId)"
           class="btn btn-secondary"
           value="{{'SHIPPING_DEMAND_ITEM_DELETE' | translate}}">
  </div>
  <form class="form-group" (ngSubmit)="f.form.valid && isItemSizeValid() && areCodesValid() && saveItem()" #f="ngForm" novalidate>
    <!--First row-->
    <div class="row">
      <!--externalId-->
      <div class="col-md-2 col-sm-12 col-xs-12 form-group"
           [ngClass]="{ 'has-danger': (f.submitted && !externalId.valid) || (f.submitted && !uniqueExternalId) || hasError('externalId')}">
        <label class="form-control-label" [class.required-field-label]="componentState.isEditable()">
          {{'SHIPPING_DEMAND_ITEM_EXTERNAL_ID' | translate}}
        </label>
        <div>
          <input class="form-control" type="text"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 placeholder="{{'SHIPPING_DEMAND_ITEM_EXTERNAL_ID' | translate}}"
                 [ngClass]="{ 'form-control-danger': (f.submitted && !externalId.valid) || (f.submitted && !uniqueExternalId) || hasError('externalId')}"
                 (ngModelChange)="checkUniqueExternalId()"
                 [(ngModel)]="m.externalId"
                 #externalId="ngModel"
                 name="externalId"
                 required
                 [disabled]="componentState.isDetailView() || disabled">
          <div class="form-control-feedback"
               *ngIf="f.submitted && !externalId.valid">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
          <div class="form-control-feedback"
               *ngIf="f.submitted && !uniqueExternalId">{{'COMMON_VALIDATION_MESSAGE_UNIQUE' | translate}}</div>
        </div>
      </div>
      <!--end externalId-->
      <!--ProductCode-->
      <div class="col-md-2 col-sm-12 col-xs-12 form-group" *ngIf="isEnabledField(EditableField.ITEM_PRODUCT_CODE)"
           [ngClass]="{ 'has-danger': (f.submitted && !productCode.valid) || hasError('productCode')}">
        <label class="form-control-label">{{'SHIPPING_DEMAND_ITEM_PRODUCT_CODE' | translate}}</label>
        <div>
          <input class="form-control" type="text"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 placeholder="{{'SHIPPING_DEMAND_ITEM_PRODUCT_CODE' | translate}}"
                 [ngClass]="{ 'form-control-danger': (f.submitted && !productCode.valid) || hasError('productCode')}"
                 (ngModelChange)="removeError('productCode')"
                 [(ngModel)]="m.productCode"
                 #productCode="ngModel"
                 name="productCode"
                 [disabled]="componentState.isDetailView() || disabled">
        </div>
      </div>
      <!--end ProductCode-->
      <!--Name-->
      <div class="col-md-6 col-sm-12 col-xs-12 form-group"
           [ngClass]="{ 'has-danger': (f.submitted && !name.valid) || hasError('name')}">
        <label class="form-control-label" [class.required-field-label]="componentState.isEditable()">
          {{'COMMON_NAME' | translate}}
        </label>
        <div>
          <input class="form-control" type="text"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 placeholder="{{'COMMON_NAME' | translate}}"
                 [ngClass]="{ 'form-control-danger': (f.submitted && !name.valid) || hasError('name')}"
                 (ngModelChange)="removeError('name')"
                 [(ngModel)]="m.name"
                 #name="ngModel"
                 name="name"
                 required
                 [disabled]="componentState.isDetailView() || disabled">
          <div class="form-control-feedback"
               *ngIf="f.submitted && !name.valid">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
        </div>
      </div>
      <!--end Name-->
      <!--Category-->
      <div class="col-md-2 col-sm-12 col-xs-12 form-group" *ngIf="isEnabledField(EditableField.ITEM_CATEGORY)"
           [ngClass]="{ 'has-danger': (f.submitted && !category.valid) || hasError('category')}">
        <label class="form-control-label">{{'SHIPPING_DEMAND_ITEM_CATEGORY' | translate}}</label>
        <div>
          <input class="form-control" type="text"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 placeholder="{{'SHIPPING_DEMAND_ITEM_CATEGORY' | translate}}"
                 [ngClass]="{ 'form-control-danger': (f.submitted && !category.valid) || hasError('category')}"
                 (ngModelChange)="removeError('category')"
                 [(ngModel)]="m.category"
                 #category="ngModel"
                 name="category"
                 [disabled]="componentState.isDetailView() || disabled">
        </div>
      </div>
      <!--end Category-->
    </div>
    <!--end First row-->
    <!--Second row-->
    <div class="row">
      <!--Amount-->
      <div class="col-md-2 col-sm-12 col-xs-12 form-group" *ngIf="isEnabledField(EditableField.ITEM_AMOUNT)"
           [ngClass]="{ 'has-danger': (f.submitted && !amount.valid) || hasError('amount')}">
        <label class="form-control-label" [class.required-field-label]="componentState.isEditable()">
          {{'SHIPPING_DEMAND_ITEM_AMOUNT' | translate}}
        </label>
        <div>
          <input class="form-control"
                 type="text"
                 maxlength="7"
                 placeholder="{{'SHIPPING_DEMAND_ITEM_AMOUNT' | translate}}"
                 [textMask]="{mask: InputMask.NATURAL_INTEGER_WITH_THOUSAND_SEPARATOR, guide: false}"
                 [ngClass]="{ 'form-control-danger': (f.submitted && !amount.valid) || hasError('amount')}"
                 (ngModelChange)="removeError('amount')"
                 [(ngModel)]="m.amount.value"
                 #amount="ngModel"
                 name="amount"
                 required
                 [disabled]="componentState.isDetailView() || disabled">
          <div class="form-control-feedback"
               *ngIf="f.submitted && !amount.valid">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
        </div>
      </div>
      <!--end Amount-->
      <!--AmountUnit-->
      <div class="col-md-2 col-sm-12 col-xs-12 form-group" *ngIf="isEnabledField(EditableField.ITEM_AMOUNT)"
           [ngClass]="{ 'has-danger': (f.submitted && !amountUnit.valid) || hasError('amountUnit')}">
        <label class="form-control-label" [class.required-field-label]="componentState.isEditable()">
          {{'SHIPPING_DEMAND_ITEM_UNIT' | translate}}
        </label>
        <div>
          <input class="form-control" type="text"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 placeholder="{{'SHIPPING_DEMAND_ITEM_UNIT' | translate}}"
                 [ngClass]="{ 'form-control-danger': (f.submitted && !amountUnit.valid) || hasError('amountUnit')}"
                 (ngModelChange)="removeError('amountUnit')"
                 [(ngModel)]="m.amount.unit"
                 [matAutocomplete]="itemUnitAuto"
                 #amountUnit="ngModel"
                 name="amountUnit"
                 required
                 (input)="itemUnitChange($event.target.value)"
                 [disabled]="componentState.isDetailView() || disabled">
          <mat-autocomplete #itemUnitAuto="matAutocomplete">
            <mat-option *ngFor="let unitOption of unitOptions | async" [value]="unitOption">
              {{unitOption}}
            </mat-option>
          </mat-autocomplete>
          <div class="form-control-feedback"
               *ngIf="f.submitted && !amountUnit.valid">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
        </div>
      </div>
      <!--end AmountUnit-->
      <!--Weight-->
      <div class="col-md-4 col-sm-12 col-xs-12 form-group" *ngIf="isEnabledField(EditableField.ITEM_WEIGHT_IN_GRAM)"
           [ngClass]="{ 'has-danger': (f.submitted && !weight.valid) || hasError('weight') || isOverweight}">
        <label class="form-control-label">{{'SHIPPING_DEMAND_ITEM_WEIGHT' | translate}}</label>
        <div class="row">
          <div class="col-md-6">
            <input class="form-control"
                   type="text"
                   placeholder="{{'SHIPPING_DEMAND_ITEM_WEIGHT' | translate}}"
                   [textMask]="{mask: InputMask.NATURAL_INTEGER_WITH_THOUSAND_SEPARATOR, guide: false}"
                   [ngClass]="{ 'form-control-danger': (f.submitted && !weight.valid) || hasError('weight') || isOverweight}"
                   (ngModelChange)="removeError('weight')"
                   [(ngModel)]="m.weight"
                   #weight="ngModel"
                   name="weight"
                   [disabled]="componentState.isDetailView() || disabled">
            <div class="form-control-feedback"
                 *ngIf="isOverweight">{{'SHIPPING_DEMAND_ITEM_WEIGHT_MAX' | translate}}</div>
          </div>

          <div class="col-md-6 padding-15-15-for-col-md mb-0">
            <select class="form-control" [compareWith]="SelectUtils.compareObjects"
                    [(ngModel)]="packageWeightUnit"
                    id="weightUnit" name="weightUnit"
                    #weightUnit="ngModel" [ngModelOptions]="{standalone: true}"
                    [disabled]="componentState.isDetailView() || disabled">
              <option *ngFor="let item of selectableWeightUnits" [ngValue]="item">{{item}}</option>
            </select>
          </div>
        </div>
      </div>
      <!--end Weight-->
      <!--PriceAmount-->
      <div class="col-md-2 col-sm-12 col-xs-12 form-group" *ngIf="isEnabledField(EditableField.ITEM_PRICE)"
           [ngClass]="{ 'has-danger': (f.submitted && !priceAmount.valid) || hasError('priceAmount')}">
        <label class="form-control-label">{{'SHIPPING_DEMAND_ITEM_PRICE_AMOUNT' | translate}}</label>
        <div>
          <input class="form-control" type="text"
                 maxlength="21"
                 placeholder="{{'SHIPPING_DEMAND_ITEM_PRICE_AMOUNT' | translate}}"
                 [textMask]="{mask: InputMask.NATURAL_INTEGER_WITH_THOUSAND_SEPARATOR, guide: false}"
                 [ngClass]="{ 'form-control-danger': (f.submitted && !priceAmount.valid) || hasError('priceAmount')}"
                 (ngModelChange)="removeError('priceAmount')"
                 [(ngModel)]="m.price.amount"
                 #priceAmount="ngModel"
                 name="priceAmount"
                 [disabled]="componentState.isDetailView() || disabled">
        </div>
      </div>
      <!--end PriceAmount-->
      <!--PriceCurrency-->
      <div class="col-md-2 col-sm-12 col-xs-12 form-group" *ngIf="isEnabledField(EditableField.ITEM_PRICE)"
           [ngClass]="{ 'has-danger': (f.submitted && !priceCurrency.valid) || hasError('priceCurrency')}">
        <label class="form-control-label" [class.required-field-label]="componentState.isEditable()">
          {{'SHIPPING_DEMAND_ITEM_PRICE_CURRENCY' | translate}}
        </label>
        <div>
          <select class="form-control" [compareWith]="SelectUtils.compareStrings"
                  [(ngModel)]="m.price.currencyCode"
                  [ngClass]="{ 'form-control-danger': (f.submitted && !priceCurrency.valid) || hasError('priceCurrency')}"
                  (ngModelChange)="removeError('priceCurrency')"
                  id="priceCurrency"
                  name="priceCurrency"
                  #priceCurrency="ngModel"
                  [ngModelOptions]="{standalone: true}"
                  required
                  [disabled]="componentState.isDetailView() || disabled">
            <option *ngFor="let item of currencyList" [ngValue]="item">{{item}}</option>
          </select>
        </div>
      </div>
      <!--end PriceCurrency-->
    </div>
    <!--end Second row-->
    <!--Third row-->
    <div class="row">
      <!--Item size width-->
      <div class="col-md-4 col-sm-12 col-xs-12 form-group" *ngIf="isEnabledField(EditableField.ITEM_ITEM_SIZE)"
           [ngClass]="{ 'has-danger': f.submitted && !isItemSizeValid()}">
        <label class="form-control-label" [class.required-field-label]="componentState.isEditable() && !isItemSizeValid()">
          {{'SHIPPING_DEMAND_ITEM_WIDTH' | translate}}
        </label>
        <div class="row">
          <div class="col-md-6">
            <input class="form-control"
                   type="number"
                   placeholder="{{'SHIPPING_DEMAND_ITEM_WIDTH' | translate}}"
                   [ngClass]="{ 'form-control-danger': f.submitted && !isItemSizeValid()}"
                   [(ngModel)]="m.itemSize.width.amount"
                   [ngModelOptions]="{standalone: true}"
                   [disabled]="componentState.isDetailView() || disabled">
            <div class="form-control-feedback"
                 *ngIf="f.submitted && !isItemSizeValid()">{{'SHIPPING_DEMAND_ITEM_SIZE_REQUIRED' | translate}}</div>
          </div>

          <div class="col-md-6 padding-15-15-for-col-md mb-0">
            <select class="form-control"
                    [(ngModel)]="m.itemSize.width.unit"
                    [ngModelOptions]="{standalone: true}"
                    [disabled]="componentState.isDetailView() || disabled">
              <option *ngFor="let item of selectableLengthUnits" [ngValue]="item">{{item}}</option>
            </select>
          </div>
        </div>
      </div>
      <!--end Item size width-->
      <!--Item size height-->
      <div class="col-md-4 col-sm-12 col-xs-12 form-group" *ngIf="isEnabledField(EditableField.ITEM_ITEM_SIZE)"
           [ngClass]="{ 'has-danger': f.submitted && !isItemSizeValid()}">
        <label class="form-control-label" [class.required-field-label]="componentState.isEditable() && !isItemSizeValid()">
          {{'SHIPPING_DEMAND_ITEM_HEIGHT' | translate}}
        </label>
        <div class="row">
          <div class="col-md-6">
            <input class="form-control"
                   type="number"
                   placeholder="{{'SHIPPING_DEMAND_ITEM_HEIGHT' | translate}}"
                   [ngClass]="{ 'form-control-danger': f.submitted && !isItemSizeValid()}"
                   [(ngModel)]="m.itemSize.height.amount"
                   [ngModelOptions]="{standalone: true}"
                   [disabled]="componentState.isDetailView() || disabled">
          </div>

          <div class="col-md-6 padding-15-15-for-col-md mb-0">
            <select class="form-control"
                    [(ngModel)]="m.itemSize.height.unit"
                    [ngModelOptions]="{standalone: true}"
                    [disabled]="componentState.isDetailView() || disabled">
              <option *ngFor="let item of selectableLengthUnits" [ngValue]="item">{{item}}</option>
            </select>
          </div>
        </div>
      </div>
      <!--end Item size height-->
      <!--Item size depth-->
      <div class="col-md-4 col-sm-12 col-xs-12 form-group" *ngIf="isEnabledField(EditableField.ITEM_ITEM_SIZE)"
           [ngClass]="{ 'has-danger': f.submitted && !isItemSizeValid()}">
        <label class="form-control-label" [class.required-field-label]="componentState.isEditable() && !isItemSizeValid()">
          {{'SHIPPING_DEMAND_ITEM_DEPTH' | translate}}
        </label>
        <div class="row">
          <div class="col-md-6">
            <input class="form-control"
                   type="number"
                   placeholder="{{'SHIPPING_DEMAND_ITEM_DEPTH' | translate}}"
                   [ngClass]="{ 'form-control-danger': f.submitted && !isItemSizeValid()}"
                   [(ngModel)]="m.itemSize.depth.amount"
                   [ngModelOptions]="{standalone: true}"
                   [disabled]="componentState.isDetailView() || disabled">
          </div>

          <div class="col-md-6 padding-15-15-for-col-md mb-0">
            <select class="form-control"
                    [(ngModel)]="m.itemSize.depth.unit"
                    [ngModelOptions]="{standalone: true}"
                    [disabled]="componentState.isDetailView() || disabled">
              <option *ngFor="let item of selectableLengthUnits" [ngValue]="item">{{item}}</option>
            </select>
          </div>
        </div>
      </div>
      <!--end Item size depth-->
    </div>
    <!--end Third row-->
    <!--Fourth row-->
    <div class="row">
      <!--boxCode-->
      <div class="col-md-2 col-sm-12 col-xs-12 form-group" *ngIf="isEnabledField(EditableField.ITEM_BOX_CODE)"
           [ngClass]="{ 'has-danger': (f.submitted && !boxCode.valid) || hasError('boxCode')}">
        <label class="form-control-label">{{'SHIPPING_DEMAND_ITEM_PRICE_BOX_CODE' | translate}}</label>
        <div>
          <input class="form-control" type="text"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 placeholder="{{'SHIPPING_DEMAND_ITEM_PRICE_BOX_CODE' | translate}}"
                 [ngClass]="{ 'form-control-danger': (f.submitted && !boxCode.valid) || hasError('boxCode')}"
                 (ngModelChange)="removeError('boxCode')"
                 [(ngModel)]="m.boxCode"
                 #boxCode="ngModel"
                 name="boxCode"
                 [disabled]="componentState.isDetailView()">
        </div>
      </div>
      <!--end boxCode-->
      <!--labelCode-->
      <div class="col-md-2 col-sm-12 col-xs-12 form-group" *ngIf="isEnabledField(EditableField.ITEM_LABEL_CODE)"
           [ngClass]="{ 'has-danger': (f.submitted && !labelCode.valid) || hasError('labelCode')}">
        <label class="form-control-label" [class.required-field-label]="componentState.isEditable() && m.boxCode.length > 0">
          {{'SHIPPING_DEMAND_ITEM_LABEL_VALUE' | translate}}
        </label>
        <div>
          <input class="form-control" type="text"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 placeholder="{{'SHIPPING_DEMAND_ITEM_LABEL_VALUE' | translate}}"
                 [ngClass]="{ 'form-control-danger': (f.submitted && !labelCode.valid) || hasError('labelCode')}"
                 (ngModelChange)="removeError('labelCode')"
                 [(ngModel)]="m.labelCode"
                 #labelCode="ngModel"
                 name="labelCode"
                 [disabled]="componentState.isDetailView()"
                 [required]="m.boxCode.length > 0">
          <div class="form-control-feedback"
               *ngIf="f.submitted && !labelCode.valid">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
        </div>
      </div>
      <!--end labelCode-->
      <!--comment-->
      <div class="col-md-8 col-sm-12 col-xs-12 form-group" *ngIf="isEnabledField(EditableField.ITEM_COMMENT)"
           [ngClass]="{ 'has-danger': (f.submitted && !comment.valid) || hasError('comment')}">
        <label class="form-control-label">{{'COMMON_COMMENT' | translate}}</label>
        <div>
          <input class="form-control" type="text"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 placeholder="{{'COMMON_COMMENT' | translate}}"
                 [ngClass]="{ 'form-control-danger': (f.submitted && !comment.valid) || hasError('comment')}"
                 (ngModelChange)="removeError('comment')"
                 [(ngModel)]="m.comment"
                 #comment="ngModel"
                 name="comment"
                 [disabled]="componentState.isDetailView()">
        </div>
      </div>
      <!--end comment-->
    </div>
    <!--end Fourth row-->
    <div class="col-md-12" *ngIf="componentState.isEditable()">
      <div class="pull-right container-vertical-padding">
        <input type="button" class="btn btn-secondary-green cursor-pointer mr-1" (click)="cancel.emit()"
               value="{{'COMMON_BUTTON_CANCEL'|translate}}"/>
        <input type="submit" class="btn btn-primary-green cursor-pointer" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
      </div>
    </div>
  </form>
</div>
