<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div class="breadcrumb-menu d-flex">
    <div class="breadcrumb-button-icon-container cursor-pointer" *ngIf="canActivate()" (click)="activate()">
      <a class="btn-setting" title="{{'COMMON_BUTTON_ENABLE' | translate}}">
        <i class="icomoon icomoon-enable icomoon-enable-table"></i> {{'COMMON_BUTTON_ENABLE' | translate}}
      </a>
    </div>
    <div class="breadcrumb-button-icon-container cursor-pointer" *ngIf="canInactivate()" (click)="inactivate()">
      <a class="btn-setting" title="{{'API_KEY_INACTIVATE' | translate}}">
        <i class="icomoon icomoon-disable icomoon-disable-table"></i> {{'API_KEY_INACTIVATE' | translate}}
      </a>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-vertical-padding animated fadeIn">
  <div class="col-xs-12 col-sm-12 col-md-8">
    <div class="card">

      <div class="card-header">
        {{'API_KEY_HEADER' | translate}}
      </div>

      <div class="card-body">

        <div class="row">
          <div class="form-group col-md-4 mb-0">
            <label readonly class="col-form-label form-control-label detail-title">{{'API_KEY_HEADER' | translate}}</label>
          </div>
          <div class="form-group col-md-8 mb-0" *ngIf="inState('ACTIVE') || inState('INACTIVE')">
            <label readonly class="col-form-label form-control-label detail-description">{{getToken()}}</label>
          </div>
          <div class="form-group col-md-8 mb-0" *ngIf="inState('NONE')">
            <label readonly class="col-form-label form-control-label detail-description">{{'API_KEY_NO_KEY_YET' | translate}}</label>
          </div>
        </div>

        <div class="pull-right" *ngIf="canDisable()" >
          <input type="button"
                 (click)="regenerate()" class="btn btn-secondary"
                 value="{{'API_KEY_REGENERATE' | translate}}">
        </div>

      </div> <!--card-body-->

    </div>
  </div>
</div>

<!--Token Modal-->
<div bsModal [config]="UiConstants.modalConfig" #tokenDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="tokenDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'COMMON_BUTTON_DETAILS' | translate}}</h4>
        <button type="button" class="close" (click)="closeTokenDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="form-group row">
        <div class="col-md-12" >
          <label class="form-control detail-description"
                 [ngStyle]="{'word-wrap': 'break-word'}">{{ tokenText }}</label>
        </div>
        <!-- /.col-md-8 -->
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="copyMessage()" >{{'API_KEY_COPY_TO_CLIPBOARD' |translate}}</button>
        <button type="button" class="btn btn-secondary" (click)="closeTokenDialog()">
          {{'COMMON_BUTTON_CLOSE' | translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
<!--/Token-->
