/* eslint-disable */
import { Injectable } from '@angular/core';
import { OrderField } from '../query/orderfields';
import { MultiselectOptionItem, OrderFieldModel, OrderFunctionResolver } from '../../util/core-utils';
import { NewBaseSearch, NewBaseSearchService } from '../new-base.search-service';
import { OrderType, Services } from '../util/services';
import { SearchUtils } from '../../util/search-utils';
import { LoggedInUserStorage } from '../util/storages';
import { Process } from './process.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Models } from '../../util/model-utils';
import { WorkflowMultiselectOptionItem } from '../workflow/workflow-multiselect.provider';

/* eslint-enable */

@Injectable()
export class ProcessSearchService extends NewBaseSearchService<OrderField.Process> {

  protected createStorage(): ProcessSearch.Storage {
    return new ProcessSearch.Storage();
  }

}

export namespace ProcessSearch {

  export class Model extends SearchUtils.SearchModel {

    public static defaultSelectedState = {id: null, stringKey: 'COMMON_VALUE_UNSELECTED'};

    showSearch: boolean = false;

    id?: number;
    externalId: string;
    name: string;
    state: StateFilterOption;
    startTimeFrom: NgbDateStruct | null;
    startTimeTo: NgbDateStruct | null;
    finishTimeFrom: NgbDateStruct | null;
    finishTimeTo: NgbDateStruct | null;
    creationTimeFrom: NgbDateStruct | null;
    creationTimeTo: NgbDateStruct | null;
    updateTimeFrom: NgbDateStruct | null;
    updateTimeTo: NgbDateStruct | null;
    creatorUser: MultiselectOptionItem<number>[];
    lastModifiedUser: MultiselectOptionItem<number>[];
    workflow: WorkflowMultiselectOptionItem[];

    constructor() {
      super();
      this.reset();
    }

    reset() {
      this.id = undefined;
      this.externalId = '';
      this.name = '';
      this.state = Model.defaultSelectedState;
      this.startTimeFrom = null;
      this.startTimeTo = null;
      this.finishTimeFrom = null;
      this.finishTimeTo = null;
      this.creationTimeFrom = null;
      this.creationTimeTo = null;
      this.updateTimeFrom = null;
      this.updateTimeTo = null;
      this.creatorUser = [];
      this.lastModifiedUser = [];
      this.workflow = [];
    }

    isEmpty(): boolean {
      return this.id === undefined &&
        this.externalId === '' &&
        this.name === '' &&
        this.state.id === null &&
        this.startTimeFrom === null &&
        this.startTimeTo === null &&
        this.finishTimeFrom === null &&
        this.finishTimeTo === null &&
        this.creationTimeFrom === null &&
        this.creationTimeTo === null &&
        this.updateTimeFrom === null &&
        this.updateTimeTo === null &&
        this.creatorUser.length === 0 &&
        this.lastModifiedUser.length === 0 &&
        this.workflow.length === 0
        ;
    }

    get creatorUserIds(): number[] {
      return this.creatorUser.map(u => u.id);
    }

    get lastModifiedUserIds(): number[] {
      return this.lastModifiedUser.map(u => u.id);
    }

    get workflowBaseIds(): number[] {
      return this.workflow.map(w => w.baseId);
    }
  }

  export namespace OrderFunctions {
    export const ID = (f: OrderField.Process) => f.id;
    export const NAME = (f: OrderField.Process) => f.name;
    export const EXTERNAL_ID = (f: OrderField.Process) => f.externalId;
    export const CREATION_TIME = (f: OrderField.Process) => f.creationTime;
    export const UPDATE_TIME = (f: OrderField.Process) => f.updateTime;
    export const START_TIME = (f: OrderField.Process) => f.startTime;
    export const FINISH_TIME = (f: OrderField.Process) => f.finishTime;
    export const DEADLINE = (f: OrderField.Process) => f.deadline;

    export const VALUES = OrderFunctionResolver.builder<OrderField.Process>()
      .add(ID, 'id')
      .add(NAME, 'name')
      .add(EXTERNAL_ID, 'external_id')
      .add(CREATION_TIME, 'creation_time')
      .add(UPDATE_TIME, 'update_time')
      .add(START_TIME, 'start_time')
      .add(FINISH_TIME, 'finish_time')
      .add(DEADLINE, 'deadline')
      .build();
  }

  export interface StateFilterOption {
    id: Process.ProcessState | null;
    stringKey: string;
  }

  export interface SearchDataResult {
    searchData: SearchData;
  }

  export interface SearchData extends NewBaseSearch.SearchData<OrderField.Process> {
    searchModel: Model;
  }

  interface StoredSearchRawData extends NewBaseSearch.StoredSearchRawData<OrderField.Process> {
    id?: number;
    externalId: string;
    name: string;
    state: StateFilterOption;
    startTimeFrom: string;
    startTimeTo: string;
    finishTimeFrom: string;
    finishTimeTo: string;
    creationTimeFrom: string;
    creationTimeTo: string;
    updateTimeFrom: string;
    updateTimeTo: string;
    creatorUser: MultiselectOptionItem<number>[];
    lastModifiedUser: MultiselectOptionItem<number>[];
    workflow: WorkflowMultiselectOptionItem[];
  }

  export class Storage extends NewBaseSearch.Storage<OrderField.Process> {

    constructor() {
      const defaultSearchData: SearchData = {
        queryModel: new OrderFieldModel(OrderFunctions.ID, OrderType.DESC),
        searchModel: new Model(),
      };
      /**
       * Increment this version if you change the SearchData interface.
       */
      const versionNumber: number = 3;
      super(defaultSearchData,
        versionNumber);
    }

    protected generateKey(): string {
      return 'UserId(' + LoggedInUserStorage.getInstance().getUserId() + ')-Search-Process';
    }

    protected fromRaw(data: StoredSearchRawData): NewBaseSearch.SearchDataResult<OrderField.Process> {
      const searchModel = new Model();
      searchModel.id = data.id;
      searchModel.externalId = data.externalId;
      searchModel.name = data.name;
      searchModel.state = data.state;
      searchModel.creationTimeFrom = Models.localDateToNgbDate(Services.toLocalDate(data.creationTimeFrom));
      searchModel.creationTimeTo = Models.localDateToNgbDate(Services.toLocalDate(data.creationTimeTo));
      searchModel.updateTimeFrom = Models.localDateToNgbDate(Services.toLocalDate(data.updateTimeFrom));
      searchModel.updateTimeTo = Models.localDateToNgbDate(Services.toLocalDate(data.updateTimeTo));
      searchModel.startTimeFrom = Models.localDateToNgbDate(Services.toLocalDate(data.startTimeFrom));
      searchModel.startTimeTo = Models.localDateToNgbDate(Services.toLocalDate(data.startTimeTo));
      searchModel.finishTimeFrom = Models.localDateToNgbDate(Services.toLocalDate(data.finishTimeFrom));
      searchModel.finishTimeTo = Models.localDateToNgbDate(Services.toLocalDate(data.finishTimeTo));
      searchModel.creatorUser = data.creatorUser;
      searchModel.lastModifiedUser = data.lastModifiedUser;
      searchModel.workflow = data.workflow;
      const searchData: SearchData = {
        queryModel: OrderFieldModel.create<OrderField.Process>(OrderFunctions.VALUES, data.order, data.queryModel),
        searchModel: searchModel,
      };
      return {
        searchData: searchData
      };
    }

    protected toRaw(data: SearchData): StoredSearchRawData {
      return {
        version: this.VERSION,
        queryModel: data.queryModel,
        order: data.queryModel.createOrder(OrderFunctions.VALUES),
        id: data.searchModel.id,
        externalId: data.searchModel.externalId,
        name: data.searchModel.name,
        state: data.searchModel.state,
        creationTimeFrom: Services.localDateToString(Models.ngbDateToLocalDate(data.searchModel.creationTimeFrom))!,
        creationTimeTo: Services.localDateToString(Models.ngbDateToLocalDate(data.searchModel.creationTimeTo))!,
        updateTimeFrom: Services.localDateToString(Models.ngbDateToLocalDate(data.searchModel.updateTimeFrom))!,
        updateTimeTo: Services.localDateToString(Models.ngbDateToLocalDate(data.searchModel.updateTimeTo))!,
        startTimeFrom: Services.localDateToString(Models.ngbDateToLocalDate(data.searchModel.startTimeFrom))!,
        startTimeTo: Services.localDateToString(Models.ngbDateToLocalDate(data.searchModel.startTimeTo))!,
        finishTimeFrom: Services.localDateToString(Models.ngbDateToLocalDate(data.searchModel.finishTimeFrom))!,
        finishTimeTo: Services.localDateToString(Models.ngbDateToLocalDate(data.searchModel.finishTimeTo))!,
        creatorUser: data.searchModel.creatorUser,
        lastModifiedUser: data.searchModel.lastModifiedUser,
        workflow: data.searchModel.workflow,
      };
    }

  }

}
