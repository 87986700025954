/* eslint-disable */
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { MultiselectOptionItem, OptionItem } from './core-utils';
import { Order } from '../lib/order/order.service';
import OrderState = Order.OrderState;
/* eslint-enable */

export class OrderSearchModel {

  id: string = '';
  externalId: string = '';
  logicState: MultiselectOptionItem<Order.OrderLogicState>[] = [];
  ownerCompanyName: string = '';
  shipmentTrackingNumber: string = '';
  shippingState: string = '';
  weightInGramFrom: string = '';
  weightInGramTo: string = '';
  cashOnDeliveryPriceFrom: string = '';
  cashOnDeliveryPriceTo: string = '';
  recipientName: string = '';
  recipientAddress: string = '';
  submitTimeFrom: NgbDateStruct | null = null;
  submitTimeTo: NgbDateStruct | null = null;
  returnTimeFrom: NgbDateStruct | null = null;
  returnTimeTo: NgbDateStruct | null = null;
  processDeadlineFrom: NgbDateStruct | null = null;
  processDeadlineTo: NgbDateStruct | null = null;
  minimumIdenticalItemCount: string = '';
  returnReasonEmpty?: boolean = undefined;

  public isEmpty(): boolean {
    return this.id.length === 0
      && this.externalId.length === 0
      && this.logicState.length === 0
      && this.ownerCompanyName.length === 0
      && this.shipmentTrackingNumber.length === 0
      && this.shippingState.length === 0
      && this.weightInGramFrom.length === 0
      && this.weightInGramTo.length === 0
      && this.cashOnDeliveryPriceFrom.length === 0
      && this.cashOnDeliveryPriceTo.length === 0
      && this.recipientName.length === 0
      && this.recipientAddress.length === 0
      && this.submitTimeFrom === null
      && this.submitTimeTo === null
      && this.returnTimeFrom === null
      && this.returnTimeTo === null
      && this.processDeadlineFrom === null
      && this.processDeadlineTo === null
      && this.minimumIdenticalItemCount.length === 0
      && this.returnReasonEmpty === undefined
      ;
  }

}

export interface OrderDisplayStateListObject {
  state: OrderDisplayStateObject;
  beforeStates: OrderDisplayStateObject[];
  afterStates: OrderDisplayStateObject[];
}

export class OrderDisplayStateObject {

  public static readonly DRAFT: OrderDisplayStateObject = {
    state: 'DRAFT',
    stringKey: 'ORDER_STATE_DRAFT',
    style: 'order-state-style-normal',
    icon: 'icomoon-order-state-draft'
  };
  public static readonly SUBMITTED: OrderDisplayStateObject = {
    state: 'SUBMITTED',
    stringKey: 'ORDER_STATE_SUBMITTED',
    style: 'order-state-style-normal',
    icon: 'icomoon-order-state-submitted'
  };
  public static readonly UNDER_PROCESSING: OrderDisplayStateObject = {
    state: 'UNDER_PROCESSING',
    stringKey: 'ORDER_STATE_UNDER_PROCESSING',
    style: 'order-state-style-normal',
    icon: 'icomoon-order-state-under-processing'
  };
  public static readonly PROCESSED: OrderDisplayStateObject = {
    state: 'PROCESSED',
    stringKey: 'ORDER_STATE_PROCESSED',
    style: 'order-state-style-normal',
    icon: 'icomoon-order-state-processed'
  };
  public static readonly DELIVERY_IN_PROGRESS: OrderDisplayStateObject = {
    state: 'DELIVERY_IN_PROGRESS',
    stringKey: 'ORDER_FINAL_STATE_DELIVERY_IN_PROGRESS',
    style: 'order-state-style-normal',
    icon: 'icomoon-order-state-under-delivery'
  };
  public static readonly DELIVERED: OrderDisplayStateObject = {
    state: 'DELIVERED',
    stringKey: 'ORDER_FINAL_STATE_DELIVERED',
    style: 'order-state-style-green',
    icon: 'icomoon-order-state-delivered'
  };
  public static readonly DELIVERY_FAILED: OrderDisplayStateObject = {
    state: 'DELIVERY_FAILED',
    stringKey: 'ORDER_FINAL_STATE_DELIVERY_FAILED',
    style: 'order-state-style-normal',
    icon: 'icomoon-order-state-delivery-failed'
  };
  public static readonly RETURNED: OrderDisplayStateObject = {
    state: 'RETURNED',
    stringKey: 'ORDER_FINAL_STATE_RETURNED',
    style: 'order-state-style-normal',
    icon: 'icomoon-order-state-returned-alt'
  };
  public static readonly SENT_BACK: OrderDisplayStateObject = {
    state: 'SENT_BACK',
    stringKey: 'ORDER_STATE_SENT_BACK',
    style: 'order-state-style-blue',
    icon: 'icomoon-order-state-sent-back'
  };
  public static readonly CANCELED: OrderDisplayStateObject = {
    state: 'CANCELED',
    stringKey: 'ORDER_STATE_CANCELED',
    style: 'order-state-style-red',
    icon: 'icomoon-order-state-canceled'
  };
  public static readonly PROCESS_FAILED: OrderDisplayStateObject = {
    state: 'PROCESS_FAILED',
    stringKey: 'ORDER_STATE_PROCESS_FAILED',
    style: 'order-state-style-red',
    icon: 'icomoon-order-state-process-failed'
  };

  state: Order.OrderLogicState;
  stringKey: string;
  style: string;
  icon: string;

  public static values(): OrderDisplayStateObject[] {
    return [
      OrderDisplayStateObject.DRAFT,
      OrderDisplayStateObject.SUBMITTED,
      OrderDisplayStateObject.UNDER_PROCESSING,
      OrderDisplayStateObject.PROCESSED,
      OrderDisplayStateObject.DELIVERY_IN_PROGRESS,
      OrderDisplayStateObject.DELIVERED,
      OrderDisplayStateObject.DELIVERY_FAILED,
      OrderDisplayStateObject.RETURNED,
      OrderDisplayStateObject.SENT_BACK,
      OrderDisplayStateObject.CANCELED,
      OrderDisplayStateObject.PROCESS_FAILED,
    ];
  }

  public static multiselectValues(): MultiselectOptionItem<Order.OrderLogicState>[] {
    return this.values().map(value => this.orderDisplayStateObjectToMultiselectOptionItem(value));
  }

  private static orderDisplayStateObjectToMultiselectOptionItem(o: OrderDisplayStateObject): MultiselectOptionItem<Order.OrderLogicState> {
    return {
      id: o.state,
      itemName: o.stringKey
    }
  }

  public static resolve(state: Order.OrderLogicState): OrderDisplayStateObject {
    return OrderDisplayStateObject.values().find(o => o.state === state)!;
  }
}

export const orderDisplayStateListObjects: OrderDisplayStateListObject[] = [
  {
    state: OrderDisplayStateObject.SUBMITTED,
    beforeStates: [],
    afterStates: [
      OrderDisplayStateObject.UNDER_PROCESSING,
      OrderDisplayStateObject.PROCESSED,
      OrderDisplayStateObject.DELIVERY_IN_PROGRESS,
      OrderDisplayStateObject.DELIVERED,
    ]
  },
  {
    state: OrderDisplayStateObject.UNDER_PROCESSING,
    beforeStates: [
      OrderDisplayStateObject.SUBMITTED,
    ],
    afterStates: [
      OrderDisplayStateObject.PROCESSED,
      OrderDisplayStateObject.DELIVERY_IN_PROGRESS,
      OrderDisplayStateObject.DELIVERED,
    ]
  },
  {
    state: OrderDisplayStateObject.PROCESSED,
    beforeStates: [
      OrderDisplayStateObject.SUBMITTED,
      OrderDisplayStateObject.UNDER_PROCESSING,
    ],
    afterStates: [
      OrderDisplayStateObject.DELIVERY_IN_PROGRESS,
      OrderDisplayStateObject.DELIVERED,
    ]
  },
  {
    state: OrderDisplayStateObject.DELIVERY_IN_PROGRESS,
    beforeStates: [
      OrderDisplayStateObject.SUBMITTED,
      OrderDisplayStateObject.UNDER_PROCESSING,
      OrderDisplayStateObject.PROCESSED,
    ],
    afterStates: [
      OrderDisplayStateObject.DELIVERED,
    ]
  },
  {
    state: OrderDisplayStateObject.DELIVERED,
    beforeStates: [
      OrderDisplayStateObject.SUBMITTED,
      OrderDisplayStateObject.UNDER_PROCESSING,
      OrderDisplayStateObject.PROCESSED,
      OrderDisplayStateObject.DELIVERY_IN_PROGRESS,
    ],
    afterStates: [
    ]
  },
  {
    state: OrderDisplayStateObject.DELIVERY_FAILED,
    beforeStates: [
      OrderDisplayStateObject.SUBMITTED,
      OrderDisplayStateObject.UNDER_PROCESSING,
      OrderDisplayStateObject.PROCESSED,
      OrderDisplayStateObject.DELIVERY_IN_PROGRESS,
    ],
    afterStates: [
      OrderDisplayStateObject.RETURNED,
      OrderDisplayStateObject.SENT_BACK,
    ]
  },
  {
    state: OrderDisplayStateObject.RETURNED,
    beforeStates: [
      OrderDisplayStateObject.SUBMITTED,
      OrderDisplayStateObject.UNDER_PROCESSING,
      OrderDisplayStateObject.PROCESSED,
      OrderDisplayStateObject.DELIVERY_IN_PROGRESS,
      OrderDisplayStateObject.DELIVERY_FAILED,
    ],
    afterStates: [
      OrderDisplayStateObject.SENT_BACK,
    ]
  },
  {
    state: OrderDisplayStateObject.SENT_BACK,
    beforeStates: [
      OrderDisplayStateObject.SUBMITTED,
      OrderDisplayStateObject.UNDER_PROCESSING,
      OrderDisplayStateObject.PROCESSED,
      OrderDisplayStateObject.DELIVERY_IN_PROGRESS,
      OrderDisplayStateObject.DELIVERY_FAILED,
      OrderDisplayStateObject.RETURNED,
    ],
    afterStates: [
    ]
  },
  {
    state: OrderDisplayStateObject.CANCELED,
    beforeStates: [
      OrderDisplayStateObject.SUBMITTED,
      OrderDisplayStateObject.UNDER_PROCESSING,
    ],
    afterStates: [
    ]
  },
  {
    state: OrderDisplayStateObject.PROCESS_FAILED,
    beforeStates: [
      OrderDisplayStateObject.SUBMITTED,
      OrderDisplayStateObject.UNDER_PROCESSING,
    ],
    afterStates: [
    ]
  },
];
