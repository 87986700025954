import { DqlQuery, Query } from '../field';

export class InvoiceSpending {

  readonly id: Query.OrderField;
  readonly spendingNumber: Query.OrderField;
  readonly eventName: Query.OrderField;
  readonly creationTime: Query.OrderField;
  readonly amount: Query.OrderField;

  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this.id = new DqlQuery.OrderField(prefix + 'id');
    this.spendingNumber = new DqlQuery.OrderField(prefix + 'spending_number');
    this.eventName = new DqlQuery.OrderField(prefix + 'event_name');
    this.creationTime = new DqlQuery.OrderField(prefix + 'creation_time');
    this.amount = new DqlQuery.OrderField(prefix + 'amount');
  }

}
