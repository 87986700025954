/* eslint-disable */
import { Map } from 'immutable';
import { ProjectRecord } from './project-record.service';
import { DqlModel } from '../../dql/dql.model';

/* eslint-enable */

export class ProjectRecordDqlFieldTextProvider {
  public static getHelper(): DqlModel.DqlStaticFieldTextHelper {
    const fields: Map<string, DqlModel.DqlFieldTexts> = Map.of(
      'name', {stringKey: 'COMMON_NAME'},
      'id', {stringKey: 'COMMON_TABLE_HEADER_ID'},
      'parent_id', {stringKey: 'DQL_PARENT_ID'},
      'disabled', {stringKey: 'COMMON_TABLE_HEADER_DISABLED'},
      'external_id', {stringKey: 'COMMON_EXTERNAL_ID'},
      'creation_time', {stringKey: 'COMMON_TABLE_HEADER_CREATION_TIME'},
      'update_time', {stringKey: 'COMMON_TABLE_HEADER_UPDATE_TIME'},
      'start_date', {stringKey: 'PROJECT_START_DATE'},
      'end_date', {stringKey: 'PROJECT_END_DATE'},
      'amount', {stringKey: 'PROJECT_BUDGET'},
      'billing_start_date', {stringKey: 'PROJECT_BILLING_START_DATE'},
      'billing_end_date', {stringKey: 'PROJECT_BILLING_END_DATE'},
      'billing_period_in_months', {stringKey: 'PROJECT_BILLING_PERIOD_IN_MONTHS'},
      'billing_type', {
        stringKey: 'COMMON_TYPE',
        enumType: ProjectRecord.billingTypes.map(t => {
          return {id: t.type, stringKey: t.stringKey};
        })
      },
      'external_id', {stringKey: 'COMMON_EXTERNAL_ID'},
      'assignee_user_id', {stringKey: 'COMMON_USER'},
      'postal_address', {stringKey: 'COMMON_LOCATION_POSTAL_ADDRESS'},
    );
    return new DqlModel.DqlStaticFieldTextHelper(fields);
  }
}
