/* eslint-disable */
import { Injectable } from '@angular/core';
import { ApplicationFileResource, ApplicationFileResourceService } from './application-file-resource.service';
import { Observable, Observer } from 'rxjs';
import { Order, PagingRequest, QueryResult, ResourceQueryResult, Services } from '../util/services';
import { DownloadedFile } from '../util/downloaded-files';
import { OffsetDateTime } from '../util/dates';
import { List, Map, Set } from 'immutable';
import { EmptyMessage } from '../util/messages';
/* eslint-enable */

@Injectable()
export class ApplicationFileService implements ApplicationFile.Service {

  constructor(private resourceService: ApplicationFileResourceService) {
  }

  query(request: ApplicationFile.QueryRequest): Observable<QueryResult<ApplicationFile.ApplicationFile>> {
    return Observable.create((observer: Observer<QueryResult<ApplicationFile.ApplicationFile>>) => {
      const resourceRequest: ApplicationFileResource.QueryRequest = {
        code: request.code,
        language_code: request.languageCode,
        content_type: request.contentType,
        order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
      };
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<ApplicationFileResource.ApplicationFile>) => {
          observer.next({
            items: this.toPublicList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(/*this.translateError(*/error/*)*/);
        },
        () => {
          observer.complete();
        });
    });
  }

  get(request: ApplicationFile.GetRequest): Observable<ApplicationFile.ApplicationFile> {
    return Observable.create((observer: Observer<ApplicationFile.ApplicationFile>) => {
      const resourceRequest: ApplicationFileResource.GetRequest = {
        id: request.id
      };
      return this.resourceService.get(resourceRequest).subscribe(
        (result: ApplicationFileResource.ApplicationFile) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  create(request: ApplicationFile.CreateRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: ApplicationFileResource.CreateRequest = {
        code: request.code,
        note: request.note,
        language_code: request.languageCode
      };
      return this.resourceService.create(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  update(request: ApplicationFile.UpdateRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: ApplicationFileResource.UpdateRequest = {
        id: request.id,
        note: request.note,
      };
      return this.resourceService.update(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  delete(request: ApplicationFile.DeleteRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: ApplicationFileResource.DeleteRequest = {
        id: request.id,
      };
      return this.resourceService.delete(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  export(request: ApplicationFile.ExportRequest): Observable<DownloadedFile> {
    return this.resourceService.export({
      id: request.id,
    });
  }

  private toPublicList(resourceList: ApplicationFileResource.ApplicationFile[]): List<ApplicationFile.ApplicationFile> {
    return List.of(...resourceList.map((r) => this.toPublic(r)));
  }

  private toPublic(r: ApplicationFileResource.ApplicationFile): ApplicationFile.ApplicationFile {
    return {
      id: r.id,
      code: r.code,
      note: r.note,
      languageCode: r.language_code,
      content: this.toPublicContent(r.content)
    }
  }

  private toPublicContent(r: ApplicationFileResource.Content | undefined): ApplicationFile.Content | undefined {
    if (r) {
      return {
        hash: r.hash,
        type: r.type,
        updateTime: Services.toOffsetDateTime(r.update_time),
      }
    }
    return undefined
  }
}

export namespace ApplicationFile {

  export interface Service {

    // <editor-fold desc="CRUD">

    query(request: QueryRequest): Observable<QueryResult<ApplicationFile>>;

    create(request: CreateRequest): Observable<EmptyMessage>;

    update(request: UpdateRequest): Observable<EmptyMessage>;

    delete(request: DeleteRequest): Observable<EmptyMessage>;


    // </editor-fold>

  }

  export interface ApplicationFile {
    id: number;
    code: string;
    note?: string;
    languageCode?: string;
    content?: Content;
  }

  export interface Content {
    hash: string;
    type: string;
    updateTime: OffsetDateTime;
  }

  export interface QueryRequest {
    code?: string;
    languageCode?: string;
    contentType?: string;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
  }

  export interface GetRequest {
    id: number;
  }

  export interface CreateRequest {
    code: string;
    note?: string;
    languageCode?: string;
  }

  export interface UpdateRequest {
    id: number;
    note?: string;
  }

  export interface DeleteRequest {
    id: number;
  }

  export interface ExportRequest {
    id: number;
  }

  export enum OrderField {
    ID,
    CODE,
    LANGUAGE_CODE,
    CONTENT_TYPE,
    CONTENT_UPDATE_TIME
  }

  export enum ValidatedField {
    UNKNOWN
  }

}

class Keys {

  private static readonly ID = 'id';
  private static readonly CODE = 'code';
  private static readonly LANGUAGE_CODE = 'language_code';
  private static readonly CONTENT_TYPE = 'content.type';
  private static readonly CONTENT_UPDATE_TIME = 'content.update_time';

  private static readonly orderFieldKeyMap: Map<ApplicationFile.OrderField, string> = Map.of(
    ApplicationFile.OrderField.ID, Keys.ID,
    ApplicationFile.OrderField.CODE, Keys.CODE,
    ApplicationFile.OrderField.LANGUAGE_CODE, Keys.LANGUAGE_CODE,
    ApplicationFile.OrderField.CONTENT_TYPE, Keys.CONTENT_TYPE,
    ApplicationFile.OrderField.CONTENT_UPDATE_TIME, Keys.CONTENT_UPDATE_TIME,
  );

  private static readonly keyValidatedFieldMap: Map<string, ApplicationFile.ValidatedField> = Map.of(
    // GeneralPdfTemplate.ValidatedField.CODE, Keys.CODE,
  );

  public static toOrderFieldKey(field: ApplicationFile.OrderField): string {
    return Keys.orderFieldKeyMap.get(field)!;
  }

  public static toValidatedField(fieldKey: string): ApplicationFile.ValidatedField {
    return Keys.keyValidatedFieldMap.get(fieldKey, ApplicationFile.ValidatedField.UNKNOWN);
  }

}
