/* eslint-disable */
import { EmptyMessage } from '../util/messages';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { ResourceQueryResult } from '../util/services';
/* eslint-enable */

@Injectable()
export class TableDocumentSchemaResourceService extends BaseHttpService {

  query(request: TableDocumentSchemaResource.QueryRequest):
    Observable<ResourceQueryResult<TableDocumentSchemaResource.TableDocumentSchema>> {
    return this.http.get<ResourceQueryResult<TableDocumentSchemaResource.TableDocumentSchema>>(this.url, this.parseParams(request));
  }

  get(request: TableDocumentSchemaResource.GetRequest): Observable<TableDocumentSchemaResource.TableDocumentSchema> {
    return this.http.get<TableDocumentSchemaResource.TableDocumentSchema>(this.url + `${request.schema_id}`);
  }

  create(request: TableDocumentSchemaResource.CreateRequest): Observable<TableDocumentSchemaResource.CreateItem> {
    return this.http.post<TableDocumentSchemaResource.CreateItem>(this.url, request);
  }

  clone(request: TableDocumentSchemaResource.CloneRequest): Observable<TableDocumentSchemaResource.CreateItem> {
    return this.http.post<TableDocumentSchemaResource.CreateItem>(this.url + `${request.schema_id}/clone`, request);
  }

  update(request: TableDocumentSchemaResource.UpdateRequest): Observable<EmptyMessage> {
    return this.http.put(this.url + `${request.schema_id}`, request);
  }

  delete(request: TableDocumentSchemaResource.DeleteRequest): Observable<EmptyMessage> {
    return this.http.delete(this.url + `${request.schema_id}`);
  }

  finalize(request: TableDocumentSchemaResource.FinalizeRequest): Observable<EmptyMessage> {
    return this.http.post(this.url + `${request.schema_id}/finalize`, null);
  }

  createNext(request: TableDocumentSchemaResource.CreateNextRequest): Observable<TableDocumentSchemaResource.CreateNextResponse> {
    return this.http.post<TableDocumentSchemaResource.CreateNextResponse>(
      this.url + `${request.schema_id}/next?withFields=${request.with_fields}`, null
    );
  }

  deprecate(request: TableDocumentSchemaResource.DeprecateRequest): Observable<EmptyMessage> {
    return this.http.post(this.url + `${request.schema_id}/deprecate`, null);
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/table-document/schema/');
  }

}


export namespace TableDocumentSchemaResource {


  export interface TableDocumentSchema {
    schema_id: number;
    creation_time?: string;
    version?: number;
    version_state: string;
    code: string;
    name: string;
    note: string;
  }



  export interface QueryRequest {
    version_state?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
    name?: string;
    code?: string;
    version?: string;
  }

  export interface GetRequest {
    schema_id: number;
  }

  export interface CreateRequest {
    code: string;
    name: string;
    note: string;
  }

  export interface CloneRequest {
    schema_id: number;
    code: string;
    name: string;
    note: string;
  }

  export interface CreateItem {
    schema_id: number;
  }

  export interface UpdateRequest {
    schema_id: number;
    code: string;
    name: string;
    note: string;
  }

  export interface DeleteRequest {
    schema_id: number;
  }

  export interface FinalizeRequest {
    schema_id: number;
  }

  export interface CreateNextRequest {
    schema_id: number;
    with_fields: boolean;
  }

  export interface CreateNextResponse {
    schema_id: number;
  }

  export interface DeprecateRequest {
    schema_id: number;
  }

}
