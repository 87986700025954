/* eslint-disable */
import { Injectable } from '@angular/core';
import { CountMessage, EmptyMessage, IdentityMessage } from '../util/messages';
import { Observable } from 'rxjs';
import { FormRecordResource } from '../form/form-record-resource.service';
import { AddressResource } from '../address';
import { BaseHttpService, EchoHeader, EchoHeaderBuilder, HeaderKeys, HeaderValues, ResourceHelper } from '../util/http-services';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResourceQueryResult } from '../util/services';
import { DownloadedFile } from '../util/downloaded-files';
import { UrlBuilder } from '../../util/url-builder';
import { map } from 'rxjs/operators';
import { FileAttachmentResource } from '../util/file-attachments';
import { LedgerNumberResource } from '../ledger/number/ledger-number-resource.service';

/* eslint-enable */

@Injectable()
export class CustomerRecordResourceService extends BaseHttpService {

  // <editor-fold desc="CRUD">

  query(request: CustomerRecordResource.QueryRequest): Observable<ResourceQueryResult<CustomerRecordResource.CustomerRecord>> {
    return this.http.get<ResourceQueryResult<CustomerRecordResource.CustomerRecord>>
    (this.url + `${request.customer_id}/records/`, this.parseParams(request));
  }

  get(request: CustomerRecordResource.GetRequest): Observable<CustomerRecordResource.CustomerRecord> {
    return this.http.get<CustomerRecordResource.CustomerRecord>
    (this.url + `${request.customer_id}/records/${request.customer_record_id}`, this.parseParams(request));
  }

  create(request: CustomerRecordResource.CreateRequest): Observable<CustomerRecordResource.CustomerRecord> {
    return this.http.post<CustomerRecordResource.CustomerRecord>
    (this.url + `${request.customer_id}/records?with_form_record=${request.with_form_record}`, request);
  }

  quickCreate(request: CustomerRecordResource.QuickCreateRequest): Observable<CustomerRecordResource.CustomerRecord> {
    const rightsStr = request.rights ? `&rights=${request.rights}` : '';
    return this.http.post<CustomerRecordResource.CustomerRecord>
    (this.url + `${request.customer_id}/records/quick-create?with_form_record=${request.with_form_record}${rightsStr}`, request);
  }

  update(request: CustomerRecordResource.UpdateRequest): Observable<CustomerRecordResource.CustomerRecord> {
    return this.http.put<CustomerRecordResource.CustomerRecord>
    (this.url + `${request.customer_id}/records/${request.customer_record_id}?with_form_record=${request.with_form_record}`, request);
  }

  setDisabled(request: CustomerRecordResource.DisableRequest): Observable<CustomerRecordResource.CustomerRecord> {
    return this.http.patch<CustomerRecordResource.CustomerRecord>
    (
      this.url + `${request.customer_id}/records/${request.customer_record_id}/disabled?with_form_record=${request.with_form_record}`,
      request
    );
  }

  addContactPersons(request: CustomerRecordResource.ContactPersonsRequest): Observable<EmptyMessage> {
    return this.http.post<EmptyMessage>(
      this.url + `${request.customer_id}/records/${request.customer_record_id}/contact-persons/add`, request.contact_person_ids);
  }

  removeContactPerson(request: CustomerRecordResource.ContactPersonRequest): Observable<EmptyMessage> {
    return this.http.post<EmptyMessage>(
      this.url + `${request.customer_id}/records/${request.customer_record_id}/` +
      `contact-persons/remove/${request.contact_person_id}`, request);
  }

  exportXls(request: CustomerRecordResource.QueryRequest): Observable<DownloadedFile> {
    const url = UrlBuilder.create('customers/:customerId/records/export-xls')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .namedNumber('customerId', <number>request.customer_id!)
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob',
      params: this.parseParams(request).params
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  exportXlsTemplate(request: IdentityMessage): Observable<DownloadedFile> {
    const url = UrlBuilder.create('customers/:customerId/records/xls-template')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .namedNumber('customerId', <number>request.id)
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob',
      params: this.parseParams(request).params
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  // </editor-fold>

  constructor(private http: HttpClient, private resourceHelper: ResourceHelper) {
    super(resourceHelper, '/customers/');
  }

}

@Injectable()
export class CustomerRecordAttachmentResourceService extends BaseHttpService {

  getAttachments(request: CustomerRecordResource.QueryAttachmentRequest): Observable<FileAttachmentResource[]> {
    return this.http.get<FileAttachmentResource[]>
    (this.url + `${request.customer_id}/records/${request.customer_record_id}/attachments`, this.parseParams(request));
  }

  downloadAttachment(request: CustomerRecordResource.AttachmentRequest): Observable<DownloadedFile> {
    const url = UrlBuilder.create('customers/:customer_id/records/:customer_record_id/attachments/:file_id'
      + (request.thumbnail ? '/thumbnail' : ''))
      .baseUrl(this.resourceHelper.getBaseUrl())
      .namedNumber('customer_id', request.customer_id)
      .namedNumber('customer_record_id', request.customer_record_id)
      .namedNumber('file_id', request.file_id)
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob',
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  deleteAttachment(request: CustomerRecordResource.AttachmentRequest): Observable<EmptyMessage> {
    return this.http.delete<IdentityMessage>(
      this.url + `${request.customer_id}/records/${request.customer_record_id}/attachments/${request.file_id}`);
  }

  updateAttachment(request: CustomerRecordResource.AttachmentUpdateRequest): Observable<EmptyMessage> {
    return this.http.patch<EmptyMessage>(
      this.url + `${request.customer_id}/records/${request.customer_record_id}/attachments/${request.file_id}`, request);
  }

  constructor(private http: HttpClient, private resourceHelper: ResourceHelper) {
    super(resourceHelper, '/customers/');
  }
}

@Injectable()
export class CustomerRecordGlobalResourceService extends BaseHttpService {

  // <editor-fold desc="CRUD">

  query(request: CustomerRecordResource.GlobalQueryRequest): Observable<ResourceQueryResult<CustomerRecordResource.CustomerRecord>> {
    return this.http.get<ResourceQueryResult<CustomerRecordResource.CustomerRecord>>(this.url, this.parseParams(request));
  }

  count(request: CustomerRecordResource.GlobalQueryRequest): Observable<CountMessage> {
    return this.http.get<CountMessage>(this.url + `count`, this.parseParams(request));
  }

  changeOwners(request: CustomerRecordResource.OwnerChangeRequest): Observable<EmptyMessage> {
    return this.http.post(this.url + `batch-change-owner`, request);
  }

  changeContactLocationsConactPerson(request: CustomerRecordResource.ContactLocationContactPersonChangeRequest):
    Observable<EmptyMessage> {
    return this.http.patch<EmptyMessage>(this.url + `${request.customer_record_id}/related-contact-location`, request);
  }

  inviteToHelpdesk(request: CustomerRecordResource.HelpdeskInviteRequest): Observable<CustomerRecordResource.HelpdeskInviteResponse> {
    return this.http.post<CustomerRecordResource.HelpdeskInviteResponse>(this.url + `helpdesk/invite`, request);
  }

  disableHelpdeskUser(request: CustomerRecordResource.HelpdeskInviteRequest): Observable<EmptyMessage> {
    return this.http.post<EmptyMessage>(this.url + `helpdesk/disable`, request);
  }

  enableHelpdeskUser(request: CustomerRecordResource.HelpdeskInviteRequest): Observable<EmptyMessage> {
    return this.http.post<EmptyMessage>(this.url + `helpdesk/enable`, request);
  }

  getHelpdeskRegistrationData(request: CustomerRecordResource.HelpdeskRegistrationDataRequest):
    Observable<CustomerRecordResource.HelpdeskRegistrationDataResponse> {
    const echoHeader: EchoHeader = new EchoHeaderBuilder().ignoreEntityNotFound(true).build();
    const headers = new HttpHeaders().set(HeaderKeys.ECHO, HeaderValues.serializeEchoHeader(echoHeader));
    return this.http.get<CustomerRecordResource.HelpdeskRegistrationDataResponse>
    (this.url + `helpdesk/data`, {
      headers: headers,
      params: this.parseParams(request).params
    });
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/customer-records/');
  }

}

@Injectable()
export class CustomerRecordContactLocationResourceService extends BaseHttpService {

  query(request: CustomerRecordContactLocationResource.QueryRequest):
    Observable<ResourceQueryResult<AddressResource.ContactLocationResource>> {
    return this.http.get<ResourceQueryResult<AddressResource.ContactLocationResource>>
    (this.url + `${request.customer_id}/records/${request.customer_record_id}/contact-locations/`,
      this.parseParams(request));
  }

  create(request: CustomerRecordContactLocationResource.CreateRequest): Observable<IdentityMessage> {
    return this.http.post<IdentityMessage>
    (this.url + `${request.customer_id}/records/${request.customer_record_id}/contact-locations/`, request);
  }

  update(request: CustomerRecordContactLocationResource.UpdateRequest): Observable<EmptyMessage> {
    return this.http.put<EmptyMessage>
    (this.url + `${request.customer_id}/records/${request.customer_record_id}/contact-locations/${request.id}`, request);
  }

  delete(request: CustomerRecordContactLocationResource.DeleteRequest): Observable<EmptyMessage> {
    return this.http.delete<EmptyMessage>
    (this.url + `${request.customer_id}/records/${request.customer_record_id}/contact-locations/${request.id}`);
  }

  downloadTemplate(request: EmptyMessage): Observable<DownloadedFile> {
    const url = UrlBuilder.create(
      'customer-record-contact-locations/xlsx-template')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob'
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  constructor(private http: HttpClient, private resourceHelper: ResourceHelper) {
    super(resourceHelper, '/customers/');
  }

}

@Injectable()
export class CustomerRecordContactPersonResourceService extends BaseHttpService {

  query(request: CustomerRecordContactPersonResource.QueryRequest):
    Observable<ResourceQueryResult<CustomerRecordResource.CustomerRecord>> {
    return this.http.get<ResourceQueryResult<CustomerRecordResource.CustomerRecord>>
    (this.url + `${request.customer_id}/records/${request.customer_record_id}/contact-persons/`,
      this.parseParams(request));
  }

  constructor(private http: HttpClient, private resourceHelper: ResourceHelper) {
    super(resourceHelper, '/customers/');
  }

}

@Injectable()
export class CustomerRecordBillingInfoResourceService extends BaseHttpService {

  readList(request: CustomerRecordBillingInfoResource.ReadRequest): Observable<CustomerRecordBillingInfoResource.BillingInfo[]> {
    return this.http.get<CustomerRecordBillingInfoResource.BillingInfo[]>
    (this.url + `${request.customer_id}/records/${request.customer_record_id}/billing-info`, this.parseParams(request));
  }

  readOne(request: CustomerRecordBillingInfoResource.ReadOneRequest): Observable<CustomerRecordBillingInfoResource.BillingInfo> {
    return this.http.get<CustomerRecordBillingInfoResource.BillingInfo>
    (this.url + `${request.customer_id}/records/${request.customer_record_id}/billing-info/${request.id}`);
  }

  create(request: CustomerRecordBillingInfoResource.CreateRequest): Observable<CustomerRecordBillingInfoResource.BillingInfo> {
    return this.http.post<CustomerRecordBillingInfoResource.BillingInfo>
    (this.url + `${request.customer_id}/records/${request.customer_record_id}/billing-info`, request);
  }

  update(request: CustomerRecordBillingInfoResource.UpdateRequest): Observable<CustomerRecordBillingInfoResource.BillingInfo> {
    return this.http.post<CustomerRecordBillingInfoResource.BillingInfo>
    (this.url + `${request.customer_id}/records/${request.customer_record_id}/billing-info/${request.id}`, request);
  }

  setDisabled(request: CustomerRecordBillingInfoResource.DisableRequest): Observable<EmptyMessage> {
    return this.http.patch<EmptyMessage>
    (this.url + `${request.customer_id}/records/${request.customer_record_id}/billing-info/${request.id}/disabled`, request);
  }

  constructor(private http: HttpClient, private resourceHelper: ResourceHelper) {
    super(resourceHelper, '/customers/');
  }

}

export namespace CustomerRecordResource {

  // <editor-fold desc="CRUD">


  export interface QueryRequest {
    fields?: string;
    rights?: string;
    with_form_record: boolean;

    contact_person?: boolean;
    contact_person_id?: number;
    contact_person_type?: boolean;
    customer_id: number;
    id?: string;
    disabled?: boolean;
    name?: string;
    external_id?: string;
    comment?: string;
    email_address?: string;
    phone_number?: string;
    postal_address_zip_code?: string;
    postal_address_city?: string;
    postal_address_address?: string;
    postal_address_house_number?: string;
    owner_user_ids?: string;
    owner_group_ids?: string;
    owner_customer_record_id?: string;
    owner_contact_location_id?: string;
    contract_number_id?: string;
    dql?: string;
    q?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
    no_progress_bar?: boolean;
  }

  export interface GlobalQueryRequest {
    fields?: string;
    rights?: string;
    with_form_record: boolean;
    parent_disabled?: boolean;

    contact_person?: boolean;
    contact_person_id?: number;
    contact_person_type?: boolean;
    id?: string;
    excluded_customer_record_ids?: string;
    disabled?: boolean;
    name?: string;
    external_id?: string;
    comment?: string;
    customer_types?: string;
    customer_ids?: string;
    email_address?: string;
    phone_number?: string;
    postal_address_zip_code?: string;
    postal_address_city?: string;
    postal_address_address?: string;
    postal_address_house_number?: string;
    owner_user_ids?: string;
    owner_group_ids?: string;
    parent_id?: number;
    contract_number_id?: string;
    q?: string;
    dql?: string;

    order?: string;
    page_number?: number;
    number_of_items?: number;
    no_progress_bar?: boolean;
  }

  export interface GetRequest {
    fields?: string;
    rights?: string;
    with_form_record: boolean;

    customer_id: number;
    customer_record_id: number;
  }

  export interface CreateRequest extends QuickCreateRequest {
    form_record: FormRecordResource.FormRecordCreateRequest;
  }

  export interface QuickCreateRequest {
    fields?: string;
    with_form_record: boolean;
    rights?: string;

    customer_id: number;
    external_id?: string;
    name?: string;
    first_name?: string;
    last_name?: string;
    comment?: string;
    position?: string;
    web_address?: string;
    tax_number?: string;
    eu_tax_number?: string;
    owner_user_ids: number[];
    owner_group_ids: number[];
    company_id?: number;
    postal_address?: AddressResource.PostalAddressResource;
    notification_address?: AddressResource.PostalAddressResource;
    notification_address_eq_postal: boolean;
    email_addresses: AddressResource.EmailAddressResource[];
    phone_numbers: AddressResource.PhoneNumberResource[];
    parent_id?: number;
    invoice_deadline_additional_days?: number;
    task_record_deadline_additional_days?: number;
    ledger_number?: number;
    main_contract_number?: string;
    // PERSON Type
    place_of_birth?: string;
    date_of_birth?: string;
    birth_name?: string;
    gender?: string;
    mothers_name?: string;
    identity_document_type?: string;
    identity_document_number?: string;
    billing_info?: CustomerRecordBillingInfoResource.CreateRequest;
  }

  export interface UpdateRequest {
    fields?: string;
    with_form_record: boolean;

    customer_id: number;
    customer_record_id: number;
    external_id?: string;
    name?: string;
    first_name?: string;
    last_name?: string;
    comment?: string;
    position?: string;
    web_address?: string;
    tax_number?: string;
    eu_tax_number?: string;
    owner_user_ids: number[];
    owner_group_ids: number[];
    company_id?: number;
    postal_address?: AddressResource.PostalAddressResource;
    notification_address?: AddressResource.PostalAddressResource;
    notification_address_eq_postal: boolean;
    email_addresses: AddressResource.EmailAddressResource[];
    phone_numbers: AddressResource.PhoneNumberResource[];
    form_record: FormRecordResource.FormRecordUpdateRequest;
    parent_id?: number;
    invoice_deadline_additional_days?: number;
    task_record_deadline_additional_days?: number;
    ledger_number?: number;
    main_contract_number?: string;
    // PERSON Type
    place_of_birth?: string;
    date_of_birth?: string;
    birth_name?: string;
    gender?: string;
    mothers_name?: string;
    identity_document_type?: string;
    identity_document_number?: string;
  }

  export interface DisableRequest {
    fields?: string;
    with_form_record: boolean;

    customer_id: number;
    customer_record_id: number;
    disabled: boolean;
  }

  export interface ContactPersonRequest {
    customer_id: number;
    customer_record_id: number;
    contact_person_id: number;
  }

  export interface ContactPersonsRequest {
    customer_id: number;
    customer_record_id: number;
    contact_person_ids: number[];
  }

  export interface OwnerChangeRequest {
    customer_record_ids: number[];
    owner_user_ids?: number[];
    owner_group_ids?: number[];
    change_type: string;
  }

  export interface ContactLocationContactPersonChangeRequest {
    customer_record_id: number;
    contact_person_customer_record_id: number;
    related_contact_location_id?: number;
  }

  export interface SetContactPersonsRequest {
    customer_id: number;
    customer_record_id: number;
    contact_persons: number[];
  }

  // Each field is optional (based on field attribute in the request).
  export interface CustomerRecord {
    id?: number; // non-null if requested
    customer_id?: number; // non-null if requested
    customer?: Customer;
    external_id?: string;
    disabled?: boolean; // non-null if requested
    creation_time?: string; // non-null if requested
    update_time?: string; // non-null if requested
    name?: string; // non-null if requested
    first_name?: string;
    last_name?: string;
    comment?: string;
    position?: string;
    web_address?: string;
    tax_number?: string;
    eu_tax_number?: string;
    owner_user_ids?: number[];
    owner_users?: OwnerUserData[];
    owner_group_ids?: number[];
    owner_groups?: OwnerGroupData[];
    company_id?: number;
    postal_address?: AddressResource.PostalAddressResource;
    notification_address?: AddressResource.PostalAddressResource;
    email_addresses?: AddressResource.EmailAddressResource[]; // non-null if requested
    phone_numbers?: AddressResource.PhoneNumberResource[]; // non-null if requested
    related_locations?: AddressResource.ContactLocationResource[]; // non-null if requested
    form_record?: FormRecordResource.FormRecord; // non-null if requested
    ledger_number?: LedgerNumberResource.LedgerNumber;
    main_contract_number?: string;
    parent_id: number;
    invoice_deadline_additional_days?: number;
    task_record_deadline_additional_days?: number;
    // PERSON Type
    place_of_birth?: string;
    date_of_birth?: string;
    birth_name?: string;
    gender?: string;
    mothers_name?: string;
    identity_document_type?: string;
    identity_document_number?: string;
    helpdesk?: HelpdeskData;
    granted_rights?: string[];
  }

  export interface Customer {
    id: number;
    name: string;
    type: string;
    complex_name: boolean;
    email_address_required: boolean;
    use_default_managed_fields: boolean;
    managed_fields: string[];
    contact_person: boolean;
  }

  export interface MobileApplicationItem {
    id: number;
    application_id: string;
    name?: string;
  }

  export interface QueryAttachmentRequest {
    customer_id: number;
    customer_record_id: number;
  }

  export interface AttachmentRequest extends QueryAttachmentRequest {
    file_id: number;
    thumbnail?: boolean;
  }

  export interface AttachmentUpdateRequest extends QueryAttachmentRequest {
    file_id: number;
    name: string;
  }

  export interface HelpdeskData {
    user?: {
      id: number;
      person_name: string;
      user_name: string;
      disabled: boolean;
    };
    invitation_time?: string;
  }

  export interface HelpdeskInviteRequest {
    customer_record_ids: number[];
  }

  export interface HelpdeskInviteResponse {
    invited: CustomerRecord[];
    already_registered: CustomerRecord[];
    invalid_email: CustomerRecord[];
  }

  export interface HelpdeskRegistrationDataRequest {
    helpdesk_token: string;
  }

  export interface HelpdeskRegistrationDataResponse {
    id: number;
    name: string;
    email_address: string;
    customer_names?: string[];
  }

  export interface OwnerUserData {
    id: number;
    person_name: string;
    user_name: string;
  }

  export interface OwnerGroupData {
    id: number;
    name: string;
  }

  // </editor-fold>

}

export namespace CustomerRecordContactLocationResource {

  export interface QueryRequest {
    customer_id: number;
    customer_record_id: number;
    ids?: string;
    contract_number_ids?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
    no_progress_bar?: boolean;
  }

  interface BaseRequest {
    customer_id: number;
    customer_record_id: number;
    name: string;
    opening_times?: string;
    comment?: string;
    postal_address: AddressResource.PostalAddressResource;
    contact_persons?: number[];
    contract_number?: string;
  }

  export interface CreateRequest extends BaseRequest {
    external_id?: string;
  }

  export interface UpdateRequest extends BaseRequest {
    id: number;
    external_id: string;
  }

  export interface DeleteRequest {
    customer_id: number;
    customer_record_id: number;
    id: number;
  }

}

export namespace CustomerRecordContactPersonResource {

  export interface QueryRequest {
    customer_id: number;
    customer_record_id: number;
    order?: string;
    fields?: string;
    page_number?: number;
    number_of_items?: number;
    no_progress_bar?: boolean;
  }

}

export namespace CustomerRecordBillingInfoResource {

  export interface BillingInfo {
    id: number;
    disabled: boolean;
    name: string;
    tax_number?: string;
    eu_tax_number?: string;
    bank_account?: string;
    invoice_vat_status: string;
    invoice_address: AddressResource.PostalAddressResource;
  }

  export interface ReadRequest {
    customer_id: number;
    customer_record_id: number;
    disabled?: boolean;
  }

  export interface ReadOneRequest extends ReadRequest {
    id: number;
  }

  export interface DisableRequest extends ReadOneRequest {
    disabled: boolean;
  }

  export interface CreateRequest extends ReadRequest {
    name: string;
    tax_number?: string;
    eu_tax_number?: string;
    bank_account?: string;
    invoice_vat_status: string;
    invoice_address: AddressResource.PostalAddressResource;
  }

  export interface UpdateRequest extends CreateRequest {
    id: number;
  }
}
