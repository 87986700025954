import { Observable } from 'rxjs';
import { EmptyMessage, IdentityMessage } from '../util/messages';
import { Order, PagingRequest, QueryResult } from '../util/services';
import { OffsetDateTime } from '../util/dates';
import { List, Map as ImmutableMap, Set } from 'immutable';
import { TriggerUtils } from './trigger-utils';
import { TriggerInstance } from './trigger-instance.service';
import { TransportDocument } from '../transport/transport-document/transport-document.service';
import { Workflow } from '../workflow/workflow.service';
import { TaskRecord } from '../task/task-record.service';

export namespace Trigger {

  import TransportDocumentType = TransportDocument.TransportDocumentType;
  import TaskRecordBulkCloneField = TaskRecord.TaskRecordBulkCloneField;

  export interface TriggerService {
    queryTriggers(request: TriggerQueryRequest): Observable<QueryResult<Trigger>>;

    getTrigger(request: TriggerGetRequest): Observable<Trigger>;

    getTriggerInstances(request: TriggerInstanceRequest): Observable<QueryResult<TriggerInstance.TriggerInstance>>;

    disableTrigger(request: TriggerDisableRequest): Observable<EmptyMessage>;

    deleteTrigger(request: TriggerDeleteRequest): Observable<EmptyMessage>;

    createTrigger(request: TriggerCreateRequest): Observable<IdentityMessage>;

    updateTrigger(request: TriggerUpdateRequest): Observable<EmptyMessage>;
  }

  export interface Trigger {
    id: number;
    type: TriggerUtils.TriggerType;
    creationTime: OffsetDateTime;
    updateTime: OffsetDateTime;
    event: TriggerUtils.NotificationEvent;
    name: string;
    disabled: boolean;
    pdf?: Pdf;
    receipt?: Receipt;
    email?: Email;
    message?: Message;
    clone?: Clone;
    rules: Workflow.BaseRule[];
  }

  export interface Pdf {
    pdfTemplate: PdfTemplate;
    namePattern: string;
  }

  export interface PdfTemplate {
    id: number;
    code: string;
  }

  export interface Receipt {
    receiptTemplate: ReceiptTemplate;
    namePattern: string;
  }

  export interface ReceiptTemplate {
    id: number;
    code: string;
    printerType: TriggerUtils.PrinterType;
  }

  export interface Email {
    emailTemplate: EmailTemplate;
    ignoredWithEmptyRecipients: boolean;
    attachment: EmailAttachment;
    recipient: EmailRecipient;
  }

  export interface EmailTemplate {
    id: number;
    name: string;
  }

  export interface Message {
    messageTemplate: MessageTemplate;
    ignoredWithEmptyRecipients: boolean;
    recipient: MessageRecipient
  }

  export interface MessageTemplate {
    id: number;
    name: string;
  }

  export interface Clone {
    defaultDeadlineAdditionalDays: number;
    openIfPossible: boolean;
    cloneFields: Set<TaskRecordBulkCloneField>;
    cloneFormFields?: Set<number>;
    destinationTaskId: number;
  }

  export interface TriggerQueryRequest {
    nameSwIc?: string;
    triggerParentId?: number;
    disabled?: boolean;
    order?: Set<Order<TriggerOrderField>>;
    noProgressBar?: boolean;
    paging?: PagingRequest;
  }

  export interface TriggerGetRequest {
    id: number;
    triggerParentId?: number;
  }

  export interface TriggerInstanceRequest {
    id: number;
    triggerParentId?: number;
    order?: Set<Order<TriggerInstanceOrderField>>;
    paging: PagingRequest;
  }

  export interface TriggerDisableRequest {
    id: number;
    triggerParentId?: number;
    disabled: boolean;
  }

  export interface TriggerDeleteRequest {
    id: number;
    triggerParentId?: number;
  }

  export interface TriggerCreateRequest {
    triggerParentId?: number;

    event: TriggerUtils.NotificationEvent;
    name: string;
    pdf?: PdfCreateRequest;
    receipt?: ReceiptCreateRequest;
    email?: EmailCreateRequest;
    message?: MessageCreateRequest;
    clone?: CloneCreateRequest;
    rules: Workflow.BaseRule[];
  }

  export interface PdfCreateRequest {
    pdfTemplateId: number;
    namePattern: string;
  }

  export interface ReceiptCreateRequest {
    receiptTemplateId: number;
    namePattern: string;
  }

  export interface EmailCreateRequest {
    emailTemplateId: number;
    ignoredWithEmptyRecipients: boolean;
    attachment: EmailAttachment;
    recipient: EmailRecipient;
  }

  export interface EmailAttachment {
    pdfTriggerIds?: Set<number>;
    transportDocumentTypes?: Set<TransportDocumentType>;
    fileDocumentIds: Set<number>;
    documentGroupIds: Set<number>;
    attachEachShipmentDocument?: boolean;
    attachInvoices: boolean;
    attachTaskAttachments: boolean;
    documentFormFieldIds: Set<number>;
  }

  export interface EmailRecipient {
    sendToEveryone: boolean;
    userIds: Set<number>;
    userGroupIds: Set<number>;
    emailAddresses: List<string>;
    relatedPersonTypes: Set<TriggerUtils.RelatedPersonType>;
    emailFormFieldIds: Set<number>;
    emailAddressTypeIds: Set<number>;
    customerFormFieldEmailAddressTypes: ImmutableMap<number, Set<number>>;
    userFormFieldIds: Set<number>;
  }

  export interface MessageCreateRequest {
    messageTemplateId: number;
    ignoredWithEmptyRecipients: boolean;
    recipient: MessageRecipient;
  }

  export interface MessageRecipient {
    sendToEveryone: boolean;
    userIds: Set<number>;
    userGroupIds: Set<number>;
    mobileApplicationIds: Set<number>;
    relatedPersonTypes: Set<TriggerUtils.RelatedPersonType>;
    userFormFieldIds: Set<number>;
  }

  export interface CloneCreateRequest {
    openIfPossible: boolean;
    defaultDeadlineAdditionalDays: number;
    destinationTaskId: number;
    cloneFields: Set<TaskRecordBulkCloneField>;
    cloneFormFields?: Set<number>;
  }

  export interface TriggerUpdateRequest extends TriggerCreateRequest {
    id: number;
  }

  export enum TriggerOrderField {
    TYPE,
    CREATION_TIME,
    NAME,
    EVENT
  }

  export enum TriggerInstanceOrderField {
    TYPE,
    CREATION_TIME,
    TRIGGER_NAME,
    TRIGGER_EVENT,
    TASK_RECORD_NAME,
    TASK_RECORD_ID,
  }

  export class Keys {

    private static readonly CREATION_TIME = 'creation_time';
    private static readonly NAME = 'name';
    private static readonly TYPE = 'type';
    private static readonly EVENT = 'event';
    private static readonly TRIGGER_NAME = 'trigger_name';
    private static readonly TRIGGER_EVENT = 'trigger_event';
    private static readonly TASK_RECORD_NAME = 'task_record_name';
    private static readonly TASK_RECORD_ID = 'task_record_id';

    private static readonly triggerOrderFieldKeyMap: ImmutableMap<TriggerOrderField, string> = ImmutableMap.of(
      TriggerOrderField.TYPE, Keys.TYPE,
      TriggerOrderField.CREATION_TIME, Keys.CREATION_TIME,
      TriggerOrderField.NAME, Keys.NAME,
      TriggerOrderField.EVENT, Keys.EVENT,
    );

    private static readonly triggerInstanceOrderFieldKeyMap: ImmutableMap<TriggerInstanceOrderField, string> = ImmutableMap.of(
      TriggerInstanceOrderField.TYPE, Keys.TYPE,
      TriggerInstanceOrderField.CREATION_TIME, Keys.CREATION_TIME,
      TriggerInstanceOrderField.TRIGGER_NAME, Keys.TRIGGER_NAME,
      TriggerInstanceOrderField.TRIGGER_EVENT, Keys.TRIGGER_EVENT,
      TriggerInstanceOrderField.TASK_RECORD_NAME, Keys.TASK_RECORD_NAME,
      TriggerInstanceOrderField.TASK_RECORD_ID, Keys.TASK_RECORD_ID,
    );

    public static toTriggerOrderFieldKey(field: TriggerOrderField): string {
      return Keys.triggerOrderFieldKeyMap.get(field)!;
    }

    public static toTriggerInstanceOrderFieldKey(field: TriggerInstanceOrderField): string {
      return Keys.triggerInstanceOrderFieldKeyMap.get(field)!;
    }
  }

}
