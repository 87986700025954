<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div class="breadcrumb-menu">
    <!--CREATE-->
    <div *ngIf="rightModel.documentGroupCreate.hasRight()"
         class="breadcrumb-button-icon-container cursor-pointer negative-margin-right"
         uiSref="Admin.DocumentGroupCreate">
      <a class="btn-setting d-flex align-items-center" title="{{'COMMON_BUTTON_ADD' | translate}}">
        <i class="icomoon icomoon-add"></i>
        <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_ADD' | translate}}</span>
      </a>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="card">

    <div class="card-header">
      {{'DOCUMENT_GROUP_LIST_TITLE' | translate}}

      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()"
           title="{{'COMMON_SHOW_SEARCH_HINT' | translate}}"><i #searchIcon
                                                                class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>

    <div class="card-body border-bottom" *ngIf="showSearch">

      <div class="row form-group">
        <div class="col-md-3">
          <label class="search-label">{{'DOCUMENT_GROUP_SEARCH_CODE_LABEL' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'DOCUMENT_GROUP_SEARCH_CODE_LABEL' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.code" name="code" id="code" #name="ngModel">
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'COMMON_NAME' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.name" name="name" id="name" #name="ngModel">
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'COMMON_STATE' | translate}}</label>
          <select class="form-control" [(ngModel)]="searchModel.disabled"
                  id="disabled" name="disabled" #disabled="ngModel"
                  [compareWith]="SelectUtils.compareObjects">
            <option *ngFor="let item of disabledItems" [ngValue]="item">{{item.text}}</option>
          </select>
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'DOCUMENT_GROUP_LANGUAGE_LABEL' | translate}}</label>
          <select class="form-control" [(ngModel)]="searchModel.languageCode" id="languageCode" name="languageCode"
                  #languageCode="ngModel"
                  [compareWith]="SelectUtils.compareObjects">
            <option *ngFor="let languageCode of languageCodes" [ngValue]="languageCode">{{languageCode.text}}</option>
          </select>
        </div>
      </div>
      <div class="col-md-12 d-flex justify-content-md-end align-items-end px-0">
        <div class="btn-group" role="group">
          <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
            {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
          </button>
          <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
            {{'COMMON_BUTTON_SEARCH' | translate}}
          </button>
        </div>
      </div>

    </div> <!-- ./card-body end -->

    <div class="card-body">

      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header w-5 hidden-xs-down">
            <app-table-field-sorter [orderField]="DocumentGroup.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(DocumentGroup.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-30 hidden-xs-down">
            <app-table-field-sorter [orderField]="DocumentGroup.OrderField.CODE"
                                    [orderType]="queryModel.getOrderType(DocumentGroup.OrderField.CODE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'DOCUMENT_GROUP_CODE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header long">
            <app-table-field-sorter [orderField]="DocumentGroup.OrderField.NAME"
                                    [orderType]="queryModel.getOrderType(DocumentGroup.OrderField.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-10">
            <app-table-sorter-no-op [text]="'COMMON_TABLE_HEADER_ACTIONS' | translate"></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let documentGroup of documentGroups | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }"
            [disabledTableRow]="documentGroup.disabled">
          <td class="responsive-table-column hidden-xs-down">{{documentGroup.id}}</td>
          <td class="responsive-table-column hidden-xs-down">{{documentGroup.code}}</td>
          <td class="responsive-table-column long ">{{documentGroup.name}}</td>
          <td class="responsive-table-column">
            <a class="icons-table-group" href="#" onclick="return false"
               (click)="setDisabled($event, documentGroup, true)"
               *ngIf="!documentGroup.disabled && rightModel.documentGroupDisable.hasRight()">
              <i class="icomoon icons-table-item icomoon-disable icomoon-disable-table"
                 title="{{'COMMON_BUTTON_DISABLE' | translate}}"></i>
            </a>
            <a class="icons-table-group" href="#" onclick="return false"
               (click)="setDisabled($event, documentGroup, false)"
               *ngIf="documentGroup.disabled && rightModel.documentGroupDisable.hasRight()">
              <i class="icomoon icons-table-item icomoon-enable icomoon-enable-table"
                 title="{{'COMMON_BUTTON_ENABLE' | translate}}"></i>
            </a>
            <a *ngIf="rightModel.documentGroupUpdate.hasRight()"
               class="icons-table-group" uiSref="Admin.DocumentGroupEdit" [uiParams]="{ id: documentGroup.id }">
              <i class="icomoon icons-table-item icomoon-modify icomoon-modify-table"
                 title="{{'COMMON_BUTTON_EDIT' | translate}}"></i>
            </a>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header w-5 hidden-xs-down">
            <app-table-field-sorter [orderField]="DocumentGroup.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(DocumentGroup.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-30 hidden-xs-down">
            <app-table-field-sorter [orderField]="DocumentGroup.OrderField.CODE"
                                    [orderType]="queryModel.getOrderType(DocumentGroup.OrderField.CODE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'DOCUMENT_GROUP_CODE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header long">
            <app-table-field-sorter [orderField]="DocumentGroup.OrderField.NAME"
                                    [orderType]="queryModel.getOrderType(DocumentGroup.OrderField.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-10">
            <app-table-sorter-no-op [text]="'COMMON_TABLE_HEADER_ACTIONS' | translate"></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>
  </div>
</div>
