/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { Order, PagingRequest, QueryResult, ResourceQueryResult, Services } from '../../util/services';
import { List, Map as ImmutableMap, Set } from 'immutable';
import { TaskStatisticsResource, TaskStatisticsResourceService } from './task-statistics-resource.service';
import {TaskResource} from "../../task/task-resource.service";

/* eslint-enable */

@Injectable()
export class TaskStatisticsService implements TaskStatistics.Service {

  query(request: TaskStatistics.QueryRequest): Observable<QueryResult<TaskStatistics.TaskStatistic>> {
    return Observable.create((observer: Observer<QueryResult<TaskStatistics.TaskStatistic>>) => {
      const resourceRequest: TaskStatisticsResource.QueryRequest = {
        by: request.by,
        task_id: request.taskId,
        user_profile_id: request.userProfileId,
        task_name: request.taskName,
        user_profile_name: request.userProfileName,
        q: request.q,
        order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.order),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
      };
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<TaskStatisticsResource.TaskStatistic>) => {
          observer.next({
            items: this.toPublicList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  get(request: TaskStatistics.GetRequest): Observable<TaskStatistics.TaskStatistic> {
    return Observable.create((observer: Observer<TaskStatistics.TaskStatistic>) => {
      const getRequest: TaskStatisticsResource.GetRequest = {
        by: request.by,
        task_id: request.taskId,
        user_profile_id: request.userProfileId,
      };
      return this.resourceService.get(getRequest).subscribe(
        (result: ResourceQueryResult<TaskStatisticsResource.TaskStatistic>) => {
          observer.next({
            taskId: result.items[0].task_id,
            id: result.items[0].id,
            userProfileId: result.items[0].user_profile_id,
            createCount: result.items[0].create_count,
            startCount: result.items[0].start_count,
            recallCount: result.items[0].recall_count,
            rejectCount: result.items[0].reject_count,
            finishCount: result.items[0].finish_count,
          });
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  getSimpleStat(request: {helpdesk?: boolean}): Observable<TaskStatistics.SimpleStat> {
    return Observable.create((observer: Observer<TaskStatistics.SimpleStat>) => {
      return this.resourceService.getSimpleStat({
        helpdesk_enabled: request.helpdesk
      }).subscribe(
        (result: TaskStatisticsResource.SimpleStat) => {
          observer.next({
            stateStat: new Map(Object.entries(result.state_stat)),
            overdueCount: result.overdue_count
          });
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  getHelpdeskStat(): Observable<ImmutableMap<string, number>> {
    return Observable.create((observer: Observer<ImmutableMap<string, number>>) => {
      return this.resourceService.getHelpdeskStat().subscribe(
        (result: ImmutableMap<string, number>) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  constructor(private resourceService: TaskStatisticsResourceService) {}

  private toPublicList(resourceList: TaskStatisticsResource.TaskStatistic[]): List<TaskStatistics.TaskStatistic> {
    return List.of(...resourceList.map((r) => this.toPublic(r)));
  }

  private toPublic(r: TaskStatisticsResource.TaskStatistic): TaskStatistics.TaskStatistic {
    return {
      id: r.id,
      taskId: r.task_id,
      userProfileId: r.user_profile_id,
      createCount: r.create_count,
      startCount: r.start_count,
      recallCount: r.recall_count,
      rejectCount: r.reject_count,
      finishCount: r.finish_count
    };
  }

}

export namespace TaskStatistics {

  export interface Service {

    query(request: QueryRequest): Observable<QueryResult<TaskStatistic>>;

  }

  export interface QueryRequest {
    by: string;
    taskId?: number;
    userProfileId?: number;
    taskName?: string;
    userProfileName?: string;
    q?: string,
    order?: Set<Order<OrderField>>;
    paging?: PagingRequest;
  }

  export interface GetRequest {
    by: string;
    taskId: number;
    userProfileId: number;
  }

  export interface TaskStatistic {
    id: string;
    taskId: number;
    userProfileId: number;
    createCount: number;
    startCount: number;
    recallCount: number;
    rejectCount: number;
    finishCount: number;
  }

  export interface SimpleStat {
    stateStat: Map<string, number>;
    overdueCount: number;
  }

  export enum OrderField {
    TASK_ID,
    USER_PROFILE_ID,
    TASK_NAME,
    USER_PROFILE_NAME,
    CREATE_COUNT,
    START_COUNT,
    RECALL_COUNT,
    REJECT_COUNT,
    FINISH_COUNT
  }

}

class Keys {

  private static readonly TASK_ID = 'task_id';
  private static readonly USER_PROFILE_ID = 'user_profile_id';
  private static readonly TASK_NAME = 'task_name';
  private static readonly USER_PROFILE_NAME = 'user_profile_name';
  private static readonly CREATE_COUNT = 'create_count';
  private static readonly START_COUNT = 'start_count';
  private static readonly RECALL_COUNT = 'recall_count';
  private static readonly REJECT_COUNT = 'reject_count';
  private static readonly FINISH_COUNT = 'finish_count';

  private static readonly orderFieldKeyImmutableMap: ImmutableMap<TaskStatistics.OrderField, string> = ImmutableMap.of(
    TaskStatistics.OrderField.TASK_ID, Keys.TASK_ID,
    TaskStatistics.OrderField.USER_PROFILE_ID, Keys.USER_PROFILE_ID,
    TaskStatistics.OrderField.TASK_NAME, Keys.TASK_NAME,
    TaskStatistics.OrderField.USER_PROFILE_NAME, Keys.USER_PROFILE_NAME,
    TaskStatistics.OrderField.CREATE_COUNT, Keys.CREATE_COUNT,
    TaskStatistics.OrderField.START_COUNT, Keys.START_COUNT,
    TaskStatistics.OrderField.RECALL_COUNT, Keys.RECALL_COUNT,
    TaskStatistics.OrderField.REJECT_COUNT, Keys.REJECT_COUNT,
    TaskStatistics.OrderField.FINISH_COUNT, Keys.FINISH_COUNT,
  )

  public static toOrderFieldKey(field: TaskStatistics.OrderField): string {
    return Keys.orderFieldKeyImmutableMap.get(field)!;
  }

}
