/* eslint-disable */
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { DownloadedFile } from '../../util/downloaded-files';
import { AddressResource } from '../../address';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from '../../util/http-services';
import { DisableRequest, EmptyMessage, IdentityMessage } from '../../util/messages';
import { ResourceQueryResult } from '../../util/services';
/* eslint-enable */

@Injectable()
export class InvoiceSettingsResourceService extends BaseHttpService {

  query(request: InvoiceSettingsResource.QueryRequest): Observable<ResourceQueryResult<InvoiceSettingsResource.InvoiceSettings>> {
    return this.http.get<ResourceQueryResult<InvoiceSettingsResource.InvoiceSettings>>(this.url, this.parseParams(request));
  }

  get(request: IdentityMessage): Observable<InvoiceSettingsResource.InvoiceSettings> {
    return this.http.get<InvoiceSettingsResource.InvoiceSettings>(this.url + `${request.id}`);
  }

  create(request: InvoiceSettingsResource.CreateRequest): Observable<InvoiceSettingsResource.InvoiceSettings> {
    return this.http.post<InvoiceSettingsResource.InvoiceSettings>(this.url, request);
  }

  update(request: InvoiceSettingsResource.UpdateRequest): Observable<InvoiceSettingsResource.InvoiceSettings> {
    return this.http.put<InvoiceSettingsResource.InvoiceSettings>(this.url + `${request.id}`, request);
  }

  downloadTemplate(request: IdentityMessage): Observable<DownloadedFile> {
    return this.http.get(this.url + `${request.id}/template`, {
      observe: 'response',
      responseType: 'blob'
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  deleteTemplate(request: IdentityMessage): Observable<EmptyMessage> {
    return this.http.delete<EmptyMessage>(this.url + `${request.id}/template`);
  }

  setDisabled(request: DisableRequest): Observable<EmptyMessage> {
    return this.http.patch<EmptyMessage>(this.url + `${request.id}/disabled`, request);
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, 'invoice/settings/');
  }

}

export namespace InvoiceSettingsResource {

  export interface QueryRequest {
    id?: string;
    disabled?: boolean;
    company_name?: string;
    external_id?: string;
    profile_name?: string;
    invoice_integration_type?: string;
    invoice_direction_type?: string;
    q?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
    no_progress_bar?: boolean;
  }

  export interface InvoiceSettings {
    id: number;
    address: AddressResource.PostalAddressResource;
    company_name: string;
    external_id: string;
    profile_name: string;
    invoice_integration_type: string;
    invoice_direction_type: string;
    inbound_stock_id?: number;
    tax_number: string;
    eu_vat_number?: string;
    email_address?: string;
    phone_number?: string;
    bank_name?: string;
    bank_account?: string;
    web_address?: string;
    registration_number?: string;
    comment_template?: string;
    footer_text?: string;
    pdf_template_id?: number;
    disabled: boolean;
    creation_time: string;
    update_time: string;
    payment_type_extras?: InvoicePaymentTypeExtra[];
    technical_user?: TechnicalUser;
    szamlazz_hu?: SzamlazzHu;
    default_invoice_appearance_type: string;
    default_payment_type?: string;
    language_code: string;
    summary_rounding_enabled: boolean;
    record_rounding_enabled: boolean;
    zero_priced_records_allowed: boolean;
  }

  export interface CreateRequest {
    address: AddressResource.PostalAddressResource;
    company_name: string;
    external_id?: string;
    profile_name: string;
    invoice_integration_type: string;
    invoice_direction_type: string;
    inbound_stock_id?: number;
    tax_number: string;
    eu_vat_number?: string;
    email_address?: string;
    phone_number?: string;
    bank_name: string;
    bank_account: string;
    web_address?: string;
    registration_number?: string;
    comment_template?: string;
    footer_text?: string;
    pdf_template_id?: number;
    technical_user?: TechnicalUser;
    szamlazz_hu?: SzamlazzHu;
    default_invoice_appearance_type: string;
    default_payment_type?: string;
    language_code: string;
    summary_rounding_enabled: boolean;
    record_rounding_enabled: boolean;
    zero_priced_records_allowed: boolean;
  }

  export interface UpdateRequest extends CreateRequest {
    id: number;
    external_id: string;
    payment_type_extras?: InvoicePaymentTypeExtra[];
  }

  export interface InvoicePaymentTypeExtra {
    payment_type: string,
    issue_deadline_plus_days: number,
  }

  export interface TechnicalUser {
    user_name?: string;
    password?: string;
    signature_key?: string;
    exchange_key?: string;
  }

  export interface SzamlazzHu {
    notification_email_address?: string;
    agent_key: string;
    send_email: boolean
    customer_email_address_type?: number;
    email_template?: string;
    email_subject?: string;
  }
}
