/* eslint-disable */
import { MultiselectOptionItem, OptionItem } from '../core-utils';
import { StockItemItem } from './stock-item-utils';
import { StockRecord } from '../../lib/stock/stock-record.service';
import { AssigneeItem } from '../task-record-utils';
import { StockOwnerUserItem } from '../../admin/stock/stock-edit/stock-edit.component';
import { List } from 'immutable';
import { DisabledItem } from '../search-utils';
import { StockSums } from '../../lib/stock/stock.service';
/* eslint-enable */

export type StockTypeName = 'COMPANY' | 'PERSONAL' | 'CUSTOMER';

export class StockEditModel {
  name: string = '';
  externalId: string = '';
  owner_users: StockOwnerUserItem[] = [];
  records: StockRecord[];
  company: MultiselectOptionItem<number>[] = [];
  customerRecord: MultiselectOptionItem<number>[] = [];
  contactLocation: MultiselectOptionItem<number>[] = [];
  central: boolean = false;
  waste: boolean = false;
  type: StockTypeName;
  sums?: StockSums[];
}

export class StockSearchModel {
  id: string;
  name: string;
  assigneeUsers: MultiselectOptionItem<number>[] = [];
  companies: MultiselectOptionItem<number>[] = [];
  customerRecord: MultiselectOptionItem<number>[] = [];
  contactLocation: MultiselectOptionItem<number>[] = [];
  stockItems: MultiselectOptionItem<number> [] = [];
  disabled?: DisabledItem;
  type: StockTypeFilterOption;

  get customerRecordIds(): string | undefined {
    if (this.customerRecord.length === 0) {
      return undefined;
    }
    return this.customerRecord.map(c => c.id).join(',');
  }

  get assigneeUserIds(): string | undefined {
    if (this.assigneeUsers.length === 0) {
      return undefined;
    }
    return this.assigneeUsers.map(c => c.id).join(',');
  }

  get companyIds(): string | undefined {
    if (this.companies.length === 0) {
      return undefined;
    }
    return this.companies.map(c => c.id).join(',');
  }

  get contactLocationIds(): string | undefined {
    if (this.contactLocation.length === 0) {
      return undefined;
    }
    return this.contactLocation.map(c => c.id).join(',');
  }

  get stockItemIds(): string | undefined {
    if (this.stockItems.length === 0) {
      return undefined;
    }
    return this.stockItems.map(c => c.id).join(',');
  }

  public isEmpty(): boolean {
    return this.id.length === 0
      && this.name.length === 0
      && this.assigneeUsers.length === 0
      && this.companies.length === 0
      && this.customerRecord.length === 0
      && this.contactLocation.length === 0
      && this.stockItems.length === 0
      && OptionItem.idOrUndefined(this.disabled) === undefined
      && this.type === 'ALL';
  }
}

export type StockTypeFilterOption = 'ALL' | 'COMPANY' | 'PERSONAL' | 'CUSTOMER';

export class StockTypeFilterOptionsObject {
  type: StockTypeFilterOption;
  stringKey: string;
}

export const stockTypeFilterOptions: StockTypeFilterOptionsObject[] = [
  {type: 'ALL', stringKey: 'COMMON_VALUE_ALL'},
  {type: 'PERSONAL', stringKey: 'STOCK_TYPE_PERSONAL'},
  {type: 'COMPANY', stringKey: 'STOCK_TYPE_COMPANY'},
  {type: 'CUSTOMER', stringKey: 'STOCK_TYPE_CUSTOMER'},
];

export class StockCreateModel extends StockEditModel {
}

export class StockRecordResourceEditRequestModel {
  amount: string = '';
  intakePrice: string = '';
  unit?: string;
  reservedAmount?: number;
}

export class StockRecordResourceCreateRequestModel extends StockRecordResourceEditRequestModel {
  stock_item: StockItemItem[] = [];
  all: boolean = false;
}

export class StockListItem extends OptionItem<number> {
}

export class StockDisabledItem extends OptionItem<StockDisabled> {
}

export class StockDisabledItems {
  public static readonly values: StockDisabled[] = ['ALL', 'ACTIVE', 'INACTIVE'];
}

export type StockDisabled =
  'ALL' |
  'ACTIVE' |
  'INACTIVE';

export class SelectableCompanyModel extends OptionItem<number> {
}

export class StockType {

  public static readonly COMPANY = new StockType('COMPANY', 'STOCK_TYPE_COMPANY', 'icomoon icomoon-administration-stock-management-alt');
  public static readonly PERSONAL = new StockType('PERSONAL', 'STOCK_TYPE_PERSONAL', 'icomoon icomoon-administration-user-management-alt');
  public static readonly CUSTOMER = new StockType('CUSTOMER', 'STOCK_TYPE_CUSTOMER', 'icomoon icomoon-sidebar-customers');

  public static readonly DEFAULT = StockType.PERSONAL;

  public static readonly VALUES: List<StockType> = List.of(
    StockType.COMPANY,
    StockType.PERSONAL,
    StockType.CUSTOMER,
  );

  public static fromName(name: string): StockType | undefined {
    const res = StockType.VALUES.toArray().filter((v) => {
      return v.name === name;
    });
    if (res.length < 1) {
      return undefined;
    }
    return res[0];
  }

  public toMultiselectOptionItem(): MultiselectOptionItem<StockTypeName> {
    return {id: this.name, itemName: this.textDictionaryKey};
  }

  private constructor(
    public readonly name: StockTypeName,
    public readonly textDictionaryKey: string,
    public readonly iconClass) {
  }

}
