import { Component, OnInit } from '@angular/core';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { TranslateService } from '@ngx-translate/core';
import { Transition } from '@uirouter/core';
import { StateName } from '../../../app.state-names';
import { OrderImportErrorModel } from '../order-import-error.model';
import { OrderImportError, OrderImportErrorService } from '../../../lib/order-import-error/order-import-error.service';
import { StringKey } from '../../../app.string-keys';
import { ToasterService } from '../../../fork/angular2-toaster/src/toaster.service';
import { UiConstants } from '../../../util/core-utils';
import { AlertType } from '../../../shared/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-order-import-error-detail',
  templateUrl: './order-import-error-detail.component.html',
  styleUrls: ['./order-import-error-detail.component.scss']
})
export class OrderImportErrorDetailComponent implements OnInit {

  model: OrderImportErrorModel = new OrderImportErrorModel();
  AlertType = AlertType;

  private orderImportErrorId: number;
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  constructor(
    private orderImportErrorService: OrderImportErrorService,
    private translateService: TranslateService,
    private transition: Transition,
    private toasterService: ToasterService) {
    this.orderImportErrorId = this.transition.params().id;
  }

  ngOnInit() {
    this.initBreadcrumb();
    this.loadModel();
  }

  private initBreadcrumb() {
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('ORDER_IMPORT_ERROR_LIST').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ORDER_IMPORT_ERROR_LIST});
      }
    );
    this.breadcrumbSelf = this.orderImportErrorId + '';
  }

  private loadModel() {
    this.orderImportErrorService.get({
      id: this.orderImportErrorId
    }).subscribe(
      (orderImportError: OrderImportError.OrderImportError) => {
        this.model.id = orderImportError.id;
        this.model.companyName = orderImportError.company.name;
        this.model.userName = orderImportError.user.personName;
        this.model.externalId1 = orderImportError.externalId1;
        this.model.externalId2 = orderImportError.externalId2 ? orderImportError.externalId2 : '';
        this.model.extraId1 = orderImportError.extraId1 ? orderImportError.extraId1 : '';
        this.model.realm = orderImportError.realm;
        this.model.partnerNameStringKey =
          OrderImportError.orderImportErrorRealms.find(o => o.realm === orderImportError.realm)!.stringKey;
        this.model.importStateObject = OrderImportError.orderImportErrorStates.find(o => o.state === orderImportError.state)!;
        this.model.creationTime = orderImportError.creationTime;
        this.model.updateTime = orderImportError.updateTime;
        this.model.recipientName = orderImportError.recipientName ? orderImportError.recipientName : '';
        this.model.errorReason = OrderImportError.orderImportErrorReasons.find(o => o.reason === orderImportError.errorReason)!;
        this.model.errorMessage = orderImportError.errorMessage ? orderImportError.errorMessage : '';
        this.model.stackTrace = orderImportError.errorStackTrace;
        this.model.data1 = orderImportError.data1;
        this.model.data2 = orderImportError.data2;
        this.model.data3 = orderImportError.data3;
        this.model.manualRejectTime = orderImportError.manualRejectTime;
        this.model.manualRejectUser = orderImportError.manualRejectUser ? orderImportError.manualRejectUser.personName : undefined;
      }
    );
  }

  rejectOrderManually(id: number) {
    this.orderImportErrorService.rejectManually({id: id}).subscribe(() => {
      this.loadModel();
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutShort,
        type: UiConstants.toastTypeSuccess,
        title: this.translateService.instant(StringKey.ORDER_IMPORT_ERROR_SUCCESSFUL_REJECT)
      });
    })
  }

  get externalId1StringKey(): string {
    switch (this.model.realm) {
      case 'PROSPEDITION':
        return 'ORDER_IMPORT_ERROR_ORDER_ID';
      default:
        return 'ORDER_IMPORT_ERROR_EXTERNAL_ID1';
    }
  }

  get extraId1StringKey(): string {
    switch (this.model.realm) {
      case 'PROSPEDITION':
        return 'ORDER_IMPORT_ERROR_DELIVERY_ID';
      default:
        return 'ORDER_IMPORT_ERROR_EXTRA_ID1';
    }
  }

}
