<div class="modal-content">
  <div class="modal-header">
    <p class="modal-title"><strong>{{'MASTER_DATA_RECORD_SELECTOR_DIALOG_TITLE' | translate}}</strong></p>
    <button type="button" class="close" (click)="closeDialog()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="filter-outer-container">
      <div class="filter-container">
        <div class="d-flex justify-content-between align-items-center cursor-pointer" (click)="toggleFilter()">
          <span class="filter-title">{{'STOCK_RECORD_SELECTOR_FILTER_TITLE' | translate}}</span>
          <div class="position-relative">
            <i class="icomoon icomoon-arrow-up filter-icon" [class.filter-icon-flipped]="showFilter"></i>
            <div class="filter-applied-indicator" *ngIf="filterAppliedIndicatorVisible"></div>
          </div>
        </div>
        <div *ngIf="showFilter">
          <div class="row">
            <div class="col-md-6 col-sm-12 form-group">
              <label class="search-label">{{'MASTER_DATA_SELECTOR_FILTER' | translate}}</label>
              <input type="text" class="form-control" placeholder="{{'MASTER_DATA_SELECTOR_FILTER' | translate}}"
                     maxlength="{{UiConstants.maxInputLongLength}}"
                     (keyup.enter)="load(1)"
                     [(ngModel)]="filterModel.q">
            </div>
            <div class="col-md-3 form-group" *ngIf="rightModel.customerRecordRead.hasRight()">
              <label class="search-label">{{'STOCK_CREATE_EDIT_CUSTOMER_RECORD'|translate}}</label>
              <angular2-multiselect
                class="form-control"
                [(ngModel)]="filterModel.customerRecord"
                [ngModelOptions]="{standalone: true}"
                (ngModelChange)="load(1)"
                [data]="customerRecords"
                (onRemoteSearch)="searchCustomerRecord($event.target.value)"
                (onSelect)="onCustomerRecordChanged()"
                (onDeSelect)="onCustomerRecordChanged()"
                [settings]="customerDropdownSettings">
              </angular2-multiselect>
            </div>
            <div class="col-md-3 form-group" *ngIf="rightModel.customerRecordRead.hasRight() && filterModel.customerRecord.length === 1">
              <label class="search-label">{{'STOCK_CREATE_EDIT_CONTACT_LOCATION'|translate}}</label>
              <angular2-multiselect
                class="form-control"
                [(ngModel)]="filterModel.contactLocation"
                (ngModelChange)="load(1)"
                [ngModelOptions]="{standalone: true}"
                [data]="contactLocations"
                (onRemoteSearch)="searchContactLocation($event.target.value)"
                [settings]="customerDropdownSettings">
              </angular2-multiselect>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between record-header">
      <span class="record-header-title">{{'MASTER_DATA_RECORD_SELECTOR_DIALOG_RECORDS_TITLE' | translate}}</span>
      <div class="row m-0">
        <div class="record-number-of-items hidden-xs-down">
          <span
            class="record-number-of-items-title">{{'SELECTOR_DIALOG_NUMBER_OF_ITEMS' | translate}}</span>
          <span class="record-number-of-items-option" *ngFor="let option of numberOfItemsOptions; let last = last"
                [class.record-number-of-items-option-active]="queryModel.itemsPerPage === option"
                [class.mr-0]="last"
                (click)="onItemsPerPageChange(option)">
              {{option}}
            </span>
        </div>
      </div>
    </div>
    <div class="list-container" *ngIf="masterDataRecords.length > 0">
      <table class="table table-striped table-bordered mb-0">
        <thead>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="MasterDataRecord.OrderField.NAME"
                                    [orderType]="queryModel.getOrderType(MasterDataRecord.OrderField.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="MasterDataRecord.OrderField.EXTERNAL_ID"
                                    [orderType]="queryModel.getOrderType(MasterDataRecord.OrderField.EXTERNAL_ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_EXTERNAL_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-field-sorter [orderField]="MasterDataRecord.OrderField.DESCRIPTION"
                                    [orderType]="queryModel.getOrderType(MasterDataRecord.OrderField.DESCRIPTION)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_DESCRIPTION' | translate"></app-table-field-sorter>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of masterDataRecords | paginate: {
                          id: pagingId,
                          itemsPerPage: queryModel.itemsPerPage,
                          currentPage: queryModel.currentPage,
                          totalItems: queryModel.totalNumberOfItems }">
          <td class="responsive-table-column align-middle">
            <div class="d-flex align-items-center">
              <app-animated-checkbox
                class="mr-05"
                (clickEvent)="toggleSelected(item)"
                [selected]="item.selected">
              </app-animated-checkbox>
              <div>
                <div>{{item.model.name}}</div>
              </div>
            </div>
          </td>
          <td class="responsive-table-column">{{item.model.externalId}}</td>
          <td class="responsive-table-column hidden-lg-down">{{item.model.description}}</td>
        </tr>
        </tbody>
      </table>
      <div class="pagination-container">
        <app-table-paging
          [id]="pagingId"
          [currentNumberOfItems]="queryModel.currentNumberOfItems"
          [totalNumberOfItems]="queryModel.totalNumberOfItems"
          [itemsPerPage]="queryModel.itemsPerPage"
          (pageChange)="onPageChange($event)"
          (itemsPerPageChange)="onItemsPerPageChange($event)">
        </app-table-paging>
      </div>
    </div>
    <div class="empty-container" *ngIf="masterDataRecords.length === 0">
      <p class="empty-title">{{'MASTER_DATA_RECORD_SELECTOR_DIALOG_RECORDS_EMPTY_TITLE' | translate}}</p>
    </div>
    <div class="spinner" *ngIf="loading">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
    <button type="button" class="btn btn-primary save-button" (click)="saveSelection()"
            *ngIf="selectedMasterDataRecords.length > 0">
      {{(selectedMasterDataRecords.length > 1 ? 'MASTER_DATA_RECORD_SELECTOR_DIALOG_SAVE_BUTTON_PLURAL' : 'MASTER_DATA_RECORD_SELECTOR_DIALOG_SAVE_BUTTON_SINGLE')
      | translate: {count: selectedMasterDataRecords.length} }}
    </button>
  </div>
</div>
