import { Injectable } from '@angular/core';
import { MultiselectProvider } from '../multiselect-provider';
import { MultiselectOptionItem, UiConstants } from '../../util/core-utils';
import { Observable, Observer } from 'rxjs';
import { OrderType, ResourceQueryResult, Services } from '../util/services';
import { Stock, StockQuery, StockService } from './stock.service';
import { Set } from 'immutable';
import { StockTypeName } from '../../util/stock/stock-utils';

@Injectable()
export class StockMultiselectProvider implements MultiselectProvider {

  private multiselectQueryRequest: StockQuery = {
    page_number: 1,
    number_of_items: UiConstants.autocompletePageSize,
    no_progress_bar: true,
    order: Services.createOrderFieldParameter(Stock.Keys.toOrderFieldKey,
      Set.of({field: Stock.OrderField.NAME, type: OrderType.ASC})),
    disabled: undefined,
    fields: ['id', 'name', 'type', 'company_id', 'owner_user_ids'].join(','),
    q: undefined
  };

  constructor(private stockService: StockService) {
  }

  load(queryRequest: StockQuery): Observable<StockMultiselectOptionItem[]> {
    return Observable.create((observer: Observer<StockMultiselectOptionItem[]>) => {
      this.stockService.query(queryRequest).subscribe((result: ResourceQueryResult<Stock>) => {
          observer.next(result.items.map(item => this.toMultiselectOptionItem(item!)));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  loadAll(predicate?: string): Observable<StockMultiselectOptionItem[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.q = predicate;
    return this.load(queryRequest);
  }

  loadActive(predicate?: string): Observable<StockMultiselectOptionItem[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.q = predicate;
    queryRequest.disabled = false;
    return this.load(queryRequest);
  }

  getById(id: number): Observable<StockMultiselectOptionItem> {
    return Observable.create((observer: Observer<StockMultiselectOptionItem>) => {
      this.stockService.get({
        id: id
      }).subscribe((result: Stock) => {
          observer.next(this.toMultiselectOptionItem(result!));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  getByIds(ids: number[]): Observable<StockMultiselectOptionItem[]> {
    const queryRequest = Object.assign([], this.multiselectQueryRequest);
    queryRequest.id = ids.join(',');
    return this.load(queryRequest);
  }

  toMultiselectOptionItem(item: Stock): StockMultiselectOptionItem {
    const ret = new StockMultiselectOptionItem();
    ret.id = item.id;
    ret.itemName = item.name;
    ret.disabled = item.disabled;
    ret.type = item.type;
    ret.companyId = item.company_id ? item.company_id : undefined;
    ret.ownerUserIds = item.owner_user_ids;
    return ret;
  }

}

export class StockMultiselectOptionItem extends MultiselectOptionItem<number> {
  ownerUserIds: number[];
  companyId?: number;
  type: StockTypeName;
}
