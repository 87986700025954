<div>
  <table class="table table-striped table-bordered">
    <thead>
    <tr>
      <th class="responsive-table-column w-5 hidden-sm-down"></th>
      <th class="responsive-table-column">{{'STOCK_RECORD_LOT_INTAKE_DATE' | translate}}</th>
      <th class="responsive-table-column hidden-sm-down">{{'STOCK_RECORD_AMOUNT' | translate}}</th>
      <th class="responsive-table-column hidden-lg-down">{{'STOCK_RECORD_LOT_INTAKE_PRICE' | translate}}</th>
      <th class="responsive-table-column hidden-lg-down">{{'STOCK_RECORD_LOT_INTAKE_PRICE_SUM' | translate}}</th>
      <th class="w-table-options-menu">
      </th>
    </tr>
    </thead>
    <tr *ngFor="let lot of lots">
      <td class="responsive-table-column w-5 text-center align-middle hidden-sm-down">{{lot.id}}</td>
      <td class="responsive-table-column align-middle">{{lot.intake_date | date:'shortDate'}}
        <div class="table-text-subtitle">{{lot.shipping_number}}</div>
      </td>
      <td
        class="responsive-table-column align-middle hidden-sm-down">{{lot.amount | formattedNumber}} {{stockRecord.stock_item.unit}}
        <div *ngIf="stockRecord.stock_item.package_data"
             class="table-text-subtitle">{{StockItemUtils.convertToPackageStr(lot.amount, stockRecord.stock_item.package_data)}}</div>
      </td>
      <td
        class="responsive-table-column align-middle hidden-lg-down">{{lot.intake_unit_net_price | formattedNumber}} {{getCurrencyName(stockRecord.stock_item.unit_price.currency_code)}}</td>
      <td
        class="responsive-table-column align-middle hidden-lg-down font-weight-bold">{{calcSumPrice(lot.amount, lot.intake_unit_net_price) | formattedNumber}} {{getCurrencyName(stockRecord.stock_item.unit_price.currency_code)}}</td>
      <td class="align-middle"
      >
        <app-table-options-menu>
          <app-dropdown-item
            *ngIf="!readonly"
            [iconClass]="'icomoon-modify'"
            [titleStringKey]="'COMMON_BUTTON_EDIT'"
            class="cursor-pointer"
            (click)="onEditClicked(lot)">
          </app-dropdown-item>
        </app-table-options-menu>
      </td>

    </tr>

  </table>
</div>
