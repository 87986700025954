<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div *ngIf="model" class="breadcrumb-menu d-flex">
    <div *ngIf="model.orderState === 'DRAFT'"
         class="breadcrumb-button-icon-container cursor-pointer negative-margin-right"
         (click)="resetData()">
      <a class="btn-setting" title="{{'ORDER_EDIT_DELETE_DATA' | translate}}">
        <i class="icomoon icomoon-trash"></i> {{'ORDER_EDIT_DELETE_DATA' | translate}}
      </a>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>

<div class="container-custom-padding-outer animated fadeIn">
  <responsive-tabset class="fullscreen_tab">
      <responsive-tab heading="{{'COMMON_BASIC_DATA'|translate}}">

        <form class="form-horizontal bordered-row" [formGroup]="orderEditForm" *ngIf="model" #f="ngForm">

          <div class="row ml-0 mr-0">

            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 card-custom-padding">

                <div class="container-vertical-padding-half">
                  <!--BASIC DATA-->
                  <div class="card">
                    <div class="card-header">
                      {{'COMMON_BASIC_DATA' | translate}}
                    </div>

                    <div class="card-body">

                      <div class="form-group row"
                           [ngClass]="{ 'has-danger': (formSubmitted
                                       && !orderEditForm.controls['externalId'].valid)
                                       || (fieldErrors.external_id)}">
                        <div class="col-md-4">
                          <label
                            class="col-form-label mw-100 form-control-label">{{'ORDER_LABEL_EXTERNAL_ID'|translate}}</label>
                        </div>
                        <div class="col-md-8">
                          <input type="text" class="form-control" placeholder="{{'ORDER_LABEL_EXTERNAL_ID' | translate}}"
                                 maxlength="{{UiConstants.maximumVarcharLength}}"
                                 [(ngModel)]="model.externalId"
                                 formControlName="externalId"
                                 (ngModelChange)="removeFieldError(fieldErrors.external_id)">
                          <div class="form-control-feedback" *ngIf="orderEditForm.controls['externalId'].touched
                                                     && orderEditForm.controls['externalId'].hasError('required')">
                            {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                          </div>
                          <div class="form-control-feedback" *ngIf="fieldErrors.external_id">
                            {{fieldErrors.external_id.text}}
                          </div>
                        </div>
                      </div>

                      <div class="form-group row">
                        <div class="col-md-4">
                          <label
                            class="col-form-label mw-100 form-control-label">{{'ORDER_SEARCH_SHIPMENT_TRACKING_NUMBER'|translate}}</label>
                        </div>
                        <div class="col-md-8">
                          <input type="text" class="form-control" placeholder="{{'ORDER_SEARCH_SHIPMENT_TRACKING_NUMBER' | translate}}"
                                 maxlength="{{UiConstants.maximumVarcharLength}}"
                                 [(ngModel)]="model.shipmentTrackingNumber"
                                 [ngModelOptions]="{standalone: true}">
                        </div>
                      </div>

                      <div class="form-group row">
                        <label class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_DESCRIPTION' |
                            translate}}</label>
                        <div class="col-md-8">
                          <input type="text" class="form-control" placeholder="{{'COMMON_DESCRIPTION' | translate}}"
                                 maxlength="{{UiConstants.maximumVarcharLength}}"
                                 [(ngModel)]="model.description" name="description" id="description" #description="ngModel"
                                 [ngModelOptions]="{standalone: true}">
                        </div>
                      </div>

                      <div class="form-group row">
                        <label class="col-form-label form-control-label col-md-4 detail-title">{{'ORDER_LABEL_SHIPPING_STATE' |
                            translate}}</label>
                        <div class="col-md-8">
                          <input type="text" class="form-control" placeholder="{{'ORDER_LABEL_SHIPPING_STATE' | translate}}"
                                 maxlength="{{UiConstants.maximumVarcharLength}}"
                                 [(ngModel)]="model.shippingState" formControlName="shippingState"
                                 [matAutocomplete]="shippingStateAutoGroup">
                        </div>
                        <mat-autocomplete #shippingStateAutoGroup="matAutocomplete">
                          <mat-option *ngFor="let shippingStateOption of shippingStateOptions$ | async" [value]="shippingStateOption">
                            {{shippingStateOption}}
                          </mat-option>
                        </mat-autocomplete>
                      </div>

                      <div class="form-group form-group-no-margin-md-down row"
                           [ngClass]="{ 'has-danger': (formSubmitted
                                       && !orderEditForm.controls['insurancePrice'].valid)
                                       || (fieldErrors.insurance_price)}">
                        <label class="col-form-label form-control-label col-md-4 detail-title">{{'ORDER_LABEL_INSURANCE_PRICE'
                            | translate}}</label>
                        <div class="col-md-8">
                          <div class="row">
                            <div class="col-md-8 padding-15-15-for-col-md pr-0">
                              <input type="text" class="form-control"
                                     placeholder="{{'ORDER_LABEL_INSURANCE_PRICE' | translate}}"
                                     maxlength="{{UiConstants.maxInputBigDecimalLengthWithSpaces}}"
                                     [textMask]="{mask: InputMask.NATURAL_FLOAT_WITH_THOUSAND_SEPARATOR, guide: false}"
                                     [(ngModel)]="model.insurancePriceAmount"
                                     formControlName="insurancePrice"
                                     (ngModelChange)="onInsurancePriceChanged()">
                            </div>
                            <!-- /.col-md-8 -->
                            <div class="col-md-4 padding-15-15-for-col-md">
                              <select class="form-control" [compareWith]="SelectUtils.compareObjects"
                                      [(ngModel)]="model.insurancePriceCurrencyCode"
                                      (ngModelChange)="onInsurancePriceChanged()"
                                      id="insurancePriceCurrency" name="insurancePriceCurrency"
                                      #insurancePriceCurrency="ngModel" [ngModelOptions]="{standalone: true}" disabled>
                                <option *ngFor="let item of selectableInsuranceCurrencies" [ngValue]="item">{{item}}</option>
                              </select>
                            </div>
                            <!-- /.col-md-4 -->
                          </div>
                          <div class="form-control-feedback" *ngIf="orderEditForm.controls['insurancePrice'].touched
                                                     && orderEditForm.controls['insurancePrice'].hasError('numberNotPositive')">
                            {{'COMMON_VALIDATION_MESSAGE_NUMBER_NOT_POSITIVE' | translate}}
                          </div>
                          <div class="form-control-feedback" *ngIf="formSubmitted
                                                     && orderEditForm.controls['insurancePrice'].hasError('validateMaxNumber')">
                            {{'COMMON_VALIDATION_MESSAGE_MAX' | translate: {maxValue: orderEditForm.controls['insurancePrice'].errors['validateMaxNumber']['max'] + ' ' + model.deliveryMethod[0].maxInsurancePrice.currencyCode} }}
                          </div>
                        </div>
                      </div>

                      <div class="form-group form-group-no-margin-md-down row"
                           [ngClass]="{ 'has-danger': (formSubmitted
                                       && !orderEditForm.controls['cashOnDeliveryPrice'].valid)
                                       || (fieldErrors.cash_on_delivery_price)}">
                        <label class="col-form-label form-control-label col-md-4 detail-title">{{'ORDER_LABEL_CASH_ON_DELIVERY_PRICE'
                            | translate}}</label>
                        <div class="col-md-8">
                          <div class="row">
                            <div class="col-md-8 padding-15-15-for-col-md pr-0">
                              <input type="text" class="form-control"
                                     placeholder="{{'ORDER_LABEL_CASH_ON_DELIVERY_PRICE' | translate}}"
                                     maxlength="{{UiConstants.maxInputBigDecimalLengthWithSpaces}}"
                                     [textMask]="{mask: InputMask.NATURAL_FLOAT_WITH_THOUSAND_SEPARATOR, guide: false}"
                                     [(ngModel)]="model.cashOnDeliveryPriceAmount"
                                     formControlName="cashOnDeliveryPrice"
                                     (ngModelChange)="onCashOnDeliveryPriceChanged()">
                            </div>
                            <!-- /.col-md-8 -->
                            <div class="col-md-4 padding-15-15-for-col-md">
                              <select class="form-control" [compareWith]="SelectUtils.compareStrings"
                                      [(ngModel)]="model.cashOnDeliveryPriceCurrencyCode"
                                      (ngModelChange)="onCashOnDeliveryPriceChanged()"
                                      id="sizeUnit" name="sizeUnit"
                                      #sizeUnit="ngModel" [ngModelOptions]="{standalone: true}">
                                <option *ngFor="let item of selectableCashOnDeliveryCurrencies" [ngValue]="item">{{item}}</option>
                              </select>
                            </div>
                            <!-- /.col-md-4 -->
                          </div>
                          <div class="form-control-feedback" *ngIf="formSubmitted
                                                     && orderEditForm.controls['cashOnDeliveryPrice'].hasError('required')">
                            {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                          </div>
                          <div class="form-control-feedback" *ngIf="formSubmitted
                                                     && orderEditForm.controls['cashOnDeliveryPrice'].hasError('numberNotPositive')">
                            {{'COMMON_VALIDATION_MESSAGE_NUMBER_NOT_POSITIVE' | translate}}
                          </div>
                          <div class="form-control-feedback" *ngIf="formSubmitted
                                                     && orderEditForm.controls['cashOnDeliveryPrice'].hasError('validateMaxNumber')">
                            {{'COMMON_VALIDATION_MESSAGE_MAX' | translate: {maxValue: orderEditForm.controls['cashOnDeliveryPrice'].errors['validateMaxNumber']['max'] + ' ' + model.deliveryMethod[0].maxCashOnDeliveryPrice.currencyCode} }}
                          </div>
                        </div>
                      </div>

                      <div class="form-group row">
                        <label class="col-form-label form-control-label col-md-4 detail-title">{{'ORDER_LABEL_CUSTOMER_NOTE' |
                            translate}}</label>
                        <div class="col-md-8">
                          <input type="text" class="form-control" placeholder="{{'ORDER_LABEL_CUSTOMER_NOTE' | translate}}"
                                 maxlength="{{UiConstants.maximumVarcharLength}}"
                                 [(ngModel)]="model.customerNote" name="customerNote" id="customerNote" #customerNote="ngModel"
                                 [ngModelOptions]="{standalone: true}">
                        </div>
                      </div>

                      <div class="form-group row" *ngIf="rightModel.orderNoteExtraRead.hasRightForOrder(order)">
                        <label class="col-form-label form-control-label col-md-4 detail-title">{{'ORDER_LABEL_EXTRA_NOTE' |
                            translate}}</label>
                        <div class="col-md-8">
                          <input *ngIf="rightModel.orderNoteExtraUpdate.hasRightForOrder(order) && extraNoteEditable()"
                                 maxlength="{{UiConstants.maximumVarcharLength}}"
                                 type="text" class="form-control" placeholder="{{'ORDER_LABEL_EXTRA_NOTE' | translate}}"
                                 [(ngModel)]="model.extraNote" name="extraNote" id="extraNote" #extraNote ="ngModel"
                                 [ngModelOptions]="{standalone: true}">
                          <label *ngIf="!rightModel.orderNoteExtraUpdate.hasRightForOrder(order) || !extraNoteEditable()"
                                 readonly class="col-form-label form-control-label detail-description">
                            {{ model.extraNote }}</label>
                        </div>
                      </div>

                      <div class="form-group row" *ngIf="rightModel.orderNoteInternalRead.hasRightForOrder(order)">
                        <label class="col-form-label form-control-label col-md-4 detail-title">{{'ORDER_LABEL_INTERNAL_NOTE' |
                            translate}}</label>
                        <div class="col-md-8">
                          <input *ngIf="rightModel.orderNoteInternalUpdate.hasRightForOrder(order) && internalNoteEditable()"
                                 maxlength="{{UiConstants.maximumVarcharLength}}"
                                 type="text" class="form-control" placeholder="{{'ORDER_LABEL_INTERNAL_NOTE' | translate}}"
                                 [(ngModel)]="model.internalNote" name="internalNote" id="internalNote" #internalNote="ngModel"
                                 [ngModelOptions]="{standalone: true}">
                          <label *ngIf="!rightModel.orderNoteInternalUpdate.hasRightForOrder(order) || !internalNoteEditable()"
                                 readonly class="col-form-label form-control-label detail-description">
                            {{ model.internalNote }}</label>
                        </div>
                      </div>

                      <h6 class="mb-1 mt-2">{{'ORDER_EDIT_PACKAGE_ATTRIBUTES' | translate}}</h6>

                      <div class="form-group row"
                           [ngClass]="{ 'has-danger': (formSubmitted
                                       && !orderEditForm.controls['packageSizeWidth'].valid)
                                       || fieldErrors.package_size_width ||
                                       (!(orderEditForm.controls['packageSizeWidth'].value === null
                                         && orderEditForm.controls['packageSizeHeight'].value === null
                                         && orderEditForm.controls['packageSizeDepth'].value === null)
                                       && !(orderEditForm.controls['packageSizeWidth'].value !== null
                                         && orderEditForm.controls['packageSizeHeight'].value !== null
                                         && orderEditForm.controls['packageSizeDepth'].value !== null))}">
                        <label class="col-form-label form-control-label col-md-4 detail-title">{{'ORDER_LABEL_SIZE_WIDTH'
                            | translate}}</label>
                        <div class="col-md-8">
                          <div class="row">
                            <div class="col-md-8 pr-md-0">
                              <div class="row">
                                <div class="col-md padding-15-15-for-col-md mb-md-0">
                                  <input type="text" class="form-control"
                                         placeholder="{{'ORDER_LABEL_SIZE_WIDTH' | translate}}"
                                         [textMask]="{mask: InputMask.NATURAL_FLOAT_WITH_THOUSAND_SEPARATOR, guide: false}"
                                         [(ngModel)]="model.packageSizeWidth"
                                         formControlName="packageSizeWidth"
                                         (ngModelChange)="onSizeWidthChanged()">
                                </div>
                              </div>
                              <!-- /.row -->
                            </div>
                            <!-- /.col-md-8 -->
                            <div class="col-md-4">
                              <select class="form-control" [compareWith]="SelectUtils.compareStrings"
                                      [(ngModel)]="packageSizeUnit"
                                      (ngModelChange)="onSizeChanged()"
                                      id="packageUnitWidth" name="packageUnitWidth"
                                      #packageUnitWidth="ngModel" [ngModelOptions]="{standalone: true}">
                                <option *ngFor="let item of selectableLengthUnits" [ngValue]="item">{{item}}</option>
                              </select>
                            </div>
                            <!-- /.col-md-4 -->
                          </div>
                          <div class="form-control-feedback" *ngIf="formSubmitted
                                                     && orderEditForm.controls['packageSizeWidth'].hasError('numberNotPositive')">
                            {{'COMMON_VALIDATION_MESSAGE_NUMBER_NOT_POSITIVE' | translate}}
                          </div>
                          <div class="form-control-feedback" *ngIf="formSubmitted
                                                     && orderEditForm.controls['packageSizeWidth'].hasError('required')">
                            {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                          </div>
                          <div class="form-control-feedback" *ngIf="formSubmitted
                                                     && orderEditForm.controls['packageSizeWidth'].hasError('validateMaxNumber')">
                            {{'COMMON_VALIDATION_MESSAGE_MAX' | translate: {maxValue: LengthFactory.createLengthFromMeters(orderEditForm.controls['packageSizeWidth'].errors['validateMaxNumber']['max'], packageSizeUnit).toString(10)} }}
                          </div>
                        </div>
                      </div>

                      <div class="form-group row"
                           [ngClass]="{ 'has-danger': (formSubmitted
                                       && !orderEditForm.controls['packageSizeHeight'].valid)
                                       || fieldErrors.package_size_height ||
                                       (!(orderEditForm.controls['packageSizeWidth'].value === null
                                         && orderEditForm.controls['packageSizeHeight'].value === null
                                         && orderEditForm.controls['packageSizeDepth'].value === null)
                                       && !(orderEditForm.controls['packageSizeWidth'].value !== null
                                         && orderEditForm.controls['packageSizeHeight'].value !== null
                                         && orderEditForm.controls['packageSizeDepth'].value !== null))}">
                        <label class="col-form-label form-control-label col-md-4 detail-title">{{'ORDER_LABEL_SIZE_HEIGHT'
                            | translate}}</label>
                        <div class="col-md-8">
                          <div class="row">
                            <div class="col-md-8 pr-md-0">
                              <div class="row">
                                <div class="col-md padding-15-15-for-col-md mb-md-0">
                                  <input type="text" class="form-control"
                                         placeholder="{{'ORDER_LABEL_SIZE_HEIGHT' | translate}}"
                                         [textMask]="{mask: InputMask.NATURAL_FLOAT_WITH_THOUSAND_SEPARATOR, guide: false}"
                                         [(ngModel)]="model.packageSizeHeight"
                                         formControlName="packageSizeHeight"
                                         (ngModelChange)="onSizeHeightChanged()">
                                </div>
                              </div>
                              <!-- /.row -->
                            </div>
                            <!-- /.col-md-8 -->
                            <div class="col-md-4">
                              <select class="form-control" [compareWith]="SelectUtils.compareStrings"
                                      [(ngModel)]="packageSizeUnit"
                                      (ngModelChange)="onSizeChanged()"
                                      id="packageUnitHeight" name="packageUnitWidth"
                                      #packageUnitHeight="ngModel" [ngModelOptions]="{standalone: true}">
                                <option *ngFor="let item of selectableLengthUnits" [ngValue]="item">{{item}}</option>
                              </select>
                            </div>
                            <!-- /.col-md-4 -->
                          </div>
                          <div class="form-control-feedback" *ngIf="formSubmitted
                                                     && orderEditForm.controls['packageSizeHeight'].hasError('numberNotPositive')">
                            {{'COMMON_VALIDATION_MESSAGE_NUMBER_NOT_POSITIVE' | translate}}
                          </div>
                          <div class="form-control-feedback" *ngIf="formSubmitted
                                                     && orderEditForm.controls['packageSizeHeight'].hasError('required')">
                            {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                          </div>
                          <div class="form-control-feedback" *ngIf="formSubmitted
                                                     && orderEditForm.controls['packageSizeHeight'].hasError('validateMaxNumber')">
                            {{'COMMON_VALIDATION_MESSAGE_MAX' | translate: {maxValue: LengthFactory.createLengthFromMeters(orderEditForm.controls['packageSizeHeight'].errors['validateMaxNumber']['max'], packageSizeUnit).toString(10)} }}
                          </div>
                        </div>
                      </div>

                      <div class="form-group row"
                           [ngClass]="{ 'has-danger': (formSubmitted
                                       && !orderEditForm.controls['packageSizeDepth'].valid)
                                       || fieldErrors.package_size_depth ||
                                       (!(orderEditForm.controls['packageSizeWidth'].value === null
                                         && orderEditForm.controls['packageSizeHeight'].value === null
                                         && orderEditForm.controls['packageSizeDepth'].value === null)
                                       && !(orderEditForm.controls['packageSizeWidth'].value !== null
                                         && orderEditForm.controls['packageSizeHeight'].value !== null
                                         && orderEditForm.controls['packageSizeDepth'].value !== null))}">
                        <label class="col-form-label form-control-label col-md-4 detail-title">{{'ORDER_LABEL_SIZE_DEPTH'
                            | translate}}</label>
                        <div class="col-md-8">
                          <div class="row">
                            <div class="col-md-8 pr-md-0">
                              <div class="row">
                                <div class="col-md padding-15-15-for-col-md mb-md-0">
                                  <input type="text" class="form-control"
                                         placeholder="{{'ORDER_LABEL_SIZE_DEPTH' | translate}}"
                                         [textMask]="{mask: InputMask.NATURAL_FLOAT_WITH_THOUSAND_SEPARATOR, guide: false}"
                                         [(ngModel)]="model.packageSizeDepth"
                                         formControlName="packageSizeDepth"
                                         (ngModelChange)="onSizeDepthChanged()">
                                </div>
                              </div>
                              <!-- /.row -->
                            </div>
                            <!-- /.col-md-8 -->
                            <div class="col-md-4">
                              <select class="form-control" [compareWith]="SelectUtils.compareStrings"
                                      [(ngModel)]="packageSizeUnit"
                                      (ngModelChange)="onSizeChanged()"
                                      id="packageUnitDepth" name="packageUnitWidth"
                                      #packageUnitWidth="ngModel" [ngModelOptions]="{standalone: true}">
                                <option *ngFor="let item of selectableLengthUnits" [ngValue]="item">{{item}}</option>
                              </select>
                            </div>
                            <!-- /.col-md-4 -->
                          </div>
                          <div class="form-control-feedback" *ngIf="formSubmitted
                                                     && orderEditForm.controls['packageSizeDepth'].hasError('numberNotPositive')">
                            {{'COMMON_VALIDATION_MESSAGE_NUMBER_NOT_POSITIVE' | translate}}
                          </div>
                          <div class="form-control-feedback" *ngIf="formSubmitted
                                                     && orderEditForm.controls['packageSizeDepth'].hasError('required')">
                            {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                          </div>
                          <div class="form-control-feedback" *ngIf="formSubmitted
                                                     && orderEditForm.controls['packageSizeDepth'].hasError('validateMaxNumber')">
                            {{'COMMON_VALIDATION_MESSAGE_MAX' | translate: {maxValue: LengthFactory.createLengthFromMeters(orderEditForm.controls['packageSizeDepth'].errors['validateMaxNumber']['max'], packageSizeUnit).toString(10)} }}
                          </div>
                          <div class="form-control-feedback" *ngIf="(!(this.orderEditForm.controls['packageSizeWidth'].value === null
                                                                && this.orderEditForm.controls['packageSizeHeight'].value === null
                                                                && this.orderEditForm.controls['packageSizeDepth'].value === null)
                                                              && !(this.orderEditForm.controls['packageSizeWidth'].value !== null
                                                                && this.orderEditForm.controls['packageSizeHeight'].value !== null
                                                                && this.orderEditForm.controls['packageSizeDepth'].value !== null))">
                            {{'ORDER_VALIDATION_MESSAGE_PACKAGE_SIZE' | translate}}
                          </div>
                        </div>
                      </div>

                      <div class="form-group row"
                           [ngClass]="{ 'has-danger': (formSubmitted
                                       && !orderEditForm.controls['weightInGram'].valid)
                                       || (fieldErrors.weight_in_gram)}">
                        <label class="col-form-label form-control-label col-md-4 detail-title">{{'ORDER_LABEL_WEIGHT'
                            | translate}}</label>
                        <div class="col-md-8">
                          <div class="row">
                            <div class="col-md-8 padding-15-15-for-col-md pr-md-0 mb-md-0">
                              <input type="text" class="form-control"
                                     placeholder="{{'ORDER_LABEL_WEIGHT' | translate}}"
                                     [textMask]="{mask: InputMask.NATURAL_FLOAT_WITH_THOUSAND_SEPARATOR, guide: false}"
                                     [(ngModel)]="model.weightInGram"
                                     formControlName="weightInGram"
                                     (ngModelChange)="onWeightInGramChanged()">
                            </div>
                            <!-- /.col-md-8 -->
                            <div class="col-md-4 padding-15-15-for-col-md mb-0">
                              <select class="form-control" [compareWith]="SelectUtils.compareObjects"
                                      [(ngModel)]="packageWeightUnit"
                                      (ngModelChange)="onWeightInGramChanged()"
                                      id="weightUnit" name="weightUnit"
                                      #weightUnit="ngModel" [ngModelOptions]="{standalone: true}">
                                <option *ngFor="let item of selectableWeightUnits" [ngValue]="item">{{item}}</option>
                              </select>
                            </div>
                            <!-- /.col-md-4 -->
                          </div>
                          <div class="form-control-feedback" *ngIf="formSubmitted
                                                     && orderEditForm.controls['weightInGram'].hasError('numberNotPositive')">
                            {{'COMMON_VALIDATION_MESSAGE_NUMBER_NOT_POSITIVE' | translate}}
                          </div>
                          <div class="form-control-feedback" *ngIf="formSubmitted
                                                     && orderEditForm.controls['weightInGram'].hasError('required')">
                            {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                          </div>
                          <div class="form-control-feedback" *ngIf="formSubmitted
                                                     && orderEditForm.controls['weightInGram'].hasError('validateMaxNumber')">
                            {{'COMMON_VALIDATION_MESSAGE_MAX' | translate: {maxValue: WeightFactory.createWeightFromGram(orderEditForm.controls['weightInGram'].errors['validateMaxNumber']['max'], packageWeightUnit).toString(10)} }}
                          </div>
                          <div class="form-control-feedback" *ngIf="fieldErrors.weight_in_gram">
                            {{fieldErrors.weight_in_gram.text}}
                          </div>
                        </div>
                      </div>

                      <div class="form-group row mb-0">
                        <label class="col-form-label form-control-label col-md-4 detail-title">{{'ORDER_LABEL_OTHER_DATA'
                            | translate}}</label>
                        <div class="col-md-8">
                          <angular2-multiselect
                            [(ngModel)]="selectedOtherData"
                            [ngModelOptions]="{standalone: true}"
                            [data]="otherData"
                            [settings]="otherDataDropdownSettings">
                          </angular2-multiselect>
                        </div>
                      </div>

                    </div> <!--./card-body-->
                  </div>
                  <!--./BASIC DATA-->
                </div>

            </div>

            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 card-custom-padding">

              <div class="container-vertical-padding-half">

                <!--RECIPIENT DATA-->
                <div class="card">
                  <div class="card-header">
                    {{'ORDER_EDIT_HEADING_RECIPIENT_DATA' | translate}}
                  </div>

                  <div class="card-body">

                    <div class="form-group row"
                         [ngClass]="{ 'has-danger': (formSubmitted
                                       && !orderEditForm.controls['recipientName'].valid)
                                       || (fieldErrors.recipient_name)}">
                      <label class="col-form-label form-control-label col-md-4 detail-title">{{'ORDER_LABEL_RECIPIENT_NAME' |
                        translate}}</label>
                      <div class="col-md-8">
                        <input type="text" class="form-control" placeholder="{{'ORDER_LABEL_RECIPIENT_NAME' | translate}}"
                               maxlength="{{UiConstants.maximumVarcharLength}}"
                               [(ngModel)]="model.recipientName" formControlName="recipientName"
                               (ngModelChange)="removeFieldError(fieldErrors.recipient_name)">
                        <div class="form-control-feedback" *ngIf="formSubmitted
                                                     && orderEditForm.controls['recipientName'].hasError('required')">
                          {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                        </div>
                      </div>
                    </div>

                    <div class="form-group row"
                         [ngClass]="{ 'has-danger': (formSubmitted
                                       && !orderEditForm.controls['recipientPhoneNumber'].valid)
                                       || (fieldErrors.recipient_phone_number)}">
                      <label class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_PHONE_NUMBER'
                        | translate}}</label>
                      <div class="col-md-8">
                        <input type="text" class="form-control" placeholder="{{'COMMON_PHONE_NUMBER' | translate}}"
                               [(ngModel)]="model.recipientPhoneNumber" formControlName="recipientPhoneNumber"
                               (ngModelChange)="removeFieldError(fieldErrors.recipient_phone_number)">
                        <div class="form-control-feedback" *ngIf="formSubmitted
                                                     && orderEditForm.controls['recipientPhoneNumber'].hasError('required')">
                          {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                        </div>
                        <div class="form-control-feedback" *ngIf="formSubmitted
                                                     && orderEditForm.controls['recipientPhoneNumber'].hasError('validatePhoneNumber')">
                          {{'COMMON_VALIDATION_MESSAGE_PHONE' | translate}}
                        </div>
                      </div>
                    </div>

                    <div class="form-group row"
                         [ngClass]="{ 'has-danger': (formSubmitted
                                       && !orderEditForm.controls['recipientEmailAddress'].valid)
                                       || (fieldErrors.recipient_email_address)}">
                      <label class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_EMAIL_ADDRESS'
                        | translate}}</label>
                      <div class="col-md-8">
                        <input type="text" class="form-control"
                               placeholder="{{'COMMON_EMAIL_ADDRESS' | translate}}"
                               [(ngModel)]="model.recipientEmailAddress" formControlName="recipientEmailAddress"
                               (ngModelChange)="removeFieldError(fieldErrors.recipient_email_address)">
                        <div class="form-control-feedback" *ngIf="formSubmitted
                                                     && orderEditForm.controls['recipientEmailAddress'].hasError('required')">
                          {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                        </div>
                        <div class="form-control-feedback" *ngIf="formSubmitted
                                                     && orderEditForm.controls['recipientEmailAddress'].hasError('email')">
                          {{'COMMON_VALIDATION_MESSAGE_EMAIL' | translate}}
                        </div>
                      </div>
                    </div>

                  </div> <!--./card-body-->
                </div>
                <!--./RECIPIENT DATA-->

              </div>
              <!-- /.continer-vertical-padding-half -->

              <div class="container-vertical-padding-half">

                <!--DELIVERY-->
                <div class="card">
                  <div class="card-header">
                    {{'ORDER_EDIT_HEADING_DELIVERY' | translate}}
                  </div>

                  <div class="card-body">

                    <div class="form-group row"
                         [ngClass]="{ 'has-danger': (formSubmitted && !orderEditForm.controls['transporterCompany'].valid) }">
                      <label
                        class="col-form-label form-control-label col-md-4 detail-title">{{'ORDER_EDIT_RECIPIENT_TRANSPORTER_COMPANY' | translate}}</label>
                      <div class="col-md-8">
                        <angular2-multiselect
                          [(ngModel)]="model.transporterCompany"
                          (ngModelChange)="onTransporterCompanyChanged()"
                          [data]="transporterCompanies"
                          (onRemoteSearch)="loadTransporterCompanies($event.target.value)"
                          formControlName="transporterCompany"
                          [settings]="parcelPointDropdownSettings">
                        </angular2-multiselect>
                        <div class="form-control-feedback"
                             *ngIf="formSubmitted && !orderEditForm.controls['transporterCompany'].valid">
                          <div class="form-control-feedback"
                               *ngIf="formSubmitted && !orderEditForm.controls['transporterCompany'].valid">
                            {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row"
                         *ngIf="model.transporterCompanyId"
                         [ngClass]="{ 'has-danger': (formSubmitted && !orderEditForm.controls['deliveryMethod'].valid) }">
                      <label class="col-form-label form-control-label col-md-4 detail-title">{{'ORDER_EDIT_RECIPIENT_DELIVERY_METHOD' | translate}}</label>
                      <div class="col-md-8">
                        <angular2-multiselect
                          [(ngModel)]="model.deliveryMethod"
                          [data]="deliveryMethods"
                          (ngModelChange)="onDeliveryMethodChanged()"
                          (onRemoteSearch)="loadDeliveryMethods($event.target.value)"
                          formControlName="deliveryMethod"
                          [settings]="deliveryMethodDropdownSettings">
                        </angular2-multiselect>
                        <div class="form-control-feedback" *ngIf="formSubmitted && !orderEditForm.controls['deliveryMethod'].valid">
                          <div class="form-control-feedback" *ngIf="formSubmitted && !orderEditForm.controls['deliveryMethod'].valid">
                            {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row"
                         *ngIf="model.deliveryMethod.length > 0 && model.deliveryMethod[0]!.type === 'PARCEL_COLLECTION_POINT'"
                         [ngClass]="{ 'has-danger': (formSubmitted && !orderEditForm.controls['parcelCollectionPoint'].valid) }">
                      <label
                        class="col-form-label form-control-label col-md-4 detail-title">{{'ORDER_EDIT_RECIPIENT_PARCEL_COLLECTION_POINT' | translate}}</label>
                      <div class="col-md-8">
                        <angular2-multiselect
                          [(ngModel)]="model.parcelCollectionPoint"
                          [data]="parcelCollectionPoints"
                          (onRemoteSearch)="loadParcelCollectionPoints($event.target.value)"
                          formControlName="parcelCollectionPoint"
                          [settings]="parcelPointDropdownSettings">
                        </angular2-multiselect>
                        <div class="form-control-feedback"
                             *ngIf="formSubmitted && !orderEditForm.controls['parcelCollectionPoint'].valid">
                          <div class="form-control-feedback"
                               *ngIf="formSubmitted && !orderEditForm.controls['parcelCollectionPoint'].valid">
                            {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row mb-0" *ngIf="model.deliveryMethod.length > 0 && model.deliveryMethod[0]!.type === 'HOME_DELIVERY'">
                      <label class="col-form-label form-control-label col-md-4 detail-title">{{'ORDER_LABEL_RECIPIENT_ADDRESS'
                        |
                        translate}}</label>
                      <div class="col-md-8">
                        <div class="poc-address-display">
                          <app-postal-address-complex
                            [form]="f"
                            [model]="model.recipientDeliveryAddress"
                            [readonly]="false">
                          </app-postal-address-complex>
                        </div>
                        <!-- /.poc-address-display -->
                      </div>
                    </div>

                  </div> <!--./card-body-->
                </div>
                <!--./DELIVERY-->

              </div>
              <!-- /.continer-vertical-padding-half -->

            </div>

          </div>

          <input type="submit" class="btn btn-primary-outline floating_apply_button"
                 (click)="saveBasicData(false, false)" value="{{'COMMON_BUTTON_APPLY'|translate}}"/>
          <input type="submit" class="btn btn-primary floating_save_button"
                 (click)="saveBasicData(false, true)" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>

        </form>

        <div class="container-vertical-padding-half card-custom-padding">

          <!--STOCK ITEMS DATA-->
          <div class="card">
            <div class="card-header">
              {{'ORDER_EDIT_HEADING_STOCK_ITEMS' | translate}}
            </div>

            <div class="card-body">

              <app-order-stock-item-container
                *ngIf="itemsLoaded && model.orderState === 'DRAFT'"
                [orderId]="orderId"
                [orderCompanyId]="order.ownerCompanyId"
                [(items)]="model.items"
                [itemErrors]="submitItemErrors"
                (stockItemsLengthChanged)="onStockItemsLengthChanged($event)">
              </app-order-stock-item-container>

              <div *ngIf="model && model.orderState === 'DRAFT'">
                <div class="d-flex justify-content-end pl-0">
                  <input type="button" class="btn btn-primary" (click)="saveBasicData(true, true)"
                         value="{{'COMMON_BUTTON_FINALIZE'|translate}}"/>
                </div>
              </div>

              <table class="table table-striped table-bordered" *ngIf="stockItemsLoaded && model.orderState !== 'DRAFT'">
                <thead>
                <tr>
                  <th>{{'ORDER_LABEL_ID' | translate}}</th>
                  <th>{{'COMMON_NAME' | translate}}</th>
                  <th class="hidden-sm-down">{{'ORDER_LABEL_EXTERNAL_ID' | translate}}</th>
                  <th class="hidden-sm-down">{{'ORDER_TABLE_HEADER_SERIAL_CODE' | translate}}</th>
                  <th class="hidden-sm-down">{{'ORDER_TABLE_HEADER_UNIT_PRICE' | translate}}</th>
                  <th class="hidden-sm-down">{{'ORDER_TABLE_HEADER_VAT_RATE' | translate}}</th>
                  <th>{{'ORDER_TABLE_HEADER_AMOUNT' | translate}}</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr class="table" *ngFor="let row of stockItems">
                  <td>{{row.itemId}}</td>
                  <td>{{row.name}}</td>
                  <td class="hidden-sm-down">{{row.externalId}}</td>
                  <td class="hidden-sm-down">{{row.serialCode}}</td>
                  <td class="hidden-sm-down">{{row.unitPrice.value | formattedNumber}} {{row.unitPrice.currency_code}}</td>
                  <td class="hidden-sm-down">{{row.unitPrice.vat_rate}}%</td>
                  <td>{{row.amount}} {{row.unit}}</td>
                  <td nowrap>
                    <div class="pull-right">
                      <a class="icons-table-group cursor-pointer" uiSref="Admin.StockItemDetail"
                         [uiParams]="{ id: row.stockItemId }">
                        <i class="icomoon icons-table-item icomoon-detail icomoon-detail-table"
                           title="{{'COMMON_BUTTON_DETAILS' | translate}}"></i>
                      </a>
                    </div>
                  </td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                  <th>{{'ORDER_LABEL_ID' | translate}}</th>
                  <th>{{'COMMON_NAME' | translate}}</th>
                  <th class="hidden-sm-down">{{'ORDER_LABEL_EXTERNAL_ID' | translate}}</th>
                  <th class="hidden-sm-down">{{'ORDER_TABLE_HEADER_SERIAL_CODE' | translate}}</th>
                  <th class="hidden-sm-down">{{'ORDER_TABLE_HEADER_UNIT_PRICE' | translate}}</th>
                  <th class="hidden-sm-down">{{'ORDER_TABLE_HEADER_VAT_RATE' | translate}}</th>
                  <th>{{'ORDER_TABLE_HEADER_AMOUNT' | translate}}</th>
                  <th></th>
                </tr>
                </tfoot>
              </table>

            </div> <!--./card-body-->
          </div>
          <!--./STOCK ITEMS DATA-->

        </div>

      </responsive-tab>
    <responsive-tab heading="{{'COMMON_DOCUMENTS'|translate}}">
      <app-order-document-list
        #orderDocumentList
        [orderId]="orderId"
        (onOrderCreateButtonClicked)="orderDocumentCreateDialog.openOrderDocumentCreateDialog()">
      </app-order-document-list>
    </responsive-tab>
  </responsive-tabset>
</div>

<app-order-document-create-dialog
  #orderDocumentCreateDialog
  [orderId]="orderId"
  (onOrderDocumentCreated)="orderDocumentList.loadList()">
</app-order-document-create-dialog>
