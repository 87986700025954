/* eslint-disable */
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Map as ImmutableMap } from 'immutable';
import { DownloadedFile } from '../util/downloaded-files';
import { Observable } from 'rxjs';
import { ResourceQueryResult } from '../util/services';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { HttpClient } from '@angular/common/http';
import { EmptyMessage, IdentityMessage, ListQuery } from '../util/messages';
import { FieldError } from '../util/errors';
/* eslint-enable */

export namespace ListItemList {

  export enum OrderField {
    ID,
    TYPE_KEY,
    CODE,
    TEXT,
  }

  export class Keys {

    private static readonly ID = 'id';
    private static readonly TYPE_KEY = 'type_key';
    private static readonly CODE = 'code';
    private static readonly TEXT = 'text';

    private static readonly orderFieldKeyMap: ImmutableMap<ListItemList.OrderField, string> = ImmutableMap.of(
      ListItemList.OrderField.ID, Keys.ID,
      ListItemList.OrderField.TYPE_KEY, Keys.TYPE_KEY,
      ListItemList.OrderField.CODE, Keys.CODE,
      ListItemList.OrderField.TEXT, Keys.TEXT,
    );

    public static toOrderFieldKey(field: ListItemList.OrderField): string {
      return Keys.orderFieldKeyMap.get(field)!;
    }

  }

  export enum TypeOrderField {
    ID,
    KEY,
  }

  export class TypeKeys {

    private static readonly ID = 'id';
    private static readonly KEY = 'key';

    private static readonly orderFieldKeyMap: ImmutableMap<ListItemList.TypeOrderField, string> = ImmutableMap.of(
      ListItemList.TypeOrderField.ID, TypeKeys.ID,
      ListItemList.TypeOrderField.KEY, TypeKeys.KEY,
    );

    public static toOrderFieldKey(field: ListItemList.TypeOrderField): string {
      return TypeKeys.orderFieldKeyMap.get(field)!;
    }

  }

}

export class ListItemParam {
  key: string;
  param_type: ListItemParamType;
  string_value?: string;
  number_value?: number;
}

export type ListItemParamType = 'STRING' | 'NUMBER';

export class ListItemParamTypeObject {
  type: ListItemParamType;
  stringKey: string;
}

export const listItemParamTypes: ListItemParamTypeObject[] = [
  {type: 'STRING', stringKey: 'LIST_ITEM_PARAM_TYPE_STRING'},
  {type: 'NUMBER', stringKey: 'LIST_ITEM_PARAM_TYPE_NUMBER'}
];

export class RawListItem {
  id: number;
  code?: string;
  type: string;
  type_id?: number;
  disabled: boolean;
  params: ListItemParam[];
  text: string;
  filter_item_ids: number[];
}

export class ListItemTypeParam {
  key: string;
  param_type: ListItemParamType;
  default_string_value?: string;
  default_number_value?: number;
}

export interface ListItemTypeFilterType {
  id: number;
  key: string;
}

export class RawListTypeItem {
  id: number;
  key: string;
  disabled: boolean;
  params: ListItemTypeParam[];
  filter_type?: ListItemTypeFilterType;
  list_item_order_field: string;
  list_item_order_type: string;
}

export interface ListItemQuery extends ListQuery.Paging, ListQuery.Order, ListQuery.QueryText, ListQuery.Fields {
  id?: string;
  type_text?: string;
  type_key?: string;
  type_id?: number;
  disabled?: boolean;
  type_disabled?: boolean;
  text?: string;
  filter_item_id?: string;
  no_progress_bar?: boolean;
}

export interface ListItemTypeQuery extends ListQuery.Paging, ListQuery.Order, ListQuery.QueryText, ListQuery.Fields {
  key?: string;
  disabled?: boolean;
  no_progress_bar?: boolean;
}

export interface GetListItemTypeByKeyRequest {
  key: string;
}

export interface ListItemCreateRequest {
  code: string,
  type: string,
  params: ListItemParam[],
  text: string,
  filter_item_ids: number[]
}

export interface ListItemTypeCreateRequest {
  key: string,
  params: ListItemTypeParam[],
  filter_type_id?: number,
  list_item_order_field: string,
  list_item_order_type: string,
}

export interface ListItemTypeDisabledRequest {
  id: number,
  disabled: boolean,
}

export interface ListItemDisabledRequest {
  id: number,
  disabled: boolean,
}

export interface ListItemUpdateRequest extends ListItemCreateRequest {
  id: number;
}

export interface ListItemTypeUpdateRequest extends ListItemTypeCreateRequest {
  id: number;
}

export interface ListItemFieldErrorMap {
  code?: FieldError;
  type?: FieldError;
  text?: FieldError;
}

export interface ListItemTypeFieldErrorMap {
  key?: FieldError;
  params?: FieldError;
}

@Injectable()
export class ListItemResourceService extends BaseHttpService {

  // LIST ITEM

  query(request: {}): Observable<ResourceQueryResult<any>> {
    return this.http.get<ResourceQueryResult<any>>(this.url, this.parseParams(request));
  }

  getMap(): Observable<{type: RawListTypeItem, items: RawListItem[]}[]> {
    return this.http.get<{type: RawListTypeItem, items: RawListItem[]}[]>(this.url + '/map', this.parseParams({
        no_progress_bar: true
      })
    );
  }

  getList(request: ListItemQuery): Observable<ResourceQueryResult<RawListItem>> {
    return this.http.get<ResourceQueryResult<RawListItem>>(this.url, this.parseParams(request));
  }

  get(request: IdentityMessage): Observable<RawListItem> {
    return this.http.get<RawListItem>(this.url + `${request.id}`);
  }

  create(request: ListItemCreateRequest): Observable<EmptyMessage> {
    return this.http.post(this.url, request);
  }

  update(request: ListItemUpdateRequest): Observable<EmptyMessage> {
    return this.http.put(this.url + `${request.id}`, request);
  }

  setDisabledItem(request: ListItemDisabledRequest): Observable<EmptyMessage> {
    return this.http.patch(this.url + `${request.id}/disabled`, request);
  }

  // LIST ITEM TYPE

  createType(request: ListItemTypeCreateRequest): Observable<EmptyMessage> {
    return this.http.post(this.url + `types`, request);
  }

  getTypeList(request: ListItemTypeQuery): Observable<ResourceQueryResult<RawListTypeItem>> {
    return this.http.get<ResourceQueryResult<RawListTypeItem>>(this.url + `types`, this.parseParams(request));
  }

  getType(request: IdentityMessage): Observable<RawListTypeItem> {
    return this.http.get<RawListTypeItem>(this.url + `types/${request.id}`);
  }

  getTypeByKey(request: GetListItemTypeByKeyRequest): Observable<RawListTypeItem> {
    return this.http.get<RawListTypeItem>(this.url + `type-by-key/${request.key}`);
  }

  updateType(request: ListItemTypeUpdateRequest): Observable<EmptyMessage> {
    return this.http.put(this.url + `types/${request.id}`, request);
  }

  setDisabledType(request: ListItemTypeDisabledRequest): Observable<EmptyMessage> {
    return this.http.patch(this.url + `types/${request.id}/disabled`, request);
  }

  typeExportXls(): Observable<DownloadedFile> {
    return this.http.get(this.url + 'types/export-xls', {
      observe: 'response',
      responseType: 'blob'
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  listItemExportXls(request: ListItemQuery): Observable<DownloadedFile> {
    return this.http.get(this.url + 'export-xls', {
      observe: 'response',
      responseType: 'blob',
      params: this.parseParams(request).params
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/list-items/');
  }

}
