import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrderFieldFunction, OrderFieldModel } from '../../../../util/core-utils';
import { OrderField } from '../../../../lib/query/orderfields';
import { OrderType, QueryResult } from '../../../../lib/util/services';
import { MileageRecordModel } from '../../../mileage-record/mileage-record.model';
import { MileageRecord, MileageRecordService } from '../../../../lib/mileage-record/mileage-record.service';
import { Dates } from '../../../../lib/util/dates';
import { SelectUtils, UiConstants } from '../../../../util/core-utils';
import { MileageRecordSearch } from '../../../../lib/mileage-record/mileage-record-search.service';
import { FilterField } from '../../../../lib/query/filterfields';
import { CriteriaBuilder } from '../../../../util/model-utils';
import { DownloadedFile } from '../../../../lib/util/downloaded-files';
import { saveAs } from 'file-saver';
import { RightModel } from '../../../../app.rights';
import { ConfigurationService } from '../../../../lib/core-ext/configuration.service';

@Component({
  selector: 'app-task-record-mileage-record',
  templateUrl: './task-record-mileage-record.component.html',
  styleUrls: ['./task-record-mileage-record.component.scss']
})
export class TaskRecordMileageRecordComponent implements OnInit {

  @Input()
  taskRecordId: number;

  @Input()
  rightModel: RightModel = RightModel.empty();

  @Output()
  onMileageRecordEditClicked: EventEmitter<MileageRecordModel> = new EventEmitter<MileageRecordModel>();

  UiConstants = UiConstants;
  SelectUtils = SelectUtils;
  MileageRecordSearch = MileageRecordSearch;

  queryModel: OrderFieldModel<OrderField.MileageRecord>
    = new OrderFieldModel(MileageRecordSearch.OrderFunctions.ID, OrderType.DESC);

  mileageRecordList: MileageRecordModel[] = [];

  get maconomyEnabled(): boolean {
    return this.configurationService.getConfiguration().feature_flags.maconomy_enabled;
  }

  constructor(
    private mileageRecordService: MileageRecordService,
    private configurationService: ConfigurationService) {
  }

  ngOnInit() {
    this.loadList()
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: OrderFieldFunction<OrderField.MileageRecord>) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    this.mileageRecordService.query(this.getQueryRequest(requestedPage)).subscribe((result: QueryResult<MileageRecord.MileageRecord>) => {
      this.mileageRecordList = [];
      result.items.forEach((mileageRecord: MileageRecord.MileageRecord) => {
        const model = new MileageRecordModel();
        model.id = mileageRecord.id;
        model.user = mileageRecord.user;
        model.vehicleId = mileageRecord.vehicle.id;
        model.vehicleLicencePlate = mileageRecord.vehicle.licencePlate;
        model.startKilometer = mileageRecord.startKilometer.toNumber();
        model.creationTime = mileageRecord.creationTime;
        model.finishTime = mileageRecord.finishTime ? mileageRecord.finishTime : Dates.emptyOffsetDateTime();
        model.finishKilometer = mileageRecord.finishKilometer ? mileageRecord.finishKilometer.toNumber() : undefined;
        model.note = mileageRecord.note ? mileageRecord.note : '';
        model.journeyType = mileageRecord.journeyType ? mileageRecord.journeyType : '';
        if (mileageRecord.taskRecord) {
          model.taskId = mileageRecord.taskRecord.taskId;
          model.taskRecordId = mileageRecord.taskRecord.id;
          model.taskName = mileageRecord.taskRecord.name;
          model.customerName = mileageRecord.taskRecord.customerName ? mileageRecord.taskRecord.customerName : '';
        }
        this.mileageRecordList.push(model);
      });
      this.queryModel.currentPage = requestedPage;
      this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
    });
  }

  private createFilter() {
    return (f: FilterField.MileageRecord) => CriteriaBuilder.builder()
      .addNumber((taskRecordId) => f.taskRecord.id.eq(taskRecordId), this.taskRecordId)
      .build();
  }

  private getQueryRequest(requestedPage?: number): MileageRecord.QueryRequest {
    const order = this.queryModel.createOrderFunction();
    const filter = this.createFilter();
    return {
      fields: f => f.each,
      order: order,
      filter: filter,
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined,
    };
  }

  exportXls() {
    const request = this.getQueryRequest();
    this.mileageRecordService.exportXls(request).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName('mileage-record.xlsx'));
      }
    );
  }

  exportMaconomy() {
    if (!this.maconomyEnabled) {
      return;
    }
    const request = this.getQueryRequest();
    this.mileageRecordService.exportMaconomy(request).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName('mileage-record.xlsx'));
      }
    );
  }

}
