/* eslint-disable */
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { RightModel } from '../../../../app.rights';
import { GrantedPermissionSetResolver, RightResolver, RightService } from '../../../../lib/right.service';
import { User, UserService } from '../../../../lib/user.service';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { StringKey } from '../../../../app.string-keys';
import {
  DeviceItem,
  MultiselectOptionItem,
  OptionItem,
  OptionItems,
  OwnerUserItem,
  OwnerUserItemFactory,
  UiConstants,
  UserGroupItem
} from '../../../../util/core-utils';
import { TranslateService } from '@ngx-translate/core';
import { Angular2Multiselects } from '../../../../util/multiselect';
import { ForwardingNgFormRef, LocalFormGroupValidationErrors, ResourceQueryResult, Services } from '../../../../lib/util/services';
import { TaskService } from '../../../../lib/task/task.service';
import { ConfigModel } from '../../../../util/task-record-utils';
import { TaskRecord, TaskRecordService } from '../../../../lib/task/task-record.service';
import { TaskRecordBase } from '../task-record-base/task-record-base-view';
import { TaskRecordCreateFieldErrorMap } from '../../../calendar/calendar.component';
import { FieldError, FieldErrors } from '../../../../lib/util/errors';
import { Observable } from 'rxjs';
import { Set } from 'immutable';
import { Arrays } from '../../../../lib/util/arrays';
import { UserGroup, UserGroupService } from '../../../../lib/user-group.service';
import { Device, DeviceManagementService } from '../../../../lib/device-management.service';
import { ToasterService } from '../../../../fork/angular2-toaster/angular2-toaster';
import { UIRouter } from '@uirouter/angular';
import { ConfigurationService } from '../../../../lib/core-ext/configuration.service';
import { AppValidators } from '../../../../util/app-validators';
import { TaskRecordQuickCreateNameTemplate, TaskRightModel } from '../../../../util/task-utils';
import { TaskMultiselectOptionItem, TaskMultiselectProvider } from '../../../../lib/task/task-multiselect.provider';
import { Strings } from '../../../../lib/util/strings';
import { OperationRights } from '../../../../app.right-definitions';
import AssigneeModel = TaskRecordBase.AssigneeModel;
import TaskRecordBulkCloneField = TaskRecord.TaskRecordBulkCloneField;

/* eslint-enable */

@Component({
  selector: 'app-task-record-bulk-clone-dialog',
  templateUrl: './task-record-bulk-clone-dialog.component.html',
  styleUrls: ['./task-record-bulk-clone-dialog.component.scss']
})
export class TaskRecordBulkCloneDialogComponent implements OnInit {

  UiConstants = UiConstants;
  TaskRecordQuickCreateNameTemplate = TaskRecordQuickCreateNameTemplate;

  @Output()
  onSuccess: EventEmitter<BulkCloneResult> = new EventEmitter();

  @ViewChild('taskRecordBulkCloneDialog', {static: true})
  taskRecordBulkCloneDialog: ModalDirective;

  @ViewChild('f') form: NgForm;
  formGroup: FormGroup;

  isVisible: boolean = false;

  rightModel: RightModel;

  dropdownSettings: Angular2Multiselects.Settings;
  remoteDropdownSettings: Angular2Multiselects.Settings;
  offlineDropdownSettings: Angular2Multiselects.Settings;
  cloneFieldsDropdownSettings: Angular2Multiselects.Settings;

  tasks: TaskMultiselectOptionItem[] = [];
  cloneFields: MultiselectOptionItem<TaskRecordBulkCloneField>[]
    = TaskRecord.bulkCloneFields.map(f => ({id: f.type, itemName: f.stringKey}));

  model: TaskRecordBulkCloneModel = new TaskRecordBulkCloneModel();

  taskRecordCreateFieldErrors: TaskRecordCreateFieldErrorMap;
  private formGroupValidationErrors: LocalFormGroupValidationErrors;

  config: ConfigModel = new ConfigModel();

  userGroups: UserGroupItem[] = [];
  users: OwnerUserItem[] = [];
  devices: DeviceItem[] = [];

  creationInProgress: boolean;

  constructor(
    private translateService: TranslateService,
    private rightService: RightService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private taskService: TaskService,
    private configService: ConfigurationService,
    private ownerUserItemFactory: OwnerUserItemFactory,
    private userGroupService: UserGroupService,
    private deviceManagementService: DeviceManagementService,
    private taskRecordService: TaskRecordService,
    private uiRouter: UIRouter,
    private toasterService: ToasterService,
    private taskMultiselectProvider: TaskMultiselectProvider,
  ) {
    this.formGroup = this.createFormGroup(formBuilder);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(
      this.createForwardingHtmlForm(),
      this.formGroup
    );

    this.taskRecordCreateFieldErrors = {};
  }

  ngOnInit() {
    this.initDropdownSettings();
    this.loadConfig();
    this.taskRecordBulkCloneDialog.onShow.subscribe(() => {
      if (!this.rightModel) {
        this.loadRightModels(() => {
          this.loadAssigneeModels();
          this.loadTasks(true);
        });
      }
    });
  }

  show(ids: number[]) {
    this.model.taskRecordIds = ids;
    this.isVisible = true;
    this.formGroup.reset();
    this.taskRecordBulkCloneDialog.show();
  }

  hide() {
    this.model.reset();
    this.isVisible = false;
    this.taskRecordBulkCloneDialog.hide();
  }

  private loadRightModels(completion: () => void) {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        completion();
      }
    );
  }

  private loadConfig() {
    this.config = this.configService.getConfigurationModel();
  }

  loadTasks(init?: boolean) {
    if (this.rightModel.taskRead.hasRight()) {
      this.tasks = [];
      this.taskService.query({
        rights: Set.of(OperationRights.TASK_RECORD_CREATE),
        disabled: false
      }).subscribe(result => {
        result.items.forEach(t => {
          const task = this.taskMultiselectProvider.toMultiselectOptionItem(t!);
          const taskGrantedRights = new TaskRightModel(GrantedPermissionSetResolver.byGrantedRights(task.grantedRights));
          if (taskGrantedRights.taskRecordCreate.hasRight()) {
            this.tasks.push(task);
          }
        });
      });
      if (init && this.tasks.length === 1) {
        this.model.task = this.tasks;
      }
    }
  }

  loadAssigneeModels(task?: TaskMultiselectOptionItem) {
    if (this.rightModel.userGroupRead.hasRight()) {
      this.loadUserGroups();
    }
    if (this.config.assigneeWithUser) {
      this.loadUsers();
    }
    if (this.config.assigneeWithMobileApp) {
      if (this.rightModel.mobileAppRead.hasRight()) {
        this.loadDevices();
      }
    }
  }

  filterUsers(q?: string) {
    this.loadUsers(q);
  }

  filterUserGroups(q?: string) {
    const ids: number[] = [];
    if (this.model.selectedTask) {
      ids.push(...this.model.selectedTask.enabledAssignees.userGroups.toArray());
    }
    this.loadUserGroups(q, ids.length > 0 ? ids : undefined);
  }

  private loadUsers(q?: string) {
    const ugId = this.model.getUserGroupId();
    const groupIds: number[] = ugId
      ? [ugId]
      : [];

    if (groupIds.length === 0 && this.model.selectedTask) {
      groupIds.push(...this.model.selectedTask.enabledAssignees.userGroups.toArray());
    }

    this.userService.query({
      q: Strings.undefinedOrNonEmpty(q),
      user_group_ids: groupIds.length > 0 ? groupIds.join(',') : undefined,
      fields: 'id,user_name,person_name',
      disabled: false,
      number_of_items: UiConstants.autocompletePageSize,
      page_number: 1,
      order: Services.createOrderFieldParameter(User.Keys.toOrderFieldKey, Set.of(User.DEFAULT_ORDER)),
      no_progress_bar: true,
    }).subscribe(result => {
      this.users = [];
      const items = this.ownerUserItemFactory.createAll(result.items);
      const sortedOwnerUsers: OwnerUserItem[] = items;
      sortedOwnerUsers.sort(OptionItems.createNameComparator());
      Arrays.iterateByIndex(sortedOwnerUsers, (item) => {
        this.users.push(item);
      });
    });
  }

  private loadUserGroups(q?: string, ids?: number[]) {
    this.userGroupService.query({
      order: Services.createOrderFieldParameter(UserGroup.Keys.toOrderFieldKey, Set.of(UserGroup.DEFAULT_ORDER)),
      name: Strings.undefinedOrNonEmpty(q),
      disabled: false,
      number_of_items: UiConstants.autocompletePageSize,
      id: ids ? ids.join(',') : undefined,
      page_number: 1,
      no_progress_bar: true
    }).subscribe((result: ResourceQueryResult<UserGroup>) => {
      this.userGroups = [];
      result.items.forEach((group) => {
        const item = {
          id: group.id,
          text: group.name
        };
        this.userGroups.push(item);
      });
    });
  }

  private loadDevices() {
    let observable: Observable<ResourceQueryResult<Device>>;
    observable = this.deviceManagementService.query({
      disabled: false,
    });
    observable.subscribe((devices: ResourceQueryResult<Device>) => {
      this.devices = [];
      devices.items.forEach((device) => {
        const item = {
          id: device.id,
          text: device.name ? device.name : device.application_id
        };
        this.devices.push(item);
      });
    });
  }


  private initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .labelKey(OptionItem.KEY_TEXT)
      .build();
    this.cloneFieldsDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .translate(true)
      .build();
    this.remoteDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .translate(false)
      .remoteSearch(true)
      .build();
    this.offlineDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .translate(false)
      .remoteSearch(false)
      .searchBy(['itemName'])
      .build();
  }

  removeFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.taskRecordCreateFieldErrors, fieldError);
  }

  onClonedFieldsChanged() {
    this.formGroup.controls['nameTemplate'].updateValueAndValidity();
  }

  onUserGroupChanged() {
    this.formGroup.controls['user'].updateValueAndValidity();
    this.formGroup.controls['device'].updateValueAndValidity();
  }

  onAssigneeChanged() {
    this.formGroup.controls['userGroup'].updateValueAndValidity();
  }

  onOpenTaskRecordsChanged() {
    this.onUserGroupChanged();
    this.onAssigneeChanged();
  }

  bulkClone() {
    this.formGroup.get('task')!.markAsTouched();
    this.formGroup.get('nameTemplate')!.markAsTouched();
    this.formGroup.get('userGroup')!.markAsTouched();
    if (this.config.assigneeWithUser) {
      this.formGroup.get('user')!.markAsTouched();
      this.formGroup.get('device')!.setErrors(null);
    }
    if (this.config.assigneeWithMobileApp) {
      this.formGroup.get('device')!.markAsTouched();
      this.formGroup.get('user')!.setErrors(null);
    }
    this.formGroup.get('cloneFields')!.markAsTouched();
    if (this.hasLocalFieldError()) {
      return;
    }

    this.creationInProgress = true;
    this.taskRecordService.clone({
      taskRecordIds: this.model.taskRecordIds,
      targetTaskId: this.model.getTaskId()!,
      nameTemplate: this.model.nameTemplate,
      userGroupId: this.model.getUserGroupId(),
      assignee: this.model.getAssigneeChangeRequest(),
      openTaskRecords: this.model.openTaskRecords,
      cloneFields: this.model.cloneFields.map(f => f.id)
    }).subscribe((result: number[]) => {
        this.creationInProgress = false;
        this.onSuccess.emit({
          taskId: this.model.getTaskId()!,
          taskRecordIds: result
        });
        this.hide();
      },
      () => {
        this.creationInProgress = false;
        this.toasterService.pop({
          timeout: UiConstants.ToastTimeoutLong,
          type: UiConstants.toastTypeError,
          title: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_TITLE),
          body: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_MESSAGE)
        });
      });
  }

  private createForwardingHtmlForm() {
    return new ForwardingNgFormRef({
      formFn: () => {
        return this.form;
      }
    });
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  private createFormGroup(fb: FormBuilder): FormGroup {
    return fb.group({
      task: [
        [],
        Validators.required
      ],
      nameTemplate: [
        '',
        AppValidators.tempValidator({
          validator: Validators.required,
          disabled: () => {
            return this.model.cloneFields.map(f => f.id).includes(TaskRecordBulkCloneField.NAME);
          }
        })
      ],
      user: [
        [],
        AppValidators.tempValidator({
          validator: Validators.required,
          disabled: () => {
            return (!this.model.openTaskRecords || this.model.userGroup.length > 0)
              || (this.config && this.config.assigneeWithMobileApp)
              || this.model.cloneFields.map(f => f.id).includes(TaskRecordBulkCloneField.ASSIGNEE);
          }
        })
      ],
      userGroup: [
        [],
        AppValidators.tempValidator({
          validator: Validators.required,
          disabled: () => {
            return !this.model.openTaskRecords
              || (this.model.assignee.user !== undefined || this.model.assignee.device !== undefined)
              || this.model.cloneFields.map(f => f.id).includes(TaskRecordBulkCloneField.USER_GROUP);
          }
        })
      ],
      device: [
        [],
        AppValidators.tempValidator({
          validator: Validators.required,
          disabled: () => {
            return (!this.model.openTaskRecords || this.model.userGroup.length > 0)
              || (this.config && this.config.assigneeWithUser)
              || this.model.cloneFields.map(f => f.id).includes(TaskRecordBulkCloneField.ASSIGNEE);
          }
        })
      ],
      cloneFields: [
        []
      ],
    });
  }

}

export class TaskRecordBulkCloneModel {
  taskRecordIds: number[] = [];
  task: TaskMultiselectOptionItem[] = [];
  nameTemplate: string = '';
  userGroup: UserGroupItem[] = [];
  assignee: AssigneeModel = new AssigneeModel();
  openTaskRecords: boolean = false;
  cloneFields: MultiselectOptionItem<TaskRecordBulkCloneField>[]
    = TaskRecord.bulkCloneFields.map(f => ({id: f.type, itemName: f.stringKey}));

  getTaskId(): number | undefined {
    return this.task.length === 1 ? this.task[0].id! : undefined;
  }

  get selectedTask(): TaskMultiselectOptionItem | undefined {
    return this.task.length > 0 ? this.task[0] : undefined;
  }

  getAssigneeChangeRequest(): TaskRecord.AssigneeChangeRequest | undefined {
    return (!this.assignee.user && !this.assignee.device)
      ? undefined
      : {
        userId: this.assignee.user && this.assignee.user.id ?
          this.assignee.user.id : undefined,
        mobileApplicationId: this.assignee.device && this.assignee.device.id ?
          this.assignee.device.id : undefined
      };
  }

  getUserGroupId(): number | undefined {
    return this.userGroup.length === 1 ? this.userGroup[0].id! : undefined;
  }

  get nameCloned(): boolean {
    return this.cloneFields.map(f => f.id).includes(TaskRecordBulkCloneField.NAME);
  }

  get userGroupCloned(): boolean {
    return this.cloneFields.map(f => f.id).includes(TaskRecordBulkCloneField.USER_GROUP);
  }

  get assigneeCloned(): boolean {
    return this.cloneFields.map(f => f.id).includes(TaskRecordBulkCloneField.ASSIGNEE);
  }

  reset() {
    this.taskRecordIds = [];
    this.task = [];
    this.nameTemplate = '';
    this.userGroup = [];
    this.assignee = new AssigneeModel();
    this.openTaskRecords = false;
    this.cloneFields = TaskRecord.bulkCloneFields.map(f => ({id: f.type, itemName: f.stringKey}));
  }
}

export interface BulkCloneResult {
  taskId: number;
  taskRecordIds: number[];
}
