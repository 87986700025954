<div class="modal-content">
  <div class="modal-header">
    <p class="modal-title"><strong>{{getTitleKey() | translate}}</strong></p>
    <button type="button" class="close" (click)="closeDialog()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <mat-dialog-content style="overflow:auto">
      <form (ngSubmit)="save()" #f="ngForm" id="form"
            [formGroup]="formGroup" novalidate>
        <div class="form-group row"
             [ngClass]="{ 'has-danger': hasLocalFieldError('name') || hasFieldError(Workflow.ValidatedField.NAME) }">
          <label for="name" class="col-form-label form-control-label col-md-4 detail-title"
                 [class.required-field-label]="!readonly">
            {{'COMMON_NAME'|translate}}
          </label>
          <div class="col-md-6">
            <div class="mw-100">
              <input type="text"
                     [(ngModel)]="model.name"
                     maxlength="{{getTextMaximumLength()}}"
                     formControlName="name"
                     trim="blur"
                     validateOnBlur
                     [validateFormControl]="formGroup.controls['name']"
                     (ngModelChange)="removeFieldError(Workflow.ValidatedField.NAME)"
                     [ngClass]="{ 'form-control-danger': hasLocalFieldError('name') || hasFieldError(Workflow.ValidatedField.NAME) }"
                     class="form-control"
                     name="name"
                     id="name"
                     placeholder="{{'COMMON_NAME'|translate}}">
              <div class="form-control-feedback" *ngIf="hasLocalFieldError('name')">
                      <span
                        *ngIf="hasLocalFieldError('name', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
              </div>
              <div class="form-control-feedback" *ngIf="hasFieldError(Workflow.ValidatedField.NAME)">
                <span>{{getFieldErrorText(Workflow.ValidatedField.NAME)}}</span>
              </div>
            </div>
          </div>
        </div>

        <app-icon-selector [(selectedIcon)]="model.icon"
                           [readonly]="readonly"></app-icon-selector>

        <div class="form-group row"
             [ngClass]="{ 'has-danger': hasLocalFieldError('externalId') || hasFieldError(Workflow.ValidatedField.EXTERNAL_ID) }">
          <label for="externalId" class="col-form-label form-control-label col-md-4 detail-title"
                 [class.required-field-label]="edit">
            {{'USER_LABEL_EXTERNAL_ID'|translate}}
          </label>
          <div class="col-md-6">
            <div class="mw-100">
              <input type="text"
                     [(ngModel)]="model.externalId"
                     maxlength="{{getTextMaximumLength()}}"
                     formControlName="externalId"
                     trim="blur"
                     validateOnBlur
                     [validateFormControl]="formGroup.controls['externalId']"
                     (ngModelChange)="removeFieldError(Workflow.ValidatedField.EXTERNAL_ID)"
                     [ngClass]="{ 'form-control-danger': hasLocalFieldError('externalId') || hasFieldError(Workflow.ValidatedField.EXTERNAL_ID) }"
                     class="form-control"
                     name="externalId"
                     id="externalId"
                     placeholder="{{'USER_LABEL_EXTERNAL_ID'|translate}}">
              <div class="form-control-feedback" *ngIf="hasLocalFieldError('externalId')">
              <span
                *ngIf="hasLocalFieldError('externalId', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
              </div>
              <div class="form-control-feedback" *ngIf="hasFieldError(Workflow.ValidatedField.EXTERNAL_ID)">
                <span>{{getFieldErrorText(Workflow.ValidatedField.EXTERNAL_ID)}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="description" class="col-form-label form-control-label col-md-4 detail-title">
            {{'COMMON_DESCRIPTION'|translate}}
          </label>
          <div class="col-md-6">
            <div class="mw-100">
              <input type="text"
                     [(ngModel)]="model.description"
                     maxlength="{{getTextMaximumLength()}}"
                     formControlName="description"
                     trim="blur"
                     validateOnBlur
                     class="form-control"
                     name="description"
                     id="description"
                     placeholder="{{'COMMON_DESCRIPTION'|translate}}">
            </div>
          </div>
        </div>

        <div class="form-group row" [ngClass]="{ 'has-danger': hasLocalFieldError('inheritanceMode')}">
          <label
            class="col-form-label form-control-label col-md-4 detail-title"
            [class.required-field-label]="!readonly">{{'WORKFLOW_CREATE_INHERITANCE_MODE'|translate}}</label>
          <div class="col-md-6">
            <div class="mw-100">
              <angular2-multiselect
                [(ngModel)]="model.inheritanceMode"
                id="inheritanceMode"
                formControlName="inheritanceMode"
                [ngClass]="{ 'form-control-danger': hasLocalFieldError('inheritanceMode') }"
                [data]="model.inheritanceModes"
                [readonly]="readonly || !model.isInheritanceEditable()"
                [readonlyBorderEnabled]="readonly"
                [settings]="dropdownSettings">
              </angular2-multiselect>
              <div class="form-control-feedback" *ngIf="hasLocalFieldError('inheritanceMode')">
          <span
            *ngIf="hasLocalFieldError('inheritanceMode', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label
            class="col-form-label form-control-label col-md-4 detail-title">
            {{'WORKFLOW_CREATE_START_WITH_FIRST_TASK'|translate}}
          </label>
          <div class="col-md-6">
            <div class="mw-100">
              <app-animated-checkbox
                [(selected)]="model.startWithFirstTask"
                (selectedChanged)="onStartWithFirstTaskChanged($event)"
                [readonly]="readonly || !model.isInheritanceEditable()">
              </app-animated-checkbox>
            </div>
          </div>
        </div>

        <label class="col-form-label mw-100 form-control-label font-weight-bold">
          {{'WORKFLOW_CREATE_PROCESS_CONFIG'|translate}}
        </label>

        <div class="form-group row">
          <label
            class="col-form-label form-control-label col-md-4 detail-title">{{'WORKFLOW_CREATE_PROCESS_REQUIRED_FIELDS' | translate}}
          </label>
          <div class="col-md-6">
            <angular2-multiselect [data]="model.requirableFields" [settings]="multiselectDropdownSettings"
                                  class="form-control"
                                  [readonly]="readonly"
                                  [(ngModel)]="model.processConfig.requiredFields"
                                  [ngModelOptions]="{standalone: true}">
            </angular2-multiselect>
          </div>
        </div>


        <div *ngIf="model.startWithFirstTask">
          <div class="form-group row">
            <label class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_NAME_TEMPLATE' |
              translate}}</label>
            <div class="col-md-6">
              <app-material-tag-input
                [(ngModel)]="model.processConfig.namePattern"
                [ngModelOptions]="{standalone: true}"
                [placeholderStringKey]="'COMMON_NAME_TEMPLATE'"
                [autocompleteItems]="WorkflowProcessConfigNameTemplate.availableTemplateBlocks"
                [separatorCharacter]="WorkflowProcessConfigNameTemplate.templateBlockSeparator"
                [generatedStringExampleMap]="WorkflowProcessConfigNameTemplate.templateBlockExampleMap"
                [separatorKeyCodes]="WorkflowProcessConfigNameTemplate.separatorKeysCodes"
                [shouldGenerateExample]="true"
                [readonly]="readonly">
              </app-material-tag-input>
            </div>
          </div>

          <div class="form-group row"
               [ngClass]="{ 'has-danger': hasFieldError(Workflow.ValidatedField.TEMPLATE) }">
            <label class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_EXTERNAL_ID_TEMPLATE' |
              translate}}</label>
            <div class="col-md-6">
              <app-material-tag-input
                [(ngModel)]="model.processConfig.externalIdSequence.template"
                [ngModelOptions]="{standalone: true}"
                (modelChange)="removeFieldError(Workflow.ValidatedField.TEMPLATE)"
                [placeholderStringKey]="'COMMON_EXTERNAL_ID_TEMPLATE'"
                [autocompleteItems]="TaskRecordExternalIdSequence.availableSequenceBlocks"
                [separatorCharacter]="TaskRecordExternalIdSequence.sequenceBlockSeparator"
                [generatedStringExampleMap]="TaskRecordExternalIdSequence.sequenceBlockExampleMap"
                [separatorKeyCodes]="TaskRecordExternalIdSequence.separatorKeysCodes"
                [shouldGenerateExample]="true"
                [readonly]="readonly">
              </app-material-tag-input>
              <div class="form-control-feedback" *ngIf="hasFieldError(Workflow.ValidatedField.TEMPLATE)">
                <span>{{getFieldErrorText(Workflow.ValidatedField.TEMPLATE)}}</span>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label
              class="col-form-label form-control-label col-md-4 detail-title">{{'TASK_EXTERNAL_ID_TEMPLATE_WITHIN_YEAR'
              | translate}}
              <i class="icomoon icomoon-info info-icon"
                 popover="{{'TASK_INFO_EXTERNAL_ID_TEMPLATE_WITHIN_YEAR' | translate}}"
                 triggers="mouseenter:mouseleave" container="body" placement="right"></i>
            </label>
            <div class="col-md-8">
              <app-animated-checkbox
                [(selected)]="model.processConfig.externalIdSequence.withinYear"
                [readonly]="readonly">
              </app-animated-checkbox>
            </div>
          </div>
        </div>

        <div *ngIf="showAdditionalFields">
          <div class="form-group row">
            <label for="name" class="col-form-label form-control-label col-md-4 detail-title">
              {{'COMMON_CREATION_TIME'|translate}}
            </label>
            <div class="col-md-6">
              <div class="mw-100">
                <label
                  class="form-control detail-input">{{model.workflow.creationTime.toUtcIsoString() | date: 'short'}}</label>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label for="name" class="col-form-label form-control-label col-md-4 detail-title">
              {{'COMMON_UPDATE_TIME'|translate}}
            </label>
            <div class="col-md-6">
              <div class="mw-100">
                <label
                  class="form-control detail-input">{{model.workflow.updateTime.toUtcIsoString() | date: 'short'}}</label>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label for="name" class="col-form-label form-control-label col-md-4 detail-title">
              {{'COMMON_LAST_EDITOR_USER'|translate}}
            </label>
            <div class="col-md-6">
              <div class="mw-100">
                <label class="form-control detail-input">{{model.getLastModifierName()}}</label>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label for="name" class="col-form-label form-control-label col-md-4 detail-title">
              {{'COMMON_VERSION'|translate}}
            </label>
            <div class="col-md-6">
              <div class="mw-100">
                <label class="form-control detail-input">v{{model.workflow.version}}</label>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label for="name" class="col-form-label form-control-label col-md-4 detail-title">
              {{'COMMON_STATE'|translate}}
            </label>
            <div class="col-md-6">
              <div class="mw-100">
                <label class="form-control detail-input">{{model.versionStateKey | translate}}</label>
              </div>
            </div>
          </div>
        </div>
      </form>
    </mat-dialog-content>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="closeDialog()">
      {{'COMMON_BUTTON_CANCEL' | translate}}
    </button>
    <button type="submit" class="btn btn-primary" form="form" *ngIf="!readonly">
      {{'COMMON_BUTTON_SAVE' | translate}}
    </button>
  </div>
</div>
