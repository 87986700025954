<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-custom-padding animated fadeIn">
  <div class="row ml-0 mr-0">
    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12 card-custom-padding all-tasks" id="task-dashboard-card-global">
      <div class="card card-top-color cursor-pointer"
           uiSref="Admin.TaskRecordGlobalList">
        <div class="card-body text-center">
          <div class="h4 all-tasks-title">{{taskGlobalCount.taskCount}}</div>
          <div class="h6 text-uppercase all-tasks-subtitle">{{ 'TASK_RECORD_GLOBAL_LIST_TITLE' | translate }}</div>
        </div>
      </div>
      <div class="card border-top-0 border-right-0">
        <div class="d-flex flex-wrap justify-content between card-footer p-0">
          <div class="col text-center card-footer-border">
            <p class="card-footer-number pt-1 mb-0">{{taskGlobalCount.taskOverDueCount}}</p>
            <p class="card-footer-text">{{'TASK_DASHBOARD_LABEL_OVERDUE' | translate}}</p>
          </div>
          <div class="col text-center card-footer-border">
            <p class="card-footer-number pt-1 mb-0">{{taskGlobalCount.taskRejectedCount}}</p>
            <p class="card-footer-text">{{'TASK_DASHBOARD_LABEL_REJECTED' | translate}}</p>
          </div>
          <div class="col text-center card-footer-border">
            <p class="card-footer-number pt-1 mb-0">{{taskGlobalCount.taskSubmittedCount}}</p>
            <p class="card-footer-text">{{'TASK_DASHBOARD_LABEL_SUBMITTED' | translate}}</p>
          </div>
        </div>
      </div>
      <div class="container-vertical-padding-half"></div>
    </div>
    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12 card-custom-padding individual-task" *ngFor="let task of dashboardTaskModels; index as i" id="task-dashboard-card-{{i}}">
      <div class="card card-top-color cursor-pointer"
           [ngStyle]="{'border-top-color': getColor(task)}"
           uiSref="Admin.TaskRecordList"
           [uiParams]="{ taskId: task.taskId }">
        <div class="card-body">
          <div class="h1 float-right mb-0">
            <td class="w-5 hidden-1300-down" [ngStyle]="{'color': getColor(task)}"><app-type-icon [icon]="getTaskIconFromId(task.taskId)" big="true"></app-type-icon></td>
          </div>
          <div class="h4 mb-0">{{getTaskRecordCount(task.taskRecordCount)}}</div>
          <small class="text-uppercase font-weight-bold">{{task.name}}</small>
          <br>
          <small class="text-muted">{{task.taskId}}</small>
        </div>
      </div>
      <div class="card border-top-0 border-right-0">
        <div class="d-flex flex-wrap justify-content between card-footer p-0">
          <div class="col text-center card-footer-border">
            <p class="card-footer-number pt-1 mb-0">{{task.overdueTaskCount}}</p>
            <p class="card-footer-text">{{'TASK_DASHBOARD_LABEL_OVERDUE' | translate}}</p>
          </div>
          <div class="col text-center card-footer-border">
            <p class="card-footer-number pt-1 mb-0">{{task.getStat('REJECTED').count}}</p>
            <p class="card-footer-text">{{'TASK_DASHBOARD_LABEL_REJECTED' | translate}}</p>
          </div>
          <div class="col text-center card-footer-border">
            <p class="card-footer-number pt-1 mb-0">{{task.getStat('SUBMITTED').count}}</p>
            <p class="card-footer-text">{{'TASK_DASHBOARD_LABEL_SUBMITTED' | translate}}</p>
          </div>
        </div>
      </div>
      <div class="container-vertical-padding-half"></div>
    </div>
    </div>
  <div class="ml-0 mr-0 mb-1" *ngIf="hasMorePages()">
    <div class="d-flex align-items-center justify-content-center">
      <button class="btn btn-outline-primary" (click)="loadTasks()">+{{'TASK_RECORD_BOARD_SHOW_MORE' | translate}}</button>
    </div>
  </div>
</div>
