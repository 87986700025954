/* eslint-disable */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
/* eslint-enable */

@Component({
  selector: 'app-animated-checkbox',
  templateUrl: './animated-checkbox.component.html',
  styleUrls: ['./animated-checkbox.component.scss']
})
export class AnimatedCheckboxComponent implements OnInit {

  @Input()
  readonly: boolean = false;

  @Output()
  private selectedChange = new EventEmitter();

  @Output()
  private selectedChanged = new EventEmitter();

  private selectedValue: boolean = false;

  @Input()
  get selected(): boolean {
    return this.selectedValue;
  }

  set selected(value: boolean) {
    const changed = value !== this.selectedValue;
    this.selectedValue = value;
    this.selectedChange.emit(this.selectedValue);
    if (changed) {
      this.selectedChanged.emit(value);
    }
  }

  @Output()
  changeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  clickEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {
  }

  changed(event: boolean) {
    this.changeEvent.emit(event);
  }

  clicked() {
    if (this.readonly) {
      return false;
    }
    this.clickEvent.emit();
  }

}
