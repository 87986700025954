/* eslint-disable */
import { Observable, Observer } from 'rxjs';
import { Address, AddressResource } from '../../address';
import { EmailAddress } from '../../util/email-address';
import { PhoneNumber } from '../../util/phone-number';
import { OffsetDateTime } from '../../util/dates';
import { DownloadedFile } from '../../util/downloaded-files';
import { FieldValidationError, Order, PagingRequest, QueryResult, ResourceQueryResult, Services } from '../../util/services';
import { List, Map, Set } from 'immutable';
import { InvoiceSettingsResource, InvoiceSettingsResourceService } from './invoice-settings-resource.service';
import { Injectable } from '@angular/core';
import { Invoice } from '../invoice/invoice.service';
import { DisableRequest, EmptyMessage, IdentityMessage } from '../../util/messages';
import { ObservableErrorResourceParser } from '../../util/errors';
import InvoicePaymentType = Invoice.InvoicePaymentType;

/* eslint-enable */

@Injectable()
export class InvoiceSettingsService implements InvoiceSettings.Service {

  constructor(private resourceService: InvoiceSettingsResourceService) {
  }

  query(request: InvoiceSettings.QueryRequest): Observable<QueryResult<InvoiceSettings.InvoiceSettings>> {
    return Observable.create((observer: Observer<QueryResult<InvoiceSettings.InvoiceSettings>>) => {
      const resourceRequest: InvoiceSettingsResource.QueryRequest = {
        id: Services.createIdParameter(request.id),
        disabled: request.disabled,
        company_name: request.companyName,
        external_id: request.externalId,
        profile_name: request.profileName,
        invoice_integration_type: request.invoiceIntegrationType ? request.invoiceIntegrationType.toString() : undefined,
        invoice_direction_type: request.invoiceDirectionType ? request.invoiceDirectionType.toString() : undefined,
        q: request.q,
        order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
        no_progress_bar: request.noProgressBar
      };
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<InvoiceSettingsResource.InvoiceSettings>) => {
          observer.next({
            items: this.toPublicList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  get(request: IdentityMessage): Observable<InvoiceSettings.InvoiceSettings> {
    return Observable.create((observer: Observer<InvoiceSettings.InvoiceSettings>) => {
      return this.resourceService.get(request).subscribe(
        (result: InvoiceSettingsResource.InvoiceSettings) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  create(request: InvoiceSettings.CreateRequest): Observable<InvoiceSettings.InvoiceSettings> {
    return Observable.create((observer: Observer<InvoiceSettings.InvoiceSettings>) => {
      const resourceRequest = this.toResourceCreateRequest(request);
      return this.resourceService.create(resourceRequest).subscribe(
        (result: InvoiceSettingsResource.InvoiceSettings) => {
          observer.next(this.toPublic(result));
        },
        (error: any) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  update(request: InvoiceSettings.UpdateRequest): Observable<InvoiceSettings.InvoiceSettings> {
    return Observable.create((observer: Observer<InvoiceSettings.InvoiceSettings>) => {
      const resourceRequest = this.toResourceUpdateRequest(request);
      return this.resourceService.update(resourceRequest).subscribe(
        (result: InvoiceSettingsResource.InvoiceSettings) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  downloadTemplate(request: IdentityMessage): Observable<DownloadedFile> {
    return this.resourceService.downloadTemplate(request);
  }

  deleteTemplate(request: IdentityMessage): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.resourceService.deleteTemplate(request).subscribe((result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  setDisabled(request: DisableRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: DisableRequest = {
        id: request.id,
        disabled: request.disabled
      };
      return this.resourceService.setDisabled(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  private toResourceCreateRequest(request: InvoiceSettings.CreateRequest): InvoiceSettingsResource.CreateRequest {
    return {
      company_name: request.companyName,
      external_id: request.externalId,
      profile_name: request.profileName,
      invoice_integration_type: request.invoiceIntegrationType.toString(),
      invoice_direction_type: request.invoiceDirectionType.toString(),
      inbound_stock_id: request.inboundStockId,
      tax_number: request.taxNumber,
      eu_vat_number: request.euVatNumber,
      email_address: Services.emailAddressToString(request.emailAddress),
      phone_number: Services.phoneNumberToString(request.phoneNumber),
      bank_name: request.bankName,
      bank_account: request.bankAccount,
      web_address: request.webAddress,
      registration_number: request.registrationNumber,
      comment_template: request.commentTemplate,
      footer_text: request.footerText,
      address: AddressResource.Mapper.fromPublicPostalAddress(request.address),
      technical_user: request.technicalUser ? {
        user_name: request.technicalUser.userName,
        signature_key: request.technicalUser.signatureKey,
        exchange_key: request.technicalUser.exchangeKey,
        password: request.technicalUser.password
      } : undefined,
      szamlazz_hu: request.szamlazzHu ? {
        agent_key: request.szamlazzHu.agentKey,
        send_email: request.szamlazzHu.sendEmail,
        customer_email_address_type: request.szamlazzHu.customerEmailAddressTypeId,
        email_subject: request.szamlazzHu.emailSubject,
        email_template: request.szamlazzHu.emailTemplate,
        notification_email_address: Services.emailAddressToString(request.szamlazzHu.notificationEmailAddress),
      } : undefined,
      default_invoice_appearance_type: request.defaultInvoiceAppearanceType.toString(),
      default_payment_type: request.defaultPaymentType,
      language_code: request.languageCode,
      summary_rounding_enabled: request.summaryRoundingEnabled,
      record_rounding_enabled: request.recordRoundingEnabled,
      zero_priced_records_allowed: request.zeroPricedRecordsAllowed,
    };
  }

  private toResourceUpdateRequest(request: InvoiceSettings.UpdateRequest): InvoiceSettingsResource.UpdateRequest {
    return {
      id: request.id,
      company_name: request.companyName,
      external_id: request.externalId,
      profile_name: request.profileName,
      invoice_integration_type: request.invoiceIntegrationType.toString(),
      invoice_direction_type: request.invoiceDirectionType.toString(),
      inbound_stock_id: request.inboundStockId,
      tax_number: request.taxNumber,
      eu_vat_number: request.euVatNumber,
      email_address: Services.emailAddressToString(request.emailAddress),
      phone_number: Services.phoneNumberToString(request.phoneNumber),
      bank_name: request.bankName,
      bank_account: request.bankAccount,
      web_address: request.webAddress,
      registration_number: request.registrationNumber,
      comment_template: request.commentTemplate,
      footer_text: request.footerText,
      address: AddressResource.Mapper.fromPublicPostalAddress(request.address),
      payment_type_extras: request.paymentTypeExtras.toArray().map(extra => {
        return {payment_type: extra.paymentType, issue_deadline_plus_days: extra.issueDeadlinePlusDays};
      }),
      technical_user: request.technicalUser ? {
        user_name: request.technicalUser.userName,
        signature_key: request.technicalUser.signatureKey,
        exchange_key: request.technicalUser.exchangeKey,
        password: request.technicalUser.password
      } : undefined,
      szamlazz_hu: request.szamlazzHu ? {
        agent_key: request.szamlazzHu.agentKey,
        send_email: request.szamlazzHu.sendEmail,
        customer_email_address_type: request.szamlazzHu.customerEmailAddressTypeId,
        email_subject: request.szamlazzHu.emailSubject,
        email_template: request.szamlazzHu.emailTemplate,
        notification_email_address: Services.emailAddressToString(request.szamlazzHu.notificationEmailAddress),
      } : undefined,
      default_invoice_appearance_type: request.defaultInvoiceAppearanceType.toString(),
      default_payment_type: request.defaultPaymentType,
      language_code: request.languageCode,
      summary_rounding_enabled: request.summaryRoundingEnabled,
      record_rounding_enabled: request.recordRoundingEnabled,
      zero_priced_records_allowed: request.zeroPricedRecordsAllowed
    };
  }

  private toPublicList(resourceList: InvoiceSettingsResource.InvoiceSettings[]): List<InvoiceSettings.InvoiceSettings> {
    return List.of(...resourceList.map((r) => this.toPublic(r)));
  }

  private toPublic(r: InvoiceSettingsResource.InvoiceSettings): InvoiceSettings.InvoiceSettings {
    return {
      id: r.id,
      address: AddressResource.Mapper.toPublicPostalAddress(r.address),
      companyName: r.company_name,
      externalId: r.external_id,
      profileName: r.profile_name,
      invoiceIntegrationType: InvoiceSettings.InvoiceIntegrationType[r.invoice_integration_type],
      invoiceDirectionType: <Invoice.InvoiceDirectionType>r.invoice_direction_type,
      inboundStockId: r.inbound_stock_id,
      taxNumber: r.tax_number,
      euVatNumber: r.eu_vat_number,
      emailAddress: Services.toEmailAddress(r.email_address),
      phoneNumber: Services.toPhoneNumber(r.phone_number),
      bankName: r.bank_name,
      bankAccount: r.bank_account,
      webAddress: r.web_address,
      registrationNumber: r.registration_number,
      commentTemplate: r.comment_template,
      footerText: r.footer_text,
      pdfTemplateId: r.pdf_template_id,
      disabled: r.disabled,
      creationTime: Services.toOffsetDateTime(r.creation_time),
      updateTime: Services.toOffsetDateTime(r.update_time),
      paymentTypeExtras: r.payment_type_extras ? Set.of(...r.payment_type_extras.map(extra => {
        return {paymentType: <Invoice.InvoicePaymentType>extra.payment_type, issueDeadlinePlusDays: extra.issue_deadline_plus_days};
      })) : Set.of(),
      technicalUser: r.technical_user ? {
        userName: r.technical_user.user_name,
        signatureKey: r.technical_user.signature_key,
        exchangeKey: r.technical_user.exchange_key,
      } : undefined,
      szamlazzHu: r.szamlazz_hu ? {
        agentKey: r.szamlazz_hu.agent_key,
        sendEmail: r.szamlazz_hu.send_email,
        customerEmailAddressTypeId: r.szamlazz_hu.customer_email_address_type,
        emailSubject: r.szamlazz_hu.email_subject,
        emailTemplate: r.szamlazz_hu.email_template,
        notificationEmailAddress: Services.toEmailAddress(r.szamlazz_hu.notification_email_address),
      } : undefined,
      defaultInvoiceAppearanceType: InvoiceSettings.InvoiceAppearanceType[r.default_invoice_appearance_type],
      defaultPaymentType: r.default_payment_type ? <InvoicePaymentType>r.default_payment_type : undefined,
      languageCode: r.language_code,
      summaryRoundingEnabled: r.summary_rounding_enabled,
      recordRoundingEnabled: r.record_rounding_enabled,
      zeroPricedRecordsAllowed: r.zero_priced_records_allowed,
    };
  }

  private translateError(error: any): any {
    const res = ObservableErrorResourceParser.parseError(error);
    const fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
    const fieldErrorMap = ObservableErrorResourceParser.toFieldErrorMap(Keys.toValidatedField, fieldErrors);
    if (!fieldErrorMap.isEmpty()) {
      return FieldValidationError.of(fieldErrorMap);
    }
    return error;
  }
}

export namespace InvoiceSettings {

  export interface Service {

    get(request: IdentityMessage): Observable<InvoiceSettings>;

    create(request: CreateRequest): Observable<InvoiceSettings>;

    update(request: UpdateRequest): Observable<InvoiceSettings>;

  }

  export interface QueryRequest {
    id?: Set<number>;
    disabled?: boolean;
    companyName?: string;
    externalId?: string;
    profileName?: string;
    invoiceIntegrationType?: InvoiceIntegrationType;
    invoiceDirectionType?: Invoice.InvoiceDirectionType;
    q?: string;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
    noProgressBar?: boolean;
  }

  export interface InvoiceSettings {
    id: number;
    address: Address.PostalAddressData;
    companyName: string;
    externalId: string;
    profileName: string;
    invoiceIntegrationType: InvoiceIntegrationType;
    invoiceDirectionType: Invoice.InvoiceDirectionType;
    inboundStockId?: number;
    taxNumber: string;
    euVatNumber?: string;
    emailAddress?: EmailAddress;
    phoneNumber?: PhoneNumber;
    bankName?: string;
    bankAccount?: string;
    webAddress?: string;
    registrationNumber?: string;
    commentTemplate?: string;
    footerText?: string;
    pdfTemplateId?: number;
    disabled: boolean;
    creationTime: OffsetDateTime;
    updateTime: OffsetDateTime;
    paymentTypeExtras: Set<InvoicePaymentTypeExtra>;
    technicalUser?: TechnicalUser;
    szamlazzHu?: SzamlazzHu;
    defaultInvoiceAppearanceType: InvoiceAppearanceType;
    defaultPaymentType?: InvoicePaymentType;
    languageCode: string;
    summaryRoundingEnabled: boolean;
    recordRoundingEnabled: boolean;
    zeroPricedRecordsAllowed: boolean;
  }

  export interface CreateRequest {
    companyName: string;
    externalId?: string;
    profileName: string;
    invoiceIntegrationType: InvoiceIntegrationType;
    invoiceDirectionType: Invoice.InvoiceDirectionType;
    inboundStockId?: number;
    taxNumber: string;
    euVatNumber?: string;
    emailAddress?: EmailAddress;
    phoneNumber?: PhoneNumber;
    bankName: string;
    bankAccount: string;
    webAddress?: string;
    registrationNumber?: string;
    commentTemplate?: string;
    footerText?: string;
    address: Address.PostalAddressData;
    technicalUser?: TechnicalUser;
    szamlazzHu?: SzamlazzHu;
    defaultInvoiceAppearanceType: InvoiceAppearanceType;
    defaultPaymentType?: InvoicePaymentType;
    languageCode: string;
    summaryRoundingEnabled: boolean;
    recordRoundingEnabled: boolean;
    zeroPricedRecordsAllowed: boolean;
  }

  export interface UpdateRequest extends CreateRequest {
    id: number;
    externalId: string;
    paymentTypeExtras: Set<InvoicePaymentTypeExtra>;
  }

  export enum InvoiceIntegrationType {
    APPWORKS = 'APPWORKS',
    SZAMLAZZ_HU = 'SZAMLAZZ_HU',
    NONE = 'NONE',
    // BILLINGO = 'BILLINGO'
  }

  export interface InvoiceIntegrationTypeObject {
    type: InvoiceIntegrationType;
    icon?: string;
    thumbnailIcon?: string;
    stringKey: string;
    shortStringKey: string;
  }

  export const invoiceIntegrationTypes: InvoiceIntegrationTypeObject[] = [
    {
      type: InvoiceIntegrationType.APPWORKS,
      icon: '../../../assets/img/invoice/invoice_type_appworks.png',
      thumbnailIcon: '../../../assets/img/invoice/thumbnail/invoice_type_appworks.png',
      stringKey: 'INVOICE_INTEGRATION_TYPE_APPWORKS',
      shortStringKey: 'INVOICE_INTEGRATION_TYPE_APPWORKS',
    },
    {
      type: InvoiceIntegrationType.SZAMLAZZ_HU,
      icon: '../../../assets/img/invoice/invoice_type_szamlazz_hu.png',
      thumbnailIcon: '../../../assets/img/invoice/thumbnail/invoice_type_szamlazz_hu.png',
      stringKey: 'INVOICE_INTEGRATION_TYPE_SZAMLAZZ_HU',
      shortStringKey: 'INVOICE_INTEGRATION_TYPE_SZAMLAZZ_HU',
    },
    {
      type: InvoiceIntegrationType.NONE,
      stringKey: 'INVOICE_INTEGRATION_TYPE_NONE',
      shortStringKey: 'INVOICE_INTEGRATION_TYPE_NONE_SHORT',
      icon: undefined
    }
    // {type: InvoiceIntegrationType.BILLINGO, icon: '../../../assets/img/invoice/invoice_type_billingo.png'}
  ];

  export enum InvoiceAppearanceType {
    PAPER = 'PAPER',
    ELECTRONIC = 'ELECTRONIC',
    // EDI = 'EDI',
    // UNKNOWN = 'UNKNOWN'
  }

  export interface InvoiceAppearanceTypeObject {
    type: InvoiceAppearanceType;
    stringKey: string;
  }

  export const invoiceAppearanceTypes: InvoiceAppearanceTypeObject[] = [
    {type: InvoiceAppearanceType.PAPER, stringKey: 'INVOICE_APPEARANCE_TYPE_PAPER'},
    {type: InvoiceAppearanceType.ELECTRONIC, stringKey: 'INVOICE_APPEARANCE_TYPE_ELECTRONIC'},
    // {type: InvoiceAppearanceType.EDI, stringKey: 'INVOICE_APPEARANCE_TYPE_EDI'},
    // {type: InvoiceAppearanceType.UNKNOWN, stringKey: 'INVOICE_APPEARANCE_TYPE_UNKNOWN'},
  ];

  export interface InvoiceLanguage {
    code: string;
    stringKey: string;
  }

  export const invoiceLanguages: InvoiceLanguage[] = [
    {code: 'hu', stringKey: 'DOCUMENT_LANGUAGE_NAME_HU'},
    {code: 'en', stringKey: 'DOCUMENT_LANGUAGE_NAME_EN'},
  ];

  export interface InvoicePaymentTypeExtra {
    paymentType: Invoice.InvoicePaymentType,
    issueDeadlinePlusDays: number
  }

  export interface TechnicalUser {
    userName?: string;
    password?: string;
    signatureKey?: string;
    exchangeKey?: string;
  }

  export interface SzamlazzHu {
    notificationEmailAddress: EmailAddress;
    agentKey: string;
    sendEmail: boolean
    customerEmailAddressTypeId?: number;
    emailTemplate?: string;
    emailSubject?: string;
  }


  export enum OrderField {
    ID,
    DISABLED,
    CREATION_TIME,
    UPDATE_TIME,
    COMPANY_NAME,
    EXTERNAL_ID,
    PROFILE_NAME
  }

  export enum ValidatedField {
    UNKNOWN,
    DISABLED,
    COMPANY_NAME,
    EXTERNAL_ID,
    PROFILE_NAME,
    TAX_NUMBER,
    EU_VAT_NUMBER,
    EMAIL_ADDRESS,
    PHONE_NUMBER,
    BANK_NAME,
    BANK_ACCOUNT,
    WEB_ADDRESS,
    REGISTRATION_NUMBER,
    EMAIL_TEXT,
    ADDRESS,
    PASSWORD,
    INVOICE_DIRECTION_TYPE,
    INBOUND_STOCK_ID
  }

}

class Keys {

  private static readonly ID = 'id';
  private static readonly DISABLED = 'disabled';
  private static readonly COMPANY_NAME = 'company_name';
  private static readonly EXTERNAL_ID = 'external_id';
  private static readonly PROFILE_NAME = 'profile_name';
  private static readonly TAX_NUMBER = 'tax_number';
  private static readonly EU_VAT_NUMBER = 'eu_vat_number';
  private static readonly EMAIL_ADDRESS = 'email_address';
  private static readonly PHONE_NUMBER = 'phone_number';
  private static readonly BANK_NAME = 'bank_name';
  private static readonly BANK_ACCOUNT = 'bank_account';
  private static readonly WEB_ADDRESS = 'web_address';
  private static readonly REGISTRATION_NUMBER = 'registration_number';
  private static readonly EMAIL_TEXT = 'email_text';
  private static readonly ADDRESS = 'address';
  private static readonly PASSWORD = 'password';
  private static readonly CREATION_TIME = 'creation_time';
  private static readonly UPDATE_TIME = 'update_time';
  private static readonly INVOICE_DIRECTION_TYPE = 'invoice_direction_type';
  private static readonly INBOUND_STOCK_ID = 'inbound_stock_id';

  private static readonly orderFieldKeyMap: Map<InvoiceSettings.OrderField, string> = Map.of(
    InvoiceSettings.OrderField.ID, Keys.ID,
    InvoiceSettings.OrderField.DISABLED, Keys.DISABLED,
    InvoiceSettings.OrderField.CREATION_TIME, Keys.CREATION_TIME,
    InvoiceSettings.OrderField.UPDATE_TIME, Keys.UPDATE_TIME,
    InvoiceSettings.OrderField.COMPANY_NAME, Keys.COMPANY_NAME,
    InvoiceSettings.OrderField.EXTERNAL_ID, Keys.EXTERNAL_ID,
    InvoiceSettings.OrderField.PROFILE_NAME, Keys.PROFILE_NAME
  );

  private static readonly keyValidatedFieldMap: Map<string, InvoiceSettings.ValidatedField> = Map.of(
    Keys.DISABLED, InvoiceSettings.ValidatedField.DISABLED,
    Keys.COMPANY_NAME, InvoiceSettings.ValidatedField.COMPANY_NAME,
    Keys.EXTERNAL_ID, InvoiceSettings.ValidatedField.EXTERNAL_ID,
    Keys.PROFILE_NAME, InvoiceSettings.ValidatedField.PROFILE_NAME,
    Keys.TAX_NUMBER, InvoiceSettings.ValidatedField.TAX_NUMBER,
    Keys.EU_VAT_NUMBER, InvoiceSettings.ValidatedField.EU_VAT_NUMBER,
    Keys.EMAIL_ADDRESS, InvoiceSettings.ValidatedField.EMAIL_ADDRESS,
    Keys.PHONE_NUMBER, InvoiceSettings.ValidatedField.PHONE_NUMBER,
    Keys.BANK_NAME, InvoiceSettings.ValidatedField.BANK_NAME,
    Keys.BANK_ACCOUNT, InvoiceSettings.ValidatedField.BANK_ACCOUNT,
    Keys.WEB_ADDRESS, InvoiceSettings.ValidatedField.WEB_ADDRESS,
    Keys.REGISTRATION_NUMBER, InvoiceSettings.ValidatedField.REGISTRATION_NUMBER,
    Keys.EMAIL_TEXT, InvoiceSettings.ValidatedField.EMAIL_TEXT,
    Keys.ADDRESS, InvoiceSettings.ValidatedField.ADDRESS,
    Keys.PASSWORD, InvoiceSettings.ValidatedField.PASSWORD,
    Keys.INVOICE_DIRECTION_TYPE, InvoiceSettings.ValidatedField.INVOICE_DIRECTION_TYPE,
    Keys.INBOUND_STOCK_ID, InvoiceSettings.ValidatedField.INBOUND_STOCK_ID,
  );

  public static toOrderFieldKey(field: InvoiceSettings.OrderField): string {
    return Keys.orderFieldKeyMap.get(field)!;
  }

  public static toValidatedField(fieldKey: string): InvoiceSettings.ValidatedField {
    return Keys.keyValidatedFieldMap.get(fieldKey, InvoiceSettings.ValidatedField.UNKNOWN);
  }

}
