/* eslint-disable */
import { EmptyMessage } from '../util/messages';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { ResourceQueryResult } from '../util/services';
import { HistoryLog } from '../history-log/history-log.service';
import { CompanyResource } from '../company/company-resource.service';
/* eslint-enable */

@Injectable()
export class CompanyStockResourceService extends BaseHttpService {

  query(request: CompanyStockResource.QueryRequest): Observable<ResourceQueryResult<CompanyStockResource.CompanyStock>> {
    return this.http.get<ResourceQueryResult<CompanyStockResource.CompanyStock>>(this.url, this.parseParams(request));
  }

  get(request: CompanyStockResource.GetRequest): Observable<CompanyStockResource.CompanyStock> {
    return this.http.get<CompanyStockResource.CompanyStock>(this.url + `${request.id}`);
  }

  create(request: CompanyStockResource.CreateRequest): Observable<EmptyMessage> {
    return this.http.post(this.url, request);
  }

  update(request: CompanyStockResource.UpdateRequest): Observable<EmptyMessage> {
    return this.http.put(this.url + `${request.id}`, request);
  }

  setDisabled(request: CompanyStockResource.DisableRequest): Observable<EmptyMessage> {
    return this.http.patch(this.url + `${request.id}/disabled`, request);
  }

  history(request: CompanyResource.HistoryRequest): Observable<ResourceQueryResult<CompanyResource.HistoryItem>> {
    return this.http.get<ResourceQueryResult<CompanyResource.HistoryItem>>(
      this.url + `${request.id}/history-logs?with_read=${request.with_read}`,
      this.parseParams(request)
    );
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/company-stocks/');
  }

}

export namespace CompanyStockResource {

  export interface CompanyStock {
    id: number;
    external_id: string;
    name: string;
    company_location: CompanyLocationData
    assignee_user_group_id?: number;
    disabled: boolean;
  }

  export interface QueryRequest {
    id?: string;
    external_id?: string;
    name?: string;
    company_ids?: string;
    company_location_ids?: string;
    user_group_ids?: string;
    q?: string;
    disabled?: boolean;
    order?: string;
    page_number?: number;
    number_of_items?: number;
    no_progress_bar?: boolean;
  }

  export interface GetRequest {
    id: number;
  }

  export interface CreateRequest {
    external_id?: string;
    name: string;
    company_location_id: number;
    assignee_user_group_id?: number;
  }

  export interface UpdateRequest {
    id: number;
    external_id: string;
    name: string;
    company_location_id: number;
    assignee_user_group_id?: number;
  }

  export interface DisableRequest {
    id: number;
    disabled: boolean;
  }

  export interface CompanyLocationData {
    id: number;
    name: string;
  }

  export interface HistoryRequest {
    id: number;
    with_read: boolean;
    q?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
  }

  export interface HistoryItem extends HistoryLog.HistoryItemBaseResource {
  }

}
