import { GrantedPermissionSet, GrantedPermissionSetResolver } from '../../right.service';
import { OperationRights } from '../../../app.right-definitions';

export class InvoiceTagRightModel {

  public readonly create: GrantedPermissionSet;
  public readonly read: GrantedPermissionSet;
  public readonly update: GrantedPermissionSet;
  public readonly disable: GrantedPermissionSet;

  constructor(resolver: GrantedPermissionSetResolver) {
    this.create = resolver.of(OperationRights.INVOICE_TAG_CREATE);
    this.read = resolver.of(OperationRights.INVOICE_TAG_READ);
    this.update = resolver.of(OperationRights.INVOICE_TAG_UPDATE);
    this.disable = resolver.of(OperationRights.INVOICE_TAG_DISABLE);
  }
}
