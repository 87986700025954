<div class="card">

  <div class="d-flex flex-row justify-content-between base-data-header">
    <div class="d-flex flex-row flex-wrap">
      <div class="d-flex flex-row px-1 py-half align-items-center">
        <div>{{transportBaseData.waybillNumber}}</div>
      </div>
      <div class="d-flex flex-row px-1 py-third-quarter align-items-center">
        <i class="icomoon pr-half" [ngClass]="transportBaseData.stateIconClass"></i>
        <div>{{transportBaseData.state | translate}}</div>
      </div>
      <div class="d-flex flex-row px-1 py-third-quarter align-items-center">
        <i class="icomoon icomoon-company pr-half"></i>
        <div>{{transportBaseData.transporterCompany}}</div>
      </div>
      <div class="d-flex flex-row px-1 py-third-quarter align-items-center"
           [class.clickable-base-data]="(rightModel.transportSetDriver.hasRight() && underReplanning)
           || (rightModel.transportUpdate.hasRight() && underPlanning)"
           (click)="onDriverClick()">
        <i class="icomoon icomoon-driver pr-half"></i>
        <ng-template #driverData>{{transportBaseData.driver ? transportBaseData.driver : '-'}}</ng-template>
        <div
          *ngIf="!transportBaseData.driver && ((rightModel.transportSetDriver.hasRight() && underReplanning)
          || (rightModel.transportUpdate.hasRight() && underPlanning));
          else driverData">
          {{'TRANSPORT_SET_DRIVER' | translate}}
        </div>
      </div>
      <div class="d-flex flex-row px-1 py-third-quarter align-items-center"
           [class.clickable-base-data]="(rightModel.transportSetVehicle.hasRight() && underReplanning)
           || (rightModel.transportUpdate.hasRight() && underPlanning)"
           (click)="onVehicleClick()">
        <i class="icomoon icomoon-vehicles pr-half"></i>
        <ng-template #vehicleData>{{transportBaseData.vehicle ? transportBaseData.vehicle : '-'}}</ng-template>
        <div
          *ngIf="!transportBaseData.vehicle && ((rightModel.transportSetVehicle.hasRight() && underReplanning)
          || (rightModel.transportUpdate.hasRight() && underPlanning)); else vehicleData">
          {{'TRANSPORT_SET_VEHICLE' | translate}}
        </div>
      </div>
      <div *ngIf="transportBaseData.safetyShipping" class="d-flex flex-row px-1 py-third-quarter align-items-center">
        <i class="icomoon icomoon-safety-shipping pr-half"></i>
        <div>{{'TRANSPORT_SAFETY_SHIPPING' | translate}}</div>
      </div>
      <div class="d-flex flex-row px-1 py-third-quarter align-items-center"
           *ngIf="transportBaseData.safetyShipping"
           [class.clickable-base-data]="rightModel.transportSetSecurityGuard.hasRight() && underPlanning && transportBaseData.safetyShipping"
           (click)="onSecurityGuardClick()">
        <i class="icomoon icomoon-security-guard pr-half"></i>
        <ng-template
            #securityGuardData>{{transportBaseData.securityGuard ? transportBaseData.securityGuard : '-'}}</ng-template>
        <div
          *ngIf="!transportBaseData.securityGuard && rightModel.transportSetSecurityGuard.hasRight() && underPlanning && transportBaseData.safetyShipping; else securityGuardData">
          {{'TRANSPORT_SET_SECURITY_GUARD' | translate}}
        </div>
      </div>
    </div>
    <div class="d-flex flex-row flex-wrap">
      <div class="border-left d-flex flex-row px-1 py-third-quarter align-items-center cursor-pointer"
           (click)="onOpenBaseDataDetailButtonClicked()">
        <i class="icomoon icomoon-detail"></i>
      </div>
      <div *ngIf="!closed && rightModel.transportUpdate.hasRight()" (click)="onModifyBaseDataButtonClicked()"
           class="border-left d-flex flex-row px-1 py-half align-items-center cursor-pointer">
        <i class="icomoon icomoon-modify"></i>
      </div>
    </div>
  </div>

</div><!-- /.card -->
