<div bsModal [config]="UiConstants.modalConfig" #fieldDialog="bs-modal" (onHide)="onModalHide($event)"
     class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content" *ngIf="visible">
      <div class="modal-header">
        <h4 class="modal-title">{{dialogTitleDictionaryKey | translate}}</h4>
        <button type="button" class="close" (click)="closeDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="card-body">

          <div class="row">

            <!-- region Title -->
            <div class="form-group col-md-6"
                 [ngClass]="{ 'has-danger': hasFieldError(Form.FieldValidatedField.TITLE) || hasLocalFieldError(title)}">
              <label>{{'COMMON_NAME' | translate}}</label>
              <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
                     [ngClass]="{ 'form-control-danger': hasFieldError(Form.FieldValidatedField.TITLE) || hasLocalFieldError(title) }"
                     [(ngModel)]="fieldModel.title"
                     (ngModelChange)="removeFieldError(Form.FieldValidatedField.TITLE)"
                     name="title" id="title" #title="ngModel" required>
              <div class="form-control-feedback" *ngIf="hasFieldError(Form.FieldValidatedField.TITLE)">
                {{getFieldErrorText(Form.FieldValidatedField.TITLE)}}
              </div>
              <div class="form-control-feedback" *ngIf="hasLocalFieldError(title)">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
            <!-- endregion Title -->

          </div> <!-- ./row end -->

        </div> <!-- ./card-body end -->
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeDialog()">{{'COMMON_BUTTON_CANCEL' | translate}}</button>
        <button type="button" class="btn btn-primary" #functionButton (click)="saveField()">{{'COMMON_BUTTON_SAVE' | translate}}</button>
      </div>

    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
