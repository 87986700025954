<!-- Name -->
<div [formGroup]="formGroup" *ngIf="model">

  <div class="form-group row" [ngClass]="{ 'has-danger': hasLocalFieldError('name') }">
    <label
      class="col-form-label col-md-4 form-control-label text-md-right"
      [class.required-field-label]="!readonly">
      {{'COMMON_NAME'|translate}}
    </label>
    <div class="col-md-8" [ngClass]="{ 'has-danger': hasLocalFieldError('name') }">
      <div class="mw-100">
        <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
               [(ngModel)]="model.name"
               name="name"
               id="name"
               [disabled]="readonly"
               formControlName="name">
        <div class="form-control-feedback"
             *ngIf="hasLocalFieldError('name')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
        </div>
      </div>
    </div>
  </div>

  <!-- Invoice vat status -->
  <div class="form-group row"
       [ngClass]="{ 'has-danger': hasLocalFieldError('vatStatuses') }">
    <label
      class="col-form-label col-md-4 text-md-right"
      [class.required-field-label]="!readonly">
      {{'CUSTOMER_RECORD_INVOICE_VAT_STATUS' | translate}}
    </label>
    <div class="col-md-8">
      <angular2-multiselect
        class="form-control"
        [(ngModel)]="model._invoiceVatStatus"
        (ngModelChange)="onVatStatusChanged()"
        [data]="invoiceVatStatuses"
        [settings]="dropdownSettings"
        id="vatStatuses"
        formControlName="vatStatuses"
        [readonly]="readonly"
        [readonlyBorderEnabled]="true">
      </angular2-multiselect>
      <div class="form-control-feedback" *ngIf="hasLocalFieldError('vatStatuses')">
        {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
      </div>
    </div>
  </div>

  <!-- Tax number -->
  <div class="form-group row"
       *ngIf="model.invoiceVatStatus === InvoiceVatStatus.DOMESTIC">
    <label
      class="col-form-label col-md-4 form-control-label text-md-right"
      [class.required-field-label]="model.invoiceVatStatus === InvoiceVatStatus.DOMESTIC && !readonly">
      {{'CUSTOMER_RECORD_TAX_NUMBER'|translate}}
    </label>
    <div class="col-md-8" [ngClass]="{ 'has-danger': hasLocalFieldError('taxNumber') }">
      <input type="text" class="form-control" placeholder="{{'CUSTOMER_RECORD_TAX_NUMBER' | translate}}"
             [(ngModel)]="model.taxNumber"
             name="taxNumber"
             id="taxNumber"
             formControlName="taxNumber"
             [textMask]="{mask: InputMask.HU_TAX_NUMBER, guide: true}">
      <div class="form-control-feedback"
           *ngIf="hasLocalFieldError('taxNumber')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
      </div>
    </div>
  </div>

  <!-- Eu tax number -->
  <div class="form-group row"
       *ngIf="model.invoiceVatStatus === InvoiceVatStatus.OTHER">
    <label
      class="col-form-label col-md-4 form-control-label text-md-right">
      {{'CUSTOMER_RECORD_EU_TAX_NUMBER'|translate}}
    </label>
    <div class="col-md-8" [ngClass]="{ 'has-danger': hasLocalFieldError('euTaxNumber') }">
      <input type="text" class="form-control" placeholder="{{'CUSTOMER_RECORD_EU_TAX_NUMBER' | translate}}"
             [(ngModel)]="model.euTaxNumber"
             name="euTaxNumber"
             id="euTaxNumber"
             formControlName="euTaxNumber">
      <div class="form-control-feedback"
           *ngIf="hasLocalFieldError('euTaxNumber', 'validateEuVatNumber')">{{'COMMON_VALIDATION_MESSAGE_INVALID_EU_VAT_NUMBER' | translate}}
      </div>
    </div>
  </div>

  <!-- Bank account -->
  <div class="form-group row"
       *ngIf="!readonly || model.bankAccount.length > 0">
    <label
      class="col-form-label col-md-4 form-control-label text-md-right">
      {{'INVOICE_SETTINGS_BANK_ACCOUNT_NUMBER'|translate}}
    </label>
    <div class="col-md-8">
      <input type="text" class="form-control"
             placeholder="{{'INVOICE_SETTINGS_BANK_ACCOUNT_NUMBER' | translate}}"
             [(ngModel)]="model.bankAccount"
             name="bankAccount"
             id="bankAccount"
             formControlName="bankAccount"
             [textMask]="{mask: InputMask.bankAccountNumberMask, guide: true}">
    </div>
  </div>

  <!-- Invoice address -->
  <div *ngIf="!readonly">
    <label class="col-form-label mw-100 form-control-label font-weight-bold">
      {{'CUSTOMER_RECORD_BILLING_INFO_INVOICE_ADDRESS'|translate}}
    </label>

    <div class="form-group">
      <app-postal-address-complex
        [form]="form"
        [allowSimpleAddress]="true"
        [model]="model.invoiceAddress">
      </app-postal-address-complex>
    </div>
  </div>

  <div class="form-group row"
       *ngIf="readonly">
    <label
      class="col-form-label col-md-4 form-control-label text-md-right">
      {{'CUSTOMER_RECORD_BILLING_INFO_INVOICE_ADDRESS'|translate}}
    </label>
    <div class="col-md-8">
      <label> {{model.invoiceAddressString}}</label>
    </div>
  </div>
</div>
