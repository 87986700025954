<div class="card">
  <div class="card-header">
    {{'COMMON_DOCUMENTS' | translate}}
    <div class="card-actions">
      <a class="btn-setting table-header-action-button d-flex align-items-center px-1 w-auto font-weight-normal"
         (click)="onDocumentCreate.emit()"
         *ngIf="!readonly && exteriorTransportRights.documentCreate.hasRight()"
         title="{{'TABLE_DOCUMENT_CREATE_TITLE' | translate}}">
        <i class="icomoon pr-1 icomoon-add"></i>{{'TABLE_DOCUMENT_CREATE_TITLE' | translate}}
      </a>
    </div><!-- /.card-actions -->
  </div>
  <div class="card-body">
    <table class="table table-striped table-bordered mb-0">
      <thead>
      <tr>
        <th class="table-sorter-header">
          <app-table-field-sorter [orderField]="OrderFunctions.TYPE"
                                  [orderType]="getOrderType(OrderFunctions.TYPE)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'ORDER_DOCUMENT_TYPE' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header">
          <app-table-field-sorter [orderField]="OrderFunctions.CREATION_TIME"
                                  [orderType]="getOrderType(OrderFunctions.CREATION_TIME)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'COMMON_UPLOADED_TIME' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header w-10">
          <app-table-sorter-no-op></app-table-sorter-no-op>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr class="table"
          *ngFor="let document of documentList">
        <td class="responsive-table-column">{{getTypeKey(document.type) | translate}}</td>
        <td class="responsive-table-column">{{document.creationTime.toUtcIsoString() | date:'short'}}</td>
        <td class="responsive-table-column text-right">
          <a class="icons-table-group cursor-pointer"
             *ngIf="exteriorTransportRights.documentDownload.hasRight()"
             (click)="downloadFile(document)">
            <i class="icomoon icons-table-item icomoon-download icomoon-modify-table"
               title="{{'COMMON_BUTTON_DOWNLOAD' | translate}}"></i>
          </a>
          <!--todo: rights-->
          <a class="icons-table-group cursor-pointer"
             *ngIf="!readonly && document.uploadedByUser && exteriorTransportRights.documentDelete.hasRight()"
             (click)="deleteFile(document.id)">
            <i class="icomoon icons-table-item icomoon-trash icomoon-modify-table"
               title="{{'COMMON_BUTTON_DELETE' | translate}}"></i>
          </a>
        </td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <th class="table-sorter-header">
          <app-table-field-sorter [orderField]="OrderFunctions.TYPE"
                                  [orderType]="getOrderType(OrderFunctions.TYPE)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'ORDER_DOCUMENT_TYPE' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header">
          <app-table-field-sorter [orderField]="OrderFunctions.CREATION_TIME"
                                  [orderType]="getOrderType(OrderFunctions.CREATION_TIME)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'COMMON_UPLOADED_TIME' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header w-10">
          <app-table-sorter-no-op></app-table-sorter-no-op>
        </th>
      </tr>
      </tfoot>
    </table>
  </div>
</div>
