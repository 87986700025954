import { Injectable } from '@angular/core';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { Observable } from 'rxjs';
import { EmptyMessage, IdentityMessage } from '../util/messages';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class EmailAddressTypeResourceService extends BaseHttpService {

  getList(): Observable<EmailAddressTypeResource.EmailAddressType[]> {
    return this.http.get<EmailAddressTypeResource.EmailAddressType[]>(this.url);
  }

  get(request: IdentityMessage): Observable<EmailAddressTypeResource.EmailAddressType> {
    return this.http.get<EmailAddressTypeResource.EmailAddressType>(this.url + `${request.id}`);
  }

  create(request: EmailAddressTypeResource.CreateRequest): Observable<IdentityMessage> {
    return this.http.post<IdentityMessage>(this.url, request);
  }

  update(request: EmailAddressTypeResource.UpdateRequest): Observable<EmptyMessage> {
    return this.http.put<EmptyMessage>(this.url + `${request.id}`, request);
  }

  setDisabled(request: EmailAddressTypeResource.DisableRequest): Observable<EmptyMessage> {
    return this.http.patch<EmptyMessage>(this.url + `${request.id}/disabled`, request);
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/email-address-types/');
  }

}

export namespace EmailAddressTypeResource {

  export interface EmailAddressType {
    id: number;
    external_id: string;
    disabled: boolean;
    names: Object;
  }

  export interface CreateRequest {
    external_id: string;
    names: Object;
  }

  export interface UpdateRequest extends CreateRequest {
    id: number;
  }

  export interface DisableRequest {
    id: number;
    disabled: boolean;
  }

}
