import { DqlQuery, Query } from '../field';
import { Workflow as WorkflowNS } from '../../workflow/workflow.service';

export class Workflow {

  readonly id: Query.NumberField;
  readonly baseId: Query.NumberField;
  readonly externalId: Query.StringField;
  readonly name: Query.StringField;
  readonly versionState: Query.EnumField<WorkflowNS.VersionState>;

  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this.id = new DqlQuery.NumberField(prefix + 'id');
    this.baseId = new DqlQuery.NumberField(prefix + 'base_id');
    this.externalId = new DqlQuery.StringField(prefix + 'external_id');
    this.name = new DqlQuery.StringField(prefix + 'name');
    this.versionState = new DqlQuery.StringField(prefix + 'version_state');
  }

}
