/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseHttpService, HeaderKeys, ResourceHelper } from './util/http-services';
import { ResourceQueryResult } from './util/services';

/* eslint-enable */

@Injectable()
export class CountryResourceService extends BaseHttpService {

  countryNameQuery(request: CountryResource.CountryNameQueryRequest): Observable<ResourceQueryResult<CountryResource.CountryName>> {
    return this.http.get<ResourceQueryResult<CountryResource.CountryName>>(this.url, this.parseParams(request));
  }

  addressTypeQuery(request: CountryResource.AddressTypeQueryRequest): Observable<ResourceQueryResult<string>> {
    return this.http.get<ResourceQueryResult<string>>(this.url + `${request.country_code}/street-types`, this.parseParams(request));
  }

  zipCodeQuery(request: CountryResource.ZipCodeQueryRequest): Observable<ResourceQueryResult<string>> {
    return this.http.get<ResourceQueryResult<string>>(this.url + `${request.country_code}/zip-codes`, this.parseParams(request));
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/countries/');
  }

}

export namespace CountryResource {

  export interface AddressTypeQueryRequest {
    country_code: string;
    q?: string;
    page_number?: number;
    number_of_items?: number;
    no_progress_bar?: boolean;
  }

  export interface CountryNameQueryRequest {
    name?: string;
    page_number?: number;
    number_of_items?: number;
    no_progress_bar?: boolean;
  }

  export interface ZipCodeQueryRequest {
    country_code: string;
    q?: string;
    page_number?: number;
    number_of_items?: number;
    no_progress_bar?: boolean;
  }

  export interface CountryName {
    country_code: string;
    name: string;
  }
}

@Injectable()
export class CityResourceService extends BaseHttpService {

  queryCity(request: CityResource.QueryRequest): Observable<ResourceQueryResult<CityResource.City>> {
    let headers = new HttpHeaders();
    if (request.token) {
      headers = headers.set(HeaderKeys.PLACE_AUTOCOMPLETE_TOKEN, request.token);
      request.token = undefined;
    }
    return this.http.get<ResourceQueryResult<CityResource.City>>(this.url, {
      headers: headers,
      params: this.parseParams(request).params
    });
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/cities/');
  }

}

export namespace CityResource {

  export interface QueryRequest {
    with_zip_codes?: boolean;
    with_streets?: boolean;
    country_code?: string;
    native_name?: string;
    zip_code?: string;
    zip_code_start?: string;
    page_number?: number;
    number_of_items?: number;
    token?: string;
    no_progress_bar?: boolean;
  }

  export interface City {
    country_code: string; // Equals with County.country_code if county is known.
    county?: string; // In some country, we have no county records.
    native_name: string;
    zip_codes?: string[]; // Null if GlobalQueryRequest.with_zip_codes is false.
    streets?: Street[]; // Null if GlobalQueryRequest.with_streets is false.
  }

  export interface Street {
    native_name: string;
    native_type: string;
  }

}
