import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { TranslateService } from '@ngx-translate/core';
import { Transition } from '@uirouter/core';
import { StateName } from '../../../app.state-names';
import { MileageRecord, MileageRecordService } from '../../../lib/mileage-record/mileage-record.service';
import { Dates } from '../../../lib/util/dates';
import { MileageRecordModel } from '../mileage-record.model';
import { GoogleMapsLoaderUtil } from '../../../util/google/google-maps-loader.util';
import { DatePipe } from '@angular/common';
import { RightModel } from '../../../app.rights';
import { RightResolver, RightService } from '../../../lib/right.service';

@Component({
  selector: 'app-mileage-record-detail',
  templateUrl: './mileage-record-detail.component.html',
  styleUrls: ['./mileage-record-detail.component.scss']
})
export class MileageRecordDetailComponent implements OnInit, AfterViewInit {

  model: MileageRecordModel = new MileageRecordModel();
  rightModel: RightModel = RightModel.empty();

  @ViewChild('gmap', { static: true }) gmapElement: any;
  map: google.maps.Map;
  private markers: google.maps.Marker[] = [];
  private mileageRecordLocations: MileageRecordLocation[] = [];
  private isMapReady: boolean = false;

  private mileageRecordId: number;
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  constructor(
    private mileageRecordService: MileageRecordService,
    private translateService: TranslateService,
    private transition: Transition,
    private rightService: RightService,
    private datePipe: DatePipe) {
    this.mileageRecordId = this.transition.params().mileageRecordId;
  }

  ngOnInit() {
    this.initBreadcrumb();
    this.loadRightModels();
  }

  ngAfterViewInit(): void {
    this.initMap(() => {
      this.setUpCoordinates();
      this.loadMapBounds();
    });
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  private initBreadcrumb() {
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('MENU_NAVBAR_MILEAGE_RECORDS').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.MILEAGE_RECORD_LIST});
      }
    );
  }

  loadModel(completion?: () => void) {
    this.mileageRecordService.get({
      id: this.mileageRecordId
    }).subscribe(
        (mileageRecord: MileageRecord.MileageRecord) => {
          this.model.id = mileageRecord.id;
          this.model.user = mileageRecord.user;
          this.model.vehicleLicencePlate = mileageRecord.vehicle.licencePlate;
          this.model.vehicleId = mileageRecord.vehicle.id;
          this.model.startKilometer = mileageRecord.startKilometer.toNumber();
          this.model.creationTime = mileageRecord.creationTime;
          this.model.finishTime = mileageRecord.finishTime ? mileageRecord.finishTime : Dates.emptyOffsetDateTime();
          this.model.finishKilometer = mileageRecord.finishKilometer ? mileageRecord.finishKilometer.toNumber() : undefined;
          this.model.note = mileageRecord.note ? mileageRecord.note : '';
          this.model.journeyType = mileageRecord.journeyType ? mileageRecord.journeyType : '';
          this.model.startCoordinates = mileageRecord.startCoordinates;
          this.model.finishCoordinates = mileageRecord.finishCoordinates;
          if (mileageRecord.taskRecord) {
            this.model.taskId = mileageRecord.taskRecord.taskId;
            this.model.taskRecordId = mileageRecord.taskRecord.id;
            this.model.taskName = mileageRecord.taskRecord.name;
            this.model.customerName = mileageRecord.taskRecord.customerName ? mileageRecord.taskRecord.customerName : '';
          }
          this.breadcrumbSelf = mileageRecord.taskRecord ? mileageRecord.taskRecord.name : mileageRecord.id + '';
          if (completion) {
            completion();
          }
        }
      );
  }

  initMap(completion: () => void) {
    if (GoogleMapsLoaderUtil.didMapLoad()) {
      this.createMap();
      this.loadModel(() => {
        completion();
      });
    }
    else {
      GoogleMapsLoaderUtil.subscribe(() => {
        this.createMap();
        this.loadModel(() => {
          completion();
        });
      });
    }
  }

  createMap() {
    const mapProp = {
      center: new google.maps.LatLng(47.49801, 19.03991),
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      streetViewControl: false,
      mapTypeControl: false
    };
    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
    const context = this;
    google.maps.event.addListenerOnce(this.map, 'idle', function () {
      context.isMapReady = true;
    });
  }

  setUpCoordinates() {
      this.mileageRecordLocations = [];
      if (this.model.startCoordinates) {
        const lat = this.model.startCoordinates.latitude.toNumber();
        const lon = this.model.startCoordinates.longitude.toNumber();
        const time = this.datePipe.transform(this.model.creationTime.toUtcIsoString(), 'short');
        const mileageRecordLocation = new MileageRecordLocation();
        mileageRecordLocation.time = time!;
        mileageRecordLocation.coords = new google.maps.LatLng(lat, lon);
        this.mileageRecordLocations.push(mileageRecordLocation)
      }
      if (this.model.finishCoordinates) {
        const lat = this.model.finishCoordinates.latitude.toNumber();
        const lon = this.model.finishCoordinates.longitude.toNumber();
        const time = this.datePipe.transform(this.model.finishTime.toUtcIsoString(), 'short');
        const mileageRecordLocation = new MileageRecordLocation();
        mileageRecordLocation.time = time!;
        mileageRecordLocation.coords = new google.maps.LatLng(lat, lon);
        this.mileageRecordLocations.push(mileageRecordLocation)
      }
  }

  private loadMapBounds() {
    const bounds = new google.maps.LatLngBounds();
    this.markers.forEach(m => m.setMap(null));
    this.markers = [];
    this.mileageRecordLocations.forEach((location, index) => {
      bounds.extend(location.coords);

      const infoWindow = new google.maps.InfoWindow({
        content: '<center><b>' + this.translateService.instant('MILEAGE_RECORD_TIME_LABEL') + ': ' + location.time + '</b></center><br/>' +
          '<center><b>' + this.translateService.instant('TASK_GEOCODING_COORDINATES') + ': ' + location.coordinateText + '</b></center>'
      });

      const marker = new google.maps.Marker({
        position: location.coords,
        map: this.map,
        label: {
          text: (1 + index) + '',
          fontSize: '16px'
        },
        icon: {
          url: this.resolvePoiUrl(),
          labelOrigin: this.getLabelOrigin()
        },
        zIndex: index

      });
      marker.addListener('click', function () {
        infoWindow.open(map, marker);
      });
      this.markers[index] = marker;
    });

    const map = this.map;
    if (!bounds.isEmpty()) {
      if (this.isMapReady) {
        map.fitBounds(bounds);
        map.panToBounds(bounds);
      }
      else {
        google.maps.event.addListenerOnce(this.map, 'idle', function () {
          map.fitBounds(bounds);
          map.panToBounds(bounds);
        });
      }
    }
  }

  private resolvePoiUrl() {
    return '../../../assets/img/poi/ic_map_poi_number.svg';
  }

  private getLabelOrigin() {
    return new google.maps.Point(18, 15);
  }
}

class MileageRecordLocation {
  time: string;
  coords: google.maps.LatLng;

  get coordinateText(): string {
    return this.coords.lat().toString() + ', ' + this.coords.lng().toString();
  }
}
