<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div *ngIf="rightModel.tableDocumentCreate.hasRight()"
       class="breadcrumb-menu d-flex">
    <div *ngIf="rightModel.tableDocumentSchemaCreate.hasRight()"
         (click)="showBaseDialog()"
         class="breadcrumb-button-icon-container negative-margin-right cursor-pointer"
    >
      <a class="btn-setting" title="{{'TABLE_DOCUMENT_CREATE' | translate}}">
        <i class="icomoon icomoon-add"></i>
        <span class="breadcrumb-button-text-visible">{{'TABLE_DOCUMENT_CREATE' | translate}}</span>
      </a>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>

<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="card">

    <div class="card-header">
      {{'MENU_NAVBAR_TABLE_DOCUMENT' | translate}}
      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()"
           title="{{'COMMON_SHOW_SEARCH_HINT' | translate}}"><i class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>

    <div class="card-body border-bottom" *ngIf="showSearch">
      <div class="row form-group">
        <div class="col-md-3">
          <label class="search-label">{{'LIST_ITEM_CODE' | translate}}</label>
          <input type="text" class="form-control"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 placeholder="{{'LIST_ITEM_CODE' | translate}}"
                 [(ngModel)]="searchModel.code">
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'COMMON_NAME' | translate}}</label>
          <input type="text" class="form-control"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 placeholder="{{'COMMON_NAME' | translate}}"
                 [(ngModel)]="searchModel.name">
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'TABLE_DOCUMENT_SCHEMA_NAME' | translate}}</label>
          <select class="form-control" [compareWith]="SelectUtils.compareNumbersWithNull" [(ngModel)]="searchModel.schemaId"
                  id="schemaChooser" name="schemaChooser" #schemaChooser="ngModel">
            <option *ngFor="let item of schemaItems" [ngValue]="item.schemaId">{{item.name + ' (' + item.version + ')'}}</option>
          </select>
        </div>
      </div>
      <div class="col-md-12 d-flex justify-content-md-end align-items-end px-0">
        <div class="btn-group" role="group">
          <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
            {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
          </button>
          <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
            {{'COMMON_BUTTON_SEARCH' | translate}}
          </button>
        </div>
      </div>
    </div><!-- /.card-body-->

    <div class="card-body">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header w-5 hidden-sm-down">
            <app-table-field-sorter [orderField]="TableDocument.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(TableDocument.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-20 hidden-sm-down">
            <app-table-field-sorter [orderField]="TableDocument.OrderField.CODE"
                                    [orderType]="queryModel.getOrderType(TableDocument.OrderField.CODE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'LIST_ITEM_CODE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="TableDocument.OrderField.NAME"
                                    [orderType]="queryModel.getOrderType(TableDocument.OrderField.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-sorter-no-op [text]="'TABLE_DOCUMENT_SCHEMA_NAME' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-sorter-no-op [text]="'COMMON_UPDATE_TIME' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-sorter-no-op [text]="'TABLE_DOCUMENT_SCHEMA_FIELD_NOTE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-actions-four">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let tableDocument of tableDocumentList | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }">
          <td class="responsive-table-column w-5 hidden-sm-down">{{tableDocument.documentId}}</td>
          <td class="responsive-table-column w-20 hidden-sm-down">{{tableDocument.code}}</td>
          <td class="responsive-table-column">{{tableDocument.name}}</td>
          <td class="responsive-table-column hidden-xs-down">{{tableDocument.schemaVersion}}</td>
          <td class="responsive-table-column hidden-lg-down">{{tableDocument.contentUpdateTime | date:'short'}}</td>
          <td class="responsive-table-column hidden-lg-down">{{tableDocument.note}}</td>
          <td class="responsive-table-column">
            <a *ngIf="rightModel.tableDocumentUpdate.hasRight()"
               class="icons-table-group cursor-pointer"
               (click)="showBaseDialog(tableDocument)">
              <i class="icomoon icons-table-item icomoon-modify icomoon-modify-table"
                 title="{{'COMMON_BUTTON_EDIT' | translate}}"></i>
            </a>
            <a *ngIf="rightModel.tableDocumentDelete.hasRight()"
               class="icons-table-group cursor-pointer" (click)="openDeleteModal(tableDocument)">
              <i class="icomoon icons-table-item icomoon-trash icomoon-disable-table"
                 title="{{'COMMON_BUTTON_DELETE' | translate}}"></i>
            </a>
            <a *ngIf="rightModel.tableDocumentRead.hasRight() && tableDocument.exportable"
               class="icons-table-group cursor-pointer" (click)="exportDocument(tableDocument)">
              <i class="icomoon icons-table-item icomoon-csv-export"
                 title="{{'COMMON_EXPORT' | translate}}"></i>
            </a>
            <a *ngIf="rightModel.tableDocumentReplace.hasRight()"
               class="icons-table-group cursor-pointer" (click)="importDocument(tableDocument)">
              <i class="icomoon icons-table-item icomoon-csv-import"
                 title="{{'COMMON_IMPORT' | translate}}"></i>
            </a>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header w-5 hidden-sm-down">
            <app-table-field-sorter [orderField]="TableDocument.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(TableDocument.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-20 hidden-sm-down">
            <app-table-field-sorter [orderField]="TableDocument.OrderField.CODE"
                                    [orderType]="queryModel.getOrderType(TableDocument.OrderField.CODE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'LIST_ITEM_CODE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="TableDocument.OrderField.NAME"
                                    [orderType]="queryModel.getOrderType(TableDocument.OrderField.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-sorter-no-op [text]="'TABLE_DOCUMENT_SCHEMA_NAME' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-sorter-no-op [text]="'COMMON_UPDATE_TIME' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-sorter-no-op [text]="'TABLE_DOCUMENT_SCHEMA_FIELD_NOTE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-actions-four">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>
  </div>
</div>

<!--BaseDialog Modal-->
<div bsModal [config]="UiConstants.modalConfig" #baseDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="baseDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{getBaseModalTitle() | translate}}</h4>
        <button type="button" class="close" (click)="closeBaseDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" [formGroup]="formGroup" #f="ngForm">
        <div class="form-group row"
             [ngClass]="{ 'has-danger': hasLocalFieldError('schema')}">
          <label class="col-form-label col-md-4">{{'TABLE_DOCUMENT_SCHEMA_NAME' | translate}}</label>
          <div class="col-md-8">
            <select
              *ngIf="tableDocumentEditModel.status === TableDocumentEditStatus.CREATE"
              class="form-control"
              formControlName="schema"
              [(ngModel)]="tableDocumentEditModel.schema"
              [compareWith]="SelectUtils.compareObjects">
              <option *ngFor="let item of schemaItems" [ngValue]="item">{{item.name + ' (' + item.version + ')'}}</option>
            </select>
            <a *ngIf="tableDocumentEditModel.status === TableDocumentEditStatus.EDIT"
               title="{{tableDocumentEditModel.schema.code + ' (' + tableDocumentEditModel.schema.version + ')'}}"
               uiSref="Admin.TableDocumentSchemaDetail"
               [uiParams]="{ id: tableDocumentEditModel.schemaId }">
                    <span class="form-control detail-description link-applion-green"
                          readonly><u>{{tableDocumentEditModel.schema.code + ' (' + tableDocumentEditModel.schema.version + ')'}}</u></span>
            </a>

            <div class="form-control-feedback"
                 *ngIf="hasLocalFieldError('schema')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
          </div>
          <!-- /.col-md-8 -->
        </div>
        <div class="form-group row"
             [ngClass]="{ 'has-danger': hasLocalFieldError('code') || fieldErrors.code}">
          <label class="col-form-label col-md-4">{{'LIST_ITEM_CODE' | translate}}</label>
          <div class="col-md-8">
            <input *ngIf="tableDocumentEditModel.status === TableDocumentEditStatus.CREATE"
                   type="text" class="form-control"
                   placeholder="{{'LIST_ITEM_CODE' | translate}}"
                   maxlength="{{UiConstants.maximumVarcharLength}}"
                   [ngClass]="{ 'form-control-danger': hasLocalFieldError('code') }"
                   [(ngModel)]="tableDocumentEditModel.code"
                   formControlName="code"
                   (ngModelChange)="removeFieldError(fieldErrors.code)">
            <label
              *ngIf="tableDocumentEditModel.status === TableDocumentEditStatus.EDIT"
              class="form-control detail-description"
              readonly>{{tableDocumentEditModel.code}}
            </label>
            <div class="form-control-feedback"
                 *ngIf="fieldErrors.code">{{fieldErrors.code.text}}</div>
            <div class="form-control-feedback"
                 *ngIf="hasLocalFieldError('code')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
          </div>
          <!-- /.col-md-8 -->
        </div>

        <div class=" form-group row"
             [ngClass]="{ 'has-danger': hasLocalFieldError('name') || fieldErrors.name}">
          <label class="col-form-label col-md-4">{{'COMMON_NAME' | translate}}</label>
          <div class="col-md-8">
            <input type="text" class="form-control"
                   placeholder="{{'COMMON_NAME' | translate}}"
                   maxlength="{{UiConstants.maximumVarcharLength}}"
                   [ngClass]="{ 'form-control-danger': hasLocalFieldError('name') }"
                   [(ngModel)]="tableDocumentEditModel.name"
                   formControlName="name"
                   (ngModelChange)="removeFieldError(fieldErrors.name)">
            <div class="form-control-feedback"
                 *ngIf="fieldErrors.name">{{fieldErrors.name.text}}</div>
            <div class="form-control-feedback"
                 *ngIf="hasLocalFieldError('name')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
          </div>
          <!-- /.col-md-8 -->
        </div>

        <div class=" form-group row">
          <label class="col-form-label col-md-4">{{'TABLE_DOCUMENT_SCHEMA_FIELD_NOTE' | translate}}</label>
          <div class="col-md-8">
            <input type="text"
                   class="form-control"
                   formControlName="note"
                   placeholder="{{'TABLE_DOCUMENT_SCHEMA_FIELD_NOTE' | translate}}"
                   maxlength="{{UiConstants.maximumVarcharLength}}"
                   [(ngModel)]="tableDocumentEditModel.note"
            >
          </div>
          <!-- /.col-md-8 -->
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeBaseDialog()">
          {{'COMMON_BUTTON_CANCEL' | translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="createTableDocument()">{{'COMMON_BUTTON_SAVE' |
          translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<app-file-upload-dialog
  [uploadPath]="schemaImportPath"
  (onResult)="onImportSuccess($event)"
  #documentImportDialog
></app-file-upload-dialog>

<div bsModal [config]="UiConstants.modalConfig" #deleteModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="deleteModalVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'TABLE_DOCUMENT_SCHEMA_DELETE' | translate}}</h4>
        <button type="button" class="close" (click)="closeDeleteModal()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group col-md-12 mb-0">
          <label>{{'TABLE_DOCUMENT_SCHEMA_DELETE_LABEL' | translate}}</label>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeDeleteModal()">
          {{'COMMON_BUTTON_CANCEL' | translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="deleteDocument()">{{'COMMON_BUTTON_DELETE' |
          translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
