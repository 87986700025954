/* eslint-disable */
import {
  FormEditFieldCreateDialogView,
  FormEditFieldDialogViewFactory,
  FormEditFieldUpdateDialogView,
} from './form-utils';
import { ComponentFactoryResolver, ComponentRef, Injector, } from '@angular/core';
import { FormEditCreateUpdateStockIntakeFieldDialogComponent } from '../../admin/form/form-edit/dialogs/fields/stock-intake/create-update/form-edit-create-update-stock-intake-field-dialog.component';

/* eslint-enable */

/**
 * Stock intake field implementation.
 */
export class FormEditStockIntakeFieldDialogViewFactory implements FormEditFieldDialogViewFactory {

  constructor(
    private injector: Injector,
    private cfr: ComponentFactoryResolver) {
  }

  createCreateView(): ComponentRef<FormEditFieldCreateDialogView> {
    return this.createView();
  }

  createUpdateView(): ComponentRef<FormEditFieldUpdateDialogView> {
    return this.createView();
  }

  private createView(): ComponentRef<FormEditCreateUpdateStockIntakeFieldDialogComponent> {
    return this.cfr.resolveComponentFactory(FormEditCreateUpdateStockIntakeFieldDialogComponent)
      .create(this.injector);
  }

}
