/* eslint-disable */
import { MultiselectOptionItem, OptionItem, UserGroupItem } from './core-utils';
import { TaskRecord } from '../lib/task/task-record.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TaskRecordStateMachine } from '../lib/task/task-record-statemachine';
import { Address } from '../lib/address';
import { SearchUtils } from './search-utils';
import { AppNgbTimeStruct } from './ngb-datepicker';
import { UserGroup } from '../lib/user-group.service';
import { User } from '../lib/user.service';
import { Device } from '../lib/device-management.service';
import { CustomerRecordFieldType } from './customer-record-utils';
import { ConfigurationResource } from '../lib/core-ext/configuration.service';
import { GrantedPermissionSet, GrantedPermissionSetResolver } from '../lib/right.service';
import { OperationRights } from '../app.right-definitions';
import SearchModel = SearchUtils.SearchModel;
import ExportState = TaskRecord.ExportState;
import CalendarDisplayLabel = ConfigurationResource.CalendarDisplayLabel;
import {TaskRecordSearch} from "../lib/task/task-record-search.service";

/* eslint-enable */

export class TaskRecordSearchModel extends SearchModel {

  id: string = '';
  name: string = '';
  externalId: string = '';
  customerName: string = '';
  customerPhoneNumber: string = '';
  placeOfConsumptionAddress: string = '';
  userGroup: UserGroupItem[] = [];
  assigneeUser: AssigneeItem[] = [];
  assigneeMobileApp: AssigneeDeviceItem[] = [];
  projects: MultiselectOptionItem<number>[] = [];
  processes: MultiselectOptionItem<number>[] = [];
  creatorUser: AssigneeItem[] = [];
  states: MultiselectOptionItem<TaskRecordStateMachine.State>[] = [];
  contractNumbers: MultiselectOptionItem<number>[] = [];
  importance: TaskRecord.TaskRecordImportance | null = null;
  deadlineFrom: NgbDateStruct | null = null;
  deadlineTo: NgbDateStruct | null = null;
  creationTimeFrom: NgbDateStruct | null = null;
  creationTimeTo: NgbDateStruct | null = null;
  updateTimeFrom: NgbDateStruct | null = null;
  updateTimeTo: NgbDateStruct | null = null;
  releaseTimeFrom: NgbDateStruct | null = null;
  releaseTimeTo: NgbDateStruct | null = null;
  agreedTimeFrom: NgbDateStruct | null = null;
  agreedTimeTo: NgbDateStruct | null = null;
  finishedTimeFrom: NgbDateStruct | null = null;
  finishedTimeTo: NgbDateStruct | null = null;
  placeOfConsumptionCity: string = '';
  placeOfConsumptionZipCode: string = '';
  placeOfConsumptionStreet: string = '';
  placeOfConsumptionHouseNumber: string = '';
  dqlText?: string = undefined;
  exportState: ExportState | null = null;
  tasks: TaskRecordSearch.TaskItem[] = [];
  emptyIntakePrices: boolean = false;

  public isEmpty(): boolean {
    return this.id.length === 0
      && this.name.length === 0
      && this.externalId.length === 0
      && this.customerName.length === 0
      && this.customerPhoneNumber.length === 0
      && this.placeOfConsumptionAddress.length === 0
      && this.assigneeUser.length === 0
      && this.assigneeMobileApp.length === 0
      && this.contractNumbers.length === 0
      && this.userGroup.length === 0
      && this.projects.length === 0
      && this.processes.length === 0
      && this.creatorUser.length === 0
      && this.states.length === 0
      && this.importance === null
      && this.exportState === null
      && this.deadlineFrom === null
      && this.deadlineTo === null
      && this.creationTimeFrom === null
      && this.creationTimeTo === null
      && this.updateTimeFrom === null
      && this.updateTimeTo === null
      && this.releaseTimeFrom === null
      && this.releaseTimeTo === null
      && this.agreedTimeFrom === null
      && this.agreedTimeTo === null
      && this.finishedTimeFrom === null
      && this.finishedTimeTo === null
      && this.placeOfConsumptionCity.length === 0
      && this.placeOfConsumptionZipCode.length === 0
      && this.placeOfConsumptionStreet.length === 0
      && this.placeOfConsumptionHouseNumber.length === 0
      && this.tasks.length === 0
      && this.dqlText === undefined
      && !this.emptyIntakePrices
      ;
  }

  public clear() {
    this.id = '';
    this.name = '';
    this.externalId = '';
    this.customerName = '';
    this.customerPhoneNumber = '';
    this.placeOfConsumptionAddress = '';
    this.assigneeUser = [];
    this.assigneeMobileApp = [];
    this.userGroup = [];
    this.projects = [];
    this.processes = [];
    this.creatorUser = [];
    this.contractNumbers = [];
    this.states = [];
    this.importance = null;
    this.exportState = null;
    this.deadlineFrom = null;
    this.deadlineTo = null;
    this.creationTimeFrom = null;
    this.creationTimeTo = null;
    this.updateTimeFrom = null;
    this.updateTimeTo = null;
    this.releaseTimeFrom = null;
    this.releaseTimeTo = null;
    this.agreedTimeFrom = null;
    this.agreedTimeTo = null;
    this.finishedTimeFrom = null;
    this.finishedTimeTo = null;
    this.placeOfConsumptionCity = '';
    this.placeOfConsumptionZipCode = '';
    this.placeOfConsumptionStreet = '';
    this.placeOfConsumptionHouseNumber = '';
    this.tasks = [];
    this.dqlText = undefined;
  }
}

export class TaskRecordImportanceItem extends OptionItem<TaskRecord.TaskRecordImportance> {
  badgeClass?: string;
}

export class TaskRecordExportStateItem extends OptionItem<TaskRecord.ExportState> {
}

export class TaskRecordStateItem extends OptionItem<TaskRecordStateMachine.State> {
  stateObject?: TaskRecordStateMachine.StateObject;
}

export class TaskRecordListModel {
  taskRecord: TaskRecord.TaskRecord;
  assignee: AssigneeListModel;
  customerName: string;
  selected: boolean = false;
  stateObject: TaskRecordStateMachine.StateObject;
  rights: TaskRecordRightModel = new TaskRecordRightModel(GrantedPermissionSetResolver.empty());
}

export class AssigneeModificationModel {
  _assigneeUser?: AssigneeItem[] = [];
  _assigneeMobileApp?: AssigneeDeviceItem[] = [];

  get assigneeUser(): AssigneeItem | undefined {
    return this._assigneeUser && this._assigneeUser.length > 0 ? this._assigneeUser[0] : undefined;
  }

  get assigneeMobileApp(): AssigneeDeviceItem | undefined {
    return this._assigneeMobileApp && this._assigneeMobileApp.length > 0 ? this._assigneeMobileApp[0] : undefined;
  }

}

export class AssigneeListModel {
  assigneeUser?: User;
  assigneeUserGroup?: UserGroup;
  assigneeMobileApp?: Device;
  miscellaneousAssigneeName?: string;

  get assigneeUserName(): string | undefined {
    return this.assigneeUser ? this.assigneeUser.person_name + ' (' + this.assigneeUser.user_name + ')' : undefined;
  }
  get assigneeUserGroupName(): string | undefined {
    return this.assigneeUserGroup ? this.assigneeUserGroup.name : undefined;
  }
  get assigneeMobileAppName(): string | undefined {
    return this.assigneeMobileApp ? this.assigneeMobileApp.name : undefined;
  }
}

export class DeadlineModificationModel {
  deadlineDate?: NgbDateStruct = undefined;
  deadlineTime?: AppNgbTimeStruct = undefined;
}

export type BukUpdateType =
  'ASSIGNEE' | 'DEADLINE';

export class TaskRecordEmailRecipientModel {
  sendToMe: boolean = false;
  sendToAssignee: boolean = false;
  sendToCustomer: boolean = false;
  otherRecipients?: string = '';
}

export class ConfigModel {
  assigneeWithUser: boolean = false;
  assigneeWithMobileApp: boolean = false;
  postalAddressFormat: string = '';
  customerRecordManagedFields: CustomerRecordFieldType[];
  calendarUserColorEnabled: boolean;
  calendarLabels: CalendarDisplayLabel[];
  calendarDisplayMonogram: boolean = false;
  calendarDisplayStartInMinutes: number = 0;
  calendarDisplayEndInMinutes: number = 1439;
  rejectedToArchived: boolean = false;
  navigateOnStateChange: boolean = false;
  taskRecordPhoneNumberRelatedList: boolean = false;
  customXlsxExportEnabled: boolean = false;
  supExportEnabled: boolean = false;
}

export class CustomerAddressItem extends MultiselectOptionItem<number | null> {
  postalAddress?: Address.PostalAddressData;
  contactLocationId?: number;
}

export interface AssigneeItem {
  id: number;
  itemName: string;
  disabled?: boolean;
}

export interface AssigneeDeviceItem {
  id: number;
  appId: string;
  itemName: string;
}

export class TaskRecordRightModel {

  public readonly read: GrantedPermissionSet;
  public readonly update: GrantedPermissionSet;
  public readonly changeDeadline: GrantedPermissionSet;
  public readonly changeAgreedTime: GrantedPermissionSet;
  public readonly changeEstimatedTime: GrantedPermissionSet;
  public readonly changeTimeSpent: GrantedPermissionSet;
  public readonly changeAssignee: GrantedPermissionSet;
  public readonly changeAssigneeInProgress: GrantedPermissionSet;
  public readonly changeImportance: GrantedPermissionSet;
  public readonly downloadSignatures: GrantedPermissionSet;
  public readonly uploadSignatures: GrantedPermissionSet;
  public readonly attachmentRead: GrantedPermissionSet;
  public readonly attachmentUploadFile: GrantedPermissionSet;
  public readonly attachmentDeleteFile: GrantedPermissionSet;
  public readonly attachmentUpdateData: GrantedPermissionSet;
  public readonly logRead: GrantedPermissionSet;
  public readonly chatRead: GrantedPermissionSet;
  public readonly chatMessageCreate: GrantedPermissionSet;
  public readonly documentRead: GrantedPermissionSet;
  public readonly changeStateArchive: GrantedPermissionSet;
  public readonly changeStateRecall: GrantedPermissionSet;
  public readonly changeStateOpen: GrantedPermissionSet;
  public readonly changeStateRestart: GrantedPermissionSet;
  public readonly changeStateReject: GrantedPermissionSet;
  public readonly changeStateStart: GrantedPermissionSet;
  public readonly changeStateReopen: GrantedPermissionSet;
  public readonly changeStatePause: GrantedPermissionSet;
  public readonly changeStateSubmit: GrantedPermissionSet;
  public readonly changeStateUnsubmit: GrantedPermissionSet;
  public readonly changeStateValidate: GrantedPermissionSet;
  public readonly changeStateRevert: GrantedPermissionSet;
  public readonly assignToMe: GrantedPermissionSet;
  public readonly subTaskCreate: GrantedPermissionSet;
  public readonly manualInvoiceCreate: GrantedPermissionSet;

  constructor(resolver: GrantedPermissionSetResolver) {
    this.read = resolver.of(OperationRights.TASK_RECORD_READ);
    this.update = resolver.of(OperationRights.TASK_RECORD_UPDATE);
    this.changeDeadline = resolver.of(OperationRights.TASK_RECORD_CHANGE_DEADLINE);
    this.changeAgreedTime = resolver.of(OperationRights.TASK_RECORD_CHANGE_AGREED_TIME);
    this.changeEstimatedTime = resolver.of(OperationRights.TASK_RECORD_CHANGE_ESTIMATED_TIME);
    this.changeTimeSpent = resolver.of(OperationRights.TASK_RECORD_CHANGE_TIME_SPENT);
    this.changeAssignee = resolver.of(OperationRights.TASK_RECORD_CHANGE_ASSIGNEE);
    this.changeAssigneeInProgress = resolver.of(OperationRights.TASK_RECORD_CHANGE_ASSIGNEE_IN_PROGRESS);
    this.changeImportance = resolver.of(OperationRights.TASK_RECORD_CHANGE_IMPORTANCE);
    this.downloadSignatures = resolver.of(OperationRights.TASK_RECORD_DOWNLOAD_SIGNATURES);
    this.uploadSignatures = resolver.of(OperationRights.TASK_RECORD_UPLOAD_SIGNATURES);
    this.attachmentRead = resolver.of(OperationRights.TASK_RECORD_ATTACHMENT_READ);
    this.attachmentUploadFile = resolver.of(OperationRights.TASK_RECORD_ATTACHMENT_UPLOAD_FILE);
    this.attachmentDeleteFile = resolver.of(OperationRights.TASK_RECORD_ATTACHMENT_DELETE_FILE);
    this.attachmentUpdateData = resolver.of(OperationRights.TASK_RECORD_ATTACHMENT_UPDATE_DATA);
    this.logRead = resolver.of(OperationRights.TASK_RECORD_LOG_READ);
    this.documentRead = resolver.of(OperationRights.TASK_RECORD_DOCUMENT_READ);
    this.chatRead = resolver.of(OperationRights.TASK_RECORD_CHAT_READ);
    this.chatMessageCreate = resolver.of(OperationRights.TASK_RECORD_CHAT_MESSAGE_CREATE);
    this.documentRead = resolver.of(OperationRights.TASK_RECORD_DOCUMENT_READ);
    this.changeStateArchive = resolver.of(OperationRights.TASK_RECORD_CHANGE_STATE_ARCHIVE);
    this.changeStateRecall = resolver.of(OperationRights.TASK_RECORD_CHANGE_STATE_RECALL);
    this.changeStateOpen = resolver.of(OperationRights.TASK_RECORD_CHANGE_STATE_OPEN);
    this.changeStateRestart = resolver.of(OperationRights.TASK_RECORD_CHANGE_STATE_RESTART);
    this.changeStateReject = resolver.of(OperationRights.TASK_RECORD_CHANGE_STATE_REJECT);
    this.changeStateStart = resolver.of(OperationRights.TASK_RECORD_CHANGE_STATE_START);
    this.changeStateReopen = resolver.of(OperationRights.TASK_RECORD_CHANGE_STATE_REOPEN);
    this.changeStatePause = resolver.of(OperationRights.TASK_RECORD_CHANGE_STATE_PAUSE);
    this.changeStateSubmit = resolver.of(OperationRights.TASK_RECORD_CHANGE_STATE_SUBMIT);
    this.changeStateUnsubmit = resolver.of(OperationRights.TASK_RECORD_CHANGE_STATE_UNSUBMIT);
    this.changeStateValidate = resolver.of(OperationRights.TASK_RECORD_CHANGE_STATE_VALIDATE);
    this.changeStateRevert = resolver.of(OperationRights.TASK_RECORD_CHANGE_STATE_REVERT);
    this.assignToMe = resolver.of(OperationRights.TASK_RECORD_ASSIGN_TO_ME);
    this.subTaskCreate = resolver.of(OperationRights.TASK_RECORD_SUB_TASK_CREATE);
    this.manualInvoiceCreate = resolver.of(OperationRights.TASK_RECORD_MANUAL_INVOICE_CREATE);
  }

}
