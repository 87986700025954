/* eslint-disable */
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Transition, UIRouter } from '@uirouter/angular';
import { combineLatest, Observable, Subject } from 'rxjs';
import { CustomerRecord, CustomerRecordService } from '../../../lib/customer/customer-record.service';
import { Customer, CustomerService } from '../../../lib/customer/customer.service';
import { CustomerRecordBase } from '../customer-record-base/customer-record-base-view';
import { CustomerRecordBaseComponent } from '../customer-record-base/customer-record-base.component';
import { StateName } from '../../../app.state-names';
import { FieldValidationError, OrderType, QueryResult } from '../../../lib/util/services';
import { FormRecordContainer } from '../../form/form-record/form-record-container';
import { FormRecordContainerComponent } from '../../form/form-record/form-record-container.component';
import { Form } from '../../../lib/form/form.service';
import { ConfigurationService, } from '../../../lib/core-ext/configuration.service';
import { Address, AddressModel, } from '../../../lib/address';
import { Country, CountryService, } from '../../../lib/country.service';
import { MultiselectOptionItem, UiConstants, } from '../../../util/core-utils';
import { UserDataLoader } from '../../../lib/user-data-loader';
import { UserGroupService, } from '../../../lib/user-group.service';
import { StringKey } from '../../../app.string-keys';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '../../../fork/angular2-toaster/angular2-toaster';
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { ConfirmLeaveModalComponent } from '../../../shared/confirm-leave-modal/confirm-leave-modal.component';
import { map } from 'rxjs/operators';
import { GrantedPermissionSetResolver, RightResolver, RightService } from '../../../lib/right.service';
import { RightModel } from '../../../app.rights';
import { ConfigModel } from '../../../util/task-record-utils';
import { Set } from 'immutable';
import { Models } from '../../../util/model-utils';
import { CustomerRecordContactPersonsComponent } from '../customer-record-contact-persons/customer-record-contact-persons.component';
import { Angular2Multiselects } from '../../../util/multiselect';
import { Strings } from '../../../lib/util/strings';
import {
  CustomerRecordContactLocationsListComponent
} from '../customer-record-contact-locations/customer-record-contact-locations-list/customer-record-contact-locations-list.component';
import { FileUploadDialogComponent } from '../../../shared/file-upload/dialog/file-upload-dialog.component';
import { UploadErrorLocalizer } from '../../../util/upload-error-localizer';
import { CustomerRecordFieldModel, CustomerRecordFieldType, CustomerRecordRightModel } from '../../../util/customer-record-utils';
import { ConfirmDialogComponent } from '../../../shared/confirm-dialog/confirm-dialog.component';
import {
  CustomerRecordBillingInfoListComponent
} from '../customer-record-billing-info/customer-record-billing-info-list/customer-record-billing-info-list.component';
import { MatDialog } from '@angular/material/dialog';
import {
  TaskRecordCreateDialogMode,
  TaskRecordCreateMaterialDialogComponent
} from '../../task/task-record/task-record-create-material-dialog/task-record-create-material-dialog.component';
import {
  CustomerRecordBillingInfoComponentMode
} from '../customer-record-billing-info/customer-record-billing-info-base/customer-record-billing-info-base.component';
import { OperationRights } from '../../../app.right-definitions';

/* eslint-enable */

@Component({
  selector: 'app-customer-record-edit',
  templateUrl: 'customer-record-edit.component.html',
  styleUrls: ['customer-record-edit.component.scss'],
})
export class CustomerRecordEditComponent implements OnInit, AfterViewInit, OnDestroy {
  UiConstants = UiConstants;
  CustomerRecordFieldType = CustomerRecordFieldType;
  CustomerRecordBillingInfoDialogMode = CustomerRecordBillingInfoComponentMode;

  @ViewChild(CustomerRecordBaseComponent, {static: true})
  baseView: CustomerRecordBase.View;

  @ViewChild(CustomerRecordBaseComponent, {static: true})
  baseComponent: CustomerRecordBaseComponent;

  @ViewChild(FormRecordContainerComponent, {static: true})
  formRecordContainerView: FormRecordContainer.View;

  @ViewChild(CustomerRecordContactPersonsComponent)
  contactPersonsComponent: CustomerRecordContactPersonsComponent;

  @ViewChild(CustomerRecordContactLocationsListComponent)
  contactLocationsComponent: CustomerRecordContactLocationsListComponent;

  @ViewChild(CustomerRecordBillingInfoListComponent)
  billingInfoListComponent: CustomerRecordBillingInfoListComponent;

  @ViewChild('f', {static: true})
  fForm: NgForm;

  @ViewChild('warningDialog', {static: true}) warningDialog: ModalDirective;
  warningDialogVisible: boolean = false;

  @ViewChild('addContactPersonDialog', {static: true}) addContactPersonDialog: ModalDirective;
  addContactPersonDialogVisible: boolean = false;

  @ViewChild('contactInviteToHelpdeskDialog', {static: true})
  contactInviteToHelpdeskDialog: ConfirmDialogComponent;

  personCustomerRecords: PersonCustomerRecordItem[] = [];
  addCustomerRecords: PersonCustomerRecordItem[] = [];
  addDropdownSettings?: Angular2Multiselects.Settings;

  customerId: number;
  customerRecordId: number;
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  deregisterWarningModal;
  saveButtonClicked: boolean = false;
  navigateOnSubmit: boolean = false;

  combinedResult?: CombinedResult;
  rightModel: RightModel = RightModel.empty();
  grantedRights?: CustomerRecordRightModel;
  config: ConfigModel = new ConfigModel();

  fieldModel: CustomerRecordFieldModel = CustomerRecordFieldModel.empty();

  @ViewChild('contactLocationImportDialog', {static: true})
  contactLocationImportDialog: FileUploadDialogComponent;
  contactLocationUploadPath: string;

  contactInviteResult: {
    invited: string,
    alreadyRegistered: string,
    invalidEmail: string
  } | null = null;
  private contactPersonIds: Set<number>;

  constructor(
    private toasterService: ToasterService,
    private translateService: TranslateService,
    private userDataLoader: UserDataLoader,
    private modalService: BsModalService,
    private configurationService: ConfigurationService,
    private countryService: CountryService,
    private customerService: CustomerService,
    private customerRecordService: CustomerRecordService,
    private uiRouter: UIRouter,
    private transition: Transition,
    private rightService: RightService,
    private configService: ConfigurationService,
    private dialog: MatDialog,
    private uploadErrorLocalizer: UploadErrorLocalizer) {
    this.customerId = this.transition.params().customerId;
    this.customerRecordId = this.transition.params().customerRecordId;
    this.config = this.configService.getConfigurationModel();
    this.contactLocationUploadPath = '/customer-record-contact-locations/import-xlsx/' + this.customerRecordId;
  }

  ngOnInit() {
    this.initBreadcrumb();
    this.initDropdownSettings();
    this.loadRightModel();
    const context = this;
    this.deregisterWarningModal = this.uiRouter.transitionService.onStart({}, function (transition) {
      if (context.saveButtonClicked
        || transition.targetState().name() === StateName.CONNECTION_ERROR
        || transition.targetState().name() === StateName.SERVER_ERROR
        || transition.targetState().name() === StateName.LOGIN) {
        return true;
      }
      else {
        const subject = new Subject<boolean>();
        const modal = context.modalService.show(ConfirmLeaveModalComponent, {backdrop: 'static', keyboard: false});
        modal.content!.subject = subject;
        return subject.asObservable().toPromise();
      }
    });
  }

  ngAfterViewInit(): void {
    this.createCombinedObservable().subscribe(
      (result: CombinedResult) => {
        this.fieldModel = CustomerRecordFieldModel.ofCustomer(result.customer);
        this.breadcrumbSelf = result.customerRecord.name;
        this.baseView.loadCustomerRecord({
          customerRecord: result.customerRecord,
          countryItems: AddressModel.CountryItem.fromCountryList(result.countries.items),
          customer: result.customer,
          defaultSelectedCountryCode: this.configurationService.getDefaultSelectedCountryCode(),
          isClone: false,
          isCreate: false
        });
        this.formRecordContainerView.loadFormRecord({
          configuration: this.configurationService.getConfiguration(),
          form: result.form,
          record: result.customerRecord.formRecord,
        });
        this.combinedResult = result;
        this.grantedRights =
          new CustomerRecordRightModel(GrantedPermissionSetResolver.byGrantedRights(result.customerRecord.grantedRights));
      }
    );
  }

  private loadRightModel() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  private initBreadcrumb() {
    this.breadcrumbParents = [];
    this.translateService.get('COMMON_CUSTOMERS').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.CUSTOMER_DASHBOARD});
      }
    );
    if (this.customerId) {
      this.customerService.get({
        customerId: this.customerId,
      }).subscribe(
        (result: Customer.Customer) => {
          this.breadcrumbParents.push({name: result.name, uiSref: StateName.CUSTOMER_RECORD_LIST, uiParam: {customerId: this.customerId}});
        }
      );
    }
    else {
      this.translateService.get('COMMON_CUSTOMERS').subscribe(
        (result: string) => {
          this.breadcrumbParents.push({name: result, uiSref: StateName.CUSTOMER_RECORD_GLOBAL_LIST});
        }
      );
    }
  }

  private initDropdownSettings(): void {
    this.addDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .remoteSearch(true)
      .build();
  }

  updateCustomerRecord() {
    if (!this.combinedResult) {
      return;
    }
    if (this.baseView.hasLocalFieldError() || this.formRecordContainerView.hasLocalFieldError()) {
      if (this.baseView.hasLocalFieldError('emailAddresses')) {
        this.showValidationErrorToast(StringKey.CUSTOMER_RECORD_EMAIL_ADDRESSES_REQUIRED);
      }
      else if (this.baseView.hasLocalFieldError('phoneNumbers')) {
        this.showValidationErrorToast(StringKey.CUSTOMER_RECORD_PHONE_NUMBERS_REQUIRED);
      }
      else {
        this.showValidationErrorToast();
      }
      return;
    }
    const model = this.baseView.getModel();
    if (this.baseView.getFieldModel().isRequired(CustomerRecordFieldType.ASSIGNEE)
      && model.users.length === 0 && model.userGroups.length === 0) {
      return;
    }
    if (this.formRecordContainerView.validateWithInterrupt()) {
      return;
    }
    const formModel = this.formRecordContainerView.createModel();

    let parentId: number | undefined = undefined;
    if (model.customerRecordParents.length !== 0) {
      parentId = model.customerRecordParents[0].id!;
    }
    const addressEnabled = this.fieldModel.isManaged(CustomerRecordFieldType.POSTAL_ADDRESS_AND_INVOICE_ADDRESS_AND_NOTIFICATION_ADDRESS);
    const updateRequest: CustomerRecord.UpdateRequest = {
      withFormRecord: false,
      customerId: this.customerId,
      customerRecordId: this.customerRecordId,
      name: this.baseComponent.complexName ? undefined : model.fullName,
      firstName: this.baseComponent.complexName ? model.firstName : undefined,
      lastName: this.baseComponent.complexName ? model.lastName : undefined,
      externalId: model.externalId,
      comment: model.comment,
      position: model.position,
      webAddress: model.webAddress,
      mainContractNumber: model.mainContractNumber,
      taxNumber: model.taxNumber.truncated,
      euTaxNumber: model.euTaxNumber.truncated,
      ownerUserIds: model.getUserIds(),
      ownerGroupIds: model.getUserGroupIds(),
      companyId: model.getCompanyId(),
      ledgerNumber: model.getLedgerNumberId(),
      postalAddress: addressEnabled ? model.postalAddress.toData() : undefined,
      notificationAddress: addressEnabled ? model.notificationAddress.toData() : undefined,
      notificationAddressEqPostal: addressEnabled ? model.notificationAddressEqPostal : false,
      emailAddresses: AddressModel.EmailAddressModel.toDataList(model.emailAddresses),
      phoneNumbers: AddressModel.PhoneNumberModel.toDataList(model.phoneNumbers),
      formRecord: {
        version: this.combinedResult.customerRecord.formRecord!.version,
        fields: formModel.fields
      },
      parentId: parentId,
      invoiceDeadlineAdditionalDays: Models.optNumber(model.invoiceDeadlineAdditionalDays),
      taskRecordDeadlineAdditionalDays: Models.optNumber(model.taskRecordDeadlineAdditionalDays),
      // PERSON type
      placeOfBirth: model.placeOfBirth,
      dateOfBirth: model.dateOfBirth,
      birthName: model.birthName,
      gender: model.gender,
      mothersName: model.mothersName,
      identityDocumentType: model.identityDocumentType,
      identityDocumentNumber: model.identityDocumentNumber,
    };
    this.customerRecordService.update(updateRequest).subscribe(
      (customerRecord: CustomerRecord.CustomerRecord) => {
        this.saveButtonClicked = true;
        if (this.navigateOnSubmit) {
          this.uiRouter.stateService.go(StateName.CUSTOMER_RECORD_LIST, {customerId: this.customerId});
        }
        else {
          this.toasterService.pop({
            timeout: UiConstants.ToastTimeoutShort,
            type: UiConstants.toastTypeSuccess,
            title: this.translateService.instant(StringKey.CUSTOMER_RECORD_DATA_MODIFY),
            body: this.translateService.instant(StringKey.CUSTOMER_RECORD_DATA_MODIFY_SUCCESS)
          });
          this.ngAfterViewInit();
        }
      },
      (error) => {
        if (error instanceof FieldValidationError) {
          this.baseView.onFieldValidationError(error.withForm(this.fForm));
          this.showValidationErrorToast();
        }
      }
    );
  }

  private createCombinedObservable(): Observable<CombinedResult> {
    return combineLatest(
      this.customerService.get({
        customerId: this.customerId,
        withForm: true
      }),
      this.customerRecordService.get({
        fields: CustomerRecord.editFields,
        withFormRecord: true,
        customerId: this.customerId,
        customerRecordId: this.customerRecordId,
        rights: Set.of(OperationRights.CUSTOMER_RECORD_ATTACHMENT_READ,
          OperationRights.CUSTOMER_RECORD_ATTACHMENT_UPDATE_DATA,
          OperationRights.CUSTOMER_RECORD_ATTACHMENT_UPLOAD_FILE,
          OperationRights.CUSTOMER_RECORD_ATTACHMENT_DELETE_FILE)
      }),
      this.countryService.query({})
    ).pipe(map(
      (
        [customer,
          customerRecord,
          countries]) => {
        return {
          form: customer.form!,
          customer: customer,
          customerRecord: customerRecord,
          countries: countries
        };
      })
    );
  }

  private showValidationErrorToast(bodyStringKey?: string) {
    this.toasterService.pop({
      timeout: UiConstants.ToastTimeoutLong,
      type: UiConstants.toastTypeError,
      title: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_TITLE),
      body: this.translateService.instant(bodyStringKey ? bodyStringKey : StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_MESSAGE)
    });
  }

  openWarningDialog() {
    this.warningDialogVisible = true;
    this.warningDialog.show();
  }

  closeWarningDialog() {
    this.warningDialogVisible = false;
    this.warningDialog.hide();
  }

  loadPersonCustomerRecords(q?: string, completion?: () => void) {
    this.customerRecordService.globalQuery({
      name: q ? Strings.undefinedOrNonEmpty(q) : undefined,
      contactPersonType: true,
      disabled: false,
      excludedCustomerRecordIds: this.contactPersonIds,
      customerTypes: ['PERSON'],
      orders: Set.of({type: OrderType.ASC, field: CustomerRecord.OrderField.NAME}),
      paging: {
        pageNumber: 1,
        numberOfItems: 30
      },
      fields: Set.of('id', 'name', 'phone_numbers', 'email_addresses', 'position', 'customer'),
      noProgressBar: true
    }).subscribe((result: QueryResult<CustomerRecord.CustomerRecord>) => {
      this.personCustomerRecords = result.items.toArray().map(r => ({
        id: r.customerRecordId, itemName: r.name,
        itemSubtitle: (r.phoneNumbers.size > 0 ? Models.phoneNumberToString(r.phoneNumbers.get(0).value) + ' ' : '') +
          (r.emailAddresses.size > 0 ? Models.emailAddressToString(r.emailAddresses.get(0).value) : ''),
        position: r.position,
        customerName: r.customer?.name
      }));
      if (completion) {
        completion();
      }
    });
  }

  openAddContactPersonDialog() {
    this.customerRecordService.queryContactPersons({
      customerId: this.customerId,
      customerRecordId: this.customerRecordId,
      fields: Set.of('id'),
      noProgressBar: true
    }).subscribe(cp => {
      this.contactPersonIds = cp.items.filter(c => c !== undefined).map(c => c!.customerRecordId).toSet();
      this.loadPersonCustomerRecords(undefined, () => {
        this.addCustomerRecords = [];
        this.addContactPersonDialogVisible = true;
        this.addContactPersonDialog.show();
      });
    });
  }

  closeAddContactPersonDialog() {
    this.addContactPersonDialogVisible = false;
    this.addContactPersonDialog.hide();
  }

  addContactPersons() {
    const ids: number[] = [];
    ids.push(...this.addCustomerRecords.map(r => r.id));
    this.customerRecordService.addContactPersons({
      customerId: this.customerId,
      customerRecordId: this.customerRecordId,
      contactPersonIds: Set.of(...ids)
    }).subscribe(() => {
      this.contactPersonsComponent.loadList(1);
      this.closeAddContactPersonDialog();
    });
  }

  ngOnDestroy() {
    this.deregisterWarningModal();
  }

  openTaskRecordQuickCreateDialog(event: any) {
    const addr = !this.baseView.getModel().postalAddress
      ? ''
      : Address.PostalAddressMapper.toString(this.baseView.getModel().postalAddress, this.config.postalAddressFormat);
    const item = {
      id: this.customerRecordId,
      text: this.baseView.getModel().name!,
      itemName: this.baseView.getModel().name + ' (' + addr + ')',
      address: addr,
      customerRecord: this.combinedResult!.customerRecord
    };
    TaskRecordCreateMaterialDialogComponent.openDialog(this.dialog, {
      mode: TaskRecordCreateDialogMode.QUICK_CREATE,
      customerRecordItem: item
    }, (result) => {
      if (result?.success) {
      }
    });
  }

  submit(navigateOnSubmit: boolean) {
    this.navigateOnSubmit = navigateOnSubmit;
  }

  onContactPersonCreated(cr: CustomerRecord.CustomerRecord) {
    this.contactLocationsComponent.loadList();
  }

  onContactLocationImported(succeeded: boolean) {
    if (succeeded) {
      this.contactLocationsComponent.loadList(1);
    }
  }

  inviteToHelpdesk(ids: number[]) {
    this.customerRecordService.inviteToHelpdesk({
      customerRecordIds: Set.of(...ids)
    }).subscribe(result => {
      this.contactInviteResult = {
        invited: result.invited.toArray().map(cr => cr.name).join(', '),
        alreadyRegistered: result.alreadyRegistered.toArray().map(cr => cr.name).join(', '),
        invalidEmail: result.invalidEmail.toArray().map(cr => cr.name).join(', ')
      };
    });
  }

}

interface CombinedResult {
  form: Form.Form;
  customer: Customer.Customer;
  customerRecord: CustomerRecord.CustomerRecord;
  countries: QueryResult<Country.Country>;
}

export interface PersonCustomerRecordItem extends MultiselectOptionItem<number> {
  position?: string;
  customerName?: string;
}
