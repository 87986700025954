/* eslint-disable */
import { FormEditFieldCreateDialogView, FormEditFieldDialogViewFactory, FormEditFieldUpdateDialogView, } from './form-utils';
import { FormEditCreateUpdateUnknownFieldDialogComponent } from '../../admin/form/form-edit/dialogs/fields/unknown/create-update/form-edit-create-update-unknown-field-dialog.component';
import { ComponentFactoryResolver, ComponentRef, Injector, } from '@angular/core';
import { Form } from '../../lib/form/form.service';
/* eslint-enable */

/**
 * Unknown field implementation.
 * This is a special case so not a good reference.
 * It has two usage:
 * - the client app does not support the type
 * - dialog is not implemented yet
 */
export class FormEditUnknownFieldDialogViewFactory implements FormEditFieldDialogViewFactory {

  constructor(
    private selector: Form.FieldDataTypeSelector,
    private injector: Injector,
    private cfr: ComponentFactoryResolver) {
  }

  createCreateView(): ComponentRef<FormEditFieldCreateDialogView> {
    return this.createView();
  }

  createUpdateView(): ComponentRef<FormEditFieldUpdateDialogView> {
    return this.createView();
  }

  private createView(): ComponentRef<FormEditCreateUpdateUnknownFieldDialogComponent> {
    const cmp = this.cfr.resolveComponentFactory(FormEditCreateUpdateUnknownFieldDialogComponent)
      .create(this.injector);
    cmp.instance.setSelector(this.selector);
    return cmp;
  }

}
