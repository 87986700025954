/* eslint-disable */
import {
  FieldDataTypeSelectors,
  FormEditFieldCreateDialogView,
  FormEditFieldDialogViewFactory,
  FormEditFieldUpdateDialogView,
} from './form-utils';
import { ComponentFactoryResolver, ComponentRef, Injector, } from '@angular/core';
import { FormEditCreateUpdateBooleanFieldDialogComponent } from '../../admin/form/form-edit/dialogs/fields/boolean/create-update/form-edit-create-update-boolean-field-dialog.component';
/* eslint-enable */

/**
 * Decimal field implementation.
 */
export class FormEditBooleanFieldDialogViewFactory implements FormEditFieldDialogViewFactory {

  constructor(
    private fieldDataTypeSelectors: FieldDataTypeSelectors,
    private injector: Injector,
    private cfr: ComponentFactoryResolver) {
  }

  createCreateView(): ComponentRef<FormEditFieldCreateDialogView> {
    return this.createView();
  }

  createUpdateView(): ComponentRef<FormEditFieldUpdateDialogView> {
    return this.createView();
  }

  private createView(): ComponentRef<FormEditCreateUpdateBooleanFieldDialogComponent> {
    const ref = this.cfr.resolveComponentFactory(FormEditCreateUpdateBooleanFieldDialogComponent)
      .create(this.injector);
    ref.instance.appInit(this.fieldDataTypeSelectors);
    return ref;
  }

}
