/* eslint-disable */
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {CommonModule, DatePipe, registerLocaleData} from '@angular/common';
import localeHu from '@angular/common/locales/hu';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {ModalModule} from 'ngx-bootstrap/modal';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {ChartsModule} from 'ng2-charts';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {APP_TRANSLATE_LOADER, LOCALE_CODE_PROVIDER} from './app.translate';
import {APP_UI_ROUTER_ROOT_MODULE} from './app.ui-router';
import {UIRouterModule} from '@uirouter/angular';
import {TranslateModule} from '@ngx-translate/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxPaginationModule} from 'ngx-pagination';
import {FileUploadModule} from 'ng2-file-upload';
import {HttpClientModule} from '@angular/common/http';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {
  DATEPICKER_FORMATTER_PROVIDER,
  DATEPICKER_I18N_PROVIDER,
  NgbDatePickerParserFormatter
} from './util/ngb-datepicker';
import {TIMEPICKER_FORMATTER_PROVIDER} from './shared/time-picker/time-parser-formatter';
import {LaddaModule} from 'angular2-ladda';
import {
  EnabledItemsValidator,
  EuVatNumberValidator,
  HuTaxNumberValidator,
  LocalDateValidator,
  MatchingPasswordValidator,
  OptionalEmailValidator,
  OptionalLatitudeValidator,
  OptionalLongitudeValidator,
  OptionalPositiveNumberValidator,
  PhoneNumberValidator,
  RequiredAutoCompleteValidator,
  UrlValidator
} from './util/app-validators';
import {PhonePipe} from './util/pipe/phone.pipe';
import {SettingsService} from './lib/settings.service';
import {AppConfigurationService} from './lib/app-configuration.service';
import {AuthService} from './lib/auth.service';
import {UserService, UserSignatureService} from './lib/user.service';
import {UserDataLoader} from './lib/user-data-loader';
import {UserGroupService} from './lib/user-group.service';
import {RootCoreService} from './lib/root-core.service';
import {DatePickerInputFactory, DatePickerTemplateFactory} from './util/datepicker-utils';
import {OwnerUserItemFactory} from './util/core-utils';
import {ApplicationDictionaryService} from './lib/application-dictionary.service';
import {ApiUserKeyService} from './lib/api-user-key.service';
import {AuditLogService} from './lib/statistics/audit-log/audit-log.service';
import {NAV_DROPDOWN_DIRECTIVES} from './shared/nav-dropdown.directive';
import {SIDEBAR_TOGGLE_DIRECTIVES} from './shared/sidebar.directive';
import {AsideToggleDirective} from './shared/aside.directive';
import {AdminLayoutComponent} from './admin/admin-layout.component';
import {LoginComponent} from './login/login.component';
import {AppComponent} from './app.component';
import {DashboardComponent} from './admin/dashboard/dashboard.component';
import {LoadingSpinnerComponent} from './shared/loading-spinner.component';
import {ConnectionErrorPageComponent} from './error-pages/connection-error-page/connection-error-page.component';
import {ServerErrorPageComponent} from './error-pages/server-error-page/server-error-page.component';
import {UserListComponent} from './admin/user/user-list/user-list.component';
import {TableSorterNoOpComponent} from './shared/table-sorter-no-op/table-sorter-no-op.component';
import {TableSorterComponent} from './shared/table-sorter/table-sorter.component';
import {TablePagingComponent} from './shared/table-paging/table-paging.component';
import {NumberOfItemsFormatter} from './util/number-of-items-formatter';
import {PaginationControlsComponent} from './shared/pagination-controls/pagination-controls.component';
import {RegisterComponent} from './register/register.component';
import {UserGroupListComponent} from './admin/usergroup/usergroup-list/usergroup-list.component';
import {UserGroupCreateComponent} from './admin/usergroup/usergroup-create/usergroup-create.component';
import {UserGroupEditComponent} from './admin/usergroup/usergroup-edit/usergroup-edit.component';
import {UserGroupCloneComponent} from './admin/usergroup/usergroup-clone/usergroup-clone.component';
import {
  ApplicationDictionaryListComponent
} from './admin/application-settings/application-dictionary-list/application-dictionary-list.component';
import {ApiUserKeyComponent} from './admin/profile/api-user-key/api-user-key.component';
import {UploadErrorLocalizer} from './util/upload-error-localizer';
import {AnimatedCheckboxComponent} from './shared/animated-checkbox/animated-checkbox.component';
import {ListItemResourceService} from './lib/list-item/list-item-resource.service';
import {TaskStatePipe} from './util/pipe/task-state';
import {
  DeviceManagementListComponent
} from './admin/application-settings/device-management/device-list/device-list.component';
import {DeviceManagementService} from './lib/device-management.service';
import {
  CONFIGURATION_PROVIDER,
  ConfigurationService,
  GLOBAL_ERROR_HANDLER_PROVIDER,
} from './lib/core-ext/configuration.service';
import {TextMaskModule} from 'angular2-text-mask';
import {TaskListComponent} from './admin/task/task-list/task-list.component';
import {TaskRecordListComponent} from './admin/task/task-record/task-record-list/task-record-list.component';
import {TaskRecordCreateComponent} from './admin/task/task-record/task-record-create/task-record-create.component';
import {TaskRecordEditComponent} from './admin/task/task-record/task-record-edit/task-record-edit.component';
import {DocumentHTMLService} from './lib/document/document-html.service';
import {
  DocumentHTMLListComponent
} from './admin/document/document-html/document-html-list/document-html-list.component';
import {
  DocumentHTMLReadComponent
} from './admin/document/document-html/document-html-read/document-html-read.component';
import {
  DocumentHTMLEditComponent
} from './admin/document/document-html/document-html-edit/document-html-edit.component';
import {DocumentTextService} from './lib/document/document-text.service';
import {
  DocumentTextListComponent
} from './admin/document/document-text/document-text-list/document-text-list.component';
import {
  DocumentTextCreateComponent
} from './admin/document/document-text/document-text-create/document-text-create.component';
import {
  DocumentTextEditComponent
} from './admin/document/document-text/document-text-edit/document-text-edit.component';
import {
  DocumentTextReadComponent
} from './admin/document/document-text/document-text-read/document-text-read.component';
import {NotificationListComponent} from './admin/notification/notification-list/notification-list.component';
import {MessagesService} from './lib/message-parent/messages.service';
import {NotificationCreateComponent} from './admin/notification/notification-create/notification-create.component';
import {NotificationReadComponent} from './admin/notification/notification-read/notification-read.component';
import {HelpAppComponent} from './admin/dashboard/helpapp/help-app.component';
import {DocumentLanguageUtil} from './util/document/document-language-utils';
import {DocumentLanguageService} from './lib/document/document-language.service';
import {CustomerEditComponent} from './admin/customer/customer-edit/customer-edit.component';
import {CustomerCreateComponent} from './admin/customer/customer-create/customer-create.component';
import {CustomerListComponent} from './admin/customer/customer-list/customer-list.component';
import {DocumentFileService} from './lib/document/document-file.service';
import {DocumentGroupService} from './lib/document/document-group.service';
import {LanguageService} from './lib/language.service';
import {
  DocumentFileCreateComponent
} from './admin/document/document-file/document-file-create/document-file-create.component';
import {
  DocumentFileEditComponent
} from './admin/document/document-file/document-file-edit/document-file-edit.component';
import {
  DocumentFileListComponent
} from './admin/document/document-file/document-file-list/document-file-list.component';
import {
  DocumentFileReadComponent
} from './admin/document/document-file/document-file-read/document-file-read.component';
import {
  ListItemTypeListComponent
} from './admin/listItem/list-item-type/list-item-type-list/list-item-type-list.component';
import {
  ListItemTypeCreateComponent
} from './admin/listItem/list-item-type/list-item-type-create/list-item-type-create.component';
import {
  ListItemTypeEditComponent
} from './admin/listItem/list-item-type/list-item-type-edit/list-item-type-edit.component';
import {AppworksComponent} from './admin/dashboard/appworks/appworks.component';
import {ListItemListComponent} from './admin/listItem/list-item/list-item-list/list-item-list.component';
import {ListItemCreateComponent} from './admin/listItem/list-item/list-item-create/list-item-create.component';
import {ListItemEditComponent} from './admin/listItem/list-item/list-item-edit/list-item-edit.component';
import {DocumentGroupListComponent} from './admin/document-group/document-group-list/document-group-list.component';
import {
  DocumentGroupCreateComponent
} from './admin/document-group/document-group-create/document-group-create.component';
import {DocumentGroupEditComponent} from './admin/document-group/document-group-edit/document-group-edit.component';
import {AuditLogListComponent} from './admin/statistics/audit-log/audit-log-list/audit-log-list.component';
import {AuditLogDetailComponent} from './admin/statistics/audit-log/audit-log-detail/audit-log-detail.component';
import {ProjectRecordListComponent} from './admin/project/record/list/project-record-list.component';
import {
  ProjectRecordGlobalResourceService,
  ProjectRecordResourceService
} from './lib/project/record/project-record-resource.service';
import {MasterDataListComponent} from './admin/masterdata/masterdata-list/masterdata-list.component';
import {MasterdataCreateComponent} from './admin/masterdata/masterdata-create/masterdata-create.component';
import {MasterdataEditComponent} from './admin/masterdata/masterdata-edit/masterdata-edit.component';
import {StockService} from './lib/stock/stock.service';
import {StockListComponent} from './admin/stock/stock-list/stock-list.component';
import {StockCreateComponent} from './admin/stock/stock-create/stock-create.component';
import {StockEditComponent} from './admin/stock/stock-edit/stock-edit.component';
import {StockItemService} from './lib/stock/stock-item.service';
import {StockSubStockRecordService} from './lib/stock/stock-sub-stock-record.service';
import {StockItemListComponent} from './admin/stock-item/stock-item-list/stock-item-list.component';
import {StockItemCreateComponent} from './admin/stock-item/stock-item-create/stock-item-create.component';
import {StockItemEditComponent} from './admin/stock-item/stock-item-edit/stock-item-edit.component';
import {
  StockLogListContainerComponent
} from './admin/stock-log/stock-log-list-container/stock-log-list-container.component';
import {StockRecordLogService} from './lib/stock/stock-record-log.service';
import {RightService} from './lib/right.service';
import {StockRecordService} from './lib/stock/stock-record.service';
import {TaskRecordCloneComponent} from './admin/task/task-record/task-record-clone/task-record-clone.component';
import {NoteListComponent} from './admin/note/note-list/note-list.component';
import {NoteService} from './lib/note.service';
import {MASTER_DATA_SERVICE_PROVIDER, MasterDataService} from './lib/masterdata/master-data.service';
import {MasterDataResourceService} from './lib/masterdata/master-data-resource.service';
import {TableFieldSorterComponent} from './shared/table-field-sorter/table-field-sorter.component';
import {EmailPipe} from './util/pipe/email.pipe';
import {FormServiceFactory} from './lib/form/form-service-factory';
import {TaskCreateComponent} from './admin/task/task-create/task-create.component';
import {TaskEditComponent} from './admin/task/task-edit/task-edit.component';
import {FormEditComponent} from './admin/form/form-edit/form-edit.component';
import {
  FormEditDialogContainerComponent
} from './admin/form/form-edit/dialog-container/form-edit-dialog-container.component';
import {
  FormEditCreateUpdateGroupDialogComponent
} from './admin/form/form-edit/dialogs/group/create-update/form-edit-create-update-group-dialog.component';
import {
  FormEditCreateUpdateUnknownFieldDialogComponent
} from './admin/form/form-edit/dialogs/fields/unknown/create-update/form-edit-create-update-unknown-field-dialog.component';
import {
  FormEditCreateUpdateStringFieldDialogComponent
} from './admin/form/form-edit/dialogs/fields/string/create-update/form-edit-create-update-string-field-dialog.component';
import {FieldDataTypeSelectors, FormRecordSerializer} from './util/form/form-utils';
import {FormEditDialogHolderDirective} from './admin/form/form-edit/dialog-container/form-edit-dialog-holder.directive';
import {SurveyService} from './lib/survey/survey.service';
import {SurveyResourceService} from './lib/survey/survey-resource.service';
import {SurveyListComponent} from './admin/survey/survey-list/survey-list.component';
import {
  FormEditCreateUpdateNumberFieldDialogComponent
} from './admin/form/form-edit/dialogs/fields/number/create-update/form-edit-create-update-number-field-dialog.component';
import {
  FormEditCreateUpdateDecimalFieldDialogComponent
} from './admin/form/form-edit/dialogs/fields/decimal/create-update/form-edit-create-update-decimal-field-dialog.component';
import {
  FormEditCreateUpdateDateFieldDialogComponent
} from './admin/form/form-edit/dialogs/fields/date/create-update/form-edit-create-update-date-field-dialog.component';
import {
  FormEditCreateUpdateBooleanFieldDialogComponent
} from './admin/form/form-edit/dialogs/fields/boolean/create-update/form-edit-create-update-boolean-field-dialog.component';
import {SurveyRecordService} from './lib/survey/survey-record.service';
import {SurveyRecordResourceService} from './lib/survey/survey-record-resource.service';
import {SurveyRecordListComponent} from './admin/survey/survey-record-list/survey-record-list.component';
import {
  MasterDataRecordGlobalResourceService,
  MasterDataRecordResourceService
} from './lib/masterdata/master-data-record-resource.service';
import {MasterDataRecordService} from './lib/masterdata/master-data-record.service';
import {InputTrimModule} from 'ng2-trim-directive';
import {ValidateOnBlurDirective} from './shared/validate-on-blur.directive';
import {SurveyCreateComponent} from './admin/survey/survey-create/survey-create.component';
import {FormTableListComponent} from './admin/form-table/form-table-list/form-table-list.component';
import {FormTableService} from './lib/form/form-table.service';
import {FormTableResourceService} from './lib/form/form-table-resource.service';
import {FormTableCreateComponent} from './admin/form-table/form-table-create/form-table-create.component';
import {FormTableEditComponent} from './admin/form-table/form-table-edit/form-table-edit.component';
import {
  FormEditCreateUpdateFormTableFieldDialogComponent
} from './admin/form/form-edit/dialogs/fields/form-table/create-update/form-edit-create-update-form-table-field-dialog.component';
import {
  MasterDataRecordEditComponent
} from './admin/masterdata/record/masterdata-record-edit/masterdata-record-edit.component';
import {
  MasterDataRecordCreateComponent
} from './admin/masterdata/record/masterdata-record-create/masterdata-record-create.component';
import {
  MasterDataRecordBaseComponent
} from './admin/masterdata/record/masterdata-record-base/masterdata-record-base.component';
import {
  FormEditCreateUpdateReadonlyTextFieldDialogComponent
} from './admin/form/form-edit/dialogs/fields/readonly/text-create-update/form-edit-create-update-readonly-text-field-dialog.component';
import {
  FormEditCreateUpdateReadonlyHtmlFieldDialogComponent
} from './admin/form/form-edit/dialogs/fields/readonly/html-create-update/form-edit-create-update-readonly-html-field-dialog.component';
import {
  MasterDataRecordListComponent
} from './admin/masterdata/record/masterdata-record-list/masterdata-record-list.component';
import {
  FormEditCreateUpdateListItemFieldDialogComponent
} from './admin/form/form-edit/dialogs/fields/list-item/create-update/form-edit-create-update-list-item-field-dialog.component';
import {
  FormRecordDefaultGroupComponent
} from './admin/form/form-record/groups/default/form-record-default-group.component';
import {
  FormRecordUnknownFieldComponent
} from './admin/form/form-record/fields/unknown/form-record-unknown-field.component';
import {FormRecordContainerComponent} from './admin/form/form-record/form-record-container.component';
import {
  MasterDataRecordCloneComponent
} from './admin/masterdata/record/masterdata-record-clone/masterdata-record-clone.component';
import {
  MasterDataRecordDetailComponent
} from './admin/masterdata/record/masterdata-record-detail/masterdata-record-detail.component';
import {
  FormEditCreateUpdateListMultiItemFieldDialogComponent
} from './admin/form/form-edit/dialogs/fields/multi-item/create-update/form-edit-create-update-list-multi-item-field-dialog.component';
import {
  FormEditCreateUpdatePhoneNumberFieldDialogComponent
} from './admin/form/form-edit/dialogs/fields/phonenumber/create-update/form-edit-create-update-phonenumber-field-dialog.component';
import {FormRecordGroupHolderDirective} from './admin/form/form-record/form-record-group-holder.directive';
import {SurveyRecordBaseComponent} from './admin/survey/survey-record-base/survey-record-base.component';
import {SurveyRecordCreateComponent} from './admin/survey/survey-record-create/survey-record-create.component';
import {
  FormEditCreateUpdateEmailAddressFieldDialogComponent
} from './admin/form/form-edit/dialogs/fields/emailaddress/create-update/form-edit-create-update-emailaddress-field-dialog.component';
import {FormRecordFieldHolderDirective} from './admin/form/form-record/shared/form-record-field-holder.directive';
import {
  FormRecordStringFieldComponent
} from './admin/form/form-record/fields/string/form-record-string-field.component';
import {PermissionResourceService} from './lib/permission-resource.service';
import {PermissionListComponent} from './admin/permission-list/permission-list.component';
import {
  FormRecordNumberFieldComponent
} from './admin/form/form-record/fields/number/form-record-number-field.component';
import {SurveyRecordDetailComponent} from './admin/survey/survey-record-detail/survey-record-detail.component';
import {SurveyRecordEditComponent} from './admin/survey/survey-record-edit/survey-record-edit.component';
import {SurveyRecordCloneComponent} from './admin/survey/survey-record-clone/survey-record-clone.component';
import {
  FormRecordDecimalFieldComponent
} from './admin/form/form-record/fields/decimal/form-record-decimal-field.component';
import {
  FormRecordBooleanFieldComponent
} from './admin/form/form-record/fields/boolean/form-record-boolean-field.component';
import {
  FormRecordEmailAddressFieldComponent
} from './admin/form/form-record/fields/emailaddress/form-record-emailaddress-field.component';
import {
  FormRecordPhoneNumberFieldComponent
} from './admin/form/form-record/fields/phonenumber/form-record-phonenumber-field.component';
import {
  FormRecordFormTableFieldComponent
} from './admin/form/form-record/fields/form-table/form-record-form-table-field.component';
import {
  FormRecordReadonlyTextFieldComponent
} from './admin/form/form-record/fields/readonly/text/form-record-readonly-text-field.component';
import {
  FormRecordReadonlyHtmlFieldComponent
} from './admin/form/form-record/fields/readonly/html/form-record-readonly-html-field.component';
import {
  FormRecordListItemFieldComponent
} from './admin/form/form-record/fields/list-item/form-record-list-item-field.component';
import {
  FormRecordFormTableFieldHolderDirective
} from './admin/form/form-record/fields/form-table/form-record-form-table-field-holder.directive';
import {
  FormRecordComposedContainerComponent
} from './admin/form/form-record/groups/composed/form-record-composed-container.component';
import {
  FormRecordFormTableFieldWrapperComponent
} from './admin/form/form-record/fields/form-table/wrapper/form-record-form-table-field-record-wrapper.component';
import {
  FormRecordFormTableFieldRecordHolderDirective
} from './admin/form/form-record/fields/form-table/wrapper/form-record-form-table-field-record-holder.directive';
import {
  FormRecordMultiItemFieldComponent
} from './admin/form/form-record/fields/multi-item/form-record-multi-item-field.component';
import {NoteDetailComponent} from './admin/note/note-detail/note-detail.component';
import {
  FormRecordFieldWrapperComponent
} from './admin/form/form-record/shared/field-wrapper/form-record-field-wrapper.component';
import {
  FormRecordFieldWrapperHolderDirective
} from './admin/form/form-record/shared/field-wrapper/form-record-field-wrapper-holder.directive';
import {
  FormRecordFieldLineBreakComponent
} from './admin/form/form-record/shared/line-break/form-record-field-line-break.component';
import {
  FormEditCreateUpdateDateTimeFieldDialogComponent
} from './admin/form/form-edit/dialogs/fields/datetime/create-update/form-edit-create-update-datetime-field-dialog.component';
import {
  FormEditCreateUpdateStockFieldDialogComponent
} from './admin/form/form-edit/dialogs/fields/stock/create-update/form-edit-create-update-stock-field-dialog.component';
import {
  FormRecordDisabledGroupComponent
} from './admin/form/form-record/groups/disabled/form-record-disabled-group.component';
import {FormRecordDateFieldComponent} from './admin/form/form-record/fields/date/form-record-date-field.component';
import {FileUploadDialogComponent} from './shared/file-upload/dialog/file-upload-dialog.component';
import {FileUploadComponent} from './shared/file-upload/flat/file-upload.component';
import {FileUploaderUtil} from './util/file-uploader-util';
import {
  FormRecordDisabledFieldComponent
} from './admin/form/form-record/fields/disabled/form-record-disabled-field.component';
import {
  FormEditCreateUpdateMasterDataFieldDialogComponent
} from './admin/form/form-edit/dialogs/fields/masterdata/create-update/form-edit-create-update-masterdata-field-dialog.component';
import {
  FormRecordMasterDataFieldComponent
} from './admin/form/form-record/fields/master-data/form-record-master-data-field.component';
import {TaskResourceService, TaskRoleResourceService} from './lib/task/task-resource.service';
import {TaskService} from './lib/task/task.service';
import {IconService} from './lib/task/icon.service';
import {
  FormRecordDateTimeFieldComponent
} from './admin/form/form-record/fields/datetime/form-record-date-time-field.component';
import {TaskRecordService} from './lib/task/task-record.service';
import {TaskRecordGlobalResourceService, TaskRecordResourceService} from './lib/task/task-record-resource.service';
import {TaskRecordBaseComponent} from './admin/task/task-record/task-record-base/task-record-base.component';
import {FormRecordStockFieldComponent} from './admin/form/form-record/fields/stock/form-record-stock-field.component';
import {CustomerService} from './lib/customer/customer.service';
import {CustomerResourceService, CustomerRoleResourceService} from './lib/customer/customer-resource.service';
import {CustomerRecordService} from './lib/customer/customer-record.service';
import {
  CustomerRecordAttachmentResourceService,
  CustomerRecordBillingInfoResourceService,
  CustomerRecordContactLocationResourceService,
  CustomerRecordContactPersonResourceService,
  CustomerRecordGlobalResourceService,
  CustomerRecordResourceService
} from './lib/customer/customer-record-resource.service';
import {CustomerRecordListComponent} from './admin/customer/customer-record-list/customer-record-list.component';
import {CustomerRecordBaseComponent} from './admin/customer/customer-record-base/customer-record-base.component';
import {CustomerRecordCreateComponent} from './admin/customer/customer-record-create/customer-record-create.component';
import {CustomerRecordEditComponent} from './admin/customer/customer-record-edit/customer-record-edit.component';
import {CustomerRecordDetailComponent} from './admin/customer/customer-record-detail/customer-record-detail.component';
import {CustomerRecordCloneComponent} from './admin/customer/customer-record-clone/customer-record-clone.component';
import {
  DocumentLinkListComponent
} from './admin/document/document-link/document-link-list/document-link-list.component';
import {
  DocumentLinkCreateComponent
} from './admin/document/document-link/document-link-create/document-link-create.component';
import {
  DocumentLinkEditComponent
} from './admin/document/document-link/document-link-edit/document-link-edit.component';
import {
  DocumentLinkReadComponent
} from './admin/document/document-link/document-link-read/document-link-read.component';
import {DocumentLinkService} from './lib/document/document-link.service';
import {CityService, CountryService,} from './lib/country.service';
import {PostalAddressFieldComponent} from './shared/address/postal-address/postal-address-field.component';
import {PostalAddressComplexComponent} from './shared/address/postal-address/complex/postal-address-complex.component';
import {EmailAddressListComponent} from './shared/address/email-address/list/email-address-list.component';
import {EmailAddressItemComponent} from './shared/address/email-address/item/email-address-item.component';
import {PhoneNumberItemComponent} from './shared/address/phone-number/item/phone-number-item.component';
import {PhoneNumberListComponent} from './shared/address/phone-number/list/phone-number-list.component';
import {ContactPersonItemComponent} from './shared/address/contact-person/item/contact-person-item.component';
import {ContactPersonListComponent} from './shared/address/contact-person/list/contact-person-list.component';
import {EmailAddressOptItemComponent} from './shared/address/email-address/opt-item/email-address-opt-item.component';
import {PhoneNumberOptItemComponent} from './shared/address/phone-number/opt-item/phone-number-opt-item.component';
import {LocationItemComponent} from './shared/address/location/item/location-item.component';
import {LocationListComponent} from './shared/address/location/list/location-list.component';
import {TaskSearchService} from './lib/task/task-search.service';
import {
  TaskRecordAttachmentComponent
} from './admin/task/task-record/task-record-attachment/task-record-attachment.component';
import {TaskRecordHistoryComponent} from './admin/task/task-record/task-record-history/task-record-history.component';
import {AdminDashboardComponent} from './admin/admin-dashboard/admin-dashboard.component';
import {DqlSearchContainerComponent} from './admin/dql-search/dql-search-container/dql-search-container.component';
import {
  DqlSearchFieldSimpleComponent
} from './admin/dql-search/field/dql-search-field-simple/dql-search-field-simple.component';
import {
  DqlSearchContainerHolderDirective
} from './admin/dql-search/dql-search-container/dql-search-container-holder.directive';
import {DqlLogicalOperationComponent} from './admin/dql-search/dql-logical-operation/dql-logical-operation.component';
import {
  DqlSearchFieldListComponent
} from './admin/dql-search/field/dql-search-field-list/dql-search-field-list.component';
import {
  DqlSearchFieldBooleanComponent
} from './admin/dql-search/field/dql-search-field-boolean/dql-search-field-boolean.component';
import {
  DqlSearchFieldDateComponent
} from './admin/dql-search/field/dql-search-field-date/dql-search-field-date.component';
import {
  DqlSearchFieldDateRangeComponent
} from './admin/dql-search/field/dql-search-field-date-range/dql-search-field-date-range.component';
import {
  DqlSearchFieldNumberRangeComponent
} from './admin/dql-search/field/dql-search-field-number-range/dql-search-field-number-range.component';
import {DqlDialogContainerComponent} from './admin/dql-search/dql-dialog-container/dql-dialog-container.component';
import {DqlCreateDialogComponent} from './admin/dql-search/dql-create-dialog/dql-create-dialog.component';
import {DqlDialogValueHolderDirective} from './admin/dql-search/dql-create-dialog/dql-dialog-value-holder.directive';
import {DefaultDeadlineTypeTextResolverFactory, TaskValidationTypeTextResolverFactory} from './util/task-utils';
import {TaskDashboardComponent} from './admin/task/task-dashboard/task-dashboard.component';
import {
  InvoiceSettingsBaseComponent
} from './admin/invoicing/invoice-settings/invoice-settings-base/invoice-settings-base.component';
import {InvoiceBookListComponent} from './admin/invoicing/invoice-book/invoice-book-list/invoice-book-list.component';
import {
  InvoiceBookCreateComponent
} from './admin/invoicing/invoice-book/invoice-book-create/invoice-book-create.component';
import {InvoiceBookEditComponent} from './admin/invoicing/invoice-book/invoice-book-edit/invoice-book-edit.component';
import {PieComponent} from './shared/pie/pie.component';
import {BillingAddressComponent} from './shared/address/billing-address/billing-address.component';
import {InvoiceListComponent} from './admin/invoicing/invoices/invoice-list/invoice-list.component';
import {InvoiceSettingsService} from './lib/invoice/invoice-settings/invoice-settings.service';
import {InvoiceSettingsResourceService} from './lib/invoice/invoice-settings/invoice-settings-resource.service';
import {LoggerFactory} from './util/logger-factory';
import {InvoiceBookService} from './lib/invoice/invoice-book/invoice-book.service';
import {InvoiceBookResourceService} from './lib/invoice/invoice-book/invoice-book-resource.service';
import {InvoiceResourceService} from './lib/invoice/invoice/invoice-resource.service';
import {InvoiceService} from './lib/invoice/invoice/invoice.service';
import {InvoiceCreateCloneComponent} from './admin/invoicing/invoices/invoice-create/invoice-create-clone.component';
import {TaskRecordPdfComponent} from './admin/task/task-record/task-record-pdf/task-record-pdf.component';
import {
  InvoiceRecordContainerComponent
} from './admin/invoicing/invoices/invoice-record-container/invoice-record-container.component';
import {InvoiceRecordEditComponent} from './admin/invoicing/invoices/invoice-record-edit/invoice-record-edit.component';
import {NgbInputTimepickerDirective} from './shared/time-picker/time-picker.directive';
import {TIMEPICKER_ADAPTER_PROVIDER} from './shared/time-picker/time-adapter';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatStepperModule} from '@angular/material/stepper';
import {FileAttachmentGalleryComponent} from './shared/file-attachment-gallery/file-attachment-gallery.component';
import {BreadcrumbComponent} from './shared/breadcrumb/breadcrumb/breadcrumb.component';
import {
  TaskRecordBreadcrumbButtonsComponent
} from './admin/task/task-record/task-record-breadcrumb-buttons/task-record-breadcrumb-buttons.component';
import {
  CustomerRecordBreadcrumbButtonsComponent
} from './admin/customer/customer-record-breadcrumb-buttons/customer-record-breadcrumb-buttons.component';
import {
  SurveyRecordBreadcrumbButtonsComponent
} from './admin/survey/survey-record-breadcrumb-buttons/survey-record-breadcrumb-buttons.component';
import {
  MasterdataRecordBreadcrumbButtonsComponent
} from './admin/masterdata/record/masterdata-record-breadcrumb-buttons/masterdata-record-breadcrumb-buttons.component';
import {CityResourceService, CountryResourceService} from './lib/country-resource.service';
import {AuditLogSearchService} from './lib/statistics/audit-log/audit-log-search.service';
import {ConfirmLeaveModalComponent} from './shared/confirm-leave-modal/confirm-leave-modal.component';
import {UserSearchService} from './lib/user-search-service';
import {UserGroupSearchService} from './lib/user-group-search-service';
import {TaskLogListComponent} from './admin/statistics/task-log/task-log-list/task-log-list.component';
import {TaskLogService} from './lib/statistics/task-log/task-log.service';
import {TaskLogSearchService} from './lib/statistics/task-log/task-log-search.service';
import {SurveySearchService} from './lib/survey-search-service';
import {CustomerSearchService} from './lib/customer-search-service';
import {MasterdataSearchService} from './lib/masterdata-search-service';
import {FormTableSearchService} from './lib/form-table-search-service';
import {MasterdataRecordSearchService} from './lib/masterdata-record-search-service';
import {DeviceSearchService} from './lib/device-search-service';
import {DocumentFileSearchService} from './lib/document/document-file-search.service';
import {StockLogSearchService} from './lib/stock-log-search-service';
import {StockItemSearchService} from './lib/stock-item-search-service';
import {
  FormEditCreateUpdatePaymentTypeFieldDialogComponent
} from './admin/form/form-edit/dialogs/fields/payment-type/create-update/form-edit-create-update-payment-type-field-dialog.component';
import {
  FormRecordPaymentTypeFieldComponent
} from './admin/form/form-record/fields/payment-type/form-record-payment-type-field.component';
import {ListItemSearchService} from './lib/list-item-search-service';
import {InvoiceDetailComponent} from './admin/invoicing/invoices/invoice-detail/invoice-detail.component';
import {ApiOperationService} from './lib/util/api-operation.service';
import {TaskLogResourceService} from './lib/statistics/task-log/task-log-resource.service';
import {CurrencyService} from './lib/currency.service';
import {FormattedNumberPipe} from './util/pipe/formatted-number.pipe';
import {
  TaskStatisticsListComponent
} from './admin/statistics/task-statistics/task-statistics-list/task-statistics-list.component';
import {
  TaskStatisticsDetailComponent
} from './admin/statistics/task-statistics/task-statistics-detail/task-statistics-detail.component';
import {TaskStatisticsResourceService} from './lib/statistics/task-statistics/task-statistics-resource.service';
import {TaskStatisticsService} from './lib/statistics/task-statistics/task-statistics.service';
import {TaskStatisticsSearchService} from './lib/statistics/task-statistics/task-statistics-search.service';
import {Ng2GoogleChartsModule} from 'ng2-google-charts';
import {
  InvoiceStatisticsListComponent
} from './admin/statistics/invoice-statistics/invoice-statistics-list/invoice-statistics-list.component';
import {
  InvoiceStatisticsResourceService
} from './lib/statistics/invoice-statistics/invoice-statistics-resource.service';
import {InvoiceStatisticsService} from './lib/statistics/invoice-statistics/invoice-statistics.service';
import {InvoiceBookSearchService} from './lib/invoice-book-search-service.component';
import {StockItemDetailComponent} from './admin/stock-item/stock-item-detail/stock-item-detail.component';
import {DashboardPieChartComponent} from './shared/dashboard-pie-chart/dashboard-pie-chart.component';
import {MatRadioModule} from '@angular/material/radio';
import {
  DqlSearchFieldUnaryComponent
} from './admin/dql-search/field/dql-search-field-unary/dql-search-field-unary.component';
import {NoteSearchService} from './lib/note-search-service';
import {MessageSearchService} from './lib/message-search-service';
import {ProjectRecordSearchService} from './lib/project/record/project-record-search.service';
import {DocumentLinkSearchService} from './lib/document/document-link-search.service';
import {OrderListComponent} from './admin/order/order-list/order-list.component';
import {OrderService} from './lib/order/order.service';
import {OrderResourceService} from './lib/order/order-resource.service';
import {OrderDetailComponent} from './admin/order/order-detail/order-detail.component';
import {OrderEditComponent} from './admin/order/order-edit/order-edit.component';
import {
  OrderStockItemContainerComponent
} from './admin/order/order-stock-item-container/order-stock-item-container.component';
import {OrderStockItemEditComponent} from './admin/order/order-stock-item-edit/order-stock-item-edit.component';
import {OrderSearchService} from './lib/order/order-search.service';
import {LegacyProcessListComponent} from './admin/legacy-process/legacy-process-list/legacy-process-list.component';
import {LegacyProcessResourceService} from './lib/legacy-process/legacy-process-resource.service';
import {LegacyProcessService} from './lib/legacy-process/legacy-process.service';
import {LegacyProcessSearchService} from './lib/legacy-process/legacy-process-search.service';
import {LegacyWorkflowListComponent} from './admin/legacy-workflow/legacy-workflow-list/legacy-workflow-list.component';
import {
  LegacyWorkflowCreateComponent
} from './admin/legacy-workflow/legacy-workflow-create/legacy-workflow-create.component';
import {LegacyWorkflowEditComponent} from './admin/legacy-workflow/legacy-workflow-edit/legacy-workflow-edit.component';
import {LegacyWorkflowSearchService} from './lib/legacy-workflow/legacy-workflow-search-service';
import {
  LegacyWorkflowTaskEditComponent
} from './admin/legacy-workflow/legacy-workflow-tasks/legacy-workflow-task-edit/legacy-workflow-task-edit.component';
import {LegacyWorkflowTaskService} from './lib/legacy-workflow/legacy-workflow-tasks/legacy-workflow-task.service';
import {
  LegacyWorkflowTaskResourceService
} from './lib/legacy-workflow/legacy-workflow-tasks/legacy-workflow-task-resource.service';
import {
  FormEditCreateUpdateCustomerFieldDialogComponent
} from './admin/form/form-edit/dialogs/fields/customer/create-edit/form-edit-create-update-customer-field-dialog.component';
import {
  FormRecordCustomerFieldComponent
} from './admin/form/form-record/fields/customer/form-record-customer-field.component';
import {
  FormEditCreateUpdateDocumentFieldDialogComponent
} from './admin/form/form-edit/dialogs/fields/document/create-update/form-edit-create-update-document-field-dialog.component';
import {
  FormEditCreateUpdatePhotoFieldDialogComponent
} from './admin/form/form-edit/dialogs/fields/photo/create-update/form-edit-create-update-photo-field-dialog.component';
import {
  FormRecordDocumentFieldComponent
} from './admin/form/form-record/fields/document/form-record-document-field.component';
import {FormRecordPhotoFieldComponent} from './admin/form/form-record/fields/photo/form-record-photo-field.component';
import {LegacyProcessEditComponent} from './admin/legacy-process/legacy-process-edit/legacy-process-edit.component';
import {
  FormRecordStockFieldItemEditComponent
} from './admin/form/form-record/fields/stock/form-record-stock-field-item-edit/form-record-stock-field-item-edit.component';
import {LegacyProcessTaskService} from './lib/legacy-process/legacy-process-task.service';
import {
  LegacyProcessTaskBaseComponent
} from './admin/legacy-process/legacy-process-task-base/legacy-process-task-base.component';
import {
  LegacyProcessTaskEditComponent
} from './admin/legacy-process/legacy-process-task-edit/legacy-process-task-edit.component';
import {
  LegacyProcessTaskDetailComponent
} from './admin/legacy-process/legacy-process-task-detail/legacy-process-task-detail.component';
import {
  FormRecordProcessOrderStockOuttakeFieldComponent
} from './admin/form/form-record/fields/process-order-stock-outtake/form-record-process-order-stock-outtake-field.component';
import {
  FormEditCreateUpdateProcessOrderStockOuttakeFieldDialogComponent
} from './admin/form/form-edit/dialogs/fields/process-order-stock-outtake/create-update/form-edit-create-update-process-order-stock-outtake-field-dialog.component';
import {
  FormEditCreateUpdateProcessOrderStockOuttakeCheckFieldDialogComponent
} from './admin/form/form-edit/dialogs/fields/process-order-stock-outtake-check/create-update/form-edit-create-update-process-order-stock-outtake-check-field-dialog.component';
import {
  FormEditCreateUpdateProcessOrderPackagingFieldDialogComponent
} from './admin/form/form-edit/dialogs/fields/process-order-packaging/create-update/form-edit-create-update-process-order-packaging-field-dialog.component';
import {TaskRecordSurveyComponent} from './admin/task/task-record/task-record-survey/task-record-survey.component';
import {
  FormRecordProcessOrderStockOuttakeCheckFieldComponent
} from './admin/form/form-record/fields/process-order-stock-outtake-check/form-record-process-order-stock-outtake-check-field.component';
import {
  LegacyProcessEditStateHistoryComponent
} from './admin/legacy-process/legacy-process-edit-state-history/legacy-process-edit-state-history.component';
import {
  FormRecordProcessOrderPackagingFieldComponent
} from './admin/form/form-record/fields/process-order-packaging/form-record-process-order-packaging-field.component';
import {
  LegacyProcessDetailComponent
} from './admin/legacy-process/legacy-process-detail/legacy-process-detail.component';
import {ErrorMessageService} from './lib/error-message-parser.service';
import {StockChangeListComponent} from './admin/stock-change/stock-change-list/stock-change-list.component';
import {StockChangeService} from './lib/stock/stock-change.service';
import {StockChangeResourceService} from './lib/stock/stock-change-resource.service';
import {StockChangeSearchService} from './lib/stock-change-search.service';
import {StockChangeDetailComponent} from './admin/stock-change/stock-change-detail/stock-change-detail.component';
import {
  DqlSearchFieldByIdComponent
} from './admin/dql-search/field/dql-search-field-by-id/dql-search-field-by-id.component';
import {
  DqlSearchFieldEnumComponent
} from './admin/dql-search/field/dql-search-field-enum/dql-search-field-enum.component';
import {
  FormEditCreateUpdateReadonlyPictureFieldDialogComponent
} from './admin/form/form-edit/dialogs/fields/readonly/picture-create-update/form-edit-create-update-readonly-picture-field-dialog.component';
import {
  FormRecordReadonlyPictureFieldComponent
} from './admin/form/form-record/fields/readonly/picture/form-record-readonly-picture-field.component';
import {
  LegacyProcessTaskAttachmentComponent
} from './admin/legacy-process/legacy-process-task-attachment/legacy-process-task-attachment.component';
import {SurveyDashboardComponent} from './admin/survey/survey-dashboard/survey-dashboard.component';
import {StockTakingsListComponent} from './admin/stock-takings/stock-takings-list/stock-takings-list.component';
import {StockTakingsService} from './lib/stock-takings/stock-takings.service';
import {StockTakingsResourceService} from './lib/stock-takings/stock-takings-resource.service';
import {StockTakingsSearchService} from './lib/stock-takings/stock-takings-search.service';
import {StockTakingsEditComponent} from './admin/stock-takings/stock-takings-edit/stock-takings-edit.component';
import {StockTakingsDetailComponent} from './admin/stock-takings/stock-takings-detail/stock-takings-detail.component';
import {StockTakingSheetResourceService} from './lib/stock-takings/stock-taking-sheet-resource.service';
import {
  StockTakingSheetListComponent
} from './admin/stock-takings/stock-taking-sheet/stock-taking-sheet-list/stock-taking-sheet-list.component';
import {StockTakingSheetSearchService} from './lib/stock-takings/stock-taking-sheet-search.service';
import {
  StockTakingSheetDetailComponent
} from './admin/stock-takings/stock-taking-sheet/stock-taking-sheet-detail/stock-taking-sheet-detail.component';
import {SimpleDashboardComponent} from './shared/simple-dashboard/simple-dashboard.component';
import {DocumentDashboardComponent} from './admin/document/document-dashboard/document-dashboard.component';
import {FormRecordFieldViewModelFactory} from './admin/form/form-record/manager/form-record-field-view-model-factory';
import {FormRecordInactivityManagerFactory} from './admin/form/form-record/manager/form-record-inactivity-manager';
import {
  FieldActivationStrategyFactory
} from './admin/form/form-record/manager/activation-strategy/field-activation-strategy-factory';
import {
  NoOpFieldActivationStrategy
} from './admin/form/form-record/manager/activation-strategy/no-op-field-activation-strategy';
import {
  BooleanFieldActivationStrategy
} from './admin/form/form-record/manager/activation-strategy/boolean-field-activation-strategy';
import {
  ListItemFieldActivationStrategy
} from './admin/form/form-record/manager/activation-strategy/list-item-field-activation-strategy';
import {
  ListMultiItemFieldActivationStrategy
} from './admin/form/form-record/manager/activation-strategy/list-multi-item-field-activation-strategy';
import {CalendarService} from './lib/calendar/calendar.service';
import {CalendarResourceService} from './lib/calendar/calendar-resource.service';
import {CalendarComponent} from './admin/calendar/calendar.component';
import {
  FormEditCreateUpdateListItemActivationRuleComponent
} from './admin/form/form-edit/dialogs/shared/list-item-activation-rule/form-edit-create-update-list-item-activation-rule.component';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import {CompanyResourceService} from './lib/company/company-resource.service';
import {CompanyService} from './lib/company/company.service';
import {CompanyListComponent} from './admin/company/company-list/company-list.component';
import {CompanyBaseComponent} from './admin/company/company-base/company-base.component';
import {CompanySearchService} from './lib/company/company-search.service';
import {VehicleService} from './lib/vehicles/vehicle.service';
import {VehicleResourceService} from './lib/vehicles/vehicle-resource.service';
import {VehicleListComponent} from './admin/vehicle/vehicle-list/vehicle-list.component';
import {VehicleSearchService} from './lib/vehicles/vehicle-search.service';
import {RoleListComponent} from './admin/role/role-list/role-list.component';
import {RoleResourceService} from './lib/role/role-resource.service';
import {RoleService} from './lib/role/role.service';
import {RoleSearchService} from './lib/role/role-search.service';
import {RoleCreateComponent} from './admin/role/role-create/role-create.component';
import {RoleEditComponent} from './admin/role/role-edit/role-edit.component';
import {TwoWayMultiselectComponent} from './shared/two-way-multiselect/two-way-multiselect.component';
import {VehicleBaseComponent} from './admin/vehicle/vehicle-base/vehicle-base.component';
import {CompanyLocationService} from './lib/company-location/company-location.service';
import {CompanyLocationResourceService} from './lib/company-location/company-location-resource.service';
import {
  CompanyLocationListComponent
} from './admin/company-location/company-location-list/company-location-list.component';
import {
  CompanyLocationBaseComponent
} from './admin/company-location/company-location-base/company-location-base.component';
import {CompanyLocationSearchService} from './lib/company-location/company-location-search.service';
import {CompanyStockService} from './lib/company-stock/company-stock.service';
import {CompanyStockResourceService} from './lib/company-stock/company-stock-resource.service';
import {CompanyStockListComponent} from './admin/company-stock/company-stock-list/company-stock-list.component';
import {CompanyStockSearchService} from './lib/company-stock/company-stock-search.service';
import {CompanyStockBaseComponent} from './admin/company-stock/company-stock-base/company-stock-base.component';
import {TransportListComponent} from './admin/transport/transport-list/transport-list.component';
import {TransportResourceService} from './lib/transport/transport-resource.service';
import {TransportService} from './lib/transport/transport.service';
import {TransportSearchService} from './lib/transport/transport-search.service';
import {TransportDetailComponent} from './admin/transport/transport-detail/transport-detail.component';
import {TransportBaseDataComponent} from './admin/transport/transport-base-data/transport-base-data.component';
import {TransportDocumentService} from './lib/transport/transport-document/transport-document.service';
import {TransportDocumentResourceService} from './lib/transport/transport-document/transport-document-resource.service';
import {ShippingDemandListComponent} from './admin/shipping-demand/shipping-demand-list/shipping-demand-list.component';
import {ShippingDemandSearchService} from './lib/shipping-demand/shipping-demand-search.service';
import {
  TransportDocumentListComponent
} from './admin/transport/transport-document/transport-document-list/transport-document-list.component';
import {TransportDocumentSearchService} from './lib/transport/transport-document/transport-document-search.service';
import {TransportLogResourceService} from './lib/transport/transport-log/transport-log-resource.service';
import {TransportLogService} from './lib/transport/transport-log/transport-log.service';
import {TransportLogListComponent} from './admin/transport/transport-log-list/transport-log-list.component';
import {ShippingDemandEditComponent} from './admin/shipping-demand/shipping-demand-edit/shipping-demand-edit.component';
import {
  ShippingDemandAddressComponent
} from './admin/shipping-demand/shipping-demand-edit/shipping-demand-address/shipping-demand-address.component';
import {
  ShippingDemandItemContainerComponent
} from './admin/shipping-demand/shipping-demand-edit/shipping-demand-item-container/shipping-demand-item-container.component';
import {
  ShippingDemandItemEditComponent
} from './admin/shipping-demand/shipping-demand-edit/shipping-demand-item-edit/shipping-demand-item-edit.component';
import {ShippingItemResourceService} from './lib/shipping-item/shipping-item-resource.service';
import {ShippingItemService} from './lib/shipping-item/shipping-item.service';
import {TransportTaskListComponent} from './admin/transport/transport-task-list/transport-task-list.component';
import {TransportTaskResourceService} from './lib/transport/transport-task/transport-task-resource.service';
import {TransportTaskService} from './lib/transport/transport-task/transport-task.service';
import {
  TransportTaskDialogContainerComponent
} from './admin/transport/transport-task-dialog-container/transport-task-dialog-container.component';
import {FormattedDurationPipe} from './util/pipe/formatted-duration.pipe';
import {ShipmentGroupService} from './lib/shipment-group/shipment-group.service';
import {
  ShipmentGroupResourceService,
  ShipmentGroupTransportResourceService,
  ShipmentOuttakeResourceService,
  ShipmentResourceService
} from './lib/shipment-group/shipment-group-resource.service';
import {
  TransportShipmentListComponent
} from './admin/transport/transport-shipment-list/transport-shipment-list.component';
import {ShipmentGroupListComponent} from './admin/shipment/shipment-group-list/shipment-group-list.component';
import {
  TransportPositionlogMapComponent
} from './admin/transport/transport-positionlog-map/transport-positionlog-map.component';
import {AnyspedComponent} from './admin/dashboard/anysped/anysped.component';
import {TransportDashboardResourceService} from './lib/transport-dashboard/transport-dashboard-resource.service';
import {TransportDashboardService} from './lib/transport-dashboard/transport-dashboard.service';
import {
  AnyspedDashboardTransportComponent
} from './admin/dashboard/anysped/anysped-dashboard-transport/anysped-dashboard-transport.component';
import {
  AnyspedDashboardShipmentComponent
} from './admin/dashboard/anysped/anysped-dashboard-shipment/anysped-dashboard-shipment.component';
import {DurationHumanizerService} from './util/duration-humanizer.service';
import {
  CustomerOwnerMasterDataListComponent
} from './admin/customer/customer-owner-master-data-list/customer-owner-master-data-list.component';
import {StockItemCategoryResourceService} from './lib/stock-item-category/stock-item-category-resource.service';
import {StockItemCategoryService} from './lib/stock-item-category/stock-item-category.service';
import {StockItemCategorySearchService} from './lib/stock-item-category/stock-item-category-search.service';
import {
  StockItemCategoryBaseComponent
} from './admin/stock-item-category/stock-item-category-base/stock-item-category-base.component';
import {
  StockItemCategoryListComponent
} from './admin/stock-item-category/stock-item-category-list/stock-item-category-list.component';
import {TransportAttachmentComponent} from './admin/transport/transport-attachment/transport-attachment.component';
import {TransportAttachmentService} from './lib/transport/transport-attachment/transport-attachment.service';
import {
  TransportAttachmentResourceService
} from './lib/transport/transport-attachment/transport-attachment-resource.service';
import {NgxEditorModule} from 'ngx-editor';
import {ImageViewerWindowComponent} from './shared/image-viewer-window/image-viewer-window.component';
import {CalendarModule, DateAdapter} from './fork/angular-calendar/src';
import {ChatComponent} from './shared/chat/chat.component';
import {TransportRunningMapComponent} from './admin/transport/transport-running-map/transport-running-map.component';
import {
  PositionLogInfoWindowComponent
} from './admin/transport/transport-running-map/info-window/position-log-info-window.component';
import {
  TransportLogDetailModalComponent
} from './admin/transport/transport-log-detail-modal/transport-log-detail-modal.component';
import {
  TableDocumentSchemaListComponent
} from './admin/table-document-schema/table-document-schema-list/table-document-schema-list.component';
import {TableDocumentSchemaResourceService} from './lib/table-document-schema/table-document-schema-resource.service';
import {TableDocumentSchemaService} from './lib/table-document-schema/table-document-schema.service';
import {
  TableDocumentSchemaBaseComponent
} from './admin/table-document-schema/table-document-schema-base/table-document-schema-base.component';
import {
  TableDocumentSchemaFieldBaseComponent
} from './admin/table-document-schema/table-document-schema-field-base/table-document-schema-field-base.component';
import {
  TableDocumentSchemaFieldResourceService
} from './lib/table-document-schema/table-document-schema-field-resource.service';
import {
  TableDocumentSchemaImportExportResourceService
} from './lib/table-document-schema/table-document-schema-import-export-resource.service';
import {TableDocumentSchemaSearchService} from './lib/table-document-schema/table-document-schema-search-service';
import {TableDocumentResourceService} from './lib/table-document/table-document-resource.service';
import {TableDocumentService} from './lib/table-document/table-document.service';
import {TableDocumentListComponent} from './admin/table-document/table-document-list/table-document-list.component';
import {TableDocumentSearchService} from './lib/table-document/table-document-search.service';
import {
  ConfigurationLoadErrorComponent
} from './error-pages/configuration-load-error/configuration-load-error.component';
import {HTTP_INTERCEPTOR_PROVIDER} from './lib/util/http-interceptors';
import {
  GeneralPdfTemplateListComponent
} from './admin/general-pdf-template/general-pdf-template-list/general-pdf-template-list.component';
import {GeneralPdfTemplateResourceService} from './lib/general-pdf-template/general-pdf-template-resource.service';
import {GeneralPdfTemplateService} from './lib/general-pdf-template/general-pdf-template.service';
import {GeneralPdfTemplateSearchService} from './lib/general-pdf-template/general-pdf-template-search-service';
import {
  CustomerRecordHistoryComponent
} from './admin/customer/customer-record-history/customer-record-history.component';
import {ClientDocumentService} from './lib/client-document/client-document.service';
import {ClientDocumentResourceService} from './lib/client-document/client-document-resource.service';
import {
  ColonnadeInsuranceListComponent
} from './admin/client-document/colonnade-insurance-list/colonnade-insurance-list.component';
import {EmailTemplateResourceService} from './lib/email-template/email-template-resource.service';
import {EmailTemplateService} from './lib/email-template/email-template.service';
import {EmailTemplateListComponent} from './admin/email-template/email-template-list/email-template-list.component';
import {EmailTemplateSearchService} from './lib/email-template/email-template-search.service';
import {EmailTemplateBaseComponent} from './admin/email-template/email-template-base/email-template-base.component';
import {ColonnadeInsuranceSearchService} from './lib/colonnade-insurance/colonnade-insurance-search.service';
import {QuillModule} from 'ngx-quill';
import {TokenListComponent} from './admin/token/token-list/token-list.component';
import {TokenResourceService} from './lib/token/token-resource.service';
import {TokenService} from './lib/token/token.service';
import {TokenSearchService} from './lib/token/token-search.service';
import {ApplicationFileResourceService} from './lib/application-file/application-file-resource.service';
import {ApplicationFileService} from './lib/application-file/application-file.service';
import {
  ApplicationFileListComponent
} from './admin/application-file/application-file-list/application-file-list.component';
import {ApplicationFileSearchService} from './lib/application-file/application-file-search.service';
import {TaskRecordDetailComponent} from './admin/task/task-record/task-record-detail/task-record-detail.component';
import {
  DocumentHTMLCreateComponent
} from './admin/document/document-html/document-html-create/document-html-create.component';
import {SurveyEditComponent} from './admin/survey/survey-edit/survey-edit.component';
import {CustomerRecordSearchService} from './lib/customer-record-search-service';
import {DocumentGroupSearchService} from './lib/document/document-group-search.service';
import {InvoiceSearchService} from './lib/invoice-search-service';
import {ListItemTypeSearchService} from './lib/list-item-type-search-service';
import {SurveyRecordSearchService} from './lib/survey-record-search-service';
import {LegacyProcessTaskResourceService} from './lib/legacy-process/legacy-process-task-resource.service';
import {LegacyWorkflowService} from './lib/legacy-workflow/legacy-workflow-service';
import {LegacyWorkflowResourceService} from './lib/legacy-workflow/legacy-workflow-resource-service';
import {FormAttachmentService} from './lib/form/form-attachment.service';
import {FormAttachmentResourceService} from './lib/form/form-attachment-resource.service';
import {RefreshFormOnChangeService} from './lib/refresh-form-on-change.service';
import {StockTakingSheetService} from './lib/stock-takings/stock-taking-sheet.service';
import {CalendarSearchService} from './lib/calendar/calendar-search.service';
import {ShippingDemandService} from './lib/shipping-demand/shipping-demand.service';
import {ShippingDemandResourceService} from './lib/shipping-demand/shipping-demand-resource.service';
import {StockSearchService} from './lib/stock-search-service';
import {CustomerRecordLogResourceService} from './lib/customer/customer-record-log-resource.service';
import {UsergroupDetailComponent} from './admin/usergroup/usergroup-detail/usergroup-detail.component';
import {RoleDetailComponent} from './admin/role/role-detail/role-detail.component';
import {RoleLogResourceService} from './lib/role/role-log-resource.service';
import {NfcCardListComponent} from './admin/user/user-base/nfc-card-list/nfc-card-list.component';
import {NfcCardResourceService} from './lib/nfc-card/nfc-card-resource.service';
import {NfcCardService} from './lib/nfc-card/nfc-card.service';
import {PlaygroundComponent} from './admin/playground/playground.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {CompanyStockCreateComponent} from './admin/company-stock/company-stock-create/company-stock-create.component';
import {CompanyCreateModalComponent} from './admin/company/company-create-modal/company-create-modal.component';
import {
  CompanyLocationCreateModalComponent
} from './admin/company-location/company-location-create-modal/company-location-create-modal.component';
import {
  UserGroupCreateModalComponent
} from './admin/usergroup/usergroup-create-modal/user-group-create-modal.component';
import {HistoryDetailDialogComponent} from './admin/history-log/history-detail-dialog/history-detail-dialog.component';
import {HistoryLogComponent} from './admin/history-log/history-log/history-log.component';
import {HIGHLIGHT_OPTIONS, HighlightModule} from 'ngx-highlightjs';
import {PlaceUpdateComponent} from './admin/place-update-modal/place-update.component';
import {PlaceService} from './lib/place/place.service';
import {PlaceResourceService} from './lib/place/place-resource.service';
import {FormImportDialogComponent} from './admin/form/form-import/form-import-dialog.component';
import {EnvironmentConfigComponent} from './admin/environment-config/environment-config.component';
import {EnvironmentConfigService} from './lib/environment-config/environment-config.service';
import {EnvironmentConfigResourceService} from './lib/environment-config/environment-config-resource.service';
import {FirebaseMessageService} from './lib/firebase/firebase-message.service';
import {FirebaseMessageResourceService} from './lib/firebase/firebase-message-resource.service';
import {PushNavigationService} from './util/firebase/push-navigation.service';
import {MessageResourceService} from './lib/message/message-resource.service';
import {MessageService} from './lib/message/message.service';
import {SidebarRightComponent} from './admin/sidebar-right/sidebar-right.component';
import {
  SidebarNotificationListComponent
} from './admin/sidebar-right/notification-list/sidebar-notification-list.component';
import {LoginDisabledComponent} from './error-pages/login-disabled/login-disabled.component';
import {MessageTemplateSearchService} from './lib/message-template/message-template-search.service';
import {MessageTemplateService} from './lib/message-template/message-template.service';
import {MessageTemplateResourceService} from './lib/message-template/message-template-resource.service';
import {
  NotificationTemplateListComponent
} from './admin/notification-template/notification-template-list/notification-template-list.component';
import {
  NotificationTemplateBaseComponent
} from './admin/notification-template/notification-template-base/notification-template-base.component';
import {
  GeneralPrinterTemplateListComponent
} from './admin/general-printer-template/general-printer-template-list/general-printer-template-list.component';
import {GeneralPrinterTemplateService} from './lib/general-printer-template/general-printer-template.service';
import {
  GeneralPrinterTemplateResourceService
} from './lib/general-printer-template/general-printer-template-resource.service';
import {
  GeneralPrinterTemplateSearchService
} from './lib/general-printer-template/general-printer-template-search-service';
import {ToasterModule} from './fork/angular2-toaster/src/toaster.module';
import {TwoWayTreeMultiselectComponent} from './shared/two-way-tree-multiselect/two-way-tree-multiselect.component';
import {TreeNodeComponent} from './shared/tree/tree-node-component/tree-node.component';
import {CustomerDashboardComponent} from './admin/customer/customer-dashboard/customer-dashboard.component';
import {AngularMultiSelectModule} from './fork/angular2-multiselect-dropdown/src/lib';
import {
  FormEditCreateUpdateInvoiceFieldDialogComponent
} from './admin/form/form-edit/dialogs/fields/invoice/create-update/form-edit-create-update-invoice-field-dialog.component';
import {
  FormRecordInvoiceFieldComponent
} from './admin/form/form-record/fields/invoice/form-record-invoice-field.component';
import {
  FormRecordInvoiceFieldItemEditComponent
} from './admin/form/form-record/fields/invoice/form-record-invoice-field-item-edit/form-record-invoice-field-item-edit.component';
import {
  DqlStoredQuerySaveDialogComponent
} from './admin/dql-search/dql-stored-query-save-dialog/dql-stored-query-save-dialog.component';
import {TriggerListComponent} from './admin/trigger/trigger-list/trigger-list.component';
import {
  TriggerPreselectDialogComponent
} from './admin/trigger/trigger-preselect-dialog/trigger-preselect-dialog.component';
import {TriggerEditDialogComponent} from './admin/trigger/trigger-edit-dialog/trigger-edit-dialog.component';
import {TriggerDetailComponent} from './admin/trigger/trigger-detail/trigger-detail.component';
import {TriggerHistoryComponent} from './admin/trigger/trigger-history/trigger-history.component';
import {TriggerInstancesComponent} from './admin/trigger/trigger-instances/trigger-instances.component';
import {TransportTriggerListComponent} from './admin/transport/transport-trigger-list/transport-trigger-list.component';
import {TransportTriggerService} from './lib/transport/transport-trigger/transport-trigger.service';
import {TransportTriggerResourceService} from './lib/transport/transport-trigger/transport-trigger-resource.service';
import {
  TaskRecordHistoryMapComponent
} from './admin/task/task-record/task-record-history/task-record-history-map/task-record-history-map.component';
import {JsonComponent} from './shared/json/json.component';
import {SantaComponent} from './shared/santa/santa.component';
import {MaterialTagInputComponent} from './shared/material-tag-input/material-tag-input.component';
import {
  TaskRecordRelatedTasksComponent
} from './admin/task/task-record/task-record-related-tasks/task-record-related-tasks.component';
import {TaskRecordSubTaskService} from './lib/task/task-record-subtask/task-record-sub-task.service';
import {StockDetailComponent} from './admin/stock/stock-detail/stock-detail.component';
import {TaskRecordSubTaskResourceService} from './lib/task/task-record-subtask/task-record-sub-task-resource.service';
import {TaskRecordSubTaskComponent} from './admin/task/task-record/task-record-sub-task/task-record-sub-task.component';
import {
  TaskRecordSubTaskCreateEditDialogComponent
} from './admin/task/task-record/task-record-sub-task/task-record-sub-task-create-edit-dialog/task-record-sub-task-create-edit-dialog.component';
import {
  TaskRecordSubTaskDetailDialogComponent
} from './admin/task/task-record/task-record-sub-task/task-record-sub-task-detail-dialog/task-record-sub-task-detail-dialog.component';
import {
  TaskRecordSubTaskDeleteDialogComponent
} from './admin/task/task-record/task-record-sub-task/task-record-sub-task-delete-dialog/task-record-sub-task-delete-dialog.component';
import {ConfirmDialogComponent} from './shared/confirm-dialog/confirm-dialog.component';
import {ExteriorTransportService} from './lib/exterior-transport/exterior-transport.service';
import {ExteriorTransportResourceService} from './lib/exterior-transport/exterior-transport-resource.service';
import {
  ExteriorTransportListComponent
} from './admin/exterior-transport/exterior-transport-list/exterior-transport-list.component';
import {ExteriorTransportSearchService} from './lib/exterior-transport/exterior-transport-search-service';
import {OrderDocumentService} from './lib/order-document/order-document.service';
import {OrderDocumentResourceService} from './lib/order-document/order-document-resource.service';
import {OrderDocumentListComponent} from './admin/order-document/order-document-list/order-document-list.component';
import {
  OrderDocumentCreateDialogComponent
} from './admin/order-document/order-document-create-dialog/order-document-create-dialog.component';
import {ShipmentGroupBaseComponent} from './admin/shipment/shipment-group-base/shipment-group-base.component';
import {
  ShipmentGroupBaseDataComponent
} from './admin/shipment/shipment-group-base/shipment-group-base-data/shipment-group-base-data.component';
import {
  ShipmentGroupShippingDemandListComponent
} from './admin/shipment/shipment-group-base/shipment-group-shipping-demand-list/shipment-group-shipping-demand-list.component';
import {
  ShipmentGroupDepotListComponent
} from './admin/shipment/shipment-group-base/shipment-group-depot-list/shipment-group-depot-list.component';
import {
  ShippingDemandSplitDialogComponent
} from './admin/shipping-demand/shipping-demand-edit/shipping-demand-split-dialog/shipping-demand-split-dialog.component';
import {
  ExteriorTransportBaseComponent
} from './admin/exterior-transport/exterior-transport-base/exterior-transport-base.component';
import {GeneralEmailResourceService} from './lib/email-template/general-email-resource.service';
import {GeneralEmailService} from './lib/email-template/general-email.service';
import {
  ExteriorTransportBaseDataComponent
} from './admin/exterior-transport/exterior-transport-base/exterior-transport-base-data/exterior-transport-base-data.component';
import {
  ExteriorTransportShipmentsComponent
} from './admin/exterior-transport/exterior-transport-base/exterior-transport-shipments/exterior-transport-shipments.component';
import {ShipmentGroupSearchService} from './lib/shipment-group/shipment-group-search.service';
import {VatRateService} from './lib/vat-rate.service';
import {
  ExteriorTransportDocumentsComponent
} from './admin/exterior-transport/exterior-transport-base/exterior-transport-documents/exterior-transport-documents.component';
import {
  ExteriorTransportDocumentResourceService
} from './lib/exterior-transport/exterior-transport-document/exterior-transport-document-resource.service';
import {
  ExteriorTransportDocumentService
} from './lib/exterior-transport/exterior-transport-document/exterior-transport-document.service';
import {
  ExteriorTransportDocumentCreateDialogComponent
} from './admin/exterior-transport/exterior-transport-base/exterior-transport-documents/exterior-transport-document-create-dialog/exterior-transport-document-create-dialog.component';
import {CompanyMultiselectProvider} from './lib/company/company-multiselect.provider';
import {CompanyLocationMultiselectProvider} from './lib/company-location/company-location-multiselect.provider';
import {
  ShippingDemandMplStatisticsComponent
} from './admin/shipping-demand/shipping-demand-edit/shipping-demand-mpl-statistics/shipping-demand-mpl-statistics.component';
import {
  OrderDocumentZipDownloadDialogComponent
} from './admin/order-document/order-document-zip-download-dialog/order-document-zip-download-dialog.component';
import {MplTrackingService} from './lib/mpl-tracking/mpl-tracking.service';
import {MplTrackingResourceService} from './lib/mpl-tracking/mpl-tracking-resource.service';
import {RegistrationSettingsService} from './lib/registration/settings/registration-settings.service';
import {RegistrationSettingsResourceService} from './lib/registration/settings/registration-settings-resource.service';
import {
  RegistrationSettingsComponent
} from './admin/registration/registration-settings/registration-settings.component';
import {EmailTemplateMultiselectProvider} from './lib/email-template/email-template-multiselect.provider';
import {ShopRenterAuthComponent} from './shoprenter/auth/shoprenter-auth.component';
import {ShopRenterService} from './lib/shoprenter/shoprenter.service';
import {ShopRenterResourceService} from './lib/shoprenter/shoprenter-resource.service';
import {ShopRenterEntryComponent} from './shoprenter/entry/shoprenter-entry.component';
import {ShopRenterShopListComponent} from './admin/shop-renter/shop-renter-shop-list/shop-renter-shop-list.component';
import {UserMultiselectProvider} from './lib/user/user-multiselect.provider';
import {ShopRenterSearchService} from './lib/shoprenter/shop-renter-search.service';
import {ShopRenterShopBaseComponent} from './admin/shop-renter/shop-renter-shop-base/shop-renter-shop-base.component';
import {RegistrationService} from './lib/registration/registration.service';
import {RegistrationResourceService} from './lib/registration/registration-resource.service';
import {StockStockRecordListComponent} from './admin/stock/stock-stock-record-list/stock-stock-record-list.component';
import {StockStockRecordSearchService} from './lib/stock/stock-stock-record-search-service';
import {StockLogListComponent} from './admin/stock-log/stock-log-list/stock-log-list.component';
import {VehicleMultiselectProvider} from './lib/vehicles/vehicle-multiselect.provider';
import {MileageRecordService} from './lib/mileage-record/mileage-record.service';
import {MileageRecordResourceService} from './lib/mileage-record/mileage-record-resource.service';
import {WorklogService} from './lib/worklog/worklog.service';
import {ManualWorklogResourceService, WorklogResourceService} from './lib/worklog/worklog-resource.service';
import {WorklogSearchService} from './lib/worklog/worklog-search-service';
import {WorklogListContainerComponent} from './admin/worklog/worklog-list-container/worklog-list-container.component';
import {WorklogListComponent} from './admin/worklog/worklog-list/worklog-list.component';
import {
  ExteriorTransportEventLogComponent
} from './admin/exterior-transport/exterior-transport-base/exterior-transport-event-log/exterior-transport-event-log.component';
import {
  InvoiceSettingsListComponent
} from './admin/invoicing/invoice-settings/invoice-settings-list/invoice-settings-list.component';
import {MileageRecordListComponent} from './admin/mileage-record/mileage-record-list/mileage-record-list.component';
import {TaskRecordMultiselectProvider} from './lib/task/task-record-multiselect.provider';
import {MileageRecordSearchService} from './lib/mileage-record/mileage-record-search.service';
import {
  MileageRecordDetailComponent
} from './admin/mileage-record/mileage-record-detail/mileage-record-detail.component';
import {SurveyMultiselectProvider} from './lib/survey/survey-multiselect.provider';
import {OrderMplStatisticsComponent} from './admin/order/order-mpl-statistics/order-mpl-statistics.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {createFirebase} from './app.firebase';
import {
  TaskRecordMileageRecordComponent
} from './admin/task/task-record/task-record-mileage-record/task-record-mileage-record.component';
import {
  LoginRegisterIntroductionComponent
} from './shared/login-register-introduction/login-register-introduction.component';
import {UserMeService} from './lib/user/user-me.service';
import {OrderImportErrorService} from './lib/order-import-error/order-import-error.service';
import {OrderImportErrorResourceService} from './lib/order-import-error/order-import-error-resource.service';
import {
  OrderImportErrorListComponent
} from './admin/order-import-error/order-import-error-list/order-import-error-list.component';
import {
  OrderImportErrorDetailComponent
} from './admin/order-import-error/order-import-error-detail/order-import-error-detail.component';
import {OrderImportErrorSearchService} from './lib/order-import-error/order-import-error-search.service';
import {
  MatConfirmDialogComponent,
  MATERIAL_DIALOG_CONFIG_PROVIDER
} from './shared/mat-confirm-dialog/mat-confirm-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {
  FormRecordInvoiceFieldCopyDialogComponent
} from './admin/form/form-record/fields/invoice/form-record-invoice-field-copy-dialog/form-record-invoice-field-copy-dialog.component';
import {FormRecordFieldNotifierService} from './lib/form/form-record-field-notifier.service';
import {UserActivationComponent} from './register/user-activation/user-activation.component';
import {RoleMultiselectProvider} from './lib/role/role-multiselect.provider';
import {NgxMasonryModule} from 'ngx-masonry';
import {AdminDashboardCardComponent} from './admin/admin-dashboard/admin-dashboard-card/admin-dashboard-card.component';
import {
  LegacyProcessMplExportDialogComponent
} from './admin/legacy-process/legacy-process-mpl-export-dialog/legacy-process-mpl-export-dialog.component';
import {
  StockItemCustomerRecordPriceListComponent
} from './admin/stock-item/stock-item-customer-record-price-list/stock-item-customer-record-price-list.component';
import {StockItemCustomerRecordPriceSearchService} from './lib/stock/stock-item-customer-record-price-search-service';
import {
  OrderDetailStateListComponent
} from './admin/order/order-detail/order-detail-state-list/order-detail-state-list.component';
import {ServiceInfoResourceService} from './lib/service-info/service-info-resource.service';
import {ServiceInfoService} from './lib/service-info/service-info.service';
import {ServiceInfoDialogComponent} from './admin/service-info/service-info-dialog/service-info-dialog.component';
import {NonEmptyPipe} from './util/pipe/non-empty.pipe';
import {
  TaskRecordInvoiceCreateDialogComponent
} from './admin/task/task-record/task-record-invoice-create-dialog/task-record-invoice-create-dialog.component';
import {TaskRecordInvoiceComponent} from './admin/task/task-record/task-record-invoice/task-record-invoice.component';
import {ParcelCollectionPointService} from './lib/parcel-collection-point/parcel-collection-point.service';
import {
  ParcelCollectionPointResourceService
} from './lib/parcel-collection-point/parcel-collection-point-resource.service';
import {
  ParcelCollectionPointListComponent
} from './admin/parcel-collection-point/parcel-collection-point-list/parcel-collection-point-list.component';
import {ParcelCollectionPointSearchService} from './lib/parcel-collection-point/parcel-collection-point-search.service';
import {
  ParcelCollectionPointBaseComponent
} from './admin/parcel-collection-point/parcel-collection-point-base/parcel-collection-point-base.component';
import {BreadcrumbMenuComponent} from './shared/breadcrumb/breadcrumb-menu/breadcrumb-menu.component';
import {
  BreadcrumbButtonComponent
} from './shared/breadcrumb/breadcrumb-menu/breadcrumb-button/breadcrumb-button.component';
import {
  BreadcrumbDropdownComponent
} from './shared/breadcrumb/breadcrumb-menu/breadcrumb-dropdown/breadcrumb-dropdown.component';
import {DropdownItemComponent} from './shared/dropdown/dropdown-item/dropdown-item.component';
import {
  TaskRecordListMapComponent
} from './admin/task/task-record/task-record-list/task-record-list-map/task-record-list-map.component';
import {
  TaskRecordMapInfoWindowComponent
} from './admin/task/task-record/task-record-list/task-record-list-map/info-window/task-record-map-info-window.component';
import {TableOptionsMenuComponent} from './shared/table-options-menu/table-options-menu.component';
import {DeliveryMethodService} from './lib/delivery-method/delivery-method.service';
import {DeliveryMethodResourceService} from './lib/delivery-method/delivery-method-resource.service';
import {DeliveryMethodMultiselectProvider} from './lib/delivery-method/delivery-method-multiselect.provider';
import {
  MasterdataRecordAttachmentComponent
} from './admin/masterdata/record/masterdata-record-attachment/masterdata-record-attachment.component';
import {
  CustomerRecordContactLocationMultiselectProvider
} from './lib/customer/contact/customer-record-contact-location-multiselect.provider';
import {CustomerRecordContactResourceService} from './lib/customer/contact/customer-record-contact-resource.service';
import {CustomerRecordContactService} from './lib/customer/contact/customer-record-contact.service';
import {TableBadgeComponent} from './shared/table-badge/table-badge.component';
import {
  ShipmentGroupShipmentListComponent
} from './admin/shipment/shipment-group-list/shipment-group-shipment-list/shipment-group-shipment-list.component';
import {DeliveryMethodSearchService} from './lib/delivery-method/delivery-method-search.service';
import {DeliveryMethodListComponent} from './admin/delivery-method/delivery-method-list/delivery-method-list.component';
import {DeliveryMethodBaseComponent} from './admin/delivery-method/delivery-method-base/delivery-method-base.component';
import {StockMultiselectProvider} from './lib/stock/stock-multiselect.provider';
import {
  MileageRecordEditDialogComponent
} from './admin/mileage-record/mileage-record-edit-dialog/mileage-record-edit-dialog.component';
import {AssigneeTableCellComponent} from './shared/assignee-table-cell/assignee-table-cell.component';
import {AvatarModule} from 'ngx-avatars';
import {ResponsiveTabsetComponent} from './shared/responsive-tabset/responsive-tabset.component';
import {ResponsiveTabsetConfig} from './shared/responsive-tabset/responsive-tabset.config';
import {ResponsiveTabDirective} from './shared/responsive-tabset/responsive-tab.directive';
import {DomChangeDirective} from './shared/dom-change/dom-change.directive';
import {AngularResizeEventModule} from 'angular-resize-event';
import {ProjectRecordMultiselectProvider} from './lib/project/record/project-record-multiselect.provider';
import {
  FormRecordStockItemCustomPriceEditComponent
} from './admin/form/form-record/fields/stock/form-record-stock-item-custom-price-edit/form-record-stock-item-custom-price-edit.component';
import {
  StockRecordSelectorDialogComponent
} from './admin/stock-record/stock-record-selector-dialog/stock-record-selector-dialog.component';
import {ResourceHelper} from './lib/util/http-services';
import {
  StockRecordSelectorCardItemComponent
} from './admin/stock-record/stock-record-selector-dialog/stock-record-selector-card-item/stock-record-selector-card-item.component';
import {LoginOptionsHelper} from './lib/util/log-helpers';
import {CredentialLoginMethodFactory} from './lib/login-methods';
import {UserBaseComponent} from './admin/user/user-base/user-base.component';
import {UserProfilePictureComponent} from './shared/user-profile-picture/user-profile-picture.component';
import {FormRecordStockPercentValidatorService} from './lib/form/form-record-stock-percent-validator.service';
import {StockItemCategoryMultiselectProvider} from './lib/stock-item-category/stock-item-category-multiselect.provider';
import {
  TableDocumentContentResourceService
} from './lib/table-document-content/table-document-content-resource.service';
import {
  ColonnadeClientDocumentStatService
} from './lib/colonnade-client-document-stat/colonnade-client-document-stat.service';
import {
  ColonnadeClientDocumentStatResourceService
} from './lib/colonnade-client-document-stat/colonnade-client-document-stat-resource.service';
import {TableDocumentContentService} from './lib/table-document-content/table-document-content.service';
import {
  ColonnadeClientDocumentStatSearchComponent
} from './admin/client-document/colonnade-client-document-stat/colonnade-client-document-stat-search/colonnade-client-document-stat-search.component';
import {
  ColonnadeClientDocumentStatEnrollerReportComponent
} from './admin/client-document/colonnade-client-document-stat/colonnade-client-document-stat-enroller-report/colonnade-client-document-stat-enroller-report.component';
import {
  ColonnadeClientDocumentStatProductReportComponent
} from './admin/client-document/colonnade-client-document-stat/colonnade-client-document-stat-product-report/colonnade-client-document-stat-product-report.component';
import {
  ColonnadeClientDocumentStatEnrollerSummaryComponent
} from './admin/client-document/colonnade-client-document-stat/colonnade-client-document-stat-enroller-summary/colonnade-client-document-stat-enroller-summary.component';
import {
  ColonnadeClientDocumentStatSearchService
} from './lib/colonnade-client-document-stat/colonnade-client-document-stat-search.service';
import {UserProfilePictureService} from './lib/user/user-profile-picture.service';
import {ImageCropperModule} from 'ngx-image-cropper';
import {WebcamModule} from 'ngx-webcam';
import {UserGroupMultiselectProvider} from './lib/user-group/user-group-multiselect.provider';
import {WorkflowCreateEditorComponent} from './admin/workflow/workflow-create-editor/workflow-create-editor.component';
import {EmailAddressTypeResourceService} from './lib/email-address-type/email-address-type-resource.service';
import {EmailAddressTypeService} from './lib/email-address-type/email-address-type.service';
import {
  EmailAddressTypeListComponent
} from './admin/email-address-type/email-address-type-list/email-address-type-list.component';
import {
  EmailAddressTypeBaseComponent
} from './admin/email-address-type/email-address-type-base/email-address-type-base.component';
import {FormRecordStockAmountValidatorService} from './lib/form/form-record-stock-amount-validator.service';
import {
  StockRecordSelectorListItemComponent
} from './admin/stock-record/stock-record-selector-dialog/stock-record-selector-list-item/stock-record-selector-list-item.component';
import {StartupComponent} from './admin/dashboard/startup.component';
import {CanvasDragLogService} from './admin/workflow/workflow-create-editor/utils/service/canvas-drag-log-service';
import {SidebarTogglerService} from './lib/util/sidebar-toggler.service';
import {
  ShipmentGroupOuttakeComponent
} from './admin/shipment/shipment-group-base/shipment-group-outtake/shipment-group-outtake.component';
import {StepperItemComponent} from './shared/stepper/stepper-item/stepper-item.component';
import {
  StepperContainerComponent
} from './shared/stepper/stepper-container/stepper-container/stepper-container.component';
import {
  CustomerRecordContactPersonsComponent
} from './admin/customer/customer-record-contact-persons/customer-record-contact-persons.component';
import {
  CustomerRecordTableCellComponent
} from './shared/customer-record-table-cell/customer-record-table-cell.component';
import {
  CustomerRecordContactLocationsListComponent
} from './admin/customer/customer-record-contact-locations/customer-record-contact-locations-list/customer-record-contact-locations-list.component';
import {
  CustomerRecordContactLocationsCreateEditDialogComponent
} from './admin/customer/customer-record-contact-locations/customer-record-contact-locations-create-edit-dialog/customer-record-contact-locations-create-edit-dialog.component';
import {
  CustomerRecordContactLocationsDetailDialogComponent
} from './admin/customer/customer-record-contact-locations/customer-record-contact-locations-detail-dialog/customer-record-contact-locations-detail-dialog.component';
import {
  FormEditCreateUpdateUserFieldDialogComponent
} from './admin/form/form-edit/dialogs/fields/user/create-edit/form-edit-create-update-user-field-dialog.component';
import {FormRecordUserFieldComponent} from './admin/form/form-record/fields/user/form-record-user-field.component';
import {WorkflowResourceService} from './lib/workflow/workflow-resource.service';
import {WorkflowService} from './lib/workflow/workflow.service';
import {WorkflowListComponent} from './admin/workflow/workflow-list/workflow-list.component';
import {WorkflowSearchService} from './lib/workflow/workflow-search-service';
import {WorkflowBaseDialogComponent} from './admin/workflow/workflow-base-dialog/workflow-base-dialog.component';
import {WorkflowBaseComponent} from './admin/workflow/workflow-base/workflow-base.component';
import {CanvasSelectionService} from './admin/workflow/workflow-create-editor/utils/service/canvas-selection-service';
import {
  WorkflowCreateEditorDataEditComponent
} from './admin/workflow/workflow-create-editor/workflow-create-editor-data-edit/workflow-create-editor-data-edit.component';
import {
  WorkflowTaskSelectorDialogComponent
} from './admin/workflow/workflow-create-editor/utils/task-selector-dialog/workflow-task-selector-dialog.component';
import {ProcessResourceService} from './lib/process/process-resource.service';
import {ProcessService} from './lib/process/process.service';
import {ProcessSearchService} from './lib/process/process-search.service';
import {ProcessListComponent} from './admin/process/process-list/process-list.component';
import {ProcessBaseDialogComponent} from './admin/process/process-base-dialog/process-base-dialog.component';
import {CustomerRecordMultiselectProvider} from './lib/customer/customer-record-multiselect-provider.service';
import {DisableControlDirective} from './util/disable-control.directive';
import {WorkflowMultiselectProvider} from './lib/workflow/workflow-multiselect.provider';
import {
  FormFieldRuleEditComponent
} from './admin/workflow/workflow-create-editor/workflow-create-editor-data-edit/form-field-rule-edit/form-field-rule-edit.component';
import {ProcessDetailComponent} from './admin/process/process-detail/process-detail.component';
import {ProcessHistoryComponent} from './admin/process/process-detail/process-history/process-history.component';
import {ProcessMultiselectProvider} from './lib/process/process-multiselect.provider';
import {AndroidAppVersionResourceService} from './lib/andorid-app-version/android-app-version-resource.service';
import {AndroidAppVersionService} from './lib/andorid-app-version/android-app-version.service';
import {AndroidAppVersionComponent} from './admin/android-app-version/android-app-version.component';
import {
  AndroidAppVersionBaseComponent
} from './admin/android-app-version/android-app-version-base/android-app-version-base.component';
import {
  AndroidAppVersionSettingsComponent
} from './admin/android-app-version/android-app-version-settings/android-app-version-settings.component';
import {
  CustomerRecordAttachmentComponent
} from './admin/customer/customer-record-attachment/customer-record-attachment.component';
import {ListItemMapService} from './lib/list-item/list-item-map.service';
import {
  WorkflowImportDialogComponent
} from './admin/workflow/workflow-list/workflow-import/workflow-import-dialog.component';
import {
  StockItemCreateTypeSelectorDialogComponent
} from './admin/stock-item/stock-item-create-type-selector/stock-item-create-type-selector-dialog.component';
import {UnsupportedDeviceComponent} from './shared/unsupported-device/unsupported-device.component';
import {DeviceDetectorService} from './fork/ngx-device-detector/device-detector.service';
import {WorkflowCloneDialogComponent} from './admin/workflow/workflow-clone-dialog/workflow-clone-dialog.component';
import {ColorCircleModule} from 'ngx-color/circle';
import {PaletteColorPickerComponent} from './shared/palette-color-picker/palette-color-picker.component';
import {
  CustomerRecordMainCustomerRecordComponent
} from './admin/customer/customer-record-main-customer-record/customer-record-main-customer-record.component';
import {SidebarNavItemComponent} from './shared/sidebar-nav-item/sidebar-nav-item.component';
import {
  DemoModeFeatureDisabledDialogComponent
} from './shared/demo-mode-feature-disabled-dialog/demo-mode-feature-disabled-dialog.component';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from 'ng-recaptcha';
import {RuntimeConfiguration} from './lib/runtime-configuration';
import {environment} from '../environments/environment';
import {PasswordResetComponent} from './password-reset/password-reset.component';
import {PasswordResetResourceService} from './lib/password-reset/password-reset-resource.service';
import {PasswordResetService} from './lib/password-reset/password-reset.service';
import {
  PasswordResetSuccessDialogComponent
} from './password-reset/password-reset-success-dialog/password-reset-success-dialog.component';
import {GuidedTourModule, GuidedTourService} from 'ngx-guided-tour';
import {SiteTourService} from './lib/site-tour/site-tour.service';
import {AngularFireModule, FIREBASE_OPTIONS} from '@angular/fire/compat';
import {
  RegistrationSettingsContainerComponent
} from './admin/registration/registration-settings-container/registration-settings-container.component';
import {
  HelpdeskLoginRegistrationContainerComponent
} from './helpdesk/login-registration/container/login-registration-container.component';
import {HelpdeskDashboardComponent} from './helpdesk/dashboard/dashboard.component';
import {HelpdeskLayoutComponent} from './helpdesk/layout/layout.component';
import {HelpdeskLoginComponent} from './helpdesk/login-registration/login/login.component';
import {HelpdeskRegistrationComponent} from './helpdesk/login-registration/registration/registration.component';
import {HelpdeskMessagesCardComponent} from './helpdesk/dashboard/messages/messages.component';
import {HelpdeskNotificationsCardComponent} from './helpdesk/dashboard/notifications/notifications.component';
import {HelpdeskDashboardStatsComponent} from './helpdesk/dashboard/stats/stats.component';
import {HelpdeskBugReportListComponent} from './helpdesk/bug-report/list/bug-report-list.component';
import {BugReportSearchService} from './lib/helpdesk/bug-report/bug-report-search-service';
import {
  CustomerRecordHelpdeskComponent
} from './admin/customer/customer-record-helpdesk/customer-record-helpdesk.component';
import {
  HelpdeskBugReportTypeSelectorDialogComponent
} from './helpdesk/bug-report/type-selector/type-selector-dialog.component';
import {AppTypeHelperService} from './lib/util/app-type-helper.service';
import {
  TaskRecordBulkCloneDialogComponent
} from './admin/task/task-record/task-record-bulk-clone-dialog/task-record-bulk-clone-dialog.component';
import {TextEllipsisDirective} from './shared/text-ellipsis.directive';
import {TabSelectService} from './lib/util/tab-select.service';
import {
  CustomerRecordBillingInfoListComponent
} from './admin/customer/customer-record-billing-info/customer-record-billing-info-list/customer-record-billing-info-list.component';
import {
  CustomerRecordBillingInfoBaseDialogComponent
} from './admin/customer/customer-record-billing-info/customer-record-billing-info-base-dialog/customer-record-billing-info-base-dialog.component';
import {
  CroppedImageUploaderDialogComponent
} from './shared/cropped-image-uploader/dialog/cropped-image-uploader-dialog.component';
import {
  TaskRecordSmsRatingComponent
} from './admin/task/task-record/task-record-sms-rating/task-record-sms-rating.component';
import {PROJECT_SERVICE_PROVIDER, ProjectService} from './lib/project/project.service';
import {ProjectResourceService} from './lib/project/project-resource.service';
import {ProjectSearchService} from './lib/project/project-search.service';
import {ProjectMultiselectProvider} from './lib/project/project-multiselect.provider';
import {ProjectListComponent} from './admin/project/list/project-list.component';
import {ProjectBaseComponent} from './admin/project/base/project-base.component';
import {ProjectRecordService} from './lib/project/record/project-record.service';
import {ProjectRecordBaseComponent} from './admin/project/record/base/project-record-base.component';
import {CalendarTimelineViewComponent} from './admin/calendar/calendar-timeline-view/calendar-timeline-view.component';
import {
  CalendarTimelineRowComponent
} from './admin/calendar/calendar-timeline-view/calendar-timeline-row/calendar-timeline-row.component';
import {DisabledTableRowDirective} from './shared/disabled-table-row.directive';
import {
  UserGroupTypeBasedRolesComponent
} from './admin/usergroup/usergroup-type-based-roles/user-group-type-based-roles.component';
import {
  UserGroupTypeBasedRoleCardComponent
} from './admin/usergroup/usergroup-type-based-roles/user-group-type-based-role-card/user-group-type-based-role-card.component';
import {
  UserGroupTypeBasedRoleEditComponent
} from './admin/usergroup/usergroup-type-based-roles/user-group-type-based-role-card/user-group-type-based-role-edit/user-group-type-based-role-edit.component';
import {IconSelectorDialogComponent} from './shared/icon-selector/icon-selector-dialog/icon-selector-dialog.component';
import {IconSelectorComponent} from './shared/icon-selector/icon-selector/icon-selector.component';
import {TypeIconComponent} from './shared/icon-selector/type-icon/type-icon.component';
import {
  FormEditCreateUpdateStockIntakeFieldDialogComponent
} from './admin/form/form-edit/dialogs/fields/stock-intake/create-update/form-edit-create-update-stock-intake-field-dialog.component';
import {
  FormRecordStockIntakeFieldComponent
} from './admin/form/form-record/fields/stock-intake/form-record-stock-intake-field.component';
import {
  StockItemSelectorDialogComponent
} from './admin/stock-item/stock-item-selector-dialog/stock-item-selector-dialog.component';
import {StockSelectorDialogComponent} from './admin/stock/stock-selector-dialog/stock-selector-dialog.component';
import {
  FormEditCreateUpdateStockMoveFieldDialogComponent
} from './admin/form/form-edit/dialogs/fields/stock-move/create-update/form-edit-create-update-stock-move-field-dialog.component';
import {
  FormRecordStockMoveFieldComponent
} from './admin/form/form-record/fields/stock-move/form-record-stock-move-field.component';
import {DropdownButtonComponent} from './shared/dropdown/dropdown-button/dropdown-button.component';
import {
  FormRecordStockFieldCommentEditComponent
} from './admin/form/form-record/fields/stock/form-record-stock-field-comment-edit/form-record-stock-field-comment-edit.component';
import {FormStockService} from './lib/form/form-stock.service';
import {FormStockResourceService} from './lib/form/form-stock-resource.service';
import {StockItemMultiselectProvider} from './lib/stock/stock-item-multiselect.provider';
import {
  InvoiceStornoDialogComponent
} from './admin/invoicing/invoices/invoice-storno-dialog/invoice-storno-dialog.component';
import {InvoiceBalanceService} from './lib/invoice/balance/invoice-balance.service';
import {InvoiceBalanceResourceService} from './lib/invoice/balance/invoice-balance-resource.service';
import {
  InvoiceSpendingDialogComponent
} from './admin/invoicing/invoice-spending/invoice-spending-dialog/invoice-spending-dialog.component';
import {
  InvoiceBalanceDetailComponent
} from './admin/invoicing/invoice-balance/invoice-balance-detail/invoice-balance-detail.component';
import {
  InvoiceBalanceCreateDialogComponent
} from './admin/invoicing/invoice-balance/invoice-balance-create-dialog/invoice-balance-create-dialog.component';
import {TaskMultiselectProvider} from './lib/task/task-multiselect.provider';
import {SignaturePadDialogComponent} from './shared/signature-pad/signature-pad-dialog/signature-pad-dialog.component';
import {SignatureComponent} from './shared/signature-pad/signature/signature.component';
import {SignaturePadComponent} from './shared/signature-pad/signature-pad/signature-pad.component';
import {
  TaskRecordSignatureComponent
} from './admin/task/task-record/task-record-signature/task-record-signature.component';
import {InvoiceSpendingResourceService} from './lib/invoice/spending/invoice-spending-resource.service';
import {InvoiceSpendingService} from './lib/invoice/spending/invoice-spending.service';
import {InvoiceSpendingSearchService} from './lib/invoice/spending/invoice-spending-search.service';
import {
  InvoiceSpendingListComponent
} from './admin/invoicing/invoice-spending/invoice-spending-list/invoice-spending-list.component';
import {LedgerNumberListComponent} from './admin/ledger/number/ledger-number-list/ledger-number-list.component';
import {LedgerNumberResourceService} from './lib/ledger/number/ledger-number-resource.service';
import {LedgerNumberService} from './lib/ledger/number/ledger-number.service';
import {LedgerNumberSearchService} from './lib/ledger/number/ledger-number-search.service';
import {LedgerNumberDialogComponent} from './admin/ledger/number/ledger-number-dialog/ledger-number-dialog.component';
import {LedgerNumberMultiselectProvider} from './lib/ledger/number/ledger-number-multiselect.provider';
import {InvoiceTagResourceService} from './lib/invoice/tag/invoice-tag-resource.service';
import {InvoiceTagService} from './lib/invoice/tag/invoice-tag.service';
import {InvoiceTagSearchService} from './lib/invoice/tag/invoice-tag-search.service';
import {InvoiceTagMultiselectProvider} from './lib/invoice/tag/invoice-tag-multiselect-provider.service';
import {InvoiceTagListComponent} from './admin/invoicing/invoice-tag/invoice-tag-list/invoice-tag-list.component';
import {InvoiceTagDialogComponent} from './admin/invoicing/invoice-tag/invoice-tag-dialog/invoice-tag-dialog.component';
import {
  InvoiceTaggerDialogComponent
} from './admin/invoicing/invoices/invoice-tagger-dialog/invoice-tagger-dialog.component';
import {
  InvoiceBatchDownloadDialogComponent
} from './admin/invoicing/invoices/invoice-batch-download-dialog/invoice-batch-download-dialog.component';
import {InvoiceBookMultiselectProvider} from './lib/invoice/invoice-book/invoice-book-multiselect-provider.service';
import {
  InvoiceSettingsMultiselectProvider
} from './lib/invoice/invoice-settings/invoice-settings-multiselect-provider.service';
import {
  MasterdataRecordQuickCreateDialogComponent
} from './admin/masterdata/record/masterdata-record-quick-create-dialog/masterdata-record-quick-create-dialog.component';
import {MasterDataRecordMultiselectProvider} from './lib/masterdata/masterdata-record-multiselect-provider.service';
import {MasterDataMultiselectProvider} from './lib/masterdata/masterdata-multiselect-provider.service';
import {
  MasterdataRecordConnectToCustomerDialogComponent
} from './admin/masterdata/record/masterdata-record-connect-to-customer-dialog/masterdata-record-connect-to-customer-dialog.component';
import {
  StockStockRecordLotListComponent
} from './admin/stock/stock-stock-record-lot-list/stock-stock-record-lot-list.component';
import {
  StockRecordLotEditDialogComponent
} from './admin/stock/stock-record-lot-edit-dialog/stock-record-lot-edit-dialog.component';
import {
  FormRecordStockIntakeHeaderDialogComponent
} from './admin/form/form-record/fields/stock-intake/form-record-stock-intake-header-dialog/form-record-stock-intake-header-dialog.component';
import {StockItemStockListComponent} from './admin/stock-item/stock-item-stock-list/stock-item-stock-list.component';
import {
  MaterialAttachmentGalleryComponent
} from './shared/material-attachment-gallery/material-attachment-gallery.component';
import {ImagePreviewDirective} from './shared/image-preview/image-preview.directive';
import {
  TaskRecordCreateMaterialDialogComponent
} from './admin/task/task-record/task-record-create-material-dialog/task-record-create-material-dialog.component';
import {
  CustomerRecordQuickCreateMaterialDialogComponent
} from './admin/customer/customer-record-quick-create-material/customer-record-quick-create-material-dialog.component';
import {
  TaskRecordMessageListComponent
} from './admin/message/task-record-message-list/task-record-message-list.component';
import {
  CustomerRecordBillingInfoBaseComponent
} from './admin/customer/customer-record-billing-info/customer-record-billing-info-base/customer-record-billing-info-base.component';
import {AngularFireMessagingModule} from '@angular/fire/compat/messaging';
import {TaskImportDialogComponent} from './admin/task/task-import-dialog/task-import-dialog.component';
import {TaskExportDialogComponent} from './admin/task/task-export-dialog/task-export-dialog.component';
import {PreventDoubleClickDirective} from './shared/prevent-double-click.directive';
import {
  MaterialAttachmentNameEditComponent
} from './shared/material-attachment-gallery/material-attachment-name-edit/material-attachment-name-edit.component';
import {
  StockItemMeasurementListComponent
} from './admin/stock-item/stock-item-measurement-list/stock-item-measurement-list.component';
import {
  StockItemMeasurementDialogComponent
} from './admin/stock-item/stock-item-measurement-list/stock-item-measurement-dialog/stock-item-measurement-dialog.component';
import {StockItemUnitOfMeasureMultiselectProvider} from './lib/stock/stock-item-unit-of-measure-multiselect.provider';
import {StockItemMeasurementSearchService} from './lib/stock/stock-item-measurement-search-service';
import {
  WorklogMaterialDialogComponent
} from './admin/worklog/worklog-material-dialog/worklog-material-dialog.component';
import {
  FormRecordMasterDataFieldLoader
} from './admin/form/form-record/fields/master-data/form-record-master-data-field.loader';
import {
  MasterDataRecordSelectorDialogComponent
} from './admin/form/form-record/fields/master-data/form-record-master-data-add-dialog/master-data-record-selector-dialog.component';
import {
  TableDisplayedFormFieldCellComponent
} from './shared/table-displayed-form-field-cell/table-displayed-form-field-cell.component';
import {TableColoredRowDirective} from './shared/table-colored-row.directive';
import {TaskRecordBoardComponent} from './admin/task/task-record/task-record-board/task-record-board.component';
import {
  TaskRecordBoardCardComponent
} from './admin/task/task-record/task-record-board/task-record-board-card/task-record-board-card.component';
import {
  CalendarProjectRecordDetailDialogComponent
} from './admin/calendar/calendar-project-record-detail-dialog/calendar-project-record-detail-dialog.component';
import {
  CalendarTaskRecordDetailDialogComponent
} from './admin/calendar/calendar-task-record-detail-dialog/calendar-task-record-detail-dialog.component';
import {GlobalCalendarEventResourceService} from './lib/global-calendar/global-calendar-event-resource.service';
import {GlobalCalendarEventService} from './lib/global-calendar/global-calendar-event.service';
import {GlobalCalendarEventSearchService} from './lib/global-calendar/global-calendar-event-search.service';
import {GlobalCalendarComponent} from './admin/global-calendar/global-calendar.component';
import {
  GlobalCalendarEventDialogComponent
} from './admin/global-calendar/global-calendar-event-dialog/global-calendar-event-dialog.component';
import {UserSignatureListComponent} from './admin/user/user-base/user-signature-list/user-signature-list.component';
import {
  FileUploadMaterialDialogComponent
} from './shared/file-upload/material-dialog/file-upload-material-dialog.component';
import {RoleImportDialogComponent} from './admin/role/role-import-dialog/role-import-dialog.component';
import {ProcessMessageListComponent} from "./admin/message/process-message-list/process-message-list.component";
import {MessageListsComponent} from './admin/message/message-lists/message-lists.component';
import {
  TaskRecordStateChangeDialogComponent
} from './admin/task/task-record/task-record-state-change-dialog/task-record-state-change-dialog.component';
import {
  TaskRecordSupExportDialogComponent
} from './admin/task/task-record/task-record-sup-export-dialog/task-record-sup-export-dialog.component';
import {
  CustomerRecordSelectorDialogComponent
} from "./admin/form/form-record/fields/customer/form-record-customer-add-dialog/customer-record-selector-dialog.component";
import {
  FormRecordCustomerFieldLoader
} from "./admin/form/form-record/fields/customer/form-record-customer-field.loader";
import {ContractNumberResourceService} from "./lib/customer/contract-number/contract-number-resource.service";
import {ContractNumberService} from "./lib/customer/contract-number/contract-number.service";
import {
  ContractNumberMultiselectProvider
} from "./lib/customer/contract-number/contract-number-multiselect-provider.service";
import { DocumentLibraryComponent } from './admin/document/document-library/document-library.component';
import { DocumentLibraryGroupListComponent } from './admin/document/document-library/document-library-group-list/document-library-group-list.component';
import { TaskRuleEditComponent } from './admin/workflow/workflow-create-editor/workflow-create-editor-data-edit/task-rule-edit/task-rule-edit.component';
import { BaseRuleEditComponent } from './admin/workflow/workflow-create-editor/workflow-create-editor-data-edit/base-rule-edit/base-rule-edit.component';
import {TaskRecordSearchService} from "./lib/task/task-record-search.service";
/* eslint-enable */

registerLocaleData(localeHu, 'hu-HU');

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    NgxPaginationModule,
    FileUploadModule,
    ChartsModule,
    Ng2GoogleChartsModule,
    LaddaModule,
    ToasterModule.forRoot(),
    InputTrimModule,
    NgbModule,
    DragDropModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    PopoverModule.forRoot(),
    UIRouterModule.forRoot(APP_UI_ROUTER_ROOT_MODULE),
    TranslateModule.forRoot({
      loader: APP_TRANSLATE_LOADER
    }),
    TextMaskModule,
    ReactiveFormsModule,
    AngularMultiSelectModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatInputModule,
    MatStepperModule,
    MatButtonModule,
    NgxEditorModule,
    CommonModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    QuillModule.forRoot(),
    HighlightModule,
    MatCardModule,
    AngularFireMessagingModule,
    MatCheckboxModule,
    MatIconModule,
    MatChipsModule,
    MatButtonToggleModule,
    MatDialogModule,
    NgxMasonryModule,
    AvatarModule,
    AngularResizeEventModule,
    ImageCropperModule,
    WebcamModule,
    ColorCircleModule,
    RecaptchaV3Module,
    GuidedTourModule,
    AngularFireModule,
  ],
  declarations: [
    NAV_DROPDOWN_DIRECTIVES,
    SIDEBAR_TOGGLE_DIRECTIVES,
    PhonePipe,
    EmailPipe,
    TaskStatePipe,
    FormattedNumberPipe,
    FormattedDurationPipe,
    NonEmptyPipe,
    NgbInputTimepickerDirective,
    UrlValidator,
    HuTaxNumberValidator,
    EuVatNumberValidator,
    PhoneNumberValidator,
    OptionalEmailValidator,
    RequiredAutoCompleteValidator,
    OptionalPositiveNumberValidator,
    EnabledItemsValidator,
    OptionalLatitudeValidator,
    OptionalLongitudeValidator,
    LocalDateValidator,
    MatchingPasswordValidator,
    AsideToggleDirective,
    ValidateOnBlurDirective,
    ImagePreviewDirective,
    JsonComponent,
    SantaComponent,
    TableFieldSorterComponent,
    TableSorterNoOpComponent,
    TableSorterComponent,
    TablePagingComponent,
    ConnectionErrorPageComponent,
    ServerErrorPageComponent,
    PieComponent,
    AppComponent,
    LoadingSpinnerComponent,
    LoginComponent,
    AdminLayoutComponent,
    AdminDashboardComponent,
    TaskDashboardComponent,
    DashboardComponent,
    StartupComponent,
    PaginationControlsComponent,
    RegisterComponent,
    UserListComponent,
    UserGroupListComponent,
    UserGroupCreateComponent,
    UserGroupEditComponent,
    UserGroupCloneComponent,
    UsergroupDetailComponent,
    ApplicationDictionaryListComponent,
    ApiUserKeyComponent,
    AnimatedCheckboxComponent,
    DeviceManagementListComponent,
    FormRecordFieldLineBreakComponent,
    FormRecordGroupHolderDirective,
    FormRecordFieldHolderDirective,
    FormRecordFormTableFieldHolderDirective,
    FormRecordFormTableFieldRecordHolderDirective,
    FormRecordFormTableFieldWrapperComponent,
    FormRecordContainerComponent,
    FormRecordComposedContainerComponent,
    FormRecordDefaultGroupComponent,
    FormRecordDisabledGroupComponent,
    FormRecordFieldWrapperComponent,
    FormRecordFieldWrapperHolderDirective,
    FormRecordUnknownFieldComponent,
    FormRecordDisabledFieldComponent,
    FormRecordStringFieldComponent,
    FormRecordNumberFieldComponent,
    FormRecordDecimalFieldComponent,
    FormRecordDateFieldComponent,
    FormRecordDateTimeFieldComponent,
    FormRecordBooleanFieldComponent,
    FormRecordEmailAddressFieldComponent,
    FormRecordPhoneNumberFieldComponent,
    FormRecordFormTableFieldComponent,
    FormRecordReadonlyTextFieldComponent,
    FormRecordReadonlyHtmlFieldComponent,
    FormRecordReadonlyPictureFieldComponent,
    FormRecordListItemFieldComponent,
    FormRecordMultiItemFieldComponent,
    FormRecordMasterDataFieldComponent,
    FormRecordStockFieldComponent,
    FormEditComponent,
    FormEditDialogHolderDirective,
    FormEditDialogContainerComponent,
    FormEditCreateUpdateGroupDialogComponent,
    FormEditCreateUpdateUnknownFieldDialogComponent,
    FormEditCreateUpdateStringFieldDialogComponent,
    FormEditCreateUpdateNumberFieldDialogComponent,
    FormEditCreateUpdatePhoneNumberFieldDialogComponent,
    FormEditCreateUpdateEmailAddressFieldDialogComponent,
    FormEditCreateUpdateDecimalFieldDialogComponent,
    FormEditCreateUpdateDateFieldDialogComponent,
    FormEditCreateUpdateDateTimeFieldDialogComponent,
    FormEditCreateUpdateBooleanFieldDialogComponent,
    FormEditCreateUpdateFormTableFieldDialogComponent,
    FormEditCreateUpdateReadonlyTextFieldDialogComponent,
    FormEditCreateUpdateReadonlyHtmlFieldDialogComponent,
    FormEditCreateUpdateReadonlyPictureFieldDialogComponent,
    FormEditCreateUpdateListItemFieldDialogComponent,
    FormEditCreateUpdateListMultiItemFieldDialogComponent,
    FormEditCreateUpdateStockFieldDialogComponent,
    FormEditCreateUpdateMasterDataFieldDialogComponent,
    TaskListComponent,
    TaskCreateComponent,
    TaskEditComponent,
    TaskRecordBaseComponent,
    TaskRecordCloneComponent,
    TaskRecordListComponent,
    TaskRecordCreateComponent,
    TaskRecordDetailComponent,
    TaskRecordEditComponent,
    TaskRecordHistoryComponent,
    TaskRecordSurveyComponent,
    DocumentHTMLListComponent,
    DocumentHTMLCreateComponent,
    DocumentHTMLReadComponent,
    DocumentHTMLEditComponent,
    DocumentTextListComponent,
    DocumentTextCreateComponent,
    DocumentTextEditComponent,
    DocumentTextReadComponent,
    DocumentLinkListComponent,
    DocumentLinkCreateComponent,
    DocumentLinkEditComponent,
    DocumentLinkReadComponent,
    NotificationListComponent,
    NotificationCreateComponent,
    NotificationReadComponent,
    HelpAppComponent,
    CustomerListComponent,
    CustomerCreateComponent,
    CustomerEditComponent,
    CustomerRecordListComponent,
    CustomerRecordBaseComponent,
    CustomerRecordCreateComponent,
    CustomerRecordEditComponent,
    CustomerRecordDetailComponent,
    CustomerRecordCloneComponent,
    DocumentFileCreateComponent,
    DocumentFileEditComponent,
    DocumentFileListComponent,
    DocumentFileReadComponent,
    ListItemTypeListComponent,
    ListItemTypeCreateComponent,
    ListItemTypeEditComponent,
    AppworksComponent,
    ListItemListComponent,
    ListItemCreateComponent,
    ListItemEditComponent,
    DocumentGroupListComponent,
    DocumentGroupCreateComponent,
    DocumentGroupEditComponent,
    AuditLogListComponent,
    AuditLogDetailComponent,
    ProjectRecordListComponent,
    ProjectRecordBaseComponent,
    MasterDataListComponent,
    MasterdataCreateComponent,
    MasterdataEditComponent,
    MasterDataRecordListComponent,
    MasterDataRecordBaseComponent,
    MasterDataRecordCreateComponent,
    MasterDataRecordEditComponent,
    MasterDataRecordDetailComponent,
    MasterDataRecordCloneComponent,
    StockListComponent,
    StockCreateComponent,
    StockEditComponent,
    StockDetailComponent,
    StockItemListComponent,
    StockItemCreateComponent,
    StockItemEditComponent,
    StockLogListContainerComponent,
    SurveyListComponent,
    SurveyCreateComponent,
    SurveyEditComponent,
    FormTableListComponent,
    FormTableCreateComponent,
    FormTableEditComponent,
    SurveyRecordListComponent,
    SurveyRecordBaseComponent,
    SurveyRecordCreateComponent,
    SurveyRecordDetailComponent,
    SurveyRecordEditComponent,
    SurveyRecordCloneComponent,
    NoteListComponent,
    NoteDetailComponent,
    PermissionListComponent,
    FileUploadDialogComponent,
    FileUploadComponent,
    PostalAddressFieldComponent,
    PostalAddressComplexComponent,
    EmailAddressItemComponent,
    EmailAddressListComponent,
    PhoneNumberItemComponent,
    PhoneNumberListComponent,
    ContactPersonItemComponent,
    ContactPersonListComponent,
    PhoneNumberOptItemComponent,
    EmailAddressOptItemComponent,
    LocationListComponent,
    LocationItemComponent,
    TaskRecordAttachmentComponent,
    DqlSearchContainerComponent,
    DqlSearchContainerHolderDirective,
    DqlSearchFieldSimpleComponent,
    DqlLogicalOperationComponent,
    DqlSearchFieldListComponent,
    DqlSearchFieldByIdComponent,
    DqlSearchFieldEnumComponent,
    DqlSearchFieldBooleanComponent,
    DqlSearchFieldDateComponent,
    DqlSearchFieldDateRangeComponent,
    DqlSearchFieldNumberRangeComponent,
    DqlSearchFieldUnaryComponent,
    DqlDialogContainerComponent,
    DqlCreateDialogComponent,
    DqlDialogValueHolderDirective,
    InvoiceBookListComponent,
    InvoiceBookCreateComponent,
    InvoiceBookEditComponent,
    InvoiceSettingsBaseComponent,
    BillingAddressComponent,
    InvoiceListComponent,
    InvoiceCreateCloneComponent,
    TaskRecordPdfComponent,
    InvoiceRecordContainerComponent,
    InvoiceRecordEditComponent,
    FileAttachmentGalleryComponent,
    MaterialAttachmentGalleryComponent,
    BreadcrumbComponent,
    TaskRecordBreadcrumbButtonsComponent,
    CustomerRecordBreadcrumbButtonsComponent,
    SurveyRecordBreadcrumbButtonsComponent,
    MasterdataRecordBreadcrumbButtonsComponent,
    ConfirmLeaveModalComponent,
    TaskLogListComponent,
    FormEditCreateUpdatePaymentTypeFieldDialogComponent,
    FormRecordPaymentTypeFieldComponent,
    InvoiceDetailComponent,
    TaskStatisticsListComponent,
    TaskStatisticsDetailComponent,
    InvoiceStatisticsListComponent,
    StockItemDetailComponent,
    DashboardPieChartComponent,
    OrderListComponent,
    OrderDetailComponent,
    OrderEditComponent,
    OrderStockItemContainerComponent,
    OrderStockItemEditComponent,
    LegacyProcessListComponent,
    LegacyWorkflowListComponent,
    LegacyWorkflowCreateComponent,
    LegacyWorkflowEditComponent,
    LegacyWorkflowTaskEditComponent,
    FormEditCreateUpdateCustomerFieldDialogComponent,
    FormEditCreateUpdateUserFieldDialogComponent,
    FormRecordCustomerFieldComponent,
    FormRecordUserFieldComponent,
    FormEditCreateUpdateDocumentFieldDialogComponent,
    FormEditCreateUpdatePhotoFieldDialogComponent,
    FormRecordDocumentFieldComponent,
    FormRecordPhotoFieldComponent,
    LegacyProcessEditComponent,
    FormRecordStockFieldItemEditComponent,
    LegacyProcessTaskBaseComponent,
    LegacyProcessTaskEditComponent,
    LegacyProcessTaskDetailComponent,
    FormEditCreateUpdateProcessOrderStockOuttakeFieldDialogComponent,
    FormRecordProcessOrderStockOuttakeFieldComponent,
    FormEditCreateUpdateProcessOrderStockOuttakeCheckFieldDialogComponent,
    FormEditCreateUpdateProcessOrderPackagingFieldDialogComponent,
    FormEditCreateUpdateListItemActivationRuleComponent,
    FormRecordProcessOrderStockOuttakeCheckFieldComponent,
    LegacyProcessEditStateHistoryComponent,
    FormRecordProcessOrderPackagingFieldComponent,
    LegacyProcessDetailComponent,
    StockChangeListComponent,
    StockChangeDetailComponent,
    FormEditCreateUpdateReadonlyPictureFieldDialogComponent,
    FormRecordReadonlyPictureFieldComponent,
    LegacyProcessTaskAttachmentComponent,
    SurveyDashboardComponent,
    StockTakingsListComponent,
    StockTakingsEditComponent,
    StockTakingsDetailComponent,
    StockTakingSheetListComponent,
    StockTakingSheetDetailComponent,
    SimpleDashboardComponent,
    DocumentDashboardComponent,
    CalendarComponent,
    CompanyListComponent,
    CompanyBaseComponent,
    VehicleListComponent,
    RoleListComponent,
    RoleCreateComponent,
    RoleEditComponent,
    RoleDetailComponent,
    TwoWayMultiselectComponent,
    VehicleBaseComponent,
    CompanyLocationListComponent,
    CompanyLocationBaseComponent,
    CompanyStockListComponent,
    CompanyStockBaseComponent,
    TransportListComponent,
    TransportDetailComponent,
    TransportBaseDataComponent,
    ShippingDemandListComponent,
    TransportDocumentListComponent,
    TransportLogListComponent,
    ShippingDemandEditComponent,
    ShippingDemandAddressComponent,
    ShippingDemandItemContainerComponent,
    ShippingDemandItemEditComponent,
    TransportTaskListComponent,
    TransportTaskDialogContainerComponent,
    TransportShipmentListComponent,
    ShipmentGroupListComponent,
    TransportPositionlogMapComponent,
    AnyspedComponent,
    AnyspedDashboardTransportComponent,
    AnyspedDashboardShipmentComponent,
    TaskRecordCreateMaterialDialogComponent,
    CustomerRecordQuickCreateMaterialDialogComponent,
    CustomerOwnerMasterDataListComponent,
    StockItemCategoryListComponent,
    StockItemCategoryBaseComponent,
    TransportAttachmentComponent,
    ImageViewerWindowComponent,
    ChatComponent,
    TransportRunningMapComponent,
    PositionLogInfoWindowComponent,
    TransportLogDetailModalComponent,
    ConfigurationLoadErrorComponent,
    ColonnadeInsuranceListComponent,
    EmailTemplateListComponent,
    EmailTemplateBaseComponent,
    TableDocumentSchemaListComponent,
    TableDocumentSchemaBaseComponent,
    TableDocumentSchemaFieldBaseComponent,
    TableDocumentListComponent,
    ConfigurationLoadErrorComponent,
    GeneralPdfTemplateListComponent,
    CustomerRecordHistoryComponent,
    TokenListComponent,
    ApplicationFileListComponent,
    NfcCardListComponent,
    CompanyStockCreateComponent,
    CompanyCreateModalComponent,
    CompanyLocationCreateModalComponent,
    UserGroupCreateModalComponent,
    HistoryDetailDialogComponent,
    HistoryLogComponent,
    PlaceUpdateComponent,
    NfcCardListComponent,
    PlaygroundComponent,
    FormImportDialogComponent,
    EnvironmentConfigComponent,
    LoginDisabledComponent,
    SidebarRightComponent,
    SidebarNotificationListComponent,
    NotificationTemplateListComponent,
    NotificationTemplateBaseComponent,
    GeneralPrinterTemplateListComponent,
    TwoWayTreeMultiselectComponent,
    TreeNodeComponent,
    CustomerDashboardComponent,
    FormEditCreateUpdateInvoiceFieldDialogComponent,
    FormRecordInvoiceFieldComponent,
    FormRecordInvoiceFieldItemEditComponent,
    DqlStoredQuerySaveDialogComponent,
    TriggerListComponent,
    TriggerPreselectDialogComponent,
    TriggerEditDialogComponent,
    TriggerDetailComponent,
    TriggerHistoryComponent,
    TriggerInstancesComponent,
    TransportTriggerListComponent,
    TaskRecordHistoryMapComponent,
    MaterialTagInputComponent,
    TaskRecordRelatedTasksComponent,
    TaskRecordSubTaskComponent,
    TaskRecordSubTaskCreateEditDialogComponent,
    TaskRecordSubTaskDetailDialogComponent,
    TaskRecordSubTaskDeleteDialogComponent,
    ConfirmDialogComponent,
    ExteriorTransportListComponent,
    TaskRecordSubTaskDeleteDialogComponent,
    OrderDocumentListComponent,
    OrderDocumentCreateDialogComponent,
    OrderDocumentZipDownloadDialogComponent,
    ShipmentGroupBaseComponent,
    ShipmentGroupBaseDataComponent,
    ShipmentGroupShippingDemandListComponent,
    ShipmentGroupDepotListComponent,
    ShippingDemandSplitDialogComponent,
    ExteriorTransportBaseComponent,
    ExteriorTransportBaseDataComponent,
    ExteriorTransportShipmentsComponent,
    ExteriorTransportDocumentsComponent,
    ExteriorTransportDocumentCreateDialogComponent,
    ShippingDemandMplStatisticsComponent,
    RegistrationSettingsComponent,
    ShopRenterAuthComponent,
    ShopRenterEntryComponent,
    ShopRenterShopListComponent,
    ShopRenterShopBaseComponent,
    StockStockRecordListComponent,
    StockLogListComponent,
    WorklogListContainerComponent,
    WorklogListComponent,
    ExteriorTransportEventLogComponent,
    InvoiceSettingsListComponent,
    MileageRecordListComponent,
    MileageRecordDetailComponent,
    TaskRecordMileageRecordComponent,
    LoginRegisterIntroductionComponent,
    OrderMplStatisticsComponent,
    OrderImportErrorListComponent,
    OrderImportErrorDetailComponent,
    MatConfirmDialogComponent,
    FormRecordInvoiceFieldCopyDialogComponent,
    UserActivationComponent,
    AdminDashboardCardComponent,
    LegacyProcessMplExportDialogComponent,
    StockItemCustomerRecordPriceListComponent,
    OrderDetailStateListComponent,
    ServiceInfoDialogComponent,
    TaskRecordInvoiceCreateDialogComponent,
    TaskRecordInvoiceComponent,
    ParcelCollectionPointListComponent,
    ParcelCollectionPointBaseComponent,
    BreadcrumbMenuComponent,
    BreadcrumbButtonComponent,
    BreadcrumbDropdownComponent,
    DropdownItemComponent,
    TaskRecordListMapComponent,
    TaskRecordMapInfoWindowComponent,
    TableOptionsMenuComponent,
    MasterdataRecordAttachmentComponent,
    TableBadgeComponent,
    TableOptionsMenuComponent,
    ShipmentGroupShipmentListComponent,
    DeliveryMethodListComponent,
    DeliveryMethodBaseComponent,
    MileageRecordEditDialogComponent,
    AssigneeTableCellComponent,
    ResponsiveTabsetComponent,
    ResponsiveTabDirective,
    DomChangeDirective,
    FormRecordStockItemCustomPriceEditComponent,
    StockRecordSelectorDialogComponent,
    StockRecordSelectorCardItemComponent,
    StockRecordSelectorListItemComponent,
    UserBaseComponent,
    UserProfilePictureComponent,
    WorkflowCreateEditorComponent,
    EmailAddressTypeListComponent,
    EmailAddressTypeBaseComponent,
    ColonnadeClientDocumentStatSearchComponent,
    ColonnadeClientDocumentStatEnrollerReportComponent,
    ColonnadeClientDocumentStatProductReportComponent,
    ColonnadeClientDocumentStatEnrollerSummaryComponent,
    ShipmentGroupOuttakeComponent,
    StepperItemComponent,
    StepperContainerComponent,
    CustomerRecordContactPersonsComponent,
    CustomerRecordTableCellComponent,
    CustomerRecordContactLocationsListComponent,
    CustomerRecordContactLocationsCreateEditDialogComponent,
    CustomerRecordContactLocationsDetailDialogComponent,
    WorkflowListComponent,
    WorkflowBaseDialogComponent,
    WorkflowBaseComponent,
    WorkflowCreateEditorDataEditComponent,
    WorkflowTaskSelectorDialogComponent,
    FormFieldRuleEditComponent,
    ProcessListComponent,
    ProcessBaseDialogComponent,
    DisableControlDirective,
    ProcessDetailComponent,
    ProcessHistoryComponent,
    AndroidAppVersionComponent,
    AndroidAppVersionBaseComponent,
    AndroidAppVersionSettingsComponent,
    CustomerRecordAttachmentComponent,
    WorkflowImportDialogComponent,
    StockItemCreateTypeSelectorDialogComponent,
    UnsupportedDeviceComponent,
    WorkflowCloneDialogComponent,
    PaletteColorPickerComponent,
    CustomerRecordMainCustomerRecordComponent,
    SidebarNavItemComponent,
    DemoModeFeatureDisabledDialogComponent,
    PasswordResetComponent,
    PasswordResetSuccessDialogComponent,
    RegistrationSettingsContainerComponent,
    HelpdeskLoginRegistrationContainerComponent,
    HelpdeskLoginComponent,
    HelpdeskDashboardComponent,
    HelpdeskLayoutComponent,
    HelpdeskRegistrationComponent,
    HelpdeskMessagesCardComponent,
    HelpdeskNotificationsCardComponent,
    HelpdeskDashboardStatsComponent,
    HelpdeskBugReportListComponent,
    CustomerRecordHelpdeskComponent,
    HelpdeskBugReportTypeSelectorDialogComponent,
    TaskRecordBulkCloneDialogComponent,
    TextEllipsisDirective,
    CustomerRecordBillingInfoListComponent,
    CustomerRecordBillingInfoBaseDialogComponent,
    CroppedImageUploaderDialogComponent,
    TaskRecordSmsRatingComponent,
    ProjectListComponent,
    ProjectBaseComponent,
    CalendarTimelineViewComponent,
    CalendarTimelineRowComponent,
    DisabledTableRowDirective,
    UserGroupTypeBasedRolesComponent,
    UserGroupTypeBasedRoleCardComponent,
    UserGroupTypeBasedRoleEditComponent,
    IconSelectorDialogComponent,
    IconSelectorComponent,
    TypeIconComponent,
    FormEditCreateUpdateStockIntakeFieldDialogComponent,
    FormRecordStockIntakeFieldComponent,
    StockItemSelectorDialogComponent,
    StockSelectorDialogComponent,
    FormEditCreateUpdateStockMoveFieldDialogComponent,
    FormRecordStockMoveFieldComponent,
    DropdownButtonComponent,
    FormRecordStockFieldCommentEditComponent,
    InvoiceStornoDialogComponent,
    InvoiceSpendingDialogComponent,
    InvoiceBalanceDetailComponent,
    InvoiceBalanceCreateDialogComponent,
    SignaturePadComponent,
    SignaturePadDialogComponent,
    SignatureComponent,
    TaskRecordSignatureComponent,
    InvoiceSpendingListComponent,
    LedgerNumberListComponent,
    LedgerNumberDialogComponent,
    InvoiceTagListComponent,
    InvoiceTagDialogComponent,
    InvoiceTaggerDialogComponent,
    InvoiceBatchDownloadDialogComponent,
    MasterdataRecordQuickCreateDialogComponent,
    MasterdataRecordConnectToCustomerDialogComponent,
    StockStockRecordLotListComponent,
    StockRecordLotEditDialogComponent,
    FormRecordStockIntakeHeaderDialogComponent,
    StockItemStockListComponent,
    TaskRecordMessageListComponent,
    ProcessMessageListComponent,
    CustomerRecordBillingInfoBaseComponent,
    TaskImportDialogComponent,
    TaskExportDialogComponent,
    PreventDoubleClickDirective,
    MaterialAttachmentNameEditComponent,
    StockItemMeasurementListComponent,
    StockItemMeasurementDialogComponent,
    WorklogMaterialDialogComponent,
    MasterDataRecordSelectorDialogComponent,
    CustomerRecordSelectorDialogComponent,
    TableDisplayedFormFieldCellComponent,
    TableColoredRowDirective,
    TaskRecordBoardComponent,
    TaskRecordBoardCardComponent,
    CalendarProjectRecordDetailDialogComponent,
    CalendarTaskRecordDetailDialogComponent,
    GlobalCalendarComponent,
    GlobalCalendarEventDialogComponent,
    UserSignatureListComponent,
    FileUploadMaterialDialogComponent,
    RoleImportDialogComponent,
    MessageListsComponent,
    TaskRecordStateChangeDialogComponent,
    TaskRecordSupExportDialogComponent,
    DocumentLibraryComponent,
    DocumentLibraryGroupListComponent,
    TaskRuleEditComponent,
    BaseRuleEditComponent,
  ],
  providers: [
    DATEPICKER_I18N_PROVIDER,
    DATEPICKER_FORMATTER_PROVIDER,
    TIMEPICKER_FORMATTER_PROVIDER,
    TIMEPICKER_ADAPTER_PROVIDER,
    LOCALE_CODE_PROVIDER,
    CONFIGURATION_PROVIDER,
    HTTP_INTERCEPTOR_PROVIDER,
    GLOBAL_ERROR_HANDLER_PROVIDER,
    MATERIAL_DIALOG_CONFIG_PROVIDER,
    {
      provide: RECAPTCHA_V3_SITE_KEY, useFactory: () => {
        return RuntimeConfiguration.getInstance().getRecaptchaV3SiteKey(environment.recaptchaV3SiteKey);
      }
    },
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          json: () => import('highlight.js/lib/languages/json'),
          xml: () => import('highlight.js/lib/languages/xml')
        }
      }
    },
    {
      provide: FIREBASE_OPTIONS,
      useFactory: createFirebase
    },
    ResourceHelper,
    LoginOptionsHelper,
    CredentialLoginMethodFactory,
    ApiUserKeyService,
    AppConfigurationService,
    ApplicationDictionaryService,
    AuditLogService,
    AuditLogSearchService,
    AuthService,
    ConfigurationService,
    CustomerRecordResourceService,
    CustomerRecordService,
    CustomerRecordSearchService,
    CustomerResourceService,
    CustomerRecordGlobalResourceService,
    CustomerService,
    CustomerSearchService,
    DatePickerInputFactory,
    DatePickerTemplateFactory,
    DeviceManagementService,
    DeviceSearchService,
    DocumentFileService,
    DocumentFileSearchService,
    DocumentGroupService,
    DocumentGroupSearchService,
    DocumentHTMLService,
    DocumentLanguageService,
    DocumentLanguageUtil,
    DocumentLinkSearchService,
    DocumentTextService,
    DocumentLinkService,
    FieldDataTypeSelectors,
    FileUploaderUtil,
    FormServiceFactory,
    IconService,
    InvoiceSearchService,
    LanguageService,
    ListItemResourceService,
    ListItemSearchService,
    ListItemTypeSearchService,
    MasterDataRecordResourceService,
    MasterDataRecordGlobalResourceService,
    MasterDataRecordMultiselectProvider,
    MasterDataMultiselectProvider,
    MasterDataRecordService,
    MasterdataRecordSearchService,
    MasterDataResourceService,
    MasterDataService,
    MasterdataSearchService,
    MessagesService,
    MessageSearchService,
    TaskService,
    TaskSearchService,
    NgbDatePickerParserFormatter,
    NoteService,
    NoteSearchService,
    NumberOfItemsFormatter,
    OwnerUserItemFactory,
    PermissionResourceService,
    ProjectRecordResourceService,
    ProjectRecordSearchService,
    RightService,
    RootCoreService,
    SettingsService,
    StockChangeService,
    StockChangeSearchService,
    StockChangeResourceService,
    StockItemService,
    StockItemSearchService,
    StockItemUnitOfMeasureMultiselectProvider,
    StockItemMeasurementSearchService,
    StockRecordLogService,
    StockRecordService,
    StockService,
    StockSearchService,
    StockLogSearchService,
    StockSubStockRecordService,
    FormTableResourceService,
    FormTableService,
    FormTableSearchService,
    SurveyRecordResourceService,
    SurveyRecordService,
    SurveyRecordSearchService,
    SurveyResourceService,
    SurveyService,
    SurveySearchService,
    TaskRecordResourceService,
    TaskRecordService,
    TaskRecordSubTaskService,
    TaskResourceService,
    TaskRecordGlobalResourceService,
    UploadErrorLocalizer,
    UserDataLoader,
    UserGroupService,
    UserGroupSearchService,
    UserService,
    UserSignatureService,
    UserSearchService,
    UserMeService,
    CountryService,
    CityService,
    CurrencyService,
    TaskRecordSearchService,
    TaskValidationTypeTextResolverFactory,
    DefaultDeadlineTypeTextResolverFactory,
    InvoiceSettingsService,
    InvoiceSettingsResourceService,
    InvoiceSettingsMultiselectProvider,
    LoggerFactory,
    InvoiceBookService,
    InvoiceBookSearchService,
    InvoiceBookResourceService,
    InvoiceBookMultiselectProvider,
    InvoiceBalanceService,
    InvoiceBalanceResourceService,
    InvoiceResourceService,
    InvoiceService,
    CityResourceService,
    TaskLogService,
    TaskLogResourceService,
    TaskLogSearchService,
    ApiOperationService,
    TaskStatisticsResourceService,
    TaskStatisticsService,
    TaskStatisticsSearchService,
    InvoiceStatisticsResourceService,
    InvoiceStatisticsService,
    OrderResourceService,
    OrderService,
    OrderSearchService,
    LegacyProcessResourceService,
    LegacyProcessService,
    LegacyProcessTaskResourceService,
    LegacyProcessTaskService,
    LegacyProcessSearchService,
    LegacyWorkflowService,
    LegacyWorkflowResourceService,
    LegacyWorkflowSearchService,
    LegacyWorkflowTaskService,
    LegacyWorkflowTaskResourceService,
    FormAttachmentService,
    FormAttachmentResourceService,
    RefreshFormOnChangeService,
    ErrorMessageService,
    CountryResourceService,
    StockTakingsService,
    StockTakingsResourceService,
    StockTakingsSearchService,
    StockTakingSheetService,
    StockTakingSheetResourceService,
    StockTakingSheetSearchService,
    FormRecordFieldViewModelFactory,
    FormRecordInactivityManagerFactory,
    FieldActivationStrategyFactory,
    BooleanFieldActivationStrategy,
    ListItemFieldActivationStrategy,
    ListMultiItemFieldActivationStrategy,
    NoOpFieldActivationStrategy,
    CalendarService,
    CalendarResourceService,
    CalendarSearchService,
    CompanyResourceService,
    CompanyService,
    CompanySearchService,
    VehicleResourceService,
    VehicleService,
    VehicleSearchService,
    RoleResourceService,
    RoleLogResourceService,
    RoleService,
    RoleSearchService,
    CompanyLocationService,
    CompanyLocationResourceService,
    CompanyLocationSearchService,
    CompanyStockService,
    CompanyStockResourceService,
    CompanyStockSearchService,
    TransportResourceService,
    TransportService,
    TransportSearchService,
    TransportDocumentService,
    TransportDocumentResourceService,
    ShippingDemandService,
    ShippingDemandResourceService,
    ShippingDemandSearchService,
    ShippingItemResourceService,
    ShippingItemService,
    TransportDocumentSearchService,
    TransportLogResourceService,
    TransportLogService,
    ShipmentGroupService,
    ShipmentGroupResourceService,
    ShipmentResourceService,
    TransportTaskResourceService,
    TransportTaskService,
    TransportDashboardResourceService,
    TransportDashboardService,
    ShipmentGroupTransportResourceService,
    DurationHumanizerService,
    StockItemCategoryResourceService,
    StockItemCategoryService,
    StockItemCategorySearchService,
    TransportAttachmentService,
    TransportAttachmentResourceService,
    ClientDocumentService,
    ClientDocumentResourceService,
    EmailTemplateResourceService,
    EmailTemplateService,
    EmailTemplateSearchService,
    TableDocumentSchemaResourceService,
    TableDocumentSchemaService,
    TableDocumentSchemaFieldResourceService,
    TableDocumentSchemaImportExportResourceService,
    TableDocumentSchemaSearchService,
    TableDocumentResourceService,
    TableDocumentService,
    TableDocumentSearchService,
    ColonnadeInsuranceSearchService,
    GeneralPdfTemplateResourceService,
    GeneralPdfTemplateService,
    GeneralPdfTemplateSearchService,
    TokenResourceService,
    TokenService,
    TokenSearchService,
    ApplicationFileResourceService,
    ApplicationFileService,
    ApplicationFileSearchService,
    CustomerRecordLogResourceService,
    NfcCardResourceService,
    NfcCardService,
    PlaceService,
    PlaceResourceService,
    EnvironmentConfigService,
    EnvironmentConfigResourceService,
    FirebaseMessageService,
    FirebaseMessageResourceService,
    PushNavigationService,
    MessageService,
    MessageResourceService,
    MessageTemplateResourceService,
    MessageTemplateService,
    MessageTemplateSearchService,
    GeneralPrinterTemplateService,
    GeneralPrinterTemplateResourceService,
    GeneralPrinterTemplateSearchService,
    TransportTriggerService,
    TransportTriggerResourceService,
    TaskRecordSubTaskService,
    TaskRecordSubTaskResourceService,
    ExteriorTransportService,
    ExteriorTransportResourceService,
    ExteriorTransportSearchService,
    OrderDocumentService,
    OrderDocumentResourceService,
    GeneralEmailResourceService,
    GeneralEmailService,
    ShipmentGroupSearchService,
    VatRateService,
    ExteriorTransportDocumentResourceService,
    ExteriorTransportDocumentService,
    CompanyMultiselectProvider,
    CompanyLocationMultiselectProvider,
    MplTrackingService,
    MplTrackingResourceService,
    RegistrationSettingsService,
    RegistrationSettingsResourceService,
    EmailTemplateMultiselectProvider,
    RoleMultiselectProvider,
    ShopRenterResourceService,
    ShopRenterService,
    UserMultiselectProvider,
    ShopRenterSearchService,
    RegistrationService,
    RegistrationResourceService,
    StockStockRecordSearchService,
    VehicleMultiselectProvider,
    MileageRecordService,
    MileageRecordResourceService,
    MileageRecordSearchService,
    WorklogService,
    WorklogResourceService,
    ManualWorklogResourceService,
    WorklogSearchService,
    TaskRecordMultiselectProvider,
    DatePipe,
    SurveyMultiselectProvider,
    OrderImportErrorService,
    OrderImportErrorResourceService,
    OrderImportErrorSearchService,
    SurveyMultiselectProvider,
    FormRecordFieldNotifierService,
    StockItemCustomerRecordPriceSearchService,
    ServiceInfoResourceService,
    ServiceInfoService,
    ParcelCollectionPointService,
    ParcelCollectionPointResourceService,
    ParcelCollectionPointSearchService,
    CustomerRecordContactService,
    CustomerRecordContactResourceService,
    CustomerRecordContactLocationMultiselectProvider,
    DeliveryMethodService,
    DeliveryMethodResourceService,
    DeliveryMethodMultiselectProvider,
    DeliveryMethodSearchService,
    StockMultiselectProvider,
    StockItemMultiselectProvider,
    ResponsiveTabsetConfig,
    ProjectRecordMultiselectProvider,
    TaskMultiselectProvider,
    EmailAddressTypeResourceService,
    EmailAddressTypeService,
    FormRecordStockPercentValidatorService,
    FormRecordStockAmountValidatorService,
    StockItemCategoryMultiselectProvider,
    TableDocumentContentResourceService,
    TableDocumentContentService,
    ColonnadeClientDocumentStatResourceService,
    ColonnadeClientDocumentStatService,
    ColonnadeClientDocumentStatSearchService,
    UserGroupMultiselectProvider,
    UserProfilePictureService,
    CanvasDragLogService,
    SidebarTogglerService,
    ShipmentOuttakeResourceService,
    CustomerRecordContactLocationResourceService,
    CustomerRecordContactPersonResourceService,
    WorkflowResourceService,
    WorkflowService,
    WorkflowSearchService,
    ProcessResourceService,
    ProcessService,
    ProcessSearchService,
    InvoiceSpendingResourceService,
    InvoiceSpendingService,
    InvoiceSpendingSearchService,
    LedgerNumberResourceService,
    LedgerNumberService,
    LedgerNumberSearchService,
    LedgerNumberMultiselectProvider,
    GlobalCalendarEventResourceService,
    GlobalCalendarEventService,
    GlobalCalendarEventSearchService,
    InvoiceTagResourceService,
    InvoiceTagService,
    InvoiceTagSearchService,
    InvoiceTagMultiselectProvider,
    ContractNumberResourceService,
    ContractNumberService,
    ContractNumberMultiselectProvider,
    CanvasSelectionService,
    CustomerRecordMultiselectProvider,
    WorkflowMultiselectProvider,
    ProcessMultiselectProvider,
    AndroidAppVersionResourceService,
    AndroidAppVersionService,
    CustomerRecordAttachmentResourceService,
    ListItemMapService,
    DeviceDetectorService,
    PasswordResetResourceService,
    PasswordResetService,
    GuidedTourService,
    SiteTourService,
    BugReportSearchService,
    AppTypeHelperService,
    TabSelectService,
    CustomerRecordBillingInfoResourceService,
    ProjectService,
    ProjectResourceService,
    ProjectSearchService,
    ProjectMultiselectProvider,
    MASTER_DATA_SERVICE_PROVIDER,
    PROJECT_SERVICE_PROVIDER,
    ProjectRecordGlobalResourceService,
    ProjectRecordService,
    FormRecordSerializer,
    TaskRoleResourceService,
    CustomerRoleResourceService,
    FormStockService,
    FormStockResourceService,
    FormRecordMasterDataFieldLoader,
    FormRecordCustomerFieldLoader
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
