import { DqlQuery, Query } from '../field';

export class Process {

  readonly id: Query.OrderField;
  readonly externalId: Query.OrderField;
  readonly name: Query.OrderField;
  readonly creationTime: Query.OrderField;
  readonly updateTime: Query.OrderField;
  readonly startTime: Query.OrderField;
  readonly finishTime: Query.OrderField;
  readonly deadline: Query.OrderField;

  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this.id = new DqlQuery.OrderField(prefix + 'id');
    this.externalId = new DqlQuery.OrderField(prefix + 'external_id');
    this.name = new DqlQuery.OrderField(prefix + 'name');
    this.creationTime = new DqlQuery.OrderField(prefix + 'creation_time');
    this.updateTime = new DqlQuery.OrderField(prefix + 'update_time');
    this.startTime = new DqlQuery.OrderField(prefix + 'start_time');
    this.finishTime = new DqlQuery.OrderField(prefix + 'finish_time');
    this.deadline = new DqlQuery.OrderField(prefix + 'deadline');
  }

}
