/* eslint-disable */
import { Directive, HostListener } from '@angular/core';
import { SidebarTogglerService } from '../lib/util/sidebar-toggler.service';
/* eslint-enable */

/**
 * Allows the sidebar to be toggled via click.
 */
/* eslint-disable */
@Directive({
  selector: '.sidebar-toggler',
})
/* eslint-enable */
export class SidebarToggleDirective {

  constructor(
    private sidebarTogglerService: SidebarTogglerService
  ) { }

  @HostListener('click', ['$event'])
  toggleOpen($event: any) {
    $event.preventDefault();
    this.sidebarTogglerService.toggle();
  }
}

/* eslint-disable */
@Directive({
  selector: '.mobile-sidebar-toggler',
})
/* eslint-enable */
export class MobileSidebarToggleDirective {

  constructor() {
  }

  // Check if element has class
  private hasClass(target: any, elementClassName: string) {
    return new RegExp('(\\s|^)' + elementClassName + '(\\s|$)').test(target.className);
  }

  @HostListener('click', ['$event'])
  toggleOpen($event: any) {
    $event.preventDefault();
    const bodyElement = document.querySelector('body');
    if (bodyElement) {
      bodyElement.classList.toggle('sidebar-mobile-show');
    }
  }
}

/**
 * Allows the off-canvas sidebar to be closed via click.
 */
/* eslint-disable */
@Directive({
  selector: '.sidebar-close',
})
/* eslint-enable */
export class SidebarOffCanvasCloseDirective {
  constructor() { }

  // Check if element has class
  private hasClass(target: any, elementClassName: string) {
    return new RegExp('(\\s|^)' + elementClassName + '(\\s|$)').test(target.className);
  }

  // Toggle element class
  private toggleClass(elem: any, elementClassName: string) {
    let newClass = ' ' + elem.className.replace( /[\t\r\n]/g, ' ' ) + ' ';
    if (this.hasClass(elem, elementClassName)) {
      while (newClass.indexOf(' ' + elementClassName + ' ') >= 0 ) {
        newClass = newClass.replace( ' ' + elementClassName + ' ' , ' ' );
      }
      elem.className = newClass.replace(/^\s+|\s+$/g, '');
    }
    else {
      elem.className += ' ' + elementClassName;
    }
  }

  @HostListener('click', ['$event'])
  toggleOpen($event: any) {
    $event.preventDefault();

    if (this.hasClass(document.querySelector('body'), 'sidebar-off-canvas')) {
      this.toggleClass(document.querySelector('body'), 'sidebar-opened');
    }
  }
}

export const SIDEBAR_TOGGLE_DIRECTIVES = [SidebarToggleDirective, SidebarOffCanvasCloseDirective, MobileSidebarToggleDirective];
