import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Angular2Multiselects } from '../../../util/multiselect';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FileItem, FileUploader, FileUploaderOptions, ParsedResponseHeaders } from 'ng2-file-upload';
import { ForwardingNgFormRef, LocalFormGroupValidationErrors } from '../../../lib/util/services';
import { OrderDocument, OrderDocumentService } from '../../../lib/order-document/order-document.service';
import { RightService } from '../../../lib/right.service';
import { FileUploaderUtil } from '../../../util/file-uploader-util';
import { UploadErrorLocalizer } from '../../../util/upload-error-localizer';
import { TranslateService } from '@ngx-translate/core';
import { ResourceHelper } from '../../../lib/util/http-services';
import { UiConstants } from '../../../util/core-utils';
import { Strings } from '../../../lib/util/strings';
import { FileUploadComponent, UploadResponse } from '../../../shared/file-upload/flat/file-upload.component';

@Component({
  selector: 'app-order-document-create-dialog',
  templateUrl: './order-document-create-dialog.component.html',
  styleUrls: ['./order-document-create-dialog.component.scss']
})
export class OrderDocumentCreateDialogComponent implements OnInit {
  UiConstants = UiConstants;

  dropdownSettings?: Angular2Multiselects.Settings;

  @Input()
  orderId: number;

  @Output()
  onOrderDocumentCreated: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('f')
  fForm: NgForm;

  @ViewChild('orderDocumentCreateDialog', { static: true }) orderDocumentCreateDialog: ModalDirective;
  orderDocumentCreateDialogVisible: boolean = false;
  orderDocumentCreateModel: OrderDocumentCreateModel = new OrderDocumentCreateModel();

  @ViewChild('fileUploadComponent', {static: false})
  fileUploadComponent: FileUploadComponent;

  formGroup: FormGroup;
  private formGroupValidationErrors: LocalFormGroupValidationErrors;
  fileUploadEmptyError: boolean = false;

  constructor(private orderDocumentService: OrderDocumentService,
              private rightService: RightService,
              private formBuilder: FormBuilder,
              private fileUploaderUtil: FileUploaderUtil,
              private uploadErrorLocalizer: UploadErrorLocalizer,
              private translateService: TranslateService,
              private resourceHelper: ResourceHelper) {
    this.formGroup = this.createFormGroup(formBuilder);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(this.createForwardingHtmlForm(), this.formGroup);
  }

  ngOnInit() {
    this.initDropdownSettings();
  }

  // Currently, we do not validate anything, but it can be useful later to have the controls ready.
  private createFormGroup(fb: FormBuilder): FormGroup {
    return fb.group(
      {
        type: fb.control(
          {value: this.orderDocumentCreateModel.type},
          [
          ]
        ),
        comment: fb.control(
          {value: this.orderDocumentCreateModel.comment},
          [
          ]
        ),
      }
    );
  }

  private initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(false)
      .enableCheckAll(false)
      .remoteSearch(false)
      .build();
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    this.formGroup.updateValueAndValidity();
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  private createForwardingHtmlForm() {
    return new ForwardingNgFormRef({
      formFn: () => {
        return this.fForm;
      }
    });
  }

  openOrderDocumentCreateDialog() {
    this.orderDocumentCreateDialogVisible = true;
    this.orderDocumentCreateDialog.show();
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(this.createForwardingHtmlForm(), this.formGroup);
    this.fileUploadComponent.uploader.clearQueue();
    this.fileUploadComponent.uploader.cancelAll();
    this.fileUploadEmptyError = false;
    this.formGroup.reset();
  }

  closeOrderDocumentCreateDialog() {
    this.orderDocumentCreateDialogVisible = false;
    this.orderDocumentCreateDialog.hide();
    this.formGroup.reset();
  }

  createOrderDocument() {
    let error: boolean = false;
    this.fileUploadEmptyError = false;
    this.formGroup.updateValueAndValidity();
    if (this.formGroup.invalid) {
      this.formGroup.get('type')!.markAsTouched();
      this.formGroup.get('comment')!.markAsTouched();
      error = true;
    }
    if (this.fileUploadComponent.uploader.queue.length === 0) {
      this.fileUploadEmptyError = true;
      error = true;
    }
    if (error) {
      return;
    }
    const url = this.resourceHelper.getBaseUrl() + '/orders-documents/';
    const options: FileUploaderOptions = this.fileUploadComponent.uploader.options;
    options.url = url;
    options.additionalParameter = {
      ['orderId'] : this.orderId
    };
    this.fileUploadComponent.uploader.setOptions(options);
    this.fileUploadComponent.uploader.uploadAll();
  }

  private updateOrderDocumentMeta(response: OrderDocument.UUIDMessage) {
    this.orderDocumentService.update({
      id: response.id,
      version: 1,
      type: Strings.undefinedOrNonEmpty(this.orderDocumentCreateModel.type),
      comment: Strings.undefinedOrNonEmpty(this.orderDocumentCreateModel.comment)
    }).subscribe(() => {
      this.closeOrderDocumentCreateDialog();
      this.onOrderDocumentCreated.emit();
    })
  }

  onUploadResult(response: UploadResponse) {
    this.updateOrderDocumentMeta(JSON.parse(response.response));
  }

}

class OrderDocumentCreateModel {
  type: string = '';
  comment: string = '';
}
