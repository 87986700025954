<div class="card ">

  <div class="card-header">
    {{'STOCK_ITEM_CUSTOMER_RECORD_PRICES'|translate}}
    <div class="card-actions">
      <a class="btn-setting table-header-action-button" (click)="onCreateClicked()"
         title="{{'COMMON_BUTTON_ADD' | translate}}"
         *ngIf="!readonly && rightModel.stockItemCustomerRecordPriceCreate.hasRight()">
        <i class="icomoon icomoon-add"></i>
      </a>
      <a class="btn-setting cursor-pointer" (click)="toggleSearch()"
         title="{{'COMMON_SHOW_SEARCH_HINT' | translate}}">
        <i class="icomoon icomoon-search"></i>
      </a>
    </div> <!-- ./card-actions end -->
  </div> <!-- ./card header end -->
  <div class="card-body border-bottom" *ngIf="showSearch">
    <div class="row form-group">
      <div class="col-md-4">
        <label class="search-label">{{'STOCK_ITEM_CUSTOMER_RECORD_PRICE_CUSTOMER_RECORD' | translate}}</label>
        <angular2-multiselect
          [(ngModel)]="searchModel.customerRecords"
          [data]="customerRecords"
          [settings]="dropdownSettings"
          (onRemoteSearch)="loadCustomerRecords($event.target.value)">
        </angular2-multiselect>
      </div>
      <div class="col-md-4">
        <label class="search-label">{{'STOCK_ITEM_CUSTOMER_RECORD_PRICE_CUSTOM_UNIT_PRICE_FROM' | translate}}</label>
        <input type="text" class="form-control" placeholder="{{'STOCK_ITEM_CUSTOMER_RECORD_PRICE_CUSTOM_UNIT_PRICE_FROM' | translate}}"
               maxlength="{{UiConstants.maximumVarcharLength}}"
               [textMask]="{mask: InputMask.NATURAL_FLOAT, guide: false}"
               (keyup.enter)="onSearchClicked()"
               [(ngModel)]="searchModel.customUnitPriceFrom">
      </div>
      <div class="col-md-4">
        <label class="search-label">{{'STOCK_ITEM_CUSTOMER_RECORD_PRICE_CUSTOM_UNIT_PRICE_TO' | translate}}</label>
        <input type="text" class="form-control" placeholder="{{'STOCK_ITEM_CUSTOMER_RECORD_PRICE_CUSTOM_UNIT_PRICE_TO' | translate}}"
               maxlength="{{UiConstants.maximumVarcharLength}}"
               [textMask]="{mask: InputMask.NATURAL_FLOAT, guide: false}"
               (keyup.enter)="onSearchClicked()"
               [(ngModel)]="searchModel.customUnitPriceTo">
      </div>
    </div>
    <div class="col-md-12 d-flex justify-content-md-end align-items-end px-0">
      <div class="btn-group" role="group">
        <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
          {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
        </button>
        <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
          {{'COMMON_BUTTON_SEARCH' | translate}}
        </button>
      </div>
    </div>
  </div><!-- /.card-body-->

  <div class="card-body">
    <table class="table table-striped table-bordered">
      <thead>
      <tr>
        <th class="table-sorter-header">
          <app-table-field-sorter [orderField]="StockItem.CustomerRecordPriceOrderField.ID"
                                  [orderType]="queryModel.getOrderType(StockItem.CustomerRecordPriceOrderField.ID)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'STOCK_ITEM_CUSTOMER_RECORD_PRICE_CUSTOMER_RECORD' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header">
          <app-table-field-sorter [orderField]="StockItem.CustomerRecordPriceOrderField.CUSTOM_UNIT_PRICE"
                                  [orderType]="queryModel.getOrderType(StockItem.CustomerRecordPriceOrderField.CUSTOM_UNIT_PRICE)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'STOCK_ITEM_CUSTOMER_RECORD_PRICE_CUSTOM_UNIT_PRICE_NET' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header">
          <app-table-field-sorter [orderField]="StockItem.CustomerRecordPriceOrderField.CUSTOM_UNIT_PRICE"
                                  [orderType]="queryModel.getOrderType(StockItem.CustomerRecordPriceOrderField.CUSTOM_UNIT_PRICE)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'STOCK_ITEM_CUSTOMER_RECORD_PRICE_CUSTOM_UNIT_PRICE_GROSS' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header" *ngIf="!readonly">
          <app-table-sorter-no-op [text]="'COMMON_TABLE_HEADER_ACTIONS' | translate"></app-table-sorter-no-op>
        </th>
      </tr>
      <tbody>
      <tr *ngFor="let price of priceList | paginate: {
                                                                itemsPerPage: queryModel.itemsPerPage,
                                                                currentPage: queryModel.currentPage,
                                                                totalItems: queryModel.currentNumberOfItems }">
        <td class="responsive-table-column">{{price.customer_record.name}}</td>
        <td class="responsive-table-column">{{price.custom_unit_price + ' ' + currency.localizedName}}</td>
        <td class="responsive-table-column">{{getGrossPrice(price.custom_unit_price) + ' ' + currency.localizedName}}</td>
        <td class="responsive-table-column w-table-actions" *ngIf="!readonly">
          <a class="icons-table-group">
            <i *ngIf="!readonly && rightModel.stockItemCustomerRecordPriceUpdate.hasRight()"
               class="icomoon icons-table-item icomoon-modify icomoon-modify-table cursor-pointer"
               (click)="onEditClicked(price)"
               title="{{'COMMON_BUTTON_EDIT' | translate}}"></i>
          </a>
          <a class="icons-table-group">
            <i *ngIf="!readonly && rightModel.stockItemCustomerRecordPriceDelete.hasRight()"
               class="icomoon icons-table-item icomoon-trash icomoon-modify-table cursor-pointer"
               (click)="onDeleteClicked(price)"
               title="{{'COMMON_BUTTON_DELETE' | translate}}"></i>
          </a>
        </td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <th class="table-sorter-header">
          <app-table-field-sorter [orderField]="StockItem.CustomerRecordPriceOrderField.ID"
                                  [orderType]="queryModel.getOrderType(StockItem.CustomerRecordPriceOrderField.ID)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'STOCK_ITEM_CUSTOMER_RECORD_PRICE_CUSTOMER_RECORD' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header">
          <app-table-field-sorter [orderField]="StockItem.CustomerRecordPriceOrderField.CUSTOM_UNIT_PRICE"
                                  [orderType]="queryModel.getOrderType(StockItem.CustomerRecordPriceOrderField.CUSTOM_UNIT_PRICE)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'STOCK_ITEM_CUSTOMER_RECORD_PRICE_CUSTOM_UNIT_PRICE_NET' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header">
          <app-table-field-sorter [orderField]="StockItem.CustomerRecordPriceOrderField.CUSTOM_UNIT_PRICE"
                                  [orderType]="queryModel.getOrderType(StockItem.CustomerRecordPriceOrderField.CUSTOM_UNIT_PRICE)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'STOCK_ITEM_CUSTOMER_RECORD_PRICE_CUSTOM_UNIT_PRICE_GROSS' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header" *ngIf="!readonly">
          <app-table-sorter-no-op [text]="'COMMON_TABLE_HEADER_ACTIONS' | translate"></app-table-sorter-no-op>
        </th>
      </tr>
      </tfoot>
    </table>
    <app-table-paging
      [currentNumberOfItems]="queryModel.currentNumberOfItems"
      [totalNumberOfItems]="queryModel.totalNumberOfItems"
      [itemsPerPage]="queryModel.itemsPerPage"
      (pageChange)="pageChanged($event)"
      (itemsPerPageChange)="itemsPerPageChanged($event)">
    </app-table-paging>
  </div>
</div>
