<div class="modal-content">
  <div class="modal-header">
    <p class="modal-title" *ngIf="data.mode === 'SET'"><strong>{{'INVOICE_ADD_TAG' | translate}}</strong></p>
    <p class="modal-title" *ngIf="data.mode === 'CLEAR'"><strong>{{'INVOICE_CLEAR_TAG' | translate}}</strong></p>
    <button type="button" class="close" (click)="closeDialog(false)" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <div class="alert alert-info" *ngIf="data.mode === 'SET'">
        <h6 class="alert-title mb-0">{{'INVOICE_SET_TAG_MESSAGE' | translate }}</h6>
      </div>
      <div class="alert alert-info" *ngIf="data.mode === 'CLEAR'">
        <h6 class="alert-title mb-0">{{'INVOICE_CLEAR_TAG_MESSAGE' | translate }}</h6>
      </div>
    </div>
    <mat-dialog-content style="overflow:visible">
      <form (ngSubmit)="save()" #f="ngForm" id="form"
            [formGroup]="formGroup" novalidate>
        <!-- region InvoiceTag -->
        <div class="row form-group"
             *ngIf="data.mode === 'SET'"
             [ngClass]="{ 'has-danger': hasLocalFieldError('invoiceTag')}">
          <label
            class="col-form-label form-control-label col-md-4 detail-title"
            [class.required-field-label]="true">{{'INVOICE_CREATE_INVOICE_TAG' | translate}}</label>

          <div class="col-8">
            <div class="mw-100">
              <angular2-multiselect [data]="invoiceTags" [settings]="searchableDropdownSettings"
                                    class="form-control"
                                    [(ngModel)]="model._invoiceTag"
                                    [ngClass]="{ 'form-control-danger': hasLocalFieldError('invoiceTag') }"
                                    (onRemoteSearch)="onInvoiceTagSearch($event.target.value)"
                                    name="invoiceTag"
                                    id="invoiceTag"
                                    formControlName="invoiceTag">
              </angular2-multiselect>
              <div class="form-control-feedback" *ngIf="hasLocalFieldError('invoiceTag')">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
          </div>
        </div>
        <!-- endregion InvoiceTag -->

      </form>
    </mat-dialog-content>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="closeDialog(false)">
      {{'COMMON_BUTTON_CANCEL' | translate}}
    </button>
    <button type="submit" class="btn btn-primary" form="form">
      {{'COMMON_BUTTON_SAVE' | translate}}
    </button>
  </div>
</div>
