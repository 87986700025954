import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Task, TaskService } from '../../../lib/task/task.service';

@Component({
  selector: 'app-helpdesk-bug-report-type-selector-dialog',
  templateUrl: './type-selector-dialog.component.html',
  styleUrls: ['./type-selector-dialog.component.scss']
})
export class HelpdeskBugReportTypeSelectorDialogComponent implements OnInit {

  tasks: Task.Task[] = [];
  selectedTaskId: number;

  constructor(
    public dialogRef: MatDialogRef<HelpdeskBugReportTypeSelectorDialogComponent, number>,
    private taskService: TaskService
  ) {
  }

  ngOnInit() {
    this.loadTasks();
  }

  private loadTasks(): void {
    this.taskService.query({
      helpdeskEnabled: true,
      disabled: false
    }).subscribe(result => this.tasks = result.items.toArray());
  }

  onSelection(taskId: number) {
    this.selectedTaskId = taskId;
  }

  closeDialog(cancel?: boolean) {
    this.dialogRef.close(cancel ? undefined : this.selectedTaskId);
  }


}
