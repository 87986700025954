/* eslint-disable */
import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { DqlCriteriaModel, DqlExpressionBuilder, DqlFieldModel } from '../dql-search-container/dql-search-container.model';
import { DqlCreateDialogComponent } from '../dql-create-dialog/dql-create-dialog.component';
import { DqlSearchFieldComponent } from '../field/dql-search-field.component';
import { DqlStoredQuerySaveDialogComponent } from '../dql-stored-query-save-dialog/dql-stored-query-save-dialog.component';
import { DqlStoredQuery } from '../../../lib/dql/dql-stored-query.service';
import { DqlStoredQueryArgs } from '../dql-search-container/dql-stored-query.args';

/* eslint-enable */

@Component({
  selector: 'app-dql-dialog-container',
  templateUrl: './dql-dialog-container.component.html',
  styleUrls: ['./dql-dialog-container.component.scss']
})
export class DqlDialogContainerComponent implements OnInit {

  onCreateExpression: EventEmitter<DqlExpressionBuilder> = new EventEmitter<DqlExpressionBuilder>();
  onQuerySavedOrDeleted: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild('newSearchDialog', { static: true }) newSearchDialog: DqlCreateDialogComponent;
  @ViewChild('storedQuerySaveDialog', { static: true }) storedQuerySaveDialog: DqlStoredQuerySaveDialogComponent;

  constructor() {
  }

  ngOnInit() {
  }

  openSearchDialog() {
    this.newSearchDialog.openNewSearchFieldDialog();
  }

  openSaveDialog(dqlStoredQueryArgs: DqlStoredQueryArgs, query: string, storedQuery?: DqlStoredQuery.DqlStoredQuery) {
    this.storedQuerySaveDialog.openDialog(dqlStoredQueryArgs, query, storedQuery);
  }

  setFields(fields: DqlFieldModel[]) {
    this.newSearchDialog.setFields(fields);
  }
}
