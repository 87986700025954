<div bsModal [config]="UiConstants.modalConfig" #companyLocationCreateDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true"
     (onHide)="onModalHidden()"
     (onShown)="onModalShown()">
  <div class="modal-dialog modal-lg" role="document" *ngIf="companyLocationCreateDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title"><strong>{{'COMPANY_LOCATION_CREATE' | translate}}</strong></p>
        <button type="button" class="close" (click)="closeCompanyLocationCreateDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form-horizontal bordered-row" (ngSubmit)="f.form.valid && createCompanyLocation()" #f="ngForm" novalidate>

          <!--region CompanyName-->
          <div class="form-group row" [ngClass]="{ 'has-danger': hasLocalFieldError(name) }">
            <label class="col-form-label form-control-label col-md-4 detail-title required-field-label">
              {{'COMPANY_LOCATION_NAME' | translate}}
            </label>
            <div class="col-md-8">
              <input type="text" class="form-control"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     placeholder="{{'COMPANY_LOCATION_NAME' | translate}}"
                     [(ngModel)]="editModel.name"
                     [ngClass]="{ 'form-control-danger': hasLocalFieldError(name) }"
                     name="name"
                     id="name"
                     #name="ngModel"
                     required>
              <div class="form-control-feedback" *ngIf="hasLocalFieldError(name)">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
          </div>
          <!--endregion CompanyName-->

          <!--region ExternalId-->
          <div class="form-group row"
               [ngClass]="{ 'has-danger': hasLocalFieldError(externalId) || fieldErrors.external_id}">
            <label class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_EXTERNAL_ID' |
              translate}}</label>
            <div class="col-md-8">
              <input type="text" class="form-control"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     placeholder="{{'COMMON_EXTERNAL_ID' | translate}}"
                     [(ngModel)]="editModel.externalId"
                     (ngModelChange)="removeFieldError(fieldErrors.external_id)"
                     [ngClass]="{ 'form-control-danger': hasLocalFieldError(externalId) || fieldErrors.external_id}"
                     name="externalId"
                     id="externalId"
                     #externalId="ngModel">
              <div class="form-control-feedback" *ngIf="fieldErrors.external_id">
                {{fieldErrors.external_id.text}}
              </div>
              <div class="form-control-feedback" *ngIf="hasLocalFieldError(externalId)">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
          </div>
          <!--endregion ExternalId-->

          <!--region GateCrossingRequired-->
          <div class="form-group row">
            <label class="col-form-label form-control-label col-md-4 detail-title">{{'COMPANY_LOCATION_GATE_CROSSING_REQUIRED' |
                translate}}</label>
            <div class="col-md-8">
              <label class="switch switch-text switch-info mb-0">
                <input type="checkbox" class="switch-input"
                       [ngModelOptions]="{standalone: true}"
                       [(ngModel)]="editModel.gateCrossingRequired">
                <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                <span class="switch-handle"></span>
              </label>
            </div>
          </div>
          <!--endregion GateCrossingRequired-->

          <!-- region Company -->
          <div class="form-group row">
            <label class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_COMPANY'|translate}}</label>
            <label class="col-form-label form-control-label col-md-4 detail-title">{{getSelectedCompanyName()}}</label>
          </div>
          <!-- endregion Company -->

          <!-- region Postal address -->
          <div *ngIf="editModel.postalAddress"
               class="form-group row">
            <label
              class="col-form-label form-control-label col-md-4 detail-title required-field-label">
              {{'COMMON_LOCATION_POSTAL_ADDRESS'|translate}}
            </label>
            <div class="col-md-8">
              <app-postal-address-complex
                [form]="f"
                [model]="editModel.postalAddress">
              </app-postal-address-complex>
            </div>
          </div>
          <!-- endregion Postal address -->

          <div class="col-md-12 d-flex justify-content-end p-0 pl-1">
            <div>
              <button type="button" class="btn btn-outline-primary" (click)="closeCompanyLocationCreateDialog()">
                {{'COMMON_BUTTON_CANCEL' | translate}}
              </button>
              <button type="submit" class="btn btn-primary ml-1">{{'COMMON_BUTTON_SAVE' |
                translate}}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
