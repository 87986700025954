<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]=breadcrumbParents
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div class="breadcrumb-menu d-flex">
    <div
      *ngIf="rightModel.companyLocationCreate.hasRight()"
      class="breadcrumb-button-icon-container negative-margin-right cursor-pointer"
      uiSref="Admin.CompanyLocationCreate"
    >
      <a class="btn-setting" title="{{'COMPANY_LOCATION_CREATE' | translate}}">
        <i class="icomoon icomoon-add"></i>
        <span class="breadcrumb-button-text-visible">{{'COMPANY_LOCATION_CREATE' | translate}}</span>
      </a>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>

<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="card">

    <div class="card-header">
      {{'MENU_NAVBAR_MENU_COMPANY_LOCATION' | translate}}

      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()" title="{{'COMMON_BUTTON_SEARCH' | translate}}"><i
          class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>

    <div class="card-body border-bottom" *ngIf="showSearch">
      <div class="row form-group">
        <div class="col-md-3">
          <label class="search-label">{{'COMMON_TABLE_HEADER_ID' | translate}}</label>
          <input type="text" class="form-control"
                 maxlength="{{UiConstants.maxInputLongLength}}"
                 (keyup.enter)="onSearchClicked()"
                 placeholder="{{'COMMON_TABLE_HEADER_ID' | translate}}"
                 [textMask]="{mask: InputMask.NATURAL_INTEGER, guide: false}"
                 [(ngModel)]="searchModel.id">
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'COMMON_EXTERNAL_ID' | translate}}</label>
          <input type="text" class="form-control"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 placeholder="{{'COMMON_EXTERNAL_ID' | translate}}"
                 [(ngModel)]="searchModel.externalId">
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'COMPANY_LOCATION_COMPANY_LOCATION_NAME' | translate}}</label>
          <input type="text" class="form-control"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 placeholder="{{'COMPANY_LOCATION_COMPANY_LOCATION_NAME' | translate}}"
                 [(ngModel)]="searchModel.name">
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'COMMON_COMPANY' | translate}}</label>
          <angular2-multiselect [data]="companyForSearch" [settings]="dropdownSettingsForCompany"
                                class="form-control"
                                [(ngModel)]="searchModel.companyIds"
                                #company="ngModel" name="company" id="company">
          </angular2-multiselect>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-3">
          <label class="search-label"> {{ 'COMMON_STATE' | translate }} </label>
          <select
            class="form-control"
            [(ngModel)]="searchModel.disabled" [compareWith]="SelectUtils.compareObjects">
            <option *ngFor="let item of disabledItems" [ngValue]="item">{{item.text}}</option>
          </select>
        </div>
      </div>
      <div class="col-md-12 d-flex justify-content-md-end align-items-end px-0">
        <div class="btn-group" role="group">
          <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
            {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
          </button>
          <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
            {{'COMMON_BUTTON_SEARCH' | translate}}
          </button>
        </div>
      </div>
    </div><!-- /.card-body-->
    <div class="card-body">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="CompanyLocation.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(CompanyLocation.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-field-sorter [orderField]="CompanyLocation.OrderField.EXTERNAL_ID"
                                    [orderType]="queryModel.getOrderType(CompanyLocation.OrderField.EXTERNAL_ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_EXTERNAL_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-field-sorter [orderField]="CompanyLocation.OrderField.NAME"
                                    [orderType]="queryModel.getOrderType(CompanyLocation.OrderField.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMPANY_LOCATION_NAME_ADDRESS' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op [text]="'COMMON_COMPANY' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-actions">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let companyLocation of companyLocationList | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }" [disabledTableRow]="companyLocation.disabled">
          <td class="responsive-table-column test_id">{{companyLocation.id}}</td>
          <td class="responsive-table-column hidden-xs-down">{{companyLocation.externalId}}</td>
          <td class="responsive-table-column">
            <label class="test_name">{{companyLocation.name}}</label>
            <br>
            <span class="company-location-address">{{Address.PostalAddressMapper.toString(companyLocation.companyLocationAddress, config.postalAddressFormat)}}</span>
          </td>
          <td class="responsive-table-column hidden-sm-down test_company">{{companyLocation.companyName}}</td>
          <td class="responsive-table-column">
            <a class="icons-table-group cursor-pointer"
               uiSref="Admin.CompanyLocationDetail"
               [uiParams]="{ id: companyLocation.id }">
              <i class="icomoon icons-table-item icomoon-detail icomoon-detail-table"
                 title="{{'COMMON_BUTTON_DETAILS' | translate}}"></i>
            </a>
            <a class="icons-table-group cursor-pointer"
               uiSref="Admin.CompanyLocationEdit"
               [uiParams]="{ id: companyLocation.id }"
               *ngIf="rightModel.companyLocationUpdate.hasRight()">
              <i class="icomoon icons-table-item icomoon-modify icomoon-modify-table"
                 title="{{'COMMON_BUTTON_EDIT' | translate}}"></i>
            </a>
            <!--TODO Add disable rights-->
            <a class="icons-table-group" href="#" onclick="return false"
               (click)="setDisabled($event, companyLocation, true)"
               *ngIf="!companyLocation.disabled && rightModel.companyLocationDisable.hasRight()">
              <i class="icomoon icons-table-item icomoon-disable icomoon-disable-table"
                 title="{{'COMMON_BUTTON_DISABLE' | translate}}"></i>
            </a>
            <a class="icons-table-group" href="#" onclick="return false"
               (click)="setDisabled($event, companyLocation, false)"
               *ngIf="companyLocation.disabled  && rightModel.companyLocationDisable.hasRight()">
              <i class="icomoon icons-table-item icomoon-enable icomoon-enable-table"
                 title="{{'COMMON_BUTTON_ENABLE' | translate}}"></i>
            </a>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="CompanyLocation.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(CompanyLocation.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-field-sorter [orderField]="CompanyLocation.OrderField.EXTERNAL_ID"
                                    [orderType]="queryModel.getOrderType(CompanyLocation.OrderField.EXTERNAL_ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_EXTERNAL_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-field-sorter [orderField]="CompanyLocation.OrderField.NAME"
                                    [orderType]="queryModel.getOrderType(CompanyLocation.OrderField.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMPANY_LOCATION_NAME_ADDRESS' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op [text]="'COMMON_COMPANY' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-actions">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>
  </div>
</div>
