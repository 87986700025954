import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TaskResource } from '../../../lib/task/task-resource.service';
import { MultiselectOptionItem, UiConstants } from '../../../util/core-utils';
import { TaskService } from '../../../lib/task/task.service';
import { Subject } from 'rxjs';
import { FieldValidationError, ForwardingNgFormRef, LocalFormGroupValidationErrors } from '../../../lib/util/services';
import { AppValidators } from '../../../util/app-validators';
import { Angular2Multiselects } from '../../../util/multiselect';
import { ErrorMessageService } from '../../../lib/error-message-parser.service';
import { TranslateUtils } from '../../../util/translate';

@Component({
  selector: 'app-task-import-dialog',
  templateUrl: './task-import-dialog.component.html',
  styleUrls: ['./task-import-dialog.component.scss']
})
export class TaskImportDialogComponent implements OnInit {

  UiConstants = UiConstants;
  @ViewChild('f') form: NgForm;
  formGroup: FormGroup;
  private formGroupValidationErrors: LocalFormGroupValidationErrors;

  model: TaskImportModel = new TaskImportModel();

  importInProgress: boolean = false;
  importDnDVisible: boolean = true;
  offlineDropdownSettings: Angular2Multiselects.Settings;
  fieldErrors?: string;
  uploadGlobalErrors?: string;
  fileName?: string;
  importResult?: string = undefined;

  constructor(
    private translateService: TranslateService,
    private taskService: TaskService,
    private formBuilder: FormBuilder,
    private errorMessageService: ErrorMessageService,
    public dialogRef: MatDialogRef<TaskImportDialogComponent, TaskImportDialogResult>,
    @Inject(MAT_DIALOG_DATA) public data: TaskImportDialogData
  ) {

    this.formGroup = this.createFormGroup(formBuilder);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(
      this.createForwardingHtmlForm(),
      this.formGroup
    );

  }

  public static openDialog(
    dialog: MatDialog,
    data: TaskImportDialogData,
    resultCallback: (result?: TaskImportDialogResult) => void) {
    const dialogRef = dialog.open(TaskImportDialogComponent, {
      closeOnNavigation: true,
      data: data,
      width: '60%',
      height: 'auto',
      panelClass: 'custom-dialog-container'
    });
    dialogRef.afterClosed().subscribe((result?: TaskImportDialogResult) => {
      resultCallback(result);
    });
  }

  ngOnInit(): void {
    this.offlineDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .remoteSearch(false)
      .searchBy(['itemName'])
      .translate(true)
      .build();
  }

  closeDialog(taskId?: number) {
    if (taskId) {
      this.dialogRef.close({taskId: taskId});
    }
    else {
      this.dialogRef.close();
    }
  }

  onImportableFileDropped(files: File[]) {
    this.importResult = undefined;
    const file = files[0];
    this.fileName = file.name;
    const fr = new FileReader();
    const subject: Subject<any> = new Subject<any>();
    fr.onloadend = (function (f) {
      return function (e) {
        try {
          const res = JSON.parse(this.result);
          subject.next(res);
        }
        catch (e) {
          subject.error('');
        }
      };
    })(file);
    fr.readAsText(file);
    subject.asObservable().subscribe((doc: TaskResource.TaskImportDocument) => {
      if (doc) {
        this.model.document = doc;
        this.importResult = undefined;
        this.importDnDVisible = false;
      }
      else {
        this.importResult = 'FORMAT_ERROR';
        this.importDnDVisible = true;
      }
      if (!this.data.taskId && !this.model.document?.base_data) {
        this.importResult = 'BASE_DATA_MISSING';
        this.importDnDVisible = true;
      }
    }, error => {
      this.importResult = 'FORMAT_ERROR';
      this.importDnDVisible = true;
    });
  }

  onFileRemoved() {
    this.model.document = undefined;
    this.fileName = undefined;
    this.importResult = undefined;
    this.fieldErrors = undefined;
    this.uploadGlobalErrors = undefined;
    this.importDnDVisible = true;
  }

  importTask() {
    if (!this.model.document) {
      return;
    }
    if (this.importResult !== undefined && this.importResult !== 'ERROR') {
      return;
    }
    if (this.hasLocalFieldError()) {
      return;
    }
    this.taskService.importJson({
      taskId: this.data.taskId,
      document: this.model.documentRequest,
      settings: {
        triggerIgnoreDisabledItems: this.model.triggerIgnoreDisabledItems,
        triggerRemoveExistingTriggers: this.model.triggerRemoveExistingTriggers,
        formIgnoreDisabledItems: this.model.formIgnoreDisabledItems
      }
    }).subscribe(result => {
      this.closeDialog(result.id)
    }, error => {
      this.importResult = 'ERROR';
      const localizedError = this.errorMessageService.parseError(error);
      this.uploadGlobalErrors = localizedError!.globalErrors.join('<br/>');
      this.fieldErrors = '';
      if (localizedError?.hasFieldErrors) {
        const f = localizedError!.extractFieldErrors();
        for (let key of Object.keys(f)) {
          this.fieldErrors += `${key}: ${f[key].text}<br/>`;
        }

      }
    });
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  private createForwardingHtmlForm() {
    return new ForwardingNgFormRef({
      formFn: () => {
        return this.form;
      }
    });
  }

  private createFormGroup(fb: FormBuilder): FormGroup {
    return fb.group({
      formIgnoreDisabledItems: fb.control(
        {value: this.model.formIgnoreDisabledItems},
        []
      ),
      triggerIgnoreDisabledItems: fb.control(
        {value: this.model.triggerIgnoreDisabledItems},
        []
      ),
      triggerRemoveExistingTriggers: fb.control(
        {value: this.model.triggerRemoveExistingTriggers},
        []
      ),
      overrideName: fb.control(
        {value: this.model.overrideName},
        []
      ),
      overrideExternalId: fb.control(
        {value: this.model.overrideExternalId},
        []
      ),
      overriddenName: fb.control(
        {value: this.model.overriddenName},
        [AppValidators.tempValidator({
          disabled: () => !this.model.overrideName,
          validator: Validators.required
        })]
      ),
      overriddenExternalId: fb.control(
        {value: this.model.overriddenExternalId},
        [AppValidators.tempValidator({
          disabled: () => !this.model.overrideExternalId,
          validator: Validators.required
        })]
      ),
      importableDataTypes: fb.control({
          value: this.model.importableDataTypes
        }, [Validators.required]
      )

    });
  }

}

export interface TaskImportDialogResult {
  taskId: number;
}

export interface TaskImportDialogData {
  taskId?: number;
}

export enum TaskImportDataType {
  BASE_DATA = 'BASE_DATA',
  FORM = 'FORM',
  TRIGGERS = 'TRIGGERS',
  ROLES = 'ROLES'
}

class TaskImportModel {
  formIgnoreDisabledItems: boolean = false;
  triggerIgnoreDisabledItems: boolean = false;
  triggerRemoveExistingTriggers: boolean = false;
  overrideName: boolean = false;
  overrideExternalId: boolean = false;
  overriddenName: string = '';
  overriddenExternalId: string = '';
  private _document?: TaskResource.TaskImportDocument;

  availableDataTypes: MultiselectOptionItem<TaskImportDataType>[] = [];
  importableDataTypes: MultiselectOptionItem<TaskImportDataType>[] = [];

  get document(): TaskResource.TaskImportDocument | undefined {
    return this._document;
  }

  set document(value: TaskResource.TaskImportDocument | undefined) {
    this.reset();
    this._document = value;
    if (this._document) {
      this.calcAvailableDataTypes();
      this.importableDataTypes = [];
      this.availableDataTypes.forEach(t => {
        this.importableDataTypes.push(t);
      })
      if (this.isBaseDataImportable()) {
        this.overriddenName = this._document.base_data!.name;
        this.overriddenExternalId = this._document.base_data!.external_id;
      }
    }
  }

  get documentRequest(): TaskResource.TaskImportDocument {
    let document: TaskResource.TaskImportDocument = JSON.parse(JSON.stringify(this.document));
    if (this.isBaseDataImportable() && this.overrideName || this.overrideExternalId) {
      if (this.overrideName) {
        document.base_data!.name = this.overriddenName;
      }
      if (this.overrideExternalId) {
        document.base_data!.external_id = this.overriddenExternalId;
      }
    }
    if (!this.isBaseDataImportable()) {
      document.base_data = undefined;
    }
    if (!this.isFormImportable()) {
      document.form = undefined;
    }
    if (!this.isTriggerImportable()) {
      document.triggers = undefined;
    }
    if (!this.isRoleImportable()) {
      document.roles = undefined;
    }
    return document;
  }

  calcAvailableDataTypes() {
    if (this._document?.base_data) {
      this.availableDataTypes.push({id: TaskImportDataType.BASE_DATA, itemName: 'TASK_IMPORT_TYPE_BASE_DATA'});
    }
    if (this._document?.form) {
      this.availableDataTypes.push({id: TaskImportDataType.FORM, itemName: 'TASK_IMPORT_TYPE_FORM'});
    }
    if (this._document?.triggers) {
      this.availableDataTypes.push({id: TaskImportDataType.TRIGGERS, itemName: 'TASK_IMPORT_TYPE_TRIGGERS'});
    }
    if (this._document?.roles) {
      this.availableDataTypes.push({id: TaskImportDataType.ROLES, itemName: 'TASK_IMPORT_TYPE_ROLES'});
    }
  }

  isFormImportable(): boolean {
    return this.importableDataTypes.find(t => t.id === TaskImportDataType.FORM) !== undefined;
  }

  isTriggerImportable(): boolean {
    return this.importableDataTypes.find(t => t.id === TaskImportDataType.TRIGGERS) !== undefined;
  }

  isRoleImportable(): boolean {
    return this.importableDataTypes.find(t => t.id === TaskImportDataType.ROLES) !== undefined;
  }

  isBaseDataImportable(): boolean {
    return this.importableDataTypes.find(t => t.id === TaskImportDataType.BASE_DATA) !== undefined;
  }

  reset() {
    this.formIgnoreDisabledItems = false;
    this.triggerIgnoreDisabledItems = false;
    this.triggerRemoveExistingTriggers = false;
    this.overrideName = false;
    this.overrideExternalId = false;
    this.overriddenName = '';
    this.overriddenExternalId = '';
    this._document = undefined;
    this.availableDataTypes = [];
    this.importableDataTypes = [];
  }
}
