<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]=breadcrumbParents
      [self]="breadcrumbSelf">
    </app-breadcrumb>
    <app-breadcrumb-menu>
      <app-breadcrumb-button
        *ngIf="rights && rights.projectRecordUpdate.hasRight() && componentState.isDetailView()"
        [iconClass]="'icomoon-modify'"
        [titleStringKey]="'COMMON_BUTTON_EDIT'"
        uiSref="Admin.ProjectRecordEdit"
        [uiParams]="{ projectId: projectId, id: componentState.id }">
      </app-breadcrumb-button>
    </app-breadcrumb-menu>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>

<div class="container-custom-padding-outer animated fadeIn">

  <responsive-tabset class="fullscreen_tab">
    <responsive-tab heading="{{'PROJECT_RECORD_DETAIL'|translate}}">
      <form class="form-horizontal bordered-row" [formGroup]="formGroup" (ngSubmit)="submit()" #f=ngForm
            novalidate>

        <div class="col-xs-12 col-sm-12 col-md-6 card-custom-padding-complete">
          <div class="card">
            <div class="card-header">
              {{ getHeadingDictionaryKey() | translate}}
            </div>
            <div class="card-body">

              <!--region Name-->
              <div class="form-group row"
                   [ngClass]="{ 'has-danger': (hasLocalFieldError('name') || hasFieldError(ProjectRecord.ValidatedField.NAME)) }">
                <label class="col-form-label form-control-label col-md-4 detail-title"
                       [class.required-field-label]="componentState.isEditable()">
                  {{'COMMON_NAME' | translate}}
                </label>
                <div class="col-md-8">
                  <input *ngIf="componentState.isEditable()"
                         type="text" class="form-control"
                         maxlength="{{UiConstants.maximumVarcharLength}}"
                         placeholder="{{'COMMON_NAME' | translate}}"
                         [(ngModel)]="model.name"
                         (ngModelChange)="removeFieldError(ProjectRecord.ValidatedField.NAME)"
                         [ngClass]="{ 'form-control-danger': hasLocalFieldError('name') || hasFieldError(ProjectRecord.ValidatedField.NAME) }"
                         formControlName="name">
                  <div class="form-control-feedback"
                       *ngIf="hasLocalFieldError('name')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                  <div class="form-control-feedback"
                       *ngIf="hasFieldError(ProjectRecord.ValidatedField.NAME)">{{getFieldErrorText(ProjectRecord.ValidatedField.NAME)}}</div>
                  <!--Appears in detailView-->
                  <label
                    *ngIf="componentState.isReadonly()" class="form-control detail-description"
                    readonly>{{model.name}}
                  </label>
                </div>
              </div>
              <!--endregion Name-->

              <!--region ExternalId-->
              <div class="form-group row"
                   [ngClass]="{ 'has-danger': (hasLocalFieldError('externalId') || hasFieldError(ProjectRecord.ValidatedField.EXTERNAL_ID)) }">
                <label class="col-form-label form-control-label col-md-4 detail-title"
                       [class.required-field-label]="componentState.isEditView()">
                  {{'COMMON_EXTERNAL_ID' | translate}}
                </label>
                <div class="col-md-8">
                  <input *ngIf="componentState.isEditable()"
                         type="text" class="form-control"
                         maxlength="{{UiConstants.maximumVarcharLength}}"
                         placeholder="{{'COMMON_EXTERNAL_ID' | translate}}"
                         [(ngModel)]="model.externalId"
                         (ngModelChange)="removeFieldError(ProjectRecord.ValidatedField.EXTERNAL_ID)"
                         [ngClass]="{ 'form-control-danger': hasLocalFieldError('externalId') || hasFieldError(ProjectRecord.ValidatedField.EXTERNAL_ID) }"
                         formControlName="externalId">
                  <div class="form-control-feedback"
                       *ngIf="hasLocalFieldError('externalId')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                  <div class="form-control-feedback"
                       *ngIf="hasFieldError(ProjectRecord.ValidatedField.EXTERNAL_ID)">{{getFieldErrorText(ProjectRecord.ValidatedField.EXTERNAL_ID)}}</div>
                  <!--Appears in detailView-->
                  <label
                    *ngIf="componentState.isReadonly()" class="form-control detail-description"
                    readonly>{{model.externalId}}
                  </label>
                </div>
              </div>
              <!--endregion ExternalId-->

              <!--region StartDate-->
              <div class="form-group row" *ngIf="componentState.isEditable() || model.startDate">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'PROJECT_START_DATE' | translate}}</label>
                <div class="col-md-8">
                  <div class="input-group" *ngIf="componentState.isEditable()">
                    <input class="form-control" placeholder="{{'PROJECT_START_DATE' | translate}}"
                           [(ngModel)]="model.startDate" ngbDatepicker
                           formControlName="startDate"
                           #startDate="ngbDatepicker">
                    <div class="input-group-addon-gray" (click)="startDate.toggle()">
                      <i class="icomoon icomoon-calendar"></i>
                    </div>
                  </div>
                  <div class="form-control-feedback" *ngIf="hasLocalFieldError('startDate')">
                    {{'COMMON_VALIDATION_MESSAGE_INVALID_LOCAL_DATE' | translate}}
                  </div>
                  <!--Appears in detailView-->
                  <label
                    *ngIf="componentState.isReadonly()" class="form-control detail-description"
                    readonly>{{getLocalDateString(model.startDate) | date:'mediumDate'}}
                  </label>
                </div>
              </div>
              <!--endregion StartDate-->

              <!--region EndDate-->
              <div class="form-group row" *ngIf="componentState.isEditable() || model.endDate">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'PROJECT_END_DATE' | translate}}</label>
                <div class="col-md-8">
                  <div class="input-group" *ngIf="componentState.isEditable()">
                    <input class="form-control" placeholder="{{'PROJECT_END_DATE' | translate}}"
                           [(ngModel)]="model.endDate" ngbDatepicker
                           formControlName="endDate"
                           #endDate="ngbDatepicker">
                    <div class="input-group-addon-gray" (click)="endDate.toggle()">
                      <i class="icomoon icomoon-calendar"></i>
                    </div>
                  </div>
                  <div class="form-control-feedback" *ngIf="hasLocalFieldError('endDate')">
                    {{'COMMON_VALIDATION_MESSAGE_INVALID_LOCAL_DATE' | translate}}
                  </div>
                  <!--Appears in detailView-->
                  <label
                    *ngIf="componentState.isReadonly()" class="form-control detail-description"
                    readonly>{{getLocalDateString(model.startDate) | date:'mediumDate'}}
                  </label>
                </div>
              </div>
              <!--endregion EndDate-->

              <!--region Amount-->
              <div class="form-group row" *ngIf="false && (componentState.isEditable() || model.amount.length > 0)">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'PROJECT_BUDGET' | translate}}</label>
                <div class="col-md-8">
                  <input *ngIf="componentState.isEditable()"
                         type="text" class="form-control"
                         maxlength="{{UiConstants.maximumVarcharLength}}"
                         placeholder="{{'PROJECT_BUDGET' | translate}}"
                         [textMask]="{mask: InputMask.NATURAL_FLOAT_WITH_THOUSAND_SEPARATOR, guide: false}"
                         [(ngModel)]="model.amount"
                         [ngModelOptions]="{standalone: true}">
                  <!--Appears in detailView-->
                  <label
                    *ngIf="componentState.isReadonly()" class="form-control detail-description"
                    readonly>{{model.amount}}
                  </label>
                </div>
              </div>
              <!--endregion Amount-->

              <!--region BillingType-->
              <div class="form-group row" *ngIf="false && (componentState.isEditable() || model.billingType.length > 0)">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_TYPE' |
                  translate}}</label>
                <div class="col-md-8">
                  <angular2-multiselect
                    [(ngModel)]="model.billingType"
                    [ngModelOptions]="{standalone: true}"
                    [data]="billingTypes"
                    [readonly]="componentState.isReadonly()"
                    [settings]="billingTypeDropdownSettings">
                  </angular2-multiselect>
                </div>
              </div>
              <!--endregion BillingType-->

              <!--region BillingStartDate-->
              <div class="form-group row" *ngIf="false && (componentState.isEditable() || model.billingStartDate)">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'PROJECT_BILLING_START_DATE' | translate}}</label>
                <div class="col-md-8">
                  <div class="input-group" *ngIf="componentState.isEditable()">
                    <input class="form-control" placeholder="{{'PROJECT_BILLING_START_DATE' | translate}}"
                           [(ngModel)]="model.billingStartDate" ngbDatepicker
                           formControlName="billingStartDate"
                           #billingStartDate="ngbDatepicker">
                    <div class="input-group-addon-gray" (click)="billingStartDate.toggle()">
                      <i class="icomoon icomoon-calendar"></i>
                    </div>
                  </div>
                  <div class="form-control-feedback" *ngIf="hasLocalFieldError('billingStartDate')">
                    {{'COMMON_VALIDATION_MESSAGE_INVALID_LOCAL_DATE' | translate}}
                  </div>
                  <!--Appears in detailView-->
                  <label
                    *ngIf="componentState.isReadonly()" class="form-control detail-description"
                    readonly>{{getLocalDateString(model.startDate) | date:'mediumDate'}}
                  </label>
                </div>
              </div>
              <!--endregion BillingStartDate-->

              <!--region BillingEndDate-->
              <div class="form-group row" *ngIf="false && (componentState.isEditable() || model.billingEndDate)">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'PROJECT_BILLING_END_DATE' | translate}}</label>
                <div class="col-md-8">
                  <div class="input-group" *ngIf="componentState.isEditable()">
                    <input class="form-control" placeholder="{{'PROJECT_BILLING_END_DATE' | translate}}"
                           [(ngModel)]="model.billingEndDate" ngbDatepicker
                           formControlName="billingEndDate"
                           #billingEndDate="ngbDatepicker">
                    <div class="input-group-addon-gray" (click)="billingEndDate.toggle()">
                      <i class="icomoon icomoon-calendar"></i>
                    </div>
                  </div>
                  <div class="form-control-feedback" *ngIf="hasLocalFieldError('billingEndDate')">
                    {{'COMMON_VALIDATION_MESSAGE_INVALID_LOCAL_DATE' | translate}}
                  </div>
                  <!--Appears in detailView-->
                  <label
                    *ngIf="componentState.isReadonly()" class="form-control detail-description"
                    readonly>{{getLocalDateString(model.startDate) | date:'mediumDate'}}
                  </label>
                </div>
              </div>
              <!--endregion BillingEndDate-->

              <!--region BillingPeriodInMonths-->
              <div class="form-group row" *ngIf="false && (componentState.isEditable() || model.billingPeriodInMonths.length > 0)">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'PROJECT_BILLING_PERIOD_IN_MONTHS' | translate}}</label>
                <div class="col-md-8">
                  <input *ngIf="componentState.isEditable()"
                         type="text" class="form-control"
                         maxlength="{{UiConstants.maximumVarcharLength}}"
                         placeholder="{{'PROJECT_BILLING_PERIOD_IN_MONTHS' | translate}}"
                         [textMask]="{mask: InputMask.NATURAL_INTEGER, guide: false}"
                         [(ngModel)]="model.billingPeriodInMonths"
                         [ngModelOptions]="{standalone: true}">
                  <!--Appears in detailView-->
                  <label
                    *ngIf="componentState.isReadonly()" class="form-control detail-description"
                    readonly>{{model.billingPeriodInMonths}}
                  </label>
                </div>
              </div>
              <!--endregion BillingPeriodInMonths-->

              <!--region AssigneeUser-->
              <div class="form-group row" *ngIf="componentState.isEditable() || model.assigneeUser.length > 0"
                   [ngClass]="{ 'has-danger': hasLocalFieldError('assigneeUser') }">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_USER' |
                  translate}}</label>
                <div class="col-md-8">
                  <angular2-multiselect
                    formControlName="assigneeUser"
                    [(ngModel)]="model.assigneeUser"
                    [data]="users"
                    (onRemoteSearch)="loadUsers($event.target.value)"
                    [readonly]="componentState.isReadonly()"
                    [settings]="userDropdownSettings">
                  </angular2-multiselect>
                  <div class="form-control-feedback"
                       *ngIf="hasLocalFieldError('assigneeUser')">{{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate }}</div>
                </div>
              </div>
              <!--endregion AssigneeUser-->

              <!-- region PostalAddress -->
              <app-postal-address-field
                *ngIf="!componentState.isReadonly() || model.postalAddress.type !== PostalAddressType.NONE"
                [model]="model.postalAddress"
                [label]="'COMMON_LOCATION_POSTAL_ADDRESS' | translate"
                [readonly]="componentState.isReadonly()"
                [requiredField]="false"
                [autoAdd]="false"
                [form]="form">
              </app-postal-address-field>
              <!-- endregion PostalAddress -->
            </div>
          </div>
        </div>


        <div class="container-horizontal-padding-half">

          <!-- region FormRecord -->
          <app-form-record-container
            [readonly]="componentState.isReadonly()"
            [cloning]="false"
            [form]="form">
          </app-form-record-container>
          <!-- endregion FormRecord -->

        </div>

        <!--Only visible on create/edit views-->
        <input *ngIf="componentState.isEditable()"
               type="submit" class="btn btn-primary-outline floating_apply_button"
               value="{{'COMMON_BUTTON_APPLY'|translate}}"
               (click)="onSaveButtonClicked(false)"/>
        <input *ngIf="componentState.isEditable()"
               type="submit" class="btn btn-primary floating_save_button"
               value="{{'COMMON_BUTTON_SAVE'|translate}}"
               (click)="onSaveButtonClicked(true)"/>
      </form>
    </responsive-tab>
    <responsive-tab heading="{{'INVOICE_CREATE_RELATED_TASKS'|translate}}"
                    *ngIf="componentState.id && rightModel.taskRecordRead.hasRight()">
      <app-new-task-record-list
        [projectRecordId]="componentState.id!"
        [_headerStringKey]="'INVOICE_CREATE_RELATED_TASKS'">
      </app-new-task-record-list>
    </responsive-tab>
  </responsive-tabset>
</div>
