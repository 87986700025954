/* eslint-disable */
import {Storages} from '../../util/repo/storages';
import {ConnectionError, ServerError} from './errors';
import {StringKey} from '../../app.string-keys';
import {StateName} from '../../app.state-names';
import {RouterStateObject} from '../../util/core-utils';
import {AppType} from "./app-type-helper.service";

/* eslint-enable */

export class LocalStorages {
  public static onLogout() {
    AuthTokenStorage.getInstance().removeToken();
    LoggedInUserStorage.getInstance().removeUserId();
  }
}

export class ServiceErrorStorage {

  private static instance = new ServiceErrorStorage();

  private serverError: ServerError | null = null;
  private connectionError: ConnectionError | null = null;
  private stateObject: RouterStateObject | null = null;

  public permissionDeniedHandlingActive: boolean = false;

  public static getInstance(): ServiceErrorStorage {
    return ServiceErrorStorage.instance;
  }

  public getStateObject(): RouterStateObject | null {
    return this.stateObject;
  }

  public setStateObject(stateObject?: RouterStateObject) {
    if (stateObject && !StateName.supportStates.includes(stateObject.stateName)) {
      this.stateObject = stateObject;
    } else if (!stateObject) {
      this.stateObject = null;
    }
  }

  public getServerError(): ServerError | null {
    return this.serverError;
  }

  setServerError(error: ServerError | null, stateObject?: RouterStateObject): void {
    this.serverError = error;
    this.setStateObject(stateObject);
  }

  public getConnectionError(): ConnectionError | null {
    return this.connectionError;
  }

  setConnectionError(error: ConnectionError | null, stateObject?: RouterStateObject): void {
    this.connectionError = error;
    this.setStateObject(stateObject);
  }

  private constructor() {
  }

}

export class AuthTokenStorage {

  private static readonly KEY_TOKEN = 'token';

  private static instance = new AuthTokenStorage();

  private storageAvailable: boolean | null = null;

  public static getInstance(): AuthTokenStorage {
    return AuthTokenStorage.instance;
  }

  public getToken(appType?: AppType): string | null {
    try {
      let token = localStorage.getItem(AuthTokenStorage.KEY_TOKEN);
      if (token) {
        if (appType && appType != this.getTokenAppType(token)) {
          this.removeToken();
          token = null;
        }
      }
      return token;
    } catch (error) {
      this.storageAvailable = false;
      return null;
    }
  }

  public setToken(token: string): void {
    try {
      localStorage.setItem(AuthTokenStorage.KEY_TOKEN, token);
    } catch (error) {
      this.storageAvailable = false;
    }
  }

  private getTokenAppType(token: string): AppType {
    const obj = JSON.parse(atob(token.split('.')[1]));
    if (obj.hasOwnProperty('application_type')) {
      return <AppType>obj.application_type;
    }
    return AppType.WEBDMIN;
  }

  public removeToken(): void {
    try {
      localStorage.removeItem(AuthTokenStorage.KEY_TOKEN);
    } catch (error) {
      this.storageAvailable = false;
    }
  }

  public isStorageAvailable(): boolean {
    if (this.storageAvailable === null) {
      this.storageAvailable = Storages.checkStorageAvailable();
    }
    return this.storageAvailable;
  }

  private constructor() {
  }

}

export class LoginRequiredReason {

  public static readonly NO_PROFILE_AVAILABLE = new LoginRequiredReason(
    StringKey.LOGIN_REQUIRED_REASON_TITLE_LOGIN_REQUIRED,
    StringKey.LOGIN_REQUIRED_REASON_TEXT_SESSION_EXPIRED,
    'alert-info');

  public static readonly INVALID_ACCESS_TOKEN = new LoginRequiredReason(
    StringKey.LOGIN_REQUIRED_REASON_TITLE_LOGIN_REQUIRED,
    StringKey.LOGIN_REQUIRED_REASON_TEXT_SESSION_EXPIRED,
    'alert-info');

  public static readonly UNKNOWN_USER = new LoginRequiredReason(
    StringKey.LOGIN_REQUIRED_REASON_TITLE_LOGIN_REQUIRED,
    StringKey.LOGIN_REQUIRED_REASON_TEXT_UNKNOWN_USER,
    'alert-info');

  public static readonly REGISTRATION_SUCCESSFUL = new LoginRequiredReason(
    StringKey.REGISTRATION_SUCCESSFUL_TITLE,
    StringKey.REGISTRATION_SUCCESSFUL_TEXT,
    'alert-success');

  public static readonly HELPDESK_REGISTRATION_SUCCESSFUL = new LoginRequiredReason(
    StringKey.REGISTRATION_SUCCESSFUL_TITLE,
    StringKey.REGISTRATION_HELPDESK_SUCCESSFUL_TEXT,
    'alert-success');

  public static readonly SHOP_RENTER_LOGIN = new LoginRequiredReason(
    StringKey.LOGIN_REQUIRED_REASON_TITLE_LOGIN_REQUIRED,
    StringKey.SHOP_RENTER_SHOP_LOGIN_REQUIRED,
    'alert-info');

  public static readonly USER_ACTIVATION_LOGIN = new LoginRequiredReason(
    StringKey.LOGIN_REQUIRED_REASON_TITLE_LOGIN_REQUIRED,
    StringKey.USER_ACTIVATION_LOGIN_REQUIRED,
    'alert-info');

  public static readonly USER_ACTIVATION_FAILED_LOGIN = new LoginRequiredReason(
    StringKey.LOGIN_REQUIRED_REASON_TITLE_LOGIN_REQUIRED,
    StringKey.USER_ACTIVATION_ALREADY_ACTIVATED_LOGIN_REQUIRED,
    'alert-info');

  constructor(private titleKey: string, private textKey: string, private alertType: string) {
  }

  public getTitleKey(): string {
    return this.titleKey;
  }

  public getTextKey(): string {
    return this.textKey;
  }

  public getAlertType(): string {
    return this.alertType;
  }

}

export class LoginRequiredReasonStorage {

  private static instance = new LoginRequiredReasonStorage();

  private reason: LoginRequiredReason | null = null;
  private stateObject: RouterStateObject | null = null;

  public static getInstance(): LoginRequiredReasonStorage {
    return LoginRequiredReasonStorage.instance;
  }

  public getReason(): LoginRequiredReason | null {
    return this.reason;
  }

  public getStateObject(): any {
    return this.stateObject;
  }

  setReason(reason: LoginRequiredReason | null, stateObject?: RouterStateObject): void {
    this.reason = reason;
    if (stateObject) {
      if (stateObject.stateName !== StateName.LOGIN) {
        this.stateObject = {
          stateName: stateObject.stateName,
          params: Object.assign({}, stateObject.params)
        };
      }
    } else {
      this.stateObject = null;
    }
  }

  private constructor() {
  }

}

export class LoggedInUserStorage {

  private static readonly KEY_USER_ID = 'logged_in_user_id'

  private static instance = new LoggedInUserStorage();

  public static getInstance(): LoggedInUserStorage {
    return LoggedInUserStorage.instance;
  }

  getUserId(): number {
    try {
      const id = localStorage.getItem(LoggedInUserStorage.KEY_USER_ID);
      if (id) {
        return +id;
      }
      throw Error('User is not logged in, handle this error elsewhere!');
    } catch (e) {
      throw Error('Logged in user storage is not available.');
    }
  }

  setUserId(id: number): void {
    try {
      localStorage.setItem(LoggedInUserStorage.KEY_USER_ID, id + '');
    } catch (e) {
      throw Error('Logged in user storage is not available.');
    }
  }

  removeUserId(): void {
    try {
      localStorage.removeItem(LoggedInUserStorage.KEY_USER_ID);
    } catch (e) {
      throw Error('Logged in user storage is not available.');
    }
  }

  private constructor() {
  }

}

export class RecaptchaV3TokenStorage {

  private static readonly KEY_RECAPTCHA_V3_TOKEN = 'recaptcha_v3_token';

  private static instance = new RecaptchaV3TokenStorage();

  private storageAvailable: boolean | null = null;

  public static getInstance(): RecaptchaV3TokenStorage {
    return RecaptchaV3TokenStorage.instance;
  }

  public clear(): void {
    try {
      localStorage.removeItem(RecaptchaV3TokenStorage.KEY_RECAPTCHA_V3_TOKEN);
    } catch (error) {
      this.storageAvailable = false;
    }
  }

  public getToken(): string | null {
    try {
      const token = localStorage.getItem(RecaptchaV3TokenStorage.KEY_RECAPTCHA_V3_TOKEN);
      this.clear();
      return token;
    } catch (error) {
      this.storageAvailable = false;
      return null;
    }
  }

  public setToken(value: string): void {
    try {
      localStorage.setItem(RecaptchaV3TokenStorage.KEY_RECAPTCHA_V3_TOKEN, value);
    } catch (error) {
      this.storageAvailable = false;
    }
  }

  isTokenAvailable(): boolean {
    return localStorage.getItem(RecaptchaV3TokenStorage.KEY_RECAPTCHA_V3_TOKEN) !== null;
  }

  public isStorageAvailable(): boolean {
    if (this.storageAvailable === null) {
      this.storageAvailable = Storages.checkStorageAvailable();
    }
    return this.storageAvailable;
  }

  private constructor() {
  }

}
