<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="card">

    <div class="card-header">
      {{'SEARCH_PANEL_HEADING_TITLE' | translate}}

      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()" title="{{'COMMON_BUTTON_ADD' | translate}}"><i class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>

    <div class="card-body" *ngIf="showSearch">

      <div class="row form-group">
        <div class="col-md-2">
          <label class="search-label">{{'PERMISSION_LIST_PRIVILEGE_DEFINITION_LABEL' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'PERMISSION_LIST_PRIVILEGE_DEFINITION_PLACEHOLDER' | translate}}"
                 [(ngModel)]="model.privilegeDefinition" name="privilegeDefinition" id="privilegeDefinition" #privilegeDefinition="ngModel" required>
        </div>
        <div class="col-md-1">
          <button type="button" (click)="search()"  class="btn btn-primary search-button">{{'COMMON_BUTTON_SEARCH' | translate}}</button>
        </div>
      </div>
    </div> <!-- ./card-body end -->
  </div> <!-- ./card end -->

  <div class="container-vertical-padding"></div>

  <div class="card">

    <div class="card-header">
      {{'PERMISSION_LIST_SEARCH_PANEL_HEADING_LIST' | translate}}
    </div><!-- /.card-header -->

    <div class="card-body">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header"><app-table-sorter-no-op [text]="'PERMISSION_LIST_HEADER_PERMISSION_SHORT_NAME' | translate"></app-table-sorter-no-op></th>
          <th class="table-sorter-header"><app-table-sorter-no-op [text]="'PERMISSION_LIST_HEADER_CURRENT_USER_MATCH' | translate"></app-table-sorter-no-op></th>
          <th class="table-sorter-header"><app-table-sorter-no-op [text]="'PERMISSION_LIST_HEADER_SEARCH_MATCH' | translate"></app-table-sorter-no-op></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let permission of completePermissionList">
          <td class="responsive-table-column">{{permission.short_name}}</td>
          <td class="responsive-table-column" *ngIf="isCurrentUserMatch(permission)"><span class="glyphicons glyphicons-ok"></span></td>
          <td class="responsive-table-column" *ngIf="!isCurrentUserMatch(permission)"><span class="glyphicons glyphicons-lock"></span></td>
          <td class="responsive-table-column" *ngIf="isSearchMatch(permission)"><span *ngIf="searchedOnce" class="glyphicons glyphicons-ok"></span></td>
          <td class="responsive-table-column" *ngIf="!isSearchMatch(permission)"><span *ngIf="searchedOnce" class="glyphicons glyphicons-lock"></span></td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header"><app-table-sorter-no-op [text]="'PERMISSION_LIST_HEADER_PERMISSION_SHORT_NAME' | translate"></app-table-sorter-no-op></th>
          <th class="table-sorter-header"><app-table-sorter-no-op [text]="'PERMISSION_LIST_HEADER_CURRENT_USER_MATCH' | translate"></app-table-sorter-no-op></th>
          <th class="table-sorter-header"><app-table-sorter-no-op [text]="'PERMISSION_LIST_HEADER_SEARCH_MATCH' | translate"></app-table-sorter-no-op></th>
        </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>
