<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [self]="breadcrumbSelf"
      [parents]="breadcrumbParents">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="card">

    <div class="card-header">
      {{'SHOP_RENTER_SHOP_LIST' | translate}}

      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()" title="{{'COMMON_BUTTON_SEARCH' | translate}}"><i
          class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>

    <div class="card-body border-bottom" *ngIf="showSearch">
      <div class="row form-group">
        <div class="col-md-4">
          <label class="search-label">{{'COMMON_TABLE_HEADER_ID' | translate}}</label>
          <input type="number" class="form-control"
                 placeholder="{{'COMMON_TABLE_HEADER_ID' | translate}}"
                 maxlength="{{UiConstants.maxInputLongLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.id">
        </div>
        <div class="col-md-4">
          <label class="search-label">{{'SHOP_RENTER_SHOP_NAME' | translate}}</label>
          <input type="text" class="form-control"
                 placeholder="{{'SHOP_RENTER_SHOP_NAME' | translate}}"
                 maxlength="{{UiConstants.maxInputLongLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.name">
        </div>
        <div class="col-md-4">
          <label class="search-label">{{'COMMON_NAME' | translate}}</label>
          <input type="text" class="form-control"
                 placeholder="{{'COMMON_NAME' | translate}}"
                 maxlength="{{UiConstants.maxInputLongLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.humanName">
        </div>
        <div class="col-md-4">
          <label class="search-label">{{'COMMON_COMPANY' | translate}}</label>
          <angular2-multiselect [data]="companies" [settings]="dropdownSettings"
                                class="form-control"
                                [(ngModel)]="searchModel.company"
                                (onRemoteSearch)="onCompanySearch($event.target.value)"
                                #company="ngModel" name="company" id="company">
          </angular2-multiselect>
        </div>
        <div class="col-md-4">
          <label class="search-label">{{'USER_SEARCH_FIELD_USERNAME' | translate}}</label>
          <angular2-multiselect [data]="users" [settings]="dropdownSettings"
                                class="form-control"
                                [(ngModel)]="searchModel.user"
                                (onRemoteSearch)="onUserSearch($event.target.value)"
                                #user="ngModel" name="user" id="user">
          </angular2-multiselect>
        </div>
      </div>
      <div class="col-md-12 d-flex justify-content-md-end align-items-end px-0">
        <div class="btn-group" role="group">
          <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
            {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
          </button>
          <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
            {{'COMMON_BUTTON_SEARCH' | translate}}
          </button>
        </div>
      </div>
    </div><!-- /.card-body-->
    <div class="card-body">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="ShopRenterSearch.OrderFunctions.ID"
                                    [orderType]="queryModel.getOrderType(ShopRenterSearch.OrderFunctions.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="ShopRenterSearch.OrderFunctions.NAME"
                                    [orderType]="queryModel.getOrderType(ShopRenterSearch.OrderFunctions.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-sorter-no-op
              [text]="'COMMON_COMPANY' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-sorter-no-op
              [text]="'USER_SEARCH_FIELD_USERNAME' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-sorter-no-op
              [text]="'SHOP_RENTER_SHOP' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op
              [text]="'SHOP_RENTER_SHOP_NAME' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-actions">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let shop of shopList | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }">
          <td class="responsive-table-column">{{shop.id}}</td>
          <td class="responsive-table-column">{{shop.humanName}}</td>
          <td class="responsive-table-column hidden-md-down">{{shop.companyName}}</td>
          <td class="responsive-table-column hidden-xs-down">{{shop.userPersonName}}</td>
          <td class="responsive-table-column hidden-md-down w-15">
            <img src="../../../../assets/img/shoprenter/shoprenter.png" class="w-100" alt="ShopRenter">
          </td>
          <td class="responsive-table-column hidden-sm-down">{{shop.name}}</td>
          <td class="responsive-table-column">
            <a class="icons-table-group cursor-pointer" uiSref="Admin.ShopRenterShopEdit"
               *ngIf="shop.rights.update.hasRight()"
               [uiParams]="{ id: shop.id }">
              <i class="icomoon icons-table-item icomoon-modify icomoon-detail-table"
                 title="{{'COMMON_BUTTON_EDIT' | translate}}"></i>
            </a>
            <a class="icons-table-group cursor-pointer" uiSref="Admin.ShopRenterShopDetail"
               [uiParams]="{ id: shop.id }">
              <i class="icomoon icons-table-item icomoon-detail icomoon-detail-table"
                 title="{{'COMMON_BUTTON_DETAILS' | translate}}"></i>
            </a>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="ShopRenterSearch.OrderFunctions.ID"
                                    [orderType]="queryModel.getOrderType(ShopRenterSearch.OrderFunctions.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="ShopRenterSearch.OrderFunctions.NAME"
                                    [orderType]="queryModel.getOrderType(ShopRenterSearch.OrderFunctions.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-sorter-no-op
              [text]="'COMMON_COMPANY' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-sorter-no-op
              [text]="'USER_SEARCH_FIELD_USERNAME' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-sorter-no-op
              [text]="'SHOP_RENTER_SHOP' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op
              [text]="'SHOP_RENTER_SHOP_NAME' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-actions">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>
  </div>
</div>
