/* eslint-disable */
import { AfterViewInit, Component, ComponentRef, ViewChild, } from '@angular/core';
import { FormEditCreateUpdateGroupDialogComponent } from '../dialogs/group/create-update/form-edit-create-update-group-dialog.component';
import {
  FieldDataTypeDescriptor,
  FormEditContext,
  FormEditFieldCreateDialogView,
  FormEditFieldDialogView,
  FormEditFieldUpdateDialogView,
  FormGroupModel,
  FormModel,
} from '../../../../util/form/form-utils';
import { FormEditDialogHolderDirective } from './form-edit-dialog-holder.directive';
import { Arrays } from '../../../../lib/util/arrays';
import { Form } from '../../../../lib/form/form.service';

/* eslint-enable */

@Component({
  selector: 'app-form-edit-dialog-container',
  templateUrl: 'form-edit-dialog-container.component.html',
  styleUrls: ['form-edit-dialog-container.component.scss'],
})
export class FormEditDialogContainerComponent implements AfterViewInit {

  @ViewChild(FormEditCreateUpdateGroupDialogComponent, { static: true })
  createGroupComponent: FormEditCreateUpdateGroupDialogComponent;

  @ViewChild(FormEditDialogHolderDirective, { static: true })
  dialogHost: FormEditDialogHolderDirective;

  private fieldCreateDialogRefs: ComponentRef<FormEditFieldCreateDialogView>[] = [];
  private fieldUpdateDialogRefs: ComponentRef<FormEditFieldUpdateDialogView>[] = [];

  private context: FormEditContext;

  registerContext(context: FormEditContext): void {
    this.context = context;
  }

  registerFormFieldDialogs(
    fieldCreateDialogRefs: ComponentRef<FormEditFieldCreateDialogView>[],
    fieldUpdateDialogRefs: ComponentRef<FormEditFieldUpdateDialogView>[]) {
    this.fieldCreateDialogRefs = fieldCreateDialogRefs;
    this.fieldUpdateDialogRefs = fieldUpdateDialogRefs;
  }

  ngAfterViewInit(): void {
    this.createGroupComponent.registerContext(this.context);
    this.dialogHost.viewContainerRef.clear();
    Arrays.iterateByIndex(this.fieldCreateDialogRefs, (ref) => {
      ref.instance.registerContext(this.context);
      this.dialogHost.viewContainerRef.insert(ref.hostView)
    });
    Arrays.iterateByIndex(this.fieldUpdateDialogRefs, (ref) => {
      ref.instance.registerContext(this.context);
      this.dialogHost.viewContainerRef.insert(ref.hostView)
    });
  }

  showCreateGroupDialog(): void {
    this.createGroupComponent.showDialog();
  }

  showUpdateGroupDialog(group: FormGroupModel): void {
    this.createGroupComponent.showDialog(group);
  }

  showCreateFieldDialog(form: FormModel, group: FormGroupModel, descriptor: FieldDataTypeDescriptor): void {
    const view = this.findCreateFieldView(descriptor);
    view.showDialog(form, group);
  }

  showUpdateFieldDialog(form: FormModel, group: FormGroupModel, field: Form.Field, clone?: boolean): void {
    const view = this.findUpdateFieldView(field.dataTypeSelector);
    view.showDialog(form, group, field, clone);
  }

  private findCreateFieldView(descriptor: FieldDataTypeDescriptor): FormEditFieldCreateDialogView {
    return this.findFieldViewBySelector(this.fieldCreateDialogRefs, descriptor.selector);
  }

  private findUpdateFieldView(selector: Form.FieldDataTypeSelector): FormEditFieldUpdateDialogView {
    return this.findFieldViewBySelector(this.fieldUpdateDialogRefs, selector);
  }

  private findFieldViewBySelector<T extends FormEditFieldDialogView>(
    refs: ComponentRef<T>[],
    selector: Form.FieldDataTypeSelector): T {
    const foundRefs = refs.filter((ref) => {
      return ref.instance.selector === selector;
    });
    if (foundRefs.length === 0) {
      throw new Error('Descriptor not found');
    }
    if (foundRefs.length > 1) {
      throw new Error('Wrong descriptor in FormEditFieldCreateDialogView');
    }
    const ref = foundRefs[0];
    return ref.instance;
  }

}
