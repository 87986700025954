import { Component, OnInit } from '@angular/core';
import { TaskRecord, TaskRecordService } from '../../../lib/task/task-record.service';
import { Set } from 'immutable';
import { OrderType, QueryResult } from '../../../lib/util/services';
import { UserService } from '../../../lib/user.service';
import { DownloadedFile } from '../../../lib/util/downloaded-files';
import {RightModel} from "../../../app.rights";
import {RightResolver, RightService} from "../../../lib/right.service";

@Component({
  selector: 'app-helpdesk-notifications-card',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class HelpdeskNotificationsCardComponent implements OnInit {

  list: {
    log: TaskRecord.Log,
    profilePicture: any | undefined
  }[] = [];

  rightModel: RightModel = RightModel.empty();

  constructor(
    private taskRecordService: TaskRecordService,
    private rightService: RightService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.loadRightModels(() => this.loadNotifications());
  }

  private loadRightModels(completion: () => void) {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        completion();
      }
    );
  }

  private loadNotifications(): void {
    if (!this.rightModel.taskRecordLogRead.hasRight()) {
      return;
    }
    this.taskRecordService.getGlobalLogHistory({
      paging: {
        pageNumber: 1,
        numberOfItems: 6
      },
      helpdesk: true,
      orders: Set.of({type: OrderType.DESC, field: TaskRecord.LogOrderField.LOG_TIME})
    }).subscribe((result: QueryResult<TaskRecord.Log>) => {
      this.list = result.items.toArray().map(l => ({log: l, profilePicture: undefined}));
      this.list.forEach(i => {
        this.userService.downloadProfilePicture(i.log.userProfileId).subscribe(
          (res: DownloadedFile) => {
            i.profilePicture = URL.createObjectURL(res.getBlob());
          },
          () => {
          }
        );
      });
    });
  }

}
