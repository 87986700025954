<div class="container-horizontal-padding container-vertical-padding animated fadeIn" >
  <div class="card">

    <div class="card-header">
      {{'DOCUMENT_HTML_PANEL_HEADING_DETAIL' | translate}}
    </div>

    <div class="card-body">

      <!--ID-->
      <div class="form-group">
        <div class="edit-form-input-group col-md-6">
          <label> {{ 'DOCUMENT_HTML_ID' | translate }} </label>
          <div>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="model.id"
              name="id"
              id="id"
              #id="ngModel"
              readonly>
          </div>
        </div>
      </div>

      <!--NAME-->
      <div class="form-group">
        <label class="edit-form-label form-control-label">{{'COMMON_NAME' | translate}}</label>
        <div class="edit-form-input-group">
          <input
            type="text"
            class="form-control"
            placeholder="{{'COMMON_NAME' | translate}}"
            [(ngModel)]="model.name"
            name="name"
            id="name"
            #code="ngModel"
            readonly>
        </div>
      </div>

      <!--CODE-->
      <div class="form-group">
        <div class="edit-form-input-group col-md-6">
          <label> {{ 'DOCUMENT_HTML_CODE' | translate }} </label>
          <div>
            <input
              type="text"
              class="form-control"

              [(ngModel)]="model.code"
              name="code"
              id="code"
              #name="ngModel"
              readonly>
          </div>
        </div>
      </div>

      <!--COMMENT-->
      <div class="form-group">
        <div *ngIf="model.comment" class="edit-form-input-group col-md-6">
          <label> {{'DOCUMENT_HTML_COMMENT' | translate}} </label>
          <input
            type="text"
            class="form-control"
            placeholder="{{'DOCUMENT_HTML_COMMENT' | translate}}"
            [(ngModel)]="model.comment"
            name="comment"
            id="comment"
            #description="ngModel"
            readonly>
        </div>
      </div>

      <!--GROUP-->
      <div *ngIf="ConfigurationService.isEnabledByServer('DOCUMENTS', configuration) && model.document_group" class="form-group">
        <div class="edit-form-input-group col-md-6">
          <label> {{ 'DOCUMENT_GROUP' | translate }} </label>
          <div>
            <input
              type="text"
              class="form-control"
              [ngModel]="model.document_group.text"
              name="document_group"
              id="document_group"
              #document_group="ngModel"
              readonly>
          </div>
        </div>
      </div>

      <!--LANGUAGE-->
      <div *ngIf="model.language_code" class="form-group">
        <div  class="edit-form-input-group col-md-6">
          <label> {{ 'DOCUMENT_LANGUAGE' | translate }} </label>
          <div>
            <input
              type="text"
              class="form-control"
              [ngModel]="model.language_code.text"
              name="language"
              id="language"
              #language="ngModel"
              readonly>
          </div>
        </div>
      </div>

    </div> <!-- ./card-body end -->
  </div>  <!-- ./card end -->

  <div class="container-vertical-padding"></div>

  <!--HTML VALUE-->
  <div class="card">

    <div class="card-header">
      {{'DOCUMENT_HTML_PANEL_HEADING_DETAIL' | translate}}
    </div>

    <div class="card-body">

      <!--EDITOR-->
      <div class="form-group">
        <div class="edit-form-input-group col-md-6">
          <iframe style="width: 100%; border:solid 1px" [srcdoc]="model.value">
          </iframe>
        </div>
      </div>

    </div><!-- /.card-body-->
  </div><!-- /.card -->

</div>
