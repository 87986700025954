// Generated from src/app/util/generated/dql/normal/Dql.g4 by ANTLR 4.7.3-SNAPSHOT
/* eslint-disable */

import { ATN } from "antlr4ts/atn/ATN";
import { ATNDeserializer } from "antlr4ts/atn/ATNDeserializer";
import { CharStream } from "antlr4ts/CharStream";
import { Lexer } from "antlr4ts/Lexer";
import { LexerATNSimulator } from "antlr4ts/atn/LexerATNSimulator";
import { NotNull } from "antlr4ts/Decorators";
import { Override } from "antlr4ts/Decorators";
import { RuleContext } from "antlr4ts/RuleContext";
import { Vocabulary } from "antlr4ts/Vocabulary";
import { VocabularyImpl } from "antlr4ts/VocabularyImpl";

import * as Utils from "antlr4ts/misc/Utils";


export class DqlLexer extends Lexer {
	public static readonly T__0 = 1;
	public static readonly NUMBER = 2;
	public static readonly WORD = 3;
	public static readonly OPERATION = 4;
	public static readonly UNARY_OPERATION = 5;
	public static readonly LOGICAL = 6;
	public static readonly WS = 7;
	public static readonly VALUE = 8;
	public static readonly SEMICOLON = 9;
	public static readonly LPAR = 10;
	public static readonly RPAR = 11;

	/* eslint-disable no-trailing-spaces */
	public static readonly channelNames: string[] = [
		"DEFAULT_TOKEN_CHANNEL", "HIDDEN",
	];

	/* eslint-disable no-trailing-spaces */
	public static readonly modeNames: string[] = [
		"DEFAULT_MODE",
	];

	public static readonly ruleNames: string[] = [
		"T__0", "DIGIT", "LOWERCASE", "UPPERCASE", "VALUE_CHARS", "NUMBER", "WORD",
		"OPERATION", "UNARY_OPERATION", "LOGICAL", "WS", "VALUE", "SEMICOLON",
		"LPAR", "RPAR",
	];

	private static readonly _LITERAL_NAMES: Array<string | undefined> = [
		undefined, "'.'", undefined, undefined, undefined, undefined, undefined,
		undefined, undefined, "';'", "'('", "')'",
	];
	private static readonly _SYMBOLIC_NAMES: Array<string | undefined> = [
		undefined, undefined, "NUMBER", "WORD", "OPERATION", "UNARY_OPERATION",
		"LOGICAL", "WS", "VALUE", "SEMICOLON", "LPAR", "RPAR",
	];
	public static readonly VOCABULARY: Vocabulary = new VocabularyImpl(DqlLexer._LITERAL_NAMES, DqlLexer._SYMBOLIC_NAMES, []);

	// @Override
	// @NotNull
	public get vocabulary(): Vocabulary {
		return DqlLexer.VOCABULARY;
	}
	/* eslint-enable no-trailing-spaces */


	constructor(input: CharStream) {
		super(input);
		this._interp = new LexerATNSimulator(DqlLexer._ATN, this);
	}

	// @Override
	public get grammarFileName(): string { return "Dql.g4"; }

	// @Override
	public get ruleNames(): string[] { return DqlLexer.ruleNames; }

	// @Override
	public get serializedATN(): string { return DqlLexer._serializedATN; }

	// @Override
	public get channelNames(): string[] { return DqlLexer.channelNames; }

	// @Override
	public get modeNames(): string[] { return DqlLexer.modeNames; }

	public static readonly _serializedATN: string =
		"\x03\uC91D\uCABA\u058D\uAFBA\u4F53\u0607\uEA8B\uC241\x02\rc\b\x01\x04" +
		"\x02\t\x02\x04\x03\t\x03\x04\x04\t\x04\x04\x05\t\x05\x04\x06\t\x06\x04" +
		"\x07\t\x07\x04\b\t\b\x04\t\t\t\x04\n\t\n\x04\v\t\v\x04\f\t\f\x04\r\t\r" +
		"\x04\x0E\t\x0E\x04\x0F\t\x0F\x04\x10\t\x10\x03\x02\x03\x02\x03\x03\x03" +
		"\x03\x03\x04\x03\x04\x03\x05\x03\x05\x03\x06\x03\x06\x03\x07\x03\x07\x07" +
		"\x07.\n\x07\f\x07\x0E\x071\v\x07\x03\b\x03\b\x03\b\x06\b6\n\b\r\b\x0E" +
		"\b7\x03\t\x03\t\x03\t\x03\t\x03\t\x03\t\x03\t\x03\t\x03\t\x03\t\x03\t" +
		"\x05\tE\n\t\x03\n\x03\n\x03\n\x05\nJ\n\n\x03\v\x03\v\x03\f\x06\fO\n\f" +
		"\r\f\x0E\fP\x03\f\x03\f\x03\r\x03\r\x07\rW\n\r\f\r\x0E\rZ\v\r\x03\r\x03" +
		"\r\x03\x0E\x03\x0E\x03\x0F\x03\x0F\x03\x10\x03\x10\x02\x02\x02\x11\x03" +
		"\x02\x03\x05\x02\x02\x07\x02\x02\t\x02\x02\v\x02\x02\r\x02\x04\x0F\x02" +
		"\x05\x11\x02\x06\x13\x02\x07\x15\x02\b\x17\x02\t\x19\x02\n\x1B\x02\v\x1D" +
		"\x02\f\x1F\x02\r\x03\x02\n\x03\x022;\x03\x02c|\x03\x02C\\\x03\x02$$\x03" +
		"\x023;\x04\x02>>@@\x04\x02((~~\x05\x02\v\f\x0F\x0F\"\"\x02k\x02\x03\x03" +
		"\x02\x02\x02\x02\r\x03\x02\x02\x02\x02\x0F\x03\x02\x02\x02\x02\x11\x03" +
		"\x02\x02\x02\x02\x13\x03\x02\x02\x02\x02\x15\x03\x02\x02\x02\x02\x17\x03" +
		"\x02\x02\x02\x02\x19\x03\x02\x02\x02\x02\x1B\x03\x02\x02\x02\x02\x1D\x03" +
		"\x02\x02\x02\x02\x1F\x03\x02\x02\x02\x03!\x03\x02\x02\x02\x05#\x03\x02" +
		"\x02\x02\x07%\x03\x02\x02\x02\t\'\x03\x02\x02\x02\v)\x03\x02\x02\x02\r" +
		"+\x03\x02\x02\x02\x0F5\x03\x02\x02\x02\x11D\x03\x02\x02\x02\x13I\x03\x02" +
		"\x02\x02\x15K\x03\x02\x02\x02\x17N\x03\x02\x02\x02\x19T\x03\x02\x02\x02" +
		"\x1B]\x03\x02\x02\x02\x1D_\x03\x02\x02\x02\x1Fa\x03\x02\x02\x02!\"\x07" +
		"0\x02\x02\"\x04\x03\x02\x02\x02#$\t\x02\x02\x02$\x06\x03\x02\x02\x02%" +
		"&\t\x03\x02\x02&\b\x03\x02\x02\x02\'(\t\x04\x02\x02(\n\x03\x02\x02\x02" +
		")*\n\x05\x02\x02*\f\x03\x02\x02\x02+/\t\x06\x02\x02,.\x05\x05\x03\x02" +
		"-,\x03\x02\x02\x02.1\x03\x02\x02\x02/-\x03\x02\x02\x02/0\x03\x02\x02\x02" +
		"0\x0E\x03\x02\x02\x021/\x03\x02\x02\x0226\x05\x07\x04\x0236\x05\t\x05" +
		"\x0246\x07a\x02\x0252\x03\x02\x02\x0253\x03\x02\x02\x0254\x03\x02\x02" +
		"\x0267\x03\x02\x02\x0275\x03\x02\x02\x0278\x03\x02\x02\x028\x10\x03\x02" +
		"\x02\x029E\x07?\x02\x02:;\x07#\x02\x02;E\x07?\x02\x02<E\t\x07\x02\x02" +
		"=>\x07>\x02\x02>E\x07?\x02\x02?@\x07@\x02\x02@E\x07?\x02\x02AE\x07\x80" +
		"\x02\x02BC\x07#\x02\x02CE\x07\x80\x02\x02D9\x03\x02\x02\x02D:\x03\x02" +
		"\x02\x02D<\x03\x02\x02\x02D=\x03\x02\x02\x02D?\x03\x02\x02\x02DA\x03\x02" +
		"\x02\x02DB\x03\x02\x02\x02E\x12\x03\x02\x02\x02FJ\x07%\x02\x02GH\x07#" +
		"\x02\x02HJ\x07%\x02\x02IF\x03\x02\x02\x02IG\x03\x02\x02\x02J\x14\x03\x02" +
		"\x02\x02KL\t\b\x02\x02L\x16\x03\x02\x02\x02MO\t\t\x02\x02NM\x03\x02\x02" +
		"\x02OP\x03\x02\x02\x02PN\x03\x02\x02\x02PQ\x03\x02\x02\x02QR\x03\x02\x02" +
		"\x02RS\b\f\x02\x02S\x18\x03\x02\x02\x02TX\x07$\x02\x02UW\x05\v\x06\x02" +
		"VU\x03\x02\x02\x02WZ\x03\x02\x02\x02XV\x03\x02\x02\x02XY\x03\x02\x02\x02" +
		"Y[\x03\x02\x02\x02ZX\x03\x02\x02\x02[\\\x07$\x02\x02\\\x1A\x03\x02\x02" +
		"\x02]^\x07=\x02\x02^\x1C\x03\x02\x02\x02_`\x07*\x02\x02`\x1E\x03\x02\x02" +
		"\x02ab\x07+\x02\x02b \x03\x02\x02\x02\n\x02/57DIPX\x03\b\x02\x02";
	public static __ATN: ATN;
	public static get _ATN(): ATN {
		if (!DqlLexer.__ATN) {
			DqlLexer.__ATN = new ATNDeserializer().deserialize(Utils.toCharArray(DqlLexer._serializedATN));
		}

		return DqlLexer.__ATN;
	}

}

/* eslint-enable */
