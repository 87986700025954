import { MultiselectOptionItem } from '../../util/core-utils';
import {
  DeliveryMethod
} from '../../lib/delivery-method/delivery-method.service';
import { OffsetDateTime } from '../../lib/util/dates';

export class DeliveryMethodEditModel {

  id: number;
  name: string = '';
  externalId: string = '';
  _type: MultiselectOptionItem<DeliveryMethod.DeliveryMethodType>[] = [];
  transporterCompany: MultiselectOptionItem<number>[] = [];
  integrationType: DeliveryMethod.DeliveryMethodIntegrationType = 'MPL';
  packageWeightRequired: boolean = false;
  maxPackageWeightValue: string = '';
  maxPackageWeightUnit: string = 'g';
  packageSizeRequired: boolean = false;
  maxPackageSizeWidth: string = '';
  maxPackageSizeHeight: string = '';
  maxPackageSizeDepth: string = '';
  maxPackageSizeUnit: string = 'cm';
  maxInsurancePriceValue: string = '';
  maxInsurancePriceCurrency: string = 'HUF';
  maxCashOnDeliveryPriceValue: string = '';
  maxCashOnDeliveryPriceCurrency: string = 'HUF';
  recipientEmailAddressRequired: boolean = false;
  recipientPhoneNumberRequired: boolean = false;

  get type(): DeliveryMethod.DeliveryMethodType | undefined {
    return this._type.length > 0 ? this._type[0].id : undefined;
  }

  get transporterCompanyId(): number | undefined {
    return this.transporterCompany.length > 0 ? this.transporterCompany[0].id : undefined;
  }

}

export class DeliveryMethodListModel {

  id: number;
  name: string = '';
  externalId: string = '';
  type: string = '';
  creationTime: OffsetDateTime;
  disabled: boolean = false;

}

export class DeliveryMethodDetailModel extends DeliveryMethodListModel {

  transporterCompany: string = '';
  integrationType: string = '';
  packageWeightRequired: boolean = false;
  maxPackageWeight?: string;
  packageSizeRequired: boolean = false;
  maxPackageSize?: string;
  maxInsurancePrice?: string;
  maxCashOnDeliveryPrice?: string;
  recipientEmailAddressRequired: boolean;
  recipientPhoneNumberRequired: boolean;

}
