/* eslint-disable */
import { EmptyMessage } from '../util/messages';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AddressResource } from '../address';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { HttpClient } from '@angular/common/http';
import { ResourceQueryResult } from '../util/services';
import { DownloadedFile } from '../util/downloaded-files';
import { UrlBuilder } from '../../util/url-builder';
import { map } from 'rxjs/operators';
import { IconResource } from '../task/icon.service';

/* eslint-enable */

@Injectable()
export class OrderResourceService extends BaseHttpService {

  query(request: OrderResource.QueryRequest): Observable<ResourceQueryResult<OrderResource.Order>> {
    return this.http.get<ResourceQueryResult<OrderResource.Order>>(this.url, this.parseParams(request));
  }

  get(request: OrderResource.GetRequest): Observable<OrderResource.Order> {
    return this.http.get<OrderResource.Order>(this.url + `${request.id}`);
  }

  create(request: OrderResource.CreateRequest): Observable<OrderResource.CreateResponse> {
    return this.http.post<OrderResource.CreateResponse>(this.url, request);
  }

  updateData(request: OrderResource.UpdateDataRequest): Observable<OrderResource.UpdateDataResponse> {
    return this.http.put<OrderResource.UpdateDataResponse>(this.url + `${request.id}`, request);
  }

  finalize(request: OrderResource.FinalizeRequest): Observable<EmptyMessage> {
    return this.http.post<EmptyMessage>(this.url + `${request.id}/submit`, null);
  }

  createStockItem(request: OrderResource.CreateStockItemRequest): Observable<OrderResource.CreateStockItemResponse> {
    return this.http.post<OrderResource.CreateStockItemResponse>(this.url + `${request.order_id}/items`, request);
  }

  updateStockItem(request: OrderResource.UpdateStockItemRequest): Observable<EmptyMessage> {
    return this.http.put<EmptyMessage>(this.url + `${request.order_id}/items/${request.item_id}`, request);
  }

  deleteStockItem(request: OrderResource.DeleteStockItemRequest): Observable<EmptyMessage> {
    return this.http.delete<EmptyMessage>(this.url + `${request.order_id}/items/${request.item_id}`);
  }

  cancel(request: OrderResource.CancelRequest): Observable<EmptyMessage> {
    return this.http.post<EmptyMessage>(this.url + `${request.order_id}/cancel`, null);
  }

  returnOrder(request: OrderResource.ReturnOrderRequest): Observable<EmptyMessage> {
    return this.http.patch<EmptyMessage>(this.url + `${request.order_id}/return-reason`, request);
  }

  queryShippingStates(request: OrderResource.QueryOrderShippingStatesRequest): Observable<ResourceQueryResult<string>> {
    return this.http.get<ResourceQueryResult<string>>(this.url + `shipping-states`, this.parseParams(request));
  }

  exportXls(request: OrderResource.QueryRequest): Observable<DownloadedFile> {
    const url = UrlBuilder.create('orders/export-xls')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob',
      params: this.parseParams(request).params
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  constructor(private http: HttpClient, private resourceHelper: ResourceHelper) {
    super(resourceHelper, '/orders/');
  }

}

export namespace OrderResource {

  export interface QueryRequest {
    fields?: string;
    order_id?: string;
    owner_user_id?: number;
    external_id?: string;
    order_state?: string;
    order_logic_state?: string;
    process_id?: number;
    owner_company_name?: string;
    shipment_tracking_number?: string;
    shipping_state?: string;
    weight_in_gram_from?: string;
    weight_in_gram_to?: string;
    cash_on_delivery_price_from?: string;
    cash_on_delivery_price_to?: string;
    recipient_name?: string;
    recipient_address?: string;
    submit_time_from?: string;
    submit_time_to?: string;
    return_time_from?: string;
    return_time_to?: string;
    process_deadline_from?: string;
    process_deadline_to?: string;
    minimum_identical_item_count?: number;
    return_reason_empty?: boolean;
    order?: string;
    page_number?: number;
    number_of_items?: number;
    with_items?: boolean;
    with_only_id?: boolean;
  }

  export interface GetRequest {
    id: number;
  }

  export interface CreateRequest {
    owner_company_id: number;
  }

  export interface CreateResponse {
    order_id: number;
  }

  export interface CancelRequest {
    order_id: number;
  }

  export interface ReturnOrderRequest {
    order_id: number;
    return_reason: string;
  }

  export interface UpdateDataRequest {
    id: number;
    external_id: string;
    description?: string;
    weight_in_gram?: number;
    shipping_state?: string;
    insurance_price?: InsurancePrice;
    cash_on_delivery_price: CashOnDeliveryPrice;
    package_size?: PackageSize;
    delivery_method_id: number;
    recipient: OrderRecipient;
    shipment_tracking_number?: string;
    other_data: OtherData;
    return_state: string;
    return_reason?: string;
    return_time?: string;
    intake_time?: string;
    cancel_time?: string;
    process_end_time?: string;
    note?: OrderNote;
    version: number;
  }

  export interface UpdateDataResponse {
    version: number;
  }

  export interface OtherData {
    fragile: boolean;
    document_handling: boolean;
    package_exchange: boolean;
    handover_by_items: boolean;
    delivery_time_window: boolean;
    oversized: boolean;
    inverse: boolean;
  }

  export interface OrderProcess {
    process_id: number;
    name?: string;
    display_name: string;
    deadline?: string;
    state: string;
    workflow: OrderWorkflow;
  }

  export interface OrderWorkflow {
    name: string;
    icon?: IconResource.Icon;
  }

  export interface FinalizeRequest {
    id: number;
  }

  export interface CreateStockItemRequest {
    order_id: number;
    stock_item_id: number;
    amount: number;
  }

  export interface CreateStockItemResponse {
    item_id: number;
  }

  export interface UpdateStockItemRequest {
    order_id: number;
    item_id: number;
    stock_item_id: number;
    amount: number;
  }

  export interface DeleteStockItemRequest {
    order_id: number;
    item_id: number;
  }

  export interface Order {
    order_id: number;
    external_id: string;
    state: string;
    final_state: string;
    logic_state: string;
    description?: string;
    owner_user_id: number;
    owner_company_id: number;
    weight_in_gram?: number;
    insurance_price?: InsurancePrice;
    cash_on_delivery_price?: CashOnDeliveryPrice;
    package_size?: PackageSize;
    delivery_method?: {
      id: number;
      name: string;
    };
    recipient?: OrderRecipient;
    items?: OrderItem[];
    shipping_state?: string;
    shipment_tracking_number?: string;
    creation_time: string;
    update_time: string;
    submit_time?: string;
    process_id?: number;
    other_data: OtherData;
    return_state: string;
    return_reason?: string;
    return_time?: string;
    intake_time?: string;
    cancel_time?: string;
    process_end_time?: string;
    process?: OrderProcess;
    note?: OrderNote;
    version: number;
    mpl_last_sync_time?: string;
  }

  export interface QueryOrderShippingStatesRequest {
    shipping_state?: string;
    page_number?: number;
    number_of_items?: number;
  }

  export interface CashOnDeliveryPrice {
    amount: string;
    currency_code: string;
  }

  export interface InsurancePrice {
    amount: string;
    currency_code: string;
  }

  export interface OrderRecipient {
    name: string;
    delivery_address?: AddressResource.PostalAddressResource;
    parcel_collection_point?: {
      id: number;
      name?: string;
    };
    phone_number?: string;
    email_address?: string;
  }

  export interface OrderItem {
    item_id: number;
    stock_item_id: number;
    amount: number;
  }

  export interface PackageSize {
    width_in_meter: number;
    height_in_meter: number;
    depth_in_meter: number;
  }

  export interface OrderNote {
    customer_note?: string;
    extra_note?: string;
    internal_note?: string;
  }

}
