/* eslint-disable */
import { EmptyMessage } from '../util/messages';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { HttpClient } from '@angular/common/http';
/* eslint-enable */

@Injectable()
export class TableDocumentSchemaFieldResourceService extends BaseHttpService {

  query(request: TableDocumentSchemaFieldResource.QueryRequest):
    Observable<TableDocumentSchemaFieldResource.TableDocumentSchemaField[]> {
    return this.http.get<TableDocumentSchemaFieldResource.TableDocumentSchemaField[]>(
      this.url + `${request.schema_id}/field`, this.parseParams(request)
    );
  }

  create(request: TableDocumentSchemaFieldResource.CreateRequest): Observable<EmptyMessage> {
    return this.http.post<EmptyMessage>(this.url + `${request.schema_id}/field`, request);
  }

  update(request: TableDocumentSchemaFieldResource.UpdateRequest): Observable<EmptyMessage> {
    return this.http.put<EmptyMessage>(this.url + `${request.schema_id}/field/${request.schema_field_id}`, request);
  }

  delete(request: TableDocumentSchemaFieldResource.DeleteRequest): Observable<EmptyMessage> {
    return this.http.delete<EmptyMessage>(this.url + `${request.schema_id}/field/${request.schema_field_id}`);
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/table-document/schema/');
  }
}

export namespace TableDocumentSchemaFieldResource {

  export interface TableDocumentSchemaField {
    field_id: string;
    schema_field_id: string;
    code: string;
    name: string;
    note?: string;
    required: boolean;
    data_type: TableDocumentSchemaFieldTypeState;
  }

  export enum TableDocumentSchemaFieldTypeState {
    STRING = 'STRING',
    INTEGER = 'INTEGER',
    LONG = 'LONG',
    DOUBLE = 'DOUBLE',
    DECIMAL = 'DECIMAL',
    BOOLEAN = 'BOOLEAN',
    LOCAL_DATE = 'LOCAL_DATE',
    LOCAL_DATE_TIME = 'LOCAL_DATE_TIME'
  }

  export interface QueryRequest {
    schema_id: number;
  }

  export interface CreateRequest {
    schema_id: string;
    code: string;
    name: string;
    note?: string;
    required: boolean;
    data_type: TableDocumentSchemaFieldTypeState;
  }

  export interface UpdateRequest {
    schema_id: string;
    schema_field_id: string;
    code: string;
    name: string;
    note?: string;
    required: boolean;
    data_type: TableDocumentSchemaFieldTypeState;
  }

  export interface DeleteRequest {
    schema_id: string;
    schema_field_id: string;
  }

}
