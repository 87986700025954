import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { StringKey } from '../../../../app.string-keys';
import { TaskRecordStateMachine } from '../../../../lib/task/task-record-statemachine';
import { TaskRecordService } from '../../../../lib/task/task-record.service';
import { ToasterService } from '../../../../fork/angular2-toaster/src/toaster.service';
import { UiConstants } from '../../../../util/core-utils';
import { TranslateService } from '@ngx-translate/core';
import { TaskRecordRightModel } from '../../../../util/task-record-utils';
import { GrantedPermissionSetResolver } from '../../../../lib/right.service';
import { Task } from '../../../../lib/task/task.service';

@Component({
  selector: 'app-task-record-signature',
  templateUrl: './task-record-signature.component.html',
  styleUrls: ['./task-record-signature.component.scss']
})
export class TaskRecordSignatureComponent implements OnInit, AfterViewInit {

  @Input()
  taskId: number;

  @Input()
  taskRecordId: number;

  @Input()
  taskRecordState: TaskRecordStateMachine.State;

  @Input()
  grantedRights: TaskRecordRightModel = new TaskRecordRightModel(GrantedPermissionSetResolver.empty());

  @Input()
  task?: Task.Task;

  @Input()
  readonly: boolean = false;

  customerSignature?: string;
  userSignature?: string;

  constructor(private taskRecordService: TaskRecordService,
              private toasterService: ToasterService,
              private translateService: TranslateService) {
  }


  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.downloadSignatures();
  }

  signatureSave(picture: string, type: 'user' | 'customer') {
    this.taskRecordService.uploadSignature({
      taskId: this.taskId,
      taskRecordId: this.taskRecordId,
      picture: picture,
      type: type
    }).subscribe(result => {
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutShort,
        type: UiConstants.toastTypeSuccess,
        title: this.translateService.instant(StringKey.FORM_DOCUMENT_SUCCESSFUL_UPLOAD),
      });
    })
  }

  downloadSignatures() {
    this.taskRecordService.downloadCustomerSignature({
      taskRecordId: this.taskRecordId,
      taskId: this.taskId
    }).subscribe(image => {
      this.customerSignature = URL.createObjectURL(image.getBlob());
    });
    this.taskRecordService.downloadUserSignature({
      taskRecordId: this.taskRecordId,
      taskId: this.taskId
    }).subscribe(image => {
      this.userSignature = URL.createObjectURL(image.getBlob());
    });
  }

  showSignature(type: 'user' | 'customer') {
    return this.grantedRights.downloadSignatures.hasRight() &&
      TaskRecordStateMachine.StateMachine.showSignature(this.taskRecordState, this.task, type);
  }

  canEditSignature(type: 'user' | 'customer') {
    return !this.readonly && (this.grantedRights.uploadSignatures.hasRight() &&
      TaskRecordStateMachine.StateMachine.canEditSignature(this.taskRecordState, this.task, type));
  }

}
