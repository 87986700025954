/// <reference types="@types/googlemaps" />
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TransportService } from '../../../lib/transport/transport.service';
import { DetailTab } from '../transport-detail/detail-tab';
import { MapSectionColors } from '../transport-task-list/transport-task-list.component';
import { GoogleMapsLoaderUtil } from '../../../util/google/google-maps-loader.util';
import { DefaultOffsetDateTimeTemplate } from '../../../lib/util/dates';
/* eslint-enable */

@Component({
  selector: 'app-transport-positionlog-map',
  templateUrl: './transport-positionlog-map.component.html',
  styleUrls: ['./transport-positionlog-map.component.scss']
})
export class TransportPositionlogMapComponent implements OnInit, DetailTab {

  private readonly firstMarkerIcon: google.maps.Icon = {url: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png'};
  private readonly lastMarkerIcon: google.maps.Icon = {url: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'};

  @ViewChild('gmap', { static: true }) gmapElement: any;
  map: google.maps.Map;

  @Input()
  transportId: number;

  private sections: google.maps.Polyline[] = [];
  private realRoute: google.maps.Polyline;
  private markers: google.maps.Marker[] = [];
  private readonly dateTemplate: DefaultOffsetDateTimeTemplate;

  constructor(private transportService: TransportService,
  ) {
    this.dateTemplate = new DefaultOffsetDateTimeTemplate('YYYY-MM-DD HH:mm');
  }

  ngOnInit() {
  }

  initMap(completion: () => void) {
    if (!this.map) {
      if (GoogleMapsLoaderUtil.didMapLoad()) {
        this.createMap();
        completion();
      }
      else {
        GoogleMapsLoaderUtil.subscribe(() => {
          this.createMap();
          completion();
        });
      }
    }
    else {
      completion();
    }

  }

  createMap() {
    const mapProp = {
      center: new google.maps.LatLng(47.49801, 19.03991),
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      streetViewControl: false,
      mapTypeControl: false
    };
    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
  }


  initComponent() {
    this.reset();
    this.initMap(() => this.loadData());
  }

  private reset() {
    this.markers.forEach(m => m.setMap(null));
    this.markers = [];
    this.sections.forEach(m => m.setMap(null));
    this.sections = [];
    if (this.realRoute) {
      this.realRoute.setMap(null);
    }
  }

  private loadData() {
    this.transportService.getPositionLogs({id: this.transportId}).subscribe(result => {
      const latlngs: google.maps.LatLng[] = [];
      const bounds = new google.maps.LatLngBounds();
      result.forEach((r, index) => {
        const coord = r.coordinate;
        if (coord) {
          const latlng = new google.maps.LatLng(coord.latitude.toNumber(), coord.longitude.toNumber());
          bounds.extend(latlng);
          const marker = new google.maps.Marker({
            position: latlng,
            map: this.map,
            icon: {
              url: '../../../../assets/img/poi/ic_map_poi_position.svg',
            },
            title: index + '., ' + r.creationTime.format(this.dateTemplate),
          });
          if (index === 0) {
            marker.setIcon(this.firstMarkerIcon);
          }
          if (index === result.length - 1) {
            marker.setIcon(this.lastMarkerIcon);
          }
          latlngs.push(latlng);
          this.markers.push(marker);
        }
      });
      this.realRoute = new google.maps.Polyline({
        strokeColor: 'green',
        map: this.map,
        path: latlngs,
      });
      const map = this.map;
      this.transportService.getRoute({id: this.transportId}).subscribe(result => {
        result.polylines.forEach((p, index) => {
          const section = new google.maps.Polyline({
            path: google.maps.geometry.encoding.decodePath(p),
            strokeColor: MapSectionColors[index % MapSectionColors.length],
            map: this.map
          });
          this.sections.push(section);
        });
        if (!bounds.isEmpty()) {
          google.maps.event.addListenerOnce(this.map, 'idle', function () {
            map.fitBounds(bounds);
            map.panToBounds(bounds);
          });
          map.setZoom(map.getZoom());
        }
        else {

        }
      });
    });
  }
}

