import { Injectable } from '@angular/core';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { Observable } from 'rxjs';
import { ResourceQueryResult } from '../util/services';
import { EmptyMessage, IdentityMessage } from '../util/messages';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DeliveryMethodResourceService extends BaseHttpService {

  query(request: DeliveryMethodResource.QueryRequest):
    Observable<ResourceQueryResult<DeliveryMethodResource.DeliveryMethod>> {
    return this.http.get<ResourceQueryResult<DeliveryMethodResource.DeliveryMethod>>(this.url, this.parseParams(request));
  }

  get(request: DeliveryMethodResource.GetRequest): Observable<DeliveryMethodResource.GetRequest> {
    return this.http.get<DeliveryMethodResource.GetRequest>(this.url + `${request.id}`);
  }

  create(request: DeliveryMethodResource.CreateRequest): Observable<IdentityMessage> {
    return this.http.post<IdentityMessage>(this.url, request);
  }

  update(request: DeliveryMethodResource.UpdateRequest): Observable<EmptyMessage> {
    return this.http.put<EmptyMessage>(this.url + `${request.id}`, request);
  }

  setDisabled(request: DeliveryMethodResource.DisableRequest): Observable<EmptyMessage> {
    return this.http.patch<EmptyMessage>(this.url + `${request.id}/disabled`, request);
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, 'delivery-methods/');
  }

}

export namespace DeliveryMethodResource {

  export interface DeliveryMethod {
    id: number;
    granted_rights?: string[];
    disabled: boolean;
    external_id: string;
    creation_time: string;
    name: string;
    type: string;
    integration_type: string;
    transporter_company?: {
      id: number;
      name: string;
    };
    package_weight_required: boolean;
    max_package_weight_in_gram?: number;
    package_size_required: boolean;
    max_package_size?: DeliveryMethodPackageSizeData;
    max_insurance_price?: DeliveryMethodPriceData;
    max_cash_on_delivery_price?: DeliveryMethodPriceData;
    recipient_email_address_required: boolean;
    recipient_phone_number_required: boolean;
  }

  export interface DeliveryMethodPackageSizeData {
    width_in_meter: string;
    height_in_meter: string;
    depth_in_meter: string;
  }

  export interface DeliveryMethodPriceData {
    currency_code: string;
    amount: string;
  }

  export interface GetRequest {
    id: number;
  }

  export interface CreateRequest {
    external_id: string;
    name: string;
    type: string;
    integration_type: string;
    transporter_company: {
      id: number;
    };
    package_weight_required: boolean;
    max_package_weight_in_gram?: number;
    package_size_required: boolean;
    max_package_size?: DeliveryMethodPackageSizeData;
    max_insurance_price?: DeliveryMethodPriceData;
    max_cash_on_delivery_price?: DeliveryMethodPriceData;
    recipient_email_address_required: boolean;
    recipient_phone_number_required: boolean;
  }

  export interface UpdateRequest extends CreateRequest {
    id: number;
  }

  export interface DisableRequest {
    id: number;
    disabled: boolean;
  }

  export interface QueryRequest {
    fields?: string;
    filter?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
    rights?: string;
    no_progress_bar?: boolean;
  }

}
