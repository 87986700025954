<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]=breadcrumbParents
      [self]="breadcrumbSelf">
    </app-breadcrumb>
    <app-breadcrumb-menu>
      <!-- region ATTACHMENT ZIP DOWNLOAD-->
      <app-breadcrumb-button
        *ngIf="rightModel.masterDataRecordAttachmentRead.hasRight()"
        [iconClass]="'icomoon-download'"
        [titleStringKey]="'ATTACHMENT_DOWNLOAD_TITLE'"
        (click)="downloadAttachmentZip()">
      </app-breadcrumb-button>
      <!-- endregion ATTACHMENT ZIP DOWNLOAD-->
      <!-- region XLSX EXPORT-->
      <app-breadcrumb-button
        *ngIf="rightModel.masterDataRecordExportFile.hasRight() && masterDataId"
        [iconClass]="'icomoon-file-xlsx'"
        [titleStringKey]="'COMMON_EXPORT_XLSX'"
        (click)="exportXls()">
      </app-breadcrumb-button>
      <app-breadcrumb-dropdown
        [titleStringKey]="'COMMON_EXPORT_XLSX'">
        <app-dropdown-item
          *ngIf="rightModel.masterDataRecordImportFile.hasRight() && masterDataId"
          [iconClass]="'icomoon-file-xlsx'"
          [titleStringKey]="'ORDER_XLS_IMPORT_SAMPLE_DOWNLOAD'"
          (click)="exportXlsTemplate()">
        </app-dropdown-item>
      </app-breadcrumb-dropdown>
      <!-- endregion XLSX EXPORT-->
      <!-- region CREATE-->
      <app-breadcrumb-button
        *ngIf="masterdataCreateRightModel.masterDataRecordCreate.hasRight()"
        [iconClass]="'icomoon-add'"
        [titleStringKey]="'COMMON_BUTTON_ADD'"
        uiSref="Admin.MasterDataRecordCreate"
        [uiParams]="{ masterDataId: masterDataId }">
      </app-breadcrumb-button>
      <!-- endregion CREATE-->
      <app-breadcrumb-dropdown
        [titleStringKey]="'COMMON_BUTTON_IMPORT'">
        <!-- region IMPORT-->
        <app-dropdown-item
          *ngIf="rightModel.masterDataRecordImportFile.hasRight() || demoModeEnabled"
          [iconClass]="'icomoon-csv-import'"
          [titleStringKey]="'COMMON_BUTTON_IMPORT'"
          [disabled]="!rightModel.masterDataRecordImportFile.hasRight()"
          (click)="rightModel.masterDataRecordImportFile.hasRight() ? importDialog.toggleDialog() : showDisabledDialog()">
        </app-dropdown-item>
        <!-- endregion IMPORT-->
      </app-breadcrumb-dropdown>
    </app-breadcrumb-menu>
  </div>

</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="card">

    <div class="card-header">
      {{'MASTERDATA_RECORD_PANEL_HEADING_LIST' | translate}}

      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()" title="{{'COMMON_BUTTON_ADD' | translate}}"><i class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>

    <div class="card-body border-bottom" *ngIf="showSearch">
      <responsive-tabset #searchTabs>
        <responsive-tab heading="{{'SEARCH_MODE_SIMPLE' | translate}}" (selectTab)="activeSearchTab = 'simple'" id="simple">
      <div class="row form-group">
        <div class="col-md-3">
          <label class="search-label">{{'MASTERDATA_RECORD_SEARCH_FIELD_ID' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'MASTERDATA_RECORD_SEARCH_FIELD_ID' | translate}}"
                 maxlength="{{UiConstants.maxInputLongLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [textMask]="{mask: InputMask.NATURAL_INTEGER, guide: false}"
                 [(ngModel)]="searchModel.id" name="id" id="id" #id="ngModel">
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'COMMON_NAME' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.name" name="name" id="name" #name="ngModel">
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'MASTERDATA_RECORD_EXTERNAL_ID' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'MASTERDATA_RECORD_EXTERNAL_ID' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.externalId" name="externalId" id="externalId" #externalId="ngModel">
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'MASTERDATA_RECORD_SEARCH_FIELD_DISABLED' | translate}}</label>
          <select class="form-control" [compareWith]="SelectUtils.compareObjects" [(ngModel)]="searchModel.disabled"
                  id="disabled" name="disabled" #disabled="ngModel">
            <option *ngFor="let item of masterdataRecordDisabledItems" [ngValue]="item">{{item.text}}</option>
          </select>
        </div>
      </div>
      <div class="col-md-12 d-flex justify-content-md-end align-items-end px-0">
        <div class="btn-group" role="group">
          <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
            {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
          </button>
          <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
            {{'COMMON_BUTTON_SEARCH' | translate}}
          </button>
        </div>
      </div>
        </responsive-tab>
        <responsive-tab heading="{{'SEARCH_MODE_DQL' | translate}}" (selectTab)="activeSearchTab = 'dql'" id="dql">
          <app-dql-search-container #dqlSearchContainer
                                    (onSearch)="onDqlSearchClicked()"
                                    [dqlStoredQueryArgs]="dqlStoredArgs"
                                    [rightModel]="rightModel"
                                    [dialogContainer]="dqlDialogHolder"></app-dql-search-container>
        </responsive-tab>
      </responsive-tabset>
    </div><!-- /.card-body-->

    <div class="card-body">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-field-sorter [orderField]="MasterDataRecord.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(MasterDataRecord.OrderField.ID)"
                                    (orderChange)="orderBy($event)" [text]="'COMMON_TABLE_HEADER_ID' | translate">
            </app-table-field-sorter>
          </th>
          <th class="table-sorter-header long">
            <app-table-field-sorter [orderField]="MasterDataRecord.OrderField.NAME"
                                    [orderType]="queryModel.getOrderType(MasterDataRecord.OrderField.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_NAME' | translate">
            </app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op [text]="'MASTER_DATA_FILTER_TYPE_CUSTOMER' | translate">
            </app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-field-sorter [orderField]="MasterDataRecord.OrderField.CREATION_TIME"
                                    [orderType]="queryModel.getOrderType(MasterDataRecord.OrderField.CREATION_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_CREATION_TIME' | translate">
            </app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-field-sorter  [orderField]="MasterDataRecord.OrderField.UPDATE_TIME"
                                     [orderType]="queryModel.getOrderType(MasterDataRecord.OrderField.UPDATE_TIME)"
                                     (orderChange)="orderBy($event)"
                                     [text]="'COMMON_TABLE_HEADER_UPDATE_TIME' | translate">
            </app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let model of masterDataRecordList | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }" [disabledTableRow]="model.masterDataRecord.disabled">
          <td class="responsive-table-column hidden-xs-down fit align-middle">{{model.masterDataRecord.masterDataRecordId}}</td>
          <td class="responsive-table-column long align-middle">
            <div class="d-flex">
              <div class="d-flex flex-column justify-content-center">
                <a class="id-text cursor-pointer"
                   href="#" onclick="return false"
                   uiSref="Admin.MasterDataRecordDetail"
                   [uiParams]="{ masterDataId: model.masterDataRecord.masterDataId, masterDataRecordId: model.masterDataRecord.masterDataRecordId }"
                   title="{{'COMMON_BUTTON_DETAILS' | translate}}">
                  {{ model.masterDataRecord.name }}
                </a>
                <span class="text-truncate table-text-subtitle">
                  {{ model.masterDataRecord.externalId }}
                </span>
              </div>
            </div>
          </td>
          <td class="responsive-table-column hidden-sm-down align-middle">
            <div class="d-flex" *ngIf="model.customerRecord">
              <div class="d-flex flex-column justify-content-center">
                <a class="id-text cursor-pointer" *ngIf="rightModel.customerRecordRead.hasRight()"
                   href="#" onclick="return false"
                   uiSref="Admin.CustomerRecordDetail"
                   [uiParams]="{ customerId: model.customerRecord.customerId, customerRecordId: model.customerRecord.customerRecordId }"
                   title="{{'COMMON_BUTTON_DETAILS' | translate}}">
                  {{model.customerRecord.name}}
                </a>
                <span *ngIf="!rightModel.customerRecordRead.hasRight()">{{model.customerRecord.name}}</span>
                <span class="text-truncate table-text-subtitle" *ngIf="model.contactLocation">
                  {{ model.contactLocation.itemName }}
              </span>
              </div>
            </div>
          </td>
          <td class="fit hidden-md-down align-middle">{{model.masterDataRecord.creationTime.toUtcIsoString() | date:'short'}}</td>
          <td class="fit hidden-md-down align-middle">{{model.masterDataRecord.updateTime.toUtcIsoString() | date:'short'}}</td>
          <td class="align-middle">
            <app-table-options-menu>
              <app-dropdown-item
                *ngIf="model.rights.read.hasRight()"
                [iconClass]="'icomoon-detail'"
                [titleStringKey]="'COMMON_BUTTON_DETAILS'"
                class="cursor-pointer"
                uiSref="Admin.MasterDataRecordDetail"
                [uiParams]="{ masterDataId: model.masterDataRecord.masterDataId, masterDataRecordId: model.masterDataRecord.masterDataRecordId }">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="model.rights.update.hasRight()"
                [iconClass]="'icomoon-modify'"
                [titleStringKey]="'COMMON_BUTTON_EDIT'"
                class="cursor-pointer"
                uiSref="Admin.MasterDataRecordEdit"
                [uiParams]="{ masterDataId: model.masterDataRecord.masterDataId, masterDataRecordId: model.masterDataRecord.masterDataRecordId }">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="model.masterDataRecord.disabled && model.rights.disable.hasRight()"
                [iconClass]="'icomoon-enable'"
                [titleStringKey]="'COMMON_BUTTON_ENABLE'"
                [itemType]="DropdownItemType.ENABLE"
                class="cursor-pointer"
                (click)="setDisabled($event, model.masterDataRecord, false)">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="!model.masterDataRecord.disabled && model.rights.disable.hasRight()"
                [iconClass]="'icomoon-disable'"
                [titleStringKey]="'COMMON_BUTTON_DISABLE'"
                [itemType]="DropdownItemType.DISABLE"
                class="cursor-pointer"
                (click)="setDisabled($event, model.masterDataRecord, true)">
              </app-dropdown-item>
            </app-table-options-menu>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-field-sorter [orderField]="MasterDataRecord.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(MasterDataRecord.OrderField.ID)"
                                    (orderChange)="orderBy($event)" [text]="'COMMON_TABLE_HEADER_ID' | translate">
            </app-table-field-sorter>
          </th>
          <th class="table-sorter-header long">
            <app-table-field-sorter [orderField]="MasterDataRecord.OrderField.NAME"
                                    [orderType]="queryModel.getOrderType(MasterDataRecord.OrderField.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_NAME' | translate">
            </app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op [text]="'MASTER_DATA_FILTER_TYPE_CUSTOMER' | translate">
            </app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-field-sorter [orderField]="MasterDataRecord.OrderField.CREATION_TIME"
                                    [orderType]="queryModel.getOrderType(MasterDataRecord.OrderField.CREATION_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_CREATION_TIME' | translate">
            </app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-field-sorter  [orderField]="MasterDataRecord.OrderField.UPDATE_TIME"
                                     [orderType]="queryModel.getOrderType(MasterDataRecord.OrderField.UPDATE_TIME)"
                                     (orderChange)="orderBy($event)"
                                     [text]="'COMMON_TABLE_HEADER_UPDATE_TIME' | translate">
            </app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>
  </div>
</div>

<app-dql-dialog-container #dqlDialogHolder></app-dql-dialog-container>

<app-file-upload-dialog
  [uploadPath]="uploadPath"
  (onResult)="onImportSuccess($event)"
  #importDialog
></app-file-upload-dialog>

