/* eslint-disable */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BreadcrumbParent } from '../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { TranslateService } from '@ngx-translate/core';
import { Calendar, CalendarService } from '../../lib/calendar/calendar.service';
import { QueryResult, ResourceQueryResult } from '../../lib/util/services';
import { CalendarSearchModel, CalendarUtils } from '../../util/calendar/calendar-utils';
import { SettingsService } from '../../lib/settings.service';
import { WeekDay } from 'calendar-utils';
import { TaskRecordService } from '../../lib/task/task-record.service';
import { Set } from 'immutable';
import { Task, TaskService } from '../../lib/task/task.service';
import { ConfigModel } from '../../util/task-record-utils';
import { TaskRecordStateMachine } from '../../lib/task/task-record-statemachine';
import { ConfigurationService } from '../../lib/core-ext/configuration.service';
import { Device, DeviceManagementService } from '../../lib/device-management.service';
import { CustomerRecordService } from '../../lib/customer/customer-record.service';
import { GrantedPermissionSetResolver, RightResolver, RightService } from '../../lib/right.service';
import { RightModel } from '../../app.rights';
import { FormBuilder } from '@angular/forms';
import { MultiselectOptionItem, OptionItem, QueryFieldModel, UiConstants } from '../../util/core-utils';
import { combineLatest, Observable, Subject } from 'rxjs';
import { Angular2Multiselects } from '../../util/multiselect';
import { UIRouter } from '@uirouter/angular';
import { NgbDatePickerParserFormatter } from '../../util/ngb-datepicker';
import { CalendarSearch, CalendarSearchService } from '../../lib/calendar/calendar-search.service';
import { FieldError, FieldErrors } from '../../lib/util/errors';
import { CalendarView } from 'angular-calendar';
import { CustomCalendarView } from '../../fork/angular-calendar/src';
import { CalendarTimelineViewComponent } from './calendar-timeline-view/calendar-timeline-view.component';
import { ProjectRecordService } from '../../lib/project/record/project-record.service';
import { FormRecordSerializer } from '../../util/form/form-utils';
import { TaskRightModel } from '../../util/task-utils';
import { OperationRights } from '../../app.right-definitions';
import { MatDialog } from '@angular/material/dialog';
import {
  TaskRecordCreateDialogMode,
  TaskRecordCreateMaterialDialogComponent
} from '../task/task-record/task-record-create-material-dialog/task-record-create-material-dialog.component';
import { CalendarTaskRecordDetailDialogComponent } from './calendar-task-record-detail-dialog/calendar-task-record-detail-dialog.component';
import {
  CalendarProjectRecordDetailDialogComponent
} from './calendar-project-record-detail-dialog/calendar-project-record-detail-dialog.component';
import { UserMultiselectProvider } from '../../lib/user/user-multiselect.provider';
import { UserGroupMultiselectProvider } from '../../lib/user-group/user-group-multiselect.provider';
import LocalCalendarEvent = CalendarUtils.LocalCalendarEvent;
import CalendarEventType = Calendar.CalendarEventType;
import DaysOfWeek = CalendarUtils.DaysOfWeek;

/* eslint-enable */

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit, OnDestroy {

  private static readonly WEEKEND_DAYS: number[] = [DaysOfWeek.SATURDAY, DaysOfWeek.SUNDAY];

  CustomCalendarView = CustomCalendarView;
  CalendarView = CalendarView;
  UiConstants = UiConstants;
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  searchModel: CalendarSearchModel = new CalendarSearchModel();
  searchResult: CalendarSearch.SearchDataResult;
  showSearch: boolean = false;

  viewDate: Date;
  rangeStartDate: Date;
  rangeEndDate: Date;
  view: CalendarView | CustomCalendarView = CalendarView.Week;
  events: LocalCalendarEvent[] = [];
  activeDayIsOpen: boolean = true;
  locale: string;
  weekStartsOn: number = DaysOfWeek.MONDAY;
  excludeDays: number[] = [];
  refresh: Subject<any> = new Subject();
  taskStates: MultiselectOptionItem<TaskRecordStateMachine.State>[] = [];
  users: MultiselectOptionItem<number>[] = [];
  userGroups: MultiselectOptionItem<number>[] = [];
  devices: MultiselectOptionItem<number>[] = [];
  tasks: TaskItem[] = [];
  tasksForCreate: TaskItem[] = [];
  config: ConfigModel = new ConfigModel();
  rightModel: RightModel = RightModel.empty();

  dayStartHour = 8;
  dayEndHour = 20;

  @ViewChild(CalendarTimelineViewComponent, {static: false}) timelineComponent: CalendarTimelineViewComponent;

  get queryModel(): QueryFieldModel<Calendar.OrderField> {
    return this.timelineComponent.queryModel;
  }

  taskRecordCreateFieldErrors: TaskRecordCreateFieldErrorMap;

  assigneeDropdownSettings: Angular2Multiselects.Settings;
  assigneeSearchDropdownSettings: Angular2Multiselects.Settings;
  localDropdownSettings: Angular2Multiselects.Settings;
  stateDropdownSettings: Angular2Multiselects.Settings;

  constructor(private translateService: TranslateService,
              private calendarService: CalendarService,
              private settingsService: SettingsService,
              private taskRecordService: TaskRecordService,
              private projectRecordService: ProjectRecordService,
              private configService: ConfigurationService,
              private userMultiselectProvider: UserMultiselectProvider,
              private userGroupMultiselectProvider: UserGroupMultiselectProvider,
              private formRecordSerializer: FormRecordSerializer,
              private deviceManagementService: DeviceManagementService,
              private customerRecordService: CustomerRecordService,
              private taskService: TaskService,
              private rightService: RightService,
              private uiRouter: UIRouter,
              private datePickerParserFormatter: NgbDatePickerParserFormatter,
              private calendarSearchService: CalendarSearchService,
              private dialog: MatDialog) {
    this.config = this.configService.getConfigurationModel();
    this.taskRecordCreateFieldErrors = {};
    this.locale = this.settingsService.getLocaleCode();
    this.viewDate = new Date();
    this.loadRightModels(() => {
      this.loadSearch(() => {
        this.showSearch = !this.searchModel.isEmpty();
        this.loadTaskStates();
        this.loadTasks();
        this.loadCalendarEvents();
      });
    });
    this.initDropdownSettings();
    this.dayStartHour = this.config.calendarDisplayStartInMinutes / 60;
    this.dayEndHour = this.config.calendarDisplayEndInMinutes / 60;
  }

  ngOnInit() {
    this.translateService.get('COMMON_CALENDAR').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
  }

  private loadSearch(completion: () => void) {
    const obs: Observable<SearchLoadResult> = combineLatest(
      this.calendarSearchService.getSearchData({}),
      (storedSearchData: CalendarSearch.SearchDataResult) => {
        const result: SearchLoadResult = {
          storedSearchData: storedSearchData
        };
        return result;
      }
    );
    obs.subscribe(
      (result: SearchLoadResult) => {
        this.searchResult = result.storedSearchData;
        this.postInitSearch(result.storedSearchData, completion);
      }
    );
  }

  private loadRightModels(completion: () => void) {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        completion();
      }
    );
  }

  loadCalendarEvents(pageNumber?: number) {
    if (this.view === CustomCalendarView.Timeline) {
      setTimeout(() => this._loadCalendarEvents(pageNumber))
    }
    else {
      this._loadCalendarEvents(pageNumber);
    }
  }

  private _loadCalendarEvents(pageNumber?: number) {
    this.updateDateRange();
    const requestedPage = pageNumber ? pageNumber : this.isTimeline() ? this.queryModel.currentPage : 1;
    this.getQueryObservable(requestedPage).subscribe((result: QueryResult<Calendar.CalendarEvent>) => {
      this.events = result.items.toArray().map(e => CalendarUtils.toCalendarEvent(e, this.config));
      if (this.isTimeline()) {
        this.queryModel.currentPage = requestedPage;
        this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
        this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
      }
    });
  }

  private getQueryObservable(requestedPage: number): Observable<QueryResult<Calendar.CalendarEvent>> {
    if (this.view === CustomCalendarView.Timeline) {
      return this.calendarService.timeline(this.getQueryRequest(requestedPage));
    }
    return this.calendarService.query(this.getQueryRequest(requestedPage));
  }

  private getQueryRequest(requestedPage: number): Calendar.QueryRequest {
    const userGroupIdSet: number[] = this.searchModel.userGroups.map(ug => ug.id);
    const ownerUserIdSet: number[] = this.searchModel.users.map(u => u.id);
    const ownerMobileAppIdSet: number[] = this.searchModel.devices.map(d => d.id);
    const type: CalendarEventType[] = [];
    type.push('TASK_RECORD');
    if (this.view === CustomCalendarView.Timeline) {
      type.push('PROJECT_RECORD');
    }
    if (this.searchModel.availableTime) {
      type.push('AVAILABLE');
    }
    const taskIdSet: number[] = [];
    if (this.searchModel.tasks.length > 0) {
      this.searchModel.tasks.forEach((task) => {
        if (task.id !== null) {
          taskIdSet.push(task.id);
        }
      });
    }
    const taskRecordStates: TaskRecordStateMachine.State[] = [];
    if (this.searchModel.taskRecordStates.length > 0) {
      taskRecordStates.push(...this.searchModel.taskRecordStates.filter(tr => tr.id !== null).map(tr => tr.id!));
    }
    return {
      fromTime: CalendarUtils.getStartOfDay(this.rangeStartDate),
      toTime: CalendarUtils.getEndOfDay(this.rangeEndDate),
      ownerUserGroupId: userGroupIdSet.length > 0 ? Set.of(...userGroupIdSet) : undefined,
      ownerUserId: ownerUserIdSet.length > 0 ? Set.of(...ownerUserIdSet) : undefined,
      ownerMobileAppId: ownerMobileAppIdSet.length > 0 ? Set.of(...ownerMobileAppIdSet) : undefined,
      taskRecordTaskId: taskIdSet.length > 0 ? Set.of(...taskIdSet) : undefined,
      type: Set.of(...type),
      taskRecordStates: taskRecordStates.length > 0 ? Set.of(...taskRecordStates) : undefined,
      assigneeFilter: this.searchModel.assigneeFilters.length > 0 ? Set.of(...this.searchModel.assigneeFilters) : undefined,
      paging: this.isTimeline() ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined
    };
  }

  private loadTasks() {
    this.taskService.query({
      disabled: false,
      rights: Set.of(OperationRights.TASK_RECORD_CREATE)
    }).subscribe(
      (result: QueryResult<Task.Task>) => {
        result.items.forEach((task: Task.Task) => {
          const taskGrantedRights = new TaskRightModel(GrantedPermissionSetResolver.byGrantedRights(task.grantedRights));

          const taskItem = {
            id: task.taskId,
            text: task.name,
            timeTrackingEnabled: task.timeTrackingEnabled,
            defaultEstimatedTimeInMinutes: task.defaultEstimatedTimeInMinutes
          };
          if (this.searchModel.taskId && taskItem.id === this.searchModel.taskId) {
            this.searchModel.tasks.push(taskItem);
          }
          if (taskGrantedRights.taskRecordCreate.hasRight()) {
            this.tasksForCreate.push({
              id: task.taskId,
              text: task.name,
              defaultEstimatedTimeInMinutes: task.defaultEstimatedTimeInMinutes
            });
          }
          this.tasks.push(taskItem);
        })
      }
    );
  }

  private loadTaskStates() {
    this.taskStates = [];
    TaskRecordStateMachine.getOrderedStates(false).forEach(
      (state?: TaskRecordStateMachine.StateObject) => {
        if (state) {
          if (state.isPresentable) {
            const item1 = {
              id: state.state,
              itemName: '...',
              stateObject: state
            };
            this.taskStates.push(item1);
            this.translateService.get(state.stringKey).subscribe((text: string) => {
              item1.itemName = text;
              const searchItem = this.searchModel.taskRecordStates.find(s => s.id === item1.id);
              if (searchItem) {
                searchItem.itemName = text;
              }
            });
          }
        }
      });
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  onViewDateChange() {
    this.activeDayIsOpen = false;
    this.loadCalendarEvents();
  }

  updateDateRange() {
    switch (this.view) {
      case CalendarView.Day:
        this.rangeStartDate = this.viewDate;
        this.rangeEndDate = this.viewDate;
        break;
      case CalendarView.Week:
      case CustomCalendarView.Timeline:
        this.rangeStartDate = CalendarUtils.calculateViewDate(1, this.viewDate, this.view);
        this.rangeEndDate = CalendarUtils.calculateViewDate(this.searchModel.daysInWeek, this.viewDate, this.view);
        break;
    }
  }

  daysInWeekChanged(value: boolean) {
    if (value) { // disable weekends
      this.searchModel.daysInWeek = 5;
    }
    else {
      this.searchModel.daysInWeek = 7;
    }
    this.setExcludeDays();
    this.loadCalendarEvents();
  }

  setExcludeDays() {
    if (this.searchModel.daysInWeek === 5) {
      this.excludeDays = CalendarComponent.WEEKEND_DAYS;
    }
    else {
      this.excludeDays = [];
    }
  }

  switchToDayView(date?: { day: WeekDay }) {
    if (date) {
      this.viewDate = date.day.date;
    }
    this.view = CalendarView.Day;
    this.loadCalendarEvents();
  }

  switchToWeekView() {
    this.view = CalendarView.Week;
    this.loadCalendarEvents();
  }

  switchToTimeline() {
    this.view = CustomCalendarView.Timeline;
    this.loadCalendarEvents();
  }

  showNextView() {
    this.viewDate = CalendarUtils.getNextViewDate(this.viewDate, this.view);
    this.onViewDateChange();
  }

  showPreviousView() {
    this.viewDate = CalendarUtils.getPreviousViewDate(this.viewDate, this.view);
    this.onViewDateChange();
  }

  showTodayView() {
    this.viewDate = new Date();
    this.onViewDateChange();
  }

  onCalendarEventClicked(event: { event: LocalCalendarEvent }) {

    if (event.event.type === 'AVAILABLE') {
      TaskRecordCreateMaterialDialogComponent.openDialog(this.dialog, {
          event: event,
          mode: TaskRecordCreateDialogMode.CALENDAR,
        }, (result) => {
          if (result?.success) {
            this.loadCalendarEvents()
          }
        }
      )
      ;
    }

    if (event.event.taskRecordId) {
      CalendarTaskRecordDetailDialogComponent.openDialog(this.dialog, {taskRecordId: event.event.taskRecordId}, () => {
      });
    }
    else if (event.event.projectRecordId) {
      CalendarProjectRecordDetailDialogComponent.openDialog(this.dialog, {projectRecordId: event.event.projectRecordId}, () => {
      });
    }
  }

  loadAssigneeModels() {
    if (this.config.assigneeWithUser) {
      this.loadUsers();
    }
    if (this.config.assigneeWithMobileApp) {
      this.loadDevices();
    }
  }

  // <editor-fold desc="loaders">
  private loadUsers(q?: string) {
    this.userMultiselectProvider.loadActive(q).subscribe((users: MultiselectOptionItem<number>[]) => {
      this.users = users;
    });
  }

  private loadUserGroups(q?: string) {
    this.userGroupMultiselectProvider.loadActive(q).subscribe((groups: MultiselectOptionItem<number>[]) => {
      this.userGroups = groups;
    });
  }

  private loadDevices() {
    this.deviceManagementService.query({
      disabled: false,
      page_number: 1,
      number_of_items: UiConstants.autocompletePageSize
    }).subscribe((devices: ResourceQueryResult<Device>) => {
      this.devices = [];
      devices.items.forEach((device) => {
        const item = {
          id: device.id,
          itemName: device.name ? device.name : device.application_id
        };
        this.devices.push(item);
      });
    });
  }

  hourSegmentClicked(date: { date: Date }) {
    if (!this.rightModel.calendarEventCreate.hasRight()) {
      return;
    }
    if (this.rightModel.taskRecordCreate.hasRight()) {
      TaskRecordCreateMaterialDialogComponent.openDialog(this.dialog, {
          date: date,
          mode: TaskRecordCreateDialogMode.CALENDAR,
        }, (result) => {
          if (result?.success) {
            this.loadCalendarEvents()
          }
        }
      )
      ;
    }
  }

  createEventClicked() {
    TaskRecordCreateMaterialDialogComponent.openDialog(this.dialog, {
        mode: TaskRecordCreateDialogMode.CALENDAR,
      }, (result) => {
        if (result?.success) {
          this.loadCalendarEvents()
        }
      }
    );
  }

  removeFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.taskRecordCreateFieldErrors, fieldError);
  }

  private initDropdownSettings() {
    this.assigneeDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .labelKey(OptionItem.KEY_TEXT)
      .enableCheckAll(false)
      .build();
    this.assigneeSearchDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .remoteSearch(true)
      .build();
    this.localDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .labelKey(OptionItem.KEY_TEXT)
      .enableCheckAll(true)
      .build();
    this.stateDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .build();
  }

  private postInitSearch(storedSearchData: CalendarSearch.SearchDataResult, completion: () => void) {
    this.searchModel.users = storedSearchData.searchData.userIds;
    this.searchModel.userGroups = storedSearchData.searchData.userGroupIds;
    this.searchModel.devices = storedSearchData.searchData.deviceIds;
    this.searchModel.taskId = storedSearchData.searchData.taskId;
    this.searchModel.availableTime = storedSearchData.searchData.availableTime ?
      storedSearchData.searchData.availableTime : undefined;
    this.searchModel.daysInWeek = storedSearchData.searchData.daysInWeek;
    this.searchModel.taskRecordStates = storedSearchData.searchData.taskStates;
    this.searchModel.assigneeFilters = storedSearchData.searchData.assigneeFilters;
    this.view = storedSearchData.searchData.view;
    this.setExcludeDays();
    completion();
  }

  onSearchClicked() {
    this.loadCalendarEvents();
  }

  onSearchReset() {
    this.calendarSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.searchModel.clear();
          this.loadCalendarEvents();
        });
      }
    );
  }

  private saveSearch() {
    const request = {
      searchData: {
        taskId: this.searchModel.tasks.length > 0 ? this.searchModel.tasks[0].id! : undefined,
        userIds: this.searchModel.users,
        userGroupIds: this.searchModel.userGroups,
        deviceIds: this.searchModel.devices,
        availableTime: this.searchModel.availableTime ? this.searchModel.availableTime : undefined,
        daysInWeek: this.searchModel.daysInWeek,
        view: this.view,
        taskStates: this.searchModel.taskRecordStates,
        assigneeFilters: this.searchModel.assigneeFilters
      }
    };
    this.calendarSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  pageChanged(selectedPage: number) {
    this.loadCalendarEvents(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadCalendarEvents(1);
  }

  private isTimeline(): boolean {
    return this.view === CustomCalendarView.Timeline;
  }

  ngOnDestroy() {
    this.saveSearch();
  }

}

export interface TaskRecordCreateFieldErrorMap {
  external_id?: FieldError;
}

export interface TaskItem extends OptionItem<number> {
  defaultEstimatedTimeInMinutes?: number;
}

interface SearchLoadResult {
  storedSearchData: CalendarSearch.SearchDataResult,
}
