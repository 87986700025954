<div class="animated fadeIn">

  <div class="card">

    <div class="card-header">
      {{'CUSTOMER_RECORD_BILLING_INFO' | translate}}
      <div class="card-actions">
        <a class="btn-setting table-header-action-button d-flex align-items-center px-1 w-auto font-weight-normal"
           (click)="createBillingInfo()"
           *ngIf="!readonly && rightModel.billingInfoCreate.hasRight()"
           title="{{'CUSTOMER_RECORD_BILLING_INFO_CREATE' | translate}}">
          <i class="icomoon pr-1 icomoon-add"></i>{{'CUSTOMER_RECORD_BILLING_INFO_CREATE' | translate}}
        </a>
      </div><!-- /.card-actions -->
    </div>

    <div class="card-body">
      <table class="table table-striped table-bordered mb-0">
        <thead>
        <tr>
          <th class="table-sorter-header">
            <app-table-sorter-no-op
              [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op
              [text]="'COMMON_NAME' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op
              [text]="'CUSTOMER_RECORD_TAX_NUMBER' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op
              [text]="'CUSTOMER_RECORD_EU_TAX_NUMBER' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op
              [text]="'CUSTOMER_RECORD_INVOICE_VAT_STATUS' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op
              [text]="'CUSTOMER_RECORD_BILLING_INFO_INVOICE_ADDRESS' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let info of list" [disabledTableRow]="info.disabled">
          <td class="fit">{{info.id}}</td>
          <td class="responsive-table-column">{{info.name}}</td>
          <td class="responsive-table-column">{{info.taxNumber}}</td>
          <td class="responsive-table-column">{{info.euTaxNumber}}</td>
          <td class="responsive-table-column">{{'INVOICE_VAT_STATUS_' + info.invoiceVatStatus | translate}}</td>
          <td class="responsive-table-column">
            {{formatPostalAddress(info.invoiceAddress)}}
          </td>
          <td class="align-middle">
            <app-table-options-menu>
              <app-dropdown-item
                [iconClass]="'icomoon-detail'"
                [titleStringKey]="'COMMON_BUTTON_DETAILS'"
                class="cursor-pointer"
                (click)="detailBillingInfo(info)">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="!readonly && rightModel.billingInfoUpdate.hasRight()"
                [iconClass]="'icomoon-modify'"
                [titleStringKey]="'COMMON_BUTTON_EDIT'"
                class="cursor-pointer"
                (click)="editBillingInfo(info)">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="!readonly && info.disabled && rightModel.billingInfoDisable.hasRight()"
                [iconClass]="'icomoon-enable'"
                [titleStringKey]="'COMMON_BUTTON_ENABLE'"
                [itemType]="DropdownItemType.ENABLE"
                class="cursor-pointer"
                (click)="setDisabled(info, false)">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="!readonly && !info.disabled && rightModel.billingInfoDisable.hasRight()"
                [iconClass]="'icomoon-disable'"
                [titleStringKey]="'COMMON_BUTTON_DISABLE'"
                [itemType]="DropdownItemType.DISABLE"
                class="cursor-pointer"
                (click)="setDisabled(info, true)">
              </app-dropdown-item>
            </app-table-options-menu>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header">
            <app-table-sorter-no-op
              [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op
              [text]="'COMMON_NAME' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op
              [text]="'CUSTOMER_RECORD_TAX_NUMBER' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op
              [text]="'CUSTOMER_RECORD_EU_TAX_NUMBER' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op
              [text]="'CUSTOMER_RECORD_INVOICE_VAT_STATUS' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op
              [text]="'CUSTOMER_RECORD_BILLING_INFO_INVOICE_ADDRESS' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>

