<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="taskRecordName">
    </app-breadcrumb>
    <div class="breadcrumb-menu d-flex negative-margin-right">
      <app-task-record-breadcrumb-buttons
        class="d-flex"
        [readonly]="false"
        [allowClone]="taskGrantedRights.taskRecordCreate.hasRight()"
        [allowBulkClone]="rightModel.taskRecordCreate.hasRight()"
        [allowStateChange]="true"
        (stateChange)="preStateChange($event)"
        [allowExport]="false"
        [allowInvoice]="baseView.getInvoiceAllowedState()"
        [allowApprovalStart]="canStartApproval()"
        [allowApprovalCancel]="canCancelApproval()"
        (startApproval)="onStartApproval()"
        (invoiceEmitter)="openInvoiceCreateDialog()"
        [taskRecordDefined]="true"
        [settableStateList]="settableStateList"
        [taskId]="taskId"
        [taskRecordId]="taskRecordId"
        [form]="f"
        [sendMessage]="rightModel.taskRecordChatMessageCreate.hasRight()"
        (sendMessageEmitter)="openSendMessageDialog()"
        (bulkClone)="bulkCloneDialog.show([$event])">
      </app-task-record-breadcrumb-buttons>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding-half animated fadeIn">
  <responsive-tabset class="fullscreen_tab">
    <responsive-tab
      heading="{{(appTypeHelperService.isHelpdesk() ? 'BUG_REPORT_PANEL_HEADING_DETAIL' : 'TASK_RECORD_PANEL_HEADING_DETAIL')|translate}}">
      <form class="form-horizontal bordered-row" (ngSubmit)="onSubmit()" #f="ngForm" novalidate>
        <app-new-task-record-base
          headingDictionaryKey="{{(appTypeHelperService.isHelpdesk() ? 'BUG_REPORT_PANEL_HEADING_UPDATE' : 'TASK_RECORD_PANEL_HEADING_UPDATE')|translate}}"
          [readonly]="false"
          [allowClone]="true"
          [allowStateChange]="true"
          [externalIdRequired]="true"
          (stateChange)="preStateChange($event)"
          (settableStateListEmitter)="settableStateListLoader($event)"
          [allowExport]="false"
          [form]="f"
          (customerRecordChanged)="onCustomerRecordChanged()"
          (assigneeUserChanged)="onAssigneeUserChanged()"
          (selectedAddressChanged)="onSelectedAddressChanged()"
          (submitted)="submit($event)"
          [quickUpdateEnabled]="rightModel.taskRecordUpdateQuick.hasRight()"
          (quickUpdate)="quickUpdateClicked()"
        >
        </app-new-task-record-base>
        <app-form-record-container
          [readonly]="false"
          [cloning]="false"
          [form]="f">
        </app-form-record-container>
      </form>
      <div class="container-vertical-padding-half"></div>
      <app-task-record-signature
        [taskRecordId]="taskRecordId"
        [taskId]="taskId"
        [task]="combinedResult?.task"
        [grantedRights]="grantedRights"
        [taskRecordState]="taskRecordState"
        [readonly]="true">
      </app-task-record-signature>
    </responsive-tab>
    <responsive-tab heading="{{'COMMON_PANEL_HEADING_ATTACHMENT'|translate}}">
      <app-task-record-attachment #attachmentView
                                  [taskId]="taskId"
                                  [taskRecordId]="taskRecordId"
                                  [readonly]="false">
      </app-task-record-attachment>
    </responsive-tab>
    <responsive-tab *ngIf="grantedRights.logRead.hasRight()"
                    heading="{{'TASK_RECORD_PANEL_HEADING_HISTORY'|translate}}">
      <app-task-record-history
        [taskId]="taskId"
        [taskRecordId]="taskRecordId">
      </app-task-record-history>
      <app-trigger-history
        *ngIf="rightModel.taskTriggerInstanceRead.hasRight()"
        [triggerService]="getTaskRecordService()"
        [triggerParentId]="taskId"
        [instanceParentId]="taskRecordId"
        [scope]="'TASK'">
      </app-trigger-history>
    </responsive-tab>
    <responsive-tab heading="{{'TASK_RECORD_PANEL_HEADING_PDF'|translate}}"
                    *ngIf="grantedRights.documentRead.hasRight()">
      <app-task-record-pdf
        [taskId]="taskId"
        [taskRecordId]="taskRecordId"
        [taskRecordState]="taskRecordState">
      </app-task-record-pdf>
    </responsive-tab>
    <responsive-tab *ngIf="combinedResult && configurationService.getConfiguration().feature_flags.sms_futar_enabled && !appTypeHelperService.isHelpdesk()"
                    heading="{{'TASK_SMS_RATING'|translate}}"
                    id="smsRating">
      <app-task-record-sms-rating
        [smsRating]="combinedResult.taskRecord.smsRating"
        [smsContent]="combinedResult.taskRecord.smsContent">
      </app-task-record-sms-rating>
    </responsive-tab>
    <responsive-tab heading="{{'TASK_RECORD_PANEL_HEADING_SURVEY'|translate}}"
                    *ngIf="isTaskRecordManagedField('SURVEYS') && rightModel.surveyRead.hasRight() && !appTypeHelperService.isHelpdesk()">
      <app-task-record-survey #surveyView (refresh)="onRefresh()">
      </app-task-record-survey>
    </responsive-tab>
    <responsive-tab heading="{{'COMMON_MESSAGES'|translate}}"
                    *ngIf="grantedRights.chatRead.hasRight()"
                    (selectTab)="taskRecordMessageList.initComponent()">
      <app-chat
        #taskRecordMessageList
        [parentId]="taskId"
        [id]="taskRecordId"
        [messageSendEnable]="grantedRights.chatMessageCreate.hasRight()"
        [chatService]="getChatService()">
      </app-chat>
    </responsive-tab>
    <responsive-tab heading="{{'INVOICE_CREATE_RELATED_TASKS'|translate}}"
                    *ngIf="!appTypeHelperService.isHelpdesk()"
                    (selectTab)="taskRecordRelatedTasks.initComponent()">
      <app-task-record-related-tasks
        #taskRecordRelatedTasks
        [customerRecordId]="customerRecordId"
        [taskId]="taskId"
        [taskRecordId]="taskRecordId">
      </app-task-record-related-tasks>
    </responsive-tab>
    <responsive-tab heading="{{'CUSTOMER_RECORD_PHONE_NUMBER_RELATED'|translate}}"
                    *ngIf="customerRecordId && config.taskRecordPhoneNumberRelatedList && !appTypeHelperService.isHelpdesk()">
      <app-new-task-record-list
        [baseCustomerRecordByPhoneNumberId]="customerRecordId"
        [_headerStringKey]="'CUSTOMER_RECORD_PHONE_NUMBER_RELATED'">
      </app-new-task-record-list>
    </responsive-tab>
    <responsive-tab heading="{{'TASK_RECORD_SUB_TASKS'|translate}}"
                    *ngIf="rightModel.taskRecordSubTaskRead.hasRight() && !appTypeHelperService.isHelpdesk()"
                    (selectTab)="taskRecordSubTask.initComponent()">
      <app-task-record-sub-task
        #taskRecordSubTask
        [taskId]="taskId"
        [taskRecordId]="taskRecordId"
        (onSubTaskCreateEditDialogButtonClicked)="taskRecordSubTaskCreateEditDialog.showSubTaskCreateEditDialog($event)"
        (onSubTaskDetailDialogButtonClicked)="taskRecordSubTaskDetailDialog.showSubTaskDetailDialog($event)"
        (onSubTaskDeleteDialogButtonClicked)="taskRecordSubTaskDeleteDialog.showDeleteSubTaskDialog($event)">
      </app-task-record-sub-task>
    </responsive-tab>
    <responsive-tab heading="{{'INVOICES_LIST_HEADING'|translate}}"
                    *ngIf="rightModel.invoiceRead.hasRight() && !appTypeHelperService.isHelpdesk()"
                    (selectTab)="taskRecordInvoice.initComponent()">
      <app-task-record-invoice
        #taskRecordInvoice
        [taskId]="taskId"
        [taskRecordId]="taskRecordId">
      </app-task-record-invoice>
    </responsive-tab>
    <responsive-tab heading="{{'WORKLOG_PANEL_HEADING_LIST'|translate}}"
                    *ngIf="rightModel.worklogRead.hasRight() && !appTypeHelperService.isHelpdesk()">
      <app-worklog-list
        #worklogList
        [scope]="WorklogScope.TASK_RECORD"
        [resourceParentId]="taskId"
        [editable]="true"
        [createEnabled]="true"
        [resourceId]="taskRecordId">
      </app-worklog-list>
    </responsive-tab>
    <responsive-tab heading="{{'MENU_NAVBAR_MILEAGE_RECORDS'|translate}}"
                    *ngIf="rightModel.mileageRecordRead.hasRight() && mileageRecordEnabled && !appTypeHelperService.isHelpdesk()">
      <app-task-record-mileage-record
        #mileageRecordList
        (onMileageRecordEditClicked)="mileageRecordUpdateDialog.showDialog($event)"
        [rightModel]="rightModel"
        [taskRecordId]="taskRecordId">
      </app-task-record-mileage-record>
    </responsive-tab>
  </responsive-tabset>
</div>

<div bsModal [config]="UiConstants.modalConfig" #sendMessageDialog="bs-modal" class="modal fade" tabindex="-1"
     role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="sendMessageDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'TASK_RECORD_EDIT_SEND_NEW_MESSAGE' | translate}}</h4>
        <button type="button" class="close" (click)="closeSendMessageDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group col-md-12"
             [ngClass]="{ 'has-danger': messageSubmitted && newMessage.length === 0 }">
          <input class="form-control" type="text"
                 placeholder="{{'TASK_RECORD_DETAIL_NEW_MESSAGE' | translate}}"
                 [(ngModel)]="newMessage"
                 [ngClass]="{ 'form-control-danger': messageSubmitted && newMessage.length === 0 }">
          <div class="form-control-feedback" *ngIf="messageSubmitted && newMessage.length === 0">
            {{'TASK_RECORD_DETAIL_MESSAGE_REQUIRED' | translate}}
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeSendMessageDialog()">
          {{'COMMON_BUTTON_CANCEL' | translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="sendMessage()">{{'COMMON_BUTTON_SEND' |
          translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<app-task-record-sub-task-create-edit-dialog
  #taskRecordSubTaskCreateEditDialog
  [taskId]="taskId"
  [taskRecordId]="taskRecordId"
  (subTaskUpdated)="taskRecordSubTask.loadList()">
</app-task-record-sub-task-create-edit-dialog>

<app-task-record-sub-task-detail-dialog
  #taskRecordSubTaskDetailDialog>
</app-task-record-sub-task-detail-dialog>

<app-task-record-sub-task-delete-dialog
  #taskRecordSubTaskDeleteDialog
  [taskId]="taskId"
  [taskRecordId]="taskRecordId"
  (onSubTaskDeleted)="taskRecordSubTask.loadList()">
</app-task-record-sub-task-delete-dialog>

<app-task-record-invoice-create-dialog
  #taskRecordInvoiceCreateDialog
  [taskId]="taskId"
  [taskRecordId]="taskRecordId"
  [selectedCustomerRecord]="baseView.getModel().customer"
  [billingInfo]="baseView.getModel().billingInfo.length === 1 ? baseView.getModel().billingInfo[0] : undefined"
  (onResult)="onInvoiceCreateResult($event)">
</app-task-record-invoice-create-dialog>

<app-mileage-record-edit-dialog
  #mileageRecordUpdateDialog
  (onMileageRecordUpdated)="mileageRecordList.loadList()">
</app-mileage-record-edit-dialog>

<app-task-record-bulk-clone-dialog
  #bulkCloneDialog
  (onSuccess)="onBulkCloneSuccess($event)">
</app-task-record-bulk-clone-dialog>
