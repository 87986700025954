import {Injectable} from "@angular/core";
import {BaseSearch, BaseSearchService} from "../base.search-service";
import {Order, OrderType, Services} from "../util/services";
import {TaskRecord} from "./task-record.service";
import {MultiselectOptionItem, OptionItem, UiConstants, UserGroupItem} from "../../util/core-utils";
import {AssigneeDeviceItem, AssigneeItem} from "../../util/task-record-utils";
import {TaskRecordStateMachine} from "./task-record-statemachine";
import {Dates, LocalDate} from "../util/dates";
import {LoggedInUserStorage} from "../util/storages";
import {Icon} from "./icon.service";

@Injectable()
export class TaskRecordSearchService extends BaseSearchService {

  protected createStorage(): TaskRecordSearch.Storage {
    return new TaskRecordSearch.Storage();
  }

}

export namespace TaskRecordSearch {

  import ExportState = TaskRecord.ExportState;

  export interface SearchDataGetRequest extends BaseSearch.SearchDataGetRequest {
    taskId?: number;
    projectRecordId?: number;
    processId?: number;
    masterDataRecordId?: number;
    customerRecordId?: number;
    baseCustomerRecordByPhoneNumberId?: number;
    taskRecordId?: number;
  }

  export interface SearchDataSetRequest extends BaseSearch.SearchDataSetRequest {
    taskId?: number;
    projectRecordId?: number;
    processId?: number;
    masterDataRecordId?: number;
    customerRecordId?: number;
    baseCustomerRecordByPhoneNumberId?: number;
    taskRecordId?: number;
    searchData: SearchData;
  }

  export interface SearchDataResult extends BaseSearch.SearchDataResult {
    searchData: SearchData;
  }

  export interface SearchData extends BaseSearch.SearchData {
    order: Order<TaskRecord.OrderField>;
    id: string;
    name: string;
    externalId: string;
    userGroup: UserGroupItem[];
    assigneeUser: AssigneeItem[];
    creatorUser: AssigneeItem[];
    contractNumbers: MultiselectOptionItem<number>[];
    assigneeMobileApp: AssigneeDeviceItem[];
    projects: MultiselectOptionItem<number>[];
    processes: MultiselectOptionItem<number>[];
    tasks: TaskItem[];
    customerName: string;
    customerPhoneNumber: string;
    taskRecordStates: MultiselectOptionItem<TaskRecordStateMachine.State>[];
    importance: TaskRecord.TaskRecordImportance | null;
    exportState: ExportState | null;
    deadlineFrom: LocalDate;
    deadlineTo: LocalDate;
    creationTimeFrom: LocalDate;
    creationTimeTo: LocalDate;
    updateTimeFrom: LocalDate;
    updateTimeTo: LocalDate;
    releaseTimeFrom: LocalDate;
    releaseTimeTo: LocalDate;
    agreedTimeFrom: LocalDate;
    agreedTimeTo: LocalDate;
    finishedTimeFrom: LocalDate;
    finishedTimeTo: LocalDate;
    placeOfConsumptionCity: string;
    placeOfConsumptionZipCode: string;
    placeOfConsumptionStreet: string;
    placeOfConsumptionHouseNumber: string;
    emptyIntakePrices: boolean;
    dqlText?: string;
  }

  interface StoredSearchRawData extends BaseSearch.StoredSearchRawData {
    itemsPerPage: number;
    pageNumber: number;
    order: Order<TaskRecord.OrderField>;
    id: string;
    name: string;
    externalId: string;
    userGroup: UserGroupItem[];
    assigneeUser: AssigneeItem[];
    creatorUser: AssigneeItem[];
    contractNumbers: MultiselectOptionItem<number>[];
    assigneeMobileApp: AssigneeDeviceItem[];
    projects: MultiselectOptionItem<number>[];
    processes: MultiselectOptionItem<number>[];
    tasks: TaskItem[];
    customerName: string;
    customerPhoneNumber: string;
    taskRecordStates: MultiselectOptionItem<TaskRecordStateMachine.State>[];
    importance: TaskRecord.TaskRecordImportance | null;
    exportState: ExportState | null;
    deadlineFrom?: string;
    deadlineTo?: string;
    creationTimeFrom?: string;
    creationTimeTo?: string;
    updateTimeFrom?: string;
    updateTimeTo?: string;
    releaseTimeFrom?: string;
    releaseTimeTo?: string;
    agreedTimeFrom?: string;
    agreedTimeTo?: string;
    finishedTimeFrom?: string;
    finishedTimeTo?: string;
    placeOfConsumptionCity: string;
    placeOfConsumptionZipCode: string;
    placeOfConsumptionStreet: string;
    placeOfConsumptionHouseNumber: string;
    emptyIntakePrices: boolean;
    dqlText?: string;
  }

  export class Storage extends BaseSearch.Storage {

    constructor() {
      const defaultSearchData = {
        itemsPerPage: UiConstants.pageNumbers[0],
        pageNumber: 1,
        order: {
          field: TaskRecord.OrderField.ID,
          type: OrderType.DESC
        },
        id: '',
        name: '',
        externalId: '',
        userGroup: [],
        assigneeUser: [],
        creatorUser: [],
        contractNumbers: [],
        assigneeMobileApp: [],
        projects: [],
        processes: [],
        tasks: [],
        customerName: '',
        customerPhoneNumber: '',
        taskRecordStates: [],
        importance: null,
        exportState: null,
        deadlineFrom: Dates.emptyLocalDate(),
        deadlineTo: Dates.emptyLocalDate(),
        creationTimeFrom: Dates.emptyLocalDate(),
        creationTimeTo: Dates.emptyLocalDate(),
        updateTimeFrom: Dates.emptyLocalDate(),
        updateTimeTo: Dates.emptyLocalDate(),
        releaseTimeFrom: Dates.emptyLocalDate(),
        releaseTimeTo: Dates.emptyLocalDate(),
        agreedTimeFrom: Dates.emptyLocalDate(),
        agreedTimeTo: Dates.emptyLocalDate(),
        finishedTimeFrom: Dates.emptyLocalDate(),
        finishedTimeTo: Dates.emptyLocalDate(),
        placeOfConsumptionCity: '',
        placeOfConsumptionZipCode: '',
        placeOfConsumptionStreet: '',
        placeOfConsumptionHouseNumber: '',
        emptyIntakePrices: false,
        dqlText: undefined
      };
      /**
       * Increment this version if you change the SearchData interface.
       */
      const versionNumber: number = 23;
      super(defaultSearchData,
        versionNumber);
    }

    protected generateKey(request: SearchDataGetRequest | SearchDataSetRequest): string {
      if (request.taskId) {
        return 'UserId(' + LoggedInUserStorage.getInstance().getUserId() + ')-Search-TaskRecord-TaskId(' + request.taskId + ')';
      }
      if (request.projectRecordId) {
        return 'UserId(' + LoggedInUserStorage.getInstance().getUserId() + ')-Search-TaskRecord-ProjectRecordId('
          + request.projectRecordId + ')';
      }
      if (request.processId) {
        return 'UserId(' + LoggedInUserStorage.getInstance().getUserId() + ')-Search-TaskRecord-ProcessId(' + request.processId + ')';
      }
      if (request.masterDataRecordId) {
        return 'UserId(' + LoggedInUserStorage.getInstance().getUserId() + ')-Search-TaskRecord-MasterDataRecordId('
          + request.masterDataRecordId + ')';
      }
      if (request.customerRecordId) {
        return 'UserId(' + LoggedInUserStorage.getInstance().getUserId() + ')-Search-TaskRecord-CustomerRecordId('
          + request.customerRecordId + ')';
      }
      if (request.baseCustomerRecordByPhoneNumberId) {
        return 'UserId(' + LoggedInUserStorage.getInstance().getUserId() + ')-Search-TaskRecord-CustomerRecordPNId('
          + request.baseCustomerRecordByPhoneNumberId + ')';
      }
      if (request.taskRecordId) {
        return 'UserId(' + LoggedInUserStorage.getInstance().getUserId() + ')-Search-TaskRecord-TaskRecordId('
          + request.taskRecordId + ')';
      }
      return 'UserId(' + LoggedInUserStorage.getInstance().getUserId() + ')-Search-TaskRecord-Global';
    }

    protected fromRaw(data: StoredSearchRawData): BaseSearch.SearchDataResult {
      const searchData: SearchData = {
        itemsPerPage: data.itemsPerPage,
        pageNumber: data.pageNumber,
        order: data.order,
        id: data.id,
        name: data.name,
        externalId: data.externalId,
        assigneeUser: data.assigneeUser,
        creatorUser: data.creatorUser,
        contractNumbers: data.contractNumbers,
        userGroup: data.userGroup,
        assigneeMobileApp: data.assigneeMobileApp,
        projects: data.projects,
        processes: data.processes,
        tasks: data.tasks,
        customerName: data.customerName,
        customerPhoneNumber: data.customerPhoneNumber,
        taskRecordStates: data.taskRecordStates,
        importance: data.importance,
        exportState: data.exportState,
        deadlineFrom: Services.toLocalDate(data.deadlineFrom),
        deadlineTo: Services.toLocalDate(data.deadlineTo),
        creationTimeFrom: Services.toLocalDate(data.creationTimeFrom),
        creationTimeTo: Services.toLocalDate(data.creationTimeTo),
        updateTimeFrom: Services.toLocalDate(data.updateTimeFrom),
        updateTimeTo: Services.toLocalDate(data.updateTimeTo),
        releaseTimeFrom: Services.toLocalDate(data.releaseTimeFrom),
        releaseTimeTo: Services.toLocalDate(data.releaseTimeTo),
        agreedTimeFrom: Services.toLocalDate(data.agreedTimeFrom),
        agreedTimeTo: Services.toLocalDate(data.agreedTimeTo),
        finishedTimeFrom: Services.toLocalDate(data.finishedTimeFrom),
        finishedTimeTo: Services.toLocalDate(data.finishedTimeTo),
        placeOfConsumptionCity: data.placeOfConsumptionCity,
        placeOfConsumptionZipCode: data.placeOfConsumptionZipCode,
        placeOfConsumptionStreet: data.placeOfConsumptionStreet,
        placeOfConsumptionHouseNumber: data.placeOfConsumptionHouseNumber,
        emptyIntakePrices: data.emptyIntakePrices,
        dqlText: data.dqlText,
      };
      return {
        searchData: searchData
      };
    }

    protected toRaw(data: SearchData): StoredSearchRawData {
      return {
        version: this.VERSION,
        itemsPerPage: data.itemsPerPage,
        pageNumber: data.pageNumber,
        order: data.order,
        id: data.id,
        name: data.name,
        externalId: data.externalId,
        userGroup: data.userGroup,
        assigneeUser: data.assigneeUser,
        creatorUser: data.creatorUser,
        contractNumbers: data.contractNumbers,
        assigneeMobileApp: data.assigneeMobileApp,
        projects: data.projects,
        processes: data.processes,
        tasks: data.tasks,
        customerName: data.customerName,
        customerPhoneNumber: data.customerPhoneNumber,
        taskRecordStates: data.taskRecordStates,
        importance: data.importance,
        exportState: data.exportState,
        deadlineFrom: Services.localDateToString(data.deadlineFrom),
        deadlineTo: Services.localDateToString(data.deadlineTo),
        creationTimeFrom: Services.localDateToString(data.creationTimeFrom),
        creationTimeTo: Services.localDateToString(data.creationTimeTo),
        updateTimeFrom: Services.localDateToString(data.updateTimeFrom),
        updateTimeTo: Services.localDateToString(data.updateTimeTo),
        releaseTimeFrom: Services.localDateToString(data.releaseTimeFrom),
        releaseTimeTo: Services.localDateToString(data.releaseTimeTo),
        agreedTimeFrom: Services.localDateToString(data.agreedTimeFrom),
        agreedTimeTo: Services.localDateToString(data.agreedTimeTo),
        finishedTimeFrom: Services.localDateToString(data.finishedTimeFrom),
        finishedTimeTo: Services.localDateToString(data.finishedTimeTo),
        placeOfConsumptionCity: data.placeOfConsumptionCity,
        placeOfConsumptionZipCode: data.placeOfConsumptionZipCode,
        placeOfConsumptionStreet: data.placeOfConsumptionStreet,
        placeOfConsumptionHouseNumber: data.placeOfConsumptionHouseNumber,
        emptyIntakePrices: data.emptyIntakePrices,
        dqlText: data.dqlText,
      }
        ;
    }

  }

  export class TaskItem extends OptionItem<number> {
    icon: Icon.Icon | undefined;
  }
}
