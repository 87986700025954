/* eslint-disable */
import { Injectable } from '@angular/core';
import { FieldError, } from './util/errors';
import { Map as ImmutableMap, Set } from 'immutable';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from './util/http-services';
import { CompanyResource } from './company/company-resource.service';
import { Order, OrderType, ResourceQueryResult } from './util/services';
import { DisableRequest, EmptyMessage, IdentityMessage, ListQuery } from './util/messages';
import { HistoryLog } from './history-log/history-log.service';
/* eslint-enable */

export namespace UserGroup {

  export enum OrderField {
    ID,
    NAME,
    HISTORY_CREATION_TIME,
    HISTORY_ISSUER_USER_PERSON_NAME,
    HISTORY_MOBILE_APPLICATION_APPLICTAION_ID

  }

  export const DEFAULT_ORDER: Order<UserGroup.OrderField> = {
    type: OrderType.ASC,
    field: UserGroup.OrderField.NAME
  };

  export class Keys {

    private static readonly ID = 'id';
    private static readonly NAME = 'name';
    private static readonly HISTORY_CREATION_TIME = 'creation_time';
    private static readonly HISTORY_ISSUER_USER_PERSON_NAME = 'issuer_user_person_name';
    private static readonly HISTORY_MOBILE_APPLICATION_APPLICTAION_ID = 'mobile_application_application_id';


    private static readonly orderFieldKeyMap: ImmutableMap<UserGroup.OrderField, string> = ImmutableMap.of(
      UserGroup.OrderField.ID, Keys.ID,
      UserGroup.OrderField.NAME, Keys.NAME,
      UserGroup.OrderField.HISTORY_CREATION_TIME, Keys.HISTORY_CREATION_TIME,
      UserGroup.OrderField.HISTORY_ISSUER_USER_PERSON_NAME, Keys.HISTORY_ISSUER_USER_PERSON_NAME,
      UserGroup.OrderField.HISTORY_MOBILE_APPLICATION_APPLICTAION_ID, Keys.HISTORY_MOBILE_APPLICATION_APPLICTAION_ID,

    );

    public static toOrderFieldKey(field: UserGroup.OrderField): string {
      return Keys.orderFieldKeyMap.get(field)!;
    }

  }

}

export interface UserGroupQuery extends ListQuery.Paging, ListQuery.Order, ListQuery.QueryText, ListQuery.Fields {
  id?: string;
  name?: string;
  disabled?: boolean;
  application_type_ids?: string;
  company_id?: string;
  roles?: string;
  no_progress_bar?: boolean;
}

export interface UserGroup {
  id: number;
  name: string;
  external_id: string;
  protected_group: boolean;
  disabled: boolean;
  creation_time: string;
  update_time: string;
  roles: number[];
  application_types: string[];
  company_ids?: number[];
  companies?: UserCompany[];
  user_ids: number[];
  ldap_mapped?: boolean;
}

export interface LdapGroup {
  dn: string;
  id: number;
  natural_id: string;
}

export interface UserCompany {
  id: number;
  name: string;
}

export interface CreateUserGroupRequest {
  name: string;
  external_id?: string;
  roles: string[];
  application_types: string[];
  company_ids?: number[];
}

export interface UpdateUserGroupRequest extends IdentityMessage {
  name: string;
  external_id: string;
  roles: string[];
  application_types: string[];
  company_ids?: number[];
}

export interface UpdateUserIdRequest extends IdentityMessage {
  user_ids: number[];
}

export interface MapLdapGroupRequest {
  id: number;
  ldap_group_ids: Set<number>;
}

export interface UserGroupFieldErrorMap {
  name?: FieldError;
  external_id?: FieldError;
  roles?: FieldError;
  application_types?: FieldError;
  company_ids?: FieldError;
}

export type UserGroupDisabled =
  'ALL' |
  'ACTIVE' |
  'INACTIVE';

export interface HistoryRequest {
  user_group_id: number;
  with_read: boolean;
  q?: string;
  order?: string;
  page_number?: number;
  number_of_items?: number;
}

export interface HistoryItemResource extends HistoryLog.HistoryItemBaseResource {
  type: string;
}

export type UserGroupHistoryType =
  'READ' |
  'CREATE' |
  'UPDATE' |
  'UPDATE_USERS' |
  'DISABLE' |
  'ENABLE' |
  'DELETE';

export class UserGroupHistoryTypeObject {
  type: UserGroupHistoryType;
  stringKey: string;
}

export const userGroupHistoryTypes: UserGroupHistoryTypeObject[] = [
  {type: 'READ', stringKey: 'USER_GROUP_HISTORY_TYPE_READ'},
  {type: 'CREATE', stringKey: 'USER_GROUP_HISTORY_TYPE_CREATE'},
  {type: 'UPDATE', stringKey: 'USER_GROUP_HISTORY_TYPE_UPDATE'},
  {type: 'UPDATE_USERS', stringKey: 'USER_GROUP_HISTORY_TYPE_UPDATE_USERS'},
  {type: 'DISABLE', stringKey: 'USER_GROUP_HISTORY_TYPE_DISABLE'},
  {type: 'ENABLE', stringKey: 'USER_GROUP_HISTORY_TYPE_ENABLE'},
  {type: 'DELETE', stringKey: 'USER_GROUP_HISTORY_TYPE_DELETE'},
];

export interface TypeBasedRoleResource {
  id?: number;
  type_id: number;
  name?: string;
  user_group_ids?: number[];
  privileges: string[];
}

export interface TypeBasedRoleImportDocumentResource {
  name?: string;
  user_group_external_ids?: string[];
  privileges: string[];
}


@Injectable()
export class UserGroupService extends BaseHttpService {

  create(request: CreateUserGroupRequest): Observable<IdentityMessage> {
    return this.http.post<IdentityMessage>(this.url, request);
  }

  query(request: UserGroupQuery): Observable<ResourceQueryResult<UserGroup>> {
    return this.http.get<ResourceQueryResult<UserGroup>>(this.url, this.parseParams(request));
  }

  get(request: IdentityMessage): Observable<UserGroup> {
    return this.http.get<UserGroup>(this.url + `${request.id}`);
  }

  update(request: UpdateUserGroupRequest): Observable<EmptyMessage> {
    return this.http.put(this.url + `${request.id}`, request);
  }

  updateUsers(request: UpdateUserIdRequest): Observable<EmptyMessage> {
    return this.http.patch(this.url + `${request.id}/users`, request);
  }

  delete(request: IdentityMessage): Observable<CompanyResource.Company> {
    return this.http.get<CompanyResource.Company>(this.url + `${request.id}`);
  }

  setDisabled(request: DisableRequest): Observable<EmptyMessage> {
    return this.http.patch(this.url + `${request.id}/disabled`, request);
  }

  history(request: HistoryRequest): Observable<ResourceQueryResult<HistoryItemResource>> {
    return this.http.get<ResourceQueryResult<HistoryItemResource>>(
      this.url + `${request.user_group_id}/history-logs?with_read=${request.with_read}`,
      this.parseParams(request)
    );
  }

  getLdapGroups(request: EmptyMessage): Observable<LdapGroup[]> {
    return this.http.get<LdapGroup[]>(this.url + `ldap`);
  }

  syncLdapGroups(request: EmptyMessage): Observable<EmptyMessage> {
    return this.http.post<EmptyMessage>(this.url + `ldap/sync`, request);
  }

  getMappedLdapGroups(request: IdentityMessage): Observable<LdapGroup[]> {
    return this.http.get<LdapGroup[]>(this.url + `${request.id}/ldap/map`);
  }

  mapLdapGroups(userGroupId: number, ldapGroupIds: Set<number>): Observable<EmptyMessage> {
    return this.http.post<EmptyMessage>(this.url + `${userGroupId}/ldap/map`, ldapGroupIds);
  }

  getTaskBasedRoles(userGroupId: number): Observable<TypeBasedRoleResource[]> {
    return this.http.get<TypeBasedRoleResource[]>(this.url + `${userGroupId}/task-roles`);
  }

  createTaskBasedRole(userGroupId: number, request: TypeBasedRoleResource): Observable<IdentityMessage> {
    return this.http.post<IdentityMessage>(this.url + `${userGroupId}/task-roles`, request);
  }

  updateTaskBasedRole(userGroupId: number, id: number, request: TypeBasedRoleResource): Observable<EmptyMessage> {
    return this.http.put<EmptyMessage>(this.url + `${userGroupId}/task-roles/${id}`, request);
  }

  deleteTaskBasedRole(userGroupId: number, id: number): Observable<EmptyMessage> {
    return this.http.delete<EmptyMessage>(this.url + `${userGroupId}/task-roles/${id}`);
  }

  getCustomerBasedRoles(userGroupId: number): Observable<TypeBasedRoleResource[]> {
    return this.http.get<TypeBasedRoleResource[]>(this.url + `${userGroupId}/customer-roles`);
  }

  createCustomerBasedRole(userGroupId: number, request: TypeBasedRoleResource): Observable<IdentityMessage> {
    return this.http.post<IdentityMessage>(this.url + `${userGroupId}/customer-roles`, request);
  }

  updateCustomerBasedRole(userGroupId: number, id: number, request: TypeBasedRoleResource): Observable<EmptyMessage> {
    return this.http.put<EmptyMessage>(this.url + `${userGroupId}/customer-roles/${id}`, request);
  }

  deleteCustomerBasedRole(userGroupId: number, id: number): Observable<EmptyMessage> {
    return this.http.delete<EmptyMessage>(this.url + `${userGroupId}/customer-roles/${id}`);
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/user-groups/');
  }

}
