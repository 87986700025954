/* eslint-disable */
import { FormRecordGroupView, FormRecordGroupViewContext, FormRecordGroupViewFactory, } from './form-utils';
import { ComponentFactoryResolver, ComponentRef, Injector, } from '@angular/core';
import { FormRecordDefaultGroupComponent } from '../../admin/form/form-record/groups/default/form-record-default-group.component';
import { FormRecordDisabledGroupComponent } from '../../admin/form/form-record/groups/disabled/form-record-disabled-group.component';
/* eslint-enable */

export class DefaultFormRecordGroupViewFactory implements FormRecordGroupViewFactory {

  constructor(
    private injector: Injector,
    private cfr: ComponentFactoryResolver) {
  }

  createView(context: FormRecordGroupViewContext): ComponentRef<FormRecordGroupView> {
    if (context.group.disabled) {
      const cmp = this.cfr.resolveComponentFactory(FormRecordDisabledGroupComponent)
        .create(this.injector);
      cmp.instance.registerContext(context);
      return cmp;
    }
    else {
      const cmp = this.cfr.resolveComponentFactory(FormRecordDefaultGroupComponent)
        .create(this.injector);
      cmp.instance.registerContext(context);
      return cmp;
    }
  }

}
