/* eslint-disable */
import { Injector } from '@angular/core';
import { Ng2StateDeclaration, RootModule, UIRouter } from '@uirouter/angular';
import { StateName, StateRequestParam } from './app.state-names';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { AdminLayoutComponent } from './admin/admin-layout.component';
import { ServerErrorPageComponent } from './error-pages/server-error-page/server-error-page.component';
import { ConnectionErrorPageComponent } from './error-pages/connection-error-page/connection-error-page.component';
import { UserListComponent } from './admin/user/user-list/user-list.component';
import { RegisterComponent } from './register/register.component';
import { UserGroupListComponent } from './admin/usergroup/usergroup-list/usergroup-list.component';
import { UserGroupCreateComponent } from './admin/usergroup/usergroup-create/usergroup-create.component';
import { UserGroupEditComponent } from './admin/usergroup/usergroup-edit/usergroup-edit.component';
import { UserGroupCloneComponent } from './admin/usergroup/usergroup-clone/usergroup-clone.component';
import {
  ApplicationDictionaryListComponent
} from './admin/application-settings/application-dictionary-list/application-dictionary-list.component';
import { ApiUserKeyComponent } from './admin/profile/api-user-key/api-user-key.component';
import { TaskListComponent } from './admin/task/task-list/task-list.component';
import { TaskRecordListComponent } from './admin/task/task-record/task-record-list/task-record-list.component';
import { TaskRecordCreateComponent } from './admin/task/task-record/task-record-create/task-record-create.component';
import { TaskRecordDetailComponent } from './admin/task/task-record/task-record-detail/task-record-detail.component';
import { TaskRecordEditComponent } from './admin/task/task-record/task-record-edit/task-record-edit.component';
import { DocumentHTMLListComponent } from './admin/document/document-html/document-html-list/document-html-list.component';
import { DocumentHTMLCreateComponent } from './admin/document/document-html/document-html-create/document-html-create.component';
import { DocumentHTMLReadComponent } from './admin/document/document-html/document-html-read/document-html-read.component';
import { DocumentHTMLEditComponent } from './admin/document/document-html/document-html-edit/document-html-edit.component';
import { DocumentTextListComponent } from './admin/document/document-text/document-text-list/document-text-list.component';
import { DocumentTextCreateComponent } from './admin/document/document-text/document-text-create/document-text-create.component';
import { DocumentTextEditComponent } from './admin/document/document-text/document-text-edit/document-text-edit.component';
import { DocumentTextReadComponent } from './admin/document/document-text/document-text-read/document-text-read.component';
import { NotificationListComponent } from './admin/notification/notification-list/notification-list.component';
import { NotificationCreateComponent } from './admin/notification/notification-create/notification-create.component';
import { NotificationReadComponent } from './admin/notification/notification-read/notification-read.component';
import { CustomerCreateComponent } from './admin/customer/customer-create/customer-create.component';
import { CustomerListComponent } from './admin/customer/customer-list/customer-list.component';
import { CustomerEditComponent } from './admin/customer/customer-edit/customer-edit.component';
import { DocumentFileListComponent } from './admin/document/document-file/document-file-list/document-file-list.component';
import { DocumentFileCreateComponent } from './admin/document/document-file/document-file-create/document-file-create.component';
import { DocumentFileReadComponent } from './admin/document/document-file/document-file-read/document-file-read.component';
import { DocumentFileEditComponent } from './admin/document/document-file/document-file-edit/document-file-edit.component';
import { ListItemTypeListComponent } from './admin/listItem/list-item-type/list-item-type-list/list-item-type-list.component';
import { ListItemTypeCreateComponent } from './admin/listItem/list-item-type/list-item-type-create/list-item-type-create.component';
import { ListItemTypeEditComponent } from './admin/listItem/list-item-type/list-item-type-edit/list-item-type-edit.component';
import { ListItemListComponent } from './admin/listItem/list-item/list-item-list/list-item-list.component';
import { ListItemCreateComponent } from './admin/listItem/list-item/list-item-create/list-item-create.component';
import { ListItemEditComponent } from './admin/listItem/list-item/list-item-edit/list-item-edit.component';
import { DocumentGroupListComponent } from './admin/document-group/document-group-list/document-group-list.component';
import { DocumentGroupCreateComponent } from './admin/document-group/document-group-create/document-group-create.component';
import { DocumentGroupEditComponent } from './admin/document-group/document-group-edit/document-group-edit.component';
import { AuditLogListComponent } from './admin/statistics/audit-log/audit-log-list/audit-log-list.component';
import { AuditLogDetailComponent } from './admin/statistics/audit-log/audit-log-detail/audit-log-detail.component';
import { ProjectRecordListComponent } from './admin/project/record/list/project-record-list.component';
import { MasterDataListComponent } from './admin/masterdata/masterdata-list/masterdata-list.component';
import { MasterdataCreateComponent } from './admin/masterdata/masterdata-create/masterdata-create.component';
import { MasterdataEditComponent } from './admin/masterdata/masterdata-edit/masterdata-edit.component';
import { StockListComponent } from './admin/stock/stock-list/stock-list.component';
import { StockCreateComponent } from './admin/stock/stock-create/stock-create.component';
import { StockEditComponent } from './admin/stock/stock-edit/stock-edit.component';
import { StockItemListComponent } from './admin/stock-item/stock-item-list/stock-item-list.component';
import { StockItemCreateComponent } from './admin/stock-item/stock-item-create/stock-item-create.component';
import { StockItemEditComponent } from './admin/stock-item/stock-item-edit/stock-item-edit.component';
import { StockLogListContainerComponent } from './admin/stock-log/stock-log-list-container/stock-log-list-container.component';
import { DeviceManagementListComponent } from './admin/application-settings/device-management/device-list/device-list.component';
import { TaskRecordCloneComponent } from './admin/task/task-record/task-record-clone/task-record-clone.component';
import { NoteListComponent } from './admin/note/note-list/note-list.component';
import { TaskCreateComponent } from './admin/task/task-create/task-create.component';
import { TaskEditComponent } from './admin/task/task-edit/task-edit.component';
import { MasterDataRecordEditComponent } from './admin/masterdata/record/masterdata-record-edit/masterdata-record-edit.component';
import { MasterDataRecordCreateComponent } from './admin/masterdata/record/masterdata-record-create/masterdata-record-create.component';
import { MasterDataRecordListComponent } from './admin/masterdata/record/masterdata-record-list/masterdata-record-list.component';
import { MasterDataRecordCloneComponent } from './admin/masterdata/record/masterdata-record-clone/masterdata-record-clone.component';
import { MasterDataRecordDetailComponent } from './admin/masterdata/record/masterdata-record-detail/masterdata-record-detail.component';
import { SurveyListComponent } from './admin/survey/survey-list/survey-list.component';
import { SurveyCreateComponent } from './admin/survey/survey-create/survey-create.component';
import { SurveyEditComponent } from './admin/survey/survey-edit/survey-edit.component';
import { FormTableListComponent } from './admin/form-table/form-table-list/form-table-list.component';
import { FormTableCreateComponent } from './admin/form-table/form-table-create/form-table-create.component';
import { FormTableEditComponent } from './admin/form-table/form-table-edit/form-table-edit.component';
import { SurveyRecordListComponent } from './admin/survey/survey-record-list/survey-record-list.component';
import { SurveyRecordCreateComponent } from './admin/survey/survey-record-create/survey-record-create.component';
import { SurveyRecordDetailComponent } from './admin/survey/survey-record-detail/survey-record-detail.component';
import { PermissionListComponent } from './admin/permission-list/permission-list.component';
import { SurveyRecordEditComponent } from './admin/survey/survey-record-edit/survey-record-edit.component';
import { SurveyRecordCloneComponent } from './admin/survey/survey-record-clone/survey-record-clone.component';
import { NoteDetailComponent } from './admin/note/note-detail/note-detail.component';
import { CustomerRecordListComponent } from './admin/customer/customer-record-list/customer-record-list.component';
import { CustomerRecordCreateComponent } from './admin/customer/customer-record-create/customer-record-create.component';
import { CustomerRecordEditComponent } from './admin/customer/customer-record-edit/customer-record-edit.component';
import { CustomerRecordDetailComponent } from './admin/customer/customer-record-detail/customer-record-detail.component';
import { CustomerRecordCloneComponent } from './admin/customer/customer-record-clone/customer-record-clone.component';
import { DocumentLinkListComponent } from './admin/document/document-link/document-link-list/document-link-list.component';
import { DocumentLinkCreateComponent } from './admin/document/document-link/document-link-create/document-link-create.component';
import { DocumentLinkEditComponent } from './admin/document/document-link/document-link-edit/document-link-edit.component';
import { DocumentLinkReadComponent } from './admin/document/document-link/document-link-read/document-link-read.component';
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import { TaskDashboardComponent } from './admin/task/task-dashboard/task-dashboard.component';
import { InvoiceSettingsBaseComponent } from './admin/invoicing/invoice-settings/invoice-settings-base/invoice-settings-base.component';
import { InvoiceBookListComponent } from './admin/invoicing/invoice-book/invoice-book-list/invoice-book-list.component';
import { InvoiceBookCreateComponent } from './admin/invoicing/invoice-book/invoice-book-create/invoice-book-create.component';
import { InvoiceBookEditComponent } from './admin/invoicing/invoice-book/invoice-book-edit/invoice-book-edit.component';
import { InvoiceListComponent } from './admin/invoicing/invoices/invoice-list/invoice-list.component';
import { InvoiceCreateCloneComponent } from './admin/invoicing/invoices/invoice-create/invoice-create-clone.component';
import { TaskLogListComponent } from './admin/statistics/task-log/task-log-list/task-log-list.component';
import { InvoiceDetailComponent } from './admin/invoicing/invoices/invoice-detail/invoice-detail.component';
import { TaskStatisticsListComponent } from './admin/statistics/task-statistics/task-statistics-list/task-statistics-list.component';
import { TaskStatisticsDetailComponent } from './admin/statistics/task-statistics/task-statistics-detail/task-statistics-detail.component';
import {
  InvoiceStatisticsListComponent
} from './admin/statistics/invoice-statistics/invoice-statistics-list/invoice-statistics-list.component';
import { StockItemDetailComponent } from './admin/stock-item/stock-item-detail/stock-item-detail.component';
import { OrderListComponent } from './admin/order/order-list/order-list.component';
import { OrderDetailComponent } from './admin/order/order-detail/order-detail.component';
import { OrderEditComponent } from './admin/order/order-edit/order-edit.component';
import { LegacyProcessListComponent } from './admin/legacy-process/legacy-process-list/legacy-process-list.component';
import { LegacyWorkflowListComponent } from './admin/legacy-workflow/legacy-workflow-list/legacy-workflow-list.component';
import { LegacyWorkflowCreateComponent } from './admin/legacy-workflow/legacy-workflow-create/legacy-workflow-create.component';
import { LegacyWorkflowEditComponent } from './admin/legacy-workflow/legacy-workflow-edit/legacy-workflow-edit.component';
import {
  LegacyWorkflowTaskEditComponent
} from './admin/legacy-workflow/legacy-workflow-tasks/legacy-workflow-task-edit/legacy-workflow-task-edit.component';
import { LegacyProcessEditComponent } from './admin/legacy-process/legacy-process-edit/legacy-process-edit.component';
import { LegacyProcessTaskEditComponent } from './admin/legacy-process/legacy-process-task-edit/legacy-process-task-edit.component';
import { LegacyProcessTaskDetailComponent } from './admin/legacy-process/legacy-process-task-detail/legacy-process-task-detail.component';
import { LegacyProcessDetailComponent } from './admin/legacy-process/legacy-process-detail/legacy-process-detail.component';
import { StockChangeListComponent } from './admin/stock-change/stock-change-list/stock-change-list.component';
import { StockChangeDetailComponent } from './admin/stock-change/stock-change-detail/stock-change-detail.component';
import { SurveyDashboardComponent } from './admin/survey/survey-dashboard/survey-dashboard.component';
import { StockTakingsListComponent } from './admin/stock-takings/stock-takings-list/stock-takings-list.component';
import { StockTakingsEditComponent } from './admin/stock-takings/stock-takings-edit/stock-takings-edit.component';
import { StockTakingsDetailComponent } from './admin/stock-takings/stock-takings-detail/stock-takings-detail.component';
import {
  StockTakingSheetDetailComponent
} from './admin/stock-takings/stock-taking-sheet/stock-taking-sheet-detail/stock-taking-sheet-detail.component';
import { SimpleDashboardComponent } from './shared/simple-dashboard/simple-dashboard.component';
import { DocumentDashboardComponent } from './admin/document/document-dashboard/document-dashboard.component';
import { CalendarComponent } from './admin/calendar/calendar.component';
import { CompanyListComponent } from './admin/company/company-list/company-list.component';
import { CompanyBaseComponent } from './admin/company/company-base/company-base.component';
import { VehicleListComponent } from './admin/vehicle/vehicle-list/vehicle-list.component';
import { RoleListComponent } from './admin/role/role-list/role-list.component';
import { RoleCreateComponent } from './admin/role/role-create/role-create.component';
import { RoleEditComponent } from './admin/role/role-edit/role-edit.component';
import { VehicleBaseComponent } from './admin/vehicle/vehicle-base/vehicle-base.component';
import { CompanyLocationListComponent } from './admin/company-location/company-location-list/company-location-list.component';
import { CompanyLocationBaseComponent } from './admin/company-location/company-location-base/company-location-base.component';
import { CompanyStockListComponent } from './admin/company-stock/company-stock-list/company-stock-list.component';
import { CompanyStockBaseComponent } from './admin/company-stock/company-stock-base/company-stock-base.component';
import { TransportListComponent } from './admin/transport/transport-list/transport-list.component';
import { TransportDetailComponent } from './admin/transport/transport-detail/transport-detail.component';
import { ShippingDemandListComponent } from './admin/shipping-demand/shipping-demand-list/shipping-demand-list.component';
import { ShipmentGroupListComponent } from './admin/shipment/shipment-group-list/shipment-group-list.component';
import { ShippingDemandEditComponent } from './admin/shipping-demand/shipping-demand-edit/shipping-demand-edit.component';
import { StockItemCategoryListComponent } from './admin/stock-item-category/stock-item-category-list/stock-item-category-list.component';
import { StockItemCategoryBaseComponent } from './admin/stock-item-category/stock-item-category-base/stock-item-category-base.component';
import {
  TableDocumentSchemaListComponent
} from './admin/table-document-schema/table-document-schema-list/table-document-schema-list.component';
import {
  TableDocumentSchemaBaseComponent
} from './admin/table-document-schema/table-document-schema-base/table-document-schema-base.component';
import { TableDocumentListComponent } from './admin/table-document/table-document-list/table-document-list.component';
import { ConfigurationLoadErrorComponent } from './error-pages/configuration-load-error/configuration-load-error.component';
import {
  GeneralPdfTemplateListComponent
} from './admin/general-pdf-template/general-pdf-template-list/general-pdf-template-list.component';
import { ColonnadeInsuranceListComponent } from './admin/client-document/colonnade-insurance-list/colonnade-insurance-list.component';
import { EmailTemplateListComponent } from './admin/email-template/email-template-list/email-template-list.component';
import { EmailTemplateBaseComponent } from './admin/email-template/email-template-base/email-template-base.component';
import { TokenListComponent } from './admin/token/token-list/token-list.component';
import { ApplicationFileListComponent } from './admin/application-file/application-file-list/application-file-list.component';
import { UsergroupDetailComponent } from './admin/usergroup/usergroup-detail/usergroup-detail.component';
import { CompanyStockCreateComponent } from './admin/company-stock/company-stock-create/company-stock-create.component';
import { RoleDetailComponent } from './admin/role/role-detail/role-detail.component';
import { PlaygroundComponent } from './admin/playground/playground.component';
import { EnvironmentConfigComponent } from './admin/environment-config/environment-config.component';
import { LoginDisabledComponent } from './error-pages/login-disabled/login-disabled.component';
import { NotificationTemplateListComponent } from './admin/notification-template/notification-template-list/notification-template-list.component';
import { NotificationTemplateBaseComponent } from './admin/notification-template/notification-template-base/notification-template-base.component';
import {
  GeneralPrinterTemplateListComponent
} from './admin/general-printer-template/general-printer-template-list/general-printer-template-list.component';
import { CustomerDashboardComponent } from './admin/customer/customer-dashboard/customer-dashboard.component';
import { TriggerDetailComponent } from './admin/trigger/trigger-detail/trigger-detail.component';
import { TransportTriggerListComponent } from './admin/transport/transport-trigger-list/transport-trigger-list.component';
import { StockDetailComponent } from './admin/stock/stock-detail/stock-detail.component';
import { ExteriorTransportListComponent } from './admin/exterior-transport/exterior-transport-list/exterior-transport-list.component';
import { ShipmentGroupBaseComponent } from './admin/shipment/shipment-group-base/shipment-group-base.component';
import { ExteriorTransportBaseComponent } from './admin/exterior-transport/exterior-transport-base/exterior-transport-base.component';
import { ShopRenterAuthComponent } from './shoprenter/auth/shoprenter-auth.component';
import { ShopRenterEntryComponent } from './shoprenter/entry/shoprenter-entry.component';
import { ShopRenterShopListComponent } from './admin/shop-renter/shop-renter-shop-list/shop-renter-shop-list.component';
import { ShopRenterShopBaseComponent } from './admin/shop-renter/shop-renter-shop-base/shop-renter-shop-base.component';
import { WorklogListContainerComponent } from './admin/worklog/worklog-list-container/worklog-list-container.component';
import { InvoiceSettingsListComponent } from './admin/invoicing/invoice-settings/invoice-settings-list/invoice-settings-list.component';
import { MileageRecordListComponent } from './admin/mileage-record/mileage-record-list/mileage-record-list.component';
import { MileageRecordDetailComponent } from './admin/mileage-record/mileage-record-detail/mileage-record-detail.component';
import { OrderImportErrorListComponent } from './admin/order-import-error/order-import-error-list/order-import-error-list.component';
import { OrderImportErrorDetailComponent } from './admin/order-import-error/order-import-error-detail/order-import-error-detail.component';
import { UserActivationComponent } from './register/user-activation/user-activation.component';
import {
  ParcelCollectionPointListComponent
} from './admin/parcel-collection-point/parcel-collection-point-list/parcel-collection-point-list.component';
import {
  ParcelCollectionPointBaseComponent
} from './admin/parcel-collection-point/parcel-collection-point-base/parcel-collection-point-base.component';
import { DeliveryMethodListComponent } from './admin/delivery-method/delivery-method-list/delivery-method-list.component';
import { DeliveryMethodBaseComponent } from './admin/delivery-method/delivery-method-base/delivery-method-base.component';
import { UserBaseComponent } from './admin/user/user-base/user-base.component';
import { WorkflowCreateEditorComponent } from './admin/workflow/workflow-create-editor/workflow-create-editor.component';
import { EmailAddressTypeListComponent } from './admin/email-address-type/email-address-type-list/email-address-type-list.component';
import { EmailAddressTypeBaseComponent } from './admin/email-address-type/email-address-type-base/email-address-type-base.component';
import { StartupComponent } from './admin/dashboard/startup.component';
import { WorkflowListComponent } from './admin/workflow/workflow-list/workflow-list.component';
import { WorkflowBaseComponent } from './admin/workflow/workflow-base/workflow-base.component';
import { ProcessListComponent } from './admin/process/process-list/process-list.component';
import { ProcessDetailComponent } from './admin/process/process-detail/process-detail.component';
import { AndroidAppVersionComponent } from './admin/android-app-version/android-app-version.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import {
  RegistrationSettingsContainerComponent
} from './admin/registration/registration-settings-container/registration-settings-container.component';
import {
  HelpdeskLoginRegistrationContainerComponent
} from './helpdesk/login-registration/container/login-registration-container.component';
import { HelpdeskLayoutComponent } from './helpdesk/layout/layout.component';
import { HelpdeskDashboardComponent } from './helpdesk/dashboard/dashboard.component';
import { HelpdeskBugReportListComponent } from './helpdesk/bug-report/list/bug-report-list.component';
import { ProjectListComponent } from './admin/project/list/project-list.component';
import { ProjectBaseComponent } from './admin/project/base/project-base.component';
import { MASTER_DATA_SERVICE } from './lib/masterdata/master-data.service';
import { PROJECT_SERVICE } from './lib/project/project.service';
import { ProjectRecordBaseComponent } from './admin/project/record/base/project-record-base.component';
import { InvoiceSpendingListComponent } from './admin/invoicing/invoice-spending/invoice-spending-list/invoice-spending-list.component';
import { LedgerNumberListComponent } from './admin/ledger/number/ledger-number-list/ledger-number-list.component';
import { InvoiceTagListComponent } from './admin/invoicing/invoice-tag/invoice-tag-list/invoice-tag-list.component';
import { TaskRecordMessageListComponent } from './admin/message/task-record-message-list/task-record-message-list.component';
import { TaskRecordBoardComponent } from './admin/task/task-record/task-record-board/task-record-board.component';
import { GlobalCalendarComponent } from './admin/global-calendar/global-calendar.component';
import {MessageListsComponent} from "./admin/message/message-lists/message-lists.component";
import {DocumentLibraryComponent} from "./admin/document/document-library/document-library.component";
/* eslint-enable */

/**
 * READ THIS BEFORE INSERTING NEW LINES!
 *
 * Each URL must be unique.
 * This is true for arguments too.
 *
 * Wrong example set:
 * /user/list
 * /user/create
 * /user/{id}
 *
 * The problem:
 * The URL '/user/{id}' is not unique because it can be '/user/list' if the 'id' argument is 'list'.
 * We known that the ID is a number, but the ui-router module does not and it produces strange behaviours.
 *
 * Good example set:
 * /users/list
 * /users/create
 * /user/{id}
 *
 * Solution:
 * Postfix with 's' if the ID does not present in the URL.
 * Apply this rule to each level. Example: /task/{id}/records/create and /task/{taskId}/record/{id}/detail
 *
 */
const APP_STATES: Ng2StateDeclaration[] = [

  {name: StateName.CONNECTION_ERROR, url: '/connection-error', component: ConnectionErrorPageComponent},
  {name: StateName.SERVER_ERROR, url: '/server-error', component: ServerErrorPageComponent},
  {name: StateName.CONFIGURATION_LOAD_ERROR, url: '/configuration-load-error', component: ConfigurationLoadErrorComponent},
  {name: StateName.LOGIN_DISABLED, url: '/login-disabled', component: LoginDisabledComponent},

  {
    name: StateName.SHOPRENTER_AUTH,
    url: '/stores/shoprenter/auth?' +
      StateRequestParam.SHOPRENTER_AUTH_SHOP_NAME + '&' +
      StateRequestParam.SHOPRENTER_AUTH_CODE + '&' +
      StateRequestParam.SHOPRENTER_AUTH_TIMESTAMP + '&' +
      StateRequestParam.SHOPRENTER_AUTH_HMAC,
    component: ShopRenterAuthComponent
  },

  {
    name: StateName.SHOPRENTER_ENTRY,
    url: '/stores/shoprenter/entry?' +
      StateRequestParam.SHOPRENTER_AUTH_SHOP_NAME + '&' +
      StateRequestParam.SHOPRENTER_AUTH_CODE + '&' +
      StateRequestParam.SHOPRENTER_AUTH_TIMESTAMP + '&' +
      StateRequestParam.SHOPRENTER_AUTH_HMAC,
    component: ShopRenterEntryComponent
  },

  {
    name: StateName.ACTIVATE_USER,
    url: '/activate-user?' + // NOTE: Changing this URL requires SERVER code change too!
      StateRequestParam.USER_ACTIVATION_TOKEN +
      '&' +
      StateRequestParam.SHOPRENTER_USER_ACTIVATION_TOKEN,
    component: UserActivationComponent
  },


  {name: StateName.LOGIN, url: '/login', component: LoginComponent, params: {fromRegister: false}},
  {name: StateName.REGISTER, url: '/register', component: RegisterComponent},

  {name: StateName.PASSWORD_REQUEST, url: '/request-password', component: PasswordResetComponent},
  {name: StateName.PASSWORD_CHANGE, url: '/reset-password?' + StateRequestParam.PASSWORD_RESET_TOKEN, component: PasswordResetComponent},

  {name: StateName.ADMIN, url: '/admin', component: AdminLayoutComponent, abstract: true},

  {name: StateName.HOME, url: '/home', component: DashboardComponent},
  {name: StateName.STARTUP, url: '/startup', component: StartupComponent},
  {name: StateName.ADMIN_DASHBOARD, url: '/admin-dashboard', component: AdminDashboardComponent},
  {name: StateName.PERMISSION_LIST, url: '/permissions/list', component: PermissionListComponent},

  {name: StateName.USER_LIST, url: '/users/list', component: UserListComponent},
  {name: StateName.USER_CREATE, url: '/users/create', component: UserBaseComponent},
  {name: StateName.USER_EDIT, url: '/user/{id}/edit', component: UserBaseComponent},
  {name: StateName.USER_DETAIL, url: '/user/{id}/detail', component: UserBaseComponent},

  {name: StateName.USER_GROUP_LIST, url: '/user-groups/list', component: UserGroupListComponent},
  {name: StateName.USER_GROUP_CREATE, url: '/user-groups/create', component: UserGroupCreateComponent},
  {name: StateName.USER_GROUP_EDIT, url: '/user-group/{id}/edit', component: UserGroupEditComponent},
  {name: StateName.USER_GROUP_CLONE, url: '/user-group/{id}/clone', component: UserGroupCloneComponent},
  {name: StateName.USER_GROUP_DETAIL, url: '/user-group/{id}/detail', component: UsergroupDetailComponent},

  {name: StateName.APPLICATION_DICTIONARY_LIST, url: '/app-dictionaries/list', component: ApplicationDictionaryListComponent},
  {name: StateName.APPLICATION_FILE_LIST, url: '/application-files/list', component: ApplicationFileListComponent},
  {name: StateName.APPLICATION_DEVICE_MANAGEMENT_LIST, url: '/device-managements/list', component: DeviceManagementListComponent},

  {name: StateName.API_USER_KEY, url: '/profile/api-key', component: ApiUserKeyComponent},

  {name: StateName.TASK_DASHBOARD, url: '/task-dashboard', component: TaskDashboardComponent},

  {name: StateName.TASK_LIST, url: '/tasks/list', component: TaskListComponent},
  {name: StateName.TASK_CREATE, url: '/tasks/create', component: TaskCreateComponent},
  {name: StateName.TASK_EDIT, url: '/task/{id}/edit', component: TaskEditComponent},
  {name: StateName.TASK_TRIGGER_DETAIL, url: '/task/{triggerParentId}/edit/triggers/{id}/detail', component: TriggerDetailComponent},

  {name: StateName.TASK_RECORD_GLOBAL_LIST, url: '/task/records', component: TaskRecordListComponent},
  {name: StateName.TASK_RECORD_LIST, url: '/task/{taskId}/records', component: TaskRecordListComponent},
  {name: StateName.TASK_RECORD_CREATE, url: '/task/{taskId}/records/create', component: TaskRecordCreateComponent},
  {name: StateName.TASK_RECORD_DETAIL, url: '/task/{taskId}/record/{taskRecordId}/detail', component: TaskRecordDetailComponent},
  {name: StateName.TASK_RECORD_EDIT, url: '/task/{taskId}/record/{taskRecordId}/edit', component: TaskRecordEditComponent},
  {name: StateName.TASK_RECORD_CLONE, url: '/task/{taskId}/record/{taskRecordId}/clone', component: TaskRecordCloneComponent},
  {name: StateName.TASK_RECORD_BOARD, url: '/task/board', component: TaskRecordBoardComponent},

  {name: StateName.MESSAGE_LISTS, url: '/messages', component: MessageListsComponent},

  {name: StateName.DOCUMENT_DASHBOARD, url: '/document-dashboard', component: DocumentDashboardComponent},
  {name: StateName.DOCUMENT_LIBRARY, url: '/document-library', component: DocumentLibraryComponent},

  {name: StateName.DOCUMENT_HTML_LIST, url: '/documents/html/list', component: DocumentHTMLListComponent},
  {name: StateName.DOCUMENT_HTML_CREATE, url: '/documents/html/create', component: DocumentHTMLCreateComponent},
  {name: StateName.DOCUMENT_HTML_READ, url: '/document/html/{id}/read', component: DocumentHTMLReadComponent},
  {name: StateName.DOCUMENT_HTML_EDIT, url: '/document/html/{id}/edit', component: DocumentHTMLEditComponent},

  {name: StateName.DOCUMENT_TEXT_LIST, url: '/documents/text/list', component: DocumentTextListComponent},
  {name: StateName.DOCUMENT_TEXT_CREATE, url: '/documents/text/create', component: DocumentTextCreateComponent},
  {name: StateName.DOCUMENT_TEXT_EDIT, url: '/document/text/{id}/edit', component: DocumentTextEditComponent},
  {name: StateName.DOCUMENT_TEXT_READ, url: '/document/text/{id}/read', component: DocumentTextReadComponent},

  {name: StateName.DOCUMENT_LINK_LIST, url: '/documents/link/list', component: DocumentLinkListComponent},
  {name: StateName.DOCUMENT_LINK_CREATE, url: '/documents/link/create', component: DocumentLinkCreateComponent},
  {name: StateName.DOCUMENT_LINK_EDIT, url: '/document/link/{id}/edit', component: DocumentLinkEditComponent},
  {name: StateName.DOCUMENT_LINK_READ, url: '/document/link/{id}/read', component: DocumentLinkReadComponent},

  {name: StateName.NOTIFICATION_LIST, url: '/notifications/list', component: NotificationListComponent},
  {name: StateName.NOTIFICATION_CREATE, url: '/notifications/create', component: NotificationCreateComponent},
  {name: StateName.NOTIFICATION_READ, url: '/notification/{id}/read', component: NotificationReadComponent},

  {name: StateName.CUSTOMER_LIST, url: '/customers/list', component: CustomerListComponent},
  {name: StateName.CUSTOMER_CREATE, url: '/customers/create/', component: CustomerCreateComponent},
  {name: StateName.CUSTOMER_EDIT, url: '/customer/{id}/edit', component: CustomerEditComponent},

  {
    name: StateName.CUSTOMER_DASHBOARD,
    url: '/customer/records/dashboard',
    component: CustomerDashboardComponent
  },
  {
    name: StateName.CUSTOMER_RECORD_GLOBAL_LIST,
    url: '/customer/records/list',
    component: CustomerRecordListComponent
  },
  {
    name: StateName.CUSTOMER_RECORD_LIST,
    url: '/customer/{customerId}/records/list',
    component: CustomerRecordListComponent
  },
  {
    name: StateName.CUSTOMER_RECORD_CREATE,
    url: '/customer/{customerId}/records/create',
    component: CustomerRecordCreateComponent
  },
  {
    name: StateName.CUSTOMER_RECORD_EDIT,
    url: '/customer/{customerId}/record/{customerRecordId}/edit',
    component: CustomerRecordEditComponent
  },
  {
    name: StateName.CUSTOMER_RECORD_DETAIL,
    url: '/customer/{customerId}/record/{customerRecordId}/detail',
    component: CustomerRecordDetailComponent
  },
  {
    name: StateName.CUSTOMER_RECORD_CLONE,
    url: '/customer/{customerId}/record/{customerRecordId}/clone',
    component: CustomerRecordCloneComponent
  },

  {name: StateName.DOCUMENT_FILE_LIST, url: '/documents/list', component: DocumentFileListComponent},
  {name: StateName.DOCUMENT_FILE_CREATE, url: '/documents/create', component: DocumentFileCreateComponent},
  {name: StateName.DOCUMENT_FILE_READ, url: '/document/{id}/read', component: DocumentFileReadComponent},
  {name: StateName.DOCUMENT_FILE_EDIT, url: '/document/{id}/edit', component: DocumentFileEditComponent},

  {name: StateName.LIST_ITEM_TYPE_LIST, url: '/list-item-types/list', component: ListItemTypeListComponent},
  {name: StateName.LIST_ITEM_TYPE_CREATE, url: '/list-item-types/create', component: ListItemTypeCreateComponent},
  {name: StateName.LIST_ITEM_TYPE_EDIT, url: '/list-item-type/{id}/edit', component: ListItemTypeEditComponent},

  {name: StateName.LIST_ITEM_LIST, url: '/list-items/list', component: ListItemListComponent},
  {name: StateName.LIST_ITEM_CREATE, url: '/list-items/create', component: ListItemCreateComponent},
  {name: StateName.LIST_ITEM_EDIT, url: '/list-item/{id}/edit', component: ListItemEditComponent},

  {name: StateName.DOCUMENT_GROUP_LIST, url: '/document-groups/list', component: DocumentGroupListComponent},
  {name: StateName.DOCUMENT_GROUP_CREATE, url: '/document-groups/create', component: DocumentGroupCreateComponent},
  {name: StateName.DOCUMENT_GROUP_EDIT, url: '/document-group/{id}/edit', component: DocumentGroupEditComponent},

  {name: StateName.AUDIT_LOG_LIST, url: '/statistics/audit-log/list', component: AuditLogListComponent},
  {name: StateName.AUDIT_LOG_DETAIL, url: '/statistics/audit-log/{id}/details', component: AuditLogDetailComponent},
  {name: StateName.TASK_LOG_LIST, url: '/statistics/task-log/list', component: TaskLogListComponent},

  {name: StateName.PROJECT_LIST, url: '/projects/list', component: ProjectListComponent},
  {name: StateName.PROJECT_CREATE, url: '/project/create', component: ProjectBaseComponent},
  {name: StateName.PROJECT_EDIT, url: '/project/{id}/edit', component: ProjectBaseComponent},
  {name: StateName.PROJECT_DETAIL, url: '/project/{id}/detail', component: ProjectBaseComponent},

  {
    name: StateName.PROJECT_DASHBOARD,
    url: '/project-dashboard',
    component: SimpleDashboardComponent,
    params: {
      countService: PROJECT_SERVICE,
      destination: StateName.PROJECT_RECORD_LIST,
      headerKey: 'MENU_NAVBAR_PROJECT_RECORD'
    }
  },
  {name: StateName.PROJECT_RECORD_LIST, url: '/project/{id}/records/list', component: ProjectRecordListComponent},
  {name: StateName.PROJECT_RECORD_CREATE, url: '/project/{projectId}/records/create', component: ProjectRecordBaseComponent},
  {name: StateName.PROJECT_RECORD_EDIT, url: '/project/{projectId}/records/{id}/edit', component: ProjectRecordBaseComponent},
  {
    name: StateName.PROJECT_RECORD_DETAIL,
    url: '/project/{projectId}/records/{id}/detail',
    component: ProjectRecordBaseComponent
  },

  {name: StateName.MASTERDATA_LIST, url: '/master-datas/list', component: MasterDataListComponent},
  {name: StateName.MASTERDATA_CREATE, url: '/master-datas/create', component: MasterdataCreateComponent},
  {name: StateName.MASTERDATA_EDIT, url: '/master-data/{id}/edit', component: MasterdataEditComponent},
  {
    name: StateName.MASTERDATA_DASHBOARD,
    url: '/master-data-dashboard',
    component: SimpleDashboardComponent,
    params: {
      countService: MASTER_DATA_SERVICE,
      destination: StateName.MASTERDATA_RECORD_LIST,
      headerKey: 'MENU_NAVBAR_MASTERDATA_RECORD'
    }
  },

  {
    name: StateName.MASTERDATA_RECORD_LIST,
    url: '/master-data/{id}/records/list',
    component: MasterDataRecordListComponent
  },
  {
    name: StateName.MASTERDATA_RECORD_CREATE,
    url: '/master-data/{masterDataId}/records/create',
    component: MasterDataRecordCreateComponent
  },
  {
    name: StateName.MASTERDATA_RECORD_EDIT,
    url: '/master-data/{masterDataId}/record/{masterDataRecordId}/edit',
    component: MasterDataRecordEditComponent
  },
  {
    name: StateName.MASTERDATA_RECORD_DETAIL,
    url: '/master-data/{masterDataId}/record/{masterDataRecordId}/detail',
    component: MasterDataRecordDetailComponent
  },
  {
    name: StateName.MASTERDATA_RECORD_CLONE,
    url: '/master-data/{masterDataId}/record/{masterDataRecordId}/clone',
    component: MasterDataRecordCloneComponent
  },

  {name: StateName.STOCK_LIST, url: '/stocks/list', component: StockListComponent},
  {name: StateName.STOCK_CREATE, url: '/stocks/create', component: StockCreateComponent},
  {name: StateName.STOCK_EDIT, url: '/stock/{id}/edit', component: StockEditComponent},
  {
    name: StateName.STOCK_EDIT_WITH_STOCK_ITEM_DETAIL,
    url: '/stock/{stockId}/edit/stock-item/{id}/detail',
    component: StockItemDetailComponent
  },
  {name: StateName.STOCK_DETAIL, url: '/stock/{id}/detail', component: StockDetailComponent},
  {
    name: StateName.STOCK_DETAIL_WITH_STOCK_ITEM_DETAIL,
    url: '/stock/{stockId}/detail/stock-item/{id}/detail',
    component: StockItemDetailComponent
  },

  {name: StateName.STOCK_ITEM_LIST, url: '/stock-items/list', component: StockItemListComponent},
  {name: StateName.STOCK_ITEM_CREATE, url: '/stock-items/{stockItemType}/create', component: StockItemCreateComponent},
  {name: StateName.STOCK_ITEM_EDIT, url: '/stock-item/{id}/edit', component: StockItemEditComponent},
  {name: StateName.STOCK_ITEM_DETAIL, url: '/stock-item/{id}/detail', component: StockItemDetailComponent},

  {name: StateName.STOCK_ITEM_CATEGORY_LIST, url: '/stock-item-category/list', component: StockItemCategoryListComponent},
  {name: StateName.STOCK_ITEM_CATEGORY_CREATE, url: '/stock-item-category/create', component: StockItemCategoryBaseComponent},
  {name: StateName.STOCK_ITEM_CATEGORY_EDIT, url: '/stock-item-category/{id}/edit', component: StockItemCategoryBaseComponent},
  {name: StateName.STOCK_ITEM_CATEGORY_DETAIL, url: '/stock-item-category/{id}/detail', component: StockItemCategoryBaseComponent},

  {name: StateName.STOCK_LOG_LIST, url: '/stock-logs/list', component: StockLogListContainerComponent},

  {name: StateName.SURVEY_DASHBOARD, url: '/survey-dashboard', component: SurveyDashboardComponent},

  {name: StateName.SURVEY_LIST, url: '/surveys/list', component: SurveyListComponent},
  {name: StateName.SURVEY_CREATE, url: '/surveys/create', component: SurveyCreateComponent},
  {name: StateName.SURVEY_EDIT, url: '/survey/{id}/edit', component: SurveyEditComponent},

  {name: StateName.FORM_TABLE_LIST, url: '/form-tables/list', component: FormTableListComponent},
  {name: StateName.FORM_TABLE_CREATE, url: '/form-tables/create', component: FormTableCreateComponent},
  {name: StateName.FORM_TABLE_EDIT, url: '/form-table/{id}/edit', component: FormTableEditComponent},

  {name: StateName.SURVEY_RECORD_LIST, url: '/survey/{id}/records/list', component: SurveyRecordListComponent},
  {name: StateName.SURVEY_RECORD_CREATE, url: '/survey/{surveyId}/records/create', component: SurveyRecordCreateComponent},
  {name: StateName.SURVEY_RECORD_DETAIL, url: '/survey/{surveyId}/record/{id}/detail', component: SurveyRecordDetailComponent},
  {name: StateName.SURVEY_RECORD_EDIT, url: '/survey/{surveyId}/record/{id}/edit', component: SurveyRecordEditComponent},
  {name: StateName.SURVEY_RECORD_CLONE, url: '/survey/{surveyId}/record/{id}/clone', component: SurveyRecordCloneComponent},

  {name: StateName.NOTE_LIST, url: '/notes/list', component: NoteListComponent},
  {name: StateName.NOTE_DETAIL, url: '/notes/{id}/detail', component: NoteDetailComponent},

  {name: StateName.INVOICE_BOOK_LIST, url: '/invoicing/book/list', component: InvoiceBookListComponent},
  {name: StateName.INVOICE_BOOK_CREATE, url: '/invoicing/book/create', component: InvoiceBookCreateComponent},
  {name: StateName.INVOICE_BOOK_EDIT, url: '/invoicing/book/{id}/edit', component: InvoiceBookEditComponent},
  {name: StateName.INVOICE_SETTINGS_LIST, url: '/invoicing/profiles/list', component: InvoiceSettingsListComponent},
  {name: StateName.INVOICE_SETTINGS_CREATE, url: '/invoicing/profiles/{type}/create', component: InvoiceSettingsBaseComponent},
  {name: StateName.INVOICE_SETTINGS_EDIT, url: '/invoicing/profiles/{id}/edit', component: InvoiceSettingsBaseComponent},
  {name: StateName.INVOICE_SETTINGS_DETAIL, url: '/invoicing/profiles/{id}/detail', component: InvoiceSettingsBaseComponent},
  {name: StateName.INVOICE_LIST, url: '/invoicing/invoices/list', component: InvoiceListComponent},
  {name: StateName.INVOICE_CREATE, url: '/invoicing/invoices/{type}/create', component: InvoiceCreateCloneComponent},
  {name: StateName.INVOICE_CLONE, url: '/invoicing/invoices/{type}/{id}/clone', component: InvoiceCreateCloneComponent},
  {name: StateName.INVOICE_DETAIL, url: '/invoicing/invoices/{id}/detail', component: InvoiceDetailComponent},
  {name: StateName.INVOICE_SPENDING_LIST, url: '/invoicing/spendings/list', component: InvoiceSpendingListComponent},
  {name: StateName.LEDGER_NUMBER_LIST, url: '/ledger/ledger-number/list', component: LedgerNumberListComponent},
  {name: StateName.INVOICE_TAG_LIST, url: '/invoiceing/invoice-tag/list', component: InvoiceTagListComponent},

  {name: StateName.TASK_STATISTICS_LIST, url: '/statistics/task-statistics/list', component: TaskStatisticsListComponent},
  {
    name: StateName.TASK_STATISTICS_DETAIL,
    url: '/statistics/task-statistics/{taskId}-{userProfileId}/detail',
    component: TaskStatisticsDetailComponent
  },

  {name: StateName.INVOICE_STATISTICS_LIST, url: '/statistics/invoice-statistics/list', component: InvoiceStatisticsListComponent},

  {name: StateName.ORDER_LIST, url: '/orders/list', component: OrderListComponent},
  {name: StateName.ORDER_DETAIL, url: '/orders/{id}/detail', component: OrderDetailComponent},
  {name: StateName.ORDER_DETAIL_WITH_PROCESS_EDIT, url: '/process/{processId}/edit/orders/{id}', component: OrderDetailComponent},
  {name: StateName.ORDER_DETAIL_WITH_PROCESS_DETAIL, url: '/process/{processId}/detail/orders/{id}', component: OrderDetailComponent},
  {name: StateName.ORDER_EDIT, url: '/orders/{id}/edit', component: OrderEditComponent},

  {name: StateName.LEGACY_PROCESS_LIST, url: '/legacy-processes/list', component: LegacyProcessListComponent},
  {name: StateName.LEGACY_PROCESS_EDIT, url: '/legacy-processes/{id}/edit', component: LegacyProcessEditComponent},
  {name: StateName.LEGACY_PROCESS_DETAIL, url: '/legacy-processes/{id}/detail', component: LegacyProcessDetailComponent},

  {
    name: StateName.LEGACY_PROCESS_TASK_EDIT,
    url: '/legacy-processes/{processId}/tasks/{id}/edit',
    component: LegacyProcessTaskEditComponent
  },
  {
    name: StateName.LEGACY_PROCESS_TASK_DETAIL,
    url: '/legacy-processes/{processId}/tasks/{id}/detail',
    component: LegacyProcessTaskDetailComponent
  },

  {name: StateName.PROCESS_LIST, url: '/processes/list', component: ProcessListComponent},
  {name: StateName.PROCESS_DETAIL, url: '/processes/{id}/detail', component: ProcessDetailComponent},

  {name: StateName.LEGACY_WORKFLOW_LIST, url: '/legacy-workflow/list', component: LegacyWorkflowListComponent},
  {name: StateName.LEGACY_WORKFLOW_CREATE, url: '/legacy-workflow/create', component: LegacyWorkflowCreateComponent},
  {name: StateName.LEGACY_WORKFLOW_EDIT, url: '/legacy-workflow/{id}/edit', component: LegacyWorkflowEditComponent},
  {
    name: StateName.LEGACY_WORKFLOW_TASK_EDIT,
    url: '/legacy-workflow/{workflowId}/tasks/{id}/edit',
    component: LegacyWorkflowTaskEditComponent
  },

  {name: StateName.WORKFLOW_CREATE_EDITOR, url: '/workflow/create/editor', component: WorkflowCreateEditorComponent},

  {name: StateName.WORKFLOW_LIST, url: '/workflow/list', component: WorkflowListComponent},
  {name: StateName.WORKFLOW_EDIT, url: '/workflow/{id}/edit', component: WorkflowBaseComponent, params: {fromDetail: false}},
  {name: StateName.WORKFLOW_DETAIL, url: '/workflow/{id}/detail', component: WorkflowBaseComponent},

  {name: StateName.STOCK_CHANGE_LIST, url: '/stock-change/list', component: StockChangeListComponent},
  {name: StateName.STOCK_CHANGE_DETAIL, url: '/stock-change/{id}/detail', component: StockChangeDetailComponent},

  {name: StateName.STOCK_TAKINGS_LIST, url: '/stock-takings', component: StockTakingsListComponent},
  {name: StateName.STOCK_TAKINGS_EDIT, url: '/stock-takings/{id}/edit', component: StockTakingsEditComponent},
  {name: StateName.STOCK_TAKINGS_DETAIL, url: '/stock-takings/{id}/detail', component: StockTakingsDetailComponent},

  {
    name: StateName.STOCK_TAKING_SHEET_DETAIL, url: '/stock-takings/{stockTakingId}/sheets/{id}', component: StockTakingSheetDetailComponent
  },

  {name: StateName.CALENDAR, url: '/calendar', component: CalendarComponent},
  {name: StateName.GLOBAL_CALENDAR, url: '/global-calendar', component: GlobalCalendarComponent},

  {name: StateName.COMPANY_LIST, url: '/companies', component: CompanyListComponent},
  {name: StateName.COMPANY_CREATE, url: '/companies/create', component: CompanyBaseComponent},
  {name: StateName.COMPANY_EDIT, url: '/companies/{id}/edit', component: CompanyBaseComponent},
  {name: StateName.COMPANY_DETAIL, url: '/companies/{id}/detail', component: CompanyBaseComponent},

  {name: StateName.VEHICLE_LIST, url: '/vehicles', component: VehicleListComponent},
  {name: StateName.VEHICLE_CREATE, url: '/vehicles/create', component: VehicleBaseComponent},
  {name: StateName.VEHICLE_EDIT, url: '/vehicles/{id}/edit', component: VehicleBaseComponent},
  {name: StateName.VEHICLE_DETAIL, url: '/vehicles/{id}/detail', component: VehicleBaseComponent},

  {name: StateName.ROLE_LIST, url: '/roles/list', component: RoleListComponent},
  {name: StateName.ROLE_CREATE, url: '/roles/create', component: RoleCreateComponent},
  {name: StateName.ROLE_EDIT, url: '/roles/{id}/edit', component: RoleEditComponent},
  {name: StateName.ROLE_DETAIL, url: '/roles/{id}/detail', component: RoleDetailComponent},

  {name: StateName.COMPANY_LOCATION_LIST, url: '/company-locations', component: CompanyLocationListComponent},
  {name: StateName.COMPANY_LOCATION_CREATE, url: '/company-locations/create', component: CompanyLocationBaseComponent},
  {name: StateName.COMPANY_LOCATION_EDIT, url: '/company-locations/{id}/edit', component: CompanyLocationBaseComponent},
  {name: StateName.COMPANY_LOCATION_DETAIL, url: '/company-locations/{id}/detail', component: CompanyLocationBaseComponent},

  {name: StateName.COMPANY_STOCK_LIST, url: '/company-stocks', component: CompanyStockListComponent},
  {name: StateName.COMPANY_STOCK_CREATE, url: '/company-stocks/create', component: CompanyStockCreateComponent},
  {name: StateName.COMPANY_STOCK_EDIT, url: '/company-stocks/{id}/edit', component: CompanyStockBaseComponent},
  {name: StateName.COMPANY_STOCK_DETAIL, url: '/company-stocks/{id}/detail', component: CompanyStockBaseComponent},

  {name: StateName.TRANSPORT_LIST, url: '/transports', component: TransportListComponent},
  {name: StateName.TRANSPORT_DETAIL, url: '/transports/{id}/detail/', component: TransportDetailComponent},
  {name: StateName.TRANSPORT_DETAIL_TAB, url: '/transports/{id}/detail/{tabId}', component: TransportDetailComponent},
  {name: StateName.TRANSPORT_TRIGGER_LIST, url: '/administration/transport-triggers', component: TransportTriggerListComponent},
  {name: StateName.TRANSPORT_TRIGGER_DETAIL, url: '/administration/transport-triggers/{id}/detail', component: TriggerDetailComponent},

  {name: StateName.SHIPPING_DEMAND_LIST, url: '/shipping-demands', component: ShippingDemandListComponent},
  {name: StateName.SHIPPING_DEMAND_CREATE, url: '/shipping-demands/create', component: ShippingDemandEditComponent},
  {name: StateName.SHIPPING_DEMAND_EDIT, url: '/shipping-demands/{id}/edit', component: ShippingDemandEditComponent},
  {name: StateName.SHIPPING_DEMAND_DETAIL, url: '/shipping-demands/{id}/detail', component: ShippingDemandEditComponent},
  {name: StateName.SHIPPING_DEMAND_CLONE, url: '/shipping-demands/{id}/clone', component: ShippingDemandEditComponent},

  {name: StateName.SHIPMENT_LIST, url: '/shipments', component: ShipmentGroupListComponent},
  {name: StateName.SHIPMENT_EDIT, url: '/shipments/{id}/edit', component: ShipmentGroupBaseComponent},
  {name: StateName.SHIPMENT_DETAIL, url: '/shipments/{id}/detail', component: ShipmentGroupBaseComponent},

  {name: StateName.COLONNADE_INSURANCE_LIST, url: '/client-document/colonnade', component: ColonnadeInsuranceListComponent},

  {name: StateName.EMAIL_TEMPLATE_LIST, url: '/administration/email-templates', component: EmailTemplateListComponent},
  {name: StateName.EMAIL_TEMPLATE_CREATE, url: '/administration/email-templates/create', component: EmailTemplateBaseComponent},
  {name: StateName.EMAIL_TEMPLATE_EDIT, url: '/administration/email-templates/{id}/edit', component: EmailTemplateBaseComponent},
  {name: StateName.EMAIL_TEMPLATE_DETAIL, url: '/administration/email-templates/{id}/detail', component: EmailTemplateBaseComponent},

  {name: StateName.TABLE_DOCUMENT_SCHEMA_LIST, url: '/table-document/schema/list', component: TableDocumentSchemaListComponent},
  {name: StateName.TABLE_DOCUMENT_SCHEMA_CREATE, url: '/table-document/schema/create', component: TableDocumentSchemaBaseComponent},
  {name: StateName.TABLE_DOCUMENT_SCHEMA_EDIT, url: '/table-document/schema/{id}/edit', component: TableDocumentSchemaBaseComponent},
  {name: StateName.TABLE_DOCUMENT_SCHEMA_DETAIL, url: '/table-document/schema/{id}/detail', component: TableDocumentSchemaBaseComponent},
  {name: StateName.TABLE_DOCUMENT_SCHEMA_CLONE, url: '/table-document/schema/{id}/clone', component: TableDocumentSchemaBaseComponent},

  {name: StateName.TABLE_DOCUMENT_LIST, url: '/table-document/list', component: TableDocumentListComponent},

  {name: StateName.GENERAL_PDF_TEMPLATE, url: '/general-pdf/template', component: GeneralPdfTemplateListComponent},

  {name: StateName.TOKENS_LIST, url: '/token/list', component: TokenListComponent},
  {name: 'playground', url: '/playground', component: PlaygroundComponent},

  {name: StateName.ENVIRONMENT_CONFIG, url: '/administration/environment-config', component: EnvironmentConfigComponent},

  {name: StateName.REGISTRATION_SETTINGS, url: '/administration/registration-settings', component: RegistrationSettingsContainerComponent},

  {name: StateName.NOTIFICATION_TEMPLATE_LIST, url: '/administration/notification-templates', component: NotificationTemplateListComponent},
  {
    name: StateName.NOTIFICATION_TEMPLATE_CREATE,
    url: '/administration/notification-templates/create',
    component: NotificationTemplateBaseComponent
  },
  {
    name: StateName.NOTIFICATION_TEMPLATE_EDIT,
    url: '/administration/notification-templates/{id}/edit',
    component: NotificationTemplateBaseComponent
  },
  {
    name: StateName.NOTIFICATION_TEMPLATE_DETAIL,
    url: '/administration/notification-templates/{id}/detail',
    component: NotificationTemplateBaseComponent
  },

  {name: StateName.EXTERIOR_TRANSPORT_LIST, url: '/exterior-transports', component: ExteriorTransportListComponent},
  {name: StateName.EXTERIOR_TRANSPORT_DETAIL, url: '/exterior-transports/{id}/detail', component: ExteriorTransportBaseComponent},
  {name: StateName.EXTERIOR_TRANSPORT_EDIT, url: '/exterior-transports/{id}/edit', component: ExteriorTransportBaseComponent},

  {name: StateName.SHOP_RENTER_SHOP_LIST, url: '/shoprenter/shops', component: ShopRenterShopListComponent},
  {name: StateName.SHOP_RENTER_SHOP_DETAIL, url: '/shoprenter/shops/{id}/detail', component: ShopRenterShopBaseComponent},
  {name: StateName.SHOP_RENTER_SHOP_EDIT, url: '/shoprenter/shops/{id}/edit', component: ShopRenterShopBaseComponent},

  {
    name: StateName.GENERAL_PRINTER_TEMPLATE_LIST,
    url: '/administration/general-printer/template',
    component: GeneralPrinterTemplateListComponent
  },

  {name: StateName.WORKLOG_LIST, url: '/administration/worklogs', component: WorklogListContainerComponent},

  {name: StateName.MILEAGE_RECORD_LIST, url: '/administration/mileage-records', component: MileageRecordListComponent},
  {
    name: StateName.MILEAGE_RECORD_DETAIL,
    url: '/administration/mileage-records/{mileageRecordId}/detail',
    component: MileageRecordDetailComponent
  },

  {name: StateName.ORDER_IMPORT_ERROR_LIST, url: '/administration/order-import-errors', component: OrderImportErrorListComponent},
  {
    name: StateName.ORDER_IMPORT_ERROR_DETAIL,
    url: '/administration/order-import-errors/{id}/detail',
    component: OrderImportErrorDetailComponent
  },

  {
    name: StateName.PARCEL_COLLECTION_POINT_LIST,
    url: '/administration/parcel-collection-points',
    component: ParcelCollectionPointListComponent
  },
  {
    name: StateName.PARCEL_COLLECTION_POINT_CREATE,
    url: '/administration/parcel-collection-points/create',
    component: ParcelCollectionPointBaseComponent
  },
  {
    name: StateName.PARCEL_COLLECTION_POINT_DETAIL,
    url: '/administration/parcel-collection-points/{id}/detail',
    component: ParcelCollectionPointBaseComponent
  },
  {
    name: StateName.PARCEL_COLLECTION_POINT_EDIT,
    url: '/administration/parcel-collection-points/{id}/edit',
    component: ParcelCollectionPointBaseComponent
  },

  {
    name: StateName.DELIVERY_METHOD_LIST,
    url: '/administration/delivery-methods',
    component: DeliveryMethodListComponent
  },
  {
    name: StateName.DELIVERY_METHOD_CREATE,
    url: '/administration/delivery-methods/create',
    component: DeliveryMethodBaseComponent
  },
  {
    name: StateName.DELIVERY_METHOD_EDIT,
    url: '/administration/delivery-methods/{id}/edit',
    component: DeliveryMethodBaseComponent
  },
  {
    name: StateName.DELIVERY_METHOD_DETAIL,
    url: '/administration/delivery-methods/{id}/detail',
    component: DeliveryMethodBaseComponent
  },

  {
    name: StateName.EMAIL_ADDRESS_TYPE_LIST,
    url: '/administration/email-address-types',
    component: EmailAddressTypeListComponent
  },
  {
    name: StateName.EMAIL_ADDRESS_TYPE_CREATE,
    url: '/administration/email-address-types/create',
    component: EmailAddressTypeBaseComponent
  },
  {
    name: StateName.EMAIL_ADDRESS_TYPE_EDIT,
    url: '/administration/email-address-types/{id}/edit',
    component: EmailAddressTypeBaseComponent
  },
  {
    name: StateName.EMAIL_ADDRESS_TYPE_DETAIL,
    url: '/administration/email-address-types/{id}/detail',
    component: EmailAddressTypeBaseComponent
  },
  {
    name: StateName.ANDROID_APP_VERSION,
    url: '/administration/android-version',
    component: AndroidAppVersionComponent
  },

  // Helpdesk
  {
    name: StateName.HELPDESK_LOGIN,
    url: '/helpdesk/login',
    component: HelpdeskLoginRegistrationContainerComponent,
    params: {drawn: false}
  },
  {
    name: StateName.HELPDESK_REGISTRATION,
    url: '/helpdesk/register?' + StateRequestParam.HELPDESK_REGISTRATION_TOKEN,
    component: HelpdeskLoginRegistrationContainerComponent,
    params: {drawn: false}
  },
  {
    name: StateName.HELPDESK,
    url: '/helpdesk',
    component: HelpdeskLayoutComponent,
    abstract: true
  },
  {
    name: StateName.HELPDESK_DASHBOARD,
    url: '/dashboard',
    component: HelpdeskDashboardComponent
  },
  {
    name: StateName.HELPDESK_BUG_REPORT_LIST,
    url: '/bug-reports',
    component: HelpdeskBugReportListComponent
  },
  {
    name: StateName.HELPDESK_BUG_REPORT_CREATE,
    url: '/bug/{taskId}/report/create',
    component: TaskRecordCreateComponent
  },
  {
    name: StateName.HELPDESK_BUG_REPORT_EDIT,
    url: '/bug/{taskId}/report/{taskRecordId}/edit',
    component: TaskRecordEditComponent
  },
  {
    name: StateName.HELPDESK_BUG_REPORT_DETAIL,
    url: '/bug/{taskId}/report/{taskRecordId}/detail',
    component: TaskRecordDetailComponent
  },
];

export const APP_UI_ROUTER_ROOT_MODULE: RootModule = {
  states: APP_STATES,
  config: uiRouterConfigFn,
  useHash: false
};

export function uiRouterConfigFn(router: UIRouter, injector: Injector) {
  // If no URL matches, go to the 'Login' state by default
  router.urlService.rules.otherwise({state: StateName.LOGIN});
}
