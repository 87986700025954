/* eslint-disable */
import { FieldValidationError } from '../../../lib/util/services';
import { LegacyProcessTask } from '../../../lib/legacy-process/legacy-process-task.service';
/* eslint-enable */

export namespace LegacyProcessTaskBase {

  export interface View {
    loadProcessTask(args: Args);

    getModel(): Model;

    hasLocalFieldError(): boolean;

    onFieldValidationError(fieldErrors: FieldValidationError<LegacyProcessTask.ValidatedField>);
  }

  export interface Args {
    processTask: LegacyProcessTask.LegacyProcessTask;
  }

  // Not injected so valid inside the namespace.
  export class Model {

    name: string = '';
    externalId: string = '';
    description: string = '';
    assigneeUserId?: number | null = undefined;

    load(record: LegacyProcessTask.LegacyProcessTask) {
      this.name = record.name;
      this.externalId = record.externalId ? record.externalId : '';
      this.description = record.description ? record.description : '';
      this.assigneeUserId = record.assigneeUserId ? record.assigneeUserId : undefined;
    }

  }

}
