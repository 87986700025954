import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { UiConstants } from '../../../util/core-utils';

@Component({
  selector: 'app-history-detail-dialog',
  templateUrl: './history-detail-dialog.component.html',
  styleUrls: ['./history-detail-dialog.component.scss']
})
export class HistoryDetailDialogComponent implements OnInit {
  UiConstants = UiConstants;

  @ViewChild('detailDialog', { static: true }) detailDialog: ModalDirective;
  detailDialogVisible: boolean = false;
  selectedHistoryDetail: string = '';

  constructor() { }

  ngOnInit() {
  }

  openDetailDialog(log: string) {
    this.selectedHistoryDetail = log;
    this.detailDialogVisible = true;
    this.detailDialog.show();
  }

  closeDetailDialog() {
    this.detailDialogVisible = false;
    this.detailDialog.hide();
  }

}
