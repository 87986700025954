/* eslint-disable */
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import {
  FormEditFieldCreateDialogView,
  FormEditFieldDialogContext,
  FormEditFieldUpdateDialogView,
  FormGroupModel,
  FormModel
} from '../../../../../../../util/form/form-utils';
import {
  FieldValidationError,
  FormRef,
  LocalFieldValidationErrors,
  LocalFieldValidationErrorsFactory
} from '../../../../../../../lib/util/services';
import { Form } from '../../../../../../../lib/form/form.service';
import { InputMask } from '../../../../../../../util/input-masks';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgModel } from '@angular/forms';
import { FieldWidthType } from '../../../../../../../util/form/form-field-width-type';
import {
  FieldValidationType,
  FormFieldValidationTypeModel
} from '../../../../../../../util/form/form-field-validation-type';
import { MultiselectOptionItem, OptionItem, TypeItem, UiConstants } from '../../../../../../../util/core-utils';
import { List, Set } from 'immutable';
import { CustomerService } from '../../../../../../../lib/customer/customer.service';
import { StringKey } from '../../../../../../../app.string-keys';
import { ToasterService } from '../../../../../../../fork/angular2-toaster/angular2-toaster';
import { TranslateService } from '@ngx-translate/core';
import { Models } from '../../../../../../../util/model-utils';
import { Angular2Multiselects } from '../../../../../../../util/multiselect';
import { CustomerFilterType } from '../../../../../../../util/form/form-field-customer-filter-type';
import { MasterDataFilterType } from '../../../../../../../util/form/form-field-masterdata-filter-type';

/* eslint-enable */

@Component({
  selector: 'app-form-edit-create-update-customer-field-dialog',
  templateUrl: './form-edit-create-update-customer-field-dialog.component.html',
  styleUrls: ['./form-edit-create-update-customer-field-dialog.component.scss']
})
export class FormEditCreateUpdateCustomerFieldDialogComponent
  implements FormEditFieldCreateDialogView, FormEditFieldUpdateDialogView, FormRef, AfterViewInit {

  public readonly selector = Form.FieldDataTypeSelector.CUSTOMER;
  public readonly createTitle = 'FORM_ITEM_CREATE_CUSTOMER_TITLE';
  public readonly updateTitle = 'FORM_ITEM_EDIT_CUSTOMER_TITLE';
  public readonly cloneTitle = 'FORM_ITEM_CLONE_CUSTOMER_TITLE';

  InputMask = InputMask;
  Form = Form;
  UiConstants = UiConstants;

  @ViewChild('fieldDialog', { static: true })
  dialogComponent: ModalDirective;

  @ViewChild('title')
  titleInput: NgModel;

  @ViewChild('customer')
  customerInput: NgModel;

  @ViewChild('api_export_name')
  apiExportNameInput: NgModel;

  @ViewChild('filterType')
  filterTypeInput: NgModel;

  visible: boolean = false;
  fieldModel: Model = new Model();
  dialogTitleDictionaryKey: string = '';
  latestApiExportName = '';

  fieldWidthTypes: FieldWidthType[] = FieldWidthType.VALUES.toArray();
  fieldValidationTypes: Form.FormFieldValidationType[] = FieldValidationType.VALUES.toArray();

  private fieldErrors: FieldValidationError<Form.FieldValidatedField> =
    FieldValidationError.empty<Form.FieldValidatedField>();

  private validatedInputs: LocalFieldValidationErrors<NgModel> =
    LocalFieldValidationErrorsFactory.empty();

  private context: FormEditFieldDialogContext;
  private group: FormGroupModel;
  private field?: Form.Field;
  private clone?: boolean;
  private submitted: boolean = false;

  availableCustomer: TypeItem[] = [];

  dropdownSettings?: Angular2Multiselects.Settings;
  localDropdownSettings?: Angular2Multiselects.Settings;
  private filterTypes: MultiselectOptionItem<CustomerFilterType.CustomerFilterType>[];

  getForm() {
    return {
      submitted: this.submitted
    };
  }

  public constructor(private customerService: CustomerService,
                     private toasterService: ToasterService,
                     private translateService: TranslateService) {
  }

  ngAfterViewInit(): void {
    this.loadLocalFieldValidationErrors();
    this.initDropdownSettings();
  }

  initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .labelKey(OptionItem.KEY_TEXT)
      .build();
    this.localDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(false)
      .enableCheckAll(true)
      .remoteSearch(false)
      .build();
  }

  registerContext(context: FormEditFieldDialogContext): void {
    this.context = context;
  }

  showDialog(form: FormModel, group: FormGroupModel, field?: Form.Field, clone?: boolean): void {
    this.latestApiExportName = '';
    this.visible = true;
    this.clone = clone;
    this.loadCustomerTypesAndShowDialog(form, group, field, clone);
  }

  onModalHide(event: ModalDirective) {
    if (event.dismissReason === 'backdrop-click' || event.dismissReason === 'esc') {
      this.visible = false;
      this.submitted = false;
      this.fieldModel = new Model();
    }
  }

  closeDialog(): void {
    this.visible = false;
    this.submitted = false;
    this.dialogComponent.hide();
    this.fieldModel = new Model();
    this.availableCustomer = [];
  }

  saveField() {
    this.submitted = true;
    if (this.hasLocalFieldError(this.titleInput)
      || this.hasLocalFieldError(this.customerInput)
      || this.hasLocalFieldError(this.filterTypeInput)
      || this.hasLocalFieldError(this.apiExportNameInput)) {
      return;
    }
    if (this.field && this.clone) {
      this.createField();
    }
    else if (this.field) {
      this.updateField(this.field);
    }
    else {
      this.createField();
    }
  }

  hasLocalFieldError(field?: NgModel): boolean {
    return this.validatedInputs.hasLocalError(field);
  }

  hasFieldError(field?: Form.FieldValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: Form.FieldValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: Form.FieldValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  getFieldValidationTypeDictionaryKey(vt: Form.FormFieldValidationType): string {
    return FieldValidationType.getFieldValidationTypeDictionaryKey(vt);
  }

  onAdminFormFieldValidationTypeChanged() {
    FieldValidationType.onAdminFormFieldValidationTypeChanged(this.fieldModel);
  }

  onMobileFormFieldValidationTypeChanged() {
    FieldValidationType.onMobileFormFieldValidationTypeChanged(this.fieldModel);
  }

  private loadLocalFieldValidationErrors() {
    const validatedInputs = List.of(this.titleInput, this.customerInput, this.apiExportNameInput, this.filterTypeInput);
    this.validatedInputs = LocalFieldValidationErrorsFactory.ofFields(this, validatedInputs);
  }

  private loadCustomerTypesAndShowDialog(form: FormModel, group: FormGroupModel, field?: Form.Field, clone?: boolean) {
    this.initCustomerFilterTypes();
    this.loadCustomerTypes(() => {
      if (this.availableCustomer.length > 0) {
        this.loadDialog(form, group, field, clone);
      }
      else {
        this.toasterService.pop({
          timeout: UiConstants.ToastTimeoutLong,
          type: UiConstants.toastTypeError,
          title: this.translateService.instant(StringKey.FORM_ITEM_CUSTOMER_NO_CUSTOMER_TYPE_IMAGE_TITLE),
          body: this.translateService.instant(StringKey.FORM_ITEM_CUSTOMER_NO_CUSTOMER_TYPE_MESSAGE)
        });
      }
    })
  }

  private loadCustomerTypes(completion: () => void, searchValue?: string) {
    this.customerService.query({
      name: searchValue,
      disabled: false,
      paging: {
        numberOfItems: UiConstants.autocompletePageSize,
        pageNumber: 1
      },
      noProgressBar: true
    }).subscribe(customerList => {
      this.availableCustomer = [];
      customerList.items.forEach((customer) => {
        if (customer) {
          const item = new TypeItem();
          item.id = customer.customerId;
          item.text = customer.name;
          item.disabled = customer.disabled;
          this.availableCustomer.push(item);
        }
      });
      if (completion) {
        completion();
      }
    });
  }


  private loadDialog(form: FormModel, group: FormGroupModel, field?: Form.Field, clone?: boolean) {
    this.group = group;
    this.dialogComponent.show();
    if (field && clone) {
      this.dialogTitleDictionaryKey = this.cloneTitle;
      this.loadField(field);
    }
    else if (field) {
      this.dialogTitleDictionaryKey = this.updateTitle;
      this.loadField(field);
    }
    else {
      this.dialogTitleDictionaryKey = this.createTitle;
    }
  }

  private loadField(field: Form.Field) {
    this.field = field;
    const attrs = field.dataType.customerAttributes!;
    const customer = this.getCustomerById(attrs.customerId);
    const placeholder = attrs.hint;
    const fieldWidth = FieldWidthType.fromPercentValue(field.formFieldWidthPercent);
    this.fieldModel.title = field.title;
    this.fieldModel.description = field.description ? field.description : '';
    this.fieldModel.hint = field.hint ? field.hint : '';
    this.fieldModel.apiExportName = field.apiExportName ? field.apiExportName : '';
    this.fieldModel.pdfExportName = field.pdfExportName ? field.pdfExportName : '';
    this.fieldModel.fieldWidthType = fieldWidth ? fieldWidth : FieldWidthType.DEFAULT;
    this.fieldModel.displayOnNewRow = field.displayOnNewRow;
    this.fieldModel.placeholder = placeholder ? placeholder : '';
    this.fieldModel.multiSelect = attrs.multiSelect;
    this.fieldModel.autoJoinOwnerOnFinish = attrs.autoJoinOwnerOnFinish;
    this.fieldModel.adminVisibleOnFormDetail = field.admin.visibleOnFormDetail;
    this.fieldModel.mobileVisibleOnFormDetail = field.mobile.visibleOnFormDetail;
    this.fieldModel.mobileVisibleOnMasterDetail = field.mobile.visibleOnMasterDetail;
    this.fieldModel.adminFormFieldValidationType = field.admin.validationType;
    this.fieldModel.helpdeskFormFieldValidationType = field.helpdesk.validationType;
    this.fieldModel.mobileFormFieldValidationType = field.mobile.validationType;
    this.fieldModel.showOnReceipt = field.showOnReceipt;
    this.fieldModel.customerItem = [];
    this.loadCustomerFilterTypes(attrs.filterTypes);
    if (customer) {
      this.fieldModel.customerItem.push(customer)
    }
    else {
      this.customerService.get({
        customerId: attrs.customerId
      }).subscribe(customer => {
        if (customer) {
          const item = new TypeItem();
          item.id = customer.customerId;
          item.text = customer.name;
          item.disabled = customer.disabled;
          this.fieldModel.customerItem.push(item);
          this.customerInput.control.updateValueAndValidity();
        }
      })
    }
  }

  private getCustomerById(customerId: number): TypeItem | undefined {
    let item: TypeItem | undefined = undefined;
    this.availableCustomer.forEach(i => {
      if (i.id === customerId) {
        item = i;
      }
    });
    return item;
  }

  private createField() {
    this.context.formService.createField({
      parentId: this.context.parentId,
      groupId: this.group.groupId,
      title: this.fieldModel.title,
      description: this.fieldModel.description,
      hint: this.fieldModel.hint,
      pdfExportName: this.fieldModel.pdfExportName,
      apiExportName: this.fieldModel.apiExportName,
      dataTypeSelector: this.selector,
      dataType: this.getDataTypeRequest(),
      formFieldWidthPercent: FieldWidthType.FULL_SCREEN.percentValue,
      displayOnNewRow: this.fieldModel.displayOnNewRow,
      admin: {
        visibleOnFormDetail: this.fieldModel.adminVisibleOnFormDetail,
        validationType: this.fieldModel.adminFormFieldValidationType
      },
      helpdesk: {
        validationType: this.fieldModel.helpdeskFormFieldValidationType
      },
      mobile: {
        visibleOnFormDetail: this.fieldModel.mobileVisibleOnFormDetail,
        visibleOnMasterDetail: this.fieldModel.mobileVisibleOnMasterDetail,
        validationType: this.fieldModel.mobileFormFieldValidationType
      },
      showOnReceipt: this.fieldModel.showOnReceipt

    }).subscribe(
      (result) => {
        this.context.formView.reloadForm();
        this.closeDialog();
      },
      (error) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withFormRef(this);
        }
      }
    );
  }

  private updateField(field: Form.Field) {
    this.context.formService.updateField({
      parentId: this.context.parentId,
      groupId: this.group.groupId,
      fieldId: field.fieldId,
      title: this.fieldModel.title,
      description: this.fieldModel.description,
      hint: this.fieldModel.hint,
      pdfExportName: this.fieldModel.pdfExportName,
      apiExportName: this.fieldModel.apiExportName,
      dataTypeSelector: this.selector,
      dataType: this.getDataTypeRequest(),
      formFieldWidthPercent: FieldWidthType.FULL_SCREEN.percentValue,
      displayOnNewRow: this.fieldModel.displayOnNewRow,
      admin: {
        visibleOnFormDetail: this.fieldModel.adminVisibleOnFormDetail,
        validationType: this.fieldModel.adminFormFieldValidationType
      },
      helpdesk: {
        validationType: this.fieldModel.helpdeskFormFieldValidationType
      },
      mobile: {
        visibleOnFormDetail: this.fieldModel.mobileVisibleOnFormDetail,
        visibleOnMasterDetail: this.fieldModel.mobileVisibleOnMasterDetail,
        validationType: this.fieldModel.mobileFormFieldValidationType
      },
      showOnReceipt: this.fieldModel.showOnReceipt
    }).subscribe(
      (result) => {
        this.context.formView.reloadForm();
        this.closeDialog();
      },
      (error) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withFormRef(this);
        }
      }
    );
  }

  private getDataTypeRequest(): Form.FieldDataType {
    return {
      customerAttributes: {
        customerId: this.fieldModel.customerItem[0]!.id!,
        hint: this.fieldModel.placeholder,
        multiSelect: this.fieldModel.multiSelect,
        autoJoinOwnerOnFinish: this.fieldModel.autoJoinOwnerOnFinish,
        filterTypes: this.fieldModel.getFilterTypes()
      }
    };
  }

  private initCustomerFilterTypes() {
    this.filterTypes = [];
    CustomerFilterType.customerFilterTypes.forEach((filterType) => {
      const item = new MultiselectOptionItem<CustomerFilterType.CustomerFilterType>()
      item.id = filterType.type;
      this.translateService.get(filterType.stringKey).subscribe(
        (result: string) => {
          item.itemName = result;
        }
      );
      this.filterTypes.push(item);
    })
  }

  private loadCustomerFilterTypes(filterTypes: Set<CustomerFilterType.CustomerFilterType>) {
    filterTypes.forEach((filterType) => {
      const item = this.filterTypes.find(ft => ft.id === filterType);
      if (item) {
        this.fieldModel.filterTypes.push(item);
        this.filterTypeInput.control.updateValueAndValidity();
      }
    })
  }

  onTitleBlur() {
    const normalizedText = Models.normalizeText(this.fieldModel.title);
    if (this.fieldModel.apiExportName === '') {
      this.fieldModel.apiExportName = normalizedText;
    }
    if (this.fieldModel.pdfExportName === '') {
      this.fieldModel.pdfExportName = normalizedText;
    }
  }
}


export class Model implements FormFieldValidationTypeModel {
  title: string = '';
  description: string = '';
  apiExportName: string = '';
  pdfExportName: string = '';
  fieldWidthType: FieldWidthType = FieldWidthType.FULL_SCREEN;
  displayOnNewRow: boolean = false;
  hint?: string;
  placeholder?: string;
  customerItem: TypeItem[] = [];
  multiSelect: boolean = false;
  autoJoinOwnerOnFinish: boolean = false;
  adminVisibleOnFormDetail: boolean = true;
  mobileVisibleOnFormDetail: boolean = true;
  mobileVisibleOnMasterDetail: boolean = false;
  adminFormFieldValidationType: Form.FormFieldValidationType = Form.FormFieldValidationType.OPTIONAL;
  mobileFormFieldValidationType: Form.FormFieldValidationType = Form.FormFieldValidationType.OPTIONAL;
  helpdeskFormFieldValidationType: Form.FormFieldValidationType = Form.FormFieldValidationType.OPTIONAL;
  showOnReceipt?: boolean = false;
  filterTypes: MultiselectOptionItem<CustomerFilterType.CustomerFilterType>[] = [];

  getFilterTypes(): Set<CustomerFilterType.CustomerFilterType> {
    return Set.of(...this.filterTypes.map(ft => ft.id))
  }

}
