/* eslint-disable */
import { CountMessage, EmptyMessage, IdentityMessage } from '../util/messages';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Map as ImmutableMap } from 'immutable';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { ResourceQueryResult } from '../util/services';

/* eslint-enable */


export interface DocumentLinkQuery {
}

export interface CreateDocumentLinkRequest {
  code?: string;
  name: string;
  value: string;
  comment: string;
  document_group_id?: number;
  language_code?: string;
}

export interface UpdateDocumentLinkRequest {
  id: number;
  name: string;
  code?: string;
  value: string;
  comment: string;
  document_group_id?: number;
  language_code?: string;
}

export interface DocumentLink {
  id: number;
  name: string;
  code: string;
  value: string;
  comment: string;
  document_group_id: number;
  language_code: string;
  creation_time: string;
  update_time: string;
  disabled: boolean;
}

export interface DisableRequest extends IdentityMessage {
  disabled: boolean;
}

@Injectable()
export class DocumentLinkService extends BaseHttpService {

  query(request: DocumentLinkQuery): Observable<ResourceQueryResult<DocumentLink>> {
    return this.http.get<ResourceQueryResult<DocumentLink>>(this.url, this.parseParams(request));
  }

  get(request: IdentityMessage): Observable<DocumentLink> {
    return this.http.get<DocumentLink>(this.url + `${request.id}`);
  }

  create(request: CreateDocumentLinkRequest): Observable<EmptyMessage> {
    return this.http.post(this.url, request);
  }

  update(request: UpdateDocumentLinkRequest): Observable<EmptyMessage> {
    return this.http.put(this.url + `${request.id}`, request);
  }

  setDisabled(request: DisableRequest): Observable<EmptyMessage> {
    return this.http.patch(this.url + `${request.id}/disabled`, request);
  }

  count(request: DocumentLinkQuery): Observable<CountMessage> {
    return this.http.get<CountMessage>(this.url + `count`, this.parseParams(request));
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/documents/links/');
  }
}

export namespace DocumentLinkList {

  export enum OrderField {
    ID,
    NAME,
    CODE,
    DOCUMENT_GROUP_NAME,
    UPDATE_TIME,
    CREATION_TIME,
  }

  export class Keys {

    private static readonly ID = 'id';
    private static readonly NAME = 'name';
    private static readonly CODE = 'code';
    private static readonly DOCUMENT_GROUP_NAME = 'document_group_name';
    private static readonly UPDATE_TIME = 'update_time';
    private static readonly CREATION_TIME = 'creation_time';

    private static readonly orderFieldKeyMap: ImmutableMap<DocumentLinkList.OrderField, string> = ImmutableMap.of(
      DocumentLinkList.OrderField.ID, Keys.ID,
      DocumentLinkList.OrderField.NAME, Keys.NAME,
      DocumentLinkList.OrderField.CODE, Keys.CODE,
      DocumentLinkList.OrderField.DOCUMENT_GROUP_NAME, Keys.DOCUMENT_GROUP_NAME,
      DocumentLinkList.OrderField.UPDATE_TIME, Keys.UPDATE_TIME,
      DocumentLinkList.OrderField.CREATION_TIME, Keys.CREATION_TIME,
    );

    public static toOrderFieldKey(field: DocumentLinkList.OrderField): string {
      return Keys.orderFieldKeyMap.get(field)!;
    }

  }

}
