import { DqlQuery, Query } from '../field';
import { LazyReference } from '../../../util/lazy';
import { UploaderUser } from './order-document';

// Each class should be an interface, but it is easier to define the constant fields in the constructors.
// For now we have only one query language so it is not a problem.
// Always use the public Query namespace for variable definitions so we will be able to introduce other language easily.

export class ExteriorTransportDocument {

  readonly id: Query.StringField;

  readonly creationTime: Query.DateTimeField;

  readonly contentSize: Query.NumberField;

  readonly contentType: Query.StringField;

  readonly originalFileName: Query.StringField;

  readonly serial: Query.StringField;

  private readonly uploaderUserReference: LazyReference<UploaderUser>; // Lazy to support circular references.

  private readonly meta: ExteriorTransportDocumentMeta;

  get uploaderUser(): UploaderUser {
    return this.uploaderUserReference.get();
  }

  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this.id = new DqlQuery.StringField(prefix + 'id');
    this.creationTime = new DqlQuery.DateTimeField(prefix + 'creation_time');
    this.contentSize = new DqlQuery.NumberField(prefix + 'content_size');
    this.contentType = new DqlQuery.StringField(prefix + 'content_type');
    this.originalFileName = new DqlQuery.StringField(prefix + 'original_file_name');
    this.serial = new DqlQuery.StringField(prefix + 'serial');
    this.uploaderUserReference = LazyReference.of(
      () => new UploaderUser(prefix + 'uploader_user.')
    );
    this.meta = new ExteriorTransportDocumentMeta(prefix + 'meta.')
  }

}

export class ExteriorTransportDocumentMeta {

  readonly comment: Query.StringField;
  readonly type: Query.StringField;

  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this.comment = new DqlQuery.StringField(prefix + 'comment');
    this.type = new DqlQuery.StringField(prefix + 'type');
  }

}
