<div class="card ">

  <div class="card-header">
    {{'STOCK_ITEM_MEASUREMENTS'|translate}}
    <div class="card-actions">
      <a class="btn-setting table-header-action-button" (click)="onCreateClicked()"
         title="{{'COMMON_BUTTON_ADD' | translate}}"
         *ngIf="!readonly && rightModel.stockItemUpdate.hasRight()">
        <i class="icomoon icomoon-add"></i>
      </a>
      <a class="btn-setting cursor-pointer" (click)="toggleSearch()"
         title="{{'COMMON_SHOW_SEARCH_HINT' | translate}}">
        <i class="icomoon icomoon-search"></i>
      </a>
    </div> <!-- ./card-actions end -->
  </div> <!-- ./card header end -->
  <div class="card-body border-bottom" *ngIf="showSearch">
    <div class="row form-group">
      <div class="col-md-3">
        <label class="search-label"> {{ 'COMMON_STATE' | translate }} </label>
        <select
          class="form-control"
          [(ngModel)]="searchModel.disabled">
          <option *ngFor="let item of disabledItems" [ngValue]="item.id">{{item.text}}</option>
        </select>
      </div>
      <div class="col-md-4 d-flex align-items-end">
        <div class="row">
          <app-animated-checkbox class="search-checkbox"
                                 [(selected)]="searchModel.includeBaseUnit">
          </app-animated-checkbox>
          <label class="search-label">{{'STOCK_ITEM_MEASUREMENT_INCLUDE_BASE_UNIT' | translate}}</label>
        </div>
      </div>
    </div>
    <div class="col-md-12 d-flex justify-content-md-end align-items-end px-0">
      <div class="btn-group" role="group">
        <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
          {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
        </button>
        <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
          {{'COMMON_BUTTON_SEARCH' | translate}}
        </button>
      </div>
    </div>
  </div><!-- /.card-body-->

  <div class="card-body">
    <table class="table table-striped table-bordered">
      <thead>
      <tr>
        <th class="table-sorter-header">
          <app-table-field-sorter [orderField]="StockItemUnitOfMeasure.OrderField.ID"
                                  [orderType]="queryModel.getOrderType(StockItemUnitOfMeasure.OrderField.ID)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header">
          <app-table-field-sorter [orderField]="StockItemUnitOfMeasure.OrderField.UNIT_NAME"
                                  [orderType]="queryModel.getOrderType(StockItemUnitOfMeasure.OrderField.UNIT_NAME)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header">
          <app-table-field-sorter [orderField]="StockItemUnitOfMeasure.OrderField.UNIT_CONVERSION"
                                  [orderType]="queryModel.getOrderType(StockItemUnitOfMeasure.OrderField.UNIT_CONVERSION)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'STOCK_ITEM_MEASUREMENT_CONVERSION' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header w-table-options-menu" *ngIf="!readonly">
          <app-table-sorter-no-op></app-table-sorter-no-op>
        </th>
      </tr>
      <tbody>
      <tr *ngFor="let item of list | paginate: {
                                                                itemsPerPage: queryModel.itemsPerPage,
                                                                currentPage: queryModel.currentPage,
                                                                totalItems: queryModel.currentNumberOfItems }">
        <td class="responsive-table-column">{{item.id}}</td>
        <td class="responsive-table-column flex-column"><div>{{item.unit_name}}</div>
          <div class="table-text-subtitle">{{item.code}}</div>
        </td>
        <td class="responsive-table-column">{{item.unit_conversion}}</td>
        <td *ngIf="!readonly">
          <app-table-options-menu>
            <app-dropdown-item
              *ngIf="!readonly && rightModel.stockItemUpdate.hasRight()"
              [iconClass]="'icomoon-modify'"
              [titleStringKey]="'COMMON_BUTTON_EDIT'"
              class="cursor-pointer"
              (click)="onEditClicked(item)"
            >
            </app-dropdown-item>
            <app-dropdown-item
              *ngIf="!readonly && !item.disabled && rightModel.stockItemUpdate.hasRight() && !item.base_unit"
              [iconClass]="'icomoon-disable'"
              [titleStringKey]="'COMMON_BUTTON_DISABLE'"
              [itemType]="DropdownItemType.DISABLE"
              class="cursor-pointer"
              (click)="onDisableClicked(item, true)">
            </app-dropdown-item>
            <app-dropdown-item
              *ngIf="!readonly && item.disabled && rightModel.stockItemUpdate.hasRight()  && !item.base_unit"
              [iconClass]="'icomoon-enable'"
              [titleStringKey]="'COMMON_BUTTON_ENABLE'"
              [itemType]="DropdownItemType.ENABLE"
              class="cursor-pointer"
              (click)="onDisableClicked(item, false)">
            </app-dropdown-item>
          </app-table-options-menu>
        </td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <th class="table-sorter-header">
          <app-table-field-sorter [orderField]="StockItemUnitOfMeasure.OrderField.ID"
                                  [orderType]="queryModel.getOrderType(StockItemUnitOfMeasure.OrderField.ID)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header">
          <app-table-field-sorter [orderField]="StockItemUnitOfMeasure.OrderField.UNIT_NAME"
                                  [orderType]="queryModel.getOrderType(StockItemUnitOfMeasure.OrderField.UNIT_NAME)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header">
          <app-table-field-sorter [orderField]="StockItemUnitOfMeasure.OrderField.UNIT_CONVERSION"
                                  [orderType]="queryModel.getOrderType(StockItemUnitOfMeasure.OrderField.UNIT_CONVERSION)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'STOCK_ITEM_MEASUREMENT_CONVERSION' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header w-table-options-menu" *ngIf="!readonly">
          <app-table-sorter-no-op></app-table-sorter-no-op>
        </th>
      </tr>
      </tfoot>
    </table>
    <app-table-paging
      [currentNumberOfItems]="queryModel.currentNumberOfItems"
      [totalNumberOfItems]="queryModel.totalNumberOfItems"
      [itemsPerPage]="queryModel.itemsPerPage"
      (pageChange)="pageChanged($event)"
      (itemsPerPageChange)="itemsPerPageChanged($event)">
    </app-table-paging>
  </div>
</div>
