<div class="modal-content">
  <div class="modal-header">
    <p class="modal-title"><strong>{{'CUSTOMER_RECORD_CONNECT_MASTER_DATA' | translate}}</strong></p>
    <button type="button" class="close" (click)="closeDialog(false)" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <mat-dialog-content style="overflow:visible">
      <form (ngSubmit)="save()" #f="ngForm" id="form"
            [formGroup]="formGroup" novalidate>

        <div class="row form-group" [ngClass]="{ 'has-danger': hasLocalFieldError('masterDataRecords')}">
          <label class="col-form-label form-control-label col-md-4 detail-title required-field-label">
            {{'CUSTOMER_RECORD_CONNECT_MASTER_DATA_RECORDS' | translate}}
          </label>
          <div class="col-md-8">
            <angular2-multiselect
              (onRemoteSearch)="onMasterDataRecordSearch($event.target.value)"
              [(ngModel)]="model._masterDataRecords"
              [data]="masterDataRecordList"
              [settings]="multiRemoteDropdownSettings"
              [ngClass]="{ 'form-control-danger': hasLocalFieldError('masterDataRecords') }"
              id="masterDataRecords"
              name="masterDataRecords"
              formControlName="masterDataRecords"
              required>
            </angular2-multiselect>
            <div class="form-control-feedback"
                 *ngIf="hasLocalFieldError('masterDataRecords')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
            </div>
          </div>
        </div>

        <div class="row form-group">
          <label class="col-form-label form-control-label col-md-4 detail-title">
            {{'MASTERDATA_RECORD_OWNER_CUSTOMER_RECORD' | translate}}
          </label>
          <div class="col-md-8">
            <angular2-multiselect
              (onRemoteSearch)="onCustomerRecordSearch($event.target.value)"
              [(ngModel)]="model._ownerCustomerRecord"
              (ngModelChange)="onCustomerRecordChanged()"
              [data]="customerRecords"
              [settings]="remoteDropdownSettings"
              [readonly]="data.customerRecordId"
              id="customerRecord"
              name="customerRecord"
              formControlName="customerRecord">
            </angular2-multiselect>
          </div>
        </div>

        <div class="row form-group">
          <label class="col-form-label form-control-label col-md-4 detail-title">
            {{'COMPANY_LOCATION' | translate}}
          </label>
          <div class="col-md-8">
            <angular2-multiselect
              (onRemoteSearch)="onCustomerContactLocationSearch($event.target.value)"
              [(ngModel)]="model._ownerContactLocation"
              [data]="customerRecordContactLocations"
              [settings]="remoteDropdownSettings"
              id="contactLocation"
              name="contactLocation"
              formControlName="contactLocation">
            </angular2-multiselect>
          </div>
        </div>
      </form>
    </mat-dialog-content>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="closeDialog(false)">
      {{'COMMON_BUTTON_CANCEL' | translate}}
    </button>
    <button type="submit" class="btn btn-primary" form="form">
      {{'COMMON_BUTTON_SAVE' | translate}}
    </button>
  </div>
</div>
