/* eslint-disable */
import { AfterViewInit, Component, OnInit, ViewChild, } from '@angular/core';
import { Transition, UIRouter } from '@uirouter/angular';
import { MultiselectOptionItem, OwnerUserItem, SelectUtils, UiConstants } from '../../../util/core-utils';
import { StateName } from '../../../app.state-names';
import { InputMask } from '../../../util/input-masks';
import { CustomerCreateModel, CustomerEditModel, CustomerOwnerTypeItem, CustomerTypeItem } from '../../../util/customer-utils';
import { Customer, CustomerService } from '../../../lib/customer/customer.service';
import { FieldValidationError, LocalFieldValidationErrors, LocalFieldValidationErrorsFactory, } from '../../../lib/util/services';
import { FormServiceCode } from '../../../lib/form/form-service-factory';
import { NgForm, NgModel, } from '@angular/forms';
import { List, Set } from 'immutable';
import { LoadingHandler } from '../../../lib/loading-handler';
import { FormRights } from '../../../util/form/form-utils';
import { RightModel, Rights } from '../../../app.rights';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { Angular2Multiselects } from '../../../util/multiselect';
import { FormEditComponent } from '../../form/form-edit/form-edit.component';
import { FormImportDialogComponent } from '../../form/form-import/form-import-dialog.component';
import { Form } from '../../../lib/form/form.service';
import { saveAs } from 'file-saver';
import { ConfigurationService } from '../../../lib/core-ext/configuration.service';
import { CustomerRecordField, CustomerRecordFieldType } from '../../../util/customer-record-utils';
import { Models } from '../../../util/model-utils';
import { Numbers } from '../../../lib/util/numbers';
import { UserGroupRoleType } from '../../usergroup/usergroup-type-based-roles/user-group-type-based-role-card/user-group-type-based-role-card.component';
import { RightResolver, RightService } from '../../../lib/right.service';
import { ToasterService } from '../../../fork/angular2-toaster/src/toaster.service';
import { StringKey } from '../../../app.string-keys';
import { UserGroupMultiselectProvider } from '../../../lib/user-group/user-group-multiselect.provider';
import { LedgerNumberMultiselectProvider } from '../../../lib/ledger/number/ledger-number-multiselect.provider';
import { UserMultiselectProvider } from '../../../lib/user/user-multiselect.provider';

/* eslint-enable */

@Component({
  selector: 'app-customer-edit',
  templateUrl: 'customer-edit.component.html',
  styleUrls: ['customer-edit.component.scss']
})
export class CustomerEditComponent implements OnInit, AfterViewInit {

  InputMask = InputMask;
  Customer = Customer;
  FormServiceCode = FormServiceCode;
  SelectUtils = SelectUtils;
  UiConstants = UiConstants;
  UserGroupRoleType = UserGroupRoleType;

  formRights: FormRights = {
    formRead: Rights.CUSTOMER_FORM_READ,
    formGroupUpdate: Rights.CUSTOMER_FORM_GROUP_UPDATE,
    formFieldUpdate: Rights.CUSTOMER_FORM_FIELD_UPDATE,
    formGroupCreate: Rights.CUSTOMER_FORM_GROUP_CREATE,
    formFieldCreate: Rights.CUSTOMER_FORM_FIELD_CREATE,
    formGroupDisable: Rights.CUSTOMER_FORM_GROUP_DISABLE,
    formFieldDisable: Rights.CUSTOMER_FORM_FIELD_DISABLE,
    formGroupMove: Rights.CUSTOMER_FORM_GROUP_MOVE,
    formFieldMove: Rights.CUSTOMER_FORM_FIELD_MOVE,
  };

  @ViewChild('f', {static: true})
  fForm: NgForm;

  @ViewChild('formEdit', {static: true})
  formEdit: FormEditComponent;

  @ViewChild('formImportDialog', {static: true})
  formImportDialog: FormImportDialogComponent;

  @ViewChild('name', {static: true})
  nameInput: NgModel;

  @ViewChild('external_id', {static: true})
  externalIdInput: NgModel;

  @ViewChild('ownerGroups', {static: true})
  ownerGroupsInput: NgModel;

  @ViewChild('requiredFieldsInput', {static: true})
  requiredFieldsInput: NgModel;

  ordinalNumberMask = [/[1-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

  rightModel: RightModel = RightModel.empty();
  model: CustomerEditModel = new CustomerCreateModel();
  ownerUsers: MultiselectOptionItem<number>[] = [];
  customerId: number;
  customerTypes: CustomerTypeItem[] = [];
  mobileOwnerTypes: CustomerOwnerTypeItem[] = [];
  webOwnerTypes: MultiselectOptionItem<Customer.CustomerOwnerType>[] = [];
  defaultManagedFields: CustomerRecordFieldType[] = [];
  manageablePersonFields: MultiselectOptionItem<CustomerRecordFieldType>[] = [];
  manageableCompanyFields: MultiselectOptionItem<CustomerRecordFieldType>[] = [];
  userGroups: MultiselectOptionItem<number>[] = [];
  ledgerNumberList: MultiselectOptionItem<number>[] = [];

  get manageableFields(): MultiselectOptionItem<CustomerRecordFieldType>[] {
    if (!this.model.getCustomerType()) {
      return [];
    }
    if (this.model.getCustomerType() === 'PERSON') {
      return this.manageablePersonFields;
    }
    return this.manageableCompanyFields;
  }

  requirablePersonFields: MultiselectOptionItem<CustomerRecordFieldType>[] = [];
  requirableCompanyFields: MultiselectOptionItem<CustomerRecordFieldType>[] = [];

  get requirableFields(): MultiselectOptionItem<CustomerRecordFieldType>[] {
    if (this.model.useDefaultManagedFields) {
      if (this.model.getCustomerType() === 'PERSON') {
        return this.requirablePersonFields.filter(f => this.defaultManagedFields.includes(f.id));
      }
      else {
        return this.requirableCompanyFields.filter(f => this.defaultManagedFields.includes(f.id));
      }
    }
    else {
      if (this.model.getCustomerType() === 'PERSON') {
        return this.requirablePersonFields.filter(f => this.model.managedFields.map(f => f.id).includes(f.id));
      }
      else {
        return this.requirableCompanyFields.filter(f => this.model.managedFields.map(f => f.id).includes(f.id));
      }
    }
  }

  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  userGroupDropdownSettings: Angular2Multiselects.Settings;
  fieldsDropdownSettings: Angular2Multiselects.Settings;
  ledgerNumberDropdownSettings: Angular2Multiselects.Settings;

  private fieldErrors: FieldValidationError<Customer.ValidatedField> =
    FieldValidationError.empty<Customer.ValidatedField>();

  private validatedInputs: LocalFieldValidationErrors<NgModel> =
    LocalFieldValidationErrorsFactory.empty();

  get formService(): Form.Service {
    return this.customerService;
  }

  constructor(private customerService: CustomerService,
              private uiRouter: UIRouter,
              private toasterService: ToasterService,
              private configService: ConfigurationService,
              private transition: Transition,
              private translateService: TranslateService,
              private rightService: RightService,
              private ledgerNumberMultiselectProvider: LedgerNumberMultiselectProvider,
              private userGroupMultiselectProvider: UserGroupMultiselectProvider,
              private userMultiselectProvider: UserMultiselectProvider,
              ) {
    this.customerId = this.transition.params().id;
  }

  ngOnInit() {
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_CUSTOMER_TYPES').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.CUSTOMER_LIST});
      }
    );
    this.initDropdown();
    this.loadRightModels();
  }

  ngAfterViewInit(): void {
    this.loadLocalFieldValidationErrors();
    this.customerService.get({
      customerId: this.customerId
    }).subscribe((customer: Customer.Customer) => {
      this.breadcrumbSelf = customer.name;
      this.loadModel(customer);
    });
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  initDropdown() {
    this.userGroupDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .remoteSearch(true)
      .build();
    this.fieldsDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .translate(true)
      .badgeShowLimit(100)
      .build();
    this.ledgerNumberDropdownSettings = Angular2Multiselects.REMOTE_SINGLE_SELECT;
  }

  update() {
    this.ownerGroupsInput.control.updateValueAndValidity();
    if (this.hasLocalFieldError() || !this.model.type || !this.model.mobileOwnerType) {
      this.showValidationErrorToast();
      return;
    }
    this.customerService.update({
      customerId: this.customerId,
      name: this.model.name,
      complexName: this.model.type.id === 'COMPANY' ? false : this.model.complexName,
      description: this.model.description,
      externalId: this.model.externalId,
      defaultLedgerNumber: this.model.defaultLedgerNumberId,
      icon: this.model.icon,
      type: this.model.type.id!,
      mobileOwnerType: this.model.mobileOwnerType.id!,
      webOwnerTypes: this.model.mapWebOwnerTypes(),
      ownerUserGroupIds: this.model.getUserGroupIds(),
      useDefaultManagedFields: this.model.useDefaultManagedFields,
      explicitOrderNumber: Models.parseNumber(Numbers.undefinedOrNonEmpty(this.model.explicitOrderNumber)),
      managedFields: this.model.useDefaultManagedFields ? undefined : Set.of(...this.model.managedFields.map(f => f.id)),
      requiredFields: Set.of(...this.model.requiredFields.map(f => f.id)),
      contactPerson: this.model.type.id === 'COMPANY' ? false : this.model.contactPerson
    }).subscribe(
      (response: Customer.Customer) => {
        LoadingHandler.getInstance().refresh();
        this.uiRouter.stateService.go(StateName.CUSTOMER_LIST);
      },
      (error: any) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withForm(this.fForm);
          this.showValidationErrorToast();
        }
      });
  }

  private showValidationErrorToast() {
    this.toasterService.pop({
      timeout: UiConstants.ToastTimeoutLong,
      type: UiConstants.toastTypeError,
      title: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_TITLE),
      body: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_MESSAGE)
    });
  }

  private loadCustomerRecordFields(managedFields: Set<CustomerRecordFieldType>, requiredFields: Set<CustomerRecordFieldType>) {
    this.defaultManagedFields = this.configService.getConfiguration().feature_flags.customer_record_managed_fields
      .map(f => <CustomerRecordFieldType>f);
    CustomerRecordField.getFields().forEach(f => {
      if (managedFields.includes(f.type)) {
        this.model.managedFields.push({
          id: f.type,
          itemName: f.stringKey
        });
      }
      if (requiredFields.includes(f.type)) {
        this.model.requiredFields.push({
          id: f.type,
          itemName: f.stringKey,
          disabled: !this.defaultManagedFields.includes(f.type)
        });
        this.requiredFieldsInput.control.updateValueAndValidity();
      }
    });
    CustomerRecordField.getManageablePersonFields().forEach(f => {
      if (this.defaultManagedFields.includes(f.type)) {
        this.manageablePersonFields.push({
          id: f.type,
          itemName: f.stringKey
        });
      }
    });
    CustomerRecordField.getManageableCompanyFields().forEach(f => {
      if (this.defaultManagedFields.includes(f.type)) {
        this.manageableCompanyFields.push({
          id: f.type,
          itemName: f.stringKey
        });
      }
    });
    CustomerRecordField.getRequirablePersonFields().forEach(f => {
      this.requirablePersonFields.push({
        id: f.type,
        itemName: f.stringKey
      });
    });
    CustomerRecordField.getRequirableCompanyFields().forEach(f => {
      this.requirableCompanyFields.push({
        id: f.type,
        itemName: f.stringKey
      });
    });
  }

  loadUserGroups(q?: string) {
    this.userGroupMultiselectProvider.loadActive(q)
      .subscribe((result) => {
        this.userGroups = result;
      });
  }

  loadUsers(q?: string) {
    this.userMultiselectProvider.loadActive(q)
      .subscribe((result) => {
        this.ownerUsers = result;
      });
  }

  loadLedgerNumbers(q?: string) {
    if (this.rightModel.ledgerNumberRead.hasRight()) {
      this.ledgerNumberMultiselectProvider.loadActive(q)
        .subscribe((result) => {
          this.ledgerNumberList = result;
        });
    }
  }

  loadCustomerTypes() {
    const keys: string[] = [];
    Customer.customerTypes.forEach((type) => {
      const item = {id: type.type, text: '', key: type.stringKey};
      this.customerTypes.push(item);
      if (this.model.type && this.model.type.id === type.type) {
        this.model.type = item;
      }
      keys.push(type.stringKey);
    });
    this.translateService.get(keys).subscribe((texts) => {
      this.customerTypes.forEach((type) => {
        type.text = texts[type.key];
      });
    });
  }

  loadCustomerOwnerTypes(customer: Customer.Customer) {
    const keys: string[] = [];
    Customer.customerMobileOwnerTypes.forEach((type) => {
      const item = {id: type.type, text: '', key: type.stringKey};
      this.mobileOwnerTypes.push(item);
      if (this.model.mobileOwnerType && this.model.mobileOwnerType.id === type.type) {
        this.model.mobileOwnerType = item;
      }
      keys.push(type.stringKey);
    });
    Customer.customerWebOwnerTypes.forEach((type) => {
      const item = {id: type.type, itemName: type.stringKey};
      this.webOwnerTypes.push(item);
    });
    if (customer.webOwnerTypes !== undefined) {
      const userIds: number[] = [];
      const userGroupIds: number[] = [];
      customer.webOwnerTypes.forEach(o => {
        if (o) {
          this.model.webOwnerTypes.push(this.webOwnerTypes.find(t => t.id === o.ownerType)!);
          if (o.presetUserIds !== undefined && o.presetUserIds.size > 0) {
            userIds.push(...o.presetUserIds.toArray());
          }
          if (o.presetUserGroupIds !== undefined && o.presetUserGroupIds.size > 0) {
            userGroupIds.push(...o.presetUserGroupIds.toArray());
          }
        }
      });
      if (userIds.length > 0) {
        this.userMultiselectProvider.getByIds(userIds).subscribe(users => {
          this.model.webOwnerPresetUsers = users;
        });
      }
      if (userGroupIds.length > 0) {
        this.userGroupMultiselectProvider.getByIds(userGroupIds).subscribe(userGroups => {
          this.model.webOwnerPresetUserGroups = userGroups;
        });
      }
    }
    this.translateService.get(keys).subscribe((texts) => {
      this.mobileOwnerTypes.forEach((type) => {
        type.text = texts[type.key];
      });
    });
  }

  exportForm() {
    this.customerService.exportForm({
      parentId: this.customerId
    }).subscribe((result: Form.FormImportDocument) => {
      const blob = new Blob([JSON.stringify(result)], {type: 'text/json;charset=utf-8'});
      saveAs(blob, 'customer_' + this.customerId + '.json');
    });
  }

  hasLocalFieldError(field?: NgModel): boolean {
    return this.validatedInputs.hasLocalError(field);
  }

  hasFieldError(field?: Customer.ValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: Customer.ValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: Customer.ValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  back() {
    window.history.back();
  }

  private loadLocalFieldValidationErrors() {
    const validatedInputs = List.of(this.externalIdInput, this.nameInput, this.ownerGroupsInput);
    this.validatedInputs = LocalFieldValidationErrorsFactory.ofFormFields(this.fForm, validatedInputs);
  }

  private loadModel(customer: Customer.Customer) {
    this.model.name = customer.name;
    this.model.complexName = customer.complexName;
    this.model.contactPerson = customer.contactPerson;
    this.model.description = customer.description;
    this.model.externalId = customer.externalId ? customer.externalId : '';
    this.model.type = {id: customer.type, text: '', key: ''};
    this.model.mobileOwnerType = {id: customer.mobileOwnerType, text: '', key: ''};
    this.model.icon = customer.icon;
    this.loadCustomerTypes();
    this.loadCustomerOwnerTypes(customer);
    this.loadUserGroups();
    if (customer.defaultLedgerNumber) {
      this.model.defaultLedgerNumber = [this.ledgerNumberMultiselectProvider.toMultiselectOptionItem(customer.defaultLedgerNumber)];
    }
    if (customer.ownerUserGroupIds.length > 0) {
      this.userGroupMultiselectProvider.getByIds(customer.ownerUserGroupIds).subscribe((groups) => {
        this.model.userGroups = groups;
      })
    }
    this.model.useDefaultManagedFields = customer.useDefaultManagedFields;
    this.loadCustomerRecordFields(customer.managedFields, customer.requiredFields);
    this.model.explicitOrderNumber = customer.explicitOrderNumber;
  }

  onCustomerTypeChanged() {
    this.removeFieldError(Customer.ValidatedField.TYPE);
    this.model.managedFields = [];
    this.onManagedFieldsChanged();
  }

  onManagedFieldsChanged() {
    this.model.requiredFields = this.model.requiredFields.filter(f => this.model.managedFields.map(f => f.id).includes(f.id));
  }

}
