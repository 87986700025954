import { Injectable } from '@angular/core';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { HttpClient } from '@angular/common/http';
import { EmptyMessage } from '../util/messages';
import { Observable } from 'rxjs';

@Injectable()
export class NfcCardResourceService extends BaseHttpService {

  query(request: NfcCardResource.QueryRequest): Observable<NfcCardResource.NfcCard[]> {
    return this.http.get<NfcCardResource.NfcCard[]>(this.url + `${request.user_id}/nfc-cards`);
  }

  create(request: NfcCardResource.CreateRequest): Observable<EmptyMessage> {
    return this.http.post(this.url + `${request.user_id}/nfc-cards`, request);
  }

  delete(request: NfcCardResource.DeleteRequest): Observable<EmptyMessage> {
    return this.http.delete(this.url + `${request.user_id}/nfc-cards/${request.nfc_card_id}`);
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/users/');
  }

}

export namespace NfcCardResource {

  export interface NfcCard {
    id: number;
    hex_id: string;
    creation_time: string;
    mobile_application_registration_count: number;
  }

  export interface QueryRequest {
    user_id: number;
  }

  export interface CreateRequest {
    user_id: number;
    hex_id: string;
  }

  export interface DeleteRequest {
    user_id: number;
    nfc_card_id: number;
  }

}
