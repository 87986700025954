/* eslint-disable */
import { FormEditFieldCreateDialogView, FormEditFieldDialogViewFactory, FormEditFieldUpdateDialogView, } from './form-utils';
import { ComponentFactoryResolver, ComponentRef, Injector, } from '@angular/core';
import { FormEditCreateUpdateInvoiceFieldDialogComponent } from '../../admin/form/form-edit/dialogs/fields/invoice/create-update/form-edit-create-update-invoice-field-dialog.component';
/* eslint-enable */

/**
 * Invoice field implementation.
 */
export class FormEditInvoiceFieldDialogViewFactory implements FormEditFieldDialogViewFactory {

  constructor(
    private injector: Injector,
    private cfr: ComponentFactoryResolver) {
  }

  createCreateView(): ComponentRef<FormEditFieldCreateDialogView> {
    return this.createView();
  }

  createUpdateView(): ComponentRef<FormEditFieldUpdateDialogView> {
    return this.createView();
  }

  private createView(): ComponentRef<FormEditCreateUpdateInvoiceFieldDialogComponent> {
    return this.cfr.resolveComponentFactory(FormEditCreateUpdateInvoiceFieldDialogComponent)
      .create(this.injector);
  }

}
