import { Icon } from './icon.service';

export class IcomoonIcons implements Icon.IconSetModel {

  public readonly iconSetNameKey: string = 'ICON_SET_ICOMOON';
  public readonly iconSetCode: string = 'icomoon_appworks';
  public readonly iconSetClass: string = 'icomoon';
  public readonly usageType: Icon.UsageType = 'CLASS';
  public readonly iconSet: string[] = [
    'icomoon-applion-vip1',
    'icomoon-applion-vip2',
    'icomoon-applion-11',
    'icomoon-applion-17',
    'icomoon-applion-bp3',
    'icomoon-applion-bp11',
    'icomoon-applion-bp14',
    'icomoon-applion-bp16',
    'icomoon-applion-bp17',
    'icomoon-applion-bp19',
    'icomoon-applion-debr',
    'icomoon-applion-gyor',
    'icomoon-applion-kecs',
    'icomoon-applion-pecs',
    'icomoon-applion-szeg',
    'icomoon-applion-vesz',
    'icomoon-dem-vip1',
    'icomoon-dem-vip2',
    'icomoon-dem-11',
    'icomoon-dem-17',
    'icomoon-dem-bp3',
    'icomoon-dem-bp14',
    'icomoon-dem-bp16',
    'icomoon-dem-bp19',
    'icomoon-dem-debr',
    'icomoon-dem-gyor',
    'icomoon-dem-kecs',
    'icomoon-dem-pecs',
    'icomoon-dem-szeg',
    'icomoon-dem-vesz',
    'icomoon-own-17',
    'icomoon-own-bp3',
    'icomoon-own-bp11',
    'icomoon-own-bp14',
    'icomoon-own-bp16',
    'icomoon-own-bp17',
    'icomoon-own-bp19',
    'icomoon-own-debr',
    'icomoon-own-gyor',
    'icomoon-own-kecs',
    'icomoon-own-pecs',
    'icomoon-own-szeg',
    'icomoon-own-vesz',
    'icomoon-reg-11',
    'icomoon-reg-17',
    'icomoon-reg-bp3',
    'icomoon-reg-bp11',
    'icomoon-reg-bp14',
    'icomoon-reg-bp16',
    'icomoon-reg-bp17',
    'icomoon-reg-bp19',
    'icomoon-reg-debr',
    'icomoon-reg-gyor',
    'icomoon-reg-kecs',
    'icomoon-reg-pecs',
    'icomoon-reg-szeg',
    'icomoon-reg-vesz',
  ];

}
