/* eslint-disable */
import { Injectable } from '@angular/core';
import { Form } from '../form/form.service';
import { Icon, IconService } from '../task/icon.service';
import { Observable, Observer } from 'rxjs';
import { ObservableErrorResourceParser } from '../util/errors';
import { FieldValidationError, Order, PagingRequest, QueryResult, ResourceQueryResult, Services } from '../util/services';
import { List, Map, Set } from 'immutable';
import { OffsetDateTime } from '../util/dates';
import { LegacyWorkflowResource, LegacyWorkflowResourceService } from './legacy-workflow-resource-service';
/* eslint-enable */

@Injectable()
export class LegacyWorkflowService implements LegacyWorkflow.Service {

  private formMapper: Form.ResourceMapper;

  constructor(private resourceService: LegacyWorkflowResourceService,
              private iconService: IconService) {
    this.formMapper = new Form.ResourceMapper();
  }

  query(request: LegacyWorkflow.QueryRequest): Observable<QueryResult<LegacyWorkflow.Workflow>> {
    return Observable.create((observer: Observer<QueryResult<LegacyWorkflow.Workflow>>) => {
      const resourceRequest: LegacyWorkflowResource.QueryRequest = {
        workflow_id: Services.createIdParameter(request.workflowIdSet),
        disabled: request.disabled,
        name: request.name,
        external_id: request.externalId,
        description: request.description,
        creation_time_from: request.creationTimeFrom && request.creationTimeFrom.isValid()
          ? request.creationTimeFrom.toUtcIsoString()
          : undefined,
        creation_time_to: request.creationTimeTo && request.creationTimeTo.isValid() ? request.creationTimeTo.toUtcIsoString() : undefined,
        update_time_from: request.updateTimeFrom && request.updateTimeFrom.isValid() ? request.updateTimeFrom.toUtcIsoString() : undefined,
        update_time_to: request.updateTimeTo && request.updateTimeTo.isValid() ? request.updateTimeTo.toUtcIsoString() : undefined,
        q: request.queryText,
        order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined
      };
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<LegacyWorkflowResource.Workflow>) => {
          observer.next({
            items: this.toPublicList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  get(request: LegacyWorkflow.GetRequest): Observable<LegacyWorkflow.Workflow> {
    return Observable.create((observer: Observer<LegacyWorkflow.Workflow>) => {
      const resourceRequest: LegacyWorkflowResource.GetRequest = {
        workflow_id: request.workflowId
      };
      return this.resourceService.get(resourceRequest).subscribe(
        (result: LegacyWorkflowResource.Workflow) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  create(request: LegacyWorkflow.CreateRequest): Observable<LegacyWorkflow.WorkflowIdentity> {
    return Observable.create((observer: Observer<LegacyWorkflow.WorkflowIdentity>) => {
      const resourceRequest: LegacyWorkflowResource.CreateRequest = {
        name: request.name,
        description: request.description,
        external_id: request.externalId,
        icon: this.iconService.toResourceIcon(request.icon),
        usage_type: 'ORDER'
      };
      return this.resourceService.create(resourceRequest).subscribe(
        (result: LegacyWorkflowResource.WorkflowIdentity) => {
          observer.next(this.toPublicIdentity(result));
        },
        (error: any) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  update(request: LegacyWorkflow.UpdateRequest): Observable<LegacyWorkflow.Workflow> {
    return Observable.create((observer: Observer<LegacyWorkflow.Workflow>) => {
      const resourceRequest: LegacyWorkflowResource.UpdateRequest = {
        workflow_id: request.workflowId,
        name: request.name,
        description: request.description,
        external_id: request.externalId,
        icon: this.iconService.toResourceIcon(request.icon),
        version: request.version,
      };
      return this.resourceService.update(resourceRequest).subscribe(
        (result: LegacyWorkflowResource.Workflow) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  setDisabled(request: LegacyWorkflow.DisableRequest): Observable<LegacyWorkflow.Workflow> {
    return Observable.create((observer: Observer<LegacyWorkflow.Workflow>) => {
      const resourceRequest: LegacyWorkflowResource.DisableRequest = {
        workflow_id: request.workflowId,
        disabled: request.disabled
      };
      return this.resourceService.setDisabled(resourceRequest).subscribe(
        (result: LegacyWorkflowResource.Workflow) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  private translateError(error: any): any {
    const res = ObservableErrorResourceParser.parseError(error);
    const fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
    const fieldErrorMap = ObservableErrorResourceParser.toFieldErrorMap(Keys.toValidatedField, fieldErrors);
    if (!fieldErrorMap.isEmpty()) {
      return FieldValidationError.of(fieldErrorMap);
    }
    return error;
  }

  private toPublicList(resourceList: LegacyWorkflowResource.Workflow[]): List<LegacyWorkflow.Workflow> {
    return List.of(...resourceList.map((r) => this.toPublic(r)));
  }

  private toPublic(r: LegacyWorkflowResource.Workflow): LegacyWorkflow.Workflow {
    return {
      workflowId: r.workflow_id,
      disabled: r.disabled,
      creationTime: Services.toOffsetDateTime(r.creation_time),
      updateTime: Services.toOffsetDateTime(r.update_time),
      name: r.name,
      description: r.description,
      externalId: r.external_id,
      icon: this.iconService.toPublicIcon(r.icon),
      version: r.version
    };
  }

  private toPublicIdentity(r: LegacyWorkflowResource.WorkflowIdentity): LegacyWorkflow.WorkflowIdentity {
    return {
      workflowId: r.workflow_id
    };
  }
}

export namespace LegacyWorkflow {

  export interface Service {

    // <editor-fold desc="CRUD">

    query(request: QueryRequest): Observable<QueryResult<Workflow>>;

    get(request: GetRequest): Observable<Workflow>;

    create(request: CreateRequest): Observable<WorkflowIdentity>;

    update(request: UpdateRequest): Observable<Workflow>;

    setDisabled(request: DisableRequest): Observable<Workflow>;

    // </editor-fold>

  }

  // <editor-fold desc="CRUD">

  export interface Workflow {
    workflowId: number;
    disabled: boolean;
    creationTime: OffsetDateTime;
    updateTime: OffsetDateTime;
    name: string;
    description: string;
    externalId: string;
    version: number;
    icon?: Icon.Icon;
  }

  export interface QueryRequest {
    workflowIdSet?: Set<number>;
    disabled?: boolean;
    name?: string;
    externalId?: string;
    description?: string;
    creationTimeFrom?: OffsetDateTime;
    creationTimeTo?: OffsetDateTime;
    updateTimeFrom?: OffsetDateTime;
    updateTimeTo?: OffsetDateTime;
    queryText?: string;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
  }

  export interface GetRequest {
    workflowId: number;
  }

  export interface WorkflowIdentity {
    workflowId: number;
  }

  export interface CreateRequest {
    name: string;
    description?: string;
    externalId?: string;
    icon?: Icon.Icon;
  }

  export interface UpdateRequest {
    workflowId: number;
    name: string;
    version: number;
    description?: string;
    externalId?: string;
    icon?: Icon.Icon;
  }

  export interface DisableRequest {
    workflowId: number;
    disabled: boolean;
  }

  export enum OrderField {
    ID,
    DISABLED,
    CREATION_TIME,
    UPDATE_TIME,
    NAME,
    DESCRIPTION,
    EXTERNAL_ID
  }

  export enum ValidatedField {
    UNKNOWN,
    NAME,
    EXTERNAL_ID
  }

}

// <editor-fold desc="Internal">

class Keys {

  private static readonly ID = 'id';
  private static readonly DISABLED = 'disabled';
  private static readonly CREATION_TIME = 'creation_time';
  private static readonly UPDATE_TIME = 'update_time';
  private static readonly NAME = 'name';
  private static readonly DESCRIPTION = 'description';
  private static readonly EXTERNAL_ID = 'external_id';

  private static readonly orderFieldKeyMap: Map<LegacyWorkflow.OrderField, string> = Map.of(
    LegacyWorkflow.OrderField.ID, Keys.ID,
    LegacyWorkflow.OrderField.DISABLED, Keys.DISABLED,
    LegacyWorkflow.OrderField.CREATION_TIME, Keys.CREATION_TIME,
    LegacyWorkflow.OrderField.UPDATE_TIME, Keys.UPDATE_TIME,
    LegacyWorkflow.OrderField.NAME, Keys.NAME,
    LegacyWorkflow.OrderField.DESCRIPTION, Keys.DESCRIPTION,
    LegacyWorkflow.OrderField.EXTERNAL_ID, Keys.EXTERNAL_ID,
  );

  private static readonly keyValidatedFieldMap: Map<string, LegacyWorkflow.ValidatedField> = Map.of(
    Keys.NAME, LegacyWorkflow.ValidatedField.NAME,
    Keys.EXTERNAL_ID, LegacyWorkflow.ValidatedField.EXTERNAL_ID,
  );

  public static toOrderFieldKey(field: LegacyWorkflow.OrderField): string {
    return Keys.orderFieldKeyMap.get(field)!;
  }

  public static toValidatedField(fieldKey: string): LegacyWorkflow.ValidatedField {
    return Keys.keyValidatedFieldMap.get(fieldKey, LegacyWorkflow.ValidatedField.UNKNOWN);
  }

}

// </editor-fold>


