<div class="card">
  <div class="card-header">
    {{headerKey | translate}}
    <div class="card-actions">
      <a *ngIf="!listVisible" class="btn-setting cursor-pointer" (click)="toggleListVisible()"
         title="{{'COMMON_BUTTON_COLLAPSE_SHOW' | translate}}"><i class="icomoon icomoon-dropdown"></i></a>
      <a *ngIf="listVisible" class="btn-setting cursor-pointer" (click)="toggleListVisible()"
         title="{{'COMMON_BUTTON_COLLAPSE_HIDE' | translate}}"><i class="icomoon icomoon-arrow-up"></i></a>
      <a class="btn-setting table-header-action-button d-flex align-items-center px-1 w-auto font-weight-normal"
         (click)="onCreateClicked()"
         *ngIf="!readonly && !creationInProgress"
         title="{{'COMMON_BUTTON_ADD' | translate}}">
        <i class="icomoon pr-1 icomoon-add"></i>{{'COMMON_BUTTON_ADD' | translate}}
      </a>
    </div><!-- /.card-actions -->
  </div>
  <div class="card-body" *ngIf="listVisible">
    <table class="table table-striped table-bordered mb-0">
      <thead>
      <tr *ngIf="roles.length > 0">
        <th class="table-sorter-header">
          <app-table-sorter-no-op [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header">
          <app-table-sorter-no-op [text]="typeTableHeaderKey | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header">
          <app-table-sorter-no-op [text]="'PERMISSION_LIST_SEARCH_PANEL_HEADING_LIST' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header w-table-options-menu" *ngIf="!readonly">
          <app-table-sorter-no-op></app-table-sorter-no-op>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let role of roles">
        <td class="fit align-middle" *ngIf="!role.editing">{{role.id}}</td>
        <td class="responsive-table-column align-middle" *ngIf="!role.editing">
          <a *ngIf="(type === Type.USERGROUP_TASK && rightModel.taskUpdate.hasRight()) || (type === Type.USERGROUP_CUSTOMER && rightModel.customerUpdate.hasRight())"
             class="id-text cursor-pointer"
             href="#" onclick="return false"
             [uiSref]="typeDestinationStateName"
             [uiParams]="{ id: role.typeId }"
             title="{{'COMMON_BUTTON_EDIT' | translate}}">
            {{role.typeName}}
          </a>
          <span *ngIf="(type === Type.USERGROUP_TASK  || type === Type.USERGROUP_CUSTOMER) && !((type === Type.USERGROUP_TASK && rightModel.taskUpdate.hasRight()) || (type === Type.USERGROUP_CUSTOMER && rightModel.customerUpdate.hasRight()))">
            {{role.typeName}}
          </span>
          <div *ngIf="type === Type.TASK  || type === Type.CUSTOMER">
            <div *ngFor="let ug of role.userGroups">
              <a *ngIf="rightModel.userGroupRead.hasRight()"
                 class="id-text cursor-pointer"
                 href="#" onclick="return false"
                 [uiSref]="typeDestinationStateName"
                 [uiParams]="{ id: ug.id }"
                 title="{{'COMMON_BUTTON_DETAILS' | translate}}">
                {{ug.itemName}}
              </a>
              <span *ngIf="!rightModel.userGroupRead.hasRight()">{{ug.itemName}}</span>
            </div>
          </div>
          <div class="table-text-subtitle" *ngIf="type === Type.USERGROUP_TASK  || type === Type.USERGROUP_CUSTOMER">
            {{role.typeExternalId}}
          </div>
        </td>
        <td class="responsive-table-column align-middle" *ngIf="!role.editing && role.privileges.length <= 5">
          <div *ngFor="let p of role.privileges">{{p}}</div>
        </td>
        <td class="responsive-table-column align-middle" *ngIf="!role.editing && role.privileges.length > 5"
            [popover]="role.privileges.join(', ')"
            triggers="mouseenter:mouseleave" container="body">
          <div *ngFor="let i of [0, 1, 2, 3]">{{role.privileges[i]}}</div>
          <div class="text-muted">+{{role.privileges.length - 4}}</div>
        </td>
        <td class="align-middle" *ngIf="!readonly && !role.editing">
          <app-table-options-menu>
            <app-dropdown-item
              [iconClass]="'icomoon-modify'"
              [titleStringKey]="'COMMON_BUTTON_EDIT'"
              class="cursor-pointer"
              (click)="role.editing = true">
            </app-dropdown-item>
            <app-dropdown-item
              [iconClass]="'icomoon-trash'"
              [titleStringKey]="'COMMON_BUTTON_DELETE'"
              class="cursor-pointer"
              (click)="deleteRole(role.id)">
            </app-dropdown-item>
          </app-table-options-menu>
        </td>
        <td colspan="4" *ngIf="role.editing">
          <app-user-group-type-based-role-edit
            [type]="type"
            [_model]="role"
            (onSubmit)="updateRole($event)"
            (onCancel)="role.editing = false">
          </app-user-group-type-based-role-edit>
        </td>
      </tr>
      <tr *ngIf="creationInProgress">
        <td colspan="4">
          <app-user-group-type-based-role-edit
            [type]="type"
            (onSubmit)="createRole($event)"
            (onCancel)="creationInProgress = false">
          </app-user-group-type-based-role-edit>
        </td>
      </tr>
      <tr *ngIf="roles.length === 0 && !creationInProgress">
        <td colspan="4">
          <div class="d-flex justify-content-center font-weight-bold text-muted p-1">
            {{'USER_GROUP_EDIT_NO_ROLES' | translate}}
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
