/* eslint-disable */
import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild,} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Transition, UIRouter} from '@uirouter/angular';
import {combineLatest, empty, Observable, Subject} from 'rxjs';
import {TaskRecord, TaskRecordService} from '../../../../lib/task/task-record.service';
import {Task, TaskService} from '../../../../lib/task/task.service';
import {TaskRecordBase} from '../task-record-base/task-record-base-view';
import {TaskRecordBaseComponent} from '../task-record-base/task-record-base.component';
import {StateName} from '../../../../app.state-names';
import {FieldValidationError, QueryResult,} from '../../../../lib/util/services';
import {FormRecordContainer} from '../../../form/form-record/form-record-container';
import {FormRecordContainerComponent} from '../../../form/form-record/form-record-container.component';
import {Form} from '../../../../lib/form/form.service';
import {ConfigurationService} from '../../../../lib/core-ext/configuration.service';
import {NgbDatePickerParserFormatter} from '../../../../util/ngb-datepicker';
import {Set} from 'immutable';
import {EmptyMessage, IdentityMessage} from '../../../../lib/util/messages';
import {TaskRecordStateMachine} from '../../../../lib/task/task-record-statemachine';
import {Country, CountryService} from '../../../../lib/country.service';
import {AddressModel} from '../../../../lib/address';
import {StringKey} from '../../../../app.string-keys';
import {TranslateService} from '@ngx-translate/core';
import {BodyOutputType, ToasterService} from '../../../../fork/angular2-toaster/angular2-toaster';
import {UiConstants} from '../../../../util/core-utils';
import {BreadcrumbParent} from '../../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import {ConfigModel, TaskRecordRightModel, TaskRecordStateItem} from '../../../../util/task-record-utils';
import {ConfirmLeaveModalComponent} from '../../../../shared/confirm-leave-modal/confirm-leave-modal.component';
import {BsModalService, ModalDirective} from 'ngx-bootstrap/modal';
import {RefreshFormOnChangeService} from '../../../../lib/refresh-form-on-change.service';
import {TaskRecordSurveyComponent} from '../task-record-survey/task-record-survey.component';
import {GrantedPermissionSetResolver, RightResolver, RightService} from '../../../../lib/right.service';
import {RightModel} from '../../../../app.rights';
import {Logger, LoggerFactory} from '../../../../util/logger-factory';
import {ChatService} from '../../../../shared/chat/chat-service.interface';
import {CustomerRecord} from '../../../../lib/customer/customer-record.service';
import {TaskRecordSubTaskComponent} from '../task-record-sub-task/task-record-sub-task.component';
import {
  TaskRecordSubTaskCreateEditDialogComponent
} from '../task-record-sub-task/task-record-sub-task-create-edit-dialog/task-record-sub-task-create-edit-dialog.component';
import {
  TaskRecordSubTaskDetailDialogComponent
} from '../task-record-sub-task/task-record-sub-task-detail-dialog/task-record-sub-task-detail-dialog.component';
import {TaskRecordFormFieldUpdater} from '../task-record-base/task-record-form-field-updater';
import {
  TaskRecordInvoiceCreateDialogComponent
} from '../task-record-invoice-create-dialog/task-record-invoice-create-dialog.component';
import {Worklog} from '../../../../lib/worklog/worklog.service';
import {WorklogListComponent} from '../../../worklog/worklog-list/worklog-list.component';
import {TaskRecordMileageRecordComponent} from '../task-record-mileage-record/task-record-mileage-record.component';
import {AppTypeHelperService} from '../../../../lib/util/app-type-helper.service';
import {BulkCloneResult} from '../task-record-bulk-clone-dialog/task-record-bulk-clone-dialog.component';
import {ErrorMessage} from '../../../../lib/error-message-parser.service';
import {tap} from 'rxjs/operators';
import {OperationRights} from '../../../../app.right-definitions';
import {TaskRightModel} from '../../../../util/task-utils';
import {
  CustomerRecordBillingInfoComponentMode
} from '../../../customer/customer-record-billing-info/customer-record-billing-info-base/customer-record-billing-info-base.component';
import {MatDialog} from "@angular/material/dialog";
import {
  TaskRecordStateChangeDialogComponent
} from "../task-record-state-change-dialog/task-record-state-change-dialog.component";
import WorklogScope = Worklog.WorklogScope;

/* eslint-enable */

@Component({
  selector: 'app-new-task-record-edit',
  templateUrl: 'task-record-edit.component.html',
  styleUrls: ['task-record-edit.component.scss'],
})
export class TaskRecordEditComponent implements OnInit, AfterViewInit, OnDestroy {
  UiConstants = UiConstants;
  WorklogScope = WorklogScope;
  CustomerRecordBillingInfoDialogMode = CustomerRecordBillingInfoComponentMode;

  @ViewChild(TaskRecordBaseComponent, {static: true})
  baseView: TaskRecordBase.View;

  @ViewChild(FormRecordContainerComponent, {static: true})
  formRecordContainerView: FormRecordContainer.View;

  @ViewChild('f', {static: true})
  fForm: NgForm;

  @ViewChild('worklogList')
  worklogList: WorklogListComponent;

  @ViewChild('sendMessageDialog', {static: true})
  sendMessageDialog: ModalDirective;
  sendMessageDialogVisible: boolean = false;
  newMessage: string = '';
  messageSubmitted: boolean = false;

  forceSaveButton: boolean = false;

  @ViewChild('surveyView') surveyView: TaskRecordSurveyComponent;

  @ViewChild('taskRecordSubTask') taskRecordSubTask: TaskRecordSubTaskComponent;

  @ViewChild('taskRecordSubTaskCreateEditDialog', {static: true})
  taskRecordSubTaskCreateEditDialog: TaskRecordSubTaskCreateEditDialogComponent;

  @ViewChild('taskRecordSubTaskDetailDialog', {static: true}) taskRecordSubTaskDetailDialog: TaskRecordSubTaskDetailDialogComponent;

  @ViewChild('taskRecordSubTaskDeleteDialog', {static: true}) taskRecordSubTaskDeleteDialog: TaskRecordSubTaskDetailDialogComponent;

  @ViewChild('taskRecordInvoiceCreateDialog', {static: true}) taskRecordInvoiceCreateDialog: TaskRecordInvoiceCreateDialogComponent;

  @ViewChild('mileageRecordList') mileageRecordList: TaskRecordMileageRecordComponent;

  taskId: number;
  taskRecordId: number;
  taskRecordState: TaskRecordStateMachine.State;
  breadcrumbParents: BreadcrumbParent[] = [];
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  settableStateList: TaskRecordStateItem[] = [];
  taskName: string;
  taskRecordName: string;
  customerRecordId?: number;

  mileageRecordEnabled: boolean = false;

  deregisterWarningModal;
  saveButtonClicked: boolean = false;
  navigateOnSubmit: boolean = false;

  grantedRights: TaskRecordRightModel = new TaskRecordRightModel(GrantedPermissionSetResolver.empty());
  taskGrantedRights: TaskRightModel = new TaskRightModel(GrantedPermissionSetResolver.empty());

  combinedResult?: CombinedResult;

  rightModel: RightModel = RightModel.empty();

  private logger: Logger;

  private formFieldUpdater: TaskRecordFormFieldUpdater;
  private quickUpdate: boolean = false;

  config: ConfigModel = new ConfigModel();

  get customerRecord(): CustomerRecord.CustomerRecord | undefined {
    return this.baseView.getModel().customer ? this.baseView.getModel().customer!.customerRecord : undefined;
  }

  constructor(private dialog: MatDialog,
              private toasterService: ToasterService,
              private translateService: TranslateService,
              public configurationService: ConfigurationService,
              private taskService: TaskService,
              private modalService: BsModalService,
              private taskRecordService: TaskRecordService,
              private uiRouter: UIRouter,
              private rightService: RightService,
              private transition: Transition,
              public appTypeHelperService: AppTypeHelperService,
              private countryService: CountryService,
              private ngbDatePickerParserFormatter: NgbDatePickerParserFormatter,
              private refreshFormOnChangeService: RefreshFormOnChangeService) {
    this.logger = LoggerFactory.createLogger('TaskRecordEditComponent');
    this.taskId = this.transition.params().taskId;
    this.taskRecordId = this.transition.params().taskRecordId;
  }

  ngOnInit() {
    const context = this;
    this.deregisterWarningModal = this.uiRouter.transitionService.onStart({}, function (transition) {
      if (context.saveButtonClicked
        || transition.targetState().name() === StateName.CONNECTION_ERROR
        || transition.targetState().name() === StateName.SERVER_ERROR
        || transition.targetState().name() === StateName.LOGIN) {
        return true;
      } else {
        const subject = new Subject<boolean>();
        const modal = context.modalService.show(ConfirmLeaveModalComponent, {backdrop: 'static', keyboard: false});
        modal.content!.subject = subject;
        return subject.asObservable().toPromise();
      }
    });
    this.refreshFormOnChangeService.formChanged.subscribe((result) => {
        this.onFormFieldsUpdated();
      }
    );
    this.loadConfig();
  }

  private loadConfig() {
    this.config = this.configurationService.getConfigurationModel();
  }

  ngAfterViewInit(): void {
    this.loadRightModels(() => {
      this.load();
    });
  }

  private loadRightModels(completion: () => void) {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        completion();
      }
    );
  }

  private load() {
    this.createCombinedObservable().subscribe(
      (result: CombinedResult) => {
        this.taskName = result.task.name;
        this.taskRecordName = result.taskRecord.name;

        this.grantedRights = new TaskRecordRightModel(GrantedPermissionSetResolver.byGrantedRights(result.taskRecord.grantedRights));
        this.taskGrantedRights = new TaskRightModel(GrantedPermissionSetResolver.byGrantedRights(result.task.grantedRights));

        this.customerRecordId = result.taskRecord.customerRecord ? result.taskRecord.customerRecord.customerRecordId : undefined;
        this.taskRecordState = result.taskRecord.state;
        this.logger.debug('loading form record');
        this.formRecordContainerView.loadFormRecord({
          configuration: this.configurationService.getConfiguration(),
          form: result.form,
          record: result.taskRecord.formRecord,
        });
        this.formFieldUpdater = new TaskRecordFormFieldUpdater(result.form, this.formRecordContainerView);
        this.baseView.loadTaskRecord({
          taskRecord: result.taskRecord,
          task: result.task,
          countryItems: AddressModel.CountryItem.fromCountryList(result.countries.items),
          rightModel: this.rightModel,
          grantedRights: this.grantedRights
        });
        this.combinedResult = result;
        this.mileageRecordEnabled = result.task.mileageRecordEnabled;
        this.forceSaveButton = !Form.Forms.hasVisibleGroup(result.form);
        this.onCustomerRecordChanged();
      },
      () => {
        this.logger.debug('error');
      },
      () => {
        this.breadcrumbParents = [];
        this.translateService.get('MENU_NAVBAR_FORMS').subscribe(
          (result: string) => {
            this.breadcrumbParents.push({name: result, uiSref: StateName.TASK_DASHBOARD});
          }
        );
        this.breadcrumbParents.push({
          name: this.taskName,
          uiSref: StateName.TASK_RECORD_LIST,
          uiParam: {taskId: this.taskId}
        });
      }
    );
  }

  settableStateListLoader(settableStateList: TaskRecordStateItem[]) {
    this.settableStateList = settableStateList;
  }

  quickUpdateTaskRecord() {
    if (!this.combinedResult) {
      return;
    }
    if (this.baseView.hasLocalFieldError()) {
      this.showValidationErrorToast();
      return;
    }
    if (!this.baseView.validatePlaceOfConsumption()) {
      return;
    }
    const model = this.baseView.getModel();

    this.taskRecordService.quickUpdate({
      taskId: this.taskId,
      taskRecordId: this.taskRecordId,
      externalId: model.externalId,
      name: model.name,
      description: model.description,
      importance: model.importance && model.importance.id ? model.importance.id : TaskRecord.DefImportance,
      ownerUserId: model.ownerUser && model.ownerUser.id ? model.ownerUser.id : undefined,
      customerId: model.customer && model.customer.id ? model.customer.id : undefined,
      billingInfoId: model.billingInfoId,
      contactLocationId: model.contactLocationId,
      projectId: model.project[0] && model.project[0].id ? model.project[0].id! : undefined,
      agreedTime: this.ngbDatePickerParserFormatter.toOffsetDateTime(model.agreedTimeDate, model.agreedTimeTime),
      deadline: this.ngbDatePickerParserFormatter.toOffsetDateTime(model.deadlineDate, model.deadlineTime),
      usergroupId: model.usergroup && model.usergroup.id ? model.usergroup.id : undefined,
      assignee: model.getServiceAssignee(),
      placeOfConsumption: model.getServicePoc(),
      textDocumentIds: model.linkDocuments.map((doc: TaskRecordBase.DocumentItem) => doc.id),
      fileDocumentIds: model.fileDocuments.map((doc: TaskRecordBase.DocumentItem) => doc.id),
      linkedSurveyIds: model.linkedSurveyIds,
      estimatedTimeInMinutes: model.estimatedTimeInMinutes
    }).subscribe(
      (taskRecord: TaskRecord.TaskRecord) => {
        this.saveButtonClicked = true;
        if (this.navigateOnSubmit) {
          this.uiRouter.stateService.go(
            this.appTypeHelperService.isHelpdesk()
              ? StateName.HELPDESK_BUG_REPORT_LIST
              : StateName.TASK_RECORD_LIST,
            {taskId: this.taskId});
        } else {
          this.toasterService.pop({
            timeout: UiConstants.ToastTimeoutShort,
            type: UiConstants.toastTypeSuccess,
            title: this.translateService.instant(StringKey.COMMON_SUCCESS),
            body: this.translateService.instant(StringKey.DEVICE_EDIT_MESSAGE)
          });
          this.reload();
        }
      },
      (error) => {
        if (error instanceof FieldValidationError) {
          this.baseView.onFieldValidationError(error.withForm(this.fForm));
          this.showValidationErrorToast();
        }
      }
    );
  }

  updateTaskRecord() {
    this._updateTaskRecord().subscribe(
      (taskRecord: TaskRecord.TaskRecord) => {
        this.saveButtonClicked = true;
        if (this.navigateOnSubmit) {
          this.uiRouter.stateService.go(
            this.appTypeHelperService.isHelpdesk()
              ? StateName.HELPDESK_BUG_REPORT_LIST
              : StateName.TASK_RECORD_LIST,
            {taskId: this.taskId});
        } else {
          this.reload();
        }
      }
    );
  }

  private _updateTaskRecord(): Observable<TaskRecord.TaskRecord> {
    if (!this.combinedResult) {
      return empty();
    }
    if (this.baseView.hasLocalFieldError() || this.formRecordContainerView.hasLocalFieldError()) {
      this.showValidationErrorToast();
      return empty();
    }
    if (!this.baseView.validatePlaceOfConsumption()) {
      return empty();
    }
    if (this.formRecordContainerView.validateWithInterrupt()) {
      return empty();
    }

    const model = this.baseView.getModel();
    const formModel = this.formRecordContainerView.createModel();


    return this.taskRecordService.update({
      taskId: this.taskId,
      taskRecordId: this.taskRecordId,
      externalId: model.externalId,
      name: model.name,
      description: model.description,
      importance: model.importance && model.importance.id ? model.importance.id : TaskRecord.DefImportance,
      ownerUserId: model.ownerUser && model.ownerUser.id ? model.ownerUser.id : undefined,
      customerId: model.customer && model.customer.id ? model.customer.id : undefined,
      billingInfoId: model.billingInfoId,
      contactLocationId: model.contactLocationId,
      projectId: model.project[0] && model.project[0].id ? model.project[0].id! : undefined,
      agreedTime: this.ngbDatePickerParserFormatter.toOffsetDateTime(model.agreedTimeDate, model.agreedTimeTime),
      deadline: this.ngbDatePickerParserFormatter.toOffsetDateTime(model.deadlineDate, model.deadlineTime),
      usergroupId: model.usergroup && model.usergroup.id ? model.usergroup.id : undefined,
      assignee: model.getServiceAssignee(),
      contractNumberId: model.contractNumberId,
      placeOfConsumption: model.getServicePoc(),
      formRecord: {
        version: this.combinedResult!.taskRecord.formRecord!.version,
        fields: formModel.fields
      },
      textDocumentIds: model.linkDocuments.map((doc: TaskRecordBase.DocumentItem) => doc.id),
      fileDocumentIds: model.fileDocuments.map((doc: TaskRecordBase.DocumentItem) => doc.id),
      linkedSurveyIds: model.linkedSurveyIds,
      estimatedTimeInMinutes: model.estimatedTimeInMinutes
    }).pipe(tap(result => {
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutShort,
        type: UiConstants.toastTypeSuccess,
        title: this.translateService.instant(StringKey.COMMON_SUCCESS),
        body: this.translateService.instant(StringKey.DEVICE_EDIT_MESSAGE)
      });
    }, error => {
      if (error instanceof FieldValidationError) {
        this.baseView.onFieldValidationError(error.withForm(this.fForm));
        this.showValidationErrorToast();
      }
    }));
  }

  preStateChange(newState: TaskRecordStateMachine.State) {
    const stateObject: TaskRecordStateMachine.StateObject | undefined =
      TaskRecordStateMachine.taskRecordStates.find((s) => s !== undefined && s.state === newState);
    this.stateChange(stateObject);
  }

  stateChange(stateObject: TaskRecordStateMachine.StateObject) {
    let updateObservable: Observable<TaskRecord.TaskRecord> = empty();
    const newStateEditable = TaskRecordStateMachine.StateMachine.canEdit(this.combinedResult?.task, stateObject.state);
    const currentStateEditable = TaskRecordStateMachine.StateMachine.canEdit(this.combinedResult?.task, this.taskRecordState);
    if (currentStateEditable) {
      updateObservable = this._updateTaskRecord();
    }
    if (stateObject) {
      updateObservable.subscribe(value => {
        if (stateObject.state === 'REVERTED' || stateObject.state === 'ARCHIVED') {
          TaskRecordStateChangeDialogComponent.openDialog(this.dialog, {
            newState: stateObject,
            ids: Set.of(this.taskRecordId)
          }, result => {
            if (result?.success) {
              this.handleStateChange(newStateEditable);
            }
          });
        } else {
          this.taskRecordService.setState({
            taskRecordIdSet: Set.of(this.taskRecordId),
            newState: stateObject,
          }).subscribe((result: EmptyMessage) => {
              this.handleStateChange(newStateEditable);
            },
            (error: ErrorMessage | TaskRecord.TaskRecordInvoiceGeneratorException | TaskRecord.TaskRecordStockItemChangeException) => {
              let errorMessage: string = '';
              let errorTitleKey: string = StringKey.TASKS_STATE_MODIFY;
              if (error instanceof TaskRecord.TaskRecordInvoiceGeneratorException) {
                error.errors.forEach(value => {
                  errorMessage += value.taskRecordId + ':<br/>';
                  errorMessage += value.messages.join('<br/>');
                  errorMessage += '<br/>';
                });
                errorMessage.trim();
              }
              if (error instanceof TaskRecord.TaskRecordStockItemChangeException) {
                errorTitleKey = StringKey.STOCK_ITEM_CHANGE_ERROR_TITLE;
                error.errors.forEach(value => {
                  errorMessage += value.errors.map(e => this.translateService.instant(StringKey.STOCK_ITEM_CHANGE_ERROR, {
                    taskRecordId: value.taskRecordId,
                    stockRecordName: e.stockRecordName,
                    newAmount: e.newAmount,
                    currentAmount: e.currentAmount,
                  }))
                    .join('<br/>');
                  errorMessage += '<br/>';
                });
                errorMessage.trim();
              }
              if (error instanceof ErrorMessage) {
                errorMessage = error.globalErrors.join('<br/>');
                errorMessage.trim();
              }
              setTimeout(() => {
                this.toasterService.pop({
                  timeout: UiConstants.ToastTimeoutLong,
                  type: UiConstants.toastTypeError,
                  bodyOutputType: BodyOutputType.TrustedHtml,
                  title: this.translateService.instant(errorTitleKey),
                  body: errorMessage.length > 0 ? errorMessage : this.translateService.instant(StringKey.TASK_STATE_CHANGE_ERROR)
                });
              }, 200);
              this.reload();
            });
        }
      });
    }
  }

  private handleStateChange(newStateEditable: boolean) {
    this.toasterService.pop({
      timeout: UiConstants.ToastTimeoutShort,
      type: UiConstants.toastTypeSuccess,
      title: this.translateService.instant(StringKey.TASKS_STATE_MODIFY),
      body: this.translateService.instant(StringKey.TASK_STATE_CHANGE_SUCCESS)
    });
    if (this.config.navigateOnStateChange) {
      this.deregisterWarningModal();
      this.uiRouter.stateService.go(StateName.TASK_RECORD_LIST, {taskId: this.taskId});
    } else {
      if (newStateEditable) {
        this.reload();
      } else {
        this.deregisterWarningModal();
        this.uiRouter.stateService.go(StateName.TASK_RECORD_DETAIL, {
          taskId: this.taskId,
          taskRecordId: this.taskRecordId
        });
      }
    }
  }

  private createCombinedObservable(): Observable<CombinedResult> {
    return combineLatest(
      this.taskService.get({
        taskId: this.taskId,
        rights: Set.of(OperationRights.TASK_RECORD_CREATE)
      }),
      this.taskRecordService.get({
        withFormRecord: true,
        taskId: this.taskId,
        taskRecordId: this.taskRecordId,
        customerFields: TaskRecordBase.customerQueryFields,
        rights: TaskRecordBase.rightRequest
      }),
      this.countryService.query({}),
      (task: Task.Task,
       taskRecord: TaskRecord.TaskRecord,
       countries: QueryResult<Country.Country>) => {
        return {
          form: task.form!,
          task: task,
          taskRecord: taskRecord,
          countries: countries
        }
      }
    );
  }

  onCustomerRecordChanged() {
    this.formFieldUpdater.onCustomerRecordChanged(this.baseView.getModel().customer, this.baseView.getModel().contactLocationId);
    this.customerRecordId = this.baseView.getModel().customer ? this.baseView.getModel().customer!.id! : undefined;
  }

  onAssigneeUserChanged() {
    this.formFieldUpdater.onAssigneeUserChanged(this.baseView.getModel().assignee.user, this.taskRecordState);
  }

  onSelectedAddressChanged() {
    this.formFieldUpdater.onContactLocationChanged(this.baseView.getModel().contactLocationId);
  }

  private showValidationErrorToast() {
    this.toasterService.pop({
      timeout: UiConstants.ToastTimeoutLong,
      type: UiConstants.toastTypeError,
      title: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_TITLE),
      body: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_MESSAGE)
    });
  }

  ngOnDestroy() {
    this.deregisterWarningModal();
  }

  onFormFieldsUpdated() {
  }

  onRefresh() {
    this.reload();
  }

  openSendMessageDialog() {
    this.newMessage = '';
    this.messageSubmitted = false;
    this.sendMessageDialogVisible = true;
    this.sendMessageDialog.show();
  }

  closeSendMessageDialog() {
    this.sendMessageDialogVisible = false;
    this.sendMessageDialog.hide();
  }

  private sendMessage() {
    this.messageSubmitted = true;
    if (this.newMessage.length === 0) {
      return;
    }
    this.taskRecordService.sendMessage({
      parentId: this.taskId,
      id: this.taskRecordId,
      content: this.newMessage
    }).subscribe((result: IdentityMessage) => {
      this.closeSendMessageDialog()
    });
  }

  openInvoiceCreateDialog() {
    this.taskRecordInvoiceCreateDialog.show();
  }

  getChatService(): ChatService {
    return this.taskRecordService;
  }

  submit(navigateOnSubmit: boolean) {
    this.navigateOnSubmit = navigateOnSubmit;
    this.quickUpdate = false;
  }

  getTaskRecordService(): TaskRecordService {
    return this.taskRecordService;
  }

  onInvoiceCreateResult(success: boolean) {
    if (success) {
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutLong,
        type: UiConstants.toastTypeSuccess,
        title: this.translateService.instant(StringKey.TASK_RECORD_MANUAL_INVOICE_CREATE_SUCCESS_TITLE),
        body: this.translateService.instant(StringKey.TASK_RECORD_MANUAL_INVOICE_CREATE_SUCCESS_BODY)
      });
      this.load();
    } else {
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutLong,
        type: UiConstants.toastTypeError,
        title: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_TITLE),
        body: this.translateService.instant(StringKey.TASK_RECORD_MANUAL_INVOICE_CREATE_ERROR)
      });
    }
  }

  reloadWorklogList() {
    if (this.worklogList) {
      this.worklogList.loadList();
    }
  }

  isTaskRecordManagedField(field): boolean {
    return this.baseView.isManagedField(field);
  }

  onBulkCloneSuccess(result: BulkCloneResult) {
    this.deregisterWarningModal();
    this.uiRouter.stateService.go(StateName.TASK_RECORD_EDIT, {
      taskId: result.taskId,
      taskRecordId: result.taskRecordIds[0]
    });
  }

  onSubmit() {
    if (!this.quickUpdate) {
      this.updateTaskRecord();
    } else {
      this.quickUpdateTaskRecord();
    }
  }

  quickUpdateClicked() {
    this.navigateOnSubmit = false;
    this.quickUpdate = true;
  }

  canStartApproval() {
    return this.grantedRights.changeStateSubmit.hasRight() &&
      TaskRecordStateMachine.StateMachine.canStartApproval(this.taskRecordState, this.combinedResult?.task);
  }

  canCancelApproval() {
    return this.grantedRights.changeStateSubmit.hasRight() &&
      TaskRecordStateMachine.StateMachine.canCancelApproval(this.taskRecordState, this.combinedResult?.task);
  }

  onStartApproval() {
    let updateObservable: Observable<TaskRecord.TaskRecord> = empty();

    const currentStateEditable = TaskRecordStateMachine.StateMachine.canEdit(this.combinedResult?.task, this.taskRecordState);
    if (currentStateEditable) {
      updateObservable = this._updateTaskRecord();
    }

    updateObservable.subscribe(r => {
      this.taskRecordService.startApproval({
        taskId: this.taskId,
        taskRecordId: this.taskRecordId
      }).subscribe(result => {
        this.deregisterWarningModal();
        this.uiRouter.stateService.go(StateName.TASK_RECORD_DETAIL, {
          taskId: this.taskId,
          taskRecordId: this.taskRecordId
        });
      });
    });
  }

  reload() {
    this.deregisterWarningModal();
    this.uiRouter.stateService.reload();
  }
}

interface CombinedResult {
  form: Form.Form;
  task: Task.Task;
  taskRecord: TaskRecord.TaskRecord;
  countries: QueryResult<Country.Country>;
}
