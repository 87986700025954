<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="card">

    <div class="card-header">
      {{'MENU_NAVBAR_NAV_TITLE_EXTERIOR_TRANSPORT' | translate}}

      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()" title="{{'COMMON_BUTTON_SEARCH' | translate}}"><i
          class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>

    <div class="card-body border-bottom" *ngIf="showSearch">
      <div class="row form-group">
        <div class="col-md-4">
          <label class="search-label">{{'EXTERIOR_TRANSPORT_SERIAL_NUMBER' | translate}}</label>
          <input type="text" class="form-control"
                 placeholder="{{'EXTERIOR_TRANSPORT_SERIAL_NUMBER' | translate}}"
                 maxlength="{{UiConstants.maxInputLongLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.serialNumber">
        </div>
        <div class="col-md-4">
          <label class="search-label">{{'EXTERIOR_TRANSPORT_TRANSPORTER_COMPANY' | translate}}</label>
          <angular2-multiselect [data]="transporterCompanies" [settings]="dropdownSettings"
                                class="form-control"
                                [(ngModel)]="searchModel.transporterCompany"
                                (onRemoteSearch)="onTransporterCompanySearch($event.target.value)"
                                #company="ngModel" name="company" id="company">
          </angular2-multiselect>
        </div>
        <div class="col-md-4">
          <label class="search-label"> {{ 'COMMON_STATE' | translate }} </label>
          <select
            class="form-control"
            [(ngModel)]="searchModel.state" [compareWith]="SelectUtils.compareObjects">
            <option *ngFor="let item of states" [ngValue]="item">{{item.stringKey | translate}}</option>
          </select>
        </div>
      </div>
      <div class="col-md-12 d-flex justify-content-md-end align-items-end px-0">
        <div class="btn-group" role="group">
          <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
            {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
          </button>
          <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
            {{'COMMON_BUTTON_SEARCH' | translate}}
          </button>
        </div>
      </div>
    </div><!-- /.card-body-->
    <div class="card-body">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="ExteriorTransportSearch.OrderFunctions.ID"
                                    [orderType]="queryModel.getOrderType(ExteriorTransportSearch.OrderFunctions.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="ExteriorTransportSearch.OrderFunctions.ID"
                                    [orderType]="queryModel.getOrderType(ExteriorTransportSearch.OrderFunctions.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'EXTERIOR_TRANSPORT_SERIAL_NUMBER' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-sorter-no-op
              [text]="'EXTERIOR_TRANSPORT_DEMANDER_COMPANY' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-sorter-no-op
              [text]="'EXTERIOR_TRANSPORT_TRANSPORTER_COMPANY' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op
              [text]="'COMMON_STATE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op
              [text]="'COMMON_CREATION_TIME' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-actions">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let transport of exteriorTransportList | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }">
          <td class="responsive-table-column">{{transport.id}}</td>
          <td class="responsive-table-column">{{transport.serialNumber}}</td>
          <td class="responsive-table-column hidden-md-down">{{transport.demanderCompanyName | translate}}</td>
          <td class="responsive-table-column hidden-xs-down">{{transport.transporterCompany.name}}</td>
          <td class="responsive-table-column">{{transport.stateStringKey | translate}}</td>
          <td class="responsive-table-column">{{transport.creationTime.toUtcIsoString() | date: 'short'}}</td>
          <td class="responsive-table-column">
            <a class="icons-table-group cursor-pointer" uiSref="Admin.ExteriorTransportDetail"
               [uiParams]="{ id: transport.id }">
              <i class="icomoon icons-table-item icomoon-detail icomoon-detail-table"
                 title="{{'COMMON_BUTTON_DETAILS' | translate}}"></i>
            </a>
            <a class="icons-table-group cursor-pointer" uiSref="Admin.ExteriorTransportEdit"
               [uiParams]="{ id: transport.id }">
              <i class="icomoon icons-table-item icomoon-modify icomoon-detail-table"
                 title="{{'COMMON_BUTTON_EDIT' | translate}}"></i>
            </a>
            <a class="icons-table-group cursor-pointer" (click)="deleteDialog.showDialog(transport.id)"
               *ngIf="transport.rights.delete.hasRight()">
              <i class="icomoon icons-table-item icomoon-trash icomoon-detail-table"
                 title="{{'COMMON_BUTTON_DELETE' | translate}}"></i>
            </a>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="ExteriorTransportSearch.OrderFunctions.ID"
                                    [orderType]="queryModel.getOrderType(ExteriorTransportSearch.OrderFunctions.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="ExteriorTransportSearch.OrderFunctions.ID"
                                    [orderType]="queryModel.getOrderType(ExteriorTransportSearch.OrderFunctions.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'EXTERIOR_TRANSPORT_SERIAL_NUMBER' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-sorter-no-op
              [text]="'EXTERIOR_TRANSPORT_DEMANDER_COMPANY' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-sorter-no-op
              [text]="'EXTERIOR_TRANSPORT_TRANSPORTER_COMPANY' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op
              [text]="'COMMON_STATE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op
              [text]="'COMMON_CREATION_TIME' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-actions">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>
  </div>
</div>

<app-confirm-dialog
    #deleteDialog
    (onConfirm)="deleteExteriorTransport($event)">
</app-confirm-dialog>
