import { DqlQuery, Query } from '../field';
import { LazyReference } from '../../../util/lazy';
import { PostalAddress } from './postal-address';
import { Coordinate } from './coordinate';
import { DeliveryMethod } from './delivery-method';

export class ParcelCollectionPoint {

  readonly id: Query.NumberField;

  readonly creationTime: Query.DateTimeField;

  readonly updateTime: Query.DateTimeField;

  readonly name: Query.StringField;

  readonly code: Query.StringField;

  readonly openingTimes: Query.StringField;

  readonly note: Query.StringField;

  readonly disabled: Query.BooleanField;

  private readonly postalAddressReference: LazyReference<PostalAddress>; // Lazy to support circular references.

  private readonly coordinatesReference: LazyReference<Coordinate>; // Lazy to support circular references.

  private readonly deliveryMethodReference: LazyReference<DeliveryMethod>; // Lazy to support circular references.

  get postalAddress(): PostalAddress {
    return this.postalAddressReference.get();
  }

  get coordinates(): Coordinate {
    return this.coordinatesReference.get();
  }

  get deliveryMethod(): DeliveryMethod {
    return this.deliveryMethodReference.get();
  }

  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this.id = new DqlQuery.NumberField(prefix + 'id');
    this.creationTime = new DqlQuery.DateTimeField(prefix + 'creation_time');
    this.updateTime = new DqlQuery.DateTimeField(prefix + 'update_time');
    this.name = new DqlQuery.StringField(prefix + 'name');
    this.code = new DqlQuery.StringField(prefix + 'code');
    this.openingTimes = new DqlQuery.StringField(prefix + 'opening_times');
    this.note = new DqlQuery.StringField(prefix + 'note');
    this.disabled = new DqlQuery.BooleanField(prefix + 'disabled');
    this.postalAddressReference = LazyReference.of(
      () => new PostalAddress(prefix + 'postal_address.')
    );
    this.coordinatesReference = LazyReference.of(
      () => new Coordinate(prefix + 'coordinates.')
    );
    this.deliveryMethodReference = LazyReference.of(
      () => new DeliveryMethod(prefix + 'delivery_method.')
    );
  }

}
