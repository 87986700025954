/* eslint-disable */
import { Logger, LoggerFactory } from '../../../../../util/logger-factory';
import { Form } from '../../../../../lib/form/form.service';
import { Set as ImmutableSet } from 'immutable';
import { FormRecordListMultiItemFieldView, FormRecordListMultiItemValue } from '../../../../../util/form/form-utils';
import {
  FieldActivationStrategy,
  FieldActivationStrategyCalculateInactivatesArgs,
  FieldActivationStrategyPutRulesArgs,
  FieldViewItem,
} from '../form-record-inactivity-manager-api';
import { FieldActivationStrategyUtils } from './field-activation-strategy-utils';
import { Injectable } from '@angular/core';

/* eslint-enable */

@Injectable()
export class ListMultiItemFieldActivationStrategy implements FieldActivationStrategy {

  private logger: Logger = LoggerFactory.createLogger('ListMultiItemFieldActivationStrategy');

  putRules(args:  FieldActivationStrategyPutRulesArgs) {
    const attrs: Form.FieldDataTypeListMultiItemAttributes = args.viewItem.field.dataType.listMultiItemAttributes!;
    if (attrs.fieldActivationEnabled) {
      const ids: Set<number> = new Set();
      attrs.activatedFieldIdsByItemId.forEach((activatedFieldIds: ImmutableSet<number>, itemId: number) => {
        activatedFieldIds!.forEach(activatedFieldId => ids.add(activatedFieldId!));
      });
      attrs.activatedGroupIdsByItemId.forEach((activatedGroupIds: ImmutableSet<number>, itemId: number) => {
        activatedGroupIds!.forEach(activatedGroupId => {
          args.form.groups
            .find(group => group!.groupId === activatedGroupId)!
            .fields.forEach(field => ids.add(field!.fieldId));
        });
      });
      ids.forEach(id => {
        const fieldViewToActivate = args.viewItems.findByFieldId(id);
        if (fieldViewToActivate) {
          this.logger.debug('ListMultiItem "%s" activates "%s"', args.viewItem.fieldTitle, fieldViewToActivate.fieldTitle);
          args.builder.put(args.viewItem, fieldViewToActivate);
        }
      });
    }
  }

  async calculateInactivates(args: FieldActivationStrategyCalculateInactivatesArgs): Promise<FieldViewItem[]> {
    const view: FormRecordListMultiItemFieldView = <FormRecordListMultiItemFieldView> args.viewItem.view;
    const values: number[] = await this.getListMultiItemValues(view);
    const attrs = args.viewItem.field.dataType.listMultiItemAttributes!;
    if (values.length === 0) {
      return args.activatees;
    }
    else {
      const mergedActivatedFieldIds: number[] = [];
      for (const value of values) {
        const activatedFieldIds = attrs.activatedFieldIdsByItemId.get(value);
        if (activatedFieldIds) {
          mergedActivatedFieldIds.push(...activatedFieldIds.toArray());
        }
        const activatedGroupIds = attrs.activatedGroupIdsByItemId.get(value);
        if (activatedGroupIds) {
          activatedGroupIds.forEach(groupId =>
            mergedActivatedFieldIds.push(...args.form.groups.find(group => group!.groupId === groupId)
              .fields.toArray().map(field => field.fieldId)))
        }
      }
      const activatedViewItems: FieldViewItem[] = FieldActivationStrategyUtils.collectViewItemsFromFieldIdSet(
        ImmutableSet(mergedActivatedFieldIds),
        args.activatees
      );
      return FieldActivationStrategyUtils.without(args.activatees, activatedViewItems);
    }
  }

  private async getListMultiItemValues(view: FormRecordListMultiItemFieldView): Promise<number[]> {
    const values: FormRecordListMultiItemValue[] = await view.valueReference.get();
    return values
      .filter((value) => {
        return value && value.id !== null;
      })
      .map((value) => {
        return value.id!;
      });
  }

}
