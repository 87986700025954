import {Component, OnInit} from '@angular/core';
import {Process, ProcessService} from '../../../lib/process/process.service';
import {Set} from 'immutable';
import {QueryResult} from '../../../lib/util/services';

@Component({
  selector: 'app-process-message-list',
  templateUrl: './process-message-list.component.html',
  styleUrls: ['./process-message-list.component.scss']
})
export class ProcessMessageListComponent implements OnInit {

  currentPage: number = 1;
  totalNumberOfItems: number = 0;
  messageList: Process.RecentMessage[] = [];

  constructor(private processService: ProcessService) {
  }

  ngOnInit(): void {
    this.loadMessages();
  }


  loadMessages(afterLoad?: boolean) {
    if (afterLoad) {
      this.currentPage++;
    }
    this.processService.getRecentMessages({
      paging: {
        pageNumber: afterLoad ? this.currentPage : 1,
        numberOfItems: 10
      },
      fields: field => field.forMessages
    }).subscribe(
      (result: QueryResult<Process.RecentMessage>) => {
        if (!afterLoad) {
          this.messageList = result.items.toArray();
        } else {
          result.items.toArray().forEach((m) => {
            this.messageList.push(m);
          });
          const messageContainers = document.getElementsByClassName('process-message-container');
          messageContainers[messageContainers.length - (this.currentPage - 1) * 10].scrollIntoView();
        }
        this.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      }
    );
  }
}


