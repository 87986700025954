import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UiConstants } from '../../../../util/core-utils';
import { InputMask } from '../../../../util/input-masks';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { FieldValidationError, ForwardingNgFormRef, LocalFormGroupValidationErrors, Services } from '../../../../lib/util/services';
import { InvoiceTag, InvoiceTagService } from '../../../../lib/invoice/tag/invoice-tag.service';
import { StockItemUnitOfMeasure } from '../../../../lib/stock/stock-item-unit-of-measure';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NgbDatePickerParserFormatter } from '../../../../util/ngb-datepicker';
import { InvoiceTagDialogData } from '../../../invoicing/invoice-tag/invoice-tag-dialog/invoice-tag-dialog.component';
import { StockItemService } from '../../../../lib/stock/stock-item.service';
import { Strings } from '../../../../lib/util/strings';
import { EmptyMessage } from '../../../../lib/util/messages';
import { Numbers } from '../../../../lib/util/numbers';
import { Models } from '../../../../util/model-utils';
import {
  TaskRecordCreateDialogData,
  TaskRecordCreateDialogResult
} from '../../../task/task-record/task-record-create-material-dialog/task-record-create-material-dialog.component';
import { AppValidators } from '../../../../util/app-validators';

@Component({
  selector: 'app-stock-item-measurement-dialog',
  templateUrl: './stock-item-measurement-dialog.component.html',
  styleUrls: ['./stock-item-measurement-dialog.component.scss']
})
export class StockItemMeasurementDialogComponent {

  UiConstants = UiConstants;
  InputMask = InputMask;
  ValidatedField = StockItemUnitOfMeasure.ValidatedField;

  @ViewChild('f', {static: true})
  form: NgForm;
  formGroup: FormGroup;
  private formGroupValidationErrors: LocalFormGroupValidationErrors;
  private fieldErrors: FieldValidationError<StockItemUnitOfMeasure.ValidatedField> =
    FieldValidationError.empty<StockItemUnitOfMeasure.ValidatedField>();

  measurement?: StockItemUnitOfMeasure.StockItemUnitOfMeasure;

  model: StockItemUnitOfMeasureModel = new StockItemUnitOfMeasureModel();

  get readonly(): boolean {
    return this.data.readonly;
  }

  constructor(public dialogRef: MatDialogRef<StockItemMeasurementDialogComponent>,
              private translateService: TranslateService,
              private datePickerParserFormatter: NgbDatePickerParserFormatter,
              private fb: FormBuilder,
              private stockItemService: StockItemService,
              @Inject(MAT_DIALOG_DATA) public data: StockItemMeasurementDialogData) {
    this.loadModel(() => {
      this.formGroup = this.createFormGroup(this.fb);
      this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(
        this.createForwardingHtmlForm(),
        this.formGroup
      );
    });
  }

  public static openDialog(
    dialog: MatDialog,
    data: StockItemMeasurementDialogData,
    resultCallback: (result?: StockItemMeasurementDialogResult) => void) {
    const dialogRef = dialog.open(StockItemMeasurementDialogComponent, {
      closeOnNavigation: true,
      data: data,
      width: '60%',
      height: 'auto',
      panelClass: 'custom-dialog-container'
    });
    dialogRef.afterClosed().subscribe((result: StockItemMeasurementDialogResult) => {
      resultCallback(result);
    });
  }

  closeDialog(modified: boolean) {
    this.dialogRef.close({modified: modified});
  }

  save() {
    if (this.hasLocalFieldError()) {
      return;
    }
    else {
      if (this.data.measurementId) {
        this.updateStockItemUnitOfMeasure();
      }
      else {
        this.createStockItemUnitOfMeasure();
      }
    }
  }

  hasFieldError(field?: StockItemUnitOfMeasure.ValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: StockItemUnitOfMeasure.ValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: StockItemUnitOfMeasure.ValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  private createForwardingHtmlForm() {
    return new ForwardingNgFormRef({
      formFn: () => {
        return this.form;
      }
    });
  }

  private createFormGroup(fb: FormBuilder): FormGroup {
    return fb.group(
      {
        name: fb.control(
          {value: this.model.name, disabled: this.readonly},
          [Validators.required]
        ),
        code: fb.control(
          {value: this.model.code, disabled: this.readonly},
          [Validators.required]
        ),
        conversion: fb.control(
          {value: this.model.conversion, disabled: this.readonly || this.measurement?.base_unit},
          [Validators.required, AppValidators.validateOptionalPositiveNumber]
        ),
      }
    );
  }

  onNameBlur() {
    const normalizedText = Models.normalizeText(this.model.name);
    if (this.model.code === '') {
      this.model.code = normalizedText;
    }
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  createStockItemUnitOfMeasure() {
    this.formGroup.updateValueAndValidity();
    if (this.formGroup.invalid) {
      return;
    }
    this.stockItemService.createUnitOfMeasure({
      stock_item_id: this.data.stockItemId,
      code: this.model.code,
      unit_name: this.model.name,
      unit_conversion: Models.parseNumber(this.model.conversion)!,
    }).subscribe(
      (result: EmptyMessage) => {
        this.closeDialog(true);
      },
      error => {

      });
  }

  updateStockItemUnitOfMeasure() {
    this.formGroup.updateValueAndValidity();
    if (this.formGroup.invalid) {
      return;
    }
    this.stockItemService.updateUnitOfMeasure({
      id: this.data.measurementId!,
      stock_item_id: this.data.stockItemId,
      code: this.model.code,
      unit_name: this.model.name,
      unit_conversion: Models.parseNumber(this.model.conversion)!,
    }).subscribe(
      (result: EmptyMessage) => {
        this.closeDialog(true);
      },
      error => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error;
        }
      });
  }

  private loadModel(completion: ()=>void) {
    this.model = new StockItemUnitOfMeasureModel();

    if (this.data.measurementId) {
      this.stockItemService.queryUnitOfMeasures({
        stock_item_id: this.data.stockItemId,
        id: ''+this.data.measurementId,
        include_base_unit: true,
        number_of_items: 1,
        page_number: 1
      }).subscribe(list => {
        const l = list.items[0];
        this.model.name = l.unit_name;
        this.model.conversion = ''+l.unit_conversion;
        this.model.code = l.code;
        this.measurement = l;
        completion();
      });
    } else {
      completion();
    }
  }

  getDialogTitle(): string {
    if (this.data.readonly) {
      return 'STOCK_ITEM_UNIT_OF_MEASURE';
    }
    if (this.data.measurementId) {
      return 'STOCK_ITEM_UNIT_OF_MEASURE_UPDATE';
    }
    return 'STOCK_ITEM_UNIT_OF_MEASURE_CREATE';
  }
}

export class StockItemUnitOfMeasureModel {
  name: string = '';
  code: string = '';
  conversion: string = '';
}

export class StockItemMeasurementDialogData {
  stockItemId: number;
  measurementId?: number;
  readonly: boolean;
}

export class StockItemMeasurementDialogResult {
  modified: boolean;
}
