<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div class="breadcrumb-menu">
    <!--CREATE-->
    <div *ngIf="rightModel.surveyCreate.hasRight()"
         class="breadcrumb-button-icon-container cursor-pointer negative-margin-right"
         uiSref="Admin.SurveyCreate">
      <a class="btn-setting d-flex align-items-center" title="{{'COMMON_BUTTON_ADD' | translate}}">
        <i class="icomoon icomoon-add"></i>
        <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_ADD' | translate}}</span>
      </a>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="card">

    <div class="card-header">
      {{'SURVEY_PANEL_HEADING_LIST' | translate}}

      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()" title="{{'COMMON_SHOW_SEARCH_HINT' | translate}}">
          <i #searchIcon class="icomoon icomoon-search"></i>
        </a>
      </div><!-- /.card-actions -->
    </div>

    <div class="card-body border-bottom" *ngIf="showSearch">
      <div class="row form-group">
        <!--NAME-->
        <div class="col-md-4">
          <label class="search-label">{{'SURVEY_SEARCH_FIELD_ID' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'SURVEY_SEARCH_FIELD_ID' | translate}}"
                 maxlength="{{UiConstants.maxInputLongLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [textMask]="{mask: InputMask.NATURAL_INTEGER, guide: false}"
                 [(ngModel)]="searchModel.id" name="id" id="id" #id="ngModel">
        </div>
        <div class="col-md-4">
          <label class="search-label">{{'COMMON_NAME' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.name" name="name" id="name" #name="ngModel">
        </div>
        <div class="col-md-4">
          <label class="search-label">{{'SURVEY_SEARCH_FIELD_DISABLED' | translate}}</label>
          <select class="form-control" [compareWith]="SelectUtils.compareObjects" [(ngModel)]="searchModel.disabled"
                  id="disabled" name="disabled" #disabled="ngModel">
            <option *ngFor="let item of surveyDisabledItems" [ngValue]="item">{{item.text}}</option>
          </select>
        </div>
      </div>
      <div class="col-md-12 d-flex justify-content-md-end align-items-end px-0">
        <div class="btn-group" role="group">
          <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
            {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
          </button>
          <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
            {{'COMMON_BUTTON_SEARCH' | translate}}
          </button>
        </div>
      </div>
    </div>

    <div class="card-body">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header w-5 hidden-xs-down">
            <app-table-field-sorter [orderField]="Survey.OrderField.ID" [orderType]="queryModel.getOrderType(Survey.OrderField.ID)"
                                    (orderChange)="orderBy($event)" [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header long">
            <app-table-field-sorter [orderField]="Survey.OrderField.NAME" [orderType]="queryModel.getOrderType(Survey.OrderField.NAME)"
                                    (orderChange)="orderBy($event)" [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-15 hidden-md-down">
            <app-table-field-sorter [orderField]="Survey.OrderField.CREATION_TIME"
                                    [orderType]="queryModel.getOrderType(Survey.OrderField.CREATION_TIME)" (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_CREATION_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-15 hidden-md-down">
            <app-table-field-sorter [orderField]="Survey.OrderField.UPDATE_TIME"
                                    [orderType]="queryModel.getOrderType(Survey.OrderField.UPDATE_TIME)" (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_UPDATE_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-table-actions">
            <app-table-sorter-no-op [text]="'COMMON_TABLE_HEADER_ACTIONS' | translate"></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let survey of surveyList | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }"
            [disabledTableRow]="survey.disabled">
          <td class="responsive-table-column hidden-xs-down">{{survey.id}}</td>
          <td class="responsive-table-column long">{{survey.name}}
            <br/>
            <span class="text-truncate table-text-subtitle">{{survey.externalId}}</span>
          </td>
          <td class="responsive-table-column hidden-md-down">{{survey.creationTime.toUtcIsoString() | date:'short'}}</td>
          <td class="responsive-table-column hidden-md-down">{{survey.updateTime.toUtcIsoString() | date:'short'}}</td>
          <td class="responsive-table-column">
            <a class="icons-table-group" href="#" onclick="return false" (click)="setDisabled($event, survey, true)"
               *ngIf="!survey.disabled && rightModel.surveyDisable.hasRight()">
              <i class="icomoon icons-table-item icomoon-disable icomoon-disable-table" title="{{'COMMON_BUTTON_DISABLE' | translate}}"></i>
            </a>
            <a class="icons-table-group" href="#" onclick="return false" (click)="setDisabled($event, survey, false)"
               *ngIf="survey.disabled && rightModel.surveyDisable.hasRight()">
              <i class="icomoon icons-table-item icomoon-enable icomoon-enable-table" title="{{'COMMON_BUTTON_ENABLE' | translate}}"></i>
            </a>
            <a *ngIf="rightModel.surveyUpdate.hasRight()"  class="icons-table-group" uiSref="Admin.SurveyEdit" [uiParams]="{ id: survey.id }">
              <i class="icomoon icons-table-item icomoon-modify icomoon-modify-table" title="{{'COMMON_BUTTON_EDIT' | translate}}"></i>
            </a>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header w-5 hidden-xs-down">
            <app-table-field-sorter [orderField]="Survey.OrderField.ID" [orderType]="queryModel.getOrderType(Survey.OrderField.ID)"
                                    (orderChange)="orderBy($event)" [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header long">
            <app-table-field-sorter [orderField]="Survey.OrderField.NAME" [orderType]="queryModel.getOrderType(Survey.OrderField.NAME)"
                                    (orderChange)="orderBy($event)" [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-15 hidden-md-down">
            <app-table-field-sorter [orderField]="Survey.OrderField.CREATION_TIME"
                                    [orderType]="queryModel.getOrderType(Survey.OrderField.CREATION_TIME)" (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_CREATION_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-15 hidden-md-down">
            <app-table-field-sorter [orderField]="Survey.OrderField.UPDATE_TIME"
                                    [orderType]="queryModel.getOrderType(Survey.OrderField.UPDATE_TIME)" (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_UPDATE_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-table-actions">
            <app-table-sorter-no-op [text]="'COMMON_TABLE_HEADER_ACTIONS' | translate"></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>
  </div>
</div>
