<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
  <div class="container-horizontal-padding container-vertical-padding animated fadeIn">
    <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 p-0">
      <div class="card">

        <div class="card-header">
          {{'DOCUMENT_GROUP_EDIT_TITLE'|translate}}
        </div>

        <div class="card-body">
          <form class="form-horizontal bordered-row" (ngSubmit)="f.form.valid && update()" #f="ngForm" novalidate>

            <div class="form-group row" [ngClass]="{ 'has-danger': (fieldErrors.code) || (f.submitted && !code.valid) }">
              <div class="col-md-4">
                <label class="mw-100 col-form-label form-control-label required-field-label">
                  {{'DOCUMENT_GROUP_CODE_LABEL' | translate}}
                </label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <input type="text" class="form-control" placeholder="{{'DOCUMENT_GROUP_CODE_LABEL' | translate}}"
                         maxlength="{{UiConstants.maximumVarcharLength}}"
                         [ngClass]="{ 'form-control-danger': (fieldErrors.code) || (f.submitted && !code.valid) }"
                         [(ngModel)]="model.item.code" (ngModelChange)="removeFieldError(fieldErrors.code)" name="code" id="code" #code="ngModel" required>
                  <div class="form-control-feedback" *ngIf="fieldErrors.code">{{fieldErrors.code.text}}</div>
                  <div class="form-control-feedback" *ngIf="f.submitted && !code.valid">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                </div>
              </div>
            </div>

            <div class="form-group row" [ngClass]="{ 'has-danger': (fieldErrors.name) || (f.submitted && !name.valid) }">
              <div class="col-md-4">
                <label class="mw-100 col-form-label form-control-label required-field-label">
                  {{'COMMON_NAME' | translate}}
                </label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
                         maxlength="{{UiConstants.maximumVarcharLength}}"
                         [ngClass]="{ 'form-control-danger': (fieldErrors.name) || (f.submitted && !name.valid) }"
                         [(ngModel)]="model.item.name" (ngModelChange)="removeFieldError(fieldErrors.name)" name="name" id="name" #name="ngModel" required>
                  <div class="form-control-feedback" *ngIf="fieldErrors.name">{{fieldErrors.name.text}}</div>
                  <div class="form-control-feedback" *ngIf="f.submitted && !name.valid">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-4">
                <label class="mw-100 col-form-label form-control-label">{{'DOCUMENT_GROUP_LANGUAGE_LABEL'|translate}}</label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <select class="form-control" [(ngModel)]="model.item.language_code" id="languageCode" name="languageCode" #languageCode="ngModel">
                    <option *ngFor="let languageCode of languageCodes" [ngValue]="languageCode">{{languageCode}}</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="form-group row" [ngClass]="{ 'has-danger': f.submitted && !userGroups.valid, 'hidden': !model.item.mainGroup }">
              <div class="col-md-4">
                <label class="mw-100 col-form-label form-control-label">{{'DOCUMENT_GROUP_USER_GROUP_LABEL'|translate}}</label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <angular2-multiselect
                    [(ngModel)]="model.item.user_groups"
                    [data]="userGroupList"
                    [settings]="dropdownSettings"
                    (onRemoteSearch)="loadUserGroups(undefined, $event.target.value)"
                    name="userGroups" id="userGroups" #userGroups="ngModel"
                    validateEnabledItems>
                  </angular2-multiselect>
                </div>
                <div class="form-control-feedback" *ngIf="f.submitted && !userGroups.valid">{{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}</div>
              </div>
            </div>

            <!--region Main group-->
            <div class="form-group row">
              <label class="col-form-label form-control-label col-md-4 detail-title">{{'DOCUMENT_GROUP_MAIN_GROUP' |
                translate}}</label>
              <div class="col-md-8">
                <label class="switch switch-text switch-info mb-0">
                  <input type="checkbox" class="switch-input"
                         [(ngModel)]="model.item.mainGroup"
                         (ngModelChange)="onMainGroupChanged()"
                         [ngModelOptions]="{standalone: true}">
                  <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                  <span class="switch-handle"></span>
                </label>
              </div>
            </div>
            <!--endregion Main group-->

            <!--region Parent group-->
            <div class="form-group row"
                 [ngClass]="{ 'has-danger': fieldErrors.parent_id || (f.submitted && !parent.valid), 'hidden': model.item.mainGroup}">
              <label class="col-form-label form-control-label col-md-4 detail-title"
                     [class.required-field-label]="!model.item.mainGroup">
                {{'DOCUMENT_GROUP_PARENT_GROUP' | translate}}
              </label>
              <div class="col-md-8">
                <angular2-multiselect
                  [(ngModel)]="model.item.parent"
                  (ngModelChange)="removeFieldError(fieldErrors.parent_id)"
                  [ngClass]="{ 'form-control-danger': fieldErrors.parent_id || (f.submitted && !parent.valid)}"
                  [data]="groups"
                  [settings]="parentDropdownSettings"
                  (onRemoteSearch)="loadGroups($event.target.value)"
                  name="parent"
                  id="parent"
                  #parent="ngModel"
                  [required]="!model.item.mainGroup"
                  validateEnabledItems>
                </angular2-multiselect>
                <div class="form-control-feedback" *ngIf="fieldErrors.parent_id">
                  {{fieldErrors.parent_id.text}}
                </div>
                <div class="form-control-feedback" *ngIf="f.submitted && !parent.valid && parent.errors['required']">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                <div class="form-control-feedback" *ngIf="f.submitted && !parent.valid && parent.errors['hasDisabledItem']">{{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}</div>
              </div>
            </div>
            <!--endregion Parent group-->

            <!--region Child groups-->
            <div class="form-group row" *ngIf="model.item.children.length > 0">
              <label class="col-form-label form-control-label col-md-4 detail-title">
                {{'DOCUMENT_GROUP_CHILD_GROUPS' | translate}}
              </label>
              <div class="col-md-8">
                <angular2-multiselect
                  [(ngModel)]="model.item.children"
                  [data]="groups"
                  [settings]="dropdownSettings"
                  (onRemoteSearch)="loadGroups($event.target.value)"
                  [readonly]="true"
                  name="children"
                  id="children"
                  #children="ngModel">
                </angular2-multiselect>
              </div>
            </div>
            <!--endregion Child groups-->

            <div>
              <div class="d-flex justify-content-end">
                <input type="submit" class="btn btn-primary" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>
    <div class="container-vertical-padding-half"></div>
    <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 p-0">
      <div class="card">
        <div class="card-header">
          {{'FORM_THUMBNAIL_HEADING'|translate}}
        </div>
        <div *ngIf="!model.hasThumbnail" class="card-body">
          <app-file-upload
            [uploadPath]="''"
            [uploadOnDrop]="false"
            [acceptedMimeType]="UploadMimeType.IMAGE"
            (onFileDropped)="thumbnailUploadDialog.onImageSelected($event)">
          </app-file-upload>
        </div> <!-- ./card-body end -->
        <div *ngIf="model.hasThumbnail" class="card-body">
          <div class="thumbnail-background mb-1" *ngIf="thumbnailLoaded" [style.background-image]="thumbnailSrc"></div>
          <div class="thumbnail-background-placeholder mb-1" *ngIf="!thumbnailLoaded"></div>
          <div class="d-flex justify-content-end">
            <input type="button" class="btn btn-primary mr-1" value="{{'COMMON_BUTTON_DOWNLOAD'|translate}}"
                   (click)="downloadThumbnail()"/>
            <input type="button" class="btn btn-primary" value="{{'COMMON_BUTTON_DELETE'|translate}}"
                   (click)="deleteThumbnail()"/>
          </div>
        </div>
      </div>
    </div>
</div>

<app-cropped-image-uploader-dialog
  #thumbnailUploadDialog
  [titleKey]="'DOCUMENT_GROUP_UPLOAD_THUMBNAIL'"
  [fixedRatioKey]="'DOCUMENT_GROUP_DOCUMENT_LIBRARY_ASPECT_RATIO'"
  [fixedRatioSwitchEnabled]="true"
  [fileName]="'document-group-' + documentGroupId + '-thumbnail.png'"
  [uploadPath]="thumbnailUploadPath"
  [aspectRatio]="documentLibraryAspectRatio"
  (onImageUploaded)="onThumbnailUploaded()">
</app-cropped-image-uploader-dialog>
