/* eslint-disable */
import { AfterViewInit, Component, ViewChild, } from '@angular/core';
import {
  FormEditFieldCreateDialogView,
  FormEditFieldDialogContext,
  FormEditFieldUpdateDialogView,
  FormGroupModel,
  FormModel,
} from '../../../../../../../util/form/form-utils';
import { InputMask } from '../../../../../../../util/input-masks';
import { Form } from '../../../../../../../lib/form/form.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FieldWidthType } from '../../../../../../../util/form/form-field-width-type';
import {
  FieldValidationError,
  FormRef,
  LocalFieldValidationErrors,
  LocalFieldValidationErrorsFactory,
  OrderType, QueryResult,
  ResourceQueryResult,
  Services
} from '../../../../../../../lib/util/services';
import { NgModel } from '@angular/forms';
import { List, Map, Set } from 'immutable';
import {
  ListItemList,
  ListItemResourceService,
  RawListItem,
  RawListTypeItem
} from '../../../../../../../lib/list-item/list-item-resource.service';
import { ListItemTypeItem } from '../../../../../../../util/list-item-utils';
import { MultiselectOptionItem, OptionItem, UiConstants } from '../../../../../../../util/core-utils';
import {
  FieldValidationType,
  FormFieldValidationTypeModel,
} from '../../../../../../../util/form/form-field-validation-type';
import { TranslateService } from '@ngx-translate/core';
import { FormEditCreateUpdateListItemActivationRuleComponent } from '../../../shared/list-item-activation-rule/form-edit-create-update-list-item-activation-rule.component';
import { Models } from '../../../../../../../util/model-utils';
import { Angular2Multiselects } from '../../../../../../../util/multiselect';
import { Strings } from '../../../../../../../lib/util/strings';
import { ListItemMapService } from '../../../../../../../lib/list-item/list-item-map.service';

/* eslint-enable */

@Component({
  selector: 'app-form-edit-create-update-list-item-field-dialog',
  templateUrl: 'form-edit-create-update-list-item-field-dialog.component.html',
  styleUrls: ['form-edit-create-update-list-item-field-dialog.component.scss'],
})
export class FormEditCreateUpdateListItemFieldDialogComponent
  implements FormEditFieldCreateDialogView, FormEditFieldUpdateDialogView, FormRef, AfterViewInit {

  public readonly selector = Form.FieldDataTypeSelector.LIST_ITEM;
  public readonly createTitle = 'FORM_ITEM_CREATE_LIST_TITLE';
  public readonly updateTitle = 'FORM_ITEM_UPDATE_LIST_TITLE';
  public readonly cloneTitle = 'FORM_ITEM_CLONE_LIST_TITLE';

  InputMask = InputMask;
  Form = Form;
  UiConstants = UiConstants;

  @ViewChild('fieldDialog', { static: true })
  dialogComponent: ModalDirective;

  @ViewChild('title')
  titleInput: NgModel;

  @ViewChild('api_export_name')
  apiExportNameInput: NgModel;

  @ViewChild('listItem')
  listItemInput: NgModel;

  @ViewChild('defaultValueInput')
  defaultValueInput: NgModel;

  @ViewChild(FormEditCreateUpdateListItemActivationRuleComponent)
  activationRule: FormEditCreateUpdateListItemActivationRuleComponent;

  visible: boolean = false;
  fieldModel: Model;
  dialogTitleDictionaryKey: string = '';
  latestApiExportName = '';

  fieldWidthTypes: FieldWidthType[] = FieldWidthType.VALUES.toArray();
  fieldValidationTypes: Form.FormFieldValidationType[] = FieldValidationType.VALUES.toArray();

  private fieldErrors: FieldValidationError<Form.FieldValidatedField> =
    FieldValidationError.empty<Form.FieldValidatedField>();

  private validatedInputs: LocalFieldValidationErrors<NgModel> =
    LocalFieldValidationErrorsFactory.empty();

  private context: FormEditFieldDialogContext;
  form: FormModel;
  private group: FormGroupModel;
  private field?: Form.Field;
  private clone?: boolean;

  submitted: boolean = false;
  availableTypeItems: ListItemTypeItem[] = [];
  availableDefaultListItems: DefaultListItem[] = [];

  fieldActivationEnabled: boolean = false;
  activatedFieldIdsByItemId: Map<number, Set<number>> = Map.of();
  activatedGroupIdsByItemId: Map<number, Set<number>> = Map.of();

  availableFilterFields: MultiselectOptionItem<number>[] = [];

  listItemTypeDropdownSettings?: Angular2Multiselects.Settings;
  listItemDropdownSettings?: Angular2Multiselects.Settings;
  filterDropdownSettings?: Angular2Multiselects.Settings;

  getForm() {
    return {
      submitted: this.submitted
    };
  }

  get selectedListItemTypeItem() {
    return this.fieldModel.listItemTypeItem[0] ? this.fieldModel.listItemTypeItem[0] : undefined;
  }

  get fieldId(): number | undefined {
    return this.field ? this.field.fieldId : undefined;
  }

  get groupId(): number | undefined {
    return this.group ? this.group.groupId : undefined;
  }

  public constructor(
    private listItemService: ListItemResourceService,
    private listItemMapService: ListItemMapService,
    private translateService: TranslateService) {
    this.fieldModel = new Model();
  }

  ngAfterViewInit(): void {
    this.loadLocalFieldValidationErrors();
    this.initDropdownSettings();
  }

  private initDropdownSettings(disabled?: boolean) {
    this.listItemTypeDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .labelKey(OptionItem.KEY_TEXT)
      .remoteSearch(true)
      .build();
    this.listItemDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .labelKey(OptionItem.KEY_TEXT)
      .remoteSearch(true)
      .disabled(disabled ? disabled : false)
          .build();
        this.filterDropdownSettings = new Angular2Multiselects.SettingsBuilder()
          .singleSelection(true)
          .enableSearchFilter(true)
          .enableCheckAll(false)
          .build();
  }

  registerContext(context: FormEditFieldDialogContext): void {
    this.context = context;
  }

  showDialog(form: FormModel, group: FormGroupModel, field?: Form.Field, clone?: boolean): void {
    this.latestApiExportName = '';
    this.visible = true;
    this.clone = clone;
    this.loadListItemTypesAndShowDialog(form, group, field, clone);
  }

  onModalHide(event: ModalDirective) {
    if (event.dismissReason === 'backdrop-click' || event.dismissReason === 'esc') {
      this.closeDialog(true);
    }
  }

  closeDialog(hidden?: boolean): void {
    this.visible = false;
    this.submitted = false;
    if (!hidden) {
      this.dialogComponent.hide();
    }
    this.fieldModel = new Model();
    this.availableTypeItems = [];
    this.fieldActivationEnabled = false;
    this.activatedFieldIdsByItemId = Map.of();
    this.activatedGroupIdsByItemId = Map.of();
    this.activationRule.reset();
  }

  saveField() {
    this.submitted = true;
    if (this.hasLocalFieldError(this.titleInput)
      || this.hasLocalFieldError(this.apiExportNameInput)
      || this.hasLocalFieldError(this.listItemInput)
      || this.hasLocalFieldError(this.defaultValueInput)
      || this.activationRule.invalid) {
      return;
    }
    if (this.field && this.clone) {
      this.createField();
    }
    else if (this.field) {
      this.updateField(this.field);
    }
    else {
      this.createField();
    }
  }

  hasLocalFieldError(field?: NgModel): boolean {
    return this.validatedInputs.hasLocalError(field);
  }

  hasFieldError(field?: Form.FieldValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: Form.FieldValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: Form.FieldValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  getFieldValidationTypeDictionaryKey(vt: Form.FormFieldValidationType): string {
    return FieldValidationType.getFieldValidationTypeDictionaryKey(vt);
  }

  onAdminFormFieldValidationTypeChanged() {
    FieldValidationType.onAdminFormFieldValidationTypeChanged(this.fieldModel);
  }

  onMobileFormFieldValidationTypeChanged() {
    FieldValidationType.onMobileFormFieldValidationTypeChanged(this.fieldModel);
  }

  private loadLocalFieldValidationErrors() {
    const validatedInputs = List.of(this.titleInput, this.listItemInput, this.apiExportNameInput, this.defaultValueInput);
    this.validatedInputs = LocalFieldValidationErrorsFactory.ofFields(this, validatedInputs);
  }

  private loadListItemTypesAndShowDialog(form: FormModel, group: FormGroupModel, field?: Form.Field, clone?: boolean) {
    this.loadListItemTypes(() => {
      this.loadDialog(form, group, field, clone);
    })
  }

  private loadListItemTypes(completion?: () => void, searchValue?: string) {
    this.listItemService.getTypeList({
      key: Strings.undefinedOrNonEmpty(searchValue),
      disabled: false,
      order: Services.createOrderFieldParameter(ListItemList.TypeKeys.toOrderFieldKey,
        Set.of({field: ListItemList.TypeOrderField.KEY, type: OrderType.ASC})),
      page_number: searchValue !== undefined ? 1 : undefined,
      number_of_items: searchValue !== undefined ? UiConstants.autocompletePageSize : undefined,
      no_progress_bar: true
    }).subscribe((result: ResourceQueryResult<RawListTypeItem>) => {
      this.availableTypeItems = [];
      result.items.forEach((listItemType) => {
        const item = new ListItemTypeItem();
        item.id = listItemType.key;
        item.text = listItemType.key;
        item.disabled = listItemType.disabled;
        if (listItemType.filter_type) {
          item.filterTypeKey = listItemType.filter_type.key;
        }
        this.availableTypeItems.push(item);
      });
      if (completion) {
        completion();
      }
    });
  }

  onListItemTypeChanged(field?: Form.Field, selection?: ListItemTypeItem[]) {
    this.loadListItems(field, selection);
    this.removeFieldError(Form.FieldValidatedField.LIST_ITEM_TYPE);
    this.fieldModel.filterField = [];
    this.loadAvailableListItemFields();
  }

  private loadAvailableListItemFields(id?: number) {
    if (this.fieldModel.listItemTypeItem[0]) {
      this.availableFilterFields = this.group.fields
        .filter(f => (f.dataTypeSelector === Form.FieldDataTypeSelector.LIST_ITEM
          && f.dataType.listItemAttributes!.listItemTypeKey === this.fieldModel.listItemTypeItem[0].filterTypeKey)
          || (f.dataTypeSelector === Form.FieldDataTypeSelector.LIST_MULTI_ITEM
            && f.dataType.listMultiItemAttributes!.listItemTypeKey === this.fieldModel.listItemTypeItem[0].filterTypeKey))
        .filter(f => !f.disabled)
        .filter(f => this.fieldId !== f.fieldId)
        .map(f => ({id: f.fieldId, itemName: f.title}));
      if (id) {
        const f = this.availableFilterFields.find(f => f.id === id);
        if (f) {
          this.fieldModel.filterField = [];
          this.fieldModel.filterField.push(f);
        }
      }
    }
    else {
      this.availableFilterFields = [];
    }
  }

  private loadListItems(field?: Form.Field, selection?: ListItemTypeItem[]) {
    this.fieldModel.defaultListItem = [];
    let type: string = '';
    this.availableDefaultListItems = [];
    if (selection && selection[0]) {
      type = selection[0].id!;
    }
    if (this.fieldModel.listItemTypeItem[0]) {
      type = this.fieldModel!.listItemTypeItem[0]!.id!;
      this.initDropdownSettings(false);
    }
    else {
      this.initDropdownSettings(true);
    }
    this.listItemMapService.getList({
      type_key: type,
      disabled: false,
      number_of_items: UiConstants.autocompletePageSize,
      page_number: 1
    }).subscribe((result: QueryResult<RawListItem>) => {
        result.items.forEach((item) => {
          if (item) {
            const newitem: DefaultListItem = new DefaultListItem();
            newitem.id = item.id;
            newitem.text = item.text;
            newitem.disabled = item.disabled;
            this.availableDefaultListItems.push(newitem);
          }
        });
      },
      () => {
      },
      () => {
        if (field) {
          this.loadField(field);
        }
      });
  }

  onListItemSearch(searchValue: string) {
    this.availableDefaultListItems = [];
    this.listItemMapService.getList({
      text: Strings.undefinedOrNonEmpty(searchValue),
      type_key: this.fieldModel.listItemTypeItem[0] ? this.fieldModel.listItemTypeItem[0].id! : undefined,
      disabled: false,
      number_of_items: UiConstants.autocompletePageSize,
      page_number: 1,
      no_progress_bar: true
    }).subscribe((result: QueryResult<RawListItem>) => {
        result.items.forEach((item) => {
          if (item) {
            const newitem: DefaultListItem = new DefaultListItem();
            newitem.id = item.id;
            newitem.text = item.text;
            newitem.disabled = item.disabled;
            this.availableDefaultListItems.push(newitem);
          }
        });
      },
      () => {
      });
  }

  private loadDialog(form: FormModel, group: FormGroupModel, field?: Form.Field, clone?: boolean) {
    this.group = group;
    this.field = field;
    setTimeout(() => {
      this.form = form;
    });
    this.dialogComponent.show();
    if (field && clone) {
      this.dialogTitleDictionaryKey = this.cloneTitle;
      this.loadField(field);
      this.loadListItems(field);
    }
    else if (field) {
      this.dialogTitleDictionaryKey = this.updateTitle;
      this.loadField(field);
      this.loadListItems(field);
    }
    else {
      this.dialogTitleDictionaryKey = this.createTitle;
    }
  }

  private loadField(field: Form.Field) {
    const attrs = field.dataType.listItemAttributes!;
    const listItemType = this.getListItemTypeByKey(attrs.listItemTypeKey);
    const defaultListItem = this.getDefaultListItemById(attrs.defaultListItemId);
    const placeholder = attrs.hint;
    const fieldWidth = FieldWidthType.fromPercentValue(field.formFieldWidthPercent);
    this.fieldActivationEnabled = attrs.fieldActivationEnabled;
    this.activatedFieldIdsByItemId = attrs.activatedFieldIdsByItemId;
    this.activatedGroupIdsByItemId = attrs.activatedGroupIdsByItemId;
    this.fieldModel.title = field.title;
    this.fieldModel.description = field.description ? field.description : '';
    this.fieldModel.hint = field.hint ? field.hint : '';
    this.fieldModel.apiExportName = field.apiExportName ? field.apiExportName : '';
    this.fieldModel.pdfExportName = field.pdfExportName ? field.pdfExportName : '';
    this.fieldModel.fieldWidthType = fieldWidth ? fieldWidth : FieldWidthType.DEFAULT;
    this.fieldModel.displayOnNewRow = field.displayOnNewRow;
    this.fieldModel.placeholder = placeholder ? placeholder : '';
    this.fieldModel.adminVisibleOnFormDetail = field.admin.visibleOnFormDetail;
    this.fieldModel.mobileVisibleOnFormDetail = field.mobile.visibleOnFormDetail;
    this.fieldModel.mobileVisibleOnMasterDetail = field.mobile.visibleOnMasterDetail;
    this.fieldModel.adminFormFieldValidationType = field.admin.validationType;
    this.fieldModel.helpdeskFormFieldValidationType = field.helpdesk.validationType;
    this.fieldModel.mobileFormFieldValidationType = field.mobile.validationType;
    this.fieldModel.showOnReceipt = field.showOnReceipt;
    this.fieldModel.listItemTypeItem = [];
    if (listItemType) {
      this.fieldModel.listItemTypeItem.push(listItemType);
      this.listItemInput.control.updateValueAndValidity();
    }
    else {
      this.listItemService.getTypeByKey({
        key: attrs.listItemTypeKey
      }).subscribe((listItemType: RawListTypeItem) => {
        const item = new ListItemTypeItem();
        item.id = listItemType.key;
        item.text = listItemType.key;
        item.disabled = listItemType.disabled;
        this.fieldModel.listItemTypeItem.push(item);
        this.listItemInput.control.updateValueAndValidity();
      });
    }
    this.fieldModel.defaultListItem = [];
    if (defaultListItem) {
      this.fieldModel.defaultListItem.push(defaultListItem);
    }
    else if (attrs.defaultListItemId) {
      this.listItemMapService.get({
        id: attrs.defaultListItemId
      }).subscribe((rawListItem: RawListItem) => {
          this.fieldModel.defaultListItem = [];
          const item = new DefaultListItem();
          item.id = rawListItem.id;
          item.text = rawListItem.text;
          item.disabled = rawListItem.disabled;
          this.fieldModel.defaultListItem.push(item);
      });
    }
    this.loadAvailableListItemFields(attrs.filterFieldId);
  }

  private getListItemTypeByKey(typeKey: string): ListItemTypeItem | undefined {
    return this.availableTypeItems.find(value => value.id === typeKey);
  }

  private getDefaultListItemById(id: number | undefined): DefaultListItem | undefined {
    return this.availableDefaultListItems.find(value => value.id === id);
  }

  private createField() {
    this.context.formService.createField({
      parentId: this.context.parentId,
      groupId: this.group.groupId,
      title: this.fieldModel.title,
      description: this.fieldModel.description,
      hint: this.fieldModel.hint,
      pdfExportName: this.fieldModel.pdfExportName,
      apiExportName: this.fieldModel.apiExportName,
      dataTypeSelector: this.selector,
      dataType: this.getDataTypeRequest(),
      formFieldWidthPercent: this.fieldModel.fieldWidthType.percentValue,
      displayOnNewRow: this.fieldModel.displayOnNewRow,
      admin: {
        visibleOnFormDetail: this.fieldModel.adminVisibleOnFormDetail,
        validationType: this.fieldModel.adminFormFieldValidationType
      },
      helpdesk: {
        validationType: this.fieldModel.helpdeskFormFieldValidationType
      },
      mobile: {
        visibleOnFormDetail: this.fieldModel.mobileVisibleOnFormDetail,
        visibleOnMasterDetail: this.fieldModel.mobileVisibleOnMasterDetail,
        validationType: this.fieldModel.mobileFormFieldValidationType
      },
      showOnReceipt: this.fieldModel.showOnReceipt
    }).subscribe(
      (result) => {
        this.context.formView.reloadForm();
        this.closeDialog();
      },
      (error) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withFormRef(this);
        }
      }
    );
  }

  private updateField(field: Form.Field) {
    this.context.formService.updateField({
      parentId: this.context.parentId,
      groupId: this.group.groupId,
      fieldId: field.fieldId,
      title: this.fieldModel.title,
      description: this.fieldModel.description,
      hint: this.fieldModel.hint,
      pdfExportName: this.fieldModel.pdfExportName,
      apiExportName: this.fieldModel.apiExportName,
      dataTypeSelector: this.selector,
      dataType: this.getDataTypeRequest(),
      formFieldWidthPercent: this.fieldModel.fieldWidthType.percentValue,
      displayOnNewRow: this.fieldModel.displayOnNewRow,
      admin: {
        visibleOnFormDetail: this.fieldModel.adminVisibleOnFormDetail,
        validationType: this.fieldModel.adminFormFieldValidationType
      },
      helpdesk: {
        validationType: this.fieldModel.helpdeskFormFieldValidationType
      },
      mobile: {
        visibleOnFormDetail: this.fieldModel.mobileVisibleOnFormDetail,
        visibleOnMasterDetail: this.fieldModel.mobileVisibleOnMasterDetail,
        validationType: this.fieldModel.mobileFormFieldValidationType
      },
      showOnReceipt: this.fieldModel.showOnReceipt
    }).subscribe(
      (result) => {
        this.context.formView.reloadForm();
        this.closeDialog();
      },
      (error) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withFormRef(this);
        }
      }
    );
  }

  private getDataTypeRequest(): Form.FieldDataType {
    return {
      listItemAttributes: {
        listItemTypeKey: this.fieldModel.listItemTypeItem[0]!.id!,
        hint: this.fieldModel.placeholder,
        defaultListItemId: OptionItem.idOrUndefined(this.fieldModel.defaultListItem[0]) ?
          this.fieldModel.defaultListItem[0]!.id! : undefined,
        fieldActivationEnabled: this.activationRule.fieldActivationEnabled,
        activatedFieldIdsByItemId: this.activationRule.activatedFieldIdsByItemId,
        activatedGroupIdsByItemId: this.activationRule.activatedGroupIdsByItemId,
        filterFieldId: this.fieldModel.filterFieldId
      }
    };
  }

  onTitleBlur() {
    const normalizedText = Models.normalizeText(this.fieldModel.title);
    if (this.fieldModel.apiExportName === '') {
      this.fieldModel.apiExportName = normalizedText;
    }
    if (this.fieldModel.pdfExportName === '') {
      this.fieldModel.pdfExportName = normalizedText;
    }
  }
}


export class Model implements FormFieldValidationTypeModel {
  title: string = '';
  description: string = '';
  apiExportName: string = '';
  pdfExportName: string = '';
  fieldWidthType: FieldWidthType = FieldWidthType.DEFAULT;
  displayOnNewRow: boolean = false;
  hint?: string;
  placeholder?: string;
  listItemTypeItem: ListItemTypeItem[] = [];
  defaultListItem: DefaultListItem[] = [];
  adminVisibleOnFormDetail: boolean = true;
  mobileVisibleOnFormDetail: boolean = true;
  mobileVisibleOnMasterDetail: boolean = false;
  adminFormFieldValidationType: Form.FormFieldValidationType = Form.FormFieldValidationType.OPTIONAL;
  helpdeskFormFieldValidationType: Form.FormFieldValidationType = Form.FormFieldValidationType.OPTIONAL;
  mobileFormFieldValidationType: Form.FormFieldValidationType = Form.FormFieldValidationType.OPTIONAL;
  showOnReceipt?: boolean = false;
  filterField: MultiselectOptionItem<number>[] = [];

  get filterFieldId(): number | undefined {
    return this.filterField.length === 1 ? this.filterField[0].id : undefined;
  }
}

class DefaultListItem extends OptionItem<number> {
}
