/* eslint-disable */
import {
  FieldActivationStrategy,
  FieldActivationStrategyCalculateInactivatesArgs,
  FieldActivationStrategyPutRulesArgs,
  FieldViewItem,
} from '../form-record-inactivity-manager-api';
import { Injectable } from '@angular/core';

/* eslint-enable */

@Injectable()
export class NoOpFieldActivationStrategy implements FieldActivationStrategy {

  putRules(args: FieldActivationStrategyPutRulesArgs) {
  }

  async calculateInactivates(args: FieldActivationStrategyCalculateInactivatesArgs): Promise<FieldViewItem[]> {
    return [];
  }

}
