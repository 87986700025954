import { Component, OnInit } from '@angular/core';
import { TaskRecord, TaskRecordService } from '../../../lib/task/task-record.service';
import { Set } from 'immutable';
import { QueryResult } from '../../../lib/util/services';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-task-record-message-list',
  templateUrl: './task-record-message-list.component.html',
  styleUrls: ['./task-record-message-list.component.scss']
})
export class TaskRecordMessageListComponent implements OnInit {

  currentPage: number = 1;
  totalNumberOfItems: number = 0;
  messageList: TaskRecord.RecentMessage[] = [];


  constructor(private taskRecordService: TaskRecordService,
              private translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.loadMessages();
  }

  loadMessages(afterLoad?: boolean) {
    if (afterLoad) {
      this.currentPage++;
    }
    this.taskRecordService.getRecentMessages({
      paging: {
        pageNumber: afterLoad ? this.currentPage : 1,
        numberOfItems: 10
      },
      fields: Set.of('id', 'task_id', 'name', 'process')
    }).subscribe(
      (result: QueryResult<TaskRecord.RecentMessage>) => {
        if (!afterLoad) {
          this.messageList = result.items.toArray();
        }
        else {
          result.items.toArray().forEach((m) => {
            this.messageList.push(m);
          });
          const messageContainers = document.getElementsByClassName('task-record-message-container');
          messageContainers[messageContainers.length - (this.currentPage - 1) * 10].scrollIntoView();
        }
        this.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      }
    );
  }
}


