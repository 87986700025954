import { Injectable } from '@angular/core';
import { UiConstants } from '../../util/core-utils';
import { StringKey } from '../../app.string-keys';
import { ToasterService } from '../../fork/angular2-toaster/src/toaster.service';
import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject, Subject, Subscription } from 'rxjs';

@Injectable({providedIn: 'root'})
export class FormRecordStockAmountValidatorService {

  private stockRecordDataCollections: StockRecordDataCollection[] = [];

  private shouldDisplayErrorToast = true;

  private _subject: Subject<StockRecordDataCollection> = new ReplaySubject();
  private initialSender?: number;

  constructor(private toasterService: ToasterService,
              private translateService: TranslateService) {
  }

  clear() {
    this.stockRecordDataCollections = [];
  }

  updateStockRecordAmount(fieldId: number, stockRecordId: number, amount: number, amountInStock: number, submitAmount?: number) {
    this.shouldDisplayErrorToast = true;
    const stockRecordDataCollectionIndex = this.stockRecordDataCollections.findIndex(f => f.stockRecordId === stockRecordId);
    if (stockRecordDataCollectionIndex > -1) {
      this.stockRecordDataCollections[stockRecordDataCollectionIndex].amountInStock = amountInStock;
      const stockRecordDataIndex =
        this.stockRecordDataCollections[stockRecordDataCollectionIndex].stockRecordData.findIndex(f => f.fieldId === fieldId);
      if (stockRecordDataIndex > -1) {
        this.stockRecordDataCollections[stockRecordDataCollectionIndex].stockRecordData[stockRecordDataIndex].amount = amount;
      } else {
        this.stockRecordDataCollections[stockRecordDataCollectionIndex].stockRecordData.push({
          fieldId: fieldId,
          amount: amount,
          submitAmount: submitAmount
        });
      }
      this.onRecordDataChanged(this.stockRecordDataCollections[stockRecordDataCollectionIndex]);
    } else {
      const data = {
        stockRecordId: stockRecordId,
        amountInStock: amountInStock,
        stockRecordData: [{
          fieldId: fieldId,
          amount: amount,
          submitAmount: submitAmount
        }]
      }
      this.stockRecordDataCollections.push(data);
      this.onRecordDataChanged(data);
    }
  }

  validate(stockRecordId: number): boolean {
    const dataCollection = this.stockRecordDataCollections.find(f => f.stockRecordId === stockRecordId);
    if (dataCollection) {
      const sum = dataCollection.stockRecordData
        .map(f => f.submitAmount ? f.amount - f.submitAmount : f.amount)
        .reduce((sum, current) => sum + current, 0);
      return sum <= dataCollection.amountInStock;
    } else {
      return true;
    }
  }

  validateDataCollection(dataCollection?: StockRecordDataCollection): boolean {
    if (dataCollection) {
      const sum = dataCollection.stockRecordData
        .map(f => f.submitAmount ? f.amount - f.submitAmount : f.amount)
        .reduce((sum, current) => sum + current, 0);
      return sum <= dataCollection.amountInStock;
    } else {
      return true;
    }
  }

  showAmountSumErrorToast(sender: number) {
    if (!this.initialSender || this.initialSender === sender) {
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutLong,
        type: UiConstants.toastTypeError,
        title: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_TITLE),
        body: this.translateService.instant(StringKey.FORM_RECORD_STOCK_VALUE_ERROR)
      });
      if (!this.initialSender) {
        this.initialSender = sender;
      }
    }
  }


  subscribe(next?: (value: StockRecordDataCollection) => void, error?: (error: any) => void, complete?: () => void): Subscription {
    return this._subject.subscribe(next, error, complete);
  }

  unsubscribe(subscription: Subscription) {
    subscription.unsubscribe();
  }

  onRecordDataChanged(data: StockRecordDataCollection) {
    this._subject.next(data);
  }

  removeStockRecordAmount(fieldId: number, stockRecordId: number) {
    const stockRecordDataCollectionIndex = this.stockRecordDataCollections.findIndex(f => f.stockRecordId === stockRecordId);
    if (stockRecordDataCollectionIndex > -1) {
      const stockRecordDataIndex =
        this.stockRecordDataCollections[stockRecordDataCollectionIndex].stockRecordData.findIndex(f => f.fieldId === fieldId);
      if (stockRecordDataIndex > -1) {
        this.stockRecordDataCollections[stockRecordDataCollectionIndex].stockRecordData.splice(stockRecordDataIndex, 1);
        this.onRecordDataChanged(this.stockRecordDataCollections[stockRecordDataCollectionIndex]);
      }
    }
  }
}

export interface StockRecordData {
  fieldId: number;
  amount: number;
  submitAmount?: number;
}

export interface StockRecordDataCollection {
  stockRecordId: number;
  stockRecordData: StockRecordData[];
  amountInStock: number;
}
