<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="card">

    <div class="card-header">
      {{'COMMON_HISTORY' | translate}}
    </div>

    <div class="card-body">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header">
            <app-table-sorter-no-op [text]="'COMMON_HISTORY_USER' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op [text]="'COMMON_HISTORY_TYPE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-sm-down w-15">
            <app-table-field-sorter [orderField]="ExteriorTransport.EventLogOrderField.CREATION_TIME"
                                    [orderType]="queryModel.getOrderType(ExteriorTransport.EventLogOrderField.CREATION_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_HISTORY_CREATION_TIME' | translate"></app-table-field-sorter>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let log of eventLogList | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }">
          <td class="responsive-table-column">{{log.user.personName}}</td>
          <td class="responsive-table-column">{{getEventLogType(log.type) | translate}}</td>
          <td class="responsive-table-column hidden-sm-down">{{log.creationTime.toUtcIsoString() | date:'short'}}</td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header">
            <app-table-sorter-no-op [text]="'COMMON_HISTORY_USER' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op [text]="'COMMON_HISTORY_TYPE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-sm-down w-15">
            <app-table-field-sorter [orderField]="ExteriorTransport.EventLogOrderField.CREATION_TIME"
                                    [orderType]="queryModel.getOrderType(ExteriorTransport.EventLogOrderField.CREATION_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_HISTORY_CREATION_TIME' | translate"></app-table-field-sorter>
          </th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>
  </div>
</div>
