/* eslint-disable */
import { FieldValidationError } from '../../../lib/util/services';
import { CustomerRecord } from '../../../lib/customer/customer-record.service';
import { AddressModel, } from '../../../lib/address';
import { List, Set } from 'immutable';
import { Models } from '../../../util/model-utils';
import { ImmutableCustomerRecord, ImmutableUserGroupItem, ImmutableUserItem, MultiselectOptionItem } from '../../../util/core-utils';
import { EuVatNumber, HuTaxNumber } from '../../../lib/util/tax-numbers';
import { Customer } from '../../../lib/customer/customer.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { CustomerRecordFieldModel, CustomerRecordItem } from '../../../util/customer-record-utils';
import { LedgerNumberMultiselectProvider } from '../../../lib/ledger/number/ledger-number-multiselect.provider';
import {
  CustomerRecordBillingInfoModel
} from '../customer-record-billing-info/customer-record-billing-info-base/customer-record-billing-info-base.component';
/* eslint-enable */

export namespace CustomerRecordBase {

  import GenderType = CustomerRecord.GenderType;

  export interface View {
    loadCustomerRecord(args: Args);

    getModel(): Model;

    hasLocalFieldError(formControlName?: string, errorCode?: string): boolean;

    onFieldValidationError(fieldErrors: FieldValidationError<CustomerRecord.ValidatedField>);

    getFieldModel(): CustomerRecordFieldModel;
  }

  export interface Args {
    customerRecord?: CustomerRecord.CustomerRecord;
    customer: Customer.Customer;
    countryItems: List<AddressModel.CountryItem>;
    defaultSelectedCountryCode?: string;
    isClone: boolean;
    isCreate: boolean;
  }

  // Not injected so valid inside the namespace.
  export class Model {

    readonly userItems: MultiselectOptionItem<number>[] = [];
    readonly userGroupItems: MultiselectOptionItem<number>[] = [];
    readonly customerRecordParentItems: ImmutableCustomerRecord[] = [];

    private _name: string;
    firstName: string;
    lastName: string;
    externalId: string;
    comment: string;
    position: string;
    webAddress: string;
    mainContractNumber: string;
    taxNumber: HuTaxNumber;
    euTaxNumber: EuVatNumber;
    users: MultiselectOptionItem<number>[] = [];
    userGroups: MultiselectOptionItem<number>[] = [];
    customerRecordParents: CustomerRecordItem[] = [];
    ledgerNumber: MultiselectOptionItem<number>[] = [];
    company: MultiselectOptionItem<number>[] = [];
    readonly postalAddress: AddressModel.PostalAddressModel;
    readonly notificationAddress: AddressModel.PostalAddressModel;
    notificationAddressEqPostal: boolean = true;
    billingInfoMatchesCustomerData: boolean = true;
    billingInfoModel: CustomerRecordBillingInfoModel = new CustomerRecordBillingInfoModel();
    readonly emailAddresses: AddressModel.EmailAddressModel[] = [];
    readonly phoneNumbers: AddressModel.PhoneNumberModel[] = [];
    invoiceDeadlineAdditionalDays?: number;
    taskRecordDeadlineAdditionalDays?: number;

    // PERSON type
    placeOfBirth: string = '';
    dateOfBirth: NgbDateStruct | null = null;
    birthName: string = '';
    gender: GenderType | undefined;
    mothersName: string = '';
    identityDocumentType: string = '';
    identityDocumentNumber: string = '';

    constructor() {
      this.postalAddress = new AddressModel.PostalAddressModel();
      this.notificationAddress = new AddressModel.PostalAddressModel();
      this.reset();
    }

    get fullName(): string {
      return this.firstName ? this.lastName + ' ' + this.firstName : this._name;
    }

    set fullName(value: string) {
      this._name = value;
    }

    get name(): string | undefined {
      return this.firstName ? undefined : this._name;
    }

    getUserIds(): Set<number> {
      return Set.of(...this.users.map((user) => {
        return user.id!;
      }));
    }

    getUserGroupIds(): Set<number> {
      return Set.of(...this.userGroups.map((userGroup) => {
        return userGroup.id!;
      }));
    }

    getCompanyId(): number | undefined {
      return this.company.length === 1 ? this.company[0].id : undefined;
    }

    getLedgerNumberId(): number | undefined {
      return this.ledgerNumber.length === 1 ? this.ledgerNumber[0].id : undefined;
    }

    reset() {
      this._name = '';
      this.firstName = '';
      this.lastName = '';
      this.externalId = '';
      this.comment = '';
      this.position = '';
      this.webAddress = '';
      this.mainContractNumber = '';
      this.taxNumber = new HuTaxNumber();
      this.euTaxNumber = new EuVatNumber();
      this.notificationAddressEqPostal = true;
      this.users.splice(0, this.users.length);
      this.userGroups.splice(0, this.userGroups.length);
      this.customerRecordParents.splice(0, this.customerRecordParents.length);
      this.company.splice(0, this.company.length);
      this.emailAddresses.splice(0, this.emailAddresses.length);
      this.postalAddress.reset(AddressModel.PostalAddressModelType.COMPLEX);
      this.notificationAddress.reset(AddressModel.PostalAddressModelType.COMPLEX);
      this.emailAddresses.splice(0, this.emailAddresses.length);
      this.phoneNumbers.splice(0, this.phoneNumbers.length);
      this.ledgerNumber.splice(0, this.ledgerNumber.length);
    }

    load(data: ModelData) {
      this.postalAddress.loadCountryItems(data.countryItems, data.defaultSelectedCountryCode);
      this.notificationAddress.loadCountryItems(data.countryItems, data.defaultSelectedCountryCode);
      if (!data.customerRecord) {
        this.reset();
        // Country items must be loaded after reset in order to set default selected country
        this.postalAddress.loadCountryItems(data.countryItems, data.defaultSelectedCountryCode);
        this.notificationAddress.loadCountryItems(data.countryItems, data.defaultSelectedCountryCode);
        if (data.customer.defaultLedgerNumber) {
          this.ledgerNumber = [LedgerNumberMultiselectProvider.toPublic(data.customer.defaultLedgerNumber)];
        }
        return;
      }
      this._name = Models.optToString(data.customerRecord.name);
      this.firstName = Models.optToString(data.customerRecord.firstName);
      this.lastName = Models.optToString(data.customerRecord.lastName);
      this.externalId = Models.optToString(data.customerRecord.externalId);
      this.comment = Models.optToString(data.customerRecord.comment);
      this.position = Models.optToString(data.customerRecord.position);
      this.webAddress = Models.optToString(data.customerRecord.webAddress);
      this.mainContractNumber = Models.optToString(data.customerRecord.mainContractNumber);
      this.taxNumber.raw = Models.optToString(data.customerRecord.taxNumber);
      this.euTaxNumber.raw = Models.optToString(data.customerRecord.euTaxNumber);
      if (data.customerRecord.parentId) {
        this.loadCustomerRecordParentItem(data.customerRecord.parentId);
      }
      this.postalAddress.load(data.customerRecord.postalAddress);
      this.notificationAddress.load(data.customerRecord.notificationAddress);
      this.notificationAddressEqPostal = data.customerRecord.notificationAddressEqPostal;
      this.emailAddresses.splice(0, this.emailAddresses.length);
      data.customerRecord.emailAddresses.forEach((address) => {
        const model = new AddressModel.EmailAddressModel();
        model.load(address);
        this.emailAddresses.push(model);
      });
      this.phoneNumbers.splice(0, this.phoneNumbers.length);
      data.customerRecord.phoneNumbers.forEach((address) => {
        const model = new AddressModel.PhoneNumberModel();
        model.load(address);
        this.phoneNumbers.push(model);
      });
      if (data.customerRecord.ledgerNumber) {
        this.ledgerNumber = [LedgerNumberMultiselectProvider.toPublic(data.customerRecord.ledgerNumber)];
      }

      // PERSON type
      this.placeOfBirth = Models.optToString(data.customerRecord.placeOfBirth);
      this.dateOfBirth = data.customerRecord.dateOfBirth;
      this.birthName = Models.optToString(data.customerRecord.birthName);
      this.gender = data.customerRecord.gender;
      this.mothersName = Models.optToString(data.customerRecord.mothersName);
      this.identityDocumentType = Models.optToString(data.customerRecord.identityDocumentType);
      this.identityDocumentNumber = Models.optToString(data.customerRecord.identityDocumentNumber);
      this.invoiceDeadlineAdditionalDays = data.customerRecord.invoiceDeadlineAdditionalDays;
      this.taskRecordDeadlineAdditionalDays = data.customerRecord.taskRecordDeadlineAdditionalDays;
    }

    private loadUserItems(userItems: List<MultiselectOptionItem<number>>) {
      this.userItems.splice(0, this.userItems.length);
      this.userItems.push(...userItems.toArray());
      // this.userItems.sort(OptionItems.createNameComparator());
    }

    loadCustomerRecordItems(customerRecords: List<ImmutableCustomerRecord>,
                            customerRecord: CustomerRecord.CustomerRecord | undefined) {
      this.customerRecordParentItems.splice(0, this.customerRecordParentItems.length);
      this.customerRecordParentItems.push(...customerRecords.toArray());
      if (customerRecord) {
        const ownCustomerRecord =
          this.customerRecordParentItems.find((value: ImmutableCustomerRecord) => value.text === customerRecord.name);
        if (ownCustomerRecord) {
          const index = this.customerRecordParentItems.indexOf(ownCustomerRecord, 0);
          if (index > -1) {
            this.customerRecordParentItems.splice(index, 1);
          }
        }
      }
    }

    setUsers(users: MultiselectOptionItem<number>[]) {
      this.users.splice(0, this.users.length);
      this.users.push(...users);
    }

    setUserItems(userItems: MultiselectOptionItem<number>[]) {
      this.userItems.splice(0, this.userItems.length);
      this.userItems.push(...userItems);
    }

    setUserGroups(userGroups: MultiselectOptionItem<number>[]) {
      this.userGroups.splice(0, this.userGroups.length);
      this.userGroups.push(...userGroups);
    }

    setUserGroupItems(userGroupItems: MultiselectOptionItem<number>[]) {
      this.userGroupItems.splice(0, this.userGroupItems.length);
      this.userGroupItems.push(...userGroupItems);
    }

    private loadCustomerRecordParentItem(ownerCustomerRecordParent: number) {
      const currentItems = List.of(...this.customerRecordParentItems).filter((item) => {
        if (!item) {
          return false;
        }
        return item.id !== null && ownerCustomerRecordParent === item.id;
      });
      this.customerRecordParents.splice(0, this.customerRecordParents.length);
      this.customerRecordParents.push(...currentItems.toArray());
    }

  }

  interface ModelData {
    countryItems: List<AddressModel.CountryItem>;
    customerRecord?: CustomerRecord.CustomerRecord;
    customer: Customer.Customer;
    defaultSelectedCountryCode?: string;
    isClone: boolean;
  }

}
