/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { Order, PagingRequest, QueryResult, ResourceQueryResult, Services } from '../util/services';
import { DownloadedFile } from '../util/downloaded-files';
import { List, Map, Set } from 'immutable';
import { OffsetDateTime } from '../util/dates';
import { GeneralPdfTemplateResource, GeneralPdfTemplateResourceService } from './general-pdf-template-resource.service';
import { EmptyMessage } from '../util/messages';
/* eslint-enable */

@Injectable()
export class GeneralPdfTemplateService implements GeneralPdfTemplate.Service {

  constructor(private resourceService: GeneralPdfTemplateResourceService) {}

  query(request: GeneralPdfTemplate.QueryRequest): Observable<QueryResult<GeneralPdfTemplate.GeneralPdfTemplate>> {
    return Observable.create((observer: Observer<QueryResult<GeneralPdfTemplate.GeneralPdfTemplate>>) => {
      const resourceRequest: GeneralPdfTemplateResource.QueryRequest = {
        ids: Services.createIdParameter(request.ids),
        code: request.code,
        disabled: request.disabled,
        q: request.queryText,
        order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
        no_progress_bar: request.noProgressBar
      };
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<GeneralPdfTemplateResource.GeneralPdfTemplate>) => {
          observer.next({
            items: this.toPublicList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(/*this.translateError(*/error/*)*/);
        },
        () => {
          observer.complete();
        });
    });
  }

  get(request: GeneralPdfTemplate.GetRequest): Observable<GeneralPdfTemplate.GeneralPdfTemplate> {
    return Observable.create((observer: Observer<GeneralPdfTemplate.GeneralPdfTemplate>) => {
      const resourceRequest: GeneralPdfTemplateResource.GetRequest = {
        code: request.code
      };
      return this.resourceService.get(resourceRequest).subscribe(
        (result: GeneralPdfTemplateResource.GeneralPdfTemplate) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(/*this.translateError(*/error/*)*/);
        },
        () => {
          observer.complete();
        });
    });
  }

  getAvailableSystemCodes(request: GeneralPdfTemplate.AvailableSystemCodesRequest): Observable<string[]> {
    return Observable.create((observer: Observer<string[]>) => {
      const resourceRequest = {
        code: request.code,
        no_progress_bar: true
      };
      return this.resourceService.getAvailableSystemCodes(resourceRequest).subscribe(
        (result: string[]) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(/*this.translateError(*/error/*)*/);
        },
        () => {
          observer.complete();
        });
    });
  }

  export(request: GeneralPdfTemplate.DownloadRequest): Observable<DownloadedFile> {
    return this.resourceService.download({
      code: request.code,
    });
  }

  setDisabled(request: GeneralPdfTemplate.DisableRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: GeneralPdfTemplateResource.DisableRequest = {
        code: request.code,
        disabled: request.disabled
      };
      return this.resourceService.setDisabled(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(/*this.translateError(*/error/*)*/);
        },
        () => {
          observer.complete();
        });
    });
  }

  private toPublicList(resourceList: GeneralPdfTemplateResource.GeneralPdfTemplate[]): List<GeneralPdfTemplate.GeneralPdfTemplate> {
    return List.of(...resourceList.map((r) => this.toPublic(r)));
  }

  private toPublic(r: GeneralPdfTemplateResource.GeneralPdfTemplate): GeneralPdfTemplate.GeneralPdfTemplate {
    return {
      id: r.id,
      code: r.code,
      contentHash: r.content_hash,
      version: r.version,
      creationTime: Services.toOffsetDateTime(r.creation_time),
      updateTime: Services.toOffsetDateTime(r.update_time),
      disabled: r.disabled,
      systemCode: r.system_code
    };
  }
}

export namespace GeneralPdfTemplate {

  export interface Service {

    // <editor-fold desc="CRUD">

    query(request: QueryRequest): Observable<QueryResult<GeneralPdfTemplate>>;

    get(request: GetRequest): Observable<EmptyMessage>;


    // </editor-fold>

  }

  export interface GeneralPdfTemplate {
    id: number;
    code: string;
    contentHash: string;
    version: number;
    creationTime?: OffsetDateTime;
    updateTime?: OffsetDateTime;
    disabled: boolean;
    systemCode: boolean;
  }

  export interface QueryRequest {
    ids?: Set<number>;
    code?: string;
    queryText?: string;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
    disabled?: boolean;
    noProgressBar?: boolean;
  }

  export interface GetRequest {
    code: string;
  }

  export interface AvailableSystemCodesRequest {
    code: string;
  }

  export interface DownloadRequest {
    code: string;
  }

  export interface DisableRequest {
    code: string;
    disabled: boolean;
  }

  export enum OrderField {
    ID,
    CODE,
  }

  export enum ValidatedField {
    UNKNOWN
  }

}


class Keys {

  private static readonly ID = 'id';
  private static readonly CODE = 'code';

  private static readonly orderFieldKeyMap: Map<GeneralPdfTemplate.OrderField, string> = Map.of(
    GeneralPdfTemplate.OrderField.ID, Keys.ID,
    GeneralPdfTemplate.OrderField.CODE, Keys.CODE,
  );

  private static readonly keyValidatedFieldMap: Map<string, GeneralPdfTemplate.ValidatedField> = Map.of(
    // GeneralPdfTemplate.ValidatedField.CODE, Keys.CODE,
  );

  public static toOrderFieldKey(field: GeneralPdfTemplate.OrderField): string {
    return Keys.orderFieldKeyMap.get(field)!;
  }

  public static toValidatedField(fieldKey: string): GeneralPdfTemplate.ValidatedField {
    return Keys.keyValidatedFieldMap.get(fieldKey, GeneralPdfTemplate.ValidatedField.UNKNOWN);
  }

}
