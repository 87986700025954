/* eslint-disable */
import { DqlModel } from '../dql/dql.model';
import { Map } from 'immutable';
import { TaskRecordStateMachine } from '../task/task-record-statemachine';
import { CustomerRecord } from './customer-record.service';
import GenderTypeObject = CustomerRecord.GenderTypeObject;
/* eslint-enable */

export class CustomerRecordDqlFieldTextProvider {
  public static getHelper(): DqlModel.DqlStaticFieldTextHelper {
    const fields: Map<string, DqlModel.DqlFieldTexts> = Map.of(
      'id', {stringKey: 'COMMON_TABLE_HEADER_ID'},
      'customer_id', {stringKey: 'CUSTOMER_SEARCH_FIELD_TYPE'},
      'disabled', {stringKey: 'COMMON_TABLE_HEADER_DISABLED'},
      'creation_time', {stringKey: 'COMMON_TABLE_HEADER_CREATION_TIME'},
      'update_time', {stringKey: 'COMMON_TABLE_HEADER_UPDATE_TIME'},
      'external_id', {stringKey: 'COMMON_TABLE_HEADER_EXTERNAL_ID'},
      'name', {stringKey: 'COMMON_NAME'},
      'comment', {stringKey: 'COMMON_COMMENT'},
      'web_address', {stringKey: 'CUSTOMER_LABEL_CONTACT_WEB_ADDRESS'},
      'tax_number', {stringKey: 'CUSTOMER_RECORD_TAX_NUMBER'},
      'eu_tax_number', {stringKey: 'CUSTOMER_RECORD_EU_TAX_NUMBER'},
      'place_of_birth', {stringKey: 'CUSTOMER_RECORD_PLACE_OF_BIRTH'},
      'date_of_birth', {stringKey: 'CUSTOMER_RECORD_DATE_OF_BIRTH'},
      'birth_name', {stringKey: 'CUSTOMER_RECORD_BIRTH_NAME'},
      'mothers_name', {stringKey: 'CUSTOMER_RECORD_MOTHERS_NAME'},
      'contract_number_id', {stringKey: 'CUSTOMER_RECORD_CONTRACT_NUMBER'},
      'gender', {stringKey: 'CUSTOMER_RECORD_GENDER', enumType: CustomerRecord.genderTypes.map((value: GenderTypeObject) => {
          return {id: <string>value.type, stringKey: value.stringKey};
        })},
      'identity_document_type', {stringKey: 'CUSTOMER_RECORD_IDENTITY_DOCUMENT_TYPE'},
      'identity_document_number', {stringKey: 'CUSTOMER_RECORD_IDENTITY_DOCUMENT_NUMBER'},
      'invoice_deadline_additional_days', {stringKey: 'CUSTOMER_RECORD_INVOICE_DEADLINE_ADDITIONAL_DAYS'},
      'parent_id', {stringKey: 'DQL_PARENT_ID'},
      'owner_user_id', {stringKey: 'CUSTOMER_RECORD_USERS'},
      'owner_group_id', {stringKey: 'CUSTOMER_RECORD_USER_GROUPS'},
      'email_address', {stringKey: 'CUSTOMER_SEARCH_EMAIL_ADDRESS_LABEL'},
      'phone_number', {stringKey: 'CUSTOMER_SEARCH_PHONE_NUMBER_LABEL'},
      'contact_person_name', {stringKey: 'CONTACT_PERSON_LIST_CONTACT'},
      'address_city', {stringKey: 'CUSTOMER_RECORD_SEARCH_POSTAL_ADDRESS_CITY'},
      'address_zip_code', {stringKey: 'CUSTOMER_RECORD_SEARCH_POSTAL_ADDRESS_ZIP_CODE'},
      'address_address', {stringKey: 'COMPANY_LABEL_CONTACT_POSTAL_ADDRESS'},
      'address_house_number', {stringKey: 'CUSTOMER_RECORD_SEARCH_POSTAL_ADDRESS_HOUSE_NUMBER'},
    );
    return new DqlModel.DqlStaticFieldTextHelper(fields);
  }
}
