<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div class="breadcrumb-menu d-flex">
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding-half animated fadeIn">
  <div class="container-vertical-padding-half">
    <app-android-app-version-settings
        #settingsComponent>
    </app-android-app-version-settings>
  </div>
  <div class="container-vertical-padding-half">
    <app-android-app-version-base
      [readonly]="false"
      [innerCard]="false"
      (versionChanged)="onVersionChanged($event)"
      (versionDeleted)="onVersionDeleted($event)"
      [headerKey]="'ANDROID_VERSION_NEW_VERSION'">
    </app-android-app-version-base>
  </div>
</div>
