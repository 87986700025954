/* eslint-disable */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../lib/auth.service';
import { MultiselectOptionItem, OptionItem, QueryFieldModel, SelectUtils, UiConstants } from '../../../util/core-utils';
import { EmptyMessage } from '../../../lib/util/messages';
import { Arrays } from '../../../lib/util/arrays';
import { UploadErrorLocalizer } from '../../../util/upload-error-localizer';
import { Stock, StockService } from '../../../lib/stock/stock.service';
import { RightModel } from '../../../app.rights';
import { RightResolver, RightService } from '../../../lib/right.service';
import { StockSearchModel, StockType, StockTypeFilterOption, stockTypeFilterOptions } from '../../../util/stock/stock-utils';
import { InputMask } from '../../../util/input-masks';
import { combineLatest, Observable } from 'rxjs';
import { Strings } from '../../../lib/util/strings';
import { StockSearch, StockSearchService } from '../../../lib/stock-search-service';
import { Angular2Multiselects } from '../../../util/multiselect';
import { StringKey } from '../../../app.string-keys';
import { Set } from 'immutable';
import { OrderType, ResourceQueryResult, Services } from '../../../lib/util/services';
import { ToasterService } from '../../../fork/angular2-toaster/angular2-toaster';
import { DisabledEnum, DisabledItem } from '../../../util/search-utils';
import { DropdownItemType } from '../../../shared/dropdown/dropdown-item/dropdown-item-type';
import { CustomerRecordMultiselectProvider } from '../../../lib/customer/customer-record-multiselect-provider.service';
import {
  CustomerRecordContactLocationMultiselectProvider
} from '../../../lib/customer/contact/customer-record-contact-location-multiselect.provider';
import { UserMultiselectProvider } from '../../../lib/user/user-multiselect.provider';
import { CompanyMultiselectProvider } from '../../../lib/company/company-multiselect.provider';
import { StockItemMultiselectProvider } from '../../../lib/stock/stock-item-multiselect.provider';

/* eslint-enable */

@Component({
  selector: 'app-stock-list',
  templateUrl: 'stock-list.component.html',
  styleUrls: ['stock-list.component.scss']
})
export class StockListComponent implements OnInit, OnDestroy {
  Stock = Stock;
  UiConstants = UiConstants;
  DropdownItemType = DropdownItemType;
  StockType = StockType;

  queryModel: QueryFieldModel<Stock.OrderField> = new QueryFieldModel(Stock.OrderField.ID, OrderType.DESC);
  stockList: Stock[] = [];
  disabledItems: DisabledItem[] = [];
  showSearch: boolean = false;
  users: MultiselectOptionItem<number>[] = [];
  usersForSearch: MultiselectOptionItem<number>[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  searchModel: StockSearchModel = new StockSearchModel();
  stockDisabledItems: DisabledItem[] = [];
  InputMask = InputMask;
  SelectUtils = SelectUtils;
  searchResetClicked = false;
  companies: MultiselectOptionItem<number>[] = [];
  stockTypes: OptionItem<StockTypeFilterOption>[] = [];
  customerRecords: MultiselectOptionItem<number>[] = [];
  contactLocations: MultiselectOptionItem<number>[] = [];
  stockItems: MultiselectOptionItem<number>[] = [];

  rightModel: RightModel = RightModel.empty();

  dropdownSettings: Angular2Multiselects.Settings;
  customerDropdownSettings: Angular2Multiselects.Settings;
  locationDropdownSettings: Angular2Multiselects.Settings;

  uploadPath: string = '/stock-records/import-xls';

  constructor(
    private translateService: TranslateService,
    private uploadErrorLocalizer: UploadErrorLocalizer,
    private userMultiselectProvider: UserMultiselectProvider,
    private authService: AuthService,
    private rightService: RightService,
    private stockService: StockService,
    private stockSearchService: StockSearchService,
    private customerRecordMultiselectProvider: CustomerRecordMultiselectProvider,
    private contactLocationMultiselectProvider: CustomerRecordContactLocationMultiselectProvider,
    private toasterService: ToasterService,
    private stockItemMultiselectProvider: StockItemMultiselectProvider,
    private companyMultiselectProvider: CompanyMultiselectProvider) {
  }

  ngOnInit() {
    this.translateService.get('MENU_NAVBAR_STOCK').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.initDropdownSettings();
    this.initDisabledOptions(DisabledEnum.FALSE);
    this.loadRightModels(() => {
      this.loadSearch(() => {
        this.loadList();
      });
    });
  }

  private loadSearch(completion: () => void) {
    const obs: Observable<SearchLoadResult> = combineLatest(
      this.stockSearchService.getSearchData({}),
      (storedSearchData: StockSearch.SearchDataResult) => {
        const result: SearchLoadResult = {
          storedSearchData: storedSearchData
        };
        return result;
      }
    );
    obs.subscribe(
      (result: SearchLoadResult) => {
        this.showSearch = !this.isEmptyStoredSearchData(result.storedSearchData);
        this.postInitSearch(result.storedSearchData);
        this.initStockTypeFilterOptions();
        completion();
      }
    );
  }

  private initStockTypeFilterOptions() {
    this.stockTypes = [];
    Arrays.iterateByIndex(stockTypeFilterOptions, (stockTypeFilterOption) => {
      const item = new OptionItem<StockTypeFilterOption>();
      item.id = stockTypeFilterOption.type;
      this.translateService.get(stockTypeFilterOption.stringKey).subscribe(
        (text: string) => {
          item.text = text;
        }
      );
      this.stockTypes.push(item);
    });
  }

  private initDisabledOptions(initValue: DisabledEnum) {
    this.stockDisabledItems = [];
    const disabledEnums: DisabledEnum[] = [DisabledEnum.NONE, DisabledEnum.FALSE, DisabledEnum.TRUE];
    Arrays.iterateByIndex(disabledEnums, (key) => {
      const item = new DisabledItem();
      item.id = key;
      this.translateService.get('COMMON_VALUE_DISABLED_ENUM_' + key).subscribe(
        (text: string) => {
          item.text = text;
        }
      );
      this.stockDisabledItems.push(item);
      if (!this.searchModel.disabled) {
        if (key === initValue) {
          this.searchModel.disabled = item;
        }
      }
    });
  }

  initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .remoteSearch(true)
      .build();
    this.customerDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
    this.locationDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .remoteSearch(true)
      .build();
  }

  private postInitSearch(storedSearchData: StockSearch.SearchDataResult) {
    this.queryModel.itemsPerPage = storedSearchData.searchData.itemsPerPage;
    this.queryModel.currentPage = storedSearchData.searchData.pageNumber;
    this.queryModel.setOrder(storedSearchData.searchData.order);
    this.searchModel.id = storedSearchData.searchData.id;
    this.searchModel.name = storedSearchData.searchData.name;
    this.searchModel.assigneeUsers = storedSearchData.searchData.ownerUsers;
    this.searchModel.companies = storedSearchData.searchData.companies;
    this.searchModel.type = storedSearchData.searchData.type;
    this.searchModel.disabled = storedSearchData.searchData.disabled ? storedSearchData.searchData.disabled : this.getActiveItem();
    this.searchModel.customerRecord = storedSearchData.searchData.customerRecord;
    this.searchModel.contactLocation = storedSearchData.searchData.contactLocation;
    this.searchModel.stockItems = storedSearchData.searchData.stockItems;
  }

  searchCustomerRecord(q?: string) {
    this.customerRecordMultiselectProvider.loadActiveNonContact(q).subscribe(c => {
      this.customerRecords = c;
    });
  }

  onCustomerRecordChanged() {
    this.searchModel.contactLocation = [];
    if (this.searchModel.customerRecord.length === 1) {
      this.searchContactLocation();
    }
  }

  searchContactLocation(q?: string) {
    if (this.searchModel.customerRecord.length === 1) {
      this.contactLocationMultiselectProvider.loadForCustomer(this.searchModel.customerRecord[0].id, q).subscribe(c => {
        this.contactLocations = c;
      });
    }
  }

  searchUsers(q?: string) {
    this.userMultiselectProvider.loadActive(q).subscribe(c => {
      this.usersForSearch = c;
    });
  }

  searchCompanies(q?: string) {
    this.companyMultiselectProvider.loadActive(q).subscribe(c => {
      this.companies = c;
    });
  }

  searchStockItems(q?: string) {
    this.stockItemMultiselectProvider.loadActive(q).subscribe(c => {
      this.stockItems = c;
    });
  }

  private getActiveItem(): DisabledItem {
    const item = new DisabledItem();
    item.id = DisabledEnum.FALSE;
    item.text = 'STOCK_SEARCH_FIELD_DISABLED_ACTIVE';
    return item;
  }

  private loadRightModels(completion: () => void) {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        completion();
      }
    );
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: Stock.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }


  private loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();
    this.stockService.query({
      id: Strings.undefinedOrNonEmpty(this.searchModel.id),
      name: Strings.undefinedOrNonEmpty(this.searchModel.name),
      owner_user_id: this.searchModel.assigneeUserIds,
      company_id: this.searchModel.companyIds,
      customer_record_id: this.searchModel.customerRecordIds,
      contact_location_id: this.searchModel.contactLocationIds,
      stock_item_ids: this.searchModel.stockItemIds,
      type: this.searchModel.type === 'ALL' ? undefined : this.searchModel.type,
      disabled: this.searchModel.disabled ? this.getDisabled() : undefined,
      page_number: requestedPage,
      number_of_items: this.queryModel.itemsPerPage,
      fields:
        ['id', 'name', 'owner_user_ids', 'creation_time', 'update_time', 'disabled', 'type', 'external_id', 'central', 'waste'].join(','),
      order: Services.createOrderFieldParameter(Stock.Keys.toOrderFieldKey, Set.of(order)),
    })
      .subscribe(
        (result: ResourceQueryResult<Stock>) => {
          this.stockList = result.items;
          this.queryModel.currentPage = requestedPage;
          this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
          this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
          this.userMultiselectProvider.getByIds(this.stockList.map(s => s.owner_user_ids).reduce((accumulator, array) => {
            return [...accumulator, ...array];
          }, [])).subscribe(users => {
            this.users = users;
          });
        }
      );
  }

  setDisabled(event: any, stock: Stock, disabled: boolean) {
    this.stockService.setDisabled({
      id: stock.id,
      disabled: disabled
    })
      .subscribe(
        (result: EmptyMessage) => {
          this.loadList();
        },
        (error: any) => {
          this.loadList();
        }
      );
  }

  getDisabled(): boolean | undefined {
    if (this.searchModel.disabled!.id === DisabledEnum.FALSE) {
      return false;
    }
    else if (this.searchModel.disabled!.id === DisabledEnum.TRUE) {
      return true;
    }
    return undefined;
  }

  getOwnerUserPersonName(stock: Stock): string | undefined {
    const users = this.users;
    const owners: string[] = [];
    if (users === null) {
      return '...';
    }
    if (!stock || !stock.owner_user_ids) {
      return undefined;
    }
    stock.owner_user_ids.forEach(id => {
      const result = Arrays.findById(users, id);
      if (result) {
        owners.push(result.itemName!);
      }
    });
    return owners.join(', ');
  }

  public onImportSuccess(succeeded: boolean) {
    if (succeeded) {
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutLong,
        type: UiConstants.toastTypeSuccess,
        title: this.translateService.instant(StringKey.COMMON_IMPORT_SUCCESS),
        body: this.translateService.instant(StringKey.COMMON_SUCCESS)
      });
    }
  }

  onSearchClicked() {
    this.loadList();
  }

  onSearchReset() {
    this.stockSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.searchResetClicked = true;
        this.loadSearch(() => {
          this.loadList(1);
        });
      }
    );
  }

  private saveSearch() {
    const request = {
      searchData: {
        itemsPerPage: this.queryModel.itemsPerPage,
        pageNumber: this.queryModel.currentPage,
        order: this.queryModel.getOrder(),
        id: this.searchModel.id,
        name: this.searchModel.name,
        ownerUsers: this.searchModel.assigneeUsers,
        companies: this.searchModel.companies,
        customerRecord: this.searchModel.customerRecord,
        contactLocation: this.searchModel.contactLocation,
        stockItems: this.searchModel.stockItems,
        type: this.searchModel.type,
        disabled: this.searchModel.disabled
      }
    };
    this.stockSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  toggleSearch() {
    if (!this.searchModel.disabled) {
      this.searchModel.disabled = this.getActiveItem();
    }
    this.showSearch = !this.showSearch;
  }

  isEmptyStoredSearchData(data: StockSearch.SearchDataResult): boolean {
    return OptionItem.idOrUndefined(data.searchData.disabled) === undefined
      && data.searchData.id.length === 0
      && data.searchData.name.length === 0
      && data.searchData.ownerUsers.length === 0
      && data.searchData.companies.length === 0
      && data.searchData.customerRecord.length === 0
      && data.searchData.contactLocation.length === 0
      && data.searchData.stockItems.length === 0
      && data.searchData.type === 'ALL'
      ;
  }

  getStockTypeIconClass(stock: Stock) {
    return stock.central && stock.waste
      ? 'icomoon icomoon-stock-type-central-and-waste'
      : stock.central
        ? 'icomoon icomoon-stock-type-central'
        : stock.waste
          ? 'icomoon icomoon-stock-type-waste'
          : StockType.fromName(stock.type)
            ? StockType.fromName(stock.type)!.iconClass
            : 'icomoon icomoon-administration-stock-management-alt';
  }

  getStockTypeStringKey(stock: Stock) {
    return stock.central && stock.waste
      ? 'STOCK_TYPE_CENTRAL_AND_WASTE'
      : stock.central
        ? 'STOCK_TYPE_CENTRAL'
        : stock.waste
          ? 'STOCK_TYPE_WASTE'
          : StockType.fromName(stock.type)
            ? StockType.fromName(stock.type)!.textDictionaryKey
            : '';
  }

  ngOnDestroy() {
    this.saveSearch();
  }

}

interface SearchLoadResult {
  storedSearchData: StockSearch.SearchDataResult,
}
