<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div class="breadcrumb-menu d-flex">

    <!--STOCK_LOG_LIST-->
    <div *ngIf="rightModel.stockRecordLogRead.hasRight()"
         class="breadcrumb-button-icon-container cursor-pointer"
         uiSref="Admin.StockLogList">
      <a class="btn-setting d-flex align-items-center" title="{{'STOCK_LOG_LIST' | translate}}">
        <i class="icomoon icomoon-stocklog"></i>
        <span class="breadcrumb-button-text-visible">{{'STOCK_LOG_LIST' | translate}}</span>
      </a>
    </div>

    <div *ngIf="rightModel.stockCreate.hasRight()"
         class="breadcrumb-button-icon-container cursor-pointer"
         uiSref="Admin.StockCreate">
      <a class="btn-setting d-flex align-items-center" title="{{'COMMON_BUTTON_ADD' | translate}}">
        <i class="icomoon icomoon-add"></i>
        <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_ADD' | translate}}</span>
      </a>
    </div>
    <div *ngIf="rightModel.stockRecordImportFile.hasRight()"
         class="breadcrumb-button-icon-container-no-text cursor-pointer p-0 negative-margin-right">
      <a class="dropdown dropdown-arrow-padding"
         dropdown title="{{'COMMON_BUTTON_ADD' | translate}}"
         href="#" onclick="return false" dropdownToggle
         data-toggle="dropdown" role="button"
         aria-haspopup="true" aria-expanded="false">
        <i class="icomoon icomoon-dropdown"></i>
        <div class="dropdown-menu dropdown-menu-right"
             *dropdownMenu
             aria-labelledby="simple-dropdown">
          <span *ngIf="rightModel.stockRecordImportFile.hasRight()"
                (click)="importDialog.toggleDialog()"
                class="btn-setting dropdown-item d-flex align-items-center"
                title="{{'COMMON_BUTTON_IMPORT' | translate}}">
            <i class="icomoon icomoon-csv-import"></i>
            <span>{{'COMMON_BUTTON_IMPORT' | translate}}</span>
          </span>
        </div>
      </a>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="card">

    <div class="card-header">
      {{'STOCK_PANEL_HEADING_LIST' | translate}}

      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()"
           title="{{'COMMON_SHOW_SEARCH_HINT' | translate}}"><i class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>
    <div class="card-body border-bottom" *ngIf="showSearch">
      <div class="row form-group">
        <div class="col-md-3">
          <label class="search-label">{{'STOCK_SEARCH_FIELD_ID' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'STOCK_SEARCH_FIELD_ID' | translate}}"
                 maxlength="{{UiConstants.maxInputLongLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [textMask]="{mask: InputMask.NATURAL_INTEGER, guide: false}"
                 [(ngModel)]="searchModel.id" name="id" id="id" #id="ngModel">
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'COMMON_NAME' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.name" name="name" id="name" #name="ngModel">
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'TASK_RECORD_SEARCH_USER_NAME' | translate}}</label>
          <angular2-multiselect [data]="usersForSearch" [settings]="dropdownSettings"
                                class="form-control"
                                [(ngModel)]="searchModel.assigneeUsers"
                                (onRemoteSearch)="searchUsers($event.target.value)"
                                #userMulti="ngModel" name="userMulti" id="userMulti">
          </angular2-multiselect>
        </div>

        <div class="col-md-3" *ngIf="rightModel.companyReadAll.hasRight()">
          <label class="search-label">{{'STOCK_CREATE_EDIT_COMPANY'|translate}}</label>
          <angular2-multiselect
            [(ngModel)]="searchModel.companies"
            [ngModelOptions]="{standalone: true}"
            [data]="companies"
            (onRemoteSearch)="searchCompanies($event.target.value)"
            [settings]="dropdownSettings">
          </angular2-multiselect>
        </div>
        <div class="col-md-3" *ngIf="rightModel.customerRecordRead.hasRight()">
          <label class="search-label">{{'STOCK_CREATE_EDIT_CUSTOMER_RECORD'|translate}}</label>
          <angular2-multiselect
            [(ngModel)]="searchModel.customerRecord"
            [ngModelOptions]="{standalone: true}"
            [data]="customerRecords"
            (onRemoteSearch)="searchCustomerRecord($event.target.value)"
            (onSelect)="onCustomerRecordChanged()"
            (onDeSelect)="onCustomerRecordChanged()"
            [settings]="customerDropdownSettings">
          </angular2-multiselect>
        </div>
        <div class="col-md-3" *ngIf="rightModel.customerRecordRead.hasRight() && searchModel.customerRecord.length === 1">
          <label class="search-label">{{'STOCK_CREATE_EDIT_CONTACT_LOCATION'|translate}}</label>
          <angular2-multiselect
            [(ngModel)]="searchModel.contactLocation"
            [ngModelOptions]="{standalone: true}"
            [data]="contactLocations"
            (onRemoteSearch)="searchContactLocation($event.target.value)"
            [settings]="customerDropdownSettings">
          </angular2-multiselect>
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'COMMON_TYPE' | translate}}</label>
          <select class="form-control" [(ngModel)]="searchModel.type"
                  id="type" name="type" #type="ngModel">
            <option *ngFor="let item of stockTypes" [ngValue]="item.id">{{item.text}}</option>
          </select>
        </div>
        <div class="col-md-3" *ngIf="rightModel.stockItemRead.hasRight()">
          <label class="search-label">{{'STOCK_SEARCH_FIELD_STOCK_ITEM'|translate}}</label>
          <angular2-multiselect
            [(ngModel)]="searchModel.stockItems"
            [ngModelOptions]="{standalone: true}"
            [data]="stockItems"
            (onRemoteSearch)="searchStockItems($event.target.value)"
            [settings]="dropdownSettings">
          </angular2-multiselect>
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'STOCK_SEARCH_FIELD_DISABLED' | translate}}</label>
          <select class="form-control" [compareWith]="SelectUtils.compareObjects" [(ngModel)]="searchModel.disabled"
                  id="disabled" name="disabled" #disabled="ngModel">
            <option *ngFor="let item of stockDisabledItems" [ngValue]="item">{{item.text}}</option>
          </select>
        </div>
      </div>
      <div class="col-md-12 d-flex justify-content-md-end align-items-end px-0">
        <div class="btn-group" role="group">
          <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
            {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
          </button>
          <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
            {{'COMMON_BUTTON_SEARCH' | translate}}
          </button>
        </div>
      </div>
    </div><!-- /.card-body-->

    <div class="card-body">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header w-5 hidden-xs-down">
            <app-table-field-sorter [orderField]="Stock.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(Stock.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header long">
            <app-table-field-sorter [orderField]="Stock.OrderField.NAME"
                                    [orderType]="queryModel.getOrderType(Stock.OrderField.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-15 hidden-md-down">
            <app-table-field-sorter [orderField]="Stock.OrderField.USER_NAME"
                                    [orderType]="queryModel.getOrderType(Stock.OrderField.USER_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'STOCK_TABLE_HEADER_USER_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-15 hidden-md-down">
            <app-table-field-sorter [orderField]="Stock.OrderField.CREATION_TIME"
                                    [orderType]="queryModel.getOrderType(Stock.OrderField.CREATION_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_CREATION_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-15 hidden-md-down">
            <app-table-field-sorter [orderField]="Stock.OrderField.UPDATE_TIME"
                                    [orderType]="queryModel.getOrderType(Stock.OrderField.UPDATE_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_UPDATE_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let stock of stockList | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }"
            [disabledTableRow]="stock.disabled">
          <td class="responsive-table-column hidden-xs-down align-middle">{{stock.id}}</td>
          <td class="responsive-table-column long align-middle">
            <div class="d-flex align-items-center">
              <div class="stock-type-icon-container mr-1">
                <i class="{{getStockTypeIconClass(stock)}}"
                   [popover]="stockTypePopover" triggers="mouseenter:mouseleave">
                </i>
              </div>
              <ng-template #stockTypePopover>
                <i class="{{getStockTypeIconClass(stock)}} mr-1"></i>
                <b>{{getStockTypeStringKey(stock) | translate}}</b>
              </ng-template>
              <div>
                <a class="id-text cursor-pointer"
                   href="#" onclick="return false"
                   uiSref="Admin.StockDetail"
                   [uiParams]="{ id: stock.id }"
                   title="{{'COMMON_BUTTON_DETAILS' | translate}}">
                  {{stock.name}}
                </a>
                <div class="text-truncate table-text-subtitle">
                  {{stock.external_id}}
                </div>
              </div>
            </div>
          </td>
          <td class="responsive-table-column hidden-md-down align-middle">{{getOwnerUserPersonName(stock)}}</td>
          <td class="responsive-table-column hidden-md-down align-middle">{{stock.creation_time | date:'short'}}</td>
          <td class="responsive-table-column hidden-md-down align-middle">{{stock.update_time | date:'short'}}</td>
          <td class="align-middle">
            <app-table-options-menu>
              <app-dropdown-item
                [iconClass]="'icomoon-detail'"
                [titleStringKey]="'COMMON_BUTTON_DETAILS'"
                class="cursor-pointer"
                uiSref="Admin.StockDetail"
                [uiParams]="{ id: stock.id }">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="rightModel.stockUpdate.hasRight()"
                [iconClass]="'icomoon-modify'"
                [titleStringKey]="'COMMON_BUTTON_EDIT'"
                class="cursor-pointer"
                uiSref="Admin.StockEdit"
                [uiParams]="{ id: stock.id }">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="stock.disabled && rightModel.stockDisable.hasRight()"
                [iconClass]="'icomoon-enable'"
                [titleStringKey]="'COMMON_BUTTON_ENABLE'"
                [itemType]="DropdownItemType.ENABLE"
                class="cursor-pointer"
                (click)="setDisabled($event, stock, false)">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="!stock.disabled && rightModel.stockDisable.hasRight()"
                [iconClass]="'icomoon-disable'"
                [titleStringKey]="'COMMON_BUTTON_DISABLE'"
                [itemType]="DropdownItemType.DISABLE"
                class="cursor-pointer"
                (click)="setDisabled($event, stock, true)">
              </app-dropdown-item>
            </app-table-options-menu>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header w-5 hidden-xs-down">
            <app-table-field-sorter [orderField]="Stock.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(Stock.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header long">
            <app-table-field-sorter [orderField]="Stock.OrderField.NAME"
                                    [orderType]="queryModel.getOrderType(Stock.OrderField.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-15 hidden-md-down">
            <app-table-field-sorter [orderField]="Stock.OrderField.USER_NAME"
                                    [orderType]="queryModel.getOrderType(Stock.OrderField.USER_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'STOCK_TABLE_HEADER_USER_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-15 hidden-md-down">
            <app-table-field-sorter [orderField]="Stock.OrderField.CREATION_TIME"
                                    [orderType]="queryModel.getOrderType(Stock.OrderField.CREATION_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_CREATION_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-15 hidden-md-down">
            <app-table-field-sorter [orderField]="Stock.OrderField.UPDATE_TIME"
                                    [orderType]="queryModel.getOrderType(Stock.OrderField.UPDATE_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_UPDATE_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>
  </div>
</div>

<app-file-upload-dialog
  [uploadPath]="uploadPath"
  (onResult)="onImportSuccess($event)"
  #importDialog
></app-file-upload-dialog>
