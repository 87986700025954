<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div class="breadcrumb-menu d-flex">
    <div class="breadcrumb-button-icon-container negative-margin-right cursor-pointer"
         (click)="openProcessCreateDialog()"
         *ngIf="workflowItems.length > 0 && rightModel.legacyProcessCreate.hasRight()">
      <a class="btn-setting d-flex align-items-center" title="{{'ORDER_LIST_CREATE_PROCESS_BUTTON' | translate}}">
        <i class="icomoon icomoon-process"></i>
        <span class="breadcrumb-button-text-visible">{{'ORDER_LIST_CREATE_PROCESS_BUTTON' | translate}}</span>
      </a>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="card">

    <div class="card-header">
      {{'MENU_NAVBAR_LEGACY_PROCESSES' | translate}}

      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()"
           title="{{'COMMON_SHOW_SEARCH_HINT' | translate}}"><i class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>
    <div class="card-body border-bottom" *ngIf="showSearch">
      <div class="row form-group mb-0">
        <div class="col">
          <label class="search-label">{{'PROCESS_SEARCH_ID' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'PROCESS_SEARCH_ID' | translate}}"
                 maxlength="{{UiConstants.maxInputLongLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.processId" name="processId" id="processId" #processId="ngModel">
        </div>
        <div class="col">
          <label class="search-label">{{'PROCESS_SEARCH_EXTERNAL_ID' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'PROCESS_SEARCH_EXTERNAL_ID' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.externalId" name="externalId" id="externalId" #externalId="ngModel">
        </div>
        <div class="col">
          <label class="search-label">{{'COMMON_NAME' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
                 (keyup.enter)="onSearchClicked()"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 [(ngModel)]="searchModel.name" name="name" id="name" #name="ngModel">
        </div>
        <div class="col">
          <label class="search-label">{{'PROCESS_SEARCH_WORKFLOW_NAME' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'PROCESS_SEARCH_WORKFLOW_NAME' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.workflowName" name="workflowName" id="workflowName" #workflowName="ngModel">
        </div>
      </div>
      <div class="row form-group">
        <div class="col">
          <label class="search-label">{{'COMMON_STATE' | translate}}</label>
          <select class="form-control" [(ngModel)]="searchModel.state"
                  id="state" name="state" #state="ngModel">
            <option *ngFor="let item of processStates" [ngValue]="item">{{item.text}}</option>
          </select>
        </div>
        <div class="col">
          <label class="search-label">{{'PROCESS_SEARCH_NUMBER_OF_ORDERS_FROM' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'PROCESS_SEARCH_NUMBER_OF_ORDERS_FROM' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.numberOfOrdersFrom" name="numberOfOrdersFrom" id="numberOfOrdersFrom" #numberOfOrdersFrom="ngModel">
        </div>
        <div class="col">
          <label class="search-label">{{'PROCESS_SEARCH_NUMBER_OF_ORDERS_TO' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'PROCESS_SEARCH_NUMBER_OF_ORDERS_TO' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.numberOfOrdersTo" name="numberOfOrdersTo" id="numberOfOrdersTo" #numberOfOrdersTo="ngModel">
        </div>
      </div>
      <div class="row form-group">
        <div class="col">
          <label class="search-label">{{'PROCESS_SEARCH_DEADLINE_FROM' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control date"
                   placeholder="{{'PROCESS_SEARCH_DEADLINE_FROM' | translate}}"
                   [(ngModel)]="searchModel.deadlineFrom" ngbDatepicker name="deadlineFrom" #deadlineFrom="ngbDatepicker"
                   id="deadlineFrom">
            <div class="input-group-addon-gray" (click)="deadlineFrom.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>
        <div class="col">
          <label class="search-label">{{'PROCESS_SEARCH_DEADLINE_TO' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control date"
                   placeholder="{{'PROCESS_SEARCH_DEADLINE_TO' | translate}}"
                   [(ngModel)]="searchModel.deadlineTo" ngbDatepicker name="deadlineTo" #deadlineTo="ngbDatepicker"
                   id="deadlineTo">
            <div class="input-group-addon-gray" (click)="deadlineTo.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>
        <div class="col">
          <label class="search-label">{{'PROCESS_SEARCH_CREATION_TIME_FROM' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control date"
                   placeholder="{{'PROCESS_SEARCH_CREATION_TIME_FROM' | translate}}"
                   [(ngModel)]="searchModel.creationTimeFrom" ngbDatepicker name="creationTimeFrom" #creationTimeFrom="ngbDatepicker"
                   id="creationTimeFrom">
            <div class="input-group-addon-gray" (click)="creationTimeFrom.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>
        <div class="col">
          <label class="search-label">{{'PROCESS_SEARCH_CREATION_TIME_TO' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control date"
                   placeholder="{{'PROCESS_SEARCH_CREATION_TIME_TO' | translate}}"
                   [(ngModel)]="searchModel.creationTimeTo" ngbDatepicker name="creationTimeTo" #creationTimeTo="ngbDatepicker"
                   id="creationTimeTo">
            <div class="input-group-addon-gray" (click)="creationTimeTo.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 d-flex justify-content-md-end align-items-end px-0">
        <div class="btn-group" role="group">
          <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
            {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
          </button>
          <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
            {{'COMMON_BUTTON_SEARCH' | translate}}
          </button>
        </div>
      </div>
    </div><!-- /.card-body-->

    <div class="card-body">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="LegacyProcess.OrderField.PROCESS_ID"
                                    [orderType]="queryModel.getOrderType(LegacyProcess.OrderField.PROCESS_ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'PROCESS_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-10 hidden-md-down">
            <app-table-field-sorter [orderField]="LegacyProcess.OrderField.WORKFLOW_NAME"
                                    [orderType]="queryModel.getOrderType(LegacyProcess.OrderField.WORKFLOW_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'PROCESS_TABLE_HEADER_WORKFLOW_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header long">
            <app-table-field-sorter [orderField]="LegacyProcess.OrderField.NAME"
                                    [orderType]="queryModel.getOrderType(LegacyProcess.OrderField.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-10 hidden-sm-down">
            <app-table-field-sorter [orderField]="LegacyProcess.OrderField.DEADLINE"
                                    [orderType]="queryModel.getOrderType(LegacyProcess.OrderField.DEADLINE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'PROCESS_TABLE_HEADER_DEADLINE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-10 hidden-sm-down">
            <app-table-field-sorter [orderField]="LegacyProcess.OrderField.STATE"
                                    [orderType]="queryModel.getOrderType(LegacyProcess.OrderField.STATE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_STATUS' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-10 hidden-lg-down">
            <app-table-field-sorter [orderField]="LegacyProcess.OrderField.NUMBER_OF_ORDERS"
                                    [orderType]="queryModel.getOrderType(LegacyProcess.OrderField.NUMBER_OF_ORDERS)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'PROCESS_TABLE_HEADER_NUMBER_OF_ORDERS' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-10 hidden-1300-down">
            <app-table-field-sorter [orderField]="LegacyProcess.OrderField.CREATION_TIME"
                                    [orderType]="queryModel.getOrderType(LegacyProcess.OrderField.CREATION_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_CREATION_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-table-actions">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let process of processList | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }">
          <td class="responsive-table-column">{{process.processId}}</td>
          <td class="responsive-table-column hidden-md-down">{{getWorkflowName(process)}}</td>
          <td class="responsive-table-column long">{{process.displayName}}</td>
          <td class="responsive-table-column hidden-sm-down">{{process.deadline.toUtcIsoString() | date:'short'}}</td>
          <td class="responsive-table-column hidden-sm-down">{{getProcessStateName(process)}}</td>
          <td class="responsive-table-column hidden-lg-down">{{getProcessOrderCount(process)}}</td>
          <td class="responsive-table-column hidden-1300-down">{{process.creationTime.toUtcIsoString() | date:'short'}}</td>
          <td class="responsive-table-column">
            <a class="icons-table-group cursor-pointer" uiSref="Admin.LegacyProcessDetail"
               [uiParams]="{ id: process.processId }">
              <i class="icomoon icons-table-item icomoon-detail icomoon-detail-table"
                 title="{{'COMMON_BUTTON_DETAILS' | translate}}"></i>
            </a>
            <a class="icons-table-group cursor-pointer" uiSref="Admin.LegacyProcessEdit"
               [uiParams]="{ id: process.processId }"
               *ngIf="rightModel.legacyProcessUpdate.hasRight() && (process.state !== 'CLOSED' && process.state !== 'FINISHED')">
              <i class="icomoon icons-table-item icomoon-modify icomoon-modify-table"
                 title="{{'COMMON_BUTTON_EDIT' | translate}}"></i>
            </a>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="LegacyProcess.OrderField.PROCESS_ID"
                                    [orderType]="queryModel.getOrderType(LegacyProcess.OrderField.PROCESS_ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'PROCESS_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-10 hidden-md-down">
            <app-table-field-sorter [orderField]="LegacyProcess.OrderField.WORKFLOW_NAME"
                                    [orderType]="queryModel.getOrderType(LegacyProcess.OrderField.WORKFLOW_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'PROCESS_TABLE_HEADER_WORKFLOW_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header long">
            <app-table-field-sorter [orderField]="LegacyProcess.OrderField.NAME"
                                    [orderType]="queryModel.getOrderType(LegacyProcess.OrderField.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-10 hidden-sm-down">
            <app-table-field-sorter [orderField]="LegacyProcess.OrderField.DEADLINE"
                                    [orderType]="queryModel.getOrderType(LegacyProcess.OrderField.DEADLINE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'PROCESS_TABLE_HEADER_DEADLINE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-10 hidden-sm-down">
            <app-table-field-sorter [orderField]="LegacyProcess.OrderField.STATE"
                                    [orderType]="queryModel.getOrderType(LegacyProcess.OrderField.STATE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_STATUS' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-10 hidden-lg-down">
            <app-table-field-sorter [orderField]="LegacyProcess.OrderField.NUMBER_OF_ORDERS"
                                    [orderType]="queryModel.getOrderType(LegacyProcess.OrderField.NUMBER_OF_ORDERS)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'PROCESS_TABLE_HEADER_NUMBER_OF_ORDERS' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-10 hidden-1300-down">
            <app-table-field-sorter [orderField]="LegacyProcess.OrderField.CREATION_TIME"
                                    [orderType]="queryModel.getOrderType(LegacyProcess.OrderField.CREATION_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_CREATION_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-table-actions">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>
  </div>
</div>

<div bsModal [config]="UiConstants.modalConfig" #processCreateDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="processCreateDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'PROCESS_CREATE_TITLE' | translate}}</h4>
        <button type="button" class="close" (click)="closeProcessCreateDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form class="modal-body" (submit)="f.form.valid && createProcess()" [formGroup]="formGroup" #f="ngForm">

        <div class="form-group row"
             [ngClass]="{ 'has-danger': hasLocalFieldError('workflowId')}">
          <label class="col-form-label col-md-4 required-field-label">{{'LEGACY_PROCESS_CREATE_WORKFLOW' | translate}}</label>
          <div class="col-md-8">
            <select class="form-control" [compareWith]="SelectUtils.compareObjects"
                    [(ngModel)]="processCreateModel.workflowId"
                    [ngClass]="{ 'form-control-danger': hasLocalFieldError('workflowId') }"
                    formControlName="workflowId"
                    (ngModelChange)="removeFieldError(LegacyProcess.ValidatedField.WORKFLOW_ID)"
                    trim="blur" validateOnBlur [validateFormControl]="formGroup.controls['workflowId']">
              <option *ngIf="workflowItems.length > 1" [ngValue]="undefined" disabled>{{'COMMON_VALUE_UNSELECTED' | translate}}</option>
              <option *ngFor="let item of workflowItems" [ngValue]="item.id">{{item.name}}</option>
            </select>
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('workflowId')">
              {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
            </div>
          </div>
          <!-- /.col-md-8 -->
        </div>

        <div class="form-group row"
             [ngClass]="{ 'has-danger': hasFieldError(LegacyProcess.ValidatedField.NAME) }">
          <label class="col-form-label col-md-4">{{'COMMON_NAME' | translate}}</label>
          <div class="col-md-8">
            <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
                   [ngClass]="{ 'form-control-danger': hasFieldError(LegacyProcess.ValidatedField.NAME) }"
                   [(ngModel)]="processCreateModel.name" formControlName="name"
                   (ngModelChange)="removeFieldError(LegacyProcess.ValidatedField.NAME)"
                   trim="blur" validateOnBlur [validateFormControl]="formGroup.controls['name']">
            <div class="form-control-feedback" *ngIf="hasFieldError(LegacyProcess.ValidatedField.NAME)">
              {{getFieldErrorText(LegacyProcess.ValidatedField.NAME)}}
            </div>
          </div>
          <!-- /.col-md-8 -->
        </div>

        <div class="form-group row"
             [ngClass]="{ 'has-danger': hasFieldError(LegacyProcess.ValidatedField.EXTERNAL_ID) }">
          <label class="col-form-label col-md-4">{{'PROCESS_CREATE_EXTERNAL_ID' | translate}}</label>
          <div class="col-md-8">
            <input type="text" class="form-control" placeholder="{{'PROCESS_CREATE_EXTERNAL_ID' | translate}}"
                   [ngClass]="{ 'form-control-danger': hasFieldError(LegacyProcess.ValidatedField.EXTERNAL_ID) }"
                   [(ngModel)]="processCreateModel.externalId" formControlName="externalId"
                   (ngModelChange)="removeFieldError(LegacyProcess.ValidatedField.EXTERNAL_ID)"
                   trim="blur" validateOnBlur [validateFormControl]="formGroup.controls['externalId']">
            <div class="form-control-feedback" *ngIf="hasFieldError(LegacyProcess.ValidatedField.EXTERNAL_ID)">
              {{getFieldErrorText(LegacyProcess.ValidatedField.EXTERNAL_ID)}}
            </div>
          </div>
          <!-- /.col-md-8 -->
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-4">{{'COMMON_DESCRIPTION' | translate}}</label>
          <div class="col-md-8">
            <input class="form-control" type="text" placeholder="{{'COMMON_DESCRIPTION' | translate}}"
                   name="description"
                   [ngModelOptions]="{standalone: true}"
                   id="description" #description="ngModel" [(ngModel)]="processCreateModel.description">
          </div>
          <!-- /.col-md-8 -->
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-4">{{'PROCESS_CREATE_DEADLINE' | translate}}</label>
          <div class="input-group col-md-8">
            <input type="text" class="form-control date"
                   placeholder="{{'PROCESS_CREATE_DEADLINE' | translate}}"
                   [(ngModel)]="processCreateModel.deadline" ngbDatepicker name="deadline" id="deadline"
                   [ngModelOptions]="{standalone: true}" #deadline="ngbDatepicker">
            <div class="input-group-addon-gray" (click)="deadline.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="closeProcessCreateDialog()">
            {{'COMMON_BUTTON_CANCEL' | translate}}
          </button>
          <button type="submit" class="btn btn-primary">{{'COMMON_BUTTON_SAVE' |
              translate}}
          </button>
        </div>
      </form>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
