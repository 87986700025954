import { SiteTourBaseComponent, SiteTourFactory, SiteTourId } from '../site-tour.factory';
import { TourStep } from 'ngx-guided-tour';
import { SiteTourStringKeys } from '../site-tour-string-keys';

export class AdminTourFactory extends SiteTourFactory {

  protected getTourSteps(baseComponent?: SiteTourBaseComponent): TourStep[] {
    return [
      {
        selector: '#admin-dashboard-content',
        title: SiteTourStringKeys.SITE_TOUR_ADMIN_TOUR_CONTENT_STEP_TITLE,
        content: SiteTourStringKeys.SITE_TOUR_ADMIN_TOUR_CONTENT_STEP_CONTENT,
        orientation: 'center',
        action: () => {
        },
        closeAction: () => {
        },
        skipStep: false,
      },
      {
        selector: '#admin-dashboard-card-user-management',
        title: SiteTourStringKeys.SITE_TOUR_ADMIN_TOUR_USER_CARD_STEP_TITLE,
        content: SiteTourStringKeys.SITE_TOUR_ADMIN_TOUR_USER_CARD_STEP_CONTENT,
        orientation: 'left',
        action: () => {
        },
        closeAction: () => {
        },
        skipStep: false,
      },
    ];
  }

  protected get tourKey(): SiteTourId {
    return SiteTourId.ADMIN_DASHBOARD;
  }

}
