/* eslint-disable */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  TableDocumentSchema,
  TableDocumentSchemaImportExport,
  TableDocumentSchemaService,
} from '../../../lib/table-document-schema/table-document-schema.service';
import { Set } from 'immutable';
import { OrderType, QueryResult } from '../../../lib/util/services';
import { OffsetDateTime } from '../../../lib/util/dates';
import { OptionItem, QueryFieldModel, SelectUtils, UiConstants } from '../../../util/core-utils';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { StateName } from '../../../app.state-names';
import { TranslateService } from '@ngx-translate/core';
import { RightModel } from '../../../app.rights';
import { RightResolver, RightService } from '../../../lib/right.service';
import { EmptyMessage } from '../../../lib/util/messages';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FileItem, FileUploader, ParsedResponseHeaders } from 'ng2-file-upload';
import { combineLatest, Observable, Subject } from 'rxjs';
import { FileUploaderUtil } from '../../../util/file-uploader-util';
import {
  TableDocumentSchemaSearch,
  TableDocumentSchemaSearchService, TableDocumentSchemaStateItem
} from '../../../lib/table-document-schema/table-document-schema-search-service';
import { Transition } from '@uirouter/angular';
import { Strings } from '../../../lib/util/strings';
import { InputMask } from '../../../util/input-masks';
import { Arrays } from '../../../lib/util/arrays';
import { ErrorDetail, ErrorMessageService } from '../../../lib/error-message-parser.service';
import { ToasterService } from '../../../fork/angular2-toaster/angular2-toaster';
import { saveAs } from 'file-saver';
import TableDocumentSchemaVersionState = TableDocumentSchema.TableDocumentSchemaVersionState;
import { FileUploadComponent } from '../../../shared/file-upload/flat/file-upload.component';

/* eslint-enable */

@Component({
  selector: 'app-table-document-schema-list',
  templateUrl: './table-document-schema-list.component.html',
  styleUrls: ['./table-document-schema-list.component.scss']
})
export class TableDocumentSchemaListComponent implements OnInit, OnDestroy {

  TableDocumentSchema = TableDocumentSchema;
  UiConstants = UiConstants;
  InputMask = InputMask;
  SelectUtils = SelectUtils;

  @ViewChild('importDialog', { static: true }) importDialog: ModalDirective;
  importDialogVisible: boolean = false;
  importDnDVisible: boolean = true;

  @ViewChild('fileUploadComponent', {static: false})
  fileUploadComponent: FileUploadComponent;
  uploadGlobalErrors: string;

  tableDocumentSchemaImportModel: TableDocumentSchemaImportModel = new TableDocumentSchemaImportModel();

  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  queryModel: QueryFieldModel<TableDocumentSchema.OrderField> = new QueryFieldModel(TableDocumentSchema.OrderField.ID, OrderType.DESC);
  rightModel: RightModel = RightModel.empty();

  tableDocumentSchemaList: TableDocumentSchemaModel[] = [];

  searchResult: TableDocumentSchemaSearch.SearchDataResult;
  searchModel: TableDocumentSchemaSearchModel = new TableDocumentSchemaSearchModel();
  showSearch: boolean = false;

  @ViewChild('deleteModal', { static: true }) deleteModal: ModalDirective;
  deleteModalVisible: boolean = false;
  selectedDeleteItem: TableDocumentSchemaModel;

  schemaVersionStateForSearchItems: TableDocumentSchemaStateItem[] = [];

  constructor(
    private translateService: TranslateService,
    private rightService: RightService,
    private tableDocumentSchemaService: TableDocumentSchemaService,
    private uploadUtil: FileUploaderUtil,
    private tableDocumentSchemaSearchService: TableDocumentSchemaSearchService,
    private transition: Transition,
    private errorMessageService: ErrorMessageService,
    private toasterService: ToasterService
  ) {}

  ngOnInit() {
    this.loadRightModels();
    this.initBreadcrumb();
    this.loadSearch(() => {
      this.showSearch = !this.searchModel.isEmpty();
      this.initSchemaVersionStateForSearchOptions();
      this.loadList()
    })
  }

  ngOnDestroy() {
    this.saveSearch();
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  initBreadcrumb() {
    this.translateService.get('MENU_NAVBAR_TABLE_DOCUMENT_SCHEMA').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
  }

  private loadSearch(completion: () => void) {
    const obs: Observable<SearchLoadResult> = combineLatest(
      this.tableDocumentSchemaSearchService.getSearchData({}),
      (storedSearchData: TableDocumentSchemaSearch.SearchDataResult) => {
        const result: SearchLoadResult = {
          storedSearchData: storedSearchData
        };
        return result;
      }
    );
    obs.subscribe(
      (result: SearchLoadResult) => {
        this.searchResult = result.storedSearchData;
        this.postInitSearch(result.storedSearchData, completion);
      }
    );
  }

  private postInitSearch(storedSearchData: TableDocumentSchemaSearch.SearchDataResult, completion: () => void) {
    this.queryModel.itemsPerPage = storedSearchData.searchData.itemsPerPage;
    this.queryModel.currentPage = storedSearchData.searchData.pageNumber;
    this.queryModel.setOrder(storedSearchData.searchData.order);
    this.searchModel.name = storedSearchData.searchData.name;
    this.searchModel.code = storedSearchData.searchData.code;
    this.searchModel.schemaVersion = storedSearchData.searchData.schemaVersion;
    this.searchModel.versionState = storedSearchData.searchData.versionState;
    completion()
  }

  private saveSearch() {
    const request = {
        searchData: {
          itemsPerPage: this.queryModel.itemsPerPage,
          pageNumber: this.queryModel.currentPage,
          order: this.queryModel.getOrder(),
          name: this.searchModel.name,
          code: this.searchModel.code,
          schemaVersion: this.searchModel.schemaVersion,
          versionState: this.searchModel.versionState
        }
      }
    ;
    this.tableDocumentSchemaSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }


  loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();
    this.tableDocumentSchemaService.query({
      name: Strings.undefinedOrNonEmpty(this.searchModel.name),
      code: Strings.undefinedOrNonEmpty(this.searchModel.code),
      version: Strings.undefinedOrNonEmpty(this.searchModel.schemaVersion),
      versionState: this.searchModel.versionState ? this.getVersionState() : undefined,
      orders: Set.of(order),
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined
    }).subscribe((result: QueryResult<TableDocumentSchema.TableDocumentSchema>) => {
      this.tableDocumentSchemaList = [];
      result.items.forEach((tableDocumentSchema: TableDocumentSchema.TableDocumentSchema) => {
        const tableDocumentSchemaModel = new TableDocumentSchemaModel();
        tableDocumentSchemaModel.schemaId = tableDocumentSchema.schemaId;
        tableDocumentSchemaModel.creationTime = tableDocumentSchema.creationTime;
        tableDocumentSchemaModel.version = tableDocumentSchema.version;
        tableDocumentSchemaModel.versionState = tableDocumentSchema.versionState;
        tableDocumentSchemaModel.code = tableDocumentSchema.code;
        tableDocumentSchemaModel.name = tableDocumentSchema.name;
        tableDocumentSchemaModel.note = tableDocumentSchema.note;
        this.tableDocumentSchemaList.push(tableDocumentSchemaModel);
      });
      this.queryModel.currentPage = requestedPage;
      this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
    });
  }

  getVersionState(): TableDocumentSchemaVersionState | undefined {
    if (this.searchModel.versionState!.id === 'DRAFT') {
      return 'DRAFT';
    }
    else if (this.searchModel.versionState!.id === 'FINALIZED') {
      return 'FINALIZED'
    }
    else if (this.searchModel.versionState!.id === 'DEPRECATED') {
      return 'DEPRECATED'
    }
    return undefined
  }

  orderBy(field: TableDocumentSchema.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  showSchemaDelete(model: TableDocumentSchemaModel): boolean {
    if (model.versionState === 'DRAFT' ||
      model.versionState === 'DEPRECATED') {
      // TODO: and if haven't documentum
      return true
    }
    return false
  }

  showSchemaEdit(model: TableDocumentSchemaModel): boolean {
    if (model.versionState === 'DRAFT') {
      return true
    }
    return false
  }

  openImportDialog() {
    this.importDialogVisible = true;
    this.importDnDVisible = true;
    this.importDialog.show();
    this.tableDocumentSchemaImportModel = new TableDocumentSchemaImportModel();
  }

  closeImportDialog() {
    this.importDialogVisible = false;
    this.importDialog.hide();
    this.fileUploadComponent.uploader.clearQueue();
    this.fileUploadComponent.uploader.cancelAll();
  }

  onImportableSurveySelected(files: File[]) {
    this.tableDocumentSchemaImportModel.importResult = undefined;
    const file = files[0];
    this.tableDocumentSchemaImportModel.fileName = file.name;
    const fr = new FileReader();
    const subject: Subject<any> = new Subject<any>();
    fr.onloadend = (function (f) {
      return function (e) {
        try {
          const res = JSON.parse(this.result);
          subject.next(res);
        }
        catch (e) {
          subject.error('');
        }
      };
    })(file);
    fr.readAsText(file);
    subject.asObservable().subscribe((survey: any) => {
      if (survey) {
        this.tableDocumentSchemaImportModel.importableSurveys = survey;
        this.tableDocumentSchemaImportModel.importResult = undefined;
        this.importDnDVisible = false;
      }
      else {
        this.tableDocumentSchemaImportModel.importResult = 'FORMAT_ERROR';
        this.importDnDVisible = true;
      }
    });
  }

  importSchema() {
    this.tableDocumentSchemaService.importSchema({
      descriptor: this.tableDocumentSchemaImportModel.importableSurveys['descriptor'],
      version: this.tableDocumentSchemaImportModel.importableSurveys['version']
    })
      .subscribe(r => {
        this.tableDocumentSchemaImportModel.importResult = 'SUCCESS';
        this.closeImportDialog();
        this.loadList();
      }, error => {
        this.tableDocumentSchemaImportModel.importResult = 'ERROR';
        this.importDnDVisible = true;
        const localizedError = this.errorMessageService.parseError(error);
        this.uploadGlobalErrors = localizedError!.globalErrors.join(',');
      });
  }

  openDeleteModal(model: TableDocumentSchemaModel) {
    this.selectedDeleteItem = model;
    this.deleteModalVisible = true;
    this.deleteModal.show();
  }

  closeDeleteModal() {
    this.deleteModalVisible = false;
    this.deleteModal.hide();
  }

  deleteSchema() {
    this.deleteModalVisible = false;
    this.deleteModal.hide();
    const model = this.selectedDeleteItem;
    this.tableDocumentSchemaService.delete(
      {
        schemaId: model.schemaId
      }).subscribe(
      (result: EmptyMessage) => {
      },
      (error: Error) => {
      },
      () => {
        this.loadList()
      });
  }

  onSearchReset() {
    this.tableDocumentSchemaSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.showSearch = true;
          this.loadList(1);
        });
      }
    );
  }

  onSearchClicked() {
    this.loadList(1);
  }

  private initSchemaVersionStateForSearchOptions() {
    const defaultItem = {
      id: null,
      text: this.translateService.instant('COMMON_VALUE_UNSELECTED'),
      key: '',
    };
    this.schemaVersionStateForSearchItems = [];
    this.schemaVersionStateForSearchItems.push(defaultItem);
    if (!this.searchModel.versionState) {
      this.searchModel.versionState = defaultItem;
    }
    TableDocumentSchema.tableDocumentSchemaVersionStates.forEach((state) => {
      this.translateService.get(state.stringKey).subscribe((text) => {
        this.schemaVersionStateForSearchItems.push({id: state.state, text: text, key: state.stringKey});
      });
    });
  }

  exportSchema(model: TableDocumentSchemaModel) {

    this.tableDocumentSchemaService.exportSchema({
      schemaId: model.schemaId!
    }).subscribe((result: TableDocumentSchemaImportExport.ImportDocument) => {
      const blob = new Blob([JSON.stringify(result)], {type: 'text/json;charset=utf-8'});
      saveAs(blob, 'document_schema_' + model.name + '(' + model.version + ').json');
    });
  }

}

class TableDocumentSchemaModel {
  schemaId: number;
  creationTime?: OffsetDateTime;
  version?: number;
  versionState: TableDocumentSchema.TableDocumentSchemaVersionState;
  code: string = '';
  name: string = '';
  note: string = '';
}

class TableDocumentSchemaSearchModel {

  name: string;
  code: string;
  schemaVersion: string;
  versionState?: TableDocumentSchemaStateItem;

  public isEmpty(): boolean {

    return this.name.length === 0
      && this.code.length === 0
      && this.schemaVersion.length === 0
      && OptionItem.idOrUndefined(this.versionState) === undefined
  }

  public clear() {
    this.name = '';
    this.code = '';
    this.schemaVersion = '';
    this.versionState = undefined;

  }

  constructor() {
  }

}

export class TableDocumentSchemaImportModel {
  fileName: string = '';
  importableSurveys: string[] = [];
  importResult?: string = undefined;
}

interface SearchLoadResult {
  storedSearchData: TableDocumentSchemaSearch.SearchDataResult,
}


