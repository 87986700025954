/* eslint-disable */
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UIRouter } from '@uirouter/angular';
import { MessageCreateModel, RecipientsCreateModel } from '../../../util/message/message-utils';
import { FieldError, FieldErrors } from '../../../lib/util/errors';
import { MultiselectOptionItem, UiConstants } from '../../../util/core-utils';
import { UserGroup, UserGroupService } from '../../../lib/user-group.service';
import { Arrays } from '../../../lib/util/arrays';
import { Device, DeviceManagement, DeviceManagementService } from '../../../lib/device-management.service';
import { StringKey } from '../../../app.string-keys';
import { TranslateService } from '@ngx-translate/core';
import { Angular2Multiselects } from '../../../util/multiselect';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { StateName } from '../../../app.state-names';
import { MessageErrorMap, MessagesService } from '../../../lib/message-parent/messages.service';
import { OrderType, ResourceQueryResult, Services } from '../../../lib/util/services';
import { Set } from 'immutable';
import { User, UserService } from '../../../lib/user.service';
import { Strings } from '../../../lib/util/strings';
import { UserGroupItemForDropdown } from '../../../util/user-utils';
/* eslint-enable */

@Component({
  selector: 'app-notification-create',
  templateUrl: 'notification-create.component.html',
  styleUrls: ['notification-create.component.css']
})
export class NotificationCreateComponent implements OnInit, AfterViewInit {

  UiConstants = UiConstants;

  userList: MultiselectOptionItem<number>[] = [];
  selectedUsers: MultiselectOptionItem<number>[] = [];

  userGroupList: UserGroupItemForDropdown[] = [];
  selectedUserGroups: UserGroupItemForDropdown[] = [];

  model: MessageCreateModel;
  fieldErrors: MessageErrorMap;

  showRecipientFilter: boolean = true;
  showSendAllSwitch: boolean = true;

  deviceList: MultiselectOptionItem<number>[] = [];
  selectedDevices: MultiselectOptionItem<number>[] = [];

  userDropdownSettings: Angular2Multiselects.Settings;
  userGroupDropdownSettings: Angular2Multiselects.Settings;
  deviceDropdownSettings: Angular2Multiselects.Settings;
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  sendToAllSwitchChange(change) {
    this.showRecipientFilter = !change.target.checked;
  }

  removeFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.fieldErrors, fieldError);
  }

  ngOnInit() {
    this.translateService.get('NOTIFICATION_PANEL_HEADING_CREATE').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('COMMON_NOTIFICATIONS').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.NOTIFICATION_LIST});
      }
    );
  }

  ngAfterViewInit(): void {
    this.loadUserGroups();
    this.loadUsers();
    this.loadDevices();
  }

  private loadUsers(q?: string) {
    this.userService.query({
      q: q ? Strings.undefinedOrNonEmpty(q) : undefined,
      disabled: false,
      order: Services.createOrderFieldParameter(User.Keys.toOrderFieldKey,
        Set.of({field: User.OrderField.PERSON_NAME, type: OrderType.ASC})),
      page_number: 1,
      number_of_items: UiConstants.autocompletePageSize,
      no_progress_bar: true
    }).subscribe(
        (users: ResourceQueryResult<User>) => {
          this.userList = [];
          users.items.forEach((user) => {
            const item = new MultiselectOptionItem<number>();
            item.id = user.id;
            item.itemName = user.person_name + ' (' + user.user_name + ')';
            this.userList.push(item);
          });
          this.initDropDown();
        }
      );
  }

  private loadUserGroups(q?: string) {
    this.userGroupService.query( {
      name: q ? Strings.undefinedOrNonEmpty(q) : undefined,
      disabled: false,
      order: Services.createOrderFieldParameter(UserGroup.Keys.toOrderFieldKey, Set.of(UserGroup.DEFAULT_ORDER)),
      page_number: 1,
      number_of_items: UiConstants.autocompletePageSize,
      no_progress_bar: true
    }).subscribe(
      (result: ResourceQueryResult<UserGroup>) => {
        this.userGroupList = [];

        Arrays.iterateByIndex(result.items, (userGroup) => {
          const item = new UserGroupItemForDropdown();
          item.id = userGroup.id;
          item.itemName = userGroup.name;
          this.userGroupList.push(item);
        });
      },
      (error: any) => {
        // Ignored. The interceptor handles the global errors.
      }
    );
  }

  private loadDevices(q?: string) {
    this.deviceManagementService.query({
      name: q ? Strings.undefinedOrNonEmpty(q) : undefined,
      disabled: false,
      order: Services.createOrderFieldParameter(DeviceManagement.Keys.toOrderFieldKey,
        Set.of({field: DeviceManagement.OrderField.NAME, type: OrderType.ASC})),
      page_number: 1,
      number_of_items: UiConstants.autocompletePageSize,
      no_progress_bar: true
    }).subscribe(
      (result: ResourceQueryResult<Device>) => {
        this.deviceList = [];

        Arrays.iterateByIndex(result.items, (device) => {
          const item = new MultiselectOptionItem<number>();
          item.id = device.id;
          item.itemName = device.name;
          if (device.name === undefined) {
            item.itemName = device.device_id;
          }
          this.deviceList.push(item);
        });
      }
    );
  }


  back() {
    window.history.back();
  }

  private validateRecipients(recipients: RecipientsCreateModel) {
    if (!this.showRecipientFilter) {
      this.removeFieldError(this.fieldErrors.recipient);
      return;
    }

    // Refresh recipients
    if (this.selectedUsers.length > 0) {
      for (const user of this.selectedUsers) {
        if (user.id !== null) {
          recipients.user_ids.push(user.id);
        }
      }
    }

    if (this.selectedUserGroups.length > 0) {
      for (const userGroup of this.selectedUserGroups) {
        if (userGroup.id !== null) {
          recipients.user_group_ids.push(userGroup.id);
        }
      }
    }

    if (this.selectedDevices.length > 0) {
      for (const device of this.selectedDevices) {
        if (device.id !== null) {
          recipients.mobile_app_ids.push(device.id);
        }
      }
    }

    // Validate
    if (
      recipients.user_group_ids.length < 1 &&
      recipients.user_ids.length < 1 &&
      recipients.mobile_app_ids.length < 1
    ) {
      this.fieldErrors.recipient = {
        text: ''
      };
    }
    else {
      this.removeFieldError(this.fieldErrors.recipient);
    }
  }

  private validateTitle() {
    if (!this.model.title || this.model.title.length < 1) {
      this.fieldErrors.title = {
        text: ''
      }
    }
  }

  private validateContent() {
    if (!this.model.content || this.model.content.length < 1) {
      this.fieldErrors.message = {
        text: ''
      }
    }
  }

  create() {
    const recipients: RecipientsCreateModel = new RecipientsCreateModel();


    this.validateRecipients(recipients);
    this.validateTitle();
    this.validateContent();
    if (this.fieldErrors.title || this.fieldErrors.message ||
      this.fieldErrors.recipient || this.fieldErrors.title || this.fieldErrors.message) {
      return;
    }

    const send_to_all_user = !this.showRecipientFilter;

    this.messageService.create({
      title: this.model.title,
      content: this.model.content,
      recipients: recipients,
      send_to_all: send_to_all_user,
    }).subscribe(response => {
      this.uiRouter.stateService.go(StateName.NOTIFICATION_LIST);
    });
  }

  refreshUserGroups(value: UserGroupItemForDropdown[]): void {
    this.selectedUserGroups = value;
  }

  refreshUserList(value: MultiselectOptionItem<number>[]): void {
    this.selectedUsers = value;
  }

  refreshDevices(value: MultiselectOptionItem<number>[]): void {
    this.selectedDevices = value;
  }

  private initDropDown() {
      this.userDropdownSettings = new Angular2Multiselects.SettingsBuilder()
        .text(StringKey.MESSAGE_LABEL_USER)
        .singleSelection(false)
        .enableSearchFilter(true)
        .remoteSearch(true)
        .enableCheckAll(true)
        .build();
      this.userGroupDropdownSettings = new Angular2Multiselects.SettingsBuilder()
        .text(StringKey.MESSAGE_LABEL_GROUP)
        .singleSelection(false)
        .enableSearchFilter(true)
        .remoteSearch(true)
        .enableCheckAll(true)
        .build();
      this.deviceDropdownSettings = new Angular2Multiselects.SettingsBuilder()
        .text(StringKey.MESSAGE_LABEL_DEVICE)
        .singleSelection(false)
        .enableSearchFilter(true)
        .remoteSearch(true)
        .enableCheckAll(true)
        .build();
  }

  constructor(private deviceManagementService: DeviceManagementService,
              private messageService: MessagesService,
              private uiRouter: UIRouter,
              private userGroupService: UserGroupService,
              private userService: UserService,
              private translateService: TranslateService) {
    this.fieldErrors = {};
    this.model = new MessageCreateModel();
  }
}
