<!--gallery container-->
<div class="gallery-container">
  <!--wrapper class for image boxes-->
  <div class="wrap">
    <!--Print all boxes-->
    <div class="box" *ngFor="let image of attachments">
      <!--inner div to hold content-->
      <div class="boxInner">
        <!--inner content container-->
        <div class="content-container">

          <div *ngIf="displayAsImage(image)"
               class="attachment-image-container"
               [ngStyle]="{
              'cursor': 'pointer',
              'background-image': 'url(\'' + getPreviewDataUrl(image) + '\')'
            }"
               (click)="onImageClick(image)">
          </div>

          <div *ngIf="displayAsPlaceholder(image)"
               class="attachment-image-container"
               [ngStyle]="{
                 'background-size': 'contain',
                 'background-color': '#E3E8EC',
                 'background-image': 'url(\'' + image.placeholderImageSrc + '\')'
               }">
          </div>

          <div class="wrapper-title-download">
            <div class="attachment-title-label" popover="{{image.attachment.fullFileName}}"
                 triggers="mouseenter:mouseleave" container="body">
              {{image.attachment.fullFileName}}
            </div>
            <div class="d-flex">
              <div class="attachment-button" *ngIf="!!deleteAllowed && hasDeleteRight(image)">
                <a (click)="openConfirmDeleteDialog(image)"
                   title="{{'COMMON_BUTTON_DELETE' | translate}}">
                  <i class="icon-standard icomoon icomoon-trash cursor-pointer"></i>
                </a>
              </div>
              <div class="attachment-button">
                <a *ngIf="image.downloading">
                  <i class="icon-standard icomoon icomoon-order-state-under-processing cursor-pointer"></i>
                </a>
                <a *ngIf="image.downloaded" (click)="downloadFile(image)"
                   title="{{'COMMON_BUTTON_DOWNLOAD' | translate}}">
                  <i class="icon-standard icomoon icomoon-import cursor-pointer" style="cursor: pointer"></i>
                </a>
                <a *ngIf="image.downloadError" (click)="retryDownloadFile(image)">
                  <i class="icon-standard icomoon icomoon-process-reopen cursor-pointer" style="cursor: pointer"></i>
                </a>
                <a *ngIf="image.downloadNotStarted" (click)="downloadFileThenSave(image)"
                   title="{{'COMMON_BUTTON_DOWNLOAD' | translate}}">
                  <i class="icon-standard icomoon icomoon-import cursor-pointer" style="cursor: pointer"></i>
                </a>
              </div>
            </div>
          </div>

        </div>
        <!--inner content container-->
      </div>
      <!--inner div to hold content end-->
    </div>
    <!--Print all boxes end-->
  </div>
  <!--wrapper class for image boxes end-->
</div>
<!--gallery container end-->
