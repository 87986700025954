import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {TaskTriggerFields, TriggerUtils} from '../../../lib/trigger/trigger-utils';
import {Trigger} from '../../../lib/trigger/trigger.service';
import {
  MultiselectOptionItem,
  OptionItem,
  OptionItems,
  OwnerUserItem,
  OwnerUserItemFactory,
  UiConstants,
} from '../../../util/core-utils';
import {EmailTemplateService} from '../../../lib/email-template/email-template.service';
import {DocumentFileService} from '../../../lib/document/document-file.service';
import {DocumentGroupService} from '../../../lib/document/document-group.service';
import {User, UserService} from '../../../lib/user.service';
import {UserGroup, UserGroupService} from '../../../lib/user-group.service';
import {Device, DeviceManagementService} from '../../../lib/device-management.service';
import {List, Map as ImmutableMap, Set} from 'immutable';
import {GeneralPdfTemplateService} from '../../../lib/general-pdf-template/general-pdf-template.service';
import {GeneralPrinterTemplateService} from '../../../lib/general-printer-template/general-printer-template.service';
import {MessageTemplateService} from '../../../lib/message-template/message-template.service';
import {ResourceQueryResult, Services} from '../../../lib/util/services';
import {Arrays} from '../../../lib/util/arrays';
import {Angular2Multiselects} from '../../../util/multiselect';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {forkJoin, Observable} from 'rxjs';
import {EmptyMessage} from '../../../lib/util/messages';
import {FieldError, FieldErrors, ObservableErrorResourceParser} from '../../../lib/util/errors';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
import {EmailAddresses} from '../../../lib/util/email-address';
import {StringKey} from '../../../app.string-keys';
import {ToasterService} from '../../../fork/angular2-toaster/src/toaster.service';
import {AppValidators} from '../../../util/app-validators';
import {TransportDocument} from '../../../lib/transport/transport-document/transport-document.service';
import {Form} from '../../../lib/form/form.service';
import {MultiselectForm} from '../../../util/multiselect-form';
import {Multiselect} from '../../../util/multiselect-helper';
import {Strings} from '../../../lib/util/strings';
import {RightResolver, RightService} from '../../../lib/right.service';
import {RightModel} from '../../../app.rights';
import {EmailAddressTypeService} from '../../../lib/email-address-type/email-address-type.service';
import {
  WorkflowTransitionFormFieldRuleModel,
  WorkflowTransitionRuleModel,
  WorkflowTransitionTaskRuleModel
} from '../../workflow/workflow-create-editor/workflow-create-editor-data-edit/workflow-create-editor-data-edit.component';
import {TaskRecord} from '../../../lib/task/task-record.service';
import {FieldDataTypeSelectors} from '../../../util/form/form-utils';
import {AngularMultiSelect} from '../../../fork/angular2-multiselect-dropdown/src/lib';
import {TaskMultiselectOptionItem, TaskMultiselectProvider} from '../../../lib/task/task-multiselect.provider';
import TriggerService = Trigger.TriggerService;
import RelatedPersonType = TriggerUtils.RelatedPersonType;
import TransportDocumentType = TransportDocument.TransportDocumentType;
import availableRelatedPersonTypes = TriggerUtils.availableRelatedPersonTypes;
import NotificationEvent = TriggerUtils.NotificationEvent;
import shipmentRelatedNotificationEvents = TriggerUtils.shipmentRelatedNotificationEvents;
import TaskRecordBulkCloneField = TaskRecord.TaskRecordBulkCloneField;

// I am really happy that this dialog contains the implementation of
// each trigger type using SCOP (switch-case oriented programming) 😭

@Component({
  selector: 'app-trigger-edit-dialog',
  templateUrl: './trigger-edit-dialog.component.html',
  styleUrls: ['./trigger-edit-dialog.component.scss']
})
export class TriggerEditDialogComponent implements OnInit {

  UiConstants = UiConstants;
  TaskTriggerFields = TaskTriggerFields;

  @Input()
  triggerService: TriggerService;

  @Input()
  triggerParentId: number;

  @Input()
  scope: TriggerUtils.TriggerScope;

  @Output()
  onTriggerCreated: EventEmitter<any> = new EventEmitter<any>();

  headingDictionaryKey = '';

  triggerType?: TriggerUtils.TriggerType;
  triggerId: number | undefined;
  clone: boolean = false;

  @ViewChild('triggerEditDialog', {static: true})
  triggerEditDialog: ModalDirective;
  triggerEditDialogVisible: boolean = false;

  @ViewChild('cloneFormFieldsInput', {static: false})
  cloneFormFieldsInput: AngularMultiSelect;

  pdfTriggerEditModel: PdfTriggerEditModel = new PdfTriggerEditModel();
  receiptTriggerEditModel: ReceiptTriggerEditModel = new ReceiptTriggerEditModel();
  emailTriggerEditModel: EmailTriggerEditModel = new EmailTriggerEditModel();
  messageTriggerEditModel: MessageTriggerEditModel = new MessageTriggerEditModel();
  cloneTriggerEditModel: CloneTriggerEditModel = new CloneTriggerEditModel();

  // templates
  receiptTemplates: OptionItem<number>[] = [];
  pdfTemplates: OptionItem<number>[] = [];
  emailTemplates: OptionItem<number>[] = [];
  messageTemplates: OptionItem<number>[] = [];

  pdfTriggers: OptionItem<number>[] = [];
  fileDocuments: OptionItem<number>[] = [];
  documentGroups: OptionItem<number>[] = [];
  users: OptionItem<number>[] = [];
  userGroups: OptionItem<number>[] = [];
  mobileApplications: OptionItem<number>[] = [];
  events: OptionItem<TriggerUtils.NotificationEvent>[] = [];
  relatedPersonTypes: OptionItem<TriggerUtils.RelatedPersonType>[] = [];
  emailFormFields: MultiselectForm.ImmutableFormFieldItem[] = [];
  documentFormFields: MultiselectForm.ImmutableFormFieldItem[] = [];
  emailUserFormFields: MultiselectForm.ImmutableFormFieldItem[] = [];
  messageUserFormFields: MultiselectForm.ImmutableFormFieldItem[] = [];
  cloneFormFields: MultiselectForm.ImmutableFormFieldItem[] = [];
  emailAddressTypes: OptionItem<number>[] = [];
  transportDocumentTypes: OptionItem<TransportDocumentType>[] = [];
  pdfTriggerFileNamePatternOptions: string[] = TaskTriggerFields.availableItems;
  bulkCloneFields: MultiselectOptionItem<TaskRecordBulkCloneField>[]
    = TaskRecord.bulkCloneFields.map(f => ({id: f.type, itemName: f.stringKey}));

  singleRemoteDropdownSettings: Angular2Multiselects.Settings;
  singleRemoteItemNameDropdownSettings: Angular2Multiselects.Settings;
  singleLocalDropdownSettings: Angular2Multiselects.Settings;
  multiRemoteDropdownSettings: Angular2Multiselects.Settings;
  multiLocalDropdownSettings: Angular2Multiselects.Settings;
  bulkFieldsDropdownSettings: Angular2Multiselects.Settings;

  pdfFormGroup: FormGroup;
  emailFormGroup: FormGroup;
  messageFormGroup: FormGroup;
  receiptFormGroup: FormGroup;
  cloneFormGroup: FormGroup;

  triggerFieldErrors: TriggerFieldErrorMap;

  // Material tag input variables
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  rightModel: RightModel = RightModel.empty();

  _form?: Form.Form;
  tasks: TaskMultiselectOptionItem[] = [];

  @Input()
  set form(form: Form.Form) {
    this._form = form;
    this.loadEmailFormFields();
    this.loadDocumentFormFields();
    this.loadUserFormFields();
    this.loadCloneFormFields();
    this.loadPdfTriggerFileNamePatternFormFieldOptions();
    this.loadCustomerFormFields();
  }

  get model(): TriggerEditModel {
    switch (this.triggerType) {
      case 'PDF':
        return this.pdfTriggerEditModel;
      case 'RECEIPT':
        return this.receiptTriggerEditModel;
      case 'MESSAGE':
        return this.messageTriggerEditModel;
      case 'EMAIL':
        return this.emailTriggerEditModel;
      default:
        return this.cloneTriggerEditModel;
    }
  }

  constructor(private emailTemplateService: EmailTemplateService,
              private fileDocumentService: DocumentFileService,
              private documentGroupService: DocumentGroupService,
              private userService: UserService,
              private userGroupService: UserGroupService,
              private taskMultiselectProvider: TaskMultiselectProvider,
              private deviceService: DeviceManagementService,
              private generalPdfTemplateService: GeneralPdfTemplateService,
              private generalPrinterTemplateService: GeneralPrinterTemplateService,
              private messageTemplateService: MessageTemplateService,
              private ownerUserItemFactory: OwnerUserItemFactory,
              private formBuilder: FormBuilder,
              private translateService: TranslateService,
              private toasterService: ToasterService,
              private rightService: RightService,
              private emailAddressTypeService: EmailAddressTypeService,
  ) {
  }

  ngOnInit() {
    this.initDropdownSettings();
    this.loadRightModels();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  loadNotificationEvents() {
    this.events = [];
    TriggerUtils.notificationEvents.forEach(event => {
      if (event.scope && event.scope === this.scope
        && (!event.supportedTriggerTypes || event.supportedTriggerTypes && event.supportedTriggerTypes.has(this.triggerType!))) {
        const item = new OptionItem<TriggerUtils.NotificationEvent>();
        item.id = event.notificationEvent;
        this.translateService.get(event.stringKey).subscribe(text => {
          item.text = text;
          this.events.push(item);
          this.events.sort((a, b) => a.text.localeCompare(b.text))
        });
      }
    });
  }

  loadTransportDocumentTypes() {
    this.transportDocumentTypes = [];
    const transportDocumentTypes: OptionItem<TransportDocumentType>[] = [];
    transportDocumentTypes.push(...this.emailTriggerEditModel.transportDocumentTypes);
    this.emailTriggerEditModel.transportDocumentTypes = [];
    TransportDocument.transportDocumentTypes.forEach(transportDocumentType => {
      const item = new OptionItem<TransportDocumentType>();
      item.id = transportDocumentType.type;
      this.translateService.get(transportDocumentType.stringKey).subscribe(text => {
        item.text = text;
        this.transportDocumentTypes.push(item);
        if (transportDocumentTypes.find((type) => type.id === item.id)) {
          this.emailTriggerEditModel.transportDocumentTypes.push(item);
        }
      });
    });
  }

  loadRelatedPersonTypes(event?: NotificationEvent) {
    this.relatedPersonTypes = [];
    const relatedPersonTypes: OptionItem<RelatedPersonType>[] = [];
    switch (this.triggerType) {
      case 'EMAIL':
        relatedPersonTypes.push(...this.emailTriggerEditModel.relatedPersonTypes);
        this.emailTriggerEditModel.relatedPersonTypes = [];
        break;
      case 'MESSAGE':
        relatedPersonTypes.push(...this.messageTriggerEditModel.relatedPersonTypes);
        this.messageTriggerEditModel.relatedPersonTypes = [];
        break;
      default:
        break;
    }
    TriggerUtils.relatedPersonTypes.forEach(relatedPersonType => {
      const personTypeAvailable = event ? availableRelatedPersonTypes.get(event).contains(relatedPersonType.type) : true;
      if (
        relatedPersonType.scope === this.scope
        && personTypeAvailable
        && this.triggerType
        && relatedPersonType.supportedTriggerTypes.contains(this.triggerType)
      ) {
        const item = new OptionItem<TriggerUtils.RelatedPersonType>();
        item.id = relatedPersonType.type;
        this.translateService.get(relatedPersonType.stringKey).subscribe(text => {
          item.text = text;
          this.relatedPersonTypes.push(item);
          if (relatedPersonTypes.find((pt) => pt.id === item.id)) {
            switch (this.triggerType) {
              case 'EMAIL':
                this.emailTriggerEditModel.relatedPersonTypes.push(item);
                break;
              case 'MESSAGE':
                this.messageTriggerEditModel.relatedPersonTypes.push(item);
                break;
              default:
                break;
            }
          }
        });
      }
    });
  }

  initDropdownSettings() {
    this.singleRemoteDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .labelKey(OptionItem.KEY_TEXT)
      .build();
    this.singleRemoteItemNameDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
    this.singleLocalDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .remoteSearch(false)
      .enableCheckAll(false)
      .labelKey(OptionItem.KEY_TEXT)
      .build();
    this.multiRemoteDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .remoteSearch(true)
      .enableCheckAll(true)
      .labelKey(OptionItem.KEY_TEXT)
      .build();
    this.multiLocalDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .remoteSearch(false)
      .enableCheckAll(true)
      .labelKey(OptionItem.KEY_TEXT)
      .build();
  }

  initTriggerEditDialog() {
    this.triggerFieldErrors = {};
    this.loadNotificationEvents();
    this.initTypeSpecificFields();
    this.loadTriggerIfPresent(() => {
      switch (this.triggerType) {
        case 'EMAIL':
          this.loadEmailTriggerEditDialog();
          break;
        case 'MESSAGE':
          this.loadMessageTriggerEditDialog();
          break;
        case 'PDF':
          this.loadPdfTriggerEditDialog();
          break;
        case 'RECEIPT':
          this.loadReceiptTriggerEditDialog();
          break;
        case 'CLONE':
          this.loadCloneTriggerEditDialog();
          break;
      }
    });
  }

  loadTriggerIfPresent(completion: () => void) {
    if (!this.triggerId) {
      this.emailTriggerEditModel = new EmailTriggerEditModel();
      this.messageTriggerEditModel = new MessageTriggerEditModel();
      this.receiptTriggerEditModel = new ReceiptTriggerEditModel();
      this.pdfTriggerEditModel = new PdfTriggerEditModel();
      this.cloneTriggerEditModel = new CloneTriggerEditModel();
      completion();
      return;
    }
    this.triggerService.getTrigger({
      id: this.triggerId,
      triggerParentId: this.triggerParentId
    }).subscribe(trigger => {
      switch (this.triggerType) {
        case 'EMAIL':
          this.loadEmailTriggerModel(trigger);
          break;
        case 'MESSAGE':
          this.loadMessageTriggerModel(trigger);
          break;
        case 'PDF':
          this.loadPdfTriggerModel(trigger);
          break;
        case 'RECEIPT':
          this.loadReceiptTriggerModel(trigger);
          break;
        case 'CLONE':
          this.loadCloneTriggerModel(trigger);
          break;
      }
      completion();
    });
  }

  loadPdfTriggerEditDialog() {
    this.loadPdfTemplates();
    this.loadPdfTriggerFileNamePatternFormFieldOptions();
  }

  loadEmailTriggerEditDialog() {
    this.loadEmailTemplates();
    this.loadFileDocuments();
    this.loadDocumentGroups();
    this.loadUsers();
    this.loadUserGroups();
    this.loadEmailFormFields();
    this.loadCustomerFormFields();
    this.loadDocumentFormFields();
    this.loadEmailAddressTypes();
    this.loadRelatedPersonTypes();
    switch (this.scope) {
      case 'TASK':
        this.loadPdfTriggers();
        break;
      case 'TRANSPORT':
        this.loadTransportDocumentTypes();
        break;
    }
  }

  loadMessageTriggerEditDialog() {
    this.loadMessageTemplates();
    this.loadUsers();
    this.loadUserGroups();
    this.loadMobileApplications();
    this.loadRelatedPersonTypes();
  }

  loadReceiptTriggerEditDialog() {
    this.loadReceiptTemplates();
  }

  loadCloneTriggerEditDialog() {
    this.bulkFieldsDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .remoteSearch(false)
      .enableCheckAll(true)
      .build();
    this.loadBulkCloneFields();
    this.loadCloneFormFields();
    if (!this.triggerId) {
      this.taskMultiselectProvider.getById(this.triggerParentId).subscribe(task => {
        this.cloneTriggerEditModel.destinationTask = [task];
      })
    }
  }

  private createPdfFormGroup() {
    this.pdfFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
      event: [[], Validators.required],
      pdfTemplate: [[], [Validators.required, AppValidators.validateEnabledItems]],
      fileNamePattern: ['', Validators.required],
    });
  }

  private createEmailFormGroup() {
    this.emailFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
      event: [[], Validators.required],
      emailTemplate: [[], [Validators.required, AppValidators.validateEnabledItems]],
      pdfTriggers: [[], AppValidators.validateEnabledItems],
      fileDocuments: [[], AppValidators.validateEnabledItems],
      documentGroups: [[], AppValidators.validateEnabledItems],
      users: [[], AppValidators.validateEnabledItems],
      userGroups: [[], AppValidators.validateEnabledItems],
      emailAddresses: [[]],
      relatedPersonTypes: [[], AppValidators.validateEnabledItems],
      emailFormFields: [[]],
      documentFormFields: [[]],
      userFormFields: [[]],
      emailAddressTypes: [[]],
      transportDocumentTypes: [[]],
      customerFormFieldEmailTypes: this.formBuilder.group({
        values: this.formBuilder.array([])
      })
    });
  }

  private createMessageFormGroup() {
    this.messageFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
      event: [[], Validators.required],
      messageTemplate: [[], [Validators.required, AppValidators.validateEnabledItems]],
      users: [[], AppValidators.validateEnabledItems],
      userGroups: [[], AppValidators.validateEnabledItems],
      mobileApplications: [[], AppValidators.validateEnabledItems],
      relatedPersonTypes: [[]],
      userFormFields: [[]],
    });
  }

  private createReceiptFormGroup() {
    this.receiptFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
      event: [[], Validators.required],
      receiptTemplate: [[], [Validators.required, AppValidators.validateEnabledItems]],
      fileNamePattern: ['', Validators.required],
    });
  }

  private createCloneFormGroup() {
    this.cloneFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
      event: [[], Validators.required],
      defaultDeadlineAdditionalDays: ['', Validators.required],
      cloneFields: [[], TriggerEditDialogComponent.validateBulkCloneFields],
      cloneFormFields: [
        [],
        AppValidators.required({
          disabled: () => {
            return !this.cloneFormFieldsVisible();
          }
        })],
      destinationTask: ['', [Validators.required, AppValidators.validateEnabledItems]]
    });
  }

  cloneFormFieldsVisible(): boolean {
    return this.cloneTriggerEditModel.cloneFields.map(f => f.id).includes(TaskRecordBulkCloneField.FORM_FIELDS);
  }

  private static validateBulkCloneFields(c: FormControl) {
    const items: MultiselectOptionItem<TaskRecordBulkCloneField>[] = c.value;
    if (!items) {
      return null;
    }
    if (!items.map(f => f.id).includes(TaskRecordBulkCloneField.NAME)) {
      return {
        nameRequired: true
      };
    }
    return null;
  }

  loadEmailTemplates(searchValue?: string) {
    this.emailTemplateService.query({
      name: Strings.undefinedOrNonEmpty(searchValue),
      paging: {
        pageNumber: 1,
        numberOfItems: UiConstants.autocompletePageSize
      },
      disabled: false,
      noProgressBar: true
    }).subscribe(result => {
      const emailTemplateIds = this.emailTriggerEditModel.emailTemplate.map((template) => template.id!);
      this.emailTemplates = [];
      if (searchValue === undefined) {
        this.emailTriggerEditModel.emailTemplate = [];
      }
      result.items.forEach((template) => {
        if (template) {
          const item = new OptionItem<number>();
          item.id = template.id;
          item.text = template.name;
          item.disabled = template.disabled;
          this.emailTemplates.push(item);
          if (searchValue === undefined && emailTemplateIds.find((id) => id === template.id)) {
            this.emailTriggerEditModel.emailTemplate.push(item);
          }
        }
      });
      if (searchValue === undefined &&
        emailTemplateIds.length > 0 &&
        this.emailTriggerEditModel.emailTemplate.length !== emailTemplateIds.length) {
        this.emailTemplateService.query({
          id: Set.of(...emailTemplateIds)
        }).subscribe(result => {
          this.emailTriggerEditModel.emailTemplate = [];
          result.items.forEach((template) => {
            if (template) {
              const item = new OptionItem<number>();
              item.id = template.id;
              item.text = template.name;
              item.disabled = template.disabled;
              this.emailTriggerEditModel.emailTemplate.push(item);
              this.emailFormGroup.controls['emailTemplate'].updateValueAndValidity();
            }
          });
        });
      }
    });
  }

  loadPdfTemplates(searchValue?: string) {
    this.generalPdfTemplateService.query({
      code: Strings.undefinedOrNonEmpty(searchValue),
      paging: {
        pageNumber: 1,
        numberOfItems: UiConstants.autocompletePageSize
      },
      disabled: false,
      noProgressBar: true
    }).subscribe(result => {
      const pdfTemplateIds = this.pdfTriggerEditModel.pdfTemplate.map((template) => template.id!);
      this.pdfTemplates = [];
      if (searchValue === undefined) {
        this.pdfTriggerEditModel.pdfTemplate = [];
      }
      result.items.forEach((template) => {
        if (template) {
          const item = new OptionItem<number>();
          item.id = template.id;
          item.text = template.code;
          item.disabled = template.disabled;
          this.pdfTemplates.push(item);
          if (searchValue === undefined && pdfTemplateIds.find((id) => id === template.id)) {
            this.pdfTriggerEditModel.pdfTemplate.push(item);
          }
        }
      });
      if (searchValue === undefined &&
        pdfTemplateIds.length > 0 &&
        this.pdfTriggerEditModel.pdfTemplate.length !== pdfTemplateIds.length
      ) {
        this.generalPdfTemplateService.query({
          ids: Set.of(...pdfTemplateIds)
        }).subscribe(result => {
          this.pdfTriggerEditModel.pdfTemplate = [];
          result.items.forEach((template) => {
            if (template) {
              const item = new OptionItem<number>();
              item.id = template.id;
              item.text = template.code;
              item.disabled = template.disabled;
              this.pdfTriggerEditModel.pdfTemplate.push(item);
              this.emailFormGroup.controls['pdfTriggers'].updateValueAndValidity();
            }
          });
        });
      }
    });
  }

  loadReceiptTemplates(searchValue?: string) {
    this.generalPrinterTemplateService.query({
      code: Strings.undefinedOrNonEmpty(searchValue),
      paging: {
        pageNumber: 1,
        numberOfItems: UiConstants.autocompletePageSize
      },
      disabled: false,
      noProgressBar: true
    }).subscribe(result => {
      const receiptTemplateIds = this.receiptTriggerEditModel.receiptTemplate.map((template) => template.id!);
      this.receiptTemplates = [];
      if (searchValue === undefined) {
        this.receiptTriggerEditModel.receiptTemplate = [];
      }
      result.items.forEach((template) => {
        if (template) {
          const item = new OptionItem<number>();
          item.id = template.id;
          item.text = template.code;
          item.disabled = template.disabled;
          this.receiptTemplates.push(item);
          if (searchValue === undefined && receiptTemplateIds.find((id) => id === template.id)) {
            this.receiptTriggerEditModel.receiptTemplate.push(item);
          }
        }
      });
      if (searchValue === undefined &&
        receiptTemplateIds.length > 0 &&
        this.receiptTriggerEditModel.receiptTemplate.length !== receiptTemplateIds.length
      ) {
        this.generalPrinterTemplateService.query({
          ids: Set.of(...receiptTemplateIds)
        }).subscribe(result => {
          this.receiptTriggerEditModel.receiptTemplate = [];
          result.items.forEach((template) => {
            if (template) {
              const item = new OptionItem<number>();
              item.id = template.id;
              item.text = template.code;
              item.disabled = template.disabled;
              this.receiptTriggerEditModel.receiptTemplate.push(item);
              this.receiptFormGroup.controls['receiptTemplate'].updateValueAndValidity();
            }
          });
        });
      }
    });
  }

  loadMessageTemplates(searchValue?: string) {
    this.messageTemplateService.query({
      name: Strings.undefinedOrNonEmpty(searchValue),
      paging: {
        pageNumber: 1,
        numberOfItems: UiConstants.autocompletePageSize
      },
      disabled: false,
      noProgressBar: true
    }).subscribe(result => {
      const messageTemplateIds = this.messageTriggerEditModel.messageTemplate.map((template) => template.id!);
      this.messageTemplates = [];
      if (searchValue === undefined) {
        this.messageTriggerEditModel.messageTemplate = [];
      }
      result.items.forEach((template) => {
        if (template) {
          const item = new OptionItem<number>();
          item.id = template.id;
          item.text = template.name;
          item.disabled = template.disabled;
          this.messageTemplates.push(item);
          if (searchValue === undefined && messageTemplateIds.find((id) => id === template.id)) {
            this.messageTriggerEditModel.messageTemplate.push(item);
          }
        }
      });
      if (searchValue === undefined &&
        messageTemplateIds.length > 0 &&
        this.messageTriggerEditModel.messageTemplate.length !== messageTemplateIds.length
      ) {
        this.messageTemplateService.query({
          id: Set.of(...messageTemplateIds)
        }).subscribe(result => {
          this.messageTriggerEditModel.messageTemplate = [];
          result.items.forEach((template) => {
            if (template) {
              const item = new OptionItem<number>();
              item.id = template.id;
              item.text = template.name;
              item.disabled = template.disabled;
              this.messageTriggerEditModel.messageTemplate.push(item);
              this.messageFormGroup.controls['messageTemplate'].updateValueAndValidity();
            }
          });
        });
      }
    });
  }

  loadFileDocuments(searchValue?: string) {
    if (this.rightModel.documentFileRead.hasRight()) {
      this.fileDocumentService.query({
        name: Strings.undefinedOrNonEmpty(searchValue),
        page_number: 1,
        number_of_items: UiConstants.autocompletePageSize,
        disabled: false,
        no_progress_bar: true
      }).subscribe(result => {
        const emailFileDocumentIds = this.emailTriggerEditModel.fileDocuments.map((template) => template.id!);
        this.fileDocuments = [];
        if (searchValue === undefined) {
          this.emailTriggerEditModel.fileDocuments = [];
        }
        result.items.forEach((document) => {
          if (document) {
            const item = new OptionItem<number>();
            item.id = document.id;
            item.text = document.name;
            item.disabled = document.disabled;
            this.fileDocuments.push(item);
            if (searchValue === undefined && emailFileDocumentIds.find((id) => id === document.id)) {
              this.emailTriggerEditModel.fileDocuments.push(item);
            }
          }
        });
        if (searchValue === undefined &&
          emailFileDocumentIds.length > 0 &&
          this.emailTriggerEditModel.fileDocuments.length !== emailFileDocumentIds.length
        ) {
          this.fileDocumentService.query({
            id: emailFileDocumentIds.join(',')
          }).subscribe(result => {
            this.emailTriggerEditModel.fileDocuments = [];
            result.items.forEach((template) => {
              if (template) {
                const item = new OptionItem<number>();
                item.id = template.id;
                item.text = template.name;
                item.disabled = template.disabled;
                this.emailTriggerEditModel.fileDocuments.push(item);
                this.emailFormGroup.controls['fileDocuments'].updateValueAndValidity();
              }
            });
          });
        }
      });
    }
  }

  loadDocumentGroups(searchValue?: string) {
    if (this.rightModel.documentGroupRead.hasRight()) {
      this.documentGroupService.query({
        name: Strings.undefinedOrNonEmpty(searchValue),
        page_number: 1,
        number_of_items: UiConstants.autocompletePageSize,
        disabled: false,
        no_progress_bar: true
      }).subscribe(result => {
        const emailDocumentGroupIds = this.emailTriggerEditModel.documentGroups.map((group) => group.id!);
        this.documentGroups = [];
        if (searchValue === undefined) {
          this.emailTriggerEditModel.documentGroups = [];
        }
        result.items.forEach((group) => {
          if (group) {
            const item = new OptionItem<number>();
            item.id = group.id;
            item.text = group.name;
            item.disabled = group.disabled;
            this.documentGroups.push(item);
            if (searchValue === undefined && emailDocumentGroupIds.find((id) => id === group.id)) {
              this.emailTriggerEditModel.documentGroups.push(item);
            }
          }
        });
        if (searchValue === undefined &&
          emailDocumentGroupIds.length > 0 &&
          this.emailTriggerEditModel.documentGroups.length !== emailDocumentGroupIds.length
        ) {
          this.documentGroupService.query({
            id: emailDocumentGroupIds.join(',')
          }).subscribe(result => {
            this.emailTriggerEditModel.documentGroups = [];
            result.items.forEach((group) => {
              if (group) {
                const item = new OptionItem<number>();
                item.id = group.id;
                item.text = group.name;
                item.disabled = group.disabled;
                this.emailTriggerEditModel.documentGroups.push(item);
                this.emailFormGroup.controls['documentGroups'].updateValueAndValidity();
              }
            });
          });
        }
      });
    }
  }

  loadPdfTriggers(searchValue?: string) {
    this.triggerService.queryTriggers({
      nameSwIc: Strings.undefinedOrNonEmpty(searchValue),
      triggerParentId: this.triggerParentId,
      noProgressBar: true,
      paging: {
        numberOfItems: UiConstants.autocompletePageSize,
        pageNumber: 1
      }
    }).subscribe(result => {
      const pdfTriggerIds = this.emailTriggerEditModel.pdfTriggers.map((trigger) => trigger.id!);
      this.pdfTriggers = [];
      if (!searchValue) {
        this.emailTriggerEditModel.pdfTriggers = [];
      }
      result.items.forEach((trigger) => {
        if (trigger && trigger.type === 'PDF') {
          const item = new OptionItem<number>();
          item.id = trigger.id;
          item.text = trigger.name;
          item.disabled = trigger.disabled;
          this.pdfTriggers.push(item);
          if (pdfTriggerIds.find((id) => id === trigger.id)) {
            this.emailTriggerEditModel.pdfTriggers.push(item);
            this.emailFormGroup.controls['pdfTriggers'].updateValueAndValidity();
          }
        }
      });
    });
  }

  loadUsers(searchValue?: string) {
    const emailUserIds = this.emailTriggerEditModel.users.map((user) => user.id!);
    const messageUserIds = this.messageTriggerEditModel.users.map((user) => user.id!);
    if (searchValue === undefined) {
      this.emailTriggerEditModel.users = [];
      this.messageTriggerEditModel.users = [];
    }
    this.userService.query({
      person_name: Strings.undefinedOrNonEmpty(searchValue),
      fields: 'id,user_name,person_name,disabled',
      disabled: false,
      number_of_items: UiConstants.autocompletePageSize,
      page_number: 1,
      order: Services.createOrderFieldParameter(User.Keys.toOrderFieldKey, Set.of(User.DEFAULT_ORDER)),
      no_progress_bar: true
    }).subscribe((users: ResourceQueryResult<User>) => {
      this.users = [];
      const sortedOwnerUsers: OwnerUserItem[] = this.ownerUserItemFactory.createAll(users.items);
      sortedOwnerUsers.sort(OptionItems.createNameComparator());
      Arrays.iterateByIndex(sortedOwnerUsers, (item) => {
        this.users.push(item);
        if (searchValue === undefined && emailUserIds.find((id) => id === item.id)) {
          this.emailTriggerEditModel.users.push(item);
        }
        if (searchValue === undefined && messageUserIds.find((id) => id === item.id)) {
          this.messageTriggerEditModel.users.push(item);
        }
      });
      if (searchValue === undefined &&
        emailUserIds.length > 0 &&
        this.emailTriggerEditModel.users.length !== emailUserIds.length) {
        this.userService.query({
          fields: 'id,user_name,person_name,disabled',
          id: emailUserIds.join(',')
        }).subscribe(users => {
          this.emailTriggerEditModel.users = [];
          const sortedOwnerUsers: OwnerUserItem[] = this.ownerUserItemFactory.createAll(users.items);
          Arrays.iterateByIndex(sortedOwnerUsers, (item) => {
            this.emailTriggerEditModel.users.push(item);
            this.emailFormGroup.controls['users'].updateValueAndValidity();
          });
        });
      }
      if (searchValue === undefined &&
        messageUserIds.length > 0 &&
        this.messageTriggerEditModel.users.length !== messageUserIds.length) {
        this.userService.query({
          fields: 'id,user_name,person_name,disabled',
          id: messageUserIds.join(',')
        }).subscribe(users => {
          this.messageTriggerEditModel.users = [];
          const sortedOwnerUsers: OwnerUserItem[] = this.ownerUserItemFactory.createAll(users.items);
          Arrays.iterateByIndex(sortedOwnerUsers, (item) => {
            this.messageTriggerEditModel.users.push(item);
            this.messageFormGroup.controls['users'].updateValueAndValidity();
          });
        });
      }
    });
  }

  loadUserGroups(searchValue?: string) {
    const emailUserGroupIds = this.emailTriggerEditModel.userGroups.map((group) => group.id!);
    const messageUserGroupIds = this.messageTriggerEditModel.userGroups.map((group) => group.id!);
    if (searchValue === undefined) {
      this.emailTriggerEditModel.userGroups = [];
      this.messageTriggerEditModel.userGroups = [];
    }
    this.userGroupService.query({
      name: Strings.undefinedOrNonEmpty(searchValue),
      disabled: false,
      number_of_items: UiConstants.autocompletePageSize,
      page_number: 1,
      order: Services.createOrderFieldParameter(UserGroup.Keys.toOrderFieldKey, Set.of(UserGroup.DEFAULT_ORDER)),
      no_progress_bar: true
    }).subscribe((groups: ResourceQueryResult<UserGroup>) => {
      this.userGroups = [];
      groups.items.forEach(group => {
        const item = new OptionItem<number>();
        item.id = group.id;
        item.text = group.name;
        item.disabled = group.disabled;
        this.userGroups.push(item);
        if (searchValue === undefined && emailUserGroupIds.find((id) => id === item.id)) {
          this.emailTriggerEditModel.userGroups.push(item);
        }
        if (searchValue === undefined && messageUserGroupIds.find((id) => id === item.id)) {
          this.messageTriggerEditModel.userGroups.push(item);
        }
      });
      if (searchValue === undefined &&
        emailUserGroupIds.length > 0 &&
        this.emailTriggerEditModel.userGroups.length !== emailUserGroupIds.length) {
        this.userGroupService.query({
          id: emailUserGroupIds.join(','),
          order: Services.createOrderFieldParameter(UserGroup.Keys.toOrderFieldKey, Set.of(UserGroup.DEFAULT_ORDER))
        }).subscribe(userGroups => {
          this.emailTriggerEditModel.userGroups = [];
          userGroups.items.forEach(group => {
            const item = new OptionItem<number>();
            item.id = group.id;
            item.text = group.name;
            item.disabled = group.disabled;
            this.emailTriggerEditModel.userGroups.push(item);
            this.emailFormGroup.controls['userGroups'].updateValueAndValidity();
          });
        });
      }
      if (searchValue === undefined &&
        messageUserGroupIds.length > 0 &&
        this.messageTriggerEditModel.userGroups.length !== messageUserGroupIds.length) {
        this.userGroupService.query({
          id: messageUserGroupIds.join(','),
          order: Services.createOrderFieldParameter(UserGroup.Keys.toOrderFieldKey, Set.of(UserGroup.DEFAULT_ORDER))
        }).subscribe(userGroups => {
          this.messageTriggerEditModel.userGroups = [];
          userGroups.items.forEach(group => {
            const item = new OptionItem<number>();
            item.id = group.id;
            item.text = group.name;
            item.disabled = group.disabled;
            this.messageTriggerEditModel.userGroups.push(item);
            this.messageFormGroup.controls['userGroups'].updateValueAndValidity();
          });
        });
      }
    });
  }

  loadMobileApplications(searchValue?: string) {
    const messageDeviceIds = this.messageTriggerEditModel.mobileApplications.map((device) => device.id!);
    if (searchValue === undefined) {
      this.messageTriggerEditModel.mobileApplications = [];
    }
    this.deviceService.query({
      q: Strings.undefinedOrNonEmpty(searchValue),
      disabled: false,
      number_of_items: UiConstants.autocompletePageSize,
      page_number: 1,
      no_progress_bar: true
    }).subscribe((devices: ResourceQueryResult<Device>) => {
      this.mobileApplications = [];
      devices.items.forEach(device => {
        const item = new OptionItem<number>();
        item.id = device.id;
        item.text = device.name ? device.name : device.application_id;
        item.disabled = device.disabled;
        this.mobileApplications.push(item);
        if (searchValue === undefined && messageDeviceIds.find((id) => id === item.id)) {
          this.messageTriggerEditModel.mobileApplications.push(item);
        }
      });
      if (searchValue === undefined &&
        messageDeviceIds.length > 0 &&
        this.messageTriggerEditModel.mobileApplications.length !== messageDeviceIds.length
      ) {
        this.deviceService.query({
          id: messageDeviceIds.join(',')
        }).subscribe(devices => {
          this.messageTriggerEditModel.mobileApplications = [];
          devices.items.forEach(device => {
            const item = new OptionItem<number>();
            item.id = device.id;
            item.text = device.name ? device.name : device.application_id;
            item.disabled = device.disabled;
            this.messageTriggerEditModel.mobileApplications.push(item);
            this.messageFormGroup.controls['mobileApplications'].updateValueAndValidity();
          });
        });
      }
    });
  }

  loadTasks(q?: string) {
    this.taskMultiselectProvider.loadActive(q).subscribe(tasks => {
      this.tasks = tasks;
    })
  }

  showTriggerEditDialog(triggerType: TriggerUtils.TriggerType, triggerId?: number, clone?: boolean) {
    this.triggerEditDialogVisible = true;
    this.triggerEditDialog.show();
    this.triggerType = triggerType;
    this.triggerId = triggerId ? triggerId : undefined;
    this.clone = clone ? clone : false;
    this.initTriggerEditDialog();
  }

  closeTriggerEditDialog() {
    this.triggerType = undefined;
    this.triggerEditDialogVisible = false;
    this.pdfTriggerEditModel = new PdfTriggerEditModel();
    this.receiptTriggerEditModel = new ReceiptTriggerEditModel();
    this.emailTriggerEditModel = new EmailTriggerEditModel();
    this.messageTriggerEditModel = new MessageTriggerEditModel();
    this.cloneTriggerEditModel = new CloneTriggerEditModel();
    this.triggerEditDialog.hide();
  }

  initTypeSpecificFields() {
    switch (this.triggerType) {
      case 'EMAIL':
        this.headingDictionaryKey = this.triggerId ? 'TRIGGER_EDIT_EMAIL' : 'TRIGGER_CREATE_EMAIL';
        this.createEmailFormGroup();
        break;
      case 'MESSAGE':
        this.headingDictionaryKey = this.triggerId ? 'TRIGGER_EDIT_MESSAGE' : 'TRIGGER_CREATE_MESSAGE';
        this.createMessageFormGroup();
        break;
      case 'PDF':
        this.headingDictionaryKey = this.triggerId ? 'TRIGGER_EDIT_PDF' : 'TRIGGER_CREATE_PDF';
        this.createPdfFormGroup();
        break;
      case 'RECEIPT':
        this.headingDictionaryKey = this.triggerId ? 'TRIGGER_EDIT_RECEIPT' : 'TRIGGER_CREATE_RECEIPT';
        this.createReceiptFormGroup();
        break;
      case 'CLONE':
        this.headingDictionaryKey = this.triggerId ? 'TRIGGER_EDIT_CLONE' : 'TRIGGER_CREATE_CLONE';
        this.createCloneFormGroup();
        break;
    }
  }

  private loadEmailTriggerModel(trigger: Trigger.Trigger) {
    this.emailTriggerEditModel = new EmailTriggerEditModel();
    this.emailTriggerEditModel.name = trigger.name;
    const notificationEvent = TriggerUtils.notificationEvents.find((event) => event.notificationEvent === trigger.event);
    if (notificationEvent) {
      this.emailTriggerEditModel.event.push({
        id: notificationEvent.notificationEvent,
        text: this.translateService.instant(notificationEvent.stringKey)
      });
    }

    this.emailTriggerEditModel.emailTemplate.push({
      id: trigger.email!.emailTemplate.id,
      text: trigger.email!.emailTemplate.name
    });
    this.emailTriggerEditModel.ignoredWithEmptyRecipients = trigger.email!.ignoredWithEmptyRecipients;

    this.emailTriggerEditModel.fileDocuments = trigger.email!.attachment.fileDocumentIds.toArray().map((id) => ({
      id: id,
      text: ''
    }));
    this.emailTriggerEditModel.documentGroups = trigger.email!.attachment.documentGroupIds.toArray().map((id) => ({
      id: id,
      text: ''
    }));
    this.emailTriggerEditModel.attachEachShipmentDocument = !!trigger.email!.attachment.attachEachShipmentDocument;
    this.emailTriggerEditModel.attachInvoices = trigger.email!.attachment.attachInvoices;
    this.emailTriggerEditModel.attachTaskAttachments = trigger.email!.attachment.attachTaskAttachments;

    this.emailTriggerEditModel.users = trigger.email!.recipient.userIds.toArray().map((id) => ({id: id, text: ''}));
    this.emailTriggerEditModel.userGroups = trigger.email!.recipient.userGroupIds.toArray().map((id) => ({
      id: id,
      text: ''
    }));
    this.emailTriggerEditModel.emailFormFields = trigger.email!.recipient.emailFormFieldIds.toArray()
      .map(id => MultiselectForm.ImmutableFormFieldItem.lazy(id)); // WHY?! Here we create a dummy object ...
    this.emailTriggerEditModel.documentFormFields = trigger.email!.attachment.documentFormFieldIds.toArray()
      .map(id => MultiselectForm.ImmutableFormFieldItem.lazy(id)); // WHY?! Here we create a dummy object ...
    this.emailTriggerEditModel.userFormFields = trigger.email!.recipient.userFormFieldIds.toArray()
      .map(id => MultiselectForm.ImmutableFormFieldItem.lazy(id)); // WHY?! Here we create a dummy object ...
    this.emailTriggerEditModel.emailAddresses = trigger.email!.recipient.emailAddresses.toArray();
    this.emailTriggerEditModel.relatedPersonTypes = trigger.email!.recipient.relatedPersonTypes.toArray().map((id) => ({
      id: id,
      text: ''
    }));
    this.emailTriggerEditModel.sendToEveryone = trigger.email!.recipient.sendToEveryone;
    this.emailTriggerEditModel.sendToAssignee = trigger.email!.recipient.relatedPersonTypes.contains('ASSIGNEE');
    this.emailTriggerEditModel.sendToCreator = trigger.email!.recipient.relatedPersonTypes.contains('CREATOR');
    this.emailTriggerEditModel.sendToProcessCreator = trigger.email!.recipient.relatedPersonTypes.contains('PROCESS_CREATOR');
    this.emailTriggerEditModel.sendToAssigneeGroup = trigger.email!.recipient.relatedPersonTypes.contains('ASSIGNEE_GROUP');

    switch (this.scope) {
      case 'TASK':
        this.emailTriggerEditModel.pdfTriggers =
          trigger.email!.attachment.pdfTriggerIds!.toArray().map((id) => ({id: id, text: ''}));
        this.emailAddressTypeService.getLocalizedList(this.translateService.currentLang.substr(0, 2))
          .subscribe(result => {
            this.emailTriggerEditModel.emailAddressTypes
              = result.filter(t => trigger.email!.recipient.emailAddressTypeIds.contains(t.id))
              .map(t => ({id: t.id, text: t.itemName}));
            const customerControls = <FormArray>this.emailFormGroup.get('customerFormFieldEmailTypes.values');
            trigger.email!.recipient.customerFormFieldEmailAddressTypes.forEach((value, key) => {
              if (key && value) {
                const c = new CustomerFormFieldEmailTypeModel();
                c._formField = [MultiselectForm.ImmutableFormFieldItem.lazy(+key)];
                c.emailAddressTypes = [];
                value.forEach(x => {
                  const o = result.find(r => r.id === x);
                  if (o) {
                    c.emailAddressTypes.push({
                      id: o.id,
                      text: o.itemName,
                      disabled: o.disabled
                    });
                  }
                });
                this.emailTriggerEditModel.customerFormFieldEmailTypes.push(c);
                const cc = this.formBuilder.control({value: c._formField},
                  [Validators.required, AppValidators.validateEnabledItems]);
                c.helper = this.createMultiselectCustomerFormFieldLoadingHelper(cc, c);
                customerControls.push(this.formBuilder.group({
                  customerFormField: cc,
                  emailAddressTypes: this.formBuilder.control({value: c.emailAddressTypes}, [Validators.required]),
                }));
              }
            })
            this.loadCustomerFormFields();
          });
        this.emailTriggerEditModel.rules = trigger.rules.map(r => {
          const model = WorkflowTransitionRuleModel.createModel(r, this.translateService);
          return model;
        });
        break;
      case 'TRANSPORT':
        this.emailTriggerEditModel.transportDocumentTypes =
          trigger.email!.attachment.transportDocumentTypes!.toArray().map((id) => ({id: id, text: ''}));
        break;
    }
  }

  loadEmailFormFields() {
    const helper = this.createMultiselectEmailFormFieldLoadingHelper();
    if (helper === undefined) {
      return;
    }
    const modelIds = this.emailTriggerEditModel.emailFormFields.map((item) => item.id!); // ... and now back to ID -.-
    const ids = Set.of(...modelIds);
    helper.loadCurrentItems(ids);
  }

  loadCustomerFormFields() {
    this.emailTriggerEditModel.customerFormFieldEmailTypes.forEach(c => {
      const helper = c.helper;
      if (helper === undefined) {
        return;
      }
      const modelIds: number[] = c._formField.map((item) => +item.id!); // ... and now back to ID -.-
      const ids = Set.of(...modelIds);
      helper.loadCurrentItems(ids);
    });
  }

  loadDocumentFormFields() {
    const helper = this.createMultiselectDocumentFormFieldLoadingHelper();
    if (helper === undefined) {
      return;
    }
    const modelIds = this.emailTriggerEditModel.documentFormFields.map((item) => item.id!); // ... and now back to ID -.-
    const ids = Set.of(...modelIds);
    helper.loadCurrentItems(ids);
  }

  loadUserFormFields() {
    {
      const helper = this.createMultiselectEmailUserFormFieldLoadingHelper();
      if (helper === undefined) {
        return;
      }
      const modelIds = this.emailTriggerEditModel.userFormFields.map((item) => item.id!); // ... and now back to ID -.-
      const ids = Set.of(...modelIds);
      helper.loadCurrentItems(ids);
    }
    {
      const helper = this.createMultiselectMessageUserFormFieldLoadingHelper();
      if (helper === undefined) {
        return;
      }
      const modelIds = this.messageTriggerEditModel.userFormFields.map((item) => item.id!); // ... and now back to ID -.-
      const ids = Set.of(...modelIds);
      helper.loadCurrentItems(ids);
    }
  }

  private loadBulkCloneFields() {
    const observables = this.bulkCloneFields.map(f => this.translateService.get(f.itemName));
    forkJoin(observables).subscribe(results => {
      for (let i = 0; i < this.bulkCloneFields.length; i++) {
        this.bulkCloneFields[i].itemName = results[i];
      }
      this.bulkCloneFields.sort(
        (first, second) => {
          return first.itemName.localeCompare(second.itemName);
        });
      if (!this.cloneTriggerEditModel.cloneFields.find(f => f.id === TaskRecordBulkCloneField.NAME)) {
        this.cloneTriggerEditModel.cloneFields.push(this.bulkCloneFields.find(f => f.id === TaskRecordBulkCloneField.NAME)!);
      }
    });
  }

  loadCloneFormFields() {
    const helper = this.createMultiselectCloneFormFieldLoadingHelper();
    if (helper === undefined) {
      return;
    }
    const modelIds = this.cloneTriggerEditModel.cloneFormFields.map((item) => item.id!); // ... and now back to ID -.-
    const ids = Set.of(...modelIds);
    helper.loadCurrentItems(ids);
  }

  private loadEmailAddressTypes() {
    this.emailAddressTypeService.getLocalizedList(
      this.translateService.currentLang.substr(0, 2)
    ).subscribe(result => {
      this.emailAddressTypes = result.map(t => ({id: t.id, text: t.itemName}));
    });
  }

  searchEmailFormFields(searchValue?: string) {
    const helper = this.createMultiselectEmailFormFieldLoadingHelper();
    if (helper === undefined) {
      return;
    }
    helper.loadSearch(searchValue);
  }

  searchCustomerFormFields(i: number, searchValue?: string) {
    const helper = this.emailTriggerEditModel.getCustomerFormFieldEmailTypes(i).helper;
    if (helper === undefined) {
      return;
    }
    helper.loadSearch(searchValue);
  }

  searchDocumentFormFields(searchValue?: string) {
    const helper = this.createMultiselectDocumentFormFieldLoadingHelper();
    if (helper === undefined) {
      return;
    }
    helper.loadSearch(searchValue);
  }

  searchEmailUserFormFields(searchValue?: string) {
    const helper = this.createMultiselectEmailUserFormFieldLoadingHelper();
    if (helper === undefined) {
      return;
    }
    helper.loadSearch(searchValue);
  }

  searchMessageUserFormFields(searchValue?: string) {
    const helper = this.createMultiselectMessageUserFormFieldLoadingHelper();
    if (helper === undefined) {
      return;
    }
    helper.loadSearch(searchValue);
  }

  searchCloneFormFields(searchValue?: string) {
    const helper = this.createMultiselectCloneFormFieldLoadingHelper();
    if (helper === undefined) {
      return;
    }
    helper.loadSearch(searchValue);
  }

  private createMultiselectEmailFormFieldLoadingHelper():
    Multiselect.LoadingHelper<MultiselectForm.ImmutableFormFieldItem> | undefined {
    const formGroup = this.emailFormGroup;
    const form = this._form;
    if (!formGroup || !form) {
      return;
    }
    const formControl = formGroup.controls['emailFormFields'];
    const filter = (field) => field.dataTypeSelector === Form.FieldDataTypeSelector.EMAIL_ADDRESS;
    return Multiselect.LoadingHelper.forEdit({
      topActiveItem$: MultiselectForm.topActiveItem$(form, filter, this.translateService),
      searchItem$: MultiselectForm.searchItem$(form, filter, this.translateService),
      modelStrategy: new Multiselect.MutableModelStrategy({
        selectableItems: this.emailFormFields,
        selectedItems: this.emailTriggerEditModel.emailFormFields
      }),
      validationStrategy: Multiselect.FormControlValidationStrategy.of(formControl),
      unknownItemFactory: MultiselectForm.createUnknownImmutableUserItemFactory(this.translateService)
    });
  }

  private createMultiselectDocumentFormFieldLoadingHelper():
    Multiselect.LoadingHelper<MultiselectForm.ImmutableFormFieldItem> | undefined {
    const formGroup = this.emailFormGroup;
    const form = this._form;
    if (!formGroup || !form) {
      return;
    }
    const formControl = formGroup.controls['documentFormFields'];
    const filter = (field) => (field.dataTypeSelector === Form.FieldDataTypeSelector.PHOTO
      || field.dataTypeSelector === Form.FieldDataTypeSelector.DOCUMENT);
    return Multiselect.LoadingHelper.forEdit({
      topActiveItem$: MultiselectForm.topActiveItem$(form, filter, this.translateService),
      searchItem$: MultiselectForm.searchItem$(form, filter, this.translateService),
      modelStrategy: new Multiselect.MutableModelStrategy({
        selectableItems: this.documentFormFields,
        selectedItems: this.emailTriggerEditModel.documentFormFields
      }),
      validationStrategy: Multiselect.FormControlValidationStrategy.of(formControl),
      unknownItemFactory: MultiselectForm.createUnknownImmutableUserItemFactory(this.translateService)
    });
  }

  private createMultiselectEmailUserFormFieldLoadingHelper():
    Multiselect.LoadingHelper<MultiselectForm.ImmutableFormFieldItem> | undefined {
    const formGroup = this.emailFormGroup;
    const form = this._form;
    if (!formGroup || !form) {
      return;
    }
    const formControl = formGroup.controls['userFormFields'];
    const filter = (field) => (field.dataTypeSelector === Form.FieldDataTypeSelector.USER);
    return Multiselect.LoadingHelper.forEdit({
      topActiveItem$: MultiselectForm.topActiveItem$(form, filter, this.translateService),
      searchItem$: MultiselectForm.searchItem$(form, filter, this.translateService),
      modelStrategy: new Multiselect.MutableModelStrategy({
        selectableItems: this.emailUserFormFields,
        selectedItems: this.emailTriggerEditModel.userFormFields
      }),
      validationStrategy: Multiselect.FormControlValidationStrategy.of(formControl),
      unknownItemFactory: MultiselectForm.createUnknownImmutableUserItemFactory(this.translateService)
    });
  }

  private createMultiselectMessageUserFormFieldLoadingHelper():
    Multiselect.LoadingHelper<MultiselectForm.ImmutableFormFieldItem> | undefined {
    const formGroup = this.messageFormGroup;
    const form = this._form;
    if (!formGroup || !form) {
      return;
    }
    const formControl = formGroup.controls['userFormFields'];
    const filter = (field) => (field.dataTypeSelector === Form.FieldDataTypeSelector.USER);
    return Multiselect.LoadingHelper.forEdit({
      topActiveItem$: MultiselectForm.topActiveItem$(form, filter, this.translateService),
      searchItem$: MultiselectForm.searchItem$(form, filter, this.translateService),
      modelStrategy: new Multiselect.MutableModelStrategy({
        selectableItems: this.messageUserFormFields,
        selectedItems: this.messageTriggerEditModel.userFormFields
      }),
      validationStrategy: Multiselect.FormControlValidationStrategy.of(formControl),
      unknownItemFactory: MultiselectForm.createUnknownImmutableUserItemFactory(this.translateService)
    });
  }

  private createMultiselectCustomerFormFieldLoadingHelper(formControl: FormControl,
                                                          emailTypeModel: CustomerFormFieldEmailTypeModel):
    Multiselect.LoadingHelper<MultiselectForm.ImmutableFormFieldItem> | undefined {
    const formGroup = this.emailFormGroup;
    const form = this._form;
    if (!formGroup || !form) {
      return;
    }
    const filter = (field) => field.dataTypeSelector === Form.FieldDataTypeSelector.CUSTOMER;
    return Multiselect.LoadingHelper.forEdit({
      topActiveItem$: MultiselectForm.topActiveItem$(form, filter, this.translateService),
      searchItem$: MultiselectForm.searchItem$(form, filter, this.translateService),
      modelStrategy: new Multiselect.MutableModelStrategy({
        selectableItems: emailTypeModel.customerFormFields,
        selectedItems: emailTypeModel._formField
      }),
      validationStrategy: Multiselect.FormControlValidationStrategy.of(formControl),
      unknownItemFactory: MultiselectForm.createUnknownImmutableUserItemFactory(this.translateService)
    });
  }

  private createMultiselectCloneFormFieldLoadingHelper():
    Multiselect.LoadingHelper<MultiselectForm.ImmutableFormFieldItem> | undefined {
    const formGroup = this.cloneFormGroup;
    const form = this._form;
    if (!formGroup || !form) {
      return;
    }
    const formControl = formGroup.controls['cloneFormFields'];
    const managedFields: Map<number, number> = new Map();
    form.groups.forEach(g => {
      g!.fields.forEach(f => {
        if (FieldDataTypeSelectors.isActivatorField(f!)) {
          const fields = FieldDataTypeSelectors.getEnabledByThisFieldIds(f!);
          fields.forEach(id => managedFields.set(id, f!.fieldId));
        }
      });
    });
    const currentValues = this.cloneTriggerEditModel.cloneFormFields.map(f => f.id!);
    const filter = (field) => {
      return managedFields.has(field.fieldId) ? currentValues.includes(managedFields.get(field.fieldId)!) : true;
    };
    return Multiselect.LoadingHelper.forEdit({
      topActiveItem$: MultiselectForm.topActiveItem$(form, filter, this.translateService),
      searchItem$: MultiselectForm.searchItem$(form, filter, this.translateService),
      modelStrategy: new Multiselect.MutableModelStrategy({
        selectableItems: this.cloneFormFields,
        selectedItems: this.cloneTriggerEditModel.cloneFormFields
      }),
      validationStrategy: Multiselect.FormControlValidationStrategy.of(formControl),
      unknownItemFactory: MultiselectForm.createUnknownImmutableUserItemFactory(this.translateService)
    });
  }

  onCloneFormFieldsChanged() {
    this.searchCloneFormFields(this.cloneFormFieldsInput.searchSubject.value.target.value);
    setTimeout(() => {
      this.cloneFormFieldsInput.openDropdown();
    });
  }

  private loadPdfTriggerFileNamePatternFormFieldOptions() {
    if (!this._form) {
      return;
    }
    const excludedFieldDataTypes: Form.FieldDataTypeSelector[] = [
      Form.FieldDataTypeSelector.FORM_TABLE,
      Form.FieldDataTypeSelector.READONLY_PICTURE,
      Form.FieldDataTypeSelector.READONLY_HTML,
      Form.FieldDataTypeSelector.READONLY_TEXT,
      Form.FieldDataTypeSelector.INVOICE,
      Form.FieldDataTypeSelector.DOCUMENT,
      Form.FieldDataTypeSelector.PHOTO,
      Form.FieldDataTypeSelector.PROCESS_ORDER_STOCK_OUTTAKE,
      Form.FieldDataTypeSelector.PROCESS_ORDER_STOCK_OUTTAKE_CHECK,
      Form.FieldDataTypeSelector.PROCESS_ORDER_PACKAGING
    ];
    this._form.groups.toArray().forEach(g => {
      g.fields.toArray().forEach(f => {
        if (!excludedFieldDataTypes.includes(f.dataTypeSelector)) {
          if (f.pdfExportName) {
            const option = '{{' + f.pdfExportName + '}}';
            if (!this.pdfTriggerFileNamePatternOptions.includes(option)) {
              this.pdfTriggerFileNamePatternOptions.push(option);
            }
          }
        }
      });
    })
  }

  private loadMessageTriggerModel(trigger: Trigger.Trigger) {
    this.messageTriggerEditModel = new MessageTriggerEditModel();
    this.messageTriggerEditModel.name = trigger.name;
    const notificationEvent = TriggerUtils.notificationEvents.find((event) => event.notificationEvent === trigger.event);
    if (notificationEvent) {
      this.messageTriggerEditModel.event.push({
        id: notificationEvent.notificationEvent,
        text: this.translateService.instant(notificationEvent.stringKey)
      });
    }

    this.messageTriggerEditModel.messageTemplate.push(
      {id: trigger.message!.messageTemplate.id, text: trigger.message!.messageTemplate.name}
    );
    this.messageTriggerEditModel.ignoredWithEmptyRecipients = trigger.message!.ignoredWithEmptyRecipients;

    this.messageTriggerEditModel.sendToEveryone = trigger.message!.recipient.sendToEveryone;
    this.messageTriggerEditModel.sendToAssignee = trigger.message!.recipient.relatedPersonTypes.contains('ASSIGNEE');
    this.messageTriggerEditModel.sendToCreator = trigger.message!.recipient.relatedPersonTypes.contains('CREATOR');
    this.messageTriggerEditModel.sendToProcessCreator = trigger.message!.recipient.relatedPersonTypes.contains('PROCESS_CREATOR');
    this.messageTriggerEditModel.sendToAssigneeGroup = trigger.message!.recipient.relatedPersonTypes.contains('ASSIGNEE_GROUP');
    this.messageTriggerEditModel.users = trigger.message!.recipient.userIds.toArray().map((id) => ({id: id, text: ''}));
    this.messageTriggerEditModel.userGroups = trigger.message!.recipient.userGroupIds.toArray().map((id) => ({
      id: id,
      text: ''
    }));
    this.messageTriggerEditModel.mobileApplications =
      trigger.message!.recipient.mobileApplicationIds.toArray().map((id) => ({id: id, text: ''}));
    this.messageTriggerEditModel.relatedPersonTypes =
      trigger.message!.recipient.relatedPersonTypes.toArray().map((id) => ({id: id, text: ''}));
    this.messageTriggerEditModel.sendToEveryone = trigger.message!.recipient.sendToEveryone;
    this.messageTriggerEditModel.userFormFields = trigger.message!.recipient.userFormFieldIds.toArray()
      .map(id => MultiselectForm.ImmutableFormFieldItem.lazy(id)); // WHY?! Here we create a dummy object ...

    if (this.scope === 'TASK') {
      this.messageTriggerEditModel.rules = trigger.rules.map(r => {
        const model = WorkflowTransitionRuleModel.createModel(r, this.translateService);
        return model;
      });
    }
  }

  private loadPdfTriggerModel(trigger: Trigger.Trigger) {
    this.pdfTriggerEditModel = new PdfTriggerEditModel();
    this.pdfTriggerEditModel.name = trigger.name;
    const notificationEvent = TriggerUtils.notificationEvents.find((event) => event.notificationEvent === trigger.event);
    if (notificationEvent) {
      this.pdfTriggerEditModel.event.push({
        id: notificationEvent.notificationEvent,
        text: this.translateService.instant(notificationEvent.stringKey)
      });
    }

    this.pdfTriggerEditModel.pdfTemplate.push(
      {id: trigger.pdf!.pdfTemplate.id, text: trigger.pdf!.pdfTemplate.code}
    );
    this.pdfTriggerEditModel.fileNamePattern = trigger.pdf!.namePattern;

    if (this.scope === 'TASK') {
      this.pdfTriggerEditModel.rules = trigger.rules.map(r => {
        const model = WorkflowTransitionRuleModel.createModel(r, this.translateService);
        return model;
      });
    }
  }

  private loadReceiptTriggerModel(trigger: Trigger.Trigger) {
    this.receiptTriggerEditModel = new ReceiptTriggerEditModel();
    this.receiptTriggerEditModel.name = trigger.name;
    const notificationEvent = TriggerUtils.notificationEvents.find((event) => event.notificationEvent === trigger.event);
    if (notificationEvent) {
      this.receiptTriggerEditModel.event.push({
        id: notificationEvent.notificationEvent,
        text: this.translateService.instant(notificationEvent.stringKey)
      });
    }

    this.receiptTriggerEditModel.receiptTemplate.push(
      {id: trigger.receipt!.receiptTemplate.id, text: trigger.receipt!.receiptTemplate.code}
    );
    this.receiptTriggerEditModel.fileNamePattern = trigger.receipt!.namePattern;

    if (this.scope === 'TASK') {
      this.receiptTriggerEditModel.rules = trigger.rules.map(r => {
        const model = WorkflowTransitionRuleModel.createModel(r, this.translateService);
        return model;
      });
    }
  }

  private loadCloneTriggerModel(trigger: Trigger.Trigger) {
    this.cloneTriggerEditModel = new CloneTriggerEditModel();
    this.cloneTriggerEditModel.name = trigger.name;
    const notificationEvent = TriggerUtils.notificationEvents.find((event) => event.notificationEvent === trigger.event);
    if (notificationEvent) {
      this.cloneTriggerEditModel.event.push({
        id: notificationEvent.notificationEvent,
        text: this.translateService.instant(notificationEvent.stringKey)
      });
    }
    this.cloneTriggerEditModel.defaultDeadlineAdditionalDays = trigger.clone!.defaultDeadlineAdditionalDays + '';
    this.cloneTriggerEditModel.openIfPossible = trigger.clone!.openIfPossible;
    this.taskMultiselectProvider.getById(trigger.clone!.destinationTaskId).subscribe(taskItem => {
      this.cloneTriggerEditModel.destinationTask = [taskItem];
    })

    if (this.scope === 'TASK') {
      this.cloneTriggerEditModel.rules = trigger.rules.map(r => {
        const model = WorkflowTransitionRuleModel.createModel(r, this.translateService);
        return model;
      });
      this.cloneTriggerEditModel.cloneFields = this.bulkCloneFields.filter(f => trigger.clone!.cloneFields.includes(f.id));
      this.cloneTriggerEditModel.cloneFormFields = trigger.clone!.cloneFormFields
        ? trigger.clone!.cloneFormFields.toArray()
          .map(id => MultiselectForm.ImmutableFormFieldItem.lazy(id)) // WHY?! Here we create a dummy object ...
        : [];
    }
  }

  createTrigger() {
    switch (this.triggerType) {
      case 'EMAIL':
        this.saveEmailTrigger();
        break;
      case 'MESSAGE':
        this.saveMessageTrigger();
        break;
      case 'PDF':
        this.savePdfTrigger();
        break;
      case 'RECEIPT':
        this.saveReceiptTrigger();
        break;
      case 'CLONE':
        this.saveCloneTrigger();
        break;
    }
  }

  saveEmailTrigger() {
    if (!this.emailFormGroup.valid) {
      this.emailFormGroup.get('name')!.markAsTouched();
      this.emailFormGroup.get('event')!.markAsTouched();
      this.emailFormGroup.get('emailTemplate')!.markAsTouched();
      return;
    }
    this.getEmailSaveRequest().subscribe(() => {
        this.closeTriggerEditDialog();
        this.onTriggerCreated.emit();
      },
      (error: any) => {
        const res = ObservableErrorResourceParser.parseError(error);
        this.triggerFieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
      });
  }

  getEmailSaveRequest(): Observable<EmptyMessage> {
    const assignee: RelatedPersonType = 'ASSIGNEE';
    const pdfTriggerIds = this.scope === 'TASK' ?
      Set.of(...this.emailTriggerEditModel.pdfTriggers.map((t) => t.id!)) : undefined;
    const transportDocumentTypes = this.scope === 'TRANSPORT' ?
      Set.of(...this.emailTriggerEditModel.transportDocumentTypes.map((t) => t.id!)) : undefined;
    const request = {
      triggerParentId: this.triggerParentId,
      name: this.emailTriggerEditModel.name,
      event: this.emailTriggerEditModel.event[0].id!,
      email: {
        ignoredWithEmptyRecipients: this.emailTriggerEditModel.ignoredWithEmptyRecipients,
        emailTemplateId: this.emailTriggerEditModel.emailTemplate[0].id!,
        recipient: {
          emailAddresses: List.of(...this.emailTriggerEditModel.emailAddresses),
          relatedPersonTypes: this.scope === 'TASK'
            ? this.emailTriggerEditModel.taskRelatedPersonTypes
            : Set.of(...this.emailTriggerEditModel.relatedPersonTypes.map((rpt) => rpt.id!)),
          userIds: Set.of(...this.emailTriggerEditModel.users.map((u) => u.id!)),
          userGroupIds: Set.of(...this.emailTriggerEditModel.userGroups.map((ug) => ug.id!)),
          emailFormFieldIds: Set.of(...this.emailTriggerEditModel.emailFormFields.map((ug) => ug.id!)),
          sendToEveryone: this.emailTriggerEditModel.sendToEveryone,
          attachInvoices: this.emailTriggerEditModel.attachInvoices,
          attachTaskAttachments: this.emailTriggerEditModel.attachTaskAttachments,
          emailAddressTypeIds: Set.of(...this.emailTriggerEditModel.emailAddressTypes.map(t => t.id!)),
          customerFormFieldEmailAddressTypes: this.emailTriggerEditModel.customerFormFieldEmailMap,
          userFormFieldIds: Set.of(...this.emailTriggerEditModel.userFormFields.map((ug) => ug.id!))
        },
        attachment: {
          pdfTriggerIds: pdfTriggerIds,
          transportDocumentTypes: transportDocumentTypes,
          documentGroupIds: Set.of(...this.emailTriggerEditModel.documentGroups.map((g) => g.id!)),
          fileDocumentIds: Set.of(...this.emailTriggerEditModel.fileDocuments.map((fd) => fd.id!)),
          attachEachShipmentDocument: this.emailTriggerEditModel.attachEachShipmentDocument ? true : undefined,
          attachInvoices: this.emailTriggerEditModel.attachInvoices,
          attachTaskAttachments: this.emailTriggerEditModel.attachTaskAttachments,
          documentFormFieldIds: Set.of(...this.emailTriggerEditModel.documentFormFields.map((ug) => ug.id!))
        }
      },
      rules: this.emailTriggerEditModel.rules.map(r => r.toRequest())
    };
    if (this.clone || !this.triggerId) {
      return this.triggerService.createTrigger(request);
    }
    const updateRequest = Object.assign({}, {id: this.triggerId}, request);
    return this.triggerService.updateTrigger(updateRequest);
  }

  saveMessageTrigger() {
    if (!this.messageFormGroup.valid) {
      this.messageFormGroup.get('name')!.markAsTouched();
      this.messageFormGroup.get('event')!.markAsTouched();
      this.messageFormGroup.get('messageTemplate')!.markAsTouched();
      return;
    }
    this.getMessageSaveRequest().subscribe(() => {
        this.closeTriggerEditDialog();
        this.onTriggerCreated.emit();
      },
      (error: any) => {
        const res = ObservableErrorResourceParser.parseError(error);
        this.triggerFieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
      });
  }

  getMessageSaveRequest(): Observable<EmptyMessage> {
    const request = {
      triggerParentId: this.triggerParentId,
      name: this.messageTriggerEditModel.name,
      event: this.messageTriggerEditModel.event[0].id!,
      message: {
        ignoredWithEmptyRecipients: this.messageTriggerEditModel.ignoredWithEmptyRecipients,
        messageTemplateId: this.messageTriggerEditModel.messageTemplate[0].id!,
        recipient: {
          relatedPersonTypes: this.scope === 'TASK'
            ? this.messageTriggerEditModel.taskRelatedPersonTypes
            : Set.of(...this.messageTriggerEditModel.relatedPersonTypes.map((rpt) => rpt.id!)),
          userIds: Set.of(...this.messageTriggerEditModel.users.map((u) => u.id!)),
          userGroupIds: Set.of(...this.messageTriggerEditModel.userGroups.map((ug) => ug.id!)),
          mobileApplicationIds: Set.of(...this.messageTriggerEditModel.mobileApplications.map((app) => app.id!)),
          sendToEveryone: this.messageTriggerEditModel.sendToEveryone,
          userFormFieldIds: Set.of(...this.messageTriggerEditModel.userFormFields.map((ug) => ug.id!))
        },
      },
      rules: this.messageTriggerEditModel.rules.map(r => r.toRequest())
    };
    if (this.clone || !this.triggerId) {
      return this.triggerService.createTrigger(request);
    }
    const updateRequest = Object.assign({}, {id: this.triggerId}, request);
    return this.triggerService.updateTrigger(updateRequest);
  }

  savePdfTrigger() {
    if (!this.pdfFormGroup.valid) {
      this.pdfFormGroup.get('name')!.markAsTouched();
      this.pdfFormGroup.get('event')!.markAsTouched();
      this.pdfFormGroup.get('pdfTemplate')!.markAsTouched();
      this.pdfFormGroup.get('fileNamePattern')!.markAsTouched();
      return;
    }
    this.getPdfSaveRequest().subscribe(() => {
        this.closeTriggerEditDialog();
        this.onTriggerCreated.emit();
      },
      (error: any) => {
        const res = ObservableErrorResourceParser.parseError(error);
        this.triggerFieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
      });
  }

  getPdfSaveRequest(): Observable<EmptyMessage> {
    const request = {
      triggerParentId: this.triggerParentId,
      name: this.pdfTriggerEditModel.name,
      event: this.pdfTriggerEditModel.event[0].id!,
      pdf: {
        pdfTemplateId: this.pdfTriggerEditModel.pdfTemplate[0].id!,
        namePattern: this.pdfTriggerEditModel.fileNamePattern
      },
      rules: this.pdfTriggerEditModel.rules.map(r => r.toRequest())
    };
    if (this.clone || !this.triggerId) {
      return this.triggerService.createTrigger(request);
    }
    const updateRequest = Object.assign({}, {id: this.triggerId}, request);
    return this.triggerService.updateTrigger(updateRequest);
  }

  saveReceiptTrigger() {
    if (!this.receiptFormGroup.valid) {
      this.receiptFormGroup.get('name')!.markAsTouched();
      this.receiptFormGroup.get('event')!.markAsTouched();
      this.receiptFormGroup.get('receiptTemplate')!.markAsTouched();
      this.receiptFormGroup.get('fileNamePattern')!.markAsTouched();
      return;
    }
    this.getReceiptSaveRequest().subscribe(() => {
        this.closeTriggerEditDialog();
        this.onTriggerCreated.emit();
      },
      (error: any) => {
        const res = ObservableErrorResourceParser.parseError(error);
        this.triggerFieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
      });
  }

  getReceiptSaveRequest(): Observable<EmptyMessage> {
    const request = {
      triggerParentId: this.triggerParentId,
      name: this.receiptTriggerEditModel.name,
      event: this.receiptTriggerEditModel.event[0].id!,
      receipt: {
        receiptTemplateId: this.receiptTriggerEditModel.receiptTemplate[0].id!,
        namePattern: this.receiptTriggerEditModel.fileNamePattern
      },
      rules: this.receiptTriggerEditModel.rules.map(r => r.toRequest())
    };
    if (this.clone || !this.triggerId) {
      return this.triggerService.createTrigger(request);
    }
    const updateRequest = Object.assign({}, {id: this.triggerId}, request);
    return this.triggerService.updateTrigger(updateRequest);
  }

  saveCloneTrigger() {
    this.cloneFormGroup.get('cloneFormFields')!.updateValueAndValidity();
    if (!this.cloneFormGroup.valid) {
      this.cloneFormGroup.get('name')!.markAsTouched();
      this.cloneFormGroup.get('event')!.markAsTouched();
      this.cloneFormGroup.get('defaultDeadlineAdditionalDays')!.markAsTouched();
      this.cloneFormGroup.get('destinationTask')!.markAsTouched();
      this.cloneFormGroup.get('cloneFields')!.markAsTouched();
      this.cloneFormGroup.get('cloneFormFields')!.markAsTouched();
      return;
    }
    this.getCloneSaveRequest().subscribe(() => {
        this.closeTriggerEditDialog();
        this.onTriggerCreated.emit();
      },
      (error: any) => {
        const res = ObservableErrorResourceParser.parseError(error);
        this.triggerFieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
      });
  }

  getCloneSaveRequest(): Observable<EmptyMessage> {
    const request = {
      triggerParentId: this.triggerParentId,
      name: this.cloneTriggerEditModel.name,
      event: this.cloneTriggerEditModel.event[0].id!,
      clone: {
        openIfPossible: this.cloneTriggerEditModel.openIfPossible,
        defaultDeadlineAdditionalDays: +this.cloneTriggerEditModel.defaultDeadlineAdditionalDays,
        destinationTaskId: this.cloneTriggerEditModel.destinationTask[0]!.id,
        cloneFields: Set.of(...this.cloneTriggerEditModel.cloneFields.map(f => f.id)),
        cloneFormFields:
          this.cloneFormFieldsVisible()
            ? Set.of(...this.cloneTriggerEditModel.cloneFormFields.map(f => f.id!))
            : undefined
      },
      rules: this.cloneTriggerEditModel.rules.map(r => r.toRequest())
    };
    if (this.clone || !this.triggerId) {
      return this.triggerService.createTrigger(request);
    }
    const updateRequest = Object.assign({}, {id: this.triggerId}, request);
    return this.triggerService.updateTrigger(updateRequest);
  }

  onNotificationEventChanged(notificationEvent: OptionItem<TriggerUtils.NotificationEvent>[]) {
    if (notificationEvent.length > 0 && notificationEvent[0].id !== null) {
      this.loadRelatedPersonTypes(notificationEvent[0].id!);
      return;
    }
    this.loadRelatedPersonTypes();
  }

  isShipmentRelatedEvent(notificationEvent: OptionItem<TriggerUtils.NotificationEvent>[]): boolean {
    if (notificationEvent.length > 0 && notificationEvent[0].id !== null &&
      shipmentRelatedNotificationEvents.find(e => e === notificationEvent[0].id!)) {
      return true;
    }
    return false;
  }

  removeFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.triggerFieldErrors, fieldError);
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add email address
    if ((value || '').trim()) {
      if (!EmailAddresses.parse(value).isValid()) {
        this.toasterService.pop({
          timeout: UiConstants.ToastTimeoutLong,
          type: UiConstants.toastTypeError,
          title: this.translateService.instant(StringKey.COMMON_VALIDATION_ERROR),
          body: this.translateService.instant(StringKey.COMMON_VALIDATION_MESSAGE_EMAIL)
        });
        return;
      }
      this.emailTriggerEditModel.emailAddresses.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(emailAddress: string): void {
    const index = this.emailTriggerEditModel.emailAddresses.indexOf(emailAddress);

    if (index >= 0) {
      this.emailTriggerEditModel.emailAddresses.splice(index, 1);
    }
  }

  newFormFieldRule() {
    this.model.addRuleModel = new WorkflowTransitionFormFieldRuleModel(this.translateService);
  }

  newTaskRule() {
    this.model.addRuleModel = new WorkflowTransitionTaskRuleModel(this.translateService);
  }

  ruleCreateSubmitted(result: boolean) {
    if (result) {
      this.model.rules.push(this.model.addRuleModel!);
    }
    this.model.addRuleModel = undefined;
  }

  editRule(index: number) {
    this.model.rules.forEach((rule, i) => {
      rule.editing = i === index;
    });
  }

  deleteRule(index: number) {
    this.model.rules.splice(index, 1);
    if (index === 0 && this.model.rules.length > 0) {
      this.model.rules[0].operator = undefined;
      this.model.rules[0].refreshName();
    }
  }

  addCustomerFormFieldEmailTypes() {
    const customerControls = <FormArray>this.emailFormGroup.get('customerFormFieldEmailTypes.values');
    const c = this.emailTriggerEditModel.addCustomerFormFieldEmailTypes();
    const cc = this.formBuilder.control({value: c._formField},
      [Validators.required, AppValidators.validateEnabledItems]);
    c.helper = this.createMultiselectCustomerFormFieldLoadingHelper(cc, c);
    customerControls.push(this.formBuilder.group({
      customerFormField: cc,
      emailAddressTypes: this.formBuilder.control({value: c.emailAddressTypes}, [Validators.required]),
    }));
    c.helper?.loadSearch();
  }

  removeCustomerFormFieldEmailTypes(i: number) {
    const customerControls = <FormArray>this.emailFormGroup.get('customerFormFieldEmailTypes.values');
    customerControls.removeAt(i);
    this.emailTriggerEditModel.removeCustomerFormFieldEmailTypes(i);
  }

  isCustomerFieldControlInvalid(i: number, formControlName: string): boolean {
    const c = (<FormGroup>(<FormArray>this.emailFormGroup.get('customerFormFieldEmailTypes.values')).at(i)).controls[formControlName];
    return c.touched && !c.valid && (c.errors && c.errors['hasDisabledItem']);
  }
}

class TriggerEditModel {
  name: string = '';
  event: OptionItem<TriggerUtils.NotificationEvent>[] = [];

  addRuleModel?: WorkflowTransitionRuleModel;
  rules: WorkflowTransitionRuleModel[] = [];
}

class PdfTriggerEditModel extends TriggerEditModel {

  // base data
  pdfTemplate: OptionItem<number>[] = [];
  fileNamePattern: string = '';

}

class ReceiptTriggerEditModel extends TriggerEditModel {

  // base data
  receiptTemplate: OptionItem<number>[] = [];
  fileNamePattern: string = '';

}

class EmailTriggerEditModel extends TriggerEditModel {

  // base data
  emailTemplate: OptionItem<number>[] = [];
  ignoredWithEmptyRecipients: boolean = true;

  // attachments
  pdfTriggers: OptionItem<number>[] = [];
  transportDocumentTypes: OptionItem<TransportDocumentType>[] = [];
  fileDocuments: OptionItem<number>[] = [];
  documentGroups: OptionItem<number>[] = [];
  attachInvoices: boolean = false;
  attachTaskAttachments: boolean = false;
  attachEachShipmentDocument: boolean = false;

  // email recipient
  sendToEveryone: boolean = false;
  sendToAssignee: boolean = false;
  sendToCreator: boolean = false;
  sendToProcessCreator: boolean = false;
  sendToAssigneeGroup: boolean = false;
  users: OptionItem<number>[] = [];
  userGroups: OptionItem<number>[] = [];
  emailAddresses: string[] = [];
  relatedPersonTypes: OptionItem<TriggerUtils.RelatedPersonType>[] = [];
  emailFormFields: MultiselectForm.ImmutableFormFieldItem[] = [];
  documentFormFields: MultiselectForm.ImmutableFormFieldItem[] = [];
  userFormFields: MultiselectForm.ImmutableFormFieldItem[] = [];
  emailAddressTypes: OptionItem<number>[] = [];
  customerFormFieldEmailTypes: CustomerFormFieldEmailTypeModel[] = [];

  get taskRelatedPersonTypes(): Set<TriggerUtils.RelatedPersonType> {
    const ret: TriggerUtils.RelatedPersonType[] = [];
    if (this.sendToAssignee) {
      ret.push('ASSIGNEE');
    }
    if (this.sendToCreator) {
      ret.push('CREATOR');
    }
    if (this.sendToProcessCreator) {
      ret.push('PROCESS_CREATOR');
    }
    if (this.sendToAssigneeGroup) {
      ret.push('ASSIGNEE_GROUP');
    }
    return Set.of(...ret);
  }

  get customerFormFieldEmailMap(): ImmutableMap<number, Set<number>> {
    const arr: any[] = [];
    this.customerFormFieldEmailTypes.forEach((value) => {
      arr.push([value.formField!.id, Set.of(...value.emailAddressTypes.map(e => e.id!))]);
    });
    return ImmutableMap(arr);
  }

  removeCustomerFormFieldEmailTypes(i: number) {
    this.customerFormFieldEmailTypes.splice(i, 1);
  }

  getCustomerFormFieldEmailTypes(i: number) {
    return this.customerFormFieldEmailTypes[i];
  }

  addCustomerFormFieldEmailTypes(): CustomerFormFieldEmailTypeModel {
    const c = new CustomerFormFieldEmailTypeModel();
    this.customerFormFieldEmailTypes.push(c);
    return c;
  }
}

class CustomerFormFieldEmailTypeModel {

  _formField: MultiselectForm.ImmutableFormFieldItem[] = [];
  customerFormFields: MultiselectForm.ImmutableFormFieldItem[] = []; // data
  emailAddressTypes: OptionItem<number>[] = [];
  helper: Multiselect.LoadingHelper<MultiselectForm.ImmutableFormFieldItem> | undefined;

  get formField(): MultiselectForm.ImmutableFormFieldItem | undefined {
    return this._formField.length > 0 ? this._formField[0] : undefined;
  }

}

class MessageTriggerEditModel extends TriggerEditModel {

  // base data
  messageTemplate: OptionItem<number>[] = [];
  ignoredWithEmptyRecipients: boolean = false;

  // message recipient
  sendToEveryone: boolean = false;
  sendToAssignee: boolean = false;
  sendToCreator: boolean = false;
  sendToProcessCreator: boolean = false;
  sendToAssigneeGroup: boolean = false;
  users: OptionItem<number>[] = [];
  userGroups: OptionItem<number>[] = [];
  mobileApplications: OptionItem<number>[] = [];
  relatedPersonTypes: OptionItem<TriggerUtils.RelatedPersonType>[] = [];
  userFormFields: MultiselectForm.ImmutableFormFieldItem[] = [];

  get taskRelatedPersonTypes(): Set<TriggerUtils.RelatedPersonType> {
    const ret: TriggerUtils.RelatedPersonType[] = [];
    if (this.sendToAssignee) {
      ret.push('ASSIGNEE');
    }
    if (this.sendToAssigneeGroup) {
      ret.push('ASSIGNEE_GROUP');
    }
    if (this.sendToCreator) {
      ret.push('CREATOR');
    }
    if (this.sendToProcessCreator) {
      ret.push('PROCESS_CREATOR');
    }
    return Set.of(...ret);
  }
}

class CloneTriggerEditModel extends TriggerEditModel {

  defaultDeadlineAdditionalDays: string = '';
  destinationTask: TaskMultiselectOptionItem[] = [];
  cloneFields: MultiselectOptionItem<TaskRecordBulkCloneField>[] = [];
  cloneFormFields: MultiselectForm.ImmutableFormFieldItem[] = [];
  openIfPossible: boolean = false;

}

interface TriggerFieldErrorMap {
  name?: FieldError;
}
