/* eslint-disable */
import { Component, ViewChild, } from '@angular/core';
import {
  FormEditFieldCreateDialogView,
  FormEditFieldDialogContext,
  FormEditFieldUpdateDialogView,
  FormGroupModel,
  FormModel,
} from '../../../../../../../util/form/form-utils';
import { InputMask } from '../../../../../../../util/input-masks';
import { Form } from '../../../../../../../lib/form/form.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FieldWidthType } from '../../../../../../../util/form/form-field-width-type';
import { FieldValidationError, FormRef, LocalFormGroupValidationErrors, } from '../../../../../../../lib/util/services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  FieldValidationType,
  FormFieldValidationTypeModel,
} from '../../../../../../../util/form/form-field-validation-type';
import { FieldInputCapitalizationMode } from '../../../../../../../util/form/form-field-input-capitalization-mode';
import { Models } from '../../../../../../../util/model-utils';
import { UiConstants } from '../../../../../../../util/core-utils';
import { AppValidators } from '../../../../../../../util/app-validators';

/* eslint-enable */

@Component({
  selector: 'app-form-edit-create-update-string-field-dialog',
  templateUrl: 'form-edit-create-update-string-field-dialog.component.html',
  styleUrls: ['form-edit-create-update-string-field-dialog.component.scss'],
})
export class FormEditCreateUpdateStringFieldDialogComponent
  implements FormEditFieldCreateDialogView, FormEditFieldUpdateDialogView, FormRef {

  public readonly selector = Form.FieldDataTypeSelector.STRING;
  public readonly createTitle = 'FORM_ITEM_CREATE_TEXT_TITLE';
  public readonly updateTitle = 'FORM_ITEM_UPDATE_TEXT_TITLE';
  public readonly cloneTitle = 'FORM_ITEM_CLONE_TEXT_TITLE';

  InputMask = InputMask;
  Form = Form;
  UiConstants = UiConstants;
  Models = Models;

  @ViewChild('fieldDialog', { static: true })
  dialogComponent: ModalDirective;

  formGroup: FormGroup;

  visible: boolean = false;
  fieldModel: Model = new Model();
  dialogTitleDictionaryKey: string = '';
  latestApiExportName = '';

  fieldWidthTypes: FieldWidthType[] = FieldWidthType.VALUES.toArray();
  fieldValidationTypes: Form.FormFieldValidationType[] = FieldValidationType.VALUES.toArray();

  fieldInputCapitalizationModes: Form.FormFieldInputCapitalizeMode[] = FieldInputCapitalizationMode.VALUES.toArray();

  private fieldErrors: FieldValidationError<Form.FieldValidatedField> =
    FieldValidationError.empty<Form.FieldValidatedField>();

  private formGroupValidationErrors: LocalFormGroupValidationErrors;

  private context: FormEditFieldDialogContext;
  private group: FormGroupModel;
  private field?: Form.Field;
  private clone?: boolean;
  private submitted: boolean = false;

  constructor(private fb: FormBuilder) {
  }

  getForm() {
    return {
      submitted: this.submitted
    };
  }

  registerContext(context: FormEditFieldDialogContext): void {
    this.context = context;
  }

  showDialog(form: FormModel, group: FormGroupModel, field?: Form.Field, clone?: boolean): void {
    this.formGroup = this.createFormGroup(this.fb);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(this, this.formGroup);
    this.latestApiExportName = '';
    this.visible = true;
    this.group = group;
    this.clone = clone;
    this.dialogComponent.show();
    if (field && clone) {
      this.dialogTitleDictionaryKey = this.cloneTitle;
      this.loadField(field);
    }
    else if (field) {
      this.dialogTitleDictionaryKey = this.updateTitle;
      this.loadField(field);
    }
    else {
      this.dialogTitleDictionaryKey = this.createTitle;
    }
  }

  onModalHide(event: ModalDirective) {
    if (event.dismissReason === 'backdrop-click' || event.dismissReason === 'esc') {
      this.visible = false;
      this.submitted = false;
      this.fieldModel = new Model();
    }
  }

  closeDialog(): void {
    this.visible = false;
    this.submitted = false;
    this.dialogComponent.hide();
    this.fieldModel = new Model();
  }

  saveField() {
    this.submitted = true;
    this.formGroup.updateValueAndValidity();
    if (this.formGroup.invalid) {
      return;
    }
    if (this.field && this.clone) {
      this.createField();
    }
    else if (this.field) {
      this.updateField(this.field);
    }
    else {
      this.createField();
    }
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  hasLocalFormError(errorCode: string): boolean {
    return this.formGroupValidationErrors.hasFormError(errorCode);
  }

  hasFieldError(field?: Form.FieldValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: Form.FieldValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: Form.FieldValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  getFieldValidationTypeDictionaryKey(vt: Form.FormFieldValidationType): string {
    return FieldValidationType.getFieldValidationTypeDictionaryKey(vt);
  }

  getFieldInputCapitalizeModeKey(icm: Form.FormFieldInputCapitalizeMode): string {
    return FieldInputCapitalizationMode.getFieldInputCapitalizeModeDictionaryKey(icm);
  }

  onAdminFormFieldValidationTypeChanged() {
    FieldValidationType.onAdminFormFieldValidationTypeChanged(this.fieldModel);
  }

  onMobileFormFieldValidationTypeChanged() {
    FieldValidationType.onMobileFormFieldValidationTypeChanged(this.fieldModel);
  }

  private parseNumber(text: string): number | undefined {
    return text.length > 0 ? Number(text) : undefined;
  }

  private loadField(field: Form.Field) {
    this.field = field;
    const attrs = field.dataType.stringAttributes!;
    const minLength = attrs.minLength;
    const maxLength = attrs.maxLength;
    const placeholder = attrs.hint;
    const defaultValue = attrs.defaultValue;
    const fieldWidth = FieldWidthType.fromPercentValue(field.formFieldWidthPercent);
    this.fieldModel.title = field.title;
    this.fieldModel.description = field.description ? field.description : '';
    this.fieldModel.hint = field.hint ? field.hint : '';
    this.fieldModel.apiExportName = field.apiExportName ? field.apiExportName : '';
    this.fieldModel.pdfExportName = field.pdfExportName ? field.pdfExportName : '';
    this.fieldModel.minLengthText = '' + minLength;
    this.fieldModel.maxLengthText = '' + maxLength;
    this.fieldModel.defaultValue = defaultValue ? defaultValue : '';
    this.fieldModel.fieldWidthType = fieldWidth ? fieldWidth : FieldWidthType.DEFAULT;
    this.fieldModel.displayOnNewRow = field.displayOnNewRow;
    this.fieldModel.placeholder = placeholder ? placeholder : '';
    this.fieldModel.multiline = attrs.multiLine ? attrs.multiLine : false ;
    this.fieldModel.adminVisibleOnFormDetail = field.admin.visibleOnFormDetail;
    this.fieldModel.mobileVisibleOnFormDetail = field.mobile.visibleOnFormDetail;
    this.fieldModel.mobileVisibleOnMasterDetail = field.mobile.visibleOnMasterDetail;
    this.fieldModel.adminFormFieldValidationType = field.admin.validationType;
    this.fieldModel.helpdeskFormFieldValidationType = field.helpdesk.validationType;
    this.fieldModel.mobileFormFieldValidationType = field.mobile.validationType;
    this.fieldModel.inputCapitalizeMode = attrs.inputCapitalizeMode;
    this.fieldModel.showOnReceipt = field.showOnReceipt;
  }

  private createField() {
    this.context.formService.createField({
      parentId: this.context.parentId,
      groupId: this.group.groupId,
      title: this.fieldModel.title,
      description: this.fieldModel.description,
      hint: this.fieldModel.hint,
      pdfExportName: this.fieldModel.pdfExportName,
      apiExportName: this.fieldModel.apiExportName,
      dataTypeSelector: this.selector,
      dataType: this.getDataTypeRequest(),
      formFieldWidthPercent: this.fieldModel.fieldWidthType.percentValue,
      displayOnNewRow: this.fieldModel.displayOnNewRow,
      admin: {
        visibleOnFormDetail: this.fieldModel.adminVisibleOnFormDetail,
        validationType: this.fieldModel.adminFormFieldValidationType
      },
      helpdesk: {
        validationType: this.fieldModel.helpdeskFormFieldValidationType
      },
      mobile: {
        visibleOnFormDetail: this.fieldModel.mobileVisibleOnFormDetail,
        visibleOnMasterDetail: this.fieldModel.mobileVisibleOnMasterDetail,
        validationType: this.fieldModel.mobileFormFieldValidationType
      },
      showOnReceipt: this.fieldModel.showOnReceipt
    }).subscribe(
      (result) => {
        this.context.formView.reloadForm();
        this.closeDialog();
      },
      (error) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withFormRef(this);
        }
      }
    );
  }

  private updateField(field: Form.Field) {
    this.context.formService.updateField({
      parentId: this.context.parentId,
      groupId: this.group.groupId,
      fieldId: field.fieldId,
      title: this.fieldModel.title,
      description: this.fieldModel.description,
      hint: this.fieldModel.hint,
      pdfExportName: this.fieldModel.pdfExportName,
      apiExportName: this.fieldModel.apiExportName,
      dataTypeSelector: this.selector,
      dataType: this.getDataTypeRequest(),
      formFieldWidthPercent: this.fieldModel.fieldWidthType.percentValue,
      displayOnNewRow: this.fieldModel.displayOnNewRow,
      admin: {
        visibleOnFormDetail: this.fieldModel.adminVisibleOnFormDetail,
        validationType: this.fieldModel.adminFormFieldValidationType
      },
      helpdesk: {
        validationType: this.fieldModel.helpdeskFormFieldValidationType
      },
      mobile: {
        visibleOnFormDetail: this.fieldModel.mobileVisibleOnFormDetail,
        visibleOnMasterDetail: this.fieldModel.mobileVisibleOnMasterDetail,
        validationType: this.fieldModel.mobileFormFieldValidationType
      },
      showOnReceipt: this.fieldModel.showOnReceipt
    }).subscribe(
      (result) => {
        this.context.formView.reloadForm();
        this.closeDialog();
      },
      (error) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withFormRef(this);
        }
      }
    );
  }

  private getDataTypeRequest(): Form.FieldDataType {
    return {
      stringAttributes: {
        inputCapitalizeMode: this.fieldModel.inputCapitalizeMode,
        hint: this.fieldModel.placeholder,
        minLength: this.parseNumber(this.fieldModel.minLengthText),
        maxLength: this.parseNumber(this.fieldModel.maxLengthText),
        defaultValue: this.fieldModel.defaultValue,
        multiLine: this.fieldModel.multiline
      }
    };
  }

  onTitleBlur() {
    const normalizedText = Models.normalizeText(this.fieldModel.title);
    if (this.fieldModel.apiExportName === '') {
      this.fieldModel.apiExportName = normalizedText;
    }
    if (this.fieldModel.pdfExportName === '') {
      this.fieldModel.pdfExportName = normalizedText;
    }
  }

  private createFormGroup(fb: FormBuilder): FormGroup {
    return fb.group(
      {
        title: fb.control(
          { value: this.fieldModel.title },
          [
            Validators.required,
          ]
        ),
        apiExportName: fb.control(
          { value: this.fieldModel.apiExportName },
          [
            Validators.required,
          ]
        ),
        minLength: fb.control(
          { value: this.fieldModel.minLengthText },
          [
            Validators.min(0),
            AppValidators.optMax(() => {
              return this.parseNumber(this.fieldModel.maxLengthText);
            })
          ]
        ),
        maxLength: fb.control(
          { value: this.fieldModel.maxLengthText },
          [
            Validators.min(1),
            AppValidators.optMin(() => {
              return this.parseNumber(this.fieldModel.minLengthText);
            })
          ]
        ),
        defaultValue: fb.control(
          { value: this.fieldModel.defaultValue },
          [
            AppValidators.optMinLength(() => {
              return this.parseNumber(this.fieldModel.minLengthText);
            }),
            AppValidators.optMaxLength(() => {
              return this.parseNumber(this.fieldModel.maxLengthText);
            })
          ]
        )
      });
  }
}

export class Model implements FormFieldValidationTypeModel {
  title: string = '';
  description: string = '';
  apiExportName: string = '';
  pdfExportName: string = '';
  minLengthText: string = '';
  maxLengthText: string = '';
  defaultValue: string = '';
  fieldWidthType: FieldWidthType = FieldWidthType.DEFAULT;
  displayOnNewRow: boolean = false;
  placeholder: string = '';
  hint: string = '';
  multiline: boolean = false;
  adminVisibleOnFormDetail: boolean = true;
  mobileVisibleOnFormDetail: boolean = true;
  mobileVisibleOnMasterDetail: boolean = false;
  adminFormFieldValidationType: Form.FormFieldValidationType = Form.FormFieldValidationType.OPTIONAL;
  helpdeskFormFieldValidationType: Form.FormFieldValidationType = Form.FormFieldValidationType.OPTIONAL;
  mobileFormFieldValidationType: Form.FormFieldValidationType = Form.FormFieldValidationType.OPTIONAL;
  inputCapitalizeMode: Form.FormFieldInputCapitalizeMode = Form.FormFieldInputCapitalizeMode.NONE;
  showOnReceipt?: boolean = false;
}
