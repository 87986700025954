/* eslint-disable */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../lib/auth.service';
import { MultiselectOptionItem, QueryFieldModel, SelectUtils, UiConstants } from '../../../util/core-utils';
import { RightModel } from '../../../app.rights';
import { RightResolver, RightService } from '../../../lib/right.service';
import {
  FieldValidationError,
  ForwardingNgFormRef,
  LocalFormGroupValidationErrors,
  OrderType,
  QueryResult
} from '../../../lib/util/services';
import { Set } from 'immutable';
import { StateName } from '../../../app.state-names';
import { UIRouter } from '@uirouter/angular';
import { OrderDisplayStateObject, OrderSearchModel } from '../../../util/order-utils';
import { OrderSearch, OrderSearchService } from '../../../lib/order/order-search.service';
import { combineLatest, Observable } from 'rxjs';
import { Models } from '../../../util/model-utils';
import { Strings } from '../../../lib/util/strings';
import { StringKey } from '../../../app.string-keys';
import { Address, AddressModel } from '../../../lib/address';
import { ConfigurationResource, ConfigurationService } from '../../../lib/core-ext/configuration.service';
import { UploadErrorLocalizer } from '../../../util/upload-error-localizer';
import { FileUploadDialogComponent } from '../../../shared/file-upload/dialog/file-upload-dialog.component';
import { OffsetDateTime } from '../../../lib/util/dates';
import { Order, OrderService } from '../../../lib/order/order.service';
import { LegacyWorkflow, LegacyWorkflowService } from '../../../lib/legacy-workflow/legacy-workflow-service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToasterService } from '../../../fork/angular2-toaster/angular2-toaster';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { LegacyProcess, LegacyProcessService } from '../../../lib/legacy-process/legacy-process.service';
import { ErrorMessage, ErrorMessageService } from '../../../lib/error-message-parser.service';
import { saveAs } from 'file-saver';
import { UploadResponse } from '../../../shared/file-upload/flat/file-upload.component';
import * as b64toBlob from 'b64-to-blob';
import { UserData, UserDataLoader, UserDataLoaderPermissionDeniedStrategy } from '../../../lib/user-data-loader';
import { Company, CompanyService } from '../../../lib/company/company.service';
import { UserCompany } from '../../../lib/user.service';
import { DownloadedFile } from '../../../lib/util/downloaded-files';
import { InputMask } from '../../../util/input-masks';
import { map } from 'rxjs/operators';
import { Icon } from '../../../lib/task/icon.service';
import { Angular2Multiselects } from '../../../util/multiselect';
import PostalAddressModelType = AddressModel.PostalAddressModelType;
import ProcessStateObject = LegacyProcess.LegacyProcessStateObject;
import OrderWorkflow = Order.OrderWorkflow;

/* eslint-enable */

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent implements OnInit, OnDestroy {
  InputMask = InputMask;
  Order = Order;
  Models = Models;
  PostalAddressModelType = PostalAddressModelType;
  Address = Address;
  SelectUtils = SelectUtils;
  ConfigurationService = ConfigurationService;
  UiConstants = UiConstants;
  orderDisplayStates: MultiselectOptionItem<Order.OrderLogicState>[] = OrderDisplayStateObject.multiselectValues();

  @ViewChild('orderImportDialog', { static: true }) orderImportDialog: FileUploadDialogComponent;
  @ViewChild('orderReturnImportDialog', { static: true }) orderReturnImportDialog: FileUploadDialogComponent;
  @ViewChild('statImportDialog', { static: true }) statImportDialog: FileUploadDialogComponent;
  @ViewChild('processCreateDialog', { static: true }) processCreateDialog: ModalDirective;
  @ViewChild('f') form?: NgForm;
  @ViewChild('addToProcessDialog', { static: true }) addToProcessDialog: ModalDirective;
  @ViewChild('companySelectDialog', { static: true }) companySelectDialog: ModalDirective;

  queryModel: QueryFieldModel<Order.OrderField> = new QueryFieldModel(Order.OrderField.ID, OrderType.DESC);
  orderList: Order[] = [];
  companyIds: number[] = [];
  users: UserData[] = [];
  companyList: CompanyItem[] = [];
  processCreateModel: LegacyProcessCreateModel = new LegacyProcessCreateModel();
  workflowList: WorkflowItem[] = [];
  processCreateDialogVisible: boolean = false;
  companySelectDialogVisible: boolean = false;
  addToProcessDialogVisible: boolean = false;
  draftAvailable: boolean = false;

  addToProcessModel: AddToProcessModel = new AddToProcessModel();
  addToProcessSubmitted: boolean = false;
  processChosen: boolean = false;
  processList: ProcessItem[] = [];
  orderCreateModel: OrderCreateModel = new OrderCreateModel([]);

  searchModel: OrderSearchModel = new OrderSearchModel();
  storedSearchData: OrderSearch.SearchDataResult;
  showSearch: boolean = false;
  dropdownSettings?: Angular2Multiselects.Settings;
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  mplStatImportPath: string = '/orders/mpl-final-status-document-import';
  orderImportPath: string = '/orders/import-xls';
  orderReturnImportPath: string = '/orders/mpl-return-document-import/base64';

  rightModel: RightModel = RightModel.empty();
  config: ConfigurationResource.Configuration;

  formGroup: FormGroup;
  private submitted: boolean = false;
  private fieldErrors: FieldValidationError<Order.ProcessValidatedField> =
    FieldValidationError.empty<Order.ProcessValidatedField>();
  private formGroupValidationErrors: LocalFormGroupValidationErrors;
  private currentUser: UserData;

  constructor(
    private translateService: TranslateService,
    private authService: AuthService,
    private processService: LegacyProcessService,
    private orderService: OrderService,
    private configService: ConfigurationService,
    private uploadErrorLocalizer: UploadErrorLocalizer,
    private orderSearchService: OrderSearchService,
    private uiRouter: UIRouter,
    private toasterService: ToasterService,
    private legacyWorkflowService: LegacyWorkflowService,
    private companyService: CompanyService,
    private userDataLoader: UserDataLoader,
    private rightService: RightService,
    private errorMessageService: ErrorMessageService,
    fb: FormBuilder) {
    this.loadConfig();
    this.formGroup = this.createFormGroup(fb);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(this.createForwardingHtmlForm(), this.formGroup);
  }

  ngOnInit() {
    this.translateService.get('MENU_NAVBAR_ORDERS').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.initDropDown();
    this.loadSearch(() => {
      this.showSearch = !this.searchModel.isEmpty();
      this.loadList();
    });
    this.loadRightModels(() => {
      if (this.rightModel.legacyWorkflowRead.hasRight()) {
        this.loadWorkflows();
      }
      if (this.rightModel.legacyProcessRead.hasRight()) {
        this.loadProcesses();
      }
      if (this.rightModel.userRead.hasRight()) {
        this.loadUsers();
      }
    });
  }

  initDropDown() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .translate(true)
      .build();
  }

  private loadSearch(completion: () => void) {
    const obs: Observable<SearchLoadResult> = combineLatest(
      this.orderSearchService.getSearchData({}),
      (storedSearchData: OrderSearch.SearchDataResult) => {
        const result: SearchLoadResult = {
          storedSearchData: storedSearchData
        };
        return result;
      }
    );
    obs.subscribe(
      (result: SearchLoadResult) => {
        this.storedSearchData = result.storedSearchData;
        this.postInitSearch(result.storedSearchData);
        completion();
      }
    );
  }

  private postInitSearch(storedSearchData: OrderSearch.SearchDataResult) {
    this.queryModel.itemsPerPage = storedSearchData.searchData.itemsPerPage;
    this.queryModel.currentPage = storedSearchData.searchData.pageNumber;
    this.queryModel.setOrder(storedSearchData.searchData.order);
    this.searchModel.id = storedSearchData.searchData.id;
    this.searchModel.externalId = storedSearchData.searchData.externalId;
    this.searchModel.logicState = storedSearchData.searchData.logicState;
    this.searchModel.ownerCompanyName = storedSearchData.searchData.ownerCompanyName;
    this.searchModel.shipmentTrackingNumber = storedSearchData.searchData.shipmentTrackingNumber;
    this.searchModel.shippingState = storedSearchData.searchData.shippingState;
    this.searchModel.weightInGramFrom = storedSearchData.searchData.weightInGramFrom;
    this.searchModel.weightInGramTo = storedSearchData.searchData.weightInGramTo;
    this.searchModel.cashOnDeliveryPriceFrom = storedSearchData.searchData.cashOnDeliveryPriceFrom;
    this.searchModel.cashOnDeliveryPriceTo = storedSearchData.searchData.cashOnDeliveryPriceTo;
    this.searchModel.recipientName = storedSearchData.searchData.recipientName;
    this.searchModel.recipientAddress = storedSearchData.searchData.recipientAddress;
    this.searchModel.submitTimeFrom = Models.localDateToNgbDate(storedSearchData.searchData.submitTimeFrom);
    this.searchModel.submitTimeTo = Models.localDateToNgbDate(storedSearchData.searchData.submitTimeTo);
    this.searchModel.returnTimeFrom = Models.localDateToNgbDate(storedSearchData.searchData.returnTimeFrom);
    this.searchModel.returnTimeTo = Models.localDateToNgbDate(storedSearchData.searchData.returnTimeTo);
    this.searchModel.processDeadlineFrom = Models.localDateToNgbDate(storedSearchData.searchData.processDeadlineFrom);
    this.searchModel.processDeadlineTo = Models.localDateToNgbDate(storedSearchData.searchData.processDeadlineTo);
    this.searchModel.minimumIdenticalItemCount = storedSearchData.searchData.minimumIdenticalItemCount;
    this.searchModel.returnReasonEmpty = storedSearchData.searchData.returnReasonEmpty ?
      storedSearchData.searchData.returnReasonEmpty : undefined;
  }

  private loadConfig() {
    this.config = this.configService.getConfiguration();
  }

  private loadRightModels(completion: () => void) {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        this.userDataLoader.loadMe(resolver.userProfile!).subscribe(me => {
          this.currentUser = me;
          completion();
        });
      }
    );
  }

  private loadWorkflows() {
    this.workflowList = [];
    this.legacyWorkflowService.query({
      disabled: false
    }).subscribe((workflows: QueryResult<LegacyWorkflow.Workflow>) => {
      workflows.items.toArray().forEach((workflow) => {
        const item: WorkflowItem = {
          name: workflow.name,
          id: workflow.workflowId
        };
        this.workflowList.push(item);
      });
    });
  }

  private loadProcesses() {
    this.processList = [];
    this.processService.query({
      state: 'OPEN',
      withOrderCount: false,
      fields: Set.of('id', 'name'),
    }).subscribe((result: QueryResult<LegacyProcess.LegacyProcess>) => {
      result.items.toArray().forEach((process) => {
        const item: ProcessItem = {
          id: process.processId,
          name: process.displayName
        };
        this.processList.push(item);
      });
    });
  }

  private loadCompanies() {
    this.companyService.query({
      id: Set.of(...this.companyIds)
    }).subscribe((result: QueryResult<Company.Company>) => {
      this.companyList = [];
      result.items.forEach((cr) => {
        if (cr) {
          this.companyList.push({
            id: cr.id,
            name: cr.name
          });
        }
      });
    });
  }

  private loadUsers() {
    this.userDataLoader.loadAllWithFields(UserDataLoaderPermissionDeniedStrategy.MISS_ALL,
      undefined, Set.of('id', 'person_name')).subscribe(
      (users: UserData[]) => {
        this.users = users;
      }
    );
  }

  createOrder() {
    const demanders = this.currentUser.companies.filter(c => c.type === 'DEMANDER');
    if (demanders.length === 1 || this.draftAvailable) {
      this.createOrderForCompany(demanders[0].id);
    }
    else if (demanders.length === 0) {
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutLong,
        type: UiConstants.toastTypeError,
        title: this.translateService.instant(StringKey.ORDER_CREATE_ERROR_TITLE),
        body: this.translateService.instant(StringKey.ORDER_CREATE_ERROR_NO_COMPANY)
      });
    }
    else {
      this.openCompanySelectDialog(demanders);
    }
  }

  createOrderForCompany(companyId: number) {
    this.orderService.create({companyId: companyId}).subscribe(
      (response) => {
        this.uiRouter.stateService.go(StateName.ORDER_EDIT, {id: response.orderId});
      }
    );
  }

  createProcess() {
    this.submitted = true;
    this.formGroup.updateValueAndValidity();
    if (this.formGroup.invalid) {
      return;
    }
    this.processService.create({
      workflowId: this.processCreateModel.workflowId!,
      usageType: this.processCreateModel.usageType,
      orderIds: this.processCreateModel.orderIds,
      name: Strings.undefinedOrNonEmpty(this.processCreateModel.name),
      externalId: Strings.undefinedOrNonEmpty(this.processCreateModel.externalId),
      description: Strings.undefinedOrNonEmpty(this.processCreateModel.description),
      deadline: Models.parseDateTimeFrom(this.processCreateModel.deadline)
    }).subscribe(
      (response) => {
        this.uiRouter.stateService.go(StateName.LEGACY_PROCESS_EDIT, {id: response.processId});
      },
      (error) => {
        const msg = this.errorMessageService.parseError(error);
        if (msg && msg.hasFieldErrors) {
          this.fieldErrors = this.parseProcessFieldErrors(msg)
            .withFormRef(this.createForwardingHtmlForm());
        }
      }
    );
  }

  addToProcess() {
    this.addToProcessSubmitted = true;
    if (this.addToProcessModel.processId) {
      this.processChosen = true;
    }
    else {
      this.processChosen = false;
      return;
    }
    this.processService.addOrders({
      processId: this.addToProcessModel.processId,
      orderIds: this.addToProcessModel.orderIds
    }).subscribe(
      () => {
        this.uiRouter.stateService.go(StateName.LEGACY_PROCESS_EDIT, {id: this.addToProcessModel.processId});
      }
    );
  }

  private getCompanyName(id: number): string {
    const filtered_records = this.companyList.filter(current_record => current_record.id === id);
    if (filtered_records === undefined || filtered_records.length === 0) {
      return '';
    }
    return filtered_records[0].name;
  }

  private getUserName(id: number): string | undefined {
    const filtered_users = this.users.filter(current_user => current_user.id === id);
    if (filtered_users === undefined || filtered_users.length === 0) {
      return '';
    }
    return filtered_users[0].person_name;
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: Order.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  private loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const orderIdSet: number[] = [];
    const orderId = Strings.undefinedOrNonEmpty(this.searchModel.id);
    if (orderId) {
      orderIdSet.push(+orderId);
    }
    this.orderService.query(this.getQueryRequest(orderIdSet, requestedPage)).subscribe(
      (result: QueryResult<Order.Order>) => {
        this.orderList = [];
        result.items.toArray().forEach((rawOrder) => {

          let recAddress: string = '';
          if (rawOrder.recipient) {
            if (rawOrder.recipient.deliveryAddress) {
              recAddress = Address.PostalAddressMapper.toString(rawOrder.recipient.deliveryAddress, this.config.postal_address_format);
            }
            else if (rawOrder.recipient.parcelCollectionPoint) {
              recAddress = rawOrder.recipient.parcelCollectionPoint.name!;
            }
          }
          let processState: ProcessStateObject | undefined;
          if (rawOrder.process) {
            processState = LegacyProcess.processStates.find(o => o.state === rawOrder.process!.state);
          }
          const item: Order = {
            orderId: rawOrder.orderId,
            externalId: rawOrder.externalId,
            orderState: rawOrder.orderState,
            state: OrderDisplayStateObject.resolve(rawOrder.logicState),
            description: Strings.optToString(rawOrder.description),
            ownerUserId: rawOrder.ownerUserId,
            ownerCompanyId: rawOrder.ownerCompanyId,
            weightInGram: rawOrder.weightInGram,
            cashOnDeliveryPrice: rawOrder.cashOnDeliveryPrice
              ? (Models.applyThousandSeparatorToDecimal(rawOrder.cashOnDeliveryPrice.amount) + ' '
                + rawOrder.cashOnDeliveryPrice.currencyCode)
              : '',
            items: rawOrder.items,
            shippingState: Strings.optToString(rawOrder.shippingState),
            shipmentTrackingNumber: Strings.optToString(rawOrder.shipmentTrackingNumber),
            creationTime: rawOrder.creationTime,
            updateTime: rawOrder.updateTime,
            submitTime: rawOrder.submitTime,
            process: rawOrder.process ? {
              id: rawOrder.process.processId,
              displayName: rawOrder.process.displayName,
              workflow: rawOrder.process.workflow,
              deadline: rawOrder.process.deadline,
              stateIcon: processState!.icon,
              stateText: processState!.stringKey
            } : undefined,
            version: rawOrder.version,
            recipientName: rawOrder.recipient ? rawOrder.recipient.name : '',
            recipientDeliveryAddress: recAddress,
            selected: false
          };
          this.orderList.push(item);
        });
        this.companyIds = [];
        this.orderList.forEach((orderItem) => {
          this.companyIds.push(orderItem.ownerCompanyId);
        });
        this.loadCompanies();
        this.queryModel.currentPage = requestedPage;
        this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
        this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
      });
    this.checkDraft();
  }

  private loadIdSet(): Observable<number[]> {
    // This query is only for updating order id array.
    const orderIdSet: number[] = [];
    const orderId = Strings.undefinedOrNonEmpty(this.searchModel.id);
    if (orderId) {
      orderIdSet.push(+orderId);
    }
    if (this.searchModel.isEmpty()) {
      // TODO throw error here
    }
    return this.orderService.query({
      fields: Set.of('id'),
      orderId: orderIdSet.length > 0 ? Set.of(...orderIdSet) : undefined,
      externalId: Strings.undefinedOrNonEmpty(this.searchModel.externalId),
      orderLogicState: this.searchModel.logicState.length === 1 ? this.searchModel.logicState[0].id : undefined,
      ownerCompanyName: Strings.undefinedOrNonEmpty(this.searchModel.ownerCompanyName),
      shipmentTrackingNumber: Strings.undefinedOrNonEmpty(this.searchModel.shipmentTrackingNumber),
      shippingState: Strings.undefinedOrNonEmpty(this.searchModel.shippingState),
      weightInGramFrom: Strings.undefinedOrNonEmpty(this.searchModel.weightInGramFrom),
      weightInGramTo: Strings.undefinedOrNonEmpty(this.searchModel.weightInGramTo),
      cashOnDeliveryPriceFrom: Strings.undefinedOrNonEmpty(this.searchModel.cashOnDeliveryPriceFrom),
      cashOnDeliveryPriceTo: Strings.undefinedOrNonEmpty(this.searchModel.cashOnDeliveryPriceTo),
      recipientName: Strings.undefinedOrNonEmpty(this.searchModel.recipientName),
      recipientAddress: Strings.undefinedOrNonEmpty(this.searchModel.recipientAddress),
      submitTimeFrom: Models.parseDateTimeFrom(this.searchModel.submitTimeFrom),
      submitTimeTo: Models.parseDateTimeTo(this.searchModel.submitTimeTo),
      returnTimeFrom: Models.parseDateTimeFrom(this.searchModel.returnTimeFrom),
      returnTimeTo: Models.parseDateTimeTo(this.searchModel.returnTimeTo),
      minimumIdenticalItemCount: Models.parseNumber(this.searchModel.minimumIdenticalItemCount)
      && +this.searchModel.minimumIdenticalItemCount > 1
        ? +this.searchModel.minimumIdenticalItemCount : undefined,
      returnReasonEmpty: this.searchModel.returnReasonEmpty ? this.searchModel.returnReasonEmpty : undefined,
    }).pipe(map((result: QueryResult<Order.Order>) => {
      const fullOrderListIds: number[] = [];
      result.items.toArray().forEach((rawOrder) => {
        fullOrderListIds.push(rawOrder.orderId);
      });
      return fullOrderListIds;
    }));
  }

  private getQueryRequest(orderIdSet: number[], requestedPage?: number): Order.QueryRequest {
    const order = this.queryModel.getOrder();
    return {
      orderId: orderIdSet.length > 0 ? Set.of(...orderIdSet) : undefined,
      externalId: Strings.undefinedOrNonEmpty(this.searchModel.externalId),
      orderLogicState: this.searchModel.logicState.length === 1 ? this.searchModel.logicState[0].id : undefined,
      ownerCompanyName: Strings.undefinedOrNonEmpty(this.searchModel.ownerCompanyName),
      shipmentTrackingNumber: Strings.undefinedOrNonEmpty(this.searchModel.shipmentTrackingNumber),
      shippingState: Strings.undefinedOrNonEmpty(this.searchModel.shippingState),
      weightInGramFrom: Strings.undefinedOrNonEmpty(this.searchModel.weightInGramFrom),
      weightInGramTo: Strings.undefinedOrNonEmpty(this.searchModel.weightInGramTo),
      cashOnDeliveryPriceFrom: Strings.undefinedOrNonEmpty(this.searchModel.cashOnDeliveryPriceFrom),
      cashOnDeliveryPriceTo: Strings.undefinedOrNonEmpty(this.searchModel.cashOnDeliveryPriceTo),
      recipientName: Strings.undefinedOrNonEmpty(this.searchModel.recipientName),
      recipientAddress: Strings.undefinedOrNonEmpty(this.searchModel.recipientAddress),
      submitTimeFrom: Models.parseDateTimeFrom(this.searchModel.submitTimeFrom),
      submitTimeTo: Models.parseDateTimeTo(this.searchModel.submitTimeTo),
      returnTimeFrom: Models.parseDateTimeFrom(this.searchModel.returnTimeFrom),
      returnTimeTo: Models.parseDateTimeTo(this.searchModel.returnTimeTo),
      processDeadlineFrom: Models.parseDateTimeFrom(this.searchModel.processDeadlineFrom),
      processDeadlineTo: Models.parseDateTimeTo(this.searchModel.processDeadlineTo),
      minimumIdenticalItemCount: Models.parseNumber(this.searchModel.minimumIdenticalItemCount)
      && +this.searchModel.minimumIdenticalItemCount > 1
        ? +this.searchModel.minimumIdenticalItemCount : undefined,
      returnReasonEmpty: this.searchModel.returnReasonEmpty ? this.searchModel.returnReasonEmpty : undefined,
      orders: Set.of(order),
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined
    };
  };

  private checkDraft() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        const ownerUserId = resolver.userProfile!.id;
        this.orderService.query({
          orderState: 'DRAFT',
          ownerUserId: ownerUserId
        }).subscribe(
          (result: QueryResult<Order.Order>) => {
            if (result.items.toArray().length > 0) {
              this.draftAvailable = true;
            }
          });
      }
    );
  }

  exportXls() {
    // User id filter if present, otherwise rely on selectedIds (search will return all items if id list is empty)
    const orderIdSet: number[] = this.searchModel.id ? [+this.searchModel.id] : this.getSelectedIds();
    const request = this.getQueryRequest(orderIdSet);
    this.orderService.exportXls(request).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName('order.xlsx'));
      }
    );
  }

  onSearchClicked() {
    this.loadList();
  }

  onSearchReset() {
    this.orderSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.showSearch = true;
          this.loadList(1);
        });
      }
    );
  }

  private saveSearch() {
    const request = {
      searchData: {
        itemsPerPage: this.queryModel.itemsPerPage,
        pageNumber: this.queryModel.currentPage,
        order: this.queryModel.getOrder(),
        id: this.searchModel.id,
        externalId: this.searchModel.externalId,
        logicState: this.searchModel.logicState,
        ownerCompanyName: this.searchModel.ownerCompanyName,
        shipmentTrackingNumber: this.searchModel.shipmentTrackingNumber,
        shippingState: this.searchModel.shippingState,
        weightInGramFrom: this.searchModel.weightInGramFrom,
        weightInGramTo: this.searchModel.weightInGramTo,
        cashOnDeliveryPriceFrom: this.searchModel.cashOnDeliveryPriceFrom,
        cashOnDeliveryPriceTo: this.searchModel.cashOnDeliveryPriceTo,
        recipientName: this.searchModel.recipientName,
        recipientAddress: this.searchModel.recipientAddress,
        submitTimeFrom: Models.ngbDateToLocalDate(this.searchModel.submitTimeFrom),
        submitTimeTo: Models.ngbDateToLocalDate(this.searchModel.submitTimeTo),
        returnTimeFrom: Models.ngbDateToLocalDate(this.searchModel.returnTimeFrom),
        returnTimeTo: Models.ngbDateToLocalDate(this.searchModel.returnTimeTo),
        processDeadlineFrom: Models.ngbDateToLocalDate(this.searchModel.processDeadlineFrom),
        processDeadlineTo: Models.ngbDateToLocalDate(this.searchModel.processDeadlineTo),
        returnReasonEmpty: this.searchModel.returnReasonEmpty ? this.searchModel.returnReasonEmpty : undefined,
        minimumIdenticalItemCount: this.searchModel.minimumIdenticalItemCount
      }
    };
    this.orderSearchService.setSearchData(request).subscribe();
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  cancelOrder(id: number) {
    this.orderService.cancel({
      orderId: id
    }).subscribe((result) => {
      this.loadList(1);
    });
  }

  public onImportSuccess(succeeded: boolean) {
    if (succeeded) {
      this.loadList(1);
    }
  }

  public onOrderReturnImportFinished(uploadResponse: UploadResponse) {
    if (uploadResponse.status === 200) {
      this.showSuccessToast();
    }
    else if (uploadResponse.status === 400) {
      const responseFile = JSON.parse(uploadResponse.response);
      if (responseFile.base64_content && responseFile.file_name && responseFile.content_type) {
        const blob = (b64toBlob as any)(responseFile.base64_content, responseFile.content_type);
        saveAs(blob, responseFile.file_name);
        this.showXlsxErrorToast();
      }
      else {
        this.showErrorToast();
      }
    }
    else {
      this.showErrorToast();
    }
  };

  showSuccessToast() {
    this.toasterService.pop({
      timeout: UiConstants.ToastTimeoutShort,
      type: UiConstants.toastTypeSuccess,
      title: this.translateService.instant(StringKey.COMMON_SUCCESS)
    });
  }

  showErrorToast() {
    this.toasterService.pop({
      timeout: UiConstants.ToastTimeoutLong,
      type: UiConstants.toastTypeError,
      title: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_TITLE),
      body: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_MESSAGE)
    });
  }

  showXlsxErrorToast() {
    this.toasterService.pop({
      timeout: UiConstants.ToastTimeoutLong,
      type: UiConstants.toastTypeError,
      title: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_TITLE),
      body: this.translateService.instant(StringKey.XLSX_IMPORT_ERROR)
    });
  }

  getSelectedIds(): number[] {
    const ids: number[] = [];
    this.orderList.forEach((tr) => {
      if (tr.selected) {
        ids.push(tr.orderId);
      }
    });
    return ids;
  }

  toggleEachOrder() {
    const eachOrderSelected = !this.eachOrderSelected;
    this.orderList.forEach((tr) => {
      tr.selected = eachOrderSelected;
    });
  }

  deselectAll() {
    this.orderList.forEach((tr) => {
      tr.selected = false;
    });
  }

  get eachOrderSelected(): boolean {
    if (this.orderList.length === 0) {
      return false;
    }
    let selected = true;
    this.orderList.forEach((tr) => {
      selected = selected && tr.selected;
    });
    return selected;
  }

  isAnyOrderSelected(): boolean {
    const selected: Order[] = this.orderList.filter((tr) => tr.selected);
    return selected.length > 0;
  }

  getSelectedOrderCount(): number {
    let selectedItems = 0;
    this.orderList.forEach((order) => {
      if (order.selected) {
        selectedItems++;
      }
    });
    return selectedItems;
  }

  openProcessCreateDialog() {
    if (this.isAnyOrderSelected()) {
      this.processCreateModel.orderIds = this.getSelectedIds();
      this.processCreateModel.workflowId = this.workflowList[0].id;
      this.processCreateDialogVisible = true;
      this.processCreateDialog.show();
    }
    else {
      this.loadIdSet().subscribe(ids => {
        this.processCreateModel.orderIds = ids;
        this.processCreateModel.workflowId = this.workflowList[0].id;
        this.processCreateDialogVisible = true;
        this.processCreateDialog.show();
      });
    }
  }

  closeProcessCreateDialog() {
    this.submitted = false;
    this.formGroup.reset();
    this.processCreateModel.reset();
    this.processCreateDialogVisible = false;
    this.processCreateDialog.hide();
  }

  openAddToProcessDialog() {
    if (this.processList.length === 0) {
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutLong,
        type: UiConstants.toastTypeError,
        title: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_TITLE),
        body: this.translateService.instant(StringKey.ORDER_LIST_NO_OPEN_PROCESSES)
      });
      return;
    }
    if (this.isAnyOrderSelected()) {
      this.addToProcessModel.orderIds = this.getSelectedIds();
      this.addToProcessDialogVisible = true;
      this.addToProcessDialog.show();
    }
    else {
      this.loadIdSet().subscribe(ids => {
        this.addToProcessModel.orderIds = ids;
        this.addToProcessDialogVisible = true;
        this.addToProcessDialog.show();
      });
    }
  }

  closeAddToProcessDialog() {
    this.addToProcessSubmitted = false;
    this.addToProcessModel.reset();
    this.addToProcessDialogVisible = false;
    this.addToProcessDialog.hide();
  }

  openCompanySelectDialog(selectableCompanies: UserCompany[]) {
    this.companySelectDialogVisible = true;
    this.orderCreateModel = new OrderCreateModel(selectableCompanies);
    this.companySelectDialog.show();
  }

  closeCompanySelectDialog() {
    this.companySelectDialogVisible = false;
    this.companySelectDialog.hide();
  }

  isEditableOrderState(orderState: Order.OrderState): boolean {
    if (this.config.valid_order_edit_states.find(editState => editState === orderState)) {
      return true;
    }
    return false;
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  hasFieldError(field?: Order.ProcessValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: Order.ProcessValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: Order.ProcessValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  private createFormGroup(fb: FormBuilder): FormGroup {
    return fb.group(
      {
        workflowId: fb.control(
          {value: this.processCreateModel.workflowId},
          [
            Validators.required,
          ]
        ),
        name: fb.control(
          {value: this.processCreateModel.name},
          []
        ),
        externalId: fb.control(
          {value: this.processCreateModel.externalId},
          []
        ),
      }
    );
  }

  private createForwardingHtmlForm() {
    return new ForwardingNgFormRef({
      formFn: () => {
        return this.form;
      }
    });
  }

  private parseProcessFieldErrors(msg: ErrorMessage) {
    return msg.parseFieldErrors<Order.ProcessValidatedField>((key: string) => {
      // It should be a map, but here we have only 3 possible key, so switch is easier.
      switch (key) {
        case 'name':
          return Order.ProcessValidatedField.NAME;
        case 'external_id':
          return Order.ProcessValidatedField.EXTERNAL_ID;
        case 'workflow_id':
          return Order.ProcessValidatedField.WORKFLOW_ID;
        default:
          throw Error('Unhandled field error: ' + key);
      }
    });
  }

  downloadFile() {
    const link = document.createElement('a');
    link.download = 'order_import_template.xlsx';
    link.href = 'assets/files/order_import_template.xlsx';
    link.click();
  }

  ngOnDestroy() {
    this.saveSearch();
  }
}

interface Order {
  orderId: number;
  externalId: string;
  orderState: Order.OrderState;
  state: OrderDisplayStateObject;
  description: string;
  ownerUserId: number;
  ownerCompanyId: number;
  weightInGram?: number;
  cashOnDeliveryPrice: string;
  items?: Order.OrderItem[];
  shippingState: string;
  shipmentTrackingNumber: string;
  creationTime: OffsetDateTime;
  updateTime: OffsetDateTime;
  submitTime?: OffsetDateTime;
  process?: {
    id: number;
    displayName: string;
    workflow: OrderWorkflow;
    deadline?: OffsetDateTime;
    stateIcon: string;
    stateText: string;
  };
  version: number;
  recipientName: string;
  recipientDeliveryAddress: string;
  selected: boolean;
}

class LegacyProcessCreateModel {
  workflowId?: number;
  usageType: string = 'ORDER';
  orderIds: number[] = [];
  name: string = '';
  externalId?: string = '';
  description?: string = '';
  deadline: NgbDateStruct | null = null;

  reset() {
    this.workflowId = undefined;
    this.usageType = 'ORDER';
    this.orderIds = [];
    this.name = '';
    this.externalId = '';
    this.description = '';
    this.deadline = null;
  }
}

class AddToProcessModel {
  processId?: number = undefined;
  orderIds: number[] = [];

  reset() {
    this.processId = undefined;
    this.orderIds = [];
  }
}

interface WorkflowItem {
  name: string;
  id: number;
}

interface CompanyItem {
  id: number;
  name: string;
}

interface SearchLoadResult {
  storedSearchData: OrderSearch.SearchDataResult,
}

interface ProcessItem {
  id?: number;
  name?: string;
}

class OrderCreateModel {
  selectedCompany: UserCompany = {id: -1, name: 'selectOne', type: 'DEMANDER'};

  constructor(readonly selectableCompanies: UserCompany[]) {
    if (this.selectableCompanies.length > 0) {
      this.selectedCompany = this.selectableCompanies[0];
    }
  }

}
