/* eslint-disable */
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Transition, UIRouter } from '@uirouter/angular';
import { TranslateService } from '@ngx-translate/core';
import { MultiselectOptionItem, OptionItems, OwnerUserItem, OwnerUserItemFactory, UiConstants, } from '../../../util/core-utils';
import { UserData, UserDataLoader, UserDataLoaderPermissionDeniedStrategy } from '../../../lib/user-data-loader';
import { StringKey } from '../../../app.string-keys';
import { StateName } from '../../../app.state-names';
import { Arrays } from '../../../lib/util/arrays';
import { InputMask } from '../../../util/input-masks';
import { Stock, StockFieldErrorMap, StockService } from '../../../lib/stock/stock.service';
import { StockEditModel, StockRecordResourceCreateRequestModel, StockType, } from '../../../util/stock/stock-utils';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { StockSubStockRecordService } from '../../../lib/stock/stock-sub-stock-record.service';
import { Numbers } from '../../../lib/util/numbers';
import { StockItem, StockItemService, StockItemType } from '../../../lib/stock/stock-item.service';
import { StockItemItem } from '../../../util/stock/stock-item-utils';
import { RightModel } from '../../../app.rights';
import { RightResolver, RightService } from '../../../lib/right.service';
import { Angular2Multiselects } from '../../../util/multiselect';
import { Strings } from '../../../lib/util/strings';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { QueryResult, ResourceQueryResult } from '../../../lib/util/services';
import { Models } from '../../../util/model-utils';
import { NgForm, NgModel } from '@angular/forms';
import { EmptyMessage } from '../../../lib/util/messages';
import { FieldError, FieldErrors, ObservableErrorResourceParser } from '../../../lib/util/errors';
import { DownloadedFile } from '../../../lib/util/downloaded-files';
import { saveAs } from 'file-saver';
import { CompanyMultiselectProvider } from '../../../lib/company/company-multiselect.provider';
import { StockStockRecordListComponent } from '../stock-stock-record-list/stock-stock-record-list.component';
import { Currency, CurrencyService } from '../../../lib/currency.service';
import { CustomerRecordMultiselectProvider } from '../../../lib/customer/customer-record-multiselect-provider.service';
import {
  CustomerRecordContactLocationMultiselectProvider
} from '../../../lib/customer/contact/customer-record-contact-location-multiselect.provider';

/* eslint-enable */

@Component({
  selector: 'app-stock-edit',
  templateUrl: 'stock-edit.component.html',
  styleUrls: ['stock-edit.component.scss']
})
export class StockEditComponent implements OnInit, AfterViewInit {
  Models = Models;
  UiConstants = UiConstants;

  InputMask = InputMask;
  model: StockEditModel;
  ownerUsers: StockOwnerUserItem[];
  fieldErrors: StockFieldErrorMap;
  stockId: number;
  stock: Stock;
  newStockRecordModel: StockRecordResourceCreateRequestModel = new StockRecordResourceCreateRequestModel();
  rightModel: RightModel = RightModel.empty();
  createDropdownSettings: Angular2Multiselects.Settings = {classes: UiConstants.angular2MultiselectClass};
  userDropdownSettings: Angular2Multiselects.Settings;
  customerDropdownSettings: Angular2Multiselects.Settings;
  createType: StockItemType = 'STOCK';

  customerRecords: MultiselectOptionItem<number>[] = [];
  contactLocations: MultiselectOptionItem<number>[] = [];
  private currencies: Currency.Currency[] = [];

  @ViewChild('newStockItemDialog', {static: true}) newStockItemDialog: ModalDirective;
  newStockDialogShown = false;
  @ViewChild('stockStockRecordList', {static: true}) stockStockRecordList: StockStockRecordListComponent;
  @ViewChild('cf', {static: true}) cf?: NgForm;
  @ViewChild('newStockItem') newStockItem: NgModel;

  stockItemsForCreate: StockItemItem[] = [];
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  stockTypes: StockType[] = StockType.VALUES.toArray();
  stockRecordCreateInProgress: boolean;

  get createWithAmount(): boolean {
    return this.createType === 'STOCK';
  }

  ngOnInit() {
    this.translateService.get('MENU_NAVBAR_STOCK').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.STOCK_LIST});
      }
    );
  }

  ngAfterViewInit() {
    this.loadRightModels(() => {
      this.loadModel();
    });
    this.initDropDown(false);
  }

  private loadRightModels(completion: () => void) {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        completion();
      }
    );
  }

  private loadModel(selectedOwnerUsers?: StockOwnerUserItem[]) {
    const fields: string[] =
      ['id', 'name', 'owner_user_ids', 'company_id', 'customer_record_id', 'contact_location_id', 'central', 'waste', 'external_id', 'type'];
    this.stockService.get({
      id: this.stockId,
      fields: fields.join(','),
      with_sums: true
    }).subscribe(stock => {
      this.breadcrumbSelf = stock.name;
      this.model.name = stock.name;
      this.model.externalId = stock.external_id;
      this.model.type = stock.type;
      this.stock = stock;
      this.model.waste = stock.waste;
      this.model.central = stock.central;
      this.model.sums = stock.stock_sums;
      this.loadOwnerUsers(stock.owner_user_ids, selectedOwnerUsers);
      if (stock.company_id) {
        if (this.rightModel.companyReadAll.hasRight()) {
          this.loadSelectedCompany(stock.company_id);
        }
        else {
          this.model.company.push({id: stock.company_id, itemName: ''});
        }
      }
      if (stock.customer_record_id) {
        if (this.rightModel.customerRecordRead.hasRight()) {
          this.customerRecordMultiselectProvider.getById(stock.customer_record_id).subscribe(c => {
            this.model.customerRecord = [c];
          })
        }
        else {
          this.model.customerRecord = [{id: stock.customer_record_id, itemName: ''}];
        }
      }
      if (stock.contact_location_id) {
        if (this.rightModel.customerRecordRead.hasRight()) {
          this.contactLocationMultiselectProvider.getById(stock.contact_location_id).subscribe(c => {
            this.model.contactLocation = [c];
          })
        }
        else {
          this.model.contactLocation = [{id: stock.contact_location_id, itemName: ''}];
        }
      }
    });
  }

  private loadSelectedCompany(id: number) {
    this.companyMultiselectProvider.getById(id).subscribe((result) => {
      this.model.company.push(result);
    });
  }

  update() {
    const ownerUsers = this.model.owner_users;
    const ownerUserIds: number[] = [];
    ownerUsers.forEach(user => {
      if (user.id !== null) {
        ownerUserIds.push(user.id);
      }
    });
    this.stockService.update({
      id: this.stockId,
      name: this.model.name,
      external_id: this.model.externalId,
      owner_user_ids: this.model.type === 'PERSONAL' ? ownerUserIds : [],
      company_id: this.model.type !== 'COMPANY' ? undefined :
        (this.model.company.length === 1 && this.model.company[0].id)
          ? this.model.company[0].id! : undefined,
      customer_record_id: this.model.type === 'CUSTOMER' ?
        (this.model.customerRecord.length === 1 && this.model.customerRecord[0].id)
          ? this.model.customerRecord[0].id! : undefined : undefined,
      contact_location_id: this.model.type === 'CUSTOMER' ?
        (this.model.contactLocation.length === 1 && this.model.contactLocation[0].id)
          ? this.model.contactLocation[0].id! : undefined : undefined,
      central: this.model.type === 'COMPANY' && this.model.central,
      waste: this.model.type === 'COMPANY' && this.model.waste,
      type: this.model.type,
    }).subscribe(
      (response: EmptyMessage) => {
        this.uiRouter.stateService.go(StateName.STOCK_LIST);
      },
      (error: any) => {
        const res = ObservableErrorResourceParser.parseError(error);
        this.fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
      }
    );
  }

  initDropDown(disabled: boolean) {
    this.createDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .remoteSearch(true)
      .disabled(disabled)
      .build();
    this.userDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .text(StringKey.COMMON_PLACEHOLDER_USER_NOT_SELECTED_YET)
      .selectAllText(StringKey.COMMON_PLACEHOLDER_SELECT_ALL_USER)
      .unSelectAllText(StringKey.COMMON_PLACEHOLDER_SELECT_ALL_USER)
      .noDataLabel(StringKey.COMMON_PLACEHOLDER_EMPTY_USER_LIST)
      .build();
    this.customerDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();

  }

  showNewStockItemDialog(stockItemType: StockItemType) {
    this.createType = stockItemType;
    this.newStockRecordModel = new StockRecordResourceCreateRequestModel();
    this.newStockItemDialog.show();
    this.newStockDialogShown = true;
    this.onSearch();
  }

  onStockRecordEdited(recordId: number) {
    this.loadModel(this.model.owner_users);
  }

  onOwnerUserChange(fieldError?: FieldError) {
    this.removeFieldError(fieldError);
  }

  removeFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.fieldErrors, fieldError);
  }

  back() {
    window.history.back();
  }

  getTextMaximumLength(): number {
    return UiConstants.maximumVarcharLength;
  }

  private loadOwnerUsers(ownerUserIds: number[], selectedOwnerUsers?: StockOwnerUserItem[]) {
    this.ownerUsers = [];
    this.userDataLoader.loadAll(UserDataLoaderPermissionDeniedStrategy.MISS_ALL).subscribe(
      (users: UserData[]) => {
        const items = this.ownerUserItemFactory.createAll(users);

        const sortedOwnerUsers: OwnerUserItem[] = items;
        sortedOwnerUsers.sort(OptionItems.createNameComparator());

        Arrays.iterateByIndex(sortedOwnerUsers, (sortedItem) => {
          const item = new StockOwnerUserItem();
          item.id = sortedItem.id;
          item.itemName = sortedItem.text;
          this.ownerUsers.push(item);
          if (selectedOwnerUsers) {
            this.model.owner_users = selectedOwnerUsers;
          }
          else {
            ownerUserIds.forEach(id => {
              if (item.id === id) {
                this.model.owner_users.push(item);
              }
            });
          }
        });
      }
    );
  }

  searchCustomerRecord(q?: string) {
    this.customerRecordMultiselectProvider.loadActiveNonContact(q).subscribe(c => {
      this.customerRecords = c;
    });
  }

  onCustomerRecordChanged() {
    this.model.contactLocation = [];
    if (this.model.customerRecord.length === 1) {
      this.searchContactLocation();
    }
  }

  searchContactLocation(q?: string) {
    if (this.model.customerRecord.length === 1) {
      this.contactLocationMultiselectProvider.loadForCustomer(this.model.customerRecord[0].id, q).subscribe(c => {
        this.contactLocations = c;
      });
    }
  }

  // MARK: - Stock Item

  onSearch(evt?: any) {
    this.stockItemsForCreate = [];
    this.stockItemService.query({
      q: evt ? Strings.undefinedOrNonEmpty(evt.target.value) : undefined,
      company_ids: this.model.type === 'PERSONAL'
        ? undefined
        : this.model.company.length === 1
          ? this.model.company[0].id.toString()
          : undefined,
      page_number: 1,
      number_of_items: 100,
      order: '+name',
      type: this.createType,
      disabled: false,
      no_progress_bar: true
    })
      .subscribe((result: ResourceQueryResult<StockItem>) => {
        this.stockItemsForCreate = result.items.map((stockItem) => {
          const ext: string = stockItem.external_id ? '(' + stockItem.external_id + ')' : '';
          return {
            id: stockItem.id,
            text: stockItem.name,
            itemName: stockItem.name + ext,
            itemType: stockItem.type,
            itemSerialCode: stockItem.serial_code,
            packageData: stockItem.package_data
          };
        });
      });
  }

  stockRecordCreateRealAll(all: boolean) {
    this.newStockRecordModel.stock_item = [];
    this.initDropDown(all);
  }

  closeNewStockItemDialog() {
    this.newStockItemDialog.hide();
    this.newStockDialogShown = false;
    this.newStockRecordModel = new StockRecordResourceCreateRequestModel();
  }

  createStockItem() {
    this.stockRecordCreateInProgress = true;
    const stockItemIds: number[] = [];
    this.newStockRecordModel.stock_item.forEach((i) => {
      if (i.id !== null) {
        stockItemIds.push(i.id);
      }
    });
    this.stockSubStockRecordService.createMass({
      stockId: this.stockId,
      stock_item_ids: stockItemIds,
      amount: Numbers.undefinedOrFloat(this.newStockRecordModel.amount),
      all: this.newStockRecordModel.all,
      stock_item_type: this.createType
    }).subscribe(result => {
      this.loadModel(this.model.owner_users);
      this.newStockRecordModel.stock_item = [];
      this.newStockRecordModel.amount = '';
      this.stockItemsForCreate = [];
      this.stockStockRecordList.loadList();
      this.closeNewStockItemDialog();
      this.stockRecordCreateInProgress = false;
    }, error => {
      this.stockRecordCreateInProgress = false;
      // TODO show toast
    });
  }

  onNewStockItemDialogClosed() {
    if (this.cf) {
      this.cf.resetForm();
    }
    this.newStockDialogShown = false;
  }

  exportXls() {
    this.stockService.exportXls(this.stockId).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName(this.model.name + '_export.xlsx'));
      }
    );
  }

  private loadCurrencies() {
    this.currencies = [];
    this.currencyService.query({}).subscribe((result: QueryResult<Currency.Currency>) => {
      result.items.forEach(c => {
        if (c) {
          this.currencies.push(c);
        }
      });
    });
  }

  getCurrencyName(currencyCode: string) {
    const cc = this.currencies.find(c => c.currencyCode === currencyCode);
    return cc ? cc.localizedName : currencyCode;
  }

  getCurrency(currencyCode: string) {
    const cc = this.currencies.find(c => c.currencyCode === currencyCode);
    return cc!;
  }

  constructor(private ownerUserItemFactory: OwnerUserItemFactory,
              private userDataLoader: UserDataLoader,
              private translateService: TranslateService,
              private companyMultiselectProvider: CompanyMultiselectProvider,
              private stockItemService: StockItemService,
              private stockService: StockService,
              private stockSubStockRecordService: StockSubStockRecordService,
              private rightService: RightService,
              private uiRouter: UIRouter,
              private customerRecordMultiselectProvider: CustomerRecordMultiselectProvider,
              private contactLocationMultiselectProvider: CustomerRecordContactLocationMultiselectProvider,
              private currencyService: CurrencyService,
              private transition: Transition) {
    this.model = new StockEditModel();
    this.model.type = StockType.DEFAULT.name;
    this.ownerUsers = [];
    this.fieldErrors = {};
    this.stockId = this.transition.params().id;
    this.loadCurrencies();
  }

}

export class StockOwnerUserItem {
  id: number | null;
  itemName: string;
}
