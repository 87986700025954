/* eslint-disable */
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Angular2Multiselects } from '../../../util/multiselect';
import { UiConstants } from '../../../util/core-utils';
import { Process, ProcessService } from '../../../lib/process/process.service';
import { ProcessCreateModel, ProcessTaskRecordConfigNameTemplate } from './process-create.model';
import { FieldValidationError, ForwardingNgFormRef, LocalFormGroupValidationErrors } from '../../../lib/util/services';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { AppValidators } from '../../../util/app-validators';
import { ConfigurationService } from '../../../lib/core-ext/configuration.service';
import { NgbDatePickerParserFormatter } from '../../../util/ngb-datepicker';
import { WorkflowMultiselectProvider } from '../../../lib/workflow/workflow-multiselect.provider';
import {
  TaskRecordCreateDialogMode,
  TaskRecordCreateMaterialDialogComponent
} from '../../task/task-record/task-record-create-material-dialog/task-record-create-material-dialog.component';
import ProcessFieldType = Process.ProcessFieldType;

/* eslint-enable */

@Component({
  selector: 'app-process-base-dialog',
  templateUrl: './process-base-dialog.component.html',
  styleUrls: ['./process-base-dialog.component.scss']
})
export class ProcessBaseDialogComponent implements OnInit {

  Process = Process;
  ProcessTaskRecordConfigNameTemplate = ProcessTaskRecordConfigNameTemplate;
  ProcessFieldType = Process.ProcessFieldType;

  model: ProcessCreateModel;

  dropdownSettings: Angular2Multiselects.Settings;
  @ViewChild('f', {static: true})
  form: NgForm;
  formGroup: FormGroup;
  private formGroupValidationErrors: LocalFormGroupValidationErrors;
  private fieldErrors: FieldValidationError<Process.ValidatedField> =
    FieldValidationError.empty<Process.ValidatedField>();

  constructor(public dialogRef: MatDialogRef<ProcessBaseDialogComponent>,
              private fb: FormBuilder,
              private workflowMultiselectProvider: WorkflowMultiselectProvider,
              private configurationService: ConfigurationService,
              private processService: ProcessService,
              private datePickerParserFormatter: NgbDatePickerParserFormatter,
              private dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: ProcessBaseDialogData) {
    this.model = new ProcessCreateModel(this.datePickerParserFormatter, this.configurationService.getConfiguration(), this.data.process);
    this.formGroup = this.createFormGroup(this.fb);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(
      this.createForwardingHtmlForm(),
      this.formGroup
    );

  }

  ngOnInit() {
    this.initDropdownSettings();
    if (!this.readonly) {
      this.initSearch();
    }
    this.loadModelData();
  }

  initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .translate(true)
      .build();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  initSearch() {
    if (this.create) {
      this.onWorkflowSearch();
    }
  }

  onWorkflowSearch(predicate?: string) {
    this.workflowMultiselectProvider.loadActive(predicate).subscribe(items => {
      this.model.workflowList = items;
    });
  }

  loadModelData() {
  }

  submit() {
    if (this.hasLocalFieldError() || this.fieldErrors.hasError()) {
      return;
    }
    if (this.data.process) {
      this.save();
    }
    else {
      this.next();
    }
  }

  next() {
    TaskRecordCreateMaterialDialogComponent.openDialog(this.dialog,
      {
        taskId: this.model.selectedWorkflow!.firstTaskId,
        mode: TaskRecordCreateDialogMode.REQUEST_ONLY,
        nameOptional: this.model.taskRecordNameTemplate.length > 0
      }, (result) => {
        if (result?.success && result.request) {
          this.model.taskRecordCreateRequest = result.request;
          this.save();
        }
        else {
          this.model.taskRecordCreateRequest = undefined;
        }
      })
  }

  save() {
    if (!this.data.process) {
      this.processService.create(this.model.toCreateRequest()).subscribe((value) => {
        this.dialogRef.close(value);
      }, (error: any) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withForm(this.form);
        }
        else {
          this.closeDialog();
        }
      });
    }
    else if (!this.readonly) {
      this.processService.update(this.model.toUpdateRequest()).subscribe((value) => {
        this.dialogRef.close({id: this.model.id});
      }, (error: any) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withForm(this.form);
        }
        else {
          this.closeDialog();
        }
      });
    }
    else {
      this.dialogRef.close();
    }
  }

  hasFieldError(field: Process.ValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: Process.ValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: Process.ValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  private createForwardingHtmlForm() {
    return new ForwardingNgFormRef({
      formFn: () => {
        return this.form;
      }
    });
  }

  private createFormGroup(fb: FormBuilder): FormGroup {
    return fb.group(
      {
        name: fb.control(
          {value: this.model.name, disabled: this.readonly},
          [AppValidators.tempValidator({
            validator: Validators.required,
            disabled: () => this.readonly || !this.isRequiredField(ProcessFieldType.NAME)
          })]
        ),
        workflow: fb.control(
          {value: this.model.workflow, disabled: !this.create},
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => !this.create
            })
          ]
        ),
        externalId: fb.control(
          {value: this.model.externalId, disabled: this.readonly},
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => !(this.edit || (!this.readonly && this.isRequiredField(ProcessFieldType.EXTERNAL_ID)))
            })
          ]
        ),
        description: fb.control(
          {value: this.model.description, disabled: this.readonly},
          [AppValidators.tempValidator({
            validator: Validators.required,
            disabled: () => this.readonly || !this.isRequiredField(ProcessFieldType.DESCRIPTION)
          })]
        ),
        deadlineDate: fb.control({value: this.model.deadlineDate, disabled: this.readonly},
          [AppValidators.validateLocalDate,
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => this.readonly || !this.isRequiredField(ProcessFieldType.DEADLINE)
            })]
        ),
        namePattern: fb.control({value: this.model.taskRecordNameTemplate, disabled: this.readonly},
          []
        ),
      }
    );
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  getTextMaximumLength(): number {
    return UiConstants.maximumVarcharLength;
  }

  getNumberMaximumLength(): string {
    return UiConstants.maxInputNumberLength;
  }

  get readonly(): boolean {
    return this.data.readonly;
  }

  get create(): boolean {
    return !this.readonly && !this.edit;
  }

  get edit(): boolean {
    return !this.readonly && this.model.process !== undefined;
  }

  get showAdditionalFields(): boolean {
    return this.model.process !== undefined;
  }

  getTitleKey(): string {
    if (this.readonly) {
      return 'PROCESS_DETAIL';
    }
    if (this.edit) {
      return 'PROCESS_EDIT';
    }
    return 'PROCESS_CREATE';
  }

  isRequiredField(field: Process.ProcessFieldType): boolean {
    return !!this.model.selectedWorkflow && this.model.selectedWorkflow.requiredFields.contains(field);
  }

  getSubmitButtonLabel() {
    if (this.data.process) {
      return 'COMMON_BUTTON_SAVE';
    }
    return 'COMMON_BUTTON_NEXT';
  }
}

export interface ProcessBaseDialogData {
  process?: Process.Process;
  readonly: boolean;
}
