import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { FieldValidationError, ForwardingNgFormRef, LocalFormGroupValidationErrors } from '../../../lib/util/services';
import { Worklog, WorklogService } from '../../../lib/worklog/worklog.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AppNgbTimeStruct, NgbDatePickerParserFormatter } from '../../../util/ngb-datepicker';
import { Models } from '../../../util/model-utils';
import { Strings } from '../../../lib/util/strings';
import { MultiselectOptionItem } from '../../../util/core-utils';
import { Set } from 'immutable';
import { AppValidators } from '../../../util/app-validators';
import { Dates } from '../../../lib/util/dates';
import { UserMultiselectProvider } from '../../../lib/user/user-multiselect.provider';
import { Angular2Multiselects } from '../../../util/multiselect';
import { InputMask } from '../../../util/input-masks';

@Component({
  selector: 'app-worklog-material-dialog',
  templateUrl: './worklog-material-dialog.component.html',
  styleUrls: ['./worklog-material-dialog.component.scss']
})
export class WorklogMaterialDialogComponent implements OnInit {

  Worklog = Worklog;
  InputMask = InputMask;
  creationInProgress: boolean;

  @ViewChild('f', {static: true})
  form: NgForm;
  formGroup: FormGroup;
  private formGroupValidationErrors: LocalFormGroupValidationErrors;
  private fieldErrors: FieldValidationError<Worklog.ValidatedField> =
    FieldValidationError.empty<Worklog.ValidatedField>();
  model: WorklogMaterialModel = new WorklogMaterialModel();
  users: MultiselectOptionItem<number>[] = [];
  dropdownSettings: Angular2Multiselects.Settings = Angular2Multiselects.REMOTE_MULTI_SELECT;

  get readonly(): boolean {
    return this.data.readonly;
  }

  constructor(private dialog: MatDialog,
              public dialogRef: MatDialogRef<WorklogMaterialDialogComponent, WorklogDialogResult>,
              @Inject(MAT_DIALOG_DATA) public data: WorklogDialogData,
              private fb: FormBuilder,
              private worklogService: WorklogService,
              private userMultiselectProvider: UserMultiselectProvider,
              private formBuilder: FormBuilder,
              private datePickerParserFormatter: NgbDatePickerParserFormatter
  ) {
    this.formGroup = this.createFormGroup(this.fb);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(
      this.createForwardingHtmlForm(),
      this.formGroup
    );
  }

  ngOnInit(): void {
    this.loadModel();
  }

  public static openDialog(
    dialog: MatDialog,
    data: WorklogDialogData,
    resultCallback: (result?: WorklogDialogResult) => void) {
    const dialogRef = dialog.open(WorklogMaterialDialogComponent, {
      closeOnNavigation: true,
      data: data,
      width: '60%',
      height: 'auto',
      panelClass: 'custom-dialog-container'
    });
    dialogRef.afterClosed().subscribe((result: WorklogDialogResult) => {
      resultCallback(result);
    });
  }

  closeDialog(success: boolean) {
    this.dialogRef.close({success: success});
  }

  loadModel() {
    this.model = new WorklogMaterialModel();
    if (!this.data.worklog) {
      return;
    }
    const model = this.data.worklog!;
    const startTime = this.datePickerParserFormatter.fromOffsetDateTime(model.startTime);
    const endTime = this.datePickerParserFormatter.fromOffsetDateTime(model.endTime);
    this.model.startTimeDate = startTime
    this.model.startTimeTime = Models.optToNgbTime(startTime)
    this.model.endTimeDate = endTime
    this.model.endTimeTime = Models.optToNgbTime(endTime)
    this.model.loggedTimeInMinutes = model.loggedTimeInMinutes ? model.loggedTimeInMinutes : 0;
    this.model.overtime = !!model.overtime;
  }

  hasFieldError(field?: Worklog.ValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: Worklog.ValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: Worklog.ValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  private createForwardingHtmlForm() {
    return new ForwardingNgFormRef({
      formFn: () => {
        return this.form;
      }
    });
  }

  hasLocalFormError(errorCode?: string): boolean {
    if (this.formGroup.errors === null) {
      return false;
    }
    let hasError = this.formGroup.touched && !this.formGroup.controls.valid;
    if (errorCode) {
      hasError = hasError && this.formGroup.errors![errorCode];
    }
    return hasError;
  }

  get startTime(): NgbDateStruct & AppNgbTimeStruct | null {
    return Models.mergeNgbDateAndTime(this.model.startTimeDate, this.model.startTimeTime);
  }

  get endTime(): NgbDateStruct & AppNgbTimeStruct | null {
    return Models.mergeNgbDateAndTime(this.model.endTimeDate, this.model.endTimeTime);
  }

  private createFormGroup(fb: FormBuilder): FormGroup {
    return this.formBuilder.group({
      users: ['', [
        AppValidators.tempValidator({
          validator: Validators.required,
          disabled: () => !this.isCreateView()
        })
      ]],
      startTimeDate: ['',
        [
          Validators.required,
          AppValidators.validateMaxNgbDateTime({
            maxValue: () => this.endTime,
            value: () => this.startTime,
            datePickerParserFormatter: this.datePickerParserFormatter
          })]
      ],
      startTimeTime: ['', Validators.required],
      endTimeDate: ['',
        [
          Validators.required,
          AppValidators.validateMinNgbDateTime({
            minValue: () => this.startTime,
            value: () => this.endTime,
            datePickerParserFormatter: this.datePickerParserFormatter
          })]
      ],
      endTimeTime: ['', Validators.required],
      loggedHours: ['', Validators.required],
      loggedMinutes: ['', Validators.max(59)],
    }, {validator: []});
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  updateDateTimeFields() {
    this.formGroup.get('startTimeDate')!.updateValueAndValidity();
    this.formGroup.get('startTimeTime')!.updateValueAndValidity();
    this.formGroup.get('endTimeDate')!.updateValueAndValidity();
    this.formGroup.get('endTimeTime')!.updateValueAndValidity();
    this.removeFieldError(Worklog.ValidatedField.START_TIME);
    this.removeFieldError(Worklog.ValidatedField.END_TIME);
  }

  loadUsers(q?: string) {
    this.userMultiselectProvider.loadActive(q)
      .subscribe((result) => {
        this.users = result;
      });
  }

  submitWorklog() {
    this.formGroup.updateValueAndValidity();
    if (this.formGroup.invalid) {
      return;
    }
    this.creationInProgress = true;
    if (this.isCreateView()) {
      this.createWorklog();
    }
    else {
      this.updateWorklog();
    }
  }

  createWorklog() {
    this.worklogService.create({
      taskId: this.data.taskId!,
      taskRecordId: this.data.taskRecordId!,
      userIds: this.model.userIds,
      startTime: this.datePickerParserFormatter.toOffsetDateTime(this.model.startTimeDate, this.model.startTimeTime),
      endTime: this.datePickerParserFormatter.toOffsetDateTime(this.model.endTimeDate, this.model.endTimeTime),
      loggedTimeInMinutes: this.model.loggedTimeInMinutes,
      overtime: this.model.overtime
    }).subscribe(() => {
        this.creationInProgress = false;
        this.closeDialog(true);
      },
      (error: any) => {
        this.creationInProgress = false;
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error;
        }
      });;
  }

  updateWorklog() {
    this.worklogService.update({
      id: this.data.worklog!.id,
      startTime: this.datePickerParserFormatter.toOffsetDateTime(this.model.startTimeDate, this.model.startTimeTime),
      endTime: this.datePickerParserFormatter.toOffsetDateTime(this.model.endTimeDate, this.model.endTimeTime),
      loggedTimeInMinutes: this.model.loggedTimeInMinutes,
      overtime: this.model.overtime
    }).subscribe(() => {
        this.creationInProgress = false;
        this.closeDialog(true);
      },
      (error: any) => {
        this.creationInProgress = false;
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error;
        }
      });

  }

  getTitleKey(): string {
    if (this.isCreateView()) {
      return 'WORKLOG_CREATE';
    }
    return 'WORKLOG_UPDATE';
  }

  isCreateView(): boolean {
    return this.data.worklog === undefined;
  }
}

export interface WorklogDialogResult {
  success: boolean;
}

export interface WorklogDialogData {
  readonly: boolean;
  worklog?: Worklog.Worklog;
  taskId?: number;
  taskRecordId?: number;
}

export class WorklogMaterialModel {
  users: MultiselectOptionItem<number>[] = [];
  loggedHours: string = '0';
  loggedMinutes: string = '0';
  startTimeDate: NgbDateStruct | null = null;
  startTimeTime: AppNgbTimeStruct = Models.zeroNgbTime();
  endTimeDate: NgbDateStruct | null = null;
  endTimeTime: AppNgbTimeStruct = Models.zeroNgbTime();
  overtime: boolean = false;

  get loggedTimeInMinutes(): number | undefined {
    let minutes: number = 0;
    if (Strings.undefinedOrNonEmpty(this.loggedHours)) {
      minutes = minutes + +this.loggedHours * 60;
    }
    if (Strings.undefinedOrNonEmpty(this.loggedMinutes)) {
      minutes = minutes + +this.loggedMinutes;
    }
    if (minutes <= 0) {
      return undefined;
    }
    return minutes;
  }

  set loggedTimeInMinutes(minutes: number | undefined) {
    if (minutes) {
      this.loggedMinutes = (minutes % 60) + '';
      this.loggedHours = ((minutes - +this.loggedMinutes) / 60) + '';
    }
  }

  get userIds(): Set<number> {
    if (this.users.length === 0) {
      return Set.of();
    }
    return Set.of(...this.users.map(u => u.id));
  }
}
