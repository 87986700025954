import { Injectable } from '@angular/core';
import { MultiselectProvider } from '../../multiselect-provider';
import { Observable, Observer } from 'rxjs';
import { MultiselectOptionItem, UiConstants } from '../../../util/core-utils';
import { QueryResult } from '../../util/services';
import { InvoiceTag, InvoiceTagService } from './invoice-tag.service';
import { List } from 'immutable';
import { FilterField } from '../../query/filterfields';
import { CriteriaBuilder } from '../../../util/model-utils';
import { Query } from '../../query/field';

@Injectable()
export class InvoiceTagMultiselectProvider implements MultiselectProvider {

  private multiselectQueryRequest: InvoiceTag.QueryRequest = {
    fields: f => f.multiselect,
    order: f => List.of(f.invoiceTag.asc().nullsLast()),
    paging: {
      pageNumber: 1,
      numberOfItems: UiConstants.autocompletePageSize,
    },
    noProgressBar: true,
  };

  constructor(private invoiceTagService: InvoiceTagService) {
  }

  load(queryRequest: InvoiceTag.QueryRequest): Observable<MultiselectOptionItem<number>[]> {
    return Observable.create((observer: Observer<MultiselectOptionItem<number>[]>) => {
      this.invoiceTagService.query(queryRequest).subscribe((result: QueryResult<InvoiceTag.InvoiceTag>) => {
          observer.next(result.items.map(item => this.toMultiselectOptionItem(item!)).toArray());
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  loadAll(predicate?: string): Observable<MultiselectOptionItem<number>[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.filter = (f: FilterField.InvoiceTag) => CriteriaBuilder.builder()
      .addString((predicate) => f.description.containsIgnoreCase(predicate), predicate)
      .build();
    return this.load(queryRequest);
  }

  loadActive(predicate?: string): Observable<MultiselectOptionItem<number>[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.filter = (f: FilterField.InvoiceTag) => CriteriaBuilder.builder()
      .addString((predicate) => f.invoiceTag.containsIgnoreCase(predicate)
        .or(f.description.containsIgnoreCase(predicate)), predicate)
      .addBoolean(() => f.disabled.isFalse())
      .build();
    return this.load(queryRequest);
  }

  getById(id: number): Observable<MultiselectOptionItem<number>> {
    return Observable.create((observer: Observer<MultiselectOptionItem<number>>) => {
      this.invoiceTagService.get({
        id: id,
        fields: f => f.multiselect
      }).subscribe((result: InvoiceTag.InvoiceTag) => {
          observer.next(this.toMultiselectOptionItem(result!));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  getByIds(ids: number[]): Observable<MultiselectOptionItem<number>[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    const filters: Query.Criteria[] = [];
    const f: FilterField.InvoiceTag = new FilterField.InvoiceTag();
    ids.forEach(id => {
      filters.push(f.id.eq(id));
    });
    queryRequest.filter = f => Query.Criterias.anyOf(List.of(...filters));
    return this.load(queryRequest);
  }

  toMultiselectOptionItem(item: InvoiceTag.InvoiceTag): MultiselectOptionItem<number> {
    return InvoiceTagMultiselectProvider.toPublic(item);
  }

  static toPublic(item: InvoiceTag.InvoiceTag): MultiselectOptionItem<number> {
    const ret = new MultiselectOptionItem<number>();
    ret.id = item.id;
    ret.itemName = item.invoiceTag;
    ret.disabled = item.disabled;
    return ret;
  }
}
