/* eslint-disable */
import { APP_INITIALIZER, ErrorHandler, Injectable } from '@angular/core';
import { ResourceHelper } from '../util/http-services';
import { Order } from '../order/order.service';
import { ConfigModel } from '../../util/task-record-utils';
import { HttpClient } from '@angular/common/http';
import { TaskRecord } from '../task/task-record.service';
import { GlobalErrorHandler } from '../../util/global-error-handler';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { CustomerRecordFieldType } from '../../util/customer-record-utils';
import { LocalizedTypeObject } from '../../util/core-utils';
import { tap } from 'rxjs/operators';
import LDAPFeatureFlag = ConfigurationResource.LDAPFeatureFlag;
import TaskRecordField = TaskRecord.TaskRecordField;
import {AppType} from "../util/app-type-helper.service";
import {UserProfileType} from "../auth.service";
/* eslint-enable */

export namespace ConfigurationResource {

  import TaskRecordField = TaskRecord.TaskRecordField;

  export interface Configuration {
    environment?: string;
    enabled_auth_methods: AuthMethods;
    input_limitations: InputLimitations;
    work_chronology: WorkChronology;
    feature_flags: FeatureFlags;
    postal_address_format: string;
    enabled_admin_features: AdminFeature[];
    enabled_client_features: string[];
    valid_order_edit_states: Order.OrderState[];
    valid_order_note_extra_edit_states: Order.OrderState[];
    valid_order_note_internal_edit_states: Order.OrderState[];
    position_log_interval_sec: number;
    santa?: boolean;
  }

  export interface AuthMethods {
    webadmin: UserProfileType[];
    helpdesk: UserProfileType[];
    android: UserProfileType[];
  }
  export interface InputLimitations {
    maximum_string_length: number;
    maximum_long_string_length: number;
  }

  export interface WorkChronology {
    minutes_per_hour: number;
    hours_per_day: number;
    days_per_week: number;
  }

  export interface FeatureFlags {
    registration_enabled?: RegistrationType;
    demo_mode_enabled: boolean;
    helpdesk_enabled: boolean;
    local_user_enabled: boolean;
    ldap_user_enabled: boolean;
    user: UserFeatureFlags;
    task_record: TaskRecordFeatureFlag;
    postal_address: PostalAddressFeatureFlag;
    lost_driver_threshold_in_seconds: number;
    customer_record_managed_fields: string[];
    task_record_managed_fields: TaskRecordField[];
    ldap?: LDAPFeatureFlag;
    carriage_mode: CarriageFlag;
    geofencing_default_radius_in_meters: number;
    sms_futar_enabled: boolean;
    calendar: Calendar;
    stock_item_product_code_generator_enabled: boolean;
    stock_item_category_required: boolean;
    maconomy_enabled: boolean;
  }

  export type CarriageFlag = 'FORCE' | 'OFF' | 'ON';

  export enum RegistrationType {
    REQUEST_USER = 'request_user',
    CREATE_USER_SHOPRENTER = 'create_user_shoprenter',
    CREATE_USER_DEMO = 'create_user_demo',
    HELPDESK = 'helpdesk',
  }

  export const registrationTypes: LocalizedTypeObject<RegistrationType>[] = [
    {type: RegistrationType.REQUEST_USER, stringKey: 'REGSITRATION_TYPE_REQUEST_USER'},
    {type: RegistrationType.CREATE_USER_SHOPRENTER, stringKey: 'REGSITRATION_TYPE_CREATE_USER_SHOPRENTER'},
    {type: RegistrationType.CREATE_USER_DEMO, stringKey: 'REGSITRATION_TYPE_CREATE_USER_DEMO'},
  ];

  export enum CalendarDisplayLabel {
    TASK_NAME = 'TASK_NAME',
    ASSIGNEE_NAME = 'ASSIGNEE_NAME',
    POC = 'POC',
    CUSTOMER_ADDRESS = 'CUSTOMER_ADDRESS',
    CUSTOMER_NAME = 'CUSTOMER_NAME',
    CUSTOMER_PHONE_NUMBERS = 'CUSTOMER_PHONE_NUMBERS'
  }

  export const calendarDisplayLabels: LocalizedTypeObject<CalendarDisplayLabel>[] = [
    {type: CalendarDisplayLabel.TASK_NAME, stringKey: 'CALENDAR_DISPLAY_LABEL_TASK_NAME'},
    {type: CalendarDisplayLabel.ASSIGNEE_NAME, stringKey: 'CALENDAR_DISPLAY_LABEL_ASSIGNEE_NAME'},
    {type: CalendarDisplayLabel.POC, stringKey: 'CALENDAR_DISPLAY_LABEL_POC'},
    {type: CalendarDisplayLabel.CUSTOMER_ADDRESS, stringKey: 'CALENDAR_DISPLAY_LABEL_CUSTOMER_ADDRESS'},
    {type: CalendarDisplayLabel.CUSTOMER_NAME, stringKey: 'CALENDAR_DISPLAY_LABEL_CUSTOMER_NAME'},
    {type: CalendarDisplayLabel.CUSTOMER_PHONE_NUMBERS, stringKey: 'CALENDAR_DISPLAY_LABEL_CUSTOMER_PHONE_NUMBERS'}
  ];

  export interface TaskRecordFeatureFlag {
    assignee: TaskRecordFeatureFlagAssignee;
    assignee_filter: TaskRecord.TaskRecordAssigneeFilter[];
    rejected_to_archived: boolean;
    admin_navigate_on_state_change: boolean;
    mobile_show_archived: boolean;
    phone_number_related_task_record_list: boolean;
    custom_xlsx_export_enabled: boolean;
    sup_export_enabled: boolean;
    webadmin_dashboard_visible_count: number;
  }

  export interface UserFeatureFlags {
    driver_enabled: boolean
    email_address: {
      required: boolean;
      unique: boolean;
    }
  }

  export interface TaskRecordFeatureFlagAssignee {
    with_user: boolean;
    with_mobile_application;
  }

  export interface PostalAddressFeatureFlag {
    accept_only_complex_address: boolean;
    accept_only_existing_city_in_countries: string[];
    accept_only_existing_street_type_in_countries: string[];
    accept_only_valid_zip_code_format: string[];
    default_selected_country_code?: string;
  }

  export interface LDAPFeatureFlag {
    email_address_handled: boolean;
    nfc_card_handled: boolean;
    phone_number_handled: boolean;
  }

  export interface Calendar {
    available_enabled: boolean;
    user_color_enabled: boolean;
    webadmin_labels: CalendarDisplayLabel[];
    display_monogram: boolean;
    workday: Day;
    display: Day;
  }

  export interface Day {
    start_in_minutes: number;
    end_in_minutes: number;
  }

  export type AdminFeature =
    'CRM' |
    'SURVEY' |
    'INVOICE' |
    'LEGACY_WORKFLOW' |
    'WORKFLOW' |
    'ORDER' |
    'STOCK' |
    'STOCK_TAKING' |
    'CALENDAR' |
    'CUSTOMERS' |
    'DOCUMENTS' |
    'MESSAGES' |
    'TASKS' |
    'MASTER_DATA' |
    'PARCEL_COLLECTION_POINT' |
    'PROJECTS' |
    'APP_DICTIONARY' |
    'APP_FILE' |
    'TABLE_DOCUMENT' |
    'GENERAL_PDF' |
    'GENERAL_EMAIL' |
    'COLONNADE_INSURANCE_1' |
    'COMPANY' |
    'COMPANY_LOCATION' |
    'COMPANY_STOCK' |
    'VEHICLE' |
    'SHIPPING_DEMAND' |
    'TRANSPORT' |
    'EXTERIOR_TRANSPORT' |
    'WEBSHOP' |
    'GENERAL_PRINTER';
}

export function configurationServiceInitializerFactory(configurationService: ConfigurationService): Function {
  // a lambda is required here, otherwise `this` won't work inside ConfigurationService::load
  return () => configurationService.load();
}

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  private readonly _loadEventSubject: Subject<ConfigurationResource.Configuration>;
  private readonly _loadEventObservable: Observable<ConfigurationResource.Configuration>;

  private _loaded = false;
  private _configuration?: ConfigurationResource.Configuration; // TODO: handle undefined state
  private _server: string | null = null;

  public static isEnabledByServer(key: ConfigurationResource.AdminFeature, configuration: ConfigurationResource.Configuration): boolean {
    return configuration.enabled_admin_features.includes(key);
  }

  public static areEnabledByServer(
    keys: ConfigurationResource.AdminFeature[],
    configuration: ConfigurationResource.Configuration): boolean {
    for (let i = 0; i < keys.length; i++) {
      if (!configuration.enabled_admin_features.includes(keys[i])) {
        return false;
      }
    }
    return true;
  }

  get configuration(): Observable<ConfigurationResource.Configuration> {
    return this._loadEventObservable;
  }

  constructor(private http: HttpClient, private resourceHelper: ResourceHelper) {
    this._loadEventSubject = new ReplaySubject<ConfigurationResource.Configuration>(1);
    this._loadEventObservable = this._loadEventSubject.asObservable();
  }

  load(): Promise<any> {
    return this.http
      .get<ConfigurationResource.Configuration>(
        `${this.resourceHelper.getBaseUrl()}/configuration`,
        {observe: 'response'}
      ).pipe(
        tap(response => {
          this._server = response.headers.get('server');
          this._configuration = response.body!;
          this._loaded = true;
          this._loadEventSubject.next(response.body!);
        })
      )
      .toPromise()
      .catch((err: any) => Promise.resolve());
  }

  public getConfiguration(): ConfigurationResource.Configuration {
    return this._configuration!!;
  }

  public getServerVersion(): string {
    return this._server !== null && this._server.split('/').length > 0
      ? this._server.split('/')[1]
      : '';
  }

  public getConfigurationModel(): ConfigModel {
    const configModel = new ConfigModel();
    configModel.postalAddressFormat = this._configuration!!.postal_address_format;
    configModel.assigneeWithUser = this._configuration!!.feature_flags.task_record.assignee.with_user;
    configModel.assigneeWithMobileApp = this._configuration!!.feature_flags.task_record.assignee.with_mobile_application;
    configModel.customerRecordManagedFields
      = this._configuration!!.feature_flags.customer_record_managed_fields.map(f => <CustomerRecordFieldType>f);
    configModel.calendarUserColorEnabled = this._configuration!!.feature_flags.calendar.user_color_enabled;
    configModel.calendarLabels = this._configuration!!.feature_flags.calendar.webadmin_labels;
    configModel.calendarDisplayMonogram = this._configuration!!.feature_flags.calendar.display_monogram;
    configModel.calendarDisplayStartInMinutes = this._configuration!!.feature_flags.calendar.display.start_in_minutes;
    configModel.calendarDisplayEndInMinutes = this._configuration!!.feature_flags.calendar.display.end_in_minutes;
    configModel.rejectedToArchived = this._configuration!!.feature_flags.task_record.rejected_to_archived;
    configModel.navigateOnStateChange = this._configuration!!.feature_flags.task_record.admin_navigate_on_state_change;
    configModel.taskRecordPhoneNumberRelatedList = this._configuration!!.feature_flags.task_record.phone_number_related_task_record_list;
    configModel.customXlsxExportEnabled = this._configuration!!.feature_flags.task_record.custom_xlsx_export_enabled;
    configModel.supExportEnabled = this._configuration!!.feature_flags.task_record.sup_export_enabled;
    return configModel;
  }

  public getPostalAddressFormat(): string {
    return this._configuration!!.postal_address_format;
  }

  public getDefaultSelectedCountryCode(): string | undefined {
    if (this._configuration!!.feature_flags.postal_address.default_selected_country_code) {
      return this._configuration!!.feature_flags.postal_address.default_selected_country_code;
    }
    return undefined;
  }

  public getLdapConfiguration(): LDAPFeatureFlag | undefined {
    if (this._configuration!!.feature_flags.ldap_user_enabled) {
      return this._configuration!!.feature_flags.ldap;
    }
    return undefined;
  }

  get loaded(): boolean {
    return this._loaded;
  }

  public isCustomerRecordManagedField(field: CustomerRecordFieldType): boolean {
    return this._configuration!!.feature_flags.customer_record_managed_fields
      && this._configuration!!.feature_flags.customer_record_managed_fields.indexOf(field.toString()) !== -1;
  }

  public isTaskRecordManagedField(field: TaskRecordField): boolean {
    return this._configuration!!.feature_flags.task_record_managed_fields
      && this._configuration!!.feature_flags.task_record_managed_fields.indexOf(field) !== -1;
  }

  public isRegistrationEnabled(): boolean {
    return !!this._configuration!!.feature_flags.registration_enabled;
  }

}

export const CONFIGURATION_PROVIDER = {
  provide: APP_INITIALIZER,
  useFactory: configurationServiceInitializerFactory,
  deps: [ConfigurationService],
  multi: true
};

export const GLOBAL_ERROR_HANDLER_PROVIDER = {
  provide: ErrorHandler,
  useClass: GlobalErrorHandler
};
