<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
    <div class="breadcrumb-menu d-flex">
      <div class="breadcrumb-button-icon-container cursor-pointer negative-margin-right"
           *ngIf="model.importStateObject && model.importStateObject.state === 'IGNORED'"
           (click)="deleteDialog.showDialog()">
        <a class="btn-setting d-flex align-items-center" title="{{'ORDER_IMPORT_ERROR_REJECT'|translate}}">
          <i class="icomoon icomoon-reject-manually"></i>
          <span class="breadcrumb-button-text-visible">{{'ORDER_IMPORT_ERROR_REJECT' | translate}}</span>
        </a>
      </div>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-vertical-padding-half animated fadeIn">

  <div class="container-vertical-padding-half">
    <div class="col-md-6">
      <div class="card">
        <div class="card-header">
          {{'ORDER_IMPORT_ERROR_DETAIL_HEADER' | translate}}
        </div>

        <div class="card-body row">

          <div class="col-md-12">

            <div class="form-horizontal">

              <div class="inner-card">

                <div class="row">
                  <div class="form-group col-md-4">
                    <label readonly class="detail-title">{{'TASK_LABEL_OWNER_USER'|translate}}</label>
                  </div>
                  <div class="form-group col-md-8">
                    <label readonly class="detail-description">{{model.userName }}</label>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-md-4">
                    <label readonly class="detail-title">{{'COMMON_TABLE_HEADER_ID'|translate}}</label>
                  </div>
                  <div class="form-group col-md-8">
                    <label readonly class="detail-description">{{model.id }}</label>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-md-4">
                    <label readonly class="detail-title">{{'COMMON_COMPANY'|translate}}</label>
                  </div>
                  <div class="form-group col-md-8">
                    <label readonly class="detail-description">{{model.companyName }}</label>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-md-4">
                    <label readonly class="detail-title">{{'COMMON_USER'|translate}}</label>
                  </div>
                  <div class="form-group col-md-8">
                    <label readonly class="detail-description">{{model.userName }}</label>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-md-4">
                    <label readonly class="detail-title">{{ externalId1StringKey |translate}}</label>
                  </div>
                  <div class="form-group col-md-8">
                    <label readonly class="detail-description">{{model.externalId1 }}</label>
                  </div>
                </div>

                <div class="row" *ngIf="model.externalId2">
                  <div class="form-group col-md-4">
                    <label readonly class="detail-title">{{'ORDER_IMPORT_ERROR_EXTERNAL_ID2'|translate}}</label>
                  </div>
                  <div class="form-group col-md-8">
                    <label readonly class="detail-description">{{model.externalId2 }}</label>
                  </div>
                </div>

                <div class="row" *ngIf="model.extraId1">
                  <div class="form-group col-md-4">
                    <label readonly class="detail-title">{{ extraId1StringKey |translate}}</label>
                  </div>
                  <div class="form-group col-md-8">
                    <label readonly class="detail-description">{{model.extraId1 }}</label>
                  </div>
                </div>

                <div class="row" *ngIf="model.importStateObject">
                  <div class="form-group col-md-4">
                    <label readonly class="detail-title">{{'ORDER_IMPORT_ERROR_STATE'|translate}}</label>
                  </div>
                  <div class="form-group col-md-8">
                    <label readonly class="detail-description">{{model.importStateObject.stringKey | translate }}</label>
                  </div>
                </div>

                <div class="row" *ngIf="model.errorReason">
                  <div class="form-group col-md-4">
                    <label readonly class="detail-title">{{'COMMON_ERROR'|translate}}</label>
                  </div>
                  <div class="form-group col-md-8">
                    <label readonly class="detail-description">{{model.errorReason.stringKey | translate }}</label>
                  </div>
                </div>

                <div class="row" *ngIf="model.errorMessage">
                  <div class="form-group col-md-4">
                    <label readonly class="detail-title">{{'TASK_RECORD_DETAIL_EXPORT_ERROR_MESSAGE'|translate}}</label>
                  </div>
                  <div class="form-group col-md-8">
                    <label readonly class="detail-description">{{model.errorMessage }}</label>
                  </div>
                </div>

                <div class="row" *ngIf="model.recipientName">
                  <div class="form-group col-md-4">
                    <label readonly class="detail-title">{{'COMMON_RECIPIENT_NAME'|translate}}</label>
                  </div>
                  <div class="form-group col-md-8">
                    <label readonly class="detail-description">{{model.recipientName }}</label>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-md-4">
                    <label readonly class="detail-title">{{'ORDER_IMPORT_ERROR_REALM'|translate}}</label>
                  </div>
                  <div class="form-group col-md-8">
                    <label readonly class="detail-description">{{model.partnerNameStringKey | translate }}</label>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-md-4">
                    <label readonly class="detail-title">{{'COMMON_CREATION_TIME'|translate}}</label>
                  </div>
                  <div class="form-group col-md-8">
                    <label readonly class="detail-description">{{model.creationTime.toUtcIsoString() | date:'short' }}</label>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-md-4">
                    <label readonly class="detail-title">{{'COMMON_UPDATE_TIME'|translate}}</label>
                  </div>
                  <div class="form-group col-md-8">
                    <label readonly class="detail-description">{{model.updateTime.toUtcIsoString() | date:'short' }}</label>
                  </div>
                </div>

                <div class="row" *ngIf="model.manualRejectTime">
                  <div class="form-group col-md-4">
                    <label readonly class="detail-title">{{'ORDER_IMPORT_ERROR_DETAIL_MANUAL_REJECT_TIME'|translate}}</label>
                  </div>
                  <div class="form-group col-md-8">
                    <label readonly class="detail-description">{{model.manualRejectTime.toUtcIsoString() | date:'short' }}</label>
                  </div>
                </div>

                <div class="row" *ngIf="model.manualRejectUser">
                  <div class="form-group col-md-4">
                    <label readonly class="detail-title">{{'ORDER_IMPORT_ERROR_DETAIL_MANUAL_REJECT_USER'|translate}}</label>
                  </div>
                  <div class="form-group col-md-8">
                    <label readonly class="detail-description">{{model.manualRejectUser}}</label>
                  </div>
                </div>

              </div>

            </div>

          </div>

        </div>
      </div>

    </div>
  </div>

  <div class="container-vertical-padding-half" *ngIf="model.data1">
    <div class=" col-lg-12 col-md-12 col-xs-12 col-sm-12">
      <div class="card">
        <div class="card-header">
          {{'ORDER_IMPORT_ERROR_DELIVERY_LIST'|translate}}
        </div>

        <div class="card-body">
          <div class="form-horizontal">

            <div class="inner-card">
              <div class="form-group col-md-12">
                <app-json *ngIf="model.data1.contentType === 'application/json'"
                          [content]="model.data1.content"></app-json>
                <div *ngIf="model.data1.contentType !== 'application/json'">
                  {{model.data1.content}}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-vertical-padding-half" *ngIf="model.data2">
    <div class=" col-lg-12 col-md-12 col-xs-12 col-sm-12">
      <div class="card">
        <div class="card-header">
          {{'ORDER_IMPORT_ERROR_DELIVERY_ATTACHMENT'|translate}}
        </div>

        <div class="card-body">
          <div class="form-horizontal">

            <div class="inner-card">
              <div class="form-group col-md-12">
                <app-json *ngIf="model.data2.contentType === 'application/json'"
                          [content]="model.data2.content"></app-json>
                <div *ngIf="model.data2.contentType !== 'application/json'">
                  {{model.data2.content}}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-vertical-padding-half" *ngIf="model.data3">
    <div class=" col-lg-12 col-md-12 col-xs-12 col-sm-12">
      <div class="card">
        <div class="card-header">
          {{'ORDER_IMPORT_ERROR_DISPATCH_ORDER_ID'|translate}}
        </div>

        <div class="card-body">
          <div class="form-horizontal">

            <div class="inner-card">
              <div class="form-group col-md-12">
                <app-json *ngIf="model.data3.contentType === 'application/json'"
                          [content]="model.data3.content"></app-json>
                <div *ngIf="model.data3.contentType !== 'application/json'">
                  {{model.data3.content}}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-vertical-padding-half" *ngIf="model.stackTrace">
    <div class=" col-lg-12 col-md-12 col-xs-12 col-sm-12">
      <div class="card">
        <div class="card-header">
          {{'ORDER_IMPORT_ERROR_STACKTRACE'|translate}}
        </div>

        <div class="card-body">
          <div class="form-horizontal">

            <div class="inner-card">
                <div class="form-group col-md-12">
                  <pre class="long-code" id="stackTrace">{{ model.stackTrace }}</pre>
                </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<app-confirm-dialog
  #deleteDialog
  (onConfirm)="rejectOrderManually(model.id)"
  [title]="'ORDER_IMPORT_ERROR_REJECT' | translate"
  [message]="'ORDER_IMPORT_ERROR_REJECT_CONFIRMATION' | translate"
  [positiveButton]="'ORDER_IMPORT_ERROR_REJECT_SHORT' | translate"
  [alertType]="AlertType.DANGER">
</app-confirm-dialog>
