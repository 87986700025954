import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Angular2Multiselects } from '../../../../util/multiselect';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { FieldValidationError, ForwardingNgFormRef, LocalFormGroupValidationErrors } from '../../../../lib/util/services';
import { MultiselectOptionItem, UiConstants } from '../../../../util/core-utils';
import { Invoice, InvoiceService } from '../../../../lib/invoice/invoice/invoice.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Strings } from '../../../../lib/util/strings';
import { TaskRecord } from '../../../../lib/task/task-record.service';
import { TranslateUtils } from '../../../../util/translate';
import { StringKey } from '../../../../app.string-keys';
import { InvoiceSettings } from '../../../../lib/invoice/invoice-settings/invoice-settings.service';
import { ToasterService } from '../../../../fork/angular2-toaster/src/toaster.service';
import { AppValidators } from '../../../../util/app-validators';
import {
  InvoiceSettingsMultiselectOptionItem,
  InvoiceSettingsMultiselectProvider
} from '../../../../lib/invoice/invoice-settings/invoice-settings-multiselect-provider.service';
import { InvoiceBookMultiselectProvider } from '../../../../lib/invoice/invoice-book/invoice-book-multiselect-provider.service';
import InvoiceIntegrationType = InvoiceSettings.InvoiceIntegrationType;

@Component({
  selector: 'app-invoice-storno-dialog',
  templateUrl: './invoice-storno-dialog.component.html',
  styleUrls: ['./invoice-storno-dialog.component.scss']
})
export class InvoiceStornoDialogComponent implements OnInit {

  Invoice = Invoice;
  UiConstants = UiConstants;
  InvoiceSettings = InvoiceSettings;

  model: InvoiceStornoModel = new InvoiceStornoModel();

  dropdownSettings: Angular2Multiselects.Settings;
  @ViewChild('f', {static: true})
  form: NgForm;
  formGroup: FormGroup;
  private formGroupValidationErrors: LocalFormGroupValidationErrors;
  private fieldErrors: FieldValidationError<Invoice.ValidatedField> =
    FieldValidationError.empty<Invoice.ValidatedField>();
  stornoInvoiceBooks: MultiselectOptionItem<number>[] = [];
  invoiceSettingsForStorno: InvoiceSettingsMultiselectOptionItem[] = [];

  inProgress: boolean = false;

  constructor(public dialogRef: MatDialogRef<InvoiceStornoDialogComponent>,
              private translateService: TranslateService,
              private fb: FormBuilder,
              private invoiceService: InvoiceService,
              private invoiceBookMultiselectProvider: InvoiceBookMultiselectProvider,
              private invoiceSettingsMultiselectProvider: InvoiceSettingsMultiselectProvider,
              private toasterService: ToasterService,
              @Inject(MAT_DIALOG_DATA) public data: InvoiceStornoDialogData) {
    this.formGroup = this.createFormGroup(this.fb);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(
      this.createForwardingHtmlForm(),
      this.formGroup
    );
  }

  ngOnInit() {
    this.loadModel();
    this.initDropdownSettings();
  }

  initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .translate(true)
      .build();
  }

  closeDialog(modified: boolean) {
    const result: InvoiceStornoDialogResult = {modified: modified};
    this.dialogRef.close(result);
  }

  save() {
    if (this.hasLocalFieldError() || this.fieldErrors.hasError()) {
      return;
    }
    else {
      this.invoiceStorno();
    }
  }

  hasFieldError(field: Invoice.ValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: Invoice.ValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: Invoice.ValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  private createForwardingHtmlForm() {
    return new ForwardingNgFormRef({
      formFn: () => {
        return this.form;
      }
    });
  }

  private createFormGroup(fb: FormBuilder): FormGroup {
    return fb.group(
      {
        stornoInvoiceSettings: fb.control(
          {value: this.model.invoiceSettings},
          [Validators.required]
        ),
        stornoInvoiceBook: fb.control(
          {value: this.model.invoiceBook},
          [AppValidators.tempValidator({
            validator: Validators.required,
            disabled: () => this.integrationType !== InvoiceIntegrationType.APPWORKS
          })]
        ),
        invoiceNumber: fb.control(
          {value: this.model.invoiceNumber},
          [AppValidators.tempValidator({
            validator: Validators.required,
            disabled: () => this.integrationType !== InvoiceIntegrationType.NONE
          })]
        ),
        taskRevert: fb.control({value: this.model.taskRevert}, []),

      }
    );
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  getTextMaximumLength(): number {
    return UiConstants.maximumVarcharLength;
  }

  loadInvoiceBooksForStorno(q?: string) {
    this.invoiceBookMultiselectProvider.loadActiveWithSettings(this.model.invoiceSettings[0]!.id, q
    ).subscribe((result) => {
      this.stornoInvoiceBooks = result;
    });
  }

  loadInvoiceSettingsForStorno(q?: string) {
    this.invoiceSettingsMultiselectProvider.loadWithPartialRequest({
      profileName: q,
      disabled: false,
      invoiceDirectionType: this.data.original.directionType,
      invoiceIntegrationType: this.integrationType
    }).subscribe((result) => {
      this.invoiceSettingsForStorno = result;
    });
  }

  invoiceStorno() {
    if (this.model.invoiceBook && this.model.selectedInvoiceId) {
      this.inProgress = true;
      this.invoiceService.storno({
        invoiceId: this.model.selectedInvoiceId,
        invoiceSettingsId: this.model.invoiceSettings[0]!.id,
        invoiceBookId: this.model.invoiceBook.length > 0 ? this.model.invoiceBook[0].id : undefined,
        taskRevert: this.model.taskRevert,
        invoiceNumber: Strings.undefinedOrNonEmpty(this.model.invoiceNumber),
        commentTemplate: Strings.undefinedOrNonEmpty(this.model.commentTemplate)
      }).subscribe(
        (result: Invoice.InvoiceStornoResponse) => {
          this.inProgress = false;
          if (result.revertResult) {
            this.toasterService.clear();
            let toastType: string = UiConstants.toastTypeInfo;
            let toastMessageKey: string = 'COMMON_VALUE_TASK_STATE_EDIT_REVERTED';
            switch (result.revertResult) {
              case TaskRecord.RevertResult.EACH_REVERT_DONE:
                toastType = UiConstants.toastTypeSuccess;
                toastMessageKey = 'TASK_RECORD_REVERT_RESULT_EACH_REVERT_DONE';
                break;
              case TaskRecord.RevertResult.EACH_REVERT_FAILED:
                toastType = UiConstants.toastTypeError;
                toastMessageKey = 'TASK_RECORD_REVERT_RESULT_EACH_REVERT_FAILED';
                break;
              case TaskRecord.RevertResult.FAILED_TO_SAVE_REVERT_STATE:
                toastType = UiConstants.toastTypeError;
                toastMessageKey = 'TASK_RECORD_REVERT_RESULT_FAILED_TO_SAVE_REVERT_STATE';
                break;
              case TaskRecord.RevertResult.INIT_ERROR:
                toastType = UiConstants.toastTypeError;
                toastMessageKey = 'TASK_RECORD_REVERT_RESULT_INIT_ERROR';
                break;
              case TaskRecord.RevertResult.INVALID_TASK_RECORD_STATE:
                toastType = UiConstants.toastTypeError;
                toastMessageKey = 'TASK_RECORD_REVERT_RESULT_INVALID_TASK_RECORD_STATE';
                break;
              case TaskRecord.RevertResult.SOME_REVERT_DONE:
                toastType = UiConstants.toastTypeWarning;
                toastMessageKey = 'TASK_RECORD_REVERT_RESULT_SOME_REVERT_DONE';
                break;
            }
            const toastTitleKey: string = 'COMMON_VALUE_TASK_STATE_EDIT_REVERTED';
            this.translateService.get([toastTitleKey, toastMessageKey]).subscribe((o) => {
              this.toasterService.pop({
                type: toastType,
                timeout: UiConstants.ToastTimeoutLong,
                title: TranslateUtils.extractValueFromObject(o, toastTitleKey),
                body: TranslateUtils.extractValueFromObject(o, toastMessageKey)
              });
            });

          }
          this.closeDialog(true);
        },
        (error: any) => {
          this.inProgress = false;
          if (error instanceof FieldValidationError) {
            this.fieldErrors = error.withForm(this.form);
            if (this.fieldErrors.hasError(Invoice.ValidatedField.UNKNOWN)) {
              this.translateService.get(StringKey.COMMON_VALIDATION_ERROR).subscribe(text => {
                this.toasterService.pop({
                  timeout: UiConstants.ToastTimeoutLong,
                  type: UiConstants.toastTypeError,
                  title: text,
                  body: this.fieldErrors.getErrorText(Invoice.ValidatedField.UNKNOWN)
                });
              });
            }
          }
        }
      );
    }
  }

  get integrationType(): InvoiceSettings.InvoiceIntegrationType {
    return this.data.original.invoiceSettings.invoiceIntegrationType;
  }

  private loadModel() {
    this.model = new InvoiceStornoModel();
    this.model.selectedInvoiceId = this.data.original.id;
    if (this.data.original.invoiceSettings) {
      this.invoiceSettingsMultiselectProvider.getById(this.data.original.invoiceSettings.id).subscribe(s => {
        this.model.invoiceSettings = [s];
      });
    }
    if (this.data.original.invoiceBookId) {
      this.invoiceBookMultiselectProvider.getById(this.data.original.invoiceBookId).subscribe(b => {
        this.model.invoiceBook = [b];
      });
    }
  }
}

class InvoiceStornoModel {
  invoiceNumber: string = '';
  invoiceSettings: InvoiceSettingsMultiselectOptionItem[] = [];
  invoiceBook: MultiselectOptionItem<number>[] = [];
  selectedInvoiceId?: number = undefined;
  taskRevert: boolean = true;
  commentTemplate: string = '';
}

export interface InvoiceStornoDialogData {
  original: Invoice.Invoice;
}

export interface InvoiceStornoDialogResult {
  modified: boolean;
}
