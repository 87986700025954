import { DqlQuery, Query } from '../field';

export class DeliveryMethod {

  readonly id: Query.OrderField;
  readonly disabled: Query.OrderField;
  readonly creationTime: Query.OrderField;
  readonly externalId: Query.OrderField;
  readonly name: Query.OrderField;

  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this.id = new DqlQuery.OrderField(prefix + 'id');
    this.disabled = new DqlQuery.OrderField(prefix + 'disabled');
    this.creationTime = new DqlQuery.OrderField(prefix + 'creation time');
    this.externalId = new DqlQuery.OrderField(prefix + 'external id');
    this.name = new DqlQuery.OrderField(prefix + 'name');
  }

}
