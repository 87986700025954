/* eslint-disable */
import { FormRecordFieldView, FormRecordFieldViewFactory, } from './form-utils';
import { ComponentFactoryResolver, ComponentRef, Injector, } from '@angular/core';
import { FormRecordNumberFieldComponent } from '../../admin/form/form-record/fields/number/form-record-number-field.component';
/* eslint-enable */

export class FormRecordNumberFieldViewFactory implements FormRecordFieldViewFactory {

  constructor(
    private injector: Injector,
    private cfr: ComponentFactoryResolver) {
  }

  createView(): ComponentRef<FormRecordFieldView> {
    const cmp = this.cfr.resolveComponentFactory(FormRecordNumberFieldComponent)
      .create(this.injector);
    return cmp;
  }

}
