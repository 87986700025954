/* eslint-disable */
import { OptionItem, TypeItem } from './core-utils';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';
import { SurveyDisabled } from '../lib/survey/survey.service';
import { DisabledItem } from './search-utils';
/* eslint-enable */

export class SurveySearchModel {
  id: string;
  name: string;
  disabled?: DisabledItem;

  public isEmpty(): boolean {
    return this.id.length === 0
      && this.name.length === 0
      && OptionItem.idOrUndefined(this.disabled) === undefined
      ;
  }
}

export class SurveyDisabledItem extends OptionItem<SurveyDisabled> {
}

export class SurveyDisabledItems {
  public static readonly values: SurveyDisabled[] = ['ALL', 'ACTIVE', 'INACTIVE'];
}

export class SurveyRecordSearchModel {
  name: string = '';
  ownerUser?: TypeItem = undefined;
  updateTimeFrom: NgbDateStruct | null = null;
  updateTimeTo: NgbDateStruct | null = null;
  disabled?: DisabledItem;

  public isEmpty(): boolean {
    return this.name.length === 0
      && this.ownerUser === undefined
      && this.updateTimeFrom === null
      && this.updateTimeTo === null
      && OptionItem.idOrUndefined(this.disabled) === undefined
      ;
  }
}

export class SurveyItem extends OptionItem<number> {
  disabled: boolean = false;
}

export class SurveyEditModel {
  name: string = '';
  description: string = '';
  externalId: string = '';
  hasPdfTemplate: boolean = false;
  hasThumbnail: boolean = false;
}

export class SurveyCreateModel extends SurveyEditModel {
}
