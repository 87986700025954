/* eslint-disable */
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { DownloadedFile, } from '../util/downloaded-files';
import { Observable } from 'rxjs';
import { FormResource, FormResourcePathGenerator } from '../form/form-resource.service';
import { UserProfile } from '../auth.service';
import { FormRecordResource } from '../form/form-record-resource.service';
import { ResourceQueryResult } from '../util/services';
import { UrlBuilder } from '../../util/url-builder';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from '../util/http-services';

/* eslint-enable */

@Injectable()
// url: '/surveys/{!:survey_id}/records'
export class SurveyRecordResourceService extends BaseHttpService {

  // <editor-fold desc="CRUD">

  query(request: SurveyRecordResource.QueryRequest): Observable<ResourceQueryResult<SurveyRecordResource.SurveyRecord>> {
    return this.http.get<ResourceQueryResult<SurveyRecordResource.SurveyRecord>>(
      this.url + `${request.survey_id}/records`,
      this.parseParams(request)
    );
  }

  get(request: SurveyRecordResource.GetRequest): Observable<SurveyRecordResource.SurveyRecord> {
    return this.http.get<SurveyRecordResource.SurveyRecord>(
      this.url + `${request.survey_id}/records/${request.survey_record_id}`,
      this.parseParams(request)
    );
  }

  create(request: SurveyRecordResource.CreateRequest): Observable<SurveyRecordResource.SurveyRecord> {
    return this.http.post<SurveyRecordResource.SurveyRecord>(this.url + `${request.survey_id}/records`, request);
  }

  update(request: SurveyRecordResource.UpdateRequest): Observable<SurveyRecordResource.SurveyRecord> {
    return this.http.put<SurveyRecordResource.SurveyRecord>(this.url + `${request.survey_id}/records/${request.survey_record_id}`, request);
  }

  setDisabled(request: SurveyRecordResource.DisableRequest): Observable<SurveyRecordResource.SurveyRecord> {
    return this.http.patch<SurveyRecordResource.SurveyRecord>(
      this.url + `${request.survey_id}/records/${request.survey_record_id}/disabled`,
      request
    );
  };

  // </editor-fold>

  // <editor-fold desc="Form">

  getForm(request: FormResource.GetFormRequest): Observable<FormResource.Form> {
    return this.http.get<FormResource.Form>(this.url + FormResourcePathGenerator.GetForm(request));
  }

  createFormGroup(request: FormResource.CreateGroupRequest): Observable<FormResource.CreateGroupResponse> {
    return this.http.post<FormResource.CreateGroupResponse>(this.url + FormResourcePathGenerator.CreateGroup(request), request);
  }

  updateFormGroup(request: FormResource.UpdateGroupRequest): Observable<FormResource.UpdateGroupResponse> {
    return this.http.put<FormResource.UpdateGroupResponse>(this.url + FormResourcePathGenerator.UpdateGroup(request), request);
  }

  disableFormGroup(request: FormResource.DisableGroupRequest): Observable<FormResource.DisableGroupResponse> {
    return this.http.patch<FormResource.DisableGroupResponse>(this.url + FormResourcePathGenerator.DisableGroup(request), request);
  }

  moveFormGroup(request: FormResource.MoveGroupRequest): Observable<FormResource.MoveGroupResponse> {
    return this.http.post<FormResource.MoveGroupResponse>(this.url + FormResourcePathGenerator.MoveGroup(request), null);
  }

  createFormField(request: FormResource.CreateFieldRequest): Observable<FormResource.CreateFieldResponse> {
    return this.http.post<FormResource.CreateFieldResponse>(this.url + FormResourcePathGenerator.CreateField(request), request);
  }

  updateFormField(request: FormResource.UpdateFieldRequest): Observable<FormResource.UpdateFieldResponse> {
    return this.http.post<FormResource.UpdateFieldResponse>(this.url + FormResourcePathGenerator.UpdateField(request), request);
  }

  disableFormField(request: FormResource.DisableFieldRequest): Observable<FormResource.DisableFieldResponse> {
    return this.http.patch<FormResource.DisableFieldResponse>(this.url + FormResourcePathGenerator.DisableField(request), request);
  }

  moveFormFieldToGroup(request: FormResource.MoveFieldToGroupRequest): Observable<FormResource.MoveFieldToGroupResponse> {
    return this.http.post<FormResource.MoveFieldToGroupResponse>(this.url + FormResourcePathGenerator.MoveFieldToGroup(request), null);
  }

  // </editor-fold>

  constructor(private http: HttpClient, private resourceHelper: ResourceHelper) {
    super(resourceHelper, '/surveys/');
  }

  downloadPdf(request: SurveyRecordResource.DownloadAttachmentRequest): Observable<DownloadedFile> {
    const url = UrlBuilder.create(
      'surveys/:surveyId/records/:surveyRecordId/pdf-export')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .namedNumber('surveyId', request.survey_id)
      .namedNumber('surveyRecordId', request.survey_record_id)
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob'
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

}

export namespace SurveyRecordResource {

  // <editor-fold desc="CRUD">

  export interface SurveyRecord {
    survey_id: number;
    id: number;
    disabled: boolean;
    creation_time: string;
    update_time: string;
    name?: string;
    owner_user_id: UserProfile;
    form_record?: FormRecordResource.FormRecord; // non-null if requested
    customer_record_id?: number;
  }

  export interface QueryRequest {
    with_form_record: boolean;
    survey_id: number;
    id?: string;
    disabled?: boolean;
    parent_disabled?: boolean;
    name?: string;
    owner_user_id?: number,
    owner_user_name?: string,
    q?: string;
    fields?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
    update_time_from?: string;
    update_time_to?: string;
  }

  export interface GetRequest {
    survey_id: number;
    survey_record_id: number;
    with_form_record: boolean;
  }

  export interface CreateRequest {
    survey_id: number;
    name?: string;
    form_record: FormRecordResource.FormRecordCreateRequest;
    with_form_record: boolean;
    customer_record_id?: number;
  }

  export interface UpdateRequest {
    survey_id: number;
    survey_record_id: number;
    name?: string;
    form_record: FormRecordResource.FormRecordUpdateRequest;
    with_form_record: boolean;
    customer_record_id?: number;
  }

  export interface DisableRequest {
    survey_id: number;
    survey_record_id: number;
    disabled: boolean;
    with_form_record: boolean;
  }

  export interface DownloadAttachmentRequest {
    survey_id: number;
    survey_record_id: number;
  }

  // </editor-fold>

}
