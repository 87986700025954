<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">{{titleKey | translate}}</h4>
    <button type="button" class="close" (click)="closeDialog()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-file-upload
      [uploadPath]="uploadPath"
      [acceptedMimeType]="acceptedMimeType"
      (onResult)="onResult($event)"
      #uploadComponent>
    </app-file-upload>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="closeDialog()">{{'COMMON_BUTTON_CLOSE' | translate}}</button>
  </div><!-- /.modal-footer -->
</div><!-- /.modal-content -->
