<div class="modal-content">
  <div class="modal-header">
    <p class="modal-title"><strong>{{getTitleKey() | translate}}</strong></p>
    <button type="button" class="close" (click)="closeDialog()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <mat-dialog-content>
    <form (ngSubmit)="submit()" #f="ngForm" id="form"
          [formGroup]="formGroup" novalidate>

      <label class="col-form-label mw-100 form-control-label font-weight-bold">
        {{'PROCESS_CREATE_BASIC_DATA'|translate}}
      </label>

      <div class="form-group row" [ngClass]="{ 'has-danger': hasLocalFieldError('workflow')}">
        <label
          class="col-form-label form-control-label col-md-4 detail-title text-md-right"
          [class.required-field-label]="!readonly">
          {{'PROCESS_CREATE_WORKFLOW'|translate}}
        </label>
        <div class="col-md-6">
          <div class="mw-100">
            <angular2-multiselect
              [(ngModel)]="model.workflow"
              id="workflow"
              formControlName="workflow"
              [ngClass]="{ 'form-control-danger': hasLocalFieldError('workflow') }"
              [data]="model.workflowList"
              [readonly]="!create"
              [readonlyBorderEnabled]="!create"
              (onRemoteSearch)="onWorkflowSearch($event.target.value)"
              [settings]="dropdownSettings">
            </angular2-multiselect>
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('workflow')">
          <span
            *ngIf="hasLocalFieldError('workflow', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="alert alert-danger" *ngIf="create && model.selectedWorkflow && !model.selectedWorkflow?.firstTaskId">
        <label>{{'PROCESS_CREATE_SELECTED_WORKFLOW_INVALID' | translate}}</label>
      </div>

      <div class="form-group row"
           [ngClass]="{ 'has-danger': hasLocalFieldError('name') || hasFieldError(Process.ValidatedField.NAME) }">
        <label for="name" class="col-form-label form-control-label col-md-4 detail-title text-md-right"
               [class.required-field-label]="!readonly && isRequiredField(ProcessFieldType.NAME)">
          {{'COMMON_NAME'|translate}}
        </label>
        <div class="col-md-6">
          <div class="mw-100">
            <input type="text"
                   [(ngModel)]="model.name"
                   maxlength="{{getTextMaximumLength()}}"
                   formControlName="name"
                   trim="blur"
                   validateOnBlur
                   [validateFormControl]="formGroup.controls['name']"
                   (ngModelChange)="removeFieldError(Process.ValidatedField.NAME)"
                   [ngClass]="{ 'form-control-danger': hasLocalFieldError('name') || hasFieldError(Process.ValidatedField.NAME) }"
                   class="form-control"
                   name="name"
                   id="name"
                   placeholder="{{'COMMON_NAME'|translate}}"
                   [readOnly]="readonly">
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('name')">
              <span
                *ngIf="hasLocalFieldError('name', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
            </div>
            <div class="form-control-feedback" *ngIf="hasFieldError(Process.ValidatedField.NAME)">
              <span>{{getFieldErrorText(Process.ValidatedField.NAME)}}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row"
           [ngClass]="{ 'has-danger': hasLocalFieldError('externalId') || hasFieldError(Process.ValidatedField.EXTERNAL_ID) }">
        <label for="externalId" class="col-form-label form-control-label col-md-4 detail-title text-md-right"
               [class.required-field-label]="edit || (!readonly && isRequiredField(ProcessFieldType.EXTERNAL_ID))">
          {{'USER_LABEL_EXTERNAL_ID'|translate}}
        </label>
        <div class="col-md-6">
          <div class="mw-100">
            <input type="text"
                   [(ngModel)]="model.externalId"
                   maxlength="{{getTextMaximumLength()}}"
                   formControlName="externalId"
                   trim="blur"
                   validateOnBlur
                   [validateFormControl]="formGroup.controls['externalId']"
                   (ngModelChange)="removeFieldError(Process.ValidatedField.EXTERNAL_ID)"
                   [ngClass]="{ 'form-control-danger': hasLocalFieldError('externalId') || hasFieldError(Process.ValidatedField.EXTERNAL_ID) }"
                   class="form-control"
                   name="externalId"
                   id="externalId"
                   placeholder="{{'USER_LABEL_EXTERNAL_ID'|translate}}"
                   [readOnly]="readonly">
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('externalId')">
              <span
                *ngIf="hasLocalFieldError('externalId', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
            </div>
            <div class="form-control-feedback" *ngIf="hasFieldError(Process.ValidatedField.EXTERNAL_ID)">
              <span>{{getFieldErrorText(Process.ValidatedField.EXTERNAL_ID)}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row"
           [ngClass]="{ 'has-danger': hasLocalFieldError('description') || hasFieldError(Process.ValidatedField.DESCRIPTION) }">
        <label for="description" class="col-form-label form-control-label col-md-4 detail-title text-md-right"
               [class.required-field-label]="!readonly && isRequiredField(ProcessFieldType.DESCRIPTION)">
          {{'COMMON_DESCRIPTION'|translate}}
        </label>
        <div class="col-md-6">
          <div class="mw-100">
            <input type="text"
                   [(ngModel)]="model.description"
                   maxlength="{{getTextMaximumLength()}}"
                   formControlName="description"
                   trim="blur"
                   validateOnBlur
                   [validateFormControl]="formGroup.controls['description']"
                   (ngModelChange)="removeFieldError(Process.ValidatedField.DESCRIPTION)"
                   [ngClass]="{ 'form-control-danger': hasLocalFieldError('description') || hasFieldError(Process.ValidatedField.NAME) }"
                   class="form-control"
                   name="description"
                   id="description"
                   placeholder="{{'COMMON_DESCRIPTION'|translate}}"
                   [readOnly]="readonly">
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('description')">
              <span
                *ngIf="hasLocalFieldError('description', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
            </div>
            <div class="form-control-feedback" *ngIf="hasFieldError(Process.ValidatedField.DESCRIPTION)">
              <span>{{getFieldErrorText(Process.ValidatedField.DESCRIPTION)}}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row"
           [ngClass]="{ 'has-danger': hasLocalFieldError('deadlineDate') || hasFieldError(Process.ValidatedField.DEADLINE) }">
        <label class="col-form-label col-md-4 mw-100 form-control-label text-md-right"
               [class.required-field-label]="!readonly && isRequiredField(ProcessFieldType.DEADLINE)">{{'TASK_LABEL_DEADLINE'|translate}}</label>
        <div class="col-md-6">
          <div class="row m-0">
            <div class="input-group">
              <input placeholder="{{'DATE_TIME_PICKER_PLACEHOLDER_DATE' | translate}}" type="text"
                     class="form-control"
                     [(ngModel)]="model.deadlineDate"
                     formControlName="deadlineDate"
                     name="deadlineDate"
                     id="deadlineDate"
                     (ngModelChange)="removeFieldError(Process.ValidatedField.DEADLINE)"
                     [ngClass]="{ 'form-control-danger': hasLocalFieldError('deadlineDate') || hasFieldError(Process.ValidatedField.DEADLINE) }"
                     ngbDatepicker #deadline_picker="ngbDatepicker"
                     [readOnly]="readonly">
              <span class="add-on input-group-addon-gray" (click)="deadline_picker.toggle()" *ngIf="!readonly">
                      <i class="icomoon icomoon-calendar"></i>
                    </span>
            </div>
          </div>
          <div class="form-control-feedback" *ngIf="hasLocalFieldError('deadlineDate')">
              <span
                *ngIf="hasLocalFieldError('deadlineDate', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
              <span
                *ngIf="hasLocalFieldError('deadlineDate', 'validateLocalDate')">{{'COMMON_VALIDATION_MESSAGE_INVALID_LOCAL_DATE' | translate}}</span>
          </div>
          <div class="form-control-feedback" *ngIf="hasFieldError(Process.ValidatedField.DEADLINE)">
            <span>{{getFieldErrorText(Process.ValidatedField.DEADLINE)}}</span>
          </div>

        </div>
      </div>

      <label class="col-form-label mw-100 form-control-label font-weight-bold">
        {{'PROCESS_CREATE_TASK_RECORD_CONFIG'|translate}}
      </label>

      <!--TaskRecordConfig-->

      <div *ngIf="showAdditionalFields">
        <div class="form-group row">
          <label for="name" class="col-form-label form-control-label col-md-4 detail-title">
            {{'COMMON_CREATION_TIME'|translate}}
          </label>
          <div class="col-md-6">
            <div class="mw-100">
              <label
                class="form-control detail-input">{{model.process.creationTime.toUtcIsoString() | date: 'short'}}</label>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="name" class="col-form-label form-control-label col-md-4 detail-title">
            {{'COMMON_UPDATE_TIME'|translate}}
          </label>
          <div class="col-md-6">
            <div class="mw-100">
              <label
                class="form-control detail-input">{{model.process.updateTime.toUtcIsoString() | date: 'short'}}</label>
            </div>
          </div>
        </div>
        <div class="form-group row" *ngIf="model.process.startTime">
          <label for="name" class="col-form-label form-control-label col-md-4 detail-title">
            {{'PROCESS_START_TIME'|translate}}
          </label>
          <div class="col-md-6">
            <div class="mw-100">
              <label
                class="form-control detail-input">{{model.process.startTime.toUtcIsoString() | date: 'short'}}</label>
            </div>
          </div>
        </div>
        <div class="form-group row" *ngIf="model.process.finishTime">
          <label for="name" class="col-form-label form-control-label col-md-4 detail-title">
            {{'PROCESS_FINISH_TIME'|translate}}
          </label>
          <div class="col-md-6">
            <div class="mw-100">
              <label
                class="form-control detail-input">{{model.process.finishTime.toUtcIsoString() | date: 'short'}}</label>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="name" class="col-form-label form-control-label col-md-4 detail-title">
            {{'COMMON_CREATOR_USER'|translate}}
          </label>
          <div class="col-md-6">
            <div class="mw-100">
              <label class="form-control detail-input">{{model.getCreatorUserName()}}</label>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="name" class="col-form-label form-control-label col-md-4 detail-title">
            {{'COMMON_LAST_EDITOR_USER'|translate}}
          </label>
          <div class="col-md-6">
            <div class="mw-100">
              <label class="form-control detail-input">{{model.getLastModifierUserName()}}</label>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="name" class="col-form-label form-control-label col-md-4 detail-title">
            {{'COMMON_VERSION'|translate}}
          </label>
          <div class="col-md-6">
            <div class="mw-100">
              <label class="form-control detail-input">v{{model.process.workflow.version}}</label>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="name" class="col-form-label form-control-label col-md-4 detail-title">
            {{'COMMON_STATE'|translate}}
          </label>
          <div class="col-md-6">
            <div class="mw-100">
              <label class="form-control detail-input">{{model.processStateKey | translate}}</label>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row"
           [ngClass]="{ 'has-danger': hasLocalFieldError('namePattern')}">
        <label class="col-form-label col-md-4 mw-100 form-control-label text-md-right">
          {{'TASK_RECORD_CREATE_NAME_TEMPLATE'|translate}}
        </label>
        <div class="col-md-6">
          <div class="mw-100">
            <app-material-tag-input
              formControlName="namePattern"
              [(ngModel)]="model.taskRecordNameTemplate"
              [placeholderStringKey]="'TASK_RECORD_CREATE_NAME_TEMPLATE'"
              [autocompleteItems]="ProcessTaskRecordConfigNameTemplate.availableSequenceBlocks"
              [separatorCharacter]="ProcessTaskRecordConfigNameTemplate.sequenceBlockSeparator"
              [generatedStringExampleMap]="ProcessTaskRecordConfigNameTemplate.sequenceBlockExampleMap"
              [separatorKeyCodes]="ProcessTaskRecordConfigNameTemplate.separatorKeysCodes"
              [shouldGenerateExample]="true"
              [form]="form"
              [readonly]="readonly">
            </app-material-tag-input>
          </div>
        </div>
      </div>

    </form>
  </mat-dialog-content>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="closeDialog()">
      {{'COMMON_BUTTON_CANCEL' | translate}}
    </button>
    <button type="submit" class="btn btn-primary" form="form" *ngIf="!readonly">
      {{getSubmitButtonLabel() | translate}}
    </button>
  </div>
</div>
