import { Injectable } from '@angular/core';
import { MultiselectProvider } from '../multiselect-provider';
import { MultiselectOptionItem, UiConstants } from '../../util/core-utils';
import { Observable, Observer } from 'rxjs';
import { OrderType, ResourceQueryResult, Services } from '../util/services';
import { Set } from 'immutable';
import { StockItem, StockItemQuery, StockItemService, StockItemType, UnitPrice } from './stock-item.service';
import { LedgerNumber } from '../ledger/number/ledger-number.service';

@Injectable()
export class StockItemMultiselectProvider implements MultiselectProvider {

  private multiselectQueryRequest: StockItemQuery = {
    page_number: 1,
    number_of_items: UiConstants.autocompletePageSize,
    no_progress_bar: true,
    order: Services.createOrderFieldParameter(StockItem.Keys.toOrderFieldKey,
      Set.of({field: StockItem.OrderField.NAME, type: OrderType.ASC})),
    disabled: undefined,
    fields: ['id', 'name', 'disabled', 'external_id', 'type',
      'product_code',
      'unit',
      'unit_price',
      'hun_vtsz_number',
      'ledger_number'].join(','),
    q: undefined
  };

  constructor(private stockItemService: StockItemService) {
  }

  load(queryRequest: StockItemQuery): Observable<StockItemMultiselectOptionItem[]> {
    return Observable.create((observer: Observer<StockItemMultiselectOptionItem[]>) => {
      this.stockItemService.query(queryRequest).subscribe((result: ResourceQueryResult<StockItem>) => {
          observer.next(result.items.map(item => this.toMultiselectOptionItem(item!)));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  loadAll(predicate?: string): Observable<StockItemMultiselectOptionItem[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.q = predicate;
    return this.load(queryRequest);
  }

  loadActive(predicate?: string): Observable<StockItemMultiselectOptionItem[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.q = predicate;
    queryRequest.disabled = false;
    return this.load(queryRequest);
  }

  getById(id: number): Observable<StockItemMultiselectOptionItem> {
    return Observable.create((observer: Observer<StockItemMultiselectOptionItem>) => {
      this.stockItemService.get({
        id: id
      }).subscribe((result: StockItem) => {
          observer.next(this.toMultiselectOptionItem(result!));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  getByIds(ids: number[]): Observable<StockItemMultiselectOptionItem[]> {
    const queryRequest = Object.assign([], this.multiselectQueryRequest);
    queryRequest.id = ids.join(',');
    return this.load(queryRequest);
  }

  toMultiselectOptionItem(item: StockItem): StockItemMultiselectOptionItem {
    const ret = new StockItemMultiselectOptionItem();
    ret.id = item.id;
    ret.itemName = item.name;
    ret.disabled = item.disabled;
    ret.type = item.type;
    ret.itemSubtitle = item.product_code;
    ret.external_id = item.external_id;
    ret.product_code = item.product_code;
    ret.name = item.name;
    ret.unit = item.unit;
    ret.unit_price = item.unit_price;
    ret.hun_vtsz_number = item.hun_vtsz_number;
    ret.ledgerNumber = item.ledger_number ? LedgerNumber.toPublic(item.ledger_number) : undefined;
    return ret;
  }

}

export class StockItemMultiselectOptionItem extends MultiselectOptionItem<number> {
  type: StockItemType;
  external_id: string;
  product_code: string;
  disabled: boolean;
  name: string;
  unit: string;
  unit_price: UnitPrice;
  hun_vtsz_number?: string;
  ledgerNumber?: LedgerNumber.LedgerNumber;
}
