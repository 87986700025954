/* eslint-disable */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { InvoiceBook, InvoiceBookService } from '../../../../lib/invoice/invoice-book/invoice-book.service';
import { Set } from 'immutable';
import { OrderType, QueryResult } from '../../../../lib/util/services';
import { MultiselectOptionItem, QueryFieldModel, SelectUtils, UiConstants } from '../../../../util/core-utils';
import { LoadingHandler } from '../../../../lib/loading-handler';
import { RightModel } from '../../../../app.rights';
import { RightResolver, RightService } from '../../../../lib/right.service';
import { combineLatest, Observable } from 'rxjs';
import { BreadcrumbParent } from '../../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { TranslateService } from '@ngx-translate/core';
import { StateName } from '../../../../app.state-names';
import { Angular2Multiselects } from '../../../../util/multiselect';
import { InvoiceBookSearch, InvoiceBookSearchService } from '../../../../lib/invoice-book-search-service.component';
import { Strings } from '../../../../lib/util/strings';
import { Arrays } from '../../../../lib/util/arrays';
import { DisabledEnum, DisabledItem } from '../../../../util/search-utils';
import { InvoiceSettings, InvoiceSettingsService } from '../../../../lib/invoice/invoice-settings/invoice-settings.service';
import { DropdownItemType } from '../../../../shared/dropdown/dropdown-item/dropdown-item-type';
import InvoiceIntegrationType = InvoiceSettings.InvoiceIntegrationType;

/* eslint-enable */

@Component({
  selector: 'app-invoice-book-list',
  templateUrl: './invoice-book-list.component.html',
  styleUrls: ['./invoice-book-list.component.scss']
})
export class InvoiceBookListComponent implements OnInit, OnDestroy {

  invoiceBookList: InvoiceBook.InvoiceBook[] = [];
  queryModel: QueryFieldModel<InvoiceBook.OrderField> = new QueryFieldModel(InvoiceBook.OrderField.ID, OrderType.DESC);
  InvoiceBook = InvoiceBook;
  DropdownItemType = DropdownItemType;

  rightModel: RightModel = RightModel.empty();
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  showSearch: boolean = false;
  searchModel: InvoiceBookSearchModel = new InvoiceBookSearchModel();
  dropdownSettings?: Angular2Multiselects.Settings;
  invoiceSettings: MultiselectOptionItem<number>[] = [];
  disabledItems: DisabledItem[] = [];
  SelectUtils = SelectUtils;
  UiConstants = UiConstants;

  constructor(private invoiceBookService: InvoiceBookService,
              private invoiceBookSearchService: InvoiceBookSearchService,
              private translateService: TranslateService,
              private invoiceSettingsService: InvoiceSettingsService,
              private rightService: RightService) {
  }

  ngOnInit() {
    this.translateService.get('MENU_NAVBAR_MENU_INVOICE_BOOK').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.loadRightModels();
    this.loadSearch(() => {
      this.showSearch = !this.searchModel.isEmpty();
      this.initDisabledOptions(DisabledEnum.FALSE);
      this.loadList();
    });
    this.loadInvoiceSettings();
    this.initDropdown();
  }

  loadInvoiceSettings(q?: string) {
    this.invoiceSettingsService.query({
      profileName: q,
      invoiceIntegrationType: InvoiceIntegrationType.APPWORKS,
      invoiceDirectionType: 'OUTBOUND',
      paging: {
        numberOfItems: UiConstants.autocompletePageSize,
        pageNumber: 1
      },
      noProgressBar: true
    }).subscribe(
      (result: QueryResult<InvoiceSettings.InvoiceSettings>) => {
        this.invoiceSettings = result.items.toArray().map(s => ({id: s.id, itemName: s.profileName}));
      }
    );
  }

  initDropdown() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .remoteSearch(true)
      .build();
  }

  private loadSearch(completion: () => void) {
    const obs: Observable<SearchLoadResult> = combineLatest(
      this.invoiceBookSearchService.getSearchData({}),
      (storedSearchData: InvoiceBookSearch.SearchDataResult) => {
        const result: SearchLoadResult = {
          storedSearchData: storedSearchData
        };
        return result;
      }
    );
    obs.subscribe(
      (result: SearchLoadResult) => {
        this.postInitSearch(result.storedSearchData);
        completion();
      }
    );
  }

  private postInitSearch(storedSearchData: InvoiceBookSearch.SearchDataResult) {
    this.queryModel.itemsPerPage = storedSearchData.searchData.itemsPerPage;
    this.queryModel.currentPage = storedSearchData.searchData.pageNumber;
    this.queryModel.setOrder(storedSearchData.searchData.order);
    this.searchModel.prefix = storedSearchData.searchData.prefix;
    this.searchModel.comment = storedSearchData.searchData.comment;
    this.searchModel.externalId = storedSearchData.searchData.externalId;
    this.searchModel.invoiceSettings = storedSearchData.searchData.invoiceSettings;
    this.searchModel.disabled = storedSearchData.searchData.disabled;
  }

  private initDisabledOptions(initValue: DisabledEnum) {
    this.disabledItems = [];
    const disabledEnums: DisabledEnum[] = [DisabledEnum.NONE, DisabledEnum.FALSE, DisabledEnum.TRUE];
    Arrays.iterateByIndex(disabledEnums, (key) => {
      const item = new DisabledItem();
      item.id = key;
      this.translateService.get('COMMON_VALUE_DISABLED_ENUM_' + key).subscribe(
        (text: string) => {
          item.text = text;
        }
      );
      this.disabledItems.push(item);
      if (!this.searchModel.disabled) {
        if (key === initValue) {
          this.searchModel.disabled = item;
        }
      }
    });
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: InvoiceBook.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  setDisabled(event: any, invoiceBook: InvoiceBook.InvoiceBook, disabled: boolean) {
    this.invoiceBookService.setDisabled({
      invoiceBookId: invoiceBook.id,
      disabled: disabled
    }).subscribe(
      (result) => {
        LoadingHandler.getInstance().refresh();
        this.loadList();
      },
      (error: any) => {
        this.loadList();
      }
    );
  }

  onSearchClicked() {
    this.loadList();
  }

  onSearchReset() {
    this.invoiceBookSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.showSearch = true;
          this.loadList(1);
        });
      }
    );
  }

  private saveSearch() {
    const request = {
      searchData: {
        itemsPerPage: this.queryModel.itemsPerPage,
        pageNumber: this.queryModel.currentPage,
        order: this.queryModel.getOrder(),
        prefix: this.searchModel.prefix,
        comment: this.searchModel.comment,
        externalId: this.searchModel.externalId,
        invoiceSettings: this.searchModel.invoiceSettings,
        disabled: this.searchModel.disabled
      }
    };
    this.invoiceBookSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  private loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();
    const disabled: boolean | undefined = !this.searchModel.disabled ||
    this.searchModel.disabled.id === DisabledEnum.NONE ?
      undefined : this.searchModel.disabled.id === DisabledEnum.TRUE;
    this.invoiceBookService.query({
      prefix: Strings.undefinedOrNonEmpty(this.searchModel.prefix),
      comment: Strings.undefinedOrNonEmpty(this.searchModel.comment),
      disabled: disabled,
      invoiceSettingsIds: Set.of(...this.searchModel.invoiceSettings.map(s => s.id)),
      orders: Set.of(order),
      paging: {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      }
    }).subscribe(
      (result: QueryResult<InvoiceBook.InvoiceBook>) => {
        this.invoiceBookList = result.items.toArray();
        this.queryModel.currentPage = requestedPage;
        this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
        this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
      }
    );
  }

  getDisabled(): boolean | undefined {
    if (this.searchModel.disabled!.id === DisabledEnum.FALSE) {
      return false;
    }
    else if (this.searchModel.disabled!.id === DisabledEnum.TRUE) {
      return true;
    }
    return undefined;
  }

  ngOnDestroy() {
    this.saveSearch();
  }

}

export class InvoiceBookSearchModel {
  prefix: string = '';
  comment: string = '';
  externalId: string = '';
  invoiceSettings: MultiselectOptionItem<number>[] = [];
  disabled: DisabledItem | null = null;

  public isEmpty(): boolean {
    return this.prefix.length === 0
      && this.comment.length === 0
      && this.externalId.length === 0
      && this.invoiceSettings.length === 0
      && (this.disabled ? this.disabled.id === DisabledItem.active().id : true)
      ;
  }
}

interface SearchLoadResult {
  storedSearchData: InvoiceBookSearch.SearchDataResult,
}
