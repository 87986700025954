import { AfterViewInit, Component, ContentChildren, Input, OnInit, QueryList } from '@angular/core';
import { DropdownItemComponent } from '../dropdown-item/dropdown-item.component';

@Component({
  selector: 'app-dropdown-button',
  templateUrl: './dropdown-button.component.html',
  styleUrls: ['./dropdown-button.component.scss']
})
export class DropdownButtonComponent implements OnInit, AfterViewInit {

  @ContentChildren(DropdownItemComponent)
  dropdownItems: QueryList<DropdownItemComponent>;

  @Input()
  buttonIconClass: string;

  viewInitialized: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.viewInitialized = true;
  }

  anyChildVisible(): boolean {
    if (this.viewInitialized) {
      return this.dropdownItems.length > 0;
    }
    return false;
  }
}
