import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StockRecordSelectorModel } from '../stock-record-selector-dialog.component';
import { StockItemUtils } from '../../../../util/stock/stock-item-utils';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[stock-record-selector-list-item]',
  templateUrl: './stock-record-selector-list-item.component.html',
  styleUrls: ['./stock-record-selector-list-item.component.scss']
})
export class StockRecordSelectorListItemComponent implements OnInit {

  StockItemUtils = StockItemUtils;

  @Input()
  record: StockRecordSelectorModel;

  @Input()
  allSelected: boolean = false;

  @Output()
  onToggleSelected: EventEmitter<StockRecordSelectorModel> = new EventEmitter<StockRecordSelectorModel>();

  constructor() {
  }

  ngOnInit() {
  }

  toggleSelected() {
    this.onToggleSelected.emit(this.record);
  }

}
