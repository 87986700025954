/* eslint-disable */
import { AfterViewInit, Component, ViewChild, } from '@angular/core';
import {
  FormEditFieldCreateDialogView,
  FormEditFieldDialogContext,
  FormEditFieldUpdateDialogView,
  FormGroupModel,
  FormModel,
} from '../../../../../../../util/form/form-utils';
import { InputMask } from '../../../../../../../util/input-masks';
import { Form } from '../../../../../../../lib/form/form.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FieldWidthType } from '../../../../../../../util/form/form-field-width-type';
import {
  FieldValidationError,
  FormRef,
  LocalFieldValidationErrors,
  LocalFieldValidationErrorsFactory,
  QueryResult,
  ResourceQueryResult,
} from '../../../../../../../lib/util/services';
import { NgModel } from '@angular/forms';
import { List, Set } from 'immutable';
import { FieldValidationType, FormFieldValidationTypeModel, } from '../../../../../../../util/form/form-field-validation-type';
import { Models } from '../../../../../../../util/model-utils';
import { StockItemCategory, StockItemCategoryService } from '../../../../../../../lib/stock-item-category/stock-item-category.service';
import { MultiselectOptionItem, OptionItem, UiConstants } from '../../../../../../../util/core-utils';
import { Angular2Multiselects } from '../../../../../../../util/multiselect';
import { StringKey } from '../../../../../../../app.string-keys';
import { TranslateService } from '@ngx-translate/core';
import { Strings } from '../../../../../../../lib/util/strings';
import { ToasterService } from '../../../../../../../fork/angular2-toaster/src/toaster.service';
import { StockType, StockTypeName } from '../../../../../../../util/stock/stock-utils';
import { Stock, StockService } from '../../../../../../../lib/stock/stock.service';

/* eslint-enable */

@Component({
  selector: 'app-form-edit-create-update-stock-move-field-dialog',
  templateUrl: 'form-edit-create-update-stock-move-field-dialog.component.html',
  styleUrls: ['form-edit-create-update-stock-move-field-dialog.component.scss'],
})
export class FormEditCreateUpdateStockMoveFieldDialogComponent
  implements FormEditFieldCreateDialogView, FormEditFieldUpdateDialogView, FormRef, AfterViewInit {

  public readonly selector = Form.FieldDataTypeSelector.STOCK_MOVE;
  public readonly createTitle = 'FORM_ITEM_CREATE_STOCK_MOVE_TITLE';
  public readonly updateTitle = 'FORM_ITEM_UPDATE_STOCK_MOVE_TITLE';
  public readonly cloneTitle = 'FORM_ITEM_CLONE_STOCK_MOVE_TITLE';

  InputMask = InputMask;
  Form = Form;
  UiConstants = UiConstants;

  @ViewChild('fieldDialog', {static: true})
  dialogComponent: ModalDirective;

  @ViewChild('title')
  titleInput: NgModel;

  @ViewChild('api_export_name')
  apiExportNameInput: NgModel;

  @ViewChild('usableStockItemCategories')
  usableStockItemCategories: NgModel;

  visible: boolean = false;
  fieldModel: Model = new Model();
  dialogTitleDictionaryKey: string = '';
  latestApiExportName = '';

  typeDropdownSettings?: Angular2Multiselects.Settings;
  categoryDropdownSettings?: Angular2Multiselects.Settings;
  stockDropdownSettings?: Angular2Multiselects.Settings;

  fieldWidthTypes: FieldWidthType[] = FieldWidthType.VALUES.toArray();
  fieldValidationTypes: Form.FormFieldValidationType[] = FieldValidationType.VALUES
    .toArray();

  private fieldErrors: FieldValidationError<Form.FieldValidatedField> =
    FieldValidationError.empty<Form.FieldValidatedField>();

  private validatedInputs: LocalFieldValidationErrors<NgModel> =
    LocalFieldValidationErrorsFactory.empty();

  private context: FormEditFieldDialogContext;
  private group: FormGroupModel;
  private field?: Form.Field;
  private clone?: boolean;
  private submitted: boolean = false;

  stockTypes: MultiselectOptionItem<StockTypeName>[] = StockType.VALUES.toArray().map(t => t.toMultiselectOptionItem());
  stockItemCategoryItems: StockItemCategoryItem[] = [];
  sourceStocks: MultiselectOptionItem<number>[] = [];
  destinationStocks: MultiselectOptionItem<number>[] = [];

  private initDropDown() {
    this.typeDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(false)
      .enableCheckAll(false)
      .translate(true)
      .build();
    this.categoryDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .labelKey(OptionItem.KEY_TEXT)
      .text(StringKey.FORM_ITEM_STOCK_ALL_CATEGORIES)
      .remoteSearch(true)
      .build();
    this.stockDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
  }

  getForm() {
    return {
      submitted: this.submitted
    };
  }

  ngAfterViewInit(): void {
    this.loadLocalFieldValidationErrors();
  }

  registerContext(context: FormEditFieldDialogContext): void {
    this.context = context;
  }

  showDialog(form: FormModel, group: FormGroupModel, field?: Form.Field, clone?: boolean): void {
    this.latestApiExportName = '';
    this.visible = true;
    this.group = group;
    this.clone = clone;
    this.dialogComponent.show();
    this.initDropDown();
    if (field && clone) {
      this.dialogTitleDictionaryKey = this.cloneTitle;
      this.loadField(field);
    }
    else if (field) {
      this.dialogTitleDictionaryKey = this.updateTitle;
      this.loadField(field);
    }
    else {
      this.dialogTitleDictionaryKey = this.createTitle;
    }
  }

  onModalHide(event: ModalDirective) {
    if (event.dismissReason === 'backdrop-click' || event.dismissReason === 'esc') {
      this.visible = false;
      this.submitted = false;
      this.fieldModel = new Model();
    }
  }

  closeDialog(): void {
    this.visible = false;
    this.submitted = false;
    this.dialogComponent.hide();
    this.fieldModel = new Model();
  }

  saveField() {
    this.submitted = true;
    if (this.hasLocalFieldError(this.titleInput)
      || this.hasLocalFieldError(this.apiExportNameInput)
      || this.hasLocalFieldError(this.usableStockItemCategories)) {
      return;
    }
    if (this.field && this.clone) {
      this.createField();
    }
    else if (this.field) {
      this.updateField(this.field);
    }
    else {
      this.createField();
    }
  }

  hasLocalFieldError(field?: NgModel): boolean {
    return this.validatedInputs.hasLocalError(field);
  }

  hasFieldError(field?: Form.FieldValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: Form.FieldValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: Form.FieldValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  getFieldValidationTypeDictionaryKey(vt: Form.FormFieldValidationType): string {
    return FieldValidationType.getFieldValidationTypeDictionaryKey(vt);
  }

  onAdminFormFieldValidationTypeChanged() {
    FieldValidationType.onAdminFormFieldValidationTypeChanged(this.fieldModel);
  }

  onMobileFormFieldValidationTypeChanged() {
    FieldValidationType.onMobileFormFieldValidationTypeChanged(this.fieldModel);
  }

  private loadLocalFieldValidationErrors() {
    const validatedInputs = List.of(this.titleInput, this.apiExportNameInput, this.usableStockItemCategories);
    this.validatedInputs = LocalFieldValidationErrorsFactory.ofFields(this, validatedInputs);
  }

  private loadField(field: Form.Field) {
    this.field = field;
    const fieldWidth = FieldWidthType.fromPercentValue(field.formFieldWidthPercent);
    this.fieldModel.title = field.title;
    this.fieldModel.description = field.description ? field.description : '';
    this.fieldModel.apiExportName = field.apiExportName ? field.apiExportName : '';
    this.fieldModel.pdfExportName = field.pdfExportName ? field.pdfExportName : '';
    this.fieldModel.fieldWidthType = fieldWidth ? fieldWidth : FieldWidthType.FULL_SCREEN;
    this.fieldModel.adminVisibleOnFormDetail = field.admin.visibleOnFormDetail;
    this.fieldModel.mobileVisibleOnFormDetail = field.mobile.visibleOnFormDetail;
    this.fieldModel.mobileVisibleOnMasterDetail = field.mobile.visibleOnMasterDetail;
    this.fieldModel.adminFormFieldValidationType = field.admin.validationType;
    this.fieldModel.helpdeskFormFieldValidationType = field.helpdesk.validationType;
    this.fieldModel.mobileFormFieldValidationType = field.mobile.validationType;
    this.fieldModel.showOnReceipt = field.showOnReceipt;
    this.fieldModel.clearOnReopen = field.dataType.stockMoveAttributes!.clearOnReopen;
    if (field.dataType.stockMoveAttributes!.sourceStockType) {
      this.fieldModel.sourceStockType.push(
        StockType.fromName(field.dataType.stockMoveAttributes!.sourceStockType)!.toMultiselectOptionItem());
    }
    if (field.dataType.stockMoveAttributes!.destinationStockType) {
      this.fieldModel.destinationStockType.push(
        StockType.fromName(field.dataType.stockMoveAttributes!.destinationStockType)!.toMultiselectOptionItem());
    }
    this.loadUsableStockItemCategoryIds(field.dataType.stockMoveAttributes!.usableStockItemCategories);
    this.loadStockSourceFilterIds(field.dataType.stockMoveAttributes!.stockSourceFilters);
    this.loadStockDestinationFilterIds(field.dataType.stockMoveAttributes!.stockDestinationFilters);
  }

  private createField() {
    this.context.formService.createField({
      parentId: this.context.parentId,
      groupId: this.group.groupId,
      title: this.fieldModel.title,
      description: this.fieldModel.description,
      pdfExportName: this.fieldModel.pdfExportName,
      apiExportName: this.fieldModel.apiExportName,
      dataTypeSelector: this.selector,
      dataType: this.getDataTypeRequest(),
      formFieldWidthPercent: this.fieldModel.fieldWidthType.percentValue,
      displayOnNewRow: false,
      admin: {
        visibleOnFormDetail: this.fieldModel.adminVisibleOnFormDetail,
        validationType: this.fieldModel.adminFormFieldValidationType
      },
      helpdesk: {
        validationType: this.fieldModel.helpdeskFormFieldValidationType
      },
      mobile: {
        visibleOnFormDetail: this.fieldModel.mobileVisibleOnFormDetail,
        visibleOnMasterDetail: this.fieldModel.mobileVisibleOnMasterDetail,
        validationType: this.fieldModel.mobileFormFieldValidationType
      },
      showOnReceipt: this.fieldModel.showOnReceipt
    }).subscribe(
      (result) => {
        this.context.formView.reloadForm();
        this.closeDialog();
      },
      (error) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withFormRef(this);
        }
      }
    );
  }

  private updateField(field: Form.Field) {
    this.context.formService.updateField({
      parentId: this.context.parentId,
      groupId: this.group.groupId,
      fieldId: field.fieldId,
      title: this.fieldModel.title,
      description: this.fieldModel.description,
      pdfExportName: this.fieldModel.pdfExportName,
      apiExportName: this.fieldModel.apiExportName,
      dataTypeSelector: this.selector,
      dataType: this.getDataTypeRequest(),
      formFieldWidthPercent: this.fieldModel.fieldWidthType.percentValue,
      displayOnNewRow: false,
      admin: {
        visibleOnFormDetail: this.fieldModel.adminVisibleOnFormDetail,
        validationType: this.fieldModel.adminFormFieldValidationType
      },
      helpdesk: {
        validationType: this.fieldModel.helpdeskFormFieldValidationType
      },
      mobile: {
        visibleOnFormDetail: this.fieldModel.mobileVisibleOnFormDetail,
        visibleOnMasterDetail: this.fieldModel.mobileVisibleOnMasterDetail,
        validationType: this.fieldModel.mobileFormFieldValidationType
      },
      showOnReceipt: this.fieldModel.showOnReceipt
    }).subscribe(
      (result) => {
        this.context.formView.reloadForm();
        this.closeDialog();
      },
      (error) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withFormRef(this);
        }
      }
    );
  }

  private getDataTypeRequest(): Form.FieldDataType {
    return {
      stockMoveAttributes: {
        sourceStockType: this.fieldModel.sourceStockType.length === 1 ? this.fieldModel.sourceStockType[0]!.id : undefined,
        destinationStockType: this.fieldModel.destinationStockType.length === 1 ? this.fieldModel.destinationStockType[0]!.id : undefined,
        usableStockItemCategories: this.getUsableStockItemCategoryIds(),
        stockSourceFilters: this.getStockSourceFilterIds(),
        stockDestinationFilters: this.getStockDestinationFilterIds(),
        clearOnReopen: this.fieldModel.clearOnReopen
      }
    };
  }

  loadStockItemCategories(searchValue?: string) {
    this.stockItemCategoryService.query({
      name: Strings.undefinedOrNonEmpty(searchValue),
      disabled: false,
      paging: {
        pageNumber: 1,
        numberOfItems: UiConstants.autocompletePageSize
      },
      noProgressBar: true
    }).subscribe((result: QueryResult<StockItemCategory.StockItemCategory>) => {
      this.stockItemCategoryItems = [];
      result.items.forEach((category: StockItemCategory.StockItemCategory) => {
        const item = {
          id: category.id,
          text: category.name,
          disabled: category.disabled
        };
        this.stockItemCategoryItems.push(item);
      });
    });
  }

  getUsableStockItemCategoryIds(): number[] {
    const usableStockItemCategoryIds: number[] = [];
    this.fieldModel.usableStockItemCategories.forEach((stockItemCategoryItem: StockItemCategoryItem) => {
      if (stockItemCategoryItem.id) {
        usableStockItemCategoryIds.push(stockItemCategoryItem.id);
      }
    });
    return usableStockItemCategoryIds;
  };

  loadUsableStockItemCategoryIds(usableStockItemCategoryIds: number[]) {
    if (usableStockItemCategoryIds.length > 0) {
      this.stockItemCategoryService.query({
        id: Set.of(...usableStockItemCategoryIds)
      }).subscribe((result: QueryResult<StockItemCategory.StockItemCategory>) => {
        this.fieldModel.usableStockItemCategories = [];
        result.items.forEach((category: StockItemCategory.StockItemCategory) => {
          const item = {
            id: category.id,
            text: category.name,
            disabled: category.disabled
          };
          this.fieldModel.usableStockItemCategories.push(item);
        });
      });
    }
  };

  sourceStockTypeChanged(item?: MultiselectOptionItem<StockTypeName>) {
    this.loadSourceStocks(undefined, item?.id);
  }

  loadSourceStocks(searchValue?: string, type?: StockTypeName) {
    this.stockService.query({
      name: Strings.undefinedOrNonEmpty(searchValue),
      disabled: false,
      order: '+name',
      page_number: 1,
      number_of_items: UiConstants.autocompletePageSize,
      type: type,
      no_progress_bar: true
    }).subscribe((result: ResourceQueryResult<Stock>) => {
      this.sourceStocks = [];
      result.items.forEach((stock: Stock) => {
        const item = {
          id: stock.id,
          itemName: stock.name,
          itemSubtitle: stock.external_id,
          disabled: stock.disabled
        };
        this.sourceStocks.push(item);
      });
    });
  }

  getStockSourceFilterIds(): number[] {
    const stockSourceFilterIds: number[] = [];
    this.fieldModel.stockSourceFilters.forEach((stockSourceFilter: MultiselectOptionItem<number>) => {
      if (stockSourceFilter.id) {
        stockSourceFilterIds.push(stockSourceFilter.id);
      }
    });
    return stockSourceFilterIds;
  };

  loadStockSourceFilterIds(stockSourceFilterIds: number[]) {
    if (stockSourceFilterIds.length > 0) {
      this.stockService.query({
        id: stockSourceFilterIds.join(','),
        order: '+name',
        type: this.fieldModel.sourceStockTypeName,
      }).subscribe((result: ResourceQueryResult<Stock>) => {
        this.fieldModel.stockSourceFilters = [];
        result.items.forEach((stock: Stock) => {
          const item = {
            id: stock.id,
            itemName: stock.name,
            itemSubtitle: stock.external_id,
            disabled: stock.disabled
          };
          this.fieldModel.stockSourceFilters.push(item);
        });
      });
    }
  };

  destinationStockTypeChanged(item?: MultiselectOptionItem<StockTypeName>) {
    this.loadDestinationStocks(undefined, item?.id);
  }

  loadDestinationStocks(searchValue?: string, type?: StockTypeName) {
    this.stockService.query({
      name: Strings.undefinedOrNonEmpty(searchValue),
      disabled: false,
      order: '+name',
      page_number: 1,
      number_of_items: UiConstants.autocompletePageSize,
      type: type,
      no_progress_bar: true
    }).subscribe((result: ResourceQueryResult<Stock>) => {
      this.destinationStocks = [];
      result.items.forEach((stock: Stock) => {
        const item = {
          id: stock.id,
          itemName: stock.name,
          itemSubtitle: stock.external_id,
          disabled: stock.disabled
        };
        this.destinationStocks.push(item);
      });
    });
  }

  getStockDestinationFilterIds(): number[] {
    const stockDestinationFilterIds: number[] = [];
    this.fieldModel.stockDestinationFilters.forEach((stockDestinationFilter: MultiselectOptionItem<number>) => {
      if (stockDestinationFilter.id) {
        stockDestinationFilterIds.push(stockDestinationFilter.id);
      }
    });
    return stockDestinationFilterIds;
  };

  loadStockDestinationFilterIds(stockDestinationFilterIds: number[]) {
    if (stockDestinationFilterIds.length > 0) {
      this.stockService.query({
        id: stockDestinationFilterIds.join(','),
        order: '+name',
        type: this.fieldModel.destinationStockTypeName,
      }).subscribe((result: ResourceQueryResult<Stock>) => {
        this.fieldModel.stockDestinationFilters = [];
        result.items.forEach((stock: Stock) => {
          const item = {
            id: stock.id,
            itemName: stock.name,
            itemSubtitle: stock.external_id,
            disabled: stock.disabled
          };
          this.fieldModel.stockDestinationFilters.push(item);
        });
      });
    }
  };


  onTitleBlur() {
    const normalizedText = Models.normalizeText(this.fieldModel.title);
    if (this.fieldModel.apiExportName === '') {
      this.fieldModel.apiExportName = normalizedText;
    }
    if (this.fieldModel.pdfExportName === '') {
      this.fieldModel.pdfExportName = normalizedText;
    }
  }

  constructor(private stockItemCategoryService: StockItemCategoryService,
              private stockService: StockService,
              private toasterService: ToasterService,
              private translateService: TranslateService) {
  }
}

export class Model implements FormFieldValidationTypeModel {
  title: string = '';
  description: string = '';
  apiExportName: string = '';
  pdfExportName: string = '';
  fieldWidthType: FieldWidthType = FieldWidthType.FULL_SCREEN;
  adminVisibleOnFormDetail: boolean = true;
  mobileVisibleOnFormDetail: boolean = true;
  mobileVisibleOnMasterDetail: boolean = false;
  adminFormFieldValidationType: Form.FormFieldValidationType = Form.FormFieldValidationType.OPTIONAL;
  helpdeskFormFieldValidationType: Form.FormFieldValidationType = Form.FormFieldValidationType.OPTIONAL;
  mobileFormFieldValidationType: Form.FormFieldValidationType = Form.FormFieldValidationType.OPTIONAL;
  showOnReceipt?: boolean = false;
  clearOnReopen: boolean = false;
  sourceStockType: MultiselectOptionItem<StockTypeName>[] = [];
  destinationStockType: MultiselectOptionItem<StockTypeName>[] = [];
  usableStockItemCategories: StockItemCategoryItem[] = [];
  stockSourceFilters: MultiselectOptionItem<number>[] = [];
  stockDestinationFilters: MultiselectOptionItem<number>[] = [];

  get sourceStockTypeName(): StockTypeName | undefined {
    return this.sourceStockType.length > 0 ? this.sourceStockType[0].id : undefined;
  }
  get destinationStockTypeName(): StockTypeName | undefined {
    return this.destinationStockType.length > 0 ? this.destinationStockType[0].id : undefined;
  }
}

interface StockItemCategoryItem extends OptionItem<number> {
}
