import { Injectable } from '@angular/core';
import { MultiselectProvider } from '../multiselect-provider';
import { MultiselectOptionItem, UiConstants } from '../../util/core-utils';
import { Set } from 'immutable';
import { OrderType, QueryResult } from '../util/services';
import { Observable, Observer } from 'rxjs';
import { StockItemCategory, StockItemCategoryService } from './stock-item-category.service';

@Injectable()
export class StockItemCategoryMultiselectProvider implements MultiselectProvider {

  private multiselectQueryRequest: StockItemCategory.QueryRequest = {
    id: undefined,
    paging: {
      pageNumber: 1,
      numberOfItems: UiConstants.autocompletePageSize
    },
    noProgressBar: true,
    orders: Set.of({field: StockItemCategory.OrderField.NAME, type: OrderType.ASC})
  };

  constructor(private stockItemCategoryService: StockItemCategoryService) {
  }

  load(queryRequest: StockItemCategory.QueryRequest): Observable<MultiselectOptionItem<number>[]> {
    return Observable.create((observer: Observer<MultiselectOptionItem<number>[]>) => {
      this.stockItemCategoryService.query(queryRequest).subscribe((result: QueryResult<StockItemCategory.StockItemCategory>) => {
          observer.next(result.items.map(item => this.toMultiselectOptionItem(item!)).toArray());
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  loadAll(predicate?: string): Observable<MultiselectOptionItem<number>[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.name = predicate;
    return this.load(queryRequest);
  }

  loadActive(predicate?: string): Observable<MultiselectOptionItem<number>[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.name = predicate;
    queryRequest.disabled = false;
    return this.load(queryRequest);
  }

  getById(id: number): Observable<MultiselectOptionItem<number>> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.id = Set.of(id);
    return Observable.create((observer: Observer<MultiselectOptionItem<number>>) => {
      this.stockItemCategoryService.query(queryRequest).subscribe((result: QueryResult<StockItemCategory.StockItemCategory>) => {
          observer.next(result.items.map(item => this.toMultiselectOptionItem(item!)).toArray()[0]);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  getByIds(ids: number[]): Observable<MultiselectOptionItem<number>[]> {
    const queryRequest = Object.assign([], this.multiselectQueryRequest);
    queryRequest.id = Set.of(...ids);
    return this.load(queryRequest);
  }

  toMultiselectOptionItem(item: StockItemCategory.StockItemCategory): MultiselectOptionItem<number> {
    const ret = new MultiselectOptionItem<number>();
    ret.id = item.id;
    ret.itemName = item.name;
    ret.itemSubtitle = item.externalId;
    ret.disabled = item.disabled;
    return ret;
  }

}
