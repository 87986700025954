import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxDefaultOptions } from '@angular/material/checkbox';
import { MultiselectOptionItem, QueryFieldModel, UiConstants } from '../../../../../../util/core-utils';
import { MasterDataRecord, MasterDataRecordService } from '../../../../../../lib/masterdata/master-data-record.service';
import { OrderType } from '../../../../../../lib/util/services';
import { FormRecordMasterDataFieldLoader } from '../form-record-master-data-field.loader';
import { MasterDataRecordFormFieldModel } from '../form-record-master-data-field.component';
import { Set } from 'immutable';
import { Form } from '../../../../../../lib/form/form.service';
import { CustomerRecordMultiselectProvider } from '../../../../../../lib/customer/customer-record-multiselect-provider.service';
import { Angular2Multiselects } from '../../../../../../util/multiselect';
import { RightResolver, RightService } from '../../../../../../lib/right.service';
import { RightModel } from '../../../../../../app.rights';
import {
  CustomerRecordContactLocationMultiselectProvider
} from '../../../../../../lib/customer/contact/customer-record-contact-location-multiselect.provider';

@Component({
  selector: 'app-master-data-record-selector-dialog',
  templateUrl: './master-data-record-selector-dialog.component.html',
  styleUrls: ['./master-data-record-selector-dialog.component.scss'],
  providers: [
    {provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: {clickAction: 'noop'} as MatCheckboxDefaultOptions}
  ]
})
export class MasterDataRecordSelectorDialogComponent implements OnInit {

  UiConstants = UiConstants;
  MasterDataRecord = MasterDataRecord;
  masterDataRecords: MasterDataRecordSelectorModel[] = [];
  selectedMasterDataRecords: MasterDataRecordSelectorModel[] = [];
  queryModel: QueryFieldModel<MasterDataRecord.OrderField> = new QueryFieldModel(MasterDataRecord.OrderField.NAME, OrderType.ASC);
  loading: boolean = false;
  numberOfItemsOptions: number[] = [12, 24, 48];
  filterModel: MasterDataRecordSelectorFilter = new MasterDataRecordSelectorFilter();

  readonly pagingId: string = 'masterDataRecordSelectorDialogPagingId';

  rightModel: RightModel = RightModel.empty();
  showFilter: boolean = false;
  filterAppliedIndicatorVisible: boolean = false;
  customerRecords: MultiselectOptionItem<number>[] = [];
  contactLocations: MultiselectOptionItem<number>[] = [];
  customerDropdownSettings: Angular2Multiselects.Settings;

  constructor(
    public dialogRef: MatDialogRef<MasterDataRecordSelectorDialogComponent, MasterDataRecordSelectorDialogResult>,
    @Inject(MAT_DIALOG_DATA) public data: MasterDataRecordSelectorDialogData,
    private masterDataRecordService: MasterDataRecordService,
    private masterDataFieldLoader: FormRecordMasterDataFieldLoader,
    private customerRecordMultiselectProvider: CustomerRecordMultiselectProvider,
    private contactLocationMultiselectProvider: CustomerRecordContactLocationMultiselectProvider,
    private rightService: RightService
  ) {
    this.loadRightModels();
    this.loadData();
    this.initDropdown();
  }

  private initDropdown() {
    this.customerDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  ngOnInit() {
    this.onItemsPerPageChange(this.numberOfItemsOptions[0]);
  }

  load(pageNumber?: number) {
    this.loadMasterDataRecords(pageNumber);
  }

  private loadData() {
    this.selectedMasterDataRecords = [];
    this.data.alreadySelected.forEach(md => {
      this.selectedMasterDataRecords.push({selected: true, model: md});
    })
    if (this.data.ownerCustomerRecordIds !== undefined) {
      this.filterAppliedIndicatorVisible = true;
      this.filterModel.customerRecord = this.data.ownerCustomerRecordIds.toArray().map(i => {
        return {id: i, itemName: 'N/A'};
      })
      this.customerRecordMultiselectProvider.getByIds(this.data.ownerCustomerRecordIds.toArray()).subscribe(c => {
        this.filterModel.customerRecord = c;
      })
    }
    if (this.data.ownerContactLocationIds !== undefined) {
      this.filterAppliedIndicatorVisible = true;
      this.filterModel.contactLocation = this.data.ownerContactLocationIds.toArray().map(i => {
        return {id: i, itemName: 'N/A'};
      })
      this.contactLocationMultiselectProvider.getByIds(this.data.ownerContactLocationIds.toArray()).subscribe(c => {
        this.filterModel.contactLocation = c;
      })
    }
  }

  private loadMasterDataRecords(pageNumber?: number) {
    this.loading = true;
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    this.masterDataFieldLoader.getMasterDataRecordObservable(this.data.masterDataId,
      false,
      true,
      undefined,
      undefined,
      this.filterModel.q,
      this.filterModel.customerRecordIds,
      this.filterModel.contactLocationIds,
      {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      },
      Set.of(this.queryModel.getOrder())).subscribe(result => {
      this.masterDataRecords = [];
      result.items.forEach(md => {
        if (md) {
          const selected = this.selectedMasterDataRecords.find(item => item.model.masterDataRecordId === md.masterDataRecordId);
          this.masterDataRecords.push({selected: !!selected, model: md});
        }
      })
      this.queryModel.currentPage = requestedPage;
      this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
      this.loading = false;
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  saveSelection() {
    this.dialogRef.close(this.selectedMasterDataRecords.length > 0 ? {
      selectedMasterDataRecords: this.selectedMasterDataRecords.map(md => md.model)
    } : undefined);
  }

  orderBy(field: MasterDataRecord.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.load(1);
  }

  onPageChange(page: number) {
    this.load(page);
  }

  onItemsPerPageChange(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.load(1);
  }

  toggleSelected(record: MasterDataRecordSelectorModel) {
    const selected = record.selected;
    if (this.data.multiSelect) {
      if (selected) {
        this.selectedMasterDataRecords.splice(this.selectedMasterDataRecords.findIndex(i => i.model.masterDataRecordId === record.model.masterDataRecordId), 1);
      }
      else {
        this.selectedMasterDataRecords.push(record);
      }
    }
    else {
      if (!selected) {
        this.masterDataRecords.forEach(md => md.selected = false);
        this.selectedMasterDataRecords = [];
        this.selectedMasterDataRecords.push(record);
      }
    }
    record.selected = !selected;

  }

  public static openSelector(
    dialog: MatDialog,
    data: MasterDataRecordSelectorDialogData,
    resultCallback: (result?: MasterDataRecordSelectorDialogResult) => void) {
    const dialogRef = dialog.open(MasterDataRecordSelectorDialogComponent, {
      panelClass: 'custom-dialog',
      closeOnNavigation: true,
      data: data
    });

    dialogRef.afterClosed().subscribe((result: MasterDataRecordSelectorDialogResult) => {
      resultCallback(result);
    });
  }

  toggleFilter() {
    this.showFilter = !this.showFilter;
    this.filterAppliedIndicatorVisible = false;
  }

  searchCustomerRecord(q?: string) {
    this.customerRecordMultiselectProvider.loadActiveNonContact(q).subscribe(c => {
      this.customerRecords = c;
    });
  }

  onCustomerRecordChanged() {
    this.filterModel.contactLocation = [];
    if (this.filterModel.customerRecord.length === 1) {
      this.searchContactLocation();
    }
  }

  searchContactLocation(q?: string) {
    if (this.filterModel.customerRecord.length === 1) {
      this.contactLocationMultiselectProvider.loadForCustomer(this.filterModel.customerRecord[0].id, q).subscribe(c => {
        this.contactLocations = c;
      });
    }
  }
}

export class MasterDataRecordSelectorModel {
  selected: boolean = false;

  model: MasterDataRecordFormFieldModel;
}

export interface MasterDataRecordSelectorDialogData {
  multiSelect: boolean;
  masterDataId: number;
  ownerCustomerRecordIds?: Set<number>;
  ownerContactLocationIds?: Set<number>;
  displayedFormField?: Form.Field;
  alreadySelected: MasterDataRecordFormFieldModel[];
}

export interface MasterDataRecordSelectorDialogResult {
  selectedMasterDataRecords?: MasterDataRecordFormFieldModel[];
}

export class MasterDataRecordSelectorFilter {
  q: string = '';
  customerRecord: MultiselectOptionItem<number>[] = [];
  contactLocation: MultiselectOptionItem<number>[] = [];

  get customerRecordIds(): Set<number> | undefined {
    if (this.customerRecord.length === 0) {
      return undefined;
    }
    return Set.of(...this.customerRecord.map(cr => cr.id));
  }

  get contactLocationIds(): Set<number> | undefined {
    if (this.contactLocation.length === 0 || this.customerRecord.length === 0) {
      return undefined;
    }
    return Set.of(...this.contactLocation.map(cr => cr.id));
  }
}
