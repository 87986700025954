import { DqlQuery, Query } from '../field';
import { LazyReference } from '../../../util/lazy';
import { User } from './user';
import { TaskRecord } from './task-record';
import { Vehicle } from './vehicle';

export class MileageRecord {

  readonly id: Query.OrderField;
  readonly creationTime: Query.OrderField;
  readonly finishTime: Query.OrderField;

  private readonly userRef: LazyReference<User>;
  private readonly taskRecordRef: LazyReference<TaskRecord>;
  private readonly vehicleRef: LazyReference<Vehicle>;

  get user(): User {
    return this.userRef.get();
  }

  get taskRecord(): TaskRecord {
    return this.taskRecordRef.get();
  }

  get vehicle(): Vehicle {
    return this.vehicleRef.get();
  }

  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this.id = new DqlQuery.OrderField(prefix + 'id');
    this.creationTime = new DqlQuery.OrderField(prefix + 'creation_time');
    this.finishTime = new DqlQuery.OrderField(prefix + 'finish_time');
    this.userRef = LazyReference.of(
      () => new User(prefix + 'user.')
    );
    this.taskRecordRef = LazyReference.of(
      () => new TaskRecord(prefix + 'task_record.')
    );
    this.vehicleRef = LazyReference.of(
      () => new Vehicle(prefix + 'vehicle.')
    );
  }

}
