/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { EmptyMessage } from '../util/messages';
import { Set } from 'immutable';
/* eslint-enable */

@Injectable()
export class EnvironmentConfigResourceService extends BaseHttpService {

  get(request: EnvironmentConfigResource.GetRequest): Observable<Map<string, string>> {
    return this.http.get<Map<string, string>>(this.url, request);
  }

  getReadonlyKeys(request: EnvironmentConfigResource.GetRequest): Observable<Set<string>> {
    return this.http.get<Set<string>>(this.url + 'read-only/keys', request);
  }

  update(request: Map<string, string>): Observable<EmptyMessage> {
    const convMap = {};
    request.forEach((val: string, key: string) => {
      convMap[key] = val;
    });
    return this.http.put<EmptyMessage>(this.url, convMap);
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, 'environment-config/');
  }

}

export namespace EnvironmentConfigResource {

  export interface GetRequest {}

}
