<select class="form-control"
        *ngIf="featureSelector"
        [(ngModel)]="selectedDashboard"
        [compareWith]="SelectUtils.compareObjects"
        name="dashboardSelector" #dashboardSelector="ngModel">
  <option *ngFor="let dashboard of selectableDashboards" [ngValue]="dashboard">{{dashboard}}</option>
</select>

<span *ngIf="appworksFeatures">
  <app-appworks-dashboard></app-appworks-dashboard>
</span>

<span *ngIf="anyspedFeatures">
  <app-anysped-dashboard></app-anysped-dashboard>
</span>
