<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div class="breadcrumb-menu d-flex">
    <div *ngIf="rightModel.orderUpdate.hasRight()
         && model
         && model.orderState === 'SENT_BACK'
         && model.returnReason"
        class="breadcrumb-button-icon-container cursor-pointer"
         (click)="showOrderReturnDialog()">
      <a class="btn-setting" title="{{'ORDER_LIST_ORDER_RETURN' | translate}}">
        <i class="icomoon icomoon-stock-change-order-intake"></i> {{'ORDER_LIST_ORDER_RETURN' | translate}}
      </a>
    </div>
    <div *ngIf="rightModel.orderUpdate.hasRight()"
         class="breadcrumb-button-icon-container cursor-pointer negative-margin-right"
         uiSref="Admin.OrderEdit"
         [uiParams]="{ id: orderId }">
      <a class="btn-setting" title="{{'COMMON_BUTTON_EDIT' | translate}}">
        <i class="icomoon icomoon-modify"></i> {{'COMMON_BUTTON_EDIT' | translate}}
      </a>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<app-order-detail-state-list
  *ngIf="model && model.logicState !== 'DRAFT'"
  [order]="model">
</app-order-detail-state-list>
<div class="container-horizontal-padding container-vertical-padding">
  <responsive-tabset class="fullscreen_tab">
    <responsive-tab heading="{{'COMMON_BASIC_DATA'|translate}}">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-8 p-0">

        <!--BASIC DATA-->
        <div class="card">
          <div class="card-header">
            {{'ORDER_DETAIL_HEADING' | translate}}
          </div>

          <div class="card-body" *ngIf="model">

            <div class="row">
              <div class="form-group col-md-4 mb-0">
                <label readonly
                       class="col-form-label form-control-label detail-title">{{'ORDER_LABEL_ID' | translate}}</label>
              </div>
              <div class="form-group col-md-8 mb-0">
                <label readonly class="col-form-label form-control-label detail-description">
                  {{ model.orderId }}</label>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-md-4 mb-0">
                <label readonly
                       class="col-form-label form-control-label detail-title">{{'ORDER_LABEL_EXTERNAL_ID' |
                  translate}}</label>
              </div>
              <div class="form-group col-md-8 mb-0">
                <label readonly class="col-form-label form-control-label detail-description">
                  {{ model.externalId }}</label>
              </div>
            </div>

            <div class="row" *ngIf="model.shipmentTrackingNumber">
              <div class="form-group col-md-4 mb-0">
                <label readonly
                       class="col-form-label form-control-label detail-title">{{'ORDER_TABLE_HEADER_TRACKING_NUMBER' |
                  translate}}</label>
              </div>
              <div class="form-group col-md-8 mb-0">
                <label readonly class="col-form-label form-control-label detail-description">
                  {{ model.shipmentTrackingNumber }}</label>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-md-4 mb-0">
                <label readonly
                       class="col-form-label form-control-label detail-title">{{'COMMON_DESCRIPTION' |
                  translate}}</label>
              </div>
              <div class="form-group col-md-8 mb-0">
                <label readonly class="col-form-label form-control-label detail-description">
                  {{ model.description }}</label>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-md-4 mb-0">
                <label readonly
                       class="col-form-label form-control-label detail-title">{{'ORDER_LABEL_SHIPPING_STATE' |
                  translate}}</label>
              </div>
              <div class="form-group col-md-8 mb-0">
                <label readonly class="col-form-label form-control-label detail-description">
                  {{ model.shippingState }}</label>
              </div>
            </div>

            <div class="row" *ngIf="ownerUserName && ownerUserName !== ''">
              <div class="form-group col-md-4 mb-0">
                <label readonly
                       class="col-form-label form-control-label detail-title">{{'ORDER_LABEL_USERNAME' | translate}}</label>
              </div>
              <div class="form-group col-md-8 mb-0">
                <label readonly class="col-form-label form-control-label detail-description">
                  {{ ownerUserName }}</label>
              </div>
            </div>

            <div class="row" *ngIf="model.weightInGram">
              <div class="form-group col-md-4 mb-0">
                <label readonly
                       class="col-form-label form-control-label detail-title">{{'ORDER_LABEL_WEIGHT' | translate}}</label>
              </div>
              <div class="form-group col-md-8 mb-0">
                <label readonly class="col-form-label form-control-label detail-description">
                  {{ model.weightInGram | formattedNumber }} g</label>
              </div>
            </div>

            <div class="row" *ngIf="model.cashOnDeliveryPrice">
              <div class="form-group col-md-4 mb-0">
                <label readonly
                       class="col-form-label form-control-label detail-title">{{'ORDER_LABEL_CASH_ON_DELIVERY_PRICE' |
                  translate}}</label>
              </div>
              <div class="form-group col-md-8 mb-0">
                <label readonly class="col-form-label form-control-label detail-description">
                  {{ model.cashOnDeliveryPrice.amount | formattedNumber }} {{ model.cashOnDeliveryPrice.currencyCode
                  }}</label>
              </div>
            </div>

            <div class="row" *ngIf="model.recipient && model.recipient.name">
              <div class="form-group col-md-4 mb-0">
                <label readonly
                       class="col-form-label form-control-label detail-title">{{'ORDER_LABEL_RECIPIENT_NAME' |
                  translate}}</label>
              </div>
              <div class="form-group col-md-8 mb-0">
                <label readonly class="col-form-label form-control-label detail-description">
                  {{ model.recipient.name }}</label>
              </div>
            </div>

            <div class="row" *ngIf="model.recipient && model.recipient.phoneNumber">
              <div class="form-group col-md-4 mb-0">
                <label readonly
                       class="col-form-label form-control-label detail-title">{{'COMMON_PHONE_NUMBER' |
                  translate}}</label>
              </div>
              <div class="form-group col-md-8 mb-0">
                <label readonly class="col-form-label form-control-label detail-description">
                  {{ model.recipient.phoneNumber.toIso() }}</label>
              </div>
            </div>

            <div class="row" *ngIf="model.recipient && model.recipient.emailAddress">
              <div class="form-group col-md-4 mb-0">
                <label readonly
                       class="col-form-label form-control-label detail-title">{{'COMMON_EMAIL_ADDRESS' |
                  translate}}</label>
              </div>
              <div class="form-group col-md-8 mb-0">
                <label readonly class="col-form-label form-control-label detail-description">
                  {{ model.recipient.emailAddress.toIso() }}</label>
              </div>
            </div>

            <div class="row" *ngIf="model.deliveryMethod">
              <div class="form-group col-md-4 mb-0">
                <label readonly
                       class="col-form-label form-control-label detail-title">{{'ORDER_EDIT_RECIPIENT_DELIVERY_METHOD' |
                  translate}}</label>
              </div>
              <div class="form-group col-md-8 mb-0">
                <label readonly class="col-form-label form-control-label detail-description">
                  {{model.deliveryMethod.name}}
                </label>
              </div>
            </div>

            <div class="row" *ngIf="model.recipient && model.recipient.deliveryAddress">
              <div class="form-group col-md-4 mb-0">
                <label readonly
                       class="col-form-label form-control-label detail-title">{{'ORDER_LABEL_RECIPIENT_ADDRESS' |
                  translate}}</label>
              </div>
              <div class="form-group col-md-8 mb-0">
                <label readonly class="col-form-label form-control-label detail-description">
                  {{Address.PostalAddressMapper.toString(model.recipient.deliveryAddress, config.postalAddressFormat)}}
                </label>
              </div>
            </div>

            <div class="row" *ngIf="model.recipient && model.recipient.parcelCollectionPoint">
              <div class="form-group col-md-4 mb-0">
                <label readonly
                       class="col-form-label form-control-label detail-title">{{'ORDER_EDIT_RECIPIENT_PARCEL_COLLECTION_POINT' |
                  translate}}</label>
              </div>
              <div class="form-group col-md-8 mb-0">
                <label readonly class="col-form-label form-control-label detail-description">
                  {{model.recipient.parcelCollectionPoint.name}}
                </label>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-md-4 mb-0">
                <label readonly
                       class="col-form-label form-control-label detail-title">{{'ORDER_LABEL_SUBMIT_TIME' |
                  translate}}</label>
              </div>
              <div class="form-group col-md-8 mb-0">
                <label readonly class="col-form-label form-control-label detail-description">
                  {{ model.submitTime.toUtcIsoString() | date:'short' }}</label>
              </div>
            </div>

            <div class="row" *ngIf="model.orderState === 'DRAFT'">
              <div class="form-group col-md-4 mb-0">
                <label readonly
                       class="col-form-label form-control-label detail-title">{{'COMMON_STATE' | translate}}</label>
              </div>
              <div class="form-group col-md-8 mb-0">
                <label readonly class="col-form-label form-control-label detail-description">
                  {{ 'ORDER_STATE_DRAFT' | translate }}</label>
              </div>
            </div>

            <div class="row" *ngIf="model.orderState === 'SENT_BACK' && model.returnTime">
              <div class="form-group col-md-4 mb-0">
                <label readonly
                       class="col-form-label form-control-label detail-title">{{'ORDER_LABEL_RETURN_TIME' |
                  translate}}</label>
              </div>
              <div class="form-group col-md-8 mb-0">
                <label readonly class="col-form-label form-control-label detail-description">
                  {{ model.returnTime.toUtcIsoString() | date:'short' }}</label>
              </div>
            </div>

            <div class="row" *ngIf="selectedOtherDataText !== ''">
              <div class="form-group col-md-4 mb-0">
                <label readonly
                       class="col-form-label form-control-label detail-title">{{'ORDER_LABEL_OTHER_DATA' |
                  translate}}</label>
              </div>
              <div class="form-group col-md-8 mb-0">
                <label readonly class="col-form-label form-control-label detail-description">
                  {{ selectedOtherDataText }}</label>
              </div>
            </div>

            <div class="row" *ngIf="model && model.note && model.note.customerNote">
              <div class="form-group col-md-4 mb-0">
                <label readonly
                       class="col-form-label form-control-label detail-title">{{'ORDER_LABEL_CUSTOMER_NOTE' |
                  translate}}</label>
              </div>
              <div class="form-group col-md-8 mb-0">
                <label readonly class="col-form-label form-control-label detail-description">
                  {{ model.note.customerNote }}</label>
              </div>
            </div>

            <div class="row"
                 *ngIf="model && model.note && model.note.extraNote && rightModel.orderNoteExtraRead.hasRightForOrder(model)">
              <div class="form-group col-md-4 mb-0">
                <label readonly
                       class="col-form-label form-control-label detail-title">{{'ORDER_LABEL_EXTRA_NOTE' |
                  translate}}</label>
              </div>
              <div class="form-group col-md-8 mb-0">
                <label readonly class="col-form-label form-control-label detail-description">
                  {{ model.note.extraNote }}</label>
              </div>
            </div>

            <div class="row"
                 *ngIf="model && model.note && model.note.internalNote && rightModel.orderNoteInternalRead.hasRightForOrder(model)">
              <div class="form-group col-md-4 mb-0">
                <label readonly
                       class="col-form-label form-control-label detail-title">{{'ORDER_LABEL_INTERNAL_NOTE' |
                  translate}}</label>
              </div>
              <div class="form-group col-md-8 mb-0">
                <label readonly class="col-form-label form-control-label detail-description">
                  {{ model.note.internalNote }}</label>
              </div>
            </div>

            <div class="row" *ngIf="model.returnReason && model.returnReason !== ''">
              <div class="form-group col-md-4 mb-0">
                <label readonly
                       class="col-form-label form-control-label detail-title">{{'ORDER_RETURN_DIALOG_RETURN_REASON' |
                  translate}}</label>
              </div>
              <div class="form-group col-md-8 mb-0">
                <label readonly class="col-form-label form-control-label detail-description">
                  {{ model.returnReason }}</label>
              </div>
            </div>

          </div> <!--./card-body-->
        </div>
        <!--./BASIC DATA-->

      </div>

      <div class="container-vertical-padding-half"></div>
      <!-- /.container-vertical-padding-half -->

      <div class="col-md-12 p-0">

        <div class="card">
          <div class="card-header">
            {{'ORDER_DETAIL_HEADING_ITEMS' | translate}}
          </div>

          <div class="card-body" *ngIf="model">

            <table class="table table-striped table-bordered">
              <thead>
              <tr>
                <th>{{'ORDER_LABEL_ID' | translate}}</th>
                <th>{{'COMMON_NAME' | translate}}</th>
                <th class="hidden-sm-down">{{'ORDER_LABEL_EXTERNAL_ID' | translate}}</th>
                <th class="hidden-sm-down">{{'ORDER_TABLE_HEADER_SERIAL_CODE' | translate}}</th>
                <th class="hidden-sm-down">{{'ORDER_TABLE_HEADER_UNIT_PRICE' | translate}}</th>
                <th class="hidden-sm-down">{{'ORDER_TABLE_HEADER_VAT_RATE' | translate}}</th>
                <th>{{'ORDER_TABLE_HEADER_AMOUNT' | translate}}</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr class="table" *ngFor="let row of stockItems">
                <td>{{row.itemId}}</td>
                <td>{{row.name}}</td>
                <td class="hidden-sm-down">{{row.externalId}}</td>
                <td class="hidden-sm-down">{{row.serialCode}}</td>
                <td
                  class="hidden-sm-down">{{row.unitPrice.value | formattedNumber}} {{row.unitPrice.currency_code}}</td>
                <td class="hidden-sm-down">{{row.unitPrice.vat_rate}}%</td>
                <td>{{row.amount}} {{row.unit}}</td>
                <td nowrap>
                  <div class="pull-right">
                    <a class="icons-table-group cursor-pointer" uiSref="Admin.StockItemDetail"
                       [uiParams]="{ id: row.stockItemId }">
                      <i class="icomoon icons-table-item icomoon-detail icomoon-detail-table"
                         title="{{'COMMON_BUTTON_DETAILS' | translate}}"></i>
                    </a>
                  </div>
                </td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <th>{{'ORDER_LABEL_ID' | translate}}</th>
                <th>{{'COMMON_NAME' | translate}}</th>
                <th class="hidden-sm-down">{{'ORDER_LABEL_EXTERNAL_ID' | translate}}</th>
                <th class="hidden-sm-down">{{'ORDER_TABLE_HEADER_SERIAL_CODE' | translate}}</th>
                <th class="hidden-sm-down">{{'ORDER_TABLE_HEADER_UNIT_PRICE' | translate}}</th>
                <th class="hidden-sm-down">{{'ORDER_TABLE_HEADER_VAT_RATE' | translate}}</th>
                <th>{{'ORDER_TABLE_HEADER_AMOUNT' | translate}}</th>
                <th></th>
              </tr>
              </tfoot>
            </table>

          </div> <!--./card-body-->
        </div>

      </div>

    </responsive-tab>
    <responsive-tab heading="{{'COMMON_DOCUMENTS'|translate}}">
      <app-order-document-list
        #orderDocumentList
        [orderId]="orderId"
        (onOrderCreateButtonClicked)="orderDocumentCreateDialog.openOrderDocumentCreateDialog()">
      </app-order-document-list>
    </responsive-tab>
    <responsive-tab heading="{{'COMMON_MPL_STATISTICS'|translate}}">
      <app-order-mpl-statistics
        [orderId]="orderId"
        [lastSyncTime]="model && model.mplLastSyncTime && model.mplLastSyncTime.isValid() ? model.mplLastSyncTime : undefined">
      </app-order-mpl-statistics>
    </responsive-tab>
  </responsive-tabset>
</div>

<app-order-document-create-dialog
    #orderDocumentCreateDialog
    [orderId]="orderId"
    (onOrderDocumentCreated)="orderDocumentList.loadList()">
</app-order-document-create-dialog>

<div bsModal [config]="UiConstants.modalConfig" #orderReturnDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog" *ngIf="model"
     aria-labelledby="myModalLabel"
     aria-hidden="true" (onHide)="onModalHidden()">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title"><strong>{{'ORDER_RETURN_DIALOG_TITLE' | translate}}</strong></p>
        <button type="button" class="close" (click)="closeOrderReturnDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form [formGroup]='orderReturnForm' class="modal-body">
        <div class="form-group"
             [ngClass]="{ 'has-danger': (orderReturnForm.controls['returnReason'].touched && !orderReturnForm.controls['returnReason'].valid)}">
          <label>{{'ORDER_RETURN_DIALOG_RETURN_REASON' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'ORDER_RETURN_DIALOG_RETURN_REASON' | translate}}"
                 [(ngModel)]="newReturnReason"
                 [maxlength]="255"
                 formControlName="returnReason">
          <div class="form-control-feedback"
               *ngIf="orderReturnForm.controls['returnReason'].touched && orderReturnForm.controls['returnReason'].hasError('required')">
            {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
          </div>
        </div>
      </form>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary-green" (click)="closeOrderReturnDialog()">
          {{'COMMON_BUTTON_CANCEL' | translate}}
        </button>
        <button type="button" class="btn btn-primary-green" (click)="returnOrder()">{{'COMMON_BUTTON_SAVE_CHANGES' |
          translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
