import { Injectable } from '@angular/core';
import { MultiselectProvider } from '../multiselect-provider';
import { MultiselectOptionItem, UiConstants } from '../../util/core-utils';
import { Set } from 'immutable';
import { OrderType, QueryResult } from '../util/services';
import { Observable, Observer } from 'rxjs';
import { TaskRecord, TaskRecordService } from './task-record.service';

@Injectable()
export class TaskRecordMultiselectProvider implements MultiselectProvider {

  private multiselectQueryRequest: TaskRecord.GlobalQueryRequest = {
    id: undefined,
    paging: {
      pageNumber: 1,
      numberOfItems: UiConstants.autocompletePageSize
    },
    fields: Set.of('id', 'name', 'disabled', 'task_id'),
    noProgressBar: true,
    orders: Set.of({field: TaskRecord.OrderField.NAME, type: OrderType.ASC}),
    disabled: false,
    name: undefined
  };

  constructor(private taskRecordService: TaskRecordService) {
  }

  load(queryRequest: TaskRecord.GlobalQueryRequest): Observable<MultiselectOptionItem<number>[]> {
    return Observable.create((observer: Observer<MultiselectOptionItem<number>[]>) => {
      this.taskRecordService.globalQuery(queryRequest).subscribe((result: QueryResult<TaskRecord.TaskRecord>) => {
          observer.next(result.items.map(item => this.toMultiselectOptionItem(item!)).toArray());
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  loadAll(predicate?: string): Observable<MultiselectOptionItem<number>[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.name = predicate;
    return this.load(queryRequest);
  }

  loadActive(predicate?: string): Observable<MultiselectOptionItem<number>[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.name = predicate;
    queryRequest.disabled = false;
    return this.load(queryRequest);
  }

  getById(id: number): Observable<MultiselectOptionItem<number>> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.id = id;
    return Observable.create((observer: Observer<MultiselectOptionItem<number>>) => {
      this.taskRecordService.globalQuery(queryRequest).subscribe((result: QueryResult<TaskRecord.TaskRecord>) => {
          observer.next(this.toMultiselectOptionItem(result.items.first()));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  getByIds(ids: number[]): Observable<MultiselectOptionItem<number>[]> {
    throw new Error('Not implemented yet.')
  }

  toMultiselectOptionItem(item: TaskRecord.TaskRecord): MultiselectOptionItem<number> {
    const ret = new MultiselectOptionItem<number>();
    ret.id = item.taskRecordId;
    ret.itemName = item.name;
    ret.disabled = item.disabled;
    return ret;
  }

}
