<div class="card" *ngIf="!readonly || m.length > 0">
  <div class="card-header" [ngClass]="{ 'has-danger-card': hasLocalError() }">
    <span [class.required-field-label]="required">
      {{'CUSTOMER_RECORD_PHONE_NUMBERS' | translate}}
    </span>
    <div class="card-actions">
      <a href="#" *ngIf="!readonly" onclick="return false" (click)="addNewEmptyItem()" title="{{'COMMON_BUTTON_ADD' | translate}}">
        <i class="icomoon icomoon-add"></i>
      </a>
    </div>
  </div>
  <div class="card-body text-center" *ngIf="!readonly && m.length === 0">
    <p class="empty-form-hint-text">{{ 'PHONE_NUMBER_LIST_ADD_TEXT' | translate }}</p>
    <button type="button" class="btn btn-secondary" (click)="addNewEmptyItem()">{{ 'COMMON_BUTTON_ADD' | translate }}</button>
  </div>
  <div class="card-body" *ngIf="m.length > 0">
    <div *ngFor="let item of m; let lastItem = last;" [ngClass]="{'pb-1': !lastItem}">
      <div class="row" [ngClass]="{'mb-1': !lastItem}">
        <div class="col-sm-12">
          <app-phone-number-item
            [model]="item"
            [readonly]="readonly"
            [form]="form"
            [deletable]="true"
            (deleteEventEmitter)="removeItem(item)">
          </app-phone-number-item>
        </div>
      </div>
      <hr *ngIf="!lastItem">
      <div class="d-flex justify-content-end" *ngIf="lastItem && !readonly">
        <button type="button" class="btn btn-secondary" (click)="addNewEmptyItem()">{{ 'COMMON_BUTTON_ADD' | translate }}</button>
      </div>
    </div>
  </div>
</div>
