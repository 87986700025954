import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DropdownItemType } from './dropdown-item-type';

@Component({
  selector: 'app-dropdown-item',
  templateUrl: './dropdown-item.component.html',
  styleUrls: ['./dropdown-item.component.scss']
})
export class DropdownItemComponent implements OnInit {

  @Input()
  titleStringKey: string;

  @Input()
  iconClass: string;

  @Input()
  itemType?: DropdownItemType = DropdownItemType.REGULAR;

  @Input()
  disabled?: boolean = false;

  @Input()
  enableSwitch: boolean = false;

  @Input()
  switchData: boolean = false;

  @Output()
  switchDataChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {
  }

  ngOnInit() {
  }

  onSwitchDataChanged(value: boolean) {
    this.switchDataChange.emit(value);
  }

}
