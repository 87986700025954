import { DqlQuery, Query } from '../field';

export class InvoiceTag {

  readonly id: Query.OrderField;
  readonly invoiceTag: Query.OrderField;
  readonly description: Query.OrderField;
  readonly creationTime: Query.OrderField;

  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this.id = new DqlQuery.OrderField(prefix + 'id');
    this.invoiceTag = new DqlQuery.OrderField(prefix + 'invoice_tag');
    this.description = new DqlQuery.OrderField(prefix + 'description');
    this.creationTime = new DqlQuery.OrderField(prefix + 'creation_time');
  }

}
