<div class="container-vertical-padding-half form-record-default-group" #groupHost>
  <div class="card">

    <div class="card-header">
      {{ group.title }} <span *ngIf="group.shortTitle" class="text-muted">({{group.shortTitle}})</span>
    </div>

    <div class="card-body">
      <div class="col-sm-12 px-0" [ngStyle]="layoutStyle">
        <ng-template app-form-record-field-holder></ng-template>
      </div>
    </div>

  </div>
</div>
