<div class="card">

  <div class="card-header">
    {{'WORKLOG_PANEL_HEADING_LIST' | translate}}

    <div class="card-actions">
      <a class="btn-setting table-header-action-button d-flex align-items-center px-1 w-auto font-weight-normal" title="{{'COMMON_BUTTON_EXPORT' | translate}}"
         (click)="exportXls()">
        <i class="icomoon pr-1 icomoon-csv-export"></i>
        <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_EXPORT' | translate}}</span>
      </a>
      <a class="btn-setting table-header-action-button d-flex align-items-center px-1 w-auto font-weight-normal" title="{{'COMMON_BUTTON_EXPORT' | translate}}"
         (click)="exportMaconomy()" *ngIf="maconomyEnabled">
        <i class="icomoon pr-1 icomoon-csv-export"></i>
        <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_MACONOMY_EXPORT' | translate}}</span>
      </a>
      <a class="btn-setting table-header-action-button d-flex align-items-center px-1 w-auto font-weight-normal" title="{{'COMMON_BUTTON_ADD' | translate}}"
         *ngIf="rightModel.worklogCreate.hasRight() && creatable"
         (click)="createWorklog()">
        <i class="icomoon pr-1 icomoon-add"></i>
        <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_ADD' | translate}}</span>
      </a>
      <a class="btn-setting cursor-pointer" (click)="toggleSearch()"
         title="{{'COMMON_SHOW_SEARCH_HINT' | translate}}"><i
        class="icomoon icomoon-search"></i></a>
    </div><!-- /.card-actions -->
  </div>

  <div class="card-body border-bottom" *ngIf="showSearch">

    <div class="row form-group">
      <div class="col-md-3" *ngIf="scope !== WorklogScope.TASK_RECORD">
        <label class="search-label">{{'WORKLOG_TASK_TYPE' | translate}}</label>
        <angular2-multiselect class="form-control"
                              [data]="taskList"
                              [(ngModel)]="searchModel.task"
                              (onRemoteSearch)="onTaskSearch($event.target.value)"
                              [settings]="multiDropdownSettings">
        </angular2-multiselect>
      </div>
      <div class="col-md-3" *ngIf="scope !== WorklogScope.TASK_RECORD">
        <label class="search-label">{{'WORKLOG_TASK_RECORD' | translate}}</label>
        <angular2-multiselect class="form-control"
                              [data]="taskRecordList"
                              [(ngModel)]="searchModel.taskRecord"
                              (onRemoteSearch)="onTaskRecordSearch($event.target.value)"
                              [settings]="dropdownSettings">
        </angular2-multiselect>
      </div>
      <div class="col-md-3" *ngIf="scope !== WorklogScope.TASK_RECORD">
        <label class="search-label">{{'WORKLOG_TASK_RECORD_SEARCH_STATE' | translate}}</label>
        <angular2-multiselect class="form-control"
                              [data]="taskRecordStates"
                              [(ngModel)]="searchModel.taskRecordState"
                              [settings]="localDropdownSettings">
        </angular2-multiselect>
      </div>
      <div class="col-md-3">
        <label class="search-label">{{'WORKLOG_SEARCH_LOGGER_USER' | translate}}</label>
        <angular2-multiselect class="form-control"
                              [data]="workerUserList"
                              [(ngModel)]="searchModel.loggerUser"
                              (onRemoteSearch)="onWorkerUserSearch($event.target.value)"
                              [settings]="dropdownSettings">
        </angular2-multiselect>
      </div>
      <div class="col-md-3" *ngIf="scope !== WorklogScope.USER">
        <label class="search-label">{{'WORKLOG_SEARCH_WORKER_USER' | translate}}</label>
        <angular2-multiselect class="form-control"
                              [data]="loggerUserList"
                              [(ngModel)]="searchModel.workerUser"
                              (onRemoteSearch)="onLoggerUserSearch($event.target.value)"
                              [settings]="dropdownSettings">
        </angular2-multiselect>
      </div>
      <div class="col-md-3">
        <label class="search-label">{{'WORKLOG_USER_TYPE' | translate}}</label>
        <select class="form-control"
                [(ngModel)]="searchModel.worklogUserType">
          <option [ngValue]="null">{{'COMMON_VALUE_UNSELECTED' | translate}}</option>
          <option *ngFor="let type of worklogUserTypes" [ngValue]="type.type">{{type.stringKey | translate}}</option>
        </select>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label class="search-label">{{'WORKLOG_SEARCH_START_DATE_FROM' | translate}}</label>
        <div class="input-group">
          <input type="text" class="form-control date"
                 placeholder="{{'WORKLOG_SEARCH_START_DATE_FROM' | translate}}"
                 [(ngModel)]="searchModel.startTimeFrom" ngbDatepicker #start_date_from="ngbDatepicker">
          <div class="input-group-addon-gray" (click)="start_date_from.toggle()">
            <i class="icomoon icomoon-calendar"></i>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <label class="search-label">{{'WORKLOG_SEARCH_START_DATE_TO' | translate}}</label>
        <div class="input-group">
          <input type="text" class="form-control date"
                 placeholder="{{'WORKLOG_SEARCH_START_DATE_TO' | translate}}"
                 [(ngModel)]="searchModel.startTimeTo" ngbDatepicker #start_date_to="ngbDatepicker">
          <div class="input-group-addon-gray" (click)="start_date_to.toggle()">
            <i class="icomoon icomoon-calendar"></i>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <label class="search-label">{{'WORKLOG_SEARCH_END_DATE_FROM' | translate}}</label>
        <div class="input-group">
          <input type="text" class="form-control date"
                 placeholder="{{'WORKLOG_SEARCH_END_DATE_FROM' | translate}}"
                 [(ngModel)]="searchModel.endTimeFrom" ngbDatepicker #end_date_from="ngbDatepicker">
          <div class="input-group-addon-gray" (click)="end_date_from.toggle()">
            <i class="icomoon icomoon-calendar"></i>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <label class="search-label">{{'WORKLOG_SEARCH_END_DATE_TO' | translate}}</label>
        <div class="input-group">
          <input type="text" class="form-control date"
                 placeholder="{{'WORKLOG_SEARCH_END_DATE_TO' | translate}}"
                 [(ngModel)]="searchModel.endTimeTo" ngbDatepicker #end_date_to="ngbDatepicker">
          <div class="input-group-addon-gray" (click)="end_date_to.toggle()">
            <i class="icomoon icomoon-calendar"></i>
          </div>
        </div>
      </div>
    </div>
    <!--Button-->
    <div class="col-md-12 d-flex justify-content-md-end align-items-end px-0">
        <div class="btn-group" role="group">
          <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
            {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
          </button>
          <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
            {{'COMMON_BUTTON_SEARCH' | translate}}
          </button>
        </div>
    </div>
  </div> <!-- ./card-body end -->
  <div class="card-body">
    <table class="table table-striped table-bordered">
      <thead>
      <tr>
        <th class="table-sorter-header">
          <app-table-field-sorter [orderField]="Worklog.OrderFunctions.ID"
                                  [orderType]="queryModel.getOrderType(Worklog.OrderFunctions.ID)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header" *ngIf="scope !== WorklogScope.USER">
          <app-table-sorter-no-op [text]="'WORKLOG_WORKER_USER' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header">
          <app-table-field-sorter [orderField]="Worklog.OrderFunctions.START_TIME"
                                  [orderType]="queryModel.getOrderType(Worklog.OrderFunctions.START_TIME)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'WORKLOG_START_TIME' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header">
          <app-table-field-sorter [orderField]="Worklog.OrderFunctions.END_TIME"
                                  [orderType]="queryModel.getOrderType(Worklog.OrderFunctions.END_TIME)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'WORKLOG_END_TIME' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header">
          <app-table-sorter-no-op [text]="'WORKLOG_CALCULATED_TIME' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header">
          <app-table-field-sorter [orderField]="Worklog.OrderFunctions.LOGGED_TIME_IN_MINUTES"
                                  [orderType]="queryModel.getOrderType(Worklog.OrderFunctions.LOGGED_TIME_IN_MINUTES)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'WORKLOG_LOGGED_TIME' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header">
          <app-table-field-sorter [orderField]="Worklog.OrderFunctions.ESTIMATED_TIME_IN_MINUTES"
                                  [orderType]="queryModel.getOrderType(Worklog.OrderFunctions.ESTIMATED_TIME_IN_MINUTES)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'ESTIMATED_LOGGED_TIME' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header" *ngIf="scope !== WorklogScope.TASK_RECORD">
          <app-table-sorter-no-op [text]="'WORKLOG_TASK_RECORD' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header w-table-options-menu">
          <app-table-sorter-no-op></app-table-sorter-no-op>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let log of worklogList | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }">
        <td class="fit align-middle">
          {{log.id}}
          <i class="icomoon icomoon-expired font-lg ml-05" *ngIf="log.overtime"
             popover="{{'WORKLOG_OVERTIME' | translate}}"
             triggers="mouseenter:mouseleave"
             container="body"
             placement="right"></i>
        </td>
        <td class="responsive-table-column" *ngIf="scope !== WorklogScope.USER">
          <app-assignee-table-cell
            [assigneeType]="AssigneeType.USER"
            [user]="log.workerUser"
            [rightModel]="rightModel"
            [userGroups]="relatedUserGroups">
          </app-assignee-table-cell>
        </td>
        <td class="fit align-middle">{{log.startTime.toUtcIsoString() | date:'short'}}</td>
        <td class="fit align-middle">{{log.endTime.toUtcIsoString() | date:'short'}}</td>
        <td class="responsive-table-column align-middle">{{getCalculatedDuration(log) | formattedDuration}}</td>
        <td class="responsive-table-column align-middle">{{getLoggedDuration(log.loggedTimeInMinutes) | formattedDuration}}</td>
        <td class="responsive-table-column align-middle">{{getLoggedDuration(log.estimatedTimeInMinutes) | formattedDuration}}</td>
        <td class="responsive-table-column" *ngIf="scope !== WorklogScope.TASK_RECORD">
          <a class="cursor-pointer"
             uiSref="Admin.TaskRecordDetail"
             [uiParams]="{ taskId: log.taskRecord.taskId, taskRecordId: log.taskRecord.id }">
            {{log.taskRecord.name}}
          </a>
        </td>
        <td class="align-middle">
          <app-table-options-menu>
            <app-dropdown-item
              *ngIf="editable && rightModel.worklogUpdate.hasRight()"
              [iconClass]="'icomoon-modify'"
              [titleStringKey]="'COMMON_BUTTON_EDIT'"
              class="cursor-pointer"
              (click)="updateWorklog(log)"
            >
            </app-dropdown-item>
            <app-dropdown-item
              *ngIf="deletable && rightModel.worklogUpdate.hasRight()"
              [iconClass]="'icomoon-trash'"
              [titleStringKey]="'COMMON_BUTTON_REMOVE'"
              class="cursor-pointer"
              (click)="deleteWorklog(log)">
            </app-dropdown-item>
          </app-table-options-menu>
        </td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <th class="table-sorter-header">
          <app-table-field-sorter [orderField]="Worklog.OrderFunctions.ID"
                                  [orderType]="queryModel.getOrderType(Worklog.OrderFunctions.ID)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header" *ngIf="scope !== WorklogScope.USER">
          <app-table-sorter-no-op [text]="'WORKLOG_WORKER_USER' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header">
          <app-table-field-sorter [orderField]="Worklog.OrderFunctions.START_TIME"
                                  [orderType]="queryModel.getOrderType(Worklog.OrderFunctions.START_TIME)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'WORKLOG_START_TIME' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header">
          <app-table-field-sorter [orderField]="Worklog.OrderFunctions.END_TIME"
                                  [orderType]="queryModel.getOrderType(Worklog.OrderFunctions.END_TIME)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'WORKLOG_END_TIME' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header">
          <app-table-sorter-no-op [text]="'WORKLOG_CALCULATED_TIME' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header">
          <app-table-field-sorter [orderField]="Worklog.OrderFunctions.LOGGED_TIME_IN_MINUTES"
                                  [orderType]="queryModel.getOrderType(Worklog.OrderFunctions.LOGGED_TIME_IN_MINUTES)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'WORKLOG_LOGGED_TIME' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header">
          <app-table-field-sorter [orderField]="Worklog.OrderFunctions.ESTIMATED_TIME_IN_MINUTES"
                                  [orderType]="queryModel.getOrderType(Worklog.OrderFunctions.ESTIMATED_TIME_IN_MINUTES)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'ESTIMATED_LOGGED_TIME' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header" *ngIf="scope !== WorklogScope.TASK_RECORD">
          <app-table-sorter-no-op [text]="'WORKLOG_TASK_RECORD' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header w-table-options-menu">
          <app-table-sorter-no-op></app-table-sorter-no-op>
        </th>
      </tr>
      </tfoot>
    </table>
    <app-table-paging
      [currentNumberOfItems]="queryModel.currentNumberOfItems"
      [totalNumberOfItems]="queryModel.totalNumberOfItems"
      [itemsPerPage]="queryModel.itemsPerPage"
      (pageChange)="pageChanged($event)"
      (itemsPerPageChange)="itemsPerPageChanged($event)">
    </app-table-paging>
  </div>
</div>
