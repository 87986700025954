<div>
  <div *ngIf="!model.totalNumberOfSelectableData; else elseBlock">
    <div>{{model.displayedValue}}</div>
  </div>
  <ng-template #elseBlock>
    <div *ngIf="model.displayedValues">
      <div *ngFor="let value of getTopSelected()" class="d-flex flex-row">
        <app-animated-checkbox [selected]="true" [readonly]="true">
        </app-animated-checkbox>
        <span class="ml-05">{{value}}</span>
      </div>
    </div>
    <div *ngIf="model.notSelectedValues">
      <div *ngFor="let value of getNotSelected()" class="d-flex flex-row">
        <app-animated-checkbox [selected]="false" [readonly]="true">
        </app-animated-checkbox>
        <span class="text-muted ml-05">{{value}}</span>
      </div>
    </div>
    <span
      class="table-text-small text-muted"
      *ngIf="displayMoreSelected()"
      [popover]="morePopover" triggers="mouseenter:mouseleave" container="body">
      {{'TABLE_DISPLAYED_FORM_FIELD_CELL_SHOW_MORE' | translate: {count: getMoreCount()} }}
    </span>
  </ng-template>
</div>
<ng-template #morePopover>
  <div *ngFor="let value of getMoreSelected()" class="d-flex flex-row">
    <span class="table-text-small">{{value}}</span>
  </div>
  <div class="table-text-small text-muted" *ngIf="getMoreMoreCount() > 0">
    {{'TABLE_DISPLAYED_FORM_FIELD_CELL_SHOW_MORE' | translate: {count: getMoreMoreCount()} }}
  </div>
</ng-template>
