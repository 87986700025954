<div class="modal-content">
  <div class="modal-header">
    <p class="modal-title">
      <strong>
        {{(model.contactPerson ? 'CUSTOMER_RECORD_CONTACT_PERSON_CREATE_TITLE' : 'CUSTOMER_RECORD_CREATE_BREADCRUMB') | translate}}
      </strong>
    </p>
    <button type="button" class="close" (click)="closeDialog(false)" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <mat-dialog-content [ngStyle]="{'overflow': model.selectedCustomer ? 'auto' : 'visible'}" #matContent>
      <stepper-container #stepper
                         (onStep)="scrollToTop()"
                         (onSave)="createCustomerRecord()">
        <stepper-item #stepBasicData
                      [iconClass]="'icomoon-roles'"
                      [title]="(model.contactPerson ? 'CUSTOMER_RECORD_CONTACT_PERSON_CREATE_BASIC_DATA' : 'CUSTOMER_RECORD_QUICK_CREATE_BASIC_DATA') | translate"
                      [additionalValidationRule]="basicDataStepValid">

          <label class="col-form-label mw-100 form-control-label font-weight-bold">
            {{(model.contactPerson ? 'CUSTOMER_RECORD_CONTACT_PERSON_CREATE_BASIC_DATA' : 'CUSTOMER_RECORD_QUICK_CREATE_BASIC_DATA') | translate }}
          </label>

          <!-- type-->
          <div class="form-group row" [ngClass]="{ 'has-danger': stepBasicData.submitted && !type.valid }">
            <label
              class="col-form-label col-md-4 form-control-label required-field-label text-md-right">
              {{(model.contactPerson ? 'CUSTOMER_RECORD_CONTACT_PERSON_CREATE_CUSTOMER_TYPE' : 'CUSTOMER_SEARCH_FIELD_TYPE') | translate }}
            </label>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  [(ngModel)]="model.customer"
                  (ngModelChange)="onCustomerChanged()"
                  [data]="customers"
                  [settings]="dropdownSettings"
                  name="type"
                  id="type"
                  #type="ngModel"
                  required>
                </angular2-multiselect>
                <div class="form-control-feedback"
                     *ngIf="stepBasicData.submitted && !type.valid">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
              </div>
            </div>
          </div>

          <!-- simple name-->
          <div class="form-group row" *ngIf="!isComplexNameRequired"
               [ngClass]="{ 'has-danger': stepBasicData.submitted && !Strings.undefinedOrNonEmpty(model.name) }">
            <label
              class="col-form-label col-md-4 form-control-label required-field-label text-md-right">
              {{(model.contactPerson ? 'CUSTOMER_RECORD_CONTACT_PERSON_CREATE_NAME' : 'CUSTOMER_RECORD_NAME') | translate }}
            </label>
            <div class="col-md-8">
              <input type="text" class="form-control"
                     placeholder="{{(model.contactPerson ? 'CUSTOMER_RECORD_CONTACT_PERSON_CREATE_NAME' : 'CUSTOMER_RECORD_NAME') | translate}}"
                     [(ngModel)]="model.name"
                     name="name"
                     id="name"
                     #name="ngModel">
              <div class="form-control-feedback"
                   *ngIf="stepBasicData.submitted && !Strings.undefinedOrNonEmpty(model.name)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
          </div>

          <!-- complex name-->
          <div *ngIf="isComplexNameRequired">
            <div class="form-group row">
              <label
                class="col-form-label col-md-4 form-control-label required-field-label text-md-right">
                {{'CUSTOMER_RECORD_FIRST_NAME' | translate }}
              </label>
              <div class="col-md-8"
                   [ngClass]="{ 'has-danger': stepBasicData.submitted && !Strings.undefinedOrNonEmpty(model.firstName) }">
                <input type="text" class="form-control"
                       placeholder="{{'CUSTOMER_RECORD_FIRST_NAME' | translate}}"
                       [(ngModel)]="model.firstName"
                       name="firstName"
                       id="firstName"
                       #firstName="ngModel">
                <div class="form-control-feedback"
                     *ngIf="stepBasicData.submitted && !Strings.undefinedOrNonEmpty(model.firstName)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label
                class="col-form-label col-md-4 form-control-label required-field-label text-md-right">
                {{'CUSTOMER_RECORD_LAST_NAME' | translate }}
              </label>
              <div class="col-md-8"
                   [ngClass]="{ 'has-danger': stepBasicData.submitted && !Strings.undefinedOrNonEmpty(model.lastName) }">
                <input type="text" class="form-control"
                       placeholder="{{'CUSTOMER_RECORD_LAST_NAME' | translate}}"
                       [(ngModel)]="model.lastName"
                       name="lastName"
                       id="lastName"
                       #lastName="ngModel">
                <div class="form-control-feedback"
                     *ngIf="stepBasicData.submitted && !Strings.undefinedOrNonEmpty(model.lastName)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
              </div>
            </div>
          </div>

          <!-- position -->
          <div class="form-group row"
               [ngClass]="{ 'has-danger': stepBasicData.submitted && !position.valid }"
               *ngIf="model.customer && model.customer.length === 1 && model.customer[0].type === 'PERSON' && isFieldManaged(CustomerRecordFieldType.POSITION)">
            <label
              class="col-form-label col-md-4 form-control-label text-md-right">
              {{'CUSTOMER_RECORD_POSITION' | translate }}
            </label>
            <div class="col-md-8">
              <input type="text" class="form-control" placeholder="{{'CUSTOMER_RECORD_POSITION' | translate}}"
                     [(ngModel)]="model.position"
                     [ngClass]="{ 'form-control-danger': !position.valid && (stepBasicData.submitted) }"
                     name="position"
                     id="position"
                     #position="ngModel"
                     [required]="isFieldRequired(CustomerRecordFieldType.POSITION)">
              <div class="form-control-feedback"
                   *ngIf="stepBasicData.submitted && !position.valid">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
          </div>

          <!-- position -->
          <div class="form-group row"
               [ngClass]="{ 'has-danger': stepBasicData.submitted && !mainContractNumber.valid }"
               *ngIf="model.customer && model.customer.length === 1 && isFieldManaged(CustomerRecordFieldType.MAIN_CONTRACT_NUMBER)">
            <label
              class="col-form-label col-md-4 form-control-label text-md-right">
              {{'CUSTOMER_RECORD_CONTRACT_NUMBER' | translate }}
            </label>
            <div class="col-md-8">
              <input type="text" class="form-control" placeholder="{{'CUSTOMER_RECORD_CONTRACT_NUMBER' | translate}}"
                     [(ngModel)]="model.mainContractNumber"
                     [ngClass]="{ 'form-control-danger': !mainContractNumber.valid && (stepBasicData.submitted) }"
                     name="mainContractNumber"
                     id="mainContractNumber"
                     #mainContractNumber="ngModel"
                     [required]="isFieldRequired(CustomerRecordFieldType.MAIN_CONTRACT_NUMBER)">
              <div class="form-control-feedback"
                   *ngIf="stepBasicData.submitted && !mainContractNumber.valid">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
          </div>

          <div *ngIf="isFieldManaged(CustomerRecordFieldType.ASSIGNEE)">

            <label class="col-form-label mw-100 form-control-label font-weight-bold">
              {{(model.contactPerson ? 'CUSTOMER_RECORD_CONTACT_PERSON_CREATE_ASSIGNEE' : 'CUSTOMER_RECORD_QUICK_CREATE_ASSIGNEE') | translate }}
            </label>

            <div [class.assignee-required]="isFieldRequired(CustomerRecordFieldType.ASSIGNEE)"
                 [class.assignee-required-danger]="isFieldRequired(CustomerRecordFieldType.ASSIGNEE) && stepBasicData.submitted && model.users.length === 0 && model.userGroups.length === 0">

              <!--Users-->
              <div class="form-group row"
                   [ngClass]="{ 'has-danger': !usersInput.valid && (stepBasicData.submitted || (usersInput.errors && usersInput.errors['hasDisabledItem'])) }">
                <label class="col-form-label col-md-4 form-control-label text-md-right">
                  {{'CUSTOMER_RECORD_USERS' | translate }}
                </label>
                <div class="col-md-8">
                  <div class="mw-100">
                    <angular2-multiselect
                      [(ngModel)]="model.users"
                      [ngClass]="{ 'form-control-danger': !usersInput.valid && (stepBasicData.submitted || (usersInput.errors && usersInput.errors['hasDisabledItem'])) }"
                      [data]="users"
                      [settings]="assigneeDropdownSettings"
                      (onRemoteSearch)="loadUserSearch($event.target.value)"
                      name="users"
                      id="users"
                      validateEnabledItems
                      #usersInput="ngModel">
                    </angular2-multiselect>
                    <div class="form-control-feedback"
                         *ngIf="!usersInput.valid && (stepBasicData.submitted || (usersInput.errors && usersInput.errors['hasDisabledItem']))">
                      {{'COMMON_VALIDATION_MESSAGE_DISABLED'
                      | translate}}
                    </div>
                  </div>
                </div>
              </div>

              <!--UserGroups-->
              <div class="form-group row"
                   [ngClass]="{ 'has-danger': !userGroupsInput.valid && (stepBasicData.submitted || (userGroupsInput.errors && userGroupsInput.errors['hasDisabledItem'])) }">
                <label class="col-form-label col-md-4 form-control-label text-md-right">
                  {{'CUSTOMER_RECORD_USER_GROUPS' | translate }}
                  <i class="icomoon icomoon-info info-icon" popover="{{'CUSTOMER_RECORD_USER_GROUPS_HINT' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right">
                  </i>
                </label>
                <div class="col-md-8">
                  <div class="mw-100">
                    <angular2-multiselect
                      [(ngModel)]="model.userGroups"
                      [ngClass]="{ 'form-control-danger': !userGroupsInput.valid && (stepBasicData.submitted || (userGroupsInput.errors && userGroupsInput.errors['hasDisabledItem'])) }"
                      [data]="userGroups"
                      [settings]="assigneeDropdownSettings"
                      (onRemoteSearch)="loadUserGroupSearch($event.target.value)"
                      name="userGroups"
                      id="userGroups"
                      validateEnabledItems
                      #userGroupsInput="ngModel">
                    </angular2-multiselect>
                    <div class="form-control-feedback"
                         *ngIf="!userGroupsInput.valid && (stepBasicData.submitted || (userGroupsInput.errors && userGroupsInput.errors['hasDisabledItem']))">
                      {{'COMMON_VALIDATION_MESSAGE_DISABLED'
                      | translate}}
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-control-feedback assignee-required-error"
                   *ngIf="isFieldRequired(CustomerRecordFieldType.ASSIGNEE) && stepBasicData.submitted && model.users.length === 0 && model.userGroups.length === 0">
                <label>{{'CUSTOMER_RECORD_ASSIGNEE_REQUIRED' | translate}}</label>
              </div>

            </div>

          </div>

          <label
            *ngIf="isFieldManaged(CustomerRecordFieldType.PHONE_NUMBERS) || isFieldManaged(CustomerRecordFieldType.EMAIL_ADDRESSES)"
            class="col-form-label mw-100 form-control-label font-weight-bold">
            {{'CUSTOMER_RECORD_QUICK_CREATE_CONTACT' | translate }}
          </label>


          <!-- Phone number-->
          <div *ngIf="isFieldManaged(CustomerRecordFieldType.PHONE_NUMBERS)" class="mb-1">
            <app-phone-number-list
              [items]="model.phoneNumbers"
              [label]="'CUSTOMER_RECORD_PHONE_NUMBERS' | translate"
              [form]="stepBasicData.form"
              [required]="isFieldRequired(CustomerRecordFieldType.PHONE_NUMBERS)">
            </app-phone-number-list>
          </div>

          <!-- Email address-->
          <div *ngIf="isFieldManaged(CustomerRecordFieldType.EMAIL_ADDRESSES)" class="mb-1">
            <app-email-address-list
              [items]="model.emailAddresses"
              [label]="'CUSTOMER_RECORD_EMAIL_ADDRESSES' | translate"
              [form]="stepBasicData.form"
              [required]="isFieldRequired(CustomerRecordFieldType.EMAIL_ADDRESSES)">
            </app-email-address-list>
          </div>

        </stepper-item>
        <stepper-item #stepLocation
                      *ngIf="isFieldManaged(CustomerRecordFieldType.POSTAL_ADDRESS_AND_INVOICE_ADDRESS_AND_NOTIFICATION_ADDRESS)"
                      [iconClass]="'icomoon-location'"
                      [title]="(model.contactPerson ? 'CUSTOMER_RECORD_CONTACT_PERSON_CREATE_LOCATION' : 'CUSTOMER_RECORD_QUICK_CREATE_LOCATION') | translate"
                      [additionalValidationRule]="locationStepValid">

          <label class="col-form-label mw-100 form-control-label font-weight-bold">
            {{(model.contactPerson ? 'CUSTOMER_RECORD_CONTACT_PERSON_CREATE_LOCATION' : 'CUSTOMER_RECORD_QUICK_CREATE_LOCATION') | translate }}
          </label>

          <!-- postal address-->
          <div class="form-group">
            <app-postal-address-field
              [form]="stepLocation.form"
              [model]="model.postalAddress"
              [requiredField]="isFieldRequired(CustomerRecordFieldType.POSTAL_ADDRESS_AND_INVOICE_ADDRESS_AND_NOTIFICATION_ADDRESS)"
              [readonly]="false"
              [autoAdd]="isFieldRequired(CustomerRecordFieldType.POSTAL_ADDRESS_AND_INVOICE_ADDRESS_AND_NOTIFICATION_ADDRESS)"
              [fieldRatio]="8">
            </app-postal-address-field>
          </div>

        </stepper-item>
        <stepper-item #stepContactLocation
                      *ngIf="data.forContactPersonData && contactLocations.length > 0"
                      [iconClass]="'icomoon-company'"
                      [title]="'CUSTOMER_RECORD_QUICK_CREATE_CONTACT_LOCATIONS' | translate">

          <label class="col-form-label mw-100 form-control-label font-weight-bold">
            {{'CUSTOMER_RECORD_QUICK_CREATE_CONTACT_LOCATIONS' | translate }}
          </label>

          <!--UserGroups-->
          <div class="form-group row">
            <label class="col-form-label col-md-4 form-control-label text-md-right">
              {{'CUSTOMER_RECORD_QUICK_CREATE_CONTACT_LOCATIONS' | translate}}
            </label>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  [(ngModel)]="model.contactLocations"
                  [data]="contactLocations"
                  [settings]="locationDropdownSettings"
                  name="contactLocationsInput"
                  id="contactLocationsInput"
                  #contactLocationsInput="ngModel">
                </angular2-multiselect>
              </div>
            </div>
          </div>

        </stepper-item>
        <stepper-item #stepOtherData
                      *ngIf="otherDataStepVisible()"
                      [iconClass]="'icomoon-detail'"
                      [title]="'CUSTOMER_RECORD_QUICK_CREATE_OTHER_DATA' | translate"
                      [additionalValidationRule]="otherDataStepValid">

          <label class="col-form-label mw-100 form-control-label font-weight-bold">
            {{'CUSTOMER_RECORD_QUICK_CREATE_OTHER_DATA' | translate }}
          </label>

          <!-- Web address -->
          <div class="form-group row"
               [ngClass]="{ 'has-danger': !webAddress.valid && (stepOtherData.submitted || (webAddress.errors && webAddress.errors['validateUrl'])) }"
               *ngIf="isFieldManaged(CustomerRecordFieldType.WEB_ADDRESS)">
            <label class="col-form-label col-md-4 form-control-label text-md-right"
            [ngClass]="{'required-field-label': isFieldRequired(CustomerRecordFieldType.WEB_ADDRESS)}">
              {{'CUSTOMER_RECORD_WEB_ADDRESS' | translate }}
            </label>
            <div class="col-md-8">
              <div class="input-group">
                <input type="text" class="form-control"
                       [ngClass]="{ 'form-control-danger': !webAddress.valid && (stepOtherData.submitted || (webAddress.errors && webAddress.errors['validateUrl'])) }"
                       [(ngModel)]="model.webAddress"
                       placeholder="{{ 'CUSTOMER_RECORD_WEB_ADDRESS' | translate }}"
                       name="webAddress"
                       id="webAddress"
                       #webAddress="ngModel"
                       [required]="isFieldRequired(CustomerRecordFieldType.WEB_ADDRESS)"
                       validateUrl>
              </div>
              <div class="form-control-feedback"
                   *ngIf="stepOtherData.submitted && webAddress.errors && webAddress.errors['required']">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
              <div class="form-control-feedback"
                   *ngIf="webAddress.errors && webAddress.errors['validateUrl']">{{'COMMON_VALIDATION_MESSAGE_INVALID_URL' | translate}}
              </div>
            </div>
          </div>

          <!-- TAX number -->
          <div class="form-group row" [ngClass]="{ 'has-danger': stepOtherData.submitted && !taxNumber.valid }"
               *ngIf="isFieldManaged(CustomerRecordFieldType.TAX_NUMBER)">
            <label class="col-form-label col-md-4 form-control-label text-md-right"
                   [ngClass]="{'required-field-label': isFieldRequired(CustomerRecordFieldType.TAX_NUMBER)}">
              {{'CUSTOMER_RECORD_TAX_NUMBER' | translate}}
            </label>
            <div class="col-md-8">
              <input type="text" class="form-control"
                     placeholder="{{'CUSTOMER_RECORD_TAX_NUMBER' | translate}}"
                     [textMask]="{mask: InputMask.HU_TAX_NUMBER, guide: true}"
                     [ngClass]="{ 'form-control-danger': stepOtherData.submitted && !taxNumber.valid }"
                     [(ngModel)]="model.taxNumber.raw"
                     name="taxNumber"
                     id="taxNumber"
                     #taxNumber="ngModel"
                     [required]="isFieldRequired(CustomerRecordFieldType.TAX_NUMBER)"
                     validateHuTaxNumber>
              <div class="form-control-feedback"
                   *ngIf="stepOtherData.submitted && taxNumber.errors && taxNumber.errors['required']">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
              <div class="form-control-feedback"
                   *ngIf="stepOtherData.submitted && taxNumber.errors && taxNumber.errors['validateHuTaxNumber']">{{'COMMON_VALIDATION_MESSAGE_INVALID_HU_TAX_NUMBER' | translate}}
              </div>
            </div>
          </div>

          <!-- EU TAX number -->
          <div class="form-group row" [ngClass]="{ 'has-danger': stepOtherData.submitted && !euTaxNumber.valid }"
               *ngIf="isFieldManaged(CustomerRecordFieldType.EU_TAX_NUMBER)">
            <label class="col-form-label col-md-4 form-control-label text-md-right"
                   [ngClass]="{'required-field-label': isFieldRequired(CustomerRecordFieldType.EU_TAX_NUMBER)}">
              {{'CUSTOMER_RECORD_EU_TAX_NUMBER' | translate}}
            </label>
            <div class="col-md-8">
              <input type="text" class="form-control"
                     placeholder="{{'CUSTOMER_RECORD_EU_TAX_NUMBER' | translate}}"
                     [ngClass]="{ 'form-control-danger': stepOtherData.submitted && !euTaxNumber.valid }"
                     [(ngModel)]="model.euTaxNumber.raw"
                     name="euTaxNumber"
                     id="euTaxNumber"
                     #euTaxNumber="ngModel"
                     [required]="isFieldRequired(CustomerRecordFieldType.EU_TAX_NUMBER)"
                     validateEuVatNumber>
              <div class="form-control-feedback"
                   *ngIf="stepOtherData.submitted && euTaxNumber.errors && euTaxNumber.errors['required']">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
              <div class="form-control-feedback"
                   *ngIf="stepOtherData.submitted && euTaxNumber.errors && euTaxNumber.errors['validateEuVatNumber']">{{'COMMON_VALIDATION_MESSAGE_INVALID_EU_VAT_NUMBER' | translate}}
              </div>
            </div>
          </div>

          <!-- Invoice deadline additional days -->
          <div class="form-group row"
               [ngClass]="{ 'has-danger': stepOtherData.submitted && !invoiceDeadlineAdditionalDays.valid }"
               *ngIf="isFieldManaged(CustomerRecordFieldType.INVOICE_DEADLINE_ADDITIONAL_DAYS)">
            <label class="col-form-label col-md-4 form-control-label text-md-right"
            [ngClass]="{'required-field-label': isFieldRequired(CustomerRecordFieldType.INVOICE_DEADLINE_ADDITIONAL_DAYS)}">
              {{'CUSTOMER_RECORD_INVOICE_DEADLINE_ADDITIONAL_DAYS' | translate}}
            </label>
            <div class="col-md-8">
              <input type="text" class="form-control"
                     placeholder="{{'CUSTOMER_RECORD_INVOICE_DEADLINE_ADDITIONAL_DAYS' | translate}}"
                     [textMask]="{mask: InputMask.NATURAL_INTEGER, guide: false}" maxlength="5"
                     [ngClass]="{ 'form-control-danger': stepOtherData.submitted && !invoiceDeadlineAdditionalDays.valid }"
                     [(ngModel)]="model.invoiceDeadlineAdditionalDays"
                     name="invoiceDeadlineAdditionalDays"
                     id="invoiceDeadlineAdditionalDays"
                     #invoiceDeadlineAdditionalDays="ngModel"
                     [required]="isFieldRequired(CustomerRecordFieldType.INVOICE_DEADLINE_ADDITIONAL_DAYS)">
              <div class="form-control-feedback"
                   *ngIf="stepOtherData.submitted && invoiceDeadlineAdditionalDays.errors">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
          </div>

          <!-- Task record deadline additional days -->
          <div class="form-group row"
               [ngClass]="{ 'has-danger': stepOtherData.submitted && !taskRecordDeadlineAdditionalDays.valid }"
               *ngIf="isFieldManaged(CustomerRecordFieldType.TASK_RECORD_DEADLINE_ADDITIONAL_DAYS)">
            <label class="col-form-label col-md-4 form-control-label text-md-right"
            [ngClass]="{'required-field-label': isFieldRequired(CustomerRecordFieldType.TASK_RECORD_DEADLINE_ADDITIONAL_DAYS)}">
              {{'CUSTOMER_RECORD_TASK_RECORD_DEADLINE_ADDITIONAL_DAYS' | translate}}
            </label>
            <div class="col-md-8">
              <input type="text" class="form-control"
                     placeholder="{{'CUSTOMER_RECORD_TASK_RECORD_DEADLINE_ADDITIONAL_DAYS' | translate}}"
                     [textMask]="{mask: InputMask.NATURAL_INTEGER, guide: false}" maxlength="5"
                     [ngClass]="{ 'form-control-danger': stepOtherData.submitted && !taskRecordDeadlineAdditionalDays.valid }"
                     [(ngModel)]="model.taskRecordDeadlineAdditionalDays"
                     name="taskRecordDeadlineAdditionalDays"
                     id="taskRecordDeadlineAdditionalDays"
                     #taskRecordDeadlineAdditionalDays="ngModel"
                     [required]="isFieldRequired(CustomerRecordFieldType.TASK_RECORD_DEADLINE_ADDITIONAL_DAYS)">
              <div class="form-control-feedback"
                   *ngIf="stepOtherData.submitted && taskRecordDeadlineAdditionalDays.errors">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
          </div>

          <!-- Place of birth -->
          <div class="form-group row" [ngClass]="{ 'has-danger': stepOtherData.submitted && !placeOfBirth.valid }"
               *ngIf="isFieldManaged(CustomerRecordFieldType.PLACE_OF_BIRTH)">
            <label class="col-form-label col-md-4 form-control-label text-md-right"
            [ngClass]="{'required-field-label': isFieldRequired(CustomerRecordFieldType.PLACE_OF_BIRTH)}">
              {{'CUSTOMER_RECORD_PLACE_OF_BIRTH' | translate }}
            </label>
            <div class="col-md-8">
              <input type="text" class="form-control"
                     [ngClass]="{ 'form-control-danger': stepOtherData.submitted && !placeOfBirth.valid }"
                     [(ngModel)]="model.placeOfBirth"
                     placeholder="{{ 'CUSTOMER_RECORD_PLACE_OF_BIRTH' | translate }}"
                     name="placeOfBirth"
                     id="placeOfBirth"
                     #placeOfBirth="ngModel"
                     [required]="isFieldRequired(CustomerRecordFieldType.PLACE_OF_BIRTH)">
              <div class="form-control-feedback"
                   *ngIf="stepOtherData.submitted && placeOfBirth.errors">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
          </div>

          <!-- Date of birth -->
          <div class="form-group row" [ngClass]="{ 'has-danger': stepOtherData.submitted && !dateOfBirthInput.valid }"
               *ngIf="isFieldManaged(CustomerRecordFieldType.DATE_OF_BIRTH)">
            <label class="col-form-label col-md-4 form-control-label text-md-right"
            [ngClass]="{'required-field-label': isFieldRequired(CustomerRecordFieldType.DATE_OF_BIRTH)}">
              {{'CUSTOMER_RECORD_DATE_OF_BIRTH' | translate }}
            </label>
            <div class="col-md-8">
              <div class="input-group">
                <input type="text" class="form-control"
                       placeholder="{{'CUSTOMER_RECORD_DATE_OF_BIRTH' | translate}}"
                       [ngClass]="{ 'form-control-danger': stepOtherData.submitted && !dateOfBirthInput.valid }"
                       [(ngModel)]="model.dateOfBirth"
                       [minDate]="CustomerRecordUtils.DateOfBirthMinDate"
                       [maxDate]="CustomerRecordUtils.DateOfBirthMaxDate"
                       [startDate]="CustomerRecordUtils.DateOfBirthDefaultDate"
                       name="dateOfBirth"
                       id="dateOfBirth"
                       #dateOfBirth="ngbDatepicker"
                       #dateOfBirthInput="ngModel"
                       ngbDatepicker
                       validateLocalDate
                       [required]="isFieldRequired(CustomerRecordFieldType.DATE_OF_BIRTH)">
                <span class="add-on input-group-addon-gray" (click)="dateOfBirth.toggle()">
                  <i class="icomoon icomoon-calendar"></i>
                     </span>
              </div>
              <div class="form-control-feedback"
                   *ngIf="stepOtherData.submitted && dateOfBirthInput.errors && dateOfBirthInput.errors['required']">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
              <div class="form-control-feedback"
                   *ngIf="stepOtherData.submitted && dateOfBirthInput.errors && dateOfBirthInput.errors['validateLocalDate']">{{'COMMON_VALIDATION_MESSAGE_INVALID_LOCAL_DATE' | translate}}
              </div>
            </div>
          </div>

          <!-- Birth name -->
          <div class="form-group row" [ngClass]="{ 'has-danger': stepOtherData.submitted && !birthName.valid }"
               *ngIf="isFieldManaged(CustomerRecordFieldType.BIRTH_NAME)">
            <label class="col-form-label col-md-4 form-control-label text-md-right"
            [ngClass]="{'required-field-label': isFieldRequired(CustomerRecordFieldType.BIRTH_NAME)}">
              {{'CUSTOMER_RECORD_BIRTH_NAME' | translate }}
            </label>
            <div class="col-md-8">
              <input type="text" class="form-control"
                     [ngClass]="{ 'form-control-danger': stepOtherData.submitted && !birthName.valid }"
                     [(ngModel)]="model.birthName"
                     placeholder="{{ 'CUSTOMER_RECORD_BIRTH_NAME' | translate }}"
                     name="birthName"
                     id="birthName"
                     #birthName="ngModel"
                     [required]="isFieldRequired(CustomerRecordFieldType.BIRTH_NAME)">
              <div class="form-control-feedback"
                   *ngIf="stepOtherData.submitted && birthName.errors">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
          </div>

          <!-- Gender -->
          <div class="form-group row" [ngClass]="{ 'has-danger': stepOtherData.submitted && !gender.valid }"
               *ngIf="isFieldManaged(CustomerRecordFieldType.GENDER)">
            <label class="col-form-label col-md-4 form-control-label text-md-right"
            [ngClass]="{'required-field-label': isFieldRequired(CustomerRecordFieldType.GENDER)}">
              {{'CUSTOMER_RECORD_GENDER' | translate }}
            </label>
            <div class="col-md-8">
              <select class="form-control"
                      [compareWith]="SelectUtils.compareStrings"
                      [(ngModel)]="model.gender"
                      [ngClass]="{ 'form-control-danger': stepOtherData.submitted && !gender.valid }"
                      name="gender"
                      id="gender"
                      #gender="ngModel"
                      [required]="isFieldRequired(CustomerRecordFieldType.GENDER)">
                <option *ngFor="let item of genderTypes" [ngValue]="item.id">{{item.text}}</option>
              </select>
              <div class="form-control-feedback"
                   *ngIf="stepOtherData.submitted && gender.errors">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
          </div>

          <!-- Mother's name -->
          <div class="form-group row" [ngClass]="{ 'has-danger': stepOtherData.submitted && !mothersName.valid }"
               *ngIf="isFieldManaged(CustomerRecordFieldType.MOTHERS_NAME)">
            <label class="col-form-label col-md-4 form-control-label text-md-right"
            [ngClass]="{'required-field-label': isFieldRequired(CustomerRecordFieldType.MOTHERS_NAME)}">
              {{'CUSTOMER_RECORD_MOTHERS_NAME' | translate }}
            </label>
            <div class="col-md-8">
              <input type="text" class="form-control"
                     [ngClass]="{ 'form-control-danger': stepOtherData.submitted && !mothersName.valid }"
                     [(ngModel)]="model.mothersName"
                     placeholder="{{ 'CUSTOMER_RECORD_MOTHERS_NAME' | translate }}"
                     name="mothersName"
                     id="mothersName"
                     #mothersName="ngModel"
                     [required]="isFieldRequired(CustomerRecordFieldType.MOTHERS_NAME)">
              <div class="form-control-feedback"
                   *ngIf="stepOtherData.submitted && mothersName.errors">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
          </div>

          <!-- Identity document type -->
          <div class="form-group row"
               [ngClass]="{ 'has-danger': stepOtherData.submitted && !identityDocumentType.valid }"
               *ngIf="isFieldManaged(CustomerRecordFieldType.IDENTITY_DOCUMENT_TYPE)">
            <label class="col-form-label col-md-4 form-control-label text-md-right"
            [ngClass]="{'required-field-label': isFieldRequired(CustomerRecordFieldType.IDENTITY_DOCUMENT_TYPE)}">
              {{'CUSTOMER_RECORD_IDENTITY_DOCUMENT_TYPE' | translate }}
            </label>
            <div class="col-md-8">
              <input type="text" class="form-control"
                     [ngClass]="{ 'form-control-danger': stepOtherData.submitted && !identityDocumentType.valid }"
                     [(ngModel)]="model.identityDocumentType"
                     placeholder="{{ 'CUSTOMER_RECORD_IDENTITY_DOCUMENT_TYPE' | translate }}"
                     name="identityDocumentType"
                     id="identityDocumentType"
                     #identityDocumentType="ngModel"
                     [required]="isFieldRequired(CustomerRecordFieldType.IDENTITY_DOCUMENT_TYPE)">
              <div class="form-control-feedback"
                   *ngIf="stepOtherData.submitted && identityDocumentType.errors">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
          </div>

          <!-- Identity document number -->
          <div class="form-group row"
               [ngClass]="{ 'has-danger': stepOtherData.submitted && !identityDocumentNumber.valid }"
               *ngIf="isFieldManaged(CustomerRecordFieldType.IDENTITY_DOCUMENT_NUMBER)">
            <label class="col-form-label col-md-4 form-control-label text-md-right"
            [ngClass]="{'required-field-label': isFieldRequired(CustomerRecordFieldType.IDENTITY_DOCUMENT_NUMBER)}">
              {{'CUSTOMER_RECORD_IDENTITY_DOCUMENT_NUMBER' | translate }}
            </label>
            <div class="col-md-8">
              <input type="text" class="form-control"
                     [ngClass]="{ 'form-control-danger': stepOtherData.submitted && !identityDocumentNumber.valid }"
                     [(ngModel)]="model.identityDocumentNumber"
                     placeholder="{{ 'CUSTOMER_RECORD_IDENTITY_DOCUMENT_NUMBER' | translate }}"
                     name="identityDocumentNumber"
                     id="identityDocumentNumber"
                     #identityDocumentNumber="ngModel"
                     [required]="isFieldRequired(CustomerRecordFieldType.IDENTITY_DOCUMENT_NUMBER)">
              <div class="form-control-feedback"
                   *ngIf="stepOtherData.submitted && identityDocumentNumber.errors">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
          </div>

          <!--Company-->
          <div class="form-group row" [ngClass]="{ 'has-danger': stepOtherData.submitted && !company.valid }"
               *ngIf="isFieldManaged(CustomerRecordFieldType.COMPANY)">
            <label class="col-form-label col-md-4 form-control-label text-md-right"
            [ngClass]="{'required-field-label': isFieldRequired(CustomerRecordFieldType.COMPANY)}">
              {{'COMMON_COMPANY' | translate }}
            </label>
            <div class="col-md-8">
              <angular2-multiselect
                [(ngModel)]="model.company"
                [data]="companies"
                [settings]="companyDropdownSettings"
                (onRemoteSearch)="loadCompanies($event.target.value)"
                name="company"
                id="company"
                #company="ngModel"
                [required]="isFieldRequired(CustomerRecordFieldType.COMPANY)">
              </angular2-multiselect>
              <div class="form-control-feedback"
                   *ngIf="stepOtherData.submitted && company.errors">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
          </div>

          <!--LedgerNumber-->
          <div class="form-group row" [ngClass]="{ 'has-danger': stepOtherData.submitted && !ledgerNumber.valid }"
               *ngIf="isFieldManaged(CustomerRecordFieldType.LEDGER_NUMBER)">
            <label class="col-form-label col-md-4 form-control-label text-md-right"
            [ngClass]="{'required-field-label': isFieldRequired(CustomerRecordFieldType.LEDGER_NUMBER)}">
              {{'LEDGER_NUMBER_LEDGER_NUMBER' | translate }}
            </label>
            <div class="col-md-8">
              <angular2-multiselect
                [(ngModel)]="model.ledgerNumber"
                [data]="ledgerNumbers"
                [settings]="companyDropdownSettings"
                (onRemoteSearch)="loadLedgerNumbers($event.target.value)"
                name="ledgerNumber"
                id="ledgerNumber"
                #ledgerNumber="ngModel"
                [required]="isFieldRequired(CustomerRecordFieldType.LEDGER_NUMBER)">
              </angular2-multiselect>
              <div class="form-control-feedback"
                   *ngIf="stepOtherData.submitted && ledgerNumber.errors">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
          </div>

        </stepper-item>
      </stepper-container>
    </mat-dialog-content>
  </div>
  <div class="modal-footer justify-content-between">
    <div>
      <button type="submit" class="btn btn-outline-primary" (click)="onExtraDataClicked()" *ngIf="stepper.isLast">
        {{'FORM_ITEM_CREATE_DOCUMENT_EXTRA_DATA' | translate}}
      </button>
    </div>
    <div>
      <button type="button" class="btn btn-outline-primary" (click)="stepper.previous()" *ngIf="!stepper.isFirst">
        {{'COMMON_BUTTON_PREVIOUS' | translate}}
      </button>
      <button type="button" class="btn btn-primary ml-1" (click)="stepper.next()">
        {{(stepper.isLast ? 'COMMON_BUTTON_SAVE' : 'COMMON_BUTTON_NEXT') | translate}}
      </button>
    </div>
  </div>
</div><!-- /.modal-content -->
