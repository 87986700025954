/* eslint-disable */
import { Injectable } from '@angular/core';
import { FileItem, FileUploader, ParsedResponseHeaders } from 'ng2-file-upload';
import { EchoHeader, EchoHeaderBuilder, HeaderKeys, HeaderValues, ResourceHelper } from '../lib/util/http-services';
import { AuthTokenStorage } from '../lib/util/storages';
import { HttpClient } from '@angular/common/http';
import {AppTypeHelperService} from "../lib/util/app-type-helper.service";
/* eslint-enable */

@Injectable()
export class FileUploaderUtil {

  constructor(private resourceHelper: ResourceHelper,
              private appTypeHelperService: AppTypeHelperService) {
  }

  public createUploader(path: string, listener: FileUploadListener): FileUploader {
    const echoHeader: EchoHeader = new EchoHeaderBuilder()
      .ignoreGlobalErrors(true)
      .build();
    const simpleFileUploader = new FileUploader({
      url: this.resourceHelper.getBaseUrl() + path,
      method: 'POST',
      authToken: AuthTokenStorage.getInstance().getToken(this.appTypeHelperService.appType())!,
      headers: [{
        name: HeaderKeys.ECHO,
        value: HeaderValues.serializeEchoHeader(echoHeader)
      }]
    });

    simpleFileUploader.onBeforeUploadItem = (item: FileItem): any => {
      listener.onBeforeUpload();
    };

    simpleFileUploader.onErrorItem = ((item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any => {
      return listener.onUploadError(response, status);
    });

    simpleFileUploader.onSuccessItem = ((item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any => {
      listener.onSuccess(item, response, status, headers);
    });
    return simpleFileUploader;
  }
}

export interface FileUploadListener {
  onBeforeUpload(): any;
  onUploadError(response: string, status: number): any;
  onSuccess(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any;
}
