import { Component, OnInit, ViewChild } from '@angular/core';
import { SubTaskModel } from '../task-record-sub-task.component';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { UiConstants } from '../../../../../util/core-utils';

@Component({
  selector: 'app-task-record-sub-task-detail-dialog',
  templateUrl: './task-record-sub-task-detail-dialog.component.html',
  styleUrls: ['./task-record-sub-task-detail-dialog.component.scss']
})
export class TaskRecordSubTaskDetailDialogComponent implements OnInit {
  UiConstants = UiConstants;

  model: SubTaskModel = new SubTaskModel();

  @ViewChild('subTaskDetailDialog', { static: true }) subTaskDetailDialog: ModalDirective;
  subTaskDetailDialogVisible = false;

  constructor() { }

  ngOnInit() {
  }

  showSubTaskDetailDialog(subTaskModel: SubTaskModel) {
    this.model = subTaskModel;
    this.subTaskDetailDialogVisible = true;
    this.subTaskDetailDialog.show();
  }

  closeSubTaskDetailDialog() {
    this.subTaskDetailDialogVisible = false;
    this.subTaskDetailDialog.hide();
  }

}
