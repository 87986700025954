import {WorkflowResource} from '../workflow/workflow-resource.service';
import {Form} from './form.service';
import {Set} from 'immutable';
import {Workflow} from '../workflow/workflow.service';
import {TaskRecord} from "../task/task-record.service";

export namespace WorkflowRuleUtils {
  export function toPublicRule(r: WorkflowResource.BaseRule): Workflow.BaseRule {
    if (isResourceFormFieldRule(r)) {
      return toPublicFormFieldRule(r);
    }
    return toPublicTaskRule(<WorkflowResource.TaskRule>r);
  }

  export function toResourceRule(r: Workflow.BaseRule): WorkflowResource.BaseRule {
    if (isPublicFormFieldRule(r)) {
      return toResourceFormFieldRule(r);
    }
    return toResourceTaskRule(<Workflow.TaskRule>r);
  }

  export function isPublicFormFieldRule(object: any): object is Workflow.FormFieldRule {
    return 'formField' in object;
  }

  function isResourceFormFieldRule(object: any): object is WorkflowResource.FormFieldRule {
    return 'form_field' in object;
  }

  function toPublicFormFieldRule(r: WorkflowResource.FormFieldRule): Workflow.FormFieldRule {
    return {
      operator: r.operator ? <Workflow.RuleOperator>r.operator : undefined,
      type: <Workflow.FormFieldRuleType>r.type,
      formField: {
        id: r.form_field.id,
        disabled: r.form_field.disabled!,
        title: r.form_field.title!,
        selector: new Form.ResourceMapper().toPublicFieldDataTypeSelector(r.form_field.field_data_type!),
      },
      command: <Workflow.RuleCommand>r.command,
      stringValue: r.string_value,
      numberValue: r.number_value,
      listItems: r.list_items ? Set.of(...r.list_items.map(i => ({id: i.id, text: i.text!}))) : undefined
    };
  }

  function toResourceFormFieldRule(r: Workflow.FormFieldRule): WorkflowResource.FormFieldRule {
    return {
      rule_type: 'form_field',
      operator: r.operator ? r.operator.toString() : undefined,
      type: r.type.toString(),
      form_field: {
        id: r.formField.id
      },
      command: r.command,
      string_value: r.stringValue,
      number_value: r.numberValue,
      list_items: r.listItems ? r.listItems.toArray().map(rule => ({id: rule.id})) : undefined
    };
  }

  function toPublicTaskRule(r: WorkflowResource.TaskRule): Workflow.TaskRule {
    return {
      operator: r.operator ? <Workflow.RuleOperator>r.operator : undefined,
      type: <Workflow.TaskRuleType>r.type,
      command: <Workflow.RuleCommand>r.command,
      users: r.users ? Set.of(...r.users.map(u => {
        return {
          id: u.id,
          userName: u.user_name,
          personName: u.person_name
        }
      })) : undefined,
      userGroups: r.user_groups ? Set.of(...r.user_groups.map(u => {
        return {
          id: u.id,
          name: u.name,
        }
      })) : undefined,
      importances: r.importances ? Set.of(...r.importances.map(u => <TaskRecord.TaskRecordImportance>u)) : undefined,
    };
  }

  function toResourceTaskRule(r: Workflow.TaskRule): WorkflowResource.TaskRule {
    return {
      rule_type: 'task',
      operator: r.operator ? r.operator.toString() : undefined,
      type: r.type.toString(),
      command: r.command,
      users: r.users ? r.users.toArray().map(rule => ({id: rule.id})) : undefined,
      user_groups: r.userGroups ? r.userGroups.toArray().map(rule => ({id: rule.id})) : undefined,
      importances: r.importances ? r.importances.toArray() : undefined,
    };
  }
}
