import { Storages } from '../../util/repo/storages';
import { SiteTourId } from './site-tour.factory';
import { LoggedInUserStorage } from '../util/storages';

export class SiteTourStorage {

  private static readonly KEY_PREFIX = 'site_tour_';

  private static instance = new SiteTourStorage();

  private storageAvailable: boolean | null = null;

  public static getInstance(): SiteTourStorage {
    return SiteTourStorage.instance;
  }

  public clear(siteTourId: SiteTourId): void {
    try {
      localStorage.removeItem(`${SiteTourStorage.KEY_PREFIX}${siteTourId}(${LoggedInUserStorage.getInstance().getUserId()})`);
    } catch (error) {
      this.storageAvailable = false;
    }
  }

  public clearAll(): void {
    try {
      const keys = Object.keys(SiteTourId);
      keys.forEach(siteTourId => {
        this.clear(<SiteTourId>siteTourId);
      });
    } catch (error) {
      this.storageAvailable = false;
    }
  }

  public skipAll(): void {
    try {
      const keys = Object.keys(SiteTourId);
      keys.forEach(siteTourId => {
        this.setTourState(<SiteTourId>siteTourId, SiteTourState.SKIPPED);
      });
    } catch (error) {
      this.storageAvailable = false;
    }
  }

  public getTourState(siteTourId: SiteTourId): SiteTourStateObject {
    try {
      const state = localStorage.getItem(`${SiteTourStorage.KEY_PREFIX}${siteTourId}(${LoggedInUserStorage.getInstance().getUserId()})`);
      if (state === null) {
        return {state: SiteTourState.WAITING};
      }
      return <SiteTourStateObject>JSON.parse(state);
    } catch (error) {
      this.storageAvailable = false;
      return {state: SiteTourState.WAITING};
    }
  }

  public setTourState(siteTourId: SiteTourId, value: SiteTourState, selector?: string): void {
    try {
      localStorage.setItem(`${SiteTourStorage.KEY_PREFIX}${siteTourId}(${LoggedInUserStorage.getInstance().getUserId()})`, JSON.stringify({
        state: value,
        step: selector
      }));
    } catch (error) {
      this.storageAvailable = false;
    }
  }

  public isStorageAvailable(): boolean {
    if (this.storageAvailable === null) {
      this.storageAvailable = Storages.checkStorageAvailable();
    }
    return this.storageAvailable;
  }

  private constructor() {
  }

}

export class SiteTourStateObject {
  state: SiteTourState;
  step?: string;
}

export enum SiteTourState {
  WAITING = 'WAITING',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  SKIPPED = 'SKIPPED '
}

