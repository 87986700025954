<div bsModal [config]="UiConstants.modalConfig" #triggerEditDialog="bs-modal" class="modal" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="triggerEditDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title"><strong>{{ headingDictionaryKey | translate}}</strong></p>
        <button type="button" class="close" (click)="closeTriggerEditDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">

        <!-- PDF Trigger-->
        <div [formGroup]="pdfFormGroup" *ngIf="triggerType === 'PDF'">

          <div class="form-group row"
               [ngClass]="{ 'has-danger': (pdfFormGroup.controls['name'].touched && !pdfFormGroup.controls['name'].valid) || triggerFieldErrors.name}">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label required-field-label">
                {{'COMMON_NAME'|translate}}
              </label>
            </div>
            <div class="col-md-8">
              <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
                     [(ngModel)]="pdfTriggerEditModel.name" formControlName="name"
                     (ngModelChange)="removeFieldError(triggerFieldErrors.name)">
              <div class="form-control-feedback"
                   *ngIf="pdfFormGroup.controls['name'].touched && !pdfFormGroup.controls['name'].valid">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
              <div class="form-control-feedback" *ngIf="triggerFieldErrors.name">
                {{triggerFieldErrors.name.text}}
              </div>
            </div>
          </div>

          <div class="form-group row"
               [ngClass]="{ 'has-danger': pdfFormGroup.controls['event'].touched && !pdfFormGroup.controls['event'].valid }">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label required-field-label">
                {{'COMMON_TABLE_HEADER_EVENT'|translate}}
                <i class="icomoon icomoon-info info-icon" popover="{{'TRIGGER_PDF_TRIGGER_EVENT_HINT' | translate}}"
                   triggers="mouseenter:mouseleave" container="body" placement="right">
                </i>
              </label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  [(ngModel)]="pdfTriggerEditModel.event"
                  [data]="events"
                  [settings]="singleLocalDropdownSettings"
                  (ngModelChange)="onNotificationEventChanged(pdfTriggerEditModel.event)"
                  formControlName="event">
                </angular2-multiselect>
                <div class="form-control-feedback"
                     *ngIf="pdfFormGroup.controls['event'].touched && !pdfFormGroup.controls['event'].valid">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row"
               [ngClass]="{ 'has-danger': pdfFormGroup.controls['pdfTemplate'].touched && !pdfFormGroup.controls['pdfTemplate'].valid }">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label required-field-label">
                {{'TRIGGER_PDF_TEMPLATE'|translate}}
                <i class="icomoon icomoon-info info-icon" popover="{{'TRIGGER_PDF_TRIGGER_TEMPLATE_HINT' | translate}}"
                   triggers="mouseenter:mouseleave" container="body" placement="right">
                </i>
              </label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  [(ngModel)]="pdfTriggerEditModel.pdfTemplate"
                  [data]="pdfTemplates"
                  [settings]="singleRemoteDropdownSettings"
                  (onRemoteSearch)="loadPdfTemplates($event.target.value)"
                  formControlName="pdfTemplate">
                </angular2-multiselect>
                <div class="form-control-feedback"
                     *ngIf="pdfFormGroup.controls['pdfTemplate'].touched && !pdfFormGroup.controls['pdfTemplate'].valid && pdfFormGroup.controls['pdfTemplate'].errors['required']">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
                <div class="form-control-feedback"
                     *ngIf="pdfFormGroup.controls['pdfTemplate'].touched && !pdfFormGroup.controls['pdfTemplate'].valid && pdfFormGroup.controls['pdfTemplate'].errors['hasDisabledItem']">
                  {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row"
               [ngClass]="{ 'has-danger': pdfFormGroup.controls['fileNamePattern'].touched && !pdfFormGroup.controls['fileNamePattern'].valid }">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label required-field-label">
                {{'TRIGGER_FILE_NAME_PATTERN'|translate}}
                <i class="icomoon icomoon-info info-icon"
                   popover="{{'TRIGGER_PDF_TRIGGER_FILENAME_PATTERN_HINT' | translate}}"
                   triggers="mouseenter:mouseleave" container="body" placement="right">
                </i>
              </label>
            </div>
            <div class="col-md-8">
              <app-material-tag-input
                formControlName="fileNamePattern"
                [(ngModel)]="pdfTriggerEditModel.fileNamePattern"
                [placeholderStringKey]="'TRIGGER_FILE_NAME_PATTERN'"
                [autocompleteItems]="pdfTriggerFileNamePatternOptions"
                [separatorCharacter]="TaskTriggerFields.separatorCharacter"
                [separatorKeyCodes]="TaskTriggerFields.separatorKeyCodes"
                [shouldGenerateExample]="false">
              </app-material-tag-input>
              <div class="form-control-feedback"
                   *ngIf="pdfFormGroup.controls['fileNamePattern'].touched && !pdfFormGroup.controls['fileNamePattern'].valid">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
          </div>

        </div><!-- /.modal-body -->

        <!-- Receipt Trigger-->
        <div [formGroup]="receiptFormGroup" *ngIf="triggerType === 'RECEIPT'">

          <div class="form-group row"
               [ngClass]="{ 'has-danger': (receiptFormGroup.controls['name'].touched && !receiptFormGroup.controls['name'].valid) || triggerFieldErrors.name}">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label required-field-label">
                {{'COMMON_NAME'|translate}}
              </label>
            </div>
            <div class="col-md-8">
              <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
                     [(ngModel)]="receiptTriggerEditModel.name" formControlName="name"
                     (ngModelChange)="removeFieldError(triggerFieldErrors.name)">
              <div class="form-control-feedback"
                   *ngIf="receiptFormGroup.controls['name'].touched && !receiptFormGroup.controls['name'].valid">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
              <div class="form-control-feedback" *ngIf="triggerFieldErrors.name">
                {{triggerFieldErrors.name.text}}
              </div>
            </div>
          </div>

          <div class="form-group row"
               [ngClass]="{ 'has-danger': receiptFormGroup.controls['event'].touched && !receiptFormGroup.controls['event'].valid }">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label required-field-label">
                {{'COMMON_TABLE_HEADER_EVENT'|translate}}
                <i class="icomoon icomoon-info info-icon" popover="{{'TRIGGER_RECEIPT_TRIGGER_EVENT_HINT' | translate}}"
                   triggers="mouseenter:mouseleave" container="body" placement="right">
                </i>
              </label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  [(ngModel)]="receiptTriggerEditModel.event"
                  [data]="events"
                  [settings]="singleLocalDropdownSettings"
                  (ngModelChange)="onNotificationEventChanged(receiptTriggerEditModel.event)"
                  formControlName="event">
                </angular2-multiselect>
                <div class="form-control-feedback"
                     *ngIf="receiptFormGroup.controls['event'].touched && !receiptFormGroup.controls['event'].valid">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row"
               [ngClass]="{ 'has-danger': receiptFormGroup.controls['receiptTemplate'].touched && !receiptFormGroup.controls['receiptTemplate'].valid }">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label required-field-label">
                {{'TRIGGER_RECEIPT_TEMPLATE'|translate}}
                <i class="icomoon icomoon-info info-icon"
                   popover="{{'TRIGGER_RECEIPT_TRIGGER_TEMPLATE_HINT' | translate}}"
                   triggers="mouseenter:mouseleave" container="body" placement="right">
                </i>
              </label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  [(ngModel)]="receiptTriggerEditModel.receiptTemplate"
                  [data]="receiptTemplates"
                  [settings]="singleRemoteDropdownSettings"
                  (onRemoteSearch)="loadReceiptTemplates($event.target.value)"
                  formControlName="receiptTemplate">
                </angular2-multiselect>
                <div class="form-control-feedback"
                     *ngIf="receiptFormGroup.controls['receiptTemplate'].touched && !receiptFormGroup.controls['receiptTemplate'].valid && receiptFormGroup.controls['receiptTemplate'].errors['required']">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
                <div class="form-control-feedback"
                     *ngIf="receiptFormGroup.controls['receiptTemplate'].touched && !receiptFormGroup.controls['receiptTemplate'].valid && receiptFormGroup.controls['receiptTemplate'].errors['hasDisabledItem']">
                  {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row"
               [ngClass]="{ 'has-danger': receiptFormGroup.controls['fileNamePattern'].touched && !receiptFormGroup.controls['fileNamePattern'].valid }">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label required-field-label">
                {{'TRIGGER_FILE_NAME_PATTERN'|translate}}
                <i class="icomoon icomoon-info info-icon"
                   popover="{{'TRIGGER_RECEIPT_TRIGGER_FILENAME_PATTERN_HINT' | translate}}"
                   triggers="mouseenter:mouseleave" container="body" placement="right">
                </i>
              </label>
            </div>
            <div class="col-md-8">
              <app-material-tag-input
                formControlName="fileNamePattern"
                [(ngModel)]="receiptTriggerEditModel.fileNamePattern"
                [placeholderStringKey]="'TRIGGER_FILE_NAME_PATTERN'"
                [autocompleteItems]="TaskTriggerFields.availableItems"
                [separatorCharacter]="TaskTriggerFields.separatorCharacter"
                [separatorKeyCodes]="TaskTriggerFields.separatorKeyCodes"
                [shouldGenerateExample]="false">
              </app-material-tag-input>
              <div class="form-control-feedback"
                   *ngIf="receiptFormGroup.controls['fileNamePattern'].touched && !receiptFormGroup.controls['fileNamePattern'].valid && receiptFormGroup.controls['fileNamePattern'].errors['required']">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
          </div>

        </div><!-- /.modal-body -->

        <!-- Message Trigger-->
        <div [formGroup]="messageFormGroup" *ngIf="triggerType === 'MESSAGE'">

          <div class="form-group row"
               [ngClass]="{ 'has-danger': (messageFormGroup.controls['name'].touched && !messageFormGroup.controls['name'].valid) || triggerFieldErrors.name}">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label required-field-label">
                {{'COMMON_NAME'|translate}}
              </label>
            </div>
            <div class="col-md-8">
              <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
                     [(ngModel)]="messageTriggerEditModel.name" formControlName="name"
                     (ngModelChange)="removeFieldError(triggerFieldErrors.name)">
              <div class="form-control-feedback"
                   *ngIf="messageFormGroup.controls['name'].touched && !messageFormGroup.controls['name'].valid">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
              <div class="form-control-feedback" *ngIf="triggerFieldErrors.name">
                {{triggerFieldErrors.name.text}}
              </div>
            </div>
          </div>

          <div class="form-group row"
               [ngClass]="{ 'has-danger': messageFormGroup.controls['event'].touched && !messageFormGroup.controls['event'].valid }">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label required-field-label">
                {{'COMMON_TABLE_HEADER_EVENT'|translate}}
                <i class="icomoon icomoon-info info-icon" popover="{{'TRIGGER_MESSAGE_TRIGGER_EVENT_HINT' | translate}}"
                   triggers="mouseenter:mouseleave" container="body" placement="right">
                </i>
              </label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  [(ngModel)]="messageTriggerEditModel.event"
                  [data]="events"
                  [settings]="singleLocalDropdownSettings"
                  (ngModelChange)="onNotificationEventChanged(messageTriggerEditModel.event)"
                  formControlName="event">
                </angular2-multiselect>
                <div class="form-control-feedback"
                     *ngIf="messageFormGroup.controls['event'].touched && !messageFormGroup.controls['event'].valid">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row"
               [ngClass]="{ 'has-danger': messageFormGroup.controls['messageTemplate'].touched && !messageFormGroup.controls['messageTemplate'].valid }">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label required-field-label">
                {{'TRIGGER_MESSAGE_TEMPLATE'|translate}}
                <i class="icomoon icomoon-info info-icon"
                   popover="{{'TRIGGER_MESSAGE_TRIGGER_TEMPLATE_HINT' | translate}}"
                   triggers="mouseenter:mouseleave" container="body" placement="right">
                </i>
              </label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  [(ngModel)]="messageTriggerEditModel.messageTemplate"
                  [data]="messageTemplates"
                  [settings]="singleRemoteDropdownSettings"
                  (onRemoteSearch)="loadMessageTemplates($event.target.value)"
                  formControlName="messageTemplate">
                </angular2-multiselect>
                <div class="form-control-feedback"
                     *ngIf="messageFormGroup.controls['messageTemplate'].touched && !messageFormGroup.controls['messageTemplate'].valid&& messageFormGroup.controls['messageTemplate'].errors['required']">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
                <div class="form-control-feedback"
                     *ngIf="messageFormGroup.controls['messageTemplate'].touched && !messageFormGroup.controls['messageTemplate'].valid&& messageFormGroup.controls['messageTemplate'].errors['hasDisabledItem']">
                  {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
                </div>
              </div>
            </div>
          </div>

          <h6 class="mb-1 mt-1">{{'TRIGGER_CREATE_RECIPIENTS' | translate}}</h6>

          <div class="form-group row">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label">{{'TRIGGER_SEND_TO_EVERYONE'|translate}}
                <i class="icomoon icomoon-info info-icon"
                   popover="{{'TRIGGER_MESSAGE_TRIGGER_SEND_TO_EVERYONE_HINT' | translate}}"
                   triggers="mouseenter:mouseleave" container="body" placement="right">
                </i>
              </label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <app-animated-checkbox
                  [(selected)]="messageTriggerEditModel.sendToEveryone">
                </app-animated-checkbox>
              </div>
            </div>
          </div>

          <div class="form-group row" *ngIf="!messageTriggerEditModel.sendToEveryone && scope === 'TASK'">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label">
                {{'TRIGGER_MESSAGE_SEND_TO_CREATOR'|translate}}
              </label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <app-animated-checkbox
                  [(selected)]="messageTriggerEditModel.sendToCreator">
                </app-animated-checkbox>
              </div>
            </div>
          </div>

          <div class="form-group row" *ngIf="!messageTriggerEditModel.sendToEveryone && scope === 'TASK'">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label">
                {{'TRIGGER_MESSAGE_SEND_TO_PROCESS_CREATOR'|translate}}
              </label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <app-animated-checkbox
                  [(selected)]="messageTriggerEditModel.sendToProcessCreator">
                </app-animated-checkbox>
              </div>
            </div>
          </div>

          <div class="form-group row" *ngIf="!messageTriggerEditModel.sendToEveryone && scope === 'TASK'">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label">
                {{'TRIGGER_MESSAGE_SEND_TO_ASSIGNEE'|translate}}
              </label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <app-animated-checkbox
                  [(selected)]="messageTriggerEditModel.sendToAssignee">
                </app-animated-checkbox>
              </div>
            </div>
          </div>

          <div class="form-group row" *ngIf="!messageTriggerEditModel.sendToEveryone && scope === 'TASK'">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label">
                {{'TRIGGER_MESSAGE_SEND_TO_ASSIGNEE_GROUP'|translate}}
              </label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <app-animated-checkbox
                  [(selected)]="messageTriggerEditModel.sendToAssigneeGroup">
                </app-animated-checkbox>
              </div>
            </div>
          </div>

          <div class="form-group row"
               *ngIf="!messageTriggerEditModel.sendToEveryone"
               [ngClass]="{ 'has-danger': messageFormGroup.controls['users'].touched && !messageFormGroup.controls['users'].valid }">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label">{{'TRIGGER_USERS'|translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  [(ngModel)]="messageTriggerEditModel.users"
                  [data]="users"
                  [settings]="multiRemoteDropdownSettings"
                  (onRemoteSearch)="loadUsers($event.target.value)"
                  formControlName="users">
                </angular2-multiselect>
                <div class="form-control-feedback"
                     *ngIf="messageFormGroup.controls['users'].touched && !messageFormGroup.controls['users'].valid && messageFormGroup.controls['users'].errors['hasDisabledItem']">
                  {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row"
               *ngIf="!messageTriggerEditModel.sendToEveryone"
               [ngClass]="{ 'has-danger': messageFormGroup.controls['userGroups'].touched && !messageFormGroup.controls['userGroups'].valid }">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label">{{'TRIGGER_USER_GROUPS'|translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  [(ngModel)]="messageTriggerEditModel.userGroups"
                  [data]="userGroups"
                  [settings]="multiRemoteDropdownSettings"
                  (onRemoteSearch)="loadUserGroups($event.target.value)"
                  formControlName="userGroups">
                </angular2-multiselect>
                <div class="form-control-feedback"
                     *ngIf="messageFormGroup.controls['userGroups'].touched && !messageFormGroup.controls['userGroups'].valid && messageFormGroup.controls['userGroups'].errors['hasDisabledItem']">
                  {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row"
               *ngIf="!messageTriggerEditModel.sendToEveryone"
               [ngClass]="{ 'has-danger': messageFormGroup.controls['mobileApplications'].touched && !messageFormGroup.controls['mobileApplications'].valid }">
            <div class="col-md-4">
              <label
                class="col-form-label mw-100 form-control-label">{{'TRIGGER_MOBILE_APPLICATIONS'|translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  [(ngModel)]="messageTriggerEditModel.mobileApplications"
                  [data]="mobileApplications"
                  [settings]="multiRemoteDropdownSettings"
                  (onRemoteSearch)="loadMobileApplications($event.target.value)"
                  formControlName="mobileApplications">
                </angular2-multiselect>
                <div class="form-control-feedback"
                     *ngIf="messageFormGroup.controls['mobileApplications'].touched && !messageFormGroup.controls['mobileApplications'].valid && messageFormGroup.controls['mobileApplications'].errors['hasDisabledItem']">
                  {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row" *ngIf="scope !== 'TASK'"
               [ngClass]="{ 'has-danger': messageFormGroup.controls['relatedPersonTypes'].touched && !messageFormGroup.controls['relatedPersonTypes'].valid }">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label">
                {{'TRIGGER_RELATED_PERSON_TYPES'|translate}}
              </label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  [(ngModel)]="messageTriggerEditModel.relatedPersonTypes"
                  [data]="relatedPersonTypes"
                  [settings]="multiLocalDropdownSettings"
                  formControlName="relatedPersonTypes">
                </angular2-multiselect>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-md-4">
              <label
                class="col-form-label mw-100 form-control-label">{{'TRIGGER_IGNORED_WITH_EMPTY_RECIPIENTS'|translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <app-animated-checkbox
                  [(selected)]="messageTriggerEditModel.ignoredWithEmptyRecipients">
                </app-animated-checkbox>
              </div>
            </div>
          </div>

          <div class="form-group row" *ngIf="scope === 'TASK'"
               [ngClass]="{ 'has-danger': messageFormGroup.controls['userFormFields'].touched && !messageFormGroup.controls['userFormFields'].valid }">
            <div class="col-md-4">
              <label
                class="col-form-label mw-100 form-control-label">{{'TRIGGER_USER_FORM_FIELDS'|translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  [(ngModel)]="messageTriggerEditModel.userFormFields"
                  [data]="messageUserFormFields"
                  [settings]="multiRemoteDropdownSettings"
                  (onRemoteSearch)="searchMessageUserFormFields($event.target.value)"
                  formControlName="userFormFields">
                </angular2-multiselect>
                <div class="form-control-feedback"
                     *ngIf="messageFormGroup.controls['userFormFields'].touched && !messageFormGroup.controls['userFormFields'].valid && messageFormGroup.controls['emailFormFields'].errors['hasDisabledItem']">
                  {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
                </div>
              </div>
            </div>
          </div>

        </div><!-- /.modal-body -->

        <!-- Email Trigger-->
        <div [formGroup]="emailFormGroup" *ngIf="triggerType === 'EMAIL'">

          <div class="form-group row"
               [ngClass]="{ 'has-danger': (emailFormGroup.controls['name'].touched && !emailFormGroup.controls['name'].valid) || triggerFieldErrors.name}">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label required-field-label">
                {{'COMMON_NAME'|translate}}
              </label>
            </div>
            <div class="col-md-8">
              <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
                     [(ngModel)]="emailTriggerEditModel.name" formControlName="name"
                     (ngModelChange)="removeFieldError(triggerFieldErrors.name)">
              <div class="form-control-feedback"
                   *ngIf="emailFormGroup.controls['name'].touched && !emailFormGroup.controls['name'].valid">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
              <div class="form-control-feedback" *ngIf="triggerFieldErrors.name">
                {{triggerFieldErrors.name.text}}
              </div>
            </div>
          </div>

          <div class="form-group row"
               [ngClass]="{ 'has-danger': emailFormGroup.controls['event'].touched && !emailFormGroup.controls['event'].valid }">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label required-field-label">
                {{'COMMON_TABLE_HEADER_EVENT'|translate}}
                <i class="icomoon icomoon-info info-icon" popover="{{'TRIGGER_EMAIL_TRIGGER_EVENT_HINT' | translate}}"
                   triggers="mouseenter:mouseleave" container="body" placement="right">
                </i>
              </label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  [(ngModel)]="emailTriggerEditModel.event"
                  [data]="events"
                  [settings]="singleLocalDropdownSettings"
                  (ngModelChange)="onNotificationEventChanged(emailTriggerEditModel.event)"
                  formControlName="event">
                </angular2-multiselect>
                <div class="form-control-feedback"
                     *ngIf="emailFormGroup.controls['event'].touched && !emailFormGroup.controls['event'].valid">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row"
               [ngClass]="{ 'has-danger': emailFormGroup.controls['emailTemplate'].touched && !emailFormGroup.controls['emailTemplate'].valid }">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label required-field-label">
                {{'TRIGGER_EMAIL_TEMPLATE'|translate}}
                <i class="icomoon icomoon-info info-icon"
                   popover="{{'TRIGGER_EMAIL_TRIGGER_TEMPLATE_HINT' | translate}}"
                   triggers="mouseenter:mouseleave" container="body" placement="right">
                </i>
              </label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  [(ngModel)]="emailTriggerEditModel.emailTemplate"
                  [data]="emailTemplates"
                  [settings]="singleRemoteDropdownSettings"
                  (onRemoteSearch)="loadEmailTemplates($event.target.value)"
                  formControlName="emailTemplate">
                </angular2-multiselect>
                <div class="form-control-feedback"
                     *ngIf="emailFormGroup.controls['emailTemplate'].touched && !emailFormGroup.controls['emailTemplate'].valid && emailFormGroup.controls['emailTemplate'].errors['required']">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
                <div class="form-control-feedback"
                     *ngIf="emailFormGroup.controls['emailTemplate'].touched && !emailFormGroup.controls['emailTemplate'].valid && emailFormGroup.controls['emailTemplate'].errors['hasDisabledItem']">
                  {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
                </div>
              </div>
            </div>
          </div>

          <h6 class="mb-1 mt-1">{{'TRIGGER_CREATE_RECIPIENTS' | translate}}</h6>

          <div class="form-group row">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label">{{'TRIGGER_SEND_TO_EVERYONE'|translate}}
                <i class="icomoon icomoon-info info-icon"
                   popover="{{'TRIGGER_EMAIL_TRIGGER_SEND_TO_EVERYONE_HINT' | translate}}"
                   triggers="mouseenter:mouseleave" container="body" placement="right">
                </i>
              </label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <app-animated-checkbox
                  [(selected)]="emailTriggerEditModel.sendToEveryone">
                </app-animated-checkbox>
              </div>
            </div>
          </div>

          <div class="form-group row" *ngIf="!emailTriggerEditModel.sendToEveryone && scope === 'TASK'">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label">
                {{'TRIGGER_MESSAGE_SEND_TO_CREATOR'|translate}}
              </label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <app-animated-checkbox
                  [(selected)]="emailTriggerEditModel.sendToCreator">
                </app-animated-checkbox>
              </div>
            </div>
          </div>

          <div class="form-group row" *ngIf="!emailTriggerEditModel.sendToEveryone && scope === 'TASK'">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label">
                {{'TRIGGER_MESSAGE_SEND_TO_PROCESS_CREATOR'|translate}}
              </label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <app-animated-checkbox
                  [(selected)]="emailTriggerEditModel.sendToProcessCreator">
                </app-animated-checkbox>
              </div>
            </div>
          </div>

          <div class="form-group row" *ngIf="!emailTriggerEditModel.sendToEveryone && scope === 'TASK'">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label">
                {{'TRIGGER_MESSAGE_SEND_TO_ASSIGNEE'|translate}}
              </label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <app-animated-checkbox
                  [(selected)]="emailTriggerEditModel.sendToAssignee">
                </app-animated-checkbox>
              </div>
            </div>
          </div>

          <div class="form-group row" *ngIf="!emailTriggerEditModel.sendToEveryone && scope === 'TASK'">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label">
                {{'TRIGGER_MESSAGE_SEND_TO_ASSIGNEE_GROUP'|translate}}
              </label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <app-animated-checkbox
                  [(selected)]="emailTriggerEditModel.sendToAssigneeGroup">
                </app-animated-checkbox>
              </div>
            </div>
          </div>

          <div class="form-group row"
               *ngIf="!emailTriggerEditModel.sendToEveryone"
               [ngClass]="{ 'has-danger': emailFormGroup.controls['users'].touched && !emailFormGroup.controls['users'].valid }">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label">{{'TRIGGER_USERS'|translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  [(ngModel)]="emailTriggerEditModel.users"
                  [data]="users"
                  [settings]="multiRemoteDropdownSettings"
                  (onRemoteSearch)="loadUsers($event.target.value)"
                  formControlName="users">
                </angular2-multiselect>
                <div class="form-control-feedback"
                     *ngIf="emailFormGroup.controls['users'].touched && !emailFormGroup.controls['users'].valid && emailFormGroup.controls['users'].errors['hasDisabledItem']">
                  {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row"
               *ngIf="!emailTriggerEditModel.sendToEveryone"
               [ngClass]="{ 'has-danger': emailFormGroup.controls['userGroups'].touched && !emailFormGroup.controls['userGroups'].valid }">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label">{{'TRIGGER_USER_GROUPS'|translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  [(ngModel)]="emailTriggerEditModel.userGroups"
                  [data]="userGroups"
                  [settings]="multiRemoteDropdownSettings"
                  (onRemoteSearch)="loadUserGroups($event.target.value)"
                  formControlName="userGroups">
                </angular2-multiselect>
                <div class="form-control-feedback"
                     *ngIf="emailFormGroup.controls['userGroups'].touched && !emailFormGroup.controls['userGroups'].valid && emailFormGroup.controls['userGroups'].errors['hasDisabledItem']">
                  {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row"
               [ngClass]="{ 'has-danger': emailFormGroup.controls['emailAddresses'].touched && !emailFormGroup.controls['emailAddresses'].valid}">
            <div class="col-md-4">
              <label
                class="col-form-label mw-100 form-control-label">{{'CUSTOMER_RECORD_EMAIL_ADDRESSES'|translate}}</label>
            </div>
            <div class="col-md-8">
              <mat-chip-list #chipList aria-label="Email selection" class="tag-input-chip-list">
                <mat-chip *ngFor="let emailAddress of emailTriggerEditModel.emailAddresses" [selectable]="selectable"
                          [removable]="removable" (removed)="remove(emailAddress)" disableRipple>
                  {{emailAddress}}
                  <c-icon *ngIf="removable" [name]="'remove'" style="width: 10px" matChipRemove></c-icon>
                </mat-chip>
                <input placeholder="{{'CUSTOMER_RECORD_EMAIL_ADDRESSES' | translate}}"
                       [ngClass]="emailTriggerEditModel.emailAddresses > 0 ? 'placeholder-margin-top' : 'placeholder-margin-center'"
                       class="tag-input border-0"
                       [matChipInputFor]="chipList"
                       [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                       [matChipInputAddOnBlur]="addOnBlur"
                       (matChipInputTokenEnd)="add($event)"
                       formControlName="emailAddresses">
              </mat-chip-list>
            </div>
            <div class="form-control-feedback"
                 *ngIf="emailFormGroup.controls['emailAddresses'].touched && !emailFormGroup.controls['emailAddresses'].valid">
              {{'COMMON_VALIDATION_MESSAGE_EMAIL' | translate}}
            </div>
          </div>

          <div class="form-group row" *ngIf="scope !== 'TASK'"
               [ngClass]="{ 'has-danger': emailFormGroup.controls['relatedPersonTypes'].touched && !emailFormGroup.controls['relatedPersonTypes'].valid }">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label">{{'TRIGGER_RELATED_PERSON_TYPES'|translate}}
                <i *ngIf="scope === 'TASK'"
                   class="icomoon icomoon-info info-icon"
                   popover="{{'TRIGGER_EMAIL_TRIGGER_OTHER_RECIPIENTS_HINT' | translate}}"
                   triggers="mouseenter:mouseleave" container="body" placement="right">
                </i>
              </label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  [(ngModel)]="emailTriggerEditModel.relatedPersonTypes"
                  [data]="relatedPersonTypes"
                  [settings]="multiLocalDropdownSettings"
                  formControlName="relatedPersonTypes">
                </angular2-multiselect>
              </div>
            </div>
          </div>

          <div class="form-group row" *ngIf="scope === 'TASK'"
               [ngClass]="{ 'has-danger': emailFormGroup.controls['emailFormFields'].touched && !emailFormGroup.controls['emailFormFields'].valid }">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label">{{'TRIGGER_EMAIL_FORM_FIELDS'|translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  [(ngModel)]="emailTriggerEditModel.emailFormFields"
                  [data]="emailFormFields"
                  [settings]="multiRemoteDropdownSettings"
                  (onRemoteSearch)="searchEmailFormFields($event.target.value)"
                  formControlName="emailFormFields">
                </angular2-multiselect>
                <div class="form-control-feedback"
                     *ngIf="emailFormGroup.controls['emailFormFields'].touched && !emailFormGroup.controls['emailFormFields'].valid && emailFormGroup.controls['emailFormFields'].errors['hasDisabledItem']">
                  {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row" *ngIf="scope === 'TASK'">
            <div class="col-md-4">
              <label
                class="col-form-label mw-100 form-control-label">{{'TRIGGER_EMAIL_ADDRESS_TYPES'|translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  [(ngModel)]="emailTriggerEditModel.emailAddressTypes"
                  [data]="emailAddressTypes"
                  [settings]="multiLocalDropdownSettings"
                  formControlName="emailAddressTypes">
                </angular2-multiselect>
              </div>
            </div>
          </div>

          <div class="form-group row" *ngIf="scope === 'TASK'"
               [ngClass]="{ 'has-danger': emailFormGroup.controls['userFormFields'].touched && !emailFormGroup.controls['userFormFields'].valid }">
            <div class="col-md-4">
              <label
                class="col-form-label mw-100 form-control-label">{{'TRIGGER_USER_FORM_FIELDS'|translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  [(ngModel)]="emailTriggerEditModel.userFormFields"
                  [data]="emailUserFormFields"
                  [settings]="multiRemoteDropdownSettings"
                  (onRemoteSearch)="searchEmailUserFormFields($event.target.value)"
                  formControlName="userFormFields">
                </angular2-multiselect>
                <div class="form-control-feedback"
                     *ngIf="emailFormGroup.controls['userFormFields'].touched && !emailFormGroup.controls['userFormFields'].valid && emailFormGroup.controls['emailFormFields'].errors['hasDisabledItem']">
                  {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
                </div>
              </div>
            </div>
          </div>

          <div formGroupName="customerFormFieldEmailTypes" class="row">
            <div class="col-md-4">
              <label
                class="col-form-label mw-100 form-control-label">{{'TRIGGER_CUSTOMER_FORM_FIELDS'|translate}}</label>
            </div>
            <div formArrayName="values" class="col-md-8">
                <ng-container
                  *ngFor="let customerFormField of emailTriggerEditModel.customerFormFieldEmailTypes; let i = index">
                  <div [formGroupName]="i">
                    <div class="form-group row">
                      <div class="col-md-4">
                        <label
                          class="col-form-label mw-100 form-control-label">{{'TRIGGER_CUSTOMER_FORM_FIELDS'|translate}}</label>
                      </div>
                      <div class="col-md-6">
                        <angular2-multiselect [data]="customerFormField.customerFormFields" [settings]="singleLocalDropdownSettings"
                                              class="form-control"
                                              [(ngModel)]="customerFormField._formField"
                                              (onRemoteSearch)="searchCustomerFormFields(i, $event.target.value)"
                                              formControlName="customerFormField"
                        >
                        </angular2-multiselect>
                        <div class="form-control-feedback"
                             *ngIf="isCustomerFieldControlInvalid(i, 'customerFormField')">
                          {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
                        </div>
                      </div>
                    </div>
                  <div class="form-group row">
                    <div class="col-md-4">
                      <label
                        class="col-form-label mw-100 form-control-label">{{'TRIGGER_EMAIL_ADDRESS_TYPES'|translate}}</label>
                    </div>

                    <div class="col-md-6 d-flex align-items-center">
                      <div class="w-100">
                        <angular2-multiselect [data]="emailAddressTypes" [settings]="multiLocalDropdownSettings"
                                              class="form-control"
                                              [(ngModel)]="customerFormField.emailAddressTypes"
                                              formControlName="emailAddressTypes">
                        </angular2-multiselect>
                        <div class="form-control-feedback"
                             *ngIf="isCustomerFieldControlInvalid(i, 'emailAddressTypes')">
                          {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
                        </div>
                      </div>
                      <div class="cursor-pointer ml-1 font-xl" (click)="removeCustomerFormFieldEmailTypes(i)"
                           title="{{'COMMON_BUTTON_DELETE' | translate}}">
                        <i class="icomoon icomoon-trash">
                        </i>
                      </div>
                  </div>
                  </div>
                  </div>
                </ng-container>

              <div class="d-flex justify-content-end">
                <button type="button" (click)="addCustomerFormFieldEmailTypes()" class="btn btn-outline-primary">
                  {{'COMMON_ADD' | translate}}
                </button>
              </div>
            </div>
          </div>
          <div class="form-group row" *ngIf="false">
            <div class="col-md-4">
              <label
                class="col-form-label mw-100 form-control-label">{{'TRIGGER_IGNORED_WITH_EMPTY_RECIPIENTS'|translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <app-animated-checkbox
                  [(selected)]="emailTriggerEditModel.ignoredWithEmptyRecipients">
                </app-animated-checkbox>
              </div>
            </div>
          </div>

          <h6 class="mb-1 mt-1">{{'FORM_CREATE_ATTACHMENT' | translate}}</h6>

          <div class="form-group row" *ngIf="scope === 'TASK'"
               [ngClass]="{ 'has-danger': emailFormGroup.controls['pdfTriggers'].touched && !emailFormGroup.controls['pdfTriggers'].valid }">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label">{{'TRIGGER_PDF_TRIGGERS'|translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  [(ngModel)]="emailTriggerEditModel.pdfTriggers"
                  [data]="pdfTriggers"
                  [settings]="multiRemoteDropdownSettings"
                  (onRemoteSearch)="loadPdfTriggers($event.target.value)"
                  formControlName="pdfTriggers">
                </angular2-multiselect>
                <div class="form-control-feedback"
                     *ngIf="emailFormGroup.controls['pdfTriggers'].touched && !emailFormGroup.controls['pdfTriggers'].valid && emailFormGroup.controls['pdfTriggers'].errors['hasDisabledItem']">
                  {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row" *ngIf="scope === 'TRANSPORT'"
               [ngClass]="{ 'has-danger': emailFormGroup.controls['transportDocumentTypes'].touched && !emailFormGroup.controls['transportDocumentTypes'].valid }">
            <div class="col-md-4">
              <label
                class="col-form-label mw-100 form-control-label">{{'TRIGGER_TRANSPORT_DOCUMENT_TYPE'|translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  [(ngModel)]="emailTriggerEditModel.transportDocumentTypes"
                  [data]="transportDocumentTypes"
                  [settings]="multiLocalDropdownSettings"
                  formControlName="transportDocumentTypes">
                </angular2-multiselect>
              </div>
            </div>
          </div>

          <div class="form-group row" *ngIf="rightModel.documentFileRead.hasRight()"
               [ngClass]="{ 'has-danger': emailFormGroup.controls['fileDocuments'].touched && !emailFormGroup.controls['fileDocuments'].valid }">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label">{{'TRIGGER_FILE_DOCUMENTS'|translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  [(ngModel)]="emailTriggerEditModel.fileDocuments"
                  [data]="fileDocuments"
                  [settings]="multiRemoteDropdownSettings"
                  (onRemoteSearch)="loadFileDocuments($event.target.value)"
                  formControlName="fileDocuments">
                </angular2-multiselect>
                <div class="form-control-feedback"
                     *ngIf="emailFormGroup.controls['fileDocuments'].touched && !emailFormGroup.controls['fileDocuments'].valid && emailFormGroup.controls['fileDocuments'].errors['hasDisabledItem']">
                  {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row" *ngIf="rightModel.documentGroupRead.hasRight()"
               [ngClass]="{ 'has-danger': emailFormGroup.controls['documentGroups'].touched && !emailFormGroup.controls['documentGroups'].valid }">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label">{{'TRIGGER_DOCUMENT_GROUPS'|translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  [(ngModel)]="emailTriggerEditModel.documentGroups"
                  [data]="documentGroups"
                  [settings]="multiRemoteDropdownSettings"
                  (onRemoteSearch)="loadDocumentGroups($event.target.value)"
                  formControlName="documentGroups">
                </angular2-multiselect>
                <div class="form-control-feedback"
                     *ngIf="emailFormGroup.controls['documentGroups'].touched && !emailFormGroup.controls['documentGroups'].valid && emailFormGroup.controls['documentGroups'].errors['hasDisabledItem']">
                  {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row" *ngIf="scope === 'TASK'">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label">{{'TRIGGER_ATTACH_INVOICES'|translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <app-animated-checkbox
                  [(selected)]="emailTriggerEditModel.attachInvoices">
                </app-animated-checkbox>
              </div>
            </div>
          </div>

          <div class="form-group row" *ngIf="scope === 'TASK'">
            <div class="col-md-4">
              <label
                class="col-form-label mw-100 form-control-label">{{'TRIGGER_ATTACH_TASK_ATTACHMENTS'|translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <app-animated-checkbox
                  [(selected)]="emailTriggerEditModel.attachTaskAttachments">
                </app-animated-checkbox>
              </div>
            </div>
          </div>

          <div class="form-group row" *ngIf="scope === 'TASK'"
               [ngClass]="{ 'has-danger': emailFormGroup.controls['documentFormFields'].touched && !emailFormGroup.controls['documentFormFields'].valid }">
            <div class="col-md-4">
              <label
                class="col-form-label mw-100 form-control-label">{{'TRIGGER_DOCUMENT_FORM_FIELDS'|translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  [(ngModel)]="emailTriggerEditModel.documentFormFields"
                  [data]="documentFormFields"
                  [settings]="multiRemoteDropdownSettings"
                  (onRemoteSearch)="searchDocumentFormFields($event.target.value)"
                  formControlName="documentFormFields">
                </angular2-multiselect>
                <div class="form-control-feedback"
                     *ngIf="emailFormGroup.controls['documentFormFields'].touched && !emailFormGroup.controls['documentFormFields'].valid && emailFormGroup.controls['emailFormFields'].errors['hasDisabledItem']">
                  {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
                </div>
              </div>
            </div>
          </div>

        </div><!-- /.modal-body -->

        <!-- Clone Trigger-->
        <div [formGroup]="cloneFormGroup" *ngIf="triggerType === 'CLONE'">

          <div class="form-group row"
               [ngClass]="{ 'has-danger': (cloneFormGroup.controls['name'].touched && !cloneFormGroup.controls['name'].valid) || triggerFieldErrors.name}">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label required-field-label">
                {{'COMMON_NAME'|translate}}
              </label>
            </div>
            <div class="col-md-8">
              <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
                     [(ngModel)]="cloneTriggerEditModel.name" formControlName="name"
                     (ngModelChange)="removeFieldError(triggerFieldErrors.name)">
              <div class="form-control-feedback"
                   *ngIf="cloneFormGroup.controls['name'].touched && !cloneFormGroup.controls['name'].valid">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
              <div class="form-control-feedback" *ngIf="triggerFieldErrors.name">
                {{triggerFieldErrors.name.text}}
              </div>
            </div>
          </div>

          <div class="form-group row"
               [ngClass]="{ 'has-danger': cloneFormGroup.controls['event'].touched && !cloneFormGroup.controls['event'].valid }">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label required-field-label">
                {{'COMMON_TABLE_HEADER_EVENT'|translate}}
                <i class="icomoon icomoon-info info-icon" popover="{{'TRIGGER_CLONE_TRIGGER_EVENT_HINT' | translate}}"
                   triggers="mouseenter:mouseleave" container="body" placement="right">
                </i>
              </label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  [(ngModel)]="cloneTriggerEditModel.event"
                  [data]="events"
                  [settings]="singleLocalDropdownSettings"
                  (ngModelChange)="onNotificationEventChanged(cloneTriggerEditModel.event)"
                  formControlName="event">
                </angular2-multiselect>
                <div class="form-control-feedback"
                     *ngIf="cloneFormGroup.controls['event'].touched && !cloneFormGroup.controls['event'].valid">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row"
               [ngClass]="{ 'has-danger': cloneFormGroup.controls['defaultDeadlineAdditionalDays'].touched && !cloneFormGroup.controls['defaultDeadlineAdditionalDays'].valid }">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label required-field-label">
                {{'TRIGGER_CLONE_TRIGGER_OFFSET'|translate}}
                <i class="icomoon icomoon-info info-icon" popover="{{'TRIGGER_CLONE_TRIGGER_OFFSET_HINT' | translate}}"
                   triggers="mouseenter:mouseleave" container="body" placement="right">
                </i>
              </label>
            </div>
            <div class="col-md-8">
              <div class="d-flex align-items-center">
                <input type="text" class="flex-grow-1 form-control"
                       placeholder="{{'TRIGGER_CLONE_TRIGGER_OFFSET' | translate}}"
                       [(ngModel)]="cloneTriggerEditModel.defaultDeadlineAdditionalDays"
                       formControlName="defaultDeadlineAdditionalDays">
                <label class="form-control-label text-nowrap ml-1">{{'TRIGGER_CLONE_TRIGGER_DAY'|translate}}
                </label>
              </div>
              <div class="form-control-feedback"
                   *ngIf="cloneFormGroup.controls['defaultDeadlineAdditionalDays'].touched && !cloneFormGroup.controls['defaultDeadlineAdditionalDays'].valid && cloneFormGroup.controls['defaultDeadlineAdditionalDays'].errors['required']">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label">{{'TRIGGER_CLONE_TRIGGER_OPEN_IF_POSSIBLE'|translate}}
                <i class="icomoon icomoon-info info-icon"
                   popover="{{'TRIGGER_CLONE_TRIGGER_OPEN_IF_POSSIBLE_HINT' | translate}}"
                   triggers="mouseenter:mouseleave" container="body" placement="right">
                </i>
              </label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <app-animated-checkbox
                  [(selected)]="cloneTriggerEditModel.openIfPossible">
                </app-animated-checkbox>
              </div>
            </div>
          </div>

          <div class="form-group row"
               [ngClass]="{ 'has-danger': cloneFormGroup.controls['destinationTask'].touched && !cloneFormGroup.controls['destinationTask'].valid }">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label required-field-label">{{'TRIGGER_CLONE_DESTINATION_TASK'|translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  [(ngModel)]="cloneTriggerEditModel.destinationTask"
                  [data]="tasks"
                  [settings]="singleRemoteItemNameDropdownSettings"
                  (onRemoteSearch)="loadTasks($event.target.value)"
                  formControlName="destinationTask">
                </angular2-multiselect>
                <div class="form-control-feedback"
                     *ngIf="cloneFormGroup.controls['destinationTask'].touched && !cloneFormGroup.controls['destinationTask'].valid && cloneFormGroup.controls['destinationTask'].errors['hasDisabledItem']">
                  {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
                </div>
                <div class="form-control-feedback"
                     *ngIf="cloneFormGroup.controls['destinationTask'].touched && !cloneFormGroup.controls['destinationTask'].valid && cloneFormGroup.controls['destinationTask'].errors['required']">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row"
               [ngClass]="{ 'has-danger': cloneFormGroup.controls['cloneFields'].touched && !cloneFormGroup.controls['cloneFields'].valid }">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label required-field-label">
                {{'TRIGGER_CLONE_FIELDS'|translate}}
              </label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  [(ngModel)]="cloneTriggerEditModel.cloneFields"
                  [data]="bulkCloneFields"
                  [settings]="bulkFieldsDropdownSettings"
                  formControlName="cloneFields">
                </angular2-multiselect>
                <div class="form-control-feedback"
                     *ngIf="cloneFormGroup.controls['cloneFields'].touched && !cloneFormGroup.controls['cloneFields'].valid">
                  {{'TRIGGER_CLONE_FIELDS_NAME_REQUIRED' | translate}}
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row" *ngIf="cloneFormFieldsVisible()"
               [ngClass]="{ 'has-danger': cloneFormGroup.controls['cloneFormFields'].touched && !cloneFormGroup.controls['cloneFormFields'].valid }">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label required-field-label">
                {{'TRIGGER_CLONE_FORM_FIELDS'|translate}}
              </label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  #cloneFormFieldsInput
                  [(ngModel)]="cloneTriggerEditModel.cloneFormFields"
                  [data]="cloneFormFields"
                  [settings]="multiRemoteDropdownSettings"
                  (onRemoteSearch)="searchCloneFormFields($event.target.value)"
                  (onSelect)="onCloneFormFieldsChanged()"
                  (onDeSelect)="onCloneFormFieldsChanged()"
                  formControlName="cloneFormFields">
                </angular2-multiselect>
                <div class="form-control-feedback"
                     *ngIf="cloneFormGroup.controls['cloneFormFields'].touched && !cloneFormGroup.controls['cloneFormFields'].valid">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
              </div>
            </div>
          </div>

        </div><!-- /.modal-body -->

        <div *ngIf="scope === 'TASK'">
          <label class="task-defaults-title my-1">{{'TRIGGER_EDIT_TRANSITION_FORM_FIELD_RULES' | translate}}</label>
          <div *ngIf="model.rules.length === 0" class="d-flex justify-content-center">
            <label class="text-muted">{{'TRIGGER_EDIT_RULE_NO_RULES' | translate}}</label>
          </div>
          <ng-container *ngFor="let rule of model.rules; let i = index">
            <div *ngIf="!rule.editing" class="rule-container mt-half cursor-pointer"
                 [ngClass]="{'disabled-rule': rule.disabled}" (click)="editRule(i)">
              {{rule.name}}
            </div>
            <app-base-rule-edit *ngIf="rule.editing"
                                      [model]="rule"
                                      [operatorRequired]="i !== 0"
                                      [form]="_form"
                                      (deleteRule)="deleteRule(i)">
            </app-base-rule-edit>
          </ng-container>
          <hr>
          <app-base-rule-edit *ngIf="model.addRuleModel"
                                    [model]="model.addRuleModel"
                                    [operatorRequired]="model.rules.length !== 0"
                                    [create]="true"
                                    [form]="_form"
                                    (createSubmitted)="ruleCreateSubmitted($event)">
          </app-base-rule-edit>
          <div class="d-flex" *ngIf="!model.addRuleModel">
          <button type="button" class="btn btn-secondary-applion-green-gray col-6 mr-05"
                  (click)="newFormFieldRule()">{{'WORKFLOW_EDIT_TRANSITION_NEW_FORM_FIELD_RULE' | translate}}
          </button>
          <button type="button" class="btn btn-secondary-applion-green-gray col-6"
                  (click)="newTaskRule()">{{'WORKFLOW_EDIT_TRANSITION_NEW_TASK_RULE' | translate}}
          </button>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeTriggerEditDialog()">{{'COMMON_BUTTON_CANCEL' |
          translate}}
        </button>
        <button type="submit" (click)="createTrigger()" class="btn btn-primary">{{'COMMON_BUTTON_SAVE' |
          translate}}
        </button>
      </div>

    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
