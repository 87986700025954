<div class="container-vertical-padding-half">
  <div class="card">
    <div class="card-header">

      <!--{{headingDictionaryKey | translate}}-->
      {{'PROCESS_DETAIL_HISTORY' | translate}}

    </div>

    <div class="card-body">


      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header long">
            <app-table-sorter-no-op
              [text]="'TASK_RECORD_HISTORY_TABLE_HEADER_CHANGE_TYPE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header long">
            <app-table-field-sorter [orderField]="TaskRecord.LogOrderField.TASKRECORD_NAME"
                                    [orderType]="queryModel.getOrderType(TaskRecord.LogOrderField.TASKRECORD_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_RELATED_TASK_RECORD' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header long">
            <app-table-field-sorter [orderField]="TaskRecord.LogOrderField.LOG_TIME"
                                    [orderType]="queryModel.getOrderType(TaskRecord.LogOrderField.LOG_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'TASK_RECORD_HISTORY_TABLE_HEADER_CHANGE_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header long hidden-sm-down">
            <app-table-field-sorter [orderField]="TaskRecord.LogOrderField.USER_PROFILE_NAME"
                                    [orderType]="queryModel.getOrderType(TaskRecord.LogOrderField.USER_PROFILE_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'TASK_RECORD_HISTORY_TABLE_HEADER_USER_HUMAN_NAME' | translate"></app-table-field-sorter>
          </th>
          <th>
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>

        </thead>
        <tbody>
        <ng-container *ngFor="let taskRecordHistory of taskRecordHistoryList | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems };
                                                      let i = index">
          <tr class="table-row-selectable" [class.table-row-selected]="selectedIndex === i">
            <td>
              {{ taskRecordHistory.changeType | translate }}
              <i *ngIf="taskRecordHistory.message" class="icomoon icomoon-state-change-message cursor-pointer ml-1"
                 popover="{{taskRecordHistory.message}}" triggers="mouseenter:mouseleave"></i>
            </td>
            <td><a class="id-text cursor-pointer"
                   href="#" onclick="return false"
                   uiSref="Admin.TaskRecordDetail"
                   [uiParams]="{ taskId: taskRecordHistory.taskRecord!.taskId, taskRecordId: taskRecordHistory.taskRecord!.id }"
                   title="{{'COMMON_BUTTON_DETAILS' | translate}}">
              {{ taskRecordHistory.taskRecord!.name }}
            </a>
              <span class="table-text-subtitle">{{ taskRecordHistory.taskRecord!.externalId }}</span>
            </td>
            <td>{{ taskRecordHistory.changeTime | date:'short' }}</td>
            <td class="hidden-sm-down">{{ taskRecordHistory.userHumanName }}</td>
            <td class="w-table-actions-single">
              <i class="icomoon icomoon-pin-on-location icomoon-modify-table cursor-pointer"
                 *ngIf="taskRecordHistory.coordinate"
                 (click)="historyMapComponent.highlightMarker(taskRecordHistory)"></i>
            </td>
          </tr>
        </ng-container>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header long">
            <app-table-sorter-no-op
              [text]="'TASK_RECORD_HISTORY_TABLE_HEADER_CHANGE_TYPE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header long">
            <app-table-field-sorter [orderField]="TaskRecord.LogOrderField.TASKRECORD_NAME"
                                    [orderType]="queryModel.getOrderType(TaskRecord.LogOrderField.TASKRECORD_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_RELATED_TASK_RECORD' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header long">
            <app-table-field-sorter [orderField]="TaskRecord.LogOrderField.LOG_TIME"
                                    [orderType]="queryModel.getOrderType(TaskRecord.LogOrderField.LOG_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'TASK_RECORD_HISTORY_TABLE_HEADER_CHANGE_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header long hidden-sm-down">
            <app-table-field-sorter [orderField]="TaskRecord.LogOrderField.USER_PROFILE_NAME"
                                    [orderType]="queryModel.getOrderType(TaskRecord.LogOrderField.USER_PROFILE_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'TASK_RECORD_HISTORY_TABLE_HEADER_USER_HUMAN_NAME' | translate"></app-table-field-sorter>
          </th>
          <th>
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>


    </div><!-- /.card-body -->
  </div>
</div>
