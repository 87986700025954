/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ListQuery } from '../util/messages';
import { Map as ImmutableMap } from 'immutable';
import { StockItemType, UnitPrice } from './stock-item.service';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { ResourceQueryResult } from '../util/services';
import { StockItemUnitOfMeasure } from './stock-item-unit-of-measure';
/* eslint-enable */

export namespace StockRecord {

  export enum OrderField {
    ID,
    STOCK_ITEM_NAME,
    STOCK_ITEM_PRODUCT_CODE,
    STOCK_NAME,
    AMOUNT,
  }

  export class Keys {

    private static readonly ID = 'id';
    private static readonly STOCK_ITEM_NAME = 'stock_item_name';
    private static readonly STOCK_NAME = 'stock_name';
    private static readonly AMOUNT = 'amount';
    private static readonly STOCK_ITEM_PRODUCT_CODE = 'stock_item_product_code';

    private static readonly orderFieldKeyMap: ImmutableMap<StockRecord.OrderField, string> = ImmutableMap.of(
      StockRecord.OrderField.ID, Keys.ID,
      StockRecord.OrderField.STOCK_ITEM_NAME, Keys.STOCK_ITEM_NAME,
      StockRecord.OrderField.STOCK_NAME, Keys.STOCK_NAME,
      StockRecord.OrderField.STOCK_ITEM_PRODUCT_CODE, Keys.STOCK_ITEM_PRODUCT_CODE,
      StockRecord.OrderField.AMOUNT, Keys.AMOUNT,
    );

    public static toOrderFieldKey(field: StockRecord.OrderField): string {
      return Keys.orderFieldKeyMap.get(field)!;
    }

  }

}

export interface StockRecordQuery extends ListQuery.Paging, ListQuery.Order, ListQuery.QueryText, ListQuery.Fields {
  id?: string;
  amount_from?: string;
  amount_to?: string;
  stock_name?: string;
  stock_id?: number;
  stock_item_name?: string;
  stock_item_id?: number;
  stock_item_external_id?: string;
  stock_item_product_code?: string;
  stock_item_type?: string;
  stock_item_serial_code?: string;
}

export interface StockRecord {
  id: number;
  creation_time: string;
  update_time: string;
  stock_id: number;
  stock_item_id: number;
  amount?: number;
  lots?: StockRecordLot[];
}

export interface StockRecordLot {
  id: number;
  amount: number;
  intake_unit_net_price?: number;
  intake_date: string;
  shipping_number: string;
}

export interface StockRecordFacadeQuery extends ListQuery.Paging, ListQuery.Order, ListQuery.QueryText, ListQuery.Fields {
  stock_record_ids?: string;
  excluded_stock_record_ids?: string;
  stock_item_ids?: string;
  excluded_stock_item_ids?: string;
  stock_item_q?: string;
  stock_item_usable_for_form_field?: boolean;
  stock_item_name?: string;
  stock_item_type?: string;
  stock_item_external_id?: string;
  stock_item_product_code?: string;
  stock_item_category_ids?: string;
  recursive_stock_item_category_id?: number;
  enabled_stock_item_category_ids?: string;
  stock_item_serial_code?: string;
  stock_ids?: string;
  stock_type?: string;
  stock_name?: string;
  stock_owner_user_id?: string;
  stock_owner_user_name?: string;
  stock_item_disabled?: boolean;
  stock_disabled?: boolean;
  amount_from?: string;
  amount_to?: string;
  include_reservations?: boolean;
  include_lots?: boolean;
  customer_record_id?: number;
  stock_owner_contact_location_id?: number;
  stock_owner_customer_record_id?: number;
}

export interface StockRecordFacade {
  stock_record_id: number;
  stock: Stock;
  stock_item: StockItem;
  amount: number;
  intake_net_price_sum?: number;
  intake_net_price_avg?: number;
  lots?: StockRecordLot[];
}

interface Stock {
  stock_id: number;
  name: string;
  central: boolean;
  waste: boolean;
  disabled: boolean;
}

interface StockItem {
  stock_item_id: number;
  type: StockItemType;
  usable_for_form_field: boolean;
  external_id: string;
  serial_code?: string;
  product_code?: string;
  hun_vtsz_number?: string;
  name: string;
  description?: string;
  unit: string;
  unit_price: UnitPrice;
  disabled: boolean;
  reserved_amount?: number;
  picture?: StockItemPictureResource;
  category_chains?: StockRecordFacadeCategory[][];
  package_data?: StockItemUnitOfMeasure.StockItemUnitOfMeasure;
  measurements?: StockItemUnitOfMeasure.StockItemUnitOfMeasure[];
  weight_in_grams?: number;
}

export interface StockRecordFacadeCategory {
  id: number;
  name: string;
}

export interface StockItemPictureResource {
  content_type?: string;
  content_hash?: string;
}

@Injectable()
export class StockRecordService extends BaseHttpService {

  query(request: StockRecordQuery): Observable<ResourceQueryResult<StockRecord>> {
    return this.http.get<ResourceQueryResult<StockRecord>>(this.url, this.parseParams(request));
  }

  facadeQuery(request: StockRecordFacadeQuery): Observable<ResourceQueryResult<StockRecordFacade>> {
    return this.http.get<ResourceQueryResult<StockRecordFacade>>(this.url + `facade`, this.parseParams(request));
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/stock-records/');
  }

}
