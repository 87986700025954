<div class="card">
  <div class="card-header">
    {{'PROCESS_EDIT_STATE_HISTORY_HEADER' | translate}}
  </div>

  <div class="card-body">
    <table class="table table-striped table-bordered">
      <thead>
      <tr>
        <th class="table-sorter-header">
          <app-table-sorter-no-op [text]="'PROCESS_EDIT_STATE_CHANGE' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header">
          <app-table-sorter-no-op [text]="'PROCESS_EDIT_CHANGE_TIME' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header">
          <app-table-sorter-no-op [text]="'PROCESS_EDIT_USER' | translate"></app-table-sorter-no-op>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let changeLog of changeLogList | paginate: { id: pagingId,
                                                      itemsPerPage: changeLogQueryModel.itemsPerPage,
                                                      currentPage: changeLogQueryModel.currentPage,
                                                      totalItems: changeLogQueryModel.currentNumberOfItems }">
        <td class="responsive-table-column">{{getChangeLogTypeText(changeLog.type)}} {{changeLog.processTaskName ? ' - ' + changeLog.processTaskName : ''}}</td>
        <td class="responsive-table-column">{{changeLog.creationTime.toUtcIsoString() | date:'short'}}</td>
        <td class="responsive-table-column">{{getUserName(changeLog.userId)}}</td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <th class="table-sorter-header">
          <app-table-sorter-no-op [text]="'PROCESS_EDIT_STATE_CHANGE' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header">
          <app-table-sorter-no-op [text]="'PROCESS_EDIT_CHANGE_TIME' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header">
          <app-table-sorter-no-op [text]="'PROCESS_EDIT_USER' | translate"></app-table-sorter-no-op>
        </th>
      </tr>
      </tfoot>
    </table>
    <app-table-paging
      [id]="pagingId"
      [currentNumberOfItems]="changeLogQueryModel.currentNumberOfItems"
      [totalNumberOfItems]="changeLogQueryModel.totalNumberOfItems"
      [itemsPerPage]="changeLogQueryModel.currentNumberOfItems"
      (pageChange)="changeLogPageChanged($event)"
      (itemsPerPageChange)="changeLogItemsPerPageChanged($event)"
      [hideItemsPerPage]="true">
    </app-table-paging>
  </div> <!--./card-body-->
</div>
