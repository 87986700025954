<div class="card">

  <div class="card-header">
    {{headingKey | translate}}

    <div class="card-actions">
      <a class="btn-setting cursor-pointer" (click)="toggleSearch()"
         title="{{'COMMON_SHOW_SEARCH_HINT' | translate}}"><i
        class="icomoon icomoon-search"></i></a>
    </div><!-- /.card-actions -->
  </div>

  <div class="card-body border-bottom" *ngIf="showSearch">

    <div class="row form-group">
      <div class="col-md-4" *ngIf="type !== StockLogListType.STOCK">
        <label class="search-label">{{'STOCK_RECORD_LOG_STOCK_NAME' | translate}}</label>
        <angular2-multiselect class="form-control"
                              [data]="stockList"
                              [(ngModel)]="searchModel.stock"
                              (onRemoteSearch)="loadStocks($event.target.value)"
                              [settings]="dropdownSettings">
        </angular2-multiselect>
      </div>
      <div class="col-md-4">
        <label class="search-label">{{'STOCK_RECORD_LOG_EDITOR' | translate}}</label>
        <angular2-multiselect class="form-control"
                              [data]="userList"
                              [(ngModel)]="searchModel.editor"
                              (onRemoteSearch)="loadUsers($event.target.value)"
                              [settings]="dropdownSettings">
        </angular2-multiselect>
      </div>

      <div class="col-md-4">
        <label class="search-label">{{'STOCK_RECORD_LOG_TASK_RECORD' | translate}}</label>
        <angular2-multiselect class="form-control"
                              [data]="taskRecordList"
                              [(ngModel)]="searchModel.taskRecord"
                              (onRemoteSearch)="loadTaskRecords($event.target.value)"
                              [settings]="dropdownSettings">
        </angular2-multiselect>
      </div>

      <div class="col-md-4" *ngIf="type !== StockLogListType.STOCK_ITEM">
        <label class="search-label">{{'STOCK_RECORD_LOG_ITEM_NAME' | translate}}</label>
        <angular2-multiselect class="form-control"
                              [data]="stockItemList"
                              [(ngModel)]="searchModel.stockItem"
                              (onRemoteSearch)="loadStockItems($event.target.value)"
                              [settings]="dropdownSettings">
        </angular2-multiselect>
      </div>
      <div class="col-md-4">
        <label class="search-label">{{'STOCK_RECORD_LOG_SOURCE' | translate}}</label>
        <angular2-multiselect class="form-control"
                              [data]="sourceList"
                              [(ngModel)]="searchModel.source"
                              [settings]="sourceDropdownSettings">
        </angular2-multiselect>
      </div>
      <div class="col-md-4">
        <label class="search-label">{{'STOCK_RECORD_LOG_CREATION_TIME_FROM' | translate}}</label>
        <div class="input-group">
          <input type="text" class="form-control date"
                 placeholder="{{'STOCK_RECORD_LOG_CREATION_TIME_FROM' | translate}}"
                 [(ngModel)]="searchModel.creationTimeFrom" ngbDatepicker name="start_date" id="start_date"
                 #start_date="ngbDatepicker">
          <div class="input-group-addon-gray" (click)="start_date.toggle()">
            <i class="icomoon icomoon-calendar"></i>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <label class="search-label">{{'STOCK_RECORD_LOG_CREATION_TIME_TO' | translate}}</label>
        <div class="input-group">
          <input type="text" class="form-control date"
                 placeholder="{{'STOCK_RECORD_LOG_CREATION_TIME_TO' | translate}}"
                 [(ngModel)]="searchModel.creationTimeTo" ngbDatepicker name="end_date" #end_date="ngbDatepicker"
                 id="end_date">
          <div class="input-group-addon-gray" (click)="end_date.toggle()">
            <i class="icomoon icomoon-calendar"></i>
          </div>
        </div>
      </div>
    </div>
    <!--Button-->
    <div class="col-md-12 d-flex justify-content-md-end align-items-end px-0">
      <div class="btn-group" role="group">
        <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
          {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
        </button>
        <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
          {{'COMMON_BUTTON_SEARCH' | translate}}
        </button>
      </div>
    </div>
  </div> <!-- ./card-body end -->
  <div class="card-body">
    <table class="table table-striped table-bordered">
      <thead>
      <tr>
        <th class="table-sorter-header hidden-1300-down">
          <app-table-field-sorter [orderField]="StockRecordLog.OrderField.ID"
                                  [orderType]="queryModel.getOrderType(StockRecordLog.OrderField.ID)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header">
          <app-table-field-sorter [orderField]="StockRecordLog.OrderField.SOURCE"
                                  [orderType]="queryModel.getOrderType(StockRecordLog.OrderField.SOURCE)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'STOCK_RECORD_LOG_SOURCE' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header hidden-xs-down" *ngIf="type !== StockLogListType.STOCK">
          <app-table-field-sorter [orderField]="StockRecordLog.OrderField.STOCK_NAME"
                                  [orderType]="queryModel.getOrderType(StockRecordLog.OrderField.STOCK_NAME)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'STOCK_RECORD_LOG_STOCK_NAME' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header" *ngIf="type !== StockLogListType.STOCK_ITEM">
          <app-table-field-sorter [orderField]="StockRecordLog.OrderField.STOCK_ITEM_NAME"
                                  [orderType]="queryModel.getOrderType(StockRecordLog.OrderField.STOCK_ITEM_NAME)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'STOCK_RECORD_LOG_ITEM_NAME' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header hidden-sm-down" *ngIf="type !== StockLogListType.STOCK_ITEM">
          <app-table-field-sorter [orderField]="StockRecordLog.OrderField.STOCK_ITEM_PRODUCT_CODE"
                                  [orderType]="queryModel.getOrderType(StockRecordLog.OrderField.STOCK_ITEM_PRODUCT_CODE)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'STOCK_ITEM_TABLE_HEADER_PRODUCT_CODE' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header hidden-sm-down">
          <app-table-field-sorter [orderField]="StockRecordLog.OrderField.AMOUNT_FROM"
                                  [orderType]="queryModel.getOrderType(StockRecordLog.OrderField.AMOUNT_FROM)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'STOCK_RECORD_LOG_AMOUNT_FROM' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header">
          <app-table-field-sorter [orderField]="StockRecordLog.OrderField.AMOUNT_TO"
                                  [orderType]="queryModel.getOrderType(StockRecordLog.OrderField.AMOUNT_TO)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'STOCK_RECORD_LOG_AMOUNT_TO' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header">
          <app-table-field-sorter [orderField]="StockRecordLog.OrderField.DIFFERENCE"
                                  [orderType]="queryModel.getOrderType(StockRecordLog.OrderField.AMOUNT_TO)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'STOCK_RECORD_LOG_AMOUNT_DIFFERENCE' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header hidden-1300-down">
          <app-table-field-sorter [orderField]="StockRecordLog.OrderField.CREATION_TIME"
                                  [orderType]="queryModel.getOrderType(StockRecordLog.OrderField.CREATION_TIME)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'STOCK_RECORD_LOG_UPDATE_TIME' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header hidden-lg-down">
          <app-table-field-sorter [orderField]="StockRecordLog.OrderField.EDITOR_PERSON_NAME"
                                  [orderType]="queryModel.getOrderType(StockRecordLog.OrderField.EDITOR_PERSON_NAME)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'STOCK_RECORD_LOG_EDITOR' | translate"></app-table-field-sorter>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let stockLog of stockRecordLogList | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }">
        <td class="responsive-table-column hidden-1300-down fit">{{stockLog.id}}</td>
        <td class="responsive-table-column fit">{{getSourceText(stockLog.source)}}
          <br>
          <a class="id-text cursor-pointer table-text-small"
             *ngIf="stockLog.task_record"
             href="#" onclick="return false"
             uiSref="Admin.TaskRecordDetail"
             [uiParams]="{ taskRecordId: stockLog.task_record.id, taskId: stockLog.task_record.task_id }"
             title="{{'COMMON_BUTTON_DETAILS' | translate}}">
            {{ stockLog.task_record.name }}
          </a>
        </td>
        <td class="responsive-table-column hidden-xs-down" *ngIf="type !== StockLogListType.STOCK">
          <a class="id-text cursor-pointer"
             href="#" onclick="return false"
             uiSref="Admin.StockDetail"
             [uiParams]="{ id: stockLog.stock.id }"
             title="{{'COMMON_BUTTON_DETAILS' | translate}}">
            {{ stockLog.stock.name }}
          </a>
        </td>
        <td class="responsive-table-column" *ngIf="type !== StockLogListType.STOCK_ITEM">
          <a class="id-text cursor-pointer"
             href="#" onclick="return false"
             uiSref="Admin.StockItemDetail"
             [uiParams]="{ id: stockLog.stock_record.stock_item.id }"
             title="{{'COMMON_BUTTON_DETAILS' | translate}}">
            {{ stockLog.stock_record.stock_item.name }}
          </a>
        </td>
        <td class="responsive-table-column hidden-sm-down"
            *ngIf="type !== StockLogListType.STOCK_ITEM">{{ stockLog.stock_record.stock_item.product_code }}</td>
        <td class="fit hidden-sm-down">{{ stockLog.amount_from }} {{ stockLog.stock_record.stock_item.unit }}
          <div *ngIf="stockLog.stock_record.stock_item.package_data"
               class="table-text-subtitle">{{StockItemUtils.convertToPackageStr(stockLog.amount_from, stockLog.stock_record.stock_item.package_data)}}</div>
        </td>
        <td class="fit">{{ stockLog.amount_to }} {{ stockLog.stock_record.stock_item.unit }}
          <div *ngIf="stockLog.stock_record.stock_item.package_data"
               class="table-text-subtitle">{{StockItemUtils.convertToPackageStr(stockLog.amount_to, stockLog.stock_record.stock_item.package_data)}}</div>
        </td>
        <td class="fit">{{ stockLog.difference }} {{ stockLog.stock_record.stock_item.unit }}
          <div *ngIf="stockLog.stock_record.stock_item.package_data"
               class="table-text-subtitle">{{StockItemUtils.convertToPackageStr(stockLog.difference, stockLog.stock_record.stock_item.package_data)}}</div>
        </td>
        <td class="hidden-1300-down fit">{{ stockLog.creation_time | date:'short'}}</td>
        <td class="hidden-lg-down fit">
          <app-assignee-table-cell
            *ngIf="stockLog.editor"
            [assigneeType]="AssigneeType.USER"
            [user]="stockLog.editor">
          </app-assignee-table-cell>
        </td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <th class="table-sorter-header hidden-1300-down">
          <app-table-field-sorter [orderField]="StockRecordLog.OrderField.ID"
                                  [orderType]="queryModel.getOrderType(StockRecordLog.OrderField.ID)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header">
          <app-table-field-sorter [orderField]="StockRecordLog.OrderField.SOURCE"
                                  [orderType]="queryModel.getOrderType(StockRecordLog.OrderField.SOURCE)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'STOCK_RECORD_LOG_SOURCE' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header hidden-xs-down" *ngIf="type !== StockLogListType.STOCK">
          <app-table-field-sorter [orderField]="StockRecordLog.OrderField.STOCK_NAME"
                                  [orderType]="queryModel.getOrderType(StockRecordLog.OrderField.STOCK_NAME)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'STOCK_RECORD_LOG_STOCK_NAME' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header" *ngIf="type !== StockLogListType.STOCK_ITEM">
          <app-table-field-sorter [orderField]="StockRecordLog.OrderField.STOCK_ITEM_NAME"
                                  [orderType]="queryModel.getOrderType(StockRecordLog.OrderField.STOCK_ITEM_NAME)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'STOCK_RECORD_LOG_ITEM_NAME' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header hidden-sm-down" *ngIf="type !== StockLogListType.STOCK_ITEM">
          <app-table-field-sorter [orderField]="StockRecordLog.OrderField.STOCK_ITEM_PRODUCT_CODE"
                                  [orderType]="queryModel.getOrderType(StockRecordLog.OrderField.STOCK_ITEM_PRODUCT_CODE)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'STOCK_ITEM_TABLE_HEADER_PRODUCT_CODE' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header hidden-sm-down">
          <app-table-field-sorter [orderField]="StockRecordLog.OrderField.AMOUNT_FROM"
                                  [orderType]="queryModel.getOrderType(StockRecordLog.OrderField.AMOUNT_FROM)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'STOCK_RECORD_LOG_AMOUNT_FROM' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header">
          <app-table-field-sorter [orderField]="StockRecordLog.OrderField.AMOUNT_TO"
                                  [orderType]="queryModel.getOrderType(StockRecordLog.OrderField.AMOUNT_TO)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'STOCK_RECORD_LOG_AMOUNT_TO' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header">
          <app-table-field-sorter [orderField]="StockRecordLog.OrderField.DIFFERENCE"
                                  [orderType]="queryModel.getOrderType(StockRecordLog.OrderField.DIFFERENCE)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'STOCK_RECORD_LOG_AMOUNT_DIFFERENCE' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header hidden-1300-down">
          <app-table-field-sorter [orderField]="StockRecordLog.OrderField.CREATION_TIME"
                                  [orderType]="queryModel.getOrderType(StockRecordLog.OrderField.CREATION_TIME)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'STOCK_RECORD_LOG_UPDATE_TIME' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header hidden-lg-down">
          <app-table-field-sorter [orderField]="StockRecordLog.OrderField.EDITOR_PERSON_NAME"
                                  [orderType]="queryModel.getOrderType(StockRecordLog.OrderField.EDITOR_PERSON_NAME)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'STOCK_RECORD_LOG_EDITOR' | translate"></app-table-field-sorter>
        </th>
      </tr>
      </tfoot>
    </table>
    <app-table-paging
      [currentNumberOfItems]="queryModel.currentNumberOfItems"
      [totalNumberOfItems]="queryModel.totalNumberOfItems"
      [itemsPerPage]="queryModel.itemsPerPage"
      (pageChange)="pageChanged($event)"
      (itemsPerPageChange)="itemsPerPageChanged($event)">
    </app-table-paging>
  </div>
</div>
