/* eslint-disable */
import { Component, ViewChild, } from '@angular/core';
import { FieldValidationError, FormRef, LocalFormGroupValidationErrors, } from '../../../../../../../lib/util/services';
import {
  FormEditFieldCreateDialogView,
  FormEditFieldDialogContext,
  FormEditFieldUpdateDialogView,
  FormGroupModel,
  FormModel,
} from '../../../../../../../util/form/form-utils';
import { Form } from '../../../../../../../lib/form/form.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators, } from '@angular/forms';
import { FieldWidthType } from '../../../../../../../util/form/form-field-width-type';
import { InputMask } from '../../../../../../../util/input-masks';
import { NgbDatePickerParserFormatter } from '../../../../../../../util/ngb-datepicker';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AppValidators } from '../../../../../../../util/app-validators';
import { Dates } from '../../../../../../../lib/util/dates';
import {
  FieldValidationType,
  FormFieldValidationTypeModel,
} from '../../../../../../../util/form/form-field-validation-type';
import { Models } from '../../../../../../../util/model-utils';
import { UiConstants } from '../../../../../../../util/core-utils';
import { TranslateService } from '@ngx-translate/core';
import { TranslateUtils } from '../../../../../../../util/translate';

/* eslint-enable */

@Component({
  selector: 'app-form-edit-create-update-date-field-dialog',
  templateUrl: 'form-edit-create-update-date-field-dialog.component.html',
  styleUrls: ['form-edit-create-update-date-field-dialog.component.scss'],
})
export class FormEditCreateUpdateDateFieldDialogComponent
  implements FormEditFieldCreateDialogView, FormEditFieldUpdateDialogView, FormRef {

  static readonly DEADLINE_API_NAME = 'invoice_deadline_date';
  static readonly DELIVERY_API_NAME = 'invoice_delivery_date';

  public readonly selector = Form.FieldDataTypeSelector.DATE;
  public readonly createTitle = 'FORM_ITEM_CREATE_DATE_TITLE';
  public readonly updateTitle = 'FORM_ITEM_UPDATE_DATE_TITLE';
  public readonly cloneTitle = 'FORM_ITEM_CLONE_DATE_TITLE';

  InputMask = InputMask;
  Form = Form;
  UiConstants = UiConstants;

  @ViewChild('fieldDialog', { static: true })
  dialogComponent: ModalDirective;

  formGroup: FormGroup;

  visible: boolean = false;
  fieldModel: Model;
  dialogTitleDictionaryKey: string = '';
  latestApiExportName = '';

  fieldWidthTypes: FieldWidthType[] = FieldWidthType.VALUES.toArray();
  fieldValidationTypes: Form.FormFieldValidationType[] = FieldValidationType.VALUES.toArray();
  reservedTitles: Map<string, string> = new Map<string, string>();

  private fieldErrors: FieldValidationError<Form.FieldValidatedField> =
    FieldValidationError.empty<Form.FieldValidatedField>();

  private formGroupValidationErrors: LocalFormGroupValidationErrors;

  private context: FormEditFieldDialogContext;
  private form?: FormModel;
  private group?: FormGroupModel;
  private field?: Form.Field;
  private clone?: boolean;
  private submitted: boolean = false;
  private editableApiName: boolean = true;

  constructor(
    private datePickerParserFormatter: NgbDatePickerParserFormatter,
    private translateService: TranslateService,
    fb: FormBuilder) {
    this.fieldModel = new Model();
    this.formGroup = this.createFormGroup(fb);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(this, this.formGroup);
    this.loadTitles();
  }

  getForm() {
    return {
      submitted: this.submitted
    };
  }

  registerContext(context: FormEditFieldDialogContext): void {
    this.context = context;
  }

  showDialog(form: FormModel, group: FormGroupModel, field?: Form.Field, clone?: boolean): void {
    this.latestApiExportName = '';
    this.editableApiName = false;
    this.visible = true;
    this.form = form;
    this.group = group;
    this.clone = clone;
    this.dialogComponent.show();
    if (field && clone) {
      this.dialogTitleDictionaryKey = this.cloneTitle;
      this.loadField(field);
    }
    else if (field) {
      this.dialogTitleDictionaryKey = this.updateTitle;
      this.loadField(field);
    }
    else {
      this.dialogTitleDictionaryKey = this.createTitle;
    }
  }

  onModalHide(event: ModalDirective) {
    if (event.dismissReason === 'backdrop-click' || event.dismissReason === 'esc') {
      this.visible = false;
      this.submitted = false;
      this.fieldModel = new Model();
    }
  }

  closeDialog(): void {
    this.visible = false;
    this.submitted = false;
    this.formGroup.reset();
    this.dialogComponent.hide();
    this.fieldModel = new Model();
  }

  saveField() {
    this.submitted = true;
    this.formGroup.updateValueAndValidity();
    if (this.formGroup.invalid) {
      return;
    }
    if (this.field && this.clone) {
      this.createField();
    }
    else if (this.field) {
      this.updateField(this.field);
    }
    else {
      this.createField();
    }
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  hasLocalFormError(errorCode: string): boolean {
    return this.formGroupValidationErrors.hasFormError(errorCode);
  }

  hasFieldError(field?: Form.FieldValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: Form.FieldValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: Form.FieldValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  isDefaultDateDisabled(): boolean {
    return this.fieldModel.defaultToday;
  }

  isMinValueDisabled(): boolean {
    return this.fieldModel.disablePast;
  }

  isMaxValueDisabled(): boolean {
    return this.fieldModel.disableFuture;
  }

  isDisableFutureDisabled(): boolean {
    return this.fieldModel.disablePast;
  }

  dateDefaultTodayChanged() {
    if (this.fieldModel.defaultToday) {
      this.fieldModel.defaultValue = null;
    }
  }

  dateDisablePastChanged() {
    if (this.fieldModel.disablePast) {
      this.fieldModel.minValue = null;
      this.fieldModel.disableFuture = false;
      this.fieldModel.maxValue = null;
    }
    this.formGroup.get('minValue')!.updateValueAndValidity();
  }

  dateDisableFutureChanged() {
    if (this.fieldModel.disableFuture) {
      this.fieldModel.maxValue = null;
    }
    this.formGroup.get('minValue')!.updateValueAndValidity();
  }

  getFieldValidationTypeDictionaryKey(vt: Form.FormFieldValidationType): string {
    return FieldValidationType.getFieldValidationTypeDictionaryKey(vt);
  }

  onAdminFormFieldValidationTypeChanged() {
    FieldValidationType.onAdminFormFieldValidationTypeChanged(this.fieldModel);
  }

  onMobileFormFieldValidationTypeChanged() {
    FieldValidationType.onMobileFormFieldValidationTypeChanged(this.fieldModel);
  }

  loadTitles() {
    this.translateService.get(['INVOICE_CREATE_DELIVERY_DATE',
      'INVOICE_CREATE_DEADLINE']).subscribe(value => {
        this.reservedTitles.set(FormEditCreateUpdateDateFieldDialogComponent.DEADLINE_API_NAME,
          TranslateUtils.extractValueFromObject(value, 'INVOICE_CREATE_DEADLINE'));
        this.reservedTitles.set(FormEditCreateUpdateDateFieldDialogComponent.DELIVERY_API_NAME,
          TranslateUtils.extractValueFromObject(value, 'INVOICE_CREATE_DELIVERY_DATE'));
    });
  }

  fillDeadline() {
    this.setApiNameEditable(false);
    const title = this.reservedTitles.get(FormEditCreateUpdateDateFieldDialogComponent.DEADLINE_API_NAME);
    this.fieldModel.title = title ? title : '';
    this.fieldModel.apiExportName = FormEditCreateUpdateDateFieldDialogComponent.DEADLINE_API_NAME;
    this.fieldModel.pdfExportName = FormEditCreateUpdateDateFieldDialogComponent.DEADLINE_API_NAME;
    this.fieldModel.minValue = null;
    this.fieldModel.maxValue = null;
    this.fieldModel.defaultValue = null;
    this.fieldModel.defaultToday = true;
    this.fieldModel.disableFuture = false;
    this.fieldModel.disablePast = true;
  }

  fillDeliveryDate() {
    this.setApiNameEditable(false);
    const title = this.reservedTitles.get(FormEditCreateUpdateDateFieldDialogComponent.DELIVERY_API_NAME);
    this.fieldModel.title = title ? title : '';
    this.fieldModel.apiExportName = FormEditCreateUpdateDateFieldDialogComponent.DELIVERY_API_NAME;
    this.fieldModel.pdfExportName = FormEditCreateUpdateDateFieldDialogComponent.DELIVERY_API_NAME;
    this.fieldModel.minValue = null;
    this.fieldModel.maxValue = null;
    this.fieldModel.defaultValue = null;
    this.fieldModel.defaultToday = true;
    this.fieldModel.disableFuture = false;
    this.fieldModel.disablePast = true;
  }

  resetModel() {
    if (!this.field) {
      this.setApiNameEditable(true);
      this.fieldModel = new Model();
    }
    else {
      this.loadField(this.field);
    }
  }

  private get startMaxValue(): NgbDateStruct | null {
    if (this.fieldModel.disableFuture) {
      return this.datePickerParserFormatter.fromLocalDate(Dates.today());
    }
    return null;
  }

  private loadField(field: Form.Field) {
    this.field = field;
    const attrs = field.dataType.dateAttributes!;
    const minValue = this.datePickerParserFormatter.fromLocalDate(attrs.minValue);
    const maxValue = this.datePickerParserFormatter.fromLocalDate(attrs.maxValue);
    const placeholder = attrs.hint;
    const defaultValue = this.datePickerParserFormatter.fromLocalDate(attrs.defaultValue);
    const defaultToday = attrs.defaultToday;
    const disableFuture = attrs.disableFuture;
    const disablePast = attrs.disablePast;
    const fieldWidth = FieldWidthType.fromPercentValue(field.formFieldWidthPercent);
    this.fieldModel.title = field.title;
    this.fieldModel.description = field.description ? field.description : '';
    this.fieldModel.hint = field.hint ? field.hint : '';
    this.fieldModel.apiExportName = field.apiExportName ? field.apiExportName : '';
    this.fieldModel.pdfExportName = field.pdfExportName ? field.pdfExportName : '';
    this.fieldModel.minValue = minValue;
    this.fieldModel.maxValue = maxValue;
    this.fieldModel.defaultValue = defaultValue;
    this.fieldModel.fieldWidthType = fieldWidth ? fieldWidth : FieldWidthType.DEFAULT;
    this.fieldModel.displayOnNewRow = field.displayOnNewRow;
    this.fieldModel.placeholder = placeholder ? placeholder : '';
    this.fieldModel.defaultToday = defaultToday;
    this.fieldModel.disableFuture = disableFuture;
    this.fieldModel.disablePast = disablePast;
    this.fieldModel.adminVisibleOnFormDetail = field.admin.visibleOnFormDetail;
    this.fieldModel.mobileVisibleOnFormDetail = field.mobile.visibleOnFormDetail;
    this.fieldModel.mobileVisibleOnMasterDetail = field.mobile.visibleOnMasterDetail;
    this.fieldModel.adminFormFieldValidationType = field.admin.validationType;
    this.fieldModel.mobileFormFieldValidationType = field.mobile.validationType;
    this.fieldModel.helpdeskFormFieldValidationType = field.helpdesk.validationType;
    this.fieldModel.showOnReceipt = field.showOnReceipt;
    this.setApiNameEditable(!this.isReservedApiName(this.fieldModel.apiExportName));
  }

  private createField() {
    this.context.formService.createField({
      parentId: this.context.parentId,
      groupId: this.group!.groupId,
      title: this.fieldModel.title,
      description: this.fieldModel.description,
      hint: this.fieldModel.hint,
      pdfExportName: this.fieldModel.pdfExportName,
      apiExportName: this.fieldModel.apiExportName,
      dataTypeSelector: this.selector,
      dataType: this.getDataTypeRequest(),
      formFieldWidthPercent: this.fieldModel.fieldWidthType.percentValue,
      displayOnNewRow: this.fieldModel.displayOnNewRow,
      admin: {
        visibleOnFormDetail: this.fieldModel.adminVisibleOnFormDetail,
        validationType: this.fieldModel.adminFormFieldValidationType
      },
      helpdesk: {
        validationType: this.fieldModel.helpdeskFormFieldValidationType
      },
      mobile: {
        visibleOnFormDetail: this.fieldModel.mobileVisibleOnFormDetail,
        visibleOnMasterDetail: this.fieldModel.mobileVisibleOnMasterDetail,
        validationType: this.fieldModel.mobileFormFieldValidationType
      },
      showOnReceipt: this.fieldModel.showOnReceipt
    }).subscribe(
      (result) => {
        this.context.formView.reloadForm();
        this.closeDialog();
      },
      (error) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withFormRef(this);
        }
      }
    );
  }

  private updateField(field: Form.Field) {
    this.context.formService.updateField({
      parentId: this.context.parentId,
      groupId: this.group!.groupId,
      fieldId: field.fieldId,
      title: this.fieldModel.title,
      description: this.fieldModel.description,
      hint: this.fieldModel.hint,
      pdfExportName: this.fieldModel.pdfExportName,
      apiExportName: this.fieldModel.apiExportName,
      dataTypeSelector: this.selector,
      dataType: this.getDataTypeRequest(),
      formFieldWidthPercent: this.fieldModel.fieldWidthType.percentValue,
      displayOnNewRow: this.fieldModel.displayOnNewRow,
      admin: {
        visibleOnFormDetail: this.fieldModel.adminVisibleOnFormDetail,
        validationType: this.fieldModel.adminFormFieldValidationType
      },
      helpdesk: {
        validationType: this.fieldModel.helpdeskFormFieldValidationType
      },
      mobile: {
        visibleOnFormDetail: this.fieldModel.mobileVisibleOnFormDetail,
        visibleOnMasterDetail: this.fieldModel.mobileVisibleOnMasterDetail,
        validationType: this.fieldModel.mobileFormFieldValidationType
      },
      showOnReceipt: this.fieldModel.showOnReceipt
    }).subscribe(
      (result) => {
        this.context.formView.reloadForm();
        this.closeDialog();
      },
      (error) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withFormRef(this);
        }
      }
    );
  }

  private getDataTypeRequest(): Form.FieldDataType {
    const minValue = this.datePickerParserFormatter.toLocalDate(this.fieldModel.minValue);
    const maxValue = this.datePickerParserFormatter.toLocalDate(this.fieldModel.maxValue);
    const defaultValue = this.datePickerParserFormatter.toLocalDate(this.fieldModel.defaultValue);
    return {
      dateAttributes: {
        hint: this.fieldModel.placeholder,
        minValue: minValue,
        maxValue: maxValue,
        defaultValue: defaultValue,
        defaultToday: this.fieldModel.defaultToday,
        disableFuture: this.fieldModel.disableFuture,
        disablePast: this.fieldModel.disablePast
      }
    };
  }

  onTitleBlur() {
    const normalizedText = Models.normalizeText(this.fieldModel.title);
    if (this.fieldModel.apiExportName === '') {
      this.fieldModel.apiExportName = normalizedText;
    }
    if (this.fieldModel.pdfExportName === '') {
      this.fieldModel.pdfExportName = normalizedText;
    }
  }

  private createFormGroup(fb: FormBuilder): FormGroup {
    return fb.group(
      {
        title: fb.control(
          {value: this.fieldModel.title},
          [
            Validators.required
          ]
        ),
        apiExportName: fb.control(
          {value: this.fieldModel.apiExportName, disabled: !this.editableApiName},
          [
            Validators.required,
          ]
        ),
        pdfExportName: fb.control(
          {value: this.fieldModel.pdfExportName, disabled: !this.editableApiName},
          [
          ]
        ),
        minValue: fb.control(
          {value: this.fieldModel.minValue, disabled: this.isMinValueDisabled()},
          [
            AppValidators.validateLocalDate,
            AppValidators.validateMaxLocalDate({
              maxValue: () => this.startMaxValue,
              datePickerParserFormatter: this.datePickerParserFormatter
            }),
          ]
        ),
        maxValue: fb.control(
          {value: this.fieldModel.maxValue, disabled: this.isMaxValueDisabled()},
          [
            AppValidators.validateLocalDate
          ]
        ),
        defaultValue: fb.control(
          {value: this.fieldModel.defaultValue, disabled: this.isDefaultDateDisabled()},
          [
            AppValidators.validateLocalDate
          ]
        )
      },
      {
        validator: AppValidators.validatorChain(
          AppValidators.validateLocalDateFromTo({
            allowEquality: false,
            from: () => this.fieldModel.minValue,
            to: () => this.fieldModel.maxValue,
            datePickerParserFormatter: this.datePickerParserFormatter
          }),
          AppValidators.validateLocalDateRange({
            min: () => this.fieldModel.minValue,
            max: () => this.fieldModel.maxValue,
            value: () => this.fieldModel.defaultValue,
            datePickerParserFormatter: this.datePickerParserFormatter
          }),
        )
      }
    );
  }

  private isReservedApiName(apiName: string): boolean {
    return apiName === FormEditCreateUpdateDateFieldDialogComponent.DEADLINE_API_NAME ||
      apiName === FormEditCreateUpdateDateFieldDialogComponent.DELIVERY_API_NAME;
  }

  setApiNameEditable(value: boolean) {
    this.editableApiName = value;
    switch (value) {
      case true:
        this.formGroup.get('apiExportName')!.enable();
        this.formGroup.get('pdfExportName')!.enable();
        break;
      case false:
        this.formGroup.get('apiExportName')!.disable();
        this.formGroup.get('pdfExportName')!.disable();
        break;
    }
  }
}

export class Model implements FormFieldValidationTypeModel {
  title: string = '';
  description: string = '';
  apiExportName: string = '';
  pdfExportName: string = '';
  minValue: NgbDateStruct | null = null;
  maxValue: NgbDateStruct | null = null;
  defaultValue: NgbDateStruct | null = null;
  fieldWidthType: FieldWidthType = FieldWidthType.DEFAULT;
  displayOnNewRow: boolean = false;
  hint: string = '';
  placeholder: string = '';
  defaultToday: boolean = false;
  disableFuture: boolean = false;
  disablePast: boolean = false;
  adminVisibleOnFormDetail: boolean = true;
  mobileVisibleOnFormDetail: boolean = true;
  mobileVisibleOnMasterDetail: boolean = false;
  adminFormFieldValidationType: Form.FormFieldValidationType = Form.FormFieldValidationType.OPTIONAL;
  mobileFormFieldValidationType: Form.FormFieldValidationType = Form.FormFieldValidationType.OPTIONAL;
  helpdeskFormFieldValidationType: Form.FormFieldValidationType = Form.FormFieldValidationType.OPTIONAL;
  showOnReceipt?: boolean = false;
}
