<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div>
    <div class="breadcrumb-menu d-flex">
      <div class="breadcrumb-button-icon-container cursor-pointer"
           (click)="formImportDialog.openImportDialog()">
        <a class="btn-setting d-flex align-items-center" title="{{'COMMON_BUTTON_IMPORT' | translate}}">
          <i class="icomoon icomoon-csv-import"></i>
          <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_IMPORT' | translate}}</span>
        </a>
      </div>
      <div class="breadcrumb-button-icon-container cursor-pointer negative-margin-right"
           (click)="exportForm()">
        <a class="btn-setting d-flex align-items-center" title="{{'COMMON_BUTTON_EXPORT' | translate}}">
          <i class="icomoon icomoon-csv-export"></i>
          <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_EXPORT' | translate}}</span>
        </a>
      </div>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<!-- region Content -->
<div class="container-horizontal-padding-half container-vertical-padding-half animated fadeIn">

  <!-- region FormTable card -->
  <div class="container-vertical-padding-half card-custom-padding-outer">
    <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 p-0">
      <div class="card">

        <div class="card-header">
          {{'FORM_TABLE_PANEL_HEADING_EDIT'|translate}}
        </div><!-- /.card-header -->

        <div class="card-body">
          <form class="form-horizontal bordered-row" (ngSubmit)="update()" #f="ngForm" novalidate>

            <!-- region Name -->
            <div class="form-group row"
                 [ngClass]="{ 'has-danger': hasFieldError(FormTable.ValidatedField.NAME) || hasLocalFieldError(name) }">
              <div class="col-md-4">
                <label class="mw-100 col-form-label form-control-label required-field-label">
                  {{'COMMON_NAME' | translate}}
                </label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
                         [ngClass]="{ 'form-control-danger': hasFieldError(FormTable.ValidatedField.NAME) || hasLocalFieldError(name) }"
                         [(ngModel)]="model.name" (ngModelChange)="removeFieldError(FormTable.ValidatedField.NAME)"
                         name="name" id="name" #name="ngModel" required>
                  <div class="form-control-feedback"
                       *ngIf="hasFieldError(FormTable.ValidatedField.NAME)">{{getFieldErrorText(FormTable.ValidatedField.NAME)}}</div>
                  <div class="form-control-feedback"
                       *ngIf="hasLocalFieldError(name)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                </div><!-- /.edit-form-input-group -->
              </div>
            </div><!-- /.form-group -->
            <!-- endregion Name -->

            <!-- region external id -->
            <div class="form-group row" [ngClass]="{ 'has-danger': hasFieldError(FormTable.ValidatedField.EXTERNAL_ID) || (f.submitted && !externalId.valid) }">
              <div class="col-md-4">
                <label class="mw-100 form-control-label form-control-label required-field-label">
                  {{'COMMON_EXTERNAL_ID' | translate}}
                </label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <input type="text" class="form-control" placeholder="{{'COMMON_EXTERNAL_ID' | translate}}"
                         [ngClass]="{ 'form-control-danger': hasFieldError(FormTable.ValidatedField.EXTERNAL_ID) || (f.submitted && !externalId.valid) }"
                         [(ngModel)]="model.externalId" (ngModelChange)="removeFieldError(FormTable.ValidatedField.EXTERNAL_ID)" name="externalId" id="externalId" #externalId="ngModel" required>
                  <div class="form-control-feedback" *ngIf="hasFieldError(FormTable.ValidatedField.EXTERNAL_ID)">{{getFieldErrorText(FormTable.ValidatedField.EXTERNAL_ID)}}</div>
                  <div class="form-control-feedback" *ngIf="f.submitted && !externalId.valid">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                </div>
              </div>
            </div>
            <!-- endregion external id -->

            <!-- region Description -->
            <div class="form-group row">
              <div class="col-md-4">
                <label class="mw-100 col-form-label form-control-label">{{'COMMON_DESCRIPTION' | translate}}</label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <input type="text" class="form-control" placeholder="{{'COMMON_DESCRIPTION' | translate}}"
                         [(ngModel)]="model.description" name="description" id="description" #description="ngModel">
                </div><!-- /.edit-form-input-group -->
              </div>
            </div><!-- /.form-group -->
            <!-- endregion Description -->

            <!-- region Bottom buttons -->
            <div>
              <div class="d-flex justify-content-end">
                <input type="submit" class="btn btn-primary" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
              </div><!-- /.edit-form-button-group -->
            </div>
            <!-- endregion Bottom buttons -->

          </form>
        </div><!-- /.card-body -->

      </div><!-- /.card -->
    </div>
  </div><!-- /.container-vertical-padding-half -->
  <!-- endregion FormTable -->

  <!-- region Form card -->
  <div class="container-vertical-padding-half card-custom-padding-outer form-editor-card-container"
       id="form-editor-card-container">
    <app-form-edit #formEdit
                   [formServiceCode]="FormServiceCode.FORM_TABLE"
                   [formRights]="formRights"
                   [parentId]="formTableId"
                   [dialogContainer]="dialogContainer">
    </app-form-edit>
  </div><!-- /.container-vertical-padding -->
  <!-- endregion Form card -->

</div>
<!-- endregion Content -->

<!-- region Dialogs -->
<app-form-edit-dialog-container #dialogContainer></app-form-edit-dialog-container>
<!-- endregion Dialogs -->

<app-form-import-dialog
    #formImportDialog
    [parentId]="formTableId"
    [formService]="formService"
    (onFormImported)="formEdit.reloadForm()">
</app-form-import-dialog>
