<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding-half animated fadeIn">
  <div class="container-vertical-padding-half col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 px-0">
        <div class="card">
          <div class="card-header">
            {{'TRIGGER_DETAIL'|translate}}
          </div>
          <div class="card-body">
            <div class="form-horizontal bordered-row">

              <!-- PDF Trigger-->
              <ng-container *ngIf="triggerType === 'PDF'">

                <div class="form-group row" *ngIf="pdfTriggerDetailModel.name.length > 0">
                  <div class="col-md-4">
                    <label class="mw-100 col-form-label form-control-label">{{'COMMON_NAME'|translate}}</label>
                  </div>
                  <div class="col-md-8">
                    <div class="mw-100">
                      <label class="form-control detail-description">{{pdfTriggerDetailModel.name}}</label>
                    </div>
                  </div>
                </div>

                <div class="form-group row" *ngIf="pdfTriggerDetailModel.event.length > 0">
                  <div class="col-md-4">
                    <label class="mw-100 col-form-label form-control-label">{{'COMMON_TABLE_HEADER_EVENT'|translate}}</label>
                  </div>
                  <div class="col-md-8">
                    <div class="mw-100">
                      <label class="form-control detail-description">{{pdfTriggerDetailModel.event | translate}}</label>
                    </div>
                  </div>
                </div>

                <div class="form-group row" *ngIf="pdfTriggerDetailModel.pdfTemplate.length > 0">
                  <div class="col-md-4">
                    <label class="mw-100 col-form-label form-control-label">{{'TRIGGER_PDF_TEMPLATE'|translate}}</label>
                  </div>
                  <div class="col-md-8">
                    <div class="mw-100">
                      <label class="form-control detail-description">{{pdfTriggerDetailModel.pdfTemplate}}</label>
                    </div>
                  </div>
                </div>

                <div class="form-group row" *ngIf="pdfTriggerDetailModel.namePattern.length > 0">
                  <div class="col-md-4">
                    <label class="mw-100 col-form-label form-control-label">{{'TRIGGER_FILE_NAME_PATTERN'|translate}}</label>
                  </div>
                  <div class="col-md-8">
                    <div class="mw-100">
                      <label class="form-control detail-description">{{pdfTriggerDetailModel.namePattern}}</label>
                    </div>
                  </div>
                </div>

              </ng-container>

              <!-- RECEIPT Trigger-->
              <ng-container *ngIf="triggerType === 'RECEIPT'">

                <div class="form-group row" *ngIf="receiptTriggerDetailModel.name.length > 0">
                  <div class="col-md-4">
                    <label class="mw-100 col-form-label form-control-label">{{'COMMON_NAME'|translate}}</label>
                  </div>
                  <div class="col-md-8">
                    <div class="mw-100">
                      <label class="form-control detail-description">{{receiptTriggerDetailModel.name}}</label>
                    </div>
                  </div>
                </div>

                <div class="form-group row" *ngIf="receiptTriggerDetailModel.event.length > 0">
                  <div class="col-md-4">
                    <label class="mw-100 col-form-label form-control-label">{{'COMMON_TABLE_HEADER_EVENT'|translate}}</label>
                  </div>
                  <div class="col-md-8">
                    <div class="mw-100">
                      <label class="form-control detail-description">{{receiptTriggerDetailModel.event | translate}}</label>
                    </div>
                  </div>
                </div>

                <div class="form-group row" *ngIf="receiptTriggerDetailModel.receiptTemplate.length > 0">
                  <div class="col-md-4">
                    <label class="mw-100 col-form-label form-control-label">{{'TRIGGER_RECEIPT_TEMPLATE'|translate}}</label>
                  </div>
                  <div class="col-md-8">
                    <div class="mw-100">
                      <label class="form-control detail-description">{{receiptTriggerDetailModel.receiptTemplate}}</label>
                    </div>
                  </div>
                </div>

                <div class="form-group row" *ngIf="receiptTriggerDetailModel.namePattern.length > 0">
                  <div class="col-md-4">
                    <label class="mw-100 col-form-label form-control-label">{{'TRIGGER_FILE_NAME_PATTERN'|translate}}</label>
                  </div>
                  <div class="col-md-8">
                    <div class="mw-100">
                      <label class="form-control detail-description">{{receiptTriggerDetailModel.namePattern}}</label>
                    </div>
                  </div>
                </div>

              </ng-container>

              <!-- MESSAGE Trigger-->
              <ng-container *ngIf="triggerType === 'MESSAGE'">

                <div class="form-group row" *ngIf="messageTriggerDetailModel.name.length > 0">
                  <div class="col-md-4">
                    <label class="mw-100 col-form-label form-control-label">{{'COMMON_NAME'|translate}}</label>
                  </div>
                  <div class="col-md-8">
                    <div class="mw-100">
                      <label class="form-control detail-description">{{messageTriggerDetailModel.name}}</label>
                    </div>
                  </div>
                </div>

                <div class="form-group row" *ngIf="messageTriggerDetailModel.event.length > 0">
                  <div class="col-md-4">
                    <label class="mw-100 col-form-label form-control-label">{{'COMMON_TABLE_HEADER_EVENT'|translate}}</label>
                  </div>
                  <div class="col-md-8">
                    <div class="mw-100">
                      <label class="form-control detail-description">{{messageTriggerDetailModel.event | translate}}</label>
                    </div>
                  </div>
                </div>

                <div class="form-group row" *ngIf="messageTriggerDetailModel.messageTemplate.length > 0">
                  <div class="col-md-4">
                    <label class="mw-100 col-form-label form-control-label">{{'TRIGGER_MESSAGE_TEMPLATE'|translate}}</label>
                  </div>
                  <div class="col-md-8">
                    <div class="mw-100">
                      <label class="form-control detail-description">{{messageTriggerDetailModel.messageTemplate}}</label>
                    </div>
                  </div>
                </div>

                <h6 class="mb-1 mt-1">{{'TRIGGER_CREATE_RECIPIENTS' | translate}}</h6>

                <div class="form-group row" *ngIf="messageTriggerDetailModel.sendToEveryone.length > 0">
                  <div class="col-md-4">
                    <label class="mw-100 col-form-label form-control-label">{{'TRIGGER_SEND_TO_EVERYONE'|translate}}</label>
                  </div>
                  <div class="col-md-8">
                    <div class="mw-100">
                      <label class="form-control detail-description">{{messageTriggerDetailModel.sendToEveryone | translate}}</label>
                    </div>
                  </div>
                </div>

                <div class="form-group row" *ngIf="messageTriggerDetailModel.users.length > 0">
                  <div class="col-md-4">
                    <label class="mw-100 col-form-label form-control-label">{{'TRIGGER_USERS'|translate}}</label>
                  </div>
                  <div class="col-md-8">
                    <div class="mw-100">
                      <label class="form-control detail-description">{{messageTriggerDetailModel.users}}</label>
                    </div>
                  </div>
                </div>

                <div class="form-group row" *ngIf="messageTriggerDetailModel.userGroups.length > 0">
                  <div class="col-md-4">
                    <label class="mw-100 col-form-label form-control-label">{{'TRIGGER_USER_GROUPS'|translate}}</label>
                  </div>
                  <div class="col-md-8">
                    <div class="mw-100">
                      <label class="form-control detail-description">{{messageTriggerDetailModel.userGroups}}</label>
                    </div>
                  </div>
                </div>

                <div class="form-group row" *ngIf="messageTriggerDetailModel.mobileApplications.length > 0">
                  <div class="col-md-4">
                    <label class="mw-100 col-form-label form-control-label">{{'TRIGGER_MOBILE_APPLICATIONS'|translate}}</label>
                  </div>
                  <div class="col-md-8">
                    <div class="mw-100">
                      <label class="form-control detail-description">{{messageTriggerDetailModel.mobileApplications}}</label>
                    </div>
                  </div>
                </div>

                <div class="form-group row" *ngIf="messageTriggerDetailModel.relatedPersonTypes.length > 0">
                  <div class="col-md-4">
                    <label class="mw-100 col-form-label form-control-label">{{'TRIGGER_RELATED_PERSON_TYPES'|translate}}</label>
                  </div>
                  <div class="col-md-8">
                    <div class="mw-100">
                      <label class="form-control detail-description">{{messageTriggerDetailModel.relatedPersonTypes}}</label>
                    </div>
                  </div>
                </div>

                <div class="form-group row" *ngIf="messageTriggerDetailModel.ignoredWithEmptyRecipients.length > 0">
                  <div class="col-md-4">
                    <label class="mw-100 col-form-label form-control-label">{{'TRIGGER_IGNORED_WITH_EMPTY_RECIPIENTS'|translate}}</label>
                  </div>
                  <div class="col-md-8">
                    <div class="mw-100">
                      <label class="form-control detail-description">{{messageTriggerDetailModel.ignoredWithEmptyRecipients | translate}}</label>
                    </div>
                  </div>
                </div>

              </ng-container>

              <!-- EMAIL Trigger-->
              <ng-container *ngIf="triggerType === 'EMAIL'">

                <div class="form-group row" *ngIf="emailTriggerDetailModel.name.length > 0">
                  <div class="col-md-4">
                    <label class="mw-100 col-form-label form-control-label">{{'COMMON_NAME'|translate}}</label>
                  </div>
                  <div class="col-md-8">
                    <div class="mw-100">
                      <label class="form-control detail-description">{{emailTriggerDetailModel.name}}</label>
                    </div>
                  </div>
                </div>

                <div class="form-group row" *ngIf="emailTriggerDetailModel.event.length > 0">
                  <div class="col-md-4">
                    <label class="mw-100 col-form-label form-control-label">{{'COMMON_TABLE_HEADER_EVENT'|translate}}</label>
                  </div>
                  <div class="col-md-8">
                    <div class="mw-100">
                      <label class="form-control detail-description">{{emailTriggerDetailModel.event | translate}}</label>
                    </div>
                  </div>
                </div>

                <div class="form-group row" *ngIf="emailTriggerDetailModel.emailTemplate.length > 0">
                  <div class="col-md-4">
                    <label class="mw-100 col-form-label form-control-label">{{'TRIGGER_EMAIL_TEMPLATE'|translate}}</label>
                  </div>
                  <div class="col-md-8">
                    <div class="mw-100">
                      <label class="form-control detail-description">{{emailTriggerDetailModel.emailTemplate}}</label>
                    </div>
                  </div>
                </div>

                <h6 class="mb-1 mt-1">{{'TRIGGER_CREATE_RECIPIENTS' | translate}}</h6>

                <div class="form-group row" *ngIf="emailTriggerDetailModel.sendToEveryone.length > 0">
                  <div class="col-md-4">
                    <label class="mw-100 col-form-label form-control-label">{{'TRIGGER_SEND_TO_EVERYONE'|translate}}</label>
                  </div>
                  <div class="col-md-8">
                    <div class="mw-100">
                      <label class="form-control detail-description">{{emailTriggerDetailModel.sendToEveryone | translate}}</label>
                    </div>
                  </div>
                </div>

                <div class="form-group row" *ngIf="emailTriggerDetailModel.users.length > 0">
                  <div class="col-md-4">
                    <label class="mw-100 col-form-label form-control-label">{{'TRIGGER_USERS'|translate}}</label>
                  </div>
                  <div class="col-md-8">
                    <div class="mw-100">
                      <label class="form-control detail-description">{{emailTriggerDetailModel.users}}</label>
                    </div>
                  </div>
                </div>

                <div class="form-group row" *ngIf="emailTriggerDetailModel.userGroups.length > 0">
                  <div class="col-md-4">
                    <label class="mw-100 col-form-label form-control-label">{{'TRIGGER_USER_GROUPS'|translate}}</label>
                  </div>
                  <div class="col-md-8">
                    <div class="mw-100">
                      <label class="form-control detail-description">{{emailTriggerDetailModel.userGroups}}</label>
                    </div>
                  </div>
                </div>

                <div class="form-group row" *ngIf="emailTriggerDetailModel.emailAddresses.length > 0">
                  <div class="col-md-4">
                    <label class="mw-100 col-form-label form-control-label">{{'CUSTOMER_RECORD_EMAIL_ADDRESSES'|translate}}</label>
                  </div>
                  <div class="col-md-8">
                    <div class="mw-100">
                      <label class="form-control detail-description">{{emailTriggerDetailModel.emailAddresses}}</label>
                    </div>
                  </div>
                </div>

                <div class="form-group row" *ngIf="emailTriggerDetailModel.emailAddressTypes.length > 0">
                  <div class="col-md-4">
                    <label class="mw-100 col-form-label form-control-label">{{'TRIGGER_EMAIL_ADDRESS_TYPES'|translate}}</label>
                  </div>
                  <div class="col-md-8">
                    <div class="mw-100">
                      <label class="form-control detail-description">{{emailTriggerDetailModel.emailAddressTypes}}</label>
                    </div>
                  </div>
                </div>

                <div class="form-group row" *ngIf="emailTriggerDetailModel.relatedPersonTypes.length > 0">
                  <div class="col-md-4">
                    <label class="mw-100 col-form-label form-control-label">{{'TRIGGER_RELATED_PERSON_TYPES'|translate}}</label>
                  </div>
                  <div class="col-md-8">
                    <div class="mw-100">
                      <label class="form-control detail-description">{{emailTriggerDetailModel.relatedPersonTypes}}</label>
                    </div>
                  </div>
                </div>

                <div class="form-group row" *ngIf="triggerScope !== 'TRANSPORT' && emailTriggerDetailModel.ignoredWithEmptyRecipients.length > 0">
                  <div class="col-md-4">
                    <label class="mw-100 col-form-label form-control-label">{{'TRIGGER_IGNORED_WITH_EMPTY_RECIPIENTS'|translate}}</label>
                  </div>
                  <div class="col-md-8">
                    <div class="mw-100">
                      <label class="form-control detail-description">{{emailTriggerDetailModel.ignoredWithEmptyRecipients | translate}}</label>
                    </div>
                  </div>
                </div>

                <h6 class="mb-1 mt-1">{{'FORM_CREATE_ATTACHMENT' | translate}}</h6>

                <div class="form-group row" *ngIf="emailTriggerDetailModel.pdfTriggers.length > 0">
                  <div class="col-md-4">
                    <label class="mw-100 col-form-label form-control-label">{{'TRIGGER_PDF_TRIGGERS'|translate}}</label>
                  </div>
                  <div class="col-md-8">
                    <div class="mw-100">
                      <label class="form-control detail-description">{{emailTriggerDetailModel.pdfTriggers}}</label>
                    </div>
                  </div>
                </div>

                <div class="form-group row" *ngIf="emailTriggerDetailModel.transportDocumentTypes.length > 0">
                  <div class="col-md-4">
                    <label class="mw-100 col-form-label form-control-label">{{'TRIGGER_TRANSPORT_DOCUMENT_TYPE'|translate}}</label>
                  </div>
                  <div class="col-md-8">
                    <div class="mw-100">
                      <label class="form-control detail-description">{{emailTriggerDetailModel.transportDocumentTypes}}</label>
                    </div>
                  </div>
                </div>

                <div class="form-group row" *ngIf="emailTriggerDetailModel.fileDocuments.length > 0">
                  <div class="col-md-4">
                    <label class="mw-100 col-form-label form-control-label">{{'TRIGGER_FILE_DOCUMENTS'|translate}}</label>
                  </div>
                  <div class="col-md-8">
                    <div class="mw-100">
                      <label class="form-control detail-description">{{emailTriggerDetailModel.fileDocuments}}</label>
                    </div>
                  </div>
                </div>

                <div class="form-group row" *ngIf="emailTriggerDetailModel.documentGroups.length > 0">
                  <div class="col-md-4">
                    <label class="mw-100 col-form-label form-control-label">{{'TRIGGER_DOCUMENT_GROUPS'|translate}}</label>
                  </div>
                  <div class="col-md-8">
                    <div class="mw-100">
                      <label class="form-control detail-description">{{emailTriggerDetailModel.documentGroups}}</label>
                    </div>
                  </div>
                </div>

                <div class="form-group row"
                     *ngIf="triggerScope === 'TRANSPORT' &&
                     emailTriggerDetailModel.attachEachShipmentDocument.length > 0">
                  <div class="col-md-4">
                    <label class="mw-100 col-form-label form-control-label">{{'TRIGGER_ATTACH_EACH_SHIPMENT_DOCUMENT'|translate}}</label>
                  </div>
                  <div class="col-md-8">
                    <div class="mw-100">
                      <label class="form-control detail-description">{{emailTriggerDetailModel.attachEachShipmentDocument | translate}}</label>
                    </div>
                  </div>
                </div>

                <div class="form-group row"
                     *ngIf="triggerScope === 'TASK' &&
                     emailTriggerDetailModel.attachInvoices.length > 0">
                  <div class="col-md-4">
                    <label class="mw-100 col-form-label form-control-label">{{'TRIGGER_ATTACH_INVOICES'|translate}}</label>
                  </div>
                  <div class="col-md-8">
                    <div class="mw-100">
                      <label class="form-control detail-description">{{emailTriggerDetailModel.attachInvoices | translate}}</label>
                    </div>
                  </div>
                </div>

              </ng-container>

            </div>
          </div>
        </div>
      </div>
</div>
<div class="container-horizontal-padding container-vertical-padding-half animated fadeIn">
  <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 px-0">
    <app-trigger-instances
    [triggerId]="triggerId"
    [triggerParentId]="triggerParentId"
    [triggerService]="triggerService"
    [triggerScope]="triggerScope">
    </app-trigger-instances>
  </div>
</div>
