<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]=breadcrumbParents
      [self]="breadcrumbSelf">
    </app-breadcrumb>
    <app-breadcrumb-menu>
      <!-- region EDIT-->
      <app-breadcrumb-button
        *ngIf="rightModel.legacyProcessTaskUpdate.hasRight()"
        [iconClass]="'icomoon-modify'"
        [titleStringKey]="'COMMON_BUTTON_EDIT'"
        uiSref="Admin.LegacyProcessTaskEdit"
        [uiParams]="{ processId: processId, id: processTaskId }">
      </app-breadcrumb-button>
      <!-- endregion EDIT-->
    </app-breadcrumb-menu>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">
  <responsive-tabset class="fullscreen_tab">
    <responsive-tab heading="{{'TASK_RECORD_PANEL_HEADING_DETAIL'|translate}}">
      <form class="form-horizontal bordered-row" (ngSubmit)="nothing()" #f="ngForm" novalidate>
        <app-legacy-process-task-base
          headingDictionaryKey="PROCESS_TASK_PANEL_HEADING_DETAIL"
          [readonly]="true"
          [allowClone]="true"
          [form]="f"></app-legacy-process-task-base>
        <app-form-record-container
          [readonly]="true"
          [cloning]="false"
          [form]="f"></app-form-record-container>
      </form>
    </responsive-tab>
    <responsive-tab heading="{{'COMMON_PANEL_HEADING_ATTACHMENT'|translate}}"
         (selectTab)="attachmentView.activateView()">
      <app-legacy-process-task-attachment #attachmentView
                                   [processId]="processId"
                                   [processTaskId]="processTaskId"
                                   (openImageViewerWindowEvent)="imageViewerWindow.openImageViewerWindow()"
                                   (clickedImageEventEmitter)="imageViewerWindow.setClickedImage($event)">
      </app-legacy-process-task-attachment>
    </responsive-tab>
    <!-- todo: Not implemented yet -->
    <!--<responsive-tab heading="{{'TASK_RECORD_PANEL_HEADING_HISTORY'|translate}}">-->
    <!--</responsive-tab>-->
    <!-- todo: Not implemented yet -->
    <!--<responsive-tab heading="{{'TASK_RECORD_PANEL_HEADING_PDF'|translate}}">-->
    <!--</responsive-tab>-->
  </responsive-tabset>
</div>

<app-image-viewer-window
  #imageViewerWindow
  [attachmentView]="attachmentView">
</app-image-viewer-window>
