import { Injectable } from '@angular/core';
import { MultiselectProvider } from '../multiselect-provider';
import { MultiselectOptionItem, UiConstants } from '../../util/core-utils';
import { Observable, Observer } from 'rxjs';
import { OrderType, QueryResult } from '../util/services';
import { Vehicle, VehicleService } from './vehicle.service';
import { Set } from 'immutable';

@Injectable()
export class VehicleMultiselectProvider implements MultiselectProvider {

  private multiselectQueryRequest: Vehicle.QueryRequest = {
    id: undefined,
    paging: {
      pageNumber: 1,
      numberOfItems: UiConstants.autocompletePageSize
    },
    noProgressBar: true,
    orders: Set.of({field: Vehicle.OrderField.NAME, type: OrderType.ASC}),
    disabled: false,
    queryText: undefined
  };

  constructor(private vehicleService: VehicleService) {
  }

  load(queryRequest: Vehicle.QueryRequest): Observable<MultiselectOptionItem<number>[]> {
    return Observable.create((observer: Observer<MultiselectOptionItem<number>[]>) => {
      this.vehicleService.query(queryRequest).subscribe((result: QueryResult<Vehicle.Vehicle>) => {
          observer.next(result.items.map(item => this.toMultiselectOptionItem(item!)).toArray());
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  loadAll(predicate?: string): Observable<MultiselectOptionItem<number>[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.queryText = predicate;
    return this.load(queryRequest);
  }

  loadActive(predicate?: string): Observable<MultiselectOptionItem<number>[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.queryText = predicate;
    queryRequest.disabled = false;
    return this.load(queryRequest);
  }

  getById(id: number): Observable<MultiselectOptionItem<number>> {
    return Observable.create((observer: Observer<MultiselectOptionItem<number>>) => {
      this.vehicleService.get({
        id: id
      }).subscribe((result: Vehicle.Vehicle) => {
          observer.next(this.toMultiselectOptionItem(result!));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  getByIds(ids: number[]): Observable<MultiselectOptionItem<number>[]> {
    const queryRequest = Object.assign([], this.multiselectQueryRequest);
    queryRequest.id = Set.of(...ids);
    return this.load(queryRequest);
  }

  toMultiselectOptionItem(item: Vehicle.Vehicle): MultiselectOptionItem<number> {
    const ret = new MultiselectOptionItem<number>();
    ret.id = item.id;
    ret.itemName = item.licencePlate;
    if (item.manufacturer || item.model) {
      const arr: string[] = [];
      if (item.manufacturer) {
        arr.push(item.manufacturer);
      }
      if (item.model) {
        arr.push(item.model);
      }
      ret.itemSubtitle = arr.join(' ');
    }
    ret.disabled = item.disabled;
    return ret;
  }

}
