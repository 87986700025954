export class TranslateUtils {

  public static extractValueFromObject(o: any, key: string): string {
    if (!o) {
      return key;
    }
    if (o.hasOwnProperty(key)) {
      const value = o[key];
      if (typeof value === 'string') {
        return value;
      }
    }
    return key;
  }

  public static replaceArguments(text: string, args: any): string {
    if (!args) {
      return text;
    }
    for (const key in args) {
      if (args.hasOwnProperty(key)) {
        const value = args[key];
        const regExp = new RegExp('\{\{' + key + '\}\}', 'g');
        text = text.replace(regExp, value);
      }
    }
    return text;
  }

}
