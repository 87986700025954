/* eslint-disable */
import { Iterable as ImmutableIterable, List as ImmutableList, Map as ImmutableMap } from 'immutable';
import { FormRecordFieldView } from '../../../../util/form/form-utils';
import { FieldViewItem } from './form-record-inactivity-manager-api';

/* eslint-enable */

export class FieldViewItemCollection {

  private itemList: ImmutableIterable<number, FieldViewItem> = ImmutableMap.of();
  private itemsByFieldId: ImmutableIterable<number, FieldViewItem> = ImmutableList.of();

  constructor() {}

  addFieldViews(fieldViews: ImmutableList<FormRecordFieldView>) {
    let itemsByFieldId: ImmutableMap<number, FieldViewItem> = this.itemsByFieldId.toMap();
    let items: ImmutableList<FieldViewItem> = this.itemList.toList();
    fieldViews.forEach((view) => {
      if (!view) {
        return;
      }
      const ctx = view.formRecordFieldContext;
      if (ctx) {
        const item = new FieldViewItem(view, ctx.field.fieldId, ctx);
        itemsByFieldId = itemsByFieldId.set(ctx.field.fieldId, item);
        items = items.push(item);
      }
    });
    this.itemList = items;
    this.itemsByFieldId = itemsByFieldId;
  }

  filter(predicate: (viewItem: FieldViewItem) => boolean) {
    this.itemList = this.itemList.filter((item) => {
      return predicate(item!);
    });
    this.itemsByFieldId = this.itemsByFieldId.filter((item) => {
      return predicate(item!);
    });
  }

  forEach(iterator: (item: FieldViewItem) => void) {
    this.itemList.forEach(iterator);
  }

  findByFieldId(fieldId: number): FieldViewItem | undefined {
    return this.itemsByFieldId.get(fieldId);
  }

}
