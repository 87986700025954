<div *ngFor="let survey of surveys">
  <div class="card">
    <div class="card-header">
      {{survey.name}}
    </div>
    <div class="card-body">
      <div class="col-sm-12 p-0">

        <table class="table table-striped table-bordered mb-0">
          <thead>
          <tr>
            <th>{{'COMMON_NAME' | translate}}</th>
            <th>{{'TASK_RECORD_PANEL_PDF_CREATION_DATE' | translate}}</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr class="table" *ngFor="let surveyRecord of getSurveyRecords(survey)">
            <td>{{surveyRecord.name ? surveyRecord.name : surveyRecord.id}}</td>
            <td>{{surveyRecord.creationTime.toUtcIsoString() | date:'short' }}</td>
            <td nowrap>
              <div class="pull-right">
                <a class="icons-table-group cursor-pointer"
                   (click)="showSurveyRecord(surveyRecord.surveyId, surveyRecord.id)">
                  <i class="icomoon icons-table-item icomoon-detail icomoon-detail-table"
                     title="{{'COMMON_BUTTON_DOWNLOAD' | translate}}"></i>
                </a>
                <a class="icons-table-group" href="#" onclick="return false" (click)="setSurveyRecordDisabled(surveyRecord.surveyId, surveyRecord.id)" *ngIf="rightModel.surveyRecordDisable.hasRight()">
                  <i class="icomoon icons-table-item icomoon-disable icomoon-disable-table" title="{{'COMMON_BUTTON_DISABLE' | translate}}"></i>
                </a>
              </div>
            </td>
          </tr>
          </tbody>
          <tfoot>
          <tr>
            <th>{{'COMMON_NAME' | translate}}</th>
            <th>{{'COMMON_CREATION_TIME' | translate}}</th>
            <th></th>
          </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>

  <div class="container-vertical-padding-half"></div>
</div>
<div *ngIf="surveys.length === 0">
  <div class="p-2 text-center text-muted">
    <h6>{{ "TASK_RECORD_DETAIL_NO_SURVEYS" | translate }}</h6>
  </div>
</div>
