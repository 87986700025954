/* eslint-disable */
import { map } from 'rxjs/operators';
import { DownloadedFile } from '../../util/downloaded-files';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FileAttachmentResource } from '../../util/file-attachments';
import { UrlBuilder } from '../../../util/url-builder';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from '../../util/http-services';

/* eslint-enable */

@Injectable()
export class TransportAttachmentResourceService extends BaseHttpService {

  getAttachments(request: TransportAttachmentResource.GetAttachmentRequest): Observable<FileAttachmentResource[]> {
    return this.http.get<FileAttachmentResource[]>(
      this.url + `${request.transport_id}/attachments`,
      this.parseParams(request)
    );
  }

  downloadAttachment(request: TransportAttachmentResource.DownloadAttachmentRequest): Observable<DownloadedFile> {
    const url = UrlBuilder.create('transports/:transportId/attachments/:fileId')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .namedNumber('transportId', request.transportId)
      .namedNumber('fileId', request.fileId)
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob'
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  constructor(private http: HttpClient, private resourceHelper: ResourceHelper) {
    super(resourceHelper, '/transports/');
  }

}

export namespace TransportAttachmentResource {

  // <editor-fold desc="CRUD">

  export interface GetAttachmentRequest {
    transport_id: number;
  }

  export interface DownloadAttachmentRequest {
    transportId: number;
    fileId: number;
  }

  // </editor-fold>

}
