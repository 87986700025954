<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">
  <div class="pl-0 pr-0 col-md-8 col-sm-12 col-xs-12">

    <div class="card">

      <div class="card-header">
        {{'DOCUMENT_LINK_PANEL_HEADING_DETAIL' | translate}}
      </div>

      <div class="card-body">

        <!--ID-->
        <div class="row">
          <div class="col-md-4">
            <label> {{ 'DOCUMENT_TEXT_ID' | translate }} </label>
          </div>
          <div class="col-md-8">
            <input
              type="text"
              class="form-control detail-description"
              [(ngModel)]="model.id"
              name="id"
              id="id"
              #id="ngModel"
              readonly>
          </div>
        </div>

        <!--NAME-->
        <div class="row">
          <div class="col-md-4">
            <label> {{ 'COMMON_NAME' | translate }} </label>
          </div>
          <div class="col-md-8">
            <input
              type="text"
              class="form-control detail-description"
              [(ngModel)]="model.name"
              name="name"
              id="name"
              #name="ngModel"
              readonly>
          </div>
        </div>

        <!--CODE-->
        <div class="row">
          <div class="col-md-4">
            <label> {{ 'DOCUMENT_TEXT_CODE' | translate }} </label>
          </div>
          <div class="col-md-4">
            <input
              type="text"
              class="form-control detail-description"
              [(ngModel)]="model.code"
              name="code"
              id="code"
              #name="ngModel"
              readonly>
          </div>
        </div>

        <!--COMMENT-->
        <div class="row" *ngIf="model.comment">
          <div class="col-md-4">
            <label> {{'DOCUMENT_TEXT_COMMENT' | translate}} </label>
          </div>
          <div class="col-md-8">
            <input
              type="text"
              class="form-control detail-description"
              placeholder="{{'DOCUMENT_TEXT_COMMENT' | translate}}"
              [(ngModel)]="model.comment"
              name="comment"
              id="comment"
              #description="ngModel"
              readonly>
          </div>
        </div>

        <!--GROUP-->
        <div class="row" *ngIf="model.document_group">
          <div class="col-md-4">
            <label> {{ 'DOCUMENT_GROUP' | translate }} </label>
          </div>
          <div class="col-md-8">
            <input
              type="text"
              class="form-control detail-description"
              [ngModel]="model.document_group.text"
              name="document_group"
              id="document_group"
              #document_group="ngModel"
              readonly>
          </div>
        </div>

        <!--LANGUAGE-->
        <div class="row" *ngIf="model.language_code">
          <div class="col-md-4">
            <label> {{ 'DOCUMENT_LANGUAGE' | translate }} </label>
          </div>
          <div class="col-md-8">
            <input
              type="text"
              class="form-control detail-description"
              [ngModel]="model.language_code.text"
              name="language"
              id="language"
              #language="ngModel"
              readonly>
          </div>
        </div>

        <!--TEXT_VALUE-->
        <div class="row">
          <div class="col-md-4">
            <label>{{'DOCUMENT_LINK_VALUE' | translate}}</label>
          </div>
          <div class="col-md-8">
            <input
              type="url"
              class="form-control detail-description"
              placeholder="{{'DOCUMENT_LINK_VALUE' | translate}}"
              [(ngModel)]="model.value"
              name="value"
              id="value"
              #value="ngModel" readonly>
          </div>
        </div>

      </div> <!-- ./card-body end -->
    </div>  <!-- ./card end -->
  </div>
</div>
