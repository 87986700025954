<div class="form-group" [formGroup]="formGroup" [ngClass]="{ 'has-danger': hasLocalFieldError('value')}">
  <label class="form-control-label text-break" [ngClass]="{ 'required-field-label': required }">{{ model.title }}
    <i *ngIf="model.hint !== ''" class="icon icon-info popover-info-icon" title="{{ model.hint }}"></i>
  </label>
  <input placeholder="{{ model.placeholder }}" type="text" class="form-control"
         formControlName="value"
         (input)="onInput()"
         [readonly]="nonEditable"
         [textMask]="getTextMaskConfiguration()"
         [(ngModel)]="model._value"
         [ngClass]="{ 'form-control-danger': hasLocalFieldError('value'), 'no-border': nonEditable }">
  <div class="form-control-feedback" *ngIf="hasLocalFieldError('value')">
    <span *ngIf="hasLocalFieldError('value', 'required')">{{ 'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate }}</span>
    <span *ngIf="hasLocalFieldError('value', 'min')">{{ 'COMMON_VALIDATION_MESSAGE_MIN' | translate: {minValue: minValue} }}</span>
    <span *ngIf="hasLocalFieldError('value', 'max')">{{ 'COMMON_VALIDATION_MESSAGE_MAX' | translate: {maxValue: maxValue} }}</span>
  </div>
</div>
