<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-vertical-padding animated fadeIn">
  <div class="col-xs-12 col-sm-12 col-md-8">

    <!--BASIC DATA-->
    <div class="card">
      <div class="card-header">
        {{'NOTIFICATION_DETAIL_TITLE'|translate}}
      </div>

      <div class="card-body">
        <form class="form-horizontal" #f="ngForm" novalidate>

          <!--USERS-->
          <div class="row" *ngIf="readModel.recipients.userNamesAsString">
            <div class="form-group col-md-4 mb-0">
              <label readonly class="col-form-label form-control-label detail-title">{{'NOTIFICATION_LABEL_USER'|translate}}</label>
            </div>
            <div class="form-group col-md-8 mb-0">
              <label readonly class="col-form-label form-control-label detail-description"> {{ readModel.recipients.userNamesAsString }} </label>
            </div>
          </div>

          <!--USER GROUPS-->
          <div class="row" *ngIf="readModel.recipients.userGroupNamesAsString">
            <div class="form-group col-md-4 mb-0">
              <label readonly class="col-form-label form-control-label detail-title">{{'NOTIFICATION_LABEL_GROUP'|translate}}</label>
            </div>
            <div class="form-group col-md-8 mb-0">
              <label readonly class="col-form-label form-control-label detail-description" id="user_group_names"> {{ readModel.recipients.userGroupNamesAsString }} </label>
            </div>
          </div>

          <!--MOBILE APPS-->
          <div class="row" *ngIf="readModel.recipients.mobileAppNamesAsString">
            <div class="form-group col-md-4 mb-0">
              <label readonly class="col-form-label form-control-label detail-title">{{'NOTIFICATION_LABEL_GROUP'|translate}}</label>
            </div>
            <div class="form-group col-md-8 mb-0">
              <label readonly class="col-form-label form-control-label detail-description" id="mobile_names"> {{ readModel.recipients.mobileAppNamesAsString }} </label>
            </div>
          </div>

          <!--SENT DATE-->
          <div class="row" *ngIf="readModel.sent_date">
            <div class="form-group col-md-4 mb-0">
              <label readonly class="col-form-label form-control-label detail-title">{{'NOTIFICATION_SENT_DATE'|translate}}</label>
            </div>
            <div class="form-group col-md-8 mb-0">
              <label readonly class="col-form-label form-control-label detail-description" id="sent_date"> {{ readModel.sent_date | date:'short' }} </label>
            </div>
          </div>

        </form>
        <form class="form-horizontal" #f="ngForm" novalidate>

          <!--TITLE-->
          <div class="row" *ngIf="readModel.title">
            <div class="form-group col-md-4 mb-0">
              <label readonly class="col-form-label form-control-label detail-title">{{'NOTIFICATION_LABEL_TITLE'|translate}}</label>
            </div>
            <div class="form-group col-md-8 mb-0">
              <label readonly class="col-form-label form-control-label detail-description" id="title"> {{ readModel.title }} </label>
            </div>
          </div>

          <!--MESSAGE-->
          <div class="row" *ngIf="readModel.content">
            <div class="form-group col-md-4 mb-0">
              <label readonly class="col-form-label form-control-label detail-title">{{'NOTIFICATION_LABEL_MESSAGE'|translate}}</label>
            </div>
            <div class="form-group col-md-8 mb-0">
              <label readonly class="col-form-label form-control-label detail-description" id="message"> {{ readModel.content }} </label>
            </div>
          </div>

        </form>
      </div> <!--./card-body-->
    </div>
    <!--./BASIC DATA-->
  </div>
</div>
