export namespace FormResource {

  export interface GetFormRequest {
    parent_id: number;
  }

  export interface CreateGroupRequest {
    parent_id: number;
    title: string;
    short_title?: string;
    api_export_name?: string;
  }

  export interface CreateGroupResponse {
    id: number;
  }

  export interface UpdateGroupRequest {
    parent_id: number;
    group_id: number;
    title: string;
    short_title?: string;
    api_export_name: string;
  }

  export interface UpdateGroupResponse {
  }

  export interface DisableGroupRequest {
    parent_id: number;
    group_id: number;
    disabled: boolean;
  }

  export interface DisableGroupResponse {
  }

  export interface MoveGroupRequest {
    parent_id: number;
    group_id: number;
    index: number;
  }

  export interface MoveGroupResponse {
  }

  export interface CreateFieldRequest {
    parent_id: number;
    group_id: number;
    title: string;
    description?: string;
    hint?: string;
    pdf_export_name?: string;
    api_export_name?: string;
    data_type_selector: FieldDataTypeSelector;
    data_type: FieldDataType;
    field_width: number;
    display_on_new_row: boolean;
    admin: FieldAdmin;
    helpdesk: FieldHelpdesk;
    mobile: FieldMobile;
    show_on_receipt?: boolean;
  }

  export interface CreateFieldResponse {
    id: number;
  }

  export interface UpdateFieldRequest {
    parent_id: number;
    group_id: number;
    field_id: number;
    title: string;
    description?: string;
    hint?: string;
    pdf_export_name?: string;
    api_export_name?: string;
    data_type_selector: FieldDataTypeSelector;
    data_type: FieldDataType;
    field_width: number;
    display_on_new_row: boolean;
    admin: FieldAdmin;
    helpdesk: FieldHelpdesk;
    mobile: FieldMobile;
    show_on_receipt?: boolean;
  }

  export interface UpdateFieldResponse {
  }

  export interface DisableFieldRequest {
    parent_id: number;
    group_id: number;
    field_id: number;
    disabled: boolean;
  }

  export interface DisableFieldResponse {
  }

  export interface MoveFieldToGroupRequest {
    parent_id: number;
    group_id: number;
    field_id: number;
    index: number;
    new_group_id: number;
  }

  export interface MoveFieldToGroupResponse {
  }

  export interface ImportRequest {
    parent_id: number;
    ignore_disabled_items: boolean;
    form: string;
    version: string;
  }

  export interface ExportRequest {
    parent_id: number;
  }

  export interface Form {
    groups: Group[]
  }

  export interface Group {
    id: number;
    creation_time: string;
    update_time: string;
    title: string;
    short_title: string;
    api_export_name: string;
    disabled: boolean;
    fields?: Field[];
  }

  export interface Field {
    id: number;
    disabled: boolean;
    creation_time: string;
    update_time: string;
    title: string;
    description?: string;
    hint?: string;
    pdf_export_name?: string;
    api_export_name?: string;
    data_type_selector: FieldDataTypeSelector;
    data_type: FieldDataType;
    field_width: number;
    display_on_new_row: boolean;
    advanced_mode_enabled: boolean;
    admin: FieldAdmin;
    helpdesk: FieldHelpdesk;
    mobile: FieldMobile;
    show_on_receipt?: boolean;
  }

  export interface FieldAdmin {
    visible_on_form_detail: boolean;
    validation_type: FormFieldValidationType;
  }

  export interface FieldHelpdesk {
    validation_type: FormFieldValidationType;
  }

  export interface FieldMobile {
    visible_on_master_detail: boolean;
    visible_on_form_detail: boolean;
    validation_type: FormFieldValidationType;
  }

  export type FormFieldValidationType =
    'OPTIONAL' | 'REQUIRED' | 'READONLY' | 'HIDDEN';

  export type FieldDataTypeSelector =
    'STRING' | 'NUMBER' | 'DECIMAL' | 'DATE' | 'DATE_TIME' |
    'BOOLEAN' | 'LIST_ITEM' | 'EMAIL_ADDRESS' | 'PHONE_NUMBER' |
    'LIST_MULTI_ITEM' | 'READONLY_TEXT' | 'READONLY_HTML' | 'STOCK' | 'STOCK_INTAKE' | 'STOCK_MOVE' | 'FORM_TABLE' |
    'PAYMENT_TYPE' | 'CUSTOMER' | 'USER' | 'DOCUMENT' | 'PROCESS_ORDER_STOCK_OUTTAKE' |
    'PROCESS_ORDER_STOCK_OUTTAKE_CHECK' | 'PROCESS_ORDER_PACKAGING' | 'READONLY_PICTURE' | 'INVOICE';

  export type FormFieldInputCapitalizeMode =
    'NONE' | 'CHARACTERS' | 'SENTENCES' | 'WORDS';

  export interface FieldDataType {
    string_attributes?: FieldDataTypeStringAttributes;
    number_attributes?: FieldDataTypeNumberAttributes;
    decimal_attributes?: FieldDataTypeDecimalAttributes;
    date_attributes?: FieldDataTypeDateAttributes;
    date_time_attributes?: FieldDataTypeDateTimeAttributes;
    boolean_attributes?: FieldDataTypeBooleanAttributes;
    list_item_attributes?: FieldDataTypeListItemAttributes;
    list_multi_item_attributes?: FieldDataTypeListMultiItemAttributes;
    email_address_attributes?: FieldDataTypeEmailAddressAttributes;
    phone_number_attributes?: FieldDataTypePhoneNumberAttributes;
    master_data_attributes?: FieldDataTypeMasterDataAttributes;
    readonly_text_attributes?: FieldDataTypeReadonlyTextAttributes;
    readonly_picture_attributes?: FieldDataTypeReadonlyPictureAttributes;
    stock_attributes?: FieldDataTypeStockAttributes;
    stock_intake_attributes?: FieldDataTypeStockIntakeAttributes;
    stock_move_attributes?: FieldDataTypeStockMoveAttributes;
    form_table_attributes?: FieldDataTypeFormTableAttributes;
    invoice_payment_type_attributes?: FieldDataTypePaymentTypeAttributes;
    customer_attributes?: FieldDataTypeCustomerAttributes;
    user_attributes?: FieldDataTypeUserAttributes;
    document_attributes?: FieldDataTypeDocumentAttributes;
    photo_attributes?: FieldDataTypePhotoAttributes;
    process_order_stock_outtake_attributes?: FieldDataTypeProcessOrderStockOuttakeAttributes;
    process_order_stock_outtake_check_attributes?: FieldDataTypeProcessOrderStockOuttakeCheckAttributes;
    process_order_packaging_attributes?: FieldDataTypeProcessOrderPackagingAttributes;
    invoice_attributes?: FieldDataTypeInvoiceAttributes;
  }

  export interface FieldDataTypeStringAttributes {
    input_capitalize_mode: FormFieldInputCapitalizeMode;
    hint?: string;
    min_length?: number; // int
    max_length?: number; // int
    default_value?: string;
    multi_line?: boolean;
  }

  export interface FieldDataTypeNumberAttributes {
    hint?: string;
    min_value?: number; // long
    max_value?: number; // long
    default_value?: number; // long
    thousand_separator_enabled?: boolean;
  }

  export interface FieldDataTypeDecimalAttributes {
    hint?: string;
    min_value?: string; // decimal
    max_value?: string; // decimal
    default_value?: string; // decimal
    thousand_separator_enabled?: boolean;
  }

  export interface FieldDataTypeDateAttributes {
    hint?: string;
    min_value?: string;
    max_value?: string;
    default_value?: string;
    default_today: boolean;
    disable_future_date: boolean;
    disable_past_date: boolean;
  }

  export interface FieldDataTypeDateTimeAttributes {
    hint?: string;
    min_value?: string;
    max_value?: string;
    default_value?: string;
    default_today: boolean;
    disable_future_date: boolean;
    disable_past_date: boolean;
  }

  export interface FieldDataTypeBooleanAttributes {
    default_value?: boolean;
    enable_other_fields: boolean;
    fields_to_be_enabled: number[];
    groups_to_be_enabled: number[];
  }

  export interface FieldDataTypeListItemAttributes {
    hint?: string;
    type: string;
    default_value?: number;
    enable_other_fields: boolean;
    fields_to_be_enabled;
    groups_to_be_enabled;
    filter_form_field?: number;
  }

  export interface FieldDataTypeListMultiItemAttributes {
    hint?: string;
    type: string;
    multi_select: boolean;
    default_value: number[];
    enable_other_fields: boolean;
    fields_to_be_enabled;
    groups_to_be_enabled;
    filter_form_field?: number;
  }

  export interface FieldDataTypeEmailAddressAttributes {
    hint?: string;
    default_value?: string;
  }

  export interface FieldDataTypePhoneNumberAttributes {
    hint?: string;
    default_value?: string;
  }

  export interface FieldDataTypeMasterDataAttributes {
    hint?: string;
    multi_select: boolean;
    master_data_id: number;
    filter_types: string[];
    auto_join_owner_on_finish: boolean;

    displayed_form_field?: Field;

    displayed_form_field_id?: number;
  }

  export interface FieldDataTypeCustomerAttributes {
    hint?: string;
    multi_select: boolean;
    customer_id: number;
    auto_join_owner_on_finish: boolean;
    filter_types: string[];
  }

  export interface FieldDataTypeUserAttributes {
    hint?: string;
    multi_select: boolean;
    user_group_ids?: number[];

    generate_signature?: string;
  }

  export interface FieldDataTypeDocumentAttributes {
    multi_select: boolean;
    document_file_type: string;
    valid_mime_types?: string[];
  }

  export interface FieldDataTypePhotoAttributes {
    multi_select: boolean;
  }

  export interface FieldDataTypeReadonlyTextAttributes {
    default_value?: string;
  }

  export interface FieldDataTypeReadonlyPictureAttributes {
    value: number;
  }

  export interface FieldDataTypeStockAttributes {
    advanced_mode_enabled: boolean;
    hide_prices_overview: boolean;
    hide_prices_edit: boolean;
    available_discounts: number[];
    usable_stock_item_categories: number[];
    source_stock_type?: string;
    stock_source_filter_ids: number[];
    modify_stored_amount: boolean;
    unique_stock_records: boolean;
    dispersion_percent_enabled: boolean;
    comment_enabled: boolean;
    comment_required?: boolean;
    comment_custom_label?: string;
    exact_category_match: boolean;
    fill_assignee_stock: boolean;
    default_filter_assignee: boolean;
    clear_on_reopen: boolean;
    used_for_invoice: boolean;
  }

  export interface FieldDataTypeStockIntakeAttributes {
    stock_type?: string;
    usable_stock_item_categories: number[];
    stock_destination_filter_ids: number[];
    clear_on_reopen: boolean;
  }

  export interface FieldDataTypeStockMoveAttributes {
    source_stock_type?: string;
    destination_stock_type?: string;
    usable_stock_item_categories: number[];
    stock_source_filter_ids: number[];
    stock_destination_filter_ids: number[];
    clear_on_reopen: boolean;
  }

  export interface FieldDataTypeFormTableAttributes {
    form_table: number;
  }

  export interface FieldDataTypePaymentTypeAttributes {
    valid_invoice_payment_types?: string[];
  }

  export interface FieldDataTypeProcessOrderStockOuttakeAttributes {
  }

  export interface FieldDataTypeProcessOrderStockOuttakeCheckAttributes {
  }

  export interface FieldDataTypeProcessOrderPackagingAttributes {
  }

  export interface FieldDataTypeInvoiceAttributes {
    available_discounts: number[];
    used_for_invoice: boolean;
    copy_data: boolean;
    merge_data: boolean;
  }

  export interface FormImportDocument {
    form: string;
    version: string;
  }

}

export class FormResourcePaths {

  public static readonly GET_FORM
    = '/{!:parent_id}/form';

  public static readonly CREATE_GROUP
    = '/{!:parent_id}/form/groups';

  public static readonly UPDATE_GROUP
    = '/{!:parent_id}/form/groups/{!:group_id}';

  public static readonly DISABLE_GROUP
    = '/{!:parent_id}/form/groups/{!:group_id}/disabled';

  public static readonly MOVE_GROUP
    = '/{!:parent_id}/form/groups/{!:group_id}/move/{!:index}';

  public static readonly CREATE_FIELD
    = '/{!:parent_id}/form/groups/{!:group_id}/fields';

  public static readonly UPDATE_FIELD
    = '/{!:parent_id}/form/groups/{!:group_id}/fields/{!:field_id}';

  public static readonly DISABLE_FIELD
    = '/{!:parent_id}/form/groups/{!:group_id}/fields/{!:field_id}/disabled';

  public static readonly MOVE_FIELD_TO_GROUP
    = '/{!:parent_id}/form/groups/{!:group_id}/fields/{!:field_id}/move/{!:index}/group/{!new_group_id}';

}

export class FormResourcePathGenerator {

  public static GetForm(request: FormResource.GetFormRequest): string {
    return `${request.parent_id}/form`;
  }

  public static CreateGroup(request: FormResource.CreateGroupRequest): string {
    return `${request.parent_id}/form/groups`;
  }

  public static UpdateGroup(request: FormResource.UpdateGroupRequest): string {
    return `${request.parent_id}/form/groups/${request.group_id}`;
  }

  public static DisableGroup(request: FormResource.DisableGroupRequest): string {
    return `${request.parent_id}/form/groups/${request.group_id}/disabled`;
  }

  public static MoveGroup(request: FormResource.MoveGroupRequest): string {
    return `${request.parent_id}/form/groups/${request.group_id}/move/${request.index}`;
  }

  public static CreateField(request: FormResource.CreateFieldRequest): string {
    return `${request.parent_id}/form/groups/${request.group_id}/fields`;
  }

  public static UpdateField(request: FormResource.UpdateFieldRequest): string {
    return `${request.parent_id}/form/groups/${request.group_id}/fields/${request.field_id}`;
  }

  public static DisableField(request: FormResource.DisableFieldRequest): string {
    return `${request.parent_id}/form/groups/${request.group_id}/fields/${request.field_id}/disabled`;
  }

  public static MoveFieldToGroup(request: FormResource.MoveFieldToGroupRequest): string {
    return `` +
      `${request.parent_id}/form/groups/${request.group_id}/fields/${request.field_id}/move/${request.index}/group/${request.new_group_id}`;
  }

  public static Import(request: FormResource.ImportRequest): string {
    return `${request.parent_id}/form/import?ignore_disabled_items=${request.ignore_disabled_items}`;
  }

  public static Export(request: FormResource.ExportRequest): string {
    return `${request.parent_id}/form/export`;
  }

}
