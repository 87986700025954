export class LazyReference<T> {

  public static of<T>(supplier: () => T): LazyReference<T> {
    return new LazyReference<T>(supplier);
  }

  public get(): T {
    return this.supplier();
  }

  private constructor(private readonly supplier: () => T) {
  }

}
