<div bsModal [config]="UiConstants.modalConfig" #newGroupDialog="bs-modal"
     (onHide)="cancelDialog()"
     class="modal fade" tabindex="-1"
     role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <!-- region Header -->
      <div class="modal-header">
        <h4 class="modal-title">{{dialogTitleDictionaryKey | translate}}</h4>
        <button type="button" class="close" (click)="cancelDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <!-- endregion Header -->
      <!-- region Body -->
      <div class="modal-body">
        <!-- region Title -->
        <div class="form-group" [ngClass]="{ 'has-danger': hasFieldError(Form.GroupValidatedField.TITLE) || hasLocalFieldError(title) }">
          <label class="form-control-label">
            {{'COMMON_NAME' | translate }}
          </label>
          <input name="title" #title="ngModel" required type="text" class="form-control"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 placeholder="{{'COMMON_NAME' | translate }}"
                 [ngClass]="{ 'form-control-danger': hasFieldError(Form.GroupValidatedField.TITLE) }"
                 [(ngModel)]="model.title"
                 (blur)="onTitleBlur()"
                 trim="blur" validateOnBlur
                 (ngModelChange)="removeFieldError(Form.GroupValidatedField.TITLE)">
          <div class="form-control-feedback" *ngIf="hasFieldError(Form.GroupValidatedField.TITLE)">
            {{getFieldErrorText(Form.GroupValidatedField.TITLE)}}
          </div>
          <div class="form-control-feedback" *ngIf="hasLocalFieldError(title)">
            {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
          </div>
        </div>
        <!-- endregion Title -->
        <!-- region Short Title -->
        <div class="form-group">
          <label class="form-control-label">
            {{'TASK_FORM_GROUP_CREATE_SHORT_TITLE_TITLE' | translate }}
          </label>
          <input name="shortTitle" #shortTitle="ngModel" type="text" class="form-control"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 placeholder="{{'TASK_FORM_GROUP_CREATE_SHORT_TITLE_TITLE' | translate }}"
                 [(ngModel)]="model.shortTitle"
                 [ngModelOptions]="{standalone: true}">
        </div>
        <!-- endregion Short Title -->
        <!-- region Title -->
        <div class="form-group" [ngClass]="{ 'has-danger': hasLocalFieldError(apiExportName) }">
          <label class="form-control-label">
            {{'FORM_ITEM_COMMON_EXPORT_NAME' | translate }}
          </label>
          <input name="apiExportName" #apiExportName="ngModel" type="text" class="form-control"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 placeholder="{{'FORM_ITEM_COMMON_EXPORT_NAME' | translate }}"
                 [ngClass]="{ 'form-control-danger': hasLocalFieldError(apiExportName) }"
                 [(ngModel)]="model.apiExportName"
                 [required]="isUpdate">
          <div class="form-control-feedback" *ngIf="hasLocalFieldError(apiExportName)">
            {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
          </div>
        </div>
        <!-- endregion Title -->
      </div>
      <!-- endregion Body -->
      <!-- region Footer buttons -->
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="cancelDialog()">{{'COMMON_BUTTON_CANCEL' | translate}}</button>
        <button type="button" class="btn btn-primary" (click)="saveGroup()">{{'COMMON_BUTTON_SAVE' | translate}}</button>
      </div>
      <!-- endregion Footer buttons -->
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
