<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]=breadcrumbParents
      [self]="breadcrumbSelf">
    </app-breadcrumb>
    <app-breadcrumb-menu>
      <app-breadcrumb-button
        *ngIf="rightModel.vehicleUpdate.hasRight() && componentState.isDetailView()"
        [iconClass]="'icomoon-modify'"
        [titleStringKey]="'COMMON_BUTTON_EDIT'"
        uiSref="Admin.VehicleEdit"
        [uiParams]="{ id: componentState.id }">
      </app-breadcrumb-button>
    </app-breadcrumb-menu>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>

<div class="container-horizontal-padding container-vertical-padding-half animated fadeIn">
  <responsive-tabset class="fullscreen_tab">
    <responsive-tab heading="{{'COMMON_CARD_HEADER_BASE_DATA'|translate}}">

<div class="container-custom-padding animated fadeIn">

  <div class="form-group">

    <form class="form-horizontal bordered-row" (ngSubmit)="submit()" #f="ngForm" id="vehicle_form" novalidate>

    <!--region Basic Details-->
    <div class="col-xs-12 col-sm-12 col-md-8 card-custom-padding">
      <div class="card">
        <div class="card-header">
          {{ getHeadingDictionaryKey() | translate}}
        </div>
        <div class="card-body">

          <!--region licencePlate-->
          <div class="form-group row"
               [ngClass]="{ 'has-danger': hasLocalFieldError(licencePlate) || hasFieldError(Vehicle.ValidatedField.LICENCE_PLATE)}">
            <label class="col-form-label form-control-label col-md-4 detail-title" [class.required-field-label]="componentState.isEditable()">
              {{'VEHICLE_LICENCE_PLATE' | translate}}
            </label>
            <div class="col-md-8">
              <input *ngIf="componentState.isEditable()"
                     type="text" class="form-control"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     placeholder="{{'VEHICLE_LICENCE_PLATE' | translate}}"
                     [(ngModel)]="editModel.licencePlate"
                     (ngModelChange)="removeFieldError(Vehicle.ValidatedField.LICENCE_PLATE)"
                     [ngClass]="{ 'form-control-danger': hasLocalFieldError(licencePlate) || hasFieldError(Vehicle.ValidatedField.LICENCE_PLATE)}"
                     name="licencePlate"
                     id="licencePlate"
                     #licencePlate="ngModel"
                     required>
              <div class="form-control-feedback"
                   *ngIf="hasLocalFieldError(licencePlate)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
              <div class="form-control-feedback"
                   *ngIf="hasFieldError(Vehicle.ValidatedField.LICENCE_PLATE)">{{getFieldErrorText(Vehicle.ValidatedField.LICENCE_PLATE)}}</div>
              <!--Appears in detailView-->
              <label
                *ngIf="componentState.isReadonly()" class="form-control detail-description"
                readonly>{{detailModel.licencePlate}}
              </label>
            </div>
          </div>
          <!--endregion licencePlate-->

          <!--region ExternalId-->
          <div class="form-group row"
               [ngClass]="{ 'has-danger': (hasLocalFieldError(externalId) || hasFieldError(Vehicle.ValidatedField.EXTERNAL_ID)) }">
            <label class="col-form-label form-control-label col-md-4 detail-title" [class.required-field-label]="componentState.isEditView()">
              {{'COMMON_EXTERNAL_ID' | translate}}
            </label>
            <div class="col-md-8">
              <input *ngIf="componentState.isEditable()"
                     type="text" class="form-control"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     placeholder="{{'COMMON_EXTERNAL_ID' | translate}}"
                     [(ngModel)]="editModel.externalId"
                     (ngModelChange)="removeFieldError(Vehicle.ValidatedField.EXTERNAL_ID)"
                     [ngClass]="{ 'form-control-danger': hasLocalFieldError(externalId) || hasFieldError(Vehicle.ValidatedField.EXTERNAL_ID) }"
                     name="externalId"
                     id="externalId"
                     #externalId="ngModel"
                     [required]="componentState.isEditView()">
              <div class="form-control-feedback"
                   *ngIf="hasLocalFieldError(externalId)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
              <div class="form-control-feedback"
                   *ngIf="hasFieldError(Vehicle.ValidatedField.EXTERNAL_ID)">{{getFieldErrorText(Vehicle.ValidatedField.EXTERNAL_ID)}}</div>
              <!--Appears in detailView-->
              <label
                *ngIf="componentState.isReadonly()" class="form-control detail-description"
                readonly>{{detailModel.externalId}}
              </label>
            </div>
          </div>
          <!--endregion ExternalId-->

          <!--region kmCounter-->
          <div class="form-group row" [ngClass]="{ 'has-danger': (hasLocalFieldError(kmCounter) || hasFieldError(Vehicle.ValidatedField.KM_COUNTER)) }">
            <label class="col-form-label form-control-label col-md-4 detail-title" [class.required-field-label]="componentState.isEditable()">
              {{'VEHICLE_MILEAGE' | translate}}
            </label>
            <div class="col-md-8">
              <input *ngIf="componentState.isEditable()"
                     type="text" class="form-control"
                     maxlength="10"
                     placeholder="{{'VEHICLE_MILEAGE' | translate}}"
                     [(ngModel)]="editModel.kmCounter"
                     (ngModelChange)="removeFieldError(Vehicle.ValidatedField.KM_COUNTER)"
                     [ngClass]="{ 'form-control-danger': hasLocalFieldError(kmCounter) || hasFieldError(Vehicle.ValidatedField.KM_COUNTER) }"
                     [textMask]="{mask: InputMask.NATURAL_FLOAT_WITH_THOUSAND_SEPARATOR, guide: false}"
                     name="kmCounter"
                     id="kmCounter"
                     #kmCounter="ngModel"
                     required>
              <div class="form-control-feedback"
                   *ngIf="hasLocalFieldError(kmCounter)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
              <div class="form-control-feedback"
                   *ngIf="hasFieldError(Vehicle.ValidatedField.KM_COUNTER)">{{getFieldErrorText(Vehicle.ValidatedField.KM_COUNTER)}}</div>
              <!--Appears in detailView-->
              <label
                *ngIf="componentState.isReadonly()" class="form-control detail-description"
                readonly>{{detailModel.kmCounter}}
              </label>
            </div>
          </div>
          <!--endregion kmCounter-->

          <!--region Manufacturer-->
          <div class="form-group row">
            <label class="col-form-label form-control-label col-md-4 detail-title">
              {{'VEHICLE_MANUFACTURER' | translate}}
            </label>
            <div class="col-md-8">
              <input *ngIf="componentState.isEditable()"
                     type="text" class="form-control"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     placeholder="{{'VEHICLE_MANUFACTURER' | translate}}"
                     [(ngModel)]="editModel.manufacturer"
                     name="manufacturer"
                     id="manufacturer"
                     #manufacturer="ngModel">
              <!--Appears in detailView-->
              <label
                *ngIf="componentState.isReadonly()" class="form-control detail-description"
                readonly>{{detailModel.manufacturer}}
              </label>
            </div>
          </div>
          <!--endregion Manufacturer-->

          <!--region Model-->
          <div class="form-group row">
            <label class="col-form-label form-control-label col-md-4 detail-title">
              {{'VEHICLE_MODEL' | translate}}
            </label>
            <div class="col-md-8">
              <input *ngIf="componentState.isEditable()"
                     type="text" class="form-control"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     placeholder="{{'VEHICLE_MODEL' | translate}}"
                     [(ngModel)]="editModel.model"
                     name="model"
                     id="model"
                     #model="ngModel">
              <!--Appears in detailView-->
              <label
                *ngIf="componentState.isReadonly()" class="form-control detail-description"
                readonly>{{detailModel.model}}
              </label>
            </div>
          </div>
          <!--endregion Model-->

            <!--region registrationDate-->
            <div class="form-group row">
              <label class="col-form-label form-control-label col-md-4 detail-title">
                {{'VEHICLE_REGISTRATION_DATE' | translate}}
              </label>
              <div class="col-md-8">
                <div class="input-group"
                     *ngIf="componentState.isEditable()">
                  <input
                    type="text" class="form-control date"
                    placeholder="{{'VEHICLE_REGISTRATION_DATE' | translate}}"
                    [(ngModel)]="editModel.registrationDate"
                    ngbDatepicker
                    name="registrationDate"
                    id="registrationDate"
                    #registrationDateModel="ngModel"
                    #registrationDate="ngbDatepicker">
                  <div class="input-group-addon-gray"
                       (click)="registrationDate.toggle()">
                    <i class="icomoon icomoon-calendar"></i>
                  </div>
                </div>
                <!--Appears in detailView-->
                <label
                  *ngIf="componentState.isReadonly()" class="form-control detail-description"
                  readonly>{{detailModel.registrationDate}}
                </label>
              </div>
            </div>
            <!--endregion registrationDate-->

            <!--region payloadKg-->
            <div class="form-group row">
              <label class="col-form-label form-control-label col-md-4 detail-title">
                {{'VEHICLE_PAYLOAD_WEIGHT_KG' | translate}}
              </label>
              <div class="col-md-8">
                <input *ngIf="componentState.isEditable()"
                       type="text" class="form-control"
                       maxlength="10"
                       placeholder="{{'VEHICLE_PAYLOAD_WEIGHT_KG' | translate}}"
                       [(ngModel)]="editModel.payloadKg"
                       [textMask]="{mask: InputMask.NATURAL_FLOAT_WITH_THOUSAND_SEPARATOR, guide: false}"
                       name="payloadKg"
                       id="payloadKg"
                       #payloadKg="ngModel">
                <!--Appears in detailView-->
                <label
                  *ngIf="componentState.isReadonly()" class="form-control detail-description"
                  readonly>{{detailModel.payloadKg}}
                </label>
              </div>
            </div>
            <!--endregion payloadKg-->

          <!--region totalWightKg-->
          <div class="form-group row">
            <label class="col-form-label form-control-label col-md-4 detail-title">
              {{'VEHICLE_TOTAL_WEIGHT_KG' | translate}}
            </label>
            <div class="col-md-8">
              <input *ngIf="componentState.isEditable()"
                     type="text" class="form-control"
                     maxlength="10"
                     placeholder="{{'VEHICLE_TOTAL_WEIGHT_KG' | translate}}"
                     [(ngModel)]="editModel.totalWeightKg"
                     [textMask]="{mask: InputMask.NATURAL_FLOAT_WITH_THOUSAND_SEPARATOR, guide: false}"
                     name="totalWeightKg"
                     id="totalWeightKg"
                     #totalWeightKg="ngModel">
              <!--Appears in detailView-->
              <label
                *ngIf="componentState.isReadonly()" class="form-control detail-description"
                readonly>{{detailModel.totalWeightKg}}
              </label>
            </div>
          </div>
          <!--endregion totalWightKg-->

          <!--region numberOfSeats-->
          <div class="form-group row">
            <label class="col-form-label form-control-label col-md-4 detail-title">{{'VEHICLE_NUMBER_OF_SEATS' | translate}}</label>
            <div class="col-md-8">
              <input *ngIf="componentState.isEditable()"
                     type="text" class="form-control"
                     maxlength="10"
                     placeholder="{{'VEHICLE_NUMBER_OF_SEATS' | translate}}"
                     [(ngModel)]="editModel.numberOfSeats"
                     [ngModelOptions]="{standalone: true}"
                     [textMask]="{mask: InputMask.NATURAL_FLOAT_WITH_THOUSAND_SEPARATOR, guide: false}">
              <!--Appears in detailView-->
              <label
                *ngIf="componentState.isReadonly()" class="form-control detail-description"
                readonly>{{detailModel.numberOfSeats}}
              </label>
            </div>
          </div>
          <!--endregion numberOfSeats-->

          <!--region color-->
          <div class="form-group row">
            <label class="col-form-label form-control-label col-md-4 detail-title">{{'VEHICLE_COLOR' | translate}}</label>
            <div class="col-md-8">
              <input *ngIf="componentState.isEditable()"
                     type="text" class="form-control"
                     maxlength="10"
                     placeholder="{{'VEHICLE_COLOR' | translate}}"
                     [(ngModel)]="editModel.color"
                     [ngModelOptions]="{standalone: true}">
              <!--Appears in detailView-->
              <label
                *ngIf="componentState.isReadonly()" class="form-control detail-description"
                readonly>{{detailModel.color}}
              </label>
            </div>
          </div>
          <!--endregion color-->

            <!--region comment-->
            <div class="form-group row"
            *ngIf="componentState.isEditable() || componentState.isDetailView() &&  detailModel.comment">
              <label class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_COMMENT' |
                translate}}</label>
              <div class="col-md-8">
                <textarea *ngIf="componentState.isEditable()"
                          type="text" class="form-control"
                          placeholder="{{'COMMON_COMMENT' | translate}}"
                          [(ngModel)]="editModel.comment"
                          rows="{{UiConstants.textareaDescriptionRowsNumber}}"
                          name="comment"
                          id="comment">
                </textarea>
                <!--Appears in detailView-->
                <label
                  *ngIf="componentState.isReadonly()" class="form-control detail-description"
                  readonly>{{detailModel.comment}}
                </label>
              </div>
            </div>
            <!--endregion comment-->

            <!--region etollRequired-->
            <div class="form-group row">
              <label class="col-form-label form-control-label col-md-4 detail-title">{{'VEHICLE_E_TOLL_REQUIRED' |
                translate}}</label>
              <div class="col-md-8">
                <label *ngIf="componentState.isEditable()"
                       class="switch switch-text switch-info mb-0">
                  <input type="checkbox" class="switch-input" [(ngModel)]="editModel.etollRequired" [ngModelOptions]="{standalone: true}">
                  <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                  <span class="switch-handle"></span>
                </label>
                <!--Appears in detailView-->
                <label
                  *ngIf="componentState.isReadonly()" class="form-control detail-description"
                  readonly>{{detailModel.etollRequiredText | translate}}
                </label>
              </div>
            </div>
            <!--endregion etollRequired-->

            <!--region registrationType-->
            <div class="form-group row">
              <label class="col-form-label form-control-label col-md-4 detail-title">
                {{'VEHICLE_REGISTRATION_TYPE' | translate}}
              </label>
              <div class="col-md-8">
                <input *ngIf="componentState.isEditable()"
                       type="text" class="form-control"
                       maxlength="{{UiConstants.maximumVarcharLength}}"
                       placeholder="{{'VEHICLE_REGISTRATION_TYPE' | translate}}"
                       [(ngModel)]="editModel.registrationType"
                       name="registrationType"
                       id="registrationType"
                       #registrationType="ngModel">
                <!--Appears in detailView-->
                <label
                  *ngIf="componentState.isReadonly()" class="form-control detail-description"
                  readonly>{{detailModel.registrationType}}
                </label>
              </div>
            </div>
            <!--endregion registrationType-->

            <!--region ownerPersonName-->
            <div class="form-group row">
              <label class="col-form-label form-control-label col-md-4 detail-title">
                {{'VEHICLE_OWNER_PERSON_NAME' | translate}}
              </label>
              <div class="col-md-8">
                <input *ngIf="componentState.isEditable()"
                       type="text" class="form-control"
                       maxlength="{{UiConstants.maximumVarcharLength}}"
                       placeholder="{{'VEHICLE_OWNER_PERSON_NAME' | translate}}"
                       [(ngModel)]="editModel.ownerPersonName"
                       name="ownerPersonName"
                       id="ownerPersonName"
                       #ownerPersonName="ngModel">
                <!--Appears in detailView-->
                <label
                  *ngIf="componentState.isReadonly()" class="form-control detail-description"
                  readonly>{{detailModel.ownerPersonName}}
                </label>
              </div>
            </div>
            <!--endregion ownerPersonName-->

            <!--region keeperPersonName-->
            <div class="form-group row"
                 *ngIf="componentState.isEditable() || componentState.isDetailView() &&  detailModel.keeperPersonName">
              <label class="col-form-label form-control-label col-md-4 detail-title">{{'VEHICLE_KEEPER_PERSON_NAME' |
                translate}}</label>
              <div class="col-md-8">
                <input *ngIf="componentState.isEditable()"
                       type="text" class="form-control"
                       maxlength="{{UiConstants.maximumVarcharLength}}"
                       placeholder="{{'VEHICLE_KEEPER_PERSON_NAME' | translate}}"
                       [(ngModel)]="editModel.keeperPersonName"
                       name="keeperPersonName"
                       id="keeperPersonName">
                <!--Appears in detailView-->
                <label
                  *ngIf="componentState.isReadonly()" class="form-control detail-description"
                  readonly>{{detailModel.keeperPersonName}}
                </label>
              </div>
            </div>
            <!--endregion keeperPersonName-->

            <!--region maturityExpiryDate-->
            <div class="form-group row"
                 *ngIf="componentState.isEditable() || componentState.isDetailView() &&  detailModel.maturityExpiryDate">
              <label class="col-form-label form-control-label col-md-4 detail-title">{{'VEHICLE_MATURITY_EXPIRY_DATE' |
                translate}}</label>
              <div class="col-md-8">
                <div class="input-group" *ngIf="componentState.isEditable()">
                  <input
                    type="text"
                    class="form-control date"
                    placeholder="{{'VEHICLE_MATURITY_EXPIRY_DATE' | translate}}"
                    [(ngModel)]="editModel.maturityExpiryDate"
                    ngbDatepicker
                    name="maturityExpiryDateModel"
                    id="maturityExpiryDateModel"
                    #maturityExpiryDate="ngbDatepicker"
                    #maturityExpiryDateModel="ngModel">
                  <div class="input-group-addon-gray"
                       (click)="maturityExpiryDate.toggle()">
                    <i class="icomoon icomoon-calendar"></i>
                  </div>
                </div>
                <div class="form-control-feedback"
                     *ngIf="hasLocalFieldError(maturityExpiryDate)">{{'FORM_FIELD_VALIDATION_DATE_INVALID' | translate}}
                </div>
                <!--Appears in detailView-->
                <label
                  *ngIf="componentState.isReadonly()" class="form-control detail-description"
                  readonly>{{detailModel.maturityExpiryDate}}
                </label>
              </div>
            </div>
            <!--endregion maturityExpiryDate-->

            <!--region company-->
            <div class="form-group row"
                 [ngClass]="{ 'has-danger': hasLocalFieldError(company) || hasFieldError(Vehicle.ValidatedField.COMPANY_ID) }">
              <label class="col-form-label form-control-label col-md-4 detail-title">
                {{'COMMON_COMPANY' | translate}}
              </label>
              <div class="col-md-8">
                <angular2-multiselect
                  [(ngModel)]="relevantModel.company"
                  (ngModelChange)="removeFieldError(Vehicle.ValidatedField.COMPANY_ID)"
                  (onRemoteSearch)="loadCompanies($event.target.value)"
                  [data]="companies"
                  [settings]="dropdownSettings"
                  [readonly]="componentState.isReadonly()"
                  id="company"
                  name="company"
                  #company="ngModel"
                  validateEnabledItems>
                </angular2-multiselect>
                <div class="form-control-feedback"
                     *ngIf="hasLocalFieldError(company)">
                  <span *ngIf="company.errors['hasDisabledItem']">{{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}</span>
                </div>
                <div class="form-control-feedback"
                     *ngIf="hasFieldError(Vehicle.ValidatedField.COMPANY_ID)">{{getFieldErrorText(Vehicle.ValidatedField.COMPANY_ID)}}</div>
              </div>
            </div>
            <!--endregion company-->


          <!--Only visible on create/edit views-->
            <div class="col-md-12 d-flex justify-content-end p-0" *ngIf="componentState.isEditable()">
              <div>
                <input type="button" class="btn btn-secondary" (click)="back()"
                       value="{{'COMMON_BUTTON_CANCEL'|translate}}"/>
                <input type="submit" class="btn btn-primary ml-1" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
              </div>
            </div>
        </div>
      </div>
    </div>
    <!-- endregion Basic Details-->

    <div class="container-vertical-padding"></div>

    <!--region Passes-->
    <div class="col-xs-12 col-sm-12 col-md-10 card-custom-padding">
      <div class="card">
        <div class="card-header">
          {{'VEHICLE_HEADER_PASSES' | translate}}
          <div class="card-actions" *ngIf="!componentState.isDetailView()">
            <a class="btn-setting cursor-pointer" (click)="openPassCreate()" title="{{'COMMON_BUTTON_ADD' | translate}}"><i class="icomoon icomoon-add title"></i></a>
          </div>
        </div>
        <div class="card-body">
          <table class="table table-striped table-bordered">
            <thead>
            <tr>
              <th class="table-sorter-header">
                <app-table-sorter-no-op [text]="'VEHICLE_PASS_TYPE' | translate"></app-table-sorter-no-op>
              </th>
              <th class="table-sorter-header">
                <app-table-sorter-no-op [text]="'VEHICLE_PASS_NAME' | translate"></app-table-sorter-no-op>
              </th>
              <th class="table-sorter-header hidden-xs-down">
                <app-table-sorter-no-op [text]="'VEHICLE_PASS_VALIDITY_DATE_FROM' | translate"></app-table-sorter-no-op>
              </th>
              <th class="table-sorter-header hidden-xs-down">
                <app-table-sorter-no-op [text]="'VEHICLE_PASS_VALIDITY_DATE_TO' | translate"></app-table-sorter-no-op>
              </th>
              <th class="table-sorter-header w-table-actions">
                <app-table-sorter-no-op [text]="''"></app-table-sorter-no-op>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let pass of componentState.isDetailView() ? detailModel.passes : editModel.passes; let i = index">
              <td class="responsive-table-column">{{getPassType(pass.type)}}</td>
              <td class="responsive-table-column">{{pass.name}}</td>
              <td class="responsive-table-column hidden-xs-down">{{datePickerParserFormatter.format(pass.validityStartDate)}}</td>
              <td class="responsive-table-column hidden-xs-down">{{datePickerParserFormatter.format(pass.validityEndDate)}}</td>
              <td class="responsive-table-column">
            <span class="text-nowrap">
              <a class="icons-table-group cursor-pointer" (click)="openPassDetail(i)">
                <i class="icomoon icons-table-item icomoon-detail icomoon-modify-table"
                   title="{{'COMMON_BUTTON_DETAILS' | translate}}"></i>
              </a>
              <a class="icons-table-group cursor-pointer" (click)="openPassEdit(i)" *ngIf="componentState.isEditable()">
                <i class="icomoon icons-table-item icomoon-modify icomoon-modify-table"
                   title="{{'COMMON_BUTTON_DETAILS' | translate}}"></i>
              </a>
              <a class="icons-table-group cursor-pointer" (click)="deletePass(i)" *ngIf="componentState.isEditable()">
                <i class="icomoon icons-table-item icomoon-trash icomoon-modify-table"
                   title="{{'COMMON_BUTTON_DETAILS' | translate}}"></i>
              </a>
            </span>
              </td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
              <th class="table-sorter-header">
                <app-table-sorter-no-op [text]="'VEHICLE_PASS_TYPE' | translate"></app-table-sorter-no-op>
              </th>
              <th class="table-sorter-header">
                <app-table-sorter-no-op [text]="'VEHICLE_PASS_NAME' | translate"></app-table-sorter-no-op>
              </th>
              <th class="table-sorter-header hidden-xs-down">
                <app-table-sorter-no-op [text]="'VEHICLE_PASS_VALIDITY_DATE_FROM' | translate"></app-table-sorter-no-op>
              </th>
              <th class="table-sorter-header hidden-xs-down">
                <app-table-sorter-no-op [text]="'VEHICLE_PASS_VALIDITY_DATE_TO' | translate"></app-table-sorter-no-op>
              </th>
              <th class="table-sorter-header w-table-actions">
                <app-table-sorter-no-op [text]="''"></app-table-sorter-no-op>
              </th>
            </tr>
            </tfoot>
          </table>
          <!--Only visible on create/edit views-->
          <div class="col-md-12 d-flex justify-content-end p-0" *ngIf="componentState.isEditable()">
            <div>
              <input type="button" class="btn btn-secondary" (click)="back()"
                     value="{{'COMMON_BUTTON_CANCEL'|translate}}"/>
              <input type="submit" class="btn btn-primary ml-1" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- endregion Passes-->

    </form>


  </div>
  <!-- /.form-group row -->


</div>

</responsive-tab>
<responsive-tab *ngIf="rightModel.vehicleHistoryLog.hasRight() && componentState.isDetailView()"
       heading="{{'COMMON_HISTORY'|translate}}">
  <app-history-log
    [queryModel]="historyQueryModel"
    [Type]="Vehicle"
    [historyList]="historyList"
    (openHistoryDetail)="historyDetailModal.openDetailDialog($event)"
    (itemsPerPageChangedOut)="itemsPerPageChanged($event)"
    (orderByOut)="orderBy($event)"
    (pageChangedOut)="pageChanged($event)"
    #historyComponent>
  </app-history-log>
</responsive-tab>
</responsive-tabset>
</div>

<div bsModal [config]="UiConstants.modalConfig" #vehiclePassDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="vehiclePassDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" *ngIf="vehiclePassDialogReadonly === undefined">{{'VEHICLE_HEADER_PASS_ADD' | translate}}</h4>
        <h4 class="modal-title" *ngIf="vehiclePassDialogReadonly === false">{{'VEHICLE_HEADER_PASS_EDIT' | translate}}</h4>
        <h4 class="modal-title" *ngIf="vehiclePassDialogReadonly === true">{{'VEHICLE_HEADER_PASS_DETAIL' | translate}}</h4>
        <button type="button" class="close" (click)="closeVehiclePassDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" [formGroup]="vehiclePassFormGroup">

        <div class="form-group row"
             [ngClass]="{ 'has-danger': vehiclePassFormGroup.controls['type'].touched && !vehiclePassFormGroup.controls['type'].valid}">
          <label class="col-form-label col-md-4 required-field-label">
            {{'VEHICLE_PASS_TYPE' | translate}}
          </label>
          <div class="col-md-8" *ngIf="!vehiclePassDialogReadonly">
            <select class="form-control" formControlName="type"
                    [(ngModel)]="selectedVehiclePass.type"
                    [ngClass]="{ 'form-control-danger': vehiclePassFormGroup.controls['type'].touched && !vehiclePassFormGroup.controls['type'].valid }">
              <option disabled hidden [value]="null">{{'COMMON_VALUE_PLEASE_SELECT' | translate}}</option>
              <option *ngFor="let item of passTypes" [ngValue]="item.id">{{item.text}}</option>
            </select>
            <div class="form-control-feedback"
                 *ngIf="vehiclePassFormGroup.controls['type'].touched && vehiclePassFormGroup.controls['type'].hasError('required')">
              {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
            </div>
          </div>
          <label class="col-form-label col-md-8 detail-description" *ngIf="vehiclePassDialogReadonly">
            {{getPassType(selectedVehiclePass.type)}}
          </label>
        </div>

        <div class="form-group row"
             [ngClass]="{ 'has-danger': vehiclePassFormGroup.controls['name'].touched && !vehiclePassFormGroup.controls['name'].valid }">
          <label class="col-form-label col-md-4 required-field-label">
            {{'VEHICLE_PASS_NAME' | translate}}
          </label>
          <div class="col-md-8" *ngIf="!vehiclePassDialogReadonly">
            <input placeholder="{{'VEHICLE_PASS_NAME' | translate}}" type="text" class="form-control"
                   formControlName="name"
                   [(ngModel)]="selectedVehiclePass.name"
                   [ngClass]="{ 'form-control-danger': vehiclePassFormGroup.controls['name'].touched && !vehiclePassFormGroup.controls['name'].valid }"
                   [maxlength] = "UiConstants.maximumVarcharLength">
            <div class="form-control-feedback"
                 *ngIf="vehiclePassFormGroup.controls['name'].touched && vehiclePassFormGroup.controls['name'].hasError('required')">
              {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
            </div>
          </div>
          <label class="col-form-label col-md-8 detail-description" *ngIf="vehiclePassDialogReadonly">
            {{selectedVehiclePass.name}}
          </label>
        </div>

        <div class="form-group row"
             [ngClass]="{ 'has-danger': vehiclePassFormGroup.controls['validityStartDate'].touched && !vehiclePassFormGroup.controls['validityStartDate'].valid }">
          <label class="col-form-label col-md-4 required-field-label">
            {{'VEHICLE_PASS_VALIDITY_DATE_FROM' | translate}}
          </label>
          <div class="col-md-8" *ngIf="!vehiclePassDialogReadonly">
            <div class="input-group">
              <input type="text" class="form-control date" placeholder="{{'VEHICLE_PASS_VALIDITY_DATE_FROM' | translate}}"
                     formControlName="validityStartDate"
                     [(ngModel)]="selectedVehiclePass.validityStartDate"
                     [ngClass]="{ 'form-control-danger': vehiclePassFormGroup.controls['validityStartDate'].touched && !vehiclePassFormGroup.controls['validityStartDate'].valid }"
                     ngbDatepicker #validityStartDate="ngbDatepicker">
              <div class="input-group-addon-gray" (click)="validityStartDate.toggle()">
                <i class="icomoon icomoon-calendar"></i>
              </div>
            </div>
            <div class="form-control-feedback"
                 *ngIf="vehiclePassFormGroup.controls['validityStartDate'].touched && vehiclePassFormGroup.controls['validityStartDate'].hasError('required')">
              {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
            </div>
          </div>
          <label class="col-form-label col-md-8 detail-description" *ngIf="vehiclePassDialogReadonly">
            {{datePickerParserFormatter.format(selectedVehiclePass.validityStartDate)}}
          </label>
        </div>

        <div class="form-group row"
             [ngClass]="{ 'has-danger': vehiclePassFormGroup.controls['validityEndDate'].touched && !vehiclePassFormGroup.controls['validityEndDate'].valid }">
          <label class="col-form-label col-md-4 required-field-label">
            {{'VEHICLE_PASS_VALIDITY_DATE_TO' | translate}}
          </label>
          <div class="col-md-8" *ngIf="!vehiclePassDialogReadonly">
            <div class="input-group">
              <input type="text" class="form-control date" placeholder="{{'VEHICLE_PASS_VALIDITY_DATE_TO' | translate}}"
                     formControlName="validityEndDate"
                     [(ngModel)]="selectedVehiclePass.validityEndDate"
                     [ngClass]="{ 'form-control-danger': vehiclePassFormGroup.controls['validityEndDate'].touched && !vehiclePassFormGroup.controls['validityEndDate'].valid }"
                     ngbDatepicker #validityEndDate="ngbDatepicker">
              <div class="input-group-addon-gray" (click)="validityEndDate.toggle()">
                <i class="icomoon icomoon-calendar"></i>
              </div>
            </div>
            <div class="form-control-feedback"
                 *ngIf="vehiclePassFormGroup.controls['validityEndDate'].touched && vehiclePassFormGroup.controls['validityEndDate'].hasError('required')">
              {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
            </div>
          </div>
          <label class="col-form-label col-md-8 detail-description" *ngIf="vehiclePassDialogReadonly">
            {{datePickerParserFormatter.format(selectedVehiclePass.validityEndDate)}}
          </label>
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-4">{{'VEHICLE_PASS_NOTE' | translate}}</label>
          <div class="col-md-8" *ngIf="!vehiclePassDialogReadonly">
            <input placeholder="{{'VEHICLE_PASS_NOTE' | translate}}" type="text" class="form-control"
                   [(ngModel)]="selectedVehiclePass.note"
                   [ngModelOptions]="{standalone: true}"
                   [maxlength] = "UiConstants.maximumVarcharLength">
          </div>
          <label class="col-form-label col-md-8 detail-description" *ngIf="vehiclePassDialogReadonly">
            {{selectedVehiclePass.note}}
          </label>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeVehiclePassDialog()" *ngIf="vehiclePassDialogReadonly">
          {{'COMMON_BUTTON_CLOSE' | translate}}
        </button>
        <button type="button" class="btn btn-secondary" (click)="closeVehiclePassDialog()" *ngIf="!vehiclePassDialogReadonly">
          {{'COMMON_BUTTON_CANCEL' | translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="savePass()" *ngIf="!vehiclePassDialogReadonly">
          {{'COMMON_BUTTON_SAVE' | translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<app-history-detail-dialog
  #historyDetailModal>
</app-history-detail-dialog>
