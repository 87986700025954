<div class="form-group" [ngClass]="{'multiselect-fixed-width': !disableDelete}">
  <div class="d-inline">
    <label class="d-flex justify-content-between">
      {{expression.criteria.field!.title + ' ' + expression.criteria.operation!.operation}}
      <i class="icomoon icomoon-exit align-self-center" *ngIf="!disableDelete" (click)="onDelete.emit(expression)"></i>
    </label>
  </div>
  <angular2-multiselect
    (onSelect)="onItemSelect($event)"
    (onDeSelect)="onItemDeselect($event)"
    [(ngModel)]="selectedValue"
    [data]="items"
    [settings]="dropdownSettings"
    onchange="">
  </angular2-multiselect>
</div>
