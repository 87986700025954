/* eslint-disable */
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DqlSearchFieldComponent } from '../dql-search-field.component';
import {
  ComplexDqlExpressionBuilder,
  DqlBinaryCriteriaModel,
  DqlCriteriaModel,
  DqlExpressionBuilder, DqlLogicalOperation, DqlLogicalOperationObject,
  DqlOperations, expressionType, SimpleDqlExpressionBuilder
} from '../../dql-search-container/dql-search-container.model';
import { DqlSearchFieldDateComponent } from '../dql-search-field-date/dql-search-field-date.component';
/* eslint-enable */

@Component({
  selector: 'app-dql-search-field-date-range',
  templateUrl: './dql-search-field-date-range.component.html',
  styleUrls: ['./dql-search-field-date-range.component.scss']
})
export class DqlSearchFieldDateRangeComponent implements OnInit, DqlSearchFieldComponent {

  @Output()
  onDelete: EventEmitter<DqlExpressionBuilder> = new EventEmitter<DqlExpressionBuilder>();

  private _expr: ComplexDqlExpressionBuilder;

  criteriaTemplate: DqlCriteriaModel;

  @Input()
  set expression(exp: DqlExpressionBuilder) {
    if (exp instanceof SimpleDqlExpressionBuilder) {
      this.criteriaTemplate = exp.criteria;
      this._expr = new ComplexDqlExpressionBuilder();
    }
    if (exp instanceof ComplexDqlExpressionBuilder) {
      this._expr = exp;
      this.criteriaTemplate = (<SimpleDqlExpressionBuilder>this._expr.expressionList[0]).criteria;
    }
    this._expr.closeExpression();
  }

  get expression(): DqlExpressionBuilder {
    return this._expr;
  }

  @Input()
  disableDelete: boolean = false;

  @ViewChild('from', { static: true })
  fromSearchField: DqlSearchFieldDateComponent;

  @ViewChild('to', { static: true })
  toSearchField: DqlSearchFieldDateComponent;

  fromCriteria: SimpleDqlExpressionBuilder;
  toCriteria: SimpleDqlExpressionBuilder;


  constructor() {
  }

  ngOnInit() {
    if (this._expr.expressionCount > 0) {
      const fromExpr = (<SimpleDqlExpressionBuilder>this._expr.expressionList
        .find(e => e.expressionType() === expressionType.SIMPLE &&
          (<SimpleDqlExpressionBuilder>e).criteria.operation === DqlOperations.GREATER_THAN_EQUALS));
      if (fromExpr) {
        this.fromCriteria = fromExpr;
      }
      const toExpr = (<SimpleDqlExpressionBuilder>this._expr.expressionList
        .find(e => e.expressionType() === expressionType.SIMPLE &&
          (<SimpleDqlExpressionBuilder>e).criteria.operation === DqlOperations.LESS_THAN_EQUALS));
      if (toExpr) {
        this.toCriteria = toExpr;
      }
    }
    if (!this.fromCriteria) {
      this.fromCriteria = new SimpleDqlExpressionBuilder();
      const crit = new DqlBinaryCriteriaModel();
      crit.field = this.criteriaTemplate.field;
      crit.operation = DqlOperations.GREATER_THAN_EQUALS;
      crit.value = '';
      this.fromCriteria.addCriteria(crit);
      this.fromCriteria.closeExpression();
      this._expr.addExpression(this.fromCriteria);
    }
    if (!this.toCriteria) {
      this.toCriteria = new SimpleDqlExpressionBuilder();
      const crit = new DqlBinaryCriteriaModel();
      crit.field = this.criteriaTemplate.field;
      crit.operation = DqlOperations.LESS_THAN_EQUALS;
      crit.value = '';
      this.toCriteria.addCriteria(crit);
      this.toCriteria.closeExpression();
      this._expr.addExpression(this.toCriteria);
    }
    this._expr.addLogical(new DqlLogicalOperationObject(DqlLogicalOperation.AND));
  }
}
