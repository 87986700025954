<app-file-attachment-gallery
  *ngIf="attachments.length > 0"
  [loaderFactory]="fileLoaderFactory"
  [fileAttachments]="attachments"
  (openImageViewerWindowEvent)="openImageViewerWindow()"
  (clickedImageEventEmitter)="clickedImageEventEmitted($event)">
</app-file-attachment-gallery>
<div class="p-2 text-center text-muted"
     *ngIf="attachments.length === 0">
  <h6>{{ "PROCESS_TASK_DETAIL_NO_ATTACHMENT" | translate }}</h6>
</div>
