<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div class="breadcrumb-menu d-flex">

    <!--IMPORT-->
    <div class="breadcrumb-button-icon-container cursor-pointer"
         href="#" onclick="return false"
         (click)="importDialog.toggleDialog()">
      <a class="btn-setting d-flex align-items-center" title="{{'COMMON_BUTTON_IMPORT' | translate}}">
        <i class="icomoon icomoon-csv-import"></i>
        <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_IMPORT' | translate}}</span>
      </a>
    </div>



    <!--EXPORT-->
    <div class="breadcrumb-button-icon-container cursor-pointer"
         (click)="exportXls()">
      <a class="btn-setting d-flex align-items-center" title="{{'COMMON_BUTTON_EXPORT' | translate}}">
        <i class="icomoon icomoon-csv-export"></i>
        <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_EXPORT' | translate}}</span>
      </a>
    </div>

    <!--CREATE-->
    <div *ngIf="rightModel.listItemTypeCreate.hasRight()"
         class="breadcrumb-button-icon-container cursor-pointer negative-margin-right"
         uiSref="Admin.ListItemTypeCreate">
      <a class="btn-setting d-flex align-items-center" title="{{'COMMON_BUTTON_ADD' | translate}}">
        <i class="icomoon icomoon-add"></i>
        <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_ADD' | translate}}</span>
      </a>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="card">

    <div class="card-header">
      {{'LIST_ITEM_TYPE_PANEL_HEADING_LIST' | translate}}

      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()"
           title="{{'COMMON_SHOW_SEARCH_HINT' | translate}}"><i #searchIcon class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>

    <div class="card-body border-bottom" *ngIf="showSearch">
      <div class="row form-group">

        <div class="col">
          <label class="search-label">{{'LIST_ITEM_SEARCH_TYPE_KEY' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control" placeholder="{{'LIST_ITEM_SEARCH_TYPE_KEY' | translate}}"
                   maxlength="{{UiConstants.maximumVarcharLength}}"
                   (keyup.enter)="onSearchClicked()"
                   [(ngModel)]="searchModel.key" name="key" id="key" #key="ngModel">
          </div>
        </div>
        <div class="col">
          <label class="search-label"> {{ 'COMMON_STATE' | translate }} </label>
          <select
            class="form-control"
            [(ngModel)]="searchModel.disabled" [compareWith]="SelectUtils.compareObjects">
            <option *ngFor="let item of disabledItems" [ngValue]="item">{{item.text}}</option>
          </select>
        </div>
      </div>
      <div class="col-md-12 d-flex justify-content-md-end align-items-end">
        <div class="btn-group" role="group">
          <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
            {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
          </button>
          <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
            {{'COMMON_BUTTON_SEARCH' | translate}}
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">

      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header w-5 hidden-xs-down">
            <app-table-field-sorter [orderField]="ListItemList.TypeOrderField.ID"
                                    [orderType]="queryModel.getOrderType(ListItemList.TypeOrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-field-sorter [orderField]="ListItemList.TypeOrderField.KEY"
                                    [orderType]="queryModel.getOrderType(ListItemList.TypeOrderField.KEY)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'LISTITEM_TABLE_HEADER_CODE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-table-actions">
            <app-table-sorter-no-op [text]="'COMMON_TABLE_HEADER_ACTIONS' | translate"></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let listItemType of litItemTypeList | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }"
            [disabledTableRow]="listItemType.disabled">

          <td class="responsive-table-column">{{listItemType.id}}</td>
          <td class="responsive-table-column">{{listItemType.key}}</td>
          <td class="responsive-table-column">
            <span class="text-nowrap">
            <a class="icons-table-group" href="#" onclick="return false"
               (click)="setDisabled($event, listItemType, true)"
               *ngIf="rightModel.listItemTypeDisable.hasRight() && !listItemType.disabled">
              <i class="icomoon icons-table-item icomoon-disable icomoon-disable-table"
                 title="{{'COMMON_BUTTON_DISABLE' | translate}}"></i>
            </a>
            <a class="icons-table-group" href="#" onclick="return false"
               (click)="rightModel.listItemTypeDisable.hasRight() && setDisabled($event, listItemType, false)"
               *ngIf="listItemType.disabled">
              <i class="icomoon icons-table-item icomoon-enable icomoon-enable-table"
                 title="{{'COMMON_BUTTON_ENABLE' | translate}}"></i>
            </a>
              <a *ngIf="rightModel.listItemTypeUpdate.hasRight()" class="icons-table-group"
                 uiSref="Admin.ListItemTypeEdit" [uiParams]="{ id: listItemType.id }">
                <i class="icomoon icons-table-item icomoon-modify icomoon-modify-table"
                   title="{{'COMMON_BUTTON_DETAILS' | translate}}"></i>
              </a>
            </span>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header w-5 hidden-xs-down">
            <app-table-field-sorter [orderField]="ListItemList.TypeOrderField.ID"
                                    [orderType]="queryModel.getOrderType(ListItemList.TypeOrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-field-sorter [orderField]="ListItemList.TypeOrderField.KEY"
                                    [orderType]="queryModel.getOrderType(ListItemList.TypeOrderField.KEY)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'LISTITEM_TABLE_HEADER_CODE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-table-actions">
            <app-table-sorter-no-op [text]="'COMMON_TABLE_HEADER_ACTIONS' | translate"></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>
  </div>
</div>

<app-file-upload-dialog
  [uploadPath]="uploadPath"
  (onResult)="onImportSuccess($event)"
  #importDialog>

</app-file-upload-dialog>
