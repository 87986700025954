/* eslint-disable */
import { DqlResourceModel } from './dql-resource.model';
import { Map } from 'immutable';
/* eslint-enable */

export namespace DqlModel {

  export interface QueryableModel {
    staticFields: StaticField[];
    dynamicFields: DynamicField[];
    hasMultipleModels: boolean;
  }

  export interface StaticField {
    dataType: QueryableFieldDataType;
    fieldTitle: string;
    values?: string[];
  }

  export interface DynamicField {
    fieldId: number;
    fieldTitle: string;
    parentTitle?: string;
    dataType: QueryableFieldDataType;
    queryableModel?: QueryableModel;
    listItemTypeId?: number;
  }

  export enum QueryableFieldDataType {
    UNKNOWN = '@UNKNOWN',
    STRING = '@STRING',
    NUMBER = '@NUMBER',
    DECIMAL = '@DECIMAL',
    DATE = '@DATE',
    DATE_TIME = '@DATE_TIME',
    BOOLEAN = '@BOOLEAN',
    LIST_ITEM = '@LIST_ITEM',
    LIST_MULTI_ITEM = '@LIST_MULTI_ITEM',
    EMAIL_ADDRESS = '@EMAIL_ADDRESS',
    PHONE_NUMBER = '@PHONE_NUMBER',
    MASTER_DATA = '@MASTER_DATA',
    STOCK = '@STOCK',
    READONLY_TEXT = '@READONLY_TEXT',
    READONLY_HTML = '@READONLY_HTML',
    FORM_TABLE = '@FORM_TABLE',
    PAYMENT_TYPE = '@PAYMENT_TYPE',
    ENUM = '@ENUM',
    USER = '@USER',
    USER_GROUP = '@USER_GROUP',
    MOBILE_APP = '@MOBILE_APP',
    CUSTOMER = '@CUSTOMER',
    CONTACT_LOCATION = '@CONTACT_LOCATION',
    COMPANY = '@COMPANY',
    COMPANY_LOCATION = '@COMPANY_LOCATION',
    PROCESS = '@PROCESS',
    CONTRACT_NUMBER = '@CONTRACT_NUMBER'

  }

  export class DqlQueryableModelConverter {

    private static readonly keyFieldDataTypeSelectorMap: Map<DqlResourceModel.QueryableFieldDataType, QueryableFieldDataType> = Map.of(
      'STRING', QueryableFieldDataType.STRING,
      'NUMBER', QueryableFieldDataType.NUMBER,
      'DECIMAL', QueryableFieldDataType.DECIMAL,
      'DATE', QueryableFieldDataType.DATE,
      'DATE_TIME', QueryableFieldDataType.DATE_TIME,
      'BOOLEAN', QueryableFieldDataType.BOOLEAN,
      'LIST_ITEM', QueryableFieldDataType.LIST_ITEM,
      'EMAIL_ADDRESS', QueryableFieldDataType.EMAIL_ADDRESS,
      'PHONE_NUMBER', QueryableFieldDataType.PHONE_NUMBER,
      'MASTER_DATA', QueryableFieldDataType.MASTER_DATA,
      'LIST_MULTI_ITEM', QueryableFieldDataType.LIST_MULTI_ITEM,
      'READONLY_TEXT', QueryableFieldDataType.READONLY_TEXT,
      'READONLY_HTML', QueryableFieldDataType.READONLY_HTML,
      'STOCK', QueryableFieldDataType.STOCK,
      'FORM_TABLE', QueryableFieldDataType.FORM_TABLE,
      'ENUM', QueryableFieldDataType.ENUM,
      'PAYMENT_TYPE', QueryableFieldDataType.PAYMENT_TYPE,
      'USER', QueryableFieldDataType.USER,
      'USER_GROUP', QueryableFieldDataType.USER_GROUP,
      'MOBILE_APP', QueryableFieldDataType.MOBILE_APP,
      'CUSTOMER', QueryableFieldDataType.CUSTOMER,
      'CONTACT_LOCATION', QueryableFieldDataType.CONTACT_LOCATION,
      'COMPANY', QueryableFieldDataType.COMPANY,
      'COMPANY_LOCATION', QueryableFieldDataType.COMPANY_LOCATION,
      'PROCESS', QueryableFieldDataType.PROCESS,
      'CONTRACT_NUMBER', QueryableFieldDataType.CONTRACT_NUMBER,
    );

    constructor(private multipleModels: boolean) {

    }

    public toPublic(resource: DqlResourceModel.QueryableModel): DqlModel.QueryableModel {
      return {
        staticFields: resource.static_fields.map((f: DqlResourceModel.StaticField): DqlModel.StaticField => {
          return this.toPublicStaticField(f);
        }),
        dynamicFields: resource.dynamic_fields.map((f: DqlResourceModel.DynamicField): DqlModel.DynamicField => {
          return this.toPublicDynamicField(f);
        }),
        hasMultipleModels: this.multipleModels
      }
    }

    private _toPublic(resource: DqlResourceModel.QueryableModel): DqlModel.QueryableModel {
      return {
        staticFields: resource.static_fields.map((f: DqlResourceModel.StaticField): DqlModel.StaticField => {
          return this.toPublicStaticField(f);
        }),
        dynamicFields: resource.dynamic_fields.map((f: DqlResourceModel.DynamicField): DqlModel.DynamicField => {
          return this.toPublicDynamicField(f);
        }),
        hasMultipleModels: false
      }
    }

    private toPublicStaticField(resource: DqlResourceModel.StaticField): DqlModel.StaticField {
      return {
        dataType: DqlQueryableModelConverter.keyFieldDataTypeSelectorMap.get(resource.data_type),
        fieldTitle: resource.field_title,
        values: resource.values
      }
    }

    private toPublicDynamicField(resource: DqlResourceModel.DynamicField): DqlModel.DynamicField {
      return {
        fieldId: resource.field_id,
        fieldTitle: resource.field_title,
        parentTitle: resource.parent_title,
        dataType: DqlQueryableModelConverter.keyFieldDataTypeSelectorMap.get(resource.data_type),
        queryableModel: resource.queryable_model ? this._toPublic(resource.queryable_model) : undefined,
        listItemTypeId: resource.list_item_type_id
      }
    }
  }

  export class DqlStaticFieldTextHelper {
    constructor(private fields: Map<string, DqlFieldTexts>) {
    }

    public getStringKey(field: string): string {
      return this.fields.get(field, {stringKey: field}).stringKey;
    }

    public getEnumType(field: string): EnumType[] | undefined {
      return this.fields.get(field, {stringKey: field}).enumType;
    }

    public getKeys(): string[] {
      return this.fields.map(value => value!.stringKey).toArray();
    }
  }

  export interface DqlFieldTexts {
    stringKey: string;
    enumType?: EnumType[];
  }

  export interface EnumType {
    id: string;
    stringKey: string;
  };
}
