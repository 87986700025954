<form (ngSubmit)="submit()" #versionForm="ngForm" id="form"
      [formGroup]="formGroup" novalidate>
  <div class="card" [ngClass]="{'inner-card': innerCard}">
    <div class="card-header" [ngClass]="{'inner-card': innerCard}">
      {{headerKey | translate}}
    </div>
    <div class="card-body" [ngClass]="{'inner-card': innerCard}">
      <div class="form-group row"
           [ngClass]="{ 'has-danger': hasLocalFieldError('versionName') || hasFieldError(AndroidAppVersion.ValidatedField.VERSION_NAME) }">
        <label for="versionName" class="col-form-label form-control-label col-md-4 detail-title"
               [class.required-field-label]="!readonly">
          {{'ANDROID_APP_VERSION_VERSION_NAME'|translate}}
        </label>
        <div class="col-md-6">
          <div class="mw-100">
            <input type="text"
                   [(ngModel)]="model.versionName"
                   maxlength="{{getTextMaximumLength()}}"
                   formControlName="versionName"
                   trim="blur"
                   validateOnBlur
                   [disableControl]="readonly"
                   [validateFormControl]="formGroup.controls['versionName']"
                   (ngModelChange)="removeFieldError(AndroidAppVersion.ValidatedField.VERSION_NAME)"
                   [ngClass]="{ 'form-control-danger': hasLocalFieldError('versionName') || hasFieldError(AndroidAppVersion.ValidatedField.VERSION_NAME) }"
                   class="form-control"
                   name="versionName"
                   id="versionName"
                   placeholder="{{'ANDROID_APP_VERSION_VERSION_NAME'|translate}}">
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('versionName')">
                      <span
                        *ngIf="hasLocalFieldError('versionName', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
            </div>
            <div class="form-control-feedback" *ngIf="hasFieldError(AndroidAppVersion.ValidatedField.VERSION_NAME)">
              <span>{{getFieldErrorText(AndroidAppVersion.ValidatedField.VERSION_NAME)}}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row"
           [ngClass]="{ 'has-danger': hasLocalFieldError('versionCode') || hasFieldError(AndroidAppVersion.ValidatedField.VERSION_CODE) }">
        <label for="versionCode" class="col-form-label form-control-label col-md-4 detail-title"
               [class.required-field-label]="!readonly">
          {{'ANDROID_APP_VERSION_VERSION_CODE'|translate}}
        </label>
        <div class="col-md-6">
          <div class="mw-100">
            <input type="number"
                   [(ngModel)]="model.versionCode"
                   maxlength="{{getNumberMaximumLength()}}"
                   formControlName="versionCode"
                   trim="blur"
                   validateOnBlur
                   [disableControl]="readonly"
                   [validateFormControl]="formGroup.controls['versionCode']"
                   (ngModelChange)="removeFieldError(AndroidAppVersion.ValidatedField.VERSION_CODE)"
                   [ngClass]="{ 'form-control-danger': hasLocalFieldError('versionCode') || hasFieldError(AndroidAppVersion.ValidatedField.VERSION_CODE) }"
                   class="form-control"
                   name="versionCode"
                   id="versionCode"
                   placeholder="{{'ANDROID_APP_VERSION_VERSION_CODE'|translate}}">
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('versionCode')">
                      <span
                        *ngIf="hasLocalFieldError('versionCode', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
            </div>
            <div class="form-control-feedback" *ngIf="hasFieldError(AndroidAppVersion.ValidatedField.VERSION_CODE)">
              <span>{{getFieldErrorText(AndroidAppVersion.ValidatedField.VERSION_CODE)}}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row"
           [ngClass]="{ 'has-danger': hasLocalFieldError('buildSlug') || hasFieldError(AndroidAppVersion.ValidatedField.BUILD_SLUG) }">
        <label for="buildSlug" class="col-form-label form-control-label col-md-4 detail-title"
               [class.required-field-label]="!readonly">
          {{'ANDROID_APP_VERSION_BUILD_SLUG'|translate}}
        </label>
        <div class="col-md-6">
          <div class="mw-100">
            <input type="text"
                   [(ngModel)]="model.buildSlug"
                   maxlength="{{getTextMaximumLength()}}"
                   formControlName="buildSlug"
                   trim="blur"
                   validateOnBlur
                   [disableControl]="readonly"
                   [validateFormControl]="formGroup.controls['buildSlug']"
                   (ngModelChange)="removeFieldError(AndroidAppVersion.ValidatedField.BUILD_SLUG)"
                   [ngClass]="{ 'form-control-danger': hasLocalFieldError('buildSlug') || hasFieldError(AndroidAppVersion.ValidatedField.BUILD_SLUG) }"
                   class="form-control"
                   name="buildSlug"
                   id="buildSlug"
                   placeholder="{{'ANDROID_APP_VERSION_BUILD_SLUG'|translate}}">
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('buildSlug')">
                      <span
                        *ngIf="hasLocalFieldError('buildSlug', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
            </div>
            <div class="form-control-feedback" *ngIf="hasFieldError(AndroidAppVersion.ValidatedField.BUILD_SLUG)">
              <span>{{getFieldErrorText(AndroidAppVersion.ValidatedField.BUILD_SLUG)}}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row"
           [ngClass]="{ 'has-danger': hasLocalFieldError('changeLog') || hasFieldError(AndroidAppVersion.ValidatedField.CHANGE_LOG) || htmlEditorEmpty }">
        <label for="changeLog" class="col-form-label form-control-label col-md-4 detail-title"
               [class.required-field-label]="!readonly">
          {{'ANDROID_APP_VERSION_CHANGE_LOG'|translate}}
        </label>
        <div class="col-md-8">
          <div class="mw-100">
            <quill-editor
              [styles]="{height: '200px'}"
              [modules]="HTMLEditorModules"
              [(ngModel)]="model.changeLog"
              formControlName="changeLog"
              validateOnBlur
              [disableControl]="readonly"
              [validateFormControl]="formGroup.controls['changeLog']"
              (ngModelChange)="htmlEditorEmpty = false; removeFieldError(AndroidAppVersion.ValidatedField.CHANGE_LOG)"
              [ngClass]="{ 'form-control-danger': hasLocalFieldError('changeLog') || hasFieldError(AndroidAppVersion.ValidatedField.CHANGE_LOG) || htmlEditorEmpty }"
              id="changeLog"
              placeholder="{{'ANDROID_APP_VERSION_CHANGE_LOG'|translate}}"></quill-editor>
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('changeLog')">
                      <span
                        *ngIf="hasLocalFieldError('changeLog', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
            </div>
            <div class="form-control-feedback" *ngIf="hasFieldError(AndroidAppVersion.ValidatedField.CHANGE_LOG)">
              <span>{{getFieldErrorText(AndroidAppVersion.ValidatedField.CHANGE_LOG)}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer d-flex justify-content-end" [ngClass]="{'inner-card': innerCard}">
      <input *ngIf="!readonly" type="submit" class="btn btn-primary" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
      <input type="button" *ngIf="readonly" (click)="delete()" class="btn btn-outline-primary mr-05"
             value="{{'COMMON_BUTTON_DELETE'|translate}}"/>
      <input type="button" *ngIf="readonly" (click)="readonly = false;" class="btn btn-primary-applion-green-gray"
             value="{{'COMMON_BUTTON_EDIT'|translate}}"/>
    </div>
  </div>
</form>
