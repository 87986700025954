import { List, Set } from 'immutable';
import { Dql } from './dql';
import { LazyReference } from '../../util/lazy';
import { OffsetDateTime } from '../util/dates';
import { EmailAddress } from '../util/email-address';
import { PhoneNumber } from '../util/phone-number';
import Decimal from 'decimal.js';

export type uuid = string; // We have no uuid data type yet.

export namespace Query {

  export type FilterFunction<T> = (field: T) => Criteria | undefined;

  export type OrderFunction<T> = (field: T) => List<Order>;

  export type FieldFunction<T> = (field: T) => Set<Field>;

  export interface OrderField {

    asc(): Order;

    desc(): Order;

  }

  export interface Order {

    nullsFirst(): Order;

    nullsLast(): Order;

  }

  export interface Criteria {

    not(): Criteria;

    and(right: Criteria): Criteria;

    or(right: Criteria): Criteria;

  }

  export class Criterias {

    public static allOf(list: List<Criteria>): Criteria | undefined {
      if (list.size < 1) {
        return undefined;
      }
      let c: Criteria = list.get(0)!;
      for (let i = 1; i < list.size; i++) {
        c = c.and(list.get(i)!);
      }
      return c;
    }

    public static anyOf(list: List<Criteria>): Criteria | undefined {
      if (list.size < 1) {
        return undefined;
      }
      let c: Criteria = list.get(0)!;
      for (let i = 1; i < list.size; i++) {
        c = c.or(list.get(i)!);
      }
      return c;
    }

    private constructor() {
    }

  }

  export interface Field {
  }

  export interface NullComparableField extends Field {

    isNull(): Criteria;

    isNotNull(): Criteria;

  }

  export interface BooleanField extends NullComparableField, Criteria {

    isTrue(): Criteria;

    isFalse(): Criteria;

  }

  export interface LiteralField<T> extends NullComparableField {

    eq(right: T): Criteria;

    in(right: List<T>): Criteria;

  }

  export interface EnumField<T> extends LiteralField<T> {
  }

  export interface NumberField extends LiteralField<number> {

    gt(right: number): Criteria;

    lt(right: number): Criteria;

    goe(right: number): Criteria;

    loe(right: number): Criteria;

  }

  export interface DecimalField extends LiteralField<Decimal> {

    gt(right: Decimal): Criteria;

    lt(right: Decimal): Criteria;

    goe(right: Decimal): Criteria;

    loe(right: Decimal): Criteria;

  }

  export interface StringField extends LiteralField<string> {

    isEmpty(): Criteria;

    isNotEmpty(): Criteria;

    equalsIgnoreCase(right: string): Criteria;

    containsIgnoreCase(right: string): Criteria;

    startsWith(right: string): Criteria;

    startsWithIgnoreCase(right: string): Criteria;

  }

  export interface DateTimeField extends LiteralField<OffsetDateTime> {

    before(right: OffsetDateTime): Criteria;

    after(right: OffsetDateTime): Criteria;

  }

  export interface EmailAddressField extends LiteralField<EmailAddress> {

    contains(right: string): Criteria;

    startsWith(right: string): Criteria;

  }

  export interface PhoneNumberField extends LiteralField<PhoneNumber> {

    contains(right: string): Criteria;

    startsWith(right: string): Criteria;

  }

  export interface UuidField extends LiteralField<uuid> {
  }

}

export namespace DqlQuery {

  enum DqlOrderType {
    ASC,
    DESC
  }

  enum DqlOrderNullStrategy {
    NULLS_FIRST,
    NULLS_LAST
  }

  export class OrderField implements Query.OrderField {

    constructor(private readonly field: string) {
    }

    asc(): Query.Order {
      return new Order(DqlOrderNullStrategy.NULLS_LAST, DqlOrderType.ASC, this.field);
    }

    desc(): Query.Order {
      return new Order(DqlOrderNullStrategy.NULLS_LAST, DqlOrderType.DESC, this.field);
    }

  }

  export class Order implements Query.Order {

    private readonly fieldLazy: LazyReference<string>;

    public static toDqlString(orderList: List<Query.Order>): string {
      return encodeURIComponent(orderList.map(order => Order.serialize(order!)).join(','));
    }

    private static serialize(order: Query.Order): string {
      return Order.of(order).toString();
    }

    private static of(order: Query.Order): Order {
      if (order instanceof Order) {
        return order;
      }
      throw Error('Not a DQL order');
    }

    constructor(
      private readonly nullStrategy: DqlOrderNullStrategy,
      private readonly type: DqlOrderType,
      private readonly field: string) {
      this.fieldLazy = LazyReference.of(() => this.createDefinition());
    }

    public toString(): string {
      return this.fieldLazy.get();
    }

    public nullsFirst(): Order {
      return new Order(DqlOrderNullStrategy.NULLS_FIRST, this.type, this.field);
    }

    public nullsLast(): Order {
      return new Order(DqlOrderNullStrategy.NULLS_LAST, this.type, this.field);
    }

    private createDefinition(): string {
      let definition = '';
      if (DqlOrderType.DESC === this.type) {
        definition += '-';
      }
      definition += this.field;
      if (DqlOrderNullStrategy.NULLS_FIRST === this.nullStrategy) {
        definition += '-missing_first';
      }
      return definition;
    }

  }

  export class RightRequestSerializer {

    private static readonly instance = new RightRequestSerializer();

    public static getInstance(): RightRequestSerializer {
      return RightRequestSerializer.instance;
    }

    serialize(rights?: Set<string>): string | undefined {
      if (rights === undefined) {
        return undefined;
      }
      if (rights.isEmpty()) {
        return undefined;
      }
      return rights.join(',');
    }

  }

  export class Field implements Query.Field {

    public static toDqlString(fieldSet: Set<Query.Field>): string {
      return fieldSet.map(field => Field.serialize(field!)).join(',');
    }

    private static serialize(field: Query.Field): string {
      return Field.of(field).toString();
    }

    private static of(field: Query.Field): Field {
      if (field instanceof Field) {
        return field;
      }
      throw Error('Not a DQL field');
    }

    constructor(private readonly field: string) {
    }

    public toString(): string {
      return this.field;
    }

  }

  export class Criteria implements Query.Criteria, Dql.StringBuilderVisitorProvider {

    public static toDqlString(criteria: Query.Criteria): string {
      return Criteria.of(criteria).createQuery();
    }

    private static of(criteria: Query.Criteria): Criteria {
      if (criteria instanceof BooleanField) {
        return criteria.isTrue();
      }
      if (criteria instanceof Criteria) {
        return criteria;
      }
      throw Error('Not a DQL expression');
    }

    constructor(private readonly visitor: Dql.StringBuilderVisitor) {
    }

    public createQuery(): string {
      return Dql.StringFactory.create(this);
    }

    public getStringBuilderVisitor(): Dql.StringBuilderVisitor {
      return this.visitor;
    }

    public not(): Criteria {
      return new Criteria(this.visitor.not());
    }

    public and(right: Query.Criteria): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofOp(this.visitor, Dql.Op.AND, this.cast(right).visitor));
    }

    public or(right: Query.Criteria): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofOp(this.visitor, Dql.Op.OR, this.cast(right).visitor));
    }

    private cast(right: Query.Criteria): Criteria {
      return Criteria.of(right);
    }

  }

  export class BooleanField implements Query.BooleanField, Dql.StringBuilderVisitorProvider {

    constructor(private readonly field) {
    }

    public createQuery(): string {
      return Dql.StringFactory.create(this);
    }

    public getStringBuilderVisitor(): Dql.StringBuilderVisitor {
      return this.isTrue().getStringBuilderVisitor();
    }

    public not(): Criteria {
      return this.isFalse();
    }

    public and(right: Query.Criteria): Criteria {
      return this.isTrue().and(right);
    }

    public or(right: Query.Criteria): Criteria {
      return this.isTrue().or(right);
    }

    public isFalse(): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofUnary(this.field, Dql.Methods.IS_FALSE));
    }

    public isNotNull(): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofUnary(this.field, Dql.Methods.IS_NOT_NULL));
    }

    public isNull(): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofUnary(this.field, Dql.Methods.IS_NULL));
    }

    public isTrue(): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofUnary(this.field, Dql.Methods.IS_TRUE));
    }

  }

  export class EnumField<T> implements Query.EnumField<T> {

    constructor(private readonly field: string, private readonly serializer: (T) => string) {
    }

    private serialize(value: T): string {
      return this.serializer(value);
    }

    private serializeList(value: List<T>): List<string> {
      return value.map(d => this.serialize(d!)).toList();
    }

    public isNotNull(): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofUnary(this.field, Dql.Methods.IS_NOT_NULL));
    }

    public isNull(): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofUnary(this.field, Dql.Methods.IS_NULL));
    }

    public eq(right: T): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofString(this.field, Dql.Methods.EQ, this.serialize(right)));
    }

    public in(right: List<T>): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofStringList(this.field, Dql.Methods.IN, this.serializeList(right)));
    }

  }

  export class NumberField implements Query.NumberField {

    constructor(private readonly field: string) {
    }

    public isNotNull(): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofUnary(this.field, Dql.Methods.IS_NOT_NULL));
    }

    public isNull(): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofUnary(this.field, Dql.Methods.IS_NULL));
    }

    public eq(right: number): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofNumber(this.field, Dql.Methods.EQ, right));
    }

    public gt(right: number): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofNumber(this.field, Dql.Methods.GT, right));
    }

    public lt(right: number): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofNumber(this.field, Dql.Methods.LT, right));
    }

    public goe(right: number): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofNumber(this.field, Dql.Methods.GOE, right));
    }

    public loe(right: number): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofNumber(this.field, Dql.Methods.LOE, right));
    }

    public in(right: List<number>): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofNumberList(this.field, Dql.Methods.IN, right));
    }

  }

  export class DecimalField implements Query.DecimalField {

    constructor(private readonly field: string) {
    }

    private serialize(value: Decimal): string {
      return value.toJSON();
    }

    private serializeList(value: List<Decimal>): List<string> {
      return value.map(d => this.serialize(d!)).toList();
    }

    public isNotNull(): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofUnary(this.field, Dql.Methods.IS_NOT_NULL));
    }

    public isNull(): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofUnary(this.field, Dql.Methods.IS_NULL));
    }

    public eq(right: Decimal): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofString(this.field, Dql.Methods.EQ, this.serialize(right)));
    }

    public gt(right: Decimal): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofString(this.field, Dql.Methods.GT, this.serialize(right)));
    }

    public lt(right: Decimal): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofString(this.field, Dql.Methods.LT, this.serialize(right)));
    }

    public goe(right: Decimal): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofString(this.field, Dql.Methods.GOE, this.serialize(right)));
    }

    public loe(right: Decimal): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofString(this.field, Dql.Methods.LOE, this.serialize(right)));
    }

    public in(right: List<Decimal>): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofStringList(this.field, Dql.Methods.IN, this.serializeList(right)));
    }

  }

  export class DateTimeField implements Query.DateTimeField {

    constructor(private readonly field: string) {
    }

    private serialize(value: OffsetDateTime): string {
      return value.toUtcIsoString();
    }

    private serializeList(value: List<OffsetDateTime>): List<string> {
      return value.map(d => this.serialize(d!)).toList();
    }

    public isNotNull(): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofUnary(this.field, Dql.Methods.IS_NOT_NULL));
    }

    public isNull(): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofUnary(this.field, Dql.Methods.IS_NULL));
    }

    public eq(right: OffsetDateTime): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofString(this.field, Dql.Methods.EQ, this.serialize(right)));
    }

    public before(right: OffsetDateTime): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofString(this.field, Dql.Methods.BEFORE, this.serialize(right)));
    }

    public after(right: OffsetDateTime): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofString(this.field, Dql.Methods.AFTER, this.serialize(right)));
    }

    public in(right: List<OffsetDateTime>): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofStringList(this.field, Dql.Methods.IN, this.serializeList(right)));
    }

  }

  export class StringField implements Query.StringField {

    constructor(private readonly field: string) {
    }

    public isNotNull(): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofUnary(this.field, Dql.Methods.IS_NOT_NULL));
    }

    public isNull(): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofUnary(this.field, Dql.Methods.IS_NULL));
    }

    public isEmpty(): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofUnary(this.field, Dql.Methods.IS_EMPTY));
    }

    public isNotEmpty(): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofUnary(this.field, Dql.Methods.IS_NOT_EMPTY));
    }

    public eq(right: string): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofString(this.field, Dql.Methods.EQ, right));
    }

    public equalsIgnoreCase(right: string): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofString(this.field, Dql.Methods.EQUALS_IGNORE_CASE, right));
    }

    public containsIgnoreCase(right: string): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofString(this.field, Dql.Methods.CONTAINS_IGNORE_CASE, right));
    }

    public startsWith(right: string): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofString(this.field, Dql.Methods.STARTS_WITH, right));
    }

    public startsWithIgnoreCase(right: string): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofString(this.field, Dql.Methods.STARTS_WITH_IGNORE_CASE, right));
    }

    public in(right: List<string>): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofStringList(this.field, Dql.Methods.IN, right));
    }

  }

  export class EmailAddressField implements Query.EmailAddressField {

    constructor(private readonly field: string) {
    }

    private serialize(value: EmailAddress): string {
      const iso = value.toIso();
      if (iso === null) {
        throw Error('Not a valid e-mail address');
      }
      return iso;
    }

    private serializeList(value: List<EmailAddress>): List<string> {
      return value.map(d => this.serialize(d!)).toList();
    }

    public isNotNull(): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofUnary(this.field, Dql.Methods.IS_NOT_NULL));
    }

    public isNull(): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofUnary(this.field, Dql.Methods.IS_NULL));
    }

    public eq(right: EmailAddress): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofString(this.field, Dql.Methods.EQ, this.serialize(right)));
    }

    public in(right: List<EmailAddress>): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofStringList(this.field, Dql.Methods.IN, this.serializeList(right)));
    }

    contains(right: string): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofString(this.field, Dql.Methods.CONTAINS, right));
    }

    startsWith(right: string): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofString(this.field, Dql.Methods.STARTS_WITH, right));
    }

  }

  export class PhoneNumberField implements Query.PhoneNumberField {

    constructor(private readonly field: string) {
    }

    private serialize(value: PhoneNumber): string {
      const iso = value.toIso();
      if (iso === null) {
        throw Error('Not a valid E.164 phone number');
      }
      return iso;
    }

    private serializeList(value: List<PhoneNumber>): List<string> {
      return value.map(d => this.serialize(d!)).toList();
    }

    public isNotNull(): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofUnary(this.field, Dql.Methods.IS_NOT_NULL));
    }

    public isNull(): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofUnary(this.field, Dql.Methods.IS_NULL));
    }

    public eq(right: PhoneNumber): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofString(this.field, Dql.Methods.EQ, this.serialize(right)));
    }

    public in(right: List<PhoneNumber>): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofStringList(this.field, Dql.Methods.IN, this.serializeList(right)));
    }

    contains(right: string): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofString(this.field, Dql.Methods.CONTAINS, right));
    }

    startsWith(right: string): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofString(this.field, Dql.Methods.STARTS_WITH, right));
    }

  }

  export class UuidField implements Query.UuidField {

    constructor(private readonly field: uuid) {
    }

    public isNotNull(): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofUnary(this.field, Dql.Methods.IS_NOT_NULL));
    }

    public isNull(): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofUnary(this.field, Dql.Methods.IS_NULL));
    }

    public eq(right: uuid): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofString(this.field, Dql.Methods.EQ, right));
    }

    public in(right: List<uuid>): Criteria {
      return new Criteria(Dql.StringBuilderVisitor.ofStringList(this.field, Dql.Methods.IN, right));
    }

  }

  export function toOptionalFilter<T>(field: T, fn?: Query.FilterFunction<T>): string | undefined {
    if (fn === undefined) {
      return undefined;
    }
    const criteria = fn(field);
    if (criteria === undefined) {
      return undefined;
    }
    return Criteria.toDqlString(criteria);
  }

  export function toOptionalOrder<T>(field: T, fn?: Query.OrderFunction<T>): string | undefined {
    if (fn === undefined) {
      return undefined;
    }
    return DqlQuery.Order.toDqlString(fn(field));
  }

  export function toOptionalFields<T>(field: T, fn?: Query.FieldFunction<T>): string | undefined {
    if (fn === undefined) {
      return undefined;
    }
    return DqlQuery.Field.toDqlString(fn(field));
  }

}
