/* eslint-disable */
import { Component, ComponentRef, ViewChild, ViewRef, } from '@angular/core';
import { FormRecordFormTableFieldWrapper } from './form-record-form-table-field-record-wrapper';
import { FormRecordFormTableFieldRecordHolderDirective } from './form-record-form-table-field-record-holder.directive';
import { FormRecordComposedContainer } from '../../../groups/composed/form-record-composed-container';

/* eslint-enable */

@Component({
  selector: 'app-form-record-form-table-field-record-wrapper',
  templateUrl: 'form-record-form-table-field-record-wrapper.component.html',
  styleUrls: ['form-record-form-table-field-record-wrapper.component.scss'],
})
export class FormRecordFormTableFieldWrapperComponent implements FormRecordFormTableFieldWrapper.View {

  @ViewChild(FormRecordFormTableFieldRecordHolderDirective, { static: true })
  host: FormRecordFormTableFieldRecordHolderDirective;

  title: string = '';

  private hostView?: ViewRef;
  private previewHostView?: ViewRef;
  private showHideListener?: FormRecordFormTableFieldWrapper.ShowHideListener;

  private removeListener: () => any;

  private _contentVisible: boolean = false;

  private readonlyFn: () => boolean = () => true;

  get readonly() {
    return this.readonlyFn();
  }

  get contentVisible(): boolean {
    return this._contentVisible;
  }

  constructor() {
  }

  registerContent(
    content: ComponentRef<FormRecordComposedContainer.View>,
    preview: ComponentRef<FormRecordComposedContainer.PreView>) {
    this.hostView = content.hostView;
    this.previewHostView = preview.hostView;
    this.host.viewContainerRef.insert(this.previewHostView!);
  }

  setOnRemoveListener(listener: () => any) {
    this.removeListener = listener;
  }

  setShowHideListener(showHideListener: FormRecordFormTableFieldWrapper.ShowHideListener) {
    this.showHideListener = showHideListener;
  }

  setTitle(title: string) {
    this.title = title;
  }

  setReadonly(readonlyFn: () => boolean) {
    this.readonlyFn = readonlyFn;
  }

  removeItem() {
    this.removeListener();
  }

  showContent() {
    const hostIndex = this.host.viewContainerRef.indexOf(this.hostView!);
    if (hostIndex >= 0) {
      return;
    }
    this._contentVisible = true;
    this.host.viewContainerRef.insert(this.hostView!);
    this.host.viewContainerRef.detach(
      this.host.viewContainerRef.indexOf(this.previewHostView!));
    const listener = this.showHideListener;
    if (listener) {
      listener.onShowContent(this);
    }
  }

  hideContent() {
    const hostIndex = this.host.viewContainerRef.indexOf(this.hostView!);
    if (hostIndex < 0) {
      return;
    }
    this._contentVisible = false;
    this.host.viewContainerRef.detach(hostIndex);
    this.host.viewContainerRef.insert(this.previewHostView!);
    const listener = this.showHideListener;
    if (listener) {
      listener.onHideContent(this);
    }
  }

}
