<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">
  <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 p-0">
    <div class="card">

      <div class="card-header">
        {{'LEGACY_WORKFLOW_EDIT'|translate}}
      </div>

      <div class="card-body">
        <form class="form-horizontal bordered-row" (ngSubmit)="f.form.valid && updateWorkflow()" #f="ngForm" novalidate>

          <div class="form-group row"
               [ngClass]="{ 'has-danger': hasFieldError(Workflow.ValidatedField.NAME) || (f.submitted && !name.valid) }">
            <div class="col-md-4">
              <label class="mw-100 col-form-label form-control-label required-field-label">
                {{'COMMON_NAME' | translate}}
              </label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
                       [ngClass]="{ 'form-control-danger': hasFieldError(Workflow.ValidatedField.NAME) || (f.submitted && !name.valid) }"
                       [(ngModel)]="model.name" (ngModelChange)="removeFieldError(Workflow.ValidatedField.NAME)"
                       name="name"
                       id="name" #name="ngModel" required>
                <div class="form-control-feedback" *ngIf="hasFieldError(Workflow.ValidatedField.NAME)">
                  {{getFieldErrorText(Workflow.ValidatedField.NAME)}}
                </div>
                <div class="form-control-feedback" *ngIf="f.submitted && !name.valid">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED'
                  | translate}}
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row" [ngClass]="{ 'has-danger': hasFieldError(Workflow.ValidatedField.EXTERNAL_ID) }">
            <div class="col-md-4">
              <label class="mw-100 col-form-label form-control-label required-field-label">
                {{'WORKFLOW_CREATE_EDIT_EXTERNAL_ID' | translate}}
              </label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <input type="text" class="form-control" placeholder="{{'WORKFLOW_CREATE_EDIT_EXTERNAL_ID' | translate}}"
                       [ngClass]="{ 'form-control-danger': hasFieldError(Workflow.ValidatedField.EXTERNAL_ID) }"
                       [ngModelOptions]="{standalone: true}"
                       [(ngModel)]="model.externalId"
                       (ngModelChange)="removeFieldError(Workflow.ValidatedField.EXTERNAL_ID)" name="external_id"
                       id="external_id" #external_id="ngModel" required>
                <div class="form-control-feedback" *ngIf="hasFieldError(Workflow.ValidatedField.EXTERNAL_ID)">
                  {{getFieldErrorText(Workflow.ValidatedField.EXTERNAL_ID)}}
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-md-4">
              <label class="mw-100 col-form-label form-control-label">{{'COMMON_DESCRIPTION' |
                translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <input type="text" class="form-control" placeholder="{{'COMMON_DESCRIPTION' | translate}}"
                       [(ngModel)]="model.description" name="description" id="description" #description="ngModel">
              </div>
            </div>
          </div>
          <app-icon-selector
          [(selectedIcon)]="model.icon"></app-icon-selector>
          <div>
            <div class="d-flex justify-content-end">
              <input type="submit" class="btn btn-primary" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
            </div>
          </div>

        </form>
      </div>
    </div>
  </div>

  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 container-vertical-padding">
    <div class="card">
      <div class="card-header">
        {{'WORKFLOW_TASKS'|translate}}
        <div class="card-actions">
          <a class="btn-setting table-header-action-button" title="{{'WORKFLOW_EDIT_ADD_TASK' | translate}}"
             (click)="showWorkflowTaskCreateDialog()">
            <i class="icomoon icomoon-add"></i></a>
        </div><!-- /.card-actions -->
      </div>
      <div class="card-body p-1">
        <div class="card"
             cdkDropList
             [cdkDropListData]="workflowTasks"
             (cdkDropListDropped)="onWorkflowTaskDropped($event)">
          <div class="card-body text-center" *ngIf="workflowTasks.length === 0">
            <p class="empty-form-hint-text">{{ 'WORKFLOW_ADD_TEXT' | translate }}</p>
            <button type="button" class="btn btn-secondary" (click)="showWorkflowTaskCreateDialog()">
              {{ 'COMMON_BUTTON_ADD' | translate }}
            </button>
          </div>
          <div class="card-body p-0" *ngIf="workflowTasks.length > 0">
            <ul class="list-group">
              <li class="list-group-item border-0">
                <div class="row" style="width: 100%;">
                  <div class="col-md-4 col-lg-4 text-truncate">
                    <h6 class="mb-0">
                      <i class="icomoon icomoon-menu-thick pr-1 type-icon" style="color:transparent"
                         title="{{'COMMON_BUTTON_MOVE' | translate}}"></i>
                      {{ 'COMMON_EXTERNAL_ID' | translate }}
                    </h6>
                  </div>
                  <div class="col-lg-4 col-md-4 text-truncate hidden-sm-down">
                    <h6 class="mb-0">{{ 'COMMON_NAME' | translate }}</h6>
                  </div>
                  <div class="col-lg-3 col-md-3 text-truncate hidden-sm-down">
                    <h6 class="mb-0">{{ 'COMMON_DESCRIPTION' | translate }}</h6>
                  </div>
                  <div class="col-md-1 col-lg-1">
                    <!--left empty intentionally-->
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <!--endregion header texts-->

          <!-- region Fields - Group (card block) -->
          <div class="card-body p-0">
            <ul class="list-group">
              <div *ngFor="let workflowTask of workflowTasks; let x = index; let last = last"
                   cdkDrag cdkDragLockAxis="y" [cdkDragData]="workflowTask"
                   [disabledTableRow]="workflowTask.disabled">
                <li class="list-group-item border-left-0 border-right-0"
                    [ngClass]="{'border-bottom-0' : last}">
                  <div class="row" style="width: 100%;">
                    <div class="col-md-4 col-lg-4 text-truncate">
                      <i class="icomoon icomoon-menu-thick pr-1 type-icon"
                         title="{{'COMMON_BUTTON_MOVE' | translate}}"
                         cdkDragHandle>
                      </i>
                      {{workflowTask.externalId}}
                    </div>
                    <div class="col-lg-4 col-md-4 text-truncate hidden-sm-down">
                      {{workflowTask.name}}
                    </div>
                    <div class="col-lg-3 col-md-3 text-truncate hidden-sm-down">
                      {{workflowTask.description}}
                    </div>
                    <div class="col-md-1 col-lg-1 text-nowrap text-right">

                      <a *ngIf="workflowTask.disabled"
                         href="#" onclick="return false" (click)="setDisabled(workflowTask, false)"
                         class="btn-setting"
                         title="{{'COMMON_BUTTON_ENABLE' | translate}}">
                        <i class="icomoon icomoon-enable icomoon-enable-table detail-icon"></i>
                      </a>

                      <a *ngIf="!workflowTask.disabled"
                         href="#" onclick="return false" (click)="setDisabled(workflowTask, true)"
                         class="btn-setting"
                         title="{{'COMMON_BUTTON_DISABLE' | translate}}">
                        <i class="icomoon icomoon-disable icomoon-disable-table detail-icon"></i>
                      </a>

                      <a href="#" onclick="return false" class="btn-setting"
                         title="{{'COMMON_BUTTON_EDIT' | translate}}"
                         uiSref="Admin.LegacyWorkflowTaskEdit" [uiParams]="{ workflowId: workflowTask.workflowId,
                                                                       id: workflowTask.workflowTaskId }">
                        <i class="icomoon icomoon-modify icomoon-modify-table detail-icon"></i>
                      </a>
                    </div>
                  </div>
                </li>
              </div>
            </ul>
          </div> <!-- /.card-body -->
        </div>
      </div>
    </div>
  </div>
</div>

<div bsModal [config]="UiConstants.modalConfig" #workflowTaskCreateDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true"
     (onHide)="onModalHidden()">
  <div class="modal-dialog modal-lg" role="document" *ngIf="workflowTaskCreateDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title"><strong>{{'WORKFLOW_TASK_CREATE' | translate}}</strong></p>
        <button type="button" class="close" (click)="closeWorkflowTaskCreateDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form [formGroup]='workflowTaskCreateForm' (ngSubmit)="createWorkflowTask()" class="modal-body">
        <div class="form-group row"
             [ngClass]="{ 'has-danger': (workflowTaskCreateForm.controls['name'].touched
                                     && !workflowTaskCreateForm.controls['name'].valid)
                                     || (workflowTaskCreateFieldErrors.name)}">
          <div class="col-md-4">
            <label class="col-form-label mw-100 form-control-label required-field-label">
              {{'COMMON_NAME'|translate}}
            </label>
          </div>
          <div class="col-md-8">
            <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
                   [(ngModel)]="workflowTaskCreateModel.name"
                   formControlName="name"
                   (ngModelChange)="removeWorkflowTaskCreateFieldError(workflowTaskCreateFieldErrors.name)">
            <div class="form-control-feedback" *ngIf="workflowTaskCreateForm.controls['name'].touched
                                                   && workflowTaskCreateForm.controls['name'].hasError('required')">
              {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
            </div>
            <div class="form-control-feedback" *ngIf="workflowTaskCreateFieldErrors.name">
              {{workflowTaskCreateFieldErrors.name.text}}
            </div>
          </div>
        </div>
        <div class="form-group row"
             [ngClass]="{ 'has-danger': workflowTaskCreateFieldErrors.name}">
          <div class="col-md-4">
            <label class="col-form-label mw-100 form-control-label">{{'COMMON_EXTERNAL_ID'|translate}}</label>
          </div>
          <div class="col-md-8">
            <input type="text" class="form-control" placeholder="{{'COMMON_EXTERNAL_ID' | translate}}"
                   [(ngModel)]="workflowTaskCreateModel.externalId"
                   (ngModelChange)="removeWorkflowTaskCreateFieldError(workflowTaskCreateFieldErrors.external_id)"
                   [ngModelOptions]="{standalone: true}">
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-4">
            <label class="col-form-label mw-100 form-control-label">{{'COMMON_DESCRIPTION'|translate}}</label>
          </div>
          <div class="col-md-8">
            <input type="text" class="form-control" placeholder="{{'COMMON_DESCRIPTION' | translate}}"
                   [(ngModel)]="workflowTaskCreateModel.description" [ngModelOptions]="{standalone: true}">
          </div>
        </div>
        <div class="form-group row"
             [ngClass]="{ 'has-danger': workflowTaskCreateForm.controls['inProgressStateName'].touched
                                     && !workflowTaskCreateForm.controls['inProgressStateName'].valid}">
          <div class="col-md-4">
            <label class="col-form-label mw-100 form-control-label required-field-label">
              {{'WORKFLOW_TASK_STATE_NAME_IN_PROGRESS'|translate}}
            </label>
          </div>
          <div class="col-md-8">
            <input type="text" class="form-control" placeholder="{{'WORKFLOW_TASK_STATE_NAME_IN_PROGRESS' | translate}}"
                   [(ngModel)]="workflowTaskCreateModel.inProgressStateName"
                   formControlName="inProgressStateName">
            <div class="form-control-feedback" *ngIf="workflowTaskCreateForm.controls['inProgressStateName'].touched
                                                   && workflowTaskCreateForm.controls['inProgressStateName'].hasError('required')">
              {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
            </div>
          </div>
        </div>
        <div class="form-group row"
             [ngClass]="{ 'has-danger': workflowTaskCreateForm.controls['finishedStateName'].touched
                                     && !workflowTaskCreateForm.controls['finishedStateName'].valid}">
          <div class="col-md-4">
            <label class="col-form-label mw-100 form-control-label required-field-label">
              {{'WORKFLOW_TASK_STATE_NAME_FINISHED'|translate}}
            </label>
          </div>
          <div class="col-md-8">
            <input type="text" class="form-control" placeholder="{{'WORKFLOW_TASK_STATE_NAME_FINISHED' | translate}}"
                   [(ngModel)]="workflowTaskCreateModel.finishedStateName"
                   formControlName="finishedStateName">
            <div class="form-control-feedback" *ngIf="workflowTaskCreateForm.controls['finishedStateName'].touched
                                                   && workflowTaskCreateForm.controls['finishedStateName'].hasError('required')">
              {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
            </div>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-4">
            <label class="col-form-label mw-100 form-control-label">{{'WORKFLOW_TASK_CUSTOMER_SIGNATURE'|translate}}</label>
          </div>
          <div class="col-md-8">
            <label class="switch switch-text switch-info mb-0">
              <input type="checkbox" class="switch-input"
                     [ngModelOptions]="{standalone: true}"
                     [(ngModel)]="workflowTaskCreateModel.customerSignature">
              <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
              <span class="switch-handle"></span>
            </label>
          </div>
        </div>
        <!-- /.form-group -->
        <div class="form-group row">
          <div class="col-md-4">
            <label class="col-form-label mw-100 form-control-label">{{'WORKFLOW_TASK_USER_SIGNATURE'|translate}}</label>
          </div>
          <div class="col-md-8">
            <label class="switch switch-text switch-info mb-0">
              <input type="checkbox" class="switch-input"
                     [ngModelOptions]="{standalone: true}"
                     [(ngModel)]="workflowTaskCreateModel.userSignature">
              <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
              <span class="switch-handle"></span>
            </label>
          </div>
        </div>
        <!-- /.form-group -->
      </form>
      <!-- /.modal-body -->
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary" (click)="closeWorkflowTaskCreateDialog()">
          {{'COMMON_BUTTON_CANCEL' | translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="createWorkflowTask()">{{'COMMON_BUTTON_SAVE' |
          translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
