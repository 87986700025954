/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { ResourceQueryResult } from '../util/services';

/* eslint-enable */

@Injectable()
export class StockChangeResourceService extends BaseHttpService {

  // <editor-fold desc="CRUD">

  query(request: StockChangeResource.QueryRequest): Observable<ResourceQueryResult<StockChangeResource.StockChange>> {
    return this.http.get<ResourceQueryResult<StockChangeResource.StockChange>>(this.url, this.parseParams(request));
  }

  get(request: StockChangeResource.GetRequest): Observable<StockChangeResource.StockChange> {
    return this.http.get<StockChangeResource.StockChange>(this.url + `${request.id}`, this.parseParams(request));
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/stock-changes/');
  }
}

export namespace StockChangeResource {

  // <editor-fold desc="CRUD">

  export interface StockChange {
    id: number;
    trx_id: string;
    assignee_user_id: number;
    note?: string;
    change_type: string;
    change_time: string;
    supplier_id?: number;
    acquisition_id?: number;
    items?: StockChangeItem[];
  }

  export interface QueryRequest {
    with_items?: boolean;
    trx_id?: string;
    change_type?: string;
    assignee_user_id?: number;
    stock_item_id?: number;
    stock_id?: number;
    change_time_from?: string;
    change_time_to?: string;
    query_text?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
  }

  export interface GetRequest {
    with_items?: boolean;
    id: number;
  }

  export interface StockChangeItem {
    stock_item_id: number;
    source_stock_id?: number;
    destination_stock_id?: number;
    amount: number;
    expiry_date?: string;
  }

  // </editor-fold>

}
