import { Set, List } from 'immutable';
import { Observable } from 'rxjs';
import { AbstractControl } from '@angular/forms';
import { Logger, LoggerFactory } from './logger-factory';

export namespace Multiselect {

  /**
   * Supported key type.
   * They work because JS can compare them (with === operator) by value.
   * JS objects are not supported yet.
   */
  export type ItemId = number | string;

  /**
   * Item interface that contains only the fields that are used by the loader logic.
   * Display logic has no place here.
   */
  export interface Item {

    /**
     * Unique identifier.
     * Required: Null and undefined will be rejected.
     * Optional to support legacy code.
     */
    id: ItemId | null | undefined,

    /**
     * True means inactive item.
     */
    disabled: boolean

  }

  /**
   * A factory that creates an unknown item by ID.
   * Unknown item means that we are not able to display it and we do not know whether it is active or inactive.
   */
  export interface UnknownItemFactory<T extends Item> {
    createUnknownItem(id: ItemId): T;
  }

  export interface ValidationStrategy {
    /**
     * Notifies the validator that the value has changed and revalidation is required.
     */
    revalidate();
  }

  export interface ModelStrategy<T extends Item> {

    /**
     * @param items ordered items (disabled ones are at the top on the editor screens)
     */
    applySelectedItems(items: T[]);

    /**
     * @param items ordered items
     */
    applySelectableItems(items: T[]);

  }

  export type SearchFunction<T extends Item> = (searchValue?: string) => Observable<List<T>>;
  export type IdSetFunction<T extends Item> = (ids?: Set<ItemId>) => Observable<List<T>>;

  export interface LoadingHelperForEditArgs<T extends Item> {

    /**
     * An Observable that returns only the top active items.
     * @param searchValue filter predicate; undefined means disabled filter; empty text means give me nothing
     */
    topActiveItem$: SearchFunction<T>;

    /**
     * An Observable that returns the requested items; disabled items are included.
     * @param ids filter predicate; undefined means disabled filter; empty set means give me nothing
     */
    searchItem$: IdSetFunction<T>;

    /**
     * A factory that creates an unknown item by ID.
     */
    unknownItemFactory: UnknownItemFactory<T>;

    /**
     * @see MutableModelStrategy
     */
    modelStrategy: ModelStrategy<T>;

    /**
     * @see FormControlValidationStrategy
     */
    validationStrategy: ValidationStrategy;

  }

  export interface LoadingHelperForSearchArgs<T extends Item> {

    /**
     * An Observable that returns the top active items; disabled items are included.
     * @param searchValue filter predicate; undefined means disabled filter; empty text menas give me nothing
     */
    topItem$: SearchFunction<T>;

    /**
     * An Observable that returns the requested items; disabled items are included.
     * @param ids filter predicate; undefined means disabled filter; empty set means give me nothing
     */
    searchItem$: IdSetFunction<T>;

    /**
     * @see MutableModelStrategy
     */
    modelStrategy: ModelStrategy<T>;

  }

  export class LoadingHelper<T extends Item> {

    private readonly logger: Logger;

    // region Init

    public static forEdit<T extends Item>(args: LoadingHelperForEditArgs<T>): LoadingHelper<T> {
      return new LoadingHelper(
        args.topActiveItem$, args.searchItem$, args.modelStrategy, true,
        args.validationStrategy, args.unknownItemFactory
      );
    }

    public static forSearch<T extends Item>(args: LoadingHelperForSearchArgs<T>): LoadingHelper<T> {
      return new LoadingHelper(
        args.topItem$, args.searchItem$, args.modelStrategy, false,
        NoOpValidationStrategy.getInstance(), FailingUnknownItemFactory.getInstance()
      );
    }

    private constructor(
      private readonly topItem$: (searchValue?: string) => Observable<List<T>>,
      private readonly searchItem$: (ids?: Set<ItemId>) => Observable<List<T>>,
      private readonly modelStrategy: ModelStrategy<T>,
      private readonly reorderDisabledItems: boolean,
      private readonly validationStrategy: ValidationStrategy,
      private readonly unknownItemFactory: UnknownItemFactory<T>) {
      this.logger = LoggerFactory.createLogger('Multiselect.LoadingHelper');
    }

    // endregion

    public loadCurrentItems(ids?: Set<ItemId>) {
      this.loadItems(ids, undefined);
    }

    public loadSearch(searchValue?: string) {
      this.loadItems(undefined, searchValue);
    }

    private loadItems(ids?: Set<ItemId>, searchValue?: string) {
      const selectableItems: T[] = [];
      const selectedItems: T[] = [];
      this.topItem$(searchValue).subscribe(result => {
        result.forEach(i => {
          const item = i!;
          if (item.id === null || item.id === undefined) {
            throw Error('Only the default single select item can use undefined ID');
          }
          selectableItems.push(item);
          if (searchValue === undefined && ids && ids.contains(item.id)) {
            selectedItems.push(item);
          }
        });
        if (searchValue === undefined && ids && selectedItems.length !== ids.size) {
          this.logger.debug('Missing items');
          this.clear(selectedItems);
          this.searchItem$(ids).subscribe(result => {
            const foundIds = result.map((item) => item!.id);
            const notFoundIds = ids.filter(id => ! foundIds.contains(id)).sort();
            const unknownItems = notFoundIds.map(id => this.unknownItemFactory.createUnknownItem(id!));
            if (!unknownItems.isEmpty()) {
              this.logger.warn('Not found IDs: ', notFoundIds);
            }
            result.forEach(i => {
              const item = i!;
              selectedItems.push(item);
            });
            const reorderedItems = this.reorderDisabledItemsToTop(selectedItems);
            unknownItems.forEach(i => {
              const item = i!;
              reorderedItems.push(item);
              selectableItems.push(item);
            });
            this.applySelectedItems(reorderedItems);
            this.applySelectableItems(selectableItems);
            this.revalidate();
          });
        }
        else {
          this.logger.debug('Each item has found');
          if (searchValue === undefined && ids) {
            const reorderedItems = this.reorderDisabledItemsToTop(selectedItems);
            this.applySelectedItems(reorderedItems);
          }
          this.applySelectableItems(selectableItems);
          this.revalidate();
        }
      });
    }

    // region Model

    private applySelectedItems(items: T[]) {
      this.modelStrategy.applySelectedItems(items);
    }

    private applySelectableItems(items: T[]) {
      this.modelStrategy.applySelectableItems(items);
    }

    private reorderDisabledItemsToTop(items: T[]): T[] {
      if (!this.reorderDisabledItems) {
        return items;
      }
      const activeItems: T[] = [];
      const orderedItems: T[] = [];
      items.forEach((item) => {
        if (item.disabled) {
          orderedItems.push(item);
        }
        else {
          activeItems.push(item);
        }
      });
      activeItems.forEach((item) => {
        orderedItems.push(item);
      });
      return orderedItems;
    }

    // endregion

    // region Util

    private clear<T>(array: T[]) {
      Utils.clear(array);
    }

    private revalidate() {
      this.validationStrategy.revalidate();
    }

    // endregion

  }

  class Utils {

    public static clear<T>(array: T[]) {
      array.splice(0, array.length);
    }

  }

  export class NoOpValidationStrategy implements ValidationStrategy {

    private static readonly INSTANCE = new NoOpValidationStrategy();

    public static getInstance(): ValidationStrategy {
      return NoOpValidationStrategy.INSTANCE;
    }

    private constructor() {
    }

    revalidate() {
    }

  }

  export class FailingUnknownItemFactory<T extends Item> implements UnknownItemFactory<T> {

    private static readonly INSTANCE = new FailingUnknownItemFactory();

    public static getInstance<T extends Item>(): UnknownItemFactory<T> {
      return <FailingUnknownItemFactory<T>> FailingUnknownItemFactory.INSTANCE;
    }

    private constructor() {
    }

    createUnknownItem(id: number | string): T {
      throw Error('Unsupported operation');
    }

  }

  export class FormControlValidationStrategy implements ValidationStrategy {

    public static of(formControl: AbstractControl): FormControlValidationStrategy {
      return new FormControlValidationStrategy(formControl);
    }

    private constructor(private readonly formControl: AbstractControl) {
    }

    revalidate() {
      this.formControl.updateValueAndValidity();
    }

  }

  export interface MutableModelArgs<T extends Item> {

    /**
     * Reference to the model.
     * Contains the selectable items in the dropdown.
     */
    selectableItems: T[];

    /**
     * Reference to the model.
     * Contains the selected items.
     */
    selectedItems: T[];

  }

  export class MutableModelStrategy<T extends Item> implements ModelStrategy<T> {

    private readonly selectableItems: T[];
    private readonly selectedItems: T[];

    constructor(args: MutableModelArgs<T>) {
      this.selectableItems = args.selectableItems;
      this.selectedItems = args.selectedItems;
    }

    applySelectedItems(items: T[]) {
      this.clear(this.selectedItems);
      this.selectedItems.push(...items);
    }

    applySelectableItems(items: T[]) {
      this.clear(this.selectableItems);
      this.selectableItems.push(...items);
      const ids = this.selectedItems.map(i => i.id!);
      this.applySelectedItems(this.selectableItems.filter(i => ids.includes(i.id!)));
    }

    private clear<T>(array: T[]) {
      Utils.clear(array);
    }

  }

}
