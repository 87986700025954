import { Injectable } from '@angular/core';
import { Company, CompanyService } from './company.service';
import { MultiselectProvider } from '../multiselect-provider';
import { Observable, Observer } from 'rxjs';
import { MultiselectOptionItem, UiConstants } from '../../util/core-utils';
import { Set } from 'immutable';
import { OrderType, QueryResult } from '../util/services';
import CompanyType = Company.CompanyType;

@Injectable()
export class CompanyMultiselectProvider implements MultiselectProvider {

  private multiselectQueryRequest: Company.QueryRequest = {
    paging: {
      pageNumber: 1,
      numberOfItems: UiConstants.autocompletePageSize
    },
    noProgressBar: true,
    orders: Set.of({field: Company.OrderField.NAME, type: OrderType.ASC}),
    disabled: undefined,
    type: undefined,
    name: undefined
  };

  constructor(private companyService: CompanyService) {
  }

  load(queryRequest: Company.QueryRequest): Observable<MultiselectOptionItem<number>[]> {
    return Observable.create((observer: Observer<MultiselectOptionItem<number>[]>) => {
      this.companyService.query(queryRequest).subscribe((result: QueryResult<Company.Company>) => {
          observer.next(result.items.map(item => this.toMultiselectOptionItem(item!)).toArray());
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  loadAll(predicate?: string): Observable<MultiselectOptionItem<number>[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.name = predicate;
    return this.load(queryRequest);
  }

  loadActive(predicate?: string): Observable<MultiselectOptionItem<number>[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.name = predicate;
    queryRequest.disabled = false;
    return this.load(queryRequest);
  }

  loadTransporterCompanies(predicate?: string): Observable<MultiselectOptionItem<number>[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.name = predicate;
    queryRequest.type = Set.of('TRANSPORTER' as CompanyType);
    queryRequest.disabled = false;
    return this.load(queryRequest);
  }

  loadDemanderCompanies(): Observable<MultiselectOptionItem<number>[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.type = Set.of('DEMANDER' as CompanyType);
    queryRequest.disabled = false;
    return this.load(queryRequest);
  }

  searchCompanies(predicate?: string): Observable<MultiselectOptionItem<number>[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.name = predicate;
    queryRequest.disabled = false;
    return this.load(queryRequest);
  }

  searchTransporterCompanies(predicate?: string): Observable<MultiselectOptionItem<number>[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.name = predicate;
    queryRequest.type = Set.of('TRANSPORTER' as CompanyType);
    return this.load(queryRequest);
  }

  searchDemanderCompanies(predicate?: string): Observable<MultiselectOptionItem<number>[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.name = predicate;
    queryRequest.type = Set.of('DEMANDER' as CompanyType);
    return this.load(queryRequest);
  }

  getById(id: number): Observable<MultiselectOptionItem<number>> {
    return Observable.create((observer: Observer<MultiselectOptionItem<number>>) => {
      this.companyService.get({
        id: id
      }).subscribe((result: Company.Company) => {
          observer.next(this.toMultiselectOptionItem(result!));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  getByIds(ids: number[]): Observable<MultiselectOptionItem<number>[]> {
    const queryRequest = Object.assign([], this.multiselectQueryRequest);
    queryRequest.id = Set.of(...ids);
    return this.load(queryRequest);
  }

  toMultiselectOptionItem(item: Company.Company): MultiselectOptionItem<number> {
    const ret = new MultiselectOptionItem<number>();
    ret.id = item.id;
    ret.itemName = item.name;
    ret.disabled = item.disabled;
    return ret;
  }

}
