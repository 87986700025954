import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserItem } from '../../../lib/user.service';

@Component({
  selector: 'app-history-log',
  templateUrl: './history-log.component.html',
  styleUrls: ['./history-log.component.scss']
})
export class HistoryLogComponent implements OnInit {

  @Output() openHistoryDetail = new EventEmitter<string>();

  @Output() orderByOut = new EventEmitter<any>();

  @Output() pageChangedOut = new EventEmitter<number>();

  @Output() itemsPerPageChangedOut = new EventEmitter<number>();

  @Input()
  queryModel: any;

  // User = User;
  @Input()
  Type: any;

  @Input()
  historyList: any[] = [];

  constructor(
  ) { }

  ngOnInit() {
  }

  orderBy(field: any) {
    this.orderByOut.emit(field);
  }

  pageChanged(selectedPage: number) {
    this.pageChangedOut.emit(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.itemsPerPageChangedOut.emit(itemsPerPage);
  }

  openDetailDialog(log: string) {
    this.openHistoryDetail.emit(log);
  }

  hasType(): boolean {
    return this.historyList.length > 0 && this.historyList[0].typeString;
  }

  hasState(): boolean {
    return this.historyList.length > 0 && this.historyList[0].stateString;
  }

}


export class HistoryBaseModel {
  id: number;
  creationTime: string = '';
  typeString?: string;
  stateString?: string;
  changeLog: string = '';
  user: UserItem;
  applicationClassification: string = '';
  applicationId: string = '';
}
