<div class="container-horizontal-padding container-vertical-padding animated fadeIn">
    <div class="row flex-column-reverse flex-md-row">

      <div class="col-lg-9 col-md-8 col-sm-12 col-xs-12 mb-1">
        <ng-container *ngIf="rightModel.shippingDemandRead.hasRight() || rightModel.transportRead.hasRight()">
          <h5 class="mb-1 container-horizontal-padding-half">{{'DASHBOARD_STATISTICS' | translate}}</h5>
          <div class="row m-0">
            <!--DemandStatistics-->
            <div class="col-xl-6 col-lg-12 container-vertical-padding-half container-horizontal-padding-half" *ngIf="rightModel.shippingDemandRead.hasRight()">
              <div class="row card-custom-padding" *ngIf="isDemandChartVisible && dashboardDemandModel">
                <div class="card col-12 p-0">
                  <h6 class="card-header">
                    {{'TRANSPORT_DASHBOARD_DEMAND_STATISTICS' | translate}}
                    <div class="card-actions">
                      <a href="#" onclick="return false" uiSref="Admin.ShippingDemandList">
                        <i class="icomoon icomoon-dashboard-task-universal"></i>
                      </a>
                    </div>
                  </h6>
                  <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="d-flex justify-content-around flex-grow-1 mb-1">
                        <app-dashboard-pie-chart
                          *ngIf="showGoogleChartsDemand"
                          [dashboardTaskModel]="dashboardDemandModel">
                        </app-dashboard-pie-chart>
                      </div>
                      <div class="d-flex flex-row flex-wrap justify-content-around flex-grow-1 align-items-center mb-1">
                        <div [ngStyle]="{'flex-grow': 1}" class="mb-1">
                          <div class="d-flex mb-1">
                            <div class="d-flex justify-content-start align-items-center">
                              <span class="circle-green px-1"></span>
                            </div>
                            <div class="justify-content-start align-items-center">
                              <h5 class="mb-0">{{dashboardDemandModel.getStat('NEW').count}}</h5>
                              <p class="mb-0">{{'SHIPPING_DEMAND_STATE_STATISTICS_NEW' | translate}}</p>
                            </div>
                          </div>
                          <div class="d-flex">
                            <div class="d-flex justify-content-start align-items-center">
                              <span class="circle-yellow px-1"></span>
                            </div>
                            <div class="justify-content-start align-items-center">
                              <h5 class="mb-0">{{dashboardDemandModel.getStat('IN_PROGRESS').count}}</h5>
                              <p class="mb-0">{{'SHIPPING_DEMAND_STATE_STATISTICS_IN_PROGRESS' | translate}}</p>
                            </div>
                          </div>
                        </div>
                        <div [ngStyle]="{'flex-grow': 1}" class="mb-1">
                          <div class="d-flex mb-1">
                            <div class="d-flex justify-content-start align-items-center">
                              <span class="circle-orange px-1"></span>
                            </div>
                            <div class="justify-content-start align-items-center">
                              <h5 class="mb-0">{{dashboardDemandModel.getStat('OPEN').count}}</h5>
                              <p class="mb-0">{{'SHIPPING_DEMAND_STATE_STATISTICS_DELIVERED' | translate}}</p>
                            </div>
                          </div>
                          <div class="d-flex">
                            <div class="d-flex justify-content-start align-items-center">
                              <span class="circle-blue px-1"></span>
                            </div>
                            <div class="justify-content-start align-items-center">
                              <h5 class="mb-0">{{dashboardDemandModel.getStat('FINISHED').count}}</h5>
                              <p class="mb-0">{{'SHIPPING_DEMAND_STATE_STATISTICS_CANCELED' | translate}}</p>
                            </div>
                          </div>
                        </div>
                        <!-- /.d-flex flex-row justify-content-start align-items-center -->
                      </div>
                      <!-- /.row -->
                      <!-- /.d-flex flex-row -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- ./DemandStatistics-->

            <!--TransportStatistics-->
            <div class="col-xl-6 col-lg-12 container-vertical-padding-half container-horizontal-padding-half" *ngIf="rightModel.transportRead.hasRight()">
              <div class="row card-custom-padding" *ngIf="isTransportChartVisible && dashboardTransportModel">
                <div class="card col-12 p-0">
                  <h6 class="card-header">
                    {{'TRANSPORT_DASHBOARD_TRANSPORT_STATISTICS' | translate}}
                    <div class="card-actions">
                      <a href="#" onclick="return false" uiSref="Admin.TransportList">
                        <i class="icomoon icomoon-dashboard-task-universal"></i>
                      </a>
                    </div>
                  </h6>
                  <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="d-flex justify-content-around flex-grow-1 mb-1">
                        <app-dashboard-pie-chart
                          *ngIf="showGoogleChartsTransport"
                          [dashboardTaskModel]="dashboardTransportModel">
                        </app-dashboard-pie-chart>
                      </div>
                      <div class="d-flex flex-row flex-wrap justify-content-around flex-grow-1 align-items-center mb-1">
                        <div [ngStyle]="{'flex-grow': 1}" class="mb-1">
                          <div class="d-flex mb-1">
                            <div class="d-flex justify-content-start align-items-center">
                              <span class="circle-green px-1"></span>
                            </div>
                            <div class="justify-content-start align-items-center">
                              <h5 class="mb-0">{{dashboardTransportModel.getStat('NEW').count}}</h5>
                              <p class="mb-0">{{'TRANSPORT_STATE_STATISTICS_NEW' | translate}}</p>
                            </div>
                          </div>
                          <div class="d-flex">
                            <div class="d-flex justify-content-start align-items-center">
                              <span class="circle-yellow px-1"></span>
                            </div>
                            <div class="justify-content-start align-items-center">
                              <h5 class="mb-0">{{dashboardTransportModel.getStat('IN_PROGRESS').count}}</h5>
                              <p class="mb-0">{{'TRANSPORT_STATE_STATISTICS_RUNNING' | translate}}</p>
                            </div>
                          </div>
                        </div>
                        <div [ngStyle]="{'flex-grow': 1}" class="mb-1">
                          <div class="d-flex mb-1">
                            <div class="d-flex justify-content-start align-items-center">
                              <span class="circle-orange px-1"></span>
                            </div>
                            <div class="justify-content-start align-items-center">
                              <h5 class="mb-0">{{dashboardTransportModel.getStat('OPEN').count}}</h5>
                              <p class="mb-0">{{'TRANSPORT_STATE_STATISTICS_FINISHED' | translate}}</p>
                            </div>
                          </div>
                          <div class="d-flex">
                            <div class="d-flex justify-content-start align-items-center">
                              <span class="circle-blue px-1"></span>
                            </div>
                            <div class="justify-content-start align-items-center">
                              <h5 class="mb-0">{{dashboardTransportModel.getStat('FINISHED').count}}</h5>
                              <p class="mb-0">{{'TRANSPORT_STATE_STATISTICS_CLOSED' | translate}}</p>
                            </div>
                          </div>
                        </div>
                        <!-- /.d-flex flex-row justify-content-start align-items-center -->
                      </div>
                      <!-- /.row -->
                      <!-- /.d-flex flex-row -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- ./TransportStatistics-->
            <div class="col-md-12 col-sm-12 nopadding" *ngIf="rightModel.transportRead.hasRight()">
              <app-anysped-dashboard-transport>

              </app-anysped-dashboard-transport>
            </div>

            <div class="col-md-12 col-sm-12 nopadding" *ngIf="rightModel.shipmentGroupRead.hasRight()">
              <app-anysped-dashboard-demand>

              </app-anysped-dashboard-demand>
            </div>

          </div>
        </ng-container>

      </div>

      <div class="col-lg-3 col-md-4 col-sm-12 col-xs-12">
        <!--Message-->
        <div class="row">
          <div class="col-md-12">
            <ng-container *ngIf="rightModel.transportRead.hasRight()">
              <h5 class="mb-0">{{ 'COMMON_MESSAGES' | translate}}</h5>
              <!--list of messages-->
              <ul class="list-group mt-1 margin-bottom-30">
                <li class="list-group-item d-flex justify-content-center" *ngIf="messageList.length === 0">
                  <div class="text-center mt-1 mb-1">
                    <span class="icomoon icomoon-message-unread empty-list-icon"></span>
                    <p class="mb-0 empty-list-text">{{ 'EMPTY_DASHBOARD_NO_NOTIFICATION' | translate}}</p>
                  </div>
                </li>
                <ng-container *ngIf="messageList.length > 0">
                  <li class="list-group-item" *ngFor="let message of messageList">
                    <div class="media w-100 cursor-pointer" uiSref="Admin.TransportDetailTab"
                         [uiParams]="{ id: message.transportId, tabId: 'COMMON_MESSAGES' }"
                         #media>
                      <span class="align-self-center icomoon icomoon-message-unread list-icon mr-1 float-left"></span>
                      <div class="media-body">
                        <div class="d-flex flex-row flex-wrap justify-content-between">
                          <p class="message-heading mb-0 d-inline-block text-truncate"
                             [ngStyle]="{'max-width': media.offsetWidth-113 + 'px'}">{{ message.userPersonName }} </p>
                          <p class="message-date mb-0">{{message.creationTime | date: message.isTodayMessage?'short':'shortDate' }}</p>
                        </div>
                        <p class="message-text mb-0 d-inline-block text-truncate"
                           [ngStyle]="{'max-width': media.offsetWidth-33 + 'px'}">{{message.content}}</p>
                      </div>
                    </div>
                  </li>
                </ng-container>
              </ul>
              <!--list of messages end-->
            </ng-container>


          </div>

        </div>
        <!-- ./Messages-->

        <!--Lost Drivers-->
        <div class="row">
          <div class="col-md-12">
            <ng-container *ngIf="rightModel.transportRead.hasRight()">
              <h5 class="mb-0">{{ 'TRANSPORT_DASHBOARD_LOST_DRIVERS' | translate}}</h5>
              <!--list of messages-->
              <ul class="list-group mt-1 margin-bottom-30">
                <li class="list-group-item d-flex justify-content-center" *ngIf="lostDriverList.length === 0">
                  <div class="text-center mt-1 mb-1">
                    <span class="icomoon icomoon-offline-device empty-list-icon"></span>
                    <p class="mb-0 empty-list-text">{{ 'EMPTY_DASHBOARD_NO_LOST_DRIVER' | translate}}</p>
                  </div>
                </li>
                <ng-container *ngIf="lostDriverList.length > 0">
                  <li class="list-group-item" *ngFor="let lostDriver of lostDriverList">
                    <div class="media w-100 cursor-pointer" uiSref="Admin.TransportDetail"
                         [uiParams]="{ id: lostDriver.transportId }"
                         #media>
                      <div class="media-body">
                        <div class="d-flex flex-row flex-wrap justify-content-between">
                          <p class="message-heading mb-0 d-inline-block text-truncate"
                             [ngStyle]="{'max-width': media.offsetWidth-113 + 'px'}">{{ lostDriver.driverPersonName }} </p>
                          <p class="message-date mb-0">{{lostDriver.creationTime | date: 'shortDate' }}</p>
                        </div>
                        <p class="message-text mb-0 d-inline-block text-truncate"
                           [ngStyle]="{'max-width': media.offsetWidth-33 + 'px'}">{{lostDriver.vehicleLicencePlate}}</p>
                      </div>
                    </div>
                  </li>
                </ng-container>
              </ul>
              <!--list of messages end-->
            </ng-container>


          </div>

        </div>
        <!-- ./LostDrivers-->

      </div> <!-- row -->

    </div> <!-- row -->
</div>
