/* eslint-disable */
import { combineLatest, debounceTime, flatMap, map } from 'rxjs/operators';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Order, OrderEditFieldErrorMap, OrderService, } from '../../../lib/order/order.service';
import { UserService } from '../../../lib/user.service';
import { Transition, UIRouter } from '@uirouter/angular';
import { StockItem, StockItemService, UnitPrice } from '../../../lib/stock/stock-item.service';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { StateName } from '../../../app.state-names';
import { MultiselectOptionItem, SelectUtils, UiConstants } from '../../../util/core-utils';
import { CountryService } from '../../../lib/country.service';
import { QueryResult, ResourceQueryResult } from '../../../lib/util/services';
import { AddressModel } from '../../../lib/address';
import { Currency, CurrencyService } from '../../../lib/currency.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FieldError, FieldErrors, ObservableErrorResourceParser } from '../../../lib/util/errors';
import { Weight, WeightFactory } from '../../../util/weight-utils';
import { AppValidators } from '../../../util/app-validators';
import { CriteriaBuilder, Models } from '../../../util/model-utils';
import { StringKey } from '../../../app.string-keys';
import { InputMask } from '../../../util/input-masks';
import { ToasterService } from '../../../fork/angular2-toaster/angular2-toaster';
import { ConfigurationResource, ConfigurationService } from '../../../lib/core-ext/configuration.service';
import { TranslateUtils } from '../../../util/translate';
import { Angular2Multiselects } from '../../../util/multiselect';
import { Observable, Subject } from 'rxjs';
import { Strings } from '../../../lib/util/strings';
import { RightResolver, RightService } from '../../../lib/right.service';
import { RightModel } from '../../../app.rights';
import { OrderRightModel } from '../../../app.rights.order';
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { ConfirmLeaveModalComponent } from '../../../shared/confirm-leave-modal/confirm-leave-modal.component';
import OrderState = Order.OrderState;
import OrderItem = Order.OrderItem;
import OtherDataField = Order.OtherDataField;
import OrderReturnState = Order.OrderReturnState;
import { DeliveryMethod, DeliveryMethodService } from '../../../lib/delivery-method/delivery-method.service';
import { List, Set } from 'immutable';
import { FilterField } from '../../../lib/query/filterfields';
import {
  ParcelCollectionPoint,
  ParcelCollectionPointService
} from '../../../lib/parcel-collection-point/parcel-collection-point.service';
import { Length, LengthFactory } from '../../../util/length-utils';
import { Company, CompanyService } from '../../../lib/company/company.service';

/* eslint-enable */

@Component({
  selector: 'app-order-edit',
  templateUrl: './order-edit.component.html',
  styleUrls: ['./order-edit.component.scss']
})
export class OrderEditComponent implements OnInit, OnDestroy {
  SelectUtils = SelectUtils;
  InputMask = InputMask;
  UiConstants = UiConstants;
  WeightFactory = WeightFactory;
  LengthFactory = LengthFactory;

  config: ConfigurationResource.Configuration;
  rightModel: OrderRightModel = OrderRightModel.empty();

  @ViewChild('warningDialog') warningDialog: ModalDirective;
  saveButtonClicked: boolean = false;
  deregisterWarningModal;

  readonly model: Model;

  Order = Order;
  order: Order.Order;

  orderId: number;
  orderEditForm: FormGroup;
  fieldErrors: OrderEditFieldErrorMap;
  formSubmitted: boolean = false;
  itemsLoaded: boolean = false;
  stockItemsLoaded: boolean = false;
  stockItemsLength: number = 0;
  stockItems: StockItemForList[] = [];
  otherData: OtherDataDropdownItem[] = [];
  selectedOtherData: OtherDataDropdownItem[] = [];
  otherDataDropdownSettings: Angular2Multiselects.Settings;
  deliveryMethodDropdownSettings: Angular2Multiselects.Settings;
  parcelPointDropdownSettings: Angular2Multiselects.Settings;
  transporterCompanies: MultiselectOptionItem<number>[] = [];
  deliveryMethods: DeliveryMethod.DeliveryMethod[] = [];
  parcelCollectionPoints: MultiselectOptionItem<number>[] = [];

  shippingStateOptions$: Observable<string[]>;

  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  selectableCashOnDeliveryCurrencies: string[] = [];
  selectableInsuranceCurrencies: string[] = [];
  selectableWeightUnits: string[] = [];
  selectableLengthUnits: string[] = ['mm', 'cm', 'm'];
  packageSizeUnit: string = 'cm';
  packageWeightUnit: string = 'g';

  submitItemErrors: any;

  fieldStyle = {
    'padding': UiConstants.formRecordFieldPadding
  };

  constructor(
    private translateService: TranslateService,
    private orderService: OrderService,
    private countryService: CountryService,
    private userService: UserService,
    private currencyService: CurrencyService,
    private stockItemService: StockItemService,
    private uiRouter: UIRouter,
    private transition: Transition,
    private formBuilder: FormBuilder,
    private toasterService: ToasterService,
    private companyService: CompanyService,
    private deliveryMethodService: DeliveryMethodService,
    private parcelCollectionPointService: ParcelCollectionPointService,
    private configService: ConfigurationService,
    private rightService: RightService,
    private modalService: BsModalService) {
    this.orderId = this.transition.params().id;
    this.orderEditForm = formBuilder.group({
      externalId: ['', Validators.required],
      insurancePrice: [
        '',
        [
          AppValidators.validateOptionalPositiveNumber,
          AppValidators.validateMaxNumber({
            value: () => {
              if (this.model) {
                return Models.parseNumber(this.model.insurancePriceAmount)
                  ? Models.parseNumber(this.model.insurancePriceAmount)
                  : undefined;
              }
              return undefined;
            },
            maxValue: () => {
              if (this.model && this.model.deliveryMethod.length === 1 && this.model.deliveryMethod[0].maxInsurancePrice) {
                return this.model.deliveryMethod[0].maxInsurancePrice!.amount.toNumber();
              }
              return null;
            }
          })
        ]
      ],
      cashOnDeliveryPrice: [
        '',
        [
          Validators.required,
          AppValidators.validateOptionalPositiveNumber,
          AppValidators.validateMaxNumber({
            value: () => {
              if (this.model) {
                if (this.model.deliveryMethod.length > 0
                  && this.model.deliveryMethod[0].maxCashOnDeliveryPrice
                  && this.model.cashOnDeliveryPriceCurrencyCode === this.model.deliveryMethod[0].maxCashOnDeliveryPrice!.currencyCode) {
                  return Models.parseNumber(this.model.cashOnDeliveryPriceAmount)
                    ? Models.parseNumber(this.model.cashOnDeliveryPriceAmount)
                    : undefined;
                }
                return Number.POSITIVE_INFINITY;
              }
              return undefined;
            },
            maxValue: () => {
              if (this.model && this.model.deliveryMethod.length === 1 && this.model.deliveryMethod[0].maxCashOnDeliveryPrice) {
                return this.model.deliveryMethod[0].maxCashOnDeliveryPrice!.amount.toNumber();
              }
              return null;
            }
          })
        ]
      ],
      weightInGram: [
        '',
        [
          AppValidators.validateOptionalPositiveNumber,
          AppValidators.required({
            disabled: () => {
              return !this.model || this.model.deliveryMethod.length === 0 || !this.model.deliveryMethod[0].packageWeightRequired;
            }
          }),
          AppValidators.validateMaxNumber({
            value: () => {
              if (this.model) {
                return Models.parseNumber(this.model.weightInGram)
                  ? new Weight(this.packageWeightUnit, Models.parseNumber(this.model.weightInGram)!).toGrams()
                  : undefined;
              }
              return undefined;
            },
            maxValue: () => {
                if (this.model && this.model.deliveryMethod.length === 1 && this.model.deliveryMethod[0].maxPackageWeightInGram) {
                  return this.model.deliveryMethod[0].maxPackageWeightInGram;
                }
              return null;
            }
          })
        ]
      ],
      packageSizeWidth: ['',
        [
          AppValidators.validateOptionalPositiveNumber,
          AppValidators.required({
            disabled: () => {
              return !this.model || this.model.deliveryMethod.length === 0 || !this.model.deliveryMethod[0].packageSizeRequired;
            }
          }),
          AppValidators.validateMaxNumber({
            value: () => {
              if (this.model) {
                return Models.parseNumber(this.model.packageSizeWidth)
                  ? new Length(this.packageSizeUnit, Models.parseNumber(this.model.packageSizeWidth)!).toMeters()
                  : undefined;
              }
              return undefined;
            },
            maxValue: () => {
              if (this.model && this.model.deliveryMethod.length === 1 && this.model.deliveryMethod[0].maxPackageSize) {
                return this.model.deliveryMethod[0].maxPackageSize!.widthInMeter.toNumber();
              }
              return null;
            }
          })]
      ],
      packageSizeHeight: ['',
        [
          AppValidators.validateOptionalPositiveNumber,
          AppValidators.required({
            disabled: () => {
              return !this.model || this.model.deliveryMethod.length === 0 || !this.model.deliveryMethod[0].packageSizeRequired;
            }
          }),
          AppValidators.validateMaxNumber({
            value: () => {
              if (this.model) {
                return Models.parseNumber(this.model.packageSizeHeight)
                  ? new Length(this.packageSizeUnit, Models.parseNumber(this.model.packageSizeHeight)!).toMeters()
                  : undefined;
              }
              return undefined;
            },
            maxValue: () => {
              if (this.model && this.model.deliveryMethod.length === 1 && this.model.deliveryMethod[0].maxPackageSize) {
                return this.model.deliveryMethod[0].maxPackageSize!.heightInMeter.toNumber();
              }
              return null;
            }
          })]
      ],
      packageSizeDepth: ['',
        [
          AppValidators.validateOptionalPositiveNumber,
          AppValidators.required({
            disabled: () => {
              return !this.model || this.model.deliveryMethod.length === 0 || !this.model.deliveryMethod[0].packageSizeRequired;
            }
          }),
          AppValidators.validateMaxNumber({
            value: () => {
              if (this.model) {
                return Models.parseNumber(this.model.packageSizeDepth)
                  ? new Length(this.packageSizeUnit, Models.parseNumber(this.model.packageSizeDepth)!).toMeters()
                  : undefined;
              }
              return undefined;
            },
            maxValue: () => {
              if (this.model && this.model.deliveryMethod.length === 1 && this.model.deliveryMethod[0].maxPackageSize) {
                return this.model.deliveryMethod[0].maxPackageSize!.depthInMeter.toNumber();
              }
              return null;
            }
          })]
      ],
      transporterCompany: ['', [Validators.required, AppValidators.validateEnabledItems]],
      deliveryMethod: ['', [Validators.required, AppValidators.validateEnabledItems]],
      parcelCollectionPoint: [
        '',
        [
          AppValidators.required({
            disabled: () => {
              return !this.model || this.model.deliveryMethod.length === 0 || this.model.deliveryMethod[0].type === 'HOME_DELIVERY';
            }
          }),
          AppValidators.validateEnabledItems
        ]
      ],
      recipientName: ['', Validators.required],
      recipientPhoneNumber: [
        '',
        [
          AppValidators.validatePhoneNumber,
          AppValidators.required({
            disabled: () => {
              return !this.model || this.model.deliveryMethod.length === 0
                || !this.model.deliveryMethod[0].recipientPhoneNumberRequired;
            }
          })
        ]
      ],
      recipientEmailAddress: [
        '',
        [
          AppValidators.validateOptionalEmail,
          AppValidators.required({
            disabled: () => {
              return !this.model || this.model.deliveryMethod.length === 0
                || !this.model.deliveryMethod[0].recipientEmailAddressRequired;
            }
          })
        ]
      ],
      shippingState: [''],
    });
    this.fieldErrors = {};
    this.model = new Model();
    const context = this;
    this.deregisterWarningModal = this.uiRouter.transitionService.onStart({}, function (transitionFn) {
      if (context.saveButtonClicked
        || transitionFn.targetState().name() === StateName.CONNECTION_ERROR
        || transitionFn.targetState().name() === StateName.SERVER_ERROR
        || transitionFn.targetState().name() === StateName.LOGIN) {
        return true;
      }
      else {
        const subject = new Subject<boolean>();
        const modal = context.modalService.show(ConfirmLeaveModalComponent, {backdrop: 'static',  keyboard: false});
        modal.content!.subject = subject;
        return subject.asObservable().toPromise();
      }
    });
  }

  ngOnInit() {
    this.translateService.get('MENU_NAVBAR_ORDERS').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ORDER_LIST});
      }
    );
    this.breadcrumbSelf = this.orderId.toString();
    this.refreshModel();
    this.initDropdownSettings();
    this.shippingStateOptions$ = this.orderEditForm.controls['shippingState'].valueChanges.pipe(
      debounceTime(UiConstants.autocompleteDebounceTime),
      flatMap(
        (value) => {
          return this.orderService.queryShippingStates({
            shippingState: value,
            paging: {
              numberOfItems: 20,
              pageNumber: 1
            }
          }).pipe(map((result) => {
            return result.items.toArray();
          }));
        }
      )
    );
  }

  private loadModel(completion: () => void) {
    this.countryService.query({}).pipe(
      combineLatest(this.orderService.get({id: this.orderId}))
    ).subscribe(
      ([countries, order]) => {
        this.model.version = order.version;
        this.model.externalId = order.externalId;
        this.model.shipmentTrackingNumber = Models.optToString(order.shipmentTrackingNumber);
        this.model.description = Models.optToString(order.description);
        this.model.insurancePriceAmount =
          order.insurancePrice ? Models.decimalToString(order.insurancePrice.amount) : '';
        this.model.insurancePriceCurrencyCode = order.insurancePrice ? order.insurancePrice.currencyCode : '';
        this.model.cashOnDeliveryPriceAmount =
          order.cashOnDeliveryPrice ? Models.decimalToString(order.cashOnDeliveryPrice.amount) : '';
        this.model.cashOnDeliveryPriceCurrencyCode = order.cashOnDeliveryPrice ? order.cashOnDeliveryPrice.currencyCode : '';
        this.model.packageSizeWidth = order.packageSize ? order.packageSize.widthInMeter.times(100).toString() : '';
        this.model.packageSizeHeight = order.packageSize ? order.packageSize.heightInMeter.times(100).toString() : '';
        this.model.packageSizeDepth = order.packageSize ? order.packageSize.depthInMeter.times(100).toString() : '';
        this.model.recipientName = order.recipient ? order.recipient.name : '';
        this.model.recipientPhoneNumber = order.recipient ? Models.phoneNumberToString(order.recipient.phoneNumber) : '';
        this.model.recipientEmailAddress = order.recipient ? Models.emailAddressToString(order.recipient.emailAddress) : '';
        this.model.demanderCompanyId = order.ownerCompanyId;
        this.loadDelivery(order.deliveryMethod, order.recipient);
        // The address is used without address type selector. We accept only complex addresses.
        this.model.recipientDeliveryAddress.loadCountryItems(
          AddressModel.CountryItem.fromCountryList(countries.items), this.configService.getDefaultSelectedCountryCode()
        );
        this.model.recipientDeliveryAddress.type = AddressModel.PostalAddressModelType.COMPLEX;
        this.model.weightInGram = order.weightInGram ? order.weightInGram + '' : '';
        this.model.shippingState = order.shippingState ? order.shippingState : '';
        this.model.items = order.items!;
        this.stockItemsLength = this.model.items.length;
        this.model.orderState = order.orderState;
        this.model.orderReturnState = order.returnState;
        this.model.customerNote = order.note ? order.note.customerNote ? order.note.customerNote : '' : '';
        this.model.extraNote = order.note ? order.note.extraNote ? order.note.extraNote : '' : '';
        this.model.internalNote = order.note ? order.note.internalNote ? order.note.internalNote : '' : '';
        this.order = order;
        this.packageSizeUnit = 'cm';
        this.packageWeightUnit = 'g';
        this.initConfiguration();
        this.loadOtherData(order.other);
        this.loadRightModels();
        this.loadStockItemsForTable(completion);
      }
    );
  }

  private initConfiguration() {
    this.config = this.configService.getConfiguration();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.userService.get({
          id: resolver.userProfile!.id
        }).subscribe((user) => {
          this.rightModel = OrderRightModel.of({
            rightModel: RightModel.of(resolver),
            currentUser: resolver.userProfile,
            currentCompanies: user.companies
          });
        });
      }
    );
  }

  private loadDropdowns() {
    this.selectableCashOnDeliveryCurrencies = [];
    this.selectableInsuranceCurrencies = [];
    this.currencyService.query({}).subscribe((result: QueryResult<Currency.Currency>) => {
      const array = result.items.toArray();
      array.forEach((currency) => {
        this.selectableCashOnDeliveryCurrencies.push(currency.currencyCode);
        if (currency.currencyCode === 'HUF') {
          this.model.insurancePriceCurrencyCode = currency.currencyCode;
          this.model.cashOnDeliveryPriceCurrencyCode = currency.currencyCode;
          this.selectableInsuranceCurrencies.push(currency.currencyCode);
        }
      });
    });
    this.selectableWeightUnits = WeightFactory.getOptions();
  }

  private loadStockItemsForTable(completion: () => void) {
    if (this.config.valid_order_edit_states.find(orderState => orderState === this.model.orderState)) {
      if (this.model.items) {
        const stockItemIds: number[] = [];
        this.model.items.forEach((item) => {
          stockItemIds.push(item.stockItemId);
        });
        this.stockItems = [];
        this.stockItemService.query({id: stockItemIds.join(',')})
          .subscribe((result: ResourceQueryResult<StockItem>) => {
            result.items.forEach((item) => {
              this.model.items!.forEach((i) => {
                if (item.id === i.stockItemId) {
                  this.stockItems.push({
                    itemId: i.itemId,
                    stockItemId: i.stockItemId,
                    name: item.name,
                    amount: i.amount,
                    unit: item.unit,
                    externalId: item.external_id,
                    serialCode: item.serial_code,
                    unitPrice: item.unit_price
                  });
                }
              });
              this.stockItemsLoaded = true;
            });
            completion();
          });
      }
    }
    else {
      completion();
    }
  }

  private loadOtherData(otherData: Order.OtherData) {
    const keys: string[] = [];
    this.otherData = [];
    this.selectedOtherData = [];
    Order.otherDataFields.forEach((field) => {
      this.otherData.push({id: field.field, itemName: field.stringKey});
      keys.push(field.stringKey);
    });
    this.translateService.get(keys).subscribe((texts) => {
      this.otherData.forEach((field) => {
        field.itemName = TranslateUtils.extractValueFromObject(texts, field.itemName);
      });
      if (otherData.fragile) {
        this.selectedOtherData.push(this.otherData.find((field) => field.id === 'FRAGILE')!);
      }
      if (otherData.documentHandling) {
        this.selectedOtherData.push(this.otherData.find((field) => field.id === 'DOCUMENT_HANDLING')!);
      }
      if (otherData.packageExchange) {
        this.selectedOtherData.push(this.otherData.find((field) => field.id === 'PACKAGE_EXCHANGE')!);
      }
      if (otherData.handoverByItems) {
        this.selectedOtherData.push(this.otherData.find((field) => field.id === 'HANDOVER_BY_ITEMS')!);
      }
    });
  }

  private loadDelivery(deliveryMethod?: any, recipient?: Order.OrderRecipient) {
    this.loadTransporterCompanies(undefined, deliveryMethod, () => {
      this.loadDeliveryMethods(undefined, deliveryMethod, () => {
        if (deliveryMethod && recipient) {
          if (this.model.deliveryMethod[0]!.type === 'PARCEL_COLLECTION_POINT') {
            this.loadParcelCollectionPoints(
              undefined, recipient.parcelCollectionPoint ? recipient.parcelCollectionPoint.id : undefined);
          }
          else {
            this.model.recipientDeliveryAddress.load(recipient.deliveryAddress);
          }
        }
        else {
        }
      });
    });
  }

  loadTransporterCompanies(q?: string, deliveryMethod?: any, completion?: () => void) {
    this.companyService.query({
      name: q ? Strings.undefinedOrNonEmpty(q) : undefined,
      demanderCompanyIds: Set.of(this.model.demanderCompanyId),
      disabled: false,
      type: Set.of(<Company.CompanyType>'TRANSPORTER'),
      paging: {
        pageNumber: 1,
        numberOfItems: UiConstants.autocompletePageSize
      },
      noProgressBar: true
    }).subscribe((result: QueryResult<Company.Company>) => {
      this.transporterCompanies = result.items.toArray().map(c => ({id: c.id, itemName: c.name}));
      if (deliveryMethod) {
        this.deliveryMethodService.get({id: deliveryMethod.id}).subscribe(method => {
          const item = this.transporterCompanies.find(c => c.id === method.transporterCompany!.id);
          if (item) {
            this.model.transporterCompany.push(item);
            if (completion) {
              completion();
            }
          }
          else {
            this.companyService.get({id: method.transporterCompany!.id}).subscribe(company => {
              this.model.transporterCompany.push({id: company.id, itemName: company.name, disabled: company.disabled});
              if (completion) {
                completion();
              }
            });
          }
        });
      }
    });
  }

  loadDeliveryMethods(q?: any, deliveryMethod?: any, completion?: () => void) {
    this.deliveryMethodService.query({
        fields: f => f.each,
        order: f => List.of(f.name.asc().nullsLast()),
        filter: (f: FilterField.DeliveryMethod) => CriteriaBuilder.builder()
          .addNumber((id) => f.transporterCompany.id.eq(id), this.model.transporterCompanyId)
          .addString((predicate) => f.name.containsIgnoreCase(predicate), q)
          .addBoolean(() => f.disabled.isFalse())
          .build(),
        paging: {
          pageNumber: 1,
          numberOfItems: UiConstants.autocompletePageSize,
        },
        noProgressBar: true,
      }).subscribe((result: QueryResult<DeliveryMethod.DeliveryMethod>) => {
      this.deliveryMethods = result.items.toArray();
      if (deliveryMethod) {
        const method = this.deliveryMethods.find(m => m.id === deliveryMethod.id);
        if (!method) {
          this.deliveryMethodService.get({
            id: deliveryMethod.id
          }).subscribe((result: DeliveryMethod.DeliveryMethod) => {
            this.model.deliveryMethod.push(result);
            this.deliveryMethods.unshift(result);
            if (completion) {
              completion();
            }
          });
        }
        else {
          this.model.deliveryMethod.push(method);
          if (completion) {
            completion();
          }
        }
      }
    });
  }

  loadParcelCollectionPoints(q?: string, parcelCollectionPointId?: number) {
    this.parcelCollectionPointService.query({
      fields: f => Set.of(f.id, f.name, f.disabled),
      order: f => List.of(f.name.asc().nullsLast()),
      filter: (f: FilterField.ParcelCollectionPoint) => CriteriaBuilder.builder()
        .addNumber((methodId) => f.deliveryMethod.id.eq(methodId), this.model.deliveryMethod[0]!.id)
        .addString((predicate) => f.name.containsIgnoreCase(predicate), q)
        .addBoolean(() => f.disabled.isFalse())
        .build(),
      paging: {
        pageNumber: 1,
        numberOfItems: UiConstants.autocompletePageSize,
      },
      noProgressBar: true
    }).subscribe((result: QueryResult<ParcelCollectionPoint.ParcelCollectionPoint>) => {
      this.parcelCollectionPoints = result.items.toArray().map(p => ({id: p.id, itemName: p.name, disabled: p.disabled}));
      if (parcelCollectionPointId) {
        const point = this.parcelCollectionPoints.find(p => p.id === parcelCollectionPointId);
        if (!point) {
          this.parcelCollectionPointService.get({
            id: parcelCollectionPointId
          }).subscribe((result: ParcelCollectionPoint.ParcelCollectionPoint) => {
            const item = {
              id: result.id,
              itemName: result.name,
              disabled: result.disabled
            };
            this.model.parcelCollectionPoint.push(item);
            this.parcelCollectionPoints.unshift(item);
          });
        }
        else {
          this.model.parcelCollectionPoint.push(point);
        }
      }
    });
  }

  private initDropdownSettings() {
    this.otherDataDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(false)
      .enableCheckAll(false)
      .build();
    this.deliveryMethodDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .remoteSearch(true)
      .labelKey('name')
      .enableCheckAll(false)
      .build();
    this.parcelPointDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .remoteSearch(true)
      .enableCheckAll(false)
      .build();
  }

  saveBasicData(finalize: boolean, navigateBack: boolean) {
    this.formSubmitted = true;
    this.orderEditForm.controls.deliveryMethod.updateValueAndValidity();
    this.orderEditForm.controls.parcelCollectionPoint.updateValueAndValidity();
    if (!this.orderEditForm.valid
      || (!(this.orderEditForm.controls['packageSizeWidth'].value === null
        && this.orderEditForm.controls['packageSizeHeight'].value === null
        && this.orderEditForm.controls['packageSizeDepth'].value === null)
        && !(this.orderEditForm.controls['packageSizeWidth'].value !== null
          && this.orderEditForm.controls['packageSizeHeight'].value !== null
          && this.orderEditForm.controls['packageSizeDepth'].value !== null))) {
      this.orderEditForm.get('externalId')!.markAsTouched();
      this.showValidationErrorToast();
      return;
    }
    else {
      let widthInMeter: number | undefined = undefined;
      let heightInMeter: number | undefined = undefined;
      let depthInMeter: number | undefined = undefined;
      let weightInGram: number | undefined = undefined;
      if (this.model.packageSizeWidth
        && this.model.packageSizeHeight
        && this.model.packageSizeDepth) {
        widthInMeter = Number(this.model.packageSizeWidth);
        heightInMeter = Number(this.model.packageSizeHeight);
        depthInMeter = Number(this.model.packageSizeDepth);
        if (widthInMeter && heightInMeter && depthInMeter) {
          switch (this.packageSizeUnit) {
            case 'mm':
              widthInMeter = widthInMeter / 1000;
              heightInMeter = heightInMeter / 1000;
              depthInMeter = depthInMeter / 1000;
              break;
            case 'cm':
              widthInMeter = widthInMeter / 100;
              heightInMeter = heightInMeter / 100;
              depthInMeter = depthInMeter / 100;
              break;
            case 'm':
              break;
          }
        }
      }
      if (this.model.weightInGram) {
        weightInGram = Models.parseNumber(this.model.weightInGram);
        if (weightInGram) {
          switch (this.packageWeightUnit) {
            case 'g':
              break;
            case 'dkg':
              weightInGram = weightInGram * 10;
              break;
            case 'kg':
              weightInGram = weightInGram * 1000;
              break;
            case 't':
              weightInGram = weightInGram * 1000 * 1000;
          }
        }
      }
      const otherData: OtherData = {
        fragile: !!this.selectedOtherData.find((field) => field.id === 'FRAGILE'),
        documentHandling: !!this.selectedOtherData.find((field) => field.id === 'DOCUMENT_HANDLING'),
        packageExchange: !!this.selectedOtherData.find((field) => field.id === 'PACKAGE_EXCHANGE'),
        handoverByItems: !!this.selectedOtherData.find((field) => field.id === 'HANDOVER_BY_ITEMS'),
        deliveryTimeWindow: !!this.selectedOtherData.find((field) => field.id === 'DELIVERY_TIME_WINDOW'),
        oversized: !!this.selectedOtherData.find((field) => field.id === 'OVERSIZED'),
        inverse: !!this.selectedOtherData.find((field) => field.id === 'INVERSE'),
      };
      const deliveryAddress
        = this.model.deliveryMethod[0]!.type === 'HOME_DELIVERY' ? this.model.recipientDeliveryAddress.toData()! : undefined;
      const insurrancePriceAmount = Models.parseDecimal(this.model.insurancePriceAmount);

      this.submitItemErrors = undefined;

      this.orderService.updateData({
        id: this.orderId,
        externalId: this.model.externalId,
        description: this.model.description,
        weightInGram: weightInGram!,
        shippingState: this.model.shippingState,
        insurancePrice: insurrancePriceAmount === undefined ? undefined : {
          amount: insurrancePriceAmount,
          currencyCode: this.model.insurancePriceCurrencyCode
        },
        cashOnDeliveryPrice: {
          amount: Models.parseDecimal(this.model.cashOnDeliveryPriceAmount)!,
          currencyCode: this.model.cashOnDeliveryPriceCurrencyCode
        },
        packageSize: {
          widthInMeter: Models.numberToDecimal(widthInMeter!)!,
          heightInMeter: Models.numberToDecimal(heightInMeter!)!,
          depthInMeter: Models.numberToDecimal(depthInMeter!)!,
        },
        deliveryMethodId: this.model.deliveryMethod[0]!.id,
        recipient: {
          name: this.model.recipientName,
          deliveryAddress: deliveryAddress,
          parcelCollectionPoint: this.model.parcelCollectionPoint.length > 0 ? {
            id: this.model.parcelCollectionPoint[0].id
          } : undefined,
          phoneNumber: Models.parsePhoneNumber(this.model.recipientPhoneNumber),
          emailAddress: Models.parseEmailAddress(this.model.recipientEmailAddress)
        },
        shipmentTrackingNumber: this.model.shipmentTrackingNumber,
        other: otherData,
        returnState: this.model.orderReturnState,
        note: {
          customerNote: Strings.undefinedOrNonEmpty(this.model.customerNote),
          extraNote: Strings.undefinedOrNonEmpty(this.model.extraNote),
          internalNote: Strings.undefinedOrNonEmpty(this.model.internalNote),
        },
        version: this.model.version
      }).subscribe((result: Order.UpdateDataResponse) => {
        this.saveButtonClicked = true;
        this.model.version = result.version;
        if (finalize) {
          if (this.stockItemsLength === 0) {
            this.toasterService.pop({
              timeout: UiConstants.ToastTimeoutLong,
              type: UiConstants.toastTypeError,
              title: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_TITLE),
              body: this.translateService.instant(StringKey.ORDER_EDIT_FINALIZE_ERROR)
            });
            return;
          }
          this.finalize(navigateBack);
        }
        else {
          this.handleChange(navigateBack);
        }
      }, (error: any) => {
        const res = ObservableErrorResourceParser.parseError(error);
        this.fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
      });
    }
  }

  private finalize(navigateBack: boolean) {
    this.submitItemErrors = undefined;
    this.orderService.finalize({
      id: this.orderId
    }).subscribe(() => {
      this.handleChange(navigateBack);
    }, error => {
      const res = ObservableErrorResourceParser.parseError(error);
      if (res) {
        this.submitItemErrors = res.extra.collection_field_errors;
      }
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutLong,
        type: UiConstants.toastTypeError,
        title: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_TITLE),
        body: this.translateService.instant(StringKey.ORDER_EDIT_FINALIZE_INSUFFICIENT_ERROR)
      });

    });
  }

  private handleChange(navigateBack: boolean) {
    if (!navigateBack) {
      this.showSavedToast();
      this.refreshModel();
    }
    else {
      this.uiRouter.stateService.go(StateName.ORDER_LIST);
    }
  }

  private showSavedToast() {
    this.toasterService.pop({
      timeout: UiConstants.ToastTimeoutShort,
      type: UiConstants.toastTypeSuccess,
      title: this.translateService.instant(StringKey.COMMON_SUCCESS),
      body: this.translateService.instant(StringKey.DEVICE_EDIT_MESSAGE)
    });
  }

  private refreshModel() {
    this.itemsLoaded = false;
    this.loadModel(() => {
      this.loadDropdowns();
      this.itemsLoaded = true;
    });
  }

  onTransporterCompanyChanged() {
    this.model.deliveryMethod = [];
    this.loadDeliveryMethods();
  }

  onDeliveryMethodChanged() {
    this.model.parcelCollectionPoint = [];
    if (this.model.deliveryMethod.length > 0 && this.model.deliveryMethod[0]!.type === 'PARCEL_COLLECTION_POINT') {
      this.loadParcelCollectionPoints();
    }
    this.orderEditForm.controls['parcelCollectionPoint'].updateValueAndValidity();
    this.orderEditForm.controls['insurancePrice'].updateValueAndValidity();
    this.orderEditForm.controls['cashOnDeliveryPrice'].updateValueAndValidity();
    this.orderEditForm.controls['weightInGram'].updateValueAndValidity();
    this.orderEditForm.controls['packageSizeWidth'].updateValueAndValidity();
    this.orderEditForm.controls['packageSizeHeight'].updateValueAndValidity();
    this.orderEditForm.controls['packageSizeDepth'].updateValueAndValidity();
    this.orderEditForm.controls['recipientPhoneNumber'].updateValueAndValidity();
    this.orderEditForm.controls['recipientEmailAddress'].updateValueAndValidity();
  }

  onWeightInGramChanged() {
    this.removeFieldError(this.fieldErrors.weight_in_gram);
    this.orderEditForm.controls.weightInGram.updateValueAndValidity();
  }

  onSizeChanged() {
    this.orderEditForm.controls.packageSizeWidth.updateValueAndValidity();
    this.orderEditForm.controls.packageSizeHeight.updateValueAndValidity();
    this.orderEditForm.controls.packageSizeDepth.updateValueAndValidity();
  }

  onSizeWidthChanged() {
    this.removeFieldError(this.fieldErrors.package_size_width);
    this.orderEditForm.controls.packageSizeWidth.updateValueAndValidity();
  }

  onSizeHeightChanged() {
    this.removeFieldError(this.fieldErrors.package_size_height);
    this.orderEditForm.controls.packageSizeHeight.updateValueAndValidity();
  }

  onSizeDepthChanged() {
    this.removeFieldError(this.fieldErrors.package_size_depth);
    this.orderEditForm.controls.packageSizeDepth.updateValueAndValidity();
  }

  onCashOnDeliveryPriceChanged() {
    this.removeFieldError(this.fieldErrors.cash_on_delivery_price);
    this.orderEditForm.controls.cashOnDeliveryPrice.updateValueAndValidity();
  }

  onInsurancePriceChanged() {
    this.removeFieldError(this.fieldErrors.insurance_price);
    this.orderEditForm.controls.insurancePrice.updateValueAndValidity();
  }

  onStockItemsLengthChanged(length: number) {
    this.stockItemsLength = length;
  }

  showValidationErrorToast() {
    this.toasterService.pop({
      timeout: UiConstants.ToastTimeoutLong,
      type: UiConstants.toastTypeError,
      title: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_TITLE),
      body: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_MESSAGE)
    });
  }

  removeFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.fieldErrors, fieldError);
  }

  extraNoteEditable(): boolean {
    if (this.config) {
      return !!this.config.valid_order_note_extra_edit_states.find((state) => state === this.model.orderState);
    }
    return false;
  }

  internalNoteEditable(): boolean {
    if (this.config) {
      return !!this.config.valid_order_note_internal_edit_states.find((state) => state === this.model.orderState);
    }
    return false;
  }

  ngOnDestroy() {
    this.deregisterWarningModal();
  }

  resetData() {
    this.model.reset();
    this.selectedOtherData = [];
  }

}

export class Model {

  readonly recipientDeliveryAddress: AddressModel.PostalAddressModel;

  version: number = 0;
  externalId: string = '';
  shipmentTrackingNumber: string = '';
  description: string = '';
  insurancePriceAmount: string = '';
  insurancePriceCurrencyCode: string = '';
  cashOnDeliveryPriceAmount: string = '';
  cashOnDeliveryPriceCurrencyCode: string = '';
  packageSizeWidth: string = '';
  packageSizeHeight: string = '';
  packageSizeDepth: string = '';
  demanderCompanyId: number;
  transporterCompany: MultiselectOptionItem<number>[] = [];
  deliveryMethod: DeliveryMethod.DeliveryMethod[] = [];
  parcelCollectionPoint: MultiselectOptionItem<number>[] = [];
  recipientName: string = '';
  recipientPhoneNumber: string = '';
  recipientEmailAddress: string = '';
  weightInGram: string = '';
  shippingState: string = '';
  items: OrderItem[] = [];
  orderState: OrderState = 'DRAFT';
  orderReturnState: OrderReturnState = 'NOT_RETURNED';
  customerNote: string = '';
  extraNote: string = '';
  internalNote: string = '';

  constructor() {
    this.recipientDeliveryAddress = new AddressModel.PostalAddressModel();
  }

  get transporterCompanyId(): number | undefined {
    return this.transporterCompany.length > 0 ? this.transporterCompany[0].id : undefined;
  }

  reset() {
    this.externalId = '';
    this.shipmentTrackingNumber = '';
    this.description = '';
    this.insurancePriceAmount = '';
    this.cashOnDeliveryPriceAmount = '';
    this.packageSizeWidth = '';
    this.packageSizeHeight = '';
    this.packageSizeDepth = '';
    this.transporterCompany = [];
    this.deliveryMethod = [];
    this.parcelCollectionPoint = [];
    this.recipientName = '';
    this.recipientPhoneNumber = '';
    this.recipientEmailAddress = '';
    this.weightInGram = '';
    this.shippingState = '';
    this.customerNote = '';
    this.extraNote = '';
    this.internalNote = '';
    this.recipientDeliveryAddress.complexValue.reset();
  }

}

interface StockItemForList {
  itemId: number;
  stockItemId: number;
  amount: number;
  unit: string;
  name: string;
  externalId: string;
  serialCode: string;
  unitPrice: UnitPrice;
}

export interface OtherData {
  fragile: boolean;
  documentHandling: boolean;
  packageExchange: boolean;
  handoverByItems: boolean;
  deliveryTimeWindow: boolean;
  oversized: boolean;
  inverse: boolean;
}

export interface OtherDataDropdownItem {
  id: OtherDataField;
  itemName: string;
}
