<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="card">

    <div class="card-header">
      {{'COMMON_HISTORY' | translate}}
    </div>

    <div class="card-body">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="CustomerRecord.OrderField.HISTORY_USER_PERSON_NAME"
                                    [orderType]="queryModel.getOrderType(CustomerRecord.OrderField.HISTORY_USER_PERSON_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_HISTORY_USER' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header ">
            <app-table-sorter-no-op [text]="'COMMON_HISTORY_TYPE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-sorter-no-op
              [text]="'COMMON_HISTORY_APPLICATION_TYPE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-field-sorter [orderField]="CustomerRecord.OrderField.HISTORY_MOBILE_APPLICATION_APPLICATION_ID"
                                    [orderType]="queryModel.getOrderType(CustomerRecord.OrderField.HISTORY_MOBILE_APPLICATION_APPLICATION_ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_HISTORY_APPLICATION_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-field-sorter [orderField]="CustomerRecord.OrderField.HISTORY_CREATION_TIME"
                                    [orderType]="queryModel.getOrderType(CustomerRecord.OrderField.HISTORY_CREATION_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_HISTORY_CREATION_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-table-actions">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let customerRecordHistory of customerRecordHistoryList | paginate: {
                                                      id: pagingId,
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }">
          <td class="responsive-table-column">{{customerRecordHistory.user.personName}}</td>
          <td class="responsive-table-column ">{{customerRecordHistory.typeString | translate}}</td>
          <td class="responsive-table-column hidden-xs-down">{{customerRecordHistory.applicationClassification | translate}}</td>
          <td class="responsive-table-column hidden-sm-down">{{customerRecordHistory.applicationId}}</td>
          <td class="responsive-table-column hidden-sm-down">{{customerRecordHistory.creationTime | date:'short'}}</td>
          <td class="responsive-table-column">
            <a class="icons-table-group cursor-pointer"
            *ngIf="customerRecordHistory.changeLog !== 'null'"
               (click)="openDetailDialog(customerRecordHistory)">
              <i class="icomoon icons-table-item icomoon-detail icomoon-detail-table"
                 title="{{'COMMON_BUTTON_DETAILS' | translate}}"></i>
            </a>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="CustomerRecord.OrderField.HISTORY_USER_PERSON_NAME"
                                    [orderType]="queryModel.getOrderType(CustomerRecord.OrderField.HISTORY_USER_PERSON_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_HISTORY_USER' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header ">
            <app-table-sorter-no-op [text]="'COMMON_HISTORY_TYPE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-sorter-no-op
              [text]="'COMMON_HISTORY_APPLICATION_TYPE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-field-sorter [orderField]="CustomerRecord.OrderField.HISTORY_MOBILE_APPLICATION_APPLICATION_ID"
                                    [orderType]="queryModel.getOrderType(CustomerRecord.OrderField.HISTORY_MOBILE_APPLICATION_APPLICATION_ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_HISTORY_APPLICATION_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-field-sorter [orderField]="CustomerRecord.OrderField.HISTORY_CREATION_TIME"
                                    [orderType]="queryModel.getOrderType(CustomerRecord.OrderField.HISTORY_CREATION_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_HISTORY_CREATION_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-table-actions">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [id]="pagingId"
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>
  </div>
</div>

