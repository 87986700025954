/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { Order, OrderType } from '../util/services';
import { UiConstants } from '../../util/core-utils';
import { MemStorage } from '../util/mem-storage';
import { environment } from '../../../environments/environment';
import { TableDocument } from './table-document.service';
import { RuntimeConfiguration } from '../runtime-configuration';
import { LoggedInUserStorage } from '../util/storages';
/* eslint-enable */

@Injectable()
export class TableDocumentSearchService {

  constructor() {}

  getSearchData(request: TableDocumentSearch.SearchDataGetRequest): Observable<TableDocumentSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<TableDocumentSearch.SearchDataResult>) => {
      try {
        observer.next(storage.read(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  setSearchData(request: TableDocumentSearch.SearchDataSetRequest): Observable<TableDocumentSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<TableDocumentSearch.SearchDataResult>) => {
      try {
        observer.next(storage.save(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  resetSearchData(request: TableDocumentSearch.SearchDataGetRequest): Observable<TableDocumentSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<TableDocumentSearch.SearchDataResult>) => {
      try {
        observer.next(storage.reset(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }





  private createStorage(): TableDocumentSearch.Storage {
    return new TableDocumentSearch.Storage();
  }
}

export namespace TableDocumentSearch {

  export interface SearchDataGetRequest {
  }

  export interface SearchDataSetRequest {
    searchData: SearchData;
  }

  export interface SearchDataResult {
    searchData: SearchData;
  }

  export interface SearchData {
    itemsPerPage: number;
    pageNumber: number;
    order: Order<TableDocument.OrderField>;
    name: string;
    code: string;
    schemaId?: number;
  }

  interface StoredSearchRawDataBase {
    version: number;
  }

  interface StoredSearchRawData extends StoredSearchRawDataBase {
    itemsPerPage: number;
    pageNumber: number;
    order: Order<TableDocument.OrderField>;
    name: string;
    code: string;
    schemaId?: number;
  }

  export class Storage {

    /**
     * Increment this version if you change the SearchData interface.
     */
    private static readonly VERSION: number = 3;

    private static readonly DEFAULT_SEARCH_DATA: SearchData = {
      itemsPerPage: UiConstants.pageNumbers[0],
      pageNumber: 1,
      order: {
        field: TableDocument.OrderField.ID,
        type: OrderType.DESC
      },
      name: '',
      code: '',
      schemaId: undefined
    };

    constructor() {
    }

    public read(request: SearchDataGetRequest): SearchDataResult {
      let rawBase: StoredSearchRawDataBase;
      try {
        const key = this.generateKey();
        const json = MemStorage.getItem(key);
        if (!json) {
          return this.createDefaultResult();
        }
        rawBase = JSON.parse(json);
      }
      catch (error) {
        return this.createDefaultResult();
      }
      if (Storage.VERSION === rawBase.version) {
        const raw: StoredSearchRawData = <StoredSearchRawData> rawBase;
        return this.fromRaw(raw);
      }
      return this.createDefaultResult();
    }


    public reset(request: SearchDataGetRequest): SearchDataResult {
      return this.save({
        searchData: Storage.DEFAULT_SEARCH_DATA,
      });
    }


    public save(request: SearchDataSetRequest): SearchDataResult {
      const key = this.generateKey();
      const raw = this.toRaw(request.searchData);
      const json = JSON.stringify(raw);
      MemStorage.setItem(key, json);
      return this.read(request);
    }

    private generateKey(): string {
      return 'UserId(' + LoggedInUserStorage.getInstance().getUserId() + ')-Search-Table-Document';
    }

    private createDefaultResult(): SearchDataResult {
      return {
        searchData: Storage.DEFAULT_SEARCH_DATA,
      };
    }


    private fromRaw(data: StoredSearchRawData): SearchDataResult {
      return {
        searchData: {
          itemsPerPage: data.itemsPerPage,
          pageNumber: data.pageNumber,
          order: data.order,
          name: data.name,
          code: data.code,
          schemaId: data.schemaId,
        }
      };
    }

    private toRaw(data: SearchData): StoredSearchRawData {
      return {
        version: Storage.VERSION,
        itemsPerPage: data.itemsPerPage,
        pageNumber: data.pageNumber,
        order: data.order,
        name: data.name,
        code: data.code,
        schemaId: data.schemaId,
      };
    }
  }

  export class CompanyItem {
    id: number;
    name: string;

    get itemName() {
      return this.name;
    }
  }
}
