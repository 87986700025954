<span class="btn-setting dropdown-item d-flex align-items-center justify-content-between {{itemType}}"
      title="{{titleStringKey | translate}}">
  <div class="disabled-overlay" *ngIf="disabled"></div>
  <div>
    <i *ngIf="iconClass" class="icomoon {{iconClass}}"></i>
    <span>
      {{titleStringKey | translate}}
    </span>
  </div>
  <label class="switch switch-text switch-info mb-0 ml-1" *ngIf="enableSwitch">
    <input type="checkbox" class="switch-input"
           [(ngModel)]="switchData"
           (ngModelChange)="onSwitchDataChanged($event)"
           [ngModelOptions]="{standalone: true}">
    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
    <span class="switch-handle"></span>
  </label>

</span>
