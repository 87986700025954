import { Component, Input, OnInit } from '@angular/core';
import { DetailTab } from '../../../transport/transport-detail/detail-tab';
import { TaskRecord, TaskRecordService } from '../../../../lib/task/task-record.service';
import { AssigneeListModel, ConfigModel, TaskRecordListModel, TaskRecordRightModel } from '../../../../util/task-record-utils';
import { ConfigurationService } from '../../../../lib/core-ext/configuration.service';
import { OrderType, QueryResult, ResourceQueryResult } from '../../../../lib/util/services';
import { List, Set } from 'immutable';
import { QueryFieldModel } from '../../../../util/core-utils';
import { GrantedPermissionSetResolver, RightResolver, RightService } from '../../../../lib/right.service';
import { RightModel } from '../../../../app.rights';
import { Task, TaskService } from '../../../../lib/task/task.service';
import { Device, DeviceManagementService } from '../../../../lib/device-management.service';
import { UserService } from '../../../../lib/user.service';
import { UserGroupService } from '../../../../lib/user-group.service';
import { empty as observableEmpty, Observable } from 'rxjs';
import { defaultIfEmpty } from 'rxjs/operators';
import { Icon } from '../../../../lib/task/icon.service';
import { TaskRecordStateMachine } from '../../../../lib/task/task-record-statemachine';
import { AssigneeType } from '../../../../shared/assignee-table-cell/assignee-table-cell.component';
import { AppTypeHelperService } from '../../../../lib/util/app-type-helper.service';
import { OperationRights } from '../../../../app.right-definitions';

@Component({
  selector: 'app-task-record-related-tasks',
  templateUrl: './task-record-related-tasks.component.html',
  styleUrls: ['./task-record-related-tasks.component.scss']
})
export class TaskRecordRelatedTasksComponent implements OnInit, DetailTab {

  TaskRecord = TaskRecord;
  AssigneeType = AssigneeType;

  @Input()
  customerRecordId?: number;

  @Input()
  taskId?: number;

  @Input()
  taskRecordId?: number;

  config: ConfigModel = new ConfigModel();

  rightModel: RightModel = RightModel.empty();

  listByLink: ListLinkModel[] = [];
  tasks: Task.Task[] = [];

  readonly pagingId = 'taskRecordRelatedTasksPaging';

  constructor(private taskRecordService: TaskRecordService,
              private configService: ConfigurationService,
              private rightService: RightService) {
  }

  ngOnInit() {
  }

  initComponent() {
    this.loadConfig();
  }

  private loadConfig() {
    this.config = this.configService.getConfigurationModel();
    this.loadRightModels(() => {
      if (this.taskId && this.taskRecordId) {
        this.loadListByLink();
      }
    });
  }


  private loadRightModels(completion: () => void) {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        completion();
      }
    );
  }

  private loadListByLink() {
    this.taskRecordService.readLinks({
      taskId: this.taskId!,
      taskRecordId: this.taskRecordId!
    }).subscribe(result => {
      this.listByLink = result.map(l => {
        return {
          destination: l.destination,
          type: l.type,
          creationTime: l.creationTime,
          stateObject: TaskRecordStateMachine.taskRecordStates.get(l.destination.state)
        };
      });
    });
  }
}

interface ListLinkModel extends TaskRecord.Link {
  stateObject: TaskRecordStateMachine.StateObject;
}
