/* eslint-disable */
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { QueryFieldModel, SelectUtils, UiConstants } from '../../../util/core-utils';
import {
  ForwardingNgFormRef,
  LocalFieldValidationErrors,
  LocalFieldValidationErrorsFactory,
  LocalFormGroupValidationErrors,
  OrderType,
  QueryResult
} from '../../../lib/util/services';
import { RightModel } from '../../../app.rights';
import { FileUploader } from 'ng2-file-upload';
import { TableDocument, TableDocumentService } from '../../../lib/table-document/table-document.service';
import { OffsetDateTime } from '../../../lib/util/dates';
import { TableDocumentSearch, TableDocumentSearchService } from '../../../lib/table-document/table-document-search.service';
import { RightResolver, RightService } from '../../../lib/right.service';
import { StateName } from '../../../app.state-names';
import { TranslateService } from '@ngx-translate/core';
import { FileUploaderUtil } from '../../../util/file-uploader-util';
import { UploadErrorLocalizer } from '../../../util/upload-error-localizer';
import { combineLatest, Observable } from 'rxjs';
import { Set } from 'immutable';
import { Transition } from '@uirouter/angular';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, NgForm, NgModel, Validators } from '@angular/forms';
import { DownloadedFile } from '../../../lib/util/downloaded-files';
import { TableDocumentSchema, TableDocumentSchemaService } from '../../../lib/table-document-schema/table-document-schema.service';
import { saveAs } from 'file-saver';
import { FileUploadDialogComponent } from '../../../shared/file-upload/dialog/file-upload-dialog.component';
import { Strings } from '../../../lib/util/strings';
import { AppValidators } from '../../../util/app-validators';
import { EmptyMessage } from '../../../lib/util/messages';
import { FieldError, FieldErrors, ObservableErrorResourceParser } from '../../../lib/util/errors';
/* eslint-enable */

@Component({
  selector: 'app-table-document-list',
  templateUrl: './table-document-list.component.html',
  styleUrls: ['./table-document-list.component.scss']
})
export class TableDocumentListComponent implements OnInit, OnDestroy {
  TableDocument = TableDocument;
  UiConstants = UiConstants;
  TableDocumentEditStatus = TableDocumentEditStatus;
  SelectUtils = SelectUtils;

  @ViewChild('baseDialog', { static: true }) baseDialog: ModalDirective;
  baseDialogVisible: boolean = false;
  tableDocumentEditModel: TableDocumentModel = new TableDocumentModel();

  @ViewChild('documentImportDialog', { static: true })
  documentImportDialog: FileUploadDialogComponent;

  @ViewChild('f')
  fForm: NgForm;

  @ViewChild('deleteModal', { static: true }) deleteModal: ModalDirective;
  deleteModalVisible: boolean = false;
  selectedDeleteItem: TableDocumentModel;

  formGroup: FormGroup;
  private formGroupValidationErrors: LocalFormGroupValidationErrors;

  private validatedInputs: LocalFieldValidationErrors<NgModel> =
    LocalFieldValidationErrorsFactory.empty();
  fieldErrors: TableDocumentErrorMap;

  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  queryModel: QueryFieldModel<TableDocument.OrderField> = new QueryFieldModel(TableDocument.OrderField.ID, OrderType.DESC);
  rightModel: RightModel = RightModel.empty();

  tableDocumentList: TableDocumentModel[] = [];

  searchResult: TableDocumentSearch.SearchDataResult;

  schemaImportPath: string = '';

  schemaItems: TableDocument.TableDocumentSchema[] = [];

  showSearch: boolean = false;

  searchModel: TableDocumentSearchModel = new TableDocumentSearchModel();

  constructor(private translateService: TranslateService,
              private rightService: RightService,
              private tableDocumentService: TableDocumentService,
              private uploadUtil: FileUploaderUtil,
              private uploadErrorLocalizer: UploadErrorLocalizer,
              private tableDocumentSearchService: TableDocumentSearchService,
              private transition: Transition,
              private changeDetector: ChangeDetectorRef,
              fb: FormBuilder,
              private tableDocumentSchemaService: TableDocumentSchemaService) {

    this.fieldErrors = {};
    this.formGroup = this.createFormGroup(fb);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(this.createForwardingHtmlForm(), this.formGroup);
  }

  ngOnInit() {
    this.loadRightModels();
    this.initBreadcrumb();
    this.initSchemaOptions(() => {
      this.loadSearch(() => {
        this.showSearch = !this.searchModel.isEmpty();
        this.loadList()
      })
    });
  }

  ngOnDestroy() {
    this.saveSearch();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  private createFormGroup(fb: FormBuilder): FormGroup {
    return fb.group(
      {
        code: fb.control(
          {value: this.tableDocumentEditModel.code},
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => {
                return this.tableDocumentEditModel.status === TableDocumentEditStatus.EDIT;
              }
            })
          ]
        ),
        name: fb.control(
          {value: this.tableDocumentEditModel.name},
          [
            Validators.required,
          ]
        ),
        schema: fb.control(
          {value: this.tableDocumentEditModel.schema},
          [
            Validators.required,
          ]
        ),
        note: fb.control(
          {value: this.tableDocumentEditModel.note},
        ),
      }
    );
  }

  private createForwardingHtmlForm() {
    return new ForwardingNgFormRef({
      formFn: () => {
        return this.fForm;
      }
    });
  }

  initBreadcrumb() {
    this.translateService.get('MENU_NAVBAR_TABLE_DOCUMENT').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
  }

  private loadSearch(completion: () => void) {
    const obs: Observable<SearchLoadResult> = combineLatest(
      this.tableDocumentSearchService.getSearchData({}),
      (storedSearchData: TableDocumentSearch.SearchDataResult) => {
        const result: SearchLoadResult = {
          storedSearchData: storedSearchData
        };
        return result;
      }
    );
    obs.subscribe(
      (result: SearchLoadResult) => {
        this.searchResult = result.storedSearchData;
        this.postInitSearch(result.storedSearchData, completion);
      }
    );
  }

  private postInitSearch(storedSearchData: TableDocumentSearch.SearchDataResult, completion: () => void) {
    this.queryModel.itemsPerPage = storedSearchData.searchData.itemsPerPage;
    this.queryModel.currentPage = storedSearchData.searchData.pageNumber;
    this.queryModel.setOrder(storedSearchData.searchData.order);
    this.searchModel.name = storedSearchData.searchData.name;
    this.searchModel.code = storedSearchData.searchData.code;
    this.searchModel.schemaId = storedSearchData.searchData.schemaId;
    completion()
  }

  private saveSearch() {
    const request = {
        searchData: {
          itemsPerPage: this.queryModel.itemsPerPage,
          pageNumber: this.queryModel.currentPage,
          order: this.queryModel.getOrder(),
          name: this.searchModel.name,
          code: this.searchModel.code,
          schemaId: this.searchModel.schemaId
        }
      }
    ;
    this.tableDocumentSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  private initSchemaOptions(completion: () => void) {
    this.schemaItems = [];
    this.tableDocumentSchemaService.query({
      versionState: 'FINALIZED',
      orders: Set.of({type: OrderType.ASC, field: TableDocument.OrderField.NAME})
    }).subscribe((result: QueryResult<TableDocumentSchema.TableDocumentSchema>) => {
      result.items.forEach((tableDocumentSchema: TableDocumentSchema.TableDocumentSchema) => {
        this.schemaItems.push({
          schemaId: tableDocumentSchema.schemaId,
          code: tableDocumentSchema.code,
          name: tableDocumentSchema.name,
          version: tableDocumentSchema.version!,
          versionState: tableDocumentSchema.versionState
        });
      });
      completion();
    });
  }

  loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();
    this.tableDocumentService.query({
      name: Strings.undefinedOrNonEmpty(this.searchModel.name),
      code: Strings.undefinedOrNonEmpty(this.searchModel.code),
      schemaId: this.searchModel.schemaId,
      orders: Set.of(order),
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined
    }).subscribe((result: QueryResult<TableDocument.TableDocument>) => {
      this.tableDocumentList = [];
      result.items.forEach((tableDocument: TableDocument.TableDocument) => {
        const tableDocumentModel = new TableDocumentModel();
        tableDocumentModel.documentId = tableDocument.documentId;
        tableDocumentModel.creationTime = tableDocument.creationTime;
        tableDocumentModel.contentUpdateTime = tableDocument.contentUpdateTime ? tableDocument.contentUpdateTime.toUtcIsoString() : '';
        tableDocumentModel.code = tableDocument.code;
        tableDocumentModel.name = tableDocument.name;
        tableDocumentModel.note = tableDocument.note;
        tableDocumentModel.exportable = tableDocument.exportable;
        tableDocumentModel.schema = tableDocument.schema;
        tableDocumentModel.schemaVersion = tableDocument.schema.name + ' (' + tableDocument.schema.version + ')';
        tableDocumentModel.schemaId = tableDocument.schema.schemaId;
        this.tableDocumentList.push(tableDocumentModel);
      });
      this.queryModel.currentPage = requestedPage;
      this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
    });
  }

  orderBy(field: TableDocument.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    this.formGroup.updateValueAndValidity();
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  removeFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.fieldErrors, fieldError);
  }

  showBaseDialog(document?: TableDocumentModel) {

    if (document) {
      this.loadEditModel(document)
    }
    else {
      this.tableDocumentEditModel = new TableDocumentModel();
      this.tableDocumentEditModel.status = TableDocumentEditStatus.CREATE;
      this.openBaseDialog();
    }
  }

  loadEditModel(model: TableDocumentModel) {
    this.tableDocumentService.get(
      {
        documentId: model.documentId,
        schemaId: model.schemaId
      }
    ).subscribe((tableDocument: TableDocument.TableDocument) => {
      this.tableDocumentEditModel = new TableDocumentModel();

      this.tableDocumentEditModel.schemaId = tableDocument.schema.schemaId;
      this.tableDocumentEditModel.documentId = tableDocument.documentId;
      this.tableDocumentEditModel.code = tableDocument.code;
      this.tableDocumentEditModel.name = tableDocument.name;
      this.tableDocumentEditModel.note = tableDocument.note;
      this.tableDocumentEditModel.schema = tableDocument.schema;
      this.tableDocumentEditModel.status = TableDocumentEditStatus.EDIT;
      this.openBaseDialog();
    });
  }

  openBaseDialog() {
    this.baseDialogVisible = true;
    this.baseDialog.show();
    this.changeDetector.detectChanges();
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(this.createForwardingHtmlForm(), this.formGroup);
  }

  closeBaseDialog() {
    this.baseDialogVisible = false;
    this.baseDialog.hide();
    this.formGroup.reset();
  }

  createTableDocument() {
    this.formGroup.updateValueAndValidity();
    if (this.formGroup.invalid) {
      this.formGroup.get('code')!.markAsTouched();
      this.formGroup.get('name')!.markAsTouched();
      return;
    }
    if (this.tableDocumentEditModel.status === TableDocumentEditStatus.CREATE) {
      this.create();
    }
    else {
      this.update();
    }
  }

  create() {
    this.tableDocumentService.create(
      {
        schemaId: this.tableDocumentEditModel.schema.schemaId,
        code: this.tableDocumentEditModel.code,
        name: this.tableDocumentEditModel.name,
        note: this.tableDocumentEditModel.note
      }
    ).subscribe(
      (result: EmptyMessage) => {
        this.baseDialogVisible = false;
        this.baseDialog.hide();
        this.formGroup.reset();
        this.loadList();
      },
      (error: any) => {
        const res = ObservableErrorResourceParser.parseError(error);
        this.fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
      });
  }

  update() {
    this.tableDocumentService.update(
      {
        schemaId: this.tableDocumentEditModel.schemaId,
        documentId: this.tableDocumentEditModel.documentId,
        name: this.tableDocumentEditModel.name,
        note: this.tableDocumentEditModel.note
      }
    ).subscribe(
      (result: EmptyMessage) => {
        this.baseDialogVisible = false;
        this.baseDialog.hide();
        this.formGroup.reset();
        this.loadList();
      },
      (error: any) => {
        const res = ObservableErrorResourceParser.parseError(error);
        this.fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
      });
  }

  deleteDocument() {
    const model = this.selectedDeleteItem;
    this.tableDocumentService.delete(
      {
        schemaId: model.schemaId,
        documentId: model.documentId
      }).subscribe(
      (result: EmptyMessage) => {
      },
      (error: Error) => {
      },
      () => {
        this.closeDeleteModal();
        this.loadList()
      });
  }

  exportDocument(model: TableDocumentModel) {

    this.tableDocumentService.export({
      schemaId: model.schemaId,
      documentId: model.documentId
    }).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName('table_document_' + model.documentId + '.json'));
      });
  }

  importDocument(model: TableDocumentModel) {
    this.schemaImportPath = '/table-document/schema/' + model.schemaId + '/document/' + model.documentId + '/xlsx';
    this.documentImportDialog.toggleDialog()
  }

  public onImportSuccess(succeeded: boolean) {
    if (succeeded) {
      this.loadList(1);
    }
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  onSearchReset() {
    this.tableDocumentSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.showSearch = true;
          this.loadList(1);
        });
      }
    );
  }

  onSearchClicked() {
    this.loadList(1);
  }

  getBaseModalTitle(): string {
    if (this.tableDocumentEditModel.status === TableDocumentEditStatus.EDIT) {
      return 'TABLE_DOCUMENT_EDIT_TITLE'
    }
    return 'TABLE_DOCUMENT_CREATE_TITLE'
  }

  openDeleteModal(model: TableDocumentModel) {
    this.selectedDeleteItem = model;
    this.deleteModalVisible = true;
    this.deleteModal.show();
  }

  closeDeleteModal() {
    this.deleteModalVisible = false;
    this.deleteModal.hide();
  }

}

class TableDocumentModel {
  documentId: number;
  creationTime?: OffsetDateTime;
  contentUpdateTime: string = '';
  code: string = '';
  name: string = '';
  note: string = '';
  exportable: boolean;
  schema: TableDocument.TableDocumentSchema;
  schemaVersion: string = '';
  schemaId: number;
  status: TableDocumentEditStatus;
}

class TableDocumentSearchModel {

  name: string;
  code: string;
  schemaId?: number;

  public isEmpty(): boolean {

    return this.name.length === 0
      && this.code.length === 0
      && !this.schemaId
  }

  public clear() {
    this.name = '';
    this.code = '';
    this.schemaId = undefined;

  }

  constructor() {
  }

}

enum TableDocumentEditStatus {
  CREATE = 'CREATE',
  EDIT = 'EDIT'
}

interface SearchLoadResult {
  storedSearchData: TableDocumentSearch.SearchDataResult,
}

interface TableDocumentErrorMap {
  code?: FieldError;
  name?: FieldError;
  schema?: FieldError;
}
