/* eslint-disable */
import { Logger, LoggerFactory } from '../../../../../util/logger-factory';
import { Form } from '../../../../../lib/form/form.service';
import { Set as ImmutableSet } from 'immutable';
import { FormRecordListItemFieldView, FormRecordListItemValue } from '../../../../../util/form/form-utils';
import {
  FieldActivationStrategy,
  FieldActivationStrategyCalculateInactivatesArgs,
  FieldActivationStrategyPutRulesArgs,
  FieldViewItem,
} from '../form-record-inactivity-manager-api';
import { FieldActivationStrategyUtils } from './field-activation-strategy-utils';
import { Injectable } from '@angular/core';

/* eslint-enable */

@Injectable()
export class ListItemFieldActivationStrategy implements FieldActivationStrategy {

  private logger: Logger = LoggerFactory.createLogger('ListItemFieldActivationStrategy');

  constructor() {
  }

  putRules(args: FieldActivationStrategyPutRulesArgs) {
    const attrs: Form.FieldDataTypeListItemAttributes = args.viewItem.field.dataType.listItemAttributes!;
    if (attrs.fieldActivationEnabled) {
      const ids: Set<number> = new Set();
      attrs.activatedFieldIdsByItemId.forEach((activatedFieldIds: ImmutableSet<number>, itemId: number) => {
        activatedFieldIds!.forEach(activatedFieldId => ids.add(activatedFieldId!));
      });
      attrs.activatedGroupIdsByItemId.forEach((activatedGroupIds: ImmutableSet<number>, itemId: number) => {
        activatedGroupIds!.forEach(activatedGroupId => {
          args.form.groups
            .find(group => group!.groupId === activatedGroupId)!
            .fields.forEach(field => ids.add(field!.fieldId));
        });
      });
      ids.forEach(id => {
        const fieldViewToActivate = args.viewItems.findByFieldId(id);
        if (fieldViewToActivate) {
          this.logger.debug('ListItem "%s" activates "%s"', args.viewItem.fieldTitle, fieldViewToActivate.fieldTitle);
          args.builder.put(args.viewItem, fieldViewToActivate);
        }
      });
    }
  }

  async calculateInactivates(args: FieldActivationStrategyCalculateInactivatesArgs): Promise<FieldViewItem[]> {
    const view: FormRecordListItemFieldView = <FormRecordListItemFieldView> args.viewItem.view;
    const value: number | null = await this.getListItemValue(view);
    const attrs = args.viewItem.field.dataType.listItemAttributes!;
    if (value === null) {
      return args.activatees;
    }
    else {
      const mergedActivatedFieldIds: number[] = [];
      const activatedFieldIds = attrs.activatedFieldIdsByItemId.get(value);
      if (activatedFieldIds) {
        mergedActivatedFieldIds.push(...activatedFieldIds.toArray());
      }
      const activatedGroupIds = attrs.activatedGroupIdsByItemId.get(value);
      if (activatedGroupIds) {
        activatedGroupIds.forEach(groupId =>
          mergedActivatedFieldIds.push(...args.form.groups.find(group => group!.groupId === groupId)
            .fields.toArray().map(field => field.fieldId)))
      }
      const activatedViewItems: FieldViewItem[] = FieldActivationStrategyUtils.collectViewItemsFromFieldIdSet(
        ImmutableSet(mergedActivatedFieldIds),
        args.activatees
      );
      return FieldActivationStrategyUtils.without(args.activatees, activatedViewItems);
    }
  }

  private async getListItemValue(view: FormRecordListItemFieldView): Promise<number | null> {
    const value: FormRecordListItemValue | null = await view.valueReference.get();
    if (value === null) {
      return null;
    }
    return value.id;
  }

}
