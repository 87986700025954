/* eslint-disable */
import { AuthResult, AuthService, LoginByCredentialRequest } from './auth.service';
import { StringKey } from '../app.string-keys';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { LoginOptions, LoginOptionsHelper } from './util/log-helpers';
import { ConfigurationService } from './core-ext/configuration.service';
import { Injectable } from '@angular/core';
/* eslint-enable */

export interface CredentialLoginMethod {
  getName(): string;
  getIconClass(): string;
  login(request: LoginByCredentialRequest): Observable<AuthResult>;
}

@Injectable()
export class CredentialLoginMethodFactory {

  public constructor(
    private authService: AuthService,
    private translateService: TranslateService,
    private loginOptionsHelper: LoginOptionsHelper) {
  }

  public createAvailableLoginMethods(): CredentialLoginMethod[] {
    const methods: CredentialLoginMethod[] = [];
    const loginOptions = this.loginOptionsHelper.getLoginOptions();
    loginOptions.forEach(loginOption => {
      if (loginOption === LoginOptions.LOCAL_USER) {
        methods.push(new LocalUserLoginMethod(this.authService, this.translateService));
      }

      if (loginOption === LoginOptions.LDAP_USER) {
        methods.push(new LdapUserLoginMethod(this.authService, this.translateService));
      }
    });
    return methods;
  }

}

class LocalUserLoginMethod implements CredentialLoginMethod {

  constructor(private authService: AuthService, private translateService: TranslateService) {
  }

  public getName(): string {
    return this.translateService.instant(StringKey.LOGIN_METHOD_NAME_LOCAL_USER);
  }

  public getIconClass(): string {
    return 'icomoon-user-local';
  }

  public login(request: LoginByCredentialRequest): Observable<AuthResult> {
    return this.authService.localUserLogin(request);
  }

}

class LdapUserLoginMethod implements CredentialLoginMethod {

  constructor(private authService: AuthService, private translateService: TranslateService) {
  }

  public getName(): string {
    return this.translateService.instant(StringKey.LOGIN_METHOD_NAME_LDAP_USER);
  }

  public getIconClass(): string {
    return 'icomoon-user-ldap';
  }

  public login(request: LoginByCredentialRequest): Observable<AuthResult> {
    return this.authService.ldapUserLogin(request);
  }

}
