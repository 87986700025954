<div class="card">
  <div class="card-header">
    {{'INVOICES_LIST_HEADING'|translate}}
  </div>
  <div class="card-body">
    <div class="col-sm-12 p-0">

      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header hidden-md-down">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op
              [text]="'INVOICES_TABLE_HEADER_INVOICE_NUMBER' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-sorter-no-op [text]="'COMMON_CREATION_TIME' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-sorter-no-op [text]="'INVOICE_APPEARANCE_TYPE_SHORT' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-sorter-no-op [text]="'INVOICE_SETTINGS_INTEGRATION_TYPE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op [text]="'INVOICE_SETTINGS_PROFILE_NAME' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op [text]="'INVOICE_SETTINGS_COMPANY_NAME' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr class="table" *ngFor="let invoice of invoices">
          <td class="type-cell text-center hidden-md-down"><i
            class="icomoon icons-table-item {{getInvoiceIconClass(invoice.categoryType)}} icomoon-detail-table"
            title="{{getInvoiceTooltipKey(invoice.categoryType) | translate}}"></i></td>
          <td class="responsive-table-column">{{invoice.invoiceNumber}}</td>
          <td
            class="responsive-table-column hidden-xs-down">{{invoice.creationTime.toUtcIsoString() | date:'short' }}</td>
          <td
            class="responsive-table-column hidden-md-down">{{getAppearanceTypeStringKey(invoice.appearanceType) | translate}}</td>
          <td class="responsive-table-column hidden-md-down">
            <img src="{{getIntegrationTypeIcon(invoice.invoiceSettings.invoiceIntegrationType)}}"
                 class="w-100 invoice-integration-icon" alt="IntegrationIcon"
                 *ngIf="hasIntegrationTypeIcon(invoice.invoiceSettings.invoiceIntegrationType)">
            <span
              *ngIf="!hasIntegrationTypeIcon(invoice.invoiceSettings.invoiceIntegrationType)"> {{getIntegrationTypeString(invoice.invoiceSettings.invoiceIntegrationType)}}</span>
          </td>
          <td class="responsive-table-column hidden-sm-down">{{invoice.invoiceSettings.profileName}}</td>
          <td class="responsive-table-column hidden-sm-down">{{invoice.invoiceSettings.companyName}}</td>
          <td nowrap>
            <a class="icons-table-group pull-right cursor-pointer">
              <i class="icomoon icons-table-item icomoon-download icomoon-detail-table"
                 (click)="downloadInvoice(invoice.id)"
                 title="{{'COMMON_BUTTON_DOWNLOAD' | translate}}"></i>
              <i uiSref="Admin.InvoiceDetail"
                 [uiParams]="{ id: invoice.id }"
                 class="icomoon icons-table-item icomoon-detail icomoon-detail-table"
                 title="{{'COMMON_BUTTON_DETAILS' | translate}}"></i>
            </a>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header hidden-md-down">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op
              [text]="'INVOICES_TABLE_HEADER_INVOICE_NUMBER' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-sorter-no-op [text]="'COMMON_CREATION_TIME' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-sorter-no-op [text]="'INVOICE_APPEARANCE_TYPE_SHORT' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-sorter-no-op [text]="'INVOICE_SETTINGS_INTEGRATION_TYPE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op [text]="'INVOICE_SETTINGS_PROFILE_NAME' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op [text]="'INVOICE_SETTINGS_COMPANY_NAME' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>
