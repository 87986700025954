import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { SearchUtils } from '../../../util/search-utils';
import { MultiselectOptionItem, OrderFieldFunction, OrderFieldModel, SelectUtils, UiConstants } from '../../../util/core-utils';
import { OrderField } from '../../../lib/query/orderfields';
import { OrderType, QueryResult } from '../../../lib/util/services';
import { Angular2Multiselects } from '../../../util/multiselect';
import { RightModel } from '../../../app.rights';
import {
  ExteriorTransportSearch,
} from '../../../lib/exterior-transport/exterior-transport-search-service';
import { CompanyService } from '../../../lib/company/company.service';
import { RightResolver, RightService } from '../../../lib/right.service';
import { FilterField } from '../../../lib/query/filterfields';
import { CriteriaBuilder, Models } from '../../../util/model-utils';
import { Dates } from '../../../lib/util/dates';
import {
  MileageRecordSearch,
  MileageRecordSearchService
} from '../../../lib/mileage-record/mileage-record-search.service';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { StateName } from '../../../app.state-names';
import { MileageRecord, MileageRecordService } from '../../../lib/mileage-record/mileage-record.service';
import { UserMultiselectProvider } from '../../../lib/user/user-multiselect.provider';
import { VehicleMultiselectProvider } from '../../../lib/vehicles/vehicle-multiselect.provider';
import { TaskRecordMultiselectProvider } from '../../../lib/task/task-record-multiselect.provider';
import { MileageRecordModel } from '../mileage-record.model';
import { DownloadedFile } from '../../../lib/util/downloaded-files';
import { saveAs } from 'file-saver';
import { AssigneeType } from '../../../shared/assignee-table-cell/assignee-table-cell.component';
import { Arrays } from '../../../lib/util/arrays';
import { UserGroupMultiselectProvider } from '../../../lib/user-group/user-group-multiselect.provider';
import { ConfigurationService } from '../../../lib/core-ext/configuration.service';
import { TaskRecordStateMachine } from '../../../lib/task/task-record-statemachine';

@Component({
  selector: 'app-mileage-record-list',
  templateUrl: './mileage-record-list.component.html',
  styleUrls: ['./mileage-record-list.component.scss']
})
export class MileageRecordListComponent extends SearchUtils.SearchableList<MileageRecordSearch.Model> implements OnInit, OnDestroy {

  UiConstants = UiConstants;
  SelectUtils = SelectUtils;
  MileageRecordSearch = MileageRecordSearch;
  AssigneeType = AssigneeType;

  queryModel: OrderFieldModel<OrderField.MileageRecord>
    = new OrderFieldModel(MileageRecordSearch.OrderFunctions.ID, OrderType.DESC);
  searchModel: MileageRecordSearch.Model = new MileageRecordSearch.Model();
  users: MultiselectOptionItem<number>[] = [];
  vehicles: MultiselectOptionItem<number>[] = [];
  taskRecords: MultiselectOptionItem<number>[] = [];
  taskRecordStates: MultiselectOptionItem<TaskRecordStateMachine.State>[] = [];

  dropdownSettings: Angular2Multiselects.Settings;
  localDropdownSettings: Angular2Multiselects.Settings = Angular2Multiselects.LOCAL_MULTI_SELECT;

  mileageRecordList: MileageRecordModel[] = [];
  relatedUserGroups: MultiselectOptionItem<number>[] = [];
  rightModel: RightModel = RightModel.empty();

  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  get maconomyEnabled(): boolean {
    return this.configurationService.getConfiguration().feature_flags.maconomy_enabled;
  }

  constructor(
    private mileageRecordService: MileageRecordService,
    private mileageRecordSearchService: MileageRecordSearchService,
    private taskRecordMultiselectProvider: TaskRecordMultiselectProvider,
    private vehicleMultiselectProvider: VehicleMultiselectProvider,
    private userMultiselectProvider: UserMultiselectProvider,
    private userGroupMultiselectProvider: UserGroupMultiselectProvider,
    private companyService: CompanyService,
    private rightService: RightService,
    private configurationService: ConfigurationService,
    injector: Injector) {
    super(MileageRecordSearch.Model, injector);
  }

  ngOnInit() {
    this.loadTaskRecordStatesForSearch();
    this.loadRightModels();
    this.initBreadcrumb();
    this.initSearch();
    this.initDropdownSettings();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  initBreadcrumb() {
    this.translateService.get('MENU_NAVBAR_MILEAGE_RECORDS').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
  }

  initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .remoteSearch(true)
      .enableCheckAll(false)
      .build();
  }

  loadSearch(completion: () => void) {
    this.mileageRecordSearchService.getSearchData({})
      .subscribe(
        (result: MileageRecordSearch.SearchDataResult) => {
          this.queryModel = result.searchData.queryModel;
          this.searchModel = result.searchData.searchModel;
          completion();
        }
      );
  }

  onFirstSearchOpen(): void {
  }

  onVehicleSearch(predicate?: string) {
    this.vehicleMultiselectProvider.loadAll(predicate).subscribe((items) => {
      this.vehicles = items;
    })
  }

  onTaskRecordSearch(predicate?: string) {
    this.taskRecordMultiselectProvider.loadAll(predicate).subscribe((items) => {
      this.taskRecords = items;
    })
  }

  onUserSearch(predicate?: string) {
    this.userMultiselectProvider.loadAll(predicate).subscribe((items) => {
      this.users = items;
    })
  }

  private loadTaskRecordStatesForSearch() {
    this.taskRecordStates = [];
    TaskRecordStateMachine.getOrderedStates(true).forEach(
      (state?: TaskRecordStateMachine.StateObject) => {
        if (state) {
          const item1 = {
            id: state.state,
            itemName: '...'
          };
          this.taskRecordStates.push(item1);
          this.translateService.get(state.stringKey).subscribe((text: string) => {
            item1.itemName = text;
          });
        }
      });
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: OrderFieldFunction<OrderField.MileageRecord>) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const request = this.getQueryRequest(requestedPage);
    this.mileageRecordService.query(request).subscribe((result: QueryResult<MileageRecord.MileageRecord>) => {
      this.mileageRecordList = [];
      result.items.forEach((mileageRecord: MileageRecord.MileageRecord) => {
        const model = new MileageRecordModel();
        model.id = mileageRecord.id;
        model.vehicleId = mileageRecord.vehicle.id;
        model.user = mileageRecord.user;
        model.vehicleLicencePlate = mileageRecord.vehicle.licencePlate;
        model.startKilometer = mileageRecord.startKilometer.toNumber();
        model.creationTime = mileageRecord.creationTime;
        model.finishTime = mileageRecord.finishTime ? mileageRecord.finishTime : Dates.emptyOffsetDateTime();
        model.finishKilometer = mileageRecord.finishKilometer ? mileageRecord.finishKilometer.toNumber() : undefined;
        model.note = mileageRecord.note ? mileageRecord.note : '';
        model.journeyType = mileageRecord.journeyType ? mileageRecord.journeyType : '';
        if (mileageRecord.taskRecord) {
          model.taskId = mileageRecord.taskRecord.taskId;
          model.taskRecordId = mileageRecord.taskRecord.id;
          model.taskName = mileageRecord.taskRecord.name;
          model.customerName = mileageRecord.taskRecord.customerName ? mileageRecord.taskRecord.customerName : '';
        }
        this.mileageRecordList.push(model);
      });
      this.loadRelatedUserGroups();
      this.queryModel.currentPage = requestedPage;
      this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
    });
  }

  private getQueryRequest(requestedPage?: number): MileageRecord.QueryRequest {
    const order = this.queryModel.createOrderFunction();
    const filter = this.createFilter();
    return {
      fields: f => f.each,
      order: order,
      filter: filter,
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined,
    };
  }

  private createFilter() {
    return (f: FilterField.MileageRecord) => CriteriaBuilder.builder()
      .addDateTime((creationTime) => f.creationTime.after(creationTime), Models.parseDateTimeFrom(this.searchModel.creationTimeFrom))
      .addDateTime((creationTime) => f.creationTime.before(creationTime), Models.parseDateTimeFrom(this.searchModel.creationTimeTo))
      .addDateTime((creationTime) => f.finishTime.after(creationTime), Models.parseDateTimeFrom(this.searchModel.finishTimeFrom))
      .addDateTime((creationTime) => f.finishTime.before(creationTime), Models.parseDateTimeFrom(this.searchModel.finishTimeTo))
      .addEnum(taskRecordState => f.taskRecord.state.in(taskRecordState), this.searchModel.taskRecordStates)
      .addString((journeyType) => f.journeyType.containsIgnoreCase(journeyType), this.searchModel.journeyType)
      .addNumber((taskRecordId) => f.taskRecord.id.eq(taskRecordId), this.searchModel.taskRecordId)
      .addNumber((userId) => f.user.id.eq(userId), this.searchModel.userId)
      .addNumber((vehicleId) => f.vehicle.id.eq(vehicleId), this.searchModel.vehicleId)
      .build();
  }

  private loadRelatedUserGroups() {
    this.userGroupMultiselectProvider.getByIds(
      Arrays.flatten(this.mileageRecordList.map(w => w.user.userGroupIds))
    ).subscribe(result => this.relatedUserGroups = result);
  }

  onSearchClicked() {
    this.loadList(1);
  }

  onSearchReset() {
    this.mileageRecordSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.toggleSearch();
          this.loadList(1);
        });
      }
    );
  }

  ngOnDestroy() {
    this.saveSearch();
  }

  private saveSearch() {
    const request = {
        searchData: {
          queryModel: this.queryModel,
          searchModel: this.searchModel
        }
      }
    ;
    this.mileageRecordSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  exportXls() {
    const request = this.getQueryRequest();
    this.mileageRecordService.exportXls(request).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName('mileage-record.xlsx'));
      }
    );
  }

  exportMaconomy() {
    if (!this.maconomyEnabled) {
      return;
    }
    const request = this.getQueryRequest();
    this.mileageRecordService.exportMaconomy(request).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName('mileage-record.xlsx'));
      }
    );
  }

}
