/* eslint-disable */
import {Component, EventEmitter, Input, OnInit, Output, ViewChild,} from '@angular/core';
import {MasterDataRecord} from '../../../../lib/masterdata/master-data-record.service';
import {FormBuilder, FormGroup, NgForm, NgModel, ValidatorFn, Validators,} from '@angular/forms';
import {
  FieldValidationError,
  ForwardingNgFormRef,
  LocalFormGroupValidationErrors,
  Services,
} from '../../../../lib/util/services';
import {MasterDataRecordBase,} from './masterdata-record-base-view';
import {
  ImmutableUserGroupItem,
  ImmutableUserItem,
  MultiselectOptionItem,
  UiConstants
} from '../../../../util/core-utils';
import {UIRouter} from '@uirouter/angular';
import {StringKey} from '../../../../app.string-keys';
import {Angular2Multiselects} from '../../../../util/multiselect';
import {TranslateService} from '@ngx-translate/core';
import {Set} from 'immutable';
import {AppValidators} from '../../../../util/app-validators';
import {
  CustomerRecordContactLocationMultiselectProvider
} from '../../../../lib/customer/contact/customer-record-contact-location-multiselect.provider';
import {Multiselect} from '../../../../util/multiselect-helper';
import {UserService} from '../../../../lib/user.service';
import {UserGroup, UserGroupService} from '../../../../lib/user-group.service';
import {of} from 'rxjs';
import {MultiselectUser} from '../../../../util/multiselect-user';
import {StateName} from '../../../../app.state-names';
import {
  CustomerRecordMultiselectItem,
  CustomerRecordMultiselectProvider
} from "../../../../lib/customer/customer-record-multiselect-provider.service";

/* eslint-enable */

@Component({
  selector: 'app-masterdata-record-base',
  templateUrl: 'masterdata-record-base.component.html',
  styleUrls: ['masterdata-record-base.component.scss'],
})
export class MasterDataRecordBaseComponent implements OnInit, MasterDataRecordBase.View {

  MasterDataRecord = MasterDataRecord;
  UiConstants = UiConstants;

  @ViewChild('name')
  nameInput: NgModel;

  @Input()
  form?: NgForm;

  @Input()
  requiredId?: string;

  @Input()
  headingDictionaryKey: string;

  @Input()
  readonly: boolean;

  @Input()
  allowClone: boolean;

  @Input()
  externalIdRequired?: boolean;

  @Output()
  submitted = new EventEmitter<boolean>();

  fieldStyle = {
    'padding': UiConstants.formRecordFieldPadding
  };

  formGroup: FormGroup;

  model: MasterDataRecordBase.Model = new MasterDataRecordBase.Model();
  masterDataRecord?: MasterDataRecord.MasterDataRecord;

  dropdownSettings: Angular2Multiselects.Settings;
  userDropdownSettings: Angular2Multiselects.Settings;
  userGroupDropdownSettings: Angular2Multiselects.Settings;
  ownerCustomerItems: CustomerRecordMultiselectItem[] = [];
  ownerContactLocations: MultiselectOptionItem<number>[] = [];

  multiselectUserLoadingHelper: Multiselect.LoadingHelper<ImmutableUserItem>;

  private fieldErrors: FieldValidationError<MasterDataRecord.ValidatedField> =
    FieldValidationError.empty<MasterDataRecord.ValidatedField>();

  private formGroupValidationErrors: LocalFormGroupValidationErrors;

  constructor(private translateService: TranslateService,
              private customerRecordMultiselectProvider: CustomerRecordMultiselectProvider,
              private customerRecordContactLocationMultiselectProvider: CustomerRecordContactLocationMultiselectProvider,
              private fb: FormBuilder,
              private userService: UserService,
              private userGroupService: UserGroupService,
              private uiRouter: UIRouter) {
  }

  getModel(): MasterDataRecordBase.Model {
    return this.model;
  }

  getFormGroup(): FormGroup {
    return this.formGroup;
  }

  loadMasterDataRecord(args: MasterDataRecordBase.Args) {
    this.loadCurrentUsers(args.masterDataRecord ? args.masterDataRecord.ownerUserIds : undefined);
    this.loadUserGroupItems(args.masterDataRecord ? args.masterDataRecord.ownerGroupIds : undefined);
    if (args.masterDataRecord) {
      this.model.load(args.masterDataRecord);
      this.masterDataRecord = args.masterDataRecord;
      this.loadOwnerCustomerRecord(this.masterDataRecord.ownerCustomerRecordId);
      this.loadOwnerContactLocation(this.masterDataRecord.ownerContactLocationId);
      this.initOwnerContactLocationSearch(this.masterDataRecord.ownerCustomerRecordId);
    }
  }

  loadCurrentUsers(ownerUserIds?: Set<number>) {
    this.multiselectUserLoadingHelper.loadCurrentItems(ownerUserIds);
  }

  loadUserSearch(searchValue?: string) {
    this.multiselectUserLoadingHelper.loadSearch(searchValue);
  }

  loadUserGroupItems(ownerGroupIds?: Set<number>, searchValue?: string) {
    const groupItems: ImmutableUserGroupItem[] = [];
    const groups: ImmutableUserGroupItem[] = [];
    this.userGroupService.query({
      name: searchValue,
      order: Services.createOrderFieldParameter(UserGroup.Keys.toOrderFieldKey, Set.of(UserGroup.DEFAULT_ORDER)),
      page_number: 1,
      number_of_items: UiConstants.autocompletePageSize,
      disabled: false,
      no_progress_bar: true
    }).subscribe(result => {
      result.items.forEach(item => {
        const i = new ImmutableUserGroupItem(
          item.id,
          of(item.name),
          undefined,
          item.disabled
        );
        groupItems.push(i);
        if (searchValue === undefined && ownerGroupIds && ownerGroupIds.contains(i.id!)) {
          groups.push(i);
        }
      });
      if (searchValue === undefined && ownerGroupIds) {
        this.model.setUserGroups(groups);
      }
      this.model.setUserGroupItems(groupItems);
      this.formGroup.controls['userGroup'].updateValueAndValidity();
      if (searchValue === undefined && ownerGroupIds && groups.length !== ownerGroupIds.size) {
        groups.splice(0, groups.length);
        this.userGroupService.query({
          id: ownerGroupIds.join(','),
          order: Services.createOrderFieldParameter(UserGroup.Keys.toOrderFieldKey, Set.of(UserGroup.DEFAULT_ORDER))
        }).subscribe(result => {
          result.items.forEach(item => {
            const i = new ImmutableUserGroupItem(
              item.id,
              of(item.name),
              undefined,
              item.disabled
            );
            groups.push(i);
          });
          this.model.setUserGroups(groups);
          this.formGroup.controls['userGroup'].updateValueAndValidity();
        });
      }
    });
  }

  onFieldValidationError(fieldErrors: FieldValidationError<MasterDataRecord.ValidatedField>) {
    this.fieldErrors = fieldErrors;
  }

  ngOnInit(): void {
    this.initDropdownSettings();
    this.formGroup = this.createFormGroup(this.fb);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(
      this.createForwardingHtmlForm(),
      this.formGroup
    );
    this.multiselectUserLoadingHelper = this.createMultiselectUserLoadingHelper();
  }

  private createMultiselectUserLoadingHelper(): Multiselect.LoadingHelper<ImmutableUserItem> {
    return Multiselect.LoadingHelper.forEdit({
      topActiveItem$: MultiselectUser.topActiveItem$(this.userService, MultiselectUser.ImmutableUserItemMapper),
      searchItem$: MultiselectUser.searchItem$(this.userService, MultiselectUser.ImmutableUserItemMapper),
      modelStrategy: this.createMultiselectUserModel(),
      validationStrategy: Multiselect.FormControlValidationStrategy.of(this.formGroup.controls['user']),
      unknownItemFactory: MultiselectUser.createUnknownImmutableUserItemFactory(this.translateService)
    });
  }

  private createMultiselectUserModel(): Multiselect.ModelStrategy<ImmutableUserItem> {
    const self = this;
    return {
      applySelectableItems(items: ImmutableUserItem[]) {
        self.model.setUserItems(items);
      },
      applySelectedItems(items: ImmutableUserItem[]) {
        self.model.setUsers(items);
      }
    };
  }

  onCustomerRecordSearch(q?: string) {
    this.ownerCustomerItems = [];
    this.customerRecordMultiselectProvider.loadActiveNonContact(q).subscribe(
      (result: CustomerRecordMultiselectItem[]) => {
        this.ownerCustomerItems = result;
      }
    );
  }

  onOwnerContactLocationSearch(predicate?: string) {
    const ownerCustomerRecordId = this.model._ownerCustomerRecord.length > 0 ? this.model._ownerCustomerRecord[0].id : undefined;
    if (ownerCustomerRecordId) {
      this.customerRecordContactLocationMultiselectProvider.loadForCustomer(ownerCustomerRecordId, predicate)
        .subscribe((items) => {
          this.ownerContactLocations = items;
        })
    }
  }

  private initOwnerContactLocationSearch(ownerCustomerRecordId?: number) {
    if (ownerCustomerRecordId) {
      this.customerRecordContactLocationMultiselectProvider.loadForCustomer(ownerCustomerRecordId, undefined)
        .subscribe((items) => {
          this.ownerContactLocations = items;
        })
    }
  }

  private loadOwnerContactLocation(ownerContactLocationId?: number) {
    if (ownerContactLocationId) {
      this.customerRecordContactLocationMultiselectProvider.getById(ownerContactLocationId).subscribe((item) => {
        this.model.ownerContactLocation = item;
      })
    }
  }

  private loadOwnerCustomerRecord(ownerCustomerRecordId?: number) {
    if (ownerCustomerRecordId) {
      this.customerRecordMultiselectProvider.getById(ownerCustomerRecordId).subscribe((item) => {
        this.model.ownerCustomerRecord = item;
      })
    }
  }

  onOwnerCustomerRecordChanged() {
    this.onOwnerContactLocationSearch();
    this.model.ownerContactLocation = undefined;
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  hasLocalFormError(errorCode: string): boolean {
    return this.formGroupValidationErrors.hasFormError(errorCode);
  }

  hasFieldError(field: MasterDataRecord.ValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: MasterDataRecord.ValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: MasterDataRecord.ValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
    this.userDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .text(StringKey.COMMON_PLACEHOLDER_USER_NOT_SELECTED_YET)
      .selectAllText(StringKey.COMMON_PLACEHOLDER_SELECT_ALL_USER)
      .unSelectAllText(StringKey.COMMON_PLACEHOLDER_SELECT_ALL_USER)
      .noDataLabel(StringKey.COMMON_PLACEHOLDER_EMPTY_USER_LIST)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .remoteSearch(true)
      .build();
    this.userGroupDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .text(StringKey.COMMON_PLACEHOLDER_USER_GROUP_NOT_SELECTED_YET)
      .selectAllText(StringKey.COMMON_PLACEHOLDER_SELECT_ALL_USER_GROUP)
      .unSelectAllText(StringKey.COMMON_PLACEHOLDER_SELECT_ALL_USER_GROUP)
      .noDataLabel(StringKey.COMMON_PLACEHOLDER_EMPTY_USER_GROUP_LIST)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .remoteSearch(true)
      .build();
  }

  private createForwardingHtmlForm() {
    return new ForwardingNgFormRef({
      formFn: () => {
        return this.form;
      }
    });
  }

  private getExternalIdRequirement(): ValidatorFn | undefined {
    if (this.externalIdRequired === true || this.requiredId === 'EXTERNAL_ID') {
      return Validators.required;
    } else {
      return undefined;
    }
  }

  private getNameRequirement(): ValidatorFn | undefined {
    if (this.requiredId === 'NAME') {
      return Validators.required;
    } else {
      return undefined;
    }
  }

  private createFormGroup(fb: FormBuilder): FormGroup {
    return fb.group(
      {
        name: fb.control(
          {value: this.model.name},
          this.getNameRequirement()
        ),
        externalId: fb.control(
          {value: this.model.externalId},
          this.getExternalIdRequirement()
        ),
        user: fb.control(
          {value: this.model.users},
          [
            AppValidators.validateEnabledItems
          ]
        ),
        userGroup: fb.control(
          {value: this.model.userGroups},
          [
            AppValidators.validateEnabledItems
          ]
        ),
        ownerCustomer: fb.control(
          {value: this.model.ownerCustomerRecord},
          AppValidators.validateEnabledItems
        ),
        ownerContactLocation: fb.control(
          {value: this.model.ownerContactLocation}
        )
      }
    );
  }

  onSaveButtonClicked(navigateOnSubmit: boolean) {
    this.submitted.emit(navigateOnSubmit);
  }

  navigateToCustomerRecord(item: CustomerRecordMultiselectItem) {
    this.uiRouter.stateService.go(StateName.CUSTOMER_RECORD_DETAIL,
      {customerId: item.customerId, customerRecordId: item.id});

  }
}


