/* eslint-disable */
import { Component, Input, OnInit, ViewChild, } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, NgModel, ValidatorFn, Validators, } from '@angular/forms';
import {
  FieldValidationError,
  ForwardingNgFormRef,
  LocalFormGroupValidationErrors,
  ResourceQueryResult,
} from '../../../lib/util/services';
import { UiConstants } from '../../../util/core-utils';
import { LegacyProcessTask } from '../../../lib/legacy-process/legacy-process-task.service';
import { UIRouter } from '@uirouter/angular';
import { LegacyProcessTaskBase } from './legacy-process-task-base-view';
import { User, UserService } from '../../../lib/user.service';
import { TranslateService } from '@ngx-translate/core';
import { Angular2Multiselects } from '../../../util/multiselect';

/* eslint-enable */

@Component({
  selector: 'app-legacy-process-task-base',
  templateUrl: 'legacy-process-task-base.component.html',
  styleUrls: ['legacy-process-task-base.component.scss'],
})
export class LegacyProcessTaskBaseComponent implements OnInit, LegacyProcessTaskBase.View {

  ProcessTask = LegacyProcessTask;

  @ViewChild('name')
  nameInput: NgModel;

  @Input()
  form?: NgForm;

  @Input()
  headingDictionaryKey: string;

  @Input()
  readonly: boolean;

  @Input()
  allowClone: boolean;

  @Input()
  externalIdRequired?: boolean;

  fieldStyle = {
    'padding': UiConstants.formRecordFieldPadding
  };

  formGroup: FormGroup;

  model: LegacyProcessTaskBase.Model = new LegacyProcessTaskBase.Model();
  processTask?: LegacyProcessTask.LegacyProcessTask;
  selectedUser: UserItem[] = [];
  users: UserItem[] = [];
  assigneeName?: string;
  dropdownSettings: Angular2Multiselects.Settings;

  private fieldErrors: FieldValidationError<LegacyProcessTask.ValidatedField> =
    FieldValidationError.empty<LegacyProcessTask.ValidatedField>();

  private formGroupValidationErrors: LocalFormGroupValidationErrors;

  constructor(private fb: FormBuilder,
              private uiRouter: UIRouter,
              private translateService: TranslateService,
              private userService: UserService) {
  }

  getModel(): LegacyProcessTaskBase.Model {
    return this.model;
  }

  loadProcessTask(args: LegacyProcessTaskBase.Args) {
    this.model.load(args.processTask);
    this.processTask = args.processTask;
    this.loadUserNames();
  }

  onFieldValidationError(fieldErrors: FieldValidationError<LegacyProcessTask.ValidatedField>) {
    this.fieldErrors = fieldErrors;
  }

  ngOnInit(): void {
    this.formGroup = this.createFormGroup(this.fb);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(
      this.createForwardingHtmlForm(),
      this.formGroup
    );
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  hasLocalFormError(errorCode: string): boolean {
    return this.formGroupValidationErrors.hasFormError(errorCode);
  }

  hasFieldError(field: LegacyProcessTask.ValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: LegacyProcessTask.ValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: LegacyProcessTask.ValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  private createForwardingHtmlForm() {
    return new ForwardingNgFormRef({
      formFn: () => {
        return this.form;
      }
    });
  }

  getExternalIdRequirement(): ValidatorFn | undefined {
    if (this.externalIdRequired === true) {
      return Validators.required;
    }
    else {
      return undefined;
    }
  }

  private createFormGroup(fb: FormBuilder): FormGroup {
    return fb.group(
      {
        name: fb.control(
          {value: this.model.name},
          []
        ),
        externalId: fb.control(
          {value: this.model.externalId},
          this.getExternalIdRequirement()
        ),
      }
    );
  }

  private loadUserNames() {
    this.users = [];
    const fields: string[] = ['id', 'person_name', 'user_name'];
    this.userService.query({
      fields: fields.join(',')
    }).subscribe((result: ResourceQueryResult<User>) => {
      result.items.forEach((user) => {
        const item = {
          id: user.id,
          name: user.person_name + ' (' + user.user_name + ')'
        };
        this.users.push(item);
        if (this.model.assigneeUserId && this.model.assigneeUserId === user.id) {
          this.selectedUser.push(item);
          this.assigneeName = user.person_name;
        }
      });
      this.initDropdown();
    });
  }

  initDropdown() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .labelKey('name')
      .build();
  }

  onUserSelected() {
    const assigneeId = this.selectedUser ?
      this.selectedUser[0] ? this.selectedUser[0].id : null
      : null;
    this.model.assigneeUserId = assigneeId;
  }
}

interface UserItem {
  id: number;
  name: string;
}
