<div class="card">

  <div class="card-header">
    <label class="form-control-label text-break"
           [ngClass]="{ 'required-field-label': required }">{{ getTitle() }} </label>
    <div class="card-actions">
      <a class="btn-setting cursor-pointer" (click)="openHeaderDialog()"
         title="{{'FORM_RECORD_STOCK_INTAKE_HEADER' | translate}}"><i class="icomoon icomoon-modify"></i></a>
      <a class="btn-setting cursor-pointer" (click)="openStockIntakeDialog()"
         *ngIf="!nonEditable && allowItemEdit"
         title="{{'FORM_RECORD_STOCK_ADD' | translate}}"><i class="icomoon icomoon-add"></i></a>
    </div><!-- /.card-actions -->
  </div>
  <div class="card-body">
    <label class="warning-text"
           *ngIf="hasDisabledItem && !nonEditable">{{'FORM_RECORD_STOCK_INTAKE_DISABLED_WARNING_TEXT' | translate}}</label>
    <label class="warning-text"
           *ngIf="isHeaderEmpty() && !nonEditable">{{'FORM_RECORD_STOCK_INTAKE_HEADER_EMPTY' | translate}}</label>
    <label class="warning-text"
           *ngIf="!optionalValue && htmlForm && htmlForm.getForm().submitted && (!model.stocks || model.stocks.length === 0)">{{'FORM_RECORD_STOCK_INTAKE_REQUIRED_WARNING_TEXT' | translate}}</label>
    <div class="text-center text-muted p-2" *ngIf="model.stocks.length === 0">
      <div style="background-image: url('../../../../../../assets/img/stock/stock-item-placeholder.png')"
           class="record-picture"></div>
      <label class="mb-1" *ngIf="!nonEditable">{{'FORM_RECORD_STOCK_INTAKE_NO_ITEM' | translate}}</label>
      <br>
      <button type="button" class="btn btn-secondary"
              *ngIf="!nonEditable && allowItemEdit"
              (click)="openStockIntakeDialog()">
        {{'FORM_RECORD_STOCK_INTAKE_START' | translate}}
      </button>
      <label class="m-0"
             *ngIf="nonEditable || !allowItemEdit">{{'FORM_RECORD_STOCK_NO_ITEM_READONLY' | translate}}</label>
    </div>
    <div *ngIf="model.stocks.length > 0">
      <div class="stock-container p-1" *ngFor="let stock of model.stocks; let stockIndex = index; let last = last"
           [ngClass]="{'disabled-stock': stock.disabled && !nonEditable, 'mb-1': !last}">
        <div class="d-flex justify-content-between mb-05">
          <label class="font-weight-bold font-lg">{{stockIndex + 1}}. {{stock.name}}</label>
          <div class="more-options-container" *ngIf="!nonEditable && allowItemEdit">
            <app-table-options-menu>
              <app-dropdown-item
                [iconClass]="'icomoon-modify'"
                [titleStringKey]="'FORM_RECORD_STOCK_INTAKE_ADD_ITEM_TO_STOCK'"
                class="cursor-pointer"
                (click)="openStockIntakeDialog(stock.id)">
              </app-dropdown-item>
              <app-dropdown-item
                [iconClass]="'icomoon-trash'"
                [titleStringKey]="'FORM_RECORD_STOCK_INTAKE_DELETE_STOCK'"
                class="cursor-pointer"
                (click)="removeStock(stock.id)">
              </app-dropdown-item>
            </app-table-options-menu>
          </div>
        </div>
        <table class="table table-sm table-bordered mb-0">
          <thead>
          <tr>
            <th class="responsive-table-column">{{'COMMON_NAME' | translate}}</th>
            <th class="responsive-table-column">{{'STOCK_ITEM_TABLE_HEADER_PRODUCT_CODE' | translate}}</th>
            <th
              class="responsive-table-column hidden-md-down">{{'FORM_RECORD_STOCK_INTAKE_IN_STOCK_AMOUNT' | translate}}</th>
            <th
              class="responsive-table-column hidden-md-down">{{'STOCK_RECORD_SELECTOR_DIALOG_RECORD_WEIGHT' | translate}}</th>
            <th class="responsive-table-column">{{'FORM_RECORD_STOCK_INTAKE_AMOUNT' | translate}}</th>
            <th class="responsive-table-column">{{'STOCK_RECORD_LOT_INTAKE_PRICE' | translate}}</th>
            <th class="delete-column" *ngIf="!nonEditable"></th>
          </tr>
          </thead>
          <tbody *ngFor="let item of stock.stockItems">
          <tr [ngClass]="{'disabled-item': item.disabled && !nonEditable}">
            <td class="responsive-table-column align-middle">{{item.name}}</td>
            <td class="responsive-table-column align-middle">{{item.productCode}}</td>
            <td
              class="responsive-table-column hidden-md-down align-middle">{{item.inStockAmount | formattedNumber}} {{item.baseUnit}}
              <div *ngIf="item.packageData"
                   class="table-text-subtitle">{{StockItemUtils.convertToPackageStr(item.inStockAmount, item.packageData)}}</div>
              <div *ngIf="item.intakePriceAvg" class="table-text-subtitle">
                avg: {{item.intakePriceAvg | formattedNumber}} {{getCurrencyString(item.currencyCode)}}
                / {{item.baseUnit}}</div>
            </td>
            <td class="responsive-table-column hidden-md-down align-middle"> {{item.sumWeightFormatted}}</td>
            <td class="responsive-table-column align-middle"
                *ngIf="nonEditable || !allowItemEdit">{{item.numberAmount}} {{item.selectedUnitOfMeasure.unit_name}}
              <div *ngIf="item.packageData && item.isBaseUnitSelected()"
                   class="table-text-subtitle">{{StockItemUtils.convertToPackageStr(item.numberAmount, item.packageData)}}</div>
              <div *ngIf="!item.isBaseUnitSelected()"
                   class="table-text-subtitle">{{StockItemUtils.convertToBaseStr(item.numberAmount, item.selectedUnitOfMeasure, item.baseUnit)}}</div>
            </td>
            <td class="responsive-table-column align-middle" style="overflow:visible"
                *ngIf="!nonEditable && allowItemEdit">
              <div class="d-flex mb-0">
                <div class="form-group w-50"
                     [ngClass]="{ 'has-danger': htmlForm && htmlForm.getForm().submitted && (!item.numberAmount || item.numberAmount <= 0) }">
                  <input type="number" class="form-control" [(ngModel)]="item.numberAmount"
                         placeholder="{{'FORM_RECORD_STOCK_INTAKE_AMOUNT' | translate}}"
                         [ngClass]="{ 'form-control-danger': htmlForm && htmlForm.getForm().submitted && (!item.numberAmount || item.numberAmount <= 0) }">
                  <div class="form-control-feedback"
                       *ngIf="htmlForm && htmlForm.getForm().submitted && !item.numberAmount">
                    {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                  </div>
                  <div class="form-control-feedback"
                       *ngIf="htmlForm && htmlForm.getForm().submitted && item.numberAmount <= 0">
                    {{'COMMON_VALIDATION_MESSAGE_NUMBER_NOT_POSITIVE' | translate}}
                  </div>
                </div>
                <div class="form-group w-50"
                     [ngClass]="{ 'has-danger': htmlForm && htmlForm.getForm().submitted && (!item.selectedUnitOfMeasure) }">
                  <angular2-multiselect
                    class="form-control"
                    [(ngModel)]="item.unitOfMeasure"
                    [data]="item.measurements"
                    [settings]="dropdownSettings"
                    [ngClass]="{ 'form-control-danger': htmlForm && htmlForm.getForm().submitted && (!item.selectedUnitOfMeasure) }"
                    validateEnabledItems
                    required>
                  </angular2-multiselect>
                </div>
              </div>
              <div *ngIf="item.packageData && item.isBaseUnitSelected()"
                   class="table-text-subtitle">{{StockItemUtils.convertToPackageStr(item.numberAmount, item.packageData)}}</div>
              <div *ngIf="!item.isBaseUnitSelected()"
                   class="table-text-subtitle">{{StockItemUtils.convertToBaseStr(item.numberAmount, item.selectedUnitOfMeasure, item.baseUnit)}}</div>
            </td>
            <td class="responsive-table-column align-middle"
                *ngIf="nonEditable">{{item.intakePrice }} {{getCurrencyString(item.currencyCode)}}
              <div
                class="table-text-subtitle">{{item.lineIntakePrice | formattedNumber }} {{getCurrencyString(item.currencyCode)}}</div>
            </td>
            <td class="responsive-table-column align-middle" *ngIf="!nonEditable">
              <div class="form-group mb-0"
                   [ngClass]="{ 'has-danger': htmlForm && htmlForm.getForm().submitted && (item.intakePrice && item.intakePrice < 0) }">
                <div class="d-flex align-items-center">
                  <input type="number" class="form-control" [(ngModel)]="item.intakePrice"
                         placeholder="{{'STOCK_RECORD_LOT_INTAKE_PRICE' | translate}}"
                         [ngClass]="{ 'form-control-danger': htmlForm && htmlForm.getForm().submitted && (item.intakePrice && item.intakePrice < 0) }">
                  <div class="ml-05">{{getCurrencyString(item.currencyCode)}}</div>
                </div>
                <div class="form-control-feedback"
                     *ngIf="htmlForm && htmlForm.getForm().submitted && (item.intakePrice && item.intakePrice < 0)">
                  {{'COMMON_VALIDATION_MESSAGE_NUMBER_NOT_POSITIVE' | translate}}
                </div>
              </div>
              <div
                class="table-text-subtitle">{{item.lineIntakePrice | formattedNumber }} {{getCurrencyString(item.currencyCode)}}</div>
            </td>
            <td class="responsive-table-column align-middle text-center" *ngIf="!nonEditable && allowItemEdit">
              <i class="icomoon icomoon-trash cursor-pointer font-xl" (click)="removeItem(stock.id, item.id)"></i>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="d-flex justify-content-end">
          <div class="col-md-4 p-0">
            <table class="table table-sm mb-0" *ngIf="stock.sumWeight.length > 0">
              <tbody>
              <tr>
                <td class="font-weight-bold text-small">{{'FORM_RECORD_STOCK_SUM_WEIGHT' | translate}}</td>
                <td class="pull-right pr-1">{{ stock.sumWeight }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer" *ngIf="!nonEditable && allowItemEdit">
    <a class="id-text cursor-pointer"
       (click)="openStockIntakeDialog()"
       title="{{'FORM_RECORD_STOCK_INTAKE_ADD_STOCK' | translate}}">
      {{'FORM_RECORD_STOCK_INTAKE_ADD_STOCK' | translate}}
    </a>
  </div>
</div>
