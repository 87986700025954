import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { FieldValidationError, ForwardingNgFormRef, LocalFormGroupValidationErrors } from '../../../../lib/util/services';
import { SelectUtils, UiConstants } from '../../../../util/core-utils';
import { Invoice } from '../../../../lib/invoice/invoice/invoice.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { InvoiceSettings } from '../../../../lib/invoice/invoice-settings/invoice-settings.service';
import { NgbDatePickerParserFormatter } from '../../../../util/ngb-datepicker';
import { EmptyMessage } from '../../../../lib/util/messages';
import { InputMask } from '../../../../util/input-masks';
import { Strings } from '../../../../lib/util/strings';
import { InvoiceTag, InvoiceTagService } from '../../../../lib/invoice/tag/invoice-tag.service';

@Component({
  selector: 'app-invoice-tag-dialog',
  templateUrl: './invoice-tag-dialog.component.html',
  styleUrls: ['./invoice-tag-dialog.component.scss']
})
export class InvoiceTagDialogComponent implements OnInit {

  Invoice = Invoice;
  InvoiceSettings = InvoiceSettings;
  SelectUtils = SelectUtils;
  UiConstants = UiConstants;
  InputMask = InputMask;
  ValidatedField = InvoiceTag.ValidatedField;

  model: InvoiceTagModel = new InvoiceTagModel();

  @ViewChild('f', {static: true})
  form: NgForm;
  formGroup: FormGroup;
  private formGroupValidationErrors: LocalFormGroupValidationErrors;
  private fieldErrors: FieldValidationError<InvoiceTag.ValidatedField> =
    FieldValidationError.empty<InvoiceTag.ValidatedField>();

  invoiceTag?: InvoiceTag.InvoiceTag;

  get readonly(): boolean {
    return this.data.readonly;
  }

  constructor(public dialogRef: MatDialogRef<InvoiceTagDialogComponent>,
              private translateService: TranslateService,
              private datePickerParserFormatter: NgbDatePickerParserFormatter,
              private fb: FormBuilder,
              private invoiceTagService: InvoiceTagService,
              @Inject(MAT_DIALOG_DATA) public data: InvoiceTagDialogData) {
    this.loadModel();
    this.formGroup = this.createFormGroup(this.fb);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(
      this.createForwardingHtmlForm(),
      this.formGroup
    );
  }

  ngOnInit() {
  }

  closeDialog(modified: boolean) {
    this.dialogRef.close({modified: modified});
  }

  save() {
    if (this.hasLocalFieldError()) {
      return;
    }
    else {
      if (this.data.invoiceTagId) {
        this.updateInvoiceTag();
      }
      else {
        this.createInvoiceTag();
      }
    }
  }

  hasFieldError(field?: InvoiceTag.ValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: InvoiceTag.ValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: InvoiceTag.ValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  private createForwardingHtmlForm() {
    return new ForwardingNgFormRef({
      formFn: () => {
        return this.form;
      }
    });
  }

  private createFormGroup(fb: FormBuilder): FormGroup {
    return fb.group(
      {
        description: fb.control(
          {value: this.model.description, disabled: this.readonly},
          []
        ),
        invoiceTag: fb.control(
          {
            value: this.model.invoiceTag,
            disabled: this.readonly
          },
          [Validators.required]
        ),
      }
    );
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  createInvoiceTag() {
    this.formGroup.updateValueAndValidity();
    if (this.formGroup.invalid) {
      return;
    }
    this.invoiceTagService.create({
      invoiceTag: this.model.invoiceTag,
      description: Strings.undefinedOrNonEmpty(this.model.description),
    }).subscribe(
      (result: EmptyMessage) => {
        this.closeDialog(true);
      },
      error => {

      });
  }

  updateInvoiceTag() {
    this.formGroup.updateValueAndValidity();
    if (this.formGroup.invalid) {
      return;
    }
    this.invoiceTagService.update({
      id: this.data.invoiceTagId!,
      invoiceTag: this.model.invoiceTag,
      description: Strings.undefinedOrNonEmpty(this.model.description),
    }).subscribe(
      (result: EmptyMessage) => {
        this.closeDialog(true);
      },
      error => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error;
        }
      });
  }

  private loadModel() {
    this.model = new InvoiceTagModel();

    if (this.data.invoiceTagId) {
      this.invoiceTagService.get({
        id: this.data.invoiceTagId!,
        fields: f => f.each
      }).subscribe(l => {
        this.model.invoiceTag = l.invoiceTag;
        this.model.description = Strings.optToString(l.description);
        this.invoiceTag = l;
      });
    }
  }

  getDialogTitle(): string {
    if (this.data.readonly) {
      return 'INVOICE_TAG_DETAIL';
    }
    if (this.data.invoiceTagId) {
      return 'INVOICE_TAG_UPDATE';
    }
    return 'INVOICE_TAG_CREATE';
  }
}

class InvoiceTagModel {
  description: string = '';
  invoiceTag: string = '';
}

export interface InvoiceTagDialogData {
  invoiceTagId?: number;
  readonly: boolean;
}

export interface InvoiceTagDialogResult {
  modified: boolean;
}
