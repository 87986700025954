/* eslint-disable */
import { Injectable } from '@angular/core';
import { OrderField } from '../query/orderfields';
import { MultiselectOptionItem, OrderFieldModel, OrderFunctionResolver } from '../../util/core-utils';
import { ExteriorTransport } from './exterior-transport.service';
import { environment } from '../../../environments/environment';
import { NewBaseSearch, NewBaseSearchService } from '../new-base.search-service';
import { OrderType } from '../util/services';
import { SearchUtils } from '../../util/search-utils';
import { RuntimeConfiguration } from '../runtime-configuration';
import { LoggedInUserStorage } from '../util/storages';
/* eslint-enable */

@Injectable()
export class ExteriorTransportSearchService extends NewBaseSearchService<OrderField.ExteriorTransport> {

  protected createStorage(): ExteriorTransportSearch.Storage {
    return new ExteriorTransportSearch.Storage();
  }

}

export namespace ExteriorTransportSearch {

  export class Model extends SearchUtils.SearchModel {

    public static defaultSelectedState = { id: null, stringKey: 'COMMON_VALUE_UNSELECTED' };

    showSearch: boolean = false;

    serialNumber: string;
    transporterCompany: MultiselectOptionItem<number>[];
    state: StateFilterOption;

    get transporterCompanyId(): number | undefined {
      if (this.transporterCompany.length > 0 && this.transporterCompany[0].id !== null) {
        return this.transporterCompany[0].id!;
      }
      return undefined;
    }

    constructor() {
      super();
      this.reset();
    }

    reset() {
      this.serialNumber = '';
      this.transporterCompany = [];
      this.state = Model.defaultSelectedState;
    }

    isEmpty(): boolean {
      return this.serialNumber === '' &&
        this.transporterCompany.length === 0 &&
        this.state.id === null;
    }

  }

  export namespace OrderFunctions {
    export const ID = (f: OrderField.ExteriorTransport) => f.id;

    export const VALUES = OrderFunctionResolver.builder<OrderField.ExteriorTransport>()
      .add(ID, 'id')
      .build();
  }

  export interface StateFilterOption {
    id: ExteriorTransport.ExteriorTransportState | null;
    stringKey: string;
  }

  export interface SearchDataResult {
    searchData: SearchData;
  }

  export interface SearchData extends NewBaseSearch.SearchData<OrderField.ExteriorTransport> {
    searchModel: Model;
  }

  interface StoredSearchRawData extends NewBaseSearch.StoredSearchRawData<OrderField.ExteriorTransport> {
    serialNumber: string;
    transporterCompany: MultiselectOptionItem<number>[];
    state: StateFilterOption;
  }

  export class Storage extends NewBaseSearch.Storage<OrderField.ExteriorTransport> {

    constructor() {
      const defaultSearchData: SearchData = {
        queryModel: new OrderFieldModel(OrderFunctions.ID, OrderType.DESC),
        searchModel: new Model(),
      };
      /**
       * Increment this version if you change the SearchData interface.
       */
      const versionNumber: number = 1;
      super(defaultSearchData,
        versionNumber);
    }

    protected generateKey(): string {
      return 'UserId(' + LoggedInUserStorage.getInstance().getUserId() + ')-Search-Exterior-Transport';
    }

    protected fromRaw(data: StoredSearchRawData): NewBaseSearch.SearchDataResult<OrderField.ExteriorTransport> {
      const searchModel = new Model();
      searchModel.serialNumber = data.serialNumber;
      searchModel.transporterCompany = data.transporterCompany;
      searchModel.state = data.state;
      const searchData: SearchData = {
        queryModel: OrderFieldModel.create<OrderField.ExteriorTransport>(OrderFunctions.VALUES, data.order, data.queryModel),
        searchModel: searchModel,
      };
      return {
        searchData: searchData
      };
    }

    protected toRaw(data: SearchData): StoredSearchRawData {
      return {
        version: this.VERSION,
        queryModel: data.queryModel,
        order: data.queryModel.createOrder(OrderFunctions.VALUES),
        serialNumber: data.searchModel.serialNumber,
        transporterCompany: data.searchModel.transporterCompany,
        state: data.searchModel.state,
      };
    }

  }

}
