import { Injectable } from '@angular/core';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { Observable } from 'rxjs';
import { ResourceQueryResult } from '../util/services';
import { EmptyMessage, IdentityMessage } from '../util/messages';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class MessageResourceService extends BaseHttpService {

  query(request: MessageResource.QueryRequest): Observable<ResourceQueryResult<MessageResource.Message>> {
    return this.http.get<ResourceQueryResult<MessageResource.Message>>(this.url, this.parseParams(request));
  }

  setRead(request: MessageResource.SetReadRequest): Observable<EmptyMessage> {
    return this.http.patch(this.url + `${request.id}/read`, request);
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/messages/');
  }

}

export namespace MessageResource {

  export interface Message {
    id: number;
    title: string;
    content: string;
    type: string;
    creation_time: string;
    read: boolean;
    disabled: boolean;
    sender_user?: SenderUser;
    data?: MessageData;
  }

  export interface MessageData {
    message_id?: number,
    entity_id: number;
    entity_type: string;
    task_record_task_id?: number;
    task_record_state?: string;
    process_task_workflow_task_id?: number;
    process_task_state?: string;
    process_workflow_id?: number;
    process_state?: string;
  }

  export interface QueryRequest {
    title?: string;
    content?: string;
    sent_date_from?: string;
    sent_date_to?: string;
    include_read_messages?: boolean;
    include_system_messages?: boolean;
    q?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
    disabled?: boolean;
    no_progress_bar?: boolean;
  }

  export interface SetReadRequest extends IdentityMessage {
    read: boolean;
    no_progress_bar?: boolean;
  }

  export interface SenderUser {
    id: number;
    type: string;
  }

}
