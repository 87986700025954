/* eslint-disable */
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Map as ImmutableMap } from 'immutable';
import {Observable, Subject} from 'rxjs';
import { DownloadedFile, } from '../util/downloaded-files';
import { ResourceQueryResult } from '../util/services';
import { UrlBuilder } from '../../util/url-builder';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { CountMessage, EmptyMessage, IdentityMessage, ListQuery } from '../util/messages';
import {Cacheable, CacheBuster} from "ts-cacheable";
import {UiConstants} from "../../util/core-utils";
/* eslint-enable */

export namespace DocumentFile {

  export const DOCUMENT_LIBRARY_ASPECT_RATIO: number = 1/1;
  export const DOCUMENT_LIBRARY_ASPECT_RATIO_STR: string = '1/1';

  export enum OrderField {
    ID,
    NAME,
    CREATION_TIME,
  }

  export class Keys {

    private static readonly ID = 'id';
    private static readonly NAME = 'name';
    private static readonly CREATION_TIME = 'creation_time';

    private static readonly orderFieldKeyMap: ImmutableMap<DocumentFile.OrderField, string> = ImmutableMap.of(
      DocumentFile.OrderField.ID, Keys.ID,
      DocumentFile.OrderField.NAME, Keys.NAME,
      DocumentFile.OrderField.CREATION_TIME, Keys.CREATION_TIME,
    );

    public static toOrderFieldKey(field: DocumentFile.OrderField): string {
      return Keys.orderFieldKeyMap.get(field)!;
    }

  }

}

export interface DocumentFileQuery extends ListQuery.Paging, ListQuery.Order, ListQuery.QueryText, ListQuery.Fields {
  id?: string;
  name?: string;
  document_group_id?: number;
  disabled?: boolean;
  description?: string;
  content_type?: string;
  no_progress_bar?: boolean;
}

export interface UpdateDocumentFileRequest {
  id: number;
  name?: string;
  external_id?: string;
  document_group_id?: number;
  language_code?: string;
  description?: string;
}

export interface DocumentFile {
  id: number;
  name: string;
  external_id: string;
  language_code: string;
  document_group_id: number;
  content_type: string;
  creation_time: string;
  update_time: string;
  content_update_time: string;
  disabled: boolean;
  owner_user: number;
  content_size: number;
  description: string;
  has_thumbnail: boolean;
}

export interface DisableRequest extends IdentityMessage {
  disabled: boolean;
}

const thumbnailCacheBuster = new Subject<void>();

@Injectable()
export class DocumentFileService extends BaseHttpService {

  query(request: DocumentFileQuery): Observable<ResourceQueryResult<DocumentFile>> {
    return this.http.get<ResourceQueryResult<DocumentFile>>(this.url, this.parseParams(request));
  }

  get(request: IdentityMessage): Observable<DocumentFile> {
    return this.http.get<DocumentFile>(this.url + `${request.id}`);
  }

  setDisabled(request: DisableRequest): Observable<EmptyMessage> {
    return this.http.patch(this.url + `${request.id}/disabled`, request);
  }

  update(request: UpdateDocumentFileRequest): Observable<EmptyMessage> {
    return this.http.put(this.url + `${request.id}`, request);
  }

  count(request: DocumentFileQuery): Observable<CountMessage> {
    return this.http.get<CountMessage>(this.url + `count`, this.parseParams(request));
  }

  downloadContent(documentId: number): Observable<DownloadedFile> {
    const url = UrlBuilder.create('documents/files/:documentId/download')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .namedNumber('documentId', documentId)
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob',
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  @Cacheable({
    maxAge: UiConstants.defaultCacheMaxAge,
    cacheBusterObserver: thumbnailCacheBuster,
    slidingExpiration: true,
    maxCacheCount: 100
  })
  downloadThumbnail(documentId: number, no_progress_bar?: boolean): Observable<DownloadedFile> {
    const url = UrlBuilder.create('documents/files/:documentId/download-thumbnail?{:no_progress_bar}')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .namedNumber('documentId', documentId)
      .queryString('no_progress_bar', no_progress_bar === undefined ? undefined : ''+no_progress_bar)
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob',
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  @CacheBuster({
    cacheBusterNotifier: thumbnailCacheBuster
  })
  deleteThumbnail(request: IdentityMessage): Observable<EmptyMessage> {
    return this.http.delete<EmptyMessage>(this.url + `${request.id}/delete-thumbnail`);
  }

  delete(request: IdentityMessage): Observable<EmptyMessage> {
    return this.http.delete<EmptyMessage>(this.url + `${request.id}`);
  }

  constructor(
    private http: HttpClient,
    private resourceHelper: ResourceHelper) {
    super(resourceHelper, '/documents/files/');
  }

}
