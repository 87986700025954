export class StateName {

  public static readonly CONNECTION_ERROR: string = 'ConnectionError';
  public static readonly SERVER_ERROR: string = 'ServerError';
  public static readonly CONFIGURATION_LOAD_ERROR: string = 'ConfigurationLoadError';
  public static readonly LOGIN_DISABLED: string = 'LoginDisabled';

  public static readonly SHOPRENTER_AUTH: string = 'ShopRenterAuth';
  public static readonly SHOPRENTER_ENTRY: string = 'ShopRenterEntry';

  public static readonly ACTIVATE_USER: string = 'ActivateUser';
  public static readonly LOGIN: string = 'Login';
  public static readonly REGISTER: string = 'Register';
  public static readonly ADMIN: string = 'Admin';

  public static readonly STARTUP: string = 'Admin.Startup';
  public static readonly HOME: string = 'Admin.Home';
  public static readonly ADMIN_DASHBOARD: string = 'Admin.Dashboard';
  public static readonly PERMISSION_LIST: string = 'Admin.PermissionList';

  public static readonly USER_LIST: string = 'Admin.UserList';
  public static readonly USER_CREATE: string = 'Admin.UserCreate';
  public static readonly USER_EDIT: string = 'Admin.UserEdit';
  public static readonly USER_DETAIL: string = 'Admin.UserDetail';

  public static readonly USER_GROUP_LIST: string = 'Admin.UserGroupList';
  public static readonly USER_GROUP_CREATE: string = 'Admin.UserGroupCreate';
  public static readonly USER_GROUP_EDIT: string = 'Admin.UserGroupEdit';
  public static readonly USER_GROUP_CLONE: string = 'Admin.UserGroupClone';
  public static readonly USER_GROUP_DETAIL: string = 'Admin.UserGroupDetail';

  public static readonly APPLICATION_DICTIONARY_LIST: string = 'Admin.ApplicationDictionaryList';
  public static readonly APPLICATION_FILE_LIST: string = 'Admin.ApplicationFileList';
  public static readonly APPLICATION_DEVICE_MANAGEMENT_LIST: string = 'Admin.ApplicationDeviceManagementListComponent';

  public static readonly API_USER_KEY: string = 'Admin.ApiUserKey';

  public static readonly TASK_DASHBOARD: string = 'Admin.TaskDashboard';

  public static readonly TASK_LIST: string = 'Admin.TaskList';
  public static readonly TASK_CREATE: string = 'Admin.TaskCreate';
  public static readonly TASK_EDIT: string = 'Admin.TaskEdit';
  public static readonly TASK_TRIGGER_DETAIL: string = 'Admin.TaskTriggerDetail';

  public static readonly TASK_RECORD_LIST: string = 'Admin.TaskRecordList';
  public static readonly TASK_RECORD_CREATE: string = 'Admin.TaskRecordCreate';
  public static readonly TASK_RECORD_DETAIL: string = 'Admin.TaskRecordDetail';
  public static readonly TASK_RECORD_EDIT: string = 'Admin.TaskRecordEdit';
  public static readonly TASK_RECORD_CLONE: string = 'Admin.TaskRecordClone';

  public static readonly TASK_RECORD_BOARD: string = 'Admin.TaskRecordBoard';

  public static readonly DOCUMENT_DASHBOARD: string = 'Admin.DocumentDashboard';
  public static readonly DOCUMENT_LIBRARY: string = 'Admin.DocumentLibrary';

  public static readonly DOCUMENT_HTML_LIST: string = 'Admin.DocumentHTMLList';
  public static readonly DOCUMENT_HTML_CREATE: string = 'Admin.DocumentHTMLCreate';
  public static readonly DOCUMENT_HTML_READ: string = 'Admin.DocumentHTMLRead';
  public static readonly DOCUMENT_HTML_EDIT: string = 'Admin.DocumentHTMLEdit';

  public static readonly DOCUMENT_TEXT_LIST: string = 'Admin.DocumentTextList';
  public static readonly DOCUMENT_TEXT_CREATE: string = 'Admin.DocumentTextCreate';
  public static readonly DOCUMENT_TEXT_EDIT: string = 'Admin.DocumentTextEdit';
  public static readonly DOCUMENT_TEXT_READ: string = 'Admin.DocumentTextRead';

  public static readonly DOCUMENT_LINK_LIST: string = 'Admin.DocumentLinkList';
  public static readonly DOCUMENT_LINK_CREATE: string = 'Admin.DocumentLinkCreate';
  public static readonly DOCUMENT_LINK_EDIT: string = 'Admin.DocumentLinkEdit';
  public static readonly DOCUMENT_LINK_READ: string = 'Admin.DocumentLinkRead';

  public static readonly NOTIFICATION_LIST: string = 'Admin.NotificationList';
  public static readonly NOTIFICATION_CREATE: string = 'Admin.NotificationCreate';
  public static readonly NOTIFICATION_READ: string = 'Admin.NotificationRead';
  public static readonly CUSTOMER_DASHBOARD: string = 'Admin.CustomerDashboard';
  public static readonly CUSTOMER_LIST: string = 'Admin.CustomerList';
  public static readonly CUSTOMER_CREATE: string = 'Admin.CustomerCreate';
  public static readonly CUSTOMER_EDIT: string = 'Admin.CustomerEdit';

  public static readonly CUSTOMER_RECORD_GLOBAL_LIST: string = 'Admin.CustomerRecordGlobalList';
  public static readonly CUSTOMER_RECORD_LIST: string = 'Admin.CustomerRecordList';
  public static readonly CUSTOMER_RECORD_CREATE: string = 'Admin.CustomerRecordCreate';
  public static readonly CUSTOMER_RECORD_EDIT: string = 'Admin.CustomerRecordEdit';
  public static readonly CUSTOMER_RECORD_DETAIL: string = 'Admin.CustomerRecordDetail';
  public static readonly CUSTOMER_RECORD_CLONE: string = 'Admin.CustomerRecordClone';

  public static readonly DOCUMENT_FILE_LIST: string = 'Admin.DocumentFileList';
  public static readonly DOCUMENT_FILE_CREATE: string = 'Admin.DocumentFileCreate';
  public static readonly DOCUMENT_FILE_READ: string = 'Admin.DocumentFileRead';
  public static readonly DOCUMENT_FILE_EDIT: string = 'Admin.DocumentFileEdit';

  public static readonly LIST_ITEM_TYPE_LIST: string = 'Admin.ListItemTypeList';
  public static readonly LIST_ITEM_TYPE_CREATE: string = 'Admin.ListItemTypeCreate';
  public static readonly LIST_ITEM_TYPE_EDIT: string = 'Admin.ListItemTypeEdit';

  public static readonly LIST_ITEM_LIST: string = 'Admin.ListItemList';
  public static readonly LIST_ITEM_CREATE: string = 'Admin.ListItemCreate';
  public static readonly LIST_ITEM_EDIT: string = 'Admin.ListItemEdit';

  public static readonly DOCUMENT_GROUP_LIST: string = 'Admin.DocumentGroupList';
  public static readonly DOCUMENT_GROUP_CREATE: string = 'Admin.DocumentGroupCreate';
  public static readonly DOCUMENT_GROUP_EDIT: string = 'Admin.DocumentGroupEdit';

  public static readonly AUDIT_LOG_LIST: string = 'Admin.AuditlogList';
  public static readonly AUDIT_LOG_DETAIL: string = 'Admin.AuditLogDetail';

  public static readonly PROJECT_LIST: string = 'Admin.ProjectList';
  public static readonly PROJECT_CREATE: string = 'Admin.ProjectCreate';
  public static readonly PROJECT_EDIT: string = 'Admin.ProjectEdit';
  public static readonly PROJECT_DETAIL: string = 'Admin.ProjectDetail';

  public static readonly PROJECT_DASHBOARD: string = 'Admin.ProjectDashboard';
  public static readonly PROJECT_RECORD_LIST: string = 'Admin.ProjectRecordList';
  public static readonly PROJECT_RECORD_CREATE: string = 'Admin.ProjectRecordCreate';
  public static readonly PROJECT_RECORD_EDIT: string = 'Admin.ProjectRecordEdit';
  public static readonly PROJECT_RECORD_DETAIL: string = 'Admin.ProjectRecordDetail';

  public static readonly MASTERDATA_DASHBOARD: string = 'Admin.MasterDataDashboard';

  public static readonly MASTERDATA_LIST: string = 'Admin.MasterdataList';
  public static readonly MASTERDATA_CREATE: string = 'Admin.MasterdataCreate';
  public static readonly MASTERDATA_EDIT: string = 'Admin.MasterdataEdit';

  public static readonly MASTERDATA_RECORD_LIST: string = 'Admin.MasterDataRecordList';
  public static readonly MASTERDATA_RECORD_CREATE: string = 'Admin.MasterDataRecordCreate';
  public static readonly MASTERDATA_RECORD_EDIT: string = 'Admin.MasterDataRecordEdit';
  public static readonly MASTERDATA_RECORD_DETAIL: string = 'Admin.MasterDataRecordDetail';
  public static readonly MASTERDATA_RECORD_CLONE: string = 'Admin.MasterDataRecordClone';

  public static readonly STOCK_LIST: string = 'Admin.StockList';
  public static readonly STOCK_CREATE: string = 'Admin.StockCreate';
  public static readonly STOCK_EDIT: string = 'Admin.StockEdit';
  public static readonly STOCK_EDIT_WITH_STOCK_ITEM_DETAIL: string = 'Admin.StockEditWithStockItemDetail';
  public static readonly STOCK_DETAIL: string = 'Admin.StockDetail';
  public static readonly STOCK_DETAIL_WITH_STOCK_ITEM_DETAIL: string = 'Admin.StockDetailWithStockItemDetail';

  public static readonly STOCK_ITEM_LIST: string = 'Admin.StockItemList';
  public static readonly STOCK_ITEM_CREATE: string = 'Admin.StockItemCreate';
  public static readonly STOCK_ITEM_EDIT: string = 'Admin.StockItemEdit';
  public static readonly STOCK_ITEM_DETAIL: string = 'Admin.StockItemDetail';

  public static readonly STOCK_ITEM_CATEGORY_LIST: string = 'Admin.StockItemCategoryList';
  public static readonly STOCK_ITEM_CATEGORY_CREATE: string = 'Admin.StockItemCategoryCreate';
  public static readonly STOCK_ITEM_CATEGORY_EDIT: string = 'Admin.StockItemCategoryEdit';
  public static readonly STOCK_ITEM_CATEGORY_DETAIL: string = 'Admin.StockItemCategoryDetail';

  public static readonly STOCK_LOG_LIST: string = 'Admin.StockLogList';

  public static readonly SURVEY_DASHBOARD: string = 'Admin.SurveyDashboard';

  public static readonly SURVEY_LIST: string = 'Admin.SurveyList';
  public static readonly SURVEY_CREATE: string = 'Admin.SurveyCreate';
  public static readonly SURVEY_EDIT: string = 'Admin.SurveyEdit';

  public static readonly FORM_TABLE_LIST: string = 'Admin.FormTableList';
  public static readonly FORM_TABLE_CREATE: string = 'Admin.FormTableCreate';
  public static readonly FORM_TABLE_EDIT: string = 'Admin.FormTableEdit';

  public static readonly SURVEY_RECORD_LIST: string = 'Admin.SurveyRecordList';
  public static readonly SURVEY_RECORD_CREATE: string = 'Admin.SurveyRecordCreate';
  public static readonly SURVEY_RECORD_EDIT: string = 'Admin.SurveyRecordEdit';
  public static readonly SURVEY_RECORD_DETAIL: string = 'Admin.SurveyRecordDetail';
  public static readonly SURVEY_RECORD_CLONE: string = 'Admin.SurveyRecordClone';

  public static readonly NOTE_LIST: string = 'Admin.NoteList';
  public static readonly NOTE_DETAIL: string = 'Admin.NoteDetail';

  public static readonly INVOICE_BOOK_LIST: string = 'Admin.InvoiceBookList';
  public static readonly INVOICE_BOOK_CREATE: string = 'Admin.InvoiceBookCreate';
  public static readonly INVOICE_BOOK_EDIT: string = 'Admin.InvoiceBookEdit';
  public static readonly INVOICE_SETTINGS_LIST: string = 'Admin.InvoiceSettingsList';
  public static readonly INVOICE_SETTINGS_CREATE: string = 'Admin.InvoiceSettingsCreate';
  public static readonly INVOICE_SETTINGS_EDIT: string = 'Admin.InvoiceSettingsEdit';
  public static readonly INVOICE_SETTINGS_DETAIL: string = 'Admin.InvoiceSettingsDetail';
  public static readonly INVOICE_LIST: string = 'Admin.InvoiceList';
  public static readonly INVOICE_CREATE: string = 'Admin.InvoiceCreate';
  public static readonly INVOICE_CLONE: string = 'Admin.InvoiceClone';
  public static readonly INVOICE_DETAIL: string = 'Admin.InvoiceDetail';
  public static readonly INVOICE_SPENDING_LIST: string = 'Admin.InvoiceSpendingList';
  public static readonly LEDGER_NUMBER_LIST: string = 'Admin.LedgerNumberList';
  public static readonly INVOICE_TAG_LIST: string = 'Admin.InvoiceTagList';

  public static readonly TASK_LOG_LIST: string = 'Admin.TaskLogList';
  public static readonly TASK_RECORD_GLOBAL_LIST: string = 'Admin.TaskRecordGlobalList';

  public static readonly MESSAGE_LISTS: string = 'Admin.MessageLists';

  public static readonly TASK_STATISTICS_LIST: string = 'Admin.TaskStatisticsList';
  public static readonly TASK_STATISTICS_DETAIL: string = 'Admin.TaskStatisticsDetail';

  public static readonly INVOICE_STATISTICS_LIST: string = 'Admin.InvoiceStatisticsList';

  public static readonly ORDER_LIST: string = 'Admin.OrderList';
  public static readonly ORDER_DETAIL: string = 'Admin.OrderDetail';
  public static readonly ORDER_DETAIL_WITH_PROCESS_EDIT: string = 'Admin.OrderDetailWithProcessEdit';
  public static readonly ORDER_DETAIL_WITH_PROCESS_DETAIL: string = 'Admin.OrderDetailWithProcessDetail';
  public static readonly ORDER_EDIT: string = 'Admin.OrderEdit';

  public static readonly LEGACY_PROCESS_LIST: string = 'Admin.LegacyProcessList';
  public static readonly LEGACY_PROCESS_EDIT: string = 'Admin.LegacyProcessEdit';
  public static readonly LEGACY_PROCESS_DETAIL: string = 'Admin.LegacyProcessDetail';

  public static readonly LEGACY_PROCESS_TASK_EDIT: string = 'Admin.LegacyProcessTaskEdit';
  public static readonly LEGACY_PROCESS_TASK_DETAIL: string = 'Admin.LegacyProcessTaskDetail';

  public static readonly PROCESS_LIST: string = 'Admin.ProcessList';
  public static readonly PROCESS_DETAIL: string = 'Admin.ProcessDetail';

  public static readonly LEGACY_WORKFLOW_LIST: string = 'Admin.LegacyWorkflowList';
  public static readonly LEGACY_WORKFLOW_CREATE: string = 'Admin.LegacyWorkflowCreate';
  public static readonly LEGACY_WORKFLOW_EDIT: string = 'Admin.LegacyWorkflowEdit';
  public static readonly LEGACY_WORKFLOW_TASK_EDIT: string = 'Admin.LegacyWorkflowTaskEdit';

  public static readonly WORKFLOW_CREATE_EDITOR: string = 'Admin.WorkflowCreateEditor';
  public static readonly WORKFLOW_LIST: string = 'Admin.WorkflowList';
  public static readonly WORKFLOW_EDIT: string = 'Admin.WorkflowEdit';
  public static readonly WORKFLOW_DETAIL: string = 'Admin.WorkflowDetail';

  public static readonly STOCK_CHANGE_LIST: string = 'Admin.StockChangeList';
  public static readonly STOCK_CHANGE_DETAIL: string = 'Admin.StockChangeDetail';

  public static readonly STOCK_TAKINGS_LIST: string = 'Admin.StockTakingsList';
  public static readonly STOCK_TAKINGS_EDIT: string = 'Admin.StockTakingsEdit';
  public static readonly STOCK_TAKINGS_DETAIL: string = 'Admin.StockTakingsDetail';

  public static readonly STOCK_TAKING_SHEET_DETAIL: string = 'Admin.StockTakingSheetDetail';

  public static readonly CALENDAR: string = 'Admin.Calendar';
  public static readonly GLOBAL_CALENDAR: string = 'Admin.GlobalCalendar';

  public static readonly COMPANY_LIST: string = 'Admin.CompanyList';
  public static readonly COMPANY_CREATE: string = 'Admin.CompanyCreate';
  public static readonly COMPANY_EDIT: string = 'Admin.CompanyEdit';
  public static readonly COMPANY_DETAIL: string = 'Admin.CompanyDetail';

  public static readonly VEHICLE_LIST: string = 'Admin.VehicleList';
  public static readonly VEHICLE_CREATE: string = 'Admin.VehicleCreate';
  public static readonly VEHICLE_EDIT: string = 'Admin.VehicleEdit';
  public static readonly VEHICLE_DETAIL: string = 'Admin.VehicleDetail';

  public static readonly ROLE_LIST: string = 'Admin.RoleList';
  public static readonly ROLE_CREATE: string = 'Admin.RoleCreate';
  public static readonly ROLE_EDIT: string = 'Admin.RoleEdit';
  public static readonly ROLE_DETAIL: string = 'Admin.RoleDetail';

  public static readonly COMPANY_LOCATION_LIST: string = 'Admin.CompanyLocationList';
  public static readonly COMPANY_LOCATION_CREATE: string = 'Admin.CompanyLocationCreate';
  public static readonly COMPANY_LOCATION_EDIT: string = 'Admin.CompanyLocationEdit';
  public static readonly COMPANY_LOCATION_DETAIL: string = 'Admin.CompanyLocationDetail';

  public static readonly COMPANY_STOCK_LIST: string = 'Admin.CompanyStockList';
  public static readonly COMPANY_STOCK_CREATE: string = 'Admin.CompanyStockCreate';
  public static readonly COMPANY_STOCK_EDIT: string = 'Admin.CompanyStockEdit';
  public static readonly COMPANY_STOCK_DETAIL: string = 'Admin.CompanyStockDetail';

  public static readonly TRANSPORT_LIST: string = 'Admin.TransportList';
  public static readonly TRANSPORT_DETAIL: string = 'Admin.TransportDetail';
  public static readonly TRANSPORT_DETAIL_TAB: string = 'Admin.TransportDetailTab';
  public static readonly TRANSPORT_TRIGGER_LIST: string = 'Admin.TransportTriggerList';
  public static readonly TRANSPORT_TRIGGER_DETAIL: string = 'Admin.TransportTriggerDetail';

  public static readonly SHIPPING_DEMAND_LIST: string = 'Admin.ShippingDemandList';
  public static readonly SHIPPING_DEMAND_CREATE: string = 'Admin.ShippingDemandCreate';
  public static readonly SHIPPING_DEMAND_EDIT: string = 'Admin.ShippingDemandEdit';
  public static readonly SHIPPING_DEMAND_DETAIL: string = 'Admin.ShippingDemandDetail';
  public static readonly SHIPPING_DEMAND_CLONE: string = 'Admin.ShippingDemandClone';

  public static readonly SHIPMENT_LIST: string = 'Admin.ShipmentList';
  public static readonly SHIPMENT_DETAIL: string = 'Admin.ShipmentDetail';
  public static readonly SHIPMENT_EDIT: string = 'Admin.ShipmentEdit';

  public static readonly COLONNADE_INSURANCE_LIST: string = 'Admin.ColonnadeInsuranceList';

  public static readonly EMAIL_TEMPLATE_LIST: string = 'Admin.EmailTemplateList';
  public static readonly EMAIL_TEMPLATE_CREATE: string = 'Admin.EmailTemplateCreate';
  public static readonly EMAIL_TEMPLATE_EDIT: string = 'Admin.EmailTemplateEdit';
  public static readonly EMAIL_TEMPLATE_DETAIL: string = 'Admin.EmailTemplateDetail';

  public static readonly TABLE_DOCUMENT_SCHEMA_LIST: string = 'Admin.TableDocumentSchemaList';
  public static readonly TABLE_DOCUMENT_SCHEMA_CREATE: string = 'Admin.TableDocumentSchemaCreate';
  public static readonly TABLE_DOCUMENT_SCHEMA_EDIT: string = 'Admin.TableDocumentSchemaEdit';
  public static readonly TABLE_DOCUMENT_SCHEMA_DETAIL: string = 'Admin.TableDocumentSchemaDetail';
  public static readonly TABLE_DOCUMENT_SCHEMA_CLONE: string = 'Admin.TableDocumentSchemaClone';

  public static readonly TABLE_DOCUMENT_LIST: string = 'Admin.TableDocumentList';

  public static readonly GENERAL_PDF_TEMPLATE: string = 'Admin.GeneralPdfTemplateList';

  public static readonly TOKENS_LIST: string = 'Admin.TokenList';

  public static readonly ENVIRONMENT_CONFIG: string = 'Admin.EnvironmentConfig';

  public static readonly REGISTRATION_SETTINGS: string = 'Admin.RegistrationSettings';

  public static readonly NOTIFICATION_TEMPLATE_LIST: string = 'Admin.NotificationTemplateList';
  public static readonly NOTIFICATION_TEMPLATE_CREATE: string = 'Admin.NotificationTemplateCreate';
  public static readonly NOTIFICATION_TEMPLATE_EDIT: string = 'Admin.NotificationTemplateEdit';
  public static readonly NOTIFICATION_TEMPLATE_DETAIL: string = 'Admin.NotificationTemplateDetail';

  public static readonly GENERAL_PRINTER_TEMPLATE_LIST: string = 'Admin.GeneralPrinterTemplateList';

  public static readonly EXTERIOR_TRANSPORT_LIST: string = 'Admin.ExteriorTransportList';
  public static readonly EXTERIOR_TRANSPORT_DETAIL: string = 'Admin.ExteriorTransportDetail';
  public static readonly EXTERIOR_TRANSPORT_EDIT: string = 'Admin.ExteriorTransportEdit';

  public static readonly SHOP_RENTER_SHOP_LIST: string = 'Admin.ShopRenterShopList';
  public static readonly SHOP_RENTER_SHOP_DETAIL: string = 'Admin.ShopRenterShopDetail';
  public static readonly SHOP_RENTER_SHOP_EDIT: string = 'Admin.ShopRenterShopEdit';

  public static readonly WORKLOG_LIST: string = 'Admin.WorklogList';

  public static readonly MILEAGE_RECORD_LIST: string = 'Admin.MileageRecordList';
  public static readonly MILEAGE_RECORD_DETAIL: string = 'Admin.MileageRecordDetail';

  public static readonly ORDER_IMPORT_ERROR_LIST: string = 'Admin.OrderImportErrorList';
  public static readonly ORDER_IMPORT_ERROR_DETAIL: string = 'Admin.OrderImportErrorDetail';

  public static readonly PARCEL_COLLECTION_POINT_LIST: string = 'Admin.ParcelCollectionPointList';
  public static readonly PARCEL_COLLECTION_POINT_CREATE: string = 'Admin.ParcelCollectionPointCreate';
  public static readonly PARCEL_COLLECTION_POINT_EDIT: string = 'Admin.ParcelCollectionPointEdit';
  public static readonly PARCEL_COLLECTION_POINT_DETAIL: string = 'Admin.ParcelCollectionPointDetail';

  public static readonly DELIVERY_METHOD_LIST: string = 'Admin.DeliveryMethodList';
  public static readonly DELIVERY_METHOD_CREATE: string = 'Admin.DeliveryMethodCreate';
  public static readonly DELIVERY_METHOD_EDIT: string = 'Admin.DeliveryMethodEdit';
  public static readonly DELIVERY_METHOD_DETAIL: string = 'Admin.DeliveryMethodDetail';

  public static readonly EMAIL_ADDRESS_TYPE_LIST: string = 'Admin.EmailAddressTypeList';
  public static readonly EMAIL_ADDRESS_TYPE_CREATE: string = 'Admin.EmailAddressTypeCreate';
  public static readonly EMAIL_ADDRESS_TYPE_EDIT: string = 'Admin.EmailAddressTypeEdit';
  public static readonly EMAIL_ADDRESS_TYPE_DETAIL: string = 'Admin.EmailAddressTypeDetail';

  public static readonly ANDROID_APP_VERSION: string = 'Admin.AndroidAppVersion';

  public static readonly PASSWORD_REQUEST: string = 'PasswordRequest';
  public static readonly PASSWORD_CHANGE: string = 'PasswordChange';

  // Helpdesk
  public static readonly HELPDESK_LOGIN: string = 'HelpdeskLogin';
  public static readonly HELPDESK_REGISTRATION: string = 'HelpdeskRegistration';
  public static readonly HELPDESK: string = 'Helpdesk';
  public static readonly HELPDESK_DASHBOARD: string = 'Helpdesk.Dashboard';
  public static readonly HELPDESK_BUG_REPORT_LIST: string = 'Helpdesk.BugReportList';
  public static readonly HELPDESK_BUG_REPORT_CREATE: string = 'Helpdesk.BugReportCreate';
  public static readonly HELPDESK_BUG_REPORT_EDIT: string = 'Helpdesk.BugReportEdit';
  public static readonly HELPDESK_BUG_REPORT_DETAIL: string = 'Helpdesk.BugReportDetail';

  public static readonly supportStates: string[] = [
      StateName.CONNECTION_ERROR,
      StateName.SERVER_ERROR,
      StateName.CONFIGURATION_LOAD_ERROR,
      StateName.LOGIN
  ]

  public static getLoginStateName(currentState: string): string {
    if (currentState.startsWith('Helpdesk')) {
      return this.HELPDESK_LOGIN;
    }
    return this.LOGIN;
  }
}

export class StateRequestParam {

  public static readonly SHOPRENTER_AUTH_SHOP_NAME = 'shopname';
  public static readonly SHOPRENTER_AUTH_CODE = 'code';
  public static readonly SHOPRENTER_AUTH_TIMESTAMP = 'timestamp';
  public static readonly SHOPRENTER_AUTH_HMAC = 'hmac';
  public static readonly SHOPRENTER_USER_ACTIVATION_TOKEN = 'shoprenter_user_activation_token';
  public static readonly USER_ACTIVATION_TOKEN = 'user_activation_token';
  public static readonly PASSWORD_RESET_TOKEN = 'password_reset_token';
  public static readonly HELPDESK_REGISTRATION_TOKEN = 'helpdesk_activation_token';

}
