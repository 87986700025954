<div class="card">
  <div class="card-header">
    {{'SHIPMENTS' | translate}}
    <div class="card-actions">
      <!--todo: search-->
<!--      <a class="btn-setting cursor-pointer"-->
<!--         title="{{'COMMON_SHOW_SEARCH_HINT' | translate}}"><i class="icomoon icomoon-search"></i></a>-->
    </div><!-- /.card-actions -->
  </div>
  <div class="card-body">
    <table class="table">
      <thead>
      <tr>
        <th class="table-sorter-header">
          <app-table-sorter-no-op [text]="'SHIPMENT_LIST_DELIVERY_NOTE_NUMBER' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header">
          <app-table-sorter-no-op [text]="'SHIPMENT_LIST_WAYBILL_NUMBER' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header hidden-sm-down">
          <app-table-field-sorter [orderField]="ShipmentGroup.OrderField.DELIVERY_NOTE_NUMBER"
                                  [orderType]="queryModel.getOrderType(ShipmentGroup.OrderField.DELIVERY_NOTE_NUMBER)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'SHIPMENT_LIST_GROUP_DELIVERY_NOTE_NUMBER' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header">
          <app-table-field-sorter [orderField]="ShipmentGroup.OrderField.DEMANDER_NAME"
                                  [orderType]="queryModel.getOrderType(ShipmentGroup.OrderField.DEMANDER_NAME)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'SHIPMENT_LIST_CUSTOMER' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header hidden-lg-down">
          <app-table-sorter-no-op [text]="'SHIPMENT_LIST_SOURCE_ADDRESS' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header hidden-lg-down">
          <app-table-sorter-no-op [text]="'SHIPMENT_LIST_DESTINATION_ADDRESS' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header hidden-sm-down">
          <app-table-sorter-no-op [text]="'SHIPMENT_LIST_STATE' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header w-table-actions">
          <app-table-sorter-no-op></app-table-sorter-no-op>
        </th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngFor="let shipmentGroup of shipmentGroupList | paginate: { id: pagingId,
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }">
        <tr [ngClass]="{'shipment-group-row': !shipmentGroup.isSingle}">
          <td class="responsive-table-column">{{shipmentGroup.isSingle ? shipmentGroup.shipment.deliveryNoteNumber : ''}}</td>
          <td class="responsive-table-column">{{shipmentGroup.isSingle ? shipmentGroup.shipment.waybillNumber : ''}}</td>
          <td class="responsive-table-column hidden-sm-down">{{shipmentGroup.deliveryNoteNumber}}</td>
          <td class="responsive-table-column">{{shipmentGroup.customer}}</td>
          <td class="responsive-table-column hidden-lg-down">{{shipmentGroup.sourceAddress}}</td>
          <td class="responsive-table-column hidden-lg-down">{{shipmentGroup.destinationAddress}}</td>
          <td class="responsive-table-column table-column-double hidden-sm-down">
            {{shipmentGroup.state}}
            <p class="text-muted mb-0" *ngIf="shipmentGroup.isSingle">{{shipmentGroup.shipment.state}}</p>
          </td>
          <td class="responsive-table-column" nowrap>
            <a class="icons-table-group cursor-pointer"
               uiSref="Admin.ShipmentEdit"
               *ngIf="canEditGroup(shipmentGroup)"
               [uiParams]="{ id: shipmentGroup.id }">
              <i class="icomoon icons-table-item icomoon-modify icomoon-modify-table"
                 title="{{'COMMON_BUTTON_EDIT' | translate}}"></i>
            </a>
            <a class="icons-table-group cursor-pointer"
               uiSref="Admin.ShipmentDetail"
               [uiParams]="{ id: shipmentGroup.id }">
              <i class="icomoon icons-table-item icomoon-detail icomoon-detail-table"
                 title="{{'COMMON_BUTTON_DETAILS' | translate}}"></i>
            </a>
            <a class="icons-table-group cursor-pointer"
               *ngIf="shipmentGroup.isSingle && isDeletableShipment(shipmentGroup.shipment) && rightModel.transportUpdate.hasRight()"
               (click)="deleteShipmentFromTransport(shipmentGroup.shipments.id)">
              <i class="icomoon icons-table-item icomoon-trash icomoon-detail-table"
                 title="{{'COMMON_BUTTON_DELETE' | translate}}"></i>
            </a>
          </td>
        </tr>
        <ng-container *ngIf="!shipmentGroup.isSingle">
          <tr class="shipment-row" *ngFor="let shipment of shipmentGroup.shipments">
            <td class="responsive-table-column">{{shipment.deliveryNoteNumber}}</td>
            <td class="responsive-table-column">{{shipment.waybillNumber}}</td>
            <td class="responsive-table-column hidden-sm-down"></td>
            <td class="responsive-table-column">{{shipment.customer}}</td>
            <td class="responsive-table-column hidden-lg-down">{{shipment.sourceAddress}}</td>
            <td class="responsive-table-column hidden-lg-down">{{shipment.destinationAddress}}</td>
            <td class="responsive-table-column hidden-sm-down">{{shipment.state}}</td>
            <td class="responsive-table-column hidden-1400-down"></td>
            <td class="responsive-table-column" nowrap>
              <a class="icons-table-group cursor-pointer"
                 *ngIf="isDeletableShipment(shipment) && rightModel.transportUpdate.hasRight()"
                 (click)="deleteShipmentFromTransport(shipment.id)">
                <i class="icomoon icons-table-item icomoon-detail icomoon-detail-table"
                   title="{{'COMMON_BUTTON_DELETE' | translate}}"></i>
              </a>
            </td>
          </tr>
        </ng-container>
      </ng-container>
      </tbody>
      <tfoot>
      <tr>
        <th class="table-sorter-header">
          <app-table-sorter-no-op [text]="'SHIPMENT_LIST_DELIVERY_NOTE_NUMBER' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header">
          <app-table-sorter-no-op [text]="'SHIPMENT_LIST_WAYBILL_NUMBER' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header hidden-sm-down">
          <app-table-field-sorter [orderField]="ShipmentGroup.OrderField.DELIVERY_NOTE_NUMBER"
                                  [orderType]="queryModel.getOrderType(ShipmentGroup.OrderField.DELIVERY_NOTE_NUMBER)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'SHIPMENT_LIST_GROUP_DELIVERY_NOTE_NUMBER' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header">
          <app-table-field-sorter [orderField]="ShipmentGroup.OrderField.DEMANDER_NAME"
                                  [orderType]="queryModel.getOrderType(ShipmentGroup.OrderField.DEMANDER_NAME)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'SHIPMENT_LIST_CUSTOMER' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header hidden-lg-down">
          <app-table-sorter-no-op [text]="'SHIPMENT_LIST_SOURCE_ADDRESS' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header hidden-lg-down">
          <app-table-sorter-no-op [text]="'SHIPMENT_LIST_DESTINATION_ADDRESS' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header hidden-sm-down">
          <app-table-sorter-no-op [text]="'SHIPMENT_LIST_STATE' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header w-table-actions">
          <app-table-sorter-no-op></app-table-sorter-no-op>
        </th>
      </tr>
      </tfoot>
    </table>
    <app-table-paging
      [id]="pagingId"
      [currentNumberOfItems]="queryModel.currentNumberOfItems"
      [totalNumberOfItems]="queryModel.totalNumberOfItems"
      [itemsPerPage]="queryModel.itemsPerPage"
      (pageChange)="pageChanged($event)"
      (itemsPerPageChange)="itemsPerPageChanged($event)">
    </app-table-paging>
  </div>
</div>
