/* eslint-disable */
import { Component, OnInit, ViewChild } from '@angular/core';
import { LegacyProcess, LegacyProcessService } from '../../../lib/legacy-process/legacy-process.service';
import { TranslateService } from '@ngx-translate/core';
import { Transition, UIRouter } from '@uirouter/angular';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { StateName } from '../../../app.state-names';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FieldValidationError, OrderType, QueryResult, ResourceQueryResult } from '../../../lib/util/services';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { AppNgbTimeStruct, NgbDatePickerParserFormatter } from '../../../util/ngb-datepicker';
import { OptionItems, QueryFieldModel, UiConstants } from '../../../util/core-utils';
import { User, UserService } from '../../../lib/user.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Order, OrderService } from '../../../lib/order/order.service';
import { Set } from 'immutable';
import { ToasterService } from '../../../fork/angular2-toaster/angular2-toaster';
import { StringKey } from '../../../app.string-keys';
import { LegacyProcessTask, LegacyProcessTaskService } from '../../../lib/legacy-process/legacy-process-task.service';
import { Angular2Multiselects } from '../../../util/multiselect';
import { TranslateUtils } from '../../../util/translate';
import { FileUploadDialogComponent } from '../../../shared/file-upload/dialog/file-upload-dialog.component';
import { Models } from '../../../util/model-utils';
import { RightModel } from '../../../app.rights';
import { RightResolver, RightService } from '../../../lib/right.service';
import { combineLatest, Observable } from 'rxjs';
import { AssigneeItem } from '../../../util/task-record-utils';
import { Company, CompanyService } from '../../../lib/company/company.service';
import { LegacyProcessMplExportDialogComponent } from '../legacy-process-mpl-export-dialog/legacy-process-mpl-export-dialog.component';

/* eslint-enable */

@Component({
  selector: 'app-legacy-process-edit',
  templateUrl: './legacy-process-edit.component.html',
  styleUrls: ['./legacy-process-edit.component.scss']
})
export class LegacyProcessEditComponent implements OnInit {

  private static readonly LOCAL_STORAGE_RELOAD_KEY = 'legacyProcessEditShowSuccessToastOnReload';

  UiConstants = UiConstants;
  Models = Models;
  LegacyProcess = LegacyProcess;

  @ViewChild('closeProcessDialog', { static: true })
  closeProcessDialog: ModalDirective;
  closeProcessDialogVisible: boolean = false;
  @ViewChild('stateImportDialog', { static: true }) stateImportDialog: FileUploadDialogComponent;

  @ViewChild('mplExportDialog', { static: true })
  mplExportDialog: LegacyProcessMplExportDialogComponent;
  mplStateImportPath = '';

  @ViewChild('f')
  fForm: NgForm;

  processId: number;
  model: LegacyProcess.LegacyProcess;
  baseDataModel: LegacyProcessEditModel = new LegacyProcessEditModel();
  processEditForm: FormGroup;
  private fieldErrors: FieldValidationError<LegacyProcess.ValidatedField> = FieldValidationError.empty<LegacyProcess.ValidatedField>();
  formSubmitted: boolean = false;
  processStates: LegacyProcessStateItem[] = [];
  processTaskStates: LegacyProcessTaskStateItem[] = [];
  users: AssigneeItem[] = [];
  selectedUser: UserItem[] = [];
  orderQueryModel: QueryFieldModel<Order.OrderField> = new QueryFieldModel(Order.OrderField.ID, OrderType.DESC);
  pagingId = 'processEdit';
  orderList: Order.Order[] = [];
  companyIds: number[] = [];
  companyList: CompanyItem[] = [];
  processTasks: LegacyProcessTaskWithAssignee[] = [];
  openTask?: string;

  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  dropdownSettings: Angular2Multiselects.Settings;

  rightModel: RightModel = RightModel.empty();

  constructor(
    private processService: LegacyProcessService,
    private orderService: OrderService,
    private companyService: CompanyService,
    private transition: Transition,
    private userService: UserService,
    private ngbDatePickerParserFormatter: NgbDatePickerParserFormatter,
    private translateService: TranslateService,
    private uiRouter: UIRouter,
    private toasterService: ToasterService,
    private formBuilder: FormBuilder,
    private rightService: RightService,
    private processTaskService: LegacyProcessTaskService
  ) {
    this.processId = this.transition.params().id;
    this.processEditForm = formBuilder.group({
      externalId: [[], Validators.required],
      name: [[]],
      deadlineDate: [[]]
    });
    this.mplStateImportPath = LegacyProcess.MPL_STATE_IMPORT_PATH.replace('{process_id}', this.processId.toString());
  }

  ngOnInit() {
    this.showSuccessToastIfSet();
    this.translateService.get('MENU_NAVBAR_LEGACY_PROCESSES').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.LEGACY_PROCESS_LIST});
      }
    );
    this.loadModel();
    this.loadProcessStates();
    this.loadProcessTaskStates();
    this.loadOrders();
    this.loadRightModels();
    this.loadProcessTasksWithUserNames();
  }

  private loadModel() {
    this.processService.get({
      id: this.processId
    }).subscribe(
      (process: LegacyProcess.LegacyProcess) => {
        this.breadcrumbSelf = process.displayName ? process.displayName : process.processId.toString();
        this.model = process;
        this.baseDataModel.name = process.displayName;
        this.baseDataModel.externalId = process.externalId;
        this.baseDataModel.description = process.displayDescription;
        const parsedDeadline = this.ngbDatePickerParserFormatter.fromOffsetDateTime(process.deadline);
        if (parsedDeadline) {
          this.baseDataModel.deadlineDate = {year: parsedDeadline.year, month: parsedDeadline.month, day: parsedDeadline.day};
          this.baseDataModel.deadlineTime = {
            hour: parsedDeadline.hour,
            minute: parsedDeadline.minute,
            second: parsedDeadline.second,
            millis: parsedDeadline.millis
          };
        }
      }
    );
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      });
  }

  private loadProcessTasksWithUserNames() {
    this.createCombinedObservable().subscribe(
      (result: CombinedResult) => {
        this.users = [];
        result.users.items.forEach((user) => {
          const item = {id: user.id, itemName: user.person_name + ' (' + user.user_name + ')'};
          this.users.push(item);
        });
        this.users.sort(OptionItems.assigneeNameComparator());
        this.initDropdown();
        this.processTasks = [];
        this.processTasks = result.processTasks.items.toArray();
        this.setProcessTaskAssignees();
        this.openTask = this.loadOpenProcessTask();
      },
      () => {
      },
      () => {
      }
    );
  }

  private createCombinedObservable(): Observable<CombinedResult> {
    const fields: string[] = ['id', 'user_name', 'person_name'];
    return combineLatest(
      this.processTaskService.query({
        processId: this.processId
      }),
      this.userService.query({
        fields: fields.join(',')
      }),
      (
        processTasks: QueryResult<LegacyProcessTask.LegacyProcessTask>,
        users: ResourceQueryResult<User>) => {
        return {
          users: users,
          processTasks: processTasks
        };
      }
    );
  }

  private loadProcessTasks() {
    this.processTaskService.query({
      processId: this.processId
    }).subscribe(
      (result: QueryResult<LegacyProcessTask.LegacyProcessTask>) => {
        this.processTasks = [];
        this.processTasks = result.items.toArray();
        this.setProcessTaskAssignees();
        this.openTask = this.loadOpenProcessTask();
      });
  }

  private loadOpenProcessTask(): string | undefined {
    this.processTasks.forEach((task) => {
      if (task.state === 'IN_PROGRESS') {
        return task.displayName;
      }
    });
    return undefined;
  }

  private loadProcessStates() {
    this.processStates = [];
    LegacyProcess.processStates.forEach((state) => {
      const item = {
        id: state.state,
        stringKey: '...',
        icon: state.icon
      };
      this.processStates.push(item);
      this.translateService.get(state.stringKey).subscribe((text: string) => {
        item.stringKey = text;
      });
    });
  }

  private loadProcessTaskStates() {
    this.processTaskStates = [];
    LegacyProcessTask.processTaskStates.forEach((state) => {
      const item = {
        id: state.state,
        stringKey: '...'
      };
      this.processTaskStates.push(item);
      this.translateService.get(state.stringKey).subscribe((text: string) => {
        item.stringKey = text;
      });
    });
  }

  private loadOrders(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.orderQueryModel.currentPage;
    const order = this.orderQueryModel.getOrder();
    this.orderService.query({
      processId: this.processId,
      orders: Set.of(order),
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.orderQueryModel.itemsPerPage
      } : undefined
    }).subscribe(
      (result: QueryResult<Order.Order>) => {
        this.orderList = result.items.toArray();
        this.companyIds = [];
        this.orderList.forEach((item) => {
          this.companyIds.push(item.ownerCompanyId);
        });
        this.loadCompanys();
        this.orderQueryModel.currentPage = requestedPage;
        this.orderQueryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
        this.orderQueryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
      });
  }

  private loadCompanys() {
    this.companyService.query({
      id: Set.of(...this.companyIds)
    }).subscribe((result: QueryResult<Company.Company>) => {
      this.companyList = [];
      result.items.forEach((cr) => {
        if (cr) {
          this.companyList.push({
            id: cr.id,
            name: cr.name
          });
        }
      });
    });
  }

  private setProcessTaskAssignees() {
    this.processTasks.forEach((processTask) => {
      this.users.forEach((user) => {
        if (user.id === processTask.assigneeUserId) {
          processTask.selectedAssignee = [];
          processTask.selectedAssignee.push(user);
        }
      });
    });
  }

  initDropdown() {
    this.translateService.get([
      StringKey.COMMON_PLACEHOLDER_EMPTY_LIST,
      StringKey.COMMON_PLACEHOLDER_SELECT_ALL,
      StringKey.COMMON_PLACEHOLDER_SEARCH,
      StringKey.STOCK_LABEL_OWNER_USER
    ]).subscribe((o) => {
      this.dropdownSettings = {
        singleSelection: true,
        text: TranslateUtils.extractValueFromObject(o, StringKey.STOCK_LABEL_OWNER_USER),
        selectAllText: TranslateUtils.extractValueFromObject(o, StringKey.COMMON_PLACEHOLDER_SELECT_ALL),
        unSelectAllText: TranslateUtils.extractValueFromObject(o, StringKey.COMMON_PLACEHOLDER_SELECT_ALL),
        searchPlaceholderText: TranslateUtils.extractValueFromObject(o, StringKey.COMMON_PLACEHOLDER_SEARCH),
        noDataLabel: TranslateUtils.extractValueFromObject(o, StringKey.STOCK_LABEL_OWNER_USER),
        enableSearchFilter: true,
        badgeShowLimit: UiConstants.angular2MultiselectUserGroupEditorBadgeLimit,
        classes: UiConstants.angular2MultiselectClass,
        enableCheckAll: true,
      };
    });
  }

  saveBaseData() {
    this.formSubmitted = true;
    if (!this.processEditForm.valid) {
      this.processEditForm.get('externalId')!.markAsTouched();
      return;
    }
    else {
      const deadline = this.ngbDatePickerParserFormatter.toOffsetDateTime(
        this.baseDataModel.deadlineDate,
        this.baseDataModel.deadlineTime)
        .toUtcIsoString();
      this.processService.update({
        id: this.processId,
        name: this.baseDataModel.name,
        externalId: this.baseDataModel.externalId,
        description: this.baseDataModel.description,
        deadline: deadline !== '' ? deadline : undefined,
      }).subscribe(() => {
          this.loadModel();
        },
        (error: any) => {
          if (error instanceof FieldValidationError) {
            this.fieldErrors = error.withForm(this.fForm);
          }
        });
    }
  }

  updateProcessTaskAssignee(processTask: LegacyProcessTaskWithAssignee) {
    const processTaskAssigneeId = processTask.selectedAssignee ?
      processTask.selectedAssignee[0] ? processTask.selectedAssignee[0].id : null
      : null;
    this.processTaskService.changeAssignee({
      processId: processTask.processId,
      processTaskId: processTask.processTaskId,
      assigneeUserId: processTaskAssigneeId
    }).subscribe(() => {
      this.loadProcessTasks();
    });
  }

  orderPageChanged(selectedPage: number) {
    this.loadOrders(selectedPage);
  }

  orderItemsPerPageChanged(itemsPerPage: number) {
    this.orderQueryModel.itemsPerPage = itemsPerPage;
    this.loadOrders(1);
  }

  hasFieldError(field: LegacyProcess.ValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: LegacyProcess.ValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: LegacyProcess.ValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  getProcessStateName(state: string): string {
    const filtered_states = this.processStates.filter(current_state => current_state.id === state);
    if (filtered_states === undefined || filtered_states.length === 0) {
      return '';
    }
    return filtered_states[0].stringKey;
  }

  getProcessTaskName(id: number): string {
    const filtered_tasks = this.processTasks.filter(current_task => current_task.processTaskId === id);
    if (filtered_tasks === undefined || filtered_tasks.length === 0) {
      return '';
    }
    return filtered_tasks[0].displayName;
  }

  getProcessTaskStateName(state: string): string {
    const filtered_states = this.processTaskStates.filter(current_state => current_state.id === state);
    if (filtered_states === undefined || filtered_states.length === 0) {
      return '';
    }
    return filtered_states[0].stringKey;
  }

  getProcessStateIcon(state: string): string {
    const filtered_states = this.processStates.filter(current_state => current_state.id === state);
    if (filtered_states === undefined || filtered_states.length === 0) {
      return '';
    }
    return filtered_states[0].icon;
  }

  getProcessTaskStateColor(state: string) {
    switch (state) {
      case 'TO_DO':
        return {
          'border': '1px solid #9b9b9b',
          'color': '#9b9b9b'
        };
      case 'OPEN':
        return {
          'border': '1px solid #00727b',
          'color': '#00727b'
        };
      case 'IN_PROGRESS':
        return {
          'border': '1px solid #01aee8',
          'color': '#01aee8'
        };
      case 'PENDING_APPROVAL':
        return {
          'border': '1px solid #f0ad4e',
          'color': '#f0ad4e'
        };
      case 'FINISHED':
        return {
          'border': '1px solid #8cc152',
          'color': '#8cc152'
        };
      case 'FAILED':
        return {
          'border': '1px solid #d0021b',
          'color': '#d0021b'
        };
      default:
        return {
          'border': '1px solid gray',
          'color': 'gray'
        };
    }
  }

  getUserName(id: number): string {
    const filtered_users = this.users.filter(current_user => current_user.id === id);
    if (filtered_users === undefined || filtered_users.length === 0) {
      return '';
    }
    return filtered_users[0].itemName;
  }

  private getCompanyName(id: number): string {
    const filtered_records = this.companyList.filter(current_record => current_record.id === id);
    if (filtered_records === undefined || filtered_records.length === 0) {
      return '';
    }
    return filtered_records[0].name;
  }

  showCloseProcessDialog() {
    this.closeProcessDialog.show();
    this.closeProcessDialogVisible = true;
  }

  hideCloseProcessDialog() {
    this.closeProcessDialog.hide();
    this.closeProcessDialogVisible = false;
  }

  startProcess() {
    if (!this.validateProcessTaskAssignee()) {
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutLong,
        type: UiConstants.toastTypeError,
        title: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_TITLE),
        body: this.translateService.instant(StringKey.PROCESS_TASK_NO_ASSIGNEE_SET)
      });
      return;
    }
    if (this.orderList.length < 1) {
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutLong,
        type: UiConstants.toastTypeError,
        title: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_TITLE),
        body: this.translateService.instant(StringKey.PROCESS_TASK_NO_ORDERS)
      });
      return;
    }
    this.processService.startProcess({
      id: this.processId
    }).subscribe(() => {
      this.showSuccessToastOnReload(true);
      this.uiRouter.stateService.reload();
    });
  }

  closeProcess(reject: boolean) {
    this.processService.closeProcess({
      id: this.processId,
      rejectOrders: reject
    }).subscribe(() => {
      this.uiRouter.stateService.reload();
    });
  }

  continueCurrentTask() {
    this.processService.continueCurrentTask({
      id: this.processId
    }).subscribe(() => {
      this.uiRouter.stateService.reload();
    });
  }

  continuePreviousTask() {
    this.processService.continuePreviousTask({
      id: this.processId
    }).subscribe(() => {
      this.uiRouter.stateService.reload();
    });
  }

  reopenProcess() {
    this.processService.reopenProcess({
      id: this.processId
    }).subscribe(() => {
      this.uiRouter.stateService.reload();
    });
  }

  deleteOrder(orderId: number) {
    if (this.model.state !== 'OPEN') {
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutLong,
        type: UiConstants.toastTypeError,
        title: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_TITLE),
        body: this.translateService.instant(StringKey.PROCESS_EDIT_DELETE_ORDER_ERROR)
      });
    }
    else {
      this.processService.deleteOrder({
        processId: this.processId,
        orderIds: [orderId]
      }).subscribe(() => {
        this.loadOrders();
      });
    }
  }

  isEditableState(state: string): boolean {
    if (state === 'TO_DO' || state === 'OPEN') {
      return true;
    }
    return false;
  }

  onImportSuccess(succeeded: boolean) {
    if (succeeded) {
      this.loadOrders(1);
    }
  }

  private validateProcessTaskAssignee(): boolean {
    // regular for loop is used instead of foreach in order to return correct value
    for (let i = 0; i < this.processTasks.length; i++) {
      if (!this.processTasks[i].assigneeUserId) {
        return false;
      }
    }
    return true;
  }

  showSuccessToastOnReload(show: boolean) {
    if (show) {
      localStorage.setItem(LegacyProcessEditComponent.LOCAL_STORAGE_RELOAD_KEY, 'true');
    }
    else {
      localStorage.setItem(LegacyProcessEditComponent.LOCAL_STORAGE_RELOAD_KEY, 'false');
    }
  }

  showSuccessToastIfSet() {
    const showSuccessToastOnReload = localStorage.getItem(LegacyProcessEditComponent.LOCAL_STORAGE_RELOAD_KEY);
    if (showSuccessToastOnReload && showSuccessToastOnReload === 'true') {
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutShort,
        type: UiConstants.toastTypeSuccess,
        title: this.translateService.instant(StringKey.COMMON_SUCCESS),
        body: this.translateService.instant(StringKey.PROCESS_EDIT_PROCESS_START_SUCCESS)
      });
    }
    this.showSuccessToastOnReload(false);
  }
}

class LegacyProcessEditModel {
  name?: string = undefined;
  externalId: string = '';
  description?: string = undefined;
  deadlineDate?: NgbDateStruct = undefined;
  deadlineTime?: AppNgbTimeStruct = undefined;
}

interface LegacyProcessStateItem {
  id: string;
  stringKey: string;
  icon: string;
}

interface LegacyProcessTaskStateItem {
  id: string;
  stringKey: string;
}

interface UserItem {
  id: number;
  name: string;
}

interface CompanyItem {
  id: number;
  name: string;
}

interface LegacyProcessTaskWithAssignee extends LegacyProcessTask.LegacyProcessTask {
  selectedAssignee?: AssigneeItem[];
}

interface CombinedResult {
  users: ResourceQueryResult<User>;
  processTasks: QueryResult<LegacyProcessTask.LegacyProcessTask>;
}
