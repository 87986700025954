import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ShippingItem, ShippingItemService } from '../../../../lib/shipping-item/shipping-item.service';
import { QueryResult } from '../../../../lib/util/services';
import { UiConstants } from '../../../../util/core-utils';
import { ShippingDemandService } from '../../../../lib/shipping-demand/shipping-demand.service';
import { IdentityMessage } from '../../../../lib/util/messages';
import { UIRouter } from '@uirouter/angular';
import { StateName } from '../../../../app.state-names';
import { ToasterService } from '../../../../fork/angular2-toaster/src/toaster.service';
import { StringKey } from '../../../../app.string-keys';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-shipping-demand-split-dialog',
  templateUrl: './shipping-demand-split-dialog.component.html',
  styleUrls: ['./shipping-demand-split-dialog.component.scss']
})
export class ShippingDemandSplitDialogComponent implements OnInit {

  UiConstants = UiConstants;

  @Input()
  demandId: number;

  @Input()
  fromDetail: boolean;

  @Output()
  onSubmit: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('dialog', { static: true })
  dialog: ModalDirective;

  isVisible: boolean = false;
  submitted: boolean = false;
  selected: boolean = false;

  private _items: ShippingItemModel[] = [];

  get items() {
    return this.selected ? this._items.filter(i => i.selected) : this._items;
  }

  constructor(
    private uiRouter: UIRouter,
    private shippingItemService: ShippingItemService,
    private toasterService: ToasterService,
    private translateService: TranslateService,
    private shippingDemandService: ShippingDemandService
  ) {}

  ngOnInit() {
  }

  show() {
    this.loadItems(() => {
      this.isVisible = true;
      this.selected = false;
      this.submitted = false;
      this.dialog.show();
    });
  }

  hide() {
    this.isVisible = false;
    this.dialog.hide();
  }

  private loadItems(completion: () => void) {
    this.shippingItemService.query({demandId: this.demandId}).subscribe((result: QueryResult<ShippingItem.ShippingItem>) => {
      this._items = result.items.toArray().map(i => {
        const item = new ShippingItemModel();
        item.name = i.name;
        item.externalId = i.externalId;
        item.availableAmount = i.amount.value;
        item.unit = i.amount.unit;
        return item;
      });
      completion();
    });
  }

  isItemValid(item: ShippingItemModel) {
    return !this.submitted || !item.selected || (item.selectedAmount > 0 && item.selectedAmount <= item.availableAmount);
  }

  isItemsValid() {
    for (let i = 0; i < this._items.length; i++) {
      if (!this.isItemValid(this._items[i])) {
        return false;
      }
    }
    return true;
  }

  selectItems() {
    this.submitted = true;
    if (!this.isItemsValid()) {
      return;
    }
    if (this._items.filter(i => i.selected).length === 0) {
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutLong,
        type: UiConstants.toastTypeError,
        title: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_TITLE),
        body: this.translateService.instant(StringKey.SHIPPING_DEMAND_SPLIT__ERROR_MIN)
      });
      return;
    }
    this.selected = true;
  }

  splitDemand() {
    this.shippingDemandService.split({
      shippingDemandId: this.demandId,
      items: this.items.map(i => ({externalId: i.externalId, amount: i.selectedAmount}))
    }).subscribe((result: IdentityMessage) => {
      this.onSubmit.emit();
      if (this.fromDetail) {
        this.uiRouter.stateService.go(StateName.SHIPPING_DEMAND_DETAIL, {id: result.id});
      }
      else {
        this.uiRouter.stateService.go(StateName.SHIPPING_DEMAND_EDIT, {id: result.id});
      }
    });
  }

}

class ShippingItemModel {
  name: string = '';
  externalId: string = '';
  availableAmount: number = 0;
  selectedAmount: number = 0;
  selected: boolean = false;
  unit: string = '';
}
