/* eslint-disable */
import { TranslateLoader } from '@ngx-translate/core';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
/* eslint-enable */

/**
 * A JS object that defines key-values.
 * Example:
 * {
 *   key1: "value1 as string",
 *   key2: "value2 as string and some {{argument}}",
 * }
 */
type Dictionary = any;

const EMPTY_DICTIONARY: Dictionary = {};

export class AppTranslateLoader implements TranslateLoader {

  private primarySource: TranslateLoader;
  private secondarySource: TranslateLoader;

  constructor(http: HttpClient) {
    this.primarySource = new TranslateHttpLoader(http, './assets/strings/', '.json');
    this.secondarySource = new TranslateHttpLoader(http, './environment/strings/', '.json');
  }

  getTranslation(lang: string): Observable<Dictionary> {
    const primary = this.primarySource.getTranslation(lang);
    const secondary = this.secondarySource.getTranslation(lang).pipe(
      catchError(error => of(EMPTY_DICTIONARY))
    );
    return combineLatest(primary, secondary, (primaryResult: Dictionary, secondaryResult: Dictionary) => {
      const mergedResult = Object.assign({}, primaryResult, secondaryResult);
      return mergedResult; // debug line
    });
  }

}
