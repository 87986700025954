/// <reference types="@types/googlemaps" />
import {
  ApplicationRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Injector, Input,
  NgZone,
  OnInit,
  ViewChild
} from '@angular/core';
import { GoogleMapsLoaderUtil } from '../../../../../util/google/google-maps-loader.util';
import { ConfigurationResource, ConfigurationService } from '../../../../../lib/core-ext/configuration.service';
import { HttpClient } from '@angular/common/http';
import { UIRouter } from '@uirouter/angular';
import { StateName } from '../../../../../app.state-names';
import { TaskRecordMapInfoWindowComponent } from './info-window/task-record-map-info-window.component';
import { TaskRecord, TaskRecordService } from '../../../../../lib/task/task-record.service';
import Decimal from 'decimal.js';
import { Set } from 'immutable';
import { TaskRecordStateMachine } from '../../../../../lib/task/task-record-statemachine';
import { TaskRecordSearchModel } from '../../../../../util/task-record-utils';
/* eslint-enable */


@Component({
  selector: 'app-task-record-list-map',
  templateUrl: './task-record-list-map.component.html',
  styleUrls: ['./task-record-list-map.component.scss']
})
export class TaskRecordListMapComponent implements OnInit {

  @Input()
  taskId?: number;

  @Input()
  searchModel: TaskRecordSearchModel;

  @ViewChild('gmap', { static: true })
  gmapElement: any;

  map: google.maps.Map;

  private config: ConfigurationResource.Configuration;

  private clusters: TaskRecordMapClusterModel[] = [];

  loadingClusters: boolean = false;
  isInfoWindowVisible: boolean;

  compRef: ComponentRef<TaskRecordMapInfoWindowComponent>;

  constructor(private taskRecordService: TaskRecordService,
              private configurationService: ConfigurationService,
              private http: HttpClient,
              private injector: Injector,
              private resolver: ComponentFactoryResolver,
              private appRef: ApplicationRef,
              private zone: NgZone,
              private uiRouter: UIRouter) {
  }

  ngOnInit() {
    this.config = this.configurationService.getConfiguration();
    this.initMap();
  }

  initMap() {
    if (!this.map) {
      if (GoogleMapsLoaderUtil.didMapLoad()) {
        this.createMap();
      }
      else {
        GoogleMapsLoaderUtil.subscribe(() => {
          this.createMap();
        });
      }
    }
  }

  createMap() {
    const mapProp = {
      center: new google.maps.LatLng(47.49801, 19.03991),
      zoom: 7,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      streetViewControl: false,
      mapTypeControl: false
    };
    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
    const realThis = this;
    google.maps.event.addListener(this.map, 'bounds_changed', function () {
      realThis.loadData();
    });
  }

  loadData() {
    if (!this.loadingClusters) {
      this.loadingClusters = true;
      const mapBounds = this.map.getBounds()!;
      this.taskRecordService.getClusters({
        fields: Set.of(...['task_id', 'id', 'name', 'state', 'customer_record', 'customer_id',
          'place_of_consumption', 'assignee', 'finalization_time', 'deadline']),
        customerFields: Set.of(...['customer_id', 'name']),
        swLat: new Decimal(mapBounds.getSouthWest().lat()),
        swLon: new Decimal(mapBounds.getSouthWest().lng()),
        neLat: new Decimal(mapBounds.getNorthEast().lat()),
        neLon: new Decimal(mapBounds.getNorthEast().lng()),
        taskId: this.taskId,
        userIds: this.searchModel.assigneeUser.length > 0 ? Set.of(this.searchModel.assigneeUser[0]!.id) : undefined,
        userGroupIds: this.searchModel.userGroup.length > 0 ? Set.of(this.searchModel.userGroup[0]!.id!) : undefined,
        state: this.searchModel.states !== null ? Set.of(...this.searchModel.states.map(s => s.id)) : undefined,
        noProgressBar: true
      }).subscribe((result: TaskRecord.ClustersResponse[]) => {
        this.loadingClusters = false;
        this.createMarkers(result);
      },
        () => {
          this.loadingClusters = false;
        });
    }
  }

  private createMarkers(clusters: TaskRecord.ClustersResponse[]) {
    const newClusters: TaskRecordMapClusterModel[] = [];

    clusters.forEach((c, index) => {
      let marker: google.maps.Marker;

      const coord = c.center;
      const latlng = new google.maps.LatLng(coord.latitude.toNumber(), coord.longitude.toNumber());
      const oldClusterIndex = this.clusters.findIndex(m => m.cluster.clusterId === c.clusterId);

      if (oldClusterIndex >= 0) {
        const oldCluster = this.clusters.splice(oldClusterIndex, 1)[0];
        oldCluster.cluster = c;
        marker = oldCluster.marker;
        newClusters.push(oldCluster);
      }
      else {
        marker = new google.maps.Marker({});
        marker.setMap(this.map);
        const model: TaskRecordMapClusterModel = {
          cluster: c,
          marker: marker,
          infoWindow: new google.maps.InfoWindow({maxWidth: 1000}),
        };
        newClusters.push(model);
      }
      marker.setPosition(latlng);
      marker.setZIndex(index);
      if (c.taskRecord) {
        marker.setIcon({
          url: this.getPoiUrl(c.taskRecord.state),
          scaledSize: new google.maps.Size(48, 48)
        });
        const model = newClusters.find(cc => cc.cluster.clusterId === c.clusterId)!;
        const zone = this.zone;
        const realThis = this; // I love js
        marker.addListener('click', function (e) {
          zone.run(() => realThis.onMarkerClick(model, e));
        });
        model.infoWindow.addListener('closeclick', _ => {
          realThis.compRef.destroy();
        });
      }
      else {
        marker.setIcon({
          url: '../../../../../assets/img/poi/task-record/cluster.svg',
          scaledSize: new google.maps.Size(36, 36),
          anchor: new google.maps.Point(18, 18)
        });
        marker.setLabel({
          text: this.getLabelText(c.count),
          fontSize: '13px'
        });
      }
    });
    this.clusters.forEach(l => {
      l.marker.setMap(null);
    });
    this.clusters = newClusters;
  }

  private getLabelText(count: number): string {
    if (count < 1000) {
      return count.toFixed();
    }
    if (count < 1000000) {
      return (count / 1000).toFixed(1) + 'K';
    }
    return (count / 1000000).toFixed(1) + 'M';
  }

  private getPoiUrl(state: TaskRecordStateMachine.State): string {
    return '../../../../../assets/img/poi/task-record/' + state.toString().toLowerCase() + '.svg';
  }

  private onMarkerClick(model: TaskRecordMapClusterModel, e: any) {
    if (this.compRef) {
      this.compRef.destroy();
    }

    // window component create
    const compFactory = this.resolver.resolveComponentFactory(TaskRecordMapInfoWindowComponent);
    this.compRef = compFactory.create(this.injector);

    // set model
    this.compRef.instance.model = model;
    const taskRecordSubscription = this.compRef.instance.onTaskRecordClicked.subscribe(x => {
      this.navigateToTaskRecord(x);
    });
    const customerRecordSubscription = this.compRef.instance.onCustomerRecordClicked.subscribe(x => {
      this.navigateToCustomerRecord(x);
    });

    // node create
    const div = document.createElement('div');
    div.appendChild(this.compRef.location.nativeElement);

    model.infoWindow.setContent(div);
    model.infoWindow.open(this.map, model.marker);

    this.appRef.attachView(this.compRef.hostView);
    this.compRef.onDestroy(() => {
      model.infoWindow.close();
      this.appRef.detachView(this.compRef.hostView);
      taskRecordSubscription.unsubscribe();
      customerRecordSubscription.unsubscribe();
    });
  }

  navigateToTaskRecord(taskRecord: TaskRecord.TaskRecord) {
    this.uiRouter.stateService.go(StateName.TASK_RECORD_DETAIL, {taskId: taskRecord.taskId, taskRecordId: taskRecord.taskRecordId});
  }

  navigateToCustomerRecord(taskRecord: TaskRecord.TaskRecord) {
    this.uiRouter.stateService.go(StateName.CUSTOMER_RECORD_DETAIL,
      {customerId: taskRecord.customerRecord!.customerId, customerRecordId: taskRecord.customerId});
  }
}

export class TaskRecordMapClusterModel {

  cluster: TaskRecord.ClustersResponse;
  marker: google.maps.Marker;
  infoWindow: google.maps.InfoWindow;
}

