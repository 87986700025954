/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { ResourceQueryResult } from '../util/services';
import { ExteriorTransport } from './exterior-transport.service';
import { EmptyMessage, IdentityMessage } from '../util/messages';
import { ShipmentGroupResource } from '../shipment-group/shipment-group-resource.service';
import { DownloadedFile } from '../util/downloaded-files';
import { UrlBuilder } from '../../util/url-builder';
import { map } from 'rxjs/operators';

/* eslint-enable */

@Injectable()
export class ExteriorTransportResourceService extends BaseHttpService {

  query(request: ExteriorTransportResource.QueryRequest): Observable<ResourceQueryResult<ExteriorTransportResource.ExteriorTransport>> {
    return this.http.get<ResourceQueryResult<ExteriorTransportResource.ExteriorTransport>>(this.url, this.parseParams(request));
  }

  get(request: ExteriorTransportResource.GetRequest): Observable<ExteriorTransportResource.ExteriorTransport> {
    return this.http.get<ExteriorTransportResource.ExteriorTransport>(this.url + `${request.id}`, this.parseParams(request));
  }

  delete(request: IdentityMessage): Observable<EmptyMessage> {
    return this.http.delete<EmptyMessage>(this.url + `${request.id}`);
  }

  createByDemands(request: ExteriorTransportResource.CreateByDemandsRequest): Observable<IdentityMessage> {
    return this.http.post<IdentityMessage>(this.url + 'create-by-demands', request);
  }

  createByShipments(request: ExteriorTransportResource.CreateByShipmentsRequest): Observable<IdentityMessage> {
    return this.http.post<IdentityMessage>(this.url + 'create-by-shipments', request);
  }

  removeShipment(request: ExteriorTransportResource.RemoveShipmentRequest): Observable<EmptyMessage> {
    return this.http.delete<EmptyMessage>(this.url +
      `${request.exterior_transport_id}/shipments/${request.shipment_id}?reset_tracking_number=${request.reset_tracking_number}`);
  }

  start(request: IdentityMessage): Observable<EmptyMessage> {
    return this.http.post<EmptyMessage>(this.url + `${request.id}/start`, request);
  }

  getShipments(request: ExteriorTransportResource.GetShipmentsRequest):
    Observable<ResourceQueryResult<ShipmentGroupResource.ShipmentGroup>> {
    return this.http.get<ResourceQueryResult<ShipmentGroupResource.ShipmentGroup>>(
      this.url + `${request.exterior_transport_id}/shipment-groups`, this.parseParams(request));
  }

  mplExport(request: ExteriorTransportResource.GetRequest): Observable<DownloadedFile> {
    const url = UrlBuilder.create('/exterior-transports/:exteriorTransportId' +
      '/mpl-request-document-generate?{:export_rows_with_shipment_tracking_number}')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .namedNumber('exteriorTransportId', request.id)
      .queryString('export_rows_with_shipment_tracking_number', 'true')
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob',
      params: this.parseParams(request).params
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  getEventLog(request: ExteriorTransportResource.EventLogRequest): Observable<ResourceQueryResult<ExteriorTransportResource.EventLog>> {
    return this.http.get<ResourceQueryResult<ExteriorTransportResource.EventLog>>(
      this.url + `${request.id}/event-logs`,
      this.parseParams(request)
    );
  }

  constructor(private http: HttpClient, private resourceHelper: ResourceHelper) {
    super(resourceHelper, 'exterior-transports/');
  }

}

export namespace ExteriorTransportResource {

  export interface ExteriorTransport {
    id: number;
    external_id: string;
    granted_rights?: string[];
    state: string;
    creation_time: string;
    start_time?: string;
    finish_time?: string;
    serial_number: string;
    transporter_company: ExteriorTransport.Company;
    demander_company?: ExteriorTransport.Company;
  }

  export interface QueryRequest {
    fields?: string;
    rights?: string;
    filter?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
  }

  export interface GetRequest {
    id: number;
    rights?: string;
  }

  export interface CreateByDemandsRequest {
    demand_ids: number[];
  }

  export interface CreateByShipmentsRequest {
    shipment_ids: number[];
  }

  export interface RemoveShipmentRequest {
    exterior_transport_id: number;
    shipment_id: number;
    reset_tracking_number: boolean;
  }

  export interface GetShipmentsRequest {
    exterior_transport_id: number;
    q?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
  }

  export interface EventLogRequest {
    id: number;
    order?: string;
    page_number?: number;
    number_of_items?: number;
  }

  export interface EventLog {
    id: number;
    creation_time: string;
    type: string;
    user: {
      id: number;
      person_name: string;
    };
  }

}
