import { AfterViewInit, Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { List, Set } from 'immutable';
import { LocalFieldValidationErrors, LocalFieldValidationErrorsFactory } from '../../../../lib/util/services';
import { NgForm, NgModel } from '@angular/forms';
import { MultiselectOptionItem } from '../../../../util/core-utils';
import { Angular2Multiselects } from '../../../../util/multiselect';
import { SearchUtils } from '../../../../util/search-utils';
import { RightModel } from '../../../../app.rights';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import {
  ColonnadeClientDocumentStatSearch,
  ColonnadeClientDocumentStatSearchService
} from '../../../../lib/colonnade-client-document-stat/colonnade-client-document-stat-search.service';
import { Models } from '../../../../util/model-utils';
import { TableDocumentContentService } from '../../../../lib/table-document-content/table-document-content.service';
import { UserMultiselectProvider } from '../../../../lib/user/user-multiselect.provider';
import { Dates } from '../../../../lib/util/dates';
import { ColonnadeClientDocumentStat } from '../../../../lib/colonnade-client-document-stat/colonnade-client-document-stat.service';
import SearchableList = SearchUtils.SearchableList;
import SearchModel = SearchUtils.SearchModel;

@Component({
  selector: 'app-colonnade-client-document-stat-search',
  templateUrl: './colonnade-client-document-stat-search.component.html',
  styleUrls: ['./colonnade-client-document-stat-search.component.scss']
})
export class ColonnadeClientDocumentStatSearchComponent extends SearchableList<ColonnadeClientDocumentStatSearchModel>
  implements OnInit, AfterViewInit {

  @Input()
  searchScope: ColonnadeClientDocumentStatSearchSearchScope;

  @Input()
  currentUserId?: number;

  @Input()
  rightModel: RightModel = RightModel.empty();

  @Input()
  userRequired: boolean = false;

  @Input()
  branchCodeRequired: boolean = false;

  @Input()
  sponsorCodeRequired: boolean = false;

  @Input()
  dateFromRequired: boolean = false;

  @Input()
  dateToRequired: boolean = false;

  @ViewChild('f', { static: true })
  fForm: NgForm;

  @ViewChild('user')
  userInput: NgModel;

  @ViewChild('branchCode', { static: true })
  branchCodeInput: NgModel;

  @ViewChild('sponsorCode', { static: true })
  sponsorCodeInput: NgModel;

  @ViewChild('dateFrom', { static: true })
  dateFromInput: NgModel;

  @ViewChild('dateTo', { static: true })
  dateToInput: NgModel;

  @Output()
  onSearch: EventEmitter<any> = new EventEmitter();

  didSearch: boolean = false;

  searchModel: ColonnadeClientDocumentStatSearchModel = new ColonnadeClientDocumentStatSearchModel();

  private validatedInputs: LocalFieldValidationErrors<NgModel> =
    LocalFieldValidationErrorsFactory.empty();

  users: MultiselectOptionItem<number>[] = [];
  branchesForSearch: MultiselectOptionItem<string>[] = [];
  sponsorsForSearch: MultiselectOptionItem<string>[] = [];
  branches: Branch[] = [];
  sponsors: Sponsor[] = [];

  dropdownSettings: Angular2Multiselects.Settings;
  dropdownSettingsForBranch: Angular2Multiselects.Settings;

  constructor(private injector: Injector,
              private tableDocumentContentService: TableDocumentContentService,
              private colonnadeClientDocumentStatResourceService: ColonnadeClientDocumentStatSearchService,
              private userMultiselectProvider: UserMultiselectProvider) {
    super(ColonnadeClientDocumentStatSearchModel, injector);
  }

  ngOnInit() {
    this.initDropdownSettings();
    this.initSearch();
    this.loadSponsors();
    this.loadBranches();
    this.onUserSearch();
  }

  ngAfterViewInit(): void {
    this.loadLocalFieldValidationErrors();
  }

  initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .remoteSearch(true)
      .build();
    this.dropdownSettingsForBranch = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .remoteSearch(true)
      .disabled(true)
      .build();
  }

  updateBranchDropdownSettings() {
    const disabled = this.searchModel.sponsorCode.length === 0;
    this.dropdownSettingsForBranch = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .remoteSearch(true)
      .disabled(disabled)
      .build();
  }

  loadSearch(completion: () => void): void {
    this.colonnadeClientDocumentStatResourceService.getSearchData({
      scope: this.searchScope
    }).subscribe(
      (result: ColonnadeClientDocumentStatSearch.SearchDataResult) => {
        this.searchModel.user = result.searchData.user;
        this.searchModel.branchCode = result.searchData.branchCode;
        this.searchModel.sponsorCode = result.searchData.sponsorCode;
        this.searchModel.dateFrom = Models.localDateToNgbDate(result.searchData.dateFrom);
        this.searchModel.dateTo = Models.localDateToNgbDate(result.searchData.dateTo);
        this.updateBranchDropdownSettings();
        completion();
      });
  }

  onFirstSearchOpen(): void {
    // fields are loaded in ngOnInit
  }

  loadList(): void {
  }

  onSearchClicked() {
    if (!this.validate()) {
      return;
    }
    this.didSearch = true;
    this.onSearch.emit();
    this.saveSearch();
  }

  validate(): boolean {
    return this.fForm.valid! && !this.hasLocalFieldError();
  }

  private loadLocalFieldValidationErrors() {
    const validatedInputs = List.of(
      this.userInput,
      this.branchCodeInput,
      this.sponsorCodeInput,
      this.dateFromInput,
      this.dateToInput
    );
    this.validatedInputs = LocalFieldValidationErrorsFactory.ofFormFields(this.fForm, validatedInputs);
  }

  hasLocalFieldError(field?: NgModel): boolean {
    return this.validatedInputs.hasLocalError(field);
  }

  onSearchReset() {
    this.colonnadeClientDocumentStatResourceService.resetSearchData({
      scope: this.searchScope
    }).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.didSearch = false;
        });
      }
    );
  }

  private saveSearch() {
    const searchData = {
      itemsPerPage: Number.MAX_SAFE_INTEGER,
      pageNumber: 1,
      user: this.searchModel.user,
      branchCode: this.searchModel.branchCode,
      sponsorCode: this.searchModel.sponsorCode,
      dateFrom: Models.ngbDateToLocalDate(this.searchModel.dateFrom),
      dateTo: Models.ngbDateToLocalDate(this.searchModel.dateTo),
    };
    this.colonnadeClientDocumentStatResourceService.setSearchData({scope: this.searchScope, searchData: searchData}).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  onUserSearch(predicate?: string) {
    this.userMultiselectProvider.loadAll(predicate).subscribe((items) => {
      this.users = items;
    });
  }

  onSponsorSearch(predicate?: string) {
    if (!predicate) {
      this.sponsorsForSearch = this.sponsors
        .slice(0, 30)
        .map(s => s.toMultiselectOptionItem());
    }
    else {
      this.sponsorsForSearch = this.sponsors
        .filter(f => f.value_hu.toLowerCase().indexOf(predicate.toLowerCase()) !== -1)
        .slice(0, 30)
        .map(s => s.toMultiselectOptionItem());
    }
  }

  onBranchSearch(predicate?: string) {
    if (this.searchModel.sponsorCode.length > 0) {
      if (!predicate) {
        this.branchesForSearch = this.branches
          .filter(f => f.sponsor_code === this.searchModel.sponsorCode[0].id)
          .slice(0, 30)
          .map(s => s.toMultiselectOptionItem());
      }
      else {
        this.branchesForSearch = this.branches
          .filter(f => f.sponsor_code === this.searchModel.sponsorCode[0].id)
          .filter(f => f.value.toLowerCase().indexOf(predicate.toLowerCase()) !== -1)
          .slice(0, 30)
          .map(s => s.toMultiselectOptionItem());
      }
    }
  }

  private loadSponsors() {
    this.tableDocumentContentService.getTableDocumentContent({
      schemaCode: 'imageDictionary',
      documentCode: 'sponsors',
      version: 1
    }).subscribe(result => {
      this.sponsors = [];
      for (const item of result) {
        const sponsor = new Sponsor();
        sponsor.code = item.code;
        sponsor.value_hu = item.value_hu;
        sponsor.image_type = item.image_type;
        this.sponsors.push(sponsor);
      }
      this.onSponsorSearch();
    });
  }

  private loadBranches() {
    this.tableDocumentContentService.getTableDocumentContent({
      schemaCode: 'branchDictionary',
      documentCode: 'branches',
      version: 1
    }).subscribe(result => {
      this.branches = [];
      for (const item of result) {
        const branch = new Branch();
        branch.code = item.code;
        branch.sponsor_code = item.sponsor_code;
        branch.sponsor = item.sponsor;
        branch.value = item.value;
        this.branches.push(branch);
      }
    });
  }

  onSponsorChanged() {
    this.searchModel.branchCode = [];
    this.onBranchSearch();
    this.updateBranchDropdownSettings();
  }

  getSearchRequest(): ColonnadeClientDocumentStat.ColonnadeB2BStatRequest {
    const hasReadAllRight = this.rightModel.colonnadeB2bReadAll.hasRight();
    const selectedUserId = this.searchModel.user.length > 0 ? Set.of(...this.searchModel.user.map(u => u.id)) : undefined;
    const userId = hasReadAllRight ? selectedUserId : Set.of(this.currentUserId!);
    return {
      userId: userId,
      sponsorCode: this.searchModel.sponsorCode.length > 0 ? Set.of(...this.searchModel.sponsorCode.map(u => u.id)) : undefined,
      branchCode: this.searchModel.branchCode.length > 0 ? Set.of(...this.searchModel.branchCode.map(u => u.id)) : undefined,
      fromTime: Models.parseDateTimeFrom(this.searchModel.dateFrom).startOfDay(),
      toTime: Models.parseDateTimeFrom(this.searchModel.dateTo).endOfDay(),
    };
  }
}

export class ColonnadeClientDocumentStatSearchModel extends SearchModel {
  user: MultiselectOptionItem<number>[] = [];
  branchCode: MultiselectOptionItem<string>[] = [];
  sponsorCode: MultiselectOptionItem<string>[] = [];
  dateFrom: NgbDateStruct | null = Models.offsetDateTimeToNgbDate(Dates.now().minusDays(1));
  dateTo: NgbDateStruct | null = Models.offsetDateTimeToNgbDate(Dates.now().minusDays(1));

  public isEmpty(): boolean {
    return this.user.length === 0
      && this.branchCode.length === 0
      && this.sponsorCode.length === 0
      && this.dateFrom === null
      && this.dateTo === null
      ;
  }

  public clear() {
    this.user = [];
    this.branchCode = [];
    this.sponsorCode = [];
    this.dateFrom = Models.offsetDateTimeToNgbDate(Dates.now().minusDays(1));
    this.dateTo = Models.offsetDateTimeToNgbDate(Dates.now().minusDays(1));
  }

}

export enum ColonnadeClientDocumentStatSearchSearchScope {
  ENROLLER_REPORT,
  PRODUCT_REPORT,
  ENROLLER_SUMMARY
}

export class Sponsor {
  image_type: string;
  value_hu: string;
  code: string;

  toMultiselectOptionItem(): MultiselectOptionItem<string> {
    const item = new MultiselectOptionItem<string>();
    item.id = this.code;
    item.itemName = this.value_hu;
    return item;
  }
}

export class Branch {
  code: string;
  sponsor: string;
  sponsor_code: string;
  value: string;

  toMultiselectOptionItem(): MultiselectOptionItem<string> {
    const item = new MultiselectOptionItem<string>();
    item.id = this.code;
    item.itemName = this.value;
    return item;
  }
}
