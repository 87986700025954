import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SignaturePadComponent } from '../signature-pad/signature-pad.component';

@Component({
  selector: 'app-signature-pad-dialog',
  templateUrl: './signature-pad-dialog.component.html',
  styleUrls: ['./signature-pad-dialog.component.scss']
})
export class SignaturePadDialogComponent implements OnInit, AfterViewInit {

  @ViewChild(SignaturePadComponent) signaturePad: SignaturePadComponent;

  signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    'canvasWidth': 300,
    'canvasHeight': 150
  };

  constructor(public dialogRef: MatDialogRef<SignaturePadDialogComponent>) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
  }

  private savePad() {
    const base64Data = this.signaturePad.toDataURL('image/png');
    return base64Data;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  clearImage() {
    this.signaturePad.clear();
  }

  saveImage() {
    this.imageSaved(this.savePad());
  }

  private imageSaved(image: string) {
    this.dialogRef.close({image: image});
  }

}

export interface SignaturePadDialogResult {
  image: string;
}
