import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UiConstants } from '../../../util/core-utils';
import { Workflow, WorkflowService } from '../../../lib/workflow/workflow.service';
import { IdentityMessage } from '../../../lib/util/messages';
import { StateName } from '../../../app.state-names';
import { UIRouter } from '@uirouter/core';
import { FieldValidationError } from '../../../lib/util/services';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-workflow-clone-dialog',
  templateUrl: './workflow-clone-dialog.component.html',
  styleUrls: ['./workflow-clone-dialog.component.scss']
})
export class WorkflowCloneDialogComponent implements OnInit {

  UiConstants = UiConstants;
  Workflow = Workflow;

  @ViewChild('f', { static: true })
  form: NgForm;

  private fieldErrors: FieldValidationError<Workflow.ValidatedField> =
    FieldValidationError.empty<Workflow.ValidatedField>();

  constructor(public dialogRef: MatDialogRef<WorkflowCloneDialogComponent>,
              private workflowService: WorkflowService,
              private uiRouter: UIRouter,
              @Inject(MAT_DIALOG_DATA) public data: Workflow.CloneRequest) {
  }

  ngOnInit() {
  }

  save() {
    this.workflowService.clone(this.data)
      .subscribe((result: IdentityMessage) => {
        this.closeDialog();
        this.uiRouter.stateService.go(StateName.WORKFLOW_EDIT, {
          id: result.id,
          fromDetail: true
        });
      }, (error: any) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withForm(this.form);
        }
      });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  hasFieldError(field: Workflow.ValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: Workflow.ValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: Workflow.ValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

}
