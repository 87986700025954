<!-- Right sidebar -->
<div class="sidebar-right" clickOutside (clickOutside)="closeRightSidebar()" [ngStyle]="{ 'margin-right': rightSidebarClosed ? '-450px' : '0px' }">
  <div class="h-100 mw-100 d-flex flex-column">

    <!-- NOTIFICATION LIST -->
    <app-sidebar-notification-list
      #notification_list>
    </app-sidebar-notification-list>

    <!-- APPLION OR BRAND LOGO-->
    <div class="mt-auto mw-100">
      <div class="navbar-brand-right" *ngIf="!brandLogoSrc"></div>
      <div class="navbar-asset-brand w-100" *ngIf="brandLogoSrc">
        <img src="{{brandLogoSrc}}" class="w-100"/>
      </div>
    </div>

  </div>
</div>
<!-- Right sidebar end -->
