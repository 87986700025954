/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { ResourceQueryResult } from '../util/services';
import { DownloadedFile } from '../util/downloaded-files';
import { UrlBuilder } from '../../util/url-builder';
import { map } from 'rxjs/operators';
import { EmptyMessage } from '../util/messages';

/* eslint-enable */

@Injectable()
export class WorklogResourceService extends BaseHttpService {

  query(request: WorklogResource.QueryRequest): Observable<ResourceQueryResult<WorklogResource.Worklog>> {
    return this.http.get<ResourceQueryResult<WorklogResource.Worklog>>(this.url, this.parseParams(request));
  }

  update(request: WorklogResource.UpdateRequest): Observable<EmptyMessage> {
    return this.http.put<EmptyMessage>(this.url + `${request.id}`, request);
  }

  exportXls(request: WorklogResource.QueryRequest): Observable<DownloadedFile> {
    const url = UrlBuilder.create('worklogs/export-xls')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob',
      params: this.parseParams(request).params
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  exportMaconomy(request: WorklogResource.QueryRequest): Observable<DownloadedFile> {
    const url = UrlBuilder.create('worklogs/export-maconomy')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob',
      params: this.parseParams(request).params
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }


  constructor(private http: HttpClient, private resourceHelper: ResourceHelper) {
    super(resourceHelper, '/worklogs/');
  }

}

@Injectable()
export class ManualWorklogResourceService extends BaseHttpService {

  create(request: WorklogResource.CreateRequest): Observable<EmptyMessage> {
    return this.http.post<EmptyMessage>(this.url + `${request.task_id}/records/${request.task_record_id}/manual-worklog`, request);
  }

  delete(request: WorklogResource.DeleteRequest): Observable<EmptyMessage> {
    return this.http.delete<EmptyMessage>(this.url + `${request.task_id}/records/${request.task_record_id}/manual-worklog/${request.id}`);
  }

  constructor(private http: HttpClient, private resourceHelper: ResourceHelper) {
    super(resourceHelper, '/tasks/');
  }

}

export namespace WorklogResource {

  export interface Worklog {
    id: number;
    creation_time: string;
    update_time: string;
    start_time: string;
    end_time: string;
    logged_time_in_minutes?: number;
    estimated_time_in_minutes?: number;
    worklog_user_type: string;
    logger_user: {
      id: number;
      person_name: string;
      user_name: string;
    };
    worker_user: {
      id: number;
      person_name: string;
      user_name: string;
      user_group_ids: number[];
      profile_picture_hash?: string;
    };
    task_record: {
      id: number;
      task_id: number;
      name: string;
    };
    overtime?: boolean;
  }

  export interface QueryRequest {
    worklog_scope?: string;
    user_id?: number;
    task_record_id?: number;
    fields?: string;
    filter?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
  }

  export interface UpdateRequest {
    id: number;
    start_time: string;
    end_time: string;
    logged_time_in_minutes?: number;
    overtime: boolean;
  }

  export interface CreateRequest {
    task_id: number;
    task_record_id: number;
    user_ids: number[];
    start_time: string;
    end_time: string;
    logged_time_in_minutes?: number;
    overtime: boolean;
  }
  export interface DeleteRequest {
    id: number;
    task_id: number;
    task_record_id: number;
  }

}
