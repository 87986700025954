<div class="container-vertical-padding container-horizontal-padding-half">
  <div class="col-md-12 d-flex justify-content-between mb-1 half-padding" *ngIf="isRemovable">
    <h6>{{'FORM_RECORD_STOCK_MODIFY_ITEM' | translate}}</h6>
    <input type="button"
           (click)="remove.emit()" class="btn btn-secondary"
           value="{{'FORM_RECORD_STOCK_DELETE_ITEM' | translate}}">
  </div>
  <div class="col-md-12 mb-1 half-padding" *ngIf="!isRemovable">
    <h6>{{'FORM_RECORD_STOCK_ADD_ITEM' | translate}}</h6>
  </div>
  <form *ngIf="!disabledItem" class="form-horizontal d-flex flex-wrap" (ngSubmit)="saveRecord()" [formGroup]="formGroup" #f="ngForm" novalidate>
    <div class="col-md-12 p-0">
      <!-- /.row -->
      <div class="row m-0 d-flex justify-content-end">

        <div class="col-md-6 col-sm-12 col-xs-12 form-group half-padding hidden-md-up"
             *ngIf="!this.dispersionPercentEnabled"
             [ngClass]="{ 'has-danger': (formGroup.controls['amount'].touched && !formGroup.controls['amount'].valid) || !validAmount }">
          <label class="form-control-label">{{'FORM_RECORD_STOCK_AMOUNT' | translate}}</label>
          <input class="form-control" type="number" placeholder="{{'FORM_RECORD_STOCK_AMOUNT' | translate}}"
                 [(ngModel)]="amount" (blur)="notifyStockRecordAmountChange()"
                 formControlName="amount" name="amountInput" required>
          <div class="form-control-feedback" *ngIf="formGroup.controls['amount'].touched && formGroup.controls['amount'].hasError('required')">
            {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
          </div>
          <div class="form-control-feedback" *ngIf="!validAmount">
            {{'FORM_RECORD_STOCK_INSUFFICIENT_IN_STOCK_AMOUNT' | translate}}
          </div>
          <div class="form-control-feedback" *ngIf="formGroup.controls['amount'].touched && formGroup.controls['amount'].hasError('numberNotPositive')">
            {{'COMMON_VALIDATION_MESSAGE_NUMBER_NOT_POSITIVE' | translate}}
          </div>
        </div>
        <div class="col-md-6 col-sm-12 col-xs-12 form-group half-padding hidden-md-up"
             *ngIf="!this.dispersionPercentEnabled"
             [ngClass]="{ 'has-danger': (formGroup.controls['unitOfMeasure'].touched && !formGroup.controls['unitOfMeasure'].valid) || !validAmount }">
          <label class="form-control-label">{{'FORM_RECORD_STOCK_UNIT' | translate}}</label>
          <angular2-multiselect
            class="form-control ml-05"
            [(ngModel)]="unitOfMeasure"
            [data]="model.stockRecord.stockItem.measurements"
            [settings]="dropdownSettings"
            formControlName="unitOfMeasure"
            (onDeSelect)="notifyStockRecordAmountChange()"
            (onSelect)="notifyStockRecordAmountChange()"
            [ngClass]="{ 'form-control-danger': (formGroup.controls['unitOfMeasure'].touched && !formGroup.controls['unitOfMeasure'].valid) || !validAmount }">
          </angular2-multiselect>
          <div class="form-control-feedback" *ngIf="formGroup.controls['unitOfMeasure'].touched && formGroup.controls['unitOfMeasure'].hasError('required')">
            {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
          </div>
        </div>

        <div class="col-md-6 col-sm-12 col-xs-12 form-group half-padding hidden-md-up"
             *ngIf="this.dispersionPercentEnabled"
             [ngClass]="{ 'has-danger': (formGroup.controls['dispersionPercent'].touched && !formGroup.controls['dispersionPercent'].valid) || !validPercentage || !this.model.validDispersionPercent }">
          <label class="form-control-label">{{'FORM_RECORD_STOCK_DISPERSION_PERCENT' | translate}}</label>
          <input class="form-control" type="number" placeholder="%"
                 [(ngModel)]="model.dispersionPercent" (blur)="validatePercentage()" formControlName="dispersionPercent" name="dispersionPercentInput" required>
          <div class="form-control-feedback" *ngIf="formGroup.controls['dispersionPercent'].touched && formGroup.controls['dispersionPercent'].hasError('required')">
            {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
          </div>
          <div class="form-control-feedback" *ngIf="!validPercentage">
            {{'FORM_RECORD_STOCK_INVALID_PERCENTAGE' | translate}}
          </div>
          <div class="form-control-feedback" *ngIf="!this.model.validDispersionPercent">
            {{'FORM_RECORD_STOCK_DISPERSION_PERCENT_ERROR' | translate}}
          </div>
          <div class="form-control-feedback" *ngIf="formGroup.controls['dispersionPercent'].touched && formGroup.controls['dispersionPercent'].hasError('numberNotPositive')">
            {{'COMMON_VALIDATION_MESSAGE_NUMBER_NOT_POSITIVE' | translate}}
          </div>
        </div>
      </div>
      <!-- /.row -->
      <div class="row m-0 d-flex justify-content-end">
        <div class="col-md-4 col-sm-12 col-xs-12 form-group half-padding hidden-lg-up" *ngIf="!this.dispersionPercentEnabled">
          <label class="form-control-label">{{'FORM_RECORD_STOCK_IN_STOCK_AMOUNT' | translate}}</label>
          <br>
          <label class="form-control-label" *ngIf="amountInStock">{{amountInStock}} {{unit}}</label>
        </div>
        <div class="col-md-4 col-sm-12 col-xs-12 form-group half-padding hidden-lg-up" *ngIf="discount === null && !this.dispersionPercentEnabled">
          <label class="form-control-label">{{'FORM_RECORD_STOCK_REDUCED_NET_PRICE' | translate}}</label>
          <input class="form-control" type="number" placeholder="{{'FORM_RECORD_STOCK_REDUCED_NET_PRICE' | translate}}"
                 [(ngModel)]="netReducedPrice" (ngModelChange)="setGrossPrice()"
                 [ngModelOptions]="{standalone: true}" name="netPrice">
        </div>
        <div class="col-md-4 col-sm-12 col-xs-12 form-group half-padding hidden-lg-up" *ngIf="discount === null && !this.dispersionPercentEnabled">
          <label class="form-control-label">{{'FORM_RECORD_STOCK_REDUCED_GROSS_PRICE' | translate}}</label>
          <input class="form-control" type="number" placeholder="{{'FORM_RECORD_STOCK_REDUCED_GROSS_PRICE' | translate}}"
                 [(ngModel)]="grossReducedPrice"  (ngModelChange)="setNetPrice()"
                 [ngModelOptions]="{standalone: true}" name="grossPrice">
        </div>
      </div>
      <!-- /.row -->
    </div>
    <!-- /.col-md-12 -->
    <div class="col-md-12 half-padding">
      <div class="pull-right">
        <input type="button" class="btn btn-secondary-green cursor-pointer mr-1" (click)="cancel.emit()"
               value="{{'COMMON_BUTTON_CANCEL'|translate}}"/>
        <input type="submit" class="btn btn-primary-green cursor-pointer" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
      </div>
    </div>
  </form>
</div>
