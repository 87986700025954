/* eslint-disable */
import { Observable, Observer } from 'rxjs';
import { Order, OrderType } from '../util/services';
import { UiConstants } from '../../util/core-utils';
import { MemStorage } from '../util/mem-storage';
import { environment } from '../../../environments/environment';
import { CompanyLocation } from './company-location.service';
import { DisabledItem } from '../../util/search-utils';
import { RuntimeConfiguration } from '../runtime-configuration';
import { LoggedInUserStorage } from '../util/storages';
import { Injectable } from "@angular/core";
/* eslint-enable */

@Injectable()
export class CompanyLocationSearchService {

  constructor() {}

  getSearchData(request: CompanyLocationSearch.SearchDataGetRequest): Observable<CompanyLocationSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<CompanyLocationSearch.SearchDataResult>) => {
      try {
        observer.next(storage.read(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  setSearchData(request: CompanyLocationSearch.SearchDataSetRequest): Observable<CompanyLocationSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<CompanyLocationSearch.SearchDataResult>) => {
      try {
        observer.next(storage.save(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  resetSearchData(request: CompanyLocationSearch.SearchDataGetRequest): Observable<CompanyLocationSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<CompanyLocationSearch.SearchDataResult>) => {
      try {
        observer.next(storage.reset(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  private createStorage(): CompanyLocationSearch.Storage {
    return new CompanyLocationSearch.Storage();
  }
}

export namespace CompanyLocationSearch {

  export interface SearchDataGetRequest {
  }

  export interface SearchDataSetRequest {
    searchData: SearchData;
  }

  export interface SearchDataResult {
    searchData: SearchData;
  }

  export interface SearchData {
    itemsPerPage: number;
    pageNumber: number;
    order: Order<CompanyLocation.OrderField>;
    id: string;
    externalId: string;
    name: string;
    companyIds: CompanyItem[];
    disabled?: DisabledItem;
  }

  interface StoredSearchRawDataBase {
    version: number;
  }

  interface StoredSearchRawData extends StoredSearchRawDataBase {
    itemsPerPage: number;
    pageNumber: number;
    order: Order<CompanyLocation.OrderField>;
    id: string;
    externalId: string;
    name: string;
    companyIds: CompanyItem[];
    disabled?: DisabledItem;
  }

  export class Storage {

    /**
     * Increment this version if you change the SearchData interface.
     */
    private static readonly VERSION: number = 2;

    private static readonly DEFAULT_SEARCH_DATA: SearchData = {
      itemsPerPage: UiConstants.pageNumbers[0],
      pageNumber: 1,
      order: {
        field: CompanyLocation.OrderField.ID,
        type: OrderType.DESC
      },
      id: '',
      externalId: '',
      name: '',
      companyIds: [],
      disabled: DisabledItem.active(),
    };

    constructor() {
    }

    public read(request: SearchDataGetRequest): SearchDataResult {
      let rawBase: StoredSearchRawDataBase;
      try {
        const key = this.generateKey();
        const json = MemStorage.getItem(key);
        if (!json) {
          return this.createDefaultResult();
        }
        rawBase = JSON.parse(json);
      }
      catch (error) {
        return this.createDefaultResult();
      }
      if (Storage.VERSION === rawBase.version) {
        const raw: StoredSearchRawData = <StoredSearchRawData> rawBase;
        return this.fromRaw(raw);
      }
      return this.createDefaultResult();
    }


    public reset(request: SearchDataGetRequest): SearchDataResult {
      return this.save({
        searchData: Storage.DEFAULT_SEARCH_DATA,
      });
    }


    public save(request: SearchDataSetRequest): SearchDataResult {
      const key = this.generateKey();
      const raw = this.toRaw(request.searchData);
      const json = JSON.stringify(raw);
      MemStorage.setItem(key, json);
      return this.read(request);
    }

    private generateKey(): string {
      return 'UserId(' + LoggedInUserStorage.getInstance().getUserId() + ')-Search-CompanyLocation';
    }

    private createDefaultResult(): SearchDataResult {
      return {
        searchData: Storage.DEFAULT_SEARCH_DATA,
      };
    }


    private fromRaw(data: StoredSearchRawData): SearchDataResult {
      return {
        searchData: {
          itemsPerPage: data.itemsPerPage,
          pageNumber: data.pageNumber,
          order: data.order,
          id: data.id,
          externalId: data.externalId,
          name: data.name,
          companyIds: data.companyIds,
          disabled: data.disabled,

        }
      };
    }

    private toRaw(data: SearchData): StoredSearchRawData {
      return {
        version: Storage.VERSION,
        itemsPerPage: data.itemsPerPage,
        pageNumber: data.pageNumber,
        order: data.order,
        id: data.id,
        externalId: data.externalId,
        name: data.name,
        companyIds: data.companyIds,
        disabled: data.disabled,

      };
    }
  }

  export class CompanyItem {
    id: number;
    name: string;
  }
}
