/* eslint-disable */
import { FormEditFieldCreateDialogView, FormEditFieldDialogViewFactory, FormEditFieldUpdateDialogView } from './form-utils';
import { ComponentFactoryResolver, ComponentRef, Injector } from '@angular/core';
import { FormEditCreateUpdatePhotoFieldDialogComponent } from '../../admin/form/form-edit/dialogs/fields/photo/create-update/form-edit-create-update-photo-field-dialog.component';
/* eslint-enable */

export class FormEditPhotoFieldDialogViewFactory implements FormEditFieldDialogViewFactory {

  constructor(
    private injector: Injector,
    private cfr: ComponentFactoryResolver) {
  }

  createCreateView(): ComponentRef<FormEditFieldCreateDialogView> {
    return this.createView();
  }

  createUpdateView(): ComponentRef<FormEditFieldUpdateDialogView> {
    return this.createView();
  }

  private createView(): ComponentRef<FormEditCreateUpdatePhotoFieldDialogComponent> {
    return this.cfr.resolveComponentFactory(FormEditCreateUpdatePhotoFieldDialogComponent)
      .create(this.injector);
  }

}
