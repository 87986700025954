import { DqlQuery, Query } from '../field';
import { DeliveryMethod as DM } from '../../delivery-method/delivery-method.service';
import { LazyReference } from '../../../util/lazy';
import { Company } from './company';

export class DeliveryMethod {

  readonly id: Query.NumberField;
  readonly disabled: Query.BooleanField;
  readonly creationTime: Query.DateTimeField;
  readonly type: Query.EnumField<DM.DeliveryMethodType>;
  readonly integrationType: Query.EnumField<DM.DeliveryMethodIntegrationType>;
  readonly externalId: Query.StringField;
  readonly name: Query.StringField;

  private readonly transporterCompanyRef: LazyReference<Company>;

  get transporterCompany(): Company {
    return this.transporterCompanyRef.get();
  }

  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this.id = new DqlQuery.NumberField(prefix + 'id');
    this.disabled = new DqlQuery.BooleanField(prefix + 'disabled');
    this.creationTime = new DqlQuery.DateTimeField(prefix + 'creation_time');
    this.type = new DqlQuery.EnumField<DM.DeliveryMethodType>(prefix + 'type', (type) => type);
    this.integrationType = new DqlQuery.EnumField<DM.DeliveryMethodIntegrationType>(prefix + 'integration_type', (type) => type);
    this.externalId = new DqlQuery.StringField(prefix + 'external_id');
    this.name = new DqlQuery.StringField(prefix + 'name');
    this.transporterCompanyRef = LazyReference.of(
      () => new Company(prefix + 'transporter_company.')
    );
  }

}
