import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrderDocument, OrderDocumentService } from '../../../lib/order-document/order-document.service';
import {
  OrderFieldFunction,
  OrderFieldModel
} from '../../../util/core-utils';
import { OrderField } from '../../../lib/query/orderfields';
import {
  OrderType,
  QueryResult
} from '../../../lib/util/services';
import { Dates, OffsetDateTime } from '../../../lib/util/dates';
import { RightModel } from '../../../app.rights';
import { RightResolver, RightService } from '../../../lib/right.service';
import { FilterField } from '../../../lib/query/filterfields';
import { CriteriaBuilder } from '../../../util/model-utils';
import { DownloadedFile } from '../../../lib/util/downloaded-files';
import { saveAs } from 'file-saver';
import { EmptyMessage } from '../../../lib/util/messages';

@Component({
  selector: 'app-order-document-list',
  templateUrl: './order-document-list.component.html',
  styleUrls: ['./order-document-list.component.scss']
})
export class OrderDocumentListComponent implements OnInit {
  OrderFunctions = OrderFunctions;

  queryModel: OrderFieldModel<OrderField.OrderDocument> = new OrderFieldModel(OrderFunctions.CREATION_TIME, OrderType.DESC);

  @Input()
  orderId: number;

  @Output()
  onOrderCreateButtonClicked: EventEmitter<number> = new EventEmitter<number>();

  rightModel: RightModel = RightModel.empty();

  orderDocumentList: OrderDocumentModel[] = [];

  readonly pagingId = 'orderDocumentPaging';

  constructor(private orderDocumentService: OrderDocumentService,
              private rightService: RightService) { }

  ngOnInit() {
    this.loadRightModels(() => {
      if (this.rightModel.orderDocumentRead.hasRight()) {
        this.loadList(1);
      }
    });
  }

  private loadRightModels(completion: () => void) {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        completion();
      }
    );
  }

  loadList(requestedPage?: number) {
    const order = this.queryModel.createOrderFunction();
    const filter = this.createFilter();
    this.orderDocumentService.query({
      fields: f => f.each,
      order: order,
      filter: filter,
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined,
    }).subscribe((result: QueryResult<OrderDocument.OrderDocument>) => {
      this.orderDocumentList = [];
      result.items.forEach((orderDocument: OrderDocument.OrderDocument) => {
        const orderDocumentModel = new OrderDocumentModel();
        orderDocumentModel.id = orderDocument.id;
        orderDocumentModel.type = orderDocument.meta.type ? orderDocument.meta.type : '';
        orderDocumentModel.originalFileName = orderDocument.originalFileName ? orderDocument.originalFileName : '';
        orderDocumentModel.creationTime = orderDocument.creationTime;
        orderDocumentModel.comment = orderDocument.meta.comment ? orderDocument.meta.comment : '';
        orderDocumentModel.uploadedByUser = !!orderDocument.uploaderUserId;
        this.orderDocumentList.push(orderDocumentModel);
      });
    });
  }

  downloadFile(orderDocument: OrderDocument.OrderDocument) {
    this.orderDocumentService.download({id: orderDocument.id}).subscribe((res: DownloadedFile) => {
      saveAs(res.getBlob(), res.getFileName(orderDocument.originalFileName));
    });
  }

  deleteFile(orderDocumentId: string) {
    this.orderDocumentService.delete({id: orderDocumentId}).subscribe((res: EmptyMessage) => {
      this.loadList(1);
    });
  }

  // Order document list is global, must be filtered for current order id
  private createFilter() {
    return (f: FilterField.OrderDocument) => CriteriaBuilder.builder()
      .addNumber((orderId) => f.order.id.eq(orderId), this.orderId)
      .build();
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: OrderFieldFunction<OrderField.OrderDocument>) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

}

export class OrderDocumentModel {
  id: string = '';
  type: string = '';
  originalFileName: string = '';
  creationTime: OffsetDateTime = Dates.emptyOffsetDateTime();
  comment: string = '';
  uploadedByUser: boolean = false;
}

export namespace OrderFunctions {
  export const SIZE = (f: OrderField.OrderDocument) => f.contentSize;
  export const CONTENT_TYPE = (f: OrderField.OrderDocument) => f.contentType;
  export const CREATION_TIME = (f: OrderField.OrderDocument) => f.creationTime;
  export const ORIGINAL_FILE_NAME = (f: OrderField.OrderDocument) => f.originalFileName;
  export const TYPE = (f: OrderField.OrderDocument) => f.meta.type;
  export const UPDATE_TIME = (f: OrderField.OrderDocument) => f.meta.updateTime;
}
