/* eslint-disable */
import { MultiselectOptionItem, OptionItem } from './core-utils';
import { ListItemParam, ListItemParamType, ListItemTypeParam } from '../lib/list-item/list-item-resource.service';
import { DisabledItem } from './search-utils';

/* eslint-enable */

export class ListItemTypeEditModel {
  readonly selectableOrderFields: MultiselectOptionItem<'ID' | 'TEXT' | 'CODE'> [] = [
    {id: 'ID', itemName: 'COMMON_TABLE_HEADER_ID'},
    {id: 'TEXT', itemName: 'LISTITEM_TABLE_HEADER_TEXT'},
    {id: 'CODE', itemName: 'LISTITEM_TABLE_HEADER_CODE'},
  ];
  readonly  selectableOrderTypes: MultiselectOptionItem<'ASC' | 'DESC'> [] = [
    {id: 'ASC', itemName: 'ORDER_TYPE_ASC'},
    {id: 'DESC', itemName: 'ORDER_TYPE_DESC'},
  ];


  key: string = '';
  params: ListItemTypeParamEditModel[] = [];
  filterType: MultiselectOptionItem<number>[] = [];

  _orderField: MultiselectOptionItem<'ID' | 'TEXT' | 'CODE'> [] = this.selectableOrderFields.filter(i => i.id === 'TEXT');
  _orderType: MultiselectOptionItem<'ASC' | 'DESC'> [] = this.selectableOrderTypes.filter(i => i.id === 'ASC');

  get filterTypeId(): number | undefined {
    return this.filterType.length === 1 ? this.filterType[0].id : undefined;
  }

  get orderField(): string | undefined {
    return this._orderField.length < 1 ? undefined : this._orderField[0].id;
  }
  get orderType(): string | undefined {
    return this._orderType.length < 1 ? undefined : this._orderType[0].id;
  }
}

export class ListItemTypeParamEditModel {
  key: string;
  paramType: ListItemParamType;
  defaultValue?: string;
}

export class ListItemParamTypeItem extends OptionItem<ListItemParamType> {
}

export class ListItemTypeSearchModel {
  key: string | null;
  disabled?: DisabledItem;

  public isEmpty(): boolean {
    return this.key === null
      && OptionItem.idOrUndefined(this.disabled) === undefined
      ;
  }
}

export class ListItemEditModel {
  _type: ListItemTypeItem[] = [];
  text: string;
  params: ListItemParam[] = [];
  code: string = '';
  filterItems: MultiselectOptionItem<number>[] = [];

  get type(): ListItemTypeItem | undefined {
    return this._type.length > 0 ? this._type[0] : undefined;
  }

  set type(t: ListItemTypeItem | undefined) {
    this._type = [];
    if (t) {
      this._type.push(t);
    }
  }

  get filterItemIds(): number[] {
    return this.filterItems.length > 0 ? this.filterItems.map(i => i.id) : [];
  }
}

export class ListItemSearchModel {
  type: MultiselectOptionItem<number>[] = [];
  text: string;
  disabled?: DisabledItem;

  public isEmpty(): boolean {
    return this.text.length === 0
      && this.type.length === 0
      && OptionItem.idOrUndefined(this.disabled) === undefined
      ;
  }
}

export class ListItemCreateModel extends ListItemEditModel {
}

export class ListItemTypeItem extends OptionItem<string> {
  params: ListItemTypeParam[];
  filterTypeId?: number;
  filterTypeKey?: string;
}

export class ListItemTypeIdItem extends OptionItem<number> {
}

export class ListItemTypeCreateModel extends ListItemTypeEditModel {

}
