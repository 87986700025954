/* eslint-disable */
import {empty as observableEmpty, Observable} from 'rxjs';
import {defaultIfEmpty} from 'rxjs/operators';
import {Component, Injector, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {List, Set} from 'immutable';
import {Transition, TransitionOptions, UIRouter} from '@uirouter/angular';
import {
  MultiselectOptionItem,
  OptionItem,
  OwnerUserItemFactory,
  QueryFieldModel,
  SelectUtils,
  UiConstants,
} from '../../../../util/core-utils';
import {TaskRecord, TaskRecordService} from '../../../../lib/task/task-record.service';
import {Task, TaskService} from '../../../../lib/task/task.service';
import {Order, OrderType, QueryResult, ResourceQueryResult, Services} from '../../../../lib/util/services';
import {RightModel} from '../../../../app.rights';
import {GrantedPermissionSetResolver, RightResolver, RightService} from '../../../../lib/right.service';
import {FileUploadDialogComponent} from '../../../../shared/file-upload/dialog/file-upload-dialog.component';
import {saveAs} from 'file-saver';
import {
  AssigneeDeviceItem,
  AssigneeItem,
  AssigneeListModel,
  AssigneeModificationModel,
  BukUpdateType,
  ConfigModel,
  DeadlineModificationModel,
  TaskRecordExportStateItem,
  TaskRecordImportanceItem,
  TaskRecordListModel,
  TaskRecordRightModel,
  TaskRecordSearchModel,
  TaskRecordStateItem
} from '../../../../util/task-record-utils';
import {Strings} from '../../../../lib/util/strings';
import {Models} from '../../../../util/model-utils';
import {ConfigurationResource, ConfigurationService} from '../../../../lib/core-ext/configuration.service';
import {UserGroup, UserGroupService} from '../../../../lib/user-group.service';
import {Device, DeviceManagementService} from '../../../../lib/device-management.service';
import {StringKey} from '../../../../app.string-keys';
import {UserData, UserDataLoader, UserDataLoaderPermissionDeniedStrategy} from '../../../../lib/user-data-loader';
import {ToasterService} from '../../../../fork/angular2-toaster/angular2-toaster';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {TabsetComponent} from 'ngx-bootstrap/tabs';
import {DownloadedFile,} from '../../../../lib/util/downloaded-files';
import {NgbDatePickerParserFormatter} from '../../../../util/ngb-datepicker';
import {CustomerRecordService} from '../../../../lib/customer/customer-record.service';
import {TaskRecordStateMachine} from '../../../../lib/task/task-record-statemachine';
import {Dates,} from '../../../../lib/util/dates';
import {Address} from '../../../../lib/address';
import {DqlSearchContainerComponent} from '../../../dql-search/dql-search-container/dql-search-container.component';
import {TaskRecordDqlFieldTextProvider} from '../../../../lib/task/task-record.dql.service';
import {Angular2Multiselects} from '../../../../util/multiselect';
import {TranslateUtils} from '../../../../util/translate';
import {StateName} from '../../../../app.state-names';
import {BreadcrumbParent} from '../../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import {Icon} from '../../../../lib/task/icon.service';
import {Invoice, InvoiceService} from '../../../../lib/invoice/invoice/invoice.service';
import {User, UserService} from '../../../../lib/user.service';
import {InputMask} from '../../../../util/input-masks';
import {EmptyMessage, IdentityArray} from '../../../../lib/util/messages';
import {DqlStoredQueryArgs} from '../../../dql-search/dql-search-container/dql-stored-query.args';
import {SearchUtils} from '../../../../util/search-utils';
import {BaseHttpService} from '../../../../lib/util/http-services';
import {BadgeStyle} from '../../../../shared/table-badge/badge-style';
import {AssigneeType} from '../../../../shared/assignee-table-cell/assignee-table-cell.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {StockMultiselectProvider} from '../../../../lib/stock/stock-multiselect.provider';
import {ProcessMultiselectProvider} from '../../../../lib/process/process-multiselect.provider';
import {TaskRecordListMapComponent} from './task-record-list-map/task-record-list-map.component';
import {
  DemoModeFeatureDisabledDialogComponent
} from '../../../../shared/demo-mode-feature-disabled-dialog/demo-mode-feature-disabled-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {SiteTourBaseComponent, SiteTourId} from '../../../../lib/site-tour/site-tour.factory';
import {SiteTourService} from '../../../../lib/site-tour/site-tour.service';
import {
  TaskRecordBulkCloneDialogComponent
} from '../task-record-bulk-clone-dialog/task-record-bulk-clone-dialog.component';
import * as printJS from 'print-js';
import {ProjectRecordMultiselectProvider} from '../../../../lib/project/record/project-record-multiselect.provider';
import * as $ from 'jquery/dist/jquery.js';
import {OperationRights} from '../../../../app.right-definitions';
import {TaskRightModel} from '../../../../util/task-utils';
import {UserMeService} from '../../../../lib/user/user-me.service';
import {PaletteColorPickerComponent} from '../../../../shared/palette-color-picker/palette-color-picker.component';
import {
  TaskRecordStateChangeDialogComponent,
  TaskRecordStateChangeDialogResult
} from "../task-record-state-change-dialog/task-record-state-change-dialog.component";
import {
  TaskRecordSupExportDialogComponent
} from "../task-record-sup-export-dialog/task-record-sup-export-dialog.component";
import SearchableList = SearchUtils.SearchableList;
import TaskRecordField = TaskRecord.TaskRecordField;
import StateChangeLabelMap = TaskRecord.StateChangeLabelMap;
import {
  ContractNumberMultiselectProvider
} from "../../../../lib/customer/contract-number/contract-number-multiselect-provider.service";
import {TaskRecordSearch, TaskRecordSearchService} from "../../../../lib/task/task-record-search.service";

/* eslint-enable */

@Component({
  selector: 'app-new-task-record-list',
  templateUrl: 'task-record-list.component.html',
  styleUrls: ['task-record-list.component.scss']
})
export class TaskRecordListComponent extends SearchableList<TaskRecordSearchModel> implements OnInit, OnDestroy, SiteTourBaseComponent {
  TaskRecord = TaskRecord;
  InputMask = InputMask;
  UiConstants = UiConstants;
  SelectUtils = SelectUtils;
  BadgeStyle = BadgeStyle;
  AssigneeType = AssigneeType;
  TaskRecordStateMachine = TaskRecordStateMachine;

  @Input()
  projectRecordId?: number;

  @Input()
  customerRecordId?: number;

  @Input()
  baseCustomerRecordByPhoneNumberId?: number;

  @Input()
  taskRecordId?: number;

  @Input()
  processId?: number;

  @Input()
  masterDataRecordId?: number;

  @Input()
  _headerStringKey?: string;
  private _canCreateTaskRecord: boolean = false;
  currentUser: User;

  get headerStringKey(): string {
    return this._headerStringKey ? this._headerStringKey : 'TASK_RECORD_PANEL_HEADING_LIST';
  }

  isComponentEmbedded: boolean;

  queryModel: QueryFieldModel<TaskRecord.OrderField> = new QueryFieldModel(TaskRecord.OrderField.ID, OrderType.DESC);

  taskRecordList: Array<TaskRecordListModel> = [];
  searchResetClicked = false;
  rightModel: RightModel = RightModel.empty();
  activeSearchTab: string = 'simple';
  readonly _taskId?: number;
  get taskId(): number | undefined {
    return this.taskRecordId || this.baseCustomerRecordByPhoneNumberId ? undefined : this._taskId;
  }

  config: ConfigModel = new ConfigModel();
  taskStates: MultiselectOptionItem<TaskRecordStateMachine.State>[] = [];
  invoices: InvoiceItem[] = [];

  taskImportances: TaskRecordImportanceItem[] = [];
  settableStateList: TaskRecordStateItem[] = [];
  settableStateOpen: TaskRecordStateItem;
  exportStates: TaskRecordExportStateItem[] = [];
  taskName: string;
  task?: Task.Task;
  taskManagedFields: Set<TaskRecord.TaskRecordField> = Set.of();
  breadcrumbParents: BreadcrumbParent[] = [];
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  settableImportanceList: TaskRecordImportanceItem[] = [];
  bulkModifiableTaskRecordIds: Set<number> = Set<number>();
  checkedIds: Set<number> = Set<number>();
  assigneeModificationModel: AssigneeModificationModel = new AssigneeModificationModel();

  deadlineModificationModel: DeadlineModificationModel = new DeadlineModificationModel();
  @ViewChild('importDialog', {static: true}) importDialog: FileUploadDialogComponent;

  @ViewChild('assigneeModificationDialog', {static: true}) assigneeModificationDialog: ModalDirective;
  @ViewChild('deadlineModificationDialog', {static: true}) deadlineModificationDialog: ModalDirective;
  @ViewChild('geocodingDialog', {static: true}) geocodingDialog: ModalDirective;

  @ViewChild('externalExportDialog', {static: true}) externalExportDialog: ModalDirective;
  @ViewChild('createPreselectDialog', {static: true}) createPreselectDialog: ModalDirective;

  @ViewChild('mapComponent') mapComponent: TaskRecordListMapComponent;

  @ViewChild('stockExportDialog', {static: true}) stockExportDialog: ModalDirective;
  stockExportDialogVisible = false;
  stockExportForm: FormGroup;
  stockExportIncludeOtherProducts: boolean = false;
  stockExportSelectedStock: MultiselectOptionItem<number>[] = [];
  stocks: MultiselectOptionItem<number>[] = [];

  @ViewChild('bulkCloneDialog', {static: true}) bulkCloneDialog: TaskRecordBulkCloneDialogComponent;

  assigneeModificationDialogVisible: boolean = false;
  deadlineModificationDialogVisible: boolean = false;
  geocodingDialogVisible: boolean = false;
  externalExportDialogVisible: boolean = false;
  createPreselectDialogVisible: boolean = false;

  uploadPath: string;

  devices: AssigneeDeviceItem[] = [];
  users: AssigneeItem[] = [];
  contractNumbers: MultiselectOptionItem<number>[] = [];
  userGroups: AssigneeItem[] = [];
  relatedUserGroups: MultiselectOptionItem<number>[] = [];

  dropdownSettings: Angular2Multiselects.Settings;
  dropdownSettingsWithRemoteSearch: Angular2Multiselects.Settings;
  dropdownSettingsForMulti: Angular2Multiselects.Settings;
  dropdownSettingsForMultiWithRemoteSearch: Angular2Multiselects.Settings;
  dropdownSettingsForTask: Angular2Multiselects.Settings;

  tasks: Task.Task[] = [];
  tasksForCreate: Task.Task[] = [];
  tasksForSearch: TaskRecordSearch.TaskItem[] = [];
  projects: MultiselectOptionItem<number>[] = [];
  processes: MultiselectOptionItem<number>[] = [];
  dqlStoredArgs: DqlStoredQueryArgs;

  private siteTourOpen2Enabled = false;

  private _searchTabs?: TabsetComponent;

  get maconomyEnabled(): boolean {
    return this.configService.getConfiguration().feature_flags.maconomy_enabled;
  }

  get customXlsxExportEnabled(): boolean {
    return this.configService.getConfiguration().feature_flags.task_record.custom_xlsx_export_enabled;
  }

  get supExportEnabled(): boolean {
    return this.configService.getConfiguration().feature_flags.task_record.sup_export_enabled;
  }

  @ViewChild('searchTabs') set searchTabs(c: TabsetComponent) {
    if (c && !this._searchTabs) {
      this._searchTabs = c;
      if (this.searchModel.dqlText) {
        this.activeSearchTab = 'dql';
        this._searchTabs.tabs[1].active = true;
      } else {
        this.activeSearchTab = 'simple';
        this._searchTabs.tabs[0].active = true;
      }
    }
    if (!c) {
      this._searchTabs = c;
    }
  }

  private dqlSearchContainer?: DqlSearchContainerComponent;

  @ViewChild('dqlSearchContainer') set dqlContainer(c: DqlSearchContainerComponent) {
    if (c) {
      if (!this.dqlSearchContainer) {
        const qt = this.searchModel.dqlText;
        this.dqlSearchContainer = c;
        this.loadDqlModel(() => {
          this.loadDqlSearch(qt);
          this.dqlSearchContainer!.loadContent();
        });
      }
    } else {
      this.dqlSearchContainer = undefined;
    }
  }

  showMap: boolean = false;

  get demoModeEnabled(): boolean {
    return this.configService.getConfiguration().feature_flags.demo_mode_enabled;
  }

  constructor(private transition: Transition,
              private rightService: RightService,
              private taskRecordService: TaskRecordService,
              private taskRecordSearchService: TaskRecordSearchService,
              private taskService: TaskService,
              private invoiceService: InvoiceService,
              private configService: ConfigurationService,
              private customerService: CustomerRecordService,
              private userGroupService: UserGroupService,
              private uiRouter: UIRouter,
              private dialog: MatDialog,
              private deviceManagementService: DeviceManagementService,
              private contractNumberMultiselectProvider: ContractNumberMultiselectProvider,
              private projectRecordMultiselectProvider: ProjectRecordMultiselectProvider,
              private userService: UserService,
              private userMeService: UserMeService,
              private toasterService: ToasterService,
              private ownerUserItemFactory: OwnerUserItemFactory,
              private dateParser: NgbDatePickerParserFormatter,
              private userDataLoader: UserDataLoader,
              private formBuilder: FormBuilder,
              private stockMultiselectProvider: StockMultiselectProvider,
              private processMultiselectProvider: ProcessMultiselectProvider,
              private siteTourService: SiteTourService,
              private ngbDatePickerParserFormatter: NgbDatePickerParserFormatter,
              private injector: Injector) {
    super(TaskRecordSearchModel, injector);
    this._taskId = this.transition.params().taskId && !this.taskRecordId ? this.transition.params().taskId : undefined;
    this.uploadPath = '/tasks/' + this.taskId + '/records/import-xls?' + BaseHttpService.LOCATION_ON;
    this.dqlStoredArgs = {service: taskService.dqlStoredQueryService, parentId: this.taskId, documentType: undefined};
  }

  ngOnInit() {
    this.initDropdownSettings();
    this.loadConfig();
    this.loadRightModels(() => {
      this.loadTask();
      this.loadMe();
      this.loadTaskStatesForSearch();
      this.loadUserGroups();
      this.onAssigneeSearch();
      this.initSearch();
    });
    this.createStockExportForm();
    this.translateService.get('MENU_NAVBAR_FORMS').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.TASK_DASHBOARD});
      }
    );
    this.initTour();
    this.initComponentEmbedded();
  }

  initTour() {
    if (!this.isComponentEmbedded) {
      if (this.siteTourService.isTourAvailable(SiteTourId.TASK_RECORD_LIST_BASE)) {
        this.siteTourService.startTour(SiteTourId.TASK_RECORD_LIST_BASE, {
          skipCallback: undefined, completeCallback: () => {
            this.onCreateTaskRecord();
          }
        });
      } else if (this.transition.from().name === StateName.TASK_RECORD_CREATE
        || this.transition.from().name === StateName.TASK_RECORD_EDIT) {
        this.siteTourService.startTour(SiteTourId.TASK_RECORD_LIST_OPEN_1, {
          baseComponent: this, completeCallback: () => {
            this.siteTourOpen2Enabled = true;
          }
        });
      }
    }
  }

  initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(false)
      .enableCheckAll(false)
      .translate(true)
      .build();
    this.dropdownSettingsWithRemoteSearch = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
    this.dropdownSettingsForMulti = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .build();
    this.dropdownSettingsForMultiWithRemoteSearch = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .remoteSearch(true)
      .build();
    this.dropdownSettingsForTask = new Angular2Multiselects.SettingsBuilder()
      .disabled(!!this.taskId)
      .singleSelection(false)
      .enableSearchFilter(true)
      .remoteSearch(true)
      .enableCheckAll(true)
      .labelKey(OptionItem.KEY_TEXT)
      .build();
  }

  private initComponentEmbedded() {
    this.isComponentEmbedded = !!this.projectRecordId || !!this.processId || !!this.masterDataRecordId || !!this.customerRecordId
      || !!this.baseCustomerRecordByPhoneNumberId || !!this.taskRecordId;
  }

  private createStockExportForm() {
    this.stockExportForm = this.formBuilder.group({
      stock: [[], Validators.required]
    });
  }

  // <editor-fold desc="loaders">

  loadStocks(predicate?: string) {
    this.stockMultiselectProvider.loadActive(predicate).subscribe(items => {
      this.stocks = items;
    });
  }

  loadMe() {
    this.userMeService.loadMe().subscribe(me => {
      this.currentUser = me;
    });
  }

  loadTask() {
    if (this.taskId) {
      this.taskService.get({
        taskId: this.taskId,
        rights: Set.of(OperationRights.TASK_RECORD_CREATE)
      }).subscribe(
        (result: Task.Task) => {
          const taskGrantedRights = new TaskRightModel(GrantedPermissionSetResolver.byGrantedRights(result.grantedRights));
          this.taskName = result.name;
          this.task = result;
          this.taskManagedFields = result.managedFields;
          if (taskGrantedRights.taskRecordCreate.hasRight()) {
            this.tasksForCreate.push(result);
            this._canCreateTaskRecord = true;
          }
        }
      );
    } else {
      this._canCreateTaskRecord = true;
      this.translateService.get('TASK_RECORD_GLOBAL_LIST_TITLE').subscribe(text => this.taskName = text);
    }
  }

  public loadSearch(completion: () => void) {
    this.taskRecordSearchService.getSearchData({
      taskId: this.taskId,
      projectRecordId: this.projectRecordId,
      processId: this.processId,
      masterDataRecordId: this.masterDataRecordId,
      customerRecordId: this.customerRecordId,
      baseCustomerRecordByPhoneNumberId: this.baseCustomerRecordByPhoneNumberId,
      taskRecordId: this.taskRecordId,
    }).subscribe(
      (result: TaskRecordSearch.SearchDataResult) => {
        this.queryModel.itemsPerPage = result.searchData.itemsPerPage;
        this.queryModel.currentPage = result.searchData.pageNumber;
        this.queryModel.setOrder(result.searchData.order);
        this.searchModel.id = result.searchData.id;
        this.searchModel.name = result.searchData.name;
        this.searchModel.externalId = result.searchData.externalId;
        this.searchModel.customerName = result.searchData.customerName;
        this.searchModel.customerPhoneNumber = result.searchData.customerPhoneNumber;
        this.searchModel.assigneeUser = result.searchData.assigneeUser;
        this.searchModel.assigneeMobileApp = result.searchData.assigneeMobileApp;
        this.searchModel.userGroup = result.searchData.userGroup;
        this.searchModel.creatorUser = result.searchData.creatorUser;
        this.searchModel.contractNumbers = result.searchData.contractNumbers;
        this.searchModel.deadlineFrom = Models.localDateToNgbDate(result.searchData.deadlineFrom);
        this.searchModel.deadlineTo = Models.localDateToNgbDate(result.searchData.deadlineTo);
        this.searchModel.creationTimeFrom = Models.localDateToNgbDate(result.searchData.creationTimeFrom);
        this.searchModel.creationTimeTo = Models.localDateToNgbDate(result.searchData.creationTimeTo);
        this.searchModel.updateTimeFrom = Models.localDateToNgbDate(result.searchData.updateTimeFrom);
        this.searchModel.updateTimeTo = Models.localDateToNgbDate(result.searchData.updateTimeTo);
        this.searchModel.releaseTimeFrom = Models.localDateToNgbDate(result.searchData.releaseTimeFrom);
        this.searchModel.releaseTimeTo = Models.localDateToNgbDate(result.searchData.releaseTimeTo);
        this.searchModel.agreedTimeFrom = Models.localDateToNgbDate(result.searchData.agreedTimeFrom);
        this.searchModel.agreedTimeTo = Models.localDateToNgbDate(result.searchData.agreedTimeTo);
        this.searchModel.finishedTimeFrom = Models.localDateToNgbDate(result.searchData.finishedTimeFrom);
        this.searchModel.finishedTimeTo = Models.localDateToNgbDate(result.searchData.finishedTimeTo);
        this.searchModel.placeOfConsumptionCity = result.searchData.placeOfConsumptionCity;
        this.searchModel.placeOfConsumptionZipCode = result.searchData.placeOfConsumptionZipCode;
        this.searchModel.placeOfConsumptionStreet = result.searchData.placeOfConsumptionStreet;
        this.searchModel.placeOfConsumptionHouseNumber = result.searchData.placeOfConsumptionHouseNumber;
        this.searchModel.emptyIntakePrices = result.searchData.emptyIntakePrices;
        this.searchModel.dqlText = result.searchData.dqlText;
        this.searchModel.states = result.searchData.taskRecordStates;
        this.searchModel.importance = result.searchData.importance;
        this.searchModel.exportState = result.searchData.exportState;
        this.searchModel.projects = result.searchData.projects;
        this.searchModel.processes = result.searchData.processes;
        this.searchModel.tasks = result.searchData.tasks;
        completion();
      });
  }

  onFirstSearchOpen(): void {
    this.loadTaskImportancesForSearch();
    this.loadExportStatesForSearch();
    this.loadTasksForSearch(undefined);
    if (this.rightModel.projectRecordRead.hasRight()) {
      this.loadProjectsForSearch(undefined);
    }
    if (this.rightModel.processRead.hasRight()) {
      this.loadProcessesForSearch(undefined);
    }
  }

  loadDqlSearch(queryString?: string) {
    if (queryString) {
      this.dqlSearchContainer!.loadQuery(queryString);
    }
  }

  private loadTaskStatesForSearch() {
    const def = this.loadDefaultItem();
    this.taskStates = [];
    this.settableStateList = [];
    TaskRecordStateMachine.getOrderedStates(false).forEach(
      (state?: TaskRecordStateMachine.StateObject) => {
        if (state) {
          if (state.isPresentable) {
            const item1 = {
              id: state.state,
              itemName: '...'
            };
            this.taskStates.push(item1);
            this.translateService.get(state.stringKey).subscribe((text: string) => {
              item1.itemName = text;
            });
          }
          if (state.stringEditKey) {
            const item2 = {
              id: state.state,
              text: '...',
              stateObject: state
            };
            this.settableStateList.push(item2);
            if (item2.stateObject.state === 'OPEN') {
              this.settableStateOpen = item2;
            }
            this.translateService.get(state.stringEditKey).subscribe((text: string) => {
              item2.text = text;
            });
          }
        }
      });
  }

  private loadTaskImportancesForSearch() {
    const def = this.loadDefaultItem();
    this.taskImportances = [];
    this.taskImportances.push(def);
    TaskRecord.taskRecordImportances.forEach((importance) => {
      const item = {
        id: importance.importance,
        text: '...'
      };
      this.taskImportances.push(item);
      this.settableImportanceList.push(item);
      this.translateService.get(importance.stringKey).subscribe((text: string) => {
        item.text = text;
      });
    });
  }

  private loadExportStatesForSearch() {
    const def = this.loadDefaultItem();
    this.exportStates = [];
    this.exportStates.push(def);
    TaskRecord.taskRecordExportStates.forEach((state) => {
      const item = {
        id: state.state,
        text: '...'
      };
      this.exportStates.push(item);
      this.translateService.get(state.stringKey).subscribe((text: string) => {
        item.text = text;
      });
    });
  }

  private loadTasksForSearch(q?: string) {
    if (!this.taskId) {
      this.taskService.query({
        name: q ? Strings.undefinedOrNonEmpty(q) : undefined,
        paging: {
          pageNumber: 1,
          numberOfItems: UiConstants.autocompletePageSize
        },
        orders: Set.of({field: Task.OrderField.NAME, type: OrderType.ASC}),
        noProgressBar: true
      }).subscribe(
        (tasks: QueryResult<Task.Task>) => {
          this.tasksForSearch = [];
          tasks.items.forEach((task: Task.Task) => {
            const item = new TaskRecordSearch.TaskItem();
            item.id = task.taskId;
            item.text = (task.name) ? task.name : '';
            item.icon = (task.icon) ? task.icon : undefined;
            item.disabled = task.disabled;
            this.tasksForSearch.push(item);
          });
        }
      );
    }
  }

  loadProjectsForSearch(q?: string) {
    this.projectRecordMultiselectProvider.loadAll(q).subscribe(result => this.projects = result);
  }

  loadProcessesForSearch(q?: string) {
    this.processMultiselectProvider.loadAll(q).subscribe(result => {
      this.processes = result;
    })
  }


  loadDefaultItem() {
    return {id: null, text: this.translateService.instant(StringKey.COMMON_VALUE_UNSELECTED)};
  }


  private loadRightModels(completion: () => void) {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        completion();
      }
    );
  }

  private loadConfig() {
    this.config = this.configService.getConfigurationModel();
  }

  // </editor-fold>

  // <editor-fold desc="conditional observables">


  private createUserArrayObservable(): Observable<IdentityArray<UserData> | null> {
    if (this.config.assigneeWithUser) {
      return this.userDataLoader.loadAllWithFields(UserDataLoaderPermissionDeniedStrategy.MISS_ALL,
        undefined, Set.of('id', 'person_name', 'user_name', 'disabled'));
    } else {
      return observableEmpty().pipe(
        defaultIfEmpty(null));
    }
  }

  private createDeviceArrayObservable(includeDisabled: boolean, query?: string, appIds?: number[]):
    Observable<ResourceQueryResult<Device> | null> {
    if (this.rightModel.mobileAppRead.hasRight() && this.config.assigneeWithMobileApp && (!appIds || appIds.length > 0)) {
      return this.deviceManagementService.query({
        id: appIds ? appIds.join(',') : undefined,
        q: query,
        disabled: includeDisabled ? undefined : false,
        page_number: 1,
        number_of_items: 100,
        order: '+name'
      });
    } else {
      return observableEmpty().pipe(defaultIfEmpty(null));
    }
  }

  // </editor-fold>

  onAssigneeSearch(searchValue?: string) {
    if (this.config.assigneeWithUser) {
      this.onUserSearch(searchValue);
    } else if (this.config.assigneeWithMobileApp) {
      this.onDeviceSearch(searchValue);
    }
  }

  loadUserGroups(searchValue?: string) {
    this.userGroupService.query({
      name: Strings.undefinedOrNonEmpty(searchValue),
      fields: 'id,name,disabled',
      disabled: false,
      number_of_items: UiConstants.autocompletePageSize,
      page_number: 1,
      no_progress_bar: true,
      order: Services.createOrderFieldParameter(UserGroup.Keys.toOrderFieldKey, Set.of(UserGroup.DEFAULT_ORDER))
    }).subscribe((groups: ResourceQueryResult<UserGroup>) => {
      this.userGroups = [];
      groups.items.forEach(group => {
        const item = {
          id: group.id,
          itemName: group.name,
          disabled: group.disabled
        };
        this.userGroups.push(item);
      });
    });
  }

  private loadRelatedUserGroups(relatedUserGroupIds: Set<number[]>) {
    this.userGroupService.query({
      id: relatedUserGroupIds.join(',')
    }).subscribe(userGroups => {
      this.relatedUserGroups = [];
      userGroups.items.forEach(userGroup => {
        const item = new MultiselectOptionItem<number>();
        item.id = userGroup.id;
        item.itemName = userGroup.name;
        item.disabled = userGroup.disabled;
        this.relatedUserGroups.push(item);
      });
    });
  }

  onUserSearch(searchValue?: string) {
    this.userService.query({
      q: Strings.undefinedOrNonEmpty(searchValue),
      fields: 'id,user_name,person_name,disabled',
      disabled: false,
      number_of_items: UiConstants.autocompletePageSize,
      page_number: 1,
      user_group_ids: Services.createIdParameter(this.task?.enabledAssignees.userGroups),
      order: Services.createOrderFieldParameter(User.Keys.toOrderFieldKey, Set.of(User.DEFAULT_ORDER)),
      no_progress_bar: true,
    }).subscribe((users: ResourceQueryResult<User>) => {
      this.users = [];
      users.items.forEach(user => {
        const item = {
          id: user.id,
          itemName: user.person_name + ' (' + user.user_name + ')',
          disabled: user.disabled
        };
        this.users.push(item);
      });
    });
  }

  onContractNumberSearch(q?: string) {
    this.contractNumberMultiselectProvider.loadActive(q).subscribe(result => {
      this.contractNumbers = result;
    })
  }
  onDeviceSearch(searchValue?: string) {
    this.deviceManagementService.query({
      q: Strings.undefinedOrNonEmpty(searchValue),
      disabled: false,
      number_of_items: UiConstants.autocompletePageSize,
      page_number: 1,
      no_progress_bar: true
    }).subscribe((devices: ResourceQueryResult<Device>) => {
      this.devices = [];
      devices.items.forEach(device => {
        const item = {
          id: device.id,
          itemName: device.name ? device.name : device.application_id,
          appId: device.application_id,
          disabled: device.disabled,
        };
        this.devices.push(item);
      });
    });
  }

  public onImportSuccess(succeeded: boolean) {
    if (succeeded) {
      this.loadList(1);
    }
  }

  isGeocoded(taskRecord: TaskRecord.TaskRecord): boolean {
    return taskRecord.placeOfConsumption
      ? (taskRecord.placeOfConsumption.geocodeStatus === 'DONE' || taskRecord.placeOfConsumption.geocodeStatus === 'MANUAL')
      : false;
  }

  getPoc(taskRecord: TaskRecord.TaskRecord): string {
    if (!taskRecord.placeOfConsumption || !taskRecord.placeOfConsumption.address) {
      return '';
    }
    return Address.PostalAddressMapper.toString(taskRecord.placeOfConsumption.address, this.config.postalAddressFormat);
  }

  getDeadline(taskRecord: TaskRecord.TaskRecord): string {
    if (!taskRecord.deadline) {
      return '';
    }
    return taskRecord.deadline.toUtcIsoString();
  }

  toggleEachTask() {
    const eachTaskSelected = !this.eachTaskSelected;
    this.taskRecordList.forEach((tr) => {
      tr.selected = eachTaskSelected;
    });
  }

  deselectAll() {
    this.taskRecordList.forEach((tr) => {
      tr.selected = false;
    });
  }

  isAnyTaskSelected(): boolean {
    const selected: TaskRecordListModel[] = this.taskRecordList.filter((tr) => tr.selected);
    return selected.length > 0;
  }

  get eachTaskSelected(): boolean {
    if (this.taskRecordList.length === 0) {
      return false;
    }
    let selected = true;
    this.taskRecordList.forEach((tr) => {
      selected = selected && tr.selected;
    });
    return selected;
  }

  getCustomerPostalAddress(postalAddress: Address.PostalAddressData): string {
    return Address.PostalAddressMapper.toString(postalAddress, this.config.postalAddressFormat);
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: TaskRecord.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  loadDqlModel(completion?: () => void) {
    this.dqlSearchContainer!.setFields(this.taskService.getDqlModel({taskId: this.taskId}),
      TaskRecordDqlFieldTextProvider.getHelper(),
      completion ? completion : () => {
      });
  }

  onSearchClicked() {
    this.deselectAll();
    this.searchModel.dqlText = undefined;
    this.loadList(1);
    this.mapComponent.loadData();
  }

  onDqlSearchClicked() {
    this.deselectAll();
    const dqlQueryString = this.dqlSearchContainer!.getQueryString();
    if (dqlQueryString) {
      this.searchModel.clear();
    }
    this.searchModel.dqlText = dqlQueryString;
    this.loadList(1);
  }

  onSearchReset() {
    this.taskRecordSearchService.resetSearchData({
      taskId: this.taskId,
      projectRecordId: this.projectRecordId,
      processId: this.processId,
      masterDataRecordId: this.masterDataRecordId,
      customerRecordId: this.customerRecordId,
      baseCustomerRecordByPhoneNumberId: this.baseCustomerRecordByPhoneNumberId,
      taskRecordId: this.taskRecordId,
    }).subscribe(
      (result) => {
        this.searchResetClicked = true;
        this.loadSearch(() => {
          this.loadList();
        });
      }
    );
  }

  private saveSearch() {
    const searchData = {
      itemsPerPage: this.queryModel.itemsPerPage,
      pageNumber: this.queryModel.currentPage,
      order: this.queryModel.getOrder(),
      id: this.searchModel.id,
      name: this.searchModel.name,
      externalId: this.searchModel.externalId,
      creatorUser: this.searchModel.creatorUser,
      contractNumbers: this.searchModel.contractNumbers,
      userGroup: this.searchModel.userGroup,
      assigneeUser: this.searchModel.assigneeUser,
      assigneeMobileApp: this.searchModel.assigneeMobileApp,
      projects: this.searchModel.projects,
      processes: this.searchModel.processes,
      tasks: this.searchModel.tasks,
      customerName: this.searchModel.customerName,
      customerPhoneNumber: this.searchModel.customerPhoneNumber,
      placeOfWork: this.searchModel.placeOfConsumptionAddress,
      taskRecordStates: this.searchModel.states,
      importance: this.searchModel.importance,
      exportState: this.searchModel.exportState,
      deadlineFrom: Models.ngbDateToLocalDate(this.searchModel.deadlineFrom),
      deadlineTo: Models.ngbDateToLocalDate(this.searchModel.deadlineTo),
      creationTimeFrom: Models.ngbDateToLocalDate(this.searchModel.creationTimeFrom),
      creationTimeTo: Models.ngbDateToLocalDate(this.searchModel.creationTimeTo),
      updateTimeFrom: Models.ngbDateToLocalDate(this.searchModel.updateTimeFrom),
      updateTimeTo: Models.ngbDateToLocalDate(this.searchModel.updateTimeTo),
      releaseTimeFrom: Models.ngbDateToLocalDate(this.searchModel.releaseTimeFrom),
      releaseTimeTo: Models.ngbDateToLocalDate(this.searchModel.releaseTimeTo),
      agreedTimeFrom: Models.ngbDateToLocalDate(this.searchModel.agreedTimeFrom),
      agreedTimeTo: Models.ngbDateToLocalDate(this.searchModel.agreedTimeTo),
      finishedTimeFrom: Models.ngbDateToLocalDate(this.searchModel.finishedTimeFrom),
      finishedTimeTo: Models.ngbDateToLocalDate(this.searchModel.finishedTimeTo),
      placeOfConsumptionCity: this.searchModel.placeOfConsumptionCity,
      placeOfConsumptionZipCode: this.searchModel.placeOfConsumptionZipCode,
      placeOfConsumptionStreet: this.searchModel.placeOfConsumptionStreet,
      placeOfConsumptionHouseNumber: this.searchModel.placeOfConsumptionHouseNumber,
      emptyIntakePrices: this.searchModel.emptyIntakePrices,
      dqlText: this.searchModel.dqlText
    };
    this.taskRecordSearchService.setSearchData({
      taskId: this.taskId,
      projectRecordId: this.projectRecordId,
      processId: this.processId,
      customerRecordId: this.customerRecordId,
      baseCustomerRecordByPhoneNumberId: this.baseCustomerRecordByPhoneNumberId,
      taskRecordId: this.taskRecordId,
      searchData: searchData
    }).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  public loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();
    this.loadListSimple(requestedPage, order).subscribe(
      (result: QueryResult<TaskRecord.TaskRecord>) => {
        this.taskRecordList = this.toModelArray(result.items);
        const taskIds: number[] = [];
        const assigneeUserIds: number[] = [];
        const assigneeUserGroupIds: number[] = [];
        const assigneeAppIds: number[] = [];
        const invoiceIds: number[] = [];
        const finishedIds: number[] = [];
        this.taskRecordList.forEach(tr => {
          taskIds.push(tr.taskRecord.taskId);
          if (this.config.assigneeWithUser) {
            if (tr.taskRecord.assignee && tr.taskRecord.assignee.userId) {
              assigneeUserIds.push(tr.taskRecord.assignee.userId);
            } else if (tr.taskRecord.userGroupId) {
              assigneeUserGroupIds.push(tr.taskRecord.userGroupId);
            }
          }
          if (this.config.assigneeWithMobileApp && tr.taskRecord.assignee && tr.taskRecord.assignee.mobileApplicationId) {
            assigneeAppIds.push(tr.taskRecord.assignee.mobileApplicationId);
          }
          if (tr.taskRecord.invoiceIds) {
            invoiceIds.push(...tr.taskRecord.invoiceIds);
          }
          if (tr.taskRecord.state === 'FINISHED') {
            finishedIds.push(tr.taskRecord.taskRecordId);
          }
        });
        if (!this.taskId) {
          this.loadTasksForList(taskIds);
        }
        if (this.config.assigneeWithUser) {
          if (assigneeUserIds.length > 0) {
            this.loadUsersForList(assigneeUserIds);
          }
          if (assigneeUserGroupIds.length > 0) {
            this.loadUserGroupsForList(assigneeUserGroupIds);
          }
        }
        if (this.config.assigneeWithMobileApp) {
          this.loadAppsForList(assigneeAppIds);
        }
        if (this.rightModel.invoiceRead.hasRight()) {
          this.loadInvoiceNumbers(invoiceIds);
        }
        this.queryModel.currentPage = requestedPage;
        this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
        this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;

      }
    );
  }

  private loadTasksForList(ids: number[]) {
    this.tasks = [];
    this.taskService.query({
      taskIdSet: Set.of(...ids)
    }).subscribe((result: QueryResult<Task.Task>) => {
      this.tasks = result.items.toArray();
    });
  }

  private loadListSimple(requestedPage: number, order: Order<TaskRecord.OrderField>): Observable<QueryResult<TaskRecord.TaskRecord>> {
    if (this.taskId) {
      const request = this.getQueryRequest(requestedPage, order, this.queryFields(), this.customerQueryFields());
      return this.taskRecordService.query(request);
    } else {
      const request = this.getGlobalQueryRequest(requestedPage, order, this.queryFields(), this.customerQueryFields(), true);
      return this.taskRecordService.globalQuery(request);
    }
  }

  private loadUsersForList(assigneeUserIds: number[]) {
    this.userService.query({
      id: assigneeUserIds.join(',')
    }).subscribe(users => {
      this.taskRecordList.forEach(tr => {
        const custName = tr.taskRecord.customerRecord ? tr.taskRecord.customerRecord.name : undefined;
        tr.customerName = custName ? custName : '';
        if (tr.taskRecord.assignee && tr.taskRecord.assignee.userId) {
          const assigneeUser = users.items.find((u) => u.id === tr.taskRecord.assignee!.userId);
          if (assigneeUser) {
            tr.assignee.assigneeUser = assigneeUser;
          }
        }
      });
      const relatedUserGroupIds = Set.of(...users.items.map(u => u.user_group_ids));
      this.loadRelatedUserGroups(relatedUserGroupIds);
    });
  }

  private loadUserGroupsForList(assigneeUserGroupIds: number[]) {
    this.userGroupService.query({
      id: assigneeUserGroupIds.join(',')
    }).subscribe(userGroups => {
      this.taskRecordList.forEach(tr => {
        if (tr.taskRecord.userGroupId) {
          const assigneeUserGroup = userGroups.items.find((ug) => ug.id === tr.taskRecord.userGroupId);
          if (assigneeUserGroup) {
            tr.assignee.assigneeUserGroup = assigneeUserGroup;
          }
        }
      });
    });
  }

  private loadAppsForList(appIds: number[]) {
    this.createDeviceArrayObservable(true, undefined, appIds)
      .subscribe((devices: ResourceQueryResult<Device> | null) => {
        this.taskRecordList.forEach((tr) => {
          const custName = tr.taskRecord.customerRecord ? tr.taskRecord.customerRecord.name : undefined;
          tr.customerName = custName ? custName : '';
          if (devices !== null) {
            const device = devices.items
              .find(d => tr.taskRecord.assignee !== undefined && tr.taskRecord.assignee.mobileApplicationId === d.id);
            tr.assignee.assigneeMobileApp = device;
          }
        });
      });
  }

  private loadInvoiceNumbers(invoiceIds: number[]) {
    if (invoiceIds.length > 0) {
      this.invoiceService.query({
        withRecords: false,
        withFooter: false,
        invoiceIdSet: Set.of(...invoiceIds)
      }).subscribe((invoices: QueryResult<Invoice.Invoice>) => {
        invoices.items.toArray().forEach((i) => {
          this.invoices.push({
            id: i.id,
            text: i.invoiceNumber
          });
        });
      });
    }
  }

  checkState(newState: TaskRecordStateMachine.StateObject) {
    const order = this.queryModel.getOrder();
    const request = this.isAnyTaskSelected() ? {
      taskRecordIdSet: this.getSelectedIdSet()
    } : this.getGlobalQueryRequest(this.queryModel.currentPage, order, this.queryFields());
    const stateRequest: TaskRecord.CheckStateQuery = {
      stateChange: newState,
      query: request
    };
    this.taskRecordService.checkState(stateRequest).subscribe((result: Set<number>) => {
        if (result.size > 0) {
          this.showApproveStateChangeDialog(newState, result);
        } else {
          this.toasterService.pop({
            timeout: UiConstants.ToastTimeoutLong,
            type: UiConstants.toastTypeError,
            title: this.translateService.instant(StringKey.TASKS_STATE_MODIFY),
            body: this.translateService.instant(StringKey.TASK_STATE_CHANGE_ERROR)
          });
        }
      },
      (error: any) => {
      });
  }

  getStateIcon(state: TaskRecordStateMachine.State): string {
    const stateObject: TaskRecordStateMachine.StateObject | undefined = TaskRecordStateMachine.taskRecordStates.get(state);
    if (stateObject) {
      return stateObject.iconClass;
    }
    return '';
  }

  getStateLabel(state: TaskRecordStateMachine.State): string {
    const stateObject: TaskRecordStateMachine.StateObject | undefined = TaskRecordStateMachine.taskRecordStates.get(state);
    if (stateObject) {
      return stateObject.stringKey;
    }
    return '';
  }

  showApproveStateChangeDialog(newState: TaskRecordStateMachine.StateObject, ids: Set<number>) {
    const dialogRef = TaskRecordStateChangeDialogComponent.openDialog(this.dialog, {
      newState: newState,
      ids: ids
    }, (result: TaskRecordStateChangeDialogResult) => {
      if (result.success) {
        this.deselectAll();
        this.loadList();
      }
    });
    if (this.siteTourOpen2Enabled) {
      this.siteTourOpen2Enabled = false;
      const req = {baseComponent: this, dialogRef: dialogRef};
      this.siteTourService.startTour(SiteTourId.TASK_RECORD_LIST_OPEN_2, req);
    }
  }

  showExternalExportDialog() {
    this.externalExportDialogVisible = true;
    this.externalExportDialog.show();
    $('#externalExportDialog').appendTo($('body'));
  }

  closeExternalExportDialog() {
    this.externalExportDialogVisible = false;
    this.externalExportDialog.hide();
    this.checkedIds = Set.of();
  }

  showCreatePreselectDialog() {
    this.createPreselectDialogVisible = true;
    this.createPreselectDialog.show();
    $('#createPreselectDialog').appendTo($('body'));
  }

  closeCreatePreselectDialog() {
    this.createPreselectDialogVisible = false;
    this.createPreselectDialog.hide();
    this.checkedIds = Set.of();
  }

  onCreateTaskRecord() {
    if (this.taskId) {
      this.createTaskRecord(this.taskId);
    } else {
      if (this.tasksForCreate.length === 1) {
        this.uiRouter.stateService.go(StateName.TASK_RECORD_CREATE, {taskId: this.tasksForCreate[0].taskId},
          this.createOptions());
      } else if (this.tasksForCreate.length > 1) {
        this.showCreatePreselectDialog();
      } else {
        this.loadTasksForCreate();
      }
    }
  }

  private createOptions(): TransitionOptions | undefined {
    return this.projectRecordId ? {custom: {projectRecordId: this.projectRecordId}} : undefined;
  }

  private loadTasksForCreate() {
    this.tasksForCreate = [];
    this.taskService.query({
      disabled: false,
      rights: Set.of(OperationRights.TASK_RECORD_CREATE)
    }).subscribe((result: QueryResult<Task.Task>) => {
      result.items.forEach(task => {
        if (task) {
          const taskGrantedRights = new TaskRightModel(GrantedPermissionSetResolver.byGrantedRights(task.grantedRights));
          if (taskGrantedRights.taskRecordCreate.hasRight()) {
            this.tasksForCreate.push(task);
          }
        }
      });
      if (this.tasksForCreate.length === 1) {
        this.uiRouter.stateService.go(StateName.TASK_RECORD_CREATE, {taskId: this.tasksForCreate[0].taskId}, this.createOptions());
      } else if (this.tasksForCreate.length > 1) {
        this.showCreatePreselectDialog();
      } else {
        this.toasterService.pop({
          timeout: UiConstants.ToastTimeoutLong,
          type: UiConstants.toastTypeError,
          title: this.translateService.instant(StringKey.TASK_RECORD_CREATE_ERROR_TITLE),
          body: this.translateService.instant(StringKey.TASK_RECORD_CREATE_ERROR_MESSAGE_NO_TASK)
        });
      }
    });
  }

  createTaskRecord(taskId: number) {
    this.closeCreatePreselectDialog();
    this.uiRouter.stateService.go(StateName.TASK_RECORD_CREATE, {taskId: taskId}, this.createOptions());
  }

  checkBulkUpdate(updateType: BukUpdateType) {
    const order = this.queryModel.getOrder();
    const request = this.isAnyTaskSelected() ? {
      taskRecordIdSet: this.getSelectedIdSet()
    } : this.getGlobalQueryRequest(this.queryModel.currentPage, order, this.queryFields());
    const method = 'ASSIGNEE' === updateType
      ? this.taskRecordService.checkBulkChangeAssignee(request)
      : this.taskRecordService.checkBulkChangeDeadline(request);
    method.subscribe((result: Set<number>) => {
        if (result.size > 0) {
          this.bulkModifiableTaskRecordIds = result;
          if (updateType === 'ASSIGNEE') {
            this.showAssigneeModificationDialog();
          }
          if (updateType === 'DEADLINE') {
            this.showDeadlineModificationDialog();
          }
        } else {
          this.toasterService.pop({
            timeout: UiConstants.ToastTimeoutLong,
            type: UiConstants.toastTypeError,
            title: this.translateService.instant(StringKey.TASKS_DATA_MODIFY),
            body: this.translateService.instant(StringKey.TASK_BULK_MODIFY_ERROR)
          });
        }
      },
      (error: Error) => {
        this.toasterService.pop({
          timeout: UiConstants.ToastTimeoutLong,
          type: UiConstants.toastTypeError,
          title: this.translateService.instant(StringKey.TASKS_STATE_MODIFY),
          body: this.translateService.instant(StringKey.TASK_BULK_MODIFY_ERROR)
        });
        this.bulkModifiableTaskRecordIds = Set<number>();
      });
  }

  tryChangeAssignee() {
    this.taskRecordService.changeAssignee({
      taskRecordIdSet: this.bulkModifiableTaskRecordIds,
      userId: this.assigneeModificationModel.assigneeUser && this.assigneeModificationModel.assigneeUser.id
        ? this.assigneeModificationModel.assigneeUser.id
        : undefined,
      mobileApplicationId: this.assigneeModificationModel.assigneeMobileApp && this.assigneeModificationModel.assigneeMobileApp.id
        ? this.assigneeModificationModel.assigneeMobileApp.id
        : undefined
    }).subscribe((result: EmptyMessage) => {
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutShort,
        type: UiConstants.toastTypeSuccess,
        title: this.translateService.instant(StringKey.TASKS_DATA_MODIFY),
        body: this.translateService.instant(StringKey.TASK_DATA_MODIFY_SUCCESS)
      });
      this.bulkModifiableTaskRecordIds = Set<number>();
      this.deselectAll();
      this.loadList();
    });
    this.closeAssigneeModificationDialog();
  }

  showAssigneeModificationDialog() {
    if (this.config.assigneeWithUser) {
      this.onUserSearch();
    }
    if (this.config.assigneeWithMobileApp) {
      this.onDeviceSearch();
    }
    this.assigneeModificationDialogVisible = true;
    this.assigneeModificationDialog.show();
    $('#assigneeModificationDialog').appendTo($('body'));
  }

  closeAssigneeModificationDialog() {
    this.assigneeModificationModel = new AssigneeModificationModel();
    this.bulkModifiableTaskRecordIds = Set<number>();
    this.assigneeModificationDialogVisible = false;
    this.assigneeModificationDialog.hide();
  }

  tryChangeDeadline() {
    this.taskRecordService.changeDeadline({
      taskRecordIdSet: this.bulkModifiableTaskRecordIds,
      deadline:
        this.dateParser.toOffsetDateTime(this.deadlineModificationModel.deadlineDate, this.deadlineModificationModel.deadlineTime)
    }).subscribe((result: EmptyMessage) => {
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutShort,
        type: UiConstants.toastTypeSuccess,
        title: this.translateService.instant(StringKey.TASKS_DATA_MODIFY),
        body: this.translateService.instant(StringKey.TASKS_DATA_MODIFY)
      });
      this.bulkModifiableTaskRecordIds = Set<number>();
      this.deselectAll();
      this.loadList();
    });
    this.closeDeadlineModificationDialog();
  }

  showDeadlineModificationDialog() {
    this.deadlineModificationDialogVisible = true;
    this.deadlineModificationDialog.show();
    $('#deadlineModificationDialog').appendTo($('body'));
  }

  closeDeadlineModificationDialog() {
    this.deadlineModificationModel = new DeadlineModificationModel();
    this.deadlineModificationDialogVisible = false;
    this.deadlineModificationDialog.hide();
  }

  checkBulkGeocode() {
    if (!this.rightModel.taskRecordGeocode.hasRight()) {
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutLong,
        type: UiConstants.toastTypeError,
        title: this.translateService.instant(StringKey.TASKS_DATA_MODIFY),
        body: this.translateService.instant(StringKey.COMMON_NO_PERMISSION)
      });
      return;
    }
    const order = this.queryModel.getOrder();
    const request = this.isAnyTaskSelected() ? {
      taskRecordIdSet: this.getSelectedIdSet()
    } : this.getGlobalQueryRequest(this.queryModel.currentPage, order, this.queryFields());
    this.taskRecordService.checkGeocode(request).subscribe((result: Set<number>) => {
        if (result.size > 0) {
          this.bulkModifiableTaskRecordIds = result;
          this.showGeocodingDialog();
        } else {
          this.toasterService.pop({
            timeout: UiConstants.ToastTimeoutLong,
            type: UiConstants.toastTypeError,
            title: this.translateService.instant(StringKey.TASKS_DATA_MODIFY),
            body: this.translateService.instant(StringKey.TASKS_DATA_MODIFY)
          });
        }
      },
      (error: Error) => {
        this.toasterService.pop({
          timeout: UiConstants.ToastTimeoutLong,
          type: UiConstants.toastTypeError,
          title: this.translateService.instant(StringKey.TASKS_STATE_MODIFY),
          body: this.translateService.instant(StringKey.TASKS_DATA_MODIFY)
        });
        this.bulkModifiableTaskRecordIds = Set<number>();
      });
  }

  tryGeocode() {
    this.taskRecordService.geocode({
      taskRecordIdSet: this.bulkModifiableTaskRecordIds,
    }).subscribe((result: EmptyMessage) => {
        this.toasterService.pop({
          timeout: UiConstants.ToastTimeoutShort,
          type: UiConstants.toastTypeSuccess,
          title: this.translateService.instant(StringKey.TASKS_DATA_MODIFY),
          body: this.translateService.instant(StringKey.TASKS_DATA_MODIFY)
        });
        this.bulkModifiableTaskRecordIds = Set<number>();
        this.deselectAll();
        this.loadList();
        this.closeGeocodingDialog();
      },
      (error: Error) => {
        this.toasterService.pop({
          timeout: UiConstants.ToastTimeoutLong,
          type: UiConstants.toastTypeError,
          title: this.translateService.instant(StringKey.TASKS_DATA_MODIFY),
          body: this.translateService.instant(StringKey.TASKS_DATA_MODIFY)
        });
        this.bulkModifiableTaskRecordIds = Set<number>();
        this.closeGeocodingDialog();
      });
    this.closeGeocodingDialog();

  }

  showGeocodingDialog() {
    this.geocodingDialogVisible = true;
    this.geocodingDialog.show();
    $('#geocodingDialog').appendTo($('body'));
  }

  closeGeocodingDialog() {
    this.geocodingDialogVisible = false;
    this.geocodingDialog.hide();
  }

  checkExternalExportIds() {
    this.checkedIds = Set.of();
    if (this.isAnyTaskSelected()) {
      this.checkedIds = this.getSelectedIdSet();
      this.showExternalExportDialog();
    } else {
      this.taskRecordService.globalQuery(this.getGlobalQueryRequest(undefined, undefined, Set.of('id')))
        .subscribe((result: QueryResult<TaskRecord.TaskRecord>) => {
          const idSet: number[] = [];
          result.items.forEach(tr => {
            if (tr) {
              idSet.push(tr.taskRecordId);
            }
          });
          this.checkedIds = Set.of(...idSet);
          this.showExternalExportDialog();
        });
    }
  }

  exportToExternalSystem(idSet: Set<number>) {
    this.taskRecordService.exportToExternalSystem({
      taskRecordIds: idSet,
    }).subscribe((result: TaskRecord.ExternalExportResult) => {
        this.toasterService.clear();
        let toastType: string = UiConstants.toastTypeInfo;
        let toastMessageKey: string = 'COMMON_BUTTON_EXTERNAL_EXPORT';
        switch (result) {
          case TaskRecord.ExternalExportResult.EACH_EXPORT_DONE:
            toastType = UiConstants.toastTypeSuccess;
            toastMessageKey = 'TASK_RECORD_EXPORT_TO_EXTERNAL_SERVICE_EACH_EXPORT_DONE';
            break;
          case TaskRecord.ExternalExportResult.EACH_EXPORT_FAILED:
            toastType = UiConstants.toastTypeError;
            toastMessageKey = 'TASK_RECORD_EXPORT_TO_EXTERNAL_SERVICE_EACH_EXPORT_FAILED';
            break;
          case TaskRecord.ExternalExportResult.EMPTY_ID_SET:
            toastType = UiConstants.toastTypeInfo;
            toastMessageKey = 'TASK_RECORD_EXPORT_TO_EXTERNAL_SERVICE_EMPTY_ID_SET';
            break;
          case TaskRecord.ExternalExportResult.FAILED_TO_SAVE_EXPORT_STATE:
            toastType = UiConstants.toastTypeError;
            toastMessageKey = 'TASK_RECORD_EXPORT_TO_EXTERNAL_SERVICE_FAILED_TO_SAVE_EXPORT_STATE';
            break;
          case TaskRecord.ExternalExportResult.INIT_ERROR:
            toastType = UiConstants.toastTypeError;
            toastMessageKey = 'TASK_RECORD_EXPORT_TO_EXTERNAL_SERVICE_INIT_ERROR';
            break;
          case TaskRecord.ExternalExportResult.INVALID_TASK_RECORD_STATE:
            toastType = UiConstants.toastTypeError;
            toastMessageKey = 'TASK_RECORD_EXPORT_TO_EXTERNAL_SERVICE_INVALID_TASK_RECORD_STATE';
            break;
          case TaskRecord.ExternalExportResult.INVALID_TASK_RECORD_TYPE:
            toastType = UiConstants.toastTypeError;
            toastMessageKey = 'TASK_RECORD_EXPORT_TO_EXTERNAL_SERVICE_INVALID_TASK_RECORD_TYPE';
            break;
          case TaskRecord.ExternalExportResult.SKIPPED:
            toastType = UiConstants.toastTypeInfo;
            toastMessageKey = 'TASK_RECORD_EXPORT_TO_EXTERNAL_SERVICE_SKIPPED';
            break;
          case TaskRecord.ExternalExportResult.SOME_EXPORT_DONE:
            toastType = UiConstants.toastTypeWarning;
            toastMessageKey = 'TASK_RECORD_EXPORT_TO_EXTERNAL_SERVICE_SOME_EXPORT_DONE';
            break;
        }
        const toastTitleKey: string = 'COMMON_BUTTON_EXTERNAL_EXPORT';
        this.translateService.get([toastTitleKey, toastMessageKey]).subscribe((o) => {
          this.toasterService.pop({
            type: toastType,
            timeout: UiConstants.ToastTimeoutLong,
            title: TranslateUtils.extractValueFromObject(o, toastTitleKey),
            body: TranslateUtils.extractValueFromObject(o, toastMessageKey)
          });
        });
        this.deselectAll();
        this.loadList();
      }, (error) => {
      },
      () => {
      });
    this.closeExternalExportDialog();
  }

  exportXls() {
    const request = this.isAnyTaskSelected() ? {
      taskIdSet: this.taskId ? Set.of(this.taskId) : undefined,
      taskRecordIdSet: this.getSelectedIdSet()
    } : this.getGlobalQueryRequest();
    this.taskRecordService.exportXls(request).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName('task_record.xlsx'));
      }
    );
  }

  exportCustomXls() {
    const request = this.isAnyTaskSelected() ? {
      taskIdSet: this.taskId ? Set.of(this.taskId) : undefined,
      taskRecordIdSet: this.getSelectedIdSet()
    } : this.getGlobalQueryRequest();
    this.taskRecordService.exportCustomXls(request).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName('task_record_custom.xlsx'));
      }
    );
  }

  exportSupCreate() {
    if (this.isAnyTaskSelected()) {
      TaskRecordSupExportDialogComponent.openDialog(this.dialog, {taskRecordIds: this.getSelectedIdSet()},
        result => {
          if (result) {
            // dunno what to do
          }
        });
    } else {
      const request = this.getGlobalQueryRequest();
      request.fields = Set.of('id');
      this.taskRecordService.globalQuery(request).subscribe(tr => {
        if (tr.items.size > 0) {
          TaskRecordSupExportDialogComponent
            .openDialog(this.dialog, {taskRecordIds: Set.of(...tr.items.map(x => x!.taskRecordId).toArray())},
            result => {
              if (result) {
              }
            });
        } else {
          this.toasterService.pop({
            timeout: UiConstants.ToastTimeoutLong,
            type: UiConstants.toastTypeError,
            title: this.translateService.instant('TASK_RECORD_SUP_EXPORT_NO_TASK_RECORDS_TITLE'),
            body: this.translateService.instant('TASK_RECORD_SUP_EXPORT_NO_TASK_RECORDS_BODY')
          });

        }
      });
    }
  }

  exportSupInvoice() {
    if (this.isAnyTaskSelected()) {
      this.taskRecordService.exportSupInvoice(this.getSelectedIdSet()).subscribe(
        (res: DownloadedFile) => {
          saveAs(res.getBlob(), res.getFileName('task_record_sup_invoice.xml'));
        }
      );
    } else {
      const request = this.getGlobalQueryRequest();
      request.fields = Set.of('id');
      this.taskRecordService.globalQuery(request).subscribe(tr => {
        if (tr.items.size > 0) {
          this.taskRecordService.exportSupInvoice(Set.of(...tr.items.toArray()
            .filter(x => x !== undefined)
            .map(x => x!.taskRecordId))).subscribe(
            (res: DownloadedFile) => {
              saveAs(res.getBlob(), res.getFileName('task_record_sup_invoice.xml'));
            });
        } else {
          this.toasterService.pop({
            timeout: UiConstants.ToastTimeoutLong,
            type: UiConstants.toastTypeError,
            title: this.translateService.instant('TASK_RECORD_SUP_EXPORT_NO_TASK_RECORDS_TITLE'),
            body: this.translateService.instant('TASK_RECORD_SUP_EXPORT_NO_TASK_RECORDS_BODY')
          });
        }
      });
    }
  }

  exportMaconomy() {
    const request = this.isAnyTaskSelected() ? {
      taskIdSet: this.taskId ? Set.of(this.taskId) : undefined,
      taskRecordIdSet: this.getSelectedIdSet()
    } : this.getGlobalQueryRequest();
    this.taskRecordService.exportMaconomy(request).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName('task_record_maconomy.xlsx'));
      }
    );
  }

  exportStockXls() {
    const request = this.isAnyTaskSelected() ? {
      taskRecordIdSet: this.getSelectedIdSet()
    } : this.getGlobalQueryRequest();
    if (this.stockExportIncludeOtherProducts) {
      if (this.stockExportSelectedStock.length > 0) {
        request.stockId = this.stockExportSelectedStock[0].id;
      } else {
        this.stockExportForm.controls['stock'].markAsTouched();
        return;
      }
    }
    this.taskRecordService.exportStockXls(request).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName('task_record_stock.xlsx'));
        this.closeStockExportDialog();
      }
    );
  }

  exportXlsTemplate() {
    this.taskRecordService.exportXlsTemplate({id: this.taskId!}).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName('task_record_template.xlsx'));
      }
    );
  }

  showStockExportDialog() {
    this.loadStocks();
    this.stockExportDialogVisible = true;
    this.stockExportDialog.show();
    $('#stockExportDialog').appendTo($('body'));
  }

  closeStockExportDialog() {
    this.stockExportForm.reset();
    this.stockExportIncludeOtherProducts = false;
    this.stockExportSelectedStock = [];
    this.stockExportDialogVisible = false;
    this.stockExportDialog.hide();
  }

  canModify(taskRecord: TaskRecord.TaskRecord, assignee?: User): boolean {
    const inState = TaskRecordStateMachine.StateMachine.canEdit(taskRecord.task, taskRecord.state);
    if (inState && taskRecord.state === 'IN_PROGRESS') {
      if (!assignee || !this.currentUser) {
        return false;
      }
      return assignee.id === this.currentUser.id;
    }
    return inState;
  }

  downloadCsv() {
    if (!this.rightModel.taskRecordExportFile.hasRight()) {
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutLong,
        type: UiConstants.toastTypeError,
        title: this.translateService.instant(StringKey.TASKS_DATA_MODIFY),
        body: this.translateService.instant(StringKey.COMMON_NO_PERMISSION)
      });
    }
    const request = this.isAnyTaskSelected() ? {
      taskId: this.taskId!,
      query: {
        taskRecordIdSet: this.getSelectedIdSet()
      }
    } : this.getQueryRequest();
    this.taskRecordService.downloadCsv(request).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName('csv_export.csv'));
      }
    );
  }

  downloadAttachmentZip() {
    if (!this.rightModel.taskRecordAttachmentReadList.hasRight()) {
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutLong,
        type: UiConstants.toastTypeError,
        title: this.translateService.instant(StringKey.TASKS_DATA_MODIFY),
        body: this.translateService.instant(StringKey.COMMON_NO_PERMISSION)
      });
    }

    const request = this.isAnyTaskSelected() ? {
      taskRecordIdSet: this.getSelectedIdSet()
    } : this.getGlobalQueryRequest(undefined, undefined, Set.of('id'));
    this.taskRecordService.globalQuery(request)
      .subscribe((result: QueryResult<TaskRecord.TaskRecord>) => {
        const idSet: number[] = [];
        result.items.forEach(tr => {
          if (tr) {
            idSet.push(tr.taskRecordId);
          }
        });
        this.taskRecordService.downloadAttachmentZip(
          {
            taskRecordIdSet: Set.of(...idSet)
          }).subscribe(res => {
          saveAs(res.getBlob(), res.getFileName('attachments.zip'));
        });
      });
  }

  downloadPdfZip() {
    if (!this.rightModel.taskRecordRead.hasRight()) {
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutLong,
        type: UiConstants.toastTypeError,
        title: this.translateService.instant(StringKey.TASKS_DATA_MODIFY),
        body: this.translateService.instant(StringKey.COMMON_NO_PERMISSION)
      });
    }

    const request = this.isAnyTaskSelected() ? {
      taskRecordIdSet: this.getSelectedIdSet()
    } : this.getGlobalQueryRequest(undefined, undefined, Set.of('id'));
    this.taskRecordService.globalQuery(request)
      .subscribe((result: QueryResult<TaskRecord.TaskRecord>) => {
        const idSet: number[] = [];
        result.items.forEach(tr => {
          if (tr) {
            idSet.push(tr.taskRecordId);
          }
        });
        this.taskRecordService.downloadPdfZip(
          {
            taskRecordIdSet: Set.of(...idSet)
          }).subscribe(res => {
          saveAs(res.getBlob(), res.getFileName('pdf-document.zip'));
        });
      });
  }

  downloadMergedPdf(print: boolean) {
    if (!this.rightModel.taskRecordRead.hasRight()) {
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutLong,
        type: UiConstants.toastTypeError,
        title: this.translateService.instant(StringKey.TASKS_DATA_MODIFY),
        body: this.translateService.instant(StringKey.COMMON_NO_PERMISSION)
      });
    }

    const request = this.isAnyTaskSelected() ? {
      taskRecordIdSet: this.getSelectedIdSet()
    } : this.getGlobalQueryRequest(undefined, undefined, Set.of('id'));
    this.taskRecordService.globalQuery(request)
      .subscribe((result: QueryResult<TaskRecord.TaskRecord>) => {
        const idSet: number[] = [];
        result.items.forEach(tr => {
          if (tr) {
            idSet.push(tr.taskRecordId);
          }
        });
        this.taskRecordService.downloadMergedPdf(
          {
            taskRecordIdSet: Set.of(...idSet)
          }).subscribe(res => {
          if (print) {
            printJS(URL.createObjectURL(res.getBlob()));
          } else {
            saveAs(res.getBlob(), res.getFileName('merged-pdf.pdf'));
          }
        });
      });
  }

  downloadTableXls() {
    if (!this.rightModel.taskRecordRead.hasRight()) {
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutLong,
        type: UiConstants.toastTypeError,
        title: this.translateService.instant(StringKey.TASKS_DATA_MODIFY),
        body: this.translateService.instant(StringKey.COMMON_NO_PERMISSION)
      });
    }

    const request = this.isAnyTaskSelected() ? {
      taskRecordIdSet: this.getSelectedIdSet()
    } : this.getGlobalQueryRequest(undefined, undefined, Set.of('id'));
    this.taskRecordService.globalQuery(request)
      .subscribe((result: QueryResult<TaskRecord.TaskRecord>) => {
        const idSet: number[] = [];
        result.items.forEach(tr => {
          if (tr) {
            idSet.push(tr.taskRecordId);
          }
        });
        this.taskRecordService.downloadTableXls(
          {
            taskRecordIdSet: Set.of(...idSet)
          }).subscribe(res => {
          saveAs(res.getBlob(), res.getFileName('table-xls.xlsx'));
        });
      });
  }

  getGlobalQueryRequest(requestedPage?: number, order?: Order<TaskRecord.OrderField>, fields?: Set<string>,
                        customerFields?: Set<string>, searchId?: boolean):
    TaskRecord.GlobalQueryRequest {
    return {
      parentDisabled: false,
      taskIdSet: this.taskId ? Set.of(this.taskId) : Set.of(...this.searchModel.tasks.map(t => t.id!)),
      taskRecordIdSet: Models.parseNumber(this.searchModel.id) ? Set.of(Models.parseNumber(this.searchModel.id)!) : undefined,
      name: Strings.undefinedOrNonEmpty(this.searchModel.name),
      externalId: Strings.undefinedOrNonEmpty(this.searchModel.externalId),
      state: this.searchModel.states.length !== 0
        ? Set.of(...this.searchModel.states.map(s => s.id)) : undefined,
      assigneeUserName: Strings.undefinedOrNonEmpty(
        this.searchModel.assigneeUser.length > 0 ? this.searchModel.assigneeUser[0]!.itemName : undefined
      ),
      assigneeUserGroupName: Strings.undefinedOrNonEmpty(
        this.searchModel.userGroup.length > 0 ? this.searchModel.userGroup[0]!.text : undefined
      ),
      assigneeMobileAppName: Strings.undefinedOrNonEmpty(
        this.searchModel.assigneeMobileApp.length > 0 ? this.searchModel.assigneeMobileApp[0]!.itemName : undefined
      ),
      assigneeUserId: this.searchModel.assigneeUser.length > 0 ? this.searchModel.assigneeUser[0]!.id : undefined,
      assigneeUserGroupId: this.searchModel.userGroup.length > 0 ? this.searchModel.userGroup[0]!.id! : undefined,
      assigneeMobileAppId: this.searchModel.assigneeMobileApp.length > 0 ? this.searchModel.assigneeMobileApp[0]!.appId : undefined,
      creatorUserId: Set.of(...this.searchModel.creatorUser.map(u => u.id)),
      contractNumberId: this.searchModel.contractNumbers.length > 0 ? Set.of(...this.searchModel.contractNumbers.map(n => n.id)) : undefined,
      customerName: Strings.undefinedOrNonEmpty(this.searchModel.customerName),
      customerPhoneNumber: Strings.undefinedOrNonEmpty(this.searchModel.customerPhoneNumber),
      projectIdSet: Set.of(...this.searchModel.projects.map(p => p.id)),
      baseProject: this.projectRecordId,
      baseProcess: this.processId,
      baseMasterDataRecord: this.masterDataRecordId,
      baseCustomerRecord: this.customerRecordId,
      baseCustomerRecordByPhoneNumber: this.baseCustomerRecordByPhoneNumberId,
      baseTaskRecord: this.taskRecordId,
      processIdSet: Set.of(...this.searchModel.processes.map(p => p.id)),
      importance: this.searchModel.importance !== null ? this.searchModel.importance : undefined,
      exportState: this.searchModel.exportState !== null ? this.searchModel.exportState : undefined,
      deadlineFrom: Models.parseDateTimeFrom(this.searchModel.deadlineFrom),
      deadlineTo: Models.parseDateTimeTo(this.searchModel.deadlineTo),
      creationTimeFrom: Models.parseDateTimeFrom(this.searchModel.creationTimeFrom),
      creationTimeTo: Models.parseDateTimeTo(this.searchModel.creationTimeTo),
      updateTimeFrom: Models.parseDateTimeFrom(this.searchModel.updateTimeFrom),
      updateTimeTo: Models.parseDateTimeTo(this.searchModel.updateTimeTo),
      releaseTimeFrom: Models.parseDateTimeFrom(this.searchModel.releaseTimeFrom),
      releaseTimeTo: Models.parseDateTimeTo(this.searchModel.releaseTimeTo),
      agreedTimeFrom: Models.parseDateTimeFrom(this.searchModel.agreedTimeFrom),
      agreedTimeTo: Models.parseDateTimeTo(this.searchModel.agreedTimeTo),
      finishedTimeFrom: Models.parseDateTimeFrom(this.searchModel.finishedTimeFrom),
      finishedTimeTo: Models.parseDateTimeTo(this.searchModel.finishedTimeTo),
      placeOfConsumptionCity: Strings.undefinedOrNonEmpty(this.searchModel.placeOfConsumptionCity),
      placeOfConsumptionZipCode: Strings.undefinedOrNonEmpty(this.searchModel.placeOfConsumptionZipCode),
      placeOfConsumptionStreet: Strings.undefinedOrNonEmpty(this.searchModel.placeOfConsumptionStreet),
      placeOfConsumptionHouseNumber: Strings.undefinedOrNonEmpty(this.searchModel.placeOfConsumptionHouseNumber),
      emptyIntakePrices: this.searchModel.emptyIntakePrices ? this.searchModel.emptyIntakePrices : undefined,
      withFormRecord: false,
      dqlText: Strings.undefinedOrNonEmpty(this.searchModel.dqlText),
      fields: fields,
      rights: this.baseCustomerRecordByPhoneNumberId
        ? Set.of(OperationRights.TASK_RECORD_READ, OperationRights.TASK_RECORD_UPDATE)
        : Set.of(OperationRights.TASK_RECORD_UPDATE),
      customerFields: customerFields,
      orders: order ? Set.of(order) : Set.of(),
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined
    };
  }

  getQueryRequest(requestedPage?: number, order?: Order<TaskRecord.OrderField>, fields?: Set<string>, customerFields?: Set<string>):
    TaskRecord.QueryRequest {
    return {
      taskId: this.taskId!,
      query: this.getGlobalQueryRequest(requestedPage, order, fields, customerFields, true)
    };
  }

  private queryFields(): Set<string> {
    return Set.of('id', 'external_id', 'task_id', 'name', 'assignee', 'user_group_id', 'place_of_consumption',
      'contract_number', 'customer_id', 'state', 'deadline', 'creation_time', 'state_change_log', 'export_state', 'previous_export_error', 'project_id',
      'agreed_time', 'finalization_time', 'invoice_ids', 'customer_record', 'chat_message_count', 'attachment_count',
      'last_chat_message', 'creator_user', 'task', 'process');
  }

  private customerQueryFields(): Set<string> {
    return Set.of('id', 'external_id', 'name', 'email_addresses', 'phone_numbers',
      'postal_address', 'customer_id', 'customer_record_id', 'owner_users', 'owner_groups');
  }

  getSelectedIdSet(): Set<number> {
    const ids: number[] = [];
    this.taskRecordList.forEach((tr) => {
      if (tr.selected) {
        ids.push(tr.taskRecord.taskRecordId);
      }
    });
    return Set.of(...ids);
  }

  toModelArray(list: List<TaskRecord.TaskRecord>): TaskRecordListModel[] {
    const ret: TaskRecordListModel[] = [];
    list.forEach((record) => {
      if (record) {
        const stateObject = TaskRecordStateMachine.taskRecordStates.get(record.state);
        ret.push({
          taskRecord: record,
          selected: false,
          customerName: '',
          assignee: new AssigneeListModel(),
          stateObject: stateObject,
          rights: new TaskRecordRightModel(GrantedPermissionSetResolver.byGrantedRights(record.grantedRights))
        });
      }
    });
    return ret;
  }

  isOverdue(model: TaskRecordListModel): boolean {
    if (model.taskRecord.state === 'NEW' || model.taskRecord.state === 'OPEN' || model.taskRecord.state === 'IN_PROGRESS') {
      if (model.taskRecord.deadline) {
        if (Dates.now().isAfter(model.taskRecord.deadline)) {
          return true;
        }
      }
    }
    return false;
  }

  canCreateTaskRecord() {
    return this._canCreateTaskRecord;
  }

  getAssignedUserName(userId?: number): string {
    const user: AssigneeItem | undefined = this.users.find(u => u.id === userId);
    return user ? user.itemName : '';
  }

  private getTaskIcon(task_id: number): Icon.Icon | undefined {
    const filtered_task = this.tasks.find(current_task => current_task.taskId === task_id);
    if (filtered_task === undefined) {
      return undefined;
    }
    return filtered_task.icon;
  }

  getTaskName(task_id: number): string {
    const filtered_task = this.tasks.find(current_task => current_task.taskId === task_id);
    if (filtered_task === undefined) {
      return '';
    }
    return filtered_task.name;
  }

  private getProjectName(id: number): string {
    const filtered_project = this.projects.find(current_project => current_project.id === id);
    if (filtered_project === undefined) {
      return '';
    }
    return filtered_project.itemName;
  }

  getInvoiceNumber(id: number): string | undefined {
    const filtered_invoice = this.invoices.find(current_invoice => current_invoice.id === id);
    if (filtered_invoice === undefined) {
      return undefined;
    }
    return filtered_invoice.text;
  }

  isManagedField(field: TaskRecordField): boolean {
    return this.taskId ? this.taskManagedFields.contains(field) : this.configService.isTaskRecordManagedField(field);
  }

  isEnabledByServer(field: ConfigurationResource.AdminFeature): boolean {
    return ConfigurationService.isEnabledByServer(field, this.configService.getConfiguration());
  }

  toggleMap() {
    this.showMap = !this.showMap;
    if (this.showMap) {
      this.activeSearchTab = 'simple';
      this._searchTabs!.tabs[0].active = true;
      this.onSearchClicked();
    }
  }

  showDisabledDialog() {
    this.dialog.open(DemoModeFeatureDisabledDialogComponent);
  }

  ngOnDestroy() {
    this.saveSearch();
  }

  getLogTypeStringKey(logType: TaskRecord.LogType) {
    return StateChangeLabelMap.get(logType, '');
  }

  showBulkCloneDialog() {
    if (this.isAnyTaskSelected()) {
      this.bulkCloneDialog.show(this.getSelectedIdSet().toArray());
      $('#taskRecordBulkCloneDialog').appendTo($('body'));
    } else {
      this.taskRecordService.globalQuery(this.getGlobalQueryRequest()).subscribe(result => {
        this.bulkCloneDialog.show(result.items.toArray().map(tr => tr.taskRecordId));
        $('#taskRecordBulkCloneDialog').appendTo($('body'));
      });
    }
  }

  isReadable(model: TaskRecordListModel): boolean {
    if (!this.baseCustomerRecordByPhoneNumberId) {
      return true;
    }
    return model.rights.read.hasRight();
  }

  isCustomerMatching(model: TaskRecordListModel): boolean {
    return this.baseCustomerRecordByPhoneNumberId !== undefined
      && model.taskRecord.customerRecord !== undefined
      && +this.baseCustomerRecordByPhoneNumberId !== model.taskRecord.customerRecord.customerRecordId;
  }

  getPagingId(): string {
    if (this.taskRecordId) {
      return 'paging-tr-' + this.taskRecordId;
    }
    if (this.customerRecordId) {
      return 'paging-cr-' + this.customerRecordId;
    }
    if (this.baseCustomerRecordByPhoneNumberId) {
      return 'paging-cr-pn-' + this.baseCustomerRecordByPhoneNumberId;
    }
    if (this.processId) {
      return 'paging-proc-' + this.processId;
    }
    if (this.masterDataRecordId) {
      return 'paging-md-' + this.masterDataRecordId;
    }
    if (this.projectRecordId) {
      return 'paging-proj-' + this.projectRecordId;
    }
    if (this.taskId) {
      return 'paging-task-' + this.taskId;
    }
    return 'paging';
  }

  getColorIndex(model: TaskRecordListModel): number {
    return model.taskRecord.task ? model.taskRecord.task.taskRecordColor : 0;
  }

  getColor(model: TaskRecordListModel) {
    return PaletteColorPickerComponent.resolveColor(this.getColorIndex(model));
  }
}

interface InvoiceItem {
  id: number;
  text?: string;
}
