<div class="modal-content">
  <div class="modal-body p-2">
    <div class="w-100 text-center">
      <i class="icomoon icomoon-transport-state-done dialog-icon"></i>
    </div>
    <label class="w-100 dialog-title">{{titleKey | translate }}</label>

    <label class="dialog-description">{{descriptionKey | translate }}</label>

    <button type="button" class="w-100 btn btn-primary" mat-dialog-close cdkFocusInitial uiSref="Login">
      {{buttonKey | translate}}
    </button>
  </div>
</div>
