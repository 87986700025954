import * as draw2d from '../../../../../../../node_modules/draw2d/dist/draw2d.js';
import { Group } from '../shape/group';
import { Label } from '../shape/label';
import { Connection } from '../connection/connection';

export class CanvasSelectionPolicy extends draw2d.policy.canvas.CanvasPolicy {

  previousSelection: draw2d.default.util.ArrayList = new draw2d.default.util.ArrayList();

  addPreviousSelectedFigure(figure) {
    this.previousSelection.add(figure);
  }

  init(attr, setter, getter) {
    super.init(attr, setter, getter);
  }

  onMouseUp(canvas, x, y, shiftKey, ctrlKey) {
    let figure = canvas.getBestFigure(x, y);
    while (figure && (!(figure instanceof Group) && !(figure instanceof Label))) {
      figure = figure.getParent();
    }
    if (figure && !(canvas.getSelection().primary instanceof Connection) && !canvas.ignoreSelection) {
      if (shiftKey === true) {
        this.previousSelection.add(figure);
        canvas.setCurrentSelection(this.previousSelection);
      } else {
        this.previousSelection.clear();
        let connection;
        if (figure.inputPort) {
          for (let i = 0; i < figure.inputPort.connections.getSize(); i++) {
            const currentConnection = figure.inputPort.connections.get(i);
            if (currentConnection.sourcePort.parent.id === currentConnection.targetPort.parent.id) {
              connection = currentConnection;
              break;
            }
          }
        }
        if (connection) {
          canvas.remove(connection);
        }
        canvas.setCurrentSelection(figure);
        if (connection) {
          setTimeout(() => {
            canvas.add(connection);
          });
        }
      }
    }
    if (!figure) {
      this.previousSelection.clear();
    }
    canvas.ignoreSelection = false;
  }

}
