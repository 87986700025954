<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]=breadcrumbParents
      [self]="breadcrumbSelf">
    </app-breadcrumb>
    <app-breadcrumb-menu>
      <app-breadcrumb-button
        *ngIf="rightModel.companyStockUpdate.hasRight() && componentState.isDetailView()"
        [iconClass]="'icomoon-modify'"
        [titleStringKey]="'COMMON_BUTTON_EDIT'"
        uiSref="Admin.CompanyStockEdit"
        [uiParams]="{ id: componentState.id }">
      </app-breadcrumb-button>
    </app-breadcrumb-menu>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>

<div class="container-horizontal-padding container-vertical-padding-half animated fadeIn">
  <responsive-tabset class="fullscreen_tab">
    <responsive-tab heading="{{'COMMON_CARD_HEADER_BASE_DATA'|translate}}">

      <div class="container-custom-padding animated fadeIn">

        <div class="form-group row mx-0">

          <!--region Basic Details-->
          <div class="col-xs-12 col-sm-12 col-md-8 card-custom-padding">
            <div class="card">
              <div class="card-header">
                {{ getHeadingDictionaryKey() | translate}}
              </div>
              <div class="card-body">
                <form class="form-horizontal bordered-row" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>

                  <!--region Name-->
                  <div class="form-group row"
                       [ngClass]="{ 'has-danger': (hasLocalFieldError(name) || (fieldErrors.name) )}">
                    <label class="col-form-label form-control-label col-md-4 detail-title" [class.required-field-label]="componentState.isEditable()">
                      {{'COMPANY_STOCK_NAME' | translate}}
                    </label>
                    <div class="col-md-8">
                      <input *ngIf="componentState.isEditable()"
                             type="text" class="form-control"
                             maxlength="{{UiConstants.maximumVarcharLength}}"
                             placeholder="{{'COMPANY_STOCK_NAME' | translate}}"
                             [(ngModel)]="editModel.name"
                             (ngModelChange)="removeFieldError(fieldErrors.name)"
                             [ngClass]="{ 'form-control-danger': hasLocalFieldError(name) || (fieldErrors.name) }"
                             name="name"
                             id="name"
                             #name="ngModel"
                             required>
                      <div class="form-control-feedback"
                           *ngIf="fieldErrors.name">{{fieldErrors.name.text}}</div>
                      <div class="form-control-feedback"
                           *ngIf="hasLocalFieldError(name)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.name}}
                      </label>
                    </div>
                  </div>
                  <!--endregion Name-->

                  <!--region ExternalId-->
                  <div class="form-group row"
                       [ngClass]="{ 'has-danger': hasLocalFieldError(externalId) || (fieldErrors.external_id) }">
                    <label class="col-form-label form-control-label col-md-4 detail-title" [class.required-field-label]="componentState.isEditView()">
                      {{'COMMON_EXTERNAL_ID' | translate}}
                    </label>
                    <div class="col-md-8">
                      <input *ngIf="componentState.isEditable()"
                             type="text" class="form-control"
                             maxlength="{{UiConstants.maximumVarcharLength}}"
                             placeholder="{{'COMMON_EXTERNAL_ID' | translate}}"
                             [(ngModel)]="editModel.externalId"
                             (ngModelChange)="removeFieldError(fieldErrors.external_id)"
                             [ngClass]="{ 'form-control-danger': hasLocalFieldError(externalId) || (fieldErrors.external_id) }"
                             name="externalId"
                             id="externalId"
                             #externalId="ngModel"
                             [required]="componentState.isEditView()"
                      >
                      <div class="form-control-feedback"
                           *ngIf="fieldErrors.external_id">{{fieldErrors.external_id.text}}</div>
                      <div class="form-control-feedback"
                           *ngIf="hasLocalFieldError(externalId)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.externalId}}
                      </label>
                    </div>
                  </div>
                  <!--endregion ExternalId-->

                  <!--region LocationName-->
                  <div class="form-group row"
                       [ngClass]="{ 'has-danger': (hasLocalFieldError(selectedLocation) || (fieldErrors.company_location) )}">
                    <label
                      class="col-form-label form-control-label col-md-4 detail-title" [class.required-field-label]="componentState.isEditable()">
                      {{'COMPANY_STOCK_LOCATION_NAME' | translate}}
                    </label>
                    <div class="col-md-8">
                      <ng-container *ngIf="componentState.isEditable()">
                        <angular2-multiselect
                          class="form-control"
                          [(ngModel)]="editModel.location"
                          [data]="locationItems"
                          [settings]="dropdownSettings"
                          (onRemoteSearch)="loadLocations($event.target.value)"
                          name="selectedLocation"
                          id="selectedLocation"
                          #selectedLocation="ngModel"
                          validateEnabledItems
                          required>
                        </angular2-multiselect>
                        <div class="form-control-feedback"
                             *ngIf="fieldErrors.company_location">{{fieldErrors.company_location.text}}
                        </div>
                        <div class="form-control-feedback"
                             *ngIf="hasLocalFieldError(selectedLocation) && selectedLocation.errors['required']">
                          {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                        <div class="form-control-feedback"
                             *ngIf="hasLocalFieldError(selectedLocation) && selectedLocation.errors['hasDisabledItem']">
                          {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
                        </div>
                      </ng-container>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.locationName}}
                      </label>
                    </div>
                  </div>
                  <!--endregion LocationName-->

                  <!--region UserGroup-->
                  <div class="form-group row"
                       [ngClass]="{ 'has-danger': hasLocalFieldError(selectedUserGroup) || (fieldErrors.assignee_user_group_id)}">
                    <label
                      class="col-form-label form-control-label col-md-4 detail-title" [class.required-field-label]="componentState.isEditable()">
                      {{'COMMON_USER_GROUP' | translate}}
                    </label>
                    <div class="col-md-8">
                      <ng-container *ngIf="componentState.isEditable()">
                        <angular2-multiselect
                          class="form-control"
                          [(ngModel)]="editModel.userGroup"
                          [data]="userGroupList"
                          [settings]="dropdownSettings"
                          (onRemoteSearch)="loadUserGroups($event.target.value)"
                          name="selectedUserGroup"
                          id="selectedUserGroup"
                          #selectedUserGroup="ngModel"
                          validateEnabledItems
                          required>
                        </angular2-multiselect>
                        <div class="form-control-feedback"
                             *ngIf="fieldErrors.assignee_user_group_id">{{fieldErrors.assignee_user_group_id.text}}
                        </div>
                        <div class="form-control-feedback"
                             *ngIf="hasLocalFieldError(selectedUserGroup) && selectedUserGroup.errors['required']">
                          {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                        <div class="form-control-feedback"
                             *ngIf="hasLocalFieldError(selectedUserGroup) && selectedUserGroup.errors['hasDisabledItem']">
                          {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
                        </div>
                      </ng-container>
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.userGroupName}}
                      </label>
                    </div>
                  </div>
                  <!--endregion UserGroup-->


                  <!--Only visible on create/edit views-->
                  <div class="col-md-12 d-flex justify-content-end p-0 pl-1" *ngIf="componentState.isEditable()">
                    <div>
                      <input type="button" class="btn btn-secondary" (click)="back()"
                             value="{{'COMMON_BUTTON_CANCEL'|translate}}"/>
                      <input type="submit" class="btn btn-primary" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <!-- endregion Basic Details-->

        </div>
        <!-- /.form-group row -->


      </div>
    </responsive-tab>
    <responsive-tab *ngIf="rightModel.companyStockHistoryLog.hasRight() && componentState.isDetailView()"
           heading="{{'COMMON_HISTORY'|translate}}">
      <app-history-log
        [queryModel]="historyQueryModel"
        [Type]="CompanyStock"
        [historyList]="historyList"
        (openHistoryDetail)="historyDetailModal.openDetailDialog($event)"
        (itemsPerPageChangedOut)="itemsPerPageChanged($event)"
        (orderByOut)="orderBy($event)"
        (pageChangedOut)="pageChanged($event)"
        #historyComponent>
      </app-history-log>
    </responsive-tab>
  </responsive-tabset>
</div>

<app-history-detail-dialog
  #historyDetailModal>
</app-history-detail-dialog>
