import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { SearchUtils } from '../../../util/search-utils';
import { WorklogSearch, WorklogSearchService } from '../../../lib/worklog/worklog-search-service';
import { MultiselectOptionItem, OrderFieldFunction, OrderFieldModel } from '../../../util/core-utils';
import { OrderType, QueryResult } from '../../../lib/util/services';
import { Angular2Multiselects } from '../../../util/multiselect';
import { UserService } from '../../../lib/user.service';
import { RightResolver, RightService } from '../../../lib/right.service';
import { CriteriaBuilder, Models } from '../../../util/model-utils';
import { Worklog, WorklogService } from '../../../lib/worklog/worklog.service';
import { OrderField } from '../../../lib/query/orderfields';
import { TaskRecordService } from '../../../lib/task/task-record.service';
import { FilterField } from '../../../lib/query/filterfields';
import * as moment from 'moment';
import { Duration } from 'moment';
import { DownloadedFile } from '../../../lib/util/downloaded-files';
import { saveAs } from 'file-saver';
import { TaskRecordMultiselectProvider } from '../../../lib/task/task-record-multiselect.provider';
import { UserMultiselectProvider } from '../../../lib/user/user-multiselect.provider';
import { RightModel } from '../../../app.rights';
import { AssigneeType } from '../../../shared/assignee-table-cell/assignee-table-cell.component';
import { UserGroupMultiselectProvider } from '../../../lib/user-group/user-group-multiselect.provider';
import { Arrays } from '../../../lib/util/arrays';
import { MatDialog } from '@angular/material/dialog';
import { WorklogMaterialDialogComponent } from '../worklog-material-dialog/worklog-material-dialog.component';
import WorklogUserType = Worklog.WorklogUserType;
import WorklogScope = Worklog.WorklogScope;
import { ConfigurationService } from '../../../lib/core-ext/configuration.service';
import { TaskRecordStateMachine } from '../../../lib/task/task-record-statemachine';
import {TaskMultiselectProvider} from "../../../lib/task/task-multiselect.provider";

@Component({
  selector: 'app-worklog-list',
  templateUrl: './worklog-list.component.html',
  styleUrls: ['./worklog-list.component.scss']
})
export class WorklogListComponent extends SearchUtils.SearchableList<WorklogSearch.Model> implements OnInit, OnDestroy {
  Worklog = Worklog;
  WorklogScope = Worklog.WorklogScope;
  worklogUserTypes = Worklog.worklogUserTypes;
  OrderField = OrderField;
  AssigneeType = AssigneeType;

  @Input()
  scope?: Worklog.WorklogScope;

  @Input()
  resourceId?: number;

  @Input()
  resourceParentId?: number;

  @Input()
  editable: boolean = false;

  @Input()
  createEnabled: boolean = false;

  searchModel: WorklogSearch.Model = new WorklogSearch.Model();
  queryModel: OrderFieldModel<OrderField.Worklog>
    = new OrderFieldModel(Worklog.OrderFunctions.ID, OrderType.DESC);

  worklogList: Worklog.Worklog[] = [];
  relatedUserGroups: MultiselectOptionItem<number>[] = [];

  taskRecordStates: MultiselectOptionItem<TaskRecordStateMachine.State>[] = [];
  taskRecordList: MultiselectOptionItem<number>[] = [];
  taskList: MultiselectOptionItem<number>[] = [];
  workerUserList: MultiselectOptionItem<number>[] = [];
  loggerUserList: MultiselectOptionItem<number>[] = [];
  dropdownSettings: Angular2Multiselects.Settings;
  multiDropdownSettings: Angular2Multiselects.Settings = Angular2Multiselects.REMOTE_MULTI_SELECT;
  localDropdownSettings: Angular2Multiselects.Settings = Angular2Multiselects.LOCAL_MULTI_SELECT;

  rightModel: RightModel = RightModel.empty();

  get deletable() {
    return this.editable && this.scope === WorklogScope.TASK_RECORD;
  }

  get creatable() {
    return this.scope === WorklogScope.TASK_RECORD
    && this.resourceParentId !== undefined && this.resourceId !== undefined && this.createEnabled;

  }

  get maconomyEnabled(): boolean {
    return this.configurationService.getConfiguration().feature_flags.maconomy_enabled;
  }

  constructor(
    private userService: UserService,
    private rightService: RightService,
    private taskRecordService: TaskRecordService,
    private worklogService: WorklogService,
    private searchService: WorklogSearchService,
    private taskRecordMultiselectProvider: TaskRecordMultiselectProvider,
    private taskMultiselectProvider: TaskMultiselectProvider,
    private userMultiselectProvider: UserMultiselectProvider,
    private userGroupMultiselectProvider: UserGroupMultiselectProvider,
    private dialog: MatDialog,
    private configurationService: ConfigurationService,
    injector: Injector
  ) {
    super(WorklogSearch.Model, injector);
  }

  ngOnInit() {
    this.loadTaskRecordStatesForSearch();
    this.loadRightModels();
    this.initSearch();
    this.initDropDown();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  initDropDown() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .remoteSearch(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .build();
  }

  onFirstSearchOpen(): void {
  }

  loadSearch(completion: () => void) {
    this.searchService.getSearchData({
      scope: this.scope,
      resourceId: this.resourceId
    }).subscribe(
      (result: WorklogSearch.SearchDataResult) => {
        this.queryModel = result.searchData.queryModel;
        this.searchModel = result.searchData.searchModel;
        completion();
      }
    );
  }

  loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const request = this.createRequest(pageNumber);
    this.worklogService.query(request).subscribe((result: QueryResult<Worklog.Worklog>) => {
      this.worklogList = result.items.toArray();
      this.loadRelatedUserGroups();
      this.queryModel.currentPage = requestedPage;
      this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
    });
  }

  private loadRelatedUserGroups() {
    this.userGroupMultiselectProvider.getByIds(
      Arrays.flatten(this.worklogList.map(w => w.workerUser.userGroupIds))
    ).subscribe(result => this.relatedUserGroups = result);
  }


  private loadTaskRecordStatesForSearch() {
    this.taskRecordStates = [];
    TaskRecordStateMachine.getOrderedStates(true).forEach(
      (state?: TaskRecordStateMachine.StateObject) => {
        if (state) {
            const item1 = {
              id: state.state,
              itemName: '...'
            };
            this.taskRecordStates.push(item1);
            this.translateService.get(state.stringKey).subscribe((text: string) => {
              item1.itemName = text;
            });
        }
      });
  }

  exportXls() {
    const request = this.createRequest();
    this.worklogService.exportXls(request).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName('worklog.xlsx'));
      }
    );
  }

  exportMaconomy() {
    const request = this.createRequest();
    this.worklogService.exportMaconomy(request).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName('worklog-maconomy.xlsx'));
      }
    );
  }

  private createRequest(pageNumber?: number): Worklog.QueryRequest {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.createOrderFunction();
    const filter = this.createFilter();
    return {
      worklogScope: this.scope,
      userId: this.scope === Worklog.WorklogScope.USER ? this.resourceId : undefined,
      taskRecordId: this.scope === Worklog.WorklogScope.TASK_RECORD ? this.resourceId : undefined,
      fields: f => f.each,
      order: order,
      filter: filter,
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined
    };
  }

  private createFilter() {
    return (f: FilterField.Worklog) => CriteriaBuilder.builder()
      .addNumber((taskRecordId) => f.taskRecord.id.eq(taskRecordId), this.searchModel.taskRecordId)
      .addNumber((loggerUserId) => f.loggerUser.id.eq(loggerUserId), this.searchModel.loggerUserId)
      .addNumber((workerUserId) => f.workerUser.id.eq(workerUserId), this.searchModel.workerUserId)
      .addNumberList(taskIds => f.taskRecord.task.id.in(taskIds), this.searchModel.taskIds)
      .addNumber(
        (loggedTimeInMinutes) => f.loggedTimeInMinutes.eq(loggedTimeInMinutes),
        Models.parseNumber(this.searchModel.loggedTimeInMinutes))
      .addNumber(
        (estimatedTimeInMinutes) => f.estimatedTimeInMinutes.eq(estimatedTimeInMinutes),
        Models.parseNumber(this.searchModel.estimatedTimeInMinutes))
      .addEnum<WorklogUserType>((worklogUserType) => f.worklogUserType.eq(worklogUserType), this.searchModel.worklogUserType)
      .addEnum(taskRecordState => f.taskRecord.state.in(taskRecordState), this.searchModel.taskRecordStates)
      .addDateTime((startTime) => f.startTime.after(startTime), Models.parseDateTimeFrom(this.searchModel.startTimeFrom))
      .addDateTime((startTime) => f.startTime.before(startTime), Models.parseDateTimeTo(this.searchModel.startTimeTo))
      .addDateTime((endTime) => f.endTime.after(endTime), Models.parseDateTimeFrom(this.searchModel.endTimeFrom))
      .addDateTime((endTime) => f.endTime.before(endTime), Models.parseDateTimeTo(this.searchModel.endTimeTo))
      .build();
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: OrderFieldFunction<OrderField.Worklog>) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  onSearchClicked() {
    this.loadList(1);
  }

  onSearchReset() {
    this.searchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.toggleSearch();
          this.loadList(1);
        });
      }
    );
  }

  private saveSearch() {
    const request = {
      scope: this.scope,
      resourceId: this.resourceId,
      searchData: {
        queryModel: this.queryModel,
        searchModel: this.searchModel
      }
    };
    this.searchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  onTaskRecordSearch(predicate?: string) {
    this.taskRecordMultiselectProvider.loadAll(predicate).subscribe((items) => {
      this.taskRecordList = items;
    })
  }

  onTaskSearch(predicate?: string) {
    this.taskMultiselectProvider.loadAll(predicate).subscribe((items) => {
      this.taskList = items;
    })
  }

  onLoggerUserSearch(predicate?: string) {
    this.userMultiselectProvider.loadAll(predicate).subscribe((items) => {
      this.loggerUserList = items;
    })
  }

  onWorkerUserSearch(predicate?: string) {
    this.userMultiselectProvider.loadAll(predicate).subscribe((items) => {
      this.workerUserList = items;
    })
  }

  getLoggedDuration(minutes?: number): Duration | undefined {
    if (minutes) {
      return moment.duration(minutes, 'minutes');
    }
    return undefined;
  }

  getCalculatedDuration(log: Worklog.Worklog): Duration {
    return moment.duration(moment(log.endTime.toUtcIsoString()).diff(log.startTime.toUtcIsoString()));
  }

  ngOnDestroy() {
    this.saveSearch();
  }

  createWorklog() {
    WorklogMaterialDialogComponent.openDialog(this.dialog, {
      taskId: this.scope === WorklogScope.TASK_RECORD ? this.resourceParentId : undefined,
      taskRecordId: this.scope === WorklogScope.TASK_RECORD ? this.resourceId : undefined,
      readonly: false
    }, (result) => {
      if (result && result.success) {
        this.loadList();
      }
    })
  }

  updateWorklog(log: Worklog.Worklog) {
    WorklogMaterialDialogComponent.openDialog(this.dialog, {
      worklog: log,
      taskId: this.scope === WorklogScope.TASK_RECORD ? this.resourceParentId : undefined,
      taskRecordId: this.scope === WorklogScope.TASK_RECORD ? this.resourceId : undefined,
      readonly: false
    }, (result) => {
      if (result && result.success) {
        this.loadList();
      }
    })
  }

  deleteWorklog(log: Worklog.Worklog) {
    if (this.scope !== WorklogScope.TASK_RECORD
      || !this.resourceParentId || !this.resourceId) {
      return;
    }
    this.worklogService.delete({id: log.id, taskId: this.resourceParentId, taskRecordId: this.resourceId}).subscribe(() => {
      this.loadList();
    })
  }
}
