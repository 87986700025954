/* eslint-disable */
import { FieldDataTypeSelectors, FormRecordFieldView, FormRecordFieldViewFactory, } from './form-utils';
import { ComponentFactoryResolver, ComponentRef, Injector, } from '@angular/core';
import { FormRecordFormTableFieldComponent } from '../../admin/form/form-record/fields/form-table/form-record-form-table-field.component';
/* eslint-enable */

export class FormRecordFormTableFieldViewFactory implements FormRecordFieldViewFactory {

  constructor(
    private selectors: FieldDataTypeSelectors,
    private injector: Injector,
    private cfr: ComponentFactoryResolver) {
  }

  createView(): ComponentRef<FormRecordFieldView> {
    const cmp = this.cfr.resolveComponentFactory(FormRecordFormTableFieldComponent)
      .create(this.injector);
    cmp.instance.registerSelectors(this.selectors);
    return cmp;
  }

}
