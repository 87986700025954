<div class="btn-group" dropdown placement="bottom right" *ngIf="!readonly">
  <button dropdownToggle type="button" class="color-picker-button cursor-pointer" [style.background-color]="_color">
  </button>
  <div id="dropdown-triggers-manual" *dropdownMenu class="dropdown-menu"
       role="menu" aria-labelledby="button-triggers-manual">
    <color-circle [color]="_color" (onChangeComplete)="onColorChanged($event)" [colors]="colors"></color-circle>
  </div>
</div>
<button type="button" class="color-picker-button color-picker-button-readonly" [style.background-color]="_color" *ngIf="readonly">
</button>
