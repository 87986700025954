/* eslint-disable */
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../../../lib/auth.service';
import { QueryFieldModel, SelectUtils, UiConstants } from '../../../../util/core-utils';
import { DocumentFileListModel, DocumentFileSearchModel } from '../../../../util/document/document-file-utils';
import { DocumentGroup, DocumentGroupService } from '../../../../lib/document/document-group.service';
import { Arrays } from '../../../../lib/util/arrays';
import { DocumentFile, DocumentFileService } from '../../../../lib/document/document-file.service';
import { TranslateService } from '@ngx-translate/core';
import { UploadErrorLocalizer } from '../../../../util/upload-error-localizer';
import { RightModel } from '../../../../app.rights';
import { RightResolver, RightService } from '../../../../lib/right.service';
import { combineLatest, Observable } from 'rxjs';
import { Set } from 'immutable';
import { DocumentFileSearch, DocumentFileSearchService } from '../../../../lib/document/document-file-search.service';
import { OrderType, ResourceQueryResult, Services } from '../../../../lib/util/services';
import { StateName } from '../../../../app.state-names';
import { BreadcrumbParent } from '../../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { EmptyMessage } from '../../../../lib/util/messages';
import { DisabledEnum, DisabledItem } from '../../../../util/search-utils';
import { DropdownItemType } from '../../../../shared/dropdown/dropdown-item/dropdown-item-type';
/* eslint-enable */

@Component({
  selector: 'app-document-file-list',
  templateUrl: 'document-file-list.component.html',
  styleUrls: ['document-file-list.component.scss']
})
export class DocumentFileListComponent implements AfterViewInit, OnInit, OnDestroy {
  DocumentFile = DocumentFile;
  UiConstants = UiConstants;
  DropdownItemType = DropdownItemType;

  @ViewChild('searchIcon', { static: true }) searchIcon: ElementRef;
  rightModel: RightModel = RightModel.empty();

  queryModel: QueryFieldModel<DocumentFile.OrderField> = new QueryFieldModel(DocumentFile.OrderField.ID, OrderType.DESC);
  documentListModel: DocumentFileListModel[] = [];
  disabledItems: DisabledItem[] = [];
  documentGroupList: DocumentGroup[] = [];

  searchModel: DocumentFileSearchModel = new DocumentFileSearchModel();
  showSearch: boolean = false;
  breadcrumbSelf: string;
  breadcrumbParents: BreadcrumbParent[] = [];
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  SelectUtils = SelectUtils;

  ngOnInit() {
    this.translateService.get('COMMON_DOCUMENTS').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.DOCUMENT_DASHBOARD});
      }
    );
    this.translateService.get('MENU_NAVBAR_DOCUMENT_FILE').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.loadSearch(() => {
      this.showSearch = !this.searchModel.isEmpty();
      this.initDisabledOptions(DisabledEnum.FALSE);
      this.loadList();
    });
    this.loadRightModels();
  }

  private loadSearch(completion: () => void) {
    const obs: Observable<SearchLoadResult> = combineLatest(
      this.documentFileSearchService.getSearchData({}),
      (storedSearchData: DocumentFileSearch.SearchDataResult) => {
        const result: SearchLoadResult = {
          storedSearchData: storedSearchData
        };
        return result;
      }
    );
    obs.subscribe(
      (result: SearchLoadResult) => {
        this.postInitSearch(result.storedSearchData);
        completion();
      }
    );
  }

  private postInitSearch(storedSearchData: DocumentFileSearch.SearchDataResult) {
    this.queryModel.itemsPerPage = storedSearchData.searchData.itemsPerPage;
    this.queryModel.currentPage = storedSearchData.searchData.pageNumber;
    this.queryModel.setOrder(storedSearchData.searchData.order);
    this.searchModel.name = storedSearchData.searchData.name;
    this.searchModel.documentGroupId = storedSearchData.searchData.documentGroupId;
    this.searchModel.disabled = storedSearchData.searchData.disabled;
  }

  private getActiveItem(): DisabledItem {
    const item = new DisabledItem();
    item.id = DisabledEnum.FALSE;
    item.text = 'COMMON_VALUE_DISABLED_ENUM_0';
    return item;
  }

  ngAfterViewInit(): void {
    this.loadDefaultDocumentGroup(() => {
      this.loadDocumentGroups();
    });
    this.loadList();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  private initDisabledOptions(initValue: DisabledEnum) {
    this.disabledItems = [];
    const disabledEnums: DisabledEnum[] = [DisabledEnum.NONE, DisabledEnum.FALSE, DisabledEnum.TRUE];
    Arrays.iterateByIndex(disabledEnums, (key) => {
      const item = new DisabledItem();
      item.id = key;
      this.translateService.get('COMMON_VALUE_DISABLED_ENUM_' + key).subscribe(
        (text: string) => {
          item.text = text;
        }
      );
      this.disabledItems.push(item);
      if (!this.searchModel.disabled) {
        if (key === initValue) {
          this.searchModel.disabled = item;
        }
      }
    });
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: DocumentFile.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  private loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();
    const disabled: boolean | undefined = !this.searchModel.disabled ||
    this.searchModel.disabled.id === DisabledEnum.NONE ?
      undefined : this.searchModel.disabled.id === DisabledEnum.TRUE;
    let name: string | undefined = undefined;
    if (this.searchModel.name) {
      name = this.searchModel.name
    }

    let group: number | undefined = undefined;
    if (this.searchModel.group && this.searchModel.group.id) {
      group = (this.searchModel.group.id === null) ? undefined : this.searchModel.group.id;
    }
    else if ((!this.searchModel.group || !this.searchModel.group.id) && this.searchModel.documentGroupId) {
      group = this.searchModel.documentGroupId;
    }
    this.fileDocumentService.query({
      name: name,
      document_group_id: group,
      disabled: disabled,
      page_number: requestedPage,
      number_of_items: this.queryModel.itemsPerPage,
      order: Services.createOrderFieldParameter(DocumentFile.Keys.toOrderFieldKey, Set.of(order)),
    })
    .subscribe(
      (result: ResourceQueryResult<DocumentFile>) => {
        this.documentListModel = result.items.map((document) => {
          return DocumentFileListModel.fromApi(document);
        });
        this.queryModel.currentPage = requestedPage;
        this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
        this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
      }
    );
  }

  getDisabled(): boolean | undefined {
    if (this.searchModel.disabled!.id === DisabledEnum.FALSE) {
      return false;
    }
    else if (this.searchModel.disabled!.id === DisabledEnum.TRUE) {
      return true;
    }
    return undefined;
  }

  onSearchClicked() {
    this.loadList(1);
  }

  onSearchReset() {
    this.documentFileSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.showSearch = true;
          this.searchModel.group = null;
          this.loadList(1);
        });
      }
    );
  }

  private saveSearch() {
    const request = {
      searchData: {
        itemsPerPage: this.queryModel.itemsPerPage,
        pageNumber: this.queryModel.currentPage,
        order: this.queryModel.getOrder(),
        name: this.searchModel.name,
        documentGroupId: this.searchModel.group ? this.searchModel.group.id : null,
        disabled: this.searchModel.disabled
      }
    };
    this.documentFileSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  getDocumentGroupName(documentGroupId: number | null): string {
    let groupName = '';
    if (!documentGroupId) {
      return groupName;
    }
    Arrays.iterateByIndex(this.documentGroupList, (userGroup) => {
      if (userGroup.id === documentGroupId ) {
        groupName = userGroup.name;
      }
    });

    return groupName;
  }

  loadDefaultDocumentGroup(completion: () => void) {
    this.translateService.get('DOCUMENT_GROUP_FILTER_ALL').subscribe(
      (text: string) => {
        const allGroup: DocumentGroup = {
          id: null,
          name: text,
          code: '',
          language_code: '',
          parent_id: 0,
          user_group_id: [],
          creation_time: '',
          update_time: '',
          disabled: false
        };
        this.documentGroupList.push(allGroup);
        completion();
      }
    );
  }

  loadDocumentGroups() {
    this.documentGroupService.query({
      order: Services.createOrderFieldParameter(DocumentFile.Keys.toOrderFieldKey,
        Set.of({field: DocumentFile.OrderField.NAME, type: OrderType.ASC}))
    }).subscribe(
      (groupList: ResourceQueryResult<DocumentGroup>) => {
        groupList.items.forEach( group => {
          this.documentGroupList.push(group);
          if (group.id === this.searchModel.documentGroupId) {
            this.searchModel.group = group;
          }
        });
      }
    );
  }

  setDisabled(event: any, documentModel: DocumentFileListModel, disabled: boolean) {
    this.fileDocumentService.setDisabled({
      id: documentModel.id,
      disabled: disabled
    })
      .subscribe(
      (result: EmptyMessage) => {
        this.loadList();
      },
      (error: any) => {
        this.loadList();
      }
    );
  }

  ngOnDestroy() {
    this.saveSearch();
  }

  constructor(
    private translateService: TranslateService,
    private documentGroupService: DocumentGroupService,
    private uploadErrorLocalizer: UploadErrorLocalizer,
    private authService: AuthService,
    private rightService: RightService,
    private fileDocumentService: DocumentFileService,
    private documentFileSearchService: DocumentFileSearchService) {}

}

interface SearchLoadResult {
  storedSearchData: DocumentFileSearch.SearchDataResult,
}
