import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TaskRecordSubTaskService } from '../../../../../lib/task/task-record-subtask/task-record-sub-task.service';
import { UiConstants } from '../../../../../util/core-utils';
import { StringKey } from '../../../../../app.string-keys';
import { TranslateService } from '@ngx-translate/core';
import { ErrorMessageService } from '../../../../../lib/error-message-parser.service';
import { ToasterService } from '../../../../../fork/angular2-toaster/src/toaster.service';

@Component({
  selector: 'app-task-record-sub-task-delete-dialog',
  templateUrl: './task-record-sub-task-delete-dialog.component.html',
  styleUrls: ['./task-record-sub-task-delete-dialog.component.scss']
})
export class TaskRecordSubTaskDeleteDialogComponent implements OnInit {
  UiConstants = UiConstants;

  @Input()
  taskId: number;

  @Input()
  taskRecordId: number;

  @Output()
  onSubTaskDeleted: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('deleteSubTaskDialog', { static: true }) deleteSubTaskDialog: ModalDirective;
  deleteSubTaskDialogVisible = false;
  subTaskIdToDelete: number;

  constructor(private taskRecordSubTaskService: TaskRecordSubTaskService,
              private translateService: TranslateService,
              private errorMessageService: ErrorMessageService,
              private toasterService: ToasterService) {

  }

  ngOnInit() {
  }

  showDeleteSubTaskDialog(subTaskId: number) {
    this.subTaskIdToDelete = subTaskId;
    this.deleteSubTaskDialogVisible = true;
    this.deleteSubTaskDialog.show();
  }

  closeDeleteSubTaskDialog() {
    this.deleteSubTaskDialogVisible = false;
    this.deleteSubTaskDialog.hide();
  }

  deleteSubTask() {
    this.taskRecordSubTaskService.delete({
      id: this.subTaskIdToDelete,
      taskId: this.taskId,
      taskRecordId: this.taskRecordId
    }).subscribe(() => {
      this.closeDeleteSubTaskDialog();
      this.onSubTaskDeleted.emit();
    })
  }

}
