import { DqlQuery, Query } from '../field';

export class ParcelCollectionPoint {

  readonly id: Query.OrderField;
  readonly creationTime: Query.OrderField;
  readonly updateTime: Query.OrderField;
  readonly name: Query.OrderField;
  readonly code: Query.OrderField;

  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this.id = new DqlQuery.OrderField(prefix + 'id');
    this.creationTime = new DqlQuery.OrderField(prefix + 'creation_time');
    this.updateTime = new DqlQuery.OrderField(prefix + 'update_time');
    this.name = new DqlQuery.OrderField(prefix + 'name');
    this.code = new DqlQuery.OrderField(prefix + 'code');
  }

}
