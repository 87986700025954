<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding-half animated fadeIn">
  <form (ngSubmit)="update()" [formGroup]="formGroup">
    <responsive-tabset class="fullscreen_tab">
      <responsive-tab heading="{{'SETTINGS_GENERAL'|translate}}"
                      [customClass]="isGeneralTabValid() ? '' : 'danger'">
        <div class="card mb-05">
          <div class="card-header">
            {{'SETTINGS_GENERAL' | translate}}
          </div>
          <div class="card-body">
            <div class="form-horizontal bordered-row">

              <!--region EnvironmentName-->
              <div class="form-group row">
                <label class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_ENVIRONMENT_NAME' |
                  translate}}</label>
                <div class="col-md-6">
                  <input type="text" class="form-control"
                         placeholder="{{'SETTINGS_ENVIRONMENT_NAME' | translate}}"
                         [(ngModel)]="model.environmentName.value"
                         [readOnly]="isReadonly(model.environmentName.key)"
                         [ngModelOptions]="{standalone: true}"
                         name="environmentName"
                         id="environmentName"
                         #environmentName="ngModel">
                </div>
              </div>
              <!--endregion EnvironmentName-->

              <!--region SingleUserMode-->
              <div class="form-group row">
                <label class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_SINGLE_USER_MODE' |
                  translate}}</label>
                <div class="col-md-6">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.singleUserMode.value"
                           [disabled]="isReadonly(model.singleUserMode.key)">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>
              <!--endregion SingleUserMode-->

              <!--region SingleUserModeTwoWay-->
              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_SINGLE_USER_MODE_TWO_WAY' |
                  translate}}</label>
                <div class="col-md-6">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.singleUserModeTwoWay.value"
                           [disabled]="isReadonly(model.singleUserModeTwoWay.key)">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>
              <!--endregion SingleUserModeTwoWay-->

              <!--region DemoModeEnabled-->
              <div class="form-group row">
                <label class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_DEMO_MODE_ENABLED' |
                  translate}}</label>
                <div class="col-md-6">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.demoModeEnabled.value"
                           [disabled]="isReadonly(model.demoModeEnabled.key)">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>
              <!--endregion DemoModeEnabled-->

              <!--region HelpdeskEnabled-->
              <div class="form-group row">
                <label class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_HELPDESK_ENABLED' |
                  translate}}</label>
                <div class="col-md-6">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.helpdeskEnabled.value"
                           [disabled]="isReadonly(model.helpdeskEnabled.key)">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>
              <!--endregion HelpdeskEnabled-->

              <!--region RegisterMobileApplicationAsDisabled-->
              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_REGISTER_MOBILE_APPLICATION_AS_DISABLED' |
                  translate}}</label>
                <div class="col-md-6">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.registerMobileApplicationAsDisabled.value"
                           [disabled]="isReadonly(model.registerMobileApplicationAsDisabled.key)">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>
              <!--endregion RegisterMobileApplicationAsDisabled-->

              <!--region AcceptOnlyComplexAddress-->
              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_ACCEPT_ONLY_COMPLEX_ADDRESS' |
                  translate}}</label>
                <div class="col-md-6">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.acceptOnlyComplexAddress.value"
                           [disabled]="isReadonly(model.acceptOnlyComplexAddress.key)">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>
              <!--endregion AcceptOnlyComplexAddress-->

              <!--region AcceptOnlyValidZipCodeFormat-->
              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_ACCEPT_ONLY_VALID_ZIP_CODE_FORMAT' |
                  translate}}</label>
                <div class="col-md-6">
                  <label *ngIf="isReadonly(model.acceptOnlyValidZipCodeFormat.key)"
                         class="col-form-label form-control-label detail-description">
                    {{getMultiselectDataReadonlyLabel(model.acceptOnlyValidZipCodeFormat.value)}}
                  </label>
                  <angular2-multiselect [data]="countryItems" [settings]="multiselectDropdownSettings"
                                        class="form-control"
                                        [(ngModel)]="model.acceptOnlyValidZipCodeFormat.value"
                                        [ngModelOptions]="{standalone: true}"
                                        [readonly]="isReadonly(model.acceptOnlyValidZipCodeFormat.key)"
                                        name="acceptOnlyValidZipCodeFormat"
                                        id="acceptOnlyValidZipCodeFormat"
                                        #acceptOnlyValidZipCodeFormat="ngModel">
                  </angular2-multiselect>
                </div>
              </div>
              <!--endregion AcceptOnlyValidZipCodeFormat-->

              <!--region AcceptOnlyExistingCityInCountries-->
              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_ACCEPT_ONLY_EXISTING_CITY_IN_COUNTRIES' |
                  translate}}</label>
                <div class="col-md-6">
                  <label *ngIf="isReadonly(model.acceptOnlyExistingCityInCountries.key)"
                         class="col-form-label form-control-label detail-description">
                    {{getMultiselectDataReadonlyLabel(model.acceptOnlyExistingCityInCountries.value)}}
                  </label>
                  <angular2-multiselect [data]="countryItems" [settings]="multiselectDropdownSettings"
                                        class="form-control"
                                        [(ngModel)]="model.acceptOnlyExistingCityInCountries.value"
                                        [ngModelOptions]="{standalone: true}"
                                        [readonly]="isReadonly(model.acceptOnlyExistingCityInCountries.key)"
                                        name="acceptOnlyExistingCityInCountries"
                                        id="acceptOnlyExistingCityInCountries"
                                        #acceptOnlyExistingCityInCountries="ngModel">
                  </angular2-multiselect>
                </div>
              </div>
              <!--endregion AcceptOnlyExistingCityInCountries-->

              <!--region AcceptOnlyExistingStreetTypeInCountries-->
              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_ACCEPT_ONLY_EXISTING_STREET_TYPE_IN_COUNTRIES' |
                  translate}}</label>
                <div class="col-md-6">
                  <label *ngIf="isReadonly(model.acceptOnlyExistingStreetTypeInCountries.key)"
                         class="col-form-label form-control-label detail-description">
                    {{getMultiselectDataReadonlyLabel(model.acceptOnlyExistingStreetTypeInCountries.value)}}
                  </label>
                  <angular2-multiselect [data]="countryItems" [settings]="multiselectDropdownSettings"
                                        class="form-control"
                                        [(ngModel)]="model.acceptOnlyExistingStreetTypeInCountries.value"
                                        [ngModelOptions]="{standalone: true}"
                                        [readonly]="isReadonly(model.acceptOnlyExistingStreetTypeInCountries.key)"
                                        name="acceptOnlyExistingStreetTypeInCountries"
                                        id="acceptOnlyExistingStreetTypeInCountries"
                                        #acceptOnlyExistingStreetTypeInCountries="ngModel">
                  </angular2-multiselect>
                </div>
              </div>
              <!--endregion AcceptOnlyExistingStreetTypeInCountries-->

              <!--region DefaultSelectedStreetType-->
              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_DEFAULT_SELECTED_STREET_TYPE' |
                  translate}}</label>
                <div class="col-md-6">
                  <input type="text" class="form-control"
                         placeholder="{{'SETTINGS_DEFAULT_SELECTED_STREET_TYPE' | translate}}"
                         [(ngModel)]="model.defaultSelectedStreetType.value"
                         [readOnly]="isReadonly(model.defaultSelectedStreetType.key)"
                         [ngModelOptions]="{standalone: true}"
                         name="defaultSelectedStreetType"
                         id="defaultSelectedStreetType"
                         #defaultSelectedStreetType="ngModel">
                </div>
              </div>
              <!--endregion DefaultSelectedStreetType-->

              <!--region DefaultSelectedCountryCode-->
              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_DEFAULT_SELECTED_COUNTRY_CODE' |
                  translate}}</label>
                <div class="col-md-6">
                  <label *ngIf="isReadonly(model.defaultSelectedCountryCode.key)"
                         class="col-form-label form-control-label detail-description">
                    {{getMultiselectDataReadonlyLabel(model.defaultSelectedCountryCode.value)}}
                  </label>
                  <angular2-multiselect [data]="countryItems" [settings]="singleselectDropdownSettings"
                                        class="form-control"
                                        [(ngModel)]="model.defaultSelectedCountryCode.value"
                                        [ngModelOptions]="{standalone: true}"
                                        [readonly]="isReadonly(model.defaultSelectedCountryCode.key)"
                                        name="defaultSelectedCountryCode"
                                        id="defaultSelectedCountryCode"
                                        #defaultSelectedCountryCode="ngModel">
                  </angular2-multiselect>
                </div>
              </div>
              <!--endregion DefaultSelectedCountryCode-->

              <!--region DefaultSelectedCountryCode-->
              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_MOBILE_COMPRESSION_LEVEL' |
                  translate}}</label>
                <div class="col-md-6">
                  <label *ngIf="isReadonly(model.mobileImageCompressionLevel.key)"
                         class="col-form-label form-control-label detail-description">
                    {{getMultiselectDataReadonlyLabel(model.mobileImageCompressionLevel.value)}}
                  </label>
                  <angular2-multiselect [data]="mobileCompressionLevels" [settings]="singleselectDropdownSettings"
                                        class="form-control"
                                        [(ngModel)]="model.mobileImageCompressionLevel.value"
                                        [ngModelOptions]="{standalone: true}"
                                        [readonly]="isReadonly(model.mobileImageCompressionLevel.key)"
                                        name="mobileImageCompressionLevel"
                                        id="mobileImageCompressionLevel"
                                        #mobileImageCompressionLevel="ngModel">
                  </angular2-multiselect>
                </div>
              </div>
              <!--endregion DefaultSelectedCountryCode-->
            </div>
          </div>
        </div>
      </responsive-tab>
      <responsive-tab heading="{{'SETTINGS_USER_DATA'|translate}}"
                      [customClass]="isUserTabValid() ? '' : 'danger'">
        <div class="card mb-05">
          <div class="card-header">
            {{ 'SETTINGS_USER_DATA' | translate }}
          </div>
          <div class="card-body">
            <div class="form-horizontal bordered-row">
              <!--region UserDriverEnabled-->
              <div class="form-group row" *ngIf="isEnabledByServer('TRANSPORT')">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_USER_DRIVER_ENABLED' |
                  translate}}</label>
                <div class="col-md-6">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.userDriverEnabled.value"
                           [disabled]="isReadonly(model.userDriverEnabled.key)">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>
              <!--endregion UserDriverEnabled-->
              <!--region UserEmailRequired-->
              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_USER_USER_EMAIL_REQUIRED' |
                  translate}}</label>
                <div class="col-md-6">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.userEmailRequired.value"
                           [disabled]="isReadonly(model.userEmailRequired.key)">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>
              <!--endregion UserEmailRequired-->
              <!--region UserEmailUnique-->
              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_USER_USER_EMAIL_UNIQUE' |
                  translate}}</label>
                <div class="col-md-6">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.userEmailUnique.value"
                           [disabled]="isReadonly(model.userEmailUnique.key)">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>
              <!--endregion UserEmailUnique-->
            </div>

          </div>
        </div>
      </responsive-tab>
      <responsive-tab heading="{{'SETTINGS_TRANSPORT_RELATED'|translate}}"
                      *ngIf="isEnabledByServer('TRANSPORT')"
                      [customClass]="isTransportTabValid() ? '' : 'danger'">
        <div class="card mb-05">
          <div class="card-header">
            {{'SETTINGS_TRANSPORT_RELATED' | translate}}
          </div>
          <div class="card-body">
            <div class="form-horizontal bordered-row">

              <!--region ShipmentHandoverDuration-->
              <div class="form-group row" [ngClass]="{ 'has-danger': hasLocalFieldError('shipmentHandoverDuration') }">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_SHIPMENT_HANDOVER_DURATION' |
                  translate}}</label>
                <div class="col-md-6">
                  <input type="number" min="0" class="form-control"
                         placeholder="{{'SETTINGS_SHIPMENT_HANDOVER_DURATION' | translate}}"
                         [(ngModel)]="model.shipmentHandoverDuration.value"
                         [ngClass]="{ 'form-control-danger': hasLocalFieldError('shipmentHandoverDuration') }"
                         [readOnly]="isReadonly(model.shipmentHandoverDuration.key)"
                         formControlName="shipmentHandoverDuration">
                  <div class="form-control-feedback" *ngIf="hasLocalFieldError('shipmentHandoverDuration', 'required')">
                    {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                  </div>
                  <div class="form-control-feedback"
                       *ngIf="hasLocalFieldError('shipmentHandoverDuration', 'numberNotPositive')">
                    {{'COMMON_VALIDATION_MESSAGE_GREATER_THAN_ZERO' | translate}}
                  </div>
                </div>
              </div>
              <!--endregion ShipmentTakeoverDuration-->

              <!--region ShipmentTakeoverDuration-->
              <div class="form-group row" [ngClass]="{ 'has-danger': hasLocalFieldError('shipmentTakeoverDuration') }">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_SHIPMENT_TAKEOVER_DURATION' |
                  translate}}</label>
                <div class="col-md-6">
                  <input type="number" min="0" class="form-control"
                         placeholder="{{'SETTINGS_SHIPMENT_TAKEOVER_DURATION' | translate}}"
                         [(ngModel)]="model.shipmentTakeoverDuration.value"
                         [ngClass]="{ 'form-control-danger': hasLocalFieldError('shipmentTakeoverDuration') }"
                         [readOnly]="isReadonly(model.shipmentTakeoverDuration.key)"
                         formControlName="shipmentTakeoverDuration"
                         required>
                  <div class="form-control-feedback" *ngIf="hasLocalFieldError('shipmentTakeoverDuration', 'required')">
                    {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                  </div>
                  <div class="form-control-feedback"
                       *ngIf="hasLocalFieldError('shipmentTakeoverDuration', 'numberNotPositive')">
                    {{'COMMON_VALIDATION_MESSAGE_GREATER_THAN_ZERO' | translate}}
                  </div>
                </div>
              </div>
              <!--endregion ShipmentTakeoverDuration-->

              <!--region TransportPositionLogInterval-->
              <div class="form-group row"
                   [ngClass]="{ 'has-danger': hasLocalFieldError('transportPositionLogInterval') }">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_TRANSPORT_POSITION_LOG_INTERVAL' |
                  translate}}</label>
                <div class="col-md-6">
                  <input type="number" min="0" class="form-control"
                         placeholder="{{'SETTINGS_TRANSPORT_POSITION_LOG_INTERVAL' | translate}}"
                         [(ngModel)]="model.transportPositionLogInterval.value"
                         [ngClass]="{ 'form-control-danger': hasLocalFieldError('transportPositionLogInterval') }"
                         [readOnly]="isReadonly(model.transportPositionLogInterval.key)"
                         formControlName="transportPositionLogInterval">
                  <div class="form-control-feedback"
                       *ngIf="hasLocalFieldError('transportPositionLogInterval', 'required')">
                    {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                  </div>
                  <div class="form-control-feedback"
                       *ngIf="hasLocalFieldError('transportPositionLogInterval', 'numberNotPositive')">
                    {{'COMMON_VALIDATION_MESSAGE_GREATER_THAN_ZERO' | translate}}
                  </div>
                </div>
              </div>
              <!--endregion TransportPositionLogInterval-->

              <!--region TransportDirectionsInterval-->
              <div class="form-group row"
                   [ngClass]="{ 'has-danger': hasLocalFieldError('transportDirectionsInterval') }">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_TRANSPORT_DIRECTIONS_INTERVAL' |
                  translate}}</label>
                <div class="col-md-6">
                  <input type="number" min="0" class="form-control"
                         placeholder="{{'SETTINGS_TRANSPORT_DIRECTIONS_INTERVAL' | translate}}"
                         [(ngModel)]="model.transportDirectionsInterval.value"
                         [ngClass]="{ 'form-control-danger': hasLocalFieldError('transportDirectionsInterval') }"
                         [readOnly]="isReadonly(model.transportDirectionsInterval.key)"
                         formControlName="transportDirectionsInterval">
                  <div class="form-control-feedback"
                       *ngIf="hasLocalFieldError('transportDirectionsInterval', 'required')">
                    {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                  </div>
                  <div class="form-control-feedback"
                       *ngIf="hasLocalFieldError('transportDirectionsInterval', 'numberNotPositive')">
                    {{'COMMON_VALIDATION_MESSAGE_GREATER_THAN_ZERO' | translate}}
                  </div>
                </div>
              </div>
              <!--endregion TransportDirectionsInterval-->

              <!--region TransportLostDriverThreshold-->
              <div class="form-group row"
                   [ngClass]="{ 'has-danger': hasLocalFieldError('transportLostDriverThreshold') }">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_TRANSPORT_LOST_DRIVER_THRESHOLD' |
                  translate}}</label>
                <div class="col-md-6">
                  <input type="number" min="0" class="form-control"
                         placeholder="{{'SETTINGS_TRANSPORT_LOST_DRIVER_THRESHOLD' | translate}}"
                         [(ngModel)]="model.transportLostDriverThreshold.value"
                         [ngClass]="{ 'form-control-danger': hasLocalFieldError('transportLostDriverThreshold') }"
                         [readOnly]="isReadonly(model.transportLostDriverThreshold.key)"
                         formControlName="transportLostDriverThreshold">
                  <div class="form-control-feedback"
                       *ngIf="hasLocalFieldError('transportLostDriverThreshold', 'required')">
                    {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                  </div>
                  <div class="form-control-feedback"
                       *ngIf="hasLocalFieldError('transportLostDriverThreshold', 'numberNotPositive')">
                    {{'COMMON_VALIDATION_MESSAGE_GREATER_THAN_ZERO' | translate}}
                  </div>
                </div>
              </div>
              <!--endregion TransportLostDriverThreshold-->

              <!--region TransportLateThreshold-->
              <div class="form-group row" [ngClass]="{ 'has-danger': hasLocalFieldError('transportLateThreshold') }">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_TRANSPORT_LATE_THRESHOLD' |
                  translate}}</label>
                <div class="col-md-6">
                  <input type="number" min="0" class="form-control"
                         placeholder="{{'SETTINGS_TRANSPORT_LATE_THRESHOLD' | translate}}"
                         [(ngModel)]="model.transportLateThreshold.value"
                         [ngClass]="{ 'form-control-danger': hasLocalFieldError('transportLateThreshold') }"
                         [readOnly]="isReadonly(model.transportLateThreshold.key)"
                         formControlName="transportLateThreshold">
                  <div class="form-control-feedback" *ngIf="hasLocalFieldError('transportLateThreshold', 'required')">
                    {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                  </div>
                  <div class="form-control-feedback"
                       *ngIf="hasLocalFieldError('transportLateThreshold', 'numberNotPositive')">
                    {{'COMMON_VALIDATION_MESSAGE_GREATER_THAN_ZERO' | translate}}
                  </div>
                </div>
              </div>
              <!--endregion TransportLateThreshold-->

              <!--region TransportAutoCloseAfterFinish-->
              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_TRANSPORT_AUTO_CLOSE_AFTER_FINISH' |
                  translate}}</label>
                <div class="col-md-6">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.transportAutoCloseAfterFinish.value"
                           [disabled]="isReadonly(model.transportAutoCloseAfterFinish.key)">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>
              <!--endregion TransportAutoCloseAfterFinish-->
            </div>
          </div>
        </div>
      </responsive-tab>
      <responsive-tab heading="{{'SETTINGS_FIELD_SERVICE_MANAGEMENT'|translate}}"
                      *ngIf="isEnabledByServer('TASKS') || isEnabledByServer('CUSTOMERS')"
                      [customClass]="isFsmTabValid() ? '' : 'danger'">
        <div class="card mb-05">
          <div class="card-header">
            {{'SETTINGS_FIELD_SERVICE_MANAGEMENT' | translate}}
          </div>
          <div class="card-body">
            <div class="form-horizontal bordered-row">

              <!--region CustomerRecordManagedFields-->
              <div class="form-group row" *ngIf="isEnabledByServer('CUSTOMERS')">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_CUSTOMER_RECORD_MANAGED_FIELDS' |
                  translate}}</label>
                <div class="col-md-6">
                  <label *ngIf="isReadonly(model.customerRecordManagedFields.key)"
                         class="col-form-label form-control-label detail-description">
                    {{getMultiselectDataReadonlyLabel(model.customerRecordManagedFields.value)}}
                  </label>
                  <angular2-multiselect [data]="customerRecordFields" [settings]="multiselectDropdownSettings"
                                        class="form-control"
                                        [(ngModel)]="model.customerRecordManagedFields.value"
                                        [ngModelOptions]="{standalone: true}"
                                        [readonly]="isReadonly(model.customerRecordManagedFields.key)"
                                        name="customerRecordManagedFields"
                                        id="customerRecordManagedFields"
                                        #customerRecordManagedFields="ngModel">
                  </angular2-multiselect>
                </div>
              </div>
              <!--endregion CustomerRecordManagedFields-->

              <!--region TaskRecordManagedFields-->
              <div class="form-group row" *ngIf="isEnabledByServer('TASKS')">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_TASK_RECORD_MANAGED_FIELDS' |
                  translate}}</label>
                <div class="col-md-6">
                  <label *ngIf="isReadonly(model.taskRecordManagedFields.key)"
                         class="col-form-label form-control-label detail-description">
                    {{getMultiselectDataReadonlyLabel(model.taskRecordManagedFields.value)}}
                  </label>
                  <angular2-multiselect [data]="taskRecordFields" [settings]="multiselectDropdownSettings"
                                        class="form-control"
                                        [(ngModel)]="model.taskRecordManagedFields.value"
                                        [ngModelOptions]="{standalone: true}"
                                        [readonly]="isReadonly(model.taskRecordManagedFields.key)"
                                        name="taskRecordManagedFields"
                                        id="taskRecordManagedFields"
                                        #taskRecordManagedFields="ngModel">
                  </angular2-multiselect>
                </div>
              </div>
              <!--endregion TaskRecordManagedFields-->

              <!--region RejectedToArchived-->
              <div class="form-group row" *ngIf="isEnabledByServer('TASKS')">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_TASK_RECORD_REJECTED_TO_ARCHIVED' |
                  translate}}
                </label>
                <div class="col-md-6">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.taskRecordRejectedToArchived.value"
                           [disabled]="readonly">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>
              <!--endregion RejectedToArchived-->

              <!--region TaskRecordAssigneeFilter -->
              <div class="form-group row" *ngIf="isEnabledByServer('TASKS')">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_TASK_ASSIGNEE_FILTER' |
                  translate}}</label>
                <div class="col-md-6">
                  <label *ngIf="isReadonly(model.taskRecordAssigneeFilters.key)"
                         class="col-form-label form-control-label detail-description">
                    {{getMultiselectDataReadonlyLabel(model.taskRecordAssigneeFilters.value)}}
                  </label>
                  <angular2-multiselect [data]="taskRecordAssigneeFilters" [settings]="multiselectDropdownSettings"
                                        class="form-control"
                                        [(ngModel)]="model.taskRecordAssigneeFilters.value"
                                        [ngModelOptions]="{standalone: true}"
                                        [readonly]="isReadonly(model.taskRecordAssigneeFilters.key)"
                                        name="taskRecordAssigneeFilters"
                                        id="taskRecordAssigneeFilters"
                                        #customerRecordManagedFields="ngModel">
                  </angular2-multiselect>
                </div>
              </div>
              <!--endregion TaskRecordAssigneeFilter -->

              <!--region GeofencingDefaultRadiusInMeters-->
              <div class="form-group row" *ngIf="isEnabledByServer('TASKS')"
                   [ngClass]="{ 'has-danger': hasLocalFieldError('geofencingDefaultRadiusInMeters') }">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'TASK_GEOFENCING_DEFAULT_RADIUS' |
                  translate}}</label>
                <div class="col-md-6">
                  <input type="number" min="0" class="form-control"
                         placeholder="{{'TASK_GEOFENCING_DEFAULT_RADIUS' | translate}}"
                         [(ngModel)]="model.geofencingDefaultRadiusInMeters.value"
                         [ngClass]="{ 'form-control-danger': hasLocalFieldError('geofencingDefaultRadiusInMeters') }"
                         [readOnly]="isReadonly(model.geofencingDefaultRadiusInMeters.key)"
                         formControlName="geofencingDefaultRadiusInMeters">
                  <div class="form-control-feedback"
                       *ngIf="hasLocalFieldError('geofencingDefaultRadiusInMeters', 'required')">
                    {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                  </div>
                  <div class="form-control-feedback"
                       *ngIf="hasLocalFieldError('geofencingDefaultRadiusInMeters', 'min')">
                    {{'TASK_GEOFENCING_RADIUS_MIN' | translate}}
                  </div>
                  <div class="form-control-feedback"
                       *ngIf="hasLocalFieldError('geofencingDefaultRadiusInMeters', 'max')">
                    {{'TASK_GEOFENCING_RADIUS_MAX' | translate}}
                  </div>
                </div>
              </div>
              <!--endregion GeofencingDefaultRadiusInMeters-->

              <!--region AllowAutomaticSignatureLoading-->
              <div class="form-group row" *ngIf="isEnabledByServer('TASKS')">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_ALLOW_AUTOMATIC_SIGNATURE_LOADING' |
                  translate}}
                  <i class="icomoon icomoon-info info-icon"
                     popover="{{'SETTINGS_ALLOW_AUTOMATIC_SIGNATURE_LOADING_HINT' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-6">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.allowAutomaticSignatureLoading.value"
                           [disabled]="readonly">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>
              <!--endregion AllowAutomaticSignatureLoading-->

              <!--region taskRecordAdminNavigateOnStateChange-->
              <div class="form-group row" *ngIf="isEnabledByServer('TASKS')">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_NAVIGATE_ON_STATE_CHANGE' |
                  translate}}
                  <i class="icomoon icomoon-info info-icon"
                     popover="{{'SETTINGS_NAVIGATE_ON_STATE_CHANGE_HINT' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-6">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.taskRecordAdminNavigateOnStateChange.value"
                           [disabled]="readonly">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>
              <!--endregion taskRecordAdminNavigateOnStateChange-->

              <!--region DashboardVisibleCount-->
              <div class="form-group row"
                   [ngClass]="{ 'has-danger': hasLocalFieldError('taskRecordWebadminDashboardVisibleCount') }">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_TASK_RECORD_WEBADMIN_DASHBOARD_VISIBLE' |
                  translate}}</label>
                <div class="col-md-6">
                  <input type="number" class="form-control"
                         placeholder="{{'SETTINGS_TASK_RECORD_WEBADMIN_DASHBOARD_VISIBLE' | translate}}"
                         [(ngModel)]="model.taskRecordWebadminDashboardVisibleCount.value"
                         [ngClass]="{ 'form-control-danger': hasLocalFieldError('taskRecordWebadminDashboardVisibleCount') }"
                         [readOnly]="isReadonly(model.taskRecordWebadminDashboardVisibleCount.key)"
                         formControlName="taskRecordWebadminDashboardVisibleCount">
                  <div class="form-control-feedback"
                       *ngIf="hasLocalFieldError('taskRecordWebadminDashboardVisibleCount', 'required')">
                    {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                  </div>
                </div>
              </div>
              <!--endregion DashboardVisibleCount-->


              <!--region taskRecordMobileShowArchived-->
              <div class="form-group row" *ngIf="isEnabledByServer('TASKS')">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_MOBILE_SHOW_ARCHIVED' |
                  translate}}
                  <i class="icomoon icomoon-info info-icon"
                     popover="{{'SETTINGS_MOBILE_SHOW_ARCHIVED_HINT' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-6">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.taskRecordMobileShowArchived.value"
                           [disabled]="readonly">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>
              <!--endregion taskRecordMobileShowArchived-->

              <!--region taskRecordMobileShowArchived-->
              <div class="form-group row" *ngIf="isEnabledByServer('TASKS')">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_PHONE_NUMBER_RELATED_TASK_RECORD_LIST' |
                  translate}}
                  <i class="icomoon icomoon-info info-icon"
                     popover="{{'SETTINGS_PHONE_NUMBER_RELATED_TASK_RECORD_LIST_HINT' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-6">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.taskRecordPhoneNumberRelatedList.value"
                           [disabled]="readonly">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>
              <!--endregion taskRecordMobileShowArchived-->
              <!--region StockItemProductCodeGeneratorEnabled-->
              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_STOCK_ITEM_PRODUCT_CODE_GENERATOR_ENABLED' |
                  translate}}</label>
                <div class="col-md-6">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.stockItemProductCodeGeneratorEnabled.value"
                           [disabled]="isReadonly(model.stockItemProductCodeGeneratorEnabled.key)">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>
              <!--endregion StockItemProductCodeGeneratorEnabled-->
              <!--region StockItemCategoryRequired-->
              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_STOCK_ITEM_CATEGORY_REQUIRED' |
                  translate}}</label>
                <div class="col-md-6">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.stockItemCategoryRequired.value"
                           [disabled]="isReadonly(model.stockItemCategoryRequired.key)">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>
              <!--endregion StockItemCategoryRequired-->

            </div>
          </div>
        </div>
        <!-- region CalendarCard -->
        <div class="card mb-05" *ngIf="isEnabledByServer('CALENDAR')">
          <div class="card-header">
            {{'SETTINGS_CALENDAR' | translate}}
          </div>
          <div class="card-body">
            <div class="form-horizontal bordered-row">

              <label class="col-form-label mw-100 form-control-label font-weight-bold">
                {{'SETTINGS_CALENDAR_DISPLAY_SETTINGS'|translate}}
              </label>

              <!--region CalendarAvailableEnabled-->
              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_CALENDAR_AVAILABLE_ENABLED' |
                  translate}}</label>
                <div class="col-md-6">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.calendarAvailableEnabled.value"
                           [disabled]="isReadonly(model.calendarAvailableEnabled.key)">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>
              <!--endregion CalendarAvailableEnabled-->

              <!--region CalendarUserColorsEnabled-->
              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_CALENDAR_USER_COLORS_ENABLED' |
                  translate}}</label>
                <div class="col-md-6">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.calendarUserColorsEnabled.value"
                           [disabled]="isReadonly(model.calendarUserColorsEnabled.key)">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>
              <!--endregion CalendarUserColorsEnabled-->

              <!--region Calendar display labels-->
              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_CALENDAR_DISPLAY_LABELS_LINE_1' |
                  translate}}</label>
                <div class="col-md-6">
                  <angular2-multiselect [data]="calendarDisplayLabelLine1Options"
                                        [settings]="registrationEnabledDropdownSettings"
                                        class="form-control"
                                        [(ngModel)]="model.calendarWebadminLabelsLine1.value"
                                        (ngModelChange)="onCalendarDisplayLabelLine1Changed()"
                                        [ngModelOptions]="{standalone: true}"
                                        [readonly]="isReadonly(model.calendarWebadminLabelsLine1.key)"
                                        name="calendarWebadminLabelsLine1"
                                        id="calendarWebadminLabelsLine1"
                                        #calendarWebadminLabelsLine1="ngModel">
                  </angular2-multiselect>
                </div>
              </div>

              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_CALENDAR_DISPLAY_LABELS_LINE_2' |
                  translate}}</label>
                <div class="col-md-6">
                  <angular2-multiselect [data]="calendarDisplayLabelLine2Options"
                                        [settings]="registrationEnabledDropdownSettings"
                                        class="form-control"
                                        [(ngModel)]="model.calendarWebadminLabelsLine2.value"
                                        (ngModelChange)="onCalendarDisplayLabelLine2Changed()"
                                        [ngModelOptions]="{standalone: true}"
                                        [readonly]="isReadonly(model.calendarWebadminLabelsLine2.key) || calendarDisplayLabelLine2Disabled"
                                        [readonlyBorderEnabled]="!isReadonly(model.calendarWebadminLabelsLine2.key) && calendarDisplayLabelLine2Disabled"
                                        name="calendarWebadminLabelsLine2"
                                        id="calendarWebadminLabelsLine2"
                                        #calendarWebadminLabelsLine2="ngModel">
                  </angular2-multiselect>
                </div>
              </div>

              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_CALENDAR_DISPLAY_LABELS_LINE_3' |
                  translate}}</label>
                <div class="col-md-6">
                  <angular2-multiselect [data]="calendarDisplayLabelLine3Options"
                                        [settings]="registrationEnabledDropdownSettings"
                                        class="form-control"
                                        [(ngModel)]="model.calendarWebadminLabelsLine3.value"
                                        [ngModelOptions]="{standalone: true}"
                                        [readonly]="isReadonly(model.calendarWebadminLabelsLine3.key) || calendarDisplayLabelLine3Disabled"
                                        [readonlyBorderEnabled]="!isReadonly(model.calendarWebadminLabelsLine3.key) && calendarDisplayLabelLine3Disabled"
                                        name="calendarWebadminLabelsLine3"
                                        id="calendarWebadminLabelsLine3"
                                        #calendarWebadminLabelsLine3="ngModel">
                  </angular2-multiselect>
                </div>
              </div>

              <!--region DisplayMonogram-->
              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_CALENDAR_DISPLAY_MONOGRAM' |
                  translate}}
                </label>
                <div class="col-md-6">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.calendarDisplayMonogram.value"
                           [disabled]="readonly">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>
              <!--endregion DisplayMonogram-->

              <div class="form-group row" *ngIf="calendarDisplayLine1Text">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_CALENDAR_DISPLAY_LABELS_PREVIEW' |
                  translate}}</label>
                <div class="col-md-6">
                  <div class="calendar-event-preview">
                    <label class="calendar-event-preview-line font-weight-bold mb-0" *ngIf="calendarDisplayLine1Text">
                      {{'{' + (calendarDisplayLine1Text | translate) + '}'}} <span
                      *ngIf="model.calendarDisplayMonogram.value"> (AB)</span>
                    </label>
                    <br *ngIf="calendarDisplayLine2Text">
                    <label class="calendar-event-preview-line mb-0" *ngIf="calendarDisplayLine2Text">
                      {{'{' + (calendarDisplayLine2Text | translate) + '}'}}
                    </label>
                    <br *ngIf="calendarDisplayLine3Text">
                    <label class="calendar-event-preview-line mb-0" *ngIf="calendarDisplayLine3Text">
                      {{'{' + (calendarDisplayLine3Text | translate) + '}'}}
                    </label>
                  </div>
                </div>
              </div>
              <!--endregion Calendar display labels-->

              <!--region CalendarDisplayStartInMinutes-->
              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_CALENDAR_DISPLAY_START_IN_MINUTES' |
                  translate}}</label>
                <div class="col-md-6"
                     [ngClass]="{ 'has-danger': submitted && hasLocalFormError('validateAppNgbTimeInterval') }">
                  <div class="input-group">
                    <input placeholder="{{'DATE_TIME_PICKER_PLACEHOLDER_TIME' | translate}}" type="text"
                           class="form-control hidden-value-when-disabled"
                           [(ngModel)]="model.calendarDisplayStartInMinutes.value"
                           formControlName="calendarDisplayStartInMinutes"
                           (ngModelChange)="formGroup.controls['calendarDisplayStartInMinutes'].updateValueAndValidity()"
                           [ngClass]="{ 'form-control-danger': submitted && hasLocalFieldError('calendarDisplayStartInMinutes') || hasLocalFormError('validateAppNgbTimeInterval') }"
                           [seconds]="false"
                           [allowEmptyValue]="true"
                           ngbTimepicker #display_start_time="ngbTimepicker"
                           [toggleButtonId]="'display_start_time_toggle_button'"
                           [spinners]="true" [hourStep]="1" [minuteStep]="10"
                           [readOnly]="isReadonly(model.calendarDisplayStartInMinutes.key)">
                    <span class="add-on input-group-addon-gray"
                          id="display_start_time_toggle_button"
                          (click)="display_start_time.toggle()">
                        <i class="icomoon icomoon-time"></i>
                </span>
                  </div>
                  <div class="form-control-feedback"
                       *ngIf="submitted && hasLocalFormError('validateAppNgbTimeInterval')">
                    {{'COMMON_VALIDATION_MESSAGE_INVALID_LOCAL_DATE_FROM_TO' | translate}}
                  </div>
                </div>
              </div>
              <!--endregion CalendarDisplayStartInMinutes-->

              <!--region CalendarDisplayEndInMinutes-->
              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_CALENDAR_DISPLAY_END_IN_MINUTES' |
                  translate}}</label>
                <div class="col-md-6"
                     [ngClass]="{ 'has-danger': submitted && hasLocalFormError('validateAppNgbTimeInterval') }">
                  <div class="input-group">
                    <input placeholder="{{'DATE_TIME_PICKER_PLACEHOLDER_TIME' | translate}}" type="text"
                           class="form-control hidden-value-when-disabled"
                           [(ngModel)]="model.calendarDisplayEndInMinutes.value"
                           formControlName="calendarDisplayEndInMinutes"
                           (ngModelChange)="formGroup.controls['calendarDisplayEndInMinutes'].updateValueAndValidity()"
                           [ngClass]="{ 'form-control-danger': submitted && hasLocalFieldError('calendarDisplayEndInMinutes') || hasLocalFormError('validateAppNgbTimeInterval') }"
                           [seconds]="false"
                           [allowEmptyValue]="true"
                           ngbTimepicker #display_end_time="ngbTimepicker"
                           [toggleButtonId]="'display_end_time_toggle_button'"
                           [spinners]="true" [hourStep]="1" [minuteStep]="10"
                           [readOnly]="isReadonly(model.calendarDisplayEndInMinutes.key)">
                    <span class="add-on input-group-addon-gray"
                          id="display_end_time_toggle_button"
                          (click)="display_end_time.toggle()">
                        <i class="icomoon icomoon-time"></i>
                </span>
                  </div>
                  <div class="form-control-feedback"
                       *ngIf="submitted && hasLocalFormError('validateAppNgbTimeInterval')">
                    {{'COMMON_VALIDATION_MESSAGE_INVALID_LOCAL_DATE_FROM_TO' | translate}}
                  </div>
                </div>
              </div>
              <!--endregion CalendarWorkdayEndInMinutes-->

              <label class="col-form-label mw-100 form-control-label font-weight-bold">
                {{'SETTINGS_CALENDAR_WORKLOG_SETTINGS'|translate}}
              </label>

              <!--region CalendarWorkdayStartInMinutes-->
              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_CALENDAR_WORKDAY_START_IN_MINUTES' |
                  translate}}</label>
                <div class="col-md-6"
                     [ngClass]="{ 'has-danger': submitted && hasLocalFormError('validateAppNgbTimeInterval') }">
                  <div class="input-group">
                    <input placeholder="{{'DATE_TIME_PICKER_PLACEHOLDER_TIME' | translate}}" type="text"
                           class="form-control hidden-value-when-disabled"
                           [(ngModel)]="model.calendarWorkdayStartInMinutes.value"
                           formControlName="calendarWorkdayStartInMinutes"
                           (ngModelChange)="formGroup.controls['calendarWorkdayStartInMinutes'].updateValueAndValidity()"
                           [ngClass]="{ 'form-control-danger': submitted && hasLocalFieldError('calendarWorkdayStartInMinutes') || hasLocalFormError('validateAppNgbTimeInterval') }"
                           [seconds]="false"
                           [allowEmptyValue]="true"
                           ngbTimepicker #workday_start_time="ngbTimepicker" [toggleButtonId]="'workday_start_time_toggle_button'"
                           [spinners]="true" [hourStep]="1" [minuteStep]="10"
                           [readOnly]="isReadonly(model.calendarWorkdayStartInMinutes.key)">
                    <span class="add-on input-group-addon-gray"
                          id="workday_start_time_toggle_button"
                          (click)="workday_start_time.toggle()">
                        <i class="icomoon icomoon-time"></i>
                </span>
                  </div>
                  <div class="form-control-feedback"
                       *ngIf="submitted && hasLocalFormError('validateAppNgbTimeInterval')">
                    {{'COMMON_VALIDATION_MESSAGE_INVALID_LOCAL_DATE_FROM_TO' | translate}}
                  </div>
                </div>
              </div>
              <!--endregion CalendarWorkdayStartInMinutes-->

              <!--region CalendarWorkdayEndInMinutes-->
              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_CALENDAR_WORKDAY_END_IN_MINUTES' |
                  translate}}</label>
                <div class="col-md-6"
                     [ngClass]="{ 'has-danger': submitted && hasLocalFormError('validateAppNgbTimeInterval') }">
                  <div class="input-group">
                    <input placeholder="{{'DATE_TIME_PICKER_PLACEHOLDER_TIME' | translate}}" type="text"
                           class="form-control hidden-value-when-disabled"
                           [(ngModel)]="model.calendarWorkdayEndInMinutes.value"
                           formControlName="calendarWorkdayEndInMinutes"
                           (ngModelChange)="formGroup.controls['calendarWorkdayEndInMinutes'].updateValueAndValidity()"
                           [ngClass]="{ 'form-control-danger': submitted && hasLocalFieldError('calendarWorkdayEndInMinutes') || hasLocalFormError('validateAppNgbTimeInterval') }"
                           [seconds]="false"
                           [allowEmptyValue]="true"
                           ngbTimepicker #workday_end_time="ngbTimepicker" [toggleButtonId]="'workday_end_time_toggle_button'"
                           [spinners]="true" [hourStep]="1" [minuteStep]="10"
                           [readOnly]="isReadonly(model.calendarWorkdayEndInMinutes.key)">
                    <span class="add-on input-group-addon-gray"
                          id="workday_end_time_toggle_button"
                          (click)="workday_end_time.toggle()">
                        <i class="icomoon icomoon-time"></i>
                </span>
                  </div>
                  <div class="form-control-feedback"
                       *ngIf="submitted && hasLocalFormError('validateAppNgbTimeInterval')">
                    {{'COMMON_VALIDATION_MESSAGE_INVALID_LOCAL_DATE_FROM_TO' | translate}}
                  </div>
                </div>
              </div>
              <!--endregion CalendarWorkdayEndInMinutes-->

              <!--region CalendarWorkOnWeekend-->
              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_CALENDAR_WORKDAY_ON_WEEKEND' |
                  translate}}
                  <i class="icomoon icomoon-info info-icon"
                     popover="{{'SETTINGS_CALENDAR_WORKDAY_ON_WEEKEND_HINT' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-6">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.calendarWorkdayOnWeekend.value"
                           [disabled]="readonly">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>
              <!--endregion CalendarWorkOnWeekend-->

              <!--region WorkChronologyHoursPerDay-->
              <div class="form-group row"
                   [ngClass]="{ 'has-danger': hasLocalFieldError('workChronologyHoursPerDay') }">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_WORK_CHRONOLOGY_HOURS_PER_DAY' |
                  translate}}</label>
                <div class="col-md-6">
                  <input type="number" min="0" class="form-control"
                         placeholder="{{'SETTINGS_WORK_CHRONOLOGY_HOURS_PER_DAY' | translate}}"
                         [(ngModel)]="model.workChronologyHoursPerDay.value"
                         [ngClass]="{ 'form-control-danger': hasLocalFieldError('workChronologyHoursPerDay') }"
                         [readOnly]="isReadonly(model.workChronologyHoursPerDay.key)"
                         formControlName="workChronologyHoursPerDay">
                  <div class="form-control-feedback"
                       *ngIf="hasLocalFieldError('workChronologyHoursPerDay', 'numberNotPositive')">
                    {{'COMMON_VALIDATION_MESSAGE_GREATER_THAN_ZERO' | translate}}
                  </div>
                  <div class="form-control-feedback"
                       *ngIf="hasLocalFieldError('workChronologyHoursPerDay', 'validateMaxNumber')">
                    {{'COMMON_VALIDATION_MESSAGE_MAX' | translate: {maxValue: 24} }}
                  </div>
                </div>
              </div>
              <!--endregion WorkChronologyHoursPerDay-->


              <!--region WorkChronologyDaysPerWeek-->
              <div class="form-group row"
                   [ngClass]="{ 'has-danger': hasLocalFieldError('workChronologyDaysPerWeek') }">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_WORK_CHRONOLOGY_DAYS_PER_WEEK' |
                  translate}}</label>
                <div class="col-md-6">
                  <input type="number" min="0" class="form-control"
                         placeholder="{{'SETTINGS_WORK_CHRONOLOGY_DAYS_PER_WEEK' | translate}}"
                         [(ngModel)]="model.workChronologyDaysPerWeek.value"
                         [ngClass]="{ 'form-control-danger': hasLocalFieldError('workChronologyDaysPerWeek') }"
                         [readOnly]="isReadonly(model.workChronologyDaysPerWeek.key)"
                         formControlName="workChronologyDaysPerWeek">
                  <div class="form-control-feedback"
                       *ngIf="hasLocalFieldError('workChronologyDaysPerWeek', 'numberNotPositive')">
                    {{'COMMON_VALIDATION_MESSAGE_GREATER_THAN_ZERO' | translate}}
                  </div>
                  <div class="form-control-feedback"
                       *ngIf="hasLocalFieldError('workChronologyDaysPerWeek', 'validateMaxNumber')">
                    {{'COMMON_VALIDATION_MESSAGE_MAX' | translate: {maxValue: 7} }}
                  </div>
                </div>
              </div>
              <!--endregion WorkChronologyDaysPerWeek-->

              <!--region CalendarSplit-->
              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_CALENDAR_SPLIT' |
                  translate}}
                </label>
                <div class="col-md-6">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.calendarSplitToWorkday.value"
                           [disabled]="readonly">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>
              <!--endregion CalendarSplit-->

            </div>
          </div>
        </div>
        <!-- endregion CalendarCard -->

      </responsive-tab>
      <responsive-tab heading="{{'SETTINGS_OTHER'|translate}}"
                      *ngIf="true"
                      [customClass]="isOtherTabValid() ? '' : 'danger'">
        <div class="card mb-05">
          <div class="card-header">
            {{'SETTINGS_OTHER' | translate}}
          </div>
          <div class="card-body">
            <div class="form-horizontal bordered-row">

              <!--region RegistrationEnabled-->
              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_REGISTRATION_ENABLED' |
                  translate}}</label>
                <div class="col-md-6">
                  <angular2-multiselect [data]="registrationTypes" [settings]="registrationEnabledDropdownSettings"
                                        class="form-control"
                                        [(ngModel)]="model.registrationEnabled.value"
                                        [ngModelOptions]="{standalone: true}"
                                        [readonly]="isReadonly(model.registrationEnabled.key)"
                                        name="registrationEnabled"
                                        id="registrationEnabled"
                                        #registrationEnabled="ngModel">
                  </angular2-multiselect>
                </div>
              </div>
              <!--endregion RegistrationEnabled-->

              <!--region RegistrationInactivationDays-->
              <div class="form-group row"
                   [ngClass]="{ 'has-danger': hasLocalFieldError('registrationInactivationDays') }">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_REGISTRATION_INACTIVATION_DAYS' |
                  translate}}</label>
                <div class="col-md-6">
                  <input type="number" min="0" class="form-control"
                         placeholder="{{'SETTINGS_REGISTRATION_INACTIVATION_DAYS' | translate}}"
                         [(ngModel)]="model.registrationInactivationDays.value"
                         [ngClass]="{ 'form-control-danger': hasLocalFieldError('registrationInactivationDays') }"
                         [readOnly]="isReadonly(model.registrationInactivationDays.key)"
                         formControlName="registrationInactivationDays">
                  <div class="form-control-feedback"
                       *ngIf="hasLocalFieldError('registrationInactivationDays', 'numberNotPositive')">
                    {{'COMMON_VALIDATION_MESSAGE_GREATER_THAN_ZERO' | translate}}
                  </div>
                </div>
              </div>
              <!--endregion RegistrationInactivationDays-->

              <!--region OrderMaxPackageWeight-->
              <div class="form-group row" *ngIf="isEnabledByServer('ORDER')"
                   [ngClass]="{ 'has-danger': hasLocalFieldError('orderMaxPackageWeight') }">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_ORDER_MAX_PACKAGE_WEIGHT' |
                  translate}}</label>
                <div class="col-md-6">
                  <input type="number" min="0" class="form-control"
                         placeholder="{{'SETTINGS_ORDER_MAX_PACKAGE_WEIGHT' | translate}}"
                         [(ngModel)]="model.orderMaxPackageWeight.value"
                         [ngClass]="{ 'form-control-danger': hasLocalFieldError('orderMaxPackageWeight') }"
                         [readOnly]="isReadonly(model.orderMaxPackageWeight.key)"
                         formControlName="orderMaxPackageWeight">
                  <div class="form-control-feedback"
                       *ngIf="hasLocalFieldError('orderMaxPackageWeight', 'numberNotPositive')">
                    {{'COMMON_VALIDATION_MESSAGE_GREATER_THAN_ZERO' | translate}}
                  </div>
                </div>
              </div>
              <!--endregion OrderMaxPackageWeight-->

              <!--region Prospedition message user group -->
              <div class="form-group row" *ngIf="isEnabledByServer('WEBSHOP')">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_PROSPEDITION_MSG_USER_GROUP_ID' |
                  translate}}</label>
                <div class="col-md-6">
                  <angular2-multiselect [data]="userGroups" [settings]="singleselectDropdownSettings"
                                        class="form-control"
                                        [(ngModel)]="model.integrationProspeditionImporterSystemMessageUserGroup.value"
                                        [ngModelOptions]="{standalone: true}"
                                        [readonly]="isReadonly(model.integrationProspeditionImporterSystemMessageUserGroup.key)"
                                        name="integrationProspeditionImporterSystemMessageUserGroup"
                                        id="integrationProspeditionImporterSystemMessageUserGroup"
                                        #integrationProspeditionImporterSystemMessageUserGroup="ngModel">
                  </angular2-multiselect>
                </div>
              </div>
              <!--endregion Prospedition message user group-->

              <!--region Prospedition reject unknown product -->
              <div class="form-group row" *ngIf="isEnabledByServer('WEBSHOP')">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_PROSPEDITION_REJECT_UNKNOWN_PRODUCT' |
                  translate}}</label>
                <div class="col-md-6">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.integrationProspeditionImporterRejectUnknownProduct.value"
                           [disabled]="isReadonly(model.integrationProspeditionImporterRejectUnknownProduct.key)">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>
              <!--endregion Prospedition reject unknown product-->

              <!--region Prospedition reject insufficient amount -->
              <div class="form-group row" *ngIf="isEnabledByServer('WEBSHOP')">
                <label
                  class="col-form-label form-control-label col-md-6 detail-title">{{'SETTINGS_PROSPEDITION_REJECT_INSUFFICIENT_AMOUNT' |
                  translate}}</label>
                <div class="col-md-6">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.integrationProspeditionImporterRejectInsufficientAmount.value"
                           [disabled]="isReadonly(model.integrationProspeditionImporterRejectInsufficientAmount.key)">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>
              <!--endregion Prospedition reject insufficient amount-->
            </div>
          </div>
        </div>
      </responsive-tab>
    </responsive-tabset>

    <!--region buttons-->
    <input type="button" class="btn btn-primary-outline floating_apply_button"
           (click)="onApplyButtonClicked()" value="{{'COMMON_BUTTON_APPLY' | translate}}"/>
    <input type="submit" class="btn btn-primary floating_save_button"
           value="{{'COMMON_BUTTON_SAVE' | translate}}"/>
    <!--endregion buttons-->

  </form>
</div>
