<div class="container-vertical-padding-half card-custom-padding-outer">
  <div class="card">
    <div class="card-header">
      {{'STOCK_TAKING_SHEETS' | translate}}
      <div class="card-actions">
        <a *ngIf="!readonly" class="btn-setting table-header-action-button" title="{{'COMMON_BUTTON_ADD' | translate}}"><i
          class="icomoon icomoon-add" (click)="showAddStockTakingSheetDialog()"></i></a>
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()"
           title="{{'COMMON_SHOW_SEARCH_HINT' | translate}}"><i class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>
    <div class="card-body border-bottom" *ngIf="showSearch">
      <div class="row form-group">
        <div class="col-lg-3 col-md-6 col-sm-12">
          <label class="search-label">{{'STOCK_CHANGE_TABLE_HEADER_TRX_ID' | translate}}</label>
          <input type="text" class="form-control"
                 placeholder="{{'STOCK_CHANGE_TABLE_HEADER_TRX_ID' | translate}}"
                 [(ngModel)]="searchModel.trxId">
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <label class="search-label">{{'FORM_RECORD_STOCK_STOCK'|translate}}</label>
          <angular2-multiselect
            [(ngModel)]="searchModel.stocks"
            [ngModelOptions]="{standalone: true}"
            [data]="stocks"
            [settings]="dropdownSettings">
          </angular2-multiselect>
        </div>
        <div class="col">
          <label class="search-label">{{'TASK_RECORD_SEARCH_USER_NAME' | translate}}</label>
          <angular2-multiselect [data]="usersForSearch" [settings]="dropdownSettingsForUser"
                                class="form-control"
                                [(ngModel)]="searchModel._assigneeUserItem"
                                #userMulti="ngModel" name="userMulti" id="userMulti">
          </angular2-multiselect>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <label class="search-label">{{'TASK_RECORD_TABLE_HEADER_STATE' | translate}}</label>
          <select class="form-control" [(ngModel)]="searchModel.state"
                  id="state" name="state" #state="ngModel"
                  [compareWith]="SelectUtils.compareStateObjects">
            <option *ngFor="let state of stockTakingSheetStates" [ngValue]="state">{{state.text}}</option>
          </select>
        </div>
      </div>
        <div class="col-md-12 d-flex justify-content-md-end align-items-end px-0">
          <div class="btn-group" role="group">
            <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
              {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
            </button>
            <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
              {{'COMMON_BUTTON_SEARCH' | translate}}
            </button>
          </div>
        </div>
  </div> <!-- ./card-body end -->
    <div *ngIf="!rightModel.stockTakingSheetRead.hasRight()" class="text-center">
      <p class="empty-form-hint-text">{{ 'STOCK_TAKING_SHEET_NO_DATA' | translate }}</p>
    </div>
    <div class="card-body" *ngIf="rightModel.stockTakingSheetRead.hasRight()">
      <!--ADD NEW TEXT ON EDIT SCREEN IF NO DATA-->
      <div *ngIf="stockTakingSheets.length === 0 && !readonly" class="text-center">
        <p class="empty-form-hint-text">{{ 'STOCK_TAKING_SHEET_ADD_TEXT' | translate }}</p>
        <button *ngIf="rightModel.stockTakingSheetCreate.hasRight()" type="button" class="btn btn-secondary"
                (click)="showAddStockTakingSheetDialog()">{{ 'COMMON_BUTTON_ADD' | translate }}</button>
      </div>
      <!--ADD NEW TEXT ON EDIT SCREEN IF NO DATA END-->
      <!--NO DATA TEXT ON DETAIL SCREEN IF NO DATA-->
      <div *ngIf="stockTakingSheets.length === 0 && readonly" class="text-center">
        <p class="empty-form-hint-text">{{ 'STOCK_TAKING_SHEET_NO_DATA' | translate }}</p>
      </div>
      <!--NO DATA TEXT ON DETAIL SCREEN IF NO DATA END-->
      <table *ngIf="stockTakingSheets.length > 0" class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header long"><app-table-field-sorter
            [orderField]="StockTakingSheet.OrderField.TRX_ID"
            [orderType]="queryModel.getOrderType(StockTakingSheet.OrderField.TRX_ID)"
            (orderChange)="orderBy($event)"
            [text]="'STOCK_CHANGE_TABLE_HEADER_TRX_ID' | translate">
          </app-table-field-sorter></th>
          <th class="table-sorter-header hidden-md-down"><app-table-field-sorter
            [orderField]="StockTakingSheet.OrderField.CREATION_TIME"
            [orderType]="queryModel.getOrderType(StockTakingSheet.OrderField.CREATION_TIME)"
            (orderChange)="orderBy($event)"
            [text]="'COMMON_TABLE_HEADER_CREATION_TIME' | translate">
          </app-table-field-sorter></th>
          <th class="table-sorter-header hidden-sm-down"><app-table-sorter-no-op
            [text]="'FORM_RECORD_STOCK_STOCK' | translate">
          </app-table-sorter-no-op></th>
          <th class="table-sorter-header hidden-sm-down"><app-table-sorter-no-op
            [text]="'TASK_RECORD_TABLE_HEADER_STATE' | translate">
          </app-table-sorter-no-op></th>
          <th class="table-sorter-header hidden-md-down"><app-table-sorter-no-op
            [text]="'TASK_RECORD_TABLE_HEADER_USER_NAME' | translate">
          </app-table-sorter-no-op></th>
          <th class="table-sorter-header hidden-md-down"><app-table-sorter-no-op
            [text]="'STOCK_TAKING_SHEET_STOCK_AMOUNT' | translate">
          </app-table-sorter-no-op></th>
          <th class="table-sorter-header hidden-md-down"><app-table-sorter-no-op
            [text]="'STOCK_TAKING_SHEET_AMOUNT_FOUND' | translate">
          </app-table-sorter-no-op></th>
          <th class="table-sorter-header hidden-sm-down"><app-table-sorter-no-op
            [text]="'STOCK_TAKING_SHEET_AMOUNT_DIFFERENCE' | translate">
          </app-table-sorter-no-op></th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let stockTakingSheet of stockTakingSheets | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }">
          <td class="responsive-table-column">{{stockTakingSheet.trxId}}</td>
          <td class="responsive-table-column hidden-md-down">{{stockTakingSheet.creationTime.toUtcIsoString() | date:'short'}}</td>
          <td class="responsive-table-column hidden-sm-down">{{stockTakingSheet.stockName}}</td>
          <td class="responsive-table-column hidden-sm-down">{{stockTakingSheet.state}}</td>
          <td class="responsive-table-column hidden-md-down">{{stockTakingSheet.ownerUserName}}</td>
          <td class="responsive-table-column hidden-md-down">{{stockTakingSheet.stockAmount}}</td>
          <td class="responsive-table-column hidden-md-down">{{stockTakingSheet.amountFound}}</td>
          <td class="responsive-table-column hidden-sm-down">
            <span [ngClass]="{'color-red': +stockTakingSheet.amountDifference < 0}">
              <span *ngIf="+stockTakingSheet.amountDifference > 0">+</span>{{stockTakingSheet.amountDifference}}
            </span>
          </td>
          <td class="align-middle">
            <app-table-options-menu>
              <app-dropdown-item
                [iconClass]="'icomoon-detail'"
                [titleStringKey]="'COMMON_BUTTON_DETAILS'"
                class="cursor-pointer"
                uiSref="Admin.StockTakingSheetDetail"
                [uiParams]="{ stockTakingId: stockTakingId, id: stockTakingSheet.sheetId }">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="hasPdf"
                [iconClass]="'icomoon-download'"
                [titleStringKey]="'TASK_PDF_DOWNLOAD'"
                class="cursor-pointer"
                (click)="downloadPdf(stockTakingSheet.sheetId)">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="rightModel.stockTakingSheetUpdate.hasRight() && stockTakingSheet.canUpdateStock"
                [iconClass]="'icomoon-stock'"
                [titleStringKey]="'STOCK_TAKING_SHEET_UPDATE_STOCK'"
                class="cursor-pointer"
                (click)="updateStock(stockTakingSheet.sheetId)">
              </app-dropdown-item>
              <app-dropdown-item
                [iconClass]="'icomoon-csv-export'"
                [titleStringKey]="'COMMON_BUTTON_EXPORT'"
                class="cursor-pointer"
                (click)="downloadXls(stockTakingSheet.sheetId)">
              </app-dropdown-item>
            </app-table-options-menu>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header long"><app-table-field-sorter
            [orderField]="StockTakingSheet.OrderField.TRX_ID"
            [orderType]="queryModel.getOrderType(StockTakingSheet.OrderField.TRX_ID)"
            (orderChange)="orderBy($event)"
            [text]="'STOCK_CHANGE_TABLE_HEADER_TRX_ID' | translate">
          </app-table-field-sorter></th>
          <th class="table-sorter-header hidden-md-down"><app-table-field-sorter
            [orderField]="StockTakingSheet.OrderField.CREATION_TIME"
            [orderType]="queryModel.getOrderType(StockTakingSheet.OrderField.CREATION_TIME)"
            (orderChange)="orderBy($event)"
            [text]="'COMMON_TABLE_HEADER_CREATION_TIME' | translate">
          </app-table-field-sorter></th>
          <th class="table-sorter-header hidden-sm-down"><app-table-sorter-no-op
            [text]="'FORM_RECORD_STOCK_STOCK' | translate">
          </app-table-sorter-no-op></th>
          <th class="table-sorter-header hidden-sm-down"><app-table-sorter-no-op
            [text]="'TASK_RECORD_TABLE_HEADER_STATE' | translate">
          </app-table-sorter-no-op></th>
          <th class="table-sorter-header hidden-md-down"><app-table-sorter-no-op
            [text]="'TASK_RECORD_TABLE_HEADER_USER_NAME' | translate">
          </app-table-sorter-no-op></th>
          <th class="table-sorter-header hidden-md-down"><app-table-sorter-no-op
            [text]="'STOCK_TAKING_SHEET_STOCK_AMOUNT' | translate">
          </app-table-sorter-no-op></th>
          <th class="table-sorter-header hidden-md-down"><app-table-sorter-no-op
            [text]="'STOCK_TAKING_SHEET_AMOUNT_FOUND' | translate">
          </app-table-sorter-no-op></th>
          <th class="table-sorter-header hidden-sm-down"><app-table-sorter-no-op
            [text]="'STOCK_TAKING_SHEET_AMOUNT_DIFFERENCE' | translate">
          </app-table-sorter-no-op></th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>
  </div>
</div>
