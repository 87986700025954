import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UIRouter} from '@uirouter/angular';
import {StateName, StateRequestParam} from '../../../app.state-names';
import {Transition} from '@uirouter/core';
import {AuthResult, AuthService} from '../../../lib/auth.service';
import {ErrorResource, ObservableErrorResourceParser, ObservableErrorResponse} from '../../../lib/util/errors';
import {HelpdeskLoginModel} from '../login/login.component';
import {HelpdeskRegistrationModel} from '../registration/registration.component';
import {RecaptchaV3Utils} from '../../../util/recaptcha-utils';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {RegistrationService} from '../../../lib/registration/registration.service';
import {Services} from '../../../lib/util/services';
import {toasterConfig} from '../../../fork/angular2-toaster/src/toaster-config';
import {
  LoggedInUserStorage,
  LoginRequiredReason,
  LoginRequiredReasonStorage,
  ServiceErrorStorage
} from '../../../lib/util/storages';
import {ConfigurationService} from '../../../lib/core-ext/configuration.service';
import {CustomerRecordService} from '../../../lib/customer/customer-record.service';
import {RouterStateObject, UiConstants} from '../../../util/core-utils';
import {Language, SettingsService} from '../../../lib/settings.service';
import {TranslateService} from '@ngx-translate/core';
import {CredentialLoginMethod, CredentialLoginMethodFactory} from "../../../lib/login-methods";
import {ModalDirective} from "ngx-bootstrap/modal";
import {AppType} from "../../../lib/util/app-type-helper.service";

@Component({
  selector: 'app-login-registration',
  templateUrl: './login-registration-container.component.html',
  styleUrls: ['./login-registration-container.component.scss']
})
export class HelpdeskLoginRegistrationContainerComponent implements OnInit, AfterViewInit, OnDestroy {

  UiConstants = UiConstants;
  State = State;
  toasterConfig = toasterConfig;

  state?: State;

  loginModel: HelpdeskLoginModel = new HelpdeskLoginModel();

  registrationModel: HelpdeskRegistrationModel = new HelpdeskRegistrationModel();

  noScrollStyleElement: HTMLStyleElement;

  loading: boolean = false;

  helpdeskToken: string;

  loginMethods: CredentialLoginMethod[] = [];
  @ViewChild('loginMethodSelectDialog', { static: true })
  loginMethodSelectDialog: ModalDirective;
  loginMethodSelectDialogVisible: boolean = false;

  get environmentName(): string {
    return this.configurationService.getConfiguration().environment
      ? this.configurationService.getConfiguration().environment!
      : '';
  }

  get languages(): Language[] {
    return this.settingsService.getAvailableLanguages().toArray();
  }

  constructor(
    private uiRouter: UIRouter,
    private transition: Transition,
    private authService: AuthService,
    private configurationService: ConfigurationService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private registrationService: RegistrationService,
    private translateService: TranslateService,
    private customerRecordService: CustomerRecordService,
    private settingsService: SettingsService,
    private credentialLoginMethodFactory: CredentialLoginMethodFactory,
  ) {
    this.checkConfiguration();
    if (transition.options().custom.fromBase) {
      window.location.reload();
    }
    if (transition.params().drawn) {
      this.state = this.resolveState();
    }
    if (this.resolveState() === State.REGISTRATION) {
      this.loadRegistrationData();
    }
    this.loginMethods = credentialLoginMethodFactory.createAvailableLoginMethods();
  }

  private checkConfiguration() {
    if (!this.configurationService.loaded) {
      localStorage.setItem('configurationError', 'true');
      ServiceErrorStorage.getInstance().setStateObject({
        stateName: this.uiRouter.stateService.$current.name,
        params: this.uiRouter.stateService.params
      });
      this.uiRouter.stateService.go(StateName.CONFIGURATION_LOAD_ERROR);
    }
  }

  ngOnInit(): void {
    if (this.resolveState() === State.LOGIN) {
      this.loading = true;
      this.authService.check({})
        .subscribe(
          (authResult: AuthResult) => {
            this.loading = false;
            if (authResult.user_profile) {
              this.uiRouter.stateService.go(StateName.HELPDESK_DASHBOARD);
            }
          },
          (error: any) => {
            this.loading = false;
          }
        );
    }
    this.disableScrolling();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.state = this.resolveState();
    });
  }

  private loadRegistrationData(): void {
    this.helpdeskToken = this.uiRouter.stateService.params[StateRequestParam.HELPDESK_REGISTRATION_TOKEN];
    this.customerRecordService.getHelpdeskRegistrationData({
      helpdeskToken: this.helpdeskToken
    }).subscribe(result => {
      this.registrationModel.emailAddress = result.emailAddress;
      this.registrationModel.personName = result.name;
      this.registrationModel.customerRecord = result.customerNames ? result.customerNames.join(', ') : '';
    },
      error => {
      this.registrationModel.globalError = error;
      });
  }

  private resolveState(): State {
    switch (this.uiRouter.stateService.current.name) {
      case StateName.HELPDESK_LOGIN:
        return State.LOGIN;
      case StateName.HELPDESK_REGISTRATION:
        return State.REGISTRATION;
      default:
        return State.LOGIN;
    }
  }

  toLogin() {
    this.state = State.LOGIN;
    setTimeout(() => {
      this.uiRouter.stateService.go(StateName.HELPDESK_LOGIN, {drawn: true});
    }, 300);
  }

  login() {
    this.loginModel.inProgress = true;
    this.loginModel.error = null;
    const destination: RouterStateObject = LoginRequiredReasonStorage.getInstance().getStateObject();
    LoginRequiredReasonStorage.getInstance().setReason(null);
    this.authService.userLogin({
      user_name: this.loginModel.username,
      password: this.loginModel.password,
      application_type: AppType.HELPDESK
    }).subscribe(
      (authResult: AuthResult) => {
        this.loginModel.inProgress = false;
        this.afterLogin(authResult, destination);
      },
      (error: ObservableErrorResponse) => {
        this.loginModel.inProgress = false;
        if (error.status === 409) {
          this.showLoginMethodSelectDialog();
          return;
        }
        const body: ErrorResource | null = ObservableErrorResourceParser.parseError(error);
        if (body && body.detail) {
          this.loginModel.error = body.detail;
        }
      }
    );
  }

  loginWithMethod(loginMethod: CredentialLoginMethod) {
    this.loginModel.inProgress = true;
    this.loginModel.error = null;
    this.loginMethodSelectDialog.hide();
    const destination: RouterStateObject = LoginRequiredReasonStorage.getInstance().getStateObject();
    LoginRequiredReasonStorage.getInstance().setReason(null);
    loginMethod.login({
      user_name: this.loginModel.username,
      password: this.loginModel.password,
      application_type: AppType.HELPDESK
    }).subscribe(
      (authResult: AuthResult) => {
        this.loginModel.inProgress = false;
        this.afterLogin(authResult, destination);
      },
      (error: ObservableErrorResponse) => {
        this.loginModel.inProgress = false;
        const body: ErrorResource | null = ObservableErrorResourceParser.parseError(error);
        if (body && body.detail) {
          this.loginModel.error = body.detail;
        }
      }
    );
  }
  private afterLogin(authResult: AuthResult, destination?: RouterStateObject) {
    LoggedInUserStorage.getInstance().setUserId(authResult.user_profile!.id);
    if (destination) {
      this.uiRouter.stateService.go(destination.stateName, destination.params);
    }
    else {
      this.uiRouter.stateService.go(StateName.HELPDESK_DASHBOARD);
    }
  }

  register() {
    this.registrationModel.inProgress = true;
    RecaptchaV3Utils.preAuthenticate(
      this.recaptchaV3Service,
      'registration',
      this.registrationService.registerUserHelpdesk({
        personName: this.registrationModel.personName,
        emailAddress: Services.toEmailAddress(this.registrationModel.emailAddress),
        user: {
          userName: this.registrationModel.emailAddress,
          password: this.registrationModel.password
        },
        helpdeskToken: this.helpdeskToken
      })).subscribe(
      (result) => {
        this.registrationModel.inProgress = false;
        LoginRequiredReasonStorage.getInstance().setReason(LoginRequiredReason.HELPDESK_REGISTRATION_SUCCESSFUL);
        this.toLogin();
      },
      (error: ObservableErrorResponse) => {
        this.registrationModel.inProgress = false;
        const body: ErrorResource | null = ObservableErrorResourceParser.parseError(error);
        if (body) {
          this.registrationModel.fieldErrors = ObservableErrorResourceParser.extractFieldErrors(body);
        }
      }
    );
  }

  showLoginMethodSelectDialog() {
    this.loginMethodSelectDialogVisible = true;
    this.loginMethodSelectDialog.show();
  }

  closeLoginMethodSelectDialog() {
    this.loginMethodSelectDialogVisible = false;
    this.loginMethodSelectDialog.hide();
  }

  private disableScrolling() {
    this.noScrollStyleElement = document.createElement('style');
    this.noScrollStyleElement.type = 'text/css';
    this.noScrollStyleElement.textContent = `
      body {
        overflow-x: hidden !important;
        overflow-y: auto !important;
      }
    `;
    document.body.appendChild(this.noScrollStyleElement);
  }

  onNavigate() {
    window.open('https://www.applion.hu', '_blank');
  }

  isCurrentLanguage(language: Language): boolean {
    return this.settingsService.isCurrentLanguage(language);
  }

  changeLanguage(language: Language) {
    this.settingsService.setLocaleCode(language.localeCode);
    this.translateService.use(language.localeCode).subscribe(
      () => {
        // This does not work:
        // this.router.stateService.reload(StateName.LOGIN);
        // Reload the Angular application instead:
        window.location.reload();
        // Reason:
        // Changing LOCALE_ID (in @angular/core, provided by LOCALE_CODE_PROVIDER) in runtime
        // does no change formatting of pipes.
      });
  }

  ngOnDestroy() {
    if (this.noScrollStyleElement) {
      document.body.removeChild(this.noScrollStyleElement);
    }
  }

}

enum State {
  LOGIN,
  REGISTRATION
}
