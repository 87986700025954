import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import { Map } from 'immutable';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { map, startWith } from 'rxjs/operators';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { InputMask } from '../../util/input-masks';

@Component({
  selector: 'app-material-tag-input',
  templateUrl: './material-tag-input.component.html',
  styleUrls: ['./material-tag-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MaterialTagInputComponent),
      multi: true
    },
  ]
})
export class MaterialTagInputComponent implements OnInit, ControlValueAccessor {

  InputMask = InputMask;

  @Input()
  readonly placeholderStringKey: string = '';

  @Input()
  private readonly autocompleteItems: string[] = [];

  @Input()
  private readonly separatorCharacter?: string = '_';

  @Input()
  private readonly generatedStringExampleMap?: Map<string, string>;

  @Input()
  readonly separatorKeyCodes?: number[] = [ENTER, COMMA];

  @Input()
  readonly shouldGenerateExample?: boolean = false;

  @Input()
  readonly required?: boolean = false;

  @Input()
  form?: NgForm;

  @Input()
  readonly readonly?: boolean = false;

  @Input()
  readonly textMaskInput?: any;

  @Output()
  modelChange: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;

  @ViewChild('autocomplete') autocomplete: MatAutocomplete;

  @ViewChild('trigger') autocompleteTrigger: MatAutocompleteTrigger;

  tagInputControl = new FormControl();

  filteredItems: Observable<string[]>;

  selectedItems: string[] = [];

  onChange = (selectedItems: string) => {
  };

  onTouched = () => {
  };

  get value(): string {
    return this.joinSelectedItems();
  }

  public isAutocompleteItem(item: string): boolean {
    return this.autocompleteItems!.indexOf(item) > -1;
  }

  public joinSelectedItems(): string {
    return this.selectedItems.join(this.separatorCharacter);
  }

  public removeFromItemsByIndex(index: number) {
    this.selectedItems.splice(index, 1);
    this.onChange(this.value);
  }

  public reset() {
    this.selectedItems.splice(0, this.selectedItems.length);
  }

  public generateExampleOutput(): string {
    if (this.generatedStringExampleMap) {
      return this.selectedItems.map(item => this.getExampleStringForKey(item)).join(this.separatorCharacter);
    }
    return '';
  }

  private getExampleStringForKey(key: string): string {
    const value = this.generatedStringExampleMap!.get(key, key);
    if (value) {
      return this.translateService.instant(value);
    }
    return '';
  }

  onAutocompleteItemSelected(event: MatAutocompleteSelectedEvent): void {
    this.selectedItems.push(event.option.value);
    this.onChange(this.value);
    this.tagInput.nativeElement.value = '';
    this.tagInputControl.setValue(null);
    this.autocompleteTrigger.openPanel();
  }

  onItemAdded(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add to selected items
    if ((value || '').trim()) {
      this.selectedItems.push(value.trim());
      this.onChange(this.value);
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
    this.tagInputControl.setValue(null);
    this.autocompleteTrigger.openPanel();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.autocompleteItems.filter(block => this.replaceBrackets(block).toLowerCase().includes(filterValue));
  }

  constructor(private translateService: TranslateService) {
    this.filteredItems = this.tagInputControl.valueChanges.pipe(
      startWith(null),
      map((block: string | null) => block ? this._filter(block) : this.autocompleteItems.slice()));
    this.tagInputControl.valueChanges.subscribe(change => {
      this.modelChange.emit(change);
    });
  }

  ngOnInit() {
  }

  writeValue(selectedItems: string): void {
    this.selectedItems = selectedItems !== null && selectedItems.length > 0 ?
      selectedItems.split(this.separatorCharacter ? this.separatorCharacter : '_') : [];
    this.onChange(selectedItems);
  }

  registerOnChange(fn: (selectedItems: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  formatTagInputItem(item: string): string {
    if (this.autocompleteItems.indexOf(item) > -1) {
      return this.replaceBrackets(item);
    }
    return item;
  }

  replaceBrackets(item: string) {
    return item.replace('{{', '').replace('}}', '');
  }

  doNothing() {
    // literally do nothing
  }
}
