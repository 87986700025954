/* eslint-disable */
import {AfterViewInit, Component, ViewChild,} from '@angular/core';
import {
  FormEditFieldCreateDialogView,
  FormEditFieldDialogContext,
  FormEditFieldUpdateDialogView,
  FormGroupModel,
  FormModel,
} from '../../../../../../../util/form/form-utils';
import {InputMask} from '../../../../../../../util/input-masks';
import {Form} from '../../../../../../../lib/form/form.service';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {FieldWidthType} from '../../../../../../../util/form/form-field-width-type';
import {
  FieldValidationError,
  FormRef,
  LocalFieldValidationErrors,
  LocalFieldValidationErrorsFactory,
  QueryResult,
  ResourceQueryResult,
} from '../../../../../../../lib/util/services';
import {NgModel} from '@angular/forms';
import {List, Set} from 'immutable';
import {
  FieldValidationType,
  FormFieldValidationTypeModel,
} from '../../../../../../../util/form/form-field-validation-type';
import {Models} from '../../../../../../../util/model-utils';
import {
  StockItemCategory,
  StockItemCategoryService
} from '../../../../../../../lib/stock-item-category/stock-item-category.service';
import {MultiselectOptionItem, OptionItem, UiConstants} from '../../../../../../../util/core-utils';
import {Angular2Multiselects} from '../../../../../../../util/multiselect';
import {StringKey} from '../../../../../../../app.string-keys';
import {TranslateService} from '@ngx-translate/core';
import {Strings} from '../../../../../../../lib/util/strings';
import {COMMA, ENTER, SPACE} from '@angular/cdk/keycodes';
import {ToasterService} from '../../../../../../../fork/angular2-toaster/src/toaster.service';
import {MaterialTagInputComponent} from '../../../../../../../shared/material-tag-input/material-tag-input.component';
import {Stock, StockService} from '../../../../../../../lib/stock/stock.service';
import {StockType, StockTypeName} from '../../../../../../../util/stock/stock-utils';

/* eslint-enable */

@Component({
  selector: 'app-form-edit-create-update-stock-field-dialog',
  templateUrl: 'form-edit-create-update-stock-field-dialog.component.html',
  styleUrls: ['form-edit-create-update-stock-field-dialog.component.scss'],
})
export class FormEditCreateUpdateStockFieldDialogComponent
  implements FormEditFieldCreateDialogView, FormEditFieldUpdateDialogView, FormRef, AfterViewInit {

  public readonly selector = Form.FieldDataTypeSelector.STOCK;
  public readonly createTitle = 'FORM_ITEM_CREATE_STOCK_TITLE';
  public readonly updateTitle = 'FORM_ITEM_UPDATE_STOCK_TITLE';
  public readonly cloneTitle = 'FORM_ITEM_CLONE_STOCK_TITLE';

  InputMask = InputMask;
  Form = Form;
  UiConstants = UiConstants;
  StockFormFieldAvailableDiscountsSequence = StockFormFieldAvailableDiscountsSequence;

  @ViewChild('fieldDialog', {static: true})
  dialogComponent: ModalDirective;

  @ViewChild('title')
  titleInput: NgModel;

  @ViewChild('api_export_name')
  apiExportNameInput: NgModel;

  @ViewChild('usableStockItemCategories')
  usableStockItemCategories: NgModel;

  @ViewChild('availableDiscountsTagInput')
  availableDiscountsTagInput: MaterialTagInputComponent;

  visible: boolean = false;
  fieldModel: Model = new Model();
  dialogTitleDictionaryKey: string = '';
  latestApiExportName = '';

  dropdownSettings?: Angular2Multiselects.Settings;
  stockDropdownSettings?: Angular2Multiselects.Settings;
  typeDropdownSettings?: Angular2Multiselects.Settings;

  fieldWidthTypes: FieldWidthType[] = FieldWidthType.VALUES.toArray();
  fieldValidationTypes: Form.FormFieldValidationType[] = FieldValidationType.VALUES
    .toArray();

  private fieldErrors: FieldValidationError<Form.FieldValidatedField> =
    FieldValidationError.empty<Form.FieldValidatedField>();

  private validatedInputs: LocalFieldValidationErrors<NgModel> =
    LocalFieldValidationErrorsFactory.empty();

  private context: FormEditFieldDialogContext;
  private group: FormGroupModel;
  private field?: Form.Field;
  private clone?: boolean;
  private submitted: boolean = false;

  stockItemCategoryItems: StockItemCategoryItem[] = [];
  sourceStocks: MultiselectOptionItem<number>[] = [];
  stockTypes: MultiselectOptionItem<StockTypeName>[] = StockType.VALUES.toArray().map(t => t.toMultiselectOptionItem());

  private initDropDown() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .labelKey(OptionItem.KEY_TEXT)
      .text(StringKey.FORM_ITEM_STOCK_ALL_CATEGORIES)
      .remoteSearch(true)
      .build();
    this.stockDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .remoteSearch(true)
      .build();
    this.typeDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(false)
      .enableCheckAll(false)
      .translate(true)
      .build();
  }

  getForm() {
    return {
      submitted: this.submitted
    };
  }

  ngAfterViewInit(): void {
    this.loadLocalFieldValidationErrors();
  }

  registerContext(context: FormEditFieldDialogContext): void {
    this.context = context;
  }

  showDialog(form: FormModel, group: FormGroupModel, field?: Form.Field, clone?: boolean): void {
    this.latestApiExportName = '';
    this.visible = true;
    this.group = group;
    this.clone = clone;
    this.dialogComponent.show();
    this.initDropDown();
    if (field && clone) {
      this.dialogTitleDictionaryKey = this.cloneTitle;
      this.loadField(field);
    } else if (field) {
      this.dialogTitleDictionaryKey = this.updateTitle;
      this.loadField(field);
    } else {
      this.dialogTitleDictionaryKey = this.createTitle;
    }
  }

  onModalHide(event: ModalDirective) {
    if (event.dismissReason === 'backdrop-click' || event.dismissReason === 'esc') {
      this.visible = false;
      this.submitted = false;
      this.fieldModel = new Model();
    }
  }

  closeDialog(): void {
    this.visible = false;
    this.submitted = false;
    this.dialogComponent.hide();
    this.fieldModel = new Model();
  }

  saveField() {
    this.submitted = true;
    if (this.hasLocalFieldError(this.titleInput)
      || this.hasLocalFieldError(this.apiExportNameInput)
      || this.hasLocalFieldError(this.usableStockItemCategories)) {
      return;
    }
    if (!this.validateAvailableDiscounts()) {
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutLong,
        type: UiConstants.toastTypeError,
        title: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_TITLE),
        body: this.translateService.instant(StringKey.FORM_ITEM_STOCK_AVAILABLE_DISCOUNTS_ERROR)
      });
      return;
    }
    if (this.field && this.clone) {
      this.createField();
    } else if (this.field) {
      this.updateField(this.field);
    } else {
      this.createField();
    }
  }

  private validateAvailableDiscounts(): boolean {
    const discounts: number[]
      = this.fieldModel.availableDiscounts.split(StockFormFieldAvailableDiscountsSequence.sequenceBlockSeparator).map(d => +d);
    for (let i = 0; i < discounts.length; i++) {
      const discount = discounts[i];
      if (discount < -100 || discount > 100) {
        return false;
      }
    }
    return true;
  }

  hasLocalFieldError(field?: NgModel): boolean {
    return this.validatedInputs.hasLocalError(field);
  }

  hasFieldError(field?: Form.FieldValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: Form.FieldValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: Form.FieldValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  getFieldValidationTypeDictionaryKey(vt: Form.FormFieldValidationType): string {
    return FieldValidationType.getFieldValidationTypeDictionaryKey(vt);
  }

  onAdminFormFieldValidationTypeChanged() {
    FieldValidationType.onAdminFormFieldValidationTypeChanged(this.fieldModel);
  }

  onMobileFormFieldValidationTypeChanged() {
    FieldValidationType.onMobileFormFieldValidationTypeChanged(this.fieldModel);
  }

  private parseNumber(text: string): number | undefined {
    return text.length > 0 ? Number(text) : undefined;
  }

  private loadLocalFieldValidationErrors() {
    const validatedInputs = List.of(this.titleInput, this.apiExportNameInput, this.usableStockItemCategories);
    this.validatedInputs = LocalFieldValidationErrorsFactory.ofFields(this, validatedInputs);
  }

  private loadField(field: Form.Field) {
    this.field = field;
    const fieldWidth = FieldWidthType.fromPercentValue(field.formFieldWidthPercent);
    this.fieldModel.title = field.title;
    this.fieldModel.description = field.description ? field.description : '';
    this.fieldModel.apiExportName = field.apiExportName ? field.apiExportName : '';
    this.fieldModel.pdfExportName = field.pdfExportName ? field.pdfExportName : '';
    this.fieldModel.fieldWidthType = fieldWidth ? fieldWidth : FieldWidthType.FULL_SCREEN;
    this.fieldModel.adminVisibleOnFormDetail = field.admin.visibleOnFormDetail;
    this.fieldModel.mobileVisibleOnFormDetail = field.mobile.visibleOnFormDetail;
    this.fieldModel.mobileVisibleOnMasterDetail = field.mobile.visibleOnMasterDetail;
    this.fieldModel.adminFormFieldValidationType = field.admin.validationType;
    this.fieldModel.helpdeskFormFieldValidationType = field.helpdesk.validationType;
    this.fieldModel.mobileFormFieldValidationType = field.mobile.validationType;
    this.fieldModel.showOnReceipt = field.showOnReceipt;
    this.fieldModel.hidePricesOverview = field.dataType.stockAttributes!.hidePricesOverview;
    this.fieldModel.advancedModeEnabled = field.dataType.stockAttributes!.advancedModeEnabled;
    this.fieldModel.hidePricesEdit = field.dataType.stockAttributes!.hidePricesEdit;
    this.fieldModel.modifyStoredAmount = field.dataType.stockAttributes!.modifyStoredAmount;
    this.fieldModel.uniqueStockRecords = field.dataType.stockAttributes!.uniqueStockRecords;
    this.fieldModel.dispersionPercentEnabled = field.dataType.stockAttributes!.dispersionPercentEnabled;
    this.fieldModel.commentEnabled = field.dataType.stockAttributes!.commentEnabled;
    this.fieldModel.commentRequired = field.dataType.stockAttributes!.commentRequired === undefined ? false : field.dataType.stockAttributes!.commentRequired;
    this.fieldModel.commentCustomLabel = field.dataType.stockAttributes!.commentCustomLabel === undefined ? '' : field.dataType.stockAttributes!.commentCustomLabel;
    this.fieldModel.exactCategoryMatch = field.dataType.stockAttributes!.exactCategoryMatch;
    this.fieldModel.fillAssigneeStock = field.dataType.stockAttributes!.fillAssigneeStock;
    this.fieldModel.defaultFilterAssignee = field.dataType.stockAttributes!.defaultFilterAssignee;
    this.fieldModel.clearOnReopen = field.dataType.stockAttributes!.clearOnReopen;
    this.fieldModel.usedForInvoice = field.dataType.stockAttributes!.usedForInvoice;
    this.fieldModel.modifyStoredAmountEnabled = !this.fieldModel.dispersionPercentEnabled;
    this.fieldModel.availableDiscounts
      = field.dataType.stockAttributes!.availableDiscounts.join(StockFormFieldAvailableDiscountsSequence.sequenceBlockSeparator);
    this.loadUsableStockItemCategoryIds(field.dataType.stockAttributes!.usableStockItemCategories);
    this.loadStockSourceFilterIds(field.dataType.stockAttributes!.stockSourceFilters);
    if (field.dataType.stockAttributes!.sourceStockType) {
      this.fieldModel.sourceStockType.push(
        StockType.fromName(field.dataType.stockAttributes!.sourceStockType)!.toMultiselectOptionItem());
    }
  }

  private createField() {
    this.context.formService.createField({
      parentId: this.context.parentId,
      groupId: this.group.groupId,
      title: this.fieldModel.title,
      description: this.fieldModel.description,
      pdfExportName: this.fieldModel.pdfExportName,
      apiExportName: this.fieldModel.apiExportName,
      dataTypeSelector: this.selector,
      dataType: this.getDataTypeRequest(),
      formFieldWidthPercent: this.fieldModel.fieldWidthType.percentValue,
      displayOnNewRow: false,
      admin: {
        visibleOnFormDetail: this.fieldModel.adminVisibleOnFormDetail,
        validationType: this.fieldModel.adminFormFieldValidationType
      },
      helpdesk: {
        validationType: this.fieldModel.helpdeskFormFieldValidationType
      },
      mobile: {
        visibleOnFormDetail: this.fieldModel.mobileVisibleOnFormDetail,
        visibleOnMasterDetail: this.fieldModel.mobileVisibleOnMasterDetail,
        validationType: this.fieldModel.mobileFormFieldValidationType
      },
      showOnReceipt: this.fieldModel.showOnReceipt
    }).subscribe(
      (result) => {
        this.context.formView.reloadForm();
        this.closeDialog();
      },
      (error) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withFormRef(this);
        }
      }
    );
  }

  private updateField(field: Form.Field) {
    this.context.formService.updateField({
      parentId: this.context.parentId,
      groupId: this.group.groupId,
      fieldId: field.fieldId,
      title: this.fieldModel.title,
      description: this.fieldModel.description,
      pdfExportName: this.fieldModel.pdfExportName,
      apiExportName: this.fieldModel.apiExportName,
      dataTypeSelector: this.selector,
      dataType: this.getDataTypeRequest(),
      formFieldWidthPercent: this.fieldModel.fieldWidthType.percentValue,
      displayOnNewRow: false,
      admin: {
        visibleOnFormDetail: this.fieldModel.adminVisibleOnFormDetail,
        validationType: this.fieldModel.adminFormFieldValidationType
      },
      helpdesk: {
        validationType: this.fieldModel.helpdeskFormFieldValidationType
      },
      mobile: {
        visibleOnFormDetail: this.fieldModel.mobileVisibleOnFormDetail,
        visibleOnMasterDetail: this.fieldModel.mobileVisibleOnMasterDetail,
        validationType: this.fieldModel.mobileFormFieldValidationType
      },
      showOnReceipt: this.fieldModel.showOnReceipt
    }).subscribe(
      (result) => {
        this.context.formView.reloadForm();
        this.closeDialog();
      },
      (error) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withFormRef(this);
        }
      }
    );
  }

  private getDataTypeRequest(): Form.FieldDataType {
    const discounts: number[] = this.fieldModel.availableDiscounts
      .split(StockFormFieldAvailableDiscountsSequence.sequenceBlockSeparator).map(d => +d);
    return {
      stockAttributes: {
        advancedModeEnabled: this.fieldModel.advancedModeEnabled,
        hidePricesOverview: this.fieldModel.hidePricesOverview,
        hidePricesEdit: this.fieldModel.hidePricesEdit,
        availableDiscounts: discounts.length > 0 ? discounts : [0],
        usableStockItemCategories: this.getUsableStockItemCategoryIds(),
        sourceStockType: this.fieldModel.sourceStockType.length === 1 ? this.fieldModel.sourceStockType[0]!.id : undefined,
        stockSourceFilters: this.getStockSourceFilterIds(),
        modifyStoredAmount: this.fieldModel.modifyStoredAmount,
        uniqueStockRecords: this.fieldModel.uniqueStockRecords,
        dispersionPercentEnabled: this.fieldModel.dispersionPercentEnabled,
        commentEnabled: this.fieldModel.commentEnabled,
        commentRequired: this.fieldModel.commentEnabled ? this.fieldModel.commentRequired : undefined,
        commentCustomLabel: this.fieldModel.commentEnabled ? Strings.undefinedOrNonEmpty(this.fieldModel.commentCustomLabel) : undefined,
        exactCategoryMatch: this.fieldModel.exactCategoryMatch,
        fillAssigneeStock: this.fieldModel.fillAssigneeStock,
        defaultFilterAssignee: this.fieldModel.defaultFilterAssignee,
        clearOnReopen: this.fieldModel.clearOnReopen,
        usedForInvoice: this.fieldModel.usedForInvoice
      }
    };
  }

  private loadStockItemCategories(searchValue?: string) {
    this.stockItemCategoryService.query({
      name: Strings.undefinedOrNonEmpty(searchValue),
      disabled: false,
      paging: {
        pageNumber: 1,
        numberOfItems: UiConstants.autocompletePageSize
      },
      noProgressBar: true
    }).subscribe((result: QueryResult<StockItemCategory.StockItemCategory>) => {
      this.stockItemCategoryItems = [];
      result.items.forEach((category: StockItemCategory.StockItemCategory) => {
        const item = {
          id: category.id,
          text: category.name,
          disabled: category.disabled
        };
        this.stockItemCategoryItems.push(item);
      });
    });
  }

  getUsableStockItemCategoryIds(): number[] {
    const usableStockItemCategoryIds: number[] = [];
    this.fieldModel.usableStockItemCategories.forEach((stockItemCategoryItem: StockItemCategoryItem) => {
      if (stockItemCategoryItem.id) {
        usableStockItemCategoryIds.push(stockItemCategoryItem.id);
      }
    });
    return usableStockItemCategoryIds;
  };

  loadUsableStockItemCategoryIds(usableStockItemCategoryIds: number[]) {
    if (usableStockItemCategoryIds.length > 0) {
      this.stockItemCategoryService.query({
        id: Set.of(...usableStockItemCategoryIds)
      }).subscribe((result: QueryResult<StockItemCategory.StockItemCategory>) => {
        this.fieldModel.usableStockItemCategories = [];
        result.items.forEach((category: StockItemCategory.StockItemCategory) => {
          const item = {
            id: category.id,
            text: category.name,
            disabled: category.disabled
          };
          this.fieldModel.usableStockItemCategories.push(item);
        });
      });
    }
  };

  sourceStockTypeChanged(item?: MultiselectOptionItem<StockTypeName>) {
    this.loadSourceStocks(undefined, item?.id);
    if (item && item.id !== 'PERSONAL') {
      this.fieldModel.defaultFilterAssignee = false;
    }
  }

  private loadSourceStocks(searchValue?: string, type?: StockTypeName) {
    this.stockService.query({
      name: Strings.undefinedOrNonEmpty(searchValue),
      disabled: false,
      page_number: 1,
      order: '+name',
      type: type,
      number_of_items: UiConstants.autocompletePageSize,
      no_progress_bar: true
    }).subscribe((result: ResourceQueryResult<Stock>) => {
      this.sourceStocks = [];
      result.items.forEach((stock: Stock) => {
        const item = {
          id: stock.id,
          itemName: stock.name,
          itemSubtitle: stock.external_id,
          disabled: stock.disabled
        };
        this.sourceStocks.push(item);
      });
    });
  }

  getStockSourceFilterIds(): number[] {
    const stockSourceFilterIds: number[] = [];
    this.fieldModel.stockSourceFilters.forEach((stockSourceFilter: MultiselectOptionItem<number>) => {
      if (stockSourceFilter.id) {
        stockSourceFilterIds.push(stockSourceFilter.id);
      }
    });
    return stockSourceFilterIds;
  };

  loadStockSourceFilterIds(stockSourceFilterIds: number[]) {
    if (stockSourceFilterIds.length > 0) {
      this.stockService.query({
        order: '+name',
        type: this.fieldModel.sourceStockTypeName,
        id: stockSourceFilterIds.join(',')
      }).subscribe((result: ResourceQueryResult<Stock>) => {
        this.fieldModel.stockSourceFilters = [];
        result.items.forEach((stock: Stock) => {
          const item = {
            id: stock.id,
            itemName: stock.name,
            itemSubtitle: stock.external_id,
            disabled: stock.disabled
          };
          this.fieldModel.stockSourceFilters.push(item);
        });
      });
    }
  };

  onTitleBlur() {
    const normalizedText = Models.normalizeText(this.fieldModel.title);
    if (this.fieldModel.apiExportName === '') {
      this.fieldModel.apiExportName = normalizedText;
    }
    if (this.fieldModel.pdfExportName === '') {
      this.fieldModel.pdfExportName = normalizedText;
    }
  }

  constructor(private stockItemCategoryService: StockItemCategoryService,
              private stockService: StockService,
              private toasterService: ToasterService,
              private translateService: TranslateService) {
  }

  onDispersionPercentEnabledChange(dispersionPercentEnabled: boolean) {
    if (dispersionPercentEnabled) {
      this.fieldModel.hidePricesEdit = true;
      this.fieldModel.hidePricesOverview = true;
      this.fieldModel.modifyStoredAmount = false;
      this.fieldModel.modifyStoredAmountEnabled = false;
    } else {
      this.fieldModel.modifyStoredAmountEnabled = true;
    }
  }

  checkAvailableDiscounts() {
    const tags = this.fieldModel.availableDiscounts.split(StockFormFieldAvailableDiscountsSequence.sequenceBlockSeparator);
    if (tags.length > 0 && tags[tags.length - 1].endsWith('-')) {
      tags.splice(tags.length - 1, 1);
      this.fieldModel.availableDiscounts = tags.join(StockFormFieldAvailableDiscountsSequence.sequenceBlockSeparator);
      this.availableDiscountsTagInput.writeValue(this.fieldModel.availableDiscounts);
    }
  }
}

export class Model implements FormFieldValidationTypeModel {
  title: string = '';
  description: string = '';
  apiExportName: string = '';
  pdfExportName: string = '';
  fieldWidthType: FieldWidthType = FieldWidthType.FULL_SCREEN;
  adminVisibleOnFormDetail: boolean = true;
  mobileVisibleOnFormDetail: boolean = true;
  mobileVisibleOnMasterDetail: boolean = false;
  adminFormFieldValidationType: Form.FormFieldValidationType = Form.FormFieldValidationType.OPTIONAL;
  helpdeskFormFieldValidationType: Form.FormFieldValidationType = Form.FormFieldValidationType.OPTIONAL;
  mobileFormFieldValidationType: Form.FormFieldValidationType = Form.FormFieldValidationType.OPTIONAL;
  showOnReceipt?: boolean = false;
  hidePricesOverview: boolean = false;
  advancedModeEnabled: boolean = false;
  hidePricesEdit: boolean = false;
  modifyStoredAmount: boolean = false;
  modifyStoredAmountEnabled: boolean = true;
  uniqueStockRecords: boolean = false;
  dispersionPercentEnabled: boolean = false;
  commentEnabled: boolean = false;
  commentRequired: boolean = false;
  commentCustomLabel: string = '';
  exactCategoryMatch: boolean = false;
  fillAssigneeStock: boolean = false;
  defaultFilterAssignee: boolean = false;
  clearOnReopen: boolean = false;
  usedForInvoice: boolean = true;
  usableStockItemCategories: StockItemCategoryItem[] = [];
  sourceStockType: MultiselectOptionItem<StockTypeName>[] = [];
  stockSourceFilters: MultiselectOptionItem<number>[] = [];
  availableDiscounts: string = '';

  get sourceStockTypeName(): StockTypeName | undefined {
    return this.sourceStockType.length > 0 ? this.sourceStockType[0].id : undefined;
  }
}

interface StockItemCategoryItem extends OptionItem<number> {
}

export namespace StockFormFieldAvailableDiscountsSequence {

  export const sequenceBlockSeparator = '/';
  export const separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];

}
