import { List } from 'immutable';

export class FieldViewItemOrder {

  private currentOrder: List<number | null>;

  constructor(
    private readonly originalOrder: List<number | null>
  ) {
    this.currentOrder = originalOrder;
  }

  remove(id: number, withLeadingNull: boolean): number | undefined {
    const index = this.currentOrder.indexOf(id);
    if (index < 0) {
      if (!this.originalOrder.includes(id)) {
        throw new Error('Invalid id!');
      }
      return undefined;
    }
    this.currentOrder = this.currentOrder.remove(index);
    if (withLeadingNull) {
      this.currentOrder = this.currentOrder.remove(index - 1);
    }
    return index;
  }

  insert(id: number, withLeadingNull: boolean): number {
    const originalIndex = this.originalOrder.indexOf(id);
    if (originalIndex < 0) {
      throw new Error('Invalid id!');
    }
    for (let i = 0; i < this.currentOrder.size; i++) {
      const index = this.originalOrder.indexOf(this.currentOrder.get(i));
      if (index >= originalIndex) {
        if (i > 0 && this.currentOrder.get(i - 1) === null) {
          i--;
        }
        this.currentOrder = this.currentOrder.insert(i, id);
        if (withLeadingNull) {
          this.currentOrder = this.currentOrder.insert(i, null);
        }
        return i;
      }
    }
    const index = this.currentOrder.size;
    this.currentOrder = this.currentOrder.insert(index, id);
    if (withLeadingNull) {
      this.currentOrder = this.currentOrder.insert(index, null);
    }
    return index;
  }

}
