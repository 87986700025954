/* eslint-disable */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Set } from 'immutable';
import { Transition } from '@uirouter/angular';
import { MultiselectOptionItem, QueryFieldModel, SelectUtils, UiConstants } from '../../../../util/core-utils';
import { MasterDataRecord, MasterDataRecordService } from '../../../../lib/masterdata/master-data-record.service';
import { OrderType, QueryResult, } from '../../../../lib/util/services';
import { RightModel } from '../../../../app.rights';
import { GrantedPermissionSetResolver, RightResolver, RightService } from '../../../../lib/right.service';
import { FileUploadDialogComponent } from '../../../../shared/file-upload/dialog/file-upload-dialog.component';
import { BreadcrumbParent } from '../../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { TranslateService } from '@ngx-translate/core';
import { MasterData, MasterDataService } from '../../../../lib/masterdata/master-data.service';
import { StateName } from '../../../../app.state-names';
import { InputMask } from '../../../../util/input-masks';
import { MasterdataRecordDisabledItem, MasterdataRecordSearchModel } from '../../../../util/masterdata-record-utils';
import { combineLatest, Observable } from 'rxjs';
import { Arrays } from '../../../../lib/util/arrays';
import { Models } from '../../../../util/model-utils';
import { Strings } from '../../../../lib/util/strings';
import { MasterdataRecordSearch, MasterdataRecordSearchService } from '../../../../lib/masterdata-record-search-service';
import { DisabledEnum, DisabledItem } from '../../../../util/search-utils';
import { saveAs } from 'file-saver';
import { DownloadedFile } from '../../../../lib/util/downloaded-files';
import { DropdownItemType } from '../../../../shared/dropdown/dropdown-item/dropdown-item-type';
import { CustomerRecord, CustomerRecordService } from '../../../../lib/customer/customer-record.service';
import { CustomerRecordContactLocationMultiselectProvider } from '../../../../lib/customer/contact/customer-record-contact-location-multiselect.provider';
import { MatDialog } from '@angular/material/dialog';
import { ConfigurationService } from '../../../../lib/core-ext/configuration.service';
import { DemoModeFeatureDisabledDialogComponent } from '../../../../shared/demo-mode-feature-disabled-dialog/demo-mode-feature-disabled-dialog.component';
import { MasterDataRecordCreateRightModel } from '../../../../app.rights.masterdatarecord';
import { User, UserService } from '../../../../lib/user.service';
import { MasterDataRecordRightModel } from '../../../../lib/masterdata/master-data-record-right.model';
import { OperationRights } from '../../../../app.right-definitions';
import { DqlStoredQueryArgs } from '../../../dql-search/dql-search-container/dql-stored-query.args';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { DqlSearchContainerComponent } from '../../../dql-search/dql-search-container/dql-search-container.component';
import { MasterDataRecordDqlFieldTextProvider } from '../../../../lib/masterdata/master-data-record.dql.service';

/* eslint-enable */

@Component({
  selector: 'app-masterdata-record-list',
  templateUrl: 'masterdata-record-list.component.html',
  styleUrls: ['masterdata-record-list.component.scss']
})
export class MasterDataRecordListComponent implements OnInit, OnDestroy {

  MasterDataRecord = MasterDataRecord;
  UiConstants = UiConstants;
  DropdownItemType = DropdownItemType;

  queryModel: QueryFieldModel<MasterDataRecord.OrderField> = new QueryFieldModel(MasterDataRecord.OrderField.ID, OrderType.DESC);
  masterDataRecordList: {
    masterDataRecord: MasterDataRecord.MasterDataRecord,
    rights: MasterDataRecordRightModel,
    customerRecord?: CustomerRecord.CustomerRecord,
    contactLocation?: MultiselectOptionItem<number>
  }[] = [];
  showSearch: boolean = false;
  rightModel: RightModel = RightModel.empty();
  // Only for a potential global list
  masterdataCreateRightModel: MasterDataRecordCreateRightModel = MasterDataRecordCreateRightModel.empty();
  readonly masterDataId: number;
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  searchModel: MasterdataRecordSearchModel = new MasterdataRecordSearchModel();
  masterdataRecordDisabledItems: DisabledItem[] = [];
  InputMask = InputMask;
  SelectUtils = SelectUtils;

  activeSearchTab: string = 'simple';
  dqlStoredArgs: DqlStoredQueryArgs;

  private _searchTabs?: TabsetComponent;

  @ViewChild('searchTabs') set searchTabs(c: TabsetComponent) {
    if (c && !this._searchTabs) {
      this._searchTabs = c;
      if (this.searchModel.dqlText) {
        this.activeSearchTab = 'dql';
        this._searchTabs.tabs[1].active = true;
      }
      else {
        this.activeSearchTab = 'simple';
        this._searchTabs.tabs[0].active = true;
      }
    }
    if (!c) {
      this._searchTabs = c;
    }
  }

  private dqlSearchContainer?: DqlSearchContainerComponent;

  @ViewChild('dqlSearchContainer') set dqlContainer(c: DqlSearchContainerComponent) {
    if (c) {
      if (!this.dqlSearchContainer) {
        const qt = this.searchModel.dqlText;
        this.dqlSearchContainer = c;
        this.loadDqlModel(() => {
          this.loadDqlSearch(qt);
          this.dqlSearchContainer!.loadContent();
        });
      }
    }
    else {
      this.dqlSearchContainer = undefined;
    }
  }

  @ViewChild('importDialog', {static: true}) importDialog: FileUploadDialogComponent;
  uploadPath: string;

  get demoModeEnabled(): boolean {
    return this.configService.getConfiguration().feature_flags.demo_mode_enabled;
  }

  constructor(
    private transition: Transition,
    private rightService: RightService,
    private translateService: TranslateService,
    private masterDataService: MasterDataService,
    private dialog: MatDialog,
    private userService: UserService,
    private configService: ConfigurationService,
    private masterDataRecordService: MasterDataRecordService,
    private customerRecordService: CustomerRecordService,
    private contactLocationProvider: CustomerRecordContactLocationMultiselectProvider,
    private masterdataRecordSearchService: MasterdataRecordSearchService) {
    this.masterDataId = this.transition.params().id;
    this.uploadPath = '/master-data/' + this.masterDataId + '/records/import-xls';
    this.dqlStoredArgs = {service: masterDataService.dqlStoredQueryService, parentId: this.masterDataId, documentType: undefined};
  }

  ngOnInit() {
    this.translateService.get('MENU_NAVBAR_MASTERDATA_RECORD').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.MASTERDATA_DASHBOARD});
      }
    );
    this.loadSearch(() => {
      this.showSearch = !this.searchModel.isEmpty();
      this.initDisabledOptions(DisabledEnum.FALSE);
      this.loadList();
    });
    this.loadRightModels();
    this.loadMasterdataName();
  }

  private loadSearch(completion: () => void) {
    const obs: Observable<SearchLoadResult> = combineLatest(
      this.masterdataRecordSearchService.getSearchData({}),
      (storedSearchData: MasterdataRecordSearch.SearchDataResult) => {
        const result: SearchLoadResult = {
          storedSearchData: storedSearchData
        };
        return result;
      }
    );
    obs.subscribe(
      (result: SearchLoadResult) => {
        this.postInitSearch(result.storedSearchData);
        completion();
      }
    );
  }

  private initDisabledOptions(initValue: DisabledEnum) {
    this.masterdataRecordDisabledItems = [];
    const disabledEnums: DisabledEnum[] = [DisabledEnum.NONE, DisabledEnum.FALSE, DisabledEnum.TRUE];
    Arrays.iterateByIndex(disabledEnums, (key) => {
      const item = new DisabledItem();
      item.id = key;
      this.translateService.get('COMMON_VALUE_DISABLED_ENUM_' + key).subscribe(
        (text: string) => {
          item.text = text;
        }
      );
      this.masterdataRecordDisabledItems.push(item);
      if (!this.searchModel.disabled) {
        if (key === initValue) {
          this.searchModel.disabled = item;
        }
      }
    });
  }

  private postInitSearch(storedSearchData: MasterdataRecordSearch.SearchDataResult) {
    this.queryModel.itemsPerPage = storedSearchData.searchData.itemsPerPage;
    this.queryModel.currentPage = storedSearchData.searchData.pageNumber;
    this.queryModel.setOrder(storedSearchData.searchData.order);
    this.searchModel.id = storedSearchData.searchData.id;
    this.searchModel.name = storedSearchData.searchData.name;
    this.searchModel.externalId = storedSearchData.searchData.external_id;
    this.searchModel.disabled = storedSearchData.searchData.disabled;
    this.searchModel.dqlText = storedSearchData.searchData.dqlText;
  }

  private getActiveItem(): MasterdataRecordDisabledItem {
    const item = new MasterdataRecordDisabledItem();
    item.id = 'ACTIVE';
    item.text = 'MASTERDATA_RECORD_SEARCH_FIELD_DISABLED_ACTIVE';
    return item;
  }

  loadMasterdataName() {
    this.masterDataService.get({
      masterDataId: this.masterDataId,
    }).subscribe(
      (result: MasterData.MasterData) => {
        this.breadcrumbSelf = result.name;
      }
    )
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        this.userService.get({id: resolver.userProfile!.id}).subscribe((user: User) => {
          this.masterdataCreateRightModel = MasterDataRecordCreateRightModel.of({
            rightModel: this.rightModel,
            currentUser: resolver.userProfile,
            currentUserGroups: user.user_group_ids
          });
        });
      }
    );
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: MasterDataRecord.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  loadDqlModel(completion?: () => void) {
    this.dqlSearchContainer!.setFields(this.masterDataService.getDqlModel({masterDataId: this.masterDataId}),
      MasterDataRecordDqlFieldTextProvider.getHelper(),
      completion ? completion : () => {
      });
  }

  loadDqlSearch(queryString?: string) {
    if (queryString) {
      this.dqlSearchContainer!.loadQuery(queryString);
    }
  }

  setDisabled(event: any, masterDataRecord: MasterDataRecord.MasterDataRecord, disabled: boolean) {
    this.masterDataRecordService.setDisabled({
      masterDataId: masterDataRecord.masterDataId,
      masterDataRecordId: masterDataRecord.masterDataRecordId,
      disabled: disabled
    }).subscribe(
      (result: MasterDataRecord.MasterDataRecord) => {
        this.loadList();
      },
      (error: any) => {
        this.loadList();
      }
    );
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  public onImportSuccess(succeeded: boolean) {
    if (succeeded) {
      this.loadList();
    }
  }

  private loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const customerRecordIds: number[] = [];
    const contactLocationsIds: number[] = [];
    this.masterDataRecordService.query(this.getQueryRequest(requestedPage)).subscribe(
      (result: QueryResult<MasterDataRecord.MasterDataRecord>) => {
        this.masterDataRecordList = result.items.toArray().map(r => ({
          masterDataRecord: r,
          rights: new MasterDataRecordRightModel(GrantedPermissionSetResolver.byGrantedRights(r.grantedRights)),
          customerRecord: undefined
        }));
        this.masterDataRecordList.forEach(r => {
          if (r.masterDataRecord.ownerCustomerRecordId) {
            customerRecordIds.push(r.masterDataRecord.ownerCustomerRecordId);
          }
          if (r.masterDataRecord.ownerContactLocationId) {
            contactLocationsIds.push(r.masterDataRecord.ownerContactLocationId);
          }
        });
        if (customerRecordIds.length > 0) {
          this.loadCustomerRecords(customerRecordIds);
        }
        if (contactLocationsIds.length > 0) {
          this.loadContactLocations(contactLocationsIds);
        }
        this.queryModel.currentPage = requestedPage;
        this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
        this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
      }
    );
  }

  private loadCustomerRecords(ids: number[]) {
    this.customerRecordService.globalQuery({
      customerRecordIdSet: Set.of(...ids),
      fields: Set.of('id', 'customer_id', 'name')
    }).subscribe(crs => {
      this.masterDataRecordList.forEach(r => {
        if (r.masterDataRecord.ownerCustomerRecordId) {
          r.customerRecord = crs.items.toArray().find(c => c.customerRecordId === r.masterDataRecord.ownerCustomerRecordId);
        }
      });
    });
  }

  private loadContactLocations(ids: number[]) {
    this.contactLocationProvider.getByIds(ids).subscribe(result => {
      this.masterDataRecordList.forEach(r => {
        if (r.masterDataRecord.ownerContactLocationId) {
          r.contactLocation = result.find(l => l.id === r.masterDataRecord.ownerContactLocationId);
        }
      })
    });
  }

  downloadAttachmentZip() {
    this.masterDataRecordService.query(this.getQueryRequest(undefined, Set.of('id')))
      .subscribe((result: QueryResult<MasterDataRecord.MasterDataRecord>) => {
        const idSet: number[] = [];
        result.items.forEach(mr => {
          if (mr) {
            idSet.push(mr.masterDataRecordId);
          }
        });
        this.masterDataRecordService.downloadAttachmentZip(
          {
            masterDataRecordIds: Set.of(...idSet)
          }).subscribe(res => {
          saveAs(res.getBlob(), res.getFileName('attachments.zip'));
        });
      });
  }

  exportXls() {
    const request = this.getQueryRequest();
    this.masterDataRecordService.exportXls(request).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName('master_data_record.xlsx'));
      }
    );
  }

  exportXlsTemplate() {
    this.masterDataRecordService.exportXlsTemplate({id: this.masterDataId!}).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName('master_data_template.xlsx'));
      }
    );
  }

  private getQueryRequest(pageNumber?: number, fields?: Set<string>): MasterDataRecord.QueryRequest {
    const order = this.queryModel.getOrder();
    const masterdataRecordIdSet: number[] = [];
    const disabled: boolean | undefined = !this.searchModel.disabled ||
    this.searchModel.disabled.id === DisabledEnum.NONE ?
      undefined : this.searchModel.disabled.id === DisabledEnum.TRUE;
    if (Models.parseNumber(this.searchModel.id)) {
      masterdataRecordIdSet.push(Models.parseNumber(this.searchModel.id)!);
    }
    const rights = Set.of(
      OperationRights.MASTER_DATA_RECORD_READ,
      OperationRights.MASTER_DATA_RECORD_UPDATE,
      OperationRights.MASTER_DATA_RECORD_DISABLE
    );
    return {
      masterDataId: this.masterDataId,
      masterDataRecordIdSet: Set.of(...masterdataRecordIdSet),
      name: Strings.undefinedOrNonEmpty(this.searchModel.name),
      externalId: Strings.undefinedOrNonEmpty(this.searchModel.externalId),
      disabled: disabled,
      dqlText: Strings.undefinedOrNonEmpty(this.searchModel.dqlText),
      fields: fields ? fields : undefined,
      orders: Set.of(order),
      paging: pageNumber ? {
        pageNumber: pageNumber,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined,
      rights: rights
    }
  }

  getDisabled(): boolean | undefined {
    if (this.searchModel.disabled!.id === DisabledEnum.FALSE) {
      return false;
    }
    else if (this.searchModel.disabled!.id === DisabledEnum.TRUE) {
      return true;
    }
    return undefined;
  }

  onSearchClicked() {
    this.searchModel.dqlText = undefined;
    this.loadList(1);
  }

  onDqlSearchClicked() {
    const dqlQueryString = this.dqlSearchContainer!.getQueryString();
    if (dqlQueryString) {
      this.searchModel.clear();
    }
    this.searchModel.dqlText = dqlQueryString;
    this.loadList(1);
  }

  onSearchReset() {
    this.masterdataRecordSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.showSearch = true;
          this.loadList(1);
        });
      }
    );
  }

  private saveSearch() {
    const request = {
      searchData: {
        itemsPerPage: this.queryModel.itemsPerPage,
        pageNumber: this.queryModel.currentPage,
        order: this.queryModel.getOrder(),
        id: this.searchModel.id,
        name: this.searchModel.name,
        external_id: this.searchModel.externalId,
        disabled: this.searchModel.disabled,
        dqlText: this.searchModel.dqlText
      }
    };
    this.masterdataRecordSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  showDisabledDialog() {
    this.dialog.open(DemoModeFeatureDisabledDialogComponent);
  }

  ngOnDestroy() {
    this.saveSearch();
  }

}

interface SearchLoadResult {
  storedSearchData: MasterdataRecordSearch.SearchDataResult,
}
