/* eslint-disable */
import { FormRecordFieldView, FormRecordFieldViewModel } from '../../../../util/form/form-utils';
import { Form } from '../../../../lib/form/form.service';
import { Injectable } from '@angular/core';

/* eslint-enable */

@Injectable()
export class FormRecordFieldViewModelFactory {

  /**
   * Undefined values must be ignored by the caller
   * @param fieldView
   */
  createModel(fieldView: FormRecordFieldView): FormRecordFieldViewModel | undefined {
    const ctx = fieldView.formRecordFieldContext;
    if (!ctx) {
      throw Error('FormRecordFieldView#formRecordFieldContext is undefined');
    }
    if (fieldView.tmpReadonly) {
      // inactive field
      // do not send to the server
      return undefined;
    }
    if (fieldView.formRecordFieldContext?.field.disabled) {
      return undefined;
    }
    if (Form.FormFieldValidationType.READONLY === ctx.validationType && ctx.fieldRecord === undefined) {
      // readonly field and there is no previous data (record creation)
      // do not send to the server
      return undefined;
    }
    if (Form.FormFieldValidationType.READONLY === ctx.validationType && ctx.cloning()) {
      // readonly field but cloning is active
      // do not send to the server
      return undefined;
    }
    if (Form.FormFieldValidationType.HIDDEN === ctx.validationType && ctx.fieldRecord === undefined) {
      // hidden field and there is no previous data (record creation)
      // do not send to the server
      return undefined;
    }
    if (Form.FormFieldValidationType.HIDDEN === ctx.validationType && ctx.cloning()) {
      // hidden field but cloning is active
      // do not send to the server
      return undefined;
    }
    return fieldView.createModel();
  }

  constructor() {
  }

}
