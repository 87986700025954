/* eslint-disable */
import { Component, Input } from '@angular/core';
/* eslint-enable */

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {

  @Input()
  parents: BreadcrumbParent[];

  @Input()
  self: string;

  constructor() {}
}

export interface BreadcrumbParent {
  name: string;
  uiSref: string,
  uiParam?: any;
}
