<div class="modal-content">
  <div class="modal-header">
    <p class="modal-title"><strong>{{'STOCK_RECORD_SELECTOR_DIALOG_TITLE' | translate}}</strong></p>
    <button type="button" class="close" (click)="closeDialog()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="filter-outer-container">
      <div class="filter-container">
        <div class="d-flex justify-content-between align-items-center cursor-pointer" (click)="toggleFilter()">
          <span class="filter-title">{{'STOCK_RECORD_SELECTOR_FILTER_TITLE' | translate}}</span>
          <div class="position-relative">
            <i class="icomoon icomoon-arrow-up filter-icon" [class.filter-icon-flipped]="showFilter"></i>
            <div class="filter-applied-indicator" *ngIf="filterAppliedIndicatorVisible"></div>
          </div>
        </div>
        <div *ngIf="showFilter">
          <div class="row form-group mt-1">
            <div class="col-md-3 col-sm-6 mb-1">
              <label class="filter-label">{{'STOCK_RECORD_SELECTOR_FILTER_ASSIGNEE' | translate}}</label>
              <angular2-multiselect
                [(ngModel)]="filterModel.assignee"
                (ngModelChange)="onAssigneeChanged()"
                [ngModelOptions]="{standalone: true}"
                [data]="users"
                (onRemoteSearch)="loadUsers($event.target.value)"
                [settings]="dropdownSettings">
              </angular2-multiselect>
            </div>
            <div class="col-md-3 col-sm-6 mb-1">
              <label class="filter-label">{{'STOCK_RECORD_SELECTOR_FILTER_STOCK' | translate}}</label>
              <angular2-multiselect
                [(ngModel)]="filterModel.stock"
                (ngModelChange)="onStockChanged()"
                [ngModelOptions]="{standalone: true}"
                [data]="stocks"
                (onRemoteSearch)="loadStocks($event.target.value)"
                [settings]="dropdownSettings">
              </angular2-multiselect>
            </div>
            <div class="col-md-6 col-sm-12 mb-1">
              <label class="filter-label">{{'STOCK_RECORD_SELECTOR_FILTER_STOCK_ITEM_NAME' | translate}}</label>
              <input type="text" class="form-control" placeholder="{{'STOCK_RECORD_SELECTOR_FILTER_STOCK_ITEM_NAME' | translate}}"
                     maxlength="{{UiConstants.maxInputLongLength}}"
                     (keyup.enter)="loadStockRecords(1)"
                     [(ngModel)]="filterModel.stockItemQuery">
            </div>
          </div>
          <div class="filter-parent-categories">
            <ng-container *ngFor="let category of filterModel.stockItemCategory; let last = last">
              <span [class.filter-parent-category-clickable]="!last"
                    (click)="!categoriesLoading && !last && selectParentCategory(category)">
                {{category.itemName}}
              </span>
              <span *ngIf="!last">/</span>
            </ng-container>
          </div>
          <div class="filter-categories">
            <mat-chip-list>
              <mat-chip *ngFor="let category of stockItemCategories" (click)="!categoriesLoading && selectCategory(category)">{{category.itemName}}</mat-chip>
            </mat-chip-list>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between record-header">
      <span class="record-header-title">{{'STOCK_RECORD_SELECTOR_DIALOG_RECORDS_TITLE' | translate}}</span>
      <div class="row m-0">
        <mat-checkbox class="record-select-all"
                      (click)="selectAll()"
                      [checked]="allSelected"
                      [disabled]="stockRecords.length === 0" >
          {{'STOCK_RECORD_SELECTOR_DIALOG_SELECT_ALL' | translate}}
        </mat-checkbox>
        <div class="record-number-of-items hidden-xs-down">
          <span class="record-number-of-items-title">{{'SELECTOR_DIALOG_NUMBER_OF_ITEMS' | translate}}</span>
          <span class="record-number-of-items-option" *ngFor="let option of numberOfItemsOptions; let last = last"
                [class.record-number-of-items-option-active]="queryModel.itemsPerPage === option"
                [class.mr-0]="last"
                (click)="onItemsPerPageChange(option)">
              {{option}}
            </span>
        </div>
        <div class="view-selector">
          <i class="icomoon icomoon-card-view ml-1 mr-1" [class.view-selector-active]="viewMode === ViewMode.CARD" (click)="changeViewMode(ViewMode.CARD)"></i>
          <i class="icomoon icomoon-transport-list" [class.view-selector-active]="viewMode === ViewMode.LIST" (click)="changeViewMode(ViewMode.LIST)"></i>
        </div>
      </div>
    </div>
    <div class="grid-container" *ngIf="viewMode === ViewMode.CARD && stockRecords.length > 0">
      <div class="row m-0">
        <div class="record-container col-xl-2 col-lg-3 col-md-4 col-sm-6"
             *ngFor="let record of stockRecords | paginate: {
                      id: pagingId,
                      itemsPerPage: queryModel.itemsPerPage,
                      currentPage: queryModel.currentPage,
                      totalItems: queryModel.totalNumberOfItems }">
          <app-stock-record-selector-card-item
            [record]="record"
            [allSelected]="allSelected"
            (onToggleSelected)="toggleSelected($event)">
          </app-stock-record-selector-card-item>
        </div>
      </div>
      <div class="pagination-container">
        <app-table-paging
          [id]="pagingId"
          [currentNumberOfItems]="queryModel.currentNumberOfItems"
          [totalNumberOfItems]="queryModel.totalNumberOfItems"
          [itemsPerPage]="queryModel.itemsPerPage"
          (pageChange)="onPageChange($event)"
          (itemsPerPageChange)="onItemsPerPageChange($event)">
        </app-table-paging>
      </div>
    </div>
    <div class="list-container" *ngIf="viewMode === ViewMode.LIST && stockRecords.length > 0">
      <table class="table table-striped table-bordered mb-0">
        <thead>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="StockRecord.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(StockRecord.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="StockRecord.OrderField.STOCK_ITEM_NAME"
                                    [orderType]="queryModel.getOrderType(StockRecord.OrderField.STOCK_ITEM_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'STOCK_RECORD_SELECTOR_DIALOG_TABLE_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-field-sorter [orderField]="StockRecord.OrderField.STOCK_ITEM_PRODUCT_CODE"
                                    [orderType]="queryModel.getOrderType(StockRecord.OrderField.STOCK_ITEM_PRODUCT_CODE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'STOCK_RECORD_SELECTOR_DIALOG_TABLE_PRODUCT_CODE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-field-sorter [orderField]="StockRecord.OrderField.STOCK_NAME"
                                    [orderType]="queryModel.getOrderType(StockRecord.OrderField.STOCK_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'STOCK_RECORD_SELECTOR_DIALOG_TABLE_STOCK' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-field-sorter [orderField]="StockRecord.OrderField.AMOUNT"
                                    [orderType]="queryModel.getOrderType(StockRecord.OrderField.AMOUNT)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'STOCK_RECORD_SELECTOR_DIALOG_TABLE_AMOUNT' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op [text]="'STOCK_RECORD_SELECTOR_DIALOG_TABLE_PRICE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
          </th>
        </tr>
        </thead>
        <tbody>
          <tr *ngFor="let record of stockRecords | paginate: {
                          id: pagingId,
                          itemsPerPage: queryModel.itemsPerPage,
                          currentPage: queryModel.currentPage,
                          totalItems: queryModel.totalNumberOfItems }"
              stock-record-selector-list-item
              [record]="record"
              [allSelected]="allSelected"
              (onToggleSelected)="toggleSelected($event)">
          </tr>
        </tbody>
      </table>
      <div class="pagination-container">
        <app-table-paging
          [id]="pagingId"
          [currentNumberOfItems]="queryModel.currentNumberOfItems"
          [totalNumberOfItems]="queryModel.totalNumberOfItems"
          [itemsPerPage]="queryModel.itemsPerPage"
          (pageChange)="onPageChange($event)"
          (itemsPerPageChange)="onItemsPerPageChange($event)">
        </app-table-paging>
      </div>
    </div>
    <div class="empty-container" *ngIf="stockRecords.length === 0">
      <img src="../../../../assets/img/stock/stock-item-placeholder.png" class="empty-icon">
      <p class="empty-title">{{'STOCK_RECORD_SELECTOR_DIALOG_RECORDS_EMPTY_TITLE' | translate}}</p>
      <p class="empty-subtitle">{{'STOCK_RECORD_SELECTOR_DIALOG_RECORDS_EMPTY_SUBTITLE' | translate}}</p>
    </div>
    <div class="loading-container" *ngIf="firstLoading">
      <div class="spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div>
    <div class="spinner" *ngIf="loading">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
    <button type="button" class="btn btn-primary save-button" (click)="saveSelection()" *ngIf="selectedStockRecords.length > 0 || allSelected">
      {{(selectedStockRecords.length > 1 ? 'STOCK_RECORD_SELECTOR_DIALOG_SAVE_BUTTON_PLURAL' : 'STOCK_RECORD_SELECTOR_DIALOG_SAVE_BUTTON_SINGLE')
      | translate: {count: allSelected ? queryModel.totalNumberOfItems : selectedStockRecords.length} }}
    </button>
  </div>
</div>
