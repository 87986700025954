import { Component, OnInit, ViewChild } from '@angular/core';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { ComponentStateResolver } from '../../../util/component-state/component-state-resolver';
import { RightModel } from '../../../app.rights';
import { ExteriorTransport, ExteriorTransportService } from '../../../lib/exterior-transport/exterior-transport.service';
import { TranslateService } from '@ngx-translate/core';
import { StateName } from '../../../app.state-names';
import { Transition, UIRouter } from '@uirouter/angular';
import { GrantedPermissionSetResolver, RightResolver, RightService } from '../../../lib/right.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { UiConstants } from '../../../util/core-utils';
import { ExteriorTransportShipmentsComponent } from './exterior-transport-shipments/exterior-transport-shipments.component';
import { ExteriorTransportBaseDataModel } from './exterior-transport-base-data/exterior-transport-base-data.model';
import { ExteriorTransportRightModel } from '../../../lib/exterior-transport/exterior-transport-right.model';
import { OperationRights } from '../../../app.right-definitions';
import { Set } from 'immutable';
import { DownloadedFile } from '../../../lib/util/downloaded-files';
import { saveAs } from 'file-saver';
import { AlertType, ConfirmDialogComponent } from '../../../shared/confirm-dialog/confirm-dialog.component';
import { Shipment } from '../../../lib/shipment-group/shipment-group.service';

@Component({
  selector: 'app-exterior-transport-base',
  templateUrl: './exterior-transport-base.component.html',
  styleUrls: ['./exterior-transport-base.component.scss']
})
export class ExteriorTransportBaseComponent implements OnInit {

  UiConstants = UiConstants;
  AlertType = AlertType;

  @ViewChild('baseDataDialog', { static: true })
  baseDataDialog: ModalDirective;
  baseDataDialogVisible: boolean = false;

  @ViewChild('shipmentsComponent', { static: true })
  shipmentsComponent: ExteriorTransportShipmentsComponent;

  // Variables used for breadcrumb
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  // Component state resolver, determines the state of the component
  componentState: ComponentStateResolver;

  // Right model to store the rights available to the User
  rightModel: RightModel = RightModel.empty();
  rights: ExteriorTransportRightModel = new ExteriorTransportRightModel(GrantedPermissionSetResolver.empty());

  baseDataModel: ExteriorTransportBaseDataModel = new ExteriorTransportBaseDataModel();

  @ViewChild('mplExportDialog', { static: true })
  mplExportDialog: ModalDirective;
  mplExportDialogVisible: boolean = false;

  @ViewChild('shipmentRemoveDialog', { static: true })
  shipmentRemoveDialog: ConfirmDialogComponent;
  shipmentRemoveDialogVisible: boolean = false;
  shipmentRemoveModel: ExteriorTransport.RemoveShipmentRequest;

  mplStateImportPath: string = '/exterior-transports/{exterior_transport_id}/mpl-response-document-import' +
    '?ignore_shipments_outside_exterior_transport=true';

  mplDownloading: boolean = false;


  _selectedTab: TAB = 'SHIPMENTS';

  constructor(
    private exteriorTransportService: ExteriorTransportService,
    private translateService: TranslateService,
    private uiRouter: UIRouter,
    private rightService: RightService,
    private transition: Transition,
  ) {
    this.componentState = new ComponentStateResolver(uiRouter, transition,
      'id',
      undefined,
      {stateName: StateName.EXTERIOR_TRANSPORT_EDIT, stateHeaderKey: 'EXTERIOR_TRANSPORT_EDIT'},
      {stateName: StateName.EXTERIOR_TRANSPORT_DETAIL, stateHeaderKey: 'EXTERIOR_TRANSPORT_DETAIL'});
    this.mplStateImportPath = this.mplStateImportPath.replace('{exterior_transport_id}', this.componentState.id!.toString());
  }

  ngOnInit() {
    this.initBreadcrumb();
    this.loadRightModels(() => {
      this.loadBaseData();
    });
  }

  initBreadcrumb() {
    this.breadcrumbSelf = this.componentState.id!.toString();
    this.translateService.get('MENU_NAVBAR_MENU_EXTERIOR_TRANSPORT').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.EXTERIOR_TRANSPORT_LIST});
      }
    );
  }

  private loadRightModels(completion: () => void) {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        completion();
      }
    );
  }

  private loadBaseData() {
    this.exteriorTransportService.get({
      id: this.componentState.id!,
      rights: Set.of(
        OperationRights.EXTERIOR_TRANSPORT_START,
        OperationRights.EXTERIOR_TRANSPORT_FINISH,
        OperationRights.EXTERIOR_TRANSPORT_SHIPMENT_ADD,
        OperationRights.EXTERIOR_TRANSPORT_SHIPMENT_REMOVE,
        OperationRights.EXTERIOR_TRANSPORT_DOCUMENT_READ,
        OperationRights.EXTERIOR_TRANSPORT_DOCUMENT_CREATE,
        OperationRights.EXTERIOR_TRANSPORT_DOCUMENT_DELETE,
        OperationRights.EXTERIOR_TRANSPORT_DOCUMENT_DOWNLOAD,
        OperationRights.EXTERIOR_TRANSPORT_DOCUMENT_META_UPDATE
      )
    }).subscribe((result: ExteriorTransport.ExteriorTransport) => {
      const transportState = ExteriorTransport.exteriorTransportStates.find((state) => state.state === result.state);
      this.baseDataModel.id = result.id.toString();
      this.baseDataModel.externalId = result.externalId;
      this.baseDataModel.serialNumber = result.serialNumber;
      this.baseDataModel.demander = result.demanderCompany ? result.demanderCompany.name : 'EXTERIOR_TRANSPORT_MULTIPLE_DEMANDERS';
      this.baseDataModel.transporter = result.transporterCompany ? result.transporterCompany.name : '';
      this.baseDataModel.state = transportState!.stringKey;
      this.baseDataModel.stateEnum = transportState!.state;
      this.baseDataModel.stateIconClass = transportState!.iconClass;
      this.baseDataModel.creationTime = result.creationTime;
      this.baseDataModel.startTime = result.startTime;
      this.baseDataModel.finishTime = result.finishTime;
      this.baseDataModel.rights =
        new ExteriorTransportRightModel(GrantedPermissionSetResolver.byGrantedRights(result.grantedRights));
    });
  }

  openBaseDataDialog() {
    this.baseDataDialogVisible = true;
    this.baseDataDialog.show();
  }

  closeBaseDataDialog() {
    this.baseDataDialogVisible = false;
    this.baseDataDialog.hide();
  }

  startExteriorTransport() {
    this.exteriorTransportService.start({
      id: this.componentState.id!
    }).subscribe(() => {
      this.loadBaseData();
    });
  }

  onImportSuccess(succeeded: boolean) {
    if (succeeded && this._selectedTab === 'SHIPMENTS') {
      this.shipmentsComponent.reloadComponent();
    }
  }

  showMplExportDialog() {
    this.mplExportDialogVisible = true;
    this.mplExportDialog.show();
  }

  closeMplExportDialog() {
    this.mplExportDialogVisible = false;
    this.mplExportDialog.hide();
  }

  mplExport() {
    this.mplDownloading = true;
    this.exteriorTransportService.mplExport({
      id: this.componentState.id!
    }).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName('mpl_export.xlsx'));
        this.closeMplExportDialog();
        this.mplDownloading = false;
      }, error => {
        this.mplDownloading = false;
      },
    );
  }

  showShipmentRemoveDialog(shipment: Shipment.Shipment) {
    this.shipmentRemoveDialogVisible = true;
    this.shipmentRemoveModel = {
      exteriorTransportId: this.componentState.id!,
      resetTrackingNumber: false,
      shipmentId: shipment.id,
      trackingNumberPresent: !!shipment.trackingNumber
    };
    this.shipmentRemoveDialog.showDialog();
  }

  closeShipmentRemoveDialog() {
    this.shipmentRemoveDialogVisible = false;
    this.shipmentRemoveDialog.closeDialog();
  }

  removeShipment() {
    this.exteriorTransportService.removeShipment(this.shipmentRemoveModel).subscribe((result) => {
      this.closeShipmentRemoveDialog();
      this.shipmentsComponent.reloadComponent();
    });
  }

  activateTab(tab: TAB) {
    this._selectedTab = tab;
  }

}

type TAB = 'SHIPMENTS' | 'DOCUMENTS' | 'HISTORY';
