/* eslint-disable */
import {Injectable} from '@angular/core';
import {Observable, Observer} from 'rxjs';
import {ObservableErrorResourceParser} from '../../util/errors';
import {FieldValidationError, PagingRequest, QueryResult, ResourceQueryResult} from '../../util/services';
import {List, Map as ImmutableMap, Set} from 'immutable';
import {DqlQuery, Query} from '../../query/field';
import {FilterField} from '../../query/filterfields';
import {OrderField} from '../../query/orderfields';
import {ContractNumberResource, ContractNumberResourceService} from './contract-number-resource.service';

/* eslint-enable */

@Injectable()
export class ContractNumberService implements ContractNumber.Service {

  private readonly filterField = new FilterField.ContractNumber();
  private readonly orderField = new OrderField.ContractNumber();
  private readonly fields = new ContractNumber.Fields.ContractNumber();

  constructor(private resourceService: ContractNumberResourceService) {
  }

  query(request: ContractNumber.QueryRequest): Observable<QueryResult<ContractNumber.ContractNumber>> {
    return Observable.create((observer: Observer<QueryResult<ContractNumber.ContractNumber>>) => {
      const resourceRequest = ContractNumber.toResourceQueryRequest(this.filterField, this.orderField, this.fields, request);
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<ContractNumberResource.ContractNumber>) => {
          observer.next({
            items: List.of(...result.items.map((item) => ContractNumber.toPublic(item))),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  get(request: ContractNumber.GetRequest): Observable<ContractNumber.ContractNumber> {
    return Observable.create((observer: Observer<ContractNumber.ContractNumber>) => {
      const resourceRequest = ContractNumber.toResourceGetRequest(this.fields, request);
      return this.resourceService.get(resourceRequest).subscribe(
        (result: ContractNumberResource.ContractNumber) => {
          observer.next(ContractNumber.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  private translateError(error: any): any {
    const res = ObservableErrorResourceParser.parseError(error);
    const fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
    const fieldErrorMap = ObservableErrorResourceParser.toFieldErrorMap(Keys.toValidatedField, fieldErrors);
    if (!fieldErrorMap.isEmpty()) {
      return FieldValidationError.of(fieldErrorMap);
    }
    return error;
  }
}

export namespace ContractNumber {


  export interface Service {

    // <editor-fold desc="CRUD">

    query(request: QueryRequest): Observable<QueryResult<ContractNumber>>;

    get(request: GetRequest): Observable<ContractNumber>;

    // </editor-fold>

  }

  // <editor-fold desc="CRUD">

  export interface QueryRequest {
    fields?: Query.FieldFunction<Fields.ContractNumber>;
    filter?: Query.FilterFunction<FilterField.ContractNumber>;
    order?: Query.OrderFunction<OrderField.ContractNumber>;
    paging?: PagingRequest;
    rights?: Set<string>;
    noProgressBar?: boolean;
  }

  export interface GetRequest {
    id: number;
    fields?: Query.FieldFunction<Fields.ContractNumber>;
    rights?: Set<string>;
  }

  export interface ContractNumber {
    id: number;
    grantedRights: Set<string>;
    number: string;
  }

  export function toResourceQueryRequest(
    filterField: FilterField.ContractNumber,
    orderField: OrderField.ContractNumber,
    fields: ContractNumber.Fields.ContractNumber,
    request: QueryRequest): ContractNumberResource.QueryRequest {
    const filter: string | undefined = DqlQuery.toOptionalFilter(filterField, request.filter);
    const order: string | undefined = DqlQuery.toOptionalOrder(orderField, request.order);
    const field: string | undefined = DqlQuery.toOptionalFields(fields, request.fields);
    return {
      filter: filter,
      order: order,
      fields: field,
      page_number: request.paging ? request.paging.pageNumber : undefined,
      number_of_items: request.paging ? request.paging.numberOfItems : undefined,
      rights: DqlQuery.RightRequestSerializer.getInstance().serialize(request.rights),
      no_progress_bar: request.noProgressBar
    };
  }

  export function toResourceGetRequest(
    fields: ContractNumber.Fields.ContractNumber,
    request: GetRequest): ContractNumberResource.GetRequest {
    const field: string | undefined = DqlQuery.toOptionalFields(fields, request.fields);
    return {
      id: request.id,
      fields: field,
      rights: DqlQuery.RightRequestSerializer.getInstance().serialize(request.rights)
    };
  }

  export function toPublic(r: ContractNumberResource.ContractNumber): ContractNumber.ContractNumber {
    return {
      id: r.id,
      grantedRights: r.granted_rights ? Set.of(...r.granted_rights) : Set.of(),
      number: r.number,
    };
  }

  export enum ValidatedField {
    UNKNOWN,
    NUMBER,
  }

  export namespace Fields {

    export class ContractNumber {

      readonly id: Query.Field = new DqlQuery.Field('id');
      readonly grantedRights: Query.Field = new DqlQuery.Field('granted_rights');
      readonly number: Query.Field = new DqlQuery.Field('number');

      get each(): Set<Query.Field> {
        return Set.of(
          this.id,
          this.number,
        );
      }

      get forList(): Set<Query.Field> {
        return Set.of(
          this.id,
          this.number,
        );
      }

      get multiselect(): Set<Query.Field> {
        return Set.of(this.id, this.number);
      }
    }

  }

}

// <editor-fold desc="Internal">


class Keys {

  private static readonly NUMBER = 'number';

  private static readonly keyValidatedFieldMap: ImmutableMap<string, ContractNumber.ValidatedField> = ImmutableMap.of(
    Keys.NUMBER, ContractNumber.ValidatedField.NUMBER,
  );

  public static toValidatedField(fieldKey: string): ContractNumber.ValidatedField {
    return Keys.keyValidatedFieldMap.get(fieldKey, ContractNumber.ValidatedField.UNKNOWN);
  }

}

// </editor-fold>


