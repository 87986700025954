/* eslint-disable */
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../../../lib/auth.service';
import { Set } from 'immutable';
import { Device, DeviceFieldErrorMap, DeviceManagement, DeviceManagementService } from '../../../../lib/device-management.service';
import { StringKey } from '../../../../app.string-keys';
import { TranslateService } from '@ngx-translate/core';
import { RightModel } from '../../../../app.rights';
import { RightResolver, RightService } from '../../../../lib/right.service';
import {
  DeviceActivationStateObject, deviceActivationStates,
  DeviceDisabled,
  DeviceDisabledItem,
  DeviceDisabledItems, MultiselectOptionItem,
  OptionItem,
  QueryFieldModel,
  SelectUtils,
  UiConstants
} from '../../../../util/core-utils';
import { Strings } from '../../../../lib/util/strings';
import { Models } from '../../../../util/model-utils';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { BreadcrumbParent } from '../../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { StateName } from '../../../../app.state-names';
import { InputMask } from '../../../../util/input-masks';
import { combineLatest, Observable } from 'rxjs';
import { Arrays } from '../../../../lib/util/arrays';
import { DeviceSearch, DeviceSearchService } from '../../../../lib/device-search-service';
import { OrderType, ResourceQueryResult, Services } from '../../../../lib/util/services';
import { EmptyMessage } from '../../../../lib/util/messages';
import { FieldError, FieldErrors, ObservableErrorResourceParser } from '../../../../lib/util/errors';
import { ToasterService } from '../../../../fork/angular2-toaster/angular2-toaster';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DropdownItemType } from '../../../../shared/dropdown/dropdown-item/dropdown-item-type';
import { AssigneeType } from '../../../../shared/assignee-table-cell/assignee-table-cell.component';
import { UserService, User } from '../../../../lib/user.service';
import { UserGroupService } from '../../../../lib/user-group.service';
/* eslint-enable */

@Component({
  selector: 'app-application-devicemanagement-list',
  templateUrl: 'device-list.component.html',
  styleUrls: ['device-list.component.scss']
})
export class DeviceManagementListComponent implements OnInit, AfterViewInit, OnDestroy {
  DeviceManagement = DeviceManagement;
  UiConstants = UiConstants;
  DropdownItemType = DropdownItemType;
  AssigneeType = AssigneeType;

  model: Model = new Model();
  users: User[] = [];
  relatedUserGroups: MultiselectOptionItem<number>[] = [];

  queryModel: QueryFieldModel<DeviceManagement.OrderField> =
    new QueryFieldModel(DeviceManagement.OrderField.APPLICATION_ID, OrderType.DESC);

  searchModel: DeviceListSearchModel = new DeviceListSearchModel();

  editItem: DeviceModel = new DeviceModel();

  validName = true;
  submitted = false;
  rightModel: RightModel = RightModel.empty();
  fieldErrors: DeviceFieldErrorMap;
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  showSearch: boolean = false;
  deviceDisabledItems: DeviceDisabledItem[] = [];
  InputMask = InputMask;
  SelectUtils = SelectUtils;
  selectedDeviceIndex: number = -1;

  @ViewChild('detailDialog', { static: true }) detailDialog: ModalDirective;
  detailDialogVisible: boolean = false;

  ngOnInit() {
    this.translateService.get('COMMON_DEVICES').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.initDeviceDisabledOptions('ACTIVE');
    this.loadSearch(() => {
      this.showSearch = !this.searchModel.isEmpty();
      this.loadList();
    });
  }

  private loadSearch(completion: () => void) {
    const obs: Observable<SearchLoadResult> = combineLatest(
      this.deviceSearchService.getSearchData({}),
      (storedSearchData: DeviceSearch.SearchDataResult) => {
        const result: SearchLoadResult = {
          storedSearchData: storedSearchData
        };
        return result;
      }
    );
    obs.subscribe(
      (result: SearchLoadResult) => {
        this.postInitSearch(result.storedSearchData);
        completion();
      }
    );
  }

  private initDeviceDisabledOptions(initValue: DeviceDisabled) {
    this.deviceDisabledItems = [];
    const deviceDisabledEnums: DeviceDisabled[] = DeviceDisabledItems.values;

    Arrays.iterateByIndex(deviceDisabledEnums, (key) => {
      const item = new DeviceDisabledItem();
      item.id = key;
      this.translateService.get('DEVICE_SEARCH_FIELD_DISABLED_' + key).subscribe(
        (text: string) => {
          item.text = text;
        }
      );
      this.deviceDisabledItems.push(item);
      if (key === initValue) {
        this.searchModel.disabled = item;
      }
    });
  }

  private postInitSearch(storedSearchData: DeviceSearch.SearchDataResult) {
    this.queryModel.itemsPerPage = storedSearchData.searchData.itemsPerPage;
    this.queryModel.currentPage = storedSearchData.searchData.pageNumber;
    this.queryModel.setOrder(storedSearchData.searchData.order);
    this.searchModel.name = storedSearchData.searchData.name;
    this.searchModel.device_model = storedSearchData.searchData.deviceModel;
    this.searchModel.latest_login_from = Models.localDateToNgbDate(storedSearchData.searchData.latestLoginFrom);
    this.searchModel.latest_login_to = Models.localDateToNgbDate(storedSearchData.searchData.latestLoginTo);
    this.searchModel.latest_login_user = storedSearchData.searchData.latestLoginUser;
    this.searchModel.latest_login_user_name = storedSearchData.searchData.latestLoginUserName;
    this.searchModel.disabled = storedSearchData.searchData.disabled;
  }

  private getActiveItem(): DeviceDisabledItem {
    const item = new DeviceDisabledItem();
    item.id = 'ACTIVE';
    item.text = 'DEVICE_SEARCH_FIELD_DISABLED_ACTIVE';
    return item;
  }

  toggleSearch() {
    if (!this.searchModel.disabled) {
      this.searchModel.disabled = this.getActiveItem();
    }
    this.showSearch = !this.showSearch;
  }

  editItemDeviceIdText(): string {
    if (this.editItem && this.editItem.device_id) {
      return this.editItem.device_id;
    }
    return '';
  }

  editItemOpSystemText(): string {
    if (this.editItem && this.editItem.operation_system_version) {
      return this.editItem.operation_system_version;
    }
    return '';
  }

  constructor(
    private translateService: TranslateService,
    private toasterService: ToasterService,
    private authService: AuthService,
    private rightService: RightService,
    private deviceManagementService: DeviceManagementService,
    private deviceSearchService: DeviceSearchService,
    private userGroupService: UserGroupService,
    private userService: UserService) {
    this.fieldErrors = {};
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  ngAfterViewInit(): void {
    this.loadRightModels();
    this.loadList(1);
  }

  setDisabled(event: any, task: Device, disabled: boolean) {
    this.deviceManagementService.setDisabled({
      id: task.id,
      disabled: disabled
    })
      .subscribe(
      (result: EmptyMessage) => {
        this.loadList();
      },
      (error: any) => {
        this.loadList();
      }
    );
  }

  openEditDialog(editDialog: any, item: Device) {
    this.editItem = {id: item.id, name: item.name, device_id: item.device_id, operation_system_version: item.operation_system_version};
    editDialog.show();
    this.submitted = false;
  }

  saveEditDialog(editDialog: any) {
    this.submitted = true;
    if (!this.editItem || !this.editItem.id) {
      this.closeEditDialog(editDialog);
      return;
    }

    this.deviceManagementService.setDevice({
      id: this.editItem.id,
      name: this.editItem.name
    }).subscribe(
      (result: EmptyMessage[]) => {
        this.validName = true;
        this.closeEditDialog(editDialog);
        this.loadList();
        this.toasterService.pop({
          timeout: UiConstants.ToastTimeoutLong,
          type: UiConstants.toastTypeSuccess,
          title: this.translateService.instant(StringKey.DEVICE_EDIT_TITLE),
          body: this.translateService.instant(StringKey.DEVICE_EDIT_MESSAGE)
        });
      },
      (error: any) => {
        const res = ObservableErrorResourceParser.parseError(error);
        this.fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
        this.validName = false;
      });
  }

  removeFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.fieldErrors, fieldError);
  }

  closeEditDialog(editDialog: any) {
    this.editItem = new DeviceModel();
    editDialog.hide();
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: DeviceManagement.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  private loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();

    let name: string | undefined = undefined;
    if (this.searchModel.name) {
      name = this.searchModel.name
    }

    let device_model: string | undefined = undefined;
    if (this.searchModel.device_model) {
      device_model = this.searchModel.device_model
    }

    let latest_request_user_name: string | undefined = undefined;
    if (this.searchModel.latest_login_user_name) {
      latest_request_user_name = this.searchModel.latest_login_user_name
    }

    let latest_request_user: number | undefined = undefined;
    if (this.searchModel.latest_login_user) {
      latest_request_user = this.searchModel.latest_login_user
    }

    const latest_request_from = Models.parseDateTimeFrom(this.searchModel.latest_login_from);
    const latest_request_to = Models.parseDateTimeTo(this.searchModel.latest_login_to);

    this.deviceManagementService.query({
      name: Strings.undefinedOrNonEmpty(name),
      device_model: Strings.undefinedOrNonEmpty(device_model),
      latest_login_user_name: Strings.undefinedOrNonEmpty(latest_request_user_name),
      latest_login_from: latest_request_from.isValid() ? latest_request_from.toUtcIsoString() : undefined,
      latest_login_to: latest_request_to.isValid() ? latest_request_to.toUtcIsoString() : undefined,
      latest_login_user_id: latest_request_user,
      latest_login_undefined: this.searchModel.disabled ? this.getLatestLoginUndefined() : undefined,
      disabled:  this.searchModel.disabled ? this.getDisabled() : undefined,
      page_number: requestedPage,
      number_of_items: this.queryModel.itemsPerPage,
      order: Services.createOrderFieldParameter(DeviceManagement.Keys.toOrderFieldKey, Set.of(order)),
    })
      .subscribe(
      (result: ResourceQueryResult<Device>) => {
        this.model.items = result.items;
        this.loadUsers();
        this.queryModel.currentPage = requestedPage;
        this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
        this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
      }
    );
  }

  getDisabled(): boolean | undefined {
    if (this.searchModel.disabled!.id === 'ACTIVE') {
      return false;
    }
    else if (this.searchModel.disabled!.id === 'INACTIVE') {
      return true;
    }
    else if (this.searchModel.disabled!.id === 'WAITING_FOR_ACTIVATION') {
      return true;
    }
    else {
      return undefined;
    }
  }

  getLatestLoginUndefined(): boolean | undefined {
    if (this.searchModel.disabled!.id === 'WAITING_FOR_ACTIVATION') {
      return true;
    }
    else {
      return undefined;
    }
  }

  onSearchClicked() {
    this.loadList();
  }

  onSearchReset() {
    this.deviceSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.showSearch = true;
          this.loadList(1);
        });
      }
    );
  }

  private saveSearch() {
    const request = {
      searchData: {
        itemsPerPage: this.queryModel.itemsPerPage,
        pageNumber: this.queryModel.currentPage,
        order: this.queryModel.getOrder(),
        name: this.searchModel.name,
        deviceModel: this.searchModel.device_model,
        latestLoginUser: this.searchModel.latest_login_user,
        latestLoginUserName: this.searchModel.latest_login_user_name,
        latestLoginFrom: Models.ngbDateToLocalDate(this.searchModel.latest_login_from),
        latestLoginTo: Models.ngbDateToLocalDate(this.searchModel.latest_login_to),
        disabled: this.searchModel.disabled
      }
    };
    this.deviceSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  openDetailDialog(id: number) {
    this.selectedDeviceIndex = id;
    this.detailDialogVisible = true;
    this.detailDialog.show();
  }

  closeDetailDialog() {
    this.detailDialogVisible = false;
    this.detailDialog.hide();
  }

  ngOnDestroy() {
    this.saveSearch();
  }

  getActivationStateObject(device: Device): DeviceActivationStateObject {
    if (!device.disabled) {
      return deviceActivationStates[0];
    }
    else if (device.disabled && device.latest_login_time) {
      return deviceActivationStates[1];
    }
    else {
      return deviceActivationStates[2];
    }
  }

  private loadUsers() {
    this.userService.query({
      id: this.model.items.map(item => item.latest_login_user_id).join(',')
    }).subscribe((result) => {
      this.users = result.items;
      const relatedUserGroupIds = Set.of(...this.users.map(u => u.user_group_ids));
      this.loadRelatedUserGroups(relatedUserGroupIds);
    })
  }

  private loadRelatedUserGroups(relatedUserGroupIds: Set<number[]>) {
    this.userGroupService.query({
      id: relatedUserGroupIds.join(',')
    }).subscribe(userGroups => {
      this.relatedUserGroups = [];
      userGroups.items.forEach(userGroup => {
        const item = new MultiselectOptionItem<number>();
        item.id = userGroup.id;
        item.itemName = userGroup.name;
        item.disabled = userGroup.disabled;
        this.relatedUserGroups.push(item);
      });
    });
  }

  getUserById(latest_login_user_id: number): User | undefined {
    return this.users.find(u => u.id === latest_login_user_id);
  }
}

interface SearchLoadResult {
  storedSearchData: DeviceSearch.SearchDataResult,
}

class DeviceModel {
  id?: number = undefined;
  name: string = '';
  operation_system_version: string;
  device_id: string;
}

class Model {
  items: Device[] = [];
}

class DeviceListSearchModel {
  name: string = '';
  device_model: string = '';
  latest_login_from: NgbDateStruct | null = null;
  latest_login_to: NgbDateStruct | null = null;
  latest_login_user?: number = undefined;
  latest_login_user_name: string = '';
  disabled?: DeviceDisabledItem = DeviceDisabledItem.active();

  public isEmpty(): boolean {
    return this.name.length === 0
      && this.device_model.length === 0
      && this.latest_login_user === 0
      && this.latest_login_user_name.length === 0
      && this.latest_login_from === null
      && this.latest_login_to === null
      && OptionItem.idOrUndefined(this.disabled) === 'ACTIVE'
      ;
  }
}


