import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CustomerRecordContactLocationMultiselectProvider } from '../../../../lib/customer/contact/customer-record-contact-location-multiselect.provider';
import { FormBuilder, FormGroup, NgForm, ValidatorFn, Validators } from '@angular/forms';
import { UIRouter } from '@uirouter/angular';
import { MasterDataRecord, MasterDataRecordService } from '../../../../lib/masterdata/master-data-record.service';
import { Angular2Multiselects } from '../../../../util/multiselect';
import { UserMultiselectProvider } from '../../../../lib/user/user-multiselect.provider';
import { UserGroupMultiselectProvider } from '../../../../lib/user-group/user-group-multiselect.provider';
import { CustomerRecordMultiselectProvider } from '../../../../lib/customer/customer-record-multiselect-provider.service';
import { FieldValidationError, ForwardingNgFormRef, LocalFormGroupValidationErrors } from '../../../../lib/util/services';
import { MultiselectOptionItem, MultiselectOptionItemWithData, SelectUtils, UiConstants } from '../../../../util/core-utils';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Strings } from '../../../../lib/util/strings';
import { AppValidators } from '../../../../util/app-validators';
import { MasterdataRecordQuickCreateDialogModel } from './masterdata-record-quick-create-dialog.model';
import { StateName } from '../../../../app.state-names';
import { MasterDataMultiselectProvider } from '../../../../lib/masterdata/masterdata-multiselect-provider.service';

@Component({
  selector: 'app-masterdata-record-quick-create-dialog',
  templateUrl: './masterdata-record-quick-create-dialog.component.html',
  styleUrls: ['./masterdata-record-quick-create-dialog.component.scss']
})
export class MasterdataRecordQuickCreateDialogComponent implements OnInit {

  MasterDataRecord = MasterDataRecord;
  UiConstants = UiConstants;
  SelectUtils = SelectUtils;
  ValidatedField = MasterDataRecord.ValidatedField;

  model: MasterdataRecordQuickCreateDialogModel = new MasterdataRecordQuickCreateDialogModel();
  remoteDropdownSettings: Angular2Multiselects.Settings;
  multiRemoteDropdownSettings: Angular2Multiselects.Settings;

  customerRecords: MultiselectOptionItem<number>[] = [];
  customerRecordContactLocations: MultiselectOptionItem<number>[] = [];
  users: MultiselectOptionItem<number>[] = [];
  userGroups: MultiselectOptionItem<number>[] = [];
  masterDataList: MultiselectOptionItemWithData<number>[] = [];

  private _navigateToEdit: boolean = false;

  @ViewChild('f', {static: true})
  form: NgForm;
  formGroup: FormGroup;
  private formGroupValidationErrors: LocalFormGroupValidationErrors;
  private fieldErrors: FieldValidationError<MasterDataRecord.ValidatedField> =
    FieldValidationError.empty<MasterDataRecord.ValidatedField>();

  constructor(
    public dialogRef: MatDialogRef<MasterdataRecordQuickCreateDialogComponent>,
    private translateService: TranslateService,
    private customerRecordMultiselectProvider: CustomerRecordMultiselectProvider,
    private customerRecordContactLocationMultiselectProvider: CustomerRecordContactLocationMultiselectProvider,
    private fb: FormBuilder,
    private userMultiselectProvider: UserMultiselectProvider,
    private userGroupMultiselectProvider: UserGroupMultiselectProvider,
    private masterDataMultiselectProvider: MasterDataMultiselectProvider,
    private masterDataRecordService: MasterDataRecordService,
    private uiRouter: UIRouter,
    @Inject(MAT_DIALOG_DATA) public data: MasterdataRecordQuickCreateDialogData) {
    this.loadModel(data);
    this.formGroup = this.createFormGroup(this.fb);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(
      this.createForwardingHtmlForm(),
      this.formGroup
    );
  }

  ngOnInit(): void {
    this.initDropdown();
  }

  private initDropdown() {
    this.remoteDropdownSettings = Angular2Multiselects.REMOTE_SINGLE_SELECT;
    this.multiRemoteDropdownSettings = Angular2Multiselects.REMOTE_MULTI_SELECT;
  }

  closeDialog(modified: boolean) {
    this.dialogRef.close({modified: modified});
  }

  navigateToEdit() {
    this._navigateToEdit = true;
  }

  save() {
    if (this.hasLocalFieldError()) {
      return;
    }
    else {
      this.createMasterDataRecord();
    }
  }

  hasFieldError(field?: MasterDataRecord.ValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: MasterDataRecord.ValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: MasterDataRecord.ValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  private createForwardingHtmlForm() {
    return new ForwardingNgFormRef({
      formFn: () => {
        return this.form;
      }
    });
  }

  private createFormGroup(fb: FormBuilder): FormGroup {
    return fb.group(
      {
        masterData: fb.control(
          {value: this.model.masterData},
          [AppValidators.validateEnabledItems, Validators.required]
        ),
        name: fb.control(
          {value: this.model.name},
          this.getNameRequirement()
        ),
        externalId: fb.control(
          {value: this.model.externalId},
          this.getExternalIdRequirement()
        ),
        description: fb.control(
          {value: this.model.description},
          []
        ),
        user: fb.control(
          {value: this.model._users},
          [
            AppValidators.validateEnabledItems
          ]
        ),
        userGroup: fb.control(
          {value: this.model._userGroups},
          [
            AppValidators.validateEnabledItems
          ]
        ),
        customerRecord: fb.control(
          {value: this.model.ownerCustomerRecord},
          AppValidators.validateEnabledItems
        ),
        contactLocation: fb.control(
          {value: this.model.ownerContactLocation, disabled: !this.model.hasCustomer()}
        )
      }
    );
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  createMasterDataRecord() {
    const navigateToEdit = this._navigateToEdit !== undefined ? this._navigateToEdit : false;
    this.formGroup.updateValueAndValidity();
    if (this.formGroup.invalid) {
      return;
    }
    this.masterDataRecordService.quickCreate({
      masterDataId: this.model.masterData!.id,
      name: Strings.undefinedOrNonEmpty(this.model.name),
      externalId: Strings.undefinedOrNonEmpty(this.model.externalId),
      description: this.model.description,
      ownerUserIds: this.model.userIds,
      ownerGroupIds: this.model.userGroupIds,
      ownerCustomerRecordId: this.model.ownerCustomerRecord ? this.model.ownerCustomerRecord.id : undefined,
      ownerContactLocationId: this.model.ownerContactLocation ? this.model.ownerContactLocation.id : undefined,
    }).subscribe(
      (result: MasterDataRecord.MasterDataRecord) => {
        this.closeDialog(true);
        if (navigateToEdit) {
          this.uiRouter.stateService.go(
            StateName.MASTERDATA_RECORD_EDIT,
            {masterDataId: result.masterDataId, masterDataRecordId: result.masterDataRecordId}
          );
        }
      },
      error => {

      });
  }

  private loadModel(data: MasterdataRecordQuickCreateDialogData) {
    this.model = new MasterdataRecordQuickCreateDialogModel();
    if (data.customerRecordId) {
      this.customerRecordMultiselectProvider.getById(data.customerRecordId).subscribe(customerRecord => {
        this.model._ownerCustomerRecord.push(customerRecord);
      })
    }
  }

  onCustomerRecordSearch(q?: string) {
    this.customerRecordMultiselectProvider.loadActive(q).subscribe(result => {
      this.customerRecords = result;
    });
  }

  onUserSearch(q?: string) {
    this.userMultiselectProvider.loadActive(q).subscribe(result => {
      this.users = result;
    });
  }

  onUserGroupSearch(q?: string) {
    this.userGroupMultiselectProvider.loadActive(q).subscribe(result => {
      this.userGroups = result;
    });
  }

  onMasterDataSearch(q?: string) {
    this.masterDataMultiselectProvider.loadActive(q).subscribe(result => {
      this.masterDataList = result;
    });
  }

  onCustomerContactLocationSearch(q?: string) {
    if (this.model.ownerCustomerRecord) {
      this.customerRecordContactLocationMultiselectProvider.loadForCustomer(this.model.ownerCustomerRecord!.id, q).subscribe(result => {
        this.customerRecordContactLocations = result;
      });
    }
    else {
      this.customerRecordContactLocations = [];
    }
  }

  onCustomerRecordChanged() {
    this.model._ownerContactLocation = [];
    this.onCustomerContactLocationSearch();
  }

  private getNameRequirement(): ValidatorFn | undefined {
    if (this.model.masterDataIdRequirement === 'NAME') {
      return Validators.required;
    }
    return undefined;
  }

  private getExternalIdRequirement(): ValidatorFn | undefined {
    if (this.model.masterDataIdRequirement === 'EXTERNAL_ID') {
      return Validators.required;
    }
    return undefined;
  }

  get externalIdRequired(): boolean {
    return this.model.masterDataIdRequirement === 'EXTERNAL_ID';
  }

  get nameRequired(): boolean {
    return this.model.masterDataIdRequirement === 'NAME';
  }
}

export interface MasterdataRecordQuickCreateDialogData {
  customerRecordId?: number;
}
