<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
    <app-breadcrumb-menu>
      <app-breadcrumb-button
        *ngIf="rightModel.companyUpdate.hasRight() && componentState.isDetailView()"
        [iconClass]="'icomoon-modify'"
        [titleStringKey]="'COMMON_BUTTON_EDIT'"
        uiSref="Admin.CompanyEdit"
        [uiParams]="{ id: detailModel.id }">
      </app-breadcrumb-button>
    </app-breadcrumb-menu>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>

<div class="container-horizontal-padding container-vertical-padding-half animated fadeIn">
  <responsive-tabset class="fullscreen_tab">
    <responsive-tab heading="{{'COMMON_CARD_HEADER_BASE_DATA'|translate}}">

      <div class="container-custom-padding animated fadeIn">

        <div class="form-group row mx-0">

          <!--region Basic Details-->
          <div class="col-xs-12 col-sm-12 col-md-6 card-custom-padding">
            <div class="card">
              <div class="card-header">
                {{ getHeadingDictionaryKey() | translate}}
              </div>
              <div class="card-body">
                <form class="form-horizontal bordered-row" (ngSubmit)="f.form.valid && submit()" #f="ngForm" id="company_form" novalidate>

                  <!--region CompanyName-->
                  <div class="form-group row" [ngClass]="{ 'has-danger': hasLocalFieldError(name) }">
                    <label class="col-form-label form-control-label col-md-4 detail-title" [class.required-field-label]="componentState.isEditable()">
                      {{'COMMON_COMPANY_NAME' | translate}}
                    </label>
                    <div class="col-md-8">
                      <input *ngIf="componentState.isEditable()"
                             type="text" class="form-control"
                             maxlength="{{UiConstants.maximumVarcharLength}}"
                             placeholder="{{'COMMON_COMPANY_NAME' | translate}}"
                             [(ngModel)]="editModel.name"
                             (ngModelChange)="removeFieldError(Company.ValidatedField.NAME)"
                             [ngClass]="{ 'form-control-danger': hasLocalFieldError(name) }"
                             name="name"
                             id="name"
                             #name="ngModel"
                             required>
                      <div class="form-control-feedback"
                           *ngIf="hasLocalFieldError(name)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.name}}
                      </label>
                    </div>
                  </div>
                  <!--endregion CompanyName-->

                  <!--region ExternalId-->
                  <div class="form-group row"
                       [ngClass]="{ 'has-danger': hasLocalFieldError(externalId) || fieldErrors.external_id}">
                    <label class="col-form-label form-control-label col-md-4 detail-title" [class.required-field-label]="componentState.isEditView()">
                      {{'COMMON_EXTERNAL_ID' | translate}}
                    </label>
                    <div class="col-md-8">
                      <input *ngIf="componentState.isEditable()"
                             type="text" class="form-control"
                             maxlength="{{UiConstants.maximumVarcharLength}}"
                             placeholder="{{'COMMON_EXTERNAL_ID' | translate}}"
                             [(ngModel)]="editModel.externalId"
                             (ngModelChange)="removeFieldError(fieldErrors.external_id)"
                             [ngClass]="{ 'form-control-danger': hasLocalFieldError(externalId) || fieldErrors.external_id}"
                             name="externalId"
                             id="externalId"
                             #externalId="ngModel"
                             [required]="componentState.isEditView()">
                      <div class="form-control-feedback"
                           *ngIf="fieldErrors.external_id">{{fieldErrors.external_id.text}}</div>
                      <div class="form-control-feedback"
                           *ngIf="hasLocalFieldError(externalId)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.externalId}}
                      </label>
                    </div>
                  </div>
                  <!--endregion ExternalId-->

                  <!--region Type-->
                  <div class="form-group row"
                       [ngClass]="{ 'has-danger': f.submitted && editModel.type.id === 'UNKNOWN'}">
                    <label class="col-form-label form-control-label col-md-4 detail-title" [class.required-field-label]="componentState.isCreateView()">
                      {{'COMPANY_TYPE' | translate}}
                    </label>
                    <div class="col-md-8">
                      <select
                        *ngIf="componentState.isCreateView()"
                        class="form-control"
                        [(ngModel)]="editModel.type" [compareWith]="SelectUtils.compareObjects"
                        (ngModelChange)="removeFieldError(Company.ValidatedField.TYPE)"
                        [ngClass]="{ 'form-control-danger': f.submitted && editModel.type.id === 'UNKNOWN'}"
                        name="type"
                        id="type"
                        ng-options="type"
                        #type="ngModel">
                        <option *ngFor="let item of typeModels"
                                [ngValue]="item">{{ 'COMPANY_TYPE_' + item.text | translate }} </option>
                      </select>
                      <div class="form-control-feedback"
                           *ngIf="f.submitted && editModel.type.id === 'UNKNOWN'">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                      <label
                        *ngIf="componentState.isEditView()" class="form-control detail-description test_type_label"
                        readonly>{{'COMPANY_TYPE_' + editModel.type.id | translate}}
                      </label>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{'COMPANY_TYPE_' + detailModel.type.id | translate}}
                      </label>
                    </div>
                  </div>
                  <!--endregion Type-->

                  <!--region TaxNumber-->
                  <div class="form-group row"
                       [ngClass]="{ 'has-danger': fieldErrors.tax_number || hasLocalFieldError(taxNumber) }">
                    <label class="col-form-label form-control-label col-md-4 detail-title">{{'COMPANY_TAX_NUMBER' |
                      translate}}</label>
                    <div class="col-md-8">
                      <input *ngIf="componentState.isEditable()"
                             type="text" class="form-control"
                             placeholder="{{'COMPANY_TAX_NUMBER' | translate}}"
                             [(ngModel)]="editModel.taxNumber.raw"
                             (ngModelChange)="removeFieldError(fieldErrors.tax_number)"
                             [ngClass]="{ 'form-control-danger': fieldErrors.tax_number || hasLocalFieldError(taxNumber) }"
                             name="taxNumber"
                             id="taxNumber"
                             [textMask]="{mask: InputMask.HU_TAX_NUMBER, guide: true}"
                             #taxNumber="ngModel">
                      <div class="form-control-feedback"
                           *ngIf="fieldErrors.tax_number">{{fieldErrors.tax_number.text}}</div>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.taxNumber}}
                      </label>
                    </div>
                  </div>
                  <!--endregion TaxNumber-->

                  <!--region EuTaxNumber-->
                  <div class="form-group row">
                    <label class="col-form-label form-control-label col-md-4 detail-title">{{'COMPANY_EU_TAX_NUMBER' |
                      translate}}
                      <i class="icomoon icomoon-info info-icon" popover="{{'COMPANY_INFO_EU_TAX_NUMBER' | translate}}"
                         *ngIf="componentState.isEditable()"
                         triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                    </label>
                    <div class="col-md-8">
                      <input *ngIf="componentState.isEditable()"
                             type="text" class="form-control"
                             placeholder="{{'INVOICE_SETTINGS_EU_TAX_NUMBER' | translate}}"
                             [(ngModel)]="editModel.euTaxNumber.raw"
                             name="euVatNumber"
                             id="euVatNumber"
                             #euVatNumber="ngModel">
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.euTaxNumber}}
                      </label>
                    </div>
                  </div>
                  <!--endregion EuTaxNumber-->

                  <!--region Email-->
                  <div class="form-group row" [ngClass]="{ 'has-danger': hasLocalFieldError(emailAddress) }">
                    <label
                      class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_CONTACT_PERSON_EMAIL_ADDRESS'
                      | translate}}</label>
                    <div class="col-md-8">
                      <div class="input-group" *ngIf="componentState.isEditable()">
                        <input type="text" class="form-control"
                               placeholder="{{'COMMON_CONTACT_PERSON_EMAIL_ADDRESS' | translate}}"
                               [(ngModel)]="editModel.emailAddress"
                               (ngModelChange)="removeFieldError(Company.ValidatedField.EMAIL_ADDRESS)"
                               [ngClass]="{ 'form-control-danger': hasLocalFieldError(emailAddress) }"
                               name="emailAddress"
                               id="emailAddress"
                               #emailAddress="ngModel"
                               validateOptionalEmail>
                        <span class="input-group-addon-gray icomoon icomoon-message-unread" id="basic-addon"></span>
                      </div>
                      <div class="form-control-feedback"
                           *ngIf="hasLocalFieldError(emailAddress)">{{'COMMON_VALIDATION_MESSAGE_EMAIL' | translate}}</div>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.emailAddress}}
                      </label>
                    </div>
                  </div>
                  <!--endregion Email-->

                  <!--region Phone-->
                  <div class="form-group row" [ngClass]="{ 'has-danger': hasLocalFieldError(phoneNumber) }">
                    <label
                      class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_CONTACT_PERSON_PHONE_NUMBER'
                      | translate}}</label>
                    <div class="col-md-8">
                      <div class="input-group" *ngIf="componentState.isEditable()">
                        <input type="text" class="form-control"
                               placeholder="{{'COMMON_CONTACT_PERSON_PHONE_NUMBER' | translate}}"
                               [(ngModel)]="editModel.phoneNumber"
                               (ngModelChange)="removeFieldError(Company.ValidatedField.PHONE_NUMBER)"
                               [ngClass]="{ 'form-control-danger': hasLocalFieldError(phoneNumber) }"
                               name="phoneNumber"
                               id="phoneNumber"
                               #phoneNumber="ngModel"
                               validatePhoneNumber>
                        <span class="input-group-addon-gray icomoon icomoon-phone" id="basic-addon2"></span>
                      </div>
                      <div class="form-control-feedback"
                           *ngIf="hasLocalFieldError(phoneNumber)">{{'COMMON_VALIDATION_MESSAGE_PHONE' | translate}}</div>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.phoneNumber}}
                      </label>
                    </div>
                  </div>
                  <!--endregion Phone-->

                  <!--region Comment-->
                  <div class="form-group row">
                    <label class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_COMMENT' |
                      translate}}</label>
                    <div class="col-md-8">
                <textarea *ngIf="componentState.isEditable()"
                          type="text" class="form-control"
                          placeholder="{{'COMMON_COMMENT' | translate}}"
                          [(ngModel)]="editModel.comment"
                          rows="{{UiConstants.textareaDescriptionRowsNumber}}"
                          name="comment"
                          id="comment"
                          #comment="ngModel">
                </textarea>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.comment}}
                      </label>
                    </div>
                  </div>
                  <!--endregion Comment-->

                  <!-- region Roles -->
                  <div *ngIf="!componentState.isEditView() ||
                        (selectedRoles.length > 0 && !rightModel.companyRolesUpdate.hasRight()) ||
                        (componentState.isEditView() && !rightModel.companyRolesUpdate.hasRight())"
                       class="form-group row">
                    <label
                      class="col-form-label form-control-label col-md-4 detail-title">{{'COMPANY_ALLOWED_ROLES'|translate}}</label>
                    <div class="col-md-8">
                      <angular2-multiselect
                        id="test_company_role_angular_select"
                        [(ngModel)]="selectedRoles"
                        [ngModelOptions]="{standalone: true}"
                        [data]="roles"
                        [settings]="dropdownSettings"
                        [readonly]="componentState.isReadonly()">
                      </angular2-multiselect>
                    </div>
                  </div>
                  <!-- endregion Roles -->

                  <!--region Transporter companies -->
                  <div class="form-group row"
                       *ngIf="componentState.isEditable() && editModel.type.id === 'DEMANDER' ||
                              componentState.isDetailView() && detailModel.type.id === 'DEMANDER'"
                       [ngClass]="{ 'has-danger': fieldErrors.transporter_company_ids}">
                    <label class="col-form-label form-control-label col-md-4 detail-title">{{'COMPANY_TRANSPORTER_COMPANIES' |
                        translate}}</label>
                    <div class="col-md-8">
                      <angular2-multiselect
                        [(ngModel)]="componentState.isEditable() ? editModel.transporterCompanies : detailModel.transporterCompanies"
                        [data]="transporterCompanies"
                        [settings]="remoteDropdownSettings"
                        (ngModelChange)="removeFieldError(fieldErrors.transporter_company_ids)"
                        (onRemoteSearch)="loadTransporterCompanies($event.target.value, undefined)"
                        [readonly]="componentState.isReadonly()"
                        name="transporterCompaniesField"
                        id="transporterCompaniesField"
                        #transporterCompaniesField="ngModel">
                      </angular2-multiselect>
                      <div class="form-control-feedback"
                           *ngIf="fieldErrors.external_id">{{fieldErrors.external_id.text}}</div>
                    </div>
                  </div>
                  <!--endregion Transporter companies -->

                  <!--Only visible on create/edit views-->
                  <div class="col-md-12 d-flex justify-content-end p-0 pl-1" *ngIf="componentState.isEditable()">
                    <div>
                      <input type="button" class="btn btn-secondary" (click)="back()"
                             value="{{'COMMON_BUTTON_CANCEL'|translate}}"/>
                      <input type="submit" class="btn btn-primary" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <!-- endregion Basic Details-->

          <!--region Postal address card-->
          <div class="col-xs-12 col-sm-12 col-md-6 card-custom-padding">
            <div class="card">
              <div class="card-header" [class.required-field-label]="componentState.isEditable()">
                {{ 'COMMON_LOCATION_POSTAL_ADDRESS' | translate}}
              </div>
              <div *ngIf="componentState.isEditable() && editModel.postalAddress" class="card-body">
                <app-postal-address-complex
                  id="test_portal_address_component"
                  [form]="f"
                  [model]="editModel.postalAddress"
                  [readonly]="componentState.isReadonly()">
                </app-postal-address-complex>
              </div>
              <!--Appears in detailView-->
              <div *ngIf="componentState.isReadonly()" class="card-body">
                <label class="form-control detail-description" readonly>{{detailModel.postalAddress}}</label>
              </div>
            </div>
          </div>
          <!--endregion Postal address card-->

        </div>
        <!-- /.form-group row -->

        <!--Card for updating roles-->
        <div *ngIf="componentState.isEditView() && rightModel.companyRolesUpdate.hasRight()"
             class="form-group row mx-0">

          <!--region Basic Details-->
          <div class="col-xs-12 col-sm-12 col-md-6 card-custom-padding">
            <div class="card">
              <div class="card-header">
                {{ 'COMPANY_ALLOWED_ROLES' | translate}}
              </div>
              <div class="card-body">

                <!-- region Roles -->
                <div class="form-group row">
                  <label
                    class="col-form-label form-control-label col-md-4 detail-title">{{'COMPANY_ALLOWED_ROLES'|translate}}</label>
                  <div class="col-md-8">
                    <angular2-multiselect
                      id="test_company_role_angular_select_edit"
                      [(ngModel)]="selectedRoles"
                      [ngModelOptions]="{standalone: true}"
                      [data]="roles"
                      [settings]="dropdownSettings">
                    </angular2-multiselect>
                  </div>
                </div>
                <!-- endregion Roles -->

                <!-- region force remove -->
                <div class="form-group row">
                  <label
                    class="col-form-label form-control-label col-md-4 detail-title">{{'COMPANY_FORCE_REMOVE'|translate}}</label>
                  <div class="col-md-8">
                    <app-animated-checkbox [(selected)]="editModel.forceRemove"
                                           class="col-form-label"></app-animated-checkbox>
                  </div>
                </div>
                <!-- region force remove -->

                <!--Only visible on create/edit views-->
                <div class="col-md-12 d-flex justify-content-end p-0 pl-1">
                  <div>
                    <input type="button" class="btn btn-primary" (click)="updateRoles()"
                           value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>
        <!-- /.form-group row -->


      </div>
    </responsive-tab>
    <responsive-tab *ngIf="rightModel.companyHistoryLog.hasRight() && componentState.isDetailView()"
           heading="{{'COMMON_HISTORY'|translate}}">
      <app-history-log
        [queryModel]="historyQueryModel"
        [Type]="Company"
        [historyList]="historyList"
        (openHistoryDetail)="historyDetailModal.openDetailDialog($event)"
        (itemsPerPageChangedOut)="itemsPerPageChanged($event)"
        (orderByOut)="orderBy($event)"
        (pageChangedOut)="pageChanged($event)"
        #historyComponent>
      </app-history-log>
    </responsive-tab>
  </responsive-tabset>
</div>

<app-history-detail-dialog
  #historyDetailModal>
</app-history-detail-dialog>
