<form class="form-group">
  <label class="form-control-label text-break">{{ model.title }}</label>
  <table class="table table-striped table-bordered">
    <thead>
    <tr>
      <th class="w-10">
        <label>{{'COMMON_TABLE_HEADER_ID' | translate}}</label>
      </th>
      <th class="w-25">
        <label>{{'STOCK_ITEM_PRODUCT' | translate}}</label>
      </th>
      <th class="w-25">
        <label>{{'STOCK_ITEM_TABLE_HEADER_PRODUCT_CODE' | translate}}</label>
      </th>
      <th class="w-25">
        <label>{{'STOCK_ITEM_TABLE_HEADER_SERIAL_CODE' | translate}}</label>
      </th>
      <th class="w-15">
        <label>{{'STOCK_RECORD_AMOUNT' | translate}}</label>
      </th>
    </tr>
    </thead>
    <tbody *ngIf="!hide()">
    <tr *ngFor="let stock of model.stocks">
      <td>{{stock.stockRecord.stockItem.id}}</td>
      <td>{{stock.stockRecord.stockItem.name}}</td>
      <td>{{stock.stockRecord.stockItem.product_code}}</td>
      <td>{{stock.stockRecord.stockItem.serial_code}}</td>
      <td>{{stock.amount ? stock.amount : '0'}} {{stock.stockRecord.stockItem.unit}}</td>
    </tr>
    </tbody>
    <thead>
    <tr>
      <th class="w-10">
        <label>{{'COMMON_TABLE_HEADER_ID' | translate}}</label>
      </th>
      <th class="w-25">
        <label>{{'STOCK_ITEM_PRODUCT' | translate}}</label>
      </th>
      <th class="w-25">
        <label>{{'STOCK_ITEM_TABLE_HEADER_PRODUCT_CODE' | translate}}</label>
      </th>
      <th class="w-25">
        <label>{{'STOCK_ITEM_TABLE_HEADER_SERIAL_CODE' | translate}}</label>
      </th>
      <th class="w-15">
        <label>{{'STOCK_RECORD_AMOUNT' | translate}}</label>
      </th>
    </tr>
    </thead>
  </table>
</form>

