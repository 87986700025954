/* eslint-disable */
import { NgbDateParserFormatter, NgbDatepickerI18n, NgbDateStruct, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import { SettingsService } from '../lib/settings.service';
import { DatePickerTemplateFactory } from './datepicker-utils';
import { Dates, InvalidLocalDate, InvalidOffsetDateTime, LocalDate, OffsetDateTime } from '../lib/util/dates';
import { TranslationWidth } from '@angular/common';

/* eslint-enable */

@Injectable()
export class DatepickerI18n extends NgbDatepickerI18n {

  private static readonly I18N_VALUES = {
    en: {
      weekdays: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
      months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    },
    hu: {
      weekdays: ['H', 'K', 'Sze', 'Cs', 'P', 'Sz', 'V'],
      months: ['Jan', 'Feb', 'Már', 'Ápr', 'Máj', 'Jún', 'Júl', 'Aug', 'Sz', 'Okt', 'Nov', 'Dec'],
    },
    fr: {
      weekdays: ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'],
      months: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Déc'],
    }
  };

  get i18n() {
    return DatepickerI18n.I18N_VALUES[this.settings.getLanguageCode()];
  }

  constructor(private settings: SettingsService) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return this.i18n.weekdays[weekday - 1];
  }

  getMonthShortName(month: number): string {
    return this.i18n.months[month - 1];
  }

  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return '';
  }

  getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
    return this.i18n.weekdays[weekday - 1];
  }

}

@Injectable()
export class NgbDatePickerParserFormatter extends NgbDateParserFormatter {

  constructor(private templateFactory: DatePickerTemplateFactory) {
    super();
  }

  public withRange(m: NgbDateStruct | string | null, minDate: NgbDateStruct, maxDate: NgbDateStruct): NgbDateStruct | string | null {
    if (m && typeof m !== 'string') {
      const model: NgbDateStruct = m;
      const ld = this.toLocalDate(m);
      const minld = this.toLocalDate(minDate);
      const maxld = this.toLocalDate(maxDate);
      if (!ld.isValid() || !minld.isValid() || !maxld.isValid()) {
        return m;
      }
      if (ld.isBefore(minld)) {
        return minDate;
      }
      if (ld.isAfter(maxld)) {
        return maxDate;
      }
    }
    return m;
  }

  public toOffsetDateTime(date: NgbDateStruct | string | null | undefined, time?: AppNgbTimeStruct | null | undefined):
    OffsetDateTime {
    const ld = this.toLocalDate(date);
    if (!ld.isValid()) {
      return new InvalidOffsetDateTime();
    }
    return Dates.createOffsetDateTime({
      year: ld.getYear(),
      month: ld.getMonth(),
      day: ld.getDay(),
      hour: time ? time.hour : 0,
      minute: time ? time.minute : 0,
      second: time ? time.second : 0,
      milliseconds: time ? time.millis : 0,
    });
  }

  public toLocalDate(model: NgbDateStruct | string | null | undefined): LocalDate {
    if (!model) {
      return new InvalidLocalDate();
    }
    if (typeof model === 'string') {
      return Dates.parseLocalDateTemplate(this.templateFactory.createDateTemplate(), model);
    }
    else {
      return Dates.createLocalDate({year: model.year, month: model.month, day: model.day});
    }
  }

  public fromLocalDate(ld: LocalDate | null | undefined): NgbDateStruct | null {
    if (!ld || !ld.isValid()) {
      return null;
    }
    return {
      year: ld.getYear(),
      month: ld.getMonth(),
      day: ld.getDay()
    };
  }

  public fromOffsetDateTime(ld: OffsetDateTime | null | undefined): (NgbDateStruct & AppNgbTimeStruct) | null {
    if (!ld || !ld.isValid()) {
      return null;
    }
    return {
      year: ld.getYear(),
      month: ld.getMonth(),
      day: ld.getDay(),
      hour: ld.getHour(),
      minute: ld.getMinute(),
      second: ld.getSecond(),
      millis: ld.getMillisecond()
    };
  }

  parse(value: string): NgbDateStruct {
    const dateTemplate = this.templateFactory.createDateTemplate();
    const ld = Dates.parseLocalDateTemplate(dateTemplate, value);
    if (ld.isValid()) {
      return {
        year: ld.getYear(),
        month: ld.getMonth(),
        day: ld.getDay()
      };
    }
    return null!;
  }

  format(date: NgbDateStruct | null): string {
    if (!date) {
      return '';
    }
    const dateTemplate = this.templateFactory.createDateTemplate();
    const year = date.year;
    const month = date.month ? date.month : 1;
    const day = date.day ? date.day : 1;
    const ld = Dates.createLocalDate({year: year, month: month, day: day});
    return ld.format(dateTemplate);
  }

}

export const DATEPICKER_I18N_PROVIDER = {
  provide: NgbDatepickerI18n, useClass: DatepickerI18n
};

export const DATEPICKER_FORMATTER_PROVIDER = {
  provide: NgbDateParserFormatter, useClass: NgbDatePickerParserFormatter
};

export type NgbDatePickerModel = NgbDateStruct | string;

export interface AppNgbTimeStruct extends NgbTimeStruct {
  millis: number;
}
