<div bsModal #dialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="dialogVisible">
    <div class="modal-content">
      <div class="modal-header" *ngIf="titleEnabled">
        <p class="modal-title"><strong>{{title}}</strong></p>
        <button type="button" class="close" (click)="closeDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ng-content></ng-content>
          <div class="alert alert-{{alertType.toString()}}" *ngIf="messageEnabled">
            <p class="alert-title mb-0"> {{message}} </p>
          </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary" (click)="canceled()" *ngIf="negativeButtonEnabled">
          {{negativeButton}}
        </button>
        <button type="button" class="btn btn-primary" (click)="confirmed()" *ngIf="positiveButtonEnabled">
          {{positiveButton}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
