/* eslint-disable */
import { Component, Input, OnInit } from '@angular/core';
import { DownloadedFile } from '../../../lib/util/downloaded-files';
import { UiConstants } from '../../../util/core-utils';
import { StringKey } from '../../../app.string-keys';
import { StateName } from '../../../app.state-names';
import { SurveyRecord, SurveyRecordService } from '../../../lib/survey/survey-record.service';
import { ToasterService } from '../../../fork/angular2-toaster/angular2-toaster';
import { TranslateService } from '@ngx-translate/core';
import { NgForm } from '@angular/forms';
import { saveAs } from 'file-saver';
import { UIRouter } from '@uirouter/angular';
/* eslint-enable */

@Component({
  selector: 'app-survey-record-breadcrumb-buttons',
  templateUrl: './survey-record-breadcrumb-buttons.component.html',
  styleUrls: ['./survey-record-breadcrumb-buttons.component.scss']
})
export class SurveyRecordBreadcrumbButtonsComponent implements OnInit {

  @Input()
  form?: NgForm;

  @Input()
  readonly: boolean;

  @Input()
  allowExport: boolean;

  @Input()
  allowClone: boolean;

  @Input()
  surveyId: number;

  @Input()
  surveyRecordId: number;

  surveyRecord?: SurveyRecord.SurveyRecord;

  constructor(
    private surveyRecordService: SurveyRecordService,
    private toasterService: ToasterService,
    private uiRouter: UIRouter,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.loadSurveyRecord();
  }

  loadSurveyRecord() {
    this.surveyRecordService.get({
      surveyId: this.surveyId,
      surveyRecordId: this.surveyRecordId
    }).subscribe(
      (result: SurveyRecord.SurveyRecord) => {
        this.surveyRecord = result;
      });
  }

  downloadPdf() {
    if (this.surveyRecord) {
      this.surveyRecordService.downloadPdf({
        surveyId: this.surveyRecord.surveyId,
        surveyRecordId: this.surveyRecord.id
      }).subscribe(
        (res: DownloadedFile) => {
          saveAs(
            res.getBlob(),
            res.getFileName(this.surveyRecord!.id + '_' + this.surveyRecord!.creationTime.toUtcIsoString() + '.pdf')
          );
        },
        err => {
          this.toasterService.pop({
            timeout: UiConstants.ToastTimeoutLong,
            type: UiConstants.toastTypeError,
            title: this.translateService.instant(StringKey.ATTACHMENT_TITLE),
            body: this.translateService.instant(StringKey.PDF_DOWNLOAD_ERROR_MESSAGE)
          });
        }
      );
    }
  }

  cloneSurveyRecord() {
    if (this.surveyRecord && this.allowClone) {
      this.uiRouter.stateService.go(StateName.SURVEY_RECORD_CLONE, {
        surveyId: this.surveyRecord.surveyId,
        id: this.surveyRecord.id
      });
    }
  }

}
