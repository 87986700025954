<div class="app flex-row align-items-center">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6 text-center">
        <img class="w-100" src="../../../assets/img/error/connection_error.svg">
        <h4 class="pt-3">{{'CONFIGURATION_ERROR_TITLE' | translate}}</h4>
        <p class="pt-1 error-text-label mb-2">{{'CONFIGURATION_ERROR_SUBTITLE' | translate}}</p>
        <div class="mx-auto">
          <button class="btn btn-primary-green" type="button" (click)="refresh()">{{'COMMON_BUTTON_REFRESH' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
