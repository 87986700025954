/* eslint-disable */
import { Directive, HostListener, Input, } from '@angular/core';
import { Logger, LoggerFactory } from '../util/logger-factory';
/* eslint-enable */

/* eslint-disable */
@Directive({
  selector: '[validateOnBlur]',
})
/* eslint-enable */
export class ValidateOnBlurDirective {

  @Input()
  validateFormControl;

  private logger: Logger;

  constructor() {
    this.logger = LoggerFactory.createLogger('ValidateOnBlurDirective');
  }

  @HostListener('focusout', ['$event.target'])
  onFocusout(target) {
    if (!this.validateFormControl) {
      this.warnUndefinedFormControl();
      return;
    }
    this.validateFormControl.markAsTouched();
  }

  @HostListener('keyup', ['$event.target'])
  onValueChanged(target) {
    if (!this.validateFormControl) {
      this.warnUndefinedFormControl();
      return;
    }
    this.validateFormControl.markAsTouched();
  }

  private warnUndefinedFormControl() {
    this.logger.warn('Undefined form control. ' +
      'Use validateOnBlur with [validateFormControl]="formGroup.controls[\'formControlName\']". ' +
      'If the warning present, check the formControlName.');
  }

}
