import { Dates, OffsetDateTime } from '../../lib/util/dates';
import { AddressModel } from '../../lib/address';
import { MultiselectOptionItem } from '../../util/core-utils';

export class ParcelCollectionPointEditModel {

  id: number;
  name: string = '';
  code: string = '';
  deliveryMethod: MultiselectOptionItem<number>[] = [];
  openingTimes: string = '';
  note: string = '';
  disabled: boolean = false;
  postalAddress: AddressModel.PostalAddressModel;
  latitude: string = '';
  longitude: string = '';

  constructor() {
    this.postalAddress = new AddressModel.PostalAddressModel();
    this.postalAddress.type = AddressModel.PostalAddressModelType.COMPLEX;
  }

  get deliveryMethodId(): number | undefined {
    return this.deliveryMethod.length > 0 ? this.deliveryMethod[0].id : undefined;
  }

}

export class ParcelCollectionPointListModel {

  id: number;
  creationTime: OffsetDateTime = Dates.emptyOffsetDateTime();
  updateTime: OffsetDateTime = Dates.emptyOffsetDateTime();
  name: string = '';
  code: string = '';
  deliveryMethodName: string = '';
  disabled: boolean = false;
  postalAddress: string = '';

}

export class ParcelCollectionPointDetailModel extends ParcelCollectionPointListModel {

  openingTimes: string = '';
  note: string = '';
  coordinates: string = '';
  latitude: string = '';
  longitude: string = '';
  deliveryMethod: MultiselectOptionItem<number>[] = [];

}
