import { Component, Input, OnInit } from '@angular/core';
import { RightModel } from '../../../app.rights';
import { UserGroupRoleType } from './user-group-type-based-role-card/user-group-type-based-role-card.component';

@Component({
  selector: 'app-usergroup-type-based-roles',
  templateUrl: './user-group-type-based-roles.component.html',
  styleUrls: ['./user-group-type-based-roles.component.scss']
})
export class UserGroupTypeBasedRolesComponent implements OnInit {

  UserGroupRoleType = UserGroupRoleType;

  @Input()
  userGroupId: number;

  @Input()
  readonly: boolean = true;

  @Input()
  rightModel: RightModel = RightModel.empty();

  constructor() { }

  ngOnInit(): void {}

}
