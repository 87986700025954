<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-custom-padding animated fadeIn">
  <div class="row ml-0 mr-0">
    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12 card-custom-padding">
      <div class="card card-top-color cursor-pointer"
           uiSref="Admin.CustomerRecordGlobalList">
        <div class="card-body text-center">
          <div class="h4 all-customers-title">{{customerRecordGlobalCount}}</div>
          <div class="h6 text-uppercase all-customers-subtitle">{{ 'CUSTOMER_RECORD_GLOBAL_LIST_TITLE' | translate }}</div>
        </div>
      </div>
      <div class="container-vertical-padding-half"></div>
    </div>
    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12 card-custom-padding" *ngFor="let customer of dashboardCustomerModels">
      <div class="card card-top-color cursor-pointer"
           uiSref="Admin.CustomerRecordList"
           [uiParams]="{ customerId: customer.customerId }">
        <div class="card-body">
          <div class="h1 float-right mb-0">
            <app-type-icon [icon]="getCustomerIconFromId(customer.customerId)" big="true"></app-type-icon>
          </div>
          <div class="h4 mb-0">{{getCustomerRecordCount(customer.customerRecordCount)}}</div>
          <small class="text-uppercase font-weight-bold">{{customer.name}}</small>
          <br>
          <small class="text-muted">{{customer.customerId}}</small>
        </div>
      </div>
      <div class="container-vertical-padding-half"></div>
    </div>
  </div>
</div>
