/* eslint-disable */
import { GrantedPermissionSet } from './lib/right.service';
import { UserProfile, } from './lib/auth.service';
import { PermissionDefinition, PermissionDefinitions } from './app.permission-definitions';
import { RightModel } from './app.rights';
import { Task } from './lib/task/task.service';
import { Shipment, ShipmentGroup } from './lib/shipment-group/shipment-group.service';
/* eslint-enable */


export interface ShipmentRightModelContext {
  rightModel: RightModel;
  currentCompanies: number[];
}

export interface ShipmentGroupModel {
  transporterId: number;
}
export interface ShipmentModel {
  subTransporterId?: number;
}

/**
 * Extension for shipment rights.
 * @see UserGrantedPermissionSet
 */
export class ShipmentRightModel {

  public readonly shipmentSubTransporterUpdate: ShipmentSubTransporterGrantedPermissionSet;
  public readonly shipmentGroupDepotUpdate: ShipmentGroupDepotUpdateGrantedPermissionSet;
  public readonly shipmentGroupDemandRemove: ShipmentGroupDemandRemoveGrantedPermissionSet;

  static empty() {
    return new ShipmentRightModel();
  }

  static of(context: ShipmentRightModelContext) {
    return new ShipmentRightModel(context);
  }

  private static map<T>(
    presentFactory: (context: ShipmentRightModelContext) => T, absentFactory: () => T, context?: ShipmentRightModelContext): T {
    if (context) {
      return presentFactory(context);
    }
    else {
      return absentFactory();
    }
  }

  private constructor(context?: ShipmentRightModelContext) {
    this.shipmentSubTransporterUpdate = ShipmentRightModel.map(
      (c: ShipmentRightModelContext) =>
        ShipmentSubTransporterGrantedPermissionSet.of(c.rightModel, c.currentCompanies),
      () => ShipmentSubTransporterGrantedPermissionSet.empty(),
      context);
    this.shipmentGroupDepotUpdate = ShipmentRightModel.map(
      (c: ShipmentRightModelContext) =>
        ShipmentGroupDepotUpdateGrantedPermissionSet.of(c.rightModel, c.currentCompanies),
      () => ShipmentGroupDepotUpdateGrantedPermissionSet.empty(),
      context);
    this.shipmentGroupDemandRemove = ShipmentRightModel.map(
      (c: ShipmentRightModelContext) =>
        ShipmentGroupDemandRemoveGrantedPermissionSet.of(c.rightModel, c.currentCompanies),
      () => ShipmentGroupDemandRemoveGrantedPermissionSet.empty(),
      context);
  }

}

class ShipmentGrantedPermissionSet implements GrantedPermissionSet {

  constructor(private delegate: GrantedPermissionSet,
              private currentCompanies?: number[],
              private allPermission?: string,
              private myCompanyPermission?: string) {
  }

  public hasRight(): boolean {
    return this.delegate.hasRight();
  }

  public hasRightForShipment(shipmentGroup?: ShipmentGroupModel, shipment?: ShipmentModel): boolean {
    if (this.hasAllPermission()) {
      return true;
    }
    return this.hasMyCompanyPermission(shipmentGroup, shipment);
  }

  public contains(permission: PermissionDefinition): boolean {
    return this.delegate.contains(permission);
  }

  private hasAllPermission(): boolean {
    if (!this.allPermission) {
      return false;
    }
    return this.delegate.contains(this.allPermission);
  }

  private hasMyCompanyPermission(shipmentGroup?: ShipmentGroupModel, shipment?: ShipmentModel): boolean {
    if (!shipment && !shipmentGroup) {
      return false;
    }
    if (!this.myCompanyPermission) {
      return false;
    }
    if (!this.currentCompanies) {
      return false;
    }
    if (shipmentGroup && this.currentCompanies.findIndex(s => s === shipmentGroup.transporterId) > -1) {
      return this.delegate.contains(this.myCompanyPermission);
    }
    if (shipment && shipment.subTransporterId && this.currentCompanies
      .findIndex(s => s === shipment.subTransporterId) > -1) {
      return this.delegate.contains(this.myCompanyPermission);
    }
    return false;
  }

}

export class ShipmentSubTransporterGrantedPermissionSet extends ShipmentGrantedPermissionSet {

  static empty(): ShipmentSubTransporterGrantedPermissionSet {
    return new ShipmentSubTransporterGrantedPermissionSet(GrantedPermissionSet.empty());
  }

  static of(rightModel: RightModel, currentCompanies: number[]): ShipmentSubTransporterGrantedPermissionSet {
      return new ShipmentSubTransporterGrantedPermissionSet(
        rightModel.shipmentChangeSubTransporter,
        currentCompanies,
        PermissionDefinitions.SHIPMENT_CHANGE_SUB_TRANSPORTER_ALL,
        PermissionDefinitions.SHIPMENT_CHANGE_SUB_TRANSPORTER_MY_COMPANY);
  }

}

export class ShipmentGroupDepotUpdateGrantedPermissionSet extends ShipmentGrantedPermissionSet {

  static empty(): ShipmentGroupDepotUpdateGrantedPermissionSet {
    return new ShipmentGroupDepotUpdateGrantedPermissionSet(GrantedPermissionSet.empty());
  }

  static of(rightModel: RightModel, currentCompanies: number[]): ShipmentGroupDepotUpdateGrantedPermissionSet {
      return new ShipmentGroupDepotUpdateGrantedPermissionSet(
        rightModel.shipmentGroupDepotUpdate,
        currentCompanies,
        PermissionDefinitions.SHIPMENT_GROUP_DEPOT_UPDATE_ALL,
        PermissionDefinitions.SHIPMENT_GROUP_DEPOT_UPDATE_MY_COMPANY);
  }

}

export class ShipmentGroupDemandRemoveGrantedPermissionSet extends ShipmentGrantedPermissionSet {

  static empty(): ShipmentGroupDemandRemoveGrantedPermissionSet {
    return new ShipmentGroupDemandRemoveGrantedPermissionSet(GrantedPermissionSet.empty());
  }

  static of(rightModel: RightModel, currentCompanies: number[]): ShipmentGroupDemandRemoveGrantedPermissionSet {
      return new ShipmentGroupDemandRemoveGrantedPermissionSet(
        rightModel.shipmentGroupShippingDemandRemove,
        currentCompanies,
        PermissionDefinitions.SHIPMENT_GROUP_DEMAND_REMOVE_ALL,
        PermissionDefinitions.SHIPMENT_GROUP_DEMAND_REMOVE_MY_COMPANY);
  }

}

