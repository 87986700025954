import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { TaskRecord, TaskRecordService } from '../../../../lib/task/task-record.service';
import { QueryFieldModel } from '../../../../util/core-utils';
import { OrderType, QueryResult } from '../../../../lib/util/services';
import { TaskRecordHistoryModel } from '../../../task/task-record/task-record-history/task-record-history.component';
import { Set } from 'immutable';

@Component({
  selector: 'app-process-history',
  templateUrl: './process-history.component.html',
  styleUrls: ['./process-history.component.scss']
})
export class ProcessHistoryComponent implements OnInit, AfterViewInit {

  TaskRecord = TaskRecord;

  @Input()
  processId: number;

  taskRecordHistoryList: TaskRecordHistoryModel[] = [];

  queryModel: QueryFieldModel<TaskRecord.LogOrderField> = new QueryFieldModel(TaskRecord.LogOrderField.LOG_TIME, OrderType.DESC);

  selectedIndex?: number;

  constructor(private taskRecordService: TaskRecordService) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.loadList();
  }

  loadList(pageNumber?: number) {
    const requestedPage: number = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();
    this.taskRecordService.getGlobalLogHistory({
      processId: this.processId,
      paging: {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      },
      orders: order ? Set.of(order) : Set.of(),
    }).subscribe((result: QueryResult<TaskRecord.Log>) => {
      this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
      this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.queryModel.currentPage = requestedPage;
      this.taskRecordHistoryList = result.items.toArray().map(r => {
        return {
          id: r.id,
          changeType: TaskRecord.LogTypeMap.get(r.logType),
          changeTime: r.logTime.toUtcIsoString(),
          userHumanName: r.user.personName,
          message: r.message,
          coordinate: r.coordinate,
          taskRecord: {
            id: r.taskRecord.id,
            name: r.taskRecord.name,
            taskId: r.taskRecord.taskId,
            externalId: r.taskRecord.externalId
          }
        };
      });
    });
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  highlightTableRow(index: number) {
    this.selectedIndex = index;
    setTimeout(() => {
      this.selectedIndex = undefined;
    }, 2000);
  }

  orderBy(field: TaskRecord.LogOrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

}
