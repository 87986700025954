import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {NgModel} from '@angular/forms';
import {CustomerItem, CustomerRightModel} from '../../../util/customer-utils';
import {AddressModel} from '../../../lib/address';
import {MultiselectOptionItem, OptionItem, SelectUtils, UiConstants} from '../../../util/core-utils';
import {TranslateService} from '@ngx-translate/core';
import {Angular2Multiselects} from '../../../util/multiselect';
import {OrderType, QueryResult} from '../../../lib/util/services';
import {Country, CountryService} from '../../../lib/country.service';
import {List, Set} from 'immutable';
import {ConfigurationService} from '../../../lib/core-ext/configuration.service';
import {Customer, CustomerService} from '../../../lib/customer/customer.service';
import {
  CustomerRecord,
  CustomerRecordContactLocation,
  CustomerRecordService
} from '../../../lib/customer/customer-record.service';
import {UIRouter} from '@uirouter/angular';
import {StateName} from '../../../app.state-names';
import {Strings} from '../../../lib/util/strings';
import {GrantedPermissionSetResolver, RightResolver, RightService} from '../../../lib/right.service';
import {
  StepperContainerComponent
} from '../../../shared/stepper/stepper-container/stepper-container/stepper-container.component';
import {
  CustomerRecordFieldModel,
  CustomerRecordFieldType,
  CustomerRecordUtils
} from '../../../util/customer-record-utils';
import {EuVatNumber, HuTaxNumber} from '../../../lib/util/tax-numbers';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {InputMask} from '../../../util/input-masks';
import {GenderTypeItem} from '../customer-record-base/customer-record-base.component';
import {ToasterService} from '../../../fork/angular2-toaster/src/toaster.service';
import {StringKey} from '../../../app.string-keys';
import {CompanyMultiselectProvider} from '../../../lib/company/company-multiselect.provider';
import {LedgerNumberMultiselectProvider} from '../../../lib/ledger/number/ledger-number-multiselect.provider';
import {UserMeService} from '../../../lib/user/user-me.service';
import {RightModel} from '../../../app.rights';
import {UserMultiselectProvider} from '../../../lib/user/user-multiselect.provider';
import {UserGroupMultiselectProvider} from '../../../lib/user-group/user-group-multiselect.provider';
import {OperationRights} from '../../../app.right-definitions';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import OrderField = CustomerRecordContactLocation.OrderField;
import GenderType = CustomerRecord.GenderType;

@Component({
  selector: 'app-customer-record-quick-create-material-dialog',
  templateUrl: './customer-record-quick-create-material-dialog.component.html',
  styleUrls: ['./customer-record-quick-create-material-dialog.component.scss']
})
export class CustomerRecordQuickCreateMaterialDialogComponent implements OnInit {
  UiConstants = UiConstants;
  SelectUtils = SelectUtils;
  Strings = Strings;
  CustomerRecordFieldType = CustomerRecordFieldType;
  InputMask = InputMask;
  CustomerRecordUtils = CustomerRecordUtils;

  @ViewChild(StepperContainerComponent)
  stepper: StepperContainerComponent;

  @ViewChild('matContent')
  matDialogContent: ElementRef;

  customers: CustomerItem[] = [];

  model: CustomerRecordQuickCreateModel = new CustomerRecordQuickCreateModel();
  modelLoaded = false;

  dropdownSettings: Angular2Multiselects.Settings;
  assigneeDropdownSettings: Angular2Multiselects.Settings;
  locationDropdownSettings: Angular2Multiselects.Settings;
  companyDropdownSettings: Angular2Multiselects.Settings;

  countryItems: List<AddressModel.CountryItem>;

  users: MultiselectOptionItem<number>[] = [];
  userGroups: MultiselectOptionItem<number>[] = [];
  contactLocations: MultiselectOptionItem<number>[] = [];
  companies: MultiselectOptionItem<number>[] = [];
  ledgerNumbers: MultiselectOptionItem<number>[] = [];
  rightModel: RightModel = RightModel.empty();
  genderTypes: GenderTypeItem[];

  @ViewChild('type')
  typeInput: NgModel;

  private currentUserId: number;
  private currentUserGroupIds: number[];

  constructor(private countryService: CountryService,
              private configService: ConfigurationService,
              private translateService: TranslateService,
              private customerService: CustomerService,
              private customerRecordService: CustomerRecordService,
              private userMultiselectProvider: UserMultiselectProvider,
              private rightService: RightService,
              private userMeService: UserMeService,
              private toasterService: ToasterService,
              private userGroupMultiselectProvider: UserGroupMultiselectProvider,
              private companyMultiselectProvider: CompanyMultiselectProvider,
              private ledgerNumberMultiselectProvider: LedgerNumberMultiselectProvider,
              private router: UIRouter,
              public dialogRef: MatDialogRef<CustomerRecordQuickCreateMaterialDialogComponent,
                CustomerRecordQuickCreateMaterialDialogResult>,
              @Inject(MAT_DIALOG_DATA) public data: CustomerRecordQuickCreateMaterialDialogData
  ) {
  }

  public static openDialog(
    dialog: MatDialog,
    data: CustomerRecordQuickCreateMaterialDialogData,
    resultCallback: (result?: CustomerRecordQuickCreateMaterialDialogResult) => void) {
    const dialogRef = dialog.open(CustomerRecordQuickCreateMaterialDialogComponent, {
      closeOnNavigation: true,
      data: data,
      width: '60%',
      height: 'auto',
      panelClass: 'custom-dialog-container'
    });
    dialogRef.afterClosed().subscribe((result: CustomerRecordQuickCreateMaterialDialogResult) => {
      resultCallback(result);
    });
  }

  ngOnInit() {
    this.initDropdownSettings();
    this.loadCurrentUser(() => {
      this.loadData();
    });
  }

  loadData() {
    this.loadCustomerTypes();
    this.loadRightModel();
    if (this.data.forContactPersonData) {
      this.loadContactLocations();
    }
    this.loadGenderTypes();
    this.model = new CustomerRecordQuickCreateModel();
    this.model.contactPerson = this.data.forContactPersonData !== undefined;
    if (!this.modelLoaded) {
      this.loadCountries(() => {
        this.model.postalAddress.loadCountryItems(this.countryItems, this.configService.getDefaultSelectedCountryCode());
        this.modelLoaded = true;
      });
    } else {
      this.model.postalAddress.loadCountryItems(this.countryItems, this.configService.getDefaultSelectedCountryCode());
    }


  }

  loadCustomerTypes() {
    this.customerService.query({
      types: this.data.forContactPersonData ? 'PERSON' : undefined,
      contactPerson: this.data.forContactPersonData !== undefined,
      disabled: false,
      rights: Set.of(OperationRights.CUSTOMER_RECORD_CREATE)
    }).subscribe(
      (result: QueryResult<Customer.Customer>) => {
        this.customers = [];
        result.items.forEach((customer) => {
          const customerGrantedRights = new CustomerRightModel(GrantedPermissionSetResolver.byGrantedRights(customer!.grantedRights));
          if (!customerGrantedRights.customerRecordCreate.hasRight()) {
            return;
          }
          const item = new CustomerItem();
          item.id = customer!.customerId;
          item.text = customer!.name;
          item.fieldModel = CustomerRecordFieldModel.ofCustomer(customer!);
          item.complexNameRequired = customer!.complexName;
          item.type = customer!.type;
          item.webOwnerTypes = customer!.webOwnerTypes;
          this.customers.push(item);
          if (this.data.customerId && item.id === +this.data.customerId) {
            this.model.customer = [];
            this.model.customer.push(item);
            this.typeInput.control.updateValueAndValidity();
            this.onCustomerChanged();
          }
        });
      }
    );
  }

  private loadRightModel() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  private loadCurrentUser(completion: () => void) {
    this.userMeService.loadMe().subscribe(u => {
      this.currentUserId = u.id;
      this.currentUserGroupIds = u.user_group_ids;
      completion();
    })
  }

  private loadDefaultOwners(webOwnerTypes: Set<Customer.WebOwnerType>) {
    const userIds: number[] = [];
    const userGroupIds: number[] = [];
    const loadMe = webOwnerTypes.filter(t => t !== undefined
      && t.ownerType === 'CURRENT_USER').size > 0;
    const loadMyGroups = webOwnerTypes.filter(t => t !== undefined
      && t.ownerType === 'CURRENT_USER_GROUP').size > 0;

    if (loadMe) {
      userIds.push(this.currentUserId);
    }
    if (loadMyGroups) {
      userGroupIds.push(...this.currentUserGroupIds);
    }

    webOwnerTypes.forEach(t => {
      if (t) {
        if (t.ownerType === 'PRESET_USER' && t.presetUserIds) {
          userIds.push(...t.presetUserIds.toArray());
        }
        if (t.ownerType === 'PRESET_USER_GROUP' && t.presetUserGroupIds) {
          userGroupIds.push(...t.presetUserGroupIds.toArray());
        }
      }
    });
    this.loadCurrentUsers(Set.of(...userIds));
    this.loadCurrentUserGroups(Set.of(...userGroupIds));

  }

  loadContactLocations() {
    this.customerRecordService.queryContactLocations({
      customerId: this.data.forContactPersonData!.customerId,
      customerRecordId: this.data.forContactPersonData!.customerRecordId,
      ids: this.data.forContactPersonData!.contactLocationId ? Set.of(this.data.forContactPersonData!.contactLocationId) : undefined,
      orders: Set.of({type: OrderType.ASC, field: OrderField.NAME})
    }).subscribe(result => {
      this.contactLocations = result.items.toArray().map(l => ({id: l.id!, itemName: l.name}));
      if (this.data.forContactPersonData!.contactLocationId) {
        this.model.contactLocations = this.contactLocations.length > 0 ? [this.contactLocations[0]] : [];
      }
    });
  }

  private loadCountries(completion: () => void) {
    this.countryService.query({}).subscribe((result: QueryResult<Country.Country>) => {
      this.countryItems = AddressModel.CountryItem.fromCountryList(result.items);
      completion();
    });
  }

  private loadGenderTypes() {
    this.genderTypes = [];
    CustomerRecord.genderTypes.forEach((type) => {
      const item = {
        id: type.type,
        text: '....'
      };
      this.genderTypes.push(item);
      this.translateService.get(type.stringKey).subscribe((text: string) => {
        item.text = text;
      })
    });
  }

  createCustomerRecord() {
    this.customerRecordService.quickCreate({
      withFormRecord: false,
      rights: this.data.rights,
      customerId: this.model.selectedCustomer!.id!,
      name: this.isComplexNameRequired ? undefined : this.model.name,
      firstName: this.isComplexNameRequired ? this.model.firstName : undefined,
      lastName: this.isComplexNameRequired ? this.model.lastName : undefined,
      position: Strings.undefinedOrNonEmpty(this.model.position),
      mainContractNumber: Strings.undefinedOrNonEmpty(this.model.mainContractNumber),
      postalAddress: this.isFieldManaged(CustomerRecordFieldType.POSTAL_ADDRESS_AND_INVOICE_ADDRESS_AND_NOTIFICATION_ADDRESS)
        ? this.model.postalAddress.toData()
        : undefined,
      notificationAddressEqPostal: true,
      emailAddresses: AddressModel.EmailAddressModel.toDataList(this.model.emailAddresses),
      phoneNumbers: AddressModel.PhoneNumberModel.toDataList(this.model.phoneNumbers),
      ownerUserIds: Set.of(...this.model.users.map(u => u.id)),
      ownerGroupIds: Set.of(...this.model.userGroups.map(ug => ug.id)),
      webAddress: this.model.webAddress,
      taxNumber: this.model.taxNumber.truncated,
      euTaxNumber: this.model.euTaxNumber.truncated,
      invoiceDeadlineAdditionalDays: this.model.invoiceDeadlineAdditionalDays,
      taskRecordDeadlineAdditionalDays: this.model.taskRecordDeadlineAdditionalDays,
      placeOfBirth: this.model.placeOfBirth,
      dateOfBirth: this.model.dateOfBirth,
      birthName: this.model.birthName,
      gender: this.model.gender,
      mothersName: this.model.mothersName,
      identityDocumentType: this.model.identityDocumentType,
      identityDocumentNumber: this.model.identityDocumentNumber,
      companyId: this.model.company.length === 1 ? this.model.company[0].id : undefined,
      ledgerNumber: this.model.getLedgerNumberId()
    }).subscribe((result: CustomerRecord.CustomerRecord) => {
        if (this.data.forContactPersonData) {
          const contactLocationIds = this.model.contactLocations.map(l => l.id);
          this.customerRecordService.updateContactLocationsContactPerson({
            customerRecordId: this.data.forContactPersonData.customerRecordId,
            contactPersonCustomerRecordId: result.customerRecordId,
            relatedContactLocationId: contactLocationIds.length === 0 ? undefined : contactLocationIds[0]
          }).subscribe(r => {
            this.closeDialog(true, result);
            if (this.model.navigateAfterCreate) {
              this.router.stateService.go(
                StateName.CUSTOMER_RECORD_EDIT,
                {customerId: result.customerId, customerRecordId: result.customerRecordId}
              );
            }
          });
        } else {
          this.closeDialog(true, result);
          if (this.model.navigateAfterCreate) {
            this.router.stateService.go(
              StateName.CUSTOMER_RECORD_EDIT,
              {customerId: result.customerId, customerRecordId: result.customerRecordId}
            );
          }
        }
      },
      (error) => {
        const keys = Array.from(error.fieldErrorMap.keys());
        keys.forEach(k => {
          this.toasterService.pop({
            timeout: UiConstants.ToastTimeoutLong,
            type: UiConstants.toastTypeError,
            title: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_TITLE),
            body: error.fieldErrorMap.get(k).text + ': ' + k,
          });
        });
      });
  }

  closeDialog(success: boolean, result?: CustomerRecord.CustomerRecord) {
    this.dialogRef.close({success: success, customerRecord: result});
  }

  initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .labelKey(OptionItem.KEY_TEXT)
      .build();
    this.assigneeDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableCheckAll(true)
      .enableSearchFilter(true)
      .remoteSearch(true)
      .build();
    this.locationDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableCheckAll(false)
      .enableSearchFilter(true)
      .build();
    this.companyDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableCheckAll(false)
      .enableSearchFilter(true)
      .remoteSearch(true)
      .build();
  }

  onExtraDataClicked() {
    this.model.navigateAfterCreate = true;
    this.stepper.next();
  }

  isFieldManaged(type: CustomerRecordFieldType): boolean {
    return this.model.selectedCustomer !== undefined && this.model.selectedCustomer.fieldModel!.isManaged(type);
  }

  isFieldRequired(type: CustomerRecordFieldType): boolean {
    return this.model.selectedCustomer !== undefined && this.model.selectedCustomer.fieldModel!.isRequired(type);
  }

  get isComplexNameRequired() {
    return this.model.selectedCustomer !== undefined && this.model.selectedCustomer.complexNameRequired;
  }

  basicDataStepValid: () => boolean = () => {
    if (!this.model.selectedCustomer || this.model.selectedCustomer.id === null) {
      return false;
    }
    if (!this.isComplexNameRequired && !Strings.undefinedOrNonEmpty(this.model.name)) {
      return false;
    }
    if (this.isComplexNameRequired
      && (!Strings.undefinedOrNonEmpty(this.model.firstName) || !Strings.undefinedOrNonEmpty(this.model.lastName))) {
      return false;
    }
    if (this.isFieldRequired(CustomerRecordFieldType.ASSIGNEE) && this.model.users.length === 0 && this.model.userGroups.length === 0) {
      return false;
    }
    if (this.isFieldRequired(CustomerRecordFieldType.POSITION) && !Strings.undefinedOrNonEmpty(this.model.position)) {
      return false;
    }
    if (this.isFieldRequired(CustomerRecordFieldType.MAIN_CONTRACT_NUMBER) && !Strings.undefinedOrNonEmpty(this.model.mainContractNumber)) {
      return false;
    }
    if (!AddressModel.EmailAddressModel.isValid(this.model.emailAddresses)) {
      return false;
    }
    if (this.isFieldRequired(CustomerRecordFieldType.EMAIL_ADDRESSES) && this.model.emailAddresses.length === 0) {
      return false;
    }
    if (!AddressModel.PhoneNumberModel.isValid(this.model.phoneNumbers)) {
      return false;
    }
    if (this.isFieldRequired(CustomerRecordFieldType.PHONE_NUMBERS) && this.model.phoneNumbers.length === 0) {
      return false;
    }
    return true;
  };

  locationStepValid: () => boolean = () => {
    return this.model.postalAddress.valid;
  };

  otherDataStepVisible(): boolean {
    const stepFields: CustomerRecordFieldType[] = [
      CustomerRecordFieldType.WEB_ADDRESS,
      CustomerRecordFieldType.IDENTITY_DOCUMENT_NUMBER,
      CustomerRecordFieldType.IDENTITY_DOCUMENT_TYPE,
      CustomerRecordFieldType.MOTHERS_NAME,
      CustomerRecordFieldType.GENDER,
      CustomerRecordFieldType.BIRTH_NAME,
      CustomerRecordFieldType.DATE_OF_BIRTH,
      CustomerRecordFieldType.PLACE_OF_BIRTH,
      CustomerRecordFieldType.TASK_RECORD_DEADLINE_ADDITIONAL_DAYS,
      CustomerRecordFieldType.INVOICE_DEADLINE_ADDITIONAL_DAYS,
      CustomerRecordFieldType.EU_TAX_NUMBER,
      CustomerRecordFieldType.TAX_NUMBER,
      CustomerRecordFieldType.COMPANY,
    ];
    for (let i = 0; i < stepFields.length; i++) {
      if (this.isFieldRequired(stepFields[i]) || this.isFieldManaged(stepFields[i])) {
        return true;
      }
    }
    return false;
  }

  otherDataStepValid: () => boolean = () => {
    if (this.isFieldRequired(CustomerRecordFieldType.WEB_ADDRESS)) {
      if (this.model.webAddress.length === 0) {
        return false;
      }
    }
    if (this.isFieldRequired(CustomerRecordFieldType.TAX_NUMBER)) {
      if (this.model.taxNumber.raw.length === 0) {
        return false;
      }
    }
    if (this.isFieldRequired(CustomerRecordFieldType.EU_TAX_NUMBER)) {
      if (this.model.euTaxNumber.raw.length === 0) {
        return false;
      }
    }
    if (this.isFieldRequired(CustomerRecordFieldType.INVOICE_DEADLINE_ADDITIONAL_DAYS)) {
      if (!this.model.invoiceDeadlineAdditionalDays) {
        return false;
      }
    }
    if (this.isFieldRequired(CustomerRecordFieldType.TASK_RECORD_DEADLINE_ADDITIONAL_DAYS)) {
      if (!this.model.taskRecordDeadlineAdditionalDays) {
        return false;
      }
    }
    if (this.isFieldRequired(CustomerRecordFieldType.PLACE_OF_BIRTH)) {
      if (this.model.placeOfBirth.length === 0) {
        return false;
      }
    }
    if (this.isFieldRequired(CustomerRecordFieldType.DATE_OF_BIRTH)) {
      if (!this.model.dateOfBirth) {
        return false;
      }
    }
    if (this.isFieldRequired(CustomerRecordFieldType.BIRTH_NAME)) {
      if (this.model.birthName.length === 0) {
        return false;
      }
    }
    if (this.isFieldRequired(CustomerRecordFieldType.GENDER)) {
      if (!this.model.gender) {
        return false;
      }
    }
    if (this.isFieldRequired(CustomerRecordFieldType.MOTHERS_NAME)) {
      if (this.model.mothersName.length === 0) {
        return false;
      }
    }
    if (this.isFieldRequired(CustomerRecordFieldType.IDENTITY_DOCUMENT_TYPE)) {
      if (this.model.identityDocumentType.length === 0) {
        return false;
      }
    }
    if (this.isFieldRequired(CustomerRecordFieldType.IDENTITY_DOCUMENT_NUMBER)) {
      if (this.model.identityDocumentNumber.length === 0) {
        return false;
      }
    }
    if (this.isFieldRequired(CustomerRecordFieldType.COMPANY)) {
      if (this.model.company.length === 0) {
        return false;
      }
    }
    return true;
  };

  onCustomerChanged() {
    if (this.isFieldRequired(CustomerRecordFieldType.COMPANY)) {
      this.loadCompanies();
    }
    if (this.model.selectedCustomer && this.model.selectedCustomer.webOwnerTypes && this.model.selectedCustomer.webOwnerTypes.size > 0) {
      this.loadDefaultOwners(this.model.selectedCustomer.webOwnerTypes);
    }
  }

  loadCompanies(q?: string) {
    this.companyMultiselectProvider.searchCompanies(q).subscribe(result => this.companies = result);
  }

  loadLedgerNumbers(q?: string) {
    if (this.rightModel.ledgerNumberRead.hasRight()) {
      this.ledgerNumberMultiselectProvider.loadActive(q).subscribe(result => this.ledgerNumbers = result);
    }
  }

  loadCurrentUsers(ownerUserIds?: Set<number>) {
    if (ownerUserIds && ownerUserIds.size > 0) {
      this.userMultiselectProvider.getByIds(ownerUserIds.toArray()).subscribe(users => this.model.users = users);
    }
  }

  loadUserSearch(searchValue?: string) {
    this.userMultiselectProvider.loadActive(searchValue).subscribe(users => this.users = users);
  }

  loadCurrentUserGroups(ownerGroupIds?: Set<number>) {
    if (ownerGroupIds && ownerGroupIds.size > 0) {
      this.userGroupMultiselectProvider.getByIds(ownerGroupIds.toArray()).subscribe(groups => this.model.userGroups = groups);
    }
  }

  loadUserGroupSearch(searchValue?: string) {
    this.userGroupMultiselectProvider.loadActive(searchValue).subscribe(groups => this.userGroups = groups);
  }

  scrollToTop() {
    this.matDialogContent.nativeElement.scrollTo(0, 0);
  }
}

export class CustomerRecordQuickCreateModel {

  contactPerson: boolean = false;

  customer: CustomerItem[] = [];
  name: string = '';
  firstName: string = '';
  lastName: string = '';
  position: string = '';
  mainContractNumber: string = '';
  postalAddress: AddressModel.PostalAddressModel;
  users: MultiselectOptionItem<number>[] = [];
  userGroups: MultiselectOptionItem<number>[] = [];
  readonly emailAddresses: AddressModel.EmailAddressModel[] = [];
  readonly phoneNumbers: AddressModel.PhoneNumberModel[] = [];
  navigateAfterCreate: boolean = false;
  contactLocations: MultiselectOptionItem<number>[] = [];
  webAddress: string = '';
  taxNumber: HuTaxNumber = new HuTaxNumber();
  euTaxNumber: EuVatNumber = new EuVatNumber();
  invoiceDeadlineAdditionalDays?: number;
  taskRecordDeadlineAdditionalDays?: number;

  // PERSON type
  placeOfBirth: string = '';
  dateOfBirth: NgbDateStruct | null = null;
  birthName: string = '';
  gender: GenderType | undefined;
  mothersName: string = '';
  identityDocumentType: string = '';
  identityDocumentNumber: string = '';
  company: MultiselectOptionItem<number>[] = [];
  ledgerNumber: MultiselectOptionItem<number>[] = [];

  getLedgerNumberId(): number | undefined {
    return this.ledgerNumber.length > 0 ? this.ledgerNumber[0].id : undefined;
  }

  constructor() {
    this.postalAddress = new AddressModel.PostalAddressModel();
    this.postalAddress.type = AddressModel.PostalAddressModelType.COMPLEX;
  }

  get selectedCustomer(): CustomerItem | undefined {
    return this.customer.length === 1 ? this.customer[0] : undefined;
  }
}

export interface ForContactPersonData {
  customerId: number;
  customerRecordId: number;
  contactLocationId?: number;
}

export interface CustomerRecordQuickCreateMaterialDialogData {
  customerId?: number;
  forContactPersonData?: ForContactPersonData;
  rights?: Set<string>;
}

export interface CustomerRecordQuickCreateMaterialDialogResult {
  success: boolean;
  customerRecord?: CustomerRecord.CustomerRecord;
}
