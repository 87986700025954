/* eslint-disable */
import { Component, Input, OnInit } from '@angular/core';
import { Transport, TransportService } from '../../../lib/transport/transport.service';
import { Shipment, ShipmentGroup, ShipmentGroupService } from '../../../lib/shipment-group/shipment-group.service';
import { OrderType, QueryResult } from '../../../lib/util/services';
import { ConfigModel } from '../../../util/task-record-utils';
import { ConfigurationService } from '../../../lib/core-ext/configuration.service';
import { Address } from '../../../lib/address';
import { ShippingDemand } from '../../../lib/shipping-demand/shipping-demand.service';
import { MultiselectOptionItem, QueryFieldModel } from '../../../util/core-utils';
import { Set } from 'immutable';
import { DetailTab } from '../transport-detail/detail-tab';
import { Logger, LoggerFactory } from '../../../util/logger-factory';
import { TransportShipmentGroupModel, TransportShipmentModel } from '../../shipment/shipment-group-list/shipment-group-list.component';
import { TranslateService } from '@ngx-translate/core';
import { TransportTask } from '../../../lib/transport/transport-task/transport-task.service';
import { RightModel } from '../../../app.rights';
import { RightResolver, RightService } from '../../../lib/right.service';
import { ShipmentRightModel } from '../../../app.rights.shpiment';
import { UserService } from '../../../lib/user.service';
import ShipmentState = Shipment.ShipmentState;
import ShipmentGroupState = ShipmentGroup.ShipmentGroupState;
import TransportState = Transport.TransportState;

/* eslint-enable */

@Component({
  selector: 'app-transport-shipment-list',
  templateUrl: './transport-shipment-list.component.html',
  styleUrls: ['./transport-shipment-list.component.scss']
})
export class TransportShipmentListComponent implements OnInit, DetailTab {

  ShipmentGroup = ShipmentGroup;
  pagingId = 'transportShipmentList';

  private readonly logger: Logger;

  queryModel: QueryFieldModel<ShipmentGroup.OrderField> = new QueryFieldModel(ShipmentGroup.OrderField.ID, OrderType.DESC);

  @Input()
  transportId: number;

  @Input()
  transportState: TransportState;

  config: ConfigModel = new ConfigModel();
  shipmentGroupStates: MultiselectOptionItem<ShipmentGroupState>[] = [];
  shipmentStates: MultiselectOptionItem<ShipmentState>[] = [];

  shipmentGroupList: TransportShipmentGroupModel[] = [];

  rightModel: RightModel = RightModel.empty();
  shipmentRightModel: ShipmentRightModel = ShipmentRightModel.empty();

  constructor(private transportService: TransportService,
              private shipmentGroupService: ShipmentGroupService,
              private translateService: TranslateService,
              private configurationService: ConfigurationService,
              private userService: UserService,
              private rightService: RightService) {
    this.logger = LoggerFactory.createLogger('TransportShipmentListComponent');
  }

  ngOnInit() {
    this.loadRightModels();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.userService.get({
          id: resolver.userProfile!.id
        }).subscribe((user) => {
          this.rightModel = RightModel.of(resolver);
          this.shipmentRightModel = ShipmentRightModel.of({
            rightModel: this.rightModel,
            currentCompanies: user.companies.map(c => c.id)
          });
        });

      }
    );
  }

  initComponent() {
    this.loadConfig(() => {
      this.loadStates(() => {
        this.loadList();
      });
    });
  }

  private loadConfig(completion: () => void) {
    this.config = this.configurationService.getConfigurationModel();
    completion();
  }

  private loadStates(completion: () => void) {
    this.shipmentGroupStates = [];
    this.shipmentStates = [];
    ShipmentGroup.shipmentGroupStates.forEach(
      (state) => {
        const item = {
          id: state.state,
          itemName: state.stringKey
        };
        this.shipmentGroupStates.push(item);
      });
    this.shipmentGroupStates.forEach(
      (state: MultiselectOptionItem<ShipmentGroupState>) => {
        this.translateService.get(state.itemName).subscribe((stateName: string) => {
            state.itemName = stateName;
          }
        );
      });
    Shipment.shipmentStates.forEach(
      (state) => {
        const item = {
          id: state.state,
          itemName: state.stringKey
        };
        this.shipmentStates.push(item);
      });
    this.shipmentStates.forEach(
      (state: MultiselectOptionItem<ShipmentState>) => {
        this.translateService.get(state.itemName).subscribe((stateName: string) => {
            state.itemName = stateName;
          }
        );
      });
    completion();
  }

  private loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();
    this.shipmentGroupService.query({
      transportId: this.transportId,
      orders: Set.of(order),
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined
    }).subscribe((result: QueryResult<ShipmentGroup.ShipmentGroup>) => {
      this.shipmentGroupList = [];
      result.items.forEach((sg: ShipmentGroup.ShipmentGroup) => {
        const group = new TransportShipmentGroupModel();
        group.id = sg.id;
        group.deliveryNoteNumber = sg.deliveryNote.number;
        group.transporterCompany = sg.transporter;
        group.customer = sg.demander ? sg.demander.company ? sg.demander.company.name : '' : '';
        group.sourceAddress = this.unwrapAddress(sg.source);
        group.destinationAddress = this.unwrapAddress(sg.destination);
        group.state = this.shipmentGroupStates.find(state => state.id === sg.state)!.itemName;
        group.stateObject = ShipmentGroup.shipmentGroupStates.find(state => state.state === sg.state)!
        sg.shipments.forEach((s: Shipment.Shipment) => {
          const shipment = new TransportShipmentModel();
          shipment.id = s.id;
          shipment.deliveryNoteNumber = s.deliveryNote.number;
          shipment.waybillNumber = s.transport ? s.transport.waybillNumber : '';
          shipment.customer = group.customer;
          shipment.sourceAddress = this.unwrapAddress(s.source);
          shipment.destinationAddress = this.unwrapAddress(s.destination);
          shipment.state = this.shipmentStates.find(state => state.id === s.state)!.itemName;
          shipment.stateObject = Shipment.shipmentStates.find(state => state.state === s.state)!;
          shipment.groupIsSafetyShipping = sg.safetyShipping;
          group.shipments.push(shipment);
        });
        this.shipmentGroupList.push(group);
      });
      this.queryModel.currentPage = requestedPage;
      this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
    });
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: ShipmentGroup.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  unwrapAddress(shippingPlace: ShippingDemand.ShippingPlace): string {
    if (shippingPlace.place && shippingPlace.place.postalAddress) {
      return Address.PostalAddressMapper.toString(shippingPlace.place.postalAddress, this.config.postalAddressFormat);
    }
    else if (shippingPlace.location && shippingPlace.location.place && shippingPlace.location.place.postalAddress) {
      return Address.PostalAddressMapper.toString(shippingPlace.location.place.postalAddress, this.config.postalAddressFormat);
    }
    else {
      return '';
    }
  }

  canEditGroup(shipmentGroup: TransportShipmentGroupModel) {
    return shipmentGroup.stateObject.state !== 'DELIVERED' && this.shipmentRightModel.shipmentGroupDemandRemove
        .hasRightForShipment({transporterId: shipmentGroup.transporterCompany.id}, undefined)
      || this.shipmentRightModel.shipmentGroupDepotUpdate
        .hasRightForShipment({transporterId: shipmentGroup.transporterCompany.id}, undefined);
  }

  isDeletableShipment(shipment: TransportTask.Shipment): boolean {
    return (this.transportState === 'UNDER_PLANNING' || this.transportState === 'UNDER_REPLANNING')
      && (shipment.state === 'NEW' || shipment.state === 'OPEN' || shipment.state === 'STOCK_PROCESSING_DONE'
        || shipment.state === 'TAKEOVER_FAILED');
  }

  deleteShipmentFromTransport(shipmentId: number) {
    this.transportService.removeShipment({
      transportId: this.transportId,
      shipmentId: shipmentId
    }).subscribe(() => {
      this.loadList();
    });
  }

}
