<div class="modal-content">
  <div class="modal-header">
    <p class="modal-title"><strong>{{'FORM_RECORD_INVOICE_COPY_TITLE' | translate}}</strong></p>
    <button type="button" class="close" (click)="closeDialog()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group row">
      <label
        class="col-form-label form-control-label col-md-4 detail-title">{{'FORM_RECORD_INVOICE_COPY_OTHER_FIELD'|translate}}</label>
      <div class="col-md-8">
        <angular2-multiselect
          [(ngModel)]="selectedField"
          [ngModelOptions]="{standalone: true}"
          [data]="data.selectableFields"
          [settings]="dropdownSettings">
        </angular2-multiselect>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="closeDialog()">
      {{'COMMON_BUTTON_CANCEL' | translate}}
    </button>
    <button type="button" class="btn btn-primary" [mat-dialog-close]="selectedItem" cdkFocusInitial [disabled]="!isItemSelected" >
      {{'COMMON_BUTTON_OK' | translate}}
    </button>
  </div>
</div>
