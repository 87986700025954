import { GrantedPermissionSetResolver } from '../../../../lib/right.service';
import { Dates, OffsetDateTime } from '../../../../lib/util/dates';
import { InvoiceTagRightModel } from '../../../../lib/invoice/tag/invoice-tag-right.model';

export class InvoiceTagListModel {
  id: number;
  rights: InvoiceTagRightModel = new InvoiceTagRightModel(GrantedPermissionSetResolver.empty());
  creationTime: OffsetDateTime = Dates.emptyOffsetDateTime();
  updateTime: OffsetDateTime = Dates.emptyOffsetDateTime();
  invoiceTag: string;
  description?: string;
  disabled: boolean;
}
