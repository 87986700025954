import { Injectable } from '@angular/core';
import { NewBaseSearch, NewBaseSearchService } from '../new-base.search-service';
import { OrderField } from '../query/orderfields';
import { SearchUtils } from '../../util/search-utils';
import { MultiselectOptionItem, OrderFieldModel, OrderFunctionResolver } from '../../util/core-utils';
import { OrderType } from '../util/services';
import { OrderImportError } from './order-import-error.service';
import { LoggedInUserStorage } from '../util/storages';

@Injectable()
export class OrderImportErrorSearchService extends NewBaseSearchService<OrderField.OrderImportError> {

  protected createStorage(): OrderImportErrorSearch.Storage {
    return new OrderImportErrorSearch.Storage();
  }

}

export namespace OrderImportErrorSearch {

  export class Model extends SearchUtils.SearchModel {

    showSearch: boolean = false;

    company: MultiselectOptionItem<number>[];
    user: MultiselectOptionItem<number>[];
    realm: MultiselectOptionItem<OrderImportError.OrderImportErrorRealm>[];
    state: MultiselectOptionItem<OrderImportError.OrderImportErrorState>[];
    errorReason: MultiselectOptionItem<OrderImportError.OrderImportErrorReason>[];
    orderCode: string;

    get userId(): number | undefined {
      if (this.user && this.user.length > 0 && this.user[0].id !== null) {
        return this.user[0].id!;
      }
      return undefined;
    }

    get companyId(): number | undefined {
      if (this.company && this.company.length > 0 && this.company[0].id !== null) {
        return this.company[0].id!;
      }
      return undefined;
    }

    get realmId(): OrderImportError.OrderImportErrorRealm | null {
      if (this.realm && this.realm.length > 0 && this.realm[0].id !== null) {
        return this.realm[0].id!;
      }
      return null;
    }

    get stateId(): OrderImportError.OrderImportErrorState | null {
      if (this.state && this.state.length > 0 && this.state[0].id !== null) {
        return this.state[0].id!;
      }
      return null;
    }

    get errorReasonId(): OrderImportError.OrderImportErrorReason | null {
      if (this.errorReason && this.errorReason.length > 0 && this.errorReason[0].id !== null) {
        return this.errorReason[0].id!;
      }
      return null;
    }

    constructor() {
      super();
      this.reset();
    }

    reset() {
      this.company = [];
      this.user = [];
      this.realm = [];
      this.state = [];
      this.errorReason = [];
      this.orderCode = '';
    }

    isEmpty(): boolean {
      return this.orderCode === '' &&
        this.company.length === 0 &&
        this.user.length === 0 &&
        this.realm.length === 0 &&
        this.state.length === 0 &&
        this.errorReason.length === 0;
    }

  }

  export namespace OrderFunctions {
    export const ID = (f: OrderField.OrderImportError) => f.id;
    export const CREATION_TIME = (f: OrderField.OrderImportError) => f.creationTime;
    export const UPDATE_TIME = (f: OrderField.OrderImportError) => f.updateTime;

    export const VALUES = OrderFunctionResolver.builder<OrderField.OrderImportError>()
      .add(ID, 'id')
      .add(CREATION_TIME, 'creation_time')
      .add(UPDATE_TIME, 'update_time')
      .build();
  }

  export interface SearchDataResult {
    searchData: SearchData;
  }

  export interface SearchData extends NewBaseSearch.SearchData<OrderField.OrderImportError> {
    searchModel: Model;
  }

  interface StoredSearchRawData extends NewBaseSearch.StoredSearchRawData<OrderField.OrderImportError> {
    company: MultiselectOptionItem<number>[];
    user: MultiselectOptionItem<number>[];
    realm: MultiselectOptionItem<OrderImportError.OrderImportErrorRealm>[];
    state: MultiselectOptionItem<OrderImportError.OrderImportErrorState>[];
    errorReason: MultiselectOptionItem<OrderImportError.OrderImportErrorReason>[];
    orderCode: string;
  }

  export class Storage extends NewBaseSearch.Storage<OrderField.OrderImportError> {

    constructor() {
      const defaultSearchData: SearchData = {
        queryModel: new OrderFieldModel(OrderFunctions.ID, OrderType.DESC),
        searchModel: new Model(),
      };
      /**
       * Increment this version if you change the SearchData interface.
       */
      const versionNumber: number = 2;
      super(defaultSearchData,
        versionNumber);
    }

    protected generateKey(): string {
      return 'UserId(' + LoggedInUserStorage.getInstance().getUserId() + ')-Search-Order-Import-Error';
    }

    protected fromRaw(data: StoredSearchRawData): NewBaseSearch.SearchDataResult<OrderField.OrderImportError> {
      const searchModel = new Model();
      searchModel.company = data.company;
      searchModel.user = data.user;
      searchModel.realm = data.realm;
      searchModel.state = data.state;
      searchModel.errorReason = data.errorReason;
      searchModel.orderCode = data.orderCode;
      const searchData: SearchData = {
        queryModel: OrderFieldModel.create<OrderField.OrderImportError>(OrderFunctions.VALUES, data.order, data.queryModel),
        searchModel: searchModel,
      };
      return {
        searchData: searchData
      };
    }

    protected toRaw(data: SearchData): StoredSearchRawData {
      return {
        version: this.VERSION,
        queryModel: data.queryModel,
        order: data.queryModel.createOrder(OrderFunctions.VALUES),
        company: data.searchModel.company,
        user: data.searchModel.user,
        realm: data.searchModel.realm,
        state: data.searchModel.state,
        errorReason: data.searchModel.errorReason,
        orderCode: data.searchModel.orderCode,
      };
    }

  }

}
