/* eslint-disable */
import { Directive, ViewContainerRef, } from '@angular/core';
/* eslint-enable */

/* eslint-disable */
@Directive({
  selector: '[app-form-record-form-table-field-record-holder]',
})
/* eslint-enable */
export class FormRecordFormTableFieldRecordHolderDirective {

  constructor(public viewContainerRef: ViewContainerRef) {
  }

}
