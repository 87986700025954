/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { DownloadedFile } from '../util/downloaded-files';
import { UrlBuilder } from '../../util/url-builder';
import { map } from 'rxjs/operators';
/* eslint-enable */

@Injectable()
export class ColonnadeClientDocumentStatResourceService extends BaseHttpService {

  getEnrollerReport(request: ColonnadeClientDocumentStatResource.ColonnadeB2BStatRequest):
    Observable<ColonnadeClientDocumentStatResource.ColonnadeB2BBySponsorValue[]> {
      return this.http.get<ColonnadeClientDocumentStatResource.ColonnadeB2BBySponsorValue[]>
        (this.url + 'colonnade_insurance_1/enroller_report', this.parseParams(request));
  }

  getEnrollerReportXlsx(request: ColonnadeClientDocumentStatResource.ColonnadeB2BStatRequest): Observable<DownloadedFile> {
    const url = UrlBuilder.create('/client-document/colonnade-stats/colonnade_insurance_1/enroller_report/xlsx')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob',
      params: this.parseParams(request).params
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  getProductReport(request: ColonnadeClientDocumentStatResource.ColonnadeB2BStatRequest):
    Observable<ColonnadeClientDocumentStatResource.ColonnadeB2BByProductValue[]> {
    return this.http.get<ColonnadeClientDocumentStatResource.ColonnadeB2BByProductValue[]>
    (this.url + 'colonnade_insurance_1/product_report', this.parseParams(request));
  }

  getProductReportXlsx(request: ColonnadeClientDocumentStatResource.ColonnadeB2BStatRequest): Observable<DownloadedFile> {
    const url = UrlBuilder.create('/client-document/colonnade-stats/colonnade_insurance_1/product_report/xlsx')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob',
      params: this.parseParams(request).params
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  getEnrollerSummary(request: ColonnadeClientDocumentStatResource.ColonnadeB2BStatRequest):
    Observable<ColonnadeClientDocumentStatResource.ColonnadeB2BByEnrollerValue[]> {
    return this.http.get<ColonnadeClientDocumentStatResource.ColonnadeB2BByEnrollerValue[]>
    (this.url + 'colonnade_insurance_1/enroller_summary', this.parseParams(request));
  }

  getEnrollerSummaryXlsx(request: ColonnadeClientDocumentStatResource.ColonnadeB2BStatRequest): Observable<DownloadedFile> {
    const url = UrlBuilder.create('/client-document/colonnade-stats/colonnade_insurance_1/enroller_summary/xlsx')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob',
      params: this.parseParams(request).params
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  constructor(private http: HttpClient, private resourceHelper: ResourceHelper) {
    super(resourceHelper, '/client-document/colonnade-stats/');
  }

}

export namespace ColonnadeClientDocumentStatResource {

  export interface ColonnadeB2BStatRequest {
    user_id?: string;
    branch_code?: string;
    sponsor_code?: string;
    from_time?: string;
    to_time?: string;
  }

  export interface ColonnadeB2BBySponsorValue {
    user: UserData;
    sponsor_text: string;
    branch_text: string;
    insurance_count: number;
    sum_price: string;
    seen_count: number;
    presented_count: number;
    customer_count: number;
  }

  export interface ColonnadeB2BByProductValue {
    user: UserData;
    product_text: string;
    product_code: string;
    insurance_count: number;
    sum_price: string;
    presented_count: number;
  }

  export interface ColonnadeB2BByEnrollerValue {
    user: UserData;
    insurance_count: number;
    sum_price: string;
    seen_count: number;
    presented_count: number;
    customer_count: number;
  }

  export interface UserData {
    id: number;
    user_name: string;
    person_name: string;
  }

}
