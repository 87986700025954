/* eslint-disable */
import { Component, OnInit, ViewChild } from '@angular/core';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { TranslateService } from '@ngx-translate/core';
import { Transition, UIRouter } from '@uirouter/angular';
import { StateName } from '../../../app.state-names';
import { StockTakings, StockTakingsService } from '../../../lib/stock-takings/stock-takings.service';
import { OffsetDateTime } from '../../../lib/util/dates';
import { PasswordChangeFieldErrorMap, User, UserService } from '../../../lib/user.service';
import { Stock, StockService } from '../../../lib/stock/stock.service';
import { saveAs } from 'file-saver';
import { OptionItem, UiConstants } from '../../../util/core-utils';
import { Angular2Multiselects } from '../../../util/multiselect';
import { FileUploadDialogComponent } from '../../../shared/file-upload/dialog/file-upload-dialog.component';
import { DownloadedFile } from '../../../lib/util/downloaded-files';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { UserData, UserDataLoader, UserDataLoaderPermissionDeniedStrategy } from '../../../lib/user-data-loader';
import { RightResolver, RightService } from '../../../lib/right.service';
import { StockTakingSheetService } from '../../../lib/stock-takings/stock-taking-sheet.service';
import { StockTakingSheetListComponent } from '../stock-taking-sheet/stock-taking-sheet-list/stock-taking-sheet-list.component';
import { StockTypeName } from '../../../util/stock/stock-utils';
import { AssigneeItem } from '../../../util/task-record-utils';
import { Observable } from 'rxjs';
import { Set } from 'immutable';
import { ToasterService } from '../../../fork/angular2-toaster/angular2-toaster';
import { ResourceQueryResult } from '../../../lib/util/services';
import { IdentityArray } from '../../../lib/util/messages';
import {
  FieldError,
  FieldErrors,
  ObservableErrorResourceParser,
  ObservableErrorResponse
} from '../../../lib/util/errors';
import PdfTemplate = StockTakings.PdfTemplate;
import { StockMultiselectProvider } from '../../../lib/stock/stock-multiselect.provider';
import { MasterDataRecordService } from '../../../lib/masterdata/master-data-record.service';
/* eslint-enable */

@Component({
  selector: 'app-stock-takings-edit',
  templateUrl: './stock-takings-edit.component.html',
  styleUrls: ['./stock-takings-edit.component.scss']
})
export class StockTakingsEditComponent implements OnInit {

  UiConstants = UiConstants;

  @ViewChild('uploadDialog', { static: true })
  uploadDialog: FileUploadDialogComponent;

  @ViewChild(StockTakingSheetListComponent)
  stockTakingSheetListComponent?: StockTakingSheetListComponent;

  stockTakingId: number;
  formSubmitted: boolean = false;
  model: StockTakingEditModel = new StockTakingEditModel();
  stocks: StockItem[] = [];
  initialStocks: StockItem[] = [];
  selectedAssignee: AssigneeItem[] = [];
  selectableAssignee: AssigneeItem[] = [];
  assignee: AssigneeItem[] = [];
  assigneeSelectorVisible: boolean = false;

  uploadPath: string;
  dropdownSettings: Angular2Multiselects.Settings;
  dropdownSettingsForStock: Angular2Multiselects.Settings;
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  addStockTakingSheetForm: FormGroup;
  @ViewChild('addStockTakingSheetDialog', { static: true }) addStockTakingSheetDialog: ModalDirective;
  addStockTakingSheetDialogVisible = false;

  selectedStock: StockItem[] = [];

  addFieldErrors: AddStockTakingSheetFieldErrorMap;

  currentUserId?: number;

  constructor(
    private transition: Transition,
    private translateService: TranslateService,
    private userService: UserService,
    private stockService: StockService,
    private stockTakingsService: StockTakingsService,
    private userDataLoader: UserDataLoader,
    private formBuilder: FormBuilder,
    private rightService: RightService,
    private stockTakingSheetService: StockTakingSheetService,
    private stockMultiselectProvider: StockMultiselectProvider,
    private toasterService: ToasterService,
    private uiRouter: UIRouter
  ) {
    this.stockTakingId = this.transition.params().id;
    this.uploadPath = '/stock-takings/' + this.stockTakingId + '/pdf-template';
    this.addStockTakingSheetForm = formBuilder.group({
      stock: [[], Validators.required],
      assignee: [[], Validators.required]
    });
    this.addFieldErrors = {};
  }

  ngOnInit() {
    this.translateService.get('MENU_NAVBAR_STOCK_TAKINGS').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.STOCK_TAKINGS_LIST});
      }
    );
    this.initDropdown();
    this.loadCurrentUserId();
    this.loadAssignee();
    this.loadStocks(() => {
      this.loadModel();
    });
  }

  initDropdown() {
      this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
        .singleSelection(false)
        .enableSearchFilter(true)
        .remoteSearch(true)
        .enableCheckAll(false)
        .build();
      this.dropdownSettingsForStock = new Angular2Multiselects.SettingsBuilder()
        .singleSelection(true)
        .enableSearchFilter(false)
        .enableCheckAll(false)
        .build();
  }

  private loadCurrentUserId() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.currentUserId = resolver.userProfile!.id;
      }
    );
  }

  loadStocks(completion?: () => void, predicate?: string) {
    this.stockMultiselectProvider.loadActive(predicate).subscribe(items => {
      this.stocks = items;
      if (completion) {
        completion();
      }
    });
  }

  private loadAssignee() {
    this.createUserArrayObservable().subscribe((users: IdentityArray<UserData> | null) => {
      this.assignee = users ? users.map((user: UserData) => {
        const item = {id: user.id, itemName: user.person_name + '(' + user.user_name + ')'};
        return item;
      }) : [];
    });
  }

  private createUserArrayObservable(): Observable<IdentityArray<UserData> | null> {
    return this.userDataLoader.loadAllWithFields(UserDataLoaderPermissionDeniedStrategy.MISS_ALL,
      undefined, Set.of('id', 'person_name', 'user_name'));
  }

  private loadModel() {
    this.stockTakingsService.get({
      stockTakingId: this.stockTakingId
    }).subscribe((result: StockTakings.StockTaking) => {
      this.breadcrumbSelf = result.name;
      this.model.name = result.name;
      this.model.trxId = result.trxId;
      this.loadOwnerUser(result.ownerUserId);
      this.model.creationTime = result.creationTime;
      this.loadSelectedStocks(result.stockIds);
      this.model.pdfTemplate = result.pdfTemplate;
    });
  }

  private loadOwnerUser(id: number) {

    this.userService.query({
      fields: ['id', 'person_name'].join(','),
      id: '' + id
    }).subscribe((result: ResourceQueryResult<User>) => {
      this.model.ownerUserName = result.items[0].person_name;
    });
  }

  private loadSelectedStocks(ids: number[]) {
    this.model.stocks = [];
    this.initialStocks = [];
    this.stockMultiselectProvider.getByIds(ids).subscribe(items => {
      this.model.stocks.push(...items);
      this.initialStocks.push(...items);
    });
  }

  saveData(navigate: boolean) {
    this.formSubmitted = true;
    if (this.model.name.length === 0) {
      return;
    }
    const stockIds: number[] = [];
    this.model.stocks.forEach((s) => {
      stockIds.push(s.id);
    });
    this.stockTakingsService.update({
      stockTakingId: this.stockTakingId,
      name: this.model.name,
      stockIds: stockIds
    }).subscribe((result) => {
      if (navigate) {
        this.uiRouter.stateService.go(StateName.STOCK_TAKINGS_LIST);
      }
      else {
        this.loadModel();
        this.formSubmitted = false;
      }
    });
  }

  openUploadPdfDialog() {
    this.uploadDialog.showDialog();
  }

  deleteTemplate() {
    this.stockTakingsService.deleteTemplate({
      stockTakingId: this.stockTakingId
    }).subscribe((result) => {
      this.loadModel();
    });
  }

  downloadTemplate() {
    this.stockTakingsService.downloadTemplate(this.stockTakingId).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName('stock-taking-' + this.stockTakingId + '-pdf-template.fodt'));
      });
  }

  onUploadSuccess(succeeded: boolean) {
    if (succeeded) {
      this.loadModel();
    }
  }

  addStockTakingSheet() {
    if (!this.addStockTakingSheetForm.valid || !this.selectedStock[0] || this.selectedStock[0].id === null) {
      this.addStockTakingSheetForm.get('stock')!.markAsTouched();
      this.addStockTakingSheetForm.get('assignee')!.markAsTouched();
      return;
    }
    this.stockTakingSheetService.create({
      stockTakingId: this.stockTakingId,
      stockId: this.selectedStock[0].id,
      assigneeUserId: this.selectedAssignee[0].id!
    }).subscribe((response) => {
      if (this.stockTakingSheetListComponent) {
        this.stockTakingSheetListComponent.loadList();
      }
      this.addStockTakingSheetDialog.hide();
    }, (error: ObservableErrorResponse) => {
        const res = ObservableErrorResourceParser.parseError(error);
        this.addFieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
      });
  }

  onSelectedStockChanged() {
    this.selectableAssignee = [];
    this.selectedAssignee = [];
    if (this.selectedStock.length > 0) {
      this.assigneeSelectorVisible = true;
      if (this.selectedStock[0].type === 'COMPANY') {
        const companyId = this.selectedStock[0].companyId ? this.selectedStock[0].companyId + '' : undefined;
        this.userService.query({
          company_id: companyId,
        }).subscribe((result: ResourceQueryResult<User>) => {
          result.items.forEach((user: User) => {
            const item = {id: user.id, itemName: user.person_name + '(' + user.user_name + ')'};
            this.selectableAssignee.push(item);
          });
        });
      }
      if (this.selectedStock[0].type === 'PERSONAL') {
        this.selectedStock[0].ownerUserIds.forEach((assigneeId) => {
          const assignee = this.assignee.find((a) => a.id === assigneeId);
          if (assignee) {
            this.selectableAssignee.push(assignee);
          }
        });
      }
    }
    else {
      this.assigneeSelectorVisible = false;
    }
  }

  showAddStockTakingSheetDialog() {
    this.addStockTakingSheetDialogVisible = true;
    this.addStockTakingSheetDialog.show();
  }

  closeAddStockTakingSheetDialog() {
    this.addStockTakingSheetDialogVisible = false;
    this.addStockTakingSheetDialog.hide();
  }

  onModalHidden() {
    this.selectedStock = [];
    this.assigneeSelectorVisible = false;
    this.addStockTakingSheetForm.reset();
  }

  removeFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.addFieldErrors, fieldError);
  }

}

class StockTakingEditModel {
  name: string = '';
  trxId: string = '';
  ownerUserName: string = '';
  creationTime?: OffsetDateTime = undefined;
  stocks: StockItem[] = [];
  pdfTemplate?: PdfTemplate = undefined;
}

interface StockItem {
  id: number;
  itemName: string;
  ownerUserIds: number[];
  companyId?: number;
  type: StockTypeName;
}

export interface AddStockTakingSheetFieldErrorMap {
  name?: FieldError;
  stock?: FieldError;
}
