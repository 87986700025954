<div class="row">
  <div class="col-md-12 col-sm-12 col-xs-12 form-group half-padding">
    <label class="form-control-label">{{'ATTACHMENT_GALLERY_EDIT_NAME' | translate}}</label>
    <div class="d-flex align-items-center">
      <input class="form-control flex-grow-1" type="text" placeholder="{{'ATTACHMENT_GALLERY_EDIT_NAME' | translate}}"
             [(ngModel)]="currentFileName"
             [ngModelOptions]="{standalone: true}" name="currentFileName">
      <span class="ml-05">.{{fileExtension}}</span>
    </div>
  </div>
</div>
<div class="row d-flex justify-content-end"><div class="text-right">
  <div class="col-md-12 text-right d-flex">
    <input type="button" (click)="close()" class="btn btn-outline-primary cursor-pointer" value="{{'COMMON_BUTTON_CLOSE'|translate}}"/>
    <input type="button" (click)="save()" class="btn btn-primary ml-1 cursor-pointer" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
  </div>
</div>
