import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxDefaultOptions } from '@angular/material/checkbox';
import { MultiselectOptionItem, QueryFieldModel, UiConstants } from '../../../../../../util/core-utils';
import { CustomerRecord, CustomerRecordService } from '../../../../../../lib/customer/customer-record.service';
import { OrderType } from '../../../../../../lib/util/services';
import {CustomerRecordFormFieldModel, FormRecordCustomerFieldLoader} from '../form-record-customer-field.loader';
import { Set } from 'immutable';
import { Form } from '../../../../../../lib/form/form.service';
import { CustomerRecordMultiselectProvider } from '../../../../../../lib/customer/customer-record-multiselect-provider.service';
import { Angular2Multiselects } from '../../../../../../util/multiselect';
import { RightResolver, RightService } from '../../../../../../lib/right.service';
import { RightModel } from '../../../../../../app.rights';
import {
  CustomerRecordContactLocationMultiselectProvider
} from '../../../../../../lib/customer/contact/customer-record-contact-location-multiselect.provider';
import {Address} from "../../../../../../lib/address";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-customer-record-selector-dialog',
  templateUrl: './customer-record-selector-dialog.component.html',
  styleUrls: ['./customer-record-selector-dialog.component.scss'],
  providers: [
    {provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: {clickAction: 'noop'} as MatCheckboxDefaultOptions}
  ]
})
export class CustomerRecordSelectorDialogComponent implements OnInit {

  UiConstants = UiConstants;
  CustomerRecord = CustomerRecord;
  customerRecords: CustomerRecordSelectorModel[] = [];
  selectedCustomerRecords: CustomerRecordSelectorModel[] = [];
  queryModel: QueryFieldModel<CustomerRecord.OrderField> = new QueryFieldModel(CustomerRecord.OrderField.NAME, OrderType.ASC);
  loading: boolean = false;
  numberOfItemsOptions: number[] = [12, 24, 48];
  filterModel: CustomerRecordSelectorFilter = new CustomerRecordSelectorFilter();

  readonly pagingId: string = 'customerRecordSelectorDialogPagingId';

  rightModel: RightModel = RightModel.empty();
  showFilter: boolean = false;
  filterAppliedIndicatorVisible: boolean = false;
  filterCustomerRecords: MultiselectOptionItem<number>[] = [];
  contactLocations: MultiselectOptionItem<number>[] = [];
  customerDropdownSettings: Angular2Multiselects.Settings;

  constructor(
    public dialogRef: MatDialogRef<CustomerRecordSelectorDialogComponent, CustomerRecordSelectorDialogResult>,
    @Inject(MAT_DIALOG_DATA) public data: CustomerRecordSelectorDialogData,
    private customerRecordService: CustomerRecordService,
    private customerFieldLoader: FormRecordCustomerFieldLoader,
    private customerRecordMultiselectProvider: CustomerRecordMultiselectProvider,
    private contactLocationMultiselectProvider: CustomerRecordContactLocationMultiselectProvider,
    private translateService: TranslateService,
    private rightService: RightService
  ) {
    this.loadRightModels();
    this.loadData();
    this.initDropdown();
  }

  private initDropdown() {
    this.customerDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  ngOnInit() {
    this.onItemsPerPageChange(this.numberOfItemsOptions[0]);
  }

  load(pageNumber?: number) {
    this.loadCustomerRecords(pageNumber);
  }

  private loadData() {
    this.selectedCustomerRecords = [];
    this.data.alreadySelected.forEach(md => {
      this.selectedCustomerRecords.push({selected: true, model: md});
    })
    if (this.data.ownerCustomerRecordIds !== undefined) {
      this.filterAppliedIndicatorVisible = true;
      this.filterModel.customerRecord = this.data.ownerCustomerRecordIds.toArray().map(i => {
        return {id: i, itemName: 'N/A'};
      })
      this.customerRecordMultiselectProvider.getByIds(this.data.ownerCustomerRecordIds.toArray()).subscribe(c => {
        this.filterModel.customerRecord = c;
      })
    }
    if (this.data.ownerContactLocationIds !== undefined) {
      this.filterAppliedIndicatorVisible = true;
      this.filterModel.contactLocation = this.data.ownerContactLocationIds.toArray().map(i => {
        return {id: i, itemName: 'N/A'};
      })
      this.contactLocationMultiselectProvider.getByIds(this.data.ownerContactLocationIds.toArray()).subscribe(c => {
        this.filterModel.contactLocation = c;
      })
    }
  }

  private loadCustomerRecords(pageNumber?: number) {
    this.loading = true;
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    this.customerFieldLoader.getCustomerRecordObservable(this.data.customerId,
      false,
      true,
      undefined,
      this.filterModel.q,
      this.filterModel.customerRecordIds,
      this.filterModel.contactLocationIds,
      {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      },
      Set.of(this.queryModel.getOrder())).subscribe(result => {
      this.customerRecords = [];
      result.items.forEach(md => {
        if (md) {
          const selected = this.selectedCustomerRecords.find(item => item.model.customerRecordId === md.customerRecordId);
          this.customerRecords.push({selected: !!selected, model: md});
        }
      })
      this.queryModel.currentPage = requestedPage;
      this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
      this.loading = false;
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  saveSelection() {
    this.dialogRef.close(this.selectedCustomerRecords.length > 0 ? {
      selectedCustomerRecords: this.selectedCustomerRecords.map(md => md.model)
    } : undefined);
  }

  orderBy(field: CustomerRecord.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.load(1);
  }

  onPageChange(page: number) {
    this.load(page);
  }

  onItemsPerPageChange(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.load(1);
  }

  toggleSelected(record: CustomerRecordSelectorModel) {
    const selected = record.selected;
    if (this.data.multiSelect) {
      if (selected) {
        this.selectedCustomerRecords.splice(this.selectedCustomerRecords.findIndex(i => i.model.customerRecordId === record.model.customerRecordId), 1);
      }
      else {
        this.selectedCustomerRecords.push(record);
      }
    }
    else {
      if (!selected) {
        this.customerRecords.forEach(md => md.selected = false);
        this.selectedCustomerRecords = [];
        this.selectedCustomerRecords.push(record);
      }
    }
    record.selected = !selected;

  }

  public static openSelector(
    dialog: MatDialog,
    data: CustomerRecordSelectorDialogData,
    resultCallback: (result?: CustomerRecordSelectorDialogResult) => void) {
    const dialogRef = dialog.open(CustomerRecordSelectorDialogComponent, {
      panelClass: 'custom-dialog',
      closeOnNavigation: true,
      data: data
    });

    dialogRef.afterClosed().subscribe((result: CustomerRecordSelectorDialogResult) => {
      resultCallback(result);
    });
  }

  toggleFilter() {
    this.showFilter = !this.showFilter;
    this.filterAppliedIndicatorVisible = false;
  }

  searchCustomerRecord(q?: string) {
    this.customerRecordMultiselectProvider.loadActiveNonContact(q).subscribe(c => {
      this.filterCustomerRecords = c;
    });
  }

  onCustomerRecordChanged() {
    this.filterModel.contactLocation = [];
    if (this.filterModel.customerRecord.length === 1) {
      this.searchContactLocation();
    }
  }

  searchContactLocation(q?: string) {
    if (this.filterModel.customerRecord.length === 1) {
      this.contactLocationMultiselectProvider.loadForCustomer(this.filterModel.customerRecord[0].id, q).subscribe(c => {
        this.contactLocations = c;
      });
    }
  }

  getEmailAddressTypeName(email: Address.EmailAddressData) {
    const languageCode = this.translateService.currentLang.substr(0, 2);
    return email.typeNames![languageCode];
  }

}

export class CustomerRecordSelectorModel {
  selected: boolean = false;
  model: CustomerRecordFormFieldModel;
}

export interface CustomerRecordSelectorDialogData {
  multiSelect: boolean;
  customerId: number;
  ownerCustomerRecordIds?: Set<number>;
  ownerContactLocationIds?: Set<number>;
  displayedFormField?: Form.Field;
  alreadySelected: CustomerRecordFormFieldModel[];
}

export interface CustomerRecordSelectorDialogResult {
  selectedCustomerRecords?: CustomerRecordFormFieldModel[];
}

export class CustomerRecordSelectorFilter {
  q: string = '';
  customerRecord: MultiselectOptionItem<number>[] = [];
  contactLocation: MultiselectOptionItem<number>[] = [];

  get customerRecordIds(): Set<number> | undefined {
    if (this.customerRecord.length === 0) {
      return undefined;
    }
    return Set.of(...this.customerRecord.map(cr => cr.id));
  }

  get contactLocationIds(): Set<number> | undefined {
    if (this.contactLocation.length === 0 || this.customerRecord.length === 0) {
      return undefined;
    }
    return Set.of(...this.contactLocation.map(cr => cr.id));
  }
}
