<div class="modal-content">
  <div class="modal-header">
    <p class="modal-title"><strong>{{'SIGNATURE_TITLE' | translate}}</strong></p>
    <button type="button" class="close" (click)="closeDialog()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <div class="d-flex flex-row-reverse mb-05">
        <i class="icomoon icomoon-trash"
           title="{{'COMMON_BUTTON_DELETE' | translate}}" (click)="clearImage()"></i>
      </div>
      <div class="row d-flex justify-content-center align-middle">
        <app-signature-pad [options]="signaturePadOptions" style="background: lightgray"></app-signature-pad>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="closeDialog()">
      {{'COMMON_BUTTON_CANCEL' | translate}}
    </button>
    <button type="button" class="btn btn-primary" (click)="saveImage()">
      {{'COMMON_BUTTON_SAVE' | translate}}
    </button>
  </div>
</div>
