/* eslint-disable */
import { Pipe, PipeTransform } from '@angular/core';
import { Duration } from 'moment';
import { DurationHumanizerService } from '../duration-humanizer.service';
/* eslint-enable */

@Pipe({
  name: 'formattedDuration'
})
export class FormattedDurationPipe implements PipeTransform {


  constructor(private durationHumanizerService: DurationHumanizerService) {
  }

  transform(val?: Duration | undefined, placeholder?: string): string {
    if (!val) {
      return placeholder ? placeholder : '';
    }
    return this.durationHumanizerService.localizedHumanize(val.asMilliseconds());
  }

}
