/* eslint-disable */
import { Component, OnInit, ViewChild } from '@angular/core';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { TranslateService } from '@ngx-translate/core';
import { Order, OrderService } from '../../../lib/order/order.service';
import { Transition, UIRouter } from '@uirouter/angular';
import { StateName } from '../../../app.state-names';
import { User, UserService } from '../../../lib/user.service';
import { StockItem, StockItemService, UnitPrice } from '../../../lib/stock/stock-item.service';
import { Address } from '../../../lib/address';
import { ConfigModel } from '../../../util/task-record-utils';
import { ConfigurationService } from '../../../lib/core-ext/configuration.service';
import { Models } from '../../../util/model-utils';
import { OtherDataDropdownItem } from '../order-edit/order-edit.component';
import { TranslateUtils } from '../../../util/translate';
import { RightResolver, RightService } from '../../../lib/right.service';
import { RightModel } from '../../../app.rights';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UiConstants } from '../../../util/core-utils';
import { StringKey } from '../../../app.string-keys';
import { ToasterService } from '../../../fork/angular2-toaster/angular2-toaster';
import { OrderRightModel } from '../../../app.rights.order';
import { ResourceQueryResult } from '../../../lib/util/services';
/* eslint-enable */

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss']
})
export class OrderDetailComponent implements OnInit {
  UiConstants = UiConstants;
  Address = Address;
  Models = Models;

  orderId: number;
  model: Order.Order;
  ownerUserName: string;
  stockItems: StockItemForList[] = [];
  otherData: OtherDataDropdownItem[] = [];
  selectedOtherData: OtherDataDropdownItem[] = [];
  selectedOtherDataText: string = '';
  newReturnReason: string;

  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  config: ConfigModel = new ConfigModel();
  rightModel: OrderRightModel = OrderRightModel.empty();
  basicRightModel: RightModel = RightModel.empty();

  @ViewChild('orderReturnDialog') orderReturnDialog: ModalDirective;
  orderReturnDialogVisible: boolean = false;
  orderReturnForm: FormGroup;

  constructor(
    private translateService: TranslateService,
    private orderService: OrderService,
    private configService: ConfigurationService,
    private userService: UserService,
    private stockItemService: StockItemService,
    private transition: Transition,
    private uiRouter: UIRouter,
    private rightService: RightService,
    private formBuilder: FormBuilder,
    private toasterService: ToasterService
  ) {
    this.orderId = this.transition.params().id;
    this.orderReturnForm = formBuilder.group({
      returnReason: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.loadConfig();
    this.initBreadcrumb();
    this.breadcrumbSelf = this.orderId.toString();
    this.loadModel(() => {
        this.loadUserName();
        this.loadStockItems();
      });
  }

  private loadConfig() {
    this.config = this.configService.getConfigurationModel();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.userService.get({
          id: resolver.userProfile!.id
        }).subscribe((user) => {
          this.rightModel = OrderRightModel.of({
            rightModel: RightModel.of(resolver),
            currentUser: resolver.userProfile,
            currentCompanies: user.companies
          });
          this.basicRightModel = RightModel.of(resolver);
        });
      }
    );
  }

  private loadModel(completion?: () => void) {
    this.orderService.get({id: this.orderId})
      .subscribe(
        (order: Order.Order) => {
          this.model = order;
          this.loadOtherData(order.other);
          this.loadRightModels();
          if (completion) {
            completion();
          }
        }
      );
  }

  private loadUserName() {
    if (this.basicRightModel.userRead.hasRight()) {
      this.userService.get({
        id: this.model.ownerUserId
      }).subscribe((user: User) => {
        this.ownerUserName = user.person_name;
      });
    }
  }

  private loadStockItems() {
    if (this.model.items) {
      const stockItemIds: number[] = [];
      this.model.items.forEach((item) => {
        stockItemIds.push(item.stockItemId);
      });
      this.stockItems = [];
      this.stockItemService.query({id: stockItemIds.join(',')})
        .subscribe((result: ResourceQueryResult<StockItem>) => {
          result.items.forEach((item) => {
            this.model.items!.forEach((i) => {
              if (item.id === i.stockItemId) {
                this.stockItems.push({
                  itemId: i.itemId,
                  stockItemId: i.stockItemId,
                  name: item.name,
                  amount: i.amount,
                  unit: item.unit,
                  externalId: item.external_id,
                  serialCode: item.serial_code,
                  unitPrice: item.unit_price
                });
              }
            });
          });
      });
    }
  }

  private loadOtherData(otherData: Order.OtherData) {
    const keys: string[] = [];
    Order.otherDataFields.forEach((field) => {
      this.otherData.push({id: field.field, itemName: field.stringKey});
      keys.push(field.stringKey);
    });
    this.translateService.get(keys).subscribe((texts) => {
      this.otherData.forEach((field) => {
        field.itemName = TranslateUtils.extractValueFromObject(texts, field.itemName);
      });
      this.selectedOtherData = [];
      if (otherData.fragile) {
        this.selectedOtherData.push(this.otherData.find((field) => field.id === 'FRAGILE')!);
      }
      if (otherData.documentHandling) {
        this.selectedOtherData.push(this.otherData.find((field) => field.id === 'DOCUMENT_HANDLING')!);
      }
      if (otherData.packageExchange) {
        this.selectedOtherData.push(this.otherData.find((field) => field.id === 'PACKAGE_EXCHANGE')!);
      }
      if (otherData.handoverByItems) {
        this.selectedOtherData.push(this.otherData.find((field) => field.id === 'HANDOVER_BY_ITEMS')!);
      }
      this.selectedOtherDataText = this.selectedOtherData.map((data) => data.itemName).join(', ');
    });
  }

  initBreadcrumb() {
    if (this.uiRouter.stateService.current.name === StateName.ORDER_DETAIL_WITH_PROCESS_EDIT) {
      const processId = this.transition.params().processId;
      this.translateService.get('MENU_NAVBAR_LEGACY_PROCESSES').subscribe(
        (result: string) => {
          this.breadcrumbParents.push({name: result, uiSref: StateName.LEGACY_PROCESS_LIST});
          this.breadcrumbParents.push({name: processId, uiSref: StateName.LEGACY_PROCESS_EDIT, uiParam: {id: processId}});
        }
      );
      this.translateService.get('MENU_NAVBAR_ORDERS').subscribe(
        (result: string) => {
          this.breadcrumbParents.push({name: result, uiSref: StateName.LEGACY_PROCESS_EDIT, uiParam: {id: processId}});
        }
      );
    }
    else if (this.uiRouter.stateService.current.name === StateName.ORDER_DETAIL_WITH_PROCESS_DETAIL) {
      const processId = this.transition.params().processId;
      this.translateService.get('MENU_NAVBAR_LEGACY_PROCESSES').subscribe(
        (result: string) => {
          this.breadcrumbParents.push({name: result, uiSref: StateName.LEGACY_PROCESS_LIST});
          this.breadcrumbParents.push({name: processId, uiSref: StateName.LEGACY_PROCESS_DETAIL, uiParam: {id: processId}});
        }
      );
      this.translateService.get('MENU_NAVBAR_ORDERS').subscribe(
        (result: string) => {
          this.breadcrumbParents.push({name: result, uiSref: StateName.LEGACY_PROCESS_DETAIL, uiParam: {id: processId}});
        }
      );
    }
    else {
      this.translateService.get('MENU_NAVBAR_ORDERS').subscribe(
        (result: string) => {
          this.breadcrumbParents.push({name: result, uiSref: StateName.ORDER_LIST});
        }
      );
    }
  }

  returnOrder() {
    if (!this.orderReturnForm.valid) {
      this.orderReturnForm.get('returnReason')!.markAsTouched();
      return;
    }
    this.orderService.returnOrder({
      orderId: this.model.orderId,
      returnReason: this.newReturnReason
    }).subscribe(() => {
      this.closeOrderReturnDialog();
      this.loadModel();
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutShort,
        type: UiConstants.toastTypeSuccess,
        title: this.translateService.instant(StringKey.COMMON_SUCCESS)
      });
    })
  }

  showOrderReturnDialog() {
    this.newReturnReason = '';
    this.orderReturnDialogVisible = true;
    this.orderReturnDialog.show();
  }

  closeOrderReturnDialog() {
    this.orderReturnDialogVisible = false;
    this.orderReturnDialog.hide();
  }

  onModalHidden() {
    this.orderReturnDialogVisible = false;
    this.orderReturnForm.reset();
  }

}

interface StockItemForList {
  itemId: number;
  stockItemId: number;
  amount: number;
  unit: string;
  name: string;
  externalId: string;
  serialCode: string;
  unitPrice: UnitPrice;
}
