<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div class="breadcrumb-menu d-flex">
    <div *ngIf="rightModel.stockTakingCreate.hasRight()" class="breadcrumb-button-icon-container negative-margin-right cursor-pointer" (click)="openStockTakingCreateDialog()">
      <a class="btn-setting" title="{{'STOCK_TAKINGS_LIST_NEW_STOCK_TAKING' | translate}}">
        <i class="icomoon icomoon-add"></i>
        <span class="breadcrumb-button-text-visible">{{'STOCK_TAKINGS_LIST_NEW_STOCK_TAKING' | translate}}</span>
      </a>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>

<div class="container-horizontal-padding-half container-vertical-padding-half animated fadeIn">

  <div class="card">

    <div class="card-header">
      {{'MENU_NAVBAR_STOCK_TAKINGS' | translate}}

      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()" title="{{'COMMON_SHOW_SEARCH_HINT' | translate}}"><i class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>

    <div class="card-body border-bottom" *ngIf="showSearch">
      <div class="row form-group">
        <div class="col">
          <label class="search-label">{{'STOCK_TAKINGS_SEARCH_ID' | translate}}</label>
          <input type="text" class="form-control"
                 maxlength="{{UiConstants.maxInputLongLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [textMask]="{mask: InputMask.NATURAL_INTEGER, guide: false}"
                 placeholder="{{'STOCK_TAKINGS_SEARCH_ID' | translate}}"
                 [(ngModel)]="searchModel.stockTakingId">
        </div>
        <div class="col" *ngIf="rightModel.userRead.hasRight()">
          <label class="search-label">{{'STOCK_TAKINGS_SEARCH_OWNER_USERS' | translate}}</label>
          <angular2-multiselect [data]="users" [settings]="dropdownSettings"
                                (onRemoteSearch)="loadUsers(undefined, $event.target.value)"
                                class="form-control"
                                [(ngModel)]="searchModel.ownerUserId">
          </angular2-multiselect>
        </div>
        <div class="col">
          <label class="search-label">{{'COMMON_NAME' | translate}}</label>
          <input type="text" class="form-control"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 placeholder="{{'COMMON_NAME' | translate}}"
                 [(ngModel)]="searchModel.name">
        </div>
        <div class="col">
          <label class="search-label">{{'STOCK_TAKINGS_SEARCH_TRX_ID' | translate}}</label>
          <input type="text" class="form-control"
                 maxlength="{{UiConstants.maxInputLongLength}}"
                 (keyup.enter)="onSearchClicked()"
                 placeholder="{{'STOCK_TAKINGS_SEARCH_TRX_ID' | translate}}"
                 [(ngModel)]="searchModel.trxId">
        </div>
      </div>
      <div class="col-md-12 d-flex justify-content-md-end align-items-end px-0">
        <div class="btn-group" role="group">
          <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
            {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
          </button>
          <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
            {{'COMMON_BUTTON_SEARCH' | translate}}
          </button>
        </div>
      </div>
    </div> <!-- ./card-body end -->

    <div class="card-body">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header w-5"><app-table-sorter-no-op
            [text]="'COMMON_TABLE_HEADER_ID' | translate">
          </app-table-sorter-no-op></th>
          <th class="table-sorter-header"><app-table-field-sorter
            [orderField]="StockTakings.OrderField.NAME"
            [orderType]="queryModel.getOrderType(StockTakings.OrderField.NAME)"
            (orderChange)="orderBy($event)"
            [text]="'STOCK_TAKINGS_LIST_NAME' | translate">
          </app-table-field-sorter></th>
          <th class="table-sorter-header"><app-table-field-sorter
            [orderField]="StockTakings.OrderField.TRX_ID"
            [orderType]="queryModel.getOrderType(StockTakings.OrderField.TRX_ID)"
            (orderChange)="orderBy($event)"
            [text]="'STOCK_TAKINGS_LIST_TRX_ID' | translate">
          </app-table-field-sorter></th>
          <th class="table-sorter-header"><app-table-sorter-no-op
            [text]="'STOCK_TAKINGS_LIST_STOCKS' | translate">
          </app-table-sorter-no-op></th>
          <th class="table-sorter-header hidden-sm-down"><app-table-field-sorter
            [orderField]="StockTakings.OrderField.OWNER_USER_PERSON_NAME"
            [orderType]="queryModel.getOrderType(StockTakings.OrderField.OWNER_USER_PERSON_NAME)"
            (orderChange)="orderBy($event)"
            [text]="'STOCK_TAKINGS_LIST_OWNER_USER' | translate">
          </app-table-field-sorter></th>
          <th class="table-sorter-header hidden-md-down"><app-table-field-sorter
            [orderField]="StockTakings.OrderField.CREATION_TIME"
            [orderType]="queryModel.getOrderType(StockTakings.OrderField.CREATION_TIME)"
            (orderChange)="orderBy($event)"
            [text]="'COMMON_TABLE_HEADER_CREATION_TIME' | translate">
          </app-table-field-sorter></th>
          <th class="table-sorter-header w-10"><app-table-sorter-no-op
            [text]="'COMMON_TABLE_HEADER_ACTIONS' | translate">
          </app-table-sorter-no-op></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let stockTaking of stockTakingList | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }">
          <td class="responsive-table-column">{{stockTaking.stockTakingId}}</td>
          <td class="responsive-table-column">{{stockTaking.name}}</td>
          <td class="responsive-table-column">{{stockTaking.trxId}}</td>
          <td class="responsive-table-column">{{getStockNames(stockTaking.stockIds)}}</td>
          <td class="responsive-table-column hidden-sm-down">{{getUserName(stockTaking.ownerUserId)}}</td>
          <td class="responsive-table-column hidden-md-down">{{stockTaking.creationTime.toUtcIsoString() | date:'short'}}</td>
          <td class="responsive-table-column">
            <a class="icons-table-group cursor-pointer" uiSref="Admin.StockTakingsDetail" [uiParams]="{ id: stockTaking.stockTakingId }">
              <i class="icomoon icons-table-item icomoon-detail icomoon-detail-table" title="{{'COMMON_BUTTON_DETAILS' | translate}}"></i>
            </a>
            <a class="icons-table-group cursor-pointer" uiSref="Admin.StockTakingsEdit" [uiParams]="{ id: stockTaking.stockTakingId }">
              <i class="icomoon icons-table-item icomoon-modify icomoon-modify-table" title="{{'COMMON_BUTTON_EDIT' | translate}}"></i>
            </a>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header w-5"><app-table-sorter-no-op
            [text]="'COMMON_TABLE_HEADER_ID' | translate">
          </app-table-sorter-no-op></th>
          <th class="table-sorter-header"><app-table-field-sorter
            [orderField]="StockTakings.OrderField.NAME"
            [orderType]="queryModel.getOrderType(StockTakings.OrderField.NAME)"
            (orderChange)="orderBy($event)"
            [text]="'STOCK_TAKINGS_LIST_NAME' | translate">
          </app-table-field-sorter></th>
          <th class="table-sorter-header"><app-table-field-sorter
            [orderField]="StockTakings.OrderField.TRX_ID"
            [orderType]="queryModel.getOrderType(StockTakings.OrderField.TRX_ID)"
            (orderChange)="orderBy($event)"
            [text]="'STOCK_TAKINGS_LIST_TRX_ID' | translate">
          </app-table-field-sorter></th>
          <th class="table-sorter-header"><app-table-sorter-no-op
            [text]="'STOCK_TAKINGS_LIST_STOCKS' | translate">
          </app-table-sorter-no-op></th>
          <th class="table-sorter-header hidden-sm-down"><app-table-field-sorter
            [orderField]="StockTakings.OrderField.OWNER_USER_PERSON_NAME"
            [orderType]="queryModel.getOrderType(StockTakings.OrderField.OWNER_USER_PERSON_NAME)"
            (orderChange)="orderBy($event)"
            [text]="'STOCK_TAKINGS_LIST_OWNER_USER' | translate">
          </app-table-field-sorter></th>
          <th class="table-sorter-header hidden-md-down"><app-table-field-sorter
            [orderField]="StockTakings.OrderField.CREATION_TIME"
            [orderType]="queryModel.getOrderType(StockTakings.OrderField.CREATION_TIME)"
            (orderChange)="orderBy($event)"
            [text]="'COMMON_TABLE_HEADER_CREATION_TIME' | translate">
          </app-table-field-sorter></th>
          <th class="table-sorter-header w-10"><app-table-sorter-no-op
            [text]="'COMMON_TABLE_HEADER_ACTIONS' | translate">
          </app-table-sorter-no-op></th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>
  </div>
</div>

<div bsModal [config]="UiConstants.modalConfig" #stockTakingCreateDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="stockTakingCreateDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'STOCK_TAKING_CREATE_DIALOG_TITLE' | translate}}</h4>
        <button type="button" class="close" (click)="closeStockTakingCreateDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group col-md-12"
             [ngClass]="{ 'has-danger': newStockTakingSubmitted && newStockTaking.name.length === 0 || createStockTakingFieldErrors.name }">
          <label class="col-form-label">{{'COMMON_NAME' | translate}}</label>
          <input class="form-control" type="text"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 placeholder="{{'COMMON_NAME' | translate}}"
                 [(ngModel)]="newStockTaking.name"
                 (ngModelChange)="removeFieldError(createStockTakingFieldErrors.name)"
                 [ngClass]="{ 'form-control-danger': newStockTakingSubmitted && newStockTaking.name.length === 0 || createStockTakingFieldErrors.name }">
          <div class="form-control-feedback" *ngIf="newStockTakingSubmitted && newStockTaking.name.length === 0">
            {{'STOCK_TAKING_CREATE_NAME_REQUIRED' | translate}}
          </div>
          <div class="form-control-feedback" *ngIf="createStockTakingFieldErrors.name">
            {{createStockTakingFieldErrors.name.text}}
          </div>
        </div>
        <div class="form-group col-md-12">
          <label class="col-form-label">{{'STOCK_TAKING_CREATE_DIALOG_STOCKS' | translate}}</label>
          <angular2-multiselect [data]="stocks" [settings]="dropdownSettings"
                                (onRemoteSearch)="loadStocks($event.target.value)"
                                class="form-control"
                                [(ngModel)]="newStockTaking.stocks">
          </angular2-multiselect>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeStockTakingCreateDialog()">
          {{'COMMON_BUTTON_CANCEL' | translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="createStockTaking()">{{'COMMON_BUTTON_SAVE' |
          translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
