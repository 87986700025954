import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ForwardingNgFormRef, LocalFormGroupValidationErrors } from '../../../../lib/util/services';
import { MultiselectOptionItem, SelectUtils, UiConstants } from '../../../../util/core-utils';
import { Invoice, InvoiceService } from '../../../../lib/invoice/invoice/invoice.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { InvoiceSettings } from '../../../../lib/invoice/invoice-settings/invoice-settings.service';
import { NgbDatePickerParserFormatter } from '../../../../util/ngb-datepicker';
import { EmptyMessage } from '../../../../lib/util/messages';
import { InvoiceSpending } from '../../../../lib/invoice/spending/invoice-spending.service';
import { Set } from 'immutable';
import { InputMask } from '../../../../util/input-masks';
import { Angular2Multiselects } from '../../../../util/multiselect';
import { InvoiceTagMultiselectProvider } from '../../../../lib/invoice/tag/invoice-tag-multiselect-provider.service';

@Component({
  selector: 'app-invoice-tagger-dialog',
  templateUrl: './invoice-tagger-dialog.component.html',
  styleUrls: ['./invoice-tagger-dialog.component.scss']
})
export class InvoiceTaggerDialogComponent implements OnInit {

  Invoice = Invoice;
  UiConstants = UiConstants;
  InvoiceSettings = InvoiceSettings;
  SelectUtils = SelectUtils;
  InputMask = InputMask;

  model: InvoiceTaggingModel = new InvoiceTaggingModel();

  @ViewChild('f', {static: true})
  form: NgForm;
  formGroup: FormGroup;
  private formGroupValidationErrors: LocalFormGroupValidationErrors;
  searchableDropdownSettings: Angular2Multiselects.Settings;
  invoiceTags: MultiselectOptionItem<number>[] = [];

  constructor(public dialogRef: MatDialogRef<InvoiceTaggerDialogComponent>,
              private translateService: TranslateService,
              private datePickerParserFormatter: NgbDatePickerParserFormatter,
              private fb: FormBuilder,
              private invoiceService: InvoiceService,
              private invoiceTagMultiselectProvider: InvoiceTagMultiselectProvider,
              @Inject(MAT_DIALOG_DATA) public data: InvoiceTaggerDialogData) {
    this.loadModel();
    this.formGroup = this.createFormGroup(this.fb);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(
      this.createForwardingHtmlForm(),
      this.formGroup
    );
  }

  ngOnInit() {
    this.initDropDown();
  }

  initDropDown() {
    this.searchableDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
  }

  closeDialog(modified: boolean) {
    this.dialogRef.close({modified: modified});
  }

  save() {
    if (this.hasLocalFieldError()) {
      return;
    }
    else {
      this.createSpending();
    }
  }

  hasFieldError(field: InvoiceSpending.ValidatedField): boolean {
    return false;
  }

  removeFieldError(field: InvoiceSpending.ValidatedField) {
  }

  getFieldErrorText(field: InvoiceSpending.ValidatedField): string {
    return '';
  }

  showIconDialog() {

  }

  private createForwardingHtmlForm() {
    return new ForwardingNgFormRef({
      formFn: () => {
        return this.form;
      }
    });
  }

  private createFormGroup(fb: FormBuilder): FormGroup {
    return fb.group(
      {
        invoiceTag: fb.control(
          {value: this.model._invoiceTag, disabled: this.data.mode === 'CLEAR'},
          [Validators.required]
        )
      }
    );
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  createSpending() {
    if (this.formGroup.invalid) {
      return;
    }
    if (this.data.mode === 'SET') {
      this.invoiceService.setTag({
        invoiceIds: this.data.originals.map(i => i?.id!).toSet(),
        invoiceTagId: this.model.invoiceTagId!
      }).subscribe(
        (result: EmptyMessage) => {
          this.closeDialog(true);
        },
        error => {

        });
    }
    else {
      this.invoiceService.clearTag({
        invoiceIds: this.data.originals.map(i => i?.id!).toSet()
      }).subscribe(
        (result: EmptyMessage) => {
          this.closeDialog(true);
        },
        error => {

        });
    }
  }

  onInvoiceTagSearch(q?: string) {
    this.invoiceTagMultiselectProvider.loadActive(q).subscribe(tags => {
      this.invoiceTags = tags;
    });
  }

  private loadModel() {
  }

}

class InvoiceTaggingModel {
  _invoiceTag: MultiselectOptionItem<number>[] = [];

  get invoiceTagId(): number | undefined {
    return this._invoiceTag.length === 0 ? undefined : this._invoiceTag[0].id;
  }
}

export interface InvoiceTaggerDialogData {
  originals: Set<Invoice.Invoice>;
  mode: 'SET' | 'CLEAR';
}

export interface InvoiceTaggerDialogResult {
  modified: boolean;
}
