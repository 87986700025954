import { Injectable } from '@angular/core';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { EmptyMessage } from '../util/messages';
import { AddressResource } from '../address';


@Injectable()
export class PlaceResourceService extends BaseHttpService {

  update(id: number, request: AddressResource.PlaceCreateResourceRequest): Observable<EmptyMessage> {
    return this.http.put<EmptyMessage>(this.url + `${id}`, request);
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/places/');
  }

}
