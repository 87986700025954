<div class="modal-content">
  <div class="modal-header">
    <p class="modal-title"><strong>{{getDialogTitle() | translate}}</strong></p>
    <button type="button" class="close" (click)="closeDialog(false)" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <mat-dialog-content style="overflow:visible">
      <form (ngSubmit)="save()" #f="ngForm" id="form"
            [formGroup]="formGroup" novalidate>

        <div class="row form-group"
             [ngClass]="{ 'has-danger': hasLocalFieldError('ledgerNumber') || hasFieldError(ValidatedField.LEDGER_NUMBER)}">
          <label
            class="col-form-label form-control-label col-md-4 detail-title"
            [class.required-field-label]="true">{{'LEDGER_NUMBER_LEDGER_NUMBER' | translate}}</label>
          <div class="col-md-8">
            <div class="mw-100">
              <input type="text"
                     [(ngModel)]="model.ledgerNumber"
                     [ngClass]="{ 'form-control-danger': hasLocalFieldError('ledgerNumber') || hasFieldError(ValidatedField.LEDGER_NUMBER) }"
                     trim="blur"
                     validateOnBlur
                     class="form-control"
                     name="ledgerNumber"
                     id="ledgerNumber"
                     formControlName="ledgerNumber"
                     (ngModelChange)="removeFieldError(ValidatedField.LEDGER_NUMBER)"
                     placeholder="{{'LEDGER_NUMBER_LEDGER_NUMBER'|translate}}">
              <div class="form-control-feedback" *ngIf="hasLocalFieldError('ledgerNumber')">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
              <div class="form-control-feedback" *ngIf="hasFieldError(ValidatedField.LEDGER_NUMBER)">
                {{getFieldErrorText(ValidatedField.LEDGER_NUMBER)}}
              </div>
            </div>
          </div>
        </div>
        <div class="row form-group"
             [ngClass]="{ 'has-danger': hasFieldError(ValidatedField.NAME)}">
          <label
            class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_NAME' | translate}}</label>
          <div class="col-md-8">
            <div class="mw-100">
              <input type="text"
                     [(ngModel)]="model.name"
                     [ngClass]="{ 'form-control-danger': hasFieldError(ValidatedField.NAME) }"
                     trim="blur"
                     validateOnBlur
                     class="form-control"
                     name="name"
                     id="name"
                     formControlName="name"
                     (ngModelChange)="removeFieldError(ValidatedField.NAME)"
                     placeholder="{{'COMMON_NAME'|translate}}">
              <div class="form-control-feedback" *ngIf="hasFieldError(ValidatedField.NAME)">
                {{getFieldErrorText(ValidatedField.LEDGER_NUMBER)}}
              </div>
            </div>
          </div>
        </div>
      </form>

      <div *ngIf="ledgerNumber">
        <div class="row form-group">
          <label
            class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_TABLE_HEADER_CREATION_TIME' | translate}}</label>
          <div class="col-md-8">
            <div class="mw-100">
              <label class="form-control detail-input">{{ledgerNumber.creationTime.toUtcIsoString() | date:'short'}}</label>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <label
            class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_TABLE_HEADER_UPDATE_TIME' | translate}}</label>
          <div class="col-md-8">
            <div class="mw-100">
              <label class="form-control detail-input">{{ledgerNumber.updateTime.toUtcIsoString() | date:'short'}}</label>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="closeDialog(false)">
      {{'COMMON_BUTTON_CANCEL' | translate}}
    </button>
    <button type="submit" class="btn btn-primary" form="form">
      {{'COMMON_BUTTON_SAVE' | translate}}
    </button>
  </div>
</div>
