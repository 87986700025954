/* eslint-disable */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StateName } from '../../../app.state-names';
import { CustomerRecord, CustomerRecordService } from '../../../lib/customer/customer-record.service';
import { UIRouter } from '@uirouter/angular';
import { NgForm } from '@angular/forms';
/* eslint-enable */

@Component({
  selector: 'app-customer-record-breadcrumb-buttons',
  templateUrl: './customer-record-breadcrumb-buttons.component.html',
  styleUrls: ['./customer-record-breadcrumb-buttons.component.scss']
})
export class CustomerRecordBreadcrumbButtonsComponent implements OnInit {

  @Input()
  form?: NgForm;

  @Input()
  readonly: boolean;

  @Input()
  allowEdit: boolean;

  @Input()
  allowTaskRecordQuickCreate: boolean;

  @Input()
  customerId: number;

  @Input()
  customerRecordId: number;

  @Output()
  onTaskRecordCreateClicked: EventEmitter<any> = new EventEmitter<any>();

  customerRecord?: CustomerRecord.CustomerRecord;

  constructor(
    private uiRouter: UIRouter,
    private customerRecordService: CustomerRecordService
  ) { }

  ngOnInit() {
    this.loadCustomerRecord();
  }

  loadCustomerRecord() {
    this.customerRecordService.get({
      customerId: this.customerId,
      customerRecordId: this.customerRecordId
    }).subscribe(
      (result: CustomerRecord.CustomerRecord) => {
        this.customerRecord = result;
      })
  }

  editCustomer() {
    if (this.customerRecord && this.allowEdit) {
      this.uiRouter.stateService.go(StateName.CUSTOMER_RECORD_EDIT, {
        customerId: this.customerRecord.customerId,
        customerRecordId: this.customerRecord.customerRecordId
      });
    }
  }

  createTaskRecord() {
    this.onTaskRecordCreateClicked.emit();
  }

}
