/* eslint-disable */
import { GooglePhoneNumber, PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { Dates } from './dates';
import { TimeZones } from './time-zones';

/* eslint-enable */

export interface PhoneNumber {
  format(): string;

  toIso(): string | null;

  isValid(): boolean;
}

export class PhoneNumbers {

  private static readonly PLUS_SIGN: string = '+';
  private static readonly DOUBLE_ZERO: string = '00';

  public static parse(text: string | null | undefined, countryCode?: string): PhoneNumber {
    if (text === null || text === undefined) {
      return new InvalidPhoneNumber(text);
    }
    if (countryCode === undefined) {
      countryCode = TimeZones.timeZoneToCountryCode(Dates.guessLocalTimeZoneName());
    }
    try {
      const phoneNumberUtil = PhoneNumberUtil.getInstance();
      const parsedNumber = phoneNumberUtil.parse(this.replaceDoubleZero(text), countryCode);
      return new GoogleParsedPhoneNumber(phoneNumberUtil, parsedNumber);
    } catch (e) {
      return new InvalidPhoneNumber(text);
    }
  }

  private static replaceDoubleZero(text: string): string {
    // In some country people use '00' instead of '+'. It is acceptable.
    if (text.startsWith(PhoneNumbers.DOUBLE_ZERO)) {
      return PhoneNumbers.PLUS_SIGN + text.substr(PhoneNumbers.DOUBLE_ZERO.length, text.length);
    }
    return text;
  }

  private constructor() {
  }

}

class GoogleParsedPhoneNumber implements PhoneNumber {

  private readonly parsedNumber: GooglePhoneNumber;
  private readonly util: PhoneNumberUtil;

  constructor(util: PhoneNumberUtil, parsedNumber: GooglePhoneNumber) {
    this.util = util;
    this.parsedNumber = parsedNumber;
  }

  format(): string {
    const text = this.util.format(this.parsedNumber, PhoneNumberFormat.INTERNATIONAL);
    return text;
  }

  toIso(): string | null {
    const text = this.util.format(this.parsedNumber, PhoneNumberFormat.E164);
    return text;
  }

  isValid(): boolean {
    const valid = this.util.isValidNumber(this.parsedNumber);
    return valid;
  }

}

class InvalidPhoneNumber implements PhoneNumber {

  constructor(private rawText?: string | null) {
  }

  format(): string {
    return this.rawText ? this.rawText : '';
  }

  toIso(): string | null {
    return null;
  }

  isValid(): boolean {
    return false;
  }

}
