<div class="card">
  <div class="card-header">
    {{'SHIPMENT_DETAILS_OUTTAKE'|translate}}
    <div class="card-actions">
      <a *ngIf="canForceReopen()"
         class="btn-setting table-header-action-button d-flex align-items-center px-1 w-auto font-weight-normal cursor-pointer"
         (click)="openForceReopenDialog.emit()"
         title="{{'SHIPMENT_OUTTAKE_FORCE_REOPEN' | translate}}">
        <i class="icomoon pr-1 icomoon-order-state-sent-back"></i>{{'SHIPMENT_OUTTAKE_FORCE_REOPEN' | translate}}
      </a>
      <a *ngIf="canChangeStorekeeper()"
         class="btn-setting table-header-action-button d-flex align-items-center px-1 w-auto font-weight-normal cursor-pointer"
         (click)="openStorekeeperChangeDialog.emit()"
         title="{{'SHIPMENT_OUTTAKE_CHANGE_ASSIGNEE' | translate}}">
        <i class="icomoon pr-1 icomoon-change-assignee"></i>{{'SHIPMENT_OUTTAKE_CHANGE_ASSIGNEE' | translate}}
      </a>
    </div><!-- /.card-actions -->
  </div>

  <div class="card-body mb-0">
    <div class="form-horizontal">

      <div class="row" *ngIf="outtakeModel.state">
        <div class="form-group col-md-4">
          <label readonly class="detail-title">{{'COMMON_STATE' | translate}}</label>
        </div>
        <div class="form-group ml-1">
          <app-table-badge
            [badgeStyle]="outtakeModel.state.badgeStyle"
            [iconClass]="outtakeModel.state.iconClass"
            [textKey]="outtakeModel.state.stringKey">
          </app-table-badge>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-4">
          <label readonly class="detail-title">{{'SHIPMENT_DETAILS_OUTTAKE_STOREKEEPER'|translate}}</label>
        </div>
        <div class="form-group col-md-8">
          <input type="text" class="form-control col-xl-8"
                 [value]="outtakeModel.storekeeper.name"
                 disabled>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-4">
          <label readonly class="detail-title">{{'SHIPMENT_DETAILS_OUTTAKE_PROGRESS_COUNT'|translate}}</label>
        </div>
        <div class="form-group col-md-8">
          <label class="detail-description">
            <span class="completed-count">{{outtakeModel.progressCount.completedCount}}</span>/{{outtakeModel.progressCount.totalCount}}</label>
        </div>
      </div>

      <div class="row" *ngIf="outtakeModel.outtakeStartTime">
        <div class="form-group col-md-4">
          <label readonly class="detail-title">{{'SHIPMENT_DETAILS_OUTTAKE_START'|translate}}</label>
        </div>
        <div class="form-group col-md-8">
          <input type="text" class="form-control col-xl-8"
                 [value]="outtakeModel.outtakeStartTime.toUtcIsoString() | date:'short'"
                 disabled>
        </div>
      </div>

    </div>
  </div>
</div>
