/* eslint-disable */
import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiUserKey, ApiUserKeyService } from '../../../lib/api-user-key.service';
import { AuthService } from '../../../lib/auth.service';
import { RightModel } from '../../../app.rights';
import { RightResolver, RightService } from '../../../lib/right.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { UiConstants } from '../../../util/core-utils';
/* eslint-enable */

@Component({
  selector: 'app-api-user-key',
  templateUrl: './api-user-key.component.html',
  styleUrls: ['./api-user-key.component.scss']
})
export class ApiUserKeyComponent implements OnInit {
  UiConstants = UiConstants;

  @ViewChild('tokenDialog', { static: true }) tokenDialog: ModalDirective;
  tokenDialogVisible: boolean = false;
  tokenText: string = '';

  model: Model | null = null;
  rightModel: RightModel = RightModel.empty();
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  constructor(private authService: AuthService,
              private rightService: RightService,
              private translateService: TranslateService,
              private apiUserKeyService: ApiUserKeyService) {
  }

  ngOnInit() {
    this.translateService.get('API_KEY_HEADER').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.loadRightModels();
    this.loadModel();
  }


  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }


  getToken(): string {
    if (!this.model) {
      return '';
    }
    return this.model.token;
  }

  inState(state: ModelState): boolean {
    if (!this.model) {
      return false;
    }
    return state === this.model.state;
  }

  canActivate(): boolean {
    return (this.inState('NONE') || this.inState('INACTIVE')
      && this.rightModel.apiUserKeyActivate.hasRight());
  }

  canInactivate(): boolean {
    return this.inState('ACTIVE') && this.rightModel.apiUserKeyInactivate.hasRight();
  }

  canDisable(): boolean {
    return (this.inState('ACTIVE')) && this.rightModel.apiUserKeyRegenerate.hasRight();
  }

  activate() {
    this.apiUserKeyService.activate({}).subscribe((result: ApiUserKey) => {
      if (result.signature) {
        this.openTokenDialog(result.signature);
      }
      this.updateModel(result);
    });
  }

  inactivate() {
    this.apiUserKeyService.inactivate({}).subscribe((result: ApiUserKey) => {
      this.updateModel(result);
    });
  }

  regenerate() {
    this.apiUserKeyService.regenerate({}).subscribe((result: ApiUserKey) => {
      if (result.signature) {
        this.openTokenDialog(result.signature);
      }
      this.updateModel(result);
    });
  }


  private loadModel() {
    this.apiUserKeyService.query({}).subscribe((apiUserKey: ApiUserKey) => {
      this.updateModel(apiUserKey);
    });
  }

  private updateModel(apiUserKey: ApiUserKey) {
    const m = new Model();
    let visibleToken = true;
    if (this.model) {
      visibleToken = this.model.visibleToken;
    }
    m.token = apiUserKey.token ? apiUserKey.token : '';
    m.state = apiUserKey.disabled === false ? 'ACTIVE' : (apiUserKey.disabled === true ? 'INACTIVE' : 'NONE');
    m.visibleToken = visibleToken;
    this.model = m;
  }

  openTokenDialog(token: string) {
    this.tokenText = token;
    this.tokenDialogVisible = true;
    this.tokenDialog.show();
  }

  closeTokenDialog() {
    this.tokenDialogVisible = false;
    this.tokenDialog.hide();
  }

  copyMessage() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.tokenText;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

}

export class Model {
  token: string = '';
  state: ModelState;
  visibleToken: boolean = false;
}

export type ModelState = 'ACTIVE' | 'INACTIVE' | 'NONE';
