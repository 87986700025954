  <div class="card">
    <div class="card-header">{{'COMMON_MESSAGES'|translate}}
      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="loadMessages()" title="{{'CHAT_REFRESH_MESSAGES' | translate}}">
          <i class="icomoon icomoon-process-reopen"></i>
        </a>
      </div><!-- /.card-actions -->
    </div>
    <!-- /.card-header -->
    <div class="card-body">
      <div *ngIf="messageList.length === 0">
        <div class="p-2 text-center text-muted">
          <h6>{{ "CHAT_NO_MESSAGE" | translate }}</h6>
        </div>
      </div>
      <div class="process-message-container d-flex flex-column" *ngFor="let message of messageList; let last = last"
           [ngClass]="{ 'message-bottom-margin': !last }">
          <label class="message-sender mb-0">{{ message.chatMessage.userPersonName }}</label>
          <label class="message-date">{{ message.chatMessage.creationTime.toUtcIsoString() | date:'short' }}</label>
        <div class="mw-100 text-wrap break-word">
          {{ message.chatMessage.content }}
        </div>
        <a class="mb-0 d-inline-block text-wrap id-text cursor-pointer"
           href="#" onclick="return false"
           uiSref="Admin.ProcessDetail"
           [uiParams]="{ id: message.process.id}"
           title="{{'PROCESS' | translate}}"
           *ngIf="message.process">{{ message.process.name }}</a>
      </div>
      <div *ngIf="messageList.length > 0 && messageList.length < totalNumberOfItems">
        <div class="pt-2 text-center">
          <button type="button" class="btn btn-secondary" (click)="loadMessages(true)">{{'CHAT_MESSAGE_LOAD_PREVIOUS' | translate}}
          </button>
        </div>
      </div>
    </div>
    <!-- /.card-body -->
  </div>
  <!-- /.card -->

