<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div class="breadcrumb-menu d-flex">
  </div>
</div>
<div class="breadcrumb-placeholder"></div>

<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="card">

    <div class="card-header">
      {{'MENU_NAVBAR_DOCUMENT_LIBRARY' | translate}}

      <div class="card-actions">
      </div><!-- /.card-actions -->
    </div>
    <div class="card-body border-bottom">
      <div class="filter-parent-categories d-flex align-items-center">
        <button type="button" [disabled]="!canGoBackGroups()" (click)="homeGroups()" class="btn nav-button">
          <i class="icomoon icomoon-postal-address-simple"
          ></i>
        </button>
          <span>/</span>
        <ng-container *ngFor="let group of selectedGroups; let last = last">
              <span [class.filter-parent-category-clickable]="!last"
                    class="ml-05"
                    (click)="!last && gotoGroup(group)">
                {{group.getData().name}}
              </span>
          <span *ngIf="!last">/</span>
        </ng-container>
      </div>

      <div>
        <div *ngIf="currentDocumentGroupLevel | async as groups">
          <app-document-library-group-list
          [documentGroups]="groups"
          (groupClicked)="gotoGroup($event)">
          </app-document-library-group-list>
        </div>
      </div>
    </div><!-- /.card-body-->

    <div class="card-body">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="DocumentFile.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(DocumentFile.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="DocumentFile.OrderField.NAME"
                                    [orderType]="queryModel.getOrderType(DocumentFile.OrderField.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-field-sorter [orderField]="DocumentFile.OrderField.CREATION_TIME"
                                    [orderType]="queryModel.getOrderType(DocumentFile.OrderField.CREATION_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'DOCUMENT_TABLE_HEADER_CREATION_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let documentModel of documentListModel" [disabledTableRow]="documentModel.disabled">

          <td class="responsive-table-column fit align-middle"> {{ documentModel.id }}</td>
          <td class="responsive-table-column long align-middle">
            <div class="d-flex align-items-center">
              <img class="file-thumbnail" [style]="{'aspect-ratio': documentAspectRatio}" *ngIf="documentModel.hasThumbnail && documentModel.thumbnailFile.downloaded" src="{{documentModel.thumbnailFile.base64dataUrl}}" alt="{{documentModel.name}}">
              <img class="file-thumbnail"  [style]="{'aspect-ratio': documentAspectRatio}" *ngIf="!documentModel.hasThumbnail || !documentModel.thumbnailFile.downloaded" src="{{documentModel.file_type.iconImageSrc}}" alt="{{documentModel.name}}">
              <div class="d-flex flex-column justify-content-center">
                <a *ngIf="rightModel.documentFileFileUpdate.hasRight()"
                   class="id-text cursor-pointer"
                   href="#" onclick="return false"
                   uiSref="Admin.DocumentFileEdit"
                   [uiParams]="{ id: documentModel.id }"
                   title="{{'COMMON_BUTTON_EDIT' | translate}}">
                  {{documentModel.name}}
                </a>
                <span *ngIf="!rightModel.documentFileFileUpdate.hasRight()">{{documentModel.name}}</span>
                <span class="text-truncate table-text-subtitle">
                  {{ documentModel.content_size.value | formattedNumber}} {{documentModel.content_size.unitDictKey | translate }}
                </span>
              </div>
            </div>
          </td>
          <td class="responsive-table-column hidden-sm-down align-middle"> {{ documentModel.creation_time | date: 'short' }}</td>
          <td class="align-middle">
            <app-table-options-menu>
              <app-dropdown-item
                *ngIf="rightModel.documentFileFileDownload.hasRight()"
                [iconClass]="'icomoon-download'"
                [titleStringKey]="'COMMON_BUTTON_DOWNLOAD'"
                class="cursor-pointer"
                (click)="downloadFile(documentModel)">
              </app-dropdown-item>
              <app-dropdown-item
                [iconClass]="'icomoon-detail'"
                [titleStringKey]="'COMMON_BUTTON_DETAILS'"
                class="cursor-pointer"
                uiSref="Admin.DocumentFileRead"
                [uiParams]="{ id: documentModel.id }">
              </app-dropdown-item>
            </app-table-options-menu>
          </td>
        </tr>
        </tbody>

        <tfoot>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="DocumentFile.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(DocumentFile.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="DocumentFile.OrderField.NAME"
                                    [orderType]="queryModel.getOrderType(DocumentFile.OrderField.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-field-sorter [orderField]="DocumentFile.OrderField.CREATION_TIME"
                                    [orderType]="queryModel.getOrderType(DocumentFile.OrderField.CREATION_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'DOCUMENT_TABLE_HEADER_CREATION_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>

      </table>
    </div>
  </div>
</div>
