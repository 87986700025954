import { AddressModel } from '../../../lib/address';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Models } from '../../../util/model-utils';
import { FieldError } from '../../../lib/util/errors';
import { ShippingDemand } from '../../../lib/shipping-demand/shipping-demand.service';
import { MultiselectOptionItem } from '../../../util/core-utils';
import PackagingAttributeType = ShippingDemand.PackagingAttributeType;
import { CompanyLocationService } from '../../../lib/company-location/company-location.service';
import ItemSize = ShippingDemand.ItemSize;
import { ShippingDemandAddressModel } from './shipping-demand-address/shipping-demand-address.model';
import { AppNgbTimeStruct } from '../../../util/ngb-datepicker';
import ShippingDemandState = ShippingDemand.ShippingDemandState;

export class ShippingDemandEditModel {
  id: number;
  state: ShippingDemandState;
  externalId: string = '';
  demandCategory: string = '';
  weightInGram: string;
  insurancePriceAmount: string = '';
  insurancePriceCurrencyCode: string = '';
  cashOnDeliveryPriceAmount: string = '';
  cashOnDeliveryPriceCurrencyCode: string = '';
  comment: string = '';

  recipientName: string = '';
  recipientLicenseCategory: string = '';
  recipientLicenseNumber: string = '';
  recipientEmailAddress?: string = '';
  recipientPhoneNumber?: string = '';
  recipientComment: string = '';

  transferorName: string = '';
  transferorLicenseCategory: string = '';
  transferorLicenseNumber: string = '';
  transferorEmailAddress?: string = '';
  transferorPhoneNumber?: string = '';
  transferorComment: string = '';

  sourceModel: ShippingDemandAddressModel;
  destinationModel: ShippingDemandAddressModel;

  safetyShipping: boolean = false;
  ekaerRequired: boolean = false;
  ekaerId: string = '';
  tourRoute: string = '';
  deadlineDate: NgbDateStruct | null = null;
  deadlineTime: AppNgbTimeStruct = Models.zeroNgbTime();
  transporterCompany: MultiselectOptionItem<number>[] = [];
  demanderCompany: MultiselectOptionItem<number>[] = [];
  sourceSystemName: string = '';
  packagingAttributes: MultiselectOptionItem<PackagingAttributeType>[] = [];
  carriageByDemanderCompany: boolean = false;

  postalAddress: AddressModel.PostalAddressModel;

  demandGroupSerial: string;
  demandSerial: string;

  constructor(private companyLocationService: CompanyLocationService) {
    this.sourceModel = new ShippingDemandAddressModel('SOURCE', companyLocationService);
    this.destinationModel = new ShippingDemandAddressModel('DESTINATION', companyLocationService);
  }

}

export class ShippingDemandDetailModel {
  id: number;
  state: ShippingDemandState;
  externalId: string = '';
  demandCategory: string = '';
  cashOnDeliveryPrice: string = '';
  insurancePrice: string = '';
  weightInGram: string;
  comment: string = '';

  recipientName: string = '';
  recipientLicenseCategory: string = '';
  recipientLicenseNumber: string = '';
  recipientEmailAddress?: string = '';
  recipientPhoneNumber?: string = '';
  recipientComment: string = '';

  transferorName: string = '';
  transferorLicenseCategory: string = '';
  transferorLicenseNumber: string = '';
  transferorEmailAddress?: string = '';
  transferorPhoneNumber?: string = '';
  transferorComment: string = '';

  safetyShipping: string = '';
  ekaerRequired: string = '';
  ekaerId: string = '';
  tourRoute: string = '';
  deadline: string = '';
  transporterCompany: string = '';
  demanderCompany: string = '';
  sourceSystemName: string = '';

  destinationModel: ShippingDemandAddressModel;
  selectedDestinationCountry: string = '';
  selectedDestinationAddress: string = '';
  selectedDestinationStockName: string = '';

  sourceModel: ShippingDemandAddressModel;
  selectedSourceCountry: string = '';
  selectedSourceAddress: string = '';
  selectedSourceStockName: string = '';
  packagingAttributes: string = '';
  carriageByDemanderCompany: string = '';
  carriageByDemanderCompanyValue: boolean = false;

  postalAddress: AddressModel.PostalAddressModel;

  demandGroupSerial: string;
  demandSerial: string;

  constructor(private companyLocationService: CompanyLocationService) {
    this.destinationModel = new ShippingDemandAddressModel('DESTINATION', companyLocationService);
    this.sourceModel = new ShippingDemandAddressModel('SOURCE', companyLocationService);
  }
}

export class CompanyModel {
  id: string = '';
  name: string = '';

  get itemName() {
    return this.name;
  }
}

export interface ShippingDemandFieldErrorMap {
  external_id?: FieldError;
  tax_number?: FieldError;
}

export class ShippingItemModel {
  id?: number;
  externalId: string = '';
  name: string = '';
  category: string = '';
  productCode: string = '';
  amount: Amount = new Amount();
  comment: string = '';
  boxCode: string = '';
  labelCode: string = '';
  weightInGram: number | undefined;
  itemSize: ItemSize | undefined;
  price: PriceModel = new PriceModel();
  editing: boolean = false;
}

export class ShippingItemEditModel {
  id?: number;
  externalId: string = '';
  name: string = '';
  category: string = '';
  productCode: string = '';
  amount: Amount = new Amount();
  comment: string = '';
  boxCode: string = '';
  labelCode: string = '';
  weight: string = '';
  itemSize: ShippingItemSizeEdit = new ShippingItemSizeEdit();
  price: PriceModel = new PriceModel();
  editing: boolean = false;
}

export class PriceModel {
  amount: string = '';
  currencyCode: string = 'HUF';
}

export class ShippingItemSizeEdit {
  width: LengthModel = new LengthModel();
  height: LengthModel = new LengthModel();
  depth: LengthModel = new LengthModel();
}

export class LengthModel {
  amount: string = '';
  unit: string = 'm';
}

export class Amount {
  value: string = '';
  unit: string = 'db';
}
