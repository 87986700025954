/* eslint-disable */
import { Observable, Observer } from 'rxjs';
import { Injectable } from '@angular/core';
import { Services } from '../util/services';
import { OffsetDateTime } from '../util/dates';
import { DqlQuery, Query } from '../query/field';
import { FilterField } from '../query/filterfields';
import { OrderField } from '../query/orderfields';
import { MplTrackingResource, MplTrackingResourceService } from './mpl-tracking-resource.service';
import { OrderFunctionResolver } from '../../util/core-utils';
import Decimal from 'decimal.js';
/* eslint-enable */

export namespace MplTracking {

  export interface Service {
    query(request: QueryRequest): Observable<MplTracking[]>;
  }

  export interface MplTracking {
    time: OffsetDateTime;
    trackingNumber: string;
    shipment?: Shipment;
    order?: Order;
    description?: string;
    category?: string;
    categoryCode?: string;
    typeCode?: string;
    postOfficeName?: string;
    backgroundSystemName?: string;
    coreServiceName?: string;
    deliveryMode?: string;
    cashOnDeliveryMode?: string;
    retentionPeriodDays?: number;
    sizeCodeDescription?: string;
    cashOnDeliveryPrice?: Decimal;
    insurancePrice?: Decimal;
    massGram?: Decimal;
  }

  export interface QueryRequest {
    filter?: Query.FilterFunction<FilterField.MplTracking>;
    order?: Query.OrderFunction<OrderField.MplTracking>;
  }

  export interface Shipment {
    id: number;
    deliveryNoteNumber: string;
  }

  export interface Order {
    id: number;
  }

  export function toPublic(r: MplTrackingResource.MplTracking): MplTracking {
    return {
      time: Services.toOffsetDateTime(r.time),
      trackingNumber: r.tracking_number,
      shipment: r.shipment ? {
        id: r.shipment.id,
        deliveryNoteNumber: r.shipment.delivery_note_number
      } : undefined,
      order: r.order ? {
        id: r.order.id
      } : undefined,
      description: r.description,
      category: r.category,
      categoryCode: r.category_code,
      typeCode: r.type_code,
      postOfficeName: r.post_office_name,
      backgroundSystemName: r.background_system_name,
      coreServiceName: r.core_service_name,
      deliveryMode: r.delivery_mode,
      cashOnDeliveryMode: r.cash_on_delivery_mode,
      retentionPeriodDays: r.retention_period_days,
      sizeCodeDescription: r.size_code_description,
      cashOnDeliveryPrice: Services.toDecimal(r.cash_on_delivery_price),
      insurancePrice: Services.toDecimal(r.insurance_price),
      massGram: Services.toDecimal(r.mass_gram)
    }
  }

  export namespace OrderFunctions {
    export const TIME = (f: OrderField.MplTracking) => f.time;

    export const VALUES = OrderFunctionResolver.builder<OrderField.MplTracking>()
      .add(TIME, 'time')
      .build();
  }

}

@Injectable()
export class MplTrackingService implements MplTracking.Service {

  private readonly filterField = new FilterField.MplTracking();
  private readonly orderField = new OrderField.MplTracking();

  constructor(private resourceService: MplTrackingResourceService) {
  }

  query(request: MplTracking.QueryRequest): Observable<MplTracking.MplTracking[]> {
    const filter: string | undefined = DqlQuery.toOptionalFilter(this.filterField, request.filter);
    const order: string | undefined = DqlQuery.toOptionalOrder(this.orderField, request.order);
    return Observable.create((observer: Observer<MplTracking.MplTracking[]>) => {
      const resourceRequest: MplTrackingResource.QueryRequest = {
        filter: filter,
        order: order
      };
      return this.resourceService.query(resourceRequest).subscribe(
        (result: MplTrackingResource.MplTracking[]) => {
          observer.next(result.map(t => MplTracking.toPublic(t)));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

}
