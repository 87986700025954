<div class="modal-content">
  <div class="modal-header">
    <p class="modal-title"><strong>{{getTitleKey() | translate}}</strong></p>
    <button type="button" class="close" (click)="closeDialog(false)" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form [formGroup]='formGroup' (ngSubmit)="createTaskRecord()" #f="ngForm">
    <div class="modal-body">
      <mat-dialog-content [ngStyle]="{'overflow': createModel.selectedTask ? 'auto' : 'visible'}">
        <div>
          <div class="form-group row"
               [ngClass]="{ 'has-danger': hasLocalFieldError('task')}">
            <div class="col-md-4">
              <label class="col-form-label mw-100 form-control-label required-field-label">
                {{'COMMON_TASK'|translate}}
              </label>
            </div>
            <div class="col-md-8">
              <angular2-multiselect [data]="tasksForCreate" [settings]="offlineDropdownSettings"
                                    class="form-control"
                                    [(ngModel)]="createModel.task"
                                    formControlName="task"
                                    [readonly]="data.taskId"
                                    (ngModelChange)="onTaskChanged()">
              </angular2-multiselect>
              <div class="form-control-feedback"
                   *ngIf="hasLocalFieldError('task')">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
          </div>

          <ng-container *ngIf="createModel.task.length > 0">

            <div class="form-group row"
                 *ngIf="mode !== TaskRecordCreateDialogMode.QUICK_CREATE_BY_CUSTOMER_RECORDS"
                 [ngClass]="{ 'has-danger': hasLocalFieldError('name')}">
              <div class="col-md-4">
                <label class="col-form-label mw-100 form-control-label" [ngClass]="{'required-field-label': nameRequired}">
                  {{'TASK_RECORD_CREATE_NAME'|translate}}
                  <i class="icomoon icomoon-info info-icon" popover="{{'TASK_RECORD_NAME_OPTIONAL_PROCESS_HINT' | translate}}"
                     *ngIf="!nameRequiredByProcess"
                     triggers="mouseenter:mouseleave" placement="right">
                  </i>
                  <i class="icomoon icomoon-info info-icon" popover="{{'TASK_RECORD_NAME_OPTIONAL_TASK_HINT' | translate}}"
                     *ngIf="!nameRequiredByTask"
                     triggers="mouseenter:mouseleave" placement="right">
                  </i>
                </label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <input type="text" class="form-control" placeholder="{{'TASK_RECORD_CREATE_NAME' | translate}}"
                         maxlength="{{UiConstants.maximumVarcharLength}}"
                         validateOnBlur [validateFormControl]="formGroup.controls['name']"
                         [matAutocomplete]="nameAutoGroup"
                         [(ngModel)]="createModel.name"
                         [ngClass]="{ 'form-control-danger': hasLocalFieldError('name') }"
                         formControlName="name"
                         (click)="onAutoCompleteClick('name')">
                  <mat-autocomplete #nameAutoGroup="matAutocomplete">
                    <mat-option *ngFor="let nameOption of nameOptions$ | async" [value]="nameOption">
                      {{nameOption}}
                    </mat-option>
                  </mat-autocomplete>
                  <div class="form-control-feedback"
                       *ngIf="hasLocalFieldError('name', 'required')">
                    {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row"
                 *ngIf="mode === TaskRecordCreateDialogMode.QUICK_CREATE_BY_CUSTOMER_RECORDS"
                 [ngClass]="{ 'has-danger': hasLocalFieldError('nameTemplate')}">
              <div class="col-md-4">
                <label class="col-form-label mw-100 form-control-label" [ngClass]="{'required-field-label': true}">
                  {{'TASK_RECORD_CREATE_NAME_TEMPLATE'|translate}}
                </label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <app-material-tag-input
                    formControlName="nameTemplate"
                    [(ngModel)]="createModel.nameTemplate"
                    [placeholderStringKey]="'TASK_RECORD_CREATE_NAME_TEMPLATE'"
                    [autocompleteItems]="TaskRecordQuickCreateNameTemplate.availableTemplateBlocks"
                    [separatorCharacter]="TaskRecordQuickCreateNameTemplate.templateBlockSeparator"
                    [generatedStringExampleMap]="TaskRecordQuickCreateNameTemplate.templateBlockExampleMap"
                    [separatorKeyCodes]="TaskRecordQuickCreateNameTemplate.separatorKeysCodes"
                    [shouldGenerateExample]="true"
                    [form]="form">
                  </app-material-tag-input>
                  <div class="form-control-feedback"
                       *ngIf="hasLocalFieldError('nameTemplate', 'required')">
                    {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row"
                 *ngIf="mode !== TaskRecordCreateDialogMode.QUICK_CREATE_BY_CUSTOMER_RECORDS && isManagedField('EXTERNAL_ID')"
                 [ngClass]="{ 'has-danger': taskRecordCreateFieldErrors.external_id}">
              <div class="col-md-4">
                <label class="col-form-label mw-100 form-control-label">{{'TASK_RECORD_EXTERNAL_ID'|translate}}</label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <input type="text" class="form-control" placeholder="{{'TASK_RECORD_EXTERNAL_ID' | translate}}"
                         maxlength="{{UiConstants.maximumVarcharLength}}"
                         [(ngModel)]="createModel.externalId"
                         (ngModelChange)="removeFieldError(taskRecordCreateFieldErrors.external_id)"
                         [ngModelOptions]="{standalone: true}">
                  <div class="form-control-feedback" *ngIf="taskRecordCreateFieldErrors.external_id">
                    {{taskRecordCreateFieldErrors.external_id.text}}
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row" *ngIf="isManagedField('DESCRIPTION')"
                 [ngClass]="{ 'has-danger': hasLocalFieldError('description')}">
              <div class="col-md-4">
                <label
                  class="col-form-label mw-100 form-control-label"
                  [class.required-field-label]="isRequiredField('DESCRIPTION')">
                  {{'TASK_RECORD_FIELD_DESCRIPTION'|translate}}
                </label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <input type="text" class="form-control" placeholder="{{'TASK_RECORD_FIELD_DESCRIPTION' | translate}}"
                         maxlength="{{UiConstants.maximumVarcharLength}}"
                         [(ngModel)]="createModel.description"
                         formControlName="description">
                </div>
                <div class="form-control-feedback"
                     *ngIf="hasLocalFieldError('description', 'required')">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
              </div>
            </div>

            <div class="form-group row" *ngIf="isManagedField('IMPORTANCE')"
                 [ngClass]="{ 'has-danger': hasLocalFieldError('importance')}">
              <div class="col-md-4">
                <label class="col-form-label mw-100 form-control-label"
                       [class.required-field-label]="isRequiredField('IMPORTANCE')">
                  {{'TASK_IMPORTANCE_TITLE'|translate}}
                </label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <select class="form-control"
                          [(ngModel)]="createModel.importance"
                          formControlName="importance">
                    <option *ngFor="let importance of taskImportances"
                            [ngValue]="importance">{{importance.text}}</option>
                  </select>
                </div>
                <div class="form-control-feedback"
                     *ngIf="hasLocalFieldError('importance', 'required')">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-4">
                <label class="col-form-label mw-100 form-control-label">{{'COMMON_STATE'|translate}}</label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <select class="form-control"
                          [(ngModel)]="createModel.state"
                          (ngModelChange)="onStateChanged()"
                          [ngModelOptions]="{standalone: true}">
                    <option *ngFor="let state of availableStates"
                            [ngValue]="state.state">{{state.stringKey | translate}}</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="form-group row" *ngIf="isManagedField('ASSIGNEE_USER_GROUP') && rightModel.userGroupRead.hasRight()"
                 [ngClass]="{ 'has-danger': hasLocalFieldError('userGroup')}">
              <div class="col-md-4">
                <label class="col-form-label mw-100 form-control-label"
                       [class.required-field-label]="isUserGroupRequired()">
                  {{'TASK_DATA_MODIFICATION_USER_GROUP'|translate}}
                </label>
              </div>
              <div class="col-md-8">
                <angular2-multiselect [data]="userGroups" [settings]="assigneeDropdownSettings"
                                      class="form-control"
                                      [(ngModel)]="createModel.userGroup"
                                      (onRemoteSearch)="filterUserGroups($event.target.value)"
                                      (ngModelChange)="onUserGroupChanged()"
                                      formControlName="userGroup">
                </angular2-multiselect>
                <div class="form-control-feedback"
                     *ngIf="hasLocalFieldError('userGroup', 'required')">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
              </div>
            </div>

            <div class="form-group row" *ngIf="config.assigneeWithUser && isManagedField('ASSIGNEE_USER_APPLICATION') && rightModel.userRead.hasRight()"
                 [ngClass]="{ 'has-danger': hasLocalFieldError('user')}">
              <div class="col-md-4">
                <label class="col-form-label mw-100 form-control-label"
                       [class.required-field-label]="isUserRequired()">
                  {{'TASK_LABEL_OWNER_USER'|translate}}
                </label>
              </div>
              <div class="col-md-8">
                <angular2-multiselect [data]="users" [settings]="assigneeDropdownSettings"
                                      class="form-control"
                                      [(ngModel)]="createModel.assignee._user"
                                      (onRemoteSearch)="filterUsers($event.target.value)"
                                      (ngModelChange)="onAssigneeChanged()"
                                      formControlName="user">
                </angular2-multiselect>
                <div class="form-control-feedback"
                     *ngIf="hasLocalFieldError('user', 'required')">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
              </div>
            </div>

            <div class="form-group row"
                 *ngIf="config.assigneeWithMobileApp && isManagedField('ASSIGNEE_USER_APPLICATION') && rightModel.mobileAppRead.hasRight()"
                 [ngClass]="{ 'has-danger': hasLocalFieldError('device')}">
              <div class="col-md-4">
                <label class="col-form-label mw-100 form-control-label"
                       [class.required-field-label]="isDeviceRequired()">
                  {{'TASK_DATA_MODIFICATION_DEVICE'|translate}}
                </label>
              </div>
              <div class="col-md-8">
                <angular2-multiselect [data]="devices" [settings]="assigneeDropdownSettings"
                                      class="form-control"
                                      [(ngModel)]="createModel.assignee._device"
                                      (ngModelChange)="onAssigneeChanged()"
                                      formControlName="device">
                </angular2-multiselect>
                <div class="form-control-feedback"
                     *ngIf="hasLocalFieldError('device', 'required')">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
              </div>
            </div>

            <div class="form-group row"
                 style="align-items: center"
                 *ngIf="mode !== TaskRecordCreateDialogMode.QUICK_CREATE_BY_CUSTOMER_RECORDS && isManagedField('CUSTOMER') && rightModel.customerRecordRead.hasRight()"
                 [ngClass]="{ 'has-danger': hasLocalFieldError('customer')}">
              <div class="col-md-4">
                <label class="col-form-label mw-100 form-control-label"
                       [class.required-field-label]="isRequiredField('CUSTOMER')">
                  {{'TASK_LABEL_CUSTOMER'|translate}}
                </label>
              </div>
              <div class="col-md-6">
                <angular2-multiselect [data]="customers" [settings]="customerDropdownSettings"
                                      class="form-control"
                                      (onSelect)="onCustomerChanged()"
                                      (onDeSelect)="onCustomerChanged()"
                                      (onRemoteSearch)="getCustomers([], $event)"
                                      [(ngModel)]="createModel.customerM"
                                      formControlName="customer">
                </angular2-multiselect>
                <div class="form-control-feedback"
                     *ngIf="hasLocalFieldError('customer', 'required')">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
              </div>
              <div class="col-md-2">
                <button type="button" class="btn btn-secondary ml-1"
                        *ngIf="rightModel.customerRecordCreate.hasRight()"
                        (click)="createCustomerRecord()">
                  {{'CUSTOMER_RECORD_CREATE_BREADCRUMB' | translate}}
                </button>

              </div>
            </div>

            <div class="form-group row"
                 *ngIf="mode !== TaskRecordCreateDialogMode.QUICK_CREATE_BY_CUSTOMER_RECORDS && task && (task.autoInvoiceGenerate || task.manualInvoiceEnabled) &&rightModel.billingInfoRead.hasRight()"
                 [ngClass]="{ 'has-danger': hasLocalFieldError('billingInfo')}">
              <div class="col-md-4">
                <label class="col-form-label mw-100 form-control-label"
                       [class.required-field-label]="task && task.autoInvoiceGenerate">
                  {{'CUSTOMER_RECORD_BILLING_INFO'|translate}}
                </label>
              </div>
              <div class="col-md-8">
                <angular2-multiselect [data]="billingInfos" [settings]="billingInfoDropdownSettings"
                                      class="form-control"
                                      [(ngModel)]="createModel.billingInfo"
                                      formControlName="billingInfo">
                </angular2-multiselect>
                <div class="form-control-feedback"
                     *ngIf="hasLocalFieldError('billingInfo', 'required')">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
              </div>
            </div>

            <div class="form-group row" *ngIf="isManagedField('PROJECT') && rightModel.projectRecordRead.hasRight()"
                 [ngClass]="{ 'has-danger': hasLocalFieldError('project')}">
              <div class="col-md-4">
                <label class="col-form-label mw-100 form-control-label"
                       [class.required-field-label]="isRequiredField('PROJECT')">
                  {{'TASK_LABEL_PROJECT'|translate}}
                </label>
              </div>
              <div class="col-md-8">
                <angular2-multiselect [data]="projects" [settings]="customerDropdownSettings"
                                      class="form-control"
                                      (onRemoteSearch)="loadProjects($event.target.value)"
                                      [(ngModel)]="createModel.project"
                                      formControlName="project">
                </angular2-multiselect>
                <div class="form-control-feedback"
                     *ngIf="hasLocalFieldError('project', 'required')">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
              </div>
            </div>

            <div class="form-group row" *ngIf="isManagedField('DEADLINE')"
                 [ngClass]="{ 'has-danger': hasLocalFieldError('deadlineDatePicker') || hasLocalFieldError('deadlineTimePicker')}">
              <div class="col-md-4">
                <label class="col-form-label mw-100 form-control-label"
                       [class.required-field-label]="isRequiredField('DEADLINE')">
                  {{'TASK_LABEL_DEADLINE'|translate}}
                </label>
              </div>
              <div class="col-md-8">
                <div class="clearfix row">
                  <div class="col-sm">
                    <div>
                      <div class="row">
                        <div class="input-group col-md-6 mb-1 mb-md-0">
                          <input placeholder="{{'DATE_TIME_PICKER_PLACEHOLDER_DATE' | translate}}" type="text"
                                 class="form-control"
                                 [(ngModel)]="createModel.deadlineTimeDate"
                                 formControlName="deadlineDatePicker"
                                 ngbDatepicker #deadline_date_picker="ngbDatepicker"
                                 validateLocalDate>
                          <span class="add-on input-group-addon-gray" (click)="deadline_date_picker.toggle()">
                              <i class="icomoon icomoon-calendar"></i>
                            </span>
                        </div>
                        <div class="input-group col-md-6">
                          <input placeholder="{{'DATE_TIME_PICKER_PLACEHOLDER_TIME' | translate}}" type="text"
                                 class="form-control hidden-value-when-disabled"
                                 [(ngModel)]="createModel.deadlineTimeTime"
                                 formControlName="deadlineTimePicker"
                                 [seconds]="false"
                                 [allowEmptyValue]="false"
                                 [disabled]="createModel.deadlineTimeDate === null || createModel.deadlineTimeDate === undefined || hasLocalFieldError('deadlineDatePicker')"
                                 ngbTimepicker #deadline_time_picker="ngbTimepicker"
                                 [toggleButtonId]="'deadline_time_toggle_button'"
                                 [spinners]="true" [hourStep]="1" [minuteStep]="10">
                          <span class="add-on input-group-addon-gray"
                                id="deadline_time_toggle_button"
                                (click)="deadline_time_picker.toggle()">
                              <i class="icomoon icomoon-time"></i>
                            </span>
                        </div>
                      </div>
                      <div class="form-control-feedback"
                           *ngIf="(hasLocalFieldError('deadlineDatePicker', 'required')) || (hasLocalFieldError('deadlineTimePicker', 'required'))">
                        {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                      </div>
                      <div class="form-control-feedback"
                           *ngIf="hasLocalFieldError('deadlineDatePicker', 'validateLocalDate')">
                        {{'COMMON_VALIDATION_MESSAGE_INVALID_LOCAL_DATE' | translate}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row" *ngIf="isManagedField('AGREED_TIME')"
                 [ngClass]="{ 'has-danger': hasLocalFieldError('agreedDatePicker') || hasLocalFieldError('agreedTimePicker')}">
              <div class="col-md-4">
                <label class="col-form-label mw-100 form-control-label"
                       [class.required-field-label]="isRequiredField('AGREED_TIME')">
                  {{'TASK_LABEL_AGREED_TIME'|translate}}
                </label>
              </div>
              <div class="col-md-8">
                <div class="clearfix row">
                  <div class="col-sm">
                    <div>
                      <div class="row">
                        <div class="input-group col-md-6 mb-1 mb-md-0">
                          <input placeholder="{{'DATE_TIME_PICKER_PLACEHOLDER_DATE' | translate}}" type="text"
                                 class="form-control"
                                 [(ngModel)]="createModel.agreedTimeDate"
                                 formControlName="agreedDatePicker"
                                 ngbDatepicker #agreed_date_picker="ngbDatepicker"
                                 validateLocalDate>
                          <span class="add-on input-group-addon-gray" (click)="agreed_date_picker.toggle()">
                              <i class="icomoon icomoon-calendar"></i>
                            </span>
                        </div>
                        <div class="input-group col-md-6">
                          <input placeholder="{{'DATE_TIME_PICKER_PLACEHOLDER_TIME' | translate}}" type="text"
                                 class="form-control hidden-value-when-disabled"
                                 [(ngModel)]="createModel.agreedTimeTime"
                                 formControlName="agreedTimePicker"
                                 [seconds]="false"
                                 [allowEmptyValue]="false"
                                 [disabled]="createModel.agreedTimeDate === null || createModel.agreedTimeDate === undefined || hasLocalFieldError('agreedDatePicker')"
                                 ngbTimepicker #agreed_time_picker="ngbTimepicker"
                                 [toggleButtonId]="'agreed_time_toggle_button'"
                                 [spinners]="true" [hourStep]="1" [minuteStep]="10">
                          <span class="add-on input-group-addon-gray"
                                id="agreed_time_toggle_button"
                                (click)="agreed_time_picker.toggle()">
                              <i class="icomoon icomoon-time"></i>
                            </span>
                        </div>
                      </div>
                      <div class="form-control-feedback"
                           *ngIf="(hasLocalFieldError('agreedDatePicker', 'required')) || (hasLocalFieldError('agreedTimePicker', 'required'))">
                        {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                      </div>
                      <div class="form-control-feedback"
                           *ngIf="hasLocalFieldError('agreedDatePicker', 'validateLocalDate')">
                        {{'COMMON_VALIDATION_MESSAGE_INVALID_LOCAL_DATE' | translate}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="isManagedField('ESTIMATED_TIME')" class="form-group row"
                 [ngClass]="{ 'has-danger': hasLocalFieldError('estimatedTimeHours') || hasLocalFieldError('estimatedTimeMinutes')}">
              <div class="col-md-4">
                <label class="col-form-label mw-100 form-control-label"
                       [class.required-field-label]="isRequiredField('ESTIMATED_TIME')">
                  {{'TASK_ESTIMATED_TIME'|translate}}
                </label>
              </div>
              <div class="col-md-8">
                <!--on create/edit screens-->
                <div class="row">
                  <div class="col-md-6 d-flex w-100 align-items-center">
                    <input type="number" class="form-control mr-1 text-right"
                           min="0"
                           [(ngModel)]="createModel.estimatedTime.hours"
                           formControlName="estimatedTimeHours">
                    <span>{{'TASK_ESTIMATED_TIME_HOURS' | translate}}</span>
                  </div>
                  <div class="col-md-6 d-flex w-100 align-items-center">
                    <input type="number" class="form-control mr-1 text-right"
                           min="0"
                           max="59"
                           step="5"
                           [(ngModel)]="createModel.estimatedTime.minutes"
                           formControlName="estimatedTimeMinutes">
                    <span>{{'TASK_ESTIMATED_TIME_MINUTES' | translate}}</span>
                  </div>
                </div>
                <div class="form-control-feedback"
                     *ngIf="(hasLocalFieldError('estimatedTimeHours', 'required')) || (hasLocalFieldError('estimatedTimeMinutes', 'required'))">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
                <div class="form-control-feedback"
                     *ngIf="hasLocalFieldError('estimatedTimeHours', 'min')">
                  {{'CALENDAR_TASK_RECORD_CREATE_ESTIMATED_TIME_HOURS_MIN_ERROR' | translate}}
                </div>
                <div class="form-control-feedback"
                     *ngIf="hasLocalFieldError('estimatedTimeMinutes', 'min')">
                  {{'CALENDAR_TASK_RECORD_CREATE_ESTIMATED_TIME_MINUTES_MIN_ERROR' | translate}}
                </div>
                <div class="form-control-feedback"
                     *ngIf="hasLocalFieldError('estimatedTimeMinutes', 'max')">
                  {{'CALENDAR_TASK_RECORD_CREATE_ESTIMATED_TIME_MINUTES_MAX_ERROR' | translate}}
                </div>
              </div>
            </div>

            <div>
              <!-- region POC address -->
              <div class="form-group row" *ngIf="mode !== TaskRecordCreateDialogMode.QUICK_CREATE_BY_CUSTOMER_RECORDS && rightModel.customerRecordRead.hasRight()">
                <label class="col-form-label form-control-label col-md-4 detail-title"
                       [class.required-field-label]="createModel.placeOfConsumptionRequirement !== POCRequirement.NOT_REQUIRED">
                  {{'TASK_ADDRESS' | translate}}
                </label>
                <div class="col-md-8">
                  <angular2-multiselect class="form-control"
                                        id="task-record-base-poc-type-input"
                                        [data]="selectableAddresses"
                                        [settings]="pocDropdownSettings"
                                        [(ngModel)]="_selectedAddress"
                                        [ngModelOptions]="{standalone: true}"
                                        (onSelect)="onSelectedAddressChanged()"
                                        (onDeSelect)="onSelectedAddressChanged()">
                  </angular2-multiselect>
                </div>
              </div>
              <div *ngIf="showPOCAddress()" class="poc-address-display">
                <app-postal-address-field
                  id="task-record-base-poc-address-input"
                  [label]="'TASK_SELECTED_POC'"
                  [model]="createModel.placeOfConsumption.address"
                  [autoAdd]="true"
                  [innerField]="true"
                  [readonly]="(selectedAddress && selectedAddress.id !== null)"
                  [requiredField]="false"
                  [fieldRatio]="8"
                  [form]="form">
                </app-postal-address-field>
              </div>
              <!-- endregion POC address -->
            </div>

          </ng-container>
          <div class="alert alert-warning mb-0"
               *ngIf="mode === TaskRecordCreateDialogMode.QUICK_CREATE_BY_CUSTOMER_RECORDS">
            <p class="mb-0">
            <span class="font-weight-bold">
              {{'CUSTOMER_RECORD_BATCH_TASK_RECORD_CREATE_WARNING' | translate: {number: createModel.customerRecordIds.length} }}
            </span>
            </p>
          </div>

        </div>
        <!-- /.modal-body -->
      </mat-dialog-content>
    </div>
    <div class="modal-footer justify-content-end">
        <span>
          <button type="button" class="btn btn-outline-primary" (click)="closeDialog(false)">
            {{'COMMON_BUTTON_CANCEL' | translate}}
          </button>
          <button type="submit" class="btn btn-primary ml-1"
                  [ladda]="creationInProgress"
                  [disabled]="creationInProgress">
            {{getSubmitButtonKey() | translate}}
          </button>
        </span>
    </div>
  </form>
</div><!-- /.modal-content -->
