import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { BreadcrumbParent } from '../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { CalendarUtils } from '../../util/calendar/calendar-utils';
import { Subject } from 'rxjs';
import { ConfigModel } from '../../util/task-record-utils';
import { MultiselectOptionItem, OrderFieldModel, UiConstants } from '../../util/core-utils';
import { RightModel } from '../../app.rights';
import { Angular2Multiselects } from '../../util/multiselect';
import { SettingsService } from '../../lib/settings.service';
import { ConfigurationService } from '../../lib/core-ext/configuration.service';
import { UserMultiselectProvider } from '../../lib/user/user-multiselect.provider';
import { RightResolver, RightService } from '../../lib/right.service';
import { UIRouter } from '@uirouter/angular';
import { NgbDatePickerParserFormatter } from '../../util/ngb-datepicker';
import { MatDialog } from '@angular/material/dialog';
import { OrderType, QueryResult } from '../../lib/util/services';
import { WeekDay } from 'calendar-utils';
import { CalendarView } from 'angular-calendar';
import { CustomCalendarView } from '../../fork/angular-calendar/src';
import { GlobalCalendarEvent, GlobalCalendarEventService } from '../../lib/global-calendar/global-calendar-event.service';
import {
  GlobalCalendarEventSearch,
  GlobalCalendarEventSearchService
} from '../../lib/global-calendar/global-calendar-event-search.service';
import { SearchUtils } from '../../util/search-utils';
import { OrderField } from '../../lib/query/orderfields';
import { FilterField } from '../../lib/query/filterfields';
import { CriteriaBuilder, Models } from '../../util/model-utils';
import { GlobalCalendarEventDialogComponent } from './global-calendar-event-dialog/global-calendar-event-dialog.component';
import { List } from 'immutable';
import LocalCalendarEvent = CalendarUtils.LocalCalendarEvent;
import DaysOfWeek = CalendarUtils.DaysOfWeek;

@Component({
  selector: 'app-global-calendar',
  templateUrl: './global-calendar.component.html',
  styleUrls: ['./global-calendar.component.scss']
})
export class GlobalCalendarComponent extends SearchUtils.SearchableList<GlobalCalendarEventSearch.Model> implements OnInit, OnDestroy {


  CustomCalendarView = CustomCalendarView;
  CalendarView = CalendarView;
  UiConstants = UiConstants;
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  viewDate: Date;
  rangeStartDate: Date;
  rangeEndDate: Date;
  view: CalendarView = CalendarView.Month;
  events: LocalCalendarEvent[] = [];
  activeDayIsOpen: boolean = true;
  locale: string;
  weekStartsOn: number = DaysOfWeek.MONDAY;
  excludeDays: number[] = [];
  refresh: Subject<any> = new Subject();
  users: MultiselectOptionItem<number>[] = [];
  config: ConfigModel = new ConfigModel();
  rightModel: RightModel = RightModel.empty();
  queryModel: OrderFieldModel<OrderField.GlobalCalendarEvent>
    = new OrderFieldModel(GlobalCalendarEventSearch.OrderFunctions.START_TIME, OrderType.ASC);
  searchModel: GlobalCalendarEventSearch.Model = new GlobalCalendarEventSearch.Model();

  dayStartHour = 8;
  dayEndHour = 20;
  assigneeSearchDropdownSettings: Angular2Multiselects.Settings;

  constructor(private globalCalendarEventService: GlobalCalendarEventService,
              private settingsService: SettingsService,
              private configService: ConfigurationService,
              private userMultiselectProvider: UserMultiselectProvider,
              private rightService: RightService,
              private uiRouter: UIRouter,
              private datePickerParserFormatter: NgbDatePickerParserFormatter,
              private globalCalendarEventSearchService: GlobalCalendarEventSearchService,
              private dialog: MatDialog,
              injector: Injector) {
    super(GlobalCalendarEventSearch.Model, injector);
    this.config = this.configService.getConfigurationModel();
    this.locale = this.settingsService.getLocaleCode();
    this.viewDate = new Date();
    this.loadRightModels(() => {
      this.loadSearch(() => {
        this.loadList();
      });
    });
    this.initDropdownSettings();
    this.dayStartHour = this.config.calendarDisplayStartInMinutes / 60;
    this.dayEndHour = this.config.calendarDisplayEndInMinutes / 60;
  }

  ngOnInit() {
    this.translateService.get('GLOBAL_CALENDAR').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
  }

  onFirstSearchOpen(): void {

  }

  loadList(): void {
    this.loadCalendarEvents();
  }

  loadSearch(completion: () => void) {
    this.globalCalendarEventSearchService.getSearchData({})
      .subscribe(
        (result: GlobalCalendarEventSearch.SearchDataResult) => {
          this.queryModel = result.searchData.queryModel;
          this.searchModel = result.searchData.searchModel;
          completion();
        }
      );
  }


  private loadRightModels(completion: () => void) {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        completion();
      }
    );
  }

  loadCalendarEvents() {
    this.updateDateRange();
    this.globalCalendarEventService.query({
      filter: this.createFilter(),
      fields: f => f.each,
    }).subscribe((result: QueryResult<GlobalCalendarEvent.GlobalCalendarEvent>) => {
      this.events = result.items.toArray().map(e => CalendarUtils.toGlobalCalendarEvent(e, this.config));
    });
  }

  private createFilter() {
    return (f: FilterField.GlobalCalendarEvent) => CriteriaBuilder.builder()
      .addNumber((id) => f.id.eq(id), this.searchModel.id)
      .addNumberList((creatorUserId) => f.creatorUser.id.in(creatorUserId), List.of(...this.searchModel.users.map(u => u.id)))
      .addString((title) => f.title.containsIgnoreCase(title), this.searchModel.title)
      .addNumber((color) => f.color.eq(color), this.searchModel.color)
      .addDateTime((startTime) => f.startTime.before(startTime), Models.parseDateTimeTo(this.searchModel.startTimeTo))
      .addDateTime((startTime) => f.startTime.after(startTime), Models.parseDateTimeFrom(this.searchModel.startTimeFrom))
      .build();
  }

  onViewDateChange() {
    this.activeDayIsOpen = false;
    this.loadCalendarEvents();
  }

  updateDateRange() {
    switch (this.view) {
      case CalendarView.Day:
        this.rangeStartDate = this.viewDate;
        this.rangeEndDate = this.viewDate;
        break;
      case CalendarView.Week:
        this.rangeStartDate = CalendarUtils.calculateViewDate(1, this.viewDate, this.view);
        this.rangeEndDate = CalendarUtils.calculateViewDate(7, this.viewDate, this.view);
        break;
      case CalendarView.Month:
        this.rangeStartDate = CalendarUtils.calculateStartOfMonth(this.viewDate);
        this.rangeEndDate = CalendarUtils.calculateEndOfMonth(this.viewDate);
        break;
    }
  }

  switchToDayView(date?: { day: WeekDay }) {
    if (date) {
      this.viewDate = date.day.date;
    }
    this.view = CalendarView.Day;
    this.loadCalendarEvents();
  }

  switchToWeekView() {
    this.view = CalendarView.Week;
    this.loadCalendarEvents();
  }

  switchToMonthView() {
    this.view = CalendarView.Month;
    this.loadCalendarEvents();
  }


  showNextView() {
    this.viewDate = CalendarUtils.getNextViewDate(this.viewDate, this.view);
    this.onViewDateChange();
  }

  showPreviousView() {
    this.viewDate = CalendarUtils.getPreviousViewDate(this.viewDate, this.view);
    this.onViewDateChange();
  }

  showTodayView() {
    this.viewDate = new Date();
    this.onViewDateChange();
  }

  onCalendarEventClicked(event: { event: LocalCalendarEvent }) {
    GlobalCalendarEventDialogComponent.openDialog(this.dialog, {
      readonly: !this.rightModel.globalCalendarEventUpdate.hasRight(),
      allowDelete: this.rightModel.globalCalendarEventDelete.hasRight(),
      globalCalendarEventId: +event.event.id!
    }, (result) => {
      if (result.modified) {
        this.loadList()
      }
    });
  }

  // <editor-fold desc="loaders">
  private loadUsers(q?: string) {
    this.userMultiselectProvider.loadActive(q).subscribe((users: MultiselectOptionItem<number>[]) => {
      this.users = users;
    });
  }

  hourSegmentClicked(date: { date: Date }) {
    if (!this.rightModel.globalCalendarEventCreate.hasRight()) {
      return;
    }
    GlobalCalendarEventDialogComponent.openDialog(this.dialog, {
      readonly: false,
      allowDelete: false,
      startDate: date.date
    }, (result) => {
      if (result.modified) {
        this.loadList()
      }
    });
  }

  createEventClicked() {
    if (!this.rightModel.globalCalendarEventCreate.hasRight()) {
      return;
    }
    GlobalCalendarEventDialogComponent.openDialog(this.dialog, {
      readonly: false,
      allowDelete: false
    }, (result) => {
      if (result.modified) {
        this.loadList()
      }
    });
  }

  private initDropdownSettings() {
    this.assigneeSearchDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .remoteSearch(true)
      .build();
  }

  onSearchClicked() {
    this.loadList();
  }

  onSearchReset() {
    this.globalCalendarEventSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.toggleSearch();
          this.loadList();
        });
      }
    );
  }

  private saveSearch() {
    const request = {
        searchData: {
          queryModel: this.queryModel,
          searchModel: this.searchModel
        }
      }
    ;
    this.globalCalendarEventSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  ngOnDestroy() {
    this.saveSearch();
  }

}
