/* eslint-disable */
import { ComponentFactoryResolver, ComponentRef, Directive, ViewContainerRef, } from '@angular/core';
import { FormRecordComposedContainerComponent } from '../../groups/composed/form-record-composed-container.component';
import { FormRef } from '../../../../../lib/util/services';
import { FormRecordComposedContainer } from '../../groups/composed/form-record-composed-container';
import { FormRecordFormTableFieldWrapperComponent } from './wrapper/form-record-form-table-field-record-wrapper.component';
import { FormRecordFormTableFieldWrapper } from './wrapper/form-record-form-table-field-record-wrapper';
/* eslint-enable */

/* eslint-disable */
@Directive({
  selector: '[app-form-record-form-table-field-holder]',
})
/* eslint-enable */
export class FormRecordFormTableFieldHolderDirective {

  constructor(public viewContainerRef: ViewContainerRef, private cfr: ComponentFactoryResolver) {
  }

  public createFormRecordComponent(form: FormRef, readonly: () => boolean): ComponentRef<FormRecordComposedContainer.View> {
    return this.createFormRecordComposedContainerComponent(false, form, readonly);
  }

  public createFormRecordPreviewComponent(form: FormRef): ComponentRef<FormRecordComposedContainer.PreView> {
    return this.createFormRecordComposedContainerComponent(true, form, () => true);
  }

  private createFormRecordComposedContainerComponent(previewMode: boolean, form: FormRef, readonly: () => boolean) {
    const view = this.cfr.resolveComponentFactory(FormRecordComposedContainerComponent)
      .create(this.viewContainerRef.injector);
    view.instance.setPreviewMode(previewMode);
    view.instance.readonly = readonly();
    view.instance.form = form;
    return view;
  }

  public createFormRecordFormTableFieldWrapper(): ComponentRef<FormRecordFormTableFieldWrapper.View> {
    const view = this.cfr.resolveComponentFactory(FormRecordFormTableFieldWrapperComponent)
      .create(this.viewContainerRef.injector);
    return view;
  }

}
