/* eslint-disable */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { RightModel } from '../../app.rights';
import { RightResolver, RightService } from '../../lib/right.service';
import { ConfigurationResource, ConfigurationService } from '../../lib/core-ext/configuration.service';
import { TranslateService } from '@ngx-translate/core';
import {
  AdminDashboardCardMenuItem,
  AdminDashboardCardModel
} from './admin-dashboard-card/admin-dashboard-card.component';
import { SidebarTogglerService } from '../../lib/util/sidebar-toggler.service';
import { Subscription } from 'rxjs';
import { SiteTourService } from '../../lib/site-tour/site-tour.service';
import { SiteTourBaseComponent, SiteTourId } from '../../lib/site-tour/site-tour.factory';

/* eslint-enable */

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: 'admin-dashboard.component.html',
  styleUrls: ['admin-dashboard.component.scss']
})

export class AdminDashboardComponent implements OnInit, OnDestroy, SiteTourBaseComponent {

  ConfigurationService = ConfigurationService;

  rightModel: RightModel = RightModel.empty();
  configuration: ConfigurationResource.Configuration;

  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  updateMasonryLayout: boolean = true;
  sidebarSubscription: Subscription;

  // <editor-fold desc="CARD DEFINITIONS">
  private readonly DATA_TYPE_MANAGEMENT_CARD: AdminDashboardCardModel = {
    id: 'data-type',
    iconClass: 'icomoon-administration-data-type-management',
    headerKey: 'ADMINISTRATION_HEADER_DATA_TYPE_MANAGEMENT',
    menuItems: [
      {
        titleKey: 'MENU_NAVBAR_MENU_CUSTOMER_TYPES',
        destination: 'Admin.CustomerList',
        visiblePredicate: () => {
          return ConfigurationService.isEnabledByServer('CUSTOMERS', this.configuration);
        },
        enabledPredicate: () => {
          return this.rightModel.webAdminCustomers.hasRight()
            && this.rightModel.customerRead.hasRight();
        }
      },
      {
        titleKey: 'MENU_NAVBAR_EMAIL_ADDRESS_TYPES',
        destination: 'Admin.EmailAddressTypeList',
        visiblePredicate: () => {
          return ConfigurationService.isEnabledByServer('CUSTOMERS', this.configuration);
        },
        enabledPredicate: () => {
          return this.rightModel.webAdminEmailAddressTypes.hasRight();
        }
      },
      {
        titleKey: 'MENU_NAVBAR_FORMS_MANAGEMENT',
        destination: 'Admin.TaskList',
        visiblePredicate: () => {
          return ConfigurationService.isEnabledByServer('TASKS', this.configuration);
        },
        enabledPredicate: () => {
          return this.rightModel.webAdminTasks.hasRight()
            && this.rightModel.taskRead.hasRight();
        }
      },
      {
        titleKey: 'MENU_NAVBAR_MASTERDATA_MANAGEMENT',
        destination: 'Admin.MasterdataList',
        visiblePredicate: () => {
          return ConfigurationService.isEnabledByServer('MASTER_DATA', this.configuration);
        },
        enabledPredicate: () => {
          return this.rightModel.masterDataRecordRead.hasRight()
            && this.rightModel.webAdminMasterdata.hasRight()
            && this.rightModel.masterDataRead.hasRight();
        }
      },
      {
        titleKey: 'MENU_NAVBAR_SURVEY',
        destination: 'Admin.SurveyList',
        visiblePredicate: () => {
          return ConfigurationService.isEnabledByServer('SURVEY', this.configuration);
        },
        enabledPredicate: () => {
          return this.rightModel.webAdminSurveys.hasRight()
            && this.rightModel.surveyRead.hasRight();
        }
      },
      {
        titleKey: 'MENU_NAVBAR_FORM_TABLE',
        destination: 'Admin.FormTableList',
        visiblePredicate: () => {
          return (ConfigurationService.isEnabledByServer('TASKS', this.configuration)
            || ConfigurationService.isEnabledByServer('SURVEY', this.configuration));
        },
        enabledPredicate: () => {
          return this.rightModel.webAdminFormTables.hasRight()
            && this.rightModel.formTableRead.hasRight();
        }
      },
      {
        titleKey: 'MENU_NAVBAR_LIST_MANAGEMENT',
        destination: 'Admin.ListItemTypeList',
        visiblePredicate: () => {
          return this.listItemEnabled;
        },
        enabledPredicate: () => {
          return this.rightModel.webAdminListItemTypes.hasRight()
            && this.rightModel.listItemTypeRead.hasRight();
        }
      },
      {
        titleKey: 'MENU_NAVBAR_NAV_TITLE_LEGACY_WORKFLOW_MANAGEMENT',
        destination: 'Admin.LegacyWorkflowList',
        visiblePredicate: () => {
          return ConfigurationService.isEnabledByServer('LEGACY_WORKFLOW', this.configuration);
        },
        enabledPredicate: () => {
          return this.rightModel.webAdminLegacyWorkflows.hasRight()
            && this.rightModel.legacyWorkflowRead.hasRight();
        }
      },
      {
        titleKey: 'MENU_NAVBAR_NAV_TITLE_WORKFLOW_MANAGEMENT',
        destination: 'Admin.WorkflowList',
        visiblePredicate: () => {
          return ConfigurationService.isEnabledByServer('WORKFLOW', this.configuration);
        },
        enabledPredicate: () => {
          return this.rightModel.webAdminWorkflows.hasRight()
            && this.rightModel.workflowRead.hasRight();
        }
      },
      {
        titleKey: 'COMMON_DOCUMENT_GROUPS',
        destination: 'Admin.DocumentGroupList',
        visiblePredicate: () => {
          return ConfigurationService.isEnabledByServer('DOCUMENTS', this.configuration);
        },
        enabledPredicate: () => {
          return this.rightModel.webAdminDocuments.hasRight()
            && this.rightModel.documentGroupRead.hasRight();
        }
      },
      {
        titleKey: 'MENU_NAVBAR_PROJECTS',
        destination: 'Admin.ProjectList',
        visiblePredicate: () => {
          return ConfigurationService.isEnabledByServer('PROJECTS', this.configuration);
        },
        enabledPredicate: () => {
          return this.rightModel.webAdminProjects.hasRight()
            && this.rightModel.projectRead.hasRight();
        }
      },
    ]
  };
  private readonly STOCK_MANAGEMENT_CARD: AdminDashboardCardModel = {
    id: 'stock-management',
    iconClass: 'icomoon-administration-stock-management-alt',
    headerKey: 'ADMINISTRATION_HEADER_STOCK_MANAGEMENT',
    menuItems: [
      {
        titleKey: 'MENU_NAVBAR_PRODUCT_MANAGEMENT',
        destination: 'Admin.StockItemList',
        visiblePredicate: () => {
          return ConfigurationService.isEnabledByServer('STOCK', this.configuration);
        },
        enabledPredicate: () => {
          return this.rightModel.webAdminStockItems.hasRight()
            && this.rightModel.stockItemRead.hasRight();
        }
      },
      {
        titleKey: 'MENU_NAVBAR_STOCK_ITEM_CATEGORY_MANAGEMENT',
        destination: 'Admin.StockItemCategoryList',
        visiblePredicate: () => {
          return ConfigurationService.isEnabledByServer('STOCK', this.configuration);
        },
        enabledPredicate: () => {
          return this.rightModel.webAdminStockItemCategories.hasRight()
            && this.rightModel.stockItemCategoryRead.hasRight();
        }
      },
      {
        titleKey: 'MENU_NAVBAR_STOCK_CHANGE',
        destination: 'Admin.StockChangeList',
        visiblePredicate: () => {
          return ConfigurationService.isEnabledByServer('STOCK', this.configuration);
        },
        enabledPredicate: () => {
          return this.rightModel.webAdminStockChanges.hasRight()
            && this.rightModel.stockChangeRead.hasRight();
        }
      },
    ]
  };
  private readonly USER_MANAGEMENT_CARD: AdminDashboardCardModel = {
    id: 'user-management',
    iconClass: 'icomoon-administration-user-management-alt',
    headerKey: 'ADMINISTRATION_HEADER_USER_MANAGEMENT',
    menuItems: [
      {
        titleKey: 'MENU_NAVBAR_NAV_TITLE_USER_MANAGEMENT',
        destination: 'Admin.UserList',
        visiblePredicate: () => {
          return this.localUserListEnabled;
        },
        enabledPredicate: () => {
          return this.rightModel.webAdminUsers.hasRight()
            && this.rightModel.userRead.hasRight();
        }
      },
      {
        titleKey: 'MENU_NAVBAR_MENU_USER_GROUPS',
        destination: 'Admin.UserGroupList',
        visiblePredicate: () => {
          return this.localUserGroupListEnabled;
        },
        enabledPredicate: () => {
          return this.rightModel.webAdminUserGroups.hasRight()
            && this.rightModel.userGroupRead.hasRight();
        }
      },
      {
        titleKey: 'MENU_NAVBAR_MENU_ROLES',
        destination: 'Admin.RoleList',
        visiblePredicate: () => {
          return true;
        },
        enabledPredicate: () => {
          return this.rightModel.webAdminRoles.hasRight()
            && this.rightModel.roleRead.hasRight();
        }
      },
    ]
  };
  private readonly STATISTICS_CARD: AdminDashboardCardModel = {
    id: 'statistics',
    iconClass: 'icomoon-administration-statistics',
    headerKey: 'ADMINISTRATION_HEADER_STATISTICS',
    menuItems: [
      {
        titleKey: 'MENU_NAVBAR_TASK_LOG',
        destination: 'Admin.TaskLogList',
        visiblePredicate: () => {
          return ConfigurationService.isEnabledByServer('TASKS', this.configuration);
        },
        enabledPredicate: () => {
          return this.rightModel.webAdminTaskRecordLogs.hasRight()
            && this.rightModel.taskRecordLogRead.hasRight();
        }
      },
      {
        titleKey: 'MENU_NAVBAR_TASK_STATISTICS',
        destination: 'Admin.TaskStatisticsList',
        visiblePredicate: () => {
          return ConfigurationService.isEnabledByServer('TASKS', this.configuration);
        },
        enabledPredicate: () => {
          return this.rightModel.webAdminTaskRecordStatistics.hasRight()
            && this.rightModel.taskLogStatRead.hasRight();
        }
      },
      {
        titleKey: 'WORKLOG_PANEL_HEADING_LIST',
        destination: 'Admin.WorklogList',
        visiblePredicate: () => {
          return ConfigurationService.isEnabledByServer('TASKS', this.configuration);
        },
        enabledPredicate: () => {
          return this.rightModel.webAdminWorklogs.hasRight()
            && this.rightModel.worklogRead.hasRight();
        }
      },
      {
        titleKey: 'MENU_NAVBAR_INVOICE_STATISTICS',
        destination: 'Admin.InvoiceStatisticsList',
        visiblePredicate: () => {
          return ConfigurationService.isEnabledByServer('TASKS', this.configuration);
        },
        enabledPredicate: () => {
          return this.rightModel.webAdminInvoiceStatistics.hasRight()
            && this.rightModel.taskInvoiceStatRead.hasRight();
        }
      },
      {
        titleKey: 'ORDER_IMPORT_ERROR_LIST',
        destination: 'Admin.OrderImportErrorList',
        visiblePredicate: () => {
          return ConfigurationService.isEnabledByServer('ORDER', this.configuration);
        },
        enabledPredicate: () => {
          return this.rightModel.webAdminOrderImportErrors.hasRight()
            && this.rightModel.orderImportErrorRead.hasRight();
        }
      },
      {
        titleKey: 'MENU_NAVBAR_MILEAGE_RECORDS',
        destination: 'Admin.MileageRecordList',
        visiblePredicate: () => {
          return ConfigurationService.isEnabledByServer('VEHICLE', this.configuration)
            && ConfigurationService.isEnabledByServer('TASKS', this.configuration); // temporal solution
        },
        enabledPredicate: () => {
          return this.rightModel.webAdminMileageRecords.hasRight()
            && this.rightModel.mileageRecordRead.hasRight();
        }
      },
      {
        titleKey: 'MENU_NAVBAR_AUDIT_LOG',
        destination: 'Admin.AuditlogList',
        visiblePredicate: () => {
          return true;
        },
        enabledPredicate: () => {
          return this.rightModel.webAdminAuditLogs.hasRight()
            && this.rightModel.auditLogRead.hasRight();
        }
      },
    ]
  };
  private readonly COMPANY_MANAGEMENT_CARD: AdminDashboardCardModel = {
    id: 'company-management',
    iconClass: 'icomoon-administration-company-management',
    headerKey: 'ADMINISTRATION_HEADER_COMPANY_MANAGEMENT',
    menuItems: [
      {
        titleKey: 'MENU_NAVBAR_COMPANIES',
        destination: 'Admin.CompanyList',
        visiblePredicate: () => {
          return ConfigurationService.isEnabledByServer('COMPANY', this.configuration);
        },
        enabledPredicate: () => {
          return this.rightModel.webAdminCompanies.hasRight()
            && this.rightModel.companyRead.hasRight();
        }
      },
      {
        titleKey: 'MENU_NAVBAR_MENU_COMPANY_LOCATION',
        destination: 'Admin.CompanyLocationList',
        visiblePredicate: () => {
          return ConfigurationService.isEnabledByServer('COMPANY_LOCATION', this.configuration);
        },
        enabledPredicate: () => {
          return this.rightModel.webAdminCompanyLocations.hasRight()
            && this.rightModel.companyLocationRead.hasRight();
        }
      },
      {
        titleKey: 'MENU_NAVBAR_MENU_COMPANY_STOCK',
        destination: 'Admin.CompanyStockList',
        visiblePredicate: () => {
          return ConfigurationService.isEnabledByServer('COMPANY_STOCK', this.configuration);
        },
        enabledPredicate: () => {
          return this.rightModel.webAdminCompanyStocks.hasRight()
            && this.rightModel.companyStockRead.hasRight();
        }
      },
      {
        titleKey: 'SHOP_RENTER_SHOP_LIST',
        destination: 'Admin.ShopRenterShopList',
        visiblePredicate: () => {
          return ConfigurationService.isEnabledByServer('WEBSHOP', this.configuration);
        },
        enabledPredicate: () => {
          return this.rightModel.webAdminShopRenterShops.hasRight()
            && this.rightModel.shopRenterShopRead.hasRight();
        }
      },
    ]
  };
  private readonly MASTER_DATA_MANAGEMENT_CARD: AdminDashboardCardModel = {
    id: 'master-data-management',
    iconClass: 'icomoon-administration-masterdata-management',
    headerKey: 'ADMINISTRATION_HEADER_OTHER_DATA_MANAGEMENT',
    menuItems: [
      {
        titleKey: 'MENU_NAVBAR_LIST_ITEM',
        destination: 'Admin.ListItemList',
        visiblePredicate: () => {
          return this.listItemEnabled;
        },
        enabledPredicate: () => {
          return this.rightModel.webAdminListItems.hasRight()
            && this.rightModel.listItemRead.hasRight();
        }
      },
      {
        titleKey: 'MENU_NAVBAR_NAV_TITLE_VEHICLES',
        destination: 'Admin.VehicleList',
        visiblePredicate: () => {
          return ConfigurationService.isEnabledByServer('VEHICLE', this.configuration);
        },
        enabledPredicate: () => {
          return this.rightModel.webAdminVehicles.hasRight()
            && this.rightModel.vehicleRead.hasRight();
        }
      },
      {
        titleKey: 'MENU_NAVBAR_DELIVERY_METHODS',
        destination: 'Admin.DeliveryMethodList',
        visiblePredicate: () => {
          return ConfigurationService.isEnabledByServer('PARCEL_COLLECTION_POINT', this.configuration);
        },
        enabledPredicate: () => {
          return this.rightModel.webAdminDeliveryMethods.hasRight()
            && this.rightModel.deliveryMethodRead.hasRight();
        }
      },
      {
        titleKey: 'MENU_NAVBAR_PARCEL_COLLECTION_POINTS',
        destination: 'Admin.ParcelCollectionPointList',
        visiblePredicate: () => {
          return ConfigurationService.isEnabledByServer('PARCEL_COLLECTION_POINT', this.configuration);
        },
        enabledPredicate: () => {
          return this.rightModel.webAdminParcelCollectionPoints.hasRight()
            && this.rightModel.parcelCollectionPointRead.hasRight();
        }
      },
    ]
  };
  private readonly TEMPLATES_CARD: AdminDashboardCardModel = {
    id: 'templates',
    iconClass: 'icomoon-administration-templates',
    headerKey: 'ADMINISTRATION_HEADER_TEMPLATES',
    menuItems: [
      {
        titleKey: 'EMAIL_TEMPLATE_LIST_HEADER',
        destination: 'Admin.EmailTemplateList',
        visiblePredicate: () => {
          return ConfigurationService.isEnabledByServer('GENERAL_EMAIL', this.configuration);
        },
        enabledPredicate: () => {
          return this.rightModel.webAdminEmailTemplates.hasRight()
            && this.rightModel.emailTemplateRead.hasRight();
        }
      },
      {
        titleKey: 'GENERAL_PDF_TEMPLATE_HEADER',
        destination: 'Admin.GeneralPdfTemplateList',
        visiblePredicate: () => {
          return ConfigurationService.isEnabledByServer('GENERAL_PDF', this.configuration);
        },
        enabledPredicate: () => {
          return this.rightModel.webAdminPdfTemplates.hasRight()
            && this.rightModel.generalPdfTemplateRead.hasRight();
        }
      },
      {
        titleKey: 'NOTIFICATION_TEMPLATE_LIST_HEADER',
        destination: 'Admin.NotificationTemplateList',
        visiblePredicate: () => {
          return true;
        },
        enabledPredicate: () => {
          return this.rightModel.webAdminMessageTemplates.hasRight()
            && this.rightModel.messageTemplateRead.hasRight();
        }
      },
      {
        titleKey: 'GENERAL_PRINTER_TEMPLATE_LIST_HEADER',
        destination: 'Admin.GeneralPrinterTemplateList',
        visiblePredicate: () => {
          return ConfigurationService.isEnabledByServer('GENERAL_PRINTER', this.configuration);
        },
        enabledPredicate: () => {
          return this.rightModel.webAdminPrinterTemplates.hasRight()
            && this.rightModel.generalPrinterTemplateRead.hasRight();
        }
      },
    ]
  };
  private readonly SYSTEM_SETTINGS_CARD: AdminDashboardCardModel = {
    id: 'system-settings',
    iconClass: 'icomoon-administration-system-settings',
    headerKey: 'ADMINISTRATION_HEADER_SYSTEM_SETTINGS',
    menuItems: [
      {
        titleKey: 'MENU_NAVBAR_SETTINGS',
        destination: 'Admin.EnvironmentConfig',
        visiblePredicate: () => {
          return true;
        },
        enabledPredicate: () => {
          return this.rightModel.webAdminEnvironmentConfiguration.hasRight()
            && this.rightModel.environmentConfigRead.hasRight();
        }
      },
      {
        titleKey: 'MENU_NAVBAR_REGISTRATION_SETTINGS',
        destination: 'Admin.RegistrationSettings',
        visiblePredicate: () => {
          return true;
        },
        enabledPredicate: () => {
          return this.rightModel.webAdminRegistrationSettings.hasRight()
            && this.rightModel.registrationSettingsRead.hasRight()
            && (this.configService.isRegistrationEnabled()
              || this.configuration.feature_flags.helpdesk_enabled);
        }
      },
      {
        titleKey: 'MENU_NAVBAR_MENU_DEVICE_MANAGEMENT',
        destination: 'Admin.ApplicationDeviceManagementListComponent',
        visiblePredicate: () => {
          return this.mobileAppsEnabled;
        },
        enabledPredicate: () => {
          return this.rightModel.webAdminMobileApplications.hasRight()
            && this.rightModel.mobileAppRead.hasRight();
        }
      },
      {
        titleKey: 'MENU_NAVBAR_TOKENS',
        destination: 'Admin.TokenList',
        visiblePredicate: () => {
          return true;
        },
        enabledPredicate: () => {
          return this.rightModel.webAdminTokens.hasRight()
            && this.rightModel.tokenRead.hasRight();
        }
      },
      {
        titleKey: 'COMMON_DICTIONARY',
        destination: 'Admin.ApplicationDictionaryList',
        visiblePredicate: () => {
          return this.dictionaryEnabled;
        },
        enabledPredicate: () => {
          return true;
        }
      },
      {
        titleKey: 'TRANSPORT_TRIGGER_LIST',
        destination: 'Admin.TransportTriggerList',
        visiblePredicate: () => {
          return ConfigurationService.isEnabledByServer('TRANSPORT', this.configuration);
        },
        enabledPredicate: () => {
          return this.rightModel.webAdminTransportTriggers.hasRight()
            && this.rightModel.transportTriggerRead.hasRight();
        }
      },
      {
        titleKey: 'ANDROID_VERSION_SETTINGS',
        destination: 'Admin.AndroidAppVersion',
        visiblePredicate: () => {
          return true;
        },
        enabledPredicate: () => {
          return this.rightModel.androidAppVersionHandle.hasRight()
            && this.rightModel.webAdminEnvironmentConfiguration.hasRight()
            && this.rightModel.environmentConfigRead.hasRight();
        }
      },
      {
        titleKey: 'COMMON_NOTIFICATIONS',
        destination: 'Admin.NotificationList',
        visiblePredicate: () => {
          return ConfigurationService.isEnabledByServer('MESSAGES', this.configuration);
        },
        enabledPredicate: () => {
          return this.rightModel.messageParentRead.hasRight()
            && this.rightModel.webAdminNotifications.hasRight();
        }
      },
    ]
  };
  private readonly INVOICE_SETTINGS_CARD: AdminDashboardCardModel = {
    id: 'invoice-settings',
    iconClass: 'icomoon-invoice',
    headerKey: 'ADMINISTRATION_HEADER_INVOICE_SETTINGS',
    menuItems: [
      {
        titleKey: 'MENU_NAVBAR_MENU_INVOICE_BOOK',
        destination: 'Admin.InvoiceBookList',
        visiblePredicate: () => {
          return ConfigurationService.isEnabledByServer('INVOICE', this.configuration);
        },
        enabledPredicate: () => {
          return this.rightModel.webAdminInvoiceBook.hasRight()
            && this.rightModel.invoiceBookRead.hasRight();
        }
      },
      {
        titleKey: 'MENU_NAVBAR_MENU_INVOICE_SETTINGS_LIST',
        destination: 'Admin.InvoiceSettingsList',
        visiblePredicate: () => {
          return ConfigurationService.isEnabledByServer('INVOICE', this.configuration);
        },
        enabledPredicate: () => {
          return this.rightModel.webAdminInvoiceProfiles.hasRight()
            && this.rightModel.invoiceSettingsRead.hasRight();
        }
      },
      {
        titleKey: 'MENU_NAVBAR_MENU_LEDGER_NUMBER_LIST',
        destination: 'Admin.LedgerNumberList',
        visiblePredicate: () => {
          return ConfigurationService.isEnabledByServer('INVOICE', this.configuration);
        },
        enabledPredicate: () => {
          return this.rightModel.webAdminLedgerNumbers.hasRight()
            && this.rightModel.ledgerNumberRead.hasRight();
        }
      },
      {
        titleKey: 'MENU_NAVBAR_MENU_INVOICE_TAG_LIST',
        destination: 'Admin.InvoiceTagList',
        visiblePredicate: () => {
          return ConfigurationService.isEnabledByServer('INVOICE', this.configuration);
        },
        enabledPredicate: () => {
          return this.rightModel.webAdminInvoiceTags.hasRight()
            && this.rightModel.invoiceTagRead.hasRight();
        }
      }
    ]
  };
  private readonly APPLICATION_DATA_CARD: AdminDashboardCardModel = {
    id: 'application-data',
    iconClass: 'icomoon-administration-application-data',
    headerKey: 'ADMINISTRATION_HEADER_APPLICATION_DATA',
    menuItems: [
      {
        titleKey: 'MENU_NAVBAR_APPLICATION_FILES',
        destination: 'Admin.ApplicationFileList',
        visiblePredicate: () => {
          return ConfigurationService.isEnabledByServer('APP_FILE', this.configuration);
        },
        enabledPredicate: () => {
          return this.rightModel.webAdminApplicationFiles.hasRight()
            && this.rightModel.appFileRead.hasRight();
        }
      },
      {
        titleKey: 'MENU_NAVBAR_TABLE_DOCUMENT',
        destination: 'Admin.TableDocumentList',
        visiblePredicate: () => {
          return ConfigurationService.isEnabledByServer('TABLE_DOCUMENT', this.configuration);
        },
        enabledPredicate: () => {
          return this.rightModel.webAdminTableDocuments.hasRight()
            && this.rightModel.tableDocumentSchemaRead.hasRight()
            && this.rightModel.tableDocumentRead.hasRight();
        }
      },
      {
        titleKey: 'MENU_NAVBAR_TABLE_DOCUMENT_SCHEMA',
        destination: 'Admin.TableDocumentSchemaList',
        visiblePredicate: () => {
          return ConfigurationService.isEnabledByServer('TABLE_DOCUMENT', this.configuration);
        },
        enabledPredicate: () => {
          return this.rightModel.webAdminTableDocumentSchemas.hasRight()
            && this.rightModel.tableDocumentSchemaRead.hasRight();
        }
      },
    ]
  };
  // </editor-fold>

  private readonly CARDS: AdminDashboardCardModel[] = [
    this.DATA_TYPE_MANAGEMENT_CARD,
    this.STOCK_MANAGEMENT_CARD,
    this.USER_MANAGEMENT_CARD,
    this.STATISTICS_CARD,
    this.COMPANY_MANAGEMENT_CARD,
    this.MASTER_DATA_MANAGEMENT_CARD,
    this.TEMPLATES_CARD,
    this.SYSTEM_SETTINGS_CARD,
    this.APPLICATION_DATA_CARD,
    this.INVOICE_SETTINGS_CARD,
  ];

  cards: AdminDashboardCardModel[] = [];

  constructor(private rightService: RightService,
              private configService: ConfigurationService,
              private sidebarTogglerService: SidebarTogglerService,
              private siteTourService: SiteTourService,
              private translateService: TranslateService) {
    this.configuration = this.configService.getConfiguration();
    this.sidebarSubscription = this.sidebarTogglerService.subscribe(() => {
      setTimeout(() => {
        this.updateMasonryLayout = !this.updateMasonryLayout;
      }, 250);
    });
  }

  ngOnInit() {
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.loadRightModels(() => {
      this.filterCards();
      this.siteTourService.startTour(SiteTourId.ADMIN_DASHBOARD, {});
    });
  }

  private loadRightModels(completion: () => void) {
    this.rightService.getRightResolver().subscribe((resolver: RightResolver) => {
      this.rightModel = RightModel.of(resolver);
      completion();
    });
  }

  private filterCards() {
    this.cards = [];
    this.CARDS.forEach(c => {
      const enabledMenuItems: AdminDashboardCardMenuItem[] = this.filterMenuItems(c.menuItems);
      if (enabledMenuItems.length > 0) {
        this.cards.push({
          id: c.id,
          iconClass: c.iconClass,
          headerKey: c.headerKey,
          menuItems: enabledMenuItems
        });
      }
    });
  }

  private filterMenuItems(items: AdminDashboardCardMenuItem[]): AdminDashboardCardMenuItem[] {
    const enabledItems: AdminDashboardCardMenuItem[] = [];
    items.forEach(i => {
      if (i.visiblePredicate() && (i.enabledPredicate() || this.configuration.feature_flags.demo_mode_enabled)) {
        enabledItems.push(i);
      }
    });
    return enabledItems;
  }

  get mobileAppsEnabled() {
    return this.configuration.enabled_client_features.length > 0;
  }

  get dictionaryEnabled() {
    return ConfigurationService.isEnabledByServer('APP_DICTIONARY', this.configuration)
  }

  get listItemEnabled() {
    return ConfigurationService.isEnabledByServer('PROJECTS', this.configuration) ||
      ConfigurationService.isEnabledByServer('SURVEY', this.configuration) ||
      ConfigurationService.isEnabledByServer('LEGACY_WORKFLOW', this.configuration) ||
      ConfigurationService.isEnabledByServer('CUSTOMERS', this.configuration) ||
      ConfigurationService.isEnabledByServer('TASKS', this.configuration) ||
      ConfigurationService.isEnabledByServer('MASTER_DATA', this.configuration);
  }

  get localUserListEnabled() {
    return this.configuration.feature_flags.local_user_enabled;
  }

  get localUserGroupListEnabled() {
    return this.configuration.feature_flags.local_user_enabled;
  }

  ngOnDestroy() {
    this.sidebarTogglerService.unsubscribe(this.sidebarSubscription);
  }

}
