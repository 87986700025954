import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { ForwardingNgFormRef, LocalFormGroupValidationErrors } from '../../../../lib/util/services';
import { SelectUtils, UiConstants } from '../../../../util/core-utils';
import { Invoice, InvoiceService } from '../../../../lib/invoice/invoice/invoice.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { InvoiceSettings } from '../../../../lib/invoice/invoice-settings/invoice-settings.service';
import { NgbDatePickerParserFormatter } from '../../../../util/ngb-datepicker';
import { InvoiceSpending } from '../../../../lib/invoice/spending/invoice-spending.service';
import { InputMask } from '../../../../util/input-masks';
import { Angular2Multiselects } from '../../../../util/multiselect';
import {
  InvoiceBookMultiselectOptionItem,
  InvoiceBookMultiselectProvider
} from '../../../../lib/invoice/invoice-book/invoice-book-multiselect-provider.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';
import { Models } from '../../../../util/model-utils';
import { DownloadedFile } from '../../../../lib/util/downloaded-files';
import { saveAs } from 'file-saver';
import { ToasterService } from '../../../../fork/angular2-toaster/src/toaster.service';

@Component({
  selector: 'app-invoice-batch-download-dialog',
  templateUrl: './invoice-batch-download-dialog.component.html',
  styleUrls: ['./invoice-batch-download-dialog.component.scss']
})
export class InvoiceBatchDownloadDialogComponent implements OnInit {

  Invoice = Invoice;
  UiConstants = UiConstants;
  InvoiceSettings = InvoiceSettings;
  SelectUtils = SelectUtils;
  InputMask = InputMask;

  model: InvoiceBatchDownloadModel = new InvoiceBatchDownloadModel();

  @ViewChild('f', {static: true})
  form: NgForm;
  formGroup: FormGroup;
  private formGroupValidationErrors: LocalFormGroupValidationErrors;
  searchableDropdownSettings: Angular2Multiselects.Settings;
  invoiceBooks: InvoiceBookMultiselectOptionItem[] = [];

  constructor(public dialogRef: MatDialogRef<InvoiceBatchDownloadDialogComponent>,
              private translateService: TranslateService,
              private toasterService: ToasterService,
              private datePickerParserFormatter: NgbDatePickerParserFormatter,
              private fb: FormBuilder,
              private invoiceService: InvoiceService,
              private invoiceBookMultiselectProvider: InvoiceBookMultiselectProvider,
              @Inject(MAT_DIALOG_DATA) public data: InvoiceBatchDownloadDialogData) {
    this.loadModel();
    this.formGroup = this.createFormGroup(this.fb);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(
      this.createForwardingHtmlForm(),
      this.formGroup
    );
  }

  ngOnInit() {
    this.initDropDown();
  }

  initDropDown() {
    this.searchableDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
  }

  closeDialog(modified: boolean) {
    this.dialogRef.close({modified: modified});
  }

  save() {
    if (this.hasLocalFieldError()) {
      return;
    }
    else {
      this.download();
    }
  }

  hasFieldError(field: InvoiceSpending.ValidatedField): boolean {
    return false;
  }

  removeFieldError(field: InvoiceSpending.ValidatedField) {
  }

  getFieldErrorText(field: InvoiceSpending.ValidatedField): string {
    return '';
  }

  showIconDialog() {

  }

  private createForwardingHtmlForm() {
    return new ForwardingNgFormRef({
      formFn: () => {
        return this.form;
      }
    });
  }

  private createFormGroup(fb: FormBuilder): FormGroup {
    return fb.group(
      {
        invoiceBook: fb.control(
          {value: this.model._invoiceBook},
          []
        ),
        invoiceSeqTo: fb.control(
          {value: this.model.invoiceSeqTo},
          []
        ),
        invoiceSeqFrom: fb.control(
          {value: this.model.invoiceSeqFrom},
          []
        ),
        issueDateFrom: fb.control(
          {value: this.model.issueDateFrom},
          []
        ),
        issueDateTo: fb.control(
          {value: this.model.issueDateTo},
          []
        ),
      }
    );
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  download() {
    if (this.formGroup.invalid) {
      return;
    }
    this.invoiceService.batchDownloadPdf({
      invoiceBookId: this.model.invoiceBookId ? this.model.invoiceBookId : undefined,
      invoiceSeqFrom: this.model.invoiceBookId ? Models.parseNumber(this.model.invoiceSeqFrom) : undefined,
      invoiceSeqTo: this.model.invoiceBookId ? Models.parseNumber(this.model.invoiceSeqTo) : undefined,
      issueDateFrom: Models.ngbDateToLocalDate(this.model.issueDateFrom),
      issueDateTo: Models.ngbDateToLocalDate(this.model.issueDateTo)

    }).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName('invoice.zip'));
        this.closeDialog(false);
      },
      (error) => {
        this.toasterService.pop({
          timeout: UiConstants.ToastTimeoutLong,
          type: UiConstants.toastTypeError,
          title: this.translateService.instant('PDF_DOWNLOAD'),
          body: this.translateService.instant('PDF_DOWNLOAD_ERROR_MESSAGE')
        });
      },
      () => {
      });
  }

  onInvoiceBookSearch(q?: string) {
    this.invoiceBookMultiselectProvider.loadActive(q).subscribe(tags => {
      this.invoiceBooks = tags;
    });
  }

  private loadModel() {
  }

}

class InvoiceBatchDownloadModel {
  _invoiceBook: InvoiceBookMultiselectOptionItem[] = [];
  issueDateFrom: NgbDateStruct | null = null;
  issueDateTo: NgbDateStruct | null = null;

  invoiceSeqFrom: string = '';
  invoiceSeqTo: string = '';

  get invoiceBookId(): number | undefined {
    return this._invoiceBook.length === 0 ? undefined : this._invoiceBook[0].id;
  }

  get invoiceBook(): InvoiceBookMultiselectOptionItem | undefined {
    return this._invoiceBook.length === 0 ? undefined : this._invoiceBook[0];
  }
}

export interface InvoiceBatchDownloadDialogData {
}

export interface InvoiceBatchDownloadDialogResult {
  modified: boolean;
}
