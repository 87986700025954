import { Injectable } from '@angular/core';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class TableDocumentContentResourceService extends BaseHttpService {

  getTableDocumentSchema(request: TableDocumentContentResource.TableDocumentSchemaGetRequest): Observable<any> {
    return this.http.get<any>(this.url + `${request.schema_code}` + '/version/' + `${request.version}`);
  }

  getTableDocumentContent(request: TableDocumentContentResource.TableDocumentContentGetRequest): Observable<any> {
    return this.http.get<any>
    (this.url + `${request.schema_code}` + '/document-code/' + `${request.document_code}` + '/version/' + `${request.version}`);
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/table-document/content/schema-code/');
  }


}

export namespace TableDocumentContentResource {

  export interface TableDocumentSchemaGetRequest {
    schema_code: string;
    version: number;
  }

  export interface TableDocumentContentGetRequest {
    schema_code: string;
    document_code: string;
    version: number;
  }

}
