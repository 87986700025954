<ng-container *ngIf="anyChildVisible()">
  <div class="btn-group" dropdown #dropdown="bs-dropdown" [autoClose]="false">
    <button dropdownToggle type="button" class="btn btn-secondary">
      <i class="icomoon {{buttonIconClass}}">
      </i>
    </button>
    <ul id="dropdown-triggers-manual" *dropdownMenu class="dropdown-menu dropdown-menu-right border-bottom-0"
        role="menu" aria-labelledby="button-triggers-manual">
      <ng-content></ng-content>
    </ul>
  </div>
</ng-container>
