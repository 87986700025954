/* eslint-disable */
import { Component, Input, OnInit } from '@angular/core';
import { saveAs } from 'file-saver';
import { TaskRecordStateMachine } from '../../../../lib/task/task-record-statemachine';
import { DownloadedFile } from '../../../../lib/util/downloaded-files';
import { QueryFieldModel } from '../../../../util/core-utils';
import { ToasterService } from '../../../../fork/angular2-toaster/angular2-toaster';
import { TranslateService } from '@ngx-translate/core';
import { RightResolver, RightService } from '../../../../lib/right.service';
import { RightModel } from '../../../../app.rights';
import { TaskRecord, TaskRecordService } from '../../../../lib/task/task-record.service';
import { OrderType, QueryResult } from '../../../../lib/util/services';
import { Set } from 'immutable';
import { TriggerUtils } from '../../../../lib/trigger/trigger-utils';
import * as printJS from 'print-js';
import { forkJoin, from, Observable, zip } from 'rxjs';
import { PDFDocument } from 'pdf-lib';
import { FileUtils } from '../../../../util/file-utils';

/* eslint-enable */

@Component({
  selector: 'app-task-record-pdf',
  templateUrl: './task-record-pdf.component.html',
  styleUrls: ['./task-record-pdf.component.scss']
})
export class TaskRecordPdfComponent implements OnInit {
  TaskRecord = TaskRecord;

  @Input()
  taskRecordId: number;

  @Input()
  taskId: number;

  @Input()
  taskRecordState: TaskRecordStateMachine.State;

  queryModel: QueryFieldModel<TaskRecord.PdfOrderField> = new QueryFieldModel(TaskRecord.PdfOrderField.DOCUMENT_ID, OrderType.DESC);
  list: {
    selected: boolean,
    pdf: TaskRecord.Pdf
  }[] = [];

  get eachPdfSelected(): boolean {
    let selected = this.list.length > 0;
    this.list.forEach((p) => {
      selected = selected && p.selected;
    });
    return selected;
  }

  get selectedPdfIds(): number[] {
    if (this.list.length > 0) {
      return this.list.filter(p => p.selected).map(p => p.pdf.pdfDocumentId);
    }
    return [];
  }

  constructor(
    private taskRecordService: TaskRecordService,
    private toasterService: ToasterService,
    private translateService: TranslateService,
    private rightService: RightService
  ) {
  }

  ngOnInit() {
    this.loadList();
  }

  loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();
    this.list = [];
    this.taskRecordService.getPdfs({
      taskId: this.taskId,
      taskRecordId: this.taskRecordId,
      orders: Set.of(order),
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined
    }).subscribe((result: QueryResult<TaskRecord.Pdf>) => {
      this.list = result.items.toArray().map(p => ({selected: false, pdf: p}));
      this.queryModel.currentPage = requestedPage;
      this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
    });
  }

  downloadPdf(id: number, print: boolean) {
    this.taskRecordService.downloadPdf({
      taskId: this.taskId,
      taskRecordId: this.taskRecordId,
      documentId: id
    }).subscribe(
      (res: DownloadedFile) => {
        if (print) {
          printJS(URL.createObjectURL(res.getBlob()));
        }
        else {
          saveAs(res.getBlob(), res.getFileName('task-record-' + this.taskRecordId + 'document' + id + '.pdf'));
        }
      });
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: TaskRecord.PdfOrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  getTriggerEventStringKey(event: TriggerUtils.NotificationEvent) {
    return TriggerUtils.notificationEvents.find(e => e.notificationEvent === event)!.stringKey;
  }

  toggleEachPdf() {
    const eachPdfSelected = !this.eachPdfSelected;
    this.list.forEach(cr => {
      cr.selected = eachPdfSelected;
    });
  }

  printPdfs() {
    const ids = this.selectedPdfIds;
    if (ids.length > 0) {
      const observables: Observable<DownloadedFile>[] = [];
      ids.forEach(id => {
        observables.push(this.taskRecordService.downloadPdf({
          taskId: this.taskId,
          taskRecordId: this.taskRecordId,
          documentId: id
        }));
      });
      forkJoin(observables).subscribe(result => {
        this.mergePdfs(result).then(blob => printJS(URL.createObjectURL(blob)));
      });
    }
  }

  private async mergePdfs(pdfs: DownloadedFile[]): Promise<Blob> {
    const mergedPdf = await PDFDocument.create();
    for (const p of pdfs) {
      const pdf = await PDFDocument.load(await FileUtils.blobToBase64(p.getBlob()));
      const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
      copiedPages.forEach(page => mergedPdf.addPage(page));
    }
    return new Blob([await mergedPdf.save()], { type: 'application/pdf' });
  }

}
