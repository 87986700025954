/* eslint-disable */
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { Services } from '../../util/services';
import { DownloadedFile, FileNameBuilder, NamedBlobFile, NamedBlobFileDecorator } from '../../util/downloaded-files';
import { FileAttachment, FileAttachmentResource, FileAttachments } from '../../util/file-attachments';
import {
  TransportAttachmentResource,
  TransportAttachmentResourceService
} from './transport-attachment-resource.service';

/* eslint-enable */

@Injectable()
export class TransportAttachmentService {

  constructor(private resourceService: TransportAttachmentResourceService) {
  }

  // <editor-fold desc="CRUD">

  getAttachments(request: TransportAttachment.GetAttachmentRequest): Observable<FileAttachment[]> {
    return Observable.create((observer: Observer<FileAttachment[]>) => {
      const resourceRequest: TransportAttachmentResource.GetAttachmentRequest = {
        transport_id: request.transportId
      };
      this.resourceService.getAttachments(resourceRequest).subscribe(
        (result: FileAttachmentResource[]) => {
          observer.next(FileAttachments.toPublicList(result));
        });
    });
  }

  downloadAttachment(request: TransportAttachment.DownloadAttachmentRequest): Observable<NamedBlobFile> {
    const fileName = new FileNameBuilder()
      .addString(request.attachment.fullFileName)
      .addOffsetDateTime(Services.toOffsetDateTime(request.attachment.contentUpdateTime))
      .build();
    return this.resourceService.downloadAttachment({
        transportId: request.transportId,
        fileId: request.attachment.id
      }
    ).pipe(map((file: DownloadedFile) => {
      return new NamedBlobFileDecorator(file, fileName);
    }));
  }

  // </editor-fold>

}

export namespace TransportAttachment {

  export interface DownloadAttachmentRequest {
    transportId: number;
    attachment: FileAttachment
  }

  export interface GetAttachmentRequest {
    transportId: number;
  }

}
