import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SignaturePadDialogComponent } from '../signature-pad-dialog/signature-pad-dialog.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss']
})
export class SignatureComponent implements OnInit {

  @Input()
  signatureImg?: string;

  @Input()
  readonly: boolean = false;

  @Output()
  signatureImgChange: EventEmitter<string> = new EventEmitter<string>();

  constructor(private dialog: MatDialog,
              public sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
  }

  openSignatureDialog() {
    const dialogRef = this.dialog.open(SignaturePadDialogComponent, {
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.signatureImg = result.image;
      this.signatureImgChange.emit(result.image);
    });
  }
}
