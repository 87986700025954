<div bsModal [config]="UiConstants.modalConfig" #dialog="bs-modal" class="modal" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document" *ngIf="dialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title"><strong>{{'SERVICE_INFO' | translate}}</strong></p>
        <button type="button" class="close" (click)="closeDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body" *ngIf="model">

        <div class="form-group row">

          <div class="col-md-6">
            <div class="form-group">
              <label class="mb-0">{{'SERVICE_INFO_ADMIN_VERSION' | translate}}</label>
              <br>
              <label class="font-weight-bold mb-0">{{adminVersion}}</label>
            </div>
            <div class="form-group">
              <label class="mb-0">{{'SERVICE_INFO_LICENSE_NAME' | translate}}</label>
              <br>
              <label class="font-weight-bold mb-0">{{model.licenseName}}</label>
            </div>
            <div class="form-group">
              <label class="mb-0">{{'SERVICE_INFO_LICENSE_API_VERSION' | translate}}</label>
              <br>
              <label class="font-weight-bold mb-0">{{model.licenseApiVersion}}</label>
            </div>
            <div class="form-group">
              <label class="mb-0">{{'SERVICE_INFO_EXTERNAL_API_VERSION' | translate}}</label>
              <br>
              <label class="font-weight-bold mb-0">{{model.externalApiVersion}}</label>
            </div>
            <div class="form-group">
              <label class="mb-0">{{'SERVICE_INFO_INTERNAL_API_VERSION' | translate}}</label>
              <br>
              <label class="font-weight-bold mb-0">{{model.internalApiVersion}}</label>
            </div>
            <div class="form-group">
              <label class="mb-0">{{'SERVICE_INFO_REVISION' | translate}}</label>
              <br>
              <label class="font-weight-bold mb-0">{{model.revision}}</label>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label class="mb-0">{{'SERVICE_INFO_SERVER_VERSION' | translate}}</label>
              <br>
              <label class="font-weight-bold mb-0">{{model.appVersion}}</label>
            </div>
            <div class="form-group">
              <label class="mb-0">{{'SERVICE_INFO_SUPPORTED_DATABASE' | translate}}</label>
              <br>
              <label class="font-weight-bold mb-0">{{model.supportedDatabase}}</label>
            </div>
            <div class="form-group">
              <label class="mb-0">{{'SERVICE_INFO_COMMIT_TIME' | translate}}</label>
              <br>
              <label class="font-weight-bold mb-0">{{model.commitTime}}</label>
            </div>
            <div class="form-group">
              <label class="mb-0">{{'SERVICE_INFO_OS' | translate}}</label>
              <br>
              <label class="font-weight-bold mb-0">{{model.osName}}</label>
            </div>
            <div class="form-group">
              <label class="mb-0">{{'SERVICE_INFO_HOSTNAME' | translate}}</label>
              <br>
              <label class="font-weight-bold mb-0">{{model.hostname}}</label>
            </div>
            <div class="form-group">
              <label class="mb-0">{{'SERVICE_INFO_JRE_VERSION' | translate}}</label>
              <br>
              <label class="font-weight-bold mb-0">{{model.jreVersion}}</label>
            </div>
          </div>
          <div class="col-md-12" *ngIf="model.androidAppInfo">
            <div class="form-group">
              <label class="mb-0">{{'SERVICE_INFO_ANDROID_INFO' | translate}}</label>
              <br>
              <label class="font-weight-bold mb-0">{{model.androidAppInfo.versionName}}</label>
              <a class="p-05" title="{{model.androidAppInfo.publicInstallPageUrl}}"
                 (click)="goToBitrise()">
                <i class="icomoon icomoon-state-action-export icon-large cursor-pointer"></i>
              </a>
            </div>
          </div>

        </div>

      </div><!-- /.modal-body -->

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeDialog()">{{'COMMON_BUTTON_CLOSE' |
          translate}}
        </button>
      </div>

    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
