<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div class="breadcrumb-menu d-flex">
    <!--CREATE-->
    <div *ngIf="rightModel.documentFileFileCreate.hasRight()"
         class="breadcrumb-button-icon-container cursor-pointer negative-margin-right"
         uiSref="Admin.DocumentFileCreate">
      <a class="btn-setting d-flex align-items-center" title="{{'COMMON_BUTTON_ADD' | translate}}">
        <i class="icomoon icomoon-add"></i>
        <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_ADD' | translate}}</span>
      </a>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="card">

    <div class="card-header">
      {{'COMMON_DOCUMENTS' | translate}}

      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()"
           title="{{'COMMON_SHOW_SEARCH_HINT' | translate}}"><i #searchIcon class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>

    <div class="card-body border-bottom" *ngIf="showSearch">

      <div class="row form-group">

        <!--Search-name-->
        <div class="col-md-4">
          <label class="search-label"> {{ 'COMMON_NAME' | translate }} </label>
          <input
            type="text"
            class="form-control"
            maxlength="{{UiConstants.maximumVarcharLength}}"
            (keyup.enter)="onSearchClicked()"
            placeholder="{{ 'COMMON_NAME' | translate }}"
            [(ngModel)]="searchModel.name"
            name="name"
            id="name"
            #name="ngModel">
        </div>

        <!--Search-document-group-->
        <div class="col-md-4">
          <label class="search-label"> {{ 'DOCUMENT_SEARCH_DOCUMENT_GROUP' | translate }} </label>
          <select
            class="form-control"
            [(ngModel)]="searchModel.group"
            [compareWith]="SelectUtils.compareObjects"
            id="result"
            name="result"
            #disabled="ngModel">
            <option *ngFor="let item of documentGroupList" [ngValue]="item"> {{ item.name }}</option>
          </select>
        </div>

        <!--Disabled-->
        <div class="col-md-4">
          <label class="search-label"> {{ 'DOCUMENT_SEARCH_DOCUMENT_DISABLED' | translate }} </label>
          <select
            class="form-control"
            [(ngModel)]="searchModel.disabled"
            [compareWith]="SelectUtils.compareObjects"
            id="disabled"
            name="disabled"
            #disabled="ngModel">
            <option *ngFor="let item of disabledItems" [ngValue]="item">{{item.text}}</option>
          </select>
        </div>
      </div>
      <!--Button-->
      <div class="col-md-12 d-flex justify-content-md-end align-items-end px-0">
        <div class="btn-group" role="group">
          <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
            {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
          </button>
          <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
            {{'COMMON_BUTTON_SEARCH' | translate}}
          </button>
        </div>
      </div>

    </div><!-- /.card-body-->
    <div class="card-body">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="DocumentFile.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(DocumentFile.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="DocumentFile.OrderField.NAME"
                                    [orderType]="queryModel.getOrderType(DocumentFile.OrderField.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-sorter-no-op
              [text]="'DOCUMENT_TABLE_HEADER_DOCUMENT_GROUP' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-field-sorter [orderField]="DocumentFile.OrderField.CREATION_TIME"
                                    [orderType]="queryModel.getOrderType(DocumentFile.OrderField.CREATION_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'DOCUMENT_TABLE_HEADER_CREATION_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let documentModel of documentListModel | paginate: {
                                                              itemsPerPage: queryModel.itemsPerPage,
                                                              currentPage: queryModel.currentPage,
                                                              totalItems: queryModel.currentNumberOfItems }" [disabledTableRow]="documentModel.disabled">

          <td class="responsive-table-column fit align-middle"> {{ documentModel.id }}</td>
          <td class="responsive-table-column long align-middle">
            <div class="d-flex align-items-center">
              <img src="{{documentModel.file_type.iconImageSrc}}" class="file-type-icon">
              <div class="d-flex flex-column justify-content-center">
                <a *ngIf="rightModel.documentFileFileUpdate.hasRight()"
                   class="id-text cursor-pointer"
                   href="#" onclick="return false"
                   uiSref="Admin.DocumentFileEdit"
                   [uiParams]="{ id: documentModel.id }"
                   title="{{'COMMON_BUTTON_EDIT' | translate}}">
                  {{documentModel.name}}
                </a>
                <span *ngIf="!rightModel.documentFileFileUpdate.hasRight()">{{documentModel.name}}</span>
                <span class="text-truncate table-text-subtitle">
                  {{ documentModel.content_size.value | formattedNumber}} {{documentModel.content_size.unitDictKey | translate }}
                </span>
              </div>
            </div>
          </td>
          <td class="responsive-table-column hidden-xs-down align-middle"> {{
            getDocumentGroupName(documentModel.document_group_id)}}
          </td>
          <td class="responsive-table-column hidden-sm-down align-middle"> {{ documentModel.creation_time | date: 'short' }}</td>
          <td class="align-middle">
            <app-table-options-menu>
              <app-dropdown-item
                [iconClass]="'icomoon-detail'"
                [titleStringKey]="'COMMON_BUTTON_DETAILS'"
                class="cursor-pointer"
                uiSref="Admin.DocumentFileRead"
                [uiParams]="{ id: documentModel.id }">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="rightModel.documentFileFileUpdate.hasRight()"
                [iconClass]="'icomoon-modify'"
                [titleStringKey]="'COMMON_BUTTON_EDIT'"
                class="cursor-pointer"
                uiSref="Admin.DocumentFileEdit"
                [uiParams]="{ id: documentModel.id }">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="documentModel.disabled && rightModel.documentFileFileUpdate.hasRight()"
                [iconClass]="'icomoon-enable'"
                [titleStringKey]="'COMMON_BUTTON_ENABLE'"
                [itemType]="DropdownItemType.ENABLE"
                class="cursor-pointer"
                (click)="setDisabled($event, documentModel, false)">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="!documentModel.disabled && rightModel.documentFileFileUpdate.hasRight()"
                [iconClass]="'icomoon-disable'"
                [titleStringKey]="'COMMON_BUTTON_DISABLE'"
                [itemType]="DropdownItemType.DISABLE"
                class="cursor-pointer"
                (click)="setDisabled($event, documentModel, true)">
              </app-dropdown-item>
            </app-table-options-menu>
          </td>
        </tr>
        </tbody>

        <tfoot>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="DocumentFile.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(DocumentFile.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="DocumentFile.OrderField.NAME"
                                    [orderType]="queryModel.getOrderType(DocumentFile.OrderField.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-sorter-no-op
              [text]="'DOCUMENT_TABLE_HEADER_DOCUMENT_GROUP' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-field-sorter [orderField]="DocumentFile.OrderField.CREATION_TIME"
                                    [orderType]="queryModel.getOrderType(DocumentFile.OrderField.CREATION_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'DOCUMENT_TABLE_HEADER_CREATION_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>

      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>
  </div>
</div>

