/* eslint-disable */
import { Injectable } from '@angular/core';
import { List, Map as ImmutableMap, Set } from 'immutable';
import { Observable, Observer } from 'rxjs';
import { FieldValidationError, Order, PagingRequest, QueryResult, ResourceQueryResult, Services } from '../util/services';
import { Form } from '../form/form.service';
import { FormResource } from '../form/form-resource.service';
import { TaskResource, TaskResourceService, TaskRoleResourceService } from './task-resource.service';
import { OffsetDateTime } from '../util/dates';
import { Icon, IconService } from './icon.service';
import { TaskRecordStateMachine } from './task-record-statemachine';
import { DqlModel } from '../dql/dql.model';
import { DqlResourceModel } from '../dql/dql-resource.model';
import { Invoice } from '../invoice/invoice/invoice.service';
import { EmptyMessage, IdentityMessage } from '../util/messages';
import { ObservableErrorResourceParser } from '../util/errors';
import { DqlStoredQuery, DqlStoredQueryService } from '../dql/dql-stored-query.service';
import { Trigger } from '../trigger/trigger.service';
import { TriggerInstanceResource } from '../trigger/trigger-instance-resource.service';
import { TriggerResource } from '../trigger/trigger-resource.service';
import { TriggerUtils } from '../trigger/trigger-utils';
import { TriggerInstance } from '../trigger/trigger-instance.service';
import { TaskRecord } from './task-record.service';
import { DataTypeObject } from '../../util/core-utils';
import { TypeBasedRoleResource } from '../user-group.service';
import TriggerService = Trigger.TriggerService;
import PlaceOfConsumptionRequirement = Task.PlaceOfConsumptionRequirement;
import TaskDefaultAssigneeType = Task.TaskDefaultAssigneeType;

/* eslint-enable */

@Injectable()
export class TaskService implements Task.Service, Form.Service, DqlStoredQuery.HolderService, TriggerService {

  private formMapper: Form.ResourceMapper;

  private readonly _dqlStoredQueryService: DqlStoredQueryService;

  constructor(private resourceService: TaskResourceService,
              private roleResourceService: TaskRoleResourceService,
              private iconService: IconService) {
    this.formMapper = new Form.ResourceMapper();
    this._dqlStoredQueryService = new DqlStoredQueryService(resourceService.dqlStoredResourceService);
  }

  get dqlStoredQueryService(): DqlStoredQueryService {
    return this._dqlStoredQueryService;
  }

  // <editor-fold desc="CRUD">

  query(request: Task.QueryRequest): Observable<QueryResult<Task.Task>> {
    return Observable.create((observer: Observer<QueryResult<Task.Task>>) => {
      const resourceRequest: TaskResource.QueryRequest = {
        id: Services.createIdParameter(request.taskIdSet),
        disabled: request.disabled,
        name: request.name,
        external_id: request.externalId,
        q: request.queryText,
        invoice_book: request.invoiceBook,
        show_on_dashboard: request.showOnDashboard,
        helpdesk_enabled: request.helpdeskEnabled,
        has_default_estimated_time: request.hasDefaultEstimatedTime,
        creation_time_from: Services.offsetDateTimeToString(request.creationTimeFrom),
        creation_time_to: Services.offsetDateTimeToString(request.creationTimeTo),
        update_time_from: Services.offsetDateTimeToString(request.updateTimeFrom),
        update_time_to: Services.offsetDateTimeToString(request.updateTimeTo),
        order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
        no_progress_bar: request.noProgressBar,
        with_form: request.withForm,
        rights: Services.createListParameter(request.rights),
      };
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<TaskResource.Task>) => {
          observer.next({
            items: this.toPublicList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  statistics(request: Task.StatisticsQueryRequest): Observable<QueryResult<Task.Task>> {
    return Observable.create((observer: Observer<QueryResult<Task.Task>>) => {
      const resourceRequest: TaskResource.StatisticsQueryRequest = {
        id: Services.createIdParameter(request.taskIdSet),
        disabled: request.disabled,
        name: request.name,
        external_id: request.externalId,
        q: request.queryText,
        state: request.state,
        statistics_type: request.statisticsType,
        show_on_dashboard: request.showOnDashboard,
        order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
        rights: Services.createListParameter(request.rights),
      };
      return this.resourceService.statistics(resourceRequest).subscribe(
        (result: ResourceQueryResult<TaskResource.Task>) => {
          observer.next({
            items: this.toPublicList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  get(request: Task.GetRequest): Observable<Task.Task> {
    return Observable.create((observer: Observer<Task.Task>) => {
      const resourceRequest: TaskResource.GetRequest = {
        task_id: request.taskId,
        rights: Services.createListParameter(request.rights),
      };
      return this.resourceService.get(resourceRequest).subscribe(
        (result: TaskResource.Task) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  create(r: Task.CreateRequest): Observable<IdentityMessage> {
    return Observable.create((observer: Observer<IdentityMessage>) => {
      const resourceRequest: TaskResource.CreateRequest = {
        language_code: r.languageCode,
        name: r.name,
        external_id: r.externalId,
        description: r.description,
        validation_type: r.validationType,
        customer_signature: r.customerSignature,
        user_signature: r.userSignature,
        picture_upload: r.pictureUpload,
        photo_confirm_dialog_enabled: r.photoConfirmDialogEnabled,
        photo_enabled_in_states: r.photoEnabledInStates.toArray(),
        min_num_of_pics: r.minNumOfPics,
        max_num_of_pics: r.maxNumOfPics,
        customer_attachment_enabled: r.customerAttachmentEnabled,
        parallel_work_rejected: r.parallelWorkRejected,
        icon: this.iconService.toResourceIcon(r.icon),
        time_tracking_enabled: r.timeTrackingEnabled,
        manual_time_tracking_enabled: r.manualTimeTrackingEnabled,
        manual_time_tracking_required: r.manualTimeTrackingRequired,
        manual_time_tracking_type: r.manualTimeTrackingType,
        collaborator_time_tracking_enabled: r.collaboratorTimeTrackingEnabled,
        mileage_record_enabled: r.mileageRecordEnabled,
        mileage_record_required: r.mileageRecordRequired,
        geofencing_enabled: r.geofencingEnabled,
        geofencing_radius_in_meters: r.geofencingRadiusInMeters,
        show_pictures_on_form: r.showPicturesOnForm,
        default_estimated_time_in_minutes: r.defaultEstimatedTimeInMinutes,
        auto_invoice_generate: r.autoInvoiceGenerate,
        manual_invoice_enabled: r.manualInvoiceEnabled,
        invoice_settings_id: r.invoiceSettingsId,
        invoice_tag_id: r.invoiceTagId,
        invoice_book_id: r.invoiceBookId,
        print_invoice_count: r.printInvoiceCount,
        valid_invoice_payment_types: r.validInvoicePaymentTypes.size > 0 ? r.validInvoicePaymentTypes.toArray() : undefined,
        generating_invoice_payment_types: r.generatingInvoicePaymentTypes.size > 0 ? r.generatingInvoicePaymentTypes.toArray() : undefined,
        default_linked_survey_ids: r.defaultLinkedSurveyIds.size > 0 ? r.defaultLinkedSurveyIds.toArray() : undefined,
        default_deadline: r.defaultDeadline ? this.toResourceDefaultDeadline(r.defaultDeadline) : undefined,
        creator_user_groups: r.creatorUserGroups.size > 0 ? r.creatorUserGroups.toArray() : undefined,
        task_record_external_id_sequence_data: TaskService.toResourceTaskRecordExternalIdSequenceData(r.taskRecordExternalIdSequenceData),
        task_record_name_template: r.taskRecordNameTemplate,
        use_default_managed_fields: r.useDefaultManagedFields,
        managed_fields: r.managedFields ? r.managedFields.toArray().map(f => <string>f) : undefined,
        helpdesk_managed_fields: r.helpdeskManagedFields ? r.helpdeskManagedFields.toArray().map(f => <string>f) : undefined,
        required_fields: r.requiredFields.toArray().map(f => <string>f),
        helpdesk_required_fields: r.helpdeskRequiredFields ? r.helpdeskRequiredFields.toArray().map(f => <string>f) : undefined,
        default_state: r.defaultState.toString(),
        default_state_force_new: r.defaultStateForceNew,
        web_create_default_assignee_type: <string>r.webCreateDefaultAssigneeType,
        show_on_dashboard: r.showOnDashboard,
        explicit_order_number: r.explicitOrderNumber,
        place_of_consumption_requirement: r.placeOfConsumptionRequirement,
        helpdesk_enabled: r.helpdeskEnabled,
        set_customer_owner_user_after_finish: r.setCustomerOwnerUserAfterFinish,
        set_customer_owner_user_group_after_finish: r.setCustomerOwnerUserGroupAfterFinish,
        task_record_color: r.taskRecordColor,
        submitted_filters: r.submittedFilters.map(p => this.toResourceSubmittedFilter(p)),
        sms_rating_enabled: r.smsRatingEnabled,
        in_progress_signature_enabled: r.inProgressSignatureEnabled,
        sms_rating_delivery_schedule_min: r.smsRatingDeliveryScheduleMin,
        sms_rating_template_id: r.smsRatingTemplateId,
        state_filters: r.stateFilters.map(p => this.toResourceStateFilter(p)),
        admin_editable_states: r.adminEditableStates.map(s => <string>s),
        enabled_assignees: {
          user_groups: r.enabledAssignees.userGroups.toArray()
        }
      };
      return this.resourceService.create(resourceRequest).subscribe(
        (result: IdentityMessage) => {
          observer.next(result);
        },
        (error: any) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  update(r: Task.UpdateRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: TaskResource.UpdateRequest = {
        task_id: r.taskId,
        name: r.name,
        external_id: r.externalId,
        description: r.description,
        validation_type: r.validationType,
        customer_signature: r.customerSignature,
        user_signature: r.userSignature,
        picture_upload: r.pictureUpload,
        photo_confirm_dialog_enabled: r.photoConfirmDialogEnabled,
        photo_enabled_in_states: r.photoEnabledInStates.toArray(),
        min_num_of_pics: r.minNumOfPics,
        max_num_of_pics: r.maxNumOfPics,
        customer_attachment_enabled: r.customerAttachmentEnabled,
        parallel_work_rejected: r.parallelWorkRejected,
        icon: this.iconService.toResourceIcon(r.icon),
        time_tracking_enabled: r.timeTrackingEnabled,
        manual_time_tracking_enabled: r.manualTimeTrackingEnabled,
        manual_time_tracking_required: r.manualTimeTrackingRequired,
        manual_time_tracking_type: r.manualTimeTrackingType,
        collaborator_time_tracking_enabled: r.collaboratorTimeTrackingEnabled,
        show_pictures_on_form: r.showPicturesOnForm,
        default_estimated_time_in_minutes: r.defaultEstimatedTimeInMinutes,
        mileage_record_enabled: r.mileageRecordEnabled,
        mileage_record_required: r.mileageRecordRequired,
        geofencing_enabled: r.geofencingEnabled,
        geofencing_radius_in_meters: r.geofencingRadiusInMeters,
        auto_invoice_generate: r.autoInvoiceGenerate,
        manual_invoice_enabled: r.manualInvoiceEnabled,
        invoice_settings_id: r.invoiceSettingsId,
        invoice_tag_id: r.invoiceTagId,
        invoice_book_id: r.invoiceBookId,
        print_invoice_count: r.printInvoiceCount,
        valid_invoice_payment_types: r.validInvoicePaymentTypes.size > 0 ? r.validInvoicePaymentTypes.toArray() : undefined,
        generating_invoice_payment_types: r.generatingInvoicePaymentTypes.size > 0 ? r.generatingInvoicePaymentTypes.toArray() : undefined,
        default_linked_survey_ids: r.defaultLinkedSurveyIds.size > 0 ? r.defaultLinkedSurveyIds.toArray() : undefined,
        default_deadline: r.defaultDeadline ? this.toResourceDefaultDeadline(r.defaultDeadline) : undefined,
        creator_user_groups: r.creatorUserGroups.size > 0 ? r.creatorUserGroups.toArray() : undefined,
        task_record_external_id_sequence_data: TaskService.toResourceTaskRecordExternalIdSequenceData(r.taskRecordExternalIdSequenceData),
        task_record_name_template: r.taskRecordNameTemplate,
        use_default_managed_fields: r.useDefaultManagedFields,
        managed_fields: r.managedFields ? r.managedFields.toArray().map(f => <string>f) : undefined,
        helpdesk_managed_fields: r.helpdeskManagedFields ? r.helpdeskManagedFields.toArray().map(f => <string>f) : undefined,
        required_fields: r.requiredFields.toArray().map(f => <string>f),
        helpdesk_required_fields: r.helpdeskRequiredFields ? r.helpdeskRequiredFields.toArray().map(f => <string>f) : undefined,
        default_state: r.defaultState.toString(),
        default_state_force_new: r.defaultStateForceNew,
        web_create_default_assignee_type: <string>r.webCreateDefaultAssigneeType,
        show_on_dashboard: r.showOnDashboard,
        explicit_order_number: r.explicitOrderNumber,
        place_of_consumption_requirement: r.placeOfConsumptionRequirement,
        helpdesk_enabled: r.helpdeskEnabled,
        set_customer_owner_user_after_finish: r.setCustomerOwnerUserAfterFinish,
        set_customer_owner_user_group_after_finish: r.setCustomerOwnerUserGroupAfterFinish,
        task_record_color: r.taskRecordColor,
        submitted_filters: r.submittedFilters.map(p => this.toResourceSubmittedFilter(p)),
        sms_rating_enabled: r.smsRatingEnabled,
        in_progress_signature_enabled: r.inProgressSignatureEnabled,
        sms_rating_delivery_schedule_min: r.smsRatingDeliveryScheduleMin,
        sms_rating_template_id: r.smsRatingTemplateId,
        state_filters: r.stateFilters.map(p => this.toResourceStateFilter(p)),
        admin_editable_states: r.adminEditableStates.map(s => <string>s),
        enabled_assignees: {
          user_groups: r.enabledAssignees.userGroups.toArray()
        }
      };
      return this.resourceService.update(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  setDisabled(request: Task.DisableRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: TaskResource.DisableRequest = {
        task_id: request.taskId,
        disabled: request.disabled
      };
      return this.resourceService.setDisabled(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  // </editor-fold>

  // <editor-fold desc="FileAttachment">

  importForm(request: Form.ImportRequest): Observable<Form.Form> {
    return Observable.create((observer: Observer<Form.Form>) => {
      return this.resourceService.importForm({
        parent_id: request.parentId,
        ignore_disabled_items: request.ignoreDisabledFields,
        form: request.form,
        version: request.version
      }).subscribe((result: FormResource.Form) => {
          observer.next(this.formMapper.toPublicForm(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        }
      );
    });
  }

  exportForm(request: Form.ExportRequest): Observable<Form.FormImportDocument> {
    return Observable.create((observer: Observer<Form.FormImportDocument>) => {
      return this.resourceService.exportForm({
        parent_id: request.parentId,
      }).subscribe((result: FormResource.FormImportDocument) => {
          observer.next({form: result.form, version: result.version});
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        }
      );
    });
  }

  importJson(request: Task.TaskImportRequest): Observable<IdentityMessage> {
    return Observable.create((observer: Observer<IdentityMessage>) => {
      return this.resourceService.importJson({
        task_id: request.taskId,
        settings: {
          trigger_remove_existing_triggers: request.settings.triggerRemoveExistingTriggers,
          trigger_ignore_disabled_items: request.settings.triggerIgnoreDisabledItems,
          form_ignore_disabled_items: request.settings.formIgnoreDisabledItems
        },
        document: request.document
      }).subscribe((result: IdentityMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        }
      );
    });
  }

  exportJson(request: Task.TaskExportRequest): Observable<TaskResource.TaskImportDocument> {
    return Observable.create((observer: Observer<TaskResource.TaskImportDocument>) => {
      return this.resourceService.exportJson({
        task_id: request.taskId,
        include_roles: request.includeRoles,
        include_base_data: request.includeBaseData,
        include_form_data: request.includeFormData,
        include_triggers: request.includeTriggers
      }).subscribe((result: TaskResource.TaskImportDocument) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        }
      );
    });
  }

  getDqlModel(request: Task.DqlRequest): Observable<DqlModel.QueryableModel> {
    return Observable.create((observer: Observer<DqlModel.QueryableModel>) => {
      return this.resourceService.getDqlModel({
        task_id: request.taskId
      }).subscribe((result: DqlResourceModel.QueryableModel) => {
          observer.next(new DqlModel.DqlQueryableModelConverter(!request.taskId).toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        }
      );
    });
  }

  setInvoiceBook(request: Task.InvoiceBookPatchRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.resourceService.setInvoiceBook({
        id: request.id,
        invoice_book: request.invoiceBook
      }).subscribe((result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        }
      );
    });
  }

// </editor-fold>

// <editor-fold desc="Form">

  getForm(request: Form.GetFormRequest): Observable<Form.Form> {
    return Observable.create((observer: Observer<Form.Form>) => {
      const resourceRequest: FormResource.GetFormRequest =
        this.formMapper.toResourceGetFormRequest(request);
      return this.resourceService.getForm(resourceRequest).subscribe(
        (result: FormResource.Form) => {
          observer.next(this.formMapper.toPublicForm(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateGetFormError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  createGroup(request: Form.CreateGroupRequest): Observable<Form.CreateGroupResponse> {
    return Observable.create((observer: Observer<Form.CreateGroupResponse>) => {
      const resourceRequest: FormResource.CreateGroupRequest =
        this.formMapper.toResourceCreateGroupRequest(request);
      return this.resourceService.createFormGroup(resourceRequest).subscribe(
        (result: FormResource.CreateGroupResponse) => {
          observer.next(this.formMapper.toPublicCreateGroupResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateCreateGroupError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  updateGroup(request: Form.UpdateGroupRequest): Observable<Form.UpdateGroupResponse> {
    return Observable.create((observer: Observer<Form.UpdateGroupResponse>) => {
      const resourceRequest: FormResource.UpdateGroupRequest =
        this.formMapper.toResourceUpdateGroupRequest(request);
      return this.resourceService.updateFormGroup(resourceRequest).subscribe(
        (result: FormResource.UpdateGroupResponse) => {
          observer.next(this.formMapper.toPublicUpdateGroupResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateUpdateGroupError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  disableGroup(request: Form.DisableGroupRequest): Observable<Form.DisableGroupResponse> {
    return Observable.create((observer: Observer<Form.DisableGroupResponse>) => {
      const resourceRequest: FormResource.DisableGroupRequest =
        this.formMapper.toResourceDisableGroupRequest(request);
      return this.resourceService.disableFormGroup(resourceRequest).subscribe(
        (result: FormResource.DisableGroupResponse) => {
          observer.next(this.formMapper.toPublicDisableGroupResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateDisableGroupError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  moveGroup(request: Form.MoveGroupRequest): Observable<Form.MoveGroupResponse> {
    return Observable.create((observer: Observer<Form.MoveGroupResponse>) => {
      const resourceRequest: FormResource.MoveGroupRequest =
        this.formMapper.toResourceMoveGroupRequest(request);
      return this.resourceService.moveFormGroup(resourceRequest).subscribe(
        (result: FormResource.MoveGroupResponse) => {
          observer.next(this.formMapper.toPublicMoveGroupResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateMoveGroupError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  createField(request: Form.CreateFieldRequest): Observable<Form.CreateFieldResponse> {
    return Observable.create((observer: Observer<Form.CreateFieldResponse>) => {
      const resourceRequest: FormResource.CreateFieldRequest =
        this.formMapper.toResourceCreateFieldRequest(request);
      return this.resourceService.createFormField(resourceRequest).subscribe(
        (result: FormResource.CreateFieldResponse) => {
          observer.next(this.formMapper.toPublicCreateFieldResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateCreateFieldError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  updateField(request: Form.UpdateFieldRequest): Observable<Form.UpdateFieldResponse> {
    return Observable.create((observer: Observer<Form.UpdateFieldResponse>) => {
      const resourceRequest: FormResource.UpdateFieldRequest =
        this.formMapper.toResourceUpdateFieldRequest(request);
      return this.resourceService.updateFormField(resourceRequest).subscribe(
        (result: FormResource.UpdateFieldResponse) => {
          observer.next(this.formMapper.toPublicUpdateFieldResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateUpdateFieldError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  disableField(request: Form.DisableFieldRequest): Observable<Form.DisableFieldResponse> {
    return Observable.create((observer: Observer<Form.DisableFieldResponse>) => {
      const resourceRequest: FormResource.DisableFieldRequest =
        this.formMapper.toResourceDisableFieldRequest(request);
      return this.resourceService.disableFormField(resourceRequest).subscribe(
        (result: FormResource.DisableFieldResponse) => {
          observer.next(this.formMapper.toPublicDisableFieldResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateDisableFieldError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  moveFieldToGroup(request: Form.MoveFieldToGroupRequest): Observable<Form.MoveFieldToGroupResponse> {
    return Observable.create((observer: Observer<Form.MoveFieldToGroupResponse>) => {
      const resourceRequest: FormResource.MoveFieldToGroupRequest =
        this.formMapper.toResourceMoveFieldToGroupRequest(request);
      return this.resourceService.moveFormFieldToGroup(resourceRequest).subscribe(
        (result: FormResource.MoveFieldToGroupResponse) => {
          observer.next(this.formMapper.toPublicMoveFieldToGroupResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateMoveFieldToGroupError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  getRoles(request: IdentityMessage): Observable<TypeBasedRoleResource[]> {
    return Observable.create((observer: Observer<TypeBasedRoleResource[]>) => {
      return this.roleResourceService.getRoles(request).subscribe(
        (result: TypeBasedRoleResource[]) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  createRole(request: TypeBasedRoleResource): Observable<IdentityMessage> {
    return Observable.create((observer: Observer<IdentityMessage>) => {
      return this.roleResourceService.createRole(request).subscribe(
        (result: IdentityMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  updateRole(request: TypeBasedRoleResource): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.roleResourceService.updateRole(request).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  deleteRole(request: TypeBasedRoleResource): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.roleResourceService.deleteRole(request).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

// </editor-fold>

// <editor-fold desc="Internal">

  private translateError(error: any): any {
    const res = ObservableErrorResourceParser.parseError(error);
    const fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
    const fieldErrorMap = ObservableErrorResourceParser.toFieldErrorMap(Keys.toValidatedField, fieldErrors);
    if (!fieldErrorMap.isEmpty()) {
      return FieldValidationError.of(fieldErrorMap);
    }
    return error;
  }

  private toPublicList(resourceList: TaskResource.Task[]): List<Task.Task> {
    return List.of(...resourceList.map((r) => this.toPublic(r)));
  }

  private toPublic(r: TaskResource.Task): Task.Task {
    const stats = this.toPublicStatistics(r.statistics);
    return {
      taskId: r.id,
      disabled: r.disabled,
      creationTime: Services.toOffsetDateTime(r.creation_time),
      updateTime: Services.toOffsetDateTime(r.update_time),
      name: r.name,
      externalId: r.external_id,
      description: r.description,
      validationType: r.validation_type,
      customerSignature: r.customer_signature,
      userSignature: r.user_signature,
      pictureUpload: r.picture_upload,
      photoConfirmDialogEnabled: r.photo_confirm_dialog_enabled,
      photoEnabledInStates: Set.of(...r.photo_enabled_in_states),
      minNumOfPics: r.min_num_of_pics,
      maxNumOfPics: r.max_num_of_pics,
      invoiceBook: r.invoice_book,
      customerAttachmentEnabled: r.customer_attachment_enabled,
      parallelWorkRejected: r.parallel_work_rejected,
      icon: this.iconService.toPublicIcon(r.icon),
      sendAutoEmailOnSubmit: r.send_auto_email_on_submit,
      sendAutoEmailToUser: r.send_auto_email_to_user,
      sendAutoEmailToCustomer: r.send_auto_email_to_customer,
      autoEmailOtherRecipients: r.auto_email_other_recipients,
      pdfTemplateId: r.template_id,
      timeTrackingEnabled: r.time_tracking_enabled,
      manualTimeTrackingEnabled: r.manual_time_tracking_enabled,
      manualTimeTrackingRequired: r.manual_time_tracking_required,
      manualTimeTrackingType: <TaskResource.TaskManualTimeTrackingType> r.manual_time_tracking_type,
      collaboratorTimeTrackingEnabled: r.collaborator_time_tracking_enabled,
      defaultEstimatedTimeInMinutes: r.default_estimated_time_in_minutes,
      mileageRecordEnabled: r.mileage_record_enabled,
      mileageRecordRequired: r.mileage_record_required,
      geofencingEnabled: r.geofencing_enabled,
      geofencingRadiusInMeters: r.geofencing_radius_in_meters,
      showPicturesOnForm: r.show_pictures_on_form,
      statistics: stats,
      overdueCount: r.overdue_count,
      autoInvoiceGenerate: r.auto_invoice_generate,
      manualInvoiceEnabled: r.manual_invoice_enabled,
      invoiceSettingsId: r.invoice_settings_id,
      invoiceBookId: r.invoice_book_id,
      invoiceTagId: r.invoice_tag_id,
      printInvoiceCount: r.print_invoice_count,
      validInvoicePaymentTypes: r.valid_invoice_payment_types
        ? Set.of(...<Invoice.InvoicePaymentType[]>r.valid_invoice_payment_types) : Set.of(),
      generatingInvoicePaymentTypes: r.generating_invoice_payment_types
        ? Set.of(...<Invoice.InvoicePaymentType[]>r.generating_invoice_payment_types) : Set.of(),
      defaultLinkedSurveyIds: r.default_linked_survey_ids ? Set.of(...r.default_linked_survey_ids) : Set.of(),
      defaultDeadline: r.default_deadline ? this.toPublicDefaultDeadline(r.default_deadline) : undefined,
      creatorUserGroups: r.creator_user_groups ? Set.of(...r.creator_user_groups) : Set.of(),
      taskRecordExternalIdSequenceData: this.toPublicTaskRecordExternalIdSequenceData(r.task_record_external_id_sequence_data),
      taskRecordNameTemplate: r.task_record_name_template,
      useDefaultManagedFields: r.use_default_managed_fields,
      managedFields: Set.of(...r.managed_fields.map(f => <TaskRecord.TaskRecordField>f)),
      helpdeskManagedFields: r.helpdesk_managed_fields
        ? Set.of(...r.helpdesk_managed_fields.map(f => <TaskRecord.TaskRecordField>f))
        : undefined,
      requiredFields: Set.of(...r.required_fields.map(f => <TaskRecord.TaskRecordField>f)),
      helpdeskRequiredFields: r.helpdesk_required_fields
        ? Set.of(...r.helpdesk_required_fields.map(f => <TaskRecord.TaskRecordField>f))
        : undefined,
      defaultState: <TaskRecordStateMachine.State>r.default_state,
      defaultStateForceNew: r.default_state_force_new,
      webCreateDefaultAssigneeType: <TaskDefaultAssigneeType> r.web_create_default_assignee_type,
      showOnDashboard: r.show_on_dashboard,
      explicitOrderNumber: r.explicit_order_number,
      placeOfConsumptionRequirement: <PlaceOfConsumptionRequirement>r.place_of_consumption_requirement,
      form: r.form ? this.formMapper.toPublicForm(r.form) : undefined,
      helpdeskEnabled: r.helpdesk_enabled,
      setCustomerOwnerUserAfterFinish: r.set_customer_owner_user_after_finish,
      setCustomerOwnerUserGroupAfterFinish: r.set_customer_owner_user_group_after_finish,
      taskRecordColor: r.task_record_color,
      submittedFilters: r.submitted_filters.map(r => this.toPublicSubmittedFilter(r)),
      smsRatingEnabled: r.sms_rating_enabled,
      inProgressSignatureEnabled: r.in_progress_signature_enabled,
      smsRatingDeliveryScheduleMin: r.sms_rating_delivery_schedule_min,
      smsRatingTemplateId: r.sms_rating_template_id,
      stateFilters: r.state_filters.map(r => this.toPublicStateFilter(r)),
      adminEditableStates: r.admin_editable_states.map(s => <TaskRecordStateMachine.State>s),
      enabledAssignees: r.enabled_assignees ? {
        userGroups: Set.of(...r.enabled_assignees.user_groups)
      } : {userGroups: Set.of()},
      grantedRights: r.granted_rights ? Set.of(...r.granted_rights) : Set.of(),
    };
  }

  private toPublicStatistics(statistics ?: Map<string, number>): ImmutableMap<TaskRecordStateMachine.State, number> | undefined {
    if (!statistics) {
      return undefined;
    }
    const keyValues: any[] = [];
    Object.keys(statistics).forEach((key: string, index: number) => {
      keyValues.push(key, statistics[key]);
    });
    return ImmutableMap.of(...keyValues);
  }

  private toPublicDefaultDeadline(r: TaskResource.TaskDefaultDeadline): Task.TaskDefaultDeadline {
    return {
      dayType: r.day_type,
      offset: r.offset
    }
  }

  private toResourceDefaultDeadline(r: Task.TaskDefaultDeadline): TaskResource.TaskDefaultDeadline {
    return {
      day_type: r.dayType,
      offset: r.offset
    }
  }

  private toPublicTaskRecordExternalIdSequenceData(r?: TaskResource.TaskRecordExternalIdSequenceData):
    Task.TaskRecordExternalIdSequenceData | undefined {
    return r ? {
      template: r.template,
      withinYear: r.within_year
    } : undefined;
  }

  public static toResourceTaskRecordExternalIdSequenceData(r?: Task.TaskRecordExternalIdSequenceData):
    TaskResource.TaskRecordExternalIdSequenceData | undefined {
    return r ? {
      template: r.template,
      within_year: r.withinYear
    } : undefined;
  }

  public static toPublicTaskRecordExternalIdSequenceData(r?: TaskResource.TaskRecordExternalIdSequenceData):
    Task.TaskRecordExternalIdSequenceData | undefined {
    return r ? {
      template: r.template,
      withinYear: r.within_year
    } : undefined;
  }

  private toPublicSubmittedFilter(r: TaskResource.SubmittedFilter): Task.SubmittedFilter {
    return {
      userGroupId: r.user_group_id,
      days: r.days
    };
  }

  private toResourceSubmittedFilter(r: Task.SubmittedFilter): TaskResource.SubmittedFilter {
    return {
      user_group_id: r.userGroupId,
      days: r.days
    };
  }

  private toPublicStateFilter(r: TaskResource.StateFilter): Task.StateFilter {
    return {
      userGroupId: r.user_group_id,
      states: Set.of(...r.states.map(s => <TaskRecordStateMachine.State>s))
    };
  }

  private toResourceStateFilter(r: Task.StateFilter): TaskResource.StateFilter {
    return {
      user_group_id: r.userGroupId,
      states: r.states.toArray().map(s => s.toString())
    };
  }

  createTrigger(request: Trigger.TriggerCreateRequest): Observable<IdentityMessage> {
    return Observable.create((observer: Observer<IdentityMessage>) => {
      const resourceRequest: TriggerResource.TriggerCreateRequest = TriggerUtils.toResourceTriggerCreateRequest(request);
      return this.resourceService.createTrigger(resourceRequest).subscribe(
        (result: IdentityMessage) => {
          observer.next(result);
        },
        (error: any) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  deleteTrigger(request: Trigger.TriggerDeleteRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: TriggerResource.TriggerDeleteRequest = TriggerUtils.toResourceTriggerDeleteRequest(request);
      return this.resourceService.deleteTrigger(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: any) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  disableTrigger(request: Trigger.TriggerDisableRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: TriggerResource.TriggerDisableRequest = TriggerUtils.toResourceTriggerDisableRequest(request);
      return this.resourceService.disableTrigger(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: any) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  getTrigger(request: Trigger.TriggerGetRequest): Observable<Trigger.Trigger> {
    return Observable.create((observer: Observer<Trigger.Trigger>) => {
      const resourceRequest: TriggerResource.TriggerGetRequest = TriggerUtils.toResourceTriggerGetRequest(request);
      return this.resourceService.getTrigger(resourceRequest).subscribe(
        (result: TriggerResource.Trigger) => {
          observer.next(TriggerUtils.toPublicTrigger(result));
        },
        (error: any) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  getTriggerInstances(request: Trigger.TriggerInstanceRequest): Observable<QueryResult<TriggerInstance.TriggerInstance>> {
    return Observable.create((observer: Observer<QueryResult<TriggerInstance.TriggerInstance>>) => {
      const resourceRequest: TriggerResource.TriggerInstanceRequest = TriggerUtils.toResourceTriggerInstanceRequest(request);
      return this.resourceService.getTriggerInstances(resourceRequest).subscribe(
        (result: ResourceQueryResult<TriggerInstanceResource.TriggerInstance>) => {
          observer.next({
            items: TriggerUtils.toPublicTriggerInstanceList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  queryTriggers(request: Trigger.TriggerQueryRequest): Observable<QueryResult<Trigger.Trigger>> {
    return Observable.create((observer: Observer<QueryResult<Trigger.Trigger>>) => {
      const resourceRequest: TriggerResource.TriggerQueryRequest = TriggerUtils.toResourceTriggerQueryRequest(request);
      return this.resourceService.queryTriggers(resourceRequest).subscribe(
        (result: ResourceQueryResult<TriggerResource.Trigger>) => {
          observer.next({
            items: TriggerUtils.toPublicTriggerList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  updateTrigger(request: Trigger.TriggerUpdateRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: TriggerResource.TriggerUpdateRequest = TriggerUtils.toResourceTriggerUpdateRequest(request);
      return this.resourceService.updateTrigger(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: any) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

// </editor-fold>

}

export namespace Task {

  export interface Service {

    // <editor-fold desc="CRUD">

    query(request: QueryRequest): Observable<QueryResult<Task>>;

    get(request: GetRequest): Observable<Task>;

    create(request: CreateRequest): Observable<IdentityMessage>;

    update(request: UpdateRequest): Observable<EmptyMessage>;

    setDisabled(request: DisableRequest): Observable<EmptyMessage>;

    // </editor-fold>

  }

  // <editor-fold desc="CRUD">

  export interface Task {
    taskId: number;
    disabled: boolean;
    creationTime: OffsetDateTime;
    updateTime: OffsetDateTime;
    name: string;
    externalId?: string;
    description?: string;
    invoiceBook?: number;
    validationType: TaskResource.TaskValidationType;
    customerSignature: TaskResource.TaskSignatureValidationType;
    userSignature: TaskResource.TaskSignatureValidationType;
    pictureUpload: boolean;
    photoConfirmDialogEnabled: boolean;
    photoEnabledInStates: Set<string>;
    minNumOfPics?: number;
    maxNumOfPics?: number;
    customerAttachmentEnabled: boolean;
    parallelWorkRejected: boolean;
    icon?: Icon.Icon;
    sendAutoEmailOnSubmit: boolean;
    sendAutoEmailToUser: boolean;
    sendAutoEmailToCustomer: boolean;
    autoEmailOtherRecipients?: string[];
    pdfTemplateId?: number;
    timeTrackingEnabled: boolean;
    manualTimeTrackingEnabled: boolean;
    manualTimeTrackingRequired?: boolean;
    manualTimeTrackingType?: TaskResource.TaskManualTimeTrackingType;
    collaboratorTimeTrackingEnabled: boolean;
    defaultEstimatedTimeInMinutes?: number;
    mileageRecordEnabled: boolean;
    mileageRecordRequired: boolean;
    geofencingEnabled: boolean;
    geofencingRadiusInMeters?: number;
    showPicturesOnForm: boolean;
    statistics?: ImmutableMap<TaskRecordStateMachine.State, number>;
    overdueCount?: number;
    autoInvoiceGenerate: boolean;
    manualInvoiceEnabled: boolean;
    invoiceSettingsId?: number;
    invoiceTagId?: number;
    invoiceBookId?: number;
    printInvoiceCount?: number;
    validInvoicePaymentTypes: Set<Invoice.InvoicePaymentType>;
    generatingInvoicePaymentTypes: Set<Invoice.InvoicePaymentType>;
    defaultLinkedSurveyIds: Set<number>;
    defaultDeadline?: TaskDefaultDeadline;
    creatorUserGroups: Set<number>;
    taskRecordExternalIdSequenceData?: TaskRecordExternalIdSequenceData;
    taskRecordNameTemplate?: string;
    useDefaultManagedFields: boolean;
    managedFields: Set<TaskRecord.TaskRecordField>;
    helpdeskManagedFields?: Set<TaskRecord.TaskRecordField>;
    requiredFields: Set<TaskRecord.TaskRecordField>;
    helpdeskRequiredFields?: Set<TaskRecord.TaskRecordField>;
    defaultState: TaskRecordStateMachine.State;
    defaultStateForceNew: boolean;
    webCreateDefaultAssigneeType: TaskDefaultAssigneeType;
    showOnDashboard: boolean;
    explicitOrderNumber?: number;
    placeOfConsumptionRequirement: PlaceOfConsumptionRequirement;
    form?: Form.Form;
    helpdeskEnabled: boolean;
    setCustomerOwnerUserAfterFinish: boolean;
    setCustomerOwnerUserGroupAfterFinish: boolean;
    taskRecordColor: number; //int
    submittedFilters: SubmittedFilter[];
    smsRatingEnabled: boolean;
    inProgressSignatureEnabled: boolean;
    smsRatingDeliveryScheduleMin?: number;
    smsRatingTemplateId?: number;
    stateFilters: StateFilter[];
    adminEditableStates: TaskRecordStateMachine.State[];
    enabledAssignees: EnabledAssignees;
    grantedRights: Set<string>;
  }

  export interface QueryRequest {
    taskIdSet?: Set<number>;
    disabled?: boolean;
    name?: string;
    externalId?: string;
    queryText?: string;
    invoiceBook?: number;
    showOnDashboard?: boolean;
    helpdeskEnabled?: boolean;
    hasDefaultEstimatedTime?: boolean;
    creationTimeFrom?: OffsetDateTime;
    creationTimeTo?: OffsetDateTime;
    updateTimeFrom?: OffsetDateTime;
    updateTimeTo?: OffsetDateTime;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
    noProgressBar?: boolean;
    withForm?: boolean;
    rights?: Set<string>;
  }

  export interface StatisticsQueryRequest {
    taskIdSet?: Set<number>;
    disabled?: boolean;
    name?: string;
    externalId?: string;
    withForm?: boolean;
    statisticsType?: TaskStatisticsType;
    state?: TaskRecordStateMachine.State;
    showOnDashboard?: boolean;
    queryText?: string;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
    rights?: Set<string>;
  }


  export interface GetRequest {
    taskId: number;
    rights?: Set<string>;
  }

  export interface DqlRequest {
    taskId?: number;
  }

  export interface CreateRequest {
    languageCode: string;
    name: string;
    externalId?: string;
    description?: string;
    validationType: TaskResource.TaskValidationType;
    customerSignature: TaskResource.TaskSignatureValidationType;
    userSignature: TaskResource.TaskSignatureValidationType;
    pictureUpload: boolean;
    photoConfirmDialogEnabled: boolean;
    photoEnabledInStates: Set<string>;
    minNumOfPics?: number;
    maxNumOfPics?: number;
    customerAttachmentEnabled: boolean;
    parallelWorkRejected: boolean;
    icon?: Icon.Icon;
    timeTrackingEnabled: boolean;
    manualTimeTrackingEnabled: boolean;
    manualTimeTrackingRequired?: boolean;
    manualTimeTrackingType?: TaskResource.TaskManualTimeTrackingType;
    showPicturesOnForm: boolean;
    collaboratorTimeTrackingEnabled: boolean;
    defaultEstimatedTimeInMinutes?: number;
    mileageRecordEnabled: boolean;
    mileageRecordRequired: boolean;
    geofencingEnabled: boolean;
    geofencingRadiusInMeters?: number;
    autoInvoiceGenerate: boolean;
    manualInvoiceEnabled: boolean;
    invoiceSettingsId?: number;
    invoiceTagId?: number;
    invoiceBookId?: number;
    printInvoiceCount?: number;
    validInvoicePaymentTypes: Set<Invoice.InvoicePaymentType>;
    generatingInvoicePaymentTypes: Set<Invoice.InvoicePaymentType>;
    defaultLinkedSurveyIds: Set<number>;
    defaultDeadline?: TaskDefaultDeadline;
    creatorUserGroups: Set<number>;
    taskRecordExternalIdSequenceData?: TaskRecordExternalIdSequenceData;
    taskRecordNameTemplate?: string;
    useDefaultManagedFields: boolean;
    managedFields?: Set<TaskRecord.TaskRecordField>;
    helpdeskManagedFields?: Set<TaskRecord.TaskRecordField>;
    requiredFields: Set<TaskRecord.TaskRecordField>;
    helpdeskRequiredFields?: Set<TaskRecord.TaskRecordField>;
    defaultState: TaskRecordStateMachine.State;
    defaultStateForceNew: boolean;
    webCreateDefaultAssigneeType: TaskDefaultAssigneeType;
    showOnDashboard: boolean;
    explicitOrderNumber?: number;
    placeOfConsumptionRequirement: PlaceOfConsumptionRequirement;
    helpdeskEnabled: boolean;
    setCustomerOwnerUserAfterFinish: boolean;
    setCustomerOwnerUserGroupAfterFinish: boolean;
    taskRecordColor: number; //int
    submittedFilters: SubmittedFilter[];
    smsRatingEnabled: boolean;
    inProgressSignatureEnabled: boolean;
    smsRatingDeliveryScheduleMin?: number;
    smsRatingTemplateId?: number;
    stateFilters: StateFilter[];
    adminEditableStates: TaskRecordStateMachine.State[];
    enabledAssignees: EnabledAssignees;
  }

  export interface UpdateRequest {
    taskId: number;
    name: string;
    externalId?: string;
    description?: string;
    validationType: TaskResource.TaskValidationType;
    customerSignature: TaskResource.TaskSignatureValidationType;
    userSignature: TaskResource.TaskSignatureValidationType;
    pictureUpload: boolean;
    photoConfirmDialogEnabled: boolean;
    photoEnabledInStates: Set<string>;
    minNumOfPics?: number;
    maxNumOfPics?: number;
    customerAttachmentEnabled: boolean;
    parallelWorkRejected: boolean;
    icon?: Icon.Icon;
    timeTrackingEnabled: boolean;
    manualTimeTrackingEnabled: boolean;
    manualTimeTrackingRequired?: boolean;
    manualTimeTrackingType?: TaskResource.TaskManualTimeTrackingType;
    showPicturesOnForm: boolean;
    collaboratorTimeTrackingEnabled: boolean;
    defaultEstimatedTimeInMinutes?: number;
    mileageRecordEnabled: boolean;
    mileageRecordRequired: boolean;
    geofencingEnabled: boolean;
    geofencingRadiusInMeters?: number;
    autoInvoiceGenerate: boolean;
    manualInvoiceEnabled: boolean;
    invoiceSettingsId?: number;
    invoiceTagId?: number;
    invoiceBookId?: number;
    printInvoiceCount?: number;
    validInvoicePaymentTypes: Set<Invoice.InvoicePaymentType>;
    generatingInvoicePaymentTypes: Set<Invoice.InvoicePaymentType>;
    defaultLinkedSurveyIds: Set<number>;
    defaultDeadline?: TaskDefaultDeadline;
    creatorUserGroups: Set<number>;
    taskRecordExternalIdSequenceData?: TaskRecordExternalIdSequenceData;
    taskRecordNameTemplate?: string;
    useDefaultManagedFields: boolean;
    managedFields?: Set<TaskRecord.TaskRecordField>;
    helpdeskManagedFields?: Set<TaskRecord.TaskRecordField>;
    requiredFields: Set<TaskRecord.TaskRecordField>;
    helpdeskRequiredFields?: Set<TaskRecord.TaskRecordField>;
    defaultState: TaskRecordStateMachine.State;
    defaultStateForceNew: boolean;
    webCreateDefaultAssigneeType: TaskDefaultAssigneeType;
    showOnDashboard: boolean;
    explicitOrderNumber?: number;
    placeOfConsumptionRequirement: PlaceOfConsumptionRequirement;
    helpdeskEnabled: boolean;
    setCustomerOwnerUserAfterFinish: boolean;
    setCustomerOwnerUserGroupAfterFinish: boolean;
    taskRecordColor: number; //int
    submittedFilters: SubmittedFilter[];
    smsRatingEnabled: boolean;
    inProgressSignatureEnabled: boolean;
    smsRatingDeliveryScheduleMin?: number;
    smsRatingTemplateId?: number;
    stateFilters: StateFilter[];
    adminEditableStates: TaskRecordStateMachine.State[];
    enabledAssignees: EnabledAssignees;
  }

  export interface DisableRequest {
    taskId: number;
    disabled: boolean;
  }

  export interface TaskImportRequest {
    taskId?: number;
    document: TaskResource.TaskImportDocument;
    settings: {
      formIgnoreDisabledItems: boolean;
      triggerIgnoreDisabledItems: boolean;
      triggerRemoveExistingTriggers: boolean;
    }
  }

  export interface TaskExportRequest {
    taskId: number;
    includeBaseData: boolean;
    includeFormData: boolean;
    includeTriggers: boolean;
    includeRoles: boolean;
  }


  export interface InvoiceBookPatchRequest {
    id: number[];
    invoiceBook: number;
  }

  export interface TaskRecordExternalIdSequenceData {
    template: string,
    withinYear: boolean
  }

  export interface SubmittedFilter {
    userGroupId: number;
    days: number;
  }

  export interface StateFilter {
    userGroupId: number;
    states: Set<TaskRecordStateMachine.State>;
  }

  export interface EnabledAssignees {
    userGroups: Set<number>;
  }

  export enum TaskDefaultAssigneeType {
    NONE = 'NONE',
    CREATOR = 'CREATOR'
  }

  export const taskDefaultAssigneeTypes: DataTypeObject<TaskDefaultAssigneeType>[] = [
    {id: TaskDefaultAssigneeType.NONE, stringKey: 'TASK_DEFAULT_ASSIGNEE_TYPE_NONE'},
    {id: TaskDefaultAssigneeType.CREATOR, stringKey: 'TASK_DEFAULT_ASSIGNEE_TYPE_CREATOR'},
  ];

  export type TaskStatisticsType = 'ALL' | 'OWN' | 'NONE';

  export enum PlaceOfConsumptionRequirement {
    NOT_REQUIRED = 'NOT_REQUIRED',
    REQUIRED = 'REQUIRED',
    CONTACT_LOCATION_REQUIRED = 'CONTACT_LOCATION_REQUIRED'
  }

  export const placeOfConsumptionRequirements: DataTypeObject<PlaceOfConsumptionRequirement>[] = [
    {id: PlaceOfConsumptionRequirement.NOT_REQUIRED, stringKey: 'PLACE_OF_CONSUMPTION_REQUIREMENT_NOT_REQUIRED'},
    {id: PlaceOfConsumptionRequirement.REQUIRED, stringKey: 'PLACE_OF_CONSUMPTION_REQUIREMENT_REQUIRED'},
    {
      id: PlaceOfConsumptionRequirement.CONTACT_LOCATION_REQUIRED,
      stringKey: 'PLACE_OF_CONSUMPTION_REQUIREMENT_CONTACT_LOCATION_REQUIRED'
    },
  ];

  export enum OrderField {
    ID,
    DISABLED,
    CREATION_TIME,
    UPDATE_TIME,
    NAME,
    EXTERNAL_ID,
    DESCRIPTION,
    EXPLICIT_ORDER_NUMBER,
  }

  export enum ValidatedField {
    UNKNOWN,
    NAME,
    EXTERNAL_ID,
    MIN_NUM_OF_PICS,
    TEMPLATE,
    TASK_RECORD_NAME_TEMPLATE,
    GEOFENCING_RADIUS_IN_METERS,
    EXPLICIT_ORDER_NUMBER,
  }

  export interface TaskDefaultDeadline {
    dayType: TaskResource.DayType;
    offset: number;
  }

  export class TaskDocumentType {

    public static PDF = new TaskDocumentType('PDF', '/pdf', 'TASK_DOCUMENT_TYPE_PDF');
    public static PRINTER = new TaskDocumentType('PRINTER', '/printer', 'TASK_DOCUMENT_TYPE_PRINTER');

    public static VALUES: List<TaskDocumentType> = List.of(TaskDocumentType.PDF, TaskDocumentType.PRINTER);

    public static fromResource(res?: TaskResource.TaskDocumentType): TaskDocumentType {
      const item = TaskDocumentType.VALUES.find((v) => {
        return v !== undefined && v.type === res
      });
      return item === undefined ? TaskDocumentType.PDF : item;
    }

    private constructor(private _type: TaskResource.TaskDocumentType, private path: string, private _stringKey: string) {
    }

    get type() {
      return this._type;
    }

    get uploadPath() {
      return this.path;
    }

    get stringKey() {
      return this._stringKey;
    }

  }

  // </editor-fold>

}

// <editor-fold desc="Internal">

class Keys {

  private static readonly ID = 'id';
  private static readonly DISABLED = 'disabled';
  private static readonly CREATION_TIME = 'creation_time';
  private static readonly UPDATE_TIME = 'update_time';
  private static readonly NAME = 'name';
  private static readonly DESCRIPTION = 'description';
  private static readonly EXTERNAL_ID = 'external_id';
  private static readonly MIN_NUM_OF_PICS = 'min_num_of_pics';
  private static readonly TEMPLATE = 'template';
  private static readonly TASK_RECORD_NAME_TEMPLATE = 'task_record_name_template';
  private static readonly GEOFENCING_RADIUS_IN_METERS = 'geofencing_radius_in_meters';
  private static readonly EXPLICIT_ORDER_NUMBER = 'explicit_order_number';

  private static readonly orderFieldKeyMap: ImmutableMap<Task.OrderField, string> = ImmutableMap.of(
    Task.OrderField.ID, Keys.ID,
    Task.OrderField.DISABLED, Keys.DISABLED,
    Task.OrderField.CREATION_TIME, Keys.CREATION_TIME,
    Task.OrderField.UPDATE_TIME, Keys.UPDATE_TIME,
    Task.OrderField.NAME, Keys.NAME,
    Task.OrderField.EXTERNAL_ID, Keys.EXTERNAL_ID,
    Task.OrderField.DESCRIPTION, Keys.DESCRIPTION,
    Task.OrderField.EXPLICIT_ORDER_NUMBER, Keys.EXPLICIT_ORDER_NUMBER,
  );

  private static readonly keyValidatedFieldMap: ImmutableMap<string, Task.ValidatedField> = ImmutableMap.of(
    Keys.NAME, Task.ValidatedField.NAME,
    Keys.EXTERNAL_ID, Task.ValidatedField.EXTERNAL_ID,
    Keys.MIN_NUM_OF_PICS, Task.ValidatedField.MIN_NUM_OF_PICS,
    Keys.TEMPLATE, Task.ValidatedField.TEMPLATE,
    Keys.TASK_RECORD_NAME_TEMPLATE, Task.ValidatedField.TASK_RECORD_NAME_TEMPLATE,
    Keys.GEOFENCING_RADIUS_IN_METERS, Task.ValidatedField.GEOFENCING_RADIUS_IN_METERS,
    Keys.EXPLICIT_ORDER_NUMBER, Task.ValidatedField.EXPLICIT_ORDER_NUMBER,
  );

  public static toOrderFieldKey(field: Task.OrderField): string {
    return Keys.orderFieldKeyMap.get(field)!;
  }

  public static toValidatedField(fieldKey: string): Task.ValidatedField {
    return Keys.keyValidatedFieldMap.get(fieldKey, Task.ValidatedField.UNKNOWN);
  }

}

// </editor-fold>
