import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Dates, OffsetDateTime } from '../../../../lib/util/dates';
import { NfcCard, NfcCardService } from '../../../../lib/nfc-card/nfc-card.service';
import { RightResolver, RightService } from '../../../../lib/right.service';
import { RightModel } from '../../../../app.rights';
import { RoleService } from '../../../../lib/role/role.service';

@Component({
  selector: 'app-nfc-card-list',
  templateUrl: './nfc-card-list.component.html',
  styleUrls: ['./nfc-card-list.component.scss']
})
export class NfcCardListComponent implements OnInit, AfterViewInit {

  @Input()
  readonly: boolean;

  @Input()
  userId: number;

  @Output()
  addNfcCardEventEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  deleteNfcCardEventEmitter: EventEmitter<number> = new EventEmitter<number>();

  nfcCardList: NfcCardModel[] = [];

  rightModel: RightModel = RightModel.empty();

  constructor(private nfcCardService: NfcCardService,
              private rightService: RightService) { }

  ngOnInit() {
    this.loadRightModels();
  }

  ngAfterViewInit() {
    this.loadList();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe((resolver: RightResolver) => {
      this.rightModel = RightModel.of(resolver);
    });
  }

  loadList() {
    this.nfcCardService.query({
      userId: this.userId
    }).subscribe((nfcCards: NfcCard.NfcCard[]) => {
      this.nfcCardList = [];
      nfcCards.forEach((nfcCard) => {
        const item = new NfcCardModel();
        item.id = nfcCard.id;
        item.hexId = nfcCard.hexId;
        item.creationTime = nfcCard.creationTime;
        item.mobileApplicationRegistrationCount = nfcCard.mobileApplicationRegistrationCount;
        this.nfcCardList.push(item);
      });
    })
  }

  addNfcCard() {
    this.addNfcCardEventEmitter.emit();
  }

  deleteNfcCard(id: number) {
    this.deleteNfcCardEventEmitter.emit(id);
  }

  getNfcCardRegistrationStatus(mobileAppRegistrationCount: number): string {
    return mobileAppRegistrationCount > 0 ? 'NFC_CARD_MOBILE_REGISTRATION_STATUS_ACTIVE' : 'NFC_CARD_MOBILE_REGISTRATION_STATUS_INACTIVE';
  }

}

export class NfcCardModel {
  id: number;
  hexId: string = '';
  creationTime: OffsetDateTime = Dates.emptyOffsetDateTime();
  mobileApplicationRegistrationCount = 0;
}
