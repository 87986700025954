/* eslint-disable */
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Transition, UIRouter } from '@uirouter/angular';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { StateName } from '../../../app.state-names';
import { TranslateService } from '@ngx-translate/core';
import { ComponentStateResolver } from '../../../util/component-state/component-state-resolver';
import { CompanyStock, CompanyStockFieldErrorMap, CompanyStockService } from '../../../lib/company-stock/company-stock.service';
import { UserGroup, UserGroupService } from '../../../lib/user-group.service';
import { NgForm, NgModel } from '@angular/forms';
import { List, Set } from 'immutable';
import {
  LocalFieldValidationErrors,
  LocalFieldValidationErrorsFactory,
  OrderType,
  QueryResult,
  ResourceQueryResult, Services
} from '../../../lib/util/services';
import { MultiselectOptionItem, OptionItem, QueryFieldModel, UiConstants } from '../../../util/core-utils';
import { CompanyLocation, CompanyLocationService } from '../../../lib/company-location/company-location.service';
import { Strings } from '../../../lib/util/strings';
import { FieldError, FieldErrors, ObservableErrorResourceParser } from '../../../lib/util/errors';
import { Address } from '../../../lib/address';
import { ConfigModel } from '../../../util/task-record-utils';
import { ConfigurationService } from '../../../lib/core-ext/configuration.service';
import { EmptyMessage } from '../../../lib/util/messages';
import { RightModel } from '../../../app.rights';
import { RightResolver, RightService } from '../../../lib/right.service';
import { HistoryDetailDialogComponent } from '../../history-log/history-detail-dialog/history-detail-dialog.component';
import { HistoryBaseModel, HistoryLogComponent } from '../../history-log/history-log/history-log.component';
import CompanyStockHistoryType = CompanyStock.CompanyStockHistoryType;
import { Angular2Multiselects } from '../../../util/multiselect';
/* eslint-enable */

@Component({
  selector: 'app-company-stock-base',
  templateUrl: './company-stock-base.component.html',
  styleUrls: ['./company-stock-base.component.scss']
})
export class CompanyStockBaseComponent implements OnInit, AfterViewInit {

  CompanyStock = CompanyStock;
  Address = Address;
  UiConstants = UiConstants;

  @ViewChild('selectedLocation')
  selectedLocationInput: NgModel;

  @ViewChild('selectedUserGroup')
  selectedUserGroupInput: NgModel;

  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;

  // Component state resolver, determines the state of the component
  componentState: ComponentStateResolver;

  // Declare models before use
  editModel: CompanyStockEditModel;
  detailModel: CompanyStockDetailModel;

  locationItems: MultiselectOptionItem<number>[] = [];
  userGroupList: MultiselectOptionItem<number>[] = [];


  dropdownSettings: Angular2Multiselects.Settings;

  config: ConfigModel = new ConfigModel();

  // Format of postal address, received from server
  postalAddressFormat: string;

  rightModel: RightModel = RightModel.empty();

  // Form for validation
  @ViewChild('f', { static: true })
  fForm: NgForm;

  // Validated inputs
  @ViewChild('externalId')
  externalId: NgModel;

  @ViewChild('name')
  name: NgModel;

  @ViewChild('selectedLocation')
  selectedLocation: NgModel;

  @ViewChild('selectedUserGroup')
  selectedUserGroup: NgModel;

  @ViewChild('historyComponent')
  historyComponent: HistoryLogComponent;

  @ViewChild('historyDetailModal', { static: true })
  historyDetailModal: HistoryDetailDialogComponent;


  // private fieldErrors: FieldValidationError<CompanyStock.ValidatedField> =
  //   FieldValidationError.empty<CompanyStock.ValidatedField>();
  fieldErrors: CompanyStockFieldErrorMap;

  private validatedInputs: LocalFieldValidationErrors<NgModel> =
    LocalFieldValidationErrorsFactory.empty();

  historyList: CompanyStockHistoryModel[] = [];

  historyTypes: CompanyStockHistoryTypeItem[];

  historyQueryModel: QueryFieldModel<CompanyStock.OrderField> =
    new QueryFieldModel(CompanyStock.OrderField.HISTORY_CREATION_TIME, OrderType.DESC);

  constructor(
    private translateService: TranslateService,
    private uiRouter: UIRouter,
    private transition: Transition,
    private companyStockService: CompanyStockService,
    private locationService: CompanyLocationService,
    private userGroupService: UserGroupService,
    private configService: ConfigurationService,
    private rightService: RightService,
  ) {
    // this.fieldErrors = FieldValidationError.empty<CompanyStock.ValidatedField>();
    this.postalAddressFormat = this.configService.getPostalAddressFormat();
    this.fieldErrors = {};
    this.componentState = new ComponentStateResolver(uiRouter, transition,
      'id',
      {stateName: StateName.COMPANY_STOCK_CREATE, stateHeaderKey: 'COMPANY_STOCK_CREATE'},
      {stateName: StateName.COMPANY_STOCK_EDIT, stateHeaderKey: 'COMPANY_STOCK_EDIT'},
      {stateName: StateName.COMPANY_STOCK_DETAIL, stateHeaderKey: 'COMPANY_STOCK_DETAIL'});
  }

  ngOnInit() {
    this.initComponentState(); // Must be called first
    this.initBreadcrumb();
    this.initDropdownSettings();
    this.loadRightModels();
  }

  ngAfterViewInit(): void {
    this.loadLocalFieldValidationErrors();

  }


  initBreadcrumb() {
    // Set breadcrumbSelf if createView, otherwise set in loadModel()
    if (this.componentState.isCreateView()) {
      this.translateService.get('COMPANY_STOCK_CREATE').subscribe(
        (result: string) => {
          this.breadcrumbSelf = result;
        }
      );
    }
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_COMPANY_STOCK').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.COMPANY_STOCK_LIST});
      }
    );
  }

  initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  private initComponentState() {
    if (!this.componentState.isDetailView()) {
      this.editModel = new CompanyStockEditModel();
    }
    else {
      this.detailModel = new CompanyStockDetailModel();
    }
    if (this.componentState.id) {
      this.loadModel();
    }
  }


  // Called by the init method first
  private loadModel() {
    this.companyStockService.get({
      id: this.componentState.id!
    }).subscribe((companyStock: CompanyStock.CompanyStock) => {
      if (this.componentState.isEditView()) {
        this.loadEditModel(companyStock);
      }
      else if (this.componentState.isDetailView()) {
        this.loadDetailModel(companyStock);
      }
      if (this.componentState.isEditable()) {
        this.loadLocations();
      }
      this.loadUserGroups();
      if (this.componentState.isDetailView() && this.rightModel.companyStockHistoryLog.hasRight() ) {
        this.loadHistoryTypes(() => {
          this.loadHistoryList()
        });
      }
      this.breadcrumbSelf = companyStock.name;
    });
  }

  private loadEditModel(companyStock: CompanyStock.CompanyStock) {
    this.editModel = new CompanyStockEditModel();
    this.editModel.name = companyStock.name;
    this.editModel.externalId = companyStock.externalId;
    this.editModel.location.push({
      id: companyStock.companyLocation.id,
      itemName: companyStock.companyLocation.name
    });
    if (companyStock.assigneeUserGroupId) {
      this.editModel.userGroup.push({
        id: companyStock.assigneeUserGroupId,
        itemName: ''
      });
    }
  }

  private loadDetailModel(companyStock: CompanyStock.CompanyStock) {
    this.detailModel = new CompanyStockDetailModel();
    this.detailModel.name = companyStock.name;
    this.detailModel.externalId = companyStock.externalId;
    this.detailModel.locationName = companyStock.companyLocation.name;
    if (companyStock.assigneeUserGroupId) {
      this.detailModel.userGroup = {
        id: companyStock.assigneeUserGroupId,
        itemName: ''
      };
    }
  }

  loadLocations(q?: string) {
    this.locationService.query({
        name: q,
        disabled: false,
        orders: Set.of({field: CompanyLocation.OrderField.NAME, type: OrderType.ASC}),
        paging: {
          numberOfItems: UiConstants.autocompletePageSize,
          pageNumber: 1
        },
        noProgressBar: true
      })
      .subscribe((result: QueryResult<CompanyLocation.CompanyLocation>) => {
        this.locationItems = result.items.toArray().map(l => ({
          id: l.id,
          itemName: l.place.postalAddress
            ? l.name + ' - ' + l.company.name
              + ' (' + Address.PostalAddressMapper.toString(l.place.postalAddress, this.postalAddressFormat) + ')'
            : l.name + ' - ' + l.company.name
        }));
      });
  }

  loadUserGroups(q?: string) {
    if (this.componentState.isEditable()) {
      this.userGroupService.query({
        name: q,
        disabled: false,
        order: Services.createOrderFieldParameter(UserGroup.Keys.toOrderFieldKey, Set.of(UserGroup.DEFAULT_ORDER)),
        number_of_items: UiConstants.autocompletePageSize,
        page_number: 1,
        no_progress_bar: true
      })
        .subscribe(
          (result: ResourceQueryResult<UserGroup>) => {
            this.userGroupList = result.items.map(ug => ({id: ug.id, itemName: ug.name}));
            if (this.editModel.userGroup.length > 0) {
              const selected = this.userGroupList.find(ug => ug.id === this.editModel.userGroup[0].id);
              if (selected) {
                this.editModel.userGroup[0] = selected;
              }
              else {
                this.userGroupService.get({id: this.editModel.userGroup[0].id}).subscribe((result: UserGroup) => {
                  this.editModel.userGroup[0] = {
                    id: result.id,
                    itemName: result.name,
                    disabled: result.disabled
                  };
                });
              }
            }
          });
    }
    else if (this.detailModel.userGroup) {
      this.userGroupService.get({id: this.detailModel.userGroup.id}).subscribe((result: UserGroup) => {
        this.detailModel.userGroup = {
          id: result.id,
          itemName: result.name,
          disabled: result.disabled
        };
      });
    }
  }

  submit() {
    this.selectedLocationInput.control.updateValueAndValidity();
    this.selectedUserGroupInput.control.updateValueAndValidity();
    if (this.componentState.isCreateView()) {
      this.create();
    }
    else if (this.componentState.isEditView()) {
      this.update();
    }
  }

  create() {
    if (this.hasLocalFieldError()) {
      return;
    }

    this.companyStockService.create({
      name: this.editModel.name,
      externalId: Strings.undefinedOrNonEmpty(this.editModel.externalId),
      companyLocationId: this.editModel.location[0].id,
      assigneeUserGroupId: this.editModel.userGroup.length > 0 ? this.editModel.userGroup[0].id : undefined
    }).subscribe(
      (response: EmptyMessage) => {
        this.uiRouter.stateService.go(StateName.COMPANY_STOCK_LIST);
      },
      (error: any) => {
        const res = ObservableErrorResourceParser.parseError(error);
        this.fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
      });
  }

  update() {
    if (this.hasLocalFieldError()) {
      return;
    }
    this.companyStockService.update({
      id: this.componentState.id!,
      name: this.editModel.name,
      externalId: this.editModel.externalId,
      companyLocationId: this.editModel.location[0].id,
      assigneeUserGroupId: this.editModel.userGroup.length > 0 ? this.editModel.userGroup[0].id : undefined
    }).subscribe(
      (response: EmptyMessage) => {
        this.uiRouter.stateService.go(StateName.COMPANY_STOCK_LIST);
      },
      (error: any) => {
        const res = ObservableErrorResourceParser.parseError(error);
        this.fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);

      });
  }

  // Get corresponding dictionary key
  getHeadingDictionaryKey(): string {
    return this.componentState.getCurrentHeaderKey();
  }

  hasLocalFieldError(field?: NgModel): boolean {
    return this.validatedInputs.hasLocalError(field);
  }

  removeFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.fieldErrors, fieldError);
  }

  private loadLocalFieldValidationErrors() {
    const validatedInputs = List.of(
      this.externalId,
      this.name,
      this.selectedLocation,
      this.selectedUserGroup
    );
    this.validatedInputs = LocalFieldValidationErrorsFactory.ofFormFields(this.fForm, validatedInputs);
  }

  back() {
    window.history.back();
  }

  loadHistoryList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.historyQueryModel.currentPage;
    const order = this.historyQueryModel.getOrder();
    this.companyStockService.history({
      id: this.componentState.id!,
      withRead: true!,
      orders: Set.of(order),
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.historyQueryModel.itemsPerPage
      } : undefined
    }).subscribe((result: QueryResult<CompanyStock.HistoryItem>) => {
      this.historyList = [];
      result.items.forEach((history: CompanyStock.HistoryItem) => {
        const historyModel = new CompanyStockHistoryModel();
        historyModel.id = history.id;
        historyModel.creationTime = history.creationTime.toUtcIsoString();
        historyModel.type = history.type;
        historyModel.typeString = this.getHistoryType(history.type);
        historyModel.changeLog = history.changeLog;
        historyModel.user = history.issuerUser!;
        historyModel.applicationClassification =
          'HISTORY_APPLICATION_CLASS_TYPE_' + history.applicationClassification;
        historyModel.applicationId =
          history.mobileApplication ? history.mobileApplication.applicationId : '';
        this.historyList.push(historyModel);
      });
      this.historyQueryModel.currentPage = requestedPage;
      this.historyQueryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.historyQueryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
    });
  }

  orderBy(field: CompanyStock.OrderField) {
    this.historyQueryModel.onOrderFieldChanged(field);
    this.loadHistoryList(1);
  }

  pageChanged(selectedPage: number) {
    this.loadHistoryList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.historyQueryModel.itemsPerPage = itemsPerPage;
    this.loadHistoryList(1);
  }

  getHistoryType(selectedType: CompanyStockHistoryType): string {
    let text = '';
    this.historyTypes.forEach( (type: CompanyStockHistoryTypeItem) => {
      if (type.id === selectedType) {
        text = type.text;
      }
    });
    return text;
  }

  private loadHistoryTypes(completion: () => void) {
    this.historyTypes = [];
    CompanyStock.companyStockHistoryTypes.forEach((type) => {
      const item = {
        id: type.type,
        text: '....'
      };
      this.historyTypes.push(item);
      this.translateService.get(type.stringKey).subscribe((text: string) => {
        item.text = text;
      })
    });
    completion()
  }

}

// Used for create/edit views
class CompanyStockEditModel {
  name: string = '';
  externalId: string = '';
  location: MultiselectOptionItem<number>[] = [];
  userGroup: MultiselectOptionItem<number>[] = [];
}

// Used for detail view
class CompanyStockDetailModel {
  name: string = '';
  externalId: string = '';
  locationName = '';
  userGroup?: MultiselectOptionItem<number> = undefined;

  get userGroupName(): string {
    return this.userGroup ? this.userGroup.itemName : '';
  }
}

class CompanyStockHistoryModel extends HistoryBaseModel {
  type: CompanyStockHistoryType;
}

export class CompanyStockHistoryTypeItem extends OptionItem<CompanyStockHistoryType> {
}
