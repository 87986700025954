<div class="card">

  <div class="card-header">
    <label class="form-control-label text-break" [ngClass]="{ 'required-field-label': required }">{{ getTitle() }} </label>
    <div class="card-actions">
      <a class="btn-setting cursor-pointer" (click)="openAddDialog()"
         *ngIf="canAdd()"
         title="{{'COMMON_BUTTON_ADD' | translate}}"><i class="icomoon icomoon-multiselect-3"></i></a>
    </div><!-- /.card-actions -->
  </div>
  <div class="card-body">
    <label class="warning-text"
           *ngIf="!optionalValue && htmlForm && htmlForm.getForm().submitted && (!model.values || model.values.length === 0)">{{'FORM_RECORD_MASTER_DATA_REQUIRED_WARNING_TEXT' | translate}}</label>
    <div class="text-center text-muted p-2" *ngIf="model.values.length === 0">
      <div style="background-image: url('../../../../../../assets/img/stock/stock-item-placeholder.png')"
           class="record-picture"></div>
      <label class="mb-1" *ngIf="!nonEditable">{{'FORM_RECORD_MASTER_DATA_NO_ITEM' | translate}}</label>
      <br>
      <button type="button" class="btn btn-secondary"
              *ngIf="canAdd()"
              (click)="openAddDialog()">
        {{'COMMON_BUTTON_ADD' | translate}}
      </button>
      <label class="m-0" *ngIf="nonEditable">{{'FORM_RECORD_MASTER_DATA_NO_ITEM_READONLY' | translate}}</label>
    </div>
    <div *ngIf="model.values.length > 0">
      <table class="table table-sm table-bordered mb-0 table-striped">
        <thead>
        <tr>
          <th class="responsive-table-column">{{'COMMON_NAME' | translate}}</th>
          <th class="responsive-table-column">{{'COMMON_EXTERNAL_ID' | translate}}</th>
          <th
            class="responsive-table-column hidden-md-down">{{'COMMON_DESCRIPTION' | translate}}</th>
          <th
            class="responsive-table-column hidden-md-down" *ngIf="displayFormField()">{{displayedFormFieldName()}}</th>
          <th class="responsive-table-column w-table-options-menu"></th>
        </tr>
        </thead>
        <tbody *ngFor="let item of model.values; let idx = index;">
        <tr [ngClass]="{'disabled-item': item.disabled}">
          <td class="responsive-table-column">
            <i
              class="icomoon icomoon-geocode-partial-alt"
              *ngIf="!customerMatches(item) || !locationMatches(item)"></i>
            <a class="id-text cursor-pointer"
               href="#" onclick="return false"
               uiSref="Admin.MasterDataRecordDetail"
               [uiParams]="{ masterDataId: masterDataId, masterDataRecordId: item.masterDataRecordId }"
               title="{{'COMMON_BUTTON_DETAILS' | translate}}">
              {{ item.name }}
            </a>
          </td>
          <td class="responsive-table-column">
            <a class="id-text cursor-pointer"
               href="#" onclick="return false"
               uiSref="Admin.MasterDataRecordDetail"
               [uiParams]="{ masterDataId: masterDataId, masterDataRecordId: item.masterDataRecordId }"
               title="{{'COMMON_BUTTON_DETAILS' | translate}}">
              {{ item.externalId }}
            </a>
          </td>
          <td class="responsive-table-column hidden-md-down">
            {{item.description}}
          </td>
          <td class="responsive-table-column hidden-md-down" *ngIf="item.displayedFormField">
            <app-table-displayed-form-field-cell [model]="item.displayedFormField">
            </app-table-displayed-form-field-cell>
          </td>
          <td class="align-middle">
          <app-table-options-menu>
            <app-dropdown-item
              *ngIf="item.rights.read.hasRight()"
              [iconClass]="'icomoon-detail'"
              [titleStringKey]="'COMMON_BUTTON_DETAILS'"
              class="cursor-pointer"
              uiSref="Admin.MasterDataRecordDetail"
              [uiParams]="{ masterDataId: masterDataId, masterDataRecordId: item.masterDataRecordId }">
            </app-dropdown-item>
            <app-dropdown-item
              *ngIf="item.rights.update.hasRight()"
              [iconClass]="'icomoon-modify'"
              [titleStringKey]="'COMMON_BUTTON_EDIT'"
              class="cursor-pointer"
              uiSref="Admin.MasterDataRecordEdit"
              [uiParams]="{ masterDataId: masterDataId, masterDataRecordId: item.masterDataRecordId }">
            </app-dropdown-item>
            <app-dropdown-item
              *ngIf="!nonEditable"
              [iconClass]="'icomoon-trash'"
              [titleStringKey]="'COMMON_BUTTON_DELETE'"
              class="cursor-pointer"
              (click)="removeMasterData(idx)">
            </app-dropdown-item>
          </app-table-options-menu>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="card-footer" *ngIf="canModify()">
    <a class="id-text cursor-pointer"
       (click)="openAddDialog()"
       title="{{'COMMON_BUTTON_EDIT' | translate}}">
      {{'COMMON_BUTTON_EDIT' | translate}}
    </a>
  </div>
</div>
