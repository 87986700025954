/* eslint-disable */
import { AfterViewInit, Component, OnInit, ViewChild, } from '@angular/core';
import { Transition, UIRouter } from '@uirouter/angular';
import { OwnerUserItem, UiConstants } from '../../../util/core-utils';
import { StateName } from '../../../app.state-names';
import { InputMask } from '../../../util/input-masks';
import { FormTableCreateModel, FormTableEditModel } from '../../../util/form-table-utils';
import { FormTable, FormTableService } from '../../../lib/form/form-table.service';
import { FieldValidationError, LocalFieldValidationErrors, LocalFieldValidationErrorsFactory, } from '../../../lib/util/services';
import { FormServiceCode } from '../../../lib/form/form-service-factory';
import { NgForm, NgModel, } from '@angular/forms';
import { List } from 'immutable';
import { FormRights } from '../../../util/form/form-utils';
import { Rights } from '../../../app.rights';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { TranslateService } from '@ngx-translate/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Form } from '../../../lib/form/form.service';
import { saveAs } from 'file-saver';
import { ToasterService } from '../../../fork/angular2-toaster/src/toaster.service';
import { StringKey } from '../../../app.string-keys';

/* eslint-enable */

@Component({
  selector: 'app-form-table-edit',
  templateUrl: 'form-table-edit.component.html',
  styleUrls: ['form-table-edit.component.scss']
})
export class FormTableEditComponent implements OnInit, AfterViewInit {

  InputMask = InputMask;
  FormTable = FormTable;
  FormServiceCode = FormServiceCode;
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  formRights: FormRights = {
    formRead: Rights.FORM_TABLE_FORM_READ,
    formGroupUpdate: Rights.FORM_TABLE_FORM_GROUP_UPDATE,
    formFieldUpdate: Rights.FORM_TABLE_FORM_FIELD_UPDATE,
    formGroupCreate: Rights.FORM_TABLE_FORM_GROUP_CREATE,
    formFieldCreate: Rights.FORM_TABLE_FORM_FIELD_CREATE,
    formGroupDisable: Rights.FORM_TABLE_FORM_GROUP_DISABLE,
    formFieldDisable: Rights.FORM_TABLE_FORM_FIELD_DISABLE,
    formGroupMove: Rights.FORM_TABLE_FORM_GROUP_MOVE,
    formFieldMove: Rights.FORM_TABLE_FORM_FIELD_MOVE,
  };

  @ViewChild('f', {static: true})
  fForm: NgForm;

  @ViewChild('name', {static: true})
  nameInput: NgModel;

  @ViewChild('externalId', {static: true})
  externalIdInput: NgModel;

  model: FormTableEditModel = new FormTableCreateModel();
  ownerUsers: OwnerUserItem[] = [];
  formTableId: number;

  @ViewChild('formImportDialog', {static: true})
  formImportDialog: ModalDirective;

  private fieldErrors: FieldValidationError<FormTable.ValidatedField> =
    FieldValidationError.empty<FormTable.ValidatedField>();

  private validatedInputs: LocalFieldValidationErrors<NgModel> =
    LocalFieldValidationErrorsFactory.empty();

  constructor(
    private formTableService: FormTableService,
    private uiRouter: UIRouter,
    private toasterService: ToasterService,
    private translateService: TranslateService,
    private transition: Transition) {
    this.formTableId = this.transition.params().id;
  }

  ngOnInit() {
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('MENU_NAVBAR_FORM_TABLE').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.FORM_TABLE_LIST});
      }
    );
  }

  ngAfterViewInit(): void {
    this.loadLocalFieldValidationErrors();
    this.loadModel();
  }

  update() {
    if (this.hasLocalFieldError()) {
      this.showValidationErrorToast();
      return;
    }
    this.formTableService.update({
      formTableId: this.formTableId,
      name: this.model.name,
      externalId: this.model.externalId,
      description: this.model.description
    }).subscribe(
      (response: FormTable.FormTable) => {
        this.uiRouter.stateService.go(StateName.FORM_TABLE_LIST);
      },
      (error: any) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withForm(this.fForm);
          this.showValidationErrorToast();
        }
      });
  }

  private showValidationErrorToast() {
    this.toasterService.pop({
      timeout: UiConstants.ToastTimeoutLong,
      type: UiConstants.toastTypeError,
      title: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_TITLE),
      body: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_MESSAGE)
    });
  }

  hasLocalFieldError(field?: NgModel): boolean {
    return this.validatedInputs.hasLocalError(field);
  }

  hasFieldError(field?: FormTable.ValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: FormTable.ValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: FormTable.ValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  back() {
    window.history.back();
  }

  private loadLocalFieldValidationErrors() {
    const validatedInputs = List.of(this.nameInput, this.externalIdInput);
    this.validatedInputs = LocalFieldValidationErrorsFactory.ofFormFields(this.fForm, validatedInputs);
  }

  private loadModel() {
    this.formTableService.get({
      formTableId: this.formTableId
    }).subscribe(
      (formTable: FormTable.FormTable) => {
        this.breadcrumbSelf = formTable.name;
        this.model = formTable;
      });
  }

  get formService(): Form.Service {
    return this.formTableService;
  }

  exportForm() {
    this.formTableService.exportForm({
      parentId: this.formTableId
    }).subscribe((result: Form.FormImportDocument) => {
      const blob = new Blob([JSON.stringify(result)], {type: 'text/json;charset=utf-8'});
      saveAs(blob, 'form_table_' + this.formTableId + '.json');
    });
  }

}
