
import { of as observableOf,  Observable } from 'rxjs';
import { DqlModel } from '../dql/dql.model';
import { Map } from 'immutable';

/* eslint-enable */

export class ColonnadeDqlService {

  public static getDqlModel(): Observable<DqlModel.QueryableModel> {
    const queryableModel: DqlModel.QueryableModel = {
      dynamicFields: [],
      staticFields: [
        {
          dataType: DqlModel.QueryableFieldDataType.STRING,
          fieldTitle: 'external_id'
        },
        {
          dataType: DqlModel.QueryableFieldDataType.CUSTOMER,
          fieldTitle: 'customer_record_id'
        },
        {
          dataType: DqlModel.QueryableFieldDataType.USER,
          fieldTitle: 'submitter_user_id'
        },
        {
          dataType: DqlModel.QueryableFieldDataType.DATE_TIME,
          fieldTitle: 'submit_time'
        },
        {
          dataType: DqlModel.QueryableFieldDataType.STRING,
          fieldTitle: 'form.insurance_product.text'
        },
        {
          dataType: DqlModel.QueryableFieldDataType.STRING,
          fieldTitle: 'form.contracting_customer_record.external_id'
        },
        {
          dataType: DqlModel.QueryableFieldDataType.STRING,
          fieldTitle: 'form.sponsor.text'
        },
        {
          dataType: DqlModel.QueryableFieldDataType.ENUM,
          fieldTitle: 'form.payment_type.text.keyword', // sorry
          values: ['Csoportos beszedés',
            'SIPO',
            'Átutalás',
            'Munkabérből levonás']
        },
        {
          dataType: DqlModel.QueryableFieldDataType.ENUM,
          fieldTitle: 'state.keyword', // sorry
          values: [
            // 'IN_PROGRESS', // currently not used by admin
            'SUBMITTED',
            'DELETED',
          ]
        },
        {
          dataType: DqlModel.QueryableFieldDataType.DATE,
          fieldTitle: 'form.deletion_date'
        },
        {
          dataType: DqlModel.QueryableFieldDataType.STRING,
          fieldTitle: 'form.deletion_cause'
        },
        {
          dataType: DqlModel.QueryableFieldDataType.STRING,
          fieldTitle: 'form.insurance_type.text'
        },
        {
          dataType: DqlModel.QueryableFieldDataType.NUMBER,
          fieldTitle: 'form.insurance_level.level'
        },
      ],
      hasMultipleModels: false
    };
    return observableOf(queryableModel);
  }

  public static getHelper(): DqlModel.DqlStaticFieldTextHelper {
    const fields: Map<string, DqlModel.DqlFieldTexts> = Map.of(
      'external_id', {stringKey: 'INSURANCE_LIST_TABLE_HEADER_EXTERNAL_ID'},
      'customer_record_id', {stringKey: 'INSURANCE_LIST_TABLE_HEADER_CLIENT_NAME'},
      'submitter_user_id', {stringKey: 'INSURANCE_LIST_TABLE_HEADER_SUBMITTER_NAME'},
      'submit_time', {stringKey: 'INSURANCE_LIST_TABLE_HEADER_SUBMIT_TIME'},
      'form.insurance_product.text', {stringKey: 'INSURANCE_FORM_INSURANCE_PRODUCT_TYPE'},
      'form.insurance_type.text', {stringKey: 'INSURANCE_FORM_INSURANCE_MODE'},
      'form.insurance_level.level', {stringKey: 'INSURANCE_FORM_INSURANCE_LEVEL'},
      'form.contracting_customer_record.external_id', {stringKey: 'INSURANCE_FORM_CONTRACTOR_EXTERNAL_ID'},
      'form.sponsor.text', {stringKey: 'INSURANCE_FORM_SPONSOR'},
      'form.deletion_date', {stringKey: 'INSURANCE_FORM_DELETION_DATE'},
      'form.deletion_cause', {stringKey: 'INSURANCE_FORM_DELETION_CAUSE'},
      'form.payment_type.text.keyword', { // sorry
        stringKey: 'INSURANCE_FORM_PAYMENT_TYPE', enumType: [
          {id: 'Csoportos beszedés', stringKey: 'INSURANCE_FORM_PAYMENT_TYPE_GROUP'},
          {id: 'SIPO', stringKey: 'SIPO'},
          {id: 'Átutalás', stringKey: 'INSURANCE_FORM_PAYMENT_TYPE_TRANSFER'},
          {id: 'Munkabérből levonás', stringKey: 'INSURANCE_FORM_PAYMENT_TYPE_PAYROLL'},
        ]
      },
      'state.keyword', { // sorry
        stringKey: 'INSURANCE_STATE', enumType: [
          // {id: 'IN_PROGRESS', stringKey: 'INSURANCE_STATE_IN_PROGRESS'},
          {id: 'SUBMITTED', stringKey: 'INSURANCE_STATE_SUBMITTED'},
          {id: 'DELETED', stringKey: 'INSURANCE_STATE_DELETED'},
        ]
      },
    );
    return new DqlModel.DqlStaticFieldTextHelper(fields);
  }

}
