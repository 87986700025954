/* eslint-disable */
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Transition, UIRouter } from '@uirouter/angular';
import { StateName } from '../../../app.state-names';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { Vehicle, VehicleService } from '../../../lib/vehicles/vehicle.service';
import { InputMask } from '../../../util/input-masks';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Models } from '../../../util/model-utils';
import {
  FieldValidationError,
  ForwardingNgFormRef,
  LocalFieldValidationErrors,
  LocalFieldValidationErrorsFactory,
  OrderType,
  QueryResult,
  Services
} from '../../../lib/util/services';
import { ComponentStateResolver } from '../../../util/component-state/component-state-resolver';
import { FormBuilder, FormGroup, NgForm, NgModel, Validators } from '@angular/forms';
import { List, Set } from 'immutable';
import { MultiselectOptionItem, OptionItem, QueryFieldModel, SelectUtils, UiConstants } from '../../../util/core-utils';
import { Company, CompanyService } from '../../../lib/company/company.service';
import { Strings } from '../../../lib/util/strings';
import { FieldError } from '../../../lib/util/errors';
import { EmptyMessage, IdentityMessage } from '../../../lib/util/messages';
import { RightModel } from '../../../app.rights';
import { RightResolver, RightService } from '../../../lib/right.service';
import { HistoryDetailDialogComponent } from '../../history-log/history-detail-dialog/history-detail-dialog.component';
import { HistoryBaseModel, HistoryLogComponent } from '../../history-log/history-log/history-log.component';
import VehicleHistoryType = Vehicle.VehicleHistoryType;
import VehiclePass = Vehicle.VehiclePass;
import VehiclePassType = Vehicle.VehiclePassType;
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgbDatePickerParserFormatter } from '../../../util/ngb-datepicker';
import { StringKey } from '../../../app.string-keys';
import { ToasterService } from '../../../fork/angular2-toaster/src/toaster.service';
import { Angular2Multiselects } from '../../../util/multiselect';
import { CompanyMultiselectProvider } from '../../../lib/company/company-multiselect.provider';

/* eslint-enable */

@Component({
  selector: 'app-vehicle-base',
  templateUrl: './vehicle-base.component.html',
  styleUrls: ['./vehicle-base.component.scss']
})
export class VehicleBaseComponent implements OnInit, AfterViewInit {

  Vehicle = Vehicle;
  SelectUtils = SelectUtils;
  UiConstants = UiConstants;
  InputMask = InputMask;

  // Variables to check the current mode of the component
  componentState: ComponentStateResolver;

  editModel: VehicleEditModel;
  detailModel: VehicleDetailModel;
  get relevantModel(): VehicleEditModel | VehicleDetailModel {
    return this.componentState.isEditable() ? this.editModel : this.detailModel;
  }

  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;

  dropdownSettings: Angular2Multiselects.Settings;
  companies: MultiselectOptionItem<number>[] = [];

  rightModel: RightModel = RightModel.empty();

  // Form for validation
  @ViewChild('f', { static: true })
  fForm: NgForm;

  @ViewChild('externalId')
  externalId: NgModel;

  @ViewChild('manufacturer')
  manufacturer: NgModel;

  @ViewChild('model')
  model: NgModel;

  @ViewChild('licencePlate')
  licencePlate: NgModel;

  @ViewChild('registrationDateModel')
  registrationDateModel: NgModel;

  @ViewChild('payloadKg')
  payloadKg: NgModel;

  @ViewChild('totalWeightKg')
  totalWeightKg: NgModel;

  @ViewChild('kmCounter')
  kmCounter: NgModel;

  @ViewChild('etollRequired')
  etollRequired: NgModel;

  @ViewChild('registrationType')
  registrationType: NgModel;

  @ViewChild('ownerPersonName')
  ownerPersonName: NgModel;

  @ViewChild('company', { static: true })
  company: NgModel;

  @ViewChild('maturityExpiryDateModel')
  maturityExpiryDate: NgModel;

  @ViewChild('historyComponent')
  historyComponent: HistoryLogComponent;

  @ViewChild('vehiclePassDialog', { static: true })
  vehiclePassDialog: ModalDirective;
  vehiclePassDialogVisible: boolean = false;
  vehiclePassDialogReadonly?: boolean = true;
  selectedVehiclePass: VehiclePassModel = new VehiclePassModel();
  selectedVehiclePassIndex?: number;
  vehiclePassFormGroup: FormGroup;

  @ViewChild('vehiclePassF')
  vehiclePassForm: NgForm;

  @ViewChild('historyDetailModal', { static: true })
  historyDetailModal: HistoryDetailDialogComponent;
  private fieldErrors: FieldValidationError<Vehicle.ValidatedField> =
    FieldValidationError.empty<Vehicle.ValidatedField>();

  private validatedInputs: LocalFieldValidationErrors<NgModel> =
    LocalFieldValidationErrorsFactory.empty();

  historyList: VehicleHistoryModel[] = [];

  passTypes: VehiclePassTypeItem[];
  historyTypes: VehicleHistoryTypeItem[];

  historyQueryModel: QueryFieldModel<Vehicle.OrderField> = new QueryFieldModel(Vehicle.OrderField.HISTORY_CREATION_TIME, OrderType.DESC);

  constructor(
    private uiRouter: UIRouter,
    private translateService: TranslateService,
    private transition: Transition,
    private vehicleService: VehicleService,
    private companyService: CompanyService,
    private companyMultiselectProvider: CompanyMultiselectProvider,
    private rightService: RightService,
    private fb: FormBuilder,
    private toasterService: ToasterService,
    private datePickerParserFormatter: NgbDatePickerParserFormatter) {
    this.componentState = new ComponentStateResolver(uiRouter, transition,
      'id',
      {stateName: StateName.VEHICLE_CREATE, stateHeaderKey: 'VEHICLE_CREATE'},
      {stateName: StateName.VEHICLE_EDIT, stateHeaderKey: 'VEHICLE_EDIT'},
      {stateName: StateName.VEHICLE_DETAIL, stateHeaderKey: 'VEHICLE_DETAIL'});
    this.vehiclePassFormGroup = this.createFormGroup(this.fb);
  }

  ngOnInit() {
    // this.loadLocalFieldValidationErrors();
    this.initComponentState(); // Must be called first
    this.initBreadcrumb();
    this.initDropdown();
    this.loadRightModels();
  }

  ngAfterViewInit(): void {
    this.loadLocalFieldValidationErrors();
  }

  initBreadcrumb() {
    if (this.componentState.isCreateView()) {
      this.translateService.get('VEHICLE_CREATE').subscribe(
        (result: string) => {
          this.breadcrumbSelf = result;
        }
      );
    }

    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('MENU_NAVBAR_NAV_TITLE_VEHICLES').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.VEHICLE_LIST});
      }
    );
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  private initComponentState() {
    if (!this.componentState.isDetailView()) {
      this.editModel = new VehicleEditModel();
      if (this.componentState.isCreateView()) {
        this.loadCompanies();
      }
    }
    else {
      this.detailModel = new VehicleDetailModel();
    }
    this.loadPassTypes();
    if (this.componentState.id) {
      this.breadcrumbSelf = this.componentState.id.toString();
      this.loadModel();
    }
  }

  private initDropdown() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .remoteSearch(true)
      .build();
  }

  private loadModel() {
    this.vehicleService.get({
      id: this.componentState.id!
    }).subscribe((vehicle: Vehicle.Vehicle) => {
      if (this.componentState.isEditView()) {
        this.editModel = new VehicleEditModel();
        this.loadEditModel(vehicle);
      }
      else if (this.componentState.isDetailView()) {
        this.detailModel = new VehicleDetailModel();
        this.loadDetailModel(vehicle);
        if (this.rightModel.vehicleHistoryLog.hasRight()) {
          this.loadHistoryTypes(() => {
            this.loadHistoryList()
          });
        }
      }


    });
  }

  back() {
    window.history.back();
  }

  private loadEditModel(vehicle: Vehicle.Vehicle) {

    this.editModel = new VehicleEditModel();
    this.editModel.externalId = vehicle.externalId;
    this.editModel.manufacturer = vehicle.manufacturer ? vehicle.manufacturer : '';
    this.editModel.model = vehicle.model ? vehicle.model : '';
    this.editModel.licencePlate = vehicle.licencePlate;
    this.editModel.registrationDate = Models.localDateToNgbDate(vehicle.registrationDate);
    this.editModel.payloadKg = Models.numberToString(vehicle.payloadKg);
    this.editModel.totalWeightKg = Models.numberToString(vehicle.totalWeightKg);
    this.editModel.numberOfSeats = Models.numberToString(vehicle.numberOfSeats);
    this.editModel.color = vehicle.color ? vehicle.color : '';
    this.editModel.comment = vehicle.comment ? vehicle.comment : '';
    this.editModel.etollRequired = vehicle.etollRequired;
    this.editModel.registrationType = vehicle.registrationType ? vehicle.registrationType : '';
    this.editModel.ownerPersonName = vehicle.ownerPersonName ? vehicle.ownerPersonName : '';
    this.editModel.keeperPersonName = vehicle.keeperPersonName ? vehicle.keeperPersonName : '';
    this.editModel.maturityExpiryDate = vehicle.maturityExpiryDate ? Models.localDateToNgbDate(vehicle.maturityExpiryDate) : null;
    const kmCounter = Models.numberToString(vehicle.kmCounter);
    this.editModel.kmCounter = kmCounter!;
    this.loadCompanies(undefined, vehicle.company.id);
    this.editModel.passes = [];
    vehicle.passes.forEach(p => {
      this.editModel.passes.push({
        type: p.type,
        name: p.name,
        validityStartDate: this.datePickerParserFormatter.fromOffsetDateTime(p.validityStartDate),
        validityEndDate: this.datePickerParserFormatter.fromOffsetDateTime(p.validityEndDate),
        note: p.note ? p.note : ''
      });
    });
  }

  private loadDetailModel(vehicle: Vehicle.Vehicle) {

    const registrationDate = Services.localDateToString(vehicle.registrationDate);
    const maturityExpiryDate = Services.localDateToString(vehicle.maturityExpiryDate);

    this.detailModel = new VehicleDetailModel();
    this.detailModel.externalId = vehicle.externalId;
    this.detailModel.manufacturer = vehicle.manufacturer ? vehicle.manufacturer : '';
    this.detailModel.model = vehicle.model ? vehicle.model : '';
    this.detailModel.licencePlate = vehicle.licencePlate;
    this.detailModel.registrationDate = registrationDate ? registrationDate : '';
    this.detailModel.payloadKg = Models.numberToString(vehicle.payloadKg) + ' kg';
    this.detailModel.totalWeightKg = Models.numberToString(vehicle.totalWeightKg) + ' kg';
    this.detailModel.numberOfSeats = Models.numberToString(vehicle.numberOfSeats);
    this.detailModel.color = vehicle.color ? vehicle.color : '';
    this.detailModel.comment = vehicle.comment ? vehicle.comment : '';
    this.detailModel.etollRequired = vehicle.etollRequired;
    this.detailModel.etollRequiredText = vehicle.etollRequired ? 'COMMON_YES' : 'COMMON_NO';
    this.detailModel.registrationType = vehicle.registrationType ? vehicle.registrationType : '';
    this.detailModel.ownerPersonName = vehicle.ownerPersonName ? vehicle.ownerPersonName : '';
    this.detailModel.keeperPersonName = vehicle.keeperPersonName ? vehicle.keeperPersonName : '';
    this.detailModel.maturityExpiryDate = maturityExpiryDate ? maturityExpiryDate : '';
    const kmCounter = Models.numberToString(vehicle.kmCounter);
    this.detailModel.kmCounter = kmCounter!;
    this.loadCompanyForDetail(vehicle.company.id);
    this.detailModel.passes = [];
    vehicle.passes.forEach(p => {
      this.detailModel.passes.push({
        type: p.type,
        name: p.name,
        validityStartDate: this.datePickerParserFormatter.fromOffsetDateTime(p.validityStartDate),
        validityEndDate: this.datePickerParserFormatter.fromOffsetDateTime(p.validityEndDate),
        note: p.note ? p.note : ''
      });
    });
  }

  loadCompanies(q?: string, id?: number) {
    this.companyService.query({
      name: q ? Strings.undefinedOrNonEmpty(q) : undefined,
      disabled: false,
      orders: Set.of({field: Company.OrderField.NAME, type: OrderType.ASC}),
      paging: {
        pageNumber: 1,
        numberOfItems: UiConstants.autocompletePageSize
      },
      noProgressBar: true
    }).subscribe((result: QueryResult<Company.Company>) => {
      this.companies = result.items.toArray().map(c => ({id: c.id, itemName: c.name, disabled: c.disabled}));
      if (id) {
        const company = this.companies.find(c => c.id === id);
        if (company) {
          this.editModel.company.push(company);
        }
        else {
          this.companyService.get({
            id: id
          }).subscribe(result => {
            this.editModel.company.push({
              id: result.id,
              itemName: result.name,
              disabled: result.disabled
            });
          });
        }
      }
      this.fForm.controls['company'].updateValueAndValidity();
    });

  }

  private loadCompanyForDetail(companyId: number) {
    this.companyMultiselectProvider.getById(companyId)
      .subscribe(result => this.detailModel.company.push(result));
  }

  // Get corresponding dictionary key
  getHeadingDictionaryKey(): string {
    return this.componentState.getCurrentHeaderKey();
  }

  submit() {
    if (this.hasLocalFieldError()) {
      this.showValidationErrorToast();
      return;
    }
    if (this.componentState.isCreateView()) {
      this.create();
    }
    else if (this.componentState.isEditView()) {
      this.update();
    }
  }

  create() {
    const registrationDate = Models.ngbDateToLocalDate(this.editModel.registrationDate);
    const passes: VehiclePass[] = [];
    this.editModel.passes.forEach(p => {
      passes.push({
        type: p.type!,
        name: p.name,
        validityStartDate: this.datePickerParserFormatter.toOffsetDateTime(p.validityStartDate),
        validityEndDate: this.datePickerParserFormatter.toOffsetDateTime(p.validityEndDate),
        note: Strings.undefinedOrNonEmpty(p.note)
      });
    });
    this.vehicleService.create({
      externalId: Strings.undefinedOrNonEmpty(this.editModel.externalId),
      manufacturer: this.editModel.manufacturer,
      model: this.editModel.model,
      licencePlate: this.editModel.licencePlate,
      registrationDate: registrationDate,
      registrationType: this.editModel.registrationType,
      payloadKg: Models.parseNumber(this.editModel.payloadKg)!,
      totalWeightKg: Models.parseNumber(this.editModel.totalWeightKg)!,
      numberOfSeats: Models.parseNumber(this.editModel.numberOfSeats),
      color: Strings.undefinedOrNonEmpty(this.editModel.color),
      comment: Strings.undefinedOrNonEmpty(this.editModel.comment),
      etollRequired: this.editModel.etollRequired,
      ownerPersonName: this.editModel.ownerPersonName,
      keeperPersonName: Strings.undefinedOrNonEmpty(this.editModel.keeperPersonName),
      maturityExpiryDate: this.editModel.maturityExpiryDate ? Models.ngbDateToLocalDate(this.editModel.maturityExpiryDate) : undefined,
      companyId: this.editModel.companyId!,
      kmCounter: Models.parseNumber(this.editModel.kmCounter)!,
      passes: passes
    }).subscribe(
      (response: IdentityMessage) => {
        this.uiRouter.stateService.go(StateName.VEHICLE_LIST);
      },
      (error: any) => {
        if (error instanceof FieldValidationError) {
          this.onFieldValidationError(error.withForm(this.fForm));
        }
      });
  }

  update() {
    const registrationDate = Models.ngbDateToLocalDate(this.editModel.registrationDate);
    const passes: VehiclePass[] = [];
    this.editModel.passes.forEach(p => {
      passes.push({
        type: p.type!,
        name: p.name,
        validityStartDate: this.datePickerParserFormatter.toOffsetDateTime(p.validityStartDate),
        validityEndDate: this.datePickerParserFormatter.toOffsetDateTime(p.validityEndDate),
        note: Strings.undefinedOrNonEmpty(p.note)
      });
    });
    this.vehicleService.update({
      id: this.componentState.id!,
      externalId: this.editModel.externalId,
      manufacturer: this.editModel.manufacturer,
      model: this.editModel.model,
      licencePlate: this.editModel.licencePlate,
      registrationDate: registrationDate,
      registrationType: this.editModel.registrationType,
      payloadKg: Models.parseNumber(this.editModel.payloadKg)!,
      totalWeightKg: Models.parseNumber(this.editModel.totalWeightKg)!,
      numberOfSeats: Models.parseNumber(this.editModel.numberOfSeats),
      color: Strings.undefinedOrNonEmpty(this.editModel.color),
      comment: Strings.undefinedOrNonEmpty(this.editModel.comment),
      etollRequired: this.editModel.etollRequired,
      ownerPersonName: this.editModel.ownerPersonName,
      keeperPersonName: Strings.undefinedOrNonEmpty(this.editModel.keeperPersonName),
      maturityExpiryDate: this.editModel.maturityExpiryDate ? Models.ngbDateToLocalDate(this.editModel.maturityExpiryDate) : undefined,
      companyId: this.editModel.companyId!,
      kmCounter: Models.parseNumber(this.editModel.kmCounter)!,
      passes: passes
    }).subscribe(
      (response: EmptyMessage) => {
        this.uiRouter.stateService.go(StateName.VEHICLE_LIST);
      },
      (error: any) => {
        if (error instanceof FieldValidationError) {
          this.onFieldValidationError(error.withForm(this.fForm));
        }
      });
  }

  onFieldValidationError(fieldErrors: FieldValidationError<Vehicle.ValidatedField>) {
    this.fieldErrors = fieldErrors;
    this.showValidationErrorToast();
  }

  private showValidationErrorToast() {
    this.toasterService.pop({
      timeout: UiConstants.ToastTimeoutLong,
      type: UiConstants.toastTypeError,
      title: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_TITLE),
      body: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_MESSAGE)
    });
  }

  hasLocalFieldError(field?: NgModel): boolean {
    return this.validatedInputs.hasLocalError(field);
  }

  hasFieldError(field: Vehicle.ValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: Vehicle.ValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: Vehicle.ValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  private loadLocalFieldValidationErrors() {
    const validatedInputs = List.of(
      this.externalId,
      this.licencePlate,
      this.kmCounter,
      this.etollRequired,
      this.company
    );
    this.validatedInputs = LocalFieldValidationErrorsFactory.ofFormFields(this.fForm, validatedInputs);
  }

  loadHistoryList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.historyQueryModel.currentPage;
    const order = this.historyQueryModel.getOrder();
    this.vehicleService.history({
      id: this.componentState.id!,
      withRead: true!,
      orders: Set.of(order),
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.historyQueryModel.itemsPerPage
      } : undefined
    }).subscribe((result: QueryResult<Vehicle.HistoryItem>) => {
      this.historyList = [];
      result.items.forEach((history: Vehicle.HistoryItem) => {
        const historyModel = new VehicleHistoryModel();
        historyModel.id = history.id;
        historyModel.creationTime = history.creationTime.toUtcIsoString();
        historyModel.type = history.type;
        historyModel.typeString = this.getHistoryType(history.type);
        historyModel.changeLog = history.changeLog;
        historyModel.user = history.issuerUser!;
        historyModel.applicationClassification =
          'HISTORY_APPLICATION_CLASS_TYPE_' + history.applicationClassification;
        historyModel.applicationId =
          history.mobileApplication ? history.mobileApplication.applicationId : '';
        this.historyList.push(historyModel);
      });
      this.historyQueryModel.currentPage = requestedPage;
      this.historyQueryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.historyQueryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
    });
  }

  orderBy(field: Vehicle.OrderField) {
    this.historyQueryModel.onOrderFieldChanged(field);
    this.loadHistoryList(1);
  }

  pageChanged(selectedPage: number) {
    this.loadHistoryList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.historyQueryModel.itemsPerPage = itemsPerPage;
    this.loadHistoryList(1);
  }

  getPassType(type: VehiclePassType): string {
    let text = '';
    this.passTypes.forEach((t: VehiclePassTypeItem) => {
      if (t.id === type) {
        text = t.text;
      }
    });
    return text;
  }

  private loadPassTypes() {
    this.passTypes = [];
    Vehicle.vehiclePassTypes.forEach((type) => {
      const item = {
        id: type.type,
        text: '....'
      };
      this.passTypes.push(item);
      this.translateService.get(type.stringKey).subscribe((text: string) => {
        item.text = text;
      })
    });
  }

  getHistoryType(selectedType: VehicleHistoryType): string {
    let text = '';
    this.historyTypes.forEach((type: VehicleHistoryTypeItem) => {
      if (type.id === selectedType) {
        text = type.text;
      }
    });
    return text;
  }

  private loadHistoryTypes(completion: () => void) {
    this.historyTypes = [];
    Vehicle.vehicleHistoryTypes.forEach((type) => {
      const item = {
        id: type.type,
        text: '....'
      };
      this.historyTypes.push(item);
      this.translateService.get(type.stringKey).subscribe((text: string) => {
        item.text = text;
      })
    });
    completion();
  }

  openPassCreate() {
    this.vehiclePassDialogReadonly = undefined;
    this.selectedVehiclePass = new VehiclePassModel();
    this.selectedVehiclePassIndex = undefined;
    this.vehiclePassFormGroup = this.createFormGroup(this.fb);
    this.showVehiclePassDialog();
  }

  openPassDetail(index: number) {
    this.vehiclePassDialogReadonly = true;
    this.selectedVehiclePass = this.componentState.isDetailView() ? this.detailModel.passes[index] : this.editModel.passes[index];
    this.selectedVehiclePassIndex = index;
    this.showVehiclePassDialog();
  }

  openPassEdit(index: number) {
    this.vehiclePassDialogReadonly = false;
    this.selectedVehiclePass = Object.assign({}, this.editModel.passes[index]);
    this.selectedVehiclePassIndex = index;
    this.showVehiclePassDialog();
  }

  showVehiclePassDialog() {
    this.vehiclePassDialogVisible = true;
    this.vehiclePassDialog.show();
  }

  closeVehiclePassDialog() {
    this.vehiclePassDialogVisible = false;
    this.vehiclePassDialog.hide();
  }

  savePass() {
    if (!this.vehiclePassFormGroup.valid) {
      this.vehiclePassFormGroup.get('type')!.markAsTouched();
      this.vehiclePassFormGroup.get('name')!.markAsTouched();
      this.vehiclePassFormGroup.get('validityStartDate')!.markAsTouched();
      this.vehiclePassFormGroup.get('validityEndDate')!.markAsTouched();
      return;
    }
    if (this.selectedVehiclePassIndex !== undefined) {
      this.editModel.passes[this.selectedVehiclePassIndex] = this.selectedVehiclePass;
    }
    else {
      this.editModel.passes.push(this.selectedVehiclePass);
    }
    this.closeVehiclePassDialog();
  }

  deletePass(index: number) {
    this.editModel.passes.splice(index, 1);
  }

  private createFormGroup(fb: FormBuilder): FormGroup {
    return fb.group(
      {
        type: fb.control(
          {value: this.selectedVehiclePass.type},
          Validators.required
        ),
        name: fb.control(
          {value: this.selectedVehiclePass.name},
            Validators.required
        ),
        validityStartDate: fb.control(
          {value: this.selectedVehiclePass.validityStartDate},
          Validators.required
        ),
        validityEndDate: fb.control(
          {value: this.selectedVehiclePass.validityEndDate},
          Validators.required
        ),
      }
    );
  }

  private createForwardingHtmlForm() {
    return new ForwardingNgFormRef({
      formFn: () => {
        return this.vehiclePassForm;
      }
    });
  }
}

// Used for create/edit views
class VehicleEditModel {

  externalId: string = '';
  manufacturer: string = '';
  model: string = '';
  licencePlate: string = '';
  registrationDate: NgbDateStruct | null = null;
  payloadKg: string = '';
  totalWeightKg: string = '';
  numberOfSeats: string = '';
  color: string = '';
  comment: string = '';
  etollRequired: boolean = false;
  registrationType: string = '';
  ownerPersonName: string = '';
  keeperPersonName: string = '';
  maturityExpiryDate: NgbDateStruct | null = null;
  company: MultiselectOptionItem<number>[] = [];
  kmCounter: string = '';
  passes: VehiclePassModel[] = [];

  get companyId(): number | undefined {
    return this.company.length === 1 ? this.company[0].id : undefined;
  }

}

// Used for detail view
class VehicleDetailModel {
  externalId: string = '';
  manufacturer: string = '';
  model: string = '';
  licencePlate: string = '';
  registrationDate: string = '';
  payloadKg: string = '';
  totalWeightKg: string = '';
  numberOfSeats: string = '';
  color: string = '';
  comment: string = '';
  etollRequired: boolean = false;
  etollRequiredText: string = '';
  registrationType: string = '';
  ownerPersonName: string = '';
  keeperPersonName: string = '';
  maturityExpiryDate: string = '';
  company: MultiselectOptionItem<number>[] = [];
  kmCounter: string;
  passes: VehiclePassModel[];
}

class VehicleHistoryModel extends HistoryBaseModel {
  type: VehicleHistoryType;
}

export class VehiclePassTypeItem extends OptionItem<VehiclePassType> {
}

export class VehicleHistoryTypeItem extends OptionItem<VehicleHistoryType> {
}

class VehiclePassModel {
  type: VehiclePassType | null = null;
  name: string = '';
  validityStartDate: NgbDateStruct | null = null;
  validityEndDate: NgbDateStruct | null = null;
  note: string = '';
}

interface VehiclePassFieldErrorMap {
  type?: FieldError;
  name?: FieldError;
  validityStartDate?: FieldError;
  validityEndDate?: FieldError;
}
