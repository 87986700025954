/* eslint-disable */
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, } from '@angular/core';
import { Transition, UIRouter } from '@uirouter/angular';
import { combineLatest, Observable, Subject } from 'rxjs';
import { TaskRecord, TaskRecordService } from '../../../../lib/task/task-record.service';
import { FieldValidationError, QueryResult } from '../../../../lib/util/services';
import { StateName } from '../../../../app.state-names';
import { TaskRecordBase } from '../task-record-base/task-record-base-view';
import { TaskRecordBaseComponent } from '../task-record-base/task-record-base.component';
import { NgForm } from '@angular/forms';
import { FormRecordContainer } from '../../../form/form-record/form-record-container';
import { FormRecordContainerComponent } from '../../../form/form-record/form-record-container.component';
import { Task, TaskService } from '../../../../lib/task/task.service';
import { ConfigurationService } from '../../../../lib/core-ext/configuration.service';
import { Form } from '../../../../lib/form/form.service';
import { LoadingHandler } from '../../../../lib/loading-handler';
import { NgbDatePickerParserFormatter } from '../../../../util/ngb-datepicker';
import { Country, CountryService } from '../../../../lib/country.service';
import { AddressModel } from '../../../../lib/address';
import { StringKey } from '../../../../app.string-keys';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '../../../../fork/angular2-toaster/angular2-toaster';
import { UiConstants } from '../../../../util/core-utils';
import { BreadcrumbParent } from '../../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmLeaveModalComponent } from '../../../../shared/confirm-leave-modal/confirm-leave-modal.component';
import { RightResolver, RightService } from '../../../../lib/right.service';
import { RightModel } from '../../../../app.rights';
import { CustomerRecord } from '../../../../lib/customer/customer-record.service';
import { IdentityMessage } from '../../../../lib/util/messages';
import { TaskRecordFormFieldUpdater } from '../task-record-base/task-record-form-field-updater';
import { SiteTourService } from '../../../../lib/site-tour/site-tour.service';
import { SiteTourBaseComponent, SiteTourId } from '../../../../lib/site-tour/site-tour.factory';
import { AppTypeHelperService } from '../../../../lib/util/app-type-helper.service';
import { User } from '../../../../lib/user.service';
import { UserMeService } from '../../../../lib/user/user-me.service';
import TaskDefaultAssigneeType = Task.TaskDefaultAssigneeType;
import TaskRecordCreateOptions = TaskRecordBase.TaskRecordCreateOptions;
import {
  CustomerRecordBillingInfoComponentMode
} from '../../../customer/customer-record-billing-info/customer-record-billing-info-base/customer-record-billing-info-base.component';
import { Strings } from '../../../../lib/util/strings';

/* eslint-enable */

@Component({
  selector: 'app-new-task-record-create',
  templateUrl: 'task-record-create.component.html',
  styleUrls: ['task-record-create.component.scss'],
})
export class TaskRecordCreateComponent implements OnInit, AfterViewInit, OnDestroy, SiteTourBaseComponent {
  CustomerRecordBillingInfoDialogMode = CustomerRecordBillingInfoComponentMode;

  @ViewChild(TaskRecordBaseComponent, {static: true})
  baseView: TaskRecordBase.View;

  @ViewChild(FormRecordContainerComponent, {static: true})
  formRecordContainerView: FormRecordContainer.View;

  @ViewChild('f', {static: true})
  fForm: NgForm;

  forceSaveButton: boolean = false;

  private taskId: number;
  private combinedResult?: CombinedResult;
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  taskName: string;

  deregisterWarningModal;
  saveButtonClicked: boolean = false;
  navigateOnSubmit: boolean = false;

  rightModel: RightModel = RightModel.empty();

  private formFieldUpdater: TaskRecordFormFieldUpdater;

  get customerRecord(): CustomerRecord.CustomerRecord | undefined {
    return this.baseView.getModel().customer ? this.baseView.getModel().customer!.customerRecord : undefined;
  }

  constructor(
    private toasterService: ToasterService,
    private translateService: TranslateService,
    private configurationService: ConfigurationService,
    private taskService: TaskService,
    private modalService: BsModalService,
    private taskRecordService: TaskRecordService,
    private uiRouter: UIRouter,
    private transition: Transition,
    private countryService: CountryService,
    private userMeService: UserMeService,
    private rightService: RightService,
    public appTypeHelperService: AppTypeHelperService,
    private siteTourService: SiteTourService,
    private ngbDatePickerParserFormatter: NgbDatePickerParserFormatter) {
    this.taskId = this.transition.params().taskId;
    this.translateService.get('TASK_RECORD_PANEL_HEADING_CREATE').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
  }

  ngOnInit() {
    const context = this;
    this.deregisterWarningModal = this.uiRouter.transitionService.onStart({}, function (transition) {
      if (context.saveButtonClicked
        || transition.targetState().name() === StateName.CONNECTION_ERROR
        || transition.targetState().name() === StateName.SERVER_ERROR
        || transition.targetState().name() === StateName.LOGIN) {
        return true;
      }
      else {
        const subject = new Subject<boolean>();
        const modal = context.modalService.show(ConfirmLeaveModalComponent, {backdrop: 'static', keyboard: false});
        modal.content!.subject = subject;
        return subject.asObservable().toPromise();
      }
    });
    this.initTour();
  }

  initTour() {
    this.siteTourService.startTour(SiteTourId.TASK_RECORD_CREATE, {
      skipCallback: undefined, completeCallback: undefined
    });
  }

  ngAfterViewInit(): void {
    this.loadRightModels(() => {
      this.createCombinedObservable().subscribe(
        (result: CombinedResult) => {
          this.taskName = result.task.name;
          this.formRecordContainerView.loadFormRecord({
            configuration: this.configurationService.getConfiguration(),
            form: result.form
          });
          this.formFieldUpdater = new TaskRecordFormFieldUpdater(result.form, this.formRecordContainerView);
          this.baseView.loadTaskRecord({
            taskRecord: undefined,
            task: result.task,
            countryItems: AddressModel.CountryItem.fromCountryList(result.countries.items),
            defaultDeadline: result.task.defaultDeadline,
            rightModel: this.rightModel,
            defaultUser: result.task.webCreateDefaultAssigneeType === TaskDefaultAssigneeType.CREATOR ? result.currentUser : undefined,
            createOptions: <TaskRecordCreateOptions>this.transition.options().custom
          });
          this.combinedResult = result;
          this.forceSaveButton = !Form.Forms.hasVisibleGroup(result.form);
        },
        () => {
        },
        () => {
          this.translateService.get('MENU_NAVBAR_FORMS').subscribe(
            (result: string) => {
              this.breadcrumbParents.push({name: result, uiSref: StateName.TASK_DASHBOARD});
            }
          );
          this.breadcrumbParents.push({name: this.taskName, uiSref: StateName.TASK_RECORD_LIST, uiParam: {taskId: this.taskId}});
        }
      );
    });
  }

  private loadRightModels(completion: () => void) {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        completion();
      }
    );
  }

  createTaskRecord() {
    if (!this.combinedResult) {
      return;
    }
    if (this.baseView.hasLocalFieldError() || this.formRecordContainerView.hasLocalFieldError()) {
      this.showValidationErrorToast();
      return;
    }
    if (!this.baseView.validatePlaceOfConsumption()) {
      return;
    }
    if (this.formRecordContainerView.validateWithInterrupt()) {
      return;
    }
    const model = this.baseView.getModel();
    const formModel = this.formRecordContainerView.createModel();
    this.taskRecordService.create({
      withFormRecord: true,
      taskId: this.taskId,
      externalId: model.externalId,
      name: Strings.undefinedOrNonEmpty(model.name),
      description: model.description,
      importance: model.importance && model.importance.id ? model.importance.id : TaskRecord.DefImportance,
      ownerUserId: model.ownerUser && model.ownerUser.id ? model.ownerUser.id : undefined,
      customerId: model.customer && model.customer.id ? model.customer.id : undefined,
      billingInfoId: model.billingInfoId,
      contactLocationId: model.contactLocationId,
      projectId: model.project[0] && model.project[0].id ? model.project[0].id! : undefined,
      agreedTime: this.ngbDatePickerParserFormatter.toOffsetDateTime(model.agreedTimeDate, model.agreedTimeTime),
      deadline: this.ngbDatePickerParserFormatter.toOffsetDateTime(model.deadlineDate, model.deadlineTime),
      usergroupId: model.usergroup && model.usergroup.id ? model.usergroup.id : undefined,
      assignee: model.getServiceAssignee(),
      placeOfConsumption: model.getServicePoc(),
      contractNumberId: model.contractNumberId,
      formRecord: {
        fields: formModel.fields
      },
      fileDocumentIds: model.fileDocuments.map((doc: TaskRecordBase.DocumentItem) => doc.id),
      textDocumentIds: model.linkDocuments.map((doc: TaskRecordBase.DocumentItem) => doc.id),
      linkedSurveyIds: model.linkedSurveyIds,
      estimatedTimeInMinutes: model.estimatedTimeInMinutes
    }).subscribe(
      (result: IdentityMessage) => {
        LoadingHandler.getInstance().refresh();
        this.saveButtonClicked = true;
        if (this.formRecordContainerView.shouldNotifyAfterCreation()) {
          this.taskRecordService.get({
            taskId: this.taskId,
            taskRecordId: result.id
          }).subscribe(tr => {
            this.formRecordContainerView.afterFormRecordCreation(tr.formRecord!.recordId).subscribe(
              next => {
                // do nothing
              },
              error => {
                // do nothing
              },
              () => {
                this.afterCreation(result);
              });
          });
        }
        else {
          this.afterCreation(result);
        }
      },
      (error) => {
        if (error instanceof FieldValidationError) {
          this.baseView.onFieldValidationError(error.withForm(this.fForm));
          this.showValidationErrorToast();
        }
      }
    );
  }

  private afterCreation(result: IdentityMessage) {
    if (this.navigateOnSubmit) {
      window.history.back();
    }
    else {
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutShort,
        type: UiConstants.toastTypeSuccess,
        title: this.translateService.instant(StringKey.COMMON_SUCCESS),
        body: this.translateService.instant(StringKey.DEVICE_EDIT_MESSAGE)
      });
      this.uiRouter.stateService.go(
        this.appTypeHelperService.isHelpdesk()
          ? StateName.HELPDESK_BUG_REPORT_EDIT
          : StateName.TASK_RECORD_EDIT,
        {taskId: this.taskId, taskRecordId: result.id}
      );
    }
  }

  private createCombinedObservable(): Observable<CombinedResult> {
    return combineLatest(
      this.taskService.get({
        taskId: this.taskId
      }),
      this.countryService.query({}),
      this.userMeService.loadMe(),
      (
        task: Task.Task,
        countries: QueryResult<Country.Country>,
        me: User
      ) => {
        return {
          form: task.form!,
          task: task,
          countries: countries,
          currentUser: me
        };
      }
    );
  }

  private showValidationErrorToast() {
    this.toasterService.pop({
      timeout: UiConstants.ToastTimeoutLong,
      type: UiConstants.toastTypeError,
      title: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_TITLE),
      body: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_MESSAGE)
    });
  }

  submit(navigateOnSubmit: boolean) {
    this.navigateOnSubmit = navigateOnSubmit;
  }

  ngOnDestroy() {
    this.deregisterWarningModal();
  }

  onCustomerRecordChanged() {
    this.formFieldUpdater.onCustomerRecordChanged(this.baseView.getModel().customer, this.baseView.getModel().contactLocationId);
  }

  onAssigneeUserChanged() {
    this.formFieldUpdater.onAssigneeUserChanged(this.baseView.getModel().assignee.user);
  }

  onSelectedAddressChanged() {
    this.formFieldUpdater.onContactLocationChanged(this.baseView.getModel().contactLocationId);
  }
}

interface CombinedResult {
  form: Form.Form;
  task: Task.Task;
  countries: QueryResult<Country.Country>;
  currentUser: User;
}
