/* eslint-disable */
import { TaskRecordStateMachine } from '../lib/task/task-record-statemachine';
import { Icon } from '../lib/task/icon.service';

/* eslint-enable */

export class AppworksDashboardTaskModel {

  taskId: number;
  taskRecordCount?: number;
  overdueTaskCount?: number;
  icon?: Icon.Icon;
  name: string;
  stats: Map<TaskRecordStateMachine.State, TaskRecordStateStat> = new Map<TaskRecordStateMachine.State, TaskRecordStateStat>();
  canCreate?: boolean;
  color: number = 0;

  getStat(state: TaskRecordStateMachine.State): TaskRecordStateStat {
    const stat = this.stats[state];
    return stat ? stat : {state: state, count: 0, percent: 0};
  }

  get creatable(): boolean {
    return this.canCreate !== undefined && this.canCreate;
  }
}

export class TaskRecordStateStat {
  state: TaskRecordStateMachine.State;
  count: number;
  percent: number;
}

export class AppworksDashboardProjectModel {

  id: number;
  taskRecordCount?: number;
  name: string;
  amount: number;
  stats: Map<TaskRecordStateMachine.State, TaskRecordStateStat> = new Map<TaskRecordStateMachine.State, TaskRecordStateStat>();

  getStat(state: TaskRecordStateMachine.State): TaskRecordStateStat {
    const stat = this.stats[state];
    return stat ? stat : {state: state, count: 0, percent: 0};
  }
}

export class AppworksDashboardCustomerModel {
  customerId: number;
  customerRecordCount?: number;
  icon?: Icon.Icon;
  name: string;
}
