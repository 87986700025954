import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StockRecord, StockRecordFacade, StockRecordFacadeQuery, StockRecordService } from '../../../lib/stock/stock-record.service';
import { StockItemService } from '../../../lib/stock/stock-item.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { OrderType, QueryResult, ResourceQueryResult, Services } from '../../../lib/util/services';
import { DownloadedFile } from '../../../lib/util/downloaded-files';
import { MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxDefaultOptions } from '@angular/material/checkbox';
import { Currency, CurrencyService } from '../../../lib/currency.service';
import { MultiselectOptionItem, QueryFieldModel, UiConstants } from '../../../util/core-utils';
import { Set } from 'immutable';
import { Angular2Multiselects } from '../../../util/multiselect';
import { StockService } from '../../../lib/stock/stock.service';
import { Strings } from '../../../lib/util/strings';
import { StockItemCategory, StockItemCategoryService } from '../../../lib/stock-item-category/stock-item-category.service';
import { TranslateService } from '@ngx-translate/core';
import { Arrays } from '../../../lib/util/arrays';
import { UserMultiselectProvider } from '../../../lib/user/user-multiselect.provider';
import { StockItemUtils } from '../../../util/stock/stock-item-utils';
import { StockTypeName } from '../../../util/stock/stock-utils';
import { StockItemUnitOfMeasure } from '../../../lib/stock/stock-item-unit-of-measure';
import {WeightFactory} from "../../../util/weight-utils";

@Component({
  selector: 'app-stock-record-selector-dialog',
  templateUrl: './stock-record-selector-dialog.component.html',
  styleUrls: ['./stock-record-selector-dialog.component.scss'],
  providers: [
    { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { clickAction: 'noop' } as MatCheckboxDefaultOptions }
  ]
})
export class StockRecordSelectorDialogComponent implements OnInit {

  UiConstants = UiConstants;
  ViewMode = ViewMode;
  StockRecord = StockRecord;
  StockItemUtils = StockItemUtils;

  showFilter: boolean = false;

  stockRecords: StockRecordSelectorModel[] = [];
  selectedStockRecords: StockRecordSelectorModel[] = [];
  currencies: Currency.Currency[] = [];
  queryModel: QueryFieldModel<StockRecord.OrderField> = new QueryFieldModel(StockRecord.OrderField.STOCK_ITEM_NAME, OrderType.ASC);

  firstLoading: boolean = true;
  loading: boolean = false;
  categoriesLoading = false;

  allSelected: boolean = false;

  numberOfItemsOptions: number[] = [12, 24, 48];

  viewMode: ViewMode = ViewMode.LIST;

  dropdownSettings: Angular2Multiselects.Settings;

  filterModel: FilterModel = new FilterModel();
  users: MultiselectOptionItem<number>[] = [];
  stocks: MultiselectOptionItem<number>[] = [];
  stockItemCategories: MultiselectOptionItem<number>[] = [];

  filterAppliedIndicatorVisible: boolean = false;

  // If all items are selected, the visible list is the result of the last successful query
  private lastSuccessfulQueryRequest?: StockRecordFacadeQuery;

  readonly pagingId: string = 'stockRecordSelectorDialogPagingId';

  constructor(
    public dialogRef: MatDialogRef<StockRecordSelectorDialogComponent, StockRecordSelectorDialogResult>,
    @Inject(MAT_DIALOG_DATA) public data: StockRecordSelectorDialogData,
    private stockRecordService: StockRecordService,
    private stockItemService: StockItemService,
    private stockItemCategoryService: StockItemCategoryService,
    private currencyService: CurrencyService,
    private sanitizer: DomSanitizer,
    private userMultiselectProvider: UserMultiselectProvider,
    private stockService: StockService,
    private translateService: TranslateService
  ) {
  }

  ngOnInit() {
    this.initDropdownSettings();
    this.loadCurrencies(() => {
      this.queryModel.itemsPerPage = this.numberOfItemsOptions[0];
      this.loadAssignee(() => {
        this.loadStocks(undefined, () => {
          this.loadStockRecords(1);
        });
      });
    });
  }

  private initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
  }

  private loadCurrencies(completion: () => void) {
    this.currencyService.query({}).subscribe(result => {
      this.currencies = result.items.toArray();
      completion();
    });
  }

  loadStockRecords(pageNumber?: number) {
    if (!this.data.exactCategoryMatch || (this.data.exactCategoryMatch && this.filterModel.stockItemCategoryId)) {
      this.loading = true;
      const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
      const request = {
        stock_item_q: Strings.undefinedOrNonEmpty(this.filterModel.stockItemQuery),
        stock_owner_user_id: this.filterModel.assigneeIds,
        stock_type: this.data.forceStockType,
        stock_ids: this.filterModel.stockIds ? this.filterModel.stockIds : this.data.forceStockIds?.join(','),
        stock_item_usable_for_form_field: true,
        stock_disabled: false,
        stock_item_disabled: false,
        customer_record_id: this.data.customerRecordId,
        stock_owner_customer_record_id: this.data.ownerCustomerRecordId,
        stock_owner_contact_location_id: this.data.ownerContactLocationId,
        stock_item_category_ids: this.data.exactCategoryMatch ? this.filterModel.stockItemCategoryId + '' : undefined,
        recursive_stock_item_category_id: !this.data.exactCategoryMatch ? this.filterModel.stockItemCategoryId : undefined,
        enabled_stock_item_category_ids: this.data.usableStockItemCategoryIds.length > 0 ?
          this.data.usableStockItemCategoryIds.join() : undefined,
        excluded_stock_record_ids: this.data.excludedStockRecordIds.length > 0 ?
          this.data.excludedStockRecordIds.join() : undefined,
        order: Services.createOrderFieldParameter(StockRecord.Keys.toOrderFieldKey, Set.of(this.queryModel.getOrder())),
        page_number: requestedPage,
        number_of_items: this.queryModel.itemsPerPage,
      };
      this.stockRecordService.facadeQuery(request).subscribe((result: ResourceQueryResult<StockRecordFacade>) => {
        this.stockRecords = [];
        result.items.forEach(r => {
          const model = new StockRecordSelectorModel();
          model.selected = !!this.selectedStockRecords.find(record => record.id === r.stock_record_id);
          model.id = r.stock_record_id;
          model.name = r.stock_item.name;
          model.stockName = r.stock.name;
          model.externalId = r.stock_item.external_id;
          model.amount = r.amount;
          model.unit = r.stock_item.unit;
          model.unitPrice = r.stock_item.unit_price.value;
          model.priceCurrency = this.currencies.find(c => c.currencyCode === r.stock_item.unit_price.currency_code)!.localizedName;
          model.vatRate = r.stock_item.unit_price.vat_rate;
          if (r.stock_item.picture && r.stock_item.picture.content_hash) {
            this.stockItemService.downloadPicture({
              id: r.stock_item.stock_item_id,
            }).subscribe((res: DownloadedFile) => {
                model.pictureSrc = URL.createObjectURL(res.getBlob());
              }
            );
          }
          model.productCode = r.stock_item.product_code;
          model.packageData = r.stock_item.package_data;
          model.hunVtszNumber = r.stock_item.hun_vtsz_number;
          model.description = !r.stock_item.description ? undefined : this.sanitizeHtml(this.decodeHTMLEntities(r.stock_item.description));
          model.rawRecord = r;
          model.weight = r.stock_item.weight_in_grams ? WeightFactory.createWeightFromGram(r.stock_item.weight_in_grams, 'kg').toString() : undefined;
          this.stockRecords.push(model);
        });

        this.queryModel.currentPage = requestedPage;
        this.queryModel.totalNumberOfItems = result.pagingResult.currentNumberOfItems;
        this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;

        this.firstLoading = false;
        this.loading = false;
        this.lastSuccessfulQueryRequest = request;
      });
    }
    else {
      this.firstLoading = false;
      Arrays.clear(this.stockRecords);
    }
  }

  private sanitizeHtml(unsafeHtml: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(unsafeHtml);
  }

  private decodeHTMLEntities(text) {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = text;
    return textArea.value;
  }

  loadAssignee(completion: () => void) {
    if (this.data.assigneeUserId) {
      this.userMultiselectProvider.getById(this.data.assigneeUserId).subscribe(result => {
        this.filterModel.assignee.push(result);
        this.onAssigneeChanged();
        this.filterAppliedIndicatorVisible = true;
        completion();
      });
    } else {
      completion();
    }
  }

  loadUsers(q?: string) {
    this.userMultiselectProvider.loadAll(q).subscribe(result => {
      this.users = result;
    });
  }

  loadStocks(q?: string, completion?: () => void) {
    this.stockService.query({
      name: q ? Strings.undefinedOrNonEmpty(q) : undefined,
      owner_user_id: this.filterModel.assigneeIds,
      order: '+name',
      id: this.data.forceStockIds?.join(','),
      type: this.data.forceStockType,
      customer_record_id: this.data.ownerCustomerRecordId ? '' + this.data.ownerCustomerRecordId : undefined,
      contact_location_id: this.data.ownerContactLocationId ? '' + this.data.ownerContactLocationId : undefined,
      disabled: false,
      page_number: 1,
      number_of_items: UiConstants.autocompletePageSize,
      no_progress_bar: true
    }).subscribe(result => {
      this.stocks = result.items.map(s => ({id: s.id, itemName: s.name}));
      if (completion) {
        completion();
      }
    });
  }

  private loadStockItemCategories() {
    this.categoriesLoading = true;
    this.stockItemCategoryService.query({
      disabled: false,
      mainCategory: !this.filterModel.stockItemCategoryId,
      parentId: this.filterModel.stockItemCategoryId,
      enabledCategoryIds: Set.of(...this.data.usableStockItemCategoryIds),
      noProgressBar: true
    }).subscribe((result: QueryResult<StockItemCategory.StockItemCategory>) => {
      this.stockItemCategories = result.items.toArray().map(c => ({id: c.id, itemName: c.name}));
      this.categoriesLoading = false;
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  saveSelection() {
    if (this.allSelected) {
      if (this.lastSuccessfulQueryRequest) {
        this.lastSuccessfulQueryRequest.page_number = undefined; // Load all items
        this.lastSuccessfulQueryRequest.number_of_items = undefined; // Load all items
        this.lastSuccessfulQueryRequest.fields = 'stock_item_id';
        this.stockRecordService.facadeQuery(this.lastSuccessfulQueryRequest).subscribe((result: ResourceQueryResult<StockRecordFacade>) => {
          const ids = result.items.map(r => r.stock_record_id);
          this.dialogRef.close({
            selectedStockRecordIds: ids
          });
        });
      }
      else {
        this.dialogRef.close({
          selectedStockRecordIds: []
        });
      }
    }
    else {
      this.dialogRef.close(this.stockRecords.length > 0 ? {
        selectedStockRecords: this.selectedStockRecords
      } : undefined);
    }
  }

  orderBy(field: StockRecord.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadStockRecords(1);
  }

  onPageChange(page: number) {
    this.loadStockRecords(page);
  }

  onItemsPerPageChange(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadStockRecords(1);
  }

  toggleSelected(record: StockRecordSelectorModel) {
    const selected = record.selected;
    if (selected) {
      this.selectedStockRecords.splice(this.selectedStockRecords.findIndex(i => i === record), 1);
    }
    else {
      this.selectedStockRecords.push(record);
    }
    record.selected = !selected;
  }

  selectAll() {
    if (this.allSelected) {
      this.selectedStockRecords = [];
      this.stockRecords.forEach(r => r.selected = false);
    }
    this.allSelected = !this.allSelected;
  }

  toggleFilter() {
    this.showFilter = !this.showFilter;
    this.filterAppliedIndicatorVisible = false;
    if (this.showFilter && this.filterModel.firstOpen) {
      this.filterModel.firstOpen = false;
      this.loadStocks();
      this.translateService.get('STOCK_RECORD_SELECTOR_FILTER_STOCK_ITEM_CATEGORIES')
        .subscribe(result => {
          this.filterModel.stockItemCategory.push({
            id: null,
            itemName: result
          });
        });
      this.loadStockItemCategories();
    }
  }

  onAssigneeChanged() {
    this.loadStockRecords(1);
    this.filterModel.stock = [];
    this.loadStocks();
  }

  onStockChanged() {
    this.loadStockRecords(1);
  }

  selectCategory(category: MultiselectOptionItem<number>) {
    this.filterModel.stockItemCategory.push(category);
    this.loadStockItemCategories();
    this.loadStockRecords(1);
  }

  selectParentCategory(category: MultiselectOptionItem<number | null>) {
    if (category.id === null) {
      this.filterModel.stockItemCategory.splice(1);
    }
    else {
      for (let i = this.filterModel.stockItemCategory.length - 1; i > 1; i--) {
        if (this.filterModel.stockItemCategory[i].id !== category.id) {
          this.filterModel.stockItemCategory.splice(i, 1);
        }
        else {
          break;
        }
      }
    }
    this.loadStockRecords(1);
    this.loadStockItemCategories();
  }

  changeViewMode(mode: ViewMode) {
    this.viewMode = mode;
  }

}

export class StockRecordSelectorModel {
  selected: boolean = false;
  id: number;
  name: string = '';
  stockName: string = '';
  externalId: string = '';
  amount: number;
  unit: string = '';
  unitPrice: number;
  priceCurrency: string = '';
  vatRate: number;
  pictureSrc?: string;
  productCode?: string;
  packageData?: StockItemUnitOfMeasure.StockItemUnitOfMeasure;
  hunVtszNumber?: string;
  description?: SafeHtml;
  weight?: string;
  rawRecord: StockRecordFacade;
}

export interface StockRecordSelectorDialogData {
  customerRecordId?: number;
  ownerCustomerRecordId?: number;
  ownerContactLocationId?: number;
  assigneeUserId?: number;
  forceStockType?: StockTypeName;
  forceStockIds?: number[];
  usableStockItemCategoryIds: number[];
  excludedStockRecordIds: number[];
  exactCategoryMatch: boolean;
}

export interface StockRecordSelectorDialogResult {
  selectedStockRecordIds?: number[];
  selectedStockRecords?: StockRecordSelectorModel[];
}

export class FilterModel {
  assignee: MultiselectOptionItem<number>[] = [];
  stock: MultiselectOptionItem<number>[] = [];
  stockItemQuery: string = '';
  stockItemCategory: MultiselectOptionItem<number | null>[] = [];
  firstOpen: boolean = true;

  get assigneeIds(): string | undefined {
    return this.assignee.length > 0 ? this.assignee.map(a => a.id).join(',') : undefined;
  }

  get stockIds(): string | undefined {
    return this.stock.length > 0 ? this.stock.map(s => s.id).join(',') : undefined;
  }

  get stockItemCategoryId(): number | undefined {
    return this.stockItemCategory.length > 1 ? this.stockItemCategory[this.stockItemCategory.length - 1].id! : undefined;
  }
}

enum ViewMode {
  LIST,
  CARD
}
