/* eslint-disable */
import { Injectable } from '@angular/core';
import { Icon, IconService } from '../task/icon.service';
import { Observable, Observer } from 'rxjs';
import { ObservableErrorResourceParser } from '../util/errors';
import { FieldValidationError, PagingRequest, QueryResult, ResourceQueryResult, Services } from '../util/services';
import { List, Map as ImmutableMap, Set } from 'immutable';
import { OffsetDateTime } from '../util/dates';
import { EmptyMessage, IdentityMessage } from '../util/messages';
import { DqlQuery, Query } from '../query/field';
import { FilterField } from '../query/filterfields';
import { OrderField } from '../query/orderfields';
import { TaskRecordStateMachine } from '../task/task-record-statemachine';
import { WorkflowResource, WorkflowResourceService } from './workflow-resource.service';
import { BadgeStyle } from '../../shared/table-badge/badge-style';
import { Form } from '../form/form.service';
import { Task, TaskService } from '../task/task.service';
import { WorkflowRuleUtils } from '../form/workflow-rule-utils';
import { Process } from '../process/process.service';
import TaskRecordExternalIdSequenceData = Task.TaskRecordExternalIdSequenceData;
import {TaskRecord} from "../task/task-record.service";

/* eslint-enable */

@Injectable()
export class WorkflowService implements Workflow.Service {

  private readonly filterField = new FilterField.Workflow();
  private readonly orderField = new OrderField.Workflow();
  private readonly fields = new Workflow.Fields.Workflow();

  constructor(private resourceService: WorkflowResourceService,
              private iconService: IconService) {
  }

  query(request: Workflow.QueryRequest): Observable<QueryResult<Workflow.Workflow>> {
    return Observable.create((observer: Observer<QueryResult<Workflow.Workflow>>) => {
      const resourceRequest = Workflow.toResourceQueryRequest(this.filterField, this.orderField, this.fields, request);
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<WorkflowResource.Workflow>) => {
          observer.next({
            items: List.of(...result.items.map((item) => Workflow.toPublic(this.iconService, item))),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  get(request: Workflow.GetRequest): Observable<Workflow.Workflow> {
    return Observable.create((observer: Observer<Workflow.Workflow>) => {
      const resourceRequest = Workflow.toResourceGetRequest(this.fields, request);
      return this.resourceService.get(resourceRequest).subscribe(
        (result: WorkflowResource.Workflow) => {
          observer.next(Workflow.toPublic(this.iconService, result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  create(request: Workflow.CreateRequest): Observable<IdentityMessage> {
    return Observable.create((observer: Observer<IdentityMessage>) => {
      const resourceRequest = Workflow.toResourceCreateRequest(this.iconService, request);
      return this.resourceService.create(resourceRequest).subscribe(
        (result: IdentityMessage) => {
          observer.next(result);
        },
        (error: any) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  createNewVersion(request: IdentityMessage): Observable<IdentityMessage> {
    return Observable.create((observer: Observer<IdentityMessage>) => {
      return this.resourceService.createNewVersion(request).subscribe(
        (result: IdentityMessage) => {
          observer.next(result);
        },
        (error: any) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  clone(request: Workflow.CloneRequest): Observable<IdentityMessage> {
    return Observable.create((observer: Observer<IdentityMessage>) => {
      const resourceRequest = Workflow.toResourceCloneRequest(this.iconService, request);
      return this.resourceService.clone(resourceRequest).subscribe(
        (result: IdentityMessage) => {
          observer.next(result);
        },
        (error: any) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  update(request: Workflow.UpdateRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest = Workflow.toResourceUpdateRequest(this.iconService, request);
      return this.resourceService.update(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  changeIcon(request: Workflow.ChangeIconRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest = Workflow.toResourceChangeIconRequest(this.iconService, request);
      return this.resourceService.changeIcon(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  delete(request: IdentityMessage): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.resourceService.delete(request).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  activate(request: IdentityMessage): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.resourceService.activate(request).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  inactivate(request: IdentityMessage): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.resourceService.inactivate(request).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  finalize(request: IdentityMessage): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.resourceService.finalize(request).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  import(request: any): Observable<IdentityMessage> {
    return Observable.create((observer: Observer<IdentityMessage>) => {
      return this.resourceService.import(request).subscribe(
        (result: any) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  export(request: IdentityMessage): Observable<any> {
    return Observable.create((observer: Observer<any>) => {
      return this.resourceService.export(request).subscribe(
        (result: any) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  createTask(request: Workflow.AddTaskRequest): Observable<IdentityMessage> {
    return Observable.create((observer: Observer<IdentityMessage>) => {
      return this.resourceService.createTask({
        workflow_id: request.workflowId,
        group_id: request.groupId,
        task: {
          id: request.taskId,
        },
        location: Workflow.toResourceLocation(request.location)
      }).subscribe(
        (result: IdentityMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  updateTask(request: Workflow.UpdateTaskRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.resourceService.updateTask({
        workflow_id: request.workflowId,
        task_id: request.taskId,
        name_pattern: request.namePattern,
        open_task_record_on_create: request.openTaskRecordOnCreate,
        assignee_inheritance_mode: request.assigneeInheritanceMode.toString(),
        customer_inheritance_mode: request.customerInheritanceMode.toString(),
        place_of_consumption_inheritance_mode: request.placeOfConsumptionInheritanceMode.toString(),
        priority_inheritance_mode: request.priorityInheritanceMode.toString(),
        deadline_inheritance_mode: request.deadlineInheritanceMode.toString(),
        project_inheritance_mode: request.projectInheritanceMode.toString(),
        attachment_inheritance_mode: request.attachmentInheritanceMode.toString(),
        pdf_inheritance_mode: request.pdfInheritanceMode.toString(),
        contract_number_inheritance_mode: request.contractNumberInheritanceMode.toString(),
        form_field_inheritance_mode: request.formFieldInheritanceMode.toString(),
        default_assignee_user: request.defaultAssigneeUser,
        default_assignee_user_group: request.defaultAssigneeUserGroup,
        inherit_from_workflow_task: request.inheritFromWorkflowTask
      }).subscribe(
        (result: IdentityMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  addTaskToGroup(request: Workflow.AddTaskToGroupRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.resourceService.addTaskToGroup({
        workflow_id: request.workflowId,
        task_id: request.taskId,
        group_id: request.groupId
      }).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  removeTaskFromGroup(request: Workflow.RemoveTaskFromGroupRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.resourceService.removeTaskFromGroup({
        workflow_id: request.workflowId,
        task_id: request.taskId,
        location: Workflow.toResourceLocation(request.location)
      }).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  createGroup(request: Workflow.AddGroupRequest): Observable<IdentityMessage> {
    return Observable.create((observer: Observer<IdentityMessage>) => {
      return this.resourceService.createGroup({
        workflow_id: request.workflowId,
        location: Workflow.toResourceLocation(request.location)
      }).subscribe(
        (result: IdentityMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  updateGroup(request: Workflow.UpdateGroupRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.resourceService.updateGroup({
        workflow_id: request.workflowId,
        group_id: request.groupId,
        name: request.name
      }).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  updateObjectLocation(request: Workflow.UpdateObjectLocationRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.resourceService.updateObjectLocation({
        workflow_id: request.workflowId,
        object_id: request.objectId,
        location: Workflow.toResourceLocation(request.location)
      }).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  deleteObject(request: Workflow.DeleteObjectRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.resourceService.deleteObject({
        workflow_id: request.workflowId,
        object_id: request.objectId
      }).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  createTransition(request: Workflow.CreateTransitionRequest): Observable<IdentityMessage> {
    return Observable.create((observer: Observer<IdentityMessage>) => {
      return this.resourceService.createTransition({
        workflow_id: request.workflowId,
        input_object_id: request.inputObjectId,
        output_object_id: request.outputObjectId,
        points: request.points.map(p => Workflow.toResourcePoint(p))
      }).subscribe(
        (result: IdentityMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  deleteTransition(request: Workflow.DeleteTransitionRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.resourceService.deleteTransition({
        workflow_id: request.workflowId,
        transition_id: request.transitionId
      }).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  updateTransitionPoints(request: Workflow.UpdateTransitionPointsRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.resourceService.updateTransitionPoints({
        workflow_id: request.workflowId,
        transition_id: request.transitionId,
        points: request.points.map(p => Workflow.toResourcePoint(p))
      }).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  updateTransitionRule(request: Workflow.UpdateTransitionRuleRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.resourceService.updateTransitionRule(Workflow.toResourceUpdateTransitionRuleRequest(request))
        .subscribe(
          (result: EmptyMessage) => {
            observer.next(result);
          },
          (error: Error) => {
            observer.error(this.translateError(error));
          },
          () => {
            observer.complete();
          });
    });
  }

  stateCount(request: IdentityMessage): Observable<Map<string, number>> {
    return Observable.create((observer: Observer<Map<string, number>>) => {
      return this.resourceService.stateCount(request).subscribe(
        (result: Map<string, number>) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  private translateError(error: any): any {
    const res = ObservableErrorResourceParser.parseError(error);
    const fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
    const fieldErrorMap = ObservableErrorResourceParser.toFieldErrorMap(Keys.toValidatedField, fieldErrors);
    if (!fieldErrorMap.isEmpty()) {
      return FieldValidationError.of(fieldErrorMap);
    }
    return error;
  }
}

export namespace Workflow {

  import TaskRecordImportance = TaskRecord.TaskRecordImportance;

  export interface Service {

    // <editor-fold desc="CRUD">

    query(request: QueryRequest): Observable<QueryResult<Workflow>>;

    get(request: GetRequest): Observable<Workflow>;

    create(request: CreateRequest): Observable<IdentityMessage>;

    createNewVersion(request: IdentityMessage): Observable<IdentityMessage>;

    clone(request: CloneRequest): Observable<IdentityMessage>;

    update(request: UpdateRequest): Observable<EmptyMessage>;

    delete(request: IdentityMessage): Observable<EmptyMessage>;

    activate(request: IdentityMessage): Observable<EmptyMessage>;

    inactivate(request: IdentityMessage): Observable<EmptyMessage>;

    finalize(request: IdentityMessage): Observable<EmptyMessage>;

    // </editor-fold>

  }

  // <editor-fold desc="CRUD">

  export interface QueryRequest {
    fields?: Query.FieldFunction<Fields.Workflow>;
    filter?: Query.FilterFunction<FilterField.Workflow>;
    order?: Query.OrderFunction<OrderField.Workflow>;
    paging?: PagingRequest;
    rights?: Set<string>;
    latestVersionsOnly?: boolean;
    noProgressBar?: boolean;
  }

  export interface Workflow {
    id: number;
    baseId?: number;
    grantedRights: Set<string>;
    version: number;
    versionState: VersionState;
    name: string;
    externalId: string;
    description?: string;
    icon?: Icon.Icon;
    inheritanceMode?: InheritanceMode;
    creationTime: OffsetDateTime;
    updateTime: OffsetDateTime;
    lastModifiedUser?: {
      id: number;
      userName: string;
      personName: string;
    };
    stateMachine?: StateMachine;
    startWithFirstTask: boolean;
    firstTaskId?: number;
    processConfig?: ProcessConfig;
  }

  export enum VersionState {
    DRAFT = 'DRAFT',
    FINALIZED = 'FINALIZED',
    DEPRECATED = 'DEPRECATED',
    INACTIVE = 'INACTIVE',
  }

  export interface VersionStateObject {
    state: VersionState;
    stringKey: string;
    iconClass: string;
    badgeStyle: string;
  }

  export const versionStates: VersionStateObject[] = [
    {
      state: VersionState.DRAFT,
      stringKey: 'WORKFLOW_VERSION_STATE_DRAFT',
      iconClass: 'icomoon-state-draft',
      badgeStyle: BadgeStyle.PRIMARY
    },
    {
      state: VersionState.FINALIZED,
      stringKey: 'WORKFLOW_VERSION_STATE_FINALIZED',
      iconClass: 'icomoon-transport-state-done',
      badgeStyle: BadgeStyle.SUCCESS
    },
    {
      state: VersionState.DEPRECATED,
      stringKey: 'WORKFLOW_VERSION_STATE_DEPRECATED',
      iconClass: 'icomoon-new-state-edit-archive',
      badgeStyle: BadgeStyle.SECONDARY
    },
    {
      state: VersionState.INACTIVE,
      stringKey: 'WORKFLOW_VERSION_STATE_INACTIVE',
      iconClass: 'icomoon-disable',
      badgeStyle: BadgeStyle.DANGER
    },
  ];


  export enum InheritanceMode {
    NONE = 'NONE',
    FIRST_TASK = 'FIRST_TASK',
    LAST_TASK = 'LAST_TASK',
    WORKFLOW_TASK = 'WORKFLOW_TASK',
    GIVEN_WORKFLOW_TASK = 'GIVEN_WORKFLOW_TASK'
  }

  export const inheritanceModes = [
    {stringKey: 'WORKFLOW_INHERITANCE_MODE_NONE', mode: InheritanceMode.NONE},
    {stringKey: 'WORKFLOW_INHERITANCE_MODE_FIRST_TASK', mode: InheritanceMode.FIRST_TASK},
    {stringKey: 'WORKFLOW_INHERITANCE_MODE_LAST_TASK', mode: InheritanceMode.LAST_TASK},
    {stringKey: 'WORKFLOW_INHERITANCE_MODE_GIVEN_WORKFLOW_TASK', mode: InheritanceMode.GIVEN_WORKFLOW_TASK},
  ];

  export const assigneeInheritanceModes = inheritanceModes
    .concat({stringKey: 'WORKFLOW_INHERITANCE_MODE_WORKFLOW_TASK', mode: InheritanceMode.WORKFLOW_TASK});

  export interface ProcessConfig {
    namePattern?: string;
    externalIdSequence?: TaskRecordExternalIdSequenceData;
    requiredFields: Set<Process.ProcessFieldType>;
  }

  export interface StateMachine {
    entryPoint: EntryPoint;
    tasks: Task[];
    groups: Group[];
    transitions: Transition[];
  }

  export interface EntryPoint {
    id: number;
    location: Location;
  }

  export interface Location {
    topLeftPoint: Point;
    size: Size;
  }

  export interface Point {
    x: number;
    y: number;
  }

  export interface Size {
    width: number;
    height: number;
  }

  export interface Task {
    id: number;
    task: {
      id: number;
      name: string;
    };
    defaultAssigneeUser?: {
      id: number;
      personName: string;
      userName: string;
    };
    defaultAssigneeUserGroup?: {
      id: number;
      name: string;
    };
    inheritFromWorkflowTask?: {
      id: number;
      name: string;
    };
    groupId?: number;
    groupIndex?: number;
    location?: Location;
    namePattern?: string;
    openTaskRecordOnCreate: boolean;
    assigneeInheritanceMode?: InheritanceMode;
    customerInheritanceMode?: InheritanceMode;
    placeOfConsumptionInheritanceMode?: InheritanceMode;
    priorityInheritanceMode?: InheritanceMode;
    deadlineInheritanceMode?: InheritanceMode;
    projectInheritanceMode?: InheritanceMode;
    attachmentInheritanceMode?: InheritanceMode;
    pdfInheritanceMode?: InheritanceMode;
    contractNumberInheritanceMode?: InheritanceMode;
    formFieldInheritanceMode?: InheritanceMode;
  }

  export interface Group {
    id: number;
    location: Location;
    name: string;
  }

  export interface Transition {
    id: number;
    inputObjectId: number;
    outputObjectId: number;
    points: Point[];
    rule: Rule;
  }

  export interface Rule {
    taskRecordStates: Set<TaskRecordStateMachine.State>;
    rules: BaseRule[];
  }

  export interface BaseRule {
    operator?: RuleOperator;
    command: RuleCommand;
  }

  export interface FormFieldRule extends BaseRule{
    type: FormFieldRuleType;
    formField: {
      id: number;
      disabled?: boolean;  // undefined in request
      title?: string; // undefined in request
      selector?: Form.FieldDataTypeSelector; // undefined in request
    };
    stringValue?: string;
    numberValue?: number;
    listItems?: Set<{
      id: number;
      text?: string; // undefined in request
    }>;
  }

  export interface TaskRule extends BaseRule {
    type: TaskRuleType;
    users?: Set<{
      id: number;
      userName?: string; // undefined in request
      personName?: string; // undefined in request
    }>;
    userGroups?: Set<{
      id: number;
      name?: string; // undefined in request
    }>;
    importances?: Set<TaskRecordImportance>;
  }

  export enum RuleOperator {
    AND = 'AND',
    OR = 'OR'
  }

  export interface RuleOperatorObject {
    operator: RuleOperator;
    stringKey: string;
  }

  export const ruleOperators: RuleOperatorObject[] = [
    {operator: RuleOperator.AND, stringKey: 'WORKFLOW_TRANSITION_RULE_OPERATOR_AND'},
    {operator: RuleOperator.OR, stringKey: 'WORKFLOW_TRANSITION_RULE_OPERATOR_OR'}
  ];

  export enum FormFieldRuleType {
    BOOLEAN = 'BOOLEAN',
    NUMBER = 'NUMBER',
    STRING = 'STRING',
    LIST = 'LIST'
  }

  export interface FormFieldRuleTypeObject {
    type: FormFieldRuleType;
    stringKey: string;
  }

  export const formFieldRuleTypes: FormFieldRuleTypeObject[] = [
    {type: FormFieldRuleType.BOOLEAN, stringKey: 'WORKFLOW_TRANSITION_RULE_TYPE_BOOLEAN'},
    {type: FormFieldRuleType.NUMBER, stringKey: 'WORKFLOW_TRANSITION_RULE_TYPE_NUMBER'},
    {type: FormFieldRuleType.STRING, stringKey: 'WORKFLOW_TRANSITION_RULE_TYPE_STRING'},
    {type: FormFieldRuleType.LIST, stringKey: 'WORKFLOW_TRANSITION_RULE_TYPE_LIST'}
  ];

  export enum TaskRuleType {
    ASSIGNEE_USER= 'ASSIGNEE_USER',
    ASSIGNEE_USER_GROUP = 'ASSIGNEE_USER_GROUP',
    ASSIGNEE_USER_USER_GROUP = 'ASSIGNEE_USER_USER_GROUP',
    IMPORTANCE = 'IMPORTANCE'
  }

  export interface TaskRuleTypeObject {
    type: TaskRuleType;
    stringKey: string;
  }

  export const taskRuleTypes: TaskRuleTypeObject[] = [
    {type: TaskRuleType.ASSIGNEE_USER, stringKey: 'WORKFLOW_TRANSITION_RULE_TYPE_ASSIGNEE_USER'},
    {type: TaskRuleType.ASSIGNEE_USER_GROUP, stringKey: 'WORKFLOW_TRANSITION_RULE_TYPE_ASSIGNEE_USER_GROUP'},
    {type: TaskRuleType.ASSIGNEE_USER_USER_GROUP, stringKey: 'WORKFLOW_TRANSITION_RULE_TYPE_ASSIGNEE_USER_USER_GROUP'},
    {type: TaskRuleType.IMPORTANCE, stringKey: 'WORKFLOW_TRANSITION_RULE_TYPE_IMPORTANCE'},
  ];

  export enum RuleCommand {
    ALL = 'ALL',
    ANY_OF = 'ANY_OF',
    NONE_OF = 'NONE_OF',
    IS_TRUE = 'IS_TRUE',
    IS_FALSE = 'IS_FALSE',
    EQUALS = 'EQUALS',
    NOT_EQUAL = 'NOT_EQUAL',
    LESS_THAN = 'LESS_THAN',
    LESS_OR_EQUAL_THAN = 'LESS_OR_EQUAL_THAN',
    GREATER_THAN = 'GREATER_THAN',
    GREATER_OR_EQUAL_THAN = 'GREATER_OR_EQUAL_THAN'
  }

  export interface RuleCommandObject {
    command: RuleCommand;
    stringKey: string;
  }

  export const ruleCommands: RuleCommandObject[] = [
    {command: RuleCommand.ALL, stringKey: 'WORKFLOW_TRANSITION_RULE_OPERATOR_ALL'},
    {command: RuleCommand.ANY_OF, stringKey: 'WORKFLOW_TRANSITION_RULE_OPERATOR_ANY_OF'},
    {command: RuleCommand.NONE_OF, stringKey: 'WORKFLOW_TRANSITION_RULE_OPERATOR_NONE_OF'},
    {command: RuleCommand.IS_TRUE, stringKey: 'WORKFLOW_TRANSITION_RULE_OPERATOR_IS_TRUE'},
    {command: RuleCommand.IS_FALSE, stringKey: 'WORKFLOW_TRANSITION_RULE_OPERATOR_IS_FALSE'},
    {command: RuleCommand.EQUALS, stringKey: 'WORKFLOW_TRANSITION_RULE_OPERATOR_EQUALS'},
    {command: RuleCommand.NOT_EQUAL, stringKey: 'WORKFLOW_TRANSITION_RULE_OPERATOR_NOT_EQUAL'},
    {command: RuleCommand.LESS_THAN, stringKey: 'WORKFLOW_TRANSITION_RULE_OPERATOR_LESS_THAN'},
    {command: RuleCommand.LESS_OR_EQUAL_THAN, stringKey: 'WORKFLOW_TRANSITION_RULE_OPERATOR_LESS_OR_EQUAL_THAN'},
    {command: RuleCommand.GREATER_THAN, stringKey: 'WORKFLOW_TRANSITION_RULE_OPERATOR_GREATER_THAN'},
    {command: RuleCommand.GREATER_OR_EQUAL_THAN, stringKey: 'WORKFLOW_TRANSITION_RULE_OPERATOR_GREATER_OR_EQUAL_THAN'},
  ];

  export interface GetRequest {
    id: number;
    fields?: Query.FieldFunction<Fields.Workflow>;
    rights?: Set<string>;
  }

  export interface CreateRequest {
    name: string;
    externalId?: string;
    description?: string;
    icon?: Icon.Icon;
    inheritanceMode: InheritanceMode;
    stateMachine: {
      entryPoint: {
        location: Location;
      };
    };
    startWithFirstTask: boolean;
    processConfig: ProcessConfig;
  }

  export interface CloneRequest extends CreateRequest {
    id: number;
  }


  export interface UpdateRequest {
    id: number;
    name: string;
    externalId: string;
    description?: string;
    icon?: Icon.Icon;
    inheritanceMode: InheritanceMode;
    startWithFirstTask: boolean;
    processConfig: ProcessConfig;
  }

  export interface ChangeIconRequest {
    id: number;
    icon?: Icon.Icon;
  }

  export interface AddTaskRequest {
    workflowId: number;
    groupId?: number;
    taskId: number;
    location: Location;
  }

  export interface UpdateTaskRequest {
    workflowId: number;
    taskId: number;
    namePattern?: string;
    openTaskRecordOnCreate: boolean;
    assigneeInheritanceMode: InheritanceMode;
    customerInheritanceMode: InheritanceMode;
    placeOfConsumptionInheritanceMode: InheritanceMode;
    priorityInheritanceMode: InheritanceMode;
    deadlineInheritanceMode: InheritanceMode;
    projectInheritanceMode: InheritanceMode;
    attachmentInheritanceMode: InheritanceMode;
    pdfInheritanceMode: InheritanceMode;
    contractNumberInheritanceMode: InheritanceMode;
    formFieldInheritanceMode: InheritanceMode;
    defaultAssigneeUser?: number;
    defaultAssigneeUserGroup?: number;
    inheritFromWorkflowTask?: number;
  }

  export interface AddTaskToGroupRequest {
    workflowId: number;
    taskId: number;
    groupId: number;
  }

  export interface RemoveTaskFromGroupRequest {
    workflowId: number;
    taskId: number;
    location: Location;
  }

  export interface AddGroupRequest {
    workflowId: number;
    location: Location;
  }

  export interface UpdateGroupRequest {
    workflowId: number;
    groupId: number;
    name: string;
  }

  export interface UpdateObjectLocationRequest {
    workflowId: number;
    objectId: number;
    location: Location;
  }

  export interface DeleteObjectRequest {
    workflowId: number;
    objectId: number;
  }

  export interface CreateTransitionRequest {
    workflowId: number;
    inputObjectId: number;
    outputObjectId: number;
    points: Point[];
  }

  export interface DeleteTransitionRequest {
    workflowId: number;
    transitionId: number;
  }

  export interface UpdateTransitionPointsRequest {
    workflowId: number;
    transitionId: number;
    points: Point[];
  }

  export interface UpdateTransitionRuleRequest {
    workflowId: number;
    transitionId: number;
    taskRecordStates: Set<TaskRecordStateMachine.State>;
    rules: BaseRule[];
  }

  export function toResourceQueryRequest(
    filterField: FilterField.Workflow,
    orderField: OrderField.Workflow,
    fields: Workflow.Fields.Workflow,
    request: QueryRequest): WorkflowResource.QueryRequest {
    const filter: string | undefined = DqlQuery.toOptionalFilter(filterField, request.filter);
    const order: string | undefined = DqlQuery.toOptionalOrder(orderField, request.order);
    const field: string | undefined = DqlQuery.toOptionalFields(fields, request.fields);
    return {
      filter: filter,
      order: order,
      fields: field,
      latest_versions_only: request.latestVersionsOnly,
      page_number: request.paging ? request.paging.pageNumber : undefined,
      number_of_items: request.paging ? request.paging.numberOfItems : undefined,
      rights: DqlQuery.RightRequestSerializer.getInstance().serialize(request.rights),
      no_progress_bar: request.noProgressBar
    };
  }

  export function toResourceGetRequest(
    fields: Workflow.Fields.Workflow,
    request: GetRequest): WorkflowResource.GetRequest {
    const field: string | undefined = DqlQuery.toOptionalFields(fields, request.fields);
    return {
      id: request.id,
      fields: field,
      rights: DqlQuery.RightRequestSerializer.getInstance().serialize(request.rights)
    };
  }

  export function toResourceCreateRequest(iconService: IconService, request: CreateRequest): WorkflowResource.CreateRequest {
    return {
      name: request.name,
      external_id: request.externalId,
      description: request.description,
      icon: iconService.toResourceIcon(request.icon),
      inheritance_mode: request.inheritanceMode.toString(),
      state_machine: {
        entry_point: {
          location: toResourceLocation(request.stateMachine.entryPoint.location)
        }
      },
      start_with_first_task: request.startWithFirstTask,
      process_config: toResourceProcessConfig(request.processConfig)!
    };
  }

  export function toResourceCloneRequest(iconService: IconService, request: CloneRequest): WorkflowResource.CloneRequest {
    return {
      id: request.id,
      name: request.name,
      external_id: request.externalId,
      description: request.description,
      icon: iconService.toResourceIcon(request.icon),
      inheritance_mode: request.inheritanceMode.toString(),
      state_machine: {
        entry_point: {
          location: toResourceLocation(request.stateMachine.entryPoint.location)
        }
      },
      start_with_first_task: request.startWithFirstTask,
      process_config: toResourceProcessConfig(request.processConfig)!
    };
  }

  export function toResourceUpdateRequest(iconService: IconService, request: UpdateRequest): WorkflowResource.UpdateRequest {
    return {
      id: request.id,
      name: request.name,
      external_id: request.externalId,
      description: request.description,
      icon: iconService.toResourceIcon(request.icon),
      inheritance_mode: request.inheritanceMode.toString(),
      start_with_first_task: request.startWithFirstTask,
      process_config: toResourceProcessConfig(request.processConfig)!
    };
  }

  export function toResourceProcessConfig(p?: ProcessConfig): WorkflowResource.ProcessConfig | undefined {
    if (p) {
      return {
        name_pattern: p.namePattern,
        external_id_sequence: TaskService.toResourceTaskRecordExternalIdSequenceData(p.externalIdSequence),
        required_fields: p.requiredFields.toArray()
      };
    }
    return undefined;
  }

  export function toPublicProcessConfig(r?: WorkflowResource.ProcessConfig): ProcessConfig | undefined {
    if (r) {
      return {
        namePattern: r.name_pattern,
        externalIdSequence: TaskService.toPublicTaskRecordExternalIdSequenceData(r.external_id_sequence),
        requiredFields: Set.of(...r.required_fields.map(f => <Process.ProcessFieldType>f))
      };
    }
    return undefined;
  }

  export function toResourceChangeIconRequest(iconService: IconService, request: ChangeIconRequest): WorkflowResource.ChangeIconRequest {
    return {
      id: request.id,
      icon: iconService.toResourceIcon(request.icon)
    };
  }

  export function toResourceLocation(p: Location): WorkflowResource.Location {
    return {
      top_left_point: toResourcePoint(p.topLeftPoint),
      size: toResourceSize(p.size)
    };
  }

  export function toResourcePoint(p: Point): WorkflowResource.Point {
    return {
      x: Math.round(p.x),
      y: Math.round(p.y)
    };
  }

  export function toResourceSize(p: Size): WorkflowResource.Size {
    return {
      width: Math.round(p.width),
      height: Math.round(p.height)
    };
  }

  export function toResourceUpdateTransitionRuleRequest(request: UpdateTransitionRuleRequest):
    WorkflowResource.UpdateTransitionRuleRequest {
    return {
      workflow_id: request.workflowId,
      transition_id: request.transitionId,
      task_record_states: request.taskRecordStates.toArray().map(s => s.toString()),
      rules: request.rules.map(r => WorkflowRuleUtils.toResourceRule(r))
    };
  }

  export function toPublic(iconService: IconService, r: WorkflowResource.Workflow): Workflow {
    return {
      id: r.id,
      baseId: r.base_id,
      grantedRights: r.granted_rights ? Set.of(...r.granted_rights) : Set.of(),
      version: r.version,
      versionState: <VersionState>r.version_state,
      name: r.name,
      externalId: r.external_id,
      description: r.description,
      icon: iconService.toPublicIcon(r.icon),
      inheritanceMode: <InheritanceMode>r.inheritance_mode,
      creationTime: Services.toOffsetDateTime(r.creation_time),
      updateTime: Services.toOffsetDateTime(r.update_time),
      lastModifiedUser: r.last_modified_user ? {
        id: r.last_modified_user.id,
        userName: r.last_modified_user.user_name,
        personName: r.last_modified_user.person_name
      } : undefined,
      stateMachine: r.state_machine ? toPublicStateMachine(r.state_machine) : undefined,
      startWithFirstTask: r.start_with_first_task,
      firstTaskId: r.first_task_id,
      processConfig: toPublicProcessConfig(r.process_config)
    };
  }

  export function toPublicStateMachine(r: WorkflowResource.StateMachine): StateMachine {
    return {
      entryPoint: toPublicEntryPoint(r.entry_point),
      tasks: r.tasks.map(t => toPublicTask(t)),
      groups: r.groups.map(g => toPublicGroup(g)),
      transitions: r.transitions.map(t => toPublicTransition(t))
    };
  }

  export function toPublicEntryPoint(r: WorkflowResource.EntryPoint): EntryPoint {
    return {
      id: r.id,
      location: toPublicLocation(r.location)!,
    };
  }

  export function toPublicLocation(r: WorkflowResource.Location): Location {
    return {
      topLeftPoint: toPublicPoint(r.top_left_point),
      size: toPublicSize(r.size)
    };
  }

  export function toPublicPoint(r: WorkflowResource.Point): Point {
    return {
      x: r.x,
      y: r.y
    };
  }

  export function toPublicSize(r: WorkflowResource.Size): Size {
    return {
      width: r.width,
      height: r.height
    };
  }

  export function toPublicTask(r: WorkflowResource.Task): Task {
    return {
      id: r.id,
      task: {
        id: r.task.id,
        name: r.task.name
      },
      defaultAssigneeUser: r.default_assignee_user ? {
        id: r.default_assignee_user.id,
        personName: r.default_assignee_user.person_name,
        userName: r.default_assignee_user.user_name
      } : undefined,
      defaultAssigneeUserGroup: r.default_assignee_user_group ? {
        id: r.default_assignee_user_group.id,
        name: r.default_assignee_user_group.name
      } : undefined,
      inheritFromWorkflowTask: r.inherit_from_workflow_task ? {
        id: r.inherit_from_workflow_task.id,
        name: r.inherit_from_workflow_task.name
      } : undefined,
      groupId: r.group_id,
      groupIndex: r.group_index,
      location: r.location ? toPublicLocation(r.location) : undefined,
      namePattern: r.name_pattern,
      openTaskRecordOnCreate: r.open_task_record_on_create,
      assigneeInheritanceMode: <InheritanceMode>r.assignee_inheritance_mode,
      customerInheritanceMode: <InheritanceMode>r.customer_inheritance_mode,
      placeOfConsumptionInheritanceMode: <InheritanceMode>r.place_of_consumption_inheritance_mode,
      priorityInheritanceMode: <InheritanceMode>r.priority_inheritance_mode,
      deadlineInheritanceMode: <InheritanceMode>r.deadline_inheritance_mode,
      projectInheritanceMode: <InheritanceMode>r.project_inheritance_mode,
      attachmentInheritanceMode: <InheritanceMode>r.attachment_inheritance_mode,
      pdfInheritanceMode: <InheritanceMode>r.pdf_inheritance_mode,
      contractNumberInheritanceMode: <InheritanceMode>r.contract_number_inheritance_mode,
      formFieldInheritanceMode: <InheritanceMode>r.form_field_inheritance_mode
    };
  }

  export function toPublicGroup(r: WorkflowResource.Group): Group {
    return {
      id: r.id,
      location: toPublicLocation(r.location),
      name: r.name
    };
  }

  export function toPublicTransition(r: WorkflowResource.Transition): Transition {
    return {
      id: r.id,
      inputObjectId: r.input_object_id,
      outputObjectId: r.output_object_id,
      points: r.points.map(p => toPublicPoint(p)),
      rule: toPublicRule(r.rule),
    };
  }

  export function toPublicRule(r: WorkflowResource.Rule): Rule {
    return {
      taskRecordStates: Set.of(...r.task_record_states.map(s => <TaskRecordStateMachine.State>s)),
      rules: r.rules.map(f => WorkflowRuleUtils.toPublicRule(f)),
    };
  }

  export enum ValidatedField {
    UNKNOWN,
    NAME,
    EXTERNAL_ID,
    TEMPLATE,
  }

  export namespace Fields {

    export class Workflow {

      readonly id: Query.Field = new DqlQuery.Field('id');
      readonly baseId: Query.Field = new DqlQuery.Field('base_id');
      readonly grantedRights: Query.Field = new DqlQuery.Field('granted_rights');
      readonly version: Query.Field = new DqlQuery.Field('version');
      readonly versionState: Query.Field = new DqlQuery.Field('version_state');
      readonly name: Query.Field = new DqlQuery.Field('name');
      readonly externalId: Query.Field = new DqlQuery.Field('external_id');
      readonly description: Query.Field = new DqlQuery.Field('description');
      readonly icon: Query.Field = new DqlQuery.Field('icon');
      readonly inheritanceMode: Query.Field = new DqlQuery.Field('inheritance_mode');
      readonly creationTime: Query.Field = new DqlQuery.Field('creation_time');
      readonly updateTime: Query.Field = new DqlQuery.Field('update_time');
      readonly lastModifiedUser: Query.Field = new DqlQuery.Field('last_modified_user');
      readonly stateMachine: Query.Field = new DqlQuery.Field('state_machine');
      readonly startWithFirstTask: Query.Field = new DqlQuery.Field('start_with_first_task');
      readonly firstTaskId: Query.Field = new DqlQuery.Field('first_task_id');
      readonly processConfig: Query.Field = new DqlQuery.Field('process_config');

      get each(): Set<Query.Field> {
        return Set.of(
          this.id,
          this.baseId,
          this.version,
          this.versionState,
          this.name,
          this.externalId,
          this.description,
          this.icon,
          this.inheritanceMode,
          this.creationTime,
          this.updateTime,
          this.lastModifiedUser,
          this.stateMachine,
          this.startWithFirstTask,
          this.firstTaskId,
          this.processConfig
        );
      }

      get forList(): Set<Query.Field> {
        return Set.of(
          this.id,
          this.version,
          this.versionState,
          this.name,
          this.externalId,
          this.icon,
          this.creationTime,
          this.updateTime,
          this.lastModifiedUser
        );
      }

      get forSearch(): Set<Query.Field> {
        return Set.of(
          this.id,
          this.baseId,
          this.name,
          this.versionState,
          this.processConfig,
          this.firstTaskId
        );
      }

    }

  }

}

// <editor-fold desc="Internal">


class Keys {

  private static readonly NAME = 'name';
  private static readonly EXTERNAL_ID = 'external_id';
  private static readonly TEMPLATE = 'template';

  private static readonly keyValidatedFieldMap: ImmutableMap<string, Workflow.ValidatedField> = ImmutableMap.of(
    Keys.NAME, Workflow.ValidatedField.NAME,
    Keys.EXTERNAL_ID, Workflow.ValidatedField.EXTERNAL_ID,
    Keys.TEMPLATE, Workflow.ValidatedField.TEMPLATE,
  );

  public static toValidatedField(fieldKey: string): Workflow.ValidatedField {
    return Keys.keyValidatedFieldMap.get(fieldKey, Workflow.ValidatedField.UNKNOWN);
  }

}

// </editor-fold>
