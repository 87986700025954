/* eslint-disable */
import { Component, EventEmitter, Input, Output, } from '@angular/core';
import { Address, AddressModel, } from '../../../../lib/address';
import { FormBuilder, FormGroup, NgForm, Validators, } from '@angular/forms';
import { ForwardingNgFormRef, LocalFormGroupValidationErrors, } from '../../../../lib/util/services';
import { AppValidators } from '../../../../util/app-validators';
import { MultiselectOptionItem, UiConstants } from '../../../../util/core-utils';
/* eslint-enable */

@Component({
  selector: 'app-email-address-item',
  templateUrl: './email-address-item.component.html',
  styleUrls: ['./email-address-item.component.scss']
})
export class EmailAddressItemComponent {
  UiConstants = UiConstants;

  @Input()
  model?: AddressModel.EmailAddressModel;

  @Input()
  readonly: boolean;

  @Input()
  deletable?: boolean = false;

  @Input()
  form?: NgForm;

  @Input()
  types: MultiselectOptionItem<number>[] = [];

  @Output()
  deleteEventEmitter: EventEmitter<any> = new EventEmitter();

  formGroup: FormGroup;

  /**
   * Only for HTML binding!
   */
  get m(): AddressModel.EmailAddressModel {
    return this.model!;
  }

  private formGroupValidationErrors: LocalFormGroupValidationErrors;

  constructor(fb: FormBuilder) {
    this.formGroup = this.createFormGroup(fb);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(
      this.createForwardingHtmlForm(),
      this.formGroup
    );
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  private createFormGroup(fb: FormBuilder): FormGroup {
    return fb.group(
      {
        value: fb.control(
          '',
          [
            Validators.required,
            AppValidators.validateOptionalEmail
          ]
        ),
        type: fb.control(
          '',
          [
            Validators.required
          ]
        ),
      }
    );
  }

  private createForwardingHtmlForm() {
    return new ForwardingNgFormRef({
      formFn: () => {
        return this.form;
      }
    });
  }

  private emitDeleteEvent() {
    this.deleteEventEmitter.emit(null);
  }

  getTypeText(typeId: number) {
    if (this.types) {
      const text = this.types.find(t => t.id === typeId);
      return text ? text.itemName : '';
    }
    return '';
  }

}
