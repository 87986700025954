import { Component, Inject, OnInit } from '@angular/core';
import { AlertType } from '../confirm-dialog/confirm-dialog.component';
import { MAT_DIALOG_DATA, MAT_DIALOG_DEFAULT_OPTIONS, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-mat-confirm-dialog',
  templateUrl: './mat-confirm-dialog.component.html',
  styleUrls: ['./mat-confirm-dialog.component.scss']
})
export class MatConfirmDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<MatConfirmDialogComponent, boolean>,
              @Inject(MAT_DIALOG_DATA) public data: MatConfirmDialogData) {
  }

  ngOnInit() {
  }

  get titleEnabled(): boolean {
    return this.data.titleKey !== undefined && this.data.titleKey.length > 0;
  }

  get messageEnabled(): boolean {
    return this.data.messageKey !== undefined && this.data.messageKey.length > 0;
  }

  get alertType(): AlertType {
    return this.data.alertType ? this.data.alertType : AlertType.INFO;
  }

  get titleKey(): string {
    return this.titleEnabled ? this.data.titleKey! : '';
  }

  get messageKey(): string {
    return this.messageEnabled ? this.data.messageKey! : '';
  }

  get negativeButtonEnabled(): boolean {
    return this.data.negativeButtonEnabled === undefined ? true : this.data.negativeButtonEnabled;
  }

  get positiveButtonEnabled(): boolean {
    return this.data.positiveButtonEnabled === undefined ? true : this.data.positiveButtonEnabled;
  }

  closeDialog() {
    this.dialogRef.close();
  }

}

export interface MatConfirmDialogData {
  titleKey?: string;
  alertType?: AlertType;
  messageKey?: string;
  negativeButtonEnabled?: boolean;
  positiveButtonEnabled?: boolean;
}

export const MATERIAL_DIALOG_CONFIG_PROVIDER = {
  provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {disableClose: true, hasBackdrop: true}
};
