/* eslint-disable */
import { Mutex } from 'async-mutex';
import { LoggerFactory } from '../util/logger-factory';

/* eslint-enable */

export interface OnStartStopListener {
  onStart(): void;

  onStop(): void;
}

export interface OnRefreshListener {
  onRefresh(): void;
}

export class LoadingHandler {

  static readonly HANDLER_PARAM = 'no_progress_bar';
  static readonly HANDLER_OFF = LoadingHandler.HANDLER_PARAM + '=true';

  private static readonly instance: LoadingHandler = new LoadingHandler();

  private readonly logger = LoggerFactory.createLogger('LoadingHandler').disableDebug();

  private readonly mutex = new Mutex();
  private readonly responseDelayInMs = 200;

  private counter: number = 0;
  private onStartStopListener?: OnStartStopListener;
  private onRefreshListener?: OnRefreshListener;

  public static getInstance(): LoadingHandler {
    return LoadingHandler.instance;
  }

  private constructor() {
  }

  public setOnStartStopListener(onStartStopListener: OnStartStopListener) {
    const self = this;
    self.mutex.runExclusive(function () {
      self.onStartStopListener = onStartStopListener;
    });
  }

  public setOnRefreshListener(onRefreshListener: OnRefreshListener) {
    const self = this;
    self.mutex.runExclusive(function () {
      self.onRefreshListener = onRefreshListener;
    });
  }

  public refresh() {
    const self = this;
    self.mutex.runExclusive(function () {
      if (self.onRefreshListener) {
        self.logger.debug('loading-handler');
        self.onRefreshListener.onRefresh();
      }
    });
  }

  public onRequest() {
    const self = this;
    self.mutex.runExclusive(function () {
      self.counter++;
      self.logger.debug('onRequest ' + self.counter);
      if (self.counter === 1) {
        self.onStart();
      }
    });
  }

  public onResponse() {
    const self = this;
    setTimeout(function () {
      self.mutex.runExclusive(function () {
        self.counter--;
        self.logger.debug('onResponse ' + self.counter);
        if (self.counter === 0) {
          self.onStop();
        }
      });
    }, self.responseDelayInMs);
  }

  private onStart() {
    this.logger.debug('onStart');
    if (this.onStartStopListener) {
      this.onStartStopListener.onStart();
    }
  }

  private onStop() {
    this.logger.debug('onStop');
    if (this.onStartStopListener) {
      this.onStartStopListener.onStop();
    }
  }

}
