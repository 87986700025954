/* eslint-disable */
import { AfterViewInit, Component, ViewChild, } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Transition, UIRouter } from '@uirouter/angular';
import { combineLatest, Observable } from 'rxjs';
import { SurveyRecord, SurveyRecordService } from '../../../lib/survey/survey-record.service';
import { Survey, SurveyService } from '../../../lib/survey/survey.service';
import { SurveyRecordBase } from '../survey-record-base/survey-record-base-view';
import { SurveyRecordBaseComponent } from '../survey-record-base/survey-record-base.component';
import { FormRecordContainer } from '../../form/form-record/form-record-container';
import { FormRecordContainerComponent } from '../../form/form-record/form-record-container.component';
import { Form } from '../../../lib/form/form.service';
import { ConfigurationService, } from '../../../lib/core-ext/configuration.service';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { StateName } from '../../../app.state-names';
import { TranslateService } from '@ngx-translate/core';
/* eslint-enable */

@Component({
  selector: 'app-survey-record-detail',
  templateUrl: 'survey-record-detail.component.html',
  styleUrls: ['survey-record-detail.component.scss'],
})
export class SurveyRecordDetailComponent implements AfterViewInit {

  @ViewChild(SurveyRecordBaseComponent, { static: true })
  baseView: SurveyRecordBase.View;

  @ViewChild(FormRecordContainerComponent, { static: true })
  formRecordContainerView: FormRecordContainer.View;

  @ViewChild('f', { static: true })
  fForm: NgForm;

  surveyId: number;
  surveyRecordId: number;
  breadcrumbSelf: string;
  breadcrumbParents: BreadcrumbParent[] = [];
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  surveyName: string;

  private combinedResult?: CombinedResult;

  constructor(private configurationService: ConfigurationService,
              private surveyService: SurveyService,
              private surveyRecordService: SurveyRecordService,
              private uiRouter: UIRouter,
              private translateService: TranslateService,
              private transition: Transition) {
    this.surveyId = this.transition.params().surveyId;
    this.surveyRecordId = this.transition.params().id;
  }

  ngAfterViewInit(): void {
    this.createCombinedObservable().subscribe(
      (result: CombinedResult) => {
        this.breadcrumbSelf = result.surveyRecord.name!;
        this.surveyName = result.survey.name;
        this.baseView.loadSurveyRecord({
          surveyRecord: result.surveyRecord
        });
        this.formRecordContainerView.loadFormRecord({
          configuration: this.configurationService.getConfiguration(),
          form: result.form,
          record: result.surveyRecord.formRecord,
        });
        this.combinedResult = result;
      },
      () => {},
      () => {
        this.translateService.get('MENU_NAVBAR_SURVEY_RECORD').subscribe(
          (result: string) => {
            this.breadcrumbParents.push({name: result, uiSref: StateName.SURVEY_DASHBOARD});
          }
        );
        this.breadcrumbParents.push({name: this.surveyName, uiSref: StateName.SURVEY_RECORD_LIST, uiParam: {id: this.surveyId}});
      }
    );
  }

  nothing() {
  }

  private createCombinedObservable(): Observable<CombinedResult> {
    return combineLatest(
      this.surveyService.getForm({
        parentId: this.surveyId
      }),
      this.surveyService.get({
        surveyId: this.surveyId
      }),
      this.surveyRecordService.get({
        withFormRecord: true,
        surveyId: this.surveyId,
        surveyRecordId: this.surveyRecordId
      }),
      (form: Form.Form,
       survey: Survey.Survey,
       surveyRecord: SurveyRecord.SurveyRecord) => {
        return {
          form: form,
          survey: survey,
          surveyRecord: surveyRecord
        };
      }
    );
  }

}

interface CombinedResult {
  form: Form.Form;
  survey: Survey.Survey;
  surveyRecord: SurveyRecord.SurveyRecord;
}
